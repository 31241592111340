export const bluePuzzle = [
  {
    word: "blue",
    hint: "the color of the sky",
    answer: "blue",
    alteredPosition: 0,
  },
  {
    word: "blue",
    hint: "red + ___ = purple",
    answer: "blue",
    alteredPosition: 1,
  },
  {
    word: "blue",
    hint: "a sad color",
    answer: "blue",
    alteredPosition: 2,
  },
  {
    word: "blue",
    hint: "name of a cartoon dog who looks for clues",
    answer: "blue",
    alteredPosition: 3,
  },
  {
    word: "blue",
    hint: "like Marge Simpson's hair",
    answer: "blue",
    alteredPosition: 2,
  },
  {
    word: "blue",
    hint: "green - yellow = ___",
    answer: "blue",
    alteredPosition: 1,
  },
  {
    word: "blue",
    hint: "a hockey player in St. Louis",
    answer: "blue",
    alteredPosition: 0,
  },
  {
    word: "blue",
    hint: "___ whale (the largest animal)",
    answer: "blue",
    alteredPosition: 1,
  },
  {
    word: "blue",
    hint: "the color of a Smurf",
    answer: "blue",
    alteredPosition: 2,
  },
  {
    word: "blue",
    hint: "1901 to 1904 is described as Picasso's ___ Period",
    answer: "blue",
    alteredPosition: 3,
  },
];
