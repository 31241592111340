export const game = [
  // day #1
  [
    {
      word: "woo",
      hint: "stir frying pan",
      answer: "wok",
      alteredPosition: 2,
    },
    {
      word: "wok",
      hint: "took the championship",
      answer: "won",
      alteredPosition: 2,
    },
    {
      word: "won",
      hint: `dramatic cry: "___ is me!"`,
      answer: "woe",
      alteredPosition: 2,
    },
    {
      word: "woe",
      hint: "long-handled farm tool",
      answer: "hoe",
      alteredPosition: 0,
    },
    {
      word: "hoe",
      hint: "move like a kangaroo",
      answer: "hop",
      alteredPosition: 2,
    },
    {
      word: "hop",
      hint: `after "alley" in basketball`,
      answer: "oop",
      alteredPosition: 0,
    },
    {
      word: "oop",
      hint: `pairs with an audience "aah"`,
      answer: "ooh",
      alteredPosition: 2,
    },
    {
      word: "ooh",
      hint: `modeling toy: play-___`,
      answer: "doh",
      alteredPosition: 0,
    },
    {
      word: "doh",
      hint: "Lassie is one",
      answer: "dog",
      alteredPosition: 2,
    },
    {
      word: "dog",
      hint: "gear tooth",
      answer: "cog",
      alteredPosition: 0,
    },
  ],
  // day #2
  [
    {
      word: "her",
      hint: "order a pay-___-view",
      answer: "per",
      alteredPosition: 0,
    },
    {
      word: "per",
      hint: "engergizing talk a coach gives",
      answer: "pep",
      alteredPosition: 2,
    },
    {
      word: "pep",
      hint: `opposite of "nope"`,
      answer: "yep",
      alteredPosition: 0,
    },
    {
      word: "yep",
      hint: `slang term for "mouth"`,
      answer: "yap",
      alteredPosition: 1,
    },
    {
      word: "yap",
      hint: "Thanksgiving vegetable",
      answer: "yam",
      alteredPosition: 2,
    },
    {
      word: "yam",
      hint: `delicious!`,
      answer: "yum",
      alteredPosition: 1,
    },
    {
      word: "yum",
      hint: `Big Red, for example`,
      answer: "gum",
      alteredPosition: 0,
    },
    {
      word: "gum",
      hint: `any dude`,
      answer: "guy",
      alteredPosition: 2,
    },
    {
      word: "guy",
      hint: `belly`,
      answer: "gut",
      alteredPosition: 2,
    },
    {
      word: "gut",
      hint: "to chop",
      answer: "cut",
      alteredPosition: 0,
    },
  ],
  // day #3
  [
    {
      word: "has",
      hint: "it's not a solid or a liquid",
      answer: "gas",
      alteredPosition: 0,
    },
    {
      word: "gas",
      hint: "actress who plays Wonder Woman",
      answer: "gal",
      alteredPosition: 2,
    },
    {
      word: "gal",
      hint: "hair styling product",
      answer: "gel",
      alteredPosition: 1,
    },
    {
      word: "gel",
      hint: "delete key label",
      answer: "del",
      alteredPosition: 0,
    },
    {
      word: "del",
      hint: "israel city: ___ Aviv",
      answer: "tel",
      alteredPosition: 0,
    },
    {
      word: "tel",
      hint: "1 + 2 + 3 + 4",
      answer: "ten",
      alteredPosition: 2,
    },
    {
      word: "ten",
      hint: "unit of weight for elephants",
      answer: "ton",
      alteredPosition: 1,
    },
    {
      word: "ton",
      hint: "Jayden, to Will Smith",
      answer: "son",
      alteredPosition: 0,
    },
    {
      word: "son",
      hint: `before "Andreas" or "Antonio"`,
      answer: "san",
      alteredPosition: 1,
    },
    {
      word: "san",
      hint: "moral slip-up",
      answer: "sin",
      alteredPosition: 1,
    },
  ],
  // day #4
  [
    {
      word: "four",
      hint: "unpleasant",
      answer: "foul",
      alteredPosition: 3,
    },
    {
      word: "foul",
      hint: "a fencing sword",
      answer: "foil",
      alteredPosition: 2,
    },
    {
      word: "foil",
      hint: "liquid bubbling",
      answer: "boil",
      alteredPosition: 0,
    },
    {
      word: "boil",
      hint: "to work hard",
      answer: "toil",
      alteredPosition: 0,
    },
    {
      word: "toil",
      hint: "wags when happy",
      answer: "tail",
      alteredPosition: 1,
    },
    {
      word: "tail",
      hint: "trains drive on a ___road",
      answer: "rail",
      alteredPosition: 0,
    },
    {
      word: "rail",
      hint: "military attack, often by air",
      answer: "raid",
      alteredPosition: 3,
    },
    {
      word: "raid",
      hint: "has a bucket and a mop",
      answer: "maid",
      alteredPosition: 0,
    },
    {
      word: "maid",
      hint: "primary",
      answer: "main",
      alteredPosition: 3,
    },
    {
      word: "main",
      hint: "ouch",
      answer: "pain",
      alteredPosition: 0,
    },
  ],
  // day #5
  [
    {
      word: "spit",
      hint: "a common dog name",
      answer: "spot",
      alteredPosition: 2,
    },
    {
      word: "spot",
      hint: "a narrow opening",
      answer: "slot",
      alteredPosition: 1,
    },
    {
      word: "slot",
      hint: "black, powdery substance",
      answer: "soot",
      alteredPosition: 1,
    },
    {
      word: "soot",
      hint: "to cheer",
      answer: "root",
      alteredPosition: 0,
    },
    {
      word: "root",
      hint: "a tall shoe",
      answer: "boot",
      alteredPosition: 0,
    },
    {
      word: "boot",
      hint: "to run away",
      answer: "bolt",
      alteredPosition: 2,
    },
    {
      word: "bolt",
      hint: "worn around the waist",
      answer: "belt",
      alteredPosition: 1,
    },
    {
      word: "belt",
      hint: "dark red vegtable",
      answer: "beet",
      alteredPosition: 2,
    },
    {
      word: "beet",
      hint: "short, high-pitched sound",
      answer: "beep",
      alteredPosition: 3,
    },
    {
      word: "beep",
      hint: "marshmallow treat",
      answer: "peep",
      alteredPosition: 0,
    },
  ],
  // day #6
  [
    {
      word: "run",
      hint: "a religious woman",
      answer: "nun",
      alteredPosition: 0,
    },
    {
      word: "nun",
      hint: "expressing negation or absence",
      answer: "non",
      alteredPosition: 1,
    },
    {
      word: "non",
      hint: "opposite of later",
      answer: "now",
      alteredPosition: 1,
    },
    {
      word: "now",
      hint: "says moo",
      answer: "cow",
      alteredPosition: 0,
    },
    {
      word: "cow",
      hint: "serves and protects",
      answer: "cop",
      alteredPosition: 2,
    },
    {
      word: "cop",
      hint: "washes the floor",
      answer: "mop",
      alteredPosition: 0,
    },
    {
      word: "mop",
      hint: "drawing of an area",
      answer: "map",
      alteredPosition: 1,
    },
    {
      word: "map",
      hint: "sticky, from a tree",
      answer: "sap",
      alteredPosition: 0,
    },
    {
      word: "sap",
      hint: "a tiny drink",
      answer: "sip",
      alteredPosition: 1,
    },
    {
      word: "sip",
      hint: "extra gratitude",
      answer: "tip",
      alteredPosition: 0,
    },
  ],
  // 3/28/2022
  [
    {
      word: "trip",
      hint: "catch an animal",
      answer: "trap",
      alteredPosition: 2,
    },
    {
      word: "trap",
      hint: "ash___",
      answer: "tray",
      alteredPosition: 3,
    },
    {
      word: "tray",
      hint: "religious worship",
      answer: "pray",
      alteredPosition: 0,
    },
    {
      word: "pray",
      hint: "theatrical performance",
      answer: "play",
      alteredPosition: 1,
    },
    {
      word: "play",
      hint: "vanquish",
      answer: "slay",
      alteredPosition: 0,
    },
    {
      word: "slay",
      hint: "strike with your hand",
      answer: "slap",
      alteredPosition: 3,
    },
    {
      word: "slap",
      hint: "accidental fall",
      answer: "slip",
      alteredPosition: 2,
    },
    {
      word: "slip",
      hint: "pig food",
      answer: "slop",
      alteredPosition: 2,
    },
    {
      word: "slop",
      hint: "mall activity",
      answer: "shop",
      alteredPosition: 1,
    },
    {
      word: "shop",
      hint: "large boat",
      answer: "ship",
      alteredPosition: 2,
    },
  ],
  // 3/29/2022 (#7)
  [
    {
      word: "shard",
      hint: "not dull",
      answer: "sharp",
      alteredPosition: 4,
    },
    {
      word: "sharp",
      hint: "a single stock",
      answer: "share",
      alteredPosition: 4,
    },
    {
      word: "share",
      hint: "beach",
      answer: "shore",
      alteredPosition: 2,
    },
    {
      word: "shore",
      hint: "a household task",
      answer: "chore",
      alteredPosition: 0,
    },
    {
      word: "chore",
      hint: "decided",
      answer: "chose",
      alteredPosition: 3,
    },
    {
      word: "chose",
      hint: "nearby",
      answer: "close",
      alteredPosition: 1,
    },
    {
      word: "close",
      hint: "a duplicate",
      answer: "clone",
      alteredPosition: 3,
    },
    {
      word: "clone",
      hint: "garlic measurement",
      answer: "clove",
      alteredPosition: 3,
    },
    {
      word: "clove",
      hint: "a hand covering",
      answer: "glove",
      alteredPosition: 0,
    },
    {
      word: "glove",
      hint: "model of Earth",
      answer: "globe",
      alteredPosition: 3,
    },
  ],
  // 3/30/2022 (#8)
  [
    {
      word: "foot",
      hint: "silly person",
      answer: "fool",
      alteredPosition: 3,
    },
    {
      word: "fool",
      hint: "chilly",
      answer: "cool",
      alteredPosition: 0,
    },
    {
      word: "cool",
      hint: "black rock fuel",
      answer: "coal",
      alteredPosition: 2,
    },
    {
      word: "coal",
      hint: "jacket",
      answer: "coat",
      alteredPosition: 3,
    },
    {
      word: "coat",
      hint: "water vehicle",
      answer: "boat",
      alteredPosition: 0,
    },
    {
      word: "boat",
      hint: "furry pig",
      answer: "boar",
      alteredPosition: 3,
    },
    {
      word: "boar",
      hint: "brown, polar, panda",
      answer: "bear",
      alteredPosition: 1,
    },
    {
      word: "bear",
      hint: "fruit",
      answer: "pear",
      alteredPosition: 0,
    },
    {
      word: "pear",
      hint: "top of a mountain",
      answer: "peak",
      alteredPosition: 3,
    },
    {
      word: "peak",
      hint: "frail",
      answer: "weak",
      alteredPosition: 0,
    },
  ],
  // 3/31/2022 (#9)
  [
    {
      word: "bear",
      hint: "a drop of sweat",
      answer: "bead",
      alteredPosition: 3,
    },
    {
      word: "bead",
      hint: "paint, pipes, pencil",
      answer: "lead",
      alteredPosition: 0,
    },
    {
      word: "lead",
      hint: "divulging information",
      answer: "leak",
      alteredPosition: 3,
    },
    {
      word: "leak",
      hint: "the top",
      answer: "peak",
      alteredPosition: 0,
    },
    {
      word: "peak",
      hint: "a light kiss",
      answer: "peck",
      alteredPosition: 2,
    },
    {
      word: "peck",
      hint: "to punch",
      answer: "deck",
      alteredPosition: 0,
    },
    {
      word: "deck",
      hint: "bringing a boat to a stop",
      answer: "dock",
      alteredPosition: 1,
    },
    {
      word: "dock",
      hint: "a geek",
      answer: "dork",
      alteredPosition: 2,
    },
    {
      word: "dork",
      hint: "pig",
      answer: "pork",
      alteredPosition: 0,
    },
    {
      word: "pork",
      hint: "a harbor",
      answer: "port",
      alteredPosition: 3,
    },
  ],
  // 4/1/2022 (#10, #102)
  [
    {
      word: "love",
      hint: "white bird",
      answer: "dove",
      alteredPosition: 0,
    },
    {
      word: "dove",
      hint: "intentional fall",
      answer: "dive",
      alteredPosition: 1,
    },
    {
      word: "dive",
      hint: "honey home",
      answer: "hive",
      alteredPosition: 0,
    },
    {
      word: "hive",
      hint: "nature walk",
      answer: "hike",
      alteredPosition: 2,
    },
    {
      word: "hike",
      hint: "two wheels",
      answer: "bike",
      alteredPosition: 0,
    },
    {
      word: "bike",
      hint: "cook in an oven",
      answer: "bake",
      alteredPosition: 1,
    },
    {
      word: "bake",
      hint: "a bundle of hay",
      answer: "bale",
      alteredPosition: 2,
    },
    {
      word: "bale",
      hint: "gets thrown, kicked, or hit",
      answer: "ball",
      alteredPosition: 3,
    },
    {
      word: "ball",
      hint: "ointment to heal skin",
      answer: "balm",
      alteredPosition: 3,
    },
    {
      word: "balm",
      hint: "tranquil",
      answer: "calm",
      alteredPosition: 0,
    },
  ],
  // 4/2/2022 (#11)
  [
    {
      word: "drop",
      hint: "trickle",
      answer: "drip",
      alteredPosition: 2,
    },
    {
      word: "drip",
      hint: "journey",
      answer: "trip",
      alteredPosition: 0,
    },
    {
      word: "trip",
      hint: "shorten",
      answer: "trim",
      alteredPosition: 3,
    },
    {
      word: "trim",
      hint: "trolley",
      answer: "tram",
      alteredPosition: 2,
    },
    {
      word: "tram",
      hint: "sports group",
      answer: "team",
      alteredPosition: 1,
    },
    {
      word: "team",
      hint: "laser",
      answer: "beam",
      alteredPosition: 0,
    },
    {
      word: "beam",
      hint: "a drop of liquid",
      answer: "bead",
      alteredPosition: 3,
    },
    {
      word: "bead",
      hint: "curve",
      answer: "bend",
      alteredPosition: 2,
    },
    {
      word: "bend",
      hint: "fix",
      answer: "mend",
      alteredPosition: 0,
    },
    {
      word: "mend",
      hint: "brain",
      answer: "mind",
      alteredPosition: 1,
    },
  ],
  // 4/3/2022 (#12)
  [
    {
      word: "fire",
      hint: "to employ",
      answer: "hire",
      alteredPosition: 0,
    },
    {
      word: "hire",
      hint: "a bunny",
      answer: "hare",
      alteredPosition: 1,
    },
    {
      word: "hare",
      hint: "to injure",
      answer: "harm",
      alteredPosition: 3,
    },
    {
      word: "harm",
      hint: "Old MacDonald had one",
      answer: "farm",
      alteredPosition: 0,
    },
    {
      word: "farm",
      hint: "to shape",
      answer: "form",
      alteredPosition: 1,
    },
    {
      word: "form",
      hint: "animal with no arms, legs, or bones",
      answer: "worm",
      alteredPosition: 0,
    },
    {
      word: "worm",
      hint: "perform a job",
      answer: "work",
      alteredPosition: 3,
    },
    {
      word: "work",
      hint: "meat from a pig",
      answer: "pork",
      alteredPosition: 0,
    },
    {
      word: "pork",
      hint: "dock ships",
      answer: "port",
      alteredPosition: 3,
    },
    {
      word: "port",
      hint: "frown",
      answer: "pout",
      alteredPosition: 2,
    },
  ],
  // 4/4/2022 (#13)
  [
    {
      word: "rope",
      hint: "be sad",
      answer: "mope",
      alteredPosition: 0,
    },
    {
      word: "mope",
      hint: "mean, median, ___",
      answer: "mode",
      alteredPosition: 2,
    },
    {
      word: "mode",
      hint: "computer program",
      answer: "code",
      alteredPosition: 0,
    },
    {
      word: "code",
      hint: "ice cream wafer container",
      answer: "cone",
      alteredPosition: 2,
    },
    {
      word: "cone",
      hint: "walking stick",
      answer: "cane",
      alteredPosition: 1,
    },
    {
      word: "cane",
      hint: "birthday, for example",
      answer: "cake",
      alteredPosition: 2,
    },
    {
      word: "cake",
      hint: "rise up",
      answer: "wake",
      alteredPosition: 0,
    },
    {
      word: "wake",
      hint: "goods and merchandise",
      answer: "ware",
      alteredPosition: 2,
    },
    {
      word: "ware",
      hint: "benign growth",
      answer: "wart",
      alteredPosition: 3,
    },
    {
      word: "wart",
      hint: "sour",
      answer: "tart",
      alteredPosition: 0,
    },
  ],
  // 4/5/2022 (#14)
  [
    {
      word: "bun",
      hint: "type of joke",
      answer: "pun",
      alteredPosition: 0,
    },
    {
      word: "pun",
      hint: "a young dog",
      answer: "pup",
      alteredPosition: 2,
    },
    {
      word: "pup",
      hint: "a drinking receptacle",
      answer: "cup",
      alteredPosition: 0,
    },
    {
      word: "cup",
      hint: "a kind of hat",
      answer: "cap",
      alteredPosition: 1,
    },
    {
      word: "cap",
      hint: "a taxi",
      answer: "cab",
      alteredPosition: 2,
    },
    {
      word: "cab",
      hint: "to steal",
      answer: "nab",
      alteredPosition: 0,
    },
    {
      word: "nab",
      hint: "a science place",
      answer: "lab",
      alteredPosition: 0,
    },
    {
      word: "lab",
      hint: "a toss",
      answer: "lob",
      alteredPosition: 1,
    },
    {
      word: "lob",
      hint: "to float long",
      answer: "bob",
      alteredPosition: 0,
    },
    {
      word: "bob",
      hint: "a ghost says",
      answer: "boo",
      alteredPosition: 2,
    },
  ],
  // 4/6/2022 (#15)
  [
    {
      word: "vat",
      hint: "wear it upon your head",
      answer: "hat",
      alteredPosition: 0,
    },
    {
      word: "hat",
      hint: "high temperature",
      answer: "hot",
      alteredPosition: 1,
    },
    {
      word: "hot",
      hint: "an owl says",
      answer: "hoo",
      alteredPosition: 2,
    },
    {
      word: "hoo",
      hint: "a cow says",
      answer: "moo",
      alteredPosition: 0,
    },
    {
      word: "moo",
      hint: "Larry, Curly, and ___",
      answer: "moe",
      alteredPosition: 2,
    },
    {
      word: "moe",
      hint: "a female deer",
      answer: "doe",
      alteredPosition: 0,
    },
    {
      word: "doe",
      hint: "singlular form of dice",
      answer: "die",
      alteredPosition: 1,
    },
    {
      word: "die",
      hint: "to compete",
      answer: "vie",
      alteredPosition: 0,
    },
    {
      word: "vie",
      hint: "a special guest",
      answer: "vip",
      alteredPosition: 2,
    },
    {
      word: "vip",
      hint: "a hint",
      answer: "tip",
      alteredPosition: 0,
    },
  ],
  // 4/7/2022 (#16)
  [
    {
      word: "copy",
      hint: "deal with",
      answer: "cope",
      alteredPosition: 3,
    },
    {
      word: "cope",
      hint: "arrive",
      answer: "come",
      alteredPosition: 2,
    },
    {
      word: "come",
      hint: "a circular roof",
      answer: "dome",
      alteredPosition: 0,
    },
    {
      word: "dome",
      hint: "10 cent coin",
      answer: "dime",
      alteredPosition: 1,
    },
    {
      word: "dime",
      hint: "a green citrus fruit",
      answer: "lime",
      alteredPosition: 0,
    },
    {
      word: "lime",
      hint: "existence",
      answer: "life",
      alteredPosition: 2,
    },
    {
      word: "life",
      hint: "to carry",
      answer: "lift",
      alteredPosition: 3,
    },
    {
      word: "lift",
      hint: "attic",
      answer: "loft",
      alteredPosition: 1,
    },
    {
      word: "loft",
      hint: "missing",
      answer: "lost",
      alteredPosition: 2,
    },
    {
      word: "lost",
      hint: "mail",
      answer: "post",
      alteredPosition: 0,
    },
  ],
  // 4/8/2022 (#17)
  [
    {
      word: "womb",
      hint: "hair brush",
      answer: "comb",
      alteredPosition: 0,
    },
    {
      word: "comb",
      hint: "to give away for free",
      answer: "comp",
      alteredPosition: 3,
    },
    {
      word: "comp",
      hint: "outdoor living",
      answer: "camp",
      alteredPosition: 1,
    },
    {
      word: "camp",
      hint: "slightly wet",
      answer: "damp",
      alteredPosition: 0,
    },
    {
      word: "damp",
      hint: "woman of rank",
      answer: "dame",
      alteredPosition: 3,
    },
    {
      word: "dame",
      hint: "domesticated",
      answer: "tame",
      alteredPosition: 0,
    },
    {
      word: "tame",
      hint: "clock",
      answer: "time",
      alteredPosition: 1,
    },
    {
      word: "time",
      hint: "copy",
      answer: "mime",
      alteredPosition: 0,
    },
    {
      word: "mime",
      hint: "5,280 feet, 1,600 meters",
      answer: "mile",
      alteredPosition: 2,
    },
    {
      word: "mile",
      hint: "evil",
      answer: "vile",
      alteredPosition: 0,
    },
  ],
  // 4/9/2022 (#18)
  [
    {
      word: "rad",
      hint: "to remove",
      answer: "rid",
      alteredPosition: 1,
    },
    {
      word: "rid",
      hint: "a cover",
      answer: "lid",
      alteredPosition: 0,
    },
    {
      word: "lid",
      hint: "a boy",
      answer: "lad",
      alteredPosition: 1,
    },
    {
      word: "lad",
      hint: "the rules",
      answer: "law",
      alteredPosition: 2,
    },
    {
      word: "law",
      hint: "uncooked",
      answer: "raw",
      alteredPosition: 0,
    },
    {
      word: "raw",
      hint: "to paddle",
      answer: "row",
      alteredPosition: 1,
    },
    {
      word: "row",
      hint: "shoots arrows",
      answer: "bow",
      alteredPosition: 0,
    },
    {
      word: "bow",
      hint: "wet, muddy ground",
      answer: "bog",
      alteredPosition: 2,
    },
    {
      word: "bog",
      hint: "a tiny insect",
      answer: "bug",
      alteredPosition: 1,
    },
    {
      word: "bug",
      hint: "to pull",
      answer: "tug",
      alteredPosition: 0,
    },
  ],
  // 4/10/2022 (#19)
  [
    {
      word: "ref",
      hint: "color of a stop sign",
      answer: "red",
      alteredPosition: 2,
    },
    {
      word: "red",
      hint: "a place to sleep",
      answer: "bed",
      alteredPosition: 0,
    },
    {
      word: "bed",
      hint: "honey maker",
      answer: "bee",
      alteredPosition: 2,
    },
    {
      word: "bee",
      hint: "a type of shirt",
      answer: "tee",
      alteredPosition: 0,
    },
    {
      word: "tee",
      hint: "a foot finger",
      answer: "toe",
      alteredPosition: 1,
    },
    {
      word: "toe",
      hint: "fish eggs",
      answer: "roe",
      alteredPosition: 0,
    },
    {
      word: "roe",
      hint: "a type of grain",
      answer: "rye",
      alteredPosition: 1,
    },
    {
      word: "rye",
      hint: "used to add color",
      answer: "dye",
      alteredPosition: 0,
    },
    {
      word: "dye",
      hint: "hole in a needle",
      answer: "eye",
      alteredPosition: 0,
    },
    {
      word: "eye",
      hint: "a female sheep",
      answer: "ewe",
      alteredPosition: 1,
    },
  ],
  // 4/11/2022 (#20)
  [
    {
      word: "son",
      hint: "center of the solar system",
      answer: "sun",
      alteredPosition: 1,
    },
    {
      word: "sun",
      hint: "offense or fault",
      answer: "sin",
      alteredPosition: 1,
    },
    {
      word: "sin",
      hint: "___ and tonic",
      answer: "gin",
      alteredPosition: 0,
    },
    {
      word: "gin",
      hint: "a weapon",
      answer: "gun",
      alteredPosition: 1,
    },
    {
      word: "gun",
      hint: "stomach",
      answer: "gut",
      alteredPosition: 2,
    },
    {
      word: "gut",
      hint: "shack",
      answer: "hut",
      alteredPosition: 0,
    },
    {
      word: "hut",
      hint: "headgear",
      answer: "hat",
      alteredPosition: 1,
    },
    {
      word: "hat",
      hint: "feline",
      answer: "cat",
      alteredPosition: 0,
    },
    {
      word: "cat",
      hint: "a bed",
      answer: "cot",
      alteredPosition: 1,
    },
    {
      word: "cot",
      hint: "a cooking container",
      answer: "pot",
      alteredPosition: 0,
    },
  ],
  // 4/12/2022 (#21)
  [
    {
      word: "bar",
      hint: "hearing organ",
      answer: "ear",
      alteredPosition: 0,
    },
    {
      word: "ear",
      hint: "to ingest",
      answer: "eat",
      alteredPosition: 2,
    },
    {
      word: "eat",
      hint: "plump",
      answer: "fat",
      alteredPosition: 0,
    },
    {
      word: "fat",
      hint: "in good health",
      answer: "fit",
      alteredPosition: 1,
    },
    {
      word: "fit",
      hint: "mental sharpness",
      answer: "wit",
      alteredPosition: 0,
    },
    {
      word: "wit",
      hint: "head covering made of hair",
      answer: "wig",
      alteredPosition: 2,
    },
    {
      word: "wig",
      hint: "oinks",
      answer: "pig",
      alteredPosition: 0,
    },
    {
      word: "pig",
      hint: "pirate's leg",
      answer: "peg",
      alteredPosition: 1,
    },
    {
      word: "peg",
      hint: "a writing instrument",
      answer: "pen",
      alteredPosition: 2,
    },
    {
      word: "pen",
      hint: "multiple males",
      answer: "men",
      alteredPosition: 0,
    },
  ],
  // 4/13/2022 (#22)
  [
    {
      word: "etsy",
      hint: "simple",
      answer: "easy",
      alteredPosition: 1,
    },
    {
      word: "easy",
      hint: "where the sun rises",
      answer: "east",
      alteredPosition: 3,
    },
    {
      word: "east",
      hint: "an ensemble",
      answer: "cast",
      alteredPosition: 0,
    },
    {
      word: "cast",
      hint: "a barrel",
      answer: "cask",
      alteredPosition: 3,
    },
    {
      word: "cask",
      hint: "a to-do",
      answer: "task",
      alteredPosition: 0,
    },
    {
      word: "task",
      hint: "an armored vehicle",
      answer: "tank",
      alteredPosition: 2,
    },
    {
      word: "tank",
      hint: "assign status",
      answer: "rank",
      alteredPosition: 0,
    },
    {
      word: "rank",
      hint: "skating area",
      answer: "rink",
      alteredPosition: 1,
    },
    {
      word: "rink",
      hint: "a citrus skin",
      answer: "rind",
      alteredPosition: 3,
    },
    {
      word: "rind",
      hint: "brain",
      answer: "mind",
      alteredPosition: 0,
    },
  ],
  // 4/14/2022 (#23)
  [
    {
      word: "kind",
      hint: "a monarch",
      answer: "king",
      alteredPosition: 3,
    },
    {
      word: "king",
      hint: "helps a plane fly",
      answer: "wing",
      alteredPosition: 0,
    },
    {
      word: "wing",
      hint: "an eye gesture",
      answer: "wink",
      alteredPosition: 3,
    },
    {
      word: "wink",
      hint: "mix of red and white",
      answer: "pink",
      alteredPosition: 0,
    },
    {
      word: "pink",
      hint: "type of rock music",
      answer: "punk",
      alteredPosition: 1,
    },
    {
      word: "punk",
      hint: "a hockey ball",
      answer: "puck",
      alteredPosition: 2,
    },
    {
      word: "puck",
      hint: "a male deer",
      answer: "buck",
      alteredPosition: 0,
    },
    {
      word: "buck",
      hint: "a large quantity",
      answer: "bulk",
      alteredPosition: 2,
    },
    {
      word: "bulk",
      hint: "a male bovine",
      answer: "bull",
      alteredPosition: 3,
    },
    {
      word: "bull",
      hint: "opposite of push",
      answer: "pull",
      alteredPosition: 0,
    },
  ],
  // 4/15/2022 (#24)
  [
    {
      word: "haul",
      hint: "pellets of frozen rain",
      answer: "hail",
      alteredPosition: 2,
    },
    {
      word: "hail",
      hint: "what a barber cuts",
      answer: "hair",
      alteredPosition: 3,
    },
    {
      word: "hair",
      hint: "just",
      answer: "fair",
      alteredPosition: 0,
    },
    {
      word: "fair",
      hint: "a criminal's hideout",
      answer: "lair",
      alteredPosition: 0,
    },
    {
      word: "lair",
      hint: "produced some eggs",
      answer: "laid",
      alteredPosition: 3,
    },
    {
      word: "laid",
      hint: "to praise, glorify, or honor",
      answer: "laud",
      alteredPosition: 2,
    },
    {
      word: "laud",
      hint: "having exceptional volume",
      answer: "loud",
      alteredPosition: 1,
    },
    {
      word: "loud",
      hint: "quantity of clothes put in a washing machine",
      answer: "load",
      alteredPosition: 2,
    },
    {
      word: "load",
      hint: "dangerous element in older paint",
      answer: "lead",
      alteredPosition: 1,
    },
    {
      word: "lead",
      hint: "tilt in one direction",
      answer: "lean",
      alteredPosition: 3,
    },
  ],
  // 4/16/2022 (#25)
  [
    {
      word: "mile",
      hint: "not-too-spicy salsa choice",
      answer: "mild",
      alteredPosition: 3,
    },
    {
      word: "mild",
      hint: "interior wall problem",
      answer: "mold",
      alteredPosition: 1,
    },
    {
      word: "mold",
      hint: "spy deeply undercover",
      answer: "mole",
      alteredPosition: 3,
    },
    {
      word: "mole",
      hint: "ski equipment",
      answer: "pole",
      alteredPosition: 0,
    },
    {
      word: "pole",
      hint: "survey of voters",
      answer: "poll",
      alteredPosition: 3,
    },
    {
      word: "poll",
      hint: "tablet in an aspirin bottle",
      answer: "pill",
      alteredPosition: 1,
    },
    {
      word: "pill",
      hint: "take up all the available space",
      answer: "fill",
      alteredPosition: 0,
    },
    {
      word: "fill",
      hint: "flannel shirt season",
      answer: "fall",
      alteredPosition: 1,
    },
    {
      word: "fall",
      hint: "terrible performance",
      answer: "fail",
      alteredPosition: 2,
    },
    {
      word: "fail",
      hint: "wrapping for a Hershey's Kiss",
      answer: "foil",
      alteredPosition: 1,
    },
  ],
  // 4/17/2022 (#26)
  [
    {
      word: "wake",
      hint: "water-skiing venue",
      answer: "lake",
      alteredPosition: 0,
    },
    {
      word: "lake",
      hint: "thumbs up on Facebook",
      answer: "like",
      alteredPosition: 1,
    },
    {
      word: "like",
      hint: `"swoosh" company`,
      answer: "nike",
      alteredPosition: 0,
    },
    {
      word: "nike",
      hint: "atomic bomb, casually",
      answer: "nuke",
      alteredPosition: 1,
    },
    {
      word: "nuke",
      hint: "to move deceptively",
      answer: "juke",
      alteredPosition: 0,
    },
    {
      word: "juke",
      hint: "the sixth month",
      answer: "june",
      alteredPosition: 2,
    },

    {
      word: "june",
      hint: "ridge of blown sand",
      answer: "dune",
      alteredPosition: 0,
    },
    {
      word: "dune",
      hint: "eat at 7 p.m., say",
      answer: "dine",
      alteredPosition: 1,
    },
    {
      word: "dine",
      hint: "sound effect for a correct answer",
      answer: "ding",
      alteredPosition: 3,
    },
    {
      word: "ding",
      hint: "search engine since 2009",
      answer: "bing",
      alteredPosition: 0,
    },
  ],
  // 4/18/2022 (#27)
  [
    {
      word: "bud",
      hint: "soil, after a heavy rain",
      answer: "mud",
      alteredPosition: 0,
    },
    {
      word: "mud",
      hint: "angry",
      answer: "mad",
      alteredPosition: 1,
    },
    {
      word: "mad",
      hint: "a trend",
      answer: "fad",
      alteredPosition: 0,
    },
    {
      word: "fad",
      hint: "big admirer",
      answer: "fan",
      alteredPosition: 2,
    },
    {
      word: "fan",
      hint: "part of a shark seen above",
      answer: "fin",
      alteredPosition: 1,
    },
    {
      word: "fin",
      hint: "family",
      answer: "kin",
      alteredPosition: 0,
    },

    {
      word: "kin",
      hint: "a young goat",
      answer: "kid",
      alteredPosition: 2,
    },
    {
      word: "kid",
      hint: "thing to do at an auction",
      answer: "bid",
      alteredPosition: 0,
    },
    {
      word: "bid",
      hint: "The ___ Bang",
      answer: "big",
      alteredPosition: 2,
    },
    {
      word: "big",
      hint: "a band's booking",
      answer: "gig",
      alteredPosition: 0,
    },
  ],
  // 4/19/2022 (#28)
  [
    {
      word: "card",
      hint: "thin, woven rope",
      answer: "cord",
      alteredPosition: 1,
    },
    {
      word: "cord",
      hint: "part of Microsoft Office",
      answer: "word",
      alteredPosition: 0,
    },
    {
      word: "word",
      hint: "a rotten apple resident",
      answer: "worm",
      alteredPosition: 3,
    },
    {
      word: "worm",
      hint: "something bureaucratic to fill out",
      answer: "form",
      alteredPosition: 0,
    },
    {
      word: "form",
      hint: "cappuccino topping",
      answer: "foam",
      alteredPosition: 1,
    },
    {
      word: "foam",
      hint: "to drift here and there",
      answer: "roam",
      alteredPosition: 0,
    },
    {
      word: "roam",
      hint: "there might be a fork in one",
      answer: "road",
      alteredPosition: 3,
    },
    {
      word: "road",
      hint: "curl up with a good book, say",
      answer: "read",
      alteredPosition: 1,
    },
    {
      word: "read",
      hint: "tall slender plant of wet areas",
      answer: "reed",
      alteredPosition: 2,
    },
    {
      word: "reed",
      hint: "undesirable plant",
      answer: "weed",
      alteredPosition: 0,
    },
  ],
  // 4/20/2022 (#29)
  [
    {
      word: "pool",
      hint: "yarn for a winter garment",
      answer: "wool",
      alteredPosition: 0,
    },
    {
      word: "wool",
      hint: "comment from a canine",
      answer: "woof",
      alteredPosition: 3,
    },
    {
      word: "woof",
      hint: "top of your mouth",
      answer: "roof",
      alteredPosition: 0,
    },
    {
      word: "roof",
      hint: "plant part that's underground",
      answer: "root",
      alteredPosition: 3,
    },
    {
      word: "root",
      hint: "money from a heist",
      answer: "loot",
      alteredPosition: 0,
    },
    {
      word: "loot",
      hint: "one might step on the gas",
      answer: "foot",
      alteredPosition: 0,
    },
    {
      word: "foot",
      hint: "refuge in a snowball fight",
      answer: "fort",
      alteredPosition: 2,
    },
    {
      word: "fort",
      hint: "chopsticks alternative",
      answer: "fork",
      alteredPosition: 3,
    },
    {
      word: "fork",
      hint: "common people",
      answer: "folk",
      alteredPosition: 2,
    },
    {
      word: "folk",
      hint: "the center of an egg",
      answer: "yolk",
      alteredPosition: 0,
    },
  ],
  // 4/21/2022 (#30)
  [
    {
      word: "door",
      hint: "person of action",
      answer: "doer",
      alteredPosition: 2,
    },
    {
      word: "doer",
      hint: "gentle creature of the forest",
      answer: "deer",
      alteredPosition: 1,
    },
    {
      word: "deer",
      hint: "academic paper reviewer, often",
      answer: "peer",
      alteredPosition: 0,
    },
    {
      word: "peer",
      hint: "tree inhabited by a Christmas partridge",
      answer: "pear",
      alteredPosition: 2,
    },
    {
      word: "pear",
      hint: "use one of your senses",
      answer: "hear",
      alteredPosition: 0,
    },
    {
      word: "hear",
      hint: "your brain is in it",
      answer: "head",
      alteredPosition: 3,
    },
    {
      word: "head",
      hint: "a group of cattle",
      answer: "herd",
      alteredPosition: 2,
    },
    {
      word: "herd",
      hint: "how you may find this puzzle",
      answer: "hard",
      alteredPosition: 1,
    },
    {
      word: "hard",
      hint: "listen up (old-style)",
      answer: "hark",
      alteredPosition: 3,
    },
    {
      word: "hark",
      hint: "like Darth Vader's side",
      answer: "dark",
      alteredPosition: 0,
    },
  ],
  // 4/22/2022 (#31)
  [
    {
      word: "hand",
      hint: "not the sea",
      answer: "land",
      alteredPosition: 0,
    },
    {
      word: "land",
      hint: "a narrow path",
      answer: "lane",
      alteredPosition: 3,
    },
    {
      word: "lane",
      hint: "a small part of a film script",
      answer: "line",
      alteredPosition: 1,
    },
    {
      word: "line",
      hint: "air freshener scent",
      answer: "pine",
      alteredPosition: 0,
    },
    {
      word: "pine",
      hint: `lead weapon in "Clue"`,
      answer: "pipe",
      alteredPosition: 2,
    },
    {
      word: "pipe",
      hint: "Vatican City's eminent tenant",
      answer: "pope",
      alteredPosition: 1,
    },
    {
      word: "pope",
      hint: "a fool",
      answer: "dope",
      alteredPosition: 0,
    },
    {
      word: "dope",
      hint: "fruit cup brand",
      answer: "dole",
      alteredPosition: 2,
    },
    {
      word: "dole",
      hint: "toy that might sleep in a miniature bed",
      answer: "doll",
      alteredPosition: 3,
    },
    {
      word: "doll",
      hint: "yawn-inducing",
      answer: "dull",
      alteredPosition: 1,
    },
  ],
  // 4/23/2022 (#32)
  [
    {
      word: "copy",
      hint: "deal with difficulities",
      answer: "cope",
      alteredPosition: 3,
    },
    {
      word: "cope",
      hint: "a lasso",
      answer: "rope",
      alteredPosition: 0,
    },
    {
      word: "rope",
      hint: "fully developed or matured",
      answer: "ripe",
      alteredPosition: 1,
    },
    {
      word: "ripe",
      hint: "use a moist towelette, say",
      answer: "wipe",
      alteredPosition: 0,
    },
    {
      word: "wipe",
      hint: "what fills some cellars",
      answer: "wine",
      alteredPosition: 2,
    },
    {
      word: "wine",
      hint: "feathered limb",
      answer: "wing",
      alteredPosition: 3,
    },
    {
      word: "wing",
      hint: "engage in karaoke",
      answer: "sing",
      alteredPosition: 0,
    },
    {
      word: "sing",
      hint: "place to put dirty dishes",
      answer: "sink",
      alteredPosition: 3,
    },
    {
      word: "sink",
      hint: "where you may skate on thin ice",
      answer: "rink",
      alteredPosition: 3,
    },
    {
      word: "rink",
      hint: "jeopardy, danger",
      answer: "risk",
      alteredPosition: 2,
    },
  ],
  // 4/23/2022 (#33)
  [
    {
      word: "lark",
      hint: "animal fat",
      answer: "lard",
      alteredPosition: 3,
    },
    {
      word: "lard",
      hint: "title for a duke",
      answer: "lord",
      alteredPosition: 1,
    },
    {
      word: "lord",
      hint: "cable to charge your phone",
      answer: "cord",
      alteredPosition: 0,
    },
    {
      word: "cord",
      hint: "Hallmark greeting",
      answer: "card",
      alteredPosition: 1,
    },
    {
      word: "card",
      hint: "freshwater fish like a koi",
      answer: "carp",
      alteredPosition: 3,
    },
    {
      word: "carp",
      hint: "large stringed musical instrument",
      answer: "harp",
      alteredPosition: 0,
    },
    {
      word: "harp",
      hint: "way to boil an egg",
      answer: "hard",
      alteredPosition: 3,
    },
    {
      word: "hard",
      hint: "type of immunity",
      answer: "herd",
      alteredPosition: 1,
    },
    {
      word: "herd",
      hint: "basil or sage, for example",
      answer: "herb",
      alteredPosition: 3,
    },
    {
      word: "herb",
      hint: "an action word",
      answer: "verb",
      alteredPosition: 0,
    },
  ],
  // 4/24/2022 (#34)
  [
    {
      word: "vest",
      hint: "bird's home of twigs",
      answer: "nest",
      alteredPosition: 0,
    },
    {
      word: "nest",
      hint: "clean or orderly",
      answer: "neat",
      alteredPosition: 2,
    },
    {
      word: "neat",
      hint: "within spittin' distance of",
      answer: "near",
      alteredPosition: 3,
    },
    {
      word: "near",
      hint: "char on the surface",
      answer: "sear",
      alteredPosition: 0,
    },
    {
      word: "sear",
      hint: "Lion King antagonist",
      answer: "scar",
      alteredPosition: 1,
    },
    {
      word: "scar",
      hint: "throw some jabs",
      answer: "spar",
      alteredPosition: 1,
    },
    {
      word: "spar",
      hint: "disruptive e-mail",
      answer: "spam",
      alteredPosition: 3,
    },
    {
      word: "spam",
      hint: "fake, fraud",
      answer: "sham",
      alteredPosition: 1,
    },
    {
      word: "sham",
      hint: "impact onomatopoeia",
      answer: "wham",
      alteredPosition: 0,
    },
    {
      word: "wham",
      hint: "sudden idea to do something",
      answer: "whim",
      alteredPosition: 2,
    },
  ],
  // 4/25/2022 (#35)
  [
    {
      word: "left",
      hint: "storage space with ladder access",
      answer: "loft",
      alteredPosition: 1,
    },
    {
      word: "loft",
      hint: `like those who ask "Where am I?"`,
      answer: "lost",
      alteredPosition: 2,
    },
    {
      word: "lost",
      hint: "price",
      answer: "cost",
      alteredPosition: 0,
    },
    {
      word: "cost",
      hint: "broken bone cover",
      answer: "cast",
      alteredPosition: 1,
    },
    {
      word: "cast",
      hint: "part of a sailboat",
      answer: "mast",
      alteredPosition: 0,
    },
    {
      word: "mast",
      hint: "a large crowd",
      answer: "mass",
      alteredPosition: 3,
    },
    {
      word: "mass",
      hint: "mountain range path",
      answer: "pass",
      alteredPosition: 0,
    },
    {
      word: "pass",
      hint: "historic",
      answer: "past",
      alteredPosition: 3,
    },
    {
      word: "past",
      hint: "separate piece",
      answer: "part",
      alteredPosition: 2,
    },
    {
      word: "part",
      hint: "green section in a city",
      answer: "park",
      alteredPosition: 3,
    },
  ],
  // 4/26/2022 (#36)
  [
    {
      word: "babe",
      hint: "a large bundle of cotton, hay, or paper",
      answer: "bale",
      alteredPosition: 2,
    },
    {
      word: "bale",
      hint: "fable or parable",
      answer: "tale",
      alteredPosition: 0,
    },
    {
      word: "tale",
      hint: "snatch, grab",
      answer: "take",
      alteredPosition: 2,
    },
    {
      word: "take",
      hint: "layered dessert, often",
      answer: "cake",
      alteredPosition: 0,
    },
    {
      word: "cake",
      hint: "hole in the side of a cliff or hill",
      answer: "cave",
      alteredPosition: 2,
    },
    {
      word: "cave",
      hint: "to cover with cement",
      answer: "pave",
      alteredPosition: 0,
    },
    {
      word: "pave",
      hint: "not tanned at all",
      answer: "pale",
      alteredPosition: 2,
    },
    {
      word: "pale",
      hint: "hide in your hand, as a magician would",
      answer: "palm",
      alteredPosition: 3,
    },
    {
      word: "palm",
      hint: "lip ____, i.e. ChapStick",
      answer: "balm",
      alteredPosition: 0,
    },
    {
      word: "balm",
      hint: "needing no shampoo",
      answer: "bald",
      alteredPosition: 3,
    },
  ],
  // 4/28/2022 (#37)
  [
    {
      word: "air",
      hint: "prepare to take a shot",
      answer: "aim",
      alteredPosition: 2,
    },
    {
      word: "aim",
      hint: "edge of a drinking cup",
      answer: "rim",
      alteredPosition: 0,
    },
    {
      word: "rim",
      hint: "liquor made in Jamaica",
      answer: "rum",
      alteredPosition: 1,
    },
    {
      word: "rum",
      hint: "addition problem's answer",
      answer: "sum",
      alteredPosition: 0,
    },
    {
      word: "sum",
      hint: "the closest star",
      answer: "sun",
      alteredPosition: 2,
    },
    {
      word: "sun",
      hint: "___ and games",
      answer: "fun",
      alteredPosition: 0,
    },
    {
      word: "fun",
      hint: "animal's coat",
      answer: "fur",
      alteredPosition: 2,
    },
    {
      word: "fur",
      hint: "good way off",
      answer: "far",
      alteredPosition: 1,
    },
    {
      word: "far",
      hint: "glass container with a lid",
      answer: "jar",
      alteredPosition: 0,
    },
    {
      word: "jar",
      hint: "toast topper, often",
      answer: "jam",
      alteredPosition: 2,
    },
  ],
  // 4/29/2022 (#38)
  [
    {
      word: "zero",
      hint: "one who comes to the rescue",
      answer: "hero",
      alteredPosition: 0,
    },
    {
      word: "hero",
      hint: "group of livestock",
      answer: "herd",
      alteredPosition: 3,
    },
    {
      word: "herd",
      hint: "you may hang it in shame",
      answer: "head",
      alteredPosition: 2,
    },
    {
      word: "head",
      hint: "big untidy pile",
      answer: "heap",
      alteredPosition: 3,
    },
    {
      word: "heap",
      hint: "what a good Samaritan offers",
      answer: "help",
      alteredPosition: 2,
    },
    {
      word: "help",
      hint: "place unlikely to freeze over",
      answer: "hell",
      alteredPosition: 3,
    },
    {
      word: "hell",
      hint: "it rings",
      answer: "bell",
      alteredPosition: 0,
    },
    {
      word: "bell",
      hint: "___ Gates (Microsoft founder)",
      answer: "bill",
      alteredPosition: 1,
    },
    {
      word: "bill",
      hint: "last legal testament",
      answer: "will",
      alteredPosition: 0,
    },
    {
      word: "will",
      hint: "totally untamed",
      answer: "wild",
      alteredPosition: 3,
    },
  ],
  // 4/30/2022 (#39)
  [
    {
      word: "time",
      hint: "less than exciting",
      answer: "tame",
      alteredPosition: 1,
    },
    {
      word: "tame",
      hint: "titled lady in England",
      answer: "dame",
      alteredPosition: 0,
    },
    {
      word: "dame",
      hint: "John Doe, e.g.",
      answer: "name",
      alteredPosition: 0,
    },
    {
      word: "name",
      hint: "the back of one's neck",
      answer: "nape",
      alteredPosition: 2,
    },
    {
      word: "nape",
      hint: "be open-mouthed",
      answer: "gape",
      alteredPosition: 0,
    },
    {
      word: "gape",
      hint: "plane boarding area",
      answer: "gate",
      alteredPosition: 2,
    },
    {
      word: "gate",
      hint: "buddy (in London)",
      answer: "mate",
      alteredPosition: 0,
    },
    {
      word: "mate",
      hint: "horse's long hair",
      answer: "mane",
      alteredPosition: 2,
    },
    {
      word: "mane",
      hint: "a whole lotta",
      answer: "many",
      alteredPosition: 3,
    },
    {
      word: "many",
      hint: "wacky",
      answer: "zany",
      alteredPosition: 0,
    },
  ],
  // 5/1/2022 (#40)
  [
    {
      word: "quiz",
      hint: "pound sterling",
      answer: "quid",
      alteredPosition: 3,
    },
    {
      word: "quid",
      hint: "area between buildings on campus",
      answer: "quad",
      alteredPosition: 2,
    },
    {
      word: "quad",
      hint: "twosome",
      answer: "duad",
      alteredPosition: 0,
    },
    {
      word: "duad",
      hint: "____ as a doornail",
      answer: "dead",
      alteredPosition: 1,
    },
    {
      word: "dead",
      hint: "peruse",
      answer: "read",
      alteredPosition: 0,
    },
    {
      word: "read",
      hint: "500 sheets of paper",
      answer: "ream",
      alteredPosition: 3,
    },
    {
      word: "ream",
      hint: "use a cell phone outside of your area",
      answer: "roam",
      alteredPosition: 1,
    },
    {
      word: "roam",
      hint: "Cappuccino topper",
      answer: "foam",
      alteredPosition: 0,
    },
    {
      word: "foam",
      hint: "to shape",
      answer: "form",
      alteredPosition: 2,
    },
    {
      word: "form",
      hint: "object with tines",
      answer: "fork",
      alteredPosition: 3,
    },
  ],
  // 5/2/2022 (#41)
  [
    {
      word: "sure",
      hint: "hang 10",
      answer: "surf",
      alteredPosition: 3,
    },
    {
      word: "surf",
      hint: "what a gang protects",
      answer: "turf",
      alteredPosition: 0,
    },
    {
      word: "turf",
      hint: "invert",
      answer: "turn",
      alteredPosition: 3,
    },
    {
      word: "turn",
      hint: "incinerate",
      answer: "burn",
      alteredPosition: 0,
    },
    {
      word: "burn",
      hint: "farm building",
      answer: "barn",
      alteredPosition: 1,
    },
    {
      word: "barn",
      hint: "unclothed",
      answer: "bare",
      alteredPosition: 3,
    },
    {
      word: "bare",
      hint: "be affected emotionally",
      answer: "care",
      alteredPosition: 0,
    },
    {
      word: "care",
      hint: "crux",
      answer: "core",
      alteredPosition: 1,
    },
    {
      word: "core",
      hint: "128 cubic feet of wood",
      answer: "cord",
      alteredPosition: 3,
    },
    {
      word: "cord",
      hint: "an informal term for a female student",
      answer: "coed",
      alteredPosition: 2,
    },
  ],
  // 5/3/2022 (#42)
  [
    {
      word: "born",
      hint: "dawn",
      answer: "morn",
      alteredPosition: 0,
    },
    {
      word: "morn",
      hint: "show someone your bum",
      answer: "moon",
      alteredPosition: 2,
    },
    {
      word: "moon",
      hint: "shortly",
      answer: "soon",
      alteredPosition: 0,
    },
    {
      word: "soon",
      hint: "it dirties santa's outfit",
      answer: "soot",
      alteredPosition: 3,
    },
    {
      word: "soot",
      hint: "put into alphabetical order",
      answer: "sort",
      alteredPosition: 2,
    },
    {
      word: "sort",
      hint: "structure that might be made of pillows",
      answer: "fort",
      alteredPosition: 0,
    },
    {
      word: "fort",
      hint: "pass gas",
      answer: "fart",
      alteredPosition: 1,
    },
    {
      word: "fart",
      hint: "forgo eating",
      answer: "fast",
      alteredPosition: 2,
    },
    {
      word: "fast",
      hint: "clenched hand",
      answer: "fist",
      alteredPosition: 1,
    },
    {
      word: "fist",
      hint: "Italian auto manufacturer",
      answer: "fiat",
      alteredPosition: 2,
    },
  ],
  // 5/4/2022 (#43)
  [
    {
      word: "fiat",
      hint: "impressive achievement",
      answer: "feat",
      alteredPosition: 1,
    },
    {
      word: "feat",
      hint: "place in Parliament/Congress",
      answer: "seat",
      alteredPosition: 0,
    },
    {
      word: "seat",
      hint: "navy ___",
      answer: "seal",
      alteredPosition: 3,
    },
    {
      word: "seal",
      hint: "pass out cards",
      answer: "deal",
      alteredPosition: 0,
    },
    {
      word: "deal",
      hint: "computer manufacturer",
      answer: "dell",
      alteredPosition: 2,
    },
    {
      word: "dell",
      hint: "Barbie, e.g.",
      answer: "doll",
      alteredPosition: 1,
    },
    {
      word: "doll",
      hint: "roadway fee",
      answer: "toll",
      alteredPosition: 0,
    },
    {
      word: "toll",
      hint: "recounted",
      answer: "told",
      alteredPosition: 3,
    },
    {
      word: "told",
      hint: "darken a font",
      answer: "bold",
      alteredPosition: 0,
    },
    {
      word: "bold",
      hint: "harry potter's blemish",
      answer: "bolt",
      alteredPosition: 3,
    },
  ],
  // 5/5/2022 (#44)
  [
    {
      word: "bolt",
      hint: "smaller than a ship",
      answer: "boat",
      alteredPosition: 2,
    },
    {
      word: "boat",
      hint: "piece of clothing worn outside",
      answer: "coat",
      alteredPosition: 0,
    },
    {
      word: "coat",
      hint: "the price to be paid",
      answer: "cost",
      alteredPosition: 2,
    },
    {
      word: "cost",
      hint: "someone who has guests",
      answer: "host",
      alteredPosition: 0,
    },
    {
      word: "host",
      hint: "tube for watering plants",
      answer: "hose",
      alteredPosition: 3,
    },
    {
      word: "hose",
      hint: "donut's middle",
      answer: "hole",
      alteredPosition: 2,
    },
    {
      word: "hole",
      hint: "distribute in charity",
      answer: "dole",
      alteredPosition: 0,
    },
    {
      word: "dole",
      hint: "one of Disney's chipmunks",
      answer: "dale",
      alteredPosition: 1,
    },
    {
      word: "dale",
      hint: "percent-off promotion",
      answer: "sale",
      alteredPosition: 0,
    },
    {
      word: "sale",
      hint: "seasoning paired with pepper",
      answer: "salt",
      alteredPosition: 3,
    },
  ],
  // 5/6/2022 (#45)
  [
    {
      word: "snow",
      hint: "to demonstrate",
      answer: "show",
      alteredPosition: 1,
    },
    {
      word: "show",
      hint: "a store",
      answer: "shop",
      alteredPosition: 3,
    },
    {
      word: "shop",
      hint: "huge boat",
      answer: "ship",
      alteredPosition: 2,
    },
    {
      word: "ship",
      hint: "stumble or slide",
      answer: "slip",
      alteredPosition: 1,
    },
    {
      word: "slip",
      hint: "moved smoothly",
      answer: "slid",
      alteredPosition: 3,
    },
    {
      word: "slid",
      hint: "uttered",
      answer: "said",
      alteredPosition: 1,
    },
    {
      word: "said",
      hint: "provided funds",
      answer: "paid",
      alteredPosition: 0,
    },
    {
      word: "paid",
      hint: "discomfort",
      answer: "pain",
      alteredPosition: 3,
    },
    {
      word: "pain",
      hint: "weather that may cancel a picnic",
      answer: "rain",
      alteredPosition: 0,
    },
    {
      word: "rain",
      hint: "horizontal bar",
      answer: "rail",
      alteredPosition: 3,
    },
  ],
  // 5/7/2022 (#46)
  [
    {
      word: "snork",
      hint: "make loud noises while sleeping",
      answer: "snore",
      alteredPosition: 4,
    },
    {
      word: "snore",
      hint: "camping treat with marshmallows",
      answer: "smore",
      alteredPosition: 1,
    },
    {
      word: "smore",
      hint: "that's Italian for love",
      answer: "amore",
      alteredPosition: 0,
    },
    {
      word: "amore",
      hint: "be fond of",
      answer: "adore",
      alteredPosition: 1,
    },
    {
      word: "adore",
      hint: "decorate or embellish",
      answer: "adorn",
      alteredPosition: 4,
    },
    {
      word: "adorn",
      hint: "oak tree nut",
      answer: "acorn",
      alteredPosition: 1,
    },
    {
      word: "acorn",
      hint: "openly dislike or disrespect",
      answer: "scorn",
      alteredPosition: 0,
    },
    {
      word: "scorn",
      hint: "kick a field goal or drop a basket, e.g.",
      answer: "score",
      alteredPosition: 4,
    },
    {
      word: "score",
      hint: "frighten",
      answer: "scare",
      alteredPosition: 2,
    },
    {
      word: "scare",
      hint: "10 pins in two balls",
      answer: "spare",
      alteredPosition: 1,
    },
  ],
  // 5/8/2022 (#47)
  [
    {
      word: "dime",
      hint: "hemispherical roof",
      answer: "dome",
      alteredPosition: 1,
    },
    {
      word: "dome",
      hint: "___ into one's own",
      answer: "come",
      alteredPosition: 0,
    },
    {
      word: "come",
      hint: "provide free tickets to",
      answer: "comp",
      alteredPosition: 3,
    },
    {
      word: "comp",
      hint: "violent government overthrow",
      answer: "coup",
      alteredPosition: 2,
    },
    {
      word: "coup",
      hint: "appetizer served in a bowl",
      answer: "soup",
      alteredPosition: 0,
    },
    {
      word: "soup",
      hint: "The Days of Our Lives, e.g.",
      answer: "soap",
      alteredPosition: 2,
    },
    {
      word: "soap",
      hint: "fly aloft",
      answer: "soar",
      alteredPosition: 3,
    },
    {
      word: "soar",
      hint: "wild pig",
      answer: "boar",
      alteredPosition: 0,
    },
    {
      word: "boar",
      hint: "Yogi or Pooh, e.g.",
      answer: "bear",
      alteredPosition: 1,
    },
    {
      word: "bear",
      hint: "drop on a face",
      answer: "tear",
      alteredPosition: 0,
    },
  ],
  // 5/15/2022 (#54)
  [
    {
      word: "free",
      hint: "lose sleep (over)",
      answer: "fret",
      alteredPosition: 3,
    },
    {
      word: "fret",
      hint: "parts of the body that are slightly larger in the evening than morning",
      answer: "feet",
      alteredPosition: 1,
    },
    {
      word: "feet",
      hint: "underground vegetable with edible greens",
      answer: "beet",
      alteredPosition: 0,
    },
    {
      word: "beet",
      hint: "verb in egg recipes",
      answer: "beat",
      alteredPosition: 2,
    },
    {
      word: "beat",
      hint: "coffee (by the unit)",
      answer: "bean",
      alteredPosition: 3,
    },
    { word: "bean", hint: "to signify", answer: "mean", alteredPosition: 0 },
    {
      word: "mean",
      hint: "sound from a dungeon",
      answer: "moan",
      alteredPosition: 1,
    },
    {
      word: "moan",
      hint: "apollo program destination",
      answer: "moon",
      alteredPosition: 2,
    },
    {
      word: "moon",
      hint: "irrelevant",
      answer: "moot",
      alteredPosition: 3,
    },
    {
      word: "moot",
      hint: "stolen money",
      answer: "loot",
      alteredPosition: 0,
    },
  ],
  // 5/16/2022 (#55)
  [
    {
      word: "raid",
      hint: "Old ___ (card game)",
      answer: "maid",
      alteredPosition: 0,
    },
    {
      word: "maid",
      hint: "letters, packages, etc.",
      answer: "mail",
      alteredPosition: 3,
    },
    { word: "mail", hint: "flunk", answer: "fail", alteredPosition: 0 },
    {
      word: "fail",
      hint: "aluminum sheet",
      answer: "foil",
      alteredPosition: 1,
    },
    {
      word: "foil",
      hint: "surely he jests",
      answer: "fool",
      alteredPosition: 2,
    },
    {
      word: "fool",
      hint: "filly or colt",
      answer: "foal",
      alteredPosition: 2,
    },
    {
      word: "foal",
      hint: "nonrenewable source of energy",
      answer: "coal",
      alteredPosition: 0,
    },
    {
      word: "coal",
      hint: "checked item",
      answer: "coat",
      alteredPosition: 3,
    },
    { word: "coat", hint: "price", answer: "cost", alteredPosition: 2 },
    {
      word: "cost",
      hint: "share on social media",
      answer: "post",
      alteredPosition: 0,
    },
  ],
  // 5/17/2022 (#56)
  [
    { word: "clam", hint: "a talon", answer: "claw", alteredPosition: 3 },
    {
      word: "claw",
      hint: "a deli side",
      answer: "slaw",
      alteredPosition: 0,
    },
    {
      word: "slaw",
      hint: "a thick slice",
      answer: "slab",
      alteredPosition: 3,
    },
    {
      word: "slab",
      hint: "an item in a COVID-19 test kit",
      answer: "swab",
      alteredPosition: 1,
    },
    {
      word: "swab",
      hint: "to strike a fly",
      answer: "swat",
      alteredPosition: 3,
    },
    {
      word: "swat",
      hint: "subway wish",
      answer: "seat",
      alteredPosition: 1,
    },
    {
      word: "seat",
      hint: "coconut filler",
      answer: "meat",
      alteredPosition: 0,
    },
    {
      word: "meat",
      hint: "defensive ring",
      answer: "moat",
      alteredPosition: 1,
    },
    {
      word: "moat",
      hint: "bearded mountain dweller",
      answer: "goat",
      alteredPosition: 0,
    },
    {
      word: "goat",
      hint: "good shot",
      answer: "goal",
      alteredPosition: 3,
    },
  ],
  // 5/18/2022 (#57)
  [
    {
      word: "pale",
      hint: 'Dickens classic "A ___ of Two Cities"',
      answer: "tale",
      alteredPosition: 0,
    },
    {
      word: "tale",
      hint: "to gibber-jabber",
      answer: "talk",
      alteredPosition: 3,
    },
    {
      word: "talk",
      hint: "word that might excite a dog",
      answer: "walk",
      alteredPosition: 0,
    },
    {
      word: "walk",
      hint: "place to hang a poster",
      answer: "wall",
      alteredPosition: 3,
    },
    {
      word: "wall",
      hint: "a stretch between classrooms",
      answer: "hall",
      alteredPosition: 0,
    },
    {
      word: "hall",
      hint: '"___ caesar!"',
      answer: "hail",
      alteredPosition: 2,
    },
    {
      word: "hail",
      hint: "get-out-of-jail money",
      answer: "bail",
      alteredPosition: 0,
    },
    {
      word: "bail",
      hint: "heat to more than 212 degrees, as water",
      answer: "boil",
      alteredPosition: 1,
    },
    {
      word: "boil",
      hint: "slinky, e.g.",
      answer: "coil",
      alteredPosition: 0,
    },
    {
      word: "coil",
      hint: "fuel from a mine",
      answer: "coal",
      alteredPosition: 2,
    },
  ],
  // 5/19/2022 (#58)
  [
    {
      word: "cafe",
      hint: "pet store purchase",
      answer: "cage",
      alteredPosition: 2,
    },
    {
      word: "cage",
      hint: "what workers earn",
      answer: "wage",
      alteredPosition: 0,
    },
    {
      word: "wage",
      hint: "thing caught near the shore",
      answer: "wave",
      alteredPosition: 2,
    },
    {
      word: "wave",
      hint: "spelunking site",
      answer: "cave",
      alteredPosition: 0,
    },
    {
      word: "cave",
      hint: "small inlet on the water",
      answer: "cove",
      alteredPosition: 1,
    },
    {
      word: "cove",
      hint: "shape of a party hat",
      answer: "cone",
      alteredPosition: 2,
    },
    {
      word: "cone",
      hint: "rib or femur",
      answer: "bone",
      alteredPosition: 0,
    },
    {
      word: "bone",
      hint: "water pipe",
      answer: "bong",
      alteredPosition: 3,
    },
    {
      word: "bong",
      hint: "gun blast",
      answer: "bang",
      alteredPosition: 1,
    },
    {
      word: "bang",
      hint: `word after "rubber" or "rock"`,
      answer: "band",
      alteredPosition: 3,
    },
  ],
  // 5/20/2022 (#59)
  [
    {
      word: "door",
      hint: "secure to the dock",
      answer: "moor",
      alteredPosition: 0,
    },
    {
      word: "moor",
      hint: "a kind of ring or music",
      answer: "mood",
      alteredPosition: 3,
    },
    {
      word: "mood",
      hint: "what's thrown in a cafeteria fight",
      answer: "food",
      alteredPosition: 0,
    },
    {
      word: "food",
      hint: "a court entertainer",
      answer: "fool",
      alteredPosition: 3,
    },
    {
      word: "fool",
      hint: "a stable newborn",
      answer: "foal",
      alteredPosition: 2,
    },
    { word: "foal", hint: "froth", answer: "foam", alteredPosition: 3 },
    { word: "foam", hint: "to wander", answer: "roam", alteredPosition: 0 },
    {
      word: "roam",
      hint: "office paper stack",
      answer: "ream",
      alteredPosition: 1,
    },
    {
      word: "ream",
      hint: "sewn line on a pair of slacks",
      answer: "seam",
      alteredPosition: 0,
    },
    {
      word: "seam",
      hint: "deception",
      answer: "sham",
      alteredPosition: 1,
    },
  ],
  // 5/21/2022 (#60)
  [
    {
      word: "hurt",
      hint: "terse",
      answer: "curt",
      alteredPosition: 0,
    },
    {
      word: "curt",
      hint: "to restore health",
      answer: "cure",
      alteredPosition: 3,
    },
    {
      word: "cure",
      hint: "artificial bait for fishing",
      answer: "lure",
      alteredPosition: 0,
    },
    {
      word: "lure",
      hint: "to lie hidden",
      answer: "lurk",
      alteredPosition: 3,
    },
    {
      word: "lurk",
      hint: "a person from Istanbul",
      answer: "turk",
      alteredPosition: 0,
    },
    {
      word: "turk",
      hint: "elephant's ivory tooth",
      answer: "tusk",
      alteredPosition: 2,
    },
    {
      word: "tusk",
      hint: "from ___ till dawn",
      answer: "dusk",
      alteredPosition: 0,
    },
    {
      word: "dusk",
      hint: "UFO's shape in many depictions",
      answer: "disk",
      alteredPosition: 1,
    },
    {
      word: "disk",
      hint: "smack-talk",
      answer: "diss",
      alteredPosition: 3,
    },
    {
      word: "diss",
      hint: "hug's partner",
      answer: "kiss",
      alteredPosition: 0,
    },
  ],
  // 5/22/2022 (#61)
  [
    {
      word: "olay",
      hint: "sculptor's soft material",
      answer: "clay",
      alteredPosition: 0,
    },
    {
      word: "clay",
      hint: "often used in chowders",
      answer: "clam",
      alteredPosition: 3,
    },
    {
      word: "clam",
      hint: "like the 1970's david bowie",
      answer: "glam",
      alteredPosition: 0,
    },
    {
      word: "glam",
      hint: "sad",
      answer: "glum",
      alteredPosition: 2,
    },
    {
      word: "glum",
      hint: "an oversupply",
      answer: "glut",
      alteredPosition: 3,
    },
    {
      word: "glut",
      hint: "disease with painful joint inflamation",
      answer: "gout",
      alteredPosition: 1,
    },
    {
      word: "gout",
      hint: "make a sad face",
      answer: "pout",
      alteredPosition: 0,
    },
    {
      word: "pout",
      hint: "a boxers' event",
      answer: "bout",
      alteredPosition: 0,
    },
    {
      word: "bout",
      hint: "rock the ___ (stir up trouble)",
      answer: "boat",
      alteredPosition: 2,
    },
    {
      word: "boat",
      hint: "scarves or snakes",
      answer: "boas",
      alteredPosition: 3,
    },
  ],
  // 5/23/2022 (#62)
  [
    {
      word: "pint",
      hint: "mouthwash flavour",
      answer: "mint",
      alteredPosition: 0,
    },
    {
      word: "mint",
      hint: "'sierra ___': a lemon-lime soda",
      answer: "mist",
      alteredPosition: 2,
    },
    {
      word: "mist",
      hint: "'little ___ sunshine'",
      answer: "miss",
      alteredPosition: 3,
    },
    {
      word: "miss",
      hint: "to insult, slangily",
      answer: "diss",
      alteredPosition: 0,
    },
    {
      word: "diss",
      hint: "a plate",
      answer: "dish",
      alteredPosition: 3,
    },
    {
      word: "dish",
      hint: "a genie can grant one or more of this",
      answer: "wish",
      alteredPosition: 0,
    },
    {
      word: "wish",
      hint: "step before dry",
      answer: "wash",
      alteredPosition: 1,
    },
    {
      word: "wash",
      hint: "a relative of the bee",
      answer: "wasp",
      alteredPosition: 3,
    },
    {
      word: "wasp",
      hint: "a faster-than-light engine speed",
      answer: "warp",
      alteredPosition: 2,
    },
    {
      word: "warp",
      hint: "an angelic instrument",
      answer: "harp",
      alteredPosition: 0,
    },
  ],
  // 5/24/2022 (#63)
  [
    {
      word: "chaim",
      hint: "piece of furniture for sitting",
      answer: "chair",
      alteredPosition: 4,
    },
    {
      word: "chair",
      hint: "'The Cosby Show' wife",
      answer: "clair",
      alteredPosition: 1,
    },
    {
      word: "clair",
      hint: "stylishness and originality",
      answer: "flair",
      alteredPosition: 0,
    },
    {
      word: "flair",
      hint: "to swing or move ones arms in a crazed fashion",
      answer: "flail",
      alteredPosition: 4,
    },
    {
      word: "flail",
      hint: "physically weak or easily broken",
      answer: "frail",
      alteredPosition: 1,
    },
    {
      word: "frail",
      hint: "a path marked and maintained in the woods",
      answer: "trail",
      alteredPosition: 0,
    },
    {
      word: "trail",
      hint: "to fish by dragging a net behind a boat",
      answer: "trawl",
      alteredPosition: 3,
    },
    {
      word: "trawl",
      hint: "southerners in the us speak with one of these",
      answer: "drawl",
      alteredPosition: 0,
    },
    {
      word: "drawl",
      hint: "sketched",
      answer: "drawn",
      alteredPosition: 4,
    },
    {
      word: "drawn",
      hint: "muscular strength",
      answer: "brawn",
      alteredPosition: 0,
    },
  ],
  // 5/25/2022 (#64)
  [
    {
      word: "weir",
      hint: "put on clothing",
      answer: "wear",
      alteredPosition: 2,
    },
    {
      word: "wear",
      hint: "shape of some honey containers",
      answer: "bear",
      alteredPosition: 0,
    },
    {
      word: "bear",
      hint: "drink of fermented malt and hops",
      answer: "beer",
      alteredPosition: 2,
    },
    {
      word: "beer",
      hint: "carnivorous dish from cattle",
      answer: "beef",
      alteredPosition: 3,
    },
    {
      word: "beef",
      hint: "a large collection of coral",
      answer: "reef",
      alteredPosition: 0,
    },
    {
      word: "reef",
      hint: "an oboe has a double one",
      answer: "reed",
      alteredPosition: 3,
    },
    {
      word: "reed",
      hint: "peruse a book",
      answer: "read",
      alteredPosition: 2,
    },
    {
      word: "read",
      hint: "a paved path for cars",
      answer: "road",
      alteredPosition: 1,
    },
    {
      word: "road",
      hint: "crowd sound",
      answer: "roar",
      alteredPosition: 3,
    },
    {
      word: "roar",
      hint: "trunk's position, on many cars",
      answer: "rear",
      alteredPosition: 1,
    },
  ],
  // 5/26/2022 (#65)
  [
    {
      word: "shone",
      hint: "a rock or 14 pounds",
      answer: "stone",
      alteredPosition: 0,
    },
    {
      word: "stone",
      hint: "'Goosebumps' author: R.L. ___",
      answer: "stine",
      alteredPosition: 2,
    },
    {
      word: "stine",
      hint: "smell very bad; reek",
      answer: "stink",
      alteredPosition: 4,
    },
    {
      word: "stink",
      hint: "move stealthily or in a provocative manner",
      answer: "slink",
      alteredPosition: 1,
    },
    {
      word: "slink",
      hint: "slang for prison; a sharp metallic sound",
      answer: "clink",
      alteredPosition: 0,
    },
    {
      word: "clink",
      hint: "___ Eastwood, Hollywood legend",
      answer: "clint",
      alteredPosition: 4,
    },
    {
      word: "clint",
      hint: "a material for making sparks",
      answer: "flint",
      alteredPosition: 0,
    },
    {
      word: "flint",
      hint: "to throw; a brief love affair",
      answer: "fling",
      alteredPosition: 4,
    },
    {
      word: "fling",
      hint: "flashy jewelry and ornamentation on a rapper",
      answer: "bling",
      alteredPosition: 0,
    },
    {
      word: "bling",
      hint: "unable to see",
      answer: "blind",
      alteredPosition: 4,
    },
  ],
  // 5/27/2022 (#66)
  [
    {
      word: "bun",
      hint: "exists until someone has an eye poked",
      answer: "fun",
      alteredPosition: 0,
    },
    {
      word: "fun",
      hint: "hair of certain animals",
      answer: "fur",
      alteredPosition: 2,
    },
    {
      word: "fur",
      hint: "at a distance",
      answer: "far",
      alteredPosition: 1,
    },
    {
      word: "far",
      hint: "cylindrical glass container",
      answer: "jar",
      alteredPosition: 0,
    },
    {
      word: "jar",
      hint: "poke someone quickly",
      answer: "jab",
      alteredPosition: 2,
    },
    {
      word: "jab",
      hint: "occupation",
      answer: "job",
      alteredPosition: 1,
    },
    {
      word: "job",
      hint: "run slowly, steadily",
      answer: "jog",
      alteredPosition: 2,
    },
    {
      word: "jog",
      hint: "don't share something",
      answer: "hog",
      alteredPosition: 0,
    },
    {
      word: "hog",
      hint: "jump on one foot",
      answer: "hop",
      alteredPosition: 2,
    },
    {
      word: "hop",
      hint: "cut the end off quickly",
      answer: "lop",
      alteredPosition: 0,
    },
  ],
  // 5/28/2022 (#67)
  [
    {
      word: "lop",
      hint: "large cut wood piece",
      answer: "log",
      alteredPosition: 2,
    },
    {
      word: "log",
      hint: "fall behind in movement",
      answer: "lag",
      alteredPosition: 1,
    },
    {
      word: "lag",
      hint: "put down gently",
      answer: "lay",
      alteredPosition: 2,
    },
    {
      word: "lay",
      hint: "a broad inlet of the sea by land",
      answer: "bay",
      alteredPosition: 0,
    },
    {
      word: "bay",
      hint: "to officially prohibit something",
      answer: "ban",
      alteredPosition: 2,
    },
    {
      word: "ban",
      hint: "garbage holder",
      answer: "bin",
      alteredPosition: 1,
    },
    {
      word: "bin",
      hint: "a kind of alcohol",
      answer: "gin",
      alteredPosition: 0,
    },
    {
      word: "gin",
      hint: "live performance",
      answer: "gig",
      alteredPosition: 2,
    },
    {
      word: "gig",
      hint: "make hole in ground",
      answer: "dig",
      alteredPosition: 0,
    },
    {
      word: "dig",
      hint: "faint lighting",
      answer: "dim",
      alteredPosition: 2,
    },
  ],
  // 5/29/2022 (#68)
  [
    {
      word: "dim",
      hint: "outer edge of a cup",
      answer: "rim",
      alteredPosition: 0,
    },
    {
      word: "rim",
      hint: "male sheep",
      answer: "ram",
      alteredPosition: 1,
    },
    {
      word: "ram",
      hint: "beam of light",
      answer: "ray",
      alteredPosition: 2,
    },
    {
      word: "ray",
      hint: "expression of triumph",
      answer: "yay",
      alteredPosition: 0,
    },
    {
      word: "yay",
      hint: "deviate from a straight course",
      answer: "yaw",
      alteredPosition: 2,
    },
    {
      word: "yaw",
      hint: "animal's foot with claws",
      answer: "paw",
      alteredPosition: 0,
    },
    {
      word: "paw",
      hint: "touch gently with flat hand",
      answer: "pat",
      alteredPosition: 2,
    },
    {
      word: "pat",
      hint: "metal cookware",
      answer: "pot",
      alteredPosition: 1,
    },
    {
      word: "pot",
      hint: "automated online 'person'",
      answer: "bot",
      alteredPosition: 0,
    },
    {
      word: "bot",
      hint: "young male",
      answer: "boy",
      alteredPosition: 2,
    },
  ],
  // 5/30/2022 (#69)
  [
    {
      word: "boy",
      hint: "object for children to play with",
      answer: "toy",
      alteredPosition: 0,
    },
    {
      word: "toy",
      hint: "2000 lbs.",
      answer: "ton",
      alteredPosition: 2,
    },
    {
      word: "ton",
      hint: "yellowish-brown color",
      answer: "tan",
      alteredPosition: 1,
    },
    {
      word: "tan",
      hint: "street-paving goop",
      answer: "tar",
      alteredPosition: 2,
    },
    {
      word: "tar",
      hint: "organ for your auditory sense",
      answer: "ear",
      alteredPosition: 0,
    },
    {
      word: "ear",
      hint: "consume food",
      answer: "eat",
      alteredPosition: 2,
    },
    {
      word: "eat",
      hint: "cereal plant grain",
      answer: "oat",
      alteredPosition: 0,
    },
    {
      word: "oat",
      hint: "not in",
      answer: "out",
      alteredPosition: 1,
    },
    {
      word: "out",
      hint: "we's possessive",
      answer: "our",
      alteredPosition: 2,
    },
    {
      word: "our",
      hint: "item for steering a gondola",
      answer: "oar",
      alteredPosition: 1,
    },
  ],
  // 5/31/2022 (#70)
  [
    {
      word: "oar",
      hint: "conflict between different nations",
      answer: "war",
      alteredPosition: 0,
    },
    {
      word: "war",
      hint: "a method of doing something",
      answer: "way",
      alteredPosition: 2,
    },
    {
      word: "way",
      hint: "questioning word",
      answer: "why",
      alteredPosition: 1,
    },
    {
      word: "why",
      hint: "'___ can it be now?'",
      answer: "who",
      alteredPosition: 2,
    },
    {
      word: "who",
      hint: "flirt with the intention of love",
      answer: "woo",
      alteredPosition: 1,
    },
    {
      word: "woo",
      hint: "cow sound",
      answer: "moo",
      alteredPosition: 0,
    },
    {
      word: "moo",
      hint: "large disordered crowd",
      answer: "mob",
      alteredPosition: 2,
    },
    {
      word: "mob",
      hint: "cry loudly",
      answer: "sob",
      alteredPosition: 0,
    },
    {
      word: "sob",
      hint: "male child",
      answer: "son",
      alteredPosition: 2,
    },
    {
      word: "son",
      hint: "a religious no-no",
      answer: "sin",
      alteredPosition: 1,
    },
  ],
  // 6/1/2022 (#71)
  [
    {
      word: "truck",
      hint: 'common Halloween phrase: "___ or treat"',
      answer: "trick",
      alteredPosition: 2,
    },
    {
      word: "trick",
      hint: "not thin",
      answer: "thick",
      alteredPosition: 1,
    },
    {
      word: "thick",
      hint: "a young bird",
      answer: "chick",
      alteredPosition: 0,
    },
    {
      word: "chick",
      hint: "one way of paying",
      answer: "check",
      alteredPosition: 2,
    },
    {
      word: "check",
      hint: "fleshy part of the face",
      answer: "cheek",
      alteredPosition: 3,
    },
    {
      word: "cheek",
      hint: "small stream of water",
      answer: "creek",
      alteredPosition: 1,
    },
    {
      word: "creek",
      hint: "socrates or zorba",
      answer: "greek",
      alteredPosition: 0,
    },
    {
      word: "greek",
      hint: "to welcome in a friendly way",
      answer: "greet",
      alteredPosition: 4,
    },
    {
      word: "greet",
      hint: "large in size",
      answer: "great",
      alteredPosition: 3,
    },
    {
      word: "great",
      hint: 'common Halloween phrase: "trick or ___"',
      answer: "treat",
      alteredPosition: 0,
    },
  ],
  // 6/2/2022 (#72)
  [
    {
      word: "beer",
      hint: "my cross to ___",
      answer: "bear",
      alteredPosition: 2,
    },
    {
      word: "bear",
      hint: "papa pig",
      answer: "boar",
      alteredPosition: 1,
    },
    {
      word: "boar",
      hint: "uncouth lout",
      answer: "boor",
      alteredPosition: 2,
    },
    {
      word: "boor",
      hint: "to reserve, like a flight",
      answer: "book",
      alteredPosition: 3,
    },
    {
      word: "book",
      hint: "just walked off with",
      answer: "took",
      alteredPosition: 0,
    },
    {
      word: "took",
      hint: "hammer or screwdriver, e.g.",
      answer: "tool",
      alteredPosition: 3,
    },
    {
      word: "tool",
      hint: "game played on a table with pockets",
      answer: "pool",
      alteredPosition: 0,
    },
    {
      word: "pool",
      hint: "____ richard's almanack",
      answer: "poor",
      alteredPosition: 3,
    },
    {
      word: "poor",
      hint: "what bartenders do",
      answer: "pour",
      alteredPosition: 2,
    },
    {
      word: "pour",
      hint: "not mine",
      answer: "your",
      alteredPosition: 0,
    },
  ],
  // 6/3/2022 (#73)
  [
    {
      word: "trace",
      hint: "neckwear after an injury",
      answer: "brace",
      alteredPosition: 0,
    },
    {
      word: "brace",
      hint: "unlike chickens",
      answer: "brave",
      alteredPosition: 3,
    },
    {
      word: "brave",
      hint: "to long for",
      answer: "crave",
      alteredPosition: 0,
    },
    {
      word: "crave",
      hint: "a short-lived fad",
      answer: "craze",
      alteredPosition: 3,
    },
    {
      word: "craze",
      hint: "to munch on grass",
      answer: "graze",
      alteredPosition: 0,
    },
    {
      word: "graze",
      hint: "a shiny coating",
      answer: "glaze",
      alteredPosition: 1,
    },
    {
      word: "glaze",
      hint: "mark first, as a trail",
      answer: "blaze",
      alteredPosition: 0,
    },
    {
      word: "blaze",
      hint: "to point the finger at",
      answer: "blame",
      alteredPosition: 3,
    },
    {
      word: "blame",
      hint: "blast at full volume",
      answer: "blare",
      alteredPosition: 3,
    },
    {
      word: "blare",
      hint: "to give an angry look",
      answer: "glare",
      alteredPosition: 0,
    },
  ],
  // 6/4/2022 (#74)
  [
    {
      word: "hand",
      hint: "a beach is full of the stuff",
      answer: "sand",
      alteredPosition: 0,
    },
    {
      word: "sand",
      hint: "dropped to the bottom of the ocean",
      answer: "sank",
      alteredPosition: 3,
    },
    {
      word: "sank",
      hint: "land alongside a river",
      answer: "bank",
      alteredPosition: 0,
    },
    {
      word: "bank",
      hint: "to support with funds",
      answer: "back",
      alteredPosition: 2,
    },
    {
      word: "back",
      hint: '"more bang for your ___"',
      answer: "buck",
      alteredPosition: 1,
    },
    {
      word: "buck",
      hint: "upper or lower bed",
      answer: "bunk",
      alteredPosition: 2,
    },
    {
      word: "bunk",
      hint: "play a practical joke on, slangily",
      answer: "punk",
      alteredPosition: 0,
    },
    {
      word: "punk",
      hint: "hardly Herculean",
      answer: "puny",
      alteredPosition: 3,
    },
    {
      word: "puny",
      hint: "pay (up)",
      answer: "pony",
      alteredPosition: 1,
    },
    {
      word: "pony",
      hint: "skeletal",
      answer: "bony",
      alteredPosition: 0,
    },
  ],
  // 6/5/2022 (#75)
  [
    {
      word: "suez",
      hint: "took to small claims court",
      answer: "sued",
      alteredPosition: 3,
    },
    {
      word: "sued",
      hint: "transport down a winter hill",
      answer: "sled",
      alteredPosition: 1,
    },
    {
      word: "sled",
      hint: "said 'guilty' or 'not guilty' in court",
      answer: "pled",
      alteredPosition: 0,
    },
    {
      word: "pled",
      hint: "turned the pool water yellow",
      answer: "peed",
      alteredPosition: 1,
    },
    {
      word: "peed",
      hint: "cheat, during a game of hide & seek",
      answer: "peek",
      alteredPosition: 3,
    },
    {
      word: "peek",
      hint: "onion relative used in soups",
      answer: "leek",
      alteredPosition: 0,
    },
    {
      word: "leek",
      hint: "give a dirty look",
      answer: "leer",
      alteredPosition: 3,
    },
    {
      word: "leer",
      hint: "antelope's playmate in song",
      answer: "deer",
      alteredPosition: 0,
    },
    {
      word: "deer",
      hint: "proof of home ownership",
      answer: "deed",
      alteredPosition: 3,
    },
    {
      word: "deed",
      hint: "plant by a pond",
      answer: "reed",
      alteredPosition: 0,
    },
  ],
  // 6/6/2022 (#76)
  [
    {
      word: "rose",
      hint: "measured portion of medicine",
      answer: "dose",
      alteredPosition: 0,
    },
    {
      word: "dose",
      hint: "bird of peace",
      answer: "dove",
      alteredPosition: 2,
    },
    {
      word: "dove",
      hint: "zero as a tennis score",
      answer: "love",
      alteredPosition: 0,
    },
    {
      word: "love",
      hint: "what players hate to do",
      answer: "lose",
      alteredPosition: 2,
    },
    {
      word: "lose",
      hint: "state or feeling of grief",
      answer: "loss",
      alteredPosition: 3,
    },
    {
      word: "loss",
      hint: "the person in charge",
      answer: "boss",
      alteredPosition: 0,
    },
    {
      word: "boss",
      hint: "weapons used in archery",
      answer: "bows",
      alteredPosition: 2,
    },
    {
      word: "bows",
      hint: "throw or roll (a ball)",
      answer: "bowl",
      alteredPosition: 3,
    },
    {
      word: "bowl",
      hint: "wolf's wail",
      answer: "howl",
      alteredPosition: 0,
    },
    {
      word: "howl",
      hint: "a long, loose hood",
      answer: "cowl",
      alteredPosition: 0,
    },
  ],
  // 6/7/2022 (#77)
  [
    {
      word: "pear",
      hint: "polar, grizzly, e.g.",
      answer: "bear",
      alteredPosition: 0,
    },
    {
      word: "bear",
      hint: "the most basic part of any song; rhythm",
      answer: "beat",
      alteredPosition: 3,
    },
    {
      word: "beat",
      hint: "beef, pork, or mutton",
      answer: "meat",
      alteredPosition: 0,
    },
    {
      word: "meat",
      hint: "castle trench",
      answer: "moat",
      alteredPosition: 1,
    },
    {
      word: "moat",
      hint: "shed one's skin",
      answer: "molt",
      alteredPosition: 2,
    },
    {
      word: "molt",
      hint: "unwanted item on bread or fruit",
      answer: "mold",
      alteredPosition: 3,
    },
    {
      word: "mold",
      hint: "an attitude or emotion",
      answer: "mood",
      alteredPosition: 2,
    },
    {
      word: "mood",
      hint: "product from a tree",
      answer: "wood",
      alteredPosition: 0,
    },
    {
      word: "wood",
      hint: "clothing from a sheep",
      answer: "wool",
      alteredPosition: 3,
    },
    {
      word: "wool",
      hint: "moderately cold; lacking in warmth",
      answer: "cool",
      alteredPosition: 0,
    },
  ],
  // 6/8/2022 (#78)
  [
    {
      word: "cowl",
      hint: "a bird of any kind",
      answer: "fowl",
      alteredPosition: 0,
    },
    {
      word: "fowl",
      hint: "possible strike in baseball",
      answer: "foul",
      alteredPosition: 2,
    },
    {
      word: "foul",
      hint: "'fantastic' number",
      answer: "four",
      alteredPosition: 3,
    },
    {
      word: "four",
      hint: "acidic taste",
      answer: "sour",
      alteredPosition: 0,
    },
    {
      word: "sour",
      hint: "speak indistinctly",
      answer: "slur",
      alteredPosition: 1,
    },
    {
      word: "slur",
      hint: "background of an action shot, often",
      answer: "blur",
      alteredPosition: 0,
    },
    {
      word: "blur",
      hint: "a sad color",
      answer: "blue",
      alteredPosition: 3,
    },
    {
      word: "blue",
      hint: "adhesive",
      answer: "glue",
      alteredPosition: 0,
    },
    {
      word: "glue",
      hint: "surly or depressed",
      answer: "glum",
      alteredPosition: 3,
    },
    {
      word: "glum",
      hint: "an oval fruit also used commonly as a color",
      answer: "plum",
      alteredPosition: 0,
    },
  ],
  // 6/9/2022 (#79)
  [
    {
      word: "leon",
      hint: "element #10; used to make signs glow",
      answer: "neon",
      alteredPosition: 0,
    },
    {
      word: "neon",
      hint: "12:00 p.m.",
      answer: "noon",
      alteredPosition: 1,
    },
    {
      word: "noon",
      hint: "celestial body that is 'made of cheese'",
      answer: "moon",
      alteredPosition: 0,
    },
    {
      word: "moon",
      hint: "low sound induced by physical or mental suffering",
      answer: "moan",
      alteredPosition: 2,
    },
    {
      word: "moan",
      hint: "the average of a set of data",
      answer: "mean",
      alteredPosition: 1,
    },
    {
      word: "mean",
      hint: "lima, kidney, or jumping",
      answer: "bean",
      alteredPosition: 0,
    },
    {
      word: "bean",
      hint: "a Kellogg's cereal: Raisin ____",
      answer: "bran",
      alteredPosition: 1,
    },
    {
      word: "bran",
      hint: "country whose capital is Tehran",
      answer: "iran",
      alteredPosition: 0,
    },
    {
      word: "iran",
      hint: "element #26; metal that was once used to make nails",
      answer: "iron",
      alteredPosition: 2,
    },
    {
      word: "iron",
      hint: "1982 sci-fi film starring jeff bridges",
      answer: "tron",
      alteredPosition: 0,
    },
  ],
  // 6/10/2022 (#80)
  [
    {
      word: "coot",
      hint: "a young male horse",
      answer: "colt",
      alteredPosition: 2,
    },
    {
      word: "colt",
      hint: "like a popsicle or the arctic",
      answer: "cold",
      alteredPosition: 3,
    },
    {
      word: "cold",
      hint: "recently sought after in Tokyo",
      answer: "gold",
      alteredPosition: 0,
    },
    {
      word: "gold",
      hint: "decorate expensively",
      answer: "gild",
      alteredPosition: 1,
    },
    {
      word: "gild",
      hint: "part of a fish",
      answer: "gill",
      alteredPosition: 3,
    },
    {
      word: "gill",
      hint: "self-control",
      answer: "will",
      alteredPosition: 0,
    },
    {
      word: "will",
      hint: "from the jungle",
      answer: "wild",
      alteredPosition: 3,
    },
    {
      word: "wild",
      hint: "air in motion",
      answer: "wind",
      alteredPosition: 2,
    },
    {
      word: "wind",
      hint: "a surface that produces lift for flight",
      answer: "wing",
      alteredPosition: 3,
    },
    {
      word: "wing",
      hint: "an interjection said after a witty insult",
      answer: "zing",
      alteredPosition: 0,
    },
  ],
  // 6/11/2022 (#81)
  [
    {
      word: "gall",
      hint: "Cinderella's big event",
      answer: "ball",
      alteredPosition: 0,
    },
    {
      word: "ball",
      hint: "empty a flooding boat",
      answer: "bail",
      alteredPosition: 2,
    },
    {
      word: "bail",
      hint: "roller coaster track",
      answer: "rail",
      alteredPosition: 0,
    },
    {
      word: "rail",
      hint: "to rummage through the fridge",
      answer: "raid",
      alteredPosition: 3,
    },
    {
      word: "raid",
      hint: "uttered",
      answer: "said",
      alteredPosition: 0,
    },
    {
      word: "said",
      hint: "lose traction on wet pavement",
      answer: "skid",
      alteredPosition: 1,
    },
    {
      word: "skid",
      hint: "largest human organ",
      answer: "skin",
      alteredPosition: 3,
    },
    {
      word: "skin",
      hint: "last cycle on a washer",
      answer: "spin",
      alteredPosition: 1,
    },
    {
      word: "spin",
      hint: "wing-to-wing measurement",
      answer: "span",
      alteredPosition: 2,
    },
    {
      word: "span",
      hint: "long-necked, graceful swimmer",
      answer: "swan",
      alteredPosition: 1,
    },
  ],
  // 6/12/2022 (#82)
  [
    {
      word: "lark",
      hint: "'listen!'",
      answer: "hark",
      alteredPosition: 0,
    },
    {
      word: "hark",
      hint: "short, dry cough",
      answer: "hack",
      alteredPosition: 2,
    },
    {
      word: "hack",
      hint: "pair of antlers",
      answer: "rack",
      alteredPosition: 0,
    },
    {
      word: "rack",
      hint: "speed contest",
      answer: "race",
      alteredPosition: 3,
    },
    {
      word: "race",
      hint: "critic's fabulous review",
      answer: "rave",
      alteredPosition: 2,
    },
    {
      word: "rave",
      hint: "wander",
      answer: "rove",
      alteredPosition: 1,
    },
    {
      word: "rove",
      hint: "magician's bird",
      answer: "dove",
      alteredPosition: 0,
    },
    {
      word: "dove",
      hint: "lavish affection on",
      answer: "dote",
      alteredPosition: 2,
    },
    {
      word: "dote",
      hint: "june 12, e.g.",
      answer: "date",
      alteredPosition: 1,
    },
    {
      word: "date",
      hint: "the duchess of cambridge",
      answer: "kate",
      alteredPosition: 0,
    },
  ],
  // 6/13/2022 (#83)
  [
    {
      word: "fargo",
      hint: "omit or decline to take; refrain from",
      answer: "forgo",
      alteredPosition: 1,
    },
    {
      word: "forgo",
      hint: "shape a metal object by heating in a fire",
      answer: "forge",
      alteredPosition: 4,
    },
    {
      word: "forge",
      hint: "to eat too much",
      answer: "gorge",
      alteredPosition: 0,
    },
    {
      word: "gorge",
      hint: "to severely overcharge",
      answer: "gouge",
      alteredPosition: 2,
    },
    {
      word: "gouge",
      hint: "cosmetic for coloring face",
      answer: "rouge",
      alteredPosition: 0,
    },
    {
      word: "rouge",
      hint: "like sandpaper",
      answer: "rough",
      alteredPosition: 4,
    },
    {
      word: "rough",
      hint: "strong and physically robust",
      answer: "tough",
      alteredPosition: 0,
    },
    {
      word: "tough",
      hint: "fifth sense or doors song '_____ me'",
      answer: "touch",
      alteredPosition: 3,
    },
    {
      word: "touch",
      hint: "mob necessity (along with pitchforks)",
      answer: "torch",
      alteredPosition: 2,
    },
    {
      word: "torch",
      hint: "best occupied by rocking chair and pitcher of iced tea",
      answer: "porch",
      alteredPosition: 0,
    },
  ],
  // 6/14/2022 (#84)
  [
    {
      word: "cape",
      hint: "an adhesive roll, scotch for one",
      answer: "tape",
      alteredPosition: 0,
    },
    {
      word: "tape",
      hint: "a ____ of two cities",
      answer: "tale",
      alteredPosition: 2,
    },
    {
      word: "tale",
      hint: "boy, not girl",
      answer: "male",
      alteredPosition: 0,
    },
    {
      word: "male",
      hint: "5280 feet, unless nautical",
      answer: "mile",
      alteredPosition: 1,
    },
    {
      word: "mile",
      hint: "highly offensive, distasteful",
      answer: "vile",
      alteredPosition: 0,
    },
    {
      word: "vile",
      hint: "a small rodent",
      answer: "vole",
      alteredPosition: 1,
    },
    {
      word: "vole",
      hint: "bottom of the shoe",
      answer: "sole",
      alteredPosition: 0,
    },
    {
      word: "sole",
      hint: "sight for ____ eyes",
      answer: "sore",
      alteredPosition: 2,
    },
    {
      word: "sore",
      hint: "oliver twist's food request",
      answer: "more",
      alteredPosition: 0,
    },
    {
      word: "more",
      hint: "with ice cream: a la ___",
      answer: "mode",
      alteredPosition: 2,
    },
  ],
  // 6/15/2022 (#85)
  [
    {
      word: "pace",
      hint: "liver spread starter",
      answer: "pate",
      alteredPosition: 2,
    },
    {
      word: "pate",
      hint: "failing to meet a deadline",
      answer: "late",
      alteredPosition: 0,
    },
    {
      word: "late",
      hint: "uncool",
      answer: "lame",
      alteredPosition: 2,
    },
    {
      word: "lame",
      hint: "green fruit, consumed with tequila & salt",
      answer: "lime",
      alteredPosition: 1,
    },
    {
      word: "lime",
      hint: "tv shown as it happens",
      answer: "live",
      alteredPosition: 2,
    },
    {
      word: "live",
      hint: `"all you need is ____" (beatles)`,
      answer: "love",
      alteredPosition: 1,
    },
    {
      word: "love",
      hint: "fail to win",
      answer: "lose",
      alteredPosition: 2,
    },
    {
      word: "lose",
      hint: "one of a dozen sent on valentines",
      answer: "rose",
      alteredPosition: 0,
    },
    {
      word: "rose",
      hint: "italians wander here",
      answer: "rome",
      alteredPosition: 2,
    },
    {
      word: "rome",
      hint: "stadium cover",
      answer: "dome",
      alteredPosition: 0,
    },
  ],
  // 6/16/2022 (#86)
  [
    {
      word: "aria",
      hint: "description of an extremely dry environment",
      answer: "arid",
      alteredPosition: 3,
    },
    {
      word: "arid",
      hint: "the pattern found on graph paper",
      answer: "grid",
      alteredPosition: 0,
    },
    {
      word: "grid",
      hint: "courage and resolve; strength of character",
      answer: "grit",
      alteredPosition: 3,
    },
    {
      word: "grit",
      hint: "a way or manner of walking",
      answer: "gait",
      alteredPosition: 1,
    },
    {
      word: "gait",
      hint: "the greek mother of all titans",
      answer: "gaia",
      alteredPosition: 3,
    },
    {
      word: "gaia",
      hint: "an elaborate party or ball",
      answer: "gala",
      alteredPosition: 2,
    },
    {
      word: "gala",
      hint: "an extremely powerful windstorm",
      answer: "gale",
      alteredPosition: 3,
    },
    {
      word: "gale",
      hint: "a door used to pass through a fence or wall",
      answer: "gate",
      alteredPosition: 2,
    },
    {
      word: "gate",
      hint: "to loathe",
      answer: "hate",
      alteredPosition: 0,
    },
    {
      word: "hate",
      hint: "a large relative of the rabbit",
      answer: "hare",
      alteredPosition: 2,
    },
  ],
  // 6/17/2022 (#87)
  [
    {
      word: "crib",
      hint: "pinching crustacean",
      answer: "crab",
      alteredPosition: 3,
    },
    {
      word: "crab",
      hint: "pre-test ritual",
      answer: "cram",
      alteredPosition: 3,
    },
    {
      word: "cram",
      hint: "chowder ingredient",
      answer: "clam",
      alteredPosition: 1,
    },
    {
      word: "clam",
      hint: "animal defense feature",
      answer: "claw",
      alteredPosition: 3,
    },
    {
      word: "claw",
      hint: "a defect or imperfection",
      answer: "flaw",
      alteredPosition: 0,
    },
    {
      word: "flaw",
      hint: "two dimensional",
      answer: "flat",
      alteredPosition: 3,
    },
    {
      word: "flat",
      hint: "impressive accomplishment",
      answer: "feat",
      alteredPosition: 1,
    },
    {
      word: "feat",
      hint: "phobia meaning",
      answer: "fear",
      alteredPosition: 3,
    },
    {
      word: "fear",
      hint: "to rip or shred",
      answer: "tear",
      alteredPosition: 0,
    },
    {
      word: "tear",
      hint: "blue-green shade",
      answer: "teal",
      alteredPosition: 3,
    },
  ],
  // 6/18/2022 (#88)
  [
    {
      word: "hut",
      hint: "a bored worker's routine",
      answer: "rut",
      alteredPosition: 0,
    },
    {
      word: "rut",
      hint: "process of decay",
      answer: "rot",
      alteredPosition: 1,
    },
    {
      word: "rot",
      hint: "long-tailed rodent",
      answer: "rat",
      alteredPosition: 1,
    },
    {
      word: "rat",
      hint: "nemesis of the rat",
      answer: "cat",
      alteredPosition: 0,
    },
    {
      word: "cat",
      hint: "covering for the head",
      answer: "cap",
      alteredPosition: 2,
    },
    {
      word: "cap",
      hint: "the juice of plants",
      answer: "sap",
      alteredPosition: 0,
    },
    {
      word: "sap",
      hint: "express in words",
      answer: "say",
      alteredPosition: 2,
    },
    {
      word: "say",
      hint: "lacking self-confidence",
      answer: "shy",
      alteredPosition: 1,
    },
    {
      word: "shy",
      hint: "possessive case of thou",
      answer: "thy",
      alteredPosition: 0,
    },
    {
      word: "thy",
      hint: "definite article",
      answer: "the",
      alteredPosition: 2,
    },
  ],
  // 6/19/2022 (#89)
  [
    {
      word: "king",
      hint: "having a good or benevolent nature",
      answer: "kind",
      alteredPosition: 3,
    },
    {
      word: "kind",
      hint: "to tie or secure (e.g. with a rope or cord)",
      answer: "bind",
      alteredPosition: 0,
    },
    {
      word: "bind",
      hint: "the early one gets the worm",
      answer: "bird",
      alteredPosition: 2,
    },
    {
      word: "bird",
      hint: "Shakespeare's nickname",
      answer: "bard",
      alteredPosition: 1,
    },
    {
      word: "bard",
      hint: "not clothed or covered; naked",
      answer: "bare",
      alteredPosition: 3,
    },
    {
      word: "bare",
      hint: "not occurring very often",
      answer: "rare",
      alteredPosition: 0,
    },
    {
      word: "rare",
      hint: "a contest of speed (e.g. running or sailing)",
      answer: "race",
      alteredPosition: 2,
    },
    {
      word: "race",
      hint: "grain found in sushi",
      answer: "rice",
      alteredPosition: 1,
    },
    {
      word: "rice",
      hint: "to annoy or irritate someone",
      answer: "rile",
      alteredPosition: 2,
    },
    {
      word: "rile",
      hint: "a heap of things lying on top of one another",
      answer: "pile",
      alteredPosition: 0,
    },
  ],
  // 6/20/2022 (#90)
  [
    {
      word: "vole",
      hint: "morally despicable or foul",
      answer: "vile",
      alteredPosition: 1,
    },
    {
      word: "vile",
      hint: "a tool with two jaws for holding something steady",
      answer: "vise",
      alteredPosition: 2,
    },
    {
      word: "vise",
      hint: "competitor to mastercard or american express",
      answer: "visa",
      alteredPosition: 3,
    },
    {
      word: "visa",
      hint: "coldplay song '___ la vida'",
      answer: "viva",
      alteredPosition: 2,
    },
    {
      word: "viva",
      hint: "popular female singer",
      answer: "diva",
      alteredPosition: 0,
    },
    {
      word: "diva",
      hint: "seedy bar or club",
      answer: "dive",
      alteredPosition: 3,
    },
    {
      word: "dive",
      hint: "to marry a woman",
      answer: "wive",
      alteredPosition: 0,
    },
    {
      word: "wive",
      hint: "eucharist drink",
      answer: "wine",
      alteredPosition: 2,
    },
    {
      word: "wine",
      hint: "common wood with lots of knots and sap",
      answer: "pine",
      alteredPosition: 0,
    },
    {
      word: "pine",
      hint: "red and white make it",
      answer: "pink",
      alteredPosition: 3,
    },
  ],
  // 6/21/2022 (#91)
  [
    {
      word: "mega",
      hint: "informal term for computer storage units",
      answer: "megs",
      alteredPosition: 3,
    },
    {
      word: "megs",
      hint: "the 'm' of ymca",
      answer: "mens",
      alteredPosition: 2,
    },
    {
      word: "mens",
      hint: "repair",
      answer: "mend",
      alteredPosition: 3,
    },
    {
      word: "mend",
      hint: "fermented honey drink",
      answer: "mead",
      alteredPosition: 2,
    },
    {
      word: "mead",
      hint: "part of a rosary",
      answer: "bead",
      alteredPosition: 0,
    },
    {
      word: "bead",
      hint: "Brangelina half",
      answer: "brad",
      alteredPosition: 1,
    },
    {
      word: "brad",
      hint: "one holding a diploma, for short",
      answer: "grad",
      alteredPosition: 0,
    },
    {
      word: "grad",
      hint: "to urge on",
      answer: "goad",
      alteredPosition: 1,
    },
    {
      word: "goad",
      hint: "scoooooore!",
      answer: "goal",
      alteredPosition: 3,
    },
    {
      word: "goal",
      hint: "stocking stuffer for a brat",
      answer: "coal",
      alteredPosition: 0,
    },
  ],
  // 6/22/2022 (#92)
  [
    {
      word: "pac",
      hint: "greek god of nature",
      answer: "pan",
      alteredPosition: 2,
    },
    {
      word: "pan",
      hint: "a play on words",
      answer: "pun",
      alteredPosition: 1,
    },
    {
      word: "pun",
      hint: "a type of dog",
      answer: "pug",
      alteredPosition: 2,
    },
    {
      word: "pug",
      hint: "error in a program",
      answer: "bug",
      alteredPosition: 0,
    },
    {
      word: "bug",
      hint: "carrying assistant",
      answer: "bag",
      alteredPosition: 1,
    },
    {
      word: "bag",
      hint: "video chat disruption",
      answer: "lag",
      alteredPosition: 0,
    },
    {
      word: "lag",
      hint: "actor jude",
      answer: "law",
      alteredPosition: 2,
    },
    {
      word: "law",
      hint: "not high",
      answer: "low",
      alteredPosition: 1,
    },
    {
      word: "low",
      hint: "bathroom in britain",
      answer: "loo",
      alteredPosition: 2,
    },
    {
      word: "loo",
      hint: "bovine utterance",
      answer: "moo",
      alteredPosition: 0,
    },
  ],
  // 6/23/2022 (#93)
  [
    {
      word: "slip",
      hint: "pancake manipulation",
      answer: "flip",
      alteredPosition: 0,
    },
    {
      word: "flip",
      hint: "inelegant dive",
      answer: "flop",
      alteredPosition: 2,
    },
    {
      word: "flop",
      hint: "wing maneuver",
      answer: "flap",
      alteredPosition: 2,
    },
    {
      word: "flap",
      hint: "show appreciation",
      answer: "clap",
      alteredPosition: 0,
    },
    {
      word: "clap",
      hint: "horse hoof sound",
      answer: "clop",
      alteredPosition: 2,
    },
    {
      word: "clop",
      hint: "agricultural plant",
      answer: "crop",
      alteredPosition: 1,
    },
    {
      word: "crop",
      hint: "raven relative",
      answer: "crow",
      alteredPosition: 3,
    },
    {
      word: "crow",
      hint: "a group of workers, such as on a ship",
      answer: "crew",
      alteredPosition: 2,
    },
    {
      word: "crew",
      hint: "to enjoy a piece of gum",
      answer: "chew",
      alteredPosition: 1,
    },
    {
      word: "chew",
      hint: "professional cook",
      answer: "chef",
      alteredPosition: 3,
    },
  ],
  // 6/24/2022 (#94)
  [
    {
      word: "crue",
      hint: "crime-solving hint",
      answer: "clue",
      alteredPosition: 1,
    },
    {
      word: "clue",
      hint: "color associated with law enforcement",
      answer: "blue",
      alteredPosition: 0,
    },
    {
      word: "blue",
      hint: "become visually indistinct",
      answer: "blur",
      alteredPosition: 3,
    },
    {
      word: "blur",
      hint: "an insulting or disparaging remark",
      answer: "slur",
      alteredPosition: 0,
    },
    {
      word: "slur",
      hint: "the opposite of sweet",
      answer: "sour",
      alteredPosition: 1,
    },
    {
      word: "sour",
      hint: `"Chicken Soup for the __" (inspirational book series)`,
      answer: "soul",
      alteredPosition: 3,
    },
    {
      word: "soul",
      hint: "to stain or defile morally",
      answer: "soil",
      alteredPosition: 2,
    },
    {
      word: "soil",
      hint: "to become agitated, seethe",
      answer: "boil",
      alteredPosition: 0,
    },
    {
      word: "boil",
      hint: "money paid to secure one's release",
      answer: "bail",
      alteredPosition: 1,
    },
    {
      word: "bail",
      hint: "where one may go after conviction",
      answer: "jail",
      alteredPosition: 0,
    },
  ],
  // 6/25/2022 (#95)
  [
    {
      word: "lap",
      hint: "baseball hat",
      answer: "cap",
      alteredPosition: 0,
    },
    {
      word: "cap",
      hint: "policeman",
      answer: "cop",
      alteredPosition: 1,
    },
    {
      word: "cop",
      hint: "where the cream rises",
      answer: "top",
      alteredPosition: 0,
    },
    {
      word: "top",
      hint: "infant",
      answer: "tot",
      alteredPosition: 2,
    },
    {
      word: "tot",
      hint: "tit for __",
      answer: "tat",
      alteredPosition: 1,
    },
    {
      word: "tat",
      hint: "has 9 lives",
      answer: "cat",
      alteredPosition: 0,
    },
    {
      word: "cat",
      hint: "a simple bed",
      answer: "cot",
      alteredPosition: 1,
    },
    {
      word: "cot",
      hint: "clockwork gear",
      answer: "cog",
      alteredPosition: 2,
    },
    {
      word: "cog",
      hint: "swamp",
      answer: "bog",
      alteredPosition: 0,
    },
    {
      word: "bog",
      hint: "suitcase",
      answer: "bag",
      alteredPosition: 1,
    },
  ],
  // 6/26/2022 (#96)
  [
    {
      word: "cab",
      hint: "baby bear",
      answer: "cub",
      alteredPosition: 1,
    },
    {
      word: "cub",
      hint: "massage",
      answer: "rub",
      alteredPosition: 0,
    },
    {
      word: "rub",
      hint: "stuck in a __ (drab routine)",
      answer: "rut",
      alteredPosition: 2,
    },
    {
      word: "rut",
      hint: "sewer rodent",
      answer: "rat",
      alteredPosition: 1,
    },
    {
      word: "rat",
      hint: "tiger family",
      answer: "cat",
      alteredPosition: 0,
    },
    {
      word: "cat",
      hint: "old tv technology (acronym)",
      answer: "crt",
      alteredPosition: 1,
    },
    {
      word: "crt",
      hint: "shed tears",
      answer: "cry",
      alteredPosition: 2,
    },
    {
      word: "cry",
      hint: "not wet",
      answer: "dry",
      alteredPosition: 0,
    },
    {
      word: "dry",
      hint: "any of seven in a week",
      answer: "day",
      alteredPosition: 1,
    },
    {
      word: "day",
      hint: `"I just finished the puzzle!" exclamation`,
      answer: "yay",
      alteredPosition: 0,
    },
  ],
  // 6/27/2022 (#97)
  [
    {
      word: "form",
      hint: "college residence",
      answer: "dorm",
      alteredPosition: 0,
    },
    {
      word: "dorm",
      hint: "unhappy destiny",
      answer: "doom",
      alteredPosition: 2,
    },
    {
      word: "doom",
      hint: "equipment for weaving",
      answer: "loom",
      alteredPosition: 0,
    },
    {
      word: "loom",
      hint: "what properly tied laces make",
      answer: "loop",
      alteredPosition: 3,
    },
    {
      word: "loop",
      hint: "chicken's home",
      answer: "coop",
      alteredPosition: 0,
    },
    {
      word: "coop",
      hint: "what a farmer plants",
      answer: "crop",
      alteredPosition: 1,
    },
    {
      word: "crop",
      hint: "raven-like bird",
      answer: "crow",
      alteredPosition: 3,
    },
    {
      word: "crow",
      hint: "grub or eats",
      answer: "chow",
      alteredPosition: 1,
    },
    {
      word: "chow",
      hint: "performance or display",
      answer: "show",
      alteredPosition: 0,
    },
    {
      word: "show",
      hint: "generic covering for feet",
      answer: "shoe",
      alteredPosition: 3,
    },
  ],
  // 6/28/2022 (#98)
  [
    {
      word: "hulu",
      hint: "a famous hawaiian style of dance",
      answer: "hula",
      alteredPosition: 3,
    },
    {
      word: "hula",
      hint: "the spanish word for 'hello'",
      answer: "hola",
      alteredPosition: 1,
    },
    {
      word: "hola",
      hint: "the type of carbonated beverage pepsi and coke are known for",
      answer: "cola",
      alteredPosition: 0,
    },
    {
      word: "cola",
      hint: "a young male horse, or gun manufacturer",
      answer: "colt",
      alteredPosition: 3,
    },
    {
      word: "colt",
      hint: "to shed an outer layer periodically, such as skin or a shell",
      answer: "molt",
      alteredPosition: 0,
    },
    {
      word: "molt",
      hint: "a grain often used in brewing alcohol",
      answer: "malt",
      alteredPosition: 1,
    },
    {
      word: "malt",
      hint: "a more authoritative way of staying 'stop!'",
      answer: "halt",
      alteredPosition: 0,
    },
    {
      word: "halt",
      hint: "the handle of a sword or dagger",
      answer: "hilt",
      alteredPosition: 1,
    },
    {
      word: "hilt",
      hint: "what happens when you're too rough with the pinball machine",
      answer: "tilt",
      alteredPosition: 0,
    },
    {
      word: "tilt",
      hint: "a thin piece of ceramic flooring",
      answer: "tile",
      alteredPosition: 3,
    },
  ],
  // 6/29/2022 (#99)
  [
    {
      word: "wolf",
      hint: "game with birdies and eagles",
      answer: "golf",
      alteredPosition: 0,
    },
    {
      word: "golf",
      hint: "error",
      answer: "goof",
      alteredPosition: 2,
    },
    {
      word: "goof",
      hint: "well-behaved",
      answer: "good",
      alteredPosition: 3,
    },
    {
      word: "good",
      hint: "something prepared by wolfgang puck",
      answer: "food",
      alteredPosition: 0,
    },
    {
      word: "food",
      hint: "like some memories",
      answer: "fond",
      alteredPosition: 2,
    },
    {
      word: "fond",
      hint: "super spy also known as 007",
      answer: "bond",
      alteredPosition: 0,
    },
    {
      word: "bond",
      hint: "bell sound",
      answer: "bong",
      alteredPosition: 3,
    },
    {
      word: "bong",
      hint: "a ceremonial noisemaker",
      answer: "gong",
      alteredPosition: 0,
    },
    {
      word: "gong",
      hint: "group of friends, informally",
      answer: "gang",
      alteredPosition: 1,
    },
    {
      word: "gang",
      hint: "fashion designer, Vera ___",
      answer: "wang",
      alteredPosition: 0,
    },
  ],
  // 6/30/2022 (#100)
  [
    {
      word: "wing",
      hint: "riesling, merlot e.g.",
      answer: "wine",
      alteredPosition: 3,
    },
    {
      word: "wine",
      hint: "knowledgeable",
      answer: "wise",
      alteredPosition: 2,
    },
    {
      word: "wise",
      hint: "make this before blowing out the candles",
      answer: "wish",
      alteredPosition: 3,
    },
    {
      word: "wish",
      hint: "something prepared by wolfgang puck",
      answer: "dish",
      alteredPosition: 0,
    },
    {
      word: "dish",
      hint: "quick race",
      answer: "dash",
      alteredPosition: 1,
    },
    {
      word: "dash",
      hint: "violent blow",
      answer: "bash",
      alteredPosition: 0,
    },
    {
      word: "bash",
      hint: "german baroque composer",
      answer: "bach",
      alteredPosition: 2,
    },
    {
      word: "bach",
      hint: "sir mix-a-lot's 'baby got ____'",
      answer: "back",
      alteredPosition: 3,
    },
    {
      word: "back",
      hint: "load up the suitcase",
      answer: "pack",
      alteredPosition: 0,
    },
    {
      word: "pack",
      hint: "hockey disk",
      answer: "puck",
      alteredPosition: 1,
    },
  ],
  [
    {
      word: "dire",
      hint: "what a dragon breathes",
      answer: "fire",
      alteredPosition: 0,
    },
    {
      word: "fire",
      hint: "thing in a folder",
      answer: "file",
      alteredPosition: 2,
    },
    {
      word: "file",
      hint: "to use all available space",
      answer: "fill",
      alteredPosition: 3,
    },
    {
      word: "fill",
      hint: "to grind grain into flour",
      answer: "mill",
      alteredPosition: 0,
    },
    {
      word: "mill",
      hint: "least spicy salsa",
      answer: "mild",
      alteredPosition: 3,
    },
    {
      word: "mild",
      hint: "fuzz on food",
      answer: "mold",
      alteredPosition: 1,
    },
    {
      word: "mold",
      hint: "first place medal",
      answer: "gold",
      alteredPosition: 0,
    },
    {
      word: "gold",
      hint: "to prod annoyingly",
      answer: "goad",
      alteredPosition: 2,
    },
    {
      word: "goad",
      hint: "to put clothes into a washing machine",
      answer: "load",
      alteredPosition: 0,
    },
    {
      word: "load",
      hint: "the star role of a movie or play",
      answer: "lead",
      alteredPosition: 1,
    },
  ],
  [
    {
      word: "bare",
      hint: "cost of a bus ride",
      answer: "fare",
      alteredPosition: 0,
    },
    {
      word: "fare",
      hint: "widespread reputation",
      answer: "fame",
      alteredPosition: 2,
    },
    {
      word: "fame",
      hint: "as before",
      answer: "same",
      alteredPosition: 0,
    },
    {
      word: "same",
      hint: "not all and not none",
      answer: "some",
      alteredPosition: 1,
    },
    {
      word: "some",
      hint: "book with some weight to it",
      answer: "tome",
      alteredPosition: 0,
    },
    {
      word: "tome",
      hint: "what a watch displays",
      answer: "time",
      alteredPosition: 1,
    },
    {
      word: "time",
      hint: "movement of seawater",
      answer: "tide",
      alteredPosition: 2,
    },
    {
      word: "tide",
      hint: "opposite of narrow",
      answer: "wide",
      alteredPosition: 0,
    },
    {
      word: "wide",
      hint: "owl descriptor",
      answer: "wise",
      alteredPosition: 2,
    },
    {
      word: "wise",
      hint: "express a desire for",
      answer: "wish",
      alteredPosition: 3,
    },
  ],
  [
    {
      word: "sega",
      hint: "lengthy story",
      answer: "saga",
      alteredPosition: 1,
    },
    {
      word: "saga",
      hint: "turkey seasoning herb",
      answer: "sage",
      alteredPosition: 3,
    },
    {
      word: "sage",
      hint: "of sound mind",
      answer: "sane",
      alteredPosition: 2,
    },
    {
      word: "sane",
      hint: "lion's hair",
      answer: "mane",
      alteredPosition: 0,
    },
    {
      word: "mane",
      hint: "not yours",
      answer: "mine",
      alteredPosition: 1,
    },
    {
      word: "mine",
      hint: "speak your __ (be forthright)",
      answer: "mind",
      alteredPosition: 3,
    },
    {
      word: "mind",
      hint: "moving air",
      answer: "wind",
      alteredPosition: 0,
    },
    {
      word: "wind",
      hint: "finishes in first place",
      answer: "wins",
      alteredPosition: 3,
    },
    {
      word: "wins",
      hint: "mental ability, astuteness",
      answer: "wits",
      alteredPosition: 2,
    },
    {
      word: "wits",
      hint: "accompanied by",
      answer: "with",
      alteredPosition: 3,
    },
  ],
  [
    {
      word: "lark",
      hint: "place to have a picnic",
      answer: "park",
      alteredPosition: 3,
    },
    {
      word: "park",
      hint: "the other white meat",
      answer: "pork",
      alteredPosition: 1,
    },
    {
      word: "pork",
      hint: "employment",
      answer: "work",
      alteredPosition: 0,
    },
    {
      word: "work",
      hint: "fish bait",
      answer: "worm",
      alteredPosition: 3,
    },
    {
      word: "worm",
      hint: "less than hot",
      answer: "warm",
      alteredPosition: 1,
    },
    {
      word: "warm",
      hint: "large hospital room",
      answer: "ward",
      alteredPosition: 3,
    },
    {
      word: "ward",
      hint: `Shakespeare's nickname, with "the"`,
      answer: "bard",
      alteredPosition: 0,
    },
    {
      word: "bard",
      hint: "Twitter logo",
      answer: "bird",
      alteredPosition: 1,
    },
    {
      word: "bird",
      hint: "secure by tying",
      answer: "bind",
      alteredPosition: 2,
    },
    {
      word: "bind",
      hint: "007",
      answer: "bond",
      alteredPosition: 1,
    },
  ],
  // 7/24/2022 (#124)
  [
    {
      word: "lout",
      hint: "it might get ____",
      answer: "loud",
      alteredPosition: 3,
    },
    {
      word: "loud",
      hint: "____ of the rings",
      answer: "lord",
      alteredPosition: 2,
    },
    {
      word: "lord",
      hint: "folk knowledge",
      answer: "lore",
      alteredPosition: 3,
    },
    {
      word: "lore",
      hint: "tackle box item",
      answer: "lure",
      alteredPosition: 1,
    },
    {
      word: "lure",
      hint: "bard's guitar",
      answer: "lute",
      alteredPosition: 2,
    },
    {
      word: "lute",
      hint: "silent",
      answer: "mute",
      alteredPosition: 0,
    },
    {
      word: "mute",
      hint: "canine with a questionable lineage",
      answer: "mutt",
      alteredPosition: 3,
    },
    {
      word: "mutt",
      hint: "not optional",
      answer: "must",
      alteredPosition: 2,
    },
    {
      word: "must",
      hint: "fair & equitable",
      answer: "just",
      alteredPosition: 0,
    },
    {
      word: "just",
      hint: "joke",
      answer: "jest",
      alteredPosition: 1,
    },
  ],
];
