export const horsePuzzle = [
  {
    word: "horse",
    hint: "Ferrari's iconic badge",
    answer: "horse",
    alteredPosition: 0,
  },
  {
    word: "horse",
    hint: "a raced animal",
    answer: "horse",
    alteredPosition: 1,
  },
  {
    word: "horse",
    hint: "an equine beast",
    answer: "horse",
    alteredPosition: 2,
  },
  {
    word: "horse",
    hint: "a knight's animal",
    answer: "horse",
    alteredPosition: 3,
  },
  {
    word: "horse",
    hint: "carousel creature",
    answer: "horse",
    alteredPosition: 4,
  },
  {
    word: "horse",
    hint: "preindustrial transport",
    answer: "horse",
    alteredPosition: 3,
  },
  {
    word: "horse",
    hint: "what a sore throat is",
    answer: "horse",
    alteredPosition: 2,
  },
  {
    word: "horse",
    hint: "hoops game whose name is spelled out",
    answer: "horse",
    alteredPosition: 1,
  },
  {
    word: "horse",
    hint: "Preakness participant",
    answer: "horse",
    alteredPosition: 0,
  },
  {
    word: "horse",
    hint: "horse",
    answer: "horse",
    alteredPosition: 1,
  },
];
