const fourData = {
  "word": [
    "Clue",
    "\"You said it!,\" in slang",
    "\"You got that right!\"",
    "\"You said it!\"",
    "\"I agree,\" in slang",
    "With 72-Across, what the answers on this puzzle's perimeter form",
    "What a definition defines",
    "Conversation piece?",
    "Popular Microsoft product",
    "Kind of ladder exemplified by the answers to the seven starred clues",
    "Promise",
    "Dictionary unit",
    "Promise",
    "News",
    "Promise",
    "With 33-Across, anagrams and puns (or parts hidden in 17-, 24-, 44- and 51-Across)",
    "Basic dictionary entry",
    "Microsoft product",
    "Scrabble formation",
    "Popular Microsoft software",
    "Scrabble play",
    "Promise",
    "News",
    "Promise",
    "News"
  ],
  "wage": [
    "Living ___",
    "$15/hour, e.g.",
    "Pay",
    "Amount an employee earns",
    "$15/hour, maybe",
    "Salary",
    "Living ___",
    "Engage in",
    "Conduct",
    "Hourly compensation",
    "Scale amount",
    "With 111-Across, do battle",
    "Employee's pay",
    "Worker's pay",
    "Hourly pay",
    "Carry on",
    "Carry on",
    "Worker's pay",
    "Living ___ (what an employer is asked to pay)",
    "It may be minimum",
    "Worker's due",
    "Worker's pay",
    "Minimum ___",
    "Worker's compensation",
    "Carry on",
    "Hourly pay",
    "What a worker earns",
    "Carry on",
    "Hourly pay",
    "Carry on",
    "Carry on",
    "Carry on",
    "Carry on",
    "Carry on, as a campaign",
    "Carry on",
    "\"Minimum\" amount",
    "\"Minimum\" amount",
    "Carry on",
    "Carry on",
    "Carry on",
    "Salary"
  ],
  "feta": [
    "Some crumbly blocks",
    "Cheese in a Greek salad",
    "Crumbly salad cheese",
    "Cheese in a spanakopita",
    "Cheese in moussaka",
    "Greek cheese",
    "Crumbly salad topper",
    "Crumbly Greek cheese",
    "Crumbly cheese in a Greek salad",
    "White pizza topping",
    "Tangy Greek cheese",
    "Ingredient in spanakopita",
    "Brine-cured cheese",
    "Crumbly salad ingredient",
    "Ingredient in a spanakopita pastry",
    "Sheep's milk product",
    "Greek salad ingredient",
    "Crumbly cheese",
    "Spanakopita ingredient",
    "Greek cheese",
    "Greek cheese",
    "Cheese often served with olives",
    "Cheese in moussaka",
    "Greek salad topper",
    "Salty salad topping",
    "Mediterranean pizza topping",
    "Cheese in spinach pies",
    "Product of Greek culture?",
    "Salad cheese",
    "Greek salad component",
    "Crumbly topping",
    "Greek salad staple",
    "___ cheese",
    "Something in a crumbled state in Greece?",
    "Cheese in a Greek salad",
    "Crumbly cheese",
    "Tiropita ingredient",
    "Greek cheese",
    "Crumbly cheese",
    "Goat cheese",
    "Greek cheese",
    "Greek salad ingredient",
    "Topping on Mediterranean pizza",
    "Greek salad ingredient",
    "Kind of cheese",
    "Greek salad ingredient",
    "Cheese in salads",
    "Greek salad cheese",
    "Salad cheese",
    "Greek cheese",
    "Greek salad cheese",
    "Food item whose name means \"slice\"",
    "Crumbly salad topping",
    "Big cheese in Greece",
    "Greek salad topping",
    "Crumbly white cheese",
    "Soft, white cheese",
    "Cheese variety",
    "Greek cheese",
    "Greek cheese",
    "Greek deli purchase",
    "Greek cheese",
    "Cheese from sheep's milk",
    "Greek cheese",
    "Greek salad ingredient",
    "Greek salad ingredient",
    "Goat cheese",
    "Greek cheese",
    "Goat's-milk cheese",
    "White cheese",
    "Goat's milk product",
    "Salad cheese",
    "Goat cheese",
    "White cheese",
    "Greek salad topping",
    "Chunks in a Greek salad"
  ],
  "able": [
    "Up to it",
    "Fit for the task",
    "Partner of willing",
    "Up to it",
    "Up to the task",
    "Having the means",
    "Opposite of inept",
    "___-bodied",
    "Qualified",
    "Partner of ready and willing",
    "Beginning of the Joint Army/Navy Phonetic Alphabet",
    "Up to it",
    "Up to the task",
    "Having the resources",
    "Cutting it / Mediterranean island",
    "Partner of ready and willing",
    "Practiced",
    "Up to it",
    "Up to the task",
    "Up to the task",
    "Up to snuff",
    "Up to the task",
    "Like most seamen, supposedly",
    "Qualified",
    "Up to the job",
    "Up to the task",
    "Pub vessel",
    "Ready, willing and ___",
    "Competent",
    "Fit for the job",
    "___-bodied",
    "Up to it",
    "Proficient",
    "Good at one's job",
    "Hacking it",
    "Having what it takes",
    "It becomes its own synonym when \"cap\" is added in front",
    "Skillful",
    "Competent",
    "Up to the job",
    "___-bodied",
    "\"___ to leap tall buildings ...\"",
    "Up to the job",
    "Up to the job",
    "Cutting it",
    "Sufficiently skilled",
    "Up to snuff",
    "Up to the job",
    "Up to the task",
    "Like some seamen",
    "Qualified",
    "Equal to the task",
    "Skilled",
    "Hardly inept",
    "Like Napoleon, before Elba?",
    "Baker's predecessor",
    "Up to the task",
    "Skilled",
    "Cut out for it",
    "Competent",
    "Up to the challenge",
    "___-minded",
    "Practiced",
    "Qualified",
    "Talented",
    "Competent",
    "Fit",
    "Fit",
    "Up to the task",
    "Up to the task",
    "Competent",
    "Having the knack",
    "Proficient",
    "Up to the task",
    "Good at one's job",
    "Qualified",
    "Adept",
    "See 93-Across",
    "Proficient",
    "Up to the job",
    "Up to the task",
    "Fit",
    "Word that keeps the same meaning if \"cap-\" is added at the front",
    "Competent",
    "Up to the task",
    "Having the know-how",
    "Fit",
    "Fit",
    "Proficient",
    "Up to the job",
    "Up to it",
    "Up to the task",
    "Up to the job",
    "Well-suited",
    "Up to the task",
    "___-bodied seaman",
    "Au fait",
    "Competent",
    "Up to it",
    "Fully fit",
    "___-bodied",
    "Up to the task",
    "Having the right stuff",
    "Having the resources",
    "Fit for duty",
    "Fully functional",
    "Up to snuff",
    "Up to the task",
    "Au fait",
    "Experienced",
    "Fit",
    "Up to the task",
    "Competent",
    "Fit for the job",
    "Competent",
    "Fit",
    "___-bodied",
    "Equipped",
    "Fit",
    "Skilled",
    "Talented",
    "Partner of ready and willing",
    "Good enough to 2-Down",
    "Up to it",
    "Fit",
    "Competent",
    "Good at one's job",
    "Fit",
    "Cutting the mustard",
    "Competent",
    "Up to it",
    "Up to snuff",
    "Skilled",
    "Fit",
    "Competent",
    "Highly adroit",
    "Baker's predecessor",
    "Competent",
    "Fully fit",
    "Up to it",
    "Competent",
    "Qualified",
    "Fit",
    "In a position to help",
    "Up to the job",
    "Fit",
    "Fit",
    "Up to the task",
    "Up to snuff",
    "Fully qualified",
    "Up to the task",
    "Talented",
    "Qualified",
    "Fit",
    "Practiced",
    "Au fait",
    "Skillful",
    "Cutting the mustard",
    "Skilled",
    "Empowered",
    "Talented",
    "Skilled",
    "Competent",
    "Up to the task",
    "Masterful",
    "Up to the task",
    "Up to speed",
    "Fit",
    "Not impotent",
    "Au fait",
    "Up to the task",
    "Strong of body and mind",
    "Fully fit",
    "Skilled",
    "Competent",
    "Up to the task",
    "Functioning in all respects",
    "Up to the task",
    "Proficient",
    "Seaman's description",
    "Qualified",
    "Skilled",
    "Up to it",
    "Efficient",
    "Equipped",
    "Ready and willing's partner",
    "Accomplished",
    "Skilled",
    "Equipped",
    "\"___ to leap tall buildings...\"",
    "Competent",
    "Permitted",
    "Up to the task",
    "Up to the task",
    "Skillful",
    "Fit",
    "Ready and willing's partner",
    "Qualified",
    "Skillful",
    "Up to it",
    "Qualified",
    "Au fait",
    "Up to the task",
    "Practiced",
    "Skillful",
    "___ bodied seaman",
    "Skilled",
    "Proficient",
    "Competent",
    "Competent",
    "Fit",
    "Having the power",
    "Willing follower?",
    "Up to the job",
    "Having the stuff",
    "Competent",
    "Fit",
    "Efficient",
    "Deft",
    "Up to snuff",
    "Up to the task",
    "Kind of seaman",
    "Up to snuff"
  ],
  "moor": [
    "Heath",
    "Heathland",
    "Dock",
    "Secure, as a ship",
    "Secure at shore",
    "Secure",
    "Othello, e.g.",
    "Othello, for one",
    "Tie up, as a ship",
    "Tie up",
    "Boggy wasteland",
    "Secure, as a ship",
    "Secure",
    "Anchor",
    "Make fast, in a way",
    "Tie up",
    "Medieval invader of Spain",
    "Othello, for one",
    "Shrubby wasteland",
    "Tie up, as a boat",
    "Tie up, as a ship",
    "\"The Hound of the Baskervilles\" setting",
    "Othello, for one",
    "\"The Hound of the Baskervilles\" backdrop",
    "Heath",
    "Othello, e.g.",
    "Othello, for one",
    "Tie up, in a way",
    "Tie up",
    "El Cid foe",
    "Cornwall feature",
    "Shrubby wasteland",
    "Drop anchor",
    "What boats may do in an inlet",
    "Anchor",
    "\"The Hound of the Baskervilles\" setting",
    "Heath",
    "Drop anchor",
    "Heath",
    "Secure",
    "Secure",
    "Drop anchor",
    "Anchor",
    "Foe for El Cid",
    "Othello, for one",
    "El Cid foe",
    "Othello was one",
    "Secure",
    "Secure",
    "Drop anchor",
    "Poor drainage area",
    "Anchor",
    "Fix firmly",
    "\"The Hound of the Baskervilles\" locale",
    "Resting place",
    "Secure a ship",
    "Secure",
    "Othello, e.g.",
    "Secure, in a way",
    "Othello, e.g.",
    "Othello, e.g.",
    "Marston ___ (1644 battle site)",
    "Heath",
    "Othello, e.g."
  ],
  "muni": [
    "S.F. metro",
    "Certain bond, for short",
    "Certain tax-free investment, for short",
    "Tax-exempt bond, for short",
    "Certain bond, for short",
    "Tax-free bond, for short",
    "Tax-free bond, briefly",
    "Bond that's often tax-free, for short",
    "San Francisco's public transit system, with \"the\"",
    "Bond variety, briefly",
    "Bond type, briefly",
    "Tax-exempt investment, for short",
    "\"Scarface\" star, 1932",
    "City bond, for short",
    "Local bond, familiarly",
    "Tax-free bond, briefly",
    "Certain bond, slangily",
    "Tax-exempt bond, briefly",
    "Certain bond, informally",
    "Bond type, briefly",
    "Certain bond, for short",
    "Certain bond, informally",
    "Certain bond, informally",
    "Tax-free bond, briefly",
    "Pasteur portrayer Paul",
    "Certain bond, for short",
    "Oscar-winning Pasteur portrayer",
    "?ôScarface?ö star, 1932",
    "Certain bond, informally",
    "Actor Paul",
    "City bond, for short"
  ],
  "psst": [
    "\"Hey ... over here!\"",
    "\"Hey ... you!\"",
    "\"Hey, over here!\"",
    "[Hey, over here!]",
    "\"Hey, over here!\"",
    "Vowelless attention-getter",
    "Quiet summons",
    "\"Hey, over here!\"",
    "\"Wanna hear a secret?\" preceder",
    "\"Over here!\"",
    "Alternative to an elbow poke",
    "\"Hey, over here!\"",
    "Attention getter",
    "\"Hey, you!\"",
    "\"Hey, pal, over here!\"",
    "Surreptitious sound during an exam",
    "\"Hey! Over here!\"",
    "[Over here!]",
    "\"Hey, buddy!\"",
    "\"Hey ... over here!\"",
    "Discreet \"Hey!\"",
    "\"Yo - check this out!\"",
    "[Over here!]",
    "\"Hey!\"",
    "Verbal alternative to a shoulder tap",
    "Covert come-on",
    "\"Hey!\"",
    "\"Hey! Over here!\"",
    "\"Hey, over here!\"",
    "It's often spoken with one hand at the edge of one's mouth",
    "Tip-off for an exam proctor",
    "\"Hey you!\"",
    "[Over here!]",
    "Discreet attention-getter",
    "\"Hey!\"",
    "Alternative to an elbow, maybe",
    "Furtive \"Hey, you!\"",
    "\"Hey you!\"",
    "Sound from a would-be test cheater",
    "Sound heard by an exam proctor, say",
    "Alternative to a tap",
    "\"Hey ... over here!\"",
    "Covert \"Hey!\"",
    "Sound from a cheater",
    "Sound before passing a note in class",
    "\"Hey, buddy!\"",
    "[You over there!]",
    "\"Hey, you!\"",
    "\"Hey, mister\"",
    "Call in place of a nudge",
    "Subtle attention-getter",
    "\"Hey you!\"",
    "\"Hey, buddy!\"",
    "It's attention-getting",
    "\"Hey, I've got a secret ...\"",
    "Relative of \"Hey, ma\"",
    "Test cheater's sound",
    "Alternative to an elbow",
    "[Hey, buddy!]",
    "Furtive attention-getter",
    "Head-turner",
    "Cheater's sound, maybe",
    "Sibilant \"Hey!\"",
    "\"Hey ... over here!\"",
    "\"Hey!\"",
    "\"Hey! Over here!\"",
    "Hushed \"Wanna hear something?!\"",
    "\"Hey!\"",
    "Nudge alternative",
    "[Hey, buddy!]",
    "\"Hey you!\"",
    "\"Hey ... over here!\"",
    "<small>\"Hey!\"</small>",
    "Cheater's whisper",
    "Whispered attention-getter",
    "Alternative to being tapped",
    "Secret indicator",
    "Head-turning sound",
    "It may precede a tip",
    "Hushed \"Hey!\"",
    "\"Hey there!\"",
    "Cheater's utterance",
    "Tip preceder, maybe",
    "[Over here!]",
    "It's attention-getting",
    "[Nudge]",
    "\"Over here ...\"",
    "Whispered message lead-in",
    "\"Hey there!\"",
    "\"Hey, over here!\"",
    "\"Hey, you!\"",
    "Discreet call",
    "Discreet attention-getter",
    "\"Hey, over here!\"",
    "... a cheater in class",
    "Covert sound",
    "\"Hey, over here!\"",
    "Cheater's sound during a test",
    "\"Hey ... over here!\"",
    "Alternative to \"Hey!\"",
    "\"Hey there!\"",
    "\"Hey!\"",
    "\"Hey, you!\"",
    "\"Over here\"",
    "\"Hey, buddy!\"",
    "Verbal elbow?",
    "Confidential warning",
    "Head-turner",
    "Sound from a test cheater, say",
    "\"Hey, you!\"",
    "Attention-getter",
    "Low-key \"Hey!\"",
    "\"Hey ? you!\"",
    "\"Over here\"",
    "Sound before \"Your fly's open\"",
    "Head-turner",
    "\"Hey, over here!\"",
    "\"Hey!\"",
    "Alternative to 50-Across",
    "Furtive summons",
    "Attention getter",
    "\"Hey ... over here!\"",
    "\"Over here!\"",
    "\"You, there\"",
    "Way to get noticed",
    "\"Hey!\"",
    "\"Hey, over here!\"",
    "Fence?Æs utterance",
    "\"Hey, you!\"",
    "Sound from an exam cheater",
    "\"Hey, you!\"",
    "\"Hey, buddy\"",
    "\"Check this out!\"",
    "[Look over here!]",
    "\"Hey you!\"",
    "It may turn some heads",
    "It precedes \"Over here!\"",
    "\"Hey!\"",
    "\"Hey there!\"",
    "\"Hey!\"",
    "Verbal nudge",
    "Head-turner",
    "Attention-getter",
    "Cousin of \"ahem\"",
    "Attention-getter",
    "Attention getter",
    "\"Hey, you!\"",
    "\"Hey, you!\"",
    "\"Hey you!\"",
    "\"Hey, there!\"",
    "\"Hey!\"",
    "\"Look over here!\"",
    "\"Over here!\"",
    "\"Check this out!\"",
    "\"Hey, you!\"",
    "\"Hey ... over here!\"",
    "\"Hey, you\"",
    "\"Hey, you!\"",
    "Attention-getter",
    "\"Hey!\"",
    "Attention getter",
    "\"Wanna hear a secret?\"",
    "\"Hey!\"",
    "\"Hey, there!\"",
    "\"Hey, bud!\"",
    "\"Hey. You. Yeah, you!\"",
    "Attention-getter",
    "Call to attention",
    "Sound before \"Yeah, you!\"",
    "\"Hey, Mac!\"",
    "Whispered call",
    "\"Hey there!\"",
    "\"Hey, you!\"",
    "Attention-getter",
    "\"Hey, you!\"",
    "Call to attention",
    "\"Want to hear a secret?\"",
    "\"Hey, you!\"",
    "\"Hey you!\"",
    "\"Over here!\"",
    "\"Hey, you!\"",
    "\"Hey, you!\"",
    "Fence's opening, perhaps",
    "\"Ahem!\""
  ],
  "odea": [
    "Classic concert chambers",
    "Ancient halls",
    "Old-fashioned theaters",
    "Ancient theaters",
    "Ancient theaters",
    "Theaters of antiquity",
    "Ancient Greek theaters",
    "Old-fashioned theaters",
    "Ancient Greek theaters",
    "Some ancient halls",
    "Ancient theaters",
    "Ancient concert halls",
    "Drama halls",
    "Some ancient halls",
    "Concert halls",
    "Ancient concert halls",
    "Old music halls",
    "Greek theaters",
    "Ancient theaters",
    "Concert halls of old",
    "Concert halls",
    "Ancient theaters",
    "Concert venues",
    "Concert halls",
    "Old music halls",
    "Ancient theaters",
    "Concert halls",
    "Old Greek theaters",
    "Old-fashioned music halls",
    "Classic theaters",
    "Old music halls",
    "Concert venues",
    "Greek theaters",
    "Old music halls",
    "Concert halls",
    "Ancient theaters",
    "Old music halls",
    "Music halls",
    "Concert halls",
    "Music halls",
    "Greek theaters",
    "Ancient Greek theaters",
    "Old-fashioned theaters",
    "Ancient concert halls",
    "Old music halls",
    "Concert halls",
    "Concert halls",
    "Ancient theaters",
    "Old Greek theaters",
    "Theaters",
    "Concert sites",
    "Roman music halls",
    "Concert halls",
    "Ancient theaters",
    "Halls of music",
    "Greek concert sites",
    "Concert sites",
    "Ancient theaters",
    "Concert halls",
    "Theaters",
    "Theaters",
    "Classical theaters",
    "Old Greek theaters"
  ],
  "pope": [
    "See captain?",
    "Peter or Paul, but not Mary",
    "Head of the Holy See",
    "One whose job prospects go up in smoke?",
    "One of seven in 14th-century Avignon",
    "Person whose Twitter handle is @Pontifex",
    "User of the Twitter handle @Pontifex",
    "Innocent, perhaps",
    "Alexander who wrote \"The Dunciad\"",
    "Vatican V.I.P.",
    "Leader in white",
    "Word before or after Alexander",
    "Vicar of Christ, more familiarly",
    "John or Paul, but not Ringo",
    "Historic resignee of 2013",
    "World leader with an eponymous \"mobile\"",
    "St. Peter was the first",
    "Francis, for one",
    "Source of the line \"Hope springs eternal ...\"",
    "One of five Nicholases",
    "John, Paul or John Paul",
    "\"Pastorals\" poet",
    "Writer who popularized the saying \"To err is human, to forgive divine\"",
    "Venerated visitor",
    "Wearer of a triple tiara",
    "The Swiss Guards guard him",
    "Poet who wrote \"Hope springs eternal in the human breast\"",
    "Bishop of Rome",
    "Successor of St. Peter",
    "Ring of the Fisherman wearer",
    "Poet who wrote \"To err is human ?à\"",
    "Wearer of a famous ring",
    "Bishop of Rome",
    "Castel Gandolfo resident",
    "\"An Essay on Criticism\" essayist",
    "Tiara wearer",
    "St. Peter was the first",
    "\"Fools rush in where angels fear to tread\" writer",
    "It can precede or follow Alexander",
    "The Swiss Guards guard him",
    "\"An Essay on Criticism\" writer",
    "Swift contemporary",
    "Bishop of Rome",
    "John or Paul but not George or Ringo",
    "John Paul II, e.g.",
    "\"The Dunciad\" poet",
    "Bishop of Rome",
    "St. Peter's Square figure",
    "Innocent, e.g.",
    "\"Essay on Man\" author",
    "\"Windsor Forest\" poet",
    "Vicar of Christ",
    "Pontiff",
    "John, Paul or John Paul",
    "John Paul, e.g."
  ],
  "shah": [
    "Bygone sovereign",
    "Former Iranian leader",
    "Old Iranian leader",
    "Bygone potentate",
    "Bollywood's ___ Rukh Khan",
    "Ousted Iranian leader",
    "___ Jahan, leader who commissioned the Taj Mahal",
    "Ousted Iranian ruler",
    "Persian word from which \"chess\" comes",
    "Political figure granted asylum by Anwar Sadat",
    "Onetime leader of Iran",
    "1979 exile",
    "Bygone title",
    "Ruler deposed in 1979",
    "___ Jahan, leader who commissioned the Taj Mahal",
    "Persian leader",
    "Exiled leader of 1979",
    "Former Iranian leader",
    "Leader from the House of Pahlavi",
    "Noted exile of 1979",
    "Onetime royal",
    "Onetime Iranian leader",
    "Ruler's title from which the word \"chess\" is derived",
    "Exiled leader of Iran",
    "Former Iranian ruler",
    "Exile of 1979",
    "Ruler toppled in 1979",
    "World leader in 1979 headlines",
    "Pre-ayatollah leader",
    "Notable 1979 exile",
    "Ayatollah predecessor",
    "Deposed leader of 1979",
    "Bygone head of Iran",
    "Old Iranian leader",
    "1970s exile",
    "Ruler deposed in 1979",
    "Deposed Iranian ruler",
    "Bygone sovereign",
    "Persian monarch",
    "Any member of the Safavid dynasty",
    "Onetime Asian autocrat",
    "Onetime C.I.A.-backed foreign leader",
    "Deposed Iranian",
    "Bygone potentate",
    "Ayatollah's predecessor",
    "Ruler toppled in 1979",
    "Bygone monarch",
    "Literally, \"king\"",
    "Former Iranian ruler",
    "Bygone royal",
    "Persian for \"king\"",
    "Persian potentate",
    "Mideast ruler",
    "Old Iranian ruler",
    "Head of old Iran",
    "Who ran Iran, once",
    "Leader's name that's etymologically related to \"chess\"",
    "Ruler exiled in 1979",
    "Former ruler of Iran",
    "Onetime throne occupier",
    "Old Iranian leader",
    "Deposed king",
    "Asian autocrat of old",
    "He ran from Iran",
    "Pahlavi, for one",
    "Iranian \"king\"",
    "Former Iranian leader",
    "Bygone title",
    "Ex-leader of Iran",
    "Peacock Throne occupant",
    "Bygone royal",
    "Overthrown leader",
    "India's ___ Jahan",
    "Deposed leader",
    "Ex-head of Iran",
    "Onetime Islamic leader",
    "Bygone leader",
    "Overthrown Iranian leader",
    "Exile of 1979",
    "Old potentate",
    "___ Jahan (Taj Mahal builder)",
    "Bygone ruler",
    "Bygone Mideast leader",
    "Exile of 1979",
    "Onetime Iranian chief",
    "1979 exile",
    "___ of Iran",
    "Mideast ruler of years past",
    "Monarch until 1979",
    "1979 exile",
    "Bygone leader",
    "Persian pooh-bah",
    "Bygone leader",
    "Bygone ruler",
    "Persian pooh-bah",
    "Former potentate",
    "Iranian chief, once",
    "Ayatollah preceder",
    "Toppled leader of 1979"
  ],
  "thon": [
    "Dance-a-___",
    "End of some charity events?",
    "Fund-raising suffix",
    "Fund-raising suffix",
    "Ending with tele-",
    "Ending with tele-",
    "Fund-raising suffix",
    "Fund-raising suffix"
  ],
  "aver": [
    "State to be the case",
    "Declare",
    "Claim confidently",
    "State",
    "Declare",
    "State as fact",
    "State firmly",
    "State as fact",
    "Say for certain",
    "Allege",
    "State",
    "State firmly",
    "State",
    "Profess",
    "Say with conviction",
    "More than conjecture",
    "State firmly",
    "Contend",
    "State firmly",
    "State confidently",
    "State with emphasis",
    "State definitively",
    "Profess",
    "Swear",
    "Not equivocate about",
    "State",
    "Declare",
    "State",
    "Maintain",
    "Say for sure",
    "State boldly",
    "More than suggest",
    "Declare",
    "Swear",
    "Swear",
    "Contend",
    "Asseverate",
    "Declare",
    "Declare",
    "State forcefully",
    "State",
    "State firmly",
    "More than conjecture",
    "Declare",
    "Assert as a fact",
    "State",
    "Profess",
    "Certify",
    "Maintain",
    "Swear",
    "Make known",
    "Profess",
    "Maintain",
    "State as fact",
    "Say with conviction",
    "Declare",
    "Confirm",
    "Declare",
    "State firmly",
    "Avouch",
    "Swear",
    "State",
    "Not just surmise",
    "Maintain",
    "State with conviction",
    "Allege",
    "Declare",
    "Assert",
    "Maintain",
    "Testify",
    "State",
    "State categorically",
    "Affirm",
    "Declare",
    "Maintain",
    "State unequivocally",
    "Swear to",
    "Maintain",
    "Swear to",
    "Profess",
    "Assert",
    "Swear to",
    "State firmly",
    "Claim",
    "State confidently",
    "State firmly",
    "Swear",
    "Maintain",
    "State",
    "Hold",
    "Maintain",
    "Claim",
    "Maintain",
    "Swear to",
    "State confidently",
    "Maintain",
    "Say it's so",
    "Affirm",
    "Maintain",
    "Profess",
    "Posit positively",
    "Say for sure",
    "Assert",
    "Say confidently",
    "Affirm positively",
    "Profess",
    "Declare",
    "Swear",
    "Express",
    "Declare",
    "Say so",
    "Declare with certainty",
    "Declare",
    "Allege",
    "Say positively",
    "Assert",
    "Allege",
    "Declare firmly",
    "Maintain",
    "Confirm",
    "Allege as fact",
    "Say so",
    "Allege as fact",
    "Profess",
    "Profess",
    "Declare",
    "State",
    "Profess",
    "Say it's so",
    "Swear",
    "State",
    "Say it's so",
    "Declare with confidence",
    "Say for sure",
    "Maintain",
    "Declare as fact",
    "Swear",
    "Maintain",
    "Say it's so",
    "State firmly",
    "Declare positively",
    "Maintain",
    "Affirm confidently",
    "Say so",
    "Have one's say",
    "Maintain",
    "Affirm",
    "Allege",
    "State",
    "Swear up and down",
    "Allege",
    "Assert",
    "Profess",
    "Declare",
    "Give a deposition",
    "Say it's so",
    "Insist",
    "State",
    "State",
    "Declare",
    "State categorically",
    "Maintain",
    "Maintain",
    "Affirm",
    "Declare positively",
    "Maintain",
    "Maintain",
    "Express",
    "Back up, in a way",
    "Swear",
    "Affirm",
    "Maintain",
    "Declare"
  ],
  "daze": [
    "Stun",
    "Stupor",
    "Stunned state",
    "Groggy state",
    "Foggy condition",
    "Stupefy",
    "Flabbergast",
    "Clouded condition",
    "Leave stumbling",
    "Knock silly",
    "Muddle",
    "Knock silly",
    "Flabbergast",
    "Muddlement",
    "Flabbergasted state",
    "Flabbergast",
    "Knock for a loop",
    "Whacked-out mental state",
    "More than surprise",
    "Make woozy",
    "Stun",
    "Stupefy",
    "Fog",
    "Stun",
    "Bowl over",
    "Knock silly",
    "Stun",
    "Stupefy",
    "Dumbfound",
    "Muddleheadedness",
    "Stupefy",
    "Stupefy",
    "Fog",
    "Moonstruck state",
    "Punchy state",
    "Stun",
    "Stupefy",
    "Stun",
    "Bewilder",
    "Stun",
    "Stupor",
    "Stupor",
    "Knock for a loop",
    "Muddle",
    "Almost knock out",
    "Flabbergast",
    "Stupefy",
    "Stun"
  ],
  "shot": [
    "Critical remark",
    "Flu fighter",
    "Espresso unit",
    "Photographed",
    "Drinking game penalty, perhaps",
    "Completely ruined",
    "Kablooey",
    "Completely busted",
    "Opportunity",
    "See 52-Across",
    "Key word #4",
    "Whiskey purchase",
    "Opportunity",
    "Cappuccino unit",
    "With 30-Down, hit dead-on",
    "Opportunity",
    "Opportunity",
    "Drink to throw back",
    "Kaput",
    "Attempt",
    "Chance to win",
    "Amount in a whiskey glass",
    "Photographed",
    "It may precede a chaser",
    "Photographed",
    "Gun blast",
    "Attempt at a basket",
    "Exhausted",
    "Ready to be junked",
    "Pic",
    "Whiskey order",
    "Gun blast",
    "Attempt to score",
    "Title chance",
    "Ruined",
    "With 40A, a V.I.P.",
    "Kerflooey",
    "Flu season protection",
    "Opportunity",
    "In bad condition",
    "Wrecked",
    "Muzzleloader's load",
    "Photographed",
    "Part of a boilermaker",
    "Filmed",
    "Kind of putter",
    "Totally gone",
    "Ruined",
    "Opportunity",
    "Pellets",
    "Completely worn out",
    "Whisky amount",
    "Opportunity",
    "Attempt",
    "With 60-Down, Summer Olympics event",
    "Goal attempt",
    "Kaput",
    "Worn out",
    "Worn out",
    "Exhausted",
    "Whiskey glass",
    "Ruined, slangily",
    "Worn-out",
    "Ruined",
    "Bullet-riddled",
    "Type of gun"
  ],
  "aims": [
    "Purposes",
    "Strives (to)",
    "Designs",
    "Goals",
    "Goals",
    "Points",
    "Goals",
    "Goals",
    "List for the forward-thinking",
    "Goals",
    "Focuses",
    "Prepares to shoot",
    "Intentions",
    "Goals",
    "Objectives",
    "Gets ready to shoot",
    "Sites",
    "Uses cross hairs",
    "Intentions",
    "Uses a riflescope",
    "Objectives",
    "Intends (to)",
    "Intentions",
    "Prepares to fire",
    "Prepares to shoot a gun",
    "Goals",
    "Prepares to be discharged",
    "Motivators",
    "Intentions",
    "Ends",
    "Tries to ensure a hit",
    "Aligns the crosshairs",
    "Gets ready to shoot",
    "Targets",
    "Points",
    "Ends",
    "Objects",
    "Sights",
    "Targets",
    "Ends",
    "Points",
    "Prepares to shoot",
    "Sights",
    "Goals",
    "Sightings",
    "Dreams",
    "Eyes a bull's-eye, say",
    "Intentions",
    "Goals",
    "Sights",
    "Points (at)",
    "Prepares for a shoot",
    "Employs a scope",
    "Destinations",
    "Directs",
    "Prepares to fire",
    "Gets ready to fire",
    "Intentions",
    "Focuses",
    "Uses a scope"
  ],
  "ames": [
    "Silicon Valley's ___ Research Center",
    "Iowa college town",
    "___ Building, Boston's first skyscraper",
    "Midwest center of agricultural research",
    "Iowa State city",
    "Iowa State's home",
    "City north of Des Moines",
    "___ Research Center",
    "College town in Iowa",
    "Iowa college city that isn't Iowa City",
    "College town of George Washington Carver",
    "City that's home to Iowa State",
    "College city on I-35",
    "NASA's ___ Research Center",
    "Big 12 college town",
    "Iowa college town",
    "NASA's ___ Research Center",
    "City north of Des Moines",
    "Aldrich who was a spy for the K.G.B.",
    "City in the Midwest's Story County",
    "Big 12 college town",
    "Iowa college town",
    "Actor Leon of \"The Postman Always Rings Twice\"",
    "Midwest college town",
    "Iowa college town",
    "NASA's ___ Research Center",
    "Home of a Big 12 school",
    "Midwest college town",
    "Hawkeye State city",
    "College town in Iowa",
    "City of central Iowa",
    "NASA's ___ Research Center",
    "Iowa State locale",
    "Spy Aldrich",
    "Home to Iowa State",
    "Home of the Big 12's Cyclones",
    "Iowa college town",
    "NASA's ___ Research Center",
    "___ Straw Poll",
    "Iowa State's city",
    "Traitor Aldrich",
    "Iowa State's home",
    "NASA's ___ Research Center",
    "Iowa city",
    "Subject of the 2012 book \"Circle of Treason\"",
    "Double agent Aldrich",
    "Traitorous Aldrich",
    "___ Straw Poll",
    "Midwest college town",
    "___ Research Center (NASA lab in Silicon Valley)",
    "City north of Des Moines",
    "Home of a Big 12 school",
    "Presidential straw poll city",
    "Ed with the 1967 hit \"My Cup Runneth Over\"",
    "Columnist and graphic novelist Jonathan",
    "Spy Aldrich",
    "NASA's ___ Research Center",
    "Iowa home of the Cyclones",
    "C.I.A. betrayer arrested in 1994",
    "Where the Iowa Straw Poll is done",
    "Home of Iowa State University",
    "Iowa college town",
    "Home of 14-Down",
    "Ed with the 1967 hit \"My Cup Runneth Over\"",
    "___ Building, first skyscraper in Boston",
    "University town near Des Moines",
    "C.I.A. betrayer Aldrich",
    "Actor Ed of \"Daniel Boone\"",
    "Bob ___, young man in Dreiser's \"Sister Carrie\"",
    "Skunk River city",
    "NASA's ___ Research Center",
    "___ Brothers, who sang \"Rag Mop\"",
    "Iowa State's town",
    "Rachel of \"General Hospital\"",
    "Traitor Aldrich ___",
    "Iowa college town",
    "Actor Leon of \"Life With Father\"",
    "Singing brothers' name",
    "NASA's ___ Research Center",
    "Iowa State's home",
    "Iowa State's home",
    "Iowa State city",
    "Skunk River city",
    "Double agent Aldrich",
    "Treasonous Aldrich",
    "Skunk River city",
    "\"Daniel Boone\" actor",
    "Infamous Aldrich",
    "Home of Iowa State",
    "NASA's ___ Research Center",
    "NASA's ___ Research Center",
    "Ed who sang \"My Cup Runneth Over\"",
    "Traitorous Aldrich",
    "Ed of \"Daniel Boone\"",
    "Harry of the novel \"The Man Who Cried I Am\"",
    "City on the Skunk River",
    "Home of Iowa State",
    "Colonial almanac compiler Nathaniel",
    "Iowa State site",
    "Cathy ___, \"East of Eden\" wife",
    "Home of Iowa State",
    "Home of Iowa State",
    "With 61-Down, a Big 12 campus city",
    "Singing family name",
    "\"Rag Mop\" singers the ___ Brothers",
    "Spy Aldrich",
    "Iowa State site",
    "Leon of \"Mister Ed\"",
    "Big Twelve Conference city",
    "Spy Aldrich",
    "City southeast of Fort Dodge",
    "City on the Skunk",
    "Home of Iowa State",
    "Skunk River city",
    "Spy Aldrich ___",
    "City half an hour north of Des Moines",
    "Site of Iowa State",
    "University town west of Cedar Rapids",
    "Home of the Cyclones",
    "Home of Iowa State",
    "Cathy ___, \"East of Eden\" wife",
    "___ Brothers of 40's-50's music",
    "Home of the Cyclones",
    "Iowa State University site",
    "Cathy of \"East of Eden\"",
    "Early American orator Fisher ___",
    "Home of the N.C.A.A.'s Cyclones",
    "City on the Skunk",
    "Reformer Jessie",
    "Spy in 1994 headlines",
    "Brothers' name of 40's-50's music",
    "City on the Skunk River",
    "Spy in '94 headlines",
    "Iowa State's locale",
    "Traitor Aldrich",
    "\"My Cup Runneth Over\" singer",
    "The___Brothers (40's-50's group)",
    "Iowa college town",
    "Iowa college town",
    "Ed of Mingo fame",
    "Midwest university town",
    "City north of Des Moines",
    "Iowa city",
    "Infamous name in spying",
    "Ed of \"Daniel Boone\"",
    "___ Brothers (\"Rag Mop\" quartet)",
    "Brothers of song",
    "End of Stepquote",
    "Iowa State's home",
    "Singer Ed",
    "Home of Iowa State",
    "City on the Skunk River",
    "Iowa university town",
    "Old-time actor Leon",
    "\"Rag Mop\" brothers",
    "City north of Des Moines",
    "Mingo portrayer",
    "Ed of \"Daniel Boone\""
  ],
  "loud": [
    "F, in music",
    "Like neon colors",
    "Composer's \"forte\"",
    "Very bright, but not in a good way",
    "Earsplitting",
    "Thundering",
    "Booming",
    "Like Hawaiian shirts",
    "Forte, on a score",
    "Eardrum-busting",
    "Opposite of quiet",
    "Attention-grabbing",
    "Forte",
    "Earsplitting",
    "Deafening",
    "Turned up",
    "Earsplitting",
    "Obstreperous",
    "Easily picked up, say",
    "Ear-busting",
    "Eardrum-busting",
    "Like Hawaiian shirts",
    "Cell phone setting",
    "Window-rattling",
    "Garish",
    "Garish",
    "Brightly colored",
    "Clear's partner",
    "Blaring",
    "Garish",
    "Obstreperous",
    "Plangent",
    "Noisy",
    "Forte",
    "Musical forte?"
  ],
  "nerd": [
    "Fluent speaker of Elvish, say",
    "\"Jobs vs. Gates: The Hippie and the ___\" (2015 TV movie)",
    "Lead-in to core or fest",
    "One who's able to rattle off digits of pi, perhaps",
    "Sort represented by the &#x1f913; emoji",
    "Homework lover, say",
    "One who knows all the answers, maybe",
    "Brainy sort",
    "Spend all weekend solving crosswords, say, with \"out\"",
    "Superbrainy sort",
    "Wonkish sort",
    "One probably not with the jocks at the lunch table",
    "Put-down that nowadays may be worn as a badge of honor",
    "Get excited about crosswords, say, with \"out\"",
    "Homework lover, maybe",
    "Jerry Lewis's \"Nutty Professor\" was an early example of one",
    "Jock's antithesis",
    "Brainiac, stereotypically",
    "Do 10 crosswords in a row, say, with \"out\"",
    "Socially maladroit sort",
    "Dorkmeister",
    "Almost any character on \"The Big Bang Theory\"",
    "One scoring 100% on Sporcle quizzes, say",
    "Geek",
    "One whose favorite website is Sporcle, say",
    "With 77-Across, when combined into one word, national trivia championship, e.g.",
    "Filmdom's Napoleon Dynamite, for one",
    "Napoleon Dynamite, e.g.",
    "Hardly Mr. Cool",
    "Stereotypical Mensan",
    "Mathlete, stereotypically",
    "Weenie",
    "Bookworm, maybe",
    "Pi Day celebrant, perhaps",
    "Stereotypical techie",
    "Swot : Britain :: ___ : America",
    "Brainy person, and proud of it",
    "Square figure",
    "Unlikely prom king",
    "Unlikely prom king",
    "Uncool sort",
    "Dweeb",
    "Poindexter type",
    "Lover of brain games",
    "Anyone who can speak Klingon, e.g.",
    "Quiz bowl lover, say",
    "Creature in Dr. Seuss's \"If I Ran the Zoo\"",
    "Unlikely prom king",
    "Square type",
    "Dweeby sort",
    "Many a character on \"The Big Bang Theory\"",
    "High-school put-down",
    "One who might celebrate Pi Day",
    "Dorky sort",
    "Brainiac's put-down",
    "Jock's counterpart",
    "Square type",
    "Square",
    "Guy with his nose always stuck in a book",
    "Geek",
    "Social misfit",
    "Bully's target, maybe",
    "Unlikely party animal",
    "\"Happy Days\" put-down",
    "Studious sort, and proud of it",
    "Geeky guy",
    "Eggheady sort",
    "Whom a bully may bully",
    "Overly bookish sort",
    "Uncool sort",
    "Encyclopedia reader from A to Z, say",
    "Mathlete, stereotypically",
    "Dweeb",
    "Uncool sort",
    "Unlikely prom king",
    "Geeky sort",
    "Hardly Mr. Cool",
    "Geeky sort",
    "Typical Rick Moranis film role",
    "Social bungler",
    "One who?Æs unhip",
    "Dork",
    "Square",
    "Dweeb",
    "Socially challenged person",
    "Social misfit",
    "Computer ___",
    "Doofus",
    "Bully's prey, traditionally",
    "Unhip person",
    "Role in many teen flicks",
    "Dweeb",
    "Dweeb",
    "Unpopular sort",
    "Bully's prey, often",
    "Doofus-y sort",
    "Dweeb",
    "Dateless sort",
    "Mr. Uncool",
    "Square",
    "Propeller-head",
    "Unlikely class president",
    "Socially challenged person",
    "Unlikely candidate for prom king",
    "Unlikely candidate for prom king",
    "Person who gets picked on",
    "Computer ___",
    "Internet addict, perhaps",
    "One who isn't in",
    "Uncool sort",
    "Dilbert, e.g.",
    "Overly brainy sort",
    "This may be hard to date",
    "One who's socially clueless",
    "Uncool one",
    "Hardly Mr. Cool",
    "Hardly a hipster",
    "Social misfit",
    "Brainy, socially inept sort",
    "Uncool sort",
    "Disappointing date, maybe",
    "Bookworm",
    "Overly bookish sort",
    "Dweeb",
    "Dweeb",
    "Taped eyeglasses wearer",
    "Square",
    "Square, updated",
    "One socially challenged",
    "Eggheady sort",
    "Dork",
    "Teen outcast",
    "Dweeb",
    "Uncool fellow",
    "One who's not in",
    "One who's socially challenged",
    "Dweeb",
    "One who's not in",
    "Hardly Mr. Cool",
    "Common butt of jokes",
    "Wonk, maybe"
  ],
  "pepe": [
    "Nickname for José",
    "Nicolas ___, standout player in soccer's Premier League",
    "Jose, to amigos",
    "Stinky Le Pew",
    "Pet form of Jose",
    "First name of a cartoon 33-Across",
    "___ the King Prawn (Muppet in \"Muppets Tonight\")",
    "Le Pew of Looney Tunes",
    "Odorous Le Pew",
    "___ Le Pew (cartoon skunk)",
    "Amorous cartoon skunk",
    "Pet form of Jose",
    "Lustful Le Pew",
    "Stinky Le Pew",
    "Cartoon skunk Le Pew",
    "Bill : William :: ___ : Jose",
    "Jose, to friends",
    "Penelope's pursuer in Looney Tunes toons",
    "Nickname for Jose",
    "___ Le Pew of cartoons",
    "Jose, to friends",
    "Amorous skunk in cartoons",
    "Cartoon skunk",
    "Skunk ___ Le Pew",
    "Le Pew of cartoons",
    "Tio ___ (sherry brand)",
    "Cartoon skunk, to amis",
    "Nickname for Jose",
    "Love-crazy Le Pew",
    "Jose, to friends",
    "Le Pew of cartoons",
    "Pet form of Jose",
    "Classic French film \"___ Le Moko\"",
    "___ Le Pew",
    "Pet name for Jose",
    "Amorous skunk Le Pew",
    "Spanish Philip, informally",
    "Cartoon skunk Le Pew",
    "___ Le Pew",
    "\"___ Le Moko\" (1937 Duvivier drama)",
    "\"___ Le Moko\" (1937 movie)",
    "Cartoon skunk ___ Le Pew",
    "___ Le Pew",
    "\"___ Le Moko\" 1937 film",
    "Skunk LePew",
    "___ Le Pew"
  ],
  "vile": [
    "Horrid",
    "Foul",
    "Despicable",
    "Deplorably bad",
    "Repellent",
    "Repulsive",
    "Repulsive",
    "Word that becomes its own synonym if the last letter is moved to the front",
    "Revolting",
    "Noxious",
    "Disgusting-tasting",
    "Base",
    "Loathsome",
    "Flagitious",
    "Depraved",
    "Reprehensible",
    "Repulsive",
    "Nasty",
    "Repellent",
    "Loathsome",
    "Offensive",
    "Awful-tasting",
    "Filthy",
    "Repellent",
    "Foul",
    "Hardly honorable",
    "Objectionable",
    "Revolting",
    "Reprehensible",
    "Repulsive",
    "Wretched",
    "Base"
  ],
  "roue": [
    "Profligate sort",
    "Philanderer",
    "Dissolute man",
    "Lecherous person",
    "Lord Byron, notably, in his personal life",
    "Dissolute sort",
    "Licentious sort",
    "Licentious man",
    "Hedonistic sort",
    "Dissolute man",
    "Marquis de Sade, e.g.",
    "Playboy",
    "Philanderer",
    "Rake",
    "Lascivious sort",
    "Rake",
    "Rake",
    "Womanizer",
    "Don Juan type",
    "Smooth operator",
    "See 110-Down",
    "Lothario",
    "Player/preyer",
    "He's less than a gentleman",
    "Don Juan type",
    "Rakish sort",
    "Pleasure seeker",
    "Womanizer",
    "Wolf",
    "Bounder",
    "Playboy",
    "Casanova",
    "Womanizer",
    "Immoralist",
    "Lecherous sort",
    "Wolf",
    "Don Juan, for one",
    "Womanizer",
    "Womanizer",
    "Playboy",
    "Philandering sort",
    "Lecher",
    "Ladies' man",
    "Don Juan, e.g.",
    "Libertine",
    "Rake",
    "Rakehell",
    "Rake",
    "Casanova type",
    "Debauchee",
    "Profligate",
    "Libertine",
    "Philandering fellow",
    "No celibate, he",
    "Rake",
    "Debaucher",
    "Casanova",
    "Don Juan",
    "Libertine",
    "Libertine",
    "Debaucher",
    "Libertine",
    "Womanizer",
    "Bounder",
    "Rake",
    "Lecher",
    "Sensualist",
    "Rakehell",
    "Rake"
  ],
  "ohms": [
    "Measures of electrical resistance",
    "Resistance units",
    "Resistance figures",
    "Units of electrical resistance",
    "&Omega;",
    "Units equivalent to volts per ampere",
    "Speaker units",
    "Units of resistance",
    "Units of resistance",
    "Omegas represent them",
    "Electrical units",
    "Units of resistance",
    "Resistance units",
    "___ law",
    "___ law",
    "___ law",
    "___ law (physics formulation)",
    "Resistance units",
    "___ law (electricity principle)",
    "Electrical units",
    "___ law",
    "Units of electrical resistance",
    "___ law",
    "___ law",
    "Resistance units",
    "Omegas represent them",
    "Resistance units",
    "Resistor measures",
    "Resistance units",
    "Resistance units",
    "Resistance units",
    "___ law",
    "___ law",
    "Units of resistance",
    "___ law",
    "Electrical units",
    "___ law",
    "Resistance units",
    "Units of resistance",
    "___ law (i = v/r)",
    "Resistance units",
    "___ law (1840's discovery)",
    "___ law, in physics",
    "___ law (i=v/r)",
    "___law (principle of electricity)",
    "___law (1827 discovery)",
    "___ law (electricity principle)",
    "Resistance units",
    "Resistance units",
    "___law (rule of electricity)",
    "Resistance figures"
  ],
  "idee": [
    "Concept, in Cannes",
    "___ fixe",
    "___ fixe",
    "___ fixe",
    "___ fixe (preoccupation)",
    "\"Bonne ___!\"",
    "Nice thought?",
    "___ fixe",
    "Thought about France",
    "___ fixe",
    "Image mentale",
    "Pensee product",
    "___ fixe",
    "Flash d'inspiration",
    "Notion: Fr.",
    "What will the French think of next?",
    "Starting point for un inventeur",
    "Light bulb over one's tete?",
    "___ fixe",
    "French brainchild",
    "___ fixe",
    "___ fixe",
    "What comes to a head of France?",
    "Bit of ingeniosite",
    "Starting point for un inventeur",
    "Extract from a French bean?",
    "___ fixe (persistent thought)",
    "Penseur's thought",
    "___ fixe",
    "What Descartes thought of?",
    "___ fixe",
    "What the French think?",
    "What a Frenchman thinks?",
    "French abstraction",
    "___ fixe (obsession)",
    "French noodle product?",
    "___ fixe",
    "___ fixe (obsession)",
    "___ fixe",
    "Creation of un philosophe",
    "___ fixe (obsession)",
    "Calais concept",
    "___ fixe (obsession)",
    "___ fixe (obsession)",
    "Thought about the Rhone",
    "It comes from une tete",
    "Inventeur's need",
    "___ fixe (obsession)",
    "___ fixe",
    "___ fixe",
    "It comes from a French bean",
    "What did de Gaulle think?",
    "___ fixe",
    "___ fixe",
    "Concept, in Quebec",
    "Nantes notion",
    "Starting point for Pasteur",
    "___ fixe",
    "Notion in Normandy",
    "___ fixe (obsession)",
    "___ fixe",
    "Descartes \"product\"",
    "Concept for Claudette",
    "Nice notion",
    "It popped into Descartes's head",
    "Concept of Descartes",
    "Nice notion",
    "New pensee",
    "___fixe",
    "\"Bonne___!\"",
    "Descartes's thought",
    "___ fixe",
    "French notion",
    "___ fixe",
    "___ fixe",
    "Bonne ___ (clever thought)",
    "___ fixe",
    "___ fixe"
  ],
  "lest": [
    "Just in case",
    "In case",
    "Cautionary word",
    "Just in case",
    "To avoid the risk that",
    "In the event that",
    "In case",
    "\"___ we forget\"",
    "\"___ we forget\"",
    "In case",
    "For fear that",
    "For fear that",
    "In case",
    "In case",
    "Just in case",
    "\"___ we forget ...\"",
    "To avoid the risk that",
    "\"___ Darkness Fall\" (L. Sprague de Camp novel)",
    "For fear that",
    "For fear that",
    "For fear that",
    "\"... ___ ye be judged\"",
    "In case that",
    "\"___ we forget\"",
    "For fear that",
    "\"___ we forget\"",
    "In the event that",
    "For fear that",
    "\"___ we forget ...\"",
    "For fear that",
    "In case",
    "For fear that",
    "In case",
    "Out of concern that",
    "Out of concern that",
    "For fear that",
    "For fear that",
    "For fear that",
    "In case",
    "In case",
    "\"... ___ faith turn to despair\": Romeo",
    "For fear that",
    "For fear that",
    "In case",
    "\"Take heed ___ any man deceive you\": Mark 13:5",
    "For fear that",
    "\"___ we forget ?à\"",
    "For fear that",
    "In case",
    "For fear that",
    "\"___ we forget ...\"",
    "\"___ we forget\"",
    "For fear that",
    "Avoiding the risk that",
    "For fear that",
    "For fear that",
    "Just in case",
    "\"___ we forget\"",
    "For fear that",
    "Obsolescent conjunction",
    "First word on a military memorial",
    "\"___ we forget...\"",
    "\"___ we forget\"",
    "In case",
    "It may come before we forget",
    "For fear that",
    "In case",
    "Just in case",
    "\"___ we forget...\"",
    "For fear that",
    "For fear that",
    "Kipling's \"___ we forget!\"",
    "\"___ we forget\"",
    "\"___we forget...\"",
    "\"___ we forget\"",
    "For fear that",
    "\"___ we forget\"",
    "\"___ we forget\"",
    "For fear that",
    "Paris station Gare de ___",
    "In case",
    "For fear that",
    "For fear that",
    "For fear that",
    "Paris's Gare de ___"
  ],
  "lass": [
    "Glasgow gal",
    "Young lady",
    "Bonny young woman",
    "Colleen",
    "Young lady",
    "Young woman",
    "Young Scottish lady",
    "Scottish miss",
    "Miss",
    "Glasgow gal",
    "Miss",
    "Girl in a bonnet, maybe",
    "Bonny miss",
    "Glasgow girl",
    "Miss",
    "Highland youth",
    "Scottish girl",
    "Colleen",
    "Miss",
    "Lad's partner",
    "Miss",
    "Maid",
    "Bonny miss",
    "Miss",
    "Colleen",
    "Plaid-clad miss",
    "Girl in tartan",
    "Girl in tartan",
    "Subject of many a Burns ballad",
    "Highland girl",
    "Glasgow lovely",
    "Sweetheart",
    "Glasgow gal",
    "Loch Lomond lovely",
    "Scottish miss",
    "Burns's \"The Lovely ___ o' Inverness\"",
    "Fair maiden",
    "Bonny gal",
    "Filly",
    "Miss",
    "Lad's mate",
    "Miss",
    "Bonny one",
    "Sweetheart",
    "Miss",
    "Party to a Highland fling?",
    "Bonny girl",
    "Kiltie's gal",
    "\"O, gie me the ___ that has acres o' charms\": Burns",
    "Miss",
    "Sweetheart",
    "Girl",
    "Glasgow gal",
    "Young woman",
    "Bonny one",
    "Maiden",
    "Lad's partner",
    "Glasgow gal",
    "Laddie's love",
    "Edinburgh girl",
    "Girl",
    "Bonny one",
    "Scottish miss",
    "Young woman",
    "\"O, gie me the ___ that has acres o' charms\": Burns",
    "Miss",
    "Filly",
    "Filly",
    "Highlands girl",
    "Galloway gal",
    "\"It Was a Lover and His ___\" (old song standard)",
    "Laddie's love",
    "Young miss",
    "Colleen",
    "Lad's love",
    "Lad's sweetheart",
    "Young woman",
    "Colleen",
    "Sweetheart",
    "Female sweetheart",
    "Colleen",
    "Girl",
    "Ballad subject, maybe",
    "Female sweetheart",
    "Debutante, for one",
    "Miss",
    "Bonny one",
    "Damsel"
  ],
  "imps": [
    "Mischief-makers",
    "Handfuls for a babysitter",
    "Little rapscallions",
    "Little pranksters",
    "Bratlings",
    "Scapegraces",
    "Little rapscallions",
    "Sorts frequently given detention",
    "Parenting challenges",
    "Little rascals",
    "Little hellions",
    "Tyrion Lannister of \"Game of Thrones\" and others",
    "Little troublemakers",
    "Little rascals",
    "Mischief-makers",
    "Chronic pains?",
    "Little devils",
    "Babysitters' challenges",
    "Little handfuls, so to speak",
    "Folklore figures",
    "They're a handful",
    "Some World of Warcraft figures",
    "Mini-terrors",
    "Little troublemakers",
    "Kin of fairies",
    "Pucks",
    "Tricksters",
    "Terrors",
    "Hell-raisers",
    "Some tricksters",
    "Little mischief-makers",
    "Kids to watch out for",
    "Little terrors",
    "Parenting challenges",
    "Bad tads",
    "Bratty types",
    "Young troublemakers",
    "Rapscallions",
    "Babysitter's handful",
    "Little devils",
    "Little tricksters",
    "Rapscallions",
    "Little demons",
    "Little rascals",
    "Dennis the Menace types",
    "Dungeons & Dragons characters",
    "Rapscallions",
    "Tricksters",
    "Pucks",
    "Tricksters",
    "Little devils",
    "Sitters' headaches",
    "Baby sitter's banes",
    "Eloise's ilk",
    "Problems for parents",
    "Urchins",
    "Pucks",
    "Rascals",
    "Devilkins",
    "Bedevilers",
    "Troublemakers",
    "Troublemakers",
    "Pitchfork wielders",
    "Little troublemakers",
    "Devilkins",
    "Misbehavers",
    "Mischief-makers",
    "Pucks"
  ],
  "moot": [
    "___ court",
    "Not worth debating",
    "Having no application",
    "Not worth hashing out",
    "Of no relevance",
    "___ point",
    "Purely academic",
    "No longer important",
    "No longer relevant",
    "Not worth discussing",
    "Like some points",
    "Theoretical",
    "Worth debating - or not",
    "Not worth debating",
    "No longer worth discussing",
    "Purely academic",
    "Open to debate",
    "___ point",
    "Subject to dispute",
    "Theoretical",
    "Like some points",
    "Like some points",
    "No longer worth debating",
    "Only hypothetical",
    "Like some points",
    "Not worth debating",
    "No longer worth debating",
    "Doubtful",
    "Not worth debating, as a point",
    "___ court (law school exercise)",
    "No longer worth debating",
    "Kind of point",
    "Subject to debate",
    "Not worth discussing",
    "___ court, law student's exercise",
    "Not worth debating",
    "Irrelevant, as a point",
    "Purely academic",
    "Not worth debating",
    "Like some points",
    "Not worth debating",
    "No longer worth debating",
    "Not relevant",
    "Arguable",
    "Debatable",
    "Like some points",
    "Like some legal issues",
    "No longer worth discussing",
    "Debatable",
    "Kind of court",
    "Kind of court",
    "Irrelevant",
    "Arguable",
    "Debatable",
    "Debatable"
  ],
  "pupa": [
    "Stage between larva and imago",
    "Stage after larva",
    "Caterpillar product",
    "Cocooned stage",
    "Part of a life transformation",
    "Cocoon contents",
    "Insect stage after larva",
    "Stage that includes a cocoon",
    "Larva successor",
    "Butterfly, once",
    "Insect stage",
    "Insect stage",
    "Post-larval insect",
    "Cocoon's contents",
    "Stage of development",
    "Stage of metamorphosis",
    "Cocoon-stage insect"
  ],
  "deus": [
    "___ ex machina",
    "\"Ditat ___\" (Arizona's state motto)",
    "Jupiter, exempli gratia",
    "Saturnus or Mercurius",
    "___ Ex (video game franchise)",
    "___ ex machina",
    "___ ex machina",
    "Subject of a Latin hymn",
    "___ ex machina",
    "Mars, e.g.",
    "Jupiter or Mars",
    "\"Ditat ___\" (Arizona's motto)",
    "Latin god",
    "Mars, exempli gratia",
    "Evan S. Connell's \"___ lo Volt!\"",
    "Saturn, e.g.",
    "Jupiter, e.g.",
    "Jupiter, e.g.",
    "Mars, e.g.",
    "Mars, e.g.",
    "___ ex machina",
    "Jupiter, e.g.",
    "Jupiter, e.g.",
    "___ ex machina",
    "Saturn or Mars",
    "Jupiter, e.g.",
    "___ ex machina",
    "Word in a hymn",
    "Jupiter or Apollo, e.g.",
    "Jupiter, e.g.",
    "___ ex machina",
    "___ ex machina"
  ],
  "late": [
    "Running behind",
    "Past the deadline",
    "\"You're ___!\" (reproof)",
    "Delinquent",
    "Past one's bedtime, say",
    "It's better than never, they say",
    "Delayed",
    "How the fashionable are said to arrive",
    "Behind schedule",
    "Bad way to get to work",
    "Running behind",
    "Running behind schedule",
    "Overdue",
    "Behind",
    "Like the baby in a 9 1/2-month pregnancy",
    "After curfew",
    "Word repeated before show",
    "Bad way to run",
    "Like some additions and editions",
    "After the bell",
    "All-too-common flight status",
    "Partner of great",
    "After the whistle",
    "One way to be running or working",
    "At nine and a half months, say",
    "When repeated, kind of show",
    "After the whistle",
    "Pregnant, maybe",
    "With 14-Down, like some talk shows",
    "Not long-departed",
    "With 20-Across, Conan's domain",
    "Fashionable, some say",
    "Like fashionable partygoers?",
    "Running behind",
    "Running ___",
    "After midnight, say",
    "Like the White Rabbit",
    "Held up",
    "Missing the boat, say",
    "After midnight, say",
    "After the buzzer",
    "Tardy",
    "Not on time",
    "After the buzzer",
    "Behind",
    "Like some shifts",
    "Former",
    "Likely to miss the bus, say",
    "Recently departed",
    "Missing the boat, say",
    "Tardy",
    "Pushing midnight, say",
    "Missing the boat",
    "Subject to a fine, maybe",
    "Like the White Rabbit",
    "After the whistle",
    "Behind",
    "Missing the deadline",
    "Overdue",
    "When repeated, like some shows",
    "Recent",
    "Missing a deadline",
    "After midnight, say",
    "After curfew",
    "Better ___ than never",
    "Still not there",
    "How one might run",
    "Recently deceased",
    "Running behind",
    "Fall-blooming, say",
    "Running behind",
    "Word repeated before show",
    "Eleventh-hour",
    "Missing a deadline",
    "With 55-Down, Insomniac's TV fare",
    "When repeated, like some shows",
    "After curfew",
    "Like the White Rabbit",
    "How workaholics often work",
    "After curfew",
    "Like the White Rabbit",
    "Delayed",
    "After the bell",
    "Overdue",
    "Better than never?",
    "Midnight or beyond",
    "Behind schedule",
    "Better than never?",
    "Like an inveterate procrastinator",
    "Overdue",
    "Past due",
    "Not on time",
    "Running behind",
    "After 11 P.M.",
    "Past due",
    "Like the White Rabbit",
    "Overdue",
    "Former",
    "Not punctual",
    "Past due",
    "Up-to-the-minute",
    "Deceased",
    "Held up",
    "Word repeated before \"show\"",
    "Like the White Rabbit",
    "Fashionable",
    "Behind"
  ],
  "deet": [
    "Popular tick repellent",
    "Bug spray ingredient",
    "Bug repellent ingredient",
    "Bug spray ingredient",
    "Bug spray ingredient",
    "Mosquito repellent ingredient",
    "Insect repellent ingredient",
    "Ingredient in insect repellent",
    "Mosquito repellent brand",
    "Off! ingredient",
    "Trademarked repellent",
    "Bug spray component",
    "Bite stopper",
    "Active ingredient in Off!",
    "Insect repellent ingredient",
    "Preventer of many bites",
    "Bug spray ingredient",
    "It makes a flea flee",
    "Bug repellent",
    "Debugger?",
    "It bugs bugs",
    "Insect repellent",
    "Insect repellent",
    "It's repellent",
    "Bug spray ingredient",
    "Bug juice?",
    "Outdoor bite preventer",
    "Insect repellent ingredient",
    "Mosquito repellent ingredient",
    "Insect repellent",
    "Active ingredient in Off!"
  ],
  "eras": [
    "Ballpark figures, in brief",
    "Ballpark figs.",
    "Timeline spans",
    "Special times",
    "Things sometimes named after queens",
    "Important stretches",
    "Low stats for some M.V.P. winners",
    "Periods of time",
    "Paleozoic, Mesozoic, Cenozoic, etc.",
    "Reigns, say",
    "Things sometimes named for kings",
    "Rules might define them",
    "Some are named for kings and queens",
    "Significant periods",
    "Museum sections, perhaps",
    "Long times",
    "Long stretches",
    "Chapters in history",
    "Times gone by",
    "Slices of time",
    "Prohibition and Victorian periods",
    "Big times",
    "They often start with elections",
    "Noteworthy periods",
    "Notable times in history",
    "Periods of note",
    "Really long times",
    "Notable stretches",
    "Revolutions can divide them",
    "Notable times",
    "Timeline periods",
    "Low ones are best, in brief",
    "Noteworthy times",
    "Some baseball stats",
    "Chapters of history",
    "Period pieces",
    "Baroque and Romantic, for classical music",
    "Timeline sections",
    "Eight-year presidencies, e.g.",
    "Historical periods",
    "History course topics",
    "Historical periods",
    "They may match presidential administrations",
    "Timeline sections",
    "Some are named after presidents",
    "Pitchers' stats",
    "Stretches in history",
    "Historic blocks",
    "Aces have low ones, briefly",
    "Ballpark figs.",
    "Historical periods",
    "Noteworthy periods",
    "Historical topics",
    "Different rooms in a museum, maybe",
    "Figs. always expressed to two decimal places",
    "Geologic time periods",
    "The Eisenhower years and others",
    "Cy Young candidates' stats",
    "Historical periods",
    "Characteristic times",
    "Noteworthy times",
    "Parts of history",
    "Pitching stats",
    "Key periods",
    "\"-zoic\" periods",
    "Memorable times",
    "Historical periods",
    "Sections of a natural history museum, maybe",
    "Meaningful stretches",
    "Pitching stats",
    "They're sometimes named after presidents",
    "Ages",
    "Timeline divisions",
    "Chapters in history",
    "They're historically significant",
    "Chapters in history",
    "Blocks of history",
    "Timeline segments",
    "The Gay Nineties and others",
    "Gay Nineties and Roaring Twenties",
    "Times to remember",
    "Geologic succession",
    "They're often associated with world leaders",
    "Big slices of history",
    "Periods of time",
    "Succession of history",
    "Notable times",
    "Reconstruction and the Roaring Twenties",
    "Eventful stretches",
    "Bullpen stats",
    "Bullpen stats",
    "Bullpen stats",
    "Big times",
    "Cy Young candidates' stats",
    "Post-Civil War Reconstruction and others",
    "The Depression and the cold war, for two",
    "Historic periods",
    "Long lines on a timeline",
    "History chapters",
    "Times gone by",
    "Chapters in history",
    "Eventful times",
    "Pitchers' stats",
    "Eventful times",
    "Times on a timeline",
    "Times on the History Channel?",
    "History chapters",
    "Period pieces?",
    "Historical succession",
    "Gay Nineties and Roaring Twenties",
    "Periods in history",
    "History units",
    "History chapters",
    "Long periods",
    "Pitching stats",
    "Mound stats",
    "Timeline divisions",
    "Time line divisions",
    "Big times",
    "Historic periods",
    "Geological periods",
    "Important periods",
    "The Cenozoic and others",
    "Ages",
    "History chapters",
    "Big times",
    "Historic periods",
    "Spanish form of \"to be\" after \"tu\"",
    "Diamond stats",
    "Long stretches",
    "Timeline divisions",
    "Memorable times",
    "Time line divisions",
    "Big times",
    "Geologists' studies",
    "Chapters of history",
    "Notable times",
    "Pitching stats, for short",
    "Notable times",
    "Times",
    "Big times",
    "Historic times",
    "Important times",
    "Times in history",
    "58-Across and others",
    "Historical times",
    "Historical divisions",
    "Eram, ___, erat",
    "Historic periods",
    "Cenozoic and Paleozoic",
    "Diamond figures: Abbr.",
    "Times to remember",
    "Period pieces",
    "Long stretches",
    "Blocks of time",
    "Big times",
    "Historical chapters",
    "Bygone times",
    "The Roosevelt years and others",
    "Big times",
    "Extended periods",
    "Great times",
    "Timeline segments",
    "Ages",
    "Lasting periods",
    "Gay 90's and Roaring 20's",
    "Special times",
    "Memorable times",
    "Paleozoic and Cenozoic",
    "Extended periods",
    "Time and time again?",
    "Historic periods",
    "Historical times",
    "Stretches of time",
    "Geologists' divisions",
    "Time pieces",
    "Periods",
    "Historic times",
    "They're full of dates",
    "Cenozoic and Paleozoic, e.g.",
    "Timeline sections",
    "Big times",
    "Periods divide them",
    "Great times",
    "Times past",
    "Postwar period and others",
    "They're historically significant",
    "Historians' subjects",
    "The Depression and others",
    "Important times",
    "History chapters",
    "Great times",
    "Historians' study",
    "Chunks of history",
    "Times past",
    "Timeline divisions",
    "Times old and new",
    "Historical spans",
    "Times to live through",
    "Chapters in world history",
    "Baseball stats",
    "Historic periods",
    "Paleozoic and Mesozoic",
    "Slices of history",
    "Paleozoic and Mesozoic",
    "Memorable periods",
    "Mesozoic and others",
    "History chapters",
    "Intervals of history",
    "Generations",
    "Timeline divisions",
    "Memorable periods",
    "Chapters in history",
    "Important periods",
    "Times to remember",
    "Historic periods",
    "Historic periods",
    "Chronological periods",
    "Time-line times",
    "Ages",
    "Memorable times",
    "Christian and others",
    "Precambrian and others",
    "Important periods",
    "Geologic divisions",
    "Historic periods",
    "Gaslight and others",
    "Christian and others",
    "Times to write about",
    "Ages",
    "Epochs",
    "Times",
    "Times to remember",
    "Timetable divisions",
    "Bullpen stats",
    "Epochs"
  ],
  "ahso": [
    "Semiserious \"Got it!\"",
    "\"Now I get it!\"",
    "\"I can see clearly now\"",
    "\"I understand,\" facetiously",
    "\"Duh, I get it\"",
    "\"Gotcha,\" facetiously",
    "Facetious words of understanding",
    "Mock words of understanding",
    "Facetious \"I see\"",
    "Facetious words of enlightenment",
    "\"Now I get it,\" facetiously",
    "\"Now I see!\"",
    "\"Got it,\" jocularly",
    "\"I see,\" facetiously",
    "\"Got it!\"",
    "Hammy \"Now I see!\"",
    "\"Gotcha,\" humorously",
    "Faux Japanese reply",
    "Facetious cry of understanding",
    "Facetious \"I see\"",
    "\"Now I see!\"",
    "\"Got it\"",
    "\"I see,\" kiddingly",
    "\"I see,\" facetiously",
    "\"I get it,\" jokily",
    "\"Now I get it\"",
    "Faux Japanese reply",
    "\"I get it now\"",
    "\"Now I get it,\" facetiously",
    "\"Got it\"",
    "Mock words of understanding",
    "Mock words of understanding",
    "Mock phrase of insight",
    "\"I get it,\" humorously",
    "Mock words of enlightenment",
    "\"I see,\" facetiously",
    "Comment of enlightenment",
    "\"I see,\" facetiously",
    "Facetious \"I see\"",
    "Mock comment of understanding",
    "Comment from Mr. Moto",
    "Mock reply of understanding",
    "Mr. Moto remark",
    "103-Down, facetiously",
    "Asian sleuth's comment",
    "\"I see,\" facetiously",
    "\"I understand,\" facetiously",
    "Words of discovery",
    "Mock phrase of insight",
    "Mock words of understanding",
    "Mock words of understanding",
    "Mr. Moto reaction",
    "\"I see,\" facetiously",
    "Mock words of enlightenment",
    "Words from Moto",
    "Words from Mr. Moto",
    "Mock words of enlightenment",
    "\"I understand!\"",
    "\"Now I see!\"",
    "\"Now I see!\"",
    "\"I understand now\"",
    "\"Now I see!\"",
    "\"I see,\" facetiously",
    "Mr. Moto's reply",
    "Mr. Moto reply",
    "Mr. Moto comment",
    "Comment from Mr. Moto",
    "Words of understanding",
    "Charlie Chan comment",
    "Charlie Chan comment"
  ],
  "sate": [
    "Stuff",
    "Fill to excess",
    "Fill to the gills",
    "Stuff",
    "Fulfill",
    "Stuff",
    "Fill to capacity",
    "Overfill",
    "Not just meet the needs of",
    "Fill to excess",
    "Make content",
    "Stuff",
    "Fill",
    "Fill",
    "*Fill to excess",
    "Quench",
    "Fill",
    "Stuff",
    "Leave gratified",
    "Overstuff",
    "Be adequate for",
    "Leave full",
    "Leave completely filled",
    "Asian appetizer",
    "Completely gratify",
    "Make content",
    "Fill beyond full",
    "Fill to the gills",
    "Fill",
    "Fill to excess",
    "Be plenty good for",
    "Fill",
    "Make content",
    "Suffice, foodwise",
    "Fill",
    "Fill",
    "Fill totally",
    "Stuff",
    "Gorge",
    "Fill",
    "Glut",
    "Fill",
    "Overfill",
    "Fill all the way",
    "Fill to excess",
    "Be enough for",
    "Fill",
    "Completely fill",
    "Be enough for",
    "Fill to excess",
    "Fill fully",
    "Stuff",
    "Glut",
    "Fill the stomach of",
    "Fill up",
    "Fill",
    "Fill beyond full",
    "Fill up",
    "Fill",
    "Completely fill, as a hungry person",
    "Make content",
    "Make full",
    "Fill to capacity",
    "Fill until full",
    "Hit the spot",
    "Fill up",
    "Glut",
    "Cram",
    "Make content",
    "Fill beyond full",
    "Fill up",
    "Fill",
    "Fill to surfeit",
    "Fill till full",
    "Stuff",
    "Fill beyond full",
    "Be enough for",
    "Fill",
    "More than fill",
    "Stuff to the gills",
    "Glut",
    "Render replete",
    "Fill to the gills",
    "Stuff",
    "Fill nicely",
    "Gorge",
    "Fill to the gills",
    "Satisfy fully",
    "Fill completely",
    "Stuff",
    "Satisfy a hankering",
    "Stuff",
    "Stuff",
    "More than content",
    "Gorge",
    "Fill",
    "Stuff",
    "Stuff",
    "Fill up",
    "Fill to excess",
    "Overfill",
    "Fill",
    "Fill",
    "Overfill",
    "Overfill",
    "Indulge to the limit",
    "Glut",
    "Gorge",
    "Fill",
    "Cloy",
    "Stuff",
    "Overfeed",
    "Fill",
    "Completely fill",
    "Glut",
    "Overfill",
    "Surfeit",
    "Give a bellyful",
    "Stuff",
    "Fill",
    "More than gratify",
    "Overindulge",
    "Stuff"
  ],
  "odds": [
    "\"___ are ...\"",
    "Nine to five, for example",
    "They can be even, paradoxically",
    "Gamblers' calculations",
    "Even ___",
    "Some calculations in 40-Down",
    "10:1, e.g.",
    "Probability",
    "Casino calculation",
    "9 to 5, e.g.",
    "\"What are the ___?\"",
    "3:1 or 4:1, e.g.",
    "You can bet on them",
    "Chances",
    "6:1 or 7:1, e.g., at a racetrack",
    "Racetrack ratio",
    "9 to 5, e.g.",
    "3:1 or 4:1, e.g.",
    "Figures at a racetrack",
    "Sportsbook offering",
    "3:2 or 10:1, e.g.",
    "Sports figures",
    "Strangely enough, they're often even",
    "Bookie's quote",
    "10:1 or 3:2 at a racetrack",
    "25, 27, 29, etc.",
    "Numbers on right-hand pages",
    "One in a million, e.g.",
    "10:1, for example",
    "Things are unlikely when they are long",
    "10:1 at a racetrack, e.g.",
    "Track figures",
    "Gambler's chances",
    "Parimutuel calculation",
    "See 33-Across",
    "Racetrack figure",
    "5:2, e.g.",
    "They're about 1 in 650,000 for drawing a royal flush",
    "Even ___",
    "Track figures",
    "Figures affected by point spreads",
    "Gambler's numbers",
    "Tip sheet figures",
    "7:30 or 8:15, say",
    "Bookies give them",
    "They may be even, ironically",
    "By all ___",
    "Partner of 62-Across",
    "Betting line",
    "2:1 or 3:1",
    "Las Vegas figures",
    "9 to 5, e.g.",
    "2:1, e.g.",
    "They may be stacked against you",
    "Line at a track",
    "They may be even, ironically",
    "Track numbers",
    "9 to 5, e.g.",
    "Bookie's quote",
    "\"What are the ___?\"",
    "5:2, e.g., at a racetrack",
    "Even ___",
    "Chances",
    "Ten to one, e.g.",
    "Some calculations",
    "What a tout may tout",
    "Bettors' figures",
    "\"The Wizard of ___\" (short-lived Alex Trebek game show)",
    "Tote board figure",
    "They may be hard to beat",
    "Track calculation",
    "Track numbers",
    "They're sometimes stacked",
    "Bookie's figure",
    "___ and ends",
    "O.T.B. postings",
    "Part of a sweepstakes's fine print",
    "Bookie's quote",
    "Chances",
    "Long ones are risky",
    "Poker player's calculation",
    "Some roulette bets",
    "Las Vegas figures",
    "Chances",
    "Chances",
    "6:1 or 3:2, say",
    "Betting parlor subject",
    "Racetrack figures",
    "Chances",
    "3:1 or 4:1, e.g.",
    "\"Against All ___\"",
    "Probability",
    "Bookie's computation",
    "Bookie's quote",
    "9 to 5, maybe",
    "Track info",
    "Bookie's concern",
    "With 65-Across, Miscellany, when separated by \"and\"",
    "Handicapper's calculation",
    "3:1, e.g.",
    "Bookie's figures",
    "Bettor's figuring",
    "7:5, e.g., at a horse race",
    "They may be beaten",
    "Chances",
    "Tout's concern",
    "Tout's tabulation",
    "With 27-Down, a bookie",
    "\"What are the ___?\"",
    "They're uneven",
    "Factor in decision-making",
    "\"What are the ___...?\"",
    "They may be against you",
    "Bettor's stat",
    "Lotto info",
    "Betting ratio",
    "Bookie's quote",
    "3 : 1, 5 : 2, etc.",
    "4:1, e.g.",
    "Race track figures",
    "Vegas calculation",
    "Chances",
    "Probability",
    "Sports figures?",
    "Track figures",
    "Bookie's quote",
    "Probability",
    "Tout's concern",
    "Chances",
    "Tote board tally",
    "Ends' partner",
    "Tote board stat",
    "Tout's topic",
    "Ten to one, e.g."
  ],
  "noah": [
    "TV host who was the subject of the documentary \"You Laugh but It's True\"",
    "Organizer of a couples cruise?",
    "Witness to the first rainbow in the Bible",
    "TV host with the memoir \"Born a Crime\"",
    "One famous for seeing double?",
    "Biblical boat captain",
    "Trevor of \"The Daily Show\"",
    "Early vintner, in the Bible",
    "TV host with the autobiography \"Born a Crime\"",
    "He planned for a rainy day",
    "Biblical patriarch-turned-sailor",
    "One releasing a dove in the Bible",
    "Flood survivor",
    "Title biblical character played by Russell Crowe",
    "\"The Daily Show\" host Trevor",
    "One preparing for a coming flood",
    "Ark builder",
    "2013-'14 N.B.A. All-Star Joakim ___",
    "Webster's first?",
    "2014 biblical title role for Russell Crowe",
    "Father of Ham",
    "Lexicographer Webster",
    "Ark builder",
    "Ark builder",
    "Rain man?",
    "Patriarch who lived 950 years",
    "Biblical ark builder",
    "Patriarch who died at age 950",
    "Builder of a 2x2 vessel?",
    "Flood survivor",
    "Rainy day planner?",
    "Genesis mariner",
    "Ark builder",
    "Life saver?",
    "Biblical patriarch \"righteous in this generation\"",
    "Webster of Webster's dictionary",
    "One who brought together many couples",
    "Ark builder",
    "Ark builder",
    "Preparer for a flood",
    "Captain for 40 days and nights",
    "NPR newsman Adams",
    "Biblical captain for 40 days and 40 nights",
    "Wordsmith Webster",
    "Flood survivor",
    "Ancient mariner",
    "Biblical flood survivor",
    "Ark builder",
    "Flood survivor",
    "See 29-Down",
    "Master of the double take?",
    "Longtime \"All Things Considered\" host Adams",
    "Collector of couples",
    "Manager of a two-party system?",
    "First name in a dictionary",
    "He released a dove in Genesis",
    "Captain for 40 days and nights",
    "Actor Wyle",
    "Captain for 40 days and nights",
    "Ancient mariner?",
    "Wyle of \"ER\"",
    "One issued a flood warning",
    "Flood survivor",
    "Webster who had a way with words",
    "Flood survivor",
    "Biblical boatwright",
    "Boat builder",
    "Ark builder",
    "Genesis skipper",
    "Wyle of \"ER\"",
    "Biblical ark builder",
    "Ark builder",
    "Vessel maker of old",
    "Wordsmith Webster",
    "Ark builder",
    "Japheth's father",
    "Man with an ark",
    "Wordwise Webster",
    "Man famous for doing a double take?",
    "Ham's father",
    "Ham's father",
    "Genesis skipper",
    "Lexicographer Webster",
    "Wordsmith Webster",
    "One with a rain check?",
    "Early skipper",
    "Preparer for a flood",
    "\"Two by Two\" role",
    "Ark builder",
    "He should have swatted those two flies",
    "First name in dictionaries",
    "Shipbuilder of note",
    "Biblical captain",
    "Biblical skipper",
    "Ark architect",
    "Wordsmith Webster",
    "\"Take two\" was his motto",
    "Ham's father",
    "Ancient mariner",
    "First name in lexicography",
    "Grandson of Methuselah",
    "One noted for bringing couples together",
    "Ark builder",
    "Beery of 20's-40's films",
    "Flood avoider",
    "First name in Websters",
    "Famous flood survivor",
    "Ark skipper",
    "Nautical zookeeper",
    "Flood engineer?",
    "Noted ark-itect",
    "Shem's father",
    "Ark builder",
    "Flood survivor",
    "Biblical 950-year-old",
    "Tennis champ Yannick"
  ],
  "ante": [
    "Pot price",
    "Add to the kitty",
    "Prefix with penultimate",
    "Request a hand, say",
    "A jokester might say \"And the pot thickens\" after one",
    "Kick in",
    "Pay to play",
    "Toss in a chip, maybe",
    "Kick in seed money",
    "Starting stake",
    "Penny-___",
    "Chip in, in a way",
    "Ask to be handed a hand?",
    "Feed the kitty",
    "Start the poker pot",
    "Starting stake",
    "Opening stake",
    "Pay (up)",
    "Poker prerequisite",
    "What to do if you'd like a hand",
    "Up the ___",
    "Pay to play",
    "Dealer's request",
    "Relative of a blind, in poker",
    "Pay (up)",
    "Need to play, say",
    "Up the ___",
    "Something to do before a deal",
    "Part of a pot",
    "Prepare to get a hand",
    "The \"a\" of a.m.",
    "Poker stake",
    "Pot starter",
    "Poker buy-in",
    "Status quo ___",
    "One or two chips, maybe",
    "Poker stake",
    "Cost to get a hand",
    "Up the ___",
    "Deal preceder",
    "It's usually put in the middle of a table",
    "Pot starter",
    "Certain buy-in",
    "Get in the game",
    "Pot grower?",
    "Prefix with chamber",
    "First chip in the pot",
    "Stud fee?",
    "Pot grower?",
    "Chip or coin thrown in the pot",
    "Pot part",
    "Post's opposite",
    "Poker stake",
    "Card game stake",
    "Couple of chips, maybe",
    "Poker table payment",
    "Penny, maybe",
    "Pot thickener?",
    "Kick in",
    "Up the ___",
    "A penny is a small one",
    "Steak",
    "Upfront bet",
    "Cost to get in",
    "Prelude to a deal",
    "Draw money?",
    "A penny is a small one",
    "Put up",
    "It's for starters",
    "It's just for openers",
    "Pot part",
    "Pay to play",
    "Pony up, in poker",
    "Front money?",
    "Player's fee",
    "Before, when placed before",
    "Indicate that one needs a hand?",
    "Get into a poker game, say",
    "Play money?",
    "Throw a chip in the pot",
    "Something to give a kitty?",
    "Penny ___",
    "Get the pot started",
    "One or two poker chips, maybe",
    "Initial money for the pot",
    "Start the pot",
    "Pot grower?",
    "Prefix with diluvian",
    "Start to fill a pot",
    "Stud money",
    "*M",
    "Pot money",
    "Prefix with bellum",
    "Pay (up)",
    "Pay to play",
    "Poker payment",
    "Pay to play, as poker",
    "Initial money for the pot",
    "Entry fee?",
    "Advance on a table",
    "Stake on a table",
    "Put one's two cents in?",
    "Upfront stake",
    "A few poker chips, maybe",
    "Status quo ___",
    "Pot addition",
    "Not post-",
    "\"I'm in\" indicator",
    "Part of a.m.",
    "Opening bet",
    "Starting stake",
    "Price to play",
    "Certain stake",
    "Deal preceder",
    "Stud fee?",
    "Pay for a hand",
    "Get in on the deal",
    "Poker pot starter",
    "Deal preceder",
    "Gambler's stake",
    "Play money?",
    "Let go to pot?",
    "Initial stake",
    "Part of a.m.",
    "Pot builder",
    "The \"a\" in a.m.",
    "Put down some chips",
    "Something that's just for starters?",
    "Bread on the table, maybe",
    "The \"a\" in a.m.",
    "Stud money",
    "The \"a\" in a.m.",
    "Toss in a chip",
    "___ meridiem (morning: Lat.)",
    "Minimum poker loss",
    "It may be upped",
    "Penny ___",
    "Initial poker stake",
    "Throw in a few chips, say",
    "\"___ up!\" (game cry)",
    "A chip or two to start with",
    "Play money?",
    "Chip or two, maybe",
    "Stake",
    "Chips in the pot",
    "What you may need to do to get a hand",
    "Amount of money that can be raised?",
    "Kick in, say",
    "Starting stake",
    "It may be raised at a table",
    "Start the pot",
    "Start the kitty",
    "Opening stake",
    "It may be upped",
    "Chip without dip?",
    "Buy in",
    "Deal preceder",
    "Table center piece?",
    "Start a hand, maybe",
    "Start to date?",
    "Stud fee?",
    "Deal preceder",
    "It may be upped",
    "Prefix with chamber",
    "A couple of chips, maybe",
    "Pay to play",
    "Blue chip, maybe",
    "It might get your kitty going",
    "Put up",
    "Start a pot going",
    "Start the kitty",
    "It may be upped",
    "Poker stake",
    "Get in on a deal",
    "Requirement for a hand, say",
    "Start to date?",
    "A few chips, say, in poker",
    "Feed the kitty",
    "Part of A.M.",
    "Blind relative?",
    "Put (up)",
    "Pay to play",
    "It may be upped",
    "You might get a hand for it",
    "Toss in a chip, say",
    "Kick into the pot",
    "Pay the kitty",
    "Penny ___",
    "Requirement in a game of dealer's choice",
    "Pot starter",
    "It's put in a pot",
    "A penny is a small one",
    "A couple of chips in the pot, say",
    "It goes in the middle of the table",
    "Indication of serious intent",
    "Gambler's stake",
    "Show that one's in?",
    "A couple of chips in the pot, maybe",
    "Chip on the table, maybe",
    "The \"a\" in a.m.",
    "Help make a pot",
    "Put in to get in",
    "Advance amount",
    "It goes in the center of the table",
    "Penny, maybe, in poker",
    "Prelude to a deal",
    "Gaming table fee",
    "Chip in",
    "Penny-___ (trivial)",
    "A few chips in the pot, maybe",
    "With 2-Down, ancients",
    "Poker payment",
    "Part of a pot",
    "Initial stake",
    "Some chips, maybe",
    "Pot foundation",
    "Fork over, with \"up\"",
    "Stud fee?",
    "Kitty starter",
    "Kitty starter",
    "Chip in",
    "Requirement for some games",
    "Poker payment",
    "Part of a pot",
    "Payment at a table",
    "Feed the kitty",
    "Handful of chips, maybe",
    "What you may do to get a hand",
    "Poker stake",
    "Chip in",
    "Poker pot starter",
    "Initial stake",
    "Table payment",
    "Poker payment",
    "Feed the kitty",
    "Penny, perhaps",
    "Some chips, maybe",
    "Poker pot primer",
    "Chip in",
    "Pay to play",
    "Poker stake",
    "Stud stake",
    "Table stake",
    "Part of A.M.",
    "A chip, maybe",
    "Penny ___",
    "Start the pot",
    "Start a pot",
    "Poker stake",
    "It goes to pot",
    "Pay to play",
    "Hand demand?",
    "Prefix with date",
    "A chip, maybe",
    "Pay for a hand",
    "Contribution before the deal",
    "Contribution to a pot",
    "Chip in",
    "Pay to play",
    "Opening stake",
    "Pay (up)",
    "___ meridiem",
    "A few chips, perhaps",
    "Prepay, with \"up\"",
    "Stud fee?",
    "Penny, perhaps",
    "Put in the pot",
    "It's a starter",
    "Poker stake",
    "Money to play with",
    "Pay one's share, with \"up\"",
    "Put one's two cents in?",
    "Chip in",
    "Pay to play",
    "Chamber lead-in",
    "Pony up",
    "Price of a hand",
    "Poker starter",
    "Pot builder",
    "Pay to play",
    "Cough (up)",
    "At times it's upped",
    "It's for openers",
    "Pay to play",
    "Advance amount",
    "Play opener",
    "Startup cost",
    "Pot builder",
    "Prefix with room",
    "Get the pot going",
    "Opening amount",
    "It's just for openers",
    "Upfront amount",
    "Start for a kitty",
    "Starting stake",
    "White chip, often",
    "Prime the pot",
    "Chip in",
    "Deal preceder",
    "Poker player's payment",
    "White chip, often",
    "Put up stakes",
    "Upfront money",
    "Opening bit",
    "What's required to be \"in\"",
    "It's a start",
    "Get the pot going",
    "Dealer's demand",
    "Opening wager",
    "Cost of cards",
    "Poker pot starter",
    "Bet",
    "Chip in chips",
    "Pot component",
    "Chip for a pot",
    "Poker starter",
    "Cough up",
    "It's just for openers",
    "Blue chip, maybe",
    "Penny, sometimes",
    "Pot grower?",
    "Penny, perhaps",
    "Amount to be raised, maybe",
    "Poker starter",
    "Pot starter",
    "It's just for openers",
    "You have to be upfront about this",
    "Kitty feed",
    "Morning's start, in old Rome?",
    "Initial offering?",
    "Individual share",
    "Pot starter",
    "Upfront money",
    "Pot builder",
    "Add to the pot",
    "Pot builder?",
    "Amount to be raised?",
    "Do this if you want to get a hand",
    "Pay to play, with \"up\"",
    "One chip, perhaps",
    "Penny, maybe",
    "Penny ___",
    "Kitty starter",
    "Pot starter",
    "Penny fee",
    "A chip, maybe",
    "Chip in a chip",
    "Poker payment",
    "Two chips, e.g.",
    "Temporal prefix",
    "Add to the pot",
    "Kitty feed",
    "Pot starter",
    "Chip in a chip",
    "Cash for cards",
    "Sometimes its upped",
    "Predeal requirement",
    "Make pots, in a way",
    "The \"A\" in A.M.",
    "Put up",
    "First chips",
    "Part of A.M.",
    "Poker chip, maybe",
    "Pot starter",
    "It's just for openers",
    "Chip, maybe",
    "Deal preceder",
    "Poker opener",
    "Pay, with \"up\"",
    "Cost of playing",
    "Not post",
    "Poker opener",
    "Buy into a poker game",
    "Stake",
    "Play money?",
    "Kitty starter",
    "Up-front amount",
    "Penny ___",
    "Make a pot",
    "Bettor's starter",
    "Chip in",
    "Chip in",
    "Put a stake on the table"
  ],
  "pipe": [
    "Quiet (down)",
    "What a plumber might fix a leak in",
    "Subject of a Magritte work (or not?)",
    "Prop for Santa Claus or Frosty the Snowman",
    "Part of a church organ",
    "Something that might spring a leak",
    "Accessory for Sherlock Holmes",
    "Appurtenance for a snowman",
    "Word with organ or cleaner",
    "Prop for Sherlock Holmes",
    "Quiet (down)",
    "Main, say",
    "Sherlock Holmes accessory",
    "Tobacco holder",
    "Prop for Popeye or Santa",
    "Accessory for a snowman",
    "Sherlock Holmes appurtenance",
    "\"___ down!\" (\"Quiet!\")",
    "Skateboarder's challenge",
    "Trap locale",
    "Weapon in Clue",
    "Water bearer",
    "Appurtenance for Santa or Sherlock Holmes",
    "Feature of a Norman Rockwell self-portrait",
    "Hookah, e.g.",
    "Subject of a Magritte painting",
    "Snowman's prop",
    "Snowman's prop",
    "Old King Cole accessory",
    "Sherlock Holmes prop",
    "Water carrier",
    "Appurtenance for Santa",
    "It might need a fitting",
    "Word that can follow the end of 18-, 27-, 46- or 61-Across",
    "Prop for Santa",
    "Popeye's tooter",
    "Kind of dream",
    "Sherlock Holmes prop",
    "Snowman prop",
    "Snowman prop",
    "Sherlock Holmes item",
    "Kind of organ or dream",
    "\"___ down now!\"",
    "St. Nick accessory",
    "Mouthpiece?",
    "Prop for Santa",
    "Tobacco holder",
    "Corncob or briar, e.g.",
    "Kind of cleaner",
    "Hugh Hefner prop"
  ],
  "cary": [
    "Elwes of \"The Princess Bride\"",
    "Actor Elwes",
    "Actor Elwes of \"The Princess Bride\"",
    "City of 150,000+ between Raleigh and Durham",
    "Leading man Grant",
    "Grant for moviemaking?",
    "Grant for a filmmaker?",
    "Grant for filmmaking?",
    "Grant for a movie?",
    "Raleigh suburb",
    "Grant in four Hitchcock films",
    "Grant for a filmmaker?",
    "Handsome Grant",
    "Actor Grant",
    "Hollywood's Grant",
    "Grant for a film?",
    "___ Middlecoff, 1949 and 1956 U.S. Open champ",
    "Grant of \"Notorious\"",
    "\"The Horse's Mouth\" novelist Joyce ___",
    "Grant of \"An Affair to Remember\"",
    "Ingrid's \"Notorious\" co-star",
    "Late great Grant"
  ],
  "elia": [
    "Princess ___ Martell on \"Game of Thrones\"",
    "Pseudonym of the essayist Charles Lamb",
    "Essayist who wrote \"Newspapers always excite curiosity. No one ever lays one down without a feeling of disappointment\"",
    "Director Kazan",
    "Director Kazan",
    "\"A Letter to ___,\" 2010 documentary co-directed by Martin Scorsese",
    "Kazan who directed \"East of Eden\"",
    "Kazan of film",
    "Director Kazan",
    "Man's name meaning \"Jehovah is God\"",
    "\"Essays of ___\"",
    "Lamb in literature",
    "Lamb, by another name",
    "\"Grace Before Meat\" essayist",
    "___ Martell, \"Game of Thrones\" princess",
    "\"On the Waterfront\" director Kazan",
    "Director Kazan",
    "Charles Lamb's \"Essays of ___\"",
    "\"East of Eden\" director Kazan",
    "Kazan who directed \"On the Waterfront\"",
    "Director Kazan",
    "Director Kazan",
    "Director Kazan",
    "\"The Praise of Chimney-Sweepers\" essayist",
    "\"On the Waterfront\" director Kazan",
    "\"Splendor in the Grass\" director Kazan",
    "\"Essays of ___\"",
    "Director Kazan",
    "\"Essays of ___\" (1823 volume)",
    "\"Modern Gallantry\" pen name",
    "Charles Lamb's pen name",
    "\"Popular Fallacies\" byline, 1826",
    "Noted literary pseudonym",
    "\"Essays of ___\"",
    "Kazan of Hollywood",
    "Kazan of Hollywood",
    "Director Kazan",
    "\"Confessions of a Drunkard\" writer, 1822",
    "\"Viva Zapata!\" director Kazan",
    "\"The South-Sea House\" essayist",
    "Director Kazan",
    "\"Popular Fallacies\" writer",
    "\"Dream-Children\" author, 1822",
    "Director Kazan",
    "Director Kazan",
    "\"Grace Before Meat\" pen name",
    "Marlon's \"On the Waterfront\" director",
    "Lamb not found on a farm",
    "\"All Fool's Day\" writer",
    "Pseudonym for Charles Lamb",
    "\"The Praise of Chimney-Sweepers\" essayist",
    "\"Old China\" essayist",
    "Director Kazan",
    "Charles Lamb alias",
    "London Magazine essayist",
    "Director Kazan",
    "\"The Praise of Chimney-Sweepers\" writer",
    "Director Kazan",
    "Literary pen name",
    "Hollywood's Kazan",
    "\"Dream Children\" essayist",
    "Director Kazan",
    "\"On the Waterfront\" director Kazan",
    "\"Essays of ___\"",
    "Director Kazan",
    "Kazan of Hollywood",
    "\"The Last Tycoon\" director Kazan",
    "\"A Chapter on Ears\" essayist",
    "Kazan who directed \"On the Waterfront\"",
    "Director Kazan",
    "Charles Lamb, pseudonymously",
    "Director Kazan",
    "Director Kazan",
    "Director Kazan",
    "\"Essays of ___\"",
    "He directed Marlon",
    "Lamb by another name",
    "\"East of Eden\" director Kazan",
    "\"Modern Gallantry\" essayist",
    "\"Distant Correspondents\" writer",
    "Charles Lamb's nom de plume",
    "Kazan who directed \"On the Waterfront\"",
    "Director Kazan",
    "Essayist's alias",
    "Director Kazan",
    "Pen name for Charles Lamb",
    "\"Last Essays of ___,\" 1833",
    "Charles Lamb's nom de plume",
    "Charles Lamb, pseudonymously",
    "Old London Magazine essayist",
    "\"Dream Children\" essayist",
    "Director Kazan",
    "Charles Lamb's nom de plume",
    "Director Kazan",
    "English literary pseudonym",
    "Director Kazan",
    "Kazan who won a 1-Down for Lifetime Achievement",
    "Director Kazan",
    "Director Kazan",
    "Director Kazan",
    "Essayist's alias",
    "Oscar winner Kazan",
    "Director Kazan",
    "Lamb's nom",
    "English literary alias",
    "\"A Chapter on Ears\" writer",
    "\"The Praise of Chimney-Sweepers\" essayist",
    "Director Kazan",
    "Charles Lamb's pen name",
    "\"Essays of ___\"",
    "\"A Chapter on Ears\" essayist",
    "19th-century alias",
    "Essayist Lamb",
    "Director Kazan",
    "\"Table Talk\" essayist",
    "Charles Lamb, pseudonymously",
    "Literary pseudonym",
    "Pen name of Charles Lamb",
    "Essayist's alias",
    "Director Kazan",
    "\"Mrs. Battle's Opinions on Whist\" writer",
    "\"Imperfect Sympathies\" essayist",
    "Director Kazan",
    "Film director Kazan",
    "Oscar winner Kazan",
    "Charles Lamb's pen name",
    "\"Last Essays of ___,\" 1833",
    "\"On the Waterfront\" director Kazan",
    "Literary pseudonym",
    "Director Kazan",
    "Essayist's alias",
    "Charles Lamb's pseudonym",
    "Director Kazan",
    "Lamb's pen name",
    "London Magazine writer",
    "\"Essays of ___\"",
    "Charles Lamb, pseudonymously",
    "\"Essays of ___\"",
    "Lifetime Oscar winner Kazan",
    "London Magazine essayist",
    "English essayist",
    "Lamb's \"Essays of ___\"",
    "Marlon's \"On the Waterfront\" director",
    "Lamb's pen name",
    "Lamb alias",
    "Director Kazan",
    "He directed Marlon",
    "Lamb's pseudonym",
    "Lamb alias",
    "Director Kazan",
    "Friend of Coleridge",
    "He was a Lamb",
    "\"Essays of ___,\" 1823",
    "Lamb's pen name",
    "Director Kazan",
    "Director Kazan",
    "Director Kazan",
    "Lamb's sobriquet",
    "Noted English letter writer",
    "Three-time director for Marlon",
    "Charles Lamb pseudonym",
    "Lamb's alias",
    "\"Essays of ___\"",
    "Lamb's pen name",
    "Charles Lamb, to readers",
    "Hollywood's Kazan",
    "Film director Kazan",
    "Director Kazan",
    "\"Essays of___,\" 1823",
    "Lamb's pen name",
    "Literary pseudonym",
    "\"A Chapter on Ears\" essayist",
    "Oscar-winning Kazan",
    "Lamb pen name",
    "\"Essays of ___\"",
    "Director Kazan",
    "Literary pen name",
    "Lamb's nom de plume",
    "Lamb's alias",
    "London Magazine essayist",
    "Literary pseudonym",
    "Lamb's name",
    "\"Dream Children\" essayist",
    "\"Tales From Shakespeare\" writer",
    "Director Kazan",
    "Lamb pseudonym",
    "Lamb's name",
    "\"On the Waterfront\" director Kazan",
    "Lamb's pen name",
    "Literary pseudonym",
    "Lamb of yore",
    "Director Kazan",
    "See 31-Across",
    "London essayist"
  ],
  "cool": [
    "Refrigerate",
    "\"Suh-weet!\"",
    "Air-condition",
    "\"Neat!\"",
    "Hip",
    "Play it ___",
    "\"Awesome!\"",
    "Hip",
    "Hunky-dory",
    "Aplomb",
    "Pie recipe directive",
    "With 66- and 67-Across, source of this puzzle theme's quote",
    "Dissipate, as ardor",
    "Air-condition",
    "Composure",
    "\"That's fine with me!\"",
    "Snazzy",
    "Hunky-dory",
    "\"Neato!\"",
    "Hip",
    "Refrigerate",
    "Hip",
    "Air-conditioned",
    "Snazzy",
    "Collected",
    "Nippy",
    "\"Fantastic!\"",
    "Hip",
    "56, say",
    "Refrigerate",
    "In the 40's, say",
    "Not nerdy",
    "Not nerdy",
    "Hip",
    "Fan",
    "\"Neato!\"",
    "\"Rad!\"",
    "In the 50's or 60's, say",
    "Hip",
    "Groovy",
    "\"Neato!\"",
    "Self-assurance",
    "Refrigerate",
    "Groovy, updated",
    "With it",
    "Hip",
    "Refrigerate",
    "Hip",
    "Refrigerate",
    "Composed",
    "Refrigerate",
    "Hip",
    "Hip",
    "Air-conditioned"
  ],
  "eons": [
    "A long, long time",
    "Seemingly forever",
    "Long runs?",
    "Millennia",
    "Long periods",
    "Many millennia",
    "Very long stretches of time",
    "Long stretches",
    "Earth's longest time divisions, geologically",
    "Long stretches",
    "Ages and ages",
    "Practically an eternity",
    "Ages and ages",
    "Many, many, many, many, many moons",
    "Countless centuries",
    "Immensely long stretches",
    "Forever and a day",
    "Ages and ages",
    "They go to great lengths",
    "Forever and ever",
    "Volcanoes develop over them",
    "Years and years and years",
    "Ages and ages",
    "Very long spans",
    "Forever and a day",
    "Immense spans",
    "Ages and ages",
    "Many, many years",
    "Stars exist over them",
    "Many millennia",
    "Huge spans",
    "Life span of a star",
    "An eternity",
    "Mountains have grown over them",
    "Time immemorial",
    "Geological units",
    "Time since the Big Bang",
    "Mountains form over them",
    "Forever",
    "Eternities",
    "Vast stretches of time",
    "A seeming eternity",
    "How long it takes mountains to form",
    "An eternity",
    "Forever, basically",
    "A seeming eternity",
    "Mountains have developed over them",
    "Ages and ages",
    "Years on end",
    "Time on end",
    "Millennia on end",
    "A lot of the time?",
    "Countless centuries",
    "Millennia on end",
    "Many ages",
    "Forever and ever",
    "Immense time spans",
    "Just about forever",
    "Ages and ages",
    "Time to evolve?",
    "Time immemorial",
    "Ages upon ages",
    "A very long time",
    "Time immemorial",
    "For-EV-er",
    "Ages and ages",
    "Time on end",
    "Ages upon ages",
    "Many millennia",
    "Seemingly forever",
    "Ages and ages",
    "A seeming eternity",
    "Long times",
    "How long it takes canyons to form",
    "Billions of years",
    "Stretches of history",
    "Seemingly forever",
    "Years and years and years",
    "Ages and ages",
    "Eternities",
    "Time immemorial",
    "Major stretches",
    "A long, long time",
    "Lengthy time units",
    "Geologic periods",
    "Awfully long time",
    "An extremely long time",
    "Great times",
    "Ages and ages",
    "Time immemorial",
    "Forever and a day",
    "Long periods",
    "Time immemorial",
    "Time on end",
    "Millions of years",
    "Many ages",
    "Ages and ages",
    "Ages and ages",
    "Long stretches",
    "Seeming eternity",
    "A dog's age",
    "Seemingly forever",
    "An eternity, seemingly",
    "Almost forever",
    "Time on end",
    "Millions of years",
    "Almost forever",
    "Years and years",
    "Spans of history",
    "Years and years",
    "Years on end",
    "Almost forever",
    "Almost forever",
    "A long time to wait",
    "A lo-o-ong time",
    "Donkey's years",
    "Astronomical figures",
    "Time and time again",
    "Astronomical periods",
    "Stretch of history",
    "Ages and ages",
    "Years on end",
    "Many ages",
    "Incalculable amount of time",
    "Seemingly forever",
    "Almost forever",
    "A dog's age",
    "Geologic periods",
    "Billions of years",
    "Years and years",
    "Many millennia",
    "A lot of time",
    "Ages and ages",
    "Years on end",
    "Seemingly forever",
    "Geologic time divisions",
    "Ages and ages",
    "Eternity",
    "Ages and ages",
    "Long, long time",
    "Geologists' times",
    "Many millennia",
    "Years and years"
  ],
  "yolk": [
    "Something that may be broken in a kitchen",
    "The \"sun\" in sunny-side-up eggs",
    "One getting a beating in the kitchen?",
    "White's counterpart",
    "See 32-Across",
    "Fatty part of an egg",
    "Center of an egg",
    "Yellow ball",
    "It's enveloped in white",
    "White's opposite",
    "Central part",
    "The sun, in sunnyside up",
    "The sunny side, in sunny side up",
    "Egg part",
    "Yellow in white?"
  ],
  "visa": [
    "Checked item for some travelers",
    "Request at a consulate",
    "Travel item",
    "Its card numbers all begin with 4",
    "Document for foreign travel",
    "Charging option",
    "Stamp collector?",
    "Company added to the Dow 30 in 2013",
    "Aid for one going places?",
    "Foriegn traveler's need",
    "Big name in plastic",
    "Something needing a stamp",
    "Certain stamp of approval",
    "MasterCard rival",
    "MasterCard competitor",
    "Big name in plastic",
    "Something that's stamped",
    "MasterCard alternative",
    "Discover alternative",
    "Place for a stamp",
    "MasterCard rival",
    "You may need it going in",
    "You can get a charge out of it",
    "Entry need, maybe",
    "Document checked at a border",
    "MasterCard rival",
    "Border guard's request",
    "It has one stripe on its back",
    "Name in plastic",
    "Plastic handle?",
    "Plastic producer",
    "Certain plastic",
    "MasterCard rival",
    "Entrance requirement",
    "Customs request",
    "MasterCard alternative",
    "Traveler's need, maybe",
    "Big name in plastic",
    "Permission to go",
    "Traveler's O.K.",
    "MasterCard alternative",
    "Embassy paper",
    "Passport endorsement",
    "International traveler's need",
    "Discover rival"
  ],
  "oven": [
    "___-ready",
    "Metaphor for a 100-degree day",
    "Pizzeria fixture",
    "Bakery fixture",
    "Place for a pizza",
    "Hot spot",
    "___-fresh",
    "Kiln, e.g.",
    "You might get your mitts on this",
    "Place to cook a turkey",
    "Something you might get your mitts on",
    "What's within your range?",
    "Rack site",
    "Kind of mitt",
    "Cookie cooker",
    "Baker's need",
    "Roasting place",
    "Plot feature in \"Hansel and Gretel\"",
    "Place where things may be burned",
    "Kiln",
    "Microwave, e.g.",
    "Where a cake is baked",
    "Place for a roast",
    "Baker",
    "Pizzeria fixture",
    "___-ready",
    "Something to get your mitts on?",
    "Baking chamber",
    "Pizzeria fixture",
    "\"Top Chef\" appliance",
    "Place for a roast",
    "Hot spot in \"Hansel and Gretel\"",
    "Pizzeria fixture",
    "Cause of the witch's demise in \"Hansel and Gretel\"",
    "Rack locale",
    "Womb, jocularly",
    "Pizzeria fixture",
    "It takes the cake",
    "Something you might get your mitts on",
    "Cookie baker",
    "Kitchen fixture",
    "Pizzeria need",
    "Word before rack or mitt",
    "Forge fixture",
    "Place for a roast",
    "*Place for a roast",
    "Firing need",
    "It's often operated with a dial",
    "Bakery fixture",
    "Brickmaking need",
    "It takes the cake",
    "Place to use Easy-Off",
    "Place for a roast",
    "Pizza place",
    "Rack holder",
    "Kitchen dial site",
    "Oast",
    "Pizzeria fixture",
    "Pizzeria fixture",
    "Tandoor, e.g.",
    "Rack holder",
    "Pizzeria fixture",
    "Hot room, colloquially",
    "It takes the cake",
    "Stove part",
    "Kitchen hot spot",
    "Place to broil",
    "Breadmaking place",
    "Pizzeria fixture",
    "See 123-Across",
    "Dutch ___",
    "What a 38-Across needs",
    "Microwave, e.g.",
    "Kind of mitt",
    "With 34-Across, places to set pies to bake",
    "Bread maker",
    "Place for a cake",
    "___-ready",
    "___-dried",
    "You might get your mitts on it",
    "Hot spot",
    "Oast",
    "Cooker",
    "Kiln",
    "Hot spot",
    "Roaster",
    "Place to wear gloves",
    "Place for a roast",
    "It's hot in here",
    "Dutch cooker",
    "Cooker",
    "Where to use Easy-Off",
    "Rising locale?",
    "Place for a timer",
    "It's within your range",
    "Place for a turkey",
    "Place to wear a mitt",
    "Dutch ___",
    "It's within your range",
    "Bakery fixture",
    "\"Hansel and Gretel\" prop",
    "Baker's need",
    "Baker's need",
    "Rising locale?",
    "Bread chamber",
    "Broiling locale",
    "Heater",
    "Dutch ___",
    "Dutch ___",
    "Pizzeria feature",
    "Kiln",
    "Child's appliance",
    "Pizza place",
    "Baker",
    "Kind of cleaner",
    "Dutch ___",
    "Place to broil",
    "Rack site",
    "The witch's end in \"Hansel and Gretel\"",
    "Broiler",
    "Turkey roaster",
    "Kind of mitt",
    "Kenmore product",
    "Place to hold a banquet roast?",
    "Baker",
    "Where Hansel was headed",
    "Hot spot",
    "Hot spot",
    "Kind of mitt",
    "Kiln",
    "Hot spot",
    "Pizzeria appliance",
    "Thermometer site",
    "Kind of rack",
    "Kind of thermometer",
    "Pizzeria need",
    "Pizzeria appliance",
    "Tandoor",
    "Kind of cleaner",
    "Kind of mitt",
    "Hot chamber",
    "Browning locale",
    "Baker",
    "Where one's goose is cooked"
  ],
  "coat": [
    "Outer layer",
    "Cover",
    "Cover",
    "Cover completely",
    "Gift from Jacob to Joseph, in the Bible",
    "Application of paint",
    "Fur",
    "Paint layer",
    "Warm winter wear",
    "Chesterfield, for one",
    "Layer",
    "Jacket",
    "Wool, for a sheep",
    "Lacquer, e.g.",
    "Layer of paint",
    "Layer of paint",
    "Jacket",
    "Layer",
    "Mackinaw or Norfolk",
    "Paint layer",
    "Dog breed distinction",
    "It may be checked, in more ways than one",
    "Slather",
    "Ulster, e.g.",
    "Cover-up",
    "Completely cover",
    "Jacket",
    "Ulster or Norfolk",
    "Layer",
    "Ulster, for one",
    "Jacket",
    "Judging point at a dog show",
    "Mac, e.g.",
    "Paint layer",
    "Snow on the ground, say",
    "Prince Albert, for one",
    "Fur",
    "Jacket",
    "Jacket",
    "Fur, e.g.",
    "Jacket",
    "London Fog product",
    "Duster, for one",
    "Checked item",
    "Paint layer",
    "Jacket",
    "Part of a suit",
    "Mac, e.g.",
    "Mackintosh, e.g.",
    "Veneer",
    "Fur or fleece",
    "Jacket",
    "It may be checked, in two senses",
    "Ulster, e.g.",
    "Fur, say",
    "Chesterfield or ulster",
    "Checked item",
    "It can be checked",
    "Prince Albert, e.g.",
    "London Fog, e.g.",
    "Snowfall",
    "Wrap",
    "Cover",
    "Chesterfield or ulster",
    "Checked item",
    "Jacket",
    "Jacket",
    "Fur",
    "Cover",
    "Layer",
    "Winter need",
    "Layer of paint",
    "___ of arms",
    "Chesterfield, e.g.",
    "Blazer",
    "Paint layer"
  ],
  "shed": [
    "Get rid of",
    "Storage for garden tools",
    "Lose a layer",
    "Remove one's coat?",
    "Place to store things",
    "What dogs do in the spring",
    "Take off",
    "Place to store garden tools",
    "Backyard building",
    "What snakes and lizards do periodically with their skin",
    "What dogs do in the spring",
    "Lose, as fur",
    "Molt",
    "Place to store a lawn mower",
    "Outbuilding for storage",
    "Take off",
    "What hairy dogs do in the spring",
    "Molt",
    "Lose, as fur",
    "Sloughed off",
    "What many 100-Across do in the spring",
    "Tool building",
    "Lose, as a coat",
    "Place to store mowers and rakes",
    "Spade holder",
    "Shake off",
    "Drop off",
    "\"___ Rather Be With Me\" (1967 hit)",
    "Shake off",
    "Get rid of",
    "Saw home?",
    "Where spades may be laid down",
    "Drop, as pounds",
    "What many furry animals do in the spring",
    "Hammer and sickle holder, maybe",
    "What cats often do in the spring",
    "Slough off",
    "Leave one's coat behind?",
    "Storage unit",
    "Molt",
    "Get out of, as clothing",
    "Drop",
    "Tool building",
    "Radiate, as light",
    "Molt",
    "Get rid of",
    "Get rid of",
    "Lose hair",
    "Lawn mower's spot",
    "Where to store a lawn mower",
    "Humble home",
    "Garage alternative",
    "Outbuilding",
    "Place for a lawn mower",
    "Drop",
    "Tool holder",
    "Peel off",
    "Garden adjunct",
    "Mower stower",
    "Peel off",
    "Cast off",
    "Place for a mower",
    "Lawn mower site",
    "Outbuilding",
    "Tool building",
    "Storage building",
    "Shake off",
    "Got rid of",
    "Take off",
    "Tool building",
    "Cast off",
    "Abandon",
    "Tool site",
    "Get rid of",
    "Slough",
    "Lose, as skin",
    "Slough off",
    "Remove",
    "Strip off",
    "Tool repository",
    "Outbuilding",
    "Cast off",
    "Throw off",
    "Throw off",
    "Flake off",
    "Shack",
    "Storage spot",
    "Took off",
    "Toolhouse",
    "\"___ Rather Be With Me\" (1967 hit)",
    "Get rid of",
    "Storage site",
    "Drop off",
    "Outbuilding",
    "Strip",
    "Tools locale",
    "Jerry-built structure",
    "Tool storage area"
  ],
  "nays": [
    "Count against?",
    "Bill blockers",
    "Those against",
    "Second side to vote",
    "Unsupportive votes",
    "They may block passage",
    "One side of a vote",
    "Thumbs-down votes",
    "\"No\" votes",
    "Half of a Senate vote",
    "Cons",
    "Oppositionists",
    "Unsupportive words",
    "Passage preventers, often",
    "Passage blockers",
    "Bill blockers",
    "Parliamentary faction",
    "Senate tally",
    "Side in a debate",
    "They may prevent passage",
    "Ayes' opposite",
    "Second half of a vote",
    "Thumbs-down votes",
    "One side of a vote",
    "Blocking attempts",
    "Some floor votes",
    "Votes against",
    "Some floor votes",
    "Side in a vote",
    "Those opposed",
    "Bill's opponents",
    "Registrations of opposition",
    "Votes against",
    "Refusals",
    "Some votes",
    "Senate votes",
    "The opposition"
  ],
  "labs": [
    "Screening sites",
    "See 6-Down",
    "Workplaces where white coats are worn",
    "Blood work locales",
    "Places to wear goggles",
    "Places for vials",
    "Science class adjuncts",
    "Hands-on science classes",
    "Parts of science courses",
    "\"Chocolate\" dogs",
    "Features of biology classes",
    "Experiment sites",
    "Where to find solutions",
    "Test runners",
    "Experiment sites",
    "Culture centers?",
    "R&D sites",
    "Places for experiments",
    "Bell ___",
    "Science class sessions",
    "Culture development sites",
    "Bell ___",
    "Culture areas?",
    "Bell ___",
    "Family dogs, for short",
    "Where many cultures thrive",
    "They might be chocolate",
    "Testing facilities",
    "Features of science classes",
    "Pharmaceutical workplaces",
    "School science rooms",
    "Some course requirements",
    "Test sites",
    "Child-friendly dogs, informally",
    "Test areas",
    "Testing centers",
    "Places for experiments",
    "Chem classes",
    "Diagnostic data, informally",
    "Experiment sites",
    "Certain retrievers, for short",
    "Places for experiments",
    "Teaching venues",
    "Places for experiments",
    "Sites for some analyses",
    "Where cures are discovered",
    "Handsome dogs, informally",
    "Science building features",
    "Certain dogs, for short",
    "Bell ___",
    "Family dogs",
    "Workplaces for Edison",
    "Bell ___",
    "Test venues",
    "Big dogs, for short",
    "Bell ___",
    "Bell ___",
    "Etna locations"
  ],
  "cece": [
    "Singer Peniston with the 1991 top 5 hit \"Finally\"",
    "Winans with 12 Grammys",
    "Jess's best friend on TV's \"New Girl\"",
    "Reduplicative girl's name",
    "Gospel singer Winans",
    "Best friend of Jess on Fox's \"New Girl\"",
    "Gospel star Winans",
    "R&B's Peniston",
    "Gospel singer Winans",
    "Woman's name that sounds like a repeated letter",
    "Singer Peniston",
    "Gospel singer Winans",
    "Member of the singing Winans family",
    "Singer Winans",
    "R&B singer Peniston",
    "Gospel singer Winans",
    "Grammy-winning Winans",
    "Gospel singer Winans",
    "Singer Winans",
    "Grammy winner Winans",
    "Grammy winner Winans",
    "R & B's Winans",
    "\"Alphabetic\" girl's name",
    "Singer Peniston with the 1991 hit \"Finally\"",
    "Gospel singer Winans",
    "Gospel singer Winans",
    "Singer Winans"
  ],
  "taut": [
    "Opposite of relaxed",
    "Opposite of loose",
    "Stretched out",
    "Opposite of loose",
    "Like violin strings",
    "Opposite of slack",
    "Full of tension",
    "Lacking slack",
    "Like drumheads",
    "Stretched tight",
    "Like a tightrope, more so than a slackline",
    "Not at all lax",
    "Not sagging at all",
    "Like a well-written thriller",
    "Ready to snap, maybe",
    "Lacking slack",
    "Like a drumhead",
    "Stretched tight",
    "Like a rope during a tug-of-war match",
    "Like faces after face-lifts",
    "Stretched tight",
    "Like a guitar string",
    "Not relaxed",
    "Pulled tight",
    "Having little give",
    "Allowing no play",
    "Stretched to the max",
    "Under tension",
    "Tensed",
    "Strained",
    "Fully stretched",
    "Tense",
    "Strained",
    "Stretched to the limit",
    "Without slack",
    "Lacking slack",
    "Like guitar strings",
    "Lacking slack",
    "Like a trampoline's surface",
    "Stretched",
    "Drawn tight",
    "Fully stretched",
    "Stretched tight",
    "Ex-lax?",
    "Unloose",
    "Without wrinkles",
    "Lacking slack",
    "Keyed up",
    "Tightly stretched",
    "Tightly strung",
    "Pulled tight",
    "Suspenseful",
    "Stretched",
    "Ex-lax?",
    "Drawn tight",
    "Tight",
    "Unrelaxed",
    "Lacking play",
    "Strung tightly",
    "Having no play",
    "Tight as a drum",
    "Not slack",
    "Without slack",
    "Unbending",
    "Lacking slack",
    "Like some nerves",
    "Drawn tight",
    "Tense"
  ],
  "orso": [
    "Thereabouts",
    "\"Approximately speaking\"",
    "Not exactly",
    "\"... ish\"",
    "... approximately",
    "\"Ish\"",
    "Approximately",
    "Roughly",
    "Approximately",
    "Thereabouts",
    "Thereabouts",
    "Thereabouts",
    "\"... ish\"",
    "Approximately",
    "\"Ish\"",
    "Estimator's phrase",
    "Estimation words",
    "Approximately",
    "Around there",
    "Approximately",
    "Approximately",
    "Qualifying phrase",
    "Approximately",
    "\"... ___ they say\"",
    "Approximately",
    "Guesstimate words",
    "Guesstimate phrase",
    "\"... ish\"",
    "Approximately",
    "\"Ish\"",
    "Words akin to \"-ish\"",
    "Approximately",
    "\"... ish\"",
    "+/-",
    "Words of approximation",
    "Roundabouts",
    "\"Ish\"",
    "Approximately",
    "Approximately",
    "Approximately",
    "+/-",
    "Guesstimate's ending",
    "Approximately",
    "About",
    "Guesstimate words",
    "Words of estimation",
    "Approximately",
    "\"___ I hear\"",
    "Approximately",
    "Approximately",
    "Approximately",
    "About",
    "Nearly",
    "+/-",
    "Approximately",
    "Thereabouts",
    "Roughly",
    "Approximately",
    "Roughly",
    "Roughly",
    "About",
    "\"... ___ he said\"",
    "Words of approximation",
    "Roughly",
    "Guesstimate phrase",
    "About",
    "Roughly",
    "Approximately",
    "\"In that area\"",
    "Roughly",
    "\"... maybe more, maybe less\"",
    "Maybe more, maybe less",
    "Estimator's phrase",
    "Roughly",
    "Roughly",
    "Approximating words",
    "Guesstimator's phrase",
    "Roughly",
    "Approximately",
    "\"In that range\"",
    "Roughly",
    "Approximately",
    "Guesstimate phrase",
    "Approximately",
    "Guesstimate phrase",
    "About",
    "Roughly",
    "Roughly",
    "Estimate follower",
    "Approximately",
    "Roughly",
    "About",
    "About",
    "Estimator's phrase",
    "Roughly",
    "Guess qualifier",
    "Approximately",
    "Approximately",
    "Guesstimate words",
    "\"... ___ I'm told\"",
    "Guesstimate words",
    "Roughly",
    "Roughly",
    "Guesstimate phrase",
    "\"... ___ they say\"",
    "Roughly",
    "About",
    "Roughly",
    "\"...___ they say\"",
    "Estimator's phrase",
    "Ballpark figure follower",
    "Roughly",
    "Roughly",
    "Give or take",
    "Approximately",
    "Approximately",
    "Approximately",
    "Approximately"
  ],
  "eden": [
    "___ Prairie, suburb of Minneapolis",
    "Paradise lost",
    "Paradise",
    "Setting for a scene in the Sistine Chapel",
    "Setting depicted in the Sistine Chapel",
    "Locale near the headwaters of the Tigris and Euphrates, some believe",
    "Garden of earthly delights",
    "Anthony ___, 1950s British P.M.",
    "Town near Buffalo that sounds like paradise",
    "Fall setting",
    "\"East of ___\" (Steinbeck novel)",
    "Genesis garden",
    "Setting for a scene in the Sistine Chapel",
    "Paradise",
    "Perfect world",
    "Setting for a Sistine Chapel painting",
    "Garden with fruit trees",
    "Home for Adam and Eve",
    "Source of the Tigris and Euphrates, per Genesis",
    "Bad place to go apple-picking?",
    "Where fruit-picking originated?",
    "Biblical garden",
    "The plot of Genesis?",
    "Heaven on earth",
    "Starting point",
    "Site of the fall of man",
    "Biblical paradise",
    "Fall location",
    "Paradise",
    "Elysium",
    "Churchill's successor in 1955",
    "Apple's first location?",
    "Biblical garden",
    "Depiction in Bosch's \"The Garden of Earthly Delights\"",
    "First place?",
    "Bible garden",
    "Genesis garden",
    "Setting for the first panel of Bosch's \"The Garden of Earthly Delights\"",
    "Plot in Genesis",
    "Fall setting",
    "Site of an early fall",
    "Temptation location",
    "Dreamland",
    "First garden site",
    "Idyllic place",
    "\"This earth of majesty, this seat of Mars, / This other ___, demi-paradise\": \"Richard II\"",
    "Genesis locale",
    "Genesis garden",
    "Genesis garden",
    "Successor to Churchill",
    "Genesis garden",
    "What's lost in \"Paradise Lost\"",
    "\"Paradise Lost\" setting",
    "Old Testament paradise",
    "Site of a famous eviction",
    "Genesis garden",
    "Shangri-la",
    "Place whose population was 1, then 2, then 0",
    "P.M. after Churchill",
    "See 62-Across",
    "Sistine Chapel depiction",
    "Garden of ___",
    "Setting for paintings by Tintoretto and Hieronymus Bosch",
    "Depiction in Bosch's \"The Garden of Earthly Delights\"",
    "British P.M. between Churchill and Macmillan",
    "The Bible's Garden of ___",
    "First place",
    "Biblical garden",
    "Starter home?",
    "Locale painted on the Sistine Chapel ceiling",
    "1950s British P.M. Anthony ___",
    "First place",
    "Tree of Life locale",
    "Idyllic place",
    "Biblical garden",
    "Sistine Chapel setting",
    "Garden with forbidden fruit",
    "___ Prairie, Minn.",
    "Site for a famed garden",
    "Successor to Churchill",
    "Dreamland",
    "Tree of Life locale",
    "Dystopia's opposite",
    "Starting point of a long race?",
    "Setting for a panel of Bosch's \"The Garden of Earthly Delights\"",
    "Source of four great rivers, in the Bible",
    "Adam's apple site",
    "Fall location",
    "Paradise",
    "Biblical garden",
    "Starting point?",
    "Elysium",
    "Sistine Chapel painting setting",
    "Location of a bad apple?",
    "Paradise lost",
    "Locale painted in the Sistine Chapel",
    "Idyllic garden",
    "Paradise lost in \"Paradise Lost\"",
    "Fall setting",
    "Original sin locale",
    "Biblical garden",
    "Trouble-free place",
    "Biblical garden",
    "Elysium",
    "Genesis place",
    "Fall scene",
    "Ideal world?",
    "Genesis locale",
    "Starting point?",
    "Setting for a fall",
    "Garden of ___",
    "Site of the first human sin",
    "Place with a talking serpent",
    "Adam and Eve's garden",
    "Garden of ___",
    "Utopia",
    "First place",
    "Blissful spot",
    "Place of bliss",
    "Prelapsarian home",
    "Setting for Genesis 2:8-25",
    "Its entrance was barred with a flaming sword",
    "It's depicted on the Sistine Chapel ceiling",
    "Genesis garden",
    "Adam and Eve's first home",
    "British P.M. between Churchill and Macmillan",
    "___ Prairie, Minn.",
    "Elysium",
    "Fall place",
    "Sir Anthony formerly of 10 Downing Street",
    "An old couple fell in it",
    "Location for the Fall",
    "Garden with an apple tree",
    "Starting point?",
    "Shangri-la",
    "Tree of Life locale",
    "Heavenly place",
    "Genesis garden",
    "Fall site",
    "Paradise",
    "Adam and Eve's first residence",
    "First couple's home",
    "First place?",
    "Paradise lost",
    "Noted garden site",
    "Home for Adam and Eve",
    "Xanadu",
    "Garden of ___",
    "Genesis garden",
    "Place of bliss",
    "Scene of a fall",
    "Barbara of \"I Dream of Jeannie\"",
    "Heaven on earth",
    "Scene of a fall",
    "Place of bliss",
    "Heaven on earth",
    "Fall setting",
    "Biblical place of innocence",
    "Genesis garden",
    "1950s British P.M.",
    "Fall place",
    "Fall setting",
    "Starting point",
    "Genesis garden",
    "The river Pison flowed from it",
    "Fall setting",
    "Heaven on earth",
    "Starting point?",
    "Genesis garden",
    "First garden",
    "Idyllic spot",
    "Scene of a fall",
    "Fall site in Genesis",
    "Shangri-la",
    "Biblical fall site",
    "Genesis garden",
    "Biblical plot",
    "Creation location",
    "Place of temptation",
    "Hebrew for \"delight\"",
    "1950's British P.M.",
    "See 32-Across",
    "Genesis garden",
    "The first place",
    "Genesis garden",
    "Utopian spot",
    "Hebrew for \"delight\"",
    "Home for Adam and Eve",
    "Place at the start",
    "Fall locale",
    "Barbara who played a TV genie",
    "Fall locale",
    "Genesis locale",
    "Home for Adam and Eve",
    "Innocence locale",
    "Garden site",
    "Place of bliss",
    "Pristine plot",
    "Barbara of \"I Dream of Jeannie\"",
    "Paradise lost",
    "First home",
    "Genesis home",
    "Noted garden site",
    "Primeval plot",
    "Fall locale",
    "Heaven",
    "Shangri-la",
    "Forbidden fruit site",
    "Scene of a fall",
    "___ Prairie, Minn.",
    "Idyllic garden",
    "Isn't that just perfect?",
    "Fairyland",
    "Paradise lost",
    "Steinbeck's \"East of ___\"",
    "Garden spot",
    "Utopia",
    "First family's home",
    "Shangri-la",
    "Genesis garden",
    "1950's British P.M.",
    "Garden of ___",
    "Fall place",
    "See 47-Down",
    "Tempting spot",
    "First lady's residence",
    "Tree of life site",
    "Fall locale",
    "Tempting garden",
    "The paradise of \"Paradise Lost\"",
    "Early home",
    "50's British P.M. Anthony",
    "Origin of man",
    "Delightful place",
    "P.M. before Macmillan",
    "Place of bliss",
    "Starting place",
    "Genesis garden",
    "Ideal place",
    "Fall setting",
    "Genesis paradise",
    "Nice place",
    "1950's British P.M.",
    "Perfect place",
    "Garden with forbidden fruit",
    "Perfect place",
    "Garden with a snake",
    "Land west of Nod",
    "Paradisiacal place",
    "First couple's place",
    "1950's British P.M.",
    "TV genie portrayer",
    "Adam's apple location?",
    "Perfect plot",
    "British P.M. before Macmillan",
    "Starting point",
    "Adam's apple area?",
    "Place of bliss",
    "Nod neighbor",
    "Place name that's Hebrew for \"pleasure\"",
    "First home",
    "Genesis garden",
    "1950's P.M.",
    "See 107-Down",
    "Apple of temptation site",
    "Adam and Eve's place",
    "Genesis garden",
    "Fall venue",
    "Fall place",
    "Adam's arboretum",
    "Elysium",
    "First place",
    "Worry-free locale",
    "Genesis locale",
    "Utopia",
    "First home",
    "Tree of life locale",
    "First place?",
    "Garden with a snake",
    "Where it all started",
    "First place",
    "Perfect place",
    "\"The Grey Room\" novelist Phillpotts",
    "Shangri-La",
    "First place?",
    "First Earl of Avon",
    "Place of complete content",
    "First place",
    "First family's home",
    "Hebrew for \"delight\"",
    "Garden with the tree of life",
    "Paradise",
    "Churchill successor",
    "See 63-Across",
    "Tree of life site",
    "Paradise",
    "Plot in Genesis",
    "Biblical plot",
    "Heaven on earth",
    "Never-never land",
    "1950's British P.M.",
    "1950's British P.M.",
    "Genesis garden",
    "Paradise",
    "First place",
    "Shangri-la",
    "Utopia",
    "Pleasantville, so to speak",
    "God's little acre?",
    "Genesis garden",
    "First place",
    "Successor to Churchill",
    "46-Down's \"The Dragons of ___\"",
    "Land west of Nod",
    "Biblical garden",
    "Paradise",
    "Utopia",
    "Site of temptation",
    "Serpent's home",
    "Genesis garden",
    "Genesis setting",
    "Perfect place",
    "Carl Sagan's \"The Dragons of ___\"",
    "\"Paradise Lost\" setting",
    "Author Phillpotts",
    "Fall setting",
    "Utopia",
    "Paradise lost",
    "Jack London's \"Martin ___\"",
    "Foreign Secretary under Churchill",
    "Hagman TV co-star",
    "First place",
    "Site of the tree of life",
    "Land west of Nod",
    "He became Earl of Avon",
    "Idyllic place",
    "Paradise",
    "Perfect place",
    "First place",
    "Garden site",
    "British pol portrayed in \"Mission to Moscow\"",
    "Paradise",
    "Paradise",
    "Shangri-la",
    "Perfect place",
    "Place of temptation",
    "First garden",
    "Paradise",
    "Sir Anthony",
    "First place",
    "Onetime Hagman co-star",
    "Jeannie portrayer",
    "English author ___ Phillpotts",
    "Garden spot",
    "Biblical garden",
    "First lady's residence",
    "Paradise",
    "Eve's domain",
    "Biblical paradise",
    "Nirvana",
    "Adam's arboretum",
    "Heaven on earth",
    "Paradise",
    "Shangri-La",
    "First place",
    "First place",
    "Biblical beginning",
    "P.M. before Macmillan",
    "Adam and Eve locale",
    "Paradise",
    "Paradise",
    "Heaven on earth",
    "Nirvana",
    "\"Harper Valley P.T.A.\" star",
    "Old New York tourist site, ___ Musee",
    "Churchill successor",
    "Paradise paradigm",
    "Anthony or Barbara",
    "Eve's place",
    "Sagan's \"The Dragons of ___\"",
    "Heaven on Earth",
    "Genesis locale",
    "Macmillan's predecessor as P.M.",
    "Churchill successor"
  ],
  "oneg": [
    "Ten C-notes",
    "25-Across on Earth, in brief",
    "Force on Earth, in brief",
    "Rare blood type, for short",
    "Universal donor's blood type, in brief",
    "What \"exaggerated\" is sometimes misspelled with",
    "Universal donor's blood type, for short",
    "Universal donor's blood type, informally",
    "Force on Earth",
    "Ten C-notes",
    "So-called universal donor type, for short",
    "Universal donor type, for short",
    "Force on earth, in brief",
    "Force on Earth, informally",
    "Downward force felt on the surface of the earth, informally",
    "Rare blood type, for short",
    "Natural thing to feel",
    "It's normal for NASA",
    "Universal donor's type, informally",
    "Pull felt on Earth",
    "You can feel it on the ground, informally",
    "Universal donor's blood type, briefly",
    "Universal donor's type, briefly",
    "Fairly uncommon blood type, informally",
    "Certain blood type, for short",
    "Pull from the ground, quickly?",
    "A thousand bucks",
    "Infrequent blood type, informally",
    "Force felt on earth, for short",
    "Blood designation, briefly",
    "Force felt on the earth, informally",
    "Force felt on earth",
    "Blood type, briefly",
    "NASA force unit, briefly",
    "Universal donor blood type, for short",
    "10 C-notes",
    "Universal donor blood type, for short",
    "Blood designation, for short",
    "Normal force felt on earth",
    "Blood type, briefly",
    "Force on earth, briefly",
    "Blood type, briefly",
    "Earth force",
    "A hundred sawbucks",
    "NASA pressure unit",
    "Force that an astronaut feels on earth",
    "Force on Earth: Abbr.",
    "Standard force",
    "Rare blood type, for short"
  ],
  "kris": [
    "One in the Jenner family",
    "___ Jenner, Kardashian family matriarch",
    "Kardashian family member",
    "Jenner of \"Keeping Up With the Kardashians\"",
    "___ Kringle (Santa Claus)",
    "Humphries of the N.B.A.",
    "Jenner of reality TV",
    "First name on \"Keeping Up With the Kardashians\"",
    "___ Kringle",
    "Kardashian matriarch",
    "Kardashian matriarch",
    "Mother of Kourtney, Kim and Khloe",
    "Kardashians' mom",
    "Jenner of reality TV",
    "Humphries of the N.B.A.",
    "___ Kringle",
    "\"American Idol\" winner ___ Allen",
    "\"American Idol\" winner Allen",
    "Kardashian matriarch",
    "___ Kross ('90s rap duo)",
    "Kringle or Kristofferson",
    "One of the angels on \"Charlie's Angels\"",
    "TV \"angel\" Munroe",
    "Singer Kristofferson",
    "Barbra's \"A Star Is Born\" co-star",
    "Kristofferson of music",
    "___ Kringle",
    "\"Charlie's Angels\" role",
    "Cheryl's \"Charlie's Angels\" role",
    "First name in country music",
    "___ Kringle",
    "___ Kringle",
    "One of Charlie's Angels",
    "Barbra's \"A Star Is Born\" co-star",
    "One of the angels on \"Charlie's Angels\"",
    "Singer Kristofferson",
    "1990's rap duo ___ Kross",
    "Rap's ___ Kross",
    "\"Charlie's Angels\" role",
    "Singer-actor Kristofferson",
    "TV angel Munroe",
    "Barbra's \"A Star Is Born\" co-star",
    "Rap duo ___ Kross",
    "Actor Kristofferson",
    "Barbra's 1976 co-star",
    "Barbra's \"A Star Is Born\" co-star",
    "Wavy-bladed dagger",
    "Barbra's \"A Star Is Born\" co-star",
    "Mr. Kristofferson",
    "Alternative name for 21 Across",
    "A Kringle"
  ],
  "sass": [
    "Ask \"Why should I?,\" say",
    "Freshness",
    "Cheek",
    "Freshness",
    "Lip",
    "Fresh stuff",
    "Insolent talk",
    "Give attitude",
    "Cheek or lip",
    "See 48-Across",
    "\"You can't make me!,\" e.g.",
    "Some freshness",
    "Lip",
    "Some wisecracks",
    "Freshness",
    "Say \"Talk to the hand, 'cause the face ain't listenin',\" say",
    "Back talk",
    "Talk smack to",
    "Stylishness",
    "What \"Talk to the hand!\" is an example of",
    "Lip or cheek",
    "Cheekiness",
    "Reason for a parental reprimand",
    "Give some lip",
    "Be flippant with",
    "Talk back to",
    "Impudence",
    "Get fresh with",
    "Smart-alecky talk",
    "Unwelcome comeback",
    "Lip",
    "Lip",
    "Talk back to",
    "Cheek",
    "Freshness",
    "Bratty retort",
    "It's delivered freshly",
    "Smart comments",
    "Cheek",
    "Lip",
    "Answers that may anger",
    "Some sharp words",
    "Wise off to",
    "Lip",
    "Bratty talk",
    "Smart talk",
    "Lip",
    "Wise off to",
    "Smart answers",
    "Cause for a kid's grounding",
    "Mouth off to",
    "Lip",
    "Impudence",
    "Cheeky chatter",
    "Fresh lines?",
    "Lip",
    "Reason to scold a kid",
    "Cause for a kid getting grounded",
    "Lip",
    "Cheek",
    "Reason for parental scolding",
    "Back talk",
    "Freshness",
    "Mouthing off",
    "Disrespect",
    "Talk that might get one in trouble",
    "Lip",
    "Lip",
    "Snippiness",
    "Guff",
    "Lip",
    "Impudent talk",
    "Back talk",
    "Freshness",
    "Freshness",
    "Disrespect",
    "Freshness",
    "Lip",
    "Talk back to",
    "Fresh talk",
    "Show disrespect to",
    "Cheek",
    "Impertinence",
    "Talk back",
    "Impudence",
    "Mouth off to",
    "Get snippy with",
    "Talk back to",
    "Mouth, so to speak",
    "Answer with attitude",
    "Mouth off to",
    "Talk back to",
    "Cause for a child's scolding",
    "Lip",
    "Cheek",
    "Get smart",
    "Back talk",
    "Reason for a parental reprimand",
    "Back talk",
    "Impertinent talk",
    "Disrespect",
    "Lip",
    "Guff",
    "Back talk",
    "Lip",
    "Back talk",
    "Back talk",
    "Cheekiness",
    "Lip",
    "Challenge authority",
    "Rejoin rudely",
    "Back talk",
    "Guff",
    "Disrespect, in a way",
    "Backtalk",
    "Talk trash to",
    "Reason for detention, maybe",
    "Answer defiantly",
    "Lip",
    "Lip",
    "Smart stuff",
    "Get smart with",
    "Lip",
    "Words of disrespect",
    "Insolence",
    "Disrespect",
    "Lip",
    "Be impudent",
    "Back talk",
    "Act saucy",
    "Back talk",
    "Cheek",
    "Get fresh with",
    "Lip",
    "Dis",
    "Some teen talk",
    "Lip",
    "Fresh words",
    "Get smart with",
    "Lip",
    "Mouth off",
    "Bratty talk",
    "Mouth off",
    "Guff",
    "Smart talk",
    "Impudent talk",
    "Freshness",
    "Be disrespectful to",
    "Flip talk",
    "Teen talk?",
    "Cheek",
    "Smart talk",
    "Be smart"
  ],
  "mail": [
    "Vote by ___",
    "Knight's armor",
    "Post-vacation accumulation",
    "Daily ___ (British paper)",
    "A lot of it is junk",
    "Postal delivery",
    "Post office delivery",
    "Contents of some drop boxes",
    "Knightly wear",
    "Some airplane cargo",
    "5-Across's business",
    "Word after e or G",
    "Knightwear?",
    "It's deposited in drops",
    "Daily delivery",
    "Letters",
    "Letters",
    "Letters",
    "Send off",
    "Letters",
    "Daily delivery",
    "Postal delivery",
    "Letters and packages",
    "Kind of call",
    "Postal delivery",
    "Kind of drop",
    "Some is junk",
    "Kind of call",
    "Armor of interlinked rings",
    "G.I.'s link with home",
    "Daily delivery",
    "Some of it is junk",
    "Daily delivery",
    "Camelot coat",
    "Pony Express load",
    "Knights' garb",
    "Knightwear",
    "Letters",
    "Knightwear?",
    "Drop in a letter box",
    "Knightwear?"
  ],
  "lpga": [
    "Org. involving course work",
    "Sports org. associated with the Evian Championship",
    "U.S. sports org. with many prominent Korean champions",
    "Solheim Cup org.",
    "Michelle Wie's org.",
    "Tour grp.",
    "Women's Open org.",
    "Org. for some sportswomen",
    "Tour grp. since 1950",
    "Org. for women taking courses?",
    "Sports org. with the Vare Trophy",
    "Grp. of teed-off women?",
    "Sports org. with the Vare Trophy",
    "Grp. of women drivers",
    "Tour grp. since 1950",
    "Org. for female shooters",
    "Org. for women drivers",
    "Ironwoman org.?",
    "Kraft Nabisco Championship org.",
    "Women drivers' grp.",
    "Evian Championship org.",
    "Org. for some good drivers",
    "Green org. for women?",
    "Tour org.",
    "Org. for Michelle Wie",
    "Org. for Annika Sorenstam",
    "Org. for women on the links",
    "With 14-Across, Nancy Lopez and Annika Sorenstam have each won this several times",
    "Women's links org.",
    "Annika Sorenstam's org.",
    "Org. that established the Legends Tour in 2001",
    "Org. for women drivers",
    "Kraft Nabisco Championship org.",
    "Women's tour grp.",
    "Evian Masters org.",
    "Annika Sorenstam grp.",
    "Org. for Annika Sorenstam",
    "Org. of which Nancy Lopez was once champion",
    "Sports org. founded in 1950",
    "Tour grp.",
    "Women's Tour sponsor: Abbr.",
    "Grp. for Nancy Lopez",
    "Women's links org.",
    "Org. overseeing courses for women?",
    "Nancy Lopez's org."
  ],
  "mdse": [
    "Dept. store stock",
    "Commercial goods: Abbr.",
    "Goods: Abbr.",
    "Goods: Abbr.",
    "Stock: Abbr.",
    "Retailer's inventory: Abbr.",
    "Gds.",
    "Inventory: Abbr.",
    "Warehouse contents: Abbr.",
    "Retailer's goods: Abbr.",
    "Store stock: Abbr.",
    "Gds.",
    "Invoice itemization: Abbr.",
    "Stock: Abbr.",
    "Retailer's gds.",
    "Inventory: Abbr.",
    "Gds.",
    "Store goods: Abbr.",
    "Dept. store inventory",
    "Store stock: Abbr.",
    "Goods: Abbr."
  ],
  "afro": [
    "Hairstyle for 2-Down",
    "Prefix with futurism",
    "Prefix with beat or futurism",
    "Hairstyle for Audre Lorde",
    "Hairstyle for Billy Preston",
    "Musical prefix with beat",
    "Big do",
    "Prefix with Cuban",
    "Hairstyle that may have a pick",
    "Picked locks?",
    "Style to pick?",
    "It was once big for Aretha Franklin",
    "Do for Jon Batiste",
    "Lead-in to punk or Cuban, in music",
    "Start of some hybrid music styles",
    "Hairdo that takes a long time to grow",
    "Big top?",
    "Classic Diana Ross hairdo",
    "Lead-in to -centric",
    "Hairstyle rarely seen in the military",
    "Big, round head of hair",
    "Hairstyle that's very big",
    "___-Cuban (music genre)",
    "Spherical locks",
    "Kind of pick",
    "Popular hairstyle in the 1960s",
    "Hairstyle maintained with a pick",
    "Old hairdo for Diana Ross",
    "Jimi Hendrix hairdo",
    "Retro hairstyle",
    "___-American",
    "Cause of bigheadedness?",
    "Age of Aquarius hairstyle",
    "Big do",
    "Picked locks?",
    "What might give you a big head?",
    "Do sometimes called a \"natural\"",
    "'60s do also called a \"natural\"",
    "Bushy hairdo",
    "Big do",
    "Do that may have a pick",
    "Hairstyle that's rarely seen on blonds and redheads",
    "Big top?",
    "Hairdo for Jimi Hendrix",
    "Big do",
    "Hairdo for any of the Jackson 5",
    "Do with a pick, maybe",
    "Early Michael Jackson hairstyle",
    "\"Hair\" do",
    "Wig style",
    "Jackson 5 hairdo",
    "Bushy do",
    "Big do",
    "Bushy hairdo",
    "Extra-large top?",
    "Hardly a close-cut hairdo",
    "Place to stick a comb",
    "Early Jesse Jackson hairdo",
    "Retro hairstyle",
    "Early do for Michael Jackson",
    "Hairstyle that may have a comb stuck in it",
    "Big do",
    "Early Michael Jackson style",
    "Bush not seen much nowadays",
    "Big do",
    "Its locks are tight",
    "___-American",
    "Big do",
    "Bushy do",
    "Do that's picked",
    "Rounded hairdo",
    "Frizzy do",
    "Frizzy coif",
    "Jimi Hendrix's style",
    "It's rarely seen under a hat",
    "Frizzy do",
    "Big top?",
    "___-Asiatic",
    "Where to stick a pick",
    "Retro do",
    "Natural",
    "A young Michael Jackson had one",
    "Place to stick a comb, once",
    "Big do",
    "Bushy do",
    "High do",
    "Retro hairdo",
    "\"The Mod Squad\" do",
    "Michael Jackson's old do",
    "Jimi Hendrix's do",
    "Natural do",
    "___-Cuban music",
    "Big do",
    "Natural hairstyle",
    "Old Michael Jackson do",
    "Bushy do",
    "Big top?",
    "Bushy do",
    "60's do",
    "1970's coif",
    "___-American",
    "Place to stick a comb",
    "Woodstock hairdo",
    "Big do",
    "High-profile hairdo",
    "Onetime Michael Jackson do",
    "Prefix with centric",
    "Pick target",
    "60's do",
    "60's do",
    "1960's activist's hairdo",
    "___-American",
    "Bushy do",
    "Hendrix hairdo",
    "Naturally curly hairdo",
    "Frizzy coif",
    "Big do",
    "Retro hairdo",
    "60's do",
    "Old-fashioned do",
    "___-American",
    "Big do",
    "70's do",
    "60's haircut",
    "Curly do",
    "Cornrows alternative",
    "60's hairdo",
    "Opposite of a buzz cut",
    "60's hairdo",
    "Round, full do",
    "Bushy coif",
    "Bygone hairstyle",
    "60's-70's hairdo",
    "Bouffant do",
    "Curly coif",
    "Bushy hairdo",
    "60's hair style",
    "Place for a pick",
    "60's coif",
    "Bushy hairstyle",
    "Bygone coif",
    "Bygone hair style",
    "___-American",
    "Dated hairdo",
    "60's hair style"
  ],
  "vena": [
    "Certain blood vessel, to a physician",
    "With 52-Down, passage to the heart",
    "___ cava",
    "___ cava",
    "___ cava",
    "Brutus's blood vessel",
    "___ cava",
    "___ cava (heart connector)",
    "___ cava",
    "___ cava",
    "___ cava (path to the heart)"
  ],
  "otro": [
    "Oaxacan \"other\"",
    "Other: Sp.",
    "Other: Sp.",
    "Como ningun ___ (unique, in Spanish)",
    "Oscar's \"other\"",
    "Other: Sp.",
    "Por ___ lado (conversely: Sp.)",
    "Spanish \"other\"",
    "Other: Sp.",
    "Other, in Acapulco",
    "Other, in Oaxaca",
    "\"Al ___ Lado del Rio\" (2004 Oscar-winning song)",
    "Not esto or eso",
    "Spanish \"other\"",
    "Spaniard's \"other\"",
    "Overseas alternative?",
    "Sancho's other",
    "Not esto or eso",
    "Spanish alternative?",
    "Other: Sp.",
    "Other: Sp.",
    "Other, in Oaxaca",
    "Other: Sp.",
    "En ___ tiempo (formerly, to Felipe)",
    "Not esto or eso",
    "Juan's other",
    "Other, in Oaxaca",
    "Other, in Zaragoza",
    "Another, in Andalucia",
    "Other, south of the border",
    "Not esto or eso",
    "\"___ Rollo\" (popular Mexican variety show)",
    "Another, in Madrid",
    "\"Al ___ Lado Del Rio\" (Best Song of 2004)",
    "Other, in Oaxaca",
    "Neither this nor that, in Peru",
    "Another, in Madrid",
    "Other: Sp.",
    "Another, in Andalusia",
    "Additional: Sp.",
    "Other, in Acapulco",
    "Not esto or eso",
    "Another, in Andalusia",
    "Another, abroad",
    "Not this or that, in Spain",
    "Not esto or eso",
    "Another, south of the border",
    "Other, to Pedro",
    "Not esto or eso",
    "Other, in Oaxaca",
    "Other: Sp.",
    "Other, to Ortega"
  ],
  "rial": [
    "Yemeni money",
    "Iranian money",
    "50-denomination coin whose reverse side shows the Fatima Masumeh Shrine",
    "Money in Oman",
    "Money in the Mideast",
    "Egypt : pound :: Iran : ___",
    "Iranian currency with a 75-Across on its bills",
    "Iranian currency",
    "Iranian currency",
    "Currency on the Arabian Peninsula",
    "Iranian currency",
    "Iranian currency",
    "Iranian money",
    "1,000 baisa",
    "Yemeni capital",
    "Omani money",
    "Capital of Iran",
    "Money in Yemen",
    "Mideast money",
    "Coin of Iran",
    "Iranian currency",
    "100 Iranian dinars",
    "Unit of currency for some oil",
    "Balboa : Panama :: ___ : Iran",
    "Mideast currency",
    "Iranian currency",
    "Mideast currency",
    "29-Across country currency",
    "Currency whose name can become its country's name by changing its last letter to an N and scrambling",
    "OPEC nation currency",
    "100 fils",
    "Iranian money",
    "Iranian coin",
    "100 Iranian dinars",
    "Mideast money",
    "Iranian money",
    "Yemeni capital",
    "Money for 32-Down",
    "Iranian money",
    "Iranian money",
    "Iranian money",
    "Yemeni money",
    "Omani money",
    "Omani's money",
    "Mideast money",
    "Muscat money",
    "Yemen's capital",
    "Mideast money",
    "Mideast money",
    "Iranian cash",
    "Iranian money",
    "Iranian coin",
    "100 dinars",
    "Eastern currency",
    "Cash in Qom",
    "Tabriz money",
    "Capital of Iran",
    "Coin of Iran",
    "100 dinars",
    "100 dinars",
    "Iranian money",
    "Mideast money",
    "100 dinars",
    "Yemeni's currency",
    "Iranian money",
    "100 dinars",
    "Qum coin",
    "Coin in Kerman",
    "Iranian money",
    "Iranian coin",
    "Mideast money",
    "Omani money",
    "Muscat moolah",
    "Coin in 47 Across",
    "Irani money"
  ],
  "laid": [
    "Put down",
    "Put down",
    "Set (down)",
    "Put down",
    "With 40-Across, exposed",
    "Placed",
    "Installed, as 17-Across",
    "Set in place",
    "Bet",
    "Set (down)",
    "Made, as a wager",
    "Placed",
    "Set down",
    "Wagered",
    "Deposited",
    "Like an egg",
    "Installed",
    "In position, as a cornerstone",
    "Positioned",
    "Placed",
    "Like eggs",
    "Put down",
    "Like cornerstones",
    "In place",
    "With 54-Down, in reserve",
    "Wagered",
    "Wagered",
    "Dismissed, with \"off\"",
    "Put",
    "Bet",
    "Deposited",
    "Put down",
    "Set down",
    "Put down"
  ],
  "anka": [
    "\"Puppy Love\" singer, 1960",
    "Paul who sang \"Diana\" and \"Lonely Boy\"",
    "He wrote lyrics to \"My Way\" for Sinatra",
    "\"Lonely Boy\" singer, 1959",
    "Singer/lyricist Paul",
    "Pop star with top 40 hits in the 1950s, '60s, '70s and '80s",
    "\"Put Your Head on My Shoulder\" singer, 1959",
    "\"My Way\" lyricist Paul",
    "\"Eso Beso\" singer",
    "Collaborator with Sedaka and Cooke on the 1964 album \"3 Great Guys\"",
    "\"Puppy Love\" hitmaker, 1960",
    "Paul who sang \"Lonely Boy\"",
    "Singer/songwriter on Canada's Walk of Fame",
    "Paul who sang \"Eso Beso\"",
    "\"Puppy Love\" singer, 1960",
    "\"Diana\" singer Paul",
    "\"My Way\" lyricist Paul",
    "\"Annette Sings ___\" (1960 pop album)",
    "Paul who sang \"Eso Beso\"",
    "Paul with the #1 hit \"Lonely Boy\"",
    "Paul who sang \"Diana\"",
    "Singer on Canada's Walk of Fame since 2005",
    "Paul who composed the \"Tonight Show\" theme",
    "\"My Way\" lyricist",
    "\"Puppy Love\" crooner",
    "Paul in the Songwriters Hall of Fame",
    "Paul who sang \"Put Your Head on My Shoulder\"",
    "Paul who sang \"Lonely Boy\"",
    "\"You Are My Destiny\" singer",
    "\"My Way\" lyricist",
    "1950s heartthrob Paul",
    "Canadian singer with three #1 Billboard hits",
    "\"Tonight My Love, Tonight\" singer",
    "\"You Are My Destiny\" singer, 1958",
    "He had a #4 hit with \"It's Time to Cry\"",
    "Paul who wrote \"My Way\"",
    "Paul who wrote \"My Way\"",
    "Paul who sang \"Diana\"",
    "\"It's Time to Cry\" singer, 1959",
    "\"My Way\" songwriter",
    "\"Puppy Love\" singer, 1960",
    "\"My Way\" lyricist",
    "Paul who sang \"Put Your Head on My Shoulder\"",
    "Paul who sang \"Puppy Love\"",
    "\"Lonely Boy\" singer/writer",
    "\"Diana\" singer",
    "\"Puppy Love\" singer Paul",
    "Classic singer with the 2005 album \"Rock Swings\"",
    "\"Lonely Boy\" singer",
    "44-Down singer",
    "\"Put Your Head on My Shoulder\" singer, 1959",
    "Singer Paul",
    "Singer Paul",
    "\"My Way\" lyricist",
    "Ottawa-born singer/songwriter",
    "\"My Way\" lyricist",
    "\"Puppy Love\" singer Paul",
    "\"Dance On Little Girl\" singer",
    "\"Diana\" singer",
    "\"Diana\" crooner Paul",
    "\"My Way\" writer",
    "Paul who sang \"Diana\"",
    "\"Lonely Boy\" singer Paul",
    "\"Diana\" singer Paul",
    "\"Puppy Love\" singer",
    "\"My Way\" songwriter",
    "Singer Paul",
    "Singer Paul",
    "\"Times of Your Life\" singer",
    "\"Lonely Boy\" singer",
    "\"She's a Lady\" songwriter",
    "\"She's a Lady\" songwriter",
    "\"You Are My Destiny\" singer, 1958",
    "\"Crazy Love\" singer",
    "\"Puppy Love\" singer",
    "\"Put Your Head on My Shoulder\" singer",
    "\"My Way\" writer",
    "Old \"Tonight Show\" theme writer",
    "\"My Way\" songwriter",
    "\"My Way\" songwriter",
    "50's-60's teen idol",
    "\"Dance On Little Girl\" singer",
    "Paul who sang \"Having My Baby\"",
    "Singer who appeared in \"The Longest Day\"",
    "Singer Paul",
    "\"Lonely Boy\" singer",
    "\"You Are My Destiny\" singer",
    "\"You Are My Destiny\" singer",
    "Singer Paul",
    "\"My Way\" lyricist",
    "\"Lonely Boy\" singer",
    "\"You Are My Destiny\" singer",
    "\"My Way\" songwriter",
    "\"Johnny's Theme\" cowriter",
    "\"Puppy Love\" singer, 1960",
    "\"Put Your Head on My Shoulder\" singer",
    "\"Diana\" singer",
    "He wrote \"My Way\" for Sinatra"
  ],
  "exam": [
    "This is only a test",
    "Proctored event, maybe",
    "Series of questions, maybe",
    "This is a test",
    "Physical, e.g.",
    "Course obstacle",
    "End-of-semester stressor",
    "One might be taken orally",
    "Midterm or final",
    "Appropriate rhyme for \"cram\"",
    "Part of a doctor's visit",
    "Reason to pull an all-nighter",
    "Annual checkup, e.g.",
    "Procedure at a doctor's office",
    "What a prep course preps for",
    "Bar ___",
    "Start of many a doctor's visit",
    "Course coda",
    "Reason for a class struggle?",
    "SAT, e.g.",
    "Class act?",
    "Final, for one",
    "Activity at a doctor's office",
    "Physical, say",
    "Word after eye or makeup",
    "Physical, e.g.",
    "Checkup",
    "Cause of a class struggle?",
    "Test",
    "Checkup, e.g.",
    "Part of a dental visit",
    "Reason to cram",
    "Checkup",
    "Typical semester finish",
    "More than a quiz",
    "Purpose of many a doctor's visit",
    "It has its problems",
    "End-of-semester event",
    "Entrance requirement, sometimes",
    "Eye ___",
    "Hospital administration?",
    "Test",
    "Something to prep for",
    "Physical, e.g.",
    "Occasion for a proctor",
    "Midterm, e.g.",
    "This is a test",
    "Final ___",
    "Bar ___",
    "Proctored event",
    "It might be required for entrance",
    "Final, e.g.",
    "Something to cram for",
    "Reason for an office visit",
    "What a student crams for",
    "Means of assessment",
    "Reason for an all-nighter",
    "Reason for an all-nighter",
    "Final, e.g.",
    "Hospital administration?",
    "Oral, e.g.",
    "Midterm worry",
    "Midterm, e.g.",
    "Checkup",
    "Final, say",
    "Occasion for proctors",
    "Midterm, e.g.",
    "Physical",
    "Midterm, for one",
    "Oral, e.g.",
    "Crammer's worry",
    "Final, e.g.",
    "Physical, e.g.",
    "Academic challenge",
    "Reason to cram",
    "Entrance requirement, perhaps",
    "Breast ___",
    "Physical, e.g.",
    "Midterm, for one",
    "Quiz",
    "Class distribution",
    "Checkup",
    "Doctor's ___",
    "All-nighter follower",
    "It may be required for entrance",
    "Course concluder",
    "Makeup ___",
    "Checkup",
    "Crammer's concern",
    "Course finale",
    "Regents, e.g.",
    "Conclusion of a term",
    "Blue book filler",
    "Physical, e.g.",
    "Test",
    "Quiz",
    "Term terminator",
    "Checkup",
    "Midyear, e.g.",
    "Final",
    "Oral, maybe",
    "Final",
    "Checkup",
    "Midterm, e.g.",
    "Physical, e.g.",
    "Term finisher, often",
    "Student stressor",
    "Word with eye or final"
  ],
  "inca": [
    "Worshiper at the ancient Qorikancha (\"Golden Temple\")",
    "People who built the Qhapaq Ñan, or \"Royal Road,\" which stretched roughly 3,700 miles",
    "Quechua speaker of old",
    "Andean empire member",
    "Person fluent in Quechua",
    "Creators of quipus, knotted strings used to record census data and other information",
    "Old South American empire",
    "Member of an early Andean civilization",
    "Sapa ___ (title for Atahualpa)",
    "Worshiper of the goddess Mama Quilla (\"mother moon\")",
    "Speakers of Quechua",
    "Machu Picchu builder",
    "Member of an old Western empire",
    "Early Cuzco resident",
    "Ancient speakers of Quechua",
    "Indigenous Peruvian",
    "Worshiper of the sun god Inti",
    "Machu Picchu builder",
    "Inti worshiper",
    "___ Trail (path in the Andes)",
    "Ancient Andean",
    "Early Cuzco dweller",
    "Smallpox victims of the 1500s",
    "Sun god worshiper",
    "Peruvian of long ago",
    "Ancient Peruvian",
    "Ancient sun worshiper",
    "With 5-Down, creator of 24,000+ miles of road before 1600",
    "Ancient Peruvian",
    "___ Empire",
    "Ancient alpaca herder",
    "Worshiper of Inti",
    "Sun worshipers",
    "Quechua speaker",
    "___ Empire",
    "Early Peruvian",
    "___ Empire",
    "Early cultivator of potatoes",
    "Conquistador's foe",
    "Ancient South American",
    "Member of an old empire",
    "Terrace farming pioneers",
    "Highlander of old",
    "Ancient Peruvian",
    "Ancient Peruvian",
    "Ancient Andean",
    "Llama herder of old",
    "South America's ___ Trail",
    "___ Empire (bygone domain)",
    "Ancient native of 45-Across",
    "People conquered by the Spanish",
    "Viracocha worshiper",
    "People who valued vicuna wool",
    "Their empire was the Land of the Four Quarters",
    "Ancient Peruvian",
    "Ancient Andean",
    "Victim of Pizarro",
    "Pizarro foe",
    "Resident of the ancient city Choquequirao",
    "Ancient Peruvian",
    "Machu Picchu resident",
    "Machu Picchu builder",
    "Ancient Peruvian",
    "Member of a mountain empire",
    "Native encountered by Pizarro",
    "Early Peruvian",
    "Cuzco native",
    "Machu Picchu resident",
    "Member of an empire founded by Manco Capac",
    "User of a record-keeping device called a quipu",
    "Quechua speaker",
    "Quechua speaker",
    "Member of a Pre-Columbian empire",
    "Peru's ___ Trail",
    "Old victim of the Spanish",
    "Certain ancient mummy",
    "Old alpaca wool gatherer",
    "Cuzco inhabitant",
    "People conquered by the Spanish",
    "Atahualpa, for one",
    "Ancient Peruvian",
    "Member of an empire that ended in the 16th century",
    "Viracocha worshiper",
    "Victim of Pizarro",
    "One of a people conquered in 1533",
    "Atahualpa's people",
    "Pizarro foe",
    "Clive Cussler's \"___ Gold\"",
    "Atahualpa, e.g.",
    "Machu Picchu dweller",
    "Cuzco native",
    "Foe of Pizarro",
    "Old llama herder",
    "Temple of the Sun worshiper",
    "Ancient Andean",
    "Machu Picchu builder",
    "Ancient Peruvian",
    "Early Peruvian",
    "Old inhabitant of 14-Across",
    "Highlander of old",
    "Sun worshiper",
    "Ancient Peruvian",
    "Worshiper of the sun god Inti",
    "Ancient royal",
    "Ancient Peruvian",
    "Old Peruvian",
    "No fan of Pizarro, certainly",
    "Ancient people",
    "Ancient Peruvian",
    "Worshiper of Inti",
    "Candidate for mummification",
    "Ancient South American",
    "___ Trail in the Andes",
    "Andean ancient",
    "Clive Cussler thriller \"___ Gold\"",
    "Quechua speaker",
    "Ancient Peruvian",
    "Early Peruvian",
    "The ___ Trail (route through Peru)",
    "Atahualpa, for one",
    "Peruvian Indian",
    "Temple of the Sun worshiper",
    "Member of a bygone empire",
    "Ancient Andean",
    "One conquered by the conquistadors",
    "Machu Picchu native",
    "Empire builder of old Peru",
    "Son of the Sun",
    "Builder at Cuzco",
    "Peruvian of old",
    "Empire builder",
    "Atahualpa subject",
    "Ancient Andean",
    "Holder of ancient riches",
    "Victim of Pizarro",
    "Machu Picchu builder",
    "Machu Picchu worshipper",
    "Ancient Andean",
    "Member of a bygone empire",
    "Ancient terrace farmer",
    "Cuzco-centered empire",
    "Peruvian native",
    "Manco Capac, e.g.",
    "Ancestor of the Q'ero Indians",
    "One in an old empire",
    "Pre-Columbian Peruvian",
    "Machu Picchu resident",
    "One conquered by Pizarro",
    "Early Peruvian",
    "Cuzco inhabitant",
    "Early Peruvian",
    "Quipu maker",
    "Bygone empire",
    "Atahualpa was one",
    "Early empire builder",
    "Andean of old",
    "Early Andean",
    "Machu Picchu dweller",
    "Early Andean",
    "Machu Picchu resident",
    "Old Peruvian",
    "Machu Picchu builder",
    "Atahualpa, for one",
    "Machu Picchu native",
    "One whom Pizarro encountered",
    "Old-time Peruvian",
    "Early Peruvian",
    "Quechua",
    "Pizarro victim",
    "Peruvian native",
    "Native of old Peru",
    "Quechua ruler",
    "Native Peruvian",
    "Pachacuti was one",
    "Atahualpa,. e.g.",
    "Indian name meaning \"ruler\"",
    "Peruvian Indian",
    "\"Son of the Sun\"",
    "Peruvian of yore"
  ],
  "tell": [
    "Poker giveaway",
    "Poker giveaway",
    "William who took a bow",
    "Bad thing for a bluffer",
    "Come clean to",
    "Let the cat out of the bag",
    "Poker giveaway",
    "Be a snitch",
    "Poker giveaway",
    "Spill the beans",
    "Poker giveaway",
    "One in a story with an apple",
    "Swiss folk hero with a crossbow",
    "Poker player's tic, perhaps",
    "Poker player's blink, say",
    "Rebuke, with \"off\"",
    "Game giveaway",
    "Partner of show or kiss",
    "Opposite of a poker face",
    "Hold 'em giveaway",
    "Inform",
    "William who shot an apple off his son's head",
    "Kiss's partner",
    "Relate",
    "Rat (on)",
    "Blow the whistle, so to speak",
    "Giveaway",
    "Bluffer's giveaway",
    "Giveaway",
    "Swiss patriot",
    "Relate, as a story",
    "Fidgeting during a poker game, e.g.",
    "Explain to",
    "Giveaway at the poker table",
    "Inform",
    "Bluffer's undoing, in poker",
    "Sing",
    "\"Do ___!\"",
    "Discern",
    "Determine",
    "Distinguish",
    "Rossini subject",
    "Archer William",
    "Legendary archer",
    "Blab",
    "Give away",
    "Tattle (on)",
    "Distinguish",
    "Rossini protagonist",
    "Show's partner",
    "Orally convey",
    "Discern",
    "Get off one's chest",
    "Show partner",
    "\"Do ___\"",
    "Recount",
    "Be a snitch",
    "Be a snitch",
    "Show's partner",
    "Confess",
    "\"Do ___!\"",
    "\"Can you ___?\" (\"Is it obvious?\")",
    "Rat",
    "Make known",
    "Fink",
    "Narrate",
    "Blow the whistle",
    "Tattle",
    "Order",
    "Rossini subject",
    "Rossini subject",
    "Marksman of Swiss legend",
    "Rat (on)",
    "Scold, with \"off\"",
    "Blab",
    "Archer William",
    "Rossini subject",
    "Disclose",
    "Relate",
    "Show's partner",
    "Snitch",
    "Apple targeter",
    "Schiller drama subject",
    "___ off (scold)"
  ],
  "urls": [
    "Info in a modern bibliography",
    "Bookmarked things",
    "Things shortened at bitly.com",
    "Places surfers frequent, for short?",
    "Google search results",
    "Many book-marks, for short",
    "Standard info on stationery nowadays",
    "Cyberaddresses",
    "Addresses that may be linked on the web",
    "Web addresses",
    "Things that have slashes",
    "Web links, briefly",
    "They help you access 17-Across, for short",
    "Things with colons inside them",
    "Surfing destinations",
    "Website links, for short",
    "They may be bookmarked",
    "Browser history listings",
    "Web addresses, briefly",
    "They may be bookmarked",
    "Some bookmarks, for short",
    "E-tailing specifications",
    "Info on modern business cards",
    "Modern addresses, for short",
    "Web addresses",
    "They may be bookmarked",
    "Web addresses",
    "Bookmarked collection",
    "Google results",
    "Internet addresses",
    "www addresses",
    "Addresses starting http://",
    "www addresses",
    "Modern addresses",
    "Some addresses",
    "Modern links, for short",
    "Some modern addresses, for short",
    "They may be bookmarked",
    "WWW addresses",
    "Web addresses, for short",
    "WWW addresses",
    "WWW addresses"
  ],
  "stat": [
    "Tackles, say",
    "No. on the back of a baseball card",
    "\"Now!\"",
    "\"Immediately!\"",
    "Turnovers, e.g.",
    "Average, maybe",
    "E.R.A. or R.B.I.",
    "Runs or walks",
    "\"A.S.A.P.!\"",
    "Right away",
    "On the double",
    "Sports figure",
    "\"Now!,\" in the E.R.",
    "\"Right away!\"",
    "Right away",
    "Walks or runs",
    "% on the back of a baseball card, say",
    "Box score bit",
    "Attempts, e.g.",
    "Saves, e.g.",
    "\"It's urgent\" in the E.R.",
    "Saves or assists",
    "\"Right now!,\" to a surgeon",
    "\"On the double!\"",
    "R.B.I.s or H.R.s",
    "On the double",
    "\"On the double!\"",
    "Walks or runs, for short",
    "On-base percentage, e.g.",
    "Runs, for instance",
    "\"Right away!\"",
    "Walks or runs",
    "R.B.I., for one",
    "\"Right away!,\" in the O.R.",
    "\"Right away!,\" in the E.R.",
    "Blocks, e.g.",
    "Turnovers, e.g.",
    "TKOs, e.g.",
    "See 51-Down",
    "FiveThirtyEight datum",
    "Topps tidbit",
    ".215 batting avg., e.g.",
    "\"Right away\"",
    "Runs, hits or errors, for short",
    "Topps card tidbit",
    "Paramedic's \"pronto\"",
    "E.R. \"A.S.A.P.\"",
    "Assists, e.g.",
    "\"Ow!\"",
    "Doc's \"Now!\"",
    "Right away",
    "\"Chop-chop!\"",
    "6'9\" or 72% free throw avg.",
    "\"Now!\"",
    "H.R.'s, e.g.",
    "Assists, say",
    "Yards, e.g.",
    "Turnovers, e.g.",
    "\"A.S.A.P.!\"",
    "Hits or runs",
    "Rushing yards or turnovers, for short",
    "A.S.A.P., in the E.R.",
    "Goals against, e.g.",
    "Doctor's directive",
    "Ballpark figure",
    "\"At once!\"",
    "Recap figure",
    "\"Now!\"",
    "Saves, say",
    "\"Like, now!\"",
    "\"Now!\"",
    "Population fig., e.g.",
    "Immediately, to a surgeon",
    "Trading card figure",
    "Carries, e.g.",
    "Steals, e.g.",
    "Rebounds, e.g.",
    "Factoid for fantasy baseball",
    "\"Right now!,\" to a doctor",
    "Interceptions or yards rushing",
    "\"Immediately!,\" in the operating room",
    "\"This instant!\"",
    "Runs, hits or errors",
    "Goals or assists",
    "Surgeon's order",
    "Rebounds or assists",
    "R.B.I. or H.R.",
    "E.R. cry",
    "Saves, e.g.",
    "\"Immediately,\" in the O.R.",
    "No. on a baseball card",
    "P.D.Q., to a surgeon",
    "Yds. rushing, e.g.",
    "Suffix with rheo-",
    "Number of service aces, e.g.",
    "Photo repro",
    "\"Right now!\"",
    "Yards rushed, e.g.",
    "Yards on the ground, e.g.",
    "E.R. order",
    "Record keeper's concern",
    "Figure",
    "Right away",
    "P.D.Q. in the I.C.U.",
    "E.R. order",
    "40-Across, e.g.",
    "Ballpark figure?",
    "Yards rushing, e.g.",
    "Sports figure",
    "P.D.Q. in an O.R.",
    "One for the record books",
    "Immediately",
    "E.R. cry",
    "Boxer's reach, e.g.",
    "Quickly, in the I.C.U.",
    "\"Immediately,\" in the O.R.",
    "On the double, in the O.R.",
    "Sports figure",
    "A.S.A.P. in an E.R.",
    "Strikeouts, say",
    "Boxer's reach, e.g.",
    "Urgent in the E.R.",
    "Points per game, e.g.",
    "Errors, e.g.",
    "Household gauge, informally",
    "Free throw percentage, e.g.",
    "Rebounds per game, e.g.",
    "1 out of 3, e.g.",
    "Temperature regulator, informally",
    "\"At once!\"",
    "Weight, e.g.",
    "Passing yardage, e.g.",
    "Order to a nurse",
    "Photo in a magazine office",
    "Sportscaster's tidbit",
    "Doctor's order",
    "Right away",
    "Free throw avg., e.g.",
    "\"P.D.Q., doc!\"",
    "Pre-desktop publishing photo",
    "Rebounds, e.g.",
    "Average, e.g.",
    "Immediately, to a surgeon",
    "\"Immediately\"",
    "Career home runs, e.g.",
    "Assists, e.g.",
    "Sports figure",
    "Photo finish?",
    "\"Now!\"",
    "Rebounds, e.g.",
    "Magazine photo, for short",
    "\"ER\" order",
    "Goals or assists",
    "\"Immediately!\"",
    "\"Now!\" in a hospital",
    "P.D.Q., on \"ER\"",
    "Number on a baseball card",
    "Yards in passing, e.g.",
    "E.R. cry",
    "Sports figure",
    "Team number",
    "Number on a baseball card",
    "Home runs or r.b.i.'s, e.g.",
    "\"ER\" command",
    "E.R. A.S.A.P.",
    "P.D.Q., on \"ER\"",
    "R.B.I. or E.R.A.",
    "TD's, e.g.",
    "\"P.D.Q., doc!\"",
    "Sports figure?",
    "Piece of info",
    "Suffix with thermo-",
    "R.B.I., e.g.",
    "\"Now!\"",
    "Immediately, in the E.R.",
    "Copy of a photo, briefly",
    "At bats, e.g.",
    "G.D.P., for one",
    "R.B.I., e.g.",
    "Now, on \"E.R.\"",
    "Field goal pct., e.g.",
    "R.B.I. or E.R.A.",
    "Datum",
    "Repro",
    "Sports figure",
    "Surgeon?Æs cry",
    "Now, in triage",
    "Sports page figure",
    "Assists, e.g.",
    "Individual figure",
    "Immediately, in the operating room",
    "Photo repro",
    "Photo add-on",
    "Immediately, to a surgeon"
  ],
  "tips": [
    "\"Read the clues carefully\" and \"Check your crossing answers\" [You're welcome!]",
    "Money left on the table",
    "Pointers",
    "Jar filler",
    "Much of a salon worker's income",
    "Rewards for waiting?",
    "Things waiters wait for",
    "Things worth waiting for?",
    "Word on a jar",
    "Sign on a jar at a bar",
    "Server's bread and butter",
    "Tout's stock-in-trade",
    "Bar jarful",
    "They're often off the books",
    "Some keep waiting for them",
    "Things worth waiting for?",
    "Aids for police detectives",
    "Word on a bar worker's jar",
    "Bread in a jar, perhaps",
    "Large part of a waiter's income",
    "A large part of a waitress's income",
    "Edges at the track",
    "A lot of a car valet's income",
    "Much of a waitress's income",
    "Reward for waiting?",
    "Rewards for waiting",
    "Inclines",
    "Service rewards",
    "Server's collection",
    "See 21-Across",
    "Diner jarful",
    "Sirloin parts",
    "Thanks monetarily",
    "Helpful hints",
    "Peaks",
    "Q followers?",
    "Good things that come to those who wait",
    "Loses verticality",
    "Often underreported income",
    "Pointers",
    "Rewards for waiting",
    "Much of a waiter's income",
    "Manual offerings",
    "Rewards for waiting",
    "Advice"
  ],
  "zahn": [
    "Broadcast journalist Paula",
    "Former CNN anchor Paula",
    "One of the initial anchors of CNN's \"American Morning\"",
    "Newswoman Paula",
    "Newswoman Paula",
    "Newswoman Paula",
    "Newswoman Paula",
    "Paula of TV news",
    "Journalist Paula",
    "Newswoman Paula",
    "Newswoman Paula",
    "Newswoman Paula",
    "Paula of CNN",
    "Paula of CNN",
    "News anchor Paula",
    "Broadcast journalist Paula",
    "Newscaster Paula",
    "Morning TV host",
    "Newswoman Paula"
  ],
  "idle": [
    "Not do anything",
    "Waiting for an assignment, maybe",
    "Just sitting around",
    "Keep an engine running without moving",
    "Like a team on a day off",
    "Like some threats",
    "Duty-free?",
    "Lollygag",
    "Just hanging out",
    "Like some threats",
    "Not going anywhere",
    "Word before rich or talk",
    "Wait in neutral",
    "Not busy",
    "Just sitting around",
    "Thumb-twiddling",
    "Hang around",
    "Inactive",
    "___ talk",
    "Sit at a red light, say",
    "Kind of hands said to be the devil's playthings",
    "Lay off, as workers",
    "Just sitting around",
    "Indolent",
    "Not doing anything",
    "Pass the time",
    "Not working",
    "Not doing too much",
    "Unused",
    "Kind of speculation",
    "Wait for a green light, say",
    "Dormant",
    "Loafing around",
    "Lay off",
    "Not in use",
    "Kind of threat",
    "Pointless",
    "Not working",
    "Goofing off",
    "\"___ hands are the devil's playthings\"",
    "Take it easy",
    "Kind of chat",
    "Not working",
    "Do nothing",
    "Out of a job",
    "Doing nothing",
    "Having a day off",
    "___ talk",
    "Lollygag",
    "Internet chat status",
    "Doing nothing",
    "Like a couch potato",
    "Nothing doing?",
    "Away from a chat program, say",
    "Not lifting a finger",
    "In a hammock, maybe",
    "Lay off",
    "Nothing doing?",
    "Thumb-twiddling",
    "Kind of threat",
    "Run while standing still",
    "Nothing doing?",
    "Not working",
    "Lay off",
    "Barely run the engine",
    "Goofing off",
    "Laid off",
    "Empty, as talk",
    "Sitting on one's hands",
    "Not active",
    "Chilling, say",
    "Not working",
    "Baseless",
    "Kill time",
    "On break, say",
    "Pink-slip",
    "\"___ hands are the devil's tools\"",
    "Just sitting around",
    "Like some threats",
    "Not working",
    "Potentially going into screen saver mode",
    "What a getaway car may be waiting in",
    "Not in operation",
    "Out of work",
    "Twiddling one's thumbs",
    "Just loafing",
    "Fallow",
    "No longer working",
    "On one's duff",
    "Run in place",
    "Not scheduled to compete",
    "Going nowhere",
    "Lay off",
    "Unemployed",
    "Not doing anything",
    "Like some chatter",
    "Not playing",
    "Like some hands",
    "Just sitting",
    "Frivolous",
    "Nothing doing?",
    "Doing nothing",
    "Loafing",
    "Kind of speculation",
    "Trifling",
    "Still",
    "Like some rumors",
    "Not in use",
    "Run in place",
    "Lay off",
    "Between jobs",
    "Asleep, say",
    "Daydreaming, say",
    "Nothing doing?",
    "Twiddling one's thumbs",
    "Furloughed",
    "Doing nothing",
    "Waiting",
    "Shelved",
    "Run without moving",
    "Just chilling",
    "Like some thoughts",
    "Indolent",
    "Unused",
    "Doing nothing",
    "Frequent Cleese co-star",
    "Just twiddling one?Æs thumbs",
    "Not playing",
    "Having time on one's hands",
    "Unmeaningful",
    "Taking a break from work",
    "Loafing",
    "Loafing",
    "Like some chatter",
    "Just twiddling the thumbs",
    "Groundless",
    "Unfounded, as gossip",
    "Not scheduled to play",
    "Mark time",
    "Not busy",
    "Knock about",
    "Lounge",
    "Between assignments",
    "Sitting on one's hands",
    "Inactive",
    "Not playing",
    "Inactive",
    "Waiting",
    "Run while standing",
    "Kind of hands that are \"the devil's playthings\"",
    "Off",
    "Leave in neutral",
    "Not engaged",
    "Barely run, as an engine",
    "Empty",
    "Not busy",
    "Like some rumors",
    "Unused",
    "Like some chatter",
    "Like some threats",
    "Just lying around",
    "Just lying around",
    "Not busy",
    "Like some fears",
    "Not engaged",
    "Not working",
    "Run without moving",
    "Goofing off",
    "Thumb-twiddling",
    "Groundless",
    "Off the job",
    "Run at the curb",
    "At rest",
    "Wait at the light",
    "Kind of talk",
    "Mothballed",
    "Loafing",
    "Not busy",
    "In neutral",
    "Not working",
    "Lounge",
    "Not going anywhere",
    "Not in use",
    "Out of action",
    "Nothing doing?",
    "Not occupied",
    "Like some hands",
    "Not in use",
    "Twiddling one's thumbs",
    "Loafing",
    "Run in neutral",
    "Not busy",
    "Not at work",
    "Lay off",
    "Lay off",
    "Out of action",
    "Turned off",
    "Loafing",
    "Indolent",
    "\"Monty Python\" troupe member",
    "Aimless",
    "Type of chatter",
    "\"Monty Python\" regular",
    "Unoccupied",
    "Run in place",
    "Thumb-twiddling",
    "Lay off",
    "Eric of \"Monty Python\"",
    "Not working",
    "Fruitless",
    "Run in neutral",
    "Not in use",
    "Thumb-twiddling",
    "Faineant"
  ],
  "babe": [
    "Sweets",
    "Honey",
    "Hon",
    "Sweetie",
    "\"The gallant pig\" of children's literature",
    "Dearie",
    "Sweets",
    "Honey",
    "Term of endearment",
    "Term of endearment",
    "Hon",
    "___ in arms",
    "Object of the search by the Three Wise Men",
    "Sweetie pie",
    "Sweets",
    "\"Hey, ___\" (casual greeting)",
    "Hon",
    "___ in the woods",
    "Dish",
    "Fox",
    "*___ Ruth",
    "Knockout",
    "With 67-Across, man whose 1930 salary was $80,000",
    "Innocent one",
    "Toots",
    "Jesus in the manger, e.g.",
    "Honey",
    "Term of endearment",
    "One \"in the woods\"",
    "Bygone Yankee great, with \"the\"",
    "Diamond legend, with \"the\"",
    "Naif",
    "Sweetie pie",
    "Honey",
    "Cinematic pig",
    "Innocent",
    "59-Across, familiarly",
    "Innocent",
    "Baseball's Ruth",
    "Jesus, in the manger",
    "Home run hitter Ruth",
    "Title pig in a 1995 film",
    "Innocent one",
    "Naif",
    "Paul Bunyan's ox",
    "One in swaddles",
    "Naif",
    "Newborn",
    "Paul Bunyan's ox",
    "With 21-Down, the first $80,000-a-year athlete",
    "Toots",
    "Term of endearment",
    "George Herman Ruth, familiarly",
    "Paul Bunyan's ox",
    "1995 porcine Oscar nominee",
    "1995 Best Picture nominee",
    "Unsophisticate",
    "___ Ruth",
    "Bunyan's Blue Ox"
  ],
  "amex": [
    "Discover alternative, for short",
    "One kind of plastic",
    "Kind of card",
    "Major credit card, briefly",
    "Company with a Roman centurion logo, informally",
    "Visa alternative",
    "Visa alternative",
    "Capital One competitor, for short",
    "Major name in cards",
    "Green card issuer, informally",
    "Green card distributor, informally",
    "Visa or MasterCard rival, informally",
    "Kind of card",
    "Visa/MC alternative",
    "Green card, in brief",
    "Certain charge card, informally",
    "\"Don't leave home without it\" card",
    "Charging giant, informally",
    "Visa alternative, for short",
    "Visa alternative, for short",
    "Alternative to MasterCard and Visa, informally",
    "Platinum Card offerer, for short",
    "Green card, informally",
    "___ card",
    "Kind of card",
    "Green card?",
    "Visa alternative, informally",
    "Kind of card",
    "MasterCard alternative, informally",
    "Discover competitor",
    "Certain stock index",
    "Business sect. heading",
    "62-Down rival, for short",
    "Financial page acronym",
    "Wall St. abbr.",
    "N.Y.S.E. relative",
    "Financial page heading",
    "Stock page heading",
    "Visa competitor",
    "N.Y.S.E. competitor",
    "Trader's abbr.",
    "Green card issuer",
    "Trading letters",
    "N.Y.S.E. competitor",
    "Wall St. acronym",
    "Stock page heading",
    "Visa rival",
    "Payment option, for short",
    "Big Board's brother: Abbr.",
    "Green card, informally",
    "N.Y.S.E. neighbor",
    "Stock heading",
    "N.Y.S.E. counterpart"
  ],
  "dora": [
    "Toon with a talking map",
    "Kids' TV character with a talking map",
    "Nickelodeon \"explorer\"",
    "Explorer of children's TV",
    "Surrealist Maar",
    "Nick Jr. explorer",
    "Young explorer of TV",
    "\"___ the Explorer\"",
    "\"___ the Explorer\"",
    "\"___ and the Lost City of Gold\" (2019 movie)",
    "Cartoon pal of the monkey Boots",
    "Woman's name meaning \"gift\"",
    "___ the Explorer",
    "\"Swiper, no swiping!\" speaker of children's TV",
    "Picasso muse ___ Maar",
    "Toon explorer",
    "Cartoon character who explores with Boots",
    "___ the Explorer",
    "___ the Explorer",
    "___ the Explorer",
    "\"David Copperfield\" wife",
    "___ Maar (Picasso's muse)",
    "Pseudonym of Freud's famed hysteria patient",
    "\"I Married ___\" (1987 ABC sitcom)",
    "Explorer of kidvid",
    "Artist Maar depicted in Picasso's \"The Weeping Woman\"",
    "TV \"explorer\"",
    "Explorer with a friend named Boots",
    "___ the Explorer",
    "TV girl with a talking map",
    "\"Cannery Row\" brothel owner",
    "Seven-year-old explorer",
    "Toon with a singing map",
    "Spanish-speaking explorer",
    "\"Explorer\" girl",
    "Animated \"Explorer\"",
    "Nickelodeon's \"___ the Explorer\"",
    "\"___ the Explorer\" (Nickelodeon show)",
    "___ Marquez, Nickelodeon cartoon girl",
    "Noted explorer traveling with a monkey",
    "Explorer of kiddie TV",
    "Pseudonym of a noted Freud patient",
    "Nickelodeon explorer",
    "TV explorer of note",
    "TV explorer",
    "Nickelodeon's explorer",
    "Celebrated Sigmund Freud patient",
    "Cartoon explorer",
    "1920s birth control advocate Russell",
    "___ Spenlow (Dickens girl)",
    "Little explorer on Nickelodeon",
    "\"Dumb ___\" (old comic strip)",
    "Nickelodeon explorer",
    "\"Cannery Row\" woman",
    "Nickelodeon's ___ the Explorer",
    "Nickelodeon's little explorer",
    "\"Dumb\" girl of old comics",
    "\"Dumb\" girl of old comics",
    "Nickelodeon's \"___ the Explorer\"",
    "\"David Copperfield\" wife",
    "\"Dumb ___\" (old comic)",
    "Nickelodeon cartoon explorer",
    "Mrs. Copperfield",
    "\"David Copperfield\" wife",
    "\"___ the Explorer\" (kids' show)",
    "\"Dumb\" girl of old comics",
    "___ Maar, Picasso subject",
    "Memorable 1964 Florida hurricane",
    "Mrs. Copperfield",
    "Copperfield's bride",
    "\"Dumb\" comics girl",
    "\"David Copperfield\" woman",
    "Nickelodeon's \"___ the Explorer\"",
    "___ Maar (Picasso subject)",
    "\"David Copperfield\" woman",
    "\"Dumb\" comics girl",
    "Copperfield's bride",
    "\"Dumb\" girl of comicdom",
    "David Copperfield's first wife",
    "\"Dumb\" girl of old comics",
    "\"Dumb\" girl of old comics",
    "\"Dumb ___\" (\"Blondie\" forerunner)",
    "Destructive 1964 Florida hurricane",
    "The first Mrs. Copperfield",
    "Mrs. David Copperfield",
    "___ Maar (Picasso mistress and subject)",
    "Dumb ___ (scatterbrain)",
    "___ Maar (Picasso subject)",
    "Mrs. Copperfield",
    "Mrs. David Copperfield",
    "\"Cannery Row\" character",
    "Celebrated Freud case",
    "Mrs. Copperfield",
    "\"Dumb\" girl",
    "\"Dumb ___\" (old comic)",
    "Mrs. David Copperfield",
    "Celebrated Freud case",
    "Dumb ___"
  ],
  "anti": [
    "Pro's opposite",
    "The \"A\" of A.D.L.",
    "Hardly a pro",
    "Saying \"nay\"",
    "Pro fighter?",
    "\"Nay\" sayer",
    "2016 #1 album for Rihanna",
    "Voting no",
    "One who hasn't turned pro?",
    "Opposite of pro-",
    "Lead-in to government or body",
    "Voting no",
    "Prefix with knock or lock",
    "Voting against",
    "Opposing",
    "Not pro",
    "Voting nay",
    "Opposed",
    "Opposed to",
    "Naysaying",
    "___-Defamation League",
    "Con",
    "Dead set against",
    "Not for",
    "Bill killer's position",
    "Not pro",
    "Con",
    "Adamantly against",
    "2016 #1 Rihanna album",
    "Disfavoring",
    "___-vaxxers",
    "Not having",
    "Prefix with thesis",
    "Opposing voice",
    "Prefix with discrimination",
    "Opposed",
    "Lining up against",
    "Set against",
    "Opposed to",
    "Cousin of contra-",
    "Not with",
    "Prefix with thesis",
    "Naysaying",
    "___-Defamation League",
    "Opposed to",
    "Rihanna's 2016 ___ World Tour",
    "___-inflammatory",
    "One opposed",
    "Rihanna album featuring \"Work\"",
    "Pro's opposite",
    "Nay voter",
    "2016 #1 album by Rihanna",
    "Against",
    "Voting against",
    "Voting against",
    "Not a fan of",
    "37-Down caller",
    "Con",
    "Hostile to",
    "Not pro",
    "Contrarian",
    "___-aging cream",
    "Start to freeze?",
    "Non-pro?",
    "Set against",
    "Relative of contra-",
    "Opposing voice",
    "One in opposition",
    "___-G suit",
    "Voting no",
    "Against",
    "Against",
    "Not pro",
    "Prefix with matter",
    "Voting no",
    "Prefix with matter",
    "Pro competitor",
    "\"No\" voter",
    "Against",
    "Lock opener?",
    "Prefix with lock",
    "Voting against",
    "Prefix with septic or tank",
    "Con",
    "Voting against",
    "War head?",
    "Start to matter?",
    "Prefix with lock or skid",
    "One opposed",
    "Con",
    "Debate position",
    "Voting nay",
    "Fighting",
    "Against",
    "Like con men?",
    "Opposing",
    "Prefix with disestablishmentarianism",
    "Con",
    "Start to matter?",
    "Cousin of contra-",
    "Opposed to",
    "Naysayer",
    "No voter",
    "Pro's foe",
    "Not a fan of",
    "Fighting",
    "Beginning to knock?",
    "Opposed to",
    "Opposed",
    "Prefix with disestablishmentarianism",
    "Opposition leader?",
    "Dead set against",
    "Voting no",
    "Debate side",
    "Opposing",
    "Prefix with knock",
    "Prefix with thesis",
    "What may start climactically?",
    "Start to freeze?",
    "Con",
    "Not having gone pro?",
    "Dead set against",
    "Prefix with skid",
    "Non-pro?",
    "Voting no",
    "Not pro",
    "Con man?",
    "Opposing",
    "Part of ABM",
    "Prefix with inflammatory",
    "Bucking",
    "Not pro",
    "Prefix with matter",
    "Protester",
    "Part of ABM",
    "Against",
    "Opposed to",
    "Objecting to",
    "Prefix with matter",
    "Prefix with lock or knock",
    "Not backing",
    "Pro fighter",
    "Opposed to",
    "\"Nay\" sayer",
    "Voting no",
    "Naysayer",
    "The \"A\" in ABM",
    "Opposed to",
    "Dissenter",
    "Not for",
    "Con",
    "Opposer",
    "Prefix with lock",
    "Prefix with body",
    "Voting second in a voice vote",
    "Cousin of contra-",
    "One who hasn't turned pro?",
    "One side in a debate",
    "No pro",
    "Con",
    "Pro's opposite",
    "Nay sayer",
    "Pro's rival",
    "Not for",
    "Prefix with knock or lock",
    "Voting no on",
    "Opposed to",
    "Con",
    "One side of a debate",
    "No pro",
    "Start to freeze?",
    "Voting \"nay\"",
    "Prefix with trust",
    "One side of a debate",
    "One opposed",
    "Planning to vote no",
    "Pro's opposite",
    "Prefix with lock or knock",
    "Pro's opposite",
    "One who hasn't turned pro?",
    "Voting no",
    "Con",
    "Not pro",
    "Nay sayer",
    "Not behind",
    "Pro foe",
    "Detractor",
    "Pro's foe",
    "Nay sayer",
    "Pro's foe",
    "Con",
    "Not pro",
    "Hostile one",
    "Against",
    "Con",
    "Con",
    "Against",
    "Oppositionist",
    "The \"A\" of ABM",
    "Dead against",
    "Picketer, perhaps",
    "Prefix with lock or knock",
    "Like some debaters",
    "Opposed to",
    "___-inflammatory",
    "Opposing",
    "Nay sayer",
    "Not pro",
    "Member of the other side",
    "Nay sayer",
    "Con",
    "Opposed to",
    "Dissenter",
    "Nay sayer",
    "Leaning against",
    "Not pro",
    "Debate side",
    "Opposing",
    "Pro's rival",
    "Con",
    "The \"A\" of ABM",
    "Pro's opposite",
    "Start to freeze?",
    "Opposed to",
    "Lock opener?",
    "Debate position",
    "Detractor",
    "Hostile to",
    "Social introduction?",
    "Naysayer",
    "Against",
    "Agin it",
    "Not for",
    "Not a pro",
    "Nay sayer",
    "Fighting",
    "Not supporting",
    "Con man?",
    "Opposed",
    "Prefix with lock or knock",
    "Not pro",
    "Prefix with disestablishmentarianism",
    "Naysayer",
    "Opposer",
    "Nay sayer",
    "Opposed",
    "Voting \"no\"",
    "Opponent",
    "Non-pro?",
    "Debate side",
    "Voting no",
    "Con",
    "Hostile to",
    "Prefix with knock or lock",
    "Dead against",
    "Leaning against",
    "Against",
    "Prefix with nuke or freeze",
    "Voting \"no\"",
    "Side in a debate",
    "Opposed to",
    "Debate side",
    "Part of ABM",
    "Naysaying",
    "Oppositionist",
    "Opponent",
    "Not backing",
    "Opposed",
    "Opposing",
    "The \"A\" in ABM",
    "One doing a con job?",
    "Opponent",
    "Not for",
    "The \"A\" of ABM",
    "Opposed",
    "Not pro",
    "Con",
    "One opposed",
    "Opposer",
    "Detractor",
    "\"No\" voter",
    "Opposing",
    "Voting no",
    "Con",
    "Con",
    "No voter",
    "Hostile to",
    "One opposed",
    "Not pro",
    "Contrary one",
    "Naysayer",
    "The \"A\" in ABM",
    "Contrarian",
    "No voter",
    "Con",
    "Prefix with skid",
    "Against",
    "Nay sayer",
    "One against",
    "Opponent",
    "Opposing",
    "Against",
    "Opposed",
    "Not for",
    "One who's \"agin\" it",
    "Oppositionist",
    "Opposed",
    "Voting nay"
  ],
  "bobo": [
    "Classic clown name",
    "Stereotypical clown name",
    "Classic clown name",
    "Classic clown with a repetitive name",
    "Common clown name",
    "Common clown name",
    "Classic clown name",
    "Film featuring Peter Sellers as a matador, with \"The\"",
    "Mr. Burns's teddy bear on \"The Simpsons\"",
    "Popular clown at kids' parties",
    "Common clown name"
  ],
  "imup": [
    "\"My turn!\"",
    "\"My turn to go\"",
    "Response to a morning knock",
    "Repeated cry after \"Stop shaking me!\"",
    "\"No need to wake me\"",
    "Grumbly \"Are you still sleeping?\" response",
    "\"My turn\"",
    "Shout when there's no cause for alarm?",
    "Exasperated cry in the morning",
    "\"You can stop trying to wake me now!\"",
    "Early morning declaration",
    "\"No need to wake me!\"",
    "\"You don't need to wake me\""
  ],
  "fire": [
    "Can",
    "Vulcan's specialty",
    "Command to a cannoneer",
    "What Vulcan was the god of",
    "Prometheus' gift",
    "Ax",
    "Tough stuff to walk through",
    "Boot",
    "See 3-Down",
    "Pink-slip",
    "Song by 56-Across",
    "Order after \"Ready ... aim ...\"",
    "Word that can combine with the ends of the answers to the six starred clues",
    "Let go",
    "Order after \"Aim!\"",
    "*Ax",
    "Can",
    "Boot",
    "Captain's command",
    "Boot or can",
    "Center of a campsite",
    "Flames",
    "Can",
    "Send packing",
    "Can",
    "Cause for alarm?",
    "Discharge a cannon",
    "Cashier",
    "It may be eaten at a circus",
    "Send packing",
    "Bad thing to be under",
    "Enthusiasm",
    "\"Let 'em have it!\"",
    "Can",
    "Passion",
    "\"Open ___!\"",
    "Order after \"aim\"",
    "Let go",
    "Let go",
    "What not to yell in a crowded theater",
    "Military command",
    "Volley"
  ],
  "tape": [
    "Stick with it!",
    "Radio recording",
    "It's on a roll",
    "Athletic trainer's supply",
    "Marathon finish line",
    "Alternative to glue",
    "Evidence that's hard to refute",
    "Sticky roll",
    "Word with recorder or measure",
    "Ending with ticker or masking",
    "Finish line ribbon",
    "Rapper's release",
    "Tailor's tool",
    "Tailor's aid",
    "\"Police Line - Do Not Cross\" material",
    "Ankle wrap for an athlete",
    "Stick with it",
    "Crime scene barrier",
    "Tailor's need",
    "3M product",
    "Stick with it",
    "Red stuff to cut through",
    "Stick with it",
    "3M product",
    "Jerry-rigging material",
    "Stick with it",
    "\"Roll the ___\"",
    "Scotch ___",
    "Reel-to-reel ___",
    "Adhesive",
    "Scotch ___",
    "Record for later viewing",
    "Finish line, often",
    "Mending aid",
    "Bit of evidence in court",
    "It's on a roll",
    "Answering machine insert",
    "Something to get caught on",
    "End of a footrace",
    "Kind of delay",
    "Bit of Watergate evidence",
    "Sticky stuff",
    "Gift-wrapper's need",
    "What some people get caught on",
    "Surveillance evidence",
    "Marathon terminus",
    "Bit of surveillance evidence",
    "Reel-to-reel ___",
    "Wiretapping evidence",
    "Captured, after \"on\"",
    "Put on record, but not actually on a record",
    "Surveillance evidence",
    "Bind",
    "Not live broadcasting",
    "Gift-wrapping need",
    "Alternative to a compact disc",
    "Welcome sight for a marathoner",
    "Put on reel-to-reel",
    "Copy, as a film",
    "Cash register part",
    "Mend",
    "Runner's goal",
    "Emergency medical kit item",
    "Scotch ___",
    "Mending stuff",
    "Race's end",
    "Race finish",
    "Item for a D.J.",
    "Sticky stuff",
    "VCR insert",
    "It may be magnetic",
    "Finish line, perhaps",
    "Hard-to-refute evidence",
    "Cash register output",
    "Race's finish line",
    "Watergate evidence",
    "Record on video",
    "Piece of evidence in a trial",
    "VCR insert",
    "A runner may break it",
    "It comes in a roll",
    "Supply in a nurse's kit",
    "3M product",
    "Blockbuster buy",
    "Measurer",
    "End of a dash",
    "Record",
    "Capture on video",
    "Sticky stuff",
    "Make a video",
    "Winners break it",
    "Measuring aid",
    "Video",
    "Video",
    "Stock ticker output",
    "Scotch or masking, e.g.",
    "The red kind is especially sticky",
    "Preserve, in a way",
    "Record",
    "Shipping need",
    "Video",
    "Record",
    "Masking, e.g.",
    "End of the race",
    "Race's end",
    "Runner's goal",
    "Sticky strip",
    "Record",
    "Mend, in a way",
    "Footrace terminus"
  ],
  "stir": [
    "Commotion",
    "Instruction in risotto recipes",
    "Mix with a spoon",
    "Big to-do",
    "Not remain completely asleep",
    "Brouhaha",
    "Hubbub",
    "Brouhaha",
    "Brouhaha",
    "Swizzle",
    "Tend to the sauce, say",
    "Move around and around",
    "Mix up",
    "Kerfuffle",
    "Hullabaloo",
    "Kindle",
    "Mix up",
    "Flutter one's eyelids, say",
    "Move a bit",
    "Come alive",
    "Ado",
    "Come out of a deep sleep",
    "Keep the sauce from congealing, say",
    "Rouse",
    "Mix, as paint",
    "Prevent from clumping, say",
    "Use a swizzle stick",
    "Ruckus",
    "Recipe directive",
    "Ado",
    "Hullabaloo",
    "Show signs of life",
    "Blend, as batter",
    "Awaken",
    "Ruckus",
    "Mix",
    "Kerfuffle",
    "Commotion",
    "Ruckus",
    "Ado",
    "Disturbance",
    "Recipe instruction",
    "To-do",
    "Keep the sauce from congealing, say",
    "To-do",
    "Blend using a spoon",
    "Hubbub",
    "Make homogeneous, perhaps",
    "Get moving",
    "Kool-Aid packet direction",
    "Show signs of life",
    "Brouhaha",
    "Ruckus",
    "Whoop-de-do",
    "Begin to come out of sleep",
    "Blend",
    "Hubbub",
    "Kool-Aid instruction",
    "Move around",
    "Start moving",
    "Prison, informally",
    "Direction in a bartender guide",
    "Brouhaha",
    "To-do",
    "Big whoop",
    "What to do after adding cream or sugar",
    "Use a swizzle stick",
    "Mix with a spoon",
    "Flutter the eyelids, say",
    "Kerfuffle",
    "Commotion",
    "Rustle",
    "Prepare, as eggnog",
    "Use a teaspoon in tea, e.g.",
    "Start to wake up",
    "Ruckus",
    "Paint can instruction",
    "Excitement",
    "Hubbub",
    "Make a mistake preparing James Bond's martini",
    "Rouse",
    "Hubbub",
    "Cookbook instruction",
    "Recipe direction",
    "Excite",
    "Mix",
    "Incite",
    "Shake, say",
    "Kind of crazy?",
    "Ado",
    "___-fry",
    "Disturbance",
    "Hurly-burly",
    "The cooler",
    "Kindle",
    "Ferment",
    "Recipe verb",
    "Ruckus",
    "To-do",
    "Disturbance",
    "Show signs of life",
    "Hoo-ha",
    "Hubbub",
    "Move a bit",
    "Commotion",
    "Hubbub",
    "Do something emotionally to",
    "Become active",
    "Mix up",
    "Mix up",
    "Use a swizzle stick",
    "Use a swizzle stick",
    "Recipe instruction",
    "Swizzle",
    "Brouhaha",
    "Blend",
    "Paint can instruction",
    "Cookbook direction",
    "Hurly-burly",
    "Hullabaloo",
    "Mix (up)",
    "Roil",
    "Disturb",
    "Get moving",
    "Start to flutter one's eyes, say",
    "Rouse",
    "Mix together",
    "Move a little",
    "To-do",
    "Mix up",
    "Recipe direction",
    "Ado",
    "Tend the sauce",
    "Energize",
    "Unrest",
    "Cookbook direction",
    "Recipe direction",
    "Arouse",
    "Set-to",
    "Mix (up)",
    "Affect emotionally",
    "Use a swizzle stick",
    "Hurly-burly",
    "The cooler",
    "Bustle",
    "Flap",
    "Hoosegow",
    "Mix up",
    "___-crazy",
    "Hubbub",
    "The big house",
    "Hoosegow",
    "To-do",
    "Hubbub",
    "Commotion",
    "The big house",
    "Recipe direction",
    "Commotion",
    "Con's place",
    "The clink",
    "Culinary directive",
    "Awaken",
    "Flap",
    "Pother",
    "Pother",
    "Bustle",
    "Ado",
    "Begin to wake up",
    "Paint can direction",
    "Create a solution?",
    "Use a spoon, in a way",
    "Paint can direction",
    "To-do",
    "Move a muscle",
    "Flap",
    "Motivate",
    "Get a move on",
    "Mix up",
    "Pokey",
    "Begin to wake",
    "Ado",
    "Whoop-de-do",
    "Slammer",
    "Move",
    "Move",
    "Slammer",
    "Awaken",
    "Swizzle",
    "Swizzle",
    "Turmoil",
    "Ruckus",
    "Be up and about",
    "Sensation",
    "Recipe directive",
    "Budge",
    "Recipe direction",
    "Do a bartending job",
    "Slammer",
    "Move a muscle",
    "Pokey",
    "Hullabaloo",
    "Recipe directive",
    "Swirl with a spoon",
    "Agitate",
    "Arouse",
    "Fuss",
    "Agitate",
    "Fuss",
    "Recipe direction",
    "Hubbub",
    "Agitate",
    "Recipe word",
    "Mix",
    "Calaboose",
    "Clink",
    "Child's order?",
    "Move a finger",
    "Agitate",
    "Commotion",
    "Budge",
    "Start waking up",
    "Move about",
    "Awaken",
    "Get the juices flowing",
    "Be up and about",
    "To-do",
    "Swizzle",
    "Agitate",
    "Fuss",
    "Budge",
    "Brouhaha",
    "The slammer",
    "Agitate",
    "The joint",
    "Pen",
    "Agitate",
    "Kind of crazy",
    "Kind of crazy",
    "Cool, as coffee",
    "Move",
    "Rouse to action",
    "Paint can direction"
  ],
  "argo": [
    "Preceder of a certain \"-naut\"",
    "Ship built with the help of Athena",
    "Jason's ship, in Greek myth",
    "Award-winning film set in Tehran",
    "2012 Ben Affleck film",
    "2012 Best Picture winner",
    "Vessel protected by Hera",
    "Brand of cornstarch",
    "Best Picture winner set in 1-Across",
    "Ship that survived the Clashing Rocks, in legend",
    "Best Picture winner directed by Ben Affleck",
    "Ship guarded by Hera",
    "Jason's ship, in myth",
    "Jason's ship, in myth",
    "Ship that Athena helped build, in myth",
    "Mythical ship sailed by Jason",
    "Besides \"Gigi,\" the only Best Picture winner with a four-letter name",
    "Mythical ship that gave its name to a constellation",
    "Best Picture of 2012",
    "Cornstarch brand since 1892",
    "Ship of mythology",
    "2012 film about the so-called \"Canadian Caper\"",
    "2012 Best Picture winner set in Iran",
    "Best Picture winner based on events of 1979-80",
    "Ship of myth",
    "Ship with a prophetic prow",
    "Hit 2012 movie whose plot involves a plan to make a movie",
    "Best Picture before \"12 Years a Slave\"",
    "Jason's fleece-seeking ship",
    "Jason's ship, in myth",
    "Best Picture after \"The Artist\"",
    "Jason's vessel, in myth",
    "Best Picture partly adapted from a C.I.A. operative's book",
    "Jason's ship",
    "Jason's ship",
    "Old ship constellation",
    "Movie featuring Ben Affleck as a C.I.A. agent",
    "2012 thriller with John Goodman and Alan Arkin",
    "Greek vessel",
    "Jason's ship",
    "Legendary galley",
    "2012 political thriller",
    "First steamship with a planned circumnavigation of the globe",
    "2012 Best Picture with Ben Affleck",
    "Rescue film of 2012",
    "Best Picture before \"12 Years a Slave\"",
    "2012 Ben Affleck film set in Iran",
    "Oscar-winning Ben Affleck film",
    "Ship in the search for the Golden Fleece",
    "Award-winning 2012 film about a fake film",
    "Oscar-winning film based partly on the book \"The Master of Disguise\"",
    "Best Picture of 2012",
    "Mythical ship",
    "Oscar-winning Ben Affleck picture",
    "Oscar-winning film set in Iran",
    "It set sail from Iolcus",
    "Jason's ship",
    "Vessel protected by Hera",
    "Golden Fleece ship",
    "Jason's ship, in myth",
    "Mythical ship with a speaking oak beam",
    "Unmanned vehicle that found the Titanic",
    "Jason's ship, in myth",
    "Ancient Greek vessel",
    "Talos hurled huge stones at it",
    "Ship written about by Apollonius of Rhodes",
    "Ship in search of the Golden Fleece",
    "Cornstarch brand",
    "Galley of myth",
    "Cornstarch brand",
    "Popular cornstarch brand",
    "Ship launched from Iolcus",
    "Former southern constellation in the shape of a ship",
    "Jason's craft",
    "Xena's horse",
    "Classically named Martian crater visited by the Opportunity rover",
    "Mythological ship",
    "Jason's ship",
    "Sirens passer",
    "Jason's ship",
    "Craft that pulled over for Sirens",
    "Cornstarch brand",
    "Golden Fleece transporter",
    "Medea rode on it",
    "It made it past sirens",
    "Large southern constellation",
    "It survived the crashing rocks, in myth",
    "Jason's ship, in myth",
    "Famous 50-oared ship",
    "Mythical craft",
    "Medea rode on it",
    "Jason's ship",
    "50-oared ship",
    "Canis Major neighbor",
    "It had 50 oars, in mythology",
    "Mythical craft",
    "Jason's ship",
    "Ship of myth",
    "Ship to Colchis",
    "Jason's charge",
    "Constellation with Canopus",
    "Ship to Colchis",
    "Jason's ship",
    "Cornstarch brand",
    "Giant constellation south of Canis Major",
    "Ship that sailed in quest of the Golden Fleece",
    "Medea rode on it",
    "Constellation south of the Big Dipper",
    "Jason's craft",
    "Jason's ship",
    "Ship on which Heracles sailed",
    "Name on a cornstarch box",
    "Jason's ship",
    "Golden Fleece craft",
    "Ship to Colchis",
    "35-Across's vessel",
    "Ship built from trees on Pelion",
    "Ship that sailed from Iolcos",
    "Jason's ship",
    "Ship of myth",
    "Jason's ship",
    "Jason's craft",
    "Cornstarch brand",
    "Important ship of myth",
    "It's south of Canis Major",
    "Golden Fleece ship",
    "Ship sailed by Tiphys",
    "Ship to Colchis"
  ],
  "pens": [
    "Puts on paper",
    "Products of Pilot and Bic",
    "Writes",
    "Calligraphy tools",
    "Corrals",
    "Things you might open with a click",
    "Writers might click them",
    "Calligraphers' choices",
    "Pilot production?",
    "Products from Parker and Cross",
    "Some hotel freebies",
    "Female swans",
    "Calligrapher's collection",
    "Writes",
    "Stock holders",
    "Staples of bank counters",
    "Stationery shop display",
    "They may be felt on a desk",
    "Digs for pigs",
    "Items in pocket protectors",
    "Writes indelibly",
    "Desk set",
    "Signature pieces?",
    "Authors",
    "Coops",
    "Sharpies, e.g.",
    "Waterman products",
    "Parker products",
    "Bank chains hold them",
    "Cross stock",
    "Stockyard divisions",
    "They used to come from wells",
    "Sharpies, e.g.",
    "Female swans",
    "Confident solvers' supply",
    "Set on a desk",
    "Cross and Parker products",
    "What stylophiles collect",
    "Writes",
    "Paper mates",
    "Puts in writing",
    "Stationer's supply",
    "They may click with writers",
    "They used to be lowered into wells",
    "Zoo sights",
    "Confident puzzlers' tools",
    "Highlighters, e.g.",
    "Ballpoints, e.g.",
    "Writes",
    "Crosses, e.g.",
    "State prisons",
    "Parker and Waterman",
    "Composes",
    "Ballpoints",
    "Pocket protector items",
    "Parker and Waterman",
    "Kennel features",
    "Cross products",
    "They may be felt",
    "Bic products",
    "They may be felt",
    "Bics, e.g.",
    "Coolers",
    "Bic or Parker products"
  ],
  "deny": [
    "Withhold from",
    "Controvert",
    "Refuse",
    "Turn down",
    "Refuse",
    "When tripled, a liar's mantra",
    "Refuse",
    "Not allow",
    "Gainsay",
    "Disavow",
    "Refuse to admit",
    "Reject as false",
    "Refute",
    "Say it isn't so",
    "Refuse to admit",
    "Gainsay",
    "Forbid",
    "Refuse to grant",
    "Refuse to grant",
    "Gainsay",
    "Fail to grant, in court",
    "Turn down",
    "Keep from",
    "Disavow",
    "Next word after \"Wherefore art thou Romeo?\"",
    "Refuse",
    "Turn down",
    "Reject, as an accusation",
    "Refute",
    "Don't fess up to",
    "Last word of \"For He's a Jolly Good Fellow\"",
    "Turn down",
    "Say isn't so",
    "Contradict",
    "Say no to",
    "Refuse to grant",
    "Say \"No, I didn't\"",
    "Turn down",
    "Turn down",
    "Refuse",
    "Turn down",
    "Withhold",
    "Controvert",
    "Not allow",
    "Withhold from",
    "What \"nobody can\" do, in song",
    "Withhold from",
    "Disavow",
    "Declare untrue",
    "Contradict",
    "Suspect's byword",
    "Not accept",
    "Refuse",
    "Plead innocent to",
    "Naysay",
    "Turn down",
    "Forswear",
    "Just say no",
    "Last word of \"For He's a Jolly Good Fellow\"",
    "Contradict",
    "Declare false",
    "Withhold",
    "Withhold, as funds",
    "When said three times, a liar's policy",
    "Disavow",
    "Naysay",
    "Say it ain't so",
    "Just say no",
    "Disallow",
    "Forswear",
    "Say it ain't so",
    "Gainsay",
    "Last word of \"For He's a Jolly Good Fellow\"",
    "Turn down",
    "Naysay",
    "Gainsay",
    "Gainsay",
    "Just say no",
    "Gainsay"
  ],
  "lake": [
    "Canoeing locale",
    "Death Valley was once one",
    "\"Go jump in the ___!\"",
    "Habitat for a trout",
    "Many a summer cottage locale",
    "Africa's ___ Chad",
    "Reservoir, e.g.",
    "Interest for a limnologist",
    "Lead-in to Victoria or Albert",
    "Summer camp locale",
    "With 24-Across, body of water that's in four African countries",
    "With 48-Down, setting for Toledo",
    "The Caspian Sea, as often classified",
    "Skating spot, maybe",
    "Okeechobee, e.g.",
    "Superior body?",
    "It may be skimmed over",
    "With 19-Across, borderer of four states",
    "Limnological study",
    "With 35-Across, view from Cleveland",
    "Summer camp locale",
    "Africa's ___ Victoria",
    "___ Michigan",
    "Word before Charles or George",
    "Great Salt ___",
    "It has a large bed",
    "One of 10,000 in Minnesota",
    "Summer camp location",
    "___ George",
    "Water-skiing locale",
    "Cottage site",
    "Superior, e.g.",
    "Winnipeg or Ontario",
    "Trout's home",
    "One of a great quintet",
    "Vacation location",
    "Fishing locale",
    "Water-skiing locale",
    "Como, for one",
    "Camp locale",
    "Cabin spot",
    "Place for a cabin",
    "Winnebago, e.g.",
    "Area behind a dam",
    "Summer cabin site",
    "Victoria, e.g.",
    "Cottage site",
    "TV's Ricki",
    "Huron, for one",
    "Charles or George, e.g.",
    "Superior designation",
    "Daytime talk show host",
    "Summer camp site",
    "Louise or Victoria",
    "Wobegon, e.g."
  ],
  "edys": [
    "Turkey Hill competitor",
    "Brand in the ice cream aisle",
    "Ice cream brand",
    "Maker of Brownie Brick Road ice cream",
    "Haagen-Dazs competitor",
    "Ben & Jerry's alternative",
    "\"Slow Churned\" ice cream brand",
    "Big name in the frozen aisle",
    "Ice cream brand",
    "\"Grand\" brand of ice cream",
    "Breyer's competitor",
    "Competitor of Blue Bell",
    "Neighbor of Ben & Jerry's in the freezer section",
    "Big name in ice cream",
    "Brand with the flavor French Silk",
    "Ice cream brand known as Dreyer's west of the Rockies",
    "Breyers alternative",
    "Slow Churned ice cream brand",
    "\"Slow Churned\" brand",
    "Freezer section brand",
    "Breyers rival",
    "Nestle brand",
    "Ben & Jerry's alternative",
    "Brand known as Dreyer's in the West",
    "Ben & Jerry's alternative",
    "One of its flavors is Mud Pie",
    "Breyers alternative",
    "Ice cream brand",
    "Ice cream brand",
    "\"Slow Churned\" brand",
    "Breyers competitor",
    "Haagen-Dazs competitor",
    "Ben & Jerry's competitor",
    "Nestle brand",
    "Brand that has Dibs",
    "Brand with the flavor Fudge Tracks",
    "Breyers alternative",
    "Ice cream brand",
    "\"Slow Churned\" brand",
    "One of its flavors is Dulce de Leche",
    "\"Slow Churned\" brand",
    "Alternative to Haagen-Dazs",
    "Ice cream brand",
    "Competitor of Ben & Jerry's",
    "Big name in ice cream",
    "Haagen-Dazs alternative",
    "\"Grand\" ice cream brand",
    "Haagen-Dazs alternative",
    "Ice cream brand",
    "Big name in ice cream",
    "Blue Bell alternative",
    "Popular ice cream",
    "Popular ice cream",
    "\"Grand\" brand",
    "Popular ice cream",
    "Alternative to Breyers",
    "Popular ice cream",
    "___ Grand (supermarket brand)",
    "Breyers rival",
    "Breyers rival",
    "Popular ice cream",
    "Frozen dessert brand",
    "\"Grand\" ice cream brand",
    "Brand in the freezer section",
    "Breyers competitor",
    "Breyers competitor",
    "Popular ice cream",
    "Popular ice cream",
    "Haagen-Dazs alternative",
    "Popular ice cream",
    "Popular ice cream",
    "Breyers rival",
    "Breyers rival",
    "Haagen-Dazs alternative",
    "Ice cream brand",
    "Ice cream brand",
    "Ice cream brand",
    "Ice cream brand",
    "Popular ice cream brand"
  ],
  "penn": [
    "William who lent his name to a state",
    "Big name in tennis balls",
    "Teller's partner in magic",
    "Brown alternative",
    "Campus abutting Drexel, informally",
    "The Quakers of the N.C.A.A.",
    "Ivy League school in Philly",
    "William with a state named after him",
    "Half of a long-running Vegas show",
    "Quaker William",
    "Southernmost Ivy",
    "Founder of Philadelphia",
    "Southernmost of the Ivies",
    "One of the Ivies",
    "State with part of I-81: Abbr.",
    "Half of a Vegas show duo",
    "Talkative half of a magic duo",
    "One of the Ivies, informally",
    "School founded by Ben Franklin",
    "See 44-Down",
    "Ivy League school in Philly",
    "Ivy League school in Philly",
    "One of the Colonial Colleges, informally",
    "Sean of \"Milk\"",
    "One of the 53-Downs",
    "New York's ___ Station",
    "An Ivy",
    "State-founding Friend",
    "Ivy League choice",
    "Ivy League school in Philly",
    "\"All the King's Men\" star, 2006",
    "William for whom a colony was named",
    "The Quakers of the Ivy League",
    "Brown alternative",
    "Colonial ___ (insurance firm)",
    "___ Station",
    "One of the Ivys",
    "William who has a state named after him",
    "\"Mystic River\" co-star, 2003",
    "___ Station",
    "Friend who founded a state",
    "One of the Ivies",
    "___ Station",
    "Brown rival",
    "One of the Ivies",
    "Magician Jillette",
    "Novelist/poet Robert ___ Warren",
    "Quaker William",
    "One of the Ivies",
    "One of the Ivies",
    "Ivy League school, briefly",
    "The Big Apple's ___ Station",
    "Ivy League team",
    "Famous Friend",
    "Ivy League team",
    "Feller with Teller",
    "Ivy League team",
    "Ivy League power",
    "Teller's partner in magic",
    "___ Station",
    "Big Ten's ___ State",
    "Poet Robert ___ Warren",
    "Big 10's ___ State",
    "\"State of Grace\" star",
    "One 39-Across",
    "Actor Sean",
    "N.Y.C. station"
  ],
  "twin": [
    "Womb mate",
    "Mattress size",
    "Matching",
    "Bed smaller than a queen",
    "See 29-Across",
    "Exact match",
    "Doppelganger",
    "Bed size",
    "Lookalike",
    "Certain bed size",
    "Minnesota baseballer",
    "Duplicate",
    "Doppelganger",
    "Romulus or Remus",
    "King's little cousin",
    "___-engine",
    "Mattress size",
    "Romulus or Remus",
    "Look-alike",
    "Minnesota player",
    "Bed size",
    "Mattress size",
    "Exact look-alike",
    "Romulus or Remus",
    "Matching",
    "Romulus or Remus",
    "Either half of Gemini",
    "Jenna or Barbara Bush",
    "Look-alike",
    "Mattress type",
    "Identical",
    "Romulus or Remus",
    "Bed size smaller than full",
    "Doppelganger",
    "___-engine",
    "Matching",
    "Minnesota ballplayer",
    "Two-screen cinema",
    "Double",
    "Castor, to Pollux",
    "Double",
    "One with a look-alike",
    "Like some beds",
    "Kind of bed",
    "Kind of bed",
    "A.L. player",
    "Divide into two",
    "Certain moviehouse",
    "Bed size",
    "Identical match",
    "Certain movie theater",
    "Hayley Mills role in \"The Parent Trap\"",
    "Artemis, to Apollo",
    "Jacob or Esau"
  ],
  "yoda": [
    "Baby ___ (\"The Mandalorian\" nickname)",
    "Diminutive master of film",
    "Who says \"When you look at the dark side, careful you must be\"",
    "Whom Grogu resembles on \"The Mandalorian\"",
    "Jedi who trained Luke",
    "Baby ___, character in \"The Mandalorian\"",
    "Diminutive Jedi master",
    "\"Star Wars\" character who could this clue have written?",
    "Jedi guru",
    "Film character who says \"Named must be your fear before banish it you can\"",
    "Sci-fi sage",
    "Robe-wearing trainer of cinema",
    "Sage swamp-dweller of film",
    "Film character who says \"Adventure. Excitement. A Jedi craves not these things\"",
    "Someone who speaks like the quote in 25-Across",
    "\"Star Wars\" sage",
    "Luke Skywalker's mentor",
    "\"Star Wars\" sage",
    "Jedi Council leader",
    "Jedi master",
    "Big-eared \"Star Wars\" character",
    "Talks like this in \"Star Wars\" films he does",
    "Speaker of \"Luke, when gone am I, the last of the Jedi will you be\"",
    "\"Star Wars\" guru",
    "Jedi with a big forehead",
    "Sage exiled on the planet Dagobah",
    "Film character who lives to be 877",
    "Luke Skywalker's mentor",
    "Jedi Council leader",
    "His planet of exile is Dagobah",
    "Film character who says \"Named must your fear be before banish it you can\"",
    "Sci-fi sage",
    "Little green man",
    "\"Star Wars\" guru",
    "\"Star Wars\" sage",
    "In a Weird Al Yankovic song, he \"looks like a Muppet, but he's wrinkled and green\"",
    "Film character who says \"Do, or do not. There is no 'try'\"",
    "Luke Skywalker's mentor",
    "Luke Skywalker's mentor",
    "\"Star Wars\" character",
    "Sage of sci-fi film",
    "Sci-fi sage",
    "Mentor for Luke Skywalker",
    "\"Star Wars\" sage",
    "Skywalker's mentor",
    "Geriatric Jedi master",
    "\"Star Wars\" sage",
    "Luke's mentor, in \"Star Wars\"",
    "Skywalker mentor",
    "The Force was with him",
    "\"Star Wars\" sage",
    "\"The Empire Strikes Back\" mystic",
    "Film character with the voice of Frank Oz",
    "Luke's \"Star Wars\" mentor",
    "\"Star Wars\" sage",
    "Luke was his disciple"
  ],
  "sris": [
    "Indian honorifics",
    "Indian honorifics",
    "Hindu honorifics",
    "Honorifics that can be repeated to indicate higher status",
    "Hindu honorifics",
    "Mumbai titles",
    "Eastern titles",
    "Mumbai misters",
    "Some foreign misters",
    "Titles for distinguished Indians",
    "Eastern titles",
    "Titles of respect",
    "Gurus' titles",
    "Eastern holy men's titles",
    "Hindu titles",
    "Indian titles of respect",
    "Hindu titles",
    "Indian honorifics",
    "Hindu honorifics",
    "Hindu titles",
    "Eastern titles",
    "Eastern \"holies\"",
    "Hindu honorifics"
  ],
  "fudd": [
    "\"Wabbit\" pursuer Elmer",
    "Elmer J. of toondom",
    "Fictional rabbit hunter",
    "Elmer with a big gun",
    "Elmer with a double-barreled shotgun",
    "Warner Bros. cartoon name",
    "Elmer who hunts wabbits",
    "Fictional hunter",
    "\"Merrie Melodies\" name"
  ],
  "oils": [
    "Some fine art",
    "\"Essential\" things",
    "Avocado or olive products",
    "Some skin care products",
    "Perfume ingredients",
    "Classic paintings",
    "Hydrophobic substances",
    "Classical paintings",
    "The \"palm\" and \"olive\" of Palmolive",
    "Masseuse's stock",
    "Gallery fill",
    "Works at the Guggenheim",
    "Lubricates",
    "Classical paintings",
    "Gainsborough's \"The Blue Boy\" and others",
    "Lubricates",
    "Classical paintings",
    "De-squeaks",
    "Many Manets",
    "Much of the Guggenheim's collection",
    "They build up in pores",
    "Traditional paintings",
    "Works at the National Gallery",
    "Works in a studio, say",
    "Palm products",
    "Met works",
    "Art collector's collection",
    "Mr. Universe contestants' needs",
    "Lubricates",
    "Many works at the Met",
    "Removes a squeak from",
    "Some Da Vinci pieces",
    "Removes a squeak from",
    "\"The Blue Boy\" and others",
    "Lubricates",
    "Bribes",
    "Traditional paintings",
    "Works at a museum",
    "Traditional paintings",
    "\"Essential\" things",
    "Museum display",
    "Some artwork",
    "Palette globs",
    "Classical paintings",
    "Frick collection",
    "Stops squeaking",
    "Some museum hangings",
    "Masseur supplies",
    "Artworks",
    "Bath additions",
    "Perfume ingredients",
    "Met display",
    "Removes squeaks from",
    "Softens, as leather",
    "Fixes a squeak",
    "Lubricates",
    "Art store stock",
    "Masseur's stock",
    "Removes a squeak from",
    "Makes shine",
    "Portraitists' purchases",
    "Protects against rust",
    "Works at the Louvre",
    "Quiets, in a way",
    "Impressionist works",
    "Rembrandt works",
    "Standard varieties",
    "Squelches a squeak",
    "Rembrandts, e.g.",
    "Portraitist's medium",
    "Some paintings",
    "Takes care of the squeaky wheel",
    "Works at the Met",
    "Services, in a way",
    "Many paintings",
    "Provides machine maintenance",
    "Artists' purchases",
    "Some are essential",
    "Art supplies"
  ],
  "apes": [
    "Imitates",
    "King Kong and others",
    "The avant-garde \"artists\" Congo and Pierre Brassau",
    "Chimps and bonobos",
    "Animals found in 16-, 20-, 35-, 51- and 58-Across",
    "Some sign language users",
    "Brutes",
    "Mimics",
    "Donkey Kong and others",
    "Caesar's army, in a popular film franchise",
    "Zoo exhibit",
    "Outdoor section of a zoo",
    "Humans' closest relatives",
    "\"Great\" creatures",
    "\"Planet of the ___\"",
    "Residents of a 1968 movie \"planet\"",
    "Section at a zoo",
    "Some early \"astronauts\"",
    "Mimics",
    "Gorillas",
    "Long-armed banana lovers",
    "Bonobos, e.g.",
    "Monkeys' uncles?",
    "Gibbons, e.g.",
    "Pithecological study",
    "Gorillas",
    "Tarzan's adopters",
    "Humans' cousins",
    "\"Planet of the ___\"",
    "Some early astronauts",
    "Copiers",
    "Big tree climbers",
    "Burlesques",
    "Copies",
    "Some of them have learned to sign",
    "Big galoots",
    "Goons",
    "Mimics",
    "Knuckle draggers",
    "\"Planet of the ___\"",
    "Hired thugs",
    "Goons",
    "Gorillas",
    "Imitates",
    "Galoots",
    "Galoots",
    "Big lugs",
    "Some early \"astronauts\"",
    "Mimics mockingly",
    "Acts like",
    "\"Tarzan of the ___\"",
    "Goons",
    "Makes like",
    "King Kong's kin",
    "King Kong's kin",
    "Knuckle draggers",
    "\"Planet of the ___\"",
    "Copiers",
    "Goons",
    "Monkey's uncles?",
    "Lummoxes",
    "Goons",
    "Parrots",
    "Gorillas and such",
    "Makes like",
    "Pals of Tarzan",
    "Hairy Halloween costumes",
    "Pithecologists' study",
    "Primatologist's study",
    "Makes like",
    "Mimics",
    "Big galoots",
    "Animals of the species Pan troglodyte",
    "Gibbons and gorillas",
    "Zoo houseful",
    "Level on the evolutionary ladder",
    "Zoo animals",
    "Tarzan's raisers",
    "Makes fun of, in a way",
    "Captors in a sci-fi classic",
    "Forest swingers",
    "Big galoots",
    "Simians",
    "\"Great\" beasts",
    "Real swingers?",
    "Gorillas and chimps",
    "Bronx Zoo houseful",
    "Monkeys' uncles?",
    "Extras in the opening of \"2001: A Space Odyssey\"",
    "Earth rulers in a 1968 film",
    "Steps in human evolution",
    "Jungle swingers",
    "Goons",
    "Henchmen",
    "Subjects of Darwinian theory",
    "King Kong's kin",
    "Simians",
    "\"Planet of the ___\"",
    "People preceders",
    "Copies",
    "Monkeys' uncles",
    "Parodies",
    "Lunks",
    "King Kong and others",
    "Greystoke's foster parents",
    "Copies",
    "\"2001\" characters",
    "Some are \"great\"",
    "Fossey's fascination",
    "Copies",
    "Jungle creatures",
    "Parrots",
    "Primatologists' subjects",
    "Mimics",
    "Barbary beasts",
    "\"Tarzan\" extras",
    "They go bananas over bananas",
    "Copycats",
    "Simians",
    "Chimps and such",
    "Galoots",
    "King Kong's kin",
    "Zoo attractions",
    "Copies",
    "Lummoxes",
    "Takes after",
    "Dian Fossey subjects",
    "Steps on the evolutionary ladder",
    "Mimics",
    "Copyists",
    "Gibbons",
    "Makes like",
    "Primates",
    "\"Greystoke\" extras",
    "Gibbons"
  ],
  "hide": [
    "Prep for a surprise party, in a way",
    "Cry just before the guest of honor arrives at a surprise party",
    "Be outta sight?",
    "Cache",
    "Stash",
    "Cry just before the birthday guest arrives at a surprise party",
    "Half of a children's game",
    "Cry at a surprise birthday party",
    "Online option that turns into \"show\" when clicked",
    "Partner of \"go seek\"",
    "Cry to guests at a surprise party",
    "Lie low",
    "Command at a surprise party",
    "Palm, in a way",
    "Cry at a surprise party just before the honoree arrives",
    "Go underground",
    "Stash",
    "Stay out of sight",
    "Get behind something?",
    "Need to tan",
    "Camouflage",
    "Conceal",
    "Tanned skin",
    "Mask",
    "Camouflage",
    "Skin",
    "Enroll in a witness protection program, say",
    "Cow cover",
    "Camouflage",
    "Obscure",
    "Lie low",
    "Screen",
    "Screen",
    "Screen",
    "Palm, say",
    "Be hard to spot",
    "Sweep under the rug",
    "Sweep under the rug",
    "Conceal",
    "Camouflage",
    "Lurk",
    "Leather, essentially",
    "Stay in the closet, say",
    "Lie low",
    "Conceal",
    "Take cover",
    "Seek's opposite",
    "Take cover",
    "Surprise party command",
    "Mask",
    "Harbor",
    "Pelt",
    "Tanning need",
    "Fur",
    "Whitewash",
    "Cover up",
    "Screen",
    "Tanning target",
    "Trapper's ware",
    "Whitewash",
    "Stash",
    "Conceal",
    "Bury",
    "Secrete",
    "Pelt",
    "Closet",
    "Ensconce"
  ],
  "woof": [
    "Big dog's bark",
    "Response to a knock at the door, perhaps",
    "Bowser's warning",
    "Dog's bark",
    "Pound sound",
    "Great Dane sound",
    "Sound from a pound",
    "Bark",
    "Bark",
    "Pound sound",
    "Kennel sound",
    "Rough bark",
    "St. Bernard sound",
    "St. Bernard's bark",
    "Pound sound",
    "Canine comment"
  ],
  "bane": [
    "Nemesis",
    "Source of ruin",
    "The ___ of one's existence",
    "Scourge",
    "Cause of ruin",
    "Scourge",
    "Bete noire",
    "Scourge",
    "___ of one's existence",
    "Cause of ruination",
    "Bete noire",
    "___ of one's existence",
    "Scourge",
    "Plague",
    "Source of misery",
    "___ of one's existence",
    "Deadly poison",
    "\"The Dark Knight Rises\" villain",
    "___ of one's existence",
    "Constant nuisance",
    "Ruination",
    "Source of ruin",
    "Curse",
    "Ruin",
    "Unhappy influence",
    "Anathema",
    "Poison",
    "Scourge",
    "Ruination",
    "Blight",
    "Downfall",
    "Cause of one's undoing",
    "Cause of distress",
    "Ruin",
    "Undoing",
    "Witch's brew",
    "Cause of misery",
    "Curse",
    "Ill bringer",
    "Bete noire",
    "Ruination",
    "Deadly poison",
    "Ruination",
    "Ruination",
    "Curse",
    "Deadly poison",
    "Spoiler",
    "Curse",
    "Scourge",
    "___ of one's existence",
    "Ruination",
    "Curse",
    "Plague",
    "Scourge",
    "Curse",
    "Curse"
  ],
  "asks": [
    "Doesn't just assume, say",
    "Inquires",
    "Bidding considerations",
    "Picks the brain of",
    "Doesn't just assume",
    "Queries",
    "Poses",
    "Requests",
    "Requests",
    "Names as a price",
    "Inquires",
    "Questions",
    "Seeks, as a price",
    "Calls on",
    "Seeks help",
    "Sets, as a price",
    "Requests",
    "Does some grilling",
    "Raises a question",
    "Poses a question",
    "Inquires",
    "Doesn't just assume",
    "Poses a poser",
    "Poses",
    "Questions",
    "Does some grilling",
    "Does some grilling",
    "Requests, with \"for\"",
    "Seeks answers",
    "Queries",
    "Poses questions",
    "Questions",
    "Inquires",
    "___ for it (invites trouble)",
    "Pops, as the question",
    "Cries (for)",
    "Quizzes",
    "Puts it to",
    "Questions",
    "Questions",
    "Grills",
    "Quotes, as a price",
    "Makes inquiries",
    "Calls upon",
    "Interviews",
    "Probes",
    "Queries",
    "Questions",
    "Expresses wonder?",
    "Questions",
    "Puts it to",
    "Queries",
    "Poses posers",
    "Emulates a reporter",
    "Seeks assistance",
    "Seeks info",
    "Tests, in a way",
    "Poses",
    "Solicits",
    "Poses questions",
    "Calls (for)",
    "Bids",
    "Looks for help",
    "Shows curiosity",
    "Quizzes",
    "Grills or pumps",
    "Sets, as a price",
    "Shows curiosity",
    "Inquires (about)",
    "Requires",
    "Names a price",
    "Interrogates",
    "Is nosy",
    "Inquires",
    "Tries to get information",
    "Calls for",
    "Wants for a price",
    "Invites",
    "Implores",
    "Questions",
    "Inquires",
    "Poses",
    "Demands",
    "Questions",
    "Pumps for info",
    "Sets a price of",
    "Calls (for)",
    "Calls upon",
    "Questions",
    "Quizzes",
    "Inquires",
    "Is inquisitive",
    "Emulates Regis?",
    "Questions",
    "Entreats",
    "Calls upon",
    "Does something appealing?",
    "Solicits",
    "Says \"When?\"",
    "Questions",
    "Responds a la \"Jeopardy!\"",
    "Inquires",
    "Shows curiosity",
    "Entreats",
    "Questions",
    "Quizzes",
    "Tries to take (out)",
    "Grills",
    "Shows curiosity",
    "Puts it to",
    "Shows curiosity",
    "Invites",
    "Quizzes",
    "Quizzes",
    "Solicits",
    "Impetrates, with \"for\"",
    "Proposes",
    "Bids",
    "Wonders aloud"
  ],
  "clad": [
    "Attired",
    "Covered up",
    "Decked out",
    "Sporting, with \"in\"",
    "Not au naturel",
    "Attired",
    "Dressed",
    "Decked out",
    "Wearing, with \"in\"",
    "Dressed",
    "Dressed",
    "Wearing, with \"in\"",
    "Attired",
    "Decked out",
    "Wrapped up",
    "Wrapped up",
    "Not in the buff",
    "Decked out",
    "Decked out (in)",
    "Outfitted",
    "Suited",
    "Decked out",
    "Attired",
    "Dressed",
    "Dressed",
    "Not bare",
    "Decked out",
    "Dressed",
    "Dressed",
    "Garbed",
    "Decent, so to speak",
    "Armor-___",
    "Dressed",
    "Decked out",
    "Decent",
    "Covered",
    "Decent",
    "Dressed",
    "Dressed",
    "Robed",
    "Dressed",
    "Dressed",
    "Dressed",
    "Wrapped up",
    "Attired",
    "Decent",
    "Not bare",
    "Attired",
    "Unlike Godiva",
    "Dressed",
    "Covered",
    "Garbed",
    "Not nude",
    "Decked",
    "Dressed",
    "Bedecked",
    "Garbed",
    "Clothed",
    "Decked out",
    "Covered up",
    "Covered"
  ],
  "ryan": [
    "Hanks's \"Sleepless in Seattle\" co-star",
    "Actor Reynolds",
    "\"Star Trek\" actress Jeri",
    "House speaker before Pelosi",
    "\"Deadpool\" actor Reynolds",
    "House leader after Boehner",
    "Romney's 2012 running mate",
    "House speaker after Boehner",
    "Reynolds of \"Deadpool\"",
    "All-time career record-holder for strikeouts",
    "\"The O.C.\" protagonist",
    "Gosling of \"La La Land\"",
    "Seacrest of \"American Top 40\"",
    "Politico Paul",
    "Romney's 2012 running mate",
    "Name on 2012 campaign posters",
    "Boehner's successor",
    "2012 running mate",
    "Romney's 2012 running mate",
    "Role played by Baldwin, Ford, Affleck and Pine",
    "1998 Spielberg title role",
    "Meg of \"Sleepless in Seattle\"",
    "Baseball's strikeout king",
    "Actor Gosling",
    "Actress Meg",
    "Film private",
    "Cornelius who wrote \"A Bridge Too Far\"",
    "Hero of many Clancy novels",
    "Clancy hero",
    "Illinois governor before Blagojevich",
    "TV host Seacrest",
    "Meg of \"You've Got Mail\"",
    "Chicago's Dan ___ Expressway",
    "Hero of several Clancy novels",
    "Strikeout ace Nolan",
    "Actress Meg",
    "Hero of Tom Clancy novels",
    "O'Neal of \"Peyton Place\"",
    "Meg of \"In the Cut\"",
    "Tom Clancy hero",
    "Baseball's first $1 million/year player",
    "Film private",
    "Pitcher with 5,714 lifetime strikeouts",
    "Tom Clancy hero",
    "O'Neal of \"Love Story\"",
    "\"Saving Private ___\"",
    "Clancy hero Jack",
    "Famous fastballer",
    "Baseball's strikeout king",
    "\"Tumbleweeds\" cartoonist Tom",
    "Meg of \"Sleepless in Seattle\"",
    "Farrah's ex",
    "RIGHT-HANDED PITCHER",
    "All-Century Team pitcher",
    "\"Saving Private ___\"",
    "O'Neal of \"Love Story\"",
    "Clancy hero Jack",
    "\"A Bridge Too Far\" author",
    "Pat Nixon's maiden name",
    "No-hitter king",
    "First baseball player to make $1 million a year",
    "Tom Clancy hero",
    "Pitcher Nolan",
    "Fastballer known as \"The Express\"",
    "Pitcher Nolan",
    "Strikeout king",
    "Actor O'Neal",
    "Actor Robert",
    "Tom Clancy hero Jack",
    "\"Sleepless in Seattle\" co-star",
    "\"A Bridge Too Far\" author Cornelius",
    "Meg of \"Sleepless in Seattle\"",
    "Ford role in \"Clear and Present Danger\"",
    "Tatum's dad"
  ],
  "whir": [
    "Sound from a fan",
    "Noise from a fan",
    "Sound made by helicopter rotors",
    "What helicopter rotors do",
    "What rotors do",
    "Blender sound",
    "Fan sound",
    "Fan sound",
    "Drone of a drone, say",
    "Blender sound",
    "Fan sound",
    "Fan sound",
    "Result of a quick revolution",
    "Fan sound",
    "Blender sound",
    "Fan sound",
    "Buzz",
    "Fan's sound",
    "Blender sound",
    "Fan sound",
    "Fan's production"
  ],
  "yurt": [
    "Portable structure that's pitched",
    "Circular dwelling",
    "Glamping option",
    "Mobile dwelling on a steppe",
    "Glamping site, maybe",
    "Steppes dwelling",
    "Tentlike dwelling with a conical roof",
    "Mongolian tent"
  ],
  "soso": [
    "Uninspiring",
    "Nothing to write home about",
    "Nothing special",
    "C-worthy",
    "Just OK",
    "Meh",
    "Meh",
    "All right",
    "Nothing to write home about",
    "C-worthy",
    "C-worthy",
    "Mediocre",
    "Meh",
    "C-worthy",
    "Average",
    "Just fine",
    "Meh",
    "Mediocre",
    "Fair",
    "Middlin'",
    "Comme ci, comme ca",
    "Middling",
    "Neither good nor bad",
    "Average",
    "Middling",
    "Neither good nor bad",
    "Meh",
    "Just all right",
    "Mediocre",
    "Just O.K.",
    "O.K.",
    "Medium",
    "Middling",
    "Not that great",
    "Nothing special",
    "Neither good nor bad",
    "Meh",
    "Middlin'",
    "All right",
    "Meh",
    "Garnering a \"meh,\" say",
    "Middling",
    "Not bad",
    "Middling",
    "Meh",
    "Nothing to write home about",
    "Fair",
    "O.K.",
    "Average",
    "Average",
    "C-worthy",
    "Fair",
    "Mediocre",
    "Middling",
    "Not great, but not awful either",
    "Middling",
    "Eh",
    "Mediocre",
    "Fair",
    "Blah",
    "Blah",
    "Passable",
    "Comme ci, comme ca",
    "\"I've been better\"",
    "Neither good nor bad",
    "Unexceptional",
    "Neither good nor bad",
    "C-worthy",
    "Not too great",
    "Fair",
    "Garden-variety",
    "Just O.K.",
    "Passable",
    "Only O.K.",
    "Hardly thrilling",
    "\"Eh\"",
    "Mediocre",
    "Passable",
    "Not bad",
    "Nothing special",
    "Nothing special",
    "Mediocre",
    "Middling",
    "Mediocre",
    "Not great",
    "Not good, but not bad",
    "Middling",
    "Average",
    "Fair",
    "Unexciting",
    "Comme ci, comme ca",
    "C-worthy?",
    "Unremarkable",
    "Passably",
    "Fair to middling",
    "Comme ci, comme ca",
    "Deserving a C",
    "Mediocre",
    "\"Comme ci, comme ca\"",
    "Middlin'",
    "Fair-to-middling",
    "Fair",
    "Mediocre",
    "Fair",
    "Middling",
    "\"Could be better\"",
    "Two-star",
    "Mediocre",
    "Fair",
    "Fair",
    "Average",
    "Middling",
    "Comme ci, comme ca",
    "Fair",
    "Mediocre",
    "Middling",
    "Just okay",
    "Middling",
    "Fair to middlin'",
    "Comme ci, comme ca",
    "Fair",
    "Not too badly",
    "Deserving a C",
    "Mediocre",
    "Fair to middlin'"
  ],
  "cafe": [
    "___ au lait",
    "Spot of espresso?",
    "Bite site",
    "Setting for many Manet paintings of the bourgeoisie",
    "\"Casablanca\" setting",
    "___ noir",
    "Establishment with a brunch rush, maybe",
    "Eatery with sidewalk tables, often",
    "Monk's on \"Seinfeld,\" e.g.",
    "Internet ___",
    "Brunch spot",
    "Bistro",
    "___ noir",
    "Lunch spot",
    "Eatery",
    "It may have outdoor seating",
    "Bistro",
    "Establishment that may display a chalkboard",
    "Bistro",
    "Many a Wi-Fi hotspot",
    "Pousse-___ (multicolored drink)",
    "Of kind society",
    "Lunch site",
    "Place to order a sandwich or espresso",
    "Site for a bite",
    "Casual eatery",
    "Place to have a brioche",
    "Rick's ___ Americain, \"Casablanca\" setting",
    "Tea for two place",
    "Starbucks, for one",
    "Place for a brioche",
    "Bite site",
    "Place for table umbrellas",
    "___ noir",
    "Parisian hangout",
    "Lunch site",
    "Rick's place",
    "Eatery",
    "It may be alfresco",
    "See 9-Across",
    "Where to order un the",
    "Soup and sandwich spot",
    "___ society",
    "See 9-Down",
    "Joe, in France",
    "Outdoor eatery",
    "Cousin of a tearoom",
    "Eatery",
    "Kind of society",
    "Site for a bite",
    "Bistro",
    "___ noir",
    "Theme of this puzzle",
    "Eatery",
    "___au lait",
    "Eatery"
  ],
  "pled": [
    "Begged",
    "Spoke to a judge, say",
    "Offered, as a defense",
    "Responded in court",
    "Stated one's case",
    "Asked earnestly",
    "Made, as one's case",
    "Stated one's case",
    "Responded in court",
    "Made an appeal",
    "Begged",
    "Made an appeal",
    "Begged",
    "Petitioned",
    "Made a case",
    "Said \"Guilty\" or \"Not guilty,\" say",
    "Stated one's case",
    "Didn't just ask",
    "Said \"Not guilty!,\" e.g.",
    "Entreated",
    "Made a case",
    "Begged",
    "Argued (for)",
    "Appealed earnestly",
    "Argued",
    "Responded in court",
    "Begged",
    "Appealed",
    "Was appealing",
    "Implored",
    "Said \"no contest,\" e.g.",
    "Made a case (for)",
    "Argued",
    "Said \"not guilty,\" e.g.",
    "Entreated",
    "Made, as a case",
    "Implored",
    "Responded in court",
    "Argued, as a claim",
    "Made, as a case",
    "Argued"
  ],
  "reds": [
    "Dark wines",
    "Section of a wine list",
    "Cincinnati squad",
    "Malbec and syrah, e.g.",
    "N.L. Central team",
    "Only M.L.B. team that Johnny Bench played for (1967-83)",
    "Wine lover's favorite team?",
    "Cincinnati sluggers",
    "Beaujolais and other wines",
    "M&M's that were discontinued from 1976 to 1987 over fears about their dye",
    "Ohio nine",
    "1970s N.L. powerhouse",
    "N.L. Central squad",
    "Commies",
    "Section of a Crayola box",
    "Great American Ball Park team",
    "Wines like Beaujolais and Chianti",
    "Cincinnati team",
    "Cincinnati squad",
    "Cold War side",
    "Wines said to go well with steak",
    "\"Stop\" lights",
    "Johnny Bench's team",
    "Some wines",
    "Most lipstick options",
    "Jazzberry Jam and Razzmatazz in a Crayola box",
    "Cabs and such",
    "Chianti and Beaujolais",
    "Six crayons in a Crayola 64 box",
    "Cab and others",
    "Wine list heading",
    "Midwest squad",
    "Their caps have a stylized \"C\"",
    "Sommeliers' suggestions",
    "Wines said to go well with meat",
    "Boogeymen of 1950s politics",
    "Wine list section",
    "Cincinnati team",
    "Some M&M's",
    "Cherry and ruby",
    "Great American Ball Park team",
    "Some M&M's",
    "Medoc and Grenache",
    "Cincinnati squad",
    "Cincinnati baseball team",
    "Bordeaux, e.g.",
    "N.L. Central team",
    "Some wines",
    "'50s scare",
    "\"Chariots of Fire\" beat it for Best Picture",
    "Ruby and scarlet",
    "Chianti and Beaujolais",
    "Film that lost the Best Picture Oscar to \"Chariots of Fire\"",
    "Cabernets, e.g.",
    "Cincinnati team",
    "Oenological category",
    "Range in lipsticks",
    "M&M's that were removed from 1976 to 1987 out of a health concern for a coloring dye",
    "Some wines",
    "Pete Rose's team",
    "Wines like Merlot",
    "Great American Ball Park team",
    "Wines to serve with beef",
    "Wine list column",
    "Cold war side",
    "Cincinnati team",
    "Roulette bets",
    "Some roulette bets",
    "Cold war enemy",
    "Some M & M's",
    "Cincinnati team",
    "They beat the 39-Across in the 1975 8-Down",
    "Cincinnati team",
    "Commies",
    "Wines that aren't whites",
    "Commies",
    "Winery choices",
    "Wine list subheading",
    "Cincinnati team",
    "Sommeliers' offerings",
    "McCarthy's quarry",
    "Many wines",
    "1990 World Series champs",
    "Cinergy Field players",
    "Some M & M's",
    "Cold war foe",
    "Dark wines",
    "Cincinnati nine",
    "1970's N.L. powerhouse",
    "Cold war group",
    "Cinergy Field athletes",
    "Cherry and cranberry",
    "Medoc and Chianti",
    "Cincinnati team",
    "John Birchers' foes",
    "Cold war foe",
    "1975-76 World Series champs",
    "Wine selections",
    "McCarthy's quarry",
    "Lipstick shades",
    "Many wines",
    "Cold war foes",
    "Some wines",
    "Johnny Bench's team",
    "Cranberry and cherry",
    "Stoplight stop lights",
    "Many wines",
    "Merlot, Medoc, etc.",
    "Marge Schott's team",
    "Scarlet and crimson",
    "Cincinnati nine",
    "Cincinnati nine",
    "Zinfandels",
    "Cinergy Field team",
    "1990 World Series champs",
    "Medoc, merlot, etc.",
    "1917 revolutionaries",
    "Bench's benchmates",
    "1990 World Series champs",
    "Riverfront Stadium players",
    "Objects of a 1950's scare",
    "Ruby and crimson",
    "Cincinnati team",
    "Fire-engine and Indian, e.g.",
    "Revolutionaries",
    "1981 Beatty film",
    "Marge Schott's boys",
    "Crimson and carmine",
    "1981 Beatty-Keaton epic",
    "Garnets",
    "Stop lights",
    "Communists",
    "Cold-war forces",
    "Bolsheviks",
    "Some M&M's"
  ],
  "oreo": [
    "Treat with a 71%-to-29% cookie-to-cream ratio",
    "Ingredient in a McDonald's McFlurry",
    "Treat thought to be stamped with symbols of the Knights Templar",
    "Black-and-white item in a sleeve",
    "Cookie that has been deemed kosher since 1997",
    "Crumbly ice cream topping",
    "Cookie in the shape of its first and last letters",
    "Frozen yogurt flavor",
    "___ O's (breakfast cereal)",
    "Certain pie crust flavor",
    "Dunkable cookie",
    "Cookie with creme",
    "Cookie that may be pulled apart or dunked",
    "Golden ___ (vanilla-flavored snack)",
    "Cookie since 1912",
    "Popular name for a black-and-white pet",
    "Black pie crust component",
    "It may get a good licking",
    "Treat with the identical color scheme as this puzzle",
    "Cookie that's often pulled apart",
    "Dirt pie ingredient",
    "Stackable cookie",
    "Product with a milk splash on its packaging",
    "Dunkable treat",
    "Froyo mix-in",
    "Most common commercial name in New York Times crosswords",
    "Cookie that's 29% cream",
    "Snack item once advertised as \"WONDERfILLED\"",
    "___ O's (breakfast cereal)",
    "Black-and-white Nabisco cookie",
    "McFlurry flavor",
    "___ Thins (cookies)",
    "Black-and-white item you can consume whole",
    "Cookie with creme in the middle",
    "Cookie with its name on it",
    "Frozen yogurt mix-in",
    "Snack that's often pulled apart",
    "Treat embossed with its name",
    "Dirt pie ingredient",
    "Treat with a Thins variety",
    "Cookie with a creme filling",
    "Sister brand of Nilla wafers",
    "Cookie since 1912",
    "Nabisco snack since 1912",
    "___ Blizzard (Dairy Queen offering)",
    "Snack you might bite or lick",
    "Brand name after \"Oh! Oh!,\" in old ads",
    "Its filling contained lard until 1997",
    "Crumbled froyo topping",
    "Certain fro-yo add-in",
    "\"Creme sandwich\" introduced over a century ago",
    "It's 29% cream",
    "___ O's (chocolaty cereal brand)",
    "Nabisco's answer to Hydrox",
    "Nabisco brand since 1912",
    "Jell-O pudding flavor",
    "Two-tone treat",
    "Cookie that may be dipped in milk",
    "Cookie that's often pulled apart",
    "Cookie that started as a Hydrox knockoff",
    "Ice cream flavor",
    "Treat since 1912",
    "Cookie with a Thins variety",
    "Ice cream flavor",
    "\"Twist, Lick, Dunk\" cookie",
    "Food brand since 1912",
    "Snack sometimes eaten from the inside out",
    "Black-and-white cookie",
    "Treat with a \"Golden\" variety",
    "Cookie in cookies-and-cream ice cream",
    "Black-and-white, in sneaker lingo",
    "Black-and-white treat",
    "Dessert item that was clued as \"Mountain: Comb. form\" in old crosswords",
    "Brand touted as \"Milk's favorite cookie\"",
    "Black-and-white cookie",
    "Chocolaty goodie",
    "Cookie that outcompeted Hydrox",
    "Stackable dessert item",
    "Product first released by the National Biscuit Company in 1912",
    "Dirt pie ingredient",
    "Cookie with a chocolaty outside",
    "Stackable snack",
    "Dunkable treat",
    "Non-fruit smoothie flavor",
    "Cookie with white filling",
    "Cookie in cookies-and-cream ice cream",
    "Bicolor snack",
    "Layered snack",
    "Cookie involved in a licking race",
    "___ cheesecake (black-and-white dessert)",
    "Cookie sometimes dunked in milk",
    "Traditional ingredient in cookies and cream ice cream",
    "Cookie ingredient in dirt cake",
    "Chocolaty nibble",
    "Snack since 1912",
    "Cookie that's kosher",
    "Brand with a \"Wonderfilled\" ad campaign",
    "Chips Ahoy! alternative",
    "Dunking cookie",
    "McFlurry flavor",
    "Twistable treat",
    "It may get dipped in milk",
    "Nabisco offering",
    "Snack item that's round on both ends?",
    "___ Biscuit (1912 debut)",
    "Crumbly snack",
    "Small sandwich",
    "Cookie that can be readily stacked",
    "Sandwich often given a twist",
    "___ Blizzard (Dairy Queen offering)",
    "Nabisco cookie",
    "Cookie celebrating its centennial in 2012",
    "Snack with a Double Stuf variety",
    "Crumbled ingredient in \"dirt pudding\"",
    "Ice cream mix-in",
    "___ balls (chocolaty snacks)",
    "Double-disc cookie",
    "Modern ice cream flavor",
    "Cookie with creme in the middle",
    "Item in a \"lick race\"",
    "Twistable treat",
    "McFlurry flavor",
    "Kind of pie or cake",
    "Cookie first baked in Manhattan's Chelsea district",
    "Modern ice cream flavor",
    "Layered cookie",
    "Cookie with creme",
    "Creme-filled cookie",
    "Treat in a blue wrapper",
    "Snack with a floral design",
    "___ Cakesters (Nabisco offering)",
    "Mousse pie ingredient, maybe",
    "Uh-Oh! ___ (Nabisco product)",
    "It's 71% cookie, 29% creme",
    "It has 12 flowers on each side",
    "Treat with a \"Golden\" variety",
    "Nabisco cookie",
    "Nabisco product",
    "Kind of mud pie",
    "McFlurry flavor",
    "\"Milk's favorite cookie,\" in commercials",
    "Three-layer snack",
    "McFlurry flavor",
    "Ice cream mix-in",
    "Lunchbox dessert item",
    "Cookie sold in a blue package",
    "Nabisco cookie",
    "Round sandwich",
    "Nabisco cookie",
    "Cookie with a filling",
    "After-lunch snack",
    "It may get a licking after lunch",
    "After-lunch bite",
    "Modern ice cream flavor",
    "Cookie with a floral design on it",
    "Double Stuf cookie",
    "Cookie with its name on it",
    "Treat that's sometimes dunked",
    "Stacking contest cookie",
    "Nabisco cookie",
    "Lunchbox goody",
    "Cookie with a creme center",
    "Cookie introduced in 1912",
    "Creme-filled cookie",
    "Twistable cookie",
    "Nabisco cookie",
    "Twist-apart treat",
    "Creme-filled snack",
    "Modern ice cream flavor",
    "Creme-filled snack",
    "Hydrox rival, once",
    "Snack with a cream center",
    "Cookie since 1912",
    "Nabisco best-seller",
    "Nabisco brand",
    "Double Stuf treat",
    "Splittable cookie",
    "Chocolaty treat",
    "Often-dunked item",
    "\"Got Milk?\" ad partner",
    "Ingredient in Cookies 'n Cream ice cream",
    "Popular cookie",
    "Some twist it before eating",
    "Snack item since 1912",
    "Popular sandwich cookie",
    "Creme cookie",
    "Modern ice cream flavor",
    "Black-and-white cookie",
    "Snack in a stack",
    "Twistable treat",
    "Snack with a lickable center",
    "Modern ice cream flavor",
    "Hydrox alternative",
    "Dunkable cookie",
    "Wafers-and-creme treat",
    "Name on a wafer",
    "Cookie with creme inside",
    "Food item whose name appears on its side",
    "Black-and-white treat",
    "Nabisco cookie",
    "Snack sold in a stack",
    "Double Stuf, for one",
    "It may be dunked",
    "Black-and-white cookie",
    "Modern ice cream flavor",
    "Cookie that has its name on it",
    "Certain cookie",
    "Circular snack",
    "Hydrox rival",
    "Filled treat",
    "Nabisco cookie",
    "Nabisco cookie",
    "Snack choice",
    "Snack favorite",
    "Ice cream flavor",
    "It has a 1 3/4\" diameter",
    "Modern ice cream flavor",
    "Snack since 1912",
    "Nabisco snack",
    "Creme cookie",
    "Nabisco cookie",
    "Nabisco treat",
    "Something often twisted apart",
    "___ O's (Post cereal)",
    "Snack since 1912",
    "Creme-filled cookie",
    "Creme-filled cookie",
    "Nabisco cookie",
    "Round of snacking?",
    "Stackable cookie",
    "Modern ice cream flavor",
    "Snack brand that sponsored Dale Earnhardt",
    "Popular cookie",
    "DoubleStuf treat",
    "Cookie with a creamy middle",
    "Nabisco cookie",
    "It may get a licking after dinner",
    "Three-ply snack",
    "Nabisco cookie",
    "Often-twisted treat",
    "Modern ice cream flavor",
    "Snack item since 1912",
    "Nabisco cookie",
    "Snack choice",
    "It may be double-stuffed",
    "___ O's (Post cereal)",
    "Nabisco item",
    "Snack in a stack",
    "It's \"sandwiched\" in 17-, 23-, 35-, 45- and 56-Across",
    "Three-layer treat",
    "Three-layer snack",
    "Cookie with a crunch",
    "Sometimes-twisted snack",
    "Creme-filled snack",
    "It has a creamy middle",
    "Black-and-white cookie",
    "Nabisco product",
    "Nabisco cookie",
    "Black-and-white cookie",
    "Modern ice cream flavor",
    "It may get a licking after dinner",
    "Hydrox look-alike",
    "Classic cookie",
    "Creme-filled snack",
    "Nabisco cookie",
    "It's often twisted apart",
    "Nabisco brand",
    "Sandwich cookie",
    "Chocolate treat",
    "Dessert item since 1912",
    "Round sandwich",
    "Three-ply snack",
    "Snack in a stack",
    "Nabisco favorite",
    "Mountain: Prefix",
    "Lunchbox treat",
    "Popular cookie",
    "Nabisco cookie",
    "It's dipped in milk",
    "Modern ice cream flavor",
    "Chocolate-and-cream cookie",
    "It may get a licking after dinner",
    "It comes in black and white",
    "After-lunch sandwich",
    "Cookie in stacking contests",
    "Hydrox competitor",
    "Cookie since 1912",
    "Treat with milk",
    "Hydrox rival",
    "Nabisco cookie",
    "After-lunch sandwich",
    "Sweet sandwich",
    "Nabisco favorite",
    "It may get a good licking",
    "Nabisco treat",
    "Kind of \"snackwich\"",
    "Snack that's bitten or licked",
    "Black-and-white treat",
    "Cookie favorite",
    "\"Biscuit\" introduced in 1912",
    "Two-toned treat",
    "Lunchbox treat",
    "Hydrox alternative",
    "Nabisco sweet",
    "Hydrox rival",
    "Chocolate snack",
    "Cookie with a creme center",
    "Black-and-white snack",
    "Snack since 1912",
    "It's a cookie",
    "Lunch box treat",
    "Sandwich cookie",
    "Nabisco snack",
    "Treat with milk",
    "Hydrox rival",
    "Snack item since 1912",
    "Hydrox rival",
    "Nabisco snack",
    "Black-and-white cookie",
    "Nabisco cookie",
    "Nabisco cookie",
    "Double Stuf cookie",
    "Sandwich cookie",
    "Popular snack",
    "It often gets a lick",
    "Nabisco brand",
    "Item dunked in milk",
    "Fig Newton rival",
    "Popular cookie",
    "Nabisco treat",
    "Triple-layer treat",
    "Nabisco product",
    "Cream-filled sandwich"
  ],
  "sean": [
    "Connery who played 007 seven times",
    "Parker who served as the first president of Facebook",
    "___ Combs a.k.a. Diddy",
    "One of the Lennons",
    "Penn name",
    "Bean on the silver screen",
    "Parker who was the first president of Facebook",
    "Irish \"John\"",
    "Jamaican rapper ___ Paul",
    "Son of John and Yoko",
    "Trump spokesman Spicer",
    "White House spokesman Spicer",
    "Actor Penn of \"Milk\"",
    "Actor Bean, whose first name looks like it rhymes with his last, but doesn't",
    "Jay ___, British singer with the 2009 #1 hit \"Down\"",
    "Bean seen on-screen",
    "Ian : Scotland :: ___ : Ireland",
    "Actor Bean of \"Troy\"",
    "___ Parker, first president of Facebook",
    "___ Parker, founding president of Facebook",
    "With 103-Down, \"Hurlyburly\" star",
    "Playwright O'Casey",
    "One of the Lennons",
    "Reggae artist ___ Paul",
    "Son of John and Yoko",
    "Parker who was one of the original faces at Facebook",
    "Roger's predecessor as 007",
    "Rapper Combs a k a Diddy",
    "Reggae/dancehall artist ___ Paul",
    "Actor Penn with two 23-Across",
    "A Lennon",
    "With 62-Across, subject of \"Beautiful Boy (Darling Boy)\"",
    "Grammy-winning reggae artist ___ Paul",
    "P. Diddy's first name",
    "Name equivalent to Hans or Ivan",
    "Actor Penn",
    "\"Blade Runner\" actress Young",
    "Actor Penn",
    "One of the Lennons",
    "Penn name",
    "Astin of \"Lord of the Rings\"",
    "Playwright O'Casey",
    "Actor Connery",
    "John Astin's actor son",
    "Actor Penn",
    "Astin of \"Lord of the Rings\"",
    "Actor Connery",
    "Penn of \"Mystic River\"",
    "One of the Lennons",
    "Bean of \"The Lord of the Rings\"",
    "Singer Lennon",
    "Puffy Combs's first name",
    "Rapper Combs",
    "Young Lennon",
    "Penn name",
    "Connery of 007 fame",
    "Actress Young",
    "Penn or Connery",
    "Penn name",
    "O'Casey or O'Faolain",
    "Playwright O'Casey",
    "Writer O'Faolain",
    "Penn name",
    "Hans, in Ireland",
    "O'Casey or Penn",
    "Mr. Connery",
    "Hollywood's Penn",
    "Penn name",
    "Actress Young",
    "Playwright O'Casey"
  ],
  "elie": [
    "Nobelist Wiesel",
    "Fashion designer ___ Saab",
    "Nobel laureate Wiesel",
    "Author Wiesel",
    "\"Night\" memoirist Wiesel",
    "Writer Wiesel",
    "Peace Nobelist Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Wiesel with a Nobel",
    "Nobelist Wiesel",
    "\"Night\" memoirist Wiesel",
    "Peace Nobelist Wiesel",
    "Writer Wiesel",
    "Fashion designer ___ Saab",
    "Peace Nobelist Wiesel",
    "Nobelist Wiesel",
    "Peace activist Wiesel",
    "Nobelist Wiesel",
    "Nobel laureate Wiesel",
    "Peace Nobelist Wiesel",
    "Nobel laureate Wiesel",
    "\"Night\" author Wiesel",
    "Late author Wiesel",
    "Nobel winner Wiesel",
    "\"Night\" memoirist Wiesel",
    "Author Wiesel",
    "1986 Nobel Peace Prize winner Wiesel",
    "Nobel laureate Wiesel",
    "Fashion designer Saab",
    "Nobelist Wiesel",
    "Writer Wiesel",
    "Composer Siegmeister",
    "Saab or Tahari of fashion",
    "Writer Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "\"Night\" author Wiesel",
    "\"Night\" author Wiesel",
    "Fashion designer Saab",
    "Fashion designer Tahari",
    "Nobelist Wiesel",
    "Nobel laureate Wiesel",
    "Author Wiesel",
    "\"Night\" author Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Designer Saab",
    "\"Night\" author Wiesel",
    "Designer Saab",
    "\"Night\" novelist Wiesel",
    "French mathematician Cartan",
    "Designer Saab",
    "Fashion's Tahari",
    "First name at the 1986 Nobel Prize ceremony",
    "Peace Nobelist Wiesel",
    "Writer Wiesel",
    "Fashion designer Saab",
    "Nobelist Wiesel",
    "Wiesel who wrote \"Night\"",
    "N.B.A.'er Mario ___",
    "Nobelist Wiesel",
    "Author Wiesel",
    "Nobelist Wiesel",
    "Composer Siegmeister",
    "Writer Wiesel",
    "Nobelist Wiesel",
    "Writer Wiesel",
    "Author Wiesel",
    "Mario ___ of the N.B.A.",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Peace Nobelist Ducommun",
    "Composer Siegmeister",
    "Onetime newsman ___ Abel",
    "Mario ___ of the N.B.A.",
    "Medicine Nobelist Metchnikoff",
    "Author Wiesel",
    "Nobelist Wiesel",
    "Sculptor Nadelman",
    "Writer Wiesel",
    "Veteran journalist ___ Abel",
    "Nobelist Wiesel",
    "Author Wiesel",
    "Nobelist Metchnikoff",
    "\"Night\" author Wiesel",
    "Author Wiesel",
    "Writer Wiesel",
    "Peace Nobelist Ducommun",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Author Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Author Wiesel",
    "Swiss Nobelist Ducommun",
    "Biologist Metchnikoff",
    "Nobelist Wiesel",
    "Mario of the N.B.A.",
    "Composer Siegmeister",
    "Peace Nobelist Ducommun",
    "Artist Nadelman",
    "Author Wiesel",
    "Medicine Nobelist Metchnikoff",
    "Writer Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Peace Nobelist Wiesel",
    "Nobelist Wiesel",
    "Composer Siegmeister",
    "Old news commentator ___ Abel",
    "Mario of the N.B.A.",
    "Writer Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Writer Wiesel",
    "\"Night\" author Wiesel",
    "Writer Wiesel",
    "Author Wiesel",
    "Writer Wiesel",
    "\"Night\" author Wiesel",
    "Author Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Writer Wiesel",
    "Nobelist Wiesel",
    "Writer Wiesel",
    "Essayist Wiesel",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Author Wiesel",
    "Nobelist Wiesel",
    "___ Ducommun, 1902 Peace Nobelist",
    "Nobelist Wiesel",
    "Nobelist Wiesel",
    "Writer Wiesel",
    "Writer Wiesel"
  ],
  "area": [
    "Vicinity",
    "Calculus calculation",
    "Word with rest or play",
    "Territory",
    "What an integral can be used to calculate",
    "Length x width, for a rectangle",
    "Floor plan info",
    "Specialty",
    "Gray ___",
    "Measurement in plane geometry",
    "Apartment listing info",
    "Spot",
    "Neighborhood",
    "Specialty",
    "Zone",
    "Concentration",
    "Sphere",
    "Math measurement",
    "Specialty",
    "Surveyor's calculation",
    "Geometry calculation",
    "See 28-Down",
    "Floor plan spec",
    "Specialty",
    "4,700 square feet, for a basketball court",
    "Researcher's specialty",
    "___ 51",
    "Surface figure",
    "Geometric calculation",
    "Specialty",
    "Zone",
    "Carpet measurement",
    "Calculation that may involve pi",
    "Field",
    "Lawn measure",
    "Integral's calculation, in calculus",
    "843 acres, for New York's Central Park",
    "The \"A\" of BART",
    "Length x width, for a rectangle",
    "Word after Bay or gray",
    "___ code",
    "Domain",
    "Two-dimensional measure",
    "The \"A\" in BART",
    "Bay ___",
    "The \"A\" of 52-Down",
    "Vicinity",
    "Metropolitan ___",
    "Rest ___",
    "Real estate measurement",
    "Surveyor's finding",
    "What a Mercator projection map notably distorts",
    "The \"A\" in BART",
    "Length x width, for a rectangle",
    "Calculus calculation",
    "Neighborhood",
    "Geometric figure",
    "Carpet layer's measurement",
    "Length x width, for a rectangle",
    "What a line doesn't have",
    "Region",
    "Zone",
    "Environment",
    "Circle calculation",
    "Zone",
    "Bailiwick",
    "Locale",
    "What a salesperson may be assigned",
    "Department",
    "One-half base x height, for a triangle",
    "Square footage",
    "Geometry calculation",
    "Vicinity",
    "Plane calculation",
    "See 60-Down",
    "Bay ___ (San Francisco, Oakland, etc.)",
    "Bailiwick",
    "Calculus calculation",
    "Length x width, for a rectangle",
    "Neck of the woods",
    "Roughly 3.8 million square miles, for the United States",
    "Zone",
    "Field",
    "Length x width, for a rectangle",
    "Ignore",
    "Domain",
    "Flooring calculation",
    "Bay ___",
    "Common calculus calculation",
    "Vicinity",
    "Something a line lacks",
    "Geometric calculation",
    "Bailiwick",
    "Field",
    "Neck of the woods",
    "Locality",
    "Bay or gray follower",
    "What pi may be used to find",
    "Space",
    "Department",
    "Neighborhood",
    "Scope",
    "Science or humanities, in college requirements",
    "Quarter",
    "Vicinity",
    "Locale",
    "The \"A\" of San Francisco's BART",
    "Integration calculation",
    "Precinct",
    "___ rug",
    "Infield, for one",
    "Field of expertise",
    "Neck of the woods",
    "Domain",
    "Region",
    "The \"A\" of BART",
    "Word with gray or Bay",
    "Flat figure",
    "Part of town",
    "Domain",
    "Bay ___",
    "Field of expertise",
    "Play ___",
    "Length x width, for a rectangle",
    "Vicinity",
    "Discipline",
    "Domain",
    "Carpet layer's calculation",
    "Bailiwick",
    "Plot-listing datum",
    "Region",
    "Find the ___ (geometry test instruction)",
    "Specialty",
    "An integral can compute it",
    "Field",
    "Geometric calculation",
    "Square footage",
    "Gray ___",
    "Department",
    "Vicinity",
    "Kind of rug or code",
    "Kind of rug",
    "Metro ___",
    "Zone",
    "Vicinity",
    "Datum in a house listing",
    "Ground",
    "Vicinity",
    "Discipline",
    "Carpet layer's calculation",
    "Discipline",
    "See 17-Across",
    "Department",
    "Region",
    "___ 51 (conspiracy theory subject)",
    "Forte",
    "Zone",
    "Calculus calculation",
    "Rest ___ (roadside stop)",
    "Region",
    "Region",
    "Locale",
    "___ 51 (ufologist's interest)",
    "109 acres, for Vatican City",
    "One-half base x height, for a triangle",
    "Info in an apartment ad",
    "Locale",
    "Environs",
    "Region",
    "1/2 base x height, for a triangle",
    "\"Clear the ___!\"",
    "Word after rest or gray",
    "Geographical datum",
    "Real estate figure",
    "Neck of the woods",
    "Two-dimensional measure",
    "Sphere",
    "Surveyor's calculation",
    "Domain",
    "Two-dimensional extent",
    "Base times height, for a rectangle",
    "Staging ___",
    "Region",
    "Field of study",
    "Vicinity",
    "Word after rest or residential",
    "Wall-to-wall measure",
    "Locale",
    "Environs",
    "Gray ___",
    "Pi r squared, for a circle",
    "Square footage",
    "General location",
    "Turf",
    "Word with gray or rest",
    "Region",
    "Side x side, for a 4-Down",
    "Field of expertise",
    "Vicinity",
    "Pi r squared, for a circle",
    "Figure in geometry",
    "Confines",
    "Department",
    "Word after gray or Bay",
    "Word after Bay or gray",
    "Geographical info",
    "Surface figure",
    "Neck of the woods",
    "Discipline",
    "Info in a real estate ad",
    "Realm",
    "Vicinity",
    "Length x width, for a rectangle",
    "Surveyor's measure",
    "Geographical datum",
    "What a surveyor surveys",
    "Stat for a state",
    "Quarter",
    "Geographical statistic",
    "It may be gray",
    "Measurement with square units",
    "Environs",
    "Sector",
    "Quarter",
    "See 58-Down",
    "See 8-Down",
    "Side by side?",
    "Stretch",
    "Pi r squared, for a circle",
    "Metropolitan ___",
    "Neighborhood",
    "Geometry calculation",
    "Precinct",
    "Zone",
    "Zone",
    "What pi may be used to find",
    "Metropolitan ___",
    "Real-estate ad statistic",
    "General vicinity",
    "Space",
    "Realtor's calculation",
    "Neighborhood",
    "Region",
    "Length x width, for a rectangle",
    "Zone",
    "Section",
    "Geometric figure",
    "Vicinity",
    "Specialty",
    "Service ___",
    "Painter's calculation",
    "Realm",
    "Whereabouts",
    "Bailiwick",
    "Field of study",
    "Region",
    "Bailiwick",
    "District",
    "Place",
    "Flooring measure",
    "Space",
    "Tiler's measurement",
    "Stretch",
    "Geometrical finding",
    "Territory",
    "Neighborhood",
    "Department",
    "Vicinity",
    "State stat",
    "Locality",
    "Locale",
    "Square footage",
    "Pi r-squared for a circle",
    "Word after rest or restricted",
    "With 9-Across, 40-Across seen in parentheses",
    "Geometry calculation",
    "Turf",
    "Field",
    "Blueprint datum",
    "Surface figure",
    "Geometry calculation",
    "It may be gray",
    "Zone",
    "Territory",
    "Square footage",
    "Stretch",
    "Square footage",
    "Plane measure",
    "Neck of the woods",
    "Side by side?",
    "Length x width, for a rectangle",
    "Carpet layer's calculation",
    "Gazetteer datum",
    "Gazetteer statistic",
    "___ code (long-distance need)",
    "Neck of the woods",
    "Side squared, for a square",
    "Atlas stat",
    "Plane measure",
    "Country statistic",
    "Side squared, for a square",
    "Vicinity",
    "Compass",
    "Neighborhood",
    "Vicinity",
    "Turf",
    "Gazetteer figure",
    "It may be restricted",
    "Province",
    "Part or parcel",
    "Purlieu",
    "Bailiwick",
    "Neighborhood",
    "Zone",
    "'Hood",
    "Bailiwick",
    "Vicinity",
    "Field",
    "Gray ___",
    "Kind of rug",
    "Region",
    "Part of WATS",
    "Bay ___",
    "Gray ___",
    "Turf",
    "Zone",
    "Polygon measurement",
    "Neighborhood",
    "Vicinity",
    "Neck of the woods",
    "Atlas stat",
    "Length x width, for a rectangle",
    "Vicinage",
    "Locality",
    "Word with Bay or gray",
    "Geometric calculation",
    "Realm",
    "Territory",
    "Side squared, for a square",
    "Suburb, for one",
    "Specialty",
    "Neighborhood",
    "Neighborhood",
    "Carpet buyer's calculation",
    "Square measure",
    "Neighborhood",
    "Square mileage",
    "Math computation",
    "Field of expertise",
    "Gazetteer datum",
    "Nonsmoking ___",
    "Stretch",
    "Vicinity",
    "Bailiwick",
    "Province",
    "Sphere",
    "Extent",
    "Expanse",
    "Part of WATS",
    "Field",
    "Territory",
    "Neighborhood",
    "The \"A\" of 38-Across",
    "Zone",
    "Restricted ___",
    "Length x width, for a rectangle",
    "Specialty",
    "Surveyor's subject",
    "Acreage",
    "Terrain",
    "Scope",
    "Tract",
    "Quarter",
    "Vicinity",
    "Gazetteer datum",
    "Scope",
    "Department",
    "Neighborhood",
    "Gazetteer datum",
    "Circle",
    "Neck of the woods",
    "Vicinity",
    "Carpet layer's calculation",
    "Sphere",
    "District",
    "Math figure",
    "Side by side, maybe?",
    "Territory",
    "Zone",
    "Locale",
    "Specialty",
    "Region",
    "Sector",
    "Purlieu",
    "Zoning board calculation",
    "Zone",
    "Code preceder",
    "Department",
    "Part",
    "Turf",
    "It's measured in square units",
    "Zone",
    "Plane measure",
    "Side by side?",
    "Neighborhood",
    "Carpeting calculation",
    "Painter's estimation",
    "Domain",
    "Carpet layer's calculation",
    "Domain",
    "Sphere",
    "Neck of the woods",
    "Neck of the woods",
    "Carpet store calculation",
    "Kind of rug",
    "Geometry calculation",
    "District",
    "Specialty",
    "Sphere of study",
    "Plane measure",
    "Region",
    "Scope",
    "Zone",
    "Vicinity",
    "Part",
    "Field of study",
    "Vicinity",
    "Environs",
    "Domain",
    "Bailiwick",
    "Suburbia, e.g.",
    "Zone",
    "Vicinity",
    "Department",
    "Calculus calculation",
    "See 114-Down",
    "District",
    "Square footage",
    "Part of WATS",
    "Department",
    "Field",
    "High-pressure ___",
    "Geometrician's figuring",
    "'Hood",
    "Specialty, so to speak",
    "Acreage",
    "Scope",
    "Lot measurement",
    "District",
    "Two-dimensional extent",
    "Field",
    "Tract",
    "Neighborhood",
    "Scope",
    "Part",
    "Neighborhood",
    "Side squared, for a square",
    "Compass",
    "Neighborhood",
    "Vicinity",
    "Neighborhood",
    "Field",
    "Two-dimensional extent",
    "Gazetteer info",
    "Province",
    "Region",
    "Belt",
    "Environs",
    "Zone",
    "Neighborhood",
    "Rug figure",
    "Geographical datum",
    "Region",
    "Scope",
    "Carpet cutter's calculation",
    "Field",
    "Territory",
    "Region",
    "Grounds",
    "Zone",
    "Vicinity",
    "Locale",
    "Tract",
    "Field",
    "Geometry subject",
    "Zone",
    "Region",
    "Parts",
    "Side by side figure?",
    "Quarter",
    "Neighborhood",
    "Geometry calculation",
    "Kind of rug",
    "Section",
    "Vicinity",
    "Department",
    "Department",
    "Carpet cutter's calculation",
    "Extent",
    "Section",
    "Code start",
    "Zone",
    "Domain",
    "Neighborhood",
    "Precinct",
    "Carpet layer's calculation",
    "Space",
    "Field of interest",
    "Scope",
    "Geometry topic",
    "Scope",
    "Acreage",
    "Field",
    "Neighborhood",
    "Length x width, for a rectangle",
    "___ rug",
    "Neighborhood",
    "Province",
    "Vicinage",
    "Range of expertise",
    "Zone",
    "Environs",
    "Scope",
    "Kind of rug",
    "Purlieu",
    "Realm",
    "Vicinity",
    "Neighborhood",
    "Neck of the woods",
    "'Hood",
    "Geometry datum",
    "Gazetteer data",
    "Sphere",
    "Territory"
  ],
  "acts": [
    "Has a part to play",
    "___ of Parliament",
    "Book that's the source of the line \"It is more blessed to give than to receive\"",
    "Follower of John",
    "Delays no more",
    "Doesn't hesitate",
    "Book after the Gospels",
    "Takes steps",
    "Makes a scene?",
    "Headings in a playbill",
    "Things separated by intermissions",
    "Circus routines",
    "Fifth book of the New Testament",
    "Plays a role",
    "Doesn't just sit",
    "Talent show entries",
    "Follower of John",
    "Performs",
    "Stops procrastinating",
    "Bills no more",
    "Doesn't delay",
    "Things passed in Congress",
    "Book of the Bible after John",
    "Variety show lineup",
    "Doesn't sit idly by",
    "Fifth book of the New Testament",
    "Isn't a bystander",
    "Follower of the Gospels",
    "Plays charades, say",
    "Drama units",
    "Book preceding Romans",
    "Follower of John",
    "Follower of John",
    "Lion taming and tightrope walking, for two",
    "New Testament book",
    "Doesn't just sit there",
    "Does something",
    "Divisions of a play",
    "Emulates Nicholson",
    "Source of the saying \"It is more blessed to give than to receive\"",
    "Doesn't just talk",
    "Follower of John",
    "___ of the Apostles",
    "Does stage work",
    "___ of the Apostles",
    "Pulls the trigger, so to speak",
    "Shticks",
    "Biblical source of the line \"It is more blessed to give than to receive\"",
    "\"Romeo and Juliet\" has five of them",
    "___ of the Apostles",
    "Talent show lineup",
    "Plays a part onstage",
    "Stage presentations",
    "Source of the line \"Thy money perish with thee\"",
    "Parts of musicals",
    "Does stage work",
    "Stops procrastinating",
    "Performs in a play",
    "Each of Shakespeare's plays has five",
    "Book after John",
    "Takes steps",
    "Makes a scene?",
    "New Testament book",
    "Is in a play",
    "Every play of Shakespeare has five of them",
    "Book that begins in Jerusalem",
    "\"Hamlet\" has five",
    "Is a cast member",
    "Play set",
    "Where the word \"Christian\" is first used in the Bible",
    "Bible book after John",
    "Romans preceder",
    "Delays no longer",
    "Takes steps",
    "Isn't idle",
    "Variety show lineup",
    "Play makers?",
    "Isn't idle",
    "Predecessor of Romans",
    "Does something",
    "New Testament book",
    "Play parts",
    "Congressional output",
    "Book after John",
    "Book ascribed to Luke",
    "Follower of John",
    "Whence the line \"It is more blessed to give than to receive\"",
    "Isn't idle",
    "Intolerable ___, one cause of the American Revolution",
    "Doesn't dilly-dally",
    "Makes a move",
    "Has a part on stage",
    "Play divisions",
    "Isn't naturally",
    "See 71-Across",
    "Does something",
    "See 52-Down",
    "Isn't passive",
    "___ of the Apostles",
    "Is in a play",
    "Deeds",
    "Follower of John",
    "Variety show fillers",
    "Play parts",
    "Intermissions separate them",
    "Does something",
    "Play parts",
    "Play divisions",
    "___ of Parliament",
    "Play makers?",
    "New Testament book",
    "Does stage work",
    "Book before Romans",
    "Exploits",
    "Isn't idle",
    "New Testament book",
    "Book that includes the reign of Tiberius",
    "Opera parts",
    "New Testament book",
    "Substitutes (for)",
    "\"Hamlet\" has five",
    "Play parts",
    "\"Macbeth\" quintet",
    "New Testament book",
    "Book before Romans",
    "Play segments",
    "Play parts",
    "Takes steps",
    "Legislation",
    "Congressional matters",
    "Plays a part",
    "\"Macbeth\" has five",
    "New Testament book",
    "\"Rigoletto\" trio",
    "Play parts",
    "Makes a scene?",
    "Gospels follower",
    "Book after John",
    "Takes steps",
    "\"Hamlet\" quintet",
    "\"Rigoletto\" has three",
    "Res gestae",
    "Play parts",
    "New Testament book",
    "\"Hamlet\" has five",
    "Does film work",
    "Plays the part",
    "___ of the Apostles",
    "Gospels follower",
    "Groups on the program",
    "Is decisive",
    "\"Julius Caesar\" has five",
    "Vaudeville segments",
    "Doesn't wait",
    "Romans predecessor",
    "Book after John",
    "Ham bits",
    "Book after John",
    "Appears",
    "Legislation",
    "Book with St. Paul's story",
    "Plays on stage",
    "Book after John",
    "\"Hamlet\" has five of them",
    "Exploits",
    "Stage doings",
    "It relates the conversion of St. Paul",
    "Romans preceder",
    "Nightclub bits",
    "___ of the Apostles",
    "Statutes",
    "It follows the Gospels",
    "Book after John"
  ],
  "leia": [
    "\"Star Wars\" princess",
    "Princess who says \"Why, you stuck-up, half-witted, scruffy-looking nerf herder!\"",
    "Noted leader of the Resistance",
    "\"Star Wars\" princess",
    "Princess who says \"You're my only hope\" in a hologram",
    "Sci-fi leader of the Resistance",
    "Sci-fi general",
    "Mother of 60-Down",
    "Princess Organa",
    "Princess in a galaxy far, far away",
    "Sci-fi princess helping lead the Rebel Alliance",
    "\"Star Wars\" princess",
    "Princess whose brother is not a prince",
    "Film heroine who says \"Somebody has to save our skins. Into the garbage chute, flyboy\"",
    "Princess who makes a plea via a hologram",
    "Frequently cosplayed sci-fi character",
    "\"Star Wars\" princess",
    "Princess who says \"I recognized your foul stench when I was brought on board\"",
    "Daughter of Anakin and Padme",
    "Her first scene is with R2-D2",
    "Sci-fi princess",
    "Cameo role at the end of \"Rogue One: A Star Wars Story\"",
    "\"Star Wars\" princess",
    "Princess of Alderaan",
    "___ Organa (\"Star Wars\" princess)",
    "Princess with a twin",
    "General of the Resistance in \"The Force Awakens\"",
    "Sci-fi princess who appears as a hologram",
    "Film character who says \"I'd just as soon kiss a Wookiee!\"",
    "\"Star Wars\" princess",
    "Luke Skywalker's twin",
    "Sci-fi character remembered for her large bus",
    "Sister of Luke, in sci-fi",
    "Princess who was captured by Jabba the Hutt",
    "\"Star Wars\" sister",
    "\"Star Wars\" princess",
    "She hailed from the planet Alderaan",
    "Speaker of the line \"Help me, Obi-Wan Kenobi. You're my only hope\"",
    "Whom Han Solo calls \"Your Worship\"",
    "Princess played by Carrie Fisher",
    "Luke's princess sister",
    "Alderaan royal",
    "Princess with a blaster",
    "Film character known for her buns",
    "Han's hon",
    "Royal from the planet Alderaan",
    "See 121-Down",
    "Luke's twin sister in \"Star Wars\"",
    "\"Star Wars\" princess",
    "Fictional princess",
    "Luke's sister, in \"Star Wars\"",
    "Sci-fi princess",
    "Princess in a spaceship",
    "Speaker of the line \"Help me, Obi-Wan Kenobi; you're my only hope\"",
    "Luke Skywalker's sister",
    "Starship princess",
    "Princess from Alderaan",
    "Sci-fi princess",
    "Royal figure of sci-fi",
    "Sci-fi royal",
    "\"Star Wars\" princess",
    "\"Star Wars\" princess",
    "Queen Amidala's daughter",
    "Film princess",
    "\"Star Wars\" role",
    "\"Star Wars\" princess",
    "Princess who battles the Death Star",
    "\"Star Wars\" princess",
    "Movie princess",
    "Fictional princess",
    "Spunky princess",
    "Princess who observed the Force",
    "\"Star Wars\" princess",
    "Movie princess",
    "\"Star Wars\" lovely",
    "Movie princess",
    "Movie princess",
    "\"Star Wars\" princess",
    "\"Star Wars\" princess",
    "\"Star Wars\" princess",
    "\"Star Wars\" princess",
    "\"Star Wars\" princess",
    "Luke's sister",
    "\"Star Wars\" princess",
    "\"Star Wars\" role",
    "\"Star Wars\" princess",
    "\"Star Wars\" princess"
  ],
  "beer": [
    "Brewski",
    "\"Suds\"",
    "It's been known to chase Wild Turkeys",
    "___ o'clock (when happy hour begins)",
    "\"You can't be a real country unless you have a ___ and an airline\": Frank Zappa",
    "Bar serving",
    "The missing letters in _UDW_IS_ _",
    "Some real heady stuff?",
    "Samuel Adams, for one",
    "What a koozie might keep cool",
    "Bud, e.g.",
    "Zymurgist's interest",
    "\"Hold my ___\" (words before a foolish act)",
    "Ballpark purchase",
    "Product much advertised during football games",
    "Case load?",
    "Happy hour order",
    "\"Suds\"",
    "Stadium vendor's offering",
    "Drink that's the subject of several rules in the Code of Hammurabi",
    "Product commonly advertised during football games",
    "Cold draft, maybe",
    "One shouldn't have a big head",
    "\"Beauty is in the eye of the ___ holder\": Kinky Friedman",
    "Molson or Michelob",
    "Fraternity party purchase",
    "Samuel Adams or Corona",
    "Boilermaker component",
    "Sierra Nevada, e.g.",
    "See 24-Down",
    "Lone Star, e.g.",
    "Contents of a 56-Across",
    "It has a head",
    "Common cause of a 3-Down",
    "Tall one or cold one",
    "Mugful",
    "It can be drafted",
    "Cold one, so to speak",
    "Blast constituent?",
    "It should have a head and a good body",
    "Ingredient in some batters",
    "Ballpark buy",
    "Suds",
    "Kind of blast",
    "Keg contents",
    "It has a head and hops",
    "Brew",
    "It may have a big head",
    "Cold one",
    "Schooner filler",
    "Many a stadium concession",
    "The \"one\" in the phrase \"draw one\"",
    "\"Cold one\"",
    "Brewski",
    "Round part",
    "Miller, for one",
    "Oktoberfest serving",
    "\"Cold one\"",
    "Kind of bust",
    "Draft, e.g.",
    "It has a head and hops",
    "It may have a big head",
    "Head site",
    "It has a head but no shoulders",
    "Certain draft",
    "Batter's base, maybe",
    "Harp, for one",
    "Cold draft",
    "Tavern offering",
    "Kind of hall",
    "Miller, for one",
    "Suds",
    "Tuborg, e.g.",
    "23-Across ingredient",
    "Suds",
    "Bud, maybe",
    "Draft, maybe",
    "Miller, for one",
    "Cold one",
    "Suds",
    "Samuel Adams, e.g.",
    "It's stingo, in British lingo",
    "Suds",
    "Kind of blast",
    "Suds, so to speak",
    "Suds",
    "It may have a head but not a tail",
    "Chaser, perhaps",
    "It has a head and hops"
  ],
  "ides": [
    "Fateful day for Caesar",
    "Date sacred to Jupiter",
    "Follower of the calends and the nones",
    "The 13th or 15th",
    "Fateful date",
    "Day in ancient Rome",
    "Fateful day in 44 B.C.",
    "Bad day for Caesar",
    "Midmonth occasion",
    "Middle of the month",
    "November 13, e.g.",
    "Shakespearean warning word",
    "Fateful day for Caesar",
    "Middle-of-the-month day",
    "February 13, e.g.",
    "When \"et tu\" was spoken",
    "Fateful day",
    "Midmonth date",
    "13th or 15th",
    "13th, maybe",
    "February 13, e.g.",
    "Eight days after the nones",
    "10/15, e.g.",
    "When Brutus struck",
    "___ of March",
    "Fateful day in the Senate",
    "Midmonth date",
    "___ of March",
    "Midmonth day",
    "Eight days after the nones, in ancient Rome",
    "May 15, e.g.",
    "Each month has one",
    "Day to beware",
    "Middle marker?",
    "March time",
    "Dangerous time",
    "Midmonth day",
    "Roman calendar day",
    "10/15, e.g.",
    "Midmonth day",
    "Mid-March date",
    "Fateful day in March",
    "\"The ___ of March are come\"",
    "13th, at times",
    "Day on old calendars",
    "Fateful day for Caesar",
    "13th, at times",
    "Yearly dozen",
    "Fateful day for Caesar",
    "Part of every month",
    "___ of March",
    "January 13, e.g.",
    "Fateful day in the Roman senate",
    "Time of danger for Caesar",
    "St. ___ (malt liquor brand named after an Irish nun)",
    "10/15, e.g.",
    "___ of March",
    "Midmonth date",
    "Fateful day in March",
    "Day of destiny",
    "Ominous time of old",
    "Days of old",
    "When 12-Down was uttered",
    "Time of danger",
    "Every month has one",
    "Fateful March date",
    "___ of March",
    "Midmonth time",
    "Bad time for Caesar",
    "Fateful March day",
    "13th or 15th",
    "Fateful date",
    "March time",
    "Midmonth day",
    "November 13, e.g.",
    "Valentine's Day eve, e.g.",
    "Midmonth time",
    "March time",
    "March day",
    "Midmonth date",
    "?ôBeware the ___ ?à?ö",
    "March 15, e.g.",
    "March time",
    "Fateful day in March",
    "Middle of March",
    "Fateful March day",
    "September 13, e.g.",
    "___ of March",
    "December 13th, e.g.",
    "Dangerous time",
    "Midmonth time",
    "Bad day for 25-Across",
    "March time",
    "Bad time for Caesar",
    "Middle of March",
    "A 15th",
    "Roman date",
    "March time",
    "Midmonth day",
    "Day of the month",
    "There are 12 in a year",
    "Day of the month",
    "Bad day for Caesar",
    "Dangerous time",
    "Time of a famous dispatch",
    "Midmonth date",
    "Midmonth date",
    "Bad day for Caesar",
    "Julian calendar date",
    "Time to beware",
    "Days of old",
    "Big date",
    "Fateful day",
    "Midmonth date",
    "Dangerous date for Caesar",
    "April 13, e.g.",
    "Day to remember",
    "March time",
    "Monthly occurrence",
    "One 13th?",
    "One day in March",
    "Midmonth date",
    "Middle of a mensis",
    "Time of the month",
    "July 15, e.g.",
    "Time to beware",
    "Mid-month, in old Rome",
    "Dangerous March date",
    "15th or so",
    "Caesar's worst day",
    "March time",
    "The 13th, usually",
    "About midmonth",
    "March time",
    "Midmonth date",
    "Dangerous March date",
    "Time of danger",
    "___of March",
    "Fateful day",
    "March time",
    "___ of March",
    "Time in \"Julius Caesar\"",
    "Middle March",
    "Bad day for Caesar"
  ],
  "boba": [
    "Word before tea or Fett",
    "Variety of tea",
    "___ Fett, \"Star Wars\" bounty hunter",
    "Sci-fi villain ___ Fett"
  ],
  "deer": [
    "Venison",
    "Bucks and does",
    "Road sign animal",
    "Cousin of an elk",
    "Animal in \"Do-Re-Mi\"",
    "Forest ranger",
    "Howl : wolf :: bell : ___",
    "Pullers of Artemis's chariot",
    "Bucks, does and fawns",
    "Start of a letter in the form of a rebus",
    "Certain animals playing in \"Home on the Range\"",
    "Bucks and does",
    "___ Xing (road sign)",
    "Subject of road \"Xing\" signs",
    "A couple of bucks?",
    "Road sign animal",
    "Puma prey",
    "Road sign silhouette",
    "Ovine : sheep :: cervine : ___",
    "Bambi, e.g.",
    "___ season",
    "Animal in \"Home on the Range\"",
    "Road sign silhouette",
    "A few bucks?",
    "___ Valley (Utah ski resort)",
    "Animal that's sometimes frozen in the headlights",
    "Bambi, for one",
    "___ Xing (road sign)",
    "Bambi and others",
    "Does and bucks",
    "They're game",
    "Traditional meat in a humble pie",
    "Road sign silhouette",
    "Forest game",
    "Game with points",
    "Cougar's prey",
    "___ Valley, 2002 Winter Olympics venue",
    "Silhouette on many a yellow sign",
    "Some ruminants",
    "Rudolph and kin",
    "Harts and hinds",
    "Rural road sign",
    "Bucks and bucks",
    "One of them does?",
    "Bucks and does",
    "Animal on XING signs",
    "White-tailed ___",
    "Does, e.g.",
    "A couple of bucks?",
    "___ Xing",
    "Hinds, e.g.",
    "Carriers of Lyme ticks",
    "Animal that may be caught in the headlights",
    "Animal on XING signs",
    "A fawn is a young one",
    "Pullers of the chariot of Artemis",
    "Skittish herd",
    "This puzzle's theme",
    "Skittish wildlife",
    "Some game",
    "Bucks, e.g.",
    "Antelope's playmate",
    "They're game",
    "Timid creature",
    "___ Xing (sign)",
    "Rural road sign image",
    "They're game",
    "Sleigh pullers",
    "Rudolph and team",
    "Does and bucks",
    "Forest animals",
    "Timid creature",
    "Whitetail, e.g.",
    "Bucks and does",
    "A couple of bucks, say",
    "Ones that may get ticked?",
    "Huckleberry eaters",
    "Bambi, for one",
    "Bucks and does",
    "Animals with fawns",
    "Big game",
    "Howl : wolf :: bell : ___",
    "Buck, say",
    "Shy creature",
    "Does and bucks",
    "Hind, e.g.",
    "Fast bucks",
    "Big game",
    "Veal : calf :: venison : ___",
    "Bucks and does",
    "Bucks",
    "Does and bucks",
    "Forest denizens",
    "They're associated with licks and ticks",
    "Bucks and does",
    "Santa's team, e.g.",
    "Puller of a pulka",
    "Indian prey",
    "Stag or hart",
    "Many bucks",
    "Lots of bucks",
    "Fantail, for one",
    "Stag party?",
    "Bucks and does",
    "Lots of bucks",
    "A lot of bucks",
    "Stags and does",
    "Does and bucks",
    "Bambi and kin",
    "Forest denizen",
    "Bambi and others",
    "Lots of bucks",
    "Natty Bumppo's quarry",
    "A couple of bucks?",
    "Moose, for one",
    "Whitetails",
    "Antelope's playmate",
    "Big bucks, perhaps",
    "Bambi, e.g.",
    "Venison",
    "Open-season quest",
    "Bucks and does",
    "Antler wearer",
    "Fawn or doe",
    "Bambi,. e.g.",
    "A few bucks",
    "Venison",
    "Does, for example",
    "Bucks and does",
    "Bucks and does",
    "Stag party?",
    "Bambi and kin",
    "Bucks, e.g."
  ],
  "role": [
    "Something played that's not a game",
    "Part to play",
    "Part",
    "Part",
    "Credit line?",
    "___-play",
    "Auditioner's desire",
    "Wonder Woman, for Gal Gadot",
    "Part of a theater?",
    "Focus of a casting director",
    "Cameo, for one",
    "Part in a movie",
    "Auditioner's goal",
    "Part to play",
    "Part to play",
    "Goal for an actor",
    "Part in a movie",
    "Iago or Othello",
    "Casting director's assignment",
    "Part",
    "Capacity",
    "Part to play",
    "Part",
    "Part in a movie",
    "Capacity",
    "Auditioner's hope",
    "Offering from a casting director",
    "Something cast",
    "Assumed thing",
    "Part of Hollywood?",
    "Part",
    "What an actor plays",
    "Capacity",
    "Capacity",
    "Something to audition for",
    "Something to play",
    "It's cast",
    "Ladder, part 5",
    "What an actor plays",
    "Credits listing",
    "Part of a play",
    "Something to play",
    "Job for an actor",
    "Show part",
    "It's cast and landed",
    "Part",
    "Function",
    "Part to play",
    "Moe Howard, for Chris Diamantopoulos, in 2012",
    "Part to play",
    "One may be leading or supporting",
    "Stage part",
    "Actor's pursuit",
    "King Henry V, e.g.",
    "It may be played for money",
    "Credits listing",
    "It might be assumed",
    "Thing to play",
    "It's assumed",
    "Something to play",
    "58-Across, for Matt Damon",
    "Lead, for one",
    "It must be cast",
    "Part on stage",
    "Cameo, e.g.",
    "Iago, for one",
    "Macbeth or Lady Macbeth",
    "Part to play",
    "Part",
    "Lead, e.g.",
    "God, for George Burns or Morgan Freeman",
    "See 12-Across",
    "What a casting director tries to fill",
    "Medea, for one",
    "Part",
    "Gen. Patton, to George C. Scott",
    "Part to play",
    "Reader's goal",
    "Film part",
    "Part",
    "Actor's part",
    "It may be played",
    "Auditioner's goal",
    "Function",
    "Audition goal",
    "Character",
    "Play part",
    "Part to play",
    "Capacity",
    "Part in a play",
    "Starlet's goal",
    "Stage part",
    "Play thing?",
    "Part",
    "Film part",
    "Goal of a tryout",
    "Actor's pursuit",
    "Part",
    "Lady Macbeth, e.g.",
    "Lady Macbeth, e.g.",
    "Hamlet, in \"Hamlet\"",
    "Something to play",
    "Hamlet or Ophelia",
    "Hat, symbolically",
    "Lady Macbeth, e.g.",
    "Starring ___",
    "Part to play",
    "Play thing?",
    "Stage part",
    "Rocky, for Stallone",
    "Auditioner's quest",
    "Character",
    "Film part",
    "It has lines",
    "Cameo, e.g.",
    "It must be cast",
    "Part",
    "Malcolm X, for Denzel Washington",
    "Part",
    "Function",
    "Cameo, say",
    "Part to play",
    "Character",
    "Part",
    "Part to play",
    "Part to play",
    "Cameo, maybe",
    "Lady Macbeth, for one",
    "Hamlet, e.g.",
    "Part to play",
    "Play part",
    "Character",
    "Romeo, e.g.",
    "Part to play",
    "Stage part",
    "Kind of model",
    "Position to fill",
    "Part",
    "Speaking___",
    "Capacity",
    "Capacity",
    "Cast assignment",
    "Part to play",
    "Portrayal",
    "Part",
    "Star's vehicle",
    "Part",
    "Actor's part"
  ],
  "chef": [
    "___ Boyardee",
    "\"Give my compliments to the ___\"",
    "Apron wearer",
    "Course designer",
    "TV's \"Top ___\"",
    "One wearing an apron and a puffy white hat",
    "Pot user?",
    "One with a lot of coursework?",
    "Food Network V.I.P.",
    "Saucier's boss",
    "Restaurant figure",
    "Special creator?",
    "Course preparer",
    "One doing course work",
    "One taking orders",
    "Food processor?",
    "Whisk wielder",
    "One who stirs the pot",
    "Pan handler",
    "Julia Child, for one",
    "Contestant on a Bravo reality show",
    "Person who's often sent compliments",
    "One who wants you to put away everything he sets out",
    "Child, for one",
    "Caterer's associate",
    "Person who's often sent compliments",
    "Special creator?",
    "Dish designer",
    "One receiving orders",
    "One in a white hat",
    "Bake-off figure",
    "White hat wearer",
    "One who takes orders",
    "Person in a puffy white hat",
    "White hat wearer",
    "Taster, at times",
    "Dish maker",
    "Person with a puffy white hat",
    "High-hatter?",
    "One who takes orders",
    "Child, for one",
    "Worker with an apron",
    "One whose work causes a stir?",
    "Person in a big white hat?"
  ],
  "hogs": [
    "Harleys, in slang",
    "Refuses to share",
    "Selfish sorts",
    "Harleys, in slang",
    "Harleys, e.g.",
    "Swine",
    "Monopolizes",
    "Arkansas footballers, informally",
    "Porkers",
    "Bikers' mounts",
    "University of Arkansas team, informally",
    "Swine",
    "Greedy ones",
    "Takes most of",
    "They take too much",
    "Sty animals",
    "Monopolizes",
    "Monopolizes",
    "Doesn't share",
    "Sloppy eaters"
  ],
  "rash": [
    "Poison ivy eruption",
    "Jumping the gun",
    "Impetuous",
    "Far from deliberate",
    "Heedless",
    "Proceeding headlong without thinking",
    "Overly hasty",
    "Hasty",
    "That's so irritating!",
    "Reckless",
    "Reckless, as a decision",
    "What talcum powder may prevent",
    "Quick and not well thought out",
    "Unconsidered",
    "Not thought out",
    "Devil-may-care",
    "Impulsive",
    "Hardly deliberate",
    "Impulsive",
    "Headlong or headstrong",
    "Impetuous",
    "Spate",
    "Foolhardy",
    "Not thought through",
    "Too hasty",
    "Imprudent",
    "Torrent",
    "Without consideration",
    "Heedless",
    "Spur-of-the-moment",
    "Precipitate",
    "Impulsive",
    "Done without due consideration",
    "Reaction to poison oak",
    "Ill-considered",
    "Too hasty",
    "Reckless",
    "Calamine lotion target",
    "Diaper problem",
    "Impetuous",
    "Calamine lotion target",
    "Prickly heat",
    "Itch cause",
    "Ill-considered",
    "Hotheaded",
    "Overly hasty",
    "Widespread reaction",
    "Dermal development",
    "Daredevil",
    "Pell-mell",
    "Foolhardy",
    "Too hasty",
    "Allergy symptom",
    "Ill-considered",
    "Undue",
    "Spate",
    "Poison ivy reaction",
    "Foolhardy",
    "Unheedful",
    "Poison ivy symptom",
    "Medical symptom",
    "Allergy indication",
    "Skin problem",
    "Foolhardy",
    "Spate",
    "Impulsive",
    "Poison ivy woe",
    "Rubella symptom",
    "Epidemic",
    "Impetuous",
    "Unthought-out",
    "Too hasty",
    "Foolhardy",
    "Unthought-out",
    "Prickly heat, e.g.",
    "Talc target",
    "Foolhardy",
    "Poison ivy woe",
    "Precipitate",
    "Reckless",
    "Not deliberate",
    "Torrent",
    "Baby's problem",
    "Hasty",
    "Devil-may-care",
    "Leaping before looking",
    "Baby skin problem",
    "Incautious",
    "Impetuous",
    "Breakout",
    "Foolhardy",
    "Baby woe",
    "Ill-considered",
    "Multitude of occurrences",
    "Too hasty",
    "Baby's diaper problem",
    "Impulsive",
    "Impetuous",
    "Unthinking",
    "Ill-considered",
    "Impetuous",
    "Skin problem"
  ],
  "snit": [
    "Fit of irritation",
    "Huff",
    "Bothered state",
    "Huff",
    "Red state?",
    "Fit of pique",
    "Irritated mood",
    "State of irritability",
    "Huff",
    "Something a crab might be found in",
    "Foul mood",
    "Sulk",
    "Foul mood",
    "Lather",
    "Huff",
    "Tizzy",
    "Pet",
    "Irritated state",
    "Huff",
    "Cross condition",
    "Fit of pique",
    "Bad mood",
    "Red state?",
    "Peeved state",
    "Sulky mood",
    "Huff",
    "Sulk",
    "Pique performance?",
    "Pique activity?",
    "Tizzy",
    "State of vexation",
    "Peevish state",
    "Pet",
    "Peeved state",
    "Cross state",
    "Agitated state",
    "Hissy fit",
    "Pet",
    "Foul mood",
    "Vexation",
    "Huff",
    "Foul mood",
    "Pother",
    "Foul mood",
    "State of ill humor",
    "Vexed state",
    "Tizzy",
    "Peeved state",
    "State when one's nose is out of joint",
    "Fit of pique",
    "Ruffled state",
    "Lather",
    "Tizzy",
    "Pique",
    "Red state?",
    "Temper tantrum",
    "Agitated state",
    "Pique",
    "Pet",
    "Fit of temper",
    "Hissy fit",
    "Huff",
    "Agitation",
    "Foul mood",
    "Foul mood",
    "Fit of pique",
    "Pet",
    "Irritated state",
    "Pique",
    "Agitated state",
    "Piqued state",
    "Lather",
    "Tizzy",
    "Fit of pique",
    "Piqued state",
    "Pique experience?",
    "Cross condition",
    "Pet",
    "Lather",
    "Huff",
    "Pique",
    "Pique",
    "Peevish display",
    "Hissy fit",
    "Hissy fit",
    "Pet",
    "Hissy fit",
    "Peevish humor",
    "Pet",
    "Agitated state",
    "Huff",
    "Piqued state",
    "Pet",
    "Complainer's state",
    "Tizzy",
    "Worked-up state",
    "Miff",
    "Agitated state",
    "Hissy fit",
    "Angry state",
    "Pique",
    "Pet",
    "Huffy state",
    "Huff",
    "Tiff",
    "It's unpleasant to be in",
    "Pet",
    "Huffy state",
    "Tizzy",
    "Tizzy",
    "Tizzy",
    "Agitation",
    "Tizzy",
    "Perturbed state",
    "Pique",
    "Tantrum",
    "In a___(agitated)",
    "Peevishness",
    "Angry state",
    "Tantrum",
    "Huff",
    "Bad mood",
    "Pique",
    "Huff",
    "Show of anger",
    "Fit of anger",
    "Irritated state",
    "Pique"
  ],
  "engr": [
    "Head of a train: Abbr.",
    "Many a graduate of 37-Across: Abbr.",
    "Many an M.I.T. grad: Abbr.",
    "Certain B.S. holder: Abbr.",
    "Many a SpaceX worker: Abbr.",
    "One practicing the \"E\" of STEM subjects: Abbr.",
    "One who pulls a train whistle: Abbr.",
    "App creator, perhaps: Abbr.",
    "Many a Silicon Valley worker: Abbr.",
    "Tech grad: Abbr.",
    "Dam designer: Abbr.",
    "M.I.T. grad, often: Abbr.",
    "56-Down grad: Abbr.",
    "Applied sci. professional",
    "Many a NASA worker: Abbr.",
    "Road designer, e.g.: Abbr.",
    "Many a Lockheed Martin employee: Abbr.",
    "Mech. whiz",
    "Many an M.I.T. grad",
    "Mech. expert",
    "Hwy. planner",
    "Mech. master",
    "Hwy. planner",
    "Bldg. planner",
    "Bridge or tunnel designer: Abbr.",
    "Mechanical whiz: Abbr.",
    "Mech. ___",
    "Technician: Abbr.",
    "Many an M.I.T. grad: Abbr.",
    "Rd. designer, e.g.",
    "Many a NASA worker: Abbr.",
    "Boeing worker: Abbr.",
    "Bldg. planner",
    "G.E. employee",
    "Hwy. dept. employee",
    "Bldg. planner",
    "Many a NASA employee: Abbr.",
    "M.I.T. grad., often",
    "Many an M.I.T. grad: Abbr.",
    "Bridge builder, e.g.: Abbr.",
    "RR employee",
    "M.I.T. grad: Abbr.",
    "Techie: Abbr.",
    "Bldg. contractor's hire",
    "Caltech grad: Abbr.",
    "Tech sch. grad",
    "R.P.I. grad, perhaps",
    "M.T.A. employee",
    "M.I.T. grad: Abbr.",
    "Technician: Abbr.",
    "M.I.T. grad: Abbr."
  ],
  "anna": [
    "Tennis's Kournikova",
    "\"___ Karenina\"",
    "Author/journalist Quindlen",
    "Actress ___ Deavere Smith",
    "Palindromic woman's name",
    "Actress Kendrick of \"Pitch Perfect\"",
    "___ of Arendelle (Disney heroine)",
    "Actress Kendrick",
    "\"Veep\" actress Chlumsky",
    "Tennis's Kournikova",
    "Disney character who sings \"Do You Want to Build a Snowman?\"",
    "Actress Chlumsky of \"Veep\"",
    "Tolstoy's \"___ Karenina\"",
    "Elsa's sister in \"Frozen\"",
    "Tolstoy heroine",
    "Palindromic woman",
    "Palindromic girl",
    "Nordqvist of the L.P.G.A.",
    "Tolstoy's \"___ Karenina\"",
    "Fashion designer Sui",
    "Actress Kendrick",
    "Eugene O'Neill's \"___ Christie\"",
    "Visitor to King Mongkut",
    "Actress Paquin who won an Academy Award at age 11",
    "Broadway character who sings \"Shall We Dance?\" while she dances",
    "Tennis's Kournikova",
    "Popular girl's name any way you look at it",
    "Fashion editor Wintour",
    "Actress Chlumsky of \"Veep\"",
    "Ballet dancer Pavlova",
    "Actress Kendrick or Paquin",
    "Actress Kendrick",
    "Fashion's Wintour",
    "The \"I\" of \"The King and I\"",
    "Emmy winner Gunn of \"Breaking Bad\"",
    "Elsa's sister in \"Frozen\"",
    "Gunn of \"Breaking Bad\"",
    "\"___ and the King of Siam\"",
    "Elsa's younger sister in \"Frozen\"",
    "Actress Kendrick of \"Pitch Perfect\"",
    "\"___ Karenina\"",
    "Woman in \"The King and I\"",
    "Kournikova of tennis",
    "\"Frozen\" princess",
    "Eugene O'Neill's \"___ Christie\"",
    "Fashion editor Wintour",
    "\"___ Karenina\"",
    "Visitor to Siam, on stage and film",
    "Actress Gunn of \"Breaking Bad\"",
    "1999 drama \"___ and the King\"",
    "Actress Kendrick of \"Pitch Perfect\"",
    "Russian ruler after Peter II",
    "Actress Paquin",
    "Tennis champ Kournikova",
    "\"The King and I\" heroine",
    "Writer Quindlen",
    "___ Gunn, \"Breaking Bad\" co-star",
    "\"The King and I\" role",
    "Karina in many a Jean-Luc Godard film",
    "\"___ and the King of Siam\"",
    "Actress Paquin",
    "O'Neill's \"___ Christie\"",
    "Tolstoy's \"___ Karenina\"",
    "She was the \"I\" in \"The King and I\"",
    "26-Down's role in \"The King and I\"",
    "\"Finnegans Wake\" wife",
    "Model ___ Nicole Smith",
    "Tolstoy heroine",
    "First lady Harrison",
    "Pavlova of the ballet",
    "\"___ and the King of Siam\"",
    "Title girl on \"Introducing ... The Beatles\"",
    "Early Beatles tune subtitled \"Go to Him\"",
    "Kendrick of \"Up in the Air\"",
    "\"The King and I\" governess",
    "\"X-Men\" actress Paquin",
    "\"Up in the Air\" actress Kendrick",
    "Eugene O'Neill's \"___ Christie\"",
    "Fashion style-setter Wintour",
    "Kournikova of tennis",
    "Lady whom Don Giovanni tries to seduce",
    "Tolstoy's Karenina",
    "Title girl on the first Beatles album",
    "Wintour of fashion",
    "\"Finnegans Wake\" wife",
    "Tennis's Kournikova",
    "Ballerina Pavlova",
    "Actress Popplewell of \"The Chronicles of Narnia\"",
    "\"___ Karenina\"",
    "\"___ and the King of Siam\"",
    "\"Finnegans Wake\" wife",
    "___ Wintour, real-life editor on whom \"The Devil Wears Prada\" is based",
    "Eleanor Roosevelt's first name",
    "Tennis player Smashnova",
    "Late celebrity ___ Nicole Smith",
    "1999 Jodie Foster title role",
    "\"The King and I\" woman",
    "Ballerina Pavlova",
    "Grandma Moses' first name",
    "Tolstoy's Karenina",
    "Sigmund Freud's daughter",
    "Kournikova of tennis",
    "Trollope's \"Lady ___\"",
    "\"The King and I\" heroine",
    "Tolstoy heroine",
    "Palindromic girl's name",
    "Actress Karina who played Scheherazade",
    "A Freud",
    "Ballerina Pavlova",
    "Kournikova of tennis",
    "Title girl of a 1953 million-selling record",
    "\"___ and the King of Siam\"",
    "Pavlova of ballet",
    "Sigmund Freud's daughter",
    "___ Eleanor Roosevelt",
    "Actress Faris of the \"Scary Movie\" movies",
    "\"Finnegans Wake\" wife",
    "Poet Akhmatova",
    "Trollope's \"Lady ___\"",
    "Tennis star Kournikova",
    "Writer Quindlen",
    "1930's-40's leading lady born in Russia",
    "\"___ Karenina\"",
    "\"___ and the King of Siam\"",
    "Pulitzer winner Quindlen",
    "Tennis's Kournikova",
    "Wife in \"Finnegans Wake\"",
    "The \"I\" of \"The King and I\"",
    "Title girl in the first Beatles album",
    "\"The King and I\" tutor",
    "O'Neill's Christie",
    "O'Neill's \"___ Christie\"",
    "\"The King and I\" character",
    "Donna ___, \"Don Giovanni\" soprano",
    "Visitor to Siam",
    "The \"I\" of \"The King and I\"",
    "One of the Freuds",
    "Ballet Russe star Pavlova",
    "Oscar winner Paquin",
    "Ballerina Pavlova",
    "Tolstoy heroine",
    "Ballerina Pavlova",
    "Tolstoy heroine",
    "Tolstoy heroine",
    "Oscar winner Paquin",
    "The \"I\" in \"The King and I\"",
    "Soprano Moffo",
    "Russian poet Akhmatova",
    "\"___ and the King\" (Foster film)",
    "Moffo or Magnani",
    "Tolstoy's \"___ Karenina\"",
    "Tutor of Siamese royalty",
    "Madame Karenina",
    "\"___ Imroth\" (Sandburg poem)",
    "The \"I\" in \"The King and I\"",
    "Soprano Moffo",
    "\"The King and I\" role",
    "Magnani of \"The Rose Tattoo\"",
    "The \"I\" in \"The King and I\"",
    "Title girl in a song on the first Beatles album",
    "Visitor to Siam",
    "\"The King and I\" role",
    "Paquin or Magnani",
    "Wife in \"Finnegans Wake\"",
    "\"The King and I\" role",
    "Tennis star Kournikova",
    "Grandma Moses' first name",
    "\"Black Beauty\" author Sewell",
    "Ballerina Pavlova",
    "Visitor to Siam",
    "First Lady Harrison",
    "Sigmund Freud's daughter",
    "The \"I\" in \"The King and I\"",
    "Writer Quindlen",
    "The \"I\" of \"The King and I\"",
    "Diva Moffo",
    "The \"I\" of \"The King and I\"",
    "Dancer Pavlova",
    "Trollope's \"Lady ___\"",
    "Moffo at the Met",
    "Christie or Quindlen",
    "\"Finnegans Wake\" wife",
    "O'Neill's \"___ Christie\"",
    "\"Finnegans Wake\" wife",
    "\"___ Karenina\"",
    "Held on the stage",
    "Deborah's role in \"The King and I\"",
    "I in \"The King and I\"",
    "Actress Magnani",
    "Palindrome girl",
    "Bangkok teacher, on Broadway",
    "Model ___ Nicole Smith",
    "\"___ Karenina\"",
    "Sigmund's daughter",
    "Ruler of Russia, 1730-40",
    "\"___Karenina\"",
    "Siamese employee, once",
    "\"The King and I\" lady",
    "With 6-Across, \"Nana\" actress, 1934",
    "?ôFinnegans Wake?ö wife",
    "Tolstoy's Karenina",
    "\"The King and I\" role",
    "Diva Moffo",
    "Tolstoy's Karenina",
    "Pulitzer winner Quindlen",
    "Soprano Moffo",
    "Dancer Pavlova",
    "Sigmund's daughter",
    "Writer Quindlen"
  ],
  "dcon": [
    "Big name in pesticides",
    "Raid shelfmate",
    "Pest control brand",
    "Pest control brand founded by Lee Ratner (!) in the 1950s",
    "Rodent control brand",
    "Pest control brand",
    "Popular rodent control brand",
    "Mousetrap brand",
    "Glue trap brand",
    "Rodenticide brand with glue traps",
    "Big name in rodent control",
    "Rodent control brand",
    "Pest control brand",
    "Big name in rodent control",
    "Household brand name with a lowercase first letter",
    "Brand of mouse poison",
    "Brand of bait pellets",
    "Popular pesticide",
    "Pest control brand",
    "Pest control brand",
    "Popular insecticide",
    "Big name in pest control",
    "Rodenticide name",
    "Raid rival",
    "Pesticide brand",
    "Raid alternative"
  ],
  "aldo": [
    "___ Ray, 1950s-'70s leading man",
    "Global shoe retailer",
    "Big name in shoes and handbags",
    "Designer Gucci",
    "Brand of shoes or handbags",
    "Designer Gucci",
    "Environmentalist ___ Leopold, author of the best-selling \"A Sand County Almanac\"",
    "Brand of shoes and handbags",
    "Shoe brand that's also a man's name",
    "Designer Gucci",
    "Designer Gucci",
    "Gucci of fashion",
    "Designer Gucci",
    "Gucci of fashion",
    "Designer Gucci",
    "Brand of shoes or handbags",
    "Ray of old pictures",
    "Designer Gucci",
    "Author/architect Buzzi",
    "Fashion's Gucci",
    "Gucci of fashion",
    "Lt. Raine of \"Inglourious Basterds\"",
    "International shoe company",
    "Ray in pictures",
    "Fashion designer Gucci",
    "Italian architect Rossi",
    "Ray of \"Battle Cry\"",
    "Fashion executive Gucci",
    "Gucci of fashion",
    "___ Moro, former Italian P.M.",
    "Designer Gucci",
    "Designer Gucci",
    "Actor Ray",
    "Tough guy portrayer Ray",
    "Designer Gucci",
    "Designer Gucci",
    "Designer Gucci",
    "Actor Ray",
    "Ray of film",
    "Designer Gucci",
    "Ray of Hollywood",
    "Actor ___ Ray",
    "Beefy actor Ray",
    "Designer Gucci",
    "Ray of film",
    "Ray of Hollywood",
    "Tough-guy actor Ray",
    "Ray of Hollywood",
    "Actor Ray",
    "Tough-guy actor Ray",
    "Actor Ray",
    "Ray of Hollywood"
  ],
  "blab": [
    "Spill the beans",
    "Gossip",
    "Not keep a secret",
    "Not keep a secret",
    "Spill something",
    "Spill the beans",
    "Spill something",
    "Tell all",
    "Talk, talk, talk",
    "Spill everything",
    "Not be a good confidant",
    "Spill the beans",
    "Sing",
    "Sing",
    "Tattle",
    "Let the cat out of the bag",
    "Babble",
    "Spill the beans",
    "State secrets",
    "Spill the beans",
    "Tell all",
    "Spill the beans",
    "Not hold it in",
    "Spill the beans",
    "Say too much",
    "Not keep to oneself",
    "Not keep secret",
    "Yak",
    "Be loose-lipped",
    "Spill the beans",
    "Give away",
    "Tell secrets",
    "Tattle",
    "Spill the beans",
    "Not be discreet",
    "Tell all",
    "Let it all out",
    "Not keep a secret",
    "Spill the beans",
    "Leak",
    "Give away",
    "Sing"
  ],
  "aime": [
    "\"Je t'___\" (French words of endearment)",
    "\"Je t'___\"",
    "\"Je t'___\"",
    "\"Je t'___\" (words from a beau)",
    "Love, in Lyon",
    "\"Je t'___\" (\"I love you,\" in French)",
    "\"Je t'___\"",
    "\"Je t'___\" (French for \"I love you\")",
    "\"Je t'___\" (\"I love you\": Fr.)",
    "\"Je t'___\"",
    "\"Je t'___\"",
    "\"Paris, Je T'___\" (2006 film)",
    "\"Je t'___\" (French words of affection)",
    "\"Je vous ___\"",
    "\"Je vous ___\"",
    "It's love, in Lille",
    "\"Je t'___\" (French words of endearment)",
    "\"Darling, Je Vous ___ Beaucoup\"",
    "\"Je Vous___\" (1947 song)"
  ],
  "tgif": [
    "End-of-the-week shout",
    "\"Weekend, here I come!\"",
    "Apt anagram of GIFT",
    "Title letters chanted in a 2011 Katy Perry hit",
    "Inits. one might hear at the office",
    "Popular ABC programming block of the '90s",
    "Weekend warrior's cry",
    "\"The freakin' weekend is here!\"",
    "End-of-the-week expression",
    "\"What a long week!\"",
    "End-of-the-week cheer",
    "\"Eight more hours and I'm outta here!\"",
    "Office letters that bring happiness?",
    "Cry after a hectic week",
    "Exclamation from a weary employee",
    "End-of-week cry",
    "Cry at a happy hour, maybe",
    "Cry to kick off the weekend",
    "Shout after a stressful week",
    "A cry of relief",
    "\"Glad the week's almost over!\"",
    "Happy end-of-week cry",
    "End-of-the-week whoop",
    "Desk jockey's happy yell",
    "\"Woo-hoo! The weekend's almost here!\"",
    "Office whoop",
    "Weekly \"Whew!\"",
    "End-of-week cry",
    "End-of-week cry",
    "Worker's weekend whoop",
    "End-of-workweek cry",
    "\"Whew! What a long week!\"",
    "Ready-for-the-weekend cry",
    "End-of-week cry",
    "Late-week cry",
    "Office workers' letters",
    "Letters said with a shout",
    "Happy hour shout",
    "End-of-workweek cry",
    "\"I'm ready for the weekend!\"",
    "End-of-workweek cry",
    "Weary worker's cry",
    "Happy hour cry",
    "Cry of joy",
    "End-of-the-week cheer",
    "Abbreviation said with a \"Whew!\"",
    "\"Whew! The workweek's almost over!\"",
    "End-of-week cry",
    "End-of-week cry",
    "Office cry",
    "Worker?Æs shout",
    "End-of-week cry",
    "Exclamation around a water cooler",
    "Comment by the work-weary",
    "Cry of relief",
    "Weekend-starting cry",
    "Cry before you're off, perhaps",
    "Letters of relief",
    "End-of-the-week cry",
    "Letters exchanged by employees?",
    "End-of-week cry",
    "End-of-the-week cry",
    "Cry at the end of a workweek",
    "Work week whoop",
    "Weekly cry",
    "Cry of relief",
    "Letters for nine-to-fivers",
    "Weary workers' exclamation",
    "Work-weary exclamation",
    "End-of-week cry",
    "Pre-weekend cry",
    "Weekly \"Whew!\"",
    "Expression of relief",
    "Letters of rejoicing",
    "Appropriate cry for Crusoe?",
    "End-of-week exclamation",
    "Crusoe's favorite expression?"
  ],
  "gate": [
    "Place for a guard",
    "Box-office revenue",
    "Airplane ticket info",
    "Info for an air traveler",
    "Racehorse's starting point",
    "Scandalous suffix",
    "Concert proceeds",
    "Boarding pass datum",
    "Scandal suffix",
    "Starting point for a horse race",
    "Stadium attendance",
    "Flight board info",
    "Receipts",
    "Arena income",
    "Entrance to a field",
    "Ending for many a scandal",
    "Paid attendance",
    "Airport assignment",
    "Horse race's starting point",
    "Box office",
    "Information on a boarding pass or stadium ticket",
    "Box-office take",
    "Swinger?",
    "Terminal information",
    "Scandal suffix",
    "Slalom obstacle",
    "Terminal division",
    "Box-office take",
    "Part of a slalom run",
    "Yard entrance",
    "Box office",
    "Golden ___ Bridge",
    "Concert promoter's figure",
    "Concert receipts",
    "Entryway",
    "Event receipts",
    "Entrance",
    "Event proceeds",
    "Formal entrance",
    "Track take",
    "Way to get out of a field",
    "Golden ___",
    "Admission receipts",
    "Fancy entrance feature",
    "Stadium take",
    "Stadium receipts",
    "Box office take",
    "Box office take",
    "Take, at a concert",
    "St. Peter's station",
    "Ticket info",
    "Garden entrance",
    "Ticket sales",
    "Garden access",
    "Golden ___",
    "Way in",
    "Scandal suffix",
    "Box office take",
    "Field opening",
    "Box office",
    "Fence opening",
    "Take",
    "Proceeds",
    "Way in",
    "Slalom obstacle",
    "Portcullis",
    "Paid attendees",
    "Receipts",
    "Concert receipts",
    "Garden entrance",
    "Entrance",
    "Political suffix since the 70's",
    "Field entrance",
    "Garden access",
    "Scandal suffix",
    "Way out",
    "Receipts",
    "Arena receipts",
    "Mountain pass"
  ],
  "acid": [
    "Battery liquid",
    "Word with rain or rock",
    "The \"A\" of DNA",
    "Something you might trip on",
    "Sharp",
    "What's anything but basic?",
    "Bitter",
    "Biting",
    "Chemical that burns",
    "___ jazz",
    "If you drop this, you're sure to trip",
    "It's below 7 on the pH scale",
    "Gastric juice, e.g.",
    "The \"A\" in DNA",
    "Battery fill",
    "Word with rain or rock",
    "Anything but basic",
    "Biting",
    "At the lower side of the pH scale",
    "Tart",
    "Litmus paper reddener",
    "Sharp",
    "Gastric ___",
    "Tums target",
    "Battery fluid",
    "Sharp",
    "Alkali neutralizer",
    "Biting",
    "Anything below 7 on the pH scale",
    "If you drop this you'll trip",
    "Boric ___",
    "Pepto-Bismol target",
    "Stomach stuff",
    "Biting",
    "Low-pH substance",
    "The \"A\" in DNA",
    "Word before test or trip",
    "LSD",
    "Indigestion cause",
    "Liquid that burns",
    "The \"A\" in RNA",
    "Trip provider?",
    "What lemon adds to a dish, in food lingo",
    "___ rock",
    "___ wash jeans",
    "You'll trip if you drop it",
    "It's not basic",
    "Nitric ___",
    "Sulfuric ___",
    "It's not basic",
    "Kind of rock",
    "Battery fluid",
    "Low-pH compound",
    "Lemon juice, e.g.",
    "Eating stuff",
    "Vinegar, for one",
    "Kind of rock",
    "Base's opposite",
    "It was dropped in the '60s",
    "Breakdown cause",
    "Timothy Leary dropped it",
    "Inspiration for Hunter S. Thompson",
    "Aspirin, e.g.",
    "Mordant",
    "Mordant",
    "Carbolic ___",
    "Target of milk of magnesia",
    "Hydrochloric ___",
    "The \"A\" in DNA",
    "Battery fluid",
    "91-Across, e.g.",
    "Biting",
    "Burn cause",
    "Hydrofluoric ___",
    "Battery contents",
    "Vicious",
    "Cause of much bellyaching?",
    "It has a low pH",
    "Like gastric juice",
    "Like gastric juice",
    "See 26-Down",
    "Etching liquid",
    "Corrosive liquid",
    "See 17-Down",
    "___ reflux",
    "Another name for 30-Down",
    "Battery fluid",
    "Etcher's fluid",
    "Battery liquid",
    "It was dropped in the 60's",
    "Citric ___",
    "Aspirin, e.g.",
    "It has a low pH",
    "Amino ___",
    "Parietal cell secretion",
    "Boric ___",
    "Kind of rock",
    "Amino, for one",
    "More than sarcastic",
    "Ascorbic ___",
    "Etcher's need",
    "Kind of wit or test",
    "Sharp",
    "Etcher's need",
    "Word with rock or rain",
    "LSD",
    "Kind of indigestion",
    "Nasty",
    "Tart",
    "Stomach botherer",
    "Kind of test or rain",
    "___ test",
    "Kind of rain",
    "Battery fluid",
    "Kind of rock",
    "Extremely sharp",
    "Sour",
    "Kind of test",
    "Having a pH value of less than 7",
    "LSD",
    "Stinging",
    "Baseless?",
    "It reddens litmus paper",
    "Nasty, as a comment",
    "Kind of tongue",
    "LSD",
    "Kind of rock",
    "Fatty ___",
    "Folic, e.g.",
    "___ rain",
    "Sarcasm",
    "Tart"
  ],
  "scow": [
    "Trash-hauling ship",
    "Garbage disposal unit",
    "Ungainly boat",
    "Flat-bottomed boat",
    "Garbage barge",
    "Flat-bottomed boat",
    "Clumsy boat",
    "Garbage-hauling boat",
    "Trash-toting transport",
    "Flat-bottomed boat",
    "Trash-hauling boat",
    "Flat-bottomed boat",
    "Trash hauler",
    "Boat with a flat bottom",
    "Wide-beamed vessel",
    "Cargo vessel",
    "Garbage boat",
    "Garbage hauler",
    "Garbage hauler",
    "Garbage hauler",
    "Garbage hauler",
    "Garbage hauler",
    "Garbage boat",
    "It's got a flat bottom",
    "Refuse transportation?",
    "Boat with square ends",
    "Bulk transporter",
    "Flat-bottomed boat",
    "Boat with an open hold",
    "Boat for shallow water",
    "Flat-bottomed boat",
    "Garbage hauler",
    "Refuse transportation",
    "Punt",
    "Boat with an open hold",
    "Garbage barge",
    "Refuse transporter",
    "Flatboat",
    "Barge, e.g.",
    "Boat with an open hold",
    "Vessel with a load",
    "Garbage boat",
    "Square-ended vessel",
    "Flat-bottomed boat",
    "Garbage barge",
    "Garbage boat",
    "Garbage boat",
    "Flatboat",
    "Refuse transport",
    "Cargo vessel",
    "Flat-bottomed boat",
    "River transport",
    "Barge"
  ],
  "thai": [
    "Kind of cuisine offering tom yung goong and tom kha kai",
    "Like the ancestry of 37-Across",
    "Homophone of 35-Down",
    "Tom yung goong or tom kha kai cuisine",
    "Neighbor of a Malay",
    "Like tom yum soup",
    "Person from Bangkok",
    "Bangkok native",
    "Language with five tones",
    "From Bangkok, say",
    "Popular takeout option",
    "Cuisine featuring drunken noodles",
    "Like some spicy food",
    "Muay ___ (martial art)",
    "Bangkok native",
    "Some spicy fare",
    "Language in Bangkok",
    "Person from Bangkok",
    "Like panang curry",
    "Cuisine with tom yum soup",
    "Cuisine with tom yum soup",
    "Language of Bangkok",
    "Pad see ew cuisine",
    "Cuisine with many curries",
    "Cuisine with tom kha gai soup",
    "Language in which \"seven\" is \"jet\"",
    "Bangkok resident",
    "Bangkok native",
    "Cuisine with curry",
    "Bangkok native",
    "Indian alternative",
    "Many a Theravada Buddhist",
    "Some spicy cuisine",
    "Asian cuisine",
    "Pad ___ (noodle dish)",
    "Bangkok native",
    "Cuisine in which \"phat mama\" is a noodle dish",
    "From Bangkok",
    "Bangkok native",
    "Pad see ew cuisine",
    "Spicy ethnic food",
    "Spicy Southeast Asian cuisine",
    "Whence the word \"bong\"",
    "Cuisine with tom yum soup",
    "Spicy cuisine",
    "Cuisine with curry",
    "Its alphabet has 44 consonants",
    "Bangkok native",
    "\"Prik khing\" cuisine",
    "Cuisine featuring nam prik",
    "Spicy cuisine",
    "Bangkok citizen",
    "Language with 44 consonants",
    "Spicy Asian cuisine",
    "Cuisine with pad see ew noodles",
    "Like some spicy food",
    "Southeast Asian cuisine",
    "Cuisine that includes pad see ew",
    "Cuisine with sen yai noodles",
    "Resident near the Isthmus of Kra",
    "Asian cuisine",
    "Spicy cuisine",
    "Ang Thong resident",
    "Spicy cuisine",
    "Bangkok native",
    "Spicy Asian cuisine",
    "Eastern cuisine",
    "Like kickboxing, originally",
    "Bangkok native",
    "Ethnic cuisine",
    "Spicy cuisine",
    "Pad ___ (noodle dish)",
    "Alternative to Chinese or Indian",
    "Bangkok native",
    "Spicy cuisine",
    "King Bhumibol Adulyadej, e.g.",
    "Ethnic cuisine",
    "Like some noodle dishes",
    "Popular cuisine",
    "Asian cuisine",
    "Hot cuisine",
    "Asian tongue",
    "Spicy cuisine",
    "Siamese",
    "Asian tongue",
    "Cuisine that's big on noodles",
    "Ethnic cuisine",
    "Popular cuisine",
    "Language with mostly monosyllabic words",
    "Tangy ethnic food",
    "Spicy cuisine",
    "Chiang Mai native",
    "Spicy cuisine",
    "Spicy cuisine",
    "Neighbor of a Laotian",
    "Cambodian's neighbor",
    "Siamese, now",
    "Cuisine choice",
    "Spicy cuisine",
    "Popular cuisine",
    "Certain cuisine",
    "Bangkok native",
    "Cuisine type",
    "Language akin to Shan",
    "Popular cuisine"
  ],
  "lath": [
    "Wood strip",
    "Wood strip",
    "Wood strip",
    "Plasterwork backing",
    "Wooden strip",
    "Backing for plaster",
    "Latticework strip",
    "Shingle supporter",
    "Trellis strip",
    "Narrow wood strip",
    "Wood strip",
    "Plaster support",
    "Backing strip",
    "Wood strip",
    "Plaster backing",
    "Plasterer's strip",
    "Strip of wood in homebuilding",
    "Plaster base",
    "Plaster base",
    "Homebuilder's strip",
    "Plaster backing",
    "It gets plastered",
    "Wood strip",
    "Homebuilder's strip",
    "It may get plastered",
    "Thin wood strip",
    "Narrow strip of wood",
    "Stucco backing",
    "Latticework",
    "Builder's backing",
    "Plaster base",
    "Strip of wood",
    "Lattice strip",
    "Builder's backing",
    "Homebuilder's supply",
    "Lattice strip",
    "Thin wood strip",
    "Plaster backing"
  ],
  "seal": [
    "Circus animal balancing a ball on its nose",
    "Close tightly",
    "Follower of Navy or presidential",
    "Grown-up pup",
    "Indicator of approval",
    "Animal that \"claps\"",
    "One clapping at a circus?",
    "Close up",
    "Member of an elite team",
    "Word after Christmas or fur",
    "Conclude, as a deal",
    "Sight on a dollar bill",
    "Notary public's need",
    "Circus animal with flippers",
    "Member of an elite team",
    "Animal having a ball at the circus?",
    "It can make an impression in correspondence",
    "Circus sight",
    "Certain Navy specialist",
    "The United States has a great one",
    "It makes an impression",
    "Elite team member",
    "Clothes",
    "Pup grown up",
    "Navy commando",
    "Member of an elite Navy team",
    "One of seven in the Book of Revelation",
    "Circus performer with a ball",
    "Circus horn honker",
    "Approval indication",
    "One having a ball at the circus?",
    "[Another arrangement of the letters in the grid]",
    "Circus balancer",
    "Christmas ___",
    "Vacuum maintainer",
    "Dollar bill feature",
    "One-named singer who married Heidi Klum",
    "Ball player?",
    "Close up",
    "Signet ring feature",
    "Security feature",
    "Good Housekeeping emblem",
    "Close up",
    "See 3-Down",
    "Certain commando",
    "Bin Laden hunter",
    "Animal balancing a beachball on its nose",
    "Diploma feature",
    "Approval indicator",
    "One clapping at a circus?",
    "Caulk",
    "It makes an impression",
    "Navy commando",
    "Circus performer",
    "It's on the back of the $1 bill",
    "Christmas ___ (holiday stamp)",
    "Diploma feature",
    "Circus barker",
    "Warranty feature",
    "It leaves an impression",
    "Circus performer with a ball",
    "Caulk",
    "SeaWorld frolicker",
    "Circus trainee",
    "Animal with a pup",
    "One having a ball at the circus?",
    "Flippered mammal",
    "Use wax on",
    "Close up",
    "Performer with a balancing act",
    "One of a Navy elite",
    "One seen playing with a beachball",
    "Part of a dollar bill",
    "Stamp",
    "Shut (up)",
    "Shut up",
    "It makes an impression",
    "Envelope closer",
    "Word with state or great",
    "Eskimo's catch",
    "Navy commando",
    "Circus barker?",
    "Navy commando",
    "48-Down military group member",
    "Barker",
    "Barker",
    "Beachball balancer",
    "Member of a pod",
    "Flippered animal",
    "Feature on an official document",
    "Approval indicator",
    "It has a ball at the circus",
    "Do a caulking job",
    "Egyptian tomb feature",
    "Close tightly",
    "Circus barker",
    "The U.S. has a Great one",
    "Gummed flap",
    "Circus performer",
    "Bewhiskered circus animal",
    "Christmas ___",
    "Make airtight",
    "Good Housekeeping award",
    "Gum site",
    "Whiskered circus animal",
    "Close, as an envelope",
    "Whiskered animal",
    "Tamperer hamperer",
    "Navy diver",
    "Sea World attraction",
    "Security item",
    "Harbor___",
    "Letter closer",
    "Close",
    "Close",
    "Make airtight",
    "Holiday stamp",
    "Authenticating symbol",
    "Gummed flap",
    "Imprimatur",
    "Bewhiskered animal",
    "Tight closure",
    "Tight closure",
    "He jumps through hoops"
  ],
  "acre": [
    "Farmland measure",
    "Corn maze measure",
    "Nearly 5,000 square yards",
    "It's a plot!",
    "Good amount of land to build on",
    "Surveying unit",
    "All over hell's half ___ (everywhere)",
    "One of Yellowstone's 2.2+ million",
    "Field unit",
    "One of many for a ranch",
    "Spread unit",
    "About half of a regular soccer field",
    "One of the 40 in \"the back 40\"",
    "Unit of an estate",
    "Lot unit",
    "A lot of farmland",
    "Growing room",
    "It's a little under a football field in area",
    "Farm measure",
    "Part of a spread",
    "One of 309 in the National Mall",
    "Subdivision of a subdivision",
    "Measurement that might be a lot?",
    "Land unit",
    "Land parcel",
    "Zoning unit",
    "Plot element?",
    "Farmland measure",
    "Real estate unit",
    "Unit for surveyors",
    "Field measurement",
    "Amount of land a pair of oxen could plow in a day, historically",
    "4,046.86 square meters",
    "One chain by one furlong",
    "Israeli city that shares its name with a unit of measure",
    "Something to build on",
    "Plot at home, maybe",
    "4,840 square yards",
    "Realtor's unit",
    "1 chain x 1 furlong",
    "Parcel portion",
    "Pastoral piece?",
    "It has much room to grow",
    "A lot of land, maybe",
    "One of 100 in Winnie-the-Pooh's wood",
    "Part of a plot",
    "Part of a plot",
    "About 3/4 of a football field",
    "Israeli port",
    "Plot piece",
    "Piece of land",
    "Winnie-the-Pooh's Hundred ___ Wood",
    "One of 100 in a \"Winnie-the-Pooh\" wood",
    "Nice plot of land",
    "One of the 40 in a farmer's \"back 40\"",
    "Field fraction",
    "Bushels per ___ (farm measure)",
    "Real estate unit",
    "Lot, maybe",
    "1/640 of a square mile",
    "Unit of farmland",
    "Parcel of land",
    "Four roods",
    "It may be a plot",
    "Spread unit",
    "Plot element?",
    "Farm unit",
    "Sizable garden",
    "Israeli port",
    "Spread unit",
    "Plot element?",
    "Field unit",
    "Plot segment",
    "Back 40 unit",
    "Israeli seaport",
    "Zoning unit, maybe",
    "One of a thousand in a Jane Smiley title",
    "Farming unit",
    "It's about 90 yards of a football field",
    "Big yard area",
    "Part of a plot",
    "Plot division",
    "One of about 14,500 in Manhattan",
    "Port in the eastern Mediterranean",
    "Plot unit",
    "Plot piece",
    "Siege of ___ (opening of the Third Crusade)",
    "Field unit",
    "It might be a lot",
    "Homeland plot?",
    "Plot segment",
    "Israeli city whose citadel was built by the Ottomans",
    "Growing room?",
    "Third Crusade siege site",
    "Plot component",
    "\"God's Little ___\" (Erskine Caldwell best seller)",
    "Farm measure",
    "Field unit",
    "Plat book unit",
    "Farm unit",
    "Plot segment",
    "Small field size",
    "Fair-sized plot",
    "Fair-sized plot",
    "Decent plot",
    "Parcel part",
    "Plot unit",
    "Israeli port",
    "It may be a lot",
    "160 square rods",
    "Homestead Act unit",
    "Planting unit",
    "Plot part",
    "It could be a lot",
    "Large lawn",
    "Small field",
    "One may be under development",
    "City NE of Haifa",
    "Big lot",
    "Farming unit",
    "Fair-sized garden",
    "Farm division",
    "Farm area",
    "A lot?",
    "Plot part",
    "Spread unit",
    "Field measure",
    "One to grow on",
    "Farm measure",
    "Plot unit",
    "1/40 of \"the back 40\"",
    "Planar measure",
    "Ranch unit",
    "Farm unit",
    "Realtor's unit",
    "It can be a lot",
    "Farm division",
    "Land unit",
    "Plot part",
    "Farm unit",
    "Plot measure",
    "Plot parcel",
    "Fair-sized garden",
    "Good lot size",
    "Plot unit",
    "Farm unit",
    "Smallish field",
    "Some of a lot",
    "Big plot",
    "Decent plot",
    "It may be a lot",
    "1/640 square mile",
    "Big plot",
    "Part of a plot",
    "Many a homestead spread",
    "Part of a parcel",
    "Farm measure",
    "Part of a plot",
    "Plowing unit",
    "Good-size field",
    "One to grow on?",
    "Growing amount?",
    "One of the \"north 40\"",
    "Large yard, perhaps",
    "Plot part",
    "Small farm size",
    "God's ___ (churchyard)",
    "1/640 of a square mile",
    "Planting unit",
    "Cropped unit?",
    "Square measure",
    "Zoning minimum, maybe",
    "Part of a plot",
    "Realty measure",
    "Field measure",
    "Farm unit",
    "A lot of plot",
    "Big yard, perhaps",
    "Piece of property",
    "One of the back 40",
    "It may be divided into rows",
    "Fraction of a field",
    "It may be under development",
    "Big plot",
    "Part of a spread",
    "Forest measure",
    "Farm measure",
    "Division of a subdivision",
    "Something to build on, maybe",
    "Big garden size",
    "Fair-sized field",
    "Farmland unit",
    "Zoning measure",
    "Farm unit",
    "Big field",
    "Field unit",
    "Pasture portion",
    "Plot, perhaps",
    "Plot piece",
    "\"God's Little ___\"",
    "Land unit",
    "A lot, maybe",
    "Vineyard measure",
    "Farm unit",
    "Farm division",
    "A little of a lot?",
    "Growing room",
    "Realtor's unit",
    "Mediterranean city known anciently as Ptolemais",
    "Field size, maybe",
    "Bit of a spread",
    "Development unit",
    "One to build on?",
    "A lot of lot",
    "Farm fraction",
    "One of the \"back 40\"",
    "Estate division",
    "It's all a plot!",
    "Small field",
    "Lot of rows to hoe",
    "Small farm spread",
    "Farm unit",
    "Zoning unit, maybe",
    "Field measure",
    "Israeli seaport",
    "One to grow on?",
    "Small field",
    "4,047 square meters",
    "\"God's Little ___\"",
    "Lot of land",
    "One to grow on?",
    "Farm measure",
    "Israeli port",
    "Growing room",
    "Plot to plant",
    "Plot size",
    "Large lot",
    "Field unit",
    "Something to build on",
    "Zoning measure",
    "Farm field, maybe",
    "God's___ (churchyard)",
    "Plowing unit",
    "\"God's Little___\"",
    "Farmer's measurement",
    "It may be a lot",
    "Farm unit",
    "Estate division",
    "Good-sized plot",
    "Fair plot",
    "A lot of lot",
    "Caldwell's \"God's Little ___\"",
    "Unit of real estate",
    "Israeli port",
    "Farm unit",
    "Farming unit",
    "Israel's Bay of ___",
    "Land amount",
    "Maybe it's all a plot?",
    "Port north of Haifa",
    "This might be a lot",
    "Part of the plot?",
    "Port seized by the Crusaders, 1191",
    "Caldwell's \"God's Little ___\"",
    "Parcel of land",
    "160 square rods",
    "Farmland unit",
    "One of the back 40, perhaps",
    "Subdivision subdivision",
    "Plat portion",
    "A lot of lot",
    "Plot measure",
    "It can be a lot"
  ],
  "ursa": [
    "___ Major (the Big Dipper)",
    "Major part of the night sky?",
    "___ Major",
    "___ Major",
    "___ Minor",
    "___ Major (constellation)",
    "___ Minor",
    "___ Major",
    "Major thing in the heavens?",
    "Celestial bear",
    "Major or minor in astronomy?",
    "___ Major",
    "Celestial animal",
    "___ Major",
    "___ Minor",
    "Bear that's up at night?",
    "___ Major",
    "Celestial beast",
    "___ Major",
    "___ Major (Great Bear)",
    "___ Major (Big Dipper's constellation)",
    "___ Major",
    "___ Minor",
    "___ Minor",
    "___ Major",
    "___ Minor",
    "Major in astronomy?",
    "Minor predecessor?",
    "Bear in constellation names",
    "Connect-the-dots bear?",
    "___ Major",
    "Major figure in space?",
    "___ Major",
    "___ Major",
    "___ Minor",
    "___ Major (constellation)",
    "___ Minor",
    "Polaris bear",
    "Constellation animal",
    "Minor opening?",
    "\"Superman II\" villainess",
    "Supervillain from Krypton",
    "___ Minor",
    "Celestial beast",
    "\"Major\" beast",
    "Constellation animal",
    "___ Major (constellation)",
    "___ Minor",
    "Nocturnal bear",
    "___ Major",
    "Bear aloft?",
    "___ Minor",
    "___ Minor",
    "\"Superman II\" villainess",
    "___ Major (constellation)",
    "___ Minor",
    "___ Major",
    "Major sight in the sky?",
    "___ Major",
    "Major constellation?",
    "___ Minor",
    "Major in astronomy?",
    "Bear overhead?",
    "Bear in the sky",
    "Major in astronomy?",
    "___ Minor (constellation)",
    "\"Superman\" villainess",
    "\"Superman II\" villainess",
    "With 45-Down, home of the Big Dipper",
    "Celestial bear",
    "___ Major",
    "Bear-named villainess in Superman films",
    "Major start?",
    "Bear in constellation names",
    "\"Superman II\" villainess",
    "Celestial bear",
    "Major in astronomy?",
    "Minor leader?",
    "Stellar bear",
    "___ Minor",
    "___ Major",
    "___ Major",
    "Constellation appellation",
    "Astronomical bear",
    "___ Minor",
    "Celestial bear",
    "___ Major (constellation)",
    "___ Minor",
    "Constellation animal",
    "___ Minor",
    "___ Major",
    "\"Major\" animal",
    "___ Minor",
    "Bear to look up to",
    "___ Major",
    "Sky bear",
    "Animal in the sky",
    "___ Minor (constellation)",
    "Bear that comes out at night?",
    "___ Minor",
    "\"Major\" animal",
    "Celestial bear",
    "___ Minor",
    "\"Major\" animal",
    "With 60-Across, a group of stars",
    "Celestial bear",
    "\"Superman\" villainess",
    "___ Major",
    "___ Major",
    "___ Minor",
    "___ Major",
    "Major introduction?",
    "___ Minor",
    "___ Major",
    "___ Minor",
    "\"Major\" name",
    "___ Major",
    "Star bear",
    "___ Minor",
    "___ Major",
    "Major animal?",
    "Celestial bear",
    "Bear with us at night",
    "___ Major",
    "Constellation bear",
    "\"Major\" animal",
    "___ Minor",
    "\"Superman II\" villainess",
    "Constellation bear",
    "___ Major (Great Bear)",
    "Major in astronomy?",
    "Constellation animal",
    "Celestial bear",
    "___ Major",
    "Constellation animal",
    "___ Major",
    "___ Minor",
    "Twinkling bear",
    "Heavenly bear",
    "Polaris bear",
    "___ Major",
    "Constellation animal",
    "___Major",
    "Constellation animal",
    "___Minor",
    "___Major",
    "Bear outlined in lights",
    "___ Major",
    "Polaris bear",
    "___ Major",
    "Roman bear",
    "With 30 Down, a night sight",
    "Bear in the sky",
    "Constellation name",
    "Nocturnal bear",
    "Nocturnal bear?",
    "Name in two constellations",
    "Bear in the sky",
    "Bear up there",
    "Bear up?"
  ],
  "asea": [
    "On the ocean",
    "Offshore",
    "Befuddled",
    "Deployed, as a sailor",
    "Like castaways, before being cast away",
    "On the main",
    "Not on solid ground, say",
    "Offshore",
    "Unmoored, in a way",
    "On deck, say",
    "Far from harbor",
    "Between ports",
    "In deep water",
    "On the main",
    "Riding the waves",
    "Plying the waves",
    "On the briny",
    "Riding the waves",
    "On the ocean",
    "Far offshore",
    "Making a crossing, maybe",
    "Not at the dock, say",
    "Between ports",
    "Not shipwrecked, say",
    "On the briny",
    "Going coast to coast, maybe",
    "On the waves",
    "Traveling from coast to coast, maybe",
    "Offshore",
    "Sailing",
    "Not on terra firma, say",
    "What landlubbers don't like to be",
    "Making a crossing, e.g.",
    "Offshore",
    "On the briny",
    "On the briny",
    "On the ocean",
    "Like Edward Lear's Owl and Pussycat",
    "Away on a submarine, say",
    "Cruising",
    "On the briny",
    "Chasing Moby Dick, say",
    "Out on the ocean",
    "No longer at anchor",
    "Between ports",
    "Cruising along",
    "Offshore",
    "No longer tied up, say",
    "On the Pacific, say",
    "Where ships go",
    "Out fishing, maybe",
    "On the ocean",
    "On the ocean",
    "Not at port",
    "Between ports",
    "Cruising",
    "Between continents, say",
    "Sailing",
    "Where Melville's Billy Budd went",
    "Away from port",
    "Voyaging",
    "Lost",
    "Where sailors go",
    "On the Adriatic, say",
    "Like whale hunters, e.g.",
    "Out of port",
    "Cruising",
    "On deck, say",
    "Where sailors go",
    "Where Billy Budd went in \"Billy Budd\"",
    "See 9-Across",
    "On a cruise",
    "Sailing",
    "Living off the land?",
    "On the ocean",
    "On an ocean voyage",
    "Like many salts",
    "Researching whales, say",
    "Where sailors go",
    "Off the shore",
    "In a shell, maybe",
    "Offshore",
    "At 30?? W 30?? N, e.g.",
    "On the ocean blue",
    "Sailing",
    "Offshore",
    "Between ports",
    "Aboard a 21-Across, maybe",
    "Away from harbor",
    "Ralph Vaughan Williams's \"___ Symphony\"",
    "Where whalers go",
    "Like pirates",
    "Having given a slip the slip?",
    "Between ports",
    "On the ocean",
    "Out on the water",
    "On a cruise",
    "Serving in the navy",
    "Where pirates go",
    "Where gobs go",
    "On a cruise",
    "On the main",
    "On the briny",
    "Out with the fleet",
    "Like pirates",
    "Having shipped out",
    "Where Melville's Billy Budd went",
    "Far from land",
    "Riding the waves",
    "On the briny",
    "Between ports",
    "Out of harbor",
    "Where bluejackets go",
    "Steaming along, maybe",
    "On the briny",
    "On the briny",
    "On the deep",
    "Being tossed, maybe",
    "Not berthed",
    "Far from harbor",
    "Totally confused",
    "Between shores, maybe",
    "On deep water",
    "Not docked",
    "Sailing",
    "On the bounding main",
    "Between ports",
    "Like some pirates",
    "Off on a cruise",
    "Sailing",
    "Between ports",
    "On the ocean",
    "Out of port",
    "Voyaging",
    "Voyaging",
    "Cruising",
    "Unable to get one's feet on the ground?",
    "Well off the coast",
    "Like some hurricanes",
    "Fishing, perhaps",
    "Sailing",
    "Between ports",
    "Where ships go",
    "Off the coast",
    "Out of port",
    "Away, in a way",
    "On the ocean",
    "On the briny",
    "On the ocean",
    "Like some tars",
    "Away from land",
    "Lost",
    "On the ocean",
    "Not in port",
    "Perplexed",
    "Sailing along",
    "Not docked",
    "Like some salts",
    "On the open water",
    "On the briny",
    "Out fishing, possibly",
    "In full sail",
    "Out of port",
    "Sailing",
    "Like many a hurricane",
    "Where liners go",
    "On the bounding main",
    "Not grounded, perhaps",
    "Between ports",
    "Way off shore",
    "Sailing",
    "Toward the big waves",
    "Not docked",
    "Out of port",
    "Headed for foreign ports",
    "Cruising",
    "Lost",
    "On a brig",
    "Out of sight of shore",
    "Not ashore",
    "Riding the waves",
    "Not in port",
    "In deep water?",
    "Sailing",
    "Not docked",
    "Away from port",
    "Cruising",
    "Dazed",
    "Out of port",
    "Not docked",
    "Floating, perhaps",
    "Out of port",
    "Out of harbor",
    "Crossing the Atlantic, maybe",
    "Out of port",
    "On the main",
    "On the briny",
    "Like some storms",
    "Like many hurricanes",
    "Cruising",
    "Out of port",
    "Floating, perhaps",
    "On the briny",
    "Out of port",
    "In the Red?",
    "On a deck, perhaps",
    "Far from shore",
    "On a crossing",
    "Cruising",
    "Not in harbor",
    "Not docked",
    "Offshore",
    "On naval maneuvers",
    "Sailing",
    "Not docked",
    "Out of port",
    "On clippers?",
    "Aboard a ship",
    "In midvoyage",
    "Between 28-Down",
    "On the Aegean, say",
    "On a deck, perhaps",
    "In midvoyage, maybe",
    "Riding the waves",
    "On the main",
    "Midvoyage",
    "Out of port",
    "In a muddle",
    "Over the bounding main",
    "Perplexed",
    "Sailing",
    "On the main",
    "Between ports",
    "Fishing, perhaps",
    "Launched",
    "\"___ of troubles\": Hamlet",
    "On the briny",
    "Underway to over there",
    "Off land",
    "Using clippers?",
    "Out of port",
    "Sailing",
    "In the Black?",
    "Toward water",
    "On the main",
    "Voyaging",
    "Drifting",
    "On the briny",
    "Out of port",
    "Sailing",
    "Out of port",
    "Far from land",
    "Out of port",
    "On the briny",
    "On the briny",
    "Between ports",
    "On an ocean liner",
    "Cruising",
    "Not on land",
    "On the main",
    "On the main",
    "En route on the QE2",
    "Away from land",
    "Cruising",
    "Out of port",
    "Braving the waves",
    "Not landbound",
    "Not in port",
    "On the main",
    "On the briny",
    "On the briny",
    "Participating in 46 Across",
    "Without a clue",
    "Offshore",
    "Voyaging",
    "On the briny",
    "Cruising",
    "Cruising",
    "Perplexed",
    "On the briny",
    "On the Baltic",
    "Cruising",
    "Not on land",
    "Perplexed",
    "Drifting",
    "Drifting",
    "On the briny",
    "Riding the waves",
    "Cruising",
    "On the briny"
  ],
  "sats": [
    "College entrance exams",
    "Senior moments?",
    "800 things?",
    "College entrance exams",
    "Rites of passage for college-bound students",
    "Concerns for many srs.",
    "Topics for Kaplan",
    "Their maximum scores are 1600",
    "Scores of these may plague high schoolers",
    "Nights that high schoolers obsess over",
    "Exams for coll.-bound kids",
    "Srs.' worries",
    "They have 225-min. 34-Downs",
    "They're taken in high sch.",
    "Info on college applications",
    "Their scores may be on transcripts",
    "Exams with three sects.",
    "Their scores may accompany transcripts",
    "Hurdles for high schoolers, for short",
    "Essay questions are part of them: Abbr.",
    "All-day sch. events",
    "H.S. hurdles",
    "Some srs. take them",
    "College entrance exams",
    "Srs.' worry",
    "Challenge for high school srs.",
    "Providers of scores, for short",
    "Sr. trials",
    "Hurdles for srs.",
    "Coll. entrance exams",
    "800 exams"
  ],
  "tail": [
    "Body part that a Manx cat lacks",
    "Follow closely",
    "End part",
    "Be just behind",
    "Follow closely, as a spy might a mark",
    "Horse's \"fly swatter\"",
    "Shadow",
    "Pin the ___ on the donkey",
    "Follow",
    "Showy peacock feature",
    "Shadow",
    "Follow closely",
    "Word before (or synonymous with) end",
    "Meaty lobster part",
    "Bushy part of a squirrel",
    "Follow",
    "Shadow",
    "Shadow",
    "Follow around, as a detective might",
    "Prehensile ___",
    "Follow",
    "Shadow",
    "It's turned before bolting",
    "Dangerous part of an alligator",
    "Ape's lack",
    "Cow's fly swatter",
    "Succulent lobster piece",
    "Part that wags",
    "Homophone for 57-Down",
    "Spy, at times",
    "See 35-Across",
    "Follow",
    "End of the lion",
    "Comet part",
    "Follow",
    "Showy peacock feature",
    "Apt attachment to the ends of 30-, 37-, 59- and 62-Across",
    "Peacock's distinctive feature",
    "Shadow",
    "Following detective",
    "What you might catch a tiger by, in a saying",
    "Follower, as in espionage",
    "Head's opposite",
    "Shadow",
    "What a donkey gets at a children's party",
    "Follow closely",
    "Follow, as a suspect",
    "Detective, at times",
    "Shadow",
    "Caboose, e.g.",
    "Follow closely",
    "Comet feature",
    "Investigator, at times",
    "Kite part",
    "Detective, at times",
    "Mermaid feature",
    "Final portion",
    "Kite part",
    "Behind",
    "Shadow",
    "Peacock's pride",
    "Detective, at times",
    "It may be docked",
    "Site of the 9-Across on an old nickel",
    "Detective, at times",
    "Follow",
    "Lobster part",
    "Surveil, in a way",
    "Lobster serving",
    "Follow furtively",
    "Shadow",
    "Lobster order",
    "Dinosaur's weapon",
    "Shadow",
    "Plane section",
    "Follow closely",
    "Deer's scut",
    "Cow's flyswatter",
    "Shadow",
    "Mermaid feature",
    "Peacock's pride",
    "Party game pin-on",
    "Shadow",
    "Head's opposite",
    "Follow",
    "Flyswatter, of a sort",
    "Manx's lack",
    "Shadow",
    "Waggin' part",
    "Follow",
    "Follow",
    "Follow",
    "Kind of fin",
    "Dog, in a way",
    "Bedog",
    "Shadow"
  ],
  "sego": [
    "Western lily",
    "State flower of Utah",
    "Lily that is Utah's state flower",
    "State flower of Utah",
    "___ lily",
    "___ lily",
    "Beehive State bloomer",
    "Utah's state flower",
    "Utah's ___ Canyon (locale of petroglyphs)",
    "Kind of lily",
    "Utah's ___ Canyon",
    "State flower of Utah",
    "Western lily",
    "___ lily",
    "Bulbous perennial",
    "___ lily",
    "___ lily",
    "Western lily",
    "___ lily (Utah's state flower)",
    "One of Utah's state symbols",
    "State flower of Utah",
    "Lily with bell-shaped flowers",
    "One of Utah's state symbols",
    "___ lily",
    "___ lily",
    "Mariposa's close relative",
    "State symbol of Utah",
    "Bearer of trumpet-shaped flowers",
    "___ lily",
    "Utah's lily",
    "Western lily",
    "Flower with a bulb",
    "Utah lily",
    "Kind of lily",
    "Utah state flower",
    "Utah's ___ Canyon",
    "Relative of a mariposa",
    "Western lily",
    "___ lily",
    "Utah lily",
    "Utah's state flower",
    "Flower that's most often white",
    "Utah's ___ Canyon",
    "Bell-shaped flower",
    "Utah's state flower",
    "Bell-shaped lily",
    "___ lily (Utah's flower)",
    "Showy lily",
    "Plant with bell-shaped flowers",
    "Western lily",
    "Kind of lily",
    "Utah's flower",
    "Utah state flower",
    "Kind of lily",
    "Mariposa lily",
    "Kind of lily",
    "Deseret bloom",
    "Flower of 16-Across",
    "Western lily",
    "Utah's state flower",
    "Bell-shaped flower",
    "Utah lily",
    "Utah's state flower",
    "Utah's state flower",
    "Type of lily",
    "Utah's state flower",
    "Utah's state flower"
  ],
  "chet": [
    "___ (the Jet) Walker, Basketball Hall-of-Famer",
    "Baker who sang \"My Funny Valentine\"",
    "Suspect #4",
    "Three-time All-Star outfielder Lemon",
    "Baker in a studio",
    "Jazzman Baker",
    "Jazzman Baker",
    "1980s baseball star Lemon",
    "Lemon who played for the 1984 World Series-winning Tigers",
    "President Arthur's nickname",
    "Baker with a trumpet",
    "Old TV news partner of David",
    "N.B.A. Hall-of-Famer Walker",
    "Jazz trumpeter Baker",
    "\"Let's Get Lost\" singer Baker",
    "Baker of jazz",
    "Guitarist Atkins",
    "Jazz trumpeter Baker",
    "Lemon on a baseball field",
    "Newsman Huntley",
    "Newsman Huntley",
    "Guitarist Atkins",
    "\"Good-night, ___\" (old TV sign-off)",
    "Guitarist Atkins",
    "Atkins of country music",
    "Guitarist Atkins",
    "The Hardy Boys' sidekick",
    "Huntley of 50's-60's NBC news",
    "Hardy Boys' pal",
    "Jazz trumpeter Baker",
    "Newsman Huntley",
    "Jazz trumpeter Baker",
    "Jazzman Baker",
    "\"Hardy Boys\" character",
    "Atkins or Huntley",
    "\"Good night, ___\" (old TV sign-off)",
    "\"Good night, ___\" (old TV phrase)",
    "\"Good night ___\" (old TV sign-off)",
    "Mr. Huntley"
  ],
  "cram": [
    "Study, study, study",
    "Squeeze",
    "Do some last-minute studying",
    "Quickly learn one's lesson?",
    "Do some fast data processing?",
    "Pull an all-nighter",
    "Study, study, study",
    "Make final preparations?",
    "Finally hit the books",
    "Study all night, say",
    "Overstuff",
    "Pack (in)",
    "Be a very fast learner?",
    "Learn fast, say",
    "Do a semester's worth of studying in one night, say",
    "Stay up all night before a test, say",
    "Learn the hard way?",
    "Learn fast, maybe",
    "Study hard at the last minute",
    "Squeeze (in)",
    "Fill by force",
    "Learn a lot quickly",
    "Study just before a test",
    "Learn a lot, say",
    "Study hard and fast",
    "Stuff",
    "Study all night, say",
    "Study hard",
    "Jampack",
    "Jam",
    "Burn the midnight oil, student-style",
    "Pull an all-nighter",
    "Make final preparations?",
    "Jam-pack",
    "Study hard",
    "Study, study, study",
    "Pull an all-nighter",
    "Hit the books hard",
    "Stuff (in)",
    "Pack",
    "Pull an all-nighter",
    "Study, and then some",
    "Crowd",
    "Study for finals",
    "Study late",
    "Pull an all-nighter",
    "Stuff",
    "Study for finals",
    "Jam",
    "Stuff",
    "Pack in",
    "Pull an all-nighter",
    "Put in an all-nighter",
    "Stuff (in)",
    "Stuff",
    "Learn in a hurry",
    "Study for finals",
    "Pack in",
    "Stuff",
    "Study feverishly",
    "Stuff",
    "Pull an all-nighter"
  ],
  "ashe": [
    "Tennis great Arthur",
    "1975 Wimbledon champ",
    "Stadium in the Billie Jean King National Tennis Center",
    "Tennis great posthumously awarded the Presidential Medal of Freedom",
    "North Carolina county near the Tennessee border",
    "Arthur who wrote \"Days of Grace\"",
    "Tennis great Arthur",
    "He was named 1992's Sportsman of the Year, despite retiring from tennis 12 years earlier",
    "1975 Wimbledon winner",
    "World's largest tennis stadium, familiarly",
    "Court legend Arthur",
    "ESPN's Arthur ___ Courage Award",
    "Tennis legend Arthur",
    "He beat Connors in the Wimbledon final in 1975",
    "First African-American Davis Cup player",
    "Tennis's Arthur ___ Stadium",
    "Eponym of a U.S. Open stadium",
    "1975 Wimbledon champ",
    "1980s U.S. Davis Cup team captain",
    "Arthur with a stadium named after him",
    "\"Days of Grace\" memoirist Arthur",
    "Queens stadium eponym",
    "Eponym of the world's largest tennis stadium",
    "Arthur of tennis fame",
    "Queens's Arthur ___ Stadium",
    "Eponym of the Courage Award given at the ESPYs",
    "Lead-in to \"-ville\"",
    "1970 Australian Open winner",
    "Longtime athlete on the U.S. Davis Cup team",
    "Name on the ESPY Courage Award",
    "Trailblazing athlete of the 1970s",
    "North Carolina county ... or lead-in to \"-ville\"",
    "Queens stadium name",
    "Arthur with a namesake stadium",
    "1975 Wimbledon winner",
    "Athlete honored on Richmond's Monument Avenue",
    "Tennis legend Arthur",
    "Athlete posthumously awarded a Presidential Medal of Freedom",
    "Queens stadium name",
    "Court legend Arthur",
    "Eponym of a North Carolina city",
    "Queens's ___ Stadium",
    "Tennis star Arthur",
    "Name attached to a North Carolina \"-ville\"",
    "Court giant Arthur",
    "Tennis player who posthumously received a Presidential Medal of Freedom",
    "Tennis legend Arthur",
    "Court legend",
    "Sports great with the 1993 memoir \"Days of Grace\"",
    "Arthur who was king of the court?",
    "Court legend Arthur",
    "Arthur of tennis",
    "Arthur of tennis",
    "Queens stadium eponym",
    "Tennis great Arthur",
    "Arthur of tennis",
    "National Junior Tennis League co-founder",
    "26-Across of a North Carolina \"-ville\"",
    "Tennis legend Arthur",
    "New York's ___ Stadium",
    "King Arthur of tennis",
    "Athlete who wrote \"A Hard Road to Glory\"",
    "Eponym of a Southern \"-ville\"",
    "Tennis's Arthur ___ Stadium",
    "1980s U.S. Davis Cup captain",
    "Subject of four Sports Illustrated covers between 1966 and 1993",
    "Queens's ___ Stadium",
    "Borg rival",
    "___ Stadium (facility near Citi Field)",
    "He got a tennis scholarship from U.C.L.A.",
    "1970s Wimbledon victor over Connors",
    "1968 winner of the 43-Down",
    "ESPN's Arthur ___ Courage Award",
    "7/5/75 winner over Connors",
    "Queens stadium name",
    "Arthur who often raised a racket",
    "___ Stadium (Big Apple tennis locale)",
    "___ Stadium, sports venue since 1997",
    "Athlete who wrote \"Off the Court\"",
    "Tennis's Arthur",
    "Athlete who posthumously won the Presidential Medal of Freedom, 1993",
    "1975 Wimbledon winner",
    "Arthur in the International Tennis Hall of Fame",
    "Arthur with a Queens stadium named after him",
    "New York stadium eponym",
    "1968 U.S. Open champ",
    "1975 Wimbledon winner",
    "\"Off the Court\" autobiographer",
    "Queens's ___ Stadium",
    "Court legend",
    "U.S. tennis legend on a 37?ó stamp",
    "North Carolina county",
    "U.S. Open's ___ Stadium",
    "Arthur with a racket",
    "1975 Wimbledon winner",
    "Tennis's Arthur",
    "Queens tennis stadium",
    "Tennis champ Arthur",
    "Arthur who wrote \"A Hard Road to Glory\"",
    "Only African-American male to win Wimbledon",
    "North Carolina county",
    "Queens's ___ Stadium",
    "Former Davis Cup captain Arthur",
    "Eponym of a North Carolina \"-ville\"",
    "Arthur of tennis",
    "New York stadium name",
    "Champ just before 36-Down",
    "Tennis great Arthur",
    "Gentleman of the court",
    "\"Off the Court\" autobiographer",
    "Queens's ___ Stadium",
    "Tennis's Arthur",
    "\"But, ___ was ambitious, I slew him\": Brutus",
    "1968 U.S. Open winner",
    "___ Stadium, sports venue since 1997",
    "___ Stadium in Queens",
    "___ Stadium, home of the U.S. Open",
    "___ Stadium (Queens landmark)",
    "See 46-Across",
    "\"But, ___ was ambitious, I slew him\": Brutus",
    "\"Off the Court\" writer",
    "Contemporary of Rosewall",
    "___ Stadium in Queens",
    "\"Off the Court\" autobiographer",
    "North Carolina county named for an early governor",
    "Arthur of the court",
    "Contemporary of Emerson",
    "1975 Wimbledon winner",
    "Arthur ___ Stadium in Queens",
    "New York's ___ Stadium",
    "Tennis's Arthur",
    "Court great",
    "Queens's ___ Stadium",
    "North Carolina county",
    "Queens's ___ Stadium",
    "Court legend",
    "Connors contemporary",
    "Arthur ___ Stadium",
    "Tennis's Arthur",
    "Court great Arthur",
    "New York's ___ Stadium",
    "\"A Hard Road to Glory\" writer",
    "New York stadium name",
    "Courtly Arthur",
    "Queens's ___ Stadium",
    "Gentleman of the court",
    "1980's U.S. Davis Cup captain",
    "Tennis's Arthur",
    "1968 champion at Forest Hills",
    "Queens stadium name",
    "North Carolina county",
    "\"Hard Road to Glory\" author",
    "New York stadium name",
    "Tennis great Arthur",
    "1980's Davis Cup captain",
    "Connors contemporary",
    "Stadium near Shea",
    "Queens stadium name",
    "New York City stadium name",
    "1968 U.S. Open champion",
    "Tennis great Arthur",
    "He beat Connors to win Wimbledon",
    "Governor for whom a North Carolina city is named",
    "\"A Hard Road to Glory\" author",
    "King Arthur of the courts",
    "1975 Wimbledon champ",
    "Flushing Meadow stadium name",
    "'75 Wimbledon champ",
    "Gentleman of the court",
    "1975 Wimbledon champ",
    "North Carolina county named for a Revolutionary War commander",
    "Queens's ___ Stadium",
    "Wimbledon winner, 1975",
    "1967 U.S. clay court champion",
    "1975 Wimbledon winner",
    "Onetime Davis Cup coach",
    "Queens's ___ Stadium",
    "Tennis legend Arthur",
    "Arthur ___ Stadium (U.S.T.A. facility)",
    "Commander at Briar Creek, in the Revolutionary War",
    "Onetime Davis Cup coach",
    "\"Hard Road to Glory\" author",
    "Revolutionary War commander John",
    "1968 U.S. Open champ",
    "1975 Wimbledon winner",
    "Former Davis Cup coach",
    "New York stadium name",
    "U.S. Open stadium name",
    "North Carolina county on the Blue Ridge Parkway",
    "Gentleman of the court",
    "Tennis champ who played for U.C.L.A.",
    "Tennis's Arthur",
    "Former Davis Cup captain",
    "Athlete with a statue in Richmond, Va.",
    "1972 U.S. Open finalist",
    "Tennis great Arthur",
    "\"A Hard Road to Glory\" athlete-author",
    "Connors opponent",
    "\"As Long ___ Needs Me\" (\"Oliver!\" song)",
    "Connors defeater, 1975",
    "Arthur___",
    "Author on mythology Geoffrey___",
    "Late tennis V.I.P.",
    "Tennis name of fame",
    "Late tennis V.I.P.",
    "Tennis's Arthur",
    "1968 U.S. Open winner",
    "Late tennis V.I.P. Arthur",
    "Late tennis V.I.P.",
    "1968 U.S. Open winner",
    "Court star Arthur",
    "Tennis great Arthur",
    "Tennis V.I.P. Arthur",
    "Foe of Laver and Newcombe",
    "Arthur of Wimbledon",
    "Ace-serving Arthur",
    "\"As Long ___ Needs Me\" (\"Oliver!\" song)",
    "Connors rival",
    "North Carolina county",
    "Late tennis V.I.P.",
    "Netman Arthur",
    "Contemporary of 22 Down",
    "Court hero",
    "Late tennis V.I.P.",
    "\"Hard Road to Glory\" writer",
    "Wimbledon winner, 1975",
    "Late tennis V.I.P.",
    "Late apartheid opponent",
    "Late tennis great",
    "Court king Arthur",
    "Netman Arthur",
    "Tennis's Arthur",
    "\"Days of Grace\" author",
    "Late opponent of apartheid",
    "Late tennis great",
    "\"Off the Court\" author",
    "Court V.I.P. Arthur",
    "1975 Wimbledon champ",
    "Late tennis V.I.P."
  ],
  "stye": [
    "Eyelid affliction",
    "Reason to see an ophthalmologist",
    "Eye problem",
    "Affliction also known as a hordeolum",
    "Ocular woe",
    "Lump near a lash",
    "Diagnosis from an ophthalmologist",
    "Ocular affliction",
    "Eye affliction",
    "Bump on a lid",
    "Concern for an ophthalmologist",
    "Eye woe",
    "Bad eye sight?",
    "Lid bump",
    "Possible reason to forgo mascara",
    "Ocular infection",
    "Eyelid ailment",
    "Reason to see an ophthalmologist",
    "Ocular affliction",
    "Lid irritant",
    "Eye sore",
    "Eye affliction",
    "Eye woe",
    "Ophthalmological ailment",
    "Ocular malady",
    "Eye sore",
    "Eye-opening problem?",
    "Irritating subject for an ophthalmologist?",
    "Eye irritation",
    "Hot compress target, perhaps",
    "Eye inflammation",
    "Ophthalmologist's concern",
    "Bad eye sight",
    "Ocular irritation",
    "Eyelid annoyance",
    "Eye affliction",
    "Extraocular annoyance",
    "Eye woe",
    "Reason for an ophthalmologist's visit",
    "Ocular ailment",
    "Ocular malady",
    "Eyelid woe",
    "Eye affliction",
    "Case for an ophthalmologist",
    "Affliction whose name rhymes with its location",
    "Eyelid malady",
    "Eye woe",
    "Eye irritation",
    "61-Across affliction",
    "Eyelid affliction",
    "Eye annoyance",
    "Staph-caused irritation",
    "Trouble with a lid?",
    "Lid problem",
    "Eye problem",
    "Ophthalmic swelling",
    "Eye woe",
    "Eye problem",
    "Eye sore",
    "Eyelid annoyance",
    "Ophthalmologist's concern",
    "Eyelid woe",
    "Certain infection",
    "Problem with a sebaceous gland",
    "Eye affliction",
    "Lid irritation",
    "Eyelid woe",
    "Ocular woe",
    "Eye doctor's concern",
    "Eyelid woe",
    "Ophthalmologist's concern",
    "Eyelid woe",
    "Eye doctor's concern",
    "Lid trouble",
    "Eye sore",
    "Lid irritation",
    "Eyelid annoyance",
    "Eyelid woe",
    "Eye sore",
    "Case for an ophthalmologist",
    "Ocular woe",
    "Site for sore eyes?",
    "Eye ailment",
    "It's found on a lid",
    "Eye sore",
    "Eye woe",
    "Lid annoyance",
    "Eye problem",
    "Eye problem",
    "Eyelid problem",
    "Ocular problem",
    "Eye malady",
    "Sebaceous gland woe",
    "Eyelid infection",
    "Eye sore",
    "Infection site",
    "Eyelid woe",
    "Ophthalmic development",
    "Lid swelling",
    "Eye affliction",
    "Ophthalmology case",
    "Eye sore",
    "Seeing trouble",
    "Eyelid swelling",
    "Eye problem",
    "47-Across, literally",
    "Eye sore",
    "Eyelid problem",
    "Eye problem",
    "Ophthalmological case",
    "Eye-opening problem",
    "Palpebral swelling",
    "Case for an ophthalmologist",
    "Red-eye cause",
    "Eye inflammation",
    "Eye problem",
    "Eye sore",
    "Ocular woe",
    "Eyelid problem",
    "Eye affliction",
    "Ophthalmologist's case",
    "Eye problem",
    "Eye problem",
    "Item in a pig's eye?",
    "Eye problem"
  ],
  "toad": [
    "Warty fly-catcher",
    "Resident of Nintendo's Mushroom Kingdom",
    "___-in-the-hole (British dish)",
    "Ally in Super Mario games",
    "Little croaker",
    "___ Hall (\"The Wind in the Willows\" residence)",
    "Warty critter",
    "\"Mr.\" in \"The Wind in the Willows\"",
    "Pride : lion :: knot : ___",
    "Loathsome person",
    "Its scientific name is Bufo bufo",
    "Super Mario Bros. character with a mushroom head",
    "Relative of a frog",
    "Disgusting sort",
    "Mr. with a \"Wild Ride\" at Disneyland",
    "Warty creature",
    "Contemptible sort",
    "Frog and ___ (friends in a children's book series)",
    "\"The Wind in the Willows\" creature",
    "Prey for a heron or garter snake",
    "Amphibian that doesn't really cause warts",
    "Vile sort",
    "Mr. ___ of \"The Wind in the Willows\"",
    "Repugnant sort",
    "*Prey for a garter snake",
    "Despicable one",
    "Horned ___ (certain lizard)",
    "Warty creature",
    "Ugly one",
    "Relative of a frog",
    "Goose : gaggle :: ___ : knot",
    "Contemptible one",
    "Pod : whale :: knot : ___",
    "Goose : gaggle :: ___ : knot",
    "Lowly sort",
    "Ugly one",
    "Relative of a frog",
    "Creature known scientifically as Bufo bufo",
    "\"The Wind in the Willows\" hopper",
    "*Natter, e.g.",
    "Fly-catching creature",
    "Repulsive sort",
    "Warty hopper",
    "Herd : horse :: knot : ___",
    "Disgusting one",
    "Detestable one",
    "Fly catcher",
    "Lowly one",
    "Wart cause, in folklore",
    "Its eye may be part of a witch's brew",
    "Moneybags in \"The Wind in the Willows\"",
    "Loathsome sort",
    "Relative of a frog",
    "Mr. ___ of \"The Wind in the Willows\"",
    "Wart causer, in legend",
    "Odious one",
    "Prey for a hognose snake",
    "Kenneth Grahame's ___ Hall",
    "Hopper",
    "Herpetologist's catch",
    "Repulsive one",
    "Land hopper",
    "Cousin of a frog",
    "Little hopper",
    "Natterjack",
    "Loathsome person",
    "Horse : herd :: ___ : knot",
    "Little hopper",
    "Warty hopper",
    "Warty hopper",
    "It takes grubs for grub",
    "Hopper",
    "Loathsome one",
    "Pad sitter",
    "Contemptible one",
    "Despicable sort",
    "Natterjack",
    "Hopper",
    "Slimeball",
    "\"The Wind in the Willows\" character",
    "Woodland creature",
    "Lowly one",
    "Sticky-tongued critter",
    "Warty-skinned critter",
    "\"The Wind in the Willows\" character",
    "Warty hopper",
    "Wart-covered creature",
    "\"The Wind in the Willows\" character",
    "Repellent one",
    "Loathsome one",
    "Little hopper",
    "Hopper",
    "Gaggle:goose::knot:___",
    "Kenneth Grahame character",
    "\"The Wind in the Willows\" critter",
    "Object of disgust",
    "\"The Wind in the Willows' character",
    "Miniature hopper",
    "Horned ___ (lizard)",
    "Mr. ___ of ?ôThe Wind in the Willows?ö",
    "Little hopper",
    "Tailless amphibian",
    "\"The Wind in the Willows\" character",
    "Wart giver, in old wives' tales"
  ],
  "helm": [
    "Captain's post",
    "Captain's place",
    "Captain's place on a ship",
    "Captain's position",
    "Captain's spot",
    "Steering position",
    "Lead",
    "Bridge position",
    "Position of command",
    "Lead",
    "Place for a big wheel",
    "Ahab's post",
    "Commander's place",
    "Place of control",
    "Skipper's position",
    "Captain's spot",
    "Captain's post",
    "Captain's place",
    "Command",
    "Captain's place",
    "Position of authority",
    "25-Across part",
    "Leadership position",
    "Command post on a ship",
    "Captain's place on a ship",
    "Captain's place",
    "Captain's position",
    "Place for a captain",
    "Bridge site",
    "Command position",
    "Control spot",
    "Control post",
    "Big wheel at sea",
    "Command's site",
    "Captain's position",
    "Navigator's position",
    "Controls",
    "Command spot",
    "Pilot's spot",
    "Quartermaster's post",
    "Control spot",
    "Control post",
    "Site of a ship's controls",
    "Captaincy",
    "Driver's seat"
  ],
  "toke": [
    "Puff from a pipe or cigarette",
    "Hit taken willingly",
    "Quick hit",
    "Puff, perhaps",
    "Drawing of grass?",
    "Drag",
    "Take a hit",
    "It's a drag",
    "Take a hit",
    "Joint action",
    "What can be a real drag?",
    "Take a hit",
    "It's a drag",
    "Quick hit",
    "Puff on a joint",
    "Blunt hit",
    "Joint action",
    "Puff from a joint",
    "Puff",
    "Draw, in a way",
    "Hit, of a sort",
    "Puff",
    "Drag on a cigarette",
    "Hit",
    "Drag",
    "Drag",
    "Use a 47-Down",
    "Strong puff",
    "Cigarette puff",
    "It's a drag",
    "Puff"
  ],
  "ovid": [
    "\"Ars Amatoria\" poet",
    "Poet who said \"Let others praise ancient times. I am glad I was born in these\"",
    "\"Ars Amatoria\" author",
    "Poet exiled by Augustus",
    "Who wrote \"Poetry comes fine-spun from a mind at peace\"",
    "\"Ars Amatoria\" writer",
    "\"Metamorphoses\" poet",
    "Poet who wrote \"Jupiter from on high laughs at lovers' perjuries\"",
    "\"Ars Amatoria\" poet",
    "\"Heroides\" poet",
    "Roman poet who wrote \"Ars Amatoria\"",
    "\"Metamorphoses\" poet",
    "\"Venus favors the bold\" writer",
    "Poet who wrote of Daedalus",
    "Poet who wrote \"Fortune and love favor the brave\"",
    "He wrote \"The sharp thorn often produces delicate roses\"",
    "\"Ars Amatoria\" poet",
    "Poet who wrote \"If you want to be loved, be lovable\"",
    "He wrote \"Venus favors the bold\"",
    "Poet who wrote \"Jupiter from on high laughs at lovers' perjuries\"",
    "Virgil contemporary",
    "Golden Age poet",
    "Roman poet who wrote about 33-Across",
    "Writer of the 644-line poem \"Ibis\"",
    "\"Amores\" poet",
    "Poet who wrote \"If you want to be loved, be lovable\"",
    "\"Metamorphoses\" poet",
    "Poet banished in A.D. 8",
    "J. M. W. Turner's \"___ Banished From Rome\"",
    "\"Time, the devourer of all things\" writer",
    "\"Ars Amatoria\" writer",
    "He wrote \"Jupiter from on high laughs at lovers' perjuries\"",
    "Poet banished by the emperor Augustus",
    "Roman poet who wrote \"To be loved, be lovable\"",
    "\"Amores\" poet",
    "\"Ars Amatoria\" poet",
    "Poet depicted in art alongside the Scythians",
    "Roman author of \"Metamorphoses\"",
    "Virgil contemporary",
    "Poet exiled by Augustus",
    "Roman love poet",
    "One of his lost works is \"Medea\"",
    "Roman poet banished by Augustus",
    "Roman poet who wrote the \"Metamorphoses\"",
    "Horace contemporary",
    "Poet who wrote \"At night there is no such thing as an ugly woman\"",
    "Contemporary of Virgil",
    "Golden Age poet",
    "Golden Age writer",
    "\"Ars Amatoria\" poet",
    "\"Metamorphoses\" poet",
    "\"Metamorphoses\" author",
    "\"Ars Amatoria\" poet",
    "?ôMetamorphoses?ö poet",
    "\"Tristia\" poet",
    "\"The Art of Love\" poet",
    "\"Metamorphoses\" author",
    "\"Tristia\" poet",
    "\"Tempus edax rerum\" writer",
    "\"Metamorphoses\" poet",
    "\"Ars amatoria\" poet",
    "Exiled Latin poet",
    "Exiled Roman poet",
    "\"Tristia\" poet",
    "Exile of A.D. 8",
    "\"Art of Love\" poet",
    "\"Tristia\" poet",
    "\"Ars amatoria\" poet",
    "\"Ars Amatoria\" poet",
    "\"The Art of Love\" poet",
    "\"Time the devourer of all things\" writer",
    "\"Heroides\" writer",
    "Roman poet",
    "\"Ars Amatoria\" poet",
    "\"The Art of Love\" author",
    "\"Ars Amatoria\" author",
    "Amatory poet",
    "First-century poet",
    "\"Tristia\" poet",
    "\"Amores\" poet",
    "Horace contemporary",
    "\"The Art of Love\" poet",
    "\"Ars Amatoria\" author",
    "\"Ars Amatoria\" author",
    "\"Metamorphoses\" poet",
    "He wrote \"To be loved, be lovable\""
  ],
  "octa": [
    "Twice tetra-",
    "Half of hexadeca-",
    "Multiple of tetra-",
    "Prefix with -gon",
    "Numerical prefix",
    "Numerical prefix",
    "Eight: Prefix",
    "Geometric prefix",
    "Twice tetra-",
    "Numerical prefix",
    "Twice tetra-",
    "Numerical prefix",
    "Numerical prefix",
    "Numerical prefix",
    "Twice tetra-",
    "Twice tetra-",
    "Numerical prefix",
    "Twice tetra-",
    "Numerical prefix",
    "Prefix with -gon",
    "Eight: Prefix",
    "Numerical prefix",
    "Two times tetra-",
    "Numerical prefix",
    "Prefix with -valent",
    "Quadri- times two",
    "Tetra- doubled",
    "Eight: Prefix",
    "Eight: Prefix",
    "Eight: Prefix",
    "Prefix with -gon",
    "Numerical prefix",
    "Numerical prefix",
    "Prefix with -hedron",
    "Prefix with -gon",
    "Eight: Prefix",
    "Numerical prefix",
    "Eight: Prefix",
    "Numerical prefix",
    "Prefix with meter",
    "Prefix with -gon",
    "Prefix with -hedron",
    "Twice tetra-",
    "Penta plus three",
    "Numerical prefix",
    "Prefix with -gon",
    "Septi- plus one",
    "Prefix with -hedron",
    "Numerical prefix",
    "Eight: Prefix"
  ],
  "spin": [
    "Whirl or twirl",
    "Type of angular momentum, in physics",
    "Go around",
    "Attempt to control the narrative, in a way",
    "Quick drive",
    "\"Wheel of Fortune\" play",
    "Certain break-dancing move",
    "Turn in a popular game show",
    "It keeps a top up",
    "Property of a subatomic particle",
    "Take a turn",
    "Whirl",
    "\"Wheel of Fortune\" option",
    "English",
    "Slant",
    "Short drive",
    "What planets do on their axes",
    "What a pool shark puts on a ball",
    "What the earth and many political analysts do",
    "___ room (postdebate area)",
    "Prepare to pin the tail on the donkey, say",
    "P.R. task",
    "Top motion",
    "Go round and round",
    "___ room (site of postdebate political commentary)",
    "Quick drive",
    "P.R. firm's job",
    "Music magazine founded by Bob Guccione Jr.",
    "Option on \"Wheel of Fortune\"",
    "Whirl",
    "Part of a publicity agent's job",
    "Prepare, as cotton candy",
    "Do some political damage control",
    "Some talk on political talk shows",
    "\"Wheel of Fortune\" action",
    "Roulette action",
    "Suffer vertigo",
    "Turn in many a kids' game",
    "Washer cycle",
    "Electron property",
    "Turn in many a children's game",
    "Quick drive",
    "Alternative to \"roll the dice\"",
    "Turn in many a children's board game",
    "Twirl ... or a cryptic hint to 20-, 36- and 51-Across?",
    "With 22-Across, prepares to put on the line",
    "Board game turn",
    "Washer or dryer action",
    "\"Wheel of Fortune\" action",
    "English",
    "Short ride",
    "Pirouette",
    "Turn at roulette",
    "Something to take in a car",
    "D.J.'s offering, informally",
    "\"Wheel of Fortune\" option",
    "Quick trip",
    "Political slant",
    "Self-serving slant",
    "\"Wheel of Fortune\" turn",
    "Public relations effort",
    "Board game turn",
    "\"Wheel of Fortune\" choice",
    "Washer cycle",
    "Popular music magazine",
    "Wash or dry cycle",
    "Showcase lead-in on \"The Price Is Right\"",
    "Whirl",
    "Kind of doctor",
    "Interpretation",
    "Fast eddy",
    "Try at roulette",
    "Quick trip",
    "Test drive",
    "Quick trip",
    "Turn in many a children's game",
    "Slant",
    "English",
    "Twirl",
    "It may be on a tennis ball",
    "\"Wheel of Fortune\" choice",
    "Specialty of some doctors",
    "Fabricate",
    "Wash cycle",
    "Interpretation",
    "Public relations effort",
    "Quick drive",
    "English",
    "English",
    "Gyrate",
    "Twirl",
    "Kind of doctor"
  ],
  "blts": [
    "Three-ingredient sandwiches, for short",
    "Club alternatives",
    "Alternatives to melts, maybe",
    "Nonkosher sandwiches, for short",
    "Short orders to a short-order cook?",
    "Nonkosher lunch orders, briefly",
    "Nonkosher deli orders",
    "Crunchy diner orders",
    "Diner purchases, for short",
    "Club cousins",
    "Counter orders?",
    "Usually toasted sandwiches, for short",
    "Short-order orders",
    "Alternatives to clubs",
    "Counter orders",
    "Layered lunch orders",
    "Nonkosher sandwiches",
    "Sandwiches with toothpicks",
    "Nonkosher lunch orders, for short",
    "Lunch counter orders",
    "Kin of clubs",
    "Counter orders",
    "Nonvegetarian sandwiches, informally",
    "They're listed near clubs",
    "Food orders sometimes prepared in toaster ovens",
    "Sandwiches that are definitely not kosher, in brief",
    "Their outsides are usually toasted",
    "Lunch orders that are typically sliced in half",
    "Nonkosher diner offerings",
    "Short orders?",
    "Nonkosher sandwiches",
    "They're made in short order",
    "Nonkosher sandwiches",
    "They may be stuck with toothpicks",
    "Some lunch orders",
    "Some diner orders",
    "Some diner orders",
    "Some deli orders, briefly",
    "Nonkosher sandwiches",
    "Toasted sandwiches, for short",
    "Diner orders",
    "Short orders",
    "They're toasted at luncheons"
  ],
  "popo": [
    "Law officers, in slang",
    "Law enforcement, slangily",
    "Coppers",
    "Cops, in slang",
    "Cops, slangily",
    "Law enforcers, in slang",
    "Law force, slangily",
    "Law enforcers, slangily",
    "Cops, in slang"
  ],
  "stlo": [
    "Normandy battle site in W.W. II",
    "Battle of the Hedgerows locale",
    "Strategic objective soon after the D-Day invasion",
    "Normandy battle site",
    "Normandy invasion town",
    "1944 battle locale",
    "France's so-called \"Capital of the Ruins\"",
    "Normandy battle site",
    "Town almost destroyed in the D-Day invasion",
    "Battle of Normandy town",
    "1944 battle site",
    "Scene of W.W. II fighting",
    "W.W. II invasion site",
    "Capital of France's Manche department",
    "D-Day invasion town",
    "D-Day objective",
    "Town in '44 headlines",
    "French town in '44 news",
    "Battle of Normandy site",
    "French town fortified by Charlemagne",
    "W.W. II battle city",
    "W.W. II battle site",
    "Normandy battle site",
    "Normandy battle town",
    "Normandy battle site",
    "Historic town on the Vire",
    "Battle of Normandy town",
    "Battle of Normandy site",
    "Battle town of 1944",
    "French town in W.W. II fighting",
    "Town in W.W. II headlines",
    "The Vire River flows through it",
    "Manche department capital",
    "French town of W.W. II",
    "1944 battle site",
    "Normandy town in W.W. II fighting",
    "Normandy invasion town",
    "Capital of Manche, France",
    "Site of July 1944 fighting",
    "Target of heavy W.W. II bombing, 1944",
    "Normandy town",
    "French town on the Vire",
    "Capital of Manche",
    "French town of W.W. II",
    "Manche's capital",
    "W.W. II city on the Vire",
    "Town near Caen",
    "1944 battle site",
    "Capital of Manche, France",
    "Site of July 1944 fighting",
    "D-Day campaign town",
    "Town nearly destroyed in 1944",
    "Normandy town",
    "Capital of Manche",
    "Normandy invasion town",
    "W.W. II site",
    "Historic Normandy town",
    "Historic town on the Vire",
    "July 1944 battle site",
    "July 1944 battle site",
    "W.W. II battle town",
    "D-Day invasion town",
    "Normandy battle site",
    "July 1944 attack site",
    "City in July 1944 news",
    "W.W. II battle site",
    "Normandy town",
    "Manche's capital",
    "1944 E.T.O. battleground",
    "July 1944 battle area",
    "Manche capital",
    "Allied jumping-off point of July 1944",
    "1944 battle site",
    "Town on the Vire",
    "Normandy battle site",
    "Battleground of July 1944",
    "Normandy battle site",
    "Manche department's capital",
    "Town near Caen",
    "Town on the Vire",
    "Allied victory site of 7/18/44",
    "Site of a 1944 Allies breakthrough",
    "D-Day invasion town",
    "Breakthrough battle in Normandy",
    "W.W. II battle site",
    "1944 battle site",
    "Town on the Vire",
    "City on the Vire",
    "Normandy battle site",
    "Normandy battle site",
    "Historic Normandy city",
    "Capital of Manche",
    "Manche capital",
    "Battle of Normandy objective",
    "E.T.O. battle town",
    "German communications center in W.W. II",
    "Town liberated in July 1944",
    "Town of July 1944 fighting",
    "Battle site soon after D-Day",
    "Normandy campaign town, 1944",
    "Normandy campaign town",
    "Normandy battle site",
    "French city in W.W. II fighting",
    "Capital of Manche",
    "Manche capital",
    "Normandy campaign town",
    "Battle site of 1944",
    "Allied victory site, 7/18/44",
    "Normandy invasion town, 1944",
    "Town near Caen",
    "Town near Caen",
    "Manche capital",
    "W.W. II battle site",
    "Capital of Manche"
  ],
  "peat": [
    "Fuel from a bog",
    "Bog fuel",
    "Organic fuel",
    "Resource from a bog",
    "Natural flavorer of Scotch",
    "Bog fuel",
    "Fuel common in Scotland",
    "Soil additive",
    "Everglades deposit",
    "Content of a bog",
    "Scotch flavorer",
    "Contents of a bog",
    "Mulching material",
    "Three follower, in sports",
    "Fuel from a fen",
    "Bog fuel",
    "It lends a smoky flavor to Scotch",
    "Bog fuel",
    "Fertilizer ingredient",
    "___ moss (gardening purchase)",
    "___ moss",
    "Bog buildup",
    "Bog product",
    "Starting material in coal formation",
    "Fuel from bogs",
    "Bog fuel",
    "Mulching matter",
    "Bog material",
    "Three-___",
    "Everglades deposit",
    "Scotch flavorer",
    "Mulching material",
    "___ moss",
    "Fuel from a bog",
    "___ moss",
    "Everglades deposit",
    "Vegetable matter",
    "Fertilizer choice",
    "Kind of moss",
    "Vegetable fuel",
    "Marsh growth",
    "Marsh material",
    "___ moss",
    "Kind of moss",
    "Bog stuff",
    "Dry fuel",
    "Swamp thing",
    "Everglades deposit",
    "Organic fuel",
    "Fuel from a marsh",
    "Kind of bog",
    "Fuel that's burned",
    "Old-fashioned fuel",
    "Charcoal component"
  ],
  "spat": [
    "Tiff",
    "Small row",
    "Finished brushing one's teeth, say",
    "Dust-up",
    "Altercation",
    "Contretemps",
    "Tiff",
    "Minor dust-up",
    "Squabble",
    "Verbal tussle",
    "Small quarrel",
    "Small argument",
    "Set-to",
    "Dust-up",
    "Petty disagreement",
    "Dustup",
    "Small scrap",
    "Row",
    "Dustup",
    "Scrap",
    "Insignificant row",
    "Cross words",
    "Row",
    "Lovers' row",
    "Rhubarb",
    "Lovers' quarrel",
    "Small scene",
    "Tiff",
    "Minor blowup",
    "Squabble",
    "Run-in",
    "Minor quarrel",
    "Little argument",
    "Got rid of some tobacco juices, say",
    "Short row",
    "Scrap",
    "Tiff",
    "It may cause a scene",
    "Little argument",
    "Small argument",
    "Dustup",
    "Petty quarrel",
    "Showed disdain, in a way",
    "Petty dispute",
    "Lovers' scrap",
    "Got rid of a chaw",
    "Petty quarrel",
    "Cross words",
    "Public scene cause",
    "Tiff",
    "Tiff",
    "Blurted (out)",
    "Tiff",
    "Run-in",
    "Set-to",
    "Clash",
    "Cross words",
    "Squabble",
    "Blowup",
    "Quarrel",
    "Cross words",
    "Tiff",
    "Row",
    "Squabble",
    "Small fight",
    "Run-in",
    "Lover's quarrel",
    "Petty quarrel",
    "Quarrel",
    "Difference of opinion",
    "Tiff",
    "Tiff",
    "Uttered contemptuously",
    "Cross words?",
    "Hardly a brawl",
    "Tiff",
    "Row",
    "Small fight",
    "Falling-out",
    "Have words, so to speak",
    "Squabble",
    "Tiff",
    "Petty quarrel",
    "Quarrel",
    "Went for the cuspidor",
    "To-do",
    "Quarrel",
    "Conflict",
    "Scrap",
    "Bickering",
    "Uttered with contempt",
    "Little row",
    "Scrap",
    "Bit of a tiff",
    "Tiff",
    "Exchange of words",
    "Showed disdain",
    "Bad exchange",
    "Flap",
    "Little row",
    "Quarrel",
    "Young oyster",
    "Quarrel",
    "Scrap",
    "Tiff",
    "Young oyster",
    "Wrangle",
    "Word fight",
    "Tiff",
    "Tiff",
    "Tiff",
    "Showed contempt",
    "Minor dispute",
    "Angry to-do",
    "Til",
    "Shoe coverer"
  ],
  "hula": [
    "Kind of skirt",
    "Do a waving motion by the ocean, say",
    "Dance traditionally performed to tell a story",
    "Hip-centric dance",
    "Polynesian performance",
    "Dance that men often do shirtless",
    "Luau performance",
    "Luau entertainment",
    "Activity for which you need a fair amount of wiggle room",
    "Dance with a lot of hip movement",
    "Kind of skirt",
    "Luau dance",
    "Dance with percussion accompaniment",
    "Dance in a festive skirt",
    "Hawaiian dance",
    "___-Hoop (toy)",
    "Dance with strong percussion",
    "Shakers' movement?",
    "Dance at a 52-Down",
    "Luau dance",
    "Kind of skirt",
    "Hip dance",
    "___ skirt",
    "Sinuous dance",
    "___ Bowl (former sporting event)",
    "___ Hoop",
    "Luau dance",
    "___ Bowl",
    "Hip-shaking dance",
    "Storytelling dance",
    "Wahine's dance",
    "Hawaii's annual ___ Bowl",
    "Dance with a wiggle",
    "Activity near a 1-Across",
    "Island dance",
    "Dance with a wiggle",
    "Dance accompanied by a gourd drum",
    "Dance with rhythmic drumming",
    "___ Bowl (postseason game)",
    "Kind of skirt",
    "Waikiki wiggles",
    "___ Bowl",
    "Wahine's dance",
    "Hip activity?",
    "Kind of skirt",
    "Luau treat",
    "Island wiggle",
    "Maui dance",
    "Belly-shaking dance",
    "Outdoor party entertainment",
    "Dance in a grass skirt",
    "*Hawaii ... grass skirts ... Bowl",
    "Waikiki wiggle",
    "Hippy entertainment",
    "Hippy movement?",
    "A whole lot of shaking going on",
    "Waikiki wiggle",
    "Molokai show",
    "Oahu dance",
    "Something to do in a skirt",
    "Kind of skirt",
    "Kind of skirt",
    "Island dance",
    "Dance done in grass skirts",
    "Dance for a luau",
    "Luau entertainment",
    "Kind of skirt",
    "Sinuous dance",
    "Wahine's dance",
    "Work off some poi, perhaps",
    "One of the Bowls",
    "Hawaiian dance",
    "Dance performed in a grass skirt",
    "Storytelling dance",
    "Dance to chants",
    "Hip-shaking in Kauai"
  ],
  "item": [
    "Tabloid twosome",
    "See 71-Down",
    "What's on the agenda",
    "New couple, in a gossip column",
    "Romantic couple",
    "Thingy",
    "Fashionable pair",
    "Two of Us?",
    "Piece of news",
    "Two people in People, maybe",
    "Part of an agenda",
    "Twosome on TMZ",
    "One thing ... or a twosome",
    "Subject of gossip",
    "Article",
    "Unit in a shopping cart",
    "One of 10 or fewer, maybe, in a checkout lane",
    "Brangelina, at one time",
    "Couple that might be snapped by paparazzi",
    "Two stars, maybe",
    "Hot couple",
    "Agenda entry",
    "Couple",
    "Unit in an online order cart",
    "Twosome on TMZ",
    "Grocery list component",
    "Two in the news",
    "Part of a list with bullets",
    "Amazon unit",
    "Twosome",
    "Follower of a bullet",
    "Thingamajig",
    "Unit in an online cart",
    "Hot ___",
    "Agenda particular",
    "Bullet point",
    "Subject for gossips",
    "Brangelina, once",
    "See 31-Down",
    "Two stars, perhaps",
    "List component",
    "Couple, in a gossip column",
    "Paragraph in the newspaper, say",
    "Tabloid pair",
    "Twosome on TMZ, e.g.",
    "Thing",
    "Couple",
    "Tabloid twosome",
    "Couple",
    "Collector's ___",
    "Agenda listing",
    "One of 10, say, in a supermarket's express checkout lane",
    "Two people in People, maybe",
    "List entry",
    "Twosome in the news",
    "Thingy",
    "One of 10, say, in an express checkout lane",
    "Twosome",
    "Part of an agenda",
    "Thing",
    "Agenda part",
    "List component",
    "Pair",
    "Couple of stars, say",
    "Thing",
    "Agenda part",
    "List component",
    "Listing on eBay",
    "Bullet follower",
    "Gossip column subject",
    "Object",
    "News clipping",
    "Bit of news",
    "Couple in a gossip column",
    "Detail",
    "Agenda part",
    "Couple in the news",
    "Thingy",
    "Collector's ___",
    "Particular",
    "Thing",
    "Two who smooch, say",
    "Gossip subject",
    "TMZ twosome",
    "Line ___",
    "Subject for gossip",
    "Tabloid couple",
    "Tabloids twosome",
    "Couple on a gossip page",
    "Thing",
    "Agenda part",
    "Twosome",
    "Detail",
    "News clipping",
    "News tidbit",
    "Line-___ veto",
    "Short news piece",
    "Bullet point",
    "See 62-Down",
    "Part of an agenda",
    "Hot pair",
    "Line-___ veto",
    "News bit",
    "Particular",
    "Big-ticket ___",
    "Inventory unit",
    "Talked-about twosome",
    "Gossip's subject",
    "List unit",
    "Couple",
    "Agenda addition",
    "Couple in the news",
    "Talked-about twosome",
    "Tabloid twosome",
    "Thingum",
    "Part of a list",
    "Particular",
    "Twosome",
    "Topic of gossip",
    "Any thing",
    "He and she in a gossip column",
    "Two in the news",
    "News clipping",
    "Twosome",
    "Point",
    "Detail",
    "Bullet point",
    "Particular",
    "Any thing",
    "Point",
    "Couple",
    "Particular",
    "News bit",
    "News bit",
    "One on a list",
    "Doohickey",
    "Particular",
    "One counted in an express checkout",
    "Bullet accompanier",
    "Point",
    "Couple of the week, e.g.",
    "Gossip tidbit",
    "Agenda unit",
    "Pair",
    "Matter to be discussed",
    "Couple",
    "Agenda part",
    "Squib, say",
    "List part",
    "One in an express checkout count",
    "Tabloid tidbit",
    "Couple in a gossip column",
    "Particular",
    "Gossip topic",
    "Particular",
    "Some thing",
    "Couple in a column",
    "Agendum, e.g.",
    "Particular",
    "Checklist bit",
    "Particular",
    "Twosome",
    "Thingamabob",
    "News bit",
    "Part of a list",
    "Thingy",
    "Tabloid twosome",
    "Piece of news",
    "Squib",
    "Gossip tidbit",
    "News bit",
    "Tabloid twosome",
    "Line-___ veto",
    "Twosome in the gossip columns",
    "Whatsis",
    "Topic of gossip",
    "Big-ticket ___",
    "One-inch news story",
    "Particular",
    "Any thing",
    "Bit of gossip",
    "Detail",
    "News bit",
    "Point",
    "Thingy",
    "Particular",
    "Part of a list",
    "Object of gossip",
    "Twosome",
    "Checklist part",
    "Columnist's squib",
    "Particular",
    "Twosome",
    "Twosome",
    "Agendum",
    "Detail",
    "List component",
    "News bit",
    "Column couple",
    "Bit of gossip",
    "List member",
    "Thing",
    "Gossip tidbit",
    "News squib",
    "Twosome",
    "News paragraph",
    "It's on the agenda",
    "Particular",
    "Twosome",
    "Paragraph",
    "Agenda part",
    "Gossip bit",
    "Particular",
    "Detail",
    "News bit",
    "Topic of gossip",
    "Society note",
    "News bit",
    "People tidbit",
    "Entry",
    "News bit",
    "Particular",
    "Detail",
    "News bit",
    "News piece",
    "Gossip column squib",
    "Dating stars, perhaps",
    "Inventory unit",
    "Agenda listing",
    "Gossip bit",
    "Newsy bit",
    "Gossip-column snippet",
    "Particular",
    "News entry"
  ],
  "stew": [
    "Lose sleep (over)",
    "Hasenpfeffer, e.g.",
    "Meal cooked in a Crock-Pot",
    "Hearty bowlful",
    "Olla podrida, for one",
    "Fret",
    "Slow-cooked dish",
    "Anxiously worry",
    "Worry",
    "Dish from a crockpot",
    "Fretful thing to be in",
    "Just sit there being mad",
    "Lose sleep (over)",
    "Be agitated",
    "Hearty dish",
    "This-and-that dish",
    "See 19-Across",
    "Fret (over)",
    "Burgoo, e.g.",
    "Ratatouille, e.g.",
    "Lose sleep (over)",
    "Mishmash",
    "Goulash, e.g.",
    "Burgoo or callaloo",
    "Fricassee, for example",
    "Hearty entree",
    "Brood",
    "Contents of a slow cooker",
    "Worry",
    "Tizzy",
    "Meat-and-vegetables dish",
    "Fret (over)",
    "Mutton ___",
    "Tizzy",
    "Meat, potato and vegetable dish",
    "Olla, e.g.",
    "Something cooked in a slow cooker",
    "Seethe",
    "Dish with a little of this and a little of that",
    "Meat-and-vegetables dish",
    "Worry, worry, worry",
    "Fuss",
    "Dish that may be ladled",
    "Brood",
    "Fret",
    "Slow-cooked dish",
    "Fret",
    "Be unsettled",
    "Beefy entree",
    "Bouillabaisse, e.g.",
    "Fret",
    "Meat-and-potatoes dish",
    "Worry",
    "Oyster ___",
    "Ratatouille or ragout",
    "Dish simmered in a pot",
    "Crock pot dinner",
    "Worry (over)",
    "Irish ___",
    "Fuss",
    "Stay up nights, say",
    "One-dish meal",
    "Dither",
    "This-and-that dish",
    "Hasenpfeffer, for one",
    "Good winter entree",
    "Pot-au-feu",
    "Brood (over)",
    "Entree eaten with a spoon",
    "Brood",
    "This-and-that dish",
    "Stay up nights",
    "Slow-cooked meal",
    "One-dish meal",
    "Fret",
    "Dish served with a big spoon",
    "Be anxious",
    "Be hot and bothered",
    "Hot pot",
    "Think things over and over",
    "Slow-boil",
    "Do a slow boil",
    "Meal in a pot",
    "Potpourri",
    "Slow-cook",
    "Hasenpfeffer, e.g.",
    "Dish cooked in a pot",
    "Some of this and some of that",
    "Endlessly worry",
    "77-Down, for one",
    "Goulash",
    "Fret",
    "\"Little of this, little of that\" dish",
    "\"Some of this, some of that\" dish",
    "Fret",
    "Ragout or burgoo",
    "Fret",
    "Dither",
    "One-dish meal",
    "Curry, e.g.",
    "Be worried (over)",
    "Brood",
    "Hot pot or pepper pot",
    "Slow-cook",
    "Hot pot",
    "Bouillabaisse",
    "One-dish meal",
    "Beef ___",
    "Hearty dinner",
    "This-and-that dish",
    "Dish that sticks to your ribs",
    "Fret",
    "Act the worrywart",
    "Lather",
    "This-and-that dish",
    "Meat-and-vegetables fare",
    "Dither",
    "Cook, as tomatoes",
    "Fret",
    "Think obsessively",
    "Lose sleep over something",
    "Do a slow burn",
    "Fret",
    "Ragout or burgoo",
    "Cook slowly",
    "Hodgepodge",
    "Cabin attendant, once",
    "Worry",
    "Bouillabaisse, e.g.",
    "Ragout",
    "Rabbit dish",
    "Cold weather meal",
    "Crockpot concoction",
    "Agonize (over)",
    "Fret",
    "Jambalaya, for one",
    "Brunswick, e.g.",
    "Flight attendant, slangily",
    "Fuss",
    "Goulash",
    "Boiled repast",
    "Onetime flight attendant, slangily",
    "Mulligan, for one",
    "Brood",
    "Odds and ends"
  ],
  "ibet": [
    "\"Su-u-ure\"",
    "\"Yeah, right!\"",
    "\"Yeah, right!\"",
    "\"Su-u-u-ure\"",
    "\"Yeah, sure!\"",
    "\"Ri-i-i-ight!\"",
    "Sarcastic comeback",
    "\"Yeah, sure\"",
    "\"A likely story!\"",
    "\"Like that'll ever happen\"",
    "\"Yeah, right!\"",
    "\"Yeah, sure!\"",
    "\"Yeah, sure\"",
    "Skeptic's sarcastic comment",
    "Sarcastic response",
    "\"Yeah, right!\"",
    "Skeptical comeback",
    "\"Likely story!\"",
    "\"Yeah, right\"",
    "\"Yeah, right!\"",
    "\"Fat chance!\"",
    "\"Su-u-u-ure it is\"",
    "Cynical rejoinder",
    "Sarcastic response",
    "\"A likely story!\"",
    "\"Uh-huh, of course\"",
    "\"Uh-huh, sure it is\"",
    "Scoffing reply",
    "[snort]",
    "\"Yeah, right\"",
    "\"Oh yeah ... uh-huh\"",
    "\"Fat chance\"",
    "\"Yeah, whatever you say\"",
    "Skeptic's reply",
    "Cynical reply",
    "\"Fat chance!\"",
    "Sarcastic retort",
    "Sarcastic \"Sure you can!\"",
    "\"Yeah, right!\"",
    "Sarcastic reply",
    "\"Uh-huh, sure\"",
    "\"Yeah, yeah\"",
    "\"Su-u-ure\"",
    "\"That'll be the day!\"",
    "\"Like that'll ever happen\"",
    "\"Yeah, that'll happen\"",
    "\"Yeah, sure ...\"",
    "\"Yeah, right\"",
    "Sarcastic reply",
    "Scoffing comment",
    "\"Yeah, sure\"",
    "Skeptic's rejoinder",
    "\"That doesn't surprise me!\"",
    "\"Uh-huh, sure it is\"",
    "\"Yeah, right!\"",
    "\"Yeah, sure\"",
    "\"Ri-i-ight!\"",
    "Sarcastic exclamation",
    "\"Uh-huh\"",
    "Skeptical rejoinder",
    "Sarcastic reply",
    "\"Uh-huh\"",
    "\"Yeah, sure\"",
    "\"Yeah, right!\"",
    "\"Su-u-ure\"",
    "\"Ha!\"",
    "Disbeliever's comment",
    "Response of disbelief",
    "\"Yeah, sure!\"",
    "\"Yeah, right\"",
    "\"Oh, sure!\"",
    "Cynic's comment",
    "Sarcastic reply",
    "\"Yeah, sure!\"",
    "\"Not very likely!\"",
    "\"Ri-i-ight\"",
    "Sarcastic comment",
    "\"Uh-huh\"",
    "\"Oh yeah, uh-huh!\"",
    "\"That'll be the day!\"",
    "\"Yeah, sure\"",
    "Cynical comment",
    "\"Oh, sure!\"",
    "Skeptic's scoff",
    "Doubter's scoff",
    "\"Yeah, right!\"",
    "Sarcastic reply to an assurance",
    "\"Oh, sure!\"",
    "\"Oh, sure\"",
    "Skeptic's remark",
    "Skeptic's scoff",
    "\"Suuure!\"",
    "Scoffing retort",
    "Skeptical words",
    "\"Yeah, right!\"",
    "Skeptic's comment",
    "\"Yeah, sure!\"",
    "Sarcastic response",
    "\"Yeah, sure\"",
    "\"Oh, sure!\"",
    "\"Yeah, sure!\"",
    "\"Oh, right!\"",
    "Cynic's retort",
    "\"Oh, sure\"",
    "\"Yeah, sure\"",
    "\"Yeah, sure!\""
  ],
  "suit": [
    "\"___ yourself\"",
    "Look attractive on",
    "Fit",
    "Firm decision maker?",
    "Job for a lawyer ... or outfit for a lawyer",
    "Something not worn on casual Friday",
    "Befit",
    "Hearts, but not minds",
    "Clubs, e.g. ... or entry requirement for some clubs",
    "Exec",
    "\"___ yourself\"",
    "Hearts, e.g.",
    "Courtroom wear ... or concern",
    "Attire not usually seen on casual Friday",
    "Diamonds, e.g.",
    "Swords or cups, in tarot",
    "One of a bridge foursome",
    "Hearts or clubs",
    "Zoot ___",
    "Rare sight on casual Friday",
    "Diamonds, e.g.",
    "Diamonds, e.g.",
    "Corporate bigwig",
    "Corporate type",
    "[See blurb]",
    "Sunday best, e.g.",
    "Any trump",
    "Dressy attire for a man",
    "Spades, for example",
    "What the 13 circled things in this puzzle constitute",
    "Clubs, e.g.",
    "Clubs or hearts",
    "Barron's reader, slangily",
    "Exec",
    "Office wear",
    "Clubs or hearts",
    "It may be strong or long",
    "Spades or clubs",
    "Business attire",
    "Something to follow",
    "Exec",
    "Exec, slangily",
    "Legal action",
    "Three-piece apparel",
    "Diamonds, e.g.",
    "Quarter-deck?",
    "Men's business wear",
    "It must be followed",
    "Diamonds or spades",
    "Word with monkey or minor",
    "Diamonds, e.g.",
    "Business attire",
    "Sometimes it must be followed",
    "Diamonds, e.g.",
    "Please",
    "Something to follow",
    "Prove acceptable to"
  ],
  "dune": [
    "First winner of the Nebula Award for best science fiction novel (1965)",
    "Hill on a beach",
    "First winner of the Nebula Award for Best Novel, 1965",
    "Hill on a beach",
    "Beach hill",
    "Buggy area",
    "Buggy locale",
    "Sand ridge",
    "Seashore feature",
    "Beach hill",
    "Sand hill",
    "Impermanent hill",
    "Hill with a \"slip face\"",
    "Sci-fi novel made into a 1984 cult film",
    "Sight near a lagoon",
    "Buggy locale?",
    "Buggy place?",
    "Hill you might drive a buggy over",
    "Rise by the shore",
    "Classic 1965 novel set on the planet Arrakis",
    "Rise by the shore",
    "Natural flood protection",
    "Coastal high spot",
    "Sand hill",
    "Herbert sci-fi classic",
    "See 9-Across",
    "Sandy slope",
    "Buggy terrain",
    "Wind-swept hill",
    "View from an oasis",
    "Seaside feature",
    "Desert sight",
    "Buggy terrain",
    "Frank Herbert novel",
    "High point at the shore",
    "Frank Herbert sci-fi novel",
    "Frank Herbert book series",
    "Sand formation",
    "Frank Herbert saga",
    "Cape Cod sight"
  ],
  "yogi": [
    "One might bend over backward",
    "One who studies asanas and pranayama",
    "One bending over backward",
    "Eastern mystic",
    "Name before Berra or Bear",
    "Nirvana seeker",
    "Headache for Ranger Smith",
    "Meditation leader",
    "Buddha, for one",
    "One holding many positions",
    "Wise guy",
    "Eastern mystic",
    "One seeking enlightenment",
    "First name in Jellystone Park",
    "Meditative sort",
    "Person holding many positions",
    "Meditative sort",
    "Nirvana seeker",
    "Lead-in to Bear or Berra",
    "Quotable Hall-of-Famer, informally",
    "___ Bear",
    "Hanna-Barbera bear",
    "Nirvana seeker",
    "He's flexible",
    "Cartoon bear",
    "Indian mystic",
    "Bear or Berra",
    "One who meditates",
    "Quotable Yank",
    "Jellystone Park denizen",
    "Mystical person",
    "Wise one",
    "Oft-quoted athlete",
    "Jellystone Park resident",
    "One who sits cross-legged, maybe",
    "Yankee nickname",
    "First name in the 40's-50's Yankees",
    "Cooperstown nickname",
    "Contemplative sort",
    "One in an awkward position?",
    "Meditator",
    "Cartoon bear",
    "Jellystone Park bear",
    "Berra or Bear",
    "Ascetic",
    "Boo Boo's buddy",
    "Leg crosser",
    "Cartoon bear",
    "Jellystone bear",
    "Hindu ascetic",
    "Quotable catcher"
  ],
  "riot": [
    "Laugh and a half",
    "Hilarious person",
    "Real cutup",
    "With 51-Across, something to \"read\"",
    "Laugh ___ (something very funny)",
    "Hilarious sort",
    "Laughfest",
    "Hoot",
    "Nonstop joker",
    "___ shield",
    "Total hoot",
    "Gas",
    "Thigh-slapper",
    "Laughfest",
    "Prison uprising",
    "Real scream",
    "Very funny person",
    "Real comedian",
    "Hilarious person",
    "Hoot",
    "Hoot",
    "Revolting scene",
    "Comedy club hit",
    "Hoot",
    "Go wild in the streets",
    "See 57-Across",
    "\"You're a regular ___!\" (Kramden cry)",
    "Thigh-slapper",
    "What revolting people do?",
    "Anarchic action",
    "Disorderly conduct",
    "Uproar",
    "\"Language of the unheard,\" per Martin Luther King Jr.",
    "Read the ___ act",
    "___ gear",
    "Laughfest",
    "Top card?",
    "Real card",
    "Real joker",
    "Pussy ___ (Russian girl group)",
    "Mob gone wild",
    "Uprising",
    "Stitch",
    "With 33-Down, plastic shields and such",
    "Uproariously funny sort",
    "Scream, so to speak",
    "___ Act",
    "Hilarious one",
    "Cause for calling in the National Guard",
    "See 13-Across",
    "Big brawl",
    "Anarchy",
    "Hysterically funny sort",
    "Tear-gassing cause",
    "Bottle-throwing occasion",
    "Brilliant display",
    "Mr. Hilarious",
    "Real comedian",
    "Go hog wild",
    "Run amok",
    "Real cutup",
    "Real knee-slapper",
    "Laugh ___",
    "Fray",
    "First-rate stand-up comic",
    "Barrel of laughs",
    "Ran-tan",
    "Hoot",
    "Real joker",
    "Mob scene",
    "Hilarious act",
    "Big melee",
    "Comedy standout",
    "Laugh-a-minute sort",
    "Comic who kills",
    "Potential lockdown preceder",
    "Event involving burning and looting",
    "Hilariously funny thing",
    "Barrel of laughs",
    "Emulate a mob",
    "Sidesplitter",
    "Laugh-a-minute",
    "Real sidesplitter",
    "Laughfest",
    "Hilarious happening",
    "Scream",
    "Brilliant display",
    "Scream",
    "___ of color",
    "Sidesplitter",
    "Protest gone bad",
    "Laugh-a-minute type",
    "Prison unrest",
    "One who's just too funny",
    "Go wild",
    "Some mayhem",
    "Uprising",
    "Successful comic",
    "Wild time",
    "Gasser",
    "Knee-slapper",
    "Hilarious one",
    "Run amok",
    "Knee-slapper",
    "Barrel of laughs",
    "Very funny person",
    "Card",
    "Unruly event",
    "Urban disturbance",
    "Laughfest",
    "Laughfest",
    "Situation for rubber bullets",
    "Sidesplitter",
    "Side-splitter",
    "Sidesplitter",
    "Laugh ___",
    "Urban unrest",
    "Sidesplitter",
    "Scream",
    "Knee-slapper",
    "Comedy hit",
    "Laugh ___",
    "Quite a comedy",
    "Good comedian",
    "Scream",
    "Laughfest",
    "Lots of laughs",
    "Quite a card",
    "Brawl",
    "Uprising at Attica",
    "Celebration that gets out of hand",
    "Funny one",
    "Warden's fear",
    "Profusion",
    "Hilarious comedian",
    "Run amok",
    "Civil uprising",
    "Very funny one",
    "Funny one",
    "Donnybrook",
    "Protest that gets out of hand",
    "Uproar",
    "Real howler",
    "Hit at Catch a Rising Star",
    "Civil disorder",
    "Run amok",
    "Very funny fellow",
    "Laugh ___",
    "Laugh-a-minute",
    "Urban unrest",
    "Really funny person",
    "Supreme comedy",
    "Haymarket Square event",
    "Card at a party",
    "Melee",
    "Battle with the police",
    "Mob scene",
    "Haymarket Square happening",
    "Side-splitting comedy",
    "Haymarket Square event",
    "Run ___",
    "Laugh-a-minute",
    "Kind of squad",
    "Major flare-up"
  ],
  "shaw": [
    "Playwright who wrote \"Those who cannot change their minds cannot change anything\"",
    "Playwright who wrote \"All great truths begin as blasphemies\"",
    "\"Major Barbara\" playwright",
    "Title partner of Hobbs in a hit 2019 film",
    "\"Saint Joan\" playwright",
    "Playwright who wrote \"Walk! Not bloody likely. I am going in a taxi\"",
    "Eliza Doolittle's creator",
    "Who wrote \"All great truths begin as blasphemies\"",
    "\"Man and Superman\" playwright",
    "Playwright who wrote \"Hell is full of musical amateurs\"",
    "\"Arms and the Man\" playwright",
    "Only Literature Nobelist also to win an Oscar",
    "Creator of Eliza Doolittle",
    "\"Pygmalion\" playwright",
    "\"Man and Superman\" playwright",
    "Bernard formerly of CNN",
    "The 1999 comedy \"She's All That\" is based on his work",
    "1925 Literature Nobelist",
    "Clarinetist Artie",
    "\"Fanny's First Play\" playwright",
    "Only man to win both a Nobel Prize and an Oscar",
    "Who wrote \"Hell is full of musical amateurs\"",
    "Henry Higgins's creator",
    "\"Rich Man, Poor Man\" novelist, 1970",
    "Irwin who wrote \"Rich Man, Poor Man\"",
    "\"Saint Joan\" playwright",
    "\"Saint Joan\" playwright",
    "\"Major Barbara\" playwright",
    "Newsman Bernard",
    "\"Pygmalion\" playwright",
    "Writer of \"Saint Joan\"",
    "\"My Fair Lady\" is based on his work",
    "Clarinetist Artie",
    "Three-time Indy winner Wilbur ___, who introduced the crash helmet",
    "\"Man and Superman\" playwright",
    "\"Pygmalion\" playwright",
    "Clarinetist Artie",
    "\"Bury the Dead\" playwright",
    "\"Mrs. Warren's Profession\" writer",
    "\"Rich Man, Poor Man\" novelist",
    "Clarinetist Artie",
    "\"Begin the Beguine\" bandleader",
    "\"Pygmalion\" dramatist",
    "\"Pygmalion\" writer",
    "\"Candida\" playwright",
    "CNN anchor Bernard",
    "\"Heartbreak House\" writer",
    "\"Heartbreak House\" writer",
    "\"Heartbreak House\" playwright",
    "Major Barbara's creator",
    "Newsman Bernard",
    "\"Arms and the Man\" playwright",
    "\"Saint Joan\" playwright",
    "Author Irwin",
    "\"Candida\" playwright",
    "\"Major Barbara\" playwright",
    "\"Pygmalion\" author"
  ],
  "etre": [
    "Raison d'___",
    "Raison d'___",
    "Raison d'___",
    "French 101 verb",
    "To be: Fr.",
    "Verb with a circumflex",
    "\"Suis\" is part of its conjugation",
    "French 101 verb",
    "To be where people sing of amour?",
    "Verb conjugated suis, es, est, etc.",
    "Raison d'___",
    "Peut-___ (maybe: Fr.)",
    "Word repeated in \"___ ou ne pas ___\"",
    "Raison d'___",
    "Being, to Sartre",
    "Raison d'___",
    "To be, overseas",
    "To be abroad?",
    "French \"to be\"",
    "Raison d'___",
    "French 101 verb",
    "To be, to Bizet",
    "French 101 verb",
    "___ humain (person: Fr.)",
    "Ser : Spanish :: ___ : French",
    "Parisian being",
    "\"Seriez\" is a form of it",
    "Raison d'___",
    "French 101 verb",
    "French \"to be\"",
    "Ser : Spanish :: ___ : French",
    "Verb from which \"suis\" and \"sommes\" are conjugated",
    "To be, in Toulouse",
    "French \"to be\"",
    "French \"to be\"",
    "Sein : German :: ___ : French",
    "Raison d'___",
    "Sein : German :: ___ : French",
    "Raison d'___",
    "Peut-___ (perhaps: Fr.)",
    "Being abroad",
    "French 101 verb",
    "French soliloquy starter?",
    "To be, to Balzac",
    "\"Fumes\" is a form of it",
    "To be, to Beatrice",
    "\"Suis\" is part of its conjugation",
    "To be, in Quebec",
    "To be, to Beatrice",
    "Ser, across the Pyrenees",
    "To be in Paris?",
    "To be very far away?",
    "Being, in Bordeaux",
    "To be, to Bernadette",
    "French word with a circumflex",
    "To be, to Bernadette",
    "French 101 verb",
    "French verb with a circumflex",
    "To be in France?",
    "To be, in Tours",
    "To be, at the Louvre",
    "To live, to Levy",
    "French \"to be\"",
    "To be overseas",
    "To be, in France",
    "\"Fusses\" is a form of it",
    "To be, to Brigitte",
    "Raison d'___",
    "Soyons is a form of it",
    "To be in a faraway land",
    "Sartre's \"L'___ et le Neant\"",
    "To be, to Henri",
    "French 101 verb",
    "Raison d'___",
    "To be abroad",
    "Being abroad",
    "Peut-___ (maybe, in Marseille)",
    "To be abroad",
    "To be overseas",
    "Parisian \"to be\"",
    "Raison d'___",
    "To be, in France",
    "Avignon infinitive",
    "To be, in Brest",
    "Being abroad",
    "Raison d'___",
    "\"To be\" to Henri",
    "To be in Paree",
    "\"To be,\" in Tours",
    "Raison d'___",
    "Verb origin of suis and sont",
    "To be over there?",
    "To be, to Henri",
    "To be, overseas",
    "To be, in Toulon",
    "Peut-___",
    "Raison d'___",
    "To be, to Henri",
    "Raison d'___",
    "French 101 infinitive",
    "To be, in France",
    "Issy infinitive",
    "Raison d'___",
    "Raison d'__",
    "To be, in Brest",
    "Sartre's being",
    "Raison d'___",
    "To be, in Toulon",
    "To be, to Gigi",
    "Raison d'___",
    "Raison d'___",
    "To be overseas",
    "Raison d'___",
    "Bien-___ (well-being): Fr.",
    "Raison d'___",
    "Raison d'___",
    "Raison d'___",
    "French 101 verb",
    "Vichy verb",
    "French 101 verb",
    "To be, in Tours",
    "To be, to Bernadette",
    "To be in Paree",
    "Word repeated in a French Hamlet's soliloquy",
    "French 101 verb",
    "French 101 verb",
    "French 101 word",
    "To be abroad",
    "Basic French verb",
    "Word repeated by a French Hamlet",
    "French 101 verb",
    "To be, at the Sorbonne",
    "Arles auxiliary",
    "French 101 word",
    "To be, in Toulouse",
    "Bien-___ (well-being): Fr.",
    "Sartre's \"L'___ et le Neant\"",
    "French 101 verb",
    "French auxiliary",
    "To be, at the Sorbonne",
    "French 101 verb",
    "To be, in Brest",
    "To be, in Bordeaux",
    "Word repeated in a French Hamlet's soliloquy",
    "French 101 verb",
    "Raison d'___",
    "Infinitive with a circumflex",
    "French 101 verb",
    "Peut-___ (French for \"maybe\")",
    "Peut-___ (maybe, in Marseille)",
    "French 101 verb",
    "French 101 verb",
    "Basic French verb",
    "Peut-___ (maybe): Fr.",
    "Basic French verb",
    "To be, in Toulouse",
    "French 101 verb",
    "Raison d'___",
    "Raison d?Æ___",
    "Peut-___ (perhaps): Fr.",
    "Peut-___ (perhaps): Fr.",
    "To be, in Toulon",
    "Raison d'___",
    "To be, in Toulouse",
    "Peut-___ (maybe, in France)",
    "Raison d'___",
    "French 101 infinitive",
    "Raison d'___",
    "To be, in France",
    "To be, in Bordeaux",
    "Raison d'___",
    "To be, in Bordeaux",
    "Basic French verb",
    "To be, in Tours",
    "French 101 verb",
    "To be, in Picardy",
    "French 101 verb",
    "French 101 verb",
    "To be, to Henri",
    "To be, in Paris",
    "To be, in Paris",
    "Tours to be?",
    "French 101 verb",
    "To be, in Brest",
    "To be, in old New Orleans",
    "To be, in Toulon",
    "To be, in Brest",
    "Bien-___ (well-being): Fr.",
    "French 101 verb",
    "French 101 verb",
    "To be, to Bernadette",
    "French 101 verb",
    "To be, to Satie",
    "Tours to be",
    "French 101 verb",
    "To be, in Paris",
    "To be, in Paris",
    "To be, to Balzac",
    "To be, to Babette",
    "To be, to Satie",
    "To be, to Henri",
    "To be, in Paree",
    "To be, abroad",
    "French 101 word",
    "To be, to Henri",
    "Raison d' ___",
    "To be, in Paree"
  ],
  "code": [
    "Do a certain developer's job",
    "Veiled language",
    "Lines in an application?",
    "A wartime communication might be sent in it",
    "Secret message",
    "Do some programming",
    "Reading block?",
    "Secret language",
    "\"Inner-city\" for \"black,\" to some people",
    "What's often debugged",
    "\"Longtime companion\" for \"same-sex partner,\" once",
    "Word that can be followed by any of several colors",
    "Up to ___",
    "Semaphore signals, e.g.",
    "See 46-Down",
    "Do some computer programming",
    "Software basis",
    "Do programming work",
    "Programmer's output",
    "What a programmer writes",
    "Word preceding various colors",
    "Word with bar or color",
    "Programmer's work",
    "Set of standards",
    "Building contractor's study",
    "Secret message",
    "Computer programmer's work",
    "Morse ___",
    "___ red",
    "Building regulations",
    "Programmers' writing",
    "The \"C\" in U.P.C.",
    "Set of principles",
    "Bar ___",
    "The \"C\" in U.P.C.",
    "Kind of word",
    "One if by land, two if by sea, etc.",
    "Spy's writing, perhaps",
    "Pig Latin, e.g.",
    "Spy's secret",
    "It may be cracked",
    "Binary ___",
    "Spy's writing",
    "Secret language",
    { "formula": "+Building ___", "result": { "error": "#NAME?" } },
    "Building regulations",
    "Message concealer",
    "Commercial ___",
    "Spy writing",
    "Safety specifications",
    "It needs to be broken",
    "Feature of Doyle's \"The Adventure of the Dancing Men\"",
    "Start for \"of honor\" or \"of silence\""
  ],
  "oman": [
    "Oldest independent state in the Arab world",
    "The toe of a geographical \"boot\"",
    "Muscat's sultanate",
    "Only United Nations member whose name starts with \"O\"",
    "Its flag displays a curved dagger known as a khanjar",
    "Country whose name can be an exclamation",
    "Mideast sultanate",
    "Oil-rich nation that's not in OPEC",
    "Yemen neighbor",
    "U.S. ally in the Gulf War",
    "Its chief agricultural export is dates",
    "Arab nation once colonized by the Portuguese",
    "Country that has approximately 0% arable land",
    "Mideast land",
    "Arabian land near the Strait of Hormuz",
    "Home of a sultan",
    "Sultan Qaboos's land",
    "Muscat's land",
    "Country whose total land area is less than .01% forested",
    "Muscat is its capital",
    "Country whose national anthem is \"Nashid As-Salaam as-Sultani\"",
    "Mideast monarchy",
    "Gulf state",
    "Tip of the Arabian Peninsula",
    "Land in which political parties are banned",
    "Mideast's Gulf of ___",
    "Muscat's land",
    "Gulf War ally",
    "Muscat is its capital",
    "Mideast monarchy",
    "Arabian Peninsula sultanate",
    "Muscat's land",
    "Something found on the toe of a boot?",
    "Country whose flag has a dagger and two swords",
    "U.N. member between Norway and Pakistan alphabetically",
    "Home to Sultan Qaboos University",
    "Arabian Sea sultanate",
    "Neighbor of Yemen",
    "Member of the 1990s Persian Gulf war coalition",
    "It's found on the toe of a boot",
    "Sultanate next to an emirate",
    "Arab League member",
    "Country whose name sounds like a Jamaican's cry",
    "Gulf country",
    "Arabian Peninsula land",
    "Bahla Fort site",
    "Arabian Peninsula land",
    "Gulf land",
    "Neighbor of Saudi Arabia",
    "Land with a sultan",
    "Country whose name sounds like a Jamaican exclamation",
    "Musandam Peninsula nation",
    "Arab nation that's not in OPEC",
    "The toe of a boot?",
    "Muscat's land",
    "It's due south of Iran",
    "Gulf of ___",
    "Muscat's land",
    "Eastern sultanate",
    "Home of the Bahla Fort and nearby oasis",
    "Neighbor of Yemen",
    "Country whose name is occasionally used as an exclamation?",
    "Arabian Peninsula sultanate",
    "Monarchy since the 1740s",
    "Arabian Peninsula sultanate",
    "Muscat is its capital",
    "\"Toe\" of the Arabian Peninsula",
    "Muscat's country",
    "Muscat's land",
    "Neighbor of Yemen",
    "Muscat's land",
    "Its capital is Muscat",
    "Yemen neighbor",
    "Sultan's land",
    "Mideast sultanate",
    "Arabian Peninsula land",
    "See 98-Down",
    "Gulf war ally",
    "Gulf of ___",
    "Neighbor of Yemen",
    "Neighbor of Yemen",
    "Neighbor of Yemen",
    "The toe of a geographical \"boot\"",
    "Tip of the Arabian Peninsula",
    "It has an exclave on the Strait of Hormuz",
    "Mideast's Gulf of ___",
    "Mideast sultanate",
    "Land on the end of a peninsula",
    "Neighbor of Yemen",
    "Arab League member",
    "Land with an exclave",
    "A sultanate",
    "Gulf war ally",
    "Arabian sultanate",
    "Land on the Arabian Peninsula",
    "Saudi Arabia neighbor",
    "Monarchy since 1744",
    "Its flag features a khanjar dagger",
    "Arabian Sea borderer",
    "Arabian sultanate",
    "Gulf sultanate",
    "Neighbor of Yemen",
    "One of the Gulf States",
    "Arabian Peninsula land",
    "Mideast's Gulf of ___",
    "Neighbor of Yemen",
    "Former British protectorate",
    "Where Muscat is",
    "Land on the Strait of Hormuz",
    "Gulf sultanate",
    "Gulf of ___",
    "Muscat's land",
    "Muscat is its capital",
    "It's ruled by a sultan",
    "Arabian Peninsula land",
    "Its capital is Muscat",
    "___ Air (Mideast carrier)",
    "Saudi Arabia neighbor",
    "Neighbor of Yemen",
    "Mideast land",
    "Mideast's Gulf of ___",
    "Mideast sultanate",
    "Gulf war ally",
    "Qabus bin Said's land",
    "Land on the Strait of Hormuz",
    "Mideast land",
    "Arab League member",
    "Muscat's land",
    "Gulf war ally",
    "The toe of the Arabian Peninsula",
    "Muscat is its capital",
    "Neighbor of Saudi Arabia",
    "Arabian Sea nation",
    "Qabus bin Said's domain",
    "Arabian sultanate",
    "Yemen neighbor",
    "Arabian Sea nation",
    "Arabian Sea adjoiner",
    "Neighbor of Saudi Arabia",
    "Neighbor of Yemen",
    "Mideast land",
    "Gulf war ally",
    "Salalah's land",
    "Muscat's land",
    "Arab League member",
    "Saudi Arabia neighbor",
    "Mideast's Gulf of ___",
    "Mideast sultanate",
    "Land in the Rub al Khali",
    "Mideast sultanate",
    "Mideast's Gulf of___",
    "Saudi Arabia neighbor",
    "Land on the Arabian Sea",
    "Qabus bin Said's domain",
    "Muscat's country",
    "Arabian sultanate",
    "Gulf war ally",
    "Arabian land",
    "Arabian sultanate",
    "Mideast land",
    "Muscat's land",
    "Arabian sultanate",
    "Gulf nation",
    "Muscat is its capital",
    "Gulf in 1991 news",
    "Mideast gulf"
  ],
  "tada": [
    "\"I did it!\"",
    "\"I did it!\"",
    "\"There! I did it!\"",
    "Triumphant cry",
    "\"Look what I did!\"",
    "Finish line?",
    "\"And there you have it!\"",
    "\"I did it!\"",
    "\"I'm do-o-one!\"",
    "\"Well, aren't I clever?!\"",
    "\"Look at me - I did it!\"",
    "Exclamation that might accompany a curtsy",
    "Exclamation usually made in a high voice",
    "Cry after doing something impressive",
    "\"Look how great I did!\"",
    "Cry made while taking a bow",
    "Cry of triumph",
    "\"I did it!\"",
    "\"Ain't I somethin'?!\"",
    "\"Voila!\"",
    "\"Look how great I did!\"",
    "\"Wasn't that fantastic?!\"",
    "\"Well, look what I did!\"",
    "\"Ain't I sumthin'!\"",
    "Cry before curtsying or taking a bow",
    "\"And that's it!\"",
    "\"All done!\"",
    "Word of mock fanfare",
    "\"There! I did it!\"",
    "\"There!\"",
    "\"There, look what I did!\"",
    "Cry made with a curtsy, maybe",
    "\"There!\"",
    "Drumroll follower",
    "\"Voila!\"",
    "Cry frequently made with jazz hands",
    "\"I did it!\"",
    "Vocal fanfare",
    "\"Look what I did!\"",
    "Cry that's often sung",
    "\"And there it is!\"",
    "\"Look what I just did!\"",
    "\"I did it!\"",
    "Magician's word",
    "\"Et voila!\"",
    "\"Look how great I did!\"",
    "\"There!\"",
    "\"And ... there you have it!\"",
    "Cry with a swelled chest",
    "\"Look how perfectly I performed!\"",
    "\"Look what I did!\"",
    "\"I did it!\"",
    "Show-off's shout",
    "\"Didn't I do great?!\"",
    "Cry of accomplishment",
    "Finish line?",
    "\"Brilliant, ain't I?!\"",
    "Bit of verbal trumpeting",
    "\"Look what I did!\"",
    "\"Aren't I amazing?!\"",
    "\"Look what I did!\"",
    "\"Look what I did!\"",
    "\"I did it!\"",
    "Revelation exclamation",
    "Vocal fanfare",
    "Cry made with a flourish",
    "\"There!\"",
    "\"Told you I could do it!\"",
    "\"And there you have it!\"",
    "\"Look, I did it!\"",
    "Verbal flourish",
    "\"I did it!\"",
    "\"Look what I did!\"",
    "Accompanier of a wondrous feat",
    "Follower of a wondrous feat",
    "Cry before applause",
    "Cry of pride",
    "\"And here it is!\"",
    "Follower of a drum roll",
    "\"I did it!\"",
    "\"I did it!\"",
    "\"Voila!\"",
    "Relative of \"voila\"",
    "Triumphant cry",
    "\"... and here it is!\"",
    "\"Voila!\"",
    "Immodest exclamation",
    "Unveiler's cry",
    "\"I did it!\"",
    "\"Et voila!\"",
    "\"I did it!\"",
    "\"Look what I did!\"",
    "Interjection that may precede a bow",
    "Exclamation with a drum roll",
    "\"Et voila!\"",
    "\"Behold!\"",
    "Bit of vocal fanfare",
    "\"I did it!\"",
    "\"Voila!\"",
    "\"Hooray for me!\"",
    "\"There!\"",
    "Dramatic entrance announcement",
    "\"There!\"",
    "\"Aren't I amazing?\"",
    "Fake fanfare",
    "\"There!\"",
    "\"See, I did it!\"",
    "Vocal fanfare",
    "Cry of success",
    "Dramatic intro",
    "Cry of accomplishment",
    "Vocal imitation of a fanfare"
  ],
  "okra": [
    "Southern cooking staple",
    "Vegetable also called \"ladies' fingers\"",
    "Vegetable used to thicken stews",
    "West African food staple",
    "Southern soup ingredient",
    "Vegetable also called ladies' fingers",
    "Cajun cooking staple",
    "Soup pod",
    "Food also known as ladies' fingers",
    "Stew thickener",
    "Gumbo vegetable",
    "Vegetable in Creole cooking",
    "New Orleans side dish",
    "Vegetable that's frequently fried",
    "Vegetable that can get slimy when overcooked",
    "Succotash ingredient, at times",
    "Gumbo green",
    "South side?",
    "Ingredient in gumbo",
    "Gumbo ingredient",
    "Gooey vegetable",
    "Gummy gumbo vegetable",
    "Jambalaya ingredient, at times",
    "Plant also known as ladies' fingers",
    "Staple of Southern cuisine",
    "Vegetable with pods",
    "Gumbo vegetable",
    "Gumbo vegetable",
    "Vegetable that becomes gooey when cooked",
    "Fried ___ (Southern dish)",
    "Vegetable that's a good source of protein",
    "South side?",
    "Southern-fried vegetable",
    "Stew thickener",
    "Gumbo ingredient",
    "Deep South delicacy",
    "Vegetable that's often fried",
    "Plant also known as ladies' fingers",
    "Southern vegetable that's often deep-fried",
    "Gumbo need",
    "Cajun cooking pod",
    "Food often with pentagonal cross sections",
    "Some pods",
    "Gumbo need",
    "Vegetable that's often fried",
    "Pods often pickled",
    "Gumbo need",
    "Southern side",
    "Soup thickener",
    "Cajun cuisine vegetable",
    "Gumbo vegetable",
    "Soup thickener",
    "Gumbo staple",
    "Vegetable in Cajun cuisine",
    "Gumbo pod",
    "Gumbo vegetable",
    "Callaloo ingredient",
    "Southern staple",
    "New Orleans staple",
    "Vegetable sometimes grown as a flower",
    "Food whose name comes from a language of West Africa",
    "Vegetable in Cajun cuisine",
    "Creole cooking pod",
    "Member of the mallow family",
    "Gumbo vegetable",
    "Gumbo ingredient",
    "Pod holder",
    "Gumbo vegetable",
    "Pod that's sometimes pickled",
    "Gumbo ingredient",
    "Gumbo ingredient",
    "Creole vegetable",
    "Gumbo vegetable",
    "Gumbo vegetable",
    "Gumbo vegetable",
    "Some pods",
    "Food that may be pickled",
    "Gumbo pods",
    "Gumbo thickener",
    "Gumbo vegetable",
    "Gumbo vegetable",
    "Plant with pods",
    "Gumbo pod",
    "Gumbo pods",
    "Gumbo vegetable",
    "Soup vegetable",
    "Bourbon Street vegetable",
    "Soup pod",
    "Tapered pods",
    "Creole vegetable",
    "Gumbo ingredient",
    "Gumbo",
    "Gumbo component",
    "Soup vegetable",
    "Gumbo ingredient",
    "Gumbo",
    "Gumbo ingredient",
    "Gumbo ingredient",
    "Staple of Southern cuisine",
    "Gumbo pods",
    "Gumbo staple",
    "Gumbo ingredient",
    "Mallow family plant",
    "Podded plant",
    "Pod vegetable",
    "Gumbo ingredient",
    "Some pods",
    "Stew ingredient",
    "Gumbo vegetable",
    "Gumbo plant",
    "Creole vegetable",
    "Soup pods",
    "Gumbo ingredient",
    "Southern soup ingredient",
    "Gumbo",
    "Plant also called lady's-finger",
    "Gumbo vegetable",
    "Soup pods",
    "Soup pod",
    "Gumbo ingredient",
    "Gumbo ingredient",
    "Plant called lady's-finger in England",
    "Green pods",
    "Pods for stews",
    "Greengrocer's pods"
  ],
  "yuri": [
    "Doctor Zhivago",
    "Doctor Zhivago, in \"Doctor Zhivago\"",
    "Cosmonaut Gagarin",
    "Former Soviet leader Andropov",
    "First name in space",
    "First spaceman's first name",
    "Space race hero Gagarin",
    "Vostok 1's Gagarin",
    "Cosmonaut Gagarin",
    "Doctor Zhivago",
    "Chess master Averbakh",
    "1980s Soviet leader Andropov",
    "First name in cosmonautics",
    "Cosmonaut Gagarin",
    "Gagarin in space",
    "Doctor Zhivago",
    "Cosmonaut Gagarin",
    "Soviet leader Andropov",
    "Cosmonaut Gagarin",
    "Cosmonaut Gagarin",
    "Doctor Zhivago",
    "Soviet leader Andropov",
    "Cosmonaut Gagarin",
    "Cosmonaut Gagarin"
  ],
  "newt": [
    "Semiaquatic creature",
    "Pond amphibian",
    "Pond dweller that can regenerate its eyes",
    "Lizardlike amphibian",
    "___ Scamander, protagonist of \"Fantastic Beasts and Where to Find Them\"",
    "Small salamander",
    "Small salamander",
    "Pond critter",
    "Animal in a creek",
    "Prey for a heron",
    "Eye of ___ (part of a witch's brew)",
    "Slim amphibian",
    "Seventh-year exam in Harry Potter",
    "Swamp dweller",
    "Pond denizen",
    "Politico Gingrich",
    "Amphibian once associated with bad spirits",
    "Pond dweller that can regenerate its eyes",
    "Politico Gingrich",
    "Eye of ___ and toe of frog (ingredients in a witches' brew)",
    "See 4-Down",
    "Pond dweller",
    "Cousin of a congo eel",
    "Former House leader Gingrich",
    "First name in the 2012 Republican primary",
    "Tadpole's later form, perhaps",
    "Wetlands creature",
    "Politico Gingrich",
    "\"She turned me into a ___!\": Monty Python",
    "Politico Gingrich",
    "Lizard look-alike",
    "Eye of ___ (witch's item)",
    "Grown-up eft",
    "Colorful amphibian",
    "Politico Gingrich",
    "Lizardlike creature",
    "House speaker between Tom and Dennis",
    "Small salamander",
    "Salamandridae family member",
    "Former speaker Gingrich",
    "Red-spotted ___",
    "\"Winning the Future\" author Gingrich",
    "Politico Gingrich",
    "Slender amphibian",
    "Small salamander",
    "___ Scamander, pseudonym of J. K. Rowling",
    "Former speaker Gingrich",
    "Young cowboy in \"Lonesome Dove\"",
    "Politico Gingrich",
    "1995 political book subtitled \"Leader of the Second American Revolution\"",
    "Politico Gingrich",
    "Brilliantly colored salamander",
    "Small salamander",
    "Grown-up eft",
    "It becomes aquatic during its breeding season",
    "?ôEye of ___ ?à?ö",
    "Witches' brew necessity",
    "Eye of ___ (witches' brew need)",
    "Former House Speaker Gingrich",
    "Pond creature",
    "Adult eft",
    "Witch's brew ingredient",
    "Former Speaker Gingrich",
    "Colorful salamander",
    "Former Speaker Gingrich",
    "Japanese ___ (popular pet)",
    "\"Eye of ___, and toe of frog\"",
    "Politician Gingrich",
    "Salamander",
    "Speaker's name",
    "Man of the House?",
    "First name on Capitol Hill",
    "First name on Capitol Hill",
    "Its eye is needed in a \"Macbeth\" recipe",
    "Eye of ___ (witch's item)",
    "Eft-wing politician?",
    "Mr. Gingrich",
    "Critic of Bill",
    "Mr. Gingrich",
    "Witch's brew ingredient",
    "1995 House chant",
    "Congressman Gingrich",
    "Congressman Gingrich",
    "\"Eye of ___\" (recipe item)"
  ],
  "awol": [
    "Missing",
    "Like someone receiving baseless accusations, maybe?",
    "Inexplicably missing, say",
    "Missing",
    "Like a soldier who might be court-martialed",
    "Truant G.I.'s infraction",
    "Completely missing, informally",
    "Unaccounted for",
    "Missing letters?",
    "G.I. who's way off base",
    "Army no-show",
    "Missing G.I.",
    "One who might be caught off base",
    "Subj. of Article 86 of the Uniform Code of Military Justice",
    "Like naughty privates?",
    "Not reporting as instructed, maybe",
    "One not reporting for duty, maybe",
    "Missing, as a G.I.",
    "Soldier who's gone missing",
    "No longer in the company, say",
    "Reveille no-show",
    "Gone badly?",
    "Runaway G.I.",
    "Bad way to go?",
    "Unable to make a mess?",
    "Off-base sort?",
    "Taking unauthorized R&R",
    "Missing, militarily",
    "Not on base?",
    "Like base runners?",
    "Unaccounted for",
    "Roll call no-show",
    "Having left the company, maybe",
    "One taking an unscheduled flight?",
    "Baselessly off-base?",
    "Gone private?",
    "Barracks no-show",
    "Mil. truant",
    "Wayward G.I.",
    "Gone, but not forgotten",
    "Unable to pass muster, say",
    "Not reporting, maybe",
    "Improperly off base, in brief",
    "U.S.M.C. truant",
    "M.P.'s target",
    "Missing roll call, say",
    "One who's gone but not forgotten",
    "Bad way to go",
    "M.P.'s quarry",
    "A.F.B. truant",
    "Off-base in a bad way",
    "Missing roll call, say",
    "One in civvies who maybe shouldn't be",
    "Whom an M.P. hunts",
    "M.P.'s quarry",
    "Object of a hunt, maybe",
    "M.P.'s concern",
    "Military no-show",
    "Object of a mil. search",
    "M.P.'s quarry",
    "Gone from the company, maybe",
    "Military no-show",
    "Object of a manhunt, maybe",
    "One who's gone but not forgotten",
    "Delinquent G.I.",
    "One who may be caught off base?",
    "Wanted G.I.",
    "Way off base?",
    "G.I. no-show",
    "M.P.'s quarry",
    "Wanted soldier",
    "Dicey G.I. status",
    "Object of a search, maybe",
    "Baseless?",
    "Unaccounted for",
    "How a soldier may go",
    "One targeted by military police",
    "What a soldier shouldn't be",
    "Soldier who's nowhere to be found",
    "Illegally off base",
    "Base truant",
    "Military acronym",
    "Off base illegally",
    "Gone wrong?",
    "Missing, in a way",
    "Truant, in a way",
    "Illegal way to go",
    "Barracks no-show",
    "Off base, perhaps",
    "Roll call absentee",
    "Roll-call no-show",
    "Delinquent G.I.",
    "Brig prisoner, perhaps",
    "One caught off base, perhaps",
    "Mil. truant",
    "Gone wrong?",
    "Object of a manhunt, maybe",
    "Person missed by a 63-Across",
    "Mil. truant",
    "Concern for an M.P.",
    "Base criminal?",
    "Missing from the Marines, say",
    "Bad way to go",
    "Roll call misser",
    "Military no-show",
    "M.P.'s quarry",
    "One who's off base, maybe",
    "M.P.'s quarry",
    "Missing at roll call, maybe",
    "Army no-show",
    "Missing G.I.",
    "Military truant",
    "M.P.'s quarry",
    "One who may be caught off base",
    "Like a wayward G.I.",
    "Sans pass",
    "One who's decamped?",
    "Missing from the Marines, say",
    "One gone but not forgotten",
    "In trouble, in the Army",
    "No-show",
    "Military no-show",
    "A wanted man, maybe",
    "Roll call misser",
    "M.P.'s prize",
    "Dissatisfied soldier",
    "Off-base, unofficially",
    "Delinquent, of a sort",
    "Army deserter",
    "Object of an Army hunt",
    "Military no-show",
    "Lost leatherneck",
    "One the Army wants",
    "Gone but not forgotten",
    "Off-base soldier",
    "Army no-show",
    "Case for the military police",
    "Army problem",
    "Roll call eschewer",
    "Wanted G.I.",
    "Off base, maybe",
    "Subject to court-martial, maybe",
    "Baseless?",
    "Gone, but not forgotten?"
  ],
  "zine": [
    "Niche mag",
    "Amateur mag",
    "The Atlantic, but not the Pacific, for short",
    "Specialist publication, for short",
    "Ending with fan",
    "Fan's pub",
    "Informal pub",
    "Fanboy's reading",
    "Amateur publication, for short",
    "Fan's reading",
    "Fan mag",
    "Fan's publication",
    "Fan's publication, for short",
    "Fan mag",
    "Fan mag",
    "Small-circulation publication for fans",
    "Fans' publication, for short",
    "Amateur mag."
  ],
  "zest": [
    "Joie de vivre",
    "Joie de vivre",
    "Gusto",
    "Lemon bar ingredient",
    "Martini's lemon twist, e.g.",
    "Soap that comes in blue-green bars",
    "Avidity",
    "Drink flavorer",
    "Zip",
    "Drink flavorer",
    "Enthusiasm",
    "Lemon rind part",
    "Piquancy",
    "Piquancy",
    "Gusto",
    "Gusto",
    "Marmalade bit",
    "Kick or punch",
    "Zip",
    "Spice",
    "Verve",
    "Marmalade ingredient",
    "Tabasco quality",
    "Tanginess",
    "Piquancy",
    "Joie de vivre",
    "Limoncello ingredient",
    "Dial competitor",
    "Passion for life",
    "Spice",
    "Gusto",
    "Piquancy",
    "You need only scrape the surface to get it",
    "Gusto",
    "Piquancy",
    "Gusto",
    "Enthusiasm",
    "Lemon peel",
    "Relish",
    "Sorbet ingredient",
    "Gusto",
    "Procter & Gamble soap",
    "Spiciness",
    "Gusto",
    "Gusto",
    "Gusto",
    "Verve",
    "Irish Spring alternative",
    "Gusto",
    "Piquancy",
    "Spice",
    "Gusto",
    "Salsa quality",
    "Gusto",
    "Gusto",
    "Tabasco quality",
    "Piquancy",
    "Kick",
    "Gusto",
    "Lemon peel",
    "Energy",
    "Bite, so to speak",
    "Part of an orange",
    "Piquancy",
    "Gusto",
    "Relish",
    "Gusto",
    "Animation",
    "Joie de vivre",
    "Piquancy",
    "Elan",
    "Spice"
  ],
  "amos": [
    "Book before Obadiah",
    "Book after Joel",
    "Book after Joel",
    "Singer Tori",
    "\"Famous\" cookie name",
    "\"Famous\" cookie maker",
    "Novelist ___ Oz",
    "\"Famous\" cookie guy",
    "Singer/songwriter Lee",
    "Singer Tori",
    "Book after Joel",
    "Baking entrepreneur Wally",
    "Nigerian novelist Tutuola",
    "Pioneering football coach ___ Alonzo Stagg",
    "Who said \"The Lord will roar from Zion, and utter his voice from Jerusalem\"",
    "Famous ___ (cookie brand)",
    "___ Lee, singer with the 2011 #1 album \"Mission Bell\"",
    "Prophet who said \"The Lord roars from Zion and thunders from Jerusalem\"",
    "\"Famous\" cookie name",
    "Commercial rhyme for \"Famous\"",
    "\"Famous\" cookie name",
    "John who played an older Kunta Kinte on \"Roots\"",
    "Contemporary of Hosea",
    "Famous ___ cookies",
    "Author Oz",
    "Andy's partner in old comedy",
    "\"Chicago\" simpleton ___ Hart",
    "Old Testament prophet",
    "Old Testament book that asks \"Does a lion roar in the thicket when it has no prey?\"",
    "\"Famous\" snack maker",
    "Trio who released the 1994 album \"Under the Pink\"",
    "Follower of Joel",
    "Musical name after Tori or before Lee",
    "Tori who sang \"Cornflake Girl\"",
    "Biblical prophet",
    "Tori of pop/rock",
    "Singer Tori",
    "Book after Joel",
    "Lee with the 2011 #1 album \"Mission Bell\"",
    "Israeli writer ___ Oz",
    "___ Lee, singer with the 2011 #1 album \"Mission Bell\"",
    "Last name in cookies",
    "\"Famous\" cookie man",
    "Half of an old radio comedy duo",
    "Wally of cookie fame",
    "Pal of Andy",
    "Famous ___ cookies",
    "\"Famous\" cookie man",
    "Old Testament shepherd",
    "\"Famous\" cookie name",
    "\"Chicago\" husband ___ Hart",
    "One of the Minor Prophets",
    "Book after Joel",
    "Famous ___ cookies",
    "Pastoral prophet",
    "Book after Joel",
    "\"Famous\" cookie guy",
    "Biblical shepherd",
    "Andy's partner in old radio",
    "Old Testament prophet",
    "Biblical follower of 28-Across",
    "Book after Joel",
    "Half a 1950s TV duo",
    "Book after Joel",
    "Book before Obadiah",
    "Andy's partner in old radio",
    "\"Famous\" cookie maker",
    "Partner in an old radio comedy duo",
    "Three-time Gold Glove winner ___ Otis",
    "\"Under the Pink\" singer Tori",
    "Burke of TV's \"Burke's Law\"",
    "Book after Joel",
    "Andy's sidekick, in old radio",
    "Old Testament book",
    "\"Murder, She Wrote\" sheriff ___ Tupper",
    "Old radio's \"___ 'n' Andy\"",
    "Old radio title character",
    "Hebrew prophet",
    "Biblical shepherd-turned-prophet",
    "Biblical book",
    "Famous cookie guy",
    "Fictional cabbie of old radio",
    "8th-century B.C. preacher",
    "Book after Joel",
    "Old Testament book",
    "19th-century journalist/politician ___ Kendall",
    "Old Testament book",
    "Singer Tori",
    "Half of a 50's TV duo",
    "Author Oz",
    "Old Testament prophet",
    "*\"Famous\" ... Andy ... prophet",
    "Biblical prophet",
    "Biblical prophet",
    "Andy's partner in old radio",
    "Half of an old radio duo",
    "It follows Joel",
    "Half of an old radio duo",
    "Andy's radio partner",
    "Book after Joel",
    "Book after Joel",
    "Pop singer Tori",
    "___ Jones of old radio comedy",
    "Singer Tori",
    "\"My Michael\" author Oz",
    "Famous ___",
    "Burke of \"Burke's Law\"",
    "Book after Joel",
    "Andy's cohort, in old radio",
    "Israeli author ___ Oz",
    "Book after Joel",
    "Andy's partner",
    "Famous ___",
    "Book of prophecies",
    "1990's singer Tori",
    "\"Famous\" name",
    "Andy's pal",
    "70's All-Star ___ Otis",
    "Book after Joel",
    "Book after Joel",
    "Grid coach ___ Alonzo Stagg",
    "Sheriff Tupper of \"Murder, She Wrote\"",
    "Biblical sheep-farmer",
    "Famous ___",
    "Book after Joel",
    "Israeli writer___Oz",
    "Famous cookie maker",
    "Old Testament book",
    "Famous cookie man",
    "Book after Joel",
    "Pop singer Tori",
    "Andy's partner",
    "Half of an old radio duo",
    "Biblical prophet",
    "Book after Joel",
    "\"Famous\" cookie name",
    "Half an old radio team",
    "Kunta Kinte portrayer John",
    "With 84-Across, Kansas City Royals star of the '70s",
    "\"Famous\" cookie name",
    "\"My Michael\" writer ___ Oz",
    "Book after Joel",
    "Andy's pal on old radio",
    "Famous cookie man",
    "\"Famous\" cookie man",
    "Freeman Gosden radio role",
    "TV sheriff Tupper",
    "\"___ 'n'Andy\"",
    "Sheriff Tupper of \"Murder, She Wrote\"",
    "Judean prophet",
    "Freeman Gosden radio role",
    "First name in cookies"
  ],
  "loft": [
    "Converted apartment, perhaps",
    "Unpartitioned apartment",
    "Where choirs sing and artists paint",
    "Upper floor of a barn",
    "Apartment in an old warehouse district, say",
    "Apartment in a former factory, maybe",
    "Where hay is stored in a barn",
    "What a pitching wedge provides",
    "Throw up",
    "Digs in an old warehouse, maybe",
    "Sleeping site, maybe",
    "Place for a choir",
    "Art gallery site, possibly",
    "Artist's pad?",
    "Toss high up",
    "Overhead cost for an artist?",
    "Converted housing",
    "Artist's digs, maybe",
    "Home in an old warehouse district",
    "Hay storage locale",
    "Gallery",
    "Barn area",
    "Toss up",
    "Room at the top",
    "Barn section",
    "Garret",
    "Place for hay",
    "Room at the top",
    "Send up",
    "Upper story",
    "Use a 9-iron, say",
    "Hit high into the air",
    "Certain apartment",
    "Converted apartment",
    "Hay storage site",
    "Modern apartment",
    "Artist's workspace",
    "Artist's pad?"
  ],
  "life": [
    "\"That's ___\"",
    "One of three at the start of a Pac-Man game",
    "What happens while you're busy making other plans, it's said",
    "With 5-Across, means of survival",
    "With 41-Across, generational sequence",
    "Vivacity",
    "Supreme Court term",
    "\"To ___!\" (\"L'chaim!\")",
    "It's a tragedy when seen in close-up but a comedy in the long shot, per Charlie Chaplin",
    "\"Where there's ___, there's hope\"",
    "Longest sentence?",
    "Vivacity",
    "The other half of a 55-Across",
    "Long sentence",
    "Magazine that published Harry Truman's memoirs",
    "Excitement",
    "Long sentence",
    "Subject of a search on Mars",
    "Quaker cereal",
    "\"That's ___\"",
    "\"My ___\" (Clinton autobiography)",
    "Long, long sentence",
    "Energy",
    "Prison sentence that may be \"without parole\"",
    "Popular cereal or magazine",
    "Lengthy sentence",
    "Bio",
    "Bygone picture weekly",
    "Game with car tokens",
    "\"That's ___\"",
    "Vitality",
    "Long sentence",
    "It's insurable",
    "Biography",
    "Severe sentence",
    "Time Inc. magazine",
    "Biography",
    "Luce publication",
    "It's often toasted",
    "Never-ending sentence?",
    "Bowl of cherries, maybe",
    "Chaim, to Potok",
    "The ___ of the party",
    "Magazine founded 11/23/36",
    "Zest",
    "16-Across's \"___ With Father\""
  ],
  "exes": [
    "Frequent subjects of Taylor Swift songs",
    "They used to be a \"thing\"",
    "Past partners",
    "\"I like texts from my ___ when they want a second chance\" (Cardi B line)",
    "Union deserters",
    "Members of a defunct union",
    "Split personalities?",
    "People whom you might try to forget",
    "Flames that have gone out?",
    "Old flames",
    "Sean Penn and Guy Ritchie, to Madonna",
    "Participants in some awkward meetings",
    "Ones who've called it quits",
    "Some singles",
    "Old flames",
    "Strikes (out)",
    "Kramer and Kramer, in \"Kramer vs. Kramer\"",
    "Opponents in custody cases",
    "Past partners",
    "Ones who are splitsville",
    "Split pair",
    "Flames that have gone out?",
    "Bruce Willis and Demi Moore, e.g.",
    "They've split",
    "An item no more",
    "Union busters?",
    "Halves of an old item?",
    "Custody sharers, often",
    "Former spouses",
    "Former spouses",
    "Mates who've split",
    "Old flames",
    "The Gabor sisters had many",
    "Alimony givers or receivers",
    "Ones giving or receiving alimony",
    "Donald and Ivana, for instance",
    "Ruptured couple",
    "Joint custody parties",
    "They've split",
    "Ones concerned with custody",
    "Couples no more",
    "Former union members?",
    "Former flames",
    "They've gone their separate ways",
    "Old flames",
    "Ones who've been through divorce court",
    "Item of the past",
    "They're splitsville",
    "Splitsville parties",
    "Old flames",
    "22-Down that has split",
    "Brad and 86-Down, e.g.",
    "Givers and receivers of alimony",
    "Split personalities?",
    "Former mates",
    "Unlikely valentine swappers",
    "Two from sixty-six?",
    "Alimony receivers, e.g.",
    "Old flames",
    "They've split",
    "Custody sharers, maybe",
    "Two in sixty-six?",
    "Ones who've gone splitsville",
    "Past dates?",
    "Ones who are all broken up",
    "Splitsville parties",
    "They're no longer hitched",
    "Alimony senders, maybe",
    "Divorces",
    "Alimony receivers",
    "Former item",
    "Split personalities?",
    "Split personalities?",
    "Split personalities?",
    "Ones who may not be on speaking terms",
    "Group for Liz Taylor",
    "They've split",
    "Ones who may have issues over issues",
    "They're broken up",
    "Split personalities?",
    "Former partners",
    "Two out of sixty-six?",
    "Split personalities?",
    "Difficulties for wedding planners, maybe",
    "Extinguished flames?",
    "They're broken up",
    "Divorcees",
    "Donald and Ivana, e.g.",
    "Men in Liz's past",
    "Partners who called it quits",
    "Union busters?",
    "Former spouses",
    "Alimony receivers",
    "Old flames",
    "Donald and Ivana, Burt and Loni, etc.",
    "Onetime item",
    "They often don't speak to each other",
    "Larry King has a few",
    "Donald and Ivana, e.g.",
    "\"First Wives Club\" members",
    "\"The First Wives' Club\" members",
    "Divorcees",
    "They've split",
    "Former spouses",
    "Former partners",
    "Liz has several",
    "Past spouses",
    "Divorcees",
    "Twins of Xerxes",
    "Returnees from Reno, maybe",
    "Support payers",
    "Split personalities?",
    "Alimony getters",
    "Alimony collectors",
    "Former spouses"
  ],
  "bars": [
    "Measures, in music",
    "Rap lyrics, in slang",
    "Word after monkey or handle",
    "Stars and ___ (Confederate flag)",
    "Taverns",
    "Criminals may be behind them",
    "Excludes",
    "1-5 on a cellphone screen",
    "Monkey ___",
    "See 35-Across",
    "Taverns",
    "Set on a cellphone",
    "Signs of cell service ... or a word that can follow both parts of 18-, 23-, 36-, 52- and 58-Across",
    "Places for shots",
    "Twix units",
    "Granola servings",
    "Zoo keepers?",
    "Spots for shots",
    "Structures near cell walls",
    "Beer places",
    "Taverns",
    "Places where free spirits aren't found?",
    "Where spirits run freely?",
    "A lot of Tijuana",
    "Restaurant waiting areas",
    "Jail cell parts",
    "Components of some codes",
    "Milky Way and others",
    "Cell wall, perhaps",
    "Musical units",
    "3 Musketeers units",
    "Tijuana attractions",
    "Blocks",
    "They're not good to be behind",
    "Cheers and others",
    "Behind ___",
    "Mars and Milky Way",
    "Buds may be produced in them",
    "Taverns",
    "Milky Way and 3 Musketeers",
    "Stands in the way of",
    "Blocks",
    "Musical measures",
    "Universal product code elements",
    "Butter units",
    "Lush settings?",
    "Prisoner confinement",
    "Captain's insignia"
  ],
  "ales": [
    "Lager alternatives",
    "Draft picks",
    "Tavern offerings",
    "They might be tapped out",
    "Taproom array",
    "Browns and stouts",
    "Sierra Nevadas, e.g.",
    "Stout and porter",
    "Some draft picks",
    "Things sold by the yard?",
    "Bar orders",
    "Beverages from 56-Down",
    "Pub pints",
    "Hoppy quaffs",
    "Pub offerings",
    "Hearty draughts",
    "Bottles that might be marked \"XXX\" in the comics",
    "Servings from a tap",
    "Sierra Nevadas, e.g.",
    "Porters may be found near them",
    "Some draft selections",
    "Alternatives to lagers",
    "Goose Island products",
    "Draft picks",
    "Sierra Nevadas and others",
    "Tavern offerings",
    "Public house options",
    "Brewery products",
    "Publicans' servings",
    "Orders at the Rose & Crown",
    "Hoppy mediums?",
    "Strong brews",
    "Pub pints",
    "Lager alternatives",
    "Inn stock",
    "They have hops",
    "Stout cousins?",
    "Flanders red and others",
    "Hearty brews",
    "They can be brown or blonde",
    "What may be brewing",
    "Pub orders",
    "Newcastle Brown and others",
    "Tap options",
    "Pub potables",
    "Insets may be filled with them",
    "Draft picks",
    "Consumables often described with a percentage",
    "Tap things?",
    "Flagon fillers",
    "Tavern menu heading",
    "Quaffs in pints",
    "Fat Tire and Full Sail",
    "Newcastle and others",
    "Pub potables",
    "Pub orders",
    "Bass line?",
    "Some brews",
    "Old Brown Dog and others",
    "Tavern stock",
    "Pub offerings",
    "Newcastle Brown and others",
    "Many are blonde",
    "Bar brews",
    "Pub pints",
    "Round in Britain, maybe",
    "Naughty Goose and Moose Drool",
    "They may be pint-size with big heads",
    "They may be drawn from a 63-Across",
    "Saloon choices",
    "Buzz producers",
    "Pub offerings",
    "Pub offerings",
    "Hoppy beverages",
    "Drinks for Chaucer's Miller",
    "Some round components",
    "Publican's stock",
    "They're often tapped",
    "Heady brews",
    "Malt beverages",
    "Pub draughts",
    "Draft choices",
    "Pub quaffs",
    "Pub deliveries",
    "Pub quaffs",
    "Saloon selections",
    "Tavern orders",
    "Draft picks",
    "They may create a buzz",
    "Pub draughts",
    "Intoxicating round",
    "They're often drawn",
    "Some brews",
    "Pub brews",
    "Publican's stock",
    "Potent round",
    "Publican's stock",
    "Beverages in barrels",
    "Stein fillers",
    "Draft picks in pubs",
    "Pub servings",
    "Subjects of Guinness records?",
    "Pub offerings",
    "\"Pale\" drinks",
    "Drinks with heads",
    "Taproom offerings",
    "Brewed beverages",
    "Stout relatives",
    "Bass productions",
    "Tavern selections",
    "Orders at McSorley's",
    "Tavern mugfuls",
    "Pub quaffs",
    "Local stock",
    "Bass offerings",
    "Stout ones",
    "Pub drinks",
    "Newcastle Browns, e.g.",
    "Saloon selections",
    "Some are pale",
    "Bass output",
    "They may be bitter",
    "Yeasty brews",
    "Some pintfuls",
    "Pub stock",
    "Some home-brew",
    "Brews",
    "Items on a 46-Down, perhaps",
    "Draft selections",
    "Bass products",
    "Porters",
    "Molson and others",
    "Pub round",
    "Yard sales?",
    "Malt beverages",
    "Saloon selections",
    "Porters",
    "Draft picks",
    "Stout drinks",
    "Amber brews",
    "Pub pints",
    "They may fill yards",
    "Pub potables",
    "Schooner fillers",
    "Schooner fillers",
    "Some are pale",
    "Pub potables",
    "Pub pints",
    "Stein fillers",
    "Pub offerings",
    "Bitter drinks",
    "\"Light\" and \"dark\" orders",
    "They're on tap in taprooms",
    "Inn inventory",
    "Bartenders tender them",
    "Some drafts",
    "Bass and others",
    "Stout relatives",
    "Saloon orders",
    "Inn inventory",
    "Pub stock",
    "Pub brews",
    "Bitters",
    "Bubbly drinks",
    "Pub brews",
    "Porters",
    "Inn inventory",
    "Porters",
    "Pub stock",
    "Drafts, maybe",
    "Pub offerings",
    "Round in the pub",
    "Drinks at the Pink Pig, say",
    "Pub quaffs",
    "Brews",
    "Porters",
    "Pub quaffs",
    "Pub potables",
    "Brews",
    "Ballantine and others",
    "Pub quaffs",
    "Pub orders",
    "Certain pints",
    "Pub supply",
    "Brewings",
    "Porters",
    "Pub round",
    "Guinness and others",
    "Half pints, maybe",
    "Round at the Duck and Drake",
    "Pub round",
    "Pub potables",
    "Tavern potables",
    "Silk-trading city of France",
    "Brews",
    "Pub pints",
    "Pub orders",
    "Ballantine and others",
    "Pub potables",
    "XXX drinks",
    "Tavern fare",
    "Tourist city near Nimes",
    "Pub quaffs",
    "Pub round",
    "Pub brews",
    "Brews",
    "Publican's offerings",
    "Steinfuls"
  ],
  "tame": [
    "Subdued",
    "Domesticated",
    "Gentle",
    "Subdue",
    "Mild",
    "Not spicy, so to speak",
    "Subdued",
    "Like the sexual references in PG-13 movies",
    "Wild's opposite",
    "Having hardly any spice",
    "Domesticated",
    "Not wild",
    "Domesticate",
    "Break",
    "Civilize",
    "Broken, as a bronco",
    "Gentle",
    "Subdue",
    "Spiritless",
    "Domesticated",
    "Break",
    "PG-rated",
    "Get under control",
    "Sans spice",
    "Wild's opposite",
    "Rein in",
    "Make gentle",
    "Not feral",
    "Unexciting",
    "No longer wild",
    "Lacking spice",
    "Domesticated",
    "Not exciting",
    "Docile",
    "Break",
    "Unlikely to bite",
    "Hardly racy",
    "Break",
    "Crack the whip at, perhaps",
    "Subdued",
    "Break",
    "Bring under control",
    "Far from risque",
    "Easy to manage",
    "Domesticate",
    "Broken, in a way",
    "Not electric",
    "Easily calmed",
    "Barely risque",
    "Like kiddie rides",
    "Lacking thrills",
    "Break, in a way",
    "Hardly racy",
    "Unexciting",
    "Broken",
    "Unlikely to bite",
    "Crack the whip at, maybe",
    "Unlikely to bite",
    "Unexciting",
    "Domesticated",
    "Busted",
    "Subdued",
    "Hardly boisterous",
    "Gentle",
    "Unracy",
    "Hardly racy",
    "Mild",
    "Docile",
    "Watered down",
    "Break, in a way",
    "Not racy",
    "Unresisting",
    "Friendly to humans",
    "Hardly feisty",
    "Housebroken"
  ],
  "sage": [
    "Herb used in smudging rites",
    "Wise one",
    "Common additive to melted butter in Italian cuisine",
    "Wise",
    "Stuffing herb",
    "Saltimbocca ingredient",
    "Solomonic sort",
    "Wise one",
    "Stuffing ingredient",
    "Worth heeding",
    "One of seven of ancient Greece",
    "Wise one",
    "Kind of tea",
    "Wise",
    "Shade of green",
    "Seasoning in stuffing",
    "Profoundly wise",
    "Learned",
    "Wise man",
    "Wise",
    "Like advice worth listening to",
    "Wise one",
    "Wise guy?",
    "Rishi, in Hinduism",
    "Brush material",
    "Wise one",
    "Wise",
    "Solomonic",
    "Meat seasoning",
    "Wise soul",
    "Herb used in sausages",
    "Graybearded sort",
    "Stuff in stuffing, often",
    "Wise",
    "Mint relative",
    "Brush material?",
    "Herb in stuffing",
    "Wise",
    "Wizard",
    "Brush makeup",
    "Learned",
    "Learned",
    "Learned",
    "Like some advice",
    "Seasoning for turkey dressing",
    "Leaves for cooking",
    "Learned",
    "White-bearded type",
    "Herb in stuffing",
    "Stuffing ingredient",
    "Wise fellow",
    "Nestor",
    "Ranch brush",
    "Herb in stuffing",
    "Long-bearded type",
    "Like Solomon",
    "Meat seasoning",
    "Learned one",
    "Wise guy",
    "Solomonic",
    "Learned",
    "Stuffing ingredient",
    "Cook's seasoning",
    "Solomon",
    "Dressing ingredient",
    "Wise one",
    "Ranch brush",
    "Swami",
    "The Thinker, for all we know",
    "Western brush",
    "Learned",
    "Stuffing herb",
    "Pundit",
    "Garden ___",
    "Wizard",
    "Brush source",
    "Solomonlike",
    "Wise one",
    "Wise guy",
    "Wise",
    "Philosopher",
    "Stuffing seasoning",
    "Wise one",
    "Guru",
    "Stuffing herb",
    "Pundit",
    "One sought for advice",
    "Wise guy",
    "Solon, e.g.",
    "Kind of brush",
    "\"Riders of the Purple ___\"",
    "Wise",
    "Wise one",
    "Wise",
    "Solomonic",
    "Emerson, The ___ of Concord",
    "Wise guy",
    "Nestor",
    "Early stock speculator Russell",
    "Wise seasoning?"
  ],
  "obie": [
    "Theater award",
    "\"Hamilton\" won one in 2015",
    "Theatrical award",
    "Annual drama award",
    "Award typically presented in Manhattan's Webster Hall",
    "Stage honor",
    "2019 award for \"What the Constitution Means to Me\"",
    "Award co-administered by the American Theater Wing since 2014",
    "Stage prize since 1956",
    "Award started by the Village Voice",
    "Honor for a play",
    "Tony's counterpart",
    "Cousin of a Drama Desk Award",
    "Dramatic award",
    "Stage achievement",
    "Award from the American Theater Wing",
    "Stage award",
    "Theater award",
    "Village Voice award",
    "Annual New York honor",
    "Annual theater award",
    "There's one for Best New American Play",
    "2015 award for \"Hamilton\"",
    "Stage prize",
    "Stage award since 1956",
    "Theater award",
    "Award for Best New American Play",
    "Theater award since 1956",
    "Drama award since 1956",
    "New York theater award",
    "Drama honor",
    "2012 honor for \"4000 Miles\"",
    "Theater prize",
    "New York theater award",
    "Annual theater award",
    "Theater award",
    "Best New American Play award",
    "Village Voice award",
    "Dramatic honor",
    "Theater award",
    "Award since 1956",
    "Village Voice award",
    "Tony's cousin",
    "Officer in \"Alice's Restaurant\"",
    "New York theater award",
    "Drama honor",
    "Award for \"Hot L Baltimore\"",
    "Village Voice award",
    "Theater award",
    "Playwright's award",
    "Village Voice bestowal",
    "Theater award",
    "Village Voice award",
    "Award for Samuel Beckett",
    "Theater award",
    "Award bestowed by the Village Voice",
    "Award for Tony Kushner",
    "Play honor",
    "Theater award",
    "Award started by the Village Voice",
    "Theater award",
    "Tony's cousin",
    "Award for \"Six Degrees of Separation\"",
    "Tony's cousin",
    "Stage award",
    "Award for \"The Zoo Story\"",
    "Theater award",
    "Award for \"Curse of the Starving Class\"",
    "Big Apple award",
    "Annual theater award",
    "Award for \"Rent\"",
    "1968 award for Vaclav Havel",
    "Theater award",
    "Playwright's dream",
    "Tony's cousin",
    "Playwright's dream",
    "Theater award",
    "Annual theater award",
    "Annual award",
    "Village Voice award",
    "1983 award for David Mamet",
    "Theater award",
    "Tony's cousin",
    "Award for Jean Genet",
    "Award for \"The Curse of the Starving Class\"",
    "Beckett award",
    "Theater award",
    "Theater award",
    "Award for Samuel Beckett",
    "Award for Eric Bogosian",
    "Village Voice award",
    "New York theater award",
    "Cousin of a Tony",
    "Award for \"Curse of the Starving Class\"",
    "Prize for \"Prelude to a Kiss\"",
    "Theather award",
    "Theater award",
    "Cousin of a Tony",
    "Award for \"Prelude to a Kiss\"",
    "Tony's cousin",
    "Theater award",
    "Acting award",
    "Stage award",
    "Drama award",
    "Award given by The Village Voice",
    "Award since 1956",
    "Off Broadway award",
    "Award since 1956"
  ],
  "rita": [
    "Actress Moreno",
    "Eponym for an Italian ice chain",
    "Singer Ora",
    "Poet Dove",
    "___ Dove, former U.S. poet laureate",
    "Romance novelist's award",
    "Former U.S. poet laureate Dove",
    "Moreno with an Emmy, Grammy, Oscar and Tony",
    "Pop singer Ora",
    "Actress Moreno or Hayworth",
    "Moreno of \"West Side Story\"",
    "Singer Ora with three solo #1 hits in Britain",
    "\"Lovely\" Beatles girl",
    "Actress Moreno",
    "\"Lovely ___\" (Beatles song)",
    "Meter maid of song",
    "Actress Moreno",
    "Beatles title girl with a \"little white book\"",
    "\"Lovely ___, meter maid\" (Beatles lyric)",
    "Hayworth who danced with Fred Astaire and Gene Kelly",
    "\"Lovely\" one of song",
    "Memorable 2005 Gulf hurricane",
    "Beatles title girl",
    "Moreno of \"West Side Story\"",
    "Journalist Skeeter of the Harry Potter books",
    "Meter maid of song",
    "Romance novelist's award",
    "Moreno of \"West Side Story\"",
    "Actress Hayworth",
    "Actress Hayworth",
    "Girl in a Beatles title",
    "Funnywoman Rudner",
    "Annual romance writer's award",
    "\"Lovely\" Beatles girl",
    "Actress Hayworth",
    "Hayworth of \"Cover Girl\"",
    "Meter maid of song",
    "Poet laureate Dove",
    "Beatles meter maid",
    "\"Lovely\" Beatles girl",
    "Singer Coolidge",
    "\"Lovely\" Beatles girl",
    "The Beatles' \"Lovely ___\"",
    "Comic Rudner",
    "\"Lovely ___, meter maid\" (Beatles lyric)",
    "Beatles girl \"filling in a ticket in her little white book\"",
    "Hurricane of 2005",
    "Reporter Skeeter of Harry Potter novels",
    "Meter maid of song",
    "Meter maid of song",
    "Missing from 37-Across",
    "Novelist ___ Mae Brown",
    "Romance fiction award",
    "Pinup Hayworth",
    "Title girl in a song by 35-Down",
    "Hayworth of \"Pal Joey\"",
    "\"Lovely\" Beatles girl",
    "Singer Coolidge",
    "Beatles meter maid",
    "Romance novelist's award",
    "Comic Rudner",
    "Comic Rudner",
    "Girl in Lou Bega's \"Mambo No. 5\"",
    "Girl in a Beatles song",
    "\"Lovely\" Beatles girl",
    "\"Rubyfruit Jungle\" novelist ___ Mae Brown",
    "Comedian Rudner",
    "Romance novelist's award",
    "Meter maid of song",
    "Novelist ___ Mae Brown",
    "Meter maid of song",
    "Sultry Hayworth",
    "Moreno of \"West Side Story\"",
    "Dove with a Pulitzer",
    "\"Lovely\" Beatles girl",
    "\"Educating ___\" (1983 film)",
    "The Beatles' \"Lovely ___\"",
    "Singer Coolidge",
    "Singer Coolidge",
    "Meter maid of song",
    "Multitalented Moreno",
    "Comic Rudner",
    "Beatles' meter maid",
    "Singer Coolidge",
    "CBS reporter Braver",
    "Title woman in a Beatles song",
    "Romance writer's reward",
    "Poet Dove",
    "\"Lovely\" Beatles girl",
    "Author ___ Mae Brown",
    "Romance writer's award",
    "Meter maid of song",
    "The Beatles' meter maid",
    "Rudner of comedy",
    "Former U.S. poet laureate ___ Dove",
    "Actress Hayworth",
    "She played Gilda in \"Gilda\"",
    "Romance writer's award",
    "\"Lovely ___\" (1967 song)",
    "Singer Coolidge",
    "Moreno of \"West Side Story\"",
    "Singer Coolidge",
    "Actress Moreno",
    "Lovely ticketer of song",
    "Pinup Hayworth",
    "Beatles girl",
    "Comedienne Rudner",
    "Miss Hayworth",
    "One of Orson?Æs exes",
    "Actress Hayworth",
    "Tushingham of ?ôDoctor Zhivago?ö",
    "Novelist ___ Mae Brown",
    "Actress Moreno",
    "Beatles' meter maid",
    "U.S. poet laureate ___ Dove",
    "Comic Rudner",
    "Girl in a Beatles song",
    "Comic Rudner",
    "Singer Coolidge"
  ],
  "hong": [
    "___ Kong",
    "___ Kong",
    "___ Kong",
    "___ Kong",
    "\"Wayne's World 2\" actor James",
    "___ Kong",
    "___ Kong",
    "___ Kong",
    "___ Kong, China",
    "___ Kong",
    "___ Kong"
  ],
  "fray": [
    "Melee",
    "Start to come apart",
    "Start to deteriorate, as a cord",
    "Melee",
    "Show wear",
    "Melee",
    "Begin to show wear",
    "Skirmish",
    "Skirmish",
    "Wear at the edges",
    "Unravel, as a cord",
    "Wear out by rubbing",
    "Scuffle",
    "Wear on, as the nerves",
    "Donnybrook",
    "Skirmish",
    "Scuffle",
    "Come apart at the seams",
    "Row",
    "Become raveled",
    "Brawl",
    "Wear out",
    "Become raveled",
    "Scuffle"
  ],
  "lipo": [
    "Abdominal procedure, for short",
    "T'ang dynasty poet",
    "Fat-removing surgery, for short",
    "Slimming surgery, for short",
    "Fat removal procedure, in brief",
    "Fat remover, for short",
    "Job for a plastic surgeon, for short",
    "Fat removal, briefly",
    "Contouring procedure, briefly",
    "Slimming surgery, informally",
    "Slimming technique, briefly",
    "Plan for losers, informally?",
    "Surgery that takes weight off, informally",
    "Certain elective surgery, for short",
    "Slimming surgery, slangily",
    "Alternative to dieting, informally",
    "Quick weight loss method, for short",
    "Way to slim down, for short",
    "Middle management?",
    "Quick weight loss option, informally",
    "Slimming option, briefly",
    "Slimming option, for short",
    "Tang dynasty poet",
    "Slimming procedure, briefly",
    "T'ang dynasty poet",
    "T'ang dynasty poet",
    "Prefix with suction",
    "Alternative to dieting, briefly",
    "Fat removal, for short",
    "Certain surgery, for short",
    "Certain surgery, for short",
    "T'ang dynasty poet",
    "Prefix with suction",
    "Poet of the T'ang dynasty",
    "Prefix with suction",
    "Contemporary of Tu Fu"
  ],
  "olay": [
    "Skin-care brand",
    "Maker of Regenerist skin cream",
    "Product once advertised with the line \"The splendor of your skin\"",
    "Cosmetics brand with \"Face anything\" ads",
    "Skin care brand",
    "Regenerist skin-care brand",
    "Skin care brand",
    "Brand of \"anti-aging\" products",
    "Billion-dollar Procter & Gamble brand",
    "Big name in skin care",
    "Neutrogena competitor",
    "Neutrogena rival",
    "Beauty product line with the slogan \"Ageless\"",
    "Big name in oil?",
    "Nivea rival",
    "Regenerist brand",
    "Skin care brand",
    "Brand name that sounds like a cheer",
    "Procter & Gamble product line",
    "Maker of the face cream Regenerist",
    "Facial moisturizing brand",
    "Brand of body washes",
    "Cosmetics brand",
    "Anti-aging product name",
    "Neutrogena competitor",
    "Oil of ___",
    "Procter & Gamble skin care brand",
    "Oil producer",
    "Big name in anti-aging products",
    "Skin care brand",
    "Brand of facial moisturizer",
    "Oil of ___",
    "Oil of ___",
    "Oil of ___",
    "Oil that's not really oily",
    "Oil of ___",
    "Oil of ___",
    "Famous name in oil",
    "Oil of ___",
    "Oil of ___",
    "See 40-Down",
    "Oil of ___",
    "Oil of ___",
    "Word on some Procter and Gamble lotions",
    "Oil of ___",
    "Oil of ___",
    "Oil of ___",
    "Oil of ___",
    "Oil of ___",
    "Oil of ___",
    "Oil of ___"
  ],
  "rusk": [
    "Dry biscuit used as baby food",
    "1960s secretary of state Dean",
    "Kennedy's secretary of state",
    "J.F.K./L.B.J. cabinet member",
    "Twice-baked bread",
    "Kennedy's Secretary of State",
    "J.F.K.'s Secretary of State",
    "Kennedy Cabinet member"
  ],
  "toil": [
    "Work, work, work",
    "Word in a Shakespearean incantation",
    "Work",
    "Grind",
    "Work up a sweat",
    "Hard labor",
    "Work hard",
    "Hard labor",
    "Labor",
    "Work like a dog",
    "Work, work, work",
    "Work hard",
    "Emulate an esne",
    "*Work hard",
    "Work hard",
    "Slave away",
    "Bust a hump",
    "Slave away",
    "Slave",
    "\"Double, double, ___ and trouble\"",
    "Labor",
    "Sweat",
    "Ditch-digging, e.g.",
    "Labor",
    "Trouble's partner",
    "Trouble's partner",
    "Labor",
    "Slave",
    "Grind",
    "Grind",
    "Drudgery",
    "Slave",
    "Hoe and weed, e.g.",
    "Trouble's partner, in Shakespeare",
    "Work like a dog",
    "Trouble's partner",
    "Sweat",
    "Struggle",
    "Sweat",
    "Work hard",
    "Slave away",
    "It's not light work",
    "Hard work",
    "Slave away",
    "Slave away",
    "It might make you a sweater",
    "Labor",
    "Plug away",
    "Work like Tillie?",
    "Travail",
    "Exertion",
    "Break one's back",
    "Labor",
    "Work hard",
    "Elbow grease",
    "Slave away",
    "Hard work",
    "Hard work",
    "Slave away",
    "Plug away",
    "Sweat",
    "Drudgery",
    "Trouble's partner",
    "Labor",
    "Peg away",
    "Drudgery",
    "Labor",
    "Work",
    "Sweat and strain",
    "Hard labor",
    "Elbow grease",
    "Hard labor",
    "Slave away",
    "Sweat (over)"
  ],
  "vial": [
    "Vaccine holder",
    "Container for nitroglycerin, say",
    "Serum vessel",
    "Serum container",
    "Lab container",
    "Potion container",
    "Perfume container",
    "Apothecary container",
    "Apothecary's container",
    "Holder of small doses",
    "Lab container",
    "Lab container",
    "Apothecary item",
    "Smelling salts holder",
    "It might hold the solution",
    "Serum container",
    "Cousin of an ampule",
    "Small bottle",
    "Medicine holder",
    "Medicine bottle",
    "Lab container",
    "Lab container",
    "Baby bottle?",
    "Apothecary container",
    "Lab bottle",
    "Lab vessel",
    "Lab vessel",
    "Glass container in a lab",
    "Perfume holder",
    "Small container for liquids",
    "Smelling salts container",
    "Serum container",
    "Medicine bottle",
    "Medicine bottle",
    "Specimen holder",
    "Tiny bottle",
    "Lab container",
    "Ampule's kin",
    "Lab glass",
    "Ampule",
    "Ampule",
    "Lab glass",
    "Poison holder",
    "Pharmacist's container",
    "Perfume holder",
    "Medicine container",
    "Test tube",
    "Lab bottle"
  ],
  "tern": [
    "Bird with an annual 18,000-mile round-trip migration",
    "Relative of a sea gull",
    "Soarer by the shore",
    "Slender shorebird",
    "\"Leave no ___ unstoned\" (jocular phrase)",
    "Polar bird",
    "Bird found on all continents, including Antarctica",
    "Fork-tailed bird",
    "Migratory seabird",
    "Bird with a two-pointed tail",
    "Arctic ___",
    "Arctic ___",
    "Fork-tailed bird",
    "Coastal diver",
    "Arctic ___ (migrating bird)",
    "Slender shorebird",
    "Coastal diver",
    "Coastal plunger",
    "Arctic ___ (pole-to-pole migrator)",
    "Arctic or Antarctic fish-eater",
    "Fork-tailed flier",
    "Cousin of a gull",
    "Seabird",
    "Shorebird",
    "Shore flier",
    "Coastal bird",
    "Arctic ___",
    "Gull relative",
    "Cousin of a gull",
    "Seabird with a forked tail",
    "Bird with a forked tail",
    "Sea bird",
    "Coastal predator",
    "Shore sight",
    "Sea swallow",
    "Fork-tailed flier",
    "Beach bird",
    "Flier around a lighthouse",
    "It has a forked tail",
    "Relative of a gull",
    "Fork-tailed bird",
    "Beach bird",
    "Aquatic bird",
    "Relative of a sea gull",
    "Shorebird",
    "Sea swallow",
    "Gull-like bird",
    "Seabird",
    "Relative of a gull",
    "Graceful bird",
    "Beach bird",
    "Beach sight",
    "Fork-tailed flier",
    "Graceful aquatic bird",
    "Bird on a beach",
    "Arctic ___",
    "Cousin of a gull",
    "Sea swallow",
    "Slender-billed sea bird",
    "\"... leave no ___ unstoned\"",
    "Shore flier",
    "Ocean flier",
    "Sea swallow",
    "Minnow eater",
    "Gull's cousin",
    "Ocean flyer",
    "Gull's cousin"
  ],
  "viol": [
    "Instrument that's a homophone of 69-Down",
    "One of a family of stringed instruments",
    "Popular Renaissance instrument",
    "Six-stringed instrument",
    "Relative of a cello",
    "Alto clef instrument",
    "Baroque stringed instrument",
    "Ancestor of a cello",
    "Instrument in Vermeer's \"The Music Lesson\"",
    "Instrument played with a bow",
    "Its players bow",
    "Fretted fiddle",
    "Fretted instrument",
    "Six-stringed instrument",
    "Old fiddle",
    "Instrument played with a bow",
    "Six-stringed instrument",
    "Six-string",
    "Bass ___",
    "Its players take a bow",
    "It has a low-arched bridge",
    "It takes a bow at a recital",
    "Bass___",
    "Six-string",
    "Bass ___",
    "Early stringed instrument",
    "Bass ___",
    "Fat fiddle"
  ],
  "inri": [
    "Crucifix inscription inits.",
    "Crucifix inscription",
    "Crucifix inscription",
    "Letters on a crucifix",
    "Letters on a crucifix",
    "Cross inscription",
    "Crucifixion letters",
    "Cross initials",
    "Cross inscription",
    "Letters on a crucifix",
    "Religious inscription",
    "Calvary inscription",
    "Calvary inscription",
    "Calvary initials",
    "Cross reference?",
    "Sign letters on the cross",
    "Cross letters",
    "Crucifix letters",
    "Cross letters",
    "Crucifix letters",
    "Letters on a crucifix",
    "Cross inscription",
    "Cross inscription",
    "Letters on a cross",
    "Cross letters",
    "Crucifix inscription",
    "Letters on a cross",
    "Sign of the cross",
    "Initials on a cross",
    "Crucifix inscription",
    "Cross letters",
    "Letters on a cross",
    "Religious inscription",
    "Cross inscription",
    "Crucifix letters",
    "Cross inscription",
    "Cross inscription",
    "Cross letters",
    "Crucifix letters",
    "Crucifix inscription",
    "Calvary letters",
    "Crucifix letters",
    "Calvary inscription",
    "Cross reference?",
    "Cross inscription",
    "Religious inscription",
    "Religious inscription",
    "Cross letters",
    "Cross letters",
    "Letters on a cross",
    "Calvary inscription",
    "Cross characters",
    "Christian inscription",
    "Crucifix inscription",
    "Crucifix inscription",
    "Crucifix letters",
    "Crucifix inscription",
    "Cross inscription",
    "Cross letters",
    "Cross letters",
    "Letters for Jesus",
    "Cross inscription",
    "Letters from Calvary",
    "Crucifix letters",
    "Cross initials",
    "Letters on a cross",
    "Cross letters",
    "Letters on a cross",
    "Biblical letters",
    "Cross inscription",
    "Biblical initials"
  ],
  "east": [
    "___ New York (Brooklyn neighborhood)",
    "With 27-Down, island nation near Indonesia",
    "Steinbeck's \"___ of Eden\"",
    "Jet stream direction",
    "___ Asia (China, Japan and environs)",
    "Polo course?",
    "Three o'clock, so to speak",
    "From Eden to the land of Nod, e.g.",
    "Big ___ (40-Across conference)",
    "The right way?",
    "Toward sunrise",
    "By the dawn's early light?",
    "It's right there on the map!",
    "Part of 21-Across",
    "\"It is the ___, and Juliet is the sun\"",
    "90?? on a compass",
    "Whence the Three Wise Men, with \"the\"",
    "1899-12-30T03:00:00.000Z",
    "Dawn's direction",
    "Dawn's direction",
    "___ Village (Manhattan neighborhood)",
    "That's right!",
    "Heading in the right direction?",
    "___ Germany",
    "Toward sunrise",
    "Word after Near, Middle or Far",
    "It's right on the map",
    "Only direction not in a state name",
    "Around 90??",
    "Three o'clock, so to speak",
    "Big ___ Conference",
    "Wrong Way Corrigan's wrong way",
    "Wind tile in mah-jongg",
    "Big ___ Conference",
    "Big ___ Conference",
    "Manhattan's ___ Village",
    "Right on a map",
    "New York City's ___ River",
    "\"It is the ___, and Juliet is the sun\": Romeo",
    "Heading for Marco Polo",
    "Big ___ Conference",
    "Right on the map",
    "Far ___",
    "The White House's ___ Room",
    "A.L. division for the Yankees",
    "Middle ___",
    "Jet stream's heading",
    "Atlantic Coast states, with \"the\"",
    "China and environs, with \"the\"",
    "Big ___",
    "One side in the annual Shrine Game",
    "Toward sunrise",
    "Sports division",
    "See 64-Across",
    "One N.B.A. All-Star Game team",
    "3:00, on a compass",
    "A.L. or N.L. division",
    "New York City's ___ River",
    "One team in the N.B.A. All-Star Game, with \"the\"",
    "Big ___ Conference",
    "Sunrise direction",
    "___ Anglia",
    "Bridge position",
    "Big ___ (sports conference)",
    "Toward the rising sun",
    "___ Room (largest room in the White House)",
    "Part of the U.S. that's usually first with election returns",
    "The White House's ___ Room",
    "Compass heading",
    "Toward sunrise",
    "Bridge hand",
    "New York's ___ River",
    "League division",
    "Sunup direction",
    "Defender in a bridge column",
    "Toward sunrise",
    "Point in the right direction?",
    "Direction after Near, Middle or Far",
    "\"___ of Eden\"",
    "The \"E\" in N.L.E.",
    "Bridge position",
    "A.L. or N.L. division",
    "Toward the dawn",
    "Sunrise direction",
    "Sunup direction",
    "Near ___",
    "Atlantic Seaboard states, with \"the\"",
    "Sunrise direction",
    "New York's ___ River",
    "Toward the rising sun",
    "Big ___ Conference",
    "The right point?",
    "American League division",
    "Orient",
    "Sunrise direction",
    "\"Yonder window,\" according to Romeo",
    "Orient",
    "Big ___ Conference",
    "Wicked witch's home in \"The Wizard of Oz\"",
    "Direction of sunup",
    "Rightmost bridge position",
    "League division",
    "Bridge hand",
    "___ St. Louis, Ill.",
    "Magi's origin, with \"the\"",
    "Point in the right direction?",
    "Toward the rising sun",
    "Magi's origin",
    "Right on the map",
    "90?? from north",
    "Steinbeck title starter",
    "Toward dawn",
    "Big ___",
    "Marco Polo's heading",
    "Atlantic Coast area, with \"the\"",
    "\"___ of Eden\"",
    "Whence daybreak",
    "Point in the right direction?",
    "All-Star team, with \"the\"",
    "All-star game side",
    "Bridge hand",
    "Point to the right",
    "Toward dawn",
    "What the Lincoln Memorial faces",
    "Down ___",
    "1899-12-30T03:00:00.000Z",
    "1899-12-30T03:00:00.000Z",
    "With 22-Down, Korea's location",
    "New York City's ___ River",
    "Right on the map",
    "Biblical star locale, with \"the\"",
    "Point to the right?",
    "___ River (what the Brooklyn Bridge spans)",
    "Atlantic Coast states, with \"the\"",
    "Right on a map",
    "See 115-Down",
    "How the Lincoln Memorial faces",
    "\"___ of Eden\"",
    "Far or down follower",
    "\"It is the ___, and Juliet is the sun!\"",
    "Bridge position",
    "Sunrise direction",
    "Right on the map",
    "Orient",
    "Toward sunrise",
    "Magi's origin",
    "Needle point?",
    "League division",
    "90 degrees",
    "Sunrise direction",
    "Down ___ (Maine)",
    "Opponent of 28-Across",
    "N.C.A.A. tournament division",
    "How Phileas Fogg traveled",
    "Down ___ (Maine)",
    "It's right on the map",
    "Orient",
    "N.C.A.A. tournament division",
    "Bridge seat",
    "Sunrise direction",
    "N.L. ___",
    "National League division",
    "Orient",
    "Whence the Magi, with \"the\"",
    "Bridge position",
    "It may be due",
    "Magi origin",
    "Sun spot?",
    "Whence the Magi, with \"the\"",
    "Like Nod, to Eden",
    "Certain all-star team",
    "It may be due",
    "Atlantic states, with \"the\"",
    "New York's ___ River",
    "All-star game side, often",
    "New England's locale",
    "Right on the map",
    "___ Orange, N.J.",
    "Bridge position",
    "Down ___ (Maine)",
    "Near ___",
    "White House's ___ Room",
    "Whence the Magi, with \"the\"",
    "90 degrees",
    "White House's ___ Room",
    "Atlantic Coast states, with \"the\"",
    "It's right in the atlas",
    "Bridge seat",
    "Orioles' division, with \"the\"",
    "Big ___ Conference",
    "90 degrees, on a compass",
    "Atlantic Seaboard, with \"the\"",
    "Sunrise direction",
    "N.F.C. division",
    "Part of SEATO",
    "How the Amazon flows",
    "N.F.C. ___",
    "It may be Far or Near",
    "Daybreak direction",
    "90 degrees",
    "It's right on your map",
    "Side in an all-star game",
    "Eden-to-Nod direction",
    "90 degrees",
    "N.F.L. conference",
    "Bridge seat",
    "Asia, with \"the\"",
    "Toward sunup",
    "N.C.A.A. regional",
    "Part of SEATO",
    "Sunup site",
    "Steinbeck's \"___ of Eden\"",
    "Dummy position",
    "One End of London",
    "Compass point",
    "See 17 Across",
    "Part of 14-Down",
    "Toward the Levant",
    "Toward sunrise",
    "Asia, with \"the\"",
    "Bridge seat",
    "Sunup direction",
    "Locale of Shakespeare's \"fiery portal\"",
    "Orient",
    "Team in an annual all-star game",
    "Bridge seat",
    "Asia, the \"the\"",
    "Big ___conference",
    "Bridge position"
  ],
  "yang": [
    "2020 Democratic also-ran",
    "Yin's partner",
    "Light side",
    "Andrew ___, businessman-turned-politician",
    "Bright side, in Chinese philosophy",
    "Counterpart to 24-Across",
    "Lighter side?",
    "The so-called \"sunny side\"",
    "Positive Chinese principle",
    "Masculine side",
    "Masculine side",
    "Light principle",
    "Positive principle",
    "Positive principle",
    "Light side",
    "Masculine side",
    "Masculine principle",
    "Jerry who co-founded Yahoo!",
    "Masculine side",
    "Masculine side",
    "The bright side?",
    "Masculine side",
    "Masculine side",
    "Yin's counterpart",
    "Yin's counterpart",
    "Masculine side",
    "Yin's opposite",
    "Masculine principle",
    "The bright side?",
    "Masculine side",
    "Positive principle",
    "Masculine side",
    "Yin's complement",
    "Yin's partner",
    "Bright side?",
    "Positive principle",
    "Yin's opposite",
    "Yin's counterpart",
    "Yin's partner"
  ],
  "love": [
    "It's nada to Nadal",
    "Domain of Venus and Aphrodite",
    "Letter sign-off",
    "Score before 15, in tennis",
    "Classic pop art sculpture with a slanted \"O\"",
    "What a lock on a bridge can symbolize",
    "Zero",
    "It \"keeps the cold out better than a cloak,\" per Longfellow",
    "Dearest",
    "Zero, at Ashe Stadium",
    "Letter sign-off",
    "Valentine's feeling",
    "Treasure",
    "Starting score in tennis",
    "\"A temporary insanity,\" per Ambrose Bierce",
    "\"___ is composed of a single soul inhabiting two bodies\": Aristotle",
    "Romance",
    "\"A madness most discreet,\" per Romeo",
    "\"The occupation of the idle man, the distraction of the warrior, the peril of the sovereign,\" per Napoleon",
    "*Valentine sentiment",
    "It means nothing",
    "Sonnet subject",
    "Troubadour's subject",
    "Serenader's subject",
    "Zero, in tennis",
    "It is \"resistless in battle,\" wrote Sophocles",
    "Aphrodite's domain",
    "All you need, in a Beatles song",
    "Something often fallen out of",
    "It's nothing",
    "XOXOXO alternative",
    "Zero",
    "All you need, to the Beatles",
    "All you need, according to the Beatles",
    "With 21-Across, heartsick",
    "Motherly instinct",
    "Valentine subject",
    "Score of zero",
    "Serenader's sentiment",
    "___-all (score)",
    "Zero, on a court",
    "Honey",
    "It means nothing to 45-Across",
    "First-sight phenomenon",
    "15 preceder",
    "It \"makes the world go round\"",
    "Score before 15",
    "It means nothing to Navratilova",
    "Starting point in tennis",
    "Zero, on a court",
    "Zero, on a court",
    "It means nothing to some people",
    "Troubadour's subject",
    "Nothing on the court",
    "It precedes 15",
    "It's nothing to Agassi",
    "Score before 15",
    "Like some tennis games",
    "One of the Beach Boys",
    "Coleridge poem",
    "Tennis score",
    "Robert Indiana painting",
    "World rotator?",
    "It's nothing"
  ],
  "siri": [
    "Who's talking on the phone?",
    "Assistant with a goofy beatboxing routine (just ask)",
    "Rival of Alexa",
    "One with a phony personality?",
    "Apple's digital assistant",
    "Assistant who asks \"What can I help you with?\"",
    "Noted speaker of more than 20 languages",
    "Modern know-it-all",
    "Who asks \"What can I help you with?\"",
    "In answer to the request \"Talk dirty to me,\" she sometimes says \"The carpet needs vacuuming\"",
    "One always getting asked questions",
    "Tech assistant",
    "One frequently saying \"Sorry, I missed that\"",
    "iPhone voice",
    "Popular assistant",
    "Who often says \"I found this on the web\"",
    "Voice-activated assistant",
    "Modern answer source",
    "Voice of the Apple Watch",
    "Assistant to millions",
    "\"Hey ___\"",
    "Voice on a phone",
    "\"Hey ___\" (start of a phone voice command)",
    "Real know-it-all?",
    "One who talks on the phone a lot?",
    "Who asks \"What can I help you with?\" on an iPhone",
    "Familiar voice since 2011",
    "iPhone assistant",
    "Voice-activated assistant",
    "One talking on the phone, nowadays?",
    "Modern know-it-all",
    "iPhone assistant",
    "When told \"I'm sleepy,\" she sometimes says \"I hope you're not driving\"",
    "Voice from a phone",
    "One with all the answers?",
    "iPhone assistant who says that \"42\" is the meaning of life",
    "Modern know-it-all?",
    "Apple genius?",
    "iPhone talker",
    "Her name is Norwegian for \"beautiful woman who leads you to victory\"",
    "In Australia her name is Karen",
    "Her \"birthday\" is Oct. 4, 2011",
    "iPhone voice",
    "Apple assistant",
    "Her voice was first heard in 2011",
    "Phone voice?"
  ],
  "nash": [
    "Mathematician John Forbes ___ Jr.",
    "Poet Ogden",
    "See 16-Across",
    "Actress Niecy on the Hollywood Walk of Fame",
    "Ogden who wrote \"Farewell, farewell, you old rhinoceros, / I'll stare at something less prepoceros\"",
    "First automaker to offer seatbelts as an option (1949)",
    "Crosby, Stills, ___ & Young",
    "Who wrote \"Parsley / Is gharsley\"",
    "\"A bit of talcum / Is always walcum\" writer",
    "First car to offer seatbelts (1950)",
    "Poet who wrote \"In the Vanities / No one wears panities\"",
    "Automaker that introduced the Rambler",
    "\"A bit of talcum / Is always walcum\" writer",
    "Mathematician John portrayed in \"A Beautiful Mind\"",
    "Longtime Crosby partner",
    "TV's \"___ Bridges\"",
    "Bandmate of Crosby, Stills and Young",
    "Crosby, Stills, ___ & Young",
    "Poet who wrote \"So Thomas Edison / Never drank his medicine\"",
    "Poet Ogden",
    "Humorist who wrote \"Happiness is having a scratch for every itch\"",
    "Mathematician John who was the subject of \"A Beautiful Mind\"",
    "Poet Ogden",
    "Humorist who wrote \"Progress might have been all right once, but it has gone on too long\"",
    "Part of rock's CSNY",
    "Two-time N.B.A. M.V.P. Steve",
    "Ambassador of old autodom",
    "Ogden who wrote \"Candy / Is dandy / But liquor / Is quicker\"",
    "Ambassador of old autodom",
    "Crowe's role in \"A Beautiful Mind\"",
    "He wrote \"If called by a panther, / Don't anther\"",
    "Auto superseded by the Rambler",
    "Graham of rock",
    "Poet Ogden",
    "Ambassador or Statesman of old autodom",
    "Ogden who wrote \"The Bronx? / No, thonx!\"",
    "He wrote a hit Broadway musical with Weill",
    "Bronx/thonx rhymer",
    "Crowe's \"A Beautiful Mind\" role",
    "\"The Turtle\" poet",
    "Ambassador or Statesman, once",
    "Car until 1957",
    "Famous rhymer of Bronx with \"thonx\"",
    "Bygone auto",
    "\"The Turtle\" poet",
    "His poem \"Fleas\" reads, in its entirety, \"Adam / Had 'em\"",
    "Car last made in 1957",
    "Ambassador of old autodom",
    "\"Candy / Is dandy / But liquor / Is quicker\" poet",
    "\"The Bronx? No thonx\" writer",
    "Ogden who wrote light verse",
    "\"Liquor is quicker\" poet",
    "\"A Beautiful Mind\" fellow",
    "Old Rambler manufacturer",
    "\"A bit of talcum / Is always walcum\" poet",
    "Car until 1957",
    "\"Bronx\"/\"thonx\" rhymer",
    "Rambler maker",
    "\"Reflections on Ice-Breaking\" poet",
    "Pun-prone poet",
    "\"I'm a Stranger Here Myself\" poet",
    "First auto to offer seat belts",
    "\"Hard Lines\" poet",
    "\"One-L lama\" poet",
    "Musical partner of Crosby and Stills",
    "\"Candy / Is dandy...\" humorist",
    "\"A bit of talcum / Is always walcum\" writer",
    "Partner of Crosby and Stills",
    "DeSoto contemporary",
    "He wrote \"The Bronx? No, thonx!\""
  ],
  "razz": [
    "Boo-oo-oo, say",
    "Heckle",
    "Ride",
    "Twit",
    "Give a Bronx cheer",
    "Ride",
    "Heckle",
    "Give a hard time",
    "Bronx cheer",
    "Tease",
    "Tease",
    "Heckle",
    "Give the Bronx cheer",
    "Tease",
    "Tease",
    "Bronx cheer",
    "Tease",
    "Give a Bronx cheer to",
    "Tease"
  ],
  "owie": [
    "Boo-boo",
    "Boo-boo",
    "Tot's injury",
    "Boo-boo",
    "Toddler's boo-boo",
    "Minor cut, say",
    "Painful injury, in totspeak",
    "Boo-boo",
    "Scraped knee, in totspeak",
    "Scrape, to a tot",
    "Baby's boo-boo",
    "Day care knee scrape, e.g.",
    "Baby bump?",
    "Injury, in totspeak",
    "Painful boo-boo",
    "Little injury, in baby talk",
    "Boo-boo",
    "Baby's cut",
    "Boo-boo",
    "Source of pain, in kidspeak",
    "Boo-boo",
    "\"Ooh, dat hurt!\"",
    "Boo-boo",
    "Little injury, to a toddler",
    "Boo-boo",
    "Cut or scrape, in totspeak",
    "Baby's boo-boo",
    "Painful boo-boo",
    "Boo-boo",
    "Boo-boo",
    "Tot's injury",
    "Skinned knee, say, to a tot",
    "Boo-boo",
    "Tot's injury",
    "Sting, in baby talk",
    "Child's cry of pain",
    "Toddler's cry of pain",
    "\"That hurt!\"",
    "Boo-boo"
  ],
  "daub": [
    "Smear",
    "Apply sloppily",
    "Smear",
    "Paint carelessly",
    "Besmear, as a canvas",
    "Apply, as plaster",
    "Apply carelessly, as paint",
    "Paint crudely",
    "Apply, as plaster",
    "Apply sloppily",
    "Put a coat on sloppily, say",
    "Apply roughly",
    "Paint none too delicately",
    "Smear (on)",
    "Smear",
    "Plaster",
    "Slap on",
    "Apply carelessly, as paint",
    "Paint unskillfully",
    "Paint crudely",
    "Smear",
    "Paint without trying to stay within the lines, say",
    "Slap on",
    "Spread (on)",
    "Coat with plaster, say",
    "Paint amateurishly",
    "Coat, as with plaster",
    "Brush carelessly",
    "Paint unskillfully",
    "Coat, with \"on\"",
    "Paint poorly",
    "Paint unskillfully",
    "Paint unskillfully",
    "Smear"
  ],
  "etsy": [
    "Site for some creative entrepreneurs",
    "Etail site for handmade goods",
    "Website for 20-Across items",
    "Website with a Seller Handbook",
    "Website with much custom-designed jewelry",
    "Online crafts shop",
    "Site for crafty sorts?",
    "Handicraft website",
    "Online craft fair, of sorts",
    "Crafty online marketplace",
    "Site that competes with Amazon Handmade",
    "Website with the headings \"Craft Supplies\" and \"Jewelry & Accessories\"",
    "Online market for craftspeople",
    "Big e-commerce site",
    "Place to buy and sell online",
    "Handicraft e-tail site",
    "E-commerce site with handmade crafts",
    "Online handicrafts marketplace",
    "Handicrafts e-tailer",
    "Place to order handmade goods",
    "Site for handmade goods",
    "Website for craftspeople",
    "Place for unique gifts",
    "Crafty website",
    "Where crafty people may be found online?",
    "Online marketplace since 2005",
    "Website for some custom-designed jewelry",
    "Craft-selling site",
    "Competitor of Amazon Handmade",
    "Website for handmade items",
    "E-tail site since 2005",
    "Online handicrafts marketplace",
    "Craftsy online store",
    "Website for craft vendors",
    "Online market for crafts",
    "Handmade products website",
    "Crafts site",
    "Craft company with a 2015 I.P.O.",
    "Online market for crafts",
    "Online crafts seller",
    "Online crafts seller",
    "Website used by a lot of artisans",
    "Place to buy handmade goods online",
    "Popular crafts site",
    "Crafts website",
    "Crafty e-tailer",
    "Online crafts site",
    "E-tailer of homemade knickknacks",
    "Modern collection of vendors"
  ],
  "etna": [
    "What Vulcan's forge lay underneath, in myth",
    "Sicily's highest peak",
    "Sicily's Parco dell'___",
    "Active Sicilian volcano",
    "Sicilian spewer",
    "Active Sicilian volcano",
    "Italian stratovolcano",
    "Mount ___, workplace of the Cyclopes in Greek myth",
    "It destroyed the town of Mascali in 1928",
    "Sight from the Sicilian town of Taormina",
    "Its name is derived from the Greek for \"I burn\"",
    "Sicilian erupter",
    "Europe's tallest volcano",
    "Destroyer of the town of Nicolosi in 1669",
    "Site of the Bocca Nuova crater",
    "Smoking hot Italian?",
    "What Zeus trapped Typhon under, in myth",
    "Italian mount",
    "Active Sicilian peak",
    "Periodic Sicilian erupter",
    "Location of Hephaestus' forge",
    "Sicilian peak",
    "Sicily's Mount ___",
    "___ Rosso (Sicilian wine)",
    "Sicilian tourist draw",
    "Europe's highest volcano",
    "Mediterranean tourist attraction",
    "Artist Joseph Wright's \"A View of Catania With Mount ___ in the Distance\"",
    "Virgil described its eruption in the \"Aeneid\"",
    "Frequent Sicilian erupter",
    "Mount whose name means, literally, \"I burn\"",
    "European stratovolcano",
    "Sicilian volcano",
    "Sicily's Mount ___",
    "European smoker",
    "Tallest active volcano in Europe",
    "European eruption site",
    "Mount that has an insurance company named after it",
    "Italian source of smoke",
    "Zeus trapped Typhon under it",
    "Mount SW of Messina",
    "Europe's largest active volcano",
    "Hephaestus' forge is said to be under it",
    "Sicilian erupter",
    "Sicilian volcano",
    "Mediterranean volcano",
    "View from Catania",
    "Volcano at the meeting point of the African and Eurasian plates",
    "Sicilian volcano",
    "Sicilian volcano",
    "Geographical eponym of an insurance company",
    "European erupter",
    "Volcano in Catania",
    "One of three active volcanoes in Italy",
    "Mount that's a poker term when read backward",
    "Sicilian volcano",
    "Italian mount",
    "Place to ski in Italy",
    "Sicilian peak",
    "Europe's highest volcano",
    "World Heritage Site since 2013",
    "Zeus trapped Typhon under it",
    "European smoker",
    "Italian hothead?",
    "Backdrop for the final scene of Antonioni's \"L'Avventura\"",
    "Sicilian rumbler",
    "Noted stratovolcano",
    "About a quarter of the population of Sicily lives on its slopes",
    "Site of an occasional outbreak in Sicily",
    "Source of many Sicilian explosions",
    "Site of the Bocca Nuova crater",
    "Typhon was trapped under it, in Greek myth",
    "Blast maker of 2002",
    "Site of Cyclops' smithy",
    "Home of Typhon, in myth",
    "View from Biancavilla",
    "Sicilian mount",
    "Sicilian volcano",
    "Italian hot spot",
    "Image in the final scene of Michelangelo Antonioni's \"L'Avventura\"",
    "\"Vulcan's chimney\"",
    "Italian mount",
    "Italian peak",
    "Blower of giant smoke rings",
    "Volcano whose eruption was described by Virgil",
    "View from Catania",
    "Sicilian spewer",
    "Explosive Sicilian?",
    "Active volcano near Messina",
    "Site of Vulcan's smithy",
    "Sicily's Mt. ___",
    "Sicilian erupter",
    "Sicilian spewer",
    "The Mountain of Fire, to 23-Acrosses",
    "Sicilian hot spot",
    "Volcano that devastated Catania",
    "Italian rumbler",
    "Virgil described its \"roar of frightful ruin\"",
    "Sicilian spewer",
    "Sicilian peak",
    "European smoker",
    "Sight from Taormina",
    "Highest European volcano",
    "Sicily's erupter",
    "Sicilian hot spot",
    "11,000-foot Italian peak",
    "European eruption site",
    "Italy's Mt. ___",
    "Volcano in Verne's \"Journey to the Center of the Earth\"",
    "It's about 2 1/2 times as high as Vesuvius",
    "Sicilian spewer",
    "Sicilian volcano",
    "View from Catania",
    "European erupter",
    "Volcano known to locals as Mongibello",
    "Locale of many Italian vineyards",
    "Largest volcano in Europe",
    "Italian source of 2-Down",
    "Source of an explosion in Italy",
    "Mountain whose name in Greek means \"I burn\"",
    "Sight from Messina",
    "10,900-foot European peak",
    "Sicilian spouter",
    "Italian volcano",
    "Smoky European peak",
    "Valle del Bove locale",
    "Sicilian volcano",
    "Natives call it Mongibello",
    "Italian exploder",
    "Mount SW of Messina",
    "View from the Gulf of Catania",
    "Locale of the Bocca Nuova crater",
    "Italy's Mt. ___",
    "Mediterranean spewer",
    "European erupter",
    "It held down the giant Enceladus, in myth",
    "Sight from Taormina",
    "Spewer of 2002",
    "Sicilian volcano",
    "Longtime smoker",
    "Europe's highest volcano",
    "Pittsburgh suburb with an Old World name",
    "Sicilian peak",
    "Sicilian mount",
    "Where Typhon, a 100-headed monster, was buried, in Greek myth",
    "Sicilian spouter",
    "11,000-foot Italian peak",
    "European erupter",
    "\"Empedocles on ___\" (Matthew Arnold poem)",
    "Sicilian hothead?",
    "2002-3 erupter",
    "Sicilian volcano",
    "Mount SW of Messina",
    "Peak near the Gulf of Catania",
    "Name derived from ancient Greek for \"I burn\"",
    "Mount in Sicily",
    "Sight from Taormina",
    "1669 blast site",
    "Sicilian smoker",
    "Peak near Taormina",
    "Valle del Bove locale",
    "Sicilian volcano",
    "European erupter",
    "European peak",
    "Sicilian erupter",
    "European hot spot",
    "Sicilian smoker",
    "View from Catania",
    "Sicilian spouter",
    "Italian cone maker",
    "Where Enceladus is buried, in myth",
    "2001 erupter",
    "Sicilian spouter",
    "It's active in Sicily",
    "Peak near Taormina",
    "European erupter",
    "Sicilian smoker",
    "11,000-foot Italian peak",
    "Sicilian spouter",
    "Mount known locally as Mongibello",
    "Vulcan's Chimney",
    "Sicilian spouter",
    "Site of the smithy of Cyclops",
    "Catania threatener",
    "Sicilian spouter",
    "View from Catania",
    "Volcano in Sicily",
    "Sicilian peak",
    "It towers over Taormina",
    "Sicilian mount",
    "\"Empedocles on ___\" (Matthew Arnold poem)",
    "Peak in Catania province",
    "Burial place of the Greek giant Enceladus",
    "Erupter of 1992",
    "It's active in Sicily",
    "Sicilian peak",
    "11,000-foot mount in Europe",
    "Sicilian erupter",
    "Where Hephaestus worked, in myth",
    "Site of the forges of Vulcan",
    "Europe's highest volcano",
    "Valle del Bove locale",
    "Sicilian spouter",
    "Sight from Taormina",
    "View from Catania",
    "Sicilian volcano",
    "Sicilian spouter",
    "Sicilian spouter",
    "Frequent blower",
    "Highest European volcano",
    "It's known locally as Mongibello",
    "Sicilian spewer",
    "Valle del Bove locale",
    "Sight from Messina",
    "Literally, \"I burn\"",
    "1998 erupter",
    "Peak near Paterno",
    "Peak seen from the Ionian Sea",
    "Europe's highest active volcano",
    "The Cyclopes' workplace",
    "\"Empedocles on ___\" (Arnold poem)",
    "Sicilian spouter",
    "Sicilian spouter",
    "Sicilian smoker",
    "Sicilian peak",
    "Mount near the Gulf of Catania",
    "Sicilian spouter",
    "Suburb of Pittsburgh",
    "Sicilian smoker",
    "Sicilian rumbler",
    "View from Taormina",
    "Sicilian spouter",
    "It towers over Taormina",
    "Sicilian blower",
    "Mountain known locally as Mongibello",
    "Mount whose name means \"I burn\"",
    "Volcano near Catania",
    "Vulcan's Chimney",
    "Pillar of heaven, to Pindar",
    "Sicilian volcano",
    "Sicilian peak",
    "Site of the fabled forges of the Cyclopes",
    "Lab burner",
    "Lab item",
    "Sicilian sight",
    "Sicilian mount",
    "Sicilian spouter",
    "Lab item",
    "Sicilian mount",
    "1169 erupter",
    "Matthew Arnold's \"Empedocles on ___\"",
    "Sicilian mount",
    "View from the Ionian Sea",
    "Sicilian mount",
    "Item for heating liquids",
    "Sicilian spouter",
    "Sicilian spouter",
    "Europe's highest volcano",
    "Sicilian spouter",
    "Erupter of 1832",
    "View from Catania",
    "Lab equipment",
    "Sicilian spouter",
    "Sicilian spewer",
    "Lab item",
    "Site in Sicily",
    "Sicilian smoker",
    "1169 erupter",
    "Mediterranean spouter",
    "Sicilian rumbler",
    "Mount, north of Catania",
    "1949 erupter",
    "Lab vessel",
    "Erupter of 1669",
    "Vesuvius's Sicilian counterpart",
    "Old lab burner",
    "Vulcan's workshop, in myth"
  ],
  "wear": [
    "Sport",
    "Potential tire trouble",
    "Gradual deterioration",
    "Have on",
    "Sign of overuse",
    "Sport",
    "Put on",
    "Sport, as a sport coat",
    "Sport",
    "Depreciation factor",
    "\"I haven't a thing to ___!\"",
    "Show signs of age",
    "Have on",
    "Have on",
    "Sport",
    "Get tiresome",
    "Clothing",
    "Sport",
    "Grow tiresome",
    "Have on, as clothes",
    "Rock, in modern lingo",
    "Grow tiresome",
    "Sport",
    "Last under use or erode under use",
    "Sport",
    "Get tiresome",
    "Result of regular use",
    "Have on",
    "Sport",
    "Sport",
    "The ravages of time",
    "Bit of fraying",
    "Become tiresome",
    "Grow tiresome",
    "Depreciation consideration",
    "Fray",
    "Become threadbare",
    "\"I haven't a thing to ___!\"",
    "Rub down",
    "Day-to-day deterioration",
    "Have on",
    "Have on",
    "Prove durable",
    "Become frayed",
    "Erode",
    "Don",
    "Become tiresome",
    "Use",
    "Wash's partner",
    "Have on",
    "Have on",
    "Have on",
    "Abrade",
    "Become tiresome",
    "Have on",
    "Have on",
    "Become frayed"
  ],
  "tres": [
    "\"___ chic!\"",
    "Very, to Véronique",
    "Chile's Nevado ___ Cruces National Park",
    "101 word in both Spanish and French",
    "\"___ chic!\"",
    "Number of stripes on la bandera mexicana",
    "Number between dos and cuatro",
    "___ Fronteras (area where Brazil, Peru and Colombia meet)",
    "Numero atomico of lithium",
    "___ leches",
    "Parisian equivalent to \"molto\" or \"muy\"",
    "Dos mas uno",
    "Very, abroad",
    "102-Down, affectedly",
    "Diez minus siete",
    "___ leches cake",
    "Number between dos and cuatro",
    "French 101 word or, with a different meaning, Spanish 101 word",
    "See 47-Across",
    "Very: Fr.",
    "Diez minus siete",
    "Numero of Disney Caballeros",
    "___ jolie",
    "Very, in Versailles",
    "___ chic",
    "Very, in Vichy",
    "Valery's \"very\"",
    "\"___ magnifique!\"",
    "Very, in Vichy",
    "Quarter of doce",
    "Los ___ mosqueteros",
    "\"___ bien!\"",
    "Uno y dos",
    "Ocho minus cinco",
    "Number of Los Lonely Boys",
    "___ jolie",
    "Figure of a Spanish count?",
    "Oh so",
    "Uno y dos",
    "More than un peu",
    "Dos follower",
    "Cube root of veintisiete",
    "Square root of nueve",
    "\"___ bien!\"",
    "Los ___ Reyes Magos",
    "___ chic",
    "Considerably, in Cannes",
    "Siesta time, maybe",
    "Lithium's numero atomico",
    "___ chic",
    "Ocho minus cinco",
    "\"___ Hombres,\" first Top 40 album by ZZ Top",
    "\"___ bien\"",
    "___ chic",
    "Uno + dos",
    "\"___ Hombres\" (ZZ Top record)",
    "Uno + uno + uno",
    "___ Cruces (Andean peak)",
    "___ chic",
    "\"___ bon\"",
    "\"___ chic!\"",
    "\"___ charmant!\"",
    "Cancun crowd?",
    "Dos follower",
    "Andean peak ___ Cruces",
    "\"___ chic\"",
    "\"___ interessant\"",
    "?ô___ bien!?ö",
    "Uno + dos",
    "Very, in Vichy",
    "Number between dos and cuatro",
    "Dos into seis",
    "Very, in Versailles",
    "___ belle",
    "___ chic",
    "\"___ bien!\"",
    "Very, affectedly",
    "Uno y dos",
    "Andean peak ___ Cruces",
    "\"___ interessant\"",
    "Uno y dos",
    "Half of seis",
    "Very, in Vichy",
    "Half of seis",
    "\"___ chic\"",
    "Una hora in the afternoon",
    "A Spanish crowd?",
    "Uno + dos",
    "\"___ bien!\"",
    "\"___ magnifique!\"",
    "___ chic",
    "Number of film caballeros",
    "___ bien",
    "___ chic",
    "Very, in Vichy",
    "Uno + dos",
    "___ chic",
    "___ bien",
    "Spanish crowd?",
    "Very, in Valence",
    "It precedes quatro, in Rio",
    "Ocho minus cinco",
    "___bien",
    "Uno y dos",
    "So very",
    "\"___ bien!\"",
    "Spanish crowd?",
    "\"___ bein!\" (French accolade)",
    "___ bien",
    "Very: Fr.",
    "Three in 5 Across",
    "Very, in Versailles"
  ],
  "lore": [
    "Pass it on!",
    "Local legends",
    "Traditional accounts",
    "Passed-down stories",
    "Tales of the past",
    "The stuff of legends",
    "Folk tales",
    "Stuff of legends",
    "The stuff of legends",
    "Set of traditional beliefs",
    "Family hand-me-down?",
    "Stories that may or may not be true",
    "Mythology",
    "Traditional knowledge",
    "Stories passed from generation to generation",
    "Folk stories",
    "Stories that might not be true",
    "It's passed on",
    "It's passed down",
    "Rhyme for \"door\" and \"more\" in the first verse of \"The Raven\"",
    "Stuff of legends",
    "Handed-down tales",
    "Mythos",
    "Stuff of legends",
    "Handed-down wisdom",
    "Same old story?",
    "Some hand-me-downs?",
    "Tales of old",
    "Ethnologist's interest",
    "The stuff of legends",
    "Legends",
    "It's passed down through the ages",
    "Folk tales and such",
    "Handed-down wisdom",
    "Old stories",
    "Tales of the Round Table, e.g.",
    "Tails",
    "Stuff of legends",
    "Oral history",
    "Old tales",
    "Passed-down stories",
    "Washington chopping down the cherry tree, e.g.",
    "It's passed on",
    "Stories passed from generation to generation",
    "It's handed down",
    "It's handed down from generation to generation",
    "Oral history",
    "Stuff of legend",
    "Stories like Washington chopping down the cherry tree",
    "Old-time wisdom",
    "It's handed down",
    "It's handed down",
    "Much family history, e.g.",
    "Oral tradition",
    "Oral history",
    "Stories passed down through generations",
    "Traditional know-how",
    "Generation-to-generation information",
    "Oral history",
    "Folk wisdom",
    "Folk tales",
    "Oral tradition",
    "Shared knowledge",
    "Erudition",
    "It may be tribal",
    "It's passed on",
    "It's passed on",
    "Some tales",
    "Age-old tales",
    "Old stories",
    "Elders' teachings",
    "Historian's interest",
    "Tales and such",
    "Knowledge",
    "Oral history",
    "Handed-down history",
    "Tales",
    "Oft-told tales",
    "Handed-down history",
    "Folk tales and such",
    "Tribal tales",
    "Folk stories",
    "Tales and such",
    "Grandparents' stories, e.g.",
    "Elders' teachings",
    "The stuff of folk tales",
    "Round-the-campfire stories",
    "Folk wisdom",
    "Learning",
    "Wisdom",
    "Old book's content",
    "Knowledge",
    "Oral history",
    "Folk follower",
    "Folk history",
    "Family stories",
    "Tradition",
    "Knowledge",
    "Folk tales",
    "Folk follower",
    "Knowledge",
    "Stories",
    "Wives' tales",
    "Handed-down stories",
    "Folk tales",
    "Stories",
    "Tradition has it",
    "Knowledge",
    "Legends"
  ],
  "each": [
    "\"Stronger With ___ Tear,\" 2009 hit album for Mary J. Blige",
    "Apiece",
    "Apiece",
    "Per",
    "A head",
    "A pop",
    "___ and every",
    "Individually",
    "Every's partner",
    "Per",
    "A pop",
    "Pricing word",
    "Per item",
    "Made for ___ other",
    "A pop",
    "Not altogether",
    "To ___ his own",
    "A pop",
    "Per item",
    "Per",
    "A head",
    "A pop",
    "Per person",
    "Every",
    "Per unit",
    "Per",
    "Apiece",
    "A unit",
    "Pricing word",
    "A head",
    "A head",
    "Apiece",
    "A pop",
    "Per",
    "Per person",
    "Piece by piece",
    "Per",
    "\"To ___ his own\"",
    "Apiece",
    "Per",
    "A pop",
    "Every one",
    "All's partner",
    "Per",
    "Apiece",
    "Individually",
    "To ___ his own",
    "For one",
    "Up",
    "A head",
    "Pricing word",
    "A pop",
    "\"___ Dawn I Die\" (James Cagney flick)",
    "A throw",
    "Per person",
    "Apiece",
    "Singly",
    "Individually",
    "\"To ___ his own\"",
    "For one",
    "Per",
    "A pop",
    "Individually",
    "Not all together",
    "A pop",
    "Individually",
    "For one",
    "A shot",
    "Price list word",
    "Apiece",
    "With 25-Down, what couples have",
    "A shot",
    "Every one",
    "Not altogether",
    "Price word",
    "Per person",
    "Individually",
    "Apiece",
    "Every's partner",
    "A pop",
    "@",
    "A head",
    "Per",
    "A throw",
    "Other preceder",
    "\"___ Dawn I Die\" (Cagney/Raft flick)",
    "Per",
    "A throw",
    "A person",
    "Apiece",
    "Apiece",
    "Apiece",
    "A pop",
    "Apiece",
    "Singly",
    "Individually",
    "A person",
    "Per",
    "Individually",
    "Apiece",
    "Price word",
    "Per",
    "Per",
    "Per capita",
    "Word in a price"
  ],
  "tank": [
    "Fail badly",
    "Lose value quickly",
    "Sleeveless top, informally",
    "Fail miserably",
    "Nose-dive",
    "Lose suddenly",
    "Fall apart in competition",
    "Fail miserably",
    "Vehicle with a hatch on top",
    "Lose a lot?",
    "Vehicle with a gun mount",
    "Go south",
    "Fail, as a flick",
    "Fail miserably, in slang",
    "Fail miserably",
    "Vehicle with a gun turret",
    "Place for a turret",
    "Aquarium",
    "Do horribly, slangily",
    "Aquarium",
    "Turret site",
    "Bazooka target",
    "Fall precipitately, in slang",
    "Vehicle with a rotating top",
    "Aquarium",
    "Propane holder",
    "Fish locale",
    "Desert Storm vehicle",
    "Aquarium",
    "Aquarium",
    "Kind of top",
    "Panzer",
    "Part of L.S.T.",
    "Army vehicle",
    "Fill (up)",
    "Holding pen",
    "Word with gas or Sherman"
  ],
  "rsvp": [
    "Request from a host",
    "Say that you're going, say",
    "Indicate availability, in a way",
    "___ card, part of a wedding invitation",
    "Get back (to)",
    "\"Let me know if you're coming\"",
    "Say maybe, maybe",
    "Send one's regrets, say",
    "Let the host know",
    "Invitation request, in brief",
    "Say whether or not you'll attend",
    "Say \"Yeah, I can make it,\" say",
    "Click \"Going\" on a Facebook event, e.g.",
    "Say yes or no, say",
    "Request in an invitation",
    "Click yes or no on an e-vite, say",
    "Be a polite invitee",
    "Get back (to)",
    "Do something polite",
    "Answer, quickly",
    "Party request",
    "Say you'll make it, say",
    "Letters of invitation?",
    "Abbr. often following 23-Down",
    "Initial request?",
    "Get back to",
    "Invitation letters",
    "Decision-prompting request",
    "Answer",
    "Accept or decline",
    "Invitation request, for short",
    "Call letters?",
    "Answer, shortly",
    "Answer",
    "Answer, briefly",
    "Reply, briefly",
    "Answer, as an invitation",
    "Get back to",
    "Host's request",
    "Say you'll go, say",
    "Accept or decline",
    "Request of an invitee, briefly",
    "Invitation letters",
    "Tell the host yes or no",
    "Invitation notation",
    "Invitation letters",
    "Short answer",
    "Invitation letters",
    "Guests may do it",
    "Common request",
    "Invitation request",
    "Answer the host",
    "Reply",
    "Invitation inits.",
    "Invitation letters",
    "Answer an invitation",
    "Invitation letters",
    "Letters to answer?",
    "Party giver's abbr.",
    "Answer, in brief",
    "Common request",
    "Invitation letters",
    "Write back",
    "Invitation notation",
    "Call back",
    "Letters of invitation?",
    "Invitation letters"
  ],
  "nmex": [
    "Where I-25 meets I-40: Abbr.",
    "Mountainous state: Abbr.",
    "Albuquerque's home: Abbr.",
    "The Rio Grande divides it: Abbr.",
    "Home of the Aztec Ruins Natl. Monument",
    "Its highest point is Wheeler Peak: Abbr.",
    "Ariz. neighbor",
    "Its motto in Eng. is \"It grows as it goes\"",
    "Home of Silver City: Abbr.",
    "Ariz. neighbor",
    "Home of the White Sands Natl. Mon.",
    "Chihuahua neighbor: Abbr.",
    "Ariz. neighbor",
    "Neighbor of Ariz."
  ],
  "neuf": [
    "Dix minus un",
    "Nine, in Nice",
    "Nine, in Nantes",
    "Dix preceder",
    "Nine, in Nantes"
  ],
  "grok": [
    "Get",
    "Understand, informally",
    "Comprehend",
    "Understand intuitively",
    "Understand",
    "Understand",
    "Grasp, informally",
    "Understand",
    "Get",
    "Grasp intuitively",
    "Grasp, in slang",
    "Understand, slangily",
    "Understand, informally",
    "Get inside and out",
    "Totally get, in slang",
    "Understand, slangily"
  ],
  "ucla": [
    "First sch. to win 100 N.C.A.A. titles",
    "Kareem Abdul-Jabbar's alma mater",
    "The Bruins of the N.C.A.A.",
    "Sch. with the most applications in the U.S.",
    "Where Jackie Robinson played college ball",
    "Sch. whose home football games used to include a live bear on the field",
    "Sch. with the song \"Hail to the Hills of Westwood\"",
    "Sch. with the most N.C.A.A. men's basketball championships (11)",
    "Sch. near Beverly Hills",
    "Bruins' sch.",
    "Where Kareem Abdul-Jabbar played college ball",
    "School with its own ZIP code - 90095",
    "11-time N.C.A.A. basketball champs",
    "Sch. near Hollywood",
    "Alma mater of N.B.A. M.V.P. Russell Westbrook",
    "Jackie Robinson's alma mater",
    "Sch. whose colors are \"true blue\" and gold",
    "Bruins' home",
    "Sch. near Hollywood",
    "The N.C.A.A.'s Bruins",
    "Sch. that plays home football games at the Rose Bowl",
    "Most-applied-to school in the U.S.",
    "Ronald Reagan ___ Medical Center",
    "Home of the N.C.A.A.'s Bruins",
    "Home to the Ronald Reagan Med. Ctr.",
    "The Bruins of the N.C.A.A.",
    "It has a noted school of theater, film and television",
    "The Bruins of the Pac-12",
    "Pac-12 hoops powerhouse",
    "Where Arthur Ashe played college tennis",
    "Most-applied-to sch. in the U.S.",
    "Five-time Rose Bowl winner, for short",
    "One of the so-called \"Public Ivies\"",
    "Home of the David Geffen School of Medicine, for short",
    "Pac-12 team",
    "Pac-12 hoops giant",
    "Winningest N.C.A.A. Basketball Championship sch.",
    "Bruins of the N.C.A.A.",
    "Campus attended by Elle in \"Legally Blonde\"",
    "Rose Bowl stadium sch.",
    "Sch. near Beverly Hills",
    "Pac-12 basketball powerhouse",
    "Pac-12 team",
    "Bruins' sch.",
    "Its fight song is \"The Mighty Bruins\"",
    "Sch. near Beverly Hills",
    "Where Troy Aikman was a QB",
    "Sch. that publishes the Daily Bruin",
    "Carol Burnett's 17-Across",
    "Jackie Robinson's alma mater",
    "The Bruins of the N.C.A.A.",
    "The Bruins of the N.C.A.A.",
    "Campus near Beverly Hills, briefly",
    "Where Bertrand Russell taught philosophy, for short",
    "Kareem Abdul-Jabbar's alma mater, in brief",
    "The Bruins' sch.",
    "Pac-12 team",
    "Bruins' sch.",
    "Pac-10 competitor",
    "11-time N.C.A.A. basketball champs",
    "Sch. with home games at Pauley Pavilion",
    "The Bruins of the N.C.A.A.",
    "The Bruins of the N.C.A.A.",
    "The Bruins of the N.C.A.A.",
    "Peace Nobelist Ralph Bunche's alma mater",
    "\"True blue\" and gold team",
    "Pac-10 sch.",
    "Sch. whose colors are \"true blue\" and gold",
    "Alma mater for Carol Burnett and Jim Morrison: Abbr.",
    "Ralph Bunche's alma mater",
    "Where Lew Alcindor played",
    "Sch. where John Wooden coached",
    "Jackie Robinson's alma mater",
    "Bruins' sch.",
    "Bruins' sch.",
    "Pac Ten powerhouse",
    "Pac 10 member",
    "Sch. with a Jack Benny Award for comedy",
    "The Bruins' sch.",
    "The Bruins of the Pac Ten",
    "Sch. where Bill Walton played",
    "Bruins home, for short",
    "Longtime John Wooden team",
    "The Bruins of the Pac Ten",
    "Bruins of the Pac 10",
    "The Bruins of the N.C.A.A.",
    "Home of Pauley Pavilion: Abbr.",
    "Pac-10 team",
    "Powell Library locale, for short",
    "Bruins' sch.",
    "Bruins' sch.",
    "Bruins' sch.",
    "Pac 10 member",
    "Pac-10 team, for short",
    "1995 N.C.A.A. hoops champs",
    "Bruins' sch.",
    "Bruins' home",
    "Pac 10 school",
    "Arthur Ashe's alma mater",
    "Five-time Rose Bowl winner",
    "1995 N.C.A.A. hoops champs",
    "Pac Ten school",
    "Bruins' sch.",
    "John Wooden Center site",
    "60's-70's sports dynasty",
    "Flo-Jo went to it",
    "Troy Aikman's alma mater",
    "Bruins' sch.",
    "Daily Bruin sch.",
    "Pac Ten team",
    "11-time N.C.A.A. basketball champs",
    "Troy Aikman's alma mater",
    "Where John Wooden coached",
    "Drake Stadium site",
    "U.S.C. rival",
    "Pac 10 team",
    "Bruins' sch.",
    "Where Bill Walton played",
    "Pauley Pavilion team",
    "Pac 10 sch.",
    "Pac 10 team",
    "Bruins' home",
    "1995 N.C.A.A. basketball champs",
    "Kareem's alma mater",
    "1995 N.C.A.A. basketball champs",
    "Where Jackie Robinson lettered",
    "Abdul-Jabbar's alma mater",
    "Bruins of the N.C.A.A.",
    "Five-time Rose Bowl winner",
    "11-time N.C.A.A. basketball champs",
    "Perennial basketball powerhouse",
    "Bruins' school",
    "Pac 10 team",
    "John Wooden's school",
    "60's B-ball champs",
    "Member of 59-Across",
    "Abdul-Jabbar's alma mater"
  ],
  "shad": [
    "Popular baitfish",
    "Bony fish with prized eggs",
    "Variety of herring",
    "Roe source",
    "Herring relative",
    "Kind of roe",
    "Source of valuable eggs",
    "Deep-bodied herring",
    "Herring type",
    "Herringlike fish",
    "Roe source",
    "Sprat relative",
    "Roe source",
    "Source of some highly prized 19-Acrosses",
    "The fish in John McPhee's \"The Founding Fish\"",
    "Alewife's relative",
    "Cousin of an alewife",
    "Atlantic catch",
    "Roe source",
    "Fish that can detect ultrasound",
    "Roe source",
    "Fish that migrates from seawater to freshwater and back",
    "Kohada, on a sushi menu",
    "Cousin of a herring",
    "Roe source",
    "Certain herring",
    "Roe source",
    "Alewife's cousin",
    "Cousin of a herring",
    "Roe source",
    "Roe source",
    "Roe source",
    "Roe source",
    "Fish that swims upstream",
    "Roe source",
    "Skipjack herring, e.g.",
    "Herring kin",
    "Relative of an alewife",
    "Certain herring",
    "Fish usually caught in the winter",
    "Certain herring",
    "Spawning fish",
    "Variety of herring",
    "Spawning fish",
    "Trawler's haul",
    "Filleted fish",
    "Roe source",
    "Fish usually caught in the winter",
    "Source of roe",
    "Migrating fish",
    "A herring"
  ],
  "gist": [
    "Meat",
    "Basic point",
    "Heart",
    "Basic idea",
    "Basic point",
    "Basic idea",
    "General idea",
    "Essence",
    "Drift",
    "Main point of an idea",
    "Basic concept",
    "Essence",
    "Meat",
    "Thrust",
    "Essence",
    "Main point",
    "Crux",
    "Heart",
    "Drift",
    "Central part of an argument",
    "You'll get it from CliffsNotes",
    "Heart",
    "Basic point",
    "Basic idea",
    "Main point",
    "Heart",
    "Main idea",
    "Essence",
    "Heart of the matter",
    "Meat",
    "Long and short of it",
    "Heart",
    "Heart of the matter",
    "Crux",
    "Main point",
    "Main idea",
    "Heart",
    "Point",
    "Main idea",
    "Central point",
    "Main point",
    "Substance",
    "Main point",
    "Heart",
    "Essence",
    "Main point",
    "Kernel",
    "Essence",
    "Heart of the matter",
    "Essential part",
    "Essence",
    "Essence"
  ],
  "dere": [
    "\"Wot's dat over ___?\"",
    "In dat place",
    "\"Dat ___\" (classic jazz song)",
    "\"Dat ___\" (jazz tune)",
    "\"Hello ___\" (old comedy intro)",
    "\"___ Mable\" (W.W. I best seller)",
    "Classic Latin work \"___ Rustica\"",
    "\"___Mable\" (1918 best seller)"
  ],
  "lull": [
    "Quiet period",
    "Quiet period",
    "Brief down period",
    "Break in the action",
    "Break",
    "Pause",
    "Letup",
    "Quiet period",
    "Temporary calm",
    "Break",
    "Put to sleep",
    "Pause in the action",
    "Put to sleep",
    "Break",
    "Temporary calm",
    "Gift to an overworked salesperson",
    "Pause",
    "Break",
    "Break in the action",
    "Peaceful period",
    "Break in the action",
    "Break in the action",
    "Down time",
    "Quiet time",
    "Put to sleep",
    "Break",
    "Respite",
    "Interlude",
    "Put to sleep",
    "Break in the action",
    "Quiet moment",
    "Slow period",
    "Break in the action",
    "Breathing space",
    "Break in the action",
    "Downtime",
    "Calm",
    "Pause",
    "Soothe"
  ],
  "salt": [
    "What halophobia is the fear of",
    "One reason dogs lick us is for this, it's believed",
    "Put (away)",
    "Rim coating, at times",
    "NaCl",
    "Pretzel topping",
    "Seasoning that can lead to high blood pressure",
    "What Lot's wife became",
    "What \"S\" may stand for on a dinner table",
    "See 5-Down",
    "Pepper's partner",
    "Save, with \"away\"",
    "Part of the \"everything\" in an everything bagel",
    "Pepper's counterpart",
    "\"S\" on the dinner table",
    "Morton product",
    "See 39-Across",
    "Margarita option",
    "Word with sea or seasoned",
    "Winter sprinkle",
    "NaCl",
    "Pepper's partner",
    "___ cellar",
    "Deice, in a way",
    "See 63-Down",
    "With 30-Across, they started in 1969",
    "NaCl",
    "Shaker contents",
    "Curer",
    "Margarita garnish",
    "Margarita go-with",
    "Margarita glass rim coating",
    "It's often pinched",
    "Cracker coating",
    "Margarita garnish",
    "Curer",
    "\"S\" shaker",
    "Put (away)",
    "Stinger?",
    "Curer",
    "Pepper's partner",
    "Put (away)",
    "A pinch, maybe",
    "Put (away)",
    "Margarita glass rim coater",
    "Jack-tar",
    "Highway department supply",
    "Sea dog",
    "Focaccia topping",
    "A dash, maybe",
    "Stash (away)",
    "Pepper's partner",
    "Margarita feature",
    "Sodium chloride",
    "Pretzel topper",
    "It may be added in a dash",
    "Source of some cures",
    "Put (away)",
    "Skeptic's grain",
    "Preserve, in a way",
    "NaCl",
    "Brining need",
    "S on a dining table",
    "It may be shaken",
    "Theme of this puzzle, found in the answers to 21-, 26-, 44- and 52-Across",
    "Ice melter",
    "Seasoned sailor",
    "Tar",
    "Margarita need",
    "Seasoned sailor",
    "Road crew's supply",
    "Preserve, in a way",
    "Highway department supply",
    "Dash in the kitchen",
    "Alum, for one",
    "Popeye, e.g.",
    "Pepper's partner",
    "1972 agreement, for short",
    "Morton product",
    "Ionic compound",
    "NaCl",
    "Pepper's partner",
    "See 59-Across",
    "Preserve",
    "1972 pact",
    "Measure of worth, they say",
    "NaCl",
    "Piquancy",
    "Shaker contents",
    "Save, with \"away\"",
    "It can sting"
  ],
  "ella": [
    "\"___ and Basie!\" (1963 jazz album)",
    "Civil rights activist Baker",
    "Fitzgerald of jazz",
    "Singer Mai with the 2018 hit \"Boo'd Up\"",
    "Jazz legend Fitzgerald",
    "Woman's name that's also a Spanish pronoun",
    "Jazz's Fitzgerald",
    "Poet ___ Wheeler Wilcox",
    "Fitzgerald of jazz",
    "Real first name of singer Lorde",
    "___ Emhoff, stepdaughter of Kamala Harris",
    "\"We All Love ___: Celebrating the First Lady of Song\" (2007 tribute)",
    "Jazz's Fitzgerald",
    "First name in jazz",
    "Lady ___, a.k.a. the First Lady of Song",
    "Jazz legend Fitzgerald",
    "The scat got her tongue, you might say",
    "Jazz's Fitzgerald",
    "Jazzy Fitzgerald",
    "\"___ Minnow Pea,\" 2001 novel with an alphabetically punny title",
    "Lady ___ (nickname in jazz)",
    "Jazzy Fitzgerald",
    "Spanish feminine pronoun",
    "Fitzgerald of jazz",
    "First name of the First Lady of Song",
    "Fitzgerald known as the Queen of Jazz",
    "Singer Fitzgerald",
    "She: Sp.",
    "Singer Fitzgerald",
    "\"___ Enchanted\" (2004 film)",
    "Jazz legend Fitzgerald",
    "Singer known for her 85-Across",
    "1969 self-titled jazz album",
    "Jazzy Fitzgerald",
    "\"___ Enchanted,\" 1998 Newbery Honor book",
    "First name in jazz",
    "Jazz great Fitzgerald",
    "Singer Fitzgerald",
    "\"___ Sings Gershwin\" (1950 jazz album)",
    "\"___ Enchanted\" (2004 film)",
    "See 68-Across",
    "Jazzy Fitzgerald",
    "She, in Salerno",
    "Singer Fitzgerald",
    "\"___ & Duke at the Cote D'Azur\" (1966 jazz album)",
    "She, in Spanish",
    "Actress Raines of \"Tall in the Saddle\"",
    "___ Moss (women's clothing label)",
    "Singer Fitzgerald",
    "\"___ Enchanted\" (2004 rom-com)",
    "Fitzgerald of old \"Is it live, or is it Memorex?\" ads",
    "Scatter?",
    "That senora",
    "Lorde's actual first name",
    "Singer Fitzgerald",
    "Actress Raines of \"Phantom Lady\"",
    "\"___ and Basie!\" (1963 jazz album)",
    "The First Lady of Song, to fans",
    "\"___ Enchanted\"",
    "\"___ Enchanted\" (2004 film)",
    "That senorita",
    "\"___ and Louis,\" 1956 jazz album",
    "Girl's name that sounds like French for \"she has it\"",
    "\"___ Enchanted\"",
    "Fitzgerald who sang duets with Louis Armstrong",
    "Jazz's Fitzgerald",
    "\"Phantom Lady\" co-star Raines",
    "She, in Seville",
    "She sang with Duke and Dizzy",
    "First name in scat",
    "Scat queen Fitzgerald",
    "First name in scat",
    "\"___ Enchanted\" (2004 film)",
    "Jazzy Fitzgerald",
    "\"___ Enchanted\" (2004 film)",
    "Poet ___ Wheeler Wilcox",
    "\"___ in Berlin\" (1960 live album)",
    "\"___ Enchanted\" (2004 film)",
    "Jazz great Fitzgerald",
    "Folk singer Jenkins",
    "Fitzgerald known as the First Lady of Song",
    "Fitzgerald who sang \"A-Tisket A-Tasket\"",
    "\"___ at Duke's Place\" (1965 jazz album)",
    "Singer Fitzgerald",
    "\"Enchanted\" girl of children's lit",
    "\"___ in London\" (jazz album)",
    "First name of the First Lady of Song",
    "Jazzy Fitzgerald",
    "Self-titled album of 1969",
    "Jazz's Fitzgerald",
    "\"___ Enchanted\" (Anne Hathaway movie)",
    "Jazz's Fitzgerald",
    "Actress Raines on the Hollywood Walk of Fame",
    "Jazz's Fitzgerald",
    "First name in scat",
    "Salmon tail?",
    "1969 self-titled jazz album",
    "Former Connecticut governor Grasso",
    "___ Peterson, lead role in \"Bells Are Ringing\"",
    "Fitzgerald who sang \"A-Tisket, A-Tasket\"",
    "Singer Fitzgerald",
    "\"___ Enchanted\" (Gail Carson Levine book)",
    "Singer Fitzgerald",
    "Singer Fitzgerald",
    "Fitzgerald who knew how to scat",
    "She: Italian",
    "\"___ Enchanted\" (2004 movie)",
    "Jazzy Fitzgerald",
    "\"___ and Louis\" (1956 duet album)",
    "Jazz's Fitzgerald",
    "Spanish \"she\"",
    "2004 title role for Anne Hathaway",
    "\"Enchanted\" girl in a 2004 film",
    "Actress Raines",
    "Cinders of old comics",
    "\"Enchanted\" girl in a 2004 film",
    "Jazzy Fitzgerald",
    "1940's-50's actress Raines",
    "Jazzy Fitzgerald",
    "Jazz's Fitzgerald",
    "Jazzy Fitzgerald",
    "\"___ Enchanted\" (Newbery-winning book made into a 2004 film)",
    "First Lady of Jazz",
    "First name in singing",
    "Ex-governor Grasso",
    "Scat queen Fitzgerald",
    "Fitzgerald of scat",
    "First name in 52-Down",
    "Poet ___ Wheeler Wilcox",
    "Fitzgerald, the First Lady of Jazz",
    "Fitzgerald who sang \"I'm Making Believe\"",
    "\"___ Enchanted\" (2004 film)",
    "Jazz's Fitzgerald",
    "\"___ Cinders\" of old funnies",
    "2004 film \"___ Enchanted\"",
    "First Lady of Jazz",
    "\"The Intimate ___\" (1990 jazz album)",
    "Singer Fitzgerald",
    "\"___ Cinders\" (old comic)",
    "First name in scat",
    "Cinders of old comics",
    "First name in scat",
    "Singer Fitzgerald",
    "That Spanish girl",
    "\"___ in Berlin\" (1960 jazz album)",
    "Poet ___ Wheeler Wilcox",
    "Raines of \"Hail the Conquering Hero\"",
    "First name in jazz",
    "Jazz's Fitzgerald",
    "Queen of scat",
    "Vocalist Fitzgerald",
    "1969 jazz album",
    "First name in scat",
    "First name in scat",
    "\"Pure ___,\" 1994 jazz album",
    "Singer Fitzgerald",
    "\"The Intimate ___\" (1990 jazz album)",
    "___ Peterson, Judy Holliday's role in \"Bells Are Ringing\"",
    "Poet ___ Wheeler Wilcox",
    "Jazzy Fitzgerald",
    "Name in the Jazz Hall of Fame",
    "Former Connecticut Gov. Grasso",
    "Scat queen Fitzgerald",
    "\"The Intimate ___\" (1990 jazz album)",
    "Jazz's Fitzgerald",
    "Actress Raines",
    "Poet ___ Wheeler Wilcox",
    "1965 jazz album",
    "Jazzy Fitzgerald",
    "First name in jazz",
    "\"___ in London\" (jazz album)",
    "1965 jazz album",
    "Jazz's Fitzgerald",
    "Raines of filmdom",
    "First name in jazz",
    "Scat artist Fitzgerald",
    "Raines of 40's-50's film",
    "Tuneful Fitzgerald",
    "Grammy winner Fitzgerald",
    "Former Gov. Grasso",
    "\"Pure ___\" (1994 jazz album)",
    "Actress Raines",
    "Scat lady",
    "Cinders in old strips",
    "First name in scat",
    "First name in scat",
    "Singer Fitzgerald",
    "Miss Cinders of old comics",
    "Poet ___ Wheeler Wilcox",
    "Suffix with salmon",
    "Jazzy Fitzgerald",
    "Noted first name in jazz",
    "Actress Raines",
    "Miss Cinders of old comics",
    "First name in scat",
    "1965 jazz album",
    "Singer Fitzgerald",
    "Queen of scat",
    "Scat queen Fitzgerald",
    "Actress Raines",
    "Scat queen Fitzgerald",
    "Actress Raines",
    "Fitzgerald who sang \"A-Tisket, A-Tasket\"",
    "First name in jazz",
    "Feminine name suffix",
    "First name in jazz",
    "Music's Fitzgerald",
    "Jazzy Fitzgerald",
    "Fitzgerald of scat",
    "Miss Fitzgerald",
    "Miss Cinders of old comics",
    "Poet ___ Wheeler Wilcox",
    "Golden-voice Fitzgerald",
    "Actress Raines",
    "Fitzgerald of scat",
    "Miss Cinders of old comics",
    "Actress Raines",
    "Miss Cinders of early comics",
    "Raines of 40's films",
    "Grammy-winning Fitzgerald",
    "Fitzgerald of scat",
    "Miss Cinders of old comics",
    "Singer Fitzgerald",
    "Poet ___ Wheeler Wilcox",
    "Scat cat"
  ],
  "leas": [
    "Bucolic spots",
    "Pastures",
    "Pastures",
    "Grazing spots",
    "Meadows",
    "Grassy stretches",
    "Places where black-eyed Susans grow",
    "Gamboling spots",
    "Grazing grounds",
    "Gamboling spots",
    "Pastures",
    "Bucolic settings",
    "Fields",
    "Meadowlands",
    "Where flocks feed",
    "Picnic places",
    "Places to graze",
    "Bucolic backdrops",
    "Verdant stretches",
    "Meadows",
    "Shepherds' locales",
    "Grazing lands",
    "Rural expanses",
    "Where sheep graze",
    "Areas between woods",
    "Places to graze",
    "Meadows",
    "Grazing sites",
    "Spots for meadowlarks",
    "Pastoral places",
    "Grasslands",
    "Picnic spots",
    "Pastoral expanses",
    "Rural tracts",
    "Pastoral expanses",
    "Gamboling places",
    "Meadows",
    "Grasslands",
    "Sheepherding locales",
    "Grazing sites",
    "Settings for idylls",
    "Grasslands",
    "Grasslands",
    "Grazing sites",
    "Pastoral settings",
    "Bucolic settings"
  ],
  "taos": [
    "Artist colony in a desert",
    "New Mexico art hub",
    "Tourist town in northern New Mexico",
    "New Mexico's ___ Ski Valley",
    "Tourist city in New Mexico's high desert",
    "Southwestern ski resort",
    "Western town that inspired Georgia O'Keeffe",
    "Ski resort NNE of Santa Fe",
    "New Mexico resort town",
    "New Mexico county or its seat",
    "Artist colony in the Sangre de Cristo Mountains",
    "___ Pueblo (World Heritage Site)",
    "___ Ski Valley",
    "___ Ski Valley, one of the highest municipalities in the U.S. (9,207 feet)",
    "Eponymous New Mexico tribe",
    "Southwest skiing locale",
    "Whence many paintings of Pueblo Indians",
    "Southwestern ski resort",
    "New Mexico skiing mecca",
    "New Mexico's ___ Ski Valley",
    "Ski resort whose name is an anagram of 34-Across",
    "Ski resort near Santa Fe",
    "___ Pueblo (Unesco World Heritage site)",
    "New Mexico county or its seat",
    "Stop along the Santa Fe trail",
    "___ Ski Valley, N.M.",
    "Western setting for artisans",
    "Hometown of TV's McCloud",
    "Ute Peak's county",
    "New Mexico county or its seat",
    "Southwestern art mecca",
    "City or county of New Mexico",
    "New Mexico's ___ Ski Valley",
    "County with the resort town Red River",
    "New Mexico skiing locale",
    "New Mexico resort",
    "Pueblo dweller",
    "New Mexico town or county",
    "Resort near the Rio Grande Gorge",
    "Southwestern resort community",
    "Town near the D. H. Lawrence Ranch",
    "New Mexico city or county",
    "Pueblo dweller",
    "Southwest art center",
    "City near Carson National Forest",
    "New Mexico county or its seat",
    "___ Ski Valley",
    "New Mexico art community",
    "Sun Belt art colony",
    "Sangre de Cristo Mountains resort",
    "Onetime home of Kit Carson",
    "New Mexico resort town",
    "Western tourist destination",
    "Locale for Georgia O'Keeffe",
    "___ Ski Valley (western resort)",
    "Georgia O'Keeffe's \"___ Pueblo\"",
    "New Mexico skiing locale",
    "New Mexico town on the Santa Fe Trail",
    "Art colony near Santa Fe",
    "New Mexico resort",
    "New Mexico tourist site",
    "New Mexico artists' home",
    "New Mexico art community",
    "Where Kit Carson is buried",
    "New Mexico art colony",
    "Pueblo in northern New Mexico",
    "New Mexico town famous for 8-Down",
    "Santa Fe Trail town",
    "New Mexico art center",
    "Southwestern resort",
    "Carson National Forest locale",
    "D. H. Lawrence's retreat",
    "Southwestern art center",
    "Site of Kit Carson's grave",
    "Town NNE of Santa Fe",
    "Kit Carson's home",
    "___ Ski Valley, N.M.",
    "Town NNE of Santa Fe",
    "D. H. Lawrence ranch site",
    "Santa Fe Trail stop",
    "New Mexico resort",
    "New Mexico art colony",
    "Southwestern art colony",
    "Southwestern art colony",
    "New Mexico art locale",
    "Kit Carson Home site",
    "___ Ski Valley, N.M.",
    "New Mexico artists' town",
    "Southwestern Indians",
    "Pueblo town",
    "Santa Fe Trail town"
  ],
  "eyes": [
    "Colon or semicolon, in an emoticon",
    "Where the lacrimal glands can be found",
    "A scallop has up to 200 of these",
    "What most spiders have eight of",
    "Ophthalmologists' focus",
    "What the giant Argus has 100 of, in Greek myth",
    "Specs can be provided for them",
    "Views",
    "They can be batted and rolled",
    "Emoji that can mean \"Get a load of this!\"",
    "Watches",
    "Peepers",
    "Centers of hurricanes",
    "What contacts contact",
    "Seeing things?",
    "The ___ (apt anagram for \"they see\")",
    "\"For Your ___ Only\"",
    "Unrealistic part of many statues",
    "Eight things that most spiders have",
    "Things in orbits",
    "Blinkers",
    "Some hieroglyphic symbols",
    "Things that people are warned not to cross",
    "Surveys",
    "What a colon might denote",
    "Regards",
    "Good lookers?",
    "Buds on spuds",
    "Real lookers?",
    "Baby blues, e.g.",
    "Intel satellites, metaphorically",
    "Surveys",
    "Hawks have sharp ones",
    "Views",
    "Observation satellites, so to speak",
    "Places for contacts",
    "They can turn red in a flash",
    "Some glass prostheses",
    "Sight seers",
    "Things opened in the morning",
    "Word with googly or goo-goo",
    "Holes in Swiss cheese",
    "Peer group?",
    "Optometrists' interest",
    "Sightseers?",
    "Hurricane centers",
    "Peepers",
    "What a colon represents in an emoticon",
    "See 1-Across",
    "Watchful ones?",
    "Potato protuberances",
    "Googly ___",
    "Potato's multitude",
    "Observes",
    "Spots for glasses",
    "11-Down, north of the border",
    "They can be batted and rolled",
    "See 1-Across",
    "They may be lazy or wandering",
    "They can be prying or crying",
    "They're seen on both sides of bridges",
    "*Storm centers",
    "Prominent features of a \"Cats\" poster",
    "They can be piercing",
    "Regards",
    "Targets of a Moe Howard poke",
    "\"Windows to the soul\"",
    "Checks out",
    "Observing things",
    "Colon, in an emoticon",
    "Peepers",
    "Ogles",
    "They can be crossed",
    "Head set",
    "Checks out",
    "Optometrists' concerns",
    "Centers, of sorts",
    "Gives a gander",
    "Tearing things?",
    "What the starts of 18-, 27-, 43- and 57-Across all have",
    "Frosty's coals",
    "Needle parts",
    "Surveys",
    "Looks at",
    "Sight seers",
    "Looks over",
    "Scrutinizes",
    "Balls with lids",
    "\"Mona Lisa\" features that \"follow\" the viewer",
    "Choice meat cuts",
    "Scrutinizes",
    "Regards",
    "Looks over",
    "Watchful pair",
    "Peepers",
    "Ogles",
    "Looks at",
    "Blinkers",
    "Storm centers",
    "Takes a gander at",
    "Examines closely",
    "Scans",
    "Ogles",
    "They're crossable",
    "Some are electric",
    "Looks at",
    "Views",
    "Peepers",
    "Focus group?",
    "Baby blues",
    "They may be blue in the face",
    "Snake ___ (60-Down)",
    "View finders?",
    "Places for patches",
    "Baby blues",
    "They may be given 40 lashes",
    "View finders",
    "Appraises",
    "Peepers",
    "Surveys",
    "Art appreciation duo?",
    "Regards",
    "Regards",
    "Ogles",
    "Potato features",
    "Head set",
    "Spuds' buds",
    "\"___ only\"",
    "Choice beef cuts",
    "Potato parts",
    "Scrutinizes",
    "They fit in sockets",
    "They're kept under lids at night",
    "Swiss cheese holes",
    "Peepers",
    "\"___right!\"",
    "Peepers",
    "What rakes make",
    "Ogles",
    "They may get 40 lashes",
    "A dollar bill has four",
    "Hurricane centers",
    "Potato buds",
    "Peepers",
    "Looks at",
    "Potato features",
    "Regards",
    "This puzzle's punning theme",
    "Regards"
  ],
  "heat": [
    "Something that may be packed",
    "Pressure, informally",
    "Pressure, metaphorically",
    "Pressure, in slang",
    "Passion",
    "What incubators provide",
    "Stick in the oven",
    "Police, informally",
    "Range output",
    "Packing ___",
    "What a conductor might conduct",
    "___-seeking missile",
    "Unwanted pressure",
    "You can pack it",
    "Qualifying race",
    "Pressure, informally",
    "Early race",
    "Furnace output",
    "Pressure, informally",
    "Radiator output",
    "What a thermometer measures",
    "Miami squad",
    "Track meet event",
    "Passion",
    "Kind of index",
    "Fervor",
    "A gun, slangily",
    "Basic cooking instruction",
    "2006 N.B.A. champs",
    "Furnace output",
    "Coppers",
    "Warmth",
    "Natural riser",
    "Radiator output",
    "Summer oppressiveness",
    "Summer woe",
    "Oven setting",
    "Incubate",
    "Furnace's output",
    "What mobsters pack",
    "Cooking direction",
    "Rods and such",
    "Pressure",
    "Miami basketball team",
    "Summer phenomenon",
    "Furnace output",
    "Temperature",
    "Passion",
    "Campbell's soup instruction",
    "Miami basketball team",
    "Gun, slangily",
    "What mobsters pack",
    "Furnace output",
    "Basic cooking instruction",
    "It may be packed",
    "Kind of wave",
    "Greenhouse effect?",
    "Qualifying race",
    "Miami team",
    "Olympics preliminary",
    "Vehemence",
    "Radiator output",
    "Miami five",
    "Race part",
    "Put on the burner",
    "Race prelim",
    "Pressure",
    "Make simmer",
    "Fever",
    "Pressure",
    "Preliminary race"
  ],
  "anat": [
    "Subj. for class cut-ups?",
    "Body sci.",
    "Basic subj. for a surgeon",
    "Surgeons' subj.",
    "Med school subj.",
    "Vet school subj.",
    "Med. school course",
    "Med. school subject",
    "Med. school subject",
    "Med. school subject",
    "Bio course: Abbr.",
    "Med school subj.",
    "MCAT topic: Abbr.",
    "Subj. that gets into circulation?",
    "Med. school course",
    "Veterinary school subj.",
    "Vet school subj.",
    "Med. student course",
    "Med. school subj.",
    "Nursing school subj.",
    "Veterinarian's subj.",
    "Art school subj.",
    "Cadaver study: Abbr.",
    "Class with a skeleton in the closet?: Abbr.",
    "Course in the biology dept.",
    "Operator's concern: Abbr.",
    "Class with many makeup exams?: Abbr.",
    "Basic subj. for a surgeon",
    "Coroner's subj.",
    "Gut course?: Abbr.",
    "Med school subj.",
    "Med school subj.",
    "Sci. course for a doctor-to-be",
    "Medical sch. topic",
    "Art school subj.",
    "Biol. subject",
    "Course related to physiology: Abbr.",
    "Study of the body: Abbr.",
    "Knowledge of body?: Abbr.",
    "Gray area?: Abbr.",
    "Med school subj.",
    "Organs are located in it: Abbr.",
    "Gray matter?: Abbr.",
    "Subj. in drawing class",
    "Constitution: Abbr.",
    "Basic course for a future M.D.",
    "Course related to physiol.",
    "Med. school subject",
    "Sci. class",
    "Subj. of a makeup exam?",
    "Biol. subject",
    "Sci. class",
    "Gray's subj.",
    "Organ teacher's field: Abbr.",
    "Constitution: Abbr.",
    "Gray's subj.",
    "Sci. course",
    "Med. school subj.",
    "Art sch. class",
    "All the things you are: Abbr.",
    "Makeup course?: Abbr.",
    "Med. school class",
    "Gray's subj.",
    "Body structure: Abbr.",
    "Med school subj.",
    "Sculpture student's subj.",
    "Med. school class",
    "Sci. course",
    "Med school subj.",
    "Biol. subject",
    "Med. sch. course",
    "Surgeon's expertise: Abbr.",
    "Sci. class",
    "Pre-med course: Abbr.",
    "Sci. course",
    "Gray area: Abbr.",
    "Biol. course",
    "Med. sch. subject",
    "Sci. class",
    "Med. school course",
    "Study of the body: Abbr.",
    "Surg. study",
    "Med school subj.",
    "Pre-med course: Abbr.",
    "Makeup: Abbr.",
    "Biol. subject",
    "Course akin to Physiol.",
    "Class with dissections: Abbr.",
    "Gray's subj.",
    "Med school subj.",
    "Sci. course",
    "Biol. subject",
    "Makeup lessons?: Abbr.",
    "Premed subj.",
    "Art school subj.",
    "Johns Hopkins subj.",
    "Med. course",
    "Gray area?: Abbr.",
    "Sci. course",
    "Biology subj.",
    "Premed class: Abbr.",
    "Med. sch. course",
    "Med. school course",
    "Course for an M.D.-to-be",
    "Sci. course",
    "Med. sch. course",
    "Premed course: Abbr.",
    "Biol. subject",
    "Premed course: Abbr.",
    "Gray's subj.",
    "Henry Gray's subj.",
    "Gray area: Abbr.",
    "Part of an M.D.'s educ.",
    "Art school subj.",
    "Med. course",
    "G.P.'s expertise",
    "Sci. course",
    "Med. school hurdle",
    "Med. subj.",
    "Gray's subj.",
    "Med. sch. subj."
  ],
  "tron": [
    "1982 Disney film with a 2010 sequel",
    "\"___: Legacy\" (sci-fi sequel)",
    "Suffix with cyclo-",
    "\"___: Uprising\" (Disney animated series)",
    "2010 sci-fi film subtitled \"Legacy\"",
    "Sci-fi film with vehicles called \"light cycles\"",
    "1982 film named after a computer program",
    "1982 film that takes place inside a computer",
    "Classic film with a game theme",
    "Suffix with Jumbo",
    "Classic sci-fi film with a sequel 28 years later",
    "Sci-fi film recognized with an Oscar for Technical Achievement 15 years after it was released",
    "Arcade game based on a film of the same name",
    "Disney sci-fi classic",
    "Futuristic movie of 1982",
    "1982 film inspired by Pong",
    "1982 movie inspired by Pong",
    "Suffix with Jumbo",
    "\"___: Legacy\" (sci-fi sequel)",
    "1982 Disney film",
    "1982 Disney film",
    "Suffix with magne-",
    "\"___: Legacy\" (2010 film sequel)",
    "Suffix with beta or cyclo-",
    "1982 sci-fi film with a 2010 sequel",
    "Ending with Jumbo",
    "High-tech 1982 Disney movie",
    "Video game film",
    "Sci-fi film with a 2010 sequel",
    "1982 Disney film",
    "Suffix with cyclo-",
    "Jeff Bridges sci-fi classic",
    "Suffix with cyclo-",
    "Suffix with Jumbo or beta",
    "1982 9-Down movie",
    "1982 Jeff Bridges flick",
    "1982 sci-fi film with a 2010 sequel",
    "Beta follower",
    "1982 high-tech film",
    "High-tech gadgetry suffix",
    "Suffix with cyclo- or Jumbo",
    "1982 Disney cybermovie",
    "1982 Disney film",
    "1982 film title role for Bruce Boxleitner",
    "1982 film and arcade game",
    "Innovative 1982 Disney film",
    "Disney movie with a hacker hero",
    "Suffix with beta",
    "1982 sci-fi film",
    "Pioneering 1982 sci-fi film",
    "Suffix associated with accelerators",
    "High-tech suffix",
    "Suffix with Jumbo",
    "Sci-fi film of 1982",
    "1982 Disney film",
    "1980's arcade game",
    "Suffix with 11-Across",
    "1982 Jeff Bridges film",
    "Suffix for high-tech gadgets",
    "Disney film with a video-game contest",
    "High-tech suffix",
    "Commercial suffix with Jumbo",
    "1982 cyberflick",
    "1982 Disney film",
    "Suffix with cosmo-",
    "Suffix in nuclear physics",
    "80's sci-fi flick",
    "Accelerator suffix",
    "Suffix in nuclear physics",
    "1982 Disney film",
    "High-tech suffix",
    "Suffix with cosmo",
    "1982 Disney flick",
    "Suffix with cyclo-",
    "1982 sci-fi film",
    "1982 Disney film",
    "1982 Disney movie",
    "1982 sci-fi flick",
    "1982 Disney film",
    "High-tech suffix",
    "Suffix in high-tech company names",
    "1982 cyberfilm",
    "1982 Disney film",
    "1982 Boxleitner film",
    "Pioneering 1982 film",
    "Jeff Bridges film of '82",
    "1982 Disney film",
    "1982 Jeff Bridges cyberfilm",
    "Innovative 1982 movie",
    "Video-game film of 1982",
    "1982 Disney film",
    "1982 Jeff Bridges film",
    "1982 Disney film"
  ],
  "soil": [
    "It covers a lot of ground",
    "Potter's substance",
    "Concern in geomorphology",
    "Besmirch",
    "Seedy area?",
    "Gardener's supply",
    "What orchids may grow without",
    "Bed of roses?",
    "Where roots take hold",
    "Growth medium",
    "Dirty",
    "What tillers till",
    "Earth",
    "Nitrogen source for plants",
    "Dirty",
    "Earth",
    "Dirty",
    "Dirty",
    "Target of some pH tests",
    "Agrarian concern",
    "Dirty",
    "Potter's purchase",
    "Dirty",
    "Terra firma",
    "It's not needed in hydroponics",
    "Potter's need",
    "Make messy",
    "Dirty",
    "Potter's buy",
    "It can be seedy",
    "Potting material",
    "Make dirty",
    "Potting need",
    "Make dirty",
    "Smudge",
    "Dirty",
    "Sully",
    "Potter's purchase",
    "Dirty",
    "Make dirty",
    "Potting need",
    "Victim of erosion",
    "Dirty",
    "Sully",
    "Earth",
    "Stain",
    "Loam",
    "Where the worm turns",
    "Land",
    "Seed bed",
    "Ground",
    "Loam",
    "Smudge",
    "Earth"
  ],
  "ewok": [
    "Endor native",
    "Furry \"Star Wars\" creature",
    "Furry Endor resident",
    "Furry critter in \"Return of the Jedi\"",
    "Sci-fi forest dweller",
    "Creature on Endor",
    "Popular \"Star Wars\" doll",
    "Furry \"Star Wars\" creature",
    "Furry \"Star Wars\" creature",
    "Worshiper of C-3PO in \"Return of the Jedi\"",
    "Ursine sci-fi creature",
    "Bearlike creature in sci-fi",
    "Arboreal sci-fi creature",
    "Furry creature in \"Return of the Jedi\"",
    "\"Caravan of Courage: An ___ Adventure\" (1984 \"Star Wars\" spinoff)",
    "Furry Endor creature",
    "Furry creature from Endor",
    "Certain Endorian",
    "\"Star Wars\" furball",
    "Cuddly sci-fi creature",
    "Endor denizen",
    "\"Star Wars\" critter",
    "Furry inhabitant of the forest moon of Endor",
    "Battle of Endor combatant",
    "\"Star Wars\" biped",
    "Furry \"Star Wars\" creature",
    "Jedi's furry friend",
    "Furry ally of Luke Skywalker",
    "Denizen of the Endor world in \"Return of the Jedi\"",
    "Furry denizen of Endor",
    "Furry sci-fi creature",
    "Furry creature allied with Luke Skywalker and the Jedi knights",
    "Sci-fi's Chief Chirpa, e.g.",
    "Furry sci-fi figure",
    "\"Star Wars\" critter",
    "Creature from the forest moon of Endor",
    "Cuddly sci-fi critter",
    "Battle of Endor fighter",
    "Jedi ally",
    "\"Star Wars\" critter",
    "\"Star Wars\" creature",
    "\"Star Wars\" creature",
    "\"Star Wars\" critter",
    "\"Return of the Jedi\" critter",
    "Furry \"Star Wars\" creature",
    "Cuddly \"Return of the Jedi\" creature",
    "Friendly \"Star Wars\" creature",
    "Furry \"Star Wars\" creature",
    "Furry \"Star Wars\" creature",
    "\"The ___ Adventure\" (\"Star Wars\" spinoff)",
    "Cuddly \"Star Wars\" creature"
  ],
  "ouzo": [
    "Spirits of Greece",
    "Anise-flavored liqueur",
    "Liqueur with a licoricelike flavor",
    "Anise-flavored liqueur",
    "Anise-flavored liqueur",
    "Anise-flavored drink",
    "Greek liqueur",
    "Anise-flavored liqueur",
    "Anise-flavored liqueur",
    "Drink said to have originated on Lesbos",
    "Anise-flavored liqueur",
    "\"My Big Fat Greek Wedding\" libation",
    "Anise-flavored liqueur",
    "Liqueur similar to Sambuca",
    "Anise-flavored liqueur",
    "Anise-flavored liqueur",
    "Drink in \"My Big Fat Greek Wedding\"",
    "Greek liqueur",
    "Anise-flavored liqueur",
    "Anise-flavored liqueur",
    "Colorless liqueur",
    "Greek liqueur",
    "Greek liqueur",
    "Colorless liqueur",
    "Anise-flavored liqueur",
    "Greek liqueur"
  ],
  "drew": [
    "Participated in a pistol duel",
    "Didn't win or lose",
    "Did a sketch",
    "Doodled, e.g.",
    "Doodled, e.g.",
    "Picked a card",
    "Tied",
    "Attracted",
    "With 80-Across, one of TV's Property Brothers",
    "Nancy who solves mysteries",
    "Was the clue giver in Pictionary",
    "Attracted",
    "Took a card",
    "Set pencil to paper, in a way",
    "Took from a card deck",
    "Attracted",
    "Tied",
    "Enticed",
    "Couldn't discard in crazy eights, say",
    "Sketched",
    "Was attractive",
    "Did cartoons, e.g.",
    "Tied",
    "Took a card",
    "Drafted, with \"up\"",
    "Took a card",
    "Took a card",
    "Sketched",
    "Had no play in crazy eights",
    "Was in a no-win situation?",
    "Chose, as lots",
    "Doodled, e.g.",
    "Sketched",
    "Sketched",
    "Provoked, as enemy fire",
    "Sketched",
    "Was attractive?",
    "Sketched",
    "Keene sleuth",
    "Was in a no-win situation?",
    "Attracted",
    "Picked from the stack of cards",
    "Scored the same",
    "Prepared to shoot in a shootout",
    "Took a card",
    "Sketched",
    "Pulled a six-shooter",
    "Didn't stand pat",
    "Performed a duel action?",
    "Created a caricature",
    "Started a gunfight",
    "Didn't stand pat",
    "Pulled out a piece",
    "Pulled",
    "Didn't stand pat",
    "Attracted",
    "Selected",
    "Allured",
    "Pulled",
    "Attracted",
    "Was attractive",
    "Pulled a gun, as in a shootout",
    "A Barrymore",
    "Lured",
    "Took a straw",
    "Keene sleuth",
    "Columnist Pearson"
  ],
  "lala": [
    "[I forgot the words ...]",
    "In ___ land",
    "In ___ land (dreaming)",
    "In ___ land",
    "___ land",
    "___ land",
    "Scat snippet",
    "___ land",
    "\"___ Means I Love You\" (1968 top 10 hit by the Delfonics)",
    "\"___ Land\" (2016 Best Picture nominee)",
    "\"___ Land,\" 2016 film",
    "___ land",
    "With 58-Down, head-in-the-clouds place ... or a hint to each answer that has four circles",
    "Karaoke stand-in?",
    "___ land",
    "When repeated, part of Van Morrison's \"Brown Eyed Girl\"",
    "___ land",
    "In ___ land",
    "Karaoke stand-in?",
    "Singsong syllables",
    "Substitute for forgotten words in a song",
    "___ land",
    "Singsong syllables",
    "___ land",
    "Refrain syllables",
    "\"___ Means I Love You\" (1968 Delfonics hit)",
    "Follower of \"ooh\" or \"tra\"",
    "In ___ land",
    "In ___ land (daydreaming)",
    "Song syllables",
    "Singsong syllables",
    "Singsong syllables",
    "___ land",
    "___ land",
    "In ___ land",
    "___ land",
    "Fa follower",
    "___ land",
    "Syllables in \"Deck the Halls\"",
    "Refrain syllables",
    "Part of many a refrain",
    "Fa followers",
    "See 37-Down",
    "In ___ land",
    "Part of a musical refrain",
    "Refrain syllables",
    "___ land",
    "___ land",
    "___ land",
    "In ___ land (spacy)",
    "Song bit",
    "Kind of land",
    "In ___ land (spaced out)",
    "Kind of land",
    "Chorus syllables",
    "[\"I can't remember the words...\"]",
    "Kind of land",
    "In ___ land (dreaming)",
    "Singer's refrain",
    "Syllables meaning \"I forgot the words\"",
    "In ___ land (spacy)",
    "Kind of land",
    "Bit of a song refrain",
    "Song syllables",
    "Tra trailer",
    "Kind of land",
    "\"Ooh\" follower",
    "Carol syllables",
    "In-___ land (spacy)",
    "Song syllables",
    "___land (Los Angeles)",
    "Carol syllables",
    "Carol syllables",
    "Refrain part",
    "Carol syllables",
    "All-purpose song lyrics"
  ],
  "leis": [
    "Adornments sometimes made with kukui nuts",
    "Gifts that are usually scented",
    "Luau loops",
    "Aromatic attire",
    "Gifts that one usually bows when receiving",
    "Hula hoops?",
    "Floral necklaces",
    "They go around heads around Diamond Head",
    "Gifts often received while bowing the head",
    "Fragrant neckwear",
    "Maui mementos",
    "Gifts of flowers",
    "Luau handouts",
    "Hawaiian handouts",
    "Strings of islands?",
    "Island rings",
    "Waikiki wear",
    "Hawaiian handouts",
    "Honolulu keepsakes",
    "Hawaiian garlands",
    "Hawaiian strings?",
    "Luau garlands",
    "Gifts at Honolulu Airport",
    "Rings of islands",
    "Kauai keepsakes",
    "Island garlands",
    "Rings of plumerias, e.g.",
    "Chaplet relatives",
    "Island chains",
    "Gifts of greeting",
    "Oahu keepsakes",
    "Rings of islands",
    "Gifts at Honolulu International Airport",
    "Hula hoops?",
    "Nice-smelling gifts",
    "Island rings",
    "Island rings",
    "Orchid products",
    "Fragrant rings",
    "Luau necklaces",
    "Kauai keepsakes",
    "Oahu wreaths",
    "Luau souvenirs",
    "Souvenirs with scents",
    "Hawaiian neckwear",
    "Fragrant hangers",
    "Maui necklaces",
    "Aloha gifts",
    "Hawaiian gifts",
    "Vacation souvenirs",
    "Arrival gifts in Honolulu",
    "Garlands",
    "Luau souvenirs",
    "\"Fantasy Island\" props",
    "Wahines' wear",
    "Oahu neckwear",
    "Cruise ship freebies",
    "Island necklaces",
    "Oahu garlands",
    "Rings of blooms",
    "Floral wear",
    "Pearl Harbor tributes",
    "Hilo mementos",
    "Muumuu accessories",
    "Napoopoo neckwear"
  ],
  "last": [
    "Hold up",
    "Persist",
    "Have legs, so to speak",
    "Like a senior year",
    "Word with dance or chance",
    "Winning the booby prize",
    "Persist",
    "Bringing up the rear",
    "Like Leo XIII among all popes named Leo",
    "In the cellar",
    "Like omega, in the Greek alphabet",
    "Take a while to wear off",
    "Like a headlining act, typically",
    "Z's position",
    "Hold out",
    "Like \"Annabel Lee\" among all Poe poems",
    "Winning the booby prize",
    "Have legs",
    "Like this clue among all the Acrosses",
    "Hindmost",
    "Bad way to finish",
    "Endure",
    "Like Z, alphabetically",
    "Have legs",
    "In the cellar",
    "In a bad place?",
    "Remain",
    "Endure",
    "Endure",
    "In the cellar",
    "Finishing 11th out of 11, e.g.",
    "Keep",
    "Caboose's place",
    "In the cellar",
    "Bringing up the rear",
    "Trailer's place",
    "Final",
    "Wear well",
    "Bringing up the rear",
    "Wear",
    "Place trailers are in",
    "Not fizzle out",
    "\" ?à and the ___ shall be first\": Matthew",
    "Survive",
    "Endure",
    "Go the distance",
    "Stay to the finish",
    "Go on",
    "Bringing up the rear",
    "Rounding out",
    "In the cellar",
    "Go on",
    "Hold up",
    "Hang in there",
    "Which words to read in 20-, 38- and 53-Across to get a warm welcome",
    "Up-to-the-minute",
    "Definitive",
    "Remain in good shape",
    "Conclusive",
    "Taking the booby prize",
    "Shoe filler",
    "In the cellar",
    "Endure",
    "Bringing up the rear",
    "Wear well",
    "Survive",
    "Wear well",
    "Word before laugh or straw",
    "Cellar dweller's place",
    "Hold up",
    "Endure",
    "Endure",
    "Cobbler's form",
    "Weather",
    "Wear well",
    "Third of three",
    "In the rear",
    "Z-like",
    "Wear well",
    "Go on",
    "Be steadfast",
    "___ hurrah",
    "Ultimate",
    "Endure"
  ],
  "toys": [
    "Santa's sleighful",
    "Tops and blocks, e.g.",
    "Buzz Lightyear and Woody, e.g.",
    "\"Dear Santa\" requests",
    "Messes around (with)",
    "Building blocks, e.g.",
    "Department store department",
    "Lincoln Logs and such",
    "Tops and such",
    "Pole workers' creations",
    "Output of Santa's workshop",
    "___ & Hobbies (eBay category)",
    "Yo-yo and Etch A Sketch",
    "Santa's bagful",
    "Transformers and Barbies",
    "Sleighful",
    "Mr. Potato Head and G.I. Joe",
    "Plays (with)",
    "Santa's bagful",
    "Santa's sleighful",
    "1992 Robin Williams movie",
    "Kids' stuff",
    "Chest contents",
    "Santa's sackful",
    "Child's closetful",
    "Kids' stuff",
    "Jacks and such",
    "Santa's sackful",
    "Kids' closetful",
    "North Pole \"exports\"",
    "Contents of some chests",
    "Hula hoops and yo-yos",
    "Playpen pile",
    "Nursery collection",
    "Kiddy litter?",
    "Children's Christmas wish",
    "Puppets, e.g.",
    "Power Rangers and such",
    "Building blocks, e.g.",
    "Model cars, e.g.",
    "Some are tops",
    "1992 Robin Williams movie",
    "F.A.O. Schwarz goods",
    "Tops, e.g.",
    "Hellman's were in the attic",
    "Santa's bagful",
    "Slinky et al.",
    "Robin Williams film",
    "North Pole exports"
  ],
  "mate": [
    "Problem for a king",
    "Bud",
    "___ in three (chess challenge)",
    "Each animal in Noah's Ark had one",
    "Game's end",
    "#, in chess notation",
    "Chess ending",
    "What \"#\" means in chess notation",
    "Half of a pair",
    "Another end of a chess game",
    "Ninth word ...",
    "One sock, to another",
    "Dating service success",
    "Australian friend",
    "One to whom you might say \"G'day!\"",
    "Impossible chess ending with a king and knight versus a lone king",
    "\"Check and ___\"",
    "Winning move",
    "Chum",
    "Bud",
    "Kiwi's companion",
    "Chess ending",
    "Chess finale",
    "Australian pal",
    "Aussie's buddy",
    "Canberra chum",
    "Counterpart",
    "Bud",
    "Running ___",
    "9-Across ending",
    "Partner",
    "Outback buddy",
    "The other shoe, e.g.",
    "Corner, as a king",
    "Crew member",
    "Recipient of \"G'day\"",
    "Pal",
    "Final check",
    "Chess ending",
    "Spouse",
    "Deck officer",
    "Chess ending",
    "Pal",
    "Pal",
    "Bloke",
    "Chess player's cry",
    "Captain's aide",
    "Game-ending word",
    "Pal",
    "\"G'day, ___!\"",
    "Second sock, say",
    "Chess ending",
    "Pal in Sydney",
    "What \"++\" means in Qe2++",
    "Certain game ending",
    "Chess ending",
    "What a stocking needs",
    "Pal, in Perth",
    "Wife",
    "Pal from Down Under",
    "Game ending",
    "One of a couple",
    "Game ender",
    "Bad news for the king",
    "Buddy",
    "\"G'day\" recipient",
    "Aussie buddy",
    "Pal, Down Under",
    "Chess finale",
    "Sock necessity",
    "Spouse",
    "Chess finale",
    "Better half",
    "Last word in chess",
    "Friend Down Under",
    "Chess finale",
    "Buddy",
    "Part of a pair",
    "Word with soul or help"
  ],
  "esso": [
    "\"Put a tiger in your tank\" sloganeer",
    "Big name in petrol",
    "Canadian gas brand",
    "Standard Oil offshoot",
    "Competitor of Petro-Canada",
    "Brand on the Alaska Highway",
    "Place to fill up in Canada",
    "\"Put a tiger in your tank\" brand",
    "ExxonMobil abroad",
    "Competitor of Petro-Canada",
    "\"Happy Motoring\" sloganeer, once",
    "Major name in petrol",
    "Competitor of Petro-Canada",
    "\"Put a tiger in your tank\" gas brand",
    "Big name in petrol",
    "Canadian gas brand",
    "Gas brand with an oval logo",
    "Big brand of petrol",
    "Gas station in Canada",
    "Gas brand whose logo has a blue oval",
    "Old U.S. gas brand still seen in Canada",
    "Canadian filling station",
    "Old \"Happy Motoring\" brand",
    "Canadian gas brand",
    "Competitor of Petro-Canada",
    "Canadian gas brand",
    "North-of-the-border station",
    "\"Put a tiger in your tank\" brand",
    "He, in Italian",
    "Petro-Canada competitor",
    "Canadian fill-up choice",
    "Canadian gas station",
    "Classic roadside logo name with red lettering in a blue oval",
    "Competitor of Petro-Canada",
    "Brand operated by Imperial",
    "It's a gas up north",
    "Standard offshoot",
    "Canadian fuel name",
    "Old station name",
    "Station on the Alaska Highway",
    "62-Across option north of the border",
    "Canadian business often connected to a Tim Hortons",
    "Standard offspring",
    "Non-U.S. gas brand",
    "International gas brand",
    "Standard breakup creation",
    "Old station name",
    "\"Put a tiger in your tank\" brand",
    "Gas brand in Canada",
    "\"Happy Motoring\" gas brand",
    "It, in Italy",
    "Bygone U.S. gas brand",
    "Former name of Exxon stations",
    "Place to fill up in Canada",
    "Canadian roadside sign",
    "Petrol brand",
    "Petro-Canada competitor",
    "Tiger Express station brand",
    "Brand name used by Jersey Standard",
    "Brand name in a blue oval",
    "Gas brand with a tiger symbol",
    "Canadian gas brand",
    "Canadian station",
    "Gas brand with a tiger symbol",
    "Gas brand north of the border",
    "Former U.S. gas brand",
    "Old U.S. gas brand with a tiger symbol",
    "Onetime Texaco competitor",
    "Old \"Put a tiger in your tank\" brand",
    "\"Put a tiger in your tank\" brand",
    "___ Blue (old kerosene brand)",
    "Shell alternative",
    "Old U.S. gas brand",
    "Old U.S. gas brand",
    "Canadian gas brand",
    "Shell alternative",
    "Old U.S. gas brand",
    "Canadian station sign",
    "Gas brand that's also an Italian pronoun",
    "\"Happy Motoring\" sloganeer",
    "Canadian gas brand",
    "Gulf competitor",
    "Gas brand in Canada",
    "Old Sinclair rival",
    "Shell alternative",
    "\"Put a tiger in your tank\" brand",
    "Flying A competitor",
    "\"Put a tiger in your tank\" brand",
    "Canadian station name",
    "Petrol brand",
    "Gas brand in Canada",
    "Old pump name",
    "Brand named after the pronunciation of its parent company's initials",
    "Petro-Canada competitor",
    "Gas brand in Canada",
    "Edsel driver's gas choice",
    "Gas brand with the slogan \"Put a tiger in your tank\"",
    "Bygone U.S. gas brand",
    "\"Happy Motoring\" sloganeer",
    "Bygone U.S. gas brand",
    "Sinclair alternative",
    "\"Happy Motoring\" brand",
    "Brand name that's coincidentally Italian for \"it\"",
    "Brand name that sounds like two letters of the alphabet",
    "Sponsor of early radio's \"Five Star Theater\"",
    "Saskatchewan station name",
    "Petrol brand",
    "Onetime distributor of free maps",
    "Bygone U.S. gas brand",
    "Exxon abroad",
    "Petrol brand",
    "Old roadside name",
    "Big petrol seller",
    "Gas brand in Canada",
    "Exxon, formerly",
    "Brand with a tiger mascot",
    "Station along Route 66",
    "Old U.S. gas brand",
    "Sinclair rival, once",
    "Canadian pump name",
    "Classic brand whose symbol is a tiger",
    "Old station name",
    "Gas company known for its tiger slogan",
    "Petrol provider",
    "Classic gas brand",
    "Classic brand with an oval logo",
    "Petrol provider",
    "Gas brand in Canada",
    "Bygone U.S. gasoline",
    "Canadian gas brand",
    "Petrol brand",
    "Italian pronoun",
    "Classic gas",
    "Petrol brand",
    "Old U.S. gasoline",
    "Pump sign in Canada",
    "Studebaker's fill-up, maybe",
    "Gas brand in Canada",
    "Canadian gas brand",
    "Cities Service competitor",
    "\"The sign of extra service\" sloganeer, once",
    "Logo along U.S. highways, once",
    "\"Happy Motoring\" company",
    "Classic brand name in an oval logo",
    "Brand with a tiger slogan",
    "Classic gas brand",
    "Conoco rival",
    "___ Plaza, former Calgary landmark name",
    "\"The sign of extra service\" sloganeer",
    "Old U.S. gas brand",
    "Cities Service competitor",
    "Old \"Happy motoring!\" sloganeer",
    "Station name in England",
    "Old U.S. gas brand",
    "Sinclair rival",
    "Old station name",
    "Station along Route 66",
    "Bygone U.S. gas brand",
    "Its slogan was once \"The sign of extra service\"",
    "Cities Service competitor",
    "Old station identification",
    "It was associated with Humble Oil",
    "European gas brand",
    "Old \"Happy motoring!\" sloganeer",
    "Conoco competitor",
    "Company with the old mascot Little Oil Drop",
    "Big name in petrol",
    "Flying A competitor",
    "Exxon predecessor",
    "\"Happy Motoring\" company",
    "Sinclair rival",
    "Sinclair alternative",
    "Gas of the past",
    "\"Happy Motoring\" sloganeer",
    "Old Standard Oil brand",
    "A gas from the past",
    "Old rival of 105-Across",
    "Little Oil Drop was its mascot",
    "\"Put a tiger in your tank\" company",
    "Exxon predecessor",
    "Bygone U.S. gas name",
    "Predecessor of Exxon",
    "Bygone brand on U.S. highways",
    "Old station identification",
    "Sinclair rival",
    "Old name in oil",
    "Renamed oil company",
    "Petrol seller",
    "Petrol brand",
    "Exxon, once",
    "Defunct oil name",
    "Bygone brand on U.S. highways",
    "Gulf competitor",
    "Petrol station choice",
    "Old Phillips 66 rival",
    "Bygone sign on U.S. highways",
    "Acronym formed from \"Standard Oil\"",
    "Old U.S. gas brand",
    "Canadian oil company",
    "\"The sign of extra service\" sloganeer",
    "Exxon, once",
    "Sinclair rival",
    "Exxon predecessor",
    "Old gas brand",
    "Classic gas brand",
    "Bygone U.S. gas brand",
    "Jersey Standard's other name",
    "This put a tiger in your tank",
    "Old Shell rival",
    "Socony rival",
    "Where an Edsel filled up, maybe",
    "Sinclair rival",
    "U.S. brand retired in 1972",
    "It, in Italy",
    "Bygone oil company",
    "Old gas name",
    "Bygone gas brand",
    "Former name at the pumps",
    "Sinclair alternative",
    "Oil company of old",
    "Conoco competitor",
    "Standard Oil logo",
    "Phillips 66 competitor",
    "Bygone gas brand",
    "Sinclair rival",
    "Renamed oil co.",
    "Conoco rival",
    "Sinclair rival",
    "Bygone motoring brand",
    "Onetime Texaco rival",
    "Conoco rival",
    "Former Sinclair competitor",
    "Old gas brand",
    "Old gas brand"
  ],
  "data": [
    "Cellphone plan concern",
    "Figures",
    "Cloud contents",
    "\"Star Trek\" character played by 12-Down",
    "Numerical information",
    "Component of a cellphone bill",
    "Figures, e.g.",
    "Facts and figures",
    "\"The world's most valuable resource is no longer oil, but ___\": The Economist",
    "Just the facts",
    "Figure in cellphone plans",
    "80-Down android",
    "___ plan",
    "What your phone uses up when you're not on Wi-Fi",
    "Facts and figures",
    "Figures",
    "Contents of spreadsheets",
    "It may be roaming overseas",
    "Help in supporting (or refuting) a hunch",
    "Mining target",
    "*Part of a scatter diagram",
    "Analytics fodder",
    "Facts and figures",
    "Stuff to collect and crunch",
    "___ roaming (smartphone setting)",
    "Facts and figures",
    "Figures on a spreadsheet",
    "___ point",
    "It may be stored on the cloud",
    "Facts and figures",
    "Spreadsheet figures",
    "Numbers to crunch",
    "Subject of a cellphone cap",
    "Spreadsheet filler",
    "Percentages and such",
    "Figures to be processed",
    "Crunched material",
    "Grist for a statistician",
    "Polling figures, e.g.",
    "Numbers to crunch",
    "Spreadsheet contents",
    "Table filler",
    "___ mining",
    "Facts and figures",
    "Numerical info",
    "Minable material",
    "Facts and figures",
    "Flash drive filler",
    "Bank deposit?",
    "Pollster's need",
    "Facts and figures",
    "Processed material",
    "Almanac contents",
    "Figures",
    "Flash drive filler",
    "Numbers to be crunched",
    "It's crunched",
    "It may be classified",
    "Appendices with some studies",
    "Facts and figures",
    "Input for computers",
    "Facts and figures",
    "Raw material, of sorts",
    "Crunched numbers",
    "What's spread on a spreadsheet",
    "Spreadsheet contents",
    "Raw material?",
    "It may be mined",
    "Spreadsheet fill",
    "What pollsters need",
    "Facts and figures",
    "Flash drive filler",
    "Flash drive filler",
    "Facts",
    "Facts and figures",
    "Polling results, e.g.",
    "Bank contents",
    "Experimental figures",
    "Figures",
    "Experiment's yield",
    "It's sometimes mined",
    "Facts and figures",
    "Spreadsheet figures",
    "Computer info",
    "Food for thought?",
    "Spreadsheet fill",
    "Numbers to crunch",
    "Holdings of some banks",
    "Disk contents",
    "Bank contents",
    "Figures",
    "Computer fodder",
    "Facts",
    "Spreadsheet filler",
    "Info",
    "Raw material?",
    "Numbers to crunch",
    "Figures",
    "Information",
    "Facts and figures",
    "Stuff to be crunched",
    "Facts",
    "Numbers to crunch",
    "Figures",
    "Info",
    "Stuff to be crunched",
    "Figures in tables",
    "Figures",
    "Facts and figures",
    "Spreadsheet numbers",
    "Chart fillers",
    "Contents of some banks",
    "Grist for processors",
    "Figures",
    "Kind of bank",
    "Kind of processing",
    "Spreadsheet material",
    "Table material",
    "Bits",
    "Info",
    "Disk contents",
    "Perot's Electronic Systems",
    "Figures"
  ],
  "skat": [
    "Card game in which jacks are the top four trumps",
    "32-card card game",
    "Three-player game",
    "Three-person card game",
    "32-card card game",
    "Game in which sevens are low",
    "Trick-taking game",
    "Three-player card game",
    "It uses sevens through aces",
    "Game in which top trumps are called matadors",
    "32-card game",
    "Game with a 32-card deck",
    "Card game played without twos through sixes",
    "Trick-taking game",
    "Trick-taking game played with 32 cards",
    "Game similar to bridge",
    "Trick-taking card game",
    "Trick-taking card game",
    "32-card game",
    "Card game popular in Germany",
    "Three-player game",
    "Game with trumps",
    "32-card game",
    "Game with 32 cards",
    "Game in which a player may be schneidered",
    "Activity in which people are not playing with a full deck",
    "32-card game",
    "Card game for three",
    "Card game with no cards below seven",
    "Trick-taking card game",
    "Game in which jacks are the highest trumps",
    "Card game for three",
    "Card game in which jacks are highest trumps",
    "It doesn't require a full deck",
    "Trick-taking game with 32 cards",
    "Game in which jacks are always highest trumps",
    "It's played with a deck of 32 cards",
    "Game with trump cards",
    "32-card game",
    "Three-player game",
    "It uses sevens through aces",
    "Game in which jacks are highest trumps",
    "Bridgelike game",
    "Game in which jacks are highest trumps",
    "32-card card game",
    "Card game for three",
    "It requires 32 cards",
    "32-card game",
    "Three-person card game",
    "32-card game",
    "Card game played with sevens through aces",
    "Three-handed card game",
    "Three-player card game",
    "Game with matadors and schneiders",
    "Three-handed card game",
    "Three-player card game",
    "32-card game",
    "32-card game",
    "Three-player card game",
    "Three-handed card game",
    "Card game",
    "Card game for three",
    "Three-person card game",
    "Three-handed card game",
    "32-card game"
  ],
  "polk": [
    "Tennessee governor who became president",
    "Florida county named for a president",
    "Apt surname for an acupuncturist?",
    "President after Tyler",
    "President after Tyler",
    "President after Tyler",
    "Annual journalism award, informally",
    "\"54-40 or fight\" candidate",
    "Clay defeater",
    "President before Taylor",
    "San Francisco street named for a president",
    "Defeater of Clay",
    "President after Tyler",
    "Prestigious journalism award",
    "Tyler and Taylor's go-between",
    "11th President"
  ],
  "pour": [
    "Really come down",
    "Decant",
    "Rain heavily",
    "Come down in buckets",
    "Do a wine steward's job",
    "Flood (in)",
    "Rain really hard",
    "Rain, rain, rain",
    "Come down hard",
    "Rain cats and dogs",
    "Rain cats and dogs",
    "Mixologist's instruction",
    "Preside over the tea ceremony",
    "Rain cats and dogs",
    "Serve, as tea",
    "Dispense, as milk",
    "Rain hard",
    "Use a ewer",
    "Come down hard",
    "Come down hard",
    "Come down heavily",
    "Really come down",
    "Decant",
    "Decant",
    "Serve sake, e.g.",
    "Rain cats and dogs"
  ],
  "ooze": [
    "Move, maybe ominously",
    "Sluggish flow",
    "Slowly seep (out)",
    "Move like molasses",
    "Go slowly",
    "Give a powerful impression of",
    "Move like molasses",
    "Move like mud",
    "Seep",
    "Molten tar, e.g.",
    "Muck",
    "Seep",
    "Exude, as charm",
    "Radiate, as charm",
    "Primordial muck",
    "Seepage",
    "Trickle",
    "Exude",
    "Seepage",
    "Slimy mud",
    "Ectoplasm, e.g.",
    "Seep",
    "Trickle (through)",
    "Move like sludge",
    "Exude",
    "Exude",
    "Move like the Blob",
    "Move like goo",
    "Drippings, maybe",
    "Seep",
    "Slow flow",
    "Exude",
    "Move like molasses",
    "Move like molasses",
    "Slime",
    "Seep",
    "Primordial stuff",
    "Seep",
    "Move like mud",
    "Slime",
    "Seep",
    "Move like molasses",
    "Squishy stuff",
    "Seep",
    "Move like ketchup",
    "What 35-Down may do",
    "Seep",
    "Let out slowly",
    "Fall through the cracks?",
    "Seep",
    "Seep",
    "Move imperceptibly",
    "Opposite of gush",
    "Go through slowly",
    "Percolate",
    "Go slowly",
    "Stuff that seeps",
    "Percolate slowly",
    "Some mud",
    "Gooey stuff",
    "Percolate slowly",
    "Seep",
    "Swamp goo",
    "Move slowly",
    "Move like sludge",
    "Gooey stuff",
    "Get through slowly",
    "Exude",
    "Flow like mud",
    "Move like molasses",
    "Seep (out)",
    "Seep",
    "Sludge",
    "Exude",
    "Primordial stuff",
    "Go very slowly",
    "Leak slowly",
    "Move like goo",
    "Seep",
    "Trickle",
    "Move like molasses",
    "It comes out of a tube",
    "Move like molasses",
    "Sludge",
    "Mud, say",
    "Seep",
    "Slip (in)",
    "Seep",
    "Seep",
    "Exude",
    "Slime",
    "Seep out",
    "Get through the cracks",
    "Osmose",
    "Seep",
    "Seep",
    "Muck",
    "Seep",
    "Move like the Blob"
  ],
  "crop": [
    "Take to another dimension?",
    "Field's yield",
    "Edit out, as from a photo",
    "Bumper ___",
    "Sorghum, e.g.",
    "Word before top or circle",
    "Wheat or soybeans",
    "Take the edge off?",
    "Trim",
    "Downsize?",
    "Jockey's accessory",
    "Beans, e.g.",
    "Alfalfa or buckwheat",
    "Trim, as a photograph",
    "Trim, as a photo",
    "Photoshop option",
    "Bit of riding gear",
    "Rice, for one",
    "Corn, wheat or soybeans",
    "Trim, as a photo",
    "Wheat or corn",
    "Photoshop command",
    "Cut short",
    "Clip, as a photo",
    "Beans or wheat",
    "Seasonal yield",
    "Shorten",
    "Corn, wheat or soybeans",
    "Jockey's whip",
    "Cut down, as a photo",
    "Photoshop option",
    "Wheat or soybeans",
    "Field yield",
    "Jockey's whip",
    "Wheat, barley or beans",
    "Harvest",
    "Equestrian equipment",
    "Dusting target",
    "What's harvested",
    "It may need dusting",
    "Clip",
    "Farmer's output",
    "Cut short, maybe",
    "Field yield",
    "Field goal?",
    "Jockey's whip",
    "Cut off",
    "Appear, with \"up\"",
    "Field yield",
    "Jockey's need",
    "Riding whip",
    "Cut, as a picture",
    "Equestrian need",
    "Jockey's need",
    "Trim",
    "Riding whip",
    "Cut short",
    "Riding whip"
  ],
  "iran": [
    "Home of many Zoroastrians",
    "Big exporter of saffron",
    "Land with coasts on both the Caspian Sea and Persian Gulf",
    "One side of a 2015 nuclear agreement",
    "Country across the Persian Gulf from the 29-Down",
    "Place where Farsi is spoken",
    "Modern Persia",
    "Tehran's land",
    "Where Shiraz is located",
    "It's at the southern end of the Caspian Sea",
    "Country that borders three \"-stans\"",
    "Country whose name becomes another country if you change the last letter to a Q",
    "Charter member of OPEC",
    "Land between the Caspian Sea and the Persian Gulf",
    "Where the biblical Esther and Daniel are purported to be buried",
    "Persia, nowadays",
    "Home of about 10% of the world's oil reserves",
    "Modern-day locale of ancient Persepolis",
    "Modern-day Persia",
    "Land with an ayatollah",
    "Tehran's land",
    "1978-79 revolution site",
    "Country west of Afghanistan",
    "Country with a Supreme Leader",
    "Country whose name can also be a full sentence",
    "Tehran's land",
    "Longtime rival of Saudi Arabia",
    "Modern land in the ancient Sasanian Empire",
    "It's on the Gulf of Oman",
    "Neighbor of Afghanistan",
    "Locale of 10% of the world's oil reserves",
    "Country whose name is also a two-word sentence",
    "Neighbor of Turkey",
    "Modern-day home of the classical poet Hafez",
    "Shiraz setting",
    "1979 revolution site",
    "It's south of the Caspian",
    "Only remaining home of the Asiatic cheetah",
    "10-Across's land",
    "Persia, nowadays",
    "\"Argo\" setting",
    "Shah's onetime domain",
    "Ayatollah's home",
    "\"Argo\" setting",
    "Site of a 1953 C.I.A.-directed coup",
    "Neighbor of 23-Across",
    "Country bordering three \"-stans\"",
    "Dry country whose name is an anagram of wet weather",
    "Shah's land",
    "Tehran's land",
    "Country whose flag says \"God is great\" 22 times",
    "\"Argo\" setting",
    "Tehran's land",
    "Country subject to 2006 U.N. sanctions",
    "1979 revolution site",
    "Hassan Rouhani's land",
    "Home of the daily Hamshahri",
    "Tehran's land",
    "Land on the Persian Gulf",
    "Home to Asia's highest volcano",
    "Target of 2006 United Nations sanctions",
    "Ayatollah's home",
    "Home of Qom",
    "Founding member of OPEC",
    "Country with a supreme leader",
    "It's bordered by three countries with \"-stan\" in their names",
    "Setting for the 2012 film \"Argo\"",
    "Azadi Stadium setting",
    "Big oil exporter",
    "Modern Persia",
    "Modern home of ancient Elam",
    "Persia, now",
    "Persia, today",
    "Home of Sistan and Baluchestan",
    "Where the Baha'i faith originated",
    "\"___ (So Far Away)\" (1982 hit by A Flock of Seagulls)",
    "Theocratic state",
    "With 47-Across, it stopped flying to New York in 1979",
    "Host of the 1974 Asian Games",
    "Tehran is its capital",
    "\"Axis of evil\" land",
    "Country with a Guardian Council",
    "Charter member of 2-Down",
    "Modern home of ancient Persepolis",
    "First landfall north of Oman",
    "Bahai's birthplace",
    "Home of Sharif University of Technology",
    "Modern home of the biblical Elam",
    "Ahmadinejad's country",
    "\"Axis of evil\" land",
    "Modern locale of ancient Persepolis",
    "Ayatollah's land",
    "Modern-day Persia",
    "Tehran's land",
    "Persia, today",
    "Setting of the 2007 animated film \"Persepolis\"",
    "Ahmadinejad's land",
    "Persia, today",
    "Modern home of ancient Medes",
    "Oil-rich land",
    "Ayatollah's land",
    "Theodemocratic state",
    "Tehran's home",
    "It's about 200 miles south of Georgia",
    "Neighbor of Turkey",
    "Persia, today",
    "Ayatollah's home",
    "Modern home of ancient Media",
    "Where Farsi is spoken",
    "Tehran's land",
    "Modern locale of ancient Persepolis",
    "Gulf land",
    "Neighbor of Pakistan",
    "Home of the Zagros Mountains",
    "Mideast land",
    "OPEC member",
    "Rug source",
    "Part of Bush's Axis of Evil",
    "Part of Bush's \"Axis of Evil\"",
    "Tehran's land",
    "1979 revolution locale",
    "Modern-day theocracy",
    "Bit of Kurdistan",
    "Persia, now",
    "Its flag says \"God is great\" 22 times",
    "It's south of Georgia",
    "Tehran's land",
    "Bit of Kurdistan",
    "Shiraz locale",
    "Ayatollah's land",
    "A theocratic republic",
    "Modern locale of ancient 113-Across",
    "Gulf land",
    "Shah's land, once",
    "Side in a 1980's war",
    "Ayatollah's land",
    "Meshed's land",
    "Tehran's locale",
    "Home of many mullahs",
    "Turkmenistan neighbor",
    "Where Tabriz is",
    "Farsi-speaking land",
    "Modern locale of ancient Persepolis",
    "Neighbor of Armenia",
    "Neighbor of Afghanistan",
    "Neighbor of Azerbaijan",
    "Modern home of ancient Susa",
    "1979-81 hostage site",
    "Qom home",
    "Farsi-speaking land",
    "Turkey toucher",
    "Revolution site of 1979",
    "Zagros Mountains locale",
    "Land on the Strait of Hormuz",
    "Mullah's land",
    "Mideast theocracy",
    "1979 hostage site",
    "Persian Gulf land",
    "Carpet place",
    "Persia, now",
    "OPEC member",
    "Carpet source",
    "Caspian Sea adjoiner",
    "Big rug exporter",
    "Qom home",
    "1980 combatant",
    "Kingdom until 1979",
    "Neighbor of Turkmenistan",
    "Qum home",
    "OPEC land",
    "It's south of Georgia",
    "Mujahedin base",
    "Home of ancient Persepolis",
    "Where diners use dinars",
    "Rug source",
    "Locale for 1999 solar eclipse watchers",
    "Mullah's home",
    "Persia, now",
    "Neighbor of Pakistan",
    "Where Qum is",
    "Big oil supplier",
    "1979 revolution site",
    "Teheran's nation",
    "Persia, today",
    "Shah's land, once",
    "Where Farsi is spoken",
    "Persia, today",
    "A \"-gate\" opener",
    "Modern Media",
    "Where Baha'i began",
    "Imperial Guard's home",
    "Side in a 1980's war",
    "Tabriz's land",
    "Country not in Rushdie's travel plans",
    "Site of a 70's revolution",
    "Teheran's land",
    "Ayatollah's land",
    "Ayatollah's land",
    "Neighbor of Turkmenistan",
    "Persia, today",
    "Foe of 26-Down",
    "Where Qum is",
    "1979 revolution site",
    "___-contra",
    "Persia, today",
    "Where Tabriz is",
    "Side in a 1980's war",
    "Rafsanjani's land",
    "Land of the Peacock Throne",
    "Persia, today",
    "Persia, today",
    "Kurdish home",
    "Rug exporter",
    "Farsi is spoken here",
    "OPEC member",
    "Neighbor of Armenia",
    "Zagros Mountains site",
    "Dr. Seuss's \"If ___ the Zoo\"",
    "Shah's land",
    "Revolution site of 1979",
    "It's south of Georgia",
    "Tabriz locale",
    "Nation on the Strait of Hormuz",
    "Major rug exporter",
    "Land on the Caspian",
    "Kurdish home",
    "Member of 35-Across",
    "Subject of Gary Sick's \"All Fall Down\"",
    "Rafsanjani's country",
    "Kerman local"
  ],
  "cobb": [
    "Jazz drummer Jimmy",
    "One of the first five inductees at Cooperstown",
    "His .366 lifetime batting average is the best ever",
    "Kind of salad",
    "___ salad",
    "Outfielder Ty",
    "Outfielder who was a member of baseball's All-Century Team",
    "All-time career batting average leader",
    "All-Century Team member",
    "Dom ___, \"Inception\" hero",
    "Ty with batting titles",
    "Baseball's \"Georgia Peach\"",
    "Baseball's Georgia Peach",
    "Ty of Cooperstown",
    "Early hitting king",
    "He had 892 steals",
    "Our Gang's Fat Joe",
    "Batting wonder, 1905-28",
    "\"The Virginian\" star"
  ],
  "nora": [
    "\"Awkwafina Is ___ From Queens\" (Comedy Central series)",
    "Writer Ephron",
    "Filmmaker Ephron",
    "\"___ From Queens,\" comedy series co-created by Awkwafina",
    "\"You've Got Mail\" director Ephron",
    "Ephron who co-wrote and directed \"Sleepless in Seattle\"",
    "___ Lum, a.k.a. actress/comedian Awkwafina",
    "Romance novelist Roberts",
    "\"Silkwood\" screenwriter Ephron",
    "Screenwriter Ephron",
    "Fictional Charles",
    "Ibsen heroine",
    "\"The Thin Man\" role",
    "Writer/filmmaker Ephron",
    "___ Helmer of \"A Doll's House\"",
    "Roberts of romance",
    "Screenwriter Ephron",
    "\"When Harry Met Sally ...\" writer Ephron",
    "The \"doll\" in Ibsen's \"A Doll's House\"",
    "Romance writer Roberts",
    "Romance writer Roberts",
    "One of Asta's masters",
    "\"When Harry Met Sally ...\" screenwriter Ephron",
    "Dunn formerly of \"S.N.L.\"",
    "Ephron who wrote \"When Harry Met Sally\"",
    "Gumshoe Charles",
    "Writer/director Ephron",
    "Romance novelist Roberts",
    "Writer Ephron",
    "Romance novelist Roberts",
    "Dunn formerly of \"S.N.L.\"",
    "Romance novelist Roberts",
    "___ Barnacle, James Joyce's wife and muse",
    "Mrs. James Joyce",
    "Mrs. James Joyce",
    "\"A Doll's House\" wife",
    "\"A Doll's House\" wife",
    "Romance novelist Roberts",
    "Director Ephron",
    "\"A Doll's House\" heroine",
    "The \"doll\" in Ibsen's \"A Doll's House\"",
    "Writer Ephron",
    "Nick's partner in \"The Thin Man\"",
    "Romance writer Roberts",
    "Writer Ephron",
    "\"The Thin Man\" detective",
    "Bayes who sang and co-wrote \"Shine On, Harvest Moon\"",
    "\"A Doll's House\" wife",
    "Charles of mysteries",
    "___ Roberts, first inductee into the Romance Writers of America Hall of Fame",
    "Ibsen's ___ Helmer",
    "Doll in \"A Doll's House\"",
    "Witty Ephron",
    "Writer Ephron",
    "Best-selling author Roberts",
    "Romance novelist Roberts",
    "Romance novelist Roberts",
    "Mrs. James Joyce",
    "Daughter of Maggie and Jiggs",
    "\"A Doll's House\" wife",
    "\"Heartburn\" author Ephron",
    "Nick and ___ Charles of \"The Thin Man\"",
    "Nick Charles's wife",
    "Asta's mistress",
    "\"The Thin Man\" wife",
    "\"A Doll's House\" heroine",
    "Comic Dunn formerly of \"S.N.L.\"",
    "Writer Ephron",
    "Writer Ephron",
    "One of Asta's owners",
    "Asta's mistress",
    "19-Across's sister",
    "Writer Ephron",
    "\"A Doll's House\" heroine",
    "Author Ephron",
    "\"The Thin Man\" role",
    "Writer Ephron",
    "Writer Ephron",
    "Writer Ephron",
    "___ Bayes, who co-wrote \"Shine On, Harvest Moon\"",
    "Delia's literary sister",
    "Kaye of ballet",
    "Writer Ephron",
    "Nick's wife",
    "Writer Ephron",
    "One of Asta's masters",
    "Nick's wife in \"The Thin Man\"",
    "Ibsen doll?",
    "Bayes who sang \"Shine On, Harvest Moon\"",
    "\"A Doll's House\" heroine",
    "\"Heartburn\" author Ephron",
    "Mrs. Charles",
    "Ibsen's \"doll\"",
    "Vaudeville singer Bayes",
    "Myrna played her",
    "Ibsen heroine",
    "\"A Doll's House\" heroine",
    "\"A Doll's House\" heroine",
    "Asta's mistress",
    "\"A Doll's House\" wife",
    "Writer Ephron",
    "Writer Ephron",
    "Asta's mistress",
    "Half of a 1934 M-G-M couple",
    "One of Asta's masters",
    "Nick and ___ Charles",
    "\"A Doll's House\" heroine",
    "Asta's mistress",
    "\"A Doll's House\" heroine",
    "Nick's \"Thin Man\" wife",
    "Role for Myrna",
    "Bayes who sang \"Over There\"",
    "Mrs. Charles",
    "Writer Ephron",
    "\"Heartburn\" writer Ephron",
    "Nick and ___ Charles",
    "Asta's mistress",
    "___ Barnacle (Mrs. James Joyce)",
    "\"A Doll's House\" heroine"
  ],
  "goon": [
    "\"Tell me more ...\"",
    "Continue",
    "Mob henchman",
    "\"Continue ...\"",
    "\"Continue ...\"",
    "Mob muscle",
    "Thug",
    "Hired hitman",
    "\"Tell me more\"",
    "?ôTell me more?ö",
    "Hired thug",
    "Continue",
    "\"You were saying ...\"",
    "Keep at it",
    "Thug",
    "Hired heavy",
    "Thug",
    "Tough",
    "Tough",
    "Proceed",
    "Hoodlum",
    "Proceed",
    "Continue",
    "Thug",
    "Hoodlum",
    "Roughneck",
    "Continue"
  ],
  "logs": [
    "Things you saw while asleep?",
    "Cabin-building items",
    "\"Paul Bunyan's toothpicks\"",
    "Records",
    "Signs (on)",
    "Calc figures",
    "Stock to be split?",
    "Journals",
    "Cabin material",
    "Ship records",
    "Lincoln ___ (building toy)",
    "Hearth contents",
    "Old shipbuilding needs",
    "Records",
    "Fireplace fill",
    "Old cabin materials",
    "Roleo needs",
    "Captain Kirk's records",
    "Keeps an account of",
    "Fireplace fuel"
  ],
  "tabs": [
    "Window components",
    "Things often seen in windows",
    "Keep ___ on (look after)",
    "File extensions",
    "Closers of some boxes",
    "Things opened in bars",
    "Word document settings",
    "Things the police may keep on suspects",
    "Things that allow for jumping ahead in line?",
    "They get picked up at clubs",
    "Browser navigation aids",
    "They're sometimes picked up at bars",
    "Keep ___ on",
    "Keep ___ on (watch)",
    "They make indents",
    "File extensions",
    "They're run up",
    "Keep ___ on (watch)",
    "They may be kept on you",
    "Keep ___ on (watch)",
    "Keep ___ on",
    "Running things?",
    "Setters of indents",
    "Keep ___ on (watch)",
    "Notebook projections",
    "Keep ___ on (watch)",
    "Running things in a bar",
    "Settings in word processing",
    "Certain computer keys",
    "Running things in a bar",
    "Keep ___ on",
    "Soda can features",
    "Folder labels",
    "They may be running",
    "They may be kept on you",
    "Typists' settings",
    "File folder parts",
    "They may be picked up in bars",
    "Place holders",
    "Notebook features",
    "Tome markers",
    "Keep ___ on (watch)",
    "Day planner features",
    "Checks",
    "Bills",
    "Slot fillers",
    "Filing aids",
    "It's good to keep these on kids"
  ],
  "stag": [
    "Animal on a Jägermeister bottle",
    "Male deer",
    "Buck",
    "___ beetle",
    "One who goes into a seasonal rut",
    "___ beetle",
    "By oneself, in a way",
    "Deer sir?",
    "Without a date",
    "Unaccompanied",
    "Alone, in a way",
    "What does follow?",
    "Animal depicted in Edwin Landseer's \"The Monarch of the Glen\"",
    "Animal form of Harry Potter's Patronus",
    "Solo, in a way",
    "___ film",
    "___ party",
    "Male deer",
    "Without a date",
    "Doe's mate",
    "Deer hunter's prize",
    "Going without a date",
    "Doe's partner",
    "___ beetle",
    "Out of date?",
    "___ beetle",
    "Solo, in a way",
    "Deer John?",
    "Kind of party",
    "Hartford insurance company symbol",
    "Like bachelor parties",
    "Male deer",
    "One way to go to a party",
    "Male deer",
    "One way to go to a party",
    "___ beetle",
    "Buck",
    "Kind of film",
    "Missing women?",
    "Single partygoer",
    "Antlered animal",
    "Fawn's father",
    "For men only",
    "Fawn's father",
    "Kind of party",
    "62-Across's woodlands mate",
    "Woodlands male",
    "One way to go to a party",
    "One way to go to a party",
    "Does partner for",
    "Partnerless, as at a party",
    "Unaccompanied",
    "How some people go to a party",
    "Do before I do's",
    "Hind's mate",
    "Just for guys",
    "Dateless",
    "Male-only",
    "Unaccompanied",
    "Doe's mate",
    "Men-only",
    "Solo, in a way",
    "Six-pointer, perhaps",
    "Antler holder",
    "Unaccompanied",
    "Men-only",
    "Doe's mate",
    "Men-only",
    "One way to go to a party",
    "Alone, in a way",
    "Guys-only",
    "Antlered animal",
    "Unaccompanied",
    "Way to attend a party",
    "For men only",
    "Men-only",
    "Like some shows",
    "Like some parties",
    "Solo",
    "For men only",
    "Word with beetle or movie",
    "One way to go to a party",
    "Like a smoker",
    "Guys-only",
    "Like some parties",
    "It's a guy thing",
    "Men-only affair",
    "Night-before event",
    "Kind of beetle",
    "Deer sir",
    "Just for men",
    "Party animal?",
    "Male deer",
    "Solo",
    "Kind of show",
    "All-male",
    "Dateless",
    "Doe's mate",
    "Like bachelor parties",
    "Without a date",
    "Without women",
    "One way to go to a party",
    "Like some parties",
    "Unaccompanied",
    "Deer sir",
    "Like some dinners",
    "Like some shows",
    "Dateless",
    "Certain partygoer",
    "Deer sir",
    "Party animal?",
    "For the boys",
    "For men only",
    "Like bachelor parties",
    "For men only",
    "Hunting target",
    "Hind's partner",
    "Like some parties",
    "For men only",
    "For guys",
    "Without women",
    "Party animal?",
    "Alone",
    "Without a date",
    "Actaeon, ultimately, in Greek myth",
    "Dateless",
    "Daddy deer",
    "Solo, in a way",
    "Alone",
    "What does attract",
    "Alone",
    "Dateless",
    "Dateless",
    "Kind of movie",
    "Without women",
    "Male deer",
    "Party animal?",
    "Fawn's father",
    "How a deer might attend a party?",
    "Beau for a doe",
    "Unpartnered",
    "Doe's mate",
    "Just for guys",
    "Kind of horn or line",
    "Bellows painting \"___ at Sharkey's\"",
    "Unaccompanied",
    "Buck",
    "Dateless",
    "Hind's mate"
  ],
  "hive": [
    "Queen's domain",
    "Where everything's abuzz?",
    "With 58-Across, collective consciousness ... or a hint to the ends of 19-, 35- and 52-Across",
    "What's all the buzz about?",
    "Kind of mind",
    "Hill : ants :: ___ : bees",
    "Place that's buzzing",
    "Buzz source",
    "Where a comb may be found",
    "Bustling place",
    "Queen's residence",
    "Center of activity",
    "Drone's place",
    "Drone base",
    "Colonial home, you might say",
    "It's all abuzz",
    "___ mind",
    "Workers' place",
    "Busy place",
    "Busy place",
    "Comb container",
    "Queen's quarters",
    "Cellular construction",
    "Center of industry",
    "Busy place",
    "Honey site",
    "Busy place",
    "Place for a buzzer",
    "Center of activity",
    "Queen's domain",
    "Busy place",
    "Honey factory",
    "Busy place",
    "Place for a comb",
    "House of wax",
    "Busy place",
    "Honeycombed home",
    "Spot for a comb",
    "Drone's home",
    "Buzzing source",
    "Bees' home",
    "Honey bunch?",
    "Busy place",
    "Queen's residence",
    "Queen's home",
    "Home for 69 Across",
    "Apian abode",
    "Scene of busyness",
    "Queen's domain",
    "Swarm",
    "Cellular home",
    "Busy place",
    "Busy place"
  ],
  "ahme": [
    "Words said with a sigh",
    "Melodramatic cry",
    "\"Alas!\"",
    "Sighed words",
    "Sigher's words",
    "Words said with a sigh",
    "Exclamation that's a homophone of 53-Down",
    "Wistful plaint",
    "\"I'm not doing so well\"",
    "[sigh]",
    "\"Alas!\"",
    "\"Such is life\"",
    "World-weary words",
    "\"Oy vey\"",
    "\"Whatever shall I do?!\"",
    "Words of woe",
    "\"Alas!\"",
    "A sigh",
    "Familiar sigh",
    "Woeful words",
    "Comment made with a sigh",
    "\"Alas!\"",
    "\"What is to be done?!\"",
    "Sighed words",
    "\"Such a pity ...\"",
    "\"Alas!\"",
    "Woeful words",
    "\"Alas!\"",
    "Sighed aside",
    "\"Life is hard ...\"",
    "Words of wistfulness",
    "[sigh]",
    "\"Alas!\"",
    "Woeful words",
    "Quaint sigh",
    "[sigh]",
    "Words said with a sigh",
    "Words accompanied by a sigh",
    "\"Sigh!\"",
    "Sighed line?",
    "\"Alas!\"",
    "\"Alas!\"",
    "Sigher's words",
    "\"Alas\""
  ],
  "axis": [
    "x, for one",
    "Graph component",
    "x, y or z, in geometry",
    "x or y, in plane geometry",
    "Center of rotation",
    "Pole position?",
    "What the abscissa and ordinate are measured from",
    "x, y or z",
    "Enemy alliance in W.W. II",
    "Pivot point",
    "x or y, on a graph",
    "___ & Allies (classic board game)",
    "Line of symmetry",
    "Japan was a member of it",
    "x, y or z follower",
    "x or y",
    "Graph line",
    "W.W. II foe",
    "Quadrant separator",
    "y, for one",
    "W.W. II foe",
    "Pivot point",
    "Pole connector",
    "Turning point",
    "1940's ___ Pact",
    "Line of symmetry",
    "Line of rotation",
    "Pivotal line",
    "W.W. II enemy",
    "Germany, Italy and Japan, in W.W. II",
    "Every mirror image has one",
    "Revolutionary line",
    "W.W. II foe, with \"the\"",
    "x or y line on a graph",
    "W.W. II foe, with \"the\"",
    "Something to reflect on?",
    "Something to reflect on?",
    "Italy's side, once",
    "x or y, on a graph",
    "Graph line",
    "Line of rotation",
    "Something to turn on",
    "W.W. II foe",
    "Bush's \"___ of evil\"",
    "Transnational cooperation",
    "Alliance",
    "Graph line",
    "Graph line",
    "Alliance",
    "Line of rotation",
    "W.W. II foe",
    "Line on a graph",
    "___ of Evil",
    "x, y or z",
    "Bisector",
    "W.W. II enemy",
    "___ of Evil",
    "Base line on a graph",
    "Graph line",
    "Main line",
    "What a mirror image has",
    "1940's foe, with \"the\"",
    "Bush's ___ of Evil",
    "x, for one",
    "Line of symmetry",
    "Line of rotation",
    "Line on a graph",
    "Central line",
    "X, Y or Z",
    "x, y or z",
    "X or Y or Z",
    "Allies' foe",
    "W.W. II enemy",
    "Allies' foe in W.W. II",
    "x or y, e.g.",
    "Line of symmetry",
    "Central line",
    "Graph line",
    "Allies' foe",
    "Hungary was a member of it",
    "X or Y, on a graph",
    "40's foe",
    "W.W. II side",
    "W.W. II powers",
    "\"Them\" of the 40's",
    "Poles' connector",
    "Center of a revolution",
    "Stick through the middle",
    "World War II foe",
    "Bygone alliance",
    "Turning point",
    "W.W.II foe"
  ],
  "wets": [
    "Throws cold water on, say",
    "Moistens",
    "Licks, say",
    "Mists, e.g.",
    "Dampens",
    "Keeps fresh, in the produce section",
    "Spritzes, e.g.",
    "Hoses down",
    "Bastes, e.g.",
    "Dips in a bucket, say",
    "Moistens",
    "Dampens",
    "Sponges, say",
    "Licks, e.g.",
    "Bedews",
    "Volstead Act opponents",
    "Believers in raising spirits?",
    "Moistens",
    "Moistens",
    "Licks, maybe",
    "Dampens",
    "Soaks",
    "Dampens",
    "Soaks",
    "Volstead Act opponents",
    "Licks, say",
    "Sprays",
    "Licks",
    "Dampens",
    "Volstead Act opponents",
    "Antiprohibitionists",
    "Antiprohibitionists"
  ],
  "rise": [
    "Shine's partner",
    "Angry reaction",
    "What good soufflés do",
    "What prices do during inflation",
    "Maya Angelou's \"And Still I ___\"",
    "What matzo doesn't do",
    "Word ladder, part 1",
    "What yeast makes dough do",
    "Get out of bed",
    "What bread dough and the morning sun do",
    "Move up",
    "Move skyward",
    "Uptick",
    "Stop lying",
    "Climb",
    "Get up",
    "Show deference to an entering judge, say",
    "Tailor's concern",
    "Opposite of set",
    "\"All ___\"",
    "\"All ___!\" (court exclamation)",
    "Order in the court",
    "Ascend",
    "Get a promotion",
    "Quit lying",
    "Ascend",
    "Gentle hill",
    "Stand",
    "Progress",
    "React to yeast",
    "Hillock",
    "\"All ___\"",
    "Hillock",
    "Exhibit upward mobility?",
    "Get out of bed",
    "Quit lying",
    "Knoll",
    "Increase",
    "Show some respect to a judge",
    "Go up",
    "\"All ___!\" (court cry)",
    "Honor His Honor, say",
    "Burgeon",
    "Knoll",
    "Mount",
    "Prepare to sing an anthem",
    "Attain success",
    "Beginning",
    "React to a crowing rooster, say",
    "Go up",
    "Levitate",
    "Surge",
    "Get higher",
    "Small hill",
    "Early career trajectory",
    "\"All ___\"",
    "Stand",
    "Move skyward",
    "Opposite of fall",
    "Angry reaction",
    "Spring (from)",
    "Progress",
    "Sprout",
    "Effect of yeast on dough",
    "Ascent",
    "Souffles do it",
    "Acclivity",
    "Appear on the scene",
    "Stairstep measure",
    "Shine's partner",
    "Slope",
    "Greet the day",
    "Lift off",
    "Hillock",
    "Fall's opposite",
    "Ascent",
    "Greet the day",
    "Souffles do it",
    "Angry reaction",
    "Get promoted",
    "Show respect, in a way",
    "Irked reaction",
    "Piece of high ground",
    "Greet the day",
    "Greet the judge",
    "Stairstep measure",
    "___ and shine",
    "Stand",
    "Faith healer's directive",
    "Be upwardly mobile?",
    "Good news on Wall Street",
    "\"All ___!\" (cry in court)",
    "Hillock",
    "Show respect to a judge",
    "Court order?",
    "Hillock",
    "Court order?",
    "Get out of bed",
    "Swell",
    "Climb",
    "Angry reaction",
    "Slope",
    "\"All ___\"",
    "Yeast's effect on cake",
    "Obey a court order",
    "Get up",
    "Irate reaction",
    "React to yeast",
    "Skyrocket",
    "Greet with old-fashioned etiquette",
    "Acclivity",
    "Near the surface",
    "Provoked response",
    "Upswing",
    "Levitate",
    "Shine's partner",
    "Skyrocket",
    "Gain in status",
    "\"And Still I ___\" (Maya Angelou poem)",
    "Angry reaction",
    "\"All ___!\" (court phrase)",
    "Shine's companion",
    "Hill",
    "Low hill",
    "Leave the ground",
    "Word providing a hint to the hidden theme answers",
    "Mezzo-soprano Stevens"
  ],
  "efts": [
    "Little salamanders",
    "Young amphibians",
    "Little salamanders",
    "Young salamanders",
    "Young newts",
    "Pond juveniles",
    "Young salamanders",
    "New newts",
    "Young salamanders",
    "Tiny amphibians",
    "Small salamanders",
    "Pond youngsters",
    "Young newts",
    "Bog youngsters",
    "Terrestrial salamanders",
    "Small amphibians",
    "Reddish-orange creatures",
    "New newts",
    "Certain newts",
    "Newts",
    "Newts",
    "Young 12-Downs",
    "Pond youngsters",
    "Young newts",
    "New newts",
    "Young newts",
    "They're spotted in ponds",
    "Young newts",
    "Terrarium youngsters",
    "Young newts",
    "New newts",
    "Red-spotted creatures",
    "Young newts",
    "Immature amphibians",
    "Red-spotted critters",
    "Young newts",
    "Lizards, old-style",
    "Some newts",
    "Terrestrial newts",
    "Newts",
    "Young newts",
    "Small amphibians",
    "Newts",
    "Salamanders",
    "Small amphibians",
    "Newts in transition",
    "Small lizards, old-style",
    "Young newts",
    "Newts",
    "Newts",
    "Newts",
    "Pond denizens",
    "Young newts",
    "Lizards, old-style",
    "Small amphibians",
    "Small amphibians",
    "Young 32-Downs",
    "Newts",
    "Salamanders",
    "Newts",
    "Little red critters"
  ],
  "shes": [
    "\"___ Gotta Have It\" (Spike Lee film)",
    "\"___ All That\" (1999 film)",
    "\"Can't You See That ___ Mine\" (1964 hit)",
    "\"___ Gotta Have It\"",
    "\"___ the Man\" (2006 film inspired by \"Twelfth Night\")",
    "\"___ All That\" (1999 rom-com)",
    "The Beatles' \"___ Leaving Home\"",
    "The Beatles' \"___ Leaving Home\"",
    "\"___ So Unusual,\" debut album by Cyndi Lauper",
    "Spike Lee's \"___ Gotta Have It\"",
    "\"___ Funny That Way\" (old song standard)",
    "Cows and sows",
    "\"___ Funny That Way\" (classic song)",
    "Females",
    "The Rolling Stones' \"___ a Rainbow\"",
    "\"___ All That\" (1999 rom-com)",
    "Tom Jones's \"___ a Lady\"",
    "Does, e.g.",
    "\"___ Mine, All Mine\" (1920s tune)",
    "\"___ Mine,\" 1984 Steve Perry hit",
    "\"___ out of your league, man!\"",
    "Hens and heifers",
    "\"___ Like the Wind\" (song from \"Dirty Dancing\")",
    "Hens and vixens",
    "\"I'll have what ___ having\"",
    "Steve Perry hit \"___ Mine\"",
    "\"___ the Boss\" (Mick Jagger album)",
    "Dams and does",
    "\"___ Gotta Have it\" (Spike Lee film)",
    "Does, say",
    "Spike Lee's \"___ Gotta Have It\"",
    "Kenny Rogers's \"___ a Mystery\"",
    "\"___ All That\" (Freddie Prinze Jr. film)",
    "The Rolling Stones' \"___ a Rainbow\"",
    "The Beatles' \"___ a Woman\"",
    "Elvis Presley's \"___ Not You\"",
    "Misses, e.g.",
    "Tom Jones's \"___ a Lady\"",
    "Sinatra tune \"___ Funny That Way\"",
    "Cows and sows",
    "The Beatles' \"___ Leaving Home\"",
    "\"___ Gotta Have It\"",
    "\"___ All That\" (1999 comedy)",
    "\"___ a Lady\" (Tom Jones hit)",
    "\"___ Gone\" (Hall & Oates hit)",
    "Girls, e.g.",
    "Women",
    "Mothers and daughters",
    "\"___ Always a Woman\" (Billy Joel hit)",
    "Cows and sows",
    "Doe and dam",
    "Tom Jones hit \"___ a Lady\"",
    "The Rolling Stones' \"___ a Rainbow\"",
    "Tom Jones's \"___ a Lady\"",
    "Spike Lee's \"___ Gotta Have It\"",
    "Patsy Cline's \"___ Got You\"",
    "Cows and sows",
    "\"___ the One\" (1996 comedy)",
    "\"___ a Lady\"",
    "Hall & Oates's \"___ Gone\"",
    "Tom Jones's \"___ a Lady\"",
    "Tom Jones's \"___ a Lady\"",
    "The Beatles' \"___ Leaving Home\"",
    "Elvis's \"___ Not You\"",
    "\"___ Gotta Have It\" (Spike Lee film)",
    "Tom Jones's \"___ a Lady\"",
    "\"___ My Girl\" (1967 hit)",
    "\"___ a Woman\" (Beatles tune)",
    "\"___ the One\" (1996 movie)",
    "1976 hit\"___Gone\"",
    "The Beatles' \"___a Woman\"",
    "The Beatles' \"___Leaving Home\"",
    "1929 hit \"___Funny That Way\"",
    "Mother and daughter",
    "Ladies",
    "Paul Anka's \"___ a Lady\"",
    "Ewes, e.g."
  ],
  "knee": [
    "Something taken in protest",
    "Take a ___",
    "Where bursitis may appear",
    "Bendable body part",
    "It may be taken in protest",
    "Part of the body mentioned in \"Oh! Susanna\"",
    "Part of jeans that might be ripped",
    "To take this, paradoxically, might signify taking a stand",
    "Take a ___",
    "It's a real bender",
    "Pants part that might need patching",
    "Patella's place",
    "Place to play spoons",
    "It bends when you sit",
    "Thigh/shin separator",
    "It's used for kicks",
    "On bended ___",
    "Provider of child support?",
    "___-high",
    "One might be taken in protest",
    "Take a ___ (protest, in a way)",
    "Joint between the ankle and hip",
    "Pad site",
    "Something to take in protest",
    "On bended ___",
    "Patella's joint",
    "___ pants",
    "Joint between the hip and ankle",
    "Part of the body to slap",
    "Perch for a bouncing baby",
    "Place to play musical spoons",
    "Place to bounce a baby",
    "Site for an A.C.L. tear",
    "Pants part that might be patched",
    "Bendable body part",
    "Something taken on a field?",
    "Patella's place",
    "Word with high or hole",
    "Site of arthroscopic surgery",
    "Leg bender",
    "Patella site",
    "See 25-Down",
    "Attack in an underhanded way",
    "It's capped and often slapped",
    "Place for the banjo in \"Oh! Susanna\"",
    "Sitting spot for a child visiting Santa",
    "Spot hit by a reflex hammer",
    "End of a lap",
    "Point of genuflection",
    "One of the eight points of contact in Muay Thai",
    "___-length",
    "Housemaid's ___ (bursitis)",
    "With 36-Down, bit of clothes mending",
    "Just above where 35-Across end",
    "Cap site",
    "Banjo's place in \"Oh! Susanna\"",
    "Place for a brace",
    "Something to make a proposal on?",
    "Support for a proposal?",
    "It may be slapped after a joke",
    "___ socks",
    "Something a mini leaves exposed",
    "One might be brought up in a brawl",
    "Patella's place",
    "Proposer's prop?",
    "Lower end of the strike zone",
    "See 16-Across",
    "Place for a jerk?",
    "Deep or high lead-in",
    "Bender?",
    "Midpoint between a midi and a mini",
    "Gonitis target",
    "Attack at close range, maybe",
    "Place for a skateboarder's pad",
    "Reflex test site",
    "Place to rest a guitar",
    "Cap site",
    "Leg joint",
    "Place for a football pad",
    "Word before deep or high",
    "Stocking part",
    "Place for a pad",
    "Impact point for a spoons player",
    "Often-replaced part",
    "What might give a physical reaction?",
    "Joint for a beggar?",
    "Banjo site?",
    "Human equivalent of a horse's stifle",
    "See 65-Down",
    "Last word in \"Oh! Susanna\"",
    "It's just below the thigh",
    "Common sports injury site",
    "Gonitis locale",
    "\"Trick\" body part",
    "Baby bouncer",
    "Midleg joint",
    "Common arthritis site",
    "Banjo supporter",
    "See 29-Down",
    "Baby-bouncing locale",
    "Thigh/shin connector",
    "\"Oh! Susanna\" closer",
    "Common injury site",
    "Place for a footballer's pad",
    "It might be slapped after a good joke",
    "Trick part?",
    "Joint that may jerk",
    "Orthopedic specialty",
    "Thigh/shin separator",
    "Banjo site in \"Oh! Susanna\"",
    "Gonitis target",
    "Place to put a banjo",
    "Leg's midpoint",
    "Common arthroscopy site",
    "Place for a pad",
    "Body part with a cap",
    "Support for a proposal",
    "See 103-Down",
    "Pad site",
    "Cap site",
    "Something that's bruisable",
    "Joint with a cap",
    "Elbow's lower counterpart",
    "Supporter of a proposal?",
    "Where pants may have a hole",
    "Patella's place",
    "Meniscus location",
    "Place for a bouncing baby",
    "Hem length, maybe",
    "Deal a low blow",
    "Body part that may be bent",
    "Tot's perch",
    "Trouser part",
    "Pants part",
    "Place for a pad",
    "Kind of pad",
    "Leg's middle",
    "Patella's place",
    "Where pants may be worn",
    "\"Trick\" body part",
    "Hit below the belt, in a way",
    "Lower joint",
    "Child support?",
    "Deep ___ bend",
    "Pants part",
    "\"... with a banjo on my ___\"",
    "Midleg",
    "It's covered by a cap",
    "It may be slapped",
    "Pants part",
    "\"Trick\" joint",
    "Midleg",
    "It may provide child support",
    "\"Trick\" joint, maybe",
    "Word with high or hole",
    "Prayer joint?",
    "Spot for a jerk?",
    "Midleg",
    "Patella's place",
    "Pants part",
    "Joint with a cap",
    "Kilts stopping point",
    "Bendable part",
    "Deep ___ bend",
    "Joint with a cap",
    "Medial meniscus site",
    "Place for a football pad",
    "Where to wear a genouillere",
    "You may be down on one",
    "\"Trick\" joint",
    "Where a cap is found on the body",
    "Baby-bouncing place",
    "Leg's midpoint",
    "Proposer's prop",
    "Pants part",
    "Place for a pad",
    "Spoon-playing site",
    "Capped part",
    "Cap locale",
    "Kind of socks",
    "Where gramps jounces junior",
    "Midleg point",
    "Bouncing baby's seat",
    "Cap site",
    "Deliver a low blow, perhaps",
    "Kind of jerk",
    "Scrape site",
    "Spoon-playing site",
    "Place for padding or paddling",
    "On bended ___",
    "Locale for a spanking",
    "On bended ___",
    "Dandling area",
    "Kind of socks",
    "Word with high or hole",
    "Baby-bouncing locale",
    "Where the patella is",
    "Spot for a scrape",
    "Baby bouncer",
    "Slapping site",
    "Pants part",
    "Kind of jerk",
    "Femur-tibia go-between",
    "Prayer joint",
    "Pants part",
    "Femur-tibia connector",
    "Makeshift seat for a tot",
    "Kind of jerk",
    "Patch place",
    "Spoon-playing locale",
    "Leg's middle",
    "Site of many scrapes",
    "What the genouillere protects",
    "Wearing-out point for pants",
    "Wounded ___, S.D.",
    "Banjo site, in song",
    "Patella's place",
    "Kind of cap or jerk",
    "Bender, of a sort",
    "Banjo site",
    "Child support?",
    "Mini revelation"
  ],
  "syst": [
    "Part of GPS: Abbr.",
    "Routine: Abbr.",
    "Part of iOS: Abbr.",
    "The \"S\" of CBS: Abbr.",
    "Part of CBS: Abbr.",
    "Part of GPS: Abbr.",
    "The \"S\" of GPS: Abbr.",
    "The second \"S\" of MS-DOS: Abbr.",
    "Way: Abbr.",
    "Part of CBS: Abbr.",
    "Part of GPS: Abbr.",
    "The \"S\" of GPS: Abbr.",
    "Alphabetizing, e.g.: Abbr.",
    "Part of CBS: Abbr.",
    "GPS component: Abbr.",
    "Interstate hwy. ___",
    "Method: Abbr.",
    "Part of GPS: Abbr.",
    "The \"S\" of TBS: Abbr.",
    "The \"S\" in GPS: Abbr.",
    "Part of GPS: Abbr.",
    "The \"S\" in CBS: Abbr.",
    "Way: Abbr.",
    "Method: Abbr.",
    "Part of CBS: Abbr.",
    "Part of 20-Across: Abbr.",
    "Meth.",
    "Part of DOS: Abbr.",
    "Part of CBS: Abbr.",
    "Part of CBS: Abbr.",
    "Part of CBS: Abbr.",
    "Meth.",
    "DOS part: Abbr.",
    "Mode: Abbr.",
    "Part of CBS: Abbr.",
    "Part of CBS: Abbr.",
    "Part of DOS: Abbr.",
    "Part of CBS: Abbr.",
    "The \"S\" in CBS: Abbr.",
    "Part of 27-Down: Abbr.",
    "Way: Abbr.",
    "Part of S.S.S.: Abbr.",
    "Part of 12-Down: Abbr.",
    "Network: Abbr.",
    "Dewey Decimal ___: Abbr.",
    "Method: Abbr.",
    "Method: Abbr.",
    "Part of S.S.S.: Abbr.",
    "Subway, e.g.: Abbr.",
    "Kind of analyst: Abbr.",
    "Method: Abbr.",
    "Part of PBS: Abbr."
  ],
  "nano": [
    "One-billionth: Prefix",
    "Prefix with technology",
    "Prefix with technology",
    "It's between micro- and pico-",
    "Prefix with technology",
    "Discontinued iPod",
    "Prefix meaning \"super-tiny\"",
    "Prefix meaning one-billionth",
    "iPod type",
    "Prefix with second",
    "Onetime iPod model",
    "Smaller than micro-",
    "Erstwhile iPod type",
    "Tiny: Prefix",
    "Prefix with technology and second",
    "Prefix with particle",
    "Inverse of giga-",
    "Prefix with second",
    "Small iPod",
    "Touch alternative",
    "iPod Mini successor",
    "Small Apple offering",
    "Billionth: Prefix",
    "Little opening?",
    "iPod model",
    "iPod model",
    "Prefix with bacteria",
    "Prefix meaning \"billionth\"",
    "iPod Mini successor",
    "Head of technology?",
    "Apple product",
    "See 27-Across",
    "iPod model",
    "iPod model",
    "Apple variety",
    "iPod ___",
    "Certain iPod",
    "\"Small\" prefix",
    "Prefix with technology",
    "Product from the maker of the 6-Down",
    "Mini successor",
    "10<sup>-9</sup>: Prefix",
    "Modern running companion?",
    "Electronic product sensation of 2005",
    "Second start?",
    "iPod type",
    "One billionth: Prefix",
    "Second start?",
    "Certain iPod",
    "iPod variety",
    "Billionth: Prefix",
    "Prefix with technology",
    "Prefix with second",
    "Prefix with technology",
    "Prefix with technology",
    "A millionth of a milli-",
    "Second start?",
    "Second introduction?",
    "Second start?",
    "Second introduction?",
    "Prefix with second",
    "Prefix with second",
    "Prefix with second",
    "Second starter",
    "Prefix with second",
    "Prefix with second",
    "Prefix meaning \"one-billionth\"",
    "Billionth: Prefix",
    "Prefix with second",
    "Prefix with second",
    "Billionth: Prefix",
    "Prefix with second",
    "Prefix meaning \"one-billionth\"",
    "Billionth: Prefix",
    "Second start",
    "Second starter",
    "Second leader"
  ],
  "ones": [
    "Scoreboard numbers when a baseball team puts up a \"picket fence\"",
    "Half of the digits in binary code",
    "There are two in \"101 Dalmatians\"",
    "Low notes",
    "George Washington bills",
    "Change for a five",
    "Singles",
    "Typos for exclamation marks if you fail to hit Shift",
    "Word sometimes substituted for \"your\"",
    "Till section",
    "About half of all binary code",
    "Gender-neutral possessive",
    "Small bills",
    "Lowest notes",
    "White bills in Monopoly",
    "Till compartment",
    "Endmost compartment in a till",
    "Much of binary code",
    "Change for a five, say",
    "Loved ___",
    "Pair of 11s?",
    "___ place",
    "White Monopoly bills",
    "Bills with George Washington's face",
    "Bills exchanged for a five",
    "Telephone buttons that lack letters",
    "Word sometimes substituted for \"your\"",
    "Snake eyes",
    "Easiest numbers to dial on a rotary phone",
    "Till section",
    "111 things?",
    "1 1 1",
    "George Washingtons",
    "Tip jar fillers",
    "___ place",
    "Much of binary code",
    "Easy things to dial on a rotary phone",
    "Kind of place for the summer?",
    "Give ___ all",
    "\"Madness in great ___ must not unwatch'd go\": \"Hamlet\"",
    "White notes in Monopoly",
    "Tip jar fillers",
    "Georges",
    "___ place",
    "Roughly half of all binary code",
    "Till compartment",
    "Word often replaced with \"your\"",
    "Gender-neutral possessive",
    "Capital of Washington?",
    "Cash register compartment",
    "White Monopoly bills",
    "Change for a five",
    "Low notes",
    "Many bills in tips",
    "Dollar bills",
    "Washington's capital?",
    "Tip jar deposits",
    "Singles",
    "1 1 1",
    "Singles",
    "Register space",
    "Two out of 11?",
    "___ column",
    "Common notes",
    "Small change",
    "Tender with Washington",
    "Many bills in tip jars",
    "Kind of a place to the left of a decimal",
    "Souls",
    "Kind of place",
    "Much binary code",
    "\"Washingtons\"",
    "Rightmost column",
    "A lot of binary code",
    "Washington bills",
    "\"Your\" alternative",
    "Some poor Olympic scores",
    "Small bills",
    "Individuals",
    "Alternative to \"your\"",
    "Low bills",
    "Unidentified people",
    "Low dice roll",
    "Tips, often",
    "Rightmost column in an addition",
    "Tip jar bills",
    "Kind of column",
    "Awful \"Dancing With the Stars\" scores",
    "Strippers' tips, often",
    "Billfold fillers",
    "Kind of place to the left of the decimal point",
    "Telephone numbers without letters",
    "Till compartment",
    "___ place",
    "Vending machine input",
    "Low ratings",
    "Change for a five",
    "\"Washingtons\"",
    "Leftmost compartment in a till",
    "Till compartment",
    "Change for a 25-Down, maybe",
    "Rarely counterfeited bills",
    "George Washingtons",
    "Change for a five",
    "Small bills",
    "Bills in tills",
    "Change components, often",
    "Snack machine inserts",
    "Vending machine input",
    "Change for a five",
    "___ place",
    "Singles",
    "Georges",
    "Kind of place",
    "Change for a five",
    "Washington capital?",
    "George Washingtons",
    "They're easy to dial on a rotary phone",
    "Till compartment",
    "Low-value wad",
    "Some till fill",
    "Till stack",
    "Wallet fillers",
    "Some bills",
    "Snake eyes",
    "Monopoly stack",
    "Certain column",
    "\"Annuit coeptis\" appears on them",
    "Change for a five",
    "Wallet thickeners",
    "Small bills",
    "Low scores",
    "Change machine input",
    "A lot of binary code",
    "Change for a five",
    "Change for a five",
    "Small change?",
    "Kind of column",
    "Till slot",
    "Addition column",
    "Halves of a 32-Across",
    "They share keys with exclamation points",
    "Singletons",
    "Rightmost column",
    "Till stack",
    "Disastrous marks for a gymnast",
    "About half of binary code",
    "Wallet fillers",
    "___ place",
    "Half of binary code",
    "Bills not stocked in A.T.M.'s",
    "About half of binary coding",
    "Mint stack",
    "Certain column",
    "Wallet padding",
    "Teller's stack",
    "Addition column",
    "*Bills ... column ... binary code",
    "Small bills",
    "Binary digits",
    "People",
    "Snake eyes",
    "See 33-Down",
    "Till fillers",
    "Smackers",
    "Very low ratings",
    "Off-guard connector",
    "Vending machine inserts",
    "Change for a five",
    "Individuals",
    "Change for a five",
    "Till fill",
    "Washington bills",
    "Singles",
    "Till compartment",
    "Much binary code",
    "Tipper's needs?",
    "Change from a cashier",
    "Change machine input",
    "Small bills",
    "Teller's stack",
    "Bank roll",
    "Teller's stack",
    "Wallet fill",
    "End drawer in a till",
    "Singles",
    "Snake eyes",
    "See 8-Down",
    "Kind of column",
    "Handy bills",
    "Half of binary code",
    "Smallest bills",
    "People",
    "Washington bills",
    "Till fill",
    "Individuals",
    "Wallet stuffers",
    "Wallet wad",
    "Individuals",
    "Aces, sometimes",
    "People",
    "Wallet wad",
    "Change for a five",
    "Parts of binary code",
    "Small bills",
    "Components of elevens",
    "Change machine fill",
    "Long-distance starts",
    "Billfold bills",
    "Change for a ten",
    "Small roll",
    "Little bills",
    "George Washington bills",
    "Snake eyes",
    "Addition column",
    "Kind of column",
    "Change, sometimes",
    "Half the binary system",
    "Low notes",
    "They're typed with the left pinkie",
    "Change, often",
    "Kind of column",
    "Billfold stuffers",
    "Wallet items",
    "Washington's bills",
    "Singles",
    "Small bills",
    "Counting method",
    "Till's bills",
    "1956 western ?ôThe Proud ___?ö",
    "Till bills",
    "Snake eyes",
    "Change for a five",
    "Snake eyes",
    "Wallet fillers",
    "Wallet fillers",
    "Change for a five",
    "A good deal of binary code",
    "Change for a five",
    "Till compartment",
    "Till's bills",
    "Certain addition column",
    "Till compartment",
    "Washington bills",
    "Singles",
    "Last column in addition",
    "Singles"
  ],
  "pale": [
    "Word before \"Blue Eyes\" or \"Blue Dot\" in titles",
    "Like beige and lilac",
    "Lightish",
    "The \"P\" of 23-Down",
    "Wan",
    "Ill-looking",
    "Not at all bright",
    "Susceptible to sunburn",
    "Not that bright",
    "Faint",
    "Blanched",
    "___ ale",
    "Enclosing fence",
    "Like Death's horse",
    "Seriously susceptible to sunburn",
    "Visibly terrified",
    "Washed out",
    "Cadaverous",
    "Washed out",
    "Ghostlike",
    "Prone to freckles",
    "Ashen",
    "Like some ales",
    "Like 24-Across",
    "Hardly tanned",
    "New at the beach, maybe",
    "Washed out",
    "Whitish",
    "Anemic-looking",
    "Untanned",
    "Whiten",
    "Hardly tanned",
    "Light",
    "Looking scared",
    "Not too bright",
    "Not brilliant",
    "Feeble",
    "Hardly tanned",
    "Far from ruddy",
    "Blanched",
    "Wan",
    "Not too bright",
    "Achromous",
    "Like some ales",
    "Whitish",
    "Visibly shaken",
    "Needing some sun",
    "Not bright",
    "Lighten up?",
    "Show fear",
    "Like some imitations",
    "Blanch",
    "Whitish",
    "Bleached",
    "Colorless",
    "Color deficient",
    "Pasty",
    "Drained",
    "Not very bright",
    "Untanned",
    "Whitish",
    "Show fright",
    "Wan",
    "Blanch",
    "Seem trivial",
    "Not vivid",
    "Barely tinged"
  ],
  "yves": [
    "Jacques-___ Cousteau",
    "International cosmetics company ___ Rocher",
    "Surrealist Tanguy",
    "First name in fashion",
    "The \"Y\" of Y.S.L.",
    "Fashion's ___ Saint Laurent",
    "First name in fashion",
    "Part of Y.S.L.",
    "Part of Y.S.L.",
    "Cosmetics company founder Rocher",
    "Actor Montand",
    "French first name in fashion",
    "Designer Saint Laurent",
    "Designer Saint Laurent",
    "Part of fashion's YSL",
    "Designer ___ Saint Laurent",
    "Part of Y.S.L.",
    "Surrealist Tanguy",
    "The \"Y\" in Y.S.L.",
    "Saint Laurent of fashion",
    "The \"Y\" in Y.S.L.",
    "Jacques Cousteau's middle name",
    "Jacques Cousteau's middle name",
    "Actor Montand",
    "Fashion's ___ Saint Laurent",
    "Actor Montand",
    "The \"Y\" of Y.S.L.",
    "Rocher of cosmetics",
    "\"Z\" actor Montand",
    "Paris-born painter Tanguy",
    "Part of Y.S.L.",
    "\"Z\" actor Montand",
    "Jacques Cousteau's middle name",
    "Actor Montand",
    "Part of Y.S.L.",
    "Part of Y.S.L.",
    "Part of Y.S.L.",
    "\"Memphis\" director Simoneau",
    "The \"Y\" of Y.S.L.",
    "Actor Montand",
    "The \"Y\" of Y.S.L.",
    "Actor Montand",
    "Painter Tanguy",
    "Director Allegret",
    "Designer St. Laurent"
  ],
  "bred": [
    "Partner of born",
    "Born and ___",
    "Fostered",
    "Born on a stud farm, say",
    "Fostered",
    "Begot",
    "Brought up",
    "Engendered",
    "Begat",
    "Born and ___",
    "Born's partner",
    "Raised",
    "Born's partner",
    "Raised, as cattle",
    "Crossed, say",
    "Raised, as livestock",
    "Mated",
    "Brought up",
    "Raised, in a way",
    "Raised",
    "Raised",
    "Born's partner",
    "Partner of born",
    "Brought up",
    "Brought up",
    "Born's partner",
    "Word with pure or standard",
    "Raised",
    "Partner of born",
    "Raised",
    "Raised",
    "Engendered",
    "Brought up",
    "___-in-the-bone (deeply ingrained)",
    "Raised",
    "Raised",
    "Born's partner",
    "Raised",
    "Raised",
    "Born's partner",
    "Reared"
  ],
  "owen": [
    "Actor Wilson",
    "Actor Wilson of \"Wedding Crashers\"",
    "Actor Wilson",
    "Meany of fiction",
    "Wilson of \"Meet the Parents\"",
    "Good name for a debtor?",
    "Actor Wilson",
    "Oscar-nominated actor Clive",
    "Actor Wilson of \"Midnight in Paris\"",
    "Actor Wilson who has appeared with Ben Stiller in 12 films",
    "John Irving's \"A Prayer for ___ Meany\"",
    "Actor Wilson of \"Midnight in Paris\"",
    "Wilson of \"The Royal Tenenbaums\"",
    "Actor Wilson",
    "John Irving's \"A Prayer for ___ Meany\"",
    "Wister who was known as the father of western fiction",
    "Actor Wilson",
    "Wister or Wilson",
    "Actor Clive of Cinemax's \"The Knick\"",
    "English poet Wilfred ___",
    "Actor Wilson of \"Midnight in Paris\"",
    "Wilson of \"Starsky & Hutch\"",
    "Poet Wilfred ___",
    "Wilson of \"The Life Aquatic With Steve Zissou\"",
    "Wilson of \"The Internship\"",
    "Wilson of Hollywood",
    "Wilson of \"Meet the Parents\"",
    "Writer Wister",
    "Meany of story",
    "Actor Wilson",
    "Danny DeVito's \"Throw Momma From the Train\" role",
    "Wilson of \"Wedding Crashers\"",
    "___ Glendower, last Welshman to hold the title Prince of Wales",
    "Actor Wilson",
    "Wilson of \"Midnight in Paris\"",
    "Wilson of \"Wedding Crashers\"",
    "Actor Wilson",
    "___ D. Young (Time's Man of the Year in 1929)",
    "Wilson of \"Starsky & Hutch,\" 2004",
    "Wilson of \"Shanghai Noon\"",
    "Film critic Gleiberman",
    "Wilson of \"Wedding Crashers\"",
    "Western writer Wister",
    "Funny Wilson",
    "Wilson of \"Zoolander\"",
    "\"The Virginian\" author Wister",
    "Wilson of \"The Darjeeling Limited\"",
    "\"Children of Men\" star Clive",
    "Wilson of \"Wedding Crashers\"",
    "Wilson of Tinseltown",
    "Wilson of \"Zoolander\"",
    "\"A Prayer for ___ Meany\"",
    "W.W. I poet Wilfred",
    "John Irving's \"A Prayer for ___ Meany\"",
    "Glendower who revolted against Henry IV",
    "Actor Wilson of \"Shanghai Noon\"",
    "___ Wilson of \"The Royal Tenenbaums\"",
    "Actor Reginald, who played both Dr. Watson and Sherlock Holmes",
    "Pulitzer-winning playwright ___ Davis",
    "Wister who wrote \"The Virginian\"",
    "Wilson of \"Shanghai Knights\"",
    "Irving's \"A Prayer for ___ Meany\"",
    "\"___ Wingrave\" (Britten opera)",
    "Western writer ___ Wister",
    "Irving's ___ Meany",
    "Writer ___ Wister",
    "\"Dulce et Decorum Est\" poet",
    "Meany of literature",
    "\"A Prayer for ___ Meany\"",
    "Novelist Wister",
    "John Irving's \"A Prayer for ___ Meany\"",
    "Glass-___ Currency Act, 1913",
    "John Irving's \"A Prayer for ___ Meany\"",
    "TV Lawyer Marshall",
    "Social reformer Robert",
    "Poet Wilfred",
    "TV lawyer ___ Marshall"
  ],
  "eins": [
    "One, in Berlin",
    "A quarter of vier",
    "Fifth of funf",
    "Start of a German count",
    "Starting point for a German count",
    "German one",
    "One, in Austria",
    "Beethoven's first?",
    "One in Munich",
    "___, zwei, drei ...",
    "German one",
    "Fifth of funf",
    "One from Germany",
    "German unity",
    "___, zwei, drei ...",
    "One abroad",
    "Funf minus vier",
    "___, zwei, drei",
    "Karl Marx's one",
    "Hamburger's one",
    "One from Germany?",
    "Zwei halved",
    "Beethoven's one",
    "One overseas",
    "Start of a German series",
    "Oberhausen one",
    "German cardinal",
    "Half of zwei",
    "One, in Koln",
    "Minor German count",
    "Oberhausen one",
    "One overseas",
    "One, to Hans",
    "Drei minus zwei",
    "One of a Kind?",
    "One, to Hans"
  ],
  "seam": [
    "Fashion line",
    "Line down the length of a skirt",
    "Coal deposit",
    "Band of ore",
    "Where clothing may come apart",
    "Mine layer",
    "Line of clothing?",
    "Stitch line",
    "Where clothes often rip",
    "Part of a baseball",
    "Mining find",
    "Ore stratum",
    "Baseball feature",
    "Meeting point for tailors?",
    "Stitched line on a baseball",
    "Coal locale",
    "Tailor's sideline?",
    "Line on a baseball",
    "Baseball part",
    "Line of jeans?",
    "Weak part of a hull",
    "Sewing line",
    "Line of stitches",
    "Part of a baseball",
    "Place for a rip",
    "Line in an A-line",
    "Rich layer",
    "Fashionable meeting place?",
    "Line from the ankle to the waist, say",
    "Coal bed",
    "Tailor's line",
    "Use a Singer",
    "Line of stitches",
    "Sewing juncture",
    "Stitching line",
    "Vulnerable gap",
    "Ore deposit",
    "Clothes line",
    "A-line line",
    "Coal site",
    "Clothing line?",
    "Clothing line",
    "Sarong's lack",
    "Line made by a 41-Across",
    "Mining target",
    "It may contain 10-Down",
    "Place vulnerable to ripping",
    "Line by a sewer",
    "It's in stitches",
    "A-line line",
    "Coal locale",
    "Sewer line?",
    "Coal stratum",
    "Tailor's line",
    "Petticoat junction?",
    "Tailor's meeting place",
    "Baseball feature",
    "Vein",
    "Petticoat junction",
    "Coal stratum",
    "It's a stitch!",
    "Clothes line",
    "Suture",
    "Stitch together",
    "Baseball feature",
    "Coal bed",
    "Ore locale",
    "Tuxedo junction",
    "Clothes line",
    "Fashion line",
    "Where ends meet",
    "Petticoat junction",
    "Ore layer",
    "Coal stratum"
  ],
  "meet": [
    "Running event",
    "Rendezvous (with)",
    "Take the next step in an online relationship",
    "Fulfill, as expectations",
    "Cross",
    "Intersect",
    "Take a Tinder match offline, say",
    "Intersect",
    "Cross",
    "Rendezvous",
    "Track or swimming competition",
    "Certain competition",
    "Cross",
    "What parallel lines never do",
    "Satisfy",
    "Bump into",
    "Pool event",
    "Track-and-field team calendar listing",
    "Cross",
    "Chance upon",
    "Gather",
    "Come together",
    "Come together",
    "Converge",
    "Intersect",
    "Swim ___",
    "Rendezvous",
    "Listing on an athletic schedule",
    "Greet's partner",
    "Entry on a sports schedule",
    "Series of races",
    "Satisfy",
    "Comply with",
    "Satisfy",
    "Come together",
    "Rendezvous",
    "Assemble",
    "Cross",
    "Comply with",
    "Part of it might consist of dashes",
    "Cross",
    "Converge",
    "What nonparallel lines do eventually",
    "___ and 15-Across (get-to-know-you gathering)",
    "Get together",
    "Wrestling event",
    "Encounter",
    "Track ___",
    "Big competition",
    "It often has dashes",
    "Encounter",
    "Adjoin",
    "Satisfy",
    "Come together",
    "Sports competition",
    "Have an engagement (with)",
    "Wrestling event",
    "Hook up",
    "Where races are run",
    "See 1-Across",
    "Gather",
    "Convene",
    "Cross",
    "Cross",
    "Bump into",
    "Assemble",
    "A runner might enter it",
    "Track event",
    "An athlete might swim in it",
    "Hook up",
    "Heated competition?",
    "Satisfy",
    "Satisfy",
    "Get together",
    "Track event",
    "Competition with shot putters and hurdlers",
    "Run into",
    "Come across",
    "Face",
    "Convene",
    "Track event",
    "A runner may enter one",
    "Get together",
    "Satisfy",
    "Speak (with)",
    "Heated competition?",
    "Face up to",
    "Come up to",
    "Shake hands for the first time",
    "Satisfy",
    "Encounter",
    "Contest",
    "Run into",
    "Swim contest",
    "One may run in it",
    "Bump into",
    "Make the acquaintance of",
    "Track ___",
    "Sports event",
    "Congregate",
    "Rendezvous",
    "Make the acquaintance of",
    "Face up to",
    "Hunt assemblage",
    "Encounter",
    "Track contest",
    "Appropriate",
    "Set of races",
    "Sporting event",
    "Track competition",
    "Come before, as the eyes",
    "Bump into",
    "Track contest",
    "Encounter",
    "Track competition",
    "Athletic event"
  ],
  "fins": [
    "Flippers",
    "Nickname for the Miami Dolphins, with \"the\"",
    "Features of classic cars",
    "Change for some sawbucks, maybe",
    "Some scuba gear",
    "Bass parts",
    "Features of many late-1950s cars",
    "Dive shop rentals",
    "Half-sawbucks",
    "Snorkeling aids",
    "Fish propellers",
    "Fish propellers",
    "Drum and bass parts",
    "Some cabbage",
    "Scuba gear",
    "Cod pieces?",
    "Fivers",
    "Sawbuck halves",
    "50's car features",
    "Five-spots",
    "Fivers",
    "$5 bills",
    "Old T-bird features",
    "Platy propellers",
    "Swimming gear",
    "Propellers, of a sort",
    "50's car features"
  ],
  "kava": [
    "Polynesian crop with medicinal properties",
    "Intoxicating Polynesian drink",
    "Herbal stress reliever from Polynesia",
    "Polynesian beverage",
    "Polynesian drink",
    "Polynesian potable",
    "Polynesian libation",
    "Intoxicating Polynesian drink",
    "Intoxicating Polynesian drink",
    "Intoxicating Polynesian quaff"
  ],
  "riga": [
    "Where to find lots of Letts",
    "Latvia's capital",
    "Latvia's capital",
    "Capital of Latvia",
    "World capital where Lettish is spoken",
    "Major Baltic port",
    "Latvia's capital",
    "European capital",
    "Northern Europe's Gulf of ___",
    "Baltic capital",
    "Largest city in the Baltic States",
    "Baltic city where Baryshnikov was born",
    "Largest city in the Baltics",
    "European capital",
    "Baltic capital",
    "Europe's Gulf of ___",
    "Baltic capital",
    "Latvia's capital",
    "Latvia's capital",
    "Latvia's capital",
    "Capital city on the Daugava River",
    "Gulf of ___, arm of the Baltic",
    "Capital of Latvia",
    "Latvia's capital",
    "Latvia's capital",
    "Latvian capital",
    "European capital",
    "Latvia's capital",
    "Port near Ogre",
    "European capital",
    "Capital on the Dvina River",
    "Latvia's capital",
    "Baryshnikov's birthplace",
    "Capital on the Daugava River",
    "World capital on a gulf of the same name",
    "Baltic capital",
    "Gulf of ___, arm of the Baltic",
    "Latvia's capital",
    "Baltic port",
    "European capital",
    "Capital occupied by the Germans in 1941",
    "Latvia's capital",
    "Baltic capital",
    "Capital of Latvia",
    "Latvia's capital",
    "Baltic capital",
    "Baltic's Gulf of ___",
    "Latvian capital",
    "European capital",
    "Latvia's capital",
    "Baltic port",
    "Baltic capital",
    "Latvia's capital",
    "Latvia's capital",
    "Latvia's capital",
    "Gulf of ___ (arm of the Baltic)",
    "European capital",
    "Capital of Latvia",
    "Baryshnikov's birthplace",
    "Baltic port",
    "Latvia's capital",
    "Baltic port",
    "Baryshnikov's birthplace",
    { "formula": "+European capital", "result": { "error": "#NAME?" } },
    "Europe's Gulf of___",
    "Europe's Gulf of___",
    "Latvia's capital",
    "Baltic capital",
    "Baltic capital",
    "Latvia's capital",
    "Gulf of ___ (Baltic arm)",
    "Baryshnikov?Æs birthplace",
    "Latvian capital",
    "Latvia's capital"
  ],
  "hero": [
    "Knight in shining armor",
    "\"My ___!\"",
    "Ticker-tape parade honoree",
    "Footlong, maybe",
    "Captain Marvel, e.g.",
    "Role model",
    "Sub",
    "Sandwich that takes two hands to handle",
    "Submarine sandwich",
    "Beau ideal",
    "Odysseus, in the \"Odyssey\"",
    "Medal of Honor recipient",
    "One who might get a parade",
    "Subway fare?",
    "Real lifesaver",
    "Bolivar, in much of South America",
    "\"Sully\" Sullenberger, notably",
    "Grinder",
    "Long lunch?",
    "\"My ___!\"",
    "\"Somebody who voluntarily walks into the unknown,\" per Tom Hanks",
    "War ace, e.g.",
    "Sub",
    "Recipient of a ticker-tape parade, maybe",
    "Villain's vanquisher",
    "\"My ___!\"",
    "Knight in shining armor",
    "Grinder",
    "One who may be a lifesaver",
    "Lionhearted sort",
    "Submarine sandwich",
    "Superman, e.g.",
    "Sub",
    "Lover of Leander, in myth",
    "Foot-long sandwich",
    "\"Don't be a ___!\"",
    "Saving type",
    "Activision's Guitar ___",
    "Leander's love",
    "Foot-long sandwich",
    "Sir Galahad, e.g.",
    "Decorated one",
    "Main part, often",
    "Tarzan or Buck Rogers, e.g.",
    "Sub",
    "One who saves the day",
    "Parade honoree, perhaps",
    "One may win a heart",
    "Subway fare?",
    "Heart recipient, perhaps",
    "Submarine sandwich",
    "Grinder",
    "Key to the city recipient, maybe",
    "Purple Heart recipient",
    "Sizable sandwich",
    "Lifesaver, e.g.",
    "Subway fare?",
    "Sandwich that requires two hands",
    "Lifesaver",
    "\"Three cheers\" recipient",
    "Hercules or Ulysses",
    "Protagonist",
    "Guitar ___ (hit video game series)",
    "Submarine sandwich",
    "Medal winner for bravery",
    "\"My ___!\"",
    "Two-handed sandwich",
    "El Cid, to Spaniards",
    "Batman or Superman",
    "Sub",
    "Lifesaver, maybe",
    "\"My ___!\"",
    "Dragon slayer",
    "Medal recipient",
    "White knight",
    "Deli product",
    "One who saves the day",
    "Savior",
    "Knight in shining armor",
    "Loooong sandwich",
    "Medal of honor recipient",
    "Lead",
    "Subway purchase",
    "Sub",
    "Paladin",
    "One getting a decoration",
    "Long sandwich",
    "Grinder",
    "\"My ___!\"",
    "Person with a medal, maybe",
    "Sizable sandwich",
    "Sub",
    "Deli order",
    "Subway fare?",
    "Submarine",
    "Medal awardee, maybe",
    "Submarine sandwich",
    "Deli sandwich",
    "Life saver",
    "Medal recipient",
    "Deli sandwich",
    "Savior",
    "\"My ___!\"",
    "Lifesaver",
    "Sub",
    "Decorated cop, say",
    "Medal recipient",
    "\"My ___!\"",
    "Firefighter, maybe",
    "Submarine",
    "Leander's love",
    "Man of the hour",
    "Achilles, e.g.",
    "\"My ___!\"",
    "Lifesaver, e.g.",
    "Star",
    "Medal recipient",
    "Long lunch?",
    "Lifesaver, say",
    "\"My ___!\"",
    "Submarine",
    "Parade honoree",
    "Object of decoration",
    "Medal recipient",
    "Submarine sandwich",
    "Deli offering",
    "Leander's lover",
    "Submarine",
    "Parade figure",
    "\"Three cheers\" recipient",
    "Sub",
    "Deli sandwich",
    "Two-handed sandwich",
    "Deli sandwich",
    "Conquering___",
    "1901 Maugham novel, with \"The\"",
    "Much-admired sandwich?",
    "Lifesaver",
    "Lunchtime handful",
    "Sub",
    "Lunch order",
    "Lover who lived by the Hellespont"
  ],
  "dads": [
    "Root beer brand",
    "Some bad joke tellers, stereotypically",
    "Some diaper changers",
    "Nascar ___ (demographic group)",
    "\"My Two ___\" (1980s sitcom)",
    "Root beer brand since 1937",
    "Root beer brand",
    "___ Day (June event, informally)",
    "Soft drink brand",
    "Big name in root beer",
    "Honorees on the third Sunday in June",
    "Pops",
    "Many soccer coaches",
    "Pops",
    "Some school play attendees",
    "Many Little League coaches",
    "Barq's rival",
    "Root beer brand",
    "Pops",
    "Big name in root beer",
    "Many P.T.A. members",
    "Root beer brand",
    "Root beer brand",
    "Pops",
    "Brits' \"governors\"",
    "Some diaper changers",
    "Little League umps, often",
    "June honorees",
    "Some stay at home",
    "Some stay at home",
    "Not mom's",
    "Root beer brand",
    "Hires competition",
    "Root beer brand",
    "A & W alternative",
    "A&W alternative",
    "Many Little League coaches",
    "Some P.T.A. members",
    "Root beer brand",
    "June honorees",
    "80's sitcom \"My Two ___\"",
    "American paters",
    "Fathers",
    "Root beer brand",
    "Family members",
    "Hires rival",
    "June honorees",
    "Papas",
    "June honorees"
  ],
  "mace": [
    "Weapon with a heavy head",
    "Defensive spray",
    "Self-defense spray",
    "Spice related to nutmeg",
    "Old-fashioned fight club?",
    "Spice in pumpkin pie",
    "Hand-held weapon",
    "Item carried in an academic procession",
    "Medieval weapon",
    "Autumn spice",
    "Antiriot spray",
    "Old ball and chain?",
    "Spray for self-defense",
    "Knight club",
    "Defensive spray",
    "Knight club",
    "Medieval weapon",
    "Irritating stuff",
    "Antiriot spray",
    "Attacker repellent",
    "Old club",
    "Dungeons & Dragons weapon",
    "Police supply",
    "Eggnog ingredient",
    "Ceremonial rod",
    "Weapon that comes in easy-to-carry and hard-to-carry varieties",
    "Trademarked spray",
    "Club for knights",
    "Spray used on rioters",
    "Knight's club",
    "Anti-attacker spray",
    "Mug spray?",
    "It might come with the mail",
    "Its punch is spiked",
    "Riot queller",
    "Medieval weapon",
    "Riot-control spray",
    "Big club",
    "Symbol of royal power",
    "Protective spray",
    "Chemical defense",
    "Riot spray",
    "Self-defense aid",
    "Stalker deterrent",
    "Nutmeg relative",
    "Nutmeg spice",
    "Nutmeg-based spice",
    "Ground spice",
    "Medieval or modern weapon",
    "Airborne defense?",
    "Mugger subduer",
    "Mugger repellent",
    "Ceremonial staff",
    "Defensive spray",
    "Clublike weapon",
    "Riot spray",
    "Nutmeg-based spice",
    "Anti-attacker spray",
    "Armor-busting weapon",
    "Defensive spray",
    "Riot queller",
    "Riot spray",
    "Riot queller",
    "Riot queller",
    "Defense mechanism?",
    "Mugger subduer",
    "Nutmeg's sister",
    "Protection in a purse",
    "Self-defense item",
    "Official's staff",
    "Nutmeg spice"
  ],
  "naif": [
    "Credulous sort",
    "Babe in the woods",
    "Green one",
    "Wide-eyed sort",
    "Wide-eyed type",
    "Babe in the woods",
    "Innocent sort",
    "Innocent sort",
    "Babe in the woods",
    "Ingenuous one",
    "One who's easily duped",
    "Innocent",
    "Innocent",
    "Babe in the woods",
    "Babe in the woods",
    "Artless one",
    "Wide-eyed sort",
    "Babe in the woods",
    "Unversed sort",
    "Babe in the woods",
    "Babe in the woods",
    "Innocent",
    "Lacking savoir-faire"
  ],
  "kahn": [
    "\"Blazing Saddles\" actress Madeline",
    "Madeline of \"Blazing Saddles\"",
    "Roger who wrote \"The Boys of Summer\"",
    "\"It Had to Be You\" lyricist Gus",
    "Madeline of \"What's Up, Doc?\"",
    "Architect Louis",
    "Salk Institute architect Louis",
    "Madeline who played Lili Von Shtupp",
    "Lyricist Gus",
    "Actress Madeline of \"Blazing Saddles\"",
    "France's Dominique Strauss-___",
    "Architect Louis",
    "Madeline of \"Blazing Saddles\"",
    "\"Clue\" actress Madeline",
    "\"It Had to Be You\" lyricist",
    "Gus who wrote the words to \"Makin' Whoopee\"",
    "Madeline of \"Young Frankenstein\"",
    "Sportswriter/editor Roger",
    "Gus who wrote \"Dream a Little Dream of Me\"",
    "E. J. ___ Jr., longtime writer for The New Yorker",
    "Longtime sportswriter Roger",
    "Gus ___, \"Ain't We Got Fun\" lyricist",
    "\"Ain't We Got Fun\" lyricist",
    "Actress Madeline",
    "\"Makin' Whoopee\" songwriter",
    "Songwriter Gus",
    "Songwriter Gus",
    "New Yorker writer E.J.___",
    "\"The Boys of Summer\" author Roger",
    "Actress Madeline"
  ],
  "alas": [
    "\"So it goes\"",
    "Exclamation after a sigh",
    "\"So sad\"",
    "\"Sadly ...\"",
    "\"That's the way the cookie crumbles\"",
    "Expression of grief",
    "\"C'est la vie\"",
    "\"That's life, I guess\"",
    "\"Such a pity!\"",
    "\"How sad!\"",
    "\"What a shame!\"",
    "\"It's a shame ...\"",
    "Word before some sad words",
    "\"Such a shame\"",
    "\"What a pity\"",
    "\"Such is life!\"",
    "\"Say it ain't so!\"",
    "\"Oh, were it not so\"",
    "\"We gave it a shot\"",
    "\"Such is my fate!\"",
    "Piteous sigh",
    "[Sigh]",
    "\"I'm afraid ...\"",
    "\"A pity\"",
    "\"I wish it weren't so!\"",
    "\"What a pity ...\"",
    "\"Sad to say ...\"",
    "\"___! 'tis true I have gone here and there\" (start of a Shakespeare sonnet)",
    "Interjection of dejection",
    "\"Ah well, we tried\"",
    "\"So it goes\"",
    "Lead-in to unfortunate news",
    "\"Such a shame!\"",
    "\"'Tis sad\"",
    "\"___, 'tis true ...\" (start of a Shakespearean sonnet)",
    "Lead-in to bad news",
    "\"Regrettably ...\"",
    "\"We need a government, ___, because of the nature of humans\": P. J. O'Rourke",
    "\"Sad to say ...\"",
    "\"Such a pity\"",
    "\"Sad to say ...\"",
    "Interjection derived from the Latin for \"weary\"",
    "Word of resignation",
    "\"Regrettably ...\"",
    "\"Woe is me!\"",
    "\"C'est la vie!\"",
    "\"Sad is my lot!\"",
    "Word of woe",
    "\"Such is life\"",
    "Sighed line",
    "\"Woe is me\"",
    "\"Sob\"",
    "[sigh]",
    "Start of a Hamlet monologue",
    "\"Ah, well\"",
    "\"___ the day!\" (cry repeated in Shakespeare)",
    "\"I wish it weren't so\"",
    "Interjection of dejection",
    "\"If only ...\"",
    "\"Unfortunately ...\"",
    "Bad-news lead-in",
    "\"That's the way the cookie crumbles\"",
    "\"Oh, were it not so!\"",
    "\"Sad to say ...\"",
    "\"Sad to say ...\"",
    "\"Sorry to say ...\"",
    "\"It's sad but true ...\"",
    "\"Ah, so sad\"",
    "\"I wish it weren't so\"",
    "\"It's a pity\"",
    "\"Sad to say ...\"",
    "Wistful remark",
    "Word of woe",
    "[sigh]",
    "Resignation exclamation",
    "\"It wasn't meant to be\"",
    "Melodramatic cry",
    "\"Unfortunately ...\"",
    "Word from Hamlet while holding a skull",
    "Pitiful interjection",
    "Sighed line?",
    "Dramatic cry",
    "\"'Tis a pity\"",
    "\"Ah, 'twas not to be\"",
    "\"___, I am dying beyond my means\": Oscar Wilde",
    "\"Woe is me!\"",
    "Interjection that comes from the Latin for \"weary\"",
    "\"Regrettably ...\"",
    "\"Oh, cruel world ...\"",
    "Sighed word",
    "\"Sadly ...\"",
    "\"___, 'tis true I have gone here and there\": Shak.",
    "\"Regrettably ...\"",
    "\"Woe is me!\"",
    "Opposite of \"Happily ...\"",
    "\"Ah, well ...\"",
    "\"Sadly ...\"",
    "Statement of resignation",
    "Statement of resignation",
    "Preceder of a bit of bad news",
    "\"Regrettably ...\"",
    "Word said with a tear, maybe",
    "\"A pity\"",
    "Cry after failing",
    "\"Lackaday!\"",
    "As bad luck would have it",
    "\"'Tis a pity\"",
    "Melodramatic cry",
    "\"Sad to say ...\"",
    "\"Regrettably ...\"",
    "\"A pity\"",
    "\"Such a pity\"",
    "\"___ the day!\" (Shakespearean exclamation)",
    "\"'Tis a pity\"",
    "\"Oh, woe!\"",
    "\"Oh, woe!\"",
    "Comment about a loss",
    "\"___, how love can trifle with itself!\": Shak.",
    "[sigh]",
    "\"Darn,\" more formally",
    "\"Sad to say ...\"",
    "\"___ the day!\" (Shakespearean interjection)",
    "\"Ah, me!\"",
    "Word of woe",
    "\"That's a shame\"",
    "\"Sad to say ...\"",
    "\"Too bad\"",
    "Self-pitying cry",
    "\"Unfortunately ...\"",
    "\"Woe is me!\"",
    "\"Too bad!\"",
    "\"___, poor Yorick! I knew him, Horatio\"",
    "[sigh]",
    "\"Oh, woe!\"",
    "Expression of unhappiness",
    "\"Too bad\"",
    "[sigh]",
    "Word of woe",
    "\"'Tis a shame\"",
    "\"___ the heavy day!\": \"Othello\"",
    "\"How sad!\"",
    "\"Too bad!\"",
    "\"What a pity!\"",
    "\"Too bad!\"",
    "Resigned remark",
    "[Sigh]",
    "Sighed cry",
    "\"Woe is me!\"",
    "\"Sad to say ...\"",
    "[sigh]",
    "Sad statement",
    "Dejection interjection",
    "Poetic plaint",
    "\"___, how love can trifle with itself!\": Shak.",
    "Regrettably",
    "\"Ohhhh ...\"",
    "\"What a shame!\"",
    "\"Oh, woe!\"",
    "Regrettably",
    "[sigh]",
    "\"'Tis a pity\"",
    "\"C'est la vie\"",
    "\"Unfortunately ...\"",
    "Comment on a loss",
    "\"Too bad!\"",
    "Regrettably",
    "Word of woe",
    "\"Sad to say ...\"",
    "Word for Yorick",
    "Opposite of \"Hurray!\"",
    "Pitying cry",
    "Resigned remark",
    "\"Too bad\"",
    "\"Sorry to say ...\"",
    "Wistful word",
    "\"Ah, me!\"",
    "\"Woe is me!\"",
    "Melodramatic cry",
    "Sorry to say",
    "\"Ah, me!\"",
    "\"Ohhh\"",
    "\"Dear me!\"",
    "\"Oh dear!\"",
    "\"What a pity!\"",
    "Cry after failing",
    "\"What a pity!\"",
    "\"Oh, my!\"",
    "It's said with a sigh",
    "\"Oh, my!\"",
    "\"What a pity!\"",
    "\"If only things had turned out different!\"",
    "\"Ah, me!\"",
    "\"Woe is me!\"",
    "\"Ah, those were the days\"",
    "Word for Yorick",
    "\"Woe is me!\"",
    "\"What a shame\"",
    "Word for Yorick",
    "[Sigh]",
    "\"Ah, me!\"",
    "Relative of 55-Across",
    "[Boo-hoo!]",
    "\"Poor me!\"",
    "\"Poor me!\"",
    "\"'Tis a pity\"",
    "\"Unfortunately...\"",
    "Word of regret",
    "\"How awful!\"",
    "Dramatic wail",
    "\"Oh dear!\"",
    "\"What a shame!\"",
    "Woeful word",
    "\"Wellaway!\" updated",
    "\"Ah, me!\"",
    "\"Oh, woe!\"",
    "Word of regret",
    "\"Oh, were it not true!\"",
    "Word said with a tear",
    "Wistful word",
    "Cry for what might have been",
    "Wistful word",
    "\"Woe is me!\"",
    "Word of resignation",
    "Woeful word",
    "\"Woe is me!\"",
    "Word after a loss",
    "Word repeated in \"Elegy in a Country Churchyard\"",
    "\"Oh, woe!\"",
    "Griever's exclamation",
    "\"Too bad!\"",
    "Start of Hamlet's \"Yorick\" speech",
    "\"___, 'tis true I have gone here and there\": Shak.",
    "\"Woe is me!\"",
    "\"I am miserable\"",
    "Word for poor Yorick",
    "Despondent comment",
    "\"Oh, woe!\"",
    "Cry for \"poor Yorick\"",
    "\"A pity!\"",
    "Woeful word",
    "Alack's partner",
    "\"___ for Man, so stealthily betrayed\": Millay",
    "\"___the day!\" (Shakespearean phrase)",
    "Lament for Yorick",
    "\"Wellaway!\"",
    "\"'Tis a pity!\"",
    "Woeful cry",
    "Word of regret",
    "\"Oh, woe!\"",
    "Gloomy Gus's expression",
    "\"'Tis a pity\"",
    "\"Bummer!\"",
    "Relative of \"oy\"",
    "\"How sad!\"",
    "\"What a shame!\"",
    "Word of lament",
    "?ôPoor me!?ö",
    "\"Woe is me!\"",
    "\"___, poor Milan!\": Prospero",
    "\"Oh, woe!\"",
    "\"___, my Love! ye do me wrong\"",
    "\"Welladay\"",
    "\"Oh, woe!\"",
    "Word of woe",
    "Theatrical lament",
    "Voiced sigh",
    "\"Poor pitiful me!\"",
    "Sadly",
    "\"Woe is me!\"",
    "Melodramatic cry",
    "\"What a pity!\"",
    "Word of woe",
    "\"Too bad!\"",
    "Sighing word"
  ],
  "jamb": [
    "Part of a doorframe",
    "Part of a frame",
    "A window may go in it",
    "Part of a frame job?",
    "Frame component",
    "Window component",
    "Part of a doorframe",
    "Hinge holder",
    "Doorframe's vertical part",
    "Entrance side",
    "Either side of a doorway",
    "Doorframe part",
    "Doorframe part",
    "Frame part",
    "Part of a frame",
    "Frame side",
    "Side of a doorway",
    "Threshold adjoiner",
    "Doorframe part",
    "Frame part",
    "Entrance part",
    "Doorway part",
    "Doorpost",
    "Sidepiece",
    "Frame part",
    "Architectural upright",
    "Entrance feature",
    "Window's support",
    "Door part",
    "Door frame upright",
    "Doorpost",
    "Frame part",
    "Door part",
    "Architectural sidepiece",
    "Doorframe part",
    "Doorway sidepiece",
    "Sidepiece",
    "Frame part",
    "Window support"
  ],
  "shag": [
    "Catch, as fly balls",
    "Carpet type",
    "Kind of rug that's bad to lose an earring in",
    "Thick hairstyle",
    "Farrah Fawcett's signature do",
    "Kind of carpet",
    "Thick rug style",
    "___ rug",
    "Long nap?",
    "Dance akin to the jitterbug",
    "Thick hairstyle",
    "Carpet style",
    "Carpet variety",
    "Something you can do with flies",
    "Kind of rug",
    "Layered hairdo",
    "Carpet choice",
    "Layered hairstyle",
    "Friend of Scoob",
    "Fad dance of the 1930s",
    "Retrieve and throw back, in baseball practice",
    "Cut with more than one layer",
    "Catch some flies",
    "Carpet option",
    "Thick carpet",
    "Retro hairstyle",
    "Catch, as flies",
    "Thick carpet",
    "Catch and throw back, as fly balls",
    "Farrah Fawcett's signature do",
    "Kind of carpet",
    "Layered haircut",
    "Retro cut",
    "Certain carpet or hairdo",
    "Catch some flies",
    "Carpet type",
    "Layered do",
    "Carpet type",
    "Kind of carpet",
    "Fetch",
    "Dance popularized in the 1930's",
    "1970's hairdo",
    "Go after",
    "Uneven do",
    "Chase in the field",
    "Fetch",
    "Pursue",
    "Uneven hairdo",
    "Hairstyle",
    "Kind of carpet",
    "Salon cut",
    "Carpet type",
    "Pipe tobacco",
    "Chase flies",
    "Lively old dance",
    "Catch, as flies",
    "Mass of hair",
    "Uneven hairdo",
    "Kind of carpet",
    "Long nap?",
    "Coarse tobacco",
    "Vigorous dance",
    "Chase flies",
    "Rug variety",
    "Nap",
    "Tangled hair mass",
    "Layered hairdo",
    "Retrieve, as fly balls",
    "Carpet variety",
    "Dance or hairstyle",
    "Chase flies",
    "Lively dance"
  ],
  "aloe": [
    "Ingredient in some topical gels",
    "Ingredient in many balms",
    "Ingredient in healing gel",
    "Gel ingredient",
    "Balm ingredient",
    "___ vera",
    "Ingredient in the skin-care aisle",
    "Staple of skin care",
    "___ vera",
    "Healing succulent",
    "___ vera",
    "Cooling succulent",
    "Ingredient in some gel face masks",
    "Soothing succulent",
    "___ vera",
    "American ___ (century plant)",
    "Common cosmetics component",
    "Burn soother",
    "Sunburn soother",
    "___ vera",
    "Source of a trendy health juice",
    "After-shave additive",
    "Sunburn aid",
    "___ vera (cream ingredient)",
    "Burn soother",
    "Relief from the desert?",
    "___ vera",
    "Natural sunburn soother",
    "Soothing ointment",
    "Plant cultivated for its juice",
    "Plant that yields a salve",
    "___ vera gel",
    "Plant that yields a potent laxative",
    "Soothing ingredient",
    "American ___ (another name for the century plant)",
    "___ water (trendy drink)",
    "Spiky plant with soothing juice",
    "Spiky yet soothing plant",
    "Lotion additive",
    "___ vera",
    "Sunburn aid",
    "Soothing ointment ingredient",
    "Cream additive",
    "Soothing lotion ingredient",
    "___ vera",
    "Natural salve",
    "It's a relief",
    "Soothing plant extract",
    "Moisturizer ingredient",
    "Soothing succulent",
    "Sunburn soother",
    "Skin cream ingredient",
    "Gel-producing succulent",
    "Skin softener",
    "Plant in the lily family",
    "Natural pain reliever",
    "Soothing substance",
    "Anti-inflammatory agent",
    "___ vera",
    "Edible succulent",
    "Poison ivy soother",
    "Salve ingredient",
    "Source of trendy health juices",
    "Natural healer",
    "Skin soother",
    "___ vera",
    "Medicinal plant",
    "Hand lotion ingredient",
    "Fleshy-leaved succulent",
    "Skin cream component",
    "Common ingredient in lotions",
    "Emollient source",
    "___ vera",
    "Lotion ingredient",
    "What may come as a relief?",
    "Healing helper",
    "It might save your skin",
    "Relief provider for a burn",
    "Succulent healer",
    "Salve ingredient",
    "First-aid gel",
    "___ vera",
    "Lotion ingredient",
    "Lotion additive",
    "Soothing stuff",
    "Ingredient in some shaving creams",
    "Shaving lotion additive",
    "Wound application",
    "Remedy for a 59-Down",
    "___ gel",
    "Staple of skin care",
    "Palliative plant",
    "Natural pain reliever",
    "Lotion additive",
    "Exotic juice ingredient",
    "Burn alleviator",
    "___ vera (skin soother)",
    "Succulent plant",
    "Common sunscreen additive",
    "Palliation application",
    "Sunburn relief",
    "Cousin of an agave",
    "The so-called \"potted physician\"",
    "Skin soother",
    "Burn application",
    "___ vera",
    "Rash application",
    "African healer",
    "Conditioner additive",
    "Moisturizer additive",
    "Bath gel ingredient",
    "Balm ingredient",
    "An emollient",
    "___ vera",
    "Soothing plant",
    "Lotion additive",
    "Skin soother",
    "Skin-care product ingredient",
    "Ingredient in some suntan lotions",
    "Perennial succulent",
    "Soap additive",
    "___ vera",
    "Botanical balm",
    "Shampoo ingredient",
    "Ingredient in traditional medicine",
    "Soothing juice",
    "Veld flower",
    "Skin soother",
    "Skin soother",
    "Palliative plant",
    "Botanical balm",
    "Lotion ingredient",
    "Skin cream ingredient",
    "Hyacinth relative",
    "Burn soother",
    "Lotion ingredient",
    "Natural soother",
    "Succulent plant",
    "Palliative plant",
    "Medicinal plant",
    "1-Across ingredient",
    "Lotion ingredient",
    "Sunburn soother",
    "Hothouse plant",
    "Lily of Africa",
    "Succulent houseplant",
    "Burn soother",
    "Rash soother",
    "Burn soother",
    "Hydrocortisone additive",
    "___ vera",
    "Botanical balm",
    "Sunscreen additive",
    "Skin cream additive",
    "Soothing succulent",
    "Salve ingredient",
    "Botanical balm",
    "Medicinal succulent",
    "Tropical flower",
    "Eczema treater",
    "Natural treatment",
    "Burn balm",
    "Healing balm",
    "First-aid salve",
    "Burn treatment",
    "Soothing plant",
    "Cleopatra used it as a beauty lotion",
    "___ vera",
    "Natural balm",
    "Balm ingredient",
    "Skin soother",
    "___ vera",
    "American ___ (Southwest plant)",
    "Natural emollient",
    "Popular plant gel",
    "Soother",
    "Succulent plant",
    "Natural healer",
    "Natural soother",
    "___ vera",
    "Inflammation reducer",
    "Fragrant heartwood",
    "Skin cream ingredient",
    "Therapeutic plant",
    "Potted ornamental",
    "Natural soother",
    "Natural balm",
    "Body lotion ingredient",
    "___ vera",
    "Soothing plant",
    "Healing balm",
    "___ vera",
    "Sunscreen ingredient",
    "Skin cream ingredient",
    "___ vera",
    "Cosmetics additive",
    "Soothing stuff",
    "Spiky plant",
    "Shaving cream additive",
    "Shaving gel ingredient",
    "Burn soother",
    "Tissue additive",
    "Succulent plant",
    "Soothing agent",
    "Soothing gel ingredient",
    "Skin cream additive",
    "Natural balm",
    "Lip balm ingredient",
    "Lily family member",
    "Natural soother",
    "Moisturizer ingredient",
    "Soothing stuff",
    "Plant with lance-shaped leaves",
    "Natural burn medication",
    "Burn soother",
    "Frostbite treater",
    "Fleshy-leafed plant",
    "Tissue additive",
    "___ vera",
    "___ vera",
    "Skin care item",
    "Natural emollient",
    "Skin soother",
    "Hand lotion ingredient",
    "Relative of the yucca",
    "Desert plant",
    "Fiber-yielding shrub",
    "Cream ingredient",
    "Lotion ingredient",
    "Lotion additive",
    "Anti-inflammatory agent",
    "African lily",
    "___ vera",
    "Balm ingredient",
    "Cream ingredient",
    "Plant with spiny-edged leaves",
    "Lotion additive",
    "Ointment ingredient",
    "Burn remedy",
    "Skin soother",
    "Skin cream ingredient",
    "African flower",
    "Hand cream ingredient",
    "Softener",
    "Bandage additive",
    "Medicinal plant",
    "Hand cream ingredient",
    "Lotion ingredient",
    "Skin soother",
    "Natural emollient",
    "Succulent plant",
    "Soothing stuff",
    "Lotion ingredient",
    "Cosmetic additive",
    "Lotion ingredient",
    "Lotion ingredient",
    "Insect repellent ingredient",
    "Balm ingredient",
    "Cream additive",
    "Shaving cream ingredient",
    "Soothing plant extract",
    "With 29-Across, a soother",
    "Yucca plant cousin",
    "Lotion ingredient",
    "Burn healer",
    "Hand lotion ingredient",
    "Burn soother",
    "Lotion ingredient",
    "Substance in skin care",
    "Soothing succulent",
    "Burn treatment",
    "Lotion ingredient",
    "Soothing plant",
    "Burn soother",
    "Shaving gel additive",
    "Medicinal juice",
    "___ vera",
    "Skin lotion ingredient",
    "Natural balm",
    "Skin lotion ingredient",
    "Lotion ingredient",
    "Lip balm ingredient",
    "Dried bitter juice",
    "Lotion ingredient",
    "Lily palliative",
    "Skin soother",
    "___ vera",
    "Facial tissue additive",
    "Cream ingredient",
    "Mother Nature's burn balm",
    "___ vera",
    "Burn relief",
    "Skin cream ingredient",
    "___ vera",
    "Burn balm",
    "Balm ingredient",
    "Therapeutic plant",
    "Emollient",
    "Ingredient in facial tissues",
    "Burn soother",
    "Skin aid",
    "Lotion ingredient",
    "Skin softener",
    "Burn balm",
    "Balm ingredient",
    "Lotion ingredient",
    "Soothing succulent",
    "Cream ingredient",
    "Fiber-yielding plant",
    "___ vera",
    "Skin cream ingredient",
    "Succulent, spiny-leafed plant",
    "Lotion ingredient",
    "Therapeutic plant",
    "Shower gel ingredient",
    "___ vera",
    "Soothing agent",
    "Skin softener",
    "Hand cream ingredient",
    "Balm ingredient",
    "Medicinal plant",
    "Sunburn soother",
    "Lotion base",
    "Burn balm",
    "Burn soother",
    "Skin soother",
    "___ vera",
    "It's gentle on the skin",
    "Cream ingredient",
    "Tissue softener",
    "Burn balm",
    "Lotion ingredient",
    "Succulent plant",
    "Medicinal herb",
    "___ vera",
    "Skin cream ingredient",
    "Emollient source",
    "Lily relative",
    "Tissue additive",
    "Herbal do-all",
    "Balm ingredient",
    "Shampoo ingredient",
    "___ vera",
    "Facial tissues additive",
    "Burn soother",
    "Salve ingredient",
    "___ vera",
    "___ vera",
    "Hand lotion ingredient",
    "Cream ingredient",
    "Cosmetic additive",
    "Burn soother",
    "Tonic-yielding plant",
    "Natural balm",
    "Golden ___ (century plant)",
    "Skin cream ingredient",
    "Lip balm ingredient",
    "Burn treatment",
    "Skin cream ingredient",
    "Healing plant",
    "Lotion additive",
    "Cream ingredient",
    "Lotion ingredient",
    "Balm ingredient",
    "Kind of root",
    "Medicinal plant",
    "Lotion ingredient",
    "Skin soother",
    "Face-saver?",
    "Skin softener",
    "Drug-yielding plant",
    "Skin softener",
    "Sunburn soother",
    "Succulent plant",
    "Skin cream ingredient",
    "Natural balm",
    "Burn soother",
    "Skin soother",
    "___ vera",
    "Pain reliever",
    "___ vera",
    "Facial tissue additive",
    "Shampoo ingredient",
    "___ vera",
    "Hand lotion ingredient",
    "Burn soother",
    "Barbados export",
    "Medicinal plant",
    "Skin softener",
    "Skin smoother",
    "Healing plant",
    "___ vera",
    "Shampoo ingredient",
    "Skin soother",
    "Shampoo ingredient",
    "Skin cream ingredient",
    "Medicinal plant",
    "Lily plant",
    "Sunburn soother",
    "Lotion ingredient",
    "___ vera",
    "Shampoo ingredient",
    "Burn soother",
    "Shaving cream additive",
    "Salve base",
    "___ hemp (fiber plant)",
    "Hand cream additive",
    "Medicinal herb",
    "Tissue addition",
    "Sunburn remedy",
    "Medicinal plant",
    "Lily",
    "Hand lotion ingredient",
    "___ vera",
    "A lily",
    "Salve ingredient",
    "Hand-cream ingredient",
    "African lily",
    "Lotion ingredient",
    "Hand-cream additive",
    "Lotion ingredient"
  ],
  "best": [
    "Top",
    "Tops",
    "Winning a blue ribbon",
    "Top-of-the-line",
    "___ in show (canine award)",
    "Superlative",
    "Alternative to \"Yours truly\"",
    "___ Picture (Oscars category)",
    "Part of BFF",
    "Defeat",
    "Common email sign-off word",
    "Worst in a competition",
    "One-up",
    "Sunday ___",
    "Top",
    "Award category word",
    "Overcome",
    "Top-shelf",
    "Top",
    "Casual sign-off in a letter",
    "Finish ahead of",
    "Outduel, e.g.",
    "A+++",
    "Cream of the crop",
    "First word in every Academy Award category",
    "Start of the title for every Oscar",
    "With \"in\" and 25-Down, blue ribbon earner",
    "Outdo",
    "Second to none",
    "A-number-one",
    "With 10-Down, favored the most",
    "Optimal",
    "Creme de la creme",
    "Letter sign-off",
    "Oscar superlative",
    "___ in Show (Westminster prize)",
    "Tops",
    "Primo",
    "Oscar title starter",
    "Trump",
    "Eclipse",
    "Informal letter closing",
    "Tops",
    "Top spot",
    "Overcome",
    "Rating a blue ribbon",
    "Taking the blue ribbon",
    "First-place",
    "With 47-Across, Academy Award category",
    "Oscar word",
    "Sunday ___",
    "Top of the line",
    "Superlative",
    "Tops",
    "Vanquish",
    "Numero uno",
    "Whip",
    "Brief letter sign-off",
    "Informal letter signoff",
    "Bet or buy lead-in",
    "Outdo",
    "Top",
    "Creme de la creme",
    "Numero uno",
    "Top-quality",
    "Vanquish",
    "Whip",
    "Top of the heap",
    "A+",
    "Cream of the crop",
    "Defeat",
    "Tiptop",
    "Unsurpassed",
    "Letter closing",
    "Insurance writer A.M.___"
  ],
  "opal": [
    "October birthstone",
    "Tometi who co-founded Black Lives Matter",
    "___ glass (translucent ornamental material)",
    "Gem that's a woman's name",
    "Birthstone for Hillary Clinton, Kamala Harris and Alexandria Ocasio-Cortez",
    "October birthstone",
    "White gemstone",
    "Gem that's also a name",
    "Birthstone for many Libras",
    "The Olympic Australis is the world's largest discovered one",
    "___ Tometi, activist who co-founded Black Lives Matter",
    "Gem found in the Outback",
    "Gem with a play of colors",
    "Down Under gemstone",
    "Gemstone discovered on Mars in 2008",
    "Milky white mineraloid",
    "Iridescent birthstone",
    "Birthstone that shares a first letter with its month",
    "A symbol of Australia",
    "Birthstone of some Scorpios",
    "October's birthstone",
    "Good name for a gemologist",
    "October birthstone",
    "Milky birthstone",
    "Birthstone after sapphire",
    "Milky white gem",
    "Australia's national gem",
    "Australia's national gemstone",
    "Memorable 1995 hurricane",
    "Precious stone that has been found on Mars (fun fact!)",
    "Olympic Australis, for one",
    "October birthstone",
    "October birthstone",
    "Iridescent stone",
    "Fancy stone",
    "Birthstone after sapphire",
    "Girl's name that's also a 59-Down",
    "October birthstone",
    "Iridescent stone",
    "Milky gemstone",
    "Fall birthstone",
    "Australian gem export",
    "The Olympic Australis is the largest one in the world",
    "October birthstone",
    "Colorful gem",
    "Stone that shimmers",
    "Iridescent stone",
    "Aunt in the \"Judy Moody\" book series",
    "October birthstone",
    "Milky gem",
    "Olympic Australis is the world's largest one",
    "Gem of a girl?",
    "National gem of Australia",
    "Kind of blue",
    "Off-white shade",
    "Harlequin ___ (multicolored gem)",
    "Translucent gem",
    "It's around 6 on the Mohs scale",
    "___-blue",
    "Libran stone",
    "Gem of a girl?",
    "Off-white shade",
    "October gem",
    "Gem of a girl?",
    "It's worn by many Libras",
    "Gemstone for most Libras",
    "___ glass",
    "Translucent gem",
    "It's often set in a ring",
    "Australian export",
    "Gem for some Libras",
    "Stone for a Libra, traditionally",
    "Milky gem",
    "The Olympic Australis, e.g.",
    "Precious girl's name?",
    "October birthstone",
    "Pearly gem",
    "Memorable 1995 hurricane with a gem of a name?",
    "Pick for a pendant",
    "Flame Queen ___ (famous gemstone)",
    "Producer of a colorful ring tone",
    "Birthstone for most Libras",
    "Queensland gem",
    "Milky gem",
    "Fall birthstone",
    "Milky white gem",
    "Gem mined in Australia",
    "Pendant option",
    "Stone for many Libras",
    "Stone for many Libras",
    "Form of silica",
    "It has a play of colors",
    "Australian gem",
    "Fire ___",
    "Milky white gem",
    "Stone for many Libras",
    "Good name for a gemologist?",
    "Down Under gemstone",
    "Birthstone for some Libras",
    "Iridescent gem",
    "Autumn birthstone",
    "Word with black or fire",
    "October gem",
    "Off-white shade",
    "Nevada gemstone",
    "Birthstone of someone born on Halloween",
    "It's softer than quartz",
    "Translucent gem",
    "Australian mine find",
    "It's worn by some Libras",
    "Word with black or fire",
    "October birthstone",
    "Destructive 1995 hurricane",
    "Fire ___",
    "Australian export",
    "Stone of many Libras",
    "Milky white gem",
    "Iridescent stone",
    "Iridescent gem",
    "___ glass",
    "Gem for some Libras",
    "Fire ___ (gem)",
    "October birthstone",
    "Iridescent stone",
    "Milky gem",
    "Colorful gem",
    "Stone made of silicon and oxygen",
    "Shimmering stone",
    "Iridescent gem",
    "Fire ___",
    "Milky gem",
    "Birthstone for many Libras",
    "Silica gem",
    "Ring gem",
    "October's stone",
    "Classic symbol of bad luck",
    "Iridescent gem",
    "Fire ___ (gem)",
    "Shade of white",
    "Gem with a play of colors",
    "Iridescent mineral",
    "It's less dense than quartz",
    "Bringer of bad luck, in legend",
    "A gem of a lady?",
    "Shade of blue",
    "Milky gem",
    "Girasol, e.g.",
    "Birthstone after sapphire",
    "October's birthstone",
    "Birthstone after sapphire",
    "Fire ___",
    "Virgin Valley valuable",
    "Symbol of hope",
    "Fall birthstone",
    "October stone",
    "Word with black or fire",
    "Common name for hydrous silica",
    "It's 6.5 on the Mohs scale",
    "Iridescent gem",
    "Girasol, e.g.",
    "October birthstone",
    "Solitaire item",
    "Milky gem",
    "Stone of many Libras",
    "Stone of some Libras",
    "Iridescent stone",
    "It's found in a ring",
    "Notorious '95 hurricane",
    "Fiery gem",
    "Autumnal stone",
    "October's birthstone",
    "Hydrophane or isopyre",
    "Shade of blue",
    "Memorable 1995 hurricane",
    "Milky-white gem",
    "Hyalite, e.g.",
    "Fire ___",
    "Translucent gem",
    "Shade of blue",
    "Stone with color flashes",
    "Fire ___",
    "Milky-white gem",
    "Memorable 1995 hurricane",
    "October gem",
    "Kind of glass",
    "Shade of blue",
    "Fire ___",
    "It's mined in South Australia",
    "Fiery gem",
    "Girasol",
    "Libran's stone, maybe",
    "Fire___",
    "Form of silica",
    "Destructive 1995 hurricane",
    "Milky gem",
    "Milky white gem",
    "Milky gem",
    "Word after fire or harlequin",
    "Form of silica",
    "Fiery gemstone",
    "Fiery gemstone",
    "Shade of blue",
    "Gem",
    "Solitaire item",
    "Good luck charm of the Middle Ages",
    "Shade of blue",
    "Shade of gray",
    "Harlequin ___ (varicolored stone)",
    "October birthstone",
    "October gem",
    "Type of glass",
    "Milky gem",
    "Whitish gem"
  ],
  "lamp": [
    "Genie's locale",
    "One might stand on a table",
    "Spelunker's aid",
    "The letter \"I\" in Pixar's logo",
    "Tiffany treasure",
    "Common bedside item",
    "Spelunker's helmet attachment",
    "Home furnishing product with a shade",
    "Genie holder",
    "Part of the Pixar logo",
    "Genie's home",
    "Reading light",
    "Miner's aid",
    "Word after flood or floor",
    "Tiffany collectible",
    "Easy chair accompanier",
    "Genie's home",
    "Lighting director?",
    "Magic item of folklore",
    "Tiffany creation",
    "*Tiffany treasure",
    "Miner's aid",
    "Djinn's home in a popular tale",
    "Desktop accessory",
    "Desktop accessory",
    "Genie's home",
    "Light",
    "Cave explorer's need",
    "Place for a genie",
    "Item beside an easy chair",
    "Light source",
    "Street fixture",
    "Den light",
    "Camper's purchase",
    "Aladdin's treasure",
    "Reading light",
    "Desktop buy",
    "Beacon",
    "Reader's aid"
  ],
  "chez": [
    "French word in some bistro names",
    "Bistro sign word",
    "At the home of: Fr.",
    "At the home of: Fr.",
    "Bistro sign word",
    "Fancy restaurant name starter",
    "Word in some French restaurant names",
    "At the home of, abroad",
    "At the home of",
    "Bistro name starter",
    "Part of many a bistro's name",
    "At the home of",
    "At the home of, to Henri",
    "At the home of: Fr.",
    "Word in a French party invitation",
    "Bistro name starter",
    "___ soi (at home): Fr.",
    "French restaurant name starter",
    "___ Panisse (restaurant)",
    "Word in French restaurant names",
    "At the home of",
    "Word in French restaurant names",
    "At the home of",
    "Word in some French restaurant names",
    "Start of some restaurant names",
    "At the home of"
  ],
  "eris": [
    "Greek goddess of discord",
    "Harmonia's opposite in Greek myth",
    "Dwarf planet with more mass than Pluto",
    "She threw the apple of discord, in Greek myth",
    "Golden apple goddess",
    "Dwarf planet beyond Pluto",
    "Apple of Discord thrower",
    "Astronomical discovery initially called Xena",
    "Dwarf planet more massive than Pluto",
    "\"Principia Discordia\" figure",
    "Dwarf planet more massive than Pluto",
    "Dwarf planet discovered in 2005",
    "Second-largest dwarf planet",
    "The sun's \"10th planet,\" once",
    "Dwarf planet discovered in 2005",
    "Olympian troublemaker",
    "Source of conflict, in antiquity",
    "Dwarf planet beyond Pluto",
    "Plutoid just beyond the Kuiper Belt",
    "Dwarf planet beyond Pluto",
    "Ninth-largest body known to orbit the sun",
    "Largest dwarf planet in the solar system",
    "Greek goddess of strife",
    "Goddess of discord",
    "She threw the apple of discord",
    "Largest dwarf planet in the solar system",
    "Goddess of discord",
    "Solar system discovery of 2003",
    "Largest known dwarf planet",
    "Dwarf planet larger than Pluto",
    "Greek discord goddess",
    "Olympic troublemaker",
    "Harmonia's antithesis",
    "Dwarf planet just beyond the Kuiper Belt",
    "Discord deity",
    "Twin sister of Ares",
    "Twin sister of Ares",
    "Thrower of the apple of discord",
    "Sister of 34-Down",
    "Tosser of the Apple of Discord",
    "Sister of Ares",
    "Discordia's Greek counterpart",
    "Discordia's Greek counterpart",
    "Goddess of discord",
    "Goddess who sparked the Trojan War",
    "Mother of battles, in myth",
    "Sister of Ares",
    "Sister of Ares",
    "Apple thrower of myth",
    "Pantheon troublemaker",
    "Discordia?Æs Greek counterpart",
    "Discordant deity",
    "Goddess of discord",
    "Goddess of discord",
    "Goddess of discord",
    "Discordia's counterpart",
    "Goddess of discord"
  ],
  "keno": [
    "Game often played with a random number generator",
    "Numbers game",
    "It's a numbers game",
    "Bingo-like casino game",
    "Gambling game akin to bingo",
    "Casino game",
    "Bingo relative",
    "Vegas numbers game",
    "Number-picking game",
    "Casino draw",
    "Casino game that looks like a thou in reverse",
    "Casino game",
    "Casino offering, derived from the Latin for \"five each\"",
    "Many a state lottery game",
    "Gambling game whose name spells a gambling town when the first letter is changed",
    "Lotto variant",
    "Casino staple",
    "Casino game with numbered balls",
    "Game with Ping-Pong-like balls",
    "Vegas game",
    "Casino activity",
    "Numbers game",
    "Popular bar game",
    "Casino game with numbers",
    "Numbers game",
    "Vegas attraction",
    "Numbers game",
    "Game akin to bingo",
    "Casino game with a caller",
    "Numbers game",
    "Numbers game",
    "Game played at the Mirage",
    "Casino game with Ping-Pong-like balls",
    "Game played with a sack called a goose",
    "Lotto variation",
    "Game with many balls",
    "Numbers game",
    "Game with many balls",
    "Numbers game",
    "Drawing game",
    "Lotto relative",
    "Casino game with tickets",
    "Numbers game with a card",
    "Gambling game with numbers",
    "Gambling game",
    "Numbers game",
    "Numbers game",
    "Bingo relative",
    "Numbers game",
    "Match game?",
    "Gambler's game",
    "Game of chance",
    "Numbers game",
    "Gambling game",
    "Lotto relative",
    "Casino game",
    "Gambling game",
    "Vegas numbers game",
    "Gambling game",
    "Numbers game",
    "Vegas offering",
    "Gambling game",
    "Bingo relative",
    "Atlantic City game",
    "Bingo-like game",
    "Lotto variant",
    "Gambling game",
    "Atlantic City attraction",
    "Numbers game",
    "Game of chance",
    "Game from the French for \"five winning numbers\"",
    "Bingo-like game",
    "Lotto-like game",
    "Atlantic City attraction",
    "Vegas game",
    "Five-in-a-row game",
    "Lottery game",
    "Game of numbers",
    "Lotto variant",
    "Lotto variant",
    "Casino game",
    "Vegas attraction",
    "Relative of lotto",
    "Gambler's game"
  ],
  "odor": [
    "Unwanted discovery in a refrigerator",
    "Musk, e.g.",
    "Something carbon monoxide lacks",
    "Axe target",
    "Funk",
    "Litter box emanation",
    "Cause of some nose-wrinkling",
    "Stink",
    "Skunk funk",
    "What the nose knows",
    "Wood stain has a strong one",
    "Lingering quality",
    "Something you can pick up with your nose",
    "Cause for nose-pinching",
    "Litter box emanation",
    "Stench",
    "Noted characteristic of a corpse flower",
    "Sign of rot",
    "An old gym sock may have one",
    "Indication that it's time to take out the trash",
    "It's down in the dumps",
    "Target of an air freshener",
    "Rotting evidence",
    "Cause of nose-wrinkling",
    "Ammonia has a strong one",
    "Renuzit target",
    "Febreze target",
    "Target of a spray",
    "Scent",
    "Febreze target",
    "Smell",
    "That stinks!",
    "Fragrance",
    "Stink",
    "Dumpster output",
    "Bad thing to find in your refrigerator",
    "Fragrance",
    "Something commonly found in a laundry bag",
    "Lilac or lavender",
    "Scent",
    "Nose wrinkler",
    "Dumpster emanation",
    "Repute",
    "Locker room emanation",
    "Gym locker emanation",
    "Distinctive smell",
    "Smell",
    "Stench",
    "Musk, e.g.",
    "It may be whiffed",
    "Sign of decay",
    "Concern regarding a litter box",
    "\"Eww!\" inducer",
    "What might make a nose wrinkle",
    "Scent",
    "Something whiffed",
    "Landfill emanation",
    "Repute",
    "Dumpster attribute, often",
    "Perfume",
    "Scent",
    "The \"O\" of B.O.",
    "Z's : sleep :: wavy lines : ___",
    "Reason to hold your nose",
    "Skunk's defense",
    "Aroma",
    "Reputation",
    "What cologne may cover",
    "Stink",
    "Febreze target",
    "The \"O\" of B.O.",
    "Something found in the trash?",
    "Sign of rot",
    "Sign of spoilage",
    "Disinfectant target",
    "Skunk's defense",
    "Oxygen's lack",
    "It stinks",
    "Fumes may produce one",
    "Body ___",
    "Aroma",
    "Cleaner's target",
    "With 51-Down, unscented",
    "Fragrance",
    "Repute",
    "Reminder that the laundry needs doing",
    "Axe target?",
    "Fish market emanation",
    "It's in the air",
    "It might come out in the wash",
    "Nose wrinkler",
    "Radon's lack",
    "Foot problem",
    "What the nose knows",
    "Bloodhound's lead",
    "Target of some sprays",
    "Effluvium",
    "Kitchen drawer?",
    "Skunk's defense",
    "Aerosol target",
    "Fragrance",
    "Something lost in the wash?",
    "Lysol target",
    "It comes out in the wash",
    "Stench",
    "Sign of decay",
    "Something you might turn up your nose at",
    "Dumpster emanation",
    "Tracker's aid",
    "\"P.U.!\" inducer",
    "It's often carried around a gym",
    "Skunk's defense",
    "Foot problem, perhaps",
    "Febreeze target",
    "Podiatric problem",
    "___-Eaters (shoe inserts)",
    "Ammonia has a strong one",
    "Scent",
    "Tracking dog's clue",
    "Effluvium",
    "Spray target",
    "Something in the air",
    "Sure target",
    "Bouquet",
    "Repute",
    "Stench",
    "Stench",
    "What wavy lines signify in the comics",
    "Cause for opening a window",
    "Podiatric problem, for some",
    "Dumpster emanation",
    "Characteristic quality",
    "Gas leak giveaway",
    "Gas leak evidence",
    "Smell",
    "___-Eaters",
    "Nose-wrinkler",
    "Something in the air",
    "Must, say",
    "Aroma",
    "Something picked up in a gym",
    "It's in the dumps",
    "Effluvium",
    "Something carbon monoxide lacks",
    "Smell",
    "Trail",
    "Emanation",
    "Smell",
    "Neon's lack",
    "Outhouse issue",
    "Sensory input",
    "Spray target, perhaps",
    "Pervasive quality",
    "A mothball may have one",
    "It comes out in the wash",
    "Spray's target, maybe",
    "Reason to put a clothespin on your nose",
    "Stench",
    "Stink",
    "Reason for nose-pinching",
    "Spray target",
    "Stench",
    "Scent",
    "Reason to say \"pee-yew!\"",
    "Something you might turn up your nose at",
    "It may elicit a funny face",
    "Scent",
    "A hound may pick it up",
    "Dump problem",
    "It's often masked",
    "Musk or must",
    "Foot problem",
    "Scent",
    "Reputation",
    "Skunk's defense",
    "Target of some sprays",
    "Telltale ___",
    "Freshness indicator",
    "Reason to bathe",
    "Olfactory stimulus",
    "Must, e.g.",
    "It may be picked up in a locker",
    "One may be disagreeable",
    "\"Phew!\" inducer",
    "Renuzit target",
    "Scent",
    "It may be faint",
    "Spray target",
    "A strong one may be found in a gym",
    "Scent",
    "Stink",
    "Household spray target",
    "Lingering effect",
    "Halitosis, e.g.",
    "Smell",
    "Landfill's emanation",
    "Methane's lack",
    "What wavy lines mean, in comics",
    "Bloodhound's clue",
    "Smell",
    "\"P.U.!\" cause",
    "Kitchen drawer?",
    "Aroma",
    "Locker room emanation",
    "What the nose knows",
    "Emanation",
    "Quality",
    "Skunk's defense",
    "Dump emanation",
    "Bouquet",
    "Nose tickler",
    "Bad whiff",
    "Nitrogen's lack",
    "Roll-on target",
    "Nose wrinkler",
    "Whiff",
    "Meaning of wavy lines, in the comics",
    "Lysol target",
    "Fragrance",
    "Methane's lack",
    "A shoe may have one",
    "Nostril wrinkler",
    "Polecat's defense",
    "Radon lacks it",
    "Unpleasant aura",
    "Scent",
    "Something the nose knows",
    "Fungus byproduct",
    "Sure target",
    "Garlic, for one",
    "It may be left in a locker",
    "Whiff",
    "Lead for a Lab",
    "Carbon monoxide's lack",
    "Stink",
    "Lilac, e.g.",
    "Scent",
    "Polecat's defense",
    "Wavy lines, in the comics",
    "Scent",
    "Aroma",
    "Repute",
    "Skunk's defense",
    "Gym socks may have one",
    "Scent",
    "Skunk's defense",
    "What the nose knows",
    "It's put off at the bakery",
    "Stench",
    "Repute",
    "Scent",
    "Repute",
    "Trail",
    "Scent",
    "Redolence",
    "Dump problem",
    "Air freshener target",
    "Stench",
    "Characteristic property",
    "What the nose knows",
    "Perfume",
    "Skunk's defense",
    "Olfactory stimulation",
    "Scent",
    "Pervasive atmosphere",
    "One may be picked up at a bakery",
    "Olfactory stimulus",
    "Repute",
    "Scent",
    "Redolence",
    "Scent",
    "Skunk's defense",
    "Redolence",
    "Effluvium",
    "Bouquet",
    "Fragrance",
    "Nose tweaker",
    "Essence",
    "Bouquet",
    "Tang",
    "Stink",
    "Incense"
  ],
  "asis": [
    "Yard sale caveat",
    "Yard sale caveat",
    "Buyer's warning",
    "How a used item may be sold",
    "\"The way things are currently going ...\"",
    "Unrepaired",
    "Warts and all",
    "Thrift shop caveat",
    "It means \"no returns allowed\"",
    "\"___ traditional ...\"",
    "Flaws and all",
    "Garage sale caveat",
    "Unaltered",
    "Garage sale caveat",
    "Secondhand sale stipulation",
    "Without change",
    "Clearance caveat",
    "Unchanged",
    "Sales caveat",
    "Without alteration",
    "Phrase on a yard sale tag",
    "\"No guarantees\"",
    "Unaltered",
    "Sales caveat",
    "Sign suggesting \"caveat emptor\"",
    "Yard sale caveat",
    "Without modification",
    "Garage sale disclaimer",
    "Defects and all",
    "Cautionary words for a buyer",
    "\"No warranty\"",
    "Risky way to buy a used car",
    "Yard sale caveat",
    "Without warranty",
    "Condition of sale",
    "\"The way things are ...\"",
    "How used goods are often sold",
    "How a sale item may be sold",
    "With warts and all",
    "In its existing state",
    "Yard sale caveat",
    "Seller's caveat",
    "[Buyer beware]",
    "Caveat to a buyer",
    "Floor model caveat",
    "Sale tag condition",
    "Unreturnable, in a way",
    "In its current state",
    "\"No guarantees\"",
    "Liability-limiting words",
    "Undisturbed",
    "Antique shop tag",
    "Tag sale tag",
    "Sale condition",
    "Sale tag words",
    "Tag line?",
    "Frequent disclaimer",
    "Warts and all",
    "Untouched",
    "Seller's caveat",
    "Clearance rack words",
    "Just like that",
    "Warts and all",
    "Sale tag caution",
    "Sale caveat",
    "Unchanged",
    "Without a guarantee of condition",
    "Tag sale proviso",
    "Untouched",
    "Without adjustments",
    "Seller's terms",
    "\"No returns\"",
    "Purchase stipulation",
    "Sans warranty",
    "Store advisory",
    "Sale tag words",
    "Tag on a sale item",
    "Sales tag words",
    "Tag line?",
    "Sale sign",
    "Unrepaired",
    "Yard sale caveat",
    "Caveat emptor phrase",
    "Sales caveat",
    "Sales tag notation",
    "Yard sale tag",
    "Flea market tag",
    "Clearance item's caveat",
    "With dents and all",
    "With warts and all",
    "Sale tag caution",
    "Price tag qualifier",
    "With warts and all",
    "Tag line?",
    "\"Not returnable\"",
    "Warts and all",
    "Tag sale tag",
    "Yard sale tag",
    "Garage sale tag",
    "Tag sale tag",
    "Closeout caveat",
    "Yard sale caution",
    "Sale sign",
    "Warts and all",
    "WYSIWYG, on sales tags",
    "Warts and all",
    "One way to sell something",
    "Yard sale tag",
    "Tag sale tag",
    "\"No returns\"",
    "Disclaimer on a sale tag",
    "\"Buyer beware\" phrase",
    "Sale sign",
    "Sale sign",
    "Sale caution",
    "Tag line",
    "[No return allowed]",
    "Sales term",
    "Message on a tag-sale tag",
    "Sale words",
    "Sale condition",
    "Clearance condition",
    "With defects and all",
    "One way to buy an item",
    "Without warranty",
    "\"No returns\"",
    "Unrepaired",
    "Sale caveat",
    "Auction caution",
    "Garage sale warning",
    "Sale caveat",
    "Used car caveat",
    "Sale item label",
    "Antique store tag",
    "How flawed goods are sold",
    "\"No return\"",
    "Thrift shop stipulation",
    "\"No returns\"",
    "Without warranty",
    "Sale tag warning",
    "Sale stipulation",
    "Buyer's caution",
    "With defects and all",
    "Tag warning",
    "Sale tag phrase",
    "Store warning",
    "Terms of sale",
    "Without option of return",
    "Seller's stipulation",
    "Sale tag notation",
    "Garage sale caveat",
    "Unfixed",
    "Sale condition",
    "Garage-sale words",
    "Buyer caveat",
    "Tag words",
    "Seller's caveat",
    "With defects and all"
  ],
  "otto": [
    "Eight, in Italian",
    "Germany's von Bismarck",
    "Palindromic number in Italian",
    "Name that has left/right symmetry",
    "Real first name of Spider-Man villain Doctor Octopus",
    "Palindromic guy's name",
    "___ the Autopilot, inflatable balloon character in \"Airplane!\"",
    "Italian number that's also a man's name",
    "Apt name for a car mechanic?",
    "___ the Great (10th-century Holy Roman emperor)",
    "Prussia's ___ von Bismarck",
    "Spider-Man villain ___ Octavius",
    "Germany's von Bismarck",
    "German chancellor ___ von Bismarck",
    "Father of Anne Frank",
    "Prussia's ___ von Bismarck",
    "Bus driver on \"The Simpsons\"",
    "Holy Roman Empire's ___ the Great",
    "___ Octavius, Spider-Man villain",
    "German chancellor ___ von Bismarck",
    "Chancellor von Bismarck",
    "___ the Orange (Syracuse mascot)",
    "Moviemaker Preminger",
    "German artist Dix",
    "Man's name that's a number in Italian",
    "Palindromic boy's name",
    "\"Beetle Bailey\" dog",
    "Palindromic boy's name",
    "Comics canine",
    "\"Beetle Bailey\" dog",
    "Good name for a chauffeur?",
    "Palindromic man's name",
    "\"The Simpsons\" bus driver",
    "Palindromic man's name",
    "\"The Simpsons\" character with a palindromic name",
    "Due + sei",
    "Palindromic boy's name",
    "\"The Simpsons\" bus driver",
    "Uber-owned company that makes self-driving trucks",
    "Germany's von Bismarck",
    "Germany's ___ von Bismarck",
    "\"The Simpsons\" bus driver",
    "Cleveland Browns great Graham",
    "Palindromic boy's name",
    "Bus driver on \"The Simpsons\"",
    "Hall-of-Fame football center Jim",
    "German boy's name meaning \"wealthy\"",
    "Man's name that's almost a homophone for 13-Across",
    "\"Beetle Bailey\" dog",
    "Good name for a chauffeur?",
    "Swimmer Kristin ___, the first woman to win six gold medals at a single Olympics",
    "___ the Orange (Syracuse mascot)",
    "Palindromic name",
    "\"Smoke Gets in Your Eyes\" lyricist Harbach",
    "The \"O\" in F. A. O. Schwarz",
    "Palindromic man's name",
    "Filmmaker Preminger",
    "Bart and Lisa's bus driver",
    "\"The Simpsons\" character with a habit of calling things \"gnarly\"",
    "Director Preminger",
    "German Expressionist ___ Dix",
    "Comics canine",
    "Good name for a garage mechanic?",
    "Raiders Hall-of-Famer Jim",
    "Palindromic man",
    "Palindromic emperor",
    "Good name for a car mechanic?",
    "\"Beetle Bailey\" dog",
    "Springfield Elementary employee",
    "Spider-Man villain ___ Octavius",
    "___ von Bismarck",
    "Hall-of-Fame QB Graham",
    "___ von Bismarck",
    "Anne Frank's father",
    "Cartoon busman Mann",
    "\"Beetle Bailey\" bulldog",
    "\"The Touch of Your Hand\" lyricist Harbach",
    "Anne Frank's father",
    "Germany's ___ von Bismarck",
    "\"Beetle Bailey\" bulldog",
    "\"Beetle Bailey\" dog",
    "Bus driver on \"The Simpsons\"",
    "Director Preminger",
    "Sharkey of TV's \"C.P.O. Sharkey\"",
    "One of four Holy Roman emperors",
    "\"Beetle Bailey\" bulldog",
    "Maestro Klemperer",
    "German king who became an early Holy Roman Emperor",
    "Germany's ___ von Bismarck",
    "Hall-of-Fame QB Graham",
    "\"Beetle Bailey\" dog",
    "Uniformed comics dog",
    "___ the Orange (Syracuse University mascot)",
    "Camp Swampy dog",
    "\"How to Make an American Quilt\" author Whitney ___",
    "\"Beetle Bailey\" dog",
    "Filmmaker Preminger",
    "Sgt. Snorkel's four-legged friend",
    "Nobel-winning physicist Stern",
    "Football Hall-of-Famer Graham",
    "Chemistry Nobelist Hahn, who co-discovered nuclear fission",
    "\"Beetle Bailey\" bulldog",
    "France's ___ von Bismarck",
    "Director Preminger",
    "\"Beetle Bailey\" dog",
    "\"Beetle Bailey\" dog",
    "Sgt. Snorkel's dog",
    "\"The Simpsons\" bus driver",
    "Dr. Octavius, Spider-Man foe",
    "Due and sei",
    "Kristin ___, six-time swimming champion at the 1988 Olympics",
    "Germany's von Bismarck",
    "\"The Simpsons\" bus driver",
    "Sgt. Snorkel's dog",
    "Camp Swampy pooch",
    "\"Beetle Bailey\" pooch",
    "Holy Roman emperor ___ the Great",
    "1931 Medicine Nobelist Warburg",
    "Director Preminger",
    "One of the Ringling Brothers",
    "Pet dog at Camp Swampy",
    "Art patron Kahn",
    "Herr Bismarck",
    "Hall-of-Fame center Jim",
    "Olympic swimming gold medalist Kristin ___",
    "\"Beetle Bailey\" pooch",
    "Conductor Klemperer",
    "Director Preminger",
    "Director Preminger",
    "Palindromic guy",
    "Maestro Klemperer",
    "German chancellor ___ von Bismarck",
    "\"Beetle Bailey\" pooch",
    "\"Beetle Bailey\" dog",
    "___ the Great (10th-century king)",
    "Germany's ___ von Bismarck",
    "\"Beetle Bailey\" dog",
    "Director Preminger",
    "\"Beetle Bailey\" dog",
    "Conductor Klemperer",
    "Nobelist Hahn or Warburg",
    "Chancellor von Bismarck",
    "Palindromic guy's name",
    "___ Graham, 1940's-50's Cleveland QB",
    "\"Beetle Bailey\" dog",
    "Conductor Klemperer",
    "Grid great Graham",
    "Dog in \"Beetle Bailey\"",
    "___ von Bismarck",
    "Conductor Klemperer",
    "Conductor Klemperer",
    "Comics canine",
    "1988 swimming gold medalist Kristin",
    "\"Beetle Bailey\" dog",
    "Bismarck, for one",
    "\"Smoke Gets in Your Eyes\" lyricist Harbach",
    "One of four Holy Roman Emperors",
    "Lyricist Harbach",
    "Football Hall-of-Famer Graham",
    "Cartoonist Soglow",
    "Director Preminger",
    "___ von Bismarck",
    "Chemistry Nobelist Hahn",
    "One of four Holy Roman emperors",
    "Conductor Klemperer",
    "Sgt. Snorkel's dog",
    "\"No, No, Nanette\" lyricist Harbach",
    "Germany's ___ von Bismarck",
    "Sgt. Snorkel's dog",
    "With 38-Down, Nobel-winning German chemist",
    "Director Preminger",
    "Sgt. Snorkel?Æs dog",
    "One of four Holy Roman emperors",
    "Dog in \"Beetle Bailey\"",
    "Football Hall-of-Famer Jim",
    "Camp Swampy dog",
    "German opera composer ___ Nicolai",
    "Football legend Graham",
    "\"How to Make an American Quilt\" author Whitney ___",
    "Camp Swampy canine",
    "Number after sette",
    "Stevenson's \"Prince ___\"",
    "Conductor Klemperer",
    "Palindromic fellow",
    "Prof. Lidenbrock of Verne's \"Journey to the Center of the Earth\"",
    "With 40-Across, 1944 Chemistry Nobelist",
    "Composer Luening",
    "Palindromic comics dog",
    "Comics canine",
    "One of the Ringling brothers",
    "\"Beetle Bailey\" dog",
    "1988 Olympic gold-medal swimmer Kristin",
    "Conductor Luening",
    "German king",
    "Conductor Klemperer",
    "Director Preminger",
    "Germany's ___ von Bismarck",
    "Sgt. Snorkel's dog",
    "\"Laura\" director Preminger",
    "Director Preminger",
    "Art patron ___ Kahn",
    "\"How to Make an American Quilt\" author Whitney ___",
    "Director Preminger",
    "Conductor Klemperer",
    "One of the Ringling Brothers",
    "\"Beetle Bailey\" dog",
    "Director Preminger",
    "Palindromic emperor",
    "\"Beetle Bailey\" dog",
    "Conductor Klemperer",
    "\"High Noon\" actor Kruger",
    "Sgt. Snorkel's bulldog",
    "One of the Ringling Brothers",
    "Number after sette",
    "German equivalent of the Oscar",
    "Mr. Preminger",
    "\"Beetle Bailey\" dog",
    "\"The Simpsons\" bus driver",
    "Palindromic name",
    "\"Beetle Bailey\" pooch",
    "Swimming gold medalist Kristin",
    "Sgt. Snorkel's dog",
    "Football Hall-of-Famer Graham",
    "Football Hall-of-Famer Graham",
    "Director Preminger",
    "Cartoon canine",
    "Swimming champ Kristin",
    "Comics canine",
    "Conductor Klemperer",
    "Mr. Penzler of mystery fiction",
    "Opera composer Nikolai",
    "Germany's Oscar",
    "Mr. Preminger"
  ],
  "pest": [
    "Persistent little sibling, maybe",
    "Pain in the neck",
    "Roach or termite",
    "Boll weevil, for one",
    "Boll weevil, to a cotton farmer",
    "Little brother, stereotypically",
    "Real pain",
    "Nuisance",
    "Little noodge",
    "Termite or gnat",
    "Bedbug, e.g.",
    "One getting shooed",
    "Annoyance",
    "Little sibling, often",
    "Pain in the you-know-what",
    "Obnoxious one",
    "Pain in the neck",
    "Rat or roach",
    "Annoyance",
    "Raid target",
    "Tick, e.g.",
    "Bother",
    "Trying type",
    "Real pain",
    "Garden interloper",
    "Nag, e.g.",
    "Fly in the face of someone?",
    "Bother",
    "Nudnik",
    "Slug, e.g.",
    "Little brother, to an older sibling, say",
    "16-Across, e.g.",
    "One who 26-Across",
    "Tick, e.g.",
    "Unwelcome guest",
    "Irksome type",
    "Dennis the Menace, for one",
    "Pain, so to speak",
    "Pain in the neck",
    "Cockroach or termite",
    "Exterminator's target",
    "With 65-Across, extermination ... or what can be done to 10 answers in this puzzle without affecting their clues?",
    "Annoying type",
    "Orkin victim",
    "Real pill",
    "Starling, e.g.",
    "Mole, e.g.",
    "Younger brother, say",
    "Raid victim",
    "Slug, say",
    "Annoyer",
    "Mole, to a gardener",
    "Irritant",
    "Persistent annoyer",
    "Buttinsky, e.g.",
    "Noodge",
    "D-Con target",
    "Termite, e.g.",
    "Little brother, maybe",
    "Annoyance",
    "Exterminator's target",
    "Nuisance",
    "Ant, maybe",
    "Bother",
    "Pain in the neck",
    "Dennis, to Mr. Wilson",
    "Fly, e.g.",
    "Noodge",
    "43-Down, e.g.",
    "Nudnik",
    "Bother",
    "Cutworm, e.g.",
    "Mosquito, e.g.",
    "Adamant ant?",
    "Fly, e.g.",
    "Hanger-on, maybe",
    "Buttinsky",
    "Noodge",
    "Termite, e.g.",
    "Pain in the neck",
    "Teaser",
    "Nuisance",
    "Nuisance",
    "Nudnik",
    "Botherer",
    "Irk-aholic?",
    "Annoyance",
    "Plague",
    "Buzzy one",
    "Nudnik",
    "Bother",
    "Nudnik",
    "Pain, so to speak",
    "Pain in the neck",
    "Weevil, for one",
    "Annoyance",
    "Nudge",
    "Pain of a sort",
    "Nudnik"
  ],
  "veil": [
    "Hijab, e.g.",
    "Cover",
    "Wedding wear",
    "Prop for a belly dancer",
    "Bridal wear",
    "Bridal wear",
    "Cloak",
    "Bit of headgear raised at the wedding altar",
    "Obscure",
    "Symbol of modesty",
    "Part of a bridal ensemble",
    "Material you might look through",
    "Bit of bridal attire",
    "It may be lifted at a wedding",
    "Bit of bridal wear",
    "Maugham's \"The Painted ___\"",
    "Cover",
    "Bit of bridal attire",
    "Cover",
    "Bride hider",
    "Bridal wear",
    "Prop for Salome",
    "Bridal accessory",
    "Sign of secrecy",
    "Screen",
    "Obscure",
    "Sign of secrecy",
    "Hide",
    "Wedding wear",
    "Bit of wedding garb",
    "Hide"
  ],
  "arts": [
    "National Endowment for the ___",
    "The \"A\" in B.A.",
    "The 700s, in the Dewey Decimal System",
    "___ and sciences",
    "Part of B.A.",
    "The \"A\" of M.M.A.",
    "___ and sciences",
    "Music and theater",
    "___ and crafts",
    "Word with liberal or language",
    "Dark ___",
    "___ and crafts",
    "Crafts' companion",
    "Word after martial or culinary",
    "The \"A\" in A&E",
    "Language ___",
    "Some are liberal",
    "Word with dark or graphic",
    "___ and crafts",
    "___ and crafts",
    "Beaux-___",
    "They may be dark or fine",
    "Theater, dance, etc.",
    "Sciences' counterpart",
    "Sciences' partner",
    "Newspaper section",
    "Black ___ (voodoo and such)",
    "___ and crafts",
    "Creative field",
    "Theater, dance, etc.",
    "Mime and puppetry, e.g.",
    "Music and theater, e.g.",
    "Newspaper section",
    "Counterpart of sciences",
    "Martial ___ (judo and others)",
    "The \"A\" of B.A.",
    "Theater, design, etc.",
    "They may be graphic",
    "Part of AMPAS",
    "They may be endowed",
    "Conservatory pursuits",
    "Music and dance, for two",
    "Part of M.F.A.",
    "Dance and drama",
    "Newspaper section",
    "National Medal of ___ (honor bestowed by the president)",
    "Focus of some philanthropy",
    "___ and sciences",
    "Kennedy Center focus",
    "Ballet and others",
    "___ and Leisure",
    "Song and dance",
    "Film and theater",
    "Field of many nonprofits",
    "B.A. part",
    "Some are liberal",
    "Crafts' partner",
    "The \"A\" of B.A.",
    "Fine things?",
    "Partner of sciences",
    "Dark ___ (Voldemort's practice in the Harry Potter books)",
    "Word with letters?",
    "Fine ___",
    "Part of 101-Across",
    "___ and crafts",
    "The second \"A\" of A.M.P.A.S.",
    "Word with liberal or visual",
    "Bachelor of ___",
    "Music and dance, e.g.",
    "Entertainment partner?",
    "___ and sciences",
    "Big area of philanthropy, with \"the\"",
    "They may be patronized",
    "Some are fine",
    "Wiles",
    "The \"A\" of A&E",
    "Dance and drama",
    "Lively group?",
    "Cultural interests",
    "Beaux-___",
    "Cultural stuff",
    "See 52-Down",
    "Graphic ___",
    "See 51-Across",
    "Crafts' partner",
    "Graphic ___",
    "Kennedy Center focus, with \"the\"",
    "Partner of letters",
    "Song and dance, e.g.",
    "Cultural doings",
    "Newspaper section, with \"The\"",
    "Crafts' partner",
    "Sciences' partner",
    "Word with liberal or martial",
    "Culture",
    "Song and dance?",
    "Newspaper section",
    "Word with fine or visual",
    "Cultural doings",
    "Fine ___",
    "PBS matters",
    "Painting and sculpting, e.g.",
    "Song and dance",
    "Cultural doings",
    "Conservatory's focus",
    "Music, ballet, sculpture, etc.",
    "Sciences' partner",
    "Newspaper section, with \"the\"",
    "Fine things?",
    "Bachelor's area",
    "Song and dance, e.g.",
    "Foundations may support them",
    "They might be fine",
    "Letters' partner",
    "Partner of letters",
    "Liberal pursuits",
    "They're black for witches",
    "Some are performing",
    "Muses' field",
    "Dilettantes' passions",
    "___ and sciences",
    "PBS matters",
    "They're sometimes fine",
    "___ and sciences",
    "Song and dance",
    "Graphic ___",
    "Fine things?",
    "Stratagems",
    "The \"A\" in B.A.",
    "Liberal pursuits",
    "Martial ___",
    "Music and painting, for two",
    "See 57-Across",
    "The \"A\" in N.E.A.",
    "Kind of center",
    "These may be fine",
    "The \"A\" of N.E.A.",
    "Sciences' partner",
    "Fine things",
    "Liberal follower",
    "Performing ___",
    "Fine things",
    "Fine subjects",
    "37-Down's partner",
    "Some are liberal",
    "Graphic ___",
    "Fine things?",
    "Partner of crafts",
    "Theater, opera, etc.",
    "Fine or liberal follower",
    "Lincoln Center subject",
    "Kennedy Center focus",
    "These may be fine",
    "Song and dance, e.g.",
    "The Muses' domain",
    "Part of B.A.",
    "Humanities",
    "College knowledge",
    "They may be fine",
    "Liberal pursuits",
    "These may be fine",
    "Part of a B.A.",
    "The \"A\" in M.A.",
    "___ and crafts",
    "___ and sciences",
    "PBS topics",
    "Partner of crafts",
    "Humanities",
    "Knowledge",
    "Cultural matters, with \"the\"",
    "Some are liberal",
    "Liberal ___",
    "Sciences' partner",
    "The humanities"
  ],
  "alec": [
    "Actor Baldwin",
    "Smart ___",
    "Son in \"Tess of the D'Urbervilles\"",
    "The spy in \"The Spy Who Came in From the Cold\"",
    "Smart ___",
    "One of the Baldwin brothers",
    "Smart ___",
    "Smart ___",
    "Guinness of film",
    "Trump impersonator Baldwin",
    "Knighted actor Guinness",
    "___ Douglas-Home, 1960s British P.M.",
    "Author Waugh",
    "Smart ___",
    "\"Smart\" boy's name",
    "A Baldwin brother",
    "Eldest of the Baldwin brothers",
    "Baldwin sometimes seen on stage",
    "Pianist Templeton",
    "Actor Guinness of \"Star Wars\"",
    "Actor Guinness",
    "1960s British P.M. ___ Douglas-Home",
    "Baldwin of \"The Boss Baby\"",
    "Smart fellow?",
    "Smart ___ (wiseacre)",
    "Trump portrayer Baldwin",
    "One of the d'Urbervilles in \"Tess of the d'Urbervilles\"",
    "Baldwin of \"To Rome With Love\"",
    "Baldwin famous for his Donald Trump impersonation",
    "Trump impersonator Baldwin",
    "Smart ___",
    "Baldwin known for his presidential impersonation",
    "Evelyn Waugh's writer brother",
    "\"Smart\" one",
    "Novelist Waugh",
    "___ Ramsay, hero of \"The Black Stallion\"",
    "Writer Waugh",
    "One of the Baldwin brothers",
    "Tess's lover in \"Tess of the D'Urbervilles\"",
    "Former British P.M. Douglas-Home",
    "One of the Baldwins",
    "Baldwin of \"30 Rock\"",
    "Ill-fated seducer in \"Tess of the D'Urbervilles\"",
    "Actor Baldwin",
    "___ Trevelyan, Agent 006 in \"GoldenEye\"",
    "Baldwin of \"30 Rock\"",
    "Baldwin of \"30 Rock\"",
    "\"The Twilight Saga\" vampire",
    "1960s British P.M. ___ Douglas-Home",
    "Baldwin of \"30 Rock\"",
    "Actor Guinness",
    "\"I'll Be Around\" songwriter Wilder",
    "Author Waugh",
    "Baldwin of \"30 Rock\"",
    "Writer Wilkinson of The New Yorker",
    "Baldwin of \"30 Rock\"",
    "Greven who wrote the 2008 best seller \"How to Talk to Girls\"",
    "Writer Waugh",
    "Frequent \"S.N.L.\" host Baldwin",
    "___ Trevelyan, villain in the James Bond film \"GoldenEye\"",
    "Britain's Douglas-Home",
    "Baldwin who has hosted \"S.N.L.\" more times than anyone else",
    "Smart ___",
    "Actor Baldwin",
    "Baldwin of \"30 Rock\"",
    "Baldwin of \"30 Rock\"",
    "Actor Guinness",
    "Baldwin or Guinness",
    "\"30 Rock\" co-star Baldwin",
    "Baldwin of \"30 Rock\"",
    "Smart ___",
    "Baldwin of \"30 Rock\"",
    "Tess's literary seducer",
    "Actor Baldwin",
    "Waugh who wrote \"Island in the Sun\"",
    "Tina's \"30 Rock\" co-star",
    "Baldwin of \"30 Rock\"",
    "A Baldwin",
    "Twin vampire in \"The Twilight Saga\"",
    "Smart ___ (wise guy)",
    "A Baldwin brother",
    "Sir ___ Guinness",
    "Actor Guinness",
    "Author Waugh",
    "One of the Baldwins",
    "Guinness or Waugh",
    "\"The Black Stallion\" lad",
    "Baldwin of \"30 Rock\"",
    "Guinness who played Obi-Wan Kenobi",
    "Baldwin of \"30 Rock\"",
    "Actor Baldwin",
    "Guinness of stage and screen",
    "British P.M. ___ Douglas-Home",
    "Pianist Templeton",
    "Lover of Tess in \"Tess of the D'Urbervilles\"",
    "\"The Black Stallion\" boy",
    "\"While We're Young\" songwriter Wilder",
    "Baldwin of \"The Cat in the Hat\"",
    "One of the Baldwin brothers",
    "Writer Waugh",
    "___ Ramsay (\"The Black Stallion\" hero)",
    "Smart ___",
    "Writer Waugh",
    "1960s British P.M. ___ Douglas-Home",
    "A Waugh",
    "A Waugh",
    "Actor Baldwin",
    "Shakespearean actor McCowen",
    "Actor Baldwin",
    "One of the Waughs",
    "Actor Guinness",
    "Actor Baldwin",
    "Writer Waugh",
    "The spy in \"The Spy Who Came In From the Cold\"",
    "A Baldwin",
    "One of the Waughs",
    "Blind jazz pianist Templeton",
    "Knighted Guinness",
    "Actor Guinness",
    "Knighted Guinness",
    "\"The Black Stallion\" lad",
    "Actor Baldwin",
    "Baldwin of the silver screen",
    "A Baldwin brother",
    "The spy in \"The Spy Who Came In From the Cold\"",
    "A Baldwin",
    "Oscar winner Guinness",
    "A Baldwin",
    "Smart ___",
    "\"Smart\" one",
    "Writer Waugh",
    "A Baldwin",
    "Smart ___",
    "Baldwin of \"Talk Radio\"",
    "Writer Waugh",
    "One of the Waughs",
    "Pianist Templeton",
    "\"The Black Stallion\" boy",
    "Cad in \"Tess of the D'Urbervilles\"",
    "Smart one",
    "Smart ___",
    "Actor Baldwin",
    "Smart ___",
    "Actor Guinness",
    "Jazz pianist Templeton",
    "One of the Baldwins",
    "Piano virtuoso Templeton",
    "One of the Baldwins",
    "Novelist Waugh",
    "\"The Black Stallion\" boy",
    "One of the Baldwins",
    "Smart guy?",
    "Knighted Guinness",
    "Actor Guinness",
    "A Baldwin",
    "Oscar winner Guinness",
    "Guinness who was knighted",
    "Le Carre's Leamas",
    "1960's P.M. ___ Douglas-Home",
    "Novelist Waugh",
    "Actor McCowen",
    "\"Tess of the D'Urbervilles\" cad",
    "Actor Guinness",
    "\"Smart\" one",
    "Seducer of 53-Down",
    "Actor Guinness",
    "Smart-___",
    "Novelist Waugh",
    "Meg's \"Prelude to a Kiss\" co-star",
    "McCowen of \"Frenzy\"",
    "\"I'll Be Around\" composer Wilder",
    "Actor Baldwin",
    "A Baldwin",
    "Baldwin of \"Prelude to a Kiss\"",
    "Obi-Wan player",
    "Smart ___",
    "Actor Guinness",
    "Old-time pianist Templeton",
    "Novelist Waugh",
    "30's-40's pianist Templeton",
    "One of the Baldwins",
    "Actor Guinness",
    "Tess's seducer in \"Tess of the D'Urbervilles\"",
    "Actor Baldwin",
    "Waugh or Guinness",
    "One of the Baldwins",
    "Smart-___",
    "Actor Baldwin",
    "One of the Baldwins",
    "Actor Guinness",
    "60's British P.M. Douglas-Home",
    "Meg's \"Prelude to a Kiss\" co-star",
    "Guinness or Baldwin",
    "Actor Guinness",
    "Actor McCowen",
    "\"The Black Stallion\" boy",
    "Novelist Waugh",
    "One of the Baldwins",
    "Actor Baldwin",
    "He played Obi-Wan",
    "One of the Baldwins",
    "Fish relish",
    "One of the Waughs",
    "Actor Guinness",
    "British P.M. ___ Douglas-Home",
    "One of the Baldwin brothers",
    "Actor McCowen",
    "Novelist Waugh",
    "\"Black Stallion\" boy",
    "Britain's ___ Douglas-Home",
    "Writer Waugh",
    "\"Tess of the d'Urbervilles\" cad",
    "Songwriter Wilder",
    "Actor McCowen",
    "\"The Black Stallion\" boy",
    "Smart ___",
    "One of the Baldwins",
    "Mr. Guinness",
    "Actor Baldwin",
    "Actor Baldwin",
    "Ex-British P.M.___Douglas-Home",
    "Mr. Guinness",
    "Actor Guinness",
    "Novelist Waugh",
    "Ex-P.M. ___ Hume",
    "Actor Guinness",
    "Novelist Waugh",
    "\"Tess of the D'Ubervilles\" cad",
    "Mr. Guinness",
    "?ôThe Black Stallion?ö hero ___ Ramsey",
    "Guiness or Baldwin",
    "English actor McCowen",
    "British P.M. Douglas-Home",
    "He played Obi-Wan",
    "Smart one",
    "Novelist Waugh",
    "British P.M. ___ Douglas-Home",
    "Actor Guinness",
    "Tess Durbeyfield's seducer",
    "Anchovy sauce"
  ],
  "mosh": [
    "Dance in a pit",
    "Slam-dance",
    "___ pit (area at a punk concert)",
    "___ pit (rock concert area)",
    "Dance in a pit",
    "Dance violently",
    "Kind of pit at a rock club",
    "Do slam-dancing",
    "Engage in frenzied concert activity",
    "Dance in a 90's rock club",
    "Slam-dance, 90's-style",
    "Kind of pit",
    "Revel a la rock fans"
  ],
  "hope": [
    "Wish",
    "\"Cross my heart and ___ to die\"",
    "\"To live without ___ is to cease to live\": Dostoyevsky",
    "Word before and after against",
    "It stayed in Pandora's box",
    "2008 political catchword",
    "What you might do when you cross your fingers",
    "2008 Obama catchword",
    "Wish",
    "Cynic's lack",
    "\"___ is being able to see that there is light despite all of the darkness\": Desmond Tutu",
    "2008 Obama catchword",
    "Bill Clinton's Arkansas birthplace",
    "Arkansas town where Bill Clinton was born",
    "2008 campaign theme",
    "Cross one's fingers",
    "Optimist's feeling",
    "Word on a 2008 Obama poster",
    "It stayed in Pandora's box",
    "One might lose it in a crisis",
    "\"Keep ___ alive!\"",
    "A good breakfast, but a bad supper, according to Francis Bacon",
    "Word before and after \"against\"",
    "Bad thing to abandon",
    "Word before and after \"against\"",
    "Rhode Island's motto",
    "?ôA good breakfast, but an ill supper?ö: Francis Bacon",
    "Clinton's birthplace",
    "Crosby partner",
    "Desire",
    "Clinton's birthplace",
    "Rhode Island's motto",
    "\"A waking dream\": Aristotle",
    "What an optimist always has",
    "Pessimist's lack",
    "Chance for success",
    "One of Pandora's finds",
    "Word before and after \"against\"",
    "Crossed fingers symbolize it",
    "\"A waking dream,\" according to Aristotle",
    "Aspiration",
    "\"___ springs eternal\"",
    "Second of three virtues",
    "Long (for)",
    "Bill Clinton's birthplace",
    "Rhode Island's motto",
    "Clinton's birthplace",
    "___ Diamond",
    "Word before and after \"against\""
  ],
  "arab": [
    "Part of U.A.E.",
    "Spirited horse",
    "___ League",
    "Bedouin, e.g.",
    "Horse developed in the desert",
    "___ American Heritage Month (April)",
    "Kaffiyeh wearer",
    "Spirited horse",
    "Like about half of the OPEC countries",
    "Many a Meccan",
    "Iraqi, e.g.",
    "Horse often used in endurance races",
    "Steed for a sheik",
    "Sheik ... or his mount",
    "Ali Baba, for one",
    "Spirited steed",
    "The \"A\" of U.A.E.",
    "Spirited horse",
    "Spirited horse",
    "Fast horse",
    "Descendant of Ishmael",
    "Unnamed character in Camus's \"The Stranger\"",
    "10-Down resident",
    "The \"A\" of U.A.E.",
    "Spirited steed",
    "United ___ Emirates",
    "Steed noted for its stamina",
    "Yemeni or Omani",
    "Mideast native",
    "Pan-___",
    "Spirited horse",
    "___ Spring (2010s movement)",
    "Resident of Oman or Yemen",
    "Aladdin, e.g.",
    "___ Spring",
    "Saudi, e.g.",
    "___ Spring (2010s movement)",
    "Like Egypt",
    "Mideast native",
    "Like many OPEC nations",
    "1-Across wearer, e.g.",
    "Like most Jordanians",
    "___ Spring",
    "___ League",
    "Certain 10-Down resident",
    "___-American",
    "Start of Egypt's official name",
    "19-Down, e.g.",
    "Many an Al Jazeera viewer",
    "Baath Party member",
    "Spirited horse",
    "Part of 20-Across",
    "Many an Iraqi",
    "Spirited steed",
    "Oman man",
    "Desert mount",
    "Fine steed",
    "Like 20% of Israel",
    "Spirited horse",
    "Man from Oman",
    "Graceful horse",
    "Omani or Yemeni",
    "Man from Oman, e.g.",
    "Horse of a sort",
    "Fellah or fedayee",
    "Victim in Camus's \"The Stranger,\" e.g.",
    "The \"A\" of U.A.E.",
    "Part of U.A.E.",
    "Bedouin, e.g.",
    "Sheik's mount",
    "The \"A\" in U.A.E.",
    "Many a dinar spender",
    "Yemeni, for one",
    "Emirate dweller",
    "Burnoose wearer",
    "Spirited horse",
    "One with an \"al-\" in his name, often",
    "9-Across native",
    "United ___ Emirates",
    "___ League",
    "Bedouin, e.g.",
    "Desert mount",
    "Show horse",
    "Qatari, e.g.",
    "Prized horse",
    "United ___ Emirates",
    "Speedy steed",
    "___-Americans (about 3.5 million people)",
    "Omani, e.g.",
    "Al Jazeera viewer, typically",
    "Djellaba wearer",
    "Elegant horse",
    "___-Israeli relations",
    "Syrian or Yemeni",
    "Show horse",
    "___ League",
    "Riyadh resident",
    "One of the house of 11-Down, e.g.",
    "___ League",
    "___-American",
    "Native Saudi",
    "___-American",
    "Ali Baba, for one",
    "Saudi, e.g.",
    "Speedy steed",
    "Pan-___",
    "Syrian, say",
    "Target Al Jazeera viewer",
    "Show horse",
    "Certain bazaargoer",
    "Saudi citizen",
    "Kaffiyeh wearer",
    "Riyadh native",
    "The Black Stallion, for one",
    "Kuwaiti, e.g.",
    "Oman man",
    "Dhow sailor, e.g.",
    "Kaffiyeh sporter",
    "Saudi or Yemeni",
    "___ League",
    "Lebanese, say",
    "Abu Dhabi dweller",
    "Sadat, e.g.",
    "___-American",
    "Yemeni or Qatari",
    "Al-Jazeera viewer",
    "Burnoose wearer",
    "Hussein I, for one",
    "Horse of the desert",
    "Saudi, e.g.",
    "Resident of 55-Down",
    "Well-bred horse",
    "Kaffiyeh wearer",
    "Saudi, e.g.",
    "Disney's Aladdin, e.g.",
    "Fine horse",
    "Oman man",
    "Many a Mideasterner",
    "Sheik, e.g.",
    "Swift steed",
    "Kaffiyeh wearer",
    "Riyadh resident",
    "Palestinian",
    "Bazaar merchant, maybe",
    "Graceful galloper",
    "Riyadh resident",
    "U.A.E. center",
    "___ League",
    "Riyadh native",
    "Medina resident",
    "Kaffiyeh wearer",
    "Dashing horse",
    "___-Israeli relations",
    "Basra native",
    "Riyadh resident",
    "Show horse",
    "Spirited horse",
    "___-Israeli relations",
    "Bahraini, e.g.",
    "Show horse",
    "Mideast's ___ League",
    "Show horse",
    "Saudi or Iraqi",
    "United ___ Emirates",
    "League member",
    "Lebanese, e.g.",
    "Dubai native",
    "Burnoose wearer",
    "Desert mount",
    "Abu Dhabi denizen",
    "___ League",
    "Riyadh resident",
    "Kind of summit",
    "Shiite, e.g.",
    "Aladdin, e.g.",
    "Spirited horse",
    "Kind of summit",
    "Show horse",
    "Bedouin",
    "Yemeni, e.g.",
    "Descendant of Ishmael",
    "Desert dweller",
    "Graceful horse",
    "League member",
    "___ League (Mideast group)",
    "___ League (group since 1945)",
    "The \"A\" in U.A.R.",
    "Mauritanian, e.g.",
    "Show horse",
    "Bedouin",
    "Pilgrim to Mecca",
    "Show horse"
  ],
  "cell": [
    "Housing in the big house",
    "Holder of mitochondria",
    "Monk's digs",
    "Mobile",
    "It multiplies by dividing",
    "Honeycomb component",
    "An amoeba has just one",
    "Prison compartment",
    "___ number",
    "Sentence structure?",
    "Cooler unit",
    "Word with wall or tower",
    "Battery",
    "Mobile",
    "Kind of tower",
    "Clandestine group",
    "Cooler part",
    "Unit in a block",
    "Block component",
    "Sentence structure?",
    "Word on a business card",
    "Block division",
    "Jail unit",
    "Sleeper ___",
    "Voltaic ___",
    "Solar panel unit",
    "Place for an arrestee",
    "See 56-Down",
    "Sleeper ___",
    "Unit in a terrorist organization",
    "Group in a spies' network",
    "Theater turn-off?",
    "Modern phone",
    "An amoeba has one",
    "Alternative to office or home",
    "Monk's abode",
    "Room at San Quentin",
    "Kind of phone",
    "Part of a spy organization",
    "Poky part",
    "Kind of phone",
    "Joint tenant's place?",
    "Pen part",
    "Lockup",
    "Spy group",
    "Prisoner's spot",
    "Battery",
    "Kind of phone",
    "It makes a bit of a stir",
    "Word with T or dry",
    "Pen chamber",
    "Kind of phone",
    "Tiny room",
    "Pen compartment",
    "Jail unit",
    "Cubbyhole",
    "D, for one"
  ],
  "hied": [
    "Rushed, old-style",
    "Got a move on",
    "Rushed",
    "Got moving",
    "Rushed",
    "Went in haste",
    "Got a move on, old-style",
    "Rushed",
    "Hurried",
    "Got a move on",
    "Went in haste",
    "Hotfooted it",
    "Got a move on",
    "Double-timed",
    "Hightailed it",
    "Sped off",
    "Went very fast",
    "Scurried",
    "Hopped to it",
    "Made tracks",
    "Went in haste",
    "Sped",
    "Got a move on"
  ],
  "psat": [
    "H.S. exam",
    "Its highest score is 1520",
    "Exam for many a 10th grader, for short",
    "Exam for H.S. jrs.",
    "Exam for college-bound H.S. students",
    "Exam for the college-bound",
    "Exam for many sophs. and jrs.",
    "H.S. exam",
    "Exam for sophs. or jrs.",
    "Princeton Review subj.",
    "College Board offering, for short",
    "It has \"calculator\" and \"no-calculator\" math sects.",
    "It has a 25-min. no-calculator section",
    "ETS offering",
    "Exam for jrs.",
    "Exam for the college-bound, for short",
    "Exam that takes 2 hrs. and 45 mins.",
    "Exam with a max score of 1520",
    "H.S. exam",
    "Measurer of college readiness, for short",
    "11th grader's exam, for short",
    "Two-hour-and-10-min. exam",
    "Its highest possible score is 240, for short",
    "Junior's challenge, for short",
    "Standardized H.S. exam",
    "H.S. exam",
    "It has a 30-min. writing skills section",
    "Exam for jrs.",
    "Natl. Merit Scholarship earner's exam",
    "H.S. junior's exam",
    "Juniors' hurdle: Abbr.",
    "Natl. Merit Scholarship qualifying exam",
    "Ordeal for jrs.",
    "Challenge for jrs.",
    "Its minimum score is 60, briefly",
    "Math is part of it: Abbr.",
    "Its scores are used in selecting Natl. Merit Scholars",
    "H.S. junior's exam",
    "Its max. score is 240",
    "H.S. exam",
    "It has two critical reading sects.",
    "Warm-up for the college-bound",
    "Oct. ordeal for jrs.",
    "H.S. junior's hurdle",
    "Exam with 125 questions: Abbr.",
    "Challenge for H.S. juniors",
    "Exam for H.S. jrs.",
    "Warm-up exam for future collegians",
    "H.S. junior's exam",
    "H.S. junior's exam",
    "Some exam practice, for short",
    "H.S. junior's exam",
    "Jr.'s exam",
    "High school jrs. take it",
    "H.S. exam",
    "Jr.'s exam",
    "Standardized H.S. exam",
    "It's administered in H.S.",
    "Prep exam, for short",
    "Warm-up exam for H.S. students",
    "Jr.'s test",
    "High sch. hurdle, for some",
    "E.T.S. offering",
    "Jr.'s exam",
    "H.S. junior's test",
    "Exam for a jr.",
    "Exam taken in H.S.",
    "Math constitutes half of it: Abbr.",
    "Exam for jrs.",
    "H.S. junior's test",
    "Math makes up half of it: Abbr.",
    "H.S. junior's challenge",
    "Jr.'s exam",
    "H.S. exam",
    "E.T.S. offering",
    "Academic rite of passage: Abbr.",
    "College prep exam",
    "Prep exam, for short",
    "H.S. exam",
    "High schooler's test, briefly",
    "Warm-up exam, for short",
    "Exam for H.S. juniors",
    "Jr.'s exam",
    "11th graders' exam: Abbr.",
    "H.S. jr.'s exam",
    "Exam for jrs.",
    "H.S. jr.'s exam",
    "Exam for H.S. jrs.",
    "Rel. of college boards"
  ],
  "herd": [
    "Word with immunity or mentality",
    "Cowboy's charge",
    "What's rounded up in a roundup",
    "Group of buffalo",
    "Collective",
    "Group of bison",
    "Collection of stock",
    "Do a cowboy's job",
    "A maverick doesn't follow it",
    "What's rounded up in a roundup",
    "Group of buffalo",
    "Group of buffalo",
    "Drove",
    "*Royal messenger",
    "Stockholder's group?",
    "Cowboy's charge",
    "Throng",
    "Round up",
    "Rustler's target",
    "Group of buffalo",
    "It's rounded up in a roundup",
    "Round up",
    "\"Thundering\" group",
    "Gather",
    "Plain sight",
    "Drove",
    "Moving stock",
    "Cattle that all have the same brand",
    "Source of a thundering sound",
    "Stampeders",
    "Reindeer group",
    "Farm group",
    "Rancher's concern",
    "Group of buffalo",
    "Driven group",
    "Jersey group?",
    "Cattle group",
    "Rancher's concern",
    "It's rounded up in a roundup",
    "Stampeding group",
    "It's rounded up in a roundup",
    "Thundering group",
    "Drove",
    "Large number",
    "Stampeders",
    "Stampeders",
    "Group of cattle",
    "What a drover drives",
    "Hand's handful",
    "Grazing group",
    "Group",
    "Drove",
    "Stampeding group",
    "Thundering group",
    "Roundup group",
    "Cowpoke's charge",
    "Stockyard group"
  ],
  "papa": [
    "One of the Smurfs",
    "Daddy",
    "Smurf with a white beard",
    "Follower of Oscar in the NATO alphabet",
    "Letter between Oscar and Quebec",
    "Old man",
    "Fairy tale bear with a hard bed",
    "Owner of the first bed that Goldilocks tested",
    "\"Come to ___\"",
    "Old man",
    "Letter before Quebec in the NATO alphabet",
    "Family man",
    "Mate for a mama",
    "Biggest of the Three Bears",
    "Bear with a bed that was too hard",
    "Old man",
    "Biggest bear in \"Goldilocks and the Three Bears\"",
    "\"Come to ___\"",
    "Smurf with a white beard",
    "___ John",
    "One of the Three Bears",
    "Family member, endearingly",
    "Daddy",
    "\"___ Was a Rollin' Stone\" (Temptations hit)",
    "Family nickname",
    "One of the Three Bears",
    "Who is told \"don't preach,\" in a Madonna hit",
    "\"Come to ___!\" (gambler's cry)",
    "Oscar follower, in communications",
    "Pizza maker John Schnatter's nickname",
    "Dad",
    "\"Goldilocks\" bear with the hardest bed",
    "Bear whose bed was too hard for Goldilocks",
    "Leader of the Smurfs",
    "Family man",
    "Mama's mate",
    "*___ John's",
    "Mama's mate",
    "Daddy",
    "Dear old dad",
    "___ John's",
    "Smurf in red",
    "\"___ Don't Preach\" (#1 Madonna hit)",
    "One of the Smurfs",
    "\"Come to ___\"",
    "Quebec preceder, to pilots",
    "___ John's (Domino's competitor)",
    "Old man",
    "Nickname for Haydn",
    "Letter after Oscar",
    "Hemingway's nickname",
    "\"Come to ___\"",
    "The old man",
    "Elderly Smurf",
    "Eddie Fisher's \"Oh! My ___\"",
    "Nickname for Joseph Haydn",
    "With 10-Across, sobriquet for Bill O'Reilly used by 39-Across",
    "\"Come to ___!\"",
    "Nickname for Hemingway",
    "\"___ Was a Rollin' Stone\"",
    "___ John's (Domino's competitor)",
    "\"Come to ___\"",
    "P, in a phonetic alphabet",
    "___ Bear",
    "Sobriquet for singer John Phillips",
    "Hemingway nickname",
    "Haiti's ___ Doc",
    "Sobriquet for Haydn",
    "Term of affection for 37-Across",
    "Dad",
    "Mama's partner",
    "Sobriquet for Haydn",
    "\"Come to ___\"",
    "Hemingway sobriquet",
    "\"___ Was a Rollin' Stone\"",
    "One of the Three Bears",
    "Dad",
    "Storybook bear",
    "One of the Three Bears",
    "One wearing the pants in the family?",
    "One-fourth of a 60's group",
    "___ Bear",
    "Mama's partner",
    "Father",
    "Role model for a lad",
    "Mate of 1-Across",
    "Family pillar",
    "Daddy",
    "Bishop of Roma",
    "Family man",
    "One of the Three Bears",
    "Haydn's sobriquet",
    "John Paul, e.g., to the Italians",
    "Hemingway's handle",
    "Tot's cry",
    "A Bear",
    "Madonna's \"___ Don't Preach\"",
    "Bear of literature",
    "Perry Como's \"___ Loves Mambo\"",
    "One of the Three Bears",
    "___ Bear",
    "\"Oh! My ___\" (Eddie Fisher tune)",
    "Mama's mate",
    "___Doc Duvalier",
    "June honoree",
    "\"Down at___Joe's\" ('63 hit)",
    "Father",
    "Hemingway nickname",
    "Dad",
    "Nickname for Haydn",
    "\"___ Was a Rollin' Stone\" (1972 hit)",
    "Hemingway moniker"
  ],
  "pics": [
    "Insta uploads",
    "Photos, informally",
    "P.R. releases",
    "Snaps",
    "Snaps",
    "Brief shots?",
    "Much of Us Weekly",
    "Snaps",
    "Photos",
    "Shots, for short",
    "Snaps",
    "Snapshots",
    "Cropped photos?"
  ],
  "typo": [
    "Care for cars?",
    "Run for fun, perhaps",
    "Cars for cats, say",
    "This cluue has one, apparently",
    "Mistakke, of a sort?",
    "Slip of the fingers",
    "Spell-checker find",
    "Ore, for one?",
    "An aye for an eye, say?",
    "Tears for Fears, e.g.",
    "Spell-checker target",
    "Exmaple for example, for example",
    "Thsi clue has one",
    "There's one in this cleu",
    "Bad character?",
    "Printed slip",
    "Feature of the previous clue",
    "Misprint",
    "Unlucky strike?",
    "Printing goof",
    "Paper slip?",
    "Four for for, for one",
    "Printing goof",
    "What each starred clue &mdash; and its answer &mdash; contains",
    "Nooks for books, maybe",
    "Spell checker's find",
    "Toys for Tots, for example",
    "Book slip",
    "Result of a bad stroke",
    "Mispelled, for misspelled, e.g.",
    "Slip on the galley",
    "Tears for Fears, e.g.",
    "Keyboard slip",
    "Printing goof",
    "Tit for tat, e.g.?",
    "Letter drop, e.g.",
    "It may be found in a proof",
    "Mistranscription",
    "Erratum",
    "Tit for tat, perhaps",
    "Printer's goof",
    "Thsi, e.g."
  ],
  "polo": [
    "Sport with mallets",
    "Explorer Marco",
    "Common bit of golf attire",
    "Sport played at British boarding schools",
    "Game that can be played on bicycles or elephants",
    "Sport with saddles",
    "Water ___",
    "Word with shirt or grounds",
    "Sport with mallets",
    "Standard part of preppy attire",
    "___ pony",
    "Olympic sport discontinued after 1936",
    "Sport that players are not allowed to play left-handed",
    "Game that becomes another game when its last two letters are switched",
    "Short-sleeved shirt",
    "Spiffy top",
    "Equestrian's sport",
    "Explorer whose name is a sport",
    "Preppy wear",
    "Game in which it's illegal to play left-handed",
    "Sport last played in the Olympics in 1936",
    "___ grounds",
    "Summer Olympics event discontinued after 1936",
    "Quarters : basketball :: chukkers : ___",
    "Source for \"Book of the Marvels of the World,\" circa 1300",
    "Lacoste product",
    "See 16-Across",
    "Preppy shirt",
    "Casual Friday shirt",
    "Marco ___ (shirt sold on Rubio's website)",
    "Garment usually with two buttons",
    "Shirt style",
    "Common khakis go-with",
    "Sport with mallets",
    "Preppy wear",
    "What sport has divisions called chukkers?",
    "Lacoste offering",
    "Sport that requires helmets",
    "Horseplay?",
    "Explorer Marco",
    "Kind of shirt named for a sport",
    "Pullover shirt",
    "Fashion line named for a sport",
    "Sport on horseback",
    "Explorer Marco",
    "Sport with a 4 1/2-ounce ball",
    "Ralph Lauren label",
    "Word before field or shirt",
    "Game you can't play left-handed",
    "Prince Charles's sport",
    "Sport with horses",
    "Sport with mallets",
    "Horseplay?",
    "Golf shirt",
    "Left-handers can't play it",
    "Game on horseback",
    "Knit shirt",
    "Pull-on",
    "Mallets are used in it",
    "Visitor to Cathay",
    "Traveler to Cathay",
    "Horse play?",
    "___ pony",
    "Pullover",
    "Ralph Lauren brand",
    "Shirt style",
    "Game you can't play left-handed",
    "Game on horseback",
    "Prince Charles's avocation",
    "Sport with mallets",
    "Game with mallets",
    "Charles's game",
    "Pullover",
    "Sport shirt",
    "Olympic sport discontinued after 1936",
    "Visitor to Kublai Khan",
    "Informal shirt",
    "Royal pastime",
    "Prince Charles's' sport",
    "Sport on horseback",
    "Game with mallets",
    "Kind of shirt",
    "Game with sticks",
    "Famed traveler",
    "Charles's game",
    "Horse play?",
    "Venetian traveler"
  ],
  "fido": [
    "Stereotypical name for a dog",
    "Buddy of Buddy, maybe",
    "Relative of Rex or Rover",
    "Stereotypical dog name that isn't actually used much",
    "Generic dog name",
    "Dog tag?",
    "Pet name meaning \"faithful\"",
    "Alternative to Spot or Rover",
    "Classic name for a man's best friend",
    "Dog tag?",
    "Pal of Rex or Rover",
    "Relative of Rex",
    "Spot follower, perhaps",
    "Relative of Bowser",
    "Spot alternative",
    "Pal for Spot or Rover",
    "Cousin of Spot",
    "Paul Bunyan's dog",
    "Rover's pal",
    "Loyal pooch",
    "Cousin of Rover",
    "Generic pooch",
    "Generic pooch",
    "Friend of Rover",
    "Pal of Spot",
    "Kennelmate of Rover",
    "Bowwow",
    "Pooch's name",
    "Spot alternative",
    "Pet name",
    "Pet name",
    "Family dog",
    "Pooch",
    "Family dog",
    "Bowser's pal",
    "Pooch's name",
    "Rover's playmate"
  ],
  "chai": [
    "___ latte",
    "Cardamom-spiced brew",
    "Spiced brew",
    "Beverage flavored with cardamom",
    "Masala ___ (hot beverage)",
    "Tea made with milk, sugar and cardamom",
    "Kind of tea",
    "Tea variety",
    "Spiced tea variety",
    "Cinnamony tea",
    "Spiced tea from the East",
    "Masala ___ (hot, spicy drink)",
    "Hot beverage",
    "___ latte",
    "Tea type",
    "___ latte",
    "Latte option",
    "___ latte",
    "Vanilla ___ (hot drink)",
    "Black tea-and-milk drink",
    "Spiced tea",
    "Tea type",
    "Tea variety",
    "Spiced Indian tea",
    "Spiced tea",
    "Spiced Indian tea",
    "Starbucks offering",
    "Indian tea",
    "Spicy tea",
    "Indian spiced tea",
    "Indian tea",
    "Hot drink",
    "Non-coffee order at Starbucks",
    "Starbucks option",
    "Spiced tea",
    "Spiced milk tea",
    "Spiced Indian tea"
  ],
  "halo": [
    "Angel's overhead?",
    "Sign of sainthood",
    "Cause of lightheadedness?",
    "Ring up?",
    "___ Top (low-cal ice cream brand)",
    "Feature in many depictions of Buddha",
    "Best-selling game series for the Xbox",
    "Angel's wear",
    "Angel's headwear",
    "Angel hair accompaniment?",
    "Symbol of saintliness",
    "Headlight?",
    "Xbox space-war franchise",
    "Angel's band of light",
    "Good sign?",
    "Dome light?",
    "Sign of a saint",
    "Angel's topper",
    "Popular first-person shooter video game",
    "Something depicted for goodness' sake?",
    "Prop at a Christmas play",
    "Los Angeles Angels' cap feature",
    "Popular first-person shooter video game since 2001",
    "Saintly presence",
    "Sign of virtue",
    "Headlight?",
    "Sign of virtue",
    "Astronomical effect",
    "Sign of spotlessness",
    "Overhead light?",
    "Eclipse phenomenon",
    "Saintly glow",
    "Circle of angels?",
    "Circle of angels?",
    "Circle of light around the sun or moon",
    "Solar or lunar phenomenon",
    "Overhead light?",
    "Cause of lightheadedness?",
    "Symbol of goodness",
    "Something about Mary?",
    "Symbol of sanctity",
    "Saintly sign",
    "Heavenly circle",
    "Meteorological effect",
    "Eclipse feature",
    "Good sign?",
    "Sign of a saint",
    "Holy ring",
    "Sign of virtue",
    "Overhead light?",
    "Astronomical effect",
    "Angel topper",
    "Nimbus",
    "Saint's glow",
    "Astronomical effect",
    "Moon surrounder",
    "Sign of a saint",
    "Angelic topper",
    "Sign of sanctity",
    "Astronomical ring",
    "Something seen with the Virgin Mary",
    "Good sign?",
    "Light ring",
    "Angel's headwear",
    "Good sign",
    "Eclipse effect",
    "What an imp never wears",
    "Radiance, of sorts",
    "Angel's headgear",
    "Angelic feature",
    "Heavenly circle",
    "Crown of light",
    "Ring up?",
    "Surrounding light",
    "Circle overhead?",
    "Overhead light?",
    "Harp go-with, maybe",
    "Meteorological effect",
    "Light overhead?",
    "Saintly glow",
    "Symbol of goodness",
    "Angelic ring",
    "Charismatic glow",
    "Circle overhead?",
    "Headlight?",
    "Symbol of goodness",
    "Angel's headwear",
    "Good sign?",
    "Good sign?",
    "Circle overhead",
    "Headlight?",
    "Solar or lunar phenomenon",
    "Luminous topper",
    "Parhelic circle",
    "Angel's topper",
    "Symbol of goodness",
    "Angel's prop",
    "Luminous ring",
    "Light ring",
    "Refraction phenomenon",
    "Aura",
    "Meteorological effect",
    "Ring",
    "Kind of effect",
    "Sign of goodness",
    "Nimbus",
    "Light of the moon",
    "Nimbus",
    "Prop at a Christmas musical",
    "Angelic symbol",
    "Nimbus",
    "Nimbus",
    "Ring to wear",
    "Angelic headgear",
    "Angel's topper",
    "Nimbus",
    "Light ring",
    "Angelic topper",
    "Headlight?",
    "Lunar light",
    "Moon phenomenon",
    "Saintly ring",
    "Overhead lighting?",
    "Angel's headgear",
    "Overhead projection?",
    "Light headwear?",
    "Angel's headgear",
    "Nimbus",
    "Circle of angels",
    "Headlight?",
    "Sign of a saint",
    "Christmas play prop"
  ],
  "mesh": [
    "Fine lattice",
    "Net material",
    "Intertwine",
    "Gym shorts material",
    "Come together",
    "It breathes well",
    "Interlock",
    "See-through material",
    "Work well together",
    "Interlace",
    "Fit well",
    "Go together well",
    "Fit together well",
    "Blend well together",
    "Go well (with)",
    "Chicken wire, e.g.",
    "Work well together",
    "Jibe",
    "Intertwine",
    "See-through clothing material",
    "Go together",
    "Work well together",
    "Protection against mosquitoes",
    "Interlock",
    "Fit together, as gears",
    "Work well together",
    "See-through material",
    "See-through stocking material",
    "Fit nicely",
    "Go well (with)",
    "Net fabric",
    "It's full of holes",
    "Go well together",
    "Jungle camping supply",
    "Netting",
    "Protection from bug bites",
    "Mosquito protection",
    "Sieve, essentially",
    "Go well (with)",
    "Fit together",
    "Mosquito protection",
    "Go well together",
    "Net",
    "Fishnet stocking material",
    "Work together",
    "Netting",
    "Jersey material",
    "What gears do",
    "Go well together",
    "Be in harmony",
    "Sport jersey material",
    "Screening",
    "Work well together",
    "Dovetail",
    "Go well together",
    "Go well together",
    "Coordinate closely",
    "Chicken coop material",
    "Coordinate",
    "Wire screen",
    "Net material",
    "Go well together",
    "Straining aid",
    "Netting",
    "Netting",
    "Reticulation",
    "Dovetail",
    "Fit well together",
    "Fit together",
    "Go well together",
    "Coordinate",
    "Fit together",
    "Fishnet, e.g.",
    "Sieve, essentially",
    "Screen",
    "Work well together",
    "It breathes",
    "Fit together",
    "Dovetail",
    "Sieve's bottom",
    "Entanglement",
    "Coordinate",
    "Intertwine",
    "Kind of stockings",
    "Intertwine",
    "Strainer",
    "Dovetail",
    "Work well together",
    "Netting",
    "Screen",
    "Entangle",
    "Interlock",
    "Netting",
    "Netting",
    "Web",
    "Engage",
    "Click",
    "Fit together",
    "What gears do"
  ],
  "alto": [
    "Kind of sax",
    "Cher or Sade, voicewise",
    "Intermediate choir voice",
    "Amy Winehouse, vocally",
    "Lead-in to sax",
    "Kind of saxophone",
    "Range for a viola",
    "Chaka Khan, vocally",
    "Countertenor",
    "Tina Turner, voicewise",
    "Voice below mezzo-soprano",
    "Spanish opposite of bajo",
    "Voice below soprano",
    "One whose range goes from about F3 to F5, musically",
    "Cher, e.g., voicewise",
    "Kind of clef",
    "Soprano's co-star?",
    "Cher, for example",
    "Toni Braxton or Mahalia Jackson, e.g.",
    "Palo ___, Calif.",
    "Adele, voicewise",
    "Kind of horn pitched in E&flat;",
    "Voice above tenor",
    "___ sax",
    "High in the Andes",
    "Dolly in \"Hello, Dolly!,\" e.g.",
    "47-Down, for one",
    "Cloud name prefix",
    "Adele, voicewise",
    "Voice above tenor",
    "Rare solo voice in opera",
    "Cher or Adele, musically",
    "Lead-in to stratus or cumulus",
    "Voice below soprano",
    "High in the Andes?",
    "Orfeo in Gluck's \"Orfeo ed Euridice,\" e.g.",
    "Prefix with stratus",
    "Midlevel voice",
    "Amy Winehouse, vocally",
    "Lead-in to cumulus",
    "Cher, for one",
    "Choir part",
    "Cher or Adele, e.g.",
    "Choir part",
    "Quartet part",
    "Kind of clef",
    "Meteorological lead-in to stratus",
    "Choir voice",
    "Voice below soprano",
    "Member of a mixed quartet",
    "Kind of clef",
    "Adele, voicewise",
    "Kind of flute",
    "___ horn",
    "Choir voice",
    "Prefix with cumulus",
    "Certain sax",
    "Voice above tenor",
    "Alicia Keys or Adele, e.g.",
    "Mama Rose in \"Gypsy,\" e.g.",
    "Part of the range that's widely accessible?",
    "Voice higher than tenor",
    "Choir voice",
    "One above a tenor",
    "Kind of clef",
    "Kind of sax",
    "Voice below soprano",
    "Many a Vienna Boys' Choir boy",
    "One quarter of a quartet",
    "___ clef",
    "Mezzo's choirmate",
    "Voice below soprano",
    "Kind of clef",
    "Part in some arrangements",
    "Woman in a choir",
    "Norah Jones or Cher",
    "Voice below soprano",
    "Choir voice",
    "Choir voice",
    "Choir part",
    "Kind of sax",
    "Sax type",
    "Opposite of bajo",
    "Liza Minnelli, for one",
    "Chorus girl",
    "Kind of sax",
    "Kind of saxophone",
    "It's high in Peru",
    "Choir voice",
    "Chorister's voice",
    "Palo ___, Calif.",
    "Voice above tenor",
    "Kind of sax",
    "It's high in the Sierras",
    "Voice below soprano",
    "A cappella group part",
    "Midrange voice type",
    "Mrs. Lovett in \"Sweeney Todd,\" for one",
    "One in a four-part harmony",
    "Choir voice",
    "High in the Sierra Madre?",
    "Quartet member",
    "Certain castrato",
    "Kind of sax",
    "Choir voice",
    "Choral voice",
    "Middle range",
    "Voice below soprano",
    "Clarinet type",
    "Saxophone type",
    "Word with flute or horn",
    "Choir voice",
    "Voice above a tenor",
    "Chorus voice",
    "High, in Honduras",
    "Lola in \"Damn Yankees,\" e.g.",
    "Lola, e.g., in \"Damn Yankees\"",
    "Word on a Mexican stop sign",
    "Range of some robe wearers",
    "___ horn",
    "A chorus line",
    "Kind of clef",
    "Opera villainess, typically",
    "Voice below soprano",
    "Prefix in cloud names",
    "Kind of clef",
    "Middle sax?",
    "High in the Andes",
    "Voice below soprano",
    "Voice above a tenor",
    "Orfeo, e.g., in Gluck's \"Orfeo ed Euridice\"",
    "Kind of sax",
    "Choir voice",
    "Low woman",
    "Deep-voiced, for a woman",
    "Violist's clef",
    "Middle part",
    "High in the Andes",
    "Choir voice",
    "Certain voice",
    "Choir voice",
    "Choir voice",
    "Choir voice",
    "Chorus member",
    "Choir voice",
    "Chorus girl",
    "Midrange voice",
    "Countertenor's counterpart",
    "Kind of recorder",
    "___ saxophone",
    "Chorus girl?",
    "Member of an instrument family",
    "See 118-Across",
    "A chorister",
    "Choir voice",
    "Voice below soprano",
    "High man",
    "Low woman?",
    "Choir member",
    "Chorus member",
    "Voice above bass",
    "Violist's clef",
    "One not ending on a high note?",
    "Like some winds",
    "Kind of sax",
    "Choir voice",
    "Choir member",
    "High in the Andes",
    "Voice range",
    "Start of some cloud names",
    "Kind of flute",
    "Viola's range",
    "Brahms's \"___ Rhapsody\"",
    "Choir voice",
    "Treble clef singer",
    "Second voice",
    "Like Woody Herman's sax",
    "Kind of clef",
    "Kind of sax",
    "Like un monte",
    "Chorus member",
    "Member of the chorus",
    "Choir part",
    "Choir voice",
    "Low woman",
    "A chorus line",
    "Kind of flute",
    "Choir member",
    "Mezzo's colleague",
    "Marian Anderson, for one",
    "Part in an ensemble",
    "Start of some cloud names",
    "Voice below soprano",
    "Kind of sax",
    "Part of a chorus",
    "Kind of flute",
    "Choir voice",
    "Choir part",
    "Kind of clef",
    "Countertenor",
    "Choir voice",
    "___-relievo",
    "Like some singing",
    "Choir voice",
    "\"___ voltaje!\" (Spanish warning)",
    "Like a certain sax",
    "Annina in \"Der Rosenkavalier\"",
    "Trujillo ___ (Puerto Rican city)",
    "Countertenor",
    "Kind of sax",
    "Kind of horn",
    "Doo-wop part",
    "Countertenor",
    "Violist's clef",
    "Quarter of a quartet, maybe",
    "Like Charlie Parker's sax",
    "Palo ___, Calif.",
    "Kind of sax",
    "Voice below soprano",
    "Choir voice",
    "Quartet member",
    "Singing part",
    "Kind of flute",
    "Brahms's \"___Rhapsody\"",
    "Start of some cloud names",
    "Chorister",
    "Quartet member",
    "Kind of sax",
    "Kind of sax",
    "Choir member",
    "Choral voice range",
    "Certain voice",
    "Kind of sax",
    "Choir voice",
    "Chorister",
    "Countertenor",
    "Kind of clef",
    "Musical part",
    "Countertenor",
    "Choir voice",
    "Countertenor"
  ],
  "lard": [
    "Nonvegan shortening",
    "Cooking grease",
    "Suet alternative",
    "Ingredient in some pie crusts",
    "Classic pie crust ingredient",
    "Fat in some piecrusts",
    "Grease for baking",
    "Fatty ingredient in pie crust",
    "Rendered pork fat",
    "Cook's supply",
    "Kosher bakery no-no",
    "Oreo ingredient until the mid-'90s",
    "It's used in preparing tamales",
    "Hog fat",
    "Ingredient in old-time cookie recipes",
    "Fatten",
    "Contents of some tubs",
    "Some shortening",
    "Add superfluous stuff to",
    "Pie crust ingredient, maybe",
    "Cook's canful",
    "Enrich",
    "Enrich",
    "Product of fatback",
    "Cooking fat",
    "Cooking grease",
    "Pig product",
    "___ Lad, doughnut shop on \"The Simpsons\"",
    "Cooking fat",
    "Baker's supply",
    "Enrich",
    "Kitchen canful",
    "Some shortening",
    "Grease",
    "Enrich, in a way",
    "Pie crust ingredient",
    "Fat in a can",
    "Fat",
    "Shortening",
    "Cook's canful",
    "Cooking fat",
    "Embellish, in a way",
    "Cooking aid",
    "Piecrust ingredient",
    "Enrich",
    "Make fat",
    "Enrich, in a way",
    "Grease",
    "Cover with fat",
    "Old pantry supply",
    "Old kitchen supply",
    "Fat",
    "Cooking fat",
    "Cooking fat",
    "Cooking fat",
    "Cooking fat",
    "Tub of ___",
    "Shortening",
    "Kitchen staple, once",
    "Fat",
    "Kitchen fat",
    "Cooking fat",
    "Hog fat"
  ],
  "ufos": [
    "Causes of some mysterious radar blips, in brief",
    "Interplanetary craft, for short",
    "Vehicles in some tabloid photos",
    "Ships passing in the night?",
    "Causes of some 911 calls",
    "Subjects of some eerie videos",
    "Some people believe swamp gas causes them, for short",
    "Sci-fi travelers, for short",
    "Sci-fi saucers",
    "Crafts in a \"close encounter of the third kind\"",
    "E.T. vehicles",
    "Foreign dishes?",
    "Hovering craft",
    "Subjects of some fuzzy photos, for short",
    "Subjects of a Roswell museum",
    "Old tabloid fodder",
    "Supernatural tabloid fodder",
    "Sci-fi craft",
    "Mysterious sightings that hover",
    "Hover craft?",
    "Subjects of a top-secret government cover-up, some believe",
    "Abductees' destinations, in some hard-to-believe stories",
    "ETs pilot them",
    "Subjects of some 911 calls",
    "They're seen but not recognized",
    "Area 51 holdings, supposedly",
    "Astounding Stories subjects",
    "Alien craft",
    "\"Independence Day\" fleet",
    "They're involved in some reported abductions",
    "Potential hoax subjects",
    "They travel very long distances",
    "Aliens' craft, for short",
    "Alien craft",
    "Project Blue Book subject",
    "Sky lights?",
    "They're encountered in \"close encounters\"",
    "\"The X-Files\" fodder",
    "ET transporters",
    "Sightings the U.S.A.F. may investigate",
    "Fantastic craft",
    "Sci-fi sightings",
    "Strange sightings",
    "Some streaking lights, briefly",
    "ETs' ships",
    "Some blinking lights, for short",
    "Roswell sightings",
    "Ships to a far-off place?",
    "Tabloid fliers",
    "Govt. secrets?",
    "Roswell sightings",
    "Roswell sightings",
    "ET's transports",
    "Abductors' craft, some say",
    "Subjects of a U.S. Air Force cover-up?",
    "Unaccounted-for radar blips",
    "Subject of Project Blue Book",
    "Saucers, maybe",
    "Eerie sightings",
    "Abduction sites, supposedly",
    "E.T. vehicles",
    "ET carriers",
    "Alien craft",
    "E.T. carriers",
    "Tabloid topics",
    "Night sightings",
    "Subjects of 58-Down investigations",
    "\"X-Files\" topics",
    "Martian craft, maybe",
    "Tabloid topics"
  ],
  "boar": [
    "Pig in the wild",
    "Big game",
    "Male hedgehog",
    "Pen pa?",
    "Male hedgehog",
    "Big pig",
    "Wild pig",
    "Animal with tusks",
    "Wild hog",
    "Wild pig",
    "Animal with tusks",
    "Wild hog",
    "Old English Christmas meat",
    "Tusked beast",
    "Object of a hunt in \"Lord of the Flies\"",
    "Head of an inn?",
    "Beast imagined in \"Beasts of the Southern Wild\"",
    "Truffle-seeking beast",
    "Wild hog",
    "Sow's mate",
    "Beast that killed Adonis",
    "Tusked animal",
    "Male swine",
    "Certain badger or raccoon",
    "Wild pig",
    "Adonis' undoing",
    "Wild tusker",
    "Wild hog",
    "Wild hog",
    "Erymanthian ___, fourth labor of Hercules",
    "Animal hunted in one of Hercules' 12 labors",
    "Luau entree",
    "Wild hog",
    "Wild hog",
    "Tusked beast",
    "Big pig",
    "Animal that killed Adonis",
    "Animal that may charge",
    "A tusker",
    "Feature of an old-fashioned roast",
    "Tusked animal",
    "Wild pig",
    "Tusked critter",
    "*Fourth step",
    "Wild hog",
    "Center of an old-fashioned roast",
    "Tusked animal",
    "Wild hog",
    "Big pig",
    "Animal \"with bristled hair,\" to Shakespeare",
    "Part of an old English Christmas feast",
    "Old English Christmas Dish",
    "Hog wild?"
  ],
  "ding": [
    "Microwave notification",
    "Minor job at a body shop",
    "Minor fender damage",
    "Demerit",
    "Kitchen timer sound",
    "Criticize in a small way, informally",
    "Little dent",
    "\"Time's up\" sound",
    "Tiny bell sound",
    "[That is correct!]",
    "Little job for a body shop",
    "Car owner's annoyance",
    "Little dent",
    "Nick",
    "Little dent",
    "Small dent",
    "Nick",
    "Surfboard flaw",
    "Quiz show sound",
    "Small dent on a fender",
    "Game show answer signal",
    "Dent",
    "Game show sound",
    "Bumper blemish"
  ],
  "ease": [
    "Simplicity",
    "Relaxation",
    "Simplicity",
    "Slip (into)",
    "Minimal effort",
    "\"At ___, soldier!\"",
    "Effortlessness",
    "With the greatest of ___",
    "Facility",
    "Simplicity",
    "Smooth sailing",
    "Facility",
    "Relieve",
    "Simplicity",
    "Facility",
    "Absence of complication",
    "Relaxation",
    "Simplicity",
    "Bed of roses, so to speak",
    "Well-being",
    "\"Darling, won't you ___ my worried mind\" (\"Layla\" lyric)",
    "Relaxation",
    "What a relaxed soldier is at",
    "Heart's-___ (pansy)",
    "Effortlessness",
    "Lessen",
    "___ of use",
    "Lack of problems",
    "Abate",
    "Reduce, as anxiety",
    "No worries",
    "Assuage",
    "Lack of pressure",
    "Alleviate",
    "Abate",
    "What pros handle things with",
    "\"He'd fly through the air with the greatest of ___\" (old song lyric)",
    "Let (up)",
    "Loosen",
    "Effortless quality",
    "Rest",
    "Freedom from anxiety",
    "Life of Riley",
    "Make less difficult",
    "Simplicity",
    "Leisure",
    "Stress-free state",
    "Lack of anxiety",
    "Bed of roses, so to speak",
    "Facilitate",
    "Maneuver with care",
    "Facility",
    "Facility",
    "Lessen, with \"off\"",
    "With 40-Down, enter gradually",
    "Rest",
    "Military order, after \"at\"",
    "No trouble",
    "Simplicity",
    "Comfort",
    "Maneuver carefully",
    "What you might solve a puzzle with",
    "Smooth, as the way",
    "Contentment",
    "Disburden",
    "What a soldier may be at?",
    "Lighten",
    "Relaxation",
    "Comfort",
    "Lack of difficulty",
    "Allay",
    "Palliate",
    "Life of Riley",
    "Quiet",
    "Life of ___",
    "Allay",
    "Minimal resistance",
    "Comfort",
    "Lack of hardships",
    "What Fred Astaire danced with",
    "Let up",
    "Comfort",
    "Allay",
    "Well-being",
    "Smooth",
    "Facility",
    "No pressure",
    "Life of Riley",
    "Lighten (up)",
    "Adroitness",
    "Let (up)",
    "Leisure",
    "Comfort",
    "Slip (into)",
    "\"___ On Down the Road\" (\"The Wiz\" song)",
    "Luxury",
    "What lotus-eaters enjoy",
    "Lessen, as difficulties",
    "Life of Riley",
    "Simplicity",
    "Luxury",
    "Lessen, as pain",
    "Fit by careful shifting",
    "Luxury",
    "Affluence",
    "Nonchalance",
    "Simplicity",
    "Slacken (off)",
    "Maneuver gingerly",
    "Slip (into)",
    "Simplicity",
    "Leisure",
    "Leisure",
    "Lighten, as a burden",
    "Maneuver gently",
    "Simplicity",
    "Peace of mind",
    "Peace of mind",
    "Relaxation",
    "Simplicity",
    "Simplicity",
    "Life of Riley",
    "Let (up)",
    "Rest",
    "Move gently",
    "Settle (into)",
    "Minimal effort",
    "Comfort",
    "Opposite of aggravate",
    "Complacency",
    "Allay",
    "Life of Riley",
    "Facility",
    "The life of Riley",
    "Life of ___",
    "Lighten, as a burden",
    "Comfort",
    "\"___ on Down the Road\"",
    "Simplicity",
    "Leisure",
    "Facility",
    "Relaxation",
    "Comfort",
    "\"___ on Down the Road\"",
    "Relieve",
    "Naturalness",
    "Life of Riley",
    "Let up",
    "Comfort",
    "It may be relative",
    "Lighten",
    "Slip (into)",
    "Leisure",
    "The good life",
    "Facility",
    "Unaffectedness",
    "Palliate",
    "Luxury",
    "Life of Riley",
    "Facility",
    "Let up",
    "Facilitate",
    "Shift very carefully",
    "Facilitate",
    "Facility",
    "Naturalness",
    "Handily, after \"with\"",
    "Moderate",
    "Relative affluence",
    "Simplicity",
    "Sufferer's desire",
    "Alleviate",
    "Simplicity",
    "Laid-back quality",
    "Facility",
    "Slacken",
    "Sugarcoat",
    "Naturalness",
    "Tranquillity",
    "Relieve",
    "Slide (into)",
    "Naturalness",
    "Facility",
    "Go gingerly",
    "Facility",
    "Prosperity",
    "Informality",
    "The good life",
    "Facility",
    "Let up",
    "Proficiency",
    "Peace",
    "Lighten",
    "Make smooth",
    "Let up",
    "Slip (into)",
    "Insouciance",
    "Moderate",
    "Relax, as one's grip",
    "Peacefulness",
    "Simplify",
    "\"At ___, soldier!\"",
    "Deftness",
    "Maneuver slowly",
    "Convenience",
    "Simplicity",
    "Relaxation",
    "Move carefully (into)",
    "Repose",
    "Cushiness",
    "The life of Riley",
    "Simplicity",
    "Smooth",
    "The life of Riley",
    "Simplicity",
    "Nonchalance",
    "Convenience",
    "Slip (into)",
    "Leisure",
    "Lighten (up)",
    "Relaxation",
    "Comfort",
    "Facility",
    "Fluency",
    "Facility",
    "Naturalness",
    "Deftness",
    "Relaxation",
    "Sybarite's delight",
    "Facility",
    "Relaxation",
    "Go gently (into)",
    "Deftness",
    "Dexterity",
    "Facility",
    "Let up",
    "Slip (into)",
    "Rest",
    "Naturalness",
    "Slacken",
    "Naturalness",
    "Liberty",
    "Facilitate",
    "Slip (into)",
    "Leisure",
    "Life of Riley",
    "Facilitate",
    "Life of Riley",
    "Grease, as one's way",
    "Facility",
    "Slip (into)",
    "Let up",
    "Facilitate",
    "Let (up)",
    "Lighten",
    "Comfort",
    "Fluidity",
    "Life of Riley",
    "Unaffectedness",
    "Proficiency",
    "Facility",
    "Effortlessness",
    "Lessen",
    "Lap of luxury",
    "Ill at ___",
    "Smooth",
    "Good life",
    "Let up",
    "Life of Riley",
    "Pave the way",
    "Leisure",
    "Facility",
    "Maneuver slowly",
    "Informality",
    "The life of Riley",
    "Lighten up"
  ],
  "aaas": [
    "Penlight batteries",
    "Some remote inserts",
    "Some batteries",
    "Some flashlight needs",
    "Some small batteries",
    "Some mouse cells",
    "Some digital camera batteries",
    "Small power sources",
    "Small cells",
    "Remote fillers, maybe",
    "Some batteries",
    "Some small batteries",
    "Some batteries",
    "Penlight batteries",
    "Some batteries",
    "Penlight batteries",
    "Some batteries",
    "Motorists' clubs, in brief"
  ],
  "cher": [
    "One-named pop diva",
    "\"Clueless\" protagonist",
    "One-named singer with a 1988 Best Actress Oscar",
    "One-named singer with the hit \"Dark Lady\"",
    "\"Moonstruck\" Oscar winner",
    "Singer featured in the 2018 film sequel to 37-Down",
    "Singer who was in 2018's \"Mamma Mia! Here We Go Again\"",
    "\"If I Could Turn Back Time\" singer, 1989",
    "Emmy, Grammy and Academy Award winner",
    "Her 2018 album \"Dancing Queen\" consists entirely of Abba covers",
    "So-called \"Goddess of Pop\"",
    "The Goddess of Pop",
    "One-named singer with #1 hits in 1965 and 1999",
    "Oscar winner with four #1 Billboard hits",
    "Female singer with a hit album in every decade since the 1960s",
    "Mother of Chaz Bono",
    "1987 Best Actress winner",
    "The Goddess of Pop",
    "\"Believe\" Grammy winner, 1999",
    "Singer who starred in \"Moonstruck\"",
    "The Goddess of Pop",
    "Sonny's old singing partner",
    "\"Burlesque\" co-star, 2010",
    "Oscar nominee for \"Silkwood\"",
    "Chaz Bono's mom",
    "\"Clueless\" protagonist",
    "Lead role in \"Clueless\"",
    "So-called \"Goddess of Pop\"",
    "\"Burlesque\" co-star, 2010",
    "Chaz's mother",
    "\"If I Could Turn Back Time\" singer, 1989",
    "Winner of an Emmy, a Grammy, an Oscar, and three Golden Globes",
    "Often-impersonated diva",
    "Oscar winner for \"Moonstruck\"",
    "Mother of Chaz Bono",
    "\"Believe\" singer, 1999",
    "Singer with a Best Actress Oscar",
    "What's expensive in Paris?",
    "Gregg Allman's wife who filed for divorce after nine days",
    "\"Mask\" star, 1985",
    "\"Moonstruck\" actress",
    "\"Bang Bang\" singer, 1966",
    "1970's variety show co-host",
    "Co-star of \"The Witches of Eastwick\"",
    "\"Suspect\" star, 1987",
    "\"Bang Bang\" singer, 1966",
    "\"A Song for the Lonely\" singer",
    "Singer with top 10 hits in the 1960's, 70's, 80's and 90's",
    "One-named singer/actress",
    "1987 self-titled million-selling album",
    "Dear, in a billet-doux",
    "Singer with the 1999 #1 hit \"Believe\"",
    "Sonny's partner, once",
    "One-named singer and Oscar winner",
    "\"Moonstruck\" actress",
    "\"Mask\" star",
    "\"Believe\" singer",
    "1987 Best Actress",
    "\"Moonstruck\" actress",
    "\"If I Could Turn Back Time\" singer",
    "Best Actress of 1987",
    "One-named singer/actress",
    "\"Moonstruck\" star",
    "Word in a billet-doux",
    "\"Mermaids\" actress",
    "The ex-Mrs. Bono",
    "\"Half-Breed\" singer",
    "Sonny's ex",
    "\"Clueless\" lead role",
    "The former Mrs. Bono",
    "\"Dark Lady\" singer",
    "1987 Best Actress",
    "\"Take Me Home\" singer",
    "Oscar winner as Loretta Castorini",
    "\"Gypsys, Tramps & Thieves\" singer",
    "\"If I Could Turn Back Time\" singer",
    "One-name star",
    "\"Dark Lady\" singer",
    "\"Mask\" star",
    "\"Dark Lady\" singer, 1974",
    "Oscar winner for \"Moonstruck\""
  ],
  "twas": [
    "\"___ the night before Christmas ...\"",
    "First word of \"A Visit From St. Nicholas\"",
    "\"___ brillig ...\"",
    "\"___ a rough night\": Macbeth",
    "First word of \"Jabberwocky\"",
    "First word of \"A Visit From St. Nicholas\"",
    "Contraction at the start of a sentence",
    "Contraction that starts \"Jabberwocky\"",
    "Start of a classic Christmas poem",
    "\"Love, when we met, ___ like two planets meeting\": Ella Wheeler Wilcox",
    "\"___ autumn, and a clear and placid day\": Wordsworth",
    "Holiday poem starter",
    "First word of \"Jabberwocky\"",
    "\"___ brillig ...\"",
    "\"___ the night before Christmas ...\"",
    "\"___ not to be\"",
    "Xmas poem opener",
    "\"___ the night before Christmas ...\"",
    "\"___ like a Maelstrom, with a notch\" (Emily Dickinson poem)",
    "\"Jabberwocky\" starter",
    "Start of a Clement Moore classic",
    "Start of \"A Visit From St. Nicholas\"",
    "\"___ Love &mdash; not me\": Dickinson",
    "\"___ the night before ...\"",
    "Christmas verse starter",
    "\"___ the night before Christmas ...\"",
    "First word of \"Jabberwocky\"",
    "\"___ wondrous pitiful\": \"Othello\"",
    "\"Jabberwocky\" start",
    "\"Jabberwocky\" opener",
    "Poetic contraction",
    "Holiday poem opener",
    "It's in the past",
    "\"Jabberwocky\" start",
    "\"Jabberwocky\" starter",
    "Start of a Christmas classic",
    "Moore verse opener",
    "\"___ the night before Christmas ...\"",
    "Christmas poem opener",
    "\"Jabberwocky\" start",
    "\"... ___ but the wind\": Byron",
    "\"Jabberwocky\" starter",
    "\"Jabberwocky\" starter",
    "Start of a Yuletide reading",
    "\"___ the night ...\"",
    "First word of \"Jabberwocky\"",
    "\"Jabberwocky\" opener",
    "First word of Carroll's \"Jabberwocky\"",
    "\"___ the night before ...\"",
    "\"Jabberwocky\" opener",
    "\"___ the night before ...\"",
    "\"___ the night before...\"",
    "\"___ the night before...\"",
    "Moore starter"
  ],
  "thou": [
    "Holier-than-___",
    "It's often seen beside art",
    "Grand",
    "G",
    "The old you?",
    "Second person in the Bible",
    "10 benjamins",
    "You, a long time ago",
    "Grand",
    "Holier-than-___",
    "You, in the Bible",
    "G",
    "Biblical pronoun",
    "G",
    "\"Wherefore art ___ Romeo?\"",
    "G",
    "Pronoun before \"shalt not\"",
    "G",
    "\"All hail, Macbeth, ___ shalt be king hereafter!\"",
    "G",
    "10 benjamins",
    "Biblical pronoun",
    "Grand",
    "What you used to be?",
    "Grand",
    "10 C-notes",
    "Grand",
    "Big chunk of moola",
    "One G",
    "Grand total?",
    "10 C-notes",
    "Second person appearing in the Bible",
    "10 C-notes",
    "Ten Commandments word",
    "Gee",
    "10 C-notes",
    "Big chunk of moola",
    "Grand",
    "Biblical pronoun",
    "Grand",
    "Friend's address",
    "G",
    "G",
    "Elizabethan pronoun",
    "\"Grand\"",
    "100 sawbucks",
    "10 C-notes",
    "10 C-notes",
    "Biblical pronoun",
    "10 C-notes",
    "Grand",
    "Ten C-notes",
    "Grand amount",
    "Commandment starter",
    "Commandment word",
    "What you used to be"
  ],
  "roof": [
    "Housetop",
    "Part of the mouth",
    "Mouth part",
    "Hit the ___",
    "Upper part of the mouth",
    "Where Santa lands",
    "Thatcher's creation",
    "Urban garden locale",
    "It may be slated",
    "Part of a house",
    "Landing spot for Santa",
    "Place for a dish",
    "Dome, e.g.",
    "Santa's landing spot",
    "Overhead expense?",
    "It's often slanted",
    "Where shingles go",
    "Landing place for Santa",
    "Santa's landing place",
    "Part of the mouth",
    "It may be raised",
    "It's over your head",
    "It's usually slanted",
    "Landing pad for Santa",
    "Parking spot for Santa",
    "You may hit it when you're angry",
    "Thatcher's place",
    "Shingle site",
    "It may be pitched",
    "Good vantage point",
    "It may be pitched",
    "It's over your head",
    "Mouth part",
    "Mouth part",
    "It might have the shakes",
    "Part of the mouth",
    "This may be tiled",
    "Rafter's locale",
    "Mouth part",
    "Heliport site, often",
    "Gambrel, for one"
  ],
  "reno": [
    "Nevada slots city",
    "Where Johnny Cash shot a man, in song",
    "First female U.S. attorney general",
    "City SW of Pyramid Lake",
    "Nevada city on the Truckee River",
    "Western city where copper-riveted jeans were invented",
    "\"911!\" preceder",
    "Western home of the National Automobile Museum",
    "Gambling mecca north of Carson City",
    "Eight-year member of Clinton's cabinet",
    "Seat of Washoe County",
    "Apt rhyme for \"casino\"",
    "City north of Washoe Lake State Park",
    "Nevada city",
    "Clinton's attorney general for all eight years",
    "City near Virginia City",
    "City SE of Plumas National Forest",
    "Ashcroft's predecessor as attorney general",
    "City near Sparks",
    "Home of minor-league baseball's Aces",
    "Major city bisected by I-80",
    "City near Tesla Gigafactory 1",
    "Home to the Triple-A Aces",
    "Sparks can be seen at its edge",
    "Nevada casino city",
    "Gambling locale",
    "Where Johnny Cash shot a man, in song",
    "City near the Sierras",
    "___ Sweeney, leading character in \"Anything Goes\"",
    "Setting of a 2000s Comedy Central police show",
    "Gambling mecca",
    "Former attorney general Janet",
    "City north of Carson City",
    "Apt rhyme for \"casino\"",
    "City near the California/Nevada border",
    "Home of Triple-A baseball's Aces",
    "City at the foot of the Sierra Nevada",
    "Western city bisected by I-80",
    "City near Pyramid Lake",
    "Eight-year Clinton cabinet member",
    "Late attorney general Janet",
    "Nevada's so-called \"Biggest Little City in the World\"",
    "Former attorney general Janet",
    "City whose name, appropriately, rhymes with \"casino\"",
    "Cabinet member who once appeared beside her impersonator on \"S.N.L.\"",
    "City at the foot of the Sierra Nevada",
    "Seat of Washoe County",
    "Attorney general parodied by Ferrell",
    "Virginia and Truckee Railroad terminus",
    "City near Sparks",
    "City near Lake Tahoe",
    "Eight-year member of Clinton's cabinet",
    "So-called \"Biggest Little City in the World\"",
    "Ashcroft's predecessor in the cabinet",
    "Nevada city",
    "Role for Ferrell on \"S.N.L.\"",
    "Member of Clinton's cabinet for all eight years",
    "Home of the University of Nevada",
    "Subject of a Will Ferrell \"S.N.L.\" impersonation",
    "Gambling mecca",
    "Place to place your bets",
    "City mentioned in \"Folsom Prison Blues\"",
    "Clinton attorney general Janet",
    "City NNE of Tahoe",
    "Stop on Amtrak's California Zephyr",
    "Terminus of the old Virginia and Truckee Railroad",
    "Nevada gambling mecca",
    "City near Pyramid Lake",
    "City with Aces Ballpark",
    "City near Virginia City",
    "Silver State city",
    "City near Lake Tahoe",
    "Where Harrah's started",
    "Gambling mecca",
    "Nevada city near Lake Tahoe",
    "City east of the Sierra Nevada",
    "City NNE of Tahoe",
    "Setting for the movie \"Sister Act\"",
    "\"___ 911!\" (former Comedy Central show)",
    "City near old silver mines",
    "Nevada gambling mecca",
    "See 46-Across",
    "Ashcroft's predecessor",
    "Western locale called the Biggest Little City in the World",
    "\"___ 911!\" (comedy series)",
    "Western gambling mecca",
    "The Biggest Little City in the World",
    "Gambling mecca",
    "Slots spot",
    "Only female attorney general",
    "Where \"I shot a man\" in Johnny Cash's \"Folsom Prison Blues\"",
    "Clinton cabinet member satirized by Will Ferrell",
    "Clinton's attorney general",
    "Clinton cabinet member",
    "Comedy Central's \"___ 911!\"",
    "City near Lake Tahoe",
    "Silver State city",
    "Nevada city",
    "Seat of Washoe County, Nev.",
    "Gambling mecca",
    "Splitsville?",
    "Janet of the Clinton cabinet",
    "City near Carson City",
    "Frequent target of old Ferrell \"S.N.L.\" skits",
    "City near Tahoe",
    "City named for a Civil War general",
    "Former attorney general Janet",
    "Civil War general Jesse",
    "Clinton's attorney general",
    "City surrounded on two sides by Toiyabe National Forest",
    "Home of the National Automobile Museum",
    "Setting for betting",
    "City for a quickie marriage or divorce",
    "Gambling mecca",
    "Western vacation destination",
    "Ex-attorney general",
    "Clinton's attorney general",
    "Spot for slots",
    "Splitting point",
    "It?Æs north of Carson City",
    "Where to get hitched in a hurry",
    "Former Attorney General",
    "Splitsville",
    "Divorce mecca",
    "Ashcroft's predecessor",
    "Ashcroft's predecessor",
    "Hitching place",
    "City named for a Union general",
    "University of Nevada locale",
    "Janet in the Clinton White House",
    "Gambler's destination",
    "It has many hitches",
    "Where people arrive to split",
    "Elopers' destination, maybe",
    "Get-hitched-quick spot",
    "Nevada city",
    "Attorney general from Miami",
    "Ashcroft's predecessor at Justice",
    "City at the foot of the Sierra Nevada",
    "Clinton cabinet member",
    "City near Sparks",
    "Gambling locale",
    "Betting setting",
    "Union city?",
    "Where people split",
    "Nevada city",
    "One-time divorce mecca",
    "First female U.S. Attorney General",
    "City on the Truckee",
    "City near Sparks",
    "City near Mt. Rose ski area",
    "Washoe County seat",
    "City north of Carson City",
    "It has many slots",
    "Tahoe gateway",
    "Clinton Cabinet member",
    "City near Virginia City",
    "It's NE of Tahoe",
    "Separation station?",
    "Place to play blackjack",
    "Atty. Gen. Janet",
    "City at the foot of the Sierra Nevadas",
    "Better place?",
    "City NE of Tahoe",
    "Clinton Cabinet member",
    "Gambling mecca",
    "Harrah's locale",
    "Snap-marriage locale",
    "Civil War major general Jesse",
    "Western city named for a Civil War general",
    "Clinton Attorney General",
    "Janet of Justice",
    "Gamblers' mecca",
    "MGM Grand site",
    "Attorney General Janet",
    "Vegas rival",
    "Janet of the Justice Department",
    "Seat of Washoe County",
    "Gambler's mecca",
    "Slots site",
    "Site of the MGM Grand",
    "Attorney General Janet",
    "Seat of Washoe County",
    "Cabinet member Janet",
    "City named for a Civil War general",
    "America West destination",
    "MGM Grand site",
    "Janet of Justice",
    "Clinton Cabinet member",
    "The Battle of Little Bighorn major",
    "Justice chief",
    "Civil War general Jesse",
    "City near Tahoe",
    "Spark's sister city",
    "Barr's Cabinet successor",
    "Escalator inventor Jesse",
    "City near Sparks",
    "Nevada city",
    "Gambling haven",
    "City near Sun Valley",
    "Where exes are made",
    "Clinton Cabinet member",
    "City west of Sparks",
    "Janet of Justice"
  ],
  "bloc": [
    "Voting group",
    "Voting group",
    "Group of voters",
    "Coalition",
    "Group that votes together",
    "Like-minded voters",
    "Coalition",
    "Like-minded voting group",
    "Voting coalition",
    "Congress's Freedom Caucus, e.g.",
    "Tea Party, e.g.",
    "Freedom Caucus, e.g.",
    "OPEC, e.g.",
    "Tea Partiers in Congress, e.g.",
    "Group of like-minded voters",
    "Like-minded voters",
    "Trading group, e.g.",
    "League of nations",
    "Progressives, e.g.",
    "Blue Dog Democrats, e.g.",
    "Tea Partiers, e.g.",
    "Alliance",
    "Tea Party, e.g.",
    "Soviet ___",
    "Voting group",
    "Eastern ___",
    "Like-minded group of voters",
    "Voting alliance",
    "Voting alliance",
    "Political coalition",
    "Group of voters",
    "Soviet ___",
    "Tea Partiers, in Congress, e.g.",
    "Faction",
    "Political grouping",
    "Political coalition",
    "___ Quebecois (political party)",
    "Congressional Black Caucus, e.g.",
    "Voting group",
    "Soviet ___",
    "Voting coalition",
    "Voting group",
    "Voting group",
    "Solid South, once",
    "Like-minded individuals",
    "Allied group",
    "Alliance",
    "Group voting the same way",
    "Legislative group",
    "Convention group",
    "United group",
    "Voting group",
    "Convention group",
    "Representatives from farm states, e.g.",
    "Nonaligned nations, e.g.",
    "Black Caucus, e.g.",
    "Alliance",
    "Group",
    "Voting group",
    "Union",
    "Alliance",
    "Conservatives or liberals, say, in the Senate",
    "Common-interest group",
    "Group, as of votes",
    "Group",
    "Cartel",
    "Coalition",
    "Voting group",
    "United nations",
    "Cartel",
    "Voting group",
    "Voting group",
    "Alliance",
    "Political grouping",
    "Coalition",
    "Group that votes alike",
    "Congressional caucus"
  ],
  "hahs": [
    "Disbelieving laughs",
    "Interjections akin to \"Yeah, su-u-ure!\"",
    "Derisive interjections",
    "Lots of laughs",
    "Derisive laughs",
    "Derisive laughs",
    "Gloaters' cries",
    "Triumphant cries",
    "Cries of triumph",
    "Doubter's outbursts"
  ],
  "atit": [
    "\"Look ___ this way ...\"",
    "Squabbling",
    "Fighting",
    "Beavering away",
    "Butting heads",
    "Working hard",
    "Working away",
    "Working diligently",
    "Going ___ (bickering)",
    "In a row",
    "Plugging away",
    "Squabbling",
    "Toiling away",
    "Quarreling",
    "Feuding",
    "Going ___ (fighting)",
    "Quarreling",
    "Squabbling",
    "Hard ___ (toiling away)",
    "Fighting",
    "Grinding away",
    "Working hard",
    "Hard ___",
    "Warring",
    "Working hard",
    "Going ___ (fighting)",
    "Feuding",
    "Having a scrap",
    "Bickering",
    "Going ___ (battling)",
    "Having a scrap",
    "Quarreling",
    "In a row",
    "Working hard",
    "Engaged in an activity",
    "Going ___ (fighting)",
    "Having a row",
    "Toiling away",
    "Bickering",
    "Going ___ (bickering)",
    "Going ___",
    "Going ___ (bickering)",
    "On task",
    "On the job",
    "Going ___",
    "Warring, say",
    "In a shouting match, perhaps",
    "Going ___ (fighting)",
    "Having a tiff",
    "Bickering",
    "Persevering, say",
    "Going ___ tooth and nail",
    "Causing trouble",
    "Going ___",
    "Vigorously pursuing something",
    "Grinding away",
    "Bickering",
    "Busy",
    "Engaged",
    "Squabbling",
    "Bickering",
    "Bickering",
    "Plugging away",
    "Haggling",
    "Working hard",
    "\"While you're ___ ...\"",
    "Going ___ tooth and nail",
    "Have ___",
    "Engaged in a 38-Down",
    "Toiling away",
    "Having words",
    "Going ___ (bickering)",
    "In a row",
    "Arguing loudly",
    "Scuffling",
    "Words after hard or keep",
    "Squabbling",
    "Bickering",
    "Squabbling",
    "Buckling down",
    "Going ___ (fighting)",
    "Scuffling",
    "Slaving away",
    "Going ___ (bickering)",
    "Going ___",
    "Fighting",
    "Going ___ (fighting)",
    "Slaving away",
    "Fighting",
    "Having words",
    "Hard ___",
    "Busting one's back",
    "Keep ___ (persist)",
    "Keep ___ (persevere)",
    "Working away",
    "Bickering",
    "Go ___ (tussle)",
    "Keep ___ (persist)",
    "Fighting, often with \"again\"",
    "Having one's nose to the grindstone",
    "Going ___",
    "Feuding",
    "Toiling away",
    "Battling",
    "Wrangling",
    "Bickering",
    "Keep ___ (persevere)",
    "Going ___ tooth and nail",
    "Working away",
    "Engaged",
    "Fighting",
    "Squabbling",
    "Slaving away",
    "Busy on a job",
    "Going ___",
    "Get ___ (start work)",
    "Quarreling",
    "Plugging away",
    "Going strong",
    "Going ___",
    "Fighting",
    "Plugging away",
    "Going ___ (fighting)",
    "Buckling down",
    "Arguing",
    "Fighting",
    "Bickering, say",
    "Going strong",
    "Squabbling",
    "Toiling",
    "On the job",
    "Fighting",
    "Fighting",
    "Working hard",
    "Spatting",
    "Arguing",
    "Bickering",
    "Working hard",
    "Busy",
    "Busy",
    "Working",
    "Very busy",
    "Plugging away",
    "Toiling",
    "Busy",
    "Working",
    "Working hard",
    "Working hard",
    "Working tirelessly",
    "Persevering",
    "Engaged in a particular activity",
    "Battling",
    "Working",
    "Keep ___ (persevere)",
    "Working",
    "Keep ___ (persevere)",
    "Busy",
    "Persevering",
    "Working away",
    "Working hard"
  ],
  "otoe": [
    "Siouan people",
    "Great Plains tribe",
    "Nebraska native",
    "Great Plains people",
    "Tribe that lent its name to a Nebraska county",
    "Omaha relative",
    "Native speakers of Chiwere",
    "Onetime buffalo-hunting tribe",
    "Nebraska county named for an indigenous people",
    "Neighbors of the Omaha",
    "Great Plains tribe",
    "Great Plains tribe",
    "Nebraska native tribe",
    "Siouan people",
    "Tribe at Council Bluff",
    "Tribe encountered by Lewis and Clark",
    "Early tribe met by Lewis and Clark",
    "Plains Indian",
    "Plains tribe",
    "Missouri River native",
    "Great Plains tribe",
    "Western tribe",
    "Some Great Plains residents",
    "Indian tribe that lent its name to a county in Nebraska",
    "Great Plains tribe",
    "Winnebago relative",
    "Plains Indian",
    "Chiwere-speaking tribe",
    "Platte tribesman",
    "Nebraska county or who once lived there",
    "Red Rock dweller",
    "Siouan speaker",
    "Historical buffalo hunter",
    "Platte Valley native",
    "Oklahoma tribe",
    "Plains tribe",
    "Plains tribe",
    "Plains tribe",
    "Siouan tribesman",
    "Siouan speaker",
    "Siouan tribe",
    "Winnebago relative",
    "Great Plains tribe",
    "Siouan tribe",
    "Plains native",
    "Great Plains tribe",
    "Oklahoma Indian",
    "Tribe encountered by Lewis and Clark",
    "Western tribe",
    "Midwest native",
    "Oklahoma tribe",
    "Nebraska tribe",
    "Nebraska county whose seat is Nebraska City",
    "Chiwere speaker",
    "Plains Indian",
    "Plains people",
    "Missouri relatives",
    "Plains native",
    "Old buffalo hunters of the Great Plains",
    "First tribe met by Lewis and Clark",
    "Winnebago relative",
    "Tribe allied with the Missouri, once",
    "Missouri River native",
    "Western tribe",
    "Plains Indians",
    "Plains Indian",
    "Old buffalo hunter",
    "First Indian tribe met by Lewis and Clark",
    "Plains Indian",
    "Tribe met by Lewis and Clark",
    "Oklahoma tribe",
    "Siouan speaker",
    "Tribe speaking Chiwere",
    "Siouan speaker",
    "Nebraska City's county",
    "Nebraska county with an Indian name",
    "Chief Whitehorse, e.g.",
    "Oklahoma tribe",
    "Midwest native",
    "Siouan speaker",
    "Siouan tribe",
    "Western native",
    "Western Indian",
    "Early Nebraskan",
    "Offshoot of the Winnebago tribe",
    "Nebraska City's county",
    "Plains native",
    "Cornhusker State tribe",
    "Cousin of a Winnebago",
    "Oklahoma native",
    "Little Thief, for one",
    "15-Down tribe",
    "Iowa kin",
    "Tribe related to the Missouria",
    "Oklahoma people",
    "Nebraska native",
    "Siouan speaker",
    "Plains tribe",
    "Siouan tribe",
    "Nebraska City is its county seat",
    "Ally of the Missouri, once",
    "Native Nebraskan",
    "Relative of a Winnebago",
    "Western tribe",
    "Oklahoma Indian",
    "Plains tribe",
    "Nebraska tribe",
    "Native Nebraskan",
    "Plains dweller",
    "Nebraska county whose seat is Nebraska City",
    "Siouan people",
    "Siouan tribe",
    "Missouri River native",
    "Siouan speaker",
    "Platte River Indian",
    "Some Sooners",
    "Oklahoma Indian",
    "Language from which \"Nebraska\" comes",
    "Missouri's ally, once",
    "Oklahoma Indian",
    "Siouan tribe",
    "Oklahoma tribe",
    "Missouri River tribe",
    "Nebraska City's county",
    "Native Oklahoman",
    "Siouan speaker",
    "Ally of the Missouri",
    "Western Indian",
    "Missouri River tribe",
    "Missouri's ally, once",
    "Western Indian",
    "Missouri Indian relative",
    "Siouan speaker",
    "Siouan speaker",
    "Plains Indian",
    "Siouan speaker",
    "Nebraska City's county",
    "Indian related to the Missouri",
    "Siouan speaker",
    "Member of the Winnebago nation",
    "Missouri Valley tribesman",
    "Oklahoma Indian",
    "Siouan speaker",
    "Oklahoma native",
    "Plains Indian",
    "Indian tribe",
    "Siouan Indian",
    "Siouan tribe",
    "Part of the Winnebago nation",
    "Chief Whitehorse, for one",
    "Plains Indian",
    "Chief Big Kansas, for one",
    "Tribe in the Winnebago nation",
    "Nebraska tribesman",
    "Western Indian",
    "Siouan Indian",
    "Western Indian",
    "Western Indian",
    "Native Oklahoman",
    "Sioux Indian",
    "Midwestern tribe",
    "Tribe in the Winnebago nation",
    "Plains Indian",
    "Oklahoma tribesman"
  ],
  "hymn": [
    "\"Amazing Grace,\" for one",
    "Music for the masses?",
    "Song sung on Sunday",
    "\"All Glory, Laud and Honor,\" e.g.",
    "Service lines?",
    "Canticle",
    "\"Day Is Dying in the West,\" for one",
    "Song of praise",
    "It may precede a 48-Across",
    "William Cowper composition",
    "15-Across, e.g.",
    "\"My Faith Looks Up to Thee,\" e.g.",
    "Service break?",
    "\"Amazing Grace,\" e.g."
  ],
  "stro": [
    "Houston A.L.er",
    "Houston M.L.B.'er",
    "2017 World Series winner, for short",
    "Minute Maid Park player, informally",
    "Minute Maid Park player, for short",
    "Houston player, for short",
    "Houston pro, informally",
    "Houston ballplayer, in sports shorthand"
  ],
  "hags": [
    "Macbeth trio",
    "Storied cauldron stirrers",
    "Witches",
    "Storied pot stirrers",
    "Trio in \"Macbeth\"",
    "Witches",
    "Fairy tale cacklers",
    "Old crones",
    "Some Halloween costumes",
    "Fairy tale figures",
    "Gorgons",
    "Some fairy story villains",
    "Crones",
    "Long-haired uglies",
    "Crones",
    "Crones",
    "Storybook baddies",
    "Gorgons",
    "Crones",
    "Frights to look at",
    "Cauldron stirrers, maybe",
    "Frightful women",
    "Coven participants",
    "Crones",
    "Hardly beauty queens",
    "Crones",
    "Fairy tale figures",
    "Harridans",
    "\"Macbeth\" trio",
    "Witches",
    "Halloween figures"
  ],
  "neat": [
    "Fastidious",
    "Tidy",
    "\"Good stuff!\"",
    "\"Gee, that's swell!\"",
    "Well-kept",
    "Tidy",
    "Straight up",
    "Tidy",
    "Dandy",
    "Tidy",
    "Straight up",
    "Tidy",
    "Peachy-keen",
    "\"Swell!\"",
    "\"Keen!\"",
    "Having everything in its proper place",
    "All tidied up",
    "Like some servings of Scotch",
    "Without ice, at a bar",
    "\"Cool beans!\"",
    "Undiluted",
    "Well-organized",
    "Tidy",
    "\"Awesome!\"",
    "How whiskey is often served",
    "\"That's quite a trick!\"",
    "Tidy",
    "Trim",
    "Without ice, at the bar",
    "Tidy",
    "In apple-pie order",
    "In apple-pie order",
    "In order",
    "\"Cool!\"",
    "Trim",
    "Orderly",
    "Peachy",
    "Cool",
    "\"Ooooh!\"",
    "Keen",
    "Without a mixer",
    "Cool",
    "Nifty",
    "\"Gnarly!\"",
    "Undiluted",
    "Ordered",
    "\"Super!\"",
    "Cool",
    "Nifty",
    "Like Felix, but not Oscar",
    "In order",
    "Straight, at a bar",
    "Tidy",
    "\"Way cool!\"",
    "Nifty",
    "Ingenious",
    "\"Cool\"",
    "Peachy",
    "Spruce",
    "\"That's keen!\"",
    "Smart",
    "In order",
    "Tidy",
    "Well-kept",
    "\"Super!\"",
    "\"Cool beans!\"",
    "Without ice, as a drink",
    "Orderly",
    "Having everything in its place",
    "Swell",
    "Having everything arranged just so",
    "All lined up",
    "___ as a pin",
    "Spick-and-span",
    "Free of clutter",
    "Well-executed",
    "\"Very cool!\"",
    "Bar request",
    "Tidy",
    "Sans ice",
    "Shipshape",
    "Spiffy",
    "Straight up",
    "Spiffy",
    "Carefully arranged",
    "Groomed",
    "Drink preference",
    "Well-planned",
    "\"Swell!\"",
    "Bourbon order",
    "Tidy",
    "Well-executed",
    "Sans ice",
    "In apple-pie order",
    "In order",
    "\"Dandy!\"",
    "Fastidious",
    "Trim",
    "Straight, or straightened",
    "In order",
    "Shipshape",
    "Bar preference",
    "Without ice",
    "Not having a thing out of place",
    "Straight",
    "Spick and span",
    "Cool",
    "Straight up",
    "How Scotch may be served",
    "Straight, at the bar",
    "Well-groomed",
    "\"Cool\"",
    "Without ice",
    "Spick-and-span",
    "\"Snazzy!\"",
    "In order",
    "Well-executed",
    "Orderly",
    "Nifty",
    "Spiffy",
    "Natty",
    "Trim",
    "Straight",
    "Shipshape",
    "Nifty",
    "Marvy",
    "In apple-pie order",
    "Without ice",
    "Straight up or straightened up",
    "Like some drinks",
    "\"Cool!\"",
    "Well-groomed",
    "Like some drink orders",
    "Shipshape",
    "\"Cool!\"",
    "Like some drinks",
    "Not having a hair out of place",
    "Sans ice",
    "Without ice",
    "Like some drinks",
    "Methodical",
    "In apple-pie order",
    "Straight up",
    "Tidy",
    "Nifty",
    "\"Cool!\"",
    "\"Cool!\"",
    "Tidy",
    "Tidy",
    "Keen",
    "Straight, at the bar",
    "Undiluted, at the bar",
    "Sans ice",
    "Bar request",
    "Undiluted",
    "Spruce",
    "Spiffy",
    "Full-strength, in a way",
    "In order",
    "Shipshape",
    "Straight",
    "Undiluted",
    "Orderly",
    "\"Capital!\"",
    "Not on the rocks",
    "Spiffy",
    "Sans mixers",
    "Uncluttered",
    "Jim-dandy",
    "Peachy-keen",
    "In order",
    "Tidy",
    "In apple-pie order",
    "Devoid of rocks?",
    "Smart",
    "Spiffy",
    "Sans mixers",
    "First-rate",
    "Pinlike",
    "Spiffy",
    "Straight",
    "Undiluted",
    "Undiluted",
    "Sans mixers",
    "One way to order whiskey",
    "Litter-free",
    "Marvy",
    "Peachy-keen",
    "Straight",
    "Trig",
    "Adroit",
    "Without water",
    "Well-groomed",
    "Tidy",
    "Spruce",
    "Pinlike",
    "Unmixed, at a mixer",
    "Kempt",
    "Unmixed, as a drink",
    "Groomed",
    "\"Cool\"",
    "Not on the rocks"
  ],
  "shui": [
    "Feng ___",
    "Feng ___",
    "Feng ___ (harmonizing philosophy)",
    "Feng ___",
    "Feng ___ (New Age concern)",
    "Feng ___",
    "Chinese for \"water\""
  ],
  "mess": [
    "Pickle",
    "Can of worms",
    "Opposite of 25-Down",
    "Snafu",
    "Target of a cleanup",
    "Soldiers' meal",
    "Children's playroom, often",
    "State of confusion",
    "Shambles",
    "Filthy state",
    "Imbroglio",
    "Cleanup target",
    "Slob's creation",
    "\"Don't ___ with Texas\"",
    "Word after hot or before kit",
    "Predicament",
    "Pigsty",
    "Fiddle (with)",
    "Unenviable situation",
    "Snafu",
    "Eyesore",
    "Snafu",
    "Predicament",
    "Wreck",
    "Jam",
    "Pigsty",
    "Clutter",
    "Whole bunch",
    "Fool (with)",
    "Base fare",
    "Meal in the military",
    "Child's room, often",
    "What a slob makes",
    "Complete foul-up",
    "What a slob leaves",
    "Waste time",
    "Hoarder's problem",
    "Job for a cleanup crew",
    "Pigsty",
    "Ugly situation",
    "Meddle (with)",
    "Quagmire",
    "Sustenance for a fatigued person?",
    "Big job for a housekeeper",
    "Boot camp meal",
    "Meal on a military base",
    "Teen's room, stereotypically",
    "Putter (around)",
    "Quagmire",
    "Play (with)",
    "Company dinner",
    "Where the crew chows down",
    "Cafeteria, to a soldier",
    "Post meal",
    "Part of a camp schedule",
    "Result of a ransacking",
    "Mishmash",
    "Snafu",
    "Soldiers' meal",
    "Military meal",
    "Untidiness",
    "Imbroglio",
    "Many a teenager's room",
    "___ hall",
    "Many an attic",
    "Event in a hall",
    "Predicament",
    "Clutter",
    "Boot camp fare",
    "Camp vittles",
    "Confusion",
    "Clutter",
    "Many a teen's room",
    "Jumble",
    "Military meal",
    "Leathernecks' lunch",
    "Private dining room?",
    "Scrape",
    "Military lunchroom",
    "Bungle, with \"up\"",
    "Clutter",
    "Word before call or hall",
    "Chaos",
    "With 23-Across, where campers eat",
    "Quantico cuisine",
    "Clutter",
    "Fix",
    "Mare's-nest",
    "Snafu",
    "Imbroglio",
    "Dinner at boot camp",
    "Clutter",
    "Meal eaten in a hall",
    "Shambles",
    "Dump",
    "Disorder",
    "Mare's-nest",
    "Meal at boot camp",
    "Bollix (up)",
    "Disarray",
    "Jumble",
    "Army chow",
    "Marine's meal",
    "Grunt's chow",
    "Shambles",
    "Foul (up)",
    "Clutter",
    "Imbroglio",
    "Bollix (up)",
    "Muddle",
    "Group meal",
    "Dining hall",
    "Hodgepodge",
    "Botch",
    "Plight"
  ],
  "olga": [
    "Olympic gold-medal gymnast Korbut",
    "Olympian Korbut",
    "Gymnast Korbut",
    "Woman's name that anagrams to GOAL",
    "One of the sisters in Chekhov's \"Three Sisters\"",
    "2018 Literature Nobelist Tokarczuk",
    "One of the sisters in Chekhov's \"Three Sisters\"",
    "Eldest of Chekhov's \"Three Sisters\"",
    "___ Tokarczuk, 2018 Literature Nobelist",
    "Eldest sister in Chekhov's \"Three Sisters\"",
    "Gymnast Korbut",
    "Bra brand",
    "First saint of the Russian Orthodox Church",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "First wife of Pablo Picasso",
    "\"Three Sisters\" sister",
    "Eldest of Chekhov's \"Three Sisters\"",
    "2008 Bond girl Kurylenko",
    "___ Korbut, 1970s Olympic gymnastics star",
    "Batman villain known as \"Queen of the Cossacks\"",
    "Actress Kurylenko of \"Quantum of Solace\"",
    "Korbut who starred at the 1972 Olympics",
    "One of Chekhov's \"Sisters\"",
    "Sister of the grand duchess Anastasia",
    "Gymnast Korbut",
    "Chekhov \"Sister\"",
    "___ Korbut, 1972 Olympic gymnastics star",
    "Chekhovian sister of Masha and Irina",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Korbut on a balance beam",
    "Mrs. Vaclav Havel, the first first lady of the Czech Republic",
    "Pasternak mistress Ivinskaya",
    "One of Chekhov's \"three sisters\"",
    "___ Korbut, 1972 Olympic gymnastic sensation",
    "___ Korbut, 1972 Soviet gymnastics star",
    "Bond girl Kurylenko",
    "Kurylenko of \"Quantum of Solace\"",
    "Bond girl Kurylenko",
    "Sister in Chekhov's \"Three Sisters\"",
    "Korbut who was a sensation at the 1972 Olympics",
    "One of Chekhov's \"Three Sisters\"",
    "Gymnast Korbut",
    "Daughter of Czar Nicholas I or II",
    "Olympic gymnast Korbut",
    "Grammy-winning merengue singer Tanon",
    "\"Three Sisters\" sister",
    "Popular brand of lingerie",
    "Saint from Kiev",
    "Gymnast Korbut",
    "\"Eugene Onegin\" girl",
    "Gymnast Korbut",
    "\"Three Sisters\" sister",
    "One of Chekhov's \"Three Sisters\"",
    "Russian saint",
    "Russian name meaning \"holy\"",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "2000 Olympic hurdles gold medalist ___ Shishigina",
    "Gymnast Korbut",
    "Korbut of the 1972 Olympics",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Poet Broumas",
    "One of Chekhov's \"Three Sisters\"",
    "Actress Petrova, femme fatale of silents",
    "\"Three Sisters\" sister",
    "\"Eugene Onegin\" mezzo",
    "Mezzo Borodina",
    "Gold-medal gymnast Korbut",
    "One of Chekhov's Three Sisters",
    "One of Chekhov's Three Sisters",
    "\"Eugene Onegin\" mezzo",
    "One of Chekhov's Three Sisters",
    "Gymnast Korbut",
    "Russian saint",
    "Mezzo Borodina",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "One of Chekhov's three sisters",
    "Russian name meaning \"holy\"",
    "Gymnast Korbut",
    "Korbut on the beam",
    "\"Freaks\" star Baclanova",
    "First name at the '72 Olympics",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Masha and Irina's sister, in Chekhov",
    "First name in gymnastics",
    "Gymnast Korbut",
    "Marathoner Markova",
    "One of Chekhov's Three Sisters",
    "\"Eugene Onegin\" mezzo-soprano",
    "\"Eugene Onegin\" sister",
    "\"The Three Sisters\" sister",
    "Actress Baclanova of old films",
    "Ballerina Spessivtseva",
    "One of Chekhov's \"Three Sisters\"",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Ballerina Spessivtseva",
    "Gymnast Korbut",
    "Chekhov lady",
    "\"Eugen Onegin\" girl",
    "Gymnast Korbut",
    "Gymnast Korbut",
    "Ballerina Spessivtseva"
  ],
  "roan": [
    "Horse of a different color",
    "Bay ___ (certain horse)",
    "Speckled coat",
    "Equine color",
    "Speckled horse",
    "Speckled, say",
    "Dappled horse",
    "Showing a bit of gray hair, say",
    "Horse with a reddish coat",
    "Speckled horse",
    "Horse color",
    "Horse of a certain color",
    "Dappled horse",
    "Coat color",
    "Leather often treated to look like morocco",
    "Horse whose coat is sprinkled with white hairs",
    "Reddish-brown",
    "Equine color",
    "Speckled steed",
    "Horse color",
    "Horse color",
    "Stable color",
    "Multihued horse",
    "Color similar to chestnut",
    "Horse color",
    "Color of a sprinkled coat",
    "Speckled horse",
    "Stable color",
    "Horse hue",
    "Speckled steed",
    "Horse of a certain color",
    "Horse with more than one color",
    "Horse color",
    "Coat color",
    "Horse color",
    "Gray-sprinkled horse",
    "Horse hue",
    "Equine color",
    "Alternative to chestnut",
    "Horse of a different color?",
    "Relative of a chestnut",
    "Like Hotspur's horse in \"King Henry IV, Part I\"",
    "Horse color",
    "Bookbinding leather",
    "Sheepskin leather for bookbinding",
    "A horse of a different color?",
    "Reddish-brown",
    "Moroccolike leather",
    "Equine color",
    "Equine color",
    "Speckled steed",
    "Horse ridden by Hotspur in \"King Henry IV, Part I\"",
    "Horse coloration",
    "Reddish-brown",
    "Sheepskin leather",
    "Bookbinding leather",
    "Horse's hue",
    "Horse coloring",
    "Kind of leather",
    "Stable shade",
    "Flecked horse",
    "Gray-spotted horse",
    "Horse color",
    "Mixed-with-white, as horsehair",
    "Some leather",
    "Horse color",
    "Stable color",
    "Horse of different colors",
    "Dappled horse",
    "Rust sprinkled with white",
    "Colorful mount",
    "Reddish shade",
    "Brown-and-white",
    "Horse color",
    "Moroccolike leather",
    "Equine color",
    "Distinctive horse",
    "Horse of a certain color",
    "Bookbinding leather",
    "Bookbinder's leather",
    "Horse of a certain color",
    "Horse color",
    "Horse color",
    "Imitation morocco",
    "Bookbinding leather",
    "Speckled horse",
    "Dark horse",
    "Equine shade",
    "Reddish-brown",
    "Horse with a gray-sprinkled coat",
    "Horse color",
    "Horse of a certain color",
    "Horse color",
    "Horse of a certain color",
    "Imitation morocco",
    "Bookbinding leather",
    "Speckled horse",
    "Like Robinson Jeffers's stallion",
    "Imitation morocco"
  ],
  "toto": [
    "Completely, after \"in\"",
    "Completely, after \"in\"",
    "Band with the 1983 #1 hit \"62-Across\"",
    "Dog in Oz",
    "Escapee from Miss Gulch's bicycle basket",
    "Revealer of the Wizard",
    "Altogether, after \"in\"",
    "Film pooch",
    "Dog on the yellow brick road",
    "Band with the 1983 #1 hit \"Africa\"",
    "Noted terrier in a 1939 film",
    "Completely, after \"in\"",
    "In ___ (entirely)",
    "Band that shares its name with a film canine",
    "Dorothy's dog",
    "Cowardly Lion harasser",
    "Completely, after \"in\"",
    "\"Africa\" band, 1982",
    "The \"him\" in the 1939 film line \"I won't let you take him!\"",
    "Oz visitor",
    "Pooch in Oz",
    "Film pooch",
    "Barker in a basket",
    "Film terrier",
    "Completely, after \"in\"",
    "Dog in Oz",
    "Dog that bit Miss Gulch",
    "Noted dark film star of the 1930s",
    "Entirely, after \"in\"",
    "In ___ (altogether)",
    "1930s film dog",
    "Kansas canine",
    "Fictional terrier",
    "Emerald City visitor",
    "Curtain puller of film",
    "Oz visitor",
    "Miss Gulch biter",
    "He was chased by the Cowardly Lion",
    "\"The Wizard of Oz\" dog",
    "Film pooch",
    "\"The Wizard of Oz\" pooch",
    "Dog who unveiled 14-Down",
    "Fictional dog from Kansas",
    "Oz visitor",
    "Noted movie terrier",
    "The Wizard of Oz's exposer",
    "Emerald City visitor",
    "1930's film dog",
    "Canine from Kansas",
    "Threatener of Miss Gulch",
    "Oz visitor",
    "Little Oz visitor",
    "Dog in Oz",
    "Film dog",
    "Wizard visitor",
    "Dog on the Yellow Brick Road",
    "Oz visitor",
    "Dog in Oz",
    "Screen pooch of 1939",
    "Oz visitor",
    "Dog in Oz",
    "In ___ (entirely)",
    "\"The Wizard of Oz\" dog",
    "Dorothy's dog",
    "Oz visitor",
    "Wind-borne pet",
    "Dog in Oz",
    "In ___ (not piecemeal)",
    "Fictional terrier",
    "1939 movie dog",
    "Wizard of Oz revealer",
    "Emerald City visitor",
    "Completely, after \"in\"",
    "Dog in Oz",
    "Movie dog",
    "Baum barker",
    "W. S. Gilbert's \"Princess ___\"",
    "Dog in Oz",
    "Kansas canine",
    "Group with Grammy's 1982 Record of the Year",
    "Canine from Kansas",
    "1939 movie canine",
    "The Wizard's unveiler",
    "Dog in Oz",
    "\"Hold the Line\" rock group",
    "Group with the 1983 #1 hit \"Africa\"",
    "Dog in Oz",
    "Oz visitor",
    "Dog in Oz",
    "Movie dog",
    "In ___ (completely)",
    "Baum dog",
    "Barker of filmdom",
    "Kansas pooch",
    "Movie pooch",
    "Emerald City visitor"
  ],
  "ocho": [
    "Diez menos dos",
    "Eight: Sp.",
    "Dos y dos y dos y dos",
    "Evening hour in Spain",
    "Number of sides on a PARE road sign",
    "Seis + dos",
    "Dos and dos and dos and dos",
    "Number of countries between los Estados Unidos y Colombia",
    "Calle ___, landmark street in Miami's Little Havana",
    "Tres + cinco",
    "Eight: Sp.",
    "Tres y cinco",
    "___ Rios, Jamaica",
    "Number above \"Operador\" on un telefono",
    "Tres + cinco",
    "Spanish eight",
    "Eight: Sp.",
    "Spanish eight",
    "37-Down, to Diego",
    "Dos x dos x dos",
    "The black ball in el juego de billar",
    "Cuatro y cuatro",
    "Diez menos dos",
    "Eight, in Acapulco",
    "Spanish eight",
    "Spanish skating figure",
    "___ Rios, Jamaica",
    "A power of dos",
    "___ Rios, Jamaica",
    "Cinco + tres",
    "___ Rios (Jamaican resort)",
    "A third of veinticuatro",
    "___ Rios, Jamaica",
    "El numero de agosto",
    "Dos cubed",
    "Cuatro times 42-Across",
    "Cuatro y cuatro",
    "___ Rios Bay",
    "Eight: Sp.",
    "Twice cuatro",
    "___ Rios, Jamaica",
    "Dos cubed",
    "Jorge's eight",
    "Eight, in Spain",
    "Number below cinco on un telefono",
    "Dos cubed",
    "Cinco y tres",
    "Twice cuatro",
    "Word from a Spanish count?",
    "___ Rios, Jamaica",
    "Cuatro + cuatro",
    "Number of sides on an \"alto\" sign",
    "___ Rios, Jamaica",
    "Cuatro y cuatro",
    "___ Rios (Jamaican resort)",
    "Half of dieciseis",
    "Cuatro doubled",
    "___ Rios, Jamaica",
    "Cuatro y cuatro",
    "Dos cubed",
    "Cuatro doubled",
    "Cuatro + cuatro",
    "Tijuana eight",
    "Cuatro y cuatro",
    "___ Rios (Jamaican resort)",
    "___ Rios, Jamaica"
  ],
  "urge": [
    "The munchies, e.g.",
    "Drive",
    "Push to do something",
    "Prod",
    "Push",
    "Something an ascetic might fight",
    "Strongly advocate",
    "It's hard to fight",
    "Impulse",
    "Prod",
    "Craving",
    "Push",
    "Advocate for",
    "Impulse",
    "Strong desire",
    "Desire",
    "Hankering",
    "Egg on",
    "Strong desire",
    "It's a feeling",
    "Desire",
    "Tell to \"Act now!,\" e.g.",
    "Desire",
    "Hankering",
    "Strong desire",
    "Impulse",
    "Advocate",
    "Yen",
    "Plead with, say",
    "Craving",
    "Goad",
    "Hankering",
    "Tell to \"Do it!\"",
    "Prod",
    "The munchies, e.g.",
    "Yen",
    "Spur (on)",
    "Hankering",
    "It might be sexual",
    "Itch",
    "Press",
    "Hankering",
    "Compel",
    "The munchies, e.g.",
    "Desire",
    "Encourage",
    "Press",
    "Impulse",
    "Impulse",
    "Wanderlust, e.g.",
    "Prevail upon",
    "Press",
    "Hankering",
    "Longing",
    "Hankering",
    "Compulsion",
    "Goad",
    "Push",
    "Instinctive desire",
    "Press",
    "Prod",
    "Longing",
    "Itch",
    "Persuade",
    "Desire",
    "Exhort",
    "Press",
    "What an addict fights",
    "Encourage",
    "Drive",
    "Feel the ___",
    "Egg on",
    "Impulse",
    "Craving",
    "Spur",
    "Press",
    "Yen",
    "Prod",
    "Impulse",
    "Impulse",
    "Egg on",
    "Press",
    "Addict's bugbear",
    "Itch",
    "Exhort",
    "The munchies, for one",
    "Press, as to vote",
    "Spur (on)",
    "Push for",
    "Adjure",
    "Impel",
    "Lean on",
    "Strongly advise",
    "Impel",
    "Craving",
    "Give a nudge, so to speak",
    "Drive",
    "Impulse",
    "Advocate",
    "Hankering",
    "Longing",
    "Twist the arm of",
    "Impulse",
    "Itch",
    "Push",
    "Prod",
    "Push",
    "Motivation",
    "Push for",
    "Press upon",
    "Press",
    "Press on",
    "Press",
    "Basic desire",
    "Desire",
    "Craving",
    "Goad",
    "Press",
    "Impel",
    "Craving",
    "Impulse",
    "Implore",
    "Press",
    "Drive",
    "See 46-Across",
    "Press",
    "Egg",
    "More than suggest",
    "Exhort",
    "It may be irresistible",
    "Press",
    "Yen",
    "Exhort",
    "Basic impulse",
    "Press",
    "Push",
    "More than suggest",
    "Impulse",
    "Recommend",
    "Itch",
    "Push",
    "Natural impulse",
    "Spur",
    "Press for",
    "Inner drive",
    "Feel the ___",
    "Plead for",
    "Importune",
    "Press",
    "Drive",
    "Encourage",
    "Yen",
    "Push",
    "Give a little push",
    "Itch",
    "Encourage",
    "Push",
    "Feel the ___",
    "Prod",
    "Exhort",
    "Prompt",
    "Impetus",
    "Coax",
    "Compulsion",
    "Exhort",
    "Driving force",
    "Counsel positively",
    "Goad",
    "Egg on",
    "Itch",
    "Egg on",
    "Spur",
    "Coax",
    "Push",
    "Egg on",
    "Press",
    "Press for",
    "Prod",
    "Impulse",
    "Push for",
    "Prod",
    "Goad",
    "Spur"
  ],
  "feet": [
    "Step two?",
    "Units of poetry",
    "What podophobia is the fear of",
    "Parts of the body that are slightly larger in the evening than morning",
    "Iambs and trochees",
    "Typical stocking stuffers",
    "Pedal pushers",
    "'",
    "Parts of a yard",
    "\"Dogs\"",
    "Dogs that take YOU for a walk?",
    "Pedal pushers",
    "Things found in clogs",
    "'",
    "Pedicure targets",
    "A limerick has 13",
    "Stocking stuffers",
    "Shuffled pair",
    "Parts of yards",
    "They have balls",
    "They may be metric ... or not",
    "Shuffled things",
    "\"Dogs\"",
    "Yard units",
    "Pedal pushers",
    "Some are corny",
    "Da-dah, da-dah, da-dah, poetically speaking",
    "Stocking stuffers",
    "Choreographers' concerns",
    "Some are corny",
    "Leg ends",
    "Paws",
    "Podiatry matters",
    "Pedal pushers",
    "Centipede's multitude",
    "They may be shuffled",
    "Pedal pushers",
    "They're found in yards",
    "Runners?",
    "Altimeter units",
    "Poetic measures",
    "Pedal pushers",
    "Podiatrists' concerns",
    "Podia",
    "They have arches",
    "\"___ don't fail me now!\"",
    "Yard sections",
    "lamb and dactyl",
    "Matter of meter"
  ],
  "sela": [
    "Actress Ward",
    "With 24-Across, Emmy winner for \"Once and Again\"",
    "Actress Ward",
    "Emmy-winning Ward",
    "Ward of \"CSI: NY\"",
    "Emmy-winning Ward",
    "Ward of cinema",
    "Ward with many awards",
    "Actress Ward",
    "Ward of \"The Fugitive\"",
    "Emmy winner Ward",
    "Actress Ward of \"Sisters\"",
    "Actress Ward",
    "Ward in pictures",
    "Ward of \"The Fugitive\"",
    "Actress Ward",
    "Ward of \"CSI: NY\"",
    "Actress Ward",
    "1987 Lionel Richie hit",
    "Actress Ward of \"CSI: NY\"",
    "Ward on a set",
    "Ward of \"CSI: NY\"",
    "Actress Ward",
    "Actress Ward",
    "Actress Ward",
    "Ward on a set",
    "Actress Ward",
    "Ward of \"Sisters\"",
    "One of Swoosie's co-stars on \"Sisters\"",
    "Ward of Hollywood",
    "Actress Ward",
    "Actress Ward",
    "Ward with awards",
    "Ward of \"The Fugitive,\" 1993",
    "Actress Ward",
    "Emmy winner Ward",
    "Emmy-winning Ward",
    "Emmy award-winning Ward",
    "Actress Ward",
    "Actress Ward",
    "Actress Ward",
    "Emmy-winning Ward",
    "Emmy-winning Ward",
    "Ward of \"Once and Again\"",
    "Actress Ward",
    "Ward awarded two Emmys",
    "Ward of \"The Fugitive,\" 1993",
    "Actress Ward",
    "Ward of \"Once and Again\"",
    "Ward on the set",
    "Ward of \"Sisters\"",
    "Ward of the screen",
    "Ward of Hollywood",
    "Ward of \"Once and Again\"",
    "Ward on a set",
    "Actress Ward",
    "Ward on TV",
    "Ward with an Emmy",
    "Actress Ward",
    "Ward on TV",
    "Ward of Mississippi",
    "Actress Ward",
    "Actress Ward",
    "Actress Ward",
    "Actress Ward",
    "Actress Ward",
    "Ward of the screen",
    "Actress Ward",
    "Ward of \"The Fugitive\"",
    "Ward of TV's \"Sisters\"",
    "Ward of \"The Fugitive\"",
    "Ward of \"Sisters\"",
    "Actress Ward",
    "Ward of \"My Fellow Americans\"",
    "Actress Ward",
    "Ward of Mississippi",
    "Actress Ward",
    "1994 Emmy-winner Ward",
    "Ward of \"Sisters\"",
    "Ward of TV's \"Sisters\"",
    "Actress Ward",
    "Actress Ward",
    "Actress Ward of \"Sisters\""
  ],
  "bead": [
    "Abacus piece",
    "Item strung on a necklace",
    "Sweat it",
    "Form moisture, in a way",
    "Droplet",
    "Bit of sweat",
    "Slider on an abacus",
    "Craft shop item",
    "Headdress decoration",
    "Droplet of sweat",
    "Item on a Native American moccasin",
    "Thing on a string",
    "Rosary part",
    "Bit of sweat",
    "One being strung along?",
    "Bit of sweat",
    "Necklace piece",
    "Droplet",
    "Bit of wampum",
    "You might sweat it out",
    "Moccasin decoration",
    "Moccasin decoration",
    "Moccasin adornment",
    "Rosary component",
    "See 1-Across",
    "Drop of sweat",
    "Primitive monetary unit",
    "Bit of sweat",
    "Decoration on an Indian moccasin",
    "Indian valuable",
    "Bit of sweat",
    "Neckwear piece",
    "Necklace ornament",
    "Item of wampum",
    "Droplet",
    "Trading unit, once",
    "Bit of sweat",
    "Droplet",
    "Bit of wampum",
    "Rifle peephole",
    "Bit of wampum"
  ],
  "etta": [
    "James who sang \"At Last\"",
    "___ Candy, best friend of Wonder Woman",
    "\"Tell Mama\" singer James",
    "James played by Beyoncé in a 2008 biopic",
    "Jazzy James",
    "James or Jones of jazz",
    "Singer James",
    "\"___ Is Betta Than Evvah!\" (1976 album)",
    "\"At Last\" singer James",
    "James of jazz",
    "Jazzy James",
    "First name in jazz",
    "Jazz's James",
    "Bluesy James",
    "James who sang \"At last, my love has come along\"",
    "Name that's an anagram of both 16- and 18-Across",
    "Jazz's James",
    "James who sang \"At Last\"",
    "Grammy-winning James",
    "___ Candy, friend of Wonder Woman",
    "Singer James",
    "James with a jazzy voice",
    "\"___ Is Betta Than Evvah!\" (1976 album)",
    "James in the Rock and Roll Hall of Fame",
    "James in both the Blues and Rock and Roll Halls of Fame",
    "Blues singer James",
    "James of jazz",
    "Grammy-winning James",
    "\"___ Kett\" (old comic strip)",
    "Jazz's James",
    "Suffix with oper-",
    "James who sang \"At Last\"",
    "James of jazz",
    "James who sang at the opening of the 1984 Summer Olympics",
    "James who sang \"At last, my love has come along ...\"",
    "Italian diminutive suffix",
    "\"At Last\" singer ___ James",
    "Place in a 1969 western",
    "James of jazz",
    "James who was nominated for a 1967 Grammy for \"Tell Mama\"",
    "___ Candy, Wonder Woman's best friend",
    "Diminutive Italian suffix",
    "___ James, 2008 Beyonce role",
    "Singer James",
    "James of R&B",
    "Grammy-winning James",
    "Singer James or Jones",
    "Jazzy James",
    "James who sang \"Good Rockin' Daddy\"",
    "Jazz legend ___ James",
    "Companion of Butch and Sundance",
    "James who sang \"At Last\"",
    "___ Place (Butch and Sundance companion)",
    "Kett of old comics",
    "Grammy-winning James",
    "Italian girl's name ending",
    "\"At Last\" singer James",
    "Italian suffix for \"small\"",
    "Grammy-winning James",
    "Jazz legend James",
    "___ Place, Butch Cassidy companion",
    "James of jazz",
    "James of jazz",
    "James portrayed by Beyonce",
    "First name in blues",
    "James who was portrayed by Beyonce",
    "Feminine name suffix",
    "___ James (Beyonce role)",
    "Jazzy James",
    "___ James, singer played by Beyonce",
    "Rock and Roll Hall-of-Famer James",
    "\"___, Red-Hot & Live\" (1982 blues album)",
    "___ Candy, \"Wonder Woman\" character",
    "Singer James",
    "\"At Last\" singer James",
    "Blues singer James",
    "Blues singer James",
    "1964 album \"___ James Rocks the House\"",
    "James of jazz",
    "\"At Last\" singer James",
    "\"Tell Mama\" singer James",
    "\"Pushover\" singer James",
    "\"___ Is Betta Than Evvah!\" (1976 album)",
    "Jazzy James or Jones",
    "Jazzy Jones",
    "Jazzy singer ___ James",
    "\"At Last\" singer James",
    "James who sang \"A Sunday Kind of Love\"",
    "\"At Last\" singer James",
    "\"At Last\" singer James",
    "Singer James",
    "Jazzy James",
    "Jazzy James",
    "Bluesy James",
    "Contralto James",
    "Moten who played Bess in Broadway's \"Porgy and Bess\"",
    "Jazzy James",
    "Blues musician Baker",
    "Bluesy James",
    "Singer James",
    "James of jazz",
    "Editorial cartoonist Hulme",
    "\"At Last\" singer James",
    "James who sang the ballad \"At Last\"",
    "Jazzy James",
    "Kett of old comics",
    "Editorial cartoonist Hulme",
    "\"Tell Mama\" singer James",
    "Feminine name ending",
    "Singer Jones",
    "Sundance Kid's lady",
    "Soul singer James",
    "Bluesy James",
    "Jazzy James",
    "Soul singer James",
    "Singer James",
    "Blues singer James",
    "With 32-Across, \"Tell Mama\" singer, 1967",
    "Place in a Robert Redford movie",
    "Grammy winner James",
    "Singer James",
    "Singer James",
    "Blues singer ___ James",
    "Place in a Robert Redford flick",
    "Jazz singer James",
    "Kett of the comics",
    "Place close to Sundance",
    "Jazz singer James",
    "Jazz singer James",
    "With 42-Down, old Paul Robinson comic",
    "Singer James or Jones",
    "James of blues",
    "Bluesy James",
    "Singer James",
    "Italian suffix meaning \"little\"",
    "Blues singer James",
    "Jazz singer ___ James",
    "James of jazz",
    "Place of fiction",
    "Singer James",
    "Blues singer James",
    "James of jazz",
    "Blues singer James",
    "Suffix with oper-",
    "Kett of old comics",
    "Sundance's gal",
    "Jazzy James",
    "Blues singer James",
    "Singer James",
    "Diminutive suffix",
    "\"___ Kett\"",
    "Singer ___ James",
    "R & B/jazz singer James",
    "Jazz singer Jones",
    "Henri feminizer",
    "Sundance's girlfriend",
    "James nicknamed \"Miss Peaches\"",
    "Italian suffix",
    "Blues singer James",
    "___ Kett of old comics",
    "Jazz singer James",
    "Place in the Old West",
    "Jazz singer James",
    "James of jazz",
    "Sundance Kid's girlfriend ___ Place",
    "Singer James",
    "Female name ending",
    "Singer James",
    "Singer James",
    "R.&B. singer James",
    "Sundance Kid's girlfriend",
    "Girl's name ending",
    "Songstress James",
    "R.&B./jazz singer James",
    "Miss Kett of old comics",
    "Jazz singer James",
    "James who sang \"Mystery Lady\"",
    "Singer James or Jones",
    "Jazz singer Jones",
    "Musical composition suffix",
    "___ Kett of early comics",
    "Kett of the comics",
    "Jazz singer ___ James",
    "Sundance's girl",
    "Ending with Henri",
    "Kett of old comics",
    "Kett of the comics",
    "Singer James",
    "Feminine name suffix",
    "___Kett of the comics",
    "Girl's name ending",
    "Singer James",
    "Sundance's heartthrob",
    "___Kett of old comics",
    "Singer James",
    "Kett of the comics",
    "Wonder Woman?Æs friend ___ Candy",
    "James of jazz",
    "Blues singer James",
    "Kett of old comics",
    "Sundance Kid's girl",
    "60's singer James",
    "R. & B. singer James",
    "Warbler James",
    "Sundance Kid's girlfriend ___ Place",
    "Singer James",
    "Ms. Kett of old comics",
    "Kett of old funnies",
    "Singer James"
  ],
  "scar": [
    "Disney villain voiced by Jeremy Irons",
    "Leave a mark",
    "Something commonly left in an operating room",
    "___ for life",
    "Leave damaged",
    "Permanent marker?",
    "Lasting impression",
    "Disney villain based on King Claudius",
    "What chickenpox can cause",
    "What might come with fencing?",
    "Surgery reminder",
    "Mark of a swordsman?",
    "Line from the past?",
    "Lasting mark",
    "23-Down villain",
    "Lasting mark",
    "Melee memento, maybe",
    "Lasting reminder",
    "Mark of a villain, maybe",
    "Disney villain voiced by Jeremy Irons",
    "Memorable line?",
    "Lasting, unpleasant memory",
    "What psychological trauma may leave",
    "Trauma reminder",
    "Bare place on the side of a mountain",
    "Facial feature of the Bond villain Ernst Blofeld",
    "\"The Lion King\" villain",
    "Lasting impression",
    "What a knife wound might leave",
    "___ for life",
    "Sign of past trauma",
    "Surgery memento",
    "Distinctive Harry Potter feature",
    "Reminder of surgery",
    "Closing line that stays with you?",
    "Arthroscopy reminder",
    "Harry Potter mark",
    "Kind of tissue",
    "Evidence of damage",
    "Appendectomy memento",
    "Mark for life",
    "Reminder of an injury",
    "Disney villain",
    "Surgery souvenir",
    "Cut line",
    "Memento from Zorro",
    "Traumatize",
    "Frankenstein's monster had one on his forehead",
    "Many a cut, eventually",
    "Battle reminder",
    "Reminder of a sort",
    "Bit of emotional damage",
    "Traumatize",
    "Memento of an old wound",
    "Reminder of an old wound",
    "Tattoo removal reminder",
    "\"The Lion King\" villain",
    "Protruding rock",
    "Accident reminder",
    "Emotionally damage",
    "Line of combat?",
    "Mark for life",
    "Leave a mark on",
    "Permanent reminder",
    "Cutting-edge development?",
    "Surgery souvenir",
    "Mark left from an injury",
    "\"The Lion King\" villain",
    "Cliff",
    "Remnant of a tattoo removal, maybe",
    "Mark permanently",
    "Injury reminder",
    "Memento of a knife fight",
    "Old punch line?",
    "Leave a mark on",
    "Lasting impression",
    "Surgery reminder",
    "Surgery aftermath",
    "Identifying mark",
    "Harry Potter's lightning bolt",
    "Identifying mark",
    "Lasting impression",
    "Mark for life",
    "Indication of previous damage",
    "Old nick",
    "Leave a permanent mark on",
    "Operation memento",
    "Harry Potter's lightning bolt, e.g.",
    "Mark for life",
    "Steep rocky eminence",
    "Cutting evidence",
    "Old battle line?",
    "Sign of injury",
    "Lasting impression",
    "Sign of damage",
    "Unwelcome mark",
    "Sign of stitches",
    "Lasting impression",
    "Kind of tissue",
    "Painful reminder",
    "Rocky outlook",
    "Bad mark",
    "Melee memento",
    "Uglify",
    "Battle memento",
    "Permanent marker?",
    "Mark for life",
    "Fight reminder",
    "Vet's pride, perhaps",
    "The lightning bolt on Harry Potter's forehead, e.g.",
    "Surgery souvenir",
    "Mark of Zorro?",
    "Kind of tissue",
    "Surgery result",
    "Slash mark?",
    "Reminder of a swordfight",
    "Mark for life",
    "Mark for life",
    "Mark for life",
    "Stitch souvenir",
    "Permanently affect for the worse",
    "Injury's aftermath",
    "Rocky cliff",
    "Mark left by Zorro?",
    "Duel souvenir",
    "Leave a mark on",
    "Melee memento",
    "Capone trademark",
    "Stigma",
    "Unwanted line",
    "Dueling memento, maybe",
    "Leave a mark on",
    "Slash mark?",
    "Lasting effect",
    "Wound reminder",
    "Sign of damage",
    "Lasting effect",
    "Lasting impression",
    "Old wound mark",
    "Mark left by Zorro?",
    "Reminder of a duel",
    "Badge of battle",
    "Traumatize",
    "Lasting impression?",
    "Cicatrix",
    "Capone feature",
    "Deface",
    "Deface",
    "Capone feature",
    "Vaccination reminder",
    "Rumble reminder",
    "Cicatrix",
    "Capone feature",
    "Surgery souvenir",
    "Deface",
    "Dueling memento",
    "Disfigure",
    "Traumatize",
    "Disfigure",
    "Cutting reminder?",
    "Wound reminder"
  ],
  "soda": [
    "Bar mixer",
    "Pop in the fridge",
    "Part of a combo meal",
    "Pop",
    "Vodka mixer",
    "Common mixer",
    "Float component",
    "It carries an added tax in Philadelphia and San Francisco",
    "Pop",
    "Pop",
    "Part of a McDonald's meal",
    "Pop",
    "Coke or 7Up",
    "Word after club, cream or caustic",
    "Club ___",
    "Gin fizz ingredient",
    "Word with club or cream",
    "Many a mixer",
    "Coke or 7Up",
    "Soft drink",
    "Slice, for example",
    "Mr. Pibb or Dr Pepper",
    "Drink at an ice cream shop",
    "Pop",
    "Cream ___",
    "Pizza party drink",
    "Common mixer",
    "Cream ___ (beverage)",
    "Mixer at a party",
    "It may come from a fountain",
    "Coke or Pepsi",
    "Six-pack purchase, maybe",
    "Coke or Pepsi",
    "Carbonated drink",
    "Club ___",
    "Tom Collins ingredient",
    "Purchase with popcorn",
    "Many a mixer",
    "Word before bread or water",
    "Word before or after lime",
    "7 Up or Pepsi",
    "Root beer, e.g.",
    "Baking ___",
    "Ingredient in an Americano",
    "Pop",
    "Fizzy drink",
    "Some call it \"pop\"",
    "Part of a fast-food combo",
    "Pop",
    "Pop",
    "Drink with a straw",
    "Root beer or ginger ale",
    "Bar mixer",
    "It gets flatter as it gets older",
    "Party mixer",
    "It's not good when it's flat",
    "What may appear after washing or baking",
    "Something that shouldn't be flat",
    "Fizz ingredient",
    "Pop",
    "Egg cream component",
    "Scotch's partner",
    "Common mixer",
    "Scotch and ___",
    "Fountain order",
    "Part of many a drink order",
    "Concession stand purchase",
    "Designated driver's drink",
    "___ pop",
    "Fountain offering",
    "Gin fizz ingredient",
    "Ice cream drink",
    "Scotch go-with",
    "Spritzer mixer",
    "Kind of ash",
    "Designated driver's order",
    "Kind of water",
    "Bubbly drink",
    "Pop",
    "Drink with a straw",
    "Fizz producer",
    "Scotch's partner",
    "Word after baking or club",
    "Kind of bottle",
    "Scotch partner",
    "Schweppes product",
    "Cream ___",
    "Float alternative",
    "Mixer",
    "Concession stand purchase",
    "Pop",
    "Highball ingredient",
    "Scotch diluter",
    "Teetotaler's order",
    "Kind of water",
    "Common mixer",
    "Scotch's partner",
    "Counter offer?",
    "Dr. Pepper, for one",
    "Bar staple",
    "Kind of jerk",
    "Club ___",
    "Fizzy drink",
    "Bubbly beverage",
    "Carbonated drink",
    "Fountain order",
    "Lab reagent",
    "Scotch's partner",
    "Pop",
    "Pop",
    "Fountain treat",
    "Fountain potable",
    "Cream___",
    "Ice-cream drink",
    "Fountain drink",
    "Baking ___",
    "Fountain treat",
    "Pop",
    "Word with cream or ice-cream",
    "Malt shop order",
    "Word with cream or ice cream",
    "Kind of cracker",
    "Faceup card in faro",
    "Kind of water",
    "Ice cream ___",
    "Soft drink",
    "Kind of fountain",
    "See 42-Across",
    "Stomach soother",
    "Summer cooler"
  ],
  "soap": [
    "Series that might feature a long-lost father-uncle",
    "Lava, e.g.",
    "Dial or Tone",
    "Telenovela, e.g.",
    "Dove in a bathtub, say",
    "Lava, e.g.",
    "Dove bar, e.g.",
    "Ivory, but not ebony",
    "\"General Hospital,\" e.g.",
    "Suds maker",
    "Soft ___ (flattery)",
    "Daytime TV fare",
    "Product sold in bars",
    "It's sold in bars",
    "Old TV's \"Guiding Light,\" for one",
    "Household item whose manufacture employs 42-Across",
    "Dial, e.g.",
    "Dishwasher need",
    "It's often picked up in bars",
    "What may be dispensed from a dispenser",
    "\"All My Children,\" e.g.",
    "It comes in cakes",
    "It may be in a dish by the sink",
    "It's slippery when wet",
    "\"The Young and the Restless,\" e.g.",
    "It's found in cakes",
    "Dish contents",
    "Bar in a shower stall",
    "\"General Hospital,\" e.g.",
    "\"Days of Our Lives,\" for one",
    "\"Days,\" for one",
    "___ dish",
    "Bar that shrinks",
    "Addition to the 33-Across",
    "Bar in a bathroom",
    "Some flakes",
    "It may be found in a dish",
    "Wet bar, maybe",
    "Ivory, e.g.",
    "Laundromat buy",
    "Bar product",
    "Ivory or Coast",
    "\"General Hospital,\" for one",
    "Suds maker",
    "Suds maker",
    "Bubble source",
    "Kind of dish",
    "Wet bar?",
    "Halloween prankster's aid",
    "Daytime TV fare",
    "Dial in the bathroom",
    "It might be stuck to a dish",
    "It's sold in bars",
    "Bribery money, in slang",
    "Afternoon TV fare",
    "Wet bar?",
    "Daytime show",
    "The end of each of 20- and 56-Across and 11- and 29-Down is a popular one",
    "Dove, e.g.",
    "\"General Hospital,\" e.g.",
    "Bubble material",
    "Love story?",
    "Bar in a kitchen",
    "It's bought in bars",
    "Hotel freebie",
    "Wet bar?",
    "\"General Hospital,\" e.g.",
    "Cake composition",
    "With 11-Down, daytime TV fare",
    "Camay, for one",
    "It's available in bars",
    "*Second step",
    "\"One Life to Live,\" e.g.",
    "Bubble source",
    "It's sold in bars",
    "\"General Hospital,\" e.g.",
    "It's sold in bars",
    "Controversial 70's-80's sitcom",
    "Afternoon fare",
    "Detergent",
    "Halloween prankster's supply",
    "Bribe money, in slang",
    "Ivory, e.g.",
    "Bar stuff",
    "Tray item",
    "Dove or Dial",
    "Daytime fare",
    "Daytime show",
    "Carver?Æs medium",
    "Billy Crystal TV series",
    "Bathday cake?",
    "Afternoon TV fare",
    "It's seen in bars",
    "Controversial 70's sitcom"
  ],
  "orca": [
    "Only known animal to prey on great white sharks",
    "Largest member of the dolphin family",
    "Sight from a Seattle ferry",
    "One in a pod",
    "Animal whose name consists of the postal codes of two states it passes in its migration",
    "Killer whale",
    "Willy, in \"Free Willy\"",
    "Apex predator of the ocean",
    "Vancouver Canucks' mascot Fin, for one",
    "Whale that preys on octopuses",
    "Subject of the 2013 documentary \"Blackfish\"",
    "Black-and-white swimmer",
    "Apex predator of the ocean",
    "Predator of the Pacific Northwest",
    "Killer whale",
    "Apex predator of the sea",
    "Killer whale",
    "Penguin predator",
    "Killer whale",
    "Swimmer with big calves",
    "Ocean predator",
    "Whale found in every one of the world's oceans",
    "Killer whale",
    "Marine menace",
    "Sight on an Alaskan cruise",
    "Pod creature",
    "Creature with a 17-month gestation period",
    "It's black and white and wet all over",
    "\"Free Willy\" creature",
    "Shamu, e.g.",
    "Predator of penguins",
    "Part of a pod",
    "Two-tone apex predator",
    "Boat in \"Jaws\"",
    "Sea predator",
    "It may prey on a ray",
    "Apex predator of the ocean",
    "Predator of dolphins",
    "Menace with four-inch teeth",
    "Black-and-white swimmer",
    "\"Free Willy\" creature",
    "Ocean predator",
    "1977 horror film set in Newfoundland",
    "Toothed whale",
    "Subject of the 2013 documentary \"Blackfish\"",
    "Part of a pod",
    "1977 film described by Leonard Maltin as \"For undiscriminating action fans whose idea of entertainment is watching Bo [Derek] getting her leg bitten off\"",
    "Willy of the \"Free Willy\" movies, e.g.",
    "Sea menace",
    "Boat in \"Jaws\"",
    "Pod part, perhaps",
    "Killer whale",
    "Sea carnivore",
    "1977 horror film set at sea",
    "Boat in \"Jaws\"",
    "Black-and-white creature",
    "SeaWorld attraction",
    "Willy of \"Free Willy,\" e.g.",
    "Shark eater",
    "Boat in \"Jaws\"",
    "Beast in the documentary \"Blackfish\"",
    "Whale of a movie?",
    "Shamu, for one",
    "Killer whale",
    "Shamu, for one",
    "Swimmer featured in the 2013 film \"Blackfish\"",
    "SeaWorld sight",
    "400-pound calf, perhaps",
    "Marine threat",
    "Ocean predator",
    "School bully?",
    "Ocean predator with giant jaws",
    "Killer whale",
    "Sight on an Alaskan cruise",
    "Ocean menace",
    "SeaWorld performer",
    "Large-toothed whale",
    "Ocean predator",
    "Killer whale",
    "Water hazard?",
    "1977 thriller set at sea",
    "One seeking to catch some rays?",
    "Predator of seals",
    "Shamu or Keiko",
    "Sea menace",
    "Cousin of a blackfish",
    "Filmdom's Willy, for one",
    "Deep-sea predator",
    "Boat in \"Jaws\"",
    "Killer whale",
    "Boat in \"Jaws\"",
    "1977 thriller co-starring Bo Derek",
    "Killer whale",
    "1977 flick with the tagline \"Terror just beneath the surface\"",
    "Killer whale",
    "Killer whale",
    "Creature at SeaWorld",
    "Killer whale",
    "Killer whale",
    "It's a killer",
    "Preyer on seals",
    "It may prey on seals",
    "Shamu, for one",
    "Deep-sea killer",
    "Shamu, for one",
    "Sea predator",
    "Sea predator",
    "Marine killer",
    "Relative of a bottlenose",
    "Sea predator",
    "Large predator",
    "Boat in \"Jaws\"",
    "Ocean killer",
    "Marine menace",
    "Danger of the deep",
    "Marine park attraction",
    "Black-and-white predator",
    "Killer whale",
    "Ocean danger",
    "Whale of a movie?",
    "Marineland performer",
    "Shamu, for one",
    "Ocean danger",
    "Ocean predator",
    "Ocean predator",
    "Killer whale",
    "Quint's boat in \"Jaws\"",
    "Killer whale",
    "Killer whale",
    "Ocean swimmer's worry",
    "SeaWorld whale",
    "Sea World attraction",
    "Sea predator",
    "Shamu, for one",
    "Killer whale",
    "Marine menace",
    "Underwater predator",
    "Marine predator",
    "\"Jaws\" boat",
    "Dolphin family member",
    "Killer at sea",
    "Shamu, for one",
    "Predatory whale",
    "Killer whale",
    "1977 movie in which Bo Derek's leg is bitten off",
    "Whale of a film?",
    "Willy of \"Free Willy\"",
    "Sighting in the North Pacific",
    "Killer whale",
    "Black-and-white predator",
    "Ocean menace",
    "Black-and-white hunter",
    "Killer whale",
    "Ocean predator",
    "Killer whale",
    "Sea World attraction",
    "Killer whale",
    "Killer whale",
    "A whale of a menace",
    "Killer whale",
    "Cetacean killer",
    "Killer whale",
    "Boat in \"Jaws\"",
    "Sea terror",
    "Killer whale",
    "Oceanic threat",
    "Bo Derek's whale of a film",
    "Killer whale",
    "Killer whale",
    "1977 film killer",
    "1977 movie thriller with Bo Derek",
    "Killer whale",
    "Bo Derek's first major film",
    "Killer whale film",
    "Ocean menace",
    "\"Jaws\" boat",
    "Killer whale",
    "Killer whale",
    "\"Jaws\" boat",
    "Killer whale",
    "Killer whale",
    "Bo Derek's film debut",
    "Killer whale",
    "Richard Harris movie of 1977",
    "Bo Derek film before \"10\"",
    "Whale of a movie",
    "Killer whale"
  ],
  "user": [
    "Person calling tech support, say",
    "Part of UX",
    "End ___",
    "___ ID",
    "___ name and password",
    "For whom a product designer designs",
    "Person with log-in information",
    "Patron",
    "Kind of ID",
    "___ ID",
    "___-friendly",
    "Manipulative sort",
    "App customer",
    "___ error",
    "___ interface",
    "FAQ checker",
    "Manipulative sort",
    "End ___",
    "Computer operator",
    "Addict",
    "Pusher's customer",
    "Operator",
    "Addict",
    "Buyer of drugs, e.g.",
    "Fee payer, often",
    "___-friendly",
    "Manual reader",
    "___ interface",
    "___-friendly",
    "___ interface",
    "Manual reader",
    "App customer",
    "One who might need an ID",
    "___ interface",
    "Exploiter",
    "___-friendly",
    "Marketing target",
    "Selfish sort",
    "Pusher's customer",
    "___ testing (part of the beta stage)",
    "___ ID",
    "Manipulator",
    "Exploitative sort",
    "App purchaser",
    "___-friendly",
    "Target of an intervention",
    "Dealer's customer",
    "___ name",
    "One logging in",
    "___ experience",
    "Consumer",
    "Druggie",
    "One with a habit",
    "One with a password, maybe",
    "One who Googles, e.g.",
    "See 34-Down",
    "One logging in",
    "One with a password, say",
    "One at a keyboard",
    "___ group",
    "Operator",
    "Manipulator",
    "Addict",
    "Friendly introduction?",
    "Kind of fee",
    "PC operator",
    "Consumer",
    "Addict",
    "Consumer",
    "Possible candidate for rehab",
    "Consumer",
    "Manipulator",
    "Manual's intended reader",
    "Lead-in for friendly",
    "Consumer",
    "Manual reader",
    "Consumer",
    "Word with interface or option",
    "___-friendly",
    "End ___",
    "Not a teetotaler",
    "Tech's customer",
    "One at the computer",
    "One pointing and clicking",
    "___ fee",
    "Word before fee or group",
    "Addict",
    "Tech caller",
    "Junkie",
    "Beta tester, e.g.",
    "___-friendly",
    "___-friendly",
    "___-friendly (simple to operate)",
    "___-friendly",
    "Manual reader",
    "End-___ (ultimate buyer)",
    "Addict",
    "Junkie",
    "Rehab candidate",
    "PC operator",
    "One getting a manual",
    "PC person",
    "Drug dealer's customer",
    "Web surfer, e.g.",
    "Addict",
    "Addict",
    "Word with fee or ID",
    "Manipulator",
    "___-friendly",
    "Manipulative one",
    "End ___",
    "Pusher's target",
    "Addict",
    "Cybercafe patron",
    "Software buyer",
    "Candidate for rehab",
    "Exploitative type",
    "One with ulterior motives",
    "Manipulative sort",
    "Not-so-straight shooter?",
    "Addict",
    "One who takes drugs, e.g.",
    "Manipulator",
    "Customer",
    "Techie's client",
    "___ group (computer info source)",
    "Customer",
    "One to whom a warranty applies",
    "Not an abstainer",
    "Abstainer's opposite",
    "One who isn't \"clean\"",
    "Tech support caller",
    "Addict",
    "String puller",
    "Manipulative one",
    "Utility customer",
    "Exploitative type",
    "Addict",
    "Tech support caller",
    "One providing input",
    "Consumer",
    "Manual reader, say",
    "Computerphile",
    "AOL patron",
    "Terminal man?",
    "Cable subscriber",
    "Not a teetotaler",
    "End ___",
    "Computerphile",
    "___-friendly",
    "Cybercafe patron",
    "Computerphile",
    "Selfish sort",
    "Drug addict",
    "Addict",
    "Manipulative sort",
    "Computer operator",
    "Addict",
    "Opportunist, maybe",
    "Net-surfer",
    "Computer operator",
    "Conniver",
    "Person with a PC",
    "Mouse manipulator",
    "Junkie",
    "Druggie",
    "Computerphile",
    "___-friendly",
    "Web visitor, e.g.",
    "One who's in it for himself",
    "Consumer",
    "One needing rehab",
    "Customer",
    "Manipulator",
    "Mouse manipulator",
    "Mouse manipulator",
    "End ___",
    "Druggie",
    "Client",
    "Exploiter",
    "Patron",
    "Employer",
    "Ultimate buyer",
    "___-friendly",
    "Exploiter",
    "One on-line",
    "___-friendly",
    "Employer",
    "Consumer",
    "America Online patron",
    "Internet accessor",
    "Computer owner",
    "Software buyer",
    "Manipulator",
    "Addict",
    "Pusher's target",
    "___-friendly",
    "Word with friendly or fee",
    "Client",
    "Client",
    "Druggie",
    "On-line person",
    "Opportunist",
    "CompuServe patron",
    "PC operator",
    "Hacker, e.g."
  ],
  "crib": [
    "Popular baby shower gift",
    "Mobile home?",
    "Digs",
    "Sleeping spot for a baby",
    "Banned aid?",
    "Nursery sight",
    "Bonus hand in a classic card game",
    "Mobile home?",
    "Place for a baby to sleep",
    "Home, in slang",
    "Home, in slang",
    "Place where a mother might sing \"Rock-a-Bye Baby\"",
    "Copy illegally",
    "Baby's bed",
    "___ notes",
    "It's got all the answers",
    "Hipster's pad",
    "Hip-hopper's home",
    "Steal",
    "Home, slangily",
    "Mobile home?",
    "Where one can retire young?",
    "Mobile home?",
    "Place for a mobile",
    "Sleeping place",
    "Cop",
    "Site for a mobile",
    "Cheater's aid",
    "Cheater's secret",
    "First bed",
    "Cheater's preparation",
    "Baby holder",
    "Need for a new arrival",
    "Toddler's place",
    "It may be in the palm of one's hand",
    "Test taker's dirty secret",
    "Take (from)",
    "Baby's bed",
    "Nursery item",
    "Toddler's bed",
    "Place for a mobile, maybe",
    "Newborn's place",
    "Wrongfully take",
    "Get via roving eyes",
    "Where baby sleeps",
    "Tot's place",
    "Plagiarize",
    "Plagiarize",
    "Cheat",
    "Manger upgrade",
    "Cheat",
    "Tot spot",
    "First bed",
    "Infant's place",
    "Corn bin",
    "Baby's bed",
    "Plagiarize",
    "Nursery item",
    "Cheater's aid"
  ],
  "riri": ["Pop music nickname", "\"Rude Boy\" singer, to fans"],
  "erie": [
    "People also known as the Cat Nation",
    "Lakeside city that's at one end of I-79",
    "Buffalo's lake",
    "Spooky-sounding lake?",
    "___ Canal, connector of the Hudson River to the Great Lakes",
    "Historic enemy of the Iroquois",
    "City roughly halfway between Cleveland and Buffalo",
    "The lake in \"lake effect\" snow",
    "County name in New York, Ohio and Pennsylvania",
    "Great Lake with the smallest volume",
    "Lake bordering Cleveland",
    "Lake largely fed by the Detroit River",
    "Tribe that fought the Iroquois",
    "Northwest county of Pennsylvania",
    "Pennsylvania's Flagship City",
    "Lake that feeds into Lake Ontario",
    "Buffalo's lake",
    "Great Lake with the shortest name",
    "New York State's ___ Canal",
    "Lake with a namesake canal",
    "Native American tribe with a namesake lake",
    "Smallest Great Lake by volume",
    "City on a lake of the same name",
    "Buffalo's county",
    "Great Lake bordered by Ohio",
    "What the \"E\" stands for in HOMES",
    "Lake near London",
    "1813 battle site in the War of 1812",
    "One of the Great Lakes",
    "Lake bordered by four states and a province",
    "The \"E\" in HOMES",
    "Lake ___ (what separates Ohio and Ontario)",
    "One of five Greats",
    "___ Railroad Co. v. Tompkins (landmark 1938 Supreme Court case)",
    "City on a lake of the same name",
    "Fort ___, Ontario",
    "The \"E\" in HOMES",
    "It's between Huron and Ontario",
    "Cleveland's lake",
    "Pennsylvania city",
    "It's a two-hour drive north of Pittsburgh",
    "Lake that feeds the Niagara River",
    "Buffalo's lake",
    "One of the Great Lakes",
    "Lake named for a Pennsylvania people",
    "Home to Bessie, a lake monster in American folklore",
    "Eastern city whose name sounds weird?",
    "Stop on Amtrak's Lake Shore Limited",
    "City north of Pittsburgh",
    "One of the Greats?",
    "Lake tribe",
    "Classic railroad name",
    "Sal's canal, in song",
    "Great Lakes city",
    "Home to Double-A baseball's SeaWolves",
    "Railroad name starting in 1832",
    "Pennsylvania's \"Gem City\"",
    "Lake connected with lake-effect snow",
    "City on Amtrak's Boston-to-Chicago line",
    "It's down the lake from Buffalo",
    "Former tribe in western New York",
    "Port with lots of lake-effect snow",
    "The \"E\" in HOMES",
    "Buffalo's lake",
    "Pennsylvania city or the lake it's on",
    "Lakeside Ohio county",
    "Pennsylvania city",
    "Cleveland's lake",
    "The \"E\" of HOMES",
    "Lake ___, home of Presque Isle",
    "Lake that stretches from Toledo to Buffalo",
    "Pennsylvania's northernmost county",
    "Buffalo's county",
    "City on a lake of the same name",
    "Lake on Ohio's northern border",
    "The Gem City, so-called because of its sparkling lake",
    "One of the Great Lakes",
    "War of 1812 battle site",
    "Buffalo's county",
    "Ontario border lake",
    "The \"E\" of the old NY&E Rail Road",
    "New York's ___ Canal",
    "Southernmost Great Lake",
    "Insurance company based in Pennsylvania",
    "Sandusky's lake",
    "City of NW Pennsylvania",
    "Member of a noted quintet",
    "New York county on the Canadian border",
    "Spooky-sounding Pennsylvania city",
    "See 39-Down",
    "U.S. city that's an anagram of 22-Across",
    "Lakeside tribe",
    "Eponymous Indian tribe",
    "New York canal",
    "War of 1812 battle site",
    "County name in three states",
    "Snowbelt city",
    "The \"E\" in HOMES",
    "Lake in an old railroad name",
    "Iroquois foe in the Beaver Wars",
    "It's at one end of I-79",
    "Canal with 36 locks",
    "Canal of song",
    "Railroad chartered in 1832",
    "City near Presque Isle State Park",
    "Buffalo's county",
    "Cleveland's lake",
    "Cleveland's lake",
    "Lake ___, source of the Niagara River",
    "1813's Battle of Lake ___",
    "Pennsylvania's Flagship City",
    "Language related to Wyandot",
    "Pennsylvania's northernmost county",
    "Lake ___, 1813 battle site",
    "Put-in-Bay's lake",
    "Cleveland's lake",
    "Certain Iroquoian",
    "___ Railroad, 1832-1960",
    "Lake connected to Sandusky Bay",
    "Smallest Great Lake, by volume",
    "Fort ___, Ontario",
    "New York State's ___ Canal",
    "Lake between Ohio and Ontario",
    "The Maumee flows northeast to this lake",
    "See 29-Across",
    "Pennsylvania city or county",
    "Great Lake between Huron and Ontario",
    "___ Lackawanna Railroad",
    "Canal of song",
    "Creepy-sounding lake name?",
    "Sandusky's lake",
    "\"Fifteen Miles on the ___ Canal\"",
    "Name of counties in three states, all crossed by I-90",
    "War of 1812 battle site",
    "Tribe that lent its name to a canal",
    "Pennsylvania port",
    "City on Presque Isle Bay",
    "Ontario's Fort ___",
    "City that's home to the U.S. Brig Niagara",
    "Southernmost of the Great Lakes",
    "Canal of song",
    "Buffalo's county",
    "View from Presque Isle",
    "Buffalo's county or lake",
    "Lake named for an Indian tribe",
    "Shallowest of the Great Lakes",
    "Losing tribe in the Beaver Wars",
    "___ Canal, waterway through Schenectady",
    "Lake bordered by four states",
    "Cleveland's lake",
    "County west of Wyoming",
    "See 23-Across",
    "Upstate New York's ___ Canal",
    "Niagara River's source",
    "Pennsylvania county or its seat",
    "See 5-Down",
    "Iroquoian language",
    "Buffalo's county",
    "Lake ___, discovery of Louis Jolliet",
    "Lake bordering four states",
    "View from Buffalo",
    "County in New York, Ohio or Pennsylvania",
    "Upstate New York county",
    "Home of Presque Isle Downs racetrack",
    "City of 100,000+ or the lake it's on",
    "War of 1812 battle site",
    "Buffalo's lake",
    "War of 1812 siege site",
    "Lake ___, home of the Bass Islands",
    "With 30-Down, locale of lots of locks",
    "Great Lake touching four states",
    "Pennsylvania's northwesternmost county",
    "Canal near Rome",
    "Buffalo's county",
    "Toledo's lake",
    "Iroquoian language",
    "Toledo's lake",
    "Cleveland's lake",
    "Lake next to Avon Lake",
    "___ Lackawanna (bygone railroad)",
    "New York tribe defeated by the Iroquois",
    "Lake ___, south of London",
    "Pennsylvania's Flagship City",
    "Lake ___, outlet of the Maumee River",
    "Shallowest Great Lake",
    "Cleveland's lake",
    "Fort ___, captured by U.S. forces in 1814",
    "___ Lackawanna Railroad",
    "Home of Gannon University",
    "Iroquoian language",
    "Keystone State port",
    "I-90 runs along it",
    "Great Lakes port",
    "Buffalo's body of water",
    "Lake Indian",
    "Jay Gould railroad",
    "Canada's Battle of Fort ___, 1866",
    "Jay Gould railroad",
    "Toledo's lake",
    "Shallowest of the Great Lakes",
    "1813 battle site",
    "Lake named after an Indian tribe",
    "Cleveland's lake",
    "City on Presque Isle Bay",
    "Pennsylvania port",
    "Toledo's lake",
    "Canal of song",
    "Cleveland's lake",
    "Pennsylvania port",
    "Lackawanna's lake",
    "Port on a lake of the same name",
    "Ohio/Ontario separator",
    "Toledo's lake",
    "Great Lakes city",
    "With 34-Across, engineering feat completed in 1825",
    "Former lakeshore tribe",
    "Lake near Niagara Falls",
    "___ Canal",
    "Niagara River source",
    "View from Toledo",
    "Toledo's lake",
    "Canal of song",
    "Cleveland's lake",
    "Toledo's lake",
    "New York county",
    "Niagara source",
    "Pennsylvania's northwesternmost county",
    "Lake tribe",
    "Behrend College locale",
    "Port city or the lake it's on",
    "Railroad chartered in 1832",
    "Northwesternmost Pennsylvania county",
    "Tribe defeated by the Iroquois",
    "Port on a lake of the same name",
    "Buffalo's lake",
    "Canal of song",
    "View from Toledo",
    "Buffalo's lake",
    "Eastern tribe",
    "View from the Rock & Roll Hall of Fame",
    "Canal of song",
    "Old railroad name",
    "Great Lakes city",
    "Toledo's lake",
    "Toledo's lake",
    "___ Lackawanna Railroad",
    "Fort ___, Ont.",
    "Iroquois foe",
    "Western New York county",
    "New York's ___ Canal",
    "Great Lakes port",
    "Cleveland's lake",
    "Canal of song",
    "Cleveland's lake",
    "Penn State branch site",
    "City near Fort Presque Isle",
    "Canal of song",
    "Keystone State port",
    "Sandusky's lake",
    "Cleveland's lake",
    "Where Commodore Perry prevailed",
    "War of 1812 battle site",
    "Tribe with palisaded villages",
    "Lake touching four states",
    "Iroquoian Indian",
    "Buffalo's lake",
    "War of 1812 battle site",
    "New York's ___ Canal",
    "Enemies of the Iroquois",
    "Oliver Hazard Perry victory site",
    "Lake ___ Beach, N.Y.",
    "Presque Isle lake",
    "Lake along which I-90 runs",
    "Buffalo's lake",
    "Buffalo's lake",
    "Old name in railroads",
    "War of 1812 locale",
    "Ashtabula's lake",
    "Sandusky's county",
    "Canal of song",
    "Keystone State port",
    "___ Railroad, founded 1832",
    "Foe of the Iroquois",
    "Gannon University city",
    "Niagara River source",
    "War of 1812 port",
    "Toledo's lake",
    "Cleveland Indian",
    "Strange-sounding city?",
    "Toledo's lake",
    "___-Lackawanna Railroad",
    "New York canal",
    "The shallowest Great Lake",
    "Sandusky's county",
    "Toledo's lake",
    "Ashtabula's lake",
    "Mercyhurst College site",
    "Buffalo's lake",
    "Iroquoian Indian",
    "Part of the U.S./Canada border",
    "Buffalo's lake",
    "Western New York county",
    "Old railroad name",
    "Classic railroad name",
    "Gannon University city",
    "Pennsylvania port",
    "Buffalo's county",
    "Ashtabula's lake",
    "Sal's canal",
    "Lake ___ College, in 16-Across",
    "New York's ___ Canal",
    "Buffalo's lake",
    "Eastern Woodlands tribe",
    "Lake near Niagara Falls",
    "New York's most populous upstate county",
    "Put-in-Bay's lake",
    "New York canal",
    "Canal with a mule, in song",
    "Old railroad name",
    "Toledo's lake",
    "New York canal",
    "Canal to the Hudson",
    "It's between Huron and Ontario",
    "Where I-79 ends",
    "An Iroquoian",
    "Where Oliver Hazard Perry prevailed",
    "Buffalo's county",
    "A Great Lake",
    "Superior's inferior",
    "Cleveland's lake",
    "Pennsylvania city",
    "U.S. port, or its locale",
    "Jay Gould railroad",
    "Toledo's lake",
    "New York and ___ Railroad, started in 1832",
    "Cuyahoga's outlet",
    "New York's ___ Canal",
    "Gannon University locale",
    "Canal with a mule named Sal",
    "Lake that feeds Niagara Falls",
    "Pelee Island's lake",
    "New York Indian",
    "DeWitt Clinton's canal",
    "Canal with a \"low bridge\" (\"ev'rybody down!\")",
    "Jolliet's 1669 discovery",
    "Iroquois enemy",
    "Lake near Jacobs Field",
    "Louis Jolliet discovery of 1669",
    "One of a watery quintet",
    "Native New Yorker",
    "Lackawanna's partner in railroading",
    "Foe of the Iroquois",
    "Northwest Pennsylvania city",
    "I-79's northern terminus",
    "Cleveland's lake",
    "Buffalo's lake",
    "Southernmost Great Lake",
    "Welland Canal outlet",
    "View from Sandusky",
    "Presque Isle locale",
    "I-79 terminus",
    "Onetime B.&O. rival",
    "Canal to Buffalo",
    "Cleveland's lake",
    "Perry battle site",
    "Cleveland's lake",
    "___Canal",
    "Native New Yorker",
    "Canal of song",
    "Toledo's lake",
    "Ohio or New York county",
    "___ Lackawanna Railway",
    "Canal to Buffalo",
    "View from Buffalo",
    "175-year-old canal",
    "Iroquoian language",
    "I-79 terminus",
    "Buffalo's lake",
    "Soprano Mills",
    "Put-in-Bay's locale",
    "Mercyhurst College site",
    "Albany canal",
    "Seneca foe, 1653",
    "1813 naval battle site",
    "Fort ___, Ont.",
    "Gannon University site",
    "Eastern Amerind",
    "Pennsylvania port",
    "Toledo's lake",
    "Buffalo's county",
    "Sandusky's lake",
    "Old name in railroading",
    "Welland Canal outlet",
    "Cleveland's lake",
    "A Great Lake",
    "Buffalo's county",
    "Northeast Indian",
    "Mule Sal's canal",
    "New York canal",
    "Ashtabula abuts it",
    "___ Lackawanna Railway",
    "A Great Lake",
    "Fort ___, Ontario",
    "\"Clinton's Ditch\"",
    "View from I-90",
    "Lackawanna's railroad partner",
    "Buffalo's lake",
    "Great Lake",
    "Welland Canal terminus",
    "Cleveland's lake",
    "One of five",
    "Toledo's lake",
    "Fort Presque Isle site",
    "Albany-to-Buffalo route",
    "Admiral Perry victory site",
    "Gannon University home",
    "Border lake",
    "View from Ashtabula",
    "15 miles of song",
    "Pennsylvania city",
    "Lackawanna's partner in railroads",
    "Canal opened in 1825",
    "Detroit River's destination",
    "Toledo's vista",
    "Enemy of the Iroquois",
    "Naval battle site of 1813",
    "Buffalo's county",
    "Spooky waterway?",
    "Seneca foe",
    "Villa Maria College site",
    "Tribe whose name means \"cat people\"",
    "War of 1812 battle site",
    "\"Low bridge! Everyone down!\" canal",
    "Pennsylvania port",
    "Part of HOMES",
    "Sight from Sandusky"
  ],
  "scan": [
    "Run one's eyes over",
    "Diagnostic image",
    "Digital picture, maybe",
    "Read over",
    "Digitize, in a way",
    "Read the U.P.C. of",
    "Glance at, as headlines",
    "Phone-unlocking option",
    "Once-over",
    "M.R.I., for one",
    "Pore over",
    "Checkers do it",
    "Use a QR code for, say",
    "Check out",
    "Result of digitization",
    "Get a good look at",
    "What optical readers do",
    "What photocopiers do",
    "Many a digitalization",
    "Read, as a bar code",
    "Do a price check on, e.g.",
    "Digitize",
    "Check out",
    "Digitize, in a way",
    "Digitize, as a document",
    "Good look",
    "Emailable picture",
    "Digitize, in a way",
    "Digitize, as a document",
    "Create a digital image of",
    "Make a digital image of",
    "Read electronically",
    "Survey with binoculars, say",
    "Make a PDF of, in a way",
    "Certain JPEG",
    "Pore over",
    "Read digitally",
    "Car radio button",
    "Read, as a bar code",
    "Eye carefully",
    "Glance over",
    "Read carefully",
    "Diagnostic test, of a sort",
    "Copiers do it",
    "Read, as a U.P.C. symbol",
    "Search",
    "Swipe at the store",
    "Counter act",
    "Read the U.P.C. of",
    "Do a cashier's job",
    "Eyeball",
    "Survey",
    "Process, in a way, as documents",
    "Word with brain or price",
    "Read quickly",
    "Sonogram, e.g.",
    "Digitize, maybe",
    "Do a cashier's job",
    "View by computed tomography",
    "Read, in a way",
    "Certain ID check",
    "Supermarket checkout action",
    "Good, close look",
    "View by computer tomography",
    "Computer image",
    "Go over, as with radar",
    "Read",
    "Check, as the horizon",
    "Security procedure",
    "Check the bar code",
    "Computerized photo",
    "Word with price or brain",
    "Be metrical",
    "Preparation for surgery, perhaps",
    "Use binoculars, say",
    "Look over",
    "Read electronically",
    "Some camera work",
    "Read at the supermarket?",
    "Computer picture",
    "Digitize, in a way",
    "Computer picture",
    "Conform, metrically",
    "Read lines, in a way",
    "Search, as the horizon",
    "Search",
    "Read a bar code",
    "Read the U.P.C.",
    "Put into the computer without typing",
    "Brain ___",
    "View electronically",
    "Examination by remote camera",
    "Capture electronically",
    "Take an electronic picture of",
    "Scrutinize",
    "Glance over",
    "Mayo Clinic test",
    "Glance over",
    "Go up and down the dial",
    "Sweep with binoculars",
    "CAT ___",
    "Examine closely",
    "See 29-Across",
    "Look over",
    "Search through",
    "Leaf through",
    "Use an optical reader",
    "Supermarket check",
    "Read quickly",
    "CAT ___",
    "Read, as U.P.C. symbols",
    "Give the once-over",
    "Browse",
    "Supermarket checkers do it",
    "Car radio feature",
    "Read, as bar codes",
    "Scrutinize",
    "Electronic reading",
    "Analyze verse",
    "Survey",
    "Survey",
    "Have a look-see"
  ],
  "wade": [
    "Water gait?",
    "Take steps (into)",
    "Use hip boots, maybe",
    "Stay close to shore, say",
    "Walk in the kiddie pool",
    "Go in just a little way, say",
    "Notable 1973 defendant",
    "Not dive in, say",
    "Flamingos do it",
    "Logs through water",
    "Get heavily (into)",
    "Cross a shallow stream, say",
    "Go off-shore, maybe",
    "Walk in water",
    "Proceed with difficulty (through)",
    "Hall-of-Famer Boggs",
    "Go in with rolled-up pants?",
    "Stay near the shore, say",
    "Go in only a little way, say",
    "Baseballer Boggs",
    "Walk through water",
    "Walk in water",
    "Go in up to one's ankles",
    "Walk in water",
    "Walk in water",
    "Spoonbills do it",
    "Ford a shallow stream, say",
    "Walk in shallow water",
    "Go in the kiddie pool, maybe",
    "Enter only up to the ankles, say",
    "Cranes do it",
    "Read (through)",
    "Wimbledon winner of 1977",
    "Stay in the shallow end of the pool",
    "Walk in water",
    "Use hip boots, perhaps",
    "Go in the water just a little way",
    "Cross a shallow creek",
    "Stay near the shore",
    "Go in up to the ankles",
    "Walk in the surf",
    "Plod (through)",
    "Walk in the baby pool",
    "Get in a little way",
    "Slog (through)",
    "1977 Wimbledon champ",
    "1977 Wimbledon winner"
  ],
  "echo": [
    "Say the same thing as",
    "Bounce off the wall",
    "___ Park (neighborhood in central Los Angeles)",
    "Reverberation",
    "Second, so to speak",
    "Reverberating sound",
    "Comeback",
    "Mindlessly repeat",
    "Come back",
    "Sound heard in a long hallway, maybe",
    "Comeback",
    "Pedal effect on a guitar",
    "Video call annoyance",
    "Sound in a long, empty hallway",
    "Resounding comeback?",
    "Bounce off the walls",
    "Where to find Amazon's Alexa",
    "Return some calls?",
    "Delta follower",
    "Resound",
    "Nymph cursed by Hera",
    "Unwanted effect on a recording",
    "Second coming?",
    "Quick comeback?",
    "Unoriginal voice",
    "NATO alphabet \"E\"",
    "Sound that a sound-absorbing chamber should eliminate",
    "Auto-reply?",
    "Call back?",
    "Repercussion",
    "Reverberation",
    "Backtalk?",
    "Repeat",
    "Canyon effect",
    "Bounce off the wall",
    "Return from a tunnel?",
    "Return from a trip to the Alps?",
    "Audio problem",
    "Bounce back",
    "Voice-activated Amazon device",
    "Radar response",
    "Sound heard in a cave",
    "Comeback in a cave",
    "Voice-controlled product from Amazon",
    "Canyon rebound",
    "Reverberate",
    "Canyon phenomenon",
    "Something you might hear in an empty building",
    "Auto-reply message?",
    "\"Hello ... hello ... hello ...\"",
    "*Quick comeback?",
    "Returned call?",
    "Reverberate",
    "Not an original thought",
    "Guitar amp effect",
    "Audio engineer's concern",
    "Talk back?",
    "Something perfect acoustics eliminate",
    "Nymph of myth",
    "Foxtrot preceder",
    "Verbal comeback?",
    "Sound effect in a long hallway",
    "Return to sender?",
    "Empty hall phenomenon",
    "What a well may produce",
    "Bounce back",
    "Parrot",
    "Say again",
    "Make a comeback?",
    "Effect of a yodel, perhaps",
    "Second hearing?",
    "Verbal feedback?",
    "Sound after call waiting?",
    "Repeat word for word",
    "Give a second hearing?",
    "Reverberation",
    "Imitate",
    "Come again?",
    "Long hallway effect",
    "Parrot",
    "Returned waves?",
    "Tunnel effect",
    "Canyon sound effect",
    "Lover of Narcissus",
    "Say again",
    "Yodel's comeback",
    "Come back",
    "Sound in a long hallway",
    "Restatement?",
    "Pursuit of Pan",
    "Parrot",
    "Sound heard in an empty hallway",
    "It comes back to you",
    "Letter after delta",
    "Comeback?",
    "Sound effect",
    "Return a call?",
    "Nymph spurned by Narcissus",
    "Bounce back",
    "Lingering trace",
    "Something a yodeler may hear",
    "Return to sender?",
    "Canyon sound effect",
    "It may be off the wall",
    "Oread in love with her own voice",
    "Repeated message?",
    "Waves back?",
    "\"Anybody home? ... home? ... home? ...\"",
    "Sound heard in a canyon",
    "She pined for Narcissus",
    "Return from a mountain?",
    "Say again",
    "Tunnel effect",
    "Hollow response",
    "Bounce back, as sound",
    "Pilot's E",
    "Repetitive reply",
    "Come back",
    "It may be heard in a tunnel",
    "Parrot",
    "Second",
    "Repeat",
    "Comeback",
    "Eerie cave effect",
    "Be reminiscent of",
    "Be reminiscent of",
    "Come back",
    "Greek nymph who pined away for Narcissus",
    "Parrot",
    "Repeat",
    "Sound in a cave",
    "Off-the-wall response?",
    "Audio effect",
    "It's often tested by shouting \"Hello!\"",
    "Narcissus spurned her",
    "Rebounding sound",
    "Water well feature",
    "Say the same thing as",
    "Returning waves, of sorts",
    "Do likewise",
    "Repeat",
    "Back talk?",
    "Sound in a long hallway",
    "Cavern feature",
    "She loved Narcissus",
    "Repeat performance?",
    "Bounce back",
    "Canyon sound",
    "Lover of Narcissus",
    "It might be off the wall",
    "Repeat",
    "Parrot",
    "Sound in a cave",
    "Parrot",
    "Repeat after me?",
    "Sound in a big, empty room",
    "Canyon effect",
    "Talk back?",
    "Bounce back",
    "\"Can you hear me? ... hear me? ... hear me?\"",
    "Studio effect",
    "Repeat performance?",
    "Sound effect",
    "Canyon sound",
    "Bounce back",
    "Chamber phenomenon",
    "Bounceback",
    "Sound heard in a cave",
    "Return from the hills",
    "Lingering trace",
    "Sound rebound",
    "1960 satellite launch",
    "Say again",
    "Sound return",
    "Off-the-wall reply",
    "Ring",
    "Say again",
    "Parrot",
    "Reverberate",
    "Reverberate",
    "Canyon sound",
    "Sonar comeback",
    "Bat's navigation aid",
    "It's been said before",
    "Send back",
    "Canyon sound",
    "Reverberate",
    "She loved Narcissus",
    "Comeback",
    "Backtalk?",
    "Repeat",
    "Go off the wall?",
    "Bounce back, in a way",
    "Sonar principle",
    "Unoriginal reply",
    "Sonar's principle",
    "Comeback, of sorts",
    "Parrot",
    "Canyon comeback",
    "\"Art is a delayed ___\": Santayana",
    "Canyon sound",
    "Come back",
    "Sound in an empty room",
    "Sympathetic response",
    "Feature of an empty house",
    "Second time around?",
    "Say again",
    "Hollow response",
    "Canyon reply",
    "\"Me too\" response",
    "She loved Narcissus",
    "Kind of chamber",
    "Quick reply, say",
    "Second",
    "Return to the Alps?",
    "Audio effect",
    "Reiterate",
    "Parrot",
    "Hollow reply?",
    "Feedback of a sort",
    "Audio problem",
    "Sonar signal",
    "Canyon effect",
    "Lingering effect",
    "Reverberation",
    "Kind of chamber",
    "22-Down reply",
    "Canyon feature",
    "Rebound",
    "Effect in the recording studio",
    "Classical nymph who spoke only by repetition",
    "Reverberate",
    "Reverberate",
    "Canyon feature",
    "Send back",
    "Comeback",
    "Snappy comeback?",
    "Repeat exactly",
    "Chamber sound",
    "Reverberate",
    "Back talk?",
    "Early satellite",
    "Audio feedback problem",
    "Bounce back",
    "Second coming?",
    "Piner for Narcissus",
    "Canyon sound",
    "Parrot",
    "Mimic",
    "Reverberate",
    "Lover of Narcissus",
    "Canyon effect",
    "Milton's \"sweetest nymph\"",
    "Bounce back",
    "Canyon phenomenon",
    "She loved Narcissus",
    "Radar reception",
    "Canyon sound",
    "Come back",
    "Sonar blip",
    "Cavern phenomenon",
    "Mimic",
    "Mimic",
    "Radar blip",
    "Canyon sound",
    "HELLO!... Hello!... hello ...",
    "Quick comeback?"
  ],
  "weld": [
    "Metalworker's union",
    "Join together",
    "Join together, in a way",
    "Join together",
    "Make one",
    "Metalworker's union?",
    "Make inseparable",
    "Join, in a way",
    "Unite under fire?",
    "Join with a blowtorch",
    "Ironworker's union?",
    "Tuesday in Hollywood",
    "Join with a blowtorch",
    "Solder",
    "Join",
    "Unite",
    "Join of arc?",
    "Join",
    "Fuse",
    "Connect, as girders",
    "Fuse",
    "Unite"
  ],
  "fall": [
    "Spill",
    "Leaf-changing season",
    "When to take a foliage tour",
    "Word ladder, part 5",
    "Cooling off period?",
    "Skating embarrassment",
    "When leaves 56-Across",
    "When many network shows debut",
    "Occur",
    "Time for TV debuts",
    "Drop",
    "Autumn",
    "Ladder danger",
    "Genesis event, with \"the\"",
    "Autumn",
    "Take a tumble",
    "Mountaineer's worry",
    "When new TV shows debut",
    "When leaves turn",
    "Be overthrown",
    "Genesis event, with \"the\"",
    "Ladder danger",
    "Eden event",
    "Take a spill",
    "Pitch",
    "Ebb",
    "Suffer defeat",
    "Subside",
    "Cider season"
  ],
  "togo": [
    "Its anthem is \"Terre de nos aïeux\"",
    "Country on the Gulf of Guinea",
    "Packed with plasticware, perhaps",
    "Burkina Faso neighbor",
    "Boxed up, say",
    "Neighbor of Ghana",
    "Restaurant order specification",
    "For takeout",
    "Like food from a West African drive-through?",
    "Not for here",
    "Like some orders",
    "Deli option",
    "Land on the Gulf of Guinea",
    "Ordering option",
    "Ordering option",
    "Restaurant request",
    "Ghana neighbor",
    "Like a drive-thru order",
    "Pizza parlor option",
    "Like many fast-food orders",
    "Deli option",
    "Ordering option",
    "Neighbor of Ghana",
    "How food may be ordered at a restaurant",
    "Like some pizza orders",
    "Take-out",
    "Like takeout orders",
    "Restaurant option",
    "Like some pizza orders",
    "Orderer's option",
    "Like some orders",
    "Like some pizza orders",
    "Not for here",
    "Like some restaurant orders",
    "For takeout",
    "Like some restaurant orders",
    "Ordering option",
    "Fast-food option",
    "Opposite of \"for here\"",
    "Like some lunch orders",
    "Ghana neighbor",
    "Take-out words",
    "Like some restaurant orders",
    "Not for here",
    "Sign in a restaurant",
    "Like some deli orders",
    "Not \"for here\"",
    "Like some orders",
    "Deli order",
    "Fast food option",
    "Like some food orders",
    "One way to order at a restaurant",
    "For takeout"
  ],
  "spun": [
    "Did some P.R. work on",
    "Like tops and tales",
    "Whirled around",
    "Fabricated, as a yarn",
    "Like cocoons and cotton candy",
    "Made a web site?",
    "Told, as tales",
    "Emulated Rumpelstiltskin",
    "Like yarn",
    "Took a turn on \"Wheel of Fortune\"",
    "Twirled",
    "Like yarn",
    "Turned pea in 19-Across",
    "Like cotton candy",
    "Like yarn",
    "Like cotton candy",
    "Like cotton candy",
    "Worked like Rumpelstiltskin",
    "Took a turn, in some games",
    "Weaved",
    "Had a quick turn",
    "Like the sugar in cotton candy",
    "Made, as a web",
    "Like webs",
    "Made, as cotton candy",
    "Made a web",
    "Centrifugalized",
    "Created a web site?",
    "Twirled",
    "Worked a jenny",
    "Wheeled around",
    "Told, as tales",
    "Rotated",
    "Gave it a whirl?"
  ],
  "star": [
    "Common Christmas tree decoration",
    "Milky Way bit",
    "The sun, for one",
    "Award to wear",
    "Symbol on Captain America's shield",
    "Castor or Pollux",
    "Old TV's \"___ Search\"",
    "State symbol",
    "Word with search or witness",
    "Twinkler in the night sky",
    "Asterisk",
    "Part of a constellation",
    "Vega, for one",
    "Map symbol for a capital, often",
    "Headliner",
    "Red carpet interviewee",
    "One of six in Subaru's logo",
    "See 20-Across",
    "Critic's award",
    "Principal",
    "Part of the Hardee's logo",
    "With 38-Across, like Romeo and Juliet ... and like the shaded words?",
    "Part of a constellation",
    "One getting the red-carpet treatment",
    "Celebrity",
    "Marquee performer",
    "Invincibility power-up in Mario games",
    "Competitor of Us Weekly",
    "Center of a Scrabble board",
    "Symbol on the Texas state flag",
    "Luminary",
    "Insignia on a Houston Astros cap",
    "Headliner",
    "The sun, for one",
    "Symbol in the middle of a Scrabble board",
    "Symbol in the middle of a Scrabble board",
    "Sun",
    "Critic's assignment",
    "Castor or Pollux",
    "Shiner",
    "One of 50 on the U.S. flag",
    "Navigator's aid",
    "Headliner",
    "Sky light",
    "Symbol at the center of a Scrabble board",
    "One receiving top billing",
    "Symbol to the left of a zero on a phone",
    "Celebrity",
    "Part of a rating",
    "Magi's guide",
    "Hollywood Walk of Fame symbol",
    "One of seven in the Big Dipper",
    "Twinkler",
    "Lead",
    "Common newspaper name",
    "Center of a Scrabble board",
    "One of 12 in the European Union logo",
    "Polaris, for one",
    "Headline",
    "*",
    "Symbol on Captain America's shield",
    "Critical unit?",
    "Have the lead",
    "Lead",
    "Have the lead",
    "Badge shape",
    "End of a fairy's wand",
    "Macy's symbol",
    "Emblem on Captain America's shield",
    "Hollywood Walk of Fame feature",
    "Headline",
    "Kansas City daily",
    "Southern Cross unit",
    "Shiner",
    "Sun",
    "Symbol in the center of a Scrabble board",
    "Map symbol",
    "Part of Texaco's logo",
    "Sirius, e.g.",
    "Marquee name",
    "White dwarf, e.g.",
    "Lead",
    "Standout",
    "Play the lead role",
    "Proxima Centauri, for one",
    "Be featured (in)",
    "Milky Way unit",
    "It often goes out with a bang",
    "Astronomy figure",
    "Symbol on the Hollywood Walk of Fame",
    "The sun, e.g.",
    "Headliner",
    "Sirius, e.g.",
    "Critic's unit",
    "See 34-Across",
    "\"You are here\" symbol",
    "Symbol on a flag",
    "Cross: Christianity :: ___ : Judaism",
    "Toronto daily",
    "Marquee name",
    "Alpha, beta or gamma",
    "One of seven in the Big Dipper",
    "Kindergarten \"grade\"",
    "\"Notch\" on Orion's belt",
    "Polaris or Sirius",
    "Lead",
    "Representation of a state on the U.S. flag",
    "Celeb",
    "Something to wish upon",
    "Macy's logo",
    "Dwarf or giant, maybe",
    "Headliner",
    "Big shot",
    "Each state, symbolically",
    "Walk of Fame embedment",
    "___ witness",
    "Polaris, e.g.",
    "Marquee topper",
    "Sky light",
    "Headline",
    "Chinese checkers board shape",
    "Dressing room door figure",
    "Guide for the Magi",
    "Headliner",
    "Xmas tree topper",
    "Critic's award",
    "Headline",
    "Top name on a marquee",
    "Any Wimbledon winner, in the tennis world",
    "Play a big part",
    "Highlight?",
    "Grade schooler's reward",
    "Headliner",
    "With 23-Across, winner of seven Oscars",
    "Headliner",
    "Headliner",
    "Headliner",
    "Marquee name",
    "Reviewer's award",
    "Commendation",
    "Christmas tree topper",
    "Magic wand tip",
    "Flag feature",
    "Standout",
    "Movie rating unit",
    "Night twinkler",
    "Have the lead",
    "Diva, e.g.",
    "Christmas tree topper",
    "Common flag symbol",
    "One with a print outside Grauman's Chinese Theatre",
    "Headline",
    "With 34D, a mariner's guide",
    "Castor or Pollux",
    "See 125-Across",
    "Top banana",
    "See 48-Across",
    "One assigned to a bomb?",
    "*",
    "Prominent player",
    "Magi guide",
    "Constellation component",
    "Big name",
    "Phone button",
    "Principal",
    "Take the lead",
    "Phone button below the 7",
    "Reviewer's unit",
    "See 34-Down",
    "Have the lead",
    "Pentagram",
    "Headliner",
    "See 55-Across",
    "Phone button below the 7",
    "Constellation part",
    "Marquee-name entertainer",
    "Headliner",
    "*",
    "Part of a constellation",
    "Good student's reward",
    "Celeb",
    "Destiny",
    "Symbol on the Hollywood Walk of Fame",
    "Headliner",
    "Hollywood sighting",
    "Old phone's lack",
    "A film might receive one",
    "Asterisk",
    "Kind of witness",
    "Telephone button",
    "Magi's interest",
    "Restaurant review symbol",
    "Top banana",
    "Nighttime twinkler",
    "Milky Way unit",
    "Lower-left phone button",
    "Film's lead",
    "Military award",
    "Symbol on a phone button",
    "Headline",
    "Asterisk",
    "Common flag feature",
    "Award for a good student",
    "Night light",
    "General acquisition",
    "Ratings unit",
    "Polaris, e.g.",
    "Symbol on a Cowboy's helmet",
    "Headline",
    "Canopus or Aldebaran",
    "Alpha, e.g.",
    "Astronomer's sighting",
    "Celebrated",
    "Wishing spot",
    "Headline",
    "One telephone button",
    "Asterisk",
    "Lead",
    "School reward",
    "\"Flaming ___\" (1960 Elvis movie)",
    "Antares, for one",
    "Play the lead",
    "Award of merit",
    "Hollywood Boulevard sight",
    "Dallas Cowboy's emblem",
    "Headliner",
    "Walk of Fame symbol",
    "Lead player",
    "Christmas tree topper",
    "Military award",
    "Twinkler",
    "Night sight",
    "Pentagram",
    "Talented one",
    "Gertrude Lawrence film bio",
    "Destiny",
    "Pentagram",
    "Part of a knight's insignia",
    "Polaris, e.g.",
    "Wishing site",
    "Luminary",
    "Guiding light?",
    "Flag feature",
    "Lead",
    "Celebrity",
    "Polaris, e.g.",
    "Critic?Æs bestowal",
    "Kansas City paper",
    "Constellation component",
    "Lead",
    "Twinkler",
    "Play the lead",
    "Christmas tree topper",
    "Red giant, e.g.",
    "Weekly World News rival",
    "Reward for merit",
    "Sirius, e.g.",
    "Headliner",
    "Head the cast",
    "National Enquirer rival",
    "Mira or Vega",
    "Wisher's sight",
    "Asterisk",
    "Night light"
  ],
  "none": [
    "Zip",
    "\"___ for me, thanks\"",
    "Zero",
    "Jack of all trades, master of ___",
    "Option that's almost always listed last",
    "Having ___ of it",
    "Last option on a survey",
    "Last word in the first verse of \"Old Mother Hubbard\"",
    "All's opposite",
    "Abstainer's portion",
    "How many verbs in Esperanto are irregular",
    "___ of the above",
    "Fourth little piggy's share",
    "Agatha Christie's \"And Then There Were ___\"",
    "Master of ___",
    "Poor dog's portion, in rhyme",
    "Less than slim",
    "Zilch",
    "Zippo",
    "Zip",
    "Amount left in Old Mother Hubbard's cupboard",
    "Chances left after Slim left town, in a saying",
    "___ too happy",
    "Zero",
    "___ of the above",
    "Lowest possible turnout",
    "Alternative to all",
    "What the fourth little piggy had",
    "Last option on some survey questions",
    "Of Peter O'Toole's eight Oscar nominations, how many he won",
    "Abstainer's choice",
    "All alternative",
    "Bar ___",
    "Slim to ___ (poor odds)",
    "Teetotaler's amount",
    "All's opposite",
    "Bar ___",
    "Zero",
    "All's counterpart",
    "Bar closing?",
    "Number of trades Jack is a master of",
    "Second to ___",
    "Zero",
    "Squat",
    "Bar ___",
    "Nary a soul",
    "Zero",
    "What the \"poor dog\" had in \"Old Mother Hubbard\"",
    "All alternative",
    "What a jack-of-all-trades is master of, supposedly",
    "All's opposite",
    "\"___ But the Brave\" (1965 Sinatra film)",
    "Choice that avoids choosing",
    "Zero",
    "Survey choice, at times",
    "___ of the above",
    "___ too soon",
    "Survey choice",
    "Zero",
    "All's opposite",
    "Portion for Mother Hubbard's dog",
    "___ the wiser",
    "What one of the little pigs had in \"This Little Pig\"",
    "Nary a soul",
    "All alternative",
    "Bar ___",
    "___ the wiser",
    "Not a lick",
    "Zip",
    "How many symphonies Chopin wrote",
    "Zero",
    "Zero",
    "All alternative",
    "Bar ___",
    "What the fourth little piggy got",
    "All-or-___",
    "Zero",
    "\"With malice toward ___ ...\": Lincoln",
    "Zero",
    "Fifth canonical hour",
    "What the fourth little piggy had",
    "Zero",
    "Poor dog's portion",
    "Second to ___",
    "What one little piggy had",
    "Zero",
    "Amount in a Christie title",
    "All's alternative",
    "Zero",
    "Zilch, so to speak",
    "Opposite of all",
    "___ of the above",
    "Have ___ of (not allow)",
    "Not a lick",
    "Zippo",
    "___ of the above",
    "The absolute minimum",
    "Bar ___",
    "\"And Then There Were___\"",
    "Zero",
    "Nary a soul",
    "Zero",
    "Zilch",
    "\"And Then There Were ___\"",
    "A canonical hour",
    "Zilch"
  ],
  "bios": [
    "Life lines?",
    "Bits on book jackets",
    "Sections of online dating profiles",
    "Parts of Twitter profiles",
    "Playbill section, informally",
    "Bits of writing often accompanied by headshots",
    "Features on some jackets, in brief",
    "Many Wikipedia articles",
    "Obits, basically",
    "Stories of a lifetime?",
    "Some book jacket blurbs",
    "Head shot accompaniers, maybe",
    "Life stories, for short",
    "Dust jacket bits",
    "\"Thumbnail\" writings",
    "Life stories, for short",
    "Book jacket blurbs",
    "Life stories",
    "Resume addenda",
    "Who's Who contents",
    "Obits, basically",
    "Brief lives",
    "Book jacket items",
    "Life stories, for short",
    "Curricula vitae",
    "Photo accompaniers, at times",
    "They sometimes accompany photos",
    "Who's Who entries",
    "Life stories, in short",
    "Short lives?"
  ],
  "pain": [
    "Royal ___",
    "Total inconvenience",
    "Pill ... or a reason to take a pill",
    "Bother",
    "Masochist's pleasure",
    "Tylenol target",
    "Tough person to tolerate",
    "Big nuisance",
    "Nuisance",
    "Suffering",
    "Hassle",
    "Pest",
    "Distress",
    "Ibuprofen target",
    "What a dolorimeter measures",
    "Ache",
    "It may be unbearable",
    "Ache",
    "Sibling, at times?",
    "Nuisance",
    "Hassle",
    "What lines with stars at the end indicate in comics",
    "Pest",
    "Analgesic's target",
    "Aspirin target",
    "Cause of a wince",
    "Pest",
    "Nuisance",
    "Pill",
    "Result of punishment",
    "Ibuprofen target",
    "Baby brother, often",
    "Number's target?",
    "Feeling that makes you say \"Ow!\"",
    "Cause of a wince",
    "Bother",
    "What an analgesic stops",
    "Arduous chore",
    "Neuralgia",
    "Annoyance",
    "Cause to grimace",
    "Hurt",
    "Nuisance",
    "Excedrin target",
    "Annoyance",
    "Nice bread",
    "Aggrieve"
  ],
  "slow": [
    "Steady partner?",
    "Like business in the off-season",
    "Snail-like",
    "Drawn-out",
    "Largo or lento",
    "A little dense",
    "Diamond-shaped road sign",
    "Dawdling",
    "Street caution",
    "Taking forever",
    "Glacial",
    "School zone sign",
    "Brake",
    "Dense",
    "Brake",
    "___ burn",
    "Dull",
    "Apply brakes to",
    "Not busy",
    "Easy to catch, say",
    "School zone warning",
    "Somewhat dense",
    "Not very busy",
    "Not busy",
    "Tortoiselike",
    "Not rushed",
    "School zone warning",
    "Not very busy",
    "Like molasses",
    "Like rush hour traffic",
    "Glacial",
    "Not bright",
    "Curve caution",
    "Gradual",
    "Lento",
    "Unhurried",
    "School zone sign",
    "Like off-season business",
    "Hit the brakes",
    "Lento",
    "10 miles per hour, say",
    "Lento",
    "Deliberate",
    "Snaillike",
    "Traffic sign",
    "School zone sign",
    "Snaillike",
    "Leisurely",
    "School zone caution",
    "Dense",
    "Poky",
    "Obtuse",
    "Tortoiselike",
    "Tortoiselike",
    "Highway caution"
  ],
  "foul": [
    "Court infraction",
    "Word with weather or ball",
    "Awful-smelling",
    "Cry ___",
    "Kind of tip, in baseball",
    "One of six allowed to an N.B.A. player",
    "Hardly ice outside",
    "Fair alternative",
    "Awful",
    "Fair's opposite",
    "Ball hit out of bounds",
    "Infraction",
    "Cager's offense",
    "Cold and rainy",
    "Referee's call"
  ],
  "glad": [
    "Showy blossom in the iris family, for short",
    "Hefty rival",
    "Pleased",
    "Tickled",
    "Tickled",
    "Pleased",
    "Trash bag brand",
    "Content",
    "Feeling pleased",
    "Bag handle?",
    "Tickled",
    "Big name in trash",
    "Pleased",
    "Song classic \"___ to Be Unhappy\"",
    "Pleased",
    "Ziploc competitor",
    "Joyful",
    "Very willing",
    "Bag brand",
    "Rodgers and Hart's \"___ to Be Unhappy\"",
    "Tickled",
    "Pleased",
    "Pleased",
    "Delighted",
    "Happy",
    "Garden bloom, informally",
    "Pleased",
    "Trash bag brand",
    "Hefty competitor",
    "Pleased",
    "Ziploc competitor",
    "Garbage bag choice",
    "Pleased",
    "Tickled",
    "Happy",
    "Bag brand",
    "Garden bloom, informally",
    "Hefty competition",
    "Delighted",
    "Elated",
    "Garden bloom, informally",
    "Tickled pink",
    "Not a bit sorry",
    "Kind of hand",
    "\"___ to help\"",
    "Happy",
    "Showy flower, for short",
    "Contented",
    "Hefty competitor",
    "Willing",
    "More than willing",
    "Thrilled",
    "Pleased",
    "Not reluctant",
    "Tickled",
    "Tickled",
    "Bulbous flower, for short",
    "Irving Berlin's \"___ to Be Home\"",
    "Like some bags or rags",
    "Gratified",
    "Very willing",
    "Pleased",
    "Happy",
    "Wrap name",
    "Happy"
  ],
  "waif": [
    "Victor Hugo's Cosette, e.g.",
    "Ragamuffin",
    "Many a Dickensian child",
    "Little Orphan Annie, e.g.",
    "Oliver Twist, for example",
    "Young stray",
    "Twiggy's look in '60s fashion",
    "Cosette, e.g., in \"Les Miserables\"",
    "Street urchin",
    "Oliver Twist, e.g.",
    "Ragamuffin",
    "Street urchin",
    "Oliver Twist, e.g.",
    "Homeless child",
    "Ragamuffin",
    "Stray",
    "Ragamuffin",
    "Stray",
    "Homeless child",
    "Stray animal",
    "Street urchin",
    "Gamin",
    "Urchin"
  ],
  "nits": [
    "Tiny carps",
    "Small criticisms",
    "Minor annoying issues",
    "Trivial objections",
    "Petty criticisms",
    "Things faultfinders pick",
    "Cavils",
    "Quibbles",
    "\"You're missing a comma\" and others",
    "Lousy eggs?",
    "Cavils",
    "Tiny criticisms",
    "Trivial objections",
    "What the picky pick",
    "Cavils",
    "Small complaints",
    "Tiny carps",
    "Minuscule issues",
    "Tiny criticisms",
    "Picked things",
    "Tiny criticisms",
    "Things to pick",
    "Small carps",
    "Lice-to-be",
    "Picking ___ with",
    "Lice-to-be",
    "Quibbles",
    "Tiny complaints",
    "Things to pick",
    "Lousy eggs?",
    "Things to pick",
    "These may be picked",
    "Annual sports contests, for short",
    "Tiny complaints",
    "Hoops contests since '38",
    "Their parents are parasites",
    "They may be picked",
    "They may be picked",
    "Tiny criticisms that are picked",
    "Immature parasites",
    "Coll. hoops competitions",
    "Boneheads, in British slang",
    "Pesky insects",
    "Louse eggs",
    "Basketball tournaments",
    "Tiny criticisms",
    "Trivial pests?",
    "Louse eggs",
    "Tiny imperfections",
    "Picky people pick them"
  ],
  "foes": [
    "Opposing forces",
    "Rivals",
    "Friends' opposite",
    "The other side",
    "Enemies",
    "Opposite sides",
    "G.I. Joe and Cobra Commander, e.g.",
    "Anti bodies?",
    "The Jedi and the Sith, e.g.",
    "Two-Face and the Riddler, to Batman",
    "They're not on your side",
    "Achilles and Hector",
    "The North and the South, in the Civil War",
    "Nemeses",
    "They're not for you",
    "They're against each other",
    "Enemies",
    "Grendel and Beowulf",
    "Us and them, maybe",
    "The Blue and the Gray, once",
    "Adversaries",
    "Rivals",
    "Adversaries",
    "Rivals",
    "Opposing sides",
    "Antagonists",
    "Opposing sides",
    "Rivals"
  ],
  "amen": [
    "Enthusiastic assent",
    "\"My thoughts exactly!\"",
    "\"So true!\"",
    "Part of a church chorus",
    "\"You said it, sister!\"",
    "End of a blessing",
    "\"Right on!\"",
    "\"Couldn't have said it better!\"",
    "Repeated word in Hozier's 2014 hit \"Take Me to Church\"",
    "Last word of the New Testament",
    "\"You said it!\"",
    "\"You said it!\"",
    "Part of a church chorus",
    "\"Hear, hear!\"",
    "\"Absolutely!\"",
    "Wholehearted endorsement",
    "\"Can I get an ___?\"",
    "\"Right on\"",
    "Mass-produced response?",
    "\"You got that right!\"",
    "\"Preach!\"",
    "\"Couldn't agree more\"",
    "\"Ain't that the truth!\"",
    "\"And how!\"",
    "\"Right on!\"",
    "Last word said just before opening the eyes",
    "\"I agree!\"",
    "\"I agree fully!\"",
    "\"Yes, exactly!\"",
    "\"You said it, brother!\"",
    "What closes on Sundays?",
    "Grace period?",
    "Last thing said before eating?",
    "\"You said it!\"",
    "\"I'll second that!\"",
    "\"Preach!\"",
    "\"Truer words have never been spoken!\"",
    "\"You said it!\"",
    "\"Damn right!\"",
    "Emphatic agreement",
    "\"You can say that again!\"",
    "\"You said it!\"",
    "Grace ender",
    "Comment to a brother or sister",
    "\"I'll second that, brother!\"",
    "Religious agreement?",
    "Cry to a preacher",
    "\"Can I get an ___?\"",
    "Last word of grace",
    "\"So be it!\"",
    "Last word of grace",
    "\"You said it!\"",
    "Emphatic agreement",
    "\"It's true!\"",
    "Revival reply",
    "\"Absolutely, brother!\"",
    "\"Hear, hear!\"",
    "Many prayers end with it",
    "Famous last word",
    "Flock's cry",
    "\"Agreed!\"",
    "\"Couldn't agree with you more\"",
    "Word said just before opening the eyes",
    "\"___, brother!\"",
    "\"Absolutely right!\"",
    "Devotional period?",
    "\"Praise be!\"",
    "\"Agreed!\"",
    "\"You sure got that right!\"",
    "\"You said it, brother!\"",
    "\"You've got that right!\"",
    "Closing word",
    "\"You said it, brother!\"",
    "Call from the flock",
    "The answer to a preacher's prayers?",
    "\"I agree 100%\"",
    "___ corner",
    "It may follow \"forever and ever\"",
    "\"You said it, sister!\"",
    "Last part of Handel's \"Messiah\"",
    "Service closer",
    "\"Exactly right!\"",
    "Hymn ender",
    "\"You got that right!\"",
    "*Sermon closer",
    "\"You said it, brother!\"",
    "\"So true!\"",
    "Word of agreement",
    "Follower of \"for ever and ever\"",
    "Grace period?",
    "Service closer",
    "Mass conclusion",
    "Prayer's end",
    "Music to a minister's ears",
    "It's often said with the eyes closed",
    "Last word in the Bible",
    "Grace ender",
    "\"Ain't it the truth!\"",
    "\"So be it\"",
    "Grace period?",
    "\"You said it!\"",
    "Period of prayer?",
    "\"You said it, brother!\"",
    "\"So be it\"",
    "\"You said it, brother!\"",
    "\"Right on!\"",
    "Comment of concurrence",
    "Final word",
    "\"You said it!\"",
    "\"You got that right!\"",
    "\"That's what I'm talking about!\"",
    "Grace finisher",
    "Word of agreement",
    "Prayer period?",
    "\"You said a mouthful!\"",
    "Response to a brother?",
    "\"You got that right!\"",
    "Word said just before opening the eyes",
    "\"You got that right!\"",
    "\"You got that!\"",
    "\"Ain't that the truth!\"",
    "Call from the flock",
    "Word of agreement",
    "\"___, brother!\"",
    "Word said before opening the eyes",
    "\"So be it\"",
    "Father's conclusion",
    "Service approval",
    "\"You said it!\"",
    "Cry from the pews",
    "\"You said it, brother!\"",
    "\"Right on!\"",
    "Prayer's end",
    "\"You can say that again!\"",
    "Parishioner's line",
    "Service sign-off",
    "Final word",
    "Prayer's end",
    "Word said before looking up",
    "\"You said it!\"",
    "\"Right on!\"",
    "Grace period?",
    "\"Absolutely!\"",
    "\"You said it!\"",
    "Word of agreement",
    "\"Indeed!\"",
    "\"You can say that again!\"",
    "Mass exodus preceder?",
    "Flock's response",
    "\"I'll say!\"",
    "Word said just before opening the eyes",
    "End of a prayer",
    "Grace period?",
    "\"I agree completely!\"",
    "\"You said it!\"",
    "Top 10 hit for the Impressions, 1964",
    "\"That's how I see it, too\"",
    "End of grace",
    "\"I agree!\"",
    "\"You said it!\"",
    "Encouraging word",
    "\"Absolutely!\"",
    "Grace period?",
    "Last thing said before dinner?",
    "\"You said it, sister!\"",
    "Evangelical's cry",
    "Hymn word",
    "Emphatic agreement",
    "Word of agreement",
    "Brother's word?",
    "Revival cry",
    "Sermon closer",
    "Assent",
    "\"You got that right!\"",
    "\"You said it!\"",
    "So be it",
    "Grace period?",
    "Grace's end",
    "\"I couldn't agree with you more!\"",
    "Strong second?",
    "Prayer's end",
    "\"___ to that!\"",
    "Hymn sign-off",
    "Grace period?",
    "\"Right on!\"",
    "End of grace",
    "Last word in the New Testament",
    "\"Couldn't have said it better myself\"",
    "\"You said it!\"",
    "___ corner (church area)",
    "\"Right on!\"",
    "Revival meeting cry",
    "\"Right on!\"",
    "\"You said it, brother!\"",
    "The final word",
    "Word said with vigorous nodding",
    "Church response",
    "\"You said it, brother!\"",
    "Mass assent",
    "\"You can say that again!\"",
    "Word said before opening the eyes",
    "Prayer closer",
    "\"You said it!\"",
    "\"Right on, brother!\"",
    "\"You said it, brother!\"",
    "Revival word",
    "\"I second that!\"",
    "\"You said it!\"",
    "Cry from the flock",
    "Word in a spiritual",
    "Congregation's assent",
    "\"Right on, brother!\"",
    "Prayer closing",
    "\"You said it!\"",
    "\"Agreed!\"",
    "\"Indeed!\"",
    "\"It is so\"",
    "\"Absolutely!\"",
    "\"You said a mouthful!\"",
    "Revival shout",
    "\"So be it!\"",
    "Word of agreement",
    "Word of agreement",
    "\"You said it, brother\"",
    "The last word",
    "\"You said it, brother!\"",
    "Corner response",
    "Prayer ending",
    "\"I completely agree\"",
    "Kind of corner",
    "\"Right on!\"",
    "Revival meeting shout",
    "\"So be it\"",
    "\"You said it!\"",
    "Word agreement",
    "Sunday wrap",
    "Prayer ending",
    "Prayer's closing",
    "The last word?",
    "Verily",
    "\"You said it!\"",
    "Sherman Hemsley TV series",
    "\"I agree!\"",
    "Revival cry"
  ],
  "mass": [
    "Certain service",
    "The \"m\" of F = ma",
    "It may be critical",
    "Midnight ___",
    "Setting for \"Amazing Grace,\" maybe",
    "The \"m\" in the equation \"F = ma\"",
    "Important word in both physics and religion",
    "Cathedral service",
    "Factor in calculating an object's momentum",
    "Atomic ___",
    "Part of E = mc<sup>2</sup>",
    "Catholic service",
    "The \"m\" of e = mc<sup>2</sup>",
    "The \"m\" of E = mc<sup>2</sup>",
    "Church service",
    "Factor in force",
    "W*D",
    "Part of W.M.D.",
    "Force/acceleration",
    "Force = ___ x acceleration",
    "The \"m\" in E = mc<sup>2</sup>",
    "Astronomical datum",
    "Catholic service",
    "Figure in Newton's second law",
    "Gravitation consideration",
    "Part of W.M.D.",
    "Body measurement",
    "Communion service",
    "Sunday service",
    "Aggregate",
    "Boston's ___ Ave.",
    "Force divided by acceleration",
    "Part of an Einstein equation",
    "Weekly service",
    "Word with critical or Catholic",
    "Come together",
    "The \"m\" of E = mc squared",
    "It may be high on weekends",
    "Come together",
    "It may be high in church",
    "Weapons of ___ destruction",
    "Force ?? acceleration",
    "Father's offering",
    "Physics calculation",
    "What \"m\" is in F = ma",
    "It may be critical",
    "A pope may lead it",
    "Fathers may hold it",
    "Large-scale",
    "Certain service",
    "Physics calculation",
    "Word with land or critical",
    "Assemble",
    "It may be critical",
    "Service at St. Peter's",
    "Bach's \"___ in B Minor\"",
    "Haydn's \"Nelson,\" for one",
    "Physics calculation",
    "High time?"
  ],
  "pang": [
    "Sudden sensation",
    "Gut reaction, say",
    "Sharp feeling",
    "Twinge of guilt",
    "Twinge",
    "Feeling of hunger",
    "Hunger indicator",
    "Twinge",
    "Guilty sensation",
    "Qualm",
    "Bit of guilt, say",
    "Guilt symptom",
    "Sign of hunger",
    "Twinge",
    "Twinge",
    "Feeling of remorse, say",
    "Hunger twinge",
    "Twinge",
    "Sign of hunger",
    "Sharp pain, as from hunger",
    "Fleeting feeling",
    "Stab",
    "Hungry feeling",
    "Qualm",
    "Twinge",
    "Feeling of hunger",
    "Twinge",
    "Bit of distress"
  ],
  "joni": [
    "Singer Mitchell",
    "Iowa senator Ernst",
    "Folk singer Mitchell",
    "Folk singer Mitchell",
    "Singer Mitchell who wrote \"Woodstock\" (but didn't attend)",
    "Senator Ernst",
    "Mitchell with the platinum album \"Blue\"",
    "Iowa politico Ernst",
    "Songbird Mitchell",
    "Singer Mitchell",
    "Mitchell who wrote and sang \"Chelsea Morning\"",
    "Musical Mitchell",
    "\"Big Yellow Taxi\" singer Mitchell",
    "Mitchell who sang \"Big Yellow Taxi\"",
    "Musical Mitchell",
    "Singer Mitchell",
    "Mitchell who sang \"Big Yellow Taxi\"",
    "___ James, who sang \"Your Cheatin' Heart\"",
    "Mitchell with a guitar",
    "First name in folk",
    "Folk singer Mitchell",
    "Musical Mitchell",
    "\"Woodstock\" songwriter Mitchell"
  ],
  "need": [
    "What air is not for an anaerobe",
    "Have to have",
    "Lawyer, for a defendant, typically",
    "It's a must",
    "One might be pressing",
    "It's not merely a yen",
    "Can't live without",
    "Bargaining point that's nonnegotiable",
    "Kitchen, for a chef",
    "Grant factor, sometimes",
    "Basis of some scholarships",
    "Beg for",
    "Have (to)",
    "Morning coffee, for many",
    "Must get",
    "Call for",
    "\"No ___\"",
    "Requirement",
    "Grant consideration",
    "\"No ___\" (\"I'm fine\")",
    "Food or air",
    "Poverty",
    "Basis of a scholarship",
    "Food, shelter or clothing",
    "Attention, for some",
    "It's a must",
    "Triage criterion",
    "Requirement",
    "Want",
    "Beggary",
    "Requisite",
    "Must have",
    "Desideratum",
    "Call for",
    "Scholarship criterion, maybe",
    "Craving",
    "Scholarship criterion",
    "Triage determination",
    "Call for",
    "Paucity",
    "Cry for",
    "Passport for foreign travel, e.g.",
    "Aid consideration",
    "More than want",
    "Scholarship basis",
    "Desideratum",
    "If ___ be",
    "Must-have",
    "Destitution",
    "___-to-know basis",
    "Grant consideration",
    "Requirement",
    "Penury",
    "Financial aid factor",
    "Triage consideration",
    "More than desire",
    "It may be urgent",
    "Grant consideration",
    "\"___ a lift?\"",
    "Desideratum",
    "Poverty",
    "Call for",
    "Exigency",
    "Requirement",
    "Sine qua non",
    "Want",
    "Addiction",
    "Straits",
    "Scholarship consideration",
    "Crave",
    "Straits",
    "Lack",
    "Must-have",
    "Cry out for",
    "Must have",
    "Triage factor",
    "Penury",
    "Impecuniosity",
    "Sine qua non",
    "Call for",
    "\"No ___\"",
    "Impecuniosity",
    "Beggary",
    "Have to have",
    "Call for",
    "Penury",
    "Must-have item",
    "It's more than a desire",
    "Require",
    "Poverty",
    "Poverty",
    "Cry out for",
    "Scholarship criterion",
    "Requisite",
    "Requirement",
    "Essential",
    "Penury",
    "Grant criterion, often",
    "Scholarship basis",
    "Requirement",
    "Bad straits",
    "Financial aid criterion",
    "Require",
    "Not just desire",
    "Essential",
    "Poverty",
    "Reason for a donation",
    "Have to have",
    "What have-nots have",
    "Welfare state?",
    "Penury",
    "Craving",
    "Requirement",
    "Grant basis",
    "Exigency",
    "Straits",
    "Desideratum",
    "Requirement",
    "Cry out for",
    "Penury",
    "Poverty",
    "Requisite",
    "Exigency",
    "Desideratum",
    "Scholarship criterion",
    "Poverty",
    "Can't do without",
    "Financial aid criterion",
    "Be short of",
    "Poverty",
    "Indigence",
    "\"___ I say more?\"",
    "Sine qua non",
    "Call for",
    "Emergency",
    "Exigency",
    "Charity's calculation",
    "Requirement",
    "Lack",
    "Requisite",
    "Grant basis",
    "Requirement",
    "Difficulty, so to speak",
    "Sine qua non",
    "Lack",
    "Financial aid criterion",
    "Require",
    "Exigency",
    "Shortage",
    "Distress",
    "Require",
    "Welfare state?",
    "Call for",
    "Lack",
    "Shortage",
    "Penury",
    "Exigency",
    "Impecuniosity",
    "Desideratum",
    "Scholarship factor",
    "Basis for financial aid",
    "Beggary",
    "Welfare state?",
    "Grant criterion",
    "Urgent want",
    "Require",
    "Lack",
    "Must have",
    "Essential",
    "Require",
    "Straits",
    "Require",
    "Scholarship criterion",
    "Scholarship factor",
    "Want",
    "Scholarship consideration",
    "Requisite",
    "Require",
    "Shortfall",
    "Requisite",
    "Poverty",
    "No frill, this"
  ],
  "eyed": [
    "Looked at suspiciously",
    "Watched",
    "Green-___ monster",
    "Checked out",
    "Viewed",
    "Checked out",
    "Gave the once-over",
    "Checked out",
    "Sized up",
    "Kept a bead on",
    "Took a gander at",
    "Follower of clear or cross",
    "Sized up",
    "Observed closely",
    "Had a bead on",
    "Visually assessed",
    "Looked over",
    "Sized up",
    "Scrutinized",
    "Checked out",
    "Ogled",
    "Took a gander at",
    "Gave the once-over",
    "Checked out",
    "Looked at",
    "Regarded",
    "Kept in sight",
    "Visually assessed",
    "Observed",
    "Took a gander at",
    "Checked out",
    "Looked at carefully",
    "Viewed",
    "Carefully saw?",
    "Ogled",
    "Inspected",
    "Sized up visually",
    "Looked over",
    "Saw",
    "Gave the once-over",
    "Checked out",
    "Checked out",
    "Took in",
    "Took a gander at",
    "Visually assessed",
    "Assessed visually",
    "Got a load of",
    "Regarded guardedly",
    "Got a good look at",
    "Got a load of",
    "Spotted",
    "Spotted",
    "Spotted",
    "Watched warily",
    "Looked over",
    "Eagle-___",
    "Gazed at",
    "Ogled",
    "Spotted",
    "Ogled",
    "Checked out",
    "Checked out",
    "Observed",
    "Regarded guardedly",
    "Gave the once-over",
    "Considered",
    "Gave the once-over",
    "Regarded",
    "Looked at",
    "Viewed",
    "Checked out",
    "In the limelight",
    "Blue-___",
    "Saw",
    "Took a gander at",
    "Gave the once-over",
    "Ogled",
    "Took note of",
    "Ogled",
    "Saw",
    "Argus-___ (vigilant)",
    "Glanced at",
    "Examined",
    "Ogled",
    "Regarded",
    "Got a good look at",
    "Got a load of",
    "Checked out",
    "Word with eagle or bug",
    "Gave the once-over",
    "Observed",
    "Noted with interest",
    "Looked at",
    "Under observation",
    "Regarded",
    "Regarded",
    "Leered",
    "Used binoculars",
    "Observed critically",
    "Ogled",
    "Surveyed",
    "Looked at",
    "Appraised",
    "Rubbernecked",
    "Inspected",
    "Gazed upon",
    "Viewed",
    "Gave the once-over"
  ],
  "news": [
    "What breaks as it first comes out",
    "\"What's the ___?\"",
    "None of it is good, as the saying goes",
    "What's happening",
    "The latest",
    "6:00 broadcast",
    "What just happened?",
    "Contents of a Facebook feed",
    "It can be old or breaking",
    "The first \"N\" of CNN",
    "Update, say",
    "Google heading",
    "With 30-Down, brief article in a paper",
    "Part of a Facebook feed",
    "\"___ to me\"",
    "What's going on",
    "___ flash",
    "Word that's only coincidentally made up of the four main compass points",
    "The latest",
    "\"That's ___ to me\"",
    "Skinny",
    "The latest",
    "What literally comes from the north, east, west and south?",
    "\"This just in ...\" fare",
    "6:30 p.m. broadcast",
    "The first \"N\" of CNN",
    "Part of CNN",
    "Google heading",
    "See 21-Down",
    "Scoop",
    "Intelligence",
    "What's going on",
    "Intelligence",
    "Front page fill",
    "Latest",
    "NPR staple",
    "Wire material",
    "Word",
    "The latest",
    "On-the-hour radio offering",
    "Intelligence",
    "Six o'clock broadcast",
    "Kind of conference",
    "Part of CNN",
    "Kind of flash",
    "Top-of-the-hour radio offering",
    "The latest",
    "The latest",
    "Time piece",
    "Front-page stuff",
    "Part of CNN",
    "Tidings",
    "Kind of brief",
    "\"That's ___ to me!\"",
    "Kind of wire",
    "Info",
    "Latest word",
    "The latest",
    "Intelligence",
    "Part of CNN",
    "Part of CNN",
    "Tidings",
    "Part of CNN",
    "Front-page matter",
    "Word"
  ],
  "arid": [
    "Like Mars",
    "Saharan",
    "Like the climate of Death Valley",
    "Like most of Mars",
    "Really dry",
    "Dry",
    "Bone-dry",
    "Droughty",
    "Parched",
    "Lacking in excitement",
    "Desertlike",
    "Like much of Arizona",
    "Dry, as a desert",
    "Low-humidity",
    "Bad for growth, in a way",
    "Like much of Chile",
    "Extremely dry",
    "Like the central planet in \"Dune\"",
    "Lacking in excitement",
    "Barren",
    "Desertlike",
    "Parched",
    "Desert-dry",
    "Superdry",
    "Rarely getting rain",
    "Desertlike",
    "Lacking rainfall",
    "Like the middle of Australia",
    "Getting little rainfall",
    "Like much of Arizona",
    "Like the planet in \"Dune\"",
    "Saharan",
    "Like most of Mauritania",
    "Like much of Chile",
    "Like Antarctica",
    "Like much of Namibia",
    "Like an environment that's difficult for farming",
    "Dry",
    "Not getting much rain",
    "Unlikely to get rain",
    "Like a cactus's climate",
    "Xeric",
    "Like Death Valley",
    "Unimaginative",
    "Uninteresting",
    "Xeric",
    "Like much of the Southwest",
    "Parched",
    "Dry as a bone",
    "Dry as a bone",
    "Drought-prone",
    "Desertlike",
    "Parched",
    "Of no interest",
    "Lifeless",
    "Saharan",
    "Like the climate of 66-Down",
    "Desiccated",
    "Saharan",
    "Saharan",
    "Like arroyo areas",
    "Anhydrous",
    "Desertlike",
    "Like Death Valley",
    "Parched",
    "Like Australia's Outback",
    "Duller than dull",
    "Like a desert",
    "Perennially parched",
    "Bone-dry",
    "Parched",
    "Desertlike",
    "Unimaginative",
    "Like a lot of Australia",
    "Sterile",
    "Lacking interest",
    "Dry",
    "Parched",
    "Vapid",
    "Like the Sahara",
    "Like most of Mongolia",
    "Desertlike",
    "Like the Negev",
    "Kalahari-like",
    "Like the Gobi",
    "Like most of west Texas",
    "Parched",
    "Uninteresting",
    "Dry as dust",
    "Insipid",
    "Infecund",
    "Like the Kalahari",
    "Sterile",
    "Completely uninteresting",
    "Like Death Valley",
    "Mojave-like",
    "Like ideal cactus climate",
    "Like most of Oman",
    "Unstimulating",
    "Far from arable",
    "Barren",
    "Waterless",
    "Bone-dry",
    "Parched",
    "Gobi-like",
    "Wanting water",
    "Needing irrigation",
    "Dry",
    "Like droughty land",
    "Not very engaging",
    "Like a dust bowl",
    "Like the Gobi",
    "Nearly rainless",
    "Saharan",
    "Like the Sahara",
    "Dry-as-dust",
    "Like Death Valley",
    "Unimaginative",
    "Like Libya, largely",
    "Dry",
    "Parched",
    "Unimaginative",
    "Unrainy",
    "Parched",
    "Like the Sahara",
    "Fruitless",
    "Bone-dry",
    "Jejune",
    "Colorless, as writing",
    "Dry as a bone",
    "Like a desert",
    "Parched",
    "Fruitless",
    "Desiccated",
    "Saharalike",
    "Uninteresting",
    "Parched",
    "Like Patagonia",
    "Rainless",
    "Mojave-like",
    "Fruitless",
    "Moistureless",
    "Saharan",
    "Dry as a bone",
    "Parched",
    "Sterile",
    "Saharan",
    "Like the Kara Kum",
    "Saharan",
    "Parched",
    "Rainless",
    "Lifeless",
    "Unfruitful",
    "Like Mongolia",
    "Rainless",
    "Dry, as a desert",
    "Like Australia's western plateau",
    "Saharan",
    "Parched",
    "Bone-dry",
    "Desertlike",
    "Dry",
    "Droughtlike",
    "Very dry",
    "Lifeless",
    "Dry",
    "Saharan",
    "Desertlike",
    "Parched",
    "Jejune",
    "Unfertile",
    "Jejune",
    "Parched",
    "Having zero rainfall",
    "Parched",
    "Waterless",
    "Mojavelike",
    "Nonproductive",
    "Needing irrigation",
    "Desertlike",
    "Needing irrigation",
    "Waterless",
    "Unfit for farming",
    "Nonfertile",
    "Dry",
    "Needing rain",
    "Desertlike",
    "Saharan",
    "Like the Negev",
    "Needing irrigation",
    "Jejune",
    "Uninspiring",
    "Parched",
    "Thirsty",
    "Parched"
  ],
  "exgi": [
    "Nov. 11 honoree",
    "Vet",
    "Vet",
    "Nov. 11 honoree",
    "Grunt no more",
    "Vet",
    "U.S. military vet",
    "Nov. 11 honoree",
    "Veteran, perhaps",
    "W.W. II vet, e.g.",
    "W.W. II vet, e.g.",
    "Vet, of a sort"
  ],
  "zion": [
    "The Promised Land",
    "Utah national park",
    "National park through which the Virgin River runs",
    "National park NE of Las Vegas",
    "National park in Utah",
    "Jerusalem",
    "Promised Land, to Rastafarians",
    "Jewish homeland",
    "Jerusalem's Mount ___",
    "Holy Land",
    "Kolob Arch locale",
    "National park through which the Virgin River runs",
    "Jewish homeland",
    "The Jewish people",
    "Jewish homeland",
    "The Promised Land",
    "Utah's ___ Canyon",
    "Utah national park",
    "Heaven",
    "Israeli mount",
    "Utah's ___ National Park",
    "Israel",
    "Temple area of Jerusalem",
    "Jerusalem's Mount ___",
    "___ National Park, Utah",
    "Utah national park",
    "Utah park",
    "Israel",
    "Utah national park",
    "Holy Land",
    "Site of King David's royal residence"
  ],
  "flam": [
    "Bit of deception",
    "Deception, informally",
    "Deception",
    "Deception",
    "Humbug",
    "Ruse",
    "Drumbeat"
  ],
  "elks": [
    "Lodge group",
    "Group with lodges",
    "Fraternal order",
    "Fraternal group with a mammalian name",
    "Cougars' prey",
    "Fraternal order with an animal emblem",
    "Wapitis",
    "Order since 1868",
    "___ Lodge",
    "___ Club",
    "Fraternal group",
    "Group whose past members have included six U.S. presidents",
    "Wapitis",
    "Group to which five U.S. presidents have belonged, from Harding to Ford",
    "Fraternal club",
    "Part of B.P.O.E.",
    "Grand Lodge group",
    "Civic-minded group",
    "Antlered Yellowstone denizens",
    "Chicago-based fraternal order",
    "Antlered animals",
    "Order with a Grand Lodge",
    "Antlered animals",
    "Group started as the Jolly Corks",
    "Organization with an Exalted Ruler",
    "___ Club (civic group)",
    "Game with horns",
    "Fraternal group",
    "\"Benevolent\" order",
    "Group with a Grand Lodge",
    "Group led by a Grand Exalted Ruler",
    "Some lodge members",
    "Benevolent and Protective Order group",
    "Rockies rangers",
    "Game with racks",
    "Fraternal group",
    "Order whose members have included five U.S. presidents",
    "Part of B.P.O.E.",
    "Fraternal group",
    "Group with a Grand Lodge in Chicago",
    "Group with an Exalted Ruler",
    "Group whose logo has a clock set at 11:00",
    "Organization originally called the Jolly Corks",
    "Fraternal group",
    "Grand Lodge Convention attendees",
    "Club with a lodge",
    "Fraternal group",
    "Colorado Rockies game?",
    "Community service group",
    "Lodge fellows",
    "Civic group",
    "A large order",
    "The \"E\" of B.P.O.E.",
    "Part of B.P.O.E.",
    "Service group",
    "Forest rangers",
    "Lodge group",
    "Lodge membership, maybe",
    "Fraternal group",
    "The \"E\" in B.P.O.E.",
    "Civic group",
    "Antlered animals",
    "Fraternal members",
    "Fraternal group",
    "Fraternal fellows",
    "Some club members",
    "Doers of good civic deeds",
    "Fraternal fellows",
    "Group with a clock in its emblem",
    "The \"E\" of B.P.O.E.",
    "Civic group",
    "Organization with a lodge",
    "Lodge fellows",
    "Lodge fellows",
    "Part of B.P.O.E.",
    "Some fraternal members",
    "Part of B.P.O.E.",
    "Lodge members",
    "Club with a clock on its emblem",
    "Lodge members",
    "Longtime fraternal order",
    "Civic organization",
    "Large lodge"
  ],
  "inre": [
    "Concerning",
    "About",
    "Phrase starting a legal memo",
    "Concerning",
    "About, on a memo",
    "Memo opener",
    "About",
    "About, on a memo",
    "Memo heading",
    "Memo opener",
    "On the topic of",
    "Concerning",
    "Latin phrase on memos",
    "About",
    "Memo starter",
    "About, on memos",
    "About",
    "Concerning, to attorneys",
    "Memo header",
    "About",
    "About, at the start of a memo",
    "About",
    "Concerning, to a lawyer",
    "Subject preceder",
    "About, on a memo",
    "Memo starter",
    "About",
    "About",
    "Memo opener",
    "Concerning",
    "Concerning",
    "About",
    "Memo starter",
    "Memo heading",
    "About",
    "About",
    "Memo opener",
    "Dictator's beginning",
    "About",
    "Memo opener",
    "Latin phrase on a memo",
    "About, on memos",
    "Subject precursor",
    "Memo starter",
    "Memo opener",
    "About",
    "Dictator's first words?",
    "Memo intro",
    "Apropos of",
    "Concerning, on a memo",
    "Brief start",
    "About",
    "Start of a legal memo",
    "Memo subject header",
    "Concerning",
    "Regarding, on memos",
    "About",
    "Concerning",
    "Concerning",
    "Concerning",
    "About, on a memo",
    "About",
    "On the subject of",
    "Dictator's start",
    "Regarding, on a memo",
    "Dictator's phrase",
    "About",
    "Concerning",
    "About, on a memo",
    "About, on a memo",
    "Concerning, in a memo",
    "Concerning",
    "Memo starter",
    "About",
    "Concerning, on a memo",
    "About",
    "As to, on memos",
    "Memo starter",
    "Memo intro",
    "Concerning, on a memo",
    "Regarding",
    "Memo header",
    "Concerning",
    "Dictator's opening",
    "Preceder of a case name",
    "Memo starter",
    "Memo starter",
    "48-Across starter",
    "Legal memo starter",
    "Memo phrase",
    "About",
    "Memo starter",
    "Memo phrase",
    "On",
    "Regarding",
    "Memo heading",
    "Concerning",
    "Memo header",
    "About",
    "Concerning",
    "About",
    "About",
    "About",
    "About",
    "Subject preceder",
    "Memo opener",
    "About",
    "About",
    "About, on a memo",
    "Memo starter",
    "Phrase usually before a colon",
    "Regarding, to counsel",
    "Subject preceder",
    "About",
    "About",
    "About",
    "Memo opener",
    "As to, in legal memos",
    "Court case title phrase",
    "Memo starter",
    "Regarding",
    "Respecting",
    "Memo starter",
    "Pertaining to",
    "About",
    "Concerning",
    "About",
    "Memo heading",
    "Docket phrase",
    "Memo opener",
    "As to",
    "Topic lead-in",
    "With respect to",
    "Memo starter",
    "Concerning",
    "About",
    "Citer's opening",
    "Concerning, to a memo writer",
    "As to",
    "Brief phrase",
    "Memo opener",
    "Memo starter",
    "Memo phrase",
    "Reference preceder",
    "Memo starter",
    "Concerning, in memos",
    "About",
    "Concerning",
    "Concerning",
    "Regarding",
    "Concerning",
    "Subject preceder",
    "About",
    "Memo starter",
    "Legalistic phrase",
    "Memo phrase",
    "Memo starter",
    "Memo starter",
    "Memo opener",
    "Memo starter",
    "Anent",
    "As to",
    "About",
    "Message starter",
    "About",
    "Concerning",
    "Memo starter",
    "Subject preceder",
    "Legal memo phrase",
    "Memo starter",
    "About",
    "About",
    "About",
    "About",
    "Concerning",
    "About, in memos",
    "Regarding",
    "Regarding",
    "Subject preceder",
    "About",
    "Regarding, in legal memos",
    "Regarding",
    "Concerning",
    "Start of a legal memo",
    "As to",
    "Legalistic phrase",
    "Apropos of",
    "As regards",
    "As to",
    "About",
    "Concerning",
    "Legal memo starter",
    "About",
    "Regarding",
    "Memo starter",
    "Memo starter",
    "Apropos of",
    "About",
    "Concerning",
    "Concerning",
    "Pertaining to",
    "About",
    "About",
    "Concerning",
    "Concerning",
    "Regarding",
    "As to",
    "Memo starter",
    "About",
    "Anent",
    "Memo words",
    "About, in legalspeak",
    "Concerning",
    "Concerning",
    "About",
    "As to",
    "Legal memo starter",
    "Apropos of",
    "Legal memo starter",
    "Memo words",
    "Concerning, at law",
    "As to",
    "Concerning"
  ],
  "legs": [
    "Long-term appeal",
    "Streaks on the side of a wineglass",
    "Staying power, so to speak",
    "Turkey drumsticks",
    "Staying power",
    "Endurance",
    "Most mammals have four of them",
    "Staying power, informally",
    "Endurance, informally",
    "Last parts drawn in hangman",
    "Bridge table foursome",
    "Stamina, figuratively",
    "Parts of relays",
    "Durability",
    "Pants fillers",
    "Staying power",
    "Pants fillers",
    "Runners",
    "Important parts of dancers",
    "Edible frog parts",
    "Stems, so to speak",
    "Pants parts",
    "Stamina",
    "Staying power",
    "Stamina",
    "Walk-on parts?",
    "Crab morsels",
    "Longevity",
    "Endurance, informally",
    "Chicken drumsticks",
    "Endurance",
    "Rockette launchers?",
    "Endurance",
    "Longevity",
    "Longevity at the box office",
    "Endurance",
    "Staying power",
    "Runners",
    "Staying power",
    "Support system?",
    "Relay segments",
    "Chair supports",
    "Runners",
    "Stamina, so to speak",
    "Runners",
    "Diamond, of gangsterdom",
    "Trouser parts",
    "Table supports",
    "Gangster Diamond",
    "Mr. Diamond",
    "Drumsticks, basically",
    "Jack Diamond's nickname",
    "Strength, in Variety talk"
  ],
  "kurt": [
    "Rock star Cobain",
    "Rocker Cobain",
    "Former U.N. secretary general Waldheim",
    "Author Vonnegut",
    "Novelist Vonnegut",
    "Actor Russell",
    "Composer Weill",
    "Mathematician Godel",
    "Rock's Cobain",
    "Mathematician Godel",
    "Quarterback Warner",
    "Maestro Masur",
    "Composer Weill",
    "Russell of Hollywood",
    "Late rocker Cobain"
  ],
  "omit": [
    "Leave off",
    "Fail to mention",
    "Leave out",
    "Jump over",
    "Disregard",
    "Leave out",
    "Leave out",
    "Cut out",
    "Drop",
    "Opposite of stet",
    "Not to mention?",
    "Leave out",
    "Leave off, as the last letter in this clu",
    "Fail to include",
    "Drop",
    "Overlook",
    "Leave off, as the last word of a",
    "Leave out",
    "Leave out",
    "Leave out",
    "Leave unsaid",
    "Skip",
    "Strike out",
    "Leave out",
    "Leave out",
    "Drop",
    "Not include",
    "Cut",
    "Go without saying?",
    "Exclude",
    "Cut out",
    "Skip over",
    "Skip over",
    "Withhold, as information",
    "Cut",
    "Pass over",
    "Drop",
    "Leave blank",
    "Drop",
    "Fail to note",
    "Drop",
    "Exclude",
    "Neglect to mention",
    "Drop",
    "Exclude",
    "Drop",
    "Leave out",
    "Cut out",
    "Except",
    "Neglect",
    "Pass over",
    "Leave out",
    "Drop",
    "Pass over",
    "Fail to mention",
    "Leave out",
    "Skip",
    "Exclude",
    "Not mention",
    "Cross out",
    "Drop",
    "Skip",
    "Strike out",
    "Drop",
    "Overlook",
    "Forget to include",
    "Rule out",
    "Not include",
    "X out",
    "Leave out",
    "Fail to mention",
    "Fail to include",
    "Leave out",
    "Leave out",
    "Skip",
    "Jump over",
    "Except",
    "Forget, maybe",
    "Leave off",
    "Not name",
    "Forget to mention",
    "Skip past",
    "Skip",
    "Leave off",
    "Fail to mention",
    "Strike",
    "Drop",
    "Bypass",
    "Strike",
    "Jump over",
    "Fail to mention",
    "Leave out",
    "Pass over",
    "Drop",
    "Cut out",
    "Leave off",
    "Jump over",
    "Leave off",
    "Leave out",
    "Exclude",
    "Leave out",
    "Leave out",
    "Drop",
    "Drop",
    "Drop",
    "Leave out",
    "Exclude",
    "Pass over",
    "Pass over",
    "Leave off",
    "Leave out",
    "Pass over",
    "Drop",
    "Overlook",
    "Leave off",
    "Overlook",
    "Don't keep in",
    "Skip by",
    "Except",
    "Strike out",
    "Jump over",
    "Bypass",
    "Not name",
    "Go without saying",
    "Leave out",
    "Skip",
    "Skip",
    "Drop",
    "Leave out",
    "Bypass",
    "Cut",
    "Skip past",
    "Fail to mention",
    "Skip",
    "Drop a line?",
    "Pass by",
    "Leave out",
    "Leave out",
    "Forget about",
    "Skip over",
    "Miss",
    "Skip",
    "Skip",
    "Drop",
    "Pass over",
    "Skip",
    "Leave out",
    "Forget about",
    "Skip over",
    "Skip",
    "Drop",
    "Opposite of include",
    "Not name",
    "Exclude",
    "Skip",
    "Leave off",
    "Leave out",
    "Cross out",
    "Neglect",
    "Drop",
    "Fail to mention",
    "Leave out",
    "Leave out",
    "Drop",
    "Bleep",
    "Overlook",
    "Pass over",
    "Elide",
    "Skip",
    "Leave out",
    "Drop",
    "Drop",
    "Neglect",
    "Overlook",
    "Drop a line?",
    "Delete",
    "Strike out",
    "Leave out",
    "Forbear",
    "Skip over",
    "Pass over",
    "Drop",
    "Exclude",
    "Fail to mention",
    "Drop",
    "Overlook",
    "Drop",
    "Drop",
    "Cut out",
    "Leave out"
  ],
  "alot": [
    "Q: How much does it cost to park at stadiums? A: ___",
    "Bunches",
    "Frequently",
    "Oodles",
    "Not just occasionally",
    "Scores",
    "Bushels",
    "Bunches",
    "Oodles",
    "Loads",
    "Scores",
    "Much",
    "Boatloads",
    "Scads",
    "More than some",
    "Oodles",
    "Loads and loads",
    "Loads",
    "Gazillions",
    "Tons and tons",
    "Very often",
    "Tons",
    "Scads",
    "Boatloads",
    "Rafts",
    "Oodles",
    "Lodes",
    "Gazillions",
    "Loads",
    "Loads and loads",
    "Oodles",
    "Bunches",
    "Much",
    "What sweet gestures may mean",
    "Oodles",
    "Tremendously",
    "Much",
    "Scads",
    "Buckets",
    "Loads",
    "Jillions",
    "Very much",
    "Tons",
    "Much",
    "Abundantly",
    "Frequently",
    "Plenty",
    "Loads",
    "Often",
    "Jillions",
    "Oodles",
    "Scores",
    "Tons",
    "Legions",
    "Oodles",
    "Very much",
    "Tons",
    "More than some",
    "Bushels",
    "Very much",
    "Very much",
    "Very often",
    "Loads",
    "Oodles",
    "Plenty",
    "Frequently",
    "Legions",
    "Very much",
    "Tons",
    "Plenty",
    "Bunches",
    "Over and over",
    "Tons",
    "Plenty",
    "Gazillions",
    "Loads",
    "Bajillions",
    "All the time",
    "Heaps",
    "Oodles",
    "Jillions",
    "Scads",
    "Tons",
    "Gazillions",
    "Scads",
    "Very much",
    "Oodles and oodles",
    "Zillions",
    "Oceans",
    "Ever so much",
    "Heaps",
    "Loads",
    "Plenty",
    "Very much",
    "Oodles",
    "Oodles",
    "Plenty",
    "Plenty",
    "Jillions",
    "Loads",
    "Quite often",
    "Heaps",
    "Plenty",
    "Very much",
    "Often",
    "Gobs",
    "Scads",
    "Quite often",
    "Loads",
    "Jillions",
    "Plenty",
    "Rafts",
    "Much",
    "Often",
    "Tremendously",
    "Often",
    "Like crazy",
    "Tremendously",
    "Scads",
    "Stacks",
    "Oodles",
    "Jillions",
    "Tons",
    "Heaps",
    "Often",
    "Heaps",
    "Gobs",
    "Oodles",
    "Like crazy",
    "Oceans",
    "Heaps",
    "Tremendously",
    "Very much",
    "Gazillions",
    "Often",
    "Frequently",
    "Commonly",
    "Scads",
    "Buckets",
    "All the time",
    "Scads",
    "Loads",
    "Often",
    "Bunches",
    "Plenty",
    "Heaps",
    "Buckets",
    "Buckets",
    "Very much",
    "Tons",
    "Tons",
    "Oodles",
    "Gobs",
    "Loads",
    "Bunches",
    "Truckloads",
    "Loads",
    "Heaps",
    "Often",
    "Much",
    "Oodles",
    "Oodles",
    "Scads",
    "Very much",
    "Loads",
    "Extensively",
    "Scads",
    "Gobs",
    "Loads",
    "Tons",
    "Heaps",
    "Immensely",
    "Gobs",
    "Scads",
    "Scores",
    "Bushelfuls",
    "Scads",
    "Very much",
    "Very much",
    "Plenty",
    "Zillions",
    "\"Thanks ___!\"",
    "Very much",
    "Rafts",
    "Scads",
    "Tons",
    "Loads",
    "Loads",
    "Gobs",
    "Scads",
    "Gobs",
    "Greatly",
    "Heaps",
    "Loads",
    "Tons",
    "Very much",
    "Many",
    "Loads",
    "Tons",
    "Oodles",
    "\"Thanks___!\"",
    "\"Thanks___!\"",
    "Very much",
    "\"___ you know!\"",
    "Scads",
    "Greatly",
    "Oodles",
    "Very much",
    "Tons",
    "Gobs",
    "Plenty",
    "\"Thanks ___!\"",
    "Plenty",
    "Oceans",
    "Heaps",
    "Barrels",
    "Loads",
    "Much",
    "Immensely",
    "Oodles",
    "Scads",
    "Like crazy",
    "\"Thanks ___!\"",
    "Like crazy",
    "Scads",
    "Considerably",
    "Much"
  ],
  "nemo": [
    "Captain in \"The Mysterious Island\"",
    "Verne captain",
    "6-Down's submarine captain",
    "Captain who says \"I am not what you call a civilized man!\"",
    "Literary member of the League of Extraordinary Gentlemen",
    "\"Finding ___\" (2003 Pixar film)",
    "Object of a search in an animated 2003 film",
    "\"Little\" fellow of old comics",
    "Name that's 53-Across backward",
    "Captain of science fiction",
    "\"Little ___ in Slumberland\" (early comic)",
    "Disney clown fish",
    "\"Finding Dory\" fish",
    "Pixar's \"Finding ___\"",
    "Submarine commander of fiction",
    "Jules Verne captain",
    "Marlin's son, in a Pixar movie",
    "Whom Dory and Marlin found, in film",
    "Title character abducted in a hit 2003 film",
    "\"Finding Dory\" fish",
    "Fictional mariner also known as Prince Dakkar",
    "Orange-and-white Pixar title character",
    "Pixar's \"Finding ___\"",
    "Orange Pixar character",
    "Captain of fiction",
    "Friend of Bubbles, in an animated film",
    "\"Finding ___\" (2003 Pixar film)",
    "Jules Verne captain",
    "Marlin's son, in an animated film",
    "\"Little\" visitor to Slumberland, in old comics",
    "\"Finding ___\"",
    "Title subject of a search in a 2003 film",
    "Sub commander of fiction",
    "Land capturer, in literature",
    "Fictional character who says \"I am not what you call a civilized man!\"",
    "Little ___ (early comic character)",
    "Pixar title character",
    "Captain ___",
    "Title fish in a Pixar film",
    "Verne captain",
    "Pixar's \"Finding ___\"",
    "\"Little\" comics boy",
    "Captain in \"Twenty Thousand Leagues Under the Sea\"",
    "Literary captain who says \"I am not what you call a civilized man!\"",
    "Pixar title character",
    "Captain of sci-fi",
    "Captain of fiction",
    "Pixar's \"Finding ___\"",
    "Verne captain",
    "Pixar's \"Finding ___,\" 2003",
    "Nautilus leader",
    "Pixar title character",
    "Captain of Jules Verne's Nautilus",
    "Nautilus captain",
    "Seaman whose last words were \"God and my country!\"",
    "Nautilus skipper",
    "Whom Marlin sought in a 2003 film",
    "Pixar fish",
    "Fictional captain",
    "Nautilus captain",
    "\"Little ___ in Slumberland\" (pioneering comic strip)",
    "Captain who said \"Eat your pudding, Mr. Land\"",
    "Fictional submariner",
    "Fictional submariner",
    "\"Finding ___,\" 2003 film",
    "Nautilus captain",
    "Captain of literature",
    "Old comics boy",
    "\"The Mysterious Island\" captain",
    "Verne skipper",
    "Disney fish",
    "\"Finding ___\"",
    "Captain of the Nautilus",
    "\"Little ___ in Slumberland\" (early comic)",
    "\"The League of Extraordinary Gentlemen\" captain",
    "Cartoon fish",
    "Jules Verne captain",
    "Nautilus captain",
    "\"Little\" boy of early comics",
    "Nautilus skipper",
    "Disney clownfish",
    "Captain in \"The Mysterious Island\"",
    "Fictional captain",
    "Explorer of science fiction",
    "Lost fish of film",
    "Captain of the Nautilus",
    "Title character in a 2003 film from 44-Down",
    "Verne captain",
    "Captain of fiction",
    "Verne captain",
    "Fish in a Disney film",
    "Verne captain",
    "Verne's reclusive captain",
    "Verne skipper",
    "Verne skipper",
    "Captain of science fiction",
    "Captain of literature",
    "Captain played in film by James Mason",
    "Jules Verne captain",
    "Verne mariner",
    "Ned Land's rescuer",
    "Verne hero",
    "Nautilus captain",
    "Nautilus captain",
    "Nautilus name",
    "Little ___",
    "Verne captain",
    "Nautilus captain",
    "\"Little\" comics fellow",
    "\"Little ___\" (early comic strip)",
    "Verne captain",
    "\"Little ___\"",
    "Verne captain",
    "Verne's captain",
    "Verne villain",
    "Commander of the Nautilus",
    "Early comics name",
    "Verne captain",
    "Verne's captain",
    "Winsor McCay's \"Little\" one",
    "Verne captain",
    "Captain once played by Omar Sharif",
    "King of early comics",
    "\"20,000 Leagues\" captain",
    "Captain for many a league",
    "Verne captain",
    "Little chap of the comics",
    "Jules Verne captain",
    "Verne's captain",
    "Verne captain",
    "Nobody, in ancient Rome",
    "James Mason sci-fi role of 1954"
  ],
  "coed": [
    "Like Swarthmore, but not Bryn Mawr",
    "Like many a company softball game",
    "Like Brown University since 1971",
    "Like many intramural sports",
    "Like most dorms nowadays",
    "Unlike Bryn Mawr College",
    "Like some rec leagues",
    "Open to both men and women, as a college",
    "Like many dorms",
    "Like mixed doubles tennis, in college",
    "Mixed, in a way",
    "Like volleyball that's played jointly by men and women",
    "Like most college dorms nowadays",
    "Like Colgate University, since 1970",
    "Open to everyone, in a way",
    "Like some dorms",
    "What Bryn Mawr College is not",
    "What Yale became in 1969",
    "Like some fraternities nowadays",
    "Like most dorms nowadays",
    "Like many dorms",
    "Like many dorms nowadays",
    "Not same-sex",
    "Not single-sex, as a school",
    "Ole Miss miss, e.g.",
    "Like many college dorms, now",
    "College figure",
    "Like many college dorms",
    "For both sexes",
    "Like some college volleyball",
    "Like military academies, today",
    "Unlike Morehouse College",
    "Like Vassar, now",
    "Like some dorms",
    "Campus figure",
    "Like The Citadel, now",
    "Like some dorms",
    "Dorm denizen",
    "What Vassar became in 1968",
    "Like virtually all schools nowadays",
    "Like most colleges today",
    "Mixed",
    "Dorm dweller",
    "Like most colleges",
    "Like The Citadel, now",
    "Not restricted by sex",
    "Like most colleges",
    "\"Betty ___\" (1930 hit)",
    "Like many modern dorms",
    "Like some college volleyball",
    "Campus lass, in old lingo",
    "Like most colleges today",
    "Like Yale since 1969",
    "Like some schools",
    "Wellesley student"
  ],
  "ieoh": [
    "Architect ___ Ming Pei",
    "The \"I\" of I. M. Pei",
    "Architect ___ Ming Pei",
    "Architect ___ Ming Pei",
    "The \"I\" in I. M. Pei",
    "The \"I\" of I. M. Pei"
  ],
  "scat": [
    "Ella Fitzgerald forte",
    "\"Out, you!\"",
    "Musical riffing from Ella Fitzgerald",
    "\"Get outta here!\"",
    "Animal tracker's clue",
    "\"Shoo!\"",
    "\"Ski-ba-bop-ba-dop-bop,\" e.g.",
    "\"Shoo, kitty!\"",
    "Improvisational style",
    "\"Begone!\"",
    "\"Git!\"",
    "Jazzy style",
    "\"Out!\"",
    "\"Shoo!\"",
    "Nonsense singing",
    "Sing like Ella Fitzgerald",
    "Tracker's clue",
    "\"Get outta here!\"",
    "Order to go",
    "\"Get out!\"",
    "Order to go away",
    "\"Get outta here!\"",
    "\"Away with you!\"",
    "Improvise, in a way",
    "Nonsense in a recording studio",
    "\"Out! I said out!\"",
    "\"Shoo-be-doo-be-doo-wop,\" e.g.",
    "Riff, vocally",
    "\"Beat it!\"",
    "\"Out!\"",
    "\"Shoo, kitty!\"",
    "\"Minnie the Moocher\" feature",
    "Make nonsensical notes?",
    "\"Shoo!\"",
    "\"Get lost, kitty!\"",
    "Jazz style",
    "\"Get outta here!\"",
    "Silly singing",
    "Vamoose",
    "\"Begone!\"",
    "Ad-libbing vocal style",
    "\"Vamoose!\"",
    "Nonsense singing",
    "\"Git!\"",
    "Nonsense singing",
    "\"Git!\"",
    "Sing like Mel Torme",
    "Sing like Ella Fitzgerald",
    "Sing like Fitzgerald",
    "\"Vamoose!\"",
    "Ignore the lyrics?",
    "\"Get out of here!\"",
    "\"Get lost, kitty!\"",
    "\"Scram!\"",
    "It's nonsense",
    "Jazz variety",
    "Command to go",
    "Ella Fitzgerald specialty",
    "\"Git!\"",
    "Some jazz singing",
    "\"Vamoose!\"",
    "Jazzy improv style",
    "\"Shoo!\"",
    "Spout nonsense?",
    "Improvise vocally",
    "\"Begone!\"",
    "\"Git!\"",
    "Fitzgerald forte",
    "Ella Fitzgerald specialty",
    "Cry to a stray",
    "Jazz technique",
    "Leave in a hurry",
    "Singing style",
    "\"Out!\"",
    "Git",
    "\"Go away!\"",
    "\"Begone!\"",
    "Skedaddle",
    "\"Get out!\"",
    "Improvise, in a way",
    "Word to a tabby",
    "\"Out!\"",
    "\"Get away!\"",
    "\"Out!\"",
    "\"Vamoose!\"",
    "Some improvisation",
    "Improvise, musically",
    "\"Get out!\"",
    "\"Aroint thee!,\" in modern language",
    "\"Beat it!\"",
    "Louis Armstrong popularized it",
    "\"Out!\"",
    "Do \"T'aint What You Do,\" maybe",
    "\"Shoo!\"",
    "\"Shoo!\"",
    "Go \"shooby-doo,\" or just \"shoo\"",
    "Jazz performance",
    "\"Get outta here!\"",
    "Jazz style",
    "Imitate Mel Torme",
    "Sing \"shooby-doo\"",
    "Torme forte",
    "\"Begone!\"",
    "Sing like Ella",
    "Mel Torme piece",
    "Jazz singing",
    "\"Away with you!\"",
    "Ella Fitzgerald forte",
    "Sing \"shooby-doo\"",
    "\"Shoo!\"",
    "\"Get going!\"",
    "Jazz performance",
    "Fitzgerald forte",
    "Hightail it",
    "\"Scram!\"",
    "Specialty of 3 Down",
    "Fitzgerald's forte",
    "Command to Tabby",
    "Fitzgerald's forte",
    "Fitzgerald specialty",
    "Shoo, to Socks"
  ],
  "sade": [
    "Marquis de ___",
    "One-named Nigerian Grammy winner",
    "One-named singer awarded a C.B.E. in 2017",
    "One-named singer with Grammys in 1985 and 2010",
    "One-named Grammy-winning singer",
    "Singer of \"Smooth Operator\"",
    "One-named hitmaker born in Nigeria",
    "Singer with the 1986 #1 album \"Promise\"",
    "Grammy-winning singer with the 2010 album \"Soldier of Love\"",
    "One-named Grammy winner for \"Soldier of Love\"",
    "One-named singer with the 1985 hit \"Smooth Operator\"",
    "\"Smooth Operator\" singer, 1985",
    "\"The Sweetest Taboo\" singer, 1985",
    "\"Your Love Is King\" singer, 1984",
    "\"Smooth Operator\" singer, 1985",
    "One-named singer with the 1985 hit \"Smooth Operator\"",
    "\"The 120 Days of Sodom\" author",
    "\"The Sweetest Taboo\" singer",
    "\"Soldier of Love\" singer, 2009",
    "Singer with the 1986 #1 album \"Promise\"",
    "One-named Nigerian singer of \"The Sweetest Taboo\"",
    "Singer with the 1986 #1 album \"Promise\"",
    "Marat's counterpart in a Peter Weiss title",
    "Grammy winner born in Nigeria",
    "Marquis de ___",
    "Top 10 singer born in Nigeria",
    "One-named Nigerian songstress",
    "\"Philosophy in the Bedroom\" author, 1795",
    "Nigerian-born singing star",
    "Marquis de ___",
    "One-named singer of \"Smooth Operator\"",
    "Nigerian-born singer with five Top 40 hits",
    "Marquis de ___",
    "She had a 1993 hit with \"No Ordinary Love\"",
    "\"Smooth Operator\" singer",
    "One-named Grammy winner of 2001",
    "Marquis de ___",
    "\"Smooth Operator\" singer",
    "\"Maureen\" singer",
    "One-named singer",
    "\"The Sweetest Taboo\" singer",
    "\"Smooth Operator\" singer",
    "\"The Sweetest Taboo\" singer, 1985",
    "\"Vic and ___\" of oldtime radio",
    "Female singer who was Grammy's 1985 Best New Artist",
    "Nigerian-born pop star",
    "\"Justine\" author",
    "One-named singer from Nigeria",
    "She won a 2002 Grammy for \"Lovers Rock\"",
    "Marquis de ___",
    "Marquis name?",
    "Historical figure portrayed in \"Quills\"",
    "One-named jazzy singer",
    "Marquis de ___",
    "\"Smooth Operator\" singer",
    "Marquis de ___",
    "\"Smooth Operator\" singer",
    "Pop singer from Nigeria",
    "Marquis de ___",
    "Marquis de ___",
    "Last name in cruelty",
    "\"The Sweetest Taboo\" singer, 1985",
    "Marquis de ___",
    "One-named pop/R.&B. singer",
    "\"Smooth Operator\" chanteuse",
    "One-named Nigerian singer",
    "\"Smooth Operator\" singer",
    "Marquis de ___",
    "\"No Ordinary Love\" singer",
    "Singer born in Nigeria",
    "De ___ (\"Justine\" novelist)",
    "\"Smooth Operator\" singer",
    "\"The Sweetest Taboo\" singer"
  ],
  "ewer": [
    "Water pitcher",
    "Part of a fancy table setting",
    "Still-life vessel",
    "Still life subject",
    "Wide-mouthed jug",
    "Pitcher in paintings",
    "Item by a basin",
    "America's Cup, e.g.",
    "Washstand accompanier",
    "Fancy pitcher",
    "America's Cup, for one",
    "Vessel with a spout",
    "Washstand vessel",
    "Common still-life subject",
    "One with a stiff upper lip?",
    "Pitcher",
    "Decorative pitcher",
    "Fancy pitcher",
    "Pitcher",
    "Still-life subject",
    "Still-life pitcher",
    "Item whose name is derived from the Latin \"aquarius\"",
    "Big-mouthed pitcher",
    "Pitcher",
    "Accessory near a basin",
    "Still-life pitcher",
    "Pour thing?",
    "One with a neck, mouth and lip, but no eyes",
    "Common still-life subject",
    "Drink holder",
    "It has a big mouth",
    "Pitcher",
    "Professor says \"Ax wielder,\" pupil suggests ...",
    "Fancy pitcher",
    "Basin go-with",
    "Vessel by a basin",
    "Wide-mouthed pitcher",
    "Washstand vessel",
    "One whose mouth and lip may be painted",
    "Fancy pitcher",
    "Vessel by a basin",
    "It has a lip and a mouth but never speaks",
    "Ornamental jug",
    "Basin go-with",
    "Pitcher",
    "Household item with a neck",
    "Water holder",
    "Still-life object",
    "Prize cup, maybe",
    "Still-life object",
    "Pitcher",
    "Basin accompanier",
    "America's Cup, e.g.",
    "Decorative pitcher",
    "Pitcher",
    "The America's Cup trophy, e.g.",
    "Porcelain piece",
    "Porcelain piece",
    "Still-life figure",
    "The America's Cup trophy, e.g.",
    "Water pitcher",
    "Still-life subject",
    "It doesn't rain but it pours",
    "Decorative pitcher",
    "Water holder",
    "Subject of some still lifes",
    "Pitcher",
    "Fancy pitcher",
    "Flared-spout pitcher",
    "Water pitcher",
    "Pitcher",
    "Basin accompanier",
    "Ornamental vessel",
    "Still-life vessel",
    "Water carrier",
    "Pitcher",
    "Pitcher, but not the diamond kind",
    "Water pitcher",
    "Ablutionary vessel",
    "Relief pitcher?",
    "Pitcher",
    "Still-life piece",
    "Wide-mouthed pitcher",
    "Water carrier",
    "Basin item",
    "Painted vessel, perhaps",
    "Pitcher",
    "Painted pitcher",
    "It may have a fat lip",
    "Decorative pitcher",
    "One with a big mouth",
    "Pitcher",
    "Ablutionary vessel",
    "It'll hold water",
    "Item in Cassatt's \"Woman Bathing\"",
    "Pitcher",
    "Pitcher with a spout",
    "Water pitcher",
    "It has a big mouth",
    "Pitcher",
    "Widemouthed pitcher",
    "Drink server",
    "It has a big mouth",
    "Water server",
    "Water pourer",
    "Still-life subject",
    "Pitcher",
    "Pitcher",
    "Pourer",
    "Pitcher",
    "This has a big mouth",
    "Classical decorative object",
    "Pitcher",
    "Pitcher",
    "Large pitcher",
    "Washstand vessel",
    "Washstand item",
    "Pitcher",
    "Water pitcher",
    "Pitcher",
    "Water vessel",
    "Juice server",
    "Pitcher",
    "Pitcher",
    "Decorative pitcher",
    "Still-life figure",
    "Water container",
    "Juice server",
    "Water bearer?",
    "Water pitcher",
    "Pitcher",
    "Still-life subject",
    "Basin adjunct",
    "Jug",
    "Jug"
  ],
  "lien": [
    "It may be on the house",
    "Property claim",
    "Mortgage claim",
    "Property claim",
    "Financial claim",
    "Property claim",
    "Property interest",
    "Way of securing payment",
    "Bad thing to come up in a title search",
    "It might be attached to a car",
    "Protection for a lender",
    "Tax ___",
    "Claim on a house",
    "Security agreement",
    "Hold on property",
    "Home security measure",
    "Collateral, of a sort",
    "Financial claim",
    "Security measure",
    "What a title may come with",
    "Lender's security",
    "Debtor's burden",
    "Property restriction",
    "There might be one on a car",
    "Legal encumbrance",
    "A title may come with one",
    "Lender's security",
    "Property encumbrance",
    "It may be on the house",
    "Repo justification",
    "Home security measure",
    "Property claim",
    "Payment guarantee",
    "Legal claim",
    "Security interest",
    "Property lawyer's concern",
    "Bank holding",
    "Part of a home security system?",
    "Certain claim",
    "See 1-Down",
    "Mechanic's ___",
    "Debtor's worry",
    "Property claim",
    "Property claim",
    "Legal claim on property",
    "Bank holding",
    "Claim against property",
    "Property claim",
    "It's put on some houses",
    "Collateral option",
    "Property attachment",
    "It may be on a property",
    "Legal basis of a repo",
    "Claim on property",
    "Means of enforcing tax compliance",
    "Mortgage company's claim",
    "Claim of a sort",
    "Means of security",
    "Legal claim",
    "Something that may be on a house",
    "Legal safety net",
    "Property lawyer's concern",
    "Property law topic",
    "Bank holding",
    "Legal claim",
    "Property right",
    "Property claim",
    "Bank claim",
    "Form of security",
    "A creditor may hold it",
    "Financial security",
    "Creditor's claim",
    "Bank holding",
    "Debtor's worry",
    "Claim on property",
    "Debt protection",
    "Certain legal protection",
    "It may be on a house",
    "Certain encumbrance",
    "Bank holding",
    "Legal claim",
    "Certain attachment",
    "A property may have one on it",
    "Bank security",
    "Lender's recourse",
    "Legal claim",
    "Bank holding",
    "Lender's claim",
    "Mortgage",
    "Property claim",
    "Legal claim",
    "It may be on the house",
    "Lender's protection",
    "Mortgage, e.g.",
    "Lender's protection",
    "Mortgage",
    "Legal encumbrance",
    "Bank claim",
    "Property encumbrance",
    "Bank holding",
    "Tax ___",
    "Bank claim",
    "Collateral, maybe",
    "Claim on property",
    "Statutory ___",
    "Mortgage",
    "Bank claim",
    "Debt security",
    "Mortgage",
    "Mortgage",
    "Property encumbrance",
    "Property claim",
    "Kind of holder",
    "Property restriction",
    "Claim",
    "Legal restriction",
    "Bank holding",
    "Mortgage interest",
    "Debt satisfier"
  ],
  "ally": [
    "One who's got your back",
    "Any member of NATO to any other",
    "Partner in battle",
    "Partner",
    "Friend in a conflict",
    "Confederate",
    "Entente member",
    "Italy, to Germany, in W.W. II",
    "Friend in war",
    "Wartime friend",
    "Friend",
    "Form a 44-Across",
    "Bedfellow",
    "Confederate",
    "Get together",
    "One in your corner",
    "One of \"us\"",
    "See 30-Across",
    "Form a coalition",
    "Confederate",
    "Friend in war",
    "Supporting force",
    "Hitch horses",
    "Join (with)",
    "Confederate",
    "Friend in war",
    "Side (with)",
    "One who's supposed to be available if needed",
    "Friend in war",
    "Friend",
    "Compatriot",
    "Associate",
    "Get together",
    "Support provider",
    "Friend",
    "Get together (with)",
    "Friend in war",
    "Confederate",
    "Comrade in arms",
    "Treaty signatory",
    "Friend in war",
    "Join (with)",
    "Partner in war",
    "Friend in war",
    "Unite formally",
    "Supporter",
    "Comrade in arms",
    "Comrade in arms",
    "Supporter",
    "Friend in war",
    "Friend in war",
    "Treaty co-signer",
    "One who takes sides",
    "Confederate",
    "Friend in need",
    "Any NATO member",
    "One who's in your corner",
    "Team up (with)",
    "Compatriot",
    "Co-fighter",
    "Treaty signer",
    "Bedfellow",
    "One who's on your side",
    "Enemy's opposite",
    "Party to a defense pact",
    "Party to a defense treaty",
    "Friend in battle",
    "War partner",
    "Compatriot",
    "Associate",
    "Comrade",
    "Conjoin",
    "Arms recipient, maybe",
    "Confederate",
    "Unionize",
    "Confederate"
  ],
  "stow": [
    "Put (away)",
    "Stash belowdecks",
    "Stash",
    "Put away",
    "Squirrel away",
    "\"Please ___ your tray tables\" (plane request)",
    "Put away for safekeeping",
    "Put away",
    "Put in storage",
    "Place underneath one's seat, say",
    "Put in a plane's overhead compartment, say",
    "\"___ it!\" (\"Hush!\")",
    "Put in a hold",
    "Put in a hold",
    "Put away",
    "Put away",
    "Put away",
    "Put away",
    "Squirrel away",
    "Put in a hold",
    "Put in the overhead bin, say",
    "Pack in the overhead bin, say",
    "Place, as cargo",
    "Stash",
    "Lay away",
    "Store",
    "Store (away)",
    "Stash",
    "Throw below, say",
    "Get in a hold",
    "Put away",
    "Warehouse",
    "Store (away)",
    "Pack away",
    "Pack",
    "Pack away",
    "Cache",
    "Pack",
    "Lay up",
    "Put away",
    "Throw below",
    "Store safely",
    "Pack away",
    "Tuck away",
    "Put away",
    "Put away for later",
    "Pack away",
    "Carefully pack (away)",
    "Secure overhead, say",
    "Put in the hold",
    "Lodge",
    "Stash",
    "Pack away",
    "Put in storage",
    "Warehouse",
    "Store",
    "Put away",
    "Put aboard",
    "Pack overhead",
    "Put away",
    "Pack",
    "Tuck away",
    "Pack",
    "Pack",
    "Put away",
    "Pack neatly",
    "Pack away",
    "Put away",
    "Put away",
    "Load",
    "Pack",
    "Put away",
    "Put belowdecks",
    "Stash away",
    "Lodge",
    "Warehouse",
    "Pack (away)",
    "Put on board",
    "Put away",
    "Put on board",
    "Pack",
    "Pack away",
    "Put in the hold",
    "Stash away",
    "Put away",
    "Put away",
    "Put in the hold",
    "Put away",
    "Keep, as cargo",
    "Put into the hold",
    "Put in the overhead rack",
    "Pack",
    "Lay up",
    "Put in an overhead bin, say",
    "Pack away",
    "Put away",
    "Store, as a ship's cargo",
    "Pack",
    "Pack",
    "Put safely away",
    "Pack",
    "Put away",
    "Put away",
    "Stash the bags"
  ],
  "dyer": [
    "Easter egg decorator",
    "Colorist",
    "Textile worker",
    "Batik artist, e.g.",
    "Cloth colorist",
    "New brunette, say",
    "Worker in a textile factory",
    "Beautician, at times",
    "One changing locks?",
    "Textile artist, perhaps",
    "Colorist",
    "Salon employee",
    "Certain leatherworker",
    "Salon employee",
    "Wayne W. ___, author of \"Your Erroneous Zones\"",
    "Batik artist",
    "Lock changer?",
    "Colorist",
    "Easter egg decorator",
    "Not a natural blonde, say",
    "Hair colorer",
    "Certain rug worker",
    "Textile worker",
    "Self-help author Wayne",
    "Salon specialist",
    "Salon worker",
    "Miss Clairol user",
    "Salon employee",
    "Not a natural blonde",
    "Batik artisan",
    "Coloring expert",
    "Unnatural blonde, e.g.",
    "One whose roots may be showing",
    "Fabric worker",
    "Tannery employee",
    "\"Your Erroneous Zones\" author",
    "\"Your Erroneous Zones\" author Wayne",
    "One turning color?",
    "One whose work is always changing",
    "Gray remover, maybe",
    "Textile worker",
    "Textile worker",
    "Hairdresser, sometimes",
    "Henna handler"
  ],
  "kayo": [
    "Prizefight ending",
    "Pugilist's goal",
    "Clobber in the ring",
    "Put down for the count",
    "Floor",
    "Deck",
    "Send to the canvas",
    "Drop for the count",
    "Bout-ending slug",
    "Wallop in the boxing ring",
    "Big belt",
    "Huge hit",
    "Send to the canvas",
    "Lay flat",
    "Send to the canvas",
    "Floor",
    "A final blow"
  ],
  "nola": [
    "The Big Easy",
    "City at one end of Lake Pontchartrain, for short",
    "The Big Easy, in brief",
    "The Big Easy",
    "Where the dish oysters Rockefeller was invented, informally",
    "Mardi Gras locale, for short",
    "Home of The Times-Picayune, informally",
    "The Big Easy",
    "Home to Bourbon St.",
    "French Quarter city, familiarly",
    "Bourbon Street's locale, informally",
    "The Big Easy",
    "The Big Easy, by another nickname",
    "Jazz Fest setting, informally",
    "Home of The Times-Picayune, familiarly",
    "Saint's home, for short",
    "The Big Easy",
    "The Big Easy",
    "Saints' home, for short",
    "Superdome locale, informally",
    "The Big Easy",
    "Home to the N.F.L.'s Saints, informally",
    "Title girl in a 1922 hit",
    "The Big Easy, briefly",
    "Theme song of bandleader Vincent Lopez",
    "Daughter of Cap'n Andy in \"Show Boat\"",
    "?ôShow Boat?ö heroine",
    "Vincent Lopez's theme song",
    "The \"She\" in Spike Lee's \"She's Gotta Have It\"",
    "Vincent Lopez's theme song",
    "Vincent Lopez standard",
    "\"She's Gotta Have It\" girl",
    "Vincent Lopez's theme song",
    "Classic song with the lyric \"Hear your lonesome, lovesick sweetheart calling\"",
    "Town where Augustus died",
    "Vincent Lopez hit of 1922",
    "Vincent Lopez's theme song",
    "Vincent Lopez's theme song",
    "1922 Vincent Lopez hit",
    "Theme song of Vincent Lopez",
    "Vincent Lopez's theme song",
    "1937 Tommy Dorsey hit",
    "Vincent Lopez's theme song",
    "Vincent Lopez's theme song",
    "Vincent Lopez's theme song"
  ],
  "lite": [
    "Diet-friendly",
    "Miller ___ (low-calorie beer)",
    "Lo-cal",
    "Miller ___",
    "Lo-cal",
    "Lo-fat",
    "For the calorie-conscious",
    "Less filling",
    "Lo-cal",
    "Miller product",
    "Not full-bodied",
    "Diet, commercially",
    "Miller ___ (low-calorie beer)",
    "Having less fat",
    "Lo-cal",
    "Cigarette label word",
    "\"Less filling\" brand",
    "Lo-fat",
    "Alternative to Genuine Draft",
    "Miller option",
    "Low-calorie, in beer names",
    "Not so rich, informally",
    "Lo-cal",
    "Lo-cal",
    "Miller brand",
    "___ rock (radio format)",
    "Not full-bodied",
    "Low-cal",
    "Beer option",
    "For dieters",
    "Low-cal",
    "Not so heavy, informally",
    "Miller offering",
    "Lo-cal",
    "For the calorie-conscious",
    "For dieters",
    "Miller product",
    "Low-calorie",
    "Low-cal",
    "Kind of beer",
    "Lo-cal",
    "Lo-cal",
    "Lo-cal",
    "Low-cal",
    "Easy-listening",
    "Dietary, in ads",
    "Lo-cal",
    "Lo-cal",
    "Dietary, in ads",
    "Low-cal",
    "Miller beer",
    "Miller beer option",
    "Low-cal",
    "Beer variety",
    "Lo-cal",
    "Lo-fat",
    "Word for the diet-conscious",
    "Miller beer brand",
    "Lower-calorie beer",
    "Beer variety",
    "Lo-cal",
    "Lo-fat",
    "Lo-cal",
    "Lo-cal",
    "Beer order",
    "Kind of beer"
  ],
  "idea": [
    "Bit of creativity",
    "Something that might pop into your head",
    "Gist",
    "\"Where'd you get that ___?\"",
    "Piece of mind?",
    "It's a thought",
    "\"The right\" thing",
    "\"The slightest\" or \"the foggiest\" thing",
    "Mental spark",
    "It might occur to you",
    "\"The very ___!\"",
    "It's a thought",
    "Concept",
    "Brainstorming output",
    "Creative nugget",
    "Theory",
    "What a germ may become",
    "Brainstorm",
    "\"Here's an ___ ...\"",
    "Brainstorming result",
    "Conception",
    "It might be put in your head",
    "What a light bulb represents in the comics",
    "Mental flash",
    "Brainchild",
    "Flash of genius, say",
    "It can be bounced off someone",
    "\"The very ___!\"",
    "Patent preceder",
    "Notion",
    "Brainstorm",
    "Patent prerequisite",
    "Entrepreneur's start",
    "Start of creation?",
    "\"Any ___?\"",
    "Brainstorm",
    "\"You have no ___\"",
    "Notion",
    "What a light bulb indicates in cartoons",
    "Creative start",
    "Now there's a thought!",
    "Unpatentable thing",
    "Spark, so to speak",
    "Conception",
    "It's just in your head",
    "Cents",
    "What a cartoon light bulb represents",
    "\"The very ___!\"",
    "Notion",
    "Inspiration",
    "Think piece?",
    "Think tank product",
    "\"I had no ___!\"",
    "What you may think of",
    "Suspicion",
    "Clue",
    "\"What's the big ___?\"",
    "It comes to a head",
    "What comes to mind",
    "Noodle concoction?",
    "Notion",
    "One that's out of one's head?",
    "Impression",
    "Brainstorm",
    "Notion",
    "\"Paris is always a good ___\" (line from the film \"Sabrina\")",
    "It might begin with a \"What if ...?\"",
    "Bean sprout?",
    "One may get carried out",
    "Brainchild",
    "Noodle product",
    "Something talked about in a TED talk",
    "\"Whose ___ was this?\"",
    "Bean product?",
    "Inkling",
    "Start of something big?",
    "It may be just a hunch",
    "It may be bounced off someone",
    "Notion",
    "Inkling",
    "Potential solution",
    "Writer's block buster",
    "Fancy",
    "The thought that counts?",
    "Thought",
    "\"What's the big ___?\"",
    "Skull session result",
    "\"No ___\" (\"Don't ask me\")",
    "Suspicion",
    "A good one is often carried out",
    "The thought that counts?",
    "It begins at conception",
    "Spark, so to speak",
    "Think about it",
    "It's a thought",
    "Word exclaimed after \"no\" or \"good\"",
    "Possible solution",
    "Brainstorm",
    "Dome light?",
    "Theory",
    "Noodle product?",
    "\"You have no ___\"",
    "Brainstorm",
    "Notion",
    "\"You have no ___!\"",
    "Glimmering",
    "\"Drawing is putting a line round an ___\": Henri Matisse",
    "Something that can't be patented",
    "\"If at first, the ___ is not absurd, then there is no hope for it\": Einstein",
    "---",
    "Notion",
    "It's just a thought",
    "Invention starter",
    "Comic-strip light bulb",
    "Brain matter?",
    "It's a thought",
    "Child of invention?",
    "Think piece?",
    "Inkling",
    "Start of a plan",
    "Noodle product?",
    "Mental flash",
    "\"Aha!\" elicitor",
    "Head light?",
    "Coconut yield?",
    "It may be bright",
    "Trial balloon, e.g.",
    "\"What's the big ___?\"",
    "\"No ___\" (\"Beats me\")",
    "Imagine that",
    "Something to think about",
    "Mental flash",
    "Inspiration",
    "Brainchild",
    "Inkling",
    "Theory's start",
    "\"The very ___!\"",
    "Think tank nugget",
    "Bean sprout?",
    "It pops into the head",
    "Reason to shout \"Eureka!\"",
    "Notion",
    "Fancy",
    "Guess",
    "Fantasy",
    "\"I had no ___!\"",
    "Notion",
    "Think tank nugget",
    "What comes to mind",
    "Think about it",
    "Notion",
    "What a germ may become",
    "\"You have no ___\"",
    "Cranial bulb?",
    "Conception",
    "Conception",
    "\"Good ___!\"",
    "Genesis of an invention",
    "Prelude to a solution",
    "\"The very ___!\"",
    "Just think of it",
    "Think tank nugget",
    "Piece of mind?",
    "Abstraction",
    "Trial balloon, e.g.",
    "\"I had no ___!\"",
    "Bright thought",
    "Think tank nugget",
    "Noodle concoction?",
    "Concept",
    "Mental flash",
    "Noodle product?",
    "Inkling",
    "What comes to mind",
    "Inspiration",
    "Think tank output",
    "Bean concoction?",
    "Brainstorm",
    "Thesis basis",
    "Notion",
    "What a germ may become",
    "It comes to mind",
    "It has a germ",
    "Creative spark",
    "It comes to mind",
    "Mental flash",
    "Brainstorm",
    "\"The very ___!\"",
    "Bean product?",
    "Brainchild",
    "Notion",
    "Head piece?",
    "Guess",
    "Light bulb's signification, in cartoons",
    "Inventor's start",
    "Creative spark",
    "Start of an invention",
    "Matter for the gray matter",
    "Brainstorm",
    "Proposal",
    "Possible solution",
    "\"What's the ___?!\"",
    "Brain wave",
    "Creative spark",
    "Brain wave",
    "Inventor's starting point",
    "Inspiration",
    "Plan of action",
    "Just a thought",
    "Clever thought",
    "Think tank nugget",
    "Glimmering",
    "Inkling",
    "Matter for the gray matter",
    "Edison product",
    "Flash of inspiration",
    "\"I had no ___!\"",
    "Brain wave",
    "Notion",
    "Bit of planning",
    "Bright thought",
    "Brainstorm",
    "Concept",
    "Piece of one's mind?",
    "Think about it",
    "Noodle concoction",
    "Inkling",
    "Notion",
    "Concept",
    "The light bulb, to Edison",
    "Brain wave",
    "Brainstorm",
    "Concept",
    "Sneaking suspicion",
    "Brain popper",
    "Flash",
    "Brainstorm",
    "Light bulb, figuratively",
    "Notion",
    "Notion",
    "Sneaking suspicion",
    "Patent prerequisite",
    "Notion",
    "\"I had no ___!\"",
    "Inkling",
    "Bright thought",
    "Fancy",
    "Inkling",
    "Conception",
    "Brainstorm",
    "\"I had no ___!\"",
    "Brainwave",
    "Start of something big?",
    "Concept",
    "Inspiration",
    "Designer's need",
    "Brainstorm",
    "Something to think about",
    "Inventive thought",
    "Inspiration",
    "Brainstorm",
    "It may be fixed",
    "It might make you say \"Aha!\"",
    "The very notion",
    "Fancy",
    "Brainstorm",
    "Think tank product",
    "Concept",
    "Notion",
    "Essay's basis",
    "Bright thought",
    "Brainstorm",
    "Game plan",
    "Brainstorm",
    "Brainstorm",
    "Think of it!",
    "Conception",
    "Bright thought",
    "Light bulb, in cartoons",
    "Think of it!",
    "Brainstorm",
    "Sneaking suspicion",
    "Brainstorm",
    "\"Good ___!\"",
    "Glimmering",
    "Brainstorm",
    "Bright thought",
    "\"What's the big___?\"",
    "Impression",
    "Think tank output",
    "Bright thought",
    "Brainstorm",
    "Brainstorm",
    "Creative start",
    "Meaning",
    "Brainchild",
    "Brainstorm",
    "Thought",
    "Stars : pain :: light bulb : ___",
    "Notion",
    "Brainstorm",
    "Think tank nugget",
    "Notion",
    "Think about it",
    "\"The very ___!\"",
    "Impression",
    "Notion",
    "Something to think about",
    "Start of an invention",
    "Inkling",
    "Flash",
    "Imagine that!",
    "Concept",
    "Sneaking suspicion",
    "Conception",
    "\"The very ___!\"",
    "Bit of brainwork",
    "Think of it!",
    "Brainstorm",
    "\"I have no ___!\"",
    "Brainchild",
    "Fancy",
    "\"The ___!\" (hmmph!)",
    "Impression",
    "Madison Avenue product",
    "Creative input"
  ],
  "roma": [
    "Colosseo locale",
    "Capitale of the Lazio region of Italy",
    "Film that lost to \"Green Book\" for Best Picture",
    "The Eternal City, to natives",
    "Capital of Italia",
    "\"Touched by an Angel\" actress Downey",
    "Locale of the Campo de' Fiori",
    "Capital of Italia",
    "Citta about 250 miles SE of 45-Across",
    "Locale of the sculpture \"La Bocca della Verita\"",
    "Fontana di Trevi locale",
    "Tomato variety",
    "Capital of Italia",
    "Oblong tomato",
    "Monte Palatino locale",
    "Egg-shaped tomato",
    "Locale of the Citta del Vaticano",
    "See 11-Down",
    "Gypsy people",
    "Capital of Italia",
    "Ruler of Gallia and Britannia, once",
    "La Citta Eterna",
    "Capital of Italia",
    "Capital in Europa",
    "See 52-Down",
    "\"Arrivederci ___\"",
    "Italia's capital",
    "Tomato type",
    "Where \"La Dolce Vita\" was filmed",
    "Capital of Italia",
    "Home of Citta del Vaticano",
    "Capital of Italia",
    "Home of the Galleria Borghese",
    "Capital of Italia",
    "City in Italia",
    "La Citta Eterna",
    "Home of Citta del Vaticano",
    "Massenet opera",
    "Home of Aeropuerto de Fiumicino",
    "Italia's capital",
    "Capital of Italia",
    "Capital of Italia",
    "Campagna di ___ (geographical region)",
    "\"La Dolce Vita\" setting",
    "Capital south of Venezia",
    "Italia's capital",
    "Italia's capital",
    "Italia's capital",
    "Il pontefice's home",
    "Alitalia destination",
    "Actress Downey",
    "Tomato type",
    "Alitalia destination",
    "Alitalia destination",
    "Capital of Italia",
    "Alitalia destination",
    "Sophia Loren's birthplace",
    "Site of the Piazza del Popolo",
    "Pontifices' home",
    "Tomato type",
    "Capital of Italia",
    "Capital of 62-Across",
    "Tomato variety",
    "Basilica Julia site",
    "Colosseo site",
    "\"Touched by an Angel\" star Downey",
    "Ancient capital",
    "Alitalia stop",
    "\"Arrivederci ___\"",
    "Where the Via del Corso runs",
    "Tevere's city",
    "1990 World Cup site, locally",
    "Capital of Italia",
    "Tomato type",
    "Where the Villa Borghese is",
    "Piazza di Spagna site",
    "\"Arrivederci ___\"",
    "Italia's capital",
    "Province of Latium",
    "Villa Borghese site",
    "It's on the Tevere"
  ],
  "task": [
    "Item on a list",
    "Piece of work",
    "Something to do",
    "Kind of force",
    "Job to do",
    "Job to do",
    "Part of a mission",
    "Project manager's assignment",
    "Bit of work",
    "Job to do",
    "Charge",
    "What you might be taken to",
    "To-do list item",
    "Mission",
    "Undertaking",
    "Up to the ___",
    "Job",
    "Something on a to-do list",
    "Washing the dishes, e.g.",
    "Unpleasant thing to be taken to",
    "Chore",
    "Agenda item",
    "Big to-do?",
    "Job to do",
    "Work to do",
    "Job",
    "Agenda unit",
    "Hoeing the garden, e.g.",
    "It's a piece of work",
    "Assignment",
    "Mission",
    "Charge",
    "Something to do",
    "Job",
    "Tall order",
    "Item on a to-do list",
    "Mission",
    "Job to do",
    "Something to do",
    "Take to ___ (reprimand)",
    "Charge",
    "What to do",
    "What to do?",
    "What to do",
    "Mission",
    "A real piece of work",
    "Something to do",
    "Something to do",
    "Bit of business",
    "Item on a to-do list",
    "Chore",
    "Stint",
    "Tedious undertaking",
    "Difficult obligation"
  ],
  "semi": [
    "Highway hauler",
    "Final Four game, e.g.",
    "Match before the final",
    "Formal introduction?",
    "Rig",
    "Freeway hauler",
    "Half up-front?",
    "Half in advance?",
    "Long truck",
    "One of two for four",
    "18-wheeler",
    "Vehicle that can jackknife",
    "What Brits call an \"articulated lorry\"",
    "Game before the final",
    "Vehicle with a cab",
    "Prefix with final or formal",
    "Interstate hauler",
    "One of two in a tournament",
    "Rig on the road",
    "Weigh station sight",
    "One for two of four",
    "It's good for the long haul",
    "Big rig",
    "Final Four game",
    "It's bigger than a quarter",
    "What often produces passing thoughts?",
    "Cab locale",
    "Certain playoff game",
    "Big long-distance carrier?",
    "Big rig",
    "Hard-to-turn vehicle",
    "Big rig",
    "Round for the final four",
    "18-wheeler",
    "Penultimate match",
    "Pro beginner?",
    "Long hauler",
    "One of two for four",
    "Convoy component",
    "Opening half?",
    "18-wheeler",
    "Final preceder",
    "18-wheeler",
    "Hard-to-park vehicle",
    "*It's bad when it knifes",
    "Big hauler",
    "Part of a convoy",
    "Important match",
    "Place for a cab",
    "Big rig",
    "Formal introduction?",
    "Prefinal game",
    "Many-axled vehicle",
    "Big rig",
    "Big truck",
    "Rest stop sight",
    "Rest stop sight",
    "Important match",
    "Teamster's rig",
    "Final Four game",
    "Big rig",
    "Round before the final",
    "Rest stop sight",
    "Weigh station sight",
    "Interstate sight",
    "Vehicle that can jackknife",
    "Formal introduction?",
    "High roller?",
    "Highway behemoth",
    "Big rig",
    "Teamster's rig",
    "Big rig",
    "Trucker's rig",
    "Big rig",
    "Teamster's rig",
    "Circular opening?",
    "18-wheeler",
    "Big match",
    "Quarter follower",
    "18-wheeler",
    "Cargo carrier",
    "Rig",
    "Big rig",
    "Final precursor",
    "Rest stop sight",
    "Final Four game",
    "Big rig",
    "Final preceder",
    "18-wheeler",
    "Rest area sight",
    "Rest area sight",
    "Sesqui- divided by three",
    "It's good for the long haul",
    "Rig",
    "Prefix with sweet",
    "Highway jackknifer",
    "Final Four game",
    "It may deliver the goods",
    "Big rig",
    "Annual opener",
    "It may make a big haul",
    "Big rig",
    "Final Four game",
    "It might jackknife",
    "Biographical opening?",
    "Conductor's intro?",
    "It may jackknife",
    "Long hauler",
    "Tractor-trailer",
    "Formal introduction?",
    "Interstate hauler",
    "Formal introduction?",
    "Prefix with conductor",
    "Big rig",
    "Tractor-trailer",
    "Top Four matchup",
    "Rig",
    "18-wheeler",
    "Game for two of four",
    "Highway hauler",
    "Trucking rig",
    "Automatic start",
    "Tournament match",
    "This might be in for the long haul",
    "Highway hauler",
    "Conductor's intro",
    "Interstate hauler",
    "Highway hauler",
    "Conductor's beginning",
    "18-wheeler",
    "Big rig",
    "Conductor's start?",
    "Big rig",
    "Sort of, for starters",
    "Interstate truck",
    "Trailer",
    "Prefix with circle",
    "Big rig",
    "Convoy component",
    "Hauler",
    "Partly",
    "18-wheeler",
    "Big rig",
    "Penultimate round",
    "It's good for the long haul",
    "Rig",
    "Rig"
  ],
  "over": [
    "\"Your turn,\" on a walkie-talkie",
    "Set of six balls, in cricket",
    "[Turn the page]",
    "Finished",
    "Done",
    "Having moved on from",
    "\"Your turn,\" in radio lingo",
    "On top of",
    "On top of",
    "Not loving anymore",
    "Above",
    "The boss of",
    "Finished",
    "Finished",
    "\"Your turn,\" on a walkie-talkie",
    "Complete",
    "Completed",
    "It may precede \"copy\"",
    "Finished",
    "No longer bothered by",
    "\"Your turn,\" on a walkie-talkie",
    "On top of",
    "Finished",
    "See 36-Across",
    "5-Across, with respect to this answer's location",
    "So last year, as a fad",
    "[continued]",
    "\"Your turn,\" to a walkie-talkie user",
    "Dun",
    "Through with",
    "No more",
    "Finished",
    "Part of an order for eggs",
    "No longer stuck on",
    "\"Your turn to talk,\" on radio",
    "High, as a guess",
    "___ the top",
    "In the strike zone",
    "Another time",
    "Key word #1",
    "\"I'm ___ it\"",
    "\"Your turn,\" on a walkie-talkie",
    "No longer interested in",
    "Certain wager",
    "In preference to",
    "*TC",
    "More than",
    "More than",
    "Supervising",
    "Choice on a gambling line",
    "Word at the bottom of a page, perhaps",
    "Through with",
    "Spanning",
    "Ham's handoff",
    "Finito",
    "Supervising",
    "See 59-Down",
    "Partner of \"done with\"",
    "Finito",
    "Atop",
    "\"Now you speak\"",
    "No longer in love with",
    "Above",
    "Above",
    "More than",
    "Through",
    "No longer disturbed by",
    "No longer interested in",
    "Done",
    "See 19-Across",
    "In the strike zone",
    "[Turn the page]",
    "Control tower word",
    "___ easy",
    "Just a memory now",
    "Superior to",
    "Much-repeated word in air traffic control",
    "Recovered from",
    "Done",
    "Supervising",
    "See 68-Across",
    "\"Get ___ it!\"",
    "Radioer's word",
    "\"___ and out\"",
    "On the high side, as a guess",
    "Through with",
    "Eggs ___ easy",
    "Frequent word from ham operators",
    "Done with",
    "Recovered from",
    "Recovered from",
    "1964 Dave Clark Five song \"Glad All ___\"",
    "Supervising",
    "Supervising",
    "[see other side]",
    "Outranking",
    "Walkie-talkie word",
    "Partner of \"out\"",
    "All done",
    "In the past",
    "Finished",
    "[continued on the other side]",
    "Done",
    "During",
    "Finito",
    "\"Back to you\"",
    "Recovered from",
    "Throughout",
    "Of higher rank than",
    "In preference to",
    "In charge of",
    "Kaput",
    "In the past",
    "See 13-Down",
    "Finished",
    "Done with",
    "\"Roger, ___ and out\"",
    "Done with",
    "Finito",
    "Eggs order",
    "No more",
    "Settled",
    "Completed",
    "Finished",
    "Eggs ___ easy",
    "Transmission closer",
    "Transmission ender",
    "More than",
    "Through",
    "___ there",
    "In excess of",
    "[see other side]",
    "Finito",
    "Put one ___",
    "Done with",
    "Finished",
    "Above",
    "Completed",
    "With 45-Down, words after \"Pssst!\"",
    "Concluded",
    "Walkie-talkie word",
    "Partner of \"done with\"",
    "\"Your turn,\" to a 5-Down",
    "In preference to",
    "Done with",
    "Through",
    "Concluded",
    "Word to a C.B. operator",
    "Completed",
    "Past",
    "Wrapped up",
    "Lapsed",
    "\"Your turn\"",
    "Finito",
    "___ easy (eggs order)",
    "\"Your turn\"",
    "[see other side]",
    "Kaput",
    "Message ender",
    "Superior to",
    "Surpassing in rank",
    "In charge of",
    "Walkie-talkie word",
    "Dispatcher's word",
    "Kaput",
    "Out's partner",
    "Concluded",
    "Word after \"roger\"",
    "Finished",
    "Above",
    "Completed",
    "\"Roger, ___ and out!\"",
    "Ham's punctuation",
    "Partner of \"done with\"",
    "Walkie-talkie word",
    "No longer thinking about",
    "[see other side]",
    "Finished",
    "Finished",
    "Finished",
    "Go ___ (exceed)",
    "Finito",
    "No longer stuck on",
    "Partner of \"done with\"",
    "Covering",
    "Finished",
    "Fini",
    "CBer's end-of-message word",
    "Through",
    "Walkie-talkie word",
    "Out's partner",
    "Walkie-talkie message ending",
    "Beyond",
    "Once more or no more",
    "By means of",
    "\"Your turn,\" via radio",
    "\"Reply completed,\" to a ham operator",
    "Ended",
    "Kaput",
    "In a managerial position to",
    "Transcending",
    "Word ending a radio announcement",
    "Walkie-talkie word",
    "Atop",
    "Concluded",
    "\"Back to you\""
  ],
  "torn": [
    "Extremely undecided",
    "Unable to decide",
    "Struggling with a decision",
    "Unable to choose",
    "Having trouble deciding",
    "Rent",
    "Unable to decide",
    "Not intact",
    "Of two minds",
    "Of two minds",
    "Of two minds",
    "Of two minds",
    "Shredded",
    "Ripped",
    "Like some muscles and tendons",
    "Debating two options, say",
    "Conflicted",
    "Ripped",
    "Divided",
    "Unable to decide",
    "In tatters",
    "Of two minds",
    "Pulled apart",
    "Of two minds",
    "Having a hard time deciding",
    "Unable to decide",
    "Of two minds",
    "Shredded",
    "Mangled",
    "Shredded",
    "Divided",
    "Undecided",
    "Of two minds",
    "Unable to decide",
    "Unable to decide",
    "Trying to decide",
    "Rent",
    "Between a rock and a hard place",
    "On the fence",
    "Conflicted",
    "Unable to decide",
    "Unable to decide",
    "Shredded",
    "Undecided",
    "Divided",
    "Ripped",
    "Having conflicting allegiances",
    "Rip, but not Van Winkle",
    "No longer mint",
    "Rent",
    "Ripped",
    "In tatters",
    "Of two minds",
    "Unable to choose",
    "Divided",
    "See 28-Across"
  ],
  "guac": [
    "Dip for chips, informally",
    "Chip dip, familiarly",
    "Avocado dip, informally",
    "Common chip dip, slangily",
    "Green dip, informally",
    "Avocado dip, informally",
    "Taqueria order, informally",
    "Green chip dip, informally",
    "Chip dip, for short",
    "Some dip, informally",
    "Avocado dip, slangily",
    "Taco stand add-on, in brief",
    "Green dip, for short",
    "Some chip dip, informally",
    "Tex-Mex topping"
  ],
  "heir": [
    "Prince, for one",
    "___ to the throne",
    "Willing participant?",
    "Estate beneficiary",
    "Next in line",
    "One who has it coming?",
    "Prince, to a throne",
    "IV, to III",
    "Prince, e.g.",
    "Probate figure",
    "One in line?",
    "Next in line",
    "Prince, for one",
    "Junior, often",
    "Next in line",
    "Executor's concern",
    "Prince, e.g.",
    "One who's succeeding",
    "Prince, e.g.",
    "___ to the throne",
    "One who's trustworthy?",
    "Willing participant?",
    "One might be apparent",
    "Whodunit suspect, perhaps",
    "Son or grandson, say",
    "Son or daughter, typically",
    "A throne has one",
    "Prince, e.g.",
    "Scion",
    "Next in line",
    "One in a line",
    "Prince, e.g.",
    "Crown prince, e.g.",
    "Successor",
    "One in a will",
    "Next in line?",
    "___ to the throne",
    "Prince, e.g.",
    "Focus of one who's willing?",
    "Next in line",
    "Estate receiver",
    "Legacy receiver",
    "Next in line?",
    "Receiving party?",
    "One who's got it coming",
    "One who has it coming?",
    "Child of fortune?",
    "One named in a will",
    "Name in a will",
    "Prince, e.g.",
    "One who stands to gain a lot?",
    "Child of fortune?",
    "Immediate successor",
    "___ to the throne",
    "Prince or princess",
    "Legatee",
    "One in a receiving line?",
    "Son, usually",
    "Scion",
    "Successor",
    "___ to the throne (prince, e.g.)",
    "One who succeeds",
    "Presumptive person?",
    "___ to the throne",
    "Leave it to him",
    "One may be apparent",
    "Next in line",
    "Murder mystery suspect",
    "One with will power?",
    "Will figure",
    "One next in line",
    "Murder suspect, often",
    "Charles, to Elizabeth",
    "Next in line",
    "Prince, in a way",
    "He succeeds",
    "He's got it coming"
  ],
  "osso": [
    "___ buco",
    "___ buco",
    "___ buco",
    "Part of an Italian veal dish name",
    "Italian menu palindrome",
    "___ buco",
    "___ buco",
    "___ buco",
    "Bone: It.",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco (veal dish)",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco",
    "Femur, por exemplo",
    "___ buco",
    "___ buco",
    "Scapola or clavicola",
    "Veal dish ___ bucco",
    "Italian bone",
    "___ buco",
    "Skeleton part, in Padua",
    "___ buco",
    "___ buco",
    "Italian bone",
    "___ buco",
    "___ buco",
    "Bologna bone",
    "Bone: It.",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco",
    "Italian bone",
    "Benito's bone",
    "Bone in Rome",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco",
    "___ buco (Italian dish)",
    "___ buco",
    "___ buco",
    "___buco",
    "___buco (Italian dish)",
    "___ buco (veal dish)",
    "___ buco"
  ],
  "loam": [
    "Gardener's soil",
    "Rich soil",
    "Compost ingredient",
    "Rich soil",
    "Good gardening soil",
    "Good soil",
    "Fertile soil",
    "Fertile dirt",
    "Rich soil",
    "Fertile soil",
    "Nursery bagful",
    "Rich soil",
    "Good earth",
    "Composition of some bricks",
    "Rich soil",
    "Rich soil",
    "Soil sort",
    "Gardener's purchase",
    "Nursery bagful",
    "Potting material",
    "Rich soil",
    "Rich soil",
    "Gardener's bagful",
    "Wall-plastering material",
    "Brickmaking mixture",
    "The good earth?",
    "Clayey soil",
    "Rich soil",
    "Good soil",
    "Rich soil",
    "Soil type",
    "Rich earth",
    "Good earth",
    "Soil layer",
    "Topsoil",
    "Gardener's purchase",
    "The good earth",
    "Gardener's soil",
    "Good earth",
    "Topsoil of choice",
    "Good earth",
    "Rich soil",
    "Farm soil",
    "The good earth?",
    "Rich soil",
    "Rich soil",
    "Farm soil",
    "Nursery supply",
    "Farm layer",
    "Rich soil",
    "Potting soil",
    "Good earth",
    "Rich soil",
    "Moistened clay",
    "Some soil",
    "Clayey soil",
    "Good farm soil",
    "Clayey soil",
    "Organic soil",
    "Good earth",
    "Rich soil",
    "Rich soil"
  ],
  "hopi": [
    "Tribe that considers the Grand Canyon its creation place",
    "People based in Kykotsmovi Village, Arizona",
    "Arizona tribe",
    "Southwestern tribe with a snake dance",
    "Certain pueblo dweller",
    "Arizona tribe",
    "Arizona tribe",
    "Arizona tribe",
    "Southwest language",
    "Doll-making tribe of the Southwest",
    "Southwestern cliff dwellers",
    "Southwest tribe",
    "Early settlers around the Grand Canyon",
    "Some Arizonans",
    "Pueblo people",
    "Indian tribe with a rain dance",
    "Arizona tribe",
    "Neighbors of the Navajo",
    "Uto-Aztecan language",
    "Indian doll maker",
    "Pueblo Indian",
    "Navajo's neighbor in Arizona",
    "Arizona tribe",
    "Pueblo language",
    "Southwest Indian",
    "Southwest tribe",
    "Literally, \"peaceful\" person",
    "Arizona tribe",
    "Some cliff dwellers",
    "Mesa tribe",
    "Dwellers at First Mesa, Ariz.",
    "Comanche relative",
    "Southwestern tribe",
    "Indian tribe with kachina dolls",
    "Traditional snake dancer",
    "Mesa dweller",
    "Arizona tribe",
    "Kachina doll maker",
    "Language of the 1983 film title \"Koyaanisqatsi\"",
    "Arizona Indian",
    "Western Indian",
    "Mesa Indian",
    "Arizona tribe",
    "Pueblo Indian",
    "Native Arizonan",
    "Snake dancers",
    "Navajo neighbor",
    "Kachina performer",
    "Arizona snake dancers",
    "Pueblo Indian",
    "Mesa people",
    "Kachina doll makers",
    "Mesa dweller",
    "Zuni's cousin",
    "Prayer stick wielders",
    "Shoshonean language",
    "Arizona Indian",
    "Sun dancer",
    "They have their reservations",
    "Kachina doll makers",
    "Pueblo dweller",
    "Arizona Indian",
    "Kachina doll makers",
    "Uto-Aztecan language",
    "Snake dancers",
    "Kachina doll maker",
    "Pueblo Indian",
    "Western Indian",
    "Arizona Indian",
    "Kachina doll carver",
    "Pueblo dwellers",
    "Pueblo dweller",
    "Southwest Indian",
    "Arizona native",
    "Mesa dweller",
    "Tribe whose name means \"peaceful\"",
    "Kachina doll-makers",
    "Kachina doll makers",
    "Rain dancer, maybe",
    "Snake dancers",
    "Snake dancers",
    "Original Arizonan",
    "Noted potters"
  ],
  "ritz": [
    "\"Puttin' on the ___\"",
    "Ostentatious display",
    "Cracker brand with a yellow-and-blue logo",
    "Cracker name since 1934",
    "Epitome of luxury, with \"the\"",
    "Hotel name synonymous with poshness",
    "Luxury hotel in London's Piccadilly district, with \"the\"",
    "Scallop-edged cracker",
    "Cracker brand",
    "Classic theater name",
    "Luxuriousness",
    "Alternative to Premium",
    "Cracker brand",
    "Luxury hotel name",
    "Big name in crackers",
    "\"Puttin' on the ___\"",
    "Big name in hotels or crackers",
    "Brand of munchables",
    "Town House alternative",
    "Cracker brand",
    "Prestigious London hotel",
    "Extravagant display",
    "Luxuriousness",
    "Cracker brand",
    "Classic hotel name",
    "Ostentatious display",
    "Nabisco brand",
    "Popular cracker brand",
    "Ostentatious display",
    "Cracker name",
    "Big name in hotels",
    "Nabisco cracker",
    "\"Puttin' on the ___\" (Berlin classic)",
    "Name in luxury hotels",
    "Hi-Ho competitor",
    "___ Brothers (old film slapstick team)"
  ],
  "udon": [
    "Thick soup noodle",
    "Thick soup noodle",
    "Thick noodle",
    "Type of wheat noodle",
    "Soba alternative",
    "Japanese noodle dish",
    "Japanese noodle dish",
    "Japanese noodle",
    "Japanese noodle type",
    "Japanese bowlful",
    "Soup noodle",
    "Thick Japanese noodle",
    "Thick Japanese noodle",
    "Japanese noodle type",
    "Noodle soup noodle",
    "Japanese noodles",
    "Soba alternative",
    "Hot Japanese bowlful",
    "Thick Japanese noodle",
    "Japanese soup noodles",
    "Japanese noodle",
    "Japanese noodle",
    "Noodles with tempura",
    "Japanese noodles",
    "Soup pasta",
    "Tempura ___ (Japanese dish)",
    "Japanese noodle product"
  ],
  "audi": [
    "Its logo is four interlocking circles",
    "BMW competitor",
    "Q2 and Q3 maker",
    "Autobahn auto",
    "Company whose logo symbolizes the union of four manufacturers",
    "Onetime \"Truth in engineering\" sloganeer",
    "Car that sounds like a Cockney greeting",
    "Car with a four-ring logo",
    "Car with four circles in its logo",
    "German carmaker",
    "BMW rival",
    "Automaker based in Bavaria",
    "Autobahn auto",
    "German auto import",
    "German wheels",
    "Parent company of Lamborghini",
    "Q5 or Q7",
    "German luxury carmaker",
    "Mercedes competitor",
    "BMW competitor",
    "Car with the slogan \"Truth in engineering\"",
    "Automaker with a four-ring logo",
    "Lamborghini owner",
    "German auto co.",
    "Its logo consists of four interlocking circles",
    "German export",
    "Automaker whose name means \"listen\" in Latin",
    "BMW rival",
    "Autodom's A8 or Q7",
    "\"Truth in engineering\" sloganeer",
    "Ingolstadt-based automaker",
    "Car whose logo is liked?",
    "\"Truth in engineering\" sloganeer",
    "German automaker",
    "Premium brand of the Volkswagen Group",
    "Car make whose name sounds like a Cockney greeting",
    "Volkswagen subsidiary",
    "Q7 maker",
    "Quattro manufacturer",
    "German carmaker",
    "Auto company whose name is Latin for \"listen\"",
    "Saab competitor",
    "Car whose logo has four rings",
    "Auto on the autobahn",
    "German import",
    "BMW competitor",
    "Autobahn auto",
    "Its logo is four interlocking rings",
    "German car",
    "Car with a logo of four rings",
    "Auto make owned by Volkswagen",
    "\"Never follow\" sloganeer, once",
    "Q7 maker",
    "A6 maker",
    "Car company owned by Volkswagen",
    "German auto",
    "BMW competitor",
    "A3 maker",
    "Autobahn sight",
    "Its logo is four rings",
    "Popular German import",
    "Imported wheels",
    "Cabriolet maker",
    "Car maker whose name is Latin",
    "BMW competitor",
    "Autobahn auto",
    "Carmaker from Bavaria",
    "BMW competitor",
    "A6 or A8 maker",
    "Maker of the A4, A6 and A8",
    "Quattro maker",
    "BMW rival",
    "European car",
    "Autobahn sight",
    "Quattro maker",
    "Quattro maker",
    "A6 or TT",
    "German car",
    "Autobahn car",
    "Quattro or Cabriolet",
    "BMW competitor",
    "BMW competitor",
    "Quattro maker",
    "BMW rival",
    "German car",
    "Autobahn cruiser",
    "German auto",
    "A6 manufacturer",
    "German auto",
    "German car maker",
    "Quattro maker",
    "5000 or Vigor, e.g.",
    "Its logo is four linked rings",
    "Autobahn auto",
    "German import",
    "Autobahn auto"
  ],
  "skor": [
    "Popular toffee bar",
    "Hershey's chocolate-and-toffee bar",
    "Toffee bar brand",
    "Hershey toffee bar",
    "Hershey toffee bar",
    "Toffee candy bar",
    "Hershey toffee bar",
    "Hershey bar brand",
    "Hershey product similar to a Heath bar",
    "Hershey bar",
    "Hershey bar with toffee",
    "Toffee candy bar",
    "Hershey toffee bar",
    "Candy bar with a crown logo",
    "Hershey brand"
  ],
  "kitt": [
    "Eartha who played Catwoman",
    "Singer Eartha",
    "Singer Eartha",
    "Eartha who sang \"C'est Si Bon\"",
    "\"Santa Baby\" singer",
    "Eartha who sang \"C'est Si Bon\"",
    "Chanteuse Eartha",
    "\"Santa Baby\" singer",
    "Smoky-voiced Eartha",
    "Talking car on \"Knight Rider\"",
    "\"Santa Baby\" singer",
    "Arizona's ___ Peak National Observatory",
    "\"Santa Baby\" singer, 1953",
    "Arizona's ___ Peak National Observatory",
    "\"Santa Baby\" singer",
    "\"Santa Baby\" hitmaker, 1953",
    "Eartha of song",
    "Catwoman of 60's TV",
    "Songstress Eartha"
  ],
  "harm": [
    "Damage",
    "Perniciousness",
    "Wrongdoing",
    "Perniciousness",
    "Injure",
    "\"First, do no ___\"",
    "Tort basis",
    "Detriment",
    "\"What's the ___ in that?\"",
    "\"What's the ___?\"",
    "It's best to stay out of its way",
    "Mistreatment",
    "Injury",
    "Perniciousness",
    "Wrong",
    "Detriment",
    "Impair",
    "\"No ___, no foul\"",
    "Mischief",
    "Damage",
    "Perniciousness",
    "Damage",
    "Vitiate",
    "Detriment",
    "Havoc",
    "Damage",
    "Pollute, say",
    "What doctors are sworn not to do",
    "Do damage to",
    "Mischief",
    "Disservice",
    "Mischief",
    "Injure",
    "Detriment",
    "Injury",
    "Ruin",
    "Injure",
    "Abuse",
    "Detriment",
    "Damage",
    "Perniciousness",
    "Do damage to",
    "Detriment"
  ],
  "acte": [
    "Opéra division",
    "Entr'___",
    "Part of un opera",
    "Entr'___",
    "\"Romeo et Juliette\" segment",
    "Entr'___",
    "Entr'___",
    "\"Tartuffe\" segment",
    "Part of a French play",
    "Entr'___ (musical interlude)",
    "Opera part",
    "Part of a Moliere work",
    "Part of a Moliere play",
    "\"Romeo et Juliette\" section",
    "Opera division",
    "Part of a French opera",
    "Part of une piece de theatre",
    "Entr'___",
    "___ de naissance (French birth certificate)",
    "Part of a French play",
    "Entr'___",
    "Opera part",
    "Entr'___",
    "Part of un opera",
    "___ gratuit (something done without apparent motive)",
    "Part of un opera",
    "Moliere play part",
    "Moliere play part",
    "Entr'___",
    "Paris Opera part",
    "Entr'___",
    "\"Romeo et Juliette\" segment",
    "Part of a Racine play",
    "Entr'___",
    "Entr'___",
    "Entr'___ (theater break)",
    "Dumas novel",
    "Play part, in Paris",
    "It may have several scenes",
    "Entr' ___",
    "Dumas novel",
    "Entr'___"
  ],
  "zany": [
    "Off-the-wall",
    "Clownish",
    "Bonkers",
    "Like screwball comedies",
    "Eccentric",
    "Off the wall",
    "Bonkers",
    "Like much Gene Wilder humor",
    "Kooky",
    "Bats",
    "Off-the-wall",
    "Wacky",
    "Wild and crazy",
    "Madcap",
    "Like some Steve Martin humor",
    "Screwball",
    "Off the wall",
    "Madcap",
    "Off-the-wall",
    "Kooky",
    "Madcap",
    "Wacky",
    "Wacky",
    "Wild",
    "Buffoon",
    "Like the Keystone Kops",
    "Marxist?",
    "Off-the-wall",
    "Wacky",
    "Madcap",
    "Like the Marx Brothers",
    "Off the wall",
    "Like Marx Brothers humor",
    "Wacky",
    "Wacko"
  ],
  "rude": [
    "Like cutting and pushing",
    "\"How ___!\"",
    "Like \"manspreading\" on public transportation",
    "Like loud phone conversations in public",
    "\"Where are your manners!\"",
    "\"Wow, no manners!\"",
    "\"Great manners you've got!\"",
    "Tactless",
    "Inconsiderate",
    "Really fresh",
    "Like cutting in line",
    "Like elbowing through a crowd, say",
    "\"How ___!\"",
    "Impolite",
    "\"How ___!\"",
    "Like cutting in line",
    "Like talking in a theater, e.g.",
    "Ill-mannered",
    "Like talking during a movie, e.g.",
    "Like cutting in line",
    "Like line jumpers",
    "Like some awakenings",
    "Presumptuous, say",
    "Tactless",
    "Snarling, say",
    "Disrespectful",
    "Impolite",
    "Like cutting in line, e.g.",
    "Like a pusher, maybe",
    "Not according to Miss Manners",
    "Lacking in tact",
    "Unrefined",
    "Like a churl",
    "Unfashioned",
    "\"How ___!\"",
    "Like some awakenings",
    "Obnoxious",
    "Hardly chivalrous",
    "Deserving a slap, maybe",
    "Like a churl",
    "Boorish",
    "Like starers",
    "Hardly genteel",
    "Uncouth",
    "Uncivil",
    "Ill-mannered",
    "Hardly genteel",
    "Boorish",
    "Ill-mannered",
    "Inconsiderate",
    "Ill-bred",
    "Ill-mannered",
    "Rough",
    "\"How ___!\"",
    "Inconsiderate",
    "Uncivil",
    "More than unpleasant",
    "Like elbowing, e.g."
  ],
  "beet": [
    "Epitome of redness",
    "Cardiologist's favorite vegetable?",
    "Nongreen salad ingredient",
    "___-red",
    "Vegetable with greens",
    "___ salad",
    "___ greens",
    "Healthful juice source",
    "Borscht vegetable",
    "___ greens",
    "Salad ingredient that's not green",
    "Sugar source",
    "Sugar source",
    "Word before red",
    "___-red",
    "Underground vegetable with edible greens",
    "Reddish-purple bit in a salad",
    "Borscht vegetable",
    "Kind of salad",
    "Red food dye source",
    "Sugar source",
    "Borscht vegetable",
    "Sugar source",
    "Borscht vegetable",
    "Borscht need",
    "Borscht ingredient",
    "Symbol of redness",
    "Epitome of redness",
    "___ greens",
    "Shade of red",
    "Red shade",
    "Symbol of redness",
    "Swiss chard, e.g.",
    "With 23-Across, crimson",
    "Symbol of redness",
    "___ greens",
    "Borscht vegetable",
    "Bagasse base, maybe",
    "Symbol of redness",
    "Sugar source",
    "Shade of red",
    "Epitome of redness",
    "Shade of red",
    "Kind of greens",
    "Symbol of redness",
    "___ greens",
    "Sugar source",
    "Sugar source",
    "Red vegetable",
    "Shade of red",
    "Sucrose source",
    "Borscht need",
    "Healthful juice source",
    "Underground vegetable",
    "Shade of red",
    "Mangel-wurzel",
    "Borscht base",
    "Shade of red",
    "Red vegetable",
    "Sugar source",
    "Borscht vegetable",
    "Sugar ___",
    "Shade of red",
    "Shade of red",
    "Borscht ingredient",
    "Red vegetable",
    "Sugar source",
    "Shade of red"
  ],
  "arch": [
    "Shape for an eyebrow or rainbow",
    "Primary",
    "One half of the McDonald's logo",
    "Feature over many a doorway",
    "Tongue-in-cheek",
    "St. Louis symbol",
    "Principal",
    "One of two in the McDonald's logo",
    "Playful and teasing",
    "Part of the foot",
    "Image on the Missouri state quarter",
    "Prefix with angel or enemy",
    "Prefix with conservative",
    "St. Louis landmark",
    "One of two in the McDonald's logo",
    "Prefix with conservative",
    "Bend over backward",
    "Playfully roguish",
    "Mischievous",
    "Sole part",
    "St. Louis landmark",
    "What an insole helps support",
    "Keystone's place",
    "Bridge shape",
    "Prefix meaning \"extreme\"",
    "Feature of the western end of the Champs-Elysees",
    "Part of a foot",
    "St. Louis ___ (landmark)",
    "Mischievous",
    "One may be built to commemorate triumph",
    "St. Louis landmark",
    "Half of the McDonald's logo",
    "Cheeky",
    "Figure on a Utah license plate",
    "Marble ___ (London landmark)",
    "One of a pair in a fast-food logo",
    "Sole support?",
    "Eyebrow shape",
    "Keystone place",
    "Angel or enemy preceder",
    "Go up, as eyebrows",
    "Shoe support",
    "Bridge feature",
    "Move up, as an eyebrow",
    "Part of a foot",
    "Eyebrow shape",
    "Keystone's place",
    "Geological feature on a Utah license plate",
    "Entrance to many a plaza",
    "St. Louis attraction",
    "Bridge shape",
    "Keystone's place",
    "Half of a McDonald's logo",
    "Main",
    "Design feature over many a gate",
    "Chief",
    "St. Louis's Gateway ___",
    "Roman aqueduct support",
    "Eyebrow shape",
    "___ enemy (one who may blow up bridges)",
    "___ of Constantine (landmark in 45-Across)",
    "Cunning",
    "Design feature of many a viaduct",
    "Roguish",
    "Mischievous",
    "St. Louis landmark",
    "St. Louis attraction",
    "Bridge or foot feature",
    "Sly",
    "Dict. label",
    "Half a McDonald's logo",
    "Sly",
    "Sole support?",
    "Playfully roguish",
    "Foot part",
    "Curve, as the back",
    "St. Louis landmark",
    "Eyebrow shape",
    "St. Louis landmark",
    "Shoe part",
    "Keystone's place",
    "Part of McDonald's logo",
    "Eyebrow shape",
    "Playfully roguish",
    "St. Louis sight",
    "Chief",
    "Cunning",
    "Keystone site",
    "Bridge piece",
    "Roguish",
    "Mosque entranceway",
    "Bend",
    "Principal",
    "Bridge part",
    "Cunning",
    "Enemy leader?",
    "Bow",
    "Keystone's place",
    "Place for a keystone",
    "Coyly roguish",
    "Enemy leader?",
    "Flatfoot's lack",
    "Spot for a parade",
    "Keystone place",
    "Roguish",
    "Mischievous",
    "Eyebrow shape",
    "St. Louis landmark",
    "Ogee, e.g.",
    "Crafty",
    "Mischievous",
    "Chief: Prefix",
    "Vault",
    "Playfully roguish",
    "Flatfoot's lack",
    "St. Louis attraction",
    "Mischievous",
    "Part of the foot",
    "Mischievous",
    "It may have fallen on a foot",
    "It has a keystone",
    "Military parade passageway",
    "Part of a gateway",
    "Bridger",
    "St. Louis landmark",
    "Eyebrow shape",
    "Eyebrow position",
    "Principal",
    "Curved gateway",
    "Part of a foot",
    "Mischievous",
    "Part of a platform dive",
    "Principal",
    "Parade passageway",
    "Mischievous",
    "Part of a foot",
    "Chief",
    "St. Louis landmark",
    "Cunning",
    "Foot part",
    "Mischievous"
  ],
  "rapt": [
    "Captivated",
    "Enthralled",
    "Entranced",
    "Mesmerized",
    "Engrossed",
    "Engaged",
    "Agog",
    "Glued to something",
    "Totally focused",
    "Engrossed",
    "Totally captivated",
    "Mesmerized",
    "Paying close attention",
    "Fascinated",
    "Enthralled",
    "Paying close attention",
    "Totally absorbed",
    "Completely focused",
    "Not wandering, say",
    "Captivated",
    "In awe",
    "Mesmerized",
    "Entranced",
    "Engrossed",
    "Spellbound",
    "Glued to something",
    "Really into something",
    "Spellbound",
    "Absorbed",
    "Engrossed",
    "More than attentive",
    "Absorbed",
    "Totally absorbed",
    "Enthralled",
    "Totally absorbed",
    "Transfixed",
    "In reverie",
    "All ears",
    "Totally absorbed",
    "Totally consumed",
    "Fully absorbed",
    "Deep in thought",
    "Unblinking",
    "Caught up",
    "Wholly absorbed",
    "Spellbound",
    "Wholly absorbed",
    "Spellbound",
    "Fully absorbed",
    "Absorbed",
    "Hanging on every word",
    "Spellbound",
    "Engrossed",
    "Spellbound",
    "Enthralled",
    "Lost",
    "Deeply engrossed",
    "Bewitched",
    "Not merely paying attention",
    "Riveted with attention",
    "Wholly absorbed",
    "Engrossed",
    "Deeply absorbed",
    "Spellbound",
    "Undivided",
    "Awestruck",
    "Totally absorbed (in)",
    "Absorbed",
    "Awestruck",
    "Enthralled",
    "Intent",
    "Immersed",
    "Like a good listener",
    "All ears",
    "Engrossed",
    "Engrossed",
    "Engrossed",
    "Mesmerized",
    "Absorbed"
  ],
  "asia": [
    "Region traveled by 63-Across",
    "China holder?",
    "Land of plenty?",
    "Home to 41-Down",
    "Home to more than 4.5 billion",
    "Difficult area to maintain control of in the game Risk",
    "Home of Baikal, the world's deepest lake",
    "Locale of both the highest and lowest points on the earth's surface",
    "Singapore setting",
    "Polo grounds?",
    "Home of 60% of the world's people",
    "It has 12 territories in Risk",
    "Largest continent",
    "Source of a big percentage of U.S. auto imports",
    "___-Pacific",
    "Big part of a Risk board",
    "The East, to the West",
    "Largest continent",
    "Java setting",
    "Home to zero winners of the FIFA World Cup, surprisingly",
    "Where the four most widely practiced religions all originated",
    "Where dogs are believed to have been domesticated 10,000+ years ago",
    "Locale of the 2018, 2020 and 2022 Olympics",
    "Home to seven \"-stan\" countries",
    "Home to more than 4.5 billion",
    "Home of yaks and zebus",
    "Home of the Himalayas",
    "Home of about 25% of U.N. member states",
    "Only continent larger than Africa",
    "Land east of the Suez Canal",
    "___-Pacific",
    "Section of The Economist",
    "What one of the five Olympic rings stands for",
    "Where the Olympics were held for the first time in 1964",
    "Home to the majority of earthlings",
    "It's west of the Pacific",
    "Where the Bactrian camel is native",
    "Home to 48 countries",
    "Locale of both the 2018 and 2020 Olympics",
    "___-Pacific (geopolitical region)",
    "Area traversed by Marco Polo",
    "Where most Buddhists reside",
    "Landmass bounded by a mountain chain and three oceans",
    "Where Siberia is",
    "17+ million square miles of the earth",
    "The East",
    "Area worth the most bonus troops in the game Risk",
    "China setting",
    "Himalayas setting",
    "Where Nepal is",
    "It spans 11 time zones",
    "It's on one side of the Urals",
    "One side of the Pacific",
    "___ Minor",
    "Self-titled debut album of 1982",
    "One side of the Bosporus strait",
    "Where the 27-Down is",
    "See 68-Down",
    "It's west of the Pacific",
    "Locale of many emerging markets",
    "Where many emerging markets can be found",
    "Home of about 60% of the world's population",
    "Part of SEATO",
    "One of a geographical septet",
    "Home of India and Indonesia",
    "___-Pacific",
    "Where the \"tiger cub economies\" are",
    "Where China and India are",
    "Turkey's place, in large part",
    "Where the World Cup has been held only once",
    "Where Vietnam is",
    "Where most of Russia is",
    "China setting",
    "Big holding in Risk",
    "Home to the Himalayas",
    "Home to most 11-Down",
    "Where Kyrgyzstan is",
    "Locale of three Summer Olympics",
    "The East",
    "It's east of Europe",
    "Where Tibet is",
    "Place for tiger woods?",
    "Where woolly mammoths once roamed",
    "Where the world's 100 tallest mountains are found",
    "It's west of the International Date Line",
    "Where 36-Down is",
    "Part of 39-Down",
    "Wall Street Journal ___",
    "Home for a 123-Across",
    "One of six World Cup qualifying zones",
    "See 67-Across",
    "Where the Himalayas are",
    "___-Pacific",
    "Where Indians live",
    "Most of Turkey is in it",
    "___ Minor",
    "Home to billions",
    "The \"A\" in SEATO",
    "See 35-Down",
    "Part of Georgia is in it",
    "Altogether it's worth the most bonus troops in Risk",
    "It's north of the Indian Ocean",
    "Part of the Old World",
    "See 96-Down",
    "Where the Himalayas are",
    "___-Pacific",
    "___ Minor",
    "Land shared by Iraq and Iran",
    "Band whose self-titled 1982 album was #1 for nine weeks",
    "Land west of the Pacific",
    "Large part of a world atlas",
    "Mongols' home",
    "Area worth the most bonus troops in the game Risk",
    "___ Minor",
    "Java setting",
    "Province of ancient Rome",
    "Polo setting",
    "Part of Istanbul is in it",
    "It's west of the Sea of Okhotsk",
    "Big holding in Risk",
    "It's not Occidental",
    "It's east of Europe",
    "Marco Polo crossed it",
    "Vietnam's continent",
    "___-Pacific",
    "Indian's home",
    "Where India is",
    "One edition of the Wall Street Journal",
    "1980s hit-makers with a geographical name",
    "___ Minor",
    "Minor area?",
    "Part of a famous septet",
    "Everest setting",
    "Home to some elephants",
    "Home to more than half the world's population",
    "Spanner of 11 time zones",
    "Mongolia's home",
    "Silk Road locale",
    "Home to most Turks",
    "Where most of Russia is",
    "Largest of a septet",
    "Part of the Old World",
    "Home to most Turks",
    "Korea's continent",
    "Big atlas section",
    "Land east of the Urals",
    "Western edge of the Pacific Rim",
    "Where Marco Polo explored",
    "Where 6-Down is",
    "It touches three oceans",
    "Largest of seven",
    "Newspaper foreign news desk",
    "Largest continent",
    "Continent explored by 9-Across",
    "Where tigers roam",
    "Three oceans touch it",
    "Toucher of the Pacific Rim",
    "\"Prometheus Unbound\" character",
    "Where Tibet is",
    "Big atlas section",
    "Locale for two Summer Olympics to date",
    "See 6-Down",
    "A big chunk of an atlas",
    "Indian's home",
    "Largest of seven",
    "See 45-Across",
    "Where the Himalayas are",
    "Where Nepal is",
    "India's locale",
    "___ Minor",
    "One side of the Urals",
    "Largest of seven",
    "Atlas section",
    "___ Minor",
    "Fertile Crescent's place",
    "Where the Ob and Indus flow",
    "Laos's locale",
    "Home of ancient Chaldea",
    "Where many Indians live",
    "Where SARS originated",
    "Where pomelos grow",
    "The East",
    "Polo grounds?",
    "Tibet's environs",
    "One side of the Bering Strait",
    "Largest of seven",
    "Large section in an atlas",
    "Sri Lanka's locale",
    "Where Sikkim is",
    "Where Korea is",
    "China's continent",
    "Spanner of 11 time zones",
    "Korea's home",
    "*Eastern ... vast ... Marco Polo",
    "32-Across is part of it",
    "Indian's home",
    "Where Marco Polo traveled",
    "Siberia's locale",
    "Java is in it",
    "Home to billions",
    "Where 37-Down is",
    "Land east of the Urals",
    "___ Minor",
    "Java is in it",
    "What one of the five Olympic rings stands for",
    "Nepal's locale",
    "___ Minor",
    "Where Alexander the Great conquered",
    "China setting",
    "Home to most Turks",
    "Polo field?",
    "17 1/2 million square miles",
    "See 23-Down",
    "Part of SEATO",
    "Where Indians live",
    "Marco Polo crossed it",
    "About 30% of the earth's land",
    "Flu source",
    "One flu source",
    "Where Japan is",
    "One of the Oceanides, in Greek myth",
    "China setting",
    "Where India is",
    "SEATO part",
    "Where China is",
    "It's east of the Urals",
    "Where 62-Across is",
    "The Kara Sea touches it",
    "Flu source",
    "The biggest of seven",
    "Home to some elephants",
    "Marco Polo crossed it",
    "The Urals are west of it",
    "Where Myanmar is",
    "Concern for 90's investors",
    "Southeast ___",
    "Three oceans touch it",
    "Atlas section",
    "Source of much West Coast trade",
    "The East",
    "Area east of the Bosporus",
    "Atlas section",
    "The Kara Sea is on its north",
    "The East",
    "Atlas section",
    "Home of 3.5 billion",
    "Where the Gobi is",
    "Pacific Rim locale",
    "Marco Polo crossed it",
    "The East",
    "Part of SEATO",
    "The East",
    "Populous place",
    "Where India is",
    "Largest of seven",
    "Daughter of Oceanus",
    "Pacific Rim region",
    "China setting",
    "Where China is",
    "___ Minor",
    "China setting",
    "Polo grounds?",
    "Globe part",
    "It has a Minor part",
    "One of seven big things",
    "Bhutan's locale",
    "Home to billions",
    "About 30 (percent) of the earth",
    "Atlas section",
    "Land east of the Urals",
    "Siberia's site",
    "Pacific rim region",
    "The East",
    "Where most people live",
    "Where Mongolia is",
    "Part of SEATO",
    "Area named after a Greek nymph",
    "Atlas section",
    "Polo ground?",
    "\"Heat of the Moment\" rock group",
    "The East",
    "Gobi Desert site",
    "One of the Oceanides",
    "Pacific Rim locale",
    "30% of the world",
    "Minor place",
    "30% of the world's land",
    "Nepal's location",
    "Siberia's site",
    "The East",
    "Where Marco Polo traveled",
    "About 17 million square miles",
    "Part of SEATO"
  ],
  "boat": [
    "Dinghy or dory",
    "Metaphor for a shared experience",
    "Dinghy or dory",
    "Ferry or wherry",
    "With 9-Across, place to yacht-shop",
    "Fancy serving platter at a sushi restaurant",
    "Lead-in to load or lift",
    "What a 76-Down pulls",
    "Tug or tub",
    "Bath toy",
    "You may follow in its wake",
    "Connection between banks?",
    "One making bank-to-bank transfers?",
    "Punt or junk",
    "Gravy holder",
    "Yacht, e.g.",
    "Loophole-exploiting casino site, say",
    "Gravy holder",
    "Islander's need, maybe",
    "Origami design",
    "Vehicle on a trailer, perhaps",
    "Gravy vessel",
    "Gravy holder",
    "Yawl or yacht",
    "Dinghy, e.g.",
    "Creation of 31-Down",
    "Tub toy",
    "Shell, e.g.",
    "Ferry or dinghy",
    "Dinghy or dory",
    "Tunnel of love vehicle",
    "Dory or ferry",
    "Salt holder",
    "Ark or bark",
    "Ketch or yawl",
    "Ferry or wherry",
    "Launch",
    "Dinghy or dory",
    "Bark or punt",
    "The African Queen, e.g.",
    "Tug, say",
    "Dugout, for one",
    "Bridge site",
    "Go sailing",
    "Dugout, for one",
    "Punt, for one",
    "Junk",
    "Sloop",
    "Serving dish"
  ],
  "dues": [
    "Club charges",
    "Payment often made around January 1",
    "Club requirement",
    "Money to belong",
    "Cost of membership",
    "Membership fees",
    "Union jack?",
    "Annual charge",
    "Club collection",
    "Periodic payments",
    "Requirement for union membership",
    "See 5-Down",
    "Some annual bills",
    "Union collections",
    "Club bill",
    "Annual bill",
    "Club charges",
    "Treasurer's receipt",
    "Cost of belonging",
    "An itemized deduction",
    "Yearly payment",
    "Club revenue",
    "They're often paid",
    "Things to pay"
  ],
  "cels": [
    "Cartoon collectibles",
    "Goofy images, perhaps?",
    "Animation stack",
    "Disney collectibles",
    "Hanna-Barbera collectibles",
    "Cartoon frames",
    "Mickey Mouse pics, e.g.",
    "Cartoon collectibles",
    "Disney Store collectibles",
    "Ones drawn to film?",
    "Cartoon frames",
    "Cartoon collectibles",
    "Cartoon frames",
    "Hanna-Barbera output",
    "Locations for Pluto, sometimes",
    "Hanna-Barbera productions",
    "Disney output, once",
    "Frames found in frames",
    "Frames a collector might frame",
    "Toon pics",
    "Drawer units?",
    "Cartoon pics",
    "Disney collectibles",
    "Short units, perhaps",
    "Art containing 4-Down",
    "Hand-colored frames",
    "Collectible frames",
    "Disney output",
    "Cartoon art",
    "Art collectibles",
    "Cartoon collectibles",
    "Animator's portfolio items",
    "Warner Bros. inventory",
    "Cartoon frames",
    "Art collectibles",
    "Animation frames",
    "Animation frames",
    "\"Fantasia\" frames"
  ],
  "topo": [
    "Map with elevation lines, in brief",
    "Hiker's map, briefly",
    "\"___ the morning!\"",
    "\"___ the morning!\"",
    "\"___ the morning\"",
    "\"___ the morning!\"",
    "\"Ed Sullivan Show\" mouse ___ Gigio",
    "\"___ the mornin'!\"",
    "\"___ the mornin'!\"",
    "___ Gigio (frequent Ed Sullivan guest)",
    "___Gigio (Ed Sullivan mouse)",
    "___ Gigio (old TV mouse)",
    "___ Gigio (\"Ed Sullivan Show\" regular)"
  ],
  "snee": [
    "Old dagger",
    "Snick and ___",
    "Partner of snick",
    "Old-fashioned dagger",
    "Old blade",
    "Bygone dagger",
    "Bygone sticker",
    "Dagger of yore",
    "Old dagger",
    "Old Highlands dagger",
    "Weapon in \"The Mikado\"",
    "Knife of old",
    "2012 Pro Bowl player Chris",
    "\"The Mikado\" weapon",
    "Three-time All-Pro guard Chris",
    "Snick and ___",
    "\"The Mikado\" weapon",
    "Old dagger",
    "Old dagger",
    "Olden dagger",
    "N.F.L. All-Pro player Chris",
    "Old dagger",
    "Old dagger",
    "Weapon of old",
    "Old dagger",
    "Snick and ___",
    "Sticker of the past",
    "Old blade",
    "Old dirk",
    "Dagger in \"The Mikado\"",
    "Snick and ___",
    "Snick's partner",
    "Snick's partner",
    "Snick and ___",
    "Ko-Ko's dagger in \"The Mikado\"",
    "Snick and ___",
    "N.F.L. offensive guard Chris",
    "N.F.L. guard Chris",
    "Old dirk",
    "Old sticker",
    "Snick and ___",
    "Old dagger",
    "Old-fashioned knife",
    "Snick and ___",
    "Dagger of old",
    "Snick-or-___",
    "Dirk's old relative",
    "Snick's partner",
    "Dagger",
    "Old dagger",
    "Old dagger",
    "Bygone blade",
    "Snick-a-___",
    "Snick's partner",
    "Bygone blade",
    "Old blade",
    "Pirate sticker",
    "Snick or ___",
    "Blade in \"The Mikado\"",
    "Large knife",
    "Snick and ___",
    "Ko-Ko's weapon in \"The Mikado\"",
    "Old-fashioned knife",
    "Bygone blade",
    "Old knife",
    "Old knife",
    "Snick and ___ (thrust and cut)",
    "Snick-a-___",
    "Snick-a-___ (combat with knives)",
    "Snicker-___",
    "Snick-or-___",
    "Large knife",
    "Cornell's ___ Hall",
    "Cut, old-style",
    "Old dagger",
    "Dirk of yore",
    "Old-time dagger",
    "Olden dagger",
    "Snicker follower",
    "Old dagger",
    "Knife, in old dialect",
    "Dagger of yore",
    "Snick or ___",
    "Snick-or-___",
    "Snick and ___",
    "Snick's partner",
    "Snick-or-___",
    "Snick's partner",
    "Antique knife",
    "Old knife"
  ],
  "thud": [
    "A bad joke might go over with one",
    "Fall sound",
    "Sound of a flop",
    "Fall sound",
    "Evidence of a hard landing",
    "Heavy-landing sound",
    "What a load might land with",
    "Landing sound",
    "Graceless landing, say",
    "Sound of a heavy fall",
    "Land heavily",
    "Fall's end",
    "Sound after dropping off?",
    "Sound of a failure",
    "Fall sound",
    "Fall heavily",
    "It may be made by a falling rock",
    "Definitely not the sound of success",
    "Fall sound",
    "Fall's end",
    "Impact sound",
    "Faint sound?",
    "A big person may come down with it",
    "Result of a failure",
    "Sound of impact",
    "Dropping sound",
    "Fall follower",
    "Cousin of plop",
    "Dull sound",
    "None-too-gentle landing",
    "Dull blow",
    "Bomb's sound",
    "Sound of collapse",
    "Falling sound",
    "Dull sound",
    "Fall sound"
  ],
  "limp": [
    "Rock's ___ Bizkit",
    "Like a flag on a windless day",
    "Like wet noodles",
    "Result of a twisted ankle",
    "Compensate for a sprained ankle, say",
    "Not go off without a hitch?",
    "Like thoroughly cooked pasta",
    "Droopy",
    "Definitely not step lively",
    "Unsteady gait",
    "Drooping",
    "Hitch",
    "Not step so lively",
    "Like a wet noodle",
    "Evidence of an ankle sprain",
    "Walk with a hitch",
    "Lacking starch",
    "Like Dali's watches",
    "Unfirm",
    "Droopy",
    "Favor one side, perhaps",
    "Lame gait",
    "Unfirm",
    "Battle reminder",
    "Like a wet noodle",
    "Favor one side?",
    "War memento, maybe",
    "Like a Dali watch",
    "Favor one side?",
    "Not having much body",
    "Walk falteringly",
    "Hobble",
    "Like a Dali watch",
    "Not stiff",
    "Flaccid",
    "Not stiff",
    "Favor one side",
    "Like a wet noodle",
    "Proceed falteringly",
    "Flaccid"
  ],
  "cali": [
    "San Diego's state, informally",
    "It has its faults, informally",
    "The Golden State, familiarly",
    "Only place in the U.S. to host both the Summer and Winter Olympics, informally",
    "Colombian city that hosted the 1971 Pan American Games",
    "Golden State, informally",
    "City WSW of Bogota",
    "Colombian metropolis",
    "L.A. locale",
    "Compton's state, to hip-hoppers",
    "City famous for bad traffic?",
    "Colombian metropolis",
    "Many a surfer's locale, informally",
    "City known for its traffic violations",
    "Colombian city of 2+ million",
    "Colombian city",
    "Frisco's state",
    "Colombian cartel city",
    "District of Colombia?",
    "LL Cool J's \"Going Back to ___\"",
    "Title locale in a 1998 Notorious B.I.G. hit",
    "It's north of Baja, informally",
    "District of Colombia?",
    "City known as Colombia's sports capital",
    "Colombia's second-largest city",
    "Colombian city",
    "Self-styled world salsa capital",
    "Colombian city",
    "Cartel city",
    "Cartel city",
    "Colombian city",
    "District of Colombia?",
    "Valle del Cauca's capital",
    "South American city of 2 million",
    "Cartel city",
    "Colombian city",
    "City southwest of Bogota",
    "Cartel city",
    "Western state, slangily",
    "City southwest of Bogota",
    "Capital of Valle del Cauca department",
    "1971 Pan American Games site",
    "Colombian city",
    "Infamous Colombian city",
    "Cartel city",
    "Colombia city",
    "1971 Pan-American Games host city",
    "City southwest of Bogota",
    "Colombian city",
    "Colombian city"
  ],
  "stan": [
    "Obsessive fan, in slang",
    "Actor Sebastian ___",
    "Ending of seven country names",
    "Obsessive fan, in slang",
    "___ Lee, longtime head of Marvel Comics",
    "Ending of seven Asian countries' names",
    "Ollie's partner in old comedy",
    "Comic book publisher Lee",
    "Lee known for his cameos",
    "Ollie's foil, in old films",
    "Van Gundy of the N.B.A.",
    "Obsessive fan, slangily",
    "Pal of Kyle and Kenny on \"South Park\"",
    "Baseball's Musial",
    "Lee of Marvel Comics",
    "Laurel of Laurel and Hardy",
    "Extreme devotee, in modern lingo",
    "Kyle and Kenny's friend on \"South Park\"",
    "Ollie's partner in old comedy",
    "___ the Man (old baseball nickname)",
    "Obsessive fan, in modern slang",
    "Hall-of-Famer Musial",
    "Getz who was nicknamed \"The Sound\"",
    "Lee of Marvel Comics",
    "Persian for \"place of\"",
    "Comic book legend Lee",
    "Lee of Marvel Comics",
    "\"___ Against Evil\" (IFC series)",
    "Baseball's Musial",
    "Lee who co-created the Avengers",
    "\"South Park\" kid voiced by Trey Parker",
    "Lee of Marvel Comics",
    "Lee of Marvel Comics",
    "Superhero creator Lee",
    "Musial in the Baseball Hall of Fame",
    "N.B.A. coach Van Gundy",
    "Ending on several central Asian country names",
    "Musial in Cooperstown",
    "Eminem song about an obsessed fan",
    "Tennis great Smith",
    "___ Lee, creator of Spider-Man",
    "\"South Park\" boy",
    "\"South Park\" boy",
    "___ Lee of Marvel Comics",
    "Van Gundy of the N.B.A.",
    "Tennis's Wawrinka, winner of the 2015 French Open",
    "Ending of several central Asian country names",
    "\"South Park\" boy",
    "Musial of Cardinals fame",
    "With 45-Across, Thor's co-creator",
    "Ollie's partner in comedy",
    "Title name in a 2000 Eminem hit",
    "Pollster Greenberg",
    "\"Mr. Mom\" director Dragoti",
    "\"South Park\" boy",
    "\"South Park\" boy",
    "Persian suffix that ends seven country names",
    "Lee of Marvel Comics",
    "Asian nation suffix",
    "\"South Park\" boy",
    "\"South Park\" boy",
    "Lee of Marvel Comics",
    "Hall-of-Famer Musial",
    "Persian for \"place of\"",
    "Kyle and Kenny's friend on \"South Park\"",
    "Saxophonist Getz",
    "___ the Man (Cardinal nickname)",
    "Satirist Freberg",
    "Musial of the Cardinals",
    "Eminem song that samples Dido's \"Thank You\"",
    "Baseball great Musial",
    "Coveleski of Cooperstown",
    "Ollie's partner",
    "Satirist Freberg",
    "Lee of Marvel Comics",
    "Laurel from England",
    "Pal of Kenny and Kyle",
    "Avant-garde filmmaker Brakhage",
    "Spider-Man creator Lee",
    "Getz of jazz",
    "Baseball's Musial",
    "Berenstain of kid-lit's Berenstain Bears",
    "1972 Wimbledon winner Smith",
    "___ Lee of Marvel Comics",
    "1970s tennis great Smith",
    "Baseball's ___ the Man",
    "Suffix meaning \"land\" in some country names",
    "Smith who won the 1972 Wimbledon",
    "\"South Park\" boy who's always crying \"Oh my God, they killed Kenny!\"",
    "Asian nation suffix",
    "A \"South Park\" kid",
    "Smith who won Wimbledon in 1972",
    "Lee of Marvel Comics",
    "Asian nation suffix",
    "Ollie's partner in comedy",
    "Satirist Freberg",
    "\"Land,\" in central Asia",
    "Hall-of-Famer Musial",
    "1971 U.S. Open winner ___ Smith",
    "Ollie's partner in old comedy",
    "Cartoonist Drake",
    "\"South Park\" boy",
    "Lee of Marvel Comics",
    "Novelist Barstow",
    "Asian nation suffix",
    "Laurel or Musial",
    "Baseball's Musial",
    "___ Smith, who won the 1972 Wimbledon",
    "Ollie's partner",
    "Mikita of hockey",
    "Asian ending for \"land\"",
    "1972 Wimbledon winner Smith",
    "Ollie's partner in old comedy",
    "___ Drake, longtime illustrator of \"Blondie\"",
    "\"The Man\" of Cardinals history",
    "Hockey's Mikita",
    "Cartoonist Drake",
    "Jazz's Kenton",
    "Comedic Laurel",
    "Lee who created Spider-Man",
    "Musial of the Cards",
    "With 74-Down, Marvel Comics founder",
    "1972 Wimbledon winner Smith",
    "Writer Berenstain famous for the Berenstain Bears",
    "Funnyman Laurel",
    "Satirist Freberg",
    "Hockey great Mikita",
    "One of the deer hunters in \"The Deer Hunter\"",
    "Laurel of comedy",
    "Comical Laurel",
    "Kenton of jazz",
    "See 40-Down",
    "\"South Park\" kid",
    "Satirist Freberg",
    "Cartoonist Drake",
    "Baseball's Musial",
    "Ollie's partner",
    "___ Lee of Marvel Comics",
    "Cartoonist Drake",
    "Jazzman Kenton",
    "Lee or Musial",
    "\"South Park\" boy",
    "\"A Kind of Loving\" novelist Barstow",
    "Hockey's Mikita",
    "Kenton of jazz",
    "Jazzman Getz",
    "Hall-of-Famer Coveleski",
    "1972 Wimbledon winner Smith",
    "Saxophonist Getz",
    "Hockey's Mikita",
    "Quarterback Humphries",
    "Jazzman Getz",
    "Actor Shaw of \"Tough Enough\"",
    "Spider-Man creator Lee",
    "Cartoonist Drake",
    "Cartoonist Drake",
    "Cartoonist Drake",
    "Quarterback Humphries",
    "Ollie's sidekick",
    "Ollie's partner in slapstick",
    "Cartoonist Drake",
    "One of the hunters in \"The Deer Hunter\"",
    "Cartoonist Drake",
    "The Man",
    "Getz or Kenton",
    "Pitcher Belinda",
    "Baseball's Musial",
    "Getz or Kenton",
    "Baseball's ___ the Man",
    "The Man",
    "Baseball's ___ the Man",
    "Cartoonist Drake",
    "Mikita of hockey",
    "Cartoonist Drake",
    "'70s tennis champ Smith",
    "Mr. Musial",
    "With 39-Across, \"The Girl From Ipanema\" saxophonist",
    "Cartoonist Drake",
    "Bandleader Kenton",
    "Baseball Hall-of-Famer Coveleski",
    "Mr. Musial",
    "Kenton of jazz",
    "Laurel or Musial",
    "Saxophonist Getz",
    "Comics publisher Lee",
    "Saxophonist Getz"
  ],
  "tech": [
    "Support for a PC",
    "___ support",
    "Field for many Silicon Valley jobs",
    "Big part of the S&P 500",
    "Silicon Valley specialty, familiarly",
    "Stock market sector, for short",
    "Modern corporate dept.",
    "End of many university names",
    "Silicon Valley industry",
    "Part of I.T., for short",
    "End of some school names, for short",
    "Fortune 500 category, informally",
    "Shortening in many school names",
    "Georgia ___",
    "Silicon Valley specialty",
    "PC fixer",
    "Computer crash investigator, informally",
    "Genius Bar staffer",
    "One sector of the Dow Jones index",
    "Apple field",
    "See 27-Down",
    "Low-___",
    "Part of STEM, for short",
    "Silicon Valley specialty",
    "M.I.T. part: Abbr.",
    "Part of STEM, for short",
    "PC support person",
    "Silicon Valley field, for short",
    "Programming pro, e.g.",
    "Big field in Silicon Valley",
    "Corporate department that offers support",
    "PC whiz",
    "Virginia ___",
    "Georgia ___",
    "High-___",
    "Word after Georgia or Virginia in school names",
    "Practical school, for short",
    "Lab ___",
    "___ support",
    "Georgia ___",
    "Texas ___",
    "Kind of support offered by a 3-Down",
    "Engineers' school, briefly",
    "Kind of support",
    "PC whiz",
    "Part of M.I.T.: Abbr.",
    "Georgia ___",
    "High-___",
    "Kind of support",
    "Kind of support",
    "Kind of support",
    "Part of M.I.T.: Abbr.",
    "With 5-Down, some volatile investments",
    "Kind of support",
    "Word with high- or low-",
    "Kind of support",
    "Certain school",
    "Part of M.I.T.: Abbr.",
    "Kind of support",
    "Kind of support",
    "\"Rambling Wreck From Georgia ___\"",
    "Computer department",
    "Texas ___",
    "Kind of support for a computer user",
    "Word in many college names",
    "Kind of support",
    "Kind of support",
    "Georgia ___",
    "Carnegie ___ (noted name in engineering)",
    "Part of M.I.T.: Abbr.",
    "\"Rambling Wreck From Georgia ___\"",
    "\"Ramblin' Wreck From Georgia ___\"",
    "Engineer's school",
    "Georgia ___",
    "Georgia ___",
    "Word with Georgia or high",
    "Georgia ___",
    "Georgia ___"
  ],
  "lyre": [
    "U-shaped stringed instrument",
    "Muse's instrument",
    "Heavenly instrument",
    "Erato's instrument",
    "Instrument for a Muse",
    "Instrument for an angel",
    "Erato played one",
    "Strings of yore",
    "Instrument for Orpheus",
    "Apollo plucked it",
    "Bard's instrument",
    "Instrument for a Muse",
    "Instrument played with a plectrum",
    "Orpheus' instrument",
    "Instrument in ancient Greek art",
    "Stringed instrument of old",
    "Apollo's instrument",
    "Instrument for Orpheus",
    "Instrument that's plucked",
    "Orpheus' instrument",
    "Recitation accompanier",
    "Greek harp",
    "Instrument made of turtle shell",
    "Cousin of a harp",
    "Ancient Greek instrument",
    "Ancient harp",
    "Invention of Hermes, supposedly",
    "Symbol of Apollo",
    "Apollo's plaything?",
    "Epic poet's prop",
    "It has curved arms",
    "Instrument for Orpheus",
    "Instrument for a Muse",
    "Orpheus's instrument",
    "Harp's cousin",
    "It's plucked",
    "Greek singer accompaniment",
    "Muse's instrument",
    "Harp's cousin",
    "Instrument on a Greek vase",
    "U-shaped instrument",
    "Apollo played it",
    "Orpheus' instrument",
    "Soothing instrument",
    "Prop for Orpheus",
    "Shell, to old poets",
    "Muse's instrument",
    "Instrument with a crossbar",
    "Swan's neighbor, in the night sky",
    "Crwth or kithara",
    "Strings of yore"
  ],
  "tsps": [
    "768 of them make a gal.",
    "Shortening used in many recipes",
    "Spice qtys.",
    "Bakers' amts.",
    "Measures of salt and sugar: Abbr.",
    "Recipe amts.",
    "Recipe measures: Abbr.",
    "Recipe amts.",
    "Cough syrup amts.",
    "Baker's units: Abbr.",
    "Cough syrup qtys.",
    "Recipe amts.",
    "48 in a cup: Abbr.",
    "Qtys. for chefs",
    "Recipe amts.",
    "Amts. of powder, maybe",
    "Alternatives to lumps: Abbr.",
    "Oz. sextet",
    "Sugar and spice amts.",
    "Fractions of fluid ozs.",
    "Kitchen amts.",
    "Equal amts.",
    "Recipe amts.",
    "Six of them make a fl. oz.",
    "Shortening in recipes?",
    "Sugar amts.",
    "Bakers' measures: Abbr.",
    "Six make a fl. oz.",
    "Six of these make a fl. oz.",
    "Recipe amts.",
    "Medicinal amts.",
    "Baking soda amts.",
    "Cooking amts.",
    "Recipe amts.",
    "Some are level: Abbr.",
    "Equal measures?: Abbr.",
    "Some are heaping: Abbr.",
    "Recipe amts.",
    "Recipe measures: Abbr.",
    "Sugar amts.",
    "Recipe measures: Abbr.",
    "Sugar amts.",
    "Recipe amts.",
    "Recipe amts.",
    "Dosage abbr.",
    "Recipe amts.",
    "Cookbook amts.",
    "Rx amts.",
    "Cough syrup amts.",
    "Dosage abbr.",
    "48 in a cup: Abbr.",
    "Medicinal amts.",
    "Sugar amts.",
    "Recipe abbr.",
    "Recipe amts.",
    "Recipe abbr.",
    "Sugar amts.",
    "Dosage amts.",
    "Dosage amts.",
    "Rx amts.",
    "Dosage amts.",
    "Recipe amts.",
    "There are three in a tbs.",
    "Sugar servings: Abbr.",
    "Dosage abbr.",
    "Recipe amts.",
    "A fl. oz. contains six",
    "Cookbook amts.",
    "Recipe amts.",
    "Medicinal amts.",
    "Child's measures: Abbr."
  ],
  "toga": [
    "Attire for Caesar",
    "Cover-up in old Rome",
    "Fraternity party costume made from a bedsheet",
    "It's a wrap",
    "Tough-to-sculpt part of some Roman statues",
    "Caesar wrap",
    "Forum garment",
    "Robe in old Rome",
    "Senate coverage?",
    "\"I, Claudius\" attire",
    "When repeated, \"Animal House\" chant",
    "Outfit in Caesar's senate",
    "___ party",
    "\"Julius Caesar\" costume",
    "Garment draped over the shoulders",
    "Attire in old Rome",
    "Attire for Atticus",
    "Caesar dressing?",
    "Stola : woman :: ___ : man",
    "Attire for Caesar",
    "Frat house party wear",
    "Senate cover-up",
    "Common feature in Roman statuary",
    "Forum wear",
    "Attire for Antonius",
    "___ party",
    "\"Gladiator\" garment",
    "\"Animal House\" party costume",
    "___ party",
    "Roman wrap",
    "Festive \"Animal House\" wrap",
    "It was wrapped around the Forum",
    "Feature of much ancient Roman statuary",
    "Caesar dressing?",
    "\"Spartacus\" attire",
    "Classical attire",
    "Word repeated in an \"Animal House\" chant",
    "\"Animal House\" garb",
    "Cover of the Colosseum?",
    "College party wear",
    "Forum wrap",
    "\"Julius Caesar\" costume",
    "Kegger cry",
    "Kind of party",
    "\"Animal House\" party attire",
    "Draped attire",
    "Ancient wrap",
    "Garb for 2-Down",
    "\"Animal House\" attire",
    "Certain party attire",
    "Male article of attire",
    "Roman robe",
    "Cloak for Claudius",
    "Forum wear",
    "Fraternity party attire",
    "\"Gladiator\" garment",
    "Roman cloak",
    "Attire for Caesar",
    "Sight on much old Roman statuary",
    "Robe for Caesar",
    "Roman Senate wear",
    "Senate cover-up",
    "Draped garment",
    "Roman robe",
    "Kind of party",
    "Wrap for some parties",
    "\"Animal House\" wear",
    "Forum wear",
    "Roman wrap",
    "Attire worn with sandals",
    "Fraternity party attire",
    "Robe for Caesar",
    "Robe",
    "Word repeated in an \"Animal House\" chant",
    "Roman wrap",
    "\"Animal House\" party wear",
    "Costume for Claudius",
    "Roman robe",
    "Cassius's costume",
    "Senate attire",
    "Colosseum cover-up",
    "Senate attire",
    "Ceremonial gown",
    "Fraternity party wear",
    "Chant at a fraternity party",
    "Kind of party",
    "\"I, Claudius\" attire",
    "Part of a frat party chant",
    "Attire for Antony",
    "\"Animal House\" wear",
    "Delta House attire",
    "\"Coriolanus\" costume",
    "Palatine garb",
    "Forum fashion",
    "Roman get-up"
  ],
  "tags": [
    "Mentions by name, in a tweet",
    "Identifies, as in a social media post",
    "Plates",
    "Graffiti signatures",
    "Identifies, as in a Facebook photo",
    "Identifiers at the bottom of a blog post",
    "Makes out?",
    "License plates",
    "G.I.'s wear",
    "Catches off base",
    "Makes \"it\"",
    "Little things that say \"To\" and \"From\"",
    "Makes it?",
    "Puts out, in baseball",
    "Identifies in a Facebook photo",
    "Nicknames",
    "Auto necessities",
    "Identifies, on Facebook",
    "Marks with graffiti",
    "IDs",
    "Graffitists' scrawls",
    "Makes \"it\"",
    "Auto needs",
    "Plates",
    "Makes \"it\"",
    "Marks",
    "Dog ___ (G.I. identifiers)",
    "Puts out, say",
    "Price indicators",
    "License plates",
    "Makes it",
    "Makes it",
    "Makes it?",
    "Some ID's",
    "Makes \"it\"",
    "Places for prices",
    "Yard sale staples",
    "Makes it",
    "Dog ID's",
    "Does like \"it\"",
    "Car owners need them"
  ],
  "lede": [
    "Opening of an article, in journalism lingo",
    "Start of a newspaper article, in journalese",
    "First line in a newspaper story",
    "Article's start, in journalism jargon",
    "First sentence of a news story",
    "First sentence of a news story",
    "Start of a news story, in journalism lingo",
    "Article's start, to a journalist"
  ],
  "balm": [
    "Lip service?",
    "Great relief",
    "Lip lotion",
    "Lip ___",
    "Comfort",
    "ChapStick, e.g.",
    "Topical treatment",
    "Lip ___",
    "Sunburn remedy",
    "Salve",
    "Lip ___",
    "Soothing ointment",
    "Healing ointment",
    "Soothing ointment",
    "Salve",
    "Unguent",
    "Aloe, naturally",
    "Soothing ointment",
    "Soothing cream",
    "Bee ___",
    "Pain soother",
    "Unguent",
    "Emollient",
    "Source of relief",
    "Soother",
    "Ointment",
    "Unguent",
    "Wound soother",
    "Healing lotion",
    "Calamine lotion, e.g.",
    "Salve",
    "Ointment"
  ],
  "nono": [
    "Forbidden action",
    "Ill-advised move",
    "Taboo",
    "Bad thing to do",
    "Faux pas",
    "Eating pizza with a fork and knife, to New Yorkers",
    "It's against the rules",
    "Roller-skating in the house, say",
    "It's verboten",
    "Talking in a movie theater, e.g.",
    "Eating with one's elbows on the table, e.g.",
    "Pitcher's feat, slangily",
    "Off-limits activity",
    "\"Don't touch that, honey!\"",
    "Texting while driving, e.g.",
    "Wearing red to a Chinese funeral, e.g.",
    "Texting in a theater, e.g.",
    "Taboo thing",
    "Taboo",
    "Texting while driving, e.g.",
    "Taboo",
    "\"Tsk, tsk\" elicitor",
    "Proscription",
    "Playing with matches, e.g.",
    "Don't do it",
    "\"That's a ___\"",
    "Talking during a movie, e.g.",
    "Texting while driving, e.g.",
    "Something to avoid",
    "Something you might get your hand slapped for doing",
    "It's taboo",
    "\"What were you thinking?!\"",
    "It's prohibited",
    "Taboo",
    "It's out of bounds",
    "Burping in public, e.g.",
    "It just isn't done",
    "Taboo",
    "Taboo",
    "Inadvisable action",
    "Off-limits item",
    "Putting regular gas in a diesel engine, e.g.",
    "Don't",
    "Taboo",
    "Taboo",
    "Moral philosophy topic",
    "Taboo",
    "A doofus might do it",
    "Talking on a cell phone during a movie, e.g.",
    "Don't",
    "Roving eyes on an exam, e.g.",
    "Elbows on the table, e.g.",
    "Smacking one's lips while eating, e.g.",
    "Taboo",
    "Cell phone ring in a theater, e.g.",
    "Taboo",
    "Using metallic dishes in a microwave, e.g.",
    "Slurping at the dinner table, e.g.",
    "Jumping a line, e.g.",
    "Inadvisable action",
    "Ringo Starr's \"___ Song\"",
    "Verboten thing",
    "Fudge, e.g., to a dieter",
    "Dessert, to a dieter",
    "Dessert, say, to a dieter",
    "Comment with a wrist-slap",
    "Faux pas",
    "Unacceptable act",
    "Eating peas with a knife, e.g.",
    "Rule breaker",
    "Inadvisable action",
    "Jaywalking, e.g.",
    "Jaywalking, e.g.",
    "Prohibition",
    "Forbidden thing",
    "Naughty deed",
    "Double negative?",
    "Proscription",
    "Forbidden fruit",
    "Thou-shalt-not",
    "Talking during a film, e.g.",
    "Taboo",
    "\"That's a ___!\"",
    "It's outlawed",
    "Taboo",
    "Banned act",
    "Taboo",
    "A don't",
    "Taboo",
    "Banned act",
    "It's off-limits",
    "It?Æs frowned upon",
    "Taboo",
    "Ringo Starr's \"___ Song\"",
    "Don't do it!",
    "It's forbidden"
  ],
  "keel": [
    "Tip (over)",
    "Topple (over)",
    "It's below belowdecks",
    "On an even ___ (steady)",
    "Backbone of a boat",
    "Balance beam?",
    "Bottom of the sea?",
    "Place for barnacles",
    "Ocean bottom?",
    "Something often described as \"even\"",
    "Something a scow lacks",
    "What's even on a ship?",
    "What a ship's ribs are connected to",
    "Shipbuilder's starting point",
    "Topple (over)",
    "Beam from one end to the other",
    "Start for a shipbuilder",
    "Bow attachment",
    "Boat's backbone",
    "Bottom of the ocean?",
    "Nautical bottom",
    "F-G",
    "Fall (over)",
    "It should be even",
    "Nautical stabilizer",
    "Part of a hull",
    "It runs from stem to stern",
    "Ship, to a poet",
    "Ship part",
    "Fall (over)",
    "Frigate part",
    "Stem-to-stern item",
    "Ship's backbone",
    "Fall (over)",
    "It goes from stem to stern",
    "Hull part",
    "The constellation Carina",
    "Nautical centerpiece",
    "Hull projection",
    "Boat's backbone",
    "Fall (over)",
    "Longitudinal boat timber",
    "Ship's spine",
    "Ship's central beam",
    "Ship, in poesy",
    "Boat's bottom",
    "Fall (over)",
    "Fall in a faint, with \"over\"",
    "It should be even",
    "\"Dallas\" actor Howard",
    "It goes from stem to stern",
    "Boat's backbone",
    "Hull attachment"
  ],
  "cate": [
    "Actress Blanchett",
    "Actress Blanchett",
    "Actress Blanchett",
    "Actress Blanchett",
    "Blanchett of \"The Aviator\"",
    "Actress Blanchett",
    "Actress Blanchett",
    "Actress Blanchett",
    "Oscar-winning actress Blanchett",
    "Actress Blanchett",
    "Blanchett of \"Blue Jasmine\"",
    "Blanchett of \"Blue Jasmine\"",
    "Actress Blanchett",
    "Oscar winner Blanchett",
    "Actress Blanchett",
    "Leonardo's \"The Aviator\" co-star",
    "Actress Blanchett",
    "Wife in \"8 Simple Rules for Dating My Teenage Daughter\"",
    "Blanchett of \"The Aviator\"",
    "Blanchett of \"Elizabeth\"",
    "Blanchett of \"Elizabeth\"",
    "Blanchett of \"Elizabeth\"",
    "Actress Blanchett of \"Elizabeth\"",
    "Actress Blanchett",
    "Elizabeth player, 1998"
  ],
  "okay": [
    "\"Sure, why not\"",
    "\"I guess so\"",
    "Give the go-ahead",
    "\"I guess\"",
    "Tolerable",
    "\"Sounds good!\"",
    "Green-light",
    "\"Fine by me\"",
    "\"I'm cool with that\"",
    "\"Yeah, why not!\"",
    "\"Deal!\"",
    "\"Sounds good to me\"",
    "\"Fine by me\"",
    "\"Got it\"",
    "Rubber-stamp",
    "\"Fine by me\"",
    "Word that can be a noun, verb, adjective, adverb or interjection",
    "Halfway decent",
    "Green-light",
    "\"Sounds good!\"",
    "Approved",
    "Allow",
    "\"Fine by me\"",
    "\"Fine by me\"",
    "\"Fine with me!\"",
    "Thumbs-up",
    "\"Fine by me\"",
    "\"Sure!\"",
    "\"I suppose\"",
    "\"That's fine\"",
    "Sound",
    "So-so",
    "Give a thumbs-up",
    "Approve",
    "Give the go-ahead",
    "Green-light",
    "\"Go ahead!\"",
    "Up to snuff",
    "What \"thumbs up\" means",
    "\"Sure, go ahead\"",
    "\"Fine by me\"",
    "\"Comme ci, comme ca\"",
    "\"Fine by me\"",
    "Copacetic",
    "Unhurt",
    "Sound",
    "Sanction",
    "Approve",
    "Green-light",
    "Less than spectacular",
    "Rubber-stamp",
    "\"I'll go along with that\"",
    "Thumbs-up",
    "Not spectacular",
    "Initial",
    "Sanction",
    "Passable",
    "\"Is that all right?\"",
    "Go-ahead",
    "Acceptable",
    "Initial, say",
    "Sanction",
    "Endorse",
    "\"Why not?\"",
    "\"Sure, why not?\"",
    "Approval",
    "\"You bet!\"",
    "Green light",
    "Approve",
    "Authorize",
    "Say yes to",
    "Approve",
    "Approve",
    "\"Sure, why not?\"",
    "Fair-to-middling",
    "Rubber-stamp"
  ],
  "nike": [
    "Brand with a swoosh logo",
    "Daughter of Styx",
    "Goddess whose Roman counterpart is Victoria",
    "Certain sneak",
    "Air Force 1 maker",
    "Winged Greek goddess",
    "Trophy figure",
    "Greek goddess of victory",
    "\"Just do it\" sloganeer",
    "Greek figure on many a trophy",
    "Company with a swoosh logo",
    "Trophy figure",
    "Company endorsed by LeBron James",
    "[Sportswear]",
    "Greek goddess seen on many trophies",
    "Big name in sneakers",
    "Brand with a swoosh",
    "Sponsor of Michael Jordan and Tiger Woods",
    "Victory goddess",
    "Company endorsed by Tiger Woods",
    "Figure on the front of Olympic medals since 1928",
    "\"Swoosh\" company",
    "Athletics brand with a swoosh",
    "\"Swoosh\" brand",
    "Brand that may be worn with a 2-Down",
    "Goddess in the hand of the statue of Athena in the Parthenon",
    "Greek goddess of victory",
    "Giant in footwear",
    "Puma rival",
    "Swoosh maker",
    "Winged goddess",
    "New Balance competitor",
    "Victory goddess",
    "?ôJust do it?ö sloganeer",
    "Big name in sneakers",
    "Shoe with a swoosh",
    "Goddess on a trophy",
    "Greek goddess of victory",
    "\"Just do it\" sloganeer",
    "Winged goddess",
    "Defense missile",
    "Corporate giant named for a mythological character",
    "Big sports event sponsor",
    "Advertiser with a swoosh",
    "Running shoe name",
    "Greek goddess of victory",
    "Reebok rival",
    "Shoemaker",
    "Adidas rival",
    "Winged Victory",
    "___ of Samothrace"
  ],
  "cred": [
    "Good reputation, in slang",
    "Rep",
    "Standing on the street",
    "Street ___",
    "Believability, for short",
    "Street ___",
    "Standing on the street",
    "Rep",
    "Rep",
    "Reputation on the street",
    "Rep",
    "Rep",
    "Rep",
    "Rep",
    "Street ___",
    "Rep on the street",
    "Street ___",
    "Reputation on the street",
    "Good rep",
    "Props on the street",
    "Reputation, informally",
    "Reputation, on the street",
    "Street ___",
    "Street ___",
    "Street ___ (asset in the hood)"
  ],
  "mole": [
    "Small mammal that lives mostly underground",
    "Animal that's also a plant?",
    "Mexican sauce made from chili peppers and chocolate",
    "Undercover operative",
    "Spy on the inside",
    "Noted Marilyn Monroe feature",
    "Enchilada topper",
    "Infiltrator",
    "Beauty mark",
    "Double agent",
    "MI6 concern",
    "Spicy chocolate sauce",
    "Insider informant",
    "Underground pest",
    "Mexican sauce",
    "Double agent",
    "Infiltrator",
    "Distinctive Cindy Crawford feature",
    "K.G.B. concern",
    "Subversive one",
    "Distinctive Marilyn Monroe facial feature",
    "Plant, maybe",
    "Sleeper agent",
    "Plant, of a sort",
    "Spymaster's worry",
    "Tunnel creator",
    "Spicy sauce",
    "Marilyn's mark",
    "Uninvited rooter",
    "Spicy chocolate sauce",
    "Spy from within",
    "Beauty mark",
    "Infiltrator",
    "Marilyn Monroe facial mark",
    "Furry tunneler",
    "Intelligence problem",
    "Person making unauthorized reports",
    "Yard pest",
    "C.I.A. worry",
    "Double agent",
    "Marilyn Monroe mark",
    "Unwelcome C.I.A. discovery",
    "Spymaster's worry",
    "Blemish",
    "Spy",
    "Suburban tunneler",
    "Dermatologist's concern",
    "Dermatologist's concern",
    "Marilyn Monroe feature",
    "Destructive digger",
    "Spymaster's worry",
    "Suburban pest",
    "Security concern",
    "Unwelcome rooter",
    "Double agent",
    "It's hard on a yard",
    "Infiltrator",
    "C.I.A. worry",
    "Underground type",
    "Marilyn Monroe facial feature",
    "Lawn pest",
    "Potential C.I.A. problem",
    "Lawn wrecker",
    "Yard pest",
    "Aldrich Ames, for one",
    "Undercover operative",
    "Source of a leak",
    "Yard digger",
    "K.G.B. worry",
    "\"The Wind in the Willows\" character"
  ],
  "unit": [
    "Cubit or karat",
    "Apartment, in real estate lingo",
    "Quart, liter or gallon",
    "Apartment, in real estate talk",
    "Condo, e.g.",
    "Tesla, for one",
    "Apartment or condo",
    "Condo, e.g.",
    "Foot or furlong",
    "Something that's often named after a scientist",
    "Syllabus section",
    "Textbook section",
    "Foot, fathom or furlong",
    "Rod or reel",
    "The \"U\" in I.C.U.",
    "Meter or liter",
    "The \"U\" of I.C.U.",
    "Watt or knot",
    "Soldier's assignment",
    "Building block",
    "The \"U\" of B.T.U.",
    "Company, e.g.",
    "Syllabus section",
    "Meter or liter",
    "Course part",
    "Collection of textbook chapters",
    "Infantry division",
    "Soldier's assignment",
    "Storage ___",
    "Gram or dram",
    "Corporate division",
    "Foot or yard",
    "The \"U\" of I.C.U.",
    "Company, e.g.",
    "Troop group",
    "Fermi, for one",
    "Curriculum component",
    "College course part",
    "Textbook segment",
    "The \"U\" of C.P.U.",
    "Military group",
    "Stone, e.g.",
    "The \"U\" of B.T.U.",
    "Condo, e.g.",
    "Ton, e.g.",
    "Hand or foot",
    "Newton, e.g.",
    "Squad, e.g.",
    "Newton, e.g.",
    "Battalion, e.g.",
    "The \"U\" of \"Law & Order: SVU\"",
    "Syllabus segment",
    "Soldier's assignment",
    "The \"U\" in C.P.U.",
    "Metric ___",
    "Foot, e.g.",
    "Family ___",
    "Peck, pint or pound",
    "Liter or meter",
    "Condo, e.g.",
    "Cadre, e.g.",
    "Knot, say",
    "Transfusion amount",
    "Peck, e.g.",
    "See 23-Across",
    "Army division",
    "The \"U\" in I.C.U.",
    "See 61-Across",
    "Newton or ohm",
    "Platoon, e.g.",
    "Second, e.g.",
    "Cadre, e.g.",
    "___ of measure",
    "One",
    "The \"U\" in 21-Down",
    "Body of troops",
    "The \"U\" in B.T.U.",
    "Military group",
    "Pint, inch or second",
    "Condo or apartment",
    "Hand or foot",
    "Newton or tesla",
    "Mil or mile",
    "___ of measure",
    "Meter or liter",
    "Complex part?",
    "Condo, e.g.",
    "Condo, e.g.",
    "Condo, e.g.",
    "Soldier's group",
    "Newton, for one",
    "The \"U\" in I.C.U.",
    "Newton, e.g.",
    "Newton, for one",
    "Inch or teaspoon",
    "Housing ___",
    "Mile or mil",
    "Apparatus",
    "Workbook segment",
    "Pascal or newton",
    "Condo, e.g.",
    "See 39-Across",
    "Building block",
    "Apartment",
    "Complex dwelling",
    "Module",
    "The ?ôU?ö in I.C.U.",
    "Hand or foot",
    "Military group",
    "Fermi or Faraday",
    "Realtor's sale",
    "Segment covered by a test, perhaps",
    "Ton, for one",
    "Knot or watt",
    "Troop group",
    "Military assignment",
    "The \"U\" of B.T.U.",
    "Military subdivision",
    "Curriculum section",
    "Squad",
    "Mile, e.g.",
    "Module",
    "Soldiers' group",
    "Condo, to a real estate agent",
    "Graduation mark",
    "Newton, e.g.",
    "Detachment of soldiers",
    "Condo",
    "Part of C.P.U.",
    "The \"U\" in I.C.U.",
    "Condo division",
    "Soldier's assignment",
    "The \"U\" in I.C.U.",
    "Complex division",
    "Platoon or squadron, e.g.",
    "Part of I.C.U.",
    "Course, in education lingo",
    "Hand or foot",
    "Troop group",
    "The \"U\" of CPU",
    "Motel room",
    "Kind of pricing",
    "Subdivision",
    "College course division",
    "Kind of pricing",
    "Measuring standard",
    "Condominium, e.g.",
    "Company part",
    "Condo, e.g.",
    "School course part",
    "Condo division",
    "Sergeant's command",
    "Inch, e.g.",
    "Module",
    "Army outfit",
    "Military group",
    "Part of CPU",
    "Military group",
    "Condo",
    "Part of a whole",
    "Body of troops",
    "Word with military or heating",
    "Wall ___ (furniture item)",
    "Part of B.T.U.",
    "Part of B.T.U.",
    "Entity",
    "Platoon, e.g.",
    "28-Down, e.g.",
    "Part of C.C.U.",
    "The \"U\" in B.T.U.",
    "Kind of price",
    "Condo",
    "The \"U\" in B.T.U.",
    "Army outfit",
    "Hawkeye's MASH, for one",
    "Component part",
    "Wall ___ (furniture purchase)",
    "Apartment",
    "Military subdivision",
    "Army outfit",
    "Part of B.T.U.",
    "Hand or foot",
    "Military group",
    "Singleton",
    "Singleton",
    "Military squad",
    "Cohesive entity"
  ],
  "toot": [
    "Play a trumpet, e.g.",
    "Warn with a horn",
    "Tugboat sound",
    "Blow a whistle",
    "[C'mon, the light turned green!]",
    "Friendly honk",
    "Blow one's horn",
    "Spree",
    "Drinking spree",
    "Drinking spree",
    "Friendly greeting on the highway",
    "It's a blast",
    "Blow on a horn",
    "Road rage sound",
    "Palindromic blast",
    "Palindromic blast",
    "Tugboat's greeting",
    "Horn sound",
    "Warning on a highway",
    "It might mean \"hello\" or \"goodbye\" to a driver",
    "Drinking binge",
    "Beep",
    "Blow a whistle",
    "Binge",
    "Drunken spree",
    "Warning from a driver",
    "Tugboat's call",
    "Barfly's binge",
    "Driver's nonverbal \"hello\"",
    "Trolley warning",
    "Blow the whistle",
    "Binge",
    "Blow",
    "Spree",
    "Sound of impatience",
    "Horn sound",
    "Attention-getting sound",
    "Horn sound",
    "Tugboat signal",
    "It's a blast",
    "Honk",
    "Traffic sound",
    "Binge",
    "Tugboat sound",
    "Sound of impatience",
    "Barfly's binge",
    "Binge",
    "Horn sound",
    "Horn's sound",
    "\"Out of my way!\" indicator",
    "Bender",
    "Honk",
    "Drinker's spree",
    "Choo-choo's sound",
    "Binge",
    "Train whistle sound",
    "Choo-choo's sound",
    "Barfly's binge",
    "Horn sound",
    "Short blast",
    "Binge",
    "Binge",
    "Bender",
    "Binge",
    "Tugboat sound",
    "Harbor alert",
    "Blow the whistle",
    "Binge",
    "Beep",
    "Horn sound",
    "Drinking spree",
    "Drinking binge"
  ],
  "emit": [
    "Send off, as rays",
    "Give off",
    "Give off",
    "Release",
    "Discharge",
    "Send off, as rays",
    "Send out",
    "Send off",
    "Send forth",
    "Release",
    "Send off",
    "Shoot out",
    "Send off, as rays",
    "Give off, as heat",
    "Give off",
    "Give off",
    "Discharge",
    "Spew out",
    "Give off",
    "Give out",
    "Issue",
    "Radiate",
    "Send out, as waves",
    "Release",
    "Put out",
    "Give off",
    "Send out",
    "Give off",
    "Give off",
    "Give off",
    "Let off",
    "Let out",
    "Radiate",
    "Radiate",
    "Send off, as rays",
    "Let out",
    "Give off",
    "Shoot off",
    "Give off, as rays",
    "Utter, as a sound",
    "Let out",
    "Discharge",
    "Give off",
    "Give off, as vibes",
    "Release, as gas",
    "Shoot out",
    "Give out",
    "Send off, as rays",
    "Give off",
    "Throw off",
    "Give off",
    "Send off",
    "Throw out",
    "Send out",
    "Send out",
    "Let off",
    "Throw out",
    "Discharge",
    "Throw off",
    "Put out",
    "Radiate",
    "Shoot out",
    "Send out",
    "Let out",
    "Give off",
    "Radiate",
    "Radiate",
    "Give off",
    "Issue",
    "Squirt, e.g.",
    "Discharge",
    "Release",
    "Issue",
    "Give off",
    "Discharge",
    "Give off",
    "Radiate",
    "Send out, as rays",
    "Give off",
    "Throw off",
    "Spew",
    "Shoot out",
    "Broadcast",
    "Give off",
    "Spew",
    "Discharge",
    "Radiate",
    "Issue",
    "Put out",
    "Radiate",
    "Let off",
    "Ooze",
    "Give forth",
    "Release",
    "Give off",
    "Let out",
    "Release",
    "Throw off",
    "Shoot out",
    "Issue",
    "Discharge",
    "Give off",
    "Broadcast",
    "Send out",
    "Give out",
    "Give off",
    "Shoot out",
    "Throw off",
    "Release",
    "Put out",
    "Give off",
    "Send out",
    "Give off",
    "Broadcast",
    "Radiate",
    "Give off",
    "Send out",
    "Spurt",
    "Send out",
    "Give off",
    "Send out",
    "Give off",
    "Let out",
    "Give off",
    "Send forth",
    "Discharge",
    "Send off",
    "Release",
    "Put out",
    "Utter",
    "Spew",
    "Give out",
    "Give out",
    "Radiate",
    "Give off",
    "Send out",
    "Radiate",
    "Release",
    "Utter",
    "Vent, in a way",
    "Send out",
    "Broadcast",
    "Discharge",
    "Shed",
    "Give off",
    "Send forth",
    "Give off",
    "Give off",
    "Send out",
    "Issue",
    "Radiate",
    "Give off",
    "Give off",
    "Discharge",
    "Give off",
    "Broadcast",
    "Issue",
    "Send out",
    "Send out",
    "Discharge",
    "Give off",
    "Not absorb",
    "Give off",
    "Throw off",
    "Send off",
    "Discharge",
    "Issue",
    "Put out",
    "Give forth",
    "Send off",
    "Issue",
    "Utter",
    "Send forth",
    "Issue",
    "Give off",
    "Release",
    "Release",
    "Send out",
    "Utter",
    "Send out",
    "Send off",
    "Give off",
    "Shed",
    "Give off, as light",
    "Give out",
    "Shoot off",
    "Release",
    "Discharge",
    "Send out",
    "Give off",
    "Spew",
    "Let out",
    "Send out",
    "Discharge",
    "Let off",
    "Spew",
    "Broadcast",
    "Give off",
    "Put out",
    "Give off",
    "Give off",
    "Radiate",
    "Give off",
    "Issue",
    "Discharge",
    "Send forth",
    "Radiate",
    "Radiate",
    "Throw off",
    "Spew forth",
    "Discharge",
    "Yield",
    "Send forth",
    "Give forth",
    "Give off",
    "Issue",
    "Radiate"
  ],
  "lear": [
    "Titular Shakespearean king",
    "King of Shakespeare",
    "King quoted as saying \"How sharper than a serpent's tooth it is / To have a thankless child!\"",
    "Shakespeare character who says \"How sharper than a serpent's tooth it is / To have a thankless child!\"",
    "Big name in jets",
    "Regan's father",
    "King of the stage",
    "Shakespearean father of three",
    "Who said \"How sharper than a serpent's tooth it is to have a thankless child!\"",
    "King who said \"Nothing will come of nothing\"",
    "Shakespearean king",
    "Shakespearean king",
    "King noted for saying \"How sharper than a serpent's tooth it is / To have a thankless child!\"",
    "Edward who wrote \"A Book of Nonsense\"",
    "Disinheritor of Cordelia",
    "Shakespearean king",
    "\"I am a man more sinn'd against than sinning\" speaker",
    "Shakespearean king",
    "Shakespearean king",
    "\"Laughable Lyrics\" poet",
    "King of tragedy",
    "King with a sad end",
    "\"Laughable Lyrics\" writer",
    "King with the immortal line \"Who is it that can tell me who I am?\"",
    "The Duke of Albany's father-in-law",
    "Norman of TV fame",
    "King on a stage",
    "Edward who wrote humorous verse",
    "Father of Goneril",
    "Norman who created \"All in the Family\"",
    "Duke of Cornwall's father-in-law, in Shakespeare",
    "\"A Book of Nonsense\" author, 1846",
    "\"There was an old man ...\" poet",
    "King of the stage",
    "King who was the father of Cordelia",
    "King of tragedy",
    "He wrote \"There was an old man of Thermopylae / Who never did anything properly ...\"",
    "Shakespeare's \"very foolish fond old man\"",
    "King of drama",
    "Father of Regan",
    "Creator of \"All in the Family\"",
    "Shakespearean character who calls himself \"a very foolish fond old man\"",
    "Small jet maker",
    "Mad king of the stage",
    "Shakespearean king",
    "Shakespearean king",
    "Edward who wrote \"The Owl and the Pussycat\"",
    "\"All in the Family\" producer Norman",
    "Cordelia's father",
    "Edward who popularized the limerick",
    "Shakespearean king",
    "TV producer Norman",
    "Regan's father",
    "Albany's father-in-law",
    "See 52-Down",
    "Shakespearean king",
    "Shakespearean king",
    "King of tragedy",
    "\"Laughable Lyrics\" writer",
    "\"The Jumblies\" poet",
    "The Earl of Kent is his courtier",
    "Regan's father",
    "Cordelia's father",
    "\"There was an Old Man with a beard\" writer",
    "Regan's father",
    "Shakespearean king",
    "Kind of jet",
    "Limerick maker",
    "Shakespearean king",
    "\"Laughable Lyrics\" writer",
    "Shakespearean king",
    "Kind of jet",
    "Gloucester's king",
    "Norman of sitcom fame",
    "Shakespeare's \"very foolish fond old man\"",
    "See 12-Down",
    "Limerick man",
    "Shakespearean king",
    "\"Maude\" producer Norman",
    "Shakespearean king",
    "Role for Gielgud",
    "Regan's father",
    "Limerick man",
    "Cordelia's father",
    "\"The Owl and the Pussy Cat\" writer",
    "Noted Norman",
    "Regan's father",
    "Tragic king",
    "___ jet",
    "Limerick man",
    "Carroll contemporary",
    "Noted James Earl Jones stage role",
    "Goneril's father",
    "\"Sanford and Son\" producer"
  ],
  "baal": [
    "Biblical false god",
    "Whom the children of Israel served, per Judges",
    "False divinity",
    "False god",
    "False god",
    "Jezebel's idol",
    "False god",
    "False deity",
    "False deity",
    "Jezebel's god",
    "Ancient fertility god",
    "Canaanite deity",
    "Deity worshiped with much sensuality",
    "False god",
    "Semitic deity",
    "False deity",
    "Jezebel's idol",
    "Canaanite god",
    "Whom Jezebel worshiped",
    "False god",
    "False god",
    "Foe of Elijah",
    "False god",
    "False idol",
    "God of the Canaanites",
    "Jezebel's idol",
    "Canaanite deity",
    "False god",
    "False god",
    "Canaanite deity",
    "False god",
    "Canaanite's deity",
    "Ancient Semitic idol",
    "False god",
    "False god",
    "Object of Canaanite worship",
    "False god",
    "False god"
  ],
  "rows": [
    "Counterpart of columns",
    "Feuds",
    "Kerfuffles",
    "Matrix specifications",
    "Spats",
    "Squabbles",
    "Toils on a trireme",
    "Counterparts of columns",
    "Melees",
    "Melees",
    "---",
    "Garden divisions",
    "Plows follow them",
    "Tetris objectives",
    "Theater seating",
    "Rank and file",
    "Noisy to-dos",
    "Seating sections",
    "Noisy quarrels",
    "Has the oars",
    "Knock-down-drag-outs",
    "Noisy fights",
    "Column crossers",
    "Scraps",
    "Grid parts",
    "Spreadsheet components",
    "Column crossers",
    "Minds the cox",
    "Moves a shell",
    "Sets of pews",
    "Sculls",
    "Propels a dinghy",
    "Paddles",
    "Dust-ups",
    "Tiers",
    "Wrangles",
    "Theater components",
    "Noisy fights",
    "Participates in a regatta, perhaps"
  ],
  "baht": [
    "Thai currency",
    "Currency of Thailand",
    "100 satang, in Thailand",
    "Coin whose obverse shows King Maha Vajiralongkorn",
    "Thai currency",
    "Bangkok banknotes",
    "Thai money",
    "Thai currency",
    "Bangkok currency",
    "Thai currency",
    "Thai money",
    "Thai currency",
    "Thai money",
    "Thai currency",
    "It's banked in Bangkok",
    "Thai cash",
    "Thai money",
    "Bangkok money",
    "Bangkok buck",
    "Thai currency",
    "Thai money"
  ],
  "okie": [
    "1930s migrant",
    "1930s migrant to California",
    "Merle Haggard tune \"___ From Muskogee\"",
    "Elizabeth Warren, by birth",
    "Sooner, informally",
    "\"___ From Muskogee\" (Merle Haggard hit)",
    "Neighbor of an Arkansawyer",
    "Dust Bowl traveler",
    "'30s migrant",
    "\"The Grapes of Wrath\" figure",
    "Neighbor of an Arkansawyer",
    "\"The Grapes of Wrath\" migrator",
    "Neighbor of a Jayhawker",
    "Depression Era refugee",
    "Depression-era migrant",
    "\"The Grapes of Wrath\" itinerant",
    "\"The Grapes of Wrath\" migrant",
    "Sooner in history?",
    "Depression-era migrant",
    "Dust Bowl migrant",
    "Sooner State resident, informally",
    "'30s migrant",
    "Dust Bowl migrant",
    "\"The Grapes of Wrath\" figure",
    "1930s migrant",
    "Steinbeck character",
    "Sooner alternative",
    "\"The Grapes of Wrath\" figure",
    "1930s migrant",
    "Dust Bowl witness",
    "Ado Annie, e.g.",
    "Merle Haggard's \"___ From Muskogee\"",
    "Kind of accent used by Ado Annie",
    "Lone Star Stater's northern neighbor",
    "Depression-era migrant",
    "Arkie neighbor",
    "Dust Bowl migrant",
    "Sooner",
    "Depression-era migrant",
    "Tex's neighbor",
    "Tom Joad, e.g.",
    "Sooner",
    "Dust Bowl migrant",
    "Steinbeck figure",
    "1930s migrant",
    "Tex's neighbor to the north",
    "Depression-era migrant",
    "Tom Joad, for one",
    "Merle Haggard, self-descriptively",
    "Neighbor of an Arkie",
    "Dust Bowl refugee",
    "Dust Bowl refugee",
    "30's migrant",
    "Merle Haggard, in song",
    "Muskogee native",
    "Panhandle migrant",
    "Depression-era figure",
    "Merle Haggard's \"___ From Muskogee\"",
    "Depression-era drifter",
    "Dust Bowl refugee",
    "\"The Grapes of Wrath\" figure",
    "\"The Grapes of Wrath\" figure",
    "Dust Bowl victim",
    "Tom Joad, for one",
    "Merle Haggard's \"___ From Muskogee\"",
    "Depression era figure",
    "\"The Grapes of Wrath\" figure",
    "Dust Bowl figure",
    "Dust Bowl refugee",
    "\"___ From Muskogee\"",
    "Tom Joad, e.g.",
    "Tom Joad, for one",
    "Merle Haggard's \"___ From Muskogee\"",
    "1930's dust bowler",
    "Merle Haggard song character",
    "Panhandler, perhaps",
    "Merle Haggard, self-admittedly",
    "Steinbeck figure",
    "30's migrant",
    "Depression Era figure",
    "Arkie neighbor",
    "Tom Joad, e.g.",
    "Merle Haggard's \"___ From Muskogee\"",
    "Sooner migrant",
    "Tom Joad, e.g.",
    "30's migrant",
    "Dorothea Lange photo subject",
    "\"___ From Muskogee\"",
    "\"___ From Muskogee\" (1970 hit)",
    "Steinbeck emigrant",
    "Tom Joad, e.g.",
    "Tom Joad, e.g.",
    "\"The Grapes of Wrath\" worker"
  ],
  "dine": [
    "Have a nice meal",
    "Eat fancily",
    "Sup",
    "Banquet",
    "Eat stylishly",
    "Take some courses",
    "Banquet",
    "Feast",
    "Have a fancy meal",
    "Sup",
    "Wine's partner",
    "Eat by candlelight",
    "Wine's partner",
    "Eat by candlelight, say",
    "Eat in style",
    "Have supper",
    "Enjoy a spread",
    "Sup",
    "Eat by candlelight, say",
    "Feast",
    "Feast",
    "Eat",
    "Banquet",
    "Have some fancy provisions?",
    "Eat fancily",
    "Have something",
    "Feast (on)",
    "Banquet",
    "Have pheasant under glass, say",
    "Banquet",
    "Break bread",
    "Sup",
    "See 26-Down",
    "Banquet",
    "Celebrate gustatorily",
    "Eat fancily",
    "Wine?Æs companion",
    "Take lunch",
    "Eat well",
    "Feast",
    "Feast"
  ],
  "step": [
    "Any rung on a ladder",
    "Algorithm part",
    "Manual part",
    "Bit recorded on a Fitbit",
    "Ladder rung",
    "Part of a wikiHow article",
    "Part of a stairway",
    "Part of an assembly instruction",
    "How-to manual component",
    "A to B, say",
    "Part of the process",
    "___ class (provider of an aerobic workout)",
    "When repeated, workout class instruction",
    "Part of a process",
    "One of 354 in the Statue of Liberty",
    "Fitbit unit",
    "Algorithm part",
    "Bit of dance instruction",
    "Part of a recipe",
    "One of 12 in Alcoholics Anonymous",
    "Fitbit bit",
    "Flight part before a landing",
    "Flight part",
    "\"Watch your ___!\"",
    "Escalator feature",
    "Process part",
    "\"Watch your ___!\"",
    "Group dance with stomps and claps",
    "Dance movement",
    "Part of a how-to manual",
    "Part of a flight",
    "Repeated cry at a dance class",
    "Very short climb",
    "Choreographer's concern",
    "It may help you get up",
    "Echoing sound in a hallway, maybe",
    "Rung",
    "Manual component",
    "Put one's foot down",
    "Stairs unit",
    "Bit of a climb",
    "When repeated, an aerobics class cry",
    "Flight component",
    "When repeated, aerobics class cry",
    "Walking distance",
    "Any \"cha\" in the cha-cha-cha",
    "Stride",
    "Bit of action",
    "Directive repeated in an aerobics class",
    "Bit of choreography",
    "Short distance",
    "Big first for a baby",
    "Short distance",
    "Rung",
    "Dance move",
    "Flight level",
    "Repeated cry in an exercise class",
    "\"Watch your ___!\"",
    "Dance move",
    "Fred Astaire move",
    "Kind of aerobics",
    "Ballet bit",
    "Instruction part",
    "Part of a flight",
    "How-to unit",
    "When repeated, aerobic instructor's cry",
    "Gait",
    "Bit of a climb",
    "One of 12 for the Alcoholics Anonymous program",
    "Whole tone, e.g.",
    "One ___ at a time",
    "Assembly instructions part",
    "Interval on a scale",
    "Dance move",
    "Challenge for the wheelchair-bound",
    "Rung",
    "1/12 of a recovery program",
    "Dance bit",
    "Part of a program",
    "Dance lesson",
    "Algorithm part",
    "Instructions part",
    "Minimal progress",
    "Manual component",
    "Stone at a stream crossing",
    "How-to unit",
    "Ladder rung",
    "Algorithm component",
    "Rung",
    "See 25-Across",
    "Short distance",
    "When repeated, a dance instructor's call",
    "Stair part",
    "Rung",
    "Flight segment",
    "Ladder rung",
    "One foot forward",
    "\"___ lively!\"",
    "Aerobic instructor's word",
    "Put one's foot down",
    "One of 12 at Alcoholics Anonymous",
    "Rung",
    "Way down",
    "Aerobic bit",
    "Cry repeated in aerobics class",
    "Tread",
    "Bit of choreography",
    "Put one's foot down?",
    "Initiative",
    "Measure",
    "Going together, after \"in\"",
    "Part of instructions",
    "Help on the way up",
    "Measure to take",
    "Rung",
    "With 34-Down, procedure starter",
    "Dance instructor's instruction",
    "Flight segment",
    "Degree in music",
    "Part of a process",
    "Footfall",
    "Put one's foot down",
    "Choreography move",
    "Dance unit",
    "Dance instructor's call",
    "Put one's foot down",
    "Rung",
    "Dance instructor's call",
    "Bit of instruction",
    "Aerobic bit",
    "Put one's foot down",
    "Escalate, with \"up\"",
    "Aerobics action",
    "Stair unit",
    "Aerobics class order",
    "Kind of aerobics",
    "Rung",
    "Stage",
    "Stair part",
    "Rung",
    "Big first for a baby",
    "Dance lesson",
    "Rung",
    "___ aerobics",
    "Dance instructor's call",
    "Measure",
    "Big first for a baby",
    "Part of an aerobics exercise",
    "Part of a process",
    "Exercise routine bit",
    "Stage",
    "Dance bit",
    "Short distance",
    "Small progress",
    "Dance instruction",
    "Recipe part",
    "Kind of aerobics",
    "Escalator part",
    "Gait",
    "Dance lesson",
    "Put one's foot down",
    "Procedure part",
    "Footfall",
    "Escalator part",
    "Part of an Army chant",
    "Put one's foot down",
    "Neil Armstrong movement",
    "Authur Murray lesson",
    "Measure",
    "Process part",
    "Bit of a climb",
    "Dance bit",
    "Word repeated by a drill sergeant",
    "Measure",
    "Escalator segment",
    "\"Watch your___!\"",
    "Stair part",
    "Part of a process",
    "\"___ right up!\"",
    "Flight segment",
    "Word with dance or stair",
    "Tread",
    "Stair part",
    "\"___ lively!\"",
    "Stairway component",
    "Procedure part",
    "Porch adjunct",
    "Arthur Murray teaching",
    "Maneuver",
    "Word before mother or lively",
    "Rung"
  ],
  "elle": [
    "Magazine co-founded in 1945 by Hélène Gordon Lazareff",
    "She: Fr.",
    "Vogue competitor",
    "International fashion magazine",
    "___ Woods, lead character in \"Legally Blonde\"",
    "Her: Fr.",
    "Fashion monthly with more than 40 international editions",
    "Vogue competitor",
    "Her, to Henri",
    "She: Fr.",
    "French fashion magazine since 1945",
    "Fashion magazine with more than 40 international editions",
    "Long-running fashion magazine",
    "Magazine with an \"Ask E. Jean\" column",
    "___ Style Awards (annual honors since 1997)",
    "Actress Fanning",
    "Her: Fr.",
    "Magazine that's weekly in France but monthly in the U.S.",
    "Fashion magazine with more than 40 international editions",
    "Fashion monthly founded in France",
    "Fashion magazine with a French name",
    "___ Decor (magazine)",
    "Fashion monthly",
    "Competitor of Allure",
    "\"Legally Blonde\" girl",
    "Vogue rival",
    "Fashion magazine that can be read from back to front?",
    "Vogue rival",
    "___ Decor (Hearst magazine)",
    "Vogue competitor",
    "Vogue rival",
    "Women's issue?",
    "Vogue competitor",
    "Fashion magazine founded in France",
    "Glamour rival",
    "Magazine with a palindromic name",
    "Palindromic fashion magazine",
    "W competitor",
    "Glamour rival",
    "Palindromic magazine title",
    "Vogue rival",
    "What the French might call 62-Across",
    "Magazine offering \"Beauty Tips, Fashion Trends & Celebrity News\"",
    "___ Style Awards",
    "Early \"Project Runway\" sponsor",
    "Woman's name that sounds like a letter",
    "Cosmopolitan competitor",
    "Allure or Essence alternative",
    "Shelfmate of Vogue",
    "Notre dame, e.g.",
    "W alternative",
    "She, overseas",
    "Allure alternative",
    "Vogue alternative",
    "Cette fille, e.g.",
    "Women's magazine with a palindromic name",
    "25-Down, en francais",
    "Fashion magazine with a French name",
    "W competitor",
    "Magazine whose name means \"she\"",
    "French \"she\"",
    "Magazine whose name sounds like a letter of the alphabet",
    "International magazine founded in France in 1945",
    "Palindromic magazine title",
    "Vogue rival",
    "Magazine that serialized Simone de Beauvoir's 1967 \"La femme rompue\"",
    "Fashion magazine founded in France",
    "Fashion magazine",
    "Fashion magazine originally from France",
    "French \"her\"",
    "Vogue competitor",
    "What an aspiring model may read",
    "Magazine title that's a pronoun",
    "She, in Cherbourg",
    "Fashionista's read, maybe",
    "Lui: him :: ___ : her",
    "Vogue competitor",
    "Women's magazine founded in France",
    "Palindromic magazine name",
    "Fashion magazine founded in France",
    "Publication with an annual \"Green Issue\"",
    "Catherine Deneuve was on its first U.S. cover",
    "Vogue competitor",
    "Sponsoring publication of TV's \"Project Runway\"",
    "Reese's \"Legally Blonde\" role",
    "___ Style Awards",
    "Vogue competitor",
    "French \"she\"",
    "Self-descriptive French name",
    "Fashion magazine",
    "W shelfmate",
    "Fashion magazine",
    "Fashion magazine",
    "American magazine founded in France",
    "She, in Cherbourg",
    "Vogue rival",
    "___ Girl (magazine)",
    "That girl, in Paris",
    "Lui's opposite",
    "Allure competitor",
    "Fashion magazine",
    "\"Legally Blonde\" blonde",
    "Reese's role in \"Legally Blonde\"",
    "Fashion monthly",
    "Magazine with a palindromic name",
    "Allure shelfmate",
    "Macpherson of \"Sirens\"",
    "Glamour rival",
    "\"Legally Blonde\" girl",
    "Marie Claire competitor",
    "Palindromic magazine title",
    "Women's issue?",
    "Model Macpherson",
    "Model Macpherson",
    "She, in Vichy",
    "Fashion magazine",
    "Allure competitor",
    "Marie Claire competitor",
    "Fashion magazine",
    "Vogue competitor",
    "Marie Claire competitor",
    "Vogue rival",
    "Vogue rival",
    "Vogue competitor",
    "Fashion monthly",
    "Fashion mag",
    "Big fashion magazine",
    "Magazine with a 14-Across on its cover",
    "Magazine a la mode",
    "Popular fashion magazine",
    "George Sand's \"___ et lui\"",
    "Lui's partner",
    "Woman of Paris",
    "Glamour competitor",
    "Lui's partner",
    "Model Macpherson",
    "Vogue competitor",
    "74-Down's opposite",
    "Female in France",
    "Supermodel Macpherson",
    "Stylish magazine",
    "Vogue rival",
    "George Sand's \"___ et lui\"",
    "Popular fashion magazine",
    "Word for a madame",
    "First name in supermodeldom",
    "Model Macpherson",
    "Colleague of Claudia and Naomi",
    "Glamour rival",
    "Vogue rival",
    "She, to Chanel",
    "Model Macpherson",
    "Vogue competitor",
    "Popular fashion magazine",
    "Model Macpherson",
    "Glamour competitor",
    "Palindromically-named magazine",
    "Vogue rival",
    "Mademoiselle",
    "Glamour rival",
    "Model Macpherson",
    "Vogue competitor",
    "Lui's counterpart",
    "Allure competitor",
    "Model Macpherson",
    "Vogue rival",
    "Glamour competitor",
    "International fashion magazine",
    "Pronoun for a Parisienne",
    "Fashion magazine",
    "Women's fashion magazine",
    "Model Macpherson",
    "Model-filled magazine",
    "Glamour rival",
    "Popular fashion magazine",
    "Model Macpherson",
    "Glamour rival",
    "Vogue competitor",
    "Madame's pronoun",
    "Periodical of haute couture",
    "Vogue rival"
  ],
  "acme": [
    "Fictional brand of rocket-powered roller skates",
    "Top",
    "Peak",
    "Highest point",
    "Very top",
    "Pinnacle",
    "Top",
    "Fictional maker of earthquake pills and tornado seeds",
    "Fictional maker of Dehydrated Boulders and Tornado Seeds (\"just add water\")",
    "Ne plus ultra",
    "Maker of Instant Feathers and Hi-Speed Tonic",
    "Ne plus ultra",
    "Crown",
    "Crowning point",
    "High point",
    "Zenith",
    "It's all downhill from here",
    "Top",
    "Apex",
    "Longtime cartoon business",
    "Fictional company behind earthquake pills and dehydrated boulders",
    "Top",
    "Pinnacle",
    "Zenith",
    "Pinnacle",
    "Maker of outlandish products in Road Runner cartoons",
    "Peak",
    "Top",
    "Zenith",
    "Crowning point",
    "High point",
    "It's all downhill from here",
    "Tiptop",
    "Tiptop",
    "Ultimate point",
    "As high as you can go",
    "Pinnacle",
    "Zenith",
    "Top",
    "Height",
    "Apotheosis",
    "Fictional corporation that made a jet-propelled unicycle",
    "Ne plus ultra",
    "Ultimate",
    "Cartoon supplier",
    "Climax",
    "Warner Bros. cartoon company",
    "Culmination",
    "Peak",
    "Top",
    "Zenith",
    "Brand in Road Runner cartoons",
    "Ne plus ultra",
    "This above all?",
    "It's all downhill from here",
    "Climber's goal",
    "Top",
    "Climax",
    "Pinnacle",
    "Top",
    "Lofty place",
    "High point",
    "Pinnacle",
    "Zenith",
    "Ne plus ultra",
    "Wile E. Coyote's go-to company",
    "Top point",
    "Zenith",
    "Pinnacle",
    "Pinnacle",
    "Looney Tunes manufacturer",
    "Tip-top",
    "Be-all and end-all",
    "#1 position",
    "Greatest flowering",
    "Summit",
    "Crowning point",
    "Pinnacle",
    "Wile E. Coyote's supplier",
    "Meridian",
    "Road Runner cartoon company",
    "Pinnacle",
    "Summit",
    "Tiptop",
    "Crown",
    "Peak",
    "Height",
    "Crown",
    "Pinnacle",
    "Ne plus ultra",
    "Climber's goal",
    "Height",
    "Peak",
    "Pinnacle",
    "Wile E. Coyote's supplier",
    "High point",
    "Ne plus ultra",
    "Top",
    "It's the top",
    "Peak",
    "Cartoon company",
    "Top of the heap",
    "Very top",
    "Pinnacle",
    "Peak",
    "Top spot",
    "Top spot",
    "Ne plus ultra",
    "Pinnacle",
    "Top spot",
    "Ne plus ultra",
    "Peak",
    "Pinnacle",
    "Climax",
    "Top spot",
    "Pinnacle",
    "Meridian",
    "Peak",
    "Peak",
    "#1 position",
    "Culmination",
    "Height",
    "Top",
    "Peak",
    "Pinnacle",
    "Zenith",
    "Crowning point",
    "#1 spot",
    "Top",
    "#1 spot",
    "Capstone",
    "High point",
    "High point",
    "#1 spot",
    "Very visible point",
    "Ne plus ultra",
    "Meridian",
    "Highest point",
    "Zenith",
    "Pinnacle",
    "Tiptop",
    "Very top",
    "Ne plus ultra",
    "Crowning point",
    "Ne plus ultra",
    "Summit",
    "Cap",
    "Ne plus ultra",
    "Tip",
    "Tiptop",
    "Crowning point",
    "Ne plus ultra",
    "Zenith",
    "Ne plus ultra",
    "Crown",
    "Mountaineer's goal",
    "Crowning point",
    "Higest point",
    "Highest stage",
    "Wile E. Coyote's supplier",
    "Crown",
    "Apex",
    "Highest point",
    "Climber's goal",
    "Zenith",
    "Crowning point",
    "Ne plus ultra",
    "High point",
    "Wile E. Coyote's supply company",
    "Ne plus ultra",
    "Highest point"
  ],
  "edda": [
    "Ancient work that describes the sacred tree Yggdrasil",
    "One literary source for Wagner's \"Ring\" cycle",
    "Icelandic work that influenced Tolkien",
    "Classic Icelandic saga",
    "Icelandic saga",
    "Classical Icelandic literary work",
    "Icelandic saga",
    "13th-century literary work",
    "Ancient Norse work",
    "Icelandic literary work",
    "Work that includes the Skaldskaparmal",
    "Source of medieval Norse poetry",
    "Ancient Icelandic literary work",
    "Old Icelandic saga",
    "Ancient Norse work",
    "Icelandic saga",
    "Ancient Norse work",
    "Icelandic saga",
    "Icelandic literary saga",
    "Classic work in Old Norse",
    "Norse source for Loki lore",
    "Collection of Norse tales",
    "13th-century composition",
    "Icelandic epic",
    "Old Norse work",
    "Poetic work with an account of Ragnarok",
    "13th-century literary classic",
    "Old Norse work",
    "Ancient Icelandic work",
    "Old Norse work",
    "Icelandic volume",
    "Snorri Sturluson work",
    "Work of prose or poetry",
    "Norse epic",
    "Icelandic epic",
    "Old mythological work",
    "Old Icelandic literary work",
    "Mythology anthology",
    "Source for Wagner's \"The Ring of the Nibelung\"",
    "Work of mythology",
    "Icelandic epic",
    "13th-century literary work",
    "Ancient literary work",
    "Snorri Sturluson story",
    "Mythology anthology",
    "Early Icelandic literature",
    "Icelandic epic",
    "Icelandic literary work",
    "Icelandic poetry collection",
    "13th-century collection",
    "Old literary collection",
    "Old Icelandic literary work",
    "Collection in Old Icelandic",
    "Icelandic literary classic",
    "Audrey Hepburn's real first name",
    "Classical collection",
    "800-year-old work on mythology",
    "Classic literary work",
    "Collection of legends",
    "Classic Icelandic poetry",
    "Snorri Sturluson work",
    "Norse poetry collection",
    "Old Norse collection",
    "Icelandic work",
    "Audrey Hepburn's real first name",
    "13th-century literary work",
    "Early work of mythology"
  ],
  "alou": [
    "Baseball family name much seen in crosswords",
    "Felipe, Matty or Jesus of baseball fame",
    "Former Expos and Giants manager Felipe",
    "Diamond family name",
    "Felipe or Moises of major-league baseball",
    "Felipe or Moises of baseball",
    "Jesus on the diamond",
    "Last name of a trio of baseball brothers",
    "Baseball family name",
    "Baseball's Matty, Felipe or Moises",
    "Matty who once had a National League batting title",
    "Felipe ___, first Dominican manager in M.L.B. history",
    "Jesus, for one",
    "Surname of three baseball brothers",
    "Jesus on the diamond",
    "Baseball's Felipe, Matty or Jesus",
    "Matty or Felipe of major-league baseball",
    "Baseball family name",
    "Diamond family name",
    "Surname of three Giants outfielders in 1963",
    "Name of a family with a combined 7,228 hits",
    "Baseball's Felipe",
    "Jesus, for one",
    "Surname of the only M.L.B. brother trio to play together in the outfield",
    "Baseball's Felipe",
    "Baseball's Moises or Jesus",
    "Jesus on a diamond",
    "Jesus in the outfield",
    "Baseball's Felipe",
    "Baseball family name",
    "Baseball family name",
    "Giant Jesus",
    "Baseball family name",
    "Major League Baseball family name",
    "Baseball's Matty or Jesus",
    "Name of three Giants outfielders in 1963",
    "Baseball's Felipe",
    "Dominican baseball family name",
    "Baseball's Moises",
    "Baseball family name",
    "Jesus, for one",
    "Baseball brother",
    "Big name in the diamond business?",
    "Baseball family surname",
    "Noted diamond family name",
    "Jesus, for one",
    "Jesus of the 1960s Giants",
    "Baseball's Moises",
    "Baseball family name",
    "Diamond family name",
    "Baseball family name",
    "Jesus, for one",
    "Baseball's Felipe or Jesus",
    "Baseball's Moises",
    "Baseball's Moises or Felipe",
    "Diamond family name",
    "Felipe or Moises of baseball",
    "Slugger Moises",
    "Baseball's Moises",
    "Baseball's Felipe or Moises",
    "Baseball's Matty or Felipe",
    "Baseball's Felipe or Jesus",
    "Baseball's Matty or Felipe",
    "Baseball family name",
    "Baseball's Moises",
    "Baseball family name",
    "Baseball family name",
    "Diamond family name",
    "Multi-generational diamond name",
    "Baseball star born in Santo Domingo",
    "Outfielder Moises",
    "Outfielder Moises",
    "Baseball's Felipe",
    "Brothers' name in 1960's-70's baseball",
    "Baseball's Matty",
    "First Dominican major-league manager",
    "Baseball's Felipe",
    "Baseball's Felipe",
    "Baseball family name",
    "Baseball family name",
    "Baseball brothers' name",
    "Former Expos manager",
    "Jesus on a diamond",
    "Baseball's Moises or Felipe",
    "Baseball family name",
    "Baseball's Matty or Moises",
    "Baseball's Moises",
    "Baseball's Jesus",
    "Moises of baseball",
    "Baseball's Moises",
    "Former Expos manager Felipe",
    "Baseball's Moises",
    "Baseball family name",
    "Jesus on the field",
    "Jesus, for one",
    "Baseball family name",
    "Diamond family name",
    "Baseball family name",
    "Baseball manager Felipe",
    "Baseball family name",
    "Baseball brothers' name",
    "Ballplayer Moises",
    "N.L. batting champ of 1966",
    "Slugger Moises",
    "Big baseball surname",
    "Baseball family name",
    "Baseballer Matty or Felipe",
    "1966 N.L. batting champ",
    "Baseball family name",
    "Diamond family name",
    "1968-69 Pirates All-Star",
    "Fielding brother",
    "Jesus, for one",
    "Baseball's Matty or Moises",
    "Baseball's Moises",
    "Big surname in baseball",
    "Major League brothers' name",
    "Baseball's Moises",
    "60's All-Star Felipe",
    "Baseball brothers' name",
    "Major league brothers' name",
    "Santo Domingo-born All-Star",
    "Baseball's Jesus",
    "Expos V.I.P.",
    "Matty or Moises",
    "Noted Expos name",
    "Moises, for one",
    "Felipe of the Expos",
    "Moises or Felipe",
    "Baseball's Jesus",
    "Baseball's Matty or Moises",
    "Moises of baseball",
    "Felipe of the Expos",
    "Baseball's Felipe",
    "Baseball's ___ brothers",
    "Felipe, Jesus or Matty",
    "Montreal Expos manager",
    "Baseball's Moises",
    "Jesus, for one"
  ],
  "ends": [
    "Trimmed parts of green beans",
    "\"This ___ here!\"",
    "Codas",
    "Things that justify the means, some say",
    "On a magnet they're called poles",
    "Wraps up",
    "Quashes",
    "Extremities",
    "Crispy brisket bits",
    "Things trimmed at a salon",
    "Elusive parts of rainbows",
    "Some rushers, in football",
    "Gets discontinued",
    "Finales",
    "Means justifiers, perhaps",
    "Odds' counterpart",
    "Finishes",
    "Quashes",
    "Burnt barbecue bits",
    "A and Z, in the alphabet",
    "Discontinues",
    "Quashes",
    "Goals",
    "They may be bitter or defensive",
    "Extremities",
    "Butts",
    "Partner of odds",
    "Stops",
    "___ of the earth",
    "Quashes",
    "Kills",
    "Terminates",
    "Quashes",
    "What a Mobius strip lacks",
    "Puts the kibosh on",
    "Wraps up",
    "Bounds",
    "Wraps up",
    "Two slices of a loaf",
    "Closes",
    "Objectives",
    "Terminals",
    "Wraps up",
    "Draws to a close",
    "Breaks off",
    "Pulls the plug on",
    "Wraps up",
    "Termini",
    "Puts the kibosh on",
    "Termini",
    "Leftovers",
    "Remnants",
    "Modern synonym for 5-Down",
    "Some football linemen",
    "Many rushers",
    "Two slices of a loaf of bread",
    "Finishes",
    "Some blockers",
    "___ of the earth",
    "Bounds",
    "Calls off",
    "Cuts off",
    "Finales",
    "Make ___ meet",
    "___ of the earth",
    "Brings to a close",
    "Some touchdown scorers",
    "Finishes",
    "Finishes",
    "Some linemen",
    "Doesn't carry on",
    "Winds up",
    "___ of the earth",
    "Conclusions",
    "Finales",
    "Concludes",
    "Machiavellian concerns",
    "Termini",
    "Finales",
    "Tips",
    "Terminates",
    "Means justifier, for some",
    "Extrema, e.g.",
    "Wraps up",
    "Gridiron positions",
    "Terminates",
    "Tips",
    "Extremities",
    "Termini",
    "Finishes",
    "Closes out",
    "Two on a line",
    "Things rings lack",
    "Discontinues",
    "Some blockers",
    "Some linemen",
    "Objectives",
    "Partner of 56-Down",
    "Discontinues",
    "Goals",
    "Goals",
    "Some receivers",
    "Reaches 0:00:00 on a countdown clock, say",
    "Remnants",
    "What circles lack",
    "To the ___ of the earth",
    "Remnants",
    "Some receivers",
    "What circles lack",
    "Extremities",
    "Goals",
    "Line pair",
    "Extremities",
    "Wraps up",
    "Termini",
    "Outer limits",
    "___ of the earth",
    "Far left and far right",
    "Pulls the plug on",
    "Conclusions",
    "Most-cooked parts of roasts",
    "Breaks off",
    "Conclusions",
    "Goals",
    "What a ring doesn't have",
    "Termini",
    "Extremities",
    "Purposes",
    "Some try to make them meet",
    "Cuts off",
    "Extremes",
    "There are two in a loaf",
    "___ of the earth",
    "Some linemen",
    "Some sausage slices",
    "Two slices of a loaf",
    "Conclusions",
    "Means justifiers, some say",
    "Rings lack them",
    "They may be split",
    "Peters out",
    "They may be tight or loose",
    "Pulls the plug on",
    "Loaf parts",
    "Concludes",
    "Front and back",
    "Cuts off",
    "Goals",
    "Football positions",
    "Stopping points",
    "It's sometimes hard to make them meet",
    "Periods",
    "Terminals",
    "Goals",
    "\"Tight\" ones",
    "Breaks off",
    "Leaves off",
    "Expires",
    "Parts cut off",
    "Final stages",
    "They may be split or tight",
    "Puts a stop to",
    "Extremities",
    "Aims",
    "Certain linemen",
    "Partner of odds",
    "Some receivers",
    "\"Loose\" things",
    "Pass catchers",
    "At loose ___",
    "Comes to a halt",
    "Loose ___",
    "See 64-Across",
    "Goes no further",
    "Means' justifier",
    "Epilogs",
    "Winds up",
    "Completes",
    "Purposes",
    "Breaks off",
    "Some scrimmagers",
    "Gridiron group",
    "Means justifiers",
    "Winds up",
    "Conclusions",
    "Finales",
    "Wraps up",
    "Some receivers",
    "Intentions",
    "Runs out",
    "They may come with odds",
    "Wraps up",
    "Quits",
    "Pulls the plug on",
    "They can be tight",
    "Pulls the plug on",
    "Pass receivers",
    "Extremities",
    "Pulls the plug on",
    "Targets of men who make passes",
    "Concludes",
    "Doesn't go on",
    "Parts to tie",
    "Means justifiers",
    "They may be split",
    "Winds up",
    "Bounds",
    "Tips",
    "Parts to tie",
    "Outcomes",
    "Goals",
    "Volumes A and Z in an encyclopedia",
    "Quashes",
    "Wraps up",
    "They may be loose or split",
    "Loaf pair",
    "Goals",
    "Wraps up",
    "Aims",
    "Brings to a close",
    "Machiavellian concerns",
    "Cricket wickets",
    "Loose parts",
    "Extremities",
    "Pass receivers",
    "Goals",
    "Wraps up",
    "Extremities",
    "Some footballers",
    "Loose items, sometimes",
    "Goals",
    "Means justifier",
    "Termini",
    "Purposes",
    "Termini",
    "Goals",
    "Results",
    "Pass receivers",
    "Some eligible receivers",
    "Means justifier",
    "Boundaries",
    "Tackles' neighbors",
    "Settles",
    "Pass receivers",
    "What a ring lacks",
    "They're sometimes loose",
    "Tackles' neighbors",
    "Remnants",
    "QB targets",
    "Linemen",
    "Upshots",
    "Concludes",
    "Split___",
    "Wraps up",
    "Tight positions?"
  ],
  "unto": [
    "Golden rule word",
    "\"Do ___ others ...\"",
    "\"For ___ whomsoever much is given ...\": Luke 12:48",
    "\"___ us a son is given\"",
    "Biblical preposition",
    "Quaint preposition",
    "\"For ___ us a child is born ...\"",
    "Golden rule word",
    "\"Woe ___ them ...\"",
    "\"Do ___ others as ...\"",
    "\"Do ___ others ...\"",
    "Biblical preposition",
    "\"Render ___ Caesar ...\"",
    "\"Do ___ others ...\"",
    "\"Do ___ others ...\"",
    "\"___ a broken heart\": Dickinson",
    "Frequent follower of \"said\" in the Bible",
    "\"Woe ___ them that call evil good\": Isaiah",
    "\"For ___ us a child is born\"",
    "Golden rule preposition",
    "Word that may precede itself",
    "Golden rule word",
    "\"Come ___ me, all ye that labor ...\"",
    "Quaint preposition",
    "What may precede itself",
    "\"Make a joyful noise ___ the Lord\"",
    "\"Adventure most ___ itself\": Emily Dickinson",
    "Golden rule preposition",
    "\"Do ___ others as ...\"",
    "Golden rule word",
    "\"Render ___ Caesar ...\"",
    "\"Do ___ others as ...\"",
    "Golden rule preposition",
    "\"Do ___ others as ...\"",
    "Quaint preposition",
    "It may precede itself",
    "\"Do ___ others ...\"",
    "\"For ___ us a child is born ...\"",
    "\"For ___ us a child ...\"",
    "Golden rule word",
    "\"The soul ___ itself\": Dickinson",
    "A law ___ itself",
    "Biblical preposition",
    "Billy Graham's \"___ the Hills\"",
    "\"Come ___ these yellow sands\": \"The Tempest\"",
    "\"Do ___ others ...\"",
    "\"Come ___ these yellow sands, / And then take hands\": Ariel in \"The Tempest\"",
    "\"Render ___ Caesar ...\"",
    "Golden rule preposition",
    "\"Render ___ Caesar ...\"",
    "\"Do ___ others as ...\"",
    "\"Do ___ others ...\"",
    "\"___ the Sons\" (Gay Talese best seller)",
    "A law ___ itself",
    "Billy Graham's \"___ the Hills\"",
    "\"___ This Last\" (series of John Ruskin essays)",
    "Gay Talese's \"___ the Sons\"",
    "A law ___ itself",
    "\"'___ Me?' I do not know you\" (Emily Dickinson poem)",
    "\"Render therefore ___ Caesar ...\"",
    "Kierkegaard's \"The Sickness ___ Death\"",
    "\"Do ___ others ...\"",
    "Sermon preposition",
    "Biblical preposition",
    "Biblical preposition",
    "Render ___ (give, old-style)",
    "Biblical preposition",
    "\"For ___ us a child is born ...\"",
    "Golden rule preposition",
    "Golden rule preposition",
    "\"___ Me? I do not know you\": Emily Dickinson",
    "\"Do ___ others ?à\"",
    "\"Woe ___ ...\"",
    "Golden rule word",
    "\"Render therefore ___ Caesar ...\"",
    "\"___ These Hills\" (Cherokee Indian drama)",
    "Golden rule word",
    "Golden rule word",
    "Biblical preposition",
    "Golden rule word",
    "\"Do ___ others ...\"",
    "Golden rule word",
    "\"Render ___ Caesar ...\"",
    "Golden rule word",
    "Old-fashioned preposition",
    "Word that may precede itself",
    "\"I will sing ___ the Lord\": Exodus",
    "A law ___ itself",
    "Golden rule preposition",
    "\"Do ___ others...\"",
    "Golden rule word",
    "Gay Talese's \"___ the Sons\"",
    "Biblical preposition",
    "Golden rule preposition",
    "\"Render therefore ___ Caesar...\"",
    "\"Do ___ others...\"",
    "\"Do ___ others as...\"",
    "\"...___ dust shalt thou return\"",
    "Golden rule word",
    "\"For ___ us a child is born...\"",
    "Gay Talese's \"___ the Sons\"",
    "\"Do ___ others as...\"",
    "Golden rule word",
    "Biblical preposition",
    "Biblical preposition",
    "\"For ___ us a child...\"",
    "\"___ us a son is given\"",
    "\"Do ___ others...\"",
    "Next-to-last word of the golden rule",
    "Biblical preposition",
    "\"For ___ us a child is born\"",
    "\"___the calmly gathered thought\": Whittier",
    "\"Do___others...\"",
    "\"Do ___ others...\"",
    "\"For ___ us a child...\"",
    "\"Do ___ others...\"",
    "\"...lamp ___ my feet\"",
    "\"Do ___ others...\"",
    "\"Do ___ others...\"",
    "Golden rule word",
    "\"___dust shall thou return\"",
    "\"Do ___ others as...\"",
    "Golden-rule word",
    "\"Render therefore ___ Caesar...\"",
    "\"For___us a child is born\"",
    "Golden rule word"
  ],
  "oned": [
    "Breakout band for Harry Styles and Zayn Malik, familiarly",
    "Group with the 2012 chart-topping album \"Up All Night,\" to fans",
    "Linear",
    "Lacking width and depth",
    "Linear, for short",
    "Linear, for short",
    "Linear, in brief",
    "Linear, informally",
    "Like the x-, y- or z-axis",
    "Flat",
    "Linear, in brief",
    "Going along the line, briefly?",
    "Like the x- or y-axis",
    "Like lines, briefly",
    "Linear, for short",
    "Linear, briefly",
    "Linear, for short",
    "Linear",
    "Linear, for short",
    "Linear",
    "Lacking width and depth, for short",
    "Like a line, in brief",
    "Superficial, briefly",
    "Linear",
    "Linear",
    "Flat, for short",
    "Like a line, briefly",
    "Linear, briefly",
    "Having no depth, in brief",
    "Like a line, briefly",
    "Like a line segment, briefly",
    "Linear",
    "Linear",
    "Like a line, briefly",
    "Linear, for short",
    "Like a line, in math",
    "Flat",
    "Like a line, briefly",
    "Lacking width and depth",
    "Like a line, briefly",
    "Lacking width and depth",
    "Like a line",
    "Linear",
    "Linear",
    "Having no width or depth",
    "Lacking height and depth"
  ],
  "mort": [
    "Mad magazine cartoonist Drucker",
    "Cuddly-looking lemur in the \"Madagascar\" movies",
    "Media big Zuckerman",
    "Mad magazine cartoonist Drucker",
    "Comedian Sahl ... also what can fill the square at the crossing of 1-Across and 1-Down",
    "Comic Sahl",
    "Cartoonist Walker",
    "Voltaire's \"La ___ de Cesar\"",
    "Death to le roi",
    "Cartoonist Walker",
    "\"Beetle Bailey\" creator Walker",
    "Cartoonist Walker",
    "Satiric Sahl",
    "Mad magazine cartoonist Drucker",
    "Cartoonist Walker",
    "Humorist Sahl",
    "\"Beetle Bailey\" creator Walker",
    "Comic Sahl",
    "Cartoonist Walker",
    "Mad magazine cartoonist Drucker",
    "Cartoonist Walker",
    "Comic Sahl",
    "Death: Fr.",
    "Satirist Sahl"
  ],
  "time": [
    "Denominator in the velocity formula",
    "\"Put your pencils down!\"",
    "It's irreversible",
    "\"Lost ___ is never found again\": Benjamin Franklin",
    "Proctor's call",
    "With 1-Down, company named for two magazines",
    "\"Nature's great healer,\" per Seneca",
    "\"Pencils down!\"",
    "Proctor's declaration",
    "What clocks keep",
    "Something you might need to kill",
    "What inmates do until they're released",
    "Show of hands?",
    "\"Stop! Turn your papers over\"",
    "Marathoner's focus",
    "It can crawl or fly, but not walk",
    "\"Pencils down!\"",
    "Magazine with a \"Person of the Year\"",
    "\"What we want most, but what, alas! we use worst,\" per William Penn",
    "Use a stopwatch on",
    "You may find it on your hands or side",
    "Cons do it",
    "See 32-Across",
    "\"Pencils down!\"",
    "See 27-Across",
    "Magazine whose cover has a red border",
    "___ 100 (annual list)",
    "*Cons do it",
    "It's often shown with hands",
    "A metronome keeps it",
    "What cons do",
    "It can be bought without money",
    "Use a stop clock on",
    "\"The great instructor,\" per Edmund Burke",
    "Proctor's call",
    "Proctor's call",
    "The so-called fourth dimension",
    "It may be on your side",
    "Player's call",
    "Proctor's call",
    "Daylight saving, e.g.",
    "Red-bordered magazine",
    "It heals all wounds, in a saying",
    "Cry after the sound of a bell",
    "Game-stopping call",
    "Proctor's call",
    "What prisoners do",
    "Proctor's call",
    "\"The discoverer of all things\": Cervantes",
    "\"___ shall unfold what plaited cunning hides\": Shak.",
    "Call to a basketball referee",
    "Proctor's call",
    "Proctor's call",
    "Proctor's announcement",
    "Test proctor's call",
    "See 62-Down",
    "Game-stopping call",
    "Ump's call",
    "Prisoners do it",
    "Use a stopwatch",
    "Test proctor's declaration",
    "Newsweek rival",
    "Marathoner's stat",
    "Ref's call",
    "Word with make or mark",
    "Partner of Warner",
    "Clock",
    "Fourth dimension",
    "It marches on",
    "Luce magazine",
    "Test giver's call",
    "What the hands may show",
    "Proctor's cry at the end of a test"
  ],
  "ashy": [
    "Flue-like",
    "Like most fireplace grates",
    "Gray in the face",
    "Like barbecue pits",
    "Like a fireplace the morning after, say",
    "Pale",
    "Cinder-covered",
    "Wan",
    "Visibly aghast",
    "Pallid",
    "Like a hearth",
    "Wan",
    "Pale-faced",
    "Like an unswept hearth",
    "Pale as a ghost",
    "Like a barbecue pit",
    "Pale-faced",
    "Like spent charcoal",
    "Pale",
    "Like a post-volcanic landscape",
    "Wan",
    "Like a spent briquette",
    "Pale",
    "Depleted of color",
    "Like some evidence in arson cases",
    "Pale",
    "Like the area around an erupting volcano",
    "Like volcanic fallout",
    "Like fireplace grates",
    "Like a used barbecue pit",
    "Pallid",
    "Visibly horrified",
    "Wan",
    "Pale as a ghost",
    "Whitish",
    "Cadaverous",
    "Grayish",
    "Wan",
    "Like Pompeii, once",
    "Pallid",
    "Like arson evidence",
    "Pasty-faced",
    "Like a spent campfire",
    "Looking frightened",
    "Pasty-faced",
    "Far from flushed",
    "Pale as a ghost",
    "Like many hearths",
    "Wan",
    "Pale",
    "Like fireplaces",
    "Gray, in a way",
    "Gray",
    "Unhealthy-looking",
    "Whitish",
    "Like some fireplaces",
    "Not healthy-looking",
    "Wan",
    "Grayish",
    "Grayish",
    "Far from ruddy",
    "Whitish",
    "Pale",
    "White",
    "Far from ruddy",
    "Paler than pale",
    "Pallid",
    "Grayish",
    "Needing a tan",
    "Wan",
    "Like certain trays",
    "Wan",
    "Pallid",
    "Pale",
    "Sickly, as a complexion",
    "Wan"
  ],
  "dojo": [
    "Place to develop one's chops",
    "Training place for martial arts",
    "Martial arts center",
    "Place to get one's kicks?",
    "Setting for \"The Karate Kid\"",
    "Martial arts school",
    "Locale for a 39-Across",
    "Karate studio",
    "Good place to kick back?",
    "Karate school",
    "Chopping center?",
    "Martial arts school",
    "Self-defense school",
    "Martial arts place",
    "School of martial arts",
    "School for martial arts",
    "Karate school",
    "Karate school",
    "Martial arts school"
  ],
  "leap": [
    "Accept eagerly, with \"at\"",
    "Vault",
    "Bit of faulty logic",
    "Long jump",
    "___ year (2020, for example)",
    "Vault",
    "Ballerina's cabriole, e.g.",
    "Vault",
    "Jete, e.g.",
    "___ years (when presidents are elected)",
    "Jump",
    "Spring",
    "Get off the ground?",
    "___ of faith",
    "Capriole",
    "Spring",
    "Bound",
    "High jump",
    "Big step",
    "Grand jete, e.g.",
    "Abrupt transition",
    "Big jump",
    "One may be taken in faith",
    "Bound",
    "Flaw in logic",
    "Break in logic",
    "Spring",
    "Jump",
    "Take the plunge",
    "Spring",
    "Jump",
    "___ year (period of 366 days)",
    "Vault (over)",
    "___ second",
    "Act precipitately",
    "Vault",
    "Neil Armstrong made a giant one for mankind",
    "Jump",
    "Vault",
    "Vault",
    "Bound",
    "Jump",
    "Ballet move",
    "Proceed impulsively",
    "___ of faith",
    "Spring",
    "Bound",
    "Vault",
    "Act of faith?",
    "___ year (2004, e.g.)",
    "Ballet move",
    "Axel, e.g.",
    "Jete, e.g.",
    "Spring",
    "Long spring",
    "Ballet practice",
    "Bound",
    "Sudden increase",
    "Bound",
    "Kind of second",
    "Hop, skip or jump",
    "Sudden transition",
    "Spring",
    "Flying ___",
    "Spring",
    "Spring",
    "Abrupt transition",
    "Cause of a non sequitur",
    "Spring",
    "Quantum ___",
    "Capriole",
    "Act of faith",
    "\"Able to ___ tall buildings...\"",
    "Giant hop",
    "Hurdle",
    "Spring",
    "Jump",
    "Intuitive step",
    "Spring",
    "Jump",
    "Spring",
    "Spring",
    "Lover's ___"
  ],
  "itch": [
    "Restless desire",
    "Hankering",
    "Medical symptom, maybe",
    "Restless desire",
    "Target of a scratch",
    "Hankering",
    "Poison ivy reaction",
    "Septennial symptom?",
    "What pruritus means, to laypeople",
    "Target for nails?",
    "Calamine target",
    "Poison ivy symptom",
    "What an oatmeal bath alleviates",
    "Hankering",
    "Scratch target",
    "Yearning",
    "Persistent desire",
    "Something calamine lotion alleviates",
    "Yen",
    "Mosquito bite annoyance",
    "Poison ivy annoyance",
    "Hankering",
    "Astronaut's woe, perhaps",
    "Desire",
    "It may come with a bite",
    "Yen",
    "Seventh anniversary ruiner?",
    "Rash feeling?",
    "It may be hard to reach",
    "Hankering",
    "Intense desire",
    "Result of a mosquito bite",
    "Yearn (for)",
    "\"The Seven Year ___\"",
    "Allergic reaction",
    "Hankering",
    "Desire",
    "Something to scratch",
    "Trigger finger problem?",
    "Nagging desire",
    "Scratcher's target",
    "Poison ivy symptom",
    "Prickly heat symptom",
    "Poison ivy symptom",
    "Desire",
    "Wanderlust, e.g.",
    "Rash reaction?",
    "Poison ivy woe",
    "Zinc oxide may treat it",
    "Result of an insect bite",
    "Target for nails",
    "Measles symptom",
    "Hankering",
    "Nagging desire",
    "Hankering",
    "Rash problem",
    "Cast wearer?Æs problem",
    "Athlete's foot symptom",
    "Cast wearer?Æs problem",
    "Hankering",
    "Target for nails?",
    "Dermatological concern",
    "Restlessness",
    "Result of a flea, maybe",
    "Yen",
    "Allergy consequence",
    "Allergic reaction",
    "Septennial problem?",
    "Result of a bite, maybe",
    "What a wool shirt can do",
    "Problem with hives",
    "Hankering",
    "Urge",
    "Bite result, say",
    "Longing",
    "Dermatologist's concern",
    "Yen",
    "Rash reaction?",
    "Hunger",
    "Cast wearer's frustration",
    "Restless desire",
    "Desire",
    "It's a sensation",
    "It might bring you up to scratch",
    "Longing",
    "Longing",
    "Nails' target",
    "Restlessness",
    "Smallpox symptom",
    "Compulsive desire",
    "Scratch it!",
    "Problem for a suited-up diver?",
    "Hankering",
    "Seven-year phenomenon",
    "Problem for a person in handcuffs?",
    "Chicken pox symptom",
    "Yen",
    "Yen",
    "Yen",
    "Result of a bite, maybe",
    "Hankering",
    "Longing",
    "Yen",
    "Yen"
  ],
  "pack": [
    "Get ready for vacation",
    "Risky thing to do close to flight time",
    "Get ready, in a way",
    "Bunch of wolves",
    "Cigarette purchase",
    "Bring along",
    "Stuff",
    "Cigarette purchase",
    "Load",
    "Cram",
    "Get ready to go",
    "Have heat",
    "Lies together?",
    "Smoker's purchase",
    "Smoker's purchase",
    "Excise tax unit",
    "Stuff"
  ],
  "loan": [
    "It may be forgiven",
    "Money to tide one over",
    "Money you have to pay back",
    "Money to tide one over",
    "Student ___",
    "College debt component",
    "Money to tide you over",
    "Student ___",
    "Advance",
    "Need for a cash-strapped car buyer",
    "Dangerous kind of shark",
    "Aid for a start-up business",
    "Mortgage, e.g.",
    "Advance",
    "Something to float",
    "Money that needs to be repaid",
    "___ shark",
    "Advance",
    "___ shark",
    "Money to tide one over",
    "\"Kitsch\" or \"kindergarten,\" from German",
    "Advance",
    "It has points of interest",
    "Student's burden",
    "Student aid",
    "Item of interest?",
    "Redistribution of wealth?",
    "Temporary gift",
    "The \"L\" in S.&L.",
    "There may be great interest in this",
    "Shylock's offering",
    "___ shark",
    "Advance",
    "Money to help one through a tight spot",
    "The \"L\" in S.&L.",
    "Advance",
    "Kind of officer",
    "Cadger's request",
    "Advance",
    "Student aid",
    "Credit union offering",
    "Kind of shark",
    "Advance",
    "Part of F.S.L.I.C.",
    "Help for some students",
    "Student aid",
    "Museum deal",
    "Spot",
    "Gift alternative",
    "It can generate a lot of interest",
    "Bank transaction",
    "Student's need, often",
    "Usurer's offering",
    "Help during hard times",
    "It's often on the house",
    "Auto dealer's deal",
    "Advance",
    "Item of interest",
    "Temporary use",
    "Advance",
    "Money to tide one over",
    "Bank job?",
    "Money to buy a car, maybe",
    "Advance",
    "Source of interest",
    "Money to tide one over",
    "Savings' partner",
    "Bank offering",
    "Kind of office",
    "It could be a credit to anyone",
    "It often comes with points",
    "Kind of shark",
    "It may come with points",
    "It may generate interest"
  ],
  "tino": [
    "4x World Series winner Martinez",
    "Former Yankee Martinez",
    "Baseball's Martinez",
    "Baseball's Martinez",
    "Nickname for Tony",
    "Two-time All-Star Martinez",
    "Ex-Yankee Martinez",
    "Ex-Yankee Martinez",
    "Ex-Yankee Martinez",
    "Martinez of the 1996-2001 Yankees",
    "All-Star Martinez",
    "Ex-Yankee Martinez",
    "Ex-Yankee All-Star Martinez",
    "Ex-Yankee Martinez",
    "One of the Flying Wallendas",
    "Two-time All-Star Martinez",
    "1997 Home Run Derby champion Martinez",
    "Former Yankee Martinez",
    "Two-time All-Star Martinez",
    "French singer/actor Rossi",
    "Martinez of the diamond",
    "Ex-Yankee Martinez",
    "Former Yankee Martinez",
    "Baseball's Martinez",
    "Baseballer Martinez",
    "First baseman Martinez",
    "Baseball's Martinez",
    "First baseman Martinez",
    "Baseballer Martinez",
    "Baseballer Martinez"
  ],
  "tubs": [
    "Ice cream containers",
    "Slow-moving ships",
    "Spots for soaks",
    "Slow-moving ships",
    "Bath fixtures",
    "They may be draining",
    "Some like them hot",
    "Big basins",
    "Wash sites",
    "Clumsy ships"
  ],
  "rant": [
    "Diatribe",
    "\"___ over\" (words after letting off steam)",
    "Tirade",
    "Vent vehemently",
    "Chew someone out, maybe",
    "Tweetstorm, e.g.",
    "Tweetstorm, e.g.",
    "Spew anger",
    "Rave's partner",
    "More than an insect, but less than a U.S. president",
    "Long blog entry, maybe",
    "Many a Twitter thread",
    "Go on (about)",
    "Go on a tirade",
    "Deliver a diatribe",
    "Tirade",
    "Talk wildly",
    "Tirade",
    "Many a one-star Yelp review",
    "Bit of blogorrhea",
    "Tirade",
    "Spew fire and brimstone, say",
    "Go on a tirade",
    "Tirade",
    "Tirade",
    "Hothead's response",
    "It might start \"Don't get me started ...\"",
    "Tirade",
    "Sound off",
    "Long broadside",
    "Tirade",
    "Air all of one's grievances, say",
    "Blow a gasket",
    "Make a fuss at a public meeting, maybe",
    "Objurgation",
    "Jeremiad",
    "Tirade",
    "Talk until you're blue in the face",
    "Blow a gasket",
    "It may come from a loud speaker",
    "Diatribe",
    "Philippic",
    "Tirade",
    "Go ballistic",
    "Go ballistic",
    "Many a blog post",
    "Go into hysterics",
    "Angry outburst",
    "Fly off the handle",
    "Angry talk",
    "Angry outburst",
    "Long, angry discourse",
    "Go ballistic",
    "Let off steam",
    "Go ballistic",
    "Let off steam, maybe",
    "Bluster",
    "Sound off",
    "Go ballistic",
    "Talk wildly",
    "Go ballistic",
    "Many a blog post",
    "Tirade",
    "Go on and on",
    "Carry on",
    "Carry on",
    "Go on and on",
    "Deliver a tirade",
    "Angry talk",
    "Long, angry complaint",
    "Deliver a tirade",
    "Earful at the complaint department",
    "Go on and on",
    "Do some tub-thumping",
    "Talk like crazy?",
    "Carry on",
    "Sound worked up",
    "Emulate Dennis Miller",
    "Go on and on",
    "Bombast",
    "Go on and on",
    "Objurgation",
    "Harangue",
    "Carry on",
    "Carry on",
    "Hyperbolize at high volume",
    "Go on and on",
    "Diatribe",
    "Deliver a tirade",
    "Sound off",
    "Throw a tantrum",
    "Excoriation",
    "Diatribe",
    "Sound off",
    "Fly off the handle",
    "Go on",
    "Harangue",
    "Wax bananas?",
    "Carry on",
    "Harangue",
    "Bombast",
    "Carry on",
    "Bombast",
    "Wild talk",
    "Go into hysterics",
    "Carry on",
    "Carry on"
  ],
  "olde": [
    "Quaint sign word",
    "Quaint shoppe descriptor",
    "Ye ___ Shoppe",
    "Of yore, of yore",
    "Ye ___ Shoppe",
    "Renaissance Faire descriptor",
    "Adjective after \"Ye\" in many a pub's name",
    "Ye ___ Shoppe",
    "Shoppe description",
    "Quaint, as a shoppe",
    "Like a shoppe, perhaps",
    "Like some shoppes",
    "McGillin's ___ Ale House (historic Philadelphia tavern)",
    "Ye ___ Shoppe",
    "Quaint store descriptor",
    "\"Ye\" follower on shoppe signs",
    "Shoppe descriptor",
    "Shoppe descriptor",
    "Ye ___ Shoppe",
    "___ English Bulldogge",
    "___ English 800 (Miller Brand)",
    "Ye ___ Shoppe",
    "Ye ___ Antique Shoppe",
    "Sign word after \"Ye\"",
    "Ye ___ Shoppe",
    "Ye ___ Shoppe",
    "Ye ___ Shoppe",
    "Ye ___ Shoppe",
    "Shoppe sign word",
    "It may follow ye",
    "Quaintly antique",
    "Ye ___ Shoppe",
    "Adjective for a shoppe",
    "Shoppe modifier",
    "Quaint sign word",
    "___ English",
    "Shoppe description",
    "Quaint, quaintly",
    "Merrie ___ England",
    "Adjective for a coach house inn, maybe",
    "Shoppe modifier",
    "___ English 800 (Miller brand)",
    "Aged, in an earlier age",
    "Vintage sign word",
    "Word on many medieval signs",
    "___-tyme",
    "Antique, antiquely",
    "Merrie ___ England",
    "Ye follower",
    "Shoppe adjective",
    "Ye follower",
    "___ Towne",
    "___ Towne",
    "Ye ___",
    "Like a shoppe",
    "Shoppe sign word",
    "Dated, formerly",
    "Word with \"ye\"",
    "Word on a shoppe sign",
    "Part of many \"shoppe\" names",
    "Ye ___ Shoppe",
    "Shoppe sign word",
    "Like a shoppe",
    "Ye ___ Curiosity Shoppe",
    "Part of many an antique shop name",
    "Ye follower",
    "Start of some inn names",
    "Like some shoppes",
    "Part of an antique shop name",
    "Ye follower",
    "Kind of shoppe",
    "\"Ye\" follower, sometimes",
    "Kind of shoppe",
    "Ye ___ Tea Shoppe",
    "From times past",
    "Like some antique shops",
    "Ye ___ Shoppe",
    "Word often after \"Ye\"",
    "Merrie___ England",
    "Like many a shoppe",
    "Word in many antique shop names",
    "Ye ___ Shoppe",
    "Ancient, in bygone days",
    "Word before shoppe",
    "Ye ___ Curiosity Shoppe",
    "Obsolete for \"obsolete\"",
    "Ye ___ Book Shoppe",
    "Ye ___ Shoppe",
    "Adjective for an antique store",
    "Ye ___ Shoppe",
    "Like Chaucer's feldes or bokes"
  ],
  "ness": [
    "Noun-making suffix",
    "\"Untouchable\" agent",
    "Prohibition Bureau name",
    "Suffix with well and good",
    "Jonathan Van ___, member of the \"Queer Eye\" cast",
    "Sad end?",
    "Playable kid in the Super Smash Bros. games",
    "Co-author of the 1957 memoir \"The Untouchables\"",
    "Suffix with good and willing",
    "Loch ___, site of Urquhart Castle",
    "Lawman Eliot",
    "Storied Prohibition agent",
    "Suffix with thick or sick",
    "\"Untouchable\" Eliot",
    "Loch ___ monster",
    "Suffix with bald or bold",
    "Loch ___ monster",
    "Bitter end?",
    "Eliot of the Untouchables",
    "Adjective-to-noun changer",
    "Loch ___",
    "Untouchable leader",
    "Loch ___ monster",
    "Noted government agent during Prohibition",
    "Eliot who chased Capone",
    "Famed loch",
    "Loch ___",
    "Loch ___ monster",
    "Capone's nemesis",
    "Noted Volstead Act enforcer",
    "It's a loch",
    "Loch ___ monster",
    "Capone's nemesis",
    "\"The Untouchables\" role",
    "Role for Costner or Stack",
    "Loch ___",
    "Crime-fighting Eliot",
    "Crime buster Eliot",
    "Great finish?",
    "Loch ___",
    "Loose end?",
    "Costner role",
    "Name associated with a mobster or a monster",
    "Untouchable leader",
    "1987 Costner role",
    "Lake of cryptozoological interest",
    "Eliot who pursued Capone",
    "Well and good finish?",
    "Storied loch",
    "Dread loch?",
    "\"The Untouchables\" character",
    "Loch ___ monster",
    "Leader of the Untouchables",
    "Eliot of \"The Untouchables\"",
    "Capone's nemesis",
    "1987 Costner role",
    "Stack role",
    "Top Untouchable",
    "Tight end?",
    "Eliot of the Untouchables",
    "1987 Costner role",
    "Eliot of the Untouchables",
    "Loch ___ monster",
    "Boss for agents Youngfellow and Rossi",
    "Sad ending?",
    "An Untouchable",
    "Capone fighter Eliot ___",
    "Crime-fighter of TV and film",
    "Costner role",
    "Good ending?",
    "Scottish river to Moray Firth",
    "Bitter end?",
    "Loch ___",
    "Famed F.B.I. figure",
    "1987 Costner role",
    "Loch ___ monster",
    "Loch ___",
    "Bitter end?",
    "Loch ___ monster",
    "Promontory",
    "Celebrated Prohibition-era lawman",
    "1987 Costner role",
    "Loch ___",
    "Crimefighter of early '60s TV",
    "Role for Stack and Costner",
    "Loch ___ monster",
    "Stack part",
    "Scottish lake",
    "Capone's nemesis",
    "See 23-Across",
    "Capone's nemesis",
    "Loch ___",
    "Capone's nemesis",
    "1987 Costner role",
    "Capone captor",
    "Loch ___",
    "Famed loch",
    "1987 role for Costner",
    "Loch ___ monster",
    "___ City, seat of a Kansas county of the same name",
    "Stack part",
    "Top Untouchable",
    "Eliot ___",
    "Capone's nemesis",
    "Capone nemesis",
    "Untouchable Eliot",
    "Costner role",
    "Bitter end?",
    "The Untouchables' leader",
    "Loch of Scotland",
    "Home for a \"monster\"",
    "Eliot of the Untouchables",
    "Noun suffix",
    "Monster's loch",
    "1987 Costner role",
    "Highlands tourist spot",
    "1987 Costner role",
    "Untouchables chief",
    "Cape",
    "So-called monster's home",
    "Loch ___",
    "Stack part",
    "Dull finish?",
    "Lead role in a 60's TV drama",
    "Capone chaser",
    "Famed loch",
    "Monster's home",
    "Costner, in \"The Untouchables\"",
    "Costner's role in \"The Untouchables\"",
    "\"Untouchable\" Eliot",
    "Famed loch",
    "Nitti's nemesis",
    "Monster's home",
    "Loch ___",
    "Onetime Chicago V.I.P.",
    "Hero of 60's TV and 80's film",
    "Mysterious loch",
    "1987 Costner role",
    "Robert Stack TV role",
    "\"The Untouchables\" protagonist",
    "\"Untouchable\" Eliot",
    "Costner role",
    "Capone foe",
    "Stack part",
    "Nitti's nemesis",
    "Early 60's TV hero",
    "Capone's nemesis",
    "1987 Costner role",
    "Eerie loch",
    "Caledonian loch",
    "Eliot of \"The Untouchables\"",
    "Monster's loch",
    "See 38-Down",
    "Agent Youngfellow's boss",
    "An Untouchable",
    "Capone's nemesis",
    "Capone foe",
    "Crime battler of 60's TV",
    "Promontory",
    "Eerie loch",
    "Stack part",
    "\"Untouchable\" Eliot",
    "Monster's home?",
    "Costner character",
    "'87 Costner film role"
  ],
  "heck": [
    "Euphemistic exclamation",
    "\"Shucks!\"",
    "\"Shoot!\"",
    "\"Darn it!\"",
    "\"Darn it!\"",
    "\"Fiddlesticks!\"",
    "\"Darn it!\"",
    "Dickens",
    "\"Dang!\"",
    "Relative of \"Darn!\"",
    "\"Shucks\"",
    "Dickens",
    "\"Gosh darn!\"",
    "\"What the ___!\"",
    "\"Darn it!\"",
    "\"Shoot!\"",
    "\"Shucks!\"",
    "\"Darn!\"",
    "Annoyed interjection"
  ],
  "foam": [
    "Latte art medium",
    "Fire extinguisher output",
    "Head makeup",
    "Bud head",
    "Fire extinguisher's output",
    "Cushion material",
    "Head",
    "Cappuccino head",
    "Soundproofing material",
    "Whitecap formation",
    "Lather",
    "Pillow filler",
    "Brew topper",
    "Shaving stuff",
    "Froth",
    "Head of a pub?",
    "Head",
    "Shaving stuff",
    "Shaver's purchase",
    "Froth",
    "Surf",
    "Head material, perhaps",
    "Froth"
  ],
  "bela": [
    "Lugosi of horror films",
    "Composer Bartók",
    "Composer Bartok",
    "___ Fleck, banjo virtuoso",
    "Composer Bartok",
    "Composer Bartok",
    "Banjoist Fleck",
    "First name in 28-Across",
    "___ Fleck and the Flecktones",
    "Lugosi of film fame",
    "Actor Lugosi",
    "Composer Bartok",
    "Actor Lugosi",
    "Fleck on the banjo",
    "Composer Bartok",
    "\"Bluebeard's Castle\" librettist Balazs",
    "Banjoist Fleck",
    "Actor Lugosi",
    "Composer Bartok",
    "Banjo master Fleck",
    "Banjoist Fleck",
    "Lugosi of horror films",
    "Composer Bartok",
    "Composer Bartok",
    "Composer Bartok",
    "Gymnastics coach Karolyi",
    "Hungarian Communist leader ___ Kun",
    "___ Fleck and the Flecktones",
    "First name in horror",
    "___ Fleck and the Flecktones",
    "Banjoist Fleck",
    "Gymnastics coach Karolyi",
    "Hungarian filmmaker Tarr",
    "Composer Bartok",
    "Film critic Balazs",
    "Olympics coach Karolyi",
    "Lugosi who played Dracula",
    "Bandleader Fleck",
    "Gymnastics coach Karolyi",
    "Jazz's ___ Fleck and the Flecktones",
    "Gymnastics coach Karolyi",
    "Gymnastics coach Karolyi",
    "Composer Bartok",
    "Actor Lugosi",
    "Gymnastics coach Karolyi",
    "Horror icon Lugosi",
    "Composer Bartok",
    "Actor Lugosi",
    "Composer Bartok",
    "1990's band ___ Fleck and the Flecktones",
    "Coach Karolyi",
    "Composer Bartok",
    "First name in horror",
    "Gymnastics coach Karolyi",
    "Composer Bartok",
    "Dracula player Lugosi",
    "Shannon's coach",
    "Composer Bartok",
    "Karolyi who coached 24-Across",
    "Hungarian revolutionary Kun",
    "Gymnastics coach Karolyi",
    "Lugosi of fright films",
    "Composer Bartok",
    "Gymnastics coach Karolyi",
    "Mr. Lugosi",
    "Composer Bartok",
    "Gymnastics coach Karolyi",
    "Gymnastics coach Karolyi",
    "Mr. Lugosi",
    "Composer Bartok",
    "Gymnastics coach Karolyi",
    "Mr. Lugosi",
    "Gymnastics coach Karolyi"
  ],
  "muss": [
    "Dishevel",
    "Tousle",
    "Dishevel",
    "Dishevel",
    "Dishevel, as the hair",
    "Disarray",
    "Dishevel",
    "Tousle",
    "\"No ___, no fuss\"",
    "Tousle, as the hair",
    "Tousle",
    "Rumple",
    "Rumple, with \"up\"",
    "Rumple, as hair",
    "Rumple",
    "Rumple",
    "Tousle",
    "Disarrange",
    "Rumple",
    "Rumple, with \"up\"",
    "Disarray",
    "Rumple",
    "Tousle",
    "Tousle",
    "Disturb, with \"up\""
  ],
  "nats": [
    "Baseball team whose mascot is Screech the eagle, familiarly",
    "2019 World Series champs",
    "M.L.B. team with a big \"W\" in its logo",
    "2019 M.L.B. champs",
    "D.C. nine",
    "D.C. nine",
    "Yanks : New York :: ___ : Washington",
    "D.C. nine",
    "D.C. club",
    "Phils' rivals",
    "Washington baseball team, for short",
    "D.C. nine",
    "D.C. team since '05",
    "D.C. team, informally",
    "D.C. diamond squad",
    "M.L.B. team with a \"W\" insignia",
    "Washington nine",
    "D.C. nine, for short",
    "N.L. East nine",
    "R.F.K. Stadium team, briefly",
    "Ex-D.C. baseballers",
    "Insurrectionist Turner and others",
    "Turner and others",
    "Writer Hentoff and others",
    "Turner and Cole",
    "Turner and others",
    "Turner and others",
    "\"Beverly Hills 90210\" restaurant owner and others",
    "\"Mr. Basketball\" Holman et al.",
    "Cole and Turner",
    "Turner and others",
    "\"Damn Yankees\" team"
  ],
  "plie": [
    "Dance move",
    "Bend at the barre",
    "French for \"bent\"",
    "Ballet bend",
    "Ballet basic",
    "Ballet move",
    "Ballet move",
    "Barre bend",
    "Ballet movement",
    "Movement that's French for \"bent\"",
    "Elegant ballet bend",
    "Pirouette follower, perhaps",
    "Ballet move",
    "\"Grand\" or \"demi\" ballet move",
    "Ballet bend",
    "Ballerina's bend",
    "Ballet move",
    "Demi-___ (ballet move)",
    "\"Swan Lake\" bend",
    "French dip?",
    "Move often preceding a pirouette",
    "Ballet move",
    "Introductory ballet instruction",
    "Bit of ballet instruction",
    "Ballet bend",
    "\"Swan Lake\" move",
    "Ballet bend",
    "Dance move",
    "Bend for Baryshnikov",
    "It might follow a 31-Across",
    "Ballet bend",
    "Ballet bend",
    "Ballet bend",
    "Balletic bend",
    "\"Swan Lake\" bend",
    "Dance move",
    "Bend at the barre",
    "Balletic bend",
    "Dancer's dip",
    "Ballet move",
    "Movement in choregraphie",
    "Ballet movement in which the knees are bent",
    "Dance exercise",
    "Ballerina's bend",
    "Ballet bend",
    "Dancer's exercise",
    "Graceful bend",
    "Barre room bend",
    "Ballet bend",
    "Ballet bend",
    "Ballet bend",
    "Knee bend",
    "Ballet movement",
    "Ballet bend",
    "Bent knee movement",
    "Graceful bend",
    "Move after a pirouette, perhaps",
    "Ballet bend",
    "Ballet bend",
    "Bend at the bar",
    "Ballet movement",
    "Dance move",
    "Barre room bend",
    "Ballet movement",
    "Balletic bend",
    "Ballet bend",
    "Ballet bend",
    "Ballet movement",
    "Ballet movement",
    "Ballet move",
    "Ballet bend"
  ],
  "omen": [
    "The Grim, in the Harry Potter books",
    "One might be good or evil",
    "Auspice",
    "Gathering clouds, to some",
    "Sign for a soothsayer",
    "\"Good\" or \"ill\" thing",
    "Black cat, some think",
    "Eclipse or a black cat, some say",
    "Shooting star, some might think",
    "Foreboding sign",
    "Dark skies, e.g.",
    "Sign for the superstitious",
    "Herald",
    "Eclipse, for some",
    "Sign of things to come",
    "Appearance of a comet, to some",
    "Gathering clouds, to some",
    "Halley's comet, to William the Conqueror",
    "Appearance of a comet, maybe",
    "Divine message, for some",
    "666, perhaps",
    "It's a sign",
    "Harbinger",
    "Blood moon, to some",
    "Sign for the superstitious",
    "Halley's comet, to William the Conqueror",
    "Harbinger",
    "Calpurnia's dream of Caesar's death, e.g.",
    "Sign",
    "What dark clouds might represent",
    "Sign of the future",
    "Sign for good or ill",
    "Unsettling dream, maybe",
    "Augur",
    "Portent",
    "Auspice",
    "Eclipse, to some",
    "Parting of the clouds, maybe",
    "Sign of things to come",
    "Film for which Gregory Peck had the highest-paid performance of his career, with \"The\"",
    "Breaking a comb, in Japan, e.g.",
    "Harbinger",
    "Portent",
    "Gathering clouds, e.g.",
    "Owl's hoot, to some",
    "Sign to heed",
    "Harbinger",
    "Foreshadowing",
    "What a mystic might look for",
    "Full moon or dark clouds, maybe",
    "Foretoken",
    "Black cat crossing one's path, e.g.",
    "It's a sign",
    "Foreboding sign",
    "A butterfly flying into a house is said to be a good one",
    "Foreshadowing",
    "What a prophet may look for",
    "Portent",
    "Sign for the superstitious",
    "1976 horror hit, with \"The\"",
    "Sign",
    "Black cat running across one's path, say",
    "You might see one in an eclipse",
    "Portent",
    "Portent",
    "Breaking of a mirror, some fear",
    "Warning sign",
    "Sign of things to come",
    "Eclipse, to some",
    "Black cat, to some",
    "Gathering clouds, e.g.",
    "Sign",
    "Black cloud or black cat, to some",
    "Sign",
    "Straw in the wind",
    "Take it as a sign",
    "Shakespeare's \"temple-haunting martlet\" is a good one",
    "*Black cat, supposedly",
    "Sign",
    "Harbinger",
    "1970s-'80s horror film franchise, with \"The\"",
    "Sign to be interpreted",
    "Eclipse, to the impressionable",
    "Sign",
    "Portent",
    "1976 Gregory Peck film, with \"The\"",
    "Augury",
    "Comet, say, to the impressionable",
    "Sign of the future",
    "Broken mirror, to some",
    "Soothsayer's subject",
    "Shooting star, maybe",
    "Storm clouds, to some",
    "Harbinger",
    "Gathering clouds, e.g.",
    "Sign from above",
    "Shooting star, maybe",
    "Impending clouds, e.g.",
    "Warning sign",
    "Red sky in the morning, e.g.",
    "Sign",
    "Sign ... or a description of the answers to the six starred clues?",
    "Mystical indicator",
    "Soothsayer's subject",
    "Sign",
    "Meteor shooting across the sky, maybe",
    "Sign to heed",
    "Red sky, perhaps",
    "Sign of things to come",
    "Warning, maybe",
    "Black cat, to some",
    "Harbinger",
    "17-Across, perhaps",
    "Lightning bolt, perhaps",
    "Black cat or eclipse, maybe",
    "Overcast sky, to some",
    "Sign from above",
    "Straw in the wind",
    "Something wordless to read",
    "Divine sign",
    "What a prophet reads",
    "Black cat, to some",
    "Gathering clouds, say",
    "Sign to interpret",
    "An eclipse, some think",
    "Mysterious sign",
    "Sign from above",
    "Sudden clouding-over, maybe",
    "Future indicator",
    "Spilled salt, say",
    "Handwriting on the wall",
    "Sign",
    "Thunderclouds, perhaps",
    "Red sky, maybe",
    "Broken mirror, say",
    "Rainbow, to some",
    "Prophet's reading",
    "Augur",
    "Augury",
    "Breaking a mirror, maybe?",
    "Prophet's wish",
    "Gathering clouds, say",
    "Sign",
    "Black cat, say",
    "Black cat, e.g.",
    "Black cat, to some",
    "Writing on the wall",
    "*Eclipse ... black cat ... future",
    "Foreboding",
    "Hooting owl, maybe",
    "Dark clouds, say",
    "Harbinger",
    "Harbinger",
    "Parting of the heavens, maybe",
    "Sign",
    "Soothsayer's clue",
    "Broken mirror, to some",
    "Sign to read",
    "Arrival of clouds, for instance",
    "Eclipse, maybe, to the ancients",
    "Gathering clouds, for one",
    "Hint of things to come",
    "Shooting star, maybe",
    "Red sky, perhaps",
    "Overcast sky, say",
    "Sign for the superstitious",
    "Straw in the wind",
    "Foreshadow",
    "Preindication",
    "Handwriting on the wall",
    "Portent",
    "Harbinger",
    "Prophetic sign",
    "Foreboding",
    "Doomsayer's sign",
    "Indication",
    "Sign",
    "Black cat, maybe",
    "Augury",
    "Sign",
    "Sudden break in the clouds, say",
    "Harbinger",
    "Awaited sign",
    "Eclipse, maybe",
    "Harbinger",
    "Augur",
    "Soothsayer's aid",
    "Augury",
    "Portent",
    "Sign",
    "Boding",
    "Eclipse, maybe, to primitive peoples",
    "Foreboding",
    "Sign of things to come",
    "Sign",
    "Oracle's utterance",
    "Presage",
    "Harbinger",
    "Augury",
    "Foreshadowing",
    "Presage",
    "Sign to heed",
    "666, to some",
    "1976 horror hit, with \"The\"",
    "Sign from on high",
    "Dodona message",
    "Bodement",
    "Creepy 1976 film, with \"The\"",
    "Augury",
    "Straw in the wind"
  ],
  "ware": [
    "Soft or hard finish",
    "Suffix with kitchen",
    "Suffix with glass or silver",
    "Piece of merchandise",
    "Pottery",
    "Ending with hard or soft",
    "Ending with silver or soft",
    "Metal finish?",
    "Enamel finish?",
    "Ending with metal or mal-",
    "Ending with metal or mal-",
    "Ending with silver or soft",
    "Ending with metal or mal-",
    "Goods",
    "Suffix with hard or soft",
    "House starter or ender",
    "Tin finish?",
    "Ending with soft or spy",
    "Suffix after kitchen",
    "Pottery",
    "Ending with flat or spy",
    "Delft, e.g.",
    "Pottery",
    "Suffix with spy",
    "Pottery Barn purchases",
    "Ending with hard or soft",
    "Suffix with soft or china",
    "Suffix with soft or hard",
    "Bit of merchandise",
    "Ending with hard or soft",
    "Item of merchandise",
    "It follows soft or silver",
    "Ending with iron or tin",
    "Pottery",
    "Pottery",
    "Delft, e.g.",
    "Pottery",
    "Goods",
    "Sale item",
    "Ending for silver or glass",
    "Forks and spoons"
  ],
  "snob": [
    "Hoity-toity type",
    "One prone to looking down",
    "Condescending sort",
    "Elitist",
    "Expensive beer chaser?",
    "La-di-da sort",
    "Kind of wine drinker who might remark \"I'm getting hints of unripened banana\"",
    "Wine ___",
    "Condescending sort",
    "One quick to pass judgment",
    "Hoity-toity sort",
    "Pretentious sort",
    "Wine ___ (oenophile, often)",
    "Hoity-toity type",
    "Hoity-toity sort",
    "Elitist sort",
    "Snoot",
    "11-Down sort",
    "Supercilious sort",
    "Hoity-toity sort",
    "One who's beyond picky",
    "Elitist",
    "One never stooping",
    "Hardly the hoi polloi type",
    "Kind of appeal",
    "Snooty sort",
    "High-hatter",
    "Uppity sort",
    "One who's always looking down",
    "One not socializing much with hoi polloi",
    "High-hatter",
    "Better-than-you type",
    "\"He who meanly admires mean things,\" per Thackeray",
    "Supercilious sort",
    "Name-dropper, perhaps",
    "Superior sort?",
    "Nose-in-the-air type",
    "Name-dropping sort",
    "One looking down on the \"little people\"",
    "Hoity-toity type",
    "One not associating with the likes of you?",
    "Name-dropper, perhaps",
    "Look-down-one's-nose type",
    "Name-dropper, maybe",
    "Nose-in-the-air type",
    "High-hatter",
    "\"Superior\" one",
    "Belittling sort",
    "One whose nose is in the air",
    "One with a turned-up nose",
    "Hoi polloi disdainer",
    "Thurston Howell type",
    "Hoity-toity one",
    "Pretentious sort",
    "Nose-in-the-air type",
    "Social-climbing type",
    "Judgment passer, perhaps",
    "Sniffish sort",
    "Snooty person",
    "Supercilious sort",
    "Kind of appeal",
    "Many an egotist",
    "Toffee-nosed type",
    "High-hatter",
    "Uppity type",
    "Stuck-up sort",
    "*Disdain ... rank ... high-hat",
    "High-hatter",
    "Climber, of a sort",
    "Snooty sort",
    "Kind of appeal",
    "Haughty one",
    "Hard-to-approach sort",
    "Kind of appeal",
    "High-hatter",
    "Kind of appeal",
    "Stereotyped Beverly Hills resident",
    "For whom nothing's good enough",
    "Kind of appeal",
    "Patronizing person",
    "Snooty one",
    "Kind of appeal",
    "Condescending type",
    "Tuft-hunter",
    "Uppity one",
    "Hoity-toity sort",
    "Nose-in-the-air type",
    "Pretentious one",
    "Kind of appeal",
    "Lordly one",
    "Elitist, in a way"
  ],
  "wane": [
    "Die out",
    "A full moon will do this",
    "Wax off?",
    "Lessen in power",
    "Stop waxing, say",
    "Go down, in a way",
    "Decline",
    "Start to go out",
    "Go down",
    "Ebb",
    "Become less full, as the moon",
    "Decline, as in popularity",
    "Die down",
    "Die down",
    "Lose strength",
    "Decline",
    "Draw to an end",
    "Fade",
    "Lose oomph",
    "Grow dim",
    "Ebb",
    "Diminish",
    "Ebb",
    "What moons do after full moons",
    "Become less bright, as the moon",
    "Drop off",
    "Grow dim",
    "Ebb",
    "Decrease, as the moon",
    "Draw to a close",
    "Decrease gradually",
    "Flag",
    "Fall off",
    "Drop off",
    "Drop off",
    "Lose power",
    "Decrease gradually",
    "Diminish",
    "Draw to a close",
    "Decline",
    "Be on the way out",
    "Fall off",
    "Fall off",
    "Taper off",
    "Go down",
    "Wind down",
    "Diminish",
    "Fall off",
    "Go downhill",
    "Dwindle",
    "Drop off",
    "Diminish",
    "Draw to a close",
    "Wax's opposite",
    "Grow dim",
    "Peter out",
    "Grow dim",
    "Grow dim",
    "Fade",
    "Alternative to wax",
    "Dwindle",
    "Diminish",
    "Diminish",
    "Wind down"
  ],
  "ades": [
    "Some stand concessions",
    "Fruit drinks",
    "Some beverage suffixes",
    "Lemon or lime drinks, informally",
    "Fruit drinks",
    "Summer drink endings",
    "Some coolers",
    "Fruity drinks",
    "Fruity coolers",
    "Coolers in coolers",
    "Fruity quaffs",
    "Picnic coolers",
    "Some thirst quenchers",
    "Summer drinks",
    "Citrus drinks",
    "Sources of vitamin C",
    "Summer coolers",
    "Some sources of vitamin C",
    "Summer coolers",
    "Citrus drinks",
    "Some punches",
    "Drinks at sidewalk stands",
    "Drinks stirred in pitchers",
    "Summer refreshers",
    "Some Snapple products",
    "Sidewalk stand offerings",
    "Fruity drinks",
    "Stand sales",
    "Coolers",
    "Curbside buys",
    "Some pitcherfuls",
    "Sources of vitamin C",
    "Sugary quaffs",
    "Some punches",
    "Vitamin C providers, maybe",
    "Fruity drinks",
    "Summer drinks",
    "Fruity drinks",
    "Fruity drinks",
    "Fruity drinks",
    "Fruit beverages",
    "Sidewalk stand buys",
    "Fruit drinks",
    "Some pitcherfuls",
    "Juice drinks",
    "Hot-weather quenchers",
    "Summer drinks",
    "Summer drinks",
    "Juice drinks",
    "Sidewalk stand quaffs",
    "Pitchers may hold them",
    "Sidewalk stand drinks",
    "Sweet drinks",
    "Citrus coolers",
    "Summer coolers",
    "Citrus coolers",
    "Stirred drinks",
    "Fruity drinks",
    "Sugary drinks",
    "Summer coolers",
    "Fruit drinks",
    "Some sports drinks",
    "Summer drinks",
    "Juice drinks",
    "Summer coolers",
    "Drinks stirred in pitchers",
    "Sugary drinks",
    "Citrus coolers",
    "Sidewalk stand beverages",
    "Summer drinks",
    "Cool drinks",
    "Juice drinks",
    "They're usually fruit-flavored",
    "Stand buys",
    "Summer quaffs",
    "Some vitamin C sources",
    "Fruity coolers",
    "Some vitamin C sources",
    "Summer coolers",
    "Fruity coolers",
    "Fruit juices",
    "Some punches",
    "Juice bar stock",
    "Sidewalk stand libations",
    "Thirst quenchers",
    "Summer heat-beaters",
    "Citrus drinks",
    "Summer drinks",
    "Fruity coolers",
    "Lemon and orange drinks",
    "Sidewalk stand sales",
    "Sugary drinks",
    "They might get drunk in the summer",
    "Lime drinks",
    "Summer quaffs",
    "Alternatives to 32-Across",
    "Picnic coolers",
    "Summer quaffs",
    "Thirst quenchers",
    "Fruity drinks",
    "Thirst slakers",
    "Lemon and lime drinks",
    "Citrus quaffs",
    "Cool drinks",
    "Fruity drinks",
    "Drinks with vitamin C",
    "Lime coolers",
    "Iced drinks",
    "Summer beverages",
    "Summer refreshers",
    "Vitamin C drinks",
    "Soft drinks",
    "Lime finishes",
    "Soft drinks"
  ],
  "rate": [
    "Velocity, e.g.",
    "Play a critical role?",
    "Give feedback on Yelp, maybe",
    "Grade",
    "Going amount",
    "Speed",
    "$100/night, e.g.",
    "Label as PG, say",
    "Merit",
    "Assign stars to, say",
    "A mile a minute, e.g.",
    "Apt rhyme for \"evaluate\"",
    "Be highly regarded",
    "Clip",
    "Low-quality",
    "First-___ (best)",
    "Give stars to",
    "The \"r\" of r = d/t",
    "Assign stars to",
    "Figure on a utility bill",
    "Matter of interest?",
    "Speed",
    "Second-___",
    "Give stars to",
    "\"Per hour\" or \"per mile\" figure",
    "Compare, in a way",
    "Be critical of?",
    "Gauge",
    "Grade",
    "Give a Yelp review, say",
    "Word after prime or cut",
    "Going ___",
    "Be highly esteemed",
    "Figure on a utility bill",
    "Put on a scale from 1 to 10, say",
    "Assess",
    "Going ___",
    "Have merit",
    "$15/hour, e.g.",
    "Parking lot figure",
    "Prime or crime follower",
    "Bit of info on the side of a taxi",
    "Put on a scale",
    "Have prestige",
    "$2.50 per 1/5 mile, e.g.",
    "Miles per gallon, e.g.",
    "Miles per hour, e.g.",
    "Mention on Yelp, say",
    "Assign stars to",
    "Evaluate",
    "Put on a scale",
    "Words per minute, e.g.",
    "Utility bill datum",
    "Hourly wage, e.g.",
    "Word with cut or exchange",
    "Insurance quote",
    "2.5%/year interest, e.g.",
    "Part of 24-Down",
    "Figure on an electric bill",
    "Credit card statement figure",
    "Have special privileges",
    "Bank quote",
    "Deserve special treatment",
    "Label G or PG, e.g.",
    "Insurance shopper's concern",
    "Miles per gallon, e.g.",
    "Not be a nobody",
    "Assign stars to, say",
    "Give a star, say",
    "Hotel hunter's concern",
    "Judge",
    "Deserve special perks, say",
    "Assign an NC-17, say",
    "Bank quote",
    "Be something special",
    "Appraise",
    "Appraise",
    "Assign an NC-17, e.g.",
    "Be held in esteem",
    "Have an in (with)",
    "Feet per second, e.g.",
    "Ad sales rep's quote",
    "Going ___",
    "$5.15/hour, e.g.",
    "Hourly charge",
    "Monitor's measure",
    "Bank posting",
    "Clip",
    "Put on a scale",
    "2%, maybe",
    "Clip",
    "Give marks to",
    "Size up",
    "Figure of interest?",
    "Pace",
    "Be worthy of",
    "Appraise",
    "Declare \"good\" or \"excellent,\" say",
    "Unit charge",
    "Have status",
    "Size up",
    "Amount charged",
    "Clip",
    "Be judgmental",
    "Judge",
    "Assign stars to",
    "Judge",
    "Have status",
    "Time and a half, e.g.",
    "Interest figure",
    "Give a 5, e.g.",
    "A dime a dozen, e.g.",
    "Bank posting",
    "Five cents a minute, e.g.",
    "5?ó/gallon, e.g.",
    "Word after going or flat",
    "Give stars to",
    "This doesn't need to be fixed, but it can be",
    "Be worthy of",
    "Mortgage consideration",
    "Size up",
    "Judge",
    "Five cents a minute, say",
    "Do a critic's job",
    "Merit",
    "Size up",
    "$75/night, e.g.",
    "Part of A.P.R.",
    "Hourly fee, e.g.",
    "Give stars to, perhaps",
    "Assign an \"R,\" say",
    "Bank posting",
    "Be held in high esteem",
    "Price",
    "Interest level",
    "Miles per hour, e.g.",
    "Taxi door info",
    "Tempo",
    "Part of A.P.R.",
    "Miles per hour, e.g.",
    "Fixed fee",
    "Word with prime or cut",
    "Appraise",
    "Fare",
    "Fixed fee",
    "Miles per hour, e.g.",
    "Insurance figure",
    "Size up",
    "Be held in esteem",
    "Count, in a manner of speaking",
    "Size up",
    "Judge",
    "Size up",
    "Give stars to"
  ],
  "tore": [
    "Ripped",
    "Went 90, say",
    "Bolted",
    "Hightailed it",
    "Went like the dickens",
    "Rent",
    "Zipped ... or ripped",
    "Burned rubber",
    "Raced",
    "Went very fast",
    "Ran fast",
    "Raced (through)",
    "Ripped",
    "Raced",
    "Blazed",
    "Ripped",
    "Shredded",
    "Rent",
    "Barreled",
    "Drove like a demon",
    "Shredded",
    "Ripped",
    "Sped",
    "Raced",
    "Ripped",
    "Went like hell",
    "Went like heck",
    "Shredded",
    "Floored it",
    "Sped",
    "Drove like mad",
    "Careered",
    "Bolted",
    "Bolted",
    "Ruptured",
    "Moved with force",
    "Ripped",
    "Ripped",
    "Streaked",
    "Flew",
    "*Made tracks",
    "Ripped",
    "Flew",
    "Ripped",
    "Went 80, say",
    "Floored it",
    "Rent",
    "Went like the dickens",
    "Went fast",
    "Ripped",
    "Raced (through)",
    "Ripped",
    "Rushed",
    "Burned up the highway",
    "Sped",
    "Blazed",
    "Whizzed (through)",
    "Dashed",
    "Rent",
    "Dashed",
    "Barreled along",
    "Sped",
    "Flew",
    "Raced",
    "Streaked",
    "Went like the dickens",
    "Skedaddled",
    "Sped",
    "Shredded",
    "Zipped",
    "Sped",
    "Drove like mad",
    "Burned up the road",
    "Split",
    "Rushed headlong",
    "Hurried",
    "Dashed",
    "Raced",
    "Ran",
    "Ignored the speed limit",
    "Sped",
    "Rent",
    "Pulled to pieces",
    "Came apart at the seams",
    "Sped",
    "Hightailed it",
    "Went lickety-split",
    "Rent",
    "Burned rubber",
    "Double-timed",
    "Made tracks",
    "Zoomed",
    "Ripped or zipped",
    "Made tracks",
    "Ripped",
    "Ripped",
    "Ripped",
    "Rent",
    "Ripped",
    "Ruptured",
    "Ran like mad",
    "Raced"
  ],
  "slur": [
    "Arc-shaped musical notation",
    "Arc on a music score",
    "Talk like a tippler",
    "Relative of a tie in music",
    "Talk drunkenly",
    "Shpeak thish way",
    "Arc on a musical score",
    "Shtalk like thish",
    "Talk trashed?",
    "Musical symbol indicating legato",
    "Indication of drunkenness",
    "Disparaging remark",
    "Fail to enunciate",
    "Speak indistinctly",
    "Speak indistinctly",
    "Score symbol similar to a tie",
    "Put-down",
    "\"You lowdown, no-good bum,\" e.g.",
    "Bit of name-calling",
    "Musical curve",
    "Disparaging remark",
    "Put-down",
    "Potential libel",
    "\"Dirtbag,\" e.g.",
    "Ethnic joke, often",
    "Epithet",
    "Musical line",
    "Speak drunkenly",
    "Say \"Offisher, I am completely shober,\" e.g.",
    "\"Dirty rotten scoundrel,\" e.g.",
    "Aspersion",
    "\"Lying thief,\" e.g.",
    "\"Dirty rat,\" e.g.",
    "Arc on a music score",
    "\"White trash,\" e.g.",
    "Speak carelessly",
    "Legato indicator",
    "Score connector, in music",
    "Talk like a drunk",
    "Putdown",
    "Drag through the mud",
    "\"Dirty, rotten scoundrel,\" e.g.",
    "Speak indistinctly",
    "Aspersion",
    "Putdown",
    "Defamatory statement",
    "Bigot's comment",
    "Sling mud at, say",
    "Speak unclearly",
    "Disparaging remark",
    "Disparage",
    "Bit of slander",
    "Cast aspersions on",
    "Evidence of drunkenness",
    "Insinuation",
    "Arc on a score",
    "Disparaging word",
    "Talk like a tosspot",
    "Ugly putdown",
    "Talk like thish, shay",
    "Insulting remark",
    "Aspersion",
    "Innuendo",
    "Talk fast, maybe",
    "Drunk's tipoff",
    "Many a campaign tactic",
    "Disparagement",
    "Epithet",
    "Insult",
    "Musical mark",
    "Aspersion",
    "Cast aspersions on",
    "\"Dirty dog,\" for one",
    "Score line",
    "Insulting remark",
    "Libelous remark",
    "Musical sign",
    "Put-down",
    "Innuendo",
    "Curve between musical notes",
    "Talk drunkenly",
    "Bit of mudslinging",
    "Put-down",
    "Malign"
  ],
  "ibos": [
    "Okonkwo's people in \"Things Fall Apart\"",
    "Some native Nigerians",
    "Nigerian natives",
    "Native Nigerians",
    "Native Nigerians",
    "Nigerian natives",
    "Certain Nigerians",
    "Nigerian people"
  ],
  "port": [
    "Left on board, say",
    "Opening for a computer technician?",
    "___ Louis, capital of Mauritius",
    "What's left at sea",
    "Wine specialty of the Douro Valley",
    "Any ___ in a storm",
    "Cruise ship stop",
    "City with piers",
    "After-dinner drink",
    "Trading hub",
    "Part of a cruise itinerary",
    "City with piers",
    "Dessert wine",
    "Computer part",
    "Cruise ship stop",
    "After-dinner serving",
    "Opening in the computer business?",
    "Dessert wine ... also what can fill the square at the crossing of 50-Across and 51-Down",
    "Strong wine",
    "Left at sea",
    "After-dinner drink",
    "Left side",
    "Left at sea",
    "Computer connection",
    "Left to the captain?",
    "Computer hookup point",
    "Computer connection",
    "Cruise stopover",
    "Computer connection",
    "Dubai or Houston",
    "Computer connection point",
    "After-dinner drink",
    "Left side",
    "Dessert wine",
    "Left, at sea",
    "What's left at sea",
    "Left on a liner",
    "35-DOWN IS ONE",
    "It was left on the Titanic",
    "Left at sea",
    "Left at sea",
    "Harbor",
    "Harbor city",
    "Good thing to have in a storm",
    "After-dinner drink",
    "Larboard",
    "What's left",
    "Drink with Stilton",
    "Maritime stop",
    "Dessert wine",
    "Computer interface jack",
    "Left on board?",
    "Where a cruise calls"
  ],
  "plea": [
    "\"Help!,\" for example",
    "\"No contest,\" for one",
    "Urgent request",
    "\"I beg of you,\" e.g.",
    "Earnest request",
    "Something heard in court",
    "___ bargain",
    "\"Nolo contendere,\" e.g.",
    "Courtroom claim",
    "Kind of bargain",
    "Fund-raising letter, e.g.",
    "\"Help me, Obi-Wan Kenobi. You're my only hope,\" e.g.",
    "Lead-in to bargain or deal",
    "One may be copped",
    "Entered response",
    "S O S, basically",
    "Court proposition",
    "Nolo contendere, e.g.",
    "\"Help me, Obi-Wan Kenobi,\" e.g.",
    "Prayer, e.g.",
    "\"Not guilty,\" e.g.",
    "Defendant's entry",
    "Message from the Red Cross, maybe",
    "\"Spare me!,\" for one",
    "\"Save yourself!,\" e.g.",
    "\"Listen!,\" e.g.",
    "\"Don't go!,\" e.g.",
    "Sentence shortener, at times",
    "\"S O S,\" e.g.",
    "Court position",
    "\"No contest,\" for one",
    "___ bargain",
    "Court stance",
    "Earnest request",
    "Answer to a judge",
    "Courtroom entry",
    "\"Guilty\" or \"not guilty\"",
    "\"Help me, Obi-Wan Kenobi,\" e.g.",
    "Cop a ___",
    "Petition",
    "Entreaty",
    "Supplication",
    "Subject of a court bargain",
    "Entreaty",
    "\"S O S!,\" e.g.",
    "S O S, e.g.",
    "\"Don't shoot!,\" e.g.",
    "\"Nolo contendere,\" e.g.",
    "Petition",
    "Defendant's declaration",
    "Court claim",
    "Court position",
    "\"Have mercy!,\" e.g.",
    "\"No more!,\" e.g.",
    "Heartfelt request",
    "___ bargain",
    "\"Not guilty,\" e.g.",
    "Courtroom entry",
    "Court action",
    "\"S O S,\" e.g.",
    "Subject of some bargaining",
    "S O S, e.g.",
    "\"Help!,\" e.g.",
    "Response to a charge",
    "Appeal",
    "\"Spare me!,\" e.g.",
    "\"Not guilty,\" e.g.",
    "Self-defense, e.g.",
    "Video from a kidnappee's family, e.g.",
    "Nolo contendere, for one",
    "\"Not guilty,\" e.g.",
    "S.O.S., in essence",
    "\"Don't hurt me!,\" e.g.",
    "\"Not guilty,\" for one",
    "Appeal",
    "Fund-raising letter, e.g.",
    "Fund-raising letter, basically",
    "Not guilty, e.g.",
    "\"Spare me!,\" e.g.",
    "\"Put me in, coach!,\" e.g.",
    "S O S, basically",
    "Many a prayer",
    "Bargaining factor",
    "Court statement",
    "Statement to a judge",
    "\"Give me another chance,\" e.g.",
    "Answer to a judge",
    "Nolo contendere, e.g.",
    "Courtroom statement",
    "Bit of begging",
    "\"Guilty,\" e.g.",
    "Answer to a charge",
    "Defendant's declaration at an arraignment",
    "\"Guilty\" or \"not guilty\"",
    "\"Nolo,\" for instance",
    "\"Do it just for me,\" e.g.",
    "Not guilty by reason of insanity, e.g.",
    "\"Not guilty,\" e.g.",
    "Nolo contendere, e.g.",
    "\"Let me go!\" e.g.",
    "Arraignment offering",
    "\"Don't hurt me!,\" for one",
    "\"Guilty,\" e.g.",
    "\"No contest,\" for one",
    "Fund-raising letter, e.g.",
    "\"Have mercy on me!,\" e.g.",
    "Courtroom entry",
    "\"Nolo,\" e.g.",
    "Entreaty",
    "\"Save me!,\" e.g.",
    "Request",
    "It may be entered in a court",
    "A prisoner may enter one",
    "Entreaty",
    "Tell it to the judge",
    "Emotional request",
    "\"Not guilty,\" e.g.",
    "Appeal",
    "\"Not guilty,\" e.g.",
    "Courtroom bargain",
    "Defense___",
    "\"Not guilty,\" e.g.",
    "Something to cop",
    "Entreaty",
    "___-bargain",
    "No contest, e.g.",
    "\"Remember the neediest,\" e.g.",
    "No contest, e.g.",
    "\"Not guilty by reason of insanity,\" e.g."
  ],
  "aero": [
    "Lead-in to -stat",
    "Prefix with nautical",
    "Sleek, in auto-speak",
    "Prefix with nautical",
    "Flying: Prefix",
    "Sleekly designed, for short",
    "Prefix with static or dynamic",
    "Prefix with -nautics",
    "Prefix with nautical",
    "Prefix with -naut",
    "Prefix with space",
    "Prefix with dynamic",
    "Sleekly designed",
    "Designed to minimize drag",
    "Prefix with -naut",
    "Flying start?",
    "Prefix with dynamic",
    "Prefix with nautical",
    "Flying start?",
    "Prefix with -nautic",
    "Engineering discipline, informally",
    "Bubble-filled Nestle chocolate bar",
    "Streamlined, for short",
    "Prefix with space",
    "Plane-related",
    "Lead-in to mexico",
    "Commercial prefix with postale",
    "Prefix with -batics",
    "Flying start?",
    "Lead-in to -drome",
    "Spanish prefix with lineas",
    "Lead-in to space",
    "Sleek, informally",
    "Wind-cheating",
    "Commercial lead-in to mexico",
    "Reducing wind resistance",
    "Prefix with dynamic",
    "Musical lead-in to -smith",
    "Aviation-related prefix",
    "Prefix with dynamic",
    "Musical lead-in to -smith",
    "Former Houston athlete",
    "Commercial prefix with postale",
    "Commercial prefix with -flot",
    "Plane-related",
    "Nestle chocolate bar since 1988",
    "Streamlined, for short",
    "Designed to minimize drag",
    "___ Club (pilot's group)",
    "Flight-related prefix",
    "Dynamic start?",
    "Prefix with dynamic",
    "Prefix with 28-Across",
    "Prefix with dynamic",
    "Prefix with engine",
    "Sleek, informally",
    "Prefix with dynamic",
    "Commercial prefix with postale",
    "Designed to minimize wind resistance",
    "Prefix with dynamic",
    "Lead-in to plane",
    "Commercial prefix with postale",
    "Prefix with dynamics",
    "Quarterly magazine published by Boeing",
    "Mexico lead-in",
    "Commercial prefix with postale",
    "Designed for flight, for short",
    "Flying start?",
    "Commercial name suggesting sleekness",
    "Prefix with plane, to a Brit",
    "Prefix with -stat",
    "Like sports cars, briefly",
    "___-engine",
    "Flying: Prefix",
    "Prefix with -batics",
    "Sleek, briefly",
    "Prefix with dynamic",
    "Houston ice hockey pro",
    "Prefix with -gramme",
    "Prefix with plane",
    "Prefix with postale",
    "Prefix with magnetic",
    "Prefix with plane",
    "Like sports cars, briefly",
    "Start of some carrier names",
    "Designed for flight",
    "Saab model",
    "Aviation-related prefix",
    "Prefix with -naut",
    "Prefix with space",
    "Prefix with dynamic",
    "Sleek, in car talk",
    "Streamlined, in brief",
    "Prefix with dynamic",
    "Prefix with dynamic",
    "Sleek, for short",
    "Commercial prefix with \"flot\"",
    "Aviation-related",
    "Britain's Royal ___ Club, for plane enthusiasts",
    "Saab model",
    "Prefix with -naut",
    "Prefix with nautical",
    "Prefix with marine",
    "Prefix with car",
    "Like sports cars, briefly",
    "Old aviation magazine ___ Digest",
    "Commercial prefix with jet",
    "Nautical leader?",
    "Commercial prefix with mexico",
    "Houston hockey player",
    "Aviation-related prefix",
    "Prefix with -postale",
    "Aviation-related",
    "Prefix with dynamic",
    "Pertaining to flying",
    "Start of many airline names",
    "Sleek, in auto talk",
    "Prefix with 94-Down",
    "Prefix with -nautic",
    "Prefix with -drome",
    "Prefix with space",
    "Plane prefix",
    "Prefix with space",
    "Prefix with magnetic",
    "Houston hockey player",
    "Prefix with -drome",
    "Prefix with magnetic",
    "Prefix with -naut",
    "Sleek, for short",
    "Prefix with nautical",
    "Designed for flying",
    "Sleek, for short",
    "Prefix with -gramme",
    "Prefix with nautical",
    "Rocket scientist's prefix",
    "Prefix in the airplane industry",
    "Prefix with nautical",
    "Prefix with dynamic",
    "Flying prefix",
    "Plane-related",
    "Prefix with -drome",
    "Prefix with space",
    "Designed for flying",
    "Planelike",
    "Prefix with -batic",
    "Prefix with nautical",
    "Prefix with space",
    "Designed to minimize wind resistance",
    "Sleek, in car talk",
    "Flying: Prefix",
    "Prefix with magnetic",
    "Prefix with dynamic",
    "Commercial prefix with star",
    "___ Digest (classic aviation magazine)",
    "Prefix with gramme",
    "Dynamic opening?",
    "Saab model",
    "Flying start?",
    "Prefix with -naut",
    "Prefix with dynamic",
    "Prefix with nautical",
    "Old magazine ___ Digest",
    "Prefix with magnetic",
    "Dynamic opener",
    "Space opener?",
    "Prefix with space",
    "Prefix with magnetic",
    "Dynamic start?",
    "Plane prefix",
    "___ Flite (bicycle brand)",
    "Prefix with magnetic",
    "Dynamic prefix",
    "Built for speed",
    "Prefix with photo",
    "Prefix with -nautics",
    "Sleekly designed",
    "Prefix with plane",
    "Designed for flight",
    "Commercial prefix with Mexico",
    "Old-fashioned prefix with photo",
    "Magazine published by Boeing",
    "Prefix with nautical",
    "Old magazine billed as \"America's Aviation Weekly\"",
    "Designed for flight",
    "Prefix with dynamic",
    "Akron baseball player",
    "Prefix with -nautics",
    "Space starter",
    "Dynamic beginning",
    "Prefix with 39-Across",
    "Sleekly designed",
    "Sleek, in automotive lingo",
    "Of planes and flying",
    "Sleek, in car lingo",
    "Dynamic start",
    "Prefix with mechanics",
    "Saab model",
    "Flying-related",
    "Prefix with dynamic",
    "Prefix with dynamic",
    "___ Flite (bicycle brand)",
    "Prefix with -gramme",
    "Prefix with mechanics",
    "Prefix with dynamic",
    "Eddie Rickenbacker's 94th ___ Squadron",
    "Dynamic introduction",
    "Prefix with space or stat",
    "Prefix with space",
    "Pertaining to aircraft",
    "Aviation-related",
    "30's auto",
    "Prefix with dynamic",
    "Dynamic start",
    "___Digest (old flying magazine)",
    "Prefix with -naut",
    "Space prefix",
    "Dynamic beginning",
    "Houston athlete of yore",
    "Nautical start",
    "Dynamic start",
    "Prefix with gram",
    "Prefix with nautical",
    "Flying: Prefix",
    "Pertaining to flight",
    "Space prefix",
    "Space prefix",
    "Prefix with space or stat",
    "Prefix with gram or train",
    "Plane or dynamic preceder",
    "Flying: Prefix",
    "Dynamic beginning"
  ],
  "ptas": [
    "Grps. receiving Our Children magazine",
    "K-12 fund-raising grps.",
    "Groups involved in class actions, for short?",
    "Readathon supporters, for short",
    "Certain fund-raising orgs.",
    "School-supporting orgs.",
    "Band boosters, perhaps",
    "Education support grps.",
    "Orgs. running drives for school supplies",
    "Some fund-raising grps.",
    "Some book fair organizers, for short",
    "Some bake sale groups, for short",
    "Some fund-raising grps.",
    "School support grps.",
    "Mom-and-pop grps.",
    "School grps.",
    "School support grps.",
    "Grps. supporting the 30-Across",
    "Ed promoters",
    "Some car wash grps.",
    "Grps. with the motto \"Every child. One voice\"",
    "Grps. known for holding bake sales",
    "Mom-and-pop orgs.",
    "Some fund-raising grps.",
    "Class boosters, for short",
    "Ed supporters",
    "School orgs.",
    "Mom-and-pop orgs.",
    "H.S. support groups",
    "H.S. supporters",
    "School grps.",
    "They have mtgs. in schools",
    "Tax-exempt educ. groups",
    "Mom-and-pop grps.",
    "Grps. sponsoring many book fairs",
    "Grps. concerned with class struggle?",
    "Sch. groups",
    "Class-conscious grps.?",
    "Some fund-raising orgs.",
    "Elhi grps.",
    "Class-conscious grps.?",
    "Money-raising grps.",
    "Frequent fund-raising grps.",
    "Bake sale grps.",
    "Mom-and-pop grps.",
    "Fund-raising grps.",
    "Fund-raising grps.",
    "Sch. groups",
    "School grps.",
    "School orgs.",
    "Class-conscious grps.?",
    "H.S. helpers",
    "School grps.",
    "Sch. groups",
    "H.S. supporters",
    "Grps. that liaise with principals",
    "Elhi orgs.",
    "Bake sale holders, often",
    "Educ. helpers",
    "Elhi orgs.",
    "Grps. sponsoring open houses",
    "School orgs.",
    "Sch. liaisons",
    "Child welfare grps.",
    "They have open houses",
    "Span. coins",
    "Home-sch. liaisons",
    "School orgs.",
    "Educ. groups",
    "Sch. orgs.",
    "School orgs.",
    "Sch. supporters",
    "School orgs."
  ],
  "teen": [
    "Fox's ___ Choice Awards",
    "Many a TikTok user",
    "Nirvana's \"Smells Like ___ Spirit\"",
    "LeBron James in his N.B.A. debut, e.g.",
    "Quinceañera celebrant, e.g.",
    "___ Choice Awards",
    "Romeo or Juliet",
    "Ending with four or six, but not three or five",
    "One starting college, typically",
    "Many a TikTok user",
    "Many a middle schooler",
    "Typical reader of John Green novels",
    "Many a TikTok user",
    "Juliet Capulet or Holden Caulfield, agewise",
    "Fake ID user, often",
    "Digital native, often",
    "Someone who is not yet 20",
    "Many a character in Ann M. Martin's \"The Baby-Sitters Club\"",
    "Typical summer intern",
    "One twixt 12 and 20",
    "Adolescent",
    "Pre-twentysomething",
    "Follower of four or six, but not five",
    "Typical college freshman",
    "Any of the Ninja Turtles",
    "Many a Generation Z member, now",
    "Many a bar mitzvah attendee",
    "One under 20",
    "Mark Zuckerberg when founding Facebook, e.g.",
    "This puzzle's constructor, for one",
    "Typical Snapchatter",
    "___ Vogue",
    "Many a first-time voter",
    "Romeo or Juliet",
    "27-Across taker, typically",
    "Member of Generation Z",
    "___ Vogue magazine",
    "Many an Instagram user",
    "___ idol",
    "Many a Snapchat user",
    "Fox's ___ Choice Awards",
    "Bar mitzvah, e.g.",
    "First-time voter, often",
    "Follower of four or six, but not five",
    "___ Vogue",
    "High schooler",
    "Youth",
    "Bar mitzvah boy, barely",
    "Archie, Betty or Veronica",
    "Many a new driver",
    "High schooler, typically",
    "23-Across's target reader",
    "Olympic female gymnast, typically",
    "\"The ___ Commandments\" (1958 hit)",
    "Many a \"Twilight\" fan",
    "College freshman, typically",
    "Romeo or Juliet",
    "Typical Justin Bieber fan",
    "One who's not yet a twentysomething",
    "Holden Caulfield, for one",
    "Miss ___ USA",
    "High schooler",
    "SAT taker, e.g.",
    "12-20 filler?",
    "First-time driver, often",
    "College freshman, usually",
    "New driver, typically",
    "Many a bar mitzvah attendee",
    "First-year college student, usually",
    "Archie or Veronica",
    "Many a driver's ed student",
    "Wii user, maybe",
    "Almost any girl in \"Gossip Girl\"",
    "First-time driver, often",
    "College freshman, usually",
    "Freshman, most likely",
    "Adolescent",
    "Typical Mad reader",
    "\"___ Wolf,\" Michael J. Fox film",
    "Person under 20",
    "New driver, frequently",
    "New driver, typically",
    "\"One Tree Hill\" target viewer",
    "Many a Facebook listee",
    "Many a Jessica Simpson fan",
    "Adolescent",
    "\"___ Angel\" (1960 #1 hit)",
    "11-Down, frequently",
    "Many a Nintendo player",
    "Bar mitzvah boy, for one",
    "New driver, usually",
    "Minor",
    "High-schooler",
    "Romeo or Juliet",
    "Target MTV viewer",
    "Comics target",
    "Archie or Veronica",
    "Freshman, probably",
    "One recently bar mitzvahed, e.g.",
    "Freshman, usually",
    "Student driver, usually",
    "One going off to college, maybe",
    "One taking driver's ed, perhaps",
    "Numerical suffix",
    "Middle-schooler, maybe",
    "One 'twixt 12 and 20",
    "Romeo or Juliet",
    "High schooler",
    "Many a Boy Scout",
    "\"Harold ___\" (old comic strip)",
    "High-schooler",
    "Suffix with nine, but not ten",
    "\"Scream\" extra",
    "New driver, often",
    "Magazine with dating tips",
    "New driver, maybe",
    "It may follow four or six, but not five",
    "Archie or Jughead",
    "\"90210\" extra",
    "Adult-to-be",
    "It follows four but not five",
    "Adult-to-be",
    "Tiger Beat reader",
    "81-Down, often",
    "Bar mitzvah boy, barely",
    "Freshman, probably",
    "Adult-to-be",
    "Tiger Beat alternative",
    "Beavis or Butt-head",
    "Popular youth magazine",
    "High schooler",
    "Summer job seeker, often",
    "Tiger Beat reader",
    "\"Happy Days\" type",
    "Selective Service registrant, agewise",
    "Rebellious one, maybe",
    "High schooler",
    "Miss ___ USA",
    "Minor, maybe",
    "Adolescent",
    "1960 #1 hit \"___ Angel\"",
    "High schooler",
    "MTV's target viewer",
    "MTV target",
    "High schooler",
    "Youth magazine",
    "YM competitor",
    "Youth",
    "High schooler",
    "One 'twixt 12 and 20",
    "Highschooler",
    "Tiger Beat reader",
    "Generation Y member",
    "Girls' magazine",
    "High schooler",
    "High schooler",
    "Adult-to-be",
    "Chelsea Clinton, e.g.",
    "Adolescent",
    "Adolescent",
    "Person 'twixt 12 and 20"
  ],
  "when": [
    "Timely query",
    "Info on an invitation",
    "\"That's enough\"",
    "Invitation particular",
    "\"Say ___\"",
    "Not just if",
    "A question of time",
    "Word following \"If not now\"",
    "\"That's enough,\" to a server",
    "\"___ pigs fly!\"",
    "\"Say ___\"",
    "\"Say ___\"",
    "Invitation info",
    "\"Say ___\" (pourer's request)",
    "\"Say ___\"",
    "A question of timing",
    "Newshawk's query",
    "Timely question?",
    "Declaration of Independence starter",
    "\"Say ___\"",
    "A question of timing",
    "Reporter's word",
    "Under what circumstances",
    "Reporter's question",
    "Something to say?",
    "Timely question",
    "Invitation info",
    "At what time?",
    "A question of time",
    "One of the five W's"
  ],
  "madd": [
    "Anti-D.W.I. org.",
    "Anti-D.U.I. org.",
    "Sober-minded org.",
    "Grp. that trademarked the phrase \"Helping Survivors Survive\"",
    "Org. advocating highway safety",
    "Org. opposed to weaving?",
    "Org. advocating breath-testing ignition locks",
    "Hwy. safety org.",
    "Road safety org.",
    "Anti-D.U.I. org.",
    "Grass-roots org. that fights alcohol abuse",
    "Safety org.",
    "Org. with a \"Designate a Driver\" program",
    "Org. whose success is no accident?",
    "Highway safety org.",
    "Safety org.",
    "Volunteer org. launched in 1980",
    "Sober-motorists org.",
    "Anti-D.W.I. group",
    "Grp. advocating tough liquor laws",
    "Antidrinking org.",
    "Hwy. safety org.",
    "Alcohol awareness org.",
    "Anti-D.W.I.org.",
    "Anti-D.W.I. group"
  ],
  "diss": [
    "Insult, slangily",
    "Deliver a burn to",
    "Put-down",
    "___ track (rap song with insults)",
    "Insult, slangily",
    "___ track (attack song)",
    "Ph.D. hurdle: Abbr.",
    "Ph.D. requirement: Abbr.",
    "Grad sch. composition",
    "Reqmt. for giving someone the third degree?",
    "Major work in grad sch.",
    "Univ. paper",
    "Ph.D. thesis: Abbr.",
    "Ph.D.'s work: Abbr."
  ],
  "mobs": [
    "Packs",
    "Throngs",
    "Rampaging groups",
    "Unruly crowds",
    "Unruly crowds",
    "Volatile masses",
    "Angry crowds",
    "Greets enthusiastically, in a way",
    "Pitchfork-wielding groups",
    "Crowds around noisily",
    "Pitchfork-wielding groups",
    "Packs",
    "Crowds around",
    "After-Christmas sale shoppers, e.g.",
    "Unruly crowds",
    "Large 40-Across of people",
    "Attacks riotously",
    "Unruly crowds",
    "Swarms",
    "Sale scenes",
    "Crowds around",
    "Crowds around"
  ],
  "trot": [
    "Moderate pace",
    "Horse's gait",
    "Go at a clip",
    "Brisk pace",
    "Gait slower than a gallop",
    "Put on display, with \"out\"",
    "Home run pace",
    "Gait faster than a walk",
    "Equestrian's pace",
    "Gait slower than a gallop",
    "Bring (out) for display",
    "Intermediate gait",
    "Easy pace",
    "Track event",
    "Bring (out)",
    "Harness racing gait",
    "Quick pace",
    "Dog jog",
    "Pleasant pace",
    "Home run ___ (baseball highlight)",
    "Compete in a harness race",
    "Bring (out)",
    "Gait faster than a walk",
    "Gait between a walk and a canter",
    "Turkey ___ (annual event)",
    "Home-run run",
    "Brisk pace",
    "Bring forward, with \"out\"",
    "Accelerated pace",
    "Race pace",
    "Dressage gait",
    "Gait faster than a walk",
    "Gait faster than a walk",
    "Gait between a walk and a canter",
    "Gait slower than a canter",
    "Medium gait",
    "Show, with \"out\"",
    "Dressage gait",
    "Easy step",
    "Horse's gait",
    "Brisk pace",
    "Jog",
    "Non-Derby pace",
    "Go quickly",
    "Arab spring?",
    "Pace at Pompano Park",
    "Home run pace",
    "Move briskly",
    "Harness race gait",
    "Harness horse's gait",
    "Moderate pace",
    "Bring (out)",
    "Dressage gait",
    "Gait not as fast as a canter",
    "Gait between walk and canter",
    "Pace in dressage",
    "Equestrian's command",
    "Go briskly",
    "Bring (out)",
    "Gait faster than a walk",
    "Compete in the Breeders Crown",
    "Gait slower than a canter",
    "Horse's gait",
    "Brisk pace",
    "Go at a clip",
    "Harness race gait",
    "Bring (out)",
    "Bring (out)",
    "It's faster than a walk",
    "Brisk pace",
    "Easy pace",
    "Gait faster than a walk",
    "Home-run run",
    "Brisk pace",
    "Race pace",
    "Instruction at a horse show",
    "Bring (out)",
    "Brisk step",
    "Gait between walk and canter",
    "Order at a horse show",
    "Pull a sulky, say",
    "Bring (out)",
    "Bring (out)",
    "Gait between walk and canter",
    "Bring (out)",
    "Home-run gait",
    "Jog",
    "Meadowlands pace",
    "Instruction to an equestrian",
    "Bring (out)",
    "Fox or turkey follower",
    "Race pace",
    "Roll (out)",
    "Gait between walk and canter",
    "Jog",
    "Pull a sulky, perhaps",
    "Track race",
    "Certain horse race",
    "Gait faster than a walk",
    "Turkey ___",
    "Race pace",
    "Bring (out)",
    "Fair pace",
    "Certain harness race",
    "Harness race",
    "Hurry",
    "Hurried pace",
    "Race pace",
    "Brisk movement",
    "Meadowlands pace",
    "What horses with sulkies do",
    "Certain horse race",
    "Gait faster than a walk",
    "Horse race",
    "Track pace",
    "The Hambletonian, e.g.",
    "Harness racer's gait",
    "Bring (out)",
    "Track event",
    "Dressage order",
    "Jog",
    "Betting event",
    "Fox or turkey follower",
    "Jog",
    "Take a turn around the track, maybe",
    "Medium pace",
    "Bring (out)",
    "Bring (out)",
    "Race pace",
    "Pony's gait",
    "Bring (out)",
    "Horse race",
    "Bring (out)",
    "Horse's gait",
    "Jog",
    "Horse show command",
    "Harness race",
    "Jog",
    "Ending with fox or dog",
    "Kentucky Futurity event",
    "Sulky contest",
    "Jog",
    "Stepped-up pace",
    "Harness race",
    "Hanover Stake, e.g.",
    "Pompano event",
    "Introduce, with \"out\"",
    "Harness race",
    "Slow down from a run",
    "Jog"
  ],
  "odin": [
    "Norse war god",
    "Husband of Frigg, in Norse mythology",
    "Thor : Thursday :: ___ : Wednesday",
    "For whom the ravens Huginn and Muninn collect intel, in legend",
    "Chief Norse god",
    "Thor's father",
    "Father of Thor",
    "For whom Wednesday is named",
    "God who gave an eye in his search for wisdom",
    "Chief Norse god",
    "One-eyed Norse god",
    "Ruler of Valhalla",
    "Ares : Greek :: ___ : Norse",
    "One-eyed god",
    "Father of Thor",
    "Role in \"Thor,\" 2011",
    "Chief figure in the Eddas",
    "Anthony Hopkins's \"Thor\" role",
    "Ruler over Valhalla",
    "Valhalla V.I.P.",
    "Owner of the horse Sleipnir",
    "God for whom a weekday is named",
    "Norse god of war",
    "Valhalla's ruling god",
    "Anthony Hopkins's role in \"Thor\"",
    "Norse god of war",
    "Valhalla V.I.P.",
    "Figure in the Ynglinga saga",
    "Thor's father",
    "Two-time mythological role for Anthony Hopkins",
    "Sleipnir's master, in myth",
    "Mythological deity with two ravens",
    "God with two ravens on his shoulders",
    "Role in \"Thor\"",
    "Norse war god",
    "Chief Norse god",
    "Asgard ruler",
    "Valhalla ruler",
    "Thor's father",
    "Father of Thor",
    "Frigg's husband",
    "Thor's father",
    "Ruler of Asgard",
    "Asgard ruler",
    "Father of Thor",
    "Valhalla V.I.P.",
    "God of war and magic",
    "Chief Norse god",
    "Role in 2011's \"Thor\"",
    "Aesir ruler",
    "Chief Norse god",
    "Thor's father",
    "Chief Norse god",
    "Chief Norse god",
    "Chief Norse deity",
    "Mount ___ (highest point on Baffin Island)",
    "Husband of Frigg",
    "Valhalla god",
    "Ruler of the Aesir",
    "Figure in the Edda",
    "One-eyed god of myth",
    "Chief Norse god",
    "Chief Norse god",
    "Ruler of the Aesir",
    "Pantheon head",
    "Chief Norse deity",
    "Chief Norse god",
    "Valhalla chief",
    "Mythical dweller across the Rainbow Bridge",
    "Valhalla chief",
    "God of wisdom",
    "Thor's father",
    "God who rode an eight-legged horse",
    "Frigg's husband",
    "Slayer of Ymir, in myth",
    "Father of Thor",
    "God attended by two ravens",
    "Gladsheim palace resident",
    "Presider over banquets of those slain in battle",
    "\"The ruler of the universe\"",
    "Viking's deity",
    "God who presided over the Aesir",
    "God with raven messengers",
    "God who rides an eight-legged horse",
    "God attended by Valkyries",
    "He gave an eye for wisdom",
    "Supreme Norse deity",
    "Norse war god",
    "Father of Balder, the god of goodness",
    "Chief Norse god",
    "Norse deity",
    "God who gave up an eye to drink from the spring of wisdom",
    "Husband of Frigg",
    "Sleipnir's rider",
    "Scandinavian god",
    "Valhalla host",
    "God of war, magic and poetry",
    "Frigg's husband",
    "Cosmos creator, in myth",
    "God attended by two ravens and two wolves",
    "Thor's father",
    "Valhalla V.I.P.",
    "Valhalla V.I.P.",
    "Thor's father",
    "Sleipnir's rider",
    "His horse had eight legs",
    "Norse war god",
    "Sleipnir's master",
    "God of war and poetry",
    "One-eyed overlord",
    "God with an eight-legged horse",
    "Valhalla V.I.P.",
    "Valhalla V.I.P.",
    "Frigg's husband",
    "Norse Zeus",
    "Viking deity",
    "Thor's lord",
    "The All-wise, of myth",
    "Valhalla V.I.P.",
    "Frigg's husband",
    "Frigg's husband",
    "Ymir?Æs defeater",
    "Valhalla dweller",
    "Norse chief",
    "Norse chief",
    "Asgard chief",
    "Aesir member",
    "Norse Zeus",
    "Frigg's husband"
  ],
  "glib": [
    "A bit too articulate, perhaps",
    "A little too silky, maybe",
    "Silver-tongued",
    "Smooth-talking",
    "Smooth-talking",
    "Smooth-talking",
    "Fast-talking",
    "Supersmooth",
    "Smooth-tongued",
    "Smooth-talking",
    "Smooth-talking",
    "Too smooth",
    "Maybe too smooth",
    "Facile",
    "Smooth-talking",
    "Silver-tongued",
    "Insincerely eloquent",
    "Like many smoothies",
    "Fast-talking",
    "Superficial",
    "Smooth to a fault",
    "Smooth-talking",
    "Smooth-talking",
    "Smooth-talking",
    "Talking maybe a little too fast",
    "Smooth",
    "Smooth",
    "Very smooth",
    "Pat",
    "Like a slickster",
    "Smooth-tongued",
    "Insincere, in a way",
    "Very smooth",
    "Slick, in conversation",
    "Smooth-talking",
    "Smooth-talking",
    "Overly smooth",
    "Smooth-talking",
    "Smooth-tongued",
    "Smooth-talking",
    "Smooth",
    "Smooth-talking",
    "Fluent",
    "Silver-tongued",
    "Quick with a reply",
    "Having too-easy answers",
    "Silver-tongued"
  ],
  "sane": [
    "All there",
    "Levelheaded",
    "Rational",
    "Sound",
    "Reasonable",
    "All there",
    "Reasonable",
    "Compos mentis",
    "Sound",
    "Fit to be tried, say",
    "All there mentally",
    "Levelheaded",
    "All together, so to speak",
    "Not a candidate for the invoking of the 25th Amendment, say",
    "Not around the bend",
    "Court psychologist's ruling",
    "Not mad",
    "Mentally together",
    "Rational",
    "Mentally together",
    "All there",
    "Untouched?",
    "Compos mentis",
    "Clear-minded",
    "Common-sense",
    "Not unhinged",
    "Ready to stand trial, in a way",
    "Uncommitted?",
    "Having good balance",
    "Untouched?",
    "Fit to be tried",
    "Not at all nutso",
    "Not loopy",
    "Not wacko",
    "Cogent",
    "Mentally together",
    "Balanced",
    "Compos mentis",
    "Not mad",
    "Having all one's marbles",
    "Compos mentis",
    "All there",
    "Hardly balmy",
    "Compos mentis",
    "Levelheaded",
    "Sound",
    "Not loco",
    "Having good balance",
    "Fit to stand trial",
    "All there",
    "Of sound mind",
    "Sound",
    "Levelheaded",
    "Logical",
    "Rational",
    "Right-minded",
    "All there",
    "Right-minded",
    "Safe's partner",
    "Compos mentis",
    "On one's rocker?",
    "Undisturbed?",
    "Court-appointed psychiatrist's ruling",
    "Compos mentis",
    "Untouched?",
    "Not mad",
    "With it, mentally",
    "All there",
    "Within reason",
    "Not crazy",
    "Of sound mind",
    "All there",
    "Lucid",
    "Fit to stand trial, say",
    "Compos mentis",
    "Perfectly reasonable",
    "Not out of one's mind",
    "Right-minded",
    "Playing with a full deck",
    "Sound in the head",
    "Sound",
    "Unlikely to be committed",
    "Fit to be tried",
    "Commonsensical",
    "Of sound mind",
    "All there",
    "Reasoned",
    "Mentally fit",
    "Commonsensical",
    "Compos mentis",
    "Not crazy",
    "All there",
    "Sound",
    "All there",
    "Levelheaded",
    "All there",
    "Not loco",
    "Thoughtful",
    "Balanced",
    "On one's rocker?",
    "Playing with a full deck",
    "All there",
    "Uncertifiable?",
    "Sensible",
    "All there",
    "Together, so to speak",
    "Compos mentis",
    "Not neurotic",
    "Playing with a full deck",
    "Sensible",
    "Not rash",
    "All there",
    "Rational",
    "Reasoned",
    "All there",
    "Having all one's marbles",
    "Common-sense",
    "Psychologically all there",
    "Having one's marbles",
    "Fit to be tried",
    "Balanced",
    "Not touched",
    "Anti-nuclear testing org.",
    "Understanding",
    "Of sound mind",
    "Compos mentis",
    "Mentally sound",
    "Reasoning",
    "Of sound mind",
    "Sound"
  ],
  "mild": [
    "Like bell peppers, on the Scoville scale",
    "Salsa option",
    "Not so hot",
    "Not spicy",
    "Not so hot",
    "___-mannered",
    "Opposite of spicy",
    "Like red bell peppers",
    "In the 70s, say",
    "Not very spicy",
    "Not so hot",
    "Temperate",
    "Like Clark Kent's manner",
    "Not too spicy",
    "Not sharp",
    "Late spring forecast",
    "Not at all spicy",
    "Having southerly breezes",
    "Gentle",
    "Spring weather forecast",
    "Not burning the mouth much",
    "Not hot",
    "Soft-spoken",
    "Inoffensive",
    "Salsa specification",
    "Adjective in cigarette ads",
    "Easy to take",
    "Fine, temperature-wise",
    "Temperate",
    "Spring weather forecast",
    "Gentle, as breezes"
  ],
  "deep": [
    "Challenger ___ (lowest known point in the earth's oceans)",
    "Like a basso's voice",
    "Like the diving end of a pool",
    "Like the part of a swimming pool with the diving board",
    "Low-pitched",
    "Meaningful",
    "Profound",
    "Very thought-provoking",
    "Profound",
    "Pitched low",
    "Profound",
    "Like the part of a pool with a diving board",
    "Thought-provoking",
    "Not easily understood",
    "Like discussions of metaphysics",
    "Profound",
    "Tough to grasp",
    "How a daring quarterback may throw",
    "Way down",
    "Worthy of pondering",
    "Almost to the outfield wall",
    "Thought-provoking",
    "Shallow's opposite",
    "Many-layered",
    "How a quarterback may throw a ball",
    "Furrow-producing",
    "Profound",
    "Profound",
    "Hard to fathom",
    "Bottomless",
    "Profound",
    "Profound",
    "Tough to fathom",
    "Tough to fathom",
    "Not shallow",
    "Heavy",
    "Seemingly bottomless",
    "Requiring much thought",
    "Bottomless",
    "Shallow's opposite",
    "Not shallow",
    "Hard to comprehend",
    "Like some football passes",
    "Philosophical",
    "Hard to fathom",
    "Bottomless",
    "Bass",
    "Full of meaning",
    "Not shallow",
    "Insightful",
    "Like a billionaire's pockets",
    "Hard to fathom",
    "Like one side of a pool",
    "Difficult to fathom",
    "Like Crater Lake",
    "Very distant, as space",
    "Like the diver's end of the pool",
    "Like a billionaire's pockets",
    "Not shallow",
    "Kind of breath",
    "Like one end of many pools",
    "Ocean",
    "Hard to fathom",
    "Esoteric",
    "Hard to comprehend",
    "Profound",
    "Yawning",
    "Like still waters, maybe",
    "Abstruse",
    "Thought-provoking",
    "Philosophical",
    "Benchley best-seller with \"The\"",
    "Baritone",
    "Basslike",
    "Philosophical",
    "Hard to grasp"
  ],
  "tito": [
    "Boxer Trinidad",
    "One of the Jackson 5",
    "One of the Jacksons",
    "One-fifth of the Jackson 5",
    "Latin music great Puente",
    "One of the Jackson 5",
    "Josip Broz, familiarly",
    "Puente of \"The Mambo Kings\"",
    "Jackson 5 member",
    "Leader of the Partisans in W.W. II",
    "Yugoslav dictator",
    "Post-W.W. II rival of Stalin",
    "Nickname for baseball manager Terry Francona",
    "Jackson sibling",
    "One of the Jackson 5",
    "One of the Jackson 5",
    "Longtime Yugoslav leader",
    "Latin musician Puente",
    "Stalin defier",
    "One of the Jackson 5",
    "Marshal ___, cold war leader",
    "Bandleader Puente",
    "One of the Jacksons",
    "Latin jazz great Puente",
    "Timbales player Puente",
    "A Jackson",
    "Mambo king Puente",
    "Outfielder Francona",
    "W.W. II partisan leader",
    "Tenor Schipa",
    "Defier of Stalin",
    "Bandleader Puente",
    "1950s-'70s Yugoslav leader",
    "He broke with Stalin in 1948",
    "Older brother of Michael Jackson",
    "Marshal ___, Yugoslavian hero",
    "One of the Jacksons",
    "Longtime Yugoslav chief",
    "Italian tenor ___ Schipa",
    "Co-founder of the Non-Aligned Movement, 1961",
    "Boxer Trinidad",
    "Josip Broz, familiarly",
    "Longtime Yugoslav leader",
    "Rebel-turned-national leader",
    "Mambo king Puente",
    "Opponent of Stalin",
    "W.W. II hero",
    "Baseball's Fuentes",
    "He broke with Stalin in 1948",
    "___ Puente, the Mambo King",
    "One of the Jackson 5",
    "A musical Jackson",
    "Former Yugoslav leader",
    "Jackson 5 member",
    "One of the Jackson 5",
    "Josip Broz, familiarly",
    "Bandleader Puente",
    "One of the Jacksons",
    "Josip Broz ___",
    "Former Yugoslavian president",
    "Puente known as \"El Rey\"",
    "Puente or Jackson",
    "Nazi fighter in W.W. II",
    "Yugoslav patriot",
    "Grammy winner Puente",
    "One of the Jackson 5",
    "Jackson 5 member",
    "Anti-Nazi leader of W.W. II",
    "Former Yugoslav chief",
    "Mambo king Puente",
    "Noted Yugoslav patriot",
    "Onetime Yugoslav chief",
    "Former leader of the nonaligned movement",
    "Challenger of Stalin",
    "Maverick Yugoslav leader",
    "Emperor in a Mozart opera",
    "Yugoslav hero",
    "Famous 25-Down",
    "Jackson 5 member",
    "One of the Jackson 5",
    "Yugoslav hero",
    "Slavic hero",
    "Josip Broz",
    "One of the Jackson 5",
    "Mozart opera \"La Clemenza di ___\"",
    "Former Yugoslav chief",
    "Famous marshal",
    "One of the Jackson 5"
  ],
  "hand": [
    "Flush, e.g.",
    "Round of applause",
    "See 41-Across",
    "Round of applause",
    "Unit in the measure of a horse's height",
    "What a person in trouble -- or performing on stage -- wants",
    "With 56-Across, clothing item for the youngest in the family",
    "Helper",
    "Round of applause",
    "Bridge component",
    "Previously owned",
    "Full house, e.g.",
    "Minute or hour marker on a clock",
    "Something traced to draw a turkey",
    "Unit of measurement for a horse's height equivalent to four inches",
    "See 65-Across",
    "Full house, for one",
    "Round of applause",
    "Crew member",
    "What's held in hold'em",
    "Straight, say",
    "Assistance",
    "What a card player is dealt",
    "See 33-Down",
    "See 14-Across",
    "13 cards, maybe",
    "Standing O, say",
    "Bit of aid",
    "Bit of assistance",
    "Full house, e.g.",
    "Flush, for one",
    "Five-digit extension",
    "Applause",
    "Clock sweeper",
    "Flush, say",
    "Acquisition from a deal",
    "52-Across is the best one",
    "Bunch : grapes :: ___ : bananas",
    "Full house, e.g.",
    "Member of a crew",
    "Full house, e.g.",
    "Round of applause",
    "Horse height measure",
    "Card collection",
    "Worker"
  ],
  "aral": [
    "Name seen on the Kazakh/Uzbek border",
    "___ Sea, whose eastern basin has become a desert",
    "With 64-Down, shrinking body of water in Asia",
    "___ Sea (vanishing body of water)",
    "___ Sea (mostly dried-up lake)",
    "Asia's shrunken ___ Sea",
    "Asia's shrunken ___ Sea",
    "With 45-Across, what was once the world's fourth-largest inland body of water",
    "Kazakhstan's ___ Sea",
    "___ Sea (almost dried-up body)",
    "Asia's ___ Sea",
    "Asian sea name / Journalist Logan",
    "Sea whose Wikipedia article is written in the past tense",
    "Name of what was once the world's second-largest saltwater lake",
    "Uzbekistan and Kazakhstan's ___ Sea",
    "___ Karakum (Asian desert)",
    "___ Sea, body greatly diminished by Russian irrigation projects",
    "Asia's disappearing ___ Sea",
    "Asia's ___ Sea",
    "___ Sea (former fourth-largest lake in the world)",
    "Desiccated ___ Sea",
    "Kazakhstan's ___ Sea",
    "Asia's ___ Sea",
    "___ Sea, now four lakes",
    "Asia's disappearing ___ Sea",
    "Nearly dried-up Asian sea",
    "Asia's diminishing ___ Sea",
    "Asia's ___ Sea",
    "Name on a Kazakh map",
    "___ Sea (the \"Sea of Islands\")",
    "With 37-Down, shrunken body of water in Asia",
    "Asia's ___ Sea",
    "___ Sea, Kazakh/Uzbek body of water",
    "___ Sea, victim of Soviet irrigation projects",
    "Asia's ___ Sea",
    "___-Caspian Depression",
    "Kazakhstan's ___ Sea",
    "___ Sea (Asian body)",
    "Asia's shrinking ___ Sea",
    "___ Sea, waters depleted by irrigation projects",
    "Uzbekistan's ___ Sea",
    "Turkic word for \"island\"",
    "___ Sea (now-divided waters)",
    "___ Sea (greatly shrunken body of water)",
    "Asia's diminished ___ Sea",
    "Asia's shrunken ___ Sea",
    "___ Sea (inland body with high salinity)",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Asian sea name",
    "Name on a map of Uzbekistan or Kazakhstan",
    "Asia's shrinking ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "___ Sea (shrinking body of water)",
    "With 113-Across, landlocked waters",
    "District in southern Kazakhstan",
    "Asia's ___ Sea",
    "Kazakh/Uzbek ___ Sea",
    "Asia's shrunken ___ Sea",
    "___ Sea (Amu Darya's outlet)",
    "Asia's ___ Sea",
    "___ Sea, modern site of ship graveyards",
    "Asia's ___ Sea",
    "___ Sea, 2,000-square-mile saltwater lake",
    "Uzbekistan's ___ Sea",
    "Asia's shrunken ___ Sea",
    "It's shrinking in Asia",
    "___ Sea, outlet of the Amu Darya",
    "Salty inland ___ Sea",
    "Asia's ___ Sea",
    "Kazakhstan's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Landlocked ___ Sea",
    "Uzbek sea name",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "___ Sea (shrinking body of water)",
    "Kazakhstan's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Asia's landlocked ___ Sea",
    "___ Sea (giant salt lake)",
    "Uzbekistan's ___ Sea",
    "___ Sea, outlet for the Amu Darya",
    "Asia's ___ Sea",
    "___ Sea, which is really a lake",
    "Asian sea name",
    "Asia's fast-shrinking ___ Sea",
    "49-Down's ___ Sea",
    "Asia's shrinking ___ Sea",
    "Asia's ___ Sea",
    "___ Sea, known in ancient times as Oxianus Lacus",
    "___ Sea (inland body of water)",
    "___ Sea, east of the Ustyurt Plateau",
    "Asia's ___ Sea",
    "___ Sea, east of the Caspian",
    "___ Sea, near the Greater Barsuki Desert",
    "Asia's shrinking ___ Sea",
    "Asia's shrinking ___ Sea",
    "Asia's ___ Sea",
    "It means \"island\" in Kazakh",
    "Asian sea name",
    "___ Sea (saline lake)",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Asia's shrinking ___ Sea",
    "___ Sea (Amu Darya's outlet)",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Landlocked ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Uzbekistan's ___ Sea",
    "Asia's ___ Sea",
    "Sea into which the Amu Darya flows",
    "Asia's ___ Sea",
    "Shrinking Asian sea",
    "Uzbekistan's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Uzbekistan's ___ Sea",
    "___ Sea (Amu Darya's outlet)",
    "Sea bordering Kazakhstan",
    "Asia's ___ Sea",
    "Shrinking sea",
    "Uzbekistan's ___ Sea",
    "Asia's ___ Sea",
    "Uzbekistan's ___ Sea",
    "Sea that's really a lake",
    "Uzbek sea",
    "___ Sea (Amu Darya's outlet)",
    "It's east of the Caspian",
    "Uzbekistan's ___ Sea",
    "Kazakh-Uzbek sea",
    "Sea near Novokazalinsk",
    "Uzbekistan's___Sea",
    "Sea east of the Caspian",
    "Sea east of the Caspian",
    "Asia's ___Sea",
    "34-Down's___Sea",
    "Inland Asian sea",
    "Uzbek sea",
    "Asia's ___ Sea",
    "Sea east of the Caspian",
    "Sea into which the Amu Darya flows",
    "Asian sea",
    "Uzbekistan's ___ Sea",
    "Asia's ___ Sea",
    "Asia's ___ Sea",
    "Saltwater lake",
    "Inland sea east of the Caspian",
    "Sea into which the Amu Darya flows",
    "Uzbekistan's ___ Sea",
    "Sea east of the Caspian"
  ],
  "shay": [
    "Light carriage, informally",
    "Two-wheeled carriage",
    "Two-wheeled carriage",
    "Rural carriage",
    "Vehicle with a folding top",
    "Horse-drawn vehicle",
    "Thing pulled by a \"hoss\"",
    "Country buggy",
    "Two-wheeled carriage",
    "Light carriage",
    "One-horse carriage",
    "Rural carriage",
    "One-horse carriage",
    "Horse-drawn carriage",
    "Rural horse-drawn conveyance",
    "Vehicle pulled by a hoss",
    "Dobbin's tow, maybe",
    "Dobbin pulls one",
    "Open carriage",
    "Traveling carriage",
    "Dobbin's tow, perhaps",
    "Dobbin might pull one",
    "Old-fashioned conveyance",
    "Two-wheeled carriage",
    "Dobbin might pull it",
    "Rural carriage",
    "Open carriage",
    "\"One-hoss\" vehicle",
    "Unpretentious carriage",
    "Carriage, in the country",
    "Open carriage"
  ],
  "card": [
    "Memory ___",
    "King or queen",
    "Ace of spades or queen of hearts",
    "Jokester",
    "Valentine's Day purchase",
    "ID",
    "Verify the age of, in a way",
    "ID",
    "\"We ___\" (convenience store sign)",
    "Check the birth date of, say",
    "*Jokester",
    "Pip's place",
    "Ace of spades, e.g.",
    "Stitch",
    "\"Here's my ___\"",
    "Real character",
    "Determine the age of, in a way",
    "Check for ID, as at a bar",
    "Ace or deuce",
    "Funny fellow",
    "Jester",
    "Funny fellow",
    "Green ___",
    "It may come with a gift",
    "Cut-up",
    "Joker, e.g.",
    "King or queen",
    "Ace or jack",
    "Stop at the door, perhaps",
    "Two or three, but not one",
    "Cutup",
    "Jack or ten",
    "Wit",
    "Jack or 10",
    "Basic bingo item",
    "Cutup",
    "Riot",
    "Joker, e.g.",
    "Jack, for one",
    "Wit",
    "Diamond, e.g.",
    "Check for ID",
    "It can be wild",
    "Ace of spades, e.g.",
    "Ask for identification",
    "Deuce or trey",
    "Word with face or place",
    "Ask to produce proof of age"
  ],
  "saab": [
    "Onetime Scandinavian export",
    "Longtime Swedish automaker",
    "Bygone Swedish auto",
    "Swedish Air Force supplier",
    "Onetime Volvo alternative",
    "Bygone Swedish auto",
    "Bygone Swedish car",
    "Car once advertised with the slogan \"The relentless pursuit of common sense\"",
    "Onetime Volvo competitor",
    "Swedish aircraft giant",
    "Onetime Volvo competitor",
    "Onetime Scandinavian automaker (containing the first part of the \"double quote\")",
    "9-3 automaker",
    "Discontinued Swedish car",
    "Car company once owned by G.M.",
    "9-5, e.g.",
    "9-5 maker",
    "Swedish Air Force supplier",
    "Automaker that introduced heated front seats",
    "Car from the same country as Volvo",
    "What a 9-5 worker worked on?",
    "Former Swedish subsidiary of General Motors",
    "Auto with a \"9\" in almost all its model names",
    "Automaker with the slogan \"Born from jets\"",
    "\"Born from jets\" automaker",
    "Discontinued Swedish car",
    "Swedish manufacturer of the 90, 900 and 9000",
    "Car with the numeral 9 in all its model names",
    "9-5, e.g.",
    "Volvo rival",
    "\"Move your mind\" sloganeer",
    "Swedish auto",
    "\"Born from jets\" sloganeer",
    "Volvo rival",
    "\"Born from jets\" automaker",
    "Sad-sounding car company?",
    "\"Born from jets\" sloganeer",
    "Car whose name is an acronym",
    "9-3 and 9-5 car manufacturer",
    "Car with a griffin in its logo",
    "Car company that originated as an airplane manufacturer",
    "Volvo rival",
    "Swedish import",
    "Volvo rival",
    "\"Born from jets\" company",
    "Car with an acronymic name",
    "Fashion designer Elie",
    "Car driven by Seinfeld on \"Seinfeld\"",
    "\"Born from jets\" sloganeer",
    "General Motors subsidiary",
    "Company with the slogan \"born from jets\"",
    "Volvo rival",
    "Carmaker since 1949",
    "Swedish auto",
    "Volvo rival",
    "Swedish export",
    "Auto import",
    "General Motors make, now",
    "Car with a griffin in its logo",
    "Volvo rival",
    "Auto import",
    "Car since 1949",
    "Swedish auto",
    "General Motors division",
    "Swedish car",
    "Swedish auto",
    "Swedish import",
    "Car since 1949",
    "Volvo rival",
    "9000 automaker",
    "Big Swedish manufacturer",
    "Auto founded by an aircraft company",
    "9000 Turbo, e.g.",
    "Car launched by an aeronautics company",
    "Auto with models 900 and 9000",
    "Auto with an acronymic name",
    "Product of Sweden",
    "Stockholm sedan",
    "Swedish auto",
    "Volvo rival",
    "Audi rival"
  ],
  "atat": [
    "Rat-___",
    "Giant walking combat vehicle in \"Star Wars\" films",
    "Rat-___",
    "Juggernaut fighting vehicle in \"Star Wars\" movies",
    "Rat-___",
    "Giant four-legged combat walker in \"Star Wars\" films",
    "Rat-___",
    "Rat-___",
    "Elephantlike walker in \"The Empire Strikes Back\"",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat follower",
    "Rat follower?",
    "Rat follower",
    "Rat tail?",
    "Rat tail?",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat tail?",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat tail?",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat-___",
    "Rat ___ (knocking)",
    "Rat ___",
    "Rat chaser?",
    "Rat-___"
  ],
  "zale": [
    "Jewelry store eponym",
    "Big name in jewelry retail",
    "Big name in retail jewelry",
    "___ Corporation (jewelry retail giant)",
    "Middleweight champ Tony",
    "40's boxer Tony"
  ],
  "ells": [
    "What jelly rolls are filled with?",
    "Wings",
    "Some pipe joints",
    "Some plumbing joints",
    "Building annexes",
    "Lawful ends?",
    "Bracket shapes",
    "The black square chunk in front of 55-, 60- and 63-Across, and others",
    "Some pipe joints",
    "Duckbill's tail?",
    "Some add-ons",
    "Blueprint additions",
    "Right-angled additions",
    "Pieces with 90-degree bends",
    "Right-angle bends",
    "Some wings",
    "Some brackets",
    "Corner shapes",
    "Some annexes",
    "Direction-changing pipes",
    "Right-angle joints",
    "Building additions",
    "90 degree pipe joints",
    "Building additions",
    "Building extensions",
    "Wings",
    "Wright wings",
    "Architects' annexes",
    "Right angles",
    "Building additions",
    "Room extensions",
    "Some wings",
    "Right-angle bends",
    "Some add-ons",
    "Some bent pipes",
    "Building annexes",
    "Turning points",
    "Building additions",
    "Conduit bends",
    "Wings",
    "Some joints",
    "Corner pieces",
    "Wings",
    "New wings",
    "Some bends",
    "Corner pieces",
    "Building wings",
    "Some extensions",
    "Some annexes",
    "Some pipe joints",
    "Extensions",
    "Some bends",
    "They're seven positions after today's theme",
    "Add-ons",
    "Building additions",
    "Some bends",
    "Building extensions",
    "Turning points",
    "Additions",
    "Connecting pipes",
    "Right-angle joints",
    "Wings",
    "Wren's wings",
    "Angled pieces",
    "Building wings",
    "Home additions",
    "Pipe bends",
    "Plumbing joints",
    "Office workstations, maybe"
  ],
  "mare": [
    "Moonscape feature",
    "Mother with a stable home?",
    "Stallion's mate",
    "Mother in a stable family?",
    "Foal's mother",
    "Sea of Tranquillity, e.g.",
    "Mother with a foal",
    "Stud finder?",
    "Stallion's mate",
    "No Triple Crown winner ever",
    "Mother horse",
    "Farm female",
    "Certain dam",
    "Horse with a foal",
    "Moon feature",
    "Dark area on the moon",
    "Moon feature",
    "Colt's mother",
    "Foal's mother",
    "One that may 28-Down",
    "Flicka, e.g.",
    "Stud farm visitor",
    "Four-legged female",
    "Part of a moonscape",
    "Foal's mother",
    "Dark region of the moon",
    "Nag, maybe",
    "Colt carrier?",
    "Stallion's mate",
    "Mate for \"my friend\" Flicka",
    "Colt's mother",
    "Colt producer",
    "Filly's mother",
    "Stallion's mate",
    "Stud's mate",
    "Moon sight",
    "Mule's mother",
    "Mature filly",
    "Foal's mother",
    "Stallion's mate",
    "Stallion's mate",
    "Colt coddler",
    "Dark area on the moon",
    "Filly's mother",
    "Stallion's mate",
    "Stallion's mate",
    "Genuine Risk, for one",
    "Dam",
    "Moon feature",
    "Stallion's mate",
    "Stallion's mate",
    "Mate for 60-Down",
    "Stable mate",
    "Dream, in combos",
    "Old gray animal?"
  ],
  "seas": [
    "Vast expanses",
    "Sail the seven ___",
    "Aegean and Baltic, for two",
    "Expansive septet",
    "Caspian and Caribbean",
    "Sizable bodies of water",
    "Caribbean and Caspian",
    "Lots and lots (of)",
    "They're often high, but never dry",
    "Word after high, heavy or seven",
    "Freedom of the ___",
    "Some of them are described as red and yellow, but not orange",
    "They're blue on maps",
    "High ___",
    "Blue swaths on maps",
    "High ___",
    "Wet septet",
    "Rising concerns in modern times?",
    "Vast amounts",
    "South ___ (Polynesia's locale)",
    "A vast quantity",
    "Expanses of H2O",
    "Vast expanses",
    "Sail the seven ___",
    "High ___",
    "Caribbean and others",
    "Literally, with 20-Across, ski resort purchases",
    "Adriatic and others",
    "Noted septet",
    "Mediterranean and Caribbean",
    "Poseidon ruled them",
    "Vast expanses",
    "Caribbean and Mediterranean",
    "They can get choppy",
    "Clive Cussler novel settings",
    "They're heavy during storms",
    "Their arms are wet",
    "Seven things for a sailor",
    "High ___",
    "See 1-Across",
    "They may be high or heavy",
    "Proverbial heptad",
    "Word in many cruise ships' names",
    "Seven ___",
    "Huge quantities",
    "Wet septet",
    "Salty septet",
    "Huge expanses",
    "Seven ___",
    "Cruise ship Empress of the ___",
    "Salty septet",
    "Neptune's realm",
    "Large amounts",
    "Seven ___",
    "Classic septet",
    "Huge expanses",
    "Shark pools?",
    "They can be choppy",
    "Domain of the goddess Tethys",
    "Rafts",
    "The world has seven of them",
    "Classical septet",
    "Caribbean and others",
    "Geographical septet",
    "Where the buoys are",
    "They can get rough",
    "Salty septet",
    "Limitless quantities",
    "Wet septet",
    "Limitless quantities",
    "Caribbean and others",
    "Word with high or seven",
    "Salty septet",
    "The Red and the Black",
    "Wet septet",
    "Classical heptad",
    "Salty septet",
    "Geographical heptad",
    "Half___over (drunk)",
    "Seven___",
    "Saline septet",
    "They're Red, White...and blue",
    "Saline seven"
  ],
  "shep": [
    "News anchor Smith, informally",
    "Smith of Fox News",
    "Record producer Pettibone",
    "Generic name for a herding dog",
    "Fox News anchor Smith",
    "Herding dog, informally",
    "Generic collie name",
    "\"Old ___\" (country music classic)",
    "Common name for a working dog",
    "Name for a farm dog",
    "\"George of the Jungle\" elephant",
    "Name for a big dog",
    "\"George of the Jungle\" elephant",
    "Bandleader Fields",
    "Faithful dog, affectionately",
    "Leader of the Limelites, in 60's pop",
    "Popular dog name",
    "Bandleader Fields",
    "Nickname for a big dog",
    "Popular dog name"
  ],
  "sisi": [
    "Enthusiastic assent in Spanish",
    "Enthusiastic Spanish assent",
    "With 10-Down, enthusiastic south-of-the-border assent",
    "Enthusiastic Spanish assent",
    "Egyptian president Abdel Fattah el-___",
    "Enthusiastic assent in Mexico",
    "\"Absolutely!,\" to Alejandro",
    "Assent in Andalusia",
    "Mexican's emphatic assent",
    "\"You bet!,\" in Yucatan",
    "Enthusiastic Spanish assent",
    "\"Absolutely, amigo!\"",
    "Enthusiastic assent in Madrid",
    "Enthusiastic Spanish assent",
    "Pedro's emphatic assent",
    "\"They say\" it in Spain, in an old Andrews Sisters hit",
    "Egyptian president Abdel Fattah el-___",
    "Emphatic assent, in Baja",
    "\"Of course, Jorge!\"",
    "\"But of course, amigo!\"",
    "\"Of course, senor!\"",
    "\"Oui, oui,\" across the Pyrenees",
    "Emphatic Spanish assent",
    "\"Of course, Senor!\"",
    "Approval in Rome",
    "Aye-aye relative?",
    "\"Say ___\" (1940 hit)",
    "\"You bet!,\" south of the border",
    "\"Say ___\" (1940 Glenn Miller hit)",
    "Senor's \"Positively!\"",
    "\"Of course, senor!\"",
    "\"You bet!\" in Yucatan",
    "\"You betcha, Bartolome!\"",
    "Mexican accord?",
    "Acapulco approval",
    "No-no's opposite?",
    "Asuncion assent",
    "Opposite of \"No, no\"",
    "\"Sure thing\" for Speedy Gonzales",
    "Mexican's assent",
    "\"Of course, Jorge!\"",
    "Juan's emphatic assent",
    "\"Say ___\" (1940 Andrews Sisters hit)",
    "\"Certainly!,\" south of the border",
    "Assent in Acapulco",
    "Answer to a senor",
    "\"Aye aye, capitan!\"",
    "Senor's emphatic yes",
    "Hoped-for reply in \"Spanish Eyes\"",
    "Answer to a senor",
    "Spanish ayes",
    "\"You said it!,\" in Sonora",
    "Senor's affirmative",
    "Emphatic assent",
    "Opposite of \"no no\"",
    "\"For sure, Fernando!\"",
    "Acapulco acceptance",
    "Spanish ayes",
    "Spanish \"ayes\"",
    "Emphatic aprobacion",
    "No-no's opposite?",
    "Senor's emphatic yes",
    "Part of a Mexican-American accord?",
    "Pedro's affirmative",
    "Reply to a senor",
    "Enthusiastic reply in Mexico",
    "Asuncion assent",
    "Spanish ayes",
    "Definitely, south of the border",
    "\"Yes, indeed,\" in Madrid",
    "Acapulco assent"
  ],
  "berg": [
    "On the surface it might not look like much",
    "Name suffix meaning \"mountain\"",
    "North Atlantic shipping menace",
    "Cartoonist Dave famous for \"The Lighter Side of ...\"",
    "The Titanic's undoing",
    "Block at sea",
    "Rescue site for a polar bear",
    "Floating mass in the North Atlantic",
    "What the Titanic had a disastrous encounter with",
    "Danger for a submarine",
    "Titanic's undoing",
    "Something coming off the shelf?",
    "Former piece of an ice shelf",
    "Chunk of ice in the ocean",
    "Floating block of ice",
    "Something that might fall off the shelf?",
    "North Atlantic hazard",
    "\"Lulu\" opera composer",
    "Belated observation of 4/14/12",
    "Something that's fallen off a shelf?",
    "Sea lane danger",
    "Arctic Ocean sighting",
    "It may come off the shelf",
    "Ship hazard",
    "Titanic's undoing",
    "It might come off the shelf",
    "Maritime danger",
    "Floating ice mass",
    "Sight in the Arctic Ocean",
    "Ice in the sea",
    "Mad magazine cartoonist Dave",
    "Arctic floater",
    "It keeps its head above water",
    "Helmsman's headache",
    "Only about 10% of it is visible",
    "Big chip off the old block?",
    "Shipping hazard",
    "BAFFIN BAY SIGHT",
    "Sight near Antarctica",
    "Arctic Ocean hazard",
    "Composer Alban",
    "\"Lulu\" composer",
    "Chunk in the Arctic Ocean",
    "Cartoonist Dave of Mad",
    "Shipping hazard",
    "Arctic floater",
    "\"Lulu\" composer",
    "Castoff from an ice shelf",
    "Cold pack",
    "\"Lulu\" composer",
    "Arctic Ocean sighting",
    "Ice block",
    "It may go with the floe",
    "Sub sinker, maybe",
    "\"Wozzeck\" composer",
    "Chip off the old ice block",
    "\"Wozzeck\" composer",
    "North Sea floater",
    "North Atlantic sighting",
    "Wayward ice",
    "Glacier Bay sight"
  ],
  "abes": [
    "Five-spots",
    "Exchange for a tenner",
    "$5 bills, slangily",
    "Fivers",
    "Fins",
    "Two of them are worth a sawbuck",
    "What you might get by breaking 4-Down",
    "Fivers",
    "Lincoln and others",
    "Fins",
    "Two of them make a sawbuck",
    "Fives",
    "Five-spots",
    "$5 bills, slangily",
    "Five-spots",
    "Fivers",
    "Five-spots",
    "Sawbuck halves",
    "Lincoln and others",
    "Fivers",
    "Ribicoff and Lincoln, familiarly",
    "Five-dollar bills, slangily",
    "Lincoln and Vigoda",
    "Vigoda and Fortas",
    "Lincoln and others",
    "Lincoln and others",
    "Justice Fortas and others",
    "Lincoln and others",
    "Lincoln and others, informally",
    "Lincoln and others",
    "Beame and Saperstein",
    "Fortas, Vigoda, etc.",
    "Fortas and Vigoda",
    "Former Sen. Ribicoff et al.",
    "Beame et al.",
    "Actor Vigoda and others",
    "Fortas and Lincoln",
    "Beame and Burrows",
    "Fortas and Burrows",
    "Lincoln and Burrows",
    "Burrows et al.",
    "Lincoln and others",
    "\"Honest\" one and namesakes",
    "Lincoln and Vigoda",
    "Playwright Burrows et al."
  ],
  "pyro": [
    "Prefix for fireworks",
    "Arsonist, in brief",
    "Match player?",
    "One who sends things up?",
    "Firebug",
    "Firebug",
    "Firebug",
    "Lighting expert?",
    "Fire lover, briefly",
    "One who delights in starting fires, informally",
    "Fire starter?",
    "Ignition system expert?",
    "Expert in match play, for short?",
    "Firebug",
    "Fire starter?",
    "Match player?",
    "Lighter, briefly?",
    "Fire fancier, for short",
    "Fire starter?",
    "Lighting specialist, informally?",
    "One looking for a match?",
    "One engaged in match play?"
  ],
  "tope": [
    "Imbibe",
    "Have a large quantity of liquor",
    "Emulate a 2-Down",
    "Bend an elbow",
    "Imbibe",
    "Be a tippler",
    "Raise one's spirits?",
    "Empty bottles",
    "Go for a few rounds?",
    "Drink to excess",
    "Imbibe",
    "Toss more than a few back",
    "Hit the bottle",
    "Drink heavily",
    "Lift one's spirits?",
    "Hit the bottle",
    "Bibble",
    "Hit the sauce",
    "Guzzle",
    "Drink like a fish",
    "Guzzle",
    "Binge at the bar"
  ],
  "esse": [
    "\"___ quam videri\" (\"To be rather than to seem\")",
    "To be: Lat.",
    "Ab ___ (absent, in Latin)",
    "\"___ quam videri,\" state motto of North Carolina",
    "Auctor ___ (authorize, in legalese)",
    "De bene ___ (legal phrase)",
    "Latin 101 word",
    "Caesar's \"to be\"",
    "To be, to Augustus",
    "Latin 101 verb",
    "To be, to Tacitus",
    "North Carolina motto opener",
    "Latin 101 verb",
    "To be, to Brutus",
    "Latin 101 verb",
    "In ___ (actually)",
    "\"To be,\" in Latin",
    "Latin \"to be\"",
    "In ___ (actually)",
    "\"To be,\" to Brutus",
    "To be in ancient times?",
    "To be, to Tiberius",
    "Being, to Claudius",
    "Latin 101 verb",
    "In ___ (existing)",
    "Ovidian infinitive",
    "De bene ___ (legal phrase)",
    "To be, to Brutus",
    "Forum being",
    "Actual, after \"in\"",
    "In ___ (actually)",
    "Latin 101 verb",
    "Latin 101 verb",
    "\"To be,\" to Titus",
    "They, in Rome",
    "Ab ___ (absent: Lat.)",
    "To be, in old Rome",
    "\"To be,\" to Brutus",
    "Catonian infinitive",
    "Latin 101 verb",
    "To be, to Augustus",
    "Latin 101 verb",
    "In ___ (actual)",
    "Latin \"to be\"",
    "Latin being",
    "\"___ quam videri\" (North Carolina's motto)",
    "Tiberius' \"to be\"",
    "Ab ___ (absent: Lat.)",
    "To be, to Brutus",
    "Latin 101 verb",
    "To be, to 33-Down",
    "Forum infinitive",
    "De bene ___",
    "To be, to Brutus",
    "To be, to Claudius",
    "Latin 101 verb",
    "Latin 101 verb",
    "Verb from which \"sum\" is derived",
    "Latin 101 verb",
    "Sum derives from it",
    "Ab ___ (absent)",
    "Ovidian infinitive",
    "In ___ (actually)",
    "Start of North Carolina's motto",
    "De bene ___ (legal phrase)",
    "Latin infinitive",
    "Being, to Brutus",
    "Caesarean being",
    "Latin 101 verb",
    "Forum infinitive",
    "Forum infinitive",
    "De bene ___ (of conditional validity)",
    "Verb of which \"sum\" is a form",
    "In ___ (existing)",
    "To be, to Brutus",
    "Start of North Carolina's motto",
    "To be, in old Rome",
    "Being, to Brutus",
    "To be: Lat.",
    "Sum, ___, fui",
    "Latin 101 verb",
    "To be, to Livy",
    "To be, to Brutus",
    "Being: Lat.",
    "Latin infinitive",
    "Start of North Carolina's motto",
    "Start of North Carolina's motto",
    "Existence, to Claudius",
    "De bene ___ (legal phrase)",
    "To be, to Brutus",
    "De bene ___ (provisionally)",
    "Start of North Carolina's motto",
    "Ovidian infinitive",
    "Latin 101 verb",
    "To be at the Colosseum",
    "Latin 101 verb",
    "In ___ (really)",
    "In ___ (actually)",
    "\"___ est percipi\" (Berkeley principle)",
    "Latin 101 verb",
    "In ___ (actual)",
    "Latin 101 verb",
    "Seneca's being",
    "In ___ (existing)",
    "Latin 101 word",
    "In ___ (actually)",
    "To be, to Brutus",
    "\"___ est percipi\" (old Latin motto)",
    "Start of North Carolina's motto",
    "Start of North Carolina's motto",
    "In ___ (actually)",
    "Being",
    "North Carolina motto starter",
    "Latin 101 verb",
    "In ___ (existing)",
    "Latin I word",
    "___ Quam Videri (North Carolina motto)",
    "To be, to Tiberius",
    "In ___ (existing)",
    "North Carolina motto beginning",
    "Cicero's existence",
    "___ in re: Lat.",
    "To be, in ancient Rome",
    "Sum, ___, fui",
    "___ quam videri (N.C.'s motto)",
    "To be, in 49-Down",
    "De bene ___",
    "In ___ (actually)",
    "To be, in old Rome",
    "Start of North Carolina's motto",
    "Caesar's existence",
    "Start of North Carolina's motto",
    "Being at the Forum",
    "To be, to Tiberius",
    "Start of North Carolina's motto",
    "Latin infinitive",
    "Start of North Carolina's motto",
    "In ___ (actually)",
    "Actual existence",
    "Latin I infinitive",
    "Actual being",
    "To be, in old Rome",
    "Start of North Carolina's motto",
    "To be, for students of 17-Across",
    "To be, to Cato",
    "Start of North Carolina's motto",
    "Being",
    "To be, in old Rome",
    "Existentialist concern",
    "They, in Trieste",
    "Being, to Caesar",
    "In ___ (existing)",
    "In ___ (actually)",
    "\"___ quam videri\" (North Carolina's motto)",
    "To be, to Brutus",
    "Actual being",
    "They, in Trieste",
    "Start of North Carolina's motto",
    "In ___ (actually)",
    "Being, to Brutus",
    "In___(actually)",
    "Being, to Brutus",
    "Start of North Carolina's motto",
    "To be, to Tacitus",
    "In___ (actually)",
    "Existence: Lat.",
    "Being, to Brutus",
    "In ___ (actually)",
    "First word of North Carolina?Æs motto",
    "In ___ (actually)",
    "In ___ (actually)",
    "___ quam videri (North Carolina motto)",
    "To be: Lat.",
    "Latin being",
    "Latin life",
    "To live, to Livy"
  ],
  "dolt": [
    "Meathead",
    "Stumblebum",
    "Not the brightest bulb in the pack",
    "Blockhead",
    "Fool",
    "Bonehead",
    "Noodlehead",
    "Nitwit",
    "Bozo",
    "Doofus",
    "Dummy",
    "Imbecile",
    "Lamebrain",
    "Fool",
    "Dimwit",
    "Lunkhead",
    "Definitely no genius",
    "Blockhead",
    "One isn't sharp",
    "Dummkopf",
    "Dummy",
    "Chowderhead",
    "Dimwit",
    "Ninny",
    "Dunce cap earner",
    "Blockhead",
    "Doofus",
    "Fumble-fingers",
    "Ninny",
    "Moron",
    "One who isn't swift",
    "Buffoon",
    "Chowderhead",
    "Airhead",
    "Airhead",
    "Nincompoop",
    "Blockhead",
    "Numbskull",
    "Yo-yo",
    "Cabbagehead",
    "Blockhead",
    "Blockhead",
    "Noodlehead"
  ],
  "lyra": [
    "Constellation resembling a harp",
    "Harp-shaped constellation",
    "Harp-shaped constellation",
    "Vega's constellation",
    "Harp-shaped constellation",
    "Constellation named for a stringed instrument",
    "Constellation next to Hercules",
    "Neighbor of Hercules",
    "Neighbor of Draco and Hercules",
    "Vega's constellation",
    "Constellation between Cygnus and Hercules",
    "Constellation with a music-related name",
    "Neighbor of Cygnus",
    "Neighbor of Draco",
    "Vega's constellation",
    "Constellation next to Hercules",
    "Neighbor of Hercules in the night sky",
    "Vega's constellation",
    "Vega's constellation",
    "Neighbor of Cygnus",
    "Vega's constellation",
    "Vega's constellation"
  ],
  "onto": [
    "Not duped by",
    "Jay-Z's \"___ the Next One\"",
    "Unfooled by",
    "Follower of \"catch\" or \"latch\"",
    "Not fooled by",
    "More than suspicious of",
    "Aware of",
    "\"Hold ___ your hat!\"",
    "More than suspicious of",
    "Not falling for",
    "\"I'm ___ you!\"",
    "Aware of, as a scheme",
    "Latch ___",
    "Not bamboozled by",
    "Not fooled by",
    "\"___ victory!\"",
    "Able to see right through",
    "Latch ___ (seize)",
    "Latch ___ (grip)",
    "Not fooled by",
    "Not fooled by",
    "Not deceived by",
    "Not falling for",
    "Aware of",
    "Not bamboozled by",
    "More than suspicious of",
    "Not tricked by",
    "Be ___ something",
    "Words with hold or pass",
    "Word after lock or glom",
    "Not conned by",
    "\"___ the next\"",
    "Not fooled by",
    "Not fooled by",
    "Undeceived by",
    "Seeing through",
    "Latch ___",
    "Not deceived by",
    "Aware of",
    "Not fooled by",
    "\"I'm ___ you!\"",
    "Aware of",
    "Not fooled by",
    "Not fooled by",
    "Able to see through",
    "Well aware of",
    "Aware of",
    "Not had by",
    "Not duped by",
    "Not suckered by",
    "Wise about",
    "Not conned by",
    "Catching ___",
    "Not duped by",
    "Not taken by",
    "Aware of",
    "Not fooled by",
    "\"I'm ___ you!\"",
    "No longer fooled by",
    "\"I'm ___ you!\"",
    "Hold ___ (keep)",
    "Savvy about",
    "Latch ___",
    "Aware of",
    "Not fooled by",
    "Knowledgeable about",
    "Not deceived by",
    "Lock or luck follower",
    "Not tricked by",
    "Words after catch or hang",
    "Not taken in by",
    "Seeing right through",
    "Not had by",
    "\"Hold ___ your hat!\"",
    "Aware of",
    "\"I'm ___ you!\"",
    "Hip about",
    "Able to see through",
    "Aware of",
    "Not duped by",
    "Not conned by",
    "Aware of",
    "Aboard",
    "See 24-Across",
    "Not fooled by",
    "Not duped by",
    "Aware of",
    "Not taken by",
    "Not fooled by",
    "Come ___",
    "Aware of",
    "Stumble ___",
    "\"I'm ___ you!\"",
    "Not duped by",
    "Not fooled by",
    "Knowing of",
    "Hang ___",
    "Come ___ (proposition)",
    "Hang ___",
    "Latch ___",
    "Seeing through the deception of",
    "Mindful of",
    "Not fooled by",
    "Aware of",
    "Not duped by",
    "Able to see right through",
    "Catch ___",
    "Aware of",
    "Aware of",
    "Not taken in by",
    "Not taken in by",
    "Savvy about",
    "Savvy about",
    "Catch ___ (start to understand)",
    "Able to see right through",
    "Able to see through",
    "Not duped by",
    "Cognizant of",
    "Aware of",
    "Not fooled by",
    "Savvy about",
    "Not had by",
    "Aboard",
    "Not fooled by",
    "?ô___ victory!?ö",
    "Not fooled by",
    "Aware of",
    "Aware of",
    "Not fooled by",
    "Catch or latch follower",
    "Not fooled by",
    "Cognizant of",
    "Not had by",
    "Aware of",
    "Aware of",
    "Not deceived by",
    "Sign ___ (accept, as a program)",
    "\"Hold ___ your hat!\"",
    "Suspicious of",
    "Latch ___",
    "Aware of",
    "Aware of",
    "Aware of",
    "Not duped by",
    "\"I'm ___ you!\"",
    "Not fooled by",
    "\"I'm ___ you!\"",
    "Not had by",
    "Aware of",
    "Not fooled by",
    "Aware of",
    "Aware of",
    "Enlightened about",
    "Aware of",
    "\"I'm ___ you!\"",
    "Aware of",
    "Not fooled by",
    "Not fooled by",
    "Knowing about",
    "What might follow catch or latch",
    "Not duped by",
    "Aware of",
    "Fully aware of",
    "Aware of",
    "Latch ___ (get)",
    "\"I'm ___ your tricks!\"",
    "Not deceived by",
    "Aware of",
    "Get ___ (board)",
    "\"I'm ___ you now!\"",
    "Aware of",
    "\"I'm ___ you!\"",
    "Word after catch or hang",
    "Not fooled by",
    "Aware of",
    "Catch ___",
    "Not fooled by",
    "Catch ___ (start to get)",
    "Aware of",
    "Aware of",
    "Not tricked by",
    "Not fooled by",
    "Not taken in by",
    "\"I'm ___ you!\"",
    "Aware of",
    "Aware of",
    "Aware of",
    "Informed about",
    "Cognizant of",
    "\"I'm___you!\"",
    "Latch___",
    "Wise about",
    "Aware of",
    "Knowing about",
    "Savvy about",
    "Latch ___ (get)",
    "Aware of",
    "Hip about",
    "Aware of",
    "Conscious of",
    "Aware of",
    "Savvy about",
    "Aware of",
    "Knowing",
    "Latch ___",
    "Aware of",
    "\"___ victory!\""
  ],
  "mika": [
    "\"Morning Joe\" co-host Brzezinski",
    "\"Morning Joe\" co-host Brzezinski",
    "Brzezinski of MSNBC"
  ],
  "odes": [
    "Lyric verses",
    "\"To ...\" things",
    "They can be Sapphic",
    "Some are Sapphic",
    "Lyric poems",
    "Volume from Horace",
    "Horatian compilation",
    "Some tributes",
    "Dedicated poems",
    "Extolling poetry",
    "They take dedication to write",
    "Coleridge's \"Dejection\" and others",
    "Collection by Horace",
    "Things showing dedication?",
    "Laudatory lines",
    "___ of Solomon",
    "Poetic tributes",
    "___ of Solomon (part of the Apocrypha)",
    "Some works by poets laureate",
    "Horatian creations",
    "Portion of Alexander Pope's work",
    "Uplifting works",
    "Poems of admiration",
    "Works toward one's passion?",
    "\"To ...\" things",
    "\"To Autumn\" and others",
    "Poems whose titles often start \"To a ...\"",
    "Dryden's \"Alexander's Feast\" and others",
    "They praise in non-prose",
    "Uplifting literature",
    "Laudatory poetry",
    "Certain Confucian compilation",
    "\"The ___ of Horace\"",
    "___ of Solomon",
    "Sapphic works",
    "Works of Keats",
    "Dedicated poems",
    "___ of Solomon",
    "Sometimes-sung pieces",
    "Horatian collection",
    "Poetic paeans",
    "Dedicated works",
    "Wordsmiths' paeans",
    "Keats collection",
    "\"To Spring\" and others",
    "Words from Wordsworth",
    "Output of Thomas Gray",
    "They may have kings as subjects",
    "Poems whose titles often start \"To a ...\"",
    "Feats of Keats",
    "Written paeans",
    "Certain panegyrics",
    "Some tributes",
    "Praiseful works",
    "Poems by 5-Down",
    "Works of Horace",
    "Some homages",
    "Lofty verses",
    "High-flown poetry",
    "\"___ et Ballades\" (Victor Hugo work)",
    "Some poems from 52-Across",
    "Poetry volume",
    "Works of Horace",
    "Some of Keats's feats",
    "Dedicated offerings",
    "Poems of praise",
    "High-flown tributes",
    "Laudatory poems",
    "Lyric poems",
    "Pablo Neruda's \"___ to Opposites\"",
    "Tributes in verse",
    "Pablo Neruda's \"___ to Common Things\"",
    "Poems of praise",
    "Features of Sophocles plays",
    "Some works for heroes",
    "Keatsian works",
    "___ of Solomon",
    "Pablo Neruda's \"Elemental ___\"",
    "Dedicated literature",
    "Horatian verses",
    "Lyrical works",
    "They're dedicated",
    "Olympian ___ (classical works)",
    "Works of 9-Down",
    "Laudatory poems",
    "Pindaric pieces",
    "Poetical tributes",
    "Heading in a Keats volume",
    "Dedicated compositions",
    "Expressions of praise",
    "Sapphic verses",
    "Sapphic works",
    "Pablo Neruda's \"___ to Common Things\"",
    "Lyric poems",
    "Volume by Horace",
    "Wordsworth works",
    "Lyric poems",
    "Some Keats poems",
    "Volume of Horace",
    "Some tributes",
    "Sapphic works",
    "Pablo Neruda's \"___ to Common Things\"",
    "Lofty lines",
    "Pindar works",
    "Poetic tributes",
    "Some tributes",
    "Praiseful poems",
    "Confucius's \"Book of ___\"",
    "Pablo Neruda works",
    "Pindar writings",
    "Some tributes",
    "Lyrical lines",
    "Lyric verses",
    "Lyric poems",
    "Lyric poems",
    "Laudatory lyrics",
    "Lyric poems",
    "Old-fashioned verses",
    "Poetic tributes",
    "___ of Solomon (book of the Apocrypha)",
    "Paeans",
    "Pindar volume",
    "Horace volume",
    "Praiseful poems",
    "Literature class readings",
    "Lyric poetry",
    "Lyrical tributes",
    "\"To the Moon\" and others",
    "They often begin with \"To\"",
    "___ of Solomon (noncanonical book)",
    "Works of Shelley",
    "Keatsian works",
    "Lyrical verses",
    "Keats creations",
    "Keats works",
    "Poems with \"To\" in their titles",
    "Quaint verses",
    "Words from Wordsworth",
    "Horace tome",
    "Thomas Gray works",
    "Keats pieces",
    "Confucius's \"Book of ___\"",
    "Lofty lines",
    "Keats specialties",
    "Purcell's \"___ and Welcome Songs\"",
    "Canticles",
    "Ceremonious poetry",
    "Poems of praise",
    "Words from Wordsworth",
    "Keatsian works",
    "Lofty works",
    "Lyric poems",
    "Lyric poems",
    "Some Pablo Neruda works",
    "Poems of praise",
    "Paul Claudel's \"Cinq Grandes ___\"",
    "They can be Horatian",
    "Horatian output",
    "Some old poetry",
    "\"___ of Solomon\"",
    "Old-fashioned poems",
    "Horace volume",
    "Tributes in verse",
    "Works of Anacreon",
    "Horace title",
    "Poet's output",
    "Horace volume",
    "Pindaric verses",
    "Keats works",
    "Works of Sappho",
    "Poetical tributes",
    "Horace title",
    "Words inspired by Erato",
    "Browning works",
    "Laudatory works",
    "Works of Bacchylides",
    "\"To Autumn\" and others",
    "Lyric poems",
    "Some of Wordsworth's words",
    "Amatory writing",
    "Ovid products",
    "Hugo works",
    "Flowery verses",
    "Shelley output",
    "Wordsworth works"
  ],
  "leek": [
    "Soup bulb",
    "Vegetable related to garlic",
    "Vegetable paired with potato in a soup",
    "Potato accompanier in soup",
    "What the French call \"poor man's asparagus\"",
    "Vichyssoise ingredient",
    "Vichyssoise vegetable",
    "Onion relative used in soups",
    "Close relative of elephant garlic",
    "Vichyssoise vegetable",
    "Onion relative used in soups",
    "Soup vegetable",
    "Onionlike soup ingredient",
    "Garlic relative",
    "Welsh national emblem",
    "Cousin of an onion",
    "Cousin of an onion",
    "Garlic relative",
    "Soup ingredient",
    "Onionlike plant used in cookery",
    "National symbol of Wales",
    "Vichyssoise ingredient",
    "Onion's kin",
    "Hot or cold soup ingredient",
    "Welsh symbol",
    "Soup ingredient",
    "Vichyssoise ingredient",
    "Stew ingredient",
    "Bulbous vegetable",
    "Soup vegetable",
    "Vichyssoise ingredient",
    "Welsh emblem",
    "Onion's cousin",
    "Shade of green",
    "Stew ingredient",
    "Soup vegetable",
    "Onionlike plant",
    "Onion relative",
    "National emblem of Wales",
    "Onion's kin",
    "Soup ingredient",
    "Kind of soup",
    "Kind of soup",
    "Garlic relative"
  ],
  "sale": [
    "Yard ___",
    "Something that's no good unless it's closed",
    "Result of a good pitch",
    "What may result in a handshake",
    "Attention-getting store sign",
    "Event that's a bit off?",
    "Going-out-of-business event",
    "Store window sign",
    "BOGO event",
    "Event on Black Friday or Cyber Monday",
    "Two-for-one event",
    "Markdown",
    "\"Off\" time",
    "After-Christmas event",
    "\"Buy one, get one free\" event",
    "Draw in an outlet",
    "Bargain hunter's delight",
    "What an auctioneer's gavel indicates",
    "Store event after Christmas",
    "Store sign on Presidents' Day",
    "Point of ___",
    "Consumer's boon",
    "Result of a perfect pitch?",
    "Presidents' Day event",
    "Store window sign",
    "Realtor's goal",
    "\"50% off\" event",
    "Shop window sign",
    "Event that's taking off?",
    "\"BOGO\" event",
    "Good time to get the goods",
    "Shopper stopper",
    "Post-Christmas store event",
    "\"Buy two, get one free\" event",
    "Closing act?",
    "Memorial Day weekend event",
    "Crowd drawer, often",
    "Stock mover",
    "Blowout, e.g.",
    "Point of ___",
    "Red-tag event",
    "For ___",
    "Broker's goal",
    "Holiday season event",
    "\"Prices slashed!\" event",
    "Common event the day after Thanksgiving",
    "Result of a pitch, perhaps",
    "Event for which percentages may be specified",
    "Ace pitcher's reward?",
    "Crowd draw, maybe",
    "Half-off event",
    "Kind of price",
    "Mall attraction",
    "\"Dollar days\" event",
    "Tent event",
    "Store sign",
    "Headline in a circular",
    "Bargain hunter's event",
    "See 36-Down",
    "Bargain event",
    "Post-Christmas store event",
    "Red tag event",
    "Red-tag event",
    "Clearance event",
    "Tent event",
    "Shopaholic's delight",
    "Realtor's goal",
    "Realtor's aim",
    "Telemarketer's aim",
    "See 1-Across",
    "Ad headline",
    "See 27-Down",
    "Point of ___",
    "16-Down's desire",
    "Crowd attractor",
    "Counter offer",
    "Ad headline",
    "Shopper stopper",
    "Sign that attracts crowds",
    "After-Christmas event",
    "Counter action",
    "Alternative to bankruptcy for a company",
    "Post-Christmas event",
    "\"Lost our lease\" event",
    "Mall attraction",
    "Sign in a store window",
    "Pitcher's success",
    "Presidents' Day event",
    "Store event",
    "Shopper's lure",
    "It may follow a pitch",
    "Possible result of bankruptcy",
    "Store sign",
    "Emporium event",
    "Reward for good pitching?",
    "Register transaction",
    "Store sign",
    "Circular announcement",
    "Successful pitch",
    "Like some merchandise",
    "Sign in a window",
    "Shopper stopper",
    "Mall event",
    "Post-Christmas event",
    "Closing act?",
    "Successful pitch",
    "Circular announcement",
    "Event marked by a \"ka-ching!\"",
    "After-Christmas event",
    "Dollar stretcher",
    "Merchandise mover",
    "Garage activity",
    "Dollar days event",
    "Bankruptcy follow-up",
    "Marketer's aim",
    "Real estate agent's goal",
    "Tent event",
    "Shopaholic's delight",
    "Post-Christmas event",
    "December 26 event",
    "Shopper's delight",
    "Leading port of old Morocco",
    "Red-tag event",
    "Red tag locale",
    "Bargain seeker's event",
    "Enticing store sign",
    "Closeout",
    "Store event",
    "50% off event",
    "Shoppers' thrill",
    "Clearance",
    "Store news",
    "Auction",
    "Store event",
    "Garage event",
    "Bargain hunter's delight",
    "Clearance",
    "No___ (register button)",
    "\"Half-off\" event",
    "Shopper's lure",
    "Word with fire or no",
    "Unloading time?",
    "Post-Christmas event"
  ],
  "anon": [
    "In short order",
    "Mystery writer?",
    "Like the verse \"Roses are red, violets are blue ...,\" in brief",
    "Unknown source, for short",
    "Soon, poetically",
    "In a while",
    "Before thou knowest",
    "Mystery writer, for short",
    "In a bit",
    "Author unknown, for short",
    "Like most proverbs, in brief",
    "In a little while, in poetry",
    "\"I'll come to you ___\": Macbeth",
    "Of uncertain origin: Abbr.",
    "In a little while",
    "Unknown author, for short",
    "In a bit, to bards",
    "Shortly, quaintly",
    "In short order",
    "Unknown author, for short",
    "Soon",
    "Shortly",
    "Shortly",
    "Ever and ___",
    "Before being outed, for short",
    "Ever's partner",
    "In a short while",
    "Erelong",
    "By and by",
    "Presently",
    "Like Mr. X, but not Malcolm X",
    "Shortly",
    "Soon enough",
    "Unknown source, informally",
    "Composer of many limericks, for short",
    "In due course",
    "Shortly",
    "Like most adages, for short",
    "Not long from now",
    "Any day now",
    "Like Mr. X, briefly",
    "Before long",
    "When Juliet tells the Nurse she will come in from the balcony",
    "Soon, to bards",
    "Before long, quaintly",
    "Like most graffiti, for short",
    "In a moment",
    "Coming up, to milady",
    "Soon",
    "Soon, quaintly",
    "Before you know it",
    "\"I'll send for you ___\": Othello",
    "By and by",
    "Soon, quaintly",
    "Before long, poetically",
    "Soon, to a bard",
    "Nameless, briefly",
    "Soon",
    "Before long",
    "Shortly, to a poet",
    "Soon, to a poet",
    "Before long, poetically",
    "Soon",
    "Before long",
    "In a bit",
    "Presently",
    "Like many blog comments, informally",
    "Soon, poetically",
    "Like most proverbs, for short",
    "Uninformative attribution: Abbr.",
    "In a moment",
    "In a bit, in poems",
    "Like many forum postings: Abbr.",
    "Like many limericks: Abbr.",
    "Bartlett's abbr.",
    "In a bit",
    "Not long from now",
    "Shortly",
    "Like most bathroom graffiti: Abbr.",
    "Soon",
    "Like some sources: Abbr.",
    "Before too long",
    "Not just yet",
    "Soon, poetically",
    "Soon enough",
    "Ever and ___",
    "Like \"Beowulf,\" in brief",
    "Soon, to poets",
    "Shortly",
    "Like many a wiseacre's comment: Abbr.",
    "Presently",
    "In a bit",
    "Al-___",
    "Any minute now",
    "Shortly",
    "Like most adages: Abbr.",
    "In the near future",
    "Shortly",
    "Like some online forum postings: Abbr.",
    "Shortly",
    "Ever's partner",
    "In the near future",
    "In a bit, to bards",
    "Ever and ___",
    "Shortly",
    "Poetic adverb",
    "Source of many a proverb, for short",
    "Shortly, to a bard",
    "Ever and ___",
    "\"Author\" of the observation, briefly",
    "Quotation designation: Abbr.",
    "Soon",
    "Ever and ___",
    "Like most graffiti: Abbr.",
    "See 38-Down",
    "Like most bathroom graffiti: Abbr.",
    "In due course",
    "Ever and ___",
    "Nameless, for short",
    "Briefly unknown?",
    "Within the hour, maybe",
    "Shortly",
    "Like many a quip: Abbr.",
    "Before long",
    "By and by",
    "Sometime soon",
    "Before long",
    "In a bit",
    "Soon",
    "Unattributed: Abbr.",
    "Quotation notation",
    "In a short time",
    "Shortly",
    "In a while",
    "Before long",
    "Like most graffiti: Abbr.",
    "Erelong",
    "Quotation attribution: Abbr.",
    "In a while",
    "Author unknown, for short",
    "Al-___",
    "Soon",
    "Auth. unknown",
    "Like most graffiti: Abbr.",
    "In a bit",
    "In a bit",
    "Soon enough",
    "Not quite yet",
    "Mysterious byline, for short",
    "Soon",
    "Soon, to a bard",
    "Quotation book abbr.",
    "Any minute",
    "This may follow words of wisdom",
    "Shortly",
    "Pretty soon",
    "Bartlett's abbr.",
    "By and by",
    "Soon",
    "Not quite yet",
    "Ever's partner",
    "Shortly",
    "Like most jokes on the Internet: Abbr.",
    "Ever's partner",
    "Prolific auth.?",
    "Shortly",
    "Presently",
    "After a while",
    "Soon",
    "In a few minutes",
    "At another time",
    "Al-___ (valuable support group)",
    "In the near future",
    "Any minute",
    "By and by",
    "Quotation notation",
    "In a while",
    "In a while",
    "Untraceable, for short",
    "Like most proverbs: Abbr.",
    "In a while",
    "Quotation notation",
    "Briefly unknown?",
    "Before long",
    "Ever's partner",
    "Shortly",
    "At another time",
    "By and by",
    "Shortly",
    "Quotation notation",
    "Soon, to a poet",
    "In good time",
    "Like most nursery rhymes: Abbr.",
    "Ever's partner",
    "Any day now",
    "Bartlett's abbr.",
    "In a bit",
    "\"Mr. X\"",
    "Prolific poet?",
    "In a bit",
    "By and by",
    "Like most graffiti: Abbr.",
    "Before long",
    "Like most limericks, in short",
    "In a while",
    "Presently",
    "\"Primary Colors\" author, for short",
    "Shortly",
    "Later",
    "Before long",
    "Freq. quotation attribution",
    "Betimes, updated",
    "Shortly",
    "Shortly",
    "In a bit",
    "Shortly",
    "Soon",
    "Shortly",
    "Not known: Abbr.",
    "Auth. unknown",
    "Not now",
    "Quotable notable?: Abbr.",
    "Erelong",
    "Shortly",
    "Any day now",
    "By and by",
    "Soon, in verse",
    "Shortly",
    "Ghost writer?",
    "In a while",
    "Author unknown, for short",
    "Quotation notation",
    "Eftsoon",
    "Forthwith",
    "Before long",
    "Pretty soon",
    "Quot. attribution",
    "Mr. X, for short",
    "Quotation notation",
    "At another time",
    "Ever and ___",
    "In the near future",
    "Not right now",
    "By and by",
    "Soon",
    "Pretty soon",
    "Prolific \"author\"",
    "Soon after",
    "Ever and ___",
    "Betimes",
    "Quotation notation?",
    "Presently"
  ],
  "coal": [
    "One source of global warming",
    "Rock around the Christmas tree?",
    "Unwanted stocking stuffer",
    "West Virginia resource",
    "Santa's \"gift\" for bad children",
    "Kind of black",
    "Eyes for Frosty the Snowman",
    "Anthracite, e.g.",
    "Fuel that contributes to global warming",
    "Big product of Kentucky",
    "Stocking stuffer",
    "Shade of black",
    "West Virginia export",
    "___ black",
    "Fossil fuel",
    "Either of Frosty the Snowman's eyes",
    "Unwanted stocking stuffer",
    "Kentucky export",
    "___-black",
    "Fossil fuel",
    "Bad child's stocking filler",
    "Unwelcome stocking stuffer",
    "Mine find",
    "Kentucky resource",
    "Big Utah export",
    "Seamy stuff?",
    "West Virginia resource",
    "\"Present\" in bad kids' Christmas stockings",
    "Santa's \"present\" for a naughty child",
    "Epitome of blackness",
    "Shade of black",
    "Fuel that's shoveled",
    "Shade of black",
    "Symbol of blackness",
    "Fuel from a mine",
    "Scuttle contents",
    "Scuttle filler",
    "Kentucky resource",
    "Brat's stocking stuffer",
    "Locomotive fuel",
    "Scuttle's contents",
    "Shade of black",
    "Anthracite, e.g.",
    "Black shade",
    "Furnace fodder",
    "Shade of black",
    "Christmas stocking item",
    "Kind of tar",
    "It's taken out at the seams",
    "Polish export",
    "Black shade",
    "Brat's Christmas present",
    "West Virginia export",
    "Fuel for old locomotives",
    "Anthracite",
    "Newcastle's pride",
    "Fossil fuel",
    "It can be gotten out of bed",
    "Naughty child's Christmas gift",
    "Welsh product",
    "Scuttle load",
    "Ember",
    "West Virginia resource"
  ],
  "terr": [
    "Yukon, e.g.: Abbr.",
    "Guam or the U.S. Virgin Islands: Abbr.",
    "Alaska, before 1959: Abbr.",
    "Apt. amenity, perhaps",
    "Okla. or Dak., once",
    "Guam, e.g.: Abbr.",
    "The U.S. Virgin Is., e.g.",
    "Okla., until 1907",
    "Okla. or Oreg., once",
    "La., e.g., once",
    "Louisiana ___: Abbr.",
    "Colonial land: Abbr.",
    "Guam or the U.S. Virgin Isls.",
    "Abbr. on an old map of the West",
    "Yukon, e.g.: Abbr.",
    "Can.'s Northwest ___",
    "La., e.g., from 1805 to 1812",
    "The Dakotas, once: Abbr.",
    "U.S. Virgin Is., e.g.",
    "Abbr. on maps of the Old West",
    "Nonmember of a union: Abbr.",
    "The U.S. Virgin Is., e.g.",
    "Apt. amenity",
    "Union member of the future: Abbr.",
    "Ariz., e.g., before 1912",
    "Dakota ___ (old geog. designation)",
    "Australia's Northern ___: Abbr.",
    "Oreg. or La., once",
    "Abbr. on old U.S. maps",
    "Hawaii, before 1959: Abbr.",
    "The Virgin Is., e.g.",
    "Admission seeker, maybe: Abbr.",
    "Hawaii, once: Abbr.",
    "Virgin Is., e.g.",
    "Virgin Is., e.g.",
    "Okla., before 1907",
    "Status before statehood: Abbr.",
    "Guam, e.g.: Abbr.",
    "Yukon, e.g.: Abbr.",
    "Virgin Isls., e.g.",
    "Dakotas, once: Abbr.",
    "Virgin Islands, e.g.: Abbr.",
    "Dak., once",
    "Pre-1821 Missouri, e.g.: Abbr.",
    "Alas., once",
    "Map abbr.",
    "Yukon, e.g.: Abbr."
  ],
  "lash": [
    "Tie down securely",
    "Makeup target",
    "Curling target",
    "Bind with rope",
    "Mascara target",
    "The eyes have it",
    "Place for mascara",
    "Where mascara goes",
    "Lid attachment",
    "Cat-o'-nine-tails component",
    "Unit of corporal punishment",
    "Eye protector",
    "Tie securely",
    "Tie securely",
    "Respond angrily, with \"out\"",
    "Eye liner?",
    "Flog",
    "Whip tip",
    "Mascara target",
    "Metaphor for punishment",
    "Stinging rebuke",
    "Shipboard punishment",
    "Old naval punishment",
    "Whip",
    "It might be batted at a knockout",
    "Mascara coats it",
    "Pirate's punishment",
    "Pirate's punishment",
    "Any tail in a cat-o'-nine-tails",
    "Mascara's place",
    "Lid attachment",
    "Punishment unit",
    "You may get an extended one at a salon",
    "Secure, in a way",
    "Corporal punishment unit",
    "Whip",
    "Liner's locale",
    "Unit of punishment",
    "Unit of punishment at sea",
    "Make secure",
    "Lid growth",
    "Respond angrily, with \"out\"",
    "Sailor's punishment",
    "Unit of punishment",
    "Eyelid attachment",
    "Whip",
    "Lid attachment",
    "Mascara site",
    "Eye protector",
    "Punishment for a sailor, maybe",
    "Punishment for a pirate",
    "Whip",
    "Place for mascara",
    "Eyewinker",
    "Mascara site",
    "Secure with rope",
    "Punishment unit",
    "Punishment unit",
    "Punishment unit",
    "Flog",
    "Eye swatter?",
    "Whip",
    "Truss",
    "Whip",
    "Pirate's punishment",
    "With 43-Across, a 1940's movie cowboy",
    "Eye protector",
    "Eye shade?",
    "Tie",
    "Switch",
    "Whip",
    "Whip end"
  ],
  "ipad": [
    "Tech release of 2010",
    "Tablet taken before going to bed, maybe",
    "Apple tablet",
    "Pro in tech since 2015",
    "Tablet released in 2010",
    "Modern register at a cashless establishment",
    "Surface competitor",
    "Apple tablet",
    "Jobs creation",
    "Tablet one might take before going to bed?",
    "Apple tablet",
    "___ Pro",
    "Product that had sales of more than 300,000 on its first day in 2010",
    "Modern replacement for a cash register",
    "Device that runs 36-Across",
    "Device that comes with 79-Across",
    "Jobs announcement of 2010",
    "Apple tablet",
    "Apple buy-product?",
    "Apple Store purchase",
    "Apple tablet",
    "Big tablet",
    "Device with a Retina display",
    "Big tablet",
    "Popular tablet",
    "Tablet since 2010",
    "Tablet purchased without a prescription?",
    "Apple tablet",
    "___ Air",
    "Certain tablet",
    "___ Pro (2015 debut)",
    "___ Air",
    "Product with Air and Mini models",
    "___ Air",
    "Modern cash register device",
    "___ Mini",
    "Apple tablet",
    "Apple product",
    "Jobs announcement of 2010",
    "Jobs announcement of 2010",
    "Paperless way to read the paper",
    "Tech release of 2010",
    "Microsoft Surface competitor",
    "What you might do some light reading on?",
    "Kindle Fire competitor",
    "Kind of app",
    "Portable Apple device",
    "Apple tablet",
    "Jobs creation",
    "Popular tablet",
    "2010 Apple release"
  ],
  "poet": [
    "Writer of 2-Down",
    "Occupation for 32-Down",
    "Amanda Gorman, e.g.",
    "\"God is the perfect ___\": Robert Browning",
    "Yeats or Keats",
    "Expert on feet",
    "One who's well-versed in the arts?",
    "Homer, for one",
    "Audre Lorde or Lord Byron",
    "Pound, e.g.",
    "Keats or Yeats",
    "Expert on meters and feet",
    "Brooke or Brooks",
    "Meter user",
    "Yearly Library of Congress appointee",
    "One seeking money for a meter?",
    "Browning or Kipling",
    "Foot specialist?",
    "Doe, e.g.",
    "\"God is the perfect ___\": Browning",
    "One who deals with stress well?",
    "Certain laureate",
    "Meter reader?",
    "Auden or Aiken",
    "Worker with a lot of stress?",
    "Foot massager?",
    "One with stressing work?",
    "One who works in feet and meters",
    "Whitman or Whittier",
    "Virgil, for one",
    "Sonneteer, e.g.",
    "Lay person?",
    "Coleridge, for one",
    "Versifier",
    "Foot specialist?",
    "Foot specialist",
    "Versifier",
    "Meter reader?",
    "Foot specialist?",
    "One who handles stress effectively?",
    "Yeats or Keats",
    "Yeats or Keats",
    "\"Every man will be a ___ if he can\": Thoreau",
    "Eliot or Frost",
    "Lay man?",
    "Frost or Burns",
    "Limerick writer, say",
    "Linesman, maybe?",
    "Pound, e.g.",
    "Laureate figure, maybe",
    "Meter reader?",
    "Sappho, e.g.",
    "Sexton, say",
    "Coffeehouse performer",
    "Sonneteer",
    "One with idyll musings?",
    "Shelley, for one",
    "O'Neill's \"A Touch of the ___\"",
    "Langston Hughes, e.g.",
    "One who works with feet",
    "One concerned with feet",
    "Verse writer",
    "Dickinson or Frost, e.g.",
    "Gwendolyn Brooks, e.g.",
    "Sexton or Pope, e.g.",
    "Rhymer",
    "Shakespeare, e.g.",
    "\"Painter of the soul\": D'Israeli",
    "Keats, for one",
    "Versifier",
    "58-Across, e.g.",
    "Pound, e.g.",
    "One who works with meters and feet",
    "Tennyson, e.g.",
    "Maya Angelou, e.g.",
    "Lyricist",
    "Frost, e.g.",
    "One who's well versed",
    "Meter-watcher",
    "Spender, for one",
    "Pound, for one"
  ],
  "alit": [
    "Made a touchdown",
    "Settled (on)",
    "Came down, as to earth",
    "Landed",
    "Landed",
    "Dismounted",
    "Stopped a flight",
    "Touched the ground",
    "Touched down",
    "Came down to earth",
    "Came down to earth",
    "Set down",
    "Came from on high",
    "Came down",
    "Came to roost",
    "Set down",
    "Came down",
    "Landed",
    "Landed, as on a branch",
    "Hit the ground",
    "Settled",
    "Landed",
    "Stopped winging it?",
    "Settled (on)",
    "Touched down",
    "Came to rest",
    "Dismounted",
    "Settled down",
    "Came down",
    "Landed",
    "Got off",
    "Touched down",
    "Came down on a branch, say",
    "Past-tense verb that is the same as its present-tense form minus the fourth and fifth letters",
    "Came down",
    "Disembarked",
    "Detrained, e.g.",
    "Settled",
    "Settled down",
    "Came down to earth",
    "Settled down",
    "Landed",
    "Touched down",
    "Came down",
    "Settled (on)",
    "Came to earth",
    "Put down",
    "Came to earth",
    "Came to rest",
    "Settled",
    "Put down",
    "Got off",
    "Touched down",
    "Settled on a branch",
    "Touched down",
    "Disembarked",
    "Dismounted",
    "Came to earth",
    "Landed",
    "Touched down",
    "Touched down",
    "Set down",
    "Came to the ground",
    "Put down",
    "Settled (on)",
    "Disembarked",
    "Touched down",
    "Came down (on)",
    "Deplaned, e.g.",
    "Dismounted",
    "Came down",
    "Set down",
    "Found a perch",
    "Set down",
    "Made a touchdown",
    "Landed (on)",
    "Landed (on)",
    "Hopped off",
    "Landed (on)",
    "Settled (on)",
    "Landed",
    "Came to rest on a wire, e.g.",
    "Came down from one?Æs high horse?",
    "Set down",
    "Dismounted",
    "Set down",
    "Settled down",
    "Touched down",
    "Landed (on)",
    "Landed",
    "Got off",
    "Hit the roof?",
    "Landed (on)",
    "Came down",
    "Got down",
    "Got down",
    "Settled",
    "Came down",
    "Touched down",
    "Settled",
    "Came down",
    "Hit the ground",
    "Touched down",
    "Hit the ground",
    "Set down",
    "Came down",
    "Dismounted",
    "Touched down",
    "Touched down",
    "Got down",
    "Came to earth",
    "Reached ground",
    "Came down",
    "Settled down",
    "Landed",
    "Dismounted",
    "Detrained",
    "Touched down",
    "Hit the ground",
    "Settled",
    "Got off",
    "Touched ground",
    "Deplaned",
    "Piled out",
    "Dismounted",
    "Landed",
    "Came to rest",
    "Touched down",
    "Got out (from)",
    "Dismounted",
    "Touched down",
    "Got off the bus",
    "Came to rest",
    "Got off",
    "Got off",
    "Detrained, e.g.",
    "Came to a perch",
    "Got down",
    "Came down to earth",
    "Hit the helipad",
    "Deplaned",
    "Came down",
    "Came down to earth",
    "Settled (on)",
    "Settled down",
    "Landed (on)",
    "Reached terra firma",
    "De-bused, e.g.",
    "Dismounted",
    "Touched down",
    "Set down",
    "Came down to earth",
    "Made it to the ground",
    "Got down",
    "Dismounted",
    "Touched the tarmac",
    "Settled",
    "Dismounted",
    "Settled, as on a perch",
    "Settled, in a way",
    "Got down",
    "Landed",
    "Landed",
    "Descended",
    "Settled",
    "Came to ground",
    "Stepped down",
    "Touched down",
    "Got off",
    "Came down",
    "Got off",
    "Landed",
    "Came to earth",
    "Came to earth",
    "Got down",
    "Touched down",
    "Got down",
    "Came down",
    "Landed",
    "Landed",
    "Returned to earth",
    "Got off",
    "Came down",
    "Parted company with a horse",
    "Got off"
  ],
  "gems": [
    "Masterpieces",
    "Insets in a crown",
    "Treasure chest contents",
    "Beauts",
    "Crown inlays",
    "Cameos and others",
    "Cluster in a marquise",
    "Beauties",
    "Sparklers",
    "Zales inventory",
    "Treasures",
    "\"Web ___\" (ESPN segment showing great fielding plays)",
    "Treasure chest treasure",
    "Rubies, emeralds, etc.",
    "Beauties",
    "Onyx and opal",
    "Solitaires, e.g.",
    "Multifaceted things",
    "Masterpieces",
    "Stones on rings",
    "Beauties",
    "Small beauties",
    "Masterpieces",
    "Paragons",
    "Emeralds and diamonds",
    "Rubies and such",
    "Tiara inlays",
    "Masterpieces",
    "Precious stones",
    "Peaches",
    "Onyxes and opals",
    "Beloved ones",
    "Sparklers",
    "Beauts",
    "Beauties",
    "Esteemed ones",
    "Pearls",
    "Screen ___ (old TV company)",
    "They have their settings",
    "Ring highlights?"
  ],
  "espy": [
    "Award for a great play",
    "Best Driver, for one",
    "Catch sight of",
    "Catch sight of",
    "Megan Rapinoe won one in 2018",
    "Spot",
    "Notice",
    "Spot",
    "Annual TV award for athletes",
    "Award for Best Moment, e.g.",
    "Make out",
    "Athletic award",
    "Play honor",
    "Spot",
    "Lay eyes on",
    "Catch sight of",
    "Athletic achievement award",
    "Spot",
    "Play award?",
    "Glimpse",
    "Get a look at",
    "Catch sight of",
    "Award won by Tiger Woods 23 times",
    "Cable sports award",
    "Spot",
    "Pick out",
    "Spot",
    "Catch sight of",
    "Award with a Best Upset category",
    "Spot",
    "Spot",
    "Spot",
    "Glimpse",
    "Spot",
    "Catch a glimpse of",
    "Catch sight of",
    "Spot",
    "Catch sight of",
    "Glimpse",
    "Award named after a TV network",
    "Lay eyes on",
    "Make out",
    "Glimpse",
    "Catch sight of",
    "Glimpse",
    "Catch sight of",
    "Catch sight of",
    "Spot",
    "Catch sight of",
    "Annual athletic award from cable TV",
    "Catch sight of",
    "Catch sight of",
    "Notice",
    "Glimpse",
    "Catch sight of",
    "Glimpse",
    "Catch sight of",
    "Glimpse",
    "Take notice of",
    "Behold",
    "Catch a glimpse of",
    "Glimpse",
    "Spot",
    "Annual athletic award",
    "Glimpse",
    "Spot",
    "Catch sight of",
    "Spot",
    "Catch sight of",
    "Catch sight of",
    "Sports achievement award",
    "Catch sight of",
    "Make out",
    "Spot",
    "Catch sight of",
    "Glimpse",
    "Perceive",
    "Sports award",
    "Spot",
    "Make out",
    "Notice",
    "Make out",
    "Perceive",
    "Make out",
    "Catch sight of",
    "Catch sight of",
    "Wordsmith Willard",
    "Spot",
    "Catch sight of",
    "Catch sight of",
    "Spot",
    "Make out",
    "Wordsmith Willard",
    "Notice",
    "Catch sight of",
    "Lay eyes on",
    "Catch sight of",
    "Catch sight of",
    "Notice",
    "Spot",
    "Glimpse",
    "Glimpse",
    "Glimpse",
    "Wordsmith Willard",
    "Agriculture Secretary Mike"
  ],
  "isle": [
    "Emerald ___ (Ireland)",
    "Man in the Irish Sea, e.g.",
    "Spot of land in the ocean",
    "Castaway's site",
    "\"Survivor\" setting, often",
    "Dot in the ocean",
    "___ of Skye",
    "___ of Man",
    "Part of an archipelago",
    "Dot of land",
    "Place to be marooned",
    "Key",
    "Key",
    "Yeats's \"The Lake ___ of Innisfree\"",
    "___ of Capri",
    "Setting for many a season of \"Survivor\"",
    "Getaway locale",
    "___ of Capri",
    "It usually has a single palm tree, in cartoons",
    "Vacation destination",
    "Land in the water",
    "___ of Capri",
    "Place reached by boat",
    "Key with a chain, maybe",
    "Archipelago part",
    "Catalina, e.g.",
    "Dot on an ocean map",
    "Castaway's locale",
    "___ of Wight",
    "Cay",
    "\"___ of Dogs\" (2018 animated movie)",
    "Scotland's Fair ___",
    "Bikini, e.g.",
    "It may be surrounded by beaches",
    "Oahu, Maui or Kauai",
    "Any of the Galapagos",
    "Catalina, e.g.",
    "Carnival Cruise stop",
    "Man, for one",
    "One surrounded at sea",
    "Yacht destination, maybe",
    "Setting for many shipwreck movies",
    "Bermuda, e.g.",
    "___ of Wight",
    "___ of Man",
    "Part of a chain",
    "Part of an archipelago",
    "Capri or Man",
    "Cuba or Aruba",
    "Castaway's spot",
    "What may be pictured on a 59-Across",
    "Sunny honeymoon site, maybe",
    "Man, but not woman",
    "Dot on an ocean map",
    "Man or Manhattan",
    "Honeymooners' site",
    "___ of Man",
    "Capri, e.g.",
    "Any of the Canaries",
    "Tahiti, for one",
    "Capri, for one",
    "___ of Wight",
    "Capri, for one",
    "___ of Man",
    "Buried treasure site, maybe",
    "___ of Capri",
    "Archipelago component",
    "Maui or Kauai",
    "Man or Mull",
    "Getaway spot in the sea",
    "Capri or Wight",
    "Vacation destination",
    "Cay",
    "Castaway site",
    "Castaway's spot",
    "Archipelago part",
    "___ of Wight",
    "Capri, e.g.",
    "___ of Capri",
    "Dot in an atlas",
    "Castaway's locale",
    "Archipelago part",
    "Key",
    "Florida's Sanibel, e.g.",
    "Manhattan, e.g.",
    "Aruba or Bora Bora",
    "Part of a chain",
    "Part of a chain, maybe",
    "Trinidad or Tobago",
    "___ of Man",
    "Key",
    "Elba or Capri",
    "___ of Man",
    "Part of many a chain",
    "Elba, for one",
    "___ of Man",
    "Pirate's hiding place, possibly",
    "___ of Wight",
    "___ of Man",
    "Barbados, e.g.",
    "Manhattan, for one",
    "Castaway's locale",
    "___ of Man",
    "___ of Wight",
    "Emerald ___",
    "1-Across, for one",
    "Key",
    "It's often volcanic",
    "Trinidad or Tobago",
    "Skye, e.g.",
    "Capri, for one",
    "Tropical vacation spot",
    "Bikini, e.g.",
    "Singapore, for one",
    "52-Across, e.g.",
    "Getaway locale",
    "Jamaica or Barbados",
    "Dot in a 10-Down",
    "___ of Wight",
    "\"Survivor\" setting, often",
    "Archipelago unit",
    "___ of Wight",
    "Manhattan, e.g.",
    "___ of Man",
    "Cruise stopover",
    "Tropical spot",
    "___ of Man",
    "Castaway's spot",
    "\"Survivor\" setting, sometimes",
    "Tropical escape",
    "Place for a 44-Down",
    "Resort site",
    "___ of Wight",
    "Marooning spot",
    "___ Royale",
    "Vacation destination",
    "Any of the Bahamas",
    "Tropical stop",
    "___ of Skye",
    "14-Down, for one",
    "Man or Wight",
    "Ferry destination, possibly",
    "Many a resort",
    "Guernsey, e.g., in the English Channel",
    "Key",
    "Key",
    "Archipelago part",
    "Vacation destination",
    "___ of Man",
    "Man, but not woman",
    "Skye, for one",
    "Molokai, for one",
    "___ of Man",
    "Capri, for one",
    "Beach site",
    "Ireland, the Emerald ___",
    "Archipelago component",
    "Man, for one",
    "It's surrounded by beaches",
    "Man, for one",
    "One of a British group",
    "___ of Capri",
    "Catalina, e.g.",
    "Beach site",
    "___ of Man",
    "Castaway's home",
    "Grand Bahama, e.g.",
    "\"The Lake ___ of Innisfree\"",
    "Cuba's ___ of Youth",
    "Castaway's locale",
    "\"Survivor\" setting",
    "South Seas locale",
    "___ of Man",
    "\"Cast Away\" setting",
    "Place for 42-Down",
    "___ of Capri",
    "Man, e.g.",
    "Great Britain, e.g.",
    "Cruise stopover",
    "___ of Man",
    "Bermuda, e.g.",
    "Key",
    "Castaway's place",
    "Capri, e.g.",
    "Man, but not Woman",
    "Vacation spot",
    "Cuba's ___ of Youth",
    "Part of an archipelago",
    "Capri, for one",
    "\"This scepter'd ___\" (England)",
    "Castaway's home",
    "Emerald ___",
    "Manhattan, e.g.",
    "Capri, e.g.",
    "18-Across or 1-Down, e.g.",
    "Ely, e.g.",
    "Catalina, e.g.",
    "Coconut palm site, maybe",
    "___ of Wight",
    "___ Royale National Park",
    "___ of Man",
    "___ Royale, Mich.",
    "Wight, for one",
    "Iwo Jima, e.g.",
    "Vacation spot",
    "Castaway's spot",
    "___ au Haut, Me.",
    "Welcome sight after a shipwreck",
    "Lighthouse locale",
    "___ of Wight",
    "St. Thomas or St. Martin",
    "Man, for one",
    "Any of the Antilles",
    "Vacation spot, perhaps",
    "Capri, e.g.",
    "Ferry terminus, maybe",
    "Dot on a map",
    "Bikini, e.g.",
    "Guernsey, for one",
    "Capri, e.g.",
    "Key",
    "\"...this sceptred ___\": \"Richard II\"",
    "Tropical spot",
    "Bermuda, e.g.",
    "Avalon, for one",
    "Presque ___, Me.",
    "Capri, e.g.",
    "___ of Man",
    "Bali Ha'i, in \"South Pacific\"",
    "Emerald ___",
    "Key",
    "Land in the ocean",
    "Yeats's \"The Lake ___ of Innisfree\"",
    "Capri, for one",
    "Elba, e.g.",
    "Key",
    "Land in the sea",
    "Man, e.g.",
    "Capri, e.g.",
    "Innisfree, e.g.",
    "Vacation spot",
    "Guernsey, e.g.",
    "Man, e.g.",
    "Vacation spot",
    "___ of Man",
    "Skye, e.g.",
    "___ Royale National Park",
    "___ of Man",
    "Michigan's ___ Royale",
    "The Emerald ___",
    "Capri, e.g.",
    "Maroon's locale",
    "Secluded place",
    "South Seas site",
    "Spot in the ocean",
    "Key",
    "Sunny vacation spot",
    "Man or Ely, e.g",
    "Sunny vacation spot",
    "Capri, for one",
    "Presque___, Me.",
    "Land in the water",
    "Sunny vacation spot"
  ],
  "iowa": [
    "State generating the highest percentage of its electricity by wind",
    "Top pork producer in the U.S.",
    "Its state fair is much visited by politicians",
    "___ Writers' Workshop",
    "Its state seal shows a steamboat on the Mississippi",
    "Geographical name that comes from the Sioux for \"sleepy ones\"",
    "Only state with a two-vowel postal code",
    "Early stop for presidential candidates",
    "John Wayne Birthplace Museum locale",
    "Des Moines's state",
    "Locale of Drake University",
    "Midwest tribe",
    "Hawkeye's home",
    "Big Ten school",
    "Where the first presidential caucuses are held",
    "Waterloo's home",
    "Sioux City's state",
    "Locale for two of the Quad Cities",
    "Its motto is \"Our liberties we prize and our rights we will maintain\"",
    "Where Herbert Hoover was born",
    "Des Moines's home",
    "Site of the first-in-the-nation caucuses",
    "State that produces the most corn",
    "Davenport's home",
    "Where the presidential primary season kicks off",
    "Its eastern and western borders are formed entirely by rivers",
    "Setting for \"The Music Man\"",
    "\"The Bridges of Madison County\" setting",
    "Caucus locale",
    "River flowing SE to the Mississippi",
    "Name repeated in ___ City, ___",
    "Word repeated in ___ City, ___",
    "One of 14 in the Big Ten",
    "Los Angeles's U.S.S. ___ Museum",
    "Place to caucus",
    "Locale for many political debates",
    "Caucus state",
    "U.S.S. ___ (W.W. II battleship)",
    "Dubuque's state",
    "Des Moines's state",
    "Early stop in a presidential race",
    "W.W. II battleship",
    "Herbert Hoover's home state",
    "Home of Fort Dodge",
    "Where I-80 crosses I-35",
    "Early caucus state",
    "Big Ten team",
    "Where James T. Kirk was born and raised",
    "\"The Bridges of Madison County\" setting",
    "Early state in presidential campaigns",
    "Home of the Hawkeyes of the Big Ten",
    "The Big Sioux River forms part of its border",
    "Sioux City's locale",
    "Home of Private Ryan in \"Saving Private Ryan\"",
    "Place name before and after City",
    "Hawkeye's home",
    "Early state in the presidential campaign",
    "Political caucus state",
    "A Siouan",
    "Corn Belt state",
    "The Hawkeyes of college sports",
    "Big source of corn",
    "For Minnesotans, it's south of the border",
    "Corn Belt state",
    "Straw poll setting",
    "The Hawkeyes of the Big Ten",
    "Davenport site",
    "Home of Pottawattamie County",
    "Skunk River locale",
    "Part of the Corn Belt",
    "Corn Belt state",
    "Waterloo's place",
    "Ottumwa's locale",
    "Dubuque's state",
    "Keokuk's home",
    "\"The Music Man\" setting",
    "Part of the Corn Belt",
    "Raccoon River locale",
    "Waterloo locale",
    "See 6-Down",
    "Hawkeye's home",
    "\"Field of Dreams\" setting",
    "Where Red Delicious apples originated",
    "Caucus state",
    "The Hawkeye State",
    "Early political caucus state",
    "Hawkeyes' home",
    "Part of the Corn Belt",
    "Big Ten school",
    "Where Buffalo Bill was born",
    "Corn locale",
    "Des Moines is its capital",
    "Presidential caucus state",
    "Part of the Corn Belt",
    "Hawkeye State",
    "\"Field of Dreams\" setting",
    "\"State Fair\" state",
    "Where Ames is",
    "The Hawkeye State",
    "\"The Music Man\" locale",
    "\"Bridges of Madison County\" setting",
    "\"The Bridges of Madison County\" locale",
    "Hawkeye State"
  ],
  "pele": [
    "Only player on more than two winning World Cup teams",
    "Athlete who once served as his country's Extraordinary Minister for Sport",
    "Brazilian soccer great",
    "Three-time World Cup champion",
    "Guinness record-holder for the most career goals in football",
    "Joint winner of FIFA's Player of the Century award in 2000",
    "Scorer of 12 World Cup goals",
    "One-named soccer great",
    "Athlete known as \"The Black Pearl\"",
    "One-named soccer star",
    "\"The King of Football\"",
    "Sports star with a signed jersey in the Vatican",
    "Athlete on Time's list of the 100 most influential people of the 20th century",
    "___ Day (November 19, in Brazil)",
    "One-named athlete whose real first name is Edson",
    "National hero of Brazil",
    "Scorer of 1,281 goals in 1,363 games",
    "Retired Brazilian soccer sensation",
    "The King of Football",
    "Brazilian soccer legend",
    "One-named soccer star",
    "One-named sports star who was once the highest-paid athlete in the world",
    "1956 Santos rookie",
    "One-named soccer legend",
    "Edson Arantes do Nascimento, to fans",
    "Forward who wore #10",
    "Athlete nicknamed \"O Rei\"",
    "Brazilian soccer hero",
    "Santos rookie of 1956",
    "Soccer immortal",
    "FIFA Player of the Century",
    "Star of football, to most of the world",
    "Goal-oriented superstar?",
    "Athlete who won the 1978 International Peace Award",
    "Sports star with an accent in his name",
    "Legendary athlete on the 6/23/75 cover of Sports Illustrated",
    "Only player to be part of three World Cup-winning teams",
    "1958 World Cup hero",
    "Old Cosmos great",
    "Cosmos legend",
    "Achiever of many goals",
    "Legendary kicker",
    "New York Cosmos star",
    "Brazilian soccer great",
    "Brazilian soccer legend",
    "Sports hero who wore #10",
    "Latino sports legend",
    "Soccer great",
    "Former World Cup star",
    "Famous kicker",
    "Memorable kicker",
    "He averaged almost a goal a game",
    "Edson Arantes do Nascimento, familiarly",
    "Cosmos star",
    "Former Cosmos star",
    "Soccer icon",
    "Three-time World Cup star",
    "One-named sports star",
    "Ex-Cosmos great",
    "Star player for the Cosmos",
    "New York Cosmos star",
    "One-named sports legend",
    "Onetime World Cup star",
    "17-year-old sensation at the 1958 World Cup",
    "Brazilian booter",
    "Soccer great born Edson Arantes",
    "Soccer superstar",
    "Cosmos star",
    "1958 World Cup sensation",
    "Noted Sao Paulo-born athlete",
    "Brazilian national hero",
    "Brazilian soccer star",
    "One-named soccer star",
    "Soccer legend",
    "Soccer legend",
    "Soccer immortal",
    "Brazilian booter",
    "Star booter",
    "One-name sports star",
    "Soccer great",
    "Brazilian booter",
    "Soccer legend",
    "Soccer great",
    "Three-time World Cup medalist",
    "That Cosmos guy",
    "New York Cosmos star",
    "A k a Edson Arantes do Nascimento",
    "Athlete from Tres Coracoes, Brazil",
    "Soccer legend"
  ],
  "tsar": [
    "Russian despot",
    "\"May God bless and keep the ___ ... far away from us!\" (\"Fiddler on the Roof\" line)",
    "Imperial title derived from \"caesar\"",
    "Old Russian ruler",
    "Fabergé egg recipient",
    "Romanov V.I.P.",
    "Historical lead-in to -evna or -evich",
    "Boris Godunov, for one",
    "Bolshevik's foe",
    "Bygone royalty",
    "Old Russian ruler",
    "Onetime title for Bulgaria's Simeon II, the last person to use it",
    "Peter the Great, for one",
    "Former Bulgarian monarch",
    "\"May God bless and keep the ___ ... far away from us!\" (line from \"Fiddler on the Roof\")",
    "Ivan or Peter, e.g.",
    "Russian lead-in to -evich or -evna",
    "Crimean War leader",
    "\"A Life for the ___\" (Mikhail Glinka opera)",
    "Ruler in the Romanov line",
    "Bygone monarch",
    "Peter or Paul (but not Mary)",
    "Emperor of Russia",
    "Title based on the name \"Caesar\"",
    "Russian Revolution target",
    "Pre-1917 ruler",
    "Bolshevik's foe",
    "Old Russian ruler",
    "Romanov leader",
    "Old Russian title that's an anagram of 57-Down",
    "Nicholas II was the last one",
    "Onetime ruler in the Winter Palace",
    "\"The Tale of ___ Saltan\" (Rimsky-Korsakov opera)",
    "Peter or Paul",
    "Ruler of old Russia",
    "Evictor of the Jews in \"Fiddler on the Roof\"",
    "Faberge egg collector",
    "Old Russian ruler",
    "Peter or Paul, but not Mary",
    "Romanov ruler",
    "Issuer of ukases",
    "Peter the Great, for one",
    "Russian ruler",
    "Leader wearing the Great Imperial Crown",
    "Peter the Great, e.g.",
    "Leader with a title derived from the name \"Caesar\"",
    "Victim of a revolution",
    "Pre-1917 despot",
    "Bolshevik's target",
    "Pre-Red head",
    "Romanov ruler",
    "1917 dethronee",
    "Peter or Paul, but not Mary",
    "Bygone Winter Palace resident",
    "Winter Palace resident",
    "Early 20th-century abdicator",
    "Old Russian ruler",
    "Winter Palace autocrat",
    "Target of a 1917 uprising",
    "Leader issuing a ukase",
    "Despised figure in \"Fiddler on the Roof\"",
    "Winter Palace resident",
    "Bolshevik's target",
    "Khan : Mongolia :: ___ : Russia",
    "Central figure in a Mussorgsky opera",
    "Ruler of Muscovy",
    "February Revolution target",
    "Bygone monarch",
    "Winter Palace resident",
    "Old Russian ruler",
    "Pre-1917 autocrat",
    "Ivan or Nicholas",
    "Title derived from the name \"Caesar\"",
    "Old Russian autocrat",
    "Old Russian ruler",
    "Bygone Kremlin resident",
    "Old Russian autocrat",
    "Romanov bigwig",
    "Despot until 1917",
    "Ivan the Terrible, for one",
    "Late 19th-century anarchist's foe",
    "Anastasia's father was one",
    "Bygone despot",
    "Bygone ruler",
    "Figure in a Rimsky-Korsakov opera",
    "Former St. Petersburg royal",
    "Old Russian despot",
    "Romanov ruler",
    "Peter, e.g.",
    "Father of a grand duke",
    "Old royal",
    "Former Russian royal",
    "Rimsky-Korsakov's \"The Tale of ___ Saltan\"",
    "Michael, e.g.",
    "Pre-Communist autocrat",
    "Opponent of Napoleon",
    "Bolshevik target",
    "Pre-1917 autocrat",
    "Old Russian autocrat",
    "Bolshevik foe",
    "Peter the Great, e.g.",
    "Peter the Great, e.g.",
    "Peter the Great, for one",
    "Old imperator",
    "Ukase issuer",
    "Peter the Great, for one",
    "Peter I, II or III",
    "Bygone despot",
    "Romanov ruler",
    "Autocrat until 1917",
    "Ivan or Feodor",
    "Shah : Iran :: ___ : Russia",
    "Old Russian despot",
    "Leader overthrown in 1917",
    "Romanov ruler",
    "Early anti-Communist",
    "Rimsky-Korsakov's \"The Tale of ___ Saltan\"",
    "Bygone autocrat",
    "Bygone crown",
    "Ruler before 31-Down",
    "Peter or Paul, but not Mary",
    "Bygone leader",
    "\"The Guns of August\" figure",
    "Boris Godunov, for one",
    "Cossacks' leader",
    "Alexis, e.g.",
    "Old Russian ruler",
    "Former Russian royalty",
    "Dweller in the Peterhof",
    "The first one ruled 1547-84",
    "Early 1900s ruler",
    "Nicholas I or II",
    "Plot target of the Decembrists",
    "Pre-Communist leader",
    "Ruler of the Cossacks",
    "Pre-1917 autocrat",
    "Bygone despot",
    "Bygone despot",
    "Ivan the Terrible, e.g.",
    "Pre-1917 Russian ruler",
    "Old Russian monarch",
    "Leader in the Crimean War",
    "Pre-1917 monarch",
    "Nicholas II was the last one",
    "26- or 55-Down",
    "Anticommunist leader",
    "Boris Godunov, e.g.",
    "Winter Palace resident",
    "Romanov ruler",
    "Peter, for one",
    "Winter Palace ruler",
    "Peter, for one",
    "Ruler of yore",
    "Mikhail Romanov, e.g.",
    "Russian leader of old",
    "Despot of yore",
    "Russian leader before 1917",
    "1917 revolution casualty",
    "Rimsky-Korsakov's \"The Tale of ___ Saltan\"",
    "Nicholas I or II",
    "Foe of communism",
    "Old overlord",
    "Rimsky-Korsakov's \"The Tale of ___ Saltan\"",
    "Russian leader before 1917",
    "Past potentate",
    "Bygone despot",
    "Bygone autocrat",
    "Russia's Nicholas I, e.g.",
    "Romanov ruler",
    "Leader before Lenin",
    "Pre-Red head",
    "Bygone autocrat",
    "Old crowned head",
    "Royal Russian",
    "Michael, for one",
    "Old monarch",
    "Peter, e.g.",
    "Customer of Faberge eggs",
    "Leader of pre-1917 Russia",
    "Leader opposed by the Bolsheviks",
    "Peter I, II or III",
    "Ivan or Nicholas",
    "Ruler until 1917",
    "Old autocrat",
    "Ex-leader of Russia",
    "Alexander II, e.g.",
    "Boris Godunov, for one",
    "Bygone despot",
    "Old Russian royal",
    "Peter the Great, e.g.",
    "Pre-Lenin ruler",
    "Abdicator of 1917",
    "Boris Godunov, for one",
    "Nicholas II was the last one",
    "Anarchists' foe, once",
    "Peter or Nicholas",
    "Ruler until 1917",
    "Abdicator of 1917",
    "Feodor I, e.g.",
    "Winter Palace ruler",
    "Peter, e.g.",
    "Pre-1917 ruler",
    "Winter Palace resident",
    "Peter or Paul",
    "Victim of a 1917 revolution",
    "Leader opposed by the Bolsheviks",
    "Old autocrat",
    "Word that's an accidental acronym of a Hemingway title",
    "Bygone leader",
    "Autocrat",
    "Olden despot",
    "Rimsky-Korsakov's \"The Tale of ___ Saltan\"",
    "Bolshevik target",
    "Nicholas I or II",
    "Autocrat",
    "Feodor, e.g.",
    "Bygone title",
    "Tolstoy's \"___ Fyodor Ivanovich\"",
    "Basil, e.g.",
    "Peter, e.g.",
    "Peter, for one",
    "Ivan or Nicholas",
    "Pre-1917 ruler",
    "Old despot",
    "Winter Palace resident",
    "Peter the Great, e.g.",
    "Winter Palace dweller, once",
    "Alexis, e.g.",
    "Nicholas II, e.g.",
    "Pre-Soviet royalty",
    "Paul I, e.g.",
    "Nicholas or Alexander",
    "Opera \"The Tale of ___ Saltan\"",
    "Nicholas I or II, e.g.",
    "Bygone leader",
    "Russian autocrat",
    "Figure in a Rimsky-Korsakov opera",
    "Nicholas was one",
    "Pre-1917 ruler",
    "Rimsky-Korsakov's Saltan, e.g.",
    "Ruler in Rimsky-Korsakov operas",
    "Ivan or Nicholas",
    "Winter Palace resident",
    "Autocrat",
    "Nicholas or Alexander",
    "Romanov leader",
    "Nicholas I or II",
    "Russian despot",
    "\"Terrible\" title",
    "Mikhail, e.g.",
    "Romanov V.I.P.",
    "Romanov leader",
    "Nicholas, e.g.",
    "Red foe",
    "Peter, once",
    "Pre-1917 ruler",
    "Erstwhile despot",
    "Mussorgsky's Boris",
    "Mikhail, for one",
    "Ivan or Peter",
    "Ruler of old",
    "Peter I, e.g.",
    "Romanov V.I.P.",
    "Prerevolutionary leader",
    "Ivan or Nicholas, e.g.",
    "Mogul",
    "Winter Palace resident, once",
    "Nicholas, e.g.",
    "Autocrat",
    "Former Russian ruler",
    "Moscow ruler",
    "Ivan the Terrible, e.g.",
    "Winter Palace resident",
    "Boris Godunov, e.g.",
    "Bear head, once",
    "Peter or Nicholas",
    "Alexander or Peter",
    "Bygone despot",
    "Slavic sovereign",
    "Ivan, for one",
    "Royal Russian",
    "Ruler until 1917",
    "Boris Godunov, e.g."
  ],
  "holo": [
    "Prefix with -gram",
    "Prefix with -gram",
    "Prefix with -gram",
    "Entire: Prefix"
  ],
  "gasx": [
    "Alternative to Beano",
    "Beano competitor",
    "Beano competitor",
    "Beano competitor",
    "Antibloating brand",
    "Beano competitor",
    "Beano alternative",
    "Beano alternative"
  ],
  "iago": [
    "\"Othello\" villain",
    "False friend in Shakespeare",
    "Shakespeare villain who says \"Virtue? A fig!\"",
    "Shakespeare character who inquires \"Are your doors lock'd?\"",
    "Jafar's parrot in \"Aladdin\"",
    "Schemer against Othello",
    "Shakespeare villain with more lines than the title character",
    "\"Othello\" villain",
    "Emilia's husband in \"Othello\"",
    "\"Aladdin\" parrot",
    "Shakespearean schemer",
    "\"Aladdin\" character named after a literary villain",
    "Villain who says \"What you know, you know\"",
    "\"Othello\" provocateur",
    "\"Othello\" villain",
    "Manipulator of Othello",
    "\"Othello\" villain",
    "\"Othello\" villain",
    "Literary character who says \"Cassio's a proper man\"",
    "Literary character who says \"I will wear my heart upon my sleeve\"",
    "\"Othello\" schemer",
    "Rival of Cassio",
    "\"I am not what I am\" speaker",
    "Rival of Cassio, in Shakespeare",
    "\"I hate the Moor\" speaker, in Shakespeare",
    "To whom Brabantio says \"Thou art a villain\"",
    "Parrot in \"Aladdin\"",
    "Shakespearean plotter",
    "Husband to Emilia in \"Othello\"",
    "\"Othello\" traitor",
    "\"I hate the Moor\" speaker",
    "Shakespeare villain",
    "\"Othello\" villain",
    "\"I will wear my heart upon my sleeve\" speaker, in Shakespeare",
    "Plotter with Roderigo",
    "\"Otello\" baritone",
    "\"I hate the Moor\" speaker",
    "\"Othello\" evildoer",
    "Shakespeare character who says \"I hate the Moor\"",
    "Shakespearean villain",
    "To whom Brabantio says \"Thou art a villain\"",
    "\"O, beware, my lord, of jealousy\" speaker",
    "Shakespearean manipulator",
    "Victimizer of Cassio",
    "Villain who says \"O, beware, my lord, of jealousy\"",
    "Plotter against Cassio in \"Othello\"",
    "\"I hate the Moor\" speaker",
    "\"But men are men; the best sometimes forget\" speaker",
    "Whom Othello declares \"is most honest\"",
    "\"Othello\" bad guy",
    "\"Othello\" villain",
    "\"Aladdin\" parrot",
    "Othello's betrayer",
    "\"I am not what I am\" speaker",
    "Shakespearean schemer",
    "\"Were I the Moor, I would not be ___\"",
    "Shakespearean character who says \"I am not what I am\"",
    "\"Othello\" villain",
    "Literary character whose first word is \"'Sblood\"",
    "\"Othello\" villain",
    "Shakespearean schemer",
    "\"Othello\" provocateur",
    "Literary character who says \"O, beware, my lord, of jealousy\"",
    "\"Othello\" character who says \"Who steals my purse steals trash\"",
    "Othello's undoer",
    "Othello's false friend",
    "\"Some busy and insinuating rogue,\" in Shakespeare",
    "Rival of Cassio",
    "Villain who says \"For I am nothing, if not critical\"",
    "\"Otello\" baritone",
    "\"Othello\" villain",
    "Emilia's husband, in \"Othello\"",
    "\"Othello\" villain",
    "Othello villain",
    "Moor's betrayer",
    "Cassio's rival",
    "A \"Spartan dog,\" according to Lodovico",
    "Shakespearean villain",
    "Plotter in a play",
    "\"O, beware, my lord, of jealousy\" speaker",
    "\"O, beware, my lord, of Jealousy!\" speaker",
    "\"We cannot all be masters, nor all masters / Cannot be truly 'd\" speaker",
    "\"Othello\" villain",
    "Othello foe",
    "\"Othello\" villain",
    "Othello's betrayer",
    "\"Othello\" villain",
    "\"Othello\" villain",
    "Globe plotter",
    "Othello's undoer",
    "Othello's undoer",
    "\"Othello\" villain",
    "Disney parrot",
    "Cassio's rival",
    "Othello's ensign",
    "Parrot in \"Aladdin\"",
    "Othello's ensign",
    "\"Othello\" villain",
    "Globe plotter",
    "Verdi villain",
    "Shakespearean villain",
    "?ôOthello?ö ensign",
    "1995 role for Kenneth Branagh",
    "Shakespearean ensign",
    "Verdi villain",
    "Othello's betrayer",
    "\"Othello\" fellow",
    "\"I lack iniquity\" speaker",
    "\"Othello\" fellow",
    "\"And what's he then, that says I play the villain?\" speaker",
    "Enemy of the Moor",
    "Othello's false friend",
    "Othello's betrayer",
    "\"Othello\" villain",
    "\"Othello\" role",
    "\"Othello\" villain",
    "Shakespearean villain",
    "Betrayer of the Moor",
    "Verdi baritone",
    "Cassio's rival",
    "\"Othello\" villain",
    "\"Othello\" villain",
    "Cassio's adversary",
    "Globe plotter",
    "Cassio's rival",
    "\"Othello\" villain",
    "Literary plotter",
    "Othello's ancient",
    "\"Othello\" villain",
    "Othello's ensign",
    "\"Othello\" villain",
    "Shakespearean villain",
    "Fictional ensign",
    "?ôHonest ___,/My Desdemona must I leave to thee?ö",
    "Othello's ensign",
    "\"Othello\" villain",
    "Emilia's husband",
    "Verdi villain",
    "Shakespearean villain",
    "Emilia's husband",
    "Shakespearean villain",
    "\"Who steals my purse steals trash\" speaker",
    "Othello's ancient",
    "\"Othello\" villain",
    "\"Otello\" baritone",
    "Venetian troublemaker",
    "Emilia's husband",
    "\"Othello\" villain",
    "\"Othello\" plotter",
    "Othello's nemesis",
    "Shakespeare's \"ancient\""
  ],
  "dali": [
    "Salvador who painted melting watches",
    "Who said \"The only difference between me and the Surrealists is that I am a Surrealist\"",
    "\"Swans Reflecting Elephants,\" e.g.",
    "Surrealist Salvador",
    "Artist given the derisive nickname \"Avida Dollars,\" an anagram of his full name",
    "32-year-old artist pictured on the cover of Time magazine in 1936",
    "Painter of melting pocket watches",
    "\"The Hallucinogenic Toreador\" painter",
    "Subject of a museum in St. Petersburg, Fla.",
    "\"The Burning Giraffe\" artist",
    "Limp watch painter",
    "\"Lobster Telephone\" artist",
    "\"The Hallucinogenic Toreador\" artist",
    "Limp watch painter",
    "\"The Persistence of Memory\" artist",
    "Artist who said \"I don't do drugs. I am drugs\"",
    "\"The Persistence of Memory\" artist",
    "Co-writer of the Surrealist silent film \"Un Chien Andalou,\" 1929",
    "\"The Burning Giraffe,\" e.g.",
    "\"Swans Reflecting Elephants\" painter",
    "Collaborator with Disney on the film \"Destino\"",
    "\"Dream Caused by the Flight of a Bee Around a Pomegranate a Second Before Awakening\" artist",
    "Surrealist played by Adrien Brody in \"Midnight in Paris\"",
    "\"Swans Reflecting Elephants\" artist",
    "Painter portrayed by Adrien Brody in \"Midnight in Paris\"",
    "\"Burning Giraffes in Yellow\" painter",
    "\"Swans Reflecting Elephants,\" e.g.",
    "\"Swans Reflecting Elephants\" artist",
    "\"Still Life - Fast Moving\" artist",
    "Painter of dreamscapes",
    "\"The Persistence of Memory\" artist",
    "Subject of a museum in Figueres, Spain",
    "Salvador who painted \"The Persistence of Memory\"",
    "\"The Hallucinogenic Toreador\" artist",
    "\"The Persistence of Memory\" painter",
    "Mustachioed Surrealist",
    "Painter of dreamscapes",
    "\"Hallucinogenic Toreador\" artist",
    "\"Lobster Telephone,\" e.g.",
    "\"Crucifixion\" artist",
    "\"Christ of St. John of the Cross\" artist",
    "\"Burning Giraffe\" painter",
    "Subject of a museum in St. Petersburg, Fla.",
    "Limp watch painter",
    "\"Anthropomorphic Bread\" painter, 1932",
    "He's found in the Prado",
    "Artist Salvador",
    "\"The Hallucinogenic Toreador\" painter",
    "\"The Persistence of Memory\" artist",
    "\"The Persistence of Memory\" artist",
    "\"Tuna Fishing\" artist",
    "Painter of limp watches",
    "Noted Surrealist",
    "\"The Persistence of Memory\" artist",
    "\"The Burning Giraffe\" painter",
    "Surrealist Salvador",
    "Mustachioed Surrealist",
    "MOMA artist",
    "Gala's husband and portraitist",
    "Limp watch painter",
    "\"Atomic Leda\" painter",
    "Subject of a St. Petersburg, Fla., museum",
    "\"Christ of St. John of the Cross\" producer",
    "Picasso contemporary",
    "Bunuel collaborator",
    "\"The Secret Life of Salvador ___\"",
    "\"The Persistence of Memory\" painter",
    "Limp watch painter",
    "Painter of limp watches",
    "Surrealist Salvador",
    "\"Christ of St. John of the Cross\" artist",
    "Surrealist Salvador",
    "\"Inventions of the Monsters\" artist",
    "Mustachioed artist",
    "Flamboyant Surrealist",
    "Bunuel collaborator",
    "Spanish Surrealist",
    "\"Diary of a Genius\" author",
    "Dreamscape artist",
    "Mustachioed artist",
    "Limp watch painter",
    "?ôTuna Fishing?ö painter",
    "Spanish Surrealist",
    "Figueras, Spain, museum subject",
    "Bunuel collaborator",
    "Spanish painter",
    "\"Tuna Fishing\" painter",
    "Mustachioed artist",
    "Dream pictures artist",
    "\"Tuna-Fishing\" artist",
    "The \"limp watch\" painter",
    "MOMA work",
    "Bunuel collaborator",
    "\"Tuna-Fishing\" painter"
  ],
  "spam": [
    "Most emails offering life insurance policies, say",
    "Something you might use a filter for",
    "Hormel product",
    "Some unwanted mail",
    "Many a phone call from one's own area code, nowadays",
    "Food invention of 1937",
    "What an email filter filters",
    "Cyberjunk",
    "Send a bunch of messages to, say",
    "Cybertrash",
    "Food portmanteau",
    "Unwanted inbox filler",
    "Unwanted email",
    "Meat that comes in a can",
    "Filter target",
    "Email best not replied to",
    "Meat in a classic Monty Python skit",
    "Often-filtered messages",
    "Much-maligned food",
    "Email folder",
    "E-con?",
    "Communication problem?",
    "Something caught in a filter",
    "Unwanted emails",
    "Unwanted mail",
    "Food product whose name is used nowadays mostly in a nonfood way",
    "Stuff caught in a filter",
    "Filter target",
    "___ folder",
    "Target of a filter",
    "___ folder",
    "Online deluge",
    "Often-filtered material",
    "Unsolicited e-mail",
    "E-mail that's sent out by the millions",
    "Name sung over and over in a Monty Python skit",
    "Filtered stuff",
    "E-mail filter's target",
    "Many unopened letters",
    "Usually deleted e-mail",
    "Too much of e-mail",
    "Hormel canned it in 1937; Congress, in 2003",
    "E-mail woe",
    "Unwelcome letters",
    "E-mail often caught in filters",
    "Rarely read letters",
    "Unwanted e-mail",
    "Many unread messages",
    "In-box clogger",
    "Filter's target",
    "___ blocker",
    "Cyberjunk",
    "Meat featured in a Monty Python musical title",
    "E-mail annoyance",
    "Unread messages, usually",
    "It may be caught in a filter",
    "Canned meat brand",
    "Canned fare since 1937",
    "Kind of blocker",
    "Subject of a museum in Austin, Minn.",
    "Some term life insurance offers",
    "Food that's the subject of a museum in Austin, Minn.",
    "Incoming clutter",
    "Relatively common letters",
    "Usually unopened mail",
    "Rarely read messages",
    "Contents of a modern flood",
    "Most of it nowadays is filtered",
    "E-garbage",
    "Many cyber-ads",
    "Cyber-junk mail",
    "Junk e-mail",
    "A dispiritingly large amount of e-mail",
    "Cybertrash",
    "Unsolicited e-mail",
    "Unwanted e-mail",
    "War fare?",
    "Computer in-box annoyance",
    "Cyber-nuisance",
    "Pesky e-mails",
    "Flood with offers, say",
    "Unwanted 41-Across",
    "Unwanted e-mail",
    "Unwanted cyber-ads",
    "Computer nuisance",
    "Foxhole fare",
    "Unwanted e-mail",
    "Modern junk",
    "Unwanted e-mail",
    "Hit with unwanted messages",
    "E-mail nuisance",
    "Meat in a can",
    "Junk E-mail",
    "W.W. II fare",
    "Front-line chow, once",
    "Cyberannoyance",
    "Canned product since 1937",
    "Junk E-mail",
    "Canned meat brand",
    "Armed forces chow item",
    "Field food",
    "G.I. grub",
    "W.W. II meat",
    "Infantry fare",
    "Lunch meat"
  ],
  "etas": [
    "Airport guesses, for short",
    "Headwinds can push them back, in brief",
    "Some rideshare info",
    "Greek H's",
    "Greek letters that rhyme with three other letters",
    "Requests made to latecomers, in brief",
    "Flight board listings, for short",
    "Info on a flight tracker app",
    "Greek vowels",
    "Greek H's",
    "Scheduling guesses that might be updated in midair, for short",
    "GPS's guesses",
    "Guesses by GPSes",
    "Greek &eta;'s",
    "HHHHH",
    "Greek H's",
    "Things in airport windows, for short",
    "LAX listings",
    "Brief announcements from pilots?",
    "Airport postings, in brief",
    "Column on a flight board, for short",
    "Station predictions, for short",
    "Connection concerns, in brief",
    "Greek H's",
    "Some fraternity members",
    "Constantly updating GPS figs.",
    "H's, in fraternity names",
    "Some airport figures, for short",
    "Greek H's",
    "HH",
    "Terminal info",
    "Landing approximations, for short",
    "Greek H's",
    "Connection concerns, for short",
    "Itinerary data, for short",
    "Certain airline alerts, for short",
    "Info for some limo drivers",
    "H H H",
    "Flight figures, for short",
    "Flight figures, for short",
    "Data for airport chauffeurs, for short",
    "Calculations made while high, for short?",
    "Greek vowels",
    "When planes are due in, for short",
    "Headwinds often push them back, briefly",
    "O'Hare info, for short",
    "H's",
    "HHH",
    "Postings at LAX and ORD",
    "They may be delayed by weather, for short",
    "Viscosity symbols",
    "Terminal announcements, for short",
    "Some P.A. announcements",
    "When flights are due in, for short",
    "Terminal info",
    "Important info for people with connections",
    "H H H H",
    "Touchdown data, for short",
    "Control tower info",
    "Pilots' announcements, in brief",
    "Info for airport greeters",
    "LAX postings",
    "H's",
    "Terminal projections, briefly",
    "Some airport announcements, for short",
    "Airport data",
    "Info about touchdowns",
    "Flight board postings, for short",
    "Airport postings, for short",
    "Itinerary info",
    "Greek H's",
    "Airport postings, for short",
    "LAX data",
    "Greek H's",
    "They may be announced en route: Abbr.",
    "Itinerary data, briefly",
    "Terminal approximations: Abbr.",
    "Plane figures?",
    "Info from some captains",
    "45-Across info",
    "Airport guesses, for short",
    "Pilots' announcements, for short",
    "Hellenic H's",
    "Greek H's",
    "Viscosity symbols",
    "Some honor society letters",
    "Greek H's",
    "Greek H's",
    "Greek vowels",
    "Cockpit announcements, briefly",
    "ORD or LAX figs.",
    "Airport guesses, for short",
    "Some guesses, for short",
    "Airport monitor info, for short",
    "Weather may affect them: Abbr.",
    "Hellenic H's",
    "When planes are due, for short",
    "Itinerary data: Abbr.",
    "Greek H's",
    "Station info, briefly",
    "Some fraternity men",
    "Greek vowels",
    "Greek vowels",
    "Pilots' info",
    "Greek vowels",
    "Pilot announcements, for short",
    "Sorority letters",
    "Guesstimates at J.F.K.",
    "J.F.K. postings",
    "H H H, to Greeks",
    "Passengers' concerns, briefly",
    "Greek H's",
    "Touchdown data",
    "In-flight info, in brief",
    "Flight data, briefly",
    "Airport postings, for short",
    "LAX listings: Abbr.",
    "Airline info, for short",
    "Announcements from the cockpit, briefly",
    "Itinerary info",
    "Greek vowels",
    "Flight data, briefly",
    "Viscosity symbols",
    "Terminal info",
    "Itinerary info",
    "In-flight details, for short",
    "Greek H's",
    "Letters from Greece",
    "Airport info",
    "Letters of Zeno",
    "Greek vowels",
    "Cockpit guesses, for short",
    "Airport info, for short",
    "J.F.K. times",
    "LAX postings",
    "Greek vowels",
    "Letters from Athens",
    "Some guesses",
    "Hellenic characters",
    "Hellenic H's",
    "Fraternity row characters",
    "Flight board data, briefly",
    "LAX guesstimates",
    "H H H",
    "Hellenic letters",
    "Long E's, in Greek",
    "Greek letters",
    "J.F.K. monitor info",
    "Greek H's",
    "Hellenic vowels",
    "Sorority letters",
    "Greek letters",
    "Greek vowels",
    "Greek letters",
    "Letters from Greece",
    "Homeric H's",
    "Airport approximations, familiarly",
    "Greek vowels",
    "Greek letters",
    "Greek letters",
    "LAX data",
    "Pilots' \"due points\": Abbr.",
    "Sched. info",
    "Greek vowels",
    "Olympic marks",
    "Hellenic H's",
    "Efficiency symbols, in physics",
    "Greek letters",
    "Greek H's",
    "Greek letters"
  ],
  "belt": [
    "Alternative to suspenders",
    "Pants holder-upper",
    "It's a cinch",
    "Hit hard",
    "Alternative to suspenders",
    "Champion's accessory",
    "Big swig",
    "Alternative to suspenders",
    "Wallop",
    "Conveyor part",
    "Wallop",
    "Martial arts award",
    "Big swig",
    "Supporting strip",
    "Big swig",
    "Slug",
    "Heavyweight's prize",
    "Corn ___",
    "Asteroid ___",
    "Asteroid ___",
    "Boxer's trophy",
    "Wallop",
    "Asteroid area",
    "Big swig",
    "Alternative to suspenders",
    "Stiff drink",
    "Holdup accessory?",
    "Asteroid area, e.g.",
    "Wallop",
    "Prizefighting prize",
    "Frequent target of engine wear",
    "Swig",
    "Big shot",
    "Wallop",
    "Karate skill category",
    "Wallop",
    "Important highway",
    "Big swig",
    "Boxing prize",
    "Whack",
    "Sander part",
    "A loser may have to tighten it",
    "Boxing prize",
    "It goes to waist",
    "Holdup device?",
    "Big shot at a bar?",
    "Skirt accessory",
    "Boxer's trophy",
    "Short snort",
    "Slug"
  ],
  "dots": [
    "Parts of i's and j's",
    "Signal that a reply is coming in a messaging app",
    "Bits of hijinks?",
    "Simple pencil-and-paper game",
    "They might be connected by children ... or detectives",
    "Parts of colons",
    "Ellipses, e.g.",
    "Pac-Man features",
    "What Pac-Man eats",
    "Medical points, e.g.",
    "Points",
    "The word \"shies\" in Morse code, entirely",
    "Sleuths connect them",
    "Parts of an ellipsis",
    "i's and j's have them",
    "Certain gumdrops",
    "\"i\" and \"j\" tops",
    "Fruity candy since 1945",
    "Braille, essentially",
    "Kids connect them",
    "Detectives connect them",
    "Freckles, e.g.",
    "Points",
    "City representatives?",
    "Colon, e.g.",
    "Dashes' partners in Morse code",
    "Classic pencil-and-paper game",
    "Movie theater candy",
    "Ellipsis, basically",
    "\"i\" toppers",
    ".",
    "Classic pencil-and-paper game",
    "Pencil-and-paper game",
    "Pixels",
    "Colon composition",
    "Colon parts",
    "You might connect them",
    "Pixels and such",
    "Place-to-sign indicators",
    "I lids?",
    "Ellipsis",
    "Pixels",
    "Specks",
    "Children's connectibles",
    "Feature of Roy Lichtenstein's art",
    "Pencil-and-paper game",
    "Polka___",
    "Popular pencil and paper game",
    "Popular pencil-and-paper game",
    "The i's have them",
    "Polka followers",
    "H or S, in Morse code",
    "Pointillist's marks"
  ],
  "glee": [
    "Extreme happiness",
    "Absolute delight",
    "Fox series about musical high schoolers",
    "Delight",
    "Great delight",
    "TV show that launched more than 200 songs onto the Billboard Hot 100",
    "Kind of club for singers",
    "Unbridled joy",
    "Sheer delight",
    "Tickled-pink feeling",
    "TV show set in William McKinley High School",
    "Elation",
    "Giddy happiness",
    "Exuberance",
    "___ club",
    "Exuberance",
    "Jubilation",
    "Feeling upon winning the lottery",
    "Utter happiness",
    "\"On cloud nine\" feeling",
    "Lottery winner's feeling",
    "Jubilance",
    "Contest winner's feeling",
    "Elation",
    "Elation",
    "Fox series set in William McKinley High School",
    "What you may squeal with",
    "Elation",
    "Much merriment",
    "___ club (singing group)",
    "Lottery winner's feeling",
    "Hit Fox show",
    "Lottery winner's feeling",
    "TV show set at William McKinley High School",
    "Great happiness",
    "Hit show with New Directions singers",
    "Merriment",
    "High spirits",
    "Exhilaration",
    "Mirth",
    "Pleasure plus",
    "High spirits",
    "\"Yippee!\" feeling",
    "Delight",
    "Pure delight",
    "___ club (singers' group)",
    "Much merriment",
    "Merriment",
    "Hilarity",
    "Hilarity",
    "High spirits",
    "Elation",
    "Jubilation",
    "Cause of big smiles",
    "Merriment",
    "A cappella part song",
    "Part song",
    "Kind of club",
    "Exultation",
    "___ club",
    "Mirth",
    "Delight",
    "Merriment",
    "Jollity",
    "Mirth",
    "Jubilation",
    "Jollity",
    "Jollity",
    "Delight",
    "Tickled-pink feeling",
    "Tickled-pink feeling",
    "High spirits",
    "Winner's feeling",
    "Mirth",
    "Mirth",
    "Tickled-pink feeling",
    "Mirth",
    "Kind of club",
    "Merriment",
    "Kind of club",
    "Part song",
    "Exuberance",
    "Jubilation",
    "Open delight",
    "Elation",
    "Jubilant gaiety",
    "Merriment",
    "Unaccompanied part song",
    "18th-century part song",
    "___ club",
    "Euphoria",
    "Jollity",
    "Near-rapture",
    "Tickled-pink feeling",
    "More than cheerfulness",
    "Merriment"
  ],
  "mbas": [
    "Degrees for C.E.O.s",
    "Some corporate holdings, for short?",
    "Degs. for entrepreneurs",
    "Degs. for C.E.O.s",
    "Degs. for entrepreneurs",
    "Some C.E.O.s' degs.",
    "51-Acrosses often hold them",
    "Grads who've demonstrated firm knowledge?",
    "Many corp. hirees have them",
    "Execs' degrees, often",
    "Degrees for many execs",
    "Some future execs",
    "Some Wharton degs.",
    "Degs. held by Bloomberg and G. W. Bush",
    "Many consultants, for short",
    "Degrees for C.F.O.s",
    "Corp. recruiters' targets",
    "Degs. for future financiers",
    "Some Wharton degs.",
    "Degs. held by Romney and Bush",
    "Some Kellogg grads",
    "Some Wharton alums",
    "Execs' degs.",
    "Corp. recruits",
    "Suits often hold them",
    "Degrees held by many C.E.O.'s",
    "Many corp. hirees",
    "Many mgrs. have them",
    "Degs. for corporate types",
    "Harvard and Wharton degs.",
    "Degrees for corp. execs",
    "Many corp. recruits",
    "Wharton degs.",
    "Corp. recruits",
    "Some Wharton alums",
    "Corp. recruits, often",
    "Wharton degs.",
    "Stern School degs.",
    "Many C.E.O.'s",
    "Many new corp. hires",
    "C.E.O.'s degs.",
    "New corp. hires",
    "New corp. hires",
    "C.E.O.'s may have them",
    "Wall St. types",
    "Wall St. hirees",
    "Wharton offerings: Abbr.",
    "Degs. for execs",
    "New corp. hires",
    "Many Forbes readers have them",
    "Budding entrepreneurs, for short",
    "Corp. recruits",
    "Degrees for C.E.O.'s",
    "Budding entrepreneurs, for short",
    "Company recruits, for short",
    "Wharton grads"
  ],
  "oryx": [
    "\"___ and Crake\" (Margaret Atwood novel)",
    "African antelope",
    "Margaret Atwood's \"___ and Crake\"",
    "Serengeti roamer",
    "African antelope",
    "Variety of antelope",
    "\"Scimitar-horned\" creature",
    "African antelope",
    "Cousin of a gazelle",
    "African antelope",
    "Relative of a gemsbok",
    "African antelope",
    "Antelope related to the gemsbok",
    "Large African antelope",
    "Serengeti antelope",
    "Large African antelope",
    "Animal some believe to be the source of the unicorn myth",
    "Straight-horned African animal",
    "African antelope",
    "African antelope",
    "Endangered antelope",
    "African grazer",
    "African antelope",
    "Endangered antelope",
    "African antelope",
    "Endangered antelope"
  ],
  "cleo": [
    "Queen of the Nile, informally",
    "Miss ___, famed TV psychic",
    "Royal role for Liz",
    "Egyptian queen, for short",
    "Legendary ruler of Egypt, informally",
    "Former TV psychic Miss ___",
    "Title female role in Shakespeare, informally",
    "Role for Liz Taylor",
    "Queen who styled herself as Isis, familiarly",
    "Last Ptolemaic ruler, informally",
    "Jazz singer Laine",
    "Role for Liz in '63",
    "Queen of the Nile, briefly",
    "Big role for Liz Taylor",
    "Marc Antony's lover, informally",
    "Miss ___ (late TV psychic)",
    "Queen killed by an asp, familiarly",
    "Queen of the Nile, informally",
    "Classic role for Liz Taylor",
    "Elizabeth Taylor role of '63",
    "Laine of jazz fame",
    "Royal role for Liz Taylor",
    "'63 Liz Taylor role",
    "Lethally poisoned ruler, familiarly",
    "1963 role for Liz",
    "Elizabeth Taylor role, in brief",
    "Fatally poisoned royal, for short",
    "Egypt's last ruling Ptolemy, familiarly",
    "'63 role for Liz",
    "'63 Liz Taylor role",
    "Jazzy Laine",
    "Last ruler of the Ptolemaic dynasty, informally",
    "Nile queen, informally",
    "Queenly role for Liz",
    "Queen of the 45-Across, for short",
    "Jazz singer Laine",
    "Nile queen, informally",
    "\"Pinocchio\" character voiced by Mel Blanc",
    "Jazz singer Laine",
    "Classic Liz Taylor part",
    "Goldfish in \"Pinocchio\"",
    "Disney goldfish",
    "Claudette Colbert title role, in brief",
    "Marc Antony's love, for short",
    "1963 role for Liz",
    "1963 role for Liz",
    "1917 Theda Bara role, informally",
    "Egyptian queen, for short",
    "1963 role for Liz",
    "Laine of jazz",
    "900-line psychic Miss ___",
    "1917 role for 30-Down, informally",
    "TV psychic Miss ___",
    "One of the Staple Singers",
    "Splashy 1963 film title role, for short",
    "Memorable 1964 Florida hurricane",
    "Role for Liz",
    "Bitten one, for short",
    "1963 role for Liz",
    "1963 Liz Taylor role",
    "Nile queen, informally",
    "Nile queen, informally",
    "Role for Liz in '63",
    "Singer Laine",
    "Nile queen, for short",
    "Singer Laine",
    "Singer Laine"
  ],
  "oink": [
    "Sound from a piggy",
    "Pen sound",
    "\"Old MacDonald\" sound",
    "It comes out of a pen, either with or without its first letter",
    "Sound from a pen",
    "Trough call",
    "Sound from a piggery",
    "Sound from a pen",
    "One of eight in \"Old MacDonald Had a Farm\"",
    "[Snort]",
    "Pen noise",
    "Sound on Old MacDonald's farm",
    "Sound on Old MacDonald's farm",
    "Emanation from a pen",
    "Swinish sound",
    "Pen sound",
    "Pig sound",
    "Word repeated before \"here,\" in song",
    "Porker's sound",
    "Farm sound",
    "Pig's grunt",
    "Pig sound",
    "Pig's sound",
    "Porcine protest",
    "\"Old MacDonald\" sound",
    "Piggy plaint",
    "Sty sound",
    "Sow sound",
    "... a porker",
    "Cousin of a squeal",
    "Sound piggish",
    "Barnyard sound",
    "Cry at Old MacDonald's",
    "Pig's sound",
    "Sty sound",
    "Sound on Old MacDonald's farm",
    "Farm sound",
    "Sty cry",
    "Pigpen cry",
    "Farm cry",
    "Barnyard noise",
    "Pen sound",
    "Sow's squeal",
    "Squeal",
    "Farm cry",
    "Farm sound",
    "Sound in \"Old MacDonald\"",
    "Sound on Old MacDonald's farm",
    "Rooter's noise?",
    "Piggish remark",
    "Farm call",
    "Sty cry",
    "Word from a pen",
    "Sound from a pen",
    "Babe's sound",
    "Sound at Old MacDonald's",
    "Berkshire response",
    "Sound for Old MacDonald",
    "Farm complaint",
    "Sow sound",
    "It comes from a pen",
    "Farm sound",
    "Sty sound",
    "Porcine cry",
    "Word from a pen"
  ],
  "beds": [
    "Places for flowers and oysters",
    "They might be twins",
    "Spots for shams",
    "Inn capacity",
    "Spots for bulbs",
    "Twins, e.g.",
    "Hotel units",
    "Garden plots",
    "Where flowers and oysters grow",
    "Their heads and feet are usually the same distance from the ground",
    "Hotel reservation specification",
    "Hospital capacity",
    "They're raised in some gardens",
    "Hospital capacity",
    "Kings and queens",
    "Places for sprigs",
    "Twins, e.g.",
    "Hotel and hospital features",
    "Hospital count",
    "4-Down inventory",
    "Twins, possibly",
    "Some are kings and queens",
    "Hospital capacity",
    "Hospital units",
    "Places to retire",
    "Tanning salon fixtures",
    "They have headboards and footboards",
    "Mattress sites",
    "Hospital count",
    "Features of greenhouses",
    "Sacks",
    "Retirement spots?",
    "Four-poster and others",
    "Garden divisions",
    "Mattress holders",
    "Bunks",
    "Infirmary count",
    "Flower sites",
    "Inn inventory",
    "People retire to these spots",
    "Hospital units",
    "Berths",
    "With 40A, garden sites",
    "Fourposters, e.g.",
    "Nurses' responsibility",
    "Hospital capacity",
    "*Queens ... oysters ... flowers",
    "Hospital capacity",
    "Nap sacks",
    "Resting places",
    "Hotel capacity",
    "Strata",
    "Penitentiary count",
    "Where oysters hang out",
    "Infirmary capacity",
    "Dorm items",
    "Garden sites",
    "Four-posters",
    "Cots and cradles",
    "Hospital count",
    "See 58 Down",
    "Hospital capacity",
    "Some of them are twins"
  ],
  "dash": [
    "Sprinkle, as of salt",
    "Dot's counterpart in Morse code",
    "Sprint",
    "Bit of salt",
    "Jet (off)",
    "Recipe amount",
    "Pinch in the kitchen",
    "Meet component",
    "Go quickly",
    "Usain Bolt event",
    "Destroy, as hopes",
    "Sprint",
    "Track event",
    "Tach site",
    "Little bit",
    "Recipe amount",
    "Gauge site, for short",
    "100-yard race, e.g.",
    "Imprecise recipe amount",
    "Bit of Morse code",
    "-",
    "Tach's location",
    "--",
    "Place for a gauge, informally",
    "Bit of salt",
    "Salt amount",
    "Code unit",
    "Track meet event",
    "Word repeated in \"Now ___ away! ___ away! ___ away ...!\"",
    "Telegraphy signal",
    "100-meter race, e.g.",
    "Sprint",
    "Sprinter's event",
    "Run ?à or ruin",
    "Certain race",
    "Pizazz",
    "Ruin, as hopes",
    "Salt amount",
    "Short sprint",
    "Short run",
    "It may be mad",
    "100-meter, e.g.",
    "Short race",
    "Track event",
    "A little salt",
    "Soupcon",
    "Elan",
    "Track event",
    "Track event",
    "Recipe amount",
    "Pinch",
    "Sprint",
    "T, in code",
    "Hyphen's cousin",
    "Break into bits",
    "Recipe approximation",
    "Small amount"
  ],
  "avis": [
    "\"We try harder\" auto rental company",
    "Motorcycle pioneers ___ and Effie Hotchkiss",
    "Parent company of Zipcar",
    "Commercial name that becomes another commercial name if you move its first letter to the end",
    "Car rental partner of Budget",
    "Dollar alternative",
    "Hertz rival",
    "Hertz rival",
    "Sister company of Budget",
    "Owner of Zipcar",
    "Car rental giant",
    "Company that acquired Zipcar in 2013",
    "Alternative to Thrifty or Dollar",
    "\"We try harder\" company",
    "Hertz rival",
    "Budget partner",
    "Hertz rival",
    "Enterprise adversary",
    "National rival",
    "Thrifty alternative",
    "National competitor",
    "Hertz rival",
    "Alamo rival",
    "Rara ___",
    "See 3-Down",
    "Alamo competitor",
    "Hertz rival",
    "Dollar competitor",
    "Company name that becomes another company name if you move its first letter to the end",
    "Company with the stock symbol CAR",
    "Warren who founded a rental car company",
    "Company started in 1946 at the Detroit and Miami airports",
    "Flier at the Forum",
    "Warren of the car rental business",
    "Enterprise alternative",
    "\"We try harder\" company",
    "Alamo battler?",
    "Dollar or Budget competitor",
    "Hertz rival",
    "Car rental company founder Warren",
    "National competitor",
    "Hertz competitor",
    "Enterprise rival",
    "Dollar alternative",
    "Hertz competitor",
    "Dollar rival",
    "Enterprise rival",
    "Airport counter name",
    "National competitor",
    "#2, famously",
    "Hertz rival",
    "Enterprise rival",
    "Dollar competitor",
    "Rent-A-Wreck competitor",
    "Famous #2",
    "Budget competitor",
    "Hertz rival",
    "National competitor",
    "Hertz competitor",
    "No. 2",
    "Budget competitor",
    "Budget rival",
    "\"We try harder\" company",
    "Dollar competitor",
    "Company with cars",
    "Pioneer company, since 1972, in computer reservations",
    "Hertz rival",
    "Dollar competitor",
    "Thrifty competitor",
    "Dollar rival",
    "Rara ___",
    "Budget alternative",
    "Hertz rival",
    "\"We try harder\" company",
    "Hertz rival",
    "National competitor",
    "Budget rival",
    "Dollar alternative",
    "Hertz rival",
    "Alamo rival",
    "Enterprise competitor",
    "Hertz rival",
    "Alamo rival",
    "Airport counter name",
    "Hertz rival",
    "National competitor",
    "Alamo competitor",
    "Hertz alternative",
    "Budget rival"
  ],
  "elem": [
    "Like the first through fifth grades: Abbr.",
    "He, but not she: Abbr.",
    "Molybdenum, for one: Abbr.",
    "___ sch.",
    "___ sch.",
    "He, for one: Abbr.",
    "Low-grade?: Abbr.",
    "Like a sch. before middle school",
    "He is one",
    "Kind of school after nursery school: Abbr.",
    "Ca, Ce, Co or Cu",
    "K-5, schoolwise",
    "K-5: Abbr.",
    "Radon or radium: Abbr.",
    "Kind of sch.",
    "Sch. type",
    "K-6 sch. designation",
    "Kind of sch.",
    "Ca, Co or Cu",
    "Part of 51-Across: Abbr.",
    "I, for one",
    "___ sch.",
    "B or C, but not A or D: Abbr.",
    "Na, Ne, Ni or No",
    "Something on a table: Abbr.",
    "K-6 sch. designation",
    "Grades 1-6: Abbr.",
    "Low-grade?: Abbr.",
    "He or I, but not you: Abbr.",
    "I, O or U, but not A or E: Abbr.",
    "Kind of sch.",
    "Basic: Abbr.",
    "K-6: Abbr.",
    "Kind of sch.",
    "Boron or bohrium: Abbr.",
    "___ sch.",
    "Kind of sch.",
    "School before middle school: Abbr.",
    "Kind of sch.",
    "Low-grade?: Abbr.",
    "K, for one: Abbr.",
    "K-6, as a sch.",
    "Ca, Ga or Pa",
    "Kind of sch.",
    "Chem. table component",
    "I, for one: Abbr.",
    "Kr or K, say",
    "K-6: Abbr.",
    "I, O or U, but not A or E: Abbr.",
    "Gr. 1-6",
    "Kind of sch.",
    "Gr. 1-6",
    "Kind of sch.",
    "Grades 1-6: Abbr.",
    "Item on a table: Abbr.",
    "Basic: Abbr.",
    "K-6: Abbr.",
    "Kind of sch.",
    "Sch. type",
    "Kind of sch.",
    "K-6, schoolwise",
    "Kind of sch.",
    "Kind of sch.",
    "I, O or U: Abbr.",
    "First sch.",
    "Certain sch.",
    "Kind of sch.",
    "K-6"
  ],
  "chap": [
    "Crack and redden, as lips",
    "Get red in the face?",
    "Fellow",
    "Bloke",
    "English fellow",
    "English guy",
    "Fellow",
    "Bloke",
    "Bloke",
    "\"Cheerio\" sayer",
    "British buddy",
    "Bloke",
    "Get cracking?",
    "Mate",
    "Crack, as lips",
    "Require balm, say",
    "Fellow",
    "Fellow",
    "\"Jolly old\" fellow",
    "Bloke",
    "Bloke",
    "Bloke",
    "Fellow",
    "Fellow",
    "Fellow",
    "Roughen in cold weather",
    "Get rough",
    "Fellow",
    "Bloke",
    "Redden and crack",
    "Bloke",
    "Crack slightly",
    "Crack from the cold",
    "British fellow",
    "Fella",
    "Old boy",
    "Crack in the cold",
    "Cause to have cracks",
    "Crack in the cold wind",
    "Fellow",
    "Bloke",
    "Roughen",
    "Fellow",
    "Bloke",
    "Split in the cold, perhaps",
    "Bloke",
    "Lip-balm target",
    "Crack, in a way",
    "Bloke",
    "Bloke",
    "Fellow",
    "Fellow",
    "Bloke",
    "Split",
    "React to exposure, maybe",
    "Crack and redden",
    "Crack in the cold, maybe",
    "Crack, in a way",
    "Bloke",
    "Jolly good fellow",
    "Fellow",
    "Sport",
    "Fellow"
  ],
  "herb": [
    "Basil or dill",
    "Ganja",
    "Marjoram, for one",
    "Good name for a gardener",
    "Chervil or chives",
    "Parsley, sage, rosemary or thyme",
    "Element in traditional medicine",
    "Basil, e.g.",
    "Cannabis, e.g.",
    "Marijuana, slangily",
    "Parsley, sage, rosemary or thyme",
    "Oregano, for one",
    "Rosemary, for one",
    "Basil or bugbane",
    "Folk medicine plant",
    "Spice's cousin",
    "___ Alpert & the Tijuana Brass",
    "Rosemary, for one",
    "Tea flavoring",
    "Cicely or tarragon",
    "Parsley, sage, rosemary or thyme",
    "Kind of garden",
    "Pimpernel or prairie clover",
    "Spearmint, e.g.",
    "Natural flavoring",
    "Flavor lender",
    "Mint or chive",
    "Parsley or bay leaf",
    "Cicely, e.g.",
    "Basil or oregano",
    "Mint, e.g.",
    "Parsley or sage",
    "Parsley or sage",
    "33-Across, e.g.",
    "Tarragon or chervil",
    "Caraway, e.g.",
    "Angelica, for one",
    "Thyme, e.g.",
    "It may be biennial",
    "Sage, for one",
    "Ginseng, e.g.",
    "Rosemary, for one",
    "Mint or sage",
    "Sage or thyme, e.g.",
    "Oregano, e.g.",
    "Peaches' singing partner",
    "Spearmint, e.g.",
    "Mint or cumin"
  ],
  "irks": [
    "Ruffles the feathers of",
    "Really bugs",
    "Ruffles",
    "Nettles",
    "Ticks off",
    "Annoys",
    "Bugs",
    "Peeves",
    "Vexes",
    "Bugs",
    "Ticks off",
    "Gets to",
    "Vexes",
    "Rankles",
    "Bothers",
    "Peeves",
    "Needles",
    "Peeves",
    "Annoys",
    "Peeves",
    "Nettles",
    "Grates on",
    "Bugs",
    "Rubs the wrong way",
    "Gets to",
    "Rubs the wrong way",
    "Miffs",
    "Annoys",
    "Rubs the wrong way",
    "Vexes",
    "Peeves",
    "Tees off",
    "Bugs",
    "Nettles",
    "Bugs",
    "Rubs the wrong way",
    "Bothers",
    "Grates on",
    "Exasperates",
    "Vexes",
    "Is vexing",
    "Ticks off",
    "Ticks off",
    "Miffs",
    "Bugs",
    "Nettles",
    "Bugs",
    "Ruffles",
    "Rubs the wrong way",
    "Gets on the nerves of",
    "Gets under one's skin",
    "Irritates",
    "Bugs",
    "Exasperates",
    "Bothers",
    "Ticks off"
  ],
  "gels": [
    "Some toothpastes and hair goops",
    "Extended-wear manicure options",
    "Gets along (with)",
    "Goes from liquid to solid, say",
    "Salon supply",
    "Comes together",
    "They may make your hair stand on end",
    "Comes together",
    "Hair stiffeners",
    "Hair goops",
    "Hardens",
    "Gets set",
    "Gets set",
    "Dippity-do and others",
    "Hair goops",
    "Gets set",
    "Stylist's stock",
    "Some deodorants",
    "Alternatives to creams",
    "Salon applications",
    "Some shampoos",
    "Some toothpastes",
    "Salon goos",
    "Breast implant materials",
    "Many applications",
    "Molecular biology lab preparations",
    "Salon supply",
    "Sets",
    "Hair products",
    "Hair goops",
    "Hair goops",
    "Sets",
    "Firms",
    "Hair goops",
    "Hair controllers",
    "Hair goops",
    "They can be hair-raising",
    "Shower soaps",
    "Salon supply",
    "Salon stock",
    "Toothpaste types",
    "Gets set",
    "Hardens",
    "Hair applications",
    "Alternatives to pastes",
    "Sets",
    "Hair-grooming aids",
    "Hairdresser's preparations",
    "Becomes definite"
  ],
  "lsat": [
    "Aspiring atty.'s exam",
    "Exam with logic questions, for short",
    "Prep class subject, for short",
    "Hurdle for a future atty.",
    "Pre-bar challenge, briefly",
    "Exam for a future D.A.",
    "Future J.D.'s hurdle",
    "Future D.A.'s hurdle",
    "Aspiring D.A.'s exam",
    "Exam for a wannabe A.D.A.",
    "Kaplan course subj.",
    "Hurdle for an aspiring atty.",
    "Kaplan course subj.",
    "Future atty.'s exam",
    "Early challenge for Barack and Michelle Obama, for short",
    "What a future attorney must now take by tablet, for short",
    "Exam with two logical reasoning sects.",
    "Exam with a max. score of 180",
    "Its min. score is 120",
    "Exam for a future atty.",
    "What the Clintons each took before they met, in brief",
    "Trial for a future atty.",
    "Future attorney's hurdle, for short",
    "Kaplan course subj.",
    "Exam with logic games, in brief",
    "Aspiring D.A.'s exam",
    "Exam for future attys.",
    "Exam with a reading comprehension sect.",
    "Exam with a section known as \"Logic Games,\" for short",
    "Exam with logic games, briefly",
    "Practice exam?",
    "Future atty.'s hurdle",
    "Focus of some prep books",
    "It includes a 35-min. writing sample",
    "Future atty.'s challenge",
    "Aspiring atty.'s exam",
    "Hurdle for an aspiring \"Esq.\"",
    "Future atty.'s exam",
    "Subject of Kaplan or Princeton Review prep",
    "Exam administered qtly.",
    "Aspiring atty.'s exam",
    "Exam for an aspiring D.A.",
    "Challenge in \"Legally Blonde,\" for short",
    "Kaplan course subj.",
    "Reqmt. for certain graduate studies",
    "Obstacle for an aspiring D.A.",
    "Future D.A.'s hurdle",
    "Exam for an aspiring atty.",
    "It's taken by some coll. seniors",
    "Future atty.'s exam",
    "Exam administered four times a yr.",
    "Exam for future attys.",
    "Future atty.'s exam",
    "Atty. wannabe's hurdle",
    "It includes an analytical reasoning sect.",
    "Exam for an aspiring Esq.",
    "A perfect score on it is 180: Abbr.",
    "It might give you legal problems: Abbr.",
    "Exam with a max. score of 180",
    "Some coll. seniors take it for Harvard and Yale, but not for Princeton",
    "Exam for a wannabe atty.",
    "Exam for an aspiring J.D.",
    "Exam for would-be attys.",
    "Future atty.'s challenge",
    "It includes a sect. of logic games",
    "Exam with sections known as \"arguments,\" for short",
    "Aspiring atty.'s hurdle",
    "Exam with a reading comprehension section, for short",
    "180 is its max. score",
    "Exam for A.B.A.-approved schools",
    "Future atty.'s hurdle",
    "Aspiring atty.'s exam",
    "Potential atty.'s exam",
    "Some college srs. take it",
    "It's taken by some coll. seniors",
    "Would-be J.D.'s exam",
    "Its max. score is 180",
    "Exam for a would-be atty.",
    "Its scores range from 120 to 180: Abbr.",
    "Exam for a future atty.",
    "Hurdle for some college srs.",
    "Some coll. seniors take it",
    "Aspiring J.D.'s exam",
    "Exam for a future D.A.",
    "Kind of score, for one doing postgrad studies",
    "Aspiring atty.'s exam",
    "Exam for future attys.",
    "Future atty.'s exam",
    "Aspiring atty.'s exam",
    "Future atty.'s hurdle",
    "Its highest possible score is 180: Abbr.",
    "The D.A. probably took it once",
    "Future atty.'s hurdle",
    "Future D.A.'s exam",
    "D.A.-to-be's hurdle",
    "Aspiring J.D.'s exam",
    "Future atty.'s hurdle",
    "Hurdle for a future 64-Across: Abbr.",
    "Hurdle for an aspiring J.D.",
    "Future atty.'s hurdle",
    "Future atty.?Æs exam",
    "Future J.D.'s hurdle",
    "Hurdle for a future litigator: Abbr.",
    "Future J.D.'s hurdle",
    "Half-day exam, briefly",
    "Future J.D.'s hurdle",
    "Future atty.'s exam",
    "Future attorney's hurdle: Abbr.",
    "Exam for attys.-to-be",
    "Relative of an M.C.A.T. or G.R.E.",
    "Future J.D.'s obstacle",
    "Its score scale is 120 to 180",
    "Future atty.'s exam",
    "Aspiring atty.'s hurdle",
    "Future atty.'s hurdle",
    "Atty.-to-be's exam",
    "Would-be J.D.'s hurdle",
    "Future atty.'s challenge",
    "Future J.D.'s hurdle",
    "Professional school hurdle: Abbr.",
    "Future attorney's exam: Abbr.",
    "Exam for college srs.",
    "E.T.S. offering",
    "Coll. senior's exam",
    "Aspiring atty.'s exam",
    "Future atty.'s exam",
    "Hurdle for an atty.-to-be",
    "Hurdle for an aspiring atty.",
    "Grueling 3-Down, for short"
  ],
  "bmaj": [
    "Key with five sharps: Abbr.",
    "Key using all the black keys: Abbr."
  ],
  "iama": [
    "Paul Simon's \"___ Rock\"",
    "\"___ Rock\"",
    "Simon & Garfunkel's \"___ Rock\"",
    "Meaning of \"Ich bin ein\" in J.F.K.'s quote",
    "Simon & Garfunkel's \"___ Rock\"",
    "\"___ Strange Loop,\" 2007 Douglas Hofstadter book",
    "\"___ Rock\" (Simon & Garfunkel hit)",
    "\"___ Dancer\" (1973 Nureyev documentary)",
    "Paul Simon's \"___ Rock\"",
    "\"___ Dancer\" (Nureyev documentary)",
    "\"___ Dancer\" (Nureyev documentary)",
    "\"___ Rock\"",
    "\"___ Rock\"",
    "Paul Simon's \"___ Rock\"",
    "\"___ Camera\"",
    "Dylan's \"___ Lonesome Hobo\"",
    "\"___ Camera\"",
    "\"___ Simple Man\" (#1 Ricky Van Shelton song)",
    "\"___ Camera\"",
    "\"___ lineman for the county\" (Glen Campbell lyric)",
    "\"___ Union Woman\" (old labor song)",
    "\"___ Rock\" (Simon & Garfunkel hit)",
    "\"___ Camera\"",
    "\"___Camera\"",
    "\"___ Camera\""
  ],
  "geog": [
    "Mapmaker's subj.",
    "Sci. with maps",
    "Trivial Pursuit category: Abbr.",
    "Popular Sporcle subj.",
    "Elem. sch. class",
    "Atlas maker's subj.",
    "An earth sci.",
    "Trivial Pursuit category: Abbr.",
    "H.S. subject",
    "Mercator's field: Abbr.",
    "Rand McNally subj."
  ],
  "loeb": [
    "Singer Lisa",
    "Grammy recipient Lisa",
    "Lisa with the 1994 hit \"Stay\"",
    "Singer Lisa",
    "Harvard University Press's ___ Classical Library",
    "Leopold's 1924 co-defendant",
    "\"Stay\" singer Lisa",
    "Central Park's ___ Boathouse",
    "Lisa with the 1994 #1 hit \"Stay (I Missed You)\"",
    "Financial writer Marshall",
    "Banker/philanthropist Solomon",
    "Leopold's partner in crime",
    "Financial writer Marshall",
    "Editor Marshall of financial publications",
    "Lisa with the 1997 hit \"I Do\"",
    "Harvard University Press's ___ Classical Library",
    "Richard ___, Clarence Darrow defendant",
    "Trial of the Century defendant",
    "Lisa with the #1 hit \"Stay (I Missed You)\"",
    "Leopold's partner in crime",
    "Leopold's 1920s co-defendant",
    "1924 co-defendant",
    "Leopold's partner in a sensational 1924 trial",
    "Financial writer Marshall",
    "Singer Lisa with the 1994 #1 hit \"Stay\"",
    "___ Classical Library, 500+ volume series begun in 1911",
    "Leopold's co-defendant in 1920's crime",
    "Leopold's 1920's co-defendant",
    "Famous murder defendant of the 1920?Æs",
    "Leopold's celebrated murder co-defendant of the 1920's",
    "See 52-Across",
    "Pop singer Lisa",
    "\"Stay\" singer Lisa",
    "Leopold and ___ (big 1920's murder case)",
    "Darrow client",
    "Leopold's partner in crime",
    "Money writer Marshall ___",
    "Leopold and ___ (1920's murder case)",
    "Darrow client, 1924",
    "Leopold's partner in crime"
  ],
  "cani": [
    "\"Pretty please?\"",
    "Request accompanying puppy dog eyes, maybe",
    "\"Ple-e-e-ease?\"",
    "\"Please, please, please?\"",
    "\"Pretty please?\"",
    "\"Is it O.K., mom?\"",
    "Child's plea",
    "\"Please, Mommy ... Please, Daddy?\"",
    "\"Pretty please?\"",
    "Nagging question?",
    "\"Go ask your mother\" elicitor",
    "Request often accompanied by \"please\"",
    "\"Ple-e-ease?\"",
    "Tot's plea",
    "\"Please?\"",
    "\"Please?\"",
    "Tot's plea",
    "Childish plea",
    "Kid's query",
    "Child's plea",
    "Child's request",
    "Child's plea",
    "\"Please?\"",
    "Pleading query",
    "Kid's plea",
    "Kid's plea",
    "\"___ Get a Witness\" (Marvin Gaye hit)",
    "\"___ help?\"",
    "\"Pretty please?\"",
    "\"Pretty please?\"",
    "Pleading child's words",
    "Question to mom or dad",
    "Kid's phrase of request"
  ],
  "sang": [
    "Violated a code of silence",
    "Blabbed",
    "Was a rat",
    "With 42-Across, coolness of mind",
    "Squealed",
    "Serenaded",
    "Was a ratfink",
    "Provided backup, say",
    "Caroled",
    "Acted like a rat",
    "Provided backup, in a way",
    "Spilled the beans",
    "Turned state's evidence",
    "Violated the \"code of silence\"",
    "Told all to the cops",
    "Turned state's evidence",
    "Emulated a rat",
    "Spilled the beans",
    "Ratted",
    "Crooned",
    "Auditioned for \"American Idol\"",
    "Showed disloyalty",
    "Performed ballads, e.g.",
    "Trolled",
    "Did a number",
    "Told all",
    "Was a fink",
    "Warbled",
    "Performed an aria",
    "Ratted",
    "Acted the fink",
    "Was trilling",
    "Was in a choir",
    "Snitched",
    "Belted out",
    "Turned state's evidence",
    "Blabbed to the feds, say",
    "Named names",
    "Ratted, in mob lingo",
    "Participated in a choir",
    "Belted one out",
    "Warbled",
    "Tattled",
    "Crooned",
    "Caroled",
    "Caroled",
    "Emulated the sirens",
    "Trilled",
    "Warbled",
    "Belted one out",
    "Confessed",
    "Ratted",
    "Squealed"
  ],
  "neil": [
    "Diamond who went platinum",
    "Author Gaiman",
    "With 44-Down, only playwright to have a New York City theater named after him while still alive",
    "Tennant of the Pet Shop Boys",
    "Novelist Gaiman",
    "Author Gaiman of the \"Sandman\" series",
    "Masculine name that sometimes follows Mac-",
    "Fantasy author Gaiman",
    "First name of the first man to walk on the moon",
    "The \"N\" of TV's NPH",
    "Late playwright Simon",
    "Moonwalker Armstrong",
    "Singer Diamond or Young",
    "Diamond with 21 platinum albums",
    "Justice Gorsuch",
    "Justice Gorsuch",
    "Buzz preceder, famously",
    "Simon of the stage",
    "Gaiman who wrote \"American Gods\" and \"Coraline\"",
    "Astrophysicist ___ deGrasse Tyson",
    "2015 Oscars host ___ Patrick Harris",
    "Simon who won a Tony for writing \"The Odd Couple\"",
    "2009 Newbery-winning author Gaiman",
    "Armstrong who said \"The Eagle has landed\"",
    "Young musician?",
    "\"Cosmos\" host ___ deGrasse Tyson",
    "Simon of Broadway",
    "Brother of George W. and Jeb",
    "Dog on TV's \"Topper\"",
    "Actor ___ Patrick Harris",
    "Singer Young or Sedaka",
    "Diamond with many cuts",
    "Moonmate of Buzz",
    "Two-time Hugo Award winner Gaiman",
    "Diamond on a record player",
    "QB O'Donnell",
    "Singer Sedaka",
    "Moonwalking Armstrong",
    "Younger brother of George W. and Jeb",
    "Young of Crosby, Stills, Nash & Young",
    "Diamond of note",
    "___ Jordan, who wrote \"The Crying Game\"",
    "___ Kinnock, 1980's-90's British Labor Party leader",
    "Pulitzer-winning writer Sheehan",
    "One of the Bush brothers",
    "Diamond of note",
    "Dramatist Simon who wrote \"Plaza Suite\"",
    "Diamond who sang \"America\"",
    "Musical Young",
    "Singer Sedaka",
    "Buzz's moonmate",
    "Astronaut Armstrong",
    "Diamond with a mike",
    "Diamond on stage",
    "Filmmaker Jordan",
    "Astronaut Armstrong",
    "Simon or Diamond",
    "Playwright Simon",
    "Buzz's companion on the moon",
    "Diamond of note",
    "Astronaut Armstrong",
    "Diamond of records",
    "Writer-director Jordan",
    "Actor ___ Patrick Harris",
    "Diamond or Sedaka",
    "Moon man Armstrong",
    "Diamond of music",
    "Actor ___ Patrick Harris",
    "Pulitzer writer Sheehan",
    "QB O'Donnell",
    "Moon walker Armstrong",
    "Singer Diamond",
    "Diamond of note",
    "Singer Young",
    "Man's name meaning \"champion\"",
    "Singer Young",
    "Pulitzer-winning writer Sheehan",
    "NASA's Armstrong",
    "Simon or Diamond",
    "Singer Young",
    "Quarterback O'Donnell",
    "Buzz's moonmate",
    "Diamond of records",
    "Astronaut Armstrong",
    "Director Jordan",
    "Pulitzer-winning writer on Vietnam___Sheehan",
    "Singer Sedaka",
    "Harris of \"Doogie Howser\"",
    "Astronaut Armstrong",
    "Playwright Simon",
    "Pulitzer-winning writer Sheehan",
    "Actor ___ Patrick Harris",
    "Screenwriter Jordan of \"The Crying Game\"",
    "Diamond of fame",
    "Buzz's lunar partner",
    "\"Topper\" pooch"
  ],
  "soft": [
    "What \"piano\" can mean",
    "Pillowy",
    "Like Brie",
    "Velvety",
    "Cushiony",
    "Like a cashmere sweater",
    "Like blackjack hands with an ace counted as 11",
    "Like decalcified water",
    "Piano, on a music score",
    "Tenderhearted",
    "Plushlike",
    "Downy",
    "Low on the Mohs scale",
    "Comfortable",
    "Downy",
    "Wishy-washy",
    "Partly melted",
    "Quick to yield",
    "Easily molded",
    "Downy",
    "Feathery",
    "Lenient",
    "Hardly hard",
    "Delicate",
    "Yielding",
    "Not strict",
    "Like 22-Down pillows",
    "\"Piano,\" literally",
    "Like some political support",
    "\"Quiet!,\" to Shakespeare",
    "Like some ice cream",
    "Flaccid",
    "Squeezable",
    "Yielding readily to pressure",
    "Cushy"
  ],
  "azur": [
    "Côte d'___",
    "Côte d'___ (French Riviera)",
    "France's Cote d'___",
    "Like la mer",
    "Color of la Mediterranee",
    "Shade of bleu",
    "Shade on the Riviera",
    "Shade of bleu",
    "Shade on the French Riviera",
    "France's Cote d'___",
    "Cote d'___",
    "Bleu hue",
    "Shade of bleu",
    "Cote d'___",
    "France's Cote d'___",
    "Cote d'___",
    "France's Cote d'___",
    "Mediterranean's Cote d'___"
  ],
  "izzy": ["Nickname for Isabelle or Isidore", "\"Miami Vice\" informant"],
  "fizz": [
    "Opposite of flatness",
    "Effervescence",
    "Opposite of flatness",
    "Effervescence"
  ],
  "amoi": [
    "Mine: Fr.",
    "Mine, in Le Mans",
    "Mine, in Marseille",
    "Mine, in Montreal",
    "Mine, to a mademoiselle",
    "Mine: Fr.",
    "What \"mia\" means across the Pyrenees",
    "Mine: Fr.",
    "Mine, to Mimi",
    "Mine: Fr.",
    "Madame's \"mine\"",
    "Mine, in Marseille",
    "\"Tout ___\" (\"All mine\": Fr.)",
    "Mine, in Marseille",
    "French mine?",
    "Mine, to Mimi",
    "French possessive",
    "Mine, in Amiens",
    "Mine, in Marseille",
    "Mine, in Marseille",
    "Mine, in Montreal",
    "Mine, to Marie",
    "Etienne's mine",
    "Opposite of yours, in Tours",
    "French possessive",
    "Mine, in Marseille",
    "Mine: Fr.",
    "Mine, to Manet",
    "Mine, in Marseille",
    "Parisian possessive",
    "Parisian possessive",
    "Not yours, in Tours",
    "Mine: Fr.",
    "French possessive",
    "French possessive",
    "Mine, on the Marne",
    "Mine, in France",
    "Parisian possessive",
    "Mine, to Mimi",
    "Mine, in Marseille",
    "Mine, in Montreal",
    "Mine, in Marseille",
    "Mine, to Monet",
    "Parisian possessive",
    "Mine, on the Marne",
    "Mine: Fr.",
    "Mine, in Marseille",
    "Mine, in Nimes",
    "Mine, in Marseille",
    "Mine, in Montreal",
    "Mine, in Marseille",
    "Mine, in Amiens",
    "Parisian possessive",
    "Parisian mine",
    "To me, to Mimi",
    "Mine, in Marseille",
    "Mine, in Amiens",
    "Mine, to Manet",
    "To me, in Paris",
    "\"Help!\" in France",
    "Marne mine",
    "Mine, in Aix",
    "To me, in Marseilles",
    "Mine, in Menton",
    "Mine, in Marseille",
    "French call for help",
    "Mine, to Marcel",
    "Mine: Fr."
  ],
  "wren": [
    "Member in the genus Troglodytes, so named for its tendency to enter dark crevices",
    "Tomtit is another name for it",
    "Bird on the South Carolina state quarter",
    "Small, brown bird",
    "Bird that sometimes sings in a duet",
    "Small bird with complex songs",
    "Bird whose head doesn't make a sound?",
    "Architect Sir Christopher ___",
    "Small songbird",
    "Carolina ___ (state bird)",
    "Wee warbler",
    "Carolina ___ (state bird)",
    "Small songbird",
    "State bird of Arizona or South Carolina",
    "St. Paul's Cathedral architect",
    "Carolina ___",
    "Drab songbird",
    "Small songbird",
    "Small songbird",
    "Small, brown songbird",
    "Cousin of a gnatcatcher",
    "House ___",
    "P. C. ___, \"Beau Geste\" novelist",
    "Troglodytes troglodytes",
    "Architect with an avian name",
    "Small songster",
    "South Carolina's state bird",
    "St. Paul's architect",
    "Singer with a wide range",
    "Small songbird",
    "Bird that perches with its tail erect",
    "Follower of Christopher or Carolina",
    "Sir Christopher the architect",
    "Cousin of a treecreeper",
    "Bird with speckled eggs",
    "Birdhouse bird",
    "House ___",
    "Architect whose epitaph says \"Reader, if you seek his monument, look around you\"",
    "Small warbler",
    "Word with house or Carolina",
    "Small songbird",
    "Wee warbler",
    "Small-but-loud songbird",
    "House ___",
    "St. Paul's architect",
    "St. Paul's architect",
    "Small songbird",
    "Songbird",
    "Chirpy bird",
    "Small songbird",
    "Bird that perches with its tail straight up",
    "Plump songbird",
    "Small songbird",
    "Small songbird",
    "Chelsea Hospital architect",
    "Warbler",
    "Little chirper",
    "Songbird",
    "Hedgerow nester",
    "Singer with wings",
    "Carolina ___ (little songster)",
    "St. Paul's architect",
    "Small songbird",
    "Songbird",
    "Architect of St. Paul's Cathedral",
    "St. Paul's architect",
    "\"Beau Geste\" author",
    "Valuable insect-eater",
    "Brownish songbird",
    "Songbird",
    "Birdhouse bird",
    "Birdhouse dweller",
    "St. Paul's architect Sir Christopher",
    "Birdhouse resident",
    "Astronomer-turned-architect",
    "St. Paul's architect",
    "Carolina ___",
    "Small songster",
    "Carolina ___",
    "\"Beau Geste\" author P. C. ___",
    "Tweety bird",
    "Songbird"
  ],
  "yipe": [
    "\"Jeepers!\"",
    "\"Eek!\"",
    "\"Jeepers!\"",
    "Cry of pain",
    "Cry of surprise",
    "\"Holy cow!\"",
    "\"Holy moly!\"",
    "\"Holy cow!\"",
    "\"Eek!\"",
    "\"Omigosh!\"",
    "\"Oh no!\"",
    "\"Egad!\"",
    "\"Good grief!\"",
    "\"Wow!\"",
    "\"Holy cow!\"",
    "Exclamation of surprise",
    "Cry of dismay",
    "\"Eek!\"",
    "Cry of surprise",
    "Cry of fright",
    "\"Omigod!\"",
    "\"Holy cow!\"",
    "\"Gosh!\"",
    "\"Holy moly!\"",
    "\"Oh, no!\"",
    "\"Holy Toledo!\"",
    "\"Holy moly!\"",
    "\"My goodness!\"",
    "\"Egad!\"",
    "\"Egad!\"",
    "Cry of pain",
    "\"Holy cow!\"",
    "Exclamation of surprise",
    "Cry of pain",
    "Cry of pain",
    "\"Oh, my!\""
  ],
  "noor": [
    "Former queen of Jordan",
    "Jordan's Queen ___",
    "Koh-i-___ diamond",
    "Koh-i-___ diamond",
    "Queen dowager of Jordan",
    "___ al-Hussein (Jordanian royal)",
    "American-born Jordanian queen",
    "Queen dowager of Jordan",
    "Former queen of Jordan",
    "Queen of Jordan",
    "Former Jordanian queen",
    "Jordan's Queen ___",
    "Koh-i-___ diamond",
    "Koh-i-___, once the world's largest known diamond",
    "Queen with a degree from Princeton",
    "American-born queen",
    "U.S.-born Jordanian queen",
    "Jordanian queen",
    "Jordanian queen",
    "Koh-i-___ diamond",
    "Jordan's Queen ___",
    "Queen who wrote \"Leap of Faith\"",
    "Former queen of Jordan",
    "Queen who wrote \"Leap of Faith\"",
    "American-born Jordanian queen",
    "Jordan's Queen ___",
    "Jordan's Queen ___",
    "Koh-i-___ diamond",
    "King Hussein's queen",
    "Hussein's queen",
    "Koh-i-___ (106-carat diamond)",
    "Koh-i-___ diamond",
    "American-born Queen of Jordan",
    "Queen of Jordan",
    "Hussein's queen",
    "Koh-i-___diamond",
    "Jordan's Queen ___",
    "King Hussein's queen",
    "Jordanian queen",
    "Hussein's queen",
    "Koh-i- ___ (famed diamond)",
    "Hussein's queen"
  ],
  "glen": [
    "Quiet valley",
    "Secluded valley",
    "___ Oaks, neighborhood in eastern Queens",
    "A stream might run through it",
    "Secluded valley",
    "Hidden valley",
    "Country/pop singer Campbell",
    "___ Rock, N.J.",
    "Campbell who sang \"By the Time I Get to Phoenix\"",
    "Place for a stream",
    "Verdant spot",
    "\"Galveston\" singer Campbell",
    "___ Rock, N.J.",
    "Narrow valley",
    "Vale",
    "Secluded valley",
    "Secluded spot",
    "Secluded valley",
    "Highland lowland?",
    "Secluded stream site",
    "Narrow valley",
    "Arizona's ___ Canyon Dam",
    "Secluded valley",
    "Secluded valley",
    "Isolated valley",
    "Spot for a stream",
    "Campbell who sang \"Rhinestone Cowboy\"",
    "Hidden valley",
    "Valley",
    "Valley ___, redundantly named California community",
    "Secluded valley",
    "Narrow valley",
    "Secluded area",
    "Place between hills",
    "Hidden valley",
    "\"Galveston\" crooner Campbell",
    "Rustic-sounding man's name",
    "Secluded area",
    "Secluded spot",
    "Arizona's ___ Canyon Dam",
    "Secluded valley",
    "Secluded valley",
    "Dale",
    "Hidden valley",
    "Secluded valley",
    "Secluded valley",
    "Campbell of country",
    "Secluded valley",
    "Secluded spot",
    "Singer Campbell",
    "Hidden valley",
    "Secluded vale",
    "Quiet valley",
    "Narrow valley",
    "___ Cove, L.I.",
    "Baltimore suburb ___ Burnie",
    "Narrow valley",
    "Site for a stream",
    "Narrow valley",
    "Dell",
    "Quiet valley",
    "Secluded valley",
    "Watkins ___, N.Y.",
    "___ Burnie, Md.",
    "Kind of plaid",
    "Secluded spot",
    "Singer Campbell",
    "Forest opening"
  ],
  "benz": [
    "Mercedes-___",
    "Luxury car nickname",
    "Mercedes-___",
    "Auto pioneer Karl",
    "Fancy wheels, familiarly",
    "Pioneering automaker",
    "Auto pioneer Karl",
    "Auto engine pioneer",
    "Patent-Motorwagen inventor",
    "Maker of the 1923 \"Teardrop\" racecar",
    "Daimler partner",
    "German auto pioneer",
    "Pioneering automaker",
    "Mercedes-___",
    "Auto pioneer Karl",
    "Mercedes-___",
    "Auto pioneer Karl",
    "German auto pioneer",
    "Luxury car name",
    "Auto pioneer",
    "Automobile pioneer",
    "Automotive pioneer",
    "Early German carmaker"
  ],
  "scad": [
    "Multitude",
    "Great deal",
    "Slew",
    "Whole bunch",
    "Whole bunch",
    "Slew",
    "Whole bunch",
    "Whole lot",
    "Multitude",
    "Whole slew",
    "Large number",
    "Large amount",
    "Whole lot",
    "Lot",
    "Large number",
    "Gob",
    "Big batch",
    "Lot",
    "Large number",
    "Whole slew",
    "Whole bunch",
    "Bunch",
    "Lot",
    "Multitude",
    "Great number",
    "A whole lot"
  ],
  "maze": [
    "Pac-Man navigates through one",
    "Lab rat's challenge",
    "Entertainment on a diner place mat, maybe",
    "Challenge sometimes built outdoors with hedges",
    "Puzzle in which people take turns solving",
    "Setting for a rat race?",
    "Labyrinth",
    "Labyrinth",
    "Way-out challenge?",
    "Labyrinth",
    "Setting for \"The Shining\"",
    "Pac-Man screen, e.g.",
    "Feature on some place mats",
    "Ikea store, to some",
    "Labyrinth",
    "Labyrinth",
    "Building plan with many doors, say",
    "Puzzle with a start and a finish",
    "It may be hedged",
    "Big garden project",
    "Labyrinth",
    "Something to be threaded",
    "Challenge for Theseus, in myth",
    "Labyrinth",
    "Hedge fun?",
    "Puzzle with a start and an end",
    "Fun house feature",
    "Construction financed by a hedge fund?",
    "Part of a lab experiment",
    "Labyrinth",
    "It has many decision points",
    "Rat's challenge",
    "Challenge for a rat",
    "Windy locale?",
    "Daedalus creation",
    "Place for an arrow",
    "Where rats race",
    "Site of many walls",
    "Place to lose yourself",
    "Rat's learning place",
    "London streets, in a manner of speaking",
    "Daedalian creation",
    "Labyrinth",
    "Hampton Court feature",
    "Lawn ___",
    "Rats run it",
    "Place to lose oneself",
    "Labyrinth",
    "English garden feature",
    "English estate feature",
    "Rat trap?",
    "Source of confusion"
  ],
  "pins": [
    "They're taken out in alleys",
    "Digital passcodes",
    "See 4-Across",
    "Bowlers' targets ... 10 of which can be found appropriately arranged in this puzzle",
    "Figures usually held in one's head",
    "Tailor's supply",
    "Ones bowled over?",
    "Wrestling victories",
    "Bowlers' targets",
    "They may be bowled over",
    "Basting aids",
    "Immobilizes",
    "They're taken out in an alley",
    "They're taken out in alleys",
    "Stickers",
    "Spare parts",
    "A.T.M. necessities",
    "Some matches end with them",
    "Holds (down)",
    "Spare items",
    "Partner of 115-Across",
    "Wrestling finales",
    "Wrestling falls",
    "Needles' partner",
    "Mat wins",
    "Kegler?Æs count",
    "Bowling targets",
    "Fastens"
  ],
  "ezer": [
    "Six-Day War leader Weizman",
    "Weizman who once headed the Israeli Air Force",
    "___ Weizman, 1990s Israeli president",
    "Israel's Weizman",
    "Former Israeli president Weizman",
    "Former Israeli president Weizman",
    "Six-Day War leader Weizman",
    "Israel's Weizman",
    "Former Israeli president Weizman",
    "Israel's Weizman",
    "Israel's Weizman",
    "Former Israeli president Weizman",
    "Former Israeli president Weizman",
    "Israel's Weizman",
    "Former Israeli president Weizman",
    "Israel's Weizman",
    "Israeli president Weizman",
    "Weizman of Israel",
    "Weizman of Israel",
    "Israeli statesman Weizman"
  ],
  "judy": [
    "No-nonsense TV judge",
    "Children's writer Blume",
    "Author Blume",
    "Garland native to Minnesota",
    "One of TV's Jetsons",
    "TV judge"
  ],
  "zees": [
    "Sleep indicators",
    "Snoring symbols",
    "A lot of pizazz?",
    "They're usually caught lying down",
    "Scrabble 10-pointers",
    "Cartoonist's indicator of nodding",
    "Middle of a puzzle?",
    "Jazz combo?",
    "Sleep indicators in the comics",
    "Snoozers catch them",
    "Piazza parts?",
    "End of a dictionary",
    "End of a dictionary",
    "Nappers catch them",
    "Pieces of pizza?",
    "Scrabble 10-pointers",
    "Sleep indicators",
    "Jazz duo?",
    "Sleep symbols",
    "A lot of pizzazz?",
    "Couple of pizzas?",
    "Puzzle pieces?",
    "Puzzle's center",
    "Fuzzy Wuzzy features",
    "Valuable Scrabble holdings",
    "Sleep symbols",
    "Zorro's marks",
    "Marks of Zorro",
    "A lot of pizzazz?",
    "Zorro's marks"
  ],
  "suzy": [
    "Football sideline reporter Kolber",
    "___ Q's (Hostess brand)",
    "ESPN anchor Kolber",
    "1936 Jean Harlow title role",
    "Skier Chaffee"
  ],
  "shaq": [
    "Center of L.A., once",
    "The Big Aristotle of the N.B.A.",
    "N.B.A. nickname until 2011",
    "He played with Kobe on the Lakers",
    "Basketball's O'Neal, informally",
    "N.B.A. Hall-of-Famer with four rap albums, informally",
    "Laker legend with a size 22 sneaker, informally",
    "Hoops great O'Neal",
    "N.B.A. star-turned-sports analyst, familiarly",
    "Pro baller-turned-commentator for N.B.A. on TNT",
    "Longtime teammate of 67-Across",
    "Cager who retired in 2011",
    "\"Kazaam\" star, informally",
    "Hoops nickname",
    "___ Daddy (N.B.A. nickname)",
    "Hoopster with six rap albums, for short",
    "N.B.A. nickname until 2011",
    "Center in size 22 shoes",
    "Nickname for a noted L.S.U. grad",
    "N.B.A. nickname",
    "Wilt Chamberneezy, more familiarly",
    "Three-time M.V.P. of the N.B.A. finals, familiarly",
    "7'1\" N.B.A. star, informally",
    "Nickname for a 7'1\" N.B.A.'er",
    "Cager O'Neal, to fans",
    "N.B.A. star in the '96 film \"Kazaam\"",
    "N.B.A.'s O'Neal, informally",
    "Self-proclaimed \"The Big Aristotle\"",
    "N.B.A. nickname",
    "Great Laker",
    "Center of L.A.",
    "N.B.A. nickname",
    "Nickname in the N.B.A.",
    "___ attack",
    "Star center",
    "Lakers' O'Neal, informally",
    "N.B.A. all-star",
    "Hoops nickname",
    "___ attack",
    "\"Kazaam\" star, familiarly",
    "Court nickname",
    "Hoops nickname",
    "N.B.A.'s O'Neal, familiarly",
    "The N.B.A.'s O'Neal, familiarly"
  ],
  "enuf": [
    "Sufficient, informally",
    "Ample, informally",
    "Sufficient, in brief",
    "Ample, informally",
    "Sufficient, informally",
    "Sufficient, informally",
    "Ample, informally",
    "Plenty, informally",
    "Adequately, to Li'l Abner",
    "Sufficient, informally",
    "Sufficient, informally",
    "Sufficient, informally",
    "Ample, informally",
    "Sufficient, informally",
    "Ample, to Li'l Abner",
    "Sufficient, informally",
    "Sufficient, informally",
    "Ample, informally",
    "Adequate, informally",
    "Plenty, informally"
  ],
  "prey": [
    "Aphids, to ladybugs",
    "Quarry",
    "Seals, to great white sharks",
    "Small game, often",
    "They're low on the food chain",
    "Food chain link",
    "Game",
    "Hunted animals",
    "Gazelles, for cheetahs",
    "Quarry",
    "Potential dinner",
    "Hunted animal",
    "Game",
    "Hunted",
    "Flies, to spiders",
    "Wild catch?",
    "Sparrow, to a sparrow hawk",
    "Hunted",
    "Mice, to cats",
    "Rabbits, to eagles, e.g.",
    "Hunted",
    "Mice, to owls",
    "Little game, perhaps",
    "Elands, to lions",
    "Food chain part",
    "Part of a food chain",
    "Rodent, to a raptor",
    "Zebras, to lions",
    "Hunted animals",
    "Hunter's target",
    "Hunter's quarry",
    "Zebras, for lions",
    "Stalked one",
    "Target",
    "Mouse, to a 27-Across",
    "The hunted",
    "Quarry",
    "Moving targets?",
    "Whom a hunter hunts",
    "The hunted",
    "Mice, to cats",
    "Hunted",
    "Hunted animals",
    "The hunted",
    "Game, maybe",
    "Small game, e.g.",
    "Zebras, to lions",
    "Small animals, often",
    "The hunted",
    "Hunted",
    "Fish, to herons",
    "Hares, to hounds",
    "See 44-Down",
    "Quarry",
    "Wolves, for wolfhounds",
    "Hunter's quarry",
    "Quarry",
    "Game in the woods?",
    "Stalker's object",
    "Bird of ___",
    "Birds of ___",
    "Weaker ones",
    "The hunted",
    "The hunted"
  ],
  "fled": [
    "Took off",
    "Ran away from",
    "Ran",
    "Ran off",
    "Turned tail",
    "Took to the hills",
    "Took to the hills",
    "Ran",
    "Bugged out",
    "Ran",
    "Skipped town",
    "Left hurriedly",
    "Ran away from",
    "Split",
    "Took a powder",
    "Split",
    "Cut out",
    "Took it on the lam",
    "Hightailed it",
    "Got out of Dodge",
    "Took off",
    "Decamped",
    "Skedaddled",
    "Amscrayed",
    "Took off",
    "Ran",
    "Skedaddled",
    "Vamoosed",
    "Escaped",
    "Exited quickly",
    "Took off",
    "Hightailed it"
  ],
  "ione": [
    "One of the Nereids of Greek myth",
    "Woman's name that means \"violet\"",
    "One of the Nereids",
    "Skye of \"Say Anything ...\"",
    "One of the Nereids in Greek myth",
    "\"The Last Days of Pompeii\" heroine",
    "Actress Skye",
    "One of the Nereids",
    "Actress Skye",
    "Actress Skye",
    "Skye of film",
    "Actress Skye of \"Say Anything ...\"",
    "Girl's name in which the last three letters are equivalent to the first?",
    "Daughter of Nereus",
    "\"The Last Days of Pompeii\" heroine",
    "One of the Nereids in Greek myth",
    "Skye of \"Say Anything ...\"",
    "Skye of \"Say Anything ...\"",
    "One of the Nereids in Greek myth",
    "Skye of \"Say Anything ...\"",
    "Actress Skye of \"Say Anything ...\"",
    "Actress Skye",
    "Heroine of Bulwer-Lytton's \"The Last Days of Pompeii\"",
    "Actress Skye",
    "Skye of \"Say Anything ...\"",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Nereid sister of Galatea",
    "Glaucus's love in \"The Last Days of Pompeii\"",
    "Skye of \"Say Anything ...\"",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Town SE of Sacramento",
    "Actress Skye",
    "Actress Skye",
    "\"The Last Days of Pompeii\" heroine",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "Actress Skye",
    "\"The Last Days of Pompeii\" heroine",
    "Actress Skye",
    "Actress Skye",
    "\"The Last Days of Pompeii\" heroine",
    "\"The Last Days of Pompeii\" heroine",
    "With 49-Down, \"Say Anything\" co-star",
    "Actress Skye",
    "Actress Skye",
    "\"The Last Days of Pompeii\" heroine",
    "Actress Skye",
    "Actress Skye",
    "\"The Last Days of Pompeii\" girl",
    "Actress Skye",
    "\"Last Days of Pompeii\" girl",
    "\"The Last Days of Pompeii\" heroine",
    "Actress Skye"
  ],
  "onze": [
    "Eleven, en français",
    "This clue number minus deux",
    "Neuf + deux",
    "Eleven: Fr.",
    "Eleven en francais",
    "Dix + 1",
    "Dix et un",
    "Eleven, to Heloise",
    "French eleven",
    "Dix follower",
    "French eleven",
    "One less than une douzaine",
    "Eleve's 11",
    "Dix + un"
  ],
  "skis": [
    "Parts of snowmobiles",
    "Makes tracks, in a way",
    "Goes on a run",
    "What Denver's airport has a special carousel for",
    "Equipment often transported on a car's roof",
    "Is on the run?",
    "Does some runs",
    "Tackles a black diamond trail, say",
    "Footwear for a run",
    "Pair on a slope",
    "Super G needs",
    "Pair for some Winter Olympians",
    "Things found between the poles?",
    "Lift things?",
    "Biathlete's needs",
    "Equipment for schussing",
    "Sports equipment that doesn't fit in carry-on luggage",
    "They're lifted on chairlifts",
    "Equipment for Olympian Lindsey Vonn",
    "Takes to the hills?",
    "Equipment at Vail",
    "Nordic runners",
    "Cross-country ___",
    "Snowmobile parts",
    "Biathlon needs",
    "Aspen gear",
    "Nordic gear",
    "Items sometimes seen on car tops",
    "Slaloms",
    "Their tips turn up",
    "Does runs",
    "Does a run",
    "Stowe equipment",
    "Street wear?",
    "Cross-country ___",
    "Stowe gear",
    "Travels between poles?",
    "Items transported on car tops",
    "They may be involved in a spill",
    "Snowmobile parts",
    "Is on the run",
    "T-bar sights",
    "Winter resort rentals",
    "Rossignol gear",
    "Takes to the slopes",
    "2 Down sights",
    "Tommy Moe transportation",
    "Nordica products",
    "Car-roof items",
    "Goes downhill",
    "Travels between the poles?",
    "Stowe gear"
  ],
  "uses": [
    "Exploits",
    "Finds a function for",
    "Manipulates",
    "Presses into service",
    "Employs",
    "Depletes, with \"up\"",
    "Exercises",
    "Purposes",
    "Applications",
    "Applies",
    "Baking soda has lots of them",
    "Puts to work",
    "What WD-40 has a lot of",
    "Functions",
    "Exploits",
    "Makes the most of",
    "Paper clips have lots of them",
    "Baking soda has many of these",
    "Employs",
    "Things a smartphone has lots of",
    "Takes advantage of",
    "A smartphone has lots of them",
    "Plays with",
    "Puts into service",
    "Plays for a sap",
    "Plays for a sap",
    "Employments",
    "A Swiss army knife has a lot of them",
    "Resourceful people find new ones",
    "Employs",
    "\"101 ___ for a Dead Cat\" (1981 best seller)",
    "Milks",
    "Plays for a chump",
    "Does something with",
    "What vinegar has a lot of",
    "Takes habitually",
    "Applications",
    "Exercises",
    "Functions",
    "Functions",
    "Draws upon",
    "Employs",
    "Exercises",
    "Applications",
    "Exploits",
    "Avails oneself of",
    "Gets high, say",
    "Plays for a fool",
    "Inhales, perhaps",
    "Purposes",
    "Puts to work",
    "Resorts to",
    "Makes a cat's-paw of",
    "Applications",
    "A Swiss Army knife has lots of them",
    "Employs",
    "\"101 ___ for a Dead Cat\" (1981 best seller)",
    "Functions",
    "Plays",
    "Plays for a fool",
    "Functions",
    "Runs through",
    "Functions",
    "Depletes, with \"up\"",
    "Consumes",
    "Doesn't let go to waste",
    "A Swiss Army knife has lots of them",
    "Works with",
    "Puts into play",
    "Exploits",
    "Applications",
    "Many hints in \"Hints From Heloise\"",
    "Puts to work",
    "Applications",
    "Employs",
    "Takes advantage of",
    "Plays for a sucker",
    "Operates",
    "Functions",
    "Gadgeteers' concerns",
    "Plays for a sap",
    "Expends",
    "Employs",
    "Puts into play",
    "Brings into play",
    "Plays for a fool",
    "Handles",
    "A Swiss army knife has lots of them",
    "Handles",
    "Finishes (up)",
    "Draws on",
    "Plays for a sap",
    "Is addicted to, maybe",
    "Makes work",
    "Partakes of",
    "Exploits",
    "Employs",
    "Applications",
    "Swiss army knives have them",
    "Depletes, with \"up\"",
    "Swiss army knives have several",
    "Takes advantage of",
    "Applies",
    "Puts to work",
    "Employs",
    "Makes a cat's-paw of",
    "Employs",
    "Puts into service",
    "A Swiss army knife has lots of them",
    "Takes advantage of",
    "Exploits",
    "Isn't selfless",
    "Aspirin has several",
    "\"Has 1,001 ___\"",
    "List on a detergent label",
    "Applications",
    "Swiss army knives have lots of them",
    "Employs",
    "Puts to work",
    "Exercises",
    "Applications",
    "Exploits",
    "Takes advantage of",
    "Applications",
    "Applies",
    "Doesn't discard",
    "Applications",
    "Applications",
    "Takes advantage of",
    "Manipulates",
    "Manipulates",
    "Applies",
    "Applications",
    "Takes advantage of",
    "Applications",
    "Operates",
    "Brings into play",
    "Takes advantage of",
    "\"A thousand and one___\"",
    "Applies",
    "Milks, in a way",
    "Applications",
    "Versatility list",
    "Exploits",
    "Benefits",
    "Administers",
    "Functions",
    "Employs",
    "Applications",
    "Exploits",
    "Functions",
    "Exploits",
    "Exploits",
    "Functions",
    "Utilities",
    "Applications",
    "Functions",
    "Spends",
    "Applications",
    "Takes advantage of",
    "Exploits"
  ],
  "teas": [
    "Some bags in boxes",
    "Oolong and Darjeeling",
    "Afternoon socials",
    "11-Across and others",
    "Herbal brews",
    "Afternoon affairs",
    "Twinings products",
    "Lipton offerings",
    "Oolong and Earl Grey",
    "Some receptions",
    "Ones steeped in tradition in England?",
    "Society affairs",
    "4:00 socials",
    "Caddy's choices",
    "Lipton selection",
    "Genteel affairs",
    "Brewed beverages",
    "They're often bagged",
    "Genteel affairs",
    "Oolong and Earl Grey",
    "Tetley products",
    "Restaurant's after-dinner selection",
    "They may be carted around",
    "*Some socials",
    "Oolong and others",
    "Affairs that might sate the British",
    "Some fund-raisers",
    "Afternoon socials",
    "Bigelow beverages",
    "White-glove affairs",
    "Twinings selections",
    "Some socials",
    "Some socials",
    "Oolong and others",
    "Events where white gloves may be worn",
    "Waiter's after-dinner offerings",
    "Afternoon socials",
    "Leaves after a meal?",
    "After-dinner selection",
    "Afternoon affairs",
    "Events at which people may wear gloves",
    "They may get into hot water",
    "4:00 socials",
    "Parts of a Victorian social schedule",
    "Afternoon affairs",
    "Afternoon affairs",
    "Afternoon socials",
    "Afternoon socials",
    "50-Down and others",
    "Darjeeling and oolong",
    "Tetley offerings",
    "Darjeeling and oolong",
    "Occasions to serve crumpets",
    "Blended items",
    "Some are green",
    "After-dinner selection",
    "Soho socials",
    "Lipton and Twinings, e.g.",
    "Genteel affairs",
    "Some are green",
    "Some parties",
    "Socials",
    "They may be herbal",
    "Afternoon affairs",
    "Lipton products",
    "Receptions",
    "Sri Lankan exports",
    "Blended ___",
    "Social affairs",
    "Indian exports",
    "Society affairs",
    "Socials",
    "Socials",
    "Rose hip and Red Zinger, e.g.",
    "Socials",
    "Oolong and green",
    "Afternoon gatherings",
    "Tetley products",
    "Society gatherings",
    "Socials"
  ],
  "deem": [
    "Judge",
    "Judge",
    "Consider",
    "Consider",
    "Consider",
    "Regard",
    "Consider to be",
    "Judge",
    "Adjudge",
    "Make the judgment to be",
    "Adjudge",
    "Regard",
    "Regard",
    "Hold",
    "Judge",
    "Consider",
    "Think",
    "Reckon",
    "Judge",
    "Consider",
    "Believe",
    "Judge",
    "View to be",
    "Hold as",
    "Judge",
    "Consider",
    "Consider",
    "Judge",
    "Judge",
    "Regard",
    "Regard",
    "Think",
    "Consider",
    "Consider",
    "Consider",
    "Regard",
    "Judge",
    "Consider",
    "Hold",
    "Hold",
    "Judge",
    "Believe",
    "Consider",
    "Hold",
    "Consider",
    "Believe",
    "Consider",
    "Regard as",
    "Regard",
    "Judge",
    "Consider",
    "Consider",
    "Regard",
    "Consider",
    "Judge",
    "Judge",
    "Consider",
    "Judge to be",
    "Make judgments",
    "Judge",
    "Judge",
    "Suppose",
    "Consider",
    "Reckon",
    "Judge",
    "Consider",
    "Think",
    "Have an opinion",
    "Consider",
    "Adjudge",
    "Judge",
    "Consider",
    "Judge",
    "Regard"
  ],
  "caan": [
    "\"The Godfather\" actor",
    "James who played Sonny Corleone",
    "James of \"The Godfather\"",
    "James of \"The Godfather\"",
    "Actor James",
    "James who played Sonny Corleone",
    "Co-star of Ferrell in 2003's \"Elf\"",
    "James of \"The Godfather\"",
    "Player of the hot-tempered Corleone",
    "\"Thief\" star, 1981",
    "James of \"Elf\"",
    "Scott of \"Hawaii Five-0\"",
    "James of \"Las Vegas\"",
    "\"The Godfather\" actor",
    "Bates's \"Misery\" co-star",
    "James of \"Thief\"",
    "James of \"The Godfather\"",
    "\"Varsity Blues\" actor Scott",
    "Actor James",
    "Corleone portrayer",
    "James of TV's \"Las Vegas\"",
    "Scott of \"Ocean's Eleven,\" 2001",
    "Corleone portrayer",
    "\"The Godfather\" actor",
    "James of \"Thief\"",
    "Player of one of the Corleones",
    "James of Hollywood",
    "James of \"Misery\"",
    "\"Misery\" co-star, 1990",
    "\"The Gambler\" star, 1974",
    "Piccolo player on TV",
    "James of \"The Godfather\"",
    "James of Hollywood",
    "\"Slither\" star, 1973",
    "\"The Godfather\" actor",
    "Actor James",
    "\"For the Boys\" co-star, 1991",
    "James of \"Brian's Song\"",
    "Actor James",
    "\"The Godfather\" co-star",
    "\"Slither\" star",
    "\"Misery\" star",
    "\"Chapter Two\" star James",
    "Actor James",
    "Actor James",
    "Actor James",
    "\"Misery\" co-star",
    "\"The Godfather\" actor",
    "He was Sonny Corleone in \"The Godfather\""
  ],
  "erte": [
    "Big name in Deco",
    "Art Deco artist",
    "The Father of Art Deco",
    "Famed Deco designer",
    "Noted Deco designer",
    "Big name in Deco design",
    "Art Deco icon",
    "Art Deco notable",
    "Noted Art Deco artist",
    "Harper's Bazaar cover designer",
    "One-named French designer",
    "Big name in Art Deco",
    "20th-century artist famous for serigraphs",
    "\"Symphony in Black\" artist",
    "Artist who designed costumes for \"Ben-Hur\"",
    "Art Deco notable",
    "Art Deco artist",
    "\"Cleopatre\" artist",
    "Art Deco artist",
    "Designer for the Ziegfeld Follies",
    "Single-named artist",
    "Ziegfeld Follies costumer",
    "Art Deco illustrator",
    "Art Deco designer of the 1920s and '30s",
    "One-named Art Deco artist",
    "\"The Seasons\" lithographer",
    "Art Deco icon",
    "Crossword designer?",
    "Art Deco notable",
    "Art Deco artist",
    "\"The Alphabet\" artist",
    "Designer who wrote \"Things I Remember\"",
    "Folies-Bergere costume designer",
    "Art Deco icon",
    "Romain de Tirtoff's pseudonym",
    "Artist's alias with an accent",
    "One-named designer",
    "Onetime Harper's Bazaar illustrator",
    "One-named designer",
    "Art Deco master",
    "\"Broadway's in Fashion\" artist",
    "\"Athena\" artist",
    "Art Deco artist",
    "Art Deco artist",
    "Romain de Tirtoff's alias",
    "Artist whose moniker is the pronunciation of his initials",
    "\"Symphony in Black\" artist",
    "Pseudonym of the artist Romain de Tirtoff",
    "Harper's Bazaar illustrator of the 1910s-'30s",
    "Art Deco artist",
    "\"Feather Gown\" sculptor",
    "Single-named artist",
    "\"Manhattan Mary V\" artist",
    "Art Deco designer",
    "Ziegfeld Follies designer",
    "One-named artist",
    "\"Symphony in Black\" artist",
    "One-named Art Deco master",
    "One-named Art Deco designer",
    "Art Deco notable",
    "Old Harper's Bazaar artist",
    "Art Deco designer",
    "Designer for Lillian Gish",
    "Deco designer",
    "Art Deco alias",
    "One-named artist",
    "Ziegfeld Follies costume designer",
    "Old Harper's Bazaar illustrator",
    "Art Deco designer",
    "Art Deco master",
    "Old Harper's Bazaar illustrator",
    "Noted Art Deco illustrator",
    "Designer for Lillian Gish and Anna Pavlova",
    "Old Harper's Bazaar illustrator",
    "Folies Bergere designer",
    "Art Deco designer",
    "Artist's name formed phonetically from his initials",
    "One-named artist",
    "\"Flower Petal Gown\" sculptor",
    "Big name in Art Deco",
    "One-named designer",
    "\"A Dream\" artist",
    "Art Deco designer",
    "Old Harper's Bazaar artist",
    "Art Deco designer",
    "\"Directoire\" artist",
    "Art Deco signature",
    "Folies-Bergere designer",
    "\"The Oriental Ballet\" designer",
    "Art Deco artist",
    "Art Deco artist",
    "Old Harper's Bazaar designer",
    "Art Deco artist",
    "\"Ziegfeld Follies\" costume designer",
    "Art Deco designer",
    "Harper's Bazaar illustrator",
    "One-named artist",
    "Noted Folies Bergere designer",
    "\"Symphony in Black\" artist",
    "Art Deco designer",
    "Fashion illustrator for Harper's Bazaar",
    "Art Deco artist",
    "Folies Bergere designer",
    "Artist with popular lithographs",
    "Noted serigraph artist",
    "One-named Art Deco designer",
    "One-named designer",
    "One-named designer",
    "Collectible illustrator",
    "Romain de Tirtoff, familiarly",
    "Art Deco designer",
    "Art Deco designer",
    "Art Deco notable",
    "\"Ziegfeld Follies\" costume designer",
    "Art Deco name",
    "One-named designer",
    "Art Deco designer",
    "Collectible illustrator",
    "Art Deco artist",
    "Noted Folies-Bergere designer",
    "1910's-30's Harper's Bazaar designer",
    "Art Deco illustrator",
    "Art Deco illustrator",
    "Highly collectible lithographer",
    "Artist with collectible lithos",
    "Romain de Tirtoff's nom d'art",
    "Art Deco pioneer",
    "Originator of cutout dresses",
    "Art Deco notable",
    "Art Deco designer",
    "Illustrious illustrator",
    "Deco doyen",
    "Art Deco designer",
    "Art Deco artist",
    "Late fashion illustrator",
    "Collectible illustrator",
    "Art Deco name",
    "Art Deco artist",
    "20th-century illustrator",
    "Deco illustrator",
    "Late fashion illustrator",
    "Harper?Æs Bazaar artist",
    "Artist de Tirtoff, professionally",
    "Folies Bergere designer",
    "Art Deco artist",
    "Alias of Romain de Tirtoff",
    "Art Deco illustrator",
    "Russian-born designer",
    "Deco artist's pseudonym",
    "Collectible modern illustrator",
    "Art Deco master",
    "Artist de Tirtoff, professionally",
    "Art Deco artist",
    "Renowned costume designer",
    "Noted Folies Bergere designer",
    "Noted Harper's Bazaar illustrator"
  ],
  "gmos": [
    "Subjects of the 2018 book \"Seeds of Science,\" for short",
    "Biotech crops, e.g., for short",
    "Modern food concerns, for short",
    "Biotech products, for short",
    "Golden rice and others, in brief"
  ],
  "shiv": [
    "Item of prison contraband",
    "Makeshift knife",
    "Prison weapon",
    "Gang weapon",
    "Blade in the pen",
    "Prison weapon",
    "Pen knife?",
    "\"West Side Story\" weapon",
    "Pen knife?",
    "Prison weapon",
    "Prisoner's knife",
    "Weapon in a gang fight",
    "Knife, slangily",
    "Weapon in a rumble",
    "Switchblade, slangily",
    "Knife",
    "Rumble weapon",
    "Gangster's blade",
    "Gangster's blade",
    "Sharp weapon, slangily",
    "Switchblade",
    "Bladed weapon",
    "Hood's blade",
    "Switchblade",
    "Switchblade",
    "Thug's knife",
    "Hood's knife",
    "Switchblade",
    "Switchblade",
    "Street knife"
  ],
  "ento": [
    "Within: Prefix",
    "Inner: Prefix",
    "Opposite of exo-",
    "Prefix with -dermal",
    "Within: Prefix",
    "Inner: Prefix",
    "Inner: Prefix",
    "Inside opening?",
    "Inner: Prefix",
    "Inside opening?",
    "Within: Prefix",
    "Prefix with -zoic",
    "Inner: Prefix",
    "Inner: Prefix",
    "Inside opening?",
    "Prefix with -derm",
    "Inner: Prefix",
    "Opposite of exo-",
    "Inner: Prefix",
    "Prefix with -zoic",
    "Inner: Prefix",
    "Inner opening?",
    "Prefix with parasite",
    "Inner: Prefix",
    "Inner, in combinations",
    "Within: Prefix",
    "Within: Prefix",
    "Within: Prefix",
    "Course on insects, for short",
    "Inner: Prefix",
    "Inner opening?",
    "Inner: Prefix",
    "Inner: Prefix",
    "Within: Prefix",
    "Inner: Prefix",
    "Within: Prefix",
    "College course on insects, for short",
    "Within: Prefix",
    "Within: Prefix",
    "Prefix with dermal",
    "Prefix with dermal",
    "Within: Prefix",
    "Inner: Prefix",
    "Within: Prefix",
    "Opposite of exo-",
    "Prefix with -zoan",
    "Not exo-",
    "Inner beginner",
    "Within: Prefix",
    "Inner: Prefix",
    "Within: Prefix",
    "Inner, in combinations",
    "Within: Prefix",
    "Within: Prefix",
    "Within: Prefix",
    "Within: Prefix",
    "Within: Prefix",
    "Within: Prefix"
  ],
  "sill": [
    "Window part",
    "Spot for a houseplant",
    "Place to grow some herbs",
    "Perch for a pie",
    "Window ledge",
    "Spot for a flowerpot",
    "Sash supporter",
    "Place for a pie to cool",
    "Window base",
    "Place to set a candle, maybe",
    "Flowerpot spot",
    "Place for a potted plant",
    "Flowerpot spot",
    "Place for a window box",
    "Housecat's perch",
    "Place for a potted plant",
    "Spot for a pot",
    "Place for a pot",
    "Place for a hot pie to cool",
    "It's often under glass",
    "Part of a frame",
    "Sunlit ledge",
    "Window part",
    "Place for a houseplant",
    "Window area",
    "Sunny spot for a plant",
    "Planter's place",
    "Window ledge",
    "Place for a houseplant",
    "Window ledge",
    "Window ledge",
    "Place for a catnap?",
    "Threshold",
    "Window feature",
    "Spot for a warm pie",
    "Window box location",
    "Pie perch",
    "Place to dust",
    "Window base",
    "Place for a planter",
    "Pie-cooling spot",
    "Housecat's perch",
    "Window ledge",
    "Framework piece",
    "Window feature",
    "Place for a flowerpot",
    "Window part",
    "Threshold",
    "Threshold",
    "Shades' stopping point",
    "Window part",
    "Foundation timber",
    "Window part",
    "Window part",
    "Dust collector?"
  ],
  "clio": [
    "Muse of history",
    "Muse of history",
    "Sister of Calliope",
    "Madison Avenue award",
    "Muse of history",
    "Muse of history",
    "Award for Agency of the Year, e.g.",
    "Historian's Muse",
    "Oscar : films :: ___ : ads",
    "Muse of history",
    "Award coveted on \"Mad Men\"",
    "Muse sometimes pictured with a book or scroll",
    "Muse of history",
    "One of its categories is Agency of the Year",
    "\"Mad Men\" award",
    "Renault model with a mythological name",
    "Mythical figure represented in Vermeer's \"The Art of Painting\"",
    "Advertising award",
    "Advertising award",
    "Sister of Erato",
    "Prize in the ad biz",
    "Historian's Muse",
    "Mother of Hyacinth, in myth",
    "Muse of history",
    "Advertising award",
    "Annual award named for a Muse",
    "Pitcher's prize?",
    "One of nine sisters in myth",
    "Sister in myth",
    "Ad award",
    "Madison Avenue award",
    "Muse of history",
    "Advertiser's award",
    "Madison Avenue award",
    "History Muse",
    "Award in the ad biz",
    "History Muse",
    "Madison Avenue award",
    "Muse of history",
    "History Muse",
    "Muse of history",
    "Saatchi & Saatchi award",
    "Muse of history",
    "Ad writer's award",
    "Award for Saatchi & Saatchi",
    "Advertising award",
    "TV ad award",
    "Durant?Æs Muse",
    "Sister of Euterpe",
    "Advertising award",
    "Muse of history",
    "Goddess pictured with an open papyrus",
    "Commercial award",
    "Ad writer's honor",
    "Campaign award"
  ],
  "ncis": [
    "TV series with Agt. Leroy Jethro Gibbs",
    "\"___: Hawaii\" (TV spinoff)",
    "CBS police procedural",
    "Longtime procedural set in Washington, D.C.",
    "Long-running CBS drama",
    "TV drama with spinoffs set in Los Angeles and New Orleans",
    "Longtime CBS drama spinoff",
    "Franchise with a series set in New Orleans",
    "It has spinoffs set in New Orleans and Los Angeles",
    "Longtime CBS police procedural",
    "Police procedural beginning in 2003",
    "Spinoff of TV's \"JAG\"",
    "Long-running CBS police drama",
    "Longtime TV procedural",
    "CBS show with a \"New Orleans\" spinoff",
    "Org. for forensic specialist Abby Sciuto",
    "Special Agent Gibbs's beat",
    "Long-running TV drama started in 2003",
    "\"JAG\" spinoff",
    "Franchise with locations in New Orleans and L.A.",
    "Mark Harmon police series",
    "Spinoff series with two spinoffs of its own",
    "America's most-watched TV series of 2012-13",
    "CBS military drama",
    "Lead-in to \"Los Angeles\" or \"New Orleans\"",
    "Formerly top-rated show starring Mark Harmon",
    "Most-watched TV series of 2012-13",
    "CBS drama set in D.C.",
    "Most-watched show of 2012-13",
    "TV drama set in the D.C. area",
    "It has a \"Los Angeles\" spinoff",
    "\"JAG\" spinoff",
    "CBS procedural",
    "CBS police drama that debuted in 2003",
    "CBS military procedural",
    "Show featuring special agents",
    "\"JAG\" spinoff with Mark Harmon",
    "TV police drama",
    "Show with a \"Los Angeles\" spinoff",
    "CBS military drama",
    "Mark Harmon action drama",
    "Spinoff of CBS's \"JAG\"",
    "Spinoff of CBS's \"JAG\""
  ],
  "tone": [
    "Something you might watch with your parents",
    "Word with earth or muscle",
    "Voice mail prompt",
    "Muscular firmness",
    "___ it down a notch",
    "\"Watch your ___!\" (response to 52-Across)",
    "It distinguishes meaning in many East Asian languages",
    "It may rise in anger",
    "Voice mail signal",
    "Get fit, with \"up\"",
    "Paint swatch selection",
    "Word with skin or ring",
    "Shade",
    "Color feature",
    "Recorded message prompt",
    "Firm (up), as muscles",
    "A parent may tell a child to watch it",
    "Step on a scale",
    "What calisthenics improve",
    "\"Don't use that ___ with me!\"",
    "Hue",
    "Build one's muscles, with \"up\"",
    "Synonym and anagram of \"note\"",
    "Pitch",
    "Manner of speaking",
    "Mood",
    "Muscle ___",
    "Soap brand with cocoa butter",
    "\"Watch your ___, young man!\"",
    "Musical pitch",
    "Build muscles, with \"up\"",
    "\"Watch your ___, young man!\"",
    "Hue",
    "Voice inflection",
    "Firm (up)",
    "Muscle ___",
    "Shape (up)",
    "Sound of music",
    "It might be harsh or hushed",
    "Muscular firmness",
    "Paint swatch choice",
    "\"___ it down!\"",
    "Voice quality",
    "Musical sound",
    "Kind of deafness",
    "Cosmetology concern",
    "Bell sound",
    "Music critic's concern",
    "Timbre",
    "Pitch",
    "Shape (up)",
    "Prevailing character",
    "Skin ___",
    "Cosmetologist's concern",
    "A kid may be told to watch it",
    "TV color adjustment",
    "Mute, with \"down\"",
    "Audiophile's concern",
    "*Musical quality",
    "Voice quality",
    "Dial ___ (telephone sound)",
    "Music quality",
    "Mood",
    "Clock chime, e.g.",
    "Blush adds it",
    "Coloring",
    "Voice quality",
    "Timbre",
    "La or fa",
    "Coloring",
    "Dial ___",
    "General character",
    "Voice mail cue",
    "Musical signal",
    "Television knob",
    "Soap made by Dial",
    "Muscle ___",
    "Audiophile's concern",
    "Pulse alternative",
    "___ poem",
    "Muscle quality",
    "Strengthen, with \"up\"",
    "Tenor",
    "General flavor",
    "Voice mail prompt",
    "Dialing need",
    "Muscle quality",
    "Mood",
    "Inflection",
    "With 39-Down, terrible with pitches",
    "Muscle condition",
    "Calisthenics improve it",
    "Stereo knob",
    "Distinctive quality",
    "Color quality",
    "Muscle quality",
    "Pitch",
    "Whole step",
    "Color quality",
    "Pitch",
    "Voice quality",
    "Voice mail prompt",
    "Kind of poem",
    "Increase in strength, with \"up\"",
    "Manner of speaking",
    "Character",
    "Way of speaking",
    "Dial sound",
    "Sound",
    "___-deaf",
    "Telephone sound",
    "Vocal inflection",
    "Sound quality",
    "Signal on the hour",
    "Vocal quality",
    "___ of voice",
    "Richness",
    "Singer's concern",
    "Color quality",
    "\"At the sound of the ___ ...\"",
    "Kind of poem",
    "Color quality",
    "Color quality",
    "Shade",
    "Dial sound",
    "TV knob",
    "Manner of speaking",
    "TV knob"
  ],
  "lost": [
    "No longer following",
    "Played paper against scissors, e.g.",
    "Befuddled",
    "Perplexed",
    "Misplaced",
    "Picked rock against paper, say",
    "Like Little Bo-Peep's sheep",
    "Fell (to)",
    "Heading on a poster with a picture of a dog",
    "Lacking a purpose in life",
    "Bewildered",
    "Like Hansel and Gretel in the forest",
    "Going \"Huh?!?\"",
    "Beyond repair",
    "Found's opposite",
    "In need of a steer",
    "In need of direction",
    "Clueless",
    "At sea",
    "TV show that debuted on 9/22/04 (middle of the cry)",
    "Not found",
    "Clueless",
    "At sea",
    "Like some causes",
    "Baffled",
    "Befuddled",
    "Found's opposite",
    "At sea",
    "At sea",
    "Not comprehending at all",
    "At sea",
    "Not grasping the material, say",
    "Out the window",
    "The story of the aftermath of Oceanic Flight 815",
    "Needing directions",
    "Unretrievable",
    "Desperately needing a map",
    "Misspent",
    "At sea",
    "Like Bo Peep's sheep",
    "Disoriented",
    "Finished second",
    "Bowed (to)",
    "Deep in thought",
    "Needing directions, say",
    "Found's partner",
    "Was second-best",
    "A-mazed?",
    "Found's partner",
    "Without hope",
    "Gave the slip",
    "Going in circles, maybe",
    "Going around in circles",
    "Like some causes",
    "Neil Simon's \"___ in Yonkers\"",
    "Like Lucy Locket's pocket",
    "Missing",
    "Bewildered",
    "Confused",
    "Found's partner",
    "Preoccupied",
    "At sea",
    "Confused",
    "Neighborhood sign word",
    "Missing",
    "Needing directions",
    "Out the window",
    "Clueless",
    "Without a clue",
    "The ___ Boys of \"Peter Pan\"",
    "Dropped, maybe"
  ],
  "erat": [
    "Part of Q.E.D.",
    "The \"E\" of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Form of the Latin \"sum\"",
    "The \"E\" of Q.E.D.",
    "Part of Q.E.D.",
    "It was: Lat.",
    "Quod ___ demonstrandum",
    "The \"E\" of Q.E.D.",
    "Bit of Q.E.D.",
    "Part of the conjugation of the Latin \"esse\"",
    "Quod ___ faciendum",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "The \"E\" of Q.E.D.",
    "Quod ___ faciendum",
    "Part of Q.E.F.",
    "\"He was,\" in Latin",
    "Logician's \"E\"",
    "The \"E\" of Q.E.D.",
    "The \"E\" of Q.E.D.",
    "Form of \"sum\"",
    "Q.E.D. part",
    "The \"E\" of Q.E.D.",
    "Part of Q.E.D.",
    "Part of the \"sum\" conjugation",
    "Q.E.F. part",
    "The \"E\" of Q.E.D.",
    "The \"E\" of Q.E.D.",
    "Sic ___ scriptum",
    "Quod ___ demonstrandum",
    "Latin \"was to be\"",
    "Part of Q.E.D.",
    "Quod ___ faciendum",
    "Latin 101 word",
    "The \"E\" of Q.E.D.",
    "\"In principio ___ Verbum\"",
    "Quod ___ demonstrandum",
    "Proof-ending word",
    "\"In principio ___ Verbum\" (biblical phrase)",
    "\"It was,\" in Latin",
    "The \"E\" in Q.E.D.",
    "The \"E\" of Q.E.D.",
    "Q.E.D. part",
    "Latin word that's an anagram of 62-Across",
    "Quod ___ faciendum",
    "Form of \"sum\"",
    "Quod ___ demonstrandum",
    "Form of \"sum\"",
    "\"Hoc ___ in votis\"",
    "The \"E\" in Q.E.D.",
    "Q.E.D. part",
    "Quod ___ demonstrandum",
    "The \"E\" in Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "\"In principio ___ Verbum\" (words from John 1:1)",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "\"Hoc ___ in votis\": Horace",
    "Quod ___ demonstrandum",
    "Quod ___ faciendum",
    "The \"E\" of Q.E.D.",
    "Part of Q.E.D.",
    "\"In principio ___ Verbum\"",
    "Part of Q.E.D.",
    "\"Sicut ___ in principio\" (doxology phrase)",
    "Was at the Colosseum?",
    "Form of the Latin \"esse\"",
    "Part of Q.E.D.",
    "Quod ___ faciendum",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "\"Hoc ___ in votis\"",
    "\"In principio ___ Verbum\"",
    "Q.E.D. part",
    "\"Sicut ___ in principio\" (doxology phrase)",
    "The \"E\" of Q.E.D.",
    "Part of Q.E.D.",
    "Middle of Q.E.D.",
    "Part of Q.E.D.",
    "\"___ Hora\" (Ezra Pound poem)",
    "Q.E.D. part",
    "\"In principio ___ Verbum et ?à\"",
    "Word after quod",
    "Q.E.D. part",
    "The \"E\" of Q.E.D.",
    "Q.E.D. part",
    "Part of Q.E.D.",
    "Q.E.D. part",
    "It was in old Rome",
    "The \"E\" in Q.E.D.",
    "Q.E.D. part",
    "\"In principio ___ verbum\"",
    "?ôSicut ___ in principio?ö (doxology phrase)",
    "Quod ___ demonstrandum",
    "\"In principio ___ Verbum\" (biblical phrase)",
    "Q.E.D. part",
    "He was in old Rome",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Q.E.D. part",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Quod ___ faciendum",
    "Q.E.D. part",
    "Part of Q.E.D.",
    "Ezra Pound's \"___ Hora\"",
    "Latin 101 word",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "Quod ___ faciendum",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "He was, in old Rome",
    "Proof word",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "\"Sicut ___ in principio\" (church lyric)",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Latin I word",
    "Form of 56-Down",
    "\"Hoc ___ in votis\"",
    "He was translated into Latin",
    "Part of Q.E.F.",
    "It was, to Ovid",
    "Part of Q.E.D.",
    "Was at the forum?",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "Quod ___ faciendum",
    "Part of Q.E.D.",
    "Was, in Latin",
    "Proof word",
    "Quod ___ faciendum",
    "Part of Q.E.D.",
    "Quod___demonstrandum",
    "Q.E.D. midsection",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "Part of Q.E.D.",
    "He was, in Rome",
    "The \"E\" of Q.E.D.",
    "Q.E.D. word",
    "Part of Q.E.D.",
    "Quod ___ faciendum",
    "Part of Q.E.D.",
    "The \"E\" in Q.E.D.",
    "Part of Q.E.D.",
    "\"Hoc ___ in votis\" (\"This was among my wishes\"): Horace",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D."
  ],
  "matt": [
    "Good name for a wrestler?",
    "Apt name for a yoga instructor?",
    "Actor Damon",
    "\"Interstellar\" actor Damon",
    "___ Smith, player of the Doctor on \"Doctor Who\"",
    "Good name for a wrestler?",
    "Groening who created \"The Simpsons\"",
    "Joey's portrayer on \"Friends\"",
    "___ Dillon, lead role on \"Gunsmoke\"",
    "Groening who created \"The Simpsons\"",
    "LeBlanc of \"Friends\"",
    "Dillon or Damon",
    "Stone who co-created \"South Park\"",
    "Actor Damon",
    "QB Stafford",
    "Drudge of the Drudge Report",
    "\"South Park\" co-creator Stone",
    "Political writer ___ Bai",
    "Olympic swimmer Biondi",
    "\"___ Houston\" of 1980s TV",
    "Groening who created \"The Simpsons\"",
    "LeBlanc of \"Joey\"",
    "\"Futurama\" creator Groening",
    "QB Hasselbeck",
    "Detective Houston",
    "Drudge on the Internet",
    "Longtime name on \"Today\"",
    "Big name in morning TV",
    "Damon of \"Good Will Hunting\"",
    "\"The Simpsons\" creator Groening",
    "Actor Damon",
    "Luisa's lover in \"The Fantasticks\"",
    "Slugger Williams",
    "Drudge of the Internet",
    "Online columnist Drudge",
    "Damon of \"The Bourne Identity\"",
    "Drudge of the Internet",
    "Houston, for one",
    "Drudge in cyberspace",
    "The Internet's ___ Drudge",
    "Fictional spy ___ Helm",
    "Katie's \"Today\" co-host",
    "___ Stone, co-creator of \"South Park\"",
    "___ Damon of \"Saving Private Ryan\"",
    "Dillon of \"Wild Things\"",
    "LeBlanc of \"Friends\"",
    "A Houston",
    "Helm of fiction",
    "TV detective Houston",
    "Actor Dillon",
    "Cartoonist Groening"
  ],
  "ride": [
    "Badger",
    "Tilt-a-Whirl, e.g.",
    "Tilt-a-Whirl, e.g.",
    "Uber request",
    "The Haunted Mansion or Splash Mountain, at Disneyland",
    "Teacups at an amusement park, e.g.",
    "Theme park feature",
    "Lift",
    "Wheels",
    "___ the bench (not get used in the game)",
    "See 18-Down",
    "One may have a height restriction",
    "Hitchhiker's hope",
    "Tease relentlessly",
    "*... for astronauts",
    "See 29-Across",
    "Lyft offering",
    "Carnival attraction",
    "Merry-go-round or roller coaster",
    "Rag on",
    "Hitchhiker's need",
    "Antagonize",
    "Be a passenger",
    "Car-pooler's need",
    "Car, slangily",
    "Merry-go-round, e.g.",
    "Tilt-A-Whirl or bumper cars",
    "Hitchhike, e.g.",
    "Pirates of the Caribbean, e.g.",
    "Roller coaster, e.g.",
    "Lift provider",
    "Fair attraction",
    "Razz",
    "Tease",
    "Teacups, e.g.",
    "Ferris wheel or bumper cars",
    "Fair diversion",
    "Hitch",
    "Not go by one's own locomotion",
    "Ferris wheel or bumper cars",
    "Midway attraction",
    "Hitchhiker's need",
    "Coaster, e.g.",
    "Fair selection",
    "Tease mercilessly",
    "Disneyland's Space Mountain, e.g.",
    "Fair sight",
    "Fair feature",
    "Ferris wheel or bumper cars",
    "Six Flags attraction",
    "Travel on horseback",
    "Theme park attraction",
    "Fair attraction",
    "Hitchhike",
    "Tilt-A-Whirl, e.g.",
    "Harass",
    "It may be a thriller",
    "Depend (on)",
    "Hitchhiker's quest",
    "Amusement park lure",
    "Fair feature",
    "Fairgrounds attraction",
    "Get carried away?",
    "Get on, in more ways than one",
    "Nag ... or use a nag",
    "\"Ticket to ___\"",
    "Hitchhiker's wish",
    "Go by bus",
    "Hitchhike",
    "Hitchhike",
    "Hitchhike",
    "Go by horse",
    "Hitch, in a way",
    "Tease mercilessly",
    "Merry-go-round or roller coaster",
    "Hitchhiker's quest",
    "Heckle",
    "Harass",
    "Subject of a Car and Driver rating",
    "Carnival attraction",
    "Astronaut Sally",
    "Fair attraction",
    "Jaunt",
    "Use a shuttle",
    "Heckle",
    "Carnival attraction",
    "Get carried away?",
    "Auto's comfort quality",
    "Fair offering",
    "Lift",
    "Carpool",
    "Roller coaster, for one",
    "Take the bus",
    "Take the bus",
    "Heckle",
    "Sally of NASA",
    "Ridicule persistently",
    "The Cyclone, e.g.",
    "Ferris wheel, e.g."
  ],
  "idos": [
    "Some formal promises",
    "Exchanged promises",
    "Promises, promises!",
    "Nuptial exchange",
    "Vows exchanged at the altar",
    "Things exchanged between brides and grooms",
    "Wedding pair",
    "Pair at the altar",
    "Pair of promises",
    "Altar exchange",
    "Exchange of swear words?",
    "Wedding pair",
    "Exchanged bonds?",
    "A couple words?",
    "Altar exchange",
    "Promising exchange",
    "They're exchanged at the altar",
    "Altar exchange",
    "Exchange at the altar",
    "Binding exchange",
    "They're said at the altar",
    "Wedding vows",
    "Vows made \"for better or worse\"",
    "Merger agreements?",
    "Important vows",
    "Wedding vows",
    "Couple at the altar?",
    "Promising start to a marriage?",
    "Bride and groom exchange",
    "Altar exchange",
    "Union agreements?",
    "Once-in-a-lifetime exchange, maybe",
    "Exchanged vows",
    "Nuptial exchange",
    "Wedding exchange",
    "Wedding exchange",
    "They're said before a kiss",
    "Wedding vows",
    "Wedding couple?",
    "Some people cry when these are said",
    "Binding exchanges",
    "A wedding requires two of them",
    "Wedding exchange",
    "They're exchanged at the altar",
    "Union agreements?",
    "Words that leave one in a bind?",
    "Altar exchange",
    "Some promises",
    "Church exchange?",
    "Sometimes-broken promises",
    "Mass-wedding chorus",
    "Union agreements?",
    "Some vows",
    "Wedding exchange",
    "They precede kisses",
    "Promising words",
    "They're exchanged at weddings",
    "Important vows",
    "Altar vows",
    "Wedding vows"
  ],
  "ikea": [
    "Its products often come with Allen keys",
    "Store with a one-way route through its cavernous space",
    "Big believer in the freedom of assembly?",
    "Producer of the world's most widely read consumer catalog",
    "Its merchandise often comes with pictorial instructions",
    "___ effect, bias toward products that consumers assemble themselves",
    "Seller of Uppland and Ektorp sofas",
    "Its products often come with Allen wrenches",
    "First company to feature a gay couple in a mainstream U.S. TV ad (1994)",
    "Ready-to-assemble furniture giant",
    "International furniture chain",
    "Retail giant that opened its first U.S. store near Philadelphia in 1985",
    "Furniture retailer founded in Sweden",
    "Big seller of unassembled furniture",
    "International retailer founded in Sweden",
    "Home furnishings store with a three-syllable name",
    "Flatpack retailer",
    "Company whose bathroom sinks are named for Swedish bodies of water",
    "Furniture giant with a blue-and-yellow logo",
    "Store with a notably mazelike layout",
    "Company with assembly lines?",
    "Home furnishings giant",
    "Furniture giant founded in Sweden",
    "Company whose how-to manuals lack words",
    "\"Make room for life\" sloganeer",
    "Build-it-yourself furniture chain",
    "Retail giant in furniture",
    "Furniture superstore",
    "Store with a three-syllable name in four letters",
    "Company that was the first in the U.S. to air a TV ad with a gay couple (1994)",
    "Furnishings retailer with gigantic stores",
    "Many of its products have legs",
    "The average size of its stores is 300,000 square feet",
    "Company with a noted catalog",
    "Seller of the Soderhamn sofa",
    "Store with a \"Self Serve\" furniture warehouse",
    "Retail giant whose catalog comes in 17 languages",
    "The world's largest one is in South Korea, the second-largest in Sweden",
    "Furniture store that also sells Swedish meatballs",
    "Furniture giant with a blue and yellow logo",
    "Furniture giant",
    "Company with \"Long live the home\" ads",
    "Mass-market furniture company",
    "Company with a lot of manual work?",
    "International company with an acronymic name",
    "Company said to use about 1% of the world's wood supply",
    "Furniture megastore",
    "Certain superstore",
    "Big name in furniture",
    "Noted provider of pictorial instructions",
    "Chain that sells Borgsjo bookcases",
    "Company founded by a 17-year-old Swede",
    "Crate & Barrel rival",
    "Big retailer of home accessories",
    "Furniture chain founded in Sweden",
    "Its products often have Allen wrenches included",
    "Pottery Barn competitor",
    "Big-box store",
    "European-based furniture giant",
    "Put-it-together-yourself company",
    "Its logo includes its name in blue letters in a yellow oval",
    "Assemble-it-yourself chain",
    "Household goods retail chain founded in Sweden",
    "International furniture retailer",
    "Pier 1 alternative",
    "Low-priced furniture source",
    "Chain founded in Sweden",
    "Retail giant whose logo has blue letters in a yellow oval",
    "Furniture giant",
    "Acronymic store name",
    "Swedish home furnishings chain",
    "Self-assembly retail chain",
    "Furniture chain",
    "Retail giant founded by a 17-year-old",
    "Unboring retailer",
    "Retail giant from 5-Down",
    "Big furniture retailer",
    "Swedish company with a catalog",
    "Swedish retail giant",
    "Furniture chain",
    "Company founded by Ingvar Kamprad",
    "Scandinavian furniture giant",
    "Swedish retail giant",
    "Retail furniture chain",
    "International retailer whose name is an acronym",
    "Big name in furniture",
    "Furniture retailer since 1943",
    "Swedish-based furniture chain",
    "Swedish-based chain",
    "Swedish furniture giant",
    "Swedish chain",
    "Big name in home furnishings",
    "Big furniture retailer",
    "Big furniture retailer"
  ],
  "migs": [
    "Soviet fighter jets",
    "Cold war fighters",
    "\"Top Gun\" jets",
    "\"Top Gun\" planes",
    "Russian fighter jets",
    "Korean War sights",
    "Cold-war fighters",
    "A little over a million dollars?"
  ],
  "bccs": [
    "Keeps informed, in a way",
    "Hidden inclusions, for short",
    "Secretly loops in, in a way",
    "Hidden addresses, for short",
    "Secretly includes on an email",
    "Loops in, in a way",
    "Includes surreptitiously",
    "Covertly drops a line to",
    "Emails discreetly",
    "Loops in, in a way",
    "Secretly adds to emails"
  ],
  "anno": [
    "___ urbis conditae",
    "\"A,\" on a timeline",
    "___ Domini",
    "___ mundi",
    "The \"A\" in A.D.",
    "___ Domini",
    "The \"A\" in A.D.",
    "\"Felice ___ nuovo!\"",
    "___ Domini",
    "An \"A\" in history?",
    "Part of A.D.",
    "The \"A\" of A.D.",
    "Part of A.D.",
    "Year abroad",
    "A.D. part",
    "___ regni",
    "___ Domini",
    "365 giorni",
    "Part of A.D.",
    "___ Christi",
    "Word on a headstone",
    "___ regni",
    "___ mundi",
    "The \"A\" of A.D.",
    "A.D. part",
    "___ Domini",
    "___ Domini",
    "The \"A\" in A.D.",
    "The \"A\" in A.D.",
    "Latin word on a cornerstone",
    "The \"A\" of A.D.",
    "The \"A\" in A.D.",
    "Word on a cornerstone",
    "Word usually abbreviated on timelines",
    "___ Domini",
    "Part of A.D.",
    "A dozen mesi",
    "Start of many dates",
    "___ mundi",
    "Dating word",
    "The \"A\" of A.D.",
    "Cornerstone word",
    "The \"A\" in A.D.",
    "52 settimane",
    "365 giorni",
    "Latin word on a cornerstone",
    "___ Domini",
    "Word on a monument",
    "Cornerstone word",
    "___ Domini",
    "Word on a memorial",
    "Latin word on a cornerstone",
    "Part of A.D.",
    "A.D. part",
    "___ Domini",
    "___ Domini",
    "Cornerstone word",
    "What \"A\" may stand for on a monument",
    "___ Domini",
    "Monumental year?",
    "Latin word on a monument",
    "Year in Italy",
    "___ Domini",
    "Part of a cornerstone inscription",
    "Monumental year?",
    "Cornerstone word",
    "___ mundi",
    "___ Christi",
    "___ regni (in the year of the reign)",
    "Domini preceder",
    "Cornerstone word",
    "Year in dates",
    "___ Domini",
    "Part of A.D.",
    "In the year: Lat.",
    "Part of A.D.",
    "___ Domini",
    "Part of A.D.",
    "___ Domini",
    "Part of A.D.",
    "___ Domini",
    "The \"A\" in A.D.",
    "The \"A\" in A.D.",
    "___ mundi",
    "___ mundi",
    "Part of A.D.",
    "___ Domini",
    "Part of A.D.",
    "Part of A.D.",
    "Part of A.D.",
    "Year, on monuments",
    "Year in Trajan's reign",
    "Word on monuments",
    "Part of a cornerstone inscription",
    "Part of A.D.",
    "___ Domini",
    "The \"A\" in A.D.",
    "Latin word on a cornerstone",
    "Part of A.D.",
    "___ Domini",
    "Part of A.D.",
    "Part of A.D.",
    "Part of A.D.",
    "___regni (in the year of the reign)",
    "Part of A.D.",
    "Part of A.D.",
    "Part of A.D.",
    "___ Domini",
    "___ Domini",
    "___ Domini"
  ],
  "qtip": [
    "Sample collector, maybe",
    "Brand of swabs",
    "Branded cotton swab",
    "The Kleenex of cotton swabs",
    "Ear swab",
    "Commercial swab",
    "Stick in a cabinet",
    "Common cotton swab",
    "Medicine applicator",
    "Common makeup applicator",
    "Stick in a medicine cabinet",
    "Popular swab",
    "Wax remover",
    "*Little swab",
    "Stick in a cabinet",
    "Stick it in your ear",
    "Unilever swab",
    "Canal cleaner",
    "Swab name",
    "Wax remover",
    "Cotton swab",
    "Common cosmetics applicator",
    "Little wax remover",
    "Swab's name",
    "Little swab",
    "Tiny swab",
    "Little swab",
    "Canal cleaner",
    "Wax attacker",
    "Medicine cabinet item",
    "Bathroom cabinet item",
    "Common swab",
    "Ear spear",
    "Ear cleaner"
  ],
  "kiwi": [
    "This will never fly",
    "Christchurch resident",
    "Green smoothie ingredient",
    "Fruit salad fruit",
    "New Zealand demonym",
    "New Zealander",
    "Green-fleshed fruit",
    "Bird with nostrils at the tip of its beak",
    "Fuzzy berry",
    "Native New Zealander",
    "Small relative of an elephant bird",
    "New Zealand bird",
    "Fuzzy food",
    "Native New Zealander",
    "Chicken-sized flightless bird",
    "Bird or fruit",
    "Auckland native",
    "Bird that lays a one-pound egg",
    "New Zealander",
    "Only bird with nostrils on the tip of its beak",
    "Green fruit",
    "Auckland native, informally",
    "Fuzzy fruit",
    "Fuzzy fruit",
    "Fuzzy fruit",
    "Christchurch native",
    "Fuzzy fruit",
    "Aussie's neighbor",
    "Aussie's neighbor",
    "Fuzzy green fruit",
    "New Zealander",
    "New Zealand bird",
    "Fruit salad fruit",
    "Fuzzy fruit",
    "Salad fruit",
    "Yogurt fruit",
    "Bird whose name is the same as its call",
    "Fuzzy fruit",
    "Nocturnal bird",
    "Apteryx australis",
    "Down Under bird",
    "Green fruit",
    "Wellington resident",
    "Flightless bird",
    "Apteryx australis",
    "New Zealand bird",
    "New Zealander",
    "Fuzzy fruit",
    "New Zealander",
    "Bird whose males incubate the eggs",
    "New Zealander",
    "Bird whose male hatches the eggs",
    "New Zealander",
    "New Zealander",
    "Apteryx",
    "Kind of polish",
    "New Zealander",
    "Fuzzy fruit"
  ],
  "goya": [
    "His \"La Maja Desnuda\" was never publicly shown in his lifetime",
    "Noted painter of scenes of the Napoleonic Wars",
    "Spanish artist Francisco ___",
    "\"Witches' Flight\" painter",
    "Painter of a maja both \"desnuda\" and \"vestida\"",
    "Brand of beans",
    "Court painter of Charles IV of Spain",
    "\"Los Caprichos\" artist",
    "\"The Naked Maja\" painter",
    "Francisco who painted frescoes",
    "They know beans",
    "\"The Naked Maja\" artist",
    "Big name in Hispanic food",
    "\"The Naked Maja\" or \"The Clothed Maja\"",
    "\"The Naked Maja\" artist",
    "Prado artist",
    "\"The Naked Maja\" painter",
    "Artist Francisco",
    "Spanish food brand",
    "Painter of \"The Naked Maja\"",
    "Painter of \"The Naked 56-Down\"",
    "\"The Naked Maja\" artist",
    "\"The Naked Maja\" artist",
    "\"Saturn Devouring His Children\" painter",
    "\"Duchess of Alba\" painter",
    "Maja painter",
    "Maja painter",
    "\"The Third of May\" painter",
    "\"Naked Maja\" painter",
    "\"Tauromaquia\" artist",
    "\"Naked Maja\" artist",
    "\"Naked Maja\" painter",
    "\"The Second of May\" artist",
    "?ôThe Naked Maja?ö painter"
  ],
  "edna": [
    "First name of the poet whose \"candle burns at both ends\"",
    "Chef Lewis who wrote \"The Taste of Country Cooking\"",
    "Mystery writer Buchanan",
    "Pulitzer winner ___ St. Vincent Millay",
    "\"Cimarron\" novelist Ferber",
    "___ Turnblad, role for Divine in \"Hairspray\"",
    "Chef Lewis who wrote \"The Taste of Country Cooking\"",
    "Poet ___ St. Vincent Millay",
    "Novelist Ferber",
    "Dame of fame",
    "Novelist O'Brien",
    "First name at Springfield Elementary",
    "___ Lewis, \"The Taste of Country Cooking\" writer",
    "Poet ___ St. Vincent Millay",
    "\"Cimarron\" novelist Ferber",
    "Poet ___ St. Vincent Millay",
    "Irish novelist O'Brien",
    "Poet ___ St. Vincent Millay",
    "Woman's name that's an anagram of two men's names",
    "\"Hairspray\" mom",
    "Actress Best of old Hollywood",
    "Poet ___ St. Vincent Millay",
    "Eccentric fashion designer in \"The Incredibles\"",
    "Dame ___ Everage",
    "Novelist Ferber",
    "Woman's name meaning \"pleasure\"",
    "Woman's name that's an anagram of a European native",
    "___ Krabappel, teacher on \"The Simpsons\"",
    "Poet ___ St. Vincent Millay",
    "___ Mode, woman in \"The Incredibles\"",
    "Author Ferber",
    "Poet ___ St. Vincent Millay",
    "Novelist Ferber",
    "Poet ___ St. Vincent Millay",
    "Pulitzer winner Ferber",
    "Mother in \"Hairspray\"",
    "Bespectacled Dame of comedy",
    "\"Hairspray\" matriarch",
    "\"Downton Abbey\" maid",
    "Ferber who wrote \"Show Boat\"",
    "Designer Mode of \"The Incredibles\"",
    "Ferber who wrote \"Giant\"",
    "Comical \"Dame\"",
    "Novelist O'Brien",
    "Ferber who wrote \"Giant\"",
    "\"Downton Abbey\" maid",
    "Krabappel of Springfield",
    "\"Hairspray\" mom usually played by a man",
    "Ned's bride on \"The Simpsons\" in 2012",
    "Buchanan of mystery",
    "Woman's name that's an anagram of a man's name",
    "\"Country Girl\" memoirist O'Brien",
    "Irish novelist O'Brien",
    "Dame ___",
    "Buchanan in a bookstore",
    "Principal Seymour's girlfriend on \"The Simpsons\"",
    "\"Giant\" novelist Ferber",
    "\"Downton Abbey\" maid",
    "Poet ___ St. Vincent Millay",
    "___ Krabappel of \"The Simpsons\"",
    "\"Hairspray\" mom",
    "Krabappel of toondom",
    "Dame ___ Everage",
    "Novelist Ferber",
    "Poet ___ St. Vincent Millay",
    "\"Hairspray\" role",
    "Poet ___ St. Vincent Millay",
    "___ Krabappel, Bart Simpson's teacher",
    "Dame ___ Everage",
    "Bart's teacher",
    "\"Hairspray\" role",
    "\"The Simpsons\" teacher Krabappel",
    "Mrs. Garrett on \"The Facts of Life\"",
    "___ Mode, female character in \"The Incredibles\"",
    "___ St. Vincent Millay",
    "Dame ___",
    "Cross-dressing \"Dame\" of humor",
    "Author Ferber",
    "\"Laverne & Shirley\" landlady",
    "Novelist Ferber",
    "Best in shows",
    "Dame who's a hoot",
    "___ Krabappel of \"The Simpsons\"",
    "\"Giant\" writer Ferber",
    "Housemother to Tootie, Natalie, Blair and Jo",
    "Writer ___ St. Vincent Millay",
    "Dame ___ Everage (Barry Humphries character)",
    "Poet ___ St. Vincent Millay",
    "Author Ferber",
    "\"The Simpsons\" teacher who was called Mrs. K",
    "Mom in \"Hairspray\"",
    "Novelist Ferber",
    "Mystery writer Buchanan",
    "Writer Ferber",
    "\"Enid and ___ dine\" (palindrome)",
    "\"Hairspray\" mom",
    "___ St. Vincent Millay",
    "\"The Facts of Life\" housemother ___ Garrett",
    "Bart's teacher, ___ Krabappel",
    "Novelist Ferber",
    "Mrs. Turnblad in \"Hairspray\"",
    "Author Ferber",
    "Novelist Ferber",
    "Novelist Ferber",
    "Mystery author Buchanan",
    "See 104-Down",
    "Poet ___ St. Vincent Millay",
    "Girl's name that's a Texas county seat",
    "Dame ___",
    "Actress Best",
    "\"So Big\" author Ferber",
    "Author Ferber",
    "Best seen on-screen",
    "Dame from Down Under",
    "\"Show Boat\" novelist Ferber",
    "Author Ferber",
    "\"Show Boat\" author Ferber",
    "Author Ferber",
    "Tracy?Æs mom, in ?ôHairspray?ö",
    "Novelist O'Brien",
    "Best of theater",
    "Mystery author Buchanan",
    "Poet ___ St. Vincent Millay",
    "Dame ___ Everage",
    "Writer Ferber",
    "Author Ferber",
    "Author Ferber",
    "Mrs. Garrett of \"The Facts of Life\"",
    "Poet ___ St. Vincent Millay",
    "Dame ___ Everage",
    "Poet ___ St. Vincent Millay",
    "Writer Buchanan",
    "Buchanan who wrote \"The Corpse Had a Familiar Face\"",
    "Dame ___, Barry Humphries character",
    "Dame Everage",
    "Dame ___",
    "\"The Awakening\" protagonist",
    "\"Giant\" author Ferber",
    "Pulitzer winner Buchanan",
    "Irish novelist O'Brien",
    "Poet ___ St. Vincent Millay",
    "Seat of Jackson County, Texas",
    "Author Ferber",
    "Laverne and Shirley's landlady",
    "1999-2000 \"Dame\" on Broadway",
    "___ St. Vincent Millay",
    "Poet ___ St. Vincent Millay",
    "Novelist Ferber",
    "Actress Best",
    "Author Ferber",
    "Old-time actress Purviance",
    "\"The High Road\" novelist O'Brien",
    "Actress Skinner of \"Mister Ed\"",
    "Dame ___ Everage",
    "Best of the theater",
    "Ferber of fiction",
    "Author O'Brien",
    "Best or Ferber",
    "Seat of Jackson County, Tex.",
    "___ St. Vincent Millay",
    "\"Giant\" author Ferber",
    "Poet ___ St. Vincent Millay",
    "Best of the theater",
    "Seat of Jackson County, Tex.",
    "Irish novelist O'Brien",
    "Mystery writer Buchanan",
    "___ St. Vincent Millay",
    "\"The Simpsons\" schoolteacher Krabappel",
    "Poet ___ St. Vincent Millay",
    "Writer Ferber",
    "Actress ___ May Oliver",
    "Poet ___ St. Vincent Millay",
    "Actress Purviance",
    "Ferber of \"Show Boat\"",
    "___ St. Vincent Millay",
    "Writer Ferber",
    "Old-time actress ___ May Oliver",
    "Author O'Brien",
    "Author Ferber",
    "Writer Buchanan",
    "Author Ferber",
    "Author Ferber",
    "Seat of Jackson County, Tex.",
    "Author Ferber",
    "Seat of Jackson County, Texas",
    "Mystery writer Buchanan",
    "Author Ferber",
    "Actress Best",
    "Novelist Ferber",
    "Author Ferber",
    "Pulitzer author Buchanan",
    "___St. Vincent Millay",
    "Author O'Brien",
    "Mystery writer Buchanan",
    "Ferber or Millay",
    "Novelist O'Brien",
    "Writer Ferber",
    "Comic \"Dame\"",
    "Irish novelist O?ÆBrien",
    "Author Ferber",
    "Irish author O'Brien",
    "Actress Purviance",
    "Poet Millay",
    "Author Ferber",
    "Author Ferber",
    "O'Brien or Ferber",
    "Purviance of Chaplin films",
    "TV's Dame ___ Everage",
    "Actress Purviance",
    "Actress ___ May Oliver",
    "Irish novelist O'Brien",
    "Writer Buchanan",
    "Best of old films",
    "___ St. Vincent Millay"
  ],
  "once": [
    "Years back",
    "___-over",
    "In the past",
    "Historically",
    "Second word in many limericks",
    "How many times the Washington Nationals have won the World Series",
    "How many times TV's Perry Mason lost a case",
    "___ and for all",
    "In years past",
    "Not again!",
    "First word of a fairy tale",
    "Previously",
    "A while ago",
    "In olden days",
    "A while back",
    "Almost never",
    "\"___ bitten, twice shy\"",
    "First word in a fairy tale",
    "A long time ago",
    "Back in the day",
    "\"There was a time ...\"",
    "\"Time was ...\"",
    "First word in a fairy tale",
    "Part of YOLO",
    "Start of a reminiscence",
    "In times past",
    "Fairy tale beginning",
    "Without repeating",
    "First word of \"The Raven\"",
    "\"Time was ... \"",
    "Years ago",
    "Without repetition",
    "First word in a fairy tale",
    "___ more",
    "Number of times Howard Hughes's Spruce Goose flew",
    "Start of a fairy tale",
    "First word of a fairy tale",
    "Fairy tale's first word",
    "Back in the day",
    "Fairy tale starter",
    "In years past",
    "Never to be repeated",
    "As soon as",
    "\"Back in my day ...\"",
    "Historically",
    "Start of a reminiscence",
    "In times gone by",
    "Many moons ago",
    "Formerly",
    "Never before, never again",
    "A single time",
    "Start of a reminiscence",
    "In the past",
    "\"I'll only say this ___ ...\"",
    "First word in a fairy tale",
    "Previously",
    "Ages ago",
    "Grimm start?",
    "\"The Raven\" start",
    "\"___ upon a time ...\"",
    "___ in a blue moon",
    "Formerly",
    "Almost never",
    "The moment that",
    "\"___ upon a time ...\"",
    "As soon as",
    "How many times Laurence Olivier won a Best Actor Oscar",
    "Almost never",
    "Almost never",
    "Start of many a tale",
    "\"When I was young ...\"",
    "\"___ upon a time ...\"",
    "Formerly",
    "In a past life",
    "In the past",
    "Head of a tale?",
    "Erstwhile",
    "\"Going ___ ...\"",
    "Some time ago",
    "\"When I was a lad ...\"",
    "In days gone by",
    "Fairy tale start",
    "Without following up, say",
    "Not again",
    "It's often enough",
    "Way back when",
    "Formerly",
    "Second word in many limericks",
    "___ a day",
    "Fairy tale starter",
    "\"The Raven\" opening",
    "Long ago",
    "First word in many a fairy tale",
    "First word of \"The Raven\"",
    "___ in a blue moon",
    "A Grimm beginning?",
    "A single time",
    "On a single occasion",
    "A bit more than never",
    "\"___ upon a time ...\"",
    "In the old days",
    "\"There was a time ...\"",
    "Enough, for some",
    "Formerly",
    "Way back when",
    "First word of \"The Raven\"",
    "It's enough, for some",
    "First word of \"The Raven\"",
    "A while ago",
    "Enough, for some",
    "\"___ upon a time ...\"",
    "Between twice and never",
    "\"___ upon a time ...\"",
    "If ever",
    "Formerly",
    "In the past",
    "First word of \"Shrek\"",
    "Long ago",
    "Back then",
    "A single time",
    "Years ago",
    "Story starter",
    "Hardly at all",
    "Second word of many limericks",
    "Without repetition",
    "Years back",
    "After",
    "Some time ago",
    "\"___ upon a time ...\"",
    "In years past",
    "\"When I was young ?à\"",
    "Way back when",
    "Whenever",
    "Grimm opener",
    "A long time ago",
    "First word of \"The Raven\"",
    "Fairy tale starter",
    "A while ago",
    "Years back",
    "A while ago",
    "Fairy tale start",
    "For ___ (on this occasion)",
    "Long ago",
    "As soon as",
    "Formerly",
    "Fairy tale start",
    "\"There was a time ...\"",
    "First word of \"The Raven\"",
    "How coffee filters are used",
    "A while back",
    "Story starter",
    "Story starter",
    "Formerly",
    "Previously",
    "Way back when",
    "In the past",
    "Immediately, after \"at\"",
    "Popular story start",
    "___ upon a time",
    "As soon as",
    "Almost never?",
    "\"___ upon a time\"",
    "In the past",
    "Storybook starter",
    "Fairy tale opener",
    "First word in a fairy tale",
    "\"___ bitten ...\"",
    "Long ago",
    "\"___ bitten, twice shy\"",
    "Storybook beginning",
    "Fairy tale opener",
    "In the old days",
    "Long ago",
    "Formerly",
    "Some time ago",
    "As soon as",
    "A single time",
    "Grimm beginning",
    "Enough, sometimes",
    "As soon as",
    "Fairy tale starter",
    "In the old days",
    "Back then",
    "Enough, for some",
    "Give the ___-over",
    "Fairy tale opener",
    "A single time",
    "\"Just this ___...\"",
    "\"___ bitten, twice shy\"",
    "Fairy tale start",
    "As soon as",
    "In the old days",
    "Fairy tale start",
    "As soon as",
    "Story opening",
    "As soon as",
    "As soon as",
    "Years ago",
    "Years ago",
    "At some prior point",
    "___ and for all",
    "In the past",
    "In olden times",
    "\"Going ___, going...\"",
    "\"___ bitten, twice shy\"",
    "On a single occasion",
    "First word in a fairy tale",
    "___ in a blue moon",
    "Enough, for some",
    "Grimm beginning",
    "As soon as",
    "Erstwhile",
    "Story starter",
    "\"___ bitten...\"",
    "Auctioneer's word",
    "Fairy tale opener",
    "Fairy tale beginning",
    "A single time",
    "Fairy tale's opening word",
    "A single time",
    "Ever",
    "Fairy tale's first word",
    "Exceptional occasion",
    "Second word of many limericks",
    "A single time",
    "Some time ago",
    "Years ago",
    "As soon as",
    "A long time ago",
    "In the past",
    "Fairy tale starter",
    "In times past",
    "Whenever",
    "\"___ bitten, twice shy\"",
    "Less than rarely",
    "Fairy tale starter",
    "Not enough, to Susann",
    "As soon as",
    "As soon as",
    "How some cousins are removed?",
    "\"Just this___...\"",
    "Fairy tale start",
    "In days of old",
    "Previously",
    "A single time",
    "In years past",
    "Formerly",
    "Formerly",
    "Fairy tale start",
    "Formerly",
    "Years ago",
    "Story starter",
    "A single time",
    "Without repetition",
    "Way back when",
    "John Berger's \"___ in Europa\"",
    "If ever",
    "Yarn opener",
    "Formerly",
    "Years ago",
    "Some years back",
    "Formerly",
    "Previously",
    "___ over lightly",
    "Tale opener",
    "1949 hit \"___ in Love With Amy\"",
    "___-over",
    "Start of many a tale",
    "First word of \"The Raven\"",
    "In the old days",
    "Tale starter"
  ],
  "toss": [
    "Caber ___ (Scottish athletic event)",
    "Throw out",
    "Chuck",
    "Chuck",
    "Egg ___",
    "Scrap",
    "Sling",
    "Trash",
    "Word with coin or ring",
    "Throw",
    "Get rid of",
    "Have trouble sleeping, say",
    "Deep-six",
    "Eject",
    "Coin ___",
    "Throw out",
    "Flip, as a coin",
    "Part of a tennis serve",
    "Turn's partner",
    "Put in the trash",
    "Turn's partner",
    "Get rid of",
    "Ring ___",
    "Throw in the trash",
    "Propose tentatively, with \"out\"",
    "Something to do to a salad or coin",
    "Chuck",
    "Chuck",
    "Fling",
    "Give the boot",
    "Eighty-six",
    "Eject, as from a game",
    "Chuck",
    "Hurl",
    "Deep-six",
    "Ditch",
    "Put to waste?",
    "Pitch",
    "Chuck",
    "Pitch",
    "Deep-six",
    "Jettison",
    "Eject from the game",
    "Part of a tennis serve",
    "Dispose of",
    "Trash",
    "Fling",
    "Cast",
    "Chuck",
    "Flip",
    "Deep-six",
    "Fling",
    "Deep-six",
    "It can turn up heads",
    "Flip, as a coin",
    "Pitch",
    "Give the heave-ho",
    "Deep-six",
    "Play with, as a Frisbee",
    "Flip",
    "Put into a 35-Down",
    "___ and turn",
    "Light throw",
    "Chuck",
    "Chuck",
    "Eighty-six",
    "Flip",
    "Chuck",
    "Junk",
    "Fling",
    "Coin action",
    "Eighty-six",
    "Turn's partner",
    "Turn's partner",
    "Use a Frisbee",
    "Deep-six",
    "Flip",
    "Coin flip",
    "Flip",
    "Deep-six",
    "Jettison",
    "Mix thoroughly",
    "Stone's throw",
    "Prekickoff ritual",
    "Chuck",
    "Chuck",
    "Start of a football game",
    "Prepare a salad",
    "Throw out",
    "Fling",
    "Kickoff preceder",
    "Not sleep peacefully",
    "Chuck",
    "Sleep restlessly",
    "Deep-six",
    "Eighty-six",
    "Pitch's partner",
    "Fling",
    "Turn's partner",
    "Pitch",
    "Attempt at a carnival booth",
    "Chuck",
    "Easy throw",
    "Have a fling?",
    "Light throw",
    "Dispose of, informally",
    "Prepare 49-Down",
    "Chuck",
    "Play horseshoes",
    "Gridiron opener",
    "Lob",
    "Ring game",
    "Heave",
    "Eighty-six",
    "Throw gently",
    "Go for a ringer",
    "Word with ring or coin",
    "Chuck",
    "Flip",
    "Eight-six",
    "Start of a bowl game"
  ],
  "nair": [
    "Depilatory brand",
    "Popular depilatory",
    "Product once advertised with the jingle \"Who wears short shorts? ...\"",
    "Brand to use \"if you dare wear short shorts\"",
    "Hair removal cream brand",
    "Hair removal brand",
    "Hair removal brand",
    "Depilatory brand",
    "Hair-razing name?",
    "Commercial alternative to waxing",
    "Product that can maintain a bikini line",
    "Veet rival",
    "\"The less you wear, the more you need ___\" (slogan)",
    "Depilatory brand",
    "Hair-razing stuff",
    "Shaving alternative",
    "Brand once advertised with the jingle \"We wear short shorts ...\"",
    "Hair removal brand",
    "Hair removal product",
    "Hair removal option",
    "Hair removal product",
    "Popular depilatory",
    "Depilatory brand",
    "Hair removal option",
    "Product that comes as a cream or wax",
    "Depilatory brand",
    "Neet rival",
    "Dipilatory brand",
    "Film director Mira"
  ],
  "narc": [
    "Snitch (on)",
    "Snitch (on), informally",
    "Deal breaker?",
    "Snitch (on)",
    "Drug cop",
    "Antidrug agent, informally",
    "Pot watcher?",
    "Bad person to do a deal with",
    "Undercover buster",
    "Person who's happy to go bust?",
    "Deal breaker?",
    "Drug cop",
    "Drug informant, informally",
    "Stash hunter",
    "Deal watcher, informally",
    "Drug cop",
    "Deal breaker?",
    "Many a police officer on \"The Wire\"",
    "Traffic cop, for short?",
    "Pusher buster",
    "Dealer buster",
    "Dealer's enemy",
    "Antidrug cop",
    "Dealbreaker?",
    "Buster?",
    "Person moving against traffic?",
    "Snitch (on), in slang",
    "Bust maker",
    "Traffic stopper?",
    "Undercover cop, perhaps",
    "Seeker of illicit 48-Across",
    "Undercover agent",
    "Buster?",
    "Buster",
    "One looking for a deal?",
    "One awaiting a shipment, maybe",
    "Undercover buster",
    "Crack officer?",
    "Person on the alert for snow?",
    "One looking for a deal?",
    "Pusher catcher, for short",
    "D.E.A. agent",
    "One always on the lookout for a deal",
    "Figure in a bust",
    "War on drugs fighter",
    "Officer who may not be in uniform",
    "Fuzz buster?",
    "Buster?",
    "Pusher's nemesis",
    "Buster?",
    "Bust maker",
    "Figure in a bust",
    "One who monitors traffic",
    "Pusher's foe",
    "Weed eradicator",
    "Drug buster, for short",
    "Drug buster",
    "Drug buster",
    "D.E.A. agent",
    "Pusher's pursuer",
    "One who nabs 15-Across",
    "Bust figure",
    "Worker with a dog",
    "Drug cop",
    "Dealer's foe",
    "Bust maker",
    "Drug agent",
    "Raider?",
    "Buster?",
    "D.E.A. agent",
    "Buster?",
    "One watching for traffic",
    "Mule buster",
    "Undercover person",
    "Prosecution witness, maybe",
    "D.E.A. agent",
    "D.E.A. operative",
    "Buster",
    "One who works with dogs, maybe",
    "Drug agent",
    "\"Traffic\" cop",
    "Nonuniformed police officer",
    "Drug dealer's nemesis",
    "Pusher pursuer",
    "D.E.A. agent",
    "Drug cop",
    "G-man",
    "Buster",
    "Buster",
    "Drug cop",
    "Bust maker",
    "Dealer's nemesis",
    "D.E.A. agent",
    "Undercover worker",
    "One whose work's a bust",
    "D.E.A. officer",
    "Police decoy, sometimes",
    "Dealer's nemesis",
    "Traffic cop?",
    "Pusher catcher",
    "Bust maker",
    "D.E.A. employee",
    "Bust participant",
    "Government detective",
    "Pusher's pursuer",
    "Pusher's nemesis",
    "D.E.A. worker",
    "Druggie's nemesis"
  ],
  "snag": [
    "Hitch or glitch",
    "Problem that's holding things up",
    "Get hold of",
    "Slight problem",
    "Fly in the ointment",
    "Sweater damage",
    "Glitch",
    "Catch",
    "Stumbling block",
    "Sudden problem in a plan",
    "Catch, as on a thorn",
    "Stocking problem",
    "Slight problem",
    "Tiny problem",
    "Glitch",
    "Unexpected problem",
    "Fly in the ointment",
    "Minor setback",
    "Hitch",
    "Slight hitch in one's plans",
    "Hosiery mishap",
    "Stumbling block",
    "Hitch",
    "Catch",
    "Glitch",
    "Sudden impediment",
    "Get caught on something",
    "Grab onto",
    "Bit of a jam",
    "Impediment",
    "Hang-up",
    "It might result in a run",
    "Hitch",
    "Hosiery spoiler",
    "Minor hang-up",
    "Hiccup",
    "It's \"catchy\"",
    "Sweater flaw",
    "Reason to say \"Now what do we do?\"",
    "It might produce a run",
    "Hitch",
    "Catch",
    "Hitch",
    "Unforeseen difficulty",
    "Hitch",
    "Hose problem",
    "Run producer",
    "Fly in the ointment",
    "Minor setback",
    "Problem with a fishing line",
    "Glitch",
    "Catch",
    "Planner's problem",
    "Impediment",
    "Minor setback",
    "Catch",
    "Hitch",
    "Fly in the ointment",
    "Planner's woe",
    "Glitch",
    "Hangup",
    "Hose problem",
    "Unforeseen difficulty",
    "Catch",
    "Hose hole",
    "Rub, in a way",
    "Holdup",
    "Unforeseen difficulty",
    "Progress impeder",
    "Barely catch",
    "Hitch",
    "Hosiery problem",
    "Catch",
    "It's a reel problem",
    "What's holding things up",
    "Hitch or glitch",
    "Small setback",
    "Holdup",
    "Catch-22",
    "Fly in the ointment",
    "Hit a ___",
    "Hidden catch",
    "Hitch",
    "Fly in the ointment",
    "Catch",
    "Kink",
    "Unexpected trouble",
    "Run producer",
    "Fly in the ointment",
    "Hitch",
    "Hang-up",
    "Catch",
    "Difficulty",
    "Catch"
  ],
  "adam": [
    "Driver around a lot?",
    "Congressman Kinzinger",
    "\"Madam, I'm ___\" (palindromic greeting)",
    "Name that derives from the Hebrew word for \"earth\"",
    "First person?",
    "One who raised Cain",
    "Driver around Hollywood",
    "Michelangelo's \"The Creation of ___\"",
    "Writer Serwer of The Atlantic",
    "Man with a spare rib?",
    "One with a first-person narrative?",
    "Early human",
    "TV's \"___ Ruins Everything\"",
    "Genesis man",
    "Not know from ___",
    "Driver of \"BlacKkKlansman\"",
    "First mate?",
    "Fall guy?",
    "Noted exile",
    "Not know from ___ (be clueless about)",
    "Levine of Maroon 5",
    "Figure in Milton's \"Paradise Lost\"",
    "Alternative name for He-Man",
    "Congressman Schiff",
    "Leader of a long race?",
    "First among men",
    "First lady's man",
    "First name?",
    "Actor Driver of \"The Force Awakens\"",
    "Partner of 34-Across",
    "Actor Driver of \"Star Wars: The Force Awakens\"",
    "Noted evictee",
    "Schiff on the House Intelligence Committee",
    "Garden party?",
    "Race leader?",
    "First among men",
    "Name first encountered in Genesis 2",
    "Man exiled from 55-Down",
    "Police dispatcher's \"A\"",
    "Economist Smith",
    "\"Madam, I'm ___\" (palindromic introduction to Eve)",
    "To whom God said \"For dust you are and to dust you will return\"",
    "Singer Lambert",
    "Old Testament patriarch",
    "First in a race?",
    "Garden party?",
    "Eve's man",
    "Scott of \"Parks and Recreation\"",
    "\"Paradise Lost\" character",
    "Eve's man",
    "Biblical figure often depicted with a fig leaf",
    "Michelangelo's \"The Creation of ___\"",
    "Biblical partner of 47-Across",
    "Leader of a race?",
    "Singer Lambert",
    "West of the screen",
    "Driver of \"Girls\"",
    "\"Bonanza\" brother",
    "Fig leaf wearer, in some art",
    "Eve's mate",
    "First of all",
    "West of \"Batman\"",
    "Eve's mate",
    "Singer Lambert",
    "Eve's mate",
    "Figure on the ceiling of the Sistine Chapel",
    "Levine of \"The Voice\"",
    "Genesis man",
    "Economist Smith who coined the term \"invisible hand\"",
    "\"___ / Had 'em\" (classic two-line poem about fleas)",
    "Eve's mate",
    "First of its kind?",
    "Sistine Chapel chap",
    "Sixth-day creation",
    "Leading man?",
    "Eve's man",
    "First name?",
    "Economist Smith",
    "Man without parents",
    "Very early 2-Down",
    "To whom God said \"You are dust, and to dust you shall return\"",
    "Genesis man",
    "33-Across's partner",
    "\"As You Like It\" servant",
    "First of all?",
    "Singer Lambert",
    "63-Across's father",
    "Economist Smith",
    "One cast out of paradise",
    "\"Paradise Lost\" character",
    "Fictional inspector Dalgliesh",
    "Singer Lambert, runner-up on the 2009 \"American Idol\"",
    "Smith of note",
    "Father of Cain and Abel",
    "Funnyman Sandler",
    "Leading man?",
    "\"Fall\" guy",
    "First mate",
    "He's seen on the ceiling of the Sistine Chapel",
    "The first prophet of God, in Islam",
    "Genesis patriarch",
    "\"Madam, I'm ___\"",
    "Noted exile",
    "Comedian Sandler",
    "Exile of note",
    "Sistine Chapel figure",
    "Leading man?",
    "Comic Sandler",
    "Genesis man",
    "First among men",
    "With 51-Across, Caped Crusader portrayer",
    "Comic Sandler",
    "Garden party?",
    "First mate?",
    "Faithful servant in \"As You Like It\"",
    "First family member",
    "First of all",
    "One who raised Cain",
    "Funnyman Sandler",
    "Biblical evictee",
    "Creation on the sixth day",
    "Rodin sculpture at the Met",
    "Provider of the first bone transplant?",
    "Garden party?",
    "First one",
    "Party of the first part?",
    "Comic Sandler",
    "Father of Seth",
    "One who raised Cain",
    "Grandfather of Enos",
    "With 69-Across, caped crusader player of 1966",
    "One there in the first place",
    "Eve's mate",
    "Man in a garden",
    "First mate?",
    "Sandler of \"Big Daddy\"",
    "___ West of \"Batman\"",
    "Garden figure",
    "Grandfather of 16-Across",
    "First father",
    "Eve's predecessor",
    "First resident of 61-Across",
    "Man cast out of paradise",
    "Genesis man",
    "Garden party?",
    "Sandler of \"Big Daddy\"",
    "First mate?",
    "First of all",
    "___ Clayton Powell Jr.",
    "First father",
    "Eve's man",
    "First of all",
    "He raised Cain",
    "Economist Smith",
    "Fall guy?",
    "Brother of Little Joe",
    "Man in Eden",
    "First person",
    "See 83-Across",
    "Man who was never born",
    "Sistine Chapel figure",
    "Unborn person?",
    "21-Across of 6-Down",
    "Rodin sculpture at the Met",
    "Eve's man",
    "Man on first?",
    "Genesis gentleman",
    "First of billions",
    "Fall guy?",
    "Polish poet Mickiewicz",
    "Sandler of \"Big Daddy\"",
    "Evictee from paradise",
    "Funnyman Sandler",
    "Man in a garden",
    "As old as ___",
    "Cain was his son",
    "Figure on the ceiling of the Sistine Chapel",
    "Man with a spare rib?",
    "One of TV's Cartwrights",
    "Actor Baldwin",
    "First of all",
    "Fall guy?",
    "Role in Haydn's \"The Creation\"",
    "Not know from ___",
    "First name?",
    "Classic Rodin sculpture",
    "Resident of 63-Across",
    "Eden dweller",
    "\"As You Like It\" servant",
    "Economist Smith",
    "Leading man?",
    "Sixth-day creation",
    "\"Paradise Lost\" character",
    "Dweller in Paradise",
    "Economist Smith",
    "1910 Rodin sculpture at the Met",
    "Garden dweller",
    "See 41-Across",
    "Garden of Eden man",
    "Famous rib donor",
    "Man in a garden",
    "First of all",
    "Eden exile",
    "First of all",
    "West of Hollywood",
    "Man in a garden",
    "Eliot's Bede",
    "Economist Smith",
    "Rodin sculpture",
    "Sandler formerly of \"S.N.L.\"",
    "Genesis 2:7 subject",
    "First of all",
    "TV's \"___ 12\"",
    "One of the Arkins",
    "A Cartwright",
    "Economist Smith",
    "___ Clayton Powell",
    "First of all",
    "___ Clayton Powell",
    "Edenite",
    "Not know from ___",
    "Sandler of \"Saturday Night Live\"",
    "First of all",
    "West of Gotham City",
    "Human #1",
    "Paradise evictee",
    "Fatherless fellow",
    "Garden dweller",
    "First bone donor",
    "\"Paradise Lost\" character",
    "Eden resident"
  ],
  "sera": [
    "Some hospital supplies",
    "Song word repeated after \"Que\"",
    "\"Buona ___\"",
    "\"Buona ___\" (Italian greeting)",
    "Repeated word in a Doris Day song",
    "Shot contents",
    "Liquids in shots",
    "\"Buona ___\" (Italian greeting)",
    "\"Buona ___\" (Italian greeting)",
    "Some hospital supplies",
    "Hospital fluids",
    "Blood parts",
    "Blood fluids",
    "Pomeriggio follower",
    "Contents of IV bags",
    "Blood components",
    "Evening in Italy",
    "Shot putter's supply?",
    "Shot putters' needs?",
    "Word sung twice after \"Que\"",
    "Vial liquids",
    "Antivenins, e.g.",
    "Blood products",
    "Shot contents",
    "Lab fluids",
    "They may be used in veins",
    "Song word repeated after \"Que\"",
    "Evening, in Italy",
    "Vial fluids",
    "They often pass through needles",
    "Blood fluids",
    "Blood fluids",
    "Hospital fluids",
    "Inoculation fluids",
    "Vital fluids",
    "Red Cross supply",
    "Evening on the Arno",
    "Clinic shipments",
    "Song word repeated after \"Que\"",
    "Anti-snakebite supplies, e.g.",
    "When Italian ghouls come out?",
    "It's repeated after \"Que\" in song",
    "Some medicines",
    "Clinic supplies",
    "Hospital fluids",
    "Corriere della ___, Italy's top-selling newspaper",
    "\"Buona ___\"",
    "Roman evening",
    "Evening for Evangelo",
    "\"Buona ___\"",
    "Hospital shipments",
    "Blood fluids",
    "\"Buona ___\"",
    "Fluids in bags",
    "Toxin fighters",
    "\"Buona ___!\"",
    "Hospital supplies",
    "Word repeated in a Doris Day song",
    "Antitoxins",
    "Antivenins, e.g.",
    "\"Buona ___\"",
    "Hospital fluids",
    "Some infusions",
    "Hospital supply",
    "Word repeated after \"Que,\" in song",
    "Hospital supplies",
    "Some injections",
    "They may carry antibodies",
    "Hospital fluids",
    "Evening on Mt. Etna",
    "Blood fluids",
    "Red Cross inventories",
    "Word repeated after \"Que\" in song",
    "Red Cross supplies",
    "Vaccines",
    "Hospital fluids",
    "Some medicines",
    "Clinic fluids",
    "Word repeated after \"Que,\" in song",
    "Word repeated after \"Que\" in a song",
    "Some injections",
    "Hospital supplies",
    "Evening in Rome",
    "Hospital fluids",
    "Stuff on slides",
    "Word repeated after \"Que\"",
    "Evening in Venezia",
    "Vaccines",
    "Immunologists' samples",
    "Blood fluids",
    "\"Buona ___\" (Italian greeting)",
    "Red Cross supplies",
    "Medical liquids",
    "\"Will be,\" to Doris Day",
    "\"Que\" follower, in song",
    "See 10-Across",
    "\"Buona ___\" (Italian greeting)",
    "Shot fluids",
    "Some transfusions",
    "Hospital fluids",
    "Hospital fluids",
    "Toxin fighters",
    "Infection suppressants",
    "Antivenins",
    "Red Cross supplies",
    "Some transfusions",
    "They can repel bugs",
    "Flu fighters",
    "Word repeated after \"Que\"",
    "Watery fluids",
    "Shot contents",
    "Hospital supplies",
    "Antitoxins",
    "Hospital supplies",
    "Will be, to Doris Day",
    "Intravenous infusions",
    "Antitoxin fluids",
    "Antitoxins",
    "Blood supplies",
    "Antivenins",
    "Word repeated after \"Que\"",
    "Antitoxins",
    "Transfusion fluids",
    "Bug shots?",
    "\"Que___...\"",
    "Neapolitan night",
    "Fluid parts of blood",
    "Blood fluids",
    "Intravenous fluids",
    "Antitoxins",
    "Evening at La Scala",
    "Hospital supplies",
    "Lab fluids",
    "Hospital fluids",
    "Antitoxins",
    "Hospital supplies",
    "Que follower, in song",
    "Antitoxins",
    "Transfusion liquids",
    "Some antitoxin",
    "Word repeated in a Doris Day song",
    "Word repeated in a Doris Day song",
    "\"Buona ___\" (Italian greeting)",
    "Word repeated after \"Que\"",
    "Antitoxins",
    "Antitoxins"
  ],
  "ohno": [
    "\"This can't be happening ...\"",
    "Words after a gasp",
    "\"This can't be good\"",
    "[Gulp!]",
    "\"Yikes!\"",
    "\"Oops!\"",
    "[Gulp!]",
    "\"This is terrible!\"",
    "\"Ai-yi-yi!\"",
    "\"Bummer!\"",
    "\"Yikes!\"",
    "\"How disastrous!\"",
    "\"I totally forgot!\"",
    "[Gasp!]",
    "\"Yikes!\"",
    "\"Here comes trouble!\"",
    "\"Yikes!\"",
    "\"Man, I'm sorry to hear!\"",
    "\"You've got to be kidding me!\"",
    "\"I'm so sorry\"",
    "\"What have I done!\"",
    "\"That's really bad!\"",
    "Olympian Apolo Anton ___",
    "\"Say it isn't so!\"",
    "\"Egads!\"",
    "\"This could be bad!\"",
    "\"That's terrible!\"",
    "\"Yikes!\"",
    "Eight-time Olympic medalist Apolo Anton ___",
    "Reaction of dismay",
    "\"This is disastrous!\"",
    "Words of dawning realization",
    "\"Shoot!\"",
    "\"Horrors!\"",
    "Cry of dismay",
    "\"Not that!\"",
    "\"Not that!\"",
    "\"I'm in for it now!\"",
    "\"It's all gone wrong!\"",
    "\"Whoops!\"",
    "\"Yikes!\"",
    "[gasp!]",
    "\"Yikes!\"",
    "\"This is horrible news!\"",
    "\"Egad!\"",
    "\"What a calamity!\"",
    "\"Here we go again!\"",
    "\"Don't say it!\"",
    "\"That's so sad!\"",
    "\"Yikes!\"",
    "Cry of panic",
    "\"I didn't mean to do that!\"",
    "\"Yikes!\"",
    "Reaction to bad news",
    "\"This is terrible!\"",
    "\"What a shame!\"",
    "\"Yikes!\"",
    "\"This can't be!\"",
    "\"Horrors!\"",
    "\"Yikes!\"",
    "\"I don't believe this!\"",
    "[Gasp!]",
    "\"This can't be!\"",
    "\"It can't be!\"",
    "Cry of exasperation",
    "\"Horrors!\"",
    "Shout of dismay",
    "\"This is disastrous!\"",
    "Worrywart's cry",
    "\"That wasn't good!\"",
    "\"Yikes!\"",
    "\"This looks bad!\"",
    "\"It can't be!\"",
    "\"Whatever shall we do?!\"",
    "\"Horrors!\"",
    "\"Darn it all!\"",
    "\"What a mistake!\"",
    "Cry of dread",
    "\"Yikes!\"",
    "Words of worry",
    "\"What, again?!\"",
    "\"What have I done?!\"",
    "\"What, again?!\"",
    "\"Argghh!\"",
    "\"This can't be!\"",
    "\"You gotta be kidding!\"",
    "?ôHorrors!?ö",
    "\"This can't be!\"",
    "Cry made with a head-slap",
    "\"Yikes!\"",
    "\"I must've forgot ...!\"",
    "\"This can't be!\"",
    "\"Horrors!\"",
    "\"It can't be!\"",
    "?ôSay it isn?Æt so!?ö",
    "Speed skater Apolo Anton ___",
    "\"Heaven forbid!\"",
    "\"Say it ain't so!\"",
    "\"Mon dieu!\"",
    "Cry of dismay",
    "\"Say it ain't so!\"",
    "Cry before disaster",
    "\"Say it isn't so!\"",
    "\"___ you don't!\"",
    "Worrying words from a driver",
    "\"Not that!\"",
    "\"Yikes!\"",
    "\"That's terrible!\"",
    "Cry of woe",
    "Cry before disaster",
    "\"Oops!\"",
    "\"I don't believe it!\"",
    "\"Horrors!\"",
    "Cry of horror",
    "Cousin of \"whoops!\"",
    "\"That's awful!\"",
    "\"Curses!\"",
    "\"Not again!\"",
    "\"Heaven forbid!\"",
    "\"Oops!\"",
    "\"Yipes!\"",
    "Cousin of \"Oy!\"",
    "Words of dismay",
    "\"___ you don't!\"",
    "\"Yikes!\"",
    "Cry of despair",
    "\"Horrors!\"",
    "\"It can't be!\"",
    "\"You gotta be kidding!\"",
    "\"This can't be!\"",
    "Cry of dread",
    "\"This can't be!\"",
    "\"This can't be!\"",
    "\"Don't tell me!\""
  ],
  "aclu": [
    "Org. with a list of Supreme Court cases on its website",
    "Rights org.",
    "Org. defending free speech",
    "Vocal opponent of 2001's Patriot Act, for short",
    "Org. that supported the Lovings in Loving v. Virginia",
    "Rights org.",
    "Bill of Rights defender, in brief",
    "Rights-defending org.",
    "Free speech org.",
    "Org. fighting for immigrants' rights",
    "Org. that might take the government to court",
    "Rights org.",
    "Org. defending 1-Across rights",
    "See 40-Across",
    "Nonprofit org. since 1920",
    "Grp. co-founded by Helen Keller",
    "Noted First Amendment advocate, for short",
    "Org. defending the Bill of Rights",
    "Anticensorship org.",
    "Equality-promoting org.",
    "Rights org. of which Helen Keller was a co-founder",
    "Org. fighting anti-Muslim discrimination",
    "Org. that defends individual rights",
    "\"Because Freedom Can't Protect Itself\" sloganeer, briefly",
    "Free speech defender, for short",
    "Scopes Trial org.",
    "Org. whose website has a \"Know Your Rights\" tab",
    "Grp. that promised Trump \"We'll see you in court\"",
    "Free speech advocacy grp.",
    "Rights grp.",
    "Grp. that often files amicus briefs",
    "Rights org.",
    "Grp. that heralded Edward Snowden as a patriot",
    "Org. concerned with due process",
    "Rights org.",
    "Rights org.",
    "Rights group, for short",
    "L.G.B.T. rights advocate",
    "Free speech supporter: Abbr.",
    "Rights org. since 1920",
    "___ v. Ashcroft (2004 privacy case)",
    "Rights grp.",
    "Censorship-fighting org.",
    "Rights advocacy grp.",
    "Rights org.",
    "Grp. defending individual rights",
    "Scopes Trial org.",
    "Opponent of the Patriot Act, for short",
    "Rights org.",
    "Org. since 1920 with many staff lawyers",
    "Org. protecting individual rights",
    "Freedom fighter, for short?",
    "Rights grp.",
    "\"Because Freedom Can't Protect Itself\" org.",
    "Org. opposed to capital punishment",
    "Rights org.",
    "Rights org.",
    "Org. that defends the Bill of Rights",
    "Rights org.",
    "Rights grp.",
    "Org. that fought warrantless wiretapping",
    "Rights org.",
    "Legal rights org.",
    "Org. with an amicus brief in Brown v. Board of Education",
    "Rights org.",
    "Bill of Rights advocacy grp.",
    "Org. that defends individual rights",
    "Rights grp.",
    "Defender of some unpopular causes, in brief",
    "Bill of Rights defender, in brief",
    "Scopes trial grp.",
    "Legal rights grp.",
    "Bill of Rights defender: Abbr.",
    "Rights org.",
    "Rights grp.",
    "Rights grp.",
    "Due process defender: Abbr.",
    "Friend of the powerless: Abbr.",
    "Org. involved in the Scopes trial",
    "Constitutional rights grp.",
    "First Amendment defender, for short",
    "Censorship-fighting org.",
    "Rights org.",
    "Legal rights org.",
    "Censorship-fighting org.",
    "Org. concerned with due process",
    "It may take a case pro bono",
    "Legal rights org.",
    "Human rights org.",
    "Rights org.",
    "Org. co-founded by Felix Frankfurter",
    "Org. often seen in court",
    "First Amendment lobbyists, for short",
    "Org. in rights fights",
    "Rights org.",
    "Org. that defends the Bill of Rights",
    "Rights protection grp.",
    "Rights defender, for short",
    "Freedom fighter, for short?",
    "Human rights org.",
    "Bill of Rights defender, for short",
    "Org. that defends freedoms",
    "Rights org.",
    "Rights org. since 1920",
    "Rights champion, for short",
    "Grp. joining a lawsuit",
    "Defendant's friend, often: Abbr.",
    "Due process championer, familiarly",
    "Rights org.",
    "Defendant's friend, often",
    "Rights grp."
  ],
  "kart": [
    "Go-___",
    "\"Go\" follower",
    "Nintendo's Mario ___",
    "Go-___",
    "Mario ___ (video game)",
    "Go-___ (kid's racer)",
    "It goes after go",
    "Go-___",
    "Mario ___ (Nintendo racing series)",
    "Child's racing vehicle",
    "Go-___",
    "Go-___",
    "Go-___",
    "Go-___",
    "Mario ___, Nintendo racing game",
    "Kid's racer",
    "Small racer",
    "Go-___",
    "Go-___",
    "Track racer",
    "Go-___",
    "Miniature racer",
    "Go-___",
    "Playmobile"
  ],
  "updo": [
    "Common prom coif",
    "Popular prom hairstyle",
    "Off-the-neck style",
    "Coif that might use pins and spray",
    "Common wedding hairstyle",
    "Hairstyle that calls for a lot of spray",
    "Hairstyle that needs pins",
    "Off-the-shoulders hairstyle",
    "Prom coif",
    "Many a pageant coif",
    "High hairstyle",
    "Hairstyle that might have a lot of spray",
    "Beehive, for one",
    "Beehive, for one",
    "It's hair-raising",
    "Beehive hairstyle, e.g.",
    "Pageant coif, maybe",
    "It's hair-raising",
    "High hairstyle",
    "High coif",
    "Salon selection",
    "Prom coif",
    "Hair-raiser?",
    "Hair salon option",
    "High hair style",
    "Many a prom coif",
    "Beehive, e.g.",
    "French twist, e.g.",
    "Salon creation",
    "Sweeping hairstyle",
    "Passe hairstyle",
    "Hairstyle that needs hairpins",
    "Sweeping hairstyle"
  ],
  "awed": [
    "Blown away",
    "Truly amazed",
    "Blown away",
    "Left speechless",
    "Filled with wonder",
    "Left speechless, maybe",
    "Totally blown away",
    "Going \"Wow, wow, wow!\"",
    "Gobsmacked",
    "Flabbergasted",
    "Immensely impressed",
    "Bowled over",
    "In amazement",
    "Left slack-jawed",
    "Blown away",
    "Bedazzled",
    "Stupefied",
    "Bowled over",
    "Wowed",
    "Spellbound",
    "Knocked silly",
    "Wonderstruck",
    "Agape, say",
    "Left slack-jawed",
    "Deeply impressed",
    "Disbelieving, maybe",
    "Dumbstruck",
    "Dumbstruck",
    "Very impressed",
    "Thunderstruck",
    "Bowled over",
    "Wowed",
    "Wowed",
    "Full of fear",
    "Floored",
    "Staggered",
    "Impressed and then some",
    "Really impressed",
    "Dumbstruck",
    "Wowed",
    "Blown away",
    "Agape, say",
    "Thinking \"Gee whiz!\"",
    "Dumbfounded",
    "Left speechless",
    "Stunned",
    "Thunderstruck",
    "Impressed mightily",
    "Blown away",
    "Left open-mouthed",
    "Thunderstruck",
    "In a state of reverence",
    "Dumbfounded",
    "Impressed",
    "Blown away",
    "Stunned",
    "Impressed",
    "Overwhelmed",
    "Starstruck",
    "Knocked out",
    "Agape",
    "In wonderment",
    "Bowled over",
    "Quite impressed",
    "More than impressed",
    "Left agape",
    "Impressed",
    "Filled with wonder",
    "Overwhelmed",
    "Impressed deeply",
    "Greatly impressed",
    "Dumbfounded",
    "Gaping, maybe",
    "Thunderstruck"
  ],
  "moes": [
    "Fictional business on TV frequently targeted by prank calls",
    "Fictional establishment selling Duff Beer",
    "Tavern in the same town as Krusty Burger",
    "Hangout on \"The Simpsons\"",
    "Hangout for Homer",
    "\"The Simpsons\" bar",
    "Longtime TV tavern",
    "TV tavern named after its proprietor",
    "Homer Simpson's watering hole",
    "Watering hole for Homer and Barney",
    "Springfield watering hole",
    "Where Duff Beer is poured",
    "Springfield bar",
    "Homer's hangout on \"The Simpsons\"",
    "Hangout for Homer",
    "___ Southwest Grill (restaurant chain)",
    "Where Duff Beer is sold, on TV",
    "Homer's hangout",
    "Springfield tavern",
    "Place to which Bart Simpson makes prank calls",
    "TV tavern",
    "Homer's hangout on \"The Simpsons\"",
    "TV tavern that sells Duff Beer",
    "Tavern in \"The Simpsons\"",
    "Homer Simpson's hangout",
    "Homer Simpson's favorite tavern",
    "\"The Simpsons\" tavern",
    "\"The Simpsons\" bar"
  ],
  "arie": [
    "Grammy winner India.___",
    "Singer India.___",
    "Racer Luyendyk",
    "Grammy winner India.___",
    "___ Luyendyk Jr., former bachelor on \"The Bachelor\"",
    "Grammy winner India.___",
    "___ Luyendyk Jr., TV bachelor of 2018",
    "Indy winner Luyendyk",
    "Singer India.___",
    "Singer India.___",
    "Racer Luyendyk",
    "Nickname for Adrianus",
    "Racer Luyendyk",
    "Grammy-winning artist India.___",
    "Singer India.___",
    "Singer India.___",
    "India with four Grammys",
    "Singer India.___",
    "Part of \"Die Fledermaus\"",
    "Indy racer Luyendyk",
    "Singer India.___",
    "Indy 500 winner Luyendyk",
    "Indy racer Luyendyk",
    "Indy racer Luyendyk",
    "Grammy winner India.___",
    "Oberhausen opera highlight",
    "Auto racer Luyendyk",
    "First name in auto racing",
    "First name in auto racing",
    "Singer India.___",
    "Singer India.___",
    "R&B singer India.___",
    "Singer India.___",
    "Singer India.___",
    "Singer India.___",
    "1997 Indy 500 winner ___ Luyendyk",
    "Singer India.___",
    "Singer India.___",
    "Luyendyk of racing",
    "Singer India.___",
    "Singer India ___",
    "Indy winner Luyendyk",
    "Indy winner Luyendyk",
    "Racer Luyendyk",
    "Singer India.___",
    "\"Acoustic Soul\" singer India.___",
    "Racer Luyendyk",
    "Racer Luyendyk",
    "German opera part",
    "Racer Luyendyk",
    "Indy's Luyendyk",
    "Two-time Indy winner Luyendyk",
    "Auto racer Luyendyk",
    "Indy 500's Luyendyk",
    "Indy champ Luyendyk",
    "Racing's Luyendyk",
    "Indy 500's Luyendyk",
    "Indy champ Luyendyk",
    "Indy champ Luyendyk",
    "Indy winner Luyendyk",
    "1990 Indy 500 winner Luyendyk"
  ],
  "alta": [
    "Acqua ___ (cause of annual flooding in Venice)",
    "Resort with a \"no snowboarders\" policy",
    "___Vista, early search engine",
    "Utah ski resort",
    "Utah ski area",
    "Like una montana",
    "Utah resort town",
    "Ski area in the Wasatch Mountains",
    "Skiing mecca in the Rockies",
    "Resort in Salt Lake County",
    "Western ski resort",
    "Resort near Snowbird",
    "Wasatch Mountains resort",
    "Resort in the Wasatch Mountains",
    "Utah ski town",
    "Ski area near Salt Lake City",
    "Baja's opposite",
    "Opposite of baja",
    "Roster in 63-Across",
    "Opposite of baja",
    "Province bordering Sask.",
    "Utah attraction for skiers",
    "Ski area in the Wasatch Mountains",
    "Ski resort next to Snowbird",
    "Ski resort that prohibits snowboarding",
    "Opposite of baja",
    "Neighbor resort of Snowbird",
    "Baja's opposite",
    "Utah ski resort",
    "Utah skiing mecca",
    "Ski resort in Salt Lake County",
    "Mont. neighbor",
    "Resort near Snowbird",
    "Opposite of baja",
    "Baja's opposite",
    "Utah winter vacation spot",
    "Lady Bird Johnson's middle name",
    "Utah ski resort",
    "___Vista (search engine)",
    "Claudia ___ Taylor (Lady Bird Johnson)",
    "Resort near Snowbird",
    "Utah ski resort",
    "Utah ski resort",
    "Utah ski area",
    "Utah ski resort",
    "Utah ski resort",
    "B.C. neighbor",
    "Baja's opposite",
    "Sask. neighbor",
    "___Vista (search engine)",
    "Banff Natl. Park locale",
    "Baja's opposite",
    "Ski resort near Snowbird",
    "Utah winter recreation area",
    "Utah resort",
    "Utah ski resort",
    "Utah ski resort",
    "Utah ski resort",
    "Utah resort",
    "Sask. neighbor",
    "Prov. bordering Mont.",
    "Neighbor of Mont.",
    "Lady Bird's middle name",
    "Ski resort near Snowbird",
    "Claudia ___ Taylor (Mrs. Lyndon Johnson)",
    "___ Vista",
    "Utah skiing resort",
    "Sask. neighbor",
    "Utah ski resort",
    "___ California",
    "Utah ski resort",
    "Resort next to Snowbird",
    "Resort near Snowbird",
    "___ Peak, in the Sierra Nevada",
    "Opposite of baja",
    "Utah ski resort",
    "___Vista (Web search engine)",
    "Cruz ___, Brazil",
    "Neighbor of Mont.",
    "Western ski resort",
    "Resort near Snowbird",
    "___ Loma, Calif.",
    "Utah ski resort",
    "Utah ski spot",
    "___ Loma, Calif.",
    "Baja opposite",
    "Opposite of baja",
    "Canadian prov.",
    "Neighbor of B.C.",
    "Province east of B.C.",
    "Utah ski resort",
    "___ California",
    "Utah resort near Snowbird",
    "Claudia ___ Taylor (Lady Bird Johnson)",
    "Neighbor of Sask.",
    "Neighbor of B.C.",
    "Lady Bird's middle name",
    "High, in place names",
    "Utah ski center",
    "Mont. neighbor"
  ],
  "edit": [
    "Copy-right?",
    "Deal with issues at work?",
    "Make amends?",
    "Prep for publication",
    "___ menu (where to find Cut, Copy and Paste)",
    "Make tweaks to",
    "Computer menu bar heading",
    "Remove excess verbiage, e.g.",
    "Heading in a word processing menu",
    "Computer menu with Undo and Redo",
    "Prepare for publishing",
    "Polish before publishing",
    "Revise, as text",
    "Computer menu with Undo and Redo",
    "Right columns?",
    "Toolbar heading",
    "Word between File and View on a menu bar",
    "Polish, in a way",
    "Text massage",
    "Polish before publishing",
    "Mince words?",
    "Menu bar heading",
    "Polish, as text",
    "Make amends?",
    "Change ... one hopes for the better",
    "Prepare to be published",
    "Tend to some p's and q's, say",
    "Tweak, in a way",
    "One of the choices on a computer's 17-Across",
    "Get in the last word?",
    "Mark up, perhaps",
    "Polish up, in a way",
    "Cut down, possibly",
    "Revise copy",
    "Revise, as text",
    "Twist someone's words, say?",
    "Work with a manuscript",
    "Computer menu option",
    "Polish, as prose",
    "Cut and paste text, e.g.",
    "Move text here and there",
    "Computer menu heading",
    "Rearrange, as text",
    "Pare a phrase?",
    "Drop a line, say",
    "Make some changes to",
    "Toolbar heading",
    "Work on a lead, maybe",
    "Polish, as text",
    "Tweak, as text",
    "Correct copy",
    "Change one's story?",
    "Work with 31-Down",
    "PC menu heading",
    "Blue-pencil",
    "Shorten, say",
    "___ menu",
    "Revise, as copy",
    "Cut down to size, maybe",
    "Make a long story short, perhaps",
    "Mark one's words?",
    "Mince words?",
    "Make cuts, say",
    "Exchange some words?",
    "Work on copy",
    "Cut a column, say",
    "Prepare for publication",
    "Strike out, say",
    "Do some paper work",
    "Tighten, possibly",
    "Common menu option",
    "Blue-pencil",
    "Execute a 47-Down, e.g.",
    "Move text around",
    "Tweak some text",
    "Rework a document",
    "Change, as text",
    "Change a sentence, say",
    "Move text around",
    "Take out, maybe",
    "---",
    "Better papers?",
    "Cut and paste, say",
    "Tighten the writin'?",
    "Move text around",
    "Fine-tune, as a script",
    "Cut and paste, say",
    "Ready for release",
    "Clean rags?",
    "Fix up, as text",
    "Make a long story short?",
    "Time manager's directive?",
    "Revise",
    "Step after \"write\"",
    "Switch lines, say?",
    "Mark up, perhaps",
    "Blue-pencil",
    "Get copy right",
    "Use a 21-Across, e.g.",
    "Add or delete, say",
    "Cut, say",
    "Alter pieces?",
    "Make the cut?",
    "Cut, maybe",
    "Exchange words?",
    "Touch up, as text",
    "Mark up",
    "PC menu heading",
    "Shorten, say",
    "Salon job, informally",
    "Cut for a column",
    "Trim, as text",
    "Blue-pencil",
    "Blue-pencil",
    "Tweak, say",
    "Trim to fit, maybe",
    "Cut and paste",
    "Clean copy?",
    "Work on after filming",
    "Cut and paste, say",
    "Revise",
    "Rewrite",
    "Blue-pencil",
    "Polish prose",
    "Cut for a column",
    "Get to work on Time?",
    "Polish, as text",
    "Better writing",
    "Make a long story short?",
    "Computer menu option",
    "Blue-pencil",
    "Mince words?",
    "Work on, in a way",
    "Rewrite",
    "Go to work on Time?",
    "Microsoft Word option",
    "Kind of menu",
    "Blue-pencil",
    "Get to work on Time",
    "Cut and paste",
    "Polish",
    "Do newspaper work",
    "Tidy up, in a way",
    "Do some copy work",
    "Revision, informally",
    "Trim to fit, maybe",
    "Rework",
    "Trim to fit, maybe",
    "Shorten, in a way",
    "Do some cutting, maybe",
    "Review a review, say",
    "Tinker with, in a way",
    "Do desk work at a newspaper",
    "Reword",
    "Work for Hearst, e.g.",
    "Make changes to",
    "Do Time?",
    "Make shorter, say",
    "Abridge, maybe",
    "Do magazine work",
    "Cut down, maybe",
    "Polish film, e.g.",
    "Cut and paste, e.g.",
    "Trim, as text",
    "Trim to fit",
    "Prepare to run",
    "Fix, as copy",
    "Polish language",
    "Shorten, say",
    "Cut, as film",
    "Touch up",
    "Ready for print",
    "Correct, in a way",
    "Modify",
    "Reduce a sentence, perhaps",
    "Work in the media",
    "Get to work on Time?",
    "Expunge, with \"out\"",
    "Blue-pencil",
    "Tinker with, in a way",
    "Rearrange, say",
    "Be a People person?",
    "Rework, as stories",
    "Put in or take out",
    "Rephrase",
    "Assist a writer",
    "Work on People",
    "Rework",
    "Pare, say",
    "Cut and paste",
    "Work in the cutting room",
    "Cut and paste",
    "Better copy",
    "Change the wording of",
    "Rephrase",
    "Not leave alone",
    "Database command",
    "Put in or take out, maybe",
    "Prepare for publication",
    "Blue-pencil",
    "Take out or put in, e.g.",
    "Computer menu option",
    "Do galley work",
    "Use a word processor, maybe",
    "Redo, as text",
    "Rewrite",
    "Serve Time?",
    "Go over proofs",
    "Bowdlerize, with \"out\"",
    "Touch up, as text",
    "Polish language",
    "Blue-pencil",
    "Handle text",
    "Touch up",
    "Work over Time",
    "Cut, maybe",
    "Perform copy desk work",
    "Do magazine work",
    "Blue-pencil",
    "Give Life support?",
    "Make a change for the verse?",
    "Tinker with, in a way",
    "Touch up, in a way",
    "Cut and paste",
    "Do some film work",
    "Rewrite",
    "Touch up",
    "Revise",
    "Correct copy",
    "Revise",
    "Rework, as copy",
    "Blue-pencil",
    "Shorten, perhaps",
    "Rephrase",
    "Computer text function",
    "Make a long story short",
    "Rework, as a story",
    "Rephrase",
    "Cut-and-paste",
    "Delete, with \"out\"",
    "Blue-pencil",
    "Fix text",
    "Blue-pencil",
    "Revise copy",
    "Abridge, perhaps",
    "Alter",
    "Revise"
  ],
  "sdsu": [
    "Calif. school that's about 20 miles from the Mexican border",
    "Southern California sch.",
    "Calif. school that's home to the Aztecs"
  ],
  "peer": [
    "Colleague",
    "___ review",
    "Person equal to you",
    "Equal",
    "Look closely (at)",
    "Equal",
    "One may exert pressure",
    "Source of some pressure",
    "Try to get a good look",
    "Look (into)",
    "Confrere",
    "One of 12 on a jury",
    "Kind of review",
    "Appointed member of Parliament",
    "Kind of pressure",
    "Source of pressure, perhaps",
    "One in a jury box",
    "Jury member",
    "___ pressure",
    "Equal",
    "Look through binoculars, e.g.",
    "Use a spyglass",
    "Jury member",
    "Source of pressure, at times",
    "Kind of review",
    "Duke, e.g.",
    "Look intently",
    "Use binoculars, say",
    "Kind of review",
    "___ of the realm",
    "Equal",
    "Jury member",
    "Look through some blinds, say",
    "One's equal",
    "One of a group of 12, say",
    "___ review",
    "Lady, e.g.",
    "Use binoculars, say",
    "Look searchingly",
    "Look out a window, say",
    "Equal",
    "Use a spyglass",
    "Lord, e.g.",
    "One's equal",
    "House of Lords member",
    "Word before group or pressure",
    "Earl, for one",
    "___ review",
    "Gaze",
    "One may be hereditary",
    "Equal",
    "Source of some pressure, maybe",
    "One's equal",
    "Classmate",
    "Count, e.g.",
    "Person of equal rank",
    "Equal",
    "Equal",
    "Look over a wall, say",
    "Jury member",
    "Count, perhaps",
    "One may pressure you",
    "Look",
    "Nobleman",
    "Jury member",
    "Squint",
    "Look closely",
    "Look",
    "Equal",
    "Juror",
    "One may exert pressure",
    "Kind of pressure",
    "Ibsen's \"___ Gynt\"",
    "Source of pressure, maybe",
    "Look",
    "Social equal",
    "One may exert pressure",
    "Equal",
    "Equal",
    "Use a keyhole, perhaps",
    "Equal",
    "Look closely",
    "Equal",
    "Squint (at)",
    "House of Lords member",
    "Look on tiptoe, say",
    "Equal",
    "Squint",
    "Have a look-see",
    "One of the elite",
    "Classmate",
    "Duke, e.g.",
    "Look with effort",
    "Duke or earl",
    "Look closely",
    "Type of group",
    "Marquess or viscount",
    "Look",
    "Jury member",
    "Classmate, e.g.",
    "Look searchingly",
    "Milord",
    "Look with squinty eyes"
  ],
  "aida": [
    "Verdi opera",
    "Verdi opera",
    "Operatic daughter of the king Amonasro",
    "Opera that's 2-Down backward",
    "Musical with the opening number \"Every Story Is a Love Story\"",
    "It's been performed more than 1,000 times at the Met",
    "Lover of Radames, in opera",
    "Broadway character who sings \"The Gods Love Nubia\"",
    "Opera with a noted triumphal march",
    "Musical set in ancient Egypt",
    "Radames's love, in opera",
    "Fictional Ethiopian princess",
    "Hit musical with music by Elton John",
    "Verdi work that may include camels",
    "Elton John musical based on a work by Verdi",
    "Work whose title character is buried alive",
    "Musical title that's 19-Across spelled backward",
    "Verdi soprano",
    "Radames's love, in opera",
    "Opera set partly on the banks of the Nile",
    "Elton John/Tim Rice Broadway musical",
    "Opera set in Egypt",
    "Nubian heroine of opera",
    "Lover of Radames, in opera",
    "New York Met performance 1,000+ times",
    "The king of Egypt has a part in it",
    "Its final scene is set in a tomb",
    "Verdi classic",
    "Opera set in Egypt",
    "Slave whom Amneris was jealous of",
    "Memphis belle?",
    "Title slave of the stage",
    "Opera with a slave girl",
    "\"Written in the Stars\" musical",
    "Opera set in Egypt",
    "Verdi's opera slave girl",
    "Opera that premiered in Cairo",
    "Hit Broadway musical set partly in a tomb",
    "110-Across set in Egypt",
    "Elton John/Tim Rice musical",
    "2000 musical with the song \"Fortune Favors the Brave\"",
    "Musical with the song \"Written in the Stars\"",
    "Slave singing several solos",
    "Opera that premiered on Christmas Eve of 1871",
    "2000 musical with the song \"Every Story Is a Love Story\"",
    "Verdi opera",
    "Slave who dies in the Temple of Vulcan",
    "\"Another Pyramid\" musical",
    "Opera set in ancient Egypt",
    "Work with singing Egyptians",
    "Elton John/Tim Rice musical",
    "Radames's love, in opera",
    "Classical opera redone by Elton John",
    "Opera set in ancient Egypt",
    "Musical featuring Nubians",
    "Verdi's \"Celeste ___\"",
    "Slave entombed with Radames",
    "A slave to opera?",
    "Elton John/Tim Rice musical",
    "Musical that opens with \"Every Story Is a Love Story\"",
    "Opera set along the Nile",
    "2000 musical that won four Tonys",
    "Opera set along the Nile",
    "Its closing duet is \"O terra, addio\"",
    "Slave girl of opera",
    "It's set partly at the Temple of Vulcan",
    "She dies with Radames",
    "*Lover of Radames",
    "\"Celeste ___\" (aria)",
    "Broadway musical with the song \"The Gods Love Nubia\"",
    "1953 Loren title role",
    "\"Gloria all'Egitto\" opera",
    "Operatic slave",
    "Opera set in the age of pharaohs",
    "Opera set on the banks of the Nile",
    "2000 Elton John/Tim Rice musical",
    "Musical set in ancient Egypt",
    "\"O patria mia\" source",
    "It's set in Egypt",
    "Slave of opera",
    "John/Rice musical",
    "Broadway musical set in ancient Egypt",
    "Opera set in ancient Egypt",
    "Verdi opera",
    "Broadway musical subtitled \"The Timeless Love Story\"",
    "Opera set in Egypt",
    "Slave girl of opera",
    "Its closing duet is \"O terra, addio\"",
    "2000 Broadway hit",
    "Fictional slave girl",
    "Turturro of \"The Sopranos\"",
    "Disney musical",
    "Opera set along the Nile",
    "Verdi heroine",
    "Opera set in Egypt",
    "Cairo debut of 1871",
    "Broadway hit co-written by Elton John",
    "Verdi opera",
    "Slave girl of opera",
    "It opened in 1871",
    "Verdi heroine",
    "Heather Headley title role on Broadway",
    "Award-winning Disney Broadway musical",
    "Operatic heroine",
    "The King of Egypt sings in it",
    "Opera set in Egypt",
    "Opera set in ancient Thebes",
    "Famous slave",
    "Opera with chariots",
    "Verdi slave girl",
    "Verdi opera or heroine",
    "Verdi heroine",
    "Verdi's slave girl",
    "Where to hear \"O patria mia\"",
    "Opera set in the time of the Pharaohs",
    "Verdi heroine",
    "Opera with elephants",
    "\"O patria mia\" singer",
    "Verdi heroine",
    "Verdi opera",
    "Slave of Amneris",
    "Opera set near the Nile",
    "Ethiopian of opera",
    "Verdi opera",
    "Fictional slave girl of Egypt",
    "Opera set in Memphis",
    "\"Ritorna vincitor\" singer",
    "Love of Radames",
    "Opera set in Egypt",
    "Verdi opera",
    "It ends at the Temple of Vulcan",
    "Verdi opera",
    "Verdi opera",
    "Slave of Amneris",
    "Opera set in Memphis",
    "Role for Leontyne",
    "Verdi opera",
    "Verdi's slave girl",
    "Turturro of \"Angie\"",
    "Amneris's rival",
    "Leontyne Price role",
    "It debuted in Cairo, Dec. 24, 1871",
    "Verdi heroine"
  ],
  "oxes": [
    "Dumb ___ (buffoons)",
    "Dumb ___ (oafs)",
    "Buffoons",
    "Dumb ___ (oafs)",
    "Dumb ___ (stupidheads)",
    "Clumsy fellows",
    "Clumsy, stupid fellows"
  ],
  "dorm": [
    "Place to live and learn",
    "Living space that may be empty in the summer",
    "Residence that might be named for a donor",
    "Quad digs",
    "College student's assignment",
    "Housing that's often empty in the summer",
    "State quarters?",
    "One side of a quad, say",
    "Place to find an R.A.",
    "Quad part",
    "Building often near a cafeteria",
    "Quad quarters",
    "Frat house alternative",
    "Rice quarters",
    "One side of a quad, maybe",
    "What you might be rushed to get out of?",
    "Rice pad",
    "Collegiate digs",
    "All-nighter site, perhaps",
    "Quad building",
    "Temple structure?",
    "Rice pad",
    "R.A.'s place",
    "College quarters",
    "State quarters?",
    "College digs",
    "Brown building?",
    "Home away from home",
    "Place to sleep",
    "58-Down digs",
    "Ivy-covered building, maybe",
    "Campus site",
    "Home away from home, maybe",
    "It may be coed",
    "Rice pad",
    "Place for a Yale lock?",
    "Panty raid site",
    "Quad building",
    "Rice housing",
    "Brown building",
    "College building",
    "College student's home",
    "College home",
    "College abode",
    "Campus building",
    "College digs",
    "Coed quarters"
  ],
  "anew": [
    "From the start",
    "Once more",
    "Over",
    "Freshly",
    "Over",
    "From scratch",
    "Once more",
    "Freshly",
    "From the start",
    "Again",
    "From scratch",
    "With a clean slate",
    "Over again",
    "All over again",
    "From the start",
    "Freshly",
    "All over again",
    "Over",
    "Over",
    "All over again",
    "Over",
    "Once again",
    "Over",
    "Over",
    "Once again",
    "Over",
    "From scratch",
    "Freshly",
    "Over",
    "Again",
    "Freshly",
    "All over again",
    "With a clean slate",
    "From square one",
    "From square one",
    "Freshly",
    "All over again",
    "From the start",
    "Another time",
    "All over again",
    "All over again",
    "From scratch",
    "Over",
    "From the top",
    "With a clean slate",
    "From square one",
    "Freshly",
    "From square one",
    "From the top",
    "Over again",
    "All over again",
    "From the start",
    "Over",
    "From the top",
    "Once more",
    "Once again",
    "Over",
    "All over again",
    "From the top",
    "All over again",
    "Freshly",
    "Once again",
    "Once again",
    "Over",
    "De novo",
    "From the top",
    "Again from the top",
    "Once more",
    "From the top",
    "From the top",
    "Freshly",
    "Over",
    "Once again",
    "Over again",
    "Fresh",
    "From scratch",
    "All over again",
    "Fresh",
    "All over",
    "Fresh",
    "De novo",
    "Way to start",
    "Fresh",
    "All over again",
    "All over",
    "From scratch",
    "Fresh",
    "Again",
    "Over",
    "Over",
    "From square one",
    "From the start",
    "From the top",
    "From the top",
    "Freshly",
    "Once more",
    "From the top",
    "For the second time",
    "From the top",
    "All over again",
    "From the top",
    "From the top",
    "Again",
    "Over again",
    "From scratch",
    "Once more",
    "In a different way",
    "From the top",
    "From the start",
    "One more time",
    "All over",
    "From square one",
    "From the top",
    "From square one",
    "Fresh",
    "Over again",
    "Over",
    "One more time",
    "From a fresh angle",
    "Again",
    "Over",
    "All over",
    "From the top",
    "From square one",
    "Freshly",
    "Over",
    "From scratch",
    "Once again",
    "De novo",
    "From the top",
    "All over",
    "All over again",
    "Over",
    "Over",
    "Over",
    "Over",
    "From the top",
    "Over",
    "Once more",
    "Freshly",
    "Freshly",
    "From scratch",
    "From the top",
    "Over again",
    "De novo",
    "One way to start",
    "From the top",
    "From the top",
    "Once again",
    "Once again",
    "From square one",
    "Once more",
    "Freshly",
    "From square one",
    "Over",
    "De novo",
    "De novo",
    "Freshly",
    "Over",
    "Over",
    "Over",
    "Over",
    "Over",
    "Resurgently",
    "Once more",
    "Over",
    "Another time",
    "Once more",
    "Freshly",
    "All over",
    "Over",
    "De novo",
    "Once more",
    "Freshly",
    "From the top",
    "Freshly",
    "From square one",
    "Over",
    "Freshly",
    "All over",
    "Freshly",
    "Once again",
    "De novo",
    "Freshly",
    "One more time",
    "Over",
    "De novo",
    "Once more",
    "Once more",
    "Once more",
    "From square one",
    "Freshly",
    "All over again",
    "Once more",
    "Once again",
    "All over",
    "From the top",
    "One more time"
  ],
  "egot": [
    "Performer's grand slam, in modern parlance",
    "Achievement for Whoopi Goldberg, in brief",
    "Complete set of showbiz awards, for short",
    "Awards feat, for short",
    "Barbra Streisand collection?",
    "Set of awards won by John Legend and Rita Moreno, for short",
    "Acronym for the four major entertainment awards"
  ],
  "stab": [
    "Wild guess",
    "Wild guess",
    "Wild guess",
    "Wild guess",
    "Random guess",
    "Wild guess",
    "Crack, so to speak",
    "Attempt",
    "Wild guess",
    "Try, informally",
    "Shot in the dark",
    "Wild guess",
    "Attempt",
    "Shot in the dark",
    "Jab with a knife",
    "Perforate, in a way",
    "Sharp pain",
    "Knife",
    "Wild guess",
    "Attempt, informally",
    "Wild guess",
    "Crack",
    "Try",
    "Random guess",
    "Impale",
    "Attack as Hamlet did Polonius",
    "Shot in the dark",
    "Wound on a dueler",
    "Random guess",
    "Attempt",
    "Wild guess",
    "Run through",
    "Attack with a sword",
    "Jab with a knife",
    "Transfix",
    "Wild guess",
    "Whack",
    "Try",
    "Attack with a knife",
    "Skewer",
    "Wild guess",
    "Attempt",
    "Uninformed guess",
    "Poke holes in",
    "Knife wound",
    "Stick with a knife",
    "Wild guess",
    "Assault with a knife",
    "Blind guess",
    "Attack with a knife",
    "Knife wound",
    "Stick",
    "Attempt",
    "Try",
    "Attack with a knife",
    "Shot",
    "Wound for Cassio",
    "Wild guess",
    "Spear",
    "Skewer",
    "Knife",
    "Attack with a dagger",
    "Knife wound",
    "Attack with a knife",
    "Stick with a stick",
    "Attempt",
    "Blind guess",
    "Pierce",
    "Knife",
    "Attempt",
    "Lance",
    "Attempt",
    "Try",
    "Stick with a toothpick",
    "Puncture",
    "Bayonet, e.g.",
    "Sharp pain",
    "Stick with a knife",
    "Try",
    "Dagger wound",
    "Wild guess",
    "Knife",
    "Go",
    "Wild guess",
    "Wild guess",
    "Stick",
    "Crack",
    "More than a twinge",
    "Knife",
    "Attempt",
    "Shot",
    "Attempt",
    "Plunge in",
    "Knife wound",
    "Attempt",
    "Attempt",
    "Attempt",
    "Poke holes in",
    "Pierce",
    "Attempt",
    "Attempt",
    "Shot",
    "Harpoon, e.g.",
    "Pierce",
    "Bayonet",
    "Attempt",
    "Try",
    "Attempt",
    "Wild guess",
    "Pierce with a fork",
    "Attempt",
    "Wild guess",
    "Wild guess",
    "See 47-Across",
    "Run through",
    "Feeling of pain",
    "Uneducated guess",
    "Poke holes in",
    "Bayonet",
    "Run through",
    "Stick",
    "Effort",
    "Attempt",
    "Knife",
    "Skewer",
    "Seize with a toothpick",
    "\"Shank,\" in prison lingo",
    "Use a knife or fork",
    "Try",
    "Lance",
    "Pin, in a way",
    "Puncture",
    "Wild guess",
    "Attempt",
    "Whack",
    "Pierce",
    "Try",
    "Knife",
    "Injure with a pitchfork",
    "Knife wound",
    "Attempt",
    "Pierce",
    "Take a___at",
    "Use a dagger",
    "Poke with a pike",
    "Try",
    "Knife",
    "Penetrate",
    "Knife",
    "Pierce"
  ],
  "opus": [
    "Impressive work",
    "\"Mr. Holland's ___,\" 1995 film for which Richard Dreyfuss received a Best Actor nomination",
    "Major piece",
    "Magnum ___",
    "Magnum ___ (greatest work)",
    "Magnum ___",
    "Magnum ___",
    "Numbered composition",
    "Numbered composition",
    "Great work",
    "Work with a number",
    "*Magnum ___",
    "Major work",
    "Overture, e.g.",
    "Work",
    "Symphony, e.g.",
    "Grand work",
    "Numbered musical work",
    "Score of a lifetime?",
    "Work",
    "___ Dei (Catholic group)",
    "Work",
    "Magnum ___",
    "Sonata, e.g.",
    "Great work",
    "Work with a number",
    "Great work",
    "Magnum ___",
    "Great work",
    "Big work",
    "Big score, maybe",
    "Magnum ___",
    "One on a Liszt list",
    "Musical number",
    "Musical work",
    "Piece of music",
    "Work ID",
    "Symphony, e.g.",
    "Numbered work",
    "Magnum ___",
    "Berkeley Breathed comic strip",
    "Work",
    "Symphony or sonata",
    "Concerto, e.g.",
    "Numbered work of a composer",
    "Numbered work",
    "Work",
    "Work",
    "Symphony, say",
    "Numbered musical work",
    "Big production",
    "Concerto, e.g.",
    "Major work",
    "Major work",
    "Sonata, e.g.",
    "Numbered work",
    "Numbered composition",
    "Beethoven's Ninth, e.g.",
    "Piece",
    "Any symphony",
    "Musical work",
    "Numbered work",
    "Composer's pride",
    "Numbered piece",
    "Work of art",
    "Composer's work",
    "Creative work",
    "Work",
    "Sonata, e.g.",
    "Numbered composition",
    "Work",
    "Musical composition",
    "Artist's work",
    "Symphony, e.g.",
    "Work",
    "Numbered work",
    "Book or symphony",
    "Magnum effort",
    "Composer's work",
    "Composition"
  ],
  "teri": [
    "Hatcher of Hollywood plots",
    "\"Spy Kids\" actress Hatcher",
    "Polo on TV",
    "Actress Polo",
    "Hatcher of \"Desperate Housewives\"",
    "Garr of \"Tootsie\"",
    "Actress Shields, mother of Brooke",
    "Hatcher who told Seinfeld \"And by the way, they're real ... and they're spectacular!\"",
    "Garr of \"Young Frankenstein\"",
    "Actress Hatcher",
    "Polo of TV's \"The Fosters\"",
    "Hatcher of \"Desperate Housewives\"",
    "\"Little Fockers\" actress Polo",
    "Actress Garr of \"Tootsie\"",
    "Actress Polo of TV's \"The Fosters\"",
    "Garr of \"Young Frankenstein\"",
    "Actress Polo",
    "Polo playing?",
    "Hatcher of \"Lois & Clark\"",
    "Hatcher of plots?",
    "Actress Garr",
    "Actress Hatcher of \"Desperate Housewives\"",
    "Actress Polo",
    "Garr of \"Tootsie\"",
    "Polo of \"The Fosters\"",
    "Polo on the small screen",
    "Actress Garr",
    "Actress Garr of \"Young Frankenstein\"",
    "Garr of \"Tootsie\"",
    "Actress Hatcher",
    "Hatcher on TV",
    "Actress Polo",
    "Actress Hatcher",
    "With 14-Across, \"Meet the Parents\" co-star",
    "Actress Hatcher",
    "Hatcher of \"Tomorrow Never Dies\"",
    "Actress Polo of \"Meet the Parents\"",
    "Actress Polo",
    "Girl's name that's a homophone of a cloth",
    "Garr of \"Tootsie\"",
    "Hatcher who co-starred in \"Tomorrow Never Dies\"",
    "Actress Hatcher",
    "Hatcher who was a Bond girl",
    "Hatcher of \"Desperate Housewives\"",
    "Polo of \"Meet the Parents\"",
    "Polo of \"Meet the Parents\"",
    "Polo of TV's \"The Fosters\"",
    "Actress Garr",
    "Hatcher of \"Desperate Housewives\"",
    "Hatcher who was a Bond girl",
    "___ Jon (fashion label)",
    "Oscar nominee Garr",
    "Hatcher or Polo",
    "Dean's \"Lois & Clark\" co-star",
    "Hatcher of Hollywood",
    "Hatcher who played Lois Lane",
    "Actress Hatcher",
    "Actress Garr",
    "Polo of \"Little Fockers\"",
    "Actress Polo",
    "Hatcher of a \"desperate\" plot?",
    "Garr who played 19-Down",
    "Actress Polo",
    "Actress Hatcher",
    "Actress Hatcher",
    "Actress Hatcher",
    "Actress Garr or Hatcher",
    "Garr of \"Tootsie\"",
    "Garr of \"Tootsie\"",
    "Hatcher with a Golden Globe",
    "Actress Hatcher",
    "Garr or Polo",
    "Actress Polo of \"Meet the Parents\"",
    "Garr or Hatcher",
    "Polo on TV",
    "Hatcher of \"Desperate Housewives\"",
    "Actress Polo",
    "\"Desperate Housewives\" co-star of Eva and Felicity",
    "Actress Polo who played a presidential candidate's wife on \"The West Wing\"",
    "___ Polo of \"Meet the Fockers\"",
    "Pop singer DeSario",
    "Garr of \"Tootsie\"",
    "Hatcher of \"Desperate Housewives\"",
    "Actress Polo",
    "Hatcher of \"Desperate Housewives\"",
    "Hatcher of film",
    "Hatcher of \"Lois & Clark\"",
    "Hatcher or Garr",
    "Hatcher of \"Lois & Clark\"",
    "Actress Garr of \"Mr. Mom\"",
    "Polo of \"Meet the Fockers\"",
    "Actress Hatcher",
    "Actress Polo",
    "Actress Polo",
    "Emmy-nominated Hatcher",
    "Actress Garr",
    "Actress Garr",
    "Austin of TV's \"Knots Landing\"",
    "Actress Polo of \"Meet the Fockers\"",
    "Actress Austin",
    "Dustin's \"Tootsie\" co-star",
    "Polo of \"Meet the Fokkers\"",
    "Actress Garr",
    "Actress Polo",
    "Actress Garr",
    "Actress Hatcher",
    "Hatcher of \"Desperate Housewives\"",
    "Actress Hatcher",
    "Actress Hatcher",
    "Actress Garr",
    "Austin of \"Knots Landing\"",
    "Actress Hatcher",
    "Garr of film",
    "Actress Garr",
    "Actress Hatcher",
    "Hollywood?Æs Hatcher",
    "Hatcher of \"Lois & Clark\"",
    "Actress Polo",
    "Hatcher of \"Lois & Clark\"",
    "Actress Garr",
    "Polo of \"Meet the Parents\"",
    "Actress Austin of \"Knots Landing\"",
    "Garr of \"Young Frankenstein\"",
    "Actress Garr",
    "Hatcher of \"Lois & Clark\"",
    "Actress Garr",
    "Actress Polo",
    "Garr of \"Young Frankenstein\"",
    "Actress Austin",
    "Garr of \"Tootsie\"",
    "Actress Hatcher",
    "Actress Polo of \"Meet the Parents\"",
    "Actress Hatcher",
    "Actress Hatcher",
    "Actress Garr",
    "TV's Hatcher",
    "Actress Hatcher",
    "1980's-90's actress Copley",
    "Hatcher of \"Lois & Clark\"",
    "Garr of \"Mr. Mom\"",
    "Garr of \"Mr. Mom\"",
    "She played Lois on \"Lois & Clark\"",
    "Hatcher of \"Lois & Clark\"",
    "Austin of \"Knots Landing\"",
    "Garr of \"Mr. Mom\"",
    "\"Mystery Date\" actress ___ Polo",
    "Hatcher of \"Lois & Clark\"",
    "Hatcher of \"Lois & Clark\"",
    "Garr of \"Tootsie\"",
    "Actress Hatcher",
    "Ms. Garr of \"Mr. Mom\"",
    "Michael's \"Mr. Mom\" co-star",
    "Actress Garr",
    "Actress Garr",
    "Garr of \"Close Encounters\"",
    "Garr of \"Mr. Mom\"",
    "Garr of \"Mr. Mom\"",
    "Garr with a \"Tootsie\" role",
    "Actress Hatcher",
    "Garr of \"Mr. Mom\"",
    "Hatcher of \"Lois & Clark\"",
    "With 12-Down, a \"Tootsie\" co-star",
    "She had a \"Tootsie\" role",
    "Gene's \"Young Frankenstein\" co-star",
    "Austin of \"Knots Landing\"",
    "Actress Copley",
    "TV's Hatcher",
    "Actress Garr",
    "Actress Hatcher",
    "Actress Hatcher",
    "She plays Lois on \"Lois & Clark\"",
    "\"Tootsie\" co-star for Dustin",
    "Actress Austin",
    "Actress Hatcher",
    "Hatcher of \"Lois & Clark\"",
    "Actress Garr",
    "Actress Copley",
    "Actress Copley",
    "Garr of \"Tootsie\"",
    "Hatcher of \"Lois & Clark\"",
    "Hatcher of \"Lois and Clark\"",
    "Actress Garr",
    "Actress Garr",
    "Actress Hatcher",
    "Actress Garr",
    "Actress Garr",
    "Garr of \"Tootsie\"",
    "Actress Garr",
    "Actress Garr",
    "Garr of \"Tootsie\""
  ],
  "mred": [
    "Mane character of classic TV?",
    "TV character who said \"Time to hit the hay ... oh, I forgot, I ate it!\"",
    "Talking horse of a '60s sitcom",
    "Talking horse of old TV",
    "Wilbur's partner in an old sitcom",
    "Talking horse of '60s TV",
    "TV character who says \"You know I wouldn't talk in front of anyone but you\"",
    "Talking horse of 1960s TV",
    "Talking TV palomino",
    "Sitcom equine of the '60s",
    "Stable character of old TV",
    "TV star who loved oats",
    "Surprising conversationalist of classic TV",
    "Old show horse",
    "Stable role on TV?",
    "Show horse",
    "He \"will never speak unless he has something to say,\" in a song",
    "Black-and-white horse?",
    "He'll \"talk 'til his voice is hoarse\"",
    "TV character who \"will never speak unless he has something to say\"",
    "TV neigh-sayer?",
    "TV star who homered off Koufax in a 1963 episode",
    "He talked only to Wilbur",
    "Who'll \"talk 'til his voice is hoarse,\" in a 1960s sitcom",
    "TV palomino",
    "Talking horse of '60s TV",
    "Talking horse of old TV",
    "TV star who said \"Stop gabbin' and get me some oats!\"",
    "Wilbur Post's \"pal\"",
    "Four-footed TV star",
    "TV role for Bamboo Harvester",
    "Talking equine of '60s TV",
    "Noted member of a Hollywood stable",
    "Wilbur Post's horse",
    "TV palomino",
    "Equine quipster",
    "Bamboo Harvester ?ôplayed?ö him",
    "TV neigh-sayer?",
    "Horse in a 60's sitcom",
    "Equine star",
    "Subject of the lyric \"A horse is a horse, of course, of course\"",
    "He conversed with Wilbur",
    "He talked to Wilbur Post",
    "Wilbur's horse",
    "Wilbur's charge in 60's TV",
    "1960's TV horse",
    "Early 60's TV listing",
    "Four-footed TV star",
    "He was more than a neigh-sayer",
    "Nick at Nite rerun",
    "First-of-a-kind 1960's TV star",
    "TV's talking horse",
    "Rocky Lane spoke for him",
    "Rocky Lane spoke for him",
    "Vocal nag",
    "He once had stable work on TV",
    "60's TV horse",
    "Wilbur Post's TV companion",
    "1960's TV lead",
    "The Posts' TV companion",
    "Equine on the tube",
    "Hoarse horse?",
    "Nonsense comedy of 60's TV",
    "Onetime prime-time equine",
    "Speaking role for Rocky Lane",
    "TV palomino",
    "He talked horse sense",
    "Horse that made sense?",
    "TV palomino",
    "See 25-Across"
  ],
  "axle": [
    "It's in heavy rotation on the highway",
    "Part of a drivetrain",
    "One of several on a tractor-trailer",
    "Turning point",
    "What a wheel connects to",
    "Connector of a pair of wheels",
    "It keeps the wheels turning",
    "Spinning part of a car",
    "One of three on a semi",
    "Toll unit",
    "It gets the wheels turning",
    "One of a pair on a car",
    "Automobile rod",
    "What car wheels turn on",
    "Wagon part",
    "One of several on a big rig",
    "Rotating car part",
    "Greased auto part",
    "Weigh station unit",
    "Hot rod's rod",
    "One making a lot of turns on the road?",
    "What a wheel rotates on",
    "One spinning its wheels?",
    "Drivetrain part",
    "___ grease",
    "Wheel connector",
    "Wheel turner",
    "Low bar",
    "Linchpin locale",
    "What a drivetrain connects to",
    "Turning point",
    "Revolutionary device?",
    "Wheelset piece",
    "Wheel turner",
    "Wheels' connector",
    "Turning point",
    "Running gear component",
    "It spins its wheels",
    "Highway toll unit",
    "Wheel turner",
    "Part of a wheelset",
    "Car bar",
    "Revolutionary car part?",
    "Hot rod rod",
    "Auto shaft",
    "Highway toll unit",
    "Device for rotating one's tires?",
    "Hot rod's rod",
    "Auto shaft",
    "Car bar",
    "Part of a gyroscope",
    "Auto shaft",
    "Wheel turner",
    "Trucker's toll unit",
    "Toll unit",
    "Wheel holder",
    "Supporting shaft",
    "Toll unit on a toll road",
    "Cotter pin's place",
    "Roll bar?",
    "Toll unit",
    "Cart part",
    "Wheel shaft",
    "Car rod",
    "Wheel turner",
    "Toll unit, sometimes",
    "Mandrel",
    "It may spin its wheels",
    "Linchpin's place",
    "Wagon part",
    "Rod in a hot rod",
    "Car bar",
    "Linchpin site",
    "Wheel connector",
    "Wheel shaft",
    "Grease gun's target",
    "Differential attachment",
    "Linchpin's place",
    "Linchpin's place",
    "Bridge toll unit",
    "Wheel turner",
    "Wagon part",
    "Toll unit",
    "Rod for a hot rod",
    "Bar on a car",
    "Wheel rotator",
    "Car bar",
    "Wheel rod",
    "Wheel support",
    "Bridge toll unit",
    "Car bar",
    "Car bar",
    "Bridge toll unit",
    "Spindle",
    "Semi support",
    "Car part",
    "Car bar",
    "Car part",
    "Auto part",
    "Oiler's target",
    "Car bar",
    "Turning point",
    "Wheel shaft",
    "Auto part"
  ],
  "alee": [
    "On the sheltered side, nautically",
    "Away from the wind, nautically",
    "Sheltered at sea",
    "Away from the wind, at sea",
    "On the safe side",
    "Downwind, at sea",
    "Safe, on board",
    "On the safe side",
    "Protected, in a way",
    "Protected, at sea",
    "Downwind",
    "Direction on a ship",
    "Sheltered, at sea",
    "Downwind, at sea",
    "Nautical adverb",
    "Opposite of aweather, to a sailor",
    "Protected on a boat",
    "Out of the wind, at sea",
    "Sheltered, at sea",
    "One way to turn a ship",
    "Downwind",
    "Sheltered from the wind",
    "Sheltered at sea",
    "Direction of a ship",
    "\"Ready about! Hard ___!\"",
    "Sheltered at sea",
    "Downwind",
    "Away from the wind",
    "Toward shelter, at sea",
    "Sheltered at sea",
    "Away from the wind",
    "Away from the wind",
    "Nautically protected",
    "Protected, at 58-Down",
    "Sheltered at sea",
    "Sheltered from the wind",
    "Protected, in a way",
    "One way to turn a vessel",
    "Sailor's heading",
    "Not aweather",
    "\"Helm's ___!\" (nautical cry)",
    "How a ship's sails may be positioned",
    "\"Helm ___!\" (captain's cry)",
    "Sheltered at sea",
    "Sheltered",
    "Protected from squalls",
    "Boat direction",
    "One way to turn a ship",
    "Captain's direction",
    "Out of the wind",
    "\"Hard ___!\" (nautical command)",
    "Away from the wind",
    "Toward safety",
    "Sheltered, at sea",
    "Sheltered",
    "Away from the wind",
    "Toward shelter, nautically",
    "On the calm side",
    "On the safe side",
    "On the sheltered side",
    "Nautical direction",
    "How a ship might turn",
    "Out of harm's way, in a way",
    "Sheltered, at sea",
    "Sheltered, at sea",
    "One way to direct a helm",
    "On the 31-Across side",
    "Sheltered",
    "On the safe side, at sea",
    "Sheltered at sea",
    "Direction at sea",
    "Direction at sea",
    "Away from the storm",
    "On the safe side",
    "How the helm might be put",
    "Not windward",
    "Sailor's direction",
    "On the sheltered side",
    "Direction at sea",
    "\"Hard ___!\"",
    "Toward the quiet side",
    "Way to turn a ship",
    "On the safe side",
    "Sheltered from the wind",
    "\"Hard ___!\" (captain's order)",
    "Out of the wind",
    "Away from the wind",
    "Away from the wind",
    "Away from the wind",
    "\"Hard ___!\" (captain's order)",
    "Sheltered",
    "Salt's direction",
    "One way to turn a ship",
    "Not in the wind",
    "Opposite of windward",
    "Out of harm's way, in a way",
    "How a ship may be turned",
    "On the safe side, at sea",
    "Protected from the wind",
    "Downwind, to a sailor",
    "Away from the storm",
    "Away from the wind",
    "On the safe side",
    "Out of harm's way",
    "Away from the wind",
    "On the less windy side",
    "How a ship may be turned",
    "Protected, in a way",
    "Sheltered",
    "\"Hard ___!\" (sailor's yell)",
    "Toward shelter",
    "On the safe side",
    "Out of the wind",
    "\"Hard ___\" (ship command)",
    "Skipper's direction",
    "Downwind, on a ship",
    "On the calm side",
    "Sheltered, at sea",
    "Downwind",
    "Away from the wind",
    "Way to direct a helm",
    "One way to turn",
    "Toward one side of a ship",
    "Away from the wind",
    "\"Hard ___!\" (nautical order)",
    "Protected, at sea",
    "Helm position",
    "Toward shelter, nautically",
    "Nautical heading",
    "Direction at sea",
    "Protected",
    "Protected",
    "Away from the wind",
    "Sheltered, nautically",
    "On the safe side",
    "Aweather's opposite",
    "Away from the wind",
    "On the quiet side?",
    "Toward shelter",
    "Off the wind",
    "Salt's direction",
    "Avoiding the draft?",
    "Out of the wind, at sea",
    "Toward shelter",
    "Toward the sheltered side",
    "Sheltered, at sea",
    "Out of the wind",
    "\"Hard ___!\" (nautical cry)",
    "Nautical direction",
    "\"Hard ___!\" (helm command)",
    "Opposite of windward",
    "Away from the wind",
    "One way to turn at sea",
    "Away from the wind",
    "Away from the wind",
    "Away from the wind",
    "On the sheltered side",
    "On the quiet side",
    "Main course?",
    "Helm heading",
    "Sheltered, at sea",
    "How some ships turn",
    "Away from the wind",
    "Downwind, at sea",
    "Nautical adverb",
    "Not aweather",
    "Away from the wind",
    "Sheltered",
    "Not aweather",
    "One way for a ship to turn",
    "Out of the wind",
    "Not windward",
    "Away from the wind",
    "One way to orient a boat",
    "Sheltered, at sea",
    "\"Hard ___!\" (ship command)",
    "Sheltered",
    "Position at sea",
    "Sheltered",
    "Away from the wind",
    "Out of the wind, nautically",
    "Sheltered, in a way",
    "Sheltered, in a way",
    "Hard ___",
    "On the safe side?",
    "Downwind, nautically",
    "Aweather's opposite",
    "Not aweather",
    "Toward the windless side",
    "Sheltered, at sea",
    "Toward shelter",
    "Safe, on board",
    "Sheltered, at sea",
    "Away from the wind",
    "Sheltered",
    "Sheltered",
    "On the safe side",
    "Out of the wind",
    "On the safe side?",
    "Nautical direction",
    "Out of the wind",
    "Safe, on board",
    "Away from the storm",
    "Toward shelter",
    "Nautical adverb",
    "Toward shelter",
    "Wheelhouse direction",
    "On the sheltered side",
    "Toward one side of a ship",
    "On the quiet side?",
    "On the safe side, at sea",
    "Toward shelter, nautically",
    "Direction at sea",
    "Sheltered, nautically",
    "Downwind",
    "Not into the wind",
    "Nautical direction",
    "Nautical heading",
    "Downwind",
    "Like one side of a ship",
    "Sheltered from the wind",
    "Downwind",
    "On the safe side?",
    "Nautical direction",
    "Toward shelter",
    "Downwind",
    "Opposite of a weather",
    "Toward shelter",
    "Opposite of windward",
    "Not aweather",
    "Toward shelter",
    "Out of the wind, on windjammers",
    "On the captain's good side?",
    "Toward shelter, to a sailor",
    "Toward shelter",
    "Toward shelter",
    "Sheltered",
    "Nautical heading",
    "Captain's heading",
    "Sheltered, nautically",
    "Nautical direction",
    "Opposite of aweather",
    "Nautical heading",
    "Sheltered, nautically",
    "Sheltered",
    "Downwind",
    "Away from the weather",
    "Toward shelter",
    "Toward shelter",
    "Toward shelter",
    "Toward shelter",
    "Nautical heading",
    "Away from the wind",
    "Ship's direction",
    "Out of the weather",
    "Windward's opposite",
    "Sheltered",
    "Not aweather",
    "Downwind, nautically",
    "Out of the wind",
    "Out of the wind",
    "Sailor's direction",
    "On the safe side",
    "Nautical direction",
    "Away from harm's way",
    "Sheltered, at sea"
  ],
  "magi": [
    "Christmas trio",
    "Wise guys?",
    "Travelers to Bethlehem, in Matthew",
    "\"We Three Kings\" subjects",
    "Trio traveling to Bethlehem",
    "Christmas gift bearers",
    "Some camel riders of note",
    "Trio in a Christmas carol",
    "O. Henry's \"The Gift of the ___\"",
    "New Testament trio",
    "Star followers",
    "Stable figures?",
    "Storied gift bearers",
    "Storied traveling trio",
    "Birthday visitors?",
    "Caspar, Melchior and Balthazar, e.g.",
    "Figures in a creche",
    "Star trek figures?",
    "O. Henry's \"The Gift of the ___\"",
    "Parts of a Nativity scene",
    "Star followers",
    "New Testament gift bearers",
    "Holiday travelers?",
    "Gifted trio?",
    "Visitors to baby Jesus",
    "Trio on camels",
    "Wise guys?",
    "Nativity scene figures",
    "Star followers",
    "Christmas party",
    "Star men?",
    "Three Wise Men",
    "Biblical gift bearers",
    "Biblical wise men",
    "Star followers",
    "Bearers of gold, frankincense and myrrh",
    "Visitors to the manger",
    "Creche trio",
    "Travelers to Bethlehem",
    "Nativity scene figures",
    "Trio in a Christmas story",
    "Creche figures",
    "Visitors to baby Jesus",
    "Nativity trio",
    "Ancient gift givers",
    "Biblical stargazers",
    "Manger visitors",
    "Gift givers",
    "Star followers",
    "Gift bearers",
    "Biblical gift-givers",
    "Creche figures",
    "Visitors to Jesus",
    "Star followers",
    "Star followers",
    "Yule trio",
    "Adoring trio",
    "O. Henry's \"The Gift of the ___\"",
    "Biblical gift bearers",
    "Creche trio",
    "They navigated by a star",
    "Searchers for a baby",
    "New Testament group",
    "Adoring ones",
    "Epiphany figures",
    "Creche trio",
    "Travelers to Bethlehem",
    "Star trekkers?",
    "Star trek voyagers?",
    "Travelers in Matthew",
    "O. Henry's \"The Gift of the ___\"",
    "Manger visitors",
    "Traveling trio",
    "Wise guys",
    "Amahl's visitors",
    "Eastern trio",
    "Early stargazers?",
    "Trio in Bethlehem",
    "Epiphany figures",
    "Travelers to Bethlehem",
    "Star witnesses?",
    "O. Henry's \"The Gift of the ___\"",
    "\"The Gift of the ___\"",
    "Trio visiting Jesus",
    "Manger trio",
    "New Testament trio",
    "Epiphany honorees",
    "Christmas travelers?",
    "Creche figures",
    "Biblical trio"
  ],
  "then": [
    "\"... and ___ some\"",
    "\"And so ...\"",
    "At that point",
    "In those days",
    "Storyteller's transition",
    "After that",
    "Now's partner",
    "Next",
    "\"That was ___ ...\"",
    "Now and ___",
    "Segue word",
    "Segue starter",
    "Not now",
    "___ and 13-Across (on the spot)",
    "First of two before-and-after pictures",
    "\"That was ___ ...\"",
    "\"All righty ___!\"",
    "\"That was ___ ...\"",
    "In the past",
    "Following",
    "Next",
    "\"And so?\"",
    "After that",
    "Not now",
    "Next",
    "Consequently",
    "So",
    "Consequently",
    "\"And so?\"",
    "Next",
    "Not now",
    "Start of a conclusion",
    "Fats Waller's \"___ I'll Be Tired of You\"",
    "See 53-Across",
    "Ergo",
    "In that case",
    "In that case",
    "In that case",
    "Accordingly",
    "That time",
    "\"___ again ...\"",
    "Word following clue 114-Across",
    "Not now",
    "And so",
    "Story connector",
    "Next",
    "Next",
    "Now's opposite",
    "Storyteller's link",
    "Following that",
    "Transitional word",
    "Next",
    "Next",
    "\"What happened next...\"",
    "Next",
    "Bygone era",
    "Subsequently",
    "Not now",
    "Not now",
    "Now's companion",
    "At that point",
    "\"___He Kissed Me\" (1963 hit)"
  ],
  "bash": [
    "Shindig",
    "Wild party",
    "Shindig",
    "Blowout",
    "Blowout",
    "Talk trash about",
    "Big party",
    "Big party",
    "Strongly disparage",
    "Criticize in no uncertain terms",
    "Verbally attack",
    "Shindig",
    "Helluva party",
    "More than dis",
    "Party that might get out of hand",
    "Big shindig",
    "Big party",
    "Big party",
    "Blowout",
    "Big blowout",
    "Big do",
    "Hit hard",
    "Party to remember",
    "Blowout",
    "Bad-mouth",
    "Big party",
    "Wingding",
    "Big do",
    "Wild party",
    "Big party",
    "Big blowout",
    "Shindig",
    "Criticize harshly",
    "Swell party",
    "Quite a party",
    "Big party",
    "Sock",
    "Big party",
    "Blowout",
    "Blowout",
    "Big blowout",
    "All-nighter, maybe",
    "Big party",
    "Quite a party",
    "Shindig",
    "Wingding",
    "Wingding",
    "Gala",
    "Blowout",
    "Big party",
    "Big do",
    "Bust-up",
    "Big party",
    "Do",
    "Blowout"
  ],
  "loaf": [
    "Something with two heels",
    "Loll",
    "It's more than a pocketful of rye",
    "Not do much of anything",
    "Bread purchase",
    "Idle",
    "Idle",
    "See 52-Down",
    "Idle",
    "Loll",
    "Don't work too hard",
    "Bread unit",
    "Avoid work",
    "Dog it",
    "Dog it",
    "Eschew exertion",
    "Just watch TV, say",
    "Goldbrick",
    "Dog it",
    "Hang out",
    "Take it easy",
    "Hang around",
    "Be idle",
    "Sit around",
    "Baker's unit",
    "Goof off",
    "Fail to work",
    "Just watch TV",
    "Lollygag",
    "Veg out",
    "Take it easy",
    "Kill time",
    "Bakery item",
    "Bread in the oven",
    "Kick back",
    "Lounge",
    "Goldbrick",
    "It has two heels",
    "Vegetate",
    "Lounge",
    "Goof off",
    "Idle",
    "Idle",
    "Noggin, to Brits",
    "Bakery product"
  ],
  "dice": [
    "D&D equipment",
    "Where the idiom \"on a roll\" comes from",
    "Equipment in Monopoly and Yahtzee",
    "Five of these are needed to play Yahtzee",
    "\"Bones\"",
    "Danglers from rear-view mirrors",
    "Things blown on for luck",
    "They may be loaded in a casino",
    "\"God does not play ___ with the world\": Einstein",
    "Things spotted in a casino",
    "They're blown on for good luck",
    "Chop up",
    "Cube ... or certain cubes",
    "Make mincemeat of, say",
    "High roller's pair",
    "They're rolled in craps",
    "Backgammon needs",
    "Shooter's pair",
    "Casino stock",
    "Yahtzee equipment",
    "Gambling aids",
    "Cubes in a casino",
    "Chop up",
    "Their faces have spots",
    "Equipment in craps",
    "Backgammon pair",
    "What high rollers roll",
    "Shooter's equipment",
    "What high rollers roll",
    "Parcheesi pair",
    "Cut to bits",
    "Equipment in chuck-a-luck",
    "Casino pair",
    "Cubes at Harrah's",
    "\"No ___!\"",
    "Gambling aids",
    "Decorations on some rearview mirrors",
    "They're spotted in casinos",
    "Chop up",
    "Casino needs",
    "Game equipment",
    "Backgammon equipment",
    "Backgammon equipment",
    "Cut up",
    "Some game equipment",
    "They show their faces in casinos",
    "Some are loaded",
    "Blocks in Atlantic City",
    "Chop up",
    "Need for some games",
    "They're spotted in casinos",
    "\"No ___!\"",
    "Monopoly equipment",
    "Vegas rollers",
    "Bones",
    "Cut into cubes",
    "\"Devil's teeth\"",
    "Reno cubes",
    "Backgammon equipment",
    "Rearview mirror decoration",
    "Ivory items of old",
    "Chuck-a-luck equipment",
    "Rolling stock?",
    "Bones",
    "Gambler's \"bones\"",
    "Chuck-a-luck equipment",
    "Yahtzee equipment"
  ],
  "urdu": [
    "Language from which \"bandana\" comes",
    "Language of the 18th-century poet Mir Taqi Mir",
    "Native language of 50 million citizens of India",
    "Language of Pakistan",
    "Language of Pakistan",
    "Pakistani language",
    "Language of Pakistan",
    "Language of the Lahore Post",
    "Language that gave us \"punch\"",
    "Language that gave us \"cummerbund\"",
    "Language that gave us \"khaki\"",
    "Official language of Pakistan",
    "Pakistani language",
    "Language of Pakistan",
    "Language of Pakistan",
    "Language of Pakistan",
    "Language from which \"cummerbund\" comes",
    "Language in Lahore",
    "Hindi relative",
    "Language of Lahore",
    "Language whose alphabet reads from right to left",
    "Language of Pakistan",
    "Pakistani tongue",
    "It's written from right to left",
    "Hindi relative",
    "Language that's written from right to left",
    "Language of Pakistan",
    "Language of Islamabad",
    "Language in Lahore",
    "Language from which \"loot\" comes",
    "Language of Pakistan",
    "Official language of Pakistan",
    "It's written right to left",
    "Language derived from Hindustani",
    "Asian tongue",
    "Language of Lahore",
    "Language of the Hindustan Express",
    "Pakistani tongue",
    "Language whose name means \"army\"",
    "Language written with Persian-Arabic letters",
    "Language in which \"Pakistan\" means \"land of the pure\"",
    "Karachi tongue",
    "Pakistani tongue",
    "Asian tongue",
    "Pakistani language",
    "It's heard in Islamabad",
    "Language of Pakistan",
    "South Asians speak it",
    "Pakistani tongue",
    "Language of Lahore",
    "Indic language",
    "Language of Pakistan",
    "Hindi relative",
    "Pakistani language",
    "Literary variant of Hindustani",
    "Language written in Persian-Arabic letters",
    "Official language of Pakistan",
    "Language in Lahore",
    "Karachi language",
    "Official language of Pakistan",
    "Relative of Hindustani"
  ],
  "cast": [
    "Put in a movie role",
    "Group in a playbill",
    "Players in a play",
    "People are asked to sign it after an accident",
    "Wear after an accident",
    "Group of actors",
    "Tossed",
    "Group in a play",
    "Closing credits heading",
    "Play mates?",
    "Names in film credits",
    "Put in play?",
    "Playbill listing",
    "Movie lot?",
    "Playbill listing",
    "Threw",
    "List in movie credits",
    "Thrown",
    "Museum dinosaur skeleton, say",
    "Throw",
    "Throw, as a shadow",
    "Strewn",
    "Playbill listing",
    "Crew's colleagues",
    "Players in a play",
    "Play group",
    "One may be original",
    "Playbill listing",
    "Project, as a 14-Across",
    "Group of actors",
    "Playbill listing",
    "See 37-Down",
    "Group of thousands, maybe",
    "Players in a play",
    "Playbill info",
    "It goes on a break",
    "Thousands may be in it",
    "Put in a part",
    "Play group",
    "Place to sign",
    "Throw, as dice",
    "Start fishing",
    "Play group?",
    "Throw",
    "Hue",
    "Result of a big break?",
    "Start angling",
    "Play group",
    "Players in a play",
    "Calculate astrologically",
    "Part of a film's credits",
    "Fracture fixer",
    "Play group",
    "Playbill list",
    "Setter of a sort",
    "Players",
    "Shed",
    "Playbill listing",
    "Calculate astrologically",
    "Players",
    "Ski resort legwear?",
    "It's made of plaster of paris",
    "Ones going through a stage?",
    "Players",
    "Set group",
    "Toss (out)",
    "Group with roles to play",
    "Ones making a scene",
    "Assign to a role",
    "Players",
    "Skiing memento, perhaps",
    "Start fishing"
  ],
  "egan": [
    "Jennifer who wrote \"A Visit From the Goon Squad\"",
    "Pulitzer-winning author Jennifer",
    "Pulitzer-winning novelist Jennifer",
    "Jennifer who wrote the Pulitzer-winning \"A Visit From the Goon Squad\"",
    "Jennifer who wrote \"Manhattan Beach\"",
    "Jennifer who wrote the Pulitzer-winning \"A Visit From the Goon Squad\"",
    "Pulitzer-winning novelist Jennifer",
    "Edward ___, longtime archbishop of New York",
    "Pulitzer-winning novelist Jennifer",
    "Eddie ___, subject of \"The French Connection\"",
    "Op-ed columnist Timothy",
    "Former New York archbishop",
    "Richard of \"A Summer Place\"",
    "Pulitzer-winning novelist Jennifer",
    "Former New York archbishop",
    "Richard of \"Love Me Tender\"",
    "\"Diaspora\" author Greg",
    "Cardinal from New York",
    "Susan of Broadway's \"Beauty and the Beast\"",
    "Eddie who inspired \"The French Connection\"",
    "Former New York cardinal Edward",
    "New York cardinal",
    "New York cardinal",
    "Science fiction author Greg",
    "Richard of \"The 300 Spartans\"",
    "Richard of old westerns and action films",
    "New York cardinal",
    "New York cardinal",
    "New York cardinal",
    "New York City archbishop",
    "\"A Summer Place\" co-star",
    "Richard of \"A Summer Place\"",
    "Alaska's first governor",
    "Star of \"A Summer Place,\" 1959",
    "New York cardinal",
    "Richard of \"Pollyanna\"",
    "New York archbishop Edward",
    "Cardinal O'Connor's successor",
    "\"Magnet and Steel\" singer Walter",
    "Big Apple cardinal",
    "Alaska's first governor William A. ___",
    "Edward ___, cardinal of New York",
    "New York archbishop Edward",
    "New York archbishop Edward",
    "O'Connor's successor as archbishop of New York",
    "Cardinal O'Connor's successor",
    "\"Life in London\" author Pierce ___",
    "\"Sleepy Time Gal\" songwriter Raymond",
    "\"Sleepy Time Gal\" lyricist Raymond",
    "William ___, Alaska's first 72-Down",
    "Famous New York cop Eddie",
    "Actor Richard",
    "First governor of Alaska",
    "First governor of Alaska",
    "Raymond who wrote \"Till We Meet Again\"",
    "Alaska's first governor",
    "Richard of \"A Summer Place\"",
    "Richard of \"A Summer Place\"",
    "Alaska's first governor",
    "Alaska's first governor",
    "Actor Richard",
    "Actor Eddie",
    "Western star Richard",
    "Actor Richard",
    "Author of \"Life in London\"",
    "\"A Summer Place\" star"
  ],
  "alba": [
    "Actress Jessica",
    "Spain's Duchess of ___",
    "Duchess of ___ (Goya model)",
    "Actress Jessica",
    "Jessica of \"L.A.'s Finest\"",
    "Jessica of \"Fantastic Four\"",
    "Actress Jessica",
    "Duchess of ___ (Goya subject)",
    "Gaelic name for Scotland",
    "Duchess of ___ (Goya subject)",
    "Jessica of \"Fantastic Four\"",
    "\"Dark Angel\" star Jessica",
    "Actress Jessica",
    "Jessica of \"Sin City\"",
    "Duchess of ___ (Goya subject)",
    "Jessica of \"Sin City\"",
    "Jessica of \"Fantastic Four\"",
    "Jessica of filmdom's \"Fantastic Four\"",
    "Actress Jessica",
    "Duchess of ___ (Goya subject)",
    "Jessica of \"Dark Angel\"",
    "Goya's \"Duchess of ___\"",
    "Actress Jessica",
    "Jessica of \"Sin City\"",
    "Jessica of \"Good Luck Chuck\"",
    "Co-star of 2005's \"Fantastic Four\"",
    "Jessica of \"Dark Angel\"",
    "Jessica of \"Sin City\"",
    "\"Sin City\" actress",
    "Jessica of \"Valentine's Day\"",
    "Duchess of ___ (Goya subject)",
    "Goya's \"The Duchess of ___\"",
    "Troubadour's love song",
    "Duke of ___ (noble Spanish title since 1472)",
    "Jessica of \"Fantastic Four\"",
    "Scottish Gaelic name for Scotland",
    "___ Longa, ancient city founded by the son of Aeneas",
    "Francisco Goya's \"Duchess of ___\"",
    "Terra ___ (pulverized gypsum)",
    "Jessica of \"Good Luck Chuck\"",
    "\"Valentine's Day\" co-star, 2010",
    "___ Longa, where Romulus and Remus were born",
    "Jessica of \"Fantastic Four\"",
    "\"Dark Angel\" star Jessica",
    "Jessica of \"Fantastic Four\"",
    "Jessica of \"Fantastic Four\" films",
    "Actress Jessica",
    "\"Sin City\" actress, 2005",
    "Duchess of ___, Goya subject",
    "Whence Goya's duchess",
    "___ Longa, birthplace of Romulus and Remus",
    "\"Duchess of ___\" (Goya work)",
    "Goya's \"Duchess of ___\"",
    "Jessica of \"Dark Angel\"",
    "Jessica of \"Dark Angel\"",
    "\"Duchess of ___,\" Goya painting",
    "\"Dark Angel\" star Jessica",
    "Duchess of ___ (Goya model)",
    "Goya's \"Duchess of ___\"",
    "Jessica of \"Dark Angel\"",
    "Duke of ___ (Philip II's adviser)",
    "Goya's duchess",
    "Dawn, to a don",
    "Goya's \"The Duchess of ___\"",
    "Troubadour's dawn song",
    "Spanish general Duke of ___",
    "___ Longa (ancient city)",
    "Terra___ (powdery substance)",
    "White matter of the brain"
  ],
  "seed": [
    "Germ",
    "Pip",
    "___ money",
    "Genesis",
    "Avocado pit, for one",
    "Number shown in brackets?",
    "Provide initial funding for",
    "Bracket position",
    "Bird feeder bit",
    "Start of an idea",
    "Lentil, e.g.",
    "Avocado pit, e.g.",
    "Watermelon part that's spit out",
    "Growing need",
    "Discarded part of a watermelon",
    "Farmer's purchase",
    "Farmer's purchase",
    "Number in brackets?",
    "Quinoa, e.g.",
    "Acorn, essentially",
    "No. 1 ___ (tournament favorite)",
    "___ money",
    "Something a strawberry has on the outside",
    "One put in bed?",
    "Gardener's spring purchase",
    "Peach pit or walnut",
    "Bracket position",
    "Lentil or coconut",
    "Sow",
    "Edible part of a sunflower",
    "Tournament front-runner",
    "Nut, basically",
    "Germ",
    "Tidbit for a bird",
    "Assigned position",
    "Pit",
    "Progeny",
    "Favorable tournament position",
    "Kernel",
    "Ranked player",
    "Start for a plant",
    "Starting point",
    "Start of something",
    "Progeny",
    "Basis of growth",
    "Origin",
    "Start of something",
    "Bit of watermelon waste",
    "Start",
    "Basis of development",
    "Symbol of regeneration",
    "Bye holder",
    "Origin",
    "Feeder filler",
    "Starting point",
    "Future shoot",
    "Bracket position",
    "Some bird feed",
    "___ money (start-up's need)",
    "Start of something big",
    "Germ",
    "___ money",
    "Induce rain from",
    "Pit",
    "Start of something big",
    "Something to go to",
    "Plant's start",
    "Tournament favorite",
    "Give a special berth",
    "Pip or pit",
    "Gardener's bagful",
    "Gardener's spring purchase",
    "Burpee unit",
    "Open position?",
    "Progeny",
    "Progeny",
    "Origin",
    "Start a garden",
    "Plant-to-be",
    "Core component",
    "Tournament draw",
    "Plant of the future",
    "Kind of money",
    "Gardener's spring purchase",
    "Lentil, e.g.",
    "Nut, basically",
    "Garden store purchase",
    "Kind of pearl",
    "Origin",
    "Very beginning",
    "Spring purchase",
    "Basis",
    "Germ",
    "Sow",
    "Burpee's bit",
    "Tournament V.I.P.",
    "Tournament ranking",
    "Offspring",
    "Acorn, e.g.",
    "Sow",
    "Tournament hotshot",
    "Farmer's purchase",
    "Spring purchases",
    "Garden starter",
    "Gardener's item",
    "Pit",
    "Kind of money",
    "Issue",
    "Parakeet treat",
    "Burpee bit",
    "Bird feeder fill"
  ],
  "amid": [
    "Surrounded by",
    "Surrounded by",
    "\"Go placidly ___ the noise and the haste ...\" (start of Max Ehrmann's \"Desiderata\")",
    "Surrounded by",
    "During",
    "During",
    "In the thick of",
    "In the thick of",
    "In the thick of",
    "In the thick of",
    "In amongst",
    "In the thick of",
    "Surrounded by",
    "In the thick of",
    "In",
    "During",
    "In",
    "Within",
    "Surrounded by",
    "Inside",
    "Surrounded by",
    "At a time of",
    "In the center of",
    "In the thick of",
    "In",
    "In",
    "In",
    "In the thick of",
    "In",
    "In the hub of",
    "Surrounded by",
    "In a crowd of",
    "Surrounded by",
    "Surrounded by",
    "In the thick of",
    "In",
    "In the thick of",
    "In the thick of",
    "In the thick of",
    "During",
    "Surrounded by",
    "Surrounded by",
    "In",
    "In the thick of",
    "Surrounded by",
    "In among",
    "In",
    "In the thick of",
    "Within",
    "Within",
    "In the thick of",
    "In",
    "In the center of",
    "In",
    "Surrounded by",
    "In the thick of",
    "In the thick of",
    "In",
    "In",
    "In the heart of",
    "In",
    "In the thick of",
    "Surrounded by",
    "In the thick of",
    "During",
    "In the thick of",
    "In the thick of",
    "Surrounded by",
    "In the thick of",
    "At the hub of",
    "In the thick of",
    "In the thick of",
    "In the thick of",
    "During",
    "Surrounded by",
    "Surrounded by",
    "In the thick of",
    "In the thick of",
    "In the thick of",
    "During",
    "In a group of",
    "During",
    "In the thick of",
    "During",
    "Surrounded by",
    "Surrounded by",
    "In",
    "During",
    "In the thick of",
    "During",
    "Within",
    "In the center of",
    "Surrounded by",
    "Surrounded by",
    "In the center of",
    "During",
    "During",
    "In the center of",
    "In the center of",
    "In the center of",
    "During",
    "Among",
    "In the thick of"
  ],
  "arte": [
    "Works in the Galleria degli Uffizi",
    "Works of Picasso y Goya",
    "Museo contents",
    "Hangings in la Galleria degli Uffizi",
    "It might be abstracto",
    "Contents of el Prado",
    "Galleria display",
    "Uffizi Gallery holding",
    "Museo contents",
    "Works of Dali, e.g.",
    "Commedia dell'___",
    "Musica o danza",
    "Museo contents",
    "Johnson of comedy",
    "Uffizi display",
    "Works of Goya, e.g.",
    "Prado works",
    "Danza, e.g.",
    "Johnson of \"Laugh-In\"",
    "Man's name that sounds like two letters of the alphabet",
    "Funny Johnson",
    "El Prado works",
    "Johnson who said \"Ver-r-r-ry interesting!\"",
    "Johnson of \"Laugh-In\"",
    "Musica, e.g.",
    "Funny Johnson",
    "Johnson of \"Laugh-In\"",
    "Commedia dell'___",
    "Scultura, per esempio",
    "El Prado hangings",
    "Uffizi display",
    "Michelangelo work",
    "Goya's field",
    "Comical Johnson",
    "\"Guernica,\" e.g.",
    "Prado displays",
    "\"Laugh-In\" comedian Johnson",
    "\"The Gong Show\" regular Johnson",
    "Museo contents",
    "Johnson of \"Laugh-In\"",
    "Works in the Uffizi",
    "Comic Johnson",
    "105-Down output",
    "Commedia dell'___",
    "Commedia dell'___",
    "Uffizi Gallery hanging",
    "Comic Johnson",
    "Spanish paintings",
    "Baseball team owner Moreno",
    "Commedia dell'___",
    "Comic Johnson",
    "Work of Michelangelo, e.g.",
    "Johnson of \"Laugh-In\"",
    "Bologna oils",
    "Output of Tintoretto, e.g.",
    "Johnson of \"Laugh-In\"",
    "Works of Michelangelo",
    "Commedia dell'___",
    "Uffizi display",
    "Commedia dell'___",
    "Museo holdings",
    "\"Vissi d'___\"",
    "Commedia dell'___",
    "Johnson who said \"Ver-r-r-y interesting!\"",
    "\"Vissi d'___,\" \"Tosca\" song",
    "Works of Michelangelo",
    "Giotto's work",
    "Johnson of \"Laugh-In\"",
    "Johnson of \"Laugh-In\"",
    "Museo holdings",
    "Johnson of TV's \"Laugh-In\"",
    "Pieta, e.g.",
    "Paolo Veronese's field",
    "Uffizi Gallery hanging",
    "Museo contents",
    "Works in la galeria",
    "Italian busts, e.g.",
    "Florentine painting, e.g.",
    "Sicilian skill",
    "Commedia dell'___",
    "What Michelangelo produced",
    "Museo works",
    "Commedia dell'___",
    "El Prado work",
    "Museo work",
    "Dali output",
    "Villa Borghese display",
    "Museo offering",
    "Funny Johnson",
    "Spanish museum work",
    "Works in the Uffizi Palace",
    "61-Across medium",
    "Johnson of \"Laugh-In\"",
    "\"The ___ of English Poesie\"",
    "\"De ___ Poetica\"",
    "Commedia dell'___",
    "Commedia dell'___",
    "Galeria de ___",
    "Johnson of \"Laugh-In\"",
    "Johnson of \"Laugh-In\"",
    "Museo holdings",
    "Commedia dell'___",
    "Museo displays",
    "Commedia dell'___",
    "Works in the Uffizi",
    "Comic Johnson",
    "Commedia dell'___",
    "\"Vissi d'___,\" from \"Tosca\"",
    "Michelangelo's work",
    "Johnson of \"Laugh-In\"",
    "Uffizi display",
    "Commedia dell'___",
    "Museo offering",
    "Commedia dell'___",
    "Puccini's \"Vissi d'___\"",
    "Uffizi attraction",
    "\"Vissi d'___\"",
    "Ruth's \"Laugh-In\" foil",
    "\"De ___ Poetica\" (ancient treatise)",
    "Puccini's \"Vissi d'___\"",
    "\"Laugh-In\" name",
    "Johnson of \"Laugh-In\"",
    "Jokester Johnson",
    "\"De___ Poetica\"",
    "\"Vissi d'___\" (Puccini aria)",
    "Comic Johnson",
    "\"Vissi d'___\" (Puccini aria)",
    "\"Verrry interesting\" Johnson",
    "Jovial Johnson",
    "Commedia dell'___",
    "\"Laugh-in\" comic",
    "Commedia dell'___",
    "Ruth's \"Laugh-In\" tormentor",
    "\"Verrrrrry interesting\" Johnson",
    "Comic Johnson",
    "Jocular Johnson",
    "Comic Johnson",
    "Jocular Johnson",
    "Commedia dell'___",
    "Comic Johnson",
    "Johnson of \"Laugh-In\""
  ],
  "elba": [
    "Actor Idris with an O.B.E.",
    "Idris of TV's \"Luther\"",
    "Old isle of exile",
    "Place of banishment for Napoleon",
    "Island to which one is able to return?",
    "Site of Napoleon's exile",
    "Island off the Tuscan coast",
    "Idris of \"The Dark Tower\"",
    "Actor Idris",
    "English actor Idris",
    "Idris of \"The Dark Tower\"",
    "Italy's third-largest island, after Sicily and Sardinia",
    "Idris ___, People's 2018 Sexiest Man Alive",
    "English actor Idris",
    "Ex-isle of exile",
    "\"Able was I ere I saw ___\"",
    "Historic isle in the Tyrrhenian Sea",
    "First place where Napoleon was exiled",
    "Italy's Isola d'___",
    "Actor Idris",
    "Mandela player in a 2013 biopic",
    "Third-largest island in Italy, after Sicily and Sardinia",
    "Isle of exile",
    "Island due south of Livorno",
    "Italian site of Napoleon's exile",
    "Part of the former Republic of Pisa",
    "Isle of exile",
    "Italian tourist destination in the Mediterranean",
    "Italian exile island",
    "Exile isle for Napoleon",
    "Isle of exile",
    "Island off the coast of Tuscany",
    "Site of an 1815 escape",
    "Island whose name is another word in this puzzle spelled backward",
    "Island of exile",
    "Part of the Tuscan Archipelago",
    "Island in the Tyrrhenian Sea",
    "Island off the coast of Tuscany",
    "\"Able was I ere I saw ___\"",
    "Isle of exile",
    "Destination for a ferry from Livorno",
    "Hundred Days campaign planning site",
    "Italian isle",
    "Island in the Arcipelago Toscano",
    "Island off Tuscany",
    "Where Napoleon planned his Hundred Days campaign",
    "Isle of exile",
    "Napoleon's home before his Hundred Days campaign",
    "Site of a notable exile",
    "Napoleon's place, once",
    "Isle of exile",
    "Noted exile site",
    "Island of Napoleon's exile",
    "Isle of exile for Napoleon",
    "Isle of exile",
    "Site of Napoleon's exile",
    "Exile isle",
    "Portoferraio is its chief town",
    "Island off the Tuscan coast",
    "Noted exile isle",
    "It's off the NE coast of Corsica",
    "Site of Napoleon's exile",
    "Isle where Napoleon was exiled",
    "Portoferraio's island",
    "Italian island",
    "Island near Corsica",
    "Famous isola",
    "Alabama county seat named for a European island",
    "Island near Corsica",
    "Portoferraio's island",
    "1814-15 principality for Napoleon",
    "Island of Napoleon's exile",
    "Exile isle for Napoleon",
    "Napoleon was banished to it",
    "Isle of exile",
    "Napoleon exile site",
    "Exile isle",
    "Site of a dramatic 1815 escape",
    "Italy's Isola d'___",
    "Exile isle",
    "Island in the Tyrrhenian Sea",
    "Napoleon exile site",
    "Portoferraio's island",
    "Isle of exile",
    "Exile site of 1814",
    "Island north of Montecristo",
    "Portoferraio's place",
    "Exile isle",
    "Exile island for Napoleon",
    "Island north of Montecristo",
    "Exile site for Napoleon",
    "Site of Napoleon's first exile",
    "Isle of exile",
    "Isle of exile for Napoleon",
    "Napoleon's home, briefly",
    "Where Napoleon was solo",
    "Napoleon's empire of 1814-15",
    "Noted exile place",
    "Where Portoferraio is",
    "Isle of exile",
    "Isle of exile",
    "1814-15 exile site",
    "Island south of Livorno",
    "Site of Napoleonic exile",
    "Island east of Corsica",
    "Exile island",
    "Island NE of Corsica",
    "Exile site",
    "Island prison of history"
  ],
  "skid": [
    "Lose traction",
    "Not handle ice well, say",
    "Lose control on the road",
    "Lose control on ice, say",
    "Suffer from a lockup",
    "Result of driving on ice, perhaps",
    "Lose traction on the road",
    "___ row",
    "Fall rapidly, as sales",
    "___ marks",
    "It may leave marks",
    "Freight platform",
    "What one might attach to a vehicle after a snowstorm",
    "Lose traction",
    "Lose traction",
    "Lose traction",
    "Kind of mark",
    "Losing streak",
    "A screech may accompany it",
    "Possible result of slamming on the brakes",
    "Start of many an accident",
    "Slip",
    "It may leave its mark",
    "Lose control on the highway",
    "Lose traction",
    "Cargo platform",
    "It leaves marks on asphalt",
    "Kind of mark",
    "Fail to stop on a dime?",
    "It may leave its mark",
    "Cargo platform",
    "___ row",
    "Lose traction",
    "Start of a car accident",
    "Winter fender-bender cause",
    "Veer out of control",
    "Lose control of a car",
    "Slide in sleet",
    "Slide sideways",
    "Have trouble on the ice",
    "Have brake problems",
    "Lose control on the road"
  ],
  "olds": [
    "Alero or Aurora, informally",
    "Parents and grandparents, in slang, with \"the\"",
    "Bygone G.M. product, informally",
    "Mother and father, slangily, with \"the\"",
    "Ford contemporary",
    "Part of REO",
    "G.M. car no longer sold new",
    "Rival of Ford and Packard",
    "Geezers, with \"the\"",
    "Assembly line pioneer",
    "Delta 88, e.g., informally",
    "88 or 98",
    "Early automotive pioneer",
    "Ford contemporary",
    "Ma and pa, with \"the\"",
    "Early automaker Ransom E. ___",
    "Cutlass or 88, informally",
    "Bygone G.M. car, appropriately enough",
    "Auto pioneer Ransom E. ___",
    "88 or 98 of autodom",
    "88, e.g., familiarly",
    "Defunct G.M. division",
    "Onetime Toronado, e.g., informally",
    "Delta 88, e.g., informally",
    "Detroit pioneer",
    "Intrigue, once, informally",
    "88 or 98, carwise",
    "Cutlass or Delta 88",
    "Cutlass or Super 88 of bygone autodom",
    "Automaker Ransom Eli",
    "Bygone Starfire, e.g., informally",
    "Discontinued G.M. car",
    "Cutlass or 88",
    "Former G.M. make",
    "Pioneer automaker",
    "98, e.g.",
    "Super 88, of the 1950s and '60s",
    "Automotive pioneer",
    "Delta 88, e.g.",
    "Aurora producer",
    "Cutlass or 88, in the auto world",
    "___ 88",
    "Bygone G.M. make",
    "Cutlass, e.g., informally",
    "Automotive innovator",
    "Early automaker",
    "Cutlass maker",
    "G.M. car",
    "Futuramic of the late 40's and early 50's",
    "Cutlass maker",
    "Starfire of 60's autodom",
    "GM line",
    "Cutlass or Delta 88",
    "Ninety Eight maker",
    "Delta 88, e.g.",
    "Toronado or Starfire",
    "Cutlass or Delta 88",
    "First assembly-line carmaker",
    "The \"o\" in Reo",
    "Ford contemporary",
    "Auto pioneer",
    "An 88, e.g.",
    "Cutlass maker, briefly",
    "Cutlass, e.g.",
    "General Motors line, for short",
    "Pioneer automaker",
    "Cutlass or Eighty Eight",
    "Eighty Eight, for one",
    "Reo maker",
    "98, in the car world",
    "Cutlass or 98, for short",
    "Reo maker",
    "Cutlass, e.g.",
    "Automobile pioneer",
    "Auto pioneer",
    "Automotive pioneer",
    "The \"O\" in R.E.O.",
    "Auto maker Ransom E. ___",
    "Auto pioneer Ransom",
    "Chevy rival",
    "Ransom, the car maker",
    "Certain G.M. car",
    "Cutlass, e.g., informally",
    "An 88",
    "Cutlass Supreme, e.g.",
    "Auto pioneer",
    "Early auto maker",
    "Ford contemporary"
  ],
  "utah": [
    "Six-sided state",
    "Salt Lake City's home",
    "Home of the Starzz, one of the original eight W.N.B.A. teams",
    "Jazz hangout?",
    "Home of the Anasazi State Park Museum",
    "Its name is said to mean \"people of the mountains\"",
    "Home to part of the Wasatch Mountains",
    "Home to Natural Bridges National Monument",
    "\"The Greatest Snow on Earth\" sloganeer",
    "Sundance state",
    "Home to the Sundance Film Festival",
    "Its license plates say \"Life Elevated\"",
    "Home of Bears Ears National Monument",
    "Geographical entity with six straight sides",
    "Four Corners state",
    "Home of the Jazz",
    "Geographical hexagon",
    "Pac-12 school that's not really near the Pacific",
    "Home to Zion National Park",
    "What separates Nevada from Colorado",
    "Home of the historic Desolation Canyon",
    "N.B.A. Western Conference team",
    "Home of Zion National Park",
    "Home of Weber State University",
    "Hexagonal state",
    "Where I-15 meets I-70",
    "Pac-12 team",
    "Home to Weber State University",
    "Zion National Park's state",
    "Steered, today",
    "Setting for the beginning of \"The Book of Mormon\"",
    "State that voted Republican by the highest percentage (73%) in the 2012 presidential election",
    "Salt flats location",
    "See 59-Down",
    "Pac-12 school",
    "Home of Bountiful",
    "Deseret, today",
    "One of the 12 in the Pac-12",
    "One of its symbols is the sego lily",
    "45th state",
    "See 33-Across",
    "Flaming Gorge locale",
    "Jazz quintet's home",
    "Home of Sunset and Paradise",
    "Salt lake state",
    "Home of Kings Peak",
    "Zion's site",
    "Great Salt Lake state",
    "See 45-Across",
    "Land of Zion?",
    "Great Salt Lake site",
    "Hexagon on a map",
    "Crossroads of the West",
    "Jazz setting",
    "With 84-Down, a Pac-12 team",
    "Hexagonal state",
    "4-Down locale",
    "Salt flats locale",
    "Home of the Sundance Film Festival",
    "Salt Lake City's state",
    "\"Big Love\" setting",
    "Home of the city Bountiful",
    "___ Beach (D-Day site)",
    "\"Big Love\" setting",
    "Home of the Bonneville Salt Flats",
    "Area code 801 area",
    "Home of Arches National Park",
    "Home of Rainbow Bridge National Monument",
    "One of the Four Corners states",
    "Home of Rainbow Bridge National Monument",
    "Six-sided state",
    "The California gull is the state bird of which state?",
    "Where the 2002 Winter Olympics were held",
    "Union member since 1896",
    "Logan's locale",
    "Home of Zion National Park",
    "Dinosaur National Monument locale",
    "Mormon State",
    "Great Salt Lake's state",
    "Vessel lost at Pearl Harbor",
    "Great Salt Lake's state",
    "Locale of 1869's Golden Spike",
    "Six-sided state",
    "Bryce Canyon locale",
    "Great Salt Lake state",
    "Jazz quintet's home",
    "Kings Peak locale",
    "Where I-15 and I-70 cross",
    "Jazz venue",
    "The 45th 7-Across",
    "One of the Four Corners states",
    "Delta Center N.B.A. team",
    "Great Salt Lake state",
    "Great Salt Lake's state",
    "Six-sided state",
    "Home of the Rainbow Bridge National Monument",
    "Slickrock Trail locale",
    "It has six sides",
    "The Beehive State",
    "Jazz home",
    "2002 Winter Olympics locale",
    "West Jordan locale",
    "\"___, We Love Thee\" (song)",
    "The Beehive State",
    "Delta Center team",
    "The Beehive State",
    "Where the first transcontinental railroad was completed",
    "The Beehive State",
    "Home of the Jazz",
    "Salt Lake City's home",
    "2002 Winter Olympics locale",
    "D-Day beachhead",
    "Its motto is \"Industry\"",
    "Ship sunk at Pearl Harbor",
    "2002 Olympics locale",
    "Bryce Canyon locale",
    "Where Logan is",
    "Union joiner of 1896",
    "Salt lake state",
    "It has six sides",
    "Home of Bryce Canyon",
    "Area code 801 area",
    "Jazz home",
    "Zion National Park home",
    "Great Salt Lake locale",
    "Bonneville Flats locale",
    "Area code 801 locale",
    "Six-sided state",
    "Four Corners state",
    "D-Day beach",
    "Rocky Mountain state",
    "Golden Spike locale",
    "Logan's home",
    "Its motto is \"Industry\"",
    "Team for which Darrell Griffith was Rookie of the Year",
    "Home to Bryce Canyon",
    "Rainbow Bridge locale",
    "Four Corners toucher",
    "Bryce Canyon locale",
    "Area code 801 area",
    "45th of 50",
    "Jazz home",
    "N.B.A. team since 1979",
    "Kings Peak locale",
    "State of the union",
    "Orrin Hatch's state",
    "One of the Four Corners states",
    "Its motto is \"Industry\"",
    "Neighbor of 27-Down",
    "The 45th of 50",
    "Ship sunk at Pearl Harbor",
    "Four Corners state",
    "Young legacy?",
    "Beehive State",
    "Four Corners state",
    "Bryce Canyon locale",
    "Its motto is \"Industry\"",
    "Jazz home",
    "1945 Roy Rogers-Dale Evans western",
    "Bryce Canyon locale",
    "Mormon state",
    "Great Salt Lake site",
    "Canyonlands locale",
    "Wyoming neighbor",
    "Where Provo is",
    "One of the corners at Four Corners Monument",
    "Jazz players are found here",
    "Delta Center team",
    "Home of Brigham Young",
    "Where Navajo Mountain is",
    "Sundance Film Festival locale",
    "Salt Lake's home",
    "Dixie National Forest site",
    "Karl Malone's team",
    "Zion National Park locale",
    "Brigham Young's destination",
    "Mormon stronghold",
    "Canyonlands National Park site",
    "Area code 801",
    "Ogden's locale",
    "Provo's state",
    "Mormon refuge",
    "1941 Pearl Harbor ship",
    "Arches National Park locale",
    "Where Bountiful is",
    "Bryce Canyon site",
    "Brigham Young's home",
    "Deseret, now",
    "Bonneville Flats site",
    "Beehive State",
    "Flaming Gorge Reservoir site",
    "Great Salt Lake state",
    "Mormons' home",
    "Pearl Harbor ship of 1941",
    "Bountiful's state",
    "Wasatch Range state",
    "Karl Malone's team",
    "Brigham Young's home",
    "I-70's western terminus",
    "Jazz locale"
  ],
  "ntwt": [
    "Abbr. on a wrapper",
    "Cereal box abbr.",
    "Food pkg. info",
    "Wrapper abbr.",
    "Amt. often measured in ozs.",
    "Packaging abbr.",
    "Abbr. on a food package",
    "Abbr. on a cereal box",
    "Container abbr.",
    "Abbr. on a food package",
    "Food label abbr.",
    "Abbr. on a food container",
    "Food label abbr.",
    "Cereal box stat.",
    "Packing info: Abbr.",
    "Food package amt.",
    "Pkg. no.",
    "Packing info: Abbr.",
    "Product package abbr.",
    "Product package abbr.",
    "Abbr. on a food label"
  ],
  "ring": [
    "Part of a wedding ceremony",
    "Good thing to have on hand at a wedding?",
    "Championship memento",
    "Item found in \"The Hobbit\"",
    "Boxing venue",
    "Purchase before popping the question",
    "Phone",
    "What Gollum calls \"my precious\"",
    "Boxing venue",
    "Age indicator in a tree trunk",
    "Surround",
    "What the best man holds for the groom",
    "Part of the Olympics logo",
    "Band at a wedding",
    "Best man's charge",
    "Pre-engagement purchase",
    "Prewedding purchase",
    "Call up",
    "Kneeler's offering",
    "It changes hands at an altar",
    "Wedding band",
    "Nuptial need",
    "Evidence of some growth",
    "Circus or wedding staple",
    "Telephone",
    "Token that you're taken",
    "World Series prize",
    "Telephone sound",
    "Engagement gift",
    "Saturn feature",
    "Cabal",
    "Symbol of troth",
    "Conspiratorial clique",
    "Sumo venue",
    "Arboreal age indicator",
    "Symbol of constancy",
    "Encircle",
    "Union symbol",
    "Foreman's locale?",
    "Boxing locale",
    "Eclipse sight",
    "Water level indicator",
    "Evidence that one's taken?",
    "Symbol of devotion",
    "O",
    "Best man's charge",
    "One year in a trunk",
    "One year in a trunk",
    "Engagement token",
    "Collar stain",
    "There's no end to it",
    "Bathtub detritus",
    "Echo",
    "Loop",
    "Prize of the Nibelung",
    "Engagement gift",
    "Cabal",
    "Proposer's offering",
    "Wagnerian cycle",
    "Boxing milieu",
    "Wagner cycle",
    "Symbol of one's troth",
    "Wedding sine qua non",
    "Cabal"
  ],
  "hoes": [
    "Digs up, in a way",
    "Tools for tilling",
    "Garden store equipment",
    "Digs up some dirt",
    "Weeds, say",
    "Prepares a bed?",
    "Gets into the weeds?",
    "Gardening tools",
    "Dirt movers",
    "Uncovers dirt, in a way",
    "Cultivation aids",
    "Weeding tools",
    "Tools for weeding",
    "Tilling tools",
    "Turns over in one's plot?",
    "Plot devices?",
    "They might break up a plot",
    "Things that turn up in gardens?",
    "Tills, in a way",
    "Groundbreaking inventions?",
    "Tends a garden",
    "Row makers",
    "Gets rid of weeds",
    "Makes a row in a garden, say",
    "Garden store stock",
    "Weed whackers",
    "Tilling tools",
    "Ground-breakers?",
    "Shed things",
    "Caretakers' equipment",
    "Works in the garden",
    "They produce a row on the farm",
    "Works in the garden",
    "Garden tools",
    "Attacks weeds",
    "Works the garden",
    "Breaks up clods"
  ],
  "java": [
    "Language that may be used in 56-Down",
    "Language introduced in 1995",
    "Coffee, informally",
    "Jitter juice",
    "Mud",
    "Christmas Island's closest neighbor",
    "Morning joe",
    "The \"joe\" of a cup of joe",
    "Jakarta's island",
    "Software platform suitable for Starbucks?",
    "Web app platform",
    "World's most populous island",
    "Programming language that's also the name of an island",
    "Joe",
    "Island where Sundanese and Madurese are spoken",
    "Coffee, slangily",
    "Coffee, slangily",
    "Joe",
    "Joe",
    "Diner cuppa",
    "Web-oriented programming language",
    "Coffee, slangily",
    "Site of a famed fossil find",
    "Mud",
    "Morning cupful, slangily",
    "Coffee, slangily",
    "Coffee, slangily",
    "Site of a noted fossil find",
    "Neighbor of Sumatra",
    "Where Jakarta is",
    "Coffee, slangily",
    "Coffee, in a beanery",
    "Morning eyeopener",
    "Black brew",
    "Cup at a diner",
    "\"Joe\"",
    "Coffee",
    "Cawfee",
    "Coffee, slangily",
    "Jakarta's island",
    "Hirt hit",
    "Coffee, in slang",
    "Joe",
    "Coffee, slangily",
    "Instrumental hit of 1964",
    "AI Hirt hit",
    "Coffee, informally"
  ],
  "unix": [
    "See 55-Down",
    "Operating system developed at Bell Labs",
    "Pioneering computer operating system",
    "Operating system from Bell Labs",
    "Early operating system",
    "Pioneering computer operating system",
    "Operating system developed at Bell Labs",
    "Certain operating system",
    "Operating system since the early '70s",
    "Operating system developed at Bell Labs",
    "Linux forerunner",
    "System used by servers",
    "Operating system with many clones",
    "System developed by Bell Labs",
    "Pioneering computer operating system",
    "Operating system developed at Bell Labs",
    "Operating system used since the 1970s",
    "Bell Labs operating system",
    "Bell Labs system",
    "It has \"batch\" and \"patch\" commands",
    "Longtime computer operating system",
    "Operating system since 1969",
    "MS-DOS alternative",
    "Operating system since 1969",
    "Computer operating system developed at Bell Labs",
    "Operating system developed at Bell Labs",
    "Windows alternative",
    "Popular computer operating system",
    "Popular computer operating system",
    "Alternative to Windows",
    "System for servers",
    "Windows alternative",
    "Operating system on many Internet servers"
  ],
  "ltrs": [
    "Much mail: Abbr.",
    "Contents of some envelopes: Abbr.",
    "Some paperwork: Abbr.",
    "Mail: Abbr.",
    "Posted pieces: Abbr.",
    "U.S.P.S. deliveries",
    "Contents of many files: Abbr.",
    "U.S.P.S. deliveries",
    "What P.O.'s handle",
    "Posts: Abbr.",
    "P.O. items",
    "P.O. items",
    "P.O. items",
    "Mail: Abbr.",
    "U.S.P.S. cargo: Abbr.",
    "P.O. boxful",
    "G.P.O. items",
    "P.O. items"
  ],
  "anal": [
    "Seriously uptight",
    "Overly fussy, informally",
    "Overly fussy, say",
    "Compulsively particular, say",
    "Fussy to excess",
    "Fastidious to a fault",
    "Highly persnickety",
    "Obsessive about details",
    "Super-uptight",
    "Obsessive to a fault",
    "Seriously uptight",
    "Beyond stuffy",
    "Overly fussy",
    "Fussy to a fault",
    "Hypermeticulous",
    "Like a control freak",
    "Annoyingly focused",
    "Overly fussy",
    "Way too uptight",
    "39-Across, informally",
    "Meticulous to a fault",
    "Like a neat freak",
    "___-retentive",
    "Rigidly uptight",
    "Like a control freak",
    "Excessively focused on something",
    "Like neatniks and clean freaks",
    "___-retentive",
    "Uptight, informally",
    "Uptight, informally",
    "Obsessive, say",
    "Fastidious to a fault",
    "Like a control freak",
    "Uptight, informally",
    "Chem. assay",
    "___-retentive",
    "Excessively orderly, informally",
    "___-retentive",
    "Psychological work: Abbr."
  ],
  "qtrs": [
    "Periods in the N.B.A.: Abbr.",
    "N.F.L. divs.",
    "N.F.L. periods: Abbr.",
    "N.F.L. game divs.",
    "Game periods: Abbr.",
    "Game divs.",
    "Bowl parts: Abbr."
  ],
  "nasa": [
    "Org. exploring Mars",
    "Org. that flew a helicopter on Mars in 2021",
    "Org. known for counting backward",
    "Org. with missions to Mars",
    "Org. with programs that are taking off?",
    "Org. with Perseverance",
    "\"Hidden Figures\" org.",
    "Org. with boosters",
    "Mission-driven org.",
    "It might send you to the moon",
    "Cape Canaveral org.",
    "Org. with skyrocketing expenses?",
    "Org. behind the New Horizons project",
    "Org. in charge of the 23-Down",
    "Mission-driven org.",
    "Launch party?",
    "Big employer in Huntsville, Ala.",
    "Org. behind the New Horizons probe",
    "Org. behind the InSight mission",
    "Org. prominent at Cape Canaveral",
    "Org. doing exploratory work",
    "Mariner's org.",
    "Team in Houston",
    "Grp. with a mission",
    "Moon unit?",
    "Org. involved in a probe",
    "Org. that hires many engineers",
    "Org. that operates the Jupiter orbiter",
    "Grp. that might have a launch party",
    "Org. featured in 16-Across",
    "Pluto flyby org.",
    "\"For the benefit of all\" org.",
    "Explorer's grp.",
    "Curiosity org.",
    "Org. in \"The Martian\"",
    "Curiosity rover launcher",
    "Probe-launching org.",
    "Apollo org.",
    "Shuttle program org.",
    "Cape Canaveral org.",
    "Grp. with a launch party?",
    "Cape Canaveral org.",
    "Booster org.",
    "Org. with lofty goals?",
    "Curiosity producer",
    "Initiator of a probe, maybe",
    "Launcher of the Curiosity rover",
    "Pioneer org.",
    "Cape Canaveral acronym",
    "2011 launcher of Curiosity",
    "Friendship org. of 1962",
    "Org. with launch parties?",
    "Opportunity creator",
    "Grp. organizing booster shots",
    "Curiosity's launcher",
    "Mariner's org.",
    "Launch party?",
    "Mariner's grp.",
    "Opportunity maker?",
    "Grp. that sends things up",
    "Cape Canaveral org.",
    "Org. in a big race of years past",
    "LEM maker",
    "Org. with the motto \"For the benefit of all\"",
    "Nimbus launcher of 1964",
    "Astronaut's letters",
    "Cong. established it in 1958",
    "Mission control org.",
    "Org. with the motto \"For the benefit of all\"",
    "Columbia org.",
    "Launcher launched in 1958",
    "Astronaut's insignia",
    "Grp. famous for its send-ups?",
    "Rocketeer's org.",
    "Satellite org.",
    "Shuttle org.",
    "Orbiting telescope launcher",
    "Pioneer's org.",
    "\"The Right Stuff\" subj.",
    "Rover's watcher",
    "World Wind developer",
    "Letters on Endeavour",
    "Ames Research Center org.",
    "Columbia org.",
    "Rocket launcher",
    "Shuttle-launching org.",
    "Payload delivery org.",
    "Pioneer org.",
    "Mission-to-Mars org.",
    "High-flier's org.?",
    "Astronaut's insignia",
    "G-suit acronym",
    "Opportunity creator",
    "Booster grp.?",
    "Voyager launcher",
    "Rover's owner",
    "Org. with a mission",
    "Pathfinder's parent",
    "Exploration org.",
    "Inits. on Mars",
    "Space shuttle launcher",
    "Explorers' org.",
    "Rocket scientist's employer",
    "Org. with far-out goals?",
    "Space capsule insignia",
    "Mission control grp.",
    "Employer of many scientists",
    "Shuttle launchers",
    "Mission Control org.",
    "Magellan's grp.",
    "Apollo's creator",
    "Houston-based org.",
    "Space shuttle org.",
    "Carpenter's employer, once",
    "Pad owner",
    "Launch agcy.",
    "Canaveral letters",
    "Org. with a mission",
    "Shuttle org.",
    "Astronaut's insignia",
    "Magellan's org.",
    "Rocket org.",
    "Atlas acronym",
    "Space race acronym",
    "Its work is done in stages",
    "Jet Propulsion Lab grp.",
    "Rocket launcher",
    "Shuttle scheduler",
    "\"Apollo 13\" subject",
    "Discovery grp.",
    "Mercury org.",
    "Discovery grp.",
    "Mars Pathfinder launcher",
    "Cape Canaveral grp.",
    "Org. for 7-Down",
    "Cape Canaveral org.",
    "\"The Right Stuff\" org.",
    "Agcy. since 1958",
    "Org. with a mission",
    "Cape Canaveral org.",
    "Cape Canaveral org.",
    "Apollo acronym",
    "Cape Canaveral acronym",
    "Discovery grp.",
    "Grp. behind Magellan",
    "Cape Canaveral org.",
    "Endeavour initials",
    "Org. with a mission",
    "Shuttle group"
  ],
  "apex": [
    "Highest point",
    "Tiptop",
    "Very top",
    "Zenith",
    "Pinnacle",
    "Highest point",
    "Culmination",
    "High point",
    "Peak",
    "Pinnacle",
    "Tip",
    "Top",
    "Highest point",
    "Highest point",
    "Tiptop",
    "Top",
    "High point",
    "Tiptop",
    "Tip",
    "Tippy-top",
    "High point",
    "Height",
    "Height",
    "Crow",
    "Height",
    "Top",
    "Zenith",
    "Extreme point",
    "Zenith",
    "There's nothing above it",
    "Climber's goal",
    "Nadir's opposite",
    "Tiptop",
    "Highest point",
    "It's all downhill from here",
    "Culmination",
    "Very top",
    "Pinnacle",
    "Acme",
    "Zenith",
    "Mountaineer's goal",
    "Pinnacle",
    "Peak",
    "Zenith",
    "Summit",
    "It's all downhill from here",
    "Peak",
    "Culminating point",
    "Peak",
    "Pinnacle",
    "Ne plus ultra",
    "Zenith",
    "Pinnacle",
    "Mountaineering goal",
    "Top",
    "Pinnacle",
    "Very top",
    "Summit",
    "Top spot",
    "Tiptop",
    "Mountaintop",
    "Pinnacle",
    "Height",
    "High spot",
    "Top spot",
    "Summit",
    "Summit",
    "Crown",
    "Summit",
    "Tiptop",
    "Pinnacle",
    "Zenith",
    "Top spot",
    "Pinnacle",
    "Pinnacle",
    "Pinnacle",
    "Top",
    "The top",
    "Tiptop",
    "Uppermost point",
    "Zenith",
    "Tiptop",
    "Top",
    "Summit",
    "High point"
  ],
  "gear": [
    "One of 10 on a ten-speed",
    "Bike ride setting",
    "One of 10 on a 10-speed",
    "Reverse, e.g.",
    "Rod, reel, tackle box, etc., for a fisher",
    "Prepare, with \"up\"",
    "Something to shift into or stow",
    "One of 10 in a ten-speed",
    "Backpack and its contents, e.g.",
    "What's packed in a backpack",
    "Boots, mask, etc., for a firefighter",
    "Backpack filler",
    "Manual selection",
    "Its teeth are pointy",
    "Tent, backpack, hiking shoes, etc.",
    "Paraphernalia",
    "Stuff",
    "Backpacker's pack",
    "1, 2, 3 or R",
    "Flippers, scuba tank, etc., for a diver",
    "Transmission setting",
    "Ski boots and such",
    "Stuff in a backpack",
    "Toothy turner",
    "Boots, backpack, tent, etc.",
    "It's got teeth",
    "Stuff stored in lockers",
    "Stick selection",
    "First, second or reverse",
    "Equipment",
    "Duds",
    "Reverse, e.g.",
    "Fishing rod, flies, lures, etc.",
    "See 32-Down",
    "Tent, sleeping bag, hiking shoes, etc.",
    "First, second or reverse",
    "Tour de France setting?",
    "Tent or sleeping bag, e.g.",
    "First or neutral",
    "Skis, boots, masks, etc.",
    "Backpack fill",
    "Bat, ball, glove, etc.",
    "Paraphernalia",
    "Reverse, e.g.",
    "Duds",
    "Unlike drive, reverse has just one",
    "Sports equipment",
    "The first one gets you going",
    "Drive, e.g.",
    "Stuff",
    "Rod and reel, e.g.",
    "Toothed part",
    "Stuff",
    "Fishing tackle and such",
    "Reverse, e.g.",
    "Cyclist's choice",
    "Stuff",
    "A mask, for a 29-Down",
    "Paraphernalia",
    "Stuff",
    "Stuff",
    "Paraphernalia",
    "PRNDL pick",
    "Sports stuff",
    "Tackle",
    "Fire hat, hose, ax, etc.",
    "Snorkel, fins, etc.",
    "Clothing",
    "Belongings",
    "First, second or reverse",
    "Driver's choice",
    "Camping stuff",
    "Paraphernalia",
    "Tackle",
    "Ropes, pads, etc.",
    "Engine part",
    "Rod, reel, tackle box, etc.",
    "Neutral or first",
    "Ropes, e.g.",
    "Duffel filler",
    "Tackle",
    "Transmission component",
    "Equipment",
    "Equipment",
    "Landing ___",
    "Tackle",
    "Trucker's choice",
    "Reverse, e.g.",
    "Tackle",
    "\"Things\"",
    "Duds",
    "Paraphernalia",
    "Trucker's concern",
    "First or reverse, e.g.",
    "Reverse, e.g.",
    "Tackle",
    "Duds",
    "Paraphernalia",
    "Tackle"
  ],
  "leif": [
    "Explorer Ericson",
    "First name of an early explorer of Vinland",
    "Explorer Ericson",
    "Eric's seafaring son",
    "Son of Eric the Red",
    "First name in New World exploration",
    "Explorer Ericson",
    "Viking Ericson",
    "Viking Ericson",
    "Mariner ___ Ericson",
    "First name in exploration",
    "Mariner Ericson",
    "Singer Garrett",
    "70's singer Garrett"
  ],
  "luis": [
    "San ___ Obispo, Calif.",
    "San ___ Obispo, Calif.",
    "All-Star pitcher Severino",
    "Yankees All-Star pitcher Severino",
    "San ___ Obispo, Calif.",
    "Argentine author Jorge ___ Borges",
    "San ___, Argentina",
    "San ___ Obispo, Calif.",
    "San ___ (Argentine province or its capital)",
    "___ Suarez, player suspended during the 2014 World Cup for biting another player",
    "First human character on \"Sesame Street\"",
    "San ___ Obispo, Calif.",
    "Baseball Hall-of-Famer Aparicio",
    "\"Los Olvidados\" director Bunuel",
    "Baseball All-Star Tiant",
    "Tiant in the Red Sox Hall of Fame",
    "See 51-Down",
    "Baseball Hall-of-Famer Aparicio",
    "San ___ Obispo, Calif.",
    "San ___ Obispo, Calif.",
    "Physics Nobelist Alvarez",
    "California's San ___ Dam",
    "San ___ Obispo, Calif.",
    "California's San ___ Dam",
    "San ___ Obispo, Calif.",
    "Author Jorge ___ Borges",
    "California's San ___ Dam",
    "Author Jorge ___ Borges",
    "Pitcher Tiant",
    "Director Bunuel",
    "San ___ Obispo, Calif.",
    "Film director Bunuel",
    "\"The Bridge of San ___ Rey\"",
    "San ___ Obispo",
    "___ de Velasco (University of Mexico founder)",
    "Pitcher Tiant",
    "Firpo of the ring"
  ],
  "ines": [
    "Mexican poet Juana ___ de la Cruz",
    "Chemical suffixes",
    "Woman's name that's also a plural suffix",
    "Fashion designer Di Santo",
    "Santa ___ (virgin martyr in the Italian Catholic Church)",
    "Wedding gown designer Di Santo",
    "Juana ___ de la Cruz, Mexican poet/nun",
    "Supermodel Sastre",
    "California's Mission Santa ___",
    "Chemical suffixes",
    "Dom Pedro's ill-fated wife",
    "California's Mission Santa ___",
    "Spanish conquistadora ___ de Suarez",
    "Supermodel Sastre",
    "Isabel Allende's \"___ of My Soul\"",
    "Chemical suffixes",
    "Juana ___ de la Cruz, Mexican nun and poet",
    "Mexican poet Juana ___ de la Cruz",
    "Supermodel Sastre",
    "Chemical endings",
    "Poet Sor Juana ___ de la Cruz",
    "___ de Castro (storied noblewoman)",
    "Chemical endings",
    "\"___ de Castro\" (John Clifford play)",
    "Chemical endings",
    "___ de Castro (Spanish noblewoman)"
  ],
  "leer": [
    "It's a bad look",
    "It's not a good look",
    "To read: Sp.",
    "Unwelcome look",
    "Bad look",
    "Unwelcome look",
    "Creepy stare",
    "Impolite look",
    "Creepazoid's gaze",
    "Sinister look",
    "Wolfish look",
    "Creepy look",
    "It might precede a pickup line",
    "Look the wrong way?",
    "Lewd look",
    "Salacious look",
    "Unwelcome look",
    "Satyr's stare",
    "Oblique look",
    "Sly look",
    "Evil look",
    "Give the stink eye",
    "Wolfish look",
    "Creepy look",
    "Be creepy, in a way",
    "Unwelcome look",
    "Villainous visage",
    "Look that's not liked",
    "Not just a side glance",
    "Lothario's look",
    "What womanizers do",
    "Creepy look",
    "Creepy look",
    "Look like a creep",
    "Desirous look",
    "A wolf may have one",
    "Wolf whistle accompanier, maybe",
    "Lounge lizard's look",
    "What rakes may do",
    "Lecher's look",
    "Uninviting look",
    "Not look so good?",
    "Lecher's look",
    "What a rake may do",
    "It might accompany a bar line",
    "Wanton look",
    "Look from a 31-Down",
    "Wanton gaze",
    "Womanizer's look",
    "Creepy look",
    "Lounge lizard's look",
    "Look like a wolf",
    "Satyric expression",
    "Lusty look",
    "Dirty look",
    "Prurient look",
    "Look lasciviously",
    "Unpleasant look",
    "Goat's look",
    "Sly glance",
    "Playboy's look",
    "Look at a Playboy Club?",
    "What a rake may do",
    "Look like a creep?",
    "Contemptuous expression",
    "Knowing look",
    "Grouchoesque look",
    "Immodest look",
    "Sideways glance",
    "Goat's look",
    "Lecherous look",
    "Lewd look",
    "Wolf's glance",
    "Wanton sneer",
    "What a rake may do",
    "Look of prurience",
    "Act the ogler",
    "Suggestive look",
    "Dirty look",
    "Odd look",
    "Goatish glance",
    "Lothario's look",
    "Wolf's look",
    "Rude look",
    "Odd look",
    "Sly glance",
    "Lecherous look",
    "Suggestive look",
    "Give a lewd look",
    "Look like a creep",
    "Unwanted look",
    "Look of lust",
    "Wanton look",
    "Look like a wolf",
    "Salacious look",
    "Wanton look",
    "Bad look",
    "48-Across's look",
    "Lecher's look",
    "Sideways look",
    "Prurient peek",
    "Look like a lecher",
    "Unwanted look",
    "Look of a wolf",
    "Look that may offend",
    "Groucho-type look",
    "Goggle (at)",
    "Sly look",
    "Salacious glance",
    "Lascivious look",
    "Sly look",
    "Wanton look",
    "Lascivious look",
    "Lascivious look",
    "Wanton look",
    "Satyr's stare",
    "Lustful look",
    "Playboy's gaze",
    "Groucho's expression",
    "Unpleasant look",
    "Goatish glance",
    "Lascivious look",
    "Look lasciviously",
    "Lecherous look",
    "Goatish glance",
    "Sinister look",
    "Smirk's cousin",
    "Lecherous look",
    "Groucho expression",
    "Unwanted look",
    "Lascivious look",
    "Look from Groucho",
    "Lascivious look",
    "Lech's look",
    "Once-over, maybe",
    "Creepy look",
    "Unwanted look",
    "Sideways look",
    "Lecher's look",
    "Sideways look",
    "Lascivious look",
    "Side glance",
    "Satyr's stare",
    "Wanton look",
    "Salacious look",
    "Satyric glance",
    "Amorous gaze",
    "Lascivious look",
    "Sidelong look",
    "Lecherous look"
  ],
  "emus": [
    "Relatives of ostriches",
    "Feathered Outback runners",
    "Bevy : quails :: mob : ___",
    "Australian avians",
    "Creatures whose males barely eat or drink during incubation",
    "Their eggs are incubated by males",
    "Images on Australia's 50-cent coins",
    "Layers of dark green eggs",
    "Birds that lay big green eggs",
    "Birds on some Australian coins",
    "Birds on Australian coins",
    "Tridactyl birds",
    "Sources of lean meat",
    "Outback flock",
    "Targets of a 1932 \"war\" in Australia",
    "Birds whose eggs are incubated by males",
    "Birds with deep drumming calls",
    "Pride : lions :: mob : ___",
    "Big birds",
    "Cousins of ostriches",
    "Outback runners",
    "Cousins of ostriches",
    "Birds with inflatable neck sacs",
    "Three-toed runners",
    "Down Under runners",
    "Grounded birds",
    "Birds appearing on Australia's 50 cent coins",
    "Relatives of ostriches",
    "Certain bird herd",
    "Dingo prey",
    "Winged runners",
    "Outback sprinters",
    "Exotic avian pets",
    "Green-egg layers",
    "Flightless flock",
    "Skinny-legged trotters",
    "Aussie runners",
    "Down Under birds",
    "Some farm stock",
    "Outback avians",
    "Birds that can sprint at 30 m.p.h.",
    "Layers of eggs weighing more than a pound",
    "Birds as big as people",
    "Avian sources of red meat",
    "They have big bills",
    "Ostrich cousins",
    "Outback birds",
    "Outback avians",
    "Birds with green eggs",
    "Outback runners",
    "Cousins of an ostrich",
    "Tall runners",
    "Birds with dark green eggs",
    "Down Under birds",
    "Pests to Australian ranchers",
    "They'll never fly",
    "Flightless flock",
    "Producers of green eggs",
    "Feathered runners",
    "Earthbound avians",
    "Outback residents",
    "Australian crop pests",
    "Big birds",
    "Cousins of kiwis",
    "Modern farm birds",
    "They won't get off the ground",
    "Cassowary cousins",
    "They just won't fly",
    "Relatives of ostriches",
    "Six-foot avians",
    "They'll never get off the ground",
    "Easily tamed birds",
    "Birds now raised on farms",
    "Grounded birds",
    "120-pound Australians",
    "Birds in herds",
    "Big birds",
    "Ostrich cousins",
    "Fancy feather sources",
    "Big birds",
    "Zoo section",
    "Rhea's cousins",
    "Cousins of the cassowary",
    "Zoo attractions",
    "Big birds",
    "Huge birds"
  ],
  "lode": [
    "Ore locale",
    "Rich source",
    "Mine find",
    "Rich vein of ore",
    "Prospector's find",
    "Vein of ore",
    "Miner's big find",
    "Ore source",
    "Rich supply of ore",
    "Rich store",
    "Big vein",
    "Mother ___",
    "Lucky strike?",
    "Rich supply",
    "Miner's strike",
    "Mother ___",
    "Mineral vein",
    "Gold digger's target",
    "Mother ___",
    "It may have gold in them thar hills",
    "Prospector's strike",
    "Ore deposit",
    "Vein glory?",
    "Big vein",
    "Rock band composition?",
    "Rich supply of ore",
    "Gold streak",
    "Prospector's strike",
    "Prospector's strike",
    "Prospector's strike",
    "Mine treasure",
    "Comstock's find",
    "Sourdough's strike",
    "Comstock ___",
    "Sourdough's dream",
    "Treasure-trove",
    "Prospector's discovery",
    "Comstock ___",
    "Valuable vein",
    "Prospector's find",
    "49-Across source",
    "Gold streak?",
    "Mother ___",
    "Ore store",
    "Treasure-trove",
    "Rich source",
    "Ore's locale",
    "Miner's strike",
    "Rich vein",
    "Gold digger's quest",
    "Rich supply",
    "Ore deposit",
    "Mother ___",
    "Glittering vein",
    "Miner's quest",
    "Bonanza",
    "Source",
    "It's in vein",
    "Big deposit",
    "Mining locale",
    "Vein",
    "Mother ___",
    "Comstock, for one",
    "Treasure-trove",
    "Locale of riches",
    "Bank deposit",
    "Mother ___",
    "Ore galore",
    "Prospector's bonanza",
    "Site of deposit withdrawals",
    "Deposit"
  ],
  "naps": [
    "Quick time outs?",
    "Snoozes",
    "Goes out for a bit?",
    "More than nods",
    "Breathers for kindergarten teachers",
    "Snoozes",
    "Goes out for a while?",
    "Fuzzes",
    "Catches forty winks",
    "Goes out for a bit?",
    "Preschool breaks",
    "Drops out?",
    "Brief sleeps",
    "Recharges, in a way",
    "Dozes",
    "Drops off, maybe",
    "They're taken in hammocks",
    "Short breaks, of a sort",
    "Afternoon refreshers",
    "Day breaks",
    "Temporary retirements?",
    "Spends some time out?",
    "Refreshers",
    "Piles",
    "Siestas",
    "Refreshers",
    "Is off guard",
    "Refreshing things",
    "Siestas",
    "Drops off",
    "Short breaks",
    "Catches Z's",
    "Fabric features",
    "Is off guard",
    "Snoozes",
    "Grabs 40 winks",
    "Siestas",
    "Is off guard",
    "Isn't vigilant",
    "Drops off",
    "Refreshers",
    "Recharges one's batteries, so to speak",
    "Slumbers",
    "Siestas",
    "Times out",
    "Takes a siesta",
    "Refreshers",
    "Drops off",
    "Baby's quiet times",
    "Down times",
    "Refreshers, you might say",
    "Downy surfaces",
    "Afternoon activities",
    "Piles",
    "Siestas",
    "Gets some quick Z's",
    "Cats catch them",
    "Logs some z's",
    "Snoozes",
    "Snoozes",
    "Catches some Z's"
  ],
  "apop": [
    "Each ... as in the price of balloons?",
    "Each",
    "Each",
    "Each",
    "Individually",
    "How balloons are priced?",
    "Per item",
    "Each",
    "Per item",
    "For each item",
    "For one",
    "Per unit",
    "Each",
    "Individually",
    "Per item",
    "Per person",
    "Each",
    "Per",
    "Each",
    "Per",
    "Individually",
    "Per",
    "Per",
    "Per",
    "How individual firecrackers are priced?",
    "Individually",
    "Each",
    "Per",
    "Each, pricewise",
    "Each",
    "Per",
    "Each",
    "Each",
    "Each",
    "Individually",
    "Each",
    "Each",
    "Per unit",
    "Pricing words",
    "Singly",
    "Each",
    "Each",
    "Per",
    "Each",
    "Each",
    "Individually",
    "Each",
    "Each",
    "Each",
    "Each",
    "Each",
    "For one",
    "Each",
    "Each, slangily",
    "Per person",
    "Per",
    "Each",
    "Each",
    "Per unit",
    "Per",
    "Each",
    "Each, in pricing",
    "Each",
    "Each",
    "For one",
    "Each",
    "Each",
    "Each",
    "Per",
    "Individually",
    "Each",
    "Per",
    "Each",
    "Each",
    "Per unit",
    "Individually",
    "Phrase often following dollar amounts",
    "How bottles of soft drink may be priced?",
    "Per item purchased",
    "Each",
    "Per",
    "Each",
    "Per unit",
    "Each",
    "Each",
    "Each"
  ],
  "dias": [
    "\"Buenos ___\"",
    "\"Buenos ___\"",
    "\"Buenos ___\"",
    "Mayo parts?",
    "Lunes y martes",
    "\"Buenos ___!\"",
    "Portuguese explorer Bartolomeu who found a sailing route around Africa",
    "\"Buenos ___!\"",
    "His 1488 voyage opened the road to India",
    "\"Buenos ___\"",
    "Los ___ de los Muertos (Mexican holiday)",
    "Portuguese explorer Bartolomeu",
    "Calendario parts",
    "There are seven in a semana",
    "\"Buenos ___\"",
    "\"Buenos ___\"",
    "\"Buenos ___\"",
    "\"Buenos ___\"",
    "Cape of Good Hope discoverer",
    "Domingo y lunes",
    "\"Buenos ___\"",
    "\"Buenos ___\"",
    "\"Buenos ___\""
  ],
  "gnus": [
    "Wildebeests",
    "Horned African beasts",
    "Serengeti herd",
    "South African game",
    "Savanna sights",
    "Wildebeests",
    "Wildebeests",
    "Wildebeests",
    "Serengeti prey",
    "Lion prey",
    "African migrators",
    "Prey for lions",
    "Striped animals",
    "Lion prey",
    "White-bearded Africans",
    "Old World grazers",
    "Wildebeests",
    "Wildebeests",
    "Serengeti herd",
    "African herd",
    "Bearded beasts",
    "Lions' prey",
    "Hump-shouldered beasts",
    "Zoo attractions",
    "Wildebeests",
    "Wildebeests",
    "Lions' prey",
    "Bearded antelopes",
    "Zoo beasts",
    "Oxlike antelopes",
    "Bearded animals"
  ],
  "okso": [
    "\"Then here's what happened ...\"",
    "\"And this affects me ... how?\"",
    "\"Your point being ...?\"",
    "\"And I should care because ...?\""
  ],
  "caps": [
    "What OPEC and NATO are both in?",
    "Things baseball uniforms and toy pistols have",
    "2018 Stanley Cup champs, to fans",
    "Ceilings",
    "Places for baseball team insignia",
    "Tube tops",
    "Washington team, familiarly",
    "Things that bottles and snow-covered mountains have",
    "*Features of some front teeth",
    "Coonskins for Davy Crockett, e.g.",
    "Ceilings",
    "Pen parts",
    "Baseball headwear",
    "Mushroom parts",
    "Tops",
    "Items on which baseball insignia appear",
    "Play ammo",
    "Limits",
    "Beanies",
    "Mushroom toppers",
    "Tams",
    "Toy gun poppers",
    "Frothy parts of waves",
    "Tams",
    "Beanies",
    "Stadium souvenirs",
    "Mushroom parts",
    "Stadium souvenirs",
    "Tops off",
    "Puts a limit on",
    "Puts a lid on",
    "Bottle tops",
    "Toy gun ammo"
  ],
  "hits": [
    "Google search info",
    "Box score column",
    "Singles, e.g.",
    "Online search metric",
    "Successes in the game Battleship",
    "Google statistic",
    "Pete Rose's 4,256",
    "Best sellers",
    "Google stat",
    "Modern measures of popularity",
    "Google results",
    "#1's, e.g.",
    "Web site visits",
    "Top 10, e.g.",
    "Chart-topping songs",
    "Chart-toppers",
    "Google stat",
    "The middle part of 44-Down",
    "Scoreboard tally",
    "See 45-Down",
    "The 40 of the Top 40",
    "Top 10 tunes",
    "They make charts",
    "Angels' wishes",
    "Home page visits",
    "Best sellers",
    "Half of a batting average calculation",
    "Pop station playlist",
    "Oft-played numbers",
    "Parade group?",
    "Angels' dreams",
    "Single and double",
    "Top 40 songs",
    "Top 40 station's play list",
    "Billboard listings",
    "Top 10 items",
    "Slugs",
    "Successes"
  ],
  "ryes": [
    "Some whiskeys",
    "Old Forester and Old Overholt offerings",
    "\"Seeded\" or \"unseeded\" grocery choices",
    "Choices at bakeries and liquor stores",
    "Stock at a saloon",
    "Some whiskeys",
    "Some whiskeys",
    "Some breads ... or a homophone for what bread loaves do",
    "Some loaves",
    "Kvass grains",
    "Some Crown Royal offerings",
    "Bakery loaves",
    "Deli loaves",
    "Some hard drinks",
    "Loafs around a deli?",
    "Lickers",
    "Wild Turkey and Jim Beam",
    "Bread and drink",
    "Jim Beam and Wild Turkey",
    "Bartender's stock",
    "Some loaves",
    "Certain spirits",
    "Bar selections",
    "Jim Beam and others",
    "Some deli buys",
    "Some whiskeys",
    "Some deli loaves",
    "Some loaves",
    "Some whiskeys",
    "Some distillery products",
    "Deli loaves",
    "Some whiskeys",
    "Seedy loaves",
    "Dark loaves",
    "Deli supplies",
    "Some breads",
    "Some whiskeys",
    "Certain grains",
    "Bakery loaves",
    "Loaves with seeds",
    "Some Jim Beam quaffs",
    "Bar stock",
    "Jim Beam and others",
    "Deli loaves",
    "Dark loaves",
    "Bar bottles",
    "Alternatives to pumpernickels",
    "Canadian Club and others",
    "Deli loaves",
    "Saloon stock",
    "Tavern offerings",
    "Russet-colored liquors",
    "Some loaves",
    "Deli wares",
    "Some are Jewish",
    "Seeded loaves",
    "Deli loaves",
    "Bakery selections",
    "Some whiskeys",
    "Some bar supplies",
    "Dark loaves",
    "Some whiskeys",
    "Delicatessen loaves",
    "Whiskeys",
    "Bar choices",
    "Bakery selections",
    "They may be seeded",
    "Tavern supplies",
    "Bakery offerings",
    "Cereal grasses",
    "Bar selections",
    "Whiskey choices",
    "Deli breads",
    "5-Down loaves",
    "Bar selection",
    "Bread and whisky",
    "Whiskies",
    "Certain grains",
    "Bar selections",
    "Bread and drink",
    "Deli loaves",
    "They may be seeded",
    "Whiskies",
    "Deli loaves",
    "Deli loaves",
    "Bakery selection",
    "Bartender's bottles",
    "Deli selections",
    "They may be seeded",
    "Some whiskeys",
    "Some whiskies",
    "Baker's offerings",
    "Bread grains",
    "Package-store wares",
    "Bar orders"
  ],
  "mope": [
    "Put on an unhappy face",
    "Brood",
    "Sulk",
    "Sulk",
    "Something one might do after an embarrassing loss",
    "Be down",
    "Sulk",
    "Feel sorry for oneself",
    "Sulk",
    "Sulk",
    "Brood",
    "Be a Debbie Downer",
    "Turn blue?",
    "Wear a long face",
    "Emulate Eeyore",
    "Wear a long face",
    "Act the gloomy Gus",
    "Be blue",
    "Betray dejection",
    "Look down",
    "Be down",
    "Put on an unhappy face",
    "Be a gloomy Gus",
    "Be down",
    "Be listless",
    "Sulk",
    "Have the blues",
    "Brood",
    "Brood",
    "Act gloomy",
    "Have a long face",
    "Wear a long face",
    "Gloomy Gus",
    "Brood",
    "Wear a long face",
    "Brood",
    "Look dejected",
    "Wear a long face"
  ],
  "snow": [
    "Contents of some banks",
    "Naturally occurring hexagonal crystals",
    "Cold shower?",
    "Contents of some drifts",
    "When it's driving, you might not want to drive",
    "Blanket that won't keep you warm?",
    "Convince with smooth talk",
    "What you might build a winter fort with",
    "Word before cap or shoe",
    "Winter Olympics need",
    "Frosted flakes?",
    "Feature of the Himalayas",
    "Word with cap or crab",
    "Word with bank or blind",
    "Word with bird or board",
    "It falls quietly",
    "Reason for a school closing",
    "Wintry coating",
    "___ globe",
    "Cold fall",
    "Bank deposit?",
    "Put one over on",
    "Reason for school cancellation",
    "Forecast eagerly awaited by schoolkids",
    "Pre-cable TV problem",
    "White icing on a gingerbread house, possibly",
    "Fall in winter",
    "Wet blanket?",
    "Frequent Arctic forecast",
    "Wet blanket?",
    "Winter drifter",
    "Bank deposit?",
    "White sheet",
    "Reason to add salt?",
    "The white of a whiteout",
    "Cause of temporary blindness",
    "Blanket material",
    "Kind of day or job",
    "Symbol of softness",
    "Monet's \"___ Scene at Argenteuil\"",
    "Overwhelm with flattery",
    "Winter forecast",
    "Old TV problem",
    "Shoveler's target",
    "Persuade through razzle-dazzle",
    "It hits the ground silently",
    "Winter forecast",
    "Possible cause of school cancellation",
    "Author C. P. ___",
    "December fall",
    "Winter fall",
    "With 35-Down, something meant to be shaken",
    "Skier's wish",
    "Cap material?",
    "Winter forecast",
    "White blanket",
    "Fall around Christmas",
    "Bamboozle",
    "The white in a whiteout",
    "Symbol of whiteness",
    "See 27-Across",
    "Cause of certain blindness",
    "Pre-cable woe",
    "Powder, e.g.",
    "Reason to bring out the chains",
    "Winter blanket",
    "Deceive but good",
    "Reason for school cancellation",
    "Welcome forecast for Santa",
    "Precipitation below 32??",
    "Old TV problem",
    "Kind of job",
    "Winter fall",
    "Falling flakes",
    "White coat",
    "TV reception problem",
    "Drifter?",
    "Winter forecast",
    "Frequent Arctic forecast",
    "Winter blanket",
    "It's not seen on cable TV",
    "Driving is hard when this is driving",
    "Winter forecast",
    "Falling flakes",
    "Winter blanket",
    "Kind of leopard or goose",
    "Precable problem",
    "Pre-cable problem",
    "Serial killer?",
    "Kids' winter schoolday wish",
    "Fall on Pikes Peak?",
    "The \"white\" of \"White Christmas\"",
    "Interference",
    "Aspen asset",
    "Cold powder",
    "Kind of job",
    "Skiers' wish",
    "All-too-frequent Buffalo forecast",
    "Convince with smooth talk",
    "Problem solved by cable",
    "Cable TV clears it up",
    "TV problem",
    "Bamboozle",
    "Winter fall"
  ],
  "jeer": [
    "Give a hoot?",
    "Taunt",
    "Loud, mocking call",
    "Catcall",
    "Loudly razz",
    "Foul call",
    "Fan sound",
    "\"Are you blind, ump?,\" e.g.",
    "Taunt",
    "Heckle",
    "Raspberry",
    "Routine response?",
    "Razz",
    "Raspberry",
    "Raspberry",
    "Razz",
    "Give the raspberry",
    "Big twit?",
    "Razz",
    "Taunt",
    "Stand-up comic's fear",
    "Give a Bronx cheer",
    "Raspberry",
    "Razz",
    "Mock",
    "Raspberry",
    "Give a razzing",
    "Raspberry",
    "Rude response",
    "Bronx cheer",
    "Scoff (at)",
    "Catcall",
    "Ridicule",
    "Raspberry",
    "Raspberry",
    "Impolite shout",
    "Raspberry",
    "Hoot",
    "Razz",
    "Boo"
  ],
  "stet": [
    "[Ignore that edit]",
    "Proofreader's directive",
    "Proofer's 33-Down",
    "\"Keep as is\"",
    "[Leave it]",
    "\"Never mind, it's fine\"",
    "[Actually, don't delete this]",
    "Leave in",
    "Decide to keep after all",
    "Editorial reversal",
    "[Let it stand]",
    "[Never mind]",
    "\"Leave it be\"",
    "\"Leave as is,\" to a proofreader",
    "Proofreader's \"leave it in\"",
    "Leave in",
    "Instruction to a typesetter",
    "Editorial override",
    "Change one's mind about changing",
    "Decide not to take out",
    "Editorial override",
    "End up leaving",
    "Editor's override",
    "Let stand, editorially",
    "Leave in",
    "Reversal of a 29-Across",
    "Leave in",
    "Editor's override",
    "Editorial reversal",
    "Proofreader's \"leave it\"",
    "Leave in",
    "Editor's \"Let it stay\"",
    "Proofer's \"oops\"",
    "[Ignore prior marks]",
    "Proofer's direction",
    "Editor's \"On second thought\"",
    "Latin for \"let it stand\"",
    "Proofer's mark",
    "Editor's \"undo\"",
    "Reinstate, in a way",
    "\"Undo\" mark",
    "Editor's override",
    "Editor's retraction",
    "Typesetting direction",
    "Leave in",
    "Time reversal?",
    "Restoration notation",
    "Leave in",
    "Amendment to an amendment",
    "Copy editor's mark",
    "Leave in, as text",
    "\"Leave in,\" to a proofreader",
    "Reinstate, in a way",
    "Leave in, to a proofreader",
    "Keep in order?",
    "Galley order",
    "Put back",
    "See 46-Down",
    "Word for a keeper?",
    "Put back, in a way",
    "Mark in a margin",
    "Put back in order?",
    "Indication to leave something in",
    "Mark meaning \"no change\"",
    "Indicator of second thoughts",
    "Opposite of 64-Down",
    "Leave in",
    "Editorial reconsideration",
    "Proofreader's \"reinstate\" mark",
    "Leave without changing",
    "[Ignore edit]",
    "What \"........\" means to a typesetter",
    "Keep in",
    "\"Do not change,\" to an editor",
    "\"Leave in,\" to a proofer",
    "Unmake changes",
    "\"Leave it in\" mark",
    "Keep in",
    "Margin marking",
    "Editing mark",
    "Decide to leave",
    "Leave in, to an editor",
    "\"Don't change\"",
    "Put back in",
    "Proofer's mark",
    "What a line of dots may signify",
    "Proof word",
    "Galley countermand",
    "Mark indicating \"O.K. as is\"",
    "Leave in, in proofreading",
    "\"Put it back in\"",
    "Mark in the margin",
    "Proofer's mark",
    "Leave in, as text",
    "\"Let stand\" direction",
    "Not delete",
    "Leave in, as text",
    "Galley mark",
    "Editor's note",
    "Margin notation",
    "Manuscript annotation",
    "Typesetting mark",
    "Leave in, to a proofreader",
    "Marginal mark",
    "Proofreader's mark",
    "Leave in, to an editor",
    "Mark consisting of a series of dots",
    "Editor's mark",
    "Leave in, as text",
    "\"Put it back\"",
    "\"Undo\" mark",
    "Editor's mark",
    "Editor's overruling",
    "Galley notation",
    "Put back",
    "\"Keep this\"",
    "Proofreader's \"leave it\"",
    "Going back in Time?",
    "Let it be",
    "Dele overrider",
    "Editor's \"let it stand\"",
    "Symbol in proofreading",
    "Dele undoer",
    "Proofreader's instruction",
    "\"Never mind\" to an editor",
    "Let stand, in editorial parlance",
    "Keep in",
    "Keep in",
    "Order on margin?",
    "Alteration canceler",
    "Put back",
    "Mark of reconsideration",
    "Dele canceler",
    "Proof word",
    "Editor's \"let it be\"",
    "\"Keep it\"",
    "Leave in, to an editor",
    "Undo a change",
    "\"Keep it in\"",
    "Proofreader's mark",
    "Put back",
    "Leave in",
    "Proofreading mark",
    "Proof word",
    "Galley notation",
    "Galley order",
    "\"Keep as is\"",
    "\"Leave it,\" to a typesetter",
    "Editor's mark",
    "Proofreader's mark",
    "Leave in",
    "\"Let it stand\"",
    "Proof annotation",
    "Editor's notation",
    "Editor's mark",
    "Proof word",
    "Undo a dele",
    "Editor's direction",
    "Keep in",
    "Leave be",
    "Leave be",
    "Leave be",
    "\"Leave as is\"",
    "Let it be, editorially",
    "Proofreader's mark",
    "\"Let it stand\"",
    "Copy editor's marking",
    "Marginal notation",
    "Editor's scribble",
    "Leave in, editorially",
    "Galley mark",
    "Opposite of 65-Across",
    "Let it stand",
    "Proofer's mark",
    "Manuscript mark",
    "Leave be",
    "Editor's mark",
    "\"Don't delete this\"",
    "Proofreader's direction",
    "Editor's mark",
    "Reinstate",
    "Leave in after all",
    "Galley marking",
    "Don't strike!",
    "Leave in",
    "Retain, in a way",
    "Undo a dele",
    "Literally, let it stand",
    "Let stand",
    "Editor's notation",
    "Editor's notation",
    "Printer's mark",
    "Editor's mark",
    "Leave in",
    "Blue-pencil notation",
    "Leave in"
  ],
  "dire": [
    "Not looking good at all",
    "Warning of disaster",
    "Pressing",
    "Very serious",
    "Superserious",
    "Urgent",
    "Catastrophic",
    "In ___ straits",
    "Requiring immediate attention",
    "Like some straits",
    "Dreadful, as circumstances",
    "More than serious",
    "Critical",
    "Descriptive of some undesirable consequences",
    "Terrible",
    "Critical",
    "Desperate",
    "Emergency-related",
    "Extreme",
    "Urgent",
    "In ___ straits",
    "Urgent",
    "Grievous",
    "Ominous",
    "Not just serious",
    "Presaging disaster",
    "Very serious",
    "Urgent",
    "Life-or-death",
    "Foreboding trouble",
    "Awful",
    "Desperate",
    "Grave",
    "Acute",
    "Kind of need",
    "Calamitous",
    "Awful",
    "In ___ straits",
    "Horrific",
    "Life-or-death",
    "Calamitous",
    "Dreadful",
    "Dreadful, as circumstances",
    "Desperate",
    "Calamitous",
    "In ___ straits",
    "Apocalyptic",
    "Desperate",
    "Potentially disastrous",
    "Calamitous",
    "Dreadful",
    "Calamitous",
    "Dreadful",
    "Dreadful",
    "Calamitous",
    "Acute",
    "More than serious",
    "Awful",
    "In ___ straits",
    "Not looking good",
    "Kind of straits",
    "Serious",
    "Like some straits",
    "Like some consequences",
    "Calamitous",
    "Like 20-Across, often",
    "Desperate",
    "Desperate",
    "Pressing",
    "Rock's ___ Straits",
    "Desperate",
    "Dreadful",
    "Desperate",
    "Urgent",
    "Terribly urgent"
  ],
  "sven": [
    "Reindeer in \"Frozen\"",
    "\"Frozen\" character with antlers",
    "___ and Ole (stock characters in Upper Midwest jokes)",
    "\"Frozen\" reindeer",
    "Reindeer in \"Frozen\"",
    "\"Frozen\" reindeer",
    "Reindeer in \"Frozen\"",
    "\"Frozen\" reindeer's name",
    "Nordic name meaning \"young warrior\"",
    "Speed-skating champion Kramer",
    "Kristoff's reindeer in \"Frozen\"",
    "Name that's Old Norse for \"young man\"",
    "___ Hedin, discoverer of the Trans-Himalaya",
    "Man's name meaning \"young man\"",
    "Name that comes from Old Norse for \"young man\"",
    "___ Kramer, 2010 Dutch speed-skating gold medalist",
    "Ren's cousin of cartoondom",
    "Cinematographer Nykvist",
    "Two-time Oscar-winning cinematographer Nykvist",
    "Stereotypical Swedish man's name",
    "___ Kramer, 2010 Dutch Olympic gold medalist in speed skating",
    "Cinematographer Nykvist",
    "Man's name meaning \"young man\"",
    "1932 skiing gold medalist Utterstrom",
    "Cinematographer Nykvist",
    "Cinematographer Nykvist",
    "Explorer ___ Anders Hedin",
    "Author Birkerts",
    "1984 skating gold medalist Gustafson",
    "Cinematographer Nykvist",
    "Cinematographer Nykvist",
    "Cinematographer Nykvist",
    "1932 skiing gold medalist Utterstrom",
    "Cinematographer Nykvist",
    "Olympic skating gold medalist Gustafson",
    "Bergman collaborator Nykvist",
    "Cinematographer Nykvist"
  ],
  "tina": [
    "2019 jukebox musical featuring the song \"Proud Mary\"",
    "Fey of \"30 Rock\"",
    "Funny Fey",
    "Funny Fey",
    "Turner on a record",
    "Fey of \"30 Rock\"",
    "Fey of \"30 Rock\"",
    "Fey of \"Baby Mama\"",
    "Fey of comedy",
    "\"S.N.L.\" alum Fey",
    "Funny Fey",
    "Daughter on Fox's \"Bob's Burgers\"",
    "\"30 Rock\" star Fey",
    "Turner on a turntable",
    "Turner or Fey",
    "Weymouth of Talking Heads",
    "Brown of publishing",
    "Daughter on the animated \"Bob's Burgers\"",
    "Fey or Turner",
    "Girl's name that becomes a contraction when its first and last letters are switched",
    "Brown who wrote \"The Diana Chronicles\"",
    "Rocker Weymouth of the Talking Heads",
    "Brown who founded The Daily Beast",
    "Fashion designer Knowles, mother of Beyonce",
    "Turner who sang \"The Best,\" 1989",
    "Fey of \"30 Rock\"",
    "Funny Fey",
    "Fey of \"30 Rock\"",
    "\"Glee\" girl",
    "Ike's partner in 1960s-'70s music",
    "Editor Brown",
    "Fey of \"30 Rock\"",
    "Funny Fey",
    "Brown of Newsweek/The Daily Beast",
    "Rock bassist Weymouth",
    "2008 greatest hits album that includes the song \"Proud Mary\"",
    "Fey of \"30 Rock\"",
    "Fey of \"30 Rock\"",
    "Turner of records",
    "Funny Fey",
    "Turner who sang \"We Don't Need Another Hero\"",
    "Turner of records",
    "Turner who sang \"The Best\"",
    "One of the Sinatras",
    "Timeless Turner",
    "Turner of records?",
    "Yothers of \"Family Ties\"",
    "\"S.N.L.\" alum Fey",
    "Turner who sang \"Proud Mary\"",
    "Brown with a blue pencil",
    "Fey of \"30 Rock\"",
    "Howe who wrote \"Pride's Crossing\"",
    "A Sinatra",
    "Writer/editor Brown",
    "One of the Sinatras",
    "Actress Louise",
    "A Turner",
    "A Sinatra",
    "Singer Turner",
    "Editor Brown",
    "Turner who sang \"I Don't Wanna Fight\"",
    "Ike & ___ Turner (1960's-70's duo)",
    "___ Fey of \"S.N.L.\"",
    "Media honcho Brown",
    "One of the Sinatras",
    "One of the Sinatras",
    "___ Louise of \"Gilligan's Island\"",
    "Singer Turner",
    "Louise of \"Gilligan's Island\"",
    "She played Ginger on \"Gilligan's Island\"",
    "Singing partner of 114-Down",
    "Model Chow",
    "One of the Sinatras",
    "Playwright Howe",
    "\"Waterworld\" actress Majorino",
    "___ Fey of \"Saturday Night Live\"",
    "Perry Como hit \"___ Marie\"",
    "Singer Turner",
    "\"Coastal Disturbances\" playwright Howe",
    "Brown of Talk magazine",
    "Singer Turner",
    "Perry Como's \"___ Marie\"",
    "Turner or Sinatra",
    "Brown of Talk magazine",
    "Turner in the Rock and Roll Hall of Fame",
    "Turner who sang \"What's Love Got to Do With It\"",
    "Singer Turner",
    "Actress Louise",
    "\"Pride's Crossing\" playwright Howe",
    "The housewife in \"Diary of a Mad Housewife\"",
    "Ike's ex",
    "Singer Turner",
    "One of the Sinatras",
    "Ike's onetime singing partner",
    "Louise of \"Gilligan's Island\"",
    "A Turner",
    "She played Ginger on \"Gilligan's Island\"",
    "Actress Louise",
    "One of the Sinatras",
    "Rock's Turner",
    "Rock's Turner",
    "Singer Turner",
    "A Turner",
    "One of the Sinatras",
    "Actress Louise",
    "Louise of \"Gilligan's Island\""
  ],
  "nada": [
    "Zip",
    "Zippo",
    "Nil",
    "Zippo",
    "A goose egg",
    "Zip",
    "Nothin'",
    "Zilch",
    "Nothin'",
    "Zippo",
    "Zilch",
    "Zilch",
    "Squat",
    "Zippo",
    "Nothin'",
    "Zippo",
    "Zilch",
    "Nothin'",
    "Bubkes",
    "Zippo",
    "Nothin'",
    "Zilch",
    "Zippo",
    "Zip",
    "Zilch",
    "Zilch",
    "Zippo",
    "Squat",
    "Squat",
    "Zip",
    "Zip",
    "Nothin'",
    "Zip",
    "Nothin'",
    "Zilch",
    "Nothing",
    "Zilch",
    "Nothin'",
    "Zippo",
    "Zip",
    "\"Nothin'\"",
    "Zilch",
    "Nothing, in Juarez",
    "Zippo",
    "Squat",
    "Zilch",
    "Zippo",
    "Zero",
    "Zilch",
    "Zilch",
    "Zilch",
    "Zippo",
    "Zilch",
    "Zippo",
    "Zilch",
    "It means nothing to Juan",
    "Zip",
    "Bubkes",
    "\"Nothin'\"",
    "Nothin'",
    "Nil",
    "Zilch",
    "Bubkes",
    "Big zero",
    "Zip",
    "Zip",
    "Nothin'",
    "Zilch",
    "Nothin'",
    "Zippo",
    "Big zero",
    "Zip, to Zapata",
    "Zip",
    "Zip",
    "Nothin'",
    "Nothin'",
    "Zip",
    "Nothin'",
    "Zilch",
    "Zippo",
    "Zippo",
    "Zapata's \"zip\"",
    "Nothin'",
    "Big zero",
    "Zip",
    "Zilch",
    "Zilch",
    "Zippo",
    "Zip",
    "Zippo",
    "Nothin'",
    "Zip",
    "Zilch",
    "Zippo",
    "Zippo",
    "Zip",
    "Zippo",
    "Nothing, south of the border",
    "Zippo",
    "Goose egg",
    "Zip, to Zapata",
    "Zip, to Zapata",
    "Zilch",
    "Zilch",
    "Nothin'",
    "Nothing, slangily",
    "Zilch",
    "Zilch, to Zapata",
    "Nothing, to a Nuyorican",
    "Nothing, in Nogales",
    "Nil, in Seville",
    "It means nothing to Julio",
    "Zilch, to Zapata",
    "Nino's nothing",
    "Zilch"
  ],
  "born": [
    "\"___ a Crime\" (2016 Trevor Noah memoir)",
    "Brought into the world",
    "Destined (to be)",
    "Lady Gaga's \"___ This Way\"",
    "With 28-Across, schlemiels",
    "\"I wasn't ___ yesterday\"",
    "Partner of bred",
    "Partner of raised",
    "Brought into the world",
    "Created",
    "Sprung (from)",
    "___-again (like some Christians)",
    "Word on a headstone",
    "Delivered",
    "Natural",
    "Delivered",
    "Kind of loser",
    "Brought to life",
    "___-again",
    "Kind of loser",
    "Kind of loser"
  ],
  "awry": [
    "Bad way to go",
    "Out of kilter",
    "Out of whack",
    "Amiss",
    "A little off",
    "Not right",
    "Off-kilter",
    "Wrong",
    "Amiss",
    "Wonky",
    "Out of kilter",
    "Off-balance",
    "Sideways",
    "Not straight",
    "Off",
    "Out of kilter",
    "Off",
    "All wrong",
    "Out of whack",
    "Catawampus",
    "Cockeyed",
    "Out of kilter",
    "Out of kilter",
    "Haywire",
    "Out of kilter",
    "Off",
    "Twisted",
    "Off",
    "Off-course",
    "Out of whack",
    "Not straight",
    "Wrong",
    "With a twist?",
    "Askew",
    "Not right"
  ],
  "ages": [
    "A long time",
    "Range on a game box",
    "A mighty long time",
    "A lo-o-ong time",
    "Like, for-EV-er",
    "Develops wrinkles, say",
    "Seemingly forever",
    "So long",
    "With 36-Across, in the distant past",
    "Possible response to \"How long's it been?\"",
    "Information on a game box",
    "Quite a long time",
    "Years and years",
    "A long stretch",
    "A very long time",
    "A really long time",
    "As long as one can remember",
    "Forever and a day",
    "For all ___",
    "Forever, seemingly",
    "Specs printed on a toy box",
    "All ___ (what a G rating means)",
    "A long, long time",
    "Census data",
    "A long, long time",
    "Seemingly forever",
    "Forever and a day",
    "Like, forever",
    "10 and 8 for Bart and Lisa Simpson, respectively",
    "Gets 16-Across",
    "Game box specification",
    "Matures",
    "Gets on",
    "Forever",
    "___ 8 and up (game specification)",
    "Game box specification",
    "Ripens, as cheese",
    "A very long time ... or a hint to the starts of the answers to the five starred clues",
    "Forever",
    "Seemingly forever",
    "___ 10 and up (info on a game box)",
    "A long, long time",
    "Improves, as wine",
    "The 13 of PG-13 and 17 of NC-17",
    "\"___ 8 and up\"",
    "Many moons",
    "Years on end",
    "Biographical data",
    "It's a long time",
    "A really long time",
    "Puts on years",
    "Gets up there",
    "A really long time",
    "Yellows or grays, say",
    "Matures",
    "A long time",
    "A long time",
    "Gains maturity",
    "A long, long time",
    "With 19-Across, far back",
    "Grows old",
    "Matures",
    "Mellows",
    "Dating service data",
    "Gets on in years",
    "Seemingly forever",
    "Years and years",
    "A long stretch",
    "Matures",
    "So, so long",
    "A long time",
    "All ___ (words on a game box)",
    "A long, long time",
    "Forever",
    "A long, long time",
    "Passes the time?",
    "Dark ___",
    "Census data",
    "All-___ (G-rated)",
    "A long time",
    "\"It's been ___\"",
    "Census stats",
    "\"It's been ___!\"",
    "Matures",
    "Gets older",
    "Matures",
    "Pieces of the past",
    "Awfully long time",
    "Mighty long time",
    "Gets on",
    "Long, long time",
    "Yellows or grays",
    "Forever and a day",
    "Quite a while",
    "Sits in the cellar, maybe",
    "Who-knows-how-long",
    "Word on a toy package",
    "Loses immediacy",
    "Seasons",
    "Seemingly forever",
    "Periods in history",
    "Stretches",
    "Mellows, as wine",
    "Family data",
    "Wrinkles, say",
    "Census data",
    "Quite a while",
    "A long time",
    "Period between blue moons",
    "Many moons",
    "Census data",
    "Mellows",
    "Seemingly forever",
    "\"___ 4 and up\"",
    "Forever",
    "Gets on",
    "Many, many years",
    "Years and years",
    "Many moons",
    "Census data",
    "Is unlike Peter Pan",
    "Entries in a family album",
    "Ripens",
    "Many a moon",
    "Gets on in years",
    "A long, long time",
    "Mellows",
    "Quite a while",
    "Census data",
    "A long time",
    "Improves, as cheese",
    "Census data",
    "Quite a while",
    "Long, long time",
    "Long, long time",
    "Dark ___",
    "Seeming eternity",
    "\"Forever\"",
    "Grays",
    "Long time",
    "Years on end",
    "Many moons",
    "Eons",
    "\"Rock of ___\"",
    "\"Rock of___\"",
    "Long time",
    "Gets older",
    "Long time",
    "A long time",
    "Turns one's hair gray",
    "School data",
    "Years and years",
    "Lifetimes",
    "Ripens",
    "Years and years",
    "Grays",
    "Consent and Reason, e.g.",
    "Years and years",
    "\"Rock of ___\"",
    "Bronze and Iron",
    "Long time",
    "\"Rock of ___\"",
    "Becomes gray",
    "Family data",
    "Man has seven",
    "Matures"
  ],
  "cami": [
    "Sleeveless undergarment, informally",
    "Sleeveless garment, informally",
    "Top that may have a built-in bra",
    "Intimate garment, for short",
    "Ladies' undergarment, casually",
    "Victoria's Secret buy, informally",
    "Short negligee, for short",
    "Sleeveless undergarment, for short",
    "Sleeveless item, for short"
  ],
  "earn": [
    "Pull in",
    "Pull down",
    "Take home",
    "Merit",
    "Make, as money",
    "Pull down",
    "Get paid",
    "Bring in",
    "Make, as a salary",
    "Bring in",
    "Pull down",
    "Bring home",
    "Bring in",
    "Bring in",
    "Not luck into",
    "Gain",
    "Become worthy of",
    "Merit",
    "Bring in",
    "Make, as an income",
    "Make",
    "Deserve",
    "Bring home",
    "Make, as money",
    "Make, as dough",
    "Gross",
    "Net",
    "Make",
    "Garner",
    "Bring home the bacon",
    "Make",
    "Pull down",
    "Make bread",
    "Bring in",
    "Be deserving of",
    "Realize",
    "Get through hard work",
    "Reap",
    "Acquire",
    "Yield, as interest",
    "Get through work",
    "Work hard for",
    "Bring in, as a salary",
    "Rake in",
    "Make",
    "Pull in",
    "Bring in",
    "Bring in",
    "Yield as profit",
    "Make, as an income",
    "Merit",
    "Merit",
    "Make",
    "Make",
    "Make",
    "Make",
    "Net",
    "Pull down",
    "Make",
    "Bring in",
    "Make",
    "Pull in",
    "Merit",
    "Make",
    "Not get merely by accident",
    "Make",
    "Net",
    "Fetch",
    "Bring in",
    "Make",
    "Bring home",
    "Win",
    "Get through hard work",
    "Make",
    "Pull in",
    "Have coming",
    "Bring in",
    "Pull down",
    "Make, as a salary",
    "Be worthy of",
    "Bring in",
    "Yield",
    "Make",
    "Bring in",
    "Make",
    "Have coming",
    "Net",
    "Take home",
    "Make in income",
    "Make, as an income",
    "Net",
    "Have coming",
    "Make, as money",
    "Be worthy of",
    "Rake in",
    "Deserve",
    "Make",
    "Work to get, as someone's trust",
    "Bring in",
    "Make",
    "Make",
    "Pull down, so to speak",
    "Pull down",
    "Make, as money",
    "Make",
    "Pull down",
    "Bring in",
    "Pull down",
    "Make",
    "Knock down, so to speak",
    "Pull down",
    "Merit",
    "Clear",
    "Pull down",
    "Bring in",
    "Deserve",
    "Make",
    "Get",
    "Take in",
    "Net",
    "Make",
    "Be a breadwinner",
    "Merit",
    "Make, as money",
    "Bring home the bacon",
    "Merit",
    "Bring home",
    "Take home",
    "Bring in",
    "Make, as bread",
    "Make",
    "Bring home",
    "Garner, as rewards",
    "Merit",
    "Gross, so to speak",
    "Pick up, as interest",
    "Gain deservedly",
    "Bring in",
    "Bring home the bacon",
    "Make money",
    "Merit",
    "Merit",
    "Take home",
    "Gross",
    "Make",
    "Garner",
    "Gain",
    "Merit",
    "Merit",
    "Bring home",
    "Be a breadwinner",
    "Deserve"
  ],
  "cruz": [
    "Celia known as the \"Queen of Salsa\"",
    "Symbol del cristianismo",
    "2016 campaigner",
    "Receiver Victor of the Giants' 2011 Super Bowl-winning season",
    "Junior senator from Texas",
    "Texas senator Ted",
    "One hanging in una iglesia",
    "Salsa singer Celia",
    "\"Volver\" actress, 2006",
    "Cristiano symbol",
    "Santa ___, University of California city",
    "San Juan de la ___",
    "Santa ___",
    "Santa ___",
    "Santa ___"
  ],
  "hiya": [
    "\"Howdy!\"",
    "Chipper greeting",
    "\"Hey there!\"",
    "Informal greeting",
    "Slangy greeting",
    "\"Howdy\"",
    "Slangy greeting",
    "\"Hey there\"",
    "\"Howdy\"",
    "\"Hello there\"",
    "Casual greeting",
    "\"Howdy\"",
    "Casual greeting",
    "\"Wassup\"",
    "Slangy greeting",
    "Informal greeting",
    "Informal greeting",
    "\"How de do\"",
    "Informal greeting",
    "Breezy greeting",
    "\"Howdy!\"",
    "Informal \"Welcome!\"",
    "\"Hey!\"",
    "\"Hey there!\"",
    "\"Howdy!\"",
    "\"Wassup?!\"",
    "\"Howdy\"",
    "Informal greeting",
    "Slangy greeting"
  ],
  "amal": [
    "Human rights lawyer Clooney",
    "Human rights lawyer Clooney",
    "Human rights attorney Clooney",
    "Activist ___ Alamuddin Clooney",
    "Human rights lawyer Clooney",
    "___ Clooney, lawyer often seen in tabloids",
    "___ Clooney, Barbara Walters's \"most fascinating person\" of 2014",
    "___ Clooney, human rights lawyer"
  ],
  "sear": [
    "Brown or blacken",
    "Burn superficially",
    "Singe",
    "Burn",
    "Brown, as a roast",
    "Brown or blacken",
    "Brown",
    "Brown in the kitchen",
    "Scorch",
    "Brand",
    "Prepare, as scallops",
    "Brown or blacken",
    "Blacken",
    "Put a quick crust on",
    "Blacken, in a way",
    "Brand",
    "Brown",
    "Put over high heat",
    "Burn superficially",
    "Blacken on a grill",
    "Instruction in many a steak recipe",
    "Blacken on a barbecue",
    "Scorch",
    "Blacken",
    "Brand",
    "Brand",
    "Char, as a steak",
    "Burn on the outside",
    "Cook so as to lock in the flavor, say",
    "Cauterize",
    "Blacken, as a steak",
    "Cook in a way, as tuna or beef tenderloin",
    "Scorch",
    "Burn",
    "Cauterize",
    "Brown",
    "Brown",
    "Burn the outside of",
    "Burn",
    "Brand",
    "Burn on the outside",
    "Burn the surface of",
    "Char",
    "Brand, in a way",
    "Scorch",
    "Burn",
    "Burn",
    "Burn",
    "Brown quickly",
    "Scorch",
    "Affix a brand to",
    "Brand",
    "Burn a bit",
    "Brand",
    "Cauterize",
    "Scorch",
    "Burn",
    "Burn",
    "Flame-broil",
    "Burn",
    "Scorch",
    "Burn slightly",
    "Lightly burn",
    "Burn the surface of",
    "Affix a brand to",
    "Burn with a branding iron",
    "Purposely burn",
    "Brand, in a way",
    "Brand",
    "Mark with a branding iron",
    "Scorch",
    "Mark with a branding iron",
    "Brand",
    "Blacken, in a way",
    "Burn quickly",
    "Burn the surface of",
    "Brand",
    "Scorch",
    "Cauterize",
    "Scorch",
    "Brand",
    "Cauterize",
    "Brown quickly",
    "Brand",
    "Char",
    "Gunlock catch",
    "Burn",
    "Cook quickly",
    "Burn",
    "Affix a brand to"
  ],
  "isee": [
    "[Nodding]",
    "\"Ah, gotcha\"",
    "Crystal gazer's lead-in",
    "\"Mm-hmm, it's clear now\"",
    "\"Gotcha\"",
    "\"Got it\"",
    "Verbal equivalent to a nod of the head",
    "\"OK, that makes sense\"",
    "\"Understood\"",
    "\"Understood\"",
    "\"Gotcha\"",
    "\"Makes sense to me\"",
    "\"You have a point ...\"",
    "\"So that's how it is\"",
    "\"Ahh\"",
    "\"Gotcha!\"",
    "\"Capeesh\"",
    "Positive response to 37-Down",
    "\"Oh, so that's how it's going to be\"",
    "\"Gotcha\"",
    "\"Understood\"",
    "Neutral response from a therapist",
    "\"O.K., got it\"",
    "\"Ah, gotcha\"",
    "\"Hmm ...\"",
    "\"Ah, makes sense\"",
    "\"Makes sense\"",
    "\"Point taken\"",
    "\"Mm-hmm\"",
    "\"Got it\"",
    "\"Gotcha\"",
    "\"Got it!\"",
    "\"That makes perfect sense now!\"",
    "Stereotypical therapist's response",
    "\"Mm-hmm\"",
    "\"Ah, now that's clear\"",
    "\"So that's it!\"",
    "\"So that's what you mean\"",
    "\"Uh-huh\"",
    "\"Hmm ...\"",
    "\"Mm-hmm\"",
    "\"Interesting ...\"",
    "\"That makes sense\"",
    "\"Understood\"",
    "Stereotypical response from a shrink",
    "\"Mmm-hmm, mmm-hmm\"",
    "\"Makes sense\"",
    "Words of enlightenment",
    "\"Ah, now it's clear\"",
    "\"Aha!\" ... or a hint to 18-, 23-, 37-, 48- and 58-Across",
    "\"That makes sense\"",
    "\"Amazing Grace\" verse ender",
    "\"That's clear to me now\"",
    "\"Aha!\"",
    "\"Gotcha\"",
    "\"Ah, gotcha\"",
    "\"Aha!\"",
    "\"That makes sense\"",
    "\"Understood\"",
    "\"Ah, that's what you mean\"",
    "\"Ohhh, O.K.\"",
    "\"Mm-hmm\"",
    "\"Hmm ...\"",
    "All-clear indication?",
    "\"That makes sense now\"",
    "\"Now it makes sense!\"",
    "Roman \"video\"",
    "Comment often after \"Hmm ...\"",
    "\"Hmm ... is that so!\"",
    "\"Get it?\" response",
    "Indication that you get it",
    "\"Oh, O.K.\"",
    "Therapist's words",
    "\"Oh, hmm ...\"",
    "Words often said with a nod",
    "\"That's clear\"",
    "\"Point taken\"",
    "\"Got it\"",
    "\"Got it!\"",
    "\"Got it\"",
    "\"Understood\"",
    "\"Uh-huh\"",
    "\"Interesting ...\"",
    "\"Ah, yes\"",
    "\"Now it makes sense!\"",
    "\"Hmm ...\"",
    "\"That's clear\"",
    "\"Hmm ...\"",
    "\"Mmm-hmm\"",
    "\"___ dead people\"",
    "\"No need to elaborate\"",
    "\"Understood\"",
    "\"Hmm, yes ...\"",
    "\"Ah\"",
    "\"Gotcha!\"",
    "\"Got it!\"",
    "Follower's response",
    "\"Interesting ...\"",
    "\"Gotcha\"",
    "\"The fog has lifted\"",
    "\"So it is\"",
    "\"Understood\"",
    "\"Ah, yes\"",
    "Words said with a nod",
    "\"Now you're making sense\"",
    "\"Got it\"",
    "\"Oh, right\"",
    "Therapist's reply",
    "\"Now it's clear\"",
    "\"Hmm ...\"",
    "Explanation follower",
    "\"Ahhh, O.K.\"",
    "\"Gotcha\"",
    "Neutral reaction to a revelation",
    "\"Ah\"",
    "\"Aha!\"",
    "\"So that's it!\"",
    "Therapist's comment",
    "\"Gotcha\"",
    "Comment when following someone",
    "\"Mm-hmm\"",
    "\"Aha!\"",
    "Words of understanding",
    "What \"video\" means literally",
    "\"Uh-huh\"",
    "\"Point taken\"",
    "Nodder's comment",
    "\"Gotcha\"",
    "Listener's acknowledgment",
    "\"Got it\"",
    "Words said with a nod",
    "\"Right\"",
    "\"Ah, yes\"",
    "\"It makes sense to me\"",
    "Opener for a crystal ball gazer",
    "\"Understood\"",
    "\"It's so obvious!\"",
    "\"Now it's clear!\"",
    "Comment made with a nod",
    "\"Oh, that's what you mean\"",
    "Illumination indication",
    "\"Got it\"",
    "\"That's clear\"",
    "\"Gotcha\"",
    "\"Mm-hmm\"",
    "\"Gotcha\"",
    "\"Mm-hmm, mm-hmm\"",
    "\"Got it\"",
    "Fortuneteller's opening",
    "Words of understanding",
    "\"Oh, uh-huh\"",
    "Psychiatrist's response",
    "Dawning response",
    "Shrink's statement",
    "\"Ah so!\"",
    "\"Aha!\"",
    "\"O-o-oh!\"",
    "\"Gotcha!\"",
    "\"Uh-huh\"",
    "Bit of shrink rap?",
    "Words of enlightenment",
    "\"Aha!\"",
    "\"Ah\"",
    "\"That's clear\"",
    "Start of a crystal gazer's statement",
    "\"O-o-oh\"",
    "\"Aha\"",
    "Conversational filler",
    "\"Yes, it's clear now\"",
    "Understanding words",
    "\"O.K.\"",
    "What a nod might mean",
    "Words with a nod",
    "\"Uh-huh\"",
    "\"Got it\"",
    "\"Gotcha\"",
    "Solicitous response",
    "\"Gotcha\"",
    "\"Now it all makes sense!\"",
    "\"Uh-huh\"",
    "\"Gotcha\"",
    "\"Gotcha\"",
    "Start of a fortuneteller's response",
    "Listener's response",
    "\"Ah, yes\"",
    "\"So that's what you mean\"",
    "Therapist's remark",
    "Response to revelations",
    "\"Gotcha\"",
    "Clairvoyant's opener",
    "\"Oh ...\"",
    "\"Amazing Grace\" ending",
    "Chin-stroking phrase",
    "\"Gotcha\"",
    "\"Mm-hmm\"",
    "\"Got it\"",
    "Shrink rap?",
    "\"Understood\"",
    "Visionary's words",
    "\"Oh\"",
    "\"It all comes clear\"",
    "Fortuneteller's phrase",
    "\"Gotcha\"",
    "Comment after the fog clears",
    "Psychiatrist's reply",
    "\"Oh, that's what you mean\"",
    "Words said with a nod",
    "Cry of epiphany",
    "\"Gotcha\"",
    "Shrink's reply",
    "\"Mm-hmm\"",
    "\"Understood!\"",
    "Words after \"hmmm\"",
    "Psychiatrist's response",
    "\"Gotcha\"",
    "Fortuneteller's opening",
    "Analyst's reply",
    "\"Now it's clear\"",
    "\"Gotcha\"",
    "\"Oh\"",
    "\"Understood!\"",
    "\"Now it's clear\"",
    "Words of understanding",
    "Psychiatrist's response",
    "Words said with a nod",
    "Fortuneteller's beginning",
    "\"Gotcha\"",
    "\"Oh, uh-huh\"",
    "\"That's clear\"",
    "Reply to an explanation",
    "Psychic's phrase",
    "?ôUnderstood?ö",
    "Therapist's response",
    "Chin stroker's words",
    "Understanding words",
    "Words said with a nod",
    "Clairvoyant's start",
    "\"Interesting\"",
    "\"Gotcha\"",
    "\"Of course!\"",
    "Psychiatrist's reply",
    "\"Aha!\"",
    "\"Gotcha\"",
    "Comment made with a nod",
    "\"Things are becoming clear\"",
    "\"Mm-hmm!\"",
    "\"Uh-huh\"",
    "\"Got you\"",
    "\"Uh-huh\"",
    "\"Clear as day\"",
    "\"Aha!\"",
    "Expression of understanding",
    "\"Ah!\"",
    "Words of understanding",
    "Words of understanding",
    "\"Now it's clear!\"",
    "Words of enlightenment",
    "Words of comprehension",
    "Psychiatrist's response",
    "Words of understanding",
    "Understanding words",
    "\"Uh-huh\"",
    "Words of comprehension",
    "\"Amazing Grace\" ending",
    "\"You're clear\"",
    "\"That's clear\"",
    "\"That explains it\"",
    "Response heard in 27-Across",
    "\"You showed me\"",
    "\"Peekaboo,___...\"",
    "\"Mmmmm\"",
    "Words of understanding",
    "Words of comprehension",
    "\"O.K.\"",
    "\"That's clear\"",
    "\"Gotcha\"",
    "Fortuneteller's start",
    "Words of comprehension",
    "Response to an explanation",
    "\"Now it's clear!\"",
    "\"Now it's clear\"",
    "Cousin of \"uh-huh\"",
    "Encouraging words from a student",
    "\"Yes, that's clear\"",
    "\"The More ___ You\" (1945 song)",
    "Phrase of understanding",
    "\"Got it\"",
    "\"Uh-huh\"",
    "Words of understanding",
    "\"___ you!\" (words in a tot's game)",
    "Sinking-in phrase",
    "Noncommittal response",
    "Words of enlightenment",
    "Understanding words",
    "\"Oh, that's what you mean!\"",
    "Words of comprehension",
    "\"So that's what you mean\"",
    "Words of enlightenment",
    "\"That's interesting\"",
    "\"Um-hmm\"",
    "Words of comprehension"
  ],
  "miso": [
    "Japanese soup ingredient",
    "Kind of soup often served at a sushi bar",
    "Sushi restaurant soup",
    "Traditional Japanese seasoning",
    "___ soup",
    "Soup to go with sushi",
    "Japanese stock holder",
    "Soup that might have a tofu cube in it",
    "___ soup",
    "Japanese soup",
    "Japanese soup",
    "___ soup",
    "Protein-rich paste",
    "Japanese soup",
    "Soup flavoring",
    "Soy-based soup",
    "Soup served at a sushi bar",
    "___ soup",
    "Kind of paste",
    "Sushi bar soup",
    "Soup with sushi",
    "___ soup",
    "Protein-rich soup",
    "Bowlful at a Japanese restaurant",
    "Sendai seasoning",
    "*___ soup",
    "Food made from fermented beans",
    "Soup with sushi",
    "___ soup",
    "___ soup (starter at a Japanese restaurant)",
    "Sushi bar soup",
    "Kind of soup at a Japanese restaurant",
    "Paste in Asian cookery",
    "Protein-rich paste",
    "Soy-based soup",
    "Japanese soup",
    "Soup that often contains tofu",
    "Japanese soup",
    "Soybean paste",
    "___ soup (sushi starter)",
    "Japanese paste",
    "Japanese soup",
    "Soup before sushi",
    "Japanese soup",
    "___ soup",
    "Soup with sushi",
    "High-protein paste",
    "Japanese soup",
    "Soybean paste",
    "Japanese soup",
    "Japanese soup",
    "Japanese soup",
    "Kind of soup"
  ],
  "enid": [
    "Children's author Blyton",
    "Children's author Blyton",
    "Oklahoma city",
    "Seat of Oklahoma's Garfield County",
    "Mystery writer Blyton",
    "Camelot lady",
    "Lady of Camelot",
    "Lady in Arthurian romance",
    "Arthurian heroine",
    "Blyton who wrote \"The Enchanted Wood\"",
    "Heroine of Tennyson's \"Idylls of the King\"",
    "Oklahoma city named for an \"Idylls of the King\" woman",
    "Woman's name that's a city in Oklahoma",
    "Geraint's love in \"Idylls of the King\"",
    "Children's author Blyton",
    "Children's author Blyton",
    "Lady in Arthurian legend",
    "Geraint's wife, in Arthurian romance",
    "Oklahoma city",
    "Tennyson's \"Geraint and ___\"",
    "Geraint's wife, in Arthurian legend",
    "It's an OK city",
    "Literary waiter",
    "Children's author Blyton",
    "Lady of Camelot",
    "Wife in Jonathan Franzen's \"The Corrections\"",
    "\"Idylls of the King\" woman",
    "Geraint's beloved, in Arthurian legend",
    "Seat of Oklahoma's Garfield County",
    "Woman's name that means \"eat\" backward",
    "Home of the Railroad Museum of Oklahoma",
    "Sooner city",
    "Oklahoma city",
    "Woman of Camelot",
    "Lady in \"Idylls of the King\"",
    "English author Blyton",
    "Sooner city",
    "City west of Tulsa",
    "\"Idylls of the King\" lady",
    "Camelot lady",
    "U.S. city named for a Tennyson character",
    "\"Idylls of the King\" woman",
    "Old-time actress Bennett",
    "So-called \"Wheat Capital of Oklahoma\"",
    "Geraint's wife, in Arthurian legend",
    "Oklahoma city",
    "\"Idylls of the King\" wife",
    "So-called \"Wheat Capital of the United States\"",
    "Woman's name that means \"eat\" backward",
    "City near Vance Air Force Base",
    "Oklahoma city",
    "Queen Wheat City of Oklahoma",
    "South-central U.S. city named for a woman in English literature",
    "\"Idylls of the King\" lady",
    "Geraint's wife in \"Idylls of the King\"",
    "\"Idylls of the King\" figure",
    "An OK city",
    "\"Idylls of the King\" lady",
    "Often-referenced but never-seen wife on \"Scrubs\"",
    "Sir Geraint's faithful wife",
    "Sir Geraint's wife, in Arthurian legend",
    "Children's author Blyton",
    "\"Idylls of the King\" woman",
    "Personification of purity, in literature",
    "She's a paradigm of patience",
    "Geraint's love, in Arthurian legend",
    "Oklahoma city",
    "City NNW of Oklahoma City",
    "English author Blyton",
    "Author Bagnold",
    "City founded during the Cherokee Strip land run",
    "Oklahoma city",
    "British author Bagnold",
    "Geraint's lady",
    "Oklahoma city",
    "Geraint's beloved",
    "Vance Air Force Base locale",
    "City along the Chisholm Trail",
    "Cherokee Strip city",
    "Home of the Chisholm Trail Expo Center",
    "City WNW of Stillwater",
    "English author Blyton",
    "County seat NNW of Oklahoma City",
    "Sooner city",
    "City along the Chisholm Trail",
    "Tennyson woman called \"the Fair\"",
    "Geraint's wife, in Arthurian legend",
    "Wife of Geraint",
    "Oklahoma city",
    "Vance Air Force Base site",
    "Patient wife of Sir Geraint",
    "\"National Velvet\" author Bagnold",
    "Author Bagnold",
    "Geraint's woman",
    "Patient wife of Sir Geraint",
    "Cherokee Strip city",
    "Author Bagnold",
    "Lady of Arthurian romance",
    "Markey of Tarzan films",
    "Friend of Guinevere, in \"The Idylls of the King\"",
    "City near Stillwater",
    "Northern Oklahoma city",
    "Oklahoma city",
    "Paradigm of patience",
    "City near Vance Air Force base",
    "City west of Tulsa",
    "In literature, the personification of purity",
    "\"Idylls of the King\" character",
    "Oklahoma county seat",
    "City west of Tulsa",
    "\"National Velvet\" author Bagnold",
    "Home of Vance Air Force Base",
    "Lady of \"Idylls of the King\"",
    "Woman with legendary patience",
    "Oklahoma city",
    "\"Idylls of the King\" figure",
    "\"National Velvet\" author Bagnold",
    "Oklahoma city",
    "Her patience is legendary",
    "\"Idylls of the King\" lady",
    "Novelist Bagnold",
    "Geraint's wife",
    "Oklahoma city",
    "Tennyson lady",
    "Camelot lady",
    "Writer Bagnold",
    "Seat of Garfield County, Okla.",
    "Mr. Meddle creator Blyton",
    "Arthurian lady",
    "Geraint's love",
    "\"National Velvet\" author Bagnold",
    "City near Vance Air Force Base",
    "Tennyson lady",
    "Author Bagnold",
    "An OK city",
    "\"Idylls of the King\" character",
    "\"National Velvet\" author Bagnold",
    "Novelist Bagnold",
    "English author Blyton",
    "Children's author Blyton",
    "Oklahoma city",
    "Sooner city",
    "City west of Tulsa",
    "Patient wife of literature",
    "Geraint's lady",
    "Vance Air Force Base locale",
    "Ladies called her \"the Fair\"",
    "Chisholm Trail town",
    "\"Idylls of the King\" woman",
    "Camelot lady",
    "Author Bagnold",
    "\"Idylls of the King\" lady",
    "Figure of Arthurian legend",
    "Sir Geraint's wife",
    "Writer Bagnold",
    "Oklahoma city",
    "\"Idylls of the King\" lady",
    "Children's author Blyton",
    "Chisholm Trail town",
    "Lady in Arthurian legend",
    "Site of Vance Air Force Base",
    "Arthurian lady",
    "Home of Phillips University",
    "Oklahoma city",
    "Oklahoma city",
    "Oklahoma city",
    "Writer Bagnold",
    "Oklahoma city",
    "Home of Phillips University",
    "Oklahoma city",
    "Oklahoma city",
    "Camelot lady",
    "Tennyson heroine",
    "Oklahoma town",
    "It's NNW of Oklahoma City",
    "Phillips University site",
    "Geraint's lady",
    "Author Bagnold",
    "Seat of Garfield County, Okla.",
    "Oklahoma city",
    "Children's author Blyton",
    "Sir Geraint's wife",
    "Oklahoma city",
    "Markey of \"Tarzan\"",
    "Arthurian lady",
    "Novelist Bagnold",
    "Camelot lady",
    "Novelist Bagnold",
    "Model of loyalty, in Arthurian legend",
    "Oklahoma city",
    "\"Idylls of the King\" lady",
    "Phillips University site",
    "Oklahoma city",
    "Phillips University site",
    "City west of Tulsa",
    "Tennyson heroine",
    "Author Blyton",
    "\"Tarzan\" actress Markey",
    "Geraint's wife",
    "Home of Phillips University",
    "Geraint's wife",
    "Tennyson heroine",
    "Geraint's beloved",
    "Geraint's beloved",
    "Geraint's ladylove",
    "Markey who played Tarzan's Jane",
    "Geraint's love",
    "Oklahoma city",
    "Novelist Bagnold",
    "Tennyson's \"Geraint and ___\"",
    "Arthurian lady",
    "Writer Bagnold"
  ],
  "nene": [
    "Bird with a reduplicative name",
    "Official bird of Hawaii",
    "Leakes of \"The Real Housewives of Atlanta\"",
    "Child, in Chile",
    "Hawaii's state bird",
    "Double birdie?",
    "World's rarest goose",
    "Protected Hawaiian bird",
    "State bird of Hawaii",
    "Creature that can walk on lava",
    "Aloha State bird",
    "State bird whose name sounds like its call",
    "Chilean child",
    "Relative of the Canada goose",
    "Hawaiian goose",
    "Endangered state bird",
    "Rarest state bird",
    "Bird with a resonant \"ha-wah\" call",
    "Bird so named because of its call",
    "Flier not found in 49 states",
    "Rarest of the 50 state birds",
    "15-Across symbol",
    "State symbol with a reduplicative name",
    "Hawaiian goose",
    "Hawaiian goose",
    "Hawaii's state bird",
    "Leakes of reality TV",
    "50th state's state bird",
    "Hawaii's state bird",
    "Protected bird in Hawaii",
    "Protected goose",
    "Hawaiian bird",
    "Rare goose",
    "Spanish baby",
    "Hawaiian goose",
    "Bird named for its call",
    "50th state's bird",
    "Hawaii's state bird",
    "Bird that may nest on volcanic ash",
    "Member of the familia",
    "New Mexican?",
    "Nonmigratory goose",
    "Endangered state bird",
    "Endangered state bird",
    "Bogota baby",
    "Baltimore oriole : Maryland :: ___ : Hawaii",
    "Hawaii's state bird",
    "Protected state bird",
    "One flying over Hawaii",
    "Bird whose name sounds like its soft call",
    "Gray-brown goose",
    "Hawaii's state bird",
    "Hawaiian goose",
    "___ Hilario, Brazilian-born N.B.A. star",
    "Sight on Hawaiian lava flows",
    "Spanish child",
    "Protected state bird",
    "Hawaii's state bird",
    "Hawaii's state bird",
    "Rare bird",
    "Hawaii's state bird",
    "State bird of Hawaii",
    "Endangered goose",
    "Hawaiian goose",
    "Lava geese",
    "Endangered goose",
    "Hawaii's state bird",
    "Island bird",
    "Hawaiian bird",
    "Wild goose",
    "State bird of Hawaii",
    "Hawaiian goose",
    "Aloha State bird",
    "Aloha State bird",
    "Bird so-called from its call",
    "Goose seen on Kauai",
    "Bird named for its call",
    "Endangered state bird",
    "Hawaiian bird",
    "Hawaii's state bird",
    "Hawaiian goose",
    "Madre's baby",
    "It's protected in Hawaii",
    "Hawaiian honker",
    "State bird of Hawaii",
    "Hawaiian honker",
    "Hawaii's state bird",
    "Madre's little one",
    "Hawaiian goose",
    "Hawaii's state bird",
    "Hawaii's state bird",
    "Gray-brown goose",
    "Hawaii's state bird",
    { "formula": "+Gray-brown goose", "result": { "error": "#NAME?" } },
    "Hawaiian goose",
    "Gray-brown goose",
    "Oahu goose",
    "Endangered state bird",
    "Hawaiian state bird",
    "Hawaiian goose",
    "Pacific goose",
    "Endangered goose",
    "Hawaii's state bird",
    "Hawaiian goose",
    "Island bird",
    "Hawaiian honker",
    "Hawaii's state bird",
    "Endangered goose",
    "Hawaiian honker",
    "Hawaiian state bird",
    "Hawaiian hen"
  ],
  "clan": [
    "Big family",
    "The McCoys, e.g.",
    "Extended family",
    "What a coat of arms might represent",
    "Extended family",
    "Tribal unit",
    "Extended family",
    "Group with a tartan",
    "Extended family",
    "Family",
    "Group sharing a tartan",
    "Family",
    "Whole extended family",
    "Kiltie's group",
    "Hatfields or McCoys",
    "Blood group?",
    "Large family",
    "Extended family",
    "Feud side",
    "Group sharing a coat of arms",
    "Genealogist's study",
    "Group sharing a coat of arms",
    "Extended family",
    "Family group",
    "It's all relatives",
    "Reunion group",
    "Extended family",
    "Reunion gatherers",
    "Family",
    "Reunion gatherers",
    "Genealogist's study",
    "Family",
    "Extended family",
    "Ones sharing a crest",
    "Extended family",
    "Kiltie's group",
    "The Kennedys, e.g.",
    "Tartan sporters",
    "The Kennedys, e.g.",
    "Family group",
    "Reunion group",
    "Tartan-wearing group",
    "Tartan sporters",
    "House",
    "Extended family",
    "Reunion group",
    "The McCoys, for one",
    "Totem's representation",
    "Family group",
    "Family",
    "\"Braveheart\" group",
    "The Kennedys, e.g.",
    "Tartan representation",
    "Kinsmen",
    "MacGregor, to Rob Roy",
    "Extended family",
    "Hatfields or McCoys, e.g.",
    "The Hatfields or the McCoys",
    "Scotch family",
    "Kennedy, for one",
    "MacGregor, for one",
    "Macgregor, for Rob Roy",
    "Family",
    "MacGregor, e.g.",
    "Reunion group",
    "Family",
    "Auel subject",
    "Kinfolk",
    "The McCoys, e.g.",
    "Fraternity",
    "Scottish group"
  ],
  "lint": [
    "It's full of fiber",
    "Bellybutton fluff",
    "Dryer residue",
    "You may use a roller to get rid of it",
    "Fluff pieces",
    "Kind of trap",
    "Dryer buildup",
    "Something to brush off a jacket",
    "Dryer screen buildup",
    "Screen buildup",
    "Dryer fuzz",
    "___ trap (part of a dryer)",
    "___ trap",
    "What a brush may pick up",
    "Fluff",
    "Fabric fluff",
    "Fluffy stuff caught in the dryer",
    "Navel buildup",
    "Fluffy stuff",
    "Bit of fluff",
    "It might get rolled off",
    "Dryer remains",
    "Makeup of some little balls",
    "It may be caught in a trap",
    "Bits of fluff",
    "Trap buildup",
    "Receiver of a brush-off?",
    "Pocket particles",
    "It might get the brush-off",
    "Fabric fuzz",
    "Trap contents",
    "It's trapped indoors",
    "___ remover",
    "Buildup on a suit jacket",
    "It's trapped on laundry day",
    "It may be caught in a trap",
    "Jacket buildup",
    "Unwanted buildup",
    "Dryer buildup",
    "Suit jacket buildup",
    "Navel buildup?",
    "Kind of trap",
    "Fuzz",
    "Fuzz",
    "Navel buildup",
    "It may get the brush-off",
    "Fluff",
    "Kind of screen",
    "Fluff",
    "Fluff",
    "Scourge of serge",
    "Reason for a brushoff?",
    "Dryer detritus",
    "Material used to dress wounds",
    "Pocket liner",
    "Fuzz",
    "It gets a brushoff",
    "Dryer residue",
    "Cling-on",
    "Fuzz",
    "Dryer residue",
    "Bit of fluff",
    "Fluff"
  ],
  "ores": [
    "Rock group",
    "Things assayers assay",
    "They're searched for in a rush",
    "Smelt things?",
    "Raw materials",
    "Rock groups",
    "Metal deposits",
    "Smelter's inputs",
    "Gold and silver have them, but not bronze",
    "Many end in \"-ite\"",
    "Miners' finds",
    "Galena and sphalerite",
    "Magnetite and cassiterite",
    "Lucky strikes?",
    "Some crust contents",
    "Unrefined cartfuls",
    "Metal-containing rocks",
    "Smeltery imports",
    "Subjects of tests for purity",
    "They're mined and refined",
    "Bauxite and others",
    "Raw metals",
    "Mine extractions",
    "Things assayers assay",
    "Valuable rocks",
    "Things pulled out of seams",
    "Miner matters",
    "Bonanza discoveries",
    "Raw materials",
    "Get a lode of these!",
    "Contents of some dollies",
    "Rocks whose names often end in \"-ite\"",
    "What assayers assay",
    "Shipments to smelteries",
    "They're mined and refined",
    "What you might take your lead from?",
    "Mine finds",
    "Extraction targets",
    "They may come with silver or gold",
    "Metal containers",
    "Iron and tin sources",
    "They're found in veins",
    "Magnetite and bauxite",
    "They're graded in geology class",
    "They're unrefined",
    "Beryl and bornite",
    "Underground rock bands?",
    "Metals from lodes",
    "They're not refined",
    "Bauxite and magnetite",
    "Contents of veins",
    "Underground metals",
    "Minerals to be processed",
    "Metalliferous rocks",
    "Mined rocks",
    "Metal containers?",
    "Veins' contents",
    "Some extracts",
    "Mineralogists' study",
    "Sought-after rock group?",
    "Some tram loads",
    "Magnetite and others",
    "Contents of some ledges",
    "Certain bank deposits",
    "Lode finds",
    "Assaying samples",
    "Sources of iron and manganese",
    "Metals from lodes",
    "Claim findings",
    "They may need refining",
    "They're dug out of the ground",
    "Seams' contents",
    "Things picked out of the earth",
    "Buried treasures",
    "They're found in rock bands",
    "Lucky strikes?",
    "Rich rocks",
    "They're often lying in beds",
    "Smelted substances",
    "Sources of gold, e.g.",
    "Tram loads",
    "Contents of some deposits",
    "Gemstone sources",
    "Some have a silver lining",
    "Valuable deposits",
    "Tram loads",
    "Mineralogist's sample set",
    "Assayers' samples",
    "Buried treasures",
    "They may hold the lead",
    "Groundbreaking discoveries?",
    "Sources of nickel, e.g.",
    "Miners' finds",
    "Tram loads",
    "Smeltery input",
    "Some bank deposits",
    "Mineralogists' samples",
    "Bauxite and others",
    "Tram loads",
    "Lode stones",
    "Mined rocks",
    "Graded materials",
    "Metallurgists' supplies",
    "They need refinement",
    "Underground treasures",
    "Claim valuables",
    "Mine finds",
    "Underground deposits",
    "Diggers' discoveries",
    "They lack refinement",
    "They can be refined",
    "Some rocks",
    "Tram loads",
    "Mine finds",
    "Panners' finds",
    "Valuable rocks",
    "Vein contents",
    "Tram loads",
    "Metals from the earth",
    "Meteor materials",
    "Things tunnelers look for",
    "Refinement targets",
    "Assayers' stuff",
    "They may be driven to extraction",
    "Ones in need of refinement",
    "Subjects of assays",
    "Miners' finds",
    "Blast furnace input",
    "Valuable deposits",
    "Vein pursuits?",
    "Tram loads",
    "They're found in veins",
    "Miners' finds",
    "Loads from lodes",
    "Metallic rocks",
    "They're often unearthed",
    "They're found in streaks",
    "They come out at the seams",
    "They're found in veins",
    "Valuable deposits",
    "These can be found in pockets",
    "Assayers' samples",
    "Rock groups?",
    "Raw materials",
    "See 9-Down",
    "They come out at the seams",
    "Malachite and others",
    "Assayers' stuff",
    "Raw metals",
    "They could use refinement",
    "They're found in veins",
    "Bonanza finds",
    "Metal containers",
    "Bauxite and others",
    "They're found in layers",
    "Smeltery materials",
    "Bonanza finds",
    "They may be picked out",
    "Metal containers",
    "They're not refined",
    "They're not refined",
    "Mine finds",
    "Lucky strikes",
    "Bonanza finds",
    "Vein pursuits",
    "Vein yields",
    "Mine extracts",
    "Loads from lodes",
    "Mesabi Range yield",
    "Mined metals",
    "They're found in prospectors' sectors",
    "Metallurgist's stock",
    "Cinnabar, taconite, etc.",
    "Metallurgists' studies",
    "Products of a 16-Across",
    "Smelter's stuff",
    "They lack refinement",
    "Some train cargoes",
    "Smeltery input",
    "Cinnabar et al.",
    "Raw material",
    "They're found in the ground",
    "Copper containers",
    "Mineral rocks",
    "Some search for these in vein",
    "Mine rocks",
    "Blast furnace input",
    "Boxcar cargoes",
    "Bank deposits",
    "Gold holders",
    "Mineral rocks",
    "Mine rocks",
    "Miner's quest",
    "Silver containers",
    "Loads from lodes",
    "Miner matters",
    "Magnetite and malachite",
    "Assayers assay them",
    "Cinnabar and barite",
    "Galena and mispickel",
    "Raw metals",
    "Bauxite and others",
    "Silver holders",
    "Miner profits?",
    "Vein contents",
    "Rock group",
    "Vein contents"
  ],
  "gtos": [
    "Some muscle cars",
    "Classic Pontiac muscle cars",
    "Classic Pontiac sports cars",
    "Classic muscle cars",
    "1960s band with a car-related name, with \"the\"",
    "Muscle cars of the '60s",
    "Old Pontiacs",
    "Vintage Pontiacs",
    "Sporty Pontiacs introduced in the '60s",
    "Classic Pontiacs",
    "Some Pontiacs",
    "Sporty Pontiacs of old",
    "Old sporty Pontiacs",
    "Old muscle cars",
    "Onetime Pontiac muscle cars",
    "Classic Pontiacs",
    "Some Pontiacs",
    "Onetime Road Runner rivals",
    "Classic muscle cars",
    "Classics with 389 engines",
    "Classic muscle cars",
    "Old muscle cars",
    "Classic muscle cars",
    "Classic muscle cars",
    "Bygone muscle cars",
    "Classic Pontiacs",
    "Old Pontiac muscle cars",
    "\"Three deuces and a four-speed\" cars of old",
    "1960s band with a car-related name, with \"the\"",
    "Some Ferraris",
    "Old Pontiacs",
    "Classic Pontiac muscle cars",
    "Old Pontiac muscle cars",
    "Pontiacs of old",
    "Bygone muscle cars",
    "Classic cars with 389 engines",
    "Classic muscle cars",
    "Sporty cars",
    "Some 1960's coupes",
    "Old cars with 389 engines",
    "Mustang competitors",
    "Motor Trend's 1968 Cars of the Year",
    "Joys for hot-rodders, once",
    "New cars of '64",
    "Old Corvette competitors",
    "Classic cars that were the first to have Ram Air engines",
    "1960's-70's Pontiacs",
    "Mustang racers, once?",
    "Sporty 60's cars",
    "Pontiac classics",
    "Old Pontiacs",
    "Old Pontiacs",
    "Old cars with Turbo-Hydramatic gearboxes"
  ],
  "shel": [
    "Poet Silverstein",
    "Writer/illustrator Silverstein",
    "Children's poet Silverstein",
    "Silverstein who wrote and illustrated \"The Giving Tree\"",
    "Children's author Silverstein",
    "Writer/illustrator Silverstein",
    "Author Silverstein",
    "Cartoonist Silverstein",
    "First name in children's literature",
    "Poet/illustrator Silverstein",
    "Poet Silverstein",
    "Silverstein who wrote \"A Boy Named Sue\"",
    "Author Silverstein",
    "Silverstein who wrote \"A Boy Named Sue\"",
    "Silverstein who wrote \"A Boy Named Sue\"",
    "Writer/illustrator Silverstein",
    "Children's author Silverstein",
    "Children's author Silverstein",
    "Silverstein of kid-lit",
    "Author Silverstein",
    "Record producer Talmy",
    "Silverstein who wrote \"The Giving Tree\"",
    "Poet/illustrator Silverstein",
    "\"The Giving Tree\" author Silverstein",
    "Author Silverstein",
    "Author Silverstein",
    "Silverstein of children's literature",
    "Writer/illustrator Silverstein",
    "\"Where the Sidewalk Ends\" author Silverstein",
    "Silverstein who wrote and illustrated \"The Giving Tree\"",
    "Writer/illustrator Silverstein",
    "Illustrator Silverstein",
    "Author Silverstein",
    "Writer Silverstein",
    "Poet/cartoonist Silverstein",
    "Author Silverstein",
    "Author Silverstein",
    "His kids' book \"Falling Up\" is dedicated to his son Matt",
    "Author Silverstein",
    "Author/illustrator Silverstein",
    "Rock producer Talmy",
    "Author Silverstein",
    "Author Silverstein",
    "Illustrator Silverstein",
    "Author/illustrator Silverstein",
    "Author Silverstein",
    "Author Silverstein",
    "Cartoonist Silverstein",
    "Cartoonist Silverstein",
    "Illustrator Silverstein",
    "Illustrator Silverstein",
    "Author Silverstein",
    "Illustrator Silverstein",
    "Writer Silverstein",
    "Illustrator Silverstein",
    "Satirist Silverstein",
    "Satirist Silverstein",
    "Writer Silverstein",
    "Cartoonist Silverstein",
    "Artist / author Silverstein",
    "Cartoonist Silverstein"
  ],
  "bode": [
    "Presage",
    "Foretell",
    "Foreshadow",
    "Portend",
    "Foretell",
    "Be an omen of",
    "Omen",
    "Portend",
    "Warn of",
    "Seem to indicate",
    "Foreshadow",
    "Portend",
    "Presage",
    "Suggest for the future",
    "Augur",
    "Portend",
    "Point to",
    "Be an omen of",
    "Portend",
    "Portend",
    "Portend",
    "Be ominous",
    "Portend",
    "Be a sign of",
    "Be an omen of",
    "Foreshadow",
    "Point to",
    "Augur",
    "Portend",
    "Portend",
    "Portend",
    "Portend",
    "Presage",
    "Presage",
    "Be an omen of",
    "Foreshadow",
    "Foretoken",
    "Foretell"
  ],
  "noli": [
    "___ me tangere (Latin warning)",
    "___ me tangere (warning against meddling)",
    "___ me tangere (touch-me-not)",
    "___ me tangere (touch-me-not)",
    "___ me tangere (something off-limits)",
    "Do not, in old Rome",
    "___ me tangere",
    "___ me tangere",
    "___ me tangere",
    "___ me tangere"
  ],
  "hail": [
    "Give kudos to",
    "Greet grandly",
    "Bad fall?",
    "Give kudos to",
    "Come down hard?",
    "Greet rapturously",
    "Greet with acclaim",
    "Loudly acclaim",
    "Cold shower?",
    "Weather forecast that's hard to predict?",
    "Warmly welcome",
    "With 37-Across, desperate attempts",
    "Caesar's predecessor?",
    "Come (from)",
    "Word repeated before \"the gang's all here\"",
    "Signal, as a cab",
    "Greet loudly",
    "When repeated, exuberant cry",
    "\"___ Caesar!\"",
    "Precipitation that may be the size of golf balls",
    "Approve enthusiastically",
    "Precipitation that can leave dents",
    "Applaud",
    "Stones from the sky",
    "Acclaim",
    "Applaud",
    "Hearty hello",
    "\"Huzzah!,\" e.g.",
    "Salute",
    "Flag down",
    "It comes down hard",
    "Icy pellets",
    "Come (from)",
    "It falls hard",
    "It's hard on the head",
    "Hearty hello",
    "Nasty fall?",
    "Honor, as a conquering hero",
    "Salute with enthusiasm",
    "Hard rain?",
    "Flag down a cab",
    "Salute",
    "Huzzah",
    "Call",
    "Ice pellets",
    "Storm pellets",
    "Flag down",
    "Rain hard?",
    "Stormy greeting?",
    "\"___, Caesar!\""
  ],
  "lads": [
    "Some pubgoers",
    "Blokes",
    "Little fellows",
    "Guys",
    "Young chaps",
    "See 6-Down",
    "Fellas",
    "Some young 'uns",
    "Striplings",
    "Young fellows",
    "Young fellows",
    "Buckos",
    "The Beatles during Beatlemania, e.g.",
    "Tom and Huck, e.g.",
    "Schoolboys",
    "Little shavers",
    "Eton students, e.g.",
    "Some sprouts",
    "Early Beatles, affectionately",
    "Chaps",
    "Boys",
    "Young fellows",
    "Boys",
    "Little shavers",
    "Pubmates",
    "Pip and others",
    "Pubmates",
    "Boys",
    "Cub scouts",
    "Shavers",
    "Ones wearing knickers",
    "Chaps",
    "Boys",
    "Shavers",
    "Boys",
    "Striplings",
    "Youngsters",
    "Chaps",
    "Fellas",
    "Chaps",
    "Sonnies",
    "Young 'uns",
    "Youngsters",
    "Chaps",
    "Young fellows",
    "Lasses' mates",
    "Lassies' partners"
  ],
  "bibi": [
    "Nickname in Israeli politics",
    "Longest-serving Israeli prime minister, familiarly",
    "Israel's Netanyahu, familiarly",
    "Benjamin Netanyahu's nickname",
    "Israel's Netanyahu, informally",
    "Nickname of an Israeli leader",
    "Benjamin Netanyahu's nickname",
    "Actress Andersson",
    "Israel's Netanyahu, informally",
    "Mideastern P.M.'s nickname",
    "Moniker for Israel's Netanyahu",
    "Andersson of \"Persona\"",
    "Nickname for Netanyahu",
    "Nickname of Israel's Netanyahu",
    "Andersson of \"Wild Strawberries\"",
    "Israel's Netanyahu, familiarly",
    "Actress Andersson of \"Persona\"",
    "Actress Andersson of \"I Never Promised You a Rose Garden\"",
    "Actress Osterwald",
    "Israel's Netanyahu, familiarly",
    "Netanyahu, informally",
    "Netanyahu of Israel"
  ],
  "econ": [
    "Milton Friedman's subj.",
    "Fiscal subj.",
    "Nobel Prize subj.",
    "Subj. for the 2009 Nobel Prize winner Elinor Ostrom, the first woman to receive it",
    "M.B.A. class",
    "Micro or macro class",
    "H.S. class",
    "Monopoly subj.",
    "Word aptly found in \"price control\"",
    "Business class, for short",
    "M.B.A. class subj.",
    "Subj. for an M.B.A. student",
    "M.B.A. prereq.",
    "Supply-and-demand subj.",
    "Short course in supply and demand?",
    "Nobel Prize subj.",
    "Taxing subject, briefly?",
    "Class for a future M.B.A.",
    "Future C.P.A.'s study",
    "Supply-and-demand subj.",
    "Major before going to B-school, maybe",
    "\"The Wealth of Nations\" subj.",
    "\"Micro\" and \"macro\" subject, for short",
    "Nobel Prize subj.",
    "Field of competition, for short?",
    "Supply-and-demand subj.",
    "Field of productivity, briefly",
    "\"Micro\" or \"macro\" subj.",
    "Supply-and-demand subj.",
    "Subject for Karl Marx, for short",
    "C.P.A.'s study",
    "Nobel Prize subj.",
    "\"Micro\" and \"macro\" subject, for short",
    "Elasticity studier's subj.",
    "One of the social sciences, for short",
    "Supply-and-demand subj.",
    "Nobel category: Abbr.",
    "Subj. concerned with booms, crashes and panics",
    "M.B.A. subj.",
    "Business school subj.",
    "Supply-and-demand subj.",
    "Growth theory subj.",
    "Nobel Prize subj.",
    "Future C.P.A.'s study",
    "Big campaign topic: Abbr.",
    "Subj. for a Fed chairman",
    "Depression specialist's subj.",
    "Study of some bubbles?: Abbr.",
    "Subj. with graphs",
    "Nobel Prize subj.",
    "H.S. class",
    "Class with graphs, for short",
    "Business major subj.",
    "Nobel Prize category: Abbr.",
    "Coll. major",
    "College course, briefly",
    "Capital subj.",
    "Capital subj.",
    "Sch. course with graphs",
    "Subj. that includes monetary policy",
    "Coll. major",
    "Supply-and-demand subj.",
    "Monopoly subj.",
    "Business subj.",
    "Supply-and-demand subj.",
    "College major, briefly",
    "Business class, briefly",
    "Supply-and-demand subj.",
    "H.S. course",
    "College course, briefly",
    "Supply-and-demand subj.",
    "High-school class, informally",
    "Supply-and-demand subj.",
    "Supply-and-demand subj.",
    "High school class, informally",
    "Coll. major",
    "Nobel Prize subj.",
    "Subj. with graphs",
    "Part of E.E.C.: Abbr.",
    "B-school subj.",
    "Eliot Janeway subj.",
    "Supply-and-demand subj.",
    "Supply-and-demand subj.",
    "Supply-and-demand subj.",
    "The Fed's concern: Abbr.",
    "Nobel Prize subj.",
    "Milton Friedman's subj.",
    "A social sci.",
    "Subj. with graphs",
    "Subj. studied for an M.B.A.",
    "Supply-and-demand subj.",
    "Supply-and-demand subj.",
    "Nobel Prize subj.",
    "Supply-and-demand subj.",
    "Study of G.D.P. and such",
    "Supply-and-demand subj.",
    "Coll. major",
    "Supply-and-demand subj.",
    "H.S. class",
    "Greenspan concern: Abbr.",
    "College major, slangily",
    "Coll. major",
    "Course with charts: Abbr.",
    "College major, for short",
    "Supply-and-demand subj.",
    "Nobel Prize subj.",
    "Subj. for an M.B.A.",
    "37-Down's concern: Abbr.",
    "Budget subj.",
    "Capital subj.",
    "Subj. for an M.B.A.",
    "Class with a Paul Samuelson text",
    "Nobel Prize category: Abbr.",
    "High school class, informally",
    "College major, slangily",
    "Class in which Keynes is taught",
    "College major, informally",
    "Subj. for Milton Friedman",
    "Coll. course",
    "Coll. course"
  ],
  "text": [
    "It might end in an emoji",
    "Bit of typing by someone who's all thumbs?",
    "Send emojis, say",
    "Actual wording",
    "Reach out with one's hands?",
    "Word with book or box",
    "Scripture",
    "Be all thumbs?",
    "Unusual feature of 68-Across",
    "\"___ me\" (modern parting words)",
    "One component of a data plan",
    "Part of a phone plan",
    "Not a good thing to do while driving",
    "It might contain an emoji",
    "\"omg\" or \"lol,\" say",
    "Exchange between cell mates?",
    "Emoji holder",
    "Class work",
    "Part of 5-Down",
    "Message with an emoji, maybe",
    "Emoticon medium",
    "\"r u there?,\" e.g.",
    "Message from one who's all thumbs?",
    "\"How r u?,\" e.g.",
    "\"u r so funny ... lmao,\" e.g.",
    "Be all thumbs as a writer?",
    "Phone-to-phone communication",
    "Do a driver's no-no",
    "Cell composition",
    "It's dangerous to do while driving",
    "Modern communication",
    "Send a message via cell phone",
    "Send a quick update, in a way",
    "Communicate like many teens",
    "Communication that might include \"OMG\" and \"TTYL\"",
    "Bible reading",
    "Communicate with without speaking",
    "Transcript",
    "Write on a BlackBerry, maybe",
    "___ message",
    "Communicate with modern-style",
    "Send a high-tech message",
    "Preacher's reading",
    "Student's book",
    "___ messaging (modern communication)",
    "Send a message to, these days",
    "Plain writing",
    "Sermon's basis",
    "What's read",
    "Words",
    "Document content",
    "Source material",
    "Church reading",
    "Student's book",
    "TelePrompTer filler",
    "Words",
    "Sermon basis",
    "Just one's type?",
    "Teleprompter filler",
    "College book",
    "Editor's concern",
    "TelePrompTer display",
    "TelePrompTer filler",
    "Student's purchase",
    "Student's book",
    "Sermon passage",
    "Libretto",
    "Sermon basis",
    "Transcript",
    "Subject",
    "Sermon starter",
    "Sermon's basis",
    "Words",
    "Words",
    "Words",
    "Copy",
    "Not pictures",
    "Not the pictures"
  ],
  "elbe": [
    "River through Bohemia",
    "North Sea feeder",
    "River that formed part of the border between East and West Germany",
    "Dresden's river",
    "River into which the Vltava flows",
    "River to the North Sea",
    "Czech Republic river",
    "North Sea feeder",
    "Dresden's river",
    "German river to the North Sea",
    "German river where American and Soviet forces met in 1945",
    "River through Dresden",
    "Hamburg's river",
    "Hamburg's river",
    "Hamburg's river",
    "River of Saxony",
    "Dresden's river",
    "Hamburger's course?",
    "River forming the eastern border of Charlemagne's empire",
    "North Sea feeder",
    "Hamburg's river",
    "Hamburg's river",
    "Hamburg's river",
    "River whose name means \"river\"",
    "Hamburg's river",
    "River to the North Sea",
    "Dresden's river",
    "Wittenberg's river",
    "German river to the North Sea",
    "Hamburg's river",
    "River to the North Sea",
    "Hamburg's river",
    "North Sea feeder",
    "North Sea tributary",
    "Hamburg's river",
    "Dresden's river"
  ],
  "rail": [
    "Skateboarder's apparatus",
    "Train travel",
    "Skate park feature",
    "Protest loudly",
    "Transportation option",
    "Complain loudly",
    "Train transportation",
    "A transcontinental traveler might go by this",
    "Third one's a harm?",
    "Public transit option",
    "Inner part of a racetrack",
    "Billiards feature",
    "Billiards cushion",
    "Skatepark fixture",
    "Skate park fixture",
    "Commuter option",
    "Train transportation",
    "Complain loudly",
    "Feature at a horse track",
    "Transit option",
    "Banister, e.g.",
    "Stairway safety feature",
    "Subway support",
    "Trains",
    "Hospital bed feature",
    "Method of shipping goods",
    "Travel option",
    "Skateboard park feature",
    "Train travel",
    "Transportation mode",
    "Bunk bed feature",
    "Vituperate",
    "Half a train track",
    "The third one is a shocker",
    "Staircase aid",
    "Train transportation",
    "Skateboard trickster's track",
    "Train track part",
    "Banister",
    "Transportation option",
    "Commuter's option",
    "Complain bitterly",
    "Train travel",
    "Stairway part",
    "Commuter option",
    "Pedestrian safety feature",
    "Fulminate (against)",
    "Alternative to air",
    "Bitterly complain",
    "Symbol of thinness",
    "Commuter's choice",
    "Fence part",
    "Local supporter?",
    "Hand support",
    "Long-toed bird with a harsh cry",
    "Transportation option",
    "Racetrack fence",
    "Train transport",
    "Barroom foot rest",
    "Safety installation",
    "Pool table part",
    "Commuter option",
    "Racetrack fence",
    "Commuting option",
    "Marsh denizen",
    "Object bitterly",
    "Alternative to air or highway",
    "Track",
    "Means of shipping",
    "Fulminate",
    "Stairwell item",
    "Shipping method",
    "Commuter line",
    "Marsh resident",
    "Amtrak transportation",
    "Part of a scenic overlook",
    "Pedestrian aid",
    "48-Across's specialty",
    "Bus or air alternative",
    "Scold harshly, with \"at\"",
    "Commuter line",
    "Seasick sailor's support",
    "Train track",
    "Air alternative",
    "Track on the ground",
    "Denunciate, with \"at\"",
    "Banister",
    "Epitome of thinness",
    "Train transportation",
    "Billiard cushion",
    "Inveigh (against)",
    "Tout's place",
    "Banister",
    "Form of transportation",
    "Amtrak travel",
    "Kind of fence",
    "Epitome of thinness",
    "Marsh bird",
    "Race track feature",
    "Tout's post"
  ],
  "sell": [
    "Successfully convince",
    "Word after hard or before short",
    "Convince",
    "Fly off the shelves",
    "Promote",
    "Wads are made to do this",
    "Convince",
    "Convince",
    "Move like a bear",
    "___-by date",
    "Hawk",
    "What bears do in the market",
    "Convince",
    "Bear's advice",
    "Unload, say",
    "Persuade",
    "Go along with a bear market",
    "Bear market order",
    "Persuade",
    "Buy's opposite",
    "Vend",
    "Buy's opposite",
    "Move through a market",
    "Dump, say",
    "Bear's cry",
    "Trader's option",
    "Persuade",
    "Buy's opposite",
    "Bears do it",
    "What bears do",
    "Bear's warning",
    "Order in a bear market",
    "Bears do it",
    "Hawk, as wares",
    "Auction",
    "Dump, e.g.",
    "Peddle",
    "Order in a bear market",
    "Unload",
    "Convince",
    "Auction off",
    "Dump, as stock",
    "Leave the shelves",
    "Hawk",
    "Hawk",
    "Broker's advice, at times",
    "Peddle",
    "Bear's cry",
    "Cut one's losses, maybe",
    "Unload, as stock",
    "Order to a broker",
    "Unload, as stock",
    "Succeed at pitching",
    "Bear's warning",
    "It may be hard or soft",
    "Behave like a bear",
    "What bears do",
    "Pitch",
    "Convince",
    "Market",
    "Auction off",
    "Convince",
    "Talk up",
    "Hawk",
    "Unload, on Wall Street",
    "Peddle",
    "Be a successful pitcher",
    "Bear's order, on Wall Street",
    "Push, maybe",
    "Hawk",
    "Order to a broker",
    "Purvey",
    "Unload, as stock",
    "Peddle",
    "Convince",
    "Wall Street order",
    "Wall Street order",
    "Trader's shout",
    "Bears do it",
    "Unload, so to speak",
    "Deal in",
    "Auction off",
    "Wall Street order",
    "Broker's tip, perhaps",
    "Successfully persuade",
    "Bears do it",
    "Hard or soft approach",
    "Wall Street order"
  ],
  "load": [
    "Important calculation for a weightlifter",
    "Whites or darks for laundry, say",
    "Amount for the washer or dryer",
    "Lights or darks, e.g.",
    "Truckful",
    "Washing machine unit",
    "Boatful",
    "Washer/dryer unit",
    "Laundry basketful",
    "Laundry basketful",
    "Truckful",
    "Burden",
    "Laundry unit",
    "Put on a truck, say",
    "Mutual fund consideration",
    "18 credits for a semester is a heavy one",
    "Laundry unit",
    "Amount a washing machine holds",
    "Washerful",
    "Washing machine contents",
    "Work assignment",
    "Bridge designer's concern",
    "Amount in the back of a pickup, e.g.",
    "Washer/dryer unit",
    "\"That's a ___ off my mind!\"",
    "Mutual fund charge",
    "Weight or freight",
    "Laundry unit",
    "Whites or colors, e.g.",
    "Ready to be fired",
    "Washerful",
    "Whites or darks, say",
    "Dishwasherful",
    "Cargo",
    "Put shells in",
    "Basketful",
    "Ton of bricks, e.g.",
    "Put on",
    "Mutual fund fee",
    "Basketful, say",
    "Put into memory",
    "Stick a shell in",
    "Put film into",
    "Dumptruckful",
    "Truckful",
    "Whites or darks, e.g.",
    "Fill the chambers, say",
    "Passel",
    "Washerful",
    "1-Down unit",
    "Amount of work",
    "Trunkful",
    "Cartful",
    "Mutual fund fee",
    "Basketful",
    "Burden",
    "Washerful",
    "Heap (on)",
    "Truckful",
    "Dumptruckful",
    "\"Get a ___ of that!\"",
    "Cargo",
    "Jam-pack",
    "Weight, as on the mind",
    "Fill, as bases",
    "Trucker's amount",
    "Put ammo in",
    "Burden",
    "Washerful"
  ],
  "apps": [
    "Galaxy array",
    "TikTok and Zoom, for two",
    "Tinder and others",
    "Some downloads",
    "TikTok and Fitbit, for two",
    "First courses, for short",
    "Array on a screen",
    "Instagram and Pinterest, for two",
    "24-Down and others",
    "iPhone downloads",
    "Google Play buys",
    "Lyft and Snapchat",
    "iPhone purchases",
    "Downloadable programs",
    "Most newspapers have them nowadays",
    "Pandora and others",
    "Smartphone downloads",
    "Google Play purchases",
    "Instagram and others",
    "Pandora and others",
    "iPad purchases",
    "Smartphone downloads",
    "Pandora and Spotify",
    "iPad downloads",
    "Tinder and others",
    "Facebook and others",
    "Android purchases",
    "iPhone purchases",
    "Developers work on them",
    "iTunes Store purchases",
    "Subjects of frequent updates",
    "Many games nowadays",
    "Downloadable programs",
    "Droids have them",
    "Downloads for mobile devices",
    "Computer downloads, informally",
    "Some downloads",
    "iPad users' purchases",
    "Downloads for tablets",
    "Many downloads",
    "Mobile downloadables",
    "Modern-day tech purchases",
    "iPhone programs",
    "iPad downloads",
    "iPhone downloads",
    "Computer programs, for short",
    "Some programs, briefly",
    "PC suite components",
    "Computer programs, briefly",
    "Computer programs, for short",
    "Some programs, briefly"
  ],
  "hora": [
    "Dance around?",
    "Rhyme for \"menorah\" in \"Hanukkah, Oh Hanukkah\"",
    "Dance sometimes done to klezmer music",
    "Dance at a Jewish wedding",
    "Circular wedding dance",
    "Wedding ring",
    "Dance at Jewish weddings",
    "Dance at a Jewish wedding",
    "Bat mitzvah dance",
    "Jewish wedding dance",
    "Wedding circle?",
    "Dance that might include a chair",
    "Bar mitzvah dance",
    "Part of un dia",
    "Part of un dia",
    "\"Hava Nagila\" dance",
    "Joyous wedding dance",
    "Circle of friends at a party, perhaps",
    "Romanian wedding dance",
    "Dance that might give you a lift?",
    "Jewish wedding dance",
    "Dance at some weddings",
    "Part of a wedding celebration",
    "Bar mitzvah dance",
    "Bar mitzvah highlight",
    "Circle dance",
    "Jewish wedding dance",
    "Circular dance",
    "Wedding ring?",
    "Ring event after exchanging rings",
    "Division del dia",
    "Dance at a Jewish wedding",
    "Dance done to \"Hava Nagila\"",
    "Bar mitzvah dance",
    "Bar mitzvah party staple",
    "Dance typically done to \"Hava Nagila\"",
    "Wedding ring?",
    "Dance around a high chair?",
    "Israeli dance",
    "Ring after exchanging rings?",
    "Bar mitzvah dance",
    "Bar mitzvah dance",
    "Circle dance",
    "It's done while holding hands",
    "Celebratory dance",
    "Dance in a circle",
    "Celebratory dance",
    "Circle dance",
    "Jewish round dance",
    "Kibbutz dance",
    "Wedding dance",
    "Round dance",
    "Circle dance",
    "Round dance",
    "Israeli dance",
    "Circle dance",
    "Lively circle?",
    "Israeli dance",
    "Chair-raising experience?",
    "Lively folk dance",
    "Bar mitzvah highlight",
    "Circle dance",
    "Jewish dance",
    "Joyful dance",
    "Bar mitzvah dance",
    "Ring dance",
    "Jewish wedding ring?",
    "Kibbutz dance",
    "Wedding dance",
    "Joyful dance",
    "Wedding dance",
    "Jewish wedding dance",
    "Lively dance",
    "Ring dance",
    "\"Que ___ es?\" (Spanish 101 question)",
    "Wedding dance",
    "Dance at a Jewish wedding",
    "Israeli dance",
    "Dance at a Jewish wedding",
    "Joyful dance",
    "Circle dance",
    "Folk dance",
    "Round dance",
    "Brisk dance",
    "Celebratory dance",
    "Kibbutzniks' dance",
    "Dance in a shtetl"
  ],
  "ants": [
    "Snacks for some beetles",
    "Myrmecophobe's fear",
    "Social workers?",
    "Workers you wouldn't want to see in the office?",
    "Fare for aardvarks",
    "Parts of black widow spiders' diets",
    "Colonial workers, maybe",
    "Little creatures recurring in Dali paintings",
    "Wee workers",
    "Pantry-raiding bugs",
    "Unwelcome picnic guests",
    "Targets of formicide",
    "Workers in formicaries",
    "Targets for exterminators",
    "Pantry pests",
    "___ on a log (healthy snack)",
    "Sugar bowl invaders",
    "Cookout crashers",
    "Ironically, they live on every continent except Antarctica",
    "Tidbits for aardvarks",
    "Army in the field?",
    "Tiny hill builders",
    "Workers on the hill",
    "Leavers of pheromone trails",
    "Line at a food stand?",
    "Hill workers",
    "Colony dwellers",
    "Tree line?",
    "They might emerge on hilltops",
    "Crumb carriers",
    "Industrious little marchers",
    "Moving line on a tree trunk",
    "Carpenters with small jobs?",
    "Picnic pests",
    "Marching band?",
    "Marching band?",
    "Insects in colonies",
    "Industrious workers",
    "Colony dwellers",
    "Social group",
    "Some \"giants\" in \"Honey, I Shrunk the Kids\"",
    "Myrmecophobe's fear",
    "Threat in \"The Naked Jungle,\" 1954",
    "Myrmecologists' study",
    "Trail near a hill?",
    "H. G. Wells's \"Empire of the ___\"",
    "Sources of formic acid",
    "Tiny colony dwellers",
    "Small soldiers",
    "Antenna users",
    "Echidna food",
    "Bugs in \"A Bug's Life\"",
    "Kitchen pests",
    "\"Marching\" insects",
    "Small marching band?",
    "Formic acid sources",
    "Tiny hill dwellers",
    "Ones putting out feelers",
    "Pantry problem",
    "Some marching bands",
    "Colonial group",
    "Some are queens",
    "Bugs in \"A Bug's Life\"",
    "Tidbits for aardvarks",
    "Hobby farm denizens",
    "Social workers",
    "Queen and her servants, maybe",
    "Drones, maybe",
    "Aardvark's diet",
    "Picnic intruders",
    "Some soldiers",
    "Certain soldiers",
    "Unpaid workers?",
    "Line at a picnic?",
    "Harvesters, e.g.",
    "Unwelcome visitors to one's home",
    "See 39-Down",
    "Tiny scurriers",
    "Creatures with tunnel vision?",
    "Aardvarks' fare",
    "Things with antennas",
    "\"Marchers\" through the answers to the five starred clues",
    "Hill dwellers",
    "Symbols of industry",
    "Social workers?",
    "\"Farm\" dwellers",
    "Red army?",
    "Six-legged scurriers",
    "White ___ (termites)",
    "Tiny tunnelers",
    "Tiny critters found twice each in 17-, 38- and 60-Across",
    "Six-legged intruders",
    "Aardvark's fare",
    "Social workers",
    "Antenna holders",
    "Tiny hill dwellers",
    "\"Them!\" bugs",
    "Marchers in single file",
    "Ruiners of some picnics",
    "Ones heading for the hills?",
    "Pantry raiders",
    "Some queens",
    "Army members?",
    "Little scurriers",
    "Colonial workers",
    "Unwelcome dining discovery",
    "Ones making pantry raids?",
    "Army members",
    "Kitchen pests",
    "Sugar lovers",
    "Relatives of termites",
    "Some colonists",
    "Hill inhabitants",
    "Silent army",
    "Aardvark fare",
    "Kitchen pests",
    "Little scurriers",
    "Honeydew lovers",
    "Them, in \"Them!\"",
    "Hill group",
    "Symbols of industry",
    "Sugar bowl marchers",
    "Soldiers and carpenters, e.g.",
    "\"The Naked Jungle\" menace",
    "Aardvarks' morsels",
    "Aardvark's prey",
    "Some soldiers",
    "Some marching groups",
    "Tiny soldiers",
    "Carpenters and harvesters",
    "Myrmecologist's study",
    "Aardvark's fare",
    "Sugar bowl marchers",
    "Small colonists",
    "Aardvark's fare",
    "Black or red insects",
    "Hill dwellers",
    "Red army?",
    "Small soldiers",
    "Six-footers",
    "Some queens",
    "Little marchers",
    "Busy bodies",
    "Aardvark's meal",
    "Farm workers?",
    "Tiny colonists",
    "Pesticide targets",
    "Wee workers",
    "Carpenters and harvesters",
    "Picnic spoilers",
    "Some workers",
    "Tunnel builders",
    "Workers in a column",
    "Pants problems",
    "Aardvark fare",
    "Some caste members",
    "Picnic raiders",
    "Sugar bowl team?",
    "Nest inhabitants",
    "Picnic invaders",
    "Amazon ___",
    "Aardvark's diet",
    "Queen's servants",
    "Carpenters, e.g.",
    "Porch raiders",
    "Termites' kin",
    "Hill dwellers",
    "Orkin targets",
    "Bugs in lines",
    "Tiny tunnelers",
    "Pantry pests",
    "Red army members?",
    "Termites' kin",
    "Picnic pests",
    "Formicary residents",
    "Some queens",
    "Army members",
    "Farm dwellers",
    "Picnic ruiners",
    "Six-footers",
    "Hill dwellers",
    "Exterminator's targets",
    "Army members?",
    "Aardvark's meal",
    "Soldiers of a sort",
    "Queen and workers",
    "Aardvark snacks",
    "Scurriers",
    "Busy bodies",
    "Chocolate-covered morsels",
    "Busy ones",
    "Pismires",
    "Some carpenters",
    "Colony members",
    "Them in \"Them!\"",
    "They take to the hills",
    "Colonists",
    "Picnic pests"
  ],
  "yule": [
    "Festive season",
    "Log time",
    "Time for a TV log",
    "Winter season",
    "Jolly time",
    "Christmastime",
    "___ log",
    "Christmas season",
    "___ Ball (event at Hogwarts during the Triwizard Tournament)",
    "Season to drink 58-Across",
    "Caroling time",
    "Traditional season for eggnog",
    "Time for eggnog",
    "Log time",
    "Festive time",
    "Post-solstice celebration",
    "Winter time",
    "Time to visit a lot of trees",
    "___ Ball, quinquennial dance in Harry Potter",
    "Christmastime",
    "Holiday season",
    "Christmas",
    "Christmastime",
    "Kind of log",
    "Kind of candle",
    "Festive time",
    "Time to go caroling",
    "Christmas season",
    "Carol time",
    "Time for eggnog",
    "Log-burning time",
    "Holiday time",
    "Christmastime",
    "Tree-trimming time",
    "Season to be jolly",
    "Festive time",
    "Kind of tide",
    "When to sing 25-Across",
    "Winter time",
    "Gift-giving time",
    "Christmas",
    "Christmas",
    "Kind of tide",
    "Christmastime",
    "Kind of log"
  ],
  "plan": [
    "Not just live in the present",
    "Strategy",
    "Schematic",
    "Design",
    "\"Here's the ___\"",
    "Something avoided by a person with teleophobia",
    "Look ahead",
    "Not wing it",
    "Impulsive people tend to lack one",
    "Blueprint",
    "Road map",
    "Sketch out",
    "Word with game or building",
    "Architect's drawing",
    "Blueprint",
    "Think ahead",
    "A is the best one",
    "War room development",
    "Strategize",
    "Blueprint",
    "One might be hatched",
    "Leave nothing to chance",
    "___ B",
    "Scheme",
    "Course of action",
    "Program",
    "___ B",
    "___ B",
    "Blueprint",
    "Not be haphazard",
    "Architect's handiwork",
    "Blueprint",
    "Insurer's offering",
    "Scheme",
    "Caballer's need",
    "Bit of strategizing",
    "New entrepreneur's need",
    "Think ahead",
    "Architectural starting point",
    "Schedule",
    "Course of action",
    "Think ahead",
    "Mastermind",
    "Think ahead",
    "Scheme",
    "Arrange",
    "Try not to be taken by surprise",
    "Something that's hatched",
    "Blueprint",
    "Blueprint",
    "Be organized, in a way",
    "Map out",
    "Marshall ___",
    "Blueprint",
    "401(k), e.g.",
    "Builder's need",
    "Work out",
    "Map out",
    "It may be medical",
    "Look ahead",
    "Blueprint",
    "Eschew spontaneity",
    "Blueprint",
    "Blueprint",
    "Architect's offering",
    "Work out",
    "___ B",
    "Blueprint",
    "It may be American or European",
    "Map out",
    "Blueprint",
    "Scheme",
    "Syllabus",
    "Word with floor or peace",
    "Strategize",
    "Outline",
    "Keogh, for one",
    "Blueprint",
    "Approach",
    "Strategize",
    "Blueprint",
    "Keogh, for one",
    "How-to",
    "Cult film \"___ 9 From Outer Space\"",
    "Map out",
    "Blueprint",
    "Syllabus"
  ],
  "rand": [
    "£ : pound sterling :: R : ___",
    "\"The Virtue of Selfishness\" essayist, 1964",
    "McNally's partner",
    "South African money",
    "South African currency",
    "___ McNally (mapmaker)",
    "South African money",
    "Cape Town coin",
    "Kentucky senator Paul",
    "___ McNally (mapmaker)",
    "\"Atlas Shrugged\" author Ayn",
    "Partner in mapmaking",
    "South African money",
    "Ayn who wrote \"Atlas Shrugged\"",
    "Name on many a road map",
    "Pioneering map publisher William",
    "Ayn who wrote \"Atlas Shrugged\"",
    "South African money",
    "Kentucky senator Paul",
    "Capital of East London",
    "Author Ayn",
    "100 cents, in East London",
    "Pretoria money",
    "Cape Town currency",
    "Ayn who wrote \"Atlas Shrugged\"",
    "Money in Pretoria",
    "Cape Town currency",
    "Money in South Africa",
    "Sperry's partner",
    "Cape Town coin",
    "Money in Port Elizabeth",
    "Major gold-mining area, with \"the\"",
    "Coin with the image of a springbok",
    "South African gold discovery site, with \"the\"",
    "African money",
    "100 cents",
    "\"We the Living\" author",
    "Big name in mapmaking",
    "\"The Virtue of Selfishness\" author",
    "\"The Fountainhead\" author",
    "South African money",
    "Big name in cartography",
    "\"We the Living\" author",
    "South African currency",
    "Big name in mapmaking",
    "100 cents abroad",
    "Dancer with a few fans",
    "100 cents, abroad",
    "Currency, in Capetown",
    "Money in Johannesburg",
    "South African money",
    "Foreign currency",
    "South African money"
  ],
  "pdas": [
    "Proto-smartphones, in brief",
    "Palm products, for short",
    "Palms, e.g., for short",
    "Devices rendered obsolescent by smartphones, in brief",
    "Smartphone precursors, for short",
    "Blackberrys, e.g., for short",
    "Smartphones replaced them, for short",
    "Old PalmPilot products, in brief",
    "BlackBerrys, e.g., in brief",
    "Forerunners of smartphones, for short",
    "Some info holders",
    "Multipurpose hand-held devices, for short",
    "Palmtop organizers, for short",
    "Keepers of appointments, for short",
    "Palm Pilots and such, for short",
    "Droids, e.g., for short",
    "BlackBerrys and Palms, for short",
    "P.R. agents' aids",
    "Droids, e.g.",
    "Droids, e.g., for short",
    "Many hand-helds, for short",
    "Droids, etc.",
    "Droids, e.g.",
    "Droids, iPhones, etc.",
    "Portable info-storing devices",
    "BlackBerry and others, for short",
    "BlackBerrys, e.g., for short",
    "Some hand-helds",
    "Modern info holders",
    "Modern organizers, briefly",
    "Some palmtops, for short",
    "High-tech appt. books",
    "Some handhelds: Abbr."
  ],
  "bird": [
    "It may be flipped in anger, with \"the\"",
    "Twitter logo",
    "Middle finger, with \"the\"",
    "Something \"in the hand\" that's \"worth two in the bush\"",
    "What might come down to the wire?",
    "Owl or osprey",
    "Frequent tweeter",
    "One with a lot of tweets",
    "Frequent flier",
    "Frequent tweeter",
    "PBS has a big one",
    "Frequent flier",
    "Aircraft, informally",
    "Ostrich or owl",
    "Tweety ___ of Warner Bros. cartoons",
    "Word with early or whirly",
    "Wren or hen",
    "Robin or swallow",
    "Cardinal, for one",
    "Nest builder",
    "Country singer?",
    "Feeder frequenter",
    "Flier",
    "One filling the bill?",
    "Wren or hen",
    "N.B.A. M.V.P., 1984-86",
    "Shuttlecock",
    "Wren or hen",
    "Basketball's Larry"
  ],
  "seth": [
    "Adam and Eve's third child",
    "Brother of Cain",
    "Late-night host Meyers",
    "Actor Green",
    "N.B.A. star Curry",
    "First name among late-night TV hosts",
    "Biblical character who lived to be 912 years old",
    "Late-night host Meyers",
    "Actor Rogen",
    "Egyptian god of chaos",
    "Actor Rogen",
    "Meyers of late-night TV",
    "Meyers of late-night",
    "Great-great-great-great-great-great-grandfather of Noah",
    "Actor Green of the \"Austin Powers\" movies",
    "Comic Rogen",
    "Meyers of late-night",
    "MacFarlane of \"American Dad!\"",
    "Meyers of \"Late Night\"",
    "Actor Green",
    "Jimmy's \"Late Night\" successor",
    "Green of Austin Powers movies",
    "Genesis brother",
    "Cain and Abel's younger brother",
    "Curry of the N.B.A.",
    "Rogen of \"Neighbors\"",
    "Meyers of late-night",
    "Thomas the clockmaker",
    "Rogen of \"Sausage Party\"",
    "Green of \"The Italian Job\"",
    "First family name",
    "MacFarlane of \"Family Guy\"",
    "Biblical character who lived 912 years",
    "Rogen of \"The Interview\"",
    "Rogen of \"The Interview\"",
    "Son of 16-Across",
    "Green on a screen",
    "Brother of Cain and Abel",
    "Actor Rogen",
    "Green of TV's \"Robot Chicken\"",
    "Evan's best friend in \"Superbad\"",
    "MacFarlane of \"Ted\"",
    "Genesis son",
    "Younger brother of Cain and Abel",
    "Rogen of \"Superbad\"",
    "Rogen of \"Knocked Up\"",
    "MacFarlane who created TV's \"Family Guy\"",
    "Biblical 912-year-old",
    "Dickens's Mr. Pecksniff",
    "\"Family Guy\" creator MacFarlane",
    "TV producer MacFarlane",
    "Actor Rogen of \"Knocked Up\"",
    "Meyers of \"Saturday Night Live\"",
    "Green of \"Radio Days\"",
    "Clockmaker Thomas",
    "Brother of Cain and Abel",
    "Green of \"The Italian Job,\" 2003",
    "Rogen of \"Knocked Up\"",
    "Dickens's Mr. Pecksniff",
    "Third son of Adam and Eve",
    "Clockmaker ___ Thomas",
    "Member of the first family",
    "Genesis son",
    "Brother of Cain and Abel",
    "Low in education",
    "Genesis son",
    "Third son of Adam and Eve",
    "Clockmaker Thomas",
    "Adam's third",
    "Clockmaker Thomas",
    "Genesis brother",
    "Genesis son",
    "Adam's third",
    "Green of \"Austin Powers\" films",
    "Brother of Cain and Abel",
    "Brother of Cain",
    "Genesis son",
    "Member of the first family",
    "Dickens's Mr. Pecksniff",
    "Genesis brother",
    "Clockmaker Thomas",
    "Clockmaker Thomas",
    "___ Arnold's Balsam (old patent medicine)",
    "Third son of 71-Down",
    "Clockmaker Thomas",
    "See 57-Down",
    "Father of Enos",
    "Genesis son",
    "___ Arnold's Balsam (old patent medicine)",
    "Actor Green of \"Buffy the Vampire Slayer\"",
    "Actor Green of \"Buffy the Vampire Slayer\"",
    "\"___ Speaks\" (Jane Roberts book)",
    "Son of Adam and Eve",
    "Genesis son",
    "Son of 70-Across",
    "Dickens's Mr. Pecksniff",
    "Adam's third son",
    "Mr. Pecksniff of \"Martin Chuzzlewit\"",
    "Clockmaker Thomas",
    "Revolutionary War commander ___ Warner",
    "Genesis son",
    "Clockmaker Thomas",
    "One of Adam's clan",
    "Clockmaker Thomas",
    "Dickens's ___ Pecksniff",
    "Dickens's Mr. Pecksniff",
    "Brother of Cain and Abel",
    "Clockmaker Thomas",
    "Clockmaker Thomas",
    "Fifth person",
    "Thomas of timepieces",
    "Genesis son",
    "The fourth man",
    "Author Kantor",
    "Dickens's Pecksniff",
    "Cabot Cove?Æs Dr. ___ Hazlitt",
    "Third son of Adam",
    "Mr. Pecksniff of \"Martin Chuzzlewit\"",
    "Son of Adam",
    "Mr. Pecksniff of \"Martin Chuzzlewit\"",
    "Writer Kantor",
    "Adam was his father",
    "Father of Enos"
  ],
  "sari": [
    "It's a wrap",
    "Indian wedding garb",
    "Garment whose name sounds like an apology",
    "Garment worn with a choli",
    "Garment whose name sounds like 54-Down",
    "Garment often made of silk",
    "Dress in India",
    "Wedding dress that's often red",
    "Certain Indian wedding garb",
    "Dress in Delhi",
    "Asian wrap",
    "One-size-fits-all garment",
    "Madras wrap",
    "Garment in Gujarat",
    "Dress in Delhi",
    "South Asian garment",
    "Indian wrap",
    "Dress that may leave the midriff exposed",
    "Indian wedding garb",
    "Dress in Delhi",
    "Rani's raiment",
    "Garment whose name is often spelled with \"ee\" at the end",
    "It's a wrap",
    "Article of apparel that often leaves one arm bare",
    "Silk dress, maybe",
    "Bollywood dress",
    "Buttonless garment",
    "Bollywood costume",
    "What covers parts of 80-Down?",
    "Indian dress",
    "Draped dress",
    "Hindu dress",
    "Asian wear with pleats",
    "Bollywood garment",
    "Dhaka dress",
    "\"Slumdog Millionaire\" costume part",
    "Wear over a petticoat",
    "Dress in Madras",
    "Rani's wear",
    "Delhi order?",
    "Certain dress",
    "Dress in Delhi",
    "Indian dress",
    "Delhi dress",
    "Dress that drapes",
    "Eastern wear",
    "Indian wrap",
    "Indian dress",
    "A choli may be worn under this",
    "Bit of Bollywood attire",
    "Indian attire",
    "Bengalese wrap",
    "Asian attire",
    "Wrap worn in India",
    "Indian woman's attire",
    "Gandhi garment",
    "Hindu wrap",
    "Attire for an Indian bride",
    "Bollywood costume",
    "Draped item",
    "Wrapped garment",
    "Pakistani fashion",
    "\"Slumdog Millionaire\" garb",
    "Garment in Gujarat",
    "Attire around the 1-Across",
    "Draped Delhi dress",
    "A choli is worn under it",
    "Over-the-shoulder wrap",
    "Bollywood cover-up",
    "Calcutta wrap",
    "Delhi wrap",
    "Rani raiment",
    "Bollywood costume",
    "Delhi wrap",
    "Wrap for Indira Gandhi",
    "Garment worn over a choli",
    "Calcutta attire",
    "Mysore garb",
    "Lucknow dress",
    "Bollywood film costume",
    "Delhi wrap",
    "Eastern attire",
    "Delhi dress",
    "Raipur wrap",
    "It?Æs a wrap",
    "Hindu wrap",
    "Draped dress",
    "Delhi attire",
    "Raipur raiment",
    "Delhi dress",
    "Taj Mahal attire",
    "Indian garb",
    "Hindu wrap",
    "Madras clothing",
    "Delhi wrap",
    "Southern Asian apparel",
    "Delhi wear",
    "Draped dress",
    "Rani's wear",
    "Lahore garb",
    "Bombay wear",
    "Indian dress",
    "An Indian may be in it",
    "Delhi dress",
    "Dress with folds",
    "Indian dress",
    "Loose garment",
    "Madras garment",
    "Garment with folds",
    "Delhi wrap",
    "Indian dress",
    "Silk dress",
    "Indian's attire",
    "It's a wrap",
    "Indian wrap",
    "Madras dress",
    "Draped dress",
    "It may cover an Indian",
    "It's a wrap",
    "Calcutta dress",
    "Part of it is draped over the shoulder",
    "Foreign attire",
    "Hindu outfit",
    "Madras dress",
    "Rani's wear",
    "Calcutta clothing",
    "Rani's wrap",
    "Ranee's wrap",
    "Indira's dress",
    "Indian dress",
    "Hindu garment",
    "Eastern attire",
    "Zsa Zsa's real first name",
    "Rani's attire",
    "Hindu garment",
    "Lightweight garment",
    "Delhi gown",
    "Punjabi garb",
    "Mrs. Gandhi's garb",
    "Madras dress",
    "Indian attire",
    "Dhaka dress",
    "Indira Gandhi attire",
    "Indian wear",
    "Rani's garb",
    "Asian dress",
    "Indian dress",
    "Draped dress",
    "Pakistani garment",
    "Madras garb",
    "Wraparound dress",
    "Zsa Zsa's real name",
    "Silk wrap",
    "Robe",
    "Zsa Zsa Gabor's real given name",
    "Draped garment",
    "Madras dress"
  ],
  "bods": [
    "Gym figures, for short",
    "Some might be sculpted, informally",
    "Beach displays, informally",
    "Gym bunnies work on them",
    "Physiques",
    "Figures, informally",
    "Muscle mag displays",
    "Physiques, informally",
    "Physiques, for short",
    "Gym displays",
    "What gymgoers show off, slangily"
  ],
  "dope": [
    "Lowdown",
    "Cool, in slang",
    "\"Sweet!\"",
    "Hella cool",
    "Really cool",
    "Pretty cool, in slang",
    "Amazing, in slang",
    "Idiot",
    "Narcotize",
    "Ignoramus",
    "Skinny",
    "Dummy",
    "No rocket scientist",
    "Fool",
    "Idiot",
    "Dummy",
    "Excellent, in modern slang",
    "Extraordinary, in slang",
    "Dummy",
    "Lamebrain",
    "Skinny",
    "Idiot",
    "Info",
    "Inside info",
    "Bonehead",
    "Skinny",
    "Ninny",
    "Damfool",
    "Latest news, slangily",
    "Inside info",
    "Ninny",
    "Chucklehead",
    "Skinny",
    "No brainiac",
    "Know-nothing",
    "Fool",
    "Numbskull",
    "Blockhead",
    "Figure (out)",
    "Info",
    "Slip a Mickey",
    "Informal information",
    "Idiot",
    "Info"
  ],
  "wynn": [
    "Casino next to the Venetian in Vegas",
    "Vegas casino developer Steve",
    "Vegas casino magnate Steve",
    "Old-time comic Ed"
  ],
  "solo": [
    "2018 sci-fi prequel",
    "Singer's time to shine",
    "Performer's showcase",
    "\"Star Wars\" pilot who, despite his name, flies with a co-pilot",
    "By oneself",
    "Hawn of the silver screen",
    "Darth Vader's son-in-law Han ___",
    "Like Lindbergh's 1927 flight to Paris",
    "Do a star turn",
    "Aria, usually",
    "Daring way to fly",
    "Star turn",
    "Recital highlight",
    "Dis-banded?",
    "Han who's the title role of a 2018 film",
    "Number for 9-Down",
    "Play alone",
    "Band member's time to shine",
    "How Lindbergh flew across the Atlantic",
    "Alone",
    "Without having a second to lose?",
    "How Charles Lindbergh flew across the Atlantic",
    "Jam session highlight",
    "Go it alone",
    "Nerve-racking performance, maybe",
    "Only one person can do it",
    "Go it alone",
    "Helpless?",
    "Go it alone",
    "Audition winner's part, maybe",
    "Duet minus one",
    "Last name in \"Star Wars\"",
    "Like Lindbergh's historic trans-Atlantic flight",
    "One's part?",
    "Jam session feature",
    "Performance for one",
    "Many an aria",
    "Unbanded?",
    "See 71-Across",
    "Unaided",
    "Song for one",
    "Dixie rival",
    "Like Lindbergh's famous flight",
    "Aria, typically",
    "Without an escort",
    "All alone",
    "One-person performance",
    "What can one do?",
    "Stand-out performance",
    "55-Across, e.g.",
    "Lindbergh's classic flight, e.g.",
    "Helpless?",
    "Unaccompanied",
    "Player's chance to shine",
    "Stag",
    "Go off from a group",
    "See 98-Across",
    "Play alone",
    "Concert highlight",
    "With no help",
    "Go it alone",
    "Band member's big moment",
    "Standout performance",
    "With God as one's co-pilot?",
    "Way to fly",
    "Recital offering",
    "Without help",
    "Unpartnered",
    "Star turn",
    "Lacking backup",
    "Aria",
    "Fly without a co-pilot",
    "Stag",
    "Stag, in a way",
    "Jazz break, e.g.",
    "Like Lindbergh's flight",
    "Tension-producing performance",
    "Unescorted",
    "Flier's feat",
    "Alone",
    "Unassisted",
    "Aria, e.g.",
    "Aeronautical feat",
    "Jam session feature",
    "Recital performance",
    "Recital piece",
    "Helpless?",
    "Fly like Lindy",
    "Single-handed",
    "Aria, usually",
    "Aeronautics achievement",
    "Whist variety",
    "Alone",
    "Unaccompanied",
    "Song for one",
    "Something for one to do",
    "Student pilot's goal",
    "Go it alone",
    "Diva's piece",
    "Virtuoso performance?",
    "Fly alone",
    "Song sung singly",
    "Like Lindbergh's flight",
    "Go it alone",
    "Fly alone",
    "Unescorted",
    "One-man band",
    "Bach violin sonata, e.g.",
    "Fly like Lindbergh",
    "Tutti's opposite, in music",
    "Unaccompanied"
  ],
  "banc": [
    "Like Supreme Court hearings, with \"en\"",
    "Court seat",
    "Fixture in un parc",
    "En ___ (with all of a court's judges)",
    "Judge's seat",
    "Judge's seat",
    "Jurist's seat",
    "Seat in court",
    "Judge's seat",
    "Judge's perch",
    "French seat",
    "French bench",
    "French bench",
    "Judge's seat",
    "Judges' seat",
    "Judges' seat",
    "Court seat",
    "French bench",
    "Judge's seat",
    "Judge's seat, in law",
    "Judge's seat",
    "Judge's seat",
    "En ___ (how some judges sit)",
    "French bench",
    "Court seat",
    "Judge's bench",
    "Court seat"
  ],
  "trek": [
    "Enterprise voyage",
    "Long haul",
    "Long hike",
    "Go the distance?",
    "Quite a hike",
    "Peregrination",
    "Long trip",
    "Long haul",
    "Journey",
    "Hike along the Appalachian Trail, e.g.",
    "\"Star ___ Beyond\" (2016 film)",
    "Long march",
    "Arduous walk",
    "Quite the hike",
    "Long hike",
    "Make arduous progress",
    "Long, arduous walk",
    "U.S.S. Enterprise journey",
    "Long hike",
    "Wearying journey",
    "Long hike",
    "Arduous hike",
    "Long, long hike",
    "Thousand-mile journey, say",
    "Go a long way",
    "Difficult journey",
    "Long haul",
    "Long, hard journey",
    "Quite a schlep",
    "Voyage with Captain Kirk",
    "No mere jaunt",
    "Tough trip",
    "Arduous journey",
    "Migration, maybe",
    "Hard journey",
    "Journey",
    "All-day hike",
    "Pilgrimage",
    "Long, long walk",
    "March lasting 31 days?",
    "Hard slog",
    "TV's \"Star ___\"",
    "Long march",
    "More than just a jaunt",
    "Long haul",
    "Polar expedition, e.g.",
    "Go far afield",
    "Hike",
    "Long haul",
    "Long hike",
    "Not just a five-minute jaunt",
    "Journey for Kirk",
    "Taxing trip",
    "Tough journey",
    "Voyage",
    "Long hike",
    "Trudge",
    "No quick trip around the block",
    "Boer migration",
    "Arduous journey",
    "Enterprise doings",
    "Not just a hike",
    "Not just a trip across town",
    "Tough going",
    "Schlepp",
    "Long haul",
    "Hard journey",
    "Hike",
    "\"Star ___\"",
    "Odyssey",
    "Quite a trip",
    "Pilgrimage",
    "Difficult journey",
    "Spock's voyage",
    "Take a hike",
    "Himalayan hike",
    "Odyssey",
    "Expedition",
    "Long journey",
    "\"Enterprise\" journey",
    "Arduous journey"
  ],
  "vase": [
    "Container whose name is pronounced with either a long \"a\" or an \"ah\"",
    "Decorative container",
    "Mason jar, in a pinch",
    "Mantel piece",
    "Waterford offering",
    "Rubin ___ (classic illusion)",
    "Bouquet holder",
    "Still-life object",
    "Ming dynasty artifact",
    "One holding flowers",
    "Piece in many a still life",
    "Still-life object",
    "Site of an arrangement",
    "Bouquet holder",
    "Still-life subject",
    "Mantel piece",
    "Ming museum piece, maybe",
    "China piece",
    "Place for a bloom",
    "One with a neck and a lip",
    "It may be put on a pedestal",
    "Bouquet holder",
    "Waterford purchase",
    "Boughpot",
    "Classical art medium",
    "Vessel for a florist",
    "Flower holder",
    "It holds a bunch",
    "Classical art medium",
    "Museum artifact",
    "It may hold a spray",
    "Carnation container",
    "Flower holder",
    "Mantel sight",
    "Still-life piece",
    "Bud holder",
    "Boughpot",
    "Bud holder",
    "Arrangement holder",
    "Ming artifact",
    "Still life subject",
    "Still-life centerpiece",
    "Bouquet site",
    "Still-life subject",
    "Bouquet holder",
    "Mantel piece",
    "Boughpot",
    "Mantel piece",
    "Wide-mouthed vessel",
    "Flower holder",
    "Amphora",
    "Floral container",
    "Place for posies"
  ],
  "gasp": [
    "Show of shock",
    "[I'm in shock!]",
    "[OMG!]",
    "React in astonishment",
    "[OMG!]",
    "[Oh!!!]",
    "[OMG!]",
    "Inspiration for a horror movie?",
    "Have a sudden inspiration?",
    "[Oh. My. God!]",
    "[I don't believe it!]",
    "Reaction of shock",
    "[Horrors!]",
    "Shocked response",
    "[OMG!]",
    "[Shocking!]",
    "Betray surprise",
    "[OMG!]",
    "[Surely you can't mean ...!]",
    "[Good heavens!]",
    "[I am shocked!]",
    "[I am SHOCKED!]",
    "Shocked reaction",
    "Sound at a horror film",
    "[Oh, my stars!]",
    "[Shocking!]",
    "[Mercy!]",
    "[Horrors!]",
    "Exclaim breathlessly",
    "Sound dumbfounded",
    "Show shock",
    "[Oh, my stars!]",
    "Swimmer's sound",
    "Shocked reaction",
    "[I'm shocked!]",
    "Betray horror",
    "[Oh ... my ... God!]",
    "One might fight to the last one",
    "[Oh ... my ... Lord!]",
    "[Oh, my heavens!]",
    "\"Oh my!,\" for one",
    "Struggle for air",
    "[You don't mean ?à!]",
    "Have a sudden inspiration?",
    "Shocked reaction",
    "[Horrors!]",
    "[I'm shocked!]",
    "Hand-over-mouth reaction",
    "Pant",
    "Sound of astonishment",
    "Sound at a fun house",
    "Struggle for air",
    "Shocked reaction",
    "Show shock",
    "Struggle for breath",
    "Sound of astonishment",
    "Have a sudden inspiration?",
    "Get a sudden inspiration?",
    "[I'm shocked! Shocked!]",
    "Recover from a run",
    "[I am shocked!]",
    "[You don't mean THAT!]",
    "Huff",
    "Fun house sound",
    "Fight for breath",
    "Exhibit shock",
    "[\"Good heavens!\"]",
    "Breathtaking action?",
    "[\"Oh my!\"]",
    "Sound astonished",
    "Sound astonished",
    "[\"You don't mean...!\"]",
    "Short-of-breath breath",
    "Sharp breath",
    "[I am shocked!]",
    "Catch one's breath",
    "Last breath",
    "Shocked response",
    "[Shock!]"
  ],
  "imon": [
    "\"It's showtime for me!\"",
    "Comment after a cue",
    "Performer's \"Wish me luck!\"",
    "\"That's my cue!\"",
    "\"That's my cue!\"",
    "\"That's my cue!\"",
    "\"Time for me to shine\"",
    "\"That's my cue!\"",
    "\"It's showtime!\"",
    "Comment immediately following a stage cue",
    "\"That was my cue\"",
    "\"That's my cue!\"",
    "Springsteen's \"___ Fire\"",
    "\"And while ___ the subject ...\"",
    "\"That's my cue!\"",
    "\"___ a roll!\"",
    "\"___ the case\"",
    "\"___ my way\"",
    "\"___ a roll!\"",
    "\"___ My Way\" (\"Porgy and Bess\" tune)",
    "\"___ cloud nine!\"",
    "\"___ Fire\" (Springsteen hit)",
    "Springsteen's \"___ Fire\"",
    "\"___ Fire\" (Springsteen hit)"
  ],
  "sept": [
    "9/",
    "Calendar abbr. that's also a French number",
    "Month number 60-Across: Abbr.",
    "Be Kind to Editors and Writers Mo. [for real!]",
    "9/",
    "Virgo/Libra mo.",
    "Vingt-___ (multiple de trois)",
    "Aug. follower",
    "Back-to-sch. time",
    "Mo. of Mexican Independence Day",
    "Deux into quatorze",
    "Quatre + trois",
    "A third of vingt-et-un",
    "Reaping time: Abbr.",
    "9/",
    "Number of dwarfs with Blanche Neige",
    "Nombre after six",
    "Harvest time: Abbr.",
    "Natl. Piano Mo.",
    "Labor Day mo.",
    "It has 720 hrs.",
    "Equinox mo.",
    "Back-to-school mo.",
    "Fall mo.",
    "9: Abbr.",
    "Half of quatorze",
    "Ninth in a series: Abbr.",
    "Fall mo.",
    "Leaf-turning mo.",
    "When W.W. II began: Abbr.",
    "Cal. page",
    "Aug. follower",
    "Aug. follower",
    "Natl. Courtesy Month",
    "Back-to-school time: Abbr.",
    "Aug.'s follower",
    "Number after six",
    "Michaelmas's mo.",
    "Labor Day's mo.",
    "Back-to-school mo.",
    "Important school mo.",
    "Mo. when Libra starts",
    "Library Card Sign-Up Mo.",
    "Fall mo.",
    "Back-to-school time: Abbr.",
    "Oct. precursor",
    "Be Kind to Editors and Writers Month: Abbr.",
    "Natl. Courtesy Month",
    "Aug.-Oct. divider",
    "Back-to-school time: Abbr."
  ],
  "poky": [
    "Annoyingly slow",
    "Slow",
    "Like tailgated drivers, often",
    "Puttering",
    "Dawdling",
    "Slowish",
    "Snaillike",
    "Dawdling",
    "Snaillike",
    "Slow",
    "Molasseslike",
    "Slow"
  ],
  "dean": [
    "Chair's superior",
    "College official",
    "\"MacGyver\" actor Richard ___ Anderson",
    "Campus leader",
    "School administrator",
    "College faculty head",
    "Academia figure",
    "Student leader?",
    "College V.I.P.",
    "Campus bigwig",
    "College V.I.P.",
    "List maker",
    "Student body overseer",
    "College person with a \"list\"",
    "Eldest member of an organization",
    "One who might expel a student",
    "Campus bigwig",
    "James, Jimmy or John",
    "Newspaper V.I.P. Baquet",
    "School overseer",
    "Administrative title",
    "Travelmate in \"On the Road\"",
    "Graduation ceremony V.I.P.",
    "Graduation V.I.P.",
    "College bigwig",
    "University official",
    "College adviser",
    "Admissions honcho",
    "Official with a list",
    "Academic overseer",
    "Figure in academia",
    "Overseer of student discipline",
    "Certain disciplinarian",
    "Watergate figure John",
    "Faculty head",
    "College V.I.P.",
    "James known for playing an 8-Down",
    "College V.I.P.",
    "___ of students",
    "School appointment",
    "Class-conscious figure?",
    "College official",
    "One doing school work?",
    "Cain of \"Lois & Clark\"",
    "___ of students",
    "Official with a list",
    "V.I.P. at V.P.I., say",
    "College administrator",
    "Recipient of many referrals",
    "Democratic honcho Howard",
    "Faculty head",
    "School V.I.P.",
    "Longest-running member",
    "University V.I.P.",
    "Dizzy of the Gas House Gang",
    "Unsuccessful candidate in the 2004 primaries",
    "List keeper",
    "Martin of the Rat Pack",
    "Campus bigwig",
    "Campus bigwig",
    "One in charge of discipline, maybe",
    "One with a list?",
    "School appointment",
    "Academic figure",
    "Campus bigwig",
    "Rat Pack cohort of Frank",
    "Figure in academia",
    "Watergate figure",
    "Person with a list",
    "Student overseer",
    "Rusk in J.F.K.'s cabinet",
    "Campus V.I.P.",
    "Student overseer",
    "University V.I.P.",
    "College bigwig",
    "One with a list",
    "Campus V.I.P.",
    "___ of admissions",
    "Church V.I.P.",
    "College appointment",
    "One who makes an admission?",
    "One with a list",
    "Old-timer, of sorts",
    "Campus figure",
    "University V.I.P.",
    "Campus authority",
    "He was a \"Giant\" star",
    "Cain of \"Lois & Clark\"",
    "One with a list",
    "Senior member",
    "Campus V.I.P.",
    "Faculty V.I.P.",
    "Admissions post",
    "___ of faculty",
    "One of the 30's Gas House Gang",
    "Ivory tower figure",
    "College official",
    "Smith or Jones",
    "College official",
    "___of faculty",
    "Campus V.I.P.",
    "College V.I.P.",
    "Mr. Witter",
    "College leader",
    "Doyen",
    "1934 baseball M.V.P.",
    "Admissions chief"
  ],
  "desi": [
    "Member of the South Asian diaspora",
    "Member of a South Asian diaspora",
    "South Asian living abroad",
    "Arnaz who loved Lucy",
    "Arnaz of \"I Love Lucy\"",
    "Member of the South Asian diaspora",
    "One of the Arnazes",
    "Member of the South Asian diaspora",
    "He loved Lucy",
    "Ricky's player on old TV",
    "First name in 1950s comedy",
    "Member of a South Asian diaspora",
    "Lucy's man",
    "South Asian living abroad",
    "Lucy's bandleader husband",
    "Lucy's guy",
    "Arnaz of \"I Love Lucy\"",
    "Ball boy?",
    "First name in 1950s comedy",
    "He loved Lucy",
    "Arnaz of \"I Love Lucy\"",
    "Arnaz of 1950s TV",
    "Lucy's partner",
    "Arnaz of \"I Love Lucy\"",
    "He played Ricky to Lucille's Lucy",
    "1965 hitmakers Dino, ___ & Billy",
    "An Arnaz",
    "Lucy's husband or son",
    "First name in '50s TV",
    "Lucy's man",
    "\"The Lucy-___ Comedy Hour\"",
    "Arnaz of \"I Love Lucy\"",
    "He loved Lucy",
    "Ball boy?",
    "Arnaz of \"I Love Lucy\"",
    "First name in '50s TV comedy",
    "One half of an old comedy duo",
    "First name in '50s comedy",
    "Lucy's love",
    "Lucy's guy",
    "Arnaz of \"I Love Lucy\"",
    "Portrayer of TV's Ricky",
    "Arnaz of 1950s TV",
    "First name in '50s comedy",
    "Arnaz of \"I Love Lucy\"",
    "Big name in '50s TV",
    "1950s TV name",
    "Arnaz of \"I Love Lucy\"",
    "Half a classic sitcom couple",
    "Arnaz of '50s TV",
    "Lucy-___ Museum in Jamestown, N.Y.",
    "First name in comedy and conga lines",
    "Arnaz of 1950's TV",
    "First name in 50's TV",
    "Arnaz of 50's TV",
    "Arnaz of \"I Love Lucy\"",
    "1950's sitcom name",
    "Ricky player in '50s TV",
    "He loved Lucy",
    "\"The Lucy-___ Comedy Hour\"",
    "50's sitcom name",
    "Lucie's dad",
    "First name in '50s TV",
    "60's pop trio Dino, ___ & Billy",
    "First name in 50's TV",
    "Lucy's guy",
    "Arnaz Sr. or Jr.",
    "He played Ricky on 50's TV",
    "He loved Lucy",
    "Ricky player",
    "Ricky's portrayer",
    "Ricky, really",
    "TV's Arnaz",
    "Ricky's portrayer",
    "One-third of a 60's pop trio",
    "First name in 50's TV",
    "Lucy's husband",
    "First name in 50's TV",
    "Lucy's partner",
    "He played Ricky on TV",
    "Lucy's partner",
    "He loved Lucy",
    "Half a 50's sitcom couple",
    "Big name in 50's TV",
    "One of a 50's sitcom couple",
    "Lucie's brother",
    "Lucie's father",
    "First name in 50's TV",
    "Lucy's partner",
    "He loved Lucy",
    "Lucie's dad",
    "Lucy's hubby",
    "___ Jr. (acting son, familiarly)",
    "He loved Lucy",
    "Actor Arnaz",
    "Lucy's husband",
    "Lucy's husband",
    "Lucy's partner",
    "He loved Lucy",
    "First name in 50's TV",
    "Name in old TV comedy",
    "60's group Dino,___& Billy",
    "Lucille's love",
    "Big name in 50's comedy",
    "TV's Ricky",
    "Lucie's dad",
    "Mr. Arnaz",
    "Lucy's son",
    "Love of Lucy",
    "TV's Ricky",
    "TV's Ricky",
    "First name in TV comedy",
    "Legendary name in sitcoms",
    "Mr. Arnaz"
  ],
  "side": [
    "Word before dish or effect",
    "Pro or con, in a debate",
    "Fries, e.g.",
    "\"I'm on your ___!\"",
    "Point of view, as in an argument",
    "Faction",
    "Hypotenuse, e.g.",
    "Pro or con",
    "Ally (with)",
    "With 44-Across, off-the-record discussions ... or 12 answers in this puzzle?",
    "Agree (with)",
    "Big butcher purchase",
    "Onion rings, e.g.",
    "Debating choice",
    "See 1-Across",
    "With 14-Across, cruise bonus",
    "Salad, often",
    "Agree (with)",
    "Dish component",
    "Mashed, e.g.",
    "Aspect",
    "Fries, to a burger",
    "Battle joiner's choice",
    "Hash browns, e.g., typically",
    "Flank",
    "Pro or con",
    "Flank",
    "Fries or slaw",
    "Slaw, e.g.",
    "Slaw or fries, e.g.",
    "Flank",
    "Fries, say",
    "Pro or con",
    "Team",
    "Fries or slaw",
    "Lateral part",
    "Yeas or nays",
    "Pro or con",
    "Wing",
    "Pro or con",
    "Fries, maybe",
    "Pro or con, in a debate",
    "Entree go-with",
    "Pro or con",
    "Pro or con",
    "Ally (with)",
    "Bar or car starter",
    "Fries, maybe",
    "A or B, on a record",
    "Pro or con",
    "In billiards, what the English call English",
    "Border",
    "One of six for a hexagon",
    "A or B, on a cassette",
    "Support, with \"with\"",
    "Team",
    "Pro or con",
    "Incidental",
    "Not the front or back",
    "Team",
    "One of six on a cube",
    "Collateral",
    "Start to kick?",
    "Team",
    "A or B, e.g.",
    "Not main",
    "Faction",
    "Up-down connector",
    "Word between up and down",
    "Kind of order"
  ],
  "rotc": [
    "Campus military org.",
    "Where majors have majors, in brief?",
    "Org. for some future lts.",
    "Military program for coll. students",
    "Org. for students in uniform",
    "Source of a deferment in the 1960s draft",
    "College recruitment org.",
    "Campus grp. that organizes marches",
    "Drilling grp.",
    "Some campus marchers, briefly",
    "Recruiting org.",
    "College recruitment org.",
    "Campus org. protested by the 26-Across",
    "What knows the drill, for short?",
    "College military org.",
    "March org.?",
    "Big source of coll. scholarships",
    "Coll. program",
    "March org.?",
    "Provider of two- and four-yr. scholarships",
    "College org. with a Color Guard",
    "Sgt.'s program",
    "Coll. elective",
    "Coll. elective",
    "Mil. educators",
    "Campus drillers",
    "Campus org.",
    "Driller's letters",
    "March org.?",
    "Org. known for drilling?",
    "Campus mil. group",
    "Recruiting org.",
    "Drilling grp.",
    "Cadets' org.",
    "Drillers' org.?",
    "Univ. military program",
    "Org. offering college scholarships",
    "March grp.?",
    "Coll. elective program",
    "Mil. option",
    "Cadet's org.",
    "Campus mil. program",
    "Univ. marching band?",
    "Univ. military program",
    "Campus program, initially",
    "University mil. group",
    "Mil. training grp.",
    "Drilling grp.",
    "O.C.S. relative",
    "Program for future leaders: Abbr.",
    "Some campus marchers: Abbr.",
    "Drilling grp.",
    "Org. in which Colin Powell once served",
    "Campus marchers: Abbr.",
    "Univ. marchers",
    "School org.",
    "Coll. offering",
    "Campus soldiers: Abbr.",
    "March org.",
    "Mil. education grp.",
    "Campus military org.",
    "Campus mil. org.",
    "College org.",
    "University mil. group",
    "Univ. recruiter",
    "Campus military org.",
    "H.S. or coll. program",
    "Cadet's course, for short",
    "Campus org.",
    "Some college training",
    "Campus program, for short",
    "Campus mil. grp.",
    "Mil. option",
    "Campus org.",
    "Coll. student's income source",
    "Campus military grp.",
    "Collegians' extracurricular activity",
    "Campus mil. org.",
    "Campus military org.",
    "Campus org.",
    "Mil. offshoot",
    "Campus mil. grp.",
    "Campus mil. grp."
  ],
  "secs": [
    "Brief periods of time",
    "Brief moments",
    "Brief moments",
    "Jiffies",
    "Ticks",
    "Brief moments, briefly",
    "Instants",
    "Stopwatch ticks: Abbr.",
    "Brief moments",
    "Instants",
    "Jiffies",
    "They're often ticked off: Abbr.",
    "Jiffs",
    "Ticks, say: Abbr.",
    "Moments, in brief",
    "Short moments",
    "Brief moments",
    "Jiffs",
    "Fractions of a deg.",
    "Small ticks?",
    "What some watches display: Abbr.",
    "Parts of mins.",
    "Winks",
    "Moments",
    "Jiffs",
    "Parts of a min.",
    "Parts of a min.",
    "Brief moments?",
    "Brief moments",
    "Parts of a 95-Across",
    "Min. components",
    "Jiffs",
    "Moments, informally",
    "Moments",
    "Brief moments",
    "Jiffies",
    "Parts of mins.",
    "Short spans",
    "Slangy instants"
  ],
  "opec": [
    "International grp. founded in 1960 in Baghdad",
    "International grp. with a 1970s U.S. embargo",
    "International grp. that's well financed?",
    "International grp. founded in Baghdad",
    "Grp. that sets oil benchmarks",
    "Big Mideast inits.",
    "Kuwait is a member of it, in brief",
    "Big oil cartel, in brief",
    "Europe-based grp. with no European members",
    "Intl. group of oil producers",
    "Intl. group founded in 1960 with five members",
    "Grp. with many Mideast members",
    "Equatorial Guinea is its least populous member, for short",
    "Grp. headquartered in Austria, which isn't a member",
    "Oil producers' grp.",
    "Grp. that's well-financed?",
    "Equatorial Guinea joined it in 2017",
    "Oil grp.",
    "Grp. whose members have reserves",
    "Intl. group with two South American members and none in North America",
    "Oil cartel",
    "Grp. that includes Iraq and Qatar",
    "Crude letters?",
    "Well-funded grp.?",
    "Intl. group headquartered in Vienna",
    "Grp. that knows the drill?",
    "Carlos the Jackal raided its HQ",
    "What group founded in 1960 currently has 12 members?",
    "Intl. association since 1960",
    "Indonesia joined it in 1962 ... and left in 2008",
    "Big acronym in energy",
    "Well-financed grp.?",
    "Source of a 1973 crisis",
    "Grp. created at the Baghdad Conference, 1960",
    "Grp. that has held summit meetings in Caracas and Riyadh",
    "Grp. that may call on its reserves?",
    "Ecuador is a member of it",
    "Oil cartel",
    "Crude acronym",
    "Grp. that includes Ecuador and Venezuela",
    "Crude group?",
    "Oil ministers' grp.",
    "118-Across is in it",
    "Ecuador and Venezuela are in it",
    "Cartel formed in '60",
    "Intl. oil group",
    "Cartel acronym",
    "Grp. that rolls out the barrels?",
    "Vienna-based grp. with no European members",
    "Source of some of the oil for 31-Down",
    "Its HQ are in Austria, which isn't a member",
    "Grp. including Nigeria and Venezuela",
    "Org. with many Mideast members",
    "Well-running group?: Abbr.",
    "Cartel led by a secretary general",
    "Venezuela is in it",
    "The U.A.E. is in it",
    "Crude letters?",
    "Grp. with clout at the gas pump",
    "Grp. in which many of the leaders wear robes",
    "Intl. price-setting group",
    "Ecuador and Venezuela are in it",
    "Barrel org.",
    "Crude group",
    "Group that includes the U.A.E.",
    "Grp. that has the world over a barrel?",
    "Fuel grp.",
    "Acronym since 1960",
    "Crude group?",
    "Oil price-setting grp.",
    "Grp. founded in Baghdad, 1960",
    "Grp. that includes Nigeria",
    "Trading grp.",
    "Crude org.",
    "Intl. oil group",
    "Oil grp.",
    "Vienna-based cartel",
    "Price-setting grp.",
    "Grp. with barrels",
    "Iran is a mem. of it",
    "Letters from the Persian Gulf?",
    "*Mideast ... pipeline ... embargo",
    "Oil cartel",
    "Oil grp.",
    "Assn. with many Gulf members",
    "Syndicate since 1960",
    "Iraq is in it",
    "Kind of minister",
    "Grp. with crude interests?",
    "Oil grp.",
    "Crude bunch?",
    "Grp. that has energy users over a barrel?",
    "Crude group?",
    "Mideast-based grp.",
    "Indonesia is in it",
    "It controls a lot of U.S. imports",
    "Crude cartel",
    "Oil cartel",
    "Intl. acronym since 1960",
    "The U.A.E. belongs to it",
    "Grp. affecting gas prices",
    "Trade grp. since 9/14/60",
    "Noted cartel",
    "Group incl. the U.A.E.",
    "Meeting of ministers association",
    "Gas shortage manipulators",
    "Grp. making barrels of money?",
    "Notorious price controllers",
    "Cartel since 1960",
    "Unpopular org. in the '70s",
    "Crude group?",
    "Org. since 1960",
    "Fuel price setter, for short",
    "\"Well\"-financed grp.",
    "Well-oiled grp.",
    "Acronym since 1960",
    "Acronym in 70's news",
    "Grp. founded in 1960"
  ],
  "tart": [
    "Like key lime pie",
    "Biting",
    "Sharp, in a way",
    "Causing the lips to pucker",
    "Sour",
    "Lip-puckering",
    "Bakery item with fruit",
    "Mouth-puckering",
    "Citrusy",
    "Sour",
    "Piquant",
    "Stolen item in \"Alice in Wonderland\"",
    "Sour",
    "Like Granny Smith apples",
    "One rarely seen outside its shell?",
    "Mini-pie",
    "Like limes",
    "Lip-puckering",
    "Sharp",
    "Lemonlike",
    "Small pastry",
    "Biting",
    "Agreeably biting",
    "Lemonlike",
    "Sharp",
    "Like limeade",
    "Like sour grapes",
    "High tea goody",
    "Pucker-producing",
    "Pointed, in a way",
    "Fruit-filled pastry",
    "Tasting like unripe apples",
    "Lip-puckering",
    "Like cranberries",
    "Sour",
    "Sharp-tongued",
    "Like crab apples",
    "Lemony",
    "Like lemonade sans sugar",
    "Like vinaigrette",
    "Napoleon's cousin",
    "Part of a knave's loot, in a rhyme",
    "See 4-Down",
    "Like vinaigrette",
    "Pucker-producing",
    "Acerb",
    "Lip-puckering",
    "Dress (up)",
    "Lip-puckering",
    "Tongue-curling",
    "Sharp",
    "Pucker-producing",
    "Lip-puckering",
    "Like some apples",
    "Pucker-inducing",
    "Biting",
    "Fruity pastry",
    "Bakery buy",
    "Like cranberries",
    "Like a sloe",
    "Lemony, e.g.",
    "Snippy",
    "Sharp",
    "Minipie",
    "Like sour grapes",
    "Barbed, in a way",
    "Dessert for one",
    "Snippy",
    "Cutting",
    "Like some apples",
    "Little pastry",
    "Pucker-producing",
    "Bakery product",
    "Cutting",
    "Floozy",
    "Fruity pastry",
    "Napoleon relative",
    "Barbed",
    "Causing a pucker",
    "Raspberry ___",
    "Snippy",
    "Biting",
    "Like a Granny Smith apple",
    "Small pastry",
    "Not bland",
    "Bakery bite"
  ],
  "asit": [
    "\"___ just so happens ...\"",
    "\"___ happens ...\"",
    "Such ___ is",
    "\"___ happens ...\"",
    "___ were",
    "___ were",
    "\"___ happens ...\"",
    "___ were",
    "With 41-Down, seemingly",
    "___ were",
    "\"___ happens ...\"",
    "\"___ stands ...\"",
    "\"___ were ...\"",
    "___ were",
    "___ stands",
    "___ stands",
    "___ were",
    "Word before \"were\"",
    "___ were",
    "___ were",
    "___ were (so to speak)",
    "\"...___ is in heaven\": Matthew",
    "___ were (seemingly)",
    "Just ___ should be",
    "\"Be that ___ may...\"",
    "___ were",
    "___ were",
    "\"___ happens...\"",
    "___ were",
    "\"___ happens...\"",
    "\"___ was in the beginning...\"",
    "\"Play it ___ lays\"",
    "\"___ was in the beginning...\"",
    "___ were",
    "___were",
    "___were",
    "___ were (so to speak)",
    "___ happens",
    "___ happened",
    "___ were (so to speak)",
    "Didion's \"Play It ___ Lays\""
  ],
  "macs": [
    "Raincoats, to Brits",
    "PC alternatives",
    "Many editorial workstations",
    "Some \"big\" burgers",
    "Things non-PC people buy?",
    "Apple computers",
    "Big ___ (some sandwiches)",
    "Bubs",
    "Decidedly non-PC types?",
    "Computers that are un-PC",
    "Alternatives to PCs",
    "Alternatives to PCs",
    "Non-Windows computers",
    "Apple Store display",
    "Safari vehicles?",
    "Bubs",
    "PC alternatives",
    "One side in a computer rivalry",
    "OS X runners",
    "Apple Store offerings",
    "Non-P.C. choices?",
    "Part of the home computer market",
    "Machines that run Panther or Leopard",
    "Bubs",
    "OS X users",
    "Some rain gear",
    "Alternatives to PCs",
    "Alternatives to PCs",
    "Buds",
    "PC alternatives",
    "Fellahs",
    "Apple computers",
    "Some rainwear",
    "Buds",
    "PC alternatives",
    "PC alternatives",
    "Jobs offerings",
    "Apple production",
    "Coats for bad weather",
    "Some computers",
    "Fellows",
    "British raincoats",
    "PC alternatives",
    "Handy computers",
    "Certain rainwear",
    "Some office equipment",
    "Brolly alternatives",
    "Performas and PowerBooks",
    "Slickers",
    "Apple products",
    "Short coats?",
    "Apples, informally",
    "Certain raingear",
    "Apple purchases",
    "Tart apples, informally"
  ],
  "alan": [
    "___ Page, N.F.L. Hall-of-Famer turned justice on the Minnesota Supreme Court",
    "___ Kim, 7-year-old star of the Golden Globe-winning \"Minari\"",
    "___ Freed, early radio personality who coined the term \"rock 'n' roll\"",
    "Late actor Rickman",
    "Cryptanalyst Turing",
    "Late Surrealist Turner",
    "Astronaut Shepard, first American in space",
    "Actor Alda",
    "First name of two of the 12 astronauts who have walked on the moon",
    "___ Thicke, onetime late-night host",
    "Boy's name that becomes a girl's name if you move the first letter to the end",
    "Computer science pioneer Turing",
    "Bean in space",
    "Jackson of country music",
    "Astronaut Shepard",
    "Arkin of \"Catch-22\"",
    "Boy's name that's an anagram of 18-Across",
    "Rickman of the Harry Potter films",
    "Computer scientist Turing",
    "Mathematician Turing",
    "Disc jockey Freed",
    "Bean in a pod?",
    "Mathematician Turing",
    "Mathematician Turing who was the subject of \"The Imitation Game\"",
    "Shepard in space",
    "Country star Jackson",
    "King who spoke at Kennedy's inaugural ball",
    "King of comedy",
    "Astronaut Shepard",
    "Enigma machine decoder Turing",
    "Rickman of the Harry Potter films",
    "Singer Jackson with 26 #1 country hits",
    "Bean on the moon",
    "Turing who was portrayed in \"The Imitation Game\"",
    "Mathematician Turing",
    "Ex-Fed head Greenspan",
    "Jackson with 13 #1 country albums",
    "Rickman of the Harry Potter films",
    "Political commentator Colmes",
    "Actor Arkin",
    "One of the men on \"Two and a Half Men\"",
    "Country singer Jackson",
    "Actor Rickman who played 32-Across",
    "Chiropractor on \"Two and a Half Men\"",
    "Rickman of the Harry Potter films",
    "Writer Moore or Moorehead",
    "Simpson of the Simpson-Bowles commission",
    "The \"A\" of Chester A. Arthur",
    "Moore who wrote \"Watchmen\"",
    "Author Paton",
    "One of the men on \"Two and a Half Men\"",
    "___ Freed, 1960s payola figure",
    "___ Turing, a founding father of computer science",
    "Economist Greenspan",
    "Arkin of Hollywood",
    "Actor Rickman",
    "Philosopher Watts",
    "With 8-Down, \"Shane\" star",
    "Mathematician Turing",
    "Political commentator Colmes",
    "Cumming of \"Nicholas Nickleby\"",
    "Actor Rickman of the Harry Potter films",
    "Shepard in space",
    "Pioneering D.J. Freed",
    "Mathematician Turing",
    "Chester Arthur's middle name",
    "Lightman who wrote \"Einstein's Dreams\"",
    "Mathematician Turing",
    "Actor Ford of \"Snatch\"",
    "Shepard who walked on the moon",
    "Mathematician Turing",
    "Lyricist Bergman",
    "The \"A\" in Chester A. Arthur",
    "Astronaut Shepard or Bean",
    "Singer Jackson with more than 20 #1 country hits",
    "Alda of \"M*A*S*H\"",
    "Pundit Colmes",
    "Mathematician Turing",
    "Lawyer Dershowitz",
    "One of TV's \"Two and a Half Men\"",
    "King of comedy",
    "Bean who was the fourth man to walk on the moon",
    "Playwright Ayckbourn",
    "Alda of \"What Women Want\"",
    "English playwright Ayckbourn",
    "___ Turing, the Father of Computer Science",
    "Chester Arthur's middle name",
    "Poet Seeger",
    "Broadway's ___ Jay Lerner",
    "Late bridge columnist Truscott",
    "___ Freed, the so-called Father of Rock and Roll",
    "President Arthur's middle name",
    "Shepard in space",
    "___ Trammell, 1984 World Series M.V.P.",
    "Presidential middle name",
    "Presidential middle name",
    "King or Shepard",
    "English actor Bates",
    "Religious philosopher Watts",
    "King of comedy",
    "Rock radio pioneer Freed",
    "An Arkin",
    "Actor Rickman",
    "King of comedy",
    "The first \"A\" of A. A. Milne",
    "Presidential middle name",
    "Fed chairman Greenspan",
    "___ Jay Lerner of Lerner & Loewe",
    "Country star Jackson",
    "Broadway's ___ Jay Lerner",
    "With 31-Down, \"Sweet Liberty\" star",
    "The Fed's Greenspan",
    "Broadway's ___ Jay Lerner",
    "Astronaut Shepard",
    "Rock 'n' roll pioneer Freed",
    "Comedian King",
    "The \"A\" in Chester A. Arthur",
    "Ladd or Greenspan",
    "Lyricist Lerner",
    "Actor Young of TV's \"Mister Ed\"",
    "Astronaut Shepard",
    "Commentator Keyes",
    "The ?ôA?ö in Chester A. Arthur",
    "Playwright Ayckbourn",
    "Harvard's Dershowitz",
    "Computer scientist Turing",
    "Mathematician Turing",
    "Actor Bates",
    "Banking chief Greenspan",
    "Author Paton",
    "Greenspan of the Fed",
    "Astronaut Bean",
    "With 15-Down, \"This Gun for Hire\" star",
    "Shepard in space",
    "Money guru Greenspan",
    "Mathematician Turing",
    "King of comedy",
    "Fed chairman Greenspan",
    "Conservative Keyes",
    "The \"A\" of Chester A. Arthur",
    "Rock 'n' roll pioneer Freed",
    "Ladd or Alda",
    "Money man Greenspan",
    "Astronaut Bean",
    "Mathematician Turing",
    "Astronaut Shepard",
    "Fed head Greenspan",
    "\"The Way We Were\" writer Bergman",
    "Josephine Tey investigator ___ Grant",
    "Pulitzer poet Dugan",
    "Chester Arthur's middle name",
    "Mr. Greenspan",
    "With 17-Across, Hawkeye Pierce portrayer",
    "Bridge expert Truscott",
    "Josephine Tey investigator ___ Grant",
    "Playwright Ayckbourn",
    "Comic King",
    "Money guru Greenspan",
    "Rock-and-roll pioneer Freed",
    "Football Hall-of-Famer ___ Page",
    "Lawyer Dershowitz",
    "Money guru Greenspan",
    "Actor Alda",
    "Chester Arthur's middle name",
    "TV's Thicke",
    "Mathematician Turing",
    "A Ladd",
    "Chester Arthur's middle name",
    "King of comedy",
    "Mr. Greenspan",
    "Economist Greenspan",
    "Ashby of the 80's Astros",
    "Novelist Paton",
    "Astronaut Shepard",
    "Astronaut Shepard",
    "Ex-Sen. Cranston",
    "Mathematician Turing",
    "King of clubs?",
    "Baseball's Ashby",
    "Astronaut Bean",
    "Attorney Dershowitz",
    "Novelist Paton",
    "Mathematician Turing",
    "Comic King",
    "Sen. Simpson",
    "Astronaut Bean",
    "Author Paton",
    "Greenspan, for one",
    "Broadway's ___ Jay Lerner",
    "Paton of \"Cry, the Beloved Country\" fame",
    "Josephine Tey investigator ___ Grant",
    "Novelist Paton",
    "A.A. Milne's first name",
    "Arkin of \"Catch-22\"",
    "Rock 'n' roll pioneer Freed",
    "Author Paton",
    "Gatsby portrayer, with 36-Across",
    "Wyoming's Simpson",
    "King of comedy",
    "Lawyer Dershowitz",
    "Humorous King",
    "Alda of \"M*A*S*H\"",
    "Chester Arthur's middle name",
    "Wyoming's Simpson",
    "King of comedy",
    "Lawyer Dershowitz",
    "Actor Young of TV's 67-Across",
    "Hale of \"Gilligan's Island\"",
    "A Bates",
    "President Arthur?Æs middle name",
    "Astronaut Shepard"
  ],
  "tara": [
    "Skater Lipinski",
    "___ Westover, author of the 2018 best-selling memoir \"Educated\"",
    "Figure skater Lipinski",
    "Actress Reid of \"Sharknado\"",
    "Figure skater Lipinski",
    "Figure skater Lipinski",
    "Seat of ancient Irish kings",
    "Skater Lipinski",
    "\"United States of ___,\" show for which Toni Collette won an Emmy",
    "Actress Reid of the \"American Pie\" films",
    "Actress Reid of \"Sharknado\"",
    "Olympic champion Lipinski",
    "Estate in \"Gone With the Wind\"",
    "___ Westover, author of the 2018 best-selling memoir \"Educated\"",
    "\"Gone With the Wind\" plantation",
    "Reid of \"The Big Lebowski\"",
    "What Scarlett saved",
    "\"Gone With the Wind\" plantation",
    "Reid of \"American Pie\"",
    "Where Scarlett got a letter?",
    "Fictional home in Georgia",
    "\"Gone With the Wind\" plantation",
    "Neighbor of Twelve Oaks, in fiction",
    "Hill of ___, site of Ireland's Lia Fail",
    "Reid of Hollywood",
    "\"Gone With the Wind\" setting",
    "Plantation of book and film",
    "Gold-medal skater Lipinski",
    "Reid of \"Sharknado\"",
    "\"Sharknado\" actress Reid",
    "Scarlett O'Hara's plantation",
    "Fictional home that was won in an all-night poker game",
    "Setting for the highest-grossing movie of 1939",
    "\"Gone With the Wind\" plantation",
    "Spread of book and film",
    "Butler's quarters?",
    "Fictional estate",
    "\"Gone With the Wind\" plantation",
    "Fictional home five miles from Jonesboro",
    "Skater Lipinski",
    "Setting in a Mitchell novel",
    "Fictional locale five miles from Jonesboro",
    "TV title role for Toni Collette",
    "Mammy's place",
    "Twelve Oaks neighbor",
    "Home in a Mitchell novel",
    "\"Gone With the Wind\" plantation",
    "Setting for the biggest movie of 1939",
    "Butler's place",
    "\"Gone With the Wind\" plantation",
    "\"Gone With the Wind\" plantation",
    "Butler's locale",
    "Old royal residence in 29-Across",
    "Gold medalist Lipinski",
    "\"Gone With the Wind\" plantation",
    "Scarlett's true love",
    "Fictional spread",
    "Scarlett O'Hara's plantation",
    "Ireland's Hill of ___",
    "\"Gone With the Wind\" setting",
    "Olympian Lipinski",
    "\"Gone With the Wind\" setting",
    "The O'Hara spread",
    "Fictional home of Gerald O'Hara",
    "The O'Hara estate",
    "Fictional plantation",
    "1999 best seller \"___ Road\"",
    "Reid of \"American Pie\"",
    "Skater Lipinski",
    "Scarlett's home",
    "Skater Lipinski",
    "Plantation of literature",
    "It had \"well-kept acres,\" in a classic novel",
    "Twelve Oaks neighbor",
    "1939 movie setting",
    "Gold medalist Lipinski",
    "Olympian Lipinski",
    "\"Gone With the Wind\" estate",
    "\"American Pie\" actress Reid",
    "\"___ Road\" (1999 Oprah's Book Club selection)",
    "Reid of \"American Pie\"",
    "Twelve Oaks neighbor",
    "Ancient Irish kings' home",
    "\"Gone With the Wind\" plantation",
    "Renowned 1939 film setting",
    "Fictional Twelve Oaks neighbor",
    "Skater Lipinski",
    "Butler's quarters?",
    "Ancient royal residence",
    "Seat of ancient Irish kings",
    "Skater Lipinski",
    "The O'Hara spread",
    "Historic Irish village",
    "Scarlett's home",
    "\"___ Road\" (1999 Maeve Binchy best seller)",
    "Butler's haunt",
    "Early Irish assembly site",
    "The O'Hara homestead",
    "1939 film home",
    "Butler's quarters?",
    "Mitchell's plantation",
    "O'Hara estate",
    "Fictional plantation",
    "Mitchell mansion",
    "Where Mammy worked",
    "The O'Hara place",
    "The O'Haras' home",
    "Skater Lipinski",
    "Ancient Irish capital",
    "Butler's locale",
    "Gold medalist Lipinski",
    "Scarlett's home",
    "First name at the '98 Olympics",
    "Skater Lipinski",
    "Royal residence of early Ireland",
    "Manor near Twelve Oaks",
    "Scarlett's home",
    "O'Hara spread",
    "\"Gone With the Wind\" setting",
    "Mitchell mansion",
    "Scarlett's estate",
    "Scarlett's plantation",
    "Scarlett's home",
    "Thomas Moore ballad locale",
    "Twelve Oaks neighbor",
    "Scarlett's home",
    "Scarlett's place",
    "Mitchell place",
    "Scarlett's home",
    "O'Hara place",
    "1939 film site",
    "Mitchell mansion",
    "Home of the 40 Down",
    "Georgia plantation",
    "Royal residence of old Ireland",
    "Estate near Atlanta",
    "O'Hara's home",
    "Home of ancient Irish kings",
    "Fictional plantation",
    "Butler's quarters?",
    "Georgia home",
    "\"G.W.T.W.\" locale"
  ],
  "miii": [
    "1,003, in Roman numerals",
    "The year 1003",
    "Early 11th-century date",
    "Year in Ethelred the Unready's reign"
  ],
  "acdc": [
    "\"Thunderstruck\" rock band",
    "Kind of power supply",
    "Rock band that electrifies audiences?",
    "Rock band with a 1980 album that went 25x platinum",
    "Electrical adapter letters",
    "\"Rock or Bust\" rockers",
    "Electrically flexible",
    "Rock band whose lead guitarist notably dresses in a schoolboy uniform",
    "Rock band that Slash really ought to play for?",
    "\"Hells Bells\" band",
    "\"Let There Be Rock\" rock band",
    "Band with a slash in its name",
    "Choice of juice?",
    "Flexible, electrically",
    "\"Highway to Hell\" band",
    "Band with the aptly titled album \"Powerage\"",
    "\"Back in Black\" rock band",
    "Electrically flexible",
    "Hard rock band formed by Malcolm and Angus Young",
    "\"For Those About to Rock\" band",
    "Rock band that gets fans charged up?",
    "Flexible, electrically speaking",
    "Kind of adapter",
    "Versatile, electrically",
    "Flexible, electrically",
    "Electrical adapter letters",
    "Electrical inits.",
    "Young brothers' band",
    "Aussie rockers with a knickers-clad lead guitarist",
    "Power option",
    "\"Back in Black\" band",
    "Flexible, electrically",
    "\"For Those About to Rock We Salute You\" band",
    "Multimillion-selling band from Australia",
    "Band with a juiced-up name?",
    "Powered in either of two ways",
    "Operating in either of two ways",
    "High-voltage Australian band?",
    "\"Back in Black\" band",
    "Hybrid, in a way",
    "Kind of converter",
    "Power option",
    "Like some outlets",
    "Band with the 22x platinum album \"Back in Black\"",
    "Plug possibilities",
    "\"Hells Bells\" band",
    "\"Highway to Hell\" band",
    "Rock band with a lightning bolt in its logo",
    "\"___ Live,\" 1992 multiplatinum album",
    "Group with the 22x platinum album \"Back in Black\"",
    "Kind of converter",
    "Electrical letters",
    "Australian band with the 2008 #1 album \"Black Ice\"",
    "Flexible, electrically",
    "Rock band with the triple-platinum album \"High Voltage\"",
    "Switch possibilities",
    "Flexible, electrically",
    "Like some electric circuits",
    "Electrical letters",
    "Kind of adapter",
    "Rock band whose first album was titled, appropriately, \"High Voltage\"",
    "\"Back in Black\" rock band",
    "Kind of adapter",
    "Kind of converter",
    "Kind of adapter",
    "Flexible, electrically",
    "Electrician's favorite rock band?",
    "Dual elec. designation",
    "Rock band with an electrical name",
    "Flexible, electrically",
    "Elec. designation",
    "Like some appliances, electrically",
    "Outlet option",
    "Adapter designation",
    "Current choice",
    "Like some adapters",
    "Adapter letters",
    "Like some electric appliances",
    "Electrical letters",
    "Like some outlets",
    "Electrically versatile",
    "Both ways, to an electrician",
    "Flexible, as an electrical outlet",
    "Current choice",
    "Flexible, in a way",
    "Current status?",
    "Current choice",
    "Flexible, electrically",
    "O.K. in any outlet",
    "Outlet type",
    "Electrical letters",
    "Australian hard-rock band",
    "Like some appliances",
    "Current status",
    "Current choice",
    "Current choice",
    "Current status",
    "Elec. abbr.",
    "Like versatile appliances",
    "Flexible, in a way"
  ],
  "tsks": [
    "Sounds of disapproval",
    "Some clicks of the tongue",
    "Sounds of disapproval",
    "Terse rebukes",
    "Sounds of disappointment",
    "Mild reprimands",
    "Judgmental sounds",
    "Sounds of reproof",
    "\"Shame on you!\" sounds",
    "Sounds of disapproval",
    "Clucks",
    "Relatives of tuts",
    "Some reproaches",
    "Judgmental clucks",
    "Clucks of disappointment",
    "Sounds of admonishment",
    "Sounds that may accompany head-shaking",
    "Terse reproofs",
    "Shows disapproval",
    "They connote disapproval",
    "Clucking sounds",
    "Tut's kin?",
    "Tut's kin?",
    "Sounds of disapproval",
    "Slight reproaches",
    "Shows disapproval",
    "\"Bad!\" sounds",
    "Expresses disapproval",
    "Reproachments",
    "They're intended to instill shame",
    "Sounds of disapproval",
    "Clucking sounds",
    "Sounds from a scolder",
    "Reproaches",
    "Sounds of disapproval",
    "Head-shakers' syllables",
    "Sounds of disapproval",
    "Priggish pronouncements",
    "Sounds of reproof"
  ],
  "pfft": [
    "Sound of dismissiveness",
    "\"You can't expect me to believe that!\"",
    "[scoff]",
    "[Just ... disappeared!]",
    "\"Well, that's ridiculous!\"",
    "[Fizzle]",
    "Sound of failure",
    "Sound of failure",
    "Dismissive sound",
    "Sound of a dud",
    "Scoffer's sound",
    "Fizzling sound",
    "How a dud goes",
    "Kaput",
    "\"That's nuthin'!\"",
    "Dud's sound",
    "Indication of a dud",
    "Kaput",
    "Kaput",
    "[fizzle]",
    "[Fizzle]",
    "Kaput sound",
    "Up in smoke",
    "Go ___ (fizzle)",
    "Fizzling-out sound",
    "\"All gone!\""
  ],
  "rage": [
    "All the ___",
    "Choler",
    "Ranter's emotion",
    "Current fashion",
    "Fury",
    "Boil over",
    "Word repeated in the Dylan Thomas line \"___ against the dying of the light\"",
    "Anger",
    "Lose one's cool",
    "Rant and rave",
    "Craze",
    "Fury",
    "Fire and fury",
    "Fury",
    "Explosive anger",
    "Something hot",
    "All the ___",
    "Road hazard?",
    "All the ___",
    "What's really hot",
    "Fulminate",
    "Dernier cri",
    "The Incredible Hulk's feeling when he's green",
    "Fury",
    "Height of fashion",
    "Anger",
    "Oversize sunglasses, these days",
    "Blood-boiling state",
    "Burning sensation?",
    "All the ___",
    "Storm",
    "Fury",
    "Frequent feeling for 3-Down",
    "Huge fad",
    "Source of the Hulk's power",
    "Fury",
    "Fulminate",
    "In thing",
    "Violent 19-Across",
    "Latest thing",
    "Be uncontrolled",
    "Fashion",
    "\"Seeing red\" feeling",
    "Burning state",
    "Throw a fit",
    "Storm",
    "Frenzy",
    "Fury",
    "Fury",
    "Hula Hoops, once",
    "Fad",
    "Road ___",
    "Latest thing",
    "Fury",
    "Red state",
    "Craze",
    "Storm",
    "It may make your face red",
    "Fury",
    "Fury",
    "Fury",
    "Frenzy",
    "Fashion craze",
    "Storm",
    "Latest thing",
    "Angry display",
    "Road condition?",
    "Fury",
    "Go ballistic",
    "Fury",
    "Latest thing",
    "See red",
    "Fires do it",
    "Spread unchecked",
    "Foam at the mouth",
    "More than fume",
    "Foam at the mouth",
    "Road ___ (driver control problem)",
    "Latest thing",
    "Road ___",
    "Vogue",
    "Ferocity",
    "Blow up",
    "Throw a tantrum",
    "Storm",
    "Go ballistic",
    "Mania",
    "More than a vogue",
    "Foam at the mouth",
    "Latest thing",
    "Latest thing",
    "Frenzy",
    "Height of fashion",
    "Latest",
    "Fad",
    "Storm",
    "Furor",
    "Fire and fury",
    "Latest \"in\" fashion",
    "Fury",
    "Fad",
    "Fad",
    "Vogue",
    "Intense anger",
    "Furor"
  ],
  "also": [
    "\"And another thing ...\"",
    "To boot",
    "Start of an aside",
    "\"What's more ...\"",
    "To boot",
    "To boot",
    "Furthermore",
    "In addition",
    "\"And another thing ...\"",
    "Tangent introducer",
    "Not to mention",
    "At that",
    "Plus",
    "Additionally",
    "As well",
    "As well",
    "To boot",
    "Too",
    "What \"btw\" means",
    "Besides",
    "Additionally",
    "Plus",
    "It may precede second thoughts",
    "\"Not only that ...\"",
    "Plus",
    "Moreover",
    "\"Oh, by the way ...\"",
    "\"Oh, by the way ...\"",
    "\"I'm not done ...\"",
    "Likewise",
    "Afterthought indicator",
    "In addition",
    "Further",
    "In addition",
    "What's more",
    "What's more",
    "In addition",
    "Additionally",
    "In addition",
    "\"On top of that ...\"",
    "As well",
    "\"On top of that ...\"",
    "As well",
    "Too",
    "Plus",
    "In addition",
    "\"Oh, one more thing ...\"",
    "Too",
    "\"Not to mention ...\"",
    "\"And another thing ...\"",
    "\"Oh, I almost forgot ...\"",
    "Adding to that",
    "In addition",
    "Furthermore",
    "\"Not only that ...\"",
    "\"One more thing ...\"",
    "\"Not to mention ...\"",
    "As well",
    "Additionally",
    "And",
    "Further",
    "In addition",
    "\"In addition ...\"",
    "The first \"A\" of 51-Across",
    "Plus",
    "To boot",
    "In addition",
    "\"Wait! There's more ...\"",
    "Too",
    "As well",
    "In addition",
    "Too",
    "\"But wait! There's more ...\"",
    "Moreover",
    "\"And one more thing ...\"",
    "\"Before I forget ...\"",
    "As well",
    "\"Ran\" preceder",
    "\"Not to mention ...\"",
    "Too",
    "Furthermore",
    "\"See\" follower in a footnote",
    "\"One more thing ...\"",
    "Therewithal",
    "Further",
    "As well",
    "In addition",
    "To boot",
    "To boot",
    "Plus",
    "Further",
    "Plus",
    "As well",
    "Not to mention",
    "To boot",
    "\"Don't forget ...\"",
    "Dictionary word before a variant spelling",
    "Too",
    "Not to mention",
    "What's more",
    "Plus",
    "In addition",
    "Not to mention",
    "In addition",
    "Likewise",
    "By the same token",
    "Not forgetting",
    "Therewithal",
    "What's more",
    "Likewise",
    "To boot",
    "Besides",
    "Besides",
    "As well",
    "Further",
    "Additionally",
    "And",
    "In addition",
    "Additionally",
    "To boot",
    "To boot",
    "To boot",
    "Besides",
    "Additional thought preceder",
    "What's more",
    "Further",
    "\"Let's not forget ...\"",
    "To boot",
    "What's more",
    "On top of that",
    "In addition",
    "What's more",
    "To boot",
    "Besides",
    "Besides",
    "Plus",
    "To boot",
    "What's more",
    "Additionally",
    "What is more",
    "Besides",
    "As well",
    "In addition",
    "To boot",
    "What's more",
    "\"The Sun ___ Rises\"",
    "What's more",
    "As well",
    "Therewithal",
    "The first \"a\" in a k a",
    "What's more",
    "Plus",
    "Likewise",
    "To boot",
    "As well",
    "What's more",
    "Plus",
    "To boot",
    "Too",
    "\"Not only that...\"",
    "Too",
    "___-ran",
    "To boot",
    "Additionally",
    "To boot",
    "Therewithal",
    "To boot",
    "What's more",
    "\"Not to mention...\"",
    "Besides",
    "___-ran",
    "Plus",
    "As well",
    "As well",
    "Too",
    "Besides which",
    "As well",
    "\"Besides that...\"",
    "As well",
    "To boot",
    "Word with ran",
    "Furthermore",
    "Too",
    "Furthermore",
    "Too",
    "Too",
    "As well",
    "What's more",
    "In addition",
    "To boot",
    "In addition",
    "To boot",
    "What's more",
    "To boot",
    "In addition",
    "In addition",
    "What's more",
    "Too",
    "What's more"
  ],
  "yore": [
    "Long ago",
    "Antiquity",
    "Days of old",
    "Time long gone",
    "Days of old",
    "Arthurian times, say",
    "Long ago",
    "Time long past",
    "Old days",
    "\"Auld Lang Syne\" time",
    "Days of old",
    "Time long past",
    "Days gone by",
    "Days of ___",
    "Long past time?",
    "Days long gone",
    "Bygone times",
    "Times long, long ago",
    "Time long ago",
    "Knight time?",
    "Days of old",
    "Bygone days",
    "Days long past",
    "Old",
    "The olden days",
    "Long ago",
    "Days of ___ (past times)",
    "Days of ___",
    "Days gone by",
    "Days long gone",
    "Long ago",
    "Days of long ago",
    "Old",
    "Yesteryear",
    "Days long past",
    "Times past",
    "Old",
    "Days of King Arthur's Round Table, e.g.",
    "Days of old",
    "Days of ___",
    "Arthurian times, say",
    "The olden days",
    "Knight time?",
    "Long ago",
    "Knight time?",
    "Days of ___",
    "Olden times",
    "Time long past",
    "Knight time?",
    "Days of ___",
    "Knight's time",
    "Time long past",
    "Times past",
    "Old",
    "Knights of ___",
    "Days of ___",
    "Bygone time",
    "Days long past",
    "Time long past",
    "Days of ___",
    "Time past, literarily",
    "Those were the days",
    "Old times",
    "Days of ___",
    "Time past",
    "Past time",
    "Time long past",
    "Long ago",
    "Long ago",
    "Auld lang syne",
    "Time past",
    "Time long ago",
    "Knight time",
    "Long ago",
    "Bygone era",
    "In days of ___",
    "Days of old",
    "Time long past",
    "Yesteryear",
    "Long ago",
    "Days of long ago",
    "Yesteryear",
    "Time past",
    "Nostalgic time",
    "The olden days",
    "Time past",
    "Antiquity",
    "Distant past",
    "Knight time",
    "Days of the dinosaurs",
    "Knight time",
    "Long ago"
  ],
  "aide": [
    "Many an anonymous source on Capitol Hill",
    "Nurse's ___",
    "Helper",
    "Gofer",
    "Supporting role",
    "Number two",
    "Helper",
    "Person carrying a briefcase, maybe",
    "White House worker",
    "Assistant",
    "Hill worker",
    "Helper",
    "Political worker",
    "Congress person",
    "Starting job in Washington, say",
    "Assistant",
    "Adjutant",
    "Second",
    "Number two",
    "Second",
    "Helper",
    "Worker on the Hill",
    "Number two",
    "Many a staffer",
    "Number two",
    "Second hand",
    "Congressional staffer",
    "Many a staffer",
    "Congressional worker",
    "Errand runner, maybe",
    "One might represent a representative",
    "White House worker",
    "Number two",
    "West Wing worker",
    "Assistant",
    "Paraprofessional",
    "Senate staffer",
    "Second",
    "White House worker",
    "Second",
    "Secretery, e.g.",
    "Page, e.g.",
    "Congressional staffer",
    "West Wing worker",
    "Hospital worker",
    "Right-hand man or woman",
    "Page, e.g.",
    "Assistant",
    "Second",
    "Secretary, say",
    "Right hand",
    "Adviser, for one",
    "Helper",
    "Helper",
    "Congress person",
    "Iago, to Othello",
    "Gofer",
    "Page, e.g.",
    "Many a staffer",
    "Deputy",
    "Secretary, e.g.",
    "Right-hand person",
    "Second",
    "Congressional hire",
    "Capitol Hill worker",
    "Page, for example",
    "One who might be left holding the bag?",
    "Helper",
    "12-Down worker",
    "Right hand",
    "Page, e.g.",
    "Right-hand person",
    "Capitol Hill helper",
    "Senate page, e.g.",
    "Right hand",
    "Adviser, say",
    "Gofer",
    "Lieutenant",
    "Page, for one",
    "Second",
    "Staffer",
    "Capitol Hill worker",
    "___-memoire (summarizing note)",
    "White House worker",
    "Helper",
    "Assistant",
    "Number two",
    "Adjutant",
    "\"West Wing\" extra",
    "Second hand",
    "Man Friday, e.g.",
    "White House worker",
    "Right hand",
    "Flag lieutenant",
    "Presidential ___",
    "John Dean, to Nixon",
    "Helper",
    "Helper",
    "Right-hand person",
    "Personal staff member",
    "White House staffer",
    "Gofer",
    "Assistant",
    "Governmental appointee",
    "Gofer",
    "Campaign worker",
    "White House worker",
    "Detail handler, maybe",
    "Campaign worker",
    "Glorified gofer",
    "Auxiliary",
    "Capitol Hill worker",
    "Right-hand person",
    "Hospital staffer",
    "Flunky",
    "West Wing worker",
    "Helper",
    "Errand runner",
    "Man Friday",
    "White House figure",
    "Deputy",
    "Adjutant",
    "Gofer",
    "Helper",
    "Glorified gofer",
    "Candy striper, e.g.",
    "White House staffer",
    "Home health worker",
    "Adjutant",
    "Gofer",
    "Flunky",
    "McCurry, to Clinton",
    "Flunky",
    "Right-hand person",
    "Flunky",
    "One who takes messages",
    "Adjutant",
    "___-memoire",
    "White House operative",
    "Adjutant",
    "Gofer",
    "Right hand",
    "Deputy",
    "Deputy",
    "Message taker, e.g.",
    "Nurse's ___",
    "Assistance provider",
    "Details handler",
    "Man Friday",
    "Orderly",
    "Wearer of an aiguillette",
    "Igor, to Frankenstein",
    "Deputy"
  ],
  "asti": [
    "Italian wine region",
    "Sparkling wine variety",
    "Sparkling Italian wine",
    "Sparkling Italian wine",
    "Source of some bubbles",
    "Italian wine region",
    "Wine center near Turin",
    "___ Spumante (wine)",
    "Sparkling white wine",
    "Italian province where Moscato is produced",
    "Italian wine region",
    "Wine center near Turin",
    "Italian wine city",
    "Certain bubbly, informally",
    "Italian wine town",
    "Piedmont wine town",
    "Wine town in Piedmont",
    "Fizzy wine option",
    "Italian province or its capital",
    "Sparkling Italian wine",
    "Italian province known for sparkling wine",
    "Riesling alternative, familiarly",
    "Wine often served with dessert",
    "Piedmont city famous for its sparkling wine",
    "Piedmont wine center",
    "Italian wine town",
    "City near Turin",
    "Freisa d'___ (Italian wine)",
    "Sparkling Italian wine",
    "City ESE of Turin",
    "Moscato bianco grape product",
    "Italian bubbly",
    "Sparkling wine region",
    "Sparkling wine",
    "Bubbly source",
    "Alternative to Soave",
    "City between Turin and Genoa",
    "Bubbly option",
    "Piedmont city",
    "___ Spumante",
    "Barbera d'___ (Italian wine)",
    "Piedmont wine",
    "Italian wine city",
    "Wine region of Italy",
    "Italian wine hub",
    "___ Spumante",
    "Place for vino",
    "Italian province or its capital",
    "Barbera d'___ (red wine)",
    "Sparkling Italian export",
    "Italian wine area",
    "Sparkling white",
    "Palazzo Alfieri's locale",
    "Italian wine area",
    "City near Turin",
    "___ Spumante",
    "Vino place",
    "Dessert wine",
    "Italian wine city",
    "Italian wine city",
    "Italian vineyard region",
    "Italian wine region",
    "Italian wine center",
    "Wine region of Italy",
    "Piedmont wine town",
    "Winemaking province of Italy",
    "Source of bubbly",
    "Piedmont city",
    "Source of bubbly",
    "Palio di ___ (Italian horse race)",
    "Italian wine province",
    "Moscato d'___ (Italian wine)",
    "Italian province or its capital",
    "Italian wine region",
    "___ Spumante (wine)",
    "Wine spot",
    "Italian wine region",
    "Sparkling wine source",
    "Bubbly place?",
    "Sparkling wine locale",
    "Grignolino d'___ (Italian red wine)",
    "European wine region",
    "___ Spumante",
    "Italian wine city",
    "Where Monferrato wine comes from",
    "Wine made from the moscato bianco grape",
    "Sparkling wine city",
    "Town ESE of Turin",
    "Martini & Rossi offering",
    "Italian wine town",
    "Martini & Rossi product",
    "Riesling alternative, familiarly",
    "___ Spumante (wine)",
    "Wine-producing region of Italy",
    "___ Spumante",
    "Italian wine area",
    "City ESE of Turin",
    "Wine region",
    "Moscato d'___ (Italian wine)",
    "Italian province or its capital",
    "___ Spumante",
    "Piedmont wine city",
    "Italian wine region",
    "Italian wine-growing region",
    "Vino region",
    "Italian wine area",
    "Italian wine town",
    "Noted wine area",
    "___ Spumante",
    "Italian wine center",
    "Piedmont province",
    "Spumante region",
    "Piedmont city",
    "Fizzy wine, familiarly",
    "Piedmont city",
    "City on the Tanaro River",
    "Region NW of Genoa",
    "Winemaking region",
    "Italian wine town",
    "Piedmont city",
    "Italian province next to Torino",
    "Piedmont province",
    "___ spumante",
    "Piedmont province",
    "Italian wine region",
    "Sparkling wine, familiarly",
    "Spot of wine?",
    "Important wine region",
    "Spot of wine?",
    "Bubbly source",
    "___ spumante",
    "City southeast of Turin",
    "Piedmont province",
    "___ Spumante",
    "Sparkling wine center",
    "___ spumante",
    "Palazzo Alfieri locale",
    "Italian wine region",
    "Wine region",
    "Spot of Italian wine",
    "___ spumante",
    "Wine city near Turin",
    "___ spumante",
    "Piedmont wine center",
    "Vino region",
    "Spumante producer",
    "Italian wine province",
    "Piedmont province",
    "Sparkling wine source",
    "Piedmont wine center",
    "___ spumante",
    "Italian wine region",
    "Spot of wine?",
    "___ spumante (wine)",
    "___ spumante",
    "Wine region",
    "Piedmont wine city",
    "Sparkling wine spot",
    "___ spumante",
    "Italian wine center",
    "Italian wine region",
    "Italian wine region",
    "Vino region",
    "___ spumante",
    "Italian wine center",
    "___spumante",
    "___spumante",
    "Italian wine region",
    "Italian wine region",
    "Italian wine region",
    "Italian wine center",
    "Italian wine center",
    "Italian wine city",
    "Italian wine city",
    "Vino center",
    "Wine region",
    "___ spumante",
    "Italian wine region",
    "Spumante source",
    "Barberi d'___ (Italian wine)",
    "Italian wine district",
    "Italian wine center",
    "Spumante city",
    "Italian wine city",
    "Wine capital",
    "___ spumante",
    "Piedmont city",
    "___ spumante"
  ],
  "thor": [
    "A founding member of the Avengers",
    "Wielder of the hammer Mjölnir",
    "God who wields the hammer Mjölnir",
    "\"___: Ragnarok\" (2017 blockbuster)",
    "God with a chariot pulled by goats",
    "Partner of Iron Man and Captain America",
    "Superhero role for 50-Across",
    "Hammer-wielding Norse god",
    "Norse god with a hammer",
    "One of Thanos's foes in the Avengers movies",
    "\"___: Ragnarok\" (2017 film)",
    "\"___: Ragnarok\" (2017 Marvel film)",
    "Marvel role for Chris Hemsworth",
    "Avenger with a hammer",
    "Marvel Comics hero",
    "One of the Avengers",
    "Hammer-wielding deity",
    "Norse deity with a hammer",
    "Wielder of the hammer Mjolnir",
    "Red-bearded god",
    "2011 Marvel film",
    "2011 Marvel Comics film",
    "Norse god of war",
    "Two-time title role for Chris Hemsworth",
    "Loki's brother, in movies",
    "One of the superheroes in 2012's \"The Avengers\"",
    "Sif's husband in myth",
    "Norse thunder god",
    "Old satellite-launching rocket",
    "Dweller in the hall Bilskirnir",
    "Mythological hammer thrower",
    "2011 title role for Chris Hemsworth",
    "God with a day of the week named after him",
    "Old space-launched rocket",
    "Thursday's eponym",
    "Onetime part of the U.S. arsenal",
    "Hammer-wielding god",
    "Heyerdahl who wrote \"Kon-Tiki\"",
    "Mythical hammer wielder",
    "God whose wife had hair of finely spun gold",
    "Red-bearded god",
    "Mythical son of Jord",
    "Iron-gloved one",
    "Norse thunder god",
    "Hammer-wielding deity",
    "Marvel Comics superhero",
    "Norse thunder god",
    "God of thunder",
    "God with a hammer",
    "Celestial hammerer",
    "Goat-drawn chariot rider, in myth",
    "Marvel Comics hero",
    "Thunder god",
    "Son of Odin",
    "He had a hammer",
    "Thunderous one?",
    "Marvel Comics superhero",
    "Norse hammer thrower",
    "Norse bolt maker",
    "Comic book superhero",
    "Hammer-hurling god",
    "Red-bearded god",
    "God with iron gloves",
    "Midgard serpent's slayer",
    "God with a hammer",
    "Mjolnir's wielder",
    "Thunder god",
    "Red-bearded god",
    "Cartoon superhero",
    "Early U.S. rocket",
    "Marvel Comics superhero",
    "Explorer Heyerdahl",
    "Hammer wielder",
    "Air Force missile",
    "Shooter of thunderbolts",
    "Mighty god",
    "Thursday's eponym"
  ],
  "nori": [
    "Seaweed wrapped around sushi",
    "Seaweed used to wrap sushi",
    "\"Me neither\"",
    "\"Me neither\"",
    "Seaweed used to wrap sushi",
    "\"Me neither\"",
    "\"Me neither\"",
    "Edible algae used to wrap sushi",
    "\"Me neither\"",
    "\"Me neither,\" formally",
    "Seaweed in a sushi restaurant",
    "\"Me neither,\" formally",
    "\"Me neither\"",
    "\"Me neither,\" formally",
    "\"Me neither\"",
    "Sushi wrapper",
    "Seaweed at a sushi bar",
    "\"Me neither,\" formally",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither\"",
    "Seaweed wrapped around sushi",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither\"",
    "\"Me neither!\""
  ],
  "myth": [
    "The \"fact\" that bulls get angry when they see the color red, e.g.",
    "George Washington's chopping down a cherry tree, e.g.",
    "Old story",
    "Finding on Snopes.com",
    "Story about Zeus and Hera, e.g.",
    "Loch Ness monster, e.g.",
    "Story set on Mount Olympus, e.g.",
    "Paul Bunyan, e.g.",
    "That babies come from a 53-Across, e.g.",
    "One often dispelled",
    "What unicorns live in",
    "\"Toads cause warts,\" e.g.",
    "Belief that toads cause warts, for example",
    "Tale of the gods",
    "Washington chopping down the cherry tree, e.g.",
    "It's not to be believed",
    "It's not true",
    "Fable",
    "Don't believe it",
    "Story from Olympus",
    "Legendary story",
    "Story",
    "Item of folklore",
    "Bulfinch topic",
    "Camus's \"The___of Sisyphus\""
  ],
  "ties": [
    "No-win situations?",
    "Relations",
    "Deuces, e.g.",
    "Nobody wins them",
    "Draws",
    "Popular Father's Day gifts",
    "Catches up to",
    "Haberdasher's array",
    "Knots",
    "Draws",
    "Gets even with?",
    "20-20 and others",
    "Binds ... or a hint to the starts of the answers to the six starred clues",
    "Rackful in a closet",
    "Relationships",
    "Rackful in a closet",
    "Draws",
    "Knots",
    "Relationships",
    "Affectionate feelings",
    "They're often broken after being reached",
    "Knots",
    "Scores like 1-1 and 2-2",
    "Knots",
    "Makes ends meet?",
    "Deadlocks",
    "Musical marks",
    "Haberdashery display",
    "Lashes",
    "No-win situations?",
    "Couples",
    "Haberdashery stock",
    "Puts in knots",
    "Trusses",
    "Railroad fixtures",
    "Score markings",
    "Railroad necessities",
    "Baseball rarities",
    "Neckwear",
    "Games nobody wins",
    "Connections",
    "They might require overtime",
    "They bind",
    "Lashes",
    "Affiliations",
    "Relationships",
    "What extra innings break",
    "Binds",
    "Makes a bow",
    "Casual Friday casualties",
    "Men's store section",
    "No-win situations?",
    "Shoelaces",
    "Knots",
    "Connections",
    "20-20 and 7-up",
    "Moors",
    "Binders?",
    "No-win situations?",
    "Father's Day favorites",
    "Connections",
    "Binders",
    "Alliances",
    "Bonds",
    "Track components",
    "Gifts for fathers",
    "String and Windsor, e.g.",
    "Dad's Day gifts"
  ],
  "sake": [
    "Wine often served with 15-Across",
    "Drink served hot or cold",
    "Japanese beverage often served hot",
    "Fermented rice wine",
    "Drink in a little cup",
    "___ bomb (cocktail)",
    "Sushi bar drink",
    "Staple of Shinto rituals",
    "National beverage of 10-Across",
    "Rice-based drink",
    "Sashimi go-with",
    "\"For heaven's ___!\"",
    "Rice wine",
    "Ritual drink in Shintoism",
    "Drink sometimes served hot",
    "Sushi go-with",
    "Literally, \"liquor,\" in Japanese",
    "Japanese rice wine",
    "Sushi bar quaff",
    "Teriyaki go-with",
    "Clear libation popular in Japan",
    "Benefit",
    "Drink served in a masu",
    "Alcohol typically drunk warm",
    "Drink at a sushi bar",
    "Benefit",
    "Benefit",
    "Benefit",
    "Alcoholic beverage often served warm",
    "Drink at a sushi bar",
    "Drink with sushi",
    "Hot Japanese drink",
    "Purpose",
    "Drink with tempura",
    "Japanese drink",
    "Benefit",
    "Drink with sushi",
    "Sushi go-with",
    "Drink often served hot",
    "Rice wine",
    "Hot drink in a cup",
    "Benefit",
    "Drink sometimes served hot",
    "Purpose",
    "Benefit",
    "Rice wine",
    "Benefit",
    "Pete's follower",
    "Rice wine",
    "Consideration",
    "Cause",
    "\"Oh, for Pete's ___\"",
    "\"For heaven's ___!\"",
    "Sushi bar drink",
    "Account",
    "Benefit",
    "Benefit",
    "Japanese tipple",
    "Advantage",
    "\"For heaven's ___!\"",
    "Benefit"
  ],
  "pita": [
    "Gyro holder",
    "Bread that can be split open to contain a filling",
    "Taverna staple",
    "Fillable flatbread",
    "Something often served with shakshuka",
    "___ bread",
    "Falafel bread",
    "Pocket that isn't full of rye?",
    "Bread with hummus",
    "Gyro bread",
    "Gyro wrap",
    "Bread at a Greek restaurant",
    "Bread dipped in hummus",
    "Falafel bread",
    "Bread with a pocket",
    "Bread with a pocket",
    "Mediterranean restaurant staple",
    "Falafel holder",
    "Bread that might hold hummus",
    "Gyro bread",
    "Falafel holder",
    "Gyro bread",
    "Kind of bread",
    "Flat bread",
    "___ bread",
    "Gyro bread",
    "Bread with tabouli",
    "Kind of sandwich",
    "___ bread",
    "Falafel holder",
    "Some bread",
    "Bread for a gyro",
    "___ bread",
    "Falafel bread",
    "Bun alternative",
    "Hummus holder",
    "Hummus scooper-upper",
    "Option for a sandwich",
    "Sandwich bread",
    "Hummus holder",
    "Hummus holder",
    "Round bread",
    "Something folded before a meal",
    "Kind of bread",
    "Rye alternative",
    "Gyro holder",
    "Stuffed food",
    "Sandwich bread",
    "Outside of a gyro",
    "___ bread",
    "Kind of sandwich",
    "Kind of sandwich",
    "Pocket bread",
    "Bread for gyros",
    "Falafel bread",
    "Edible pocket",
    "Hummus accompaniment",
    "Falafel holder",
    "Gyro need",
    "Hummus holder, maybe",
    "Sandwich bread",
    "Hummus holder",
    "___ bread",
    "Sandwich base",
    "Sandwich choice",
    "Kind of bread",
    "Gyro bread",
    "Hummus holder",
    "Kind of bread",
    "Tabouli holder",
    "Pocket bread",
    "Pocket bread",
    "Pocket bread",
    "Sandwich material",
    "Kind of bread",
    "Pocket item?",
    "Gyro holder"
  ],
  "sole": [
    "Fish often served \"meunière\"",
    "Lone",
    "Only",
    "Shoe bottom",
    "Base of an arch",
    "Flounder relative",
    "Part of the foot",
    "Shoe part that's usually the first to wear out",
    "Flat part of a flat",
    "Fish that may be served meuniere",
    "Exclusive",
    "Step on it!",
    "Footprint maker",
    "Fish order",
    "Only",
    "Singular",
    "One and only",
    "Exclusive",
    "Lone",
    "*Shoe part",
    "Lone",
    "Palm : hand :: ___ : foot",
    "It's always getting stepped on",
    "Slipper part",
    "Filleted fish",
    "Kind of beneficiary",
    "One and only",
    "Fish often prepared with a meuniere sauce",
    "It's always underfoot",
    "Foot-long part",
    "Where the rubber meets the road?",
    "Unduplicated",
    "Exclusive",
    "Dover ___",
    "Common restaurant fish",
    "Only",
    "Dover delicacy",
    "Flat bottom?",
    "Flat bottom",
    "Only",
    "___ amandine",
    "Dover ___",
    "It's under a foot",
    "Lemon ___",
    "Lone",
    "Shoe bottom",
    "Print maker",
    "Shoe part",
    "Fillet of ___",
    "The heel is attached to it",
    "It's commonly filleted",
    "Exclusive",
    "One and only",
    "One and only",
    "Individual",
    "Like some heirs",
    "Only",
    "Exclusive",
    "Dover delicacy",
    "Low part of a high top",
    "Unduplicated",
    "Loafer's bottom",
    "Lemon ___",
    "Shoe bottom",
    "One and only",
    "Seafood selection",
    "Dish served with a lemon wedge",
    "Oxford foundation?",
    "Dover fish",
    "Filet of ___",
    "Shoe bottom",
    "___ proprietor",
    "Boot bottom",
    "Fish dish",
    "Exclusive",
    "One-and-only",
    "Lemon ___",
    "Oxford foundation",
    "Undivided",
    "Lone",
    "It's under a foot",
    "Shoe part that's wiped on 23-Down",
    "Unique",
    "Exclusive",
    "Step on it",
    "Kind of proprietor",
    "Shoe bottom",
    "Possible splinter site",
    "Single",
    "Slipper part",
    "\"O ___ Mio\"",
    "Dover ___",
    "Unique",
    "Exclusive",
    "Exclusive",
    "Dover specialty",
    "One and only",
    "Coastal catch",
    "Something underfoot?",
    "Only",
    "It leaves its mark on the beach",
    "Flounder's cousin",
    "Step on it",
    "Fish entree",
    "Fillet of ___",
    "Unique",
    "Only",
    "Seafood entree",
    "Fish from Dover",
    "Shoe bottom",
    "Exclusive",
    "Exclusive",
    "___ survivor",
    "Exclusive",
    "Only",
    "Filet, maybe",
    "Part of a pump",
    "___proprietorship",
    "Part of a foot",
    "Isolated",
    "Exclusive",
    "Shoe bottom",
    "Boot bottom",
    "Fileted fish",
    "Fish entree"
  ],
  "pall": [
    "Cloud of melancholy",
    "Gloomy atmosphere",
    "Third word ...",
    "Shroud",
    "It can be cast in a tragedy",
    "Funeral drape",
    "Feeling of gloom",
    "Coffin cover",
    "Grow wearisome",
    "What's borne at a funeral",
    "Become wearisome",
    "Dark cover",
    "Become tiresome",
    "Gloomy atmosphere",
    "Dark cloud",
    "Oppression",
    "10-Across atmosphere",
    "Heavy sheet",
    "___ Mall (London street)",
    "Velvet spread",
    "Velvet drape",
    "Gloomy atmosphere",
    "Cast a ___ over",
    "Ominous cloud",
    "Coffin cloth",
    "Gloomy shadow",
    "Grow tiresome",
    "Dark shadow"
  ],
  "cave": [
    "Give up arguing",
    "Spelunk",
    "Give in",
    "Give in",
    "Give up",
    "Give in",
    "Give up",
    "Like a satellite dish",
    "Batmobile \"garage\"",
    "Locale for Osama bin Laden",
    "Spelunker's hangout",
    "Bats' place",
    "Grotto",
    "Spelunker's setting",
    "Spelunking site",
    "Yield",
    "Give up, slangily",
    "Where bats \"hang out\"",
    "Stalactite site",
    "Bat's hangout",
    "Natural hideout",
    "Kind of art",
    "Bat's home",
    "\"B.C.\" abode",
    "Mammoth ___",
    "Cro-Magnon's home",
    "Mendelssohn's \"Fingal's ___\"",
    "Bat's home",
    "Bat's home",
    "Grotto",
    "It may be batty",
    "Locale for Ali Baba",
    "Fall (in)",
    "Cro-Magnon's home",
    "Neanderthals' home",
    "Speleology topic"
  ],
  "bass": [
    "Many an opera villain",
    "Role for Paul McCartney in the Beatles",
    "Bottom part",
    "Shoe company with a fish name",
    "Woofer output",
    "British brew since 1777",
    "Sound from a subwoofer",
    "Stereo knob",
    "Villain's part, often",
    "*The Mikado in \"The Mikado,\" e.g.",
    "One who can't hit high pitches?",
    "Prominent feature of dubstep music",
    "Kind of clef",
    "Deepest part",
    "Deep end?",
    "Stereo control",
    "___ clef",
    "Low-voiced chorus member",
    "Voice below baritone",
    "\"Largemouth\" fish",
    "Bottom part",
    "Striped swimmer",
    "Sam Adams alternative",
    "Sting's instrument",
    "See 64-Down",
    "Low man in the choir",
    "Boom box setting",
    "Jazz combo member",
    "Paul Robeson, e.g.",
    "Low part",
    "Stereo knob",
    "It's deep",
    "Bigmouth, for one",
    "Voice below baritone",
    "Grouper, e.g.",
    "Part of a jazz combo",
    "Treble's counterpart",
    "Paul McCartney played it for the Beatles",
    "Follower of the bottom line?",
    "Stereo control",
    "\"Nozze di Figaro\" part for Dr. Bartolo",
    "Low pitch",
    "Low voice",
    "Striped swimmer",
    "Low pitch",
    "It may be striped",
    "Stereo knob",
    "McCartney played it in the Beatles",
    "McCartney's instrument",
    "Blackfish or redeye",
    "Largemouth or smallmouth",
    "Low pitch?",
    "Male voice",
    "Like McCartney's guitar in the Beatles",
    "Barbershop quartet part",
    "Part of a combo",
    "Lowest voice",
    "Bigmouth, e.g.",
    "River fish",
    "With 32-Down, recipient of England's first trademark",
    "Opera villain, typically",
    "Lowest pitch",
    "Word with blue or sea",
    "Kind of drum or fiddle",
    "Striped fish",
    "Chorale part",
    "With 53-Down, a beverage brand since 1777",
    "Paul McCartney's instrument",
    "Low in pitch",
    "McCartney's instrument",
    "Smallmouth fish",
    "The low notes",
    "Contralto's counterpart",
    "Chorale part",
    "Striped fish"
  ],
  "aarp": [
    "Org. for seniors",
    "Influential D.C. lobby",
    "Org. with 50+ members",
    "Grp. that sends you a card for your 50th birthday",
    "50+ group",
    "Major D.C. lobby",
    "Org. whose website has a Social Security Resource Center",
    "50 or more letters?",
    "Org. looking out for seniors' interests",
    "___ The Magazine (highest-circulating magazine in the U.S.)",
    "Bestower of the Movies for Grownups Awards",
    "Grp. that offers driver safety courses",
    "Senior's org.",
    "Senior party?",
    "Grp. making after-work plans?",
    "Seniors' org.",
    "Lobbying org. for seniors",
    "Powerful D.C. lobby",
    "Seniors' org.",
    "D.C. lobby for seniors",
    "Advocate for the 50-and-over crowd",
    "Powerful D.C. lobby",
    "Advocate for seniors",
    "Org. whose magazine was once called Modern Maturity",
    "Big D.C. lobby",
    "___ The Magazine (bimonthly with 35+ million readers)",
    "Org. promoting Social Security solvency",
    "Powerful D.C. lobby",
    "50 or more people?",
    "Org. for seniors",
    "Big mailer to the over-50 crowd",
    "Seniors' org.",
    "Powerful D.C. lobby",
    "40 million-member org. founded in 1958",
    "Seniors' org.",
    "50+ org.",
    "Lobbying org. for seniors",
    "Srs.' lobby",
    "Seniors' org.",
    "50+ org.",
    "Org. for boomers, now",
    "Org. for boomers, now",
    "Powerful D.C. lobby",
    "U.S. org. with over 39 million members",
    "Powerful D.C. lobby",
    "Org. with the motto \"The power to make it better\"",
    "Grp. founded by a former high-school principal in 1958",
    "Org. for those 50+",
    "Org. for boomers, now",
    "Modern Maturity org.",
    "Powerful D.C. lobby",
    "Org. for boomers, now",
    "Soc. Sec. supporter",
    "50+ org.",
    "Org. for people 50 and over",
    "Powerful D.C. lobby",
    "Seniors' org.",
    "Seniors' grp.",
    "Over-50 grp.",
    "50-and-up org.",
    "50-and-up grp.",
    "Modern Maturity org.",
    "Modern Maturity grp.",
    "Modern Maturity grp.",
    "Seniors' PAC",
    "Over-50 org.",
    "Org. for the 20 Down"
  ],
  "bris": [
    "Jewish rite",
    "Rite with a mohel",
    "Eighth-day rite",
    "Rite for a newborn Jewish boy",
    "Eighth-day rite",
    "Jewish rite",
    "Rite for a newborn Jewish boy",
    "Jewish ritual"
  ],
  "moet": [
    "___ et Chandon",
    "Popular brand on New Year's Eve, informally",
    "Champagne name",
    "Big name in bubbly",
    "___ & Chandon (Champagne)",
    "Big name in bubbly",
    "Big name in Champagne",
    "Chandon's partner in Champagne",
    "Brand of bubbly, familiarly",
    "Chandon's partner",
    "Champagne name",
    "___ & Chandon (Champagne)",
    "Big name in bubbly",
    "___ & Chandon (Champagne)",
    "Bubbly brand, for short",
    "Bubbly choice",
    "Vintner Claude",
    "Big name in bubbly",
    "___ & Chandon Champagne",
    "Bubbly name",
    "Champagne name",
    "Name on a Champagne bottle",
    "___ et Chandon (Champagne)",
    "___ et Chandon",
    "Bubbly name",
    "___ & Chandon (champagne)",
    "___ & Chandon (champagne)",
    "Champagne name"
  ],
  "taps": [
    "Selects, as for a position",
    "Designates",
    "Music of the night?",
    "Bugs",
    "Nightly bugle call",
    "Tune also known as \"Butterfield's Lullaby\"",
    "Typing sounds",
    "Water spigots",
    "Call heard at Arlington",
    "Staple of Memorial Day services",
    "Chooses to lead",
    "Bugle tune ... or what one does to 1-, 18-, 35-, 43- and 62-Across",
    "24-note tune",
    "Call in the evening",
    "A little night music",
    "Restin' piece?",
    "Reveille's counterpart",
    "Military base tune",
    "Composition of only four different notes",
    "Memorial Day performance",
    "Bugler's melody",
    "It causes lights to go out",
    "Military funeral concluder",
    "Lager sources",
    "It's bugled on a base",
    "Bugler's bedtime tune",
    "Bugle tune",
    "Last call",
    "A little night music?",
    "Music at many a memorial",
    "Closing bars",
    "Lights-out signal",
    "Late-hours tune",
    "Bugler's evening call",
    "It's played at night",
    "Memorial Day solo",
    "Bugler's call",
    "Last call?",
    "Call on base",
    "Begins to use",
    "Base tune",
    "Last call?",
    "Draws off",
    "Spigots",
    "Shoe appendages",
    "Memorial Day solo",
    "G.I.'s lullaby?",
    "Veterans Day solo",
    "Call at camp",
    "A little night music",
    "Night music",
    "\"Lights out\" music",
    "A little night music",
    "\"Lights out\" tune",
    "A little night music",
    "Bugle solo",
    "A little night music",
    "A little night music",
    "\"Lights out\" indicator",
    "A little night music?"
  ],
  "hire": [
    "Bring on for a position",
    "Engage",
    "\"This Gun for ___\" (film noir classic)",
    "Put on the payroll",
    "Employ",
    "Add to the team",
    "Bring on board",
    "Add to the payroll",
    "Bring into the company",
    "Find a position for",
    "Take on",
    "Charter",
    "Fill a position",
    "New employee",
    "What a limo may be for",
    "Bring on board",
    "Fill a position",
    "Bring on board",
    "Take on",
    "Employ",
    "Get to work?",
    "Take on",
    "Bring into the business",
    "Engage",
    "Place on the payroll",
    "Put on the payroll",
    "Give a name badge, say",
    "Put on the payroll",
    "Take on",
    "Bring on board",
    "Engage",
    "Take on",
    "Bring on board",
    "Charter",
    "Take on, as employees",
    "Engagement",
    "Take on",
    "Take on",
    "Bring on, as an employee",
    "Take on",
    "Put on the payroll",
    "Bring on",
    "Charter",
    "Engage",
    "Take on",
    "Take on",
    "Engage",
    "Take on",
    "Take on",
    "Take 3, clue 1",
    "Put on the payroll",
    "Contract with",
    "Take on",
    "Take on",
    "Take on, as an employee",
    "Take on",
    "Take on",
    "Sign up",
    "Take on",
    "Take on",
    "Employ",
    "Bring on board",
    "Take on",
    "Put on staff",
    "Sign on"
  ],
  "room": [
    "Chamber",
    "Book it",
    "Live (together)",
    "Place on a Clue board",
    "Study, for instance",
    "Latitude",
    "Apartment unit",
    "ssorcA-41?",
    "Leeway",
    "What there often is for improvement",
    "Leeway",
    "S*O",
    "Scope",
    "Board's partner",
    "The \"where\" of a Clue accusation, whose identity is hinted at by the three shaded answers in this quadrant",
    "Boarder's domain",
    "Leeway",
    "Stay (with)",
    "Bath, e.g.",
    "Leeway",
    "___ and 59-Across (lodger's fee)",
    "One of nine on a Clue board",
    "See 10-Across",
    "No ___ at the 13-Down",
    "Blueprint feature",
    "Leeway",
    "Study, say",
    "Study, e.g.",
    "Leeway",
    "Latitude",
    "Inn's offering",
    "Space",
    "\"A ___ of One's Own\"",
    "Kitchen or bath",
    "Kitchen or bath",
    "See 59-Across",
    "Part of an accusation in the game Clue",
    "See 60-Across",
    "Blueprint feature",
    "Wiggle ___",
    "Study, say",
    "Scope",
    "Capacity",
    "Freedom",
    "Apartment part",
    "Study, say",
    "Scope",
    "Compartment",
    "Board's partner",
    "Kitchen or den",
    "Latitude",
    "Capacity",
    "Scope",
    "Latitude",
    "Board go-with",
    "Part of a Clue accusation",
    "Play, in a sense",
    "Compartment",
    "Space",
    "Kitchen, e.g.",
    "Ship cabin",
    "Part of a Clue accusation",
    "Ample scope",
    "Leeway",
    "Leeway",
    "Motel vacancy"
  ],
  "cray": [
    "Unhinged, in slang",
    "Supercomputing pioneer Seymour",
    "Out of one's head, in slang",
    "*Bonkers",
    "Bonkers, in modern lingo",
    "Big name in supercomputers",
    "Seymour ___, the Father of Supercomputing",
    "Supercomputer pioneer",
    "Supercomputer name",
    "Big name in computers",
    "Bluesman Robert",
    "Supercomputer maker",
    "Bluesman Robert"
  ],
  "noam": [
    "Author/activist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Cognitive scientist Chomsky",
    "Chomsky who wrote \"Syntactic Structures\"",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "\"Barney Miller\" Emmy winner Pitlik",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Professor Chomsky",
    "Pitlik who directed \"Barney Miller\" and \"Mr. Belvedere\"",
    "First name in linguistics",
    "Linguist Chomsky",
    "First name among linguists",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Sitcom director Pitlik",
    "First name in linguistics",
    "First name in linguistics",
    "First name in linguistics",
    "Linguist Chomsky",
    "Hebrew name meaning \"sweetness\"",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky",
    "Linguist Chomsky"
  ],
  "maps": [
    "Rand McNally's business",
    "Google ___",
    "Charts / Inbox distraction",
    "Google ___",
    "GPS graphics",
    "See 5-Across",
    "Google ___",
    "Google ___",
    "Rand McNally items",
    "Atlas contents",
    "Google ___",
    "Mariner's array",
    "Treasure-hunters' aids",
    "Gas station freebies, once",
    "Hikers' helpers",
    "Google ___",
    "Prepares a course for",
    "Ones given latitude?",
    "They may have keys",
    "Aids for treasure hunters",
    "Google feature",
    "National Geographic inserts",
    "Google service",
    "War room items",
    "Google heading",
    "Features of TV weather forecasts",
    "Tourist guides",
    "Products of Hammond",
    "Route revealers",
    "They have scales",
    "Place holders",
    "Cartographic products",
    "Atlas contents",
    "Cartographers' works",
    "They have scales and keys",
    "Mariners' needs",
    "They may provide relief",
    "War room items",
    "Vacation-planning aids",
    "Guidebook features",
    "They may provide relief",
    "Hammond products",
    "Hikers' needs",
    "Glove compartment items",
    "Hikers' needs",
    "Explorer's aids",
    "Rand McNally products"
  ],
  "obit": [
    "Final words?",
    "Last words?",
    "\"In memoriam\" piece",
    "Piece that often includes a D.O.B.",
    "Celebration of a life, for short",
    "Story of a lifetime, in brief",
    "Dead space?",
    "Piece of sad news",
    "Snippet with a funeral date",
    "Often-prewritten news article, for short",
    "Story of a lifetime, for short?",
    "End note?",
    "End piece?",
    "News item that its subject never reads",
    "Newspaper piece that always starts at the end?",
    "Passing mention?",
    "Last words?",
    "Passing note?",
    "Passing mention?",
    "Life lines?",
    "\"Late\" news item",
    "Notice after the expiration date?",
    "Write-up on the recently deceased",
    "Dead reckoning?",
    "Life lines?",
    "Black-bordered news item",
    "Expiration notice",
    "News item of passing concern?",
    "Words of remembrance, briefly",
    "Exit lines?",
    "Solemn column",
    "End note?",
    "Black-bordered news item",
    "Facts of life?",
    "Life after death?",
    "End notes?",
    "Final words?",
    "Article often bordered in black",
    "Departure announcement?",
    "Departure info?",
    "Black-bordered news item",
    "Something of yours you'll never see",
    "End piece?",
    "Bye lines?",
    "Article that may list survivors, in brief",
    "It has an expiration date",
    "News item listing surviving kin",
    "News item that often has a person's age in the headline",
    "Passing notice",
    "Passing notice",
    "Death notice, for short",
    "Late news?",
    "Life lines?",
    "Black-bordered news item",
    "Final notice?",
    "End piece?",
    "Sad news item",
    "It might be found, appropriately, in a newspaper morgue",
    "Passing mention?",
    "Newspaper notice",
    "Dying words?",
    "Passing remarks?",
    "Dying words",
    "Something of yours you can't see",
    "\"In memoriam\" item",
    "Black-bordered news item",
    "Life story",
    "End notes?",
    "Death notice",
    "Item of passing interest?",
    "Final notice",
    "Final farewell",
    "Memorial announcer",
    "Life lines?",
    "Final notice",
    "The last word",
    "Life sentences?",
    "News item often written in advance",
    "Final notice",
    "Life lines?",
    "Words in passing?",
    "Final notice",
    "End piece?",
    "Write of passage?",
    "Final words",
    "Passing mention?",
    "Passing notice",
    "\"Bye\" words?",
    "A few words in passing?",
    "Post mortem bio",
    "News item for a scrapbook",
    "Life preserver?",
    "Passing mention?",
    "Last words?",
    "Parting words",
    "Life preserver?",
    "Passing notice?",
    "Life sentences?",
    "Parting words?",
    "Final notice",
    "Passing notice",
    "Passing notice",
    "Notice in passing",
    "Passing piece",
    "Final say?",
    "Newspaper item",
    "Last writes?",
    "Notice of departure",
    "Final notice",
    "Biographer's item",
    "Final notice",
    "Final notice",
    "Back-of-paper news item",
    "Sad news item",
    "Final words",
    "Final notice",
    "Last writes?",
    "Final copy",
    "Last words",
    "Final notice"
  ],
  "vote": [
    "November exhortation",
    "Get out the ___",
    "Capitol Hill activity",
    "Democracy in action",
    "Primary concern",
    "End of the word ladder",
    "Yea or nay",
    "November imperative",
    "Primary imperative?",
    "Word on a political button",
    "Democracy in action",
    "Ballot",
    "Raise one's hand, say",
    "November exhortation",
    "With 31-Across, favor, as a ballot measure",
    "What a 25-Across may have lost",
    "Subject of the 19th, 24th and 26th Amendments",
    "What an office seeker seeks",
    "Yea or nay",
    "Show of hands, e.g.",
    "Drop paper in a box, maybe",
    "Privilege of those 18 and over",
    "End of many a race",
    "Go for a party, say",
    "It may result in a landslide",
    "Say yea or nay",
    "Susan B. Anthony's goal",
    "Cast a ballot",
    "Suspenseful part of \"Survivor\"",
    "Say \"aye,\" say",
    "Cast a ballot",
    "November catchword",
    "Declare up or down",
    "Stand up and be counted",
    "November exhortation",
    "Franchise",
    "Blackball, e.g.",
    "Have one's say, in a way",
    "Franchise",
    "November word"
  ],
  "goji": ["Berry touted as a \"superfood\""],
  "tuba": [
    "Heavy weight for a musician to bear?",
    "Instrument that largely replaced the ophicleide",
    "One likely to end on a low note?",
    "Big brass",
    "\"Oom\" producer",
    "Bass player",
    "Big blow for a musician?",
    "Big brass",
    "Instrument that makes the cheeks puff out",
    "Oompah-pah instrument",
    "Bass instrument",
    "It has a solo near the end of \"An American in Paris\"",
    "Marching band instrument",
    "March sound",
    "Big instrument in a marching band",
    "Big horn",
    "Big brass",
    "It's big and brassy",
    "Big band member",
    "Deep-toned instrument",
    "Heavy instrument to march with",
    "Oom-pah-pah instrument",
    "Sousaphone, e.g.",
    "Big brass",
    "Oompah band instrument",
    "Marching band instrument",
    "Part of a military band",
    "\"Symphonie Fantastique\" need",
    "Part of the back of an orchestra",
    "One with a big bell",
    "Hefty horn",
    "Oompah-paher",
    "Oktoberfest band instrument",
    "\"Tubby\" musical instrument",
    "Bass line provider",
    "Bass brass",
    "Band member",
    "Marching band staple",
    "Big brass",
    "Marching band member",
    "Low brass",
    "Big brass",
    "Marching band member",
    "Oompah instrument",
    "Big brass",
    "Big blow for a band?",
    "Vaughan Williams wrote a concerto for it",
    "Big horn",
    "Big brass instrument",
    "Part of a marching band",
    "Low note provider",
    "Band member",
    "Low blow?",
    "March instrument",
    "March need",
    "Bottom brass",
    "March blower",
    "Sousaphone cousin",
    "March instrument",
    "Part of a marching band"
  ],
  "euro": [
    "Currency launched on 1/1/1999",
    "Deutsche mark replacer",
    "Symbol that Mac users get by pressing Option + Shift + 2",
    "$ : dollar :: &euro; : ___",
    "It made the peseta passé",
    "Coin with 12 stars",
    "Replacement for the lira and mark",
    "Continental currency",
    "Drachma replacement",
    "Capital of Latvia",
    "Coin with 12 stars on one side",
    "Its symbol resembles a C with two lines across the center",
    "Tip of Italy?",
    "Coin that's been legal tender since New Year's Day in 2002",
    "Currency with a \"zone\"",
    "$ : dollar :: &euro; :",
    "Coin with a map on its reverse",
    "Currency unit worth a little more than a dollar",
    "Coin in Koln",
    "Currency of Italy or France",
    "Bread common to many countries",
    "Prefix with skeptic",
    "21st-century currency",
    "Symbol gotten by typing Option+Shift+2",
    "Tip of France?",
    "Coin of many countries",
    "It's worth a little more than a dollar",
    "It's a little less than a pound",
    "Mark's replacement",
    "Symbol gotten by typing Ctrl+Alt+E, in Microsoft Word",
    "Modern prefix with skeptic",
    "Currency of France or Italy",
    "Commercial lead-in to pass",
    "Alternative to a pound",
    "Lead-in to zone",
    "Latvian capital",
    "Not exactly old money",
    "Prefix with zone or trash",
    "Coin of France or Spain",
    "Koln coin",
    "Malta adopted it in 2008",
    "Mark's replacement",
    "Successor to the mark and franc",
    "Mark's successor",
    "Prefix with zone",
    "Coin whose front varies by country",
    "Prefix with zone and skeptic",
    "Start to pop?",
    "Two-tone coin",
    "Currency with a 20-cent coin",
    "It has paper denominations from 5 to 500",
    "Jack for Jacques?",
    "Continental coin",
    "Lead-in to pop or pass",
    "100 cents",
    "It replaced the franc and mark",
    "One featuring a Maltese cross",
    "___ Disney Resort (original name of Disneyland Paris)",
    "Replacement for the franc and mark",
    "French or Italian bread",
    "Continental coin",
    "Continental currency",
    "Multinational coin",
    "Multinational currency",
    "Continental coin",
    "Replacement for the mark and franc",
    "Prefix with zone",
    "New circulator of 2002",
    "Replacement for the mark, franc and lira",
    "Multinational currency",
    "Franc replacement",
    "Mark's replacement",
    "What has made some people miss the mark?",
    "It entered circulation in 2002",
    "Currency that replaced the drachma",
    "Coin with a map on its back",
    "Continental money",
    "Continental currency",
    "Continental coin",
    "100 cents",
    "Multinational currency",
    "24-Down replacement",
    "Peseta's replacement",
    "Continental money",
    "Coin with 12 stars on both the front and back",
    "Its symbol looks like an equal sign through a C",
    "Overseas capital",
    "100 cents",
    "Capital of Italy",
    "Lira's replacement",
    "31-Across replacer",
    "Capital of Italy",
    "Prefix with zone",
    "Money since 2002",
    "Continental currency",
    "___ Disney",
    "Coin across the Atlantic",
    "Continental coin",
    "Money since 2002",
    "Mark's replacement",
    "Lira's replacement",
    "Continental capital",
    "Transnational currency",
    "Dollar rival",
    "Guilder's replacement",
    "Continental coin",
    "Money in Madrid",
    "21st-century currency",
    "Money in Monaco",
    "Prefix with dollar",
    "Coin with 12 stars on it",
    "Currency that replaced 23-Across",
    "Mark's replacement",
    "Coin tossed in the 15-Across fountain, nowadays",
    "Continental currency",
    "Prefix with pop",
    "Continental coin",
    "Mark's replacement",
    "Continental currency",
    "International coin",
    "International money",
    "100 cents",
    "Continental \"dollar\"",
    "See 18-Across",
    "Money on the Continent",
    "Prefix with trash",
    "Continental currency",
    "Foreign money",
    "Replacer of the franc",
    "Mark's replacement",
    "It's about a buck",
    "100-cent unit",
    "Franc exchange",
    "It replaced the 10-Down",
    "Mark's successor",
    "Prefix with market",
    "Continental currency",
    "It's somewhat less than a pound",
    "Money at a casa de cambio",
    "Mark successor",
    "Newly minted money",
    "Continental capital",
    "New money",
    "It's replacing the lira",
    "Continental money",
    "Market opener?",
    "Disney opener",
    "Prefix with bond",
    "Shared currency unit",
    "Continental currency",
    "Mark alternative",
    "New coin",
    "Foreign exchange",
    "New money",
    "Speculator's target",
    "Buck overseas?",
    "Dollar rival",
    "New money",
    "Mark replacer",
    "New money on the Continent",
    "It's worth around a dollar",
    "Current currency",
    "Prefix with centric",
    "Continental currency",
    "Currency worth a little over a dollar",
    "Continental prefix",
    "New money",
    "New currency on the Continent",
    "International money",
    "Money in the making",
    "Currency replacing the mark, franc, lira, etc.",
    "Prefix with dollar or trash",
    "Prefix with dollar",
    "Prefix with bond",
    "From the Continent",
    "Prefix with dollar or trash",
    "Kind of bond",
    "___ Disney",
    "Dollar prefix",
    "Prefix with centric",
    "Prefix with Disney",
    "Prefix with Disney",
    "Prefix with dollars",
    "Kind of dollars"
  ],
  "nice": [
    "City on the French Riviera",
    "Determination from Santa",
    "\"I like what you did there\"",
    "\"Neat!\"",
    "Naughty's opposite",
    "\"Well played!\"",
    "\"I like it!\"",
    "\"Good going!\"",
    "See 34-Across",
    "\"Good job!\"",
    "Deserving, to Santa",
    "Pleasant",
    "Friendly",
    "Annotation on Santa's list",
    "\"Well done!\"",
    "\"I'm impressed!\"",
    "\"___ going!\"",
    "French city named after the Greek goddess of victory",
    "Naughty's opposite",
    "\"I'm impressed!\"",
    "\"Good going!\"",
    "See 8-Down",
    "\"Sweet!\"",
    "Terse compliment",
    "\"Ooh-la-la!\"",
    "\"Good job!\"",
    "Like guys who finish last, per Durocher",
    "Pleasant",
    "\"Good job!\"",
    "Not naughty",
    "Pleasing",
    "Diplomatic, say",
    "Riviera city",
    "\"Well done!\"",
    "\"Well played!\"",
    "Riviera city",
    "Well-mannered",
    "\"Good one!\"",
    "Opposite of naughty",
    "\"Well done!\"",
    "\"Sweet!\"",
    "\"Sweet!\"",
    "Respectable",
    "\"Good shot!\"",
    "\"Great shot!\"",
    "\"Good shot!\"",
    "\"Good job!\"",
    "Not naughty",
    "\"Way to go!\"",
    "\"Good one!\"",
    "\"Well done!\"",
    "\"Well done!\"",
    "Agreeable",
    "\"Good one!\"",
    "\"I'm impressed!\"",
    "\"Good going!\"",
    "Agreeable",
    "Pleasing",
    "Kindly",
    "\"Good shot!\"",
    "\"Well done!\"",
    "\"Good going!\"",
    "Heading on Santa's list",
    "Likable",
    "\"Well done!\"",
    "Delightful",
    "Riviera city",
    "Jim-dandy",
    "Swell",
    "\"Well done!\"",
    "\"Great shot!\"",
    "\"Good shot!\"",
    "\"Good one!\"",
    "Riviera city",
    "\"Good going!\"",
    "\"Well done!\"",
    "Fine",
    "\"Well done!\"",
    "Warm-hearted",
    "Enjoyable",
    "\"Treat Me ___\" (1957 Elvis song)",
    "Likable",
    "\"Good work!\"",
    "Riviera resort",
    "\"Well done!\"",
    "Not naughty",
    "Fine",
    "Congenial",
    "Cote d'Azur city",
    "City near Monaco",
    "Decorous",
    "Charming",
    "Garibaldi's birthplace"
  ],
  "atta": [
    "Lead-in to girl or boy",
    "Lead-in to girl",
    "Lead-in to boy or girl",
    "Flour in Indian cuisine",
    "Lead-in to girl or boy",
    "Former U.N. secretary general Kofi ___ Annan",
    "Intro to boy or girl",
    "Start of an encouragement",
    "Encouraging start?",
    "Lead-in that means \"Way to go!\"",
    "Lead-in to girl or boy",
    "Lead-in to boy or girl",
    "\"___ way!\" (words of congratulation)",
    "Lead-in to girl or boy",
    "Encouraging start?",
    "Congratulatory start",
    "Lead-in to girl",
    "Lead-in to girl or boy",
    "Girl or boy intro",
    "Lead-in to boy or girl",
    "Lead-in to boy or girl",
    "Lead-in to boy or girl",
    "Lead-in to girl or boy",
    "Kofi Annan's middle name",
    "\"___ baby!\"",
    "Lead-in to boy or girl",
    "Lead-in to boy",
    "Lead-in to boy or girl",
    "Lead-in to boy",
    "Lead-in to girl",
    "Lead-in to boy",
    "The U.N.'s Kofi ___ Annan",
    "Lead-in to boy or girl",
    "Slangy lead-in to \"way\"",
    "Lead-in to boy or girl",
    "The U.N.'s Kofi ___ Annan",
    "Lead-in to girl",
    "Lead-in to boy or girl",
    "Lead-in to \"boy!\" or \"girl!\"",
    "Lead-in to girl",
    "Lead-in to boy or girl",
    "Lead-in to girl",
    "Boy or girl lead-in",
    "Girl/boy intro",
    "Lead-in to boy",
    "\"___ baby!\"",
    "Start for boy or girl",
    "Lead-in to boy or girl",
    "Contraction before boy or girl",
    "Lead-in to boy or girl",
    "The U.N.'s Kofi ___ Annan",
    "Lead-in to boy or girl",
    "\"___ girl!\"",
    "Boy lead-in",
    "Lead-in to girl",
    "Lead-in to girl or boy",
    "Lead-in to a congratulatory cry",
    "Lead-in to girl",
    "Lead-in to girl",
    "Intro for boy or girl",
    "Encouraging start?",
    "The U.N.'s Kofi ___ Annan",
    "Girl or boy lead-in",
    "\"A Bug's Life\" princess",
    "Lead-in to boy",
    "The U.N.'s Kofi ___ Annan",
    "Boy/girl introduction",
    "\"___girl!\"",
    "Lead-in for boy or girl",
    "The U.N.'s Kofi ___ Annan",
    "\"___ boy!\"",
    "Intro for boy or girl",
    "\"___ boy!\"",
    "Boy or girl preceder",
    "See 8-Down",
    "Kofi ___ Annan",
    "Slangy lead-in for \"way\"",
    "Lead-in to girl",
    "Lead-in for boy or girl",
    "\"___ way!\"",
    "Girl lead-in",
    "Boy or girl lead-in",
    "\"Girl\" lead-in",
    "\"Girl\" lead-in",
    "Lead-in to boy or girl",
    "\"___ girl!\"",
    "Boy or girl preceder",
    "Start with boy or girl",
    "\"___ way!\" (\"You go, girl!\")",
    "\"___ girl!\"",
    "\"___ girl!\"",
    "Boy lead-in",
    "Start with boy or girl",
    "Girl lead-in",
    "Heine poem \"___ Troll\"",
    "Boy or girl lead-in",
    "Girl lead-in",
    "Boy preceder",
    "Start for girl",
    "Boy starter",
    "Girl starter",
    "Start of a cry of encouragement",
    "Boy preceder",
    "Boy or girl preceder",
    "\"___ boy!\"",
    "\"___ boy!\"",
    "Heine poem \"___ Troll\"",
    "Boy or girl preceder",
    "Start for boy or girl",
    "\"___ boy!\"",
    "\"___ boy!\"",
    "\"___ girl!\""
  ],
  "ache": [
    "Pain",
    "Bengay target",
    "What aspirin helps alleviate",
    "Hunger (for)",
    "Belly trouble",
    "Smart relative?",
    "Post-workout feeling",
    "It's gotta hurt",
    "Joint problem",
    "Word after head, heart or belly",
    "Feel sore",
    "Muscle pain",
    "Feel the burn",
    "Painful throb",
    "Feel the pain",
    "Pain",
    "Result of overexertion",
    "Pain in a tooth or the heart",
    "Long",
    "Pine",
    "Distress signal?",
    "Feeling in the long run?",
    "Painful throb",
    "Yen",
    "Great desire",
    "Long",
    "Hanker (for)",
    "Need ibuprofen, say",
    "Really long",
    "Post-marathon feeling",
    "It hurts",
    "Charley horse sensation",
    "Post-run feeling",
    "Lovesickness, e.g.",
    "Aftereffect from working out",
    "Throb",
    "Have a hole in one's heart",
    "Dull hurt",
    "Arthritis symptom",
    "Hunger",
    "Long",
    "Ice pack target",
    "Homesickness, e.g.",
    "Not just smart",
    "Feel it the next day, say",
    "Feel the pain",
    "Suffer from overexercise, maybe",
    "Feel pity (for)",
    "Pine (for)",
    "42-Down target",
    "Flu symptom",
    "Feel deep compassion",
    "Heartsickness, e.g.",
    "Really long?",
    "Really long",
    "Pine (for)",
    "Yearn (for)",
    "Hurt",
    "Heartbreak, e.g.",
    "Be sore",
    "Broken-heart symptom",
    "Really long",
    "5-Across target",
    "Hurt",
    "Joint concern",
    "Pine (for)",
    "Post-marathon feeling",
    "Yearn",
    "Bleed (for)",
    "Yearn (for)",
    "Longing",
    "Ibuprofen target",
    "Hurt",
    "Soreness",
    "Rheumatism symptom",
    "Longing",
    "Masseur's target",
    "Nagging pain",
    "Dull discomfort",
    "Result of overexercise",
    "Result of some heavy lifting",
    "Long",
    "Yearn (for)",
    "Workout aftereffect",
    "Yearn",
    "Dull hurt",
    "Pain",
    "Dull pain",
    "Pain in the neck",
    "Flu symptom",
    "Liniment target",
    "Yearn (for)",
    "Hurt",
    "Want badly, with \"for\"",
    "Rheumatism symptom",
    "Long (for)",
    "Result of overstrain",
    "Yearn (for)",
    "Workout aftermath",
    "Throb",
    "Have rheumatic pains",
    "Feel eager",
    "Long (for)",
    "Ibuprofen target",
    "Feel compassion",
    "Long",
    "Weekend warrior's woe",
    "Suffer from a charley horse",
    "Liniment target",
    "Yearn",
    "What a massage may ease",
    "Caladryl: itch :: Bengay : ___",
    "Heart problem",
    "Source of misery",
    "Cause for a massage",
    "Feel sore",
    "Aspirin target",
    "Flu symptom",
    "Pain",
    "Liniment target",
    "Pounding",
    "Yearn (for)",
    "Result of overexercise",
    "Yearn",
    "Need an ice bag",
    "Have a hankering",
    "Masseur's target",
    "Long",
    "Need Advil, say",
    "Dolor",
    "Need Bengay",
    "Sleep preventer",
    "Dull hurt",
    "Massage target",
    "Yearn (for)",
    "Suffer",
    "Need liniment",
    "Tummy trouble",
    "Overexerciser's woe",
    "Bit of infirmity",
    "Flu symptom",
    "Really smart",
    "Have a yen",
    "Stomach woe",
    "Yearn",
    "Yearn",
    "Flu symptom",
    "Feel sympathy (for)",
    "Tooth trouble",
    "Pain",
    "Cause for an aspirin",
    "Workout aftereffect",
    "Back problem",
    "Yearn",
    "Mourn (for)",
    "Advil target",
    "Long (for)",
    "Fluish feeling",
    "Soreness",
    "Harbinger of tooth trouble",
    "Arthritis complaint",
    "Reason for a backrub",
    "Yearn",
    "Reason for an ice pack",
    "Long (for)",
    "Discomfort",
    "Heart problem?",
    "Marathoner's woe",
    "Sign of old age",
    "Yearn (for)",
    "Medicinal side effect, maybe",
    "Long",
    "Overexercising result",
    "Yen",
    "Reason to see a dentist",
    "Pine (for)",
    "Result of overexercise",
    "Hurt",
    "Yearn (for)",
    "Result of overexercise",
    "Pain",
    "Pain in the neck?",
    "Yearn",
    "Get sore",
    "Marathoner's complaint",
    "Workout woe",
    "Pine (for)",
    "Be sore",
    "Be in pain",
    "Long",
    "Throb",
    "Tylenol target",
    "Feel sore",
    "Masseuse's target",
    "Distress signal",
    "Lumbago, e.g.",
    "Word with head or heart",
    "Long",
    "Masseur's target",
    "Workout aftermath",
    "Heart hurt",
    "Post-weeding blues",
    "Result of a strain",
    "Liniment target",
    "Yearn (for)",
    "Symptom for a dentist",
    "After-shoveling feeling",
    "Pine (for)",
    "Hypochondriac's complaint",
    "Yearn passionately",
    "Aspirin target",
    "Migraine, e.g.",
    "Sore spot",
    "Muscle malady",
    "Flu symptom",
    "Yearn",
    "Have pains",
    "Reason for balm",
    "Persistent pain",
    "Hypochondriac's complaint",
    "Starve (for)",
    "Patient's complaint",
    "Long",
    "Yearn",
    "Yearn (for)",
    "Have a hangover",
    "Tooth pain",
    "Flu symptom",
    "Be heartsick",
    "Yearn",
    "Pine",
    "Long (for)",
    "Cause for liniment",
    "Problem for a masseur",
    "Training-room complaint",
    "Need a backrub"
  ],
  "cinq": [
    "Five, in French",
    "Deux + trois",
    "Quarter of vingt",
    "Half of dix",
    "Dix halved",
    "Quatre + un",
    "V as in Versailles",
    "Quitting time in Quebec, maybe",
    "Rouge roulette number",
    "Roulette play",
    "Rouge roulette number",
    "Roulette bet"
  ],
  "goas": [
    "Dress like, for a costume party, say",
    "Impersonate on Halloween",
    "Dress like",
    "Dress like for a Halloween bash",
    "Impersonate, in a way",
    "Pretend to be, as at a Halloween party",
    "___-you-please (not tied down)"
  ],
  "hamm": [
    "Soccer great Mia",
    "Two-time FIFA Women's World Player of the Year (2001, 2002)",
    "Soccer star on a 1999 Wheaties box",
    "Soccer great Mia",
    "Mia who appeared on a Wheaties box in 1999",
    "Athlete with the 1999 guide \"Go for the Goal\"",
    "Soccer star Mia",
    "Jon of 2010's \"The Town\"",
    "U.S. soccer great Mia",
    "Actor Jon of \"Mad Men\"",
    "Soccer star Mia",
    "\"Mad Men\" star Jon",
    "Soccer great Mia",
    "Olympic gymnast Paul or Morgan",
    "2004 Olympics gymnastics star Paul or Morgan",
    "Last name of twin gymnasts in the 2004 Olympics",
    "Scorer of a record 158 goals",
    "Eponym of a classic Minnesota-brewed beer",
    "Soccer star Mia",
    "Mia of women's soccer",
    "Eponym of a classic Minnesota-brewed beer",
    "Soccer star Mia",
    "Soccer star Mia",
    "Soccer star Mia",
    "Hanseatic League city",
    "Soccer star Mia",
    "Soccer star Mia",
    "See 56-Across",
    "Soccer star Mia",
    "Luxembourg town where George Patton is buried"
  ],
  "whit": [
    "Iota",
    "Tiniest amount",
    "Tiny amount",
    "Scintilla",
    "Iota",
    "Tiniest amount",
    "Tiny amount",
    "Slightest amount",
    "Scintilla",
    "Jot",
    "Little bit",
    "Iota",
    "Shred",
    "The least bit",
    "Iota",
    "Jot"
  ],
  "only": [
    "Solitary",
    "Just",
    "\"Recycle ___\" (sign on a bin)",
    "Word before child or human",
    "Just",
    "Word before child or human",
    "Start for the Top 40 song titles \"Sixteen,\" \"Time\" and \"You\"",
    "Singular",
    "Sole",
    "The low, low price of",
    "The \"O\" of CD-ROM",
    "Apt rhyme for \"lonely\"",
    "Cash ___ (restaurant caveat)",
    "Members ___",
    "Just",
    "\"It's ___ a matter of time\"",
    "One's partner",
    "The first \"O\" in YOLO",
    "Exclusively",
    "Modifier of a low price",
    "Nothing but",
    "NPR's \"___ a Game\"",
    "Word before \"Sixteen,\" \"Time\" and \"You\" in top 10 hit songs",
    "Just",
    "No more than",
    "Title word before \"You,\" \"U\" or \"Yesterday\" in hit songs",
    "The \"O\" in S.R.O.",
    "Exclusively",
    "Just",
    "Kind of child",
    "Just",
    "Exclusively",
    "Word before a sale price",
    "Just",
    "\"___ You\" (1955 Platters hit)",
    "___ child",
    "One's partner",
    "Just",
    "The \"O\" in CD-ROM",
    "Exclusive",
    "No more than",
    "Right turn ___",
    "Just",
    "Word before a discounted price",
    "Just",
    "As recently as",
    "But",
    "Roy Orbison's \"___ the Lonely\"",
    "Nothing but",
    "As recently as",
    "\"Members ___\"",
    "Sole",
    "Sole",
    "Exclusive",
    "Unique",
    "Just",
    "The \"O\" in S.R.O.",
    "Just",
    "Exclusively",
    "One's partner",
    "Just",
    "One's partner",
    "As recently as",
    "Just",
    "Nothing but",
    "Kind of child",
    "Just",
    "As recently as",
    "The ?ôO?ö in ROM",
    "Child with no siblings",
    "\"For Your Eyes ___\"",
    "Just",
    "Part of S.R.O.",
    "Word before a discounted price",
    "Left Turn ___ (street sign)",
    "Just",
    "Part of ROM",
    "The \"O\" in S.R.O.",
    "Just",
    "Just",
    "Sole",
    "Nothing but",
    "Kind of child",
    "\"Members ___\"",
    "Just",
    "Unique",
    "Just",
    "Just",
    "No more than",
    "Just",
    "Just",
    "Nothing but",
    "Twitty's \"It's ___ Make Believe\"",
    "Exclusively",
    "No more than",
    "For men ___ (stag)",
    "\"___You\" (Platters hit)",
    "Harry Golden's \"___ in America\"",
    "One and ___",
    "One's partner"
  ],
  "clue": [
    "Aid in solving a mystery",
    "Tip-off",
    "Something dropped in a mystery novel",
    "Game with Mrs. White and Professor Plum",
    "Bit of evidence for Sherlock",
    "This, for one",
    "Single hair on a carpet, maybe",
    "This, e.g.",
    "This, for one",
    "Crime scene find",
    "What this is for 1-Down",
    "Whodunit game",
    "Mrs. Peacock's game",
    "\"Get a ___!\"",
    "\"Get a ___!\"",
    "This is one",
    "Detective's lead",
    "One of 77 in this puzzle",
    "Space cadet's need?",
    "Lead",
    "Fingerprint, perhaps",
    "Footprint, maybe",
    "It has nine rooms",
    "Miss Scarlet's game",
    "Game played with a rope",
    "Lipstick print, maybe",
    "Solving aid",
    "Footprint or fingerprint, say",
    "A ditz hasn't one",
    "Lead",
    "Colonel Mustard's game",
    "Mystery novel element",
    "Object under a magnifying glass, maybe",
    "What a loose thread might be",
    "Word in many a Nancy Drew title",
    "What a private eye may eye",
    "Game associated with the starts of 17-, 23-, 32-, 40-, 47- and 54-Across",
    "Game with Colonel Mustard and Mrs. Peacock",
    "Investigation aid",
    "Hint",
    "Footprint or loose thread, perhaps",
    "Game with Miss Scarlet and Professor Plum",
    "Word in many a Nancy Drew title",
    "Lead",
    "Tip-off",
    "Answer's opposite",
    "Detective's discovery",
    "Hint",
    "This is one",
    "Whodunit board game",
    "Whodunit board game",
    "Lead",
    "Help for a detective",
    "Sleuth's find",
    "Sleuth's need",
    "Crossword hint",
    "Inkling",
    "Footprint, maybe",
    "Airhead's lack?",
    "Sleuth's find",
    "See 24-Across",
    "Fingerprint, perhaps",
    "It may be found with a magnifying glass",
    "Print, maybe",
    "This, for example",
    "Fingerprint or dropped handkerchief, say",
    "Lead",
    "Help for Nancy Drew",
    "Tip-off",
    "Colonel Mustard's game",
    "Shoe impression, maybe",
    "Professor Plum's game",
    "Hint",
    "Mystery element",
    "Detectives' board game",
    "Game played with a knife",
    "Observatory site?",
    "Hunt hint"
  ],
  "turn": [
    "Word before \"the tables\" or \"the tide\"",
    "Go bad",
    "Zig or zag",
    "Chance to go",
    "Zig or zag",
    "Zig or zag",
    "Dice roll, say",
    "Not go straight",
    "Move",
    "Change colors",
    "Go bad",
    "Spin of the dial or roll of the dice",
    "Roll of the dice, say",
    "Spoil",
    "When tripled, 1965 Byrds hit",
    "Time to go",
    "Become a traitor",
    "Go time in a game",
    "Chance to play in a game",
    "Twist",
    "Chance to play",
    "*Right face, e.g.",
    "Roll of the dice, maybe",
    "Go bad",
    "Go bad",
    "Curve",
    "Go bad",
    "Go bad",
    "Come about",
    "Go left or right",
    "Go sour",
    "Spoil",
    "Change colors",
    "Go bad",
    "Spin",
    "Part of driving directions",
    "Wrench",
    "Become a traitor",
    "Go sour",
    "Take a dogleg, e.g.",
    "Time to make a move",
    "Swirl",
    "Rebel (against)",
    "Go bad",
    "Not go straight",
    "Out of ___",
    "Crank",
    "U, for one",
    "Go sour"
  ],
  "elsa": [
    "Disney princess voiced by Idina Menzel",
    "Wife of Albert Einstein",
    "Jewelry designer Peretti",
    "Disney's ___ of Arendelle",
    "Disney queen who sings in an ice palace",
    "Disney character loosely based on Hans Christian Andersen's \"Snow Queen\"",
    "Animated sister of princess Anna",
    "___ of Arendelle (Disney queen)",
    "Disney queen of Arendelle",
    "Singer of \"Into the Unknown\" in \"Frozen II\"",
    "\"Frozen\" queen",
    "Who sings \"Let It Go\" in \"Frozen\"",
    "\"Frozen\" queen",
    "\"Frozen\" snow queen",
    "Singer of \"Let It Go\" in Disney's \"Frozen\"",
    "\"Let It Go\" singer in \"Frozen\"",
    "Queen of Arendelle, in a Disney movie",
    "Queen in Disney's \"Frozen\"",
    "Snow queen in \"Frozen\"",
    "Disney snow queen",
    "\"Lohengrin\" soprano",
    "Wife of Albert Einstein",
    "To whom \"Do You Want to Build a Snowman?\" is sung",
    "Jewelry designer Peretti",
    "\"Let It Go\" singer, in film",
    "\"Let It Go\" singer in \"Frozen\"",
    "Fictional queen of Arendelle",
    "Two-time Oscar-nominated actress Lanchester",
    "Disney's Queen of Arendelle",
    "Italian novelist Morante",
    "\"Frozen\" princess",
    "\"Frozen\" snow queen",
    "Captain von Trapp's betrothed",
    "Lohengrin's love",
    "Mrs. Albert Einstein",
    "Disney queen voiced by Idina Menzel",
    "\"Frozen\" princess",
    "\"Frozen\" princess",
    "First name of an Oscar-nominated actress of 1957",
    "Mrs. Einstein",
    "Second wife of Einstein",
    "Princess in \"Frozen\"",
    "Queen in \"Frozen\"",
    "2013 film queen who sings \"Let It Go\"",
    "Film character who brings the snowman Olaf to life",
    "\"Frozen\" character who sings \"Let It Go\"",
    "Wife of Albert Einstein",
    "\"Lohengrin\" lady",
    "Jewelry designer Peretti",
    "\"Born Free\" lioness",
    "Orphaned lion of literature",
    "Snow queen in Disney's \"Frozen\"",
    "Joy Adamson's big cat",
    "\"Lohengrin\" lass",
    "Jewelry designer Peretti",
    "\"Born Free\" lioness",
    "\"Lohengrin\" heroine",
    "Mrs. Albert Einstein",
    "Lanchester of film",
    "Lanchester on the screen",
    "Lohengrin's love",
    "\"Born Free\" lioness",
    "Four-footed orphan of literature",
    "\"Lohengrin\" soprano",
    "Designer Schiaparelli",
    "\"Born Free\" lioness",
    "Supermodel Benitez",
    "Joy Adamson's lioness",
    "Designer Schiaparelli",
    "The bride in Wagner's \"Bridal Chorus\"",
    "Italian novelist Morante",
    "Dr. Schneider of \"Indiana Jones and the Last Crusade\"",
    "\"Born Free\" lioness",
    "Mrs. Albert Einstein",
    "Wagner's ___ of Brabant",
    "___ Schneider, villainess in \"Indiana Jones and the Last Crusade\"",
    "Designer Schiaparelli",
    "Fashion designer Schiaparelli",
    "\"Born Free\" lioness",
    "Old-time gossip queen Maxwell",
    "Designer Schiaparelli",
    "Movie lioness",
    "\"Bridal Chorus\" bride",
    "\"Lohengrin\" lass",
    "\"Born Free\" feline",
    "\"Born Free\" lioness",
    "\"Lohengrin\" role",
    "Schiaparelli of fashion",
    "Dr. ___ Schneider, historian who was a love interest of Indiana Jones",
    "Actress Lanchester, who married Charles Laughton",
    "Actress Pataky",
    "Opera heroine with the aria \"Einsam in truben Tagen\"",
    "Designer Schiaparelli",
    "Mrs. Einstein",
    "Jewelry designer Peretti",
    "Wagner heroine ___ of Brabant",
    "\"Bridal Chorus\" bride",
    "Lioness of book and film",
    "Couturiere Schiaparelli",
    "\"Born Free\" lioness",
    "\"Lohengrin\" soprano",
    "Supermodel Benitez",
    "Jewelry designer Peretti",
    "\"Born Free\" lioness",
    "\"Lohengrin\" soprano",
    "\"Born Free\" lioness",
    "Lanchester of film",
    "\"Born Free\" lioness",
    "The lady in \"The Lady From Shanghai\"",
    "Lanchester of film",
    "Adamson's lioness",
    "Fashion maven Klensch",
    "Lohengrin's love",
    "Wagner soprano",
    "Lohengrin's love",
    "Actress Lanchester",
    "Joy Adamson's cub",
    "Lohengrin's bride",
    "Literary lioness",
    "Designer Schiaparelli",
    "\"La Storia\" novelist Morante",
    "Jewelry designer Peretti",
    "Actress Lanchester",
    "Designer Schiaparelli",
    "Lohengrin's inamorata",
    "Opera character with a dream",
    "Leonine movie star",
    "Designer Schiaparelli",
    "Old radio's \"___ Maxwell's Party Line\"",
    "Opera lass who broke a vow",
    "Socialite Maxwell",
    "Film feline",
    "Literary lioness",
    "Novelist Morante",
    "Designer Peretti",
    "Style maven Klensch",
    "Noted socialite Maxwell",
    "Socialite Maxwell",
    "Hostess Maxwell",
    "Newswoman Klensch",
    "Film feline",
    "Actress Lanchester",
    "Fashion designer Schiaparelli",
    "Forbidden-question asker, in \"Lohengrin\"",
    "Designer Schiaparelli",
    "Lohengrin's bride",
    "Actress Martinelli",
    "\"Born Free\" lioness",
    "Movie lioness",
    "Literary lioness",
    "Actress Lanchester",
    "\"Born Free\" lioness",
    "Opera heroine who sings \"Einsam in truben Tagen\"",
    "Lohengrin's love",
    "Fashion's Klensch",
    "\"Born Free\" lioness",
    "\"Born Free\" lioness",
    "Old radio host Maxwell",
    "Socialite Maxwell",
    "Old socialite Maxwell",
    "\"Lohengrin\" miss",
    "Old socialite Maxwell",
    "Actress Lanchester",
    "\"Born Free\" lion",
    "\"Lohengrin\" soprano",
    "Gottfried's sister, in opera",
    "Onetime hostess Maxwell",
    "Lohengrin's love",
    "\"Lohengrin\" heroine",
    "Wagner girl",
    "Noted lioness",
    "Adamson heroine?",
    "Old columnist Maxwell",
    "Designer Schiaparelli",
    "Wagner heroine",
    "\"Lohengrin\" heroine"
  ],
  "nets": [
    "Safeguards for tightrope walkers",
    "Brooklyn basketball team",
    "Court suspensions?",
    "Brings home",
    "Safety features for tightrope walkers",
    "Mosquito guards",
    "N.B.A. team with black-and-white uniforms",
    "Badminton court fixtures",
    "Barclays Center team",
    "Brings in",
    "Ends up with",
    "Garners",
    "Captures",
    "Trawlers' equipment",
    "Clears",
    "Court equipment",
    "Keepsakes for March Madness victors",
    "Aids for butterfly collectors",
    "Makes after taxes",
    "New York team",
    "Barclays Center squad",
    "Barclays Center team",
    "Snags",
    "Brooklyn hoopsters",
    "Some safari camping gear",
    "Realizes",
    "Catches",
    "Ends up with",
    "Earns in the end",
    "Brooklyn squad",
    "N.B.A. team originally called the Americans",
    "Court suspensions",
    "Takes home",
    "Brooklyn team since 2012",
    "Clears after taxes",
    "Clears",
    "See 2-Down",
    "Gains",
    "Prudential Center team",
    "Keeps after taxes",
    "Realizes",
    "Fishermen cast them",
    "Safari equipment",
    "See 52-Across",
    "Pulls in",
    "Trawlers' gear",
    "Fishing aids",
    "Pulls in",
    "Cross-court items?",
    "Butterfly catchers' needs",
    "Safety equipment",
    "Meshes",
    "Brings in",
    "Butterfly catchers",
    "Court couple?",
    "Court hangers",
    "Clears",
    "Basketball rim attachments",
    "New Jersey hoopsters",
    "Takes home",
    "New Jersey hoopsters",
    "Separators on badminton courts",
    "Brings home",
    "Brings home",
    "Clears",
    "Brings home",
    "Is left with",
    "Ends up with",
    "Catchers",
    "New Jersey hoopsters",
    "Takes home",
    "Pulls in",
    "New Jersey hoopsters",
    "Realizes",
    "Lepidopterists' gear",
    "New Jersey five",
    "New Jersey hoopsters",
    "The pucks stop here",
    "New Jersey hoopsters",
    "Fishing aids",
    "Volleyball court dividers",
    "Butterfly catchers' needs",
    "Trawling equipment",
    "Mosquito guards",
    "Catches",
    "New Jersey hoopsters",
    "Team with which Derrick Coleman was the 1991 N.B.A. Rookie of the Year",
    "See 47-Across",
    "Ping-Pong table dividers",
    "Shrimpers' gear",
    "New Jersey hoopsters",
    "New Jersey hoopsters",
    "Captures",
    "Acrobats' security",
    "Realizes",
    "Clears",
    "Circus safety features",
    "Butterfly snarers",
    "Takes home",
    "New Jersey cagers",
    "Clears",
    "Trawlers' gear",
    "Court dividers",
    "New Jersey ___",
    "Circus safety equipment",
    "Clears",
    "Takes home, in a way",
    "Takes home",
    "Trawlers' trailers",
    "New Jersey hoopsters",
    "Acquires",
    "Takes home",
    "Acquires in the end",
    "Clearwing catchers",
    "Grouper grabbers",
    "Clears",
    "Clears",
    "New Jersey hoopsters",
    "New Jersey five",
    "Takes home",
    "Snags",
    "Takes home",
    "Brings in",
    "Circus props",
    "New Jersey five",
    "Trawler's equipment",
    "Trawlers' gear",
    "Meadowlands Arena team",
    "Lets touch them",
    "Mosquito protection",
    "New Jersey team",
    "Clears",
    "Veil fabrics",
    "New Jersey five",
    "Meshes",
    "Jersey five",
    "Realizes",
    "Seines",
    "Fox and others, informally",
    "Earns as profit",
    "New Jersey five",
    "Snares",
    "Captures",
    "Meadowlands team"
  ],
  "used": [
    "Secondhand",
    "Like thrift shop wares",
    "Like most books offered on AbeBooks.com",
    "Kind of bookstore",
    "Like items at a Goodwill store",
    "Not new",
    "Handled",
    "___ car salesman",
    "Not new",
    "Imposed upon",
    "Not new",
    "Secondhand",
    "Kind of bookstore",
    "Played for a fool",
    "Not new",
    "Car lot designation",
    "No longer mint",
    "Like pawns and puppets",
    "Amazon category",
    "Took advantage of",
    "Played for a sap",
    "___ car",
    "Exhausted",
    "Preowned",
    "Like most consignment shop items",
    "Like many Craigslist items",
    "Like some textbooks",
    "How many college textbooks are bought",
    "Played for a sap",
    "Like Goodwill wares",
    "Not new",
    "Like trade-ins",
    "Accustomed (to)",
    "Secondhand",
    "Not new",
    "With 6-Down, old wheels",
    "Preowned",
    "Secondhand",
    "Like Goodwill goods",
    "Like thrift store merchandise",
    "Milked",
    "Milked",
    "Not new",
    "Not mint",
    "Took advantage of",
    "Like many items listed on eBay",
    "Like a car with 20,000 miles on its odometer",
    "Drew on",
    "Not new",
    "Preowned",
    "Exploited",
    "Like some clothing",
    "Took advantage of",
    "Like thrift shop wares",
    "Broken in, say",
    "___ car dealer",
    "Preowned",
    "Not mint",
    "Secondhand",
    "Car category",
    "After 2004, the only way to buy a 14-Across",
    "Handled",
    "Played for a cat's-paw",
    "New's opposite",
    "Played for a sap",
    "Like many products on eBay",
    "Drew on",
    "Preowned",
    "Hand-me-down",
    "Utilized",
    "Not mint",
    "___ to be",
    "Not new",
    "Did something with",
    "Like antiques",
    "Inured (to)",
    "Worn",
    "Spent",
    "Car category",
    "How the duped feel",
    "Not new",
    "Drew on",
    "Not mint",
    "Brought into play",
    "Hand-me-down",
    "Preowned",
    "From the Salvation Army, say",
    "Worked with",
    "Drew on",
    "Played for a sap",
    "Hand-me-down",
    "Took advantage of",
    "Took advantage of",
    "Not new",
    "Like Goodwill goods",
    "Subject to markdown, say",
    "Not new",
    "Not new",
    "Like most yard sale goods",
    "Secondhand",
    "Took advantage of",
    "No longer mint",
    "Like some bookstores",
    "Not mint",
    "Kind of car",
    "Played for a sap",
    "Drew on",
    "Thrift shop condition",
    "Reconditioned, e.g.",
    "Took habitually",
    "Inured",
    "Adapted (to)",
    "Pre-owned",
    "Employed",
    "Kind of car",
    "Did something with",
    "Drew on",
    "No longer mint",
    "Like hand-me-downs",
    "Worn",
    "Preowned",
    "Preowned",
    "Not mint",
    "Took advantage of",
    "Preowned",
    "Not new",
    "Brought into play",
    "Previously owned",
    "Not new",
    "Worn",
    "Wielded",
    "Depleted",
    "Worn",
    "Like some books",
    "Took unfair advantage of",
    "Previously owned",
    "Worn",
    "Second hand",
    "Preowned",
    "Put to work for oneself",
    "Wont (to)",
    "Hand-me-down",
    "Played for a sucker",
    "Played for a sucker",
    "Played for a fool",
    "Employed",
    "Like some cars",
    "Gone, with \"up\"",
    "Pre-owned"
  ],
  "pray": [
    "Address God",
    "Talk up?",
    "Hope beyond hope",
    "Really, really hope",
    "Do so hope",
    "Request intervention",
    "Say grace, e.g.",
    "Supplicate",
    "\"Let us ___\"",
    "Say grace, e.g.",
    "Recite the rosary, e.g.",
    "Appeal to God",
    "Speak with folded hands, say",
    "Say grace, say",
    "Ask",
    "Speak up?",
    "Do penance, say",
    "Supplicate",
    "Talk in the service, perhaps",
    "Say the rosary",
    "Talk up?",
    "Entreat",
    "Beseech",
    "Say the paternoster",
    "Talk in church",
    "Recite the rosary, e.g.",
    "Implore",
    "Ask",
    "Appeal",
    "Request fervently",
    "\"Let us ___\"",
    "Make an appeal",
    "\"___ tell\"",
    "Wish",
    "Devoutly wish",
    "Supplicate"
  ],
  "aone": [
    "Top-notch",
    "Prime",
    "Tops",
    "Steak sauce brand",
    "Top-notch",
    "Five-star",
    "Superlative",
    "Super-quality",
    "Top of the line",
    "Finest-quality, informally",
    "Dynamite",
    "Top-of-the-line",
    "First-class",
    "Top-quality",
    "Top-notch",
    "Top of the line",
    "Super-duper",
    "Top-notch",
    "Great",
    "Tops",
    "Top-notch",
    "Top-notch",
    "Of the highest rank",
    "Top-notch",
    "Top-notch",
    "Tops",
    "Primo",
    "Choice",
    "Top-shelf",
    "Square for a white rook, in chess notation",
    "Best-of-the-best",
    "Nary ___",
    "Super",
    "Unbeatable",
    "Top-shelf",
    "Best of the best",
    "Nary ___",
    "Top-shelf",
    "The best",
    "Sterling",
    "Dynamite",
    "Top-notch",
    "Bang-up",
    "Bang-up",
    "Super-duper",
    "Tops",
    "Super-duper",
    "Top of the line",
    "1-Across, taking into account its 61-Across",
    "Top-of-the-line",
    "The best",
    "Super-duper",
    "Top-flight",
    "First-rate",
    "Highly rated",
    "Bang-up",
    "Tops",
    "Primo",
    "U.S. visa type issued to visiting diplomats",
    "Splendid",
    "Super-duper",
    "Topnotch",
    "Crack",
    "*Tops",
    "Superior",
    "Prime",
    "Best-of-the-best",
    "Super-duper",
    "Top-notch",
    "Popular steak sauce",
    "Choice",
    "Super-duper",
    "Excellent",
    "Five-star",
    "Tiptop",
    "Crack",
    "Top-notch",
    "Primo",
    "Super-duper",
    "Top status",
    "With \"and\" and 47-Down, Lawrence Welk's intro",
    "Dynamite",
    "Blue-ribbon",
    "Absolutely the best",
    "Primo",
    "Top-rated",
    "Superior",
    "Rook's spot on a chessboard",
    "Five-star",
    "Super-duper",
    "Dynamite",
    "Top-shelf",
    "Crackerjack",
    "*Primo",
    "Super-duper",
    "Tops",
    "Tops",
    "Primo",
    "Bang-up",
    "Top-notch",
    "Super-duper",
    "Tops",
    "Blue-ribbon",
    "Top-of-the-line",
    "First-rate",
    "Tops",
    "Nonpareil",
    "Top-notch",
    "Super-duper",
    "First-rate",
    "Top-notch",
    "Primo",
    "Primo",
    "Dynamite",
    "Tops",
    "Tops",
    "Top-notch",
    "Top-of-the-line",
    "Top-drawer",
    "Top-notch",
    "Top-rated",
    "Top-notch",
    "Excellent",
    "Super-duper",
    "Jim-dandy",
    "Top-notch",
    "First-class",
    "Super-duper",
    "Top-drawer",
    "Top-notch",
    "Excellent",
    "Outstanding",
    "Top-notch",
    "Excellent",
    "Primo",
    "Peerless",
    "Five-star",
    "Tiptop",
    "Jim-dandy",
    "Four-star",
    "Super ... or where a super might live",
    "Top-notch",
    "First-rate",
    "Top-notch",
    "Unmatched",
    "Tip-top",
    "Top-notch",
    "Super-duper",
    "Tops",
    "Top-drawer",
    "Tops",
    "Excellent",
    "Top-notch",
    "Tops",
    "First-rate",
    "Prime",
    "Top-flight",
    "Tops",
    "Tops",
    "Superb",
    "First-class",
    "Super-duper",
    "Tops",
    "Tops",
    "Primo",
    "Top-flight",
    "First-class",
    "Superb",
    "Top-drawer",
    "Bang-up",
    "Top-flight",
    "Top-drawer",
    "Tiptop",
    "First-class",
    "Excellent",
    "Super",
    "Superior",
    "Top-of-the-line",
    "Top-notch",
    "Top of the line",
    "Top-drawer",
    "Top of the line",
    "First-class",
    "Top-flight",
    "Great"
  ],
  "cole": [
    "Rapper J. ___",
    "Suitable-sounding name for a kid on Santa's naughty list?",
    "King of a nursery rhyme",
    "\"This Will Be\" singer Natalie",
    "Porter, for one",
    "Suspect #1",
    "Porter of note",
    "___ slaw",
    "\"Born Sinner\" rapper J. ___",
    "Artist Thomas ___, founder of the Hudson River School",
    "King of rhyme",
    "He had a 1948 #1 hit with \"Nature Boy\"",
    "___ slaw",
    "Singer Nat King ___",
    "Nat or Natalie",
    "___ Hamels, 2008 World Series M.V.P.",
    "King of verse",
    "___ Hamels, 2008 World Series M.V.P.",
    "Porter who wrote \"Night and Day\"",
    "Porter, for one",
    "___ slaw",
    "Designer Kenneth",
    "Thomas ___, artist of the Hudson River School",
    "With 68-Across, fish filet go-with",
    "___ slaw",
    "Nat or Natalie",
    "___ slaw",
    "Michael of \"The Mod Squad\"",
    "U.S.S. ___, ship in 2000 news",
    "___ SLAW",
    "Artist Thomas ___, founder of the Hudson River School",
    "___ slaw",
    "Merry king",
    "Plant chopped up in salads",
    "___ slaw",
    "King ___ Trio (popular 40's combo)",
    "Nat King or Natalie",
    "\"Unforgettable\" singer",
    "\"Merry old\" king of rhyme",
    "First black to host a TV series",
    "Composer Porter",
    "Old King ___",
    "Nat or Natalie",
    "Big band drummer Cozy ___",
    "Fiddlers' king"
  ],
  "song": [
    "Serenade, e.g.",
    "Track, say",
    "Low, low price, in an expression",
    "Karaoke selection",
    "Chinese dynasty ended by Kublai Khan",
    "Single, say",
    "Ballad, e.g.",
    "iTunes download",
    "Playlist listing",
    "Track, in a sense",
    "Product of Boston or Chicago",
    "Spotify selection",
    "Spotify selection",
    "Ballad, e.g.",
    "Record number?",
    "Part of a countdown",
    "Aid in identifying a bird",
    "\"Yesterday\" or \"Tomorrow\"",
    "\"Row, Row, Row Your Boat,\" e.g.",
    "See 27-Down",
    "It may be broken into on Broadway",
    "What you might break into",
    "Dance partner?",
    "Something to break into",
    "\"Both Sides Now,\" for one",
    "iTunes selection",
    "Any one of the Top 40",
    "Dance's partner",
    "Number",
    "MP3 download",
    "Bird call",
    "Spiritual, e.g.",
    "Track",
    "Spiritual, e.g.",
    "Dance partner?",
    "Product of Berlin",
    "See 34-Across",
    "Dance partner?",
    "Lay",
    "It has bars",
    "Tune",
    "Psalm",
    "A bird may have one",
    "Noted work?",
    "Lay",
    "Dance partner",
    "Dance's partner",
    "Carol",
    "Anthem",
    "Disney's \"___ of the South\"",
    "Tin Pan Alley product",
    "Ballad",
    "Something to break into",
    "Carol",
    "Dance partner",
    "Lullaby",
    "Noted presentation?",
    "It comes in bars",
    "With 63 Across, \"Zip-A-Dee-Doo-Dah\" movie",
    "Something to break into",
    "Spiritual, e.g."
  ],
  "prom": [
    "Big night for a high schooler",
    "___ dress",
    "Dance with a king and queen",
    "Big event for high school seniors",
    "Spring dance",
    "Ball in a gym, maybe",
    "Occasion for a high school after-party",
    "School event with a king and a queen",
    "Place to wear a tuxedo",
    "Place for a king and queen",
    "Seniors' big night out",
    "Party that often has an after-party",
    "Senior moment?",
    "Last dance?",
    "Spring school dance",
    "Event presided over by a king and queen",
    "Big school dance",
    "Event with a king and queen",
    "Occasion to choose a royal court",
    "Senior's big night out",
    "Formal, maybe",
    "Dance with a king",
    "Gym ball?",
    "Dance for 1-Down",
    "High school's crowning event?",
    "Formal occasion",
    "Annual coronation site",
    "Where a king may be crowned",
    "It's a ball",
    "Event for a king and queen",
    "Senior moment?",
    "Spring event",
    "Event for limos",
    "Where a queen may be crowned",
    "See 32-Across",
    "Senior event",
    "Teen happening",
    "Senior moment?",
    "Limo destination, perhaps",
    "May dance, maybe",
    "Place for a king and queen",
    "May school event, often",
    "It's a ball",
    "Big night for teens"
  ],
  "hone": [
    "Sharpen",
    "Perfect, as skills",
    "Put an edge on",
    "Sharpen",
    "Give an edge",
    "Sharpen",
    "Sharpen, as skills",
    "Improve gradually, say",
    "Put an edge on",
    "Perfect",
    "Sharpen, as one's skills",
    "Practice to improve",
    "Perfect",
    "Fine-tune",
    "Sharpen, as a skill",
    "Sharpen, as skills",
    "Improve",
    "Get an edge on",
    "Practice",
    "Sharpen",
    "Perfect",
    "Refine",
    "Sharpen",
    "Sharpen",
    "Practice, as skills",
    "You might rub a knife across it",
    "Make more efficient",
    "Aid in gaining an edge",
    "Fine-tune",
    "Sharpen, as a knife",
    "Improve",
    "Opposite of dull",
    "Sharpen",
    "It'll give you an edge",
    "Put an edge on",
    "Improve",
    "Sharpen, as a knife",
    "Sharpen, as skills",
    "Fine-tune",
    "Perfect",
    "Improve",
    "Fine-tune",
    "It might help you make the cut",
    "Apply to a whetstone",
    "Give the edge to?",
    "Perfect",
    "Work on the edge?",
    "Perfect",
    "Fine-tune",
    "It'll give you the edge",
    "Practice, as skills",
    "Put an edge on",
    "Perfect",
    "Sharpen",
    "Give an edge",
    "Use a whetstone on",
    "Give an edge to",
    "Sharpen",
    "Sharpen",
    "Improve",
    "Perfect",
    "Sharpen",
    "Perfect",
    "Edge",
    "Improve, as one's skills",
    "Sharpen",
    "Sharpen, as on a whetstone",
    "Fine-tune",
    "Fine-tune",
    "Sharpen",
    "Sharpen",
    "Improve, in a way",
    "Sharpen",
    "Grind, maybe",
    "Perfect",
    "Give an edge to",
    "Sharpen",
    "Sharpen",
    "Perfect",
    "Whet",
    "Sharpen, as a razor",
    "Perfect",
    "Whetstone",
    "Fine-tune",
    "Improve",
    "Sharpen",
    "Perfect",
    "Grind, in away",
    "Practice, so to speak",
    "Oilstone",
    "Use a whetstone",
    "Use of oilstone"
  ],
  "esau": [
    "Biblical father of Eliphaz",
    "Hirsute figure in the Bible",
    "\"I saw ___, he saw me\" (classic tongue twister line)",
    "Jacob's brother, in the Bible",
    "Twin in Genesis",
    "Isaac and Rebekah's firstborn",
    "Biblical twin of Jacob",
    "Biblical twin",
    "Isaac's firstborn",
    "Twin of Jacob in the Bible",
    "Hairy hunter of Genesis",
    "A son of Isaac",
    "Isaac's firstborn",
    "Jacob's biblical twin",
    "Biblical birthright seller",
    "Biblical birthright seller",
    "Biblical son of Isaac",
    "Grandson of Abraham",
    "Progenitor of the Edomites, in the Bible",
    "Biblical twin",
    "Isaac's elder son",
    "Brother mentioned more than 70 times in Genesis",
    "Jacob's twin",
    "Isaac's firstborn",
    "Biblical twin",
    "Son of Rebekah",
    "Biblical brother with a birthright",
    "Jacob's twin",
    "Jacob's biblical twin",
    "Hairy hunter of Genesis",
    "Biblical polygamist",
    "Biblical son of Isaac",
    "Red-haired biblical twin",
    "Hairy one in a Bible story",
    "Biblical figure referred to as a \"son of the desert\"",
    "Jacob's womb-mate",
    "He married two Hittites to the chagrin of his parents, in Genesis",
    "His brother was given the name Israel",
    "Twin of Jacob",
    "Missing name in the tongue twister \"I saw ___ sawing wood ...\"",
    "Biblical twin described as a \"cunning hunter\"",
    "Whom \"I saw\" on a seesaw, in a tongue twister",
    "Joseph's uncle, in Genesis",
    "One of a reconciling pair painted by Rubens",
    "Twin of Jacob and in-law of 30-Down",
    "Biblical twin",
    "Kate's kisser in a classic tongue twister",
    "Subject of a prophecy in Genesis",
    "Brother of Jacob",
    "Jacob's twin",
    "Son of Isaac",
    "Hairy son of Isaac",
    "Edomite patriarch",
    "Biblical venison preparer",
    "Genesis' \"man of the field\"",
    "Older son of Isaac",
    "Jacob's twin",
    "One of Isaac's twins",
    "Genesis man",
    "Birthright seller in the Bible",
    "Twin of Jacob",
    "Grandson of Abraham",
    "Biblical twin",
    "Biblical progenitor of the Edomites",
    "He was born \"all over like an hairy garment\"",
    "Jacob's twin",
    "Biblical hunter",
    "\"___ Wood would saw wood\" (part of an old tongue twister)",
    "The \"you\" in \"you will serve your brother\"",
    "\"Hairy man\" in Genesis",
    "Biblical twin",
    "Jacob's brother",
    "Old Testament \"man of the field\"",
    "Old Testament outdoorsman",
    "Jacob's twin",
    "Isaac's eldest",
    "Son of Isaac",
    "Biblical twin",
    "\"___ Wood sawed wood\" (old tongue-twister)",
    "Biblical twin",
    "Biblical name meaning \"hairy\"",
    "Issachar's uncle",
    "Isaac's eldest",
    "Biblical twin who sold his birthright",
    "Biblical figure whose name means \"hairy\" in Hebrew",
    "Genesis twin",
    "Uncle of Levi",
    "Grandson of Abraham",
    "\"___ Wood sawed wood\" (start of a tongue twister)",
    "The Bible's \"cunning hunter\"",
    "His twin duped him",
    "Jacob's twin",
    "Jacob's twin",
    "Isaac's eldest",
    "Biblical birthright seller",
    "Venison preparer in the Bible",
    "Father of Jacob",
    "Twin in Genesis",
    "\"I saw ___ sawing wood ...\" (old tongue twister)",
    "Biblical birthright seller",
    "Jacob's twin",
    "Biblical son who sold his birthright",
    "Jacob's twin",
    "\"___ Wood would saw wood ...\" (part of a classic tongue twister)",
    "Biblical twin",
    "Mess of pottage buyer",
    "Biblical \"hairy one\"",
    "Jacob's twin in the Bible",
    "Jacob's twin",
    "Biblical pottage purchaser",
    "Jacob's brother",
    "Birthright seller",
    "Jacob's twin",
    "Old Testament twin",
    "Mess of pottage buyer",
    "Son of Isaac",
    "Jacob's twin",
    "Genesis twin",
    "Isaac's eldest",
    "Biblical twin",
    "Genesis brother",
    "Jacob's twin",
    "Biblical twin",
    "Tricked twin",
    "Seller of his birthright, in Gen. 25",
    "Jacob's twin",
    "Son of Isaac",
    "Jacob's twin",
    "Genesis twin",
    "Levi's uncle",
    "Jacob's twin",
    "Genesis twin",
    "One reconciled in Gen. 33",
    "Jacob's twin",
    "Jacob tricked him",
    "His twin tricked him",
    "Jacob's brother",
    "Biblical twin",
    "Biblical twin who sold his birthright",
    "Twin in the Torah",
    "Son of Isaac",
    "Biblical twin",
    "Twin in Genesis",
    "Son in Genesis",
    "Isaac's firstborn",
    "He sold his birthright to Jacob",
    "Isaac's eldest",
    "Genesis twin",
    "Jacob's twin",
    "\"I saw ___ kissing Kate ...\" (tongue twister)",
    "Son of Isaac",
    "One of Isaac's twins",
    "Jacob's twin",
    "Jacob's twin",
    "See 63-Across",
    "Biblical name meaning \"hairy\"",
    "Jacob's twin",
    "Son of Rebekah",
    "Genesis man",
    "Biblical birthright seller",
    "Jacob's twin",
    "Jacob's twin",
    "Rebecca and Isaac's eldest",
    "Jacob's twin",
    "Jacob's twin",
    "\"___ Wood sawed wood\" (tongue twister)",
    "Biblical twin",
    "Isaac's firstborn",
    "Biblical birthright seller",
    "Whom \"she saw...on a seesaw,\" in a children's ditty",
    "1997 Philip Kerr best-selling mystery",
    "Adah's spouse",
    "\"My Brother ___\" (Grateful Dead song)",
    "Jacob's twin",
    "Isaac's eldest",
    "Birthright seller, in Genesis",
    "Genesis brother",
    "Jacob's twin",
    "Son of Rebekah",
    "Son of Rebekah",
    "Biblical brother",
    "Jacob's twin",
    "Biblical twin",
    "Biblical barterer",
    "\"___ was a cunning hunter\": Genesis",
    "Jacob's twin",
    "Forefather of the Edomites",
    "Brother of Jacob",
    "Genesis twin",
    "Jacob's twin",
    "Biblical birthright seller",
    "Grandson of Abraham",
    "Jacob's twin",
    "Son of Rebekah",
    "Birthright barterer",
    "Jacob's twin",
    "Jacob's twin",
    "Ishmael's son-in-law",
    "The Bible's \"hairy one\"",
    "Birthright seller",
    "Genesis name",
    "Genesis brother",
    "Jacob's brother",
    "Ancestor of the Edomites",
    "Birthright seller"
  ],
  "dana": [
    "CNN political correspondent Bash",
    "___ Owens a.k.a. Queen Latifah",
    "Boston's ___-Farber Cancer Institute",
    "Comic Carvey once of \"S.N.L.\"",
    "Political commentator Perino",
    "Mount ___, much-hiked peak in Yosemite",
    "\"S.N.L.\" alum Carvey",
    "Fox News commentator Perino",
    "Fox News host Perino",
    "Delany or Carvey",
    "Perino of Fox News",
    "Southern California's ___ Point",
    "Political pundit Perino",
    "\"Two Years Before the Mast\" author",
    "Agent Scully on \"The X-Files\"",
    "Mike's \"Wayne's World\" co-star",
    "Writer Richard Henry ___",
    "Former White House press secretary Perino",
    "Fox's partner on \"The X-Files\"",
    "___ Point, Calif.",
    "___ Perino, George W. Bush's last press secretary",
    "Richard Henry ___, author of \"Two 67-Across Before the Mast\"",
    "Gender-ambiguous name",
    "Funny Carvey",
    "Comic Carvey",
    "Carvey who used to say \"Well, isn't that special?\"",
    "___ Perino, George W. Bush's last White House press secretary",
    "Francis ___, signer of the Articles of Confederation",
    "Carvey of \"Wayne's World\"",
    "Comic Carvey",
    "Delany of \"China Beach\"",
    "Capt. Holmes of \"From Here to Eternity\"",
    "Agent Scully",
    "Charles Anderson ___, owner and editor of the New York Sun, 1868-97",
    "\"Two Years Before the Mast\" author",
    "Comedian Carvey",
    "Comic Carvey",
    "Plato of \"Diff'rent Strokes\"",
    "Mike's \"Wayne's World\" co-star",
    "\"Two Years Before the Mast\" writer",
    "Gillian's role on \"The X-Files\"",
    "Andrews or Carvey",
    "Author Richard Henry ___",
    "Comedian Carvey",
    "Actor Andrews",
    "Longtime New York Sun editor Charles ___",
    "\"Two Years Before the Mast\" writer",
    "\"Red Roses for a Blue Lady\" singer",
    "\"Two Years Before the Mast\" author",
    "Andrews or Carvey",
    "\"Two Years Before the Mast\" author",
    "Comic Carvey",
    "Seaman-author",
    "?ôRed Roses for a Blue Lady?ö singer",
    "\"Two Years Before the Mast\" writer",
    "Comic Carvey",
    "Comic Carvey",
    "Newspaper editor Charles Anderson ___",
    "\"Red Roses for a Blue Lady\" singer",
    "19th-century sea novelist",
    "Seaman-novelist who served on the Pilgrim"
  ],
  "utne": [
    "___ Reader (quarterly magazine)",
    "___ Reader",
    "___ Reader (magazine with the slogan \"Cure ignorance\")",
    "___ Reader",
    "Magazine with the motto \"Cure ignorance\"",
    "___ Reader",
    "Alternative media magazine, informally",
    "___ Reader (quarterly magazine)",
    "___ Reader",
    "___ Reader (quarterly magazine)",
    "___ Reader",
    "___ Reader",
    "___ Reader",
    "___ Reader",
    "Magazine founder Eric",
    "Eric of magazine publishing",
    "Magazine founder Eric",
    "___ Reader (alternative digest)",
    "___ Reader",
    "Alternative media magazine founder",
    "___ Reader",
    "Magazine founder Eric",
    "___ Reader",
    "Alternative media magazine founder",
    "___ Reader (alternative magazine)",
    "___ Reader",
    "Magazine founder Eric",
    "___ Reader (bimonthly magazine)",
    "___ Reader",
    "___ Reader (alternative magazine)",
    "Reader founder",
    "___ Independent Press Awards",
    "___ Reader",
    "___ Reader",
    "___ Reader (eclectic bimonthly)",
    "___ Reader (alternative magazine)",
    "___ Reader (alternative magazine)",
    "___ Reader (bimonthly magazine)",
    "___ Reader",
    "Reader's digest founder of 1984",
    "Magazine founder Eric",
    "___ Independent Press Awards",
    "___ Reader",
    "___ Reader",
    "___ Reader",
    "___ Reader",
    "___ Reader",
    "___ Reader",
    "Magazine founder Eric",
    "___ Reader (eclectic magazine)",
    "Olio magazine",
    "Alternative media magazine",
    "Eclectic magazine",
    "Minneapolis-based magazine",
    "\"A Different Read on Life\" magazine",
    "Alternative digest magazine",
    "Eclectic bimonthly",
    "Popular eclectic magazine",
    "Popular alternative press magazine",
    "Magazine founder Eric",
    "Magazine of reprints",
    "___ Reader (magazine)",
    "___ Reader (magazine)",
    "___ Reader (magazine)",
    "___ Reader (eclectic magazine)",
    "The ___ Reader (magazine)",
    "The ___ Reader",
    "The ___ Reader (magazine)",
    "The ___ Reader",
    "The ___ Reader",
    "___ Reader (eclectic magazine)",
    "The ___ Reader (1990's magazine)",
    "The ___ Reader (eclectic magazine)"
  ],
  "bone": [
    "Shape of a canine ID tag, often",
    "Uneaten part of an entree, maybe",
    "Fish eater's annoyance",
    "See 90-Across",
    "Shade of white",
    "Grave matter",
    "See 45-Down",
    "Stockpot addition",
    "Radius, for one",
    "Paleontologist's find",
    "Chew toy alternative",
    "Treat for a dog",
    "Radius, but not diameter",
    "No-show in Hubbard's cupboard",
    "Rib, e.g.",
    "Doggie bag item",
    "Radius, e.g.",
    "Fillet",
    "Tibia or fibula",
    "Radius, e.g.",
    "Small concession",
    "Paleontological find",
    "Matter of contention",
    "Exemplar of dryness",
    "Common shape for a dog biscuit",
    "A boxer may work on it",
    "Radius, e.g.",
    "Something to pick",
    "Word with china or dry",
    "Fish throwaway",
    "Study (up on)",
    "Shade of white",
    "Paleontologist's discovery",
    "Matter of contention",
    "See 49-Down",
    "Something to pick?",
    "Stockpot item",
    "Treat for Rover",
    "Rib or ulna",
    "One may be fractured",
    "Brush (up)",
    "Off-white shade",
    "___ china",
    "Dog's favorite part of a steak",
    "Radius or rib",
    "Exemplar of dryness",
    "Matter of contention",
    "Cut to the ___",
    "Something to pick",
    "Matter of contention",
    "Jolly Roger feature",
    "Skeleton part",
    "Rib, for one",
    "Snack for a dog",
    "Archeological find",
    "Femur, e.g.",
    "Small concession",
    "Rib or jaw",
    "Paleontologist's discovery"
  ],
  "elms": [
    "Massachusetts' College of Our Lady of the ___",
    "Eugene O'Neill's \"Desire Under the ___\"",
    "Classic boulevard liners",
    "California : palms :: New England : ___",
    "Stately street liners",
    "Symbols of hope during the American and French Revolutions",
    "The Liberty Tree and others",
    "Colonnade trees",
    "Blighted trees",
    "Trees that line the National Mall",
    "Things that suffered a 20th-century blight",
    "Eugene O'Neill's \"Desire Under the ___\"",
    "Newport, R.I., estate that's a National Historic Landmark, with \"the\"",
    "National Mall liners",
    "Classic street liners",
    "Trees attacked by bark beetles",
    "Majestic trees",
    "Stately shade trees",
    "Trees associated with the underworld in Celtic myth",
    "Stately hardwoods",
    "State symbols of Massachusetts and North Dakota",
    "Stately trees",
    "Massachusetts' College of Our Lady of the ___",
    "State trees of North Dakota and Massachusetts",
    "Shapely shade trees",
    "Frequent features of John Constable landscapes",
    "O'Neill's \"Desire Under the ___\"",
    "Street-lining trees",
    "Stately shaders",
    "Shade providers",
    "Chinese ___ (popular bonsai trees)",
    "Classic trees on shady streets",
    "Group in many a park",
    "O'Neill title trees",
    "Trees in Gray's country churchyard",
    "State symbols of North Dakota and Massachusetts",
    "Part of the New Haven landscape",
    "Shady group?",
    "Shade providers",
    "Classic street liners",
    "O'Neill's \"Desire Under the ___\"",
    "Sherwood Forest sights",
    "Majestic shade trees",
    "Raw materials for shipbuilding",
    "Historic mansion in Newport, R.I., with \"the\"",
    "Classic Main Street liners",
    "\"Slippery\" trees",
    "They're seen in many John Constable paintings",
    "Nine ___ (London district)",
    "Stately trees",
    "Landmark Newport mansion, with \"the\"",
    "Graceful shaders",
    "Classic street liners",
    "Elegant shade trees",
    "Shady giants",
    "Trees in an O'Neill title",
    "Dutch ___ (uncommon sights nowadays)",
    "The shady bunch?",
    "Shade trees",
    "New Haven, City of ___",
    "\"Rock'd the full-foliaged ___\": Tennyson",
    "Shade trees",
    "Stately shade trees",
    "Stately stand",
    "Dutch disease victims",
    "Threatened flora",
    "Once-popular street liners",
    "Shade trees",
    "Some blight victims",
    "Shade trees",
    "O'Neill's \"Desire Under the ___\"",
    "Shade trees",
    "Stately trees",
    "Colonnade trees",
    "Shade trees",
    "Tennyson's \"immemorial ___\"",
    "Shade trees",
    "They line some old streets",
    "Stately trees",
    "\"Desire Under the ___\"",
    "Popular street liners",
    "\"The moan of doves in immemorial ___\": Tennyson",
    "Some are slippery",
    "\"The moan of doves in immemorial ___\": Tennyson",
    "Shade trees",
    "\"Desire Under the ___\"",
    "Classic street liners",
    "They may be slippery",
    "Stately shaders",
    "Some are slippery",
    "Items in Gray's country churchyard",
    "Shade trees",
    "Street shaders",
    "\"Desire Under the___\"",
    "Shady ones",
    "City of ___ (New Haven, Conn.)",
    "\"Desire Under the ___\"",
    "Shady bunch",
    "Tennyson's \"doves in immemorial ___\""
  ],
  "prep": [
    "Ready",
    "Dicing onions, mincing garlic, etc.",
    "Get ready for a test, say",
    "Get ready, as for surgery",
    "Task for a sous-chef",
    "Slice and dice, say",
    "Get ready",
    "Elite sort of school, for short",
    "___ school",
    "Dicing food or turning on the oven, e.g.",
    "Get ready, in brief",
    "Word after meal or before school",
    "Kitchen work before cooking",
    "Get-ready work",
    "Kind of school",
    "___ school",
    "Prestigious school, for short",
    "Ready for surgery",
    "Get ready, casually",
    "Elite-type school",
    "Peeling potatoes or shucking corn, for short",
    "Some kitchen work, informally",
    "Test ___",
    "Attend Choate, say",
    "Princeton Review coursework",
    "SAT course, e.g.",
    "Test ___",
    "Lay the groundwork",
    "Test ___",
    "Ready",
    "Get ready for an exam",
    "___ school",
    "Teacher's before-class work",
    "Get ready, for short",
    "Ready",
    "Get set",
    "Make ready, briefly",
    "Princeton Review or Kaplan study",
    "Feed facts to, maybe",
    "Make ready, briefly",
    "Before-test work, informally",
    "Make ready, for short",
    "Ready",
    "Kind of course",
    "Get ready for an exam",
    "Ready",
    "Get ready, for short",
    "Get ready",
    "Warm-up",
    "Ready",
    "Ready for the operating room",
    "Get ready",
    "Get-ready work",
    "Trial run",
    "Kind of school",
    "Ready for surgery",
    "Attend Andover, e.g.",
    "Get ready, for short",
    "Ready for surgery",
    "Attend Choate, e.g.",
    "Attend Andover, e.g.",
    "___ school (precollege institution)",
    "Warm-up",
    "Kind of school",
    "Scrub down, as for surgery",
    "Kind of school",
    "Kind of school",
    "Get ready, informally",
    "Make ready, informally",
    "Warm-up",
    "Get ready"
  ],
  "beth": [
    "\"2 Broke Girls\" co-star Behrs",
    "First letter in the Torah",
    "\"Little Women\" sister",
    "New York's ___ Israel Medical Center",
    "Girl's name that's a Hebrew letter",
    "\"Little Women\" woman",
    "___ Israel Medical Center",
    "Top 10 Kiss hit with backing by the New York Philharmonic",
    "Aleph follower",
    "\"Little Women\" woman",
    "\"Little Women\" woman",
    "1976 top 10 hit for Kiss",
    "\"Little Women\" woman",
    "One of Alcott's \"little women\"",
    "Henley who wrote \"Crimes of the Heart\"",
    "___ Israel",
    "One of the \"Little Women\"",
    "Title girl in a 1976 Kiss hit",
    "___ Israel",
    "\"Little Women\" woman",
    "First letter in Bathsheba",
    "Letter before gimel",
    "One of Alcott's \"little women\"",
    "One of the \"Little Women\"",
    "One of Alcott's \"little women\"",
    "\"Little Women\" woman",
    "One of Alcott's \"Little Women\"",
    "1976 Kiss hit",
    "Letter after aleph",
    "One of the March sisters",
    "One of the March sisters",
    "One of Alcott's little women"
  ],
  "elon": [
    "Musk of SpaceX",
    "Musk of 45-Across",
    "Musk of SpaceX",
    "Musk who co-founded PayPal",
    "Musk who founded SpaceX",
    "Entrepreneur Musk",
    "Southern university named for its town",
    "Entrepreneur Musk",
    "Southern university or the town it's in",
    "College town east of Greensboro",
    "Southern university whose team is the Phoenix",
    "SpaceX founder Musk",
    "North Carolina university",
    "Musk of SpaceX",
    "University in North Carolina",
    "University near Greensboro",
    "Musk of Tesla",
    "SpaceX founder Musk",
    "SpaceX head Musk",
    "North Carolina university",
    "Tesla co-founder Musk",
    "Spacecraft designer ___ Musk",
    "___ Musk, co-founder of Tesla Motors and PayPal",
    "North Carolina university",
    "North Carolina college town",
    "University in North Carolina",
    "School in the Piedmont region",
    "Southern university whose campus is a botanical garden",
    "North Carolina university",
    "Carolina university",
    "University near Burlington",
    "Southern university that shares its name with a biblical judge",
    "North Carolina's ___ University",
    "Piedmont university",
    "Southern Conference school",
    "North Carolina university",
    "Its sports teams are called the Phoenix",
    "North Carolina university",
    "Southern university",
    "Southern university whose campus is a botanical garden",
    "Carolina university",
    "North Carolina university",
    "University of North Carolina",
    "North Carolina's ___ University",
    "Carolina university",
    "The Phoenix of the Southern Conference",
    "North Carolina university",
    "North Carolina college",
    "North Carolina school",
    "Southern college",
    "Carolina college town",
    "North Carolina university",
    "North Carolina university",
    "North Carolina college",
    "College in North Carolina",
    "Carolina college",
    "Tar Heel State campus",
    "Alamance County college",
    "Carolina college",
    "Big South Conference college",
    "North Carolina college",
    "Dixie school",
    "College much seen in crosswords",
    "Carolina college",
    "Carolina college",
    "North Carolina college",
    "North Carolina school",
    "___College, N.C.",
    "North Carolina college",
    "North Carolina college",
    "Southern religious school",
    "___ College, N.C.",
    "___ College, N.C.",
    "United Church of Christ college",
    "North Carolina school",
    "North Carolina college",
    "North Carolina college",
    "North Carolina school"
  ],
  "ahas": [
    "Clicking sounds?",
    "Indicators of enlightenment",
    "Mental sparks",
    "Cries of discovery",
    "Expressions of enlightenment",
    "\"Eureka!\" moments",
    "Sounds of enlightenment",
    "Good crosswords provide lots of them",
    "Cries of surprise",
    "Clicking sounds?",
    "\"Eureka\"-esque exclamations",
    "Solvers' cries",
    "Cries of discovery",
    "Cries of enlightenment",
    "Solvers' shouts",
    "\"Eureka!\" moments",
    "Epiphanies",
    "Certain breakthroughs",
    "Sounds accompanying light bulbs?",
    "What discoveries may yield",
    "Puzzle solvers' cries",
    "Cries of discovery",
    "Pupil reactions",
    "Teachers love hearing them",
    "Epiphanies",
    "They often accompany discoveries",
    "Words teachers like to hear",
    "Solvers' shouts",
    "Discovery accompaniers",
    "Cries of insight",
    "Teachers like to hear them",
    "Sounds of discoverers",
    "Code crackers' comments",
    "Inventors' cries",
    "Revelation responses",
    "Recognition responses",
    "Cries from creative people",
    "Bright students have them",
    "Epiphanies",
    "Discoveries, colloquially",
    "Cries while brainstorming",
    "Epiphanies",
    "Good students have many of these",
    "Lab shouts",
    "Words teachers like to hear",
    "Triumphant cries",
    "Sudden bolts of lightning",
    "Cries of discovery",
    "Epiphanic cries",
    "Puzzle solvers' cries",
    "Discoverers' cries",
    "Joyful cries",
    "Discovery cries"
  ],
  "hose": [
    "Water (down)",
    "It often has its kinks",
    "Running apparel?",
    "Some legwear",
    "Trick, in slang",
    "Bad place for a long run",
    "Cheat royally",
    "Garden item that sounds like the plural of another garden item",
    "Garden waterer",
    "Hydrant hookup",
    "It may wind up at the side of a house",
    "Cement truck attachment",
    "Pull a con on",
    "Fire hydrant attachment",
    "Scam",
    "Water carrier",
    "Women's stockings",
    "Defraud, in slang",
    "Rip off",
    "Garden watering aid",
    "Extension from a fire truck",
    "Bamboozle",
    "It might have some kinks in it",
    "Gas pump attachment",
    "Trick, slangily",
    "Hydrant attachment",
    "Completely con",
    "Cheat, in slang",
    "It might fill a kiddie pool",
    "Engine attachment",
    "Sprinkler conduit",
    "Cheat, in slang",
    "Water bearer?",
    "Sprinkler attachment",
    "Where runs may be made",
    "It may wind up at the side of the house",
    "Nozzle site",
    "Nozzle connector",
    "You may work out its kinks",
    "Cheat, slangily",
    "Cheat, slangily",
    "One may get kinky",
    "Nylons",
    "Spray source",
    "Rip off",
    "Nozzle site",
    "Water bearer",
    "Stockings",
    "Watering aid",
    "Gardening need",
    "Firefighter's need",
    "Nozzle site",
    "Coil in the yard",
    "Hydrant attachment",
    "Gardener's need",
    "Vacuum feature",
    "Engine attachment",
    "It may be coiled, but it never strikes",
    "Stockings",
    "Stockings",
    "Motor attachment",
    "Water carrier",
    "Gardener's need",
    "Women's wear",
    "Gardener's need",
    "Thoroughly wet, with \"down\"",
    "It may wind up on the side of a house",
    "Cheat, slangily",
    "Nozzle site",
    "Faucet hookup",
    "Fire truck need",
    "57-Down carrier",
    "Air carrier",
    "Hoover hookup",
    "Place for a run",
    "Water (down)",
    "Fire truck attachment",
    "It can go to blazes",
    "Firefighter's handful",
    "Stockings",
    "Stockings",
    "Drench, in a way",
    "Alternative to a watering can",
    "Hydrant hookup",
    "Stockings",
    "Stockings",
    "Car engine part",
    "Gardener's need",
    "Drench, with \"down\""
  ],
  "roil": [
    "Agitate",
    "Disturb, as sediment",
    "Make muddy, as water",
    "Muddy",
    "Make turbulent",
    "Upset",
    "Stir up",
    "Churn",
    "Move turbulently",
    "Disturb",
    "Stir up",
    "Stir up",
    "Upset",
    "Disturb",
    "Agitate",
    "Churn",
    "Churn",
    "Agitate",
    "Disturb",
    "Stir up",
    "Muddy",
    "Muddy up",
    "Agitate",
    "Make muddy",
    "Stir up",
    "Stir up",
    "Stir up",
    "Agitate",
    "Muddy",
    "Muddy",
    "Muddy",
    "Stir up, as the waters",
    "Stir up",
    "Churn",
    "Churn",
    "Stir up",
    "Agitate",
    "Irritate",
    "Muddy up",
    "Muddy",
    "Muddy up",
    "Stir",
    "Stir up",
    "Disturb",
    "Muddy up",
    "Stir up",
    "Muddy up",
    "Shake up",
    "Vex",
    "Disturb",
    "Stir up",
    "Muddy up",
    "Stir up",
    "Shake up",
    "Stir up",
    "Disturb",
    "Stir up",
    "Stir up",
    "Disturb",
    "Stir up",
    "Stir up",
    "Provoke",
    "Aggravate",
    "Make muddy",
    "Disturb",
    "Disturb",
    "Shake up",
    "Muddy waters",
    "Disquiet",
    "Churn",
    "Stir up",
    "Muddy",
    "Ruffle"
  ],
  "puli": [
    "Hungarian herding breed",
    "Long-haired sheepdog",
    "Hungarian sheepdog",
    "Hungarian sheepdog",
    "Hungarian sheepdog",
    "Sheepdog with fine matted hair"
  ],
  "nome": [
    "Seward Peninsula city",
    "One end of the Iditarod",
    "Locale of an early 20th-century gold rush",
    "Iditarod terminus",
    "Where the Iditarod ends",
    "The ___ Nugget (alliteratively named newspaper since 1897)",
    "U.S. city connected to the outside only by airplane, boat and sled",
    "City on the Seward Peninsula",
    "1899 gold rush destination",
    "Gold rush city of 1899",
    "U.S. city less than 150 miles from the International Date Line",
    "Gold rush town once called Anvil City",
    "Alaskan city on the Seward Peninsula",
    "Seward Peninsula city",
    "Early 1900s gold rush locale",
    "City whose name is pronounced like the natives' word for \"Where is ...?\"",
    "U.S. city known to some locals as Siqnazuaq",
    "Norton Sound city",
    "Norton Sound port",
    "Iditarod terminus",
    "Where Wyatt Earp operated the Dexter Saloon",
    "Iditarod endpoint",
    "Alaskan gold rush city",
    "Gold rush town of 1899",
    "Bering Sea port",
    "U.S. city near Russia",
    "Alaskan city near the Arctic Circle",
    "Gold rush locale of 1898-99",
    "City east of Saint Lawrence Island",
    "Iditarod terminus",
    "1899 gold rush locale",
    "It's on Norton Sound",
    "City south of the Bering Land Bridge National Preserve",
    "1890s gold rush city",
    "Alaskan city where the Iditarod ends",
    "The ___ Nugget, Alaska's oldest newspaper",
    "1899 gold rush site",
    "Iditarod's finish",
    "Iditarod terminus",
    "Biggest town on Norton Sound",
    "Iditarod race destination",
    "?ôCaro ___?ö (?ôRigoletto?ö aria)",
    "Iditarod terminus",
    "Musher's goal in March",
    "Iditarod terminus",
    "Seward Peninsula city",
    "Seward Peninsula city",
    "Norton Sound port",
    "Norton Sound port",
    "Iditarod destination",
    "Alaskan outpost",
    "Alaska gold rush town",
    "Iditarod destination",
    "Alaskan city",
    "Bering Sea port",
    "Norton Sound city",
    "Iditarod terminus",
    "City on Norton Sound",
    "Iditarod terminus"
  ],
  "elan": [
    "Verve",
    "Effervescence",
    "Pizazz",
    "Gusto",
    "Snazziness",
    "Zip",
    "Gusto",
    "Pizazz",
    "Flair",
    "Panache",
    "Gusto",
    "Style",
    "Flair",
    "Fizz",
    "Spirit",
    "Panache",
    "Joie de vivre",
    "Verve",
    "Pizazz",
    "Panache",
    "Panache",
    "Verve",
    "Flair",
    "Panache",
    "Panache",
    "Gusto",
    "Sparkle",
    "Panache",
    "Flair",
    "Flair",
    "Spirit",
    "Flair",
    "Dash",
    "Flair",
    "Flair",
    "Panache",
    "Pizazz",
    "Zest",
    "Panache",
    "Dash",
    "Brio",
    "Brio",
    "Pizazz",
    "Panache",
    "Flourish",
    "Spirit",
    "Style",
    "Animation",
    "Dash",
    "Joie de vivre",
    "Passion",
    "Panache",
    "Pizazz",
    "Life",
    "Stylishness",
    "Panache",
    "Personal flair",
    "Flair",
    "Flair",
    "Dash",
    "Dash",
    "Style",
    "Pizazz",
    "Distinctive style",
    "Style",
    "Sparkle",
    "Sparkle",
    "Dash",
    "Verve",
    "Zing",
    "Bounce",
    "Flair",
    "Verve",
    "Panache",
    "Zip",
    "Pizazz",
    "Zip",
    "Pizazz",
    "Panache",
    "Verve",
    "Dash",
    "Verve",
    "Personal flair",
    "Pizazz",
    "Verve",
    "Pizazz",
    "Spirited style",
    "Zest",
    "Dash",
    "Pizazz",
    "Spirit",
    "Pizazz",
    "Flair",
    "Flair",
    "Pizazz",
    "Panache",
    "Sparkle",
    "Pizazz",
    "Brio",
    "Impetuous quality",
    "Flair",
    "Pizazz",
    "Snap",
    "Pizazz",
    "Flair",
    "Dash",
    "Pizazz",
    "Gusto",
    "Zip",
    "Flair",
    "Pizazz",
    "Verve",
    "Pizazz",
    "Pizazz",
    "Spirit",
    "Zing",
    "Personal flair",
    "Verve",
    "Great flair",
    "Pizazz",
    "Vivacity",
    "Zing",
    "Panache",
    "Dash",
    "Flair",
    "Exuberance",
    "Verve",
    "Joie de vivre",
    "Spirit",
    "Dash",
    "Pizazz",
    "Verve",
    "Flair",
    "Dashing style",
    "Verve",
    "High spirit",
    "Joie de vivre",
    "Oomph",
    "Panache",
    "Flamboyant style",
    "Snap",
    "Grand style",
    "Pizazz",
    "Verve",
    "Spirit",
    "Verve",
    "Zing",
    "Pizazz",
    "Verve",
    "Flamboyance",
    "Spirit",
    "Panache",
    "Star quality",
    "Panache",
    "Pizzazz",
    "Pizzazz",
    "Verve",
    "Spirit",
    "Verve",
    "Joie de vivre",
    "Pizazz",
    "Pizazz",
    "Zing",
    "Gusto",
    "Flair",
    "Dash",
    "Brio",
    "Snap",
    "Verve",
    "Zip",
    "Sparkle",
    "Verve",
    "Verve",
    "Dash",
    "Distinctive flair",
    "Joie de vivre",
    "Dash",
    "Dash",
    "Verve",
    "Panache",
    "Vivacity",
    "Verve",
    "Flair",
    "Spirit",
    "Enthusiasm",
    "Verve",
    "Verve",
    "Sparkle",
    "Blithe spirit",
    "Verve",
    "Dash",
    "Vivacity",
    "Panache",
    "Flair",
    "Oomph",
    "Flair",
    "Verve",
    "Enthusiasm",
    "Enthusiasm",
    "Pizazz",
    "Exuberance",
    "Panache",
    "Pizazz",
    "Joie de vivre",
    "Style",
    "Exuberance",
    "Spirit",
    "Panache",
    "Panache",
    "Dash",
    "Vigor",
    "Dash",
    "Vim",
    "Dash",
    "Flourish",
    "Flair",
    "Panache",
    "Dash of panache",
    "Verve",
    "Exuberance",
    "Panache",
    "Dash",
    "Dash",
    "Flair",
    "Spirit",
    "Joie de vivre",
    "Spirit",
    "Dash",
    "Flair",
    "Panache",
    "Spirit",
    "Ardor",
    "Vigor",
    "Joie de vivre",
    "Verve",
    "Dash"
  ],
  "wool": [
    "Pea jacket material",
    "Merino, for one",
    "Something not much worn in the summer",
    "Fabric for a winter coat",
    "Vicuna product",
    "What's got ewe covered?",
    "Coat in a cote",
    "Sheep's coat",
    "Winter coat material",
    "Eye cover for the naive?",
    "Winter wear material",
    "Some winter wear",
    "Scarf material",
    "Peacoat material",
    "Winter coat material",
    "Winter coat material",
    "Scarf material",
    "Worsted fabric",
    "Cashmere, e.g.",
    "Moth's temptation",
    "Winter wear",
    "Meal for a moth",
    "Cashmere, e.g.",
    "Winter warmer",
    "It's got ewe covered",
    "Winter fabric"
  ],
  "outs": [
    "On the ___ (no longer friendly)",
    "Diamond data",
    "On the ___ (not talking to each other)",
    "Baseball stats",
    "See 63-Across",
    "What flies usually become",
    "What most pop-ups are",
    "Possibilities for escape",
    "Loopholes",
    "\"Three up, three down\" threesome",
    "Results of sacrifices",
    "The double of a double play",
    "Ins' partner",
    "Baseball count",
    "Exposes a secret of",
    "Contract loopholes, e.g.",
    "What tagging a runner and catching a fly ball result in",
    "Escape clauses, e.g.",
    "Loopholes",
    "The triple in a triple play",
    "Inning divisions",
    "Inning closers",
    "On the ___ (bickering)",
    "Election losers",
    "What tags may produce",
    "Publicizes, in a way",
    "The double of a double play",
    "Escape clauses",
    "Ballpark figure",
    "Scoreboard info",
    "Election losers",
    "The triple of a triple play",
    "Double play pair",
    "Political losers",
    "Scoreboard stat",
    "The triple in a triple play",
    "Inning parts",
    "The triple in a triple play",
    "Scoreboard number",
    "Baseball tags"
  ],
  "atom": [
    "Quark's place",
    "It's elementary",
    "Just a bit",
    "Manhattan Project subject",
    "A little bit of everything?",
    "Elemental part of an element",
    "Tiny bit",
    "Model in a science classroom",
    "Little matter",
    "One tiny bit",
    "Something of little matter?",
    "Itsy-bitsy bit",
    "Subject of J. J. Thomson's \"plum pudding\" model",
    "Small matter",
    "Bit of chemistry",
    "Little wonder?",
    "Chemist's study",
    "Tiny bit",
    "Physics 101 subject",
    "Bit",
    "Quark's place",
    "Something divided in W.W. II",
    "Small matter",
    "Neutron's home",
    "Bit of physics",
    "Tiny power source",
    "Supercollider bit",
    "Elemental bit",
    "Bond collector?",
    "Thing whose size is measured in picometers",
    "Molecular unit",
    "___ smasher",
    "Half of a two-volume directory",
    "___ smasher",
    "___ Ant (cartoon superhero)",
    "Focus of quantum mechanics",
    "Focus of study for Niels Bohr",
    "Small matter?",
    "Captain ___ (DC Comics superhero)",
    "Part of a molecule",
    "Tiny powerhouse",
    "Smidgen",
    "Tiny building block",
    "Tiny matter",
    "Little matter",
    "Bond part",
    "Half of a two-volume encyclopedia, say",
    "One of 24 in a glucose molecule",
    "Tiny bit",
    "Supercollider bit",
    "Bond holder?",
    "Topic of elementary education?",
    "Symbol in the logo of \"The Big Bang Theory\"",
    "Scintilla",
    "Small wonder?",
    "Quark's place",
    "Thing that might decay",
    "Tiny bit",
    "It's elementary",
    "What the winged woman is holding in the Emmy statuette",
    "Isotope, e.g.",
    "Split bit",
    "Bit of science",
    "Itsy-bitsy bit",
    "Building block of molecules",
    "Bit to split",
    "Its diameter is measured in picometers",
    "Maker of bonds",
    "Small building block",
    "Proton's place",
    "Scintilla",
    "The H, H or O in H2O",
    "One of three for H<sub>2</sub>O",
    "Mr. ___, radioactive enemy of Captain Marvel",
    "Excitable one",
    "Orbit site",
    "Elemental unit",
    "Molecular matter",
    "Word that comes from the Greek for \"indivisible\"",
    "Small matter?",
    "Molecule component",
    "Unit proposed by Leucippus",
    "___ smasher",
    "Accelerated bit",
    "Molecular 60-Across",
    "Volume 1 of a two-volume encyclopedia?",
    "Tiny bit",
    "Basic building block",
    "Speck",
    "Molecule building block",
    "Molecule part",
    "Quantum mechanics model",
    "Tiny bit",
    "Bit",
    "With 41-Down, nuclear device",
    "Teensy bit",
    "Bond component",
    "Bit to split",
    "Part of a molecule",
    "Little bit",
    "Basic building block",
    "Part of a molecule",
    "___ smasher",
    "Molecule part",
    "Bit",
    "Nuclear energy source",
    "Site of tiny orbits",
    "Tittle",
    "Positron's place",
    "Something smashable",
    "Proton's place",
    "Mighty bit",
    "Split bit",
    "Molecule part",
    "Positron's place",
    "Tiny bit",
    "Interest of Fermi",
    "Tiny bit",
    "Subject of fission",
    "Minute bit",
    "Speck",
    "Tiny part",
    "Minute bit",
    "Tiny powerhouse",
    "Subject for Fermi",
    "Unit of matter",
    "Little bit",
    "Quark's place",
    "It's smashed in a lab",
    "Infinitesimal bit",
    "Tiny building block",
    "Jot",
    "Basic building block",
    "Unit of a molecule",
    "Wee bit",
    "With 60-Across, 1960's TV cartoon hero",
    "Pion's place",
    "Nuclear energy source",
    "Proton's place",
    "Little bit",
    "Speck",
    "Speck",
    "Lepton's locale",
    "Bohr's study",
    "Something to smash",
    "Electron's place",
    "Bohr's study",
    "Basic bit",
    "Molecule component",
    "Home to a muon or pion",
    "Part of a molecule",
    "Energy source",
    "Jot",
    "Chemistry book chapter,with \"the\"",
    "Whit",
    "Quark's place",
    "Molecule part",
    "Smashed item",
    "First half of the files?",
    "Quark's place",
    "Speck",
    "Item of interest to Niels Bohr",
    "Molecule part",
    "Elementary particle",
    "Positron's place",
    "Bit",
    "Jot",
    "Elemental particle",
    "Kind of bomb",
    "Focus for Fermi",
    "Bit of physics",
    "Tiny particle",
    "Teensy bit",
    "Bit",
    "Pion's place",
    "Bit of physics",
    "Diminutive DC Comics superhero, with \"The\"",
    "Physicist's concern",
    "Quark's place",
    "Fermi's fascination",
    "Kind of smasher",
    "Energy source",
    "Pile particle",
    "Elemental unit",
    "Speck",
    "Smidgen",
    "Mote",
    "Jot",
    "Molecule component",
    "Speck",
    "Mighty mite",
    "Mighty bit",
    "Tiny bit",
    "Little matter",
    "___ bomb",
    "Quark's place",
    "First half of the alphabet",
    "Speck",
    "Smidgen",
    "Jot",
    "Tiny powerhouse",
    "___ bomb",
    "Mighty mite",
    "Building block of nature",
    "Energy source",
    "Reactor factor",
    "It's little matter",
    "Mighty mite",
    "Smidgen that's smashed",
    "Tiny bit",
    "Mighty mite",
    "Bit for Fermi",
    "___ smasher",
    "Energy source",
    "Accelerator item"
  ],
  "apse": [
    "Church part",
    "Quiet place to pray",
    "Recess for prayer",
    "Locale of the Chair of Saint Peter in Saint Peter's Basilica",
    "Church recess",
    "Feature of Notre-Dame supported by flying buttresses",
    "Cathedral recess",
    "Church recess",
    "Place for an icon in church",
    "Locale with a vaulted roof",
    "Altar place",
    "Abbey area",
    "Chapel recess",
    "Church recess",
    "Area with a half-dome",
    "Recessed area in a church",
    "Church part that sounds like what you might have on your smartphone",
    "Semidomed church area",
    "Vaulted recess",
    "Stained-glass window locale",
    "Cathedral part",
    "Locale for a shrine",
    "Church recess",
    "Area abutting a transept",
    "Religious setback?",
    "Place to pray",
    "Part of a chapel",
    "Place for a shrine",
    "View from a pew",
    "Place for an altar",
    "Church nook",
    "Church recess",
    "___ aisle, part of a church",
    "Niche form of architecture?",
    "Indoor recess",
    "Quiet place to pray",
    "Chapel recess",
    "Place to pray",
    "Altar area",
    "Quiet place to pray",
    "Altar spot",
    "Church recess",
    "Church recess",
    "Cathedral recess",
    "Something that sticks out in a church?",
    "Quiet place to pray",
    "Basilica part",
    "Vault locale",
    "Vaulted recess",
    "Church section",
    "Abbey area",
    "Place for a saint's image, maybe",
    "Sunday recess?",
    "Semidome site",
    "Vaulted church area",
    "Certain recess",
    "View from a pew, maybe",
    "Recess",
    "Church section",
    "Basilica niche",
    "Projection for some kneelers",
    "View from a pew",
    "Church niche",
    "Rounded cathedral feature",
    "Cathedral area",
    "Religious mosaic locale",
    "Site of a religious retreat",
    "Part of St. Paul's",
    "Semidomed area",
    "Area that may have stained-glass windows",
    "Religious recession?",
    "Place to pray",
    "Church recess",
    "Altar locale",
    "Area that may have stained-glass windows",
    "Cathedral feature",
    "Recess",
    "Place to pray",
    "Cathedral recess",
    "Oratory projection",
    "Church recess",
    "Place for some relics",
    "It's often vaulted",
    "Vaulted recess",
    "Vault may finally collapse",
    "Half-dome construction",
    "Cathedral recess",
    "Cathedral area",
    "Semicircular building extension",
    "Recess at Notre Dame",
    "Basilica part",
    "Area next to an ambulatory",
    "Abbey area",
    "Vaulted area, often",
    "Place for some icons",
    "Cathedral recess",
    "Place for prayer",
    "Cathedral area",
    "Semicircular room",
    "Religious statue locale",
    "Architectural projection",
    "Place for a fresco, maybe",
    "Semicircular room",
    "Church recess",
    "Vaulted area",
    "Church recess",
    "Notre Dame niche",
    "Area that may have stained glass",
    "Recess",
    "Abbey area",
    "Place for icons",
    "Church recess",
    "Area with a curved wall",
    "Cathedral recess",
    "Recess",
    "Cathedral recess",
    "Architectural projection",
    "Ecclesiastical setback?",
    "Temple part",
    "Basilica feature",
    "Abbey area",
    "Altar area",
    "Basilica area",
    "Part of an abbey",
    "Cathedral area",
    "Niche at Notre Dame",
    "Basilica area",
    "Basilica part",
    "Church alcove",
    "Chapel part",
    "Vaulted area",
    "Area next to an ambulatory",
    "Cathedral area",
    "Church area",
    "Side room",
    "Basilica feature",
    "Architectural recess",
    "Church alcove",
    "Church section",
    "Vaulted recess",
    "Vaulted recess",
    "Vaulted area, perhaps",
    "Domed recess",
    "Church recess",
    "Far end of a church",
    "Church recess",
    "Recessed area",
    "Altar site",
    "Church area",
    "Stained-glass window site",
    "Church nook",
    "Place for some icons",
    "Nave neighbor",
    "Semicircular recess",
    "Stained glass locale, perhaps",
    "Church recess",
    "Vaulted space",
    "Church part",
    "Church nook",
    "Church alcove",
    "Church recess",
    "Recess at St. Peter's",
    "Nave neighbor",
    "Church recess",
    "Church niche",
    "Vaulted church area",
    "Vaulted room",
    "Lady chapel locale",
    "Church part",
    "Room off an ambulatory",
    "Church recess",
    "Cathedral nook",
    "Church part",
    "Church niche",
    "Cathedral area",
    "Site of a bishop's seat in early churches",
    "Polygonal recess",
    "Church projection",
    "Church part",
    "Church area",
    "Church recess",
    "Basilica section",
    "Semicircular room",
    "Cathedral protuberance",
    "Church nook",
    "Place for a vault",
    "Church area",
    "Church nook",
    "Church nook",
    "Church recess",
    "Nave sight",
    "Site for stained-glass windows",
    "Basilica recess",
    "Church area",
    "Orbital point",
    "Cathedral part",
    "Cathedral area",
    "It's usually vaulted",
    "Chancel neighbor",
    "Church part",
    "Domed projection",
    "Semicircular church section",
    "It's behind the altar",
    "Church niche",
    "Church recess",
    "Arched recess",
    "Church area",
    "Basilica part",
    "Church nook",
    "Church nook"
  ],
  "dahs": [
    "There are three of them in a Morse \"O\"",
    "Morse \"Toto,\" totally",
    "Bits of Morse code",
    "\"Tom,\" entirely, in Morse code",
    "Morse bits",
    "Counterparts of dits",
    "Three of these make an O",
    "Morse bits",
    "Code bits",
    "Partners of dits",
    "Morse bits",
    "Morse T's",
    "Partner of 4-Down",
    "Parts of Morse code",
    "Morse code bits",
    "Dits' partners",
    "All of T-O-M-T-O-M, in Morse code",
    "Morse code dashes",
    "Dits' counterparts, in Morse code"
  ],
  "ayes": [
    "Passing through D.C.?",
    "Words said in passing?",
    "Sailors' affirmatives",
    "Those in favor",
    "Supporting votes",
    "Parliament votes",
    "Those in favor",
    "Those in favor",
    "Passing judgments?",
    "Assents at sea",
    "Pro side",
    "First half of a Senate vote",
    "Naval agreements?",
    "All those in favor",
    "Thumbs-up votes",
    "Assents to the captain",
    "Those in favor",
    "Passing requirements",
    "Affirmations to captains",
    "For people?",
    "Side for passage",
    "Pirates' assents",
    "Passing side?",
    "Senate majority group, maybe?",
    "Anagram - and synonym - of 71-Across",
    "All those in favor",
    "Motion carriers",
    "Chorus after \"All in favor\"",
    "Roll-call calls",
    "Votes in favor",
    "They might have it",
    "Affirmatives",
    "Rogers on a ship",
    "Yes votes",
    "Voting side",
    "Affirmative actions?",
    "Cries made in passing?",
    "Affirmative votes",
    "Yes votes",
    "Words of agreement",
    "Side for passage",
    "Calls for passage",
    "First side to vote",
    "Affirmative votes",
    "Senate tally",
    "Replies on the Enterprise",
    "Nays' opposites",
    "Affirmative votes",
    "Some roll-call calls",
    "They sometimes \"have it\"",
    "Votes for",
    "Floor votes",
    "\"Up\" votes",
    "Roll call calls",
    "They're usually first to raise their hands",
    "They're pros",
    "One side of a vote",
    "Affirmatives",
    "Passing needs",
    "Yeoman's yeses",
    "Some votes",
    "They're needed for bills",
    "Some votes",
    "Passing comments?",
    "Roll call calls",
    "Pair for a captain?",
    "They may have it",
    "Words of support",
    "Pro side",
    "Words said in passing?",
    "\"The ___ have it\"",
    "Passing remarks?",
    "Motion supporters",
    "They may have it",
    "Billy Budd's affirmatives",
    "Nays' opposites",
    "They pass bills",
    "Thumbs-up votes",
    "Senate votes"
  ],
  "peso": [
    "USD : dollar :: MXN : ___",
    "South American capital",
    "Capital of the Philippines",
    "Spanish for \"weight\"",
    "100 centavos",
    "Spanish for \"weight\"",
    "Philippine currency",
    "Cordoba : Nicaragua :: ___ : Cuba",
    "Dinar : Iraq :: ___ : Chile",
    "Mexican money",
    "Money in Mexico",
    "Spanish for \"weight\"",
    "Mexican \"dollar\"",
    "Cuban bread",
    "100 centavos",
    "Capital of Colombia",
    "Cuban currency",
    "100 centavos",
    "Banco de Mexico money",
    "Mexican money",
    "Mexican moolah",
    "Bit of dinero",
    "Philippine money",
    "Money in Mexico",
    "Mexican money",
    "Mexican money",
    "Mexican coin",
    "Dollar : U.S. :: ___ : Cuba",
    "Dinero unit",
    "Bit of dinero",
    "Mexican money",
    "Cuban money",
    "Spanish \"weight\"",
    "Cancun coin",
    "Coin of many countries",
    "Coin of Colombia",
    "Durango dinero",
    "Monterrey cheddar?",
    "Currency unit whose symbol is \"$\"",
    "Shekel : Israel :: ___ : Mexico",
    "Mexican money",
    "Philippine money",
    "Capital of Chile",
    "Chihuahua scratch?",
    "Money across the border",
    "What's tender in Mexican restaurants?",
    "Coin with a profile of Jose Maria Morelos",
    "Dominican capital",
    "South-of-the-border currency",
    "Colombian cash",
    "Currency whose symbol is \"$\"",
    "Capital of Colombia",
    "Predecessor of the boliviano",
    "100 centavos",
    "100 centavos",
    "Baja bread",
    "Coin in Cancun",
    "Mexican moolah",
    "Tender in Tijuana",
    "Coin with a $ sign",
    "Money in Mexico",
    "Coin in Cancun",
    "Mexican money",
    "Mexican mint product",
    "Bit of Mexican bread",
    "Mexican moolah",
    "Cancun coin",
    "Dominican dollar",
    "100 centavos",
    "North American capital",
    "Change in Cuba",
    "Eight reals, once",
    "Mexican bread",
    "Cuban currency",
    "100 centavos",
    "Bit of dinero",
    "Place to see Jose Morelos",
    "Monterrey jack?",
    "Cancun coin",
    "100 centesimos",
    "It shows the head of Jose Morelos",
    "Chilean money",
    "Mexican mint product",
    "Colombian money",
    "Item for a Mexican pot?",
    "Cash in Cancun",
    "Not-so-powerful currency",
    "Baja buck",
    "Colombian coin",
    "Mexican moolah",
    "Mexican bread",
    "Foreign money",
    "Mexican moolah",
    "South-of-the-border money",
    "Piece of eight",
    "Mexican money",
    "Devalued currency",
    "Small change in Chihuahua",
    "Eight reals, once",
    "Mexican coin",
    "Coin of Chihuahua",
    "Cuban dough",
    "Chihuahua change",
    "Dough for a taco?"
  ],
  "assn": [
    "Part of 79-Down: Abbr.",
    "Part of N.A.A.C.P.: Abbr.",
    "The \"A\" of W.N.B.A.: Abbr.",
    "Part of A.P.A.: Abbr.",
    "Professional org.",
    "Org.",
    "Part of U.S.T.A.: Abbr.",
    "Professional grp.",
    "The first \"A\" of N.A.A.C.P.: Abbr.",
    "N.E.A. member?: Abbr.",
    "Org.'s cousin",
    "One of the A's in N.A.A.C.P.: Abbr.",
    "Org.",
    "P.G.A. part: Abbr.",
    "One of the a's in Nascar: Abbr.",
    "The \"A\" in N.B.A.: Abbr.",
    "One of the A's in A.M.A.: Abbr.",
    "The \"A\" in 25-Across: Abbr.",
    "Part of 35-Down: Abbr.",
    "Part of A.A.A.: Abbr.",
    "Part of P.G.A.: Abbr.",
    "N.R.A. member?: Abbr.",
    "The \"A\" of 9-Across: Abbr.",
    "Part of N.R.A.: Abbr.",
    "Part of 6-Down: Abbr.",
    "A.M.A. part: Abbr.",
    "The \"A\" of N.B.A.: Abbr.",
    "An \"A\" in N.C.A.A.: Abbr.",
    "Part of A.M.A.: Abbr.",
    "Part of AARP: Abbr.",
    "Order: Abbr.",
    "League: Abbr.",
    "The second \"A\" in N.C.A.A.: Abbr.",
    "Part of N.E.A.: Abbr.",
    "Part of N.C.A.A.: Abbr.",
    "Professional org.",
    "N.E.A. member?: Abbr.",
    "Part of N.C.A.A.: Abbr.",
    "Part of Y.M.C.A.: Abbr.",
    "Part of N.A.A.C.P.: Abbr.",
    "League: Abbr.",
    "Part of P.T.A.: Abbr.",
    "P.T.A. member?: Abbr.",
    "Society: Abbr.",
    "A.A.A. part: Abbr.",
    "A.M.A. member?: Abbr.",
    "The \"A\" in P.T.A.: Abbr.",
    "The first \"A\" in N.A.A.C.P.: Abbr.",
    "Part of M.P.A.A.: Abbr.",
    "Part of P.T.A.: Abbr.",
    "Part of P.T.A.: Abbr.",
    "Part of AARP: Abbr.",
    "Professional org.",
    "Org.",
    "Club: Abbr.",
    "Part of A.A.R.P.: Abbr.",
    "Org.",
    "N.R.A. part: Abbr.",
    "Grp. with a common purpose",
    "A.A.A. part: Abbr.",
    "Y.M.C.A. member?: Abbr.",
    "A.A.A. member?: Abbr.",
    "Professional grp.",
    "Part of N.A.A.C.P.: Abbr.",
    "Members' body: Abbr.",
    "P.T.A. part: Abbr.",
    "Part of A.A.R.P.: Abbr.",
    "Part of 57-Down: Abbr.",
    "N.C.A.A. part: Abbr.",
    "Part of P.G.A.: Abbr.",
    "Part of Nasdaq: Abbr.",
    "Society: Abbr.",
    "Union: Abbr.",
    "Org.",
    "Org.",
    "Cousin of an org.",
    "Alumni ___: Abbr.",
    "Part of N.A.A.C.P.: Abbr.",
    "Club: Abbr.",
    "84-Across, e.g.: Abbr.",
    "League: Abbr.",
    "Abbr. in many group names",
    "Org.",
    "Org.",
    "Org.",
    "Grp.",
    "N.B.A., e.g.: Abbr.",
    "Org.",
    "Org.",
    "Org.",
    "Part of Nasdaq: Abbr.",
    "Org.",
    "Part of Nascar: Abbr.",
    "Org.",
    "Part of N.B.A.: Abbr.",
    "Org.",
    "Org.",
    "Org.",
    "Part of N.A.A.C.P.: Abbr.",
    "Part of N.A.A.C.P.: Abbr.",
    "Org.",
    "Org.",
    "Org.",
    "Part of A.A.R.P.: Abbr.",
    "Part of NASDAQ: Abbr.",
    "Part of P.T.A.: Abbr.",
    "Org.",
    "Professional org.",
    "Org.",
    "League: Abbr.",
    "Org.",
    "Professional grp.",
    "Professional grp.",
    "Professional grp.",
    "Part of N.C.A.A.: Abbr.",
    "Grp.",
    "Club: Abbr.",
    "Alliance: Abbr.",
    "Org.",
    "Part of P.T.A.: Abbr.",
    "Professional grp.",
    "Professional soc.",
    "Org.",
    "Org.",
    "Part of 46-Across: Abbr.",
    "Trade grp.",
    "Professional org.",
    "Org.",
    "Org."
  ],
  "amts": [
    "Qt. and gal.",
    "Qts. and pts.",
    "Totals: Abbr.",
    "Tsps. or tbsps.",
    "Quantities: Abbr.",
    "Recipe details: Abbr.",
    "Rx dosages, e.g.: Abbr.",
    "Qts. and gals.",
    "Quantities: Abbr.",
    "Sums: Abbr.",
    "Qtys.",
    "2 and 3 tsps., e.g.",
    "10 cc's and 64 fl. oz.",
    "Balance sheet nos.",
    "Quantities: Abbr.",
    "Acct. numbers",
    "A bushel and a peck?: Abbr.",
    "Nos.",
    "Withdrawal figs.",
    "Recipe details: Abbr.",
    "Qtys.",
    "Nos. on checks",
    "Recipe info: Abbr.",
    "Statement figures: Abbr.",
    "Acct. figures",
    "Recipe info: Abbr.",
    "Quantities: Abbr.",
    "Nos. on checks",
    "Qts. and pts.",
    "Ledger entries: Abbr.",
    "Quantities: Abbr.",
    "Tsp. and tbsp.",
    "Quantities: Abbr.",
    "Lbs. and qts., e.g.",
    "Quantities: Abbr."
  ],
  "ajar": [
    "Letting in a little light, perhaps",
    "A little cracked",
    "Just open",
    "Cracked",
    "Partly open",
    "Cracked a bit",
    "A bit cracked",
    "Cracked open",
    "Barely open, as a door",
    "Cracked open, as a door",
    "Open a bit",
    "Barely open",
    "Cracked",
    "Not fully open",
    "Partly open, as a door",
    "Cracked a little",
    "Not fully closed",
    "Nearly closed",
    "Slightly open",
    "\"When it's ___\" (answer to an old riddle)",
    "A bit cracked",
    "Not quite closed",
    "Slightly cracked, as a door",
    "Cracked a bit",
    "Not quite closed",
    "Open a bit",
    "Not completely shut",
    "Not shut tight",
    "Not fully closed, as 1-Across",
    "Close to closed",
    "Open a smidge",
    "Unlatched, say",
    "Opened a bit",
    "Cracked",
    "Open a tad",
    "Cracked just slightly",
    "Open a crack",
    "Not fully open",
    "Cracked open, as a door",
    "Not wide open",
    "Cracked a bit",
    "Admitting a slight draft, maybe",
    "Slightly open",
    "Slightly open",
    "Open a crack",
    "Open a tad",
    "Allowing some ventilation, say",
    "\"Wearing the face that she keeps in ___ by the door\" (Beatles lyric)",
    "Open a tad",
    "Not quite closed",
    "Close to closed",
    "Like a door that doesn't afford complete privacy",
    "Cracked a little",
    "Cracked a bit",
    "Cracked open, as a door",
    "Nearly shut",
    "Cracked open",
    "Not fully shut",
    "Allowing peeking, perhaps",
    "Q.: When is a door not a door? A.: When it's ___",
    "Slightly open",
    "Cracked",
    "A little open",
    "Barely open",
    "Openable with a gentle push, say",
    "Barely open",
    "Slightly open",
    "Like some doors",
    "Slightly open, as a door",
    "Not stopping the draft, say",
    "Not quite shut",
    "Almost closed",
    "Not shut all the way",
    "Cracked open",
    "Somewhat open",
    "Not completely shut, as a door",
    "Opened a bit",
    "Slightly open",
    "Open just a little",
    "Admitting just a little light, maybe",
    "Not quite closed",
    "Close to closed",
    "See 26-Down",
    "Not closed all the way",
    "Cracked a bit",
    "Cracked",
    "Close to closed",
    "Open slightly",
    "Not completely closed",
    "Open slightly",
    "Cracked",
    "Open a bit",
    "Nearly closed",
    "Open a bit",
    "Slightly open",
    "Slightly open",
    "Open a bit",
    "Opened just a crack",
    "A bit cracked",
    "Slightly open",
    "Not completely closed",
    "Cracked open",
    "Open a bit",
    "\"When it's ___\" (old riddle answer)",
    "Slightly open",
    "Admitting a draft, maybe",
    "Partially open",
    "Barely open",
    "Not fully shut",
    "A bit cracked?",
    "Left so as to admit some light",
    "Open slightly",
    "Not fully closed",
    "Not fully closed",
    "Not quite shut",
    "Cracked a bit",
    "Between open and closed",
    "Just open",
    "Discordant",
    "Cracked",
    "Open a crack",
    "Cracked",
    "Not quite closed",
    "Close to closed",
    "Leaving a small opening",
    "\"When it's ___\" (old riddle answer)",
    "Close to closed",
    "Not latched",
    "Admitting a draft",
    "Open a bit",
    "Slightly open",
    "Not quite shut",
    "Like some doors",
    "Open a crack",
    "Close to closed",
    "Cracked open",
    "Like a 1-Across, maybe",
    "Cracked open",
    "Open a bit",
    "Not fully closed",
    "Propped open, perhaps",
    "\"When it's ___\" (old riddle's end)",
    "Not shut tight",
    "Not fully shut",
    "Not quite closed",
    "Close to closed",
    "Open a crack",
    "Like some doors",
    "Slightly open",
    "Open, but just",
    "Open a bit",
    "Not quite shut",
    "Slightly open",
    "Open a bit",
    "Slightly open",
    "Slightly open",
    "Not fully shut",
    "Open",
    "Partly open",
    "Not shut tight",
    "Open a bit",
    "Not fully closed",
    "Open a bit",
    "Not fully shut",
    "Open slightly",
    "Barely open",
    "Partly open",
    "Cracked open",
    "Partly open",
    "Almost shut",
    "Opened a crack"
  ],
  "ncaa": [
    "Org. at the center of modern \"name, image and likeness\" legislation",
    "Big Dance org.",
    "Org. with bowl games",
    "Sweet 16 org.",
    "Org. for the Big East and Pac-12",
    "ESPNU covers its games",
    "Org. affected by Title IX",
    "Inits. at the top of some brackets",
    "Sports org. headquartered in Indianapolis",
    "Sports org. headquartered in Indianapolis",
    "University sports org.",
    "Org. that holds many conferences",
    "Sports org. that sets eligibility requirements",
    "Org. for Big Red or Big Green",
    "Org. with Huskies and Bulldogs",
    "Org. with an Inspiration Award and an Award of Valor",
    "Big org. in Saturday afternoon TV",
    "Final Four inits.",
    "Sports org. based in Indianapolis",
    "Indianapolis-based sports org.",
    "March Madness org.",
    "Big scholarship awarder, for short",
    "Grp. from which many people are drafted",
    "Awarder of billions in sports scholarships, in brief",
    "Org. for the Big East, Big South and Big 12",
    "March Madness org.",
    "Sports org. headquartered in Indianapolis",
    "March Madness org.",
    "Final Four grp.",
    "Org. with Divisions I-III",
    "Publisher of Champion magazine, for short",
    "Tournament organizer since '39",
    "Org. overseeing the Big Ten and Big 12",
    "Org. with conferences",
    "ESPNU covers it",
    "Sports org. with divisions I, II and III",
    "Org. with rules on eligibility",
    "Final Four org.",
    "March Madness org.",
    "Sports org. founded in 1906",
    "Org. with eligibility rules",
    "Org. affected by Title IX",
    "Bulldogs play in it: Abbr.",
    "Certain tourney overseer",
    "Sweet 16 org.",
    "Org. with Lions, Tigers and Bears",
    "Sports org. headquartered in Indianapolis",
    "Abbr. on a sports ticker",
    "Final Four org.",
    "Org. with Divisions I, II and III",
    "Sportsmanship Award org.",
    "Final Four org.",
    "Indianapolis-based sports org.",
    "Org. with brackets",
    "Final Four org.",
    "ESPN ticker abbr.",
    "Grp. concerned with bowls",
    "See 43-Across",
    "Scholarship-offering org.",
    "Final Four org.",
    "Org. with Spartans and Trojans",
    "March Madness inits.",
    "See 12-Down",
    "March Madness org.",
    "Org. with a Hall of Champions",
    "Final Four inits.",
    "Org. with Divisions I, II and III",
    "March Madness org.",
    "Big Ten or Big 12 org.",
    "Game grp.",
    "ESPN topic",
    "Org. involved with ESPN",
    "College World Series org.",
    "Elite Eight org.",
    "Sweet 16 org.",
    "Hall of Champions org.",
    "Final Four org.",
    "March Madness org.",
    "March Madness org.",
    "Univ. sports org.",
    "March Madness org.",
    "Org. since 1910",
    "Final Four org.",
    "Annual hoops championship organizer, for short",
    "Sports org. for scholars",
    "Final Four org.",
    "Org. setting eligibility requirements",
    "Final Four org.",
    "Scholastic sports grp.",
    "Sweet 16 org.",
    "Grp. with eligibility requirements",
    "Sports org. since 1906",
    "Scholastic sports grp.",
    "Final Four letters",
    "Org. with eligibility rules",
    "School sports org.",
    "Elite Eight org.",
    "Final Four org.",
    "Sweet 16 org.",
    "Final Four grp.",
    "Big sports inits.",
    "Final Four org.",
    "Final Four org.",
    "March Madness org.",
    "Final Four org.",
    "Interscholastic sports org.",
    "Sports org. with a March tourney",
    "March Madness grp.",
    "Sports org.",
    "Final Four org.",
    "Bowl org.",
    "Sports org. since 1906",
    "College sports org., for short",
    "Hoops tournament org.",
    "Amateur sports org.",
    "March Madness org.",
    "Duke belongs to it: Abbr.",
    "Org. with eligibility rules",
    "March Madness org.",
    "March Madness org.",
    "March tourney sponsor",
    "Wildcats' org.",
    "Org. with eligibility rules",
    "March Madness org.",
    "Orange Bowl org.",
    "College sports org.",
    "Final Four org.",
    "School sports org.",
    "Final Four inits.",
    "Campus sports org.",
    "Tournament org.",
    "Saturday TV sports org.",
    "March Madness org.",
    "Sports org.",
    "University sports org.",
    "Tourn. sponsor since 1939",
    "\"Sweet-16\" org.",
    "Final Four org.",
    "Sports org.",
    "\"March Madness\" org."
  ],
  "coen": [
    "Surname on a 2010 \"True Grit\" poster",
    "Either of two directing brothers",
    "Joel or Ethan of the film world",
    "Either brother who co-wrote \"O Brother, Where Art Thou?\"",
    "Family name of Hollywood brothers",
    "Either director of \"Inside Llewyn Davis\"",
    "Either Best Director winner of 2007",
    "Director Joel or Ethan",
    "Either \"Inside Llewyn Davis\" director",
    "Either director of \"True Grit\"",
    "Director Joel or Ethan",
    "Filmdom family name",
    "\"The Hudsucker Proxy\" director, 1994",
    "Filmmaking brother Joel or Ethan",
    "Either \"True Grit\" director",
    "___ brothers (Hollywood duo)",
    "Either director of 2010's \"True Grit\"",
    "Director Joel or Ethan",
    "\"The Big Lebowski\" director",
    "\"Fargo\" director",
    "\"Fargo\" director",
    "\"A Serious Man\" co-director, 2009",
    "Either \"Fargo\" co-director",
    "\"The Big Lebowski\" director",
    "Filmmaker Joel or Ethan",
    "Either of two directing brothers",
    "Joel who directed \"Raising Arizona\"",
    "\"The Man Who Wasn't There\" director, 2001",
    "Director of \"The Ladykillers,\" 2004",
    "\"Fargo\" director",
    "\"Fargo\" director",
    "\"Fargo\" director Joel",
    "\"Fargo\" director",
    "Filmdom's Ethan or Joel",
    "Filmmaking family name",
    "Filmdom's Joel or Ethan",
    "Film maker Joel or Ethan",
    "\"Barton Fink\" director Joel"
  ],
  "lmao": [
    "\"Oh, our sides are hurting!\"",
    "[sooo funny!]",
    "\"Wow, that's 2 funny!\"",
    "\"Hilarious!,\" in a text",
    "\"Hahahahahaha!\"",
    "Response from a greatly amused texter",
    "\"u r hilarious!\"",
    "Online \"Very funny!\"",
    "ROFL alternative"
  ],
  "igor": [
    "2010 Atlantic hurricane that was the largest up to that time",
    "2019 #1 album for Tyler, the Creator",
    "Composer Stravinsky",
    "Composer Stravinsky",
    "Man's name that can follow \"v\" or \"r\" to form an English word",
    "Composer Stravinsky",
    "2019 #1 album for Tyler, the Creator",
    "Frankenstein's assistant in \"Young Frankenstein\"",
    "\"Young Frankenstein\" character who asks \"What hump?\"",
    "Stock character in fictional labs",
    "Fictional lab assistant",
    "Composer Stravinsky",
    "Sikorsky of aviation",
    "Assistant to Dr. Frankenstein",
    "2019 #1 album by Tyler, the Creator",
    "Assistant in a horror film",
    "\"Young Frankenstein\" assistant",
    "Composer Stravinsky",
    "Horror assistant",
    "Hunchbacked film character",
    "Lab assistant in many a horror film",
    "Horror movie assistant",
    "Borodin opera prince",
    "Servant in \"Young Frankenstein\"",
    "Dr. Frankenstein's assistant",
    "\"Young Frankenstein\" role",
    "Borodin's prince",
    "Dr. Frankenstein's assistant",
    "Classical pianist Levit",
    "Hunchbacked assistant of film",
    "Frankenstein's assistant",
    "Prince of opera",
    "Sikorsky of aviation",
    "___ Olshansky, first Soviet-born N.F.L. player",
    "Frankenstein's aide",
    "Operatic prince",
    "Dr. Frankenstein's helper",
    "Composer Stravinsky",
    "Horror film assistant with a Russian name",
    "Dr. Frankenstein's assistant",
    "One who has a hunch",
    "Servant in the \"Discworld\" novels",
    "One with a pretty strong hunch?",
    "Stravinsky or Sikorsky",
    "Real-estate mogul Olenicoff",
    "Borodin's \"Prince ___\"",
    "Lab assistant in a horror film",
    "Stravinsky or Sikorsky",
    "Aviation pioneer Sikorsky",
    "Role in \"Young Frankenstein\"",
    "Inventor Sikorsky",
    "Composer Stravinsky",
    "Assistant played by Charles Bronson in \"House of Wax\"",
    "Stereotypical lab assistant's name",
    "Composer Stravinsky",
    "Character with a prominent back",
    "Composer Stravinsky",
    "Noted lab assistant",
    "Real-estate tycoon Olenicoff",
    "Operatic prince",
    "Dr. Frankenstein's assistant",
    "\"M*A*S*H\" private Straminsky",
    "Prince of opera",
    "Nobel physicist Tamm",
    "\"Young Frankenstein\" hunchback",
    "2008 film about a hunchbacked lab assistant",
    "Hunchbacked assistant of horror films",
    "Classic 1974 role for Marty Feldman",
    "Role in \"Young Frankenstein\"",
    "Kurchatov who oversaw the Soviet atomic bomb project",
    "Operatic prince",
    "\"The Lay of the Host of ___\" (old Russian epic poem)",
    "Borodin's \"Prince ___\"",
    "Hunchbacked assistant",
    "Russian conductor/composer Markevitch",
    "\"Young Frankenstein\" hunchback",
    "Aviation pioneer Sikorsky",
    "Composer Stravinsky",
    "Operatic prince",
    "Composer Stravinsky",
    "Famed lab assistant",
    "Assistant with a hunch?",
    "Dr. Frankenstein's assistant",
    "Borodin's operatic prince",
    "Composer Stravinsky",
    "Stravinsky or Sikorsky",
    "Frankenstein's helper",
    "Operatic prince",
    "1974 Marty Feldman comic-horror role",
    "Composer Stravinsky",
    "Composer Stravinsky",
    "Russian choreographer Moiseyev",
    "Dr. Frankenstein's assistant",
    "Borodin prince",
    "Prince of opera",
    "Stereotypical lab assistant",
    "Famed lab assistant",
    "Soviet Physics Nobelist ___ Tamm",
    "Frankenstein's assistant, in film",
    "Fictional hunchback",
    "Operatic prince",
    "Marty Feldman in \"Young Frankenstein\"",
    "Prince of opera",
    "Operatic prince",
    "Composer Stravinsky",
    "Composer Stravinsky",
    "Composer Stravinsky",
    "Dr. Frankenstein's assistant",
    "Choreographer Moiseyev",
    "Frankenstein factotum",
    "Hunchback of film horror",
    "Aviation pioneer Sikorsky",
    "Frankenstein's assistant",
    "Violinist Oistrakh",
    "Frankenstein's assistant",
    "Marty's role in \"Young Frankenstein\"",
    "Fictional hunchback",
    "Stravinsky or Sikorsky",
    "Stereotypical hunchback",
    "Dr. Frankenstein's assistant",
    "Conductor Markevitch",
    "Operatic prince",
    "Inventor Sikorsky",
    "Prince in a Borodin opera",
    "Composer Stravinsky",
    "Fictional hunchback",
    "Hockey's Kravchuk or Korolev",
    "Inventor Sikorsky",
    "Composer Stravinsky",
    "Dr. Frankenstein's helper",
    "Borodin title prince",
    "Composer Stravinsky",
    "Composer Stravinsky",
    "Composer Stravinsky",
    "Borodin's \"Prince ___\"",
    "Inventor Sikorsky",
    "Dr. Frankenstein's assistant",
    "Composer Stravinsky",
    "Stereotypical lab assistant",
    "Transylvanian lab assistant?",
    "Historic Russian prince",
    "Fictional hunchback",
    "Composer Stravinsky",
    "Fictional lab assistant",
    "Composer Stravinsky",
    "Borodin's prince",
    "Borodin's \"Prince ___\"",
    "Humpbacked helper",
    "Helicopter pioneer Sikorsky",
    "Dr. Frankenstein's assistant",
    "Composer Stravinsky",
    "Dr. Frankenstein's assistant",
    "Composer Stravinsky",
    "Sikorsky or Stravinsky",
    "Operatic prince",
    "Helicopter builder Sikorsky",
    "Feldman role in \"Young Frankenstein\"",
    "Composer Stravinsky",
    "Operatic prince",
    "Composer Stravinsky",
    "Prince of opera",
    "Borodin's prince",
    "Russian epic hero",
    "Inventor Sikorsky",
    "Frankenstein's helper",
    "Mr. Stravinsky",
    "Mr. Sikorsky",
    "Lab assistant",
    "Soviet scientist Kurchatov",
    "Lab assistant, of film",
    "Mr. Stravinsky",
    "Mr. Sikorsky",
    "Operatic prince",
    "Dr. Frankenstein's assistant",
    "Borodin's prince",
    "Borodin's \"Prince ___\"",
    "Borodin's prince",
    "Mr. Stravinsky"
  ],
  "team": [
    "Join (up)",
    "A's, but not B's or C's",
    "Word with sport or spirit",
    "___ U.S.A.",
    "Squad",
    "___ player",
    "The Avengers, e.g.",
    "Crew",
    "Sports squad",
    "Crew",
    "A's, e.g. ... or a word following \"A\"",
    "Lions, Tigers or Bears",
    "\"Go, ___!\"",
    "Squad",
    "60-Across, in baseball",
    "___ U.S.A.",
    "Reds or Blues",
    "Diamond nine",
    "Pick in an office pool, maybe",
    "Pair of oxen, e.g.",
    "Uniform-wearing group",
    "Bulls or Bears",
    "What a mascot represents",
    "Flames on ice, e.g.",
    "Locker room user",
    "Giants or Titans",
    "Santa's reindeer, e.g.",
    "Span",
    "Word after mule or school",
    "There's no \"I\" in it, they say",
    "Jets or Nets",
    "___ U.S.A.",
    "___ U.S.A.",
    "What there's no \"I\" in",
    "Orioles, Eagles or Cardinals",
    "Skins, e.g.",
    "Squad",
    "See 41-Across",
    "Word often cried after \"Go\"",
    "Orioles or Cardinals",
    "Wagon puller, often",
    "Santa's reindeer, e.g.",
    "Squad",
    "Sled pullers",
    "Stagecoach puller",
    "Conference member",
    "Nets or Jets",
    "Play mates?",
    "Mets, Jets or Nets",
    "Grizzlies or Bears",
    "Yoked pair",
    "Nationals, again",
    "Whom the cheerleaders cheer",
    "Bulls or Bears",
    "Kind of spirit",
    "Lions or Tigers",
    "Whom a coach coaches",
    "Pair of oxen",
    "Kind of spirit",
    "Kind of spirit",
    "Suns or Stars",
    "Santa's reindeer, e.g.",
    "\"Go, ___!\"",
    "Squad",
    "Plow puller",
    "Bulls or Bears",
    "\"Go, ___!\"",
    "Broncos or Chargers",
    "Mets, Jets or Nets",
    "Canadiens or Canucks",
    "Jets, e.g.",
    "Hawks or Seahawks, e.g.",
    "Pair with a plow",
    "___player",
    "Kind of spirit",
    "Plow animals",
    "Squad",
    "Pair off",
    "Blue Jays or Cardinals, e.g.",
    "Corps",
    "Word after \"go!\""
  ],
  "ergo": [
    "Post hoc, ___ propter hoc (logical fallacy)",
    "So",
    "Therefore",
    "Latin word that's 12-Down backward",
    "Lead-in to -nomic",
    "Thus",
    "So",
    "Logician's \"therefore\"",
    "Post hoc, ___ propter hoc (common fallacy)",
    "So",
    "Therefore",
    "So",
    "Therefore",
    "Therefore",
    "So",
    "As a result",
    "\"Logic dictates ...\"",
    "Hence",
    "In conclusion",
    "As a result",
    "Therefore",
    "Consequently",
    "\"And so ...\"",
    "Conclusion preceder",
    "So",
    "Descartes's \"therefore\"",
    "Lead-in to a conclusion",
    "Hence",
    "Thus, to Gaius",
    "Logical connector",
    "Word of logic",
    "Conclusion lead-in",
    "So",
    "As a result",
    "It leads to a logical conclusion",
    "Lead-in to a conclusion",
    "As a result",
    "By that logic",
    "As a consequence",
    "Therefore",
    "[Another arrangement of the letters in the grid]",
    "And so",
    "Accordingly",
    "\"Cogito, ___ sum\"",
    "Start of a conclusion",
    "Therefore",
    "\"It follows that ...\"",
    "\"By that logic ...\"",
    "Therefore",
    "Therefore",
    "Word before \"sum\"",
    "As an end result",
    "\"In which case ...\"",
    "As a result",
    "Hence",
    "Descartes's \"therefore\"",
    "Hence",
    "Hence",
    "Consequently",
    "Logician's word",
    "As a result",
    "As a result",
    "Conclusion lead-in",
    "\"And so ...\"",
    "It follows that",
    "Therefore",
    "Conclusion lead-in",
    "As a result",
    "Therefore",
    "Sum lead-in",
    "As a result",
    "Therefore",
    "For this reason",
    "Because of this",
    "So it follows that",
    "As a result",
    "As a result",
    "As a result",
    "Start of a conclusion",
    "Consequently",
    "It follows that",
    "Hence",
    "It then follows that ...",
    "Therefore",
    "Thus",
    "As a result",
    "For this reason",
    "Descartes's \"therefore\"",
    "\"It follows that ?à\"",
    "So",
    "\"?à ___ sum\"",
    "So",
    "Hence",
    "By logic, then ...",
    "Hence",
    "Transition in logic",
    "\"Sum\" preceder",
    "Therefore",
    "Therefore",
    "\"Cogito, ___ sum\"",
    "Descartes's \"therefore\"",
    "So",
    "It follows a premise",
    "Consequently",
    "As a result",
    "So",
    "Hence",
    "As a result",
    "Sum preceder?",
    "By that reasoning",
    "Proof word",
    "Hence",
    "It follows that",
    "Conclusion preceder",
    "Therefore",
    "\"Cogito ___ sum\"",
    "Consequently",
    "Thus",
    "Hence",
    "So",
    "Thus",
    "Start of a conclusion",
    "Therefore",
    "\"Cogito ___ sum\"",
    "Part of a Cartesian declaration",
    "Hence",
    "Logician's word",
    "So",
    "As a result",
    "Cartesian conjunction",
    "Hence",
    "Proof part",
    "Therefore",
    "Hence",
    "Thus",
    "\"Tantum ___\" (part of a Eucharist hymn)",
    "Accordingly",
    "Therefore",
    "Hence",
    "As a result",
    "Therefore",
    "Conclusion starter",
    "Therefore",
    "\"Cogito ___ sum\"",
    "Inferential word",
    "Therefore",
    "Proof word",
    "Beginning of a conclusion",
    "\"Cogito___sum\"",
    "\"Tantum___\" (hymn part)",
    "As a result",
    "Thus",
    "Hence",
    "Therefore",
    "Hence",
    "So",
    "So",
    "So",
    "Therefore",
    "Consequently",
    "Hence",
    "So",
    "Therefore",
    "Therefore",
    "Therefore",
    "Consequently",
    "Proof word"
  ],
  "dkny": [
    "Sister brand of Guess? and Calvin Klein",
    "Fashion house based in the Big Apple",
    "Big Apple fashion inits.",
    "Big Apple-based designer label",
    "Fashion company with a Big Apple flagship store",
    "Fashion inits.",
    "Designer label letters",
    "Inits. on some underwear",
    "Designer label letters",
    "Designer letters",
    "Label in Saks and Bloomies",
    "Fashion initials"
  ],
  "rasp": [
    "File type",
    "Farrier's tool",
    "File type",
    "Carver's tool",
    "Croak",
    "Scratchy voice",
    "Rough talk?",
    "Scrape",
    "Hoarse voice",
    "Talk like a tough, say",
    "Hoarse voice",
    "Speak with a gravelly voice",
    "Carpentry tool",
    "Farrier's tool",
    "Scrape",
    "Shop tool",
    "Scratch",
    "Speak with a gravelly voice",
    "Blacksmith's tool",
    "Speak hoarsely",
    "Louis Armstrong vocal feature",
    "Cliched gift for a prisoner",
    "Get-out-of-jail aid, maybe",
    "Farrier's tool",
    "Grate",
    "Speak like a tough guy, say",
    "Potential flu symptom",
    "Grate",
    "Filing tool",
    "See 9-Down",
    "Carpenter's file",
    "Not be smooth-talking?",
    "Stereotypical mobster's voice",
    "Sing like Tom Waits",
    "Metalworker's tool",
    "Speak with laryngitis, say",
    "Speak with a scratchy voice",
    "Speak with a gravelly voice",
    "Filing aid",
    "Emulate Don Corleone",
    "Blacksmith's tool",
    "Scratch",
    "Not smooth-talk?",
    "Tool for horses' hooves",
    "Woodworking tool",
    "Sound gravelly",
    "Horseshoer's tool",
    "Imitate Don Corleone",
    "Godfather's voice, maybe",
    "Coarse file",
    "Laryngitis symptom",
    "Croak",
    "The Godfather's voice, e.g.",
    "Not speak clearly",
    "Hoarse voice",
    "Prisoner's tool for escape",
    "Farrier's aid",
    "Talk like a godfather",
    "Coarse file",
    "Farrier's file",
    "Coarse file",
    "Grate",
    "Farrier's tool",
    "Coarse file",
    "File",
    "Rough tool",
    "Sound gravelly",
    "Blacksmith's file",
    "Coarse file",
    "Sign of laryngitis",
    "Grating sound",
    "Hoarse speech",
    "Horseshoer's tool",
    "Filer",
    "Hoarse talk",
    "Heavy smoker's voice, maybe",
    "Tooth site",
    "Coarse file",
    "Grate",
    "Mobster's speech, maybe",
    "Hoarseness",
    "Coarse file",
    "Conical-toothed tool",
    "Grate",
    "Grate",
    "Woodworking tool",
    "Irritate",
    "Barely speak",
    "File",
    "Carpenter's tool",
    "Irritate",
    "Smoother",
    "Hoof smoother",
    "Filer",
    "Horseshoer's need",
    "Croak",
    "Heavy file",
    "Harsh words",
    "Throaty utterance",
    "Bronchitis symptom",
    "Farrier's tool",
    "Horseshoer's tool",
    "Abrade",
    "Its teeth are conical",
    "Woodworking tool",
    "Blacksmith's tool",
    "Grate"
  ],
  "jete": [
    "Grand ___",
    "Ballet leap",
    "Graceful leap",
    "Ballerina's springing jump",
    "Ballet leap",
    "\"Black Swan\" jump",
    "Ballet jump",
    "Ballet leap",
    "Barre hop?",
    "One might be performed en avant",
    "Ballet leap",
    "Simple gymnastics move",
    "Jump on a stage",
    "\"Grand\" or \"petit\" dance move",
    "Ballet leap",
    "Ballet jump",
    "Balletic leap",
    "Leap on a stage",
    "Ballet leap",
    "Ballet leap",
    "Jump from one foot to the other",
    "Grand ___",
    "Leap in a tutu",
    "Leap at the ballet",
    "Ballet jump",
    "Bit of fancy footwork",
    "Ballet jump",
    "Ballet jump",
    "Ballet leap",
    "Ballet leap"
  ],
  "agar": [
    "Bio lab supply",
    "Culture medium",
    "Food thickener",
    "Vegetarian substitute for gelatin",
    "Bio lab medium",
    "Ice cream thickener",
    "Culture medium in a lab",
    "Petri dish medium",
    "Substance made from seaweed",
    "Gelatin substitute made from seaweed",
    "Food thickener",
    "Vegetarian gelatin substitute",
    "Soup thickener",
    "Food-thickening agent",
    "Lab medium",
    "Food thickener",
    "Food-thickening agent",
    "Gelatin substitute",
    "Food thickener",
    "Food thickener",
    "Chef's thickening agent",
    "Culture medium",
    "Food thickener",
    "Lab culture medium",
    "Stabilizing kitchen supply",
    "Culture medium in a 59-Down",
    "Soup thickener",
    "Seaweed derivative",
    "Food thickener",
    "Ingredient in many Asian desserts",
    "Food thickener",
    "Petri dish gel",
    "With 54-Down, food gelling agent",
    "Vegan gelatin substitute",
    "Medium in bio labs",
    "Journalist/writer Herbert",
    "Gelatin substitute",
    "Actor John of \"Sands of Iwo Jima\"",
    "Petri dish gel",
    "Food thickener",
    "Culture medium",
    "Petri dish gel",
    "Lab gel",
    "Food thickener",
    "Food thickener",
    "Gelatinous ingredient in desserts",
    "John who co-starred in \"Sands of Iwo Jima\"",
    "Food thickener",
    "Clarifying agent in brewing",
    "Food thickener",
    "Gel made from seaweed",
    "Environment for multiplication, of sorts",
    "Petri dish gel",
    "Biology lab supply",
    "Bacteriologist's base",
    "Food stabilizer",
    "Culture medium",
    "Petri dish gel",
    "Petri dish stuff",
    "It helps a culture advance",
    "Lab medium",
    "Gelatin substitute",
    "Food thickener",
    "Food thickener",
    "Petri dish gel",
    "Seaweed product",
    "Culture medium",
    "Food thickener",
    "Food thickener",
    "Thickening agent",
    "Lab gel",
    "Lab medium",
    "Culture content",
    "Germ jelly",
    "Food thickener",
    "Thickening agent",
    "Petri dish filler",
    "Lab gel",
    "Ice cream thickening agent",
    "Thickening agent",
    "Biology lab supply",
    "Herbert who won a Pulitzer for \"The People's Choice\"",
    "Ice cream ingredient",
    "Petri dish filler",
    "Gel in a lab",
    "What E. coli eat",
    "Food thickener",
    "Ice cream thickener",
    "Petri dish filler",
    "Thickening agent in food",
    "Staph dining area?",
    "Ice cream additive",
    "Gelatin substitute",
    "Cultural aid?",
    "Lab gel",
    "Thickening agent",
    "Seaweed substance",
    "Seaweed extract",
    "Chinese gelatin",
    "Seaweed substance",
    "Culture contents",
    "Thickening agent",
    "Germs may grow in it",
    "Gel made from seaweed",
    "Where bacteria may flourish",
    "Seaweed substance",
    "Gelatinlike base",
    "Biology lab supply",
    "Petri dish filler",
    "Food thickening agent",
    "\"Fort Apache\" actor, 1948",
    "So-called \"Chinese gelatin\"",
    "Actor John of \"Sands of Iwo Jima\"",
    "Algae product",
    "Mold medium",
    "It makes things gel",
    "Lab gel",
    "Germs might grow in it",
    "Food thickener",
    "Seaweed product",
    "1934 Pulitzer winner Herbert",
    "\"The People's Choice\" historian Herbert",
    "Actor John, once married to Shirley Temple",
    "Gelatin substitute",
    "1934 Pulitzer writer Herbert",
    "Ice cream thickener",
    "1934 Pulitzer author Herbert",
    "Ice cream thickener",
    "Seaweed product",
    "Culture site",
    "Lab culture",
    "Ice cream thickener",
    "Food thickening agent",
    "Petri dish contents",
    "Pulitzer author Herbert",
    "Food gelling agent",
    "Food thickener",
    "Culture base",
    "John of ?ôSands of Iwo Jima?ö",
    "Emulsifying agent",
    "Pulitzer-winning author Herbert",
    "Seaweed substance",
    "Petri dish contents",
    "Actor John",
    "Actor John",
    "Seaweed product",
    "\"Miracle Mile\" star",
    "Seaweed derivative"
  ],
  "sued": [
    "Made the case against?",
    "Took to court",
    "Wouldn't settle, say",
    "Accused of infringement, say",
    "Went after, in a way",
    "Went to court, say",
    "Went after, in a way",
    "Took to court",
    "Wouldn't settle, say",
    "Went after, in a way",
    "Sought legal redress",
    "Went after, in a way",
    "Made a case against?",
    "Didn't take it lying down, say",
    "Like patent infringers, often",
    "Like some libelers",
    "Took to court",
    "Like some copyright infringers",
    "Took to court",
    "Went after",
    "Exercised a legal option",
    "Took to court",
    "Like a malfeasant, often",
    "Made a case?",
    "Like a defendant in court",
    "Courted ... or used the courts",
    "Pleaded",
    "Went after",
    "Sought a judgment",
    "Took action against",
    "Took to court",
    "Like some polluters",
    "Like a shyster, often",
    "Sought damages",
    "Sought damages",
    "Engaged in litigation",
    "Took to court",
    "Tried to get back at",
    "Sought reparations from",
    "Wooed",
    "Petitioned"
  ],
  "okok": [
    "\"All right, we get it!\"",
    "\"Please, enough already!\"",
    "\"Enough! I get it!\"",
    "\"All right already!\"",
    "\"All right, I'll do it!\"",
    "\"All right already, I get it!\"",
    "\"All right, already!\"",
    "\"Enough, I get it!\"",
    "\"I get your point. Jeez!\"",
    "\"Enough already ... relax!\"",
    "\"I get it already!\"",
    "Assent to relent",
    "\"Enough already!\"",
    "Relenting assent",
    "\"All right already!\"",
    "\"I get it, stop nagging me!\"",
    "\"All right already!\"",
    "\"I heard you! I heard you!\""
  ],
  "neon": [
    "Strip lighting",
    "Like some advertising lights",
    "Gas in glowing signs",
    "Element of show business?",
    "___ Deion (onetime football nickname)",
    "Extra-bright",
    "Gas that's lighter than air",
    "Like some lights",
    "Vividly colored",
    "Light element?",
    "Illuminating gas",
    "Very bright, as colors",
    "One of the noble gases",
    "It's a gas",
    "Superbright",
    "Illuminating gas",
    "Bright night light",
    "Ne, on the periodic table",
    "Sign gas",
    "Sign gas",
    "Element between helium and argon on the periodic table",
    "Superbright",
    "It's under helium in the periodic table",
    "Superbright",
    "City light",
    "Fifth-most abundant element in the universe",
    "<--, on the periodic table",
    "Gas in commercial signs",
    "Commercial light",
    "Gas in signs",
    "10 on a table",
    "___ Deion (onetime nickname in the N.F.L.)",
    "Like some Crayola crayons",
    "Superbright",
    "Certain tube filler",
    "Bright light",
    "Gas in advertising lights",
    "Like many highlighter colors",
    "Times Square flasher?",
    "It's next to fluorine on the periodic table",
    "Loud, as a color",
    "Like many highlighter colors",
    "Word in the names of some bright colors",
    "Gas in commercial lights",
    "Like an \"Open 24 hours\" sign, perhaps",
    "Extra-bright",
    "Really bright",
    "Gas in a DINER sign",
    "It's noble",
    "Attention-getting sign",
    "Extra-bright",
    "Vegas gas",
    "Unreactive element",
    "Glowing gas",
    "Noble gas",
    "Gas in advertising signs",
    "Extra-bright, as a color",
    "Superbright",
    "Like some highlighter colors",
    "#10 on a table",
    "\"On tap\" sign, sometimes",
    "Gas in lights",
    "Old Dodge",
    "Really bright, as colors",
    "It's noble",
    "Rialto attention-getter",
    "It's noble",
    "\"Open 24 hours\" sign, maybe",
    "Strip sign",
    "___ lamp",
    "Gas in diner signs",
    "Like many Las Vegas signs",
    "Bygone Dodge",
    "Element of Times Square",
    "Chemical element with the symbol Fe",
    "Light material?",
    "City light",
    "Rialto sign",
    "Quite bright",
    "With 62-Across, nickname for former N.F.L. star Sanders",
    "Noble element",
    "Las Vegas light",
    "Glass gas",
    "\"Open late\" sign, maybe",
    "Very bright",
    "Kind of light",
    "Nightclub light",
    "It's inert",
    "Conductor seen at night",
    "It's inert",
    "Superbright",
    "Dodge model",
    "Light up in a bar",
    "Gas in advertising lights",
    "Gas light",
    "Light on Broadway",
    "Diner sign",
    "It's noble",
    "It's a gas",
    "Colorful tropical fish",
    "Number 10 on a table",
    "Dodge compact",
    "It's inert",
    "\"Open all night\" sign, maybe",
    "Shockingly bright",
    "Glower",
    "Gaudy sign",
    "Light on Broadway",
    "Liquid ___ (refrigerant)",
    "Light stuff",
    "Light gas",
    "Kind of sign",
    "It's a gas",
    "Gas in signs",
    "Very bright",
    "Element #10",
    "Luminous sign",
    "Like some colors",
    "Strip lighting",
    "It may say \"DINER\"",
    "Inert element used in lights",
    "No. 10",
    "Kind of sign",
    "It's a gas",
    "Element #10",
    "Broadway brightener",
    "Extra-bright",
    "Cryogenic refrigerant",
    "Glass tube filler",
    "Broadway luminary?",
    "Scientific discovery of 1898",
    "Blindingly bright",
    "Ginza glower",
    "With 121-Across, they're bright on Broadway",
    "No. 10 in a list",
    "Brightly colored",
    "Like some colors",
    "Kind of light",
    "Element in Geiger counters",
    "Brightly-colored",
    "Vegas sight",
    "Marquee filler",
    "Broadway brightener",
    "Advertising sign",
    "Vegas sign",
    "Advertising sign",
    "Barroom fixture",
    "Hardly drab",
    "Element found in Geiger counters",
    "Sign gas",
    "Element #10",
    "It's a gas",
    "Eye-catching sign",
    "It's a gas",
    "Bit of bar advertising",
    "Broadway blinker",
    "Gas in Vegas",
    "Superbright",
    "Very bright, as colors",
    "Glitzy sign",
    "\"Open 24 hours\" sign, maybe",
    "Night light",
    "Laser element",
    "Sign gas",
    "Certain Dodge",
    "Vegas night sight",
    "Storefront item",
    "Extra bright",
    "Tavern light",
    "Brightly colored",
    "Ultrabright",
    "Rialto light",
    "Plymouth model",
    "Advertising sign",
    "Very bright",
    "Vegas gas",
    "Bright, colorwise",
    "Geissler tube illuminant",
    "It's a gas",
    "Light gas"
  ],
  "tutu": [
    "It sticks out in a dance studio",
    "Ballerina wear",
    "Ballerina's skirt",
    "\"The Nutcracker\" skirt",
    "Ballerina's skirt",
    "Frilly garment",
    "Short dance wear",
    "\"Swan Lake\" article of attire",
    "Ballerina's wear",
    "\"Swan Lake\" attire",
    "Need for some dancers",
    "What one might go for a spin in?",
    "Hippo's wear in 4-Down",
    "\"Swan Lake\" attire",
    "Attire usually worn with slippers",
    "Object frequently painted by Degas",
    "Article to take a spin in?",
    "\"Black Swan\" attire",
    "Ballerina's skirt",
    "Dancer's duds",
    "South African Peace Nobelist, 1984",
    "Noted archbishop",
    "Something a person may take a spin in?",
    "Peace Nobelist from South Africa",
    "Ballet skirt",
    "Nobel-winning bishop",
    "Ballerina's garb",
    "Cabriole performer's wear",
    "Dancer's dress",
    "Ballet attire",
    "Piece to leap in",
    "Dress not comfortable to sit in",
    "Skirt to twirl in",
    "\"The Nutcracker\" attire",
    "Ballet piece",
    "Ballet wear",
    "You might go for a spin in it",
    "Degas detail",
    "1984 Peace Nobelist",
    "1984 Peace Nobelist",
    "South African archbishop Desmond",
    "Sight in a Degas painting",
    "1984 Peace Nobelist",
    "\"Swan Lake\" garb",
    "One might go for a spin in it",
    "1984 Peace Nobelist",
    "Ballerina's dress",
    "Dancer's dress",
    "Something to go for a spin in?",
    "Dainty attire",
    "Dancing outfit",
    "\"Swan Lake\" skirt",
    "Stage apparel",
    "Ballet wear",
    "\"Swan Lake\" attire",
    "South African Peace Nobelist",
    "\"Swan Lake\" piece?",
    "1984 Peace Nobelist",
    "1984 Peace Prize recipient",
    "Item in which to do a plie",
    "Ballerina's skirt",
    "\"Coppelia\" costume",
    "South African Nobelist",
    "1984 Peace Nobelist",
    "Skater's attire, at times",
    "Peace Nobelist of 1984",
    "1984 Peace Nobelist",
    "Tarlatan skirt",
    "Tarlatan skirt",
    "Attire for walking on tiptoe",
    "Tarlatan skirt",
    "Ballet attire",
    "Projecting skirt",
    "Outfit for Pavlova",
    "\"Swan Lake\" garb",
    "1984 Nobelist",
    "Balletic put-on",
    "1984 Nobelist",
    "Hippo's wear in \"Fantasia\"",
    "Ballerina's skirt"
  ],
  "idid": [
    "Possible response to \"Who took the last cookie?\"",
    "Brief confession",
    "Terse admission",
    "Terse response accepting responsibility",
    "Terse admission",
    "Brief admission of responsibility",
    "Terse admission",
    "Reply of confirmation",
    "\"___ it!\"",
    "\"___ it my way\"",
    "\"Look what ___!\"",
    "\"Look what ___!\"",
    "\"What ___ for Love\" (\"A Chorus Line\" song)",
    "\"Look what ___!\"",
    "\"Look what ___!\"",
    "\"What ___ for Love\" (\"A Chorus Line\" song)",
    "\"___ it my way\" (Sinatra lyric)",
    "\"___ it!\" (gleeful cry)"
  ],
  "tour": [
    "Musicians are often on it",
    "Go all over",
    "Host's offer at a housewarming",
    "Part of a college visit, typically",
    "See the sights",
    "Museum offering",
    "P.G.A. ___",
    "Announcement from a band",
    "Rock stars are frequently on this",
    "Take the show on the road",
    "Grand ___ (cultural trip around Europe)",
    "Museum offering",
    "With 23-Down, leader of a sightseers' group",
    "College campus offering",
    "Get the show on the road",
    "Many a vacation package",
    "Get the show on the road",
    "Band news",
    "What some bands and just-published authors do",
    "Museum docent's offering",
    "A musical might be on one",
    "A band may be on one",
    "Go places",
    "Walk through",
    "What a Realtor may give",
    "___ de France",
    "See the sights",
    "Travel by bus, say",
    "Band's schedule",
    "Historic site option",
    "Go from gig to gig",
    "Soldier's period of service",
    "Band's plan",
    "Military stint",
    "Duty for a docent",
    "Docent's offering",
    "See the sights",
    "Docent's offering",
    "Museum offering",
    "See the sights",
    "What a band may have planned",
    "It may be grand",
    "Hit the road",
    "Promote an album, possibly",
    "Gig after gig",
    "Studio visit",
    "Big concert news",
    "It may be taken around the world",
    "___ de France",
    "Period of duty",
    "Big music news",
    "Vacation plan, maybe",
    "Sightseeing trip",
    "Take the show on the road",
    "Take the show on the road",
    "It may be grand",
    "___ de force",
    "Hit the road",
    "Kind of bus",
    "Sightseeing trip",
    "Beatles' \"Magical Mystery___\"",
    "The Beatles' \"Magical Mystery___\"",
    "Get the show on the road",
    "Package ___",
    "Sightsee",
    "Visitor's trip",
    "Go by bus, perhaps",
    "The rounds",
    "Guided excursion",
    "Excursion"
  ],
  "iroc": [
    "Muscle car acronym",
    "Classic Camaro",
    "___-Z (classic Camaro)",
    "Classic Camaro",
    "Classic Camaro, informally",
    "Car named after an automotive competition",
    "Camaro ___-Z",
    "Classic Camaro",
    "Classic Camaro",
    "Classic Camaro",
    "Old stock car inits.",
    "Classic '80s Camaro",
    "Camaro ___-Z",
    "___-Z (classic car)",
    "Camaro ___-Z",
    "Camaro ___-Z",
    "___-Z",
    "___-Z (zippy Camaro)",
    "Sporty Camaro",
    "Camaro ___-Z",
    "High-performance Camaro",
    "___-Z (old Camaro)",
    "High-performance Camaro ___-Z",
    "Camaro model"
  ],
  "edam": [
    "Cheese whose name is a semordnilap",
    "Cheese used in Babybels",
    "Wax-wrapped cheese",
    "Toponym in the dairy aisle",
    "Cheese of the Netherlands",
    "Semihard Dutch cheese",
    "Dutch cheese",
    "It can be substituted for Gouda",
    "Eponymous Dutch town",
    "Red-cased import",
    "Mild yellow cheese",
    "Dutch cheese town",
    "Eponymous Dutch town",
    "Dutch town with a cheese named after it",
    "Alternative to Leyden or Boerenkaas",
    "Cheese with a red covering",
    "Traditional product of North Holland",
    "Semihard Dutch cheese",
    "Cheese from the Netherlands",
    "Round product with a wax wrapper",
    "Dutch cheese town",
    "Dutch cheese",
    "Mild cheese",
    "Choice of cheese",
    "Big cheese?",
    "Big cheese in the Netherlands",
    "Cheese similar to Gouda",
    "Eponymous Dutch town",
    "Answer to the riddle \"What cheese is made backward?\"",
    "What queso de bola is another name for",
    "Mild cheese",
    "Dutch cheese",
    "Cheese sometimes paired with pinot noir",
    "Sweet-curd cheese",
    "Cheese coated in red wax",
    "Dutch cheese town",
    "Cheese used in Babybels",
    "Main ingredient in queso relleno",
    "Dutch export",
    "Dutch cheese",
    "Dutch export",
    "Dutch cheese",
    "Dutch export",
    "Town whose exports are waxed",
    "Dutch cheese",
    "Cheese named after a town in Holland",
    "Gouda cousin",
    "Gouda alternative",
    "Big wheel in the cheese world?",
    "Dutch wheels",
    "Cheese made from the milk of Friesian cows",
    "Cheese selection",
    "Eponymic Dutch city",
    "Cheese with a red coat",
    "Cheese in a red wheel",
    "Cheese with red wrapping",
    "Cracker topper",
    "Cheese that doesn't spoil",
    "Hard cheese",
    "Queso relleno cheese",
    "Big wheel at a reception?",
    "Red-skinned food",
    "Edible red ball",
    "Cheese coated in red wax",
    "Dutch cheese town",
    "Dutch cheese",
    "Cheese ball?",
    "Mild cheese",
    "Cheese with a red coat",
    "Imported cheese",
    "Dutch export",
    "Mild cheese",
    "Cheese sold in red paraffin",
    "Dutch export",
    "Dutch dairy product",
    "Cheese choice",
    "Dutch cheese",
    "Cheese town",
    "Red ball?",
    "Cheese choice",
    "Town on the IJsselmeer",
    "Dutch cheese",
    "Mild yellow cheese",
    "Cheese town",
    "It's mild and a bit nutty",
    "Cracker topper",
    "Dutch city with a cheese market",
    "Cheese in a ball",
    "Dutch export",
    "Paraffin-wrapped cheese",
    "Noted cheese town",
    "Cheese in a ball",
    "Dutch export",
    "Dutch cheese",
    "Cheese type",
    "Dutch cheese",
    "Dutch cheese",
    "Wax-coated cheese",
    "Dutch cheese",
    "Toponymic dairy item",
    "Wax-coated cheese",
    "Cheese in a ball",
    "Dutch cheese",
    "Dutch export",
    "It's often given a red coat",
    "Cheese from Europe",
    "Dutch cheese",
    "Skimmed milk product",
    "Dutch seaport",
    "Dutch cheese",
    "Dutch cheese",
    "Wax-covered cheese",
    "City on the Ijsselmeer",
    "Red-wrapped Dutch cheese",
    "It often has a red coat",
    "Gouda alternative",
    "Dutch import",
    "Dutch cheese",
    "Dutch cheese",
    "Dutch export",
    "Dutch product",
    "Dutch export",
    "Dutch cheese",
    "Dutch export",
    "Dutch treat?",
    "Dutch cheese",
    "Gouda alternative",
    "Something to nibble on with Beaujolais",
    "Cracker topper",
    "Wheel from Holland",
    "Product encased in red wax",
    "___ cheese",
    "Dutch treat",
    "Dutch cheese",
    "Dutch cheese",
    "Dutch cheese",
    "Mild cheese",
    "Dutch cheese",
    "Dutch cheese",
    "Cheese burg?",
    "Holland export",
    "Dutch cheese",
    "Ijsselmeer Dam site",
    "Cracker cheese",
    "Tray cheese",
    "Round cheese",
    "Dutch cheese",
    "North Holland seaport",
    "It has a red coat",
    "Cheesy town",
    "Dutch treat",
    "Dutch export",
    "Netherlands town",
    "What's wrapped up in the whole ball of wax?",
    "Red-coated cheese",
    "It's made in balls",
    "Eponymous Dutch town",
    "Cocktail party offering",
    "Mild cheese",
    "Round cheese",
    "Dutch treat",
    "Red-coated food item",
    "Kind of cheese",
    "Imported cheese",
    "Cheese in a ball",
    "Red rind contents",
    "Pressed cheese",
    "Cheese choice",
    "Mild cheese",
    "Cheese from Holland",
    "Kind of cheese",
    "Wax-coated cheese",
    "Dutch export",
    "Dutch cheese",
    "Port on the Ijsselmeer",
    "Party cheese",
    "Holland export",
    "Cheese town",
    "Gouda's cousin",
    "Ball-shaped cheese",
    "Red-clad cheese",
    "Popular Dutch export"
  ],
  "defy": [
    "Go against",
    "Oppose",
    "Resist, as orders",
    "Refuse to obey",
    "Go against",
    "Brave",
    "Fail to follow",
    "Challenge",
    "Stand up to",
    "Stand up to",
    "Openly challenge",
    "Boldly resist",
    "Go up against",
    "Stand up to",
    "Go against, as someone's will",
    "Flout",
    "Stare down, say",
    "Go against",
    "Thumb one's nose at",
    "Beard",
    "Stand up to",
    "Buck",
    "Thumb one's nose at",
    "Stand up to",
    "Brave",
    "Stand up to"
  ],
  "nosy": [
    "All up in another's business",
    "Digging too deep",
    "Really digging",
    "Liable to snoop",
    "Like some bad next-door neighbors",
    "Likely to butt in",
    "Liable to peep",
    "Snooping",
    "Peeping, say",
    "Inquisitive",
    "Asking too many questions",
    "Overly curious",
    "Given to eavesdropping",
    "Having a propensity to dig",
    "Curious to a fault",
    "Paying too much interest",
    "Curious to a fault",
    "Not merely interested",
    "Like stereotypical TV neighbors",
    "Busy",
    "Digging too deep",
    "In the wrong business?",
    "Prying",
    "Digging for dirt?",
    "Like a quidnunc",
    "Like a buttinsky",
    "Snoopy",
    "Meddlesome",
    "Not just casually interested",
    "Like a busybody",
    "Like a yenta",
    "Inquisitive",
    "Too inquisitive",
    "Meddlesome",
    "Curious",
    "Too interested",
    "Snoopy",
    "Snoopy",
    "___ Parker",
    "Prying",
    "Prying",
    "Prying",
    "Not merely interested",
    "Like a yenta",
    "Inquisitive",
    "Inquiring",
    "Like a yenta",
    "Like a yenta",
    "Prying",
    "More than just interested",
    "Looking for dirt, perhaps",
    "Too inquisitive",
    "Prying",
    "Obviously interested",
    "Snoopy",
    "Prying",
    "Meddlesome",
    "Unduly interested",
    "Overcurious",
    "Like a busybody",
    "Given to eavesdropping",
    "Meddlesome",
    "Snoopy"
  ],
  "hots": [
    "Red ___",
    "Intense attraction, with \"the\"",
    "See 48-Down",
    "Red ___",
    "Have the ___ for",
    "Sexual attraction, with \"the\"",
    "Desire, with \"the\"",
    "Red ___",
    "Intense attraction, with \"the\"",
    "Have the ___ for",
    "Red ___",
    "Candies that burn the mouth",
    "Have the ___ for",
    "Have the ___ for",
    "Romantic attraction, slangily"
  ],
  "ours": [
    "Between you and me",
    "Mine and yours",
    "Couple's word",
    "Not mine alone",
    "\"___ is not to reason why ...\"",
    "Sharer's word",
    "Not just for me",
    "Not just mine",
    "Barbra Streisand album \"A Love Like ___\"",
    "Not mine alone",
    "Not just mine",
    "What we share",
    "\"___ is not to reason why\"",
    "Willa Cather's \"One of ___\"",
    "What we have",
    "Partners' pronoun",
    "Partnership indicator",
    "\"Yours, Mine and ___\"",
    "Opposite of theirs",
    "Sharing word",
    "\"One of ___\" (1923 Pulitzer-winning novel by Willa Cather)",
    "Sharer's word",
    "What we share",
    "\"A Love Like ___\" (Barbra Streisand album)",
    "Sharer's pronoun",
    "Belonging to you and me",
    "Sharer's pronoun",
    "Willa Cather's \"One of ___\"",
    "Couple's word",
    "\"___ is not to ...\"",
    "Partners' word",
    "Owned jointly by you and me",
    "Willa Cather's \"One of ___\"",
    "What we have",
    "Couple's word",
    "Sharer's word",
    "\"This Love of ___\" (1945 film)",
    "Yours and mine",
    "Like community property",
    "Willa Cather's \"One of ___\"",
    "Sharer's word",
    "Yours and mine",
    "Not just mine, say",
    "It's for you and me",
    "Sharer's word",
    "It makes \"mine\" a double",
    "Sharer's word",
    "Not theirs",
    "Belonging to us",
    "Not theirs",
    "Mine and yours",
    "Yours and mine",
    "Not theirs",
    "Not theirs",
    "Sharers' word",
    "Belonging to us",
    "Sharer's pronoun",
    "Willa Cather's \"One of___\"",
    "Sharer's word",
    "\"___ is a great wild country\": Browning",
    "Willa Cather's \"One of ___\"",
    "Not theirs",
    "Not just mine",
    "Willa Cather's \"One of ___\"",
    "Couple's pronoun"
  ],
  "reel": [
    "Wind on the water?",
    "Be taken aback",
    "Dance done to fiddle music",
    "Scottish folk dance",
    "Attachment to a fishing rod",
    "Show evidence of shock",
    "Dance done to fiddle music",
    "Rod and ___ (fishing equipment)",
    "Walk lurchingly",
    "Part of a film archive",
    "List quickly, with \"off\"",
    "Walk dizzily",
    "Film site",
    "Stumble around in a daze",
    "Rod's partner",
    "Stagger",
    "Stagger",
    "Part of a film archive",
    "Rod's mate",
    "Rod and ___",
    "Collection of highlights or bloopers",
    "Walk like a tosspot",
    "Walk while dizzy",
    "Kite adjunct",
    "Fishing line holder",
    "Casting need",
    "Film library unit",
    "React to terrible news, say",
    "Fishing device",
    "React to a haymaker",
    "An early Disney cartoon had one",
    "Stagger",
    "Line holder",
    "Recite quickly, with \"off\"",
    "Appear stunned",
    "Piece of fly-casting equipment",
    "Walk while dizzy",
    "Film unit",
    "Film spool",
    "Fishing line holder",
    "Country dance",
    "Fishing line holder",
    "Cousin of a jig",
    "Projectionist's unit",
    "Casting need",
    "Show shock, in a way",
    "Pull (in)",
    "Casting need",
    "Readily recite, with \"off\"",
    "Fiddler's tune",
    "Spinner",
    "Suffer the effects of a haymaker",
    "Recite rapidly, with \"off\"",
    "Casting requirement",
    "Line holder",
    "Fishing line holder",
    "Rod's partner",
    "Irish dance",
    "Film unit",
    "It holds the line",
    "Stagger",
    "Rod's partner",
    "Predigital film part",
    "What you might wind up with",
    "Lurch",
    "Fiddler's tune",
    "Fishing line winder",
    "Projector unit",
    "Line holder",
    "Walk drunkenly",
    "Rod's partner",
    "Spin",
    "Contents of a large, round, flat can",
    "Stagger",
    "Draw (in)",
    "Film vault holding",
    "Pull (in)",
    "Casting need",
    "Fishing partner of 62-Down",
    "Rod attachment",
    "Part of a Hollywood archive",
    "Virginia dance",
    "A line winds in and out of it",
    "Movie roll",
    "Spin",
    "Film unit",
    "Rod attachment",
    "Film part",
    "Rod's partner",
    "Walk like a sot",
    "Old-fashioned dance",
    "Cousin of a hornpipe",
    "Dance that's done to \"Drowsy Maggie\"",
    "Walk like a sot",
    "Whirl",
    "Angler's need",
    "Fishing line holder",
    "Item of 5-Down",
    "It holds the line",
    "Fishing rod attachment",
    "Fishing equipment",
    "Equipment for catching a 2-Down",
    "Dance at a barn dance",
    "Fall back",
    "Film segment",
    "Movie unit",
    "Tape measure part",
    "Not walk straight",
    "Country dance",
    "Tape recorder part",
    "It holds the line",
    "It might give you a line",
    "Spin",
    "Spinner",
    "Quaint dance",
    "Casting requirement?",
    "Microfilm unit",
    "Fishing rod attachment",
    "Lively dance",
    "Projector load",
    "It might wind up on a boat",
    "Stagger",
    "Not walk straight",
    "Fishing device",
    "Film unit",
    "Lively old dance",
    "It holds the line",
    "Movie segment",
    "Rod's companion",
    "Stagger",
    "Fishing item",
    "Projection room item",
    "Movie unit",
    "Film spool",
    "Angling need",
    "Film amount",
    "Country dance",
    "Stagger",
    "Movie unit",
    "Director's unit",
    "Bit of fishing gear",
    "Not walk a straight line",
    "Lively dance",
    "Be giddy",
    "Lively dance",
    "Fishing equipment",
    "Projectionist's need",
    "Recite, with \"off\"",
    "Tackle-box item",
    "Virginia ___",
    "Film short",
    "Fishing gear"
  ],
  "slid": [
    "Took a dive",
    "Dirtied a baseball uniform, in a way",
    "Reached base feet-first",
    "Declined",
    "Got home safely, in a way",
    "Took a tumble, as the stock market",
    "Tried to steal second, maybe",
    "Dropped a bit",
    "Declined",
    "Dropped, as poll numbers",
    "Declined",
    "Avoided a tag, say",
    "Lost traction",
    "Declined",
    "Dropped a bit, as the stock market",
    "Skidded",
    "Declined, as stocks",
    "Declined a bit",
    "Lost traction",
    "Lost traction",
    "Tried to make it home, say",
    "Came into a base horizontally",
    "Got home in a cloud of dust",
    "Lost traction",
    "Did a glissade",
    "Lost traction",
    "Dropped, as in the polls",
    "Declined",
    "Moved on ice",
    "Went down to second?",
    "Went down",
    "Dove home",
    "Declined",
    "Lost traction",
    "Fell, as interest rates",
    "Didn't have good traction",
    "Went down a firehouse pole, e.g.",
    "Barely made it home?",
    "Didn't have traction",
    "Glided",
    "Tried to beat the tag",
    "Used a firehouse pole",
    "Suffered a sell-off",
    "Went down",
    "Dove home",
    "Hit the dirt",
    "Headed downward",
    "Gradually fell",
    "Went feet first",
    "Coasted",
    "Fell off",
    "Declined",
    "Coasted",
    "Eased gently",
    "Skidded",
    "Came in horizontally",
    "Coasted"
  ],
  "tire": [
    "Tucker",
    "One for the road?",
    "Tucker out",
    "Lose stamina",
    "Sight on the back of a jeep",
    "Part of many a tree swing",
    "On an airplane, it's filled with nitrogen rather than air",
    "Poop",
    "Pit stop replacement, often",
    "Flag",
    "Drain",
    "Become drowsy",
    "It's fixed when it's flat",
    "Goodyear product",
    "Feature of an old-fashioned swing",
    "Lose steam",
    "Tucker out",
    "Flag",
    "Firestone product",
    "Poop out",
    "Weary",
    "Part of a makeshift swing",
    "Begin to give out",
    "One of 18 on an 18-wheeler",
    "Become bored (of)",
    "Big unicycle part",
    "One getting bald over time",
    "Grow weary",
    "Part of an old-fashioned swing",
    "Flag",
    "Bad thing to blow ... or what each of the circled letters in this puzzle represents",
    "Fade",
    "Run low on juice",
    "Spare change?",
    "Get bored (of)",
    "Oxymoronic purchase at a blowout sale?",
    "Flag",
    "Exhaust",
    "Fade",
    "Uniroyal product",
    "One going bald over time?",
    "Michelin product",
    "See 50-Across",
    "Need spelling, say",
    "One needing pressure to perform well",
    "Kind of pump",
    "Drain",
    "Flag",
    "Goodyear or BFGoodrich product",
    "It can be balanced and biased simultaneously",
    "See 58-Across",
    "Bridgestone product",
    "Tucker out",
    "Get beat",
    "Ring around a rim",
    "Jade",
    "Inflation target",
    "Tucker (out)",
    "Tucker out",
    "Flag",
    "Pit stop change",
    "It rotates ... and may be rotated",
    "Almost any part of the Michelin Man",
    "Rubber that meets the road",
    "Poop",
    "Become bushed",
    "Part of a makeshift swing",
    "Spare change?",
    "Makeshift swing",
    "Place for studs",
    "Poop",
    "It's worn on a road trip",
    "Spare change?",
    "Flag",
    "Wear down",
    "Goodyear product",
    "Get jaded",
    "Flag",
    "It may need air",
    "One may become bald",
    "Kind of jack",
    "With 43-Across, leaver of a mark",
    "Bad thing to blow",
    "Bore",
    "See 3-Down",
    "Spare item",
    "Michelin product",
    "It may be belted",
    "It may be worn after traveling",
    "Get bushed",
    "Poop out",
    "Run out of gas",
    "Bore",
    "Poop out",
    "Drain",
    "Bad thing to blow",
    "Flag",
    "Become bushed",
    "Poop",
    "BFGoodrich item",
    "Spare change?",
    "Saturn revolver",
    "Bias-ply, for one",
    "Michelin product",
    "Lose steam",
    "Uniroyal product",
    "Lose steam",
    "Goodyear product",
    "Poop out",
    "Get bushed",
    "Spare, maybe",
    "Spare, e.g.",
    "Enervate",
    "Wind, so to speak",
    "Kind of iron or chain",
    "Cross-ply, e.g.",
    "Radial, e.g.",
    "Rubber roller",
    "Akron product",
    "Flag",
    "Flag",
    "Flag",
    "Kind of pump",
    "Flag",
    "Exhaust",
    "Flag",
    "Become fatigued",
    "Bore",
    "Retread, e.g.",
    "Flag",
    "It may have bias"
  ],
  "goat": [
    "Neologism for the \"best ever\"",
    "Horned farm animal",
    "Bearded farm animal",
    "Source of the milk for chèvre cheese",
    "Best ever, acronymically",
    "Main ingredient in the curry dish kosha mangsho",
    "Charge",
    "Mother that might have a beard",
    "U.S. Naval Academy mascot",
    "One with a beard",
    "One forced to take the blame",
    "Curse of the Billy ___, Cubs \"jinx\" that ended in 2016",
    "Target of blame",
    "Zodiac symbol",
    "Year of the ___ (most of 2015)",
    "Billy or nanny",
    "Animal that butts",
    "Bearded mountain dweller",
    "Charge",
    "Attack",
    "Farm animal with horns",
    "Billy or nanny",
    "Chimera, in part",
    "Bearded beast",
    "Billy, e.g.",
    "Faun, in part",
    "Big kid?",
    "One might be kidding",
    "Bearded animal",
    "Blame bearer",
    "Animal with a beard",
    "It's half the faun",
    "Butt of jokes",
    "Bearded animal",
    "Bearded farm animal",
    "Victim",
    "Milk source",
    "Attack",
    "Begin enthusiastically",
    "Blame bearer",
    "Grown-up kid",
    "Kind of cheese",
    "Lamb : sheep :: kid : ___",
    "Charge",
    "Capricorn's symbol",
    "Attack",
    "Animal that gives milk",
    "Charge",
    "It's half the faun",
    "Attack",
    "Barnyard butter",
    "Razzing victim",
    "Annapolis mascot",
    "Butter",
    "Satyr, in part",
    "One with kids",
    "Zodiac symbol",
    "Nanny, perhaps"
  ],
  "lune": [
    "Apt foreign rhyme of \"moon\"",
    "Debussy's \"Clair de ___\"",
    "Part of une eclipse",
    "Debussy's \"Clair de ___\"",
    "Moon, in Montreuil",
    "Crescent",
    "Crescent shape",
    "\"Clair de ___\"",
    "Figure in plane geometry",
    "Crescent shape",
    "Millet's moon",
    "Sphere segment",
    "Debussy's \"Clair de ___\"",
    "Crescent",
    "Crescent",
    "Crescent",
    "Moon over my ami?",
    "Crescent-shaped figure",
    "Symbol on Pakistan's flag",
    "Crescent"
  ],
  "gown": [
    "Prom purchase",
    "Wedding dress, e.g.",
    "Formal frock",
    "Order for Oscar night",
    "Subject of interest in the question \"Who are you wearing?\"",
    "Cap's partner",
    "Cap's partner",
    "Hospital wear",
    "Oscar night rental",
    "Dress for a ball"
  ],
  "spay": [
    "Do a certain veterinary job",
    "Neuter, as a pet",
    "Fix",
    "Do a vet's job on",
    "Do a veterinarian's job on",
    "Fix, as a dog",
    "Fix",
    "Neuter",
    "Make litterproof?",
    "Fix, as a cat",
    "Easy-to-carry telescope",
    "Neuter",
    "Tenure",
    "Fix, as a toy",
    "Fix",
    "Fix",
    "Alter",
    "Fix",
    "Fix",
    "Fix, as a pointer",
    "Fix",
    "Make unbearable?",
    "Fix",
    "Fix, as a cat",
    "Neuter",
    "Neuter",
    "Neuter",
    "Fix",
    "Neuter",
    "Fix, in a way",
    "Neuter",
    "Fix",
    "Neuter",
    "Alter",
    "Neuter",
    "Fix, in a way",
    "Neuter",
    "Neuter",
    "Do a vet's job"
  ],
  "yaks": [
    "Hairy Tibetan beasts",
    "Shaggy beasts of 53-Across",
    "Chatters",
    "Shaggy beasts",
    "Gabs",
    "Animals that provided hair used in Chewbacca's costume",
    "Jabbers",
    "Gabs, gabs, gabs",
    "Won't shut up",
    "Sherpa's herd",
    "Natters",
    "Is a chatterbox",
    "Tibetan herd",
    "Goes on and on",
    "Shaggy Tibetans",
    "Asian bovines",
    "Talks, talks, talks",
    "Talks one's head off",
    "Chews the fat",
    "Jabbers",
    "Shoots the breeze",
    "Gabs",
    "Jaws",
    "Hirsute Tibetans",
    "Tibetan bovines",
    "Talks one's head off",
    "Rattles on",
    "Shoots the breeze",
    "Talks a blue streak",
    "Gabs",
    "Tibetan beasts",
    "Goes on and on and...",
    "Beasts of burden",
    "Ties up the phone",
    "Shaggy oxen",
    "Blathers",
    "Runs off at the mouth"
  ],
  "firs": [
    "Christmas trees",
    "Some Christmas purchases",
    "Two are named after Douglas and Fraser",
    "Composition of some mountain forests",
    "Some early January curbside pickups",
    "Christmastime purchases",
    "Christmas trees",
    "You may visit a lot of them before Christmas",
    "They may be decorated for the holidays",
    "January detritus",
    "Traditional Christmas purchases",
    "A lot of a Maine forest",
    "Alpine sights",
    "Holiday trees",
    "Needle holders",
    "Some evergreens",
    "Some December deliveries",
    "Christmas trees",
    "Cone makers",
    "Mountain covering",
    "Pines",
    "Trees near a tree line",
    "Holiday purchases",
    "Evergreens"
  ],
  "roar": [
    "Sound heard before many a classic movie",
    "Din from a den",
    "Crowd noise",
    "Waterfall sound",
    "Thunder",
    "Crowd noise",
    "Surf's sound",
    "Sound in a circus act",
    "Surf sound",
    "33-Across's sound",
    "Crowd noise",
    "Call of the wild",
    "Laugh hysterically",
    "Sound from a rowdy crowd",
    "Bellow",
    "Not just laugh",
    "Tornado sound",
    "Call of the wild?",
    "Plane engine's sound when taking off",
    "Sound to fear in the savanna",
    "What lions and big engines do",
    "Word rhyming with \"ignore\" in \"I Am Woman\"",
    "Laugh without restraint",
    "More than giggle",
    "Simba's warning",
    "Lion's sound",
    "Jet engine sound",
    "Crowd sound",
    "Surf sound",
    "Thunder",
    "2013 #1 Katy Perry hit",
    "V-8 sound",
    "Din",
    "Engine sound at Indy",
    "Sound of approval",
    "Surf sound",
    "Waterfall sound",
    "\"I am woman, hear me ___\"",
    "Big laugh",
    "Thunder",
    "Niagara Falls sound",
    "More than chuckle",
    "Big laugh",
    "Lion's sound",
    "Din",
    "Jet engine sound",
    "Sound heard before an MGM film",
    "Lion's sound",
    "Zoo sound",
    "Lion's sound",
    "Air show sound",
    "Surf sound",
    "Waterfall sound",
    "Have a hearty laugh",
    "Jet's noise",
    "Opening sound of an MGM film",
    "Cannon sound",
    "Belly laugh",
    "Surf sound",
    "Sound heard before some films start",
    "Lion's warning",
    "Belly laugh",
    "Jet engine's output",
    "No mere chuckle",
    "Hubbub",
    "Enthusiastic audience response",
    "Crowd sound",
    "Emulate a woman, in \"I Am Woman\"",
    "Have a hearty laugh",
    "React angrily",
    "Engine sound",
    "MGM sound effect",
    "Loud laugh",
    "Stadium sound",
    "Laugh heartily",
    "Big top noise",
    "Engine sound",
    "Sound from a 57-Down",
    "It may come from a crowd",
    "Surf sound",
    "Crowd sound",
    "Arena sound",
    "Crowd sound",
    "React angrily",
    "Laugh loudly",
    "Big laugh",
    "Not just tehee",
    "Crowd reaction",
    "Den din",
    "Die laughing, so to speak",
    "MGM sound effect",
    "Surf sound",
    "Not just chuckle",
    "Crowd noise",
    "Surf's sound",
    "Much more than a snicker",
    "Guffaw",
    "Din",
    "Din",
    "Din",
    "Sound of crowd approval",
    "Laugh heartily",
    "Overwhelming audience response",
    "Den din",
    "Crowd noise",
    "Crowd sound",
    "Hurricane sound",
    "Big laugh",
    "Expression of pride?",
    "Den din",
    "Den din",
    "Guffaw",
    "Surf sound",
    "Outcry",
    "Rocket's sound",
    "Surf's sound",
    "Shuttle launch sound",
    "Sea sound",
    "Engine sound",
    "Jungle warning",
    "Conch shell effect",
    "Fireball sound",
    "Rumble",
    "Surf sound",
    "35-Across's sound",
    "Crowd sound",
    "Guffaw",
    "Surf's sound",
    "Din",
    "More than chuckle",
    "First sound in an M-G-M film",
    "Surf's sound",
    "Jungle cry",
    "Simba's cry",
    "Safari sound",
    "Crowd sound",
    "More than laugh",
    "Surf sound",
    "Leonine sound",
    "Sound of waves",
    "Thunder",
    "Surf sound",
    "Bellow"
  ],
  "roni": [
    "Certain pizza topping, slangily",
    "Rice-A-___",
    "Rice-A-___",
    "Quaker Oats's Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Pasta ___ (Quaker brand)",
    "Noodle ___",
    "Rice-A-___",
    "Pasta, in product names",
    "Rice-A-___",
    "Commercial ending with Pasta",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-A-___",
    "Rice-a-___"
  ],
  "open": [
    "Forthright",
    "Start (with)",
    "Ready for business",
    "Word with secret or source",
    "Tournament type",
    "Many a tournament",
    "U.S. ___",
    "See 7-Down",
    "Like some minds and marriages",
    "Candid",
    "Agreeable (to)",
    "Available to the public",
    "Business sign that's flipped in the morning",
    "Not holding back emotions",
    "Start, as an investigation",
    "Willing to listen",
    "Sign with the times",
    "Store window sign",
    "Store window sign",
    "Not hush-hush",
    "Premiere",
    "Request from a dentist",
    "Daytime store window sign",
    "Uncork, as a bottle",
    "Like some relationships",
    "Like most businesses from 9 to 5",
    "Dentist's directive",
    "Emotionally honest",
    "Store sign",
    "Store sign that might be flipped at 9 a.m.",
    "Word before air, fire or water",
    "Store sign",
    "Available for business",
    "Store sign during business hours",
    "Like most businesses between 9 to 5",
    "Start for seas or seasons",
    "Feature of many a wedding reception",
    "Pro-am tourney, often",
    "Welcome sign",
    "Store sign between 9 a.m. and 6 p.m.",
    "Unwrap",
    "Shop sign that may be flipped",
    "Tournament type",
    "Dentist's instruction",
    "Candid",
    "Store sign",
    "\"___ wide\" (dentist's directive)",
    "Public",
    "Dentist's request",
    "Shop door sign",
    "Amenable (to)",
    "Tournament that takes all comers",
    "Unreserved",
    "With 7-Down, easy things to score goals on",
    "Dentist's request",
    "Unfurl",
    "Taking customers",
    "\"Yes, we're ___\"",
    "___ seas",
    "Ready for business",
    "Not settled",
    "\"___ sesame\"",
    "Sign on a shop's door",
    "Unwrap",
    "___ mike (coffeehouse event)",
    "Word with French or U.S.",
    "Ajar",
    "Welcoming customers",
    "Place the first bet",
    "Still unfilled",
    "Dentist's request",
    "Start the bidding",
    "Nonexclusive",
    "Ready for business",
    "Break the seal on",
    "Up-front",
    "Easy to get into",
    "Like some primaries",
    "Ready to do business",
    "Ready for business",
    "Big tournament",
    "Say \"one club,\" say",
    "Ready for business",
    "Kind of batting stance",
    "Shop window sign",
    "Diner sign",
    "Speaking freely",
    "Unlock",
    "As yet undecided",
    "Available",
    "Welcoming",
    "Candid",
    "Actively trading",
    "9:00-5:30 store sign",
    "Store sign",
    "Crack ___",
    "Dentist's request",
    "Big tournament",
    "Receptive",
    "Forthright",
    "Unlock",
    "Ready for business",
    "Store sign",
    "Unwrap, as a gift",
    "Dentist's order",
    "Sign in a store window",
    "Sign on a store door",
    "Store sign after 9 a.m.",
    "Frank",
    "Ready for business",
    "Big tournament",
    "Dentist's directive",
    "Doing business",
    "Store sign",
    "Out-of-doors",
    "Dentist's direction",
    "Dentist's order",
    "Uncap",
    "Uncover",
    "Big tournament",
    "Word with sea or season",
    "Certain tournament",
    "Ready for business",
    "Dentist's request",
    "Many a tournament",
    "Doing business",
    "Store door sign",
    "Big tournament",
    "Exposed",
    "Court contest",
    "Unwrap",
    "Up for grabs",
    "Dentist's request",
    "With 58-Across, a reception feature",
    "Free of defenders",
    "Tourney type",
    "Unlock",
    "Ready for business",
    "___ to suggestions",
    "Available",
    "Golf or tennis championship",
    "Door sign during store hours",
    "Out-of-doors",
    "Unseal",
    "Up front",
    "With 34-Across, like some women's shoes",
    "Ready to serve",
    "Many a tournament",
    "Receptive",
    "Begin bidding",
    "Crack",
    "Receptive",
    "Frank",
    "More than ajar",
    "Sports event",
    "Up for grabs",
    "Tennis event",
    "Unguarded",
    "Outdoors",
    "Word with season or secret",
    "Dentist's request",
    "Ready for business",
    "Dentist's command",
    "Frank",
    "Tournament for all",
    "Wall-less",
    "Dentist's request",
    "Ready for business",
    "All-comers' tournament",
    "Candid",
    "Dentist's command",
    "U.S. ___",
    "Candid",
    "Unguarded, as a receiver",
    "Unrestricted",
    "\"___ sesame\"",
    "Aboveboard",
    "Unrolled",
    "Unguarded, in football"
  ],
  "bean": [
    "Cannellini, e.g.",
    "Bit of coffee",
    "Noggin",
    "Noodle",
    "Noggin",
    "Navy ___",
    "Think box",
    "Noggin",
    "Think piece?",
    "Hit on the head",
    "Noggin",
    "Hit on the noggin",
    "Conk",
    "Noggin",
    "Think piece?",
    "Noggin",
    "Noodle",
    "Hit on the noggin",
    "Conk",
    "Noodle",
    "Noggin",
    "Noggin",
    "Bop",
    "Noodle",
    "Salad morsel",
    "Vanilla ___",
    "Noggin",
    "Noggin",
    "Think box",
    "Gray matter",
    "One with notions",
    "Noodle",
    "Noggin",
    "Noggin",
    "Noggin",
    "Protein source",
    "See 103-Across",
    "Succotash tidbit",
    "Noodle",
    "Word with ball or bag",
    "Conk",
    "Noggin",
    "Noggin",
    "Lima, e.g.",
    "Noggin",
    "Noodle",
    "Lima, for one",
    "Vanilla, e.g.",
    "Clothing retailer L.L.",
    "Noodle",
    "Noodle"
  ],
  "agog": [
    "Terribly eager",
    "Really eager",
    "Eagerly excited",
    "Frenzied",
    "On tenterhooks, maybe",
    "Wide-eyed in wonder",
    "On pins and needles",
    "Eager",
    "Bug-eyed",
    "Wide-eyed with excitement",
    "Breathless",
    "Wide-eyed",
    "Wide-eyed",
    "Eagerly expectant",
    "Wide-eyed with excitement",
    "Superexcited",
    "Wide-eyed",
    "All atwitter",
    "Bug-eyed",
    "Stoked",
    "Bug-eyed",
    "Like a kid in a candy store",
    "Excited and then some",
    "Spellbound",
    "Twitter-filled",
    "Keen",
    "Absolutely enthralled",
    "Open-mouthed",
    "Awed",
    "On the edge of one's seat",
    "Keenly waiting",
    "Very eager to see something",
    "Wide-eyed and open-mouthed",
    "In a swivet",
    "Like many an out-of-towner in Times Square",
    "Wide-eyed",
    "Bug-eyed",
    "Awestruck",
    "Awestruck",
    "Stoked",
    "Enthralled",
    "Dumbfounded",
    "Awestruck",
    "In a state of 10-Down",
    "Bug-eyed",
    "Spellbound",
    "Intensely interested",
    "Enthused",
    "All worked up",
    "All in a twitter",
    "Stoked, perhaps",
    "Totally wowed",
    "Highly excited",
    "Wide-eyed",
    "Completely wowed",
    "Like kids at a circus, maybe",
    "Bug-eyed",
    "Wide-eyed",
    "Slack-jawed",
    "In awe",
    "Bright-eyed",
    "Wide-eyed",
    "Wide-eyed",
    "Enthralled",
    "Entranced",
    "Awestruck",
    "Obviously eager",
    "All excited",
    "Really eager",
    "Visibly wowed",
    "Wide-eyed",
    "Wide-eyed",
    "Excitedly",
    "Wowed",
    "Like a kid at the circus",
    "Wide-eyed",
    "Bug-eyed",
    "Wide-eyed",
    "Eager",
    "Wide-eyed",
    "Wide-eyed",
    "Bug-eyed",
    "All excited",
    "In a tizzy",
    "Spellbound",
    "In awe",
    "Bug-eyed",
    "Wowed",
    "Wide-eyed",
    "Intensely interested",
    "In a swivet",
    "In a state of excitement",
    "Worked up",
    "All worked up",
    "Awestruck",
    "All excited",
    "Enthralled",
    "Wild-eyed",
    "Enthralled",
    "Highly excited",
    "Full of excitement",
    "Bug-eyed",
    "Awestruck",
    "Enthralled",
    "Eagerly excited",
    "With bated breath",
    "In awe",
    "Popeyed",
    "Enthralled",
    "Awestruck",
    "Awestruck",
    "Slack-jawed",
    "Eyes-a-poppin'",
    "Wide-eyed",
    "More than eager",
    "Excited",
    "Eager",
    "Enthralled",
    "Excited",
    "Het up"
  ],
  "evil": [
    "Heinous",
    "Like some twins and grins",
    "\"Don't be ___\" (Google motto)",
    "Wickedness",
    "Satan's doings",
    "Like Satan",
    "Satanic",
    "Heinous",
    "What mustache-twirling might suggest",
    "Dr. ___, film enemy of Austin Powers",
    "Wicked",
    "Like a \"mwa-ha-ha\" laugh",
    "Dastard's doings",
    "With 53-Across, one whom you might blame for something bad that you did",
    "Wicked",
    "Bad, bad, bad",
    "Satan's doings",
    "\"Man produces ___ as a bee produces honey\": William Golding",
    "Fiendish",
    "Satanic",
    "Malevolence",
    "Like Michael Myers of \"Halloween\"",
    "Satanic",
    "Black-hearted",
    "What superheroes battle",
    "Bad to the bone",
    "\"Don't be ___\" (Google motto)",
    "Voldemort-like",
    "Very dark",
    "Dr. ___, Austin Powers's foe",
    "\"___ is whatever distracts\": Kafka",
    "Maleficent",
    "Wicked",
    "Nefarious",
    "Bad to the bone",
    "Bad to the bone",
    "Like some spirits",
    "Diabolical",
    "Bad to the bone",
    "Nefarious",
    "Satan's doing",
    "Wicked",
    "Like Voldemort",
    "\"Don't be ___\" (Google's motto)",
    "The dark side",
    "84-Down's doings",
    "Satanic",
    "Dastardly",
    "Malevolent",
    "\"Fear is pain rising from the anticipation of ___\": Aristotle",
    "Kind of twin",
    "Diabolical",
    "___ eye",
    "The ___ One (sobriquet for Satan)",
    "Blackhearted",
    "Straight from hell",
    "Good's opposite",
    "Dr. ___ (Mike Myers character)",
    "Demon's doing",
    "Foul",
    "Devilish",
    "\"The ___ of Frankenstein\" (Peter Cushing film)",
    "Demonic",
    "Demon's doing",
    "Like some twins",
    "Satanic",
    "Dr. ___ of \"Austin Powers\" films",
    "Dark side",
    "Nefariousness",
    "Dastardly doings",
    "The dark side",
    "Rotten",
    "It can't be good",
    "Blackhearted",
    "Diabolical",
    "Foul doings",
    "Demonic",
    "Dark doings",
    "Vice",
    "Blackhearted",
    "Worse than bad",
    "Dastardly doings",
    "\"___ falls on him who goes to seek it\": Cervantes",
    "Like Darth Vader",
    "Perfidious",
    "It's not good",
    "Diabolical",
    "Set, Egyptian god of ___",
    "Dark side",
    "Villain's work",
    "Wicked",
    "Fiendish",
    "Devil's work",
    "The dark force",
    "Big Bad Wolf's doing",
    "Malevolence",
    "Wickedness",
    "Devil's doings",
    "Demonic",
    "See 44-Down",
    "Bad to the bone",
    "Axis of ___",
    "Satan's doings",
    "Axis of ___",
    "Worse than knavery",
    "Damnable",
    "Villainous act",
    "Satanic",
    "No good deed",
    "Wickedness",
    "Satan's work",
    "Dark doings",
    "Malevolent",
    "\"Who knows what ___ lurks ...\"",
    "Fiendishness",
    "Sermon topic",
    "Satan's work",
    "Unholiness",
    "Devil's doing",
    "Good opponent?",
    "Full of turpitude",
    "Like Darth Vader",
    "Like Edmund in \"King Lear\"",
    "Ungodliness",
    "Santana's \"___ Ways\"",
    "Like Darth Maul",
    "Unholy",
    "The dark force",
    "Like Darth Vader",
    "Like some twins",
    "The wrong stuff",
    "Like Darth Vader",
    "Malicious",
    "Good's partner",
    "Fiendish",
    "Satanic",
    "Wicked",
    "Like Lex Luthor",
    "Wicked",
    "Satanicalness",
    "What lurks in the hearts of men",
    "Base",
    "Satanic",
    "The dark side",
    "More than devilish",
    "Dark forces",
    "Like Satan",
    "Rank and vile",
    "Wicked",
    "Nefariousness",
    "Stephen King topic",
    "Base",
    "Wicked",
    "Harmful",
    "Kind of empire",
    "\"Hear no___...\"",
    "Satanic",
    "Diabolic",
    "Satan's doing",
    "Sinfulness",
    "Dark side",
    "Good's opponent",
    "Good's opponent",
    "Diabolic",
    "Malevolent",
    "Sinful",
    "Blackhearted",
    "Baudelaire's \"The Flowers of___\"",
    "Infamy",
    "Satan's doing",
    "Kind of eye",
    "The dark force",
    "Demon's doing",
    "Kind of eye",
    "Demonic",
    "Heinous",
    "lniquitous",
    "\"Hear no___ ...\""
  ],
  "brag": [
    "Crow",
    "Crow",
    "Toot one's own horn",
    "Engage in rodomontade",
    "Crow",
    "Toot one's own horn",
    "Toot one's own horn",
    "Crow",
    "Crow",
    "Speak for oneself?",
    "Exhibit some immodesty",
    "What 105-Acrosses do",
    "Toot one's own horn",
    "Egotist's comment",
    "Crow",
    "Boast",
    "Gasconade",
    "Crow",
    "Talk big",
    "Boast",
    "Toot one's horn",
    "Toot one's horn",
    "Engage in fanfaronade",
    "Blow one's horn",
    "Talk big",
    "Talk big",
    "Gasconade",
    "Act the blowhard",
    "Bluster",
    "Crow",
    "Crow",
    "Toot one's own horn",
    "Crow",
    "Talk glowingly about one's children, e.g.",
    "Talk big",
    "Crow",
    "Boast",
    "Talk big",
    "Boast",
    "Overstate one's case",
    "Vaunt",
    "Boast"
  ],
  "sail": [
    "Compete in the America's Cup, say",
    "Fly (through)",
    "Something that's often rigged",
    "Breeze (through)",
    "Spinnaker or spanker",
    "Go by sea",
    "Part of a ship",
    "Go with the wind?",
    "It's rigged",
    "Go yachting",
    "Take a yacht out",
    "Something that's frequently trimmed",
    "Sheet on a mast",
    "Something controlled by rigging",
    "Breeze (along)",
    "Something that may be trimmed or rigged",
    "Set ___ (embark)",
    "Mast's attachment",
    "Go wherever the wind blows?",
    "Travel with Sinbad, say",
    "Junk mover",
    "Jib, e.g.",
    "Windmill arm",
    "Bark part",
    "Take the junk out?",
    "Breeze (through)",
    "Crossjack, e.g.",
    "Go through channels?",
    "Go easily (through)",
    "With 21-Across, ship out?",
    "Yachting need",
    "Mast attachment",
    "Whiz",
    "Fly (through)",
    "See 17-Across",
    "Compete in the America's Cup",
    "Go yachting",
    "Clipper's sheet",
    "Progress smoothly",
    "Move easily",
    "Wind catcher",
    "Breeze (through)",
    "Leave port",
    "Jib or spinnaker",
    "Go out on the ocean",
    "Mast item",
    "Go yachting",
    "Windmill arm",
    "Explore the seven seas",
    "Junk component",
    "Breeze (through)",
    "Jib or spanker",
    "Go to sea",
    "Make a wake",
    "Spinnaker",
    "Attack, with \"into\"",
    "Flying jib, e.g.",
    "Clipper feature",
    "Breeze (through)",
    "Fly (through)",
    "*Wind ... cruise ... canvas",
    "Romp",
    "Go yachting",
    "Crossjack, e.g.",
    "Breeze (through)",
    "Go yachting",
    "Go yachting",
    "Leave dock",
    "Flying jib, e.g.",
    "Spinnaker, e.g.",
    "Spinnaker or jib",
    "Spinnaker, e.g.",
    "Windmill blade",
    "Breeze (along)",
    "Spanker, e.g.",
    "Go yachting",
    "Cruise",
    "Leave shore",
    "Go smoothly"
  ],
  "rink": [
    "An ice place to go?",
    "Place to lace up",
    "Zamboni site",
    "Venue for broomball",
    "Place for curlers",
    "Winter sports locale",
    "Milieu of 49-Across",
    "Winter playground",
    "It can be smoothed over",
    "Ice Follies venue",
    "N.H.L. venue",
    "Setting for 37-Across",
    "Ice Capades venue",
    "Flames shoot in it",
    "Ice hockey venue",
    "Where a power play may occur",
    "Icy area",
    "Winter Olympics venue",
    "Curling place",
    "Where to see a camel",
    "Islanders' place",
    "Place to go for a spin",
    "N.H.L. venue",
    "Ice Follies venue",
    "Milieu for Lemieux",
    "Winter Olympics venue",
    "Skating oval",
    "Where to do figure eights",
    "Rangers locale"
  ],
  "stix": [
    "Pixy ___ (candy brand)",
    "Pixy ___",
    "Pixy ___ (candy)",
    "Pixy ___ (candy)"
  ],
  "rene": [
    "Philosopher Descartes",
    "Actor Auberjonois",
    "Descartes who said \"Cogito, ergo sum\"",
    "Actress Russo",
    "Mathematician Descartes",
    "Actress Russo",
    "Name meaning \"born again\"",
    "French name meaning \"born again\"",
    "Descartes who said \"I think, therefore I am\"",
    "Painter Magritte",
    "Shoe designer Caovilla",
    "___ Caovilla (brand of high-end women's shoes)",
    "French director Clair",
    "___ Caovilla, Italian shoe designer",
    "Philosopher Descartes",
    "Levesque of Quebec",
    "Philosopher Descartes",
    "___ Belbenoit, noted escapee from Devil's Island",
    "Author Francois-___ de Chateaubriand",
    "Painter Magritte",
    "Actress Russo",
    "Former Haitian president Preval",
    "Artist Magritte",
    "French director Clement",
    "___ Preval, two-time president of Haiti",
    "Artist Magritte",
    "Philosopher Descartes",
    "Artist Magritte",
    "Philosopher Descartes",
    "Actor Auberjonois",
    "French film director Clair",
    "___ Preval, twice-elected president of Haiti",
    "Russo of \"Thor: The Dark World\"",
    "French film director Clement",
    "Lacoste of tennis",
    "Russo of \"30-Down\"",
    "Artist Magritte",
    "Descartes who wrote \"Cogito ergo sum\"",
    "President Coty of France",
    "Name meaning \"born again\"",
    "Lacoste of tennis",
    "Tennis great Lacoste",
    "___ Belloq, villain in \"Raiders of the Lost Ark\"",
    "Thinker Descartes",
    "Philosopher Descartes",
    "Surrealist Magritte",
    "Actress Russo",
    "Clement with two Oscar-winning films",
    "Unisex name meaning \"born again\"",
    "Peace Nobelist Cassin",
    "Actress Russo",
    "Peace Nobelist Cassin",
    "Painter Magritte",
    "Conductor Leibowitz",
    "Descartes who wrote \"Cogito, ergo sum\"",
    "___ Laennec, inventor of the stethoscope",
    "___ Gallimard, protagonist of \"M. Butterfly\"",
    "Actress Russo",
    "Russo of \"Thor\"",
    "Novelist Bazin",
    "Mathematician Descartes",
    "Lacoste of tennis",
    "Russo of film",
    "___ Furterer, line of French hair products",
    "Russo of \"Major League\"",
    "Painter Magritte",
    "___ Coty, French president before de Gaulle",
    "Philosopher Descartes",
    "Actor Auberjonois",
    "___ Caovilla, maker of high-end women's shoes",
    "Artist Magritte",
    "___ Fonck, top Allied fighter ace of W.W. I",
    "Haitian president Preval",
    "Russo who co-starred in \"The Thomas Crown Affair\"",
    "___ Dubos, humanist who said \"Think globally, act locally\"",
    "Philosopher Descartes",
    "___ Dubos, Pulitzer winner for \"So Human an Animal\"",
    "Francois-___ de Chateaubriand",
    "Descartes who thought \"I think ...\"",
    "Russo of \"Ransom\"",
    "Actress Russo",
    "Surrealist Magritte",
    "Oscar-winning French film director ___ Clement",
    "Auberjonois of \"The Christmas Star\"",
    "Painter Magritte",
    "French filmmaker Clair",
    "Tennis great Lacoste",
    "Surrealist Magritte",
    "Artist Magritte",
    "Pulitzer-winning science author Dubos",
    "Artist Magritte",
    "French conductor Leibowitz",
    "1968 Peace Nobelist Cassin",
    "Philosopher Descartes",
    "___ Coty, predecessor of Charles de Gaulle",
    "Philosopher Descartes",
    "Mathematician Descartes",
    "French writer Francois ___ de Chateaubriand",
    "Philosopher Descartes",
    "Philosopher Descartes",
    "Physician Laennec, who invented the stethoscope",
    "Philosopher Descartes",
    "See 62-Across",
    "Philosopher Descartes",
    "Artist Magritte",
    "1990's Haitian president Preval",
    "Artist Magritte",
    "Actor Auberjonois",
    "Director Clair",
    "Peace Nobelist Cassin",
    "French film director Clement",
    "Painter Magritte",
    "France's ___ Coty",
    "___ Coty, Charles de Gaulle's predecessor",
    "Frenchman Descartes",
    "1968 Peace Nobelist Cassin",
    "Actor Auberjonois",
    "Philosopher Descartes",
    "French physicist ___ de Reaumur",
    "Artist Magritte",
    "French author ___ Bazin",
    "Painter Magritte",
    "Mathematician Descartes",
    "Peace Nobelist Cassin",
    "Philosopher Descartes",
    "Philosopher Descartes",
    "Actress Russo",
    "French jeweler Lalique",
    "Actress Russo",
    "Artist Magritte",
    "Quebecois Levesque",
    "Title hero of a Chateaubriand novel",
    "Peace Nobelist Cassin",
    "Artist Magritte",
    "Actor Auberjonois",
    "Surrealist Magritte",
    "Actress Russo",
    "Philosopher Descartes",
    "1968 Peace Nobelist Cassin",
    "France's Coty",
    "Actor Auberjonois",
    "Descartes, who said, \"I think, therefore I am\"",
    "Lacoste of the courts",
    "Devil's Island escapee ___ Belbenoit",
    "Surrealist Magritte",
    "Quebec's Levesque",
    "Actress Russo",
    "Russo of \"Get Shorty\"",
    "Actor Auberjonois",
    "Actress Russo",
    "Tennis's Lacoste",
    "Russo of \"Ransom,\" 1996",
    "Artist Magritte",
    "Peace Nobelist Cassin",
    "Tennis legend Lacoste",
    "Russo of \"In the Line of Fire\"",
    "Quebec's Levesque",
    "French jeweler Lalique",
    "Artist Magritte",
    "Russo of \"Tin Cup\"",
    "Levesque of Quebec",
    "Devil's Island escapee ___ Belbenoit",
    "Actress Russo",
    "Russo of \"Outbreak\"",
    "Tennis's Lacoste",
    "Pulitzer-winning microbiologist Dubos",
    "Russo of \"Ransom\"",
    "Russo of \"Outbreak\"",
    "Artist Magritte",
    "Actress Russo",
    "Russo of \"Tin Cup\"",
    "Coty of France",
    "Kevin's co-star in \"Tin Cup\"",
    "Actress Russo",
    "Quebec's Levesque",
    "Film director Clair",
    "Peace Nobelist ___ Cassin",
    "Artist Magritte",
    "Artist Magritte",
    "Mathematician Descartes",
    "Tennis's ___ Lacoste",
    "Clint's \"In the Line of Fire\" co-star",
    "Peace Nobelist Cassin",
    "Chateaubriand hero",
    "Artist Magritte",
    "Art Nouveau jeweler Lalique",
    "French president Coty",
    "Lacoste of tennis",
    "With 65 Across, former French president",
    "French statesman Coty",
    "Artist Magritte",
    "It's put before Descartes",
    "Chateaubriand novel",
    "Russo of \"In the Line of Fire\"",
    "Artist Magritte",
    "Actress Russo",
    "Artist Magritte",
    "Philosopher Descartes",
    "20's tennis champion Lacoste",
    "\"And Then There Were None\" director, with 12 Down",
    "Artist Magritte"
  ],
  "tate": [
    "___ Modern (London gallery)",
    "Big name in British art",
    "Museum on the Thames",
    "Home for a Hogarth or a Constable, with \"the\"",
    "___ Modern",
    "British philanthropist Henry",
    "Art museum with many Constables and Sargents",
    "Home to Rodin's \"The Kiss,\" with \"the\"",
    "___ Modern",
    "Allen ___, onetime U.S. poet laureate",
    "Museum near Westminster Abbey",
    "English poet laureate Nahum",
    "London tourist destination, with \"the\"",
    "Gallery on the Thames",
    "Jazz saxophonist Buddy",
    "___ Modern (London museum)",
    "___ Modern",
    "Where many Sargents hang, with \"the\"",
    "London gallery",
    "Site of many London hangings",
    "Home to many John Constable works, with \"the\"",
    "Sharon of \"Valley of the Dolls\"",
    "Turner Prize institution",
    "London art gallery",
    "___ Gallery",
    "___ Modern (London gallery)",
    "London tourist stop",
    "London art gallery",
    "___ Modern (London gallery)",
    "Thames gallery",
    "British art museum",
    "London art gallery",
    "Gallery on the Thames",
    "London's ___ Gallery",
    "Gallery showing works by Turner, Reynolds and Constable",
    "London gallery",
    "1992 Pulitzer poet James",
    "English poet laureate of 1692-1715",
    "Larry or Louise on \"Bewitched\"",
    "London museum",
    "London gallery",
    "Poet who wrote the novel \"The Fathers\"",
    "Where many Sargents hang, with \"the\"",
    "London gallery",
    "Title boy genius of a 1991 film",
    "Poet laureate of 1700",
    "Sir Henry for whom a gallery is named",
    "\"Ode to the Confederate Dead\" poet",
    "Where to see a Constable or Turner, with \"the\"",
    "\"Ode to the Confederate Dead\" poet Allen ___",
    "Home to some Sargents, with \"the\"",
    "\"Soap\" family name",
    "English poet laureate, 1692-1715",
    "London gallery",
    "New Criticism poet Allen ___",
    "Renowned London gallery",
    "London gallery",
    "Actress Sharon",
    "\"Valley of the Dolls\" actress",
    "Site of some Sargents",
    "London's ___ Gallery",
    "Poet laureate of 1700",
    "Where some Picassos hang",
    "Site of many a Sargent",
    "\"King Lear\" bowdlerizer",
    "British poet laureate Nahum",
    "Noted gallery",
    "British poet laureate Nahum",
    "Turner Wing gallery",
    "London's ___ Gallery",
    "\"The Fathers\" novelist",
    "\"Soap\" family name",
    "Poet laureate of 1692",
    "1991 film \"Little Man ___\"",
    "Poet laureate of 1692",
    "London art gallery",
    "Where to see Turners and Sargents",
    "Site of some Millais works, with \"the\"",
    "London gallery",
    "London gallery",
    "Art philanthropist Sir Henry",
    "English poet laureate Nahum ___",
    "Site of some Sargent paintings",
    "London gallery",
    "London's___Gallery",
    "London gallery",
    "?ôSoap?ö family name",
    "London gallery",
    "Poet laureate Nahum",
    "\"Soap\" family name",
    "London's ___ Gallery"
  ],
  "pets": [
    "Four-footed friends",
    "Animals around the house",
    "Family members that get talked down to?",
    "Strokes",
    "Cats and dogs",
    "Beloved members of the family",
    "Rewards for good behavior, maybe",
    "Vets' charges",
    "Favorites",
    "Black widow spiders make dangerous ones",
    "They might get collared",
    "No-nos in many apartment buildings",
    "Darlings",
    "Garfield and Odie, for two",
    "Adopt-a-thon adoptees",
    "Subjects of an apartment restriction",
    "Dogs, cats and gerbils",
    "Shelter rescues, e.g.",
    "Parrots and ferrets",
    "Favored ones",
    "They rarely have surnames",
    "Strokes ... or ones getting stroked",
    "Tamed animals",
    "Adopt-a-thon adoptees",
    "Cats and gerbils, e.g.",
    "Some allergy sources",
    "Animals with collars, often",
    "Dogs, cats and gerbils",
    "No ___ (store sign)",
    "Subject of a hotel policy",
    "Penthouse pinups",
    "Strokes",
    "Many adoptees",
    "Guinea pigs, maybe",
    "No ___ Allowed (motel sign)",
    "Adoptees from shelters",
    "Some motel prohibitions",
    "Strokes ... or ones stroked",
    "No ___ allowed (sign)",
    "Four-footed friends",
    "Consumers of Purina and Iams food",
    "Strokes",
    "Subject heading in a hotel brochure",
    "Some shelter dwellers",
    "Dogs, but rarely hogs",
    "Vets' concerns",
    "Beloved animals",
    "Fondlings",
    "Bad moods",
    "Guinea pigs, maybe",
    "No-nos in many hotels",
    "Furry companions",
    "Darlings",
    "They may be collared",
    "Dogs and cats, e.g.",
    "Teachers' favorites",
    "\"No ___ allowed\"",
    "Guinea pigs, maybe",
    "Apple polishers",
    "Socks and Millie, e.g.",
    "Dearies",
    "Strokes",
    "Furry companions",
    "Home bodies?",
    "Darlings",
    "Fido and Fluffy"
  ],
  "exed": [
    "Crossed (out)",
    "Struck out",
    "Marked, as a ballot",
    "Deleted",
    "Struck (out)",
    "Struck out",
    "Marked (out)",
    "Took (out)",
    "Marked, as a ballot",
    "Struck out",
    "Struck out",
    "Deleted",
    "Stricken",
    "Marked, in a way",
    "Crossed (out)",
    "Deleted, with \"out\"",
    "Marked, in a way",
    "Deleted",
    "Struck out",
    "Crossed (out)"
  ],
  "sore": [
    "Achy",
    "Tender spot",
    "Mad (at)",
    "Peeved",
    "Ticked off",
    "Cross",
    "Ticked off",
    "Achy",
    "Disgruntled",
    "Aching",
    "Like a bad loser",
    "Painful to the touch",
    "Cross",
    "Achy",
    "Achy",
    "Peeved",
    "Word with spot or sport",
    "Put out",
    "Teed off",
    "Needing Bengay, say",
    "Miffed",
    "Peeved",
    "In need of an ice bath, say",
    "Ticked",
    "Ticked",
    "Peeved",
    "Like some losers",
    "Put out",
    "Bothered",
    "Feeling the effects of a workout",
    "Nursing a grudge, say",
    "Put out",
    "Cross",
    "Miffed",
    "Caker, for example",
    "Like poor losers",
    "Peeved",
    "Like a Monday morning quarterback?",
    "Teed off",
    "Ticked off",
    "Not taking a loss well, say",
    "Achy",
    "Teed off",
    "Upset",
    "Not losing well",
    "Irked",
    "See 9-Down",
    "Peeved",
    "Cross",
    "Feeling stood-up, say",
    "Peeved",
    "Peeved",
    "Smarting",
    "Like some eyes",
    "Ruing the workout, maybe",
    "Achy",
    "In a snit",
    "Like bad losers",
    "Vexed",
    "*Teed off",
    "Put out",
    "Sensitive",
    "Peeved",
    "Irritated",
    "Hurting",
    "Put out",
    "Teed off",
    "Holding a grudge",
    "Peeved",
    "Ticked off",
    "Put out",
    "Tender",
    "Ticked",
    "Achy",
    "Ticked off",
    "Teed off",
    "Achy",
    "Like some losers",
    "Burned up",
    "Annoyed",
    "Huffy",
    "Like some losers",
    "Put out",
    "Needing some kneading?",
    "Resentful",
    "Disgruntled",
    "Ticked off",
    "Miffed",
    "Like poor losers",
    "Stung",
    "Your throat might be this from yelling",
    "Ticked off",
    "Ticked off",
    "Teed off",
    "Word with bed or saddle",
    "Upset",
    "Kind of loser",
    "Ticked off",
    "Achy",
    "Welt",
    "Painful",
    "Ticked off",
    "None too happy",
    "Achy",
    "Upset",
    "Recovering from a charley horse",
    "Mad (at)",
    "Achy",
    "Peeved",
    "Holding a grudge",
    "Peeved",
    "Achy",
    "Hurting",
    "Mad as a hornet",
    "Hurting",
    "Resentful",
    "Needing liniment",
    "Annoyed",
    "Kind of loser",
    "Miffed",
    "Resentful",
    "Steamed",
    "Like some losers",
    "Irked",
    "Aching",
    "Irked",
    "Teed off",
    "Seeing red",
    "Achy",
    "Miffed",
    "Ticked off",
    "Peeved",
    "Miffed",
    "Achy",
    "Kind of loser",
    "Achy",
    "Peeved"
  ],
  "fief": [
    "Feudal plot",
    "Vassal's plot of land",
    "Where the lord's work is done?",
    "Feudal estate",
    "Feudal domain",
    "Lord's domain",
    "Sphere of control",
    "Vassal's reward",
    "Overlord's domain",
    "Person's sphere of operation",
    "Feudal estate",
    "Landed estate",
    "Feudal estate",
    "Lord's domain",
    "Workplace for a 23-Down",
    "Feudal holding",
    "Feudal estate",
    "Feudal estate",
    "Feudal estate",
    "Feudal estate",
    "Feudal land",
    "Feudal land",
    "Feudal estate",
    "Feudal estate",
    "Where 1-Across slaved",
    "Feudal estate",
    "Feudal estate",
    "Feudal estate",
    "Where esnes slaved"
  ],
  "tarp": [
    "Save it for a rainy day",
    "Camper's cover",
    "Infield shield",
    "Temporary rain cover",
    "Shield from the elements",
    "It may cover all the bases",
    "Cover during a rain delay",
    "Something saved for a rainy day",
    "Rainproof cover",
    "Roofer's cover",
    "Diamond cover",
    "Covering pulled out during a rain delay",
    "Pool protector",
    "Rain delay sight",
    "Field cover during a rain delay",
    "Response to the '08 financial crisis",
    "Diamond cover",
    "Protective covering",
    "Campsite sight",
    "Camping supply",
    "Base protector",
    "Bank bailout acronym",
    "Cover-up during a shower",
    "Rain check?",
    "Rain cover",
    "Tent floor, maybe",
    "Ground cover",
    "Outdoor cover",
    "Infield cover",
    "Rain delay roll-out",
    "Field protector",
    "Diamond protector",
    "Stadium rollout",
    "One covering the infield",
    "Field protector",
    "Protective covering",
    "Ballpark rollout",
    "Ball field covering",
    "Canvas cover",
    "Shield from the elements",
    "Pool pull-over",
    "Ballfield cover",
    "Canvas cover",
    "Infield cover",
    "Diamond covering",
    "It may cover all the bases",
    "Waterproof cover",
    "Diamond cover",
    "Rain check?",
    "It may cover a diamond",
    "Field cover",
    "Camper's purchase",
    "Canvas cover",
    "Cover for a diamond",
    "Canvas",
    "Court protection",
    "Canvas",
    "Protective covering",
    "Rain check?",
    "Football field protector",
    "Canvas cover",
    "Diamond cover",
    "Rain check",
    "Weather protector",
    "Canvas",
    "Diamond protector",
    "Game saver, at times"
  ],
  "rien": [
    "\"De ___\" (\"You're welcome\": Fr.)",
    "\"De ___\" (response to \"Merci\")",
    "Nothing, in Nantes",
    "Edith Piaf's \"Non, Je Ne Regrette ___\"",
    "Nothing, in Nantes",
    "Nothing: Fr.",
    "\"De ___\" (\"You're welcome\": Fr.)",
    "Love at the French Open, essentially",
    "Nanette's \"nothing\"",
    "It means nothing to the French",
    "Zip across Corsica?",
    "Nothing: Fr.",
    "Nothing, in Paris",
    "Nothing, in Nantes",
    "Nothing, in Nantes",
    "It means nothing to Sarkozy",
    "Zip around France?",
    "Tout's opposite",
    "It means nothing to the French",
    "Tout's antithesis",
    "Tout's opposite",
    "It means nothing to the French",
    "Nothing abroad",
    "Nada, across the Pyrenees",
    "Nothing, in Nantes",
    "\"___ ne va plus\" (roulette call)",
    "Nothing, in Nice",
    "It means nothing to Nicolette",
    "It means nothing to Nanette",
    "Opposite of tout",
    "Nothing, in Nantes",
    "Nothing: Fr.",
    "Nothing: Fr."
  ],
  "jars": [
    "Cannery row?",
    "Places for tips",
    "Jolts",
    "Cookie containers",
    "Cannery row?",
    "Containers in a pantry",
    "Shocks",
    "Throws off balance",
    "Amphorae, e.g.",
    "Pantry lineup",
    "Larder lineup",
    "Shocks",
    "Cookie holders",
    "Pickle containers",
    "Shakes up",
    "Jostles",
    "Preservers of preserves",
    "Pantry stock",
    "Pantry lineup",
    "Some are jam-packed",
    "Cannery row?",
    "Potpourri containers",
    "Bumps",
    "Rattles",
    "Shakes up",
    "Shakes up",
    "Honey holders",
    "Places for pickles",
    "See 30-Down",
    "Amphorae",
    "They preserve preserves",
    "Jolts",
    "Shakes up"
  ],
  "wack": ["Bad, slangily"],
  "alia": [
    "Inter ___",
    "Et ___",
    "Actress Shawkat of \"Arrested Development\"",
    "Inter ___ (among others)",
    "Actress ___ Shawkat of \"Arrested Development\"",
    "Et ___ (and others)",
    "Et ___",
    "Inter ___",
    "Et ___ (and others)",
    "Inter ___",
    "Octavia's \"others\"",
    "Inter ___",
    "Et ___ (and others)",
    "Others, to Octavia",
    "Jordan's Queen ___ International Airport",
    "Inter ___",
    "Ovid's others",
    "Amman's Queen ___ International Airport",
    "Inter ___",
    "Latin \"others\"",
    "Inter ___",
    "Others, to Ovid",
    "Et ___ (and others)",
    "Et ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Jordan's Queen ___ International Airport",
    "Et ___",
    "Inter ___",
    "Inter ___ (among other things)",
    "Et follower",
    "Inter ___",
    "Suffix with Saturn",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Follower of inter or et",
    "Inter ___",
    "Others, in Latin",
    "Inter ___",
    "Inter ___",
    "Et ___ (and others)",
    "Amman's Queen ___ International Airport",
    "Inter ___",
    "Et ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Others, to Ovid",
    "Inter ___",
    "Suffix with margin",
    "Inter ___",
    "Others, to Octavian",
    "Et ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Jordan's Queen ___ International Airport",
    "Albania's last Communist president",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Others, to Ovid",
    "Suffix with Saturn",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    { "formula": "+Inter follower", "result": { "error": "#NAME?" } },
    "Et ___ (and others)",
    "Inter ___",
    "Last leader of Communist Albania",
    "Inter___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "Inter ___",
    "By another way: Lat.",
    "Inter ___",
    "Saturn's end?"
  ],
  "soba": [
    "Japanese noodle",
    "Noodles sometimes served with tsuyu sauce",
    "Noodles often eaten cold in the summer",
    "Gluten-free noodle variety",
    "Japanese noodles",
    "Buckwheat noodles",
    "Japanese noodle",
    "Japanese bowlful",
    "Noodle house noodles",
    "Japanese noodle",
    "Japanese noodles",
    "Noodles in Japanese cookery",
    "Japanese bowlful",
    "Thin Japanese noodle",
    "Japanese noodle",
    "Japanese noodle"
  ],
  "edgy": [
    "Really out there, as humor",
    "Uptight",
    "Pushing the envelope",
    "Pushing the bounds of propriety",
    "Out there",
    "Avant-garde",
    "Avant-garde",
    "Avant-garde",
    "Uneasy",
    "Eliciting nervous laughter, say",
    "Tense",
    "Like fringe festival fare",
    "Risque, maybe",
    "Pushing conventional limits",
    "Avant-garde",
    "Pushing the envelope",
    "Envelope-pushing",
    "Risque, say",
    "Pushing the boundaries of propriety",
    "Avant-garde",
    "Exploring the bounds of propriety, as humor",
    "A little \"out there,\" as humor",
    "Apprehensive",
    "Apt to snap",
    "Tense",
    "Jumpy",
    "Pushing the envelope",
    "Envelope-pushing",
    "Unconventionally provocative",
    "Daring, in a way",
    "All nerves",
    "Nervous",
    "On tenterhooks",
    "Pushing the envelope",
    "Avant-garde",
    "Nervous",
    "Nervously irritable",
    "Like some modern filmmaking",
    "Nervously irritable",
    "Fidgeting",
    "On pins and needles",
    "Jittery",
    "Uptight",
    "Nervous",
    "Like hip ads",
    "Tense",
    "On pins and needles",
    "Irritable",
    "Nervously irritable",
    "Nervous",
    "On pins and needles",
    "Tense",
    "Nervous",
    "Irascible",
    "Wired",
    "Irritable",
    "Stressed out",
    "Nervously irritable",
    "On pins and needles",
    "Tense",
    "On pins and needles",
    "Snappish",
    "Wired",
    "Nervous"
  ],
  "thur": [
    "What follows hump day: Abbr.",
    "Wed. follower",
    "Must See TV night: Abbr.",
    "Day of the week of Jul. 4, 1776",
    "Ascension Day, e.g.: Abbr.",
    "Wed. follower",
    "Thanksgiving day: Abbr.",
    "Calendar abbr.",
    "There's a holy one every yr.",
    "Calendar abbr."
  ],
  "zeus": [
    "Top Olympian",
    "Greek counterpart of Jupiter",
    "For whom \"it is not possible either to trick or escape the mind,\" per Hesiod",
    "Jupiter, to the Greeks",
    "God holding a thunderbolt",
    "Jupiter's counterpart",
    "Chief Olympian",
    "Thunderbolt thrower",
    "Mount Olympus chief",
    "Swan's alter ego, in myth",
    "Olympian sovereign",
    "Mt. Olympus figure",
    "Son of Cronus",
    "God who hurls lightning bolts",
    "Olympian ruler",
    "Olympian leader",
    "Pantheon ruler",
    "Brother of Poseidon",
    "Hera's husband"
  ],
  "isay": [
    "\"My word!\"",
    "\"My word!\"",
    "Words to an \"old chap\"",
    "\"What ___ goes!\" (parent's pronouncement)",
    "\"My goodness!\"",
    "\"My word!\"",
    "\"Well, well, well!\"",
    "\"Well, well\"",
    "Opiner's opener",
    "\"My word!\"",
    "\"Good heavens, old chap!\"",
    "\"Here's my two cents ...\"",
    "\"___, old chap!\"",
    "\"By Jove!\"",
    "Words before \"old chap\"",
    "Exclamation of surprise, in Britain",
    "Brit's exclamation",
    "\"Well, blimey!\"",
    "Words before \"old chap\"",
    "\"Well, well, old chap\"",
    "\"Well!\"",
    "British interjection",
    "\"By Jove!\"",
    "\"Goodness!\"",
    "Cry of surprise",
    "\"Well, well, well,\" to a Brit",
    "\"Goodness me!\"",
    "Surprised expression",
    "\"What can ___?\"",
    "\"This is quite a surprise!\"",
    "\"___, old chap!\"",
    "\"Oho, dear chap!\"",
    "British interjection",
    "Exeter exclamation",
    "\"My word!\"",
    "\"___, old chap!\"",
    "Words before \"tomato\" or \"potato\"",
    "\"By Jove!\"",
    "\"Well, old chap!\"",
    "\"Well!\"",
    "\"___, old chap!\"",
    "\"My, my, old chap!\"",
    "\"Look here, old chap!\"",
    "Brit's exclamation",
    "\"Well, well,\" to Wellington",
    "English exclamation",
    "\"?à ___ tomato\"",
    "\"___, old chap\"",
    "\"It is my suggestion ?à\"",
    "Watson's \"___ Holmes ?à\"",
    "Words to an old chap",
    "\"Because ___ so!\"",
    "Bloke's \"Well, well!\"",
    "Words to an \"old chap\"",
    "Brit's exclamation",
    "Words to an old chap?",
    "Brit's cry",
    "\"Well, well!\"",
    "\"Oho, dear chap!\"",
    "Brit's exclamation",
    "\"My word!\"",
    "Brit's exclamation",
    "\"Well, well!\"",
    "\"Well, well!\"",
    "Mary Higgins Clark's \"Before ___ Good-Bye\"",
    "\"___, old chap\"",
    "Ray Charles's \"What'd ___\"",
    "\"My word!\"",
    "Words to an \"old chap\"",
    "\"Look here, old chap!\"",
    "Brit's interjection",
    "\"Indeed!\" overseas",
    "\"___, old chap!\"",
    "\"Do as ___,...\"",
    "British exclamation",
    "Exmoor exclamation",
    "Essex exclamation",
    "\"___ old chap...\"",
    "British exclamation",
    "Brit's phrase",
    "Britisher's exclamation"
  ],
  "koko": [
    "Lord High Executioner in \"The Mikado\"",
    "Gorilla who was famously taught sign language",
    "Baritone in \"The Mikado\"",
    "Executioner in \"The Mikado\"",
    "Gorilla pioneering in sign language",
    "\"The Mikado\" baritone",
    "Gorilla skilled in sign language",
    "Gorilla famously taught to use sign language",
    "Famous language-signing gorilla",
    "Clown of renown",
    "\"The Mikado\" character",
    "The Mikado's Lord High Executioner",
    "\"Mikado\" executioner"
  ],
  "racy": [
    "Risqué",
    "Off-color",
    "R-rated, say",
    "Salacious",
    "Blue",
    "Off-color",
    "Ribald",
    "Suggestive",
    "Not for prudes",
    "Risque",
    "Salacious",
    "A little blue",
    "Somewhat blue",
    "Risque",
    "Blue",
    "Bordering on pornographic",
    "Suggestive",
    "A bit dirty",
    "A little dirty",
    "Off-color",
    "Bawdy",
    "A bit blue",
    "R-rated, maybe",
    "Like blue humor",
    "Risque",
    "Risque",
    "Like many Mae West quotes",
    "Slightly off-color",
    "Suggestive",
    "Slightly improper",
    "Suggestive",
    "Risque",
    "Indelicate",
    "Like \"Lady Chatterley's Lover\"",
    "Piquant",
    "Ribald",
    "Hot",
    "Piquant",
    "Suggestive"
  ],
  "test": [
    "Class struggle?",
    "Performance check",
    "Drill, for instance",
    "What may make the grade",
    "Try out",
    "Rorschach, for one",
    "Lab work",
    "Class struggle?",
    "Word repeated before \"1, 2, 3\"",
    "[Is this thing on?]",
    "Experiment",
    "Class act?",
    "Beta follower",
    "Obstacle to overcome",
    "Trial",
    "Try",
    "Challenge",
    "Cause of class struggle?",
    "See 37-Down",
    "Certain cricket match",
    "Try",
    "Beta ___",
    "Try out",
    "Word repeated while tapping a microphone",
    "Class struggle?",
    "Try",
    "Word repeated during a mic check",
    "Reason to pull an all-nighter",
    "Trial balloon",
    "Try out",
    "Word with road or blood",
    "See 19-Down",
    "Trial run",
    "Try out",
    "Try",
    "Big quiz",
    "A crucible is a hard one",
    "Follower of alpha and beta",
    "Final, e.g.",
    "Road ___",
    "Challenge",
    "Word repeated by a roadie into a microphone",
    "International cricket match",
    "Try",
    "Trial",
    "Course obstacle?",
    "It's got problems",
    "Pageant, e.g.",
    "Try",
    "Midterm, for one",
    "Trial balloon",
    "Class struggle?",
    "Assay, say",
    "Lab procedure",
    "Sample",
    "Lab job",
    "Examination",
    "Check, as brakes",
    "License prerequisite, often",
    "Final, say",
    "Give a whirl",
    "Try",
    "Dry run",
    "Focus of a class action?",
    "Trial run",
    "Eye ___",
    "Cricket match",
    "Try to prove",
    "Dry run",
    "See 1-Down",
    "Try out",
    "Clinical study",
    "Try",
    "Battery part",
    "Try out",
    "An essay may be on one",
    "The \"T\" of S.A.T.",
    "See 36-Down",
    "Try",
    "Putting a toe in the water, say",
    "Try out",
    "Battery component",
    "Quiz",
    "Exam",
    "Midterm, e.g.",
    "Put out a feeler, maybe",
    "The \"T\" of S.A.T.",
    "Feeler",
    "Try",
    "Makeup, e.g.",
    "Try",
    "Try out",
    "Try out",
    "Check",
    "Dry run",
    "Cause of a class struggle?",
    "Probe",
    "Word after high or road",
    "Sampling",
    "Makeup, e.g.",
    "Dry run",
    "Step in getting a license",
    "Kind of case",
    "Reason to cram",
    "Dry run",
    "Audition",
    "New driver's hurdle",
    "Dry run",
    "See 32-Down",
    "Battery component",
    "Midterm, say",
    "School administration",
    "Audition",
    "Consumer Reports report",
    "With 24-Down, self-diagnosis aids",
    "Entrance requirement, maybe",
    "Check",
    "Probation",
    "Litmus is one kind",
    "Kind of pilot",
    "Checkup",
    "Dip one's toe in, say",
    "Try",
    "Dry run",
    "Cross-check",
    "Midterm, say",
    "Toe in the water",
    "Trial balloon",
    "Experiment",
    "Midterm, e.g.",
    "Midterm, e.g.",
    "Feeler",
    "Driver's license prerequisite",
    "Exam",
    "Boards, e.g.",
    "Feeler",
    "Experiment",
    "___ match (cricket event)",
    "Examine",
    "Experiment",
    "Ultrasound is one",
    "Word repeated before \"1, 2, 3\"",
    "Screen ___",
    "Midterm, for one",
    "Probe"
  ],
  "jagr": [
    "Hockey great Jaromir",
    "Hockey legend Jaromir",
    "Jaromir ___, five-time N.H.L. scoring leader",
    "Hockey's Jaromir ___",
    "Five-time N.H.L. scoring leader of the 1990s-'00s",
    "Hockey great Jaromir",
    "N.H.L. great from the Czech Republic"
  ],
  "ribs": [
    "BBQ offering",
    "Some barbecue fare",
    "Classic barbecue fare",
    "Specialty of Kansas City cooks",
    "Barbecue serving",
    "Barbecue dish",
    "Entree that may be slathered in barbecue sauce",
    "Connections to the sternum",
    "Barbecue order",
    "Cookout fare",
    "Barbecue fare",
    "Razzes",
    "Saucy fare",
    "Barbecue fare",
    "See 39-Across",
    "Barbecue order",
    "Rack parts",
    "Barbecue dish",
    "Barbecue order",
    "Cookout fare",
    "Barbecue fare",
    "Barbecue fare",
    "Hearty entree",
    "Steakhouse selection",
    "Barbecue entree",
    "Barbecue fare",
    "Barbecued entree",
    "\"Spare\" items",
    "Barbecue entree",
    "\"Spare\" items at a barbecue",
    "Barbecued dish slathered with sauce",
    "Kids",
    "Umbrella parts",
    "Cookout fare",
    "Needles",
    "Cookout fare",
    "Barbecue entree",
    "Some are spare",
    "Cookout fare",
    "Spare parts?",
    "Teases"
  ],
  "wept": [
    "Was lachrymose",
    "Shed tears",
    "Openly mourned",
    "Was lachrymose",
    "Shed tears",
    "Broke down, say",
    "Experienced lacrimation",
    "Cried",
    "Had pity (for)",
    "Boohooed",
    "Imitated Niobe",
    "Boohooed",
    "\"Jesus ___\" (shortest Bible verse)",
    "Openly grieved",
    "Had a bawl",
    "Turned on the waterworks",
    "Cried",
    "Shed tears",
    "Cried",
    "Openly mourned",
    "Cried",
    "Cried",
    "Mewled",
    "Lamented",
    "\"Jesus ___\" (shortest verse in the Bible)",
    "Wasn't happy",
    "Turned on the waterworks",
    "Whimpered",
    "Blubbered",
    "Sobbed",
    "Grieved"
  ],
  "ezra": [
    "Cornell who founded Cornell",
    "English singer/songwriter George ___",
    "Columnist Klein",
    "Book after II Chronicles",
    "Book before Nehemiah",
    "Book after II Chronicles",
    "Fitch of Abercrombie & Fitch",
    "Old Testament book next alphabetically after Ezekiel",
    "Pound who wrote \"Literature is news that stays news\"",
    "Poet Pound",
    "Book after II Chronicles",
    "It begins \"Now in the first year of Cyrus king of Persia ...\"",
    "Book after Chronicles",
    "Book after II Chronicles",
    "Cornell of Cornell University",
    "Klein of Vox.com",
    "Political writer/blogger Klein",
    "___ Taft Benson (1980s-'90s Mormon leader)",
    "Poet Pound",
    "Political columnist Klein",
    "Old Testament prophet",
    "Poet Pound",
    "Biblical prophet",
    "Alternative rock's Better Than ___",
    "Pound of poetry",
    "Cornell of Cornell University",
    "Biblical prophet",
    "Biblical figure whose name means \"help\"",
    "Biblical book once combined with Nehemiah",
    "Biblical figure believed to be buried near Basra",
    "Political blogger Klein",
    "Cornell who founded Western Union",
    "Poet Pound",
    "Book before Nehemiah",
    "Book before Nehemiah",
    "Pound of poetry",
    "Cornell of Cornell University",
    "Cornell of Cornell University",
    "Rock's Better Than ___",
    "Book that begins \"Now in the first year of Cyrus king of Persia ...\"",
    "Book before Nehemiah",
    "Rock's Better Than ___",
    "Book after Chronicles",
    "Poet Pound",
    "Old Testament book",
    "Author of the Books of Chronicles, by tradition",
    "Cornell or Pound",
    "Book before Nehemiah",
    "Book concerned with the end of the Babylonian captivity",
    "Rock's Better Than ___",
    "Cornell of Cornell University",
    "Poisoned husband in \"Mourning Becomes Electra\"",
    "Cornell of university fame",
    "Book after II Chronicles",
    "Fitch of Abercrombie & Fitch",
    "Writer Pound",
    "Biblical prophet",
    "Poet Pound",
    "Old Testament book",
    "Poet Pound",
    "Poet Pound",
    "Fitch who co-founded Abercrombie & Fitch",
    "Old Testament book",
    "Pound of verse",
    "Rock music's Better Than ___",
    "Book after Chronicles",
    "Book before Nehemiah",
    "Early Jewish reformer",
    "Pound of verse",
    "Book after II Chronicles",
    "Prophet who led Jews back to Jerusalem",
    "Pound with a metric system?",
    "Book that details the reign of Cyrus",
    "Book before Nehemiah",
    "College endower Cornell",
    "Poet Pound",
    "Book after II Chronicles",
    "Book before Nehemiah",
    "Book that tells of the reign of Cyrus the Great",
    "Book before Nehemiah",
    "Poet Pound",
    "Cornell of Cornell University",
    "Book before Nehemiah",
    "Book that tells of the reign of Cyrus",
    "Pound of literature",
    "Pound of verse",
    "Book before Nehemiah",
    "Book before Nehemiah",
    "Poet Pound",
    "Rock band Better Than ___",
    "University founder Cornell",
    "Old Testament book",
    "Book before Nehemiah",
    "Pound of literature",
    "Pound of verse",
    "Book after II Chronicles",
    "Poet Pound",
    "University founder Cornell",
    "Browning's \"Rabbi Ben ___\"",
    "Pound of poetry",
    "Book that tells of the rebuilding of the Temple of Jerusalem",
    "Book of Artaxerxes",
    "Cornell of Cornell University",
    "It begins \"In the first year of Cyrus...\"",
    "Book before Nehemiah",
    "Pound of literature",
    "Book after II Chronicles",
    "College founder Cornell",
    "Poet Pound",
    "Pound of poetry",
    "Jerusalem visitor from the Persian court",
    "Book after II Chronicles",
    "It begins \"In the first year of Cyrus king of Persia...\"",
    "Poet Pound",
    "Biblical scribe",
    "Book before Nehemiah",
    "Poet Pound",
    "Pound of poetry",
    "Book after II Chronicles",
    "University founder Cornell",
    "Poet Pound",
    "Nehemiah preceder",
    "Metrical Pound",
    "Former Mormon chief ___Taft Benson"
  ],
  "dead": [
    "Kaput",
    "Unexciting, as a bar scene",
    "Like a sparsely attended party",
    "Like some batteries and parties",
    "With 71-Across, traveling music fan of old",
    "Out of juice",
    "Juiceless, as a battery",
    "Out of juice",
    "Having a battery that's out of juice",
    "Playing ___",
    "Inoperative",
    "At 0% battery",
    "Out of juice",
    "No fun, as a party",
    "Opposite of hopping, as a party",
    "Out of juice, as a battery",
    "No longer working",
    "Like a dull party",
    "No longer under consideration",
    "No longer working",
    "Out of juice",
    "Underattended, say",
    "Like some batteries",
    "Out of electricity",
    "Utterly exhausted",
    "Utterly exhausted",
    "In need of recharging",
    "Totally not happening",
    "No longer carrying current",
    "No more",
    "Gone to glory",
    "Very, very tired",
    "Absolutely",
    "___ as a doornail",
    "Defunct",
    "Like some batteries",
    "Conked out for good",
    "No longer under consideration",
    "Obsolete",
    "Lifeless",
    "Kaput",
    "Word with heat or meat",
    "Obsolete",
    "No longer under consideration",
    "Practically devoid of customers",
    "Done for",
    "Without juice, as an electrical wire",
    "Wanted-poster option",
    "Bo-o-o-o-ring",
    "Without bounce",
    "Extinguished",
    "Not working",
    "Like Latin, today",
    "Not working, as a battery",
    "Lacking oomph",
    "Like some phones",
    "Conked out",
    "Pooped",
    "Out of juice",
    "Exhausted",
    "Conked out, as a battery",
    "___ ringer",
    "Unusable",
    "Not working, as a battery"
  ],
  "raga": [
    "Indian music composition",
    "Backbone of Indian classical music",
    "Indian musical pattern",
    "Sitar selection",
    "Bit of Eastern music",
    "Music played on a sitar",
    "Accompaniment at an Indian restaurant",
    "Bit of a Bollywood soundtrack",
    "Music often heard in Bollywood films",
    "Some music in Mumbai",
    "Bit of Bollywood music",
    "Ravi Shankar's music",
    "Sitar piece",
    "Music of Mumbai",
    "1971 documentary about Ravi Shankar",
    "Shankar piece",
    "Musical anagram of AGRA, fittingly",
    "Indian music",
    "___ rock (some George Harrison music)",
    "Music of Mumbai",
    "Tamboura player's music",
    "Bit of Indian music",
    "Eastern melody",
    "Indian melody",
    "Shankar piece",
    "Mumbai music",
    "Sitar music",
    "Ravi Shankar performance",
    "Music in Mysore",
    "Ravi Shankar played it at Woodstock",
    "Music that influenced the Beatles' \"Norwegian Wood\"",
    "Indians play it",
    "Music played by Ravi Shankar at Woodstock",
    "Indian music",
    "1971 documentary about Ravi Shankar",
    "Eastern music",
    "Some Hindu music",
    "Music of India",
    "Indian music",
    "Indian melodic pattern",
    "Musical form whose name means \"color\"",
    "Certain sitar piece",
    "Indian improvisation",
    "Hindu music form",
    "Sitar selection",
    "Madras music",
    "Eastern music",
    "Eastern music style",
    "Indian music",
    "Genre of 49-Across",
    "Music of India",
    "Music whose name is Sanskrit for \"color\"",
    "Hindu melody pattern",
    "Sitar music",
    "Hindu music",
    "Indian music",
    "Indian music style",
    "Indian music",
    "Sitar music",
    "Eastern music",
    "Indian music",
    "Music whose name means \"color\"",
    "Tabla player's music",
    "Sitarist's rendition",
    "Madras music",
    "Ethnic music",
    "Ethnic music",
    "Shankar piece"
  ],
  "skyy": [
    "Big vodka brand",
    "Blue-bottled vodka brand",
    "Vodka in a blue bottle",
    "Vodka in a blue bottle",
    "Grey Goose competitor",
    "Grey Goose competitor",
    "Vodka brand",
    "Absolut competitor",
    "Vodka brand"
  ],
  "rose": [
    "Flower for a valentine",
    "Desire for a contestant on \"The Bachelor\"",
    "Valentine's Day flower",
    "Stood up",
    "Thorny subject",
    "Type of wine with an accent",
    "Symbol of Aphrodite",
    "Symbol in the logo of the Democratic Socialists of America",
    "Prize on \"The Bachelor\"",
    "Valentine's Day flower",
    "Jack's love in \"Titanic\"",
    "Tudor symbol",
    "Wine variety",
    "Flower girl?",
    "By any other name it would smell as sweet, per Juliet",
    "Part of a Derby garland",
    "Got off the ground?",
    "Stood",
    "Jack's love in \"Titanic\"",
    "Prop on \"The Bachelor\"",
    "Valentine's Day flower",
    "With 32-Down, followed a career ladder",
    "It can be prickly",
    "Some summer wine",
    "Valentine's Day flower",
    "What the name \"Rhoda\" means",
    "Climbed",
    "Valentine's Day flower",
    "See 5-Across",
    "___ of Sharon",
    "Symbol of Lutheranism",
    "Symbol of Aphrodite",
    "Pinkish red",
    "Headed heavenward",
    "Symbol of the Virgin Mary",
    "Levitated",
    "Pink shade",
    "Took off",
    "Beau's buy",
    "Kennedy matriarch",
    "One with big hips, maybe",
    "Got promoted",
    "Mateus ___",
    "Jack's love in \"Titanic\"",
    "Part of the American Greetings logo",
    "Ascended",
    "Pinkish",
    "Suitor's surprise",
    "Either of two opposing war emblems",
    "One symbol of the 41-Across",
    "Shot up",
    "See 34-Across",
    "Mounted",
    "Word before or after tea",
    "Sommelier's selection",
    "Showed respect, in a way",
    "Attained new heights",
    "American Beauty, for one",
    "Glass-encased item in \"Beauty and the Beast\"",
    "Gift that might cut",
    "Pink wine",
    "Summer wine selection",
    "Thorny subject",
    "See 58-Across",
    "___ Sunday, the fourth Sunday in Lent",
    "Gift on \"The Bachelor\"",
    "Stood up",
    "Gift on \"The Bachelor\"",
    "Pink potable",
    "Switch-hitter known as Charlie Hustle",
    "Sunset shade",
    "Certain cabernet",
    "Left a chair",
    "Part of a Valentine bouquet",
    "Gift on a first date, maybe",
    "Wine choice",
    "Flower for Valentine's Day",
    "___ of Jericho",
    "Mounted",
    "Picked up",
    "Wine choice",
    "Picked up",
    "New York's state flower",
    "Jack's love in \"Titanic\"",
    "Valentine bouquet item",
    "Didn't sit still?",
    "Wine choice",
    "York symbol",
    "Pink",
    "Gift for a diva",
    "Dawn-colored",
    "Like some wines",
    "Kennedy matriarch",
    "Crimson Glory, for one",
    "Wine choice",
    "Left the couch",
    "\"Oliver Twist\" girl",
    "Obeyed a court order?",
    "California Grenache, e.g.",
    "Wine choice",
    "Sunset-tinged",
    "Wine choice",
    "Banned Pete",
    "Rocketed",
    "Floral gift",
    "Baseball's Charlie Hustle",
    "A Kennedy",
    "Late Kennedy matriarch",
    "Wine choice",
    "White house bud, in the future",
    "Kennedy matriarch",
    "Thorny flower",
    "American Beauty, e.g.",
    "Rambler, for one",
    "Ascended",
    "Emblem of England",
    "Bower flower",
    "Blush shade",
    "Damask, for one",
    "Stood",
    "Type of wine",
    "Billy of show biz",
    "Charlie Hustle"
  ],
  "utes": [
    "Pac-12 squad",
    "Sporty vehicles",
    "Sporty trucks, in brief",
    "\"Runnin'\" team of N.C.A.A. Division I college basketball",
    "Some Four Corners natives",
    "Beehive State tribe",
    "Runnin' ___ (N.C.A.A. team nickname)",
    "Neighbors of the Navajo",
    "Conference foes of the Buffaloes",
    "Some Pac-12 athletes",
    "Western tribe",
    "Home team at Rice-Eccles Stadium",
    "\"Runnin'\" college team",
    "Chief Ouray and others",
    "Pac-12 team about 625 miles from the Pacific",
    "Sport-___ (off-roaders)",
    "Beehive State tribe",
    "Sport-___ (some vehicles)",
    "Natives for whom a state is named",
    "Pac-12 team",
    "Some Shoshonean speakers",
    "Pac-12 team",
    "Tribe that gave its name to a state",
    "Western tribe",
    "Pac-12 team",
    "Four Corners-area tribe",
    "Runnin' ___ (N.C.A.A. team)",
    "Western tribe",
    "Shoshone relatives",
    "College team named for a tribe",
    "The Pac-12's Runnin' ___",
    "Pac-12 team",
    "Trojans' foes",
    "Huntsman Center team",
    "Sport-___ (vehicles)",
    "Great Basin natives",
    "Pac-12 team",
    "Salt Lake City athletes",
    "Western tribe",
    "Tribe near the Great Salt Lake",
    "Black Hawk War combatants",
    "Beehive State tribe",
    "See 115-Down",
    "Western Indians",
    "Pac-12 team",
    "Indians who gave their name to a state",
    "Tribe once in the Provo area",
    "Uintah and Ouray Reservation tribe",
    "Mountain West team",
    "Runnin' ___, 1944 N.C.A.A. basketball champs",
    "Western tribe",
    "Beehive State Indians",
    "Western Indian tribe",
    "Mountain West Conference team",
    "2009 Sugar Bowl champs",
    "Big wheels",
    "Mountain West Conference team",
    "Salt Lake City team",
    "Early Coloradans",
    "Shoshone speakers",
    "Salt Lake City athletes",
    "Versatile vehicles, for short",
    "Relatives of the Shoshones",
    "Mountain West Conference team",
    "Four Corners-area Indians",
    "Mountain West Conference team",
    "Western Indians",
    "Mountain West Conference team",
    "Huntsman Center players",
    "Chief Jack House and others",
    "Salt Lake City collegians",
    "Salt Lake City collegians",
    "Western tribe",
    "Mountain West Conference team",
    "Huntsman Center team",
    "Western tribe",
    "Sport ___ (off-road vehicles)",
    "Shoshoneans",
    "Uintah and Ouray Reservation inhabitants",
    "Tableland tribe",
    "Beehive State natives",
    "Mountain West conference team",
    "Sport ___ (modern vehicles)",
    "All-purpose trucks",
    "Off-roaders' choices, briefly",
    "N.C.A.A.'s Runnin' ___",
    "Chief Ouray's tribe",
    "Colorado natives",
    "Sport ___ (modern vehicles)",
    "Western Indians",
    "Sport ___ (popular vehicles)",
    "Huntsman Center team",
    "They have reservations about Colorado",
    "Rockies tribe",
    "Colorado Indians",
    "Versatile vehicles",
    "Short trucks?",
    "Western tribe",
    "Colorado natives",
    "Western tribe",
    "Sporty trucks, for short",
    "Western tribe",
    "1998 Final Four team",
    "Sport-___ (popular vehicles)",
    "Multipurpose vehicles, informally",
    "Salt Lake City players",
    "Western Indians",
    "Sport ___ (trucklike vehicles)",
    "Western Athletic Conference team",
    "Western tribe",
    "Salt Lake City students",
    "Western Athletic Conference team",
    "Indians with a sun dance",
    "Uintah Reservation Indians",
    "Colorado Indians",
    "Shoshoneans",
    "Colorado Indians",
    "Western Indians",
    "Enemies of the Navajo",
    "Indians of the West",
    "Shoshone tribe",
    "New Mexico natives",
    "Salt Lake City athletes",
    "Shoshoneans",
    "Colorado Indians",
    "Salt Lake City team",
    "Salt Lake City team",
    "Western Indians"
  ],
  "swap": [
    "Face ___ (app that creates bizarre photos)",
    "Substitute",
    "Exchange",
    "Trade",
    "Give and take, say",
    "Tit for tat?",
    "Tit for tat?",
    "Trade",
    "Give and take",
    "Exchange",
    "Exchange",
    "Even trade",
    "It's a deal",
    "Exchange",
    "Substitute",
    "Exchange",
    "Trade",
    "Tit for tat?",
    "Exchange",
    "One-for-one transaction",
    "Exchange",
    "Cashless transaction",
    "Trade",
    "Switch",
    "Quid pro quo",
    "Cash-free transaction",
    "With 67-Across, an appropriate title for this puzzle?",
    "Tax-free transaction, usually",
    "Tit for tat ... or tat for tit",
    "Give and take",
    "Mistake at the hospital?",
    "Exchange",
    "Substitute",
    "Trade",
    "Something to haggle over",
    "Cashless deal",
    "Give and take",
    "Give this for that",
    "Switch",
    "Give and take",
    "Trade",
    "Cashless deal",
    "Exchange",
    "Quid pro quo",
    "Pull the switch?",
    "Kind of meet",
    "This for that",
    "Exchange",
    "___ shop",
    "Trade",
    "Kind of meet",
    "Tit for tat?",
    "Exchange",
    "Give and take",
    "Give and take",
    "Pull a switch",
    "It's a deal",
    "Meet transaction",
    "Bandy",
    "Kind of meet",
    "Barter",
    "Trade",
    "Give and take",
    "Kind of meet",
    "Cashless deal",
    "Exchange",
    "Trade",
    "Barter",
    "Trade",
    "Give-and-take",
    "Exchange",
    "Give tit for tat",
    "Switch"
  ],
  "dvds": [
    "Successors of VHS tapes",
    "Certain Netflix offerings",
    "They may come in a boxed set",
    "Data storage items on the decline",
    "Some library offerings",
    "Redbox offerings",
    "Burned things",
    "Output of some pirates",
    "VHS tape displacers"
  ],
  "mona": [
    "Leonardo da Vinci's \"___ Lisa\"",
    "\"___ Lisa\"",
    "Leonardo da Vinci's \"___ Lisa\"",
    "\"___ Lisa\"",
    "Da Vinci's \"___ Lisa\"",
    "U.S. poet laureate ___ Van Duyn",
    "With 29-Across, source of a famous smile",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "Leonardo da Vinci's \"___ Lisa\"",
    "Poet ___ Van Duyn",
    "Villainess Vanderwaal of TV's \"Pretty Little Liars\"",
    "\"___ Lisa\"",
    "Homer Simpson's mother",
    "\"___ Lisa\"",
    "\"First name\" in the Louvre",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "Bart Simpson's grandmother",
    "Homer Simpson's mom",
    "Marisa's role in \"My Cousin Vinny\"",
    "Lisa with a \"mystic smile\"",
    "\"___ Lisa\"",
    "___ Van Duyn, 1991 Pulitzer-winning poet",
    "\"___ Lisa\"",
    "Poet Van Duyn",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "\"___ Lisa Smile\" (2003 film)",
    "\"___ Lisa\"",
    "Marisa's \"My Cousin Vinny\" role",
    "Author Simpson",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "First name in portraits",
    "\"The Best Little Whorehouse in Texas\" woman",
    "Poet Van Duyn",
    "\"___ Lisa\"",
    "Political columnist Charen",
    "\"Black Beauty\" star ___ Freeman",
    "Political columnist Charen",
    "Novelist Simpson",
    "Poet Van Duyn",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "Poet Van Duyn",
    "\"Who's the Boss?\" role",
    "Actress Washbourne",
    "\"___ Lisa\"",
    "\"___ Lisa\"",
    "\"Black Beauty\" star Freeman",
    "Pulitzer poet Van Duyn",
    "Novelist Simpson",
    "Former U.S. poet laureate ___Van Duyn",
    "\"___ Lisa\"",
    "?ô___ Lisa?ö",
    "___ Lisa",
    "\"___ Lisa\"",
    "Actress Washbourne",
    "\"Who's the Boss?\" mother",
    "\"___ Lisa\"",
    "Former U.S. poet laureate ___ Van Duyn",
    "\"The Best Little Whorehouse in Texas\" lady"
  ],
  "reap": [
    "Collect, as profits",
    "Bring in from the field",
    "Harvest",
    "Garner",
    "Take in",
    "Harvest",
    "Harvest",
    "Get back",
    "Collect from the soil",
    "Collect in the field",
    "Harvest",
    "Gather",
    "Collect",
    "Swing a scythe, say",
    "Take in",
    "Harvest, as crops",
    "Gather",
    "Gather",
    "Collect",
    "Gather what's been sown",
    "Cut crops",
    "Cut and collect in the field",
    "Gain, as benefits",
    "Earn, as profit",
    "End the growing season",
    "Garner",
    "Gather in",
    "Gather",
    "Realize, as profit",
    "Cut and collect",
    "Get back",
    "Harvest",
    "Cut off one's ears?",
    "Gather in the field",
    "Harvest",
    "Get, as profits",
    "Bring in",
    "Gather",
    "Harvest",
    "Sow's counterpart",
    "Procure",
    "Take in",
    "Get out of the field",
    "Take in",
    "Collect, as rewards",
    "Harvest",
    "Harvest",
    "Gain",
    "Glean",
    "Harvest",
    "Harvest",
    "Harvest",
    "Collect, as benefits",
    "Win",
    "Gather in",
    "Do some fall farmwork",
    "Bring in from the fields",
    "Obtain",
    "Bring in the sheaves",
    "Bring in",
    "Gather, as grain",
    "Bring in",
    "Get back",
    "Haul in",
    "Realize",
    "Take in",
    "Bring in",
    "Get in return",
    "Bring in",
    "Get back",
    "Cut the crop",
    "Get",
    "Harvest",
    "Bring in",
    "Bring in",
    "Harvest",
    "Bring in the sheaves",
    "Take in",
    "Bring in",
    "Harvest",
    "Harvest",
    "Harvest",
    "Acquire",
    "Realize",
    "Win",
    "Swing a scythe",
    "Get as a result",
    "\"___ the Wild Wind\" (DeMille movie)",
    "Harvest",
    "Bring in",
    "Gather",
    "Glean",
    "Bring in",
    "Collect",
    "Gather",
    "Bring in",
    "Harvest",
    "Sow's opposite",
    "Harvest",
    "Win",
    "Bring in",
    "Cut forage",
    "Harvest",
    "Bring in the sheaves",
    "Bring in the rye",
    "Harvest",
    "Gather",
    "Bring in",
    "Harvest",
    "Bring in",
    "Cut the mustard?",
    "Sow's opposite",
    "Harvest",
    "Realize",
    "Obtain",
    "Harvest",
    "Come by",
    "Lop the crop",
    "Get in return",
    "Glean",
    "Bring in",
    "Sow's opposite",
    "Harvest",
    "Bring in the crops",
    "Take in",
    "Gather"
  ],
  "agha": [
    "Turkish title of honor",
    "Mr. : English :: ___ : Persian",
    "Ottoman title",
    "Ottoman title",
    "Turkish title",
    "Ottoman honorific",
    "Ottoman Empire title",
    "Ottoman pooh-bah",
    "Ottoman commander",
    "Old Turkish V.I.P.",
    "Ottoman honorific",
    "Eastern leader",
    "Turkish honorific",
    "Turkish title",
    "Turkish title",
    "Turkish military leader",
    "Ottoman official",
    "Turkish title",
    "Turkish title",
    "Old Turkish title",
    "Turkish honorific",
    "Ottoman V.I.P.",
    "Turkish title",
    "Ottoman Empire chief",
    "Turkish title of old",
    "Turkish V.I.P.",
    "Muslim honorific",
    "Ottoman Empire V.I.P.",
    "Turkish title",
    "Turkish title",
    "Turkish title",
    "Turkish title",
    "Eastern panjandrum",
    "Muslim honorific",
    "Turkish honorific",
    "Turkish title",
    "Turkish official",
    "Turkish leader",
    "Turkish honorific",
    "Turkish title",
    "Near Eastern honorific",
    "Muslim honorific",
    "Turkish title",
    "Turkish title",
    "___ Khan (Muslim leader)",
    "Ottoman title",
    "Foreign title",
    "Turkish leader",
    "Eastern pooh-bah",
    "Turkish title",
    "Turkish title",
    "Turkish title",
    "Turkish title",
    "Mideast title",
    "Turkish official",
    "Foreign title of honor",
    "Turkish bigwig",
    "Turkish bigwig",
    "Turkish leader",
    "Turkish title",
    "Turkish honcho",
    "Turkish V.I.P."
  ],
  "farm": [
    "Old MacDonald had one",
    "Old MacDonald's place",
    "Food source",
    "Many hands may be found on it",
    "Raise things",
    "40 acres, maybe",
    "Old MacDonald's place",
    "Old MacDonald's place",
    "Old MacDonald had one",
    "50-acre homestead, maybe",
    "Minor-league club, in baseball",
    "4-Her's home",
    "Raise things",
    "Old MacDonald's property",
    "One to grow on",
    "Do agricultural work",
    "Old MacDonald's place",
    "Growing business?",
    "Barn's place",
    "Kind of team",
    "Its business is growing",
    "Kind of team",
    "Spread",
    "Raise crops",
    "Kind of team",
    "Growing locale",
    "Sharecrop",
    "Kind of team",
    "Ethan Frome's home",
    "Croft"
  ],
  "abut": [
    "Border on",
    "Appear alongside",
    "Border",
    "Be against",
    "What San Diego and Tijuana do",
    "Neighbor",
    "Share a side",
    "Border",
    "Border on",
    "Be up against",
    "Border",
    "Touch geographically",
    "Adjoin",
    "Border",
    "Be against",
    "What Iran and Iraq do",
    "Touch",
    "Side with?",
    "Touch",
    "Be against",
    "Go up against",
    "Go up against",
    "Touch",
    "Share a border with",
    "Touch, as two states",
    "Neighbor",
    "Share a border",
    "Be contiguous",
    "Border on",
    "Go cheek-to-cheek with",
    "Share a border",
    "Have an edge against",
    "Border",
    "Go up against",
    "Neighbor",
    "Be contiguous to",
    "Lie next to",
    "Border",
    "Touch",
    "Touch on",
    "Border on",
    "Touch",
    "Rest on",
    "Be flush with",
    "Border on",
    "Push up against",
    "Be against",
    "Neighbor",
    "Be next to",
    "Be next to",
    "Border on",
    "Border on",
    "Go up against",
    "Go up against",
    "Go up against",
    "Touch",
    "Border on",
    "Flank",
    "Push up against",
    "Touch",
    "Lie next to",
    "Border",
    "End at",
    "Have an edge against",
    "Border on",
    "Border on",
    "Lie next to",
    "Border on",
    "Be next to",
    "Border on",
    "Neighbor",
    "Neighbor",
    "Touch",
    "Border on",
    "Be against",
    "Adjoin",
    "Border upon",
    "Be adjacent to",
    "Touch upon",
    "Touch on",
    "Neighbor",
    "Neighbor on",
    "Border",
    "Touch on",
    "Border on",
    "Touch on",
    "Touch on",
    "Border on",
    "Side with?",
    "Touch on",
    "Have an edge against",
    "Border on",
    "Adjoin",
    "Touch",
    "Bump up against",
    "Touch",
    "Border",
    "Be next to",
    "Go up against",
    "Border on",
    "Share a side with",
    "Adjoin",
    "Border on",
    "Lie next to",
    "Neighbor",
    "Border on",
    "Touch",
    "Adjoin",
    "Touch",
    "Adjoin",
    "Touch upon",
    "Get in touch with?",
    "Stand side by side",
    "Touch",
    "Border on"
  ],
  "geny": [
    "Millennials, informally",
    "Millennials, by another name",
    "Millennials, informally"
  ],
  "iwin": [
    "\"You lose\"",
    "\"Checkmate!\"",
    "\"Victory is mine!\"",
    "Words of triumph",
    "\"Victory is mine!\"",
    "\"Victory!\"",
    "1-Across's cry",
    "\"Game over!\"",
    "Counterpart of \"You lose\"",
    "Uncommon cry after a lottery drawing",
    "\"Heads ___, tails ...\"",
    "\"Checkmate!\"",
    "Cry after hitting a jackpot",
    "\"Heads ___, tails ...\"",
    "\"Victory!\"",
    "\"Heads ___, tails ...\"",
    "\"Bingo!\"",
    "Triumphant cry",
    "Victor's cry",
    "\"Bingo!\"",
    "Option for heads",
    "Victor's cry",
    "Victor's cry",
    "\"Heads ___ ?à\"",
    "Victor's cry",
    "High-handed remark?",
    "Victor's cry",
    "\"You lose\"",
    "Game-ending declaration",
    "Cry after a coin flip",
    "Gleeful cry after a coin flip",
    "Game-ending pronouncement"
  ],
  "rods": [
    "Fishing sticks",
    "Sticks",
    "Nuclear fuel containers",
    "They're found in most clothes closets",
    "Partners of cones",
    "Parts of a nuclear reactor",
    "40 make up a furlong",
    "Shapes of some bacterial cells",
    "Some retina cells",
    "Subjects of meltdowns",
    "Bacilli shapes",
    "Shapes of bacilli bacteria",
    "Partners of cones in the eye",
    "Street ___ (souped-up cars)",
    "Towel holders",
    "Towel holders",
    "Reels' counterparts",
    "Engine parts",
    "Fishing sticks",
    "Curtain hangers",
    "Fishing poles",
    "Concrete reinforcers",
    "Some retinal cells",
    "Wands",
    "Partner of cones",
    "Reactor parts",
    "Eye parts",
    "Farm measures",
    "Curtain holders",
    "Lightning catchers",
    "With 40A, racers",
    "Fishing gear",
    "Nuclear fuel holders",
    "Fishing equipment",
    "Engine parts",
    "Parts of window treatments",
    "Wands",
    "Drape holders",
    "Heaters",
    "Fishing poles",
    "Bacillus shapes",
    "Axles",
    "Serling and Stewart",
    "Surveyor's measures",
    "Land measures"
  ],
  "gogh": [
    "Artist Vincent van ___",
    "Artist Vincent van ___",
    "Van ___",
    "Van ___, \"Lane in Autumn\" painter",
    "Artist Vincent van ___",
    "Van ___ (oil producer)",
    "Van follower",
    "Van ___"
  ],
  "umps": [
    "Baseball officials, for short",
    "Home plate officials, informally",
    "Ones usually found at home",
    "Ballpark figures",
    "Keeps track of a count, maybe",
    "Home squatters?",
    "People working at home",
    "Ones making calls, informally",
    "Experts in the field?",
    "Ones stationed at home",
    "Ones calling people out?",
    "Diamond officials, informally",
    "Ones working at home?",
    "They decide what's fair",
    "World Series game sextet",
    "Ones watching their plates?",
    "Works at the ballpark, maybe",
    "Home plate figures, informally",
    "Strike callers",
    "Callers of strikes and balls",
    "Officials on a diamond",
    "Ones making some safe decisions?",
    "Base characters?",
    "Officials who cry \"Steee-rike!\"",
    "Tag callers?",
    "Ones who might cry foul?",
    "Calls a game",
    "Officials behind batters",
    "Stereotypically \"blind\" officials",
    "Pitch evaluators",
    "Ones near bases",
    "You might be safe with them",
    "Diamond experts",
    "Diamond group",
    "Officials who cry \"Yer out!\"",
    "Vest wearers",
    "Calls a game",
    "They cover all the bases",
    "\"Steee-rike!\" callers",
    "Hall-of-famers Bill Klem and Nestor Chylak, e.g.",
    "Ballpark figures?",
    "Ballpark figures",
    "Diamond V.I.P.'s",
    "Ones found near home",
    "Sports officials",
    "Strike callers",
    "Diamond officials",
    "Strike callers",
    "They stand behind catchers",
    "Diamond experts",
    "They cover the bases",
    "Quartet on a baseball field",
    "Ballpark figures",
    "Strike callers",
    "\"Ball!\" callers",
    "Play callers",
    "Plate watchers",
    "They cover all the bases"
  ],
  "okey": [
    "\"___ doke!\"",
    "___-doke",
    "\"___-doke!\"",
    "___-dokey",
    "___-doke",
    "___-dokey",
    "___-dokey",
    "___-doke",
    "___ dokey"
  ],
  "push": [
    "Instruction on a door",
    "Midwife's instruction",
    "Delivery room instruction",
    "Instruction on a door",
    "Instruction on a door",
    "Encourage",
    "Promote",
    "Big fund-raising effort",
    "Campaign",
    "Market aggressively",
    "Labor leader's cry?",
    "Exhortation during labor",
    "Urge on",
    "Midwife's instruction",
    "Word on a door",
    "Midwife's exhortation",
    "Get-up-and-go",
    "Door word",
    "Word on a door",
    "Word on a door"
  ],
  "soho": [
    "Manhattan neighborhood above Canal Street",
    "London theater district",
    "London neighborhood west of Covent Garden",
    "Manhattan neighborhood below Greenwich Village",
    "N.Y.C. neighborhood near NoLIta",
    "\"I met her in a club down in old ___\" (\"Lola\" lyric)",
    "Manhattan neighborhood next to TriBeCa",
    "Upscale London district",
    "Boutique-filled N.Y.C. neighborhood",
    "Artsy Big Apple neighborhood",
    "Artsy Manhattan neighborhood",
    "Manhattan area north of TriBeCa",
    "Home of many Big Apple galleries",
    "\"From ___ down to Brighton, I must have played them all\" (\"Pinball Wizard\" lyric)",
    "London area next to Mayfair",
    "Gallery district in Manhattan",
    "Part of N.Y.C. once derisively called Hell's Hundred Acres",
    "Manhattan neighborhood next to TriBeCa",
    "Neighbor of Manhattan's Little Italy",
    "West End district",
    "Westminster district",
    "Artsy New York neighborhood",
    "Gallery-filled Manhattan neighborhood",
    "Carnaby Street's locale",
    "Artsy Manhattan neighborhood",
    "TriBeCa neighbor",
    "Manhattan district with art galleries",
    "Big Apple neighborhood",
    "Area near Little Italy",
    "Loft-y place?",
    "Home to some notable cast-iron architecture in N.Y.C.",
    "Greenwich Village neighbor",
    "Home of London's Palace Theatre",
    "Big Apple neighborhood",
    "TriBeCa neighbor",
    "London entertainment district",
    "Big Apple neighborhood",
    "Artsy N.Y.C. locale",
    "Swinging London district",
    "Gallery-filled part of the Big Apple",
    "It's east of Mayfair",
    "Westminster area",
    "Arty Manhattan district",
    "Area north of Leicester Square",
    "Artsy Manhattan area",
    "Manhattan area",
    "It borders Mayfair",
    "London district",
    "London district",
    "Manhattan district",
    "New York or London district",
    "Old warehouse district in New York",
    "Gallery area",
    "Tribeca is next to it",
    "London shopping district",
    "Shaftesbury Avenue locale",
    "Manhattan neighborhood",
    "Big Apple gallery district",
    "London's ___ Square",
    "Coventry Street locale",
    "Touristy district",
    "New York gallery district",
    "Big Apple area near TriBeCa",
    "London area",
    "Manhattan neighborhood",
    "Area of the Big Apple",
    "Manhattan area with lots of galleries",
    "TriBeCa neighbor",
    "London or New York district",
    "TriBeCa neighbor",
    "Arty Big Apple area",
    "London district",
    "London's ___ Square",
    "New York neighborhood",
    "It borders Regent Street",
    "Area near TriBeCa",
    "Big Apple section",
    "Manhattan locale",
    "Lower Broadway area",
    "It's near Piccadilly",
    "Part of London or Manhattan",
    "It's next to Mayfair, in London",
    "Noted gallery locale",
    "Carnaby Street locale",
    "Big Apple area",
    "London area",
    "London restaurant district",
    "London district",
    "Manhattan district",
    "Palace Theatre locale",
    "Noted art district",
    "It's near Piccadilly Circus"
  ],
  "twos": [
    "Lowest pair in poker",
    "What Kit Kat bars come in",
    "Deuces",
    "Low pair",
    "Counting by them takes half the time",
    "\"Terrible\" time for tykes",
    "Worst poker pair",
    "Ark groupings",
    "Craps throw called \"Little Joe\"",
    "Deuces",
    "Things not found in binary code, paradoxically",
    "Numbers for Noah",
    "Uncommon notes",
    "Some baskets",
    "Jeffersons",
    "Some toddler sizes",
    "\"Terrible\" years for kids",
    "Rare craps throws",
    "Groups on Noah's Ark",
    "Pairs",
    "Low rolls",
    "Low pair",
    "Uncommon bills",
    "Yahtzee category",
    "\"Terrible\" period",
    "Noah's Ark groupings",
    "Rare notes",
    "Pairings",
    "Deuces",
    "Low pair",
    "What chopsticks come in",
    "Pairs",
    "Infrequently seen bills",
    "Duos",
    "The animals for Noah's Ark came in these",
    "Terrible ___",
    "Jefferson bills",
    "Some people count by them",
    "Deuces",
    "Low pair",
    "Rare bills",
    "Rare bills",
    "Deuces",
    "Rare bills",
    "Rare bills",
    "Couples",
    "\"Terrible\" time",
    "Double dates",
    "Rare bills",
    "Some think they're terrible",
    "Terrible time?",
    "Terrible time?",
    "Terrible ___",
    "Counting method",
    "Braces",
    "\"___ company...\"",
    "The terrible ___",
    "Times that try parents' souls",
    "Ark groupings",
    "Terrible time",
    "Groups entering Noah's ark",
    "Uncommon bills",
    "Couples",
    "Deux, dos, due et al.",
    "Terrible ___",
    "Braces"
  ],
  "onus": [
    "Burden",
    "Burden",
    "Charge",
    "Burden to bear",
    "Obligation to do something",
    "Albatross",
    "Responsibility",
    "Burden",
    "Cross to bear",
    "Burden",
    "Weight",
    "Responsibility",
    "Burden",
    "Burden",
    "Heavy responsibility",
    "Cross to bear",
    "Weight",
    "Burden",
    "Cross to bear",
    "Burden",
    "Burden of proof",
    "Cross to bear",
    "Weight",
    "Cross to bear",
    "Responsibility",
    "Cross to bear",
    "It's hard to bear",
    "Heavy burden",
    "Burden",
    "Difficult duty",
    "Responsibility",
    "Heavy load",
    "Burden",
    "Responsibility",
    "Burden",
    "Heavy duty",
    "Burden",
    "\"This one's ___\" (\"Our treat\")",
    "Stigma",
    "Monkey on one's back",
    "Duty",
    "Burden",
    "___ probandi (legal term)",
    "Large charge",
    "Burden of proof",
    "Burden",
    "Difficult weight",
    "Burden",
    "Unpleasant duty",
    "Hard thing to carry",
    "So-called \"albatross\"",
    "Big burden",
    "Responsibility",
    "Arduous task",
    "Load to bear",
    "Cross to bear",
    "Burden",
    "Charge",
    "Burden",
    "Heavy load",
    "Burden",
    "Cross to bear",
    "Burden",
    "Burden",
    "Burden",
    "Albatross",
    "Cross to bear",
    "Millstone",
    "Cross to bear",
    "Unpaid debt, e.g.",
    "Weight",
    "Burden",
    "Millstone",
    "Burden of proof",
    "Responsibility",
    "Weight",
    "Cross to bear",
    "Difficult burden",
    "Big burden",
    "Obligation",
    "Burden of proof",
    "Obligation",
    "Burden",
    "Weight",
    "Burden of proof",
    "Burden",
    "Responsibility",
    "Cross",
    "Burden",
    "Heavy burden",
    "Weight",
    "Burden",
    "Burden",
    "Cross to bear",
    "Burden",
    "Load to bear",
    "Burden of proof",
    "___ probandi (burden of proof)",
    "Load",
    "Burden",
    "Blame",
    "Burden",
    "Millstone",
    "Weight",
    "Burden",
    "Burden",
    "Cross to bear",
    "Weight",
    "Burden",
    "Obligation",
    "Blame",
    "Encumbrance",
    "Responsibility",
    "Albatross, figuratively",
    "Blame",
    "Burden",
    "Burden",
    "Burden",
    "Responsibility",
    "Something borne",
    "Duty",
    "Weight",
    "Obligation, in court",
    "Burden",
    "Cross to bear",
    "Responsibility",
    "Burden",
    "Burden",
    "Burden",
    "Encumbrance",
    "Unwelcome work",
    "It's carried on the shoulders",
    "___ probandi",
    "Duty",
    "Blame",
    "Blame",
    "Load to bear",
    "Burden",
    "Burden of proof",
    "Responsibility",
    "Unpleasant task",
    "___ probandi",
    "Burden",
    "Burden",
    "Duty",
    "Load",
    "Burden",
    "Burden",
    "Load",
    "Burden",
    "Blame",
    "Albatross",
    "___ probandi (legal doctrine)",
    "Responsibility",
    "Weight",
    "Burden",
    "Burden",
    "Cross to bear",
    "Responsibility",
    "Heavy load",
    "Responsibility",
    "Burden"
  ],
  "dahl": [
    "Roald who wrote \"James and the Giant Peach\"",
    "Author of \"The Witches\" and \"The Twits\"",
    "Author whose name is one letter off from 31-Down",
    "Roald who wrote \"The BFG\"",
    "Roald who created Willy Wonka",
    "Author of the best-selling children's book \"Matilda\"",
    "\"The Twits\" author",
    "Roald who wrote \"James and the Giant Peach\"",
    "Author Roald",
    "\"The BFG\" author",
    "Roald who wrote \"Fantastic Mr. Fox\"",
    "Charlie Bucket's creator",
    "Creator of the Oompa-Loompas and the BFG",
    "Roald who wrote \"Fantastic Mr. Fox\"",
    "Wonka inventor",
    "Roald who wrote \"James and the Giant Peach\"",
    "\"The Witches\" writer",
    "\"Fantastic Mr. Fox\" author",
    "Author Roald",
    "Children's author who won three Edgars",
    "Children's author who created Miss Trunchbull",
    "Willy Wonka's creator",
    "Writer Roald who created the Oompa-Loompas",
    "Author Roald",
    "\"Matilda\" author",
    "\"Matilda\" author, 1988",
    "Roald who created Willy Wonka",
    "Author Roald",
    "Author Roald",
    "Gary who invented the Pet Rock",
    "Willy Wonka's creator",
    "Gary who invented the Pet Rock",
    "Roald who wrote \"James and the Giant Peach\"",
    "Actress Arlene",
    "Roald who created Willy Wonka",
    "\"Fantastic Mr. Fox\" author",
    "Willy Wonka's creator",
    "Roald who wrote \"James and the Giant Peach\"",
    "\"Fantastic Mr. Fox\" author, 1970",
    "\"The Witches\" author",
    "Arlene of \"Here Come the Girls,\" 1953",
    "Actress Arlene",
    "Arlene of \"Here Come the Girls\"",
    "Roald who wrote \"Charlie and the Chocolate Factory\"",
    "Writer Roald",
    "Willy Wonka's creator",
    "Author Roald",
    "Willy Wonka's creator",
    "Willie Wonka's creator",
    "Arlene of \"One Life to Live\"",
    "\"The Gremlins\" author",
    "\"Chitty Chitty Bang Bang\" screenwriter",
    "Author Roald",
    "Screenwriter/novelist Roald",
    "Children's author Roald",
    "Author Roald",
    "\"Fantastic Mr. Fox\" author",
    "\"You Only Live Twice\" scriptwriter",
    "Willy Wonka's creator",
    "\"Kiss, Kiss\" author",
    "\"James and the Giant Peach\" author",
    "Willy Wonka creator",
    "Willy Wonka's creator",
    "\"Charlie and the Chocolate Factory\" author",
    "\"Chitty Chitty Bang Bang\" screenwriter",
    "\"The Diamond Queen\" actress"
  ],
  "spas": [
    "Pampering places",
    "\"Me day\" destinations",
    "Sites for whirlpools",
    "Health resorts",
    "Mineral springs",
    "Places for baths",
    "Upscale hotel amenities",
    "Springs for vacation trips",
    "Rejuvenating resorts",
    "Complimentary robe providers",
    "Springs",
    "Pampering places",
    "Places where cucumber slices are not for salad",
    "Springs for a vacation?",
    "Upscale hotel features",
    "Some bathroom installations",
    "Mineral springs",
    "Beauty spots",
    "Beauty spots",
    "Places for pampering",
    "Employers of masseurs",
    "Treatment centers?",
    "Beauty spots",
    "Luxury resort amenities",
    "Hot tubs",
    "Massage joints",
    "Stops to get a massage",
    "Massage spots",
    "Workplaces for masseuses",
    "Some cruise ship amenities",
    "Places offering facials",
    "Travel agency listings",
    "Relaxing spots",
    "They have springs",
    "Places to get massages",
    "Places for sweaters",
    "Resorts",
    "Baths",
    "Baden-Baden and others",
    "Complimentary robe providers",
    "Places to wear robes",
    "Five-star hotel offerings",
    "Places for baths",
    "Spots for rubs and scrubs",
    "Pleasant treatment centers",
    "Healthful retreats",
    "Steamy places",
    "Apt rhyme of \"aahs\"",
    "Resorts",
    "Hydrotherapy sites",
    "Places where masseurs massage",
    "Evian and Vichy",
    "They may have jets",
    "Places to unwind",
    "Places to press the flesh?",
    "Rest spots",
    "Watering holes",
    "Places to be pampered",
    "Locales of mineral waters",
    "Bath and others",
    "Places to tone bodies",
    "Body shops?",
    "Health resorts",
    "Healthful resorts",
    "Pool sites",
    "Jacuzzis",
    "R & R sites",
    "Sites of hot springs",
    "Bath features",
    "Relaxation venues",
    "Healthful retreats",
    "Fitness centers",
    "Springs",
    "Restful resorts",
    "Ems and others",
    "Places to get steamed",
    "Resorts",
    "Vacation spots",
    "Pampering places",
    "Jacuzzis",
    "Hot Springs and others",
    "Healthful retreats",
    "Pool sites",
    "Baths",
    "Relaxing spots",
    "Exercise options?",
    "Pampering places",
    "Hydros : England :: ___ : U.S.",
    "Soothing spots",
    "Health resorts",
    "Luxury resorts",
    "Hot tubs",
    "They may offer rubdowns",
    "Some resorts",
    "Where people shape up",
    "Resorts of sorts",
    "They'll get you in hot water",
    "Special home installations",
    "Watering holes",
    "Restorative sites",
    "Exercise options?",
    "They have springs",
    "Luxurious resorts",
    "Hot Springs and others",
    "Places to get 9-Down",
    "Watering holes",
    "Bath add-ons",
    "Get-well spots",
    "Body shops?",
    "They may be filled with jets",
    "Hot tubs",
    "Watering holes",
    "Some retreats",
    "Vacation sites",
    "Mineral springs",
    "Luxury home features",
    "Resorts, of sorts",
    "Jacuzzis",
    "Baden and Bath, e.g.",
    "Ems and Baden-Baden",
    "Baden-Baden and Ems",
    "Vichy and Ems",
    "Resorts",
    "Bath and Baden-Baden",
    "Karlovy Vary and others",
    "Vichy et al.",
    "New England soda fountains",
    "Hot springs",
    "Spring spots",
    "Body shops?",
    "Baden-Baden and others",
    "Toning salons",
    "French Lick and others"
  ],
  "ziti": [
    "Baked Italian dish",
    "Big tubes",
    "It's totally tubular",
    "Baked dish often served with tomato sauce",
    "Cousin of penne",
    "Pasta choice",
    "Baked pasta dish",
    "Rigatoni's cousin",
    "Baked ___",
    "Baked Italian dish",
    "Pasta often baked with tomato sauce",
    "Ingredient in some pastitsio",
    "Penne alternative",
    "Tubes on the dinner table",
    "Large-tubed pasta",
    "Tubular pasta",
    "Baked ___",
    "Pasta choice",
    "Tubes in an oven",
    "Tubes in an oven",
    "Casserole pasta",
    "Feeding tubes?",
    "Pasta choice",
    "Fusilli alternative",
    "Tubular pasta",
    "Alternative to penne",
    "Rigatoni relative",
    "Popular pasta",
    "Tubes on the table",
    "Tubular pasta",
    "Tubular pasta",
    "Tubes in the kitchen",
    "Alternative to bow ties",
    "Tubes on the dinner table",
    "Rigatoni relative",
    "Rigatoni relative",
    "Penne alternative",
    "Tubular pasta",
    "Penne alternative",
    "Cousin of rigatoni",
    "Tubular pasta",
    "Tubular pasta",
    "Tubular pasta",
    "Tubular pasta"
  ],
  "loot": [
    "Pirate's treasure",
    "What might be taken away in a getaway",
    "Rioter's spoils",
    "Getaway car cargo",
    "Pillage",
    "Heist take",
    "Sack",
    "Stolen stuff",
    "Sack",
    "Ill-gotten goods",
    "Booty",
    "Rioter's haul",
    "Thief's bagful",
    "Pillage",
    "Boodle",
    "Hot goods",
    "Plunder",
    "Spoils",
    "Plunder",
    "Booty",
    "Swag",
    "It may be hidden at a hideout",
    "Rioter's haul",
    "Plunder",
    "Spoils",
    "Spoils",
    "Rioter's take",
    "Spoils",
    "Heist haul",
    "Booty",
    "Stolen money",
    "Spoils",
    "Rioter's take",
    "Plunder",
    "Plunder",
    "Stolen goods",
    "Ransack",
    "Booty",
    "Ransack",
    "It may be fenced",
    "Hot stuff",
    "Rioter's take",
    "Hot stuff",
    "Sack",
    "Heister's haul",
    "Heist haul",
    "Swag",
    "Sack",
    "Swag",
    "Ill-gotten goods",
    "Spoils",
    "Spoils",
    "Spoils",
    "Cabbage",
    "Hot items",
    "Heist gain",
    "Rioter's take",
    "Booty",
    "Spoils",
    "1966 Joe Orton play",
    "Boodle",
    "Pirate's recompense",
    "Burglarize",
    "Spoils",
    "Booty",
    "Swag",
    "Booty",
    "Treasures",
    "Robber's take"
  ],
  "iger": [
    "Longtime Disney chief Bob",
    "Media exec Robert",
    "Robert ___, longtime Disney C.E.O.",
    "Disney president who oversaw the Pixar acquisition",
    "Bob ___, longtime Disney C.E.O.",
    "Disney exec Bob",
    "Disney bigwig Bob",
    "Robert who oversaw the acquisitions of Pixar, Marvel and Lucasfilm",
    "Eisner's successor at Disney",
    "Disney chief Bob",
    "Eisner's successor at Disney",
    "Eisner's successor as Disney chief",
    "Media executive Bob",
    "Media exec Robert",
    "Media exec Robert",
    "Eisner successor at Disney"
  ],
  "rook": [
    "Corner chess piece",
    "Starting piece on a1 or h8, say",
    "Swindle",
    "Figure in a corner",
    "Fleece",
    "Part of a king's guard",
    "Piece on a1",
    "Swindle",
    "Castle",
    "One that can only go straight",
    "Starter on square a1, h1, a8 or h8",
    "Piece next to a knight",
    "Corner chess piece",
    "Corner piece in chess",
    "Corner piece",
    "Corner piece in chess",
    "Piece next to a knight",
    "Corner piece",
    "Corner piece",
    "Corner piece",
    "Castle, in chess",
    "Castle",
    "Fleece",
    "Knight's neighbor",
    "Corner chesspiece",
    "Corner piece",
    "Man in a corner",
    "Piece next to a knight",
    "Rip off",
    "Corner piece",
    "Corner piece",
    "Corner piece",
    "Piece next to a knight",
    "Orthogonally moving piece",
    "Fleece",
    "Piece next to a knight",
    "Hornswoggle",
    "Kasparov's corner man",
    "Fleece",
    "Chess castle",
    "Castle, in chess",
    "Corner piece",
    "Corner piece",
    "Hornswoggle",
    "Dupe",
    "Defraud"
  ],
  "scam": [
    "Con job",
    "Email about big lottery winnings, usually",
    "Many a get-rich-quick scheme",
    "Hoodwink",
    "Swindle",
    "Kind of artist",
    "With 41-Down, swindlers",
    "Swindle",
    "Fake I.R.S. call, e.g.",
    "Subject of a Better Business Bureau investigation",
    "Grifter's game",
    "Rip-off",
    "Racket",
    "Almost any offer that's too good to be true",
    "Pull a fast one on",
    "Grifter's game",
    "Apple varieties / Trick",
    "Phishing scheme, e.g.",
    "Ponzi scheme, e.g.",
    "Run a con on",
    "Rook or gull",
    "Robocall from the I.R.S., e.g.",
    "Attorney general's investigation target",
    "Deal with a huge catch",
    "Play for a fool",
    "Take for a ride",
    "Flimflam",
    "Arrangement of hosing?",
    "Put one over on",
    "Almost any \"Get rich quick!\" offer",
    "Get-rich-quick offer, typically",
    "Many a carnival game",
    "Grift, e.g.",
    "One may take you in",
    "Source of ill-gotten gains",
    "Fool",
    "Fleecing",
    "Swindle",
    "Do a Bernie Madoff job on",
    "Too-good-to-be-true offer, often",
    "Hustle",
    "Phishing lure?",
    "Con game",
    "Goal of phishing",
    "E-mail from a Nigerian prince, usually",
    "E-mail from a Nigerian prince, probably",
    "Bernie Madoff's hedge fund, e.g.",
    "Offer that's too good to be true, often",
    "Contrivance for taking people for a ride",
    "Many an e-mail \"click here\" offer",
    "Hustle",
    "One reported to the Better Business Bureau",
    "It takes folks in",
    "Ripoff",
    "Hustle",
    "Con",
    "Fleece",
    "Con game",
    "Con game",
    "E-mail offer of $17,000,000.00, e.g.",
    "Fast one",
    "Ponzi scheme, e.g.",
    "Ripoff",
    "Offer that seems too good to be true, probably",
    "Shell game",
    "Shell game, e.g.",
    "E-mail from a Nigerian with $10 million to give you, e.g.",
    "Rip-off",
    "Hustle",
    "Fleece",
    "Hoodwink",
    "Take for a ride",
    "One may take you in",
    "Ponzi scheme, e.g.",
    "Cozenage",
    "Pyramid scheme",
    "Money-losing proposition",
    "Fleece",
    "Shell game",
    "Swindle",
    "Pyramid scheme, e.g.",
    "Trick",
    "Ripoff",
    "Ensnaring scheme",
    "Sting",
    "Fraud",
    "Cheat",
    "Fleece",
    "Con game",
    "Swindle",
    "Defraud",
    "Ripoff",
    "Fast one",
    "Con game",
    "Fleece",
    "Swindle",
    "Ripoff",
    "Fraud",
    "Fraudulent operation",
    "Defraud",
    "Con game",
    "Con",
    "Fast shuffle, so to speak",
    "Rip-off",
    "Con game",
    "Con artist's art",
    "Run a game on",
    "Rip-off",
    "Swindle",
    "Swindle",
    "Sting",
    "Pull a con",
    "Sting",
    "Swindle",
    "Fraud",
    "Con job",
    "Con",
    "Con game",
    "Fast one",
    "Money-losing proposition",
    "Rip off",
    "Con man's con",
    "Swindle",
    "Deception",
    "Telemarketing danger",
    "Con",
    "How a fool and his money are parted",
    "Three-card monte, e.g.",
    "Hoax",
    "Case for the consumer affairs bureau",
    "Rip-off",
    "Ripoff",
    "Con artist's game",
    "Swindle",
    "Defraud",
    "Con game",
    "Ponzi scheme",
    "Dupe",
    "Bunco",
    "Swindle",
    "Pigeon drop, e.g.",
    "Sting",
    "Sucker play"
  ],
  "tees": [
    "Golfer's bagful",
    "Seriously annoys, with \"off\"",
    "Popular reunion swag",
    "Green pocketful",
    "They provide added height for a swing",
    "Props of sorts",
    "Golf ball stands",
    "Many souvenir shirts",
    "Butt end?",
    "Golf pegs",
    "Golfer's bagful",
    "Handful of golfers?",
    "Souvenir shop purchases",
    "Bagful carried by a caddie",
    "Some intersections",
    "Golf ball props",
    "Some stadium souvenirs",
    "Links things",
    "Shots are taken off of them",
    "Some summer wear",
    "Pro shop purchases",
    "Things having their home on the range?",
    "Aids for football kickers",
    "Course requirements?",
    "Souvenir shop stock",
    "Purchases at a golf pro shop",
    "Homecoming giveaways",
    "Butt end?",
    "Caddie's bagful",
    "Tic-tac-toe starters?",
    "Souvenir shop stock",
    "Golfer's bagful",
    "Tour mementos",
    "Casual wear",
    "Equipment miniature golf players don't need",
    "Caddie's pocketful",
    "Tight ends?",
    "Souvenir buys",
    "Athletic supporters?",
    "Little props",
    "Tiger's bagful",
    "Morse dashes",
    "Perturbs, with \"off\"",
    "Athletic supporters?",
    "Little middle?",
    "Inexpensive pro shop buys",
    "Some pipe joints",
    "Concert souvenirs",
    "Two out of twenty?",
    "*Pro shop buys",
    "Ball supporters",
    "Ball holders",
    "Casual tops",
    "Caddie's bagful",
    "Pigskin supports",
    "Gets ready, with \"up\"",
    "Supply for driving",
    "Gadgets not needed in miniature golf",
    "Some plumbing joints",
    "Summer shirts, informally",
    "Tourist shop display",
    "Buttonless shirts, informally",
    "Curling targets",
    "Summer shirts",
    "Souvenir shop staples",
    "Casual wear",
    "Golf pegs",
    "Golfer's bagful",
    "Concert souvenirs",
    "Golf ball props",
    "Souvenir shop stock",
    "Drivers' aids",
    "Tight ends?",
    "Golfer's bagful",
    "Links things",
    "Tight ends?",
    "Casual shirts",
    "Starts (off)",
    "Ball-bearing group",
    "Golf ball props",
    "Place-kickers' props",
    "Driving spots",
    "Golfers' needs",
    "Some souvenirs",
    "Pro shop bagful",
    "Ball-bearing items",
    "Golf shop purchase",
    "Caddie's bagful",
    "Souvenir buys",
    "Golfer's bagful",
    "Informal shirts",
    "Course aids",
    "Caddie's bagful",
    "Elevators, of a sort",
    "Ball props",
    "Shirts for golfers?",
    "Summer shirts",
    "Caddie supplies",
    "Golfer's pocketful",
    "Some intersections",
    "Driver's needs",
    "Concert souvenirs",
    "Tops or props",
    "Undershirts",
    "Kickoff props",
    "Some shirts, informally",
    "Golf pegs",
    "Driving needs",
    "Some intersections",
    "Caddies carry them",
    "Summer shirts",
    "Caddies carry them",
    "Golf course 18",
    "Golfers' gadgets"
  ],
  "ifwe": [
    "\"___ put our heads together ...\"",
    "\"___ Must Die\" (Claude McKay poem)",
    "\"___ Never Meet Again\" (Elvis song)"
  ],
  "phil": [
    "Singer/drummer Collins",
    "Hartman who voiced Troy McClure on \"The Simpsons\"",
    "\"S.N.L.\" alum Hartman",
    "TV's Dr. ___",
    "One of the Everly Brothers",
    "Dr. ___",
    "Uncle ___, main role on \"The Fresh Prince of Bel-Air\"",
    "Knight who co-founded Nike",
    "Man's nickname in a metropolitan orchestra?",
    "Golfer Mickelson",
    "Dr. ___",
    "Punxsutawney prognosticator",
    "Doctor seen by millions",
    "One of the Everly Brothers",
    "Don Everly's singing brother",
    "Uncle on \"The Fresh Prince of Bel-Air\"",
    "Rock's Everly or Collins",
    "Afternoon TV's Dr. ___",
    "\"Cool dad\" on \"Modern Family\"",
    "TV's Dr. ___",
    "Punxsutawney ___ (annual spring forecaster)",
    "Punxsutawney ___ (Groundhog Day celeb)",
    "One of the Everly Brothers",
    "Dr. ___",
    "Grammy-winning Collins",
    "Dr. ___",
    "Dr. with advice in O magazine",
    "\"The Amazing Race\" host Keoghan",
    "TV Dr. of note",
    "TV's Dr. ___",
    "Dr. ___",
    "Punxsutawney name",
    "Punxsutawney groundhog",
    "\"Buck Rogers\" novelist Nowlan",
    "Rizzuto of the 1940's-50's Yankees",
    "Teammate of Yogi",
    "Dr. on TV",
    "Hockey's Esposito",
    "Talk show host Dr. ___",
    "Dr. ___, TV adviser on life and relationships",
    "Silvers who played Sgt. Bilko",
    "Pop singer Collins",
    "4-Down's twin",
    "One of the Everly Brothers",
    "\"Murphy Brown\" bar owner",
    "TV's Donahue",
    "Pop singer Collins",
    "Yankee legend Rizzuto",
    "Rizzuto or Collins",
    "\"Murphy Brown\" bar owner",
    "Esposito of hockey",
    "Comedian Hartman",
    "Sen. Gramm",
    "One of the Everly Brothers",
    "First name in daytime talk"
  ],
  "rons": [
    "\"___ Gone Wrong\" (2021 film)",
    "Hamilton biographer Chernow and others",
    "Two 1980s White House personages",
    "Cey and Darling of baseball",
    "Running back Dayne and others",
    "Reagan and others",
    "Hollywood's Howard and Perlman",
    "Reagan and others",
    "Baseballers Guidry and Swoboda",
    "Jockey Turcotte and others",
    "Reagan Sr. and Jr.",
    "Silver and Brown",
    "Pres. Reagan and others",
    "Howard and Brown",
    "Brown and others",
    "Actors Silver and Howard",
    "Baseball's LeFlore et al.",
    "Howard and Brown"
  ],
  "hall": [
    "Room in Clue",
    "Part of a campus map",
    "Place for a monitor",
    "Campus building",
    "Corridor",
    "Campus building",
    "Concert venue",
    "Room in Clue",
    "It's between the Study and Lounge on a Clue board",
    "College building",
    "___ of Fame",
    "Room in Clue",
    "Long way to walk?",
    "Place for family portraits",
    "Where a lecture may be given",
    "Long way?",
    "Concert site",
    "Concert venue",
    "See 53-Across",
    "Room connector",
    "Campus site",
    "Symphony venue",
    "Campus building",
    "Place where there might be a mess",
    "Kind of monitor",
    "Hotel feature",
    "Campus building",
    "Quad building",
    "Place for portraits",
    "Campus building",
    "Lecture room",
    "Campus location",
    "Place for portraits",
    "Repository of fame?",
    "\"Let's Make a Deal\" host",
    "School locale",
    "Locale in the game Clue",
    "Campus building",
    "Room connector",
    "Convention site",
    "Place for a lecture",
    "Boston's Faneuil ___",
    "Auditorium",
    "Dining area",
    "___ of Fame",
    "Room between rooms"
  ],
  "shim": [
    "Carpenter's wedge",
    "Level",
    "Leveling wedge",
    "Table evener",
    "Carpenter's leveler",
    "Level",
    "Leveling wedge",
    "Level",
    "Leveler",
    "Carpentry spacer",
    "Thin wedge",
    "Thin wedge",
    "It keeps things on the level",
    "One just filling up space",
    "Level",
    "Carpenter's leveler",
    "Slender wedge",
    "Table leveler",
    "Level",
    "Machine wedge",
    "Mason's wedge",
    "Leveling wedge",
    "Thin wedge of wood",
    "Mason's wedge",
    "Leveling wedge",
    "Wooden wedge"
  ],
  "melt": [
    "Die like the Wicked Witch of the West",
    "What ice cubes do in the hot sun",
    "Go soft",
    "Disappear, as snow",
    "Go from one state to another?",
    "Annual spring occurrence",
    "Hot sandwich",
    "What snowmen do in the sun",
    "Tuna ___",
    "Wrap alternative",
    "Start running",
    "Go soft",
    "Endure a temperature of 110??, say",
    "What a 40-Across produces in the summer",
    "Short-order sandwich",
    "Go the way of snowmen",
    "Tuna-and-cheese sandwich",
    "Diner menu item",
    "Go soft",
    "Go weak in the knees",
    "Go soft",
    "Tuna ___",
    "Disappear a la Frosty",
    "Cheesy sandwich",
    "Alternative to a B.L.T.",
    "Cheesy sandwich",
    "Opposite of freeze",
    "Soften",
    "Go weak at the knees",
    "Unfreeze",
    "Cheesy entree",
    "Become squishy, like chocolate",
    "Sandwich from the oven",
    "Go soft, as butter",
    "Dish with tuna and cheese",
    "Soften, as chocolate",
    "Change states, in a way",
    "Lunch counter offering",
    "Lose one's resistance",
    "Hot sandwich",
    "Hot sandwich",
    "Become soft",
    "Liquefy",
    "Tuna ___",
    "Go soft, in a way",
    "Go soft",
    "Tuna ___",
    "Tuna ___",
    "Dissolve",
    "Soften",
    "Liquate",
    "Get all mushy",
    "Go to a different state?",
    "Deliquesce",
    "Change states, in a way",
    "Put salt on, maybe",
    "Sandwich that's been heated",
    "Defrost",
    "Get all teary-eyed",
    "Liquefy, as ice cream",
    "Weaken, romantically",
    "Dissolve",
    "Grilled sandwich",
    "Thaw",
    "Cheesy sandwich"
  ],
  "tile": [
    "Domino, e.g.",
    "Mahjong piece",
    "Component of some fractals",
    "Scrabble piece",
    "Draw from a Scrabble bag",
    "Floor type",
    "Scrabble unit",
    "Roofing option",
    "Piece of a mosaic",
    "Floor piece",
    "One of a hundred in Scrabble",
    "Makeshift coaster, maybe",
    "Outdoor fountain piece",
    "Bananagrams game piece",
    "Do some roof work",
    "Roofing material",
    "Bathhouse square",
    "Flooring option",
    "Plaza square, maybe",
    "Part of a space shuttle's exterior",
    "Roofing choice",
    "Scrabble piece",
    "Backsplash piece",
    "Game piece",
    "Mah-jongg piece",
    "Scrabble draw",
    "Backsplash unit",
    "Item for a mason",
    "8-point X, e.g.",
    "Scrabble piece",
    "Tessellation piece",
    "Scrabble piece",
    "Some flooring",
    "Word game component, sometimes",
    "10-point Q, e.g.",
    "It may be fired",
    "Square in a public square, maybe",
    "Counter piece",
    "Slate alternative",
    "Game piece",
    "Roofing choice",
    "Backsplash component",
    "Mah-jongg piece",
    "Square in a steam room",
    "Mah-jongg draw",
    "Mah-jongg piece",
    "Terra-cotta piece",
    "Game piece",
    "Floor element",
    "One with a glazed look?",
    "Shower square",
    "Shuttle protector",
    "Scrabble piece",
    "Mosaic piece",
    "Game piece",
    "Scrabble piece",
    "Tessellation unit",
    "Bit of roofing",
    "Scrabble piece",
    "Linoleum alternative",
    "Bit of masonry",
    "Glazed square",
    "Parquet alternative",
    "Bit of flooring",
    "Kind of floor",
    "Mah-jongg piece",
    "Glazed unit",
    "Mosaic piece",
    "Mah-jongg piece",
    "Ceramacist's medium",
    "One of 100 in Scrabble",
    "Fireplace decoration",
    "Turkish bath decoration",
    "Easy-to-clean floor",
    "Scrabble piece",
    "Bath item",
    "4-point W, e.g.",
    "Scrabble draw",
    "It's baked in a square",
    "Scrabble piece",
    "Domino, e.g.",
    "Mah-jongg piece",
    "Bathroom flooring",
    "Floor unit",
    "Scrabble piece",
    "Floor square",
    "Villa decoration",
    "Grouter's target",
    "Villa decoration",
    "Scrabble piece",
    "Flooring",
    "Gamepiece",
    "Floor piece",
    "Hacienda roofing material",
    "Ceramic",
    "Bathroom feature",
    "Roofing item",
    "Patio component",
    "Scrabble piece",
    "Roofing item"
  ],
  "saur": [
    "Dino's tail?",
    "Ending with dino-",
    "Jurassic suffix",
    "Suffix at a natural history museum",
    "Suffix with dino-",
    "Suffix with dino-",
    "Suffix with ptero-",
    "Suffix with tyranno-",
    "Dino's tail?",
    "Ancient reptile's suffix"
  ],
  "fork": [
    "It's often left on the table",
    "Decision spot",
    "Road divider",
    "Decision point",
    "Decision point in a road",
    "Food sticker",
    "Stick with it",
    "Chess tactic that involves attacking two pieces at once",
    "Part of a road",
    "Choice location?",
    "Silver piece",
    "Part of a river",
    "Divide"
  ],
  "epic": [
    "___ Games, company behind Fortnite",
    "Word with poetry or proportions",
    "To be remembered for all time",
    "Legendary",
    "___ Games, maker of Fortnite",
    "The \"Odyssey,\" for one",
    "More than outstanding",
    "\"Lawrence of Arabia,\" for one",
    "The \"Mahabharata\" or the \"Ramayana\"",
    "Derek Walcott's \"Omeros,\" for one",
    "More than magnificent",
    "Earth-shattering",
    "More than amazing",
    "Huger than huge",
    "The \"Iliad\" or the \"Odyssey\"",
    "Grand in scale",
    "Way cool",
    "Greater than great",
    "Completely amazing, in slang",
    "For the ages",
    "Word before tale or fail",
    "\"Shogun\" or \"The Lord of the Rings\"",
    "For the ages",
    "Story of heroes",
    "Long narrative poem",
    "___ Games, company behind Fortnite",
    "Totally awesome",
    "Grand-scale production",
    "\"Beowulf,\" e.g.",
    "Grandiose",
    "\"Awesome, dude\"",
    "To be remembered for a very long time",
    "Way impressive",
    "\"The Last Emperor,\" e.g.",
    "Three-hour-plus movie, maybe",
    "Bigger than big",
    "Bigger than big",
    "Grander than grand",
    "Grand",
    "Absolutely awesome",
    "Longfellow's \"Evangeline,\" e.g.",
    "Many a Charlton Heston movie",
    "\"The Divine Comedy,\" e.g.",
    "It's a long story",
    "Long tale",
    "For the ages",
    "Mind-blowing, in modern lingo",
    "Filmdom's \"The Bible,\" e.g.",
    "Having immense implications",
    "Huger than huge",
    "Huge in scope",
    "\"Ben-Hur,\" for one",
    "\"Gladiator,\" for one",
    "Grander than grand",
    "Bigger than big",
    "\"Gilgamesh,\" e.g.",
    "Like one for the ages",
    "Vast",
    "Totally awesome",
    "More than awesome",
    "\"Ben-Hur,\" for one",
    "\"The Far Pavilions,\" for one",
    "Grand",
    "Movie that might have a cast of thousands",
    "Like some fails, in modern slang",
    "Awesome, in slang",
    "More awesome than awesome",
    "Grand",
    "Monumental",
    "\"The Lord of the Rings,\" e.g.",
    "Grand-scale",
    "Grand",
    "Heroic tale",
    "\"Beowulf,\" e.g.",
    "Quite a tale",
    "Awesome",
    "Awesome, in slang",
    "\"Ben-Hur,\" for one",
    "Sweeping story",
    "Sweeping",
    "On a grand scale",
    "Homeric genre",
    "Like some battles",
    "Sweeping",
    "Of sweeping proportions",
    "Cast-of-thousands movie",
    "Huge in scope",
    "The \"Odyssey\" or \"Beowulf\"",
    "\"Beowulf,\" for one",
    "\"Beowulf,\" e.g.",
    "Grand",
    "It might have a lot of extras",
    "Grand",
    "\"Paradise Lost,\" e.g.",
    "\"Beowulf,\" e.g.",
    "Ambitious in scope",
    "\"The Divine Comedy,\" for one",
    "DeMille output",
    "Cast-of-thousands film",
    "Like \"Paradise Lost\"",
    "Sweeping",
    "Larger-than-life",
    "Many a sword-and-sandals film",
    "Anthony Mann's \"The Fall of the Roman Empire,\" e.g.",
    "\"Cleopatra,\" for one",
    "Huge",
    "Huge",
    "Cast-of-thousands film",
    "Movie best seen on a wide screen",
    "Grand",
    "Grander than grand",
    "Long tale",
    "Bigger than big",
    "Cast-of-thousands",
    "Quite a story",
    "Like ?ôThe Lord of the Rings?ö",
    "Grist for DeMille?",
    "Michener's \"Centennial,\" e.g.",
    "Hours-long film, perhaps",
    "DeMille production, e.g.",
    "No short story",
    "\"Ben-Hur,\" for one",
    "\"Beowulf,\" e.g.",
    "Homer's \"Iliad,\" e.g.",
    "Heroic saga",
    "Big story",
    "\"Paradise Lost,\" e.g.",
    "Majestic poem",
    "The \"Iliad\" or \"Odyssey\"",
    "\"Lawrence of Arabia,\" for one",
    "Michael Jackson's record label",
    "Many a Cecil B. DeMille film",
    "Grander than grand",
    "\"The Ten Commandments,\" for one",
    "Heroic tale",
    "Big production",
    "Large-scale",
    "\"Beowulf,\" for one",
    "\"Titanic,\" e.g.",
    "Like some proportions",
    "Like many a DeMille film",
    "Colossal, filmwise",
    "Grand in scale",
    "Like \"Star Wars\"",
    "Like some struggles",
    "Vast",
    "Cast-of-thousands film",
    "\"Ben-Hur,\" e.g.",
    "Sweeping story",
    "Many a DeMille film",
    "\"Ulysses,\" for one",
    "Like some proportions",
    "Many a miniseries",
    "Sweeping",
    "Larger-than-life",
    "DeMille-type film",
    "Multigenerational story",
    "\"Gandhi,\" for one",
    "Big story",
    "Sword-and-sandal flick",
    "Colossal",
    "Kind of proportions",
    "Majestic",
    "Like the \"Iliad\" and \"Odyssey\"",
    "Grand, as an adventure",
    "\"Iliad\" or \"Aeneid,\" e.g.",
    "Sweeping",
    "Many a Cecil B. De Mille film",
    "Grand",
    "Kind of proportions",
    "\"Beowulf,\" for one",
    "\"War and Peace,\" e.g.",
    "Monumental",
    "Quite a story",
    "Stupendous",
    "Of enormous import",
    "Kind of proportions",
    "Miniseries, maybe",
    "Grand",
    "Big story",
    "Like some proportions",
    "\"Paradise Lost,\" e.g.",
    "Grand saga",
    "\"Iliad,\" e.g.",
    "Cecil B. DeMille work",
    "\"Iliad,\" e.g.",
    "Not just an ordinary novel",
    "Miniseries, maybe",
    "Sweeping story",
    "Grand story",
    "Vast",
    "Of majestic proportion",
    "Hero's tale",
    "DeMille genre",
    "Of great size",
    "Like Homer's \"Iliad\"",
    "Of grand proportions",
    "\"Paradise Lost,\" for one",
    "\"Iliad,\" e.g."
  ],
  "gram": [
    "Weight of a paper clip, roughly",
    "About 15 1/2 grains",
    "Nutrition label unit",
    "Unit of fat",
    "\"g,\" to a chemist",
    "Just under half a penny's weight",
    "Fat unit",
    "Elderly relative, informally",
    "See 67-Across",
    "Small metric weight",
    "Fat unit",
    "Unit of fat",
    "Fat unit",
    "Metric weight",
    "Protein unit",
    "Tiny weight",
    "About 15 grains",
    "Unit of fat",
    "Chemist's amount",
    "Tiny weight unit",
    "15.432 grains",
    "A little fat",
    "The \"G\" in EKG",
    ".035 ounce",
    "Medicinal weight",
    "Ending with cable or candy",
    "Unit of fat",
    "Lab weight",
    "Family member, for short",
    "Mom's mom",
    "Dieter's measure"
  ],
  "rare": [
    "Half-baked?",
    "Word with earth or bird",
    "Happening once in a blue moon",
    "Red, as a steak",
    "Unusual",
    "Hardly to be had",
    "Numismatic classification",
    "Like snow leopards and Siberian tigers",
    "Hard to find",
    "Like shark attacks",
    "\"Still mooing\"",
    "Like royal flushes",
    "Pink or close to it",
    "Like getting a $2 bill in change",
    "Like a blue moon",
    "Like royal flushes",
    "Like blue moons",
    "Few and far between",
    "Few and far between",
    "Unusual",
    "Steakhouse specification",
    "Like albino alligators",
    "Like a steak that's \"still mooing\"",
    "Like signatures of William Shakespeare",
    "Still red in the middle",
    "Scarce",
    "Like $10 gold eagle coins",
    "Steak order",
    "Like black sheep",
    "Hard to get hold of",
    "Like white panthers",
    "Like albinism",
    "Like walk-off touchdowns",
    "Atypical",
    "Like tritium and triplets",
    "Like N.F.L. games ending in a tie",
    "Like Inverted Jenny postage stamps",
    "Not medium or well done",
    "Like black rhinos",
    "Steak specification",
    "Like blue moons",
    "\"On the hoof,\" in diner lingo",
    "Still quite red",
    "Tag in some dictionary definitions",
    "Still red, say",
    "Like a 1913 Liberty Head nickel",
    "Steak request",
    "Like triple plays",
    "Like first editions, often",
    "Like years evenly divisible by four that are not leap years",
    "Of interest to a collector, say",
    "Not well, say",
    "___ bird",
    "Atypical",
    "Numismatist's classification",
    "Description on many eBay listings",
    "Steakhouse order",
    "Like hurricanes in January",
    "Not found in many stamp collections, say",
    "Opposite of well done",
    "Like a sighting of an ivory-billed woodpecker",
    "Like a day in June, according to Lowell",
    "Kind of form",
    "In ___ form",
    "\"Bloody\"",
    "Thin",
    "One-in-a-million",
    "Like a four-leaf clover",
    "\"Still mooing,\" as burgers go",
    "Seldom seen",
    "Like a blue lobster",
    "Hard to come by",
    "With 43-Across, yttrium or scandium",
    "Meat request",
    "\"Bloody\"",
    "Like a total solar eclipse",
    "Burger order specification",
    "Like no-hitters",
    "Still red, as a steak",
    "Opposite of well done",
    "In short supply",
    "Exceptional",
    "\"Still mooing\"",
    "Red inside",
    "Porterhouse order",
    "Like some gases",
    "Like hen's teeth",
    "Like proverbial hen's teeth",
    "Of particular interest to a completist",
    "Once-in-a-blue-moon",
    "Pink, maybe",
    "Like a 1943 copper penny",
    "Like a Liberty gold coin",
    "Exceptional",
    "Burger order",
    "Bloody, so to speak",
    "Like a perfect game",
    "Label in a bibliophile's catalog",
    "Few and far between",
    "Pink inside",
    "Bloody, so to speak",
    "Practically unheard-of",
    "Red in the middle",
    "Like white elephants",
    "Few and far between",
    "Barely cooked",
    "Thin",
    "Red in the middle",
    "Like an ivory-billed woodpecker",
    "Once-in-a-blue-moon",
    "Numismatist's classification",
    "Still red inside",
    "Pink, say",
    "Uncommon",
    "Like one in a million",
    "Seldom seen",
    "Opposite of well done",
    "\"Bloody\"",
    "With 17-Across, one in a million",
    "Uncommon",
    "\"For the ___ and radiant maiden\": Poe",
    "\"Suddenly, as ___ things will, it vanished\": E. B. Browning",
    "___ as a blue rose",
    "Exceptional",
    "Like a white Bengal tiger",
    "Not turning up much",
    "Seen once in a blue moon",
    "Endangered",
    "Pretty pink",
    "Nearly unique in the world",
    "Coin rating",
    "Unwonted",
    "Coin classification",
    "Choice",
    "Reddish pink",
    "Like a blue rose",
    "Like a 52-Across",
    "Seldom seen",
    "Reddish",
    "\"___ as a winter swallow\": Balzac",
    "Like a compliant cat",
    "Scattered",
    "Like hen's teeth",
    "Coin grade",
    "Still pink",
    "Steak order",
    "Hard to find",
    "Medium-___",
    "Like hen's teeth",
    "Unusually excellent",
    "Infrequent",
    "Uncommonly good",
    "Facing extinction",
    "Like a 1943 copper penny",
    "Of interest to collectors, say",
    "Choice",
    "One-in-a-million",
    "Bibliophile's label",
    "Hard to find",
    "Seldom seen",
    "Reddish",
    "Pinkish",
    "Hard to find",
    "Reddish, perhaps",
    "Not common",
    "Pink, as a steak",
    "Exceptional",
    "Hardly seen",
    "Steak order",
    "57-Down request",
    "Pinkish",
    "Like some coins",
    "Steak order",
    "Like a no-hitter",
    "Seldom seen",
    "Pink",
    "Dictionary notation",
    "Not well?",
    "Uncommon",
    "Seldom seen",
    "Unusually excellent",
    "Pinkish, as a steak",
    "Barely done",
    "Stamp designation",
    "Book classification",
    "Pink, so to speak",
    "Undone?",
    "Hard to come by",
    "Pink, as a steak",
    "Like hen's teeth",
    "Lightly cooked",
    "Superfine",
    "Scarce",
    "Seldom seen",
    "Recherche",
    "Not easy to find",
    "Scarce",
    "Opposite of well",
    "Roast beef request",
    "Steak order",
    "Red in the middle",
    "Seldom seen",
    "Bloody, so to speak",
    "Exceptional",
    "On the hoof, in lunch counter lingo",
    "Almost red",
    "Scarce",
    "Recherche",
    "Antique description",
    "Seldom seen",
    "Barbecue order",
    "Infrequent",
    "Steak order",
    "Exceptional",
    "One-in-a- million",
    "Pink, as steak",
    "Steak order",
    "\"So ___\" (Jimmy Dorsey hit)",
    "Atypical",
    "Uncommon",
    "Like praseodymium",
    "Sought by bibliomaniacs",
    "Seldom seen",
    "Steak order",
    "Recherche",
    "Steak order"
  ],
  "smog": [
    "City hazard",
    "Overhead cost of manufacturing?",
    "Polluted air",
    "It's hardly a breath of fresh air",
    "Air apparent?",
    "Reason for a bad air day?",
    "Result of atmospheric inversion",
    "Skyline-obscuring pollution",
    "City choker",
    "Unwanted blanket",
    "Urban portmanteau",
    "Environmental concern",
    "Pollution from car emissions, in part",
    "Urban air pollution",
    "Environmental bane",
    "It's hardly a breath of fresh air",
    "Airborne urban hazard",
    "City haze",
    "Beijing problem",
    "Blanket produced in Mexico City",
    "Mexico City sight",
    "Dangerous blanket",
    "What can take your breath away in L.A.?",
    "Suffocating blanket",
    "Air apparent?",
    "Sun blocker",
    "Urban haze",
    "Environmental portmanteau",
    "Unwanted cloud",
    "Factor in an air quality rating",
    "Urban woe",
    "Subject of an exhaustive E.P.A. study?",
    "Urban pollution",
    "Urban woe",
    "Pollution that may sting the eyes",
    "Metropolitan hangover?",
    "Cough cause",
    "Eye irritant",
    "Air apparent?",
    "Cause of a bad air day?",
    "Gray blanket",
    "Air that makes you go [cough, cough]",
    "Air condition",
    "Cause of some urban coughs",
    "Possible cause of heavy breathing",
    "Kind of alert",
    "Urban pollution",
    "View blocker",
    "Big city problem",
    "It might make you short of breath",
    "It might hang over your head",
    "Urban haze",
    "Haze",
    "Clean Air Act target",
    "Urban haze",
    "Urban problem",
    "Cause of an air alert",
    "View disrupter",
    "It may be difficult to drive in",
    "Grimy air",
    "Unpleasant hangover?",
    "Scenery spoiler",
    "Target of clean air laws",
    "Portmanteau pollution mixture",
    "E.P.A. concern",
    "Los Angeles woe",
    "E.P.A. concern",
    "Air pollution",
    "Air apparent?",
    "Alert subject",
    "Some urban air",
    "Visibility reducer",
    "Urban blight",
    "Kind of alert",
    "Urban haze",
    "Urban haze",
    "Effect of auto exhaust",
    "Kind of alert",
    "City blight",
    "Air pollution",
    "Unwelcome cloud",
    "Kind of alert",
    "Health hazard",
    "Air apparent?",
    "Unhealthy air",
    "Urban problem",
    "Choking cause",
    "Urban miasma"
  ],
  "errs": [
    "Messes up",
    "Slips",
    "Flubs it",
    "Misses the mark",
    "Blunders",
    "Makes a boo-boo",
    "Blunders",
    "Chokes, say",
    "Makes a typo, say",
    "Goes off",
    "Slips",
    "Messes up",
    "Writes \"millenium\" or \"accomodate,\" e.g.",
    "Goes wrong",
    "Goes wrong",
    "Flubs it",
    "Goofs up",
    "Makes a boo-boo",
    "Misunderstands, e.g.",
    "Fumbles",
    "Goes amiss",
    "Goes off course",
    "Slips",
    "Messes up",
    "Misreckons",
    "Messes up",
    "Slips up",
    "Messes up",
    "Flubs",
    "Goofs",
    "Drops the ball",
    "Slips",
    "Is off",
    "Bungles",
    "Goofs",
    "Makes a boo-boo",
    "Does wrong",
    "Boots one",
    "Stumbles",
    "Slips up",
    "Needs to make a retraction",
    "Bungles",
    "Blows it",
    "Has things reversed, maybe",
    "Makes a wrong turn",
    "Goes off",
    "Miscalculates",
    "Fouls up",
    "Muffs",
    "Goofs",
    "Slips",
    "Makes mistakes",
    "Makes a misstep",
    "Ends up short, maybe",
    "Blunders",
    "Slips up",
    "Lets a ground ball go through one's legs, say",
    "Blunders",
    "Goes astray",
    "Misunderstands, say",
    "Makes mistakes",
    "Muffs",
    "Makes a bad call",
    "Messes up",
    "Slips up",
    "Slips up",
    "Goes off",
    "Muffs",
    "Makes a boo-boo",
    "Muffs",
    "Goes off",
    "Chooses badly",
    "Makes mistakes",
    "Goes off",
    "Isn't right on",
    "Guesses wrong",
    "Misjudges",
    "Gets it wrong",
    "Slips",
    "Misses the mark",
    "Slips",
    "Slips up",
    "Blunders",
    "Goes off",
    "Drops the ball",
    "Goes off",
    "Misses the mark",
    "Lapses",
    "Screws up",
    "Overthrows first, e.g.",
    "Drops the ball",
    "Transgresses",
    "Transgresses",
    "Blunders",
    "Drops the ball",
    "Blunders",
    "Slips",
    "Barks up the wrong tree",
    "Slips",
    "Botches one",
    "Muffs one",
    "Goofs",
    "Blows it",
    "Misses the mark",
    "Fouls up",
    "Messes up",
    "Blows it",
    "Muffs",
    "Misses a fly, say",
    "Miscalculates",
    "Shows fallibility",
    "Muffs",
    "Goes off",
    "Goes off",
    "Shows one's human side",
    "Flubs",
    "Miscalculates",
    "Drops a brick",
    "Goofs",
    "Goofs",
    "Slips or trips",
    "Transgresses",
    "Slips",
    "Stumbles",
    "Foozles",
    "Flubs",
    "Stumbles",
    "Goes off",
    "Causes an unearned run, perhaps",
    "Puts 2 and 2 together to make 3",
    "Goofs",
    "Goes off, in a way",
    "Goofs",
    "Blows a 4-Down, say",
    "Goes astray",
    "Goofs up",
    "Goes off",
    "Bumbles",
    "Slips",
    "Blows it",
    "Makes a boo-boo",
    "Goofs up",
    "Stumbles",
    "Blows it",
    "Slips",
    "Misses the mark",
    "Flubs",
    "Is guilty of a 28-Across",
    "Does the wrong thing",
    "Stumbles",
    "Sins",
    "Misses the mark",
    "Doesn't call a spade a spade?",
    "Slips",
    "Screws up",
    "Goofs",
    "Goes astray",
    "Commits a faux pas",
    "Pulls a boner",
    "Makes mistakes",
    "Blows it",
    "Muffs",
    "Goofs up",
    "Messes up",
    "Commits a sin",
    "Muffs",
    "Misreckons",
    "Messes up",
    "Takes the primrose path",
    "Goofs",
    "Goes wrong",
    "Misses the mark",
    "Flubs",
    "Goofs",
    "Commits a faux pas",
    "Blunders",
    "Muffs",
    "Sins",
    "Flubs",
    "Does wrong",
    "Muffs",
    "Misses the mark",
    "Is off base",
    "Misreckons",
    "Shows one's humanity",
    "Goofs"
  ],
  "axes": [
    "x, y and z",
    "x and y, on a graph",
    "Chops",
    "x and y, on a graph",
    "Lets go",
    "Forest swingers",
    "Fires",
    "Cans",
    "Choppers",
    "Scraps",
    "Lines of a plane",
    "x, y and z",
    "Plane dividers",
    "x and y, on a graph",
    "x and y, in math",
    "Fires",
    "Lumberjacks' tools",
    "Alternatives to saws",
    "Cans",
    "x, y and z",
    "Gives the heave-ho",
    "Graph lines",
    "Graph lines",
    "Eighty-sixes",
    "Lumberjacking tools",
    "x, y and z, in math",
    "Cans",
    "Lumberjacks' tools",
    "x and y, on a graph",
    "Dungeons & Dragons weapons",
    "Lines on planes",
    "Dumps",
    "Choppers",
    "x, y and z",
    "Cans",
    "Fires",
    "Pink-slips",
    "Graph features",
    "Turning points",
    "Good fellers",
    "X and Y, on a graph",
    "Gets rid of",
    "Cancels",
    "Bounces",
    "Graph lines",
    "Firefighters' tools",
    "They bring things down",
    "x, y and z",
    "Sacks",
    "Quadrant separators",
    "X and Y, maybe",
    "Sacks",
    "Hackers",
    "They may go to blazes",
    "y and z, e.g.",
    "Lets go",
    "x and y",
    "Chopping tools",
    "Gives the heave-ho",
    "Fires",
    "Firefighting equipment",
    "Fires",
    "X and Y on a graph",
    "x, y and z",
    "Lumber camp implements"
  ],
  "claw": [
    "Fight tooth and nail",
    "Talon",
    "End of a hammer",
    "Scratch maker",
    "Fearsome part of a Jabberwock",
    "Hammer feature",
    "Cardinal point?",
    "Part of a crane",
    "Crab's means of defense",
    "Nail",
    "Crayfish feature",
    "Part of a wolf or a lobster",
    "Gripper",
    "Fight tooth and nail",
    "Hammer part",
    "Maul, in a way",
    "Lobster limb",
    "Partner of scratch",
    "Nail",
    "Crab serving",
    "Construction crane attachment",
    "Scratch",
    "Curved nail, perhaps",
    "Animal's nail",
    "Cat's gripper",
    "Itch reliever",
    "Hammer's end",
    "Cat's scratcher",
    "Nail on a paw",
    "Hammer part",
    "Nail puller",
    "Lobster portion",
    "Manhandle",
    "Dig sans tools",
    "Clutcher",
    "Hammer feature",
    "Scratch",
    "Falcon feature",
    "Cat's scratcher",
    "Bear's scratcher",
    "Lobster pincer",
    "Talon",
    "Talon",
    "Hammer part",
    "Eagle feature",
    "Hammer part"
  ],
  "ragu": [
    "Pasta topper",
    "Pasta topper",
    "Prego alternative",
    "Pasta sauce brand",
    "Alternative to Prego",
    "Big name in pasta sauce",
    "___ alla bolognese (meat-based pasta sauce)",
    "Italian word with a grave accent that becomes a brand name with an acute accent",
    "Pasta sauce brand",
    "Brand of 33-Down",
    "Big name in pasta sauce",
    "\"Old World Style\" pasta sauce brand",
    "Alfredo sauce brand",
    "Italian-style sauce brand",
    "Pasta sauce \"trusted since 1937\"",
    "Prego alternative",
    "Brand with \"Old World Style\"",
    "Prego competitor",
    "Classico competitor",
    "Old World Style sauce brand",
    "Sauce brand since 1937",
    "Prego competitor",
    "Pasta sauce brand",
    "Sauce brand",
    "Sauce brand",
    "Pasta sauce brand",
    "Prego competitor",
    "Classico rival",
    "___ Bolognese",
    "Sauce brand",
    "Alfredo sauce brand",
    "Prego alternative",
    "\"Mama's Special Garden\" brand",
    "Sauce that's made \"Old World Style\"",
    "Sauce brand",
    "Giant in pasta sauce",
    "Prego competitor",
    "Sauce maker",
    "Unilever brand",
    "Pasta sauce first sold in 1937",
    "Brand name with an accent on its last letter",
    "Classico competitor",
    "Jarful for Italian cuisine",
    "Sauce brand",
    "Prego competitor",
    "Prego competitor",
    "Prego rival",
    "Brand for a pasta lover",
    "Maker of Robusto! sauce",
    "Sauce brand",
    "Spaghetti sauce brand",
    "Pasta sauce maker",
    "It has 11 \"Robusto!\" flavors",
    "Prego competitor",
    "Brand of spaghetti sauce",
    "Brand of sauce",
    "Newman's Own competitor",
    "Lipton, Inc. brand",
    "Sauce brand",
    "Prego competitor",
    "Prego competitor",
    "Brand name with an accent on it",
    "Prego rival",
    "Prego rival",
    "Sauce brand",
    "Pasta sauce brand",
    "Popular sauce",
    "Popular sauce",
    "Prego competitor",
    "Brand of sauce",
    "Certain sauce",
    "Spaghetti sauce brand",
    "Brand that offers \"Chunky\" style",
    "Prego competitor",
    "Sauce brand",
    "Saucy name?",
    "Aunt Millie's competitor",
    "Prego competitor",
    "Francesco Rinaldi competitor"
  ],
  "lift": [
    "Pump some weights",
    "Hitchhiker's need",
    "Elevator, in England",
    "Follower of face or fork",
    "Elevate",
    "Ride",
    "Connector of English stories",
    "Hoist",
    "Elevate",
    "Pick-me-up",
    "Morale booster",
    "Boost",
    "Brit's elevator",
    "Good news on a gloomy day, e.g.",
    "Boost",
    "Good feeling",
    "Pick up",
    "See 83-Down",
    "Plagiarize",
    "See 61-Down",
    "Pick-me-up",
    "Ride",
    "London elevator",
    "Elevator, in Exeter",
    "Help for a pedestrian",
    "London elevator"
  ],
  "chad": [
    "Lake ___, where the Chari River empties",
    "Land south of Libya",
    "Land between Nigeria and Sudan",
    "Ballot hanger",
    "Neighbor of Sudan",
    "African land whose capital is N'Djamena",
    "See 45-Across",
    "Fallout from the 2000 election?",
    "Country between Sudan and Niger",
    "Something left hanging in an election?",
    "Neighbor of Niger",
    "Waste of a vote?",
    "Landlocked African land",
    "Africa's Lake ___, body of water in four countries",
    "One may be left hanging",
    "2000 election scrap",
    "Paper ballot punch-out",
    "Central Africa's Lake ___",
    "Punched-out part of a paper ballot",
    "One left hanging after an election?",
    "Famed political hanger-on",
    "Neighbor of Niger",
    "Something that shouldn't be left hanging",
    "Election hanger-on?",
    "Neighbor of Niger",
    "One that's punched out",
    "Part of a punch ballot",
    "Hanger-on at the polls?",
    "It may have a dimple",
    "Punch card fallout",
    "Data card debris",
    "Voting booth hanger-on?",
    "Something on which an election hangs?",
    "Its capital is N'Djamena",
    "Neighbor of Cameroon",
    "McQueen of movies",
    "Neighbor of Sudan",
    "Neighbor of Libya",
    "Neighbor of Sudan",
    "Jeremy's singing partner",
    "Neighbor of Nigeria",
    "Country or its largest lake",
    "Lake in Africa",
    "Neighbor of Libya",
    "Nigerian border lake",
    "African lake"
  ],
  "dees": [
    "Old-fashioned endings?",
    "Poor grades",
    "Diamond brackets?",
    "Middle's middle?",
    "Two of diamonds?",
    "Discord on the far left and far right?",
    "Odd couple?",
    "Barely passing grades",
    "Couple of buddies?",
    "Pretty low grades",
    "*Poor grades",
    "Subpar grades",
    "Dead ends?",
    "Wedding couple?",
    "Rick with the 1976 #1 hit \"Disco Duck\"",
    "Almost-failing grades",
    "Low grades",
    "They're worth 1.0",
    "G.P.A. spoilers",
    "Rick with the 1976 #1 hit \"Disco Duck\"",
    "Honor society no-nos",
    "G.P.A. spoilers",
    "Radio Hall-of-Famer Rick",
    "Poor marks",
    "Barely passing grades",
    "Crummy grades",
    "Scrabble 2-pointers",
    "Bad marks",
    "Stinky grades",
    "Bad marks",
    "Fiddle duet?",
    "Detached ends?",
    "Two of diamonds?",
    "Odd couple?",
    "Rick who sang \"Disco Duck\"",
    "Ruby and Sandra",
    "Reason for school probation, maybe",
    "Poor marks",
    "G.P.A. spoilers",
    "Poor grades",
    "Odd couple?",
    "Low marks",
    "Two Scottish rivers",
    "Barely passing grades",
    "School passes?",
    "Low marks",
    "TV host Rick",
    "Barely passing marks",
    "Not grades to brag about",
    "Bad grades",
    "Grades below the curve"
  ],
  "nine": [
    "Ball with a yellow stripe",
    "The sum of the digits of any multiple of ___ is a multiple of ___ (arithmetic curiosity)",
    "16-Across squared",
    "Point value commonly assigned to a queen in chess",
    "Number that, in Chinese languages, is a homophone for \"longevity,\" and is thus considered good luck",
    "Start of every California ZIP code",
    "Yellow-striped ball",
    "Number of M.V.P. awards won by Wayne Gretzky",
    "Score for a bull's-eye in archery",
    "Tony-winning musical based on Fellini's \"8 1/2\"",
    "___ lives",
    "CB channel for emergency use",
    "Value not appearing on any Scrabble tile",
    "Number of worlds connected by Yggdrasil in Norse myth",
    "See 26-Down",
    "Highest hand value in baccarat",
    "Small square",
    "Number of batters in a lineup",
    "Soccer striker's jersey number, traditionally",
    "Highest score in baccarat",
    "Fluorine's atomic number",
    "CB radio emergency channel",
    "This puzzle's theme",
    "Square after four",
    "Square after four",
    "Musical based on Fellini's \"8 1/2\"",
    "Highest score in baccarat",
    "Highest figure in sudoku",
    "Rock's ___ Inch Nails",
    "Best hand value in baccarat",
    "Number of heads of the Hydra, in myth",
    "Number of holes in a half-round of golf",
    "Ball with a yellow stripe",
    "Alaska ZIP code starter",
    "Near-perfect rating",
    "Lucky figure in Chinese culture",
    "Number of \"lives\" a cat has",
    "Upside-down six",
    "See 122-Across",
    "West Coast ZIP starter",
    "Start of a call for help",
    "\"The ___ Tailors,\" Dorothy L. Sayers mystery",
    "Musical with the song \"Be Italian\"",
    "Cost in dollars of the world's first TV ad in 1941",
    "WXY, on a phone",
    "Cloud ___",
    "Complement of Dante's circles of hell",
    "Yellow-striped ball",
    "Round of golf, informally",
    "Number of Muses",
    "Right fielder, on a scorecard",
    "Roger Maris, for the Yankees",
    "Highest score in baccarat",
    "Clementine's shoe size",
    "Almost perfect?",
    "*Start of a 38-Across",
    "Last digit in a price, often",
    "Ball with a yellow stripe",
    "Fine diving score",
    "It's a square",
    "Supreme Court count",
    "Three's opposite on a clock face",
    "Quick outing on the links",
    "Low square",
    "Punch-in time for many",
    "Musical based on a Fellini film",
    "A round of golf, informally",
    "U.S. highway with a ferry connection between Delaware and New Jersey",
    "Start of a critical call",
    "Low square",
    "First digit in a California ZIP code",
    "Full complement of baseball players",
    "Small square",
    "Excellent gymnastics score",
    "Yellow-striped ball in pool",
    "Highest score in baccarat",
    "Shortest iron, in golf",
    "Roger Maris's number",
    "Diamond complement",
    "See 25-Down",
    "Store opening time",
    "Diamond complement",
    "\"The Whole ___ Yards\"",
    "Prime-time time",
    "Reindeer team, counting Rudolph",
    "Almost perfect rating",
    "A perfect square",
    "Muses count",
    "Salinger's \"___ Stories\"",
    "Number dialed before two ones",
    "Musical with the song \"Be Italian\"",
    "With 5- and 10-Across, need for 69-, 70- and 71-Across",
    "Kind of iron",
    "Ted Williams wore it",
    "Cloud ___",
    "Pretty high rating",
    "1982 Tony musical",
    "Cardinal with a loop",
    "Shop-opening time",
    "Supreme Court count",
    "Shift start, for many",
    "Opening time, maybe",
    "Opening time, maybe",
    "Opening time, maybe",
    "Target number in baccarat",
    "Five after four",
    "Prime-time hour",
    "Mozart's age, when he wrote his Fifth Symphony",
    "Lowest card in pinochle",
    "Emergency CB channel",
    "Prime time hour",
    "Excellent-plus rating",
    "Supreme Court count",
    "See left",
    "Common opening time",
    "Emergency CB channel",
    "Prime time hour",
    "Ted Williams wore it",
    "Inches in a span",
    "Best hand in baccarat",
    "Baseball squad",
    "Number of Beethoven symphonies",
    "Full complement of planets",
    "Emergency CB channel",
    "High gymnastic score",
    "Supreme Court complement",
    "Diamond digit",
    "Supreme Court complement",
    "Diamond group",
    "1982 Tommy Tune show",
    "Not quite Bo Derek"
  ],
  "dion": [
    "One-named singer with the 1968 hit \"Abraham, Martin and John\"",
    "Grammy winner for \"My Heart Will Go On\"",
    "\"Lovers Who Wander\" singer, 1962",
    "Best-selling Canadian singer of all time (with 200+ million record sales)",
    "One-named hitmaker of the 1950s-'60s",
    "Singer Celine",
    "\"Runaround Sue\" singer, 1961",
    "The Belmonts' frontman, in 1950s music",
    "\"Little Diane\" singer, 1962",
    "\"Ruby Baby\" singer, 1963",
    "Singer of the 1962 hit \"The Wanderer\"",
    "Singer Celine",
    "\"Runaround Sue\" singer",
    "\"The Wanderer\" singer, 1961",
    "\"Ruby Baby\" singer, 1963",
    "\"Titanic\" soundtrack singer",
    "1960's singer with the Del Satins",
    "\"My Heart Will Go On\" singer",
    "Singer Celine",
    "Lead singer for the Belmonts",
    "Pop's Celine ___",
    "\"A Teenager in Love\" singer",
    "\"Runaround Sue\" singer"
  ],
  "hans": [
    "Composer Zimmer",
    "Prince in \"Frozen\"",
    "First name in fairy tales",
    "Prince in \"Frozen\"",
    "Composer Zimmer with four Grammys",
    "Prince in \"Frozen\"",
    "Prince in \"Frozen\"",
    "Franz's partner in old \"S.N.L.\" sketches",
    "A Katzenjammer kid",
    "___ Zimmer, Oscar-winning composer for \"The Lion King\"",
    "Weapons inspector Blix",
    "Geiger of Geiger counter fame",
    "Eponymous Dr. Asperger",
    "Brinker on skates",
    "Brinker of children's lit",
    "Brinker with storied skates",
    "Geiger of counter fame",
    "Geiger with a counter",
    "Geiger of Geiger counter fame",
    "One of the Katzenjammer Kids",
    "___ Christian Andersen",
    "Counter inventor Geiger",
    "___ Christian Andersen",
    "Brinker with silver skates",
    "Geiger of Geiger counter fame",
    "\"The Captain and the Kids\" kid",
    "Geiger of Geiger counter fame",
    "Storied boy with silver skates",
    "Renaissance artist Memling",
    "First name in Danish literature",
    "First name in fairy tales",
    "Silver skates boy",
    "Skater Brinker"
  ],
  "risk": [
    "Take a ___",
    "\"The Game of Strategic Conquest\"",
    "Reward seeker's concern",
    "Put at stake",
    "Game with 42 territory cards",
    "Gamble",
    "Word after flight or credit",
    "Game played on a map",
    "Important consideration for investors",
    "Hasbro board game in which armies conquer territories",
    "Trusting someone you don't know, e.g.",
    "Chance",
    "Jeopardize",
    "Lender's assessment",
    "Decision theory factor",
    "Game played across the world",
    "Global conquest board game",
    "Game played on a world map",
    "Hazard",
    "Calculated thing",
    "Jeopardy",
    "Put on the line",
    "Jeopardy",
    "Gamble",
    "Lay on the line",
    "Popular U.S. board game since 1959",
    "Underwriter's assessment",
    "Classic board game",
    "Insurer's calculation",
    "Hazard",
    "Take a chance",
    "Uncertainty",
    "It may be calculated",
    "Hazard",
    "Classic Parker Brothers game",
    "Insurer?Æs focus",
    "Exposure",
    "Daredevil's desire",
    "Insurer's calculation",
    "Thrill-seeker's love",
    "Kind of capital",
    "Capitalist's concern",
    "Take a chance on",
    "Underwriter's concern",
    "Put on the line",
    "Take a flier",
    "Insurance agent's calculation",
    "Peril"
  ],
  "bets": [
    "Puts down a few chips, maybe",
    "Doesn't check, say",
    "Stakes",
    "Casino actions",
    "Wagers",
    "Wagers",
    "Agrees to \"make things interesting\"",
    "Odds and evens, say",
    "Risks",
    "Trackside transactions",
    "Wagers",
    "Wagers",
    "Things get crazy when all of them are off",
    "Picks",
    "Action in FanDuel and DraftKings",
    "Pot growers?",
    "Parlays",
    "Over and under, e.g.",
    "Things that may all be off",
    "Book deals?",
    "They're placed in Vegas",
    "Risks",
    "All of these may be off",
    "\"All ___ are off!\"",
    "They're often taken on horses",
    "Lays it on the line?",
    "Gets in the pool, say",
    "All of them may be off",
    "Takes a risk",
    "Stakes",
    "Red and black, perhaps",
    "Stakes",
    "Track action",
    "Pit transactions",
    "\"All ___ are off!\"",
    "Actions at chuck-a-luck",
    "Gaming table stacks",
    "Plays the ponies",
    "Handicappers' actions",
    "\"All ___ are off!\"",
    "\"Rouge\" and \"noir\"",
    "Risks",
    "\"All ___ are off\"",
    "Odd and even, at times",
    "Derby doings",
    "They may all be off",
    "Track records?",
    "Bookie's bookings",
    "Pot contents",
    "Wagers",
    "Things to be hedged"
  ],
  "slip": [
    "Piece of intimate wear",
    "___ of the tongue",
    "___ of the tongue",
    "Yacht spot",
    "Berth place",
    "Lose one's footing",
    "Prelude to a fall",
    "Spot for a yacht",
    "Lose one's footing",
    "Word with pink or cow",
    "Get worse",
    "Make a mistake",
    "Start to lose it",
    "Boo-boo",
    "Elude a person's grasp",
    "Lose one's grip",
    "Freudian mistake",
    "Berth",
    "Erratum",
    "Lose one's footing",
    "Boo-boo",
    "Bobble",
    "Tiny misstep",
    "Victoria's Secret purchase",
    "Blow it",
    "Faux pas",
    "Err",
    "Break one's resolution, say",
    "Sales receipt",
    "Fall preceder",
    "Yacht spot",
    "Faux pas",
    "Goof",
    "Boo-boo",
    "Misstep",
    "Get worse",
    "Woman's undergarment",
    "Gymnast's worry",
    "Freudian ___",
    "Faux pas",
    "Falter",
    "Lingerie item",
    "Berth place",
    "Bad thing to have showing",
    "Cricket position behind the wicketkeeper",
    "Cause for a correction",
    "Boo-boo",
    "Boo-boo",
    "Faux pas",
    "Mess-up",
    "Indiscretion",
    "It's bad when it's pink",
    "Lingerie item",
    "Berth place",
    "Boo-boo",
    "Mountaineer's worry",
    "Ice hazard",
    "Erratum",
    "Boo-boo",
    "Faux pas",
    "Boo-boo",
    "Accident on ice",
    "Underskirt",
    "Space for a ship",
    "Faux pas",
    "Underdress",
    "Marina space",
    "Boo-boo",
    "Decline"
  ],
  "skew": [
    "Lean",
    "Distort",
    "Distort",
    "Make biased",
    "Twist",
    "Twist",
    "Distort",
    "Tilt",
    "Distort",
    "Distort",
    "Distort",
    "Distort, as data",
    "Distort",
    "Polling bias",
    "Slant",
    "Distort",
    "Twist",
    "Twist",
    "Twist, as polling results",
    "Slant",
    "Twist, as facts",
    "Slant",
    "Twist",
    "Slant",
    "Distort",
    "Twist",
    "Apply spin to",
    "Distort",
    "Distort, as data",
    "Show unfairly",
    "Twist, as findings",
    "Bias",
    "Bias",
    "Bias",
    "Distort",
    "Twist",
    "Throw off",
    "Bias",
    "Warp",
    "Distort",
    "Misrepresent",
    "Place on an angle",
    "Slant unfairly",
    "Distort",
    "Twist",
    "Bias",
    "Warp",
    "Slant",
    "Misrepresent",
    "Warp",
    "Distort, as survey results",
    "Twist",
    "Distort",
    "Depict unfairly",
    "Slant",
    "Turn obliquely",
    "Swerve"
  ],
  "have": [
    "Choose from the restaurant menu",
    "Possess",
    "Own",
    "\"I ___ a dream ...\"",
    "Give birth to",
    "Someone who's well-off",
    "Eat",
    "\"What ___ we here?\"",
    "\"Girls Just Want to ___ Fun\"",
    "Be down with",
    "Consume",
    "Possess",
    "Billionaire, for one",
    "Eat or drink",
    "Eat",
    "Suffer from",
    "Privileged one",
    "Possess",
    "Own",
    "Experience",
    "Undergo",
    "Eat",
    "Suffer from",
    "\"What will you ___?\"",
    "Maintain",
    "Own",
    "One who's well off",
    "Possess",
    "Monied one",
    "See 24-Down",
    "Experience",
    "Keep",
    "Moneyed one",
    "Suffer from",
    "Eat or drink",
    "Wealthy one",
    "Be bound (to)",
    "Rich one",
    "Give birth to",
    "Give birth to",
    "Suffer from",
    "Moneyed one",
    "Include",
    "Moneyed one",
    "Include",
    "One of the wealthy",
    "Comprise",
    "Be laid up with",
    "Experience",
    "Own",
    "Possess",
    "Be afflicted with",
    "Possess",
    "Possess",
    "Contain",
    "Own",
    "Possess",
    "\"___ a nice day!\"",
    "Wealthy person",
    "Wealthy person"
  ],
  "sikh": [
    "Literally, \"disciple\"",
    "Many a Punjabi",
    "Believer in the Five Thieves (lust, wrath, greed, attachment and ego)",
    "Guru, maybe",
    "Guru, maybe",
    "Many a Punjabi",
    "Punjabi for \"disciple\"",
    "Turbaned believer",
    "One of an Indian minority",
    "Guru's disciple, maybe",
    "Worshiper in a temple",
    "Guru follower",
    "Turban wearer",
    "Many a Punjabi",
    "Many a turban wearer",
    "Many a Punjabi",
    "Punjabi believer",
    "Literally, \"disciple\"",
    "Believer with a turban",
    "Religious person with a turban",
    "Punjabi believer",
    "Punjabi believer"
  ],
  "rube": [
    "Clodhopper",
    "Naive sort",
    "Naive sort",
    "Yahoo",
    "Carny's target",
    "Unsophisticated sort",
    "No sophisticate",
    "Simpleton",
    "Con artist's mark",
    "Hayseed",
    "Bumpkin",
    "Potential sucker",
    "Potential sucker",
    "Rustic",
    "Unsophisticated sort",
    "Any of TV's Clampetts",
    "Country cousin",
    "Hick",
    "Sticks figure",
    "Rustic",
    "\"Hee Haw\" character",
    "Hardly a sophisticate",
    "Unsophisticated sort",
    "Bumpkin",
    "Hick",
    "Sticks figure",
    "Yokel",
    "Country bumpkin",
    "Hick",
    "Country bumpkin",
    "Yokel",
    "Rustic",
    "Hick",
    "Hick",
    "Hayseed",
    "\"Mike and Ike\" creator Goldberg",
    "Hick",
    "Bumpkin",
    "Rustic"
  ],
  "phew": [
    "Cry of relief",
    "\"That was a close one!\"",
    "Expression of relief",
    "\"Glad that's over!\"",
    "\"Close call!\"",
    "\"I'm exhausted!\"",
    "[What a relief!]",
    "\"That wasn't easy at all!\"",
    "Sigh of relief",
    "\"Close one!\"",
    "\"What a relief!\"",
    "\"Glad that's done!\"",
    "\"That was sure close!\"",
    "\"That was a close one!\"",
    "\"That was close!\"",
    "\"Close call!\"",
    "\"So that's done!\"",
    "\"What a relief!\"",
    "\"Man, that was close!\"",
    "Exclamation after a workout",
    "\"They almost got me!\"",
    "\"I'm glad THAT's over!\"",
    "\"What a relief!\"",
    "\"That was close!\"",
    "\"What a relief!\"",
    "\"That stinks!\"",
    "\"That was close!\"",
    "\"Boy, am I tired!\"",
    "\"That was close!\"",
    "\"That was close!\"",
    "\"That was close!\"",
    "Piano mover's cry",
    "\"Boy, that was close!\"",
    "\"Boy, is it hot!\"",
    "\"That was a close one!\"",
    "\"That was close!\"",
    "\"That was close!\"",
    "\"That was close!\"",
    "Sound of relief"
  ],
  "verb": [
    "Rain or shine",
    "Almost any word ending in -ize",
    "Noun go-with",
    "What fish or chicken can be, but not turkey",
    "Win, lose or draw",
    "Sink or swim, e.g.",
    "Make or break, e.g.",
    "Hop, skip or jump",
    "What \"is\" is",
    "Shake, rattle or roll",
    "Subject follower",
    "Do or die",
    "It might be irregular",
    "Trick or treat, e.g.",
    "Make or break",
    "Hop, skip or jump",
    "\"Make\" or \"break\"",
    "Do or die",
    "Give or take",
    "Make or break",
    "Hit or miss",
    "Hop, skip or jump",
    "Sink or swim, e.g.",
    "5-Down, for example",
    "Sink or swim, e.g.",
    "Action word",
    "Action word",
    "Make or break",
    "Make or break, e.g."
  ],
  "peel": [
    "Fibrous part of a potato",
    "Banana leftover",
    "Zest",
    "Source of zest",
    "Strip",
    "Spa offering",
    "Spa option",
    "Hightail it away, with \"out\"",
    "Spa treatment",
    "Get over a sunburn, maybe",
    "Something you might slip on",
    "Spa treatment",
    "Rind",
    "Lose a tan, say",
    "Exfoliation",
    "Skin treatment at a spa",
    "Rind",
    "Spa treatment",
    "Result of a burn",
    "Bare it all",
    "Banana waste",
    "Day spa treatment",
    "Orange exterior",
    "See 54-Down",
    "Strip",
    "Plastic surgeon's procedure",
    "Emma of \"The Avengers\"",
    "Suffer from sunburn",
    "Leave rubber, with \"out\"",
    "Marmalade component",
    "Zest",
    "Skin",
    "Result of a day at the beach?",
    "Strip",
    "Orange throwaway",
    "Something to slip on?",
    "Banana discard",
    "Stage after a sunburn",
    "Zest",
    "Zest",
    "What strippers do",
    "Floor it, with \"out\"",
    "Leave in a hurry, with \"out\"",
    "Burn rubber, with \"out\"",
    "Outer covering",
    "Something to slip on?",
    "Leave in a hurry, with \"out\"",
    "Banana waste",
    "Debark?",
    "Speed away, with \"out\"",
    "Strip",
    "Part of a lemon",
    "Drink garnish",
    "Slippery part of a banana",
    "Take off",
    "Rind",
    "Disrobe",
    "Lose a layer",
    "Striptease",
    "Sunburn result",
    "Rind",
    "Undress",
    "Strip",
    "Orange discard",
    "Sunburn woe",
    "Flake (off)",
    "It's out on a lime",
    "Rind",
    "Orange waste",
    "Orange waste",
    "Post-sunburn experience",
    "Sunburn result",
    "Exfoliate",
    "Sir Robert of London's bobbies",
    "Eponymous British Bobby",
    "Lemon zest source",
    "Unclothe",
    "Curacao ingredient",
    "British statesman Sir Robert"
  ],
  "demi": [
    "___ bra",
    "Prefix with john or god",
    "\"Sorry Not Sorry\" singer Lovato",
    "Prefix with god or john",
    "Prefix with tasse meaning \"half\"",
    "___-sec (wine designation)",
    "Prefix with god",
    "50% to start?",
    "Half: Prefix",
    "Singer Lovato",
    "Moore of \"G.I. Jane\"",
    "Celebrity ex of Bruce and Ashton",
    "Starting half?",
    "___-monde",
    "___-glace (rich sauce)",
    "Prefix with john",
    "Actress Moore",
    "Prefix with god",
    "Actress Moore of \"Ghost\"",
    "Actress Moore of \"G.I. Jane\"",
    "Prefix with god",
    "Half at the start?",
    "Brat Packer Moore",
    "Half: Prefix",
    "Moore in entertainment news",
    "Prefix with god",
    "Prefix with monde",
    "Actress Moore",
    "With 1-Down, moderately sweet, to a vintner",
    "Moore of \"G.I. Jane\"",
    "Moore of film",
    "Moore of \"G.I. Jane\"",
    "___-plie (ballet move)",
    "___-pointe (ballet position)",
    "Moore of \"Striptease,\" 1996",
    "Prefix with god",
    "Moore of \"G.I. Jane\"",
    "___-pointe (ballet position)",
    "Actress Moore",
    "Half: Prefix",
    "Actress Moore",
    "Patrick's \"Ghost\" co-star",
    "Prefix with god",
    "Prefix with god",
    "Moore of film",
    "Prefix with god",
    "Bruce's ex",
    "___-pointe (ballet position)",
    "Moore of \"G.I. Jane\"",
    "Prefix with god",
    "Half of the French?",
    "Prefix with monde",
    "___-plie (ballet movement)",
    "Moore of \"G.I. Jane\"",
    "Prefix with god",
    "Moore of \"Striptease\"",
    "Actress Moore",
    "Moore of \"Ghost\"",
    "Actress Moore",
    "Moore of \"Indecent Proposal\"",
    "Actress Moore",
    "Moore of \"A Few Good Men\"",
    "Patrick's \"Ghost\" co-star",
    "Actress Moore",
    "Half begun?"
  ],
  "axel": [
    "Skater's leap",
    "Winter Olympics maneuver",
    "Move named for the 19th-century skater Paulsen",
    "Winter Olympics maneuver",
    "Skating leap",
    "Winter Olympics feat",
    "Ice skating maneuver",
    "Double or triple feat in the Olympics",
    "Skating feat",
    "Skating feat",
    "Skating leap",
    "Feat with double and triple versions",
    "Jump with a turn on ice",
    "\"Double\" or \"triple\" move",
    "One's turn at the Olympics?",
    "Jump in an ice rink",
    "Figure skating jump",
    "Spin out on the ice?",
    "\"Double\" or \"triple\" feat",
    "Turn and a half on the ice",
    "Skating feat",
    "Impressive way to turn up?",
    "Rink maneuver",
    "\"Double\" or \"triple\" feat",
    "Rink jump",
    "Jump on ice",
    "Relative of a lutz",
    "Skater's jump",
    "\"Double\" or \"triple\" move",
    "Jump on the ice",
    "Figure skating jump",
    "Olympics jump",
    "Figure skater's leap",
    "Jump with an extra half-turn",
    "Jump in a rink",
    "Skating maneuver",
    "Lipinski leap",
    "Required element in many figure skating competitions",
    "Jump on the ice",
    "Rink leap",
    "Skater's feat",
    "Winter Olympics maneuver",
    "Jump that may be doubled",
    "Skater's jump",
    "Eddie's character in \"Beverly Hills Cop\"",
    "Jump and a twist",
    "Figure skater's jump",
    "1 1?Ç2 rotation leap",
    "Skater's leap",
    "Jump in the rink",
    "Jump with a twist",
    "Skating jump",
    "Leap for Lipinski",
    "Rink move",
    "Jump named for a skater",
    "Eddie's Beverly Hills cop",
    "Bit of skating practice",
    "It'll turn you around",
    "Figure skater's jump",
    "Leap for Lipinski",
    "Skater?Æs leap",
    "Skater's jump",
    "Jump on the ice",
    "Turnaround jump",
    "Skater's jump",
    "Skater's jump",
    "Jump on the ice",
    "Skating jump",
    "Jump on the ice",
    "Skating pioneer Paulsen",
    "Olympics jump",
    "It may be a double in the Olympics",
    "Skating jump",
    "Figure skater's jump",
    "Jump in the Winter Olympics",
    "Leap for Lipinski",
    "Leap for Lipinski",
    "Skating maneuver",
    "Skater's jumping feat",
    "Relative of a Salchow",
    "Rink jump",
    "Skater's maneuver",
    "Olympics jump",
    "Skater's move",
    "Jump at the Ice Capades",
    "Jump with a twist",
    "Skater's leap",
    "Skating maneuver",
    "\"Double\" or \"triple\" maneuver",
    "Skating maneuver",
    "Figure skating jump",
    "Ice show jump",
    "\"The Fusco Brothers\" dog, in the funnies",
    "Skating maneuver",
    "Oksana Baiul leap",
    "Skating pioneer Paulsen",
    "Double or triple feat",
    "Kerrigan feat"
  ],
  "ttyl": [
    "\"G2G\"",
    "Texter's farewell",
    "Equivalent of \"cya\" in a text",
    "Texter's \"ciao\"",
    "\"Until next time,\" in a text",
    "\"Bye for now,\" in a text",
    "Texter's sign-off",
    "Texter's bye-bye",
    "Texter's valediction",
    "Texter's sign-off",
    "\"Gotta run,\" in a text",
    "\"Gotta go,\" in textspeak",
    "\"So long,\" for short",
    "Texter's \"ciao\"",
    "\"Bye for now,\" in textspeak",
    "What best-selling 2004 young adult novel was written entirely in the form of instant messages?",
    "Modern farewell letters",
    "Texting ta-ta",
    "Texter's ta-ta",
    "Texter's \"bye now\"",
    "Texter's \"ciao\""
  ],
  "evan": [
    "Emmy and Golden Globe-nominated actress ___ Rachel Wood",
    "Welsh \"John\"",
    "\"Dear ___ Hansen\" (2017 Tony-winning musical)",
    "First name that, reversed, is part of a church",
    "___ Rachel Wood of \"Westworld\"",
    "Actress ___ Rachel Wood",
    "\"___ Almighty,\" 2007 film",
    "Actress ___ Rachel Wood",
    "Welsh form of \"John\"",
    "\"Dear ___ Hansen\" (Broadway hit)",
    "Broadway's \"Dear ___ Hansen\"",
    "Welsh \"John\"",
    "Ex-senator Bayh",
    "\"Dear ___ Hansen\" (2017 Tony winner)",
    "John, abroad",
    "Welsh \"John\"",
    "___ Spiegel, co-founder of Snapchat",
    "\"___ Almighty\" (2007 film)",
    "Former senator Bayh",
    "Actress ___ Rachel Wood",
    "Hansen of a 2016 Broadway hit",
    "Actor Peters of \"American Horror Story\"",
    "Actress ___ Rachel Wood",
    "___ Spiegel, co-founder of Snapchat",
    "Bayh of Indiana politics",
    "Snapchat co-founder Spiegel",
    "Three-time All-Star Longoria for the Tampa Bay Rays",
    "Hunter who wrote \"The Blackboard Jungle\"",
    "Actress ___ Rachel Wood",
    "Writer Osnos of The New Yorker",
    "Longoria with two Gold Gloves",
    "Actress ___ Rachel Wood",
    "Fashion label ___-Picone",
    "Former Indiana senator Bayh",
    "\"___ Almighty\" (2007 film)",
    "Welsh form of \"John\"",
    "___ Longoria, 2008 A.L. Rookie of the Year",
    "Ex-senator Bayh",
    "Hunter who wrote \"The Blackboard Jungle\"",
    "Olympic skating champion Lysacek",
    "Form of \"John\"",
    "2007 film \"___ Almighty\"",
    "___ Lysacek, 2010 Olympic figure skating gold medalist",
    "\"Thirteen\" actress ___ Rachel Wood",
    "\"___ Almighty\" (Steve Carell movie)",
    "Indiana senator Bayh",
    "2007 Steve Carell title role",
    "Politico Bayh",
    "\"___ Almighty\" (2007 movie)",
    "___ and Jaron (identical-twins pop duo)",
    "\"Almighty\" title role for Steve Carell",
    "Sen. Bayh",
    "\"___ Almighty,\" 2007 film",
    "Hunter of literature",
    "Politico Bayh",
    "___ Hunter a k a Ed McBain",
    "\"The Birds\" screenwriter ___ Hunter",
    "Sen. Bayh of Indiana",
    "Sen. Bayh of Indiana",
    "Highlander in Sir Walter Scott's \"Waverley\"",
    "Hoosier senator Bayh",
    "Politico Bayh",
    "Hunter of fiction",
    "Novelist Hunter",
    "Sen. Bayh of Indiana",
    "Welsh form of John",
    "___ Picone (clothing maker)",
    "Sen. Bayh of Indiana",
    "Sen. Bayh",
    "Sen. Bayh",
    "Sen. Bayh",
    "Sen. Bayh",
    "Author Hunter a k a Ed McBain",
    "John, in Wales",
    "\"Mrs. Bridge\" author Connell",
    "Hunter a k a Ed McBain",
    "Indiana Sen. ___ Bayh",
    "Author ___ S. Connell",
    "Sen. Bayh",
    "Author ___ S. Connell",
    "Former Gov. Bayh",
    "Author ___ S. Connell",
    "Politico Bayh",
    "Author Hunter",
    "Hunter of fiction",
    "Bayh of Indiana",
    "\"Mrs. Bridge\" novelist Connell",
    "Fashion house ___-Picone",
    "Fashion designer Picone",
    "Writer Hunter",
    "Novelist ___ S. Connell Jr.",
    "Writer Hunter",
    "\"The Blackboard Jungle\" author Hunter",
    "Novelist ___ S. Connell Jr.",
    "Author Hunter",
    "Gov. Bayh of Indiana",
    "1985 Edgar winner___Hunter",
    "Author Connell",
    "Writer Hunter",
    "Novelist ___ S. Connell Jr.",
    "Welsh \"John\"",
    "Novelist Hunter",
    "Novelist ___ S. Connell, Jr.",
    "John, in Wales",
    "Author Hunter",
    "Gov. Bayh of Indiana",
    "Writer Hunter"
  ],
  "went": [
    "Passed",
    "Sold (for)",
    "Took off",
    "Took a turn",
    "Said, informally",
    "Took a turn",
    "Departed",
    "Took one's turn",
    "Skipped town",
    "Departed",
    "Departed",
    "Came's partner",
    "Split",
    "Departed",
    "Traveled",
    "Didn't clash (with)",
    "Traveled",
    "With 13-Down, blew one's stack",
    "Left",
    "Sold (for)",
    "Gave out",
    "Stopped working",
    "Departed",
    "Left the scene",
    "Took a turn",
    "Departed",
    "Hit the road",
    "Headed out",
    "Departed",
    "Took a turn",
    "Traveled",
    "Left",
    "Proceeded",
    "Took off",
    "Left",
    "Exploded, with \"off\"",
    "Hit the road",
    "Got out",
    "___ with (dated)",
    "Collapsed",
    "Was used up",
    "Hit the road",
    "Took one's turn",
    "Didn't stay",
    "Left",
    "Didn't stop",
    "\"What ___ wrong?\"",
    "Collapsed",
    "Traveled",
    "Left",
    "Traveled",
    "Departed",
    "Left",
    "Departed",
    "Traveled",
    "Took off"
  ],
  "tvpg": [
    "Common sitcom rating",
    "Rating for \"Supergirl\" or \"Gilmore Girls\"",
    "How many network sitcoms are rated",
    "Rating for many a sitcom",
    "What suggestive dialogue may result in",
    "\"Modern Family\" rating",
    "Common sitcom rating",
    "Rating for many a sitcom",
    "Tube rating",
    "Rating for most episodes of \"The Simpsons\"",
    "\"Seinfeld\" designation",
    "\"American Idol\" rating",
    "Suitable for teen audiences",
    "Many a sitcom rating",
    "Family viewing mark"
  ],
  "heel": [
    "No-goodnik",
    "Crusty piece of bread",
    "Prominent part of a pump",
    "Obedience class command",
    "Part of a foot",
    "Stiletto, e.g.",
    "Last-eaten part of a loaf, often",
    "Command to a dog",
    "Elevator near an arch?",
    "Command to a dog",
    "Shoe part",
    "Cad",
    "Scoundrel",
    "Cad",
    "\"Follow\"",
    "Canine command",
    "Achilles' weak spot",
    "Command to a canine",
    "Elevator at the bottom?",
    "Part of a shoe with a tap",
    "Dog trainer's \"Follow!\"",
    "Dog command",
    "Last of a loaf",
    "Dog command",
    "Cad",
    "Height enhancer",
    "Cad",
    "Achilles' weakness",
    "Darned spot, often",
    "Sole support",
    "Boot part",
    "Boot part",
    "Crusty piece of bread",
    "No-goodnik",
    "No-goodnik",
    "Stiletto, e.g.",
    "Command to Rover",
    "Replaceable shoe part",
    "Scoundrel",
    "Pursue closely",
    "Contemptible one",
    "Rear of a sole",
    "Cad",
    "Lab instruction?",
    "No-goodnik",
    "Height enhancer",
    "Obedience school command",
    "Blackguard",
    "Shoe part",
    "No-goodnik",
    "No Mr. Nice Guy",
    "No-goodnik",
    "Command to a dog",
    "6-Down's partner",
    "Lowdown louse",
    "Part of a pump",
    "Toe's opposite",
    "Bread end",
    "Replaceable shoe part",
    "24-Across's opposite",
    "Command to Spot",
    "Shoe part",
    "Place for a shoe tap",
    "Command to Rover",
    "Canine command",
    "Dog command",
    "Cad"
  ],
  "nile": [
    "River personified by the god Hapi",
    "4,100+ mile river that drains 11 countries",
    "Where the infant Moses was found",
    "View from Memphis",
    "\"Blue\" or \"White\" river",
    "River that's home to 1-Across",
    "North-flowing river",
    "Shade of green",
    "Well, I'll be dammed!",
    "It flows past Memphis",
    "River draining 11 countries",
    "It flows past Giza",
    "One with a big mouth in Africa?",
    "Cairo's river",
    "Stream near the Great Pyramids",
    "\"Egypt was the gift of the ___\": Herodotus",
    "It passes Luxor Temple",
    "River along which 56-Across is located",
    "River through ancient Nubia",
    "River that feeds Lake Nasser",
    "River that turned to blood in Exodus",
    "Elephantine Island is in it",
    "River that flows south to north",
    "One of its sources is Lake Tana",
    "Water source for 11 countries",
    "Shade of blue or green",
    "Where baby Moses was found",
    "Title river in a Christie mystery",
    "River near the Valley of the Kings",
    "Locale in Exodus",
    "Blue ___",
    "Abu Simbel's waterway",
    "World's longest river",
    "Site of cataracts",
    "Setting for a classic Agatha Christie mystery",
    "West ___ virus",
    "Lotus-laden waterway",
    "River through two world capitals",
    "Khartoum's river",
    "River near the Pyramids",
    "River near the Pyramids",
    "The Imbaba Bridge spans it",
    "See 54-Across",
    "Locale of a Horatio Nelson victory",
    "28-Across locale",
    "Stream past Memphis",
    "Blue ___",
    "Cleopatra's river",
    "View from Memphis",
    "Cataract site",
    "Lord Nelson, the Hero of the ___",
    "Setting for an Agatha Christie mystery",
    "River near the Great Pyramids",
    "Where Aida sings \"O patria mia\"",
    "Memphis's locale",
    "Aswan's river",
    "Setting for an Agatha Christie novel",
    "Scene of classic flooding",
    "Sahara irrigator",
    "View from Abu Simbel",
    "River near the Sphinx",
    "\"Where's my serpent of old ___?\": \"Antony and Cleopatra\"",
    "Egyptian lifeline",
    "Memphis's locale",
    "With 66-Across, Egyptian agricultural area",
    "Shade of green",
    "Cataract site",
    "4,100-mile-long river",
    "Course in African history",
    "Cairo's waterway",
    "Alexandria's river",
    "West ___ virus",
    "Pharaoh's river",
    "It went through Memphis",
    "Home for hippopotamuses",
    "Shade of green",
    "River past Luxor",
    "River with Blue and White tributaries",
    "River in an Agatha Christie title",
    "Sight in Memphis",
    "Cairo's river",
    "Cairo's waterway",
    "World's longest river",
    "Green or blue shade",
    "Rosetta's locale",
    "Where young Moses was found",
    "Christie's \"Death on the ___\"",
    "Christie setting",
    "Shade of green",
    "Blue or White follower",
    "World's longest river",
    "It rises in Lake Victoria",
    "Sight in Memphis",
    "\"Cleopatra\" backdrop",
    "Christie murder setting",
    "River the Aswan Dam dams",
    "World's longest river",
    "Blue preceder or follower",
    "Shade of blue",
    "\"Blue\" or \"White\" river",
    "Khartoum's river",
    "Christie's \"Death on the ___\"",
    "Christie's \"Death on the ___\"",
    "Africa's longest river",
    "Literary death scene",
    "\"Blue\" or \"White\" river",
    "Site of annual floods",
    "Rosetta's river",
    "\"Cleopatra\" backdrop",
    "Bank-washer in Cairo",
    "Aswan Dam locale",
    "Egypt's main water supply",
    "Christie's \"Death on the ___\"",
    "Shade of blue",
    "Shade of green",
    "Pharaohs' river",
    "World's longest river",
    "Cairo's river",
    "Blue ___",
    "Aswan's river",
    "Cleo's lane",
    "Longest river in the world",
    "Shade of green",
    "Cairo's river",
    "River through Khartoum",
    "Kind of crocodile",
    "Shade of green",
    "Lake Nasser site",
    "Cairo's site",
    "\"Cleopatra\" setting",
    "Lake Nasser site",
    "\"Cleopatra\" locale",
    "Lake Victoria outlet",
    "Memphis locale",
    "Cairo's river",
    "Home for some crocodiles",
    "Where Moses rafted",
    "\"Cleopatra\" setting",
    "Memphis's locale",
    "Christie murder locale",
    "Pale aqua",
    "Kind of blue"
  ],
  "wiig": [
    "Kristen of \"S.N.L.\"",
    "Kristen formerly of \"S.N.L.\"",
    "Kristen of \"Bridesmaids\"",
    "Co-star of 2011's \"Bridesmaids\"",
    "\"Bridesmaids\" co-star",
    "Kristen of 2016's \"Ghostbusters\"",
    "Kristen of \"Bridesmaids\""
  ],
  "tens": [
    "Top marks",
    "TNT, in poker slang",
    "Second place at a math competition?",
    "Till compartment",
    "Abacus column",
    "Yellow bills in Monopoly",
    "Cards with the most pips",
    "Bills with Alexander Hamilton on them",
    "Striped blue balls",
    "Pairs of fins",
    "Bills with Alexander Hamilton",
    "Perfect scores for divers",
    "Sawbucks",
    "Perfect dives",
    "Hamilton's bills",
    "Till compartment",
    "Hamiltons",
    "Treasury bills?",
    "Hamilton settings",
    "Hamiltons",
    "Some bills",
    "Kind of column",
    "Heavenly bodies?",
    "Top scores in Olympic diving",
    "Second place?",
    "Some Olympic coups",
    "Fox ratings",
    "Exchange for a twenty, maybe",
    "Hamiltons",
    "All the best?",
    "Beauty marks?",
    "XXX",
    "Kind of column",
    "Perfect specimens",
    "Composition of some wads",
    "Till compartment",
    "Perfect specimens",
    "Till compartment",
    "Second place?",
    "The U.S. Treasury is on their backs",
    "Fives and ___",
    "___ place",
    "Bills you might break 20s into",
    "Sawbucks",
    "Till compartment",
    "Gymnastics coups",
    "Second place from the right",
    "Gymnasts' coups",
    "Certain 40-Across column",
    "Counting method",
    "Perfect Olympic scores",
    "Sawbucks",
    "Hamilton bills",
    "Perfect marks",
    "Till section",
    "Top ratings",
    "Till section",
    "Knockouts, so to speak",
    "Some bills",
    "Super scores",
    "Till stack",
    "Score halves",
    "Till stack",
    "Top scores, sometimes",
    "Change for a 20",
    "Kind of column",
    "Till compartment",
    "Counting method",
    "Gymnasts rarely see them",
    "Gymnastic coups",
    "Some bills",
    "Addition column",
    "Column next to the ones",
    "Addition column",
    "Till compartment",
    "Olympic coup",
    "Certain column",
    "Perfect scores",
    "Change for a twenty",
    "Column next to the ones",
    "Change for a twenty",
    "Symbols of perfection",
    "Certain column",
    "Teller's stack",
    "Jacks take them",
    "High ratings",
    "High scores",
    "Certain column",
    "Duo in a score",
    "Change for a twenty",
    "Counting method",
    "Perfect ones",
    "Change for a C-note",
    "Comaneci achievements",
    "Addition column",
    "Top vaults",
    "Sawbucks",
    "Scores for Comaneci",
    "Jacks take them",
    "Addition column",
    "Sawbucks",
    "Change for a C-note",
    "X's",
    "Change for a twenty",
    "Counting system",
    "7 + 3, 5 + 5, 1 + 9, etc.",
    "Counting method",
    "Addition column",
    "Olympic gymnast's goals",
    "Decimal system"
  ],
  "emir": [
    "Mohammed bin Thani was the first one for Qatar",
    "Title meaning \"commander\"",
    "Mideast bigwig",
    "Leader of an oil-rich land, maybe",
    "Qatari leader",
    "Sheik's peer",
    "Leader in a kaffiyeh",
    "Member of the House of Saud, e.g.",
    "The conqueror Tamerlane, for one",
    "Mideast ruler",
    "Qatari leader",
    "Leader of Qatar",
    "Eastern prince",
    "One title of the prophet Muhammad",
    "Qatari leader",
    "Kuwaiti leader",
    "Title meaning \"commander\"",
    "Qatari leader",
    "Sheik's peer",
    "Title in the House of Saud",
    "Leader in a robe",
    "Gulf mogul",
    "Mideast bigwig",
    "Mideast V.I.P.",
    "Title meaning \"commander\"",
    "Mideast bigwig",
    "Leader in a robe",
    "Kuwaiti leader",
    "Sheik's peer",
    "Arabian prince",
    "Robe-wearing ruler",
    "Abu Dhabi dignitary",
    "Mideast ruler",
    "Qatari bigwig",
    "Desert royal",
    "Kuwaiti leader",
    "Arab chief",
    "Eastern leader",
    "Overseas prince",
    "Dubai ruler",
    "Gulf State leader",
    "Qatari leader",
    "Leader of Qatar",
    "Ruler of Dubai",
    "Kuwaiti leader",
    "Arabic for \"commander\"",
    "Robed ruler",
    "Top Qatari",
    "Arab V.I.P.",
    "Arab ruler",
    "V.I.P. in a robe",
    "Kuwaiti ruler",
    "Big shot on Al Jazeera",
    "Ruler of Kuwait",
    "Ruler in a robe",
    "Oil-rich ruler, perhaps",
    "Doha V.I.P.",
    "Mideast ruler",
    "Mideast ruler",
    "OPEC dignitary, maybe",
    "Mideast leader",
    "Mideast noble",
    "Kuwaiti dignitary",
    "Arab ruler",
    "Persian Gulf leader",
    "Commander, in Arabic",
    "Kuwaiti leader",
    "Qatari ruler",
    "Arabic for \"commander\"",
    "Kaffiyeh-clad commander",
    "Kuwaiti chief",
    "Leader in a robe",
    "Kuwaiti leader",
    "Mideast V.I.P.",
    "Mideast bigwig",
    "Mideast V.I.P.",
    "Kuwaiti pooh-bah",
    "Foreign prince",
    "Kuwaiti pooh-bah",
    "Eastern ruler",
    "Ruler in a kaffiyeh",
    "Dubai dignitary",
    "Mideast V.I.P.",
    "Islamic chieftain",
    "Mideast potentate",
    "Robed dignitary",
    "Mideast leader",
    "Oil-rich ruler",
    "Ruler of Qatar",
    "Kuwaiti leader",
    "Arab chieftain",
    "Dignitary from Dubai",
    "Mideast bigwig",
    "Title from which \"admiral\" is derived",
    "Foreign dignitary",
    "Namer of a representative to OPEC, maybe",
    "Kaffiyehed commander",
    "Qatari leader",
    "Kuwaiti ruler",
    "Arabian V.I.P.",
    "Arab statesman",
    "Mideast ruler",
    "Kaffiyeh-clad commander",
    "Kuwaiti leader",
    "Kuwaiti leader",
    "Qatari V.I.P.",
    "Palace resident",
    "V.I.P. on Al Jazeera",
    "Kuwaiti ruler",
    "Mideast chief",
    "Gulf V.I.P.",
    "Leader at OPEC, maybe",
    "Qatari leader",
    "Certain head of state",
    "Royal Arabian",
    "Foreign head of state",
    "Sheik's guest",
    "Ruler of Qatar",
    "Mideast prince",
    "Arab ruler",
    "Sharif's relative",
    "Mideast title",
    "Leader in a robe",
    "Mideast bigwig",
    "40-Down V.I.P.",
    "Foreign dignitary",
    "Ruler with a throne",
    "Islamic ruler",
    "Qatari leader",
    "High guy in Dubai",
    "Hereditary prince",
    "Mideast ruler",
    "Kuwaiti ruler",
    "Kuwaiti chief",
    "Ruler in a palace",
    "Jabir al-Ahmad al-Sabah, e.g.",
    "Literally, \"commander\"",
    "Arabian bigwig",
    "Abu Dhabi leader",
    "Head of state in Kuwait",
    "Dubai V.I.P.",
    "Mideast bigwig",
    "OPEC V.I.P.",
    "Islamic leader",
    "Arabic for \"commander\"",
    "Foreign prince",
    "Descendant of Muhammad the Prophet",
    "Kuwaiti bigwig",
    "Mideast bigwig",
    "Gulf leader",
    "Abu Dhabi bigwig",
    "Mideast chief",
    "Mideast bigwig",
    "Desert dignitary",
    "Mideast summiteer",
    "Boss man of Ajman",
    "Osman, for one",
    "Sheik's peer",
    "Arab chieftain",
    "Bahrain bigwig",
    "Nabob of the Near East",
    "Arab prince",
    "Arabian bigwig",
    "Gulf war V.I.P.",
    "Gulf war figure",
    "Kuwaiti leader",
    "Foreign title",
    "Big name in Ajman",
    "Mideast bigwig",
    "Abu Dhabi prince",
    "Leader in the dunes",
    "Kuwaiti leader",
    "Kuwaiti honcho",
    "Eastern V.I.P.",
    "Jabir al-Sabah, e.g.",
    "Head of Abu Dhabi",
    "Kuwaiti V.I.P.",
    "Kuwaiti ruler",
    "Abu Dhabi V.I.P."
  ],
  "slap": [
    "Word with shot or happy",
    "Throw (together)",
    "Haphazardly assemble, with \"together\"",
    "Bit of Three Stooges comedy",
    "Sharp rebuff",
    "It's an affront",
    "Hockey shot sound",
    "Smack",
    "Nonverbal response to an insult",
    "Swat",
    "Response to an affront",
    "Give an unexpected hand",
    "High-five sound",
    "Certain shot in hockey",
    "Reaction to an insult",
    "Throw (together)",
    "Response to an affront",
    "Many a hockey shot",
    "Quickly put (together)",
    "Indication of freshness",
    "Come-on comeuppance",
    "Throw (together)",
    "Sound of a high-five",
    "Indicator of freshness?",
    "*Obstetrician's action on a newborn's behind",
    "[You cad!]",
    "It's an affront",
    "Bass technique",
    "[How dare you?!]",
    "Response to an affront",
    "\"How dare you!\" accompanier",
    "An affront",
    "Response to an affront",
    "Affix carelessly, with \"on\"",
    "Action to a newborn baby's bottom",
    "What a cheeky one's cheek might get",
    "Possible response to a grabby boyfriend",
    "Response to freshness?",
    "Indicator of freshness?",
    "Part of a Three Stooges routine",
    "Unfortunate date ending",
    "Sharp rebuke",
    "[How dare you!]",
    "Part of a Three Stooges shtick",
    "Cuff",
    "Sharp rebuke",
    "Prelude to a duel",
    "Response to a rude remark",
    "Delivery after a delivery?",
    "High-five, e.g.",
    "Retaliation for a pinch",
    "Response to an insult",
    "High-five, e.g.",
    "Hysteria curber",
    "Midwife's act",
    "Rebuke",
    "Response to an insult",
    "Response to an insult, maybe",
    "Indication of indignation",
    "Certain hockey shot",
    "Smack",
    "Response to an affront",
    "Duel preliminary",
    "Response to an insult",
    "Sign of freshness?",
    "Doctor's action to a newborn's bottom",
    "Hockey shot",
    "Not-so-subtle no",
    "Pass rejection?",
    "Sound before \"Thanks, I needed that!\"",
    "Invitation to duel, perhaps",
    "Sharp rebuke",
    "Prelude to a duel",
    "Prelude to a duel",
    "High-five sound",
    "Post-delivery delivery",
    "Sharp rebuff",
    "Certain hockey shot",
    "Prelude to a duel",
    "Response to an insult",
    "Sharp rebuke",
    "Put-down",
    "Big shot in ice hockey",
    "Response to a pass?",
    "Reply to a masher",
    "Blow",
    "Personal affront",
    "Midwife's action",
    "Affront",
    "Smack",
    "Reply to an insult",
    "Smack",
    "Reaction to a pinch",
    "Light punishment",
    "Strike",
    "Masher's comeuppance",
    "Sharp put-down",
    "Cuff"
  ],
  "tori": [
    "Singer Amos",
    "Bagel shapes",
    "Doughnut shapes, mathematically",
    "Singer Amos",
    "Actress Spelling of \"Beverly Hills 90210\"",
    "Inner tubes, topologically",
    "Doughnut figures",
    "Rings",
    "Famous Amos",
    "Spelling or Amos",
    "Doughnuts, in topology",
    "Spelling with lines",
    "Lifesavers, e.g.",
    "Singer Amos",
    "Inner tubes, topologically",
    "Doughnuts, mathematically",
    "Doughnut shapes",
    "Doughnuts",
    "Famous Amos",
    "\"A Sorta Fairytale\" singer Amos",
    "\"___ & Dean: Home Sweet Hollywood\" (reality series)",
    "Doughnut shapes, mathematically",
    "Doughnuts, mathematically",
    "Doughnut shapes",
    "Spelling of \"90210\"",
    "Doughnut shapes, mathematically speaking",
    "Doughnuts, topologically speaking",
    "Doughnuts, but not danishes",
    "Tubes, e.g.",
    "Danish kroner, topologically speaking",
    "Singer/songwriter Amos",
    "Singer Amos",
    "Bagels, e.g.",
    "Bagels, essentially",
    "Actress Spelling",
    "Holey things",
    "Some shapes in topology",
    "Actress Spelling",
    "Famous Amos",
    "Actress Spelling",
    "Inner tubes, e.g.",
    "Famous Amos",
    "Bagels, mathematically",
    "Pop singer Amos",
    "Famous Amos",
    "Inner tubes, topologically",
    "Geometric shapes",
    "Famous Amos",
    "Pop singer Amos",
    "Spelling of Hollywood",
    "A famous Amos",
    "Shapes with holes",
    "Mathematical rings",
    "Semicircular moldings",
    "Actress Spelling",
    "Singer Amos",
    "Inner tubes, e.g.",
    "Lifesaver shapes",
    "3-D figures",
    "Topology figures",
    "Famous Amos",
    "Topological shapes",
    "Math rings",
    "Rings",
    "Semicircular moldings at column bases",
    "Actress Spelling",
    "Topological shapes",
    "They have holes in their middles",
    "Topics in topology",
    "Math rings",
    "Math models",
    "Spelling on television",
    "Doughnut shapes",
    "Amos or Spelling",
    "Geometric solids",
    "Geometric solids",
    "TV actress Spelling",
    "Spelling on TV",
    "Geometrical solids",
    "Inner tubes, geometrically",
    "Actress Spelling",
    "Spelling of \"Beverly Hills 90210\"",
    "Actress Spelling",
    "Singer Amos",
    "Spelling on TV",
    "Actress Spelling",
    "Geometric shapes",
    "Singer Amos",
    "Actress Spelling"
  ],
  "taft": [
    "1947's ___-Hartley Act",
    "First president to be buried at Arlington National Cemetery",
    "Last U.S. president to have facial hair",
    "President who finished third in his race for re-election",
    "Loser to Wilson in 1912",
    "Election winner of 1908",
    "President who was formerly Theodore Roosevelt's secretary of war",
    "President who also served as provisional governor of Cuba",
    "Only president who was also a chief justice",
    "President who later served as chief justice",
    "Only U.S. president also to serve as chief justice",
    "Chief justice appointed by Harding",
    "Only president to administer the oath of office to two other presidents",
    "Last mustachioed president",
    "President William Howard ___",
    "President after Roosevelt",
    "Ohio senator who was one of J.F.K.'s eight \"Profiles in Courage\"",
    "Former Washington heavyweight",
    "Ex-president who swore in President Hoover",
    "Roosevelt's successor",
    "Secretary of war under Theodore Roosevelt",
    "President whose father co-founded Yale's Skull and Bones",
    "1920s chief justice",
    "Chief justice before Hughes",
    "President before Wilson",
    "President just before Wilson",
    "President who later served as chief justice",
    "President before Wilson",
    "Chief justice before Hughes",
    "Wilson's predecessor",
    "1920's chief justice",
    "President before Wilson",
    "Sherman served with him",
    "Mr. Republican",
    "Roly-poly president",
    "1920's chief justice",
    "1920's Chief Justice",
    "For whom Sherman was veep",
    "Last mustachioed president",
    "10th Supreme Court chief justice",
    "Eisenhower's rival for the 1952 nomination",
    "300-pound President",
    "Bryan defeater, 1908",
    "Hotel in \"The Graduate\"",
    "Electee of 1908",
    "Mr. Republican of the old G.O.P.",
    "Wilson's predecessor",
    "Sherman was his Veep",
    "Chief Justice in the 1920's",
    "White House heavyweight",
    "___-Hartley Act"
  ],
  "remy": [
    "\"Ratatouille\" protagonist",
    "Name on a Cognac bottle",
    "\"Ratatouille\" rat",
    "___ Martin, French firm since 1724",
    "___ Martin (Cognac maker)",
    "\"Ratatouille\" rat",
    "___ Martin",
    "___ Martin Cognac",
    "Rat in \"Ratatouille\"",
    "___ Martin",
    "Main rat in \"Ratatouille\"",
    "___ Martin (cognac brand)",
    "\"Ratatouille\" rat chef",
    "___ Martin (cognac)",
    "___ Martin (cognac brand)",
    "___ Martin cognac",
    "___ Martin (cognac)",
    "___ Martin (cognac)",
    "___ Martin Cognac",
    "Big name in fine wines",
    "___ Martin (cognac)"
  ],
  "cake": [
    "It may be iced for a happy birthday party",
    "Word that can follow either half of the answers to the starred clues",
    "Big order for a wedding reception",
    "What you can't have and eat, too, it's said",
    "Wedding reception staple",
    "What mud can do",
    "See 5-Across",
    "One might say \"Happy Birthday\"",
    "Become clumped",
    "Staple at a wedding reception",
    "Clump up",
    "With 58-Across, bakery container",
    "It may be upside-down",
    "Part of many an anniversary celebration",
    "Ending with tea or cup",
    "Become clumped",
    "Wedding reception need",
    "It's rich in sugar",
    "___ and 10-Down",
    "Birthday order",
    "Wonderland food for Alice",
    "Stick together",
    "Part of a birthday celebration",
    "Piece of soap",
    "Base for some decorations",
    "Birthday dessert",
    "Birthday order",
    "Word that can follow either half of 17-, 25-, 37-, 52- or 62-Across",
    "Word that can follow the ends of 17-, 31-, 38-, 50- and 64-Across",
    "Wedding reception centerpiece",
    "Wedding reception centerpiece",
    "Word that can follow the end of 16-, 22-, 31-, 40-, 45- or 56-Across",
    "It may be upside-down",
    "Bake sale order",
    "Set",
    "Order for a party caterer",
    "Decorator's base",
    "It may be tiered",
    "Party centerpiece",
    "Candle holder",
    "Soap bar",
    "Winner's prize, maybe",
    "Anniversary item",
    "Birthday party necessity",
    "Soap unit"
  ],
  "adds": [
    "Is calculating, say",
    "Does a summer job?",
    "Puts on top of",
    "Stirs in",
    "Puts two and two together, say",
    "Puts on",
    "Tacks on",
    "Sticks in",
    "Chimes in",
    "Enhances, with \"to\"",
    "Uses sigma notation, in calculus",
    "Appends",
    "Puts together",
    "Interjects",
    "Puts two and two together",
    "Annexes",
    "Throws into the mix",
    "Throws in",
    "Also mentions",
    "Goes on to say",
    "New contacts, informally",
    "Mixes in",
    "Puts two and two together, say",
    "Says further",
    "Tacks on",
    "Puts on",
    "Does sums",
    "Increases, with \"to\"",
    "Throws in",
    "Tots",
    "Tosses in",
    "Puts on",
    "Interjects",
    "Tots",
    "Introduces to the mix",
    "Opposite of subtracts",
    "Puts in",
    "Interjects",
    "Puts together",
    "States further",
    "Writes as a postscript, say",
    "Combines",
    "Puts in",
    "Sums",
    "Opposite of subtracts",
    "Tacks on",
    "Contributes",
    "Goes on to say",
    "Annexes",
    "Kicks in",
    "Tosses in",
    "Throws in",
    "Kicks in",
    "Elaborates",
    "Comments further",
    "Puts on",
    "Opposite of subtracts",
    "Annexes",
    "Uses the \"+\" function",
    "Tosses in",
    "Mentions further",
    "Tallies",
    "Does sums",
    "Throws in",
    "Tacks on",
    "Interjects",
    "Throws in",
    "Puts on",
    "Takes on",
    "Tosses in",
    "Throws on",
    "Attaches",
    "Interjects",
    "Affixes",
    "Throws in",
    "Takes on",
    "Puts on",
    "Stirs in",
    "Tacks on",
    "Puts in extra",
    "Does simple math",
    "Throws in",
    "Combines",
    "Contributes",
    "Tacks on",
    "Sums (up)",
    "Counts up",
    "Says further",
    "States further",
    "Puts on",
    "Puts in",
    "Puts on",
    "Finds the sum",
    "Contributes",
    "Contributes",
    "Uses an abacus",
    "Totals up",
    "Mounts (up to)",
    "Subjoins",
    "Combines",
    "Throws in",
    "Follows a recipe direction",
    "Tacks on",
    "Does simple arithmetic",
    "Puts 2 and 2 together",
    "Does sums",
    "Throws in",
    "Throws in",
    "States further",
    "Puts two and two together",
    "Comments further",
    "Goes on",
    "Annexes",
    "Throws in",
    "Tacks on",
    "Stirs in ingredients",
    "Tacks on",
    "Puts together",
    "Appends",
    "Inserts",
    "Foots (up)",
    "Tallies",
    "Says further",
    "Tacks on",
    "Dubs in",
    "Puts on",
    "Supplements",
    "Sticks on",
    "Tacks on",
    "Annexes",
    "Puts on",
    "Tags on",
    "Puts two and two together",
    "Supplements",
    "Tots up",
    "Reckons",
    "Sums",
    "Apposes",
    "Postfixes",
    "Puts two and two together"
  ],
  "jell": [
    "Work well together",
    "Come together",
    "Solidify",
    "Come together",
    "Take shape",
    "Set, as mousse",
    "Come together",
    "Get set",
    "Set",
    "Come together",
    "Become definite",
    "Set",
    "Come together"
  ],
  "orly": [
    "Aéroport d'___",
    "Air France hub",
    "Alternative to de Gaulle",
    "Paris suburb",
    "Nail polish brand",
    "Airport about 28 miles from Disneyland Paris",
    "Alternative to Charles de Gaulle",
    "Airport whose name is also a big brand of nail polish",
    "Eponymous Paris suburb",
    "Paris's ___ Airport",
    "Paris airport",
    "Alternative to Beauvais-Tille",
    "Air France hub",
    "Aeroports de Paris manages it",
    "General-aviation alternative to Le Bourget",
    "Air France destination",
    "European hub",
    "Aeroport d'___",
    "Paris airport",
    "Hub for Air Caraibes Atlantique",
    "Paris suburb",
    "Alternative to Beauvais",
    "Suburb south of Paris",
    "Traveler's alternative to De Gaulle",
    "Charles de Gaulle alternative",
    "Air France destination",
    "Alternative to Le Bourget",
    "Airport for Air France",
    "Air France destination",
    "Paris airport",
    "Destination for visitors to Paris",
    "French airport",
    "Air France locale",
    "Paris taxi destination, maybe",
    "Alternative to De Gaulle",
    "It's near Paris",
    "Paris airport",
    "Air France stop",
    "French air hub",
    "Suburb south of Paris",
    "Paris airport",
    "Alternative to Charles de Gaulle",
    "Airport outside Paris",
    "Site south of Paris",
    "Major Air France terminus",
    "European air hub",
    "Paris airport",
    "Gateway to Paris",
    "Paris airport",
    "Paris airport",
    "Air France terminal",
    "Paris airport",
    "Avion destination",
    "Alternative to Le Bourget",
    "Air France airport",
    "Alternative to Charles de Gaulle",
    "Paris designation",
    "Paris landing site",
    "Alternative to Charles de Gaulle",
    "Concordes land there",
    "Air France lands there"
  ],
  "sumo": [
    "Japanese national sport",
    "Sport in a ring",
    "Wrestler in a mawashi",
    "Sport in which rikishi wear mawashi",
    "Shoving match, in a way",
    "Sport that takes place in a dohyo",
    "Competition between two heavyweights",
    "Sport in a ring",
    "Sport whose participants are called rikishi",
    "Competition with minimal apparel",
    "Sport in a ring",
    "Sport conducted in a dohyo",
    "Sport originally part of a Shinto ritual",
    "Battle of the bulges?",
    "Heavy competition?",
    "Variety of wrestling",
    "Wrestling for 400-pounders",
    "Sport for rikishi",
    "Activity in a dohyo",
    "Sport for heavyweights",
    "Ring sport",
    "___ wrestler",
    "Heavyweight wrestling",
    "Heavyweight bouts?",
    "Bare-chested sport",
    "Shoving matches?",
    "Big sport overseas?",
    "Ring toss activity?",
    "Kind of wrestling",
    "Rikishi's specialty",
    "Heavyweights face off in it",
    "Popular spectator sport that's not in the Olympics",
    "Mawashi wearer's activity",
    "Sport of a rikishi",
    "Japanese wrestling",
    "Contact sport with a purification ritual",
    "Sport for rikishi",
    "Heavyweights compete in it",
    "Sport with beefy grapplers",
    "Japanese wrestling",
    "Ring sport",
    "Ring contest",
    "Japanese wrestling",
    "Clash of heavyweights",
    "Japanese wrestling",
    "Sport in which athletes crouch",
    "Ring sport",
    "Kind of wrestling",
    "Kind of wrestling",
    "Clash of heavyweights",
    "Kind of wrestling",
    "Wrestling style",
    "Squatter?",
    "Japanese sport",
    "Japanese wrestling",
    "Japanese wrestling",
    "Shoving match?",
    "Japanese wrestling",
    "Ceremonial sport",
    "Ring sport",
    "Sport for heavyweights",
    "Japanese wrestling",
    "Sport in a ring",
    "Japanese entertainment",
    "Kind of wrestler",
    "Oriental combat",
    "Certain wrestler"
  ],
  "watt": [
    "Unit commonly following \"40,\" \"60,\" \"75\" and \"100\"",
    "Electrical unit",
    "Light bulb unit",
    "1/746 horsepower",
    "Light amount?",
    "Engineer who coined the term \"horsepower\"",
    "Power unit",
    "One joule per second",
    "Power figure?",
    "Power bit",
    "Inventor with three steam engine patents",
    "Steam engine pioneer James",
    "Joule/second",
    "Light bulb unit",
    "Light amount?",
    "___-hour",
    "Bit of power",
    "Unit often preceded by kilo-",
    "Light bulb measure",
    "Volt-ampere",
    "Unit of power",
    "Word after 60-, 75- or 100-",
    "Bulb unit",
    "What \"W\" stands for on a light bulb",
    "James with an electrical unit named after him",
    "Light bulb unit",
    "Small bit of power",
    "Light bulb unit",
    "Steam engine pioneer",
    "The \"W\" of kWh",
    "Light bulb unit",
    "Eponymous engineer",
    "Small amount of power",
    "Power unit",
    "Volt-ampere",
    "Eponymous Scottish inventor",
    "Light bulb unit",
    "Light bulb unit",
    "Reagan's first interior secretary",
    "Light bulb unit",
    "Interior Secretary under Reagan",
    "Power unit",
    "Light bulb unit",
    "Bulb unit",
    "He coined the term \"horsepower\"",
    "Bulb measure"
  ],
  "cato": [
    "Roman who said \"After I'm dead I'd rather people ask why I have no monument than why I have one\"",
    "Foe of Caesar",
    "Roman orator",
    "Stoic politician of ancient Rome",
    "\"Elder\" Roman statesman",
    "Who wrote \"Wise men learn more from fools than fools from the wise\"",
    "Foe of Caesar",
    "Roman statesman known as \"the Censor\"",
    "Punic War agitator",
    "Roman censor",
    "Ancient Rome's ___ the Elder",
    "Ancient Roman censor",
    "\"Elder\" or \"Younger\" Roman statesman",
    "Foe of Caesar",
    "Roman who declared \"Carthage must be destroyed\"",
    "Roman statesman ___ the Elder",
    "\"The Censor\" of ancient Rome",
    "Famed Roman censor",
    "Censor of ancient Rome",
    "Roman statesman and writer",
    "Enemy of Carthage",
    "Ancient Roman censor",
    "Denouncer of Caesar, 63 B.C.",
    "Roman called \"the Censor\"",
    "\"Carthage must be destroyed!\" proclaimer",
    "Stoic philosopher",
    "\"Lydia\" poet",
    "Censor of 51-Down",
    "\"Elder\" or \"Younger\" Roman",
    "Roman philosopher",
    "Plutarch biographical subject",
    "Either of two Roman statesmen",
    "Roman called \"The Elder\"",
    "Subject of one of Plutarch's \"Lives\"",
    "Rival of Scipio",
    "Ancient Roman censor",
    "Roman ethicist",
    "\"Julius Caesar\" role",
    "Marcus Porcius",
    "Roman statesman and censor"
  ],
  "meld": [
    "Vulcan mind ___",
    "Blend",
    "Combination in the card game bezique",
    "Gin rummy combo",
    "Vulcan mind ___",
    "Fuse",
    "Blend",
    "Join",
    "Vulcan mind ___",
    "Blend",
    "Card game declaration",
    "Part of a pinochle round",
    "Become inseparable",
    "Pinochle lay-down",
    "Mixture",
    "Blend",
    "Blend",
    "Fuse",
    "Blend",
    "Card table action",
    "Blend",
    "Blend",
    "Card game declaration",
    "Amalgamate",
    "Pinochle combo",
    "Four kings, maybe",
    "Fuse",
    "Put one's cards on the table, maybe",
    "Combine",
    "Pinochle combo",
    "Blend",
    "Pinochle combo",
    "Pinochle holding",
    "Pinochle play",
    "Conflate",
    "Pinochle combo",
    "Score in pinochle"
  ],
  "ires": [
    "Angers",
    "Fits of anger",
    "Angers",
    "Inflames",
    "Seriously vexes",
    "States of pique",
    "Angers",
    "Steams",
    "Angers",
    "Angers",
    "Angers",
    "Ticks off",
    "Extreme piques",
    "Red states",
    "Steams",
    "Angers",
    "Makes sore",
    "Angers",
    "Burns up",
    "Furies",
    "Raises the hackles of",
    "Ticks off",
    "Provokes",
    "Angers",
    "Incenses",
    "Ticks off",
    "Tees off",
    "Angers",
    "Ticks off",
    "Honks off, so to speak",
    "Gets hot",
    "Tees off",
    "Steams up",
    "Tees off",
    "Ticks off",
    "Angers",
    "Ticks off",
    "Angers",
    "Angers",
    "Cholers",
    "Ticks off",
    "Ticks off",
    "Makes see red",
    "Rages",
    "Ticks off",
    "Furies",
    "Tees off",
    "Resentments",
    "Furies",
    "Provokes splenetically",
    "Burns up",
    "Makes angry",
    "More than miffs",
    "More than annoys",
    "Furies",
    "Fits or snits",
    "Infuriates",
    "Hot tempers",
    "Tempers",
    "Burns up",
    "Tempers",
    "Furies",
    "Burns up",
    "Ticked states",
    "More than upsets",
    "Angers",
    "Angers",
    "Gets one's goat",
    "Provokes",
    "Angers",
    "Ticks off",
    "Angers",
    "Furies",
    "Infuriates",
    "Burns one up",
    "Angers",
    "Wraths"
  ],
  "lion": [
    "Cowardly ___",
    "King of cubs",
    "Person of outstanding importance",
    "Columbia athlete",
    "King of the jungle",
    "One of two statues outside the New York Public Library",
    "Symbol of England",
    "Den denizen",
    "Rung #1 of an apt word ladder",
    "MGM symbol",
    "Meaning of \"Simba\" in Swahili",
    "Peugeot symbol",
    "Safari sighting",
    "Den denizen",
    "\"The ___ King\"",
    "11-Down symbol",
    "Sphinx, in part",
    "Symbol of England",
    "Animal on the Sri Lankan flag",
    "One jumping through hoops, maybe",
    "*Great in number",
    "One sleeping \"in the jungle, the mighty jungle,\" in song",
    "Royal Arms of England symbol",
    "Rear half of a griffin",
    "Front part of a chimera",
    "Highly revered one",
    "Coward in a 1939 film",
    "Beast fought by Heracles",
    "Symbol on Sri Lanka's flag",
    "Leo's symbol",
    "___ of Judah",
    "\"The Wizard of Oz\" companion",
    "\"The Wizard of Oz\" coward",
    "*\"In like a ___ ...\"",
    "Ali, the ___ of God",
    "Viking foe?",
    "Louis VIII nickname, with \"the\"",
    "Brave one",
    "Literary ___",
    "Cowardly resident of Oz",
    "Symbol of courage",
    "Narnia's Aslan, e.g.",
    "Columbia athlete",
    "Zodiac symbol",
    "Horoscope figure",
    "Common symbol in heraldry",
    "Pride member",
    "Big prowler",
    "Companion of 28-Across",
    "Leo's symbol",
    "Feature of a dangerous circus act",
    "Animal on Sri Lanka's flag",
    "Pride member",
    "Animal with a mane",
    "Circus animal with a tamer",
    "One guarding the steps of the New York Public Library",
    "Viking foe",
    "Skilled stalker",
    "Civic leader",
    "Northern constellation, with \"the\"",
    "Emblem of Great Britain",
    "Animal with a cub",
    "Big cat",
    "Emblem of St. Mark",
    "Pride member",
    "Heraldic beast",
    "Big 22-Down",
    "Zodiac symbol",
    "First part",
    "Animal on England's shield",
    "Big cat",
    "Nickname for Louis VIII, with \"the\"",
    "MGM icon",
    "Den dweller",
    "Ali, the ___ of God",
    "MGM symbol",
    "Great Britain's emblem",
    "Friend to Androcles",
    "Literary ___",
    "Skilled stalker",
    "Simba, for one",
    "Celebrity",
    "Leo, astrologically",
    "Roarer",
    "Coward in \"The Wizard of Oz\"",
    "Its pride is its pride",
    "Half of a griffin",
    "Symbol on an English coat of arms",
    "Brave one",
    "King of the jungle",
    "Animal with a mane",
    "Literary ___",
    "British emblem",
    "Literary ___",
    "Androcles' friend",
    "Columbia athlete",
    "Club member since 1917",
    "Disney's Simba and Nala",
    "Oz traveler",
    "\"Androcles and the ___\"",
    "Columbia mascot",
    "Friend of Androcles",
    "Columbia student",
    "Regulus' constellation, with \"the\"",
    "M-G-M symbol",
    "Zodiac animal",
    "Share holder?",
    "Symbol of St. Mark",
    "King of the beasts",
    "Important person",
    "Emblem on an English shield",
    "Columbia athlete",
    "New York Public Library figure",
    "Animal that sleeps with its eyes open"
  ],
  "otis": [
    "R&B great Redding",
    "Company that makes moving walkways",
    "\"Miss ___ Regrets,\" jazz standard performed by Ethel Waters and Ella Fitzgerald",
    "\"The Adventures of Milo and ___\" (1989 film)",
    "Big elevator maker",
    "Inventor Boykin who helped develop the pacemaker",
    "Elevator brake inventor Elisha",
    "Elevator innovator",
    "Williams who was one of the original Temptations",
    "Bluesy Redding",
    "Redding who wrote \"Respect\"",
    "Redding who sang \"(Sittin' On) The Dock of the Bay\"",
    "Singer Williams of the Temptations",
    "Los Angeles's ___ College of Art and Design",
    "___ Day and the Knights (\"Animal House\" group)",
    "Singer Redding",
    "Singer/songwriter Redding",
    "L.A.'s ___ College of Art and Design",
    "Singer Redding",
    "2012 Grammy-winning rap hit that samples \"Try a Little Tenderness\"",
    "Singer Redding",
    "Elevator innovator Elisha",
    "Mayberry sot",
    "___ and Carla (1960s R&B duo)",
    "Big name in elevators",
    "Elisha in the National Inventors Hall of Fame",
    "R&B great Redding",
    "Moving walkway maker",
    "Manufacturer of indoor cars",
    "Redding who sang \"(Sittin' On) the Dock of the Bay\"",
    "Old blues singer Johnny",
    "Johnny nicknamed \"The Godfather of Rhythm and Blues\"",
    "Los Angeles's ___ College of Art and Design",
    "Maker of thousands of cars annually",
    "\"Miss\" with regrets",
    "Soulful Redding",
    "Big name in escalators",
    "Soul singer Redding",
    "Soul singer Redding",
    "Mr. ___, protagonist in Wilde's \"The Canterville Ghost\"",
    "Singer Williams of the Temptations",
    "Maker of indoor cars",
    "Redding of R&B",
    "Moving walkway maker",
    "William ___, inventor of the steam shovel",
    "Soul singer Redding",
    "William who invented the steam shovel",
    "[Elevators]",
    "Big maker of moving walkways",
    "Lex Luthor's main henchman in \"Superman\"",
    "Inventor whose success went up and down?",
    "Soul singer Redding",
    "Soulful Redding",
    "Milo's partner in film",
    "Elevator innovator",
    "Soul singer Redding",
    "Mayberry town drunk",
    "Redding of R&B",
    "Elevator pioneer Elisha",
    "Uplifting company?",
    "Old sitcom sot",
    "Redding of R&B",
    "Manufacturer of boxy cars",
    "___ & Carla (1960s duo)",
    "Company that once owned the trademark \"Escalator\"",
    "\"Miss ___ Regrets\"",
    "Escalator pioneer",
    "Singer Redding",
    "Porter's regretful Miss",
    "R&B pioneer Johnny",
    "Williams of the Temptations",
    "2011 Grammy-winning song by Jay-Z and Kanye West",
    "Redding who sang \"The Dock of the Bay\"",
    "___ B. Driftwood (\"A Night at the Opera\" role)",
    "Soul singer Redding",
    "Singer Redding",
    "Soulful Redding",
    "Elevator pioneer Elisha",
    "Soulful Redding",
    "People mover since 1853",
    "Elevator pioneer Elisha",
    "Big name in the 20-Across business",
    "Lift innovator",
    "Its products go up and down",
    "___ Day & the Knights (band in \"Animal House\")",
    "Elevator inventor Elisha",
    "R&B singer Shuggie ___",
    "___ Chandler, longtime publisher of the Los Angeles Times",
    "Big name in elevators",
    "Milo's canine pal",
    "Manufacturer of boxy cars",
    "Former Magic player Smith",
    "Porter's regretful \"Miss\"",
    "Bluesman Rush",
    "A leading manufacturer of cars",
    "Johnny with the 1958 hit \"Willie and the Hand Jive\"",
    "Milo's title partner in a 1989 film",
    "Revolutionary patriot James",
    "Maker of moving walkways",
    "Former newspaper publisher ___ Chandler",
    "Big elevator manufacturer",
    "Steam shovel inventor William",
    "Revolutionary James ___, famous for saying \"Taxation without representation is tyranny\"",
    "1970s N.F.L.'er Armstrong",
    "Elevator company",
    "Armstrong of N.F.L. fame",
    "Elevator pioneer Elisha",
    "Blues guitarist Taylor",
    "___ Elevator Company",
    "Inventor of a braking system for cars",
    "Mayberry souse",
    "Elevator brake inventor",
    "Ned Beatty's role in \"Superman\"",
    "\"Miss ___ Regrets\"",
    "Los Angeles's ___ College of Art and Design",
    "Man's name meaning \"One who hears well\"",
    "\"Miss ___ Regrets\"",
    "Elevator pioneer Elisha",
    "Singer Redding",
    "Company that has its ups and downs",
    "Milo's title partner in a 1989 film",
    "Company whose cars don't use gasoline",
    "He became famous for holding up cars",
    "Elevator pioneer",
    "R & B's ___ Day & the Knights",
    "Lift innovator",
    "Songwriter Blackwell",
    "Soul singer Redding",
    "Elevator maker",
    "Mayberry man",
    "Supermodel Carre",
    "\"The Adventures of Milo and ___\" (1989 film)",
    "He gave us a lift",
    "Redding of R & B",
    "Singer Redding",
    "Sitcom souse",
    "Porter's regretful Miss",
    "Innovator in lifts",
    "Singer Redding",
    "Miss in a 1934 song",
    "Williams of the Temptations",
    "Groucho's \"A Night at the Opera\" role",
    "R. & B. singer Redding",
    "___ & Carla, 60's singing duo",
    "Elevator firm",
    "Elevator inventor",
    "Johnny in the Rock and Roll Hall of Fame",
    "Singer Redding",
    "Leader of a lush life in Mayberry?",
    "Lift handle?",
    "\"Miss ___ Regrets\"",
    "Singer Redding",
    "Elevator man",
    "Miss ___ of song",
    "Groucho, in \"A Night at the Opera\"",
    "Bluesman Spann",
    "Singer Redding",
    "Singer Redding",
    "Company that owns the trade name \"Escalator\"",
    "Milo's pal, in a 1989 film",
    "Elevator man",
    "Elevator pioneer",
    "Song miss",
    "___ Campbell on \"The Andy Griffith Show\"",
    "Regretful one of song",
    "Redding of R&B",
    "Inventor Elisha",
    "Singer Redding",
    "Elevator pioneer",
    "Cole Porter miss",
    "Redding of song",
    "Football's Armstrong",
    "Football's ___ Armstrong",
    "Elevator brand",
    "Mayberry toper",
    "Mayberry jail habitue",
    "Singer Redding",
    "Name that has its ups and downs",
    "1989 film \"Milo & ___\"",
    "Miss in a 1934 song",
    "Cager Thorpe",
    "\"Miss ___ Regrets\" (1934 hit)",
    "Football's Armstrong",
    "His invention made skyscrapers possible",
    "Elevator innovator",
    "Publisher Chandler",
    "Mayberry sot",
    "\"Miss ___ Regrets\"",
    "Inventor Elisha",
    "Mayberry sot",
    "Revolutionary orator James",
    "Elevator inventor",
    "Redding of 60's soul",
    "James who originated the phrase \"Taxation without representation\"",
    "Big name in elevators",
    "Singer Redding",
    "Colonial pamphleteer James",
    "Inventor Elisha",
    "Elevator man",
    "Football's Armstrong",
    "Newspaper publisher Chandler",
    "Mayberry tippler",
    "Groucho in \"A Night at the Opera\"",
    "Safety elevator inventor",
    "Mayberry jail habitue",
    "\"Miss ___ Regrets\"",
    "Mayberry sot",
    "See 12-Down",
    "Football's Armstrong",
    "Inventor Elisha",
    "Elevator inventor",
    "Elevator pioneer",
    "Man with a lift",
    "Elevator name",
    "\"Miss ___ Regrets\"",
    "Redding of 60's soul"
  ],
  "ague": [
    "Chills and fever",
    "Feverish fit",
    "Bad fit",
    "Bad fit",
    "Fit for a sweater",
    "Fever and chills",
    "Shivering fit",
    "It's not a good fit",
    "Fever fit",
    "Feverish chills",
    "Feverish fit",
    "Shivering fit",
    "Fever fit",
    "Chill in bed?",
    "Malarial fever",
    "Feverish fit",
    "Chill producer",
    "Fit of fever",
    "Malaria symptom",
    "Fit of fever",
    "Malaria symptom",
    "Feverish fit",
    "Possible flu symptom",
    "Fever fit",
    "Flu symptom",
    "Bad fit",
    "Malaria symptom",
    "Malarial fever",
    "Flu symptom",
    "Fit of shaking chills",
    "Flu symptom",
    "The chills",
    "Flu feature",
    "Flu symptom",
    "Fit of shivering",
    "Rigor of a fever",
    "Malarial symptom",
    "Chills and fever",
    "Cause of some shaking",
    "Flu symptom",
    "Flulike symptoms",
    "Chills and fever",
    "Tropical fever",
    "Chills and fever",
    "Shivery fever",
    "Chill",
    "Malaria symptom",
    "Fluish feeling",
    "Shaking spell",
    "Shivering fit",
    "Fit of fever",
    "Debilitating symptoms",
    "Chill",
    "Fever fit",
    "Shaking chill",
    "Fluish feeling",
    "Fit of fever",
    "Feverish fit",
    "Malarial fever",
    "Malarial fever",
    "Chills",
    "Malaria symptom",
    "Chills and fever",
    "Chill",
    "Malarial fever",
    "Flu feature",
    "Tropical fever",
    "It gives you fits",
    "Chill",
    "Malaria symptom",
    "Malaria symptom",
    "Jungle fever",
    "Fever",
    "Tropical woe",
    "Chills and fever",
    "Bad fit?",
    "Chills",
    "The shivers",
    "Malarial symptom",
    "Dickensian chill",
    "Fever"
  ],
  "pops": [
    "Daddy-o",
    "Daddy-o",
    "Boston ___",
    "Bursts, as a balloon",
    "Boston ___",
    "Daddy-o",
    "Daddy-o",
    "One of the folks",
    "Provider of some light fare",
    "Boston ___ (orchestra)",
    "Old man",
    "The old man",
    "The old man",
    "Bursts open",
    "Explodes",
    "Daddy-o",
    "Boston ___",
    "Old man",
    "Daddy-o",
    "Boston orchestra",
    "Daddy-o",
    "Certain orchestra",
    "Daddy-o",
    "Suddenly asks",
    "Bubble wrap sounds",
    "Daddy-o",
    "Daddy-o",
    "Boston ___ Orchestra",
    "Springs (for), slangily",
    "Boston bunch",
    "Daddy-o",
    "The Boston ___"
  ],
  "lana": [
    "Actress Condor of \"To All the Boys I've Loved Before\"",
    "Actress/YouTube star ___ Condor",
    "Singer Del Rey",
    "One of the film-directing Wachowskis",
    "Singer Del Rey",
    "Singer ___ Del Rey",
    "Singer ___ Del Rey",
    "Singer Del Rey",
    "Girl's name that's an anagram of 15-Across",
    "Onetime Bond girl ___ Wood",
    "Singer ___ Del Rey",
    "Actress Turner",
    "Superboy's love interest ___ Lang",
    "Turner of \"Peyton Place\"",
    "First name of an Oscar-nominated actress of 1957",
    "Pop singer ___ Del Rey",
    "Del Rey with the album \"Ultraviolence\"",
    "Actress Turner",
    "Singer Del Rey",
    "___ Del Rey, singer with the 2014 #1 album \"Ultraviolence\"",
    "Pop singer Del Rey",
    "Clark's Smallville crush",
    "___ Lang, Superboy's love",
    "Girl's name that becomes a boy's name when the last letter moves to the start",
    "Superboy's sweetie",
    "Superboy's sweetie",
    "Actress Turner",
    "1971 Bond girl ___ Wood",
    "Actress Turner",
    "Turner of Hollywood",
    "___ Lang of Smallville",
    "Smallville girl",
    "Turner who was known as the Sweater Girl",
    "Actress Turner",
    "Actress Turner",
    "Actress Turner",
    "Clark's crush on \"Smallville\"",
    "Superboy's crush",
    "Old-time actress Turner",
    "Turner of \"Somewhere I'll Find You,\" 1942",
    "Clark's crush on \"Smallville\"",
    "Superboy's girlfriend",
    "Actress Turner",
    "Turner of \"Peyton Place,\" 1957",
    "Actress Wood of \"Diamonds Are Forever\"",
    "Turner of Hollywood",
    "Turner who was called the Sweater Girl",
    "Turner of \"By Love Possessed\"",
    "Singer Cantrell",
    "Joel's seducer in \"Risky Business\"",
    "Actress Turner",
    "A Turner",
    "Actress Turner",
    "Turner of film",
    "A Turner",
    "Superboy's girlfriend",
    "A Turner",
    "Turner known as the Sweater Girl",
    "Actress Wood of \"Peyton Place\"",
    "Glamorous actress Turner",
    "Superboy's sweetheart",
    "Turner who was called the \"Sweater Girl\"",
    "Actress Wood",
    "Actress Turner",
    "Sweater girl Turner",
    "Actress Turner",
    "___ Lang, Superboy's girlfriend",
    "Actress Turner",
    "Actress Wood of \"Diamonds Are Forever\"",
    "Turner who was called \"The Sweater Girl\"",
    "Actress Turner",
    "An ex of Artie",
    "Turner or Wood",
    "Turner, the 40's Sweater Girl",
    "A Turner",
    "Superboy's girlfriend",
    "She played \"Diane,\" 1956",
    "One of Artie's exes",
    "Actress Turner",
    "Turner of the screen",
    "Turner of \"Peyton Place\"",
    "Actress Turner",
    "Actress Turner",
    "Turner of movies",
    "Lang of comics' Smallville",
    "\"Peyton Place\" actress Wood",
    "Actress Turner",
    "Actress Turner",
    "Actress Turner",
    "___ Lang (Superbody's girlfriend)",
    "Superboy's girlfriend",
    "Turner of Hollywood",
    "Miss Cantrell"
  ],
  "alda": [
    "Actor Alan",
    "Alan of \"The Aviator\"",
    "Actor Alan of \"Crimes and Misdemeanors\"",
    "\"M*A*S*H\" co-star Alan",
    "Portrayer of Senator Vinick on \"The West Wing\"",
    "Actor who played Senator Vinick on \"The West Wing\"",
    "Multi-Emmy-winning actor whose first and last names start with the same two letters",
    "Alan with six Emmys",
    "Actor with the 2007 memoir \"Things I Overheard While Talking to Myself\"",
    "Hawkeye's player on \"M*A*S*H\"",
    "Famous Alan whose last name shares three of the four letters of ALAN",
    "\"Manhattan Murder Mystery\" actor, 1993",
    "Actor nominated for 34 Emmys",
    "Director of the \"M*A*S*H\" finale",
    "Alan who wrote the book \"If I Understood You, Would I Have This Look on My Face?\"",
    "Alan in the Television Hall of Fame",
    "Actor Alan",
    "Alan of \"Bridge of Spies\"",
    "\"M*A*S*H\" man",
    "\"The Aviator\" actor, 2004",
    "Vinick's portrayer on \"The West Wing\"",
    "Actor whose four-letter first name shares three letters with his last",
    "Alan who played Captain Pierce",
    "Senator Vinick's portrayer on \"The West Wing\"",
    "\"What Women Want\" actor",
    "Oscar nominee for \"The Aviator\"",
    "Oscar-nominated actor for \"The Aviator\"",
    "Actor whose last name is a 41-Down of his first name, after a D is changed to an N",
    "Alan who played Hawkeye",
    "He played the U.S. president in \"Canadian Bacon,\" 1995",
    "Actor Alan",
    "Robert of \"The Girl Who Knew Too Much\"",
    "He played Hawkeye on \"M*A*S*H\"",
    "Photographer/writer Arlene",
    "Pierce portrayer",
    "Alan of \"The Aviator\"",
    "Gershwin portrayer in \"Rhapsody in Blue\"",
    "Alan of \"M*A*S*H\"",
    "\"M*A*S*H\" star",
    "Tynan player in \"The Seduction of Joe Tynan\"",
    "\"Tower Heist\" actor, 2011",
    "Star of the most-watched TV episode ever",
    "Host of PBS's \"Scientific American Frontiers\"",
    "\"M*A*S*H\" star",
    "Alan who played Hawkeye",
    "Alan who won an Emmy for his role on \"The West Wing\"",
    "Alan who was born Alphonso D'Abruzzo",
    "Alan of \"Crimes and Misdemeanors\"",
    "1985 Oscars co-host with Fonda and Williams",
    "\"Crimes and Misdemeanors\" actor, 1989",
    "Alan of Hollywood",
    "Alan of \"M*A*S*H\"",
    "Alan who played TV's Hawkeye",
    "Only person to win Emmys for acting, writing and directing",
    "Capt. Pierce player",
    "Alan who directed and starred in \"Betsy's Wedding\"",
    "Alan of \"M*A*S*H\"",
    "2006 Emmy winner for \"The West Wing\"",
    "\"M*A*S*H\" star",
    "Pierce player on TV",
    "Tony nominee for \"Glengarry Glen Ross\"",
    "Robert who won a Tony for \"Guys and Dolls\"",
    "Tynan player in \"The Seduction of Joe Tynan\"",
    "Plimpton portrayer in \"Paper Lion\"",
    "\"The Seduction of Joe Tynan\" star, 1979",
    "Pierce player",
    "Tony winner for \"Guys and Dolls,\" 1951",
    "Alan of \"Betsy's Wedding\"",
    "Hawkeye's portrayer",
    "Robert of Broadway's \"Guys and Dolls\"",
    "\"Paper Lion\" star, 1968",
    "\"Jenny\" co-star, 1970",
    "\"Sweet Liberty\" director and star",
    "\"The West Wing\" actor",
    "Hawkeye player on \"M*A*S*H\"",
    "Alan of \"M*A*S*H\"",
    "\"Betsy's Wedding\" star",
    "\"The Aviator\" actor, 2004",
    "Oscar nominee for \"The Aviator,\" 2004",
    "Longtime host of \"Scientific American Frontiers\"",
    "\"Same Time, Next Year\" star",
    "Pierce portrayer on TV and film",
    "Hawkeye player on \"M*A*S*H\"",
    "13-Down player",
    "Alan of \"M*A*S*H\"",
    "Alan of \"The Seduction of Joe Tynan\"",
    "\"Betsy's Wedding\" star, 1990",
    "Alan of \"M*A*S*H\"",
    "Hawkeye player",
    "\"Betsy's Wedding\" actor",
    "See 58-Across",
    "\"M*A*S*H\" star",
    "Alan of \"Betsy's Wedding\"",
    "Actor Robert or Alan",
    "Emmy-winner Alan",
    "Pierce's portrayer",
    "Alan of \"Manhattan Murder Mystery\"",
    "Actor Alan",
    "\"Betsy's Wedding\" director",
    "1940's-80's actor Robert",
    "Actor Alan",
    "\"The Four Seasons\" star",
    "Robert of \"Guys and Dolls\"",
    "Pierce player on \"M*A*S*H\"",
    "\"Betsy's Wedding\" star",
    "\"The Seduction of Joe Tynan\" star",
    "\"A New Life\" director",
    "Swit's TV co-star",
    "Joe Tynan's portrayer, 1979",
    "\"M*A*S*H\" star",
    "Actor whose last name has three letters of his first",
    "Co-star of Farrell and Swit",
    "\"Crimes and Misdemeanors\" actor",
    "See 5-Down",
    "Two-time Emmy winner as best actor in a comedy",
    "\"Sweet Liberty\" star",
    "\"The Mephisto Waltz\" star",
    "Hawkeye Pierce portrayer",
    "Pierce player on TV",
    "\"Crimes & Misdemeanors\" actor",
    "TV's Hawkeye",
    "Actor Alan",
    "Actor Alan",
    "\"Everyone Says I Love You\" actor",
    "\"Crimes and Misdemeanors\" actor",
    "Capt. Pierce portrayer",
    "Pierce's portrayer",
    "Alan of \"The Four Seasons\"",
    "Actor Alan",
    "He was Pierce on \"M*A*S*H\"",
    "\"M*A*S*H\" actor",
    "?ôSweet Liberty?ö star",
    "\"California Suite\" star",
    "Hawkeye Pierce's portrayer",
    "Soprano Frances",
    "Actor Robert",
    "Swit co-star",
    "\"Guys and Dolls\" Tony winner, 1951",
    "\"Sweet Liberty\" star",
    "Hawkeye portrayer"
  ],
  "caen": [
    "French city where William the Conqueror is buried",
    "Large urban area in Normandy, France",
    "Battle of Normandy city",
    "French city that was an objective for recapture on D-Day",
    "Battle of Normandy locale",
    "City largely destroyed in Operation Charnwood",
    "French city once held by William the Conqueror",
    "D-Day locale",
    "Normandy city where William the Conqueror is buried",
    "City east of St.-Lo",
    "Battle of Normandy locale",
    "Battle of Normandy city",
    "Normandy campaign city",
    "W.W. II battle town",
    "Battle of Normandy city",
    "Normandy city",
    "Battle of Normandy city",
    "\"It's News to Me\" columnist Herb",
    "Setting of William the Conqueror's castle",
    "Columnist who wrote \"Baghdad by the Bay\"",
    "Columnist who wrote \"Don't Call It Frisco,\" 1953",
    "Battle of Normandy city",
    "Longtime columnist who coined the term \"beatnik\"",
    "City largely destroyed by the Normandy campaign",
    "French city where William the Conqueror is buried",
    "Town largely destroyed by the Battle of Normandy",
    "Normandy city",
    "Normandy city",
    "Normandy city",
    "City near Le Havre",
    "French city largely destroyed during the Normandy campaign",
    "Where William the Conqueror is buried",
    "\"One Man's San Francisco\" author",
    "Late San Francisco columnist Herb",
    "French city heavily hit in 1944",
    "City of northern France",
    "Columnist Herb",
    "City retaken by the Allies, July 9, 1944",
    "Normandy city",
    "Normandy city",
    "L'Abbaye-aux-Hommes locale",
    "D-Day city",
    "D-Day city",
    "Columnist Herb"
  ],
  "grit": [
    "Toughness",
    "Sandpaper specification",
    "Stick-to-itiveness",
    "Determination",
    "Stick-to-itiveness",
    "Stick-to-it-iveness",
    "Annoyance for an oyster eater",
    "Steadfast determination",
    "Moxie",
    "Stick-to-it-iveness",
    "Fortitude",
    "Moral toughness",
    "Clench",
    "Bits of sand",
    "Stick-to-it-iveness",
    "Determination",
    "Sandpaper surface",
    "Sand",
    "Determination",
    "Nerve",
    "Staying power",
    "Pluck",
    "Sand",
    "Stick-to-it-iveness",
    "Stick-to-itiveness",
    "Beach sandal problem",
    "Sand",
    "Backbone",
    "Sandpaper coating",
    "Indomitable spirit",
    "Sand inside a shoe, e.g.",
    "Doggedness",
    "It bollixes up the machinery",
    "Stick-to-itiveness",
    "Pluck",
    "Stick-to-it-iveness",
    "Fortitude",
    "Pluck",
    "It's stuck in beach sandals",
    "Sand",
    "Determination",
    "Gumption",
    "Sandpaper surface"
  ],
  "ripe": [
    "Yellow, as a banana",
    "Ready",
    "What comes before old age?",
    "Ready for picking",
    "Completely ready",
    "As good as it's going to get?",
    "Ready for picking",
    "No longer green, say",
    "Smelly",
    "Red, perhaps",
    "Like thumped watermelons making a deep sound",
    "Opportune",
    "Mature",
    "Ready ... or red, maybe",
    "Having sat in the locker room way too long",
    "Ready (for)",
    "Well positioned (for)",
    "Stinky",
    "Like yellow 17-Acrosses",
    "Ready",
    "Ready for picking",
    "Opportune",
    "Smelly",
    "Pickable",
    "Offensive-smelling",
    "Ready (for)",
    "Strong-smelling, say",
    "Finally edible",
    "Yellow, as a banana",
    "Smelly",
    "Sufficiently aged",
    "Stinky",
    "Opportune",
    "Fully red, as a raspberry",
    "Ready enough",
    "Smelly, as post-workout clothes",
    "Like many old gym socks",
    "Between green and black, say",
    "Rather smelly",
    "Stinky, as gym clothes",
    "Between green and black, maybe",
    "Fully ready",
    "Red, maybe",
    "Opportune",
    "Ready for picking",
    "Ready for picking",
    "Ready for picking",
    "Ready",
    "Seasoned",
    "Ready to eat",
    "Pickable",
    "Red, as an apple",
    "Fully prepared (for)",
    "It may precede old age",
    "Fully developed",
    "Ready to pluck",
    "Ready for plucking",
    "Like overused gym clothes",
    "Ready to pluck",
    "Ready to pluck",
    "Ready to eat",
    "Ready",
    "Like a yellow banana",
    "Ready for plucking",
    "Opportune",
    "Pickable",
    "Ready",
    "Ready to be picked",
    "Full-fledged",
    "Pluckable",
    "Properly aged",
    "Ready to eat",
    "Ready for picking",
    "Ready to pick",
    "Ready for picking",
    "Ready for harvest",
    "Ready to eat",
    "Ready to pick",
    "Mature",
    "In one?Æs prime",
    "Ready for picking",
    "In one's prime",
    "Ready",
    "Good enough to eat",
    "Quite ready",
    "Ready enough"
  ],
  "eric": [
    "\"Spamalot\" writer Idle",
    "Guitarist Clapton",
    "Man found in America?",
    "Prince in \"The Little Mermaid\"",
    "Idle of Monty Python",
    "Indiana governor Holcomb",
    "Guitarist Clapton",
    "Guitarist Clapton",
    "Lexicographer Partridge",
    "Guitarist Clapton",
    "Name of seven Danish kings",
    "Prince in \"The Little Mermaid\"",
    "Former attorney general Holder",
    "Comedian Andre with a self-named Adult Swim show",
    "Former attorney general Holder",
    "With 1-Down, player of the Hulk in 2003's \"Hulk\"",
    "Former attorney general Holder",
    "Michael ___ Dyson, author on race, politics and culture",
    "George Orwell's real first name",
    "Comedic actor Wareheim",
    "Rock's Burdon or Clapton",
    "Guitar legend Clapton",
    "Idle on the set",
    "California politico Garcetti",
    "\"Spamalot\" creator Idle",
    "With 1-Down, star of Spielberg's \"Munich\"",
    "Viking explorer",
    "Rock's Clapton or Burdon",
    "Runner Liddell depicted in \"Chariots of Fire\"",
    "___ Rohmer, French New Wave director",
    "Country star Church",
    "Guitarist Clapton",
    "Holder of the Obama cabinet",
    "Holder of the Obama cabinet",
    "Author Michael ___ Dyson",
    "Clapton who sang \"Layla\"",
    "Idle laughter source?",
    "Speed skater Heiden",
    "Ivanka's younger brother",
    "Brother of Donald Trump Jr.",
    "Former attorney general Holder",
    "With 2-Down, star of 2003's \"Hulk\"",
    "Decker or Dickerson of the N.F.L.",
    "One of the Trumps",
    "Bana of \"Troy\"",
    "Country singer Church",
    "\"South Park\" boy",
    "\"Layla\" musician Clapton",
    "___ Blair, George Orwell's real name",
    "___ the Red",
    "___ the Red",
    "Hoffer or Holder",
    "___ Church, country singer with the #1 hits \"Drink in My Hand\" and \"Springsteen\"",
    "___ Blair, George Orwell's real name",
    "Holder of a cabinet position, formerly",
    "Cartman's first name on \"South Park\"",
    "___ Holder, first African-American attorney general",
    "\"True Blood\" vampire Northman",
    "Football Hall-of-Famer Dickerson",
    "Singer Clapton",
    "Actor Stoltz",
    "Holder of a cabinet position",
    "Cantor of Congress",
    "Guitarist Clapton",
    "Attorney General Holder",
    "Comical Idle",
    "Actor Roberts",
    "\"Spamalot\" writer Idle",
    "\"The Little Mermaid\" prince",
    "Representative Cantor",
    "___ the Red",
    "Spy novelist Ambler",
    "___ the Red (Viking explorer)",
    "\"South Park\" boy",
    "Attorney general Holder",
    "Guitarist Clapton",
    "Any of seven Danish kings",
    "Novelist Ambler",
    "Clapton of rock",
    "Attorney General Holder",
    "Attorney General Holder",
    "Google executive Schmidt",
    "Hockey's Lindros",
    "Attorney General Holder",
    "Children's author Carle",
    "Holder in the Obama cabinet",
    "___ the Midget, regular on \"The Howard Stern Show\"",
    "Attorney General Holder",
    "Idle of \"Life of Brian\"",
    "House Republican V.I.P. Cantor",
    "Playwright Bogosian",
    "Red man?",
    "N.F.L. Hall-of-Famer Dickerson",
    "Cartman's first name on \"South Park\"",
    "Rock's Clapton",
    "Lassie creator Knight",
    "Actor Bana of \"Munich\"",
    "Prince in \"The Little Mermaid\"",
    "Speed skater Heiden",
    "Greenland colonizer",
    "Newsman Sevareid",
    "Pitcher Gagne",
    "N.H.L.'er Nystrom",
    "Novelist Ambler",
    "___ the Red",
    "___ the Red",
    "Speed skater Heiden",
    "Rock's Clapton",
    "Hockey star Lindros",
    "Clapton who sang \"Layla\"",
    "Clapton who sang \"Layla\"",
    "Olympian Heiden",
    "Mystery writer Ambler",
    "Slang expert Partridge",
    "Rocker Clapton",
    "Children's author Carle",
    "Guitarist Clapton",
    "Journalist Sevareid",
    "___ the Red",
    "Red-haired adventurer",
    "Newsman Sevareid",
    "Stoltz of \"Pulp Fiction\"",
    "___ the Red",
    "Bana who played the Incredible Hulk in film",
    "Guitarist Clapton",
    "Director Rohmer",
    "Pop singer Carmen",
    "McCormack of \"Will & Grace\"",
    "N.H.L. star Lindros",
    "Idle in comedy",
    "Novelist Ambler",
    "Rocker Clapton",
    "\"The Little Mermaid\" hero",
    "Rock's Clapton",
    "Novelist Ambler",
    "Director Rohmer",
    "Guitarist Clapton",
    "Lexicographer Partridge",
    "Playwright Bogosian",
    "Philosopher Hoffer",
    "___ the Red",
    "Writer Hoffer",
    "Montross of the N.B.A.",
    "Speedskater Heiden",
    "Writer Ambler",
    "Idle in a \"circus\"",
    "Idle of the Python troupe",
    "Mystery writer Ambler",
    "Slang expert Partridge",
    "N.H.L.'s Lindros",
    "Idle fellow?",
    "Newsman Sevareid",
    "Grid great Dickerson",
    "Speed skater Heiden",
    "Stoltz of \"Mask\"",
    "Lexicographer Partridge",
    "Playwright Bogosian",
    "Guitarist Clapton",
    "Explorer called \"the Red\"",
    "Late CBS newsman Sevareid",
    "Rock's Clapton",
    "Language writer Partridge",
    "Skater Heiden",
    "\"The Little Mermaid\" prince",
    "\"The True Believer\" author Hoffer",
    "Author Ambler",
    "Idle on the screen",
    "Pop music's Clapton or Carmen",
    "N.F.L.'s Dickerson",
    "Actor Stoltz",
    "Hockey's Lindros",
    "Famous redhead",
    "With 27-Down, old English character actor",
    "Explorer called \"the Red\"",
    "Actor Stoltz",
    "___ the Red",
    "Lassie creator Knight",
    "Guitarist Clapton",
    "Newsman Sevareid",
    "Davis of baseball",
    "Rock singer Carmen",
    "Skater Heiden",
    "Norse mariner",
    "Playwright Bogosian",
    "Dickerson of the N.F.L.",
    "10th-century explorer",
    "Lexicographer Partridge",
    "Language maven Partridge",
    "Psychiatrist Berne",
    "Late newsman Sevareid",
    "Satirist Idle",
    "The Red",
    "Philosopher Hoffer",
    "Late newscaster Sevareid",
    "Singer Clapton",
    "Skater Heiden",
    "Folk singer___Anderson",
    "Rock's Clapton or Carmen",
    "Newsman Sevareid",
    "\"The Red\"",
    "Philosopher Hoffer",
    "Newsman Sevareid",
    "___the Red",
    "___ the Red",
    "Guitarist Clapton",
    "Playwright Bogosian",
    "Guitarist Clapton",
    "Idle of \"Monty Python\"",
    "Author Ambler",
    "Guitarist Clapton",
    "Idle of \"Monty Python\"",
    "Red navigator",
    "Lexicographer Partridge",
    "Roberts of \"Star 80\"",
    "Skater Heiden",
    "Folk singer Andersen",
    "Rock star Clapton",
    "Author Lustbader",
    "Playwright Bogosian",
    "Skater Heiden",
    "Pop singer Burdon",
    "\"The Red\""
  ],
  "sink": [
    "What some ships and hearts do",
    "What some ships and hearts do",
    "What things do in quicksand",
    "What hearts do when things look bad",
    "Swim's alternative",
    "Where dirty dishes pile up",
    "Ruin",
    "Deteriorate",
    "Place for plates",
    "Settle",
    "Put in the hole",
    "Where dirty dishes pile up",
    "Plummet",
    "Send down",
    "Dirty dishes locale",
    "Become understood, with \"in\"",
    "Make, as a putt",
    "Wash-up spot",
    "Settle",
    "Scuttle",
    "Make, as a putt",
    "Swim's alternative",
    "Descend",
    "Drown",
    "Swim's alternative"
  ],
  "like": [
    "Thumbs-up icon meaning",
    "\"What's that ___?\"",
    "What a thumbs-up icon might represent",
    "Much-overused filler word",
    "Fancy",
    "Facebook action",
    "Valley girl's filler",
    "Reminiscent of",
    "Modern verbal crutch",
    "Be fond of",
    "Give a thumbs-up on Facebook",
    "___ button (Facebook icon)",
    "Go for",
    "Go for",
    "Not far from",
    "Take a shine to",
    "Simile part",
    "Akin to",
    "Parallel to",
    "Go for",
    "Similar to",
    { "formula": "-ish", "result": { "error": "#NAME?" } },
    "Get off on",
    "Word of Valleyspeak",
    "Go for",
    "Dig, so to speak",
    "Parallel to"
  ],
  "lids": [
    "Pot covers",
    "Hats, slangily",
    "Targets for some eye makeup",
    "Elusive Tupperware components, often",
    "Stack at Starbucks",
    "Jar tops",
    "Lens caps?",
    "Coffee shop freebies",
    "Preserves covers?",
    "They open in the morning",
    "Caps",
    "Tops",
    "Hats, informally",
    "Eyeshades?",
    "Tupperware stock",
    "They may be flipped",
    "Caps",
    "Jar tops",
    "Tops",
    "Toppers",
    "They close at night",
    "Eye liners",
    "Seal creators, sometimes",
    "Jar tops",
    "Facial pair",
    "Spending restraints",
    "Eyeball covers",
    "They're tops",
    "Blinkers",
    "They're the tops",
    "Toppers",
    "Jar tops",
    "Eye parts",
    "Box tops",
    "Tupperware pieces",
    "Eye covers",
    "Pots' tops",
    "Can-opener targets"
  ],
  "mayo": [
    "Spread on a banh mi",
    "Hellmann's product, informally",
    "\"Hold the ___\" (deli order)",
    "Minnesota's ___ Clinic",
    "Part of a club",
    "Chicken salad ingredient",
    "Main ingredient of remoulade",
    "Ingredient in Marie Rose sauce",
    "Subway option",
    "Potato salad ingredient, informally",
    "It's part of a club",
    "Spread out in the kitchen?",
    "Hellmann's product, informally",
    "Tuna salad ingredient",
    "Bread spread",
    "It's sometimes held at a deli",
    "Month after abril",
    "Lunch spread, often",
    "Spread for lunch, maybe",
    "Sandwich spread",
    "Tartar sauce ingredient",
    "Deli spread",
    "B.L.T. ingredient",
    "It may be held at a 39-Down",
    "Clinic name",
    "Deli supply",
    "It's not held when it's used",
    "Tuna salad ingredient",
    "Waldorf salad ingredient",
    "It's held at eateries",
    "Sandwich spread, for short",
    "Deli spread",
    "___ Clinic",
    "___ Clinic",
    "Deli jarful",
    "Deli jarful",
    "Waldorf salad ingredient, for short",
    "___ Clinic",
    "Deli spread",
    "It may be held in delis",
    "11-Down extra",
    "County in NW Ireland",
    "___Brothers",
    "Sandwich topping",
    "Certain spread",
    "Minnesota clinic",
    "Cinco de ___ (Mexican holiday)",
    "It's often held in diners"
  ],
  "biel": [
    "Jessica of \"Valentine's Day\"",
    "Actress Jessica of \"Hitchcock\"",
    "Actress Jessica",
    "Actress Jessica",
    "Jessica of \"7th Heaven\"",
    "Jessica of \"The Book of Love\"",
    "Jessica of \"The Illusionist\"",
    "Jessica of \"The Texas Chainsaw Massacre\"",
    "Jessica of \"7th Heaven\"",
    "Jessica of \"The Illusionist\"",
    "Actress Jessica",
    "Jessica of \"7th Heaven\"",
    "Jessica of \"Hitchcock\"",
    "Jessica of \"The Illusionist\""
  ],
  "vets": [
    "November parade participants, informally",
    "People calling the shots at the zoo?",
    "Docs for dogs",
    "May marchers",
    "Animal docs",
    "Carefully examines",
    "Ones who fix toys?",
    "Nov. honorees",
    "Spot checkers?",
    "Some zoo employees",
    "They're out of service",
    "Some November paraders, for short",
    "Ones out of service?",
    "Checks out",
    "Ones doing lab exams?",
    "Checks for accuracy",
    "Checks out thoroughly",
    "Kerry and McCain, e.g.",
    "Looks over",
    "Fact-checks",
    "Ones with war stories",
    "Dog docs",
    "November honorees",
    "Docs for dachshunds",
    "11/11 honorees",
    "Cat scanners",
    "Nov. 11 honorees",
    "Dalmatian docs",
    "Seasoned hands",
    "Retrievers' relievers?",
    "Annual paraders",
    "November honorees",
    "Docs for dachshunds",
    "Animal docs",
    "November honorees"
  ],
  "volt": [
    "What \"V\" may stand for in electronics",
    "Plug-in Chevy model",
    "Lightning unit",
    "Eco-friendly car introduced in 2011",
    "Chevy plug-in car",
    "V, in physics",
    "Electric Chevy model",
    "Electric Chevy",
    "General Motors product",
    "Pioneering General Motors electric car",
    "Lead-in to meter",
    "Battery unit",
    "Electrical unit",
    "Fencer's move",
    "Electric unit",
    "Electrical unit",
    "Part of mV",
    "Electric unit",
    "It's shocking!",
    "Electric unit",
    "Electrical unit",
    "Bit of a shock",
    "Bit of a charge",
    "It's shocking!"
  ],
  "lead": [
    "One getting top billing",
    "Top-billed performer",
    "Material in automobile batteries",
    "Heavy metal",
    "Heavy metal",
    "Aid for a detective",
    "Play auditioner's hope",
    "Pb",
    "Clue for a detective",
    "Pb, to chemists",
    "Main role",
    "Pb, to a chemist",
    "Show the way",
    "It blocks Superman's X-ray vision",
    "Modern paint no-no",
    "Heavy metal",
    "With 42-Down, literally, control completely",
    "Dancer Fred Astaire, to Adele",
    "Radiation shield material",
    "Starring role",
    "Period for preparation",
    "Fat part",
    "Detective's aid",
    "Alchemy material",
    "Sleuth's quest",
    "With 34-Across, slag furnace input",
    "Male ballroom dancer, traditionally",
    "X-ray vision blocker",
    "Starring role",
    "Pb, in chemistry",
    "Detective's need",
    "Chief",
    "Aid for a detective",
    "Essential for an investigator",
    "Galena extract",
    "Show the way",
    "__ poisoning",
    "One lap, maybe, in a race",
    "What a detective follows",
    "Tip",
    "Extraction from galena ore",
    "Show the way",
    "Starring role",
    "Metal that Superman can't see through",
    "Detective?Æs need",
    "Its symbol is Pb",
    "Make the first moves, in dancing",
    "Help for a sales rep",
    "Get ahead",
    "Guide",
    "Heavy metal",
    "X-ray blocker",
    "Starring role",
    "One of two ballroom dancers",
    "Front-runner's edge",
    "Sinker material",
    "One of a pair of dancers",
    "X-ray vision blocker",
    "Not back up",
    "Hindrance for Superman",
    "Pewter component",
    "Big part",
    "Detective's need",
    "Best part",
    "Pencil filler",
    "Helpful information",
    "Actor's goal",
    "Kind of balloon",
    "One of a ballroom couple",
    "Pencil's innards",
    "Pb, elementally speaking",
    "Kind of story",
    "Star",
    "Conduct",
    "X-ray vision blocker",
    "Be the bellwether",
    "Conduct",
    "Guide on the dance floor",
    "Starring role",
    "X-ray vision blocker",
    "Hindrance for Superman",
    "Hot clue",
    "Problem for Superman"
  ],
  "osha": [
    "Hazmat regulator",
    "Agcy. dealing with workplace accidents",
    "Govt. plant inspector",
    "Org. with lots of inspectors",
    "Factory-inspecting org.",
    "Workplace-inspecting org.",
    "Factory-inspecting org.",
    "Worker protection org.",
    "Labor-regulating org.",
    "Org. tracking workplace accidents",
    "Group concerned with things that are NSFW?",
    "Fed. monitor of workplace hazards",
    "Hazmat regulator",
    "Agcy. headed by a deputy asst. secretary of labor",
    "Org. for some inspectors",
    "Workplace inspection org.",
    "Govt. org. for whistle-blowers",
    "Org. with inspectors",
    "Dept. of Labor agcy.",
    "Org. that's found by accident?",
    "Dept. of Labor arm",
    "Labor agcy.",
    "Grp. that doesn't cover the self-employed",
    "Plant watcher, for short",
    "Worker protection org.",
    "Govt. org. that gets tips from whistleblowers",
    "Arm of the Dept. of Labor",
    "It may require gloves, for short",
    "Factory watchdog grp.",
    "Dept. of Labor branch",
    "Workplace protection agcy.",
    "Employee protection org.",
    "It works for workers, in brief",
    "Sweatshop regulator, for short",
    "Dept. of Labor arm",
    "Workplace welfare org.",
    "Lab safety org.",
    "Org. that, when spelled backward, is an old-timey exclamation",
    "Workplace rules setter, for short",
    "Hazmat monitor",
    "Factory-inspecting org.",
    "Hazmat-monitoring org.",
    "Part of the Dept. of Labor",
    "Dept. of Labor division",
    "Dept. of Labor division",
    "Workplace watchdog, in brief",
    "Watchdog org.",
    "Workplace welfare org.",
    "Federal watchdog agcy.",
    "Federal org. with compliance officers",
    "Factory-inspecting org.",
    "Dept. of Labor division",
    "Dept. of Labor agency",
    "Dept. of Labor arm",
    "Federal org. with inspectors",
    "Producer of workplace regs.",
    "Org. concerned with workplace injuries",
    "Job conditions agcy.",
    "Injury-monitoring org.",
    "Worker protection agcy.",
    "Govt. watchdog since 1970",
    "Workplace watchdog, for short",
    "Worker welfare grp.",
    "Dept. of Labor arm",
    "Workers' protection agcy.",
    "Labor Dept. watchdog",
    "Dept. of Labor division",
    "Workplace watchdog grp.",
    "Workplace watchdog org.",
    "Org. setting workplace rules",
    "Workplace watchdog org.",
    "Agcy. concerned with ergonomics",
    "Workplace watchdog org.",
    "It's headed by a deputy asst. secy. of labor",
    "Construction site watchdog, for short",
    "Workplace stds. enforcer",
    "Labor Dept. watchdog",
    "Department of Labor agcy.",
    "Nixon creation of 1970: Abbr.",
    "Dept. of Labor branch",
    "Arm of the Dept. of Labor",
    "Dept. of Labor agcy.",
    "Org. that inspects factories",
    "Labor Dept. arm",
    "Dept. of Labor div.",
    "Fed. workplace watchdog",
    "Dept. of Labor arm",
    "Agency with workplace regs.",
    "Workers' protection org.",
    "Hazmat regulator",
    "Dept. of Labor div.",
    "Worker safety grp.",
    "Part of the Dept. of Labor",
    "Workplace watchdog grp.",
    "Workers' welfare overseer: Abbr.",
    "Fed. watchdog since 1971",
    "Fed. workplace watchdog",
    "Govt. workplace watchdog",
    "Dept. of Labor division",
    "Fed. workplace watchdog",
    "Part of the Dept. of Labor",
    "Workplace watchdog, for short",
    "Workplace safety org.",
    "Accident-monitoring grp.",
    "Fed. workplace monitor",
    "Dept. of Labor watchdog",
    "Govt. watchdog",
    "Dept. of Labor arm",
    "Dept. of Labor agcy.",
    "Workplace watchdog org.",
    "Division of Labor: Abbr.",
    "Govt. regulators",
    "Worker protection grp.",
    "Corp. monitor",
    "Dept. of Labor division",
    "Worker protection org.",
    "D.C. regulators",
    "Dept. of Labor grp.",
    "Workplace regulator, for short",
    "Org. with many rules",
    "Accident-preventing org.",
    "Labor Dept. div.",
    "Worker welfare org.",
    "Dept. of Labor division",
    "Labor Dept. division",
    "Org. protecting workers",
    "Regulatory grp. since 1970",
    "Watchmen's watchdog?: Abbr.",
    "Govt. watchdog",
    "Workplace regulator, for short",
    "Org. concerned with working conditions",
    "Worker protection org.",
    "Fed. watchdog",
    "Division of Labor, briefly",
    "Dept. of Labor division",
    "Workers' protection org.",
    "Workplace overseer, for short",
    "Employer watchdog: Abbr.",
    "Labor Dept. section",
    "Worker protection grp.",
    "Workers' watchdog grp.",
    "Part of the Dept. of Labor",
    "Fed. watchdog",
    "Part of the Labor Dept.",
    "Job hazard regulator: Abbr.",
    "Labor Dept. arm",
    "Govt. watchdog grp.",
    "Dept. of Labor division",
    "Part of the Dept. of Labor",
    "Safety org.",
    "Worker-welfare watchdog: Abbr.",
    "Agcy. founded in 1970"
  ],
  "warp": [
    "Bend out of shape",
    "\"Let's do the Time ___ again\" (campy 1970s lyric)",
    "Bad thing on a record",
    "Get bent",
    "Record defect",
    "Get bent out of shape",
    "Bend out of shape",
    "___ speed",
    "Distort",
    "Measure of speed in \"Star Trek\"",
    "Word before speed or after time",
    "Twist out of shape",
    "Problem with an old record",
    "___ speed",
    "Kind of speed, in \"Star Trek\"",
    "\"Star Trek\" speed",
    "At ___ speed (quickly)",
    "\"Star Trek\" speed",
    "Like some speeds",
    "Not keep straight",
    "Buckle",
    "Twist"
  ],
  "cons": [
    "Bamboozles",
    "Bamboozles",
    "Disadvantages found in this puzzle's three longest Across answers ...",
    "Drawbacks",
    "Drawbacks",
    "Fleeces",
    "Inmates",
    "Takes in",
    "Yardbirds",
    "Deceives",
    "Reasons to say no",
    "Bad points",
    "Negative points",
    "Not pros",
    "Scams",
    "Takes for a ride",
    "Bamboozles",
    "Hustles",
    "Cooler fill",
    "Pen denizens",
    "Pen fill",
    "Hoodwinks",
    "Swindles",
    "Flimflammery",
    "Reasons to vote no",
    "Tricks",
    "Alcatraz population, once",
    "Hustles",
    "Hoodwinks",
    "Bamboozles",
    "Bamboozles",
    "Reasons against",
    "Takes for a ride"
  ],
  "asto": [
    "Regarding",
    "Apropos of",
    "Regarding",
    "Regarding",
    "Regarding",
    "For",
    "Regarding",
    "Concerning",
    "Concerning, in a memo",
    "About",
    "Regarding, in a memo",
    "Regarding",
    "Regarding",
    "Regarding",
    "Regarding",
    "Regarding",
    "Regarding",
    "Regarding",
    "Apropos of",
    "Regarding",
    "About",
    "Regarding",
    "Concerning",
    "Concerning",
    "Regarding",
    "Regarding",
    "Concerning",
    "Regarding",
    "Regarding, in a memo",
    "Regarding",
    "Concerning",
    "Regarding",
    "Memo segue",
    "Regarding",
    "About",
    "Regarding",
    "Concerning",
    "Repeated words in a multiple-count verdict reading",
    "Regarding",
    "Regarding",
    "Regarding",
    "Regarding",
    "Referential words",
    "Regarding",
    "Concerning",
    "Concerning",
    "Concerning",
    "About",
    "Regarding",
    "Regarding",
    "Concerning",
    "Regarding",
    "Respecting",
    "Anent",
    "Concerning",
    "About",
    "Topic lead-in",
    "Regarding",
    "Regarding",
    "Concerning",
    "Concerning",
    "About",
    "Regarding",
    "Concerning",
    "Concerning",
    "On",
    "Vis-a-vis",
    "Memo start",
    "In the matter of",
    "Regarding",
    "Regarding",
    "Regarding",
    "Topic lead-in",
    "Regarding",
    "Regarding",
    "In connection with",
    "On the subject of",
    "Regarding",
    "About",
    "Concerning",
    "About",
    "Communique segue",
    "About",
    "Concerning, in a memo",
    "Concerning",
    "Concerning",
    "Regarding",
    "Concerning",
    "Regarding",
    "About",
    "Regarding",
    "Concerning",
    "Communique segue",
    "\"Now, about ?à\"",
    "\"Now, about ...\"",
    "Communique segue",
    "Concerning",
    "Regarding",
    "Regarding",
    "About",
    "Concerning",
    "Apropos of",
    "Concerning",
    "Concerning",
    "Regarding",
    "About",
    "About",
    "Regarding",
    "Regarding",
    "Regarding",
    "Concerning",
    "Apropos of",
    "Anent",
    "About",
    "About",
    "Regarding",
    "Concerning",
    "In the matter of",
    "About",
    "Concerning",
    "Regarding",
    "Regarding",
    "Re",
    "Concerning",
    "Concerning",
    "Regarding",
    "Concerning",
    "About",
    "Regarding",
    "Regarding",
    "Regarding",
    "Concerning",
    "About",
    "Regarding",
    "Anent",
    "Concerning",
    "Memo start",
    "Regarding",
    "Regarding",
    "About",
    "Regarding",
    "\"Now, about...\"",
    "Regarding",
    "Apropos of",
    "In re",
    "Regarding",
    "About",
    "Concerning",
    "Regarding",
    "Concerning",
    "Regarding",
    "Regarding",
    "Concerning",
    "Respecting",
    "Regarding",
    "Anent",
    "Re",
    "Regarding"
  ],
  "acai": [
    "Kind of palm tree in Central and South America",
    "Dark purple fruit",
    "\"Superfruit\" from South America",
    "___ bowl (fruity treat)",
    "Popular berry",
    "Fruit rich in antioxidants and vowels",
    "Kind of berry",
    "___ bowl",
    "Smoothie berry",
    "Source of a purple puree",
    "Brazilian export",
    "Fruit from a palm",
    "Kind of bowl ordered at a juice bar",
    "Flavoring of Cedilla liqueur",
    "\"Superfruit\" berry",
    "Kind of palm",
    "Berry that looks like a blueberry",
    "Kind of palm cultivated for its fruit",
    "Purple berry",
    "South American palm cultivated for its fruit",
    "Fruit whose name is pronounced in three syllables",
    "Smoothie bar stock",
    "Berry often blended and served in a bowl",
    "Berry from Brazil",
    "Berry in a purple smoothie",
    "Palm that produces berries",
    "___ bowl (health food offering)",
    "Kind of palm",
    "Black-purple fruit from a palm tree",
    "Smoothie berry",
    "Superfood sometimes called \"purple gold\"",
    "Fruit with a cedilla in its name",
    "Smoothie berry",
    "Smoothie flavor",
    "Palm berry",
    "___ bowl (trendy healthful food)",
    "___ bowl (dish for the health-conscious)",
    "Berry with two diacritics in its name",
    "Purplish berry",
    "___ na tigela (fruity Brazilian dish)",
    "Smoothie \"superfruit\"",
    "Smoothie flavor",
    "Berry marketed as a superfood",
    "Berry for a purple smoothie",
    "Purple smoothie flavorer",
    "Four-letter fruit pronounced in three syllables",
    "Berry in smoothies and yogurt",
    "Smoothie fruit",
    "Berry marketed as healthful",
    "Smoothie berry",
    "\"Superfood\" Amazon berry",
    "___ palm (tree with a healthful berry)",
    "Antioxidant-rich berry",
    "Smoothie fruit",
    "Berry imported from Brazil",
    "Berry said to have anti-aging qualities",
    "Antioxidant berry",
    "Berry advertised as healthful",
    "___ palm",
    "Brazilian berry",
    "Antioxidant-rich berry",
    "___ palm",
    "Dark purple fruit",
    "Palm fruit",
    "Modern yogurt flavor",
    "Healthful berry",
    "Antioxidant berry",
    "Trendy antioxidant berry",
    "Antioxidant berry",
    "Berry variety",
    "Berry for the health-conscious",
    "Popular source of antioxidants",
    "Berry in some energy boosters",
    "Berry used as a dietary supplement",
    "___ palm",
    "Berry touted as a superfood"
  ],
  "snot": [
    "Obnoxious sort",
    "Little brat",
    "Bratty kid",
    "Rotten little twerp",
    "Insolent sort",
    "Twerp",
    "Impertinent sort",
    "Impudent twerp"
  ],
  "yaps": [
    "Talks one's mouth off",
    "Talks, talks, talks",
    "Jibber-jabbers",
    "Pups' protests",
    "Talks, talks, talks",
    "Doesn't stop talking",
    "Flaps one's gums",
    "Runs off at the mouth",
    "Talks one's head off",
    "Blathers",
    "Rambles",
    "Talks, talks, talks",
    "Jabbers",
    "Blabbers",
    "Goes on and on and on",
    "Kissers",
    "Goes on and on",
    "Talks one's head off",
    "Kennel cries",
    "Big fat mouths",
    "Sounds in pounds",
    "Doesn't shut up",
    "Talks a blue streak",
    "Talks, talks, talks",
    "Puppy sounds",
    "Goes on and on",
    "Chihuahua cries",
    "Natters on",
    "Doesn't shut up",
    "Round at the pound",
    "Pounds' sounds",
    "Dog din",
    "Won't shut up",
    "Chatters",
    "Shrill barks",
    "Blabs",
    "Talks noisily",
    "Kissers",
    "Barks"
  ],
  "auto": [
    "What thoughtless people may be on?",
    "Camera setting",
    "Lead-in to pilot",
    "Lead-in to correct",
    "Thermostat setting",
    "Manual alternative",
    "Thing with tags",
    "Taurus, for one",
    "Mustang, e.g.",
    "Part of many an insurance bundle",
    "VW or BMW",
    "An exhausted person might be on it",
    "Focus on the road, e.g.?",
    "Easy camera setting",
    "Hertz rental",
    "Coupe or sedan",
    "Modern prefix with correct",
    "A plane might be flown on it",
    "Car",
    "On ___ (running by itself)",
    "Camera setting for amateur photographers",
    "Taurus or Touareg",
    "Jam ingredient?",
    "Opposite of manual",
    "A camera may be set on it",
    "Modern prefix with complete or correct",
    "Motorcade unit",
    "Digital camera mode",
    "Camera setting",
    "VW or BMW",
    "Lead-in to dealer or dialer",
    "Kind of body",
    "Volvo or Volt",
    "Ford or Lincoln",
    "Chevy, e.g.",
    "Prefix with complete",
    "Road runner",
    "What drives on a parkway and parks on a driveway",
    "Camera setting",
    "With 79-Down, place to get spare parts",
    "What a garage protects",
    "Lead-in to correct",
    "Prefix with correct",
    "Prefix with pilot",
    "Volvo or VW",
    "Detroit product",
    "Camera setting",
    "It may get in a jam",
    "Self starter?",
    "\"Das ___\" (Volkswagen slogan)",
    "Prius, for one",
    "Digital camera mode",
    "Grand Theft ___",
    "The \"A\" in U.A.W.",
    "Sedan or coupe",
    "Caddy, e.g.",
    "Aries or Taurus",
    "VW or BMW",
    "Garage occupant",
    "Camera setting",
    "It's chopped in a chop shop",
    "Camera setting",
    "Something to go in ... or on",
    "LaSalle or DeSoto",
    "Jaguar or Mustang",
    "Park Avenue, for one",
    "Saab or Subaru",
    "Camera setting",
    "DeSoto or LaSalle",
    "Camera setting",
    "Sedan or wagon",
    "Not manual",
    "Saturn or Mercury",
    "2-Down, for example",
    "A camera may be set on this",
    "Prefix with -crat",
    "Road runner",
    "Lincoln or Ford",
    "Camera setting",
    "Garage occupant",
    "A factory worker might make a dash for it",
    "Driveway occupant",
    "Not manually, after \"on\"",
    "With 48-Down, Inquisition judgment and execution",
    "Wheels",
    "Camera setting",
    "Self-starter?",
    "21-Across, e.g.",
    "Pilot's setting, perhaps",
    "Photographer's setting",
    "One for the road",
    "Mercury or Saturn",
    "Modern camera setting",
    "Taurus or Aries",
    "Part of U.A.W.",
    "On ___ (needing no human intervention)",
    "Garage contents",
    "Taurus or Aries",
    "It has body parts",
    "VW or Volvo",
    "Hyundai or Honda",
    "Insurable item",
    "On ___ (proceeding independently)",
    "Crosley or Nash",
    "Model T, e.g.",
    "Insurable item",
    "Aries or Taurus",
    "Not manual",
    "Chrysler, e.g.",
    "18-Down product",
    "One with a painted body",
    "Item in a garage",
    "Aries or Taurus",
    "Power control, for short",
    "___ parts",
    "G.M. or MG product",
    "Prefix with focus",
    "Kind of mechanic",
    "Car",
    "Garage occupant",
    "One for the road",
    "Compact, e.g.",
    "Self starter",
    "Motor",
    "Mobile starter",
    "Opposite of manual",
    "Kind of pilot",
    "Pilot starter",
    "Prefix with pilot",
    "Saturn or Mercury, e.g.",
    "Crate, so to speak",
    "Aries or Taurus, e.g.",
    "Kind of camera focus",
    "Prefix with graph or crat",
    "Workers in Detroit make a dash for it",
    "Jam ingredient",
    "Taurus or Aries, e.g.",
    "Mercury or Saturn",
    "\"Self \" starter",
    "\"Wheels\""
  ],
  "yeah": [
    "\"That's right!\"",
    "Opposite of nah",
    "\"Think so?\"",
    "\"Um, sure\"",
    "\"Right on!\"",
    "When doubled, \"I heard you the first time\"",
    "\"You got that right!\"",
    "When sung three times, what follows \"She loves you\"",
    "\"You betcha!\"",
    "\"Uh-huh\"",
    "\"Woo-hoo!\"",
    "\"Oh, why not?!\"",
    "\"You betcha\"",
    "Word repeatedly sung after \"She loves you ...\"",
    "\"You betcha\"",
    "\"Sweet!\"",
    "\"Hot dog!\"",
    "\"Woo-hoo!\"",
    "When sung three times, part of a Beatles refrain",
    "\"I suppose so\"",
    "\"Amen to that!\"",
    "\"Couldn't agree more\"",
    "When said three times, \"Of course, obviously!\"",
    "Triumphant cry",
    "Fist pumper's cry",
    "When sung three times, part of a Beatles refrain",
    "\"___, you!\"",
    "\"Amen!\"",
    "Opposite of nah",
    "Repeated word in \"She Loves You\"",
    "When repeated, part of a Beatles refrain",
    "Word repeated in a Beatles refrain",
    "\"Woo-hoo!\"",
    "\"Awright!\"",
    "\"O.K.\"",
    "\"You betcha\"",
    "\"Right on!\"",
    "\"I guess that's true\"",
    "\"Awright!\"",
    "\"Good going!\"",
    "One-third of a Beatles refrain",
    "\"Awright!\"",
    "\"Uh-huh\"",
    "Positive reply",
    "\"You bet\"",
    "\"Uh-huh\"",
    "Slangy assent",
    "\"Uh-huh\"",
    "\"Uh-huh\"",
    "Part of a Beatles refrain",
    "\"___, right!\"",
    "\"___, right\"",
    "\"You bet!\"",
    "\"You talkin' to me?\"",
    "\"Sure!\"",
    "Slangy okay",
    "Informal agreement"
  ],
  "spur": [
    "On the ___ of the moment",
    "Goad",
    "Cowboy boot feature",
    "Appendage on a cowboy's boot",
    "___ of the moment",
    "San Antonio pro",
    "Urge on",
    "Railway offshoot",
    "Cowboy boot attachment",
    "Cowboy boot accessory",
    "What you might use when you say \"Giddyup!\"",
    "What might be used with a \"Giddy-up!\"",
    "Spike on a cowboy boot",
    "Poker in a western",
    "2014 N.B.A. champ",
    "Cowboy's prod",
    "Sidetrack?",
    "Boot accessory",
    "Provoke",
    "Thing on a cowboy's boot",
    "Urge on",
    "Cowboy boot feature",
    "Thing on a cowboy's boot",
    "Cowboy boot feature",
    "It may be down at the heel",
    "Track branch",
    "Poker variety?",
    "Cowboy boot attachment",
    "Stimulate",
    "Urge (on)",
    "Cowboy boot part",
    "Side track",
    "San Antonio player",
    "Side line",
    "Cowboy boot feature",
    "Pony provoker",
    "Incentivize",
    "Cowboy boot attachment",
    "Egg on",
    "Urge (on)",
    "Prompt",
    "Cowboy boot attachment",
    "Railway siding",
    "It's used with a \"giddyup!\"",
    "Part of a cowboy's boot",
    "Prodder",
    "Railroad siding",
    "Goad",
    "Item on a cowboy boot",
    "Offshoot",
    "Instigate",
    "One down at the heels?",
    "Stimulus",
    "Sidetrack"
  ],
  "arse": [
    "Rear end, to Brits",
    "Keister",
    "Keister, in Leicester",
    "Bottom of Britain?",
    "Bottom, to a Brit",
    "British bottom",
    "The end of the British monarchy?",
    "Seat in Parliament?",
    "British derriere",
    "Oxford bottom",
    "Derry derriere"
  ],
  "ymha": [
    "Jewish community center, for short",
    "Jewish community org.",
    "Jewish males' org.",
    "Jewish youth org.",
    "Jewish youth org."
  ],
  "bats": [
    "Have ___ in one's belfry",
    "Cave dwellers",
    "Daft",
    "Cave residents",
    "Creatures under Wayne Manor",
    "\"Sonar\"-equipped fliers",
    "Off one's rocker",
    "Some Quidditch equipment",
    "Flutters, as eyelashes",
    "Belfry occupants",
    "Wrigley sticks?",
    "Touched",
    "Dotty",
    "Upside-down sleepers",
    "Upside-down sleepers",
    "Is at the plate",
    "Loco",
    "Night fliers",
    "Loony",
    "Off one's trolley",
    "Loony",
    "Flying mammals",
    "Halloween decorations",
    "Flying mammals",
    "Halloween decorations",
    "Loony",
    "Louisville Sluggers",
    "Daffy",
    "Crackers",
    "Cave dwellers",
    "Plumb loco",
    "Unhinged",
    "Nuts",
    "Is up",
    "Fruity",
    "Is up",
    "Nutty",
    "Louisville Sluggers",
    "Faces the pitcher",
    "Nutty",
    "Off one's trolley",
    "Nutty"
  ],
  "tins": [
    "Altoids containers",
    "Metal food containers",
    "Spam comes in them",
    "Tuna holders",
    "Mint holders",
    "Spam holders",
    "Cookie containers",
    "Food preservers",
    "Containers for cookies or sewing supplies",
    "Metal canisters",
    "Pantry containers",
    "Some decorative containers",
    "Cookie holders",
    "Quaint food containers",
    "Spice stores?",
    "Spam is removed from them",
    "Retro candy containers",
    "Biscuit containers",
    "Spam protection items?",
    "Sardine containers",
    "Cookie holders",
    "Pantry array",
    "Cans",
    "Cans",
    "Old-fashioned food containers",
    "Spam holders",
    "Cookie holders",
    "Cookie containers",
    "Pie pans",
    "Cookie holders",
    "Cookie containers",
    "Pantry containers",
    "Pie containers",
    "Anchovy containers",
    "Old-fashioned containers",
    "Anchovy containers",
    "Tobacco holders",
    "Pantry items",
    "Cans",
    "Cannery row?",
    "Packs, as sardines",
    "Small containers",
    "Sardine containers",
    "Baking pans"
  ],
  "sled": [
    "Transport down a winter hill",
    "\"Calvin and Hobbes\" conveyance",
    "\"Ethan Frome\" vehicle",
    "Transport down a hill",
    "Item lugged up a hill",
    "Vehicle that travels in only one direction",
    "Travel tirelessly?",
    "Go downhill fast",
    "Iditarod vehicle",
    "Runners support it",
    "Something dogs may pull",
    "Toboggan, e.g.",
    "Tireless racer",
    "Where to see two runners side by side",
    "Toboggan, e.g.",
    "Conveyance for Calvin and Hobbes",
    "Vehicle that requires no fuel",
    "Skeleton vehicle, in the Olympics",
    "Conveyance used either lying down or sitting up",
    "Conveyance in \"Calvin and Hobbes\"",
    "It goes downhill",
    "Huskies' haul",
    "___ dog",
    "\"Calvin and Hobbes\" conveyance",
    "Seasonal transport",
    "See 66-Across",
    "Something pulled uphill",
    "One may make tracks",
    "With 26-Down, bit of winter fun",
    "Skimobile, informally",
    "Toboggan, e.g.",
    "Cold-weather conveyance",
    "Follower of a team",
    "Dog team's burden",
    "Flexible Flyer, e.g.",
    "Vehicle in \"Frozen\"",
    "\"Calvin and Hobbes\" conveyance",
    "Luge, e.g.",
    "It's inclined to provide entertainment for kids",
    "Gift from 1-Down",
    "Traditional Yule gift",
    "Hillside thrill-ride need",
    "Gravity-powered vehicle",
    "Flexible Flyer, e.g.",
    "Transport for Calvin and Hobbes",
    "Runners' spot",
    "Malamute's burden",
    "Large item in Santa's bag, maybe",
    "Iditarod vehicle",
    "Anchorage-to-Nome racer",
    "Coast, in a way",
    "Toboggan",
    "Place for runners",
    "Go downhill fast?",
    "Olympic vehicle",
    "Vehicle to take over a jump",
    "Iditarod transport",
    "Zero-emissions vehicle",
    "Wheelless vehicle",
    "Toy sometimes pulled with a rope",
    "Vehicle for Calvin and Hobbes",
    "One drawn to igloos",
    "Bob, e.g.",
    "Bob, e.g.",
    "Runner's place",
    "Something that's fun on the coast?",
    "Toboggan, e.g.",
    "Iditarod vehicle",
    "Beloved object of 28-Down",
    "Bob in the Olympics",
    "Iditarod vehicle",
    "Runner's place",
    "Ride with runners",
    "Musher's carrier",
    "Iditarod vehicle",
    "Inuit's transport",
    "Iditarod entry",
    "Sleigh",
    "Inuit's ride",
    "Vehicle on 30-Across",
    "Dog team's burden",
    "Runner's place",
    "Runner's place",
    "Sight near an igloo",
    "Source of many spills",
    "Flexible Flyer, for one",
    "Iditarod entry",
    "It's involved in many spills",
    "It goes tirelessly",
    "Musher's transport",
    "Coaster with runners",
    "Husky vehicle",
    "Gravity-powered vehicle",
    "Go downhill",
    "Toy used on hills",
    "Husky burden",
    "Spill producer",
    "Musher?Æs transport",
    "Source of spills on hills",
    "Runners carry it",
    "Coaster",
    "With 34D, a racing vehicle",
    "Eskimo's vehicle",
    "It rides on runners",
    "Transportation with rails",
    "Need for Santa",
    "Husky burden",
    "Kind of dog",
    "Coaster",
    "Husky's burden",
    "Musher's transport",
    "Runners' place",
    "Runners carry it",
    "Classic Christmas toy",
    "Enjoy some winter fun",
    "Toy attached to a rope",
    "Winter toy",
    "Place for runners",
    "Arctic transport",
    "Gravity-powered vehicle",
    "Husky's tow",
    "Have fun on a white bed?",
    "Winter toy",
    "Transport dating from the Stone Age",
    "Huskies?Æ load",
    "Firewood carrier",
    "Downhill racer",
    "Arctic vehicle",
    "Runners carry it",
    "It has runners",
    "Winter toy",
    "Inuit's transport",
    "Eskimo's need",
    "Inuit's item",
    "Perennial Christmas gift",
    "Where runners are found",
    "Bob in the Olympics",
    "Winter transport",
    "Winter toy",
    "Runners' location",
    "Take to the hills?",
    "Iditarod Trail transport",
    "Child's Christmas gift",
    "Musher's need",
    "Kind of dog",
    "Go-devil",
    "Downhill runner",
    "It goes with runners",
    "Zero-wheeled vehicle",
    "Coast",
    "Racing vehicle",
    "Kind of dog",
    "Santa's vehicle",
    "Winter transportation",
    "Malamute's burden",
    "Iditarod sight",
    "Coast",
    "Kind of dog",
    "Runners?Æ locale",
    "One going downhill",
    "Bob, for one",
    "Inuit conveyance",
    "Flexible Flyer, for one",
    "Coaster",
    "Kane's Rosebud"
  ],
  "toon": [
    "Saturday morning TV character",
    "Saturday morning character",
    "Bugs Bunny or Wile E. Coyote",
    "Betty Boop, e.g.",
    "Linus or Lucy, e.g.",
    "Boo Boo, e.g.",
    "Bugs Bunny, e.g.",
    "Bugs Bunny or Jessica Rabbit",
    "Bugs Bunny or Wile E. Coyote",
    "One drawn to kids' entertainment?",
    "Animator's creation",
    "SpongeBob or Scooby-Doo",
    "Charlie Brown, e.g.",
    "Any of the \"South Park\" characters",
    "Bugs, e.g.",
    "Animated character",
    "Bugs Bunny or Tweety Bird",
    "Any 40-Across character",
    "Donald or Daffy Duck",
    "SpongeBob, e.g.",
    "Hanna-Barbera creation",
    "The Road Runner, for one",
    "Porky Pig, e.g.",
    "Pixar drawing",
    "Wile E. Coyote or the Road Runner",
    "Roger Rabbit or Donald Duck",
    "Cel character",
    "Bullwinkle, for one",
    "Goofy or Daffy, e.g.",
    "Bugs Bunny or Wile E. Coyote",
    "Any of TV's Simpsons",
    "Bullwinkle, for one",
    "Tom or Jerry of \"Tom and Jerry\"",
    "Bullwinkle, e.g.",
    "Bugs or Daffy",
    "Daffy Duck or Porky Pig",
    "Animator's creation",
    "Animation figure",
    "Cel character",
    "Goofy, e.g.",
    "Rocky or Bullwinkle",
    "See 12-Down",
    "Porky Pig, e.g.",
    "Daffy Duck or Elmer Fudd",
    "Elmer Fudd, e.g.",
    "Warner Bros. creation",
    "Daffy Duck or Donald Duck",
    "Roger Rabbit, for one",
    "Warner Bros. creation",
    "Animated character",
    "Bugs Bunny, e.g.",
    "Roger Rabbit, e.g.",
    "Many a Disney character",
    "Roger Rabbit, e.g.",
    "Roger or Jessica Rabbit",
    "Saturday TV fare, slangily",
    "\"Tummy Trouble\" character",
    "Jessica Rabbit, for one"
  ],
  "chin": [
    "Place for a goatee",
    "*\"Cheers!\"",
    "Hitchcock double feature?",
    "Something a thoughtful person strokes",
    "With 67-Across, attachment to a string instrument",
    "Shoot the breeze",
    "Uppercut target",
    "Something most people don't want two of",
    "It may be cut by an uppercut",
    "Vandyke locale",
    "Spot for a goatee",
    "\"Double\" facial feature",
    "It might be cut by an uppercut",
    "Place for a Vandyke",
    "Goatee's place",
    "Goatee site",
    "Goatee site",
    "Goatee's locale",
    "Goatee's locale",
    "Goatee site",
    "Gossip",
    "End of a punch?",
    "Gossip",
    "Goatee's place",
    "Goatee site",
    "Where a sock goes?",
    "Gab",
    "It may be stroked",
    "Goatee's location",
    "Do a gym exercise",
    "Gab",
    "Gab",
    "Gym exercise",
    "Pull oneself up to the bar",
    "Vandyke place"
  ],
  "upon": [
    "\"Once ___ a time\"",
    "Atop",
    "\"___ reflection ...\"",
    "Hip to, as the latest news",
    "Well versed in",
    "Atop",
    "Knowledgeable about",
    "\"___ reflection ...\"",
    "Second word of fairy tales",
    "Informed about",
    "Atop",
    "Shortly after",
    "\"Once ___ a time ...\"",
    "\"When You Wish ___ a Star\"",
    "Familiar with",
    "Knowing all about",
    "\"___ the Housetop\" (Christmas song)",
    "Agreed-___",
    "___ reflection",
    "Atop",
    "Acquainted with",
    "Over",
    "\"It Came ___ a Midnight Clear\"",
    "In the know about",
    "Atop",
    "Hip to",
    "Au courant about",
    "\"___ further review ...\"",
    "Well versed in",
    "\"___ my honor!\"",
    "Over",
    "Atop",
    "Knowledgeable of",
    "Part of many British place names",
    "Second word of many fairy tales",
    "Agreed ___",
    "Second word in many a fairy tale",
    "Informed about",
    "\"___ my word!\"",
    "Apprised of",
    "Word in some British place names",
    "Familiar with",
    "Atop",
    "Knowledgeable about",
    "Stratford-___-Avon",
    "Knowing, slangily",
    "Informed of",
    "Aware of",
    "Over",
    "Well-versed in",
    "Abreast of",
    "Straddling",
    "At the time of",
    "Aware of",
    "Atop",
    "Aware of",
    "Conversant with",
    "Abreast of",
    "Atop",
    "Abreast of",
    "Fairy tale's second word",
    "Over",
    "Atop",
    "Versed in",
    "Familiar with",
    "\"___ the Roof\" (Drifters hit)",
    "Familiar with",
    "Knowing about",
    "Abreast of",
    "Abreast of",
    "Conversant with",
    "Read ___ (study)",
    "Over",
    "Atop",
    "At the time of",
    "Versed in",
    "Current with",
    "\"___ the Roof\" (1963 hit)",
    "Very soon after",
    "Stratford-___-Avon",
    "Atop",
    "Familiar with",
    "___ reflection",
    "Atop",
    "Well-informed about",
    "Drifters' \"___ the Roof\"",
    "___ reflection",
    "\"Once ___ a time...\"",
    "Fairy tale's second word",
    "\"___ the housetop...\" (Christmas lyric)",
    "Stratford-Avon link",
    "Topping",
    "It follows once",
    "Knowledgeable about",
    "Once follower",
    "On top of",
    "Knowledgeable about",
    "Directly over",
    "Well briefed about",
    "Atop",
    "Savvy about",
    "\"___ the Roof\" (1963 hit)",
    "Over",
    "Chance ___ (meet accidentally)",
    "Over",
    "Knowing about"
  ],
  "dogs": [
    "Kennel occupants",
    "Occupants of kennels",
    "Chow chows and chihuahuas",
    "Feet, slangily",
    "Barking lot?",
    "Canines",
    "Feet, slangily",
    "Feet, in slang",
    "Lady and the Tramp, e.g.",
    "Pugs and poodles",
    "Ballpark fare",
    "Franks",
    "Feet, in slang",
    "They may be measured by the pound",
    "Follows relentlessly",
    "Follows persistently",
    "See 15-Down",
    "Feet, slangily",
    "Example, for boxers",
    "For what animals are the Canary Islands named?",
    "Lady and the Tramp, e.g.",
    "Recipients of the cries seen at the starts of 20-, 36- and 50-Across and 7-Down",
    "Tracks",
    "Follows persistently",
    "Kind of life",
    "Shih Tzus, e.g.",
    "Hounds",
    "Bedevils, in a way",
    "Labradors and Yorkshires",
    "Feet",
    "?ôFranks?ö",
    "Feet",
    "Follows hostilely",
    "Shadows"
  ],
  "kiss": [
    "Candy item in foil",
    "Culmination of a wedding ceremony",
    "X in XXX, maybe",
    "Hershey's foiled confection",
    "With 35-Across, Cole Porter musical",
    "Smack",
    "Bite-size chocolate candy",
    "Meet face to face?",
    "Give mouth-to-mouth to?",
    "What a lipstick print signifies",
    "\"Rock and Roll All Nite\" band",
    "SWA*",
    "Give lip to?",
    "French ___",
    "Meet face-to-face?",
    "Smack",
    "X",
    "X, on a greeting card",
    "Request of a frog in a fairy tale",
    "New Year's Eve action",
    "Smooch",
    "It's often planted",
    "Hershey confection",
    "Smooch",
    "Smooch",
    "Part of S.W.A.K.",
    "Rodin sculpture, with \"The\"",
    "Meeting of heads?",
    "Barely touch, on a pool table",
    "Lip service?",
    "It's sometimes stolen",
    "What X may represent",
    "Smack",
    "Smooch",
    "Rodin sculpture, with \"The\"",
    "Smooch",
    "Donne's \"last lamenting\" thing",
    "Rodin sculpture, with \"The\"",
    "Smooch",
    "1930 hit \"The ___ Waltz\"",
    "Rodin work with \"The\"",
    "Rodin work, With \"The\"",
    "Stolen item that's often returned",
    "One method of sealing",
    "Philematologist's study",
    "Conical candy",
    "Smack"
  ],
  "ldrs": [
    "People in charge: Abbr.",
    "C.E.O. and pres.",
    "P.M.'s and such: Abbr.",
    "Ones in charge: Abbr."
  ],
  "sash": [
    "Holder of merit badges on a scout uniform",
    "Bit of ceremonial attire",
    "It's fit for a queen",
    "Glazier's frame",
    "Bit of attire that might say \"MISS UNIVERSE\"",
    "Merit badge holder",
    "Band for an awards ceremony, maybe",
    "Winner's accessory",
    "Part of Miss America's attire",
    "Window frame",
    "Pageant wear",
    "Part of a window thrown up in \"A Visit From St. Nicholas\"",
    "Item worn diagonally",
    "It's a cinch!",
    "Military band",
    "It's a cinch",
    "Miss America accessory",
    "Pageant wrap",
    "Something the narrator of \"A Visit From St. Nicholas\" threw up",
    "Scout uniform part",
    "Merit badge displayer",
    "Window frame",
    "Boy Scout uniform part",
    "Tiara accompaniment",
    "Military band",
    "Door part",
    "Pageant accessory",
    "It's an honor to wear",
    "See 97-Across",
    "Clothing item worn diagonally",
    "Military band",
    "Where a Brownie's merit badges are attached",
    "Beauty pageant wear",
    "Dress accessory",
    "Band for a \"Miss\"",
    "Cummerbund, e.g.",
    "Miss America identifier",
    "What says \"Miss America\" on Miss America",
    "Pageant wrap",
    "Accessory that may have a state name on it",
    "Window part",
    "Part of a legionnaire's costume",
    "What the narrator \"threw up\" in \"The Night Before Christmas\"",
    "Glazier's frame",
    "Miss America band",
    "Window framework",
    "Weather-stripped item",
    "Pageant entrant's wear",
    "One might say \"Nevada\" in Las Vegas",
    "Place for the words \"Miss USA\"",
    "Miss America accessory",
    "Window part",
    "Accessory that might say \"Miss Universe\"",
    "Scout uniform part",
    "Tiara go-with",
    "\"Miss Universe\" holder",
    "\"Miss America\" might be printed on one",
    "Part of a girl scout's uniform",
    "Pageant accessory",
    "Military band",
    "Pageant adornment",
    "Accessory for Miss America",
    "Beauty queen's wear",
    "Window frame",
    "Item that may be \"Miss\" printed?",
    "Window part",
    "Pane holder",
    "Obi",
    "Badge holder",
    "Part of Miss America's attire",
    "\"Miss America\" appears on it",
    "Beauty pageant wear",
    "It might say \"New Jersey\" in Atlantic City",
    "Merit badge spot",
    "Obi",
    "Accessory for Miss America",
    "Scarf",
    "Miss Universe's wrap",
    "Band over a bathing suit, maybe",
    "Cummerbund",
    "Part of beauty pageant attire",
    "Ornamental attire",
    "Door part",
    "Miss America wears one",
    "Medals locale",
    "Merit badge holder",
    "Window frame",
    "Pane frame",
    "Miss America attire",
    "Pane's place",
    "French door component",
    "Cummerbund",
    "Pane holder",
    "Obi",
    "Miss?Æs item of apparel",
    "Obi",
    "Miss America prop",
    "\"... and threw up the ___\"",
    "Window frame",
    "Framework",
    "Window surrounding",
    "Waist material",
    "Pane holder",
    "Part of an officer's uniform"
  ],
  "nags": [
    "Rides",
    "Bugs",
    "Repeatedly scolds",
    "Badgers",
    "Horses that could be hounds or badgers?",
    "Harpies",
    "Rides",
    "Hounds or badgers",
    "Noodges",
    "Rides",
    "Horses, to bettors",
    "Hounds",
    "Acts the shrew",
    "Carps, carps, carps",
    "Scratch sheet listings",
    "Ones bet on by bettors",
    "Reminds a bit too much",
    "Rosinantes",
    "Bothers",
    "Carpers and harpers",
    "Emulates Xanthippe",
    "Complains and complains and ...",
    "Is a bother",
    "Bothers",
    "Badgers",
    "Bettors bet on them",
    "Badgers",
    "Scolds ceaselessly",
    "Noodges",
    "Emulates Xanthippe",
    "Bettors bet on them",
    "Emulates Xanthippe",
    "Plugs of a sort"
  ],
  "relo": [
    "Move, informally",
    "Major move, for short",
    "Move, in Realtor jargon",
    "Potential new job requirement, informally",
    "Quick move?",
    "Type of property, in real-estate lingo",
    "Move, informally",
    "Informal move",
    "Move, in real-estate lingo",
    "Move, informally",
    "Move, for short",
    "Job-related move, for short",
    "It might be needed for a new job, informally",
    "Quick move?",
    "Move, in agent lingo",
    "Move, to a Realtor",
    "Move, to a real estate agent",
    "Move, informally",
    "Move, in Realtor lingo",
    "Move, in real-estate lingo",
    "Slangy move",
    "Move, in real-estate ads",
    "Pull up stakes, informally",
    "Employee's move, for short",
    "Move, in Realtor-ese",
    "Move, in Realtor-speak",
    "Move, to a real-estate broker",
    "Job-related move, for short",
    "Change of address, for short",
    "It may accompany a promotion, briefly",
    "Move, in Realtor lingo",
    "Move, in Realtor-speak",
    "Move, to a real-estate broker",
    "Move, in Realtor-speak",
    "Employee's move, for short",
    "Move, in Realtor lingo",
    "Move, informally",
    "Move, to a Realtor",
    "Move, in Realtor-speak",
    "Move, to a Realtor",
    "Move, quickly",
    "Move, in real estate",
    "Move, in Realtor-speak",
    "Move, in Realtor-speak",
    "Realtor's specialty, for short",
    "Move, in real estate lingo",
    "Move, in the realty biz",
    "Move, in Realtor-speak",
    "Move, in Realtor lingo",
    "Employee's move, for short",
    "Move, in Realtor lingo"
  ],
  "imet": [
    "First two words of Shelley's \"Ozymandias\"",
    "\"How ___ Your Mother\"",
    "\"How ___ Your Mother\"",
    "\"___ her in a club down in old Soho\" (opening lyric of \"Lola\")",
    "Multi-Emmy-winning \"How ___ Your Mother\"",
    "\"How ___ Your Mother\"",
    "TV's \"How ___ Your Mother\"",
    "\"How ___ Your Mother\"",
    "\"Since ___ You Baby\" (1956 hit)",
    "\"___ a man with seven wives\"",
    "\"How ___ Your Mother\"",
    "TV's \"How ___ Your Mother\"",
    "\"How ___ Your Mother\"",
    "The Box Tops' \"___ Her in Church\"",
    "\"Since ___ You Baby\" (1956 hit)",
    "\"___ a man with seven wives\"",
    "\"___ a traveler from an antique land\": \"Ozymandias\"",
    "\"___ a traveler ...\": \"Ozymandias\"",
    "\"___ a Man,\" John Ciardi poetry collection",
    "\"___ a man with seven wives\"",
    "\"___ a man with seven wives\"",
    "\"___ him on a Monday\" (Crystals lyric)",
    "\"Since ___ You Baby\" (1956 hit)",
    "\"___ a traveler from an antique land\": \"Ozymandias\"",
    "\"___ a man with seven wives\"",
    "\"___ a man with ...\"",
    "\"Since ___ You Baby\" (1956 hit)",
    "\"___ a man...\"",
    "Ciardi's \"___ a Man\"",
    "\"___ a man with seven wives\"",
    "\"___ a man...\"",
    "\"___ a man who wasn't there\"",
    "\"___a man who wasn't there\"",
    "\"___Him in Paris\" (1937 film)",
    "\"___Her on Monday\" (1942 song)",
    "\"___ a man with seven...\"",
    "Ciardi?Æs ?ô___ a Man?ö",
    "John Ciardi's \"___ a Man\"",
    "\"___Her on Monday\" (1942 hit)",
    "\"... ___a man with seven wives\"",
    "\"___ a man with seven ...\""
  ],
  "gads": [
    "Roves (about)",
    "Gallivants (about)",
    "Wanders (about)",
    "Gallivants",
    "Flits (about)",
    "Travels (about)",
    "Wanders aimlessly",
    "Wanders (about)",
    "Travels (about)",
    "Gallivants, with \"about\"",
    "Roams",
    "Gallivants, with \"about\"",
    "Gallivants",
    "Proceeds here and there",
    "Moves aimlessly, with \"about\"",
    "Wanders aimlessly",
    "Goes here and there",
    "Gallivants",
    "Wanders",
    "Proceeds here and there",
    "Roams",
    "Wanders (about)",
    "Flits (about)",
    "Is a jet-setter",
    "Meanders",
    "Wanders"
  ],
  "quit": [
    "Give notice",
    "Bail",
    "Drop",
    "Go cold turkey",
    "Call it a day",
    "Fail to persevere",
    "Computer command",
    "Ctrl+Q function",
    "Relinquish",
    "Leave work for good",
    "Conk out",
    "Make canning impossible?",
    "Give out",
    "Give up",
    "Get out of",
    "Call it a day",
    "Throw in the towel",
    "Pull out",
    "Discontinue",
    "Cease",
    "Computer command",
    "Conk out",
    "Renounce",
    "Call it a day",
    "Withdraw",
    "Relinquish",
    "Pack it in",
    "Go cold turkey",
    "Pack it in",
    "Pack it in",
    "Bail out",
    "Throw in the towel",
    "Give up",
    "Throw in the towel",
    "Conk out",
    "Vacate",
    "Go cold turkey",
    "Hang up one's spurs",
    "Leave off",
    "Computer function",
    "Stop",
    "Surrender"
  ],
  "choi": [
    "\"Parasite\" co-star ___ Woo-shik",
    "Broadcast personality Kelly",
    "Fifth-most-common Korean surname, after Kim, Lee, Park and Jung",
    "___ Woo-shik, co-star of 2019's \"Parasite\""
  ],
  "bein": [
    "Hippie confab",
    "Hippie happening",
    "Hippie celebration",
    "1960s hippie event",
    "Summer of Love prelude",
    "Hippie happening",
    "\"___ Green\" (Kermit the Frog song)",
    "\"It's not easy ___ green\"",
    "60's protest",
    "60's happening",
    "Peaceful gathering, 60's-style",
    "?ôHair?ö tune",
    "Hippie gathering of a sort",
    "Hippie gathering of a sort",
    "60's event"
  ],
  "trap": [
    "Hip-hop subgenre",
    "Sport shooting variety",
    "Sting, perhaps",
    "Piehole",
    "Golfer's challenge",
    "Mouth, slangily",
    "Lint collector",
    "Cakehole",
    "Setup for a police sting",
    "Golfer's obstacle",
    "Big, fat mouth",
    "Pie hole",
    "Sting operation, e.g.",
    "Mouth, slangily",
    "Pitfall",
    "Headline? / Snare",
    "Snare",
    "Sting, e.g.",
    "Mouse catcher",
    "Mouth, slangily",
    "Sand, in golf",
    "Bate",
    "Lawyer's clever question, say",
    "Pie hole",
    "Sting",
    "Sting operation",
    "Mouth, slangily",
    "Mouth, slangily",
    "Sting, e.g.",
    "Golf course hazard",
    "Pie hole",
    "Snare",
    "Aid for catching a mouse",
    "Part of a drain",
    "\"Shut yer ___!\"",
    "Snare",
    "Duffer's hazard",
    "Clay pigeon launcher",
    "Sprung thing",
    "Police setup",
    "Golfer's concern",
    "Sting",
    "Door without a knob, say",
    "Piehole",
    "Corner",
    "Big mouth, slangily",
    "Corner",
    "Help in hunting",
    "It may be sprung",
    "Golf course pitfall",
    "Deadfall, e.g.",
    "Word that can follow the ends of 18-, 25-, 43- and 58-Across",
    "Big mouth",
    "Set up",
    "Catch-22",
    "\"Shut your ___!\"",
    "Decoy",
    "Piehole",
    "Snare",
    "Pipe section",
    "Word with speed or fire",
    "Police sting, e.g.",
    "Spider web, e.g.",
    "Big fat mouth",
    "Snare",
    "Catch-22",
    "Links hazard",
    "Sting, basically",
    "Mouth, slangily",
    "Sink pipe part",
    "Sting, of sorts",
    "Drainpipe part",
    "Mouse catcher",
    "Mouth, slangily",
    "Duffer's obstacle",
    "1-Down catcher",
    "Duffer's challenge",
    "Spring site",
    "Drain part",
    "Police operation",
    "Mouth, in slang",
    "Pitfall",
    "Big mouth",
    "Word with sand or speed",
    "Fat mouth",
    "Duffer's locale",
    "Snare",
    "Kind of door",
    "Pitfall",
    "Mouth, slangily",
    "Links hazard",
    "Football play",
    "Big fat mouth",
    "Sting operation",
    "Kisser",
    "Sand ___",
    "Subterfuge",
    "Golf peril",
    "Police sting",
    "It's catching",
    "Green flanker",
    "Skeet device",
    "Sting",
    "Golf hazard",
    "Golf hazard",
    "Sting operation",
    "Pitfall",
    "Kisser",
    "Lobster pot",
    "Golf hazard",
    "Quicksand, e.g.",
    "Snare",
    "Door on the floor",
    "Golf impediment",
    "Snare",
    "Police operation",
    "Green hazard",
    "Gin",
    "Links hazard",
    "Win at cat-and-mouse",
    "Ensnare",
    "Mouse catcher",
    "Catch animals",
    "Golfing snag",
    "Corner"
  ],
  "hilo": [
    "Home to Waianuenue Falls",
    "City whose first two letters are its state's postal abbreviation",
    "Biggest city on the island of Hawaii",
    "Big Island city",
    "Variety of stud poker, familiarly",
    "It has views of Mauna Loa and Mauna Kea",
    "Stud poker variation, informally",
    "U.S. city whose name looks oxymoronic",
    "U.S. city whose name looks like a form of poker",
    "Biggest city on the Big Island",
    "Port on the Big Island",
    "Biggest city on the Big Island",
    "Variety of poker, briefly",
    "Big city on the Big Island",
    "City SE of Honolulu",
    "University of Hawaii campus locale",
    "Largest city on the island of Hawaii",
    "Hawaiian port",
    "Liliuokalani Gardens site",
    "Hawaii county seat",
    "Kind of poker",
    "Largest city on the island of Hawaii",
    "City that overlooks a bay of the same name",
    "U.S. city in sight of two volcanoes",
    "City with a seemingly contradictory two-syllable name",
    "Hawaii's ___ Bay",
    "Hawaiian port",
    "Hawaiian port",
    "View from Mauna Kea",
    "Big Island port",
    "City whose name seems to consist of two opposites",
    "Hawaiian port",
    "Hawaii County's seat",
    "Seat of Hawaii County",
    "Hawaiian port",
    "Southernmost U.S. city",
    "Hawaii County's seat",
    "Poker variation, informally",
    "Hawaiian city",
    "Aloha State port",
    "Hawaiian port",
    "City southeast of Honolulu",
    "Hawaii's ___ Bay",
    "___ Bay, Hawaii",
    "See 7-Down",
    "Seat of Hawaii County",
    "Leleiwi Point site",
    "Hawaiian seaport",
    "Capital of Hawaii County",
    "Southernmost U.S. city",
    "Mauna Loa locale",
    "___ Bay, Hawaii",
    "Hawaii County capital",
    "Hawaiian seaport",
    "Hawaiian port"
  ],
  "sine": [
    "0, for 0°",
    "Reciprocal of cosecant",
    "Ratio of an angle's opposite side to the hypotenuse",
    "Function yielding 0 when applied to pi",
    "Kind of wave",
    "Kind of wave",
    "Trig function",
    "Trigonometric ratio",
    "Basic trig ratio",
    "Function associated with oscillation",
    "___ qua non",
    "___ qua non",
    "___ qua non (essential element)",
    "Wave to a math teacher?",
    "0, for 0 degrees",
    "___ wave (oscilloscope output)",
    "Trig function",
    "Kind of wave",
    "It makes a wave",
    "It's 1 for 90??",
    "___ wave",
    "0.5, for 150??",
    "Wave creator",
    "___ qua non",
    "___ wave",
    "0, for 180 degrees",
    "It has a reciprocal function",
    "0.5, for 30 degrees",
    "It's between -1 and +1",
    "Reciprocal of 12-Down",
    "___ die",
    "Kind of wave",
    "Periodic function",
    "Trig term",
    "It has a period of 2&pi;",
    "Trig function",
    "Kind of wave",
    "___ qua non",
    "Fourier series function",
    "Trig ratio",
    "Trig ratio",
    "0, for 180 degrees",
    "___ wave",
    "Right triangle figure",
    "Trig function",
    "___ mora (without delay, in Latin)",
    "___ wave",
    "Math ratio",
    "Trig function",
    "___ function",
    "Trig function",
    "Trig term",
    "Trig function",
    "Trig ratio",
    "Trigonometry term",
    "Opposite leg divided by the hypotenuse",
    "Cosecant's reciprocal",
    "Trig ratio",
    "Kind of wave",
    "Kind of curve",
    "It ranges from -1 to +1",
    "Kind of curve",
    "Trig function",
    "Math ratio",
    "Fourier series function",
    "Ratio of AB to BC, say",
    "Kind of wave",
    "Trig ratio",
    "Kind of curve, in math",
    "___ qua non",
    "Trig figure",
    "Kind of curve",
    "___qua non",
    "___qua non",
    "Trig function",
    "Trig function",
    "Trigonometric ratio",
    "Kind of curve",
    "Tangent's cousin",
    "Trig function",
    "Trig function"
  ],
  "inch": [
    "One-___ punch (kung fu technique)",
    "Distance between belt holes, maybe",
    "Not give an ___ (be stubborn)",
    "Diameter of a quarter, roughly",
    "Move like a snail",
    "Snowfall unit",
    "1/12 of a foot",
    "Nose (along)",
    "Creep (along)",
    "Crawl (along)",
    "Creep (along)",
    "Nonmetric measure",
    "Narrow margin",
    "Crawl (along)",
    "Square ___",
    "Tailor's unit",
    "Move slowly (along)",
    "Advance slowly",
    "Ruler division",
    "Narrow margin",
    "Move slowly",
    "Ruler unit",
    "See 61-Across",
    "1/12 of a ruler",
    "Go slowly (along)",
    "Move slowly",
    "Word derived from the Latin \"uncia,\" meaning \"one-twelfth\"",
    "Ruler part",
    "Narrow winning margin",
    "1/36 of a yard",
    "Ruler division",
    "Unit indicated by \"",
    "1/12 of a foot",
    "2.54 centimeters",
    "Creep",
    "Creep (along)",
    "Creep (along)",
    "Creep (along)",
    "Mark of a ruler",
    "Mark of a ruler",
    "Mark of a ruler",
    "Creep",
    "Rainfall measure",
    "Progress slowly",
    "Creep (along)",
    "Short distance",
    "Ruler unit",
    "Make a small move",
    "Foot part",
    "Tiny amount of progress",
    "Foot division",
    "Rainfall measurement",
    "Move slowly",
    "Span fraction",
    "Ruler unit",
    "Distance between belt notches, maybe",
    "Narrow margin",
    "Creep",
    "Creep",
    "Narrow margin",
    "Foot part"
  ],
  "ions": [
    "Table salt is made of them",
    "Accelerator particles",
    "Na+ and Cl-",
    "Discoveries of Michael Faraday",
    "They move in a charged atmosphere",
    "Things charged at science labs?",
    "Charged particles",
    "Charged particles",
    "Cannon ammo in sci-fi",
    "Sci-fi beam makeup",
    "Charged particles",
    "Parts of some bonds",
    "Charged particles",
    "Makeup of some sci-fi beams",
    "Cyclotron bits",
    "Charged particles",
    "Charged particles",
    { "formula": "+ _xludf.and - particles", "result": { "error": "#NAME?" } },
    "Ammonium and others",
    "They're never free of charge",
    "Charged things",
    "They carry charges",
    "They have pluses and minuses",
    "Particle accelerator particles",
    "Discoveries of Michael Faraday",
    "Makeup of some beams",
    "Plasma constituents",
    "Charged particles",
    "Accelerator particles",
    "Some Saturns",
    "Charged particles",
    "Table salt is composed of them",
    "Cyclotron particles",
    "Na+ and Cl-",
    "They carry a charge",
    "Solar wind particles",
    "Ammonium particles, e.g.",
    "Carbonium and others",
    "Plus and minus items",
    "Atoms with + or - symbols",
    "Ones with charges",
    "Charge holders",
    "Charged particles",
    "Charged particles",
    "Saturn models",
    "Solar wind particles",
    "They can be indicated by a + or -",
    "Molecular bits",
    "Some Saturns",
    "Salt constituents",
    "Electrified particles",
    "Charged particles",
    "Certain atoms",
    "They're charged",
    "Atoms that have gained or lost electrons",
    "Exchanged items",
    "They have their pluses and minuses",
    "Cyclotron bits",
    "Bonding candidates",
    "Ca++ and Cl-",
    "Particles in particle accelerators",
    "Charged items",
    "Exploratorium subject",
    "They have their pluses and minuses",
    "Na+ and Ca++, e.g.",
    "Charged particles",
    "Radiation products",
    "Charged particles",
    "Exchanged items, maybe",
    "They're charged",
    "Physics particles",
    "They may be exchanged in chambers",
    "They move in a charged atmosphere",
    "They're not free of charge",
    "Charged particles",
    "Products of gamma rays",
    "Charged particles",
    "Solar wind particles",
    "Charged particles",
    "They're charged",
    "Particles"
  ],
  "logo": [
    "Brand image",
    "4-Down, for CBS",
    "Something blurred to avoid trademark infringement",
    "Something trademarked",
    "A lowercase \"f\" on a blue background, for Facebook",
    "McDonald's arches, e.g.",
    "Shell's shell, e.g.",
    "Kangaroo, for 22-Across",
    "Bitten-into apple, for Apple",
    "A panda, for the World Wildlife Fund",
    "Expense for a business start-up",
    "Bull's-eye, for Target",
    "Snapchat's ghost, e.g.",
    "Snapchat's ghost, e.g.",
    "Apple's apple, e.g.",
    "Target's target, e.g.",
    "Apple's apple, e.g.",
    "The Olympic rings, e.g.",
    "Standard home page feature",
    "Target's target, e.g.",
    "An apple for Apple Inc., e.g.",
    "It's seen on many roadside signs",
    "Golden arches for McDonald's, e.g.",
    "An apple with a bite out of it, for one",
    "The Olympic rings, e.g.",
    "Golden arches, for McDonald's",
    "Stationery topper",
    "Letterhead design",
    "Business card graphic",
    "AT&T's stylized globe, e.g.",
    "Olympic rings, for one",
    "McDonald's arches, e.g.",
    "Olympic rings, e.g.",
    "NBC's peacock, e.g.",
    "Nike's swoosh, e.g.",
    "McDonald's arches, e.g.",
    "Stylized U.S. flag, often",
    "Merrill Lynch bull, for one",
    "Firm image",
    "NBC's peacock, e.g.",
    "Corporate image",
    "Letterhead graphic",
    "Apple computer's apple, e.g.",
    "See 37-Down",
    "Apple's apple, e.g.",
    "Lion, for MGM, e.g.",
    "Apple's apple, e.g.",
    "Nike's swoosh, e.g.",
    "Corporate image",
    "Nike's swoosh, for one",
    "Sign of The Times?",
    "Commercial symbol",
    "CBS's eye, e.g.",
    "Company trademark",
    "NBC?Æs peacock, e.g."
  ],
  "oona": [
    "Actress Chaplin",
    "Actress Chaplin",
    "Actress Chaplin",
    "\"Game of Thrones\" actress Chaplin",
    "Actress Chaplin of 53-Across",
    "Actress Chaplin of \"Game of Thrones\"",
    "A Chaplin",
    "Actress Chaplin of \"Game of Thrones\"",
    "Chaplin of \"Game of Thrones\"",
    "\"Game of Thrones\" actress Chaplin",
    "Chaplin of \"Game of Thrones\"",
    "Chaplin of \"Game of Thrones\"",
    "Chaplin of \"Game of Thrones\"",
    "Donald Duck cartoon princess",
    "Actress Chaplin of \"Game of Thrones\"",
    "Wife of Charlie Chaplin",
    "Chaplin of \"Game of Thrones\"",
    "An O'Neill",
    "___ O'Neill",
    "Actress Chaplin of \"Game of Thrones\"",
    "Charlie Chaplin's last wife",
    "Wife of Charlie Chaplin",
    "Actress Chaplin of \"Game of Thrones\"",
    "Mrs. Charlie Chaplin",
    "Princess in Donald Duck cartoons",
    "Wife of Charlie Chaplin",
    "O'Neill whom J. D. Salinger wanted to marry",
    "The final Mrs. Chaplin",
    "One of the Chaplins",
    "A Chaplin",
    "Princess ___ (Disney duck)",
    "Wife of Charlie Chaplin",
    "Mrs. Chaplin",
    "The last Mrs. Charlie Chaplin",
    "Young wife (age 18) of Charlie Chaplin (age 54)",
    "1998 biography subtitled \"Living in the Shadows\"",
    "A Chaplin",
    "Biography subtitled \"Living in the Shadows\"",
    "The last Mrs. Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Disney's Princess ___",
    "A Chaplin",
    "A Chaplin",
    "A Chaplin",
    "The last Mrs. Chaplin",
    "Mrs. Chaplin",
    "A Mrs. Chaplin",
    "Mrs. Chaplin",
    "54-year-old Charlie's 18-year-old bride",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "One of the O'Neills",
    "Mrs. Chaplin",
    "Charlie Chaplin?Æs widow",
    "Geraldine Chaplin's mother",
    "Miss O'Neill",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Eugene O'Neill's daughter",
    "Mrs. Chaplin",
    "Chaplin nee 39-Down",
    "A Chaplin",
    "Mrs. Chaplin",
    "Woman's name that's Irish for \"unity\"",
    "Mrs. Chaplin",
    "A Mrs. Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "The last Mrs. Chaplin",
    "One of the O'Neills",
    "A Chaplin",
    "Mrs. Chaplin",
    "Wife of Charlie Chaplin",
    "The last Mrs. Chaplin",
    "Mrs. Charles Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "One of the O'Neills",
    "Lady Chaplin",
    "One of the O'Neills",
    "Chaplin's widow",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "An O?ÆNeill",
    "A Chaplin",
    "Mrs. Chaplin",
    "Mrs. Chaplin",
    "Lady Chaplin",
    "One of the O'Neills",
    "Miss O'Neill",
    "The last Mrs. Chaplin",
    "Mrs. Chaplin",
    "The last Mrs. Chaplin"
  ],
  "afar": [
    "\"Bearing gifts we traverse ___\" (\"We Three Kings\" lyric)",
    "48-Down away",
    "Way off",
    "A ways away",
    "Miles away",
    "How Phileas Fogg traveled",
    "In the distance",
    "Way off",
    "Way out there",
    "Way yonder",
    "At a distance",
    "Miles away",
    "Many miles away",
    "Some ways off",
    "How Odysseus traveled",
    "Way off",
    "Miles away",
    "Over the horizon",
    "Beyond the horizon",
    "A long way off",
    "Miles away",
    "A ways away",
    "From a distance",
    "Many miles away",
    "In the distance",
    "Quite distant",
    "Not even close?",
    "A long way off",
    "Off in the distance",
    "Not even close",
    "How Bilbo Baggins traveled",
    "Light-years away",
    "\"Our deeds still travel with us from ___, / And what we have been makes us what we are\": George Eliot",
    "A long way off",
    "Quite a distance off",
    "Place to worship from",
    "In the distance",
    "In the distance",
    "Quite a ways away",
    "Over yonder",
    "Way off",
    "Not close",
    "Many miles away",
    "Way off",
    "Many miles off",
    "At a distance",
    "Light years away",
    "Way off",
    "Over the hill, say",
    "A long way off",
    "Quite a ways off",
    "From a distance",
    "Miles and miles away",
    "___ cry",
    "Many miles off",
    "Way off",
    "Not even close",
    "Miles off",
    "Way off",
    "Out there",
    "Miles away",
    "Way off",
    "Way off",
    "Miles away",
    "In the distance",
    "Way off",
    "Light years off",
    "Beginning to cry?",
    "Come from ___",
    "Not close",
    "Come from ___",
    "Many miles away",
    "Come from ___",
    "From a distance",
    "Way out there",
    "Miles off",
    "Many miles off",
    "Cry ___",
    "One way to travel",
    "Miles off",
    "In the distance",
    "Some distance away",
    "At a distance",
    "Way out",
    "Way off",
    "Way off",
    "Way off",
    "Come from ___",
    "Well out of range",
    "Way off",
    "Come from ___",
    "Light years away",
    "At a distance",
    "In the distance",
    "Miles away",
    "Many miles away",
    "In the distance",
    "Way off",
    "From quite a distance",
    "Way over there",
    "Yonder",
    "Miles away",
    "Way out there",
    "Way, way off",
    "Way, way off",
    "Way out",
    "Well out of range",
    "Miles away",
    "Way off",
    "Way off",
    "Miles away",
    "Over the hill, maybe",
    "Many miles away",
    "Somewhere over the rainbow",
    "Out there",
    "Really out there",
    "Many miles away",
    "Way off",
    "At a distance",
    "Way off",
    "At a distance",
    "Miles off",
    "Off in the distance",
    "Miles off",
    "At a distance",
    "A long way off",
    "Really out there",
    "Way off",
    "Way out there",
    "Out there",
    "At a distance",
    "Way out there",
    "Way off",
    "Off yonder",
    "In the distance",
    "Come from ___",
    "Out of range, maybe",
    "Off yonder",
    "From a distance",
    "Way off",
    "From where some worship?",
    "Way off",
    "Winston Churchill's \"___ Country\"",
    "Come from ___",
    "Not nigh",
    "Many miles off",
    "At a distance",
    "Off yonder",
    "Miles away",
    "Some miles away",
    "At a distance",
    "Off in the distance",
    "At a distance",
    "Way off",
    "Way out there",
    "Many miles off",
    "Yon",
    "At a distance",
    "At a distance",
    "At some distance",
    "Way off",
    "Many miles away",
    "Not nigh",
    "Way off",
    "Many miles away",
    "Way off",
    "From a distance",
    "Come from ___",
    "Way off",
    "___ cry",
    "Way off",
    "Winston Churchill's \"___ Country\"",
    "In the distance",
    "From a distance",
    "Winston Churchill's \"___ Country\"",
    "Quite some distance off",
    "Churchill's \"___ Country\"",
    "\"It's ___ cry from...\"",
    "At a distance",
    "At a distance",
    "From a distance",
    "In the distance",
    "Beyond the horizon",
    "Come from ___",
    "___ cry",
    "\"It's ___ cry...\"",
    "In the distance",
    "From a distance",
    "Way out there",
    "Off yonder",
    "Way off",
    "___ cry",
    "Worship from ___",
    "Way off",
    "Not nearby",
    "Way off",
    "Come from ___",
    "Distantly",
    "Not in the area",
    "At a distance",
    "Nowhere near"
  ],
  "were": [
    "\"Now ___ even\"",
    "\"Now, where ___ we?\"",
    "Word that may or may not be a contraction",
    "\"Would that it ___\"",
    "\"Now ___ talking!\"",
    "\"___ #1!\"",
    "\"___ here!\"",
    "\"___ sorry ...\"",
    "\"___ doomed!\"",
    "\"___ #1!\"",
    "Fall Out Boy's \"Sugar, ___ Goin Down\"",
    "Contraction that's also a past-tense verb",
    "Existed",
    "Are no longer",
    "\"The Way We ___\"",
    "Used to be",
    "\"As you ___\"",
    "\"___ live!\"",
    "Lived",
    "\"___ #1!\"",
    "\"___ back\"",
    "\"___ even\"",
    "\"As you ___\"",
    "Existed",
    "\"#1\" may follow it",
    "\"___ back!\"",
    "Used to be",
    "\"___ #1!\"",
    "\"#1\" follows it",
    "\"While you ___ out ... \"",
    "Had been",
    "\"___ pregnant!\"",
    "\"___ #1!\"",
    "Be in a certain mood?",
    "See 15-Across",
    "\"___ here!\"",
    "See 3-Down",
    "\"___ #1!\"",
    "\"___ #1!\"",
    "1973 #1 hit \"___ an American Band\"",
    "\"___ in the Money\" (1933 hit)",
    "Existed",
    "\"___ number one!\" (stadium chant)",
    "Used to be",
    "With 50-Down, cry upon returning",
    "Lived",
    "\"___ madly for Adlai\" (1952 campaign slogan)",
    "\"The Way We ___\"",
    "\"___ #1!\"",
    "Ginger Rogers song \"___ in the Money\"",
    "As it ___",
    "\"___ #1!\"",
    "\"Now ___ talkin'!\"",
    "Are, eventually",
    "Used to be",
    "Used to be",
    "Number one starter?",
    "\"___ sorry...\"",
    "\"___ Not Dressing\" (Crosby comedy)",
    "\"___ No Angels\"",
    "\"Where ___ you?\"",
    "\"As you ___\"",
    "\"___ No Angels\" (Bogart flick)",
    "\"The Way We ___\"",
    "\"___ No Angels\" (Bogart film)",
    "Existed",
    "Ginger Rogers tune \"___ in the Money\"",
    "\"The stockings ___ hung...\"",
    "\"As you ___\"",
    "Word before \"#1\"",
    "\"___ Off to See the Wizard\"",
    "Had being",
    "Lived",
    "\"If I ___...\"",
    "\"___ all in this together\"",
    "Had been",
    "\"___ off to see...\""
  ],
  "more": [
    "\"Gimme, gimme, gimme!\"",
    "Button clicked to see the rest of an article",
    "Seconds, say",
    "Seconds, say",
    "\"Encore!\"",
    "Seconds",
    "\"What's ___ ...\"",
    "What a glutton always wants",
    "What someone who's never satisfied wants",
    "Else",
    "\"Encore!\"",
    "\"Utopia\" writer, 1516",
    "\"Encore!\"",
    "Glutton's desire",
    "\"Keep it coming!\"",
    "With 26-Across, somewhat",
    "Gourmand's want",
    "Greedy person's demand",
    "Less's opposite",
    "Hog's desire",
    "Request upon finishing",
    "\"Encore!\"",
    "Seconds, at dinner",
    "\"Encore!\"",
    "Gourmand's request",
    "Oliver Twist's request",
    "Thomas who wrote \"Utopia\"",
    "Glutton's desire",
    "Thirds, e.g.",
    "Seconds and then thirds",
    "What greedy people want",
    "\"Encore!\"",
    "Further",
    "Seconds",
    "Oliver's request",
    "Extra",
    "Greedy person's cry",
    "\"Encore!\"",
    "With 43-Across, approximately",
    "Extra",
    "\"Encore!\"",
    "Perry Como classic",
    "Oliver's request",
    "\"Don't stop!\"",
    "Cry of greed",
    "\"Don't stop\"",
    "With 42-Across, Somewhat",
    "What's ___ ...",
    "\"Encore!\"",
    "End-of-performance call",
    "Longer",
    "Seconds",
    "\"But wait, there's ___!\"",
    "Seconds",
    "Oliver's request",
    "Gourmand's wish",
    "Extra",
    "Sir Thomas",
    "\"Oliver Twist has asked for ___!\"",
    "Hoarder's cry",
    "Song from \"Mondo Cane\""
  ],
  "yall": [
    "Elided pronoun",
    "\"___ ready for this?\" (opening of a pump-up jam by 2 Unlimited)",
    "\"You folks,\" in Dixie",
    "Pronoun in Dixie",
    "Group addressed in the South",
    "Everyone, in Dixie",
    "Dixie term of address",
    "Informal pronoun",
    "Pronoun with an apostrophe",
    "Southern pronoun",
    "Term of address in Dixie",
    "Term of address in the South",
    "Dixieland group?",
    "Dialectal contraction",
    "Southern group address",
    "Contracted group",
    "Everyone in Dixie",
    "Everyone, in the South",
    "Dixieland group?",
    "Dixie pronoun",
    "You and you, along the Yazoo",
    "Southern contraction"
  ],
  "eggo": [
    "Brand whose last letter is in the shape of its product",
    "Food brand whose sales boomed after the premiere of \"Stranger Things\"",
    "Toaster Strudel alternative",
    "Toaster Swirlz brand",
    "Frozen waffle brand",
    "Pop-up breakfast brand",
    "Product of the Kellogg Company",
    "Breakfast brand",
    "Frozen breakfast brand",
    "Breakfast brand",
    "Breakfast item in a box",
    "Breakfast item in a box",
    "Frozen waffle brand",
    "Brand with \"Thick & Fluffy\" products",
    "Frozen waffle brand",
    "Kellogg's brand",
    "Breakfast brand for the toaster",
    "Breakfast brand",
    "Frozen food brand with French Toaster Sticks",
    "Waffle brand",
    "Breakfast food with a rhyming slogan",
    "Brand in the freezer aisle",
    "Brand with a Thick & Fluffy variety",
    "Something that may pop up in the morning",
    "Freezer section brand",
    "Breakfast-on-the-run option",
    "Toaster waffle brand",
    "Kellogg's brand since 1970",
    "Toasted waffle",
    "Food brand originally called Froffles",
    "Frozen product with blueberry and chocolate chip flavors",
    "Frozen waffle brand",
    "Frozen food brand",
    "Kellogg's brand",
    "Brand in the freezer",
    "Frozen waffle brand",
    "Brand with Toaster Swirlz",
    "Brand in the frozen food section",
    "Freezer brand",
    "Frozen waffle brand",
    "Frozen waffle brand",
    "Frozen waffle brand",
    "Breakfast brand for a toaster",
    "Frozen waffle brand",
    "Frozen waffle brand",
    "Toaster waffle",
    "French Toaster Sticks maker",
    "Kellogg's brand",
    "Waffle brand",
    "Waffle brand",
    "Brand kept near a toaster",
    "Frozen waffle brand",
    "Breakfast brand",
    "Kellogg company brand",
    "Frozen waffle brand",
    "Frozen waffle brand",
    "Waffle brand",
    "Brand in the freezer",
    "Brand in the frozen foods section",
    "Frozen food brand",
    "Breakfast brand",
    "Breakfast food brand name",
    "Kellogg's brand",
    "Breakfast food brand",
    "Kellogg Company brand",
    "Waffle choice",
    "Toaster waffle",
    "Kellogg brand",
    "Waffle brand",
    "Breakfast name",
    "Toasted food",
    "Kellogg Foods brand",
    "Aunt Jemima alternative",
    "Downyflake alternative",
    "Waffle brand",
    "Frozen waffle brand",
    "Downyflake rival",
    "Frozen waffle brand",
    "Breakfast brand name",
    "Breakfast brand"
  ],
  "road": [
    "Spot for a hairpin",
    "Way to go",
    "Way",
    "Way to go",
    "Artery",
    "Pike",
    "Take it for a ride",
    "The Beatles' \"Abbey ___\"",
    "Appropriate word found in 36-Down",
    "Rocky ___",
    "Pike, e.g.",
    "*\"Mad Max: Fury ___\"",
    "Thoroughfare",
    "Artery",
    "You may find a fork in it",
    "U.S. 1, e.g.",
    "See 43-Across",
    "It's found between the shoulders",
    "Pike",
    "Artery",
    "Drag",
    "The Beatles' \"Abbey ___\"",
    "Pike, e.g.",
    "See 9-Across",
    "Blacktop, e.g.",
    "Map line",
    "Way to go",
    "Highway or byway",
    "Highway",
    "Where ballplayers wear gray, with \"the\"",
    "It may get burned up",
    "You might tear it up",
    "Start of many Hope/Crosby film titles",
    "Course",
    "Abbey ___",
    "Kind of trip",
    "Artery",
    "Path",
    "Frost's \"The ___ Not Taken\"",
    "Pike",
    "Jam site",
    "Highway",
    "See 27-Down",
    "It may be hogged",
    "Thoroughfare",
    "Pike",
    "Throughway",
    "Motorway",
    "Route 1, e.g.",
    "Pike",
    "Line on a map",
    "Driveway's end",
    "Byway",
    "Freeway, e.g.",
    "It has two shoulders but no head",
    "It has shoulders",
    "Way to go",
    "Pike",
    "Turnpike",
    "Highway",
    "Way to go",
    "Way to go",
    "Way to go",
    "Kind of test",
    "Way to go",
    "It may have broad shoulders",
    "Way to go",
    "Artery",
    "Way to go",
    "Causeway",
    "Kind of show",
    "Abbey or Tobacco, e.g.",
    "Word with block or test",
    "Yellow brick, e.g."
  ],
  "moms": [
    "May celebrants",
    "\"My Two ___\" (2015 Claudia Harrington children's book)",
    "May honorees",
    "Choosy ones, in Jif ads",
    "\"Choosy ___ choose Jif\" (ad slogan)",
    "Annual May honorees",
    "Many drivers for play dates",
    "Delivery people?",
    "They deliver",
    "Pops' partners",
    "Dads' counterpart",
    "Soccer ___",
    "Stay-at-home ___ (family types)",
    "Soccer ___",
    "Suburban political force",
    "May day honorees?",
    "They're honored in May",
    "Pops' mates",
    "Pops' partners",
    "Apple-pie pros",
    "May honorees"
  ],
  "pete": [
    "Sneaky ___",
    "___ Alonso, Mets slugger with the most home runs by a rookie in M.L.B. history (53)",
    "___ the Cat (fictional feline of children's books)",
    "Red Rose",
    "Davidson of \"S.N.L.\"",
    "Politician Buttigieg",
    "Davidson of \"S.N.L.\"",
    "Rose with 4,256 major-league hits",
    "Red Rose",
    "Rose no longer seen in fields",
    "Pistol ___ (Oklahoma State's mascot)",
    "Sneaky ___ (trickster)",
    "Sessions of Congress",
    "St. ___ (site of a spring vacay)",
    "Tennis great Sampras",
    "Super Bowl-winning coach Carroll",
    "Sessions of Congress",
    "Tennis's Sampras",
    "Rose with many hits",
    "N.F.L. coach Carroll",
    "___ Domenici, longtime New Mexico senator",
    "Colleague of Don on \"Mad Men\"",
    "Best of classic rock",
    "Basketball legend Maravich",
    "Officemate of Don and Peggy on \"Mad Men\"",
    "Rose in a field",
    "Tennis's Sampras",
    "Rose family member",
    "Rose of the diamond",
    "Pistol ___ (Oklahoma State's mascot)",
    "Rose high in some people's estimation",
    "\"Our Gang\" pooch",
    "British actor Postlethwaite",
    "Tennis player Sampras",
    "___ Best of the pre-Ringo Beatles",
    "With 37-Down, seven-time Wimbledon champ",
    "Rose who surpassed Cobb",
    "Townshend of the Who",
    "Rose with a hit record",
    "Rose family member",
    "Black ___, archnemesis of Mickey Mouse",
    "With 35-Across, one who has done the circled things, combined, more often than any other major-league player",
    "Jim's partner on \"Adam 12\"",
    "Fountain in New Orleans",
    "Folk singer Seeger",
    "Tampa/St. ___",
    "Townshend of the Who",
    "Folk singer Seeger",
    "Best of the early Beatles",
    "Red Rose",
    "Journalist Hamill",
    "Baseball's Rose",
    "Townshend of the Who",
    "Tampa/St. ___",
    "Guitarist Townshend",
    "Tennis ace Sampras",
    "Singer Seeger",
    "St. ___ Beach, Fla.",
    "With 69-Across, baseball great with 2,165 career runs",
    "Seeger of the Weavers",
    "Red Rose, once",
    "Ex-governor of California ___ Wilson",
    "Fountain of jazz",
    "St. ___, spring break mecca",
    "Basketball star Maravich",
    "Red Rose",
    "Fountain of music",
    "Townshend of the Who",
    "Folk singer Seeger",
    "Fountain in New Orleans",
    "Sampras or Rose",
    "Red Rose",
    "\"The Mod Squad\" role",
    "Football exec Rozelle",
    "Our Gang pooch",
    "Tennis great Sampras",
    "Rose in a field",
    "Journalist Hamill",
    "Tennis ace Sampras",
    "Rose by another name?",
    "Townshend of the Who",
    "Tampa-St. ___",
    "Red Rose",
    "\"Our Gang\" dog",
    "Best of the Beatles",
    "Officer Malloy of \"Adam 12\"",
    "Singer Seeger",
    "St. ___ (spring break spot)",
    "Townshend of the Who",
    "Lt. ___ Mitchell (Tom Cruise's \"Top Gun\" role)",
    "Townshend of the Who",
    "St. ___ (fine beach locale)",
    "Red Rose, once",
    "N.F.L.'s ___ Rozelle Award",
    "Sampras of tennis",
    "Tennis's Sampras",
    "Seeger of song",
    "St. ___ (vacation spot)",
    "Tennis's Sampras",
    "California Gov. Wilson",
    "Tennis's Sampras",
    "Journalist Hamill",
    "\"Our Gang\" pooch",
    "Townshend of the Who",
    "Baseball's Rose",
    "Gov. Wilson",
    "Best of the Beatles",
    "Hall-of-Famer Maravich",
    "N.F.L.'s___ Rozelle Award",
    "Tennis's Sampras",
    "A Rose by another name",
    "St. ___ (vacationer's mecca)",
    "Much is done for his sake",
    "Sneaky guy?",
    "Reds' Rose",
    "Governor Wilson",
    "St. ___ (vacation spot)",
    "Tennis's Sampras",
    "Sneaky guy?"
  ],
  "rein": [
    "Line from a bit?",
    "Pull (in)",
    "Line for a sleigh ride",
    "It comes straight from the horse's mouth",
    "Check, with \"in\"",
    "Curb, with \"in\"",
    "Apt rhyme for \"constrain\"",
    "Steed stopper",
    "Symbol of control",
    "Bridle strap",
    "Pull (in)",
    "Harness strap",
    "Bridle strap",
    "This pulls a bit",
    "Pull (in)",
    "Horse leader?",
    "Control, with \"in\"",
    "Restrain, with \"in\"",
    "Curb, with \"in\"",
    "Pull (in)",
    "Pull (in)",
    "Jockey strap",
    "Jockey's strap",
    "Curb, with \"in\"",
    "Rider's handful",
    "Bridle strap",
    "Jockey's strap",
    "Free ___ (total control)",
    "Jockey strap",
    "It can pull a bit to one side",
    "Check",
    "Symbol of authority",
    "Pull (in)",
    "Bridle strap",
    "Horse halter",
    "Jockey's control",
    "Derby strap",
    "Check",
    "Head turner",
    "Keep in check, with \"in\"",
    "Santa's deer leader?",
    "Pull (in)",
    "Head turner",
    "Pull (in)",
    "Pull (in)",
    "Curb",
    "Silver checker",
    "Jockey's handful",
    "Dancer's strap?",
    "Bridle strap",
    "It may hold your horses",
    "Bridle strap",
    "Bridle part",
    "Restraint",
    "What a coach driver holds",
    "Horse halter",
    "Carriage driver's need",
    "Halter attachment",
    "Pull back (in)",
    "Pull (in)",
    "Coachman's line",
    "Guide strap",
    "Silver holder",
    "Cupid holder?",
    "Pull (in)",
    "Scout leader?",
    "Jockey strap",
    "Stage lead",
    "Curb",
    "Check",
    "Check, with \"in\"",
    "Horse controller",
    "Pull (in)",
    "Jockey's strap",
    "Bit attachment",
    "Curb, with \"in\"",
    "Keep in check",
    "Bridle strap",
    "Equestrian's grip",
    "Pull (in)",
    "One that's a bit controlling?",
    "Check",
    "This pulls a bit",
    "Bridle part",
    "Pull (in)",
    "Horse halter?",
    "Bit attachment",
    "Driver's line",
    "Jockey strap",
    "Silver lead",
    "Pull (in)",
    "Bridle strap",
    "Pull (in)",
    "Restraint",
    "Plowman's need",
    "Harness part",
    "Bit attachment",
    "Bridle strap",
    "Trigger control",
    "Bridle strap",
    "Bit attachment",
    "Check",
    "This pulls a bit",
    "Bridle strap",
    "Pull (in)",
    "It might run down the neck",
    "Pull (in)",
    "Bridle strap",
    "Check",
    "Pull (in)",
    "Silver holder?",
    "Traveller's check",
    "Harness part",
    "Check",
    "Trigger puller?",
    "Curb, with \"in\"",
    "Control",
    "Bridle part",
    "Harness part",
    "Silver wear?",
    "Curb",
    "Harness part",
    "Harness part",
    "Curb, with \"in\""
  ],
  "gins": [
    "Generates dubiously, with \"up\"",
    "Beefeater and Bombay Sapphire",
    "Cotton processors",
    "Choices for Negroni cocktails",
    "Generates, with \"up\"",
    "Plantation machines",
    "Bombay and Boodles",
    "Goes out, in a card game",
    "Machines on cotton plantations",
    "Goes out in a game of rummy",
    "Traps",
    "Array on a bar shelf",
    "Deseeders",
    "Some traps",
    "They're worth bonuses, in cards",
    "Cotton seeders",
    "Wins in a card game",
    "Goes out, in a card game",
    "Whitney contraptions"
  ],
  "jots": [
    "Takes (down)",
    "Quickly writes (down)",
    "Tiny amounts",
    "Little bits",
    "Dashes off",
    "Tiny amounts",
    "Scribbles (down)",
    "Smidgens",
    "Scribbles (down)",
    "Writes quickly",
    "Scribbles",
    "Scribbles (down)",
    "Writes (down)",
    "Scribbles (down)",
    "Tittles",
    "Scribbles, with \"down\"",
    "Tittles"
  ],
  "ving": [
    "Actor Rhames",
    "\"Pulp Fiction\" actor Rhames",
    "Rhames of \"Mission: Impossible\""
  ],
  "aden": [
    "Mideast locale of Sira Fortress",
    "Main port of Yemen",
    "Mideast port",
    "Mideast's Gulf of ___",
    "Port SSE of the Suez Canal",
    "Yemeni port",
    "Mideast's Gulf of ___",
    "City on a gulf of the same name",
    "Port north of the Horn of Africa",
    "Arabian port",
    "City where, according to legend, Cain and Abel are buried",
    "Yemeni port",
    "Mideast's Gulf of ___",
    "British crown colony from 1937 to 1963",
    "Gulf of ___ (waters off the coast of Djibouti)",
    "Yemen seaport",
    "Yemeni seaport",
    "Yemen seaport",
    "Yemeni port city",
    "Former British crown colony in the Mideast",
    "Mideast's Gulf of ___",
    "Yemeni port city",
    "Mideast's Gulf of ___",
    "Major tanker port",
    "Yemeni port",
    "Mideast's Gulf of ___",
    "Gulf of ___",
    "Yemeni port",
    "Port of Yemen",
    "City where some believe Cain and Abel are buried",
    "Yemeni port",
    "Mideast port",
    "Gulf of ___",
    "Mideast's Gulf of ___",
    "Mideast oil port",
    "Port of Yemen",
    "Gulf of ___",
    "Gulf of ___",
    "Yemeni port",
    "Former capital near 44-Across",
    "Yemeni port",
    "Port SSE of Sana",
    "Yemeni seaport",
    "Present-day site of the ancient port city Eudaemon",
    "Port annexed by Britain in 1839",
    "Gulf of ___, off the coast of Yemen",
    "Gulf of ___, modern pirates' realm",
    "Main port of Yemen",
    "Near Eastern port",
    "Port near the Red Sea",
    "Mideast city that was once a British protectorate",
    "Mideast's Gulf of ___",
    "Yemeni port",
    "Arabian Peninsula port",
    "City that lost capital status in 1990",
    "Yemeni port",
    "Mideast harbor city",
    "Mideast's Gulf of ___",
    "Yemeni port",
    "Gulf of ___, off the Horn of Africa",
    "Site of a 1992 Al Qaeda attack",
    "Port whose harbor is in the crater of an extinct volcano",
    "Gulf port",
    "Gulf of ___, off the coast of Yemen",
    "Mideast's Gulf of ___",
    "Mideast port",
    "Yemeni port",
    "Mideast port",
    "Yemeni port",
    "Mideast's Gulf of ___",
    "Former British colony in Arabia",
    "Near Eastern port",
    "Major oil refinery port",
    "Mideast's Gulf of ___",
    "Chief Yemeni port",
    "Port on a gulf of the same name",
    "Mideast's Gulf of ___",
    "Yemeni port",
    "Port in Yemen",
    "Yemen's Gulf of ___",
    "Former British colony",
    "Mideast port",
    "Yemeni city",
    "Major oil port",
    "Mideast's Gulf of ___",
    "Port city built around an old volcano crater",
    "Gulf port",
    "Yemeni port",
    "Mideast's Gulf of ___",
    "Gulf port",
    "Yemeni port",
    "Gulf of ___ (entrance to the Red Sea)",
    "Gulf port",
    "Gulf port",
    "Yemeni port",
    "Yemeni port",
    "Port near Ta'izz",
    "Djibouti's Gulf of ___",
    "Yemen's chief port",
    "Gulf of ___, off the coast of Yemen",
    "Yemeni port",
    "Yemeni city",
    "Mideast's Gulf of ___",
    "Gulf port",
    "Mideast's Gulf of ___",
    "Mideast port",
    "The Mideast's Gulf of ___",
    "Former British protectorate",
    "Yemen's Gulf of ___",
    "Trading partner of ancient Tyre",
    "Gulf port",
    "Mideast port",
    "Gulf port",
    "Yemeni port",
    "Yemeni city",
    "Yemeni port",
    "Mideast's Gulf of ___",
    "Mideast's Gulf of ___",
    "Yemeni capital",
    "Gulf east of Djibouti",
    "Mideast's Gulf of ___",
    "Mideast's Gulf of ___",
    "Yemeni port",
    "Gulf capital",
    "Former crown colony",
    "Mideast's Gulf of ___",
    "The Mideast's Gulf of ___",
    "Major fueling station for the Suez Canal",
    "Yemeni port",
    "Yemeni port",
    "Gulf north of Somalia",
    "Yemen port",
    "Yemeni city",
    "Old British colony",
    "Arabia's Gulf of ___",
    "Arabian port",
    "Gulf near Yemen",
    "Mideast's Gulf of ___",
    "Mideastern gulf",
    "Yemen port",
    "Gulf north of Somalia",
    "Yemen port",
    "Mideast gulf",
    "Arabian Peninsula port",
    "Gulf of ___",
    "Arabian Sea gulf",
    "Mideast gulf",
    "Yemen's second-largest city",
    "___ of thieves"
  ],
  "aced": [
    "Performed very well on",
    "Got an A+ on",
    "Didn't just excel on",
    "Got an A on",
    "Got 100% on",
    "Completed perfectly",
    "Scored 100 on",
    "Scored 100 on",
    "Got 100 on",
    "Did an amazing job on",
    "Got a perfect score on",
    "Passed with flying colors",
    "Nailed",
    "Sailed through",
    "Got 100 on",
    "Got completely correct",
    "Slipped one by, in a way",
    "Crushed, as a test",
    "Nailed",
    "Served well?",
    "Got one's serve past",
    "Scored 100 on",
    "Got nothing back from?",
    "Passed with ease",
    "Didn't get a return from",
    "Got a hole in one on",
    "Scored 100 on",
    "Achieved a perfect score on",
    "Made no mistakes on",
    "Got a 41-Across on",
    "Killed, as a test",
    "Waltzed through",
    "Easily passed",
    "Blasted through",
    "Scored 100 on, as a test",
    "Served a ball past",
    "Whizzed through",
    "Passed easily",
    "Killed, as a test",
    "Breezed through",
    "Nailed",
    "Nailed",
    "Breezed through",
    "Didn't just pass",
    "Got a perfect score on",
    "Didn't bomb at all",
    "Sailed through",
    "Served as well as possible",
    "Hit a serve past",
    "Passed with flying colors",
    "Served well",
    "Nailed",
    "Scored perfectly on",
    "Scored 100% on",
    "Got a 15-Across on",
    "Nailed",
    "Nailed, as a test",
    "Served to perfection?",
    "Got 100 on",
    "Nailed",
    "Provided unbeatable service?",
    "Shot one on",
    "Breezed through",
    "Opposite of blew",
    "Nailed",
    "Got 100 on",
    "Scored 100% on",
    "Easily passed",
    "Breezed through",
    "Served past",
    "Breezed through",
    "Handled perfectly",
    "Served past",
    "Scored 100 on",
    "Scored very well on",
    "Passed with flying colors",
    "Zipped through",
    "Got 100 on",
    "Served perfectly",
    "Caught flat-footed on a tennis serve, maybe",
    "Sailed through",
    "Scored on serve",
    "Breezed through, as a test",
    "Didn't just pass",
    "Breezed through",
    "Did perfectly",
    "Like a poor tennis player, frequently",
    "Scored 100 on",
    "Scored on serve",
    "Got 100 on",
    "Sailed through",
    "Sailed through",
    "Put past?",
    "Got 100% on",
    "Zipped through",
    "Got 100 on",
    "Whizzed through",
    "Put one past?",
    "More than passed",
    "Shot one",
    "Scored 100 on",
    "Got 100 on, as a test",
    "Passed with flying colors",
    "Got 100 on",
    "Made a great point",
    "Scored 100 on",
    "Completed effortlessly",
    "Easily passed",
    "Scored 100 on",
    "Got no return from",
    "Put one past",
    "Passed beautifully",
    "Served perfectly, in tennis",
    "Scored 100 on",
    "Finished perfectly",
    "Passed with ease",
    "Got a top mark on",
    "Opposite of flunked",
    "Did better than a B",
    "Scored a hole-in-one",
    "Served well",
    "Scored on a serve",
    "Opposite of flunked",
    "Handled perfectly",
    "Didn't just pass",
    "Passed easily"
  ],
  "lets": [
    "\"I'm game\"",
    "\"Sounds like a plan\"",
    "\"I'd love to!\"",
    "Prince's \"___ Go Crazy\"",
    "\"Sure, I'm game\"",
    "\"I'm game if you're game!\"",
    "No-fault rulings at court?",
    "Gives permission to",
    "Positive response to an invitation",
    "Answer to \"Shall we?\"",
    "\"We should totally do that!\"",
    "Calls for reserves?",
    "Calls to reserve?",
    "\"___ party!\"",
    "Badminton do-overs",
    "\"I'm game!\"",
    "Agreeable answer to an invitation",
    "\"Sounds like a plan!\"",
    "\"Sounds like a plan!\"",
    "\"Why don't we?!\"",
    "\"I'm in!\"",
    "\"Oh, why not?!\"",
    "Do-overs",
    "\"Sure, I'm game\"",
    "\"Sure, I'm game\"",
    "With 106-Across, \"It's time to do this thing\"",
    "Instances when service isn't perfect?",
    "\"Sure, why not?!\"",
    "Court replays",
    "\"I'm game\"",
    "\"I'm game\"",
    "Response to \"Shall we?\"",
    "Tennis do-overs",
    "\"Yes\" to an invitation",
    "\"Shall we?\" reply",
    "\"___ Make a Deal\"",
    "\"Why don't we?!\"",
    "Rents out",
    "Allows",
    "\"___ Go Crazy\" (#1 Prince hit)",
    "\"Sure, I'm up for it\"",
    "Response of assent",
    "Tennis do-overs",
    "Do-overs",
    "\"Sure, why not?!\"",
    "\"I'm game if you're game!\"",
    "Court calls",
    "Cole Porter's \"___ Do It\"",
    "\"Sure, I'm game\"",
    "\"___ Dance\" (David Bowie album)",
    "\"I'm game!\"",
    "Court interferences",
    "David Bowie's \"___ Dance\"",
    "Reply to \"Shall we?\"",
    "Reply to \"Shall we?\"",
    "Do-overs",
    "\"Sure, why don't we?!\"",
    "\"I'm game\"",
    "Answer to \"Shall we?\"",
    "Reasons for do-overs",
    "\"___ not fight\"",
    "\"Why not?!\"",
    "Response to \"Shall we?\"",
    "\"___ go!\"",
    "\"___ go!\"",
    "Polite response to an invitation",
    "Response to \"Shall we?\"",
    "\"Sure, I'm game\"",
    "\"___ Fall in Love\"",
    "Doesn't disallow",
    "Permits",
    "Cry of concurrence",
    "Positive reply to an invitation",
    "Answer to \"Shall we?\"",
    "\"Oh, why not?\"",
    "\"O.K., why not?\"",
    "\"Why don't we?\"",
    "Cole Porter's \"___ Do It\"",
    "\"I'm game!\"",
    "?ô___ Dance?ö (Benny Goodman theme)",
    "Answer to \"Shall we?\"",
    "Net calls",
    "Tennis calls",
    "\"___ Do It\" (Porter tune)",
    "\"why don't we?\"",
    "O.K.'S"
  ],
  "dang": [
    "\"Shoot!\"",
    "\"Nuts!\"",
    "\"Oh, fudge!\"",
    "\"Phooey!\"",
    "\"Goldarn it!\"",
    "\"Well, shoot!\"",
    "\"Ain't that the worst!\"",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "\"Shoot!\"",
    "\"Shoot!\"",
    "\"Well, gosh darn!\"",
    "\"Goll-lee!\"",
    "\"Durn it!\"",
    "\"Shoot!\"",
    "Darn",
    "\"Drat!\"",
    "\"Dagnabbit!\"",
    "\"Consarn it!\"",
    "\"Shoot!\"",
    "\"Durn it!\"",
    "\"Shoot!\"",
    "\"Confound it!\"",
    "Swearer's euphemism"
  ],
  "espn": [
    "Channel owned by Disney",
    "It shows a lot of plays, but no musicals",
    "\"SportsCenter\" channel",
    "TV channel that owns the website The Undefeated",
    "Disney-owned cable channel",
    "Place to get highlights",
    "\"SportsCenter\" network",
    "\"Fantasy Focus\" podcast airer",
    "\"30 for 30\" network",
    "It has a lot of competition on TV",
    "___ Daily, popular podcast for armchair athletes",
    "Broadcaster of the X Games",
    "\"Monday Night Football\" airer",
    "Locker room shower?",
    "Basic cable channel owned by Disney",
    "Locker room shower?",
    "Channel with highlights",
    "Bristol, Conn.-based cable inits.",
    "\"SportsCenter\" airer",
    "\"SportsNation\" station",
    "\"SportsCenter\" channel",
    "Postgame shower?",
    "Channel with several spinoff channels",
    "\"College GameDay\" channel",
    "___ Classic (cable channel)",
    "\"Monday Night Football\" channel",
    "FS1 competitor",
    "FiveThirtyEight.com owner",
    "National Spelling Bee airer",
    "\"First Take\" airer",
    "Home of \"Monday Night Football\"",
    "Place to follow the M.L.B.",
    "\"Outside the Lines\" airer",
    "Subject of the 2011 book \"These Guys Have All the Fun\"",
    "World Series of Poker channel",
    "Channel that describes itself as \"The worldwide leader in sports\"",
    "Cable's ___ Classic",
    "Where to see \"Outside the Lines\"",
    "National Spelling Bee airer",
    "Sister company of ABC",
    "Airer of the \"Not Top Ten\" plays",
    "Channel turned to at many bars",
    "Disney/Hearst-owned channel",
    "FiveThirtyEight owner",
    "\"Outside the Lines\" broadcaster",
    "Channel with many game highlights",
    "\"Outside the Lines\" broadcaster",
    "FiveThirtyEight owner",
    "Channel with postgame analysis",
    "Where many games can be viewed",
    "\"NFL Live\" airer",
    "\"Pardon the Interruption\" network",
    "Armchair athlete's channel",
    "Its logo's letters have a stripe running through them",
    "Channel with game highlights",
    "___ Zone",
    "Provider of N.F.L. coverage",
    "\"Pardon the Interruption\" airer",
    "\"1st and 10\" airer",
    "N.F.L. broadcaster",
    "\"Baseball Tonight\" broadcaster",
    "\"Pardon the Interruption\" presenter",
    "\"Outside the Lines\" airer",
    "Presenter of many game shows?",
    "\"Around the Horn\" cable channel",
    "Sister company of ABC",
    "Big game hunter?",
    "\"Baseball Tonight\" airer",
    "Where to find \"Baseball Tonight\"",
    "\"Nascar Now\" broadcaster",
    "Channel game",
    "\"Friday Night Fights\" presenter",
    "\"Baseball Tonight\" network",
    "\"Around the Horn\" channel",
    "It shows many matches",
    "Jock's channel",
    "\"Nascar Now\" channel",
    "Channel for football and basketball games",
    "\"Monday Night Football\" channel",
    "\"Pardon the Interruption\" airer",
    "Armchair athlete's channel",
    "Nascar airer",
    "\"SportsCenter\" channel",
    "Home of \"Monday Night Football\"",
    "Cable inits. since 1979",
    "\"SportsCenter\" channel",
    "Channel for armchair athletes",
    "Where to see \"Monday Night Football\"",
    "Cable staple since 1979",
    "Disney-owned channel",
    "X Games airer",
    "Airer of many games",
    "Fox Sports alternative",
    "\"Dream Job\" network",
    "Armchair athlete's aid",
    "Nascar airer",
    "___ Zone",
    "Armchair athlete's channel",
    "Cable channel owned by ABC",
    "___ Zone (bar and restaurant chain)",
    "\"Baseball Tonight\" channel",
    "Basic cable offering",
    "Part of ABC",
    "\"Baseball Tonight\" channel",
    "Inits. for an armchair quarterback?",
    "X Games shower",
    "?ôBaseball Tonight?ö channel",
    "\"X Games\" airer",
    "Armchair quarterback's channel",
    "?ôSportsZone?ö airer",
    "Part of the Walt Disney Co.",
    "\"SportsCenter\" channel",
    "Locker room shower?",
    "X-Games telecaster",
    "Interest of armchair athletes",
    "Nascar broadcaster",
    "Cable channel based in Bristol, Conn.",
    "One place to get scores",
    "Cable staple",
    "Armchair athlete's channel",
    "Armchair athlete's channel",
    "N.F.L. broadcaster",
    "Cable staple",
    "Cable staple"
  ],
  "atab": [
    "Open ___ (plan to pay later)",
    "Run ___ (postpone the bar bill)",
    "Open ___ (start at the bar, maybe)",
    "Run up ___ (owe)",
    "Run up ___",
    "Run ___ (drink on credit)",
    "Run ___ (not pay as you go)",
    "Run up ___",
    "Run ___ (owe)"
  ],
  "shia": [
    "Like the majority of Iraqis and Bahrainis",
    "Branch of Islam",
    "One of the two main branches of Islam",
    "Second-largest branch of Islam",
    "Branch of Islam",
    "Much of the population of Iran",
    "Branch of Islam",
    "Branch of Islam",
    "Most Iranian Muslims",
    "One side of a longstanding feud",
    "Actor LaBeouf",
    "Muslim minority",
    "Branch of Islam",
    "LaBeouf of \"Transformers\"",
    "Sect opposed to ISIS",
    "Branch of Islam",
    "Many a pilgrim to Mecca",
    "Branch of Islam",
    "Actor LaBeouf",
    "Actor LaBeouf",
    "Actor LaBeouf",
    "Sunni rival",
    "Branch of Islam",
    "Branch of Islam",
    "Branch of Islam predominant in Iran",
    "Ayatollah, e.g.",
    "Islamic sect",
    "Division of Islam",
    "Branch of Islam",
    "Some Muslims",
    "Many Iranian believers",
    "Some Muslims"
  ],
  "abel": [
    "Brother of Cain",
    "His murder elicited the first wail of mourning, in Islamic accounts",
    "Member of the first family",
    "___ Prize, annual award in mathematics",
    "Whom Cain slew",
    "The brother in \"Am I my brother's keeper?\"",
    "Biblical shepherd",
    "Brother of Cain",
    "Brother of Cain",
    "Second son",
    "___ Tesfaye, real name of singer The Weeknd",
    "Shepherd slain by Cain",
    "Biblical brother",
    "Ferrara who directed \"King of New York\"",
    "Brother of Cain",
    "First victim of sibling rivalry",
    "One of three biblical brothers",
    "Whom Cain slew in Genesis",
    "Son of Adam",
    "___ Tesfaye, real name of pop music's the Weeknd",
    "Victim of the first fratricide",
    "Member of the first family",
    "Adam's family member",
    "___ Tesfaye a.k.a. R&B's the Weeknd",
    "Victim of murder one",
    "Filmmaker Ferrara",
    "Brother of Cain",
    "Ferrara who directed \"Bad Lieutenant,\" 1992",
    "Big brother's victim, once",
    "Cain mutiny victim?",
    "Brother of Cain",
    "Biblical anagram of 55-Across",
    "\"Am I my brother's keeper?\" brother",
    "Third man",
    "Man's name that's a homophone of 16-Across",
    "The first to die",
    "Genesis brother",
    "Second major figure in the Bible alphabetically",
    "Genesis son",
    "First person to die in the Bible",
    "___ Prize, annual international award for mathematics",
    "Third person masculine?",
    "Brother of Cain",
    "Biblical brother",
    "Second issue?",
    "Genesis victim",
    "Uncle of Enoch",
    "Genesis son",
    "First fratricide victim",
    "Early fratricide victim",
    "The brother in \"Am I my brother's keeper?\"",
    "Son of Eve",
    "Genesis son",
    "Number two son",
    "Biblical brother",
    "Biblical shepherd",
    "Genesis brother",
    "First name alphabetically in 42-Down",
    "He had righteous blood, per Matthew 23:35",
    "Genesis victim",
    "Biblical homophone of 1-Down",
    "The good son",
    "The brother in \"Am I my brother's keeper?\"",
    "Early shepherd",
    "Cain's victim",
    "The brother in \"Am I my brother's keeper?\"",
    "Genesis victim",
    "Genesis victim",
    "Explorer Tasman of Tasmania fame",
    "Genesis victim",
    "Genesis son",
    "Biblical brother",
    "Brother of Cain and Seth",
    "Genesis shepherd",
    "Eve's second son",
    "Biblical brother",
    "Cain's brother",
    "Early victim",
    "Genesis victim",
    "Mathematician Niels Henrik ___",
    "Genesis brother",
    "Genesis brother",
    "Biblical shepherd",
    "Genesis boy",
    "Genesis victim",
    "Genesis victim",
    "Genesis son",
    "Cain's victim",
    "Cain's brother",
    "Biblical shepherd",
    "Genesis brother",
    "Cain's victim",
    "First fratricide victim",
    "Biblical brother",
    "Second son",
    "Genesis victim",
    "Old union leader I. W. ___",
    "Genesis victim",
    "Explorer Tasman",
    "See 54-Across",
    "Spy exchanged for Powers in 1962",
    "Brother of Cain",
    "First martyr, according to Matthew",
    "1960's-70's steelworkers' chief",
    "Noted fratricide victim",
    "Brother of Cain",
    "The third man?",
    "Labor leader I. W. ___",
    "Cain's brother",
    "Genesis shepherd",
    "First victim",
    "Rima's \"Green Mansions\" beloved",
    "\"Fear City\" director Ferrara",
    "Brother of Seth",
    "Genesis victim",
    "Director Gance",
    "Second son",
    "First shepherd",
    "Cain's victim",
    "Biblical murder victim",
    "Third man",
    "Magwitch of \"Great Expectations\"",
    "Uncle of Enos",
    "Cain's brother",
    "Labor leader I. W.",
    "Shepherd of Genesis",
    "Genesis brother",
    "Spy exchanged for Powers",
    "Genesis name",
    "Former steelworkers' union chief",
    "Son of Adam and Eve",
    "Director Ferrara",
    "United Steelworkers leader I. W. ___",
    "Newsman Elie",
    "Genesis brother",
    "Offerer of lambs to God",
    "\"Napoleon\" director Gance",
    "First murder victim",
    "Brother of Cain",
    "Spy in a 1962 exchange",
    "Cain's victim",
    "Genesis son",
    "Longtime Steelworkers chief",
    "Spy Rudolf",
    "Famed fratricide victim",
    "Brother in \"Am I my brother's keeper?\"",
    "The \"first martyr\"",
    "Magwitch of \"Great Expectations\"",
    "Old actor Walter",
    "Genesis brother",
    "Cain's victim",
    "Son of Adam",
    "First shepherd",
    "Victim of sibling rivalry",
    "\"J'Accuse\" director Gance",
    "The third man",
    "Mr. Magwitch of \"Great Expectations\"",
    "Third man",
    "German virtuoso Karl",
    "Soviet spy Rudolf",
    "Ill-fated sibling rival",
    "Onetime labor chief",
    "Spy in a 1962 exchange",
    "Ex-steelworkers chief",
    "Eve's second-born"
  ],
  "ever": [
    "At any point",
    "Even once",
    "In perpetuity",
    "Anytime at all",
    "Penultimate word in many fairy tales",
    "What's before after, at the end?",
    "\"Do I ___!\"",
    "\"Best. Day. ___!\"",
    "At any time",
    "\"Do I ___!\"",
    "Of all time",
    "___ and anon",
    "At any time",
    "\"Have you ___ wondered ...?\"",
    "At all",
    "Better than ___",
    "Of all time",
    "\"Do I ___!\"",
    "___ after",
    "At any time",
    "Penultimate word of a fairy tale",
    "Hardly ___",
    "At any point in history",
    "At any juncture",
    "___ since",
    "\"Do I ___!\"",
    "It becomes its own synonym when \"for\" is added in front",
    "\"Would I ___!\"",
    "For all time",
    "\"Have I ___ told you ...?\"",
    "Always",
    "\"For the first time ___ ...\"",
    "Second-to-last word in a fairy tale",
    "At least once",
    "Continually",
    "Even once",
    "\"... if ___ a wiz there was\"",
    "Next-to-last word in a fairy tale",
    "Eternally",
    "Ceaselessly",
    "\"... lived happily ___ after\"",
    "\"Be it ___ so humble ...\"",
    "\"Would I ___!\"",
    "Of all time",
    "Green leader?",
    "Eternally",
    "\"If I ___ ...\"",
    "\"... happily ___ after\"",
    "In perpetuum",
    "At any time",
    "\"... happily ___ after\"",
    "\"Do I ___!\"",
    "At any point",
    "At least once",
    "Next-to-last word in the Lord's Prayer",
    "\"Well, did you ___?!\"",
    "Mindful leader?",
    "Eternally",
    "\"Well, did you ___?!\"",
    "___ since",
    "Last word in the Lord's Prayer, before \"Amen\"",
    "\"Do I ___!\"",
    "By any chance",
    "Present opener?",
    "Partner of anon",
    "\"If I ___ ...\"",
    "At any time",
    "At any time",
    "\"Did you ___?\"",
    "Incessantly",
    "Sometime",
    "Loving leader?",
    "Penultimate fairy tale word",
    "Anytime",
    "\"Did I ___!\"",
    "At any time",
    "\"Did you ___?!\"",
    "At any point",
    "\"... ___ after\"",
    "At all",
    "At any time",
    "At any point",
    "Even once",
    "Always",
    "Always",
    "\"... ___ after\"",
    "At any time",
    "\"Be it ___ so humble ?à\"",
    "At any time",
    "\"Did you ___?!\"",
    "\"Did you ___?\"",
    "\"?à happily ___ after\"",
    "___ and 27-Down (for all time)",
    "By any chance",
    "Always",
    "\"Did you ___?!\"",
    "It can come before after",
    "In any way",
    "Present opener?",
    "At any time",
    "Constantly",
    "\"If I ___ ...\"",
    "At any time",
    "In perpetuity",
    "Exceedingly, with \"so\"",
    "In any case",
    "At any time",
    "At any time",
    "\"Boy, do I ___!\"",
    "At all",
    "At any time",
    "By any chance",
    "Continuously",
    "Constantly",
    "Ending with \"for\" or \"what\"",
    "At any time",
    "\"As ___\" (letter closing)",
    "Even once",
    "Penultimate fairy tale word",
    "At any time",
    "Who can come before it",
    "\"... happily ___ after\"",
    "Always",
    "Anytime",
    "___ and anon",
    "Of all time",
    "\"... happily ___ after\"",
    "At all",
    "Penultimate fairy tale word",
    "At any time",
    "Next-to-last fairy tale word",
    "\"Did you ___?!\"",
    "Continuously",
    "At all",
    "Continuously",
    "Penultimate fairy tale word",
    "Eternally",
    "\"Well, did you ___?\"",
    "Anon's partner",
    "Word prefixed by who, what or when",
    "At any point",
    "At any point",
    "At any time",
    "Not just for now",
    "At any time",
    "\"Did you ___?\"",
    "At any time",
    "Lasting attachment",
    "In any way",
    "At any time",
    "___ and anon",
    "\"Did you ___?\"",
    "\"If ___ I should leave...\"",
    "Always",
    "Perpetually",
    "Just once",
    "Unceasingly",
    "Relentlessly",
    "By any chance",
    "Incessantly",
    "At any time",
    "By any chance",
    "Continuously",
    "\"If you ___...!\" (threat)",
    "Penultimate fairy-tale word",
    "At any time",
    "In any way",
    "Anon's partner",
    "\"Did you ___?\"",
    "\"Did I___!\"",
    "At any time",
    "\"Did you___!\"",
    "By any chance",
    "At any time",
    "At any time",
    "Ad infinitum",
    "\"Do I ___!\"",
    "Eternally",
    "___ and anon",
    "Always",
    "Always",
    "At all",
    "In perpetuity",
    "By any chance",
    "For or what follower",
    "At any time",
    "Always",
    "Hardly ___ (rarely)",
    "Constantly",
    "Lasting introduction",
    "At any time",
    "Ad infinitum",
    "?ôDid you ___ !?ö",
    "Perpetually",
    "Penultimate fairy tale word"
  ],
  "maui": [
    "Second-largest Hawaiian island",
    "Hawaii surfing destination",
    "Site of an annual Taro Festival",
    "Home to the Hana Highway",
    "Island whose name rhymes with 72-Across",
    "Second-largest of the Hawaiian Islands",
    "Island east of Lanai",
    "Island ESE of Oahu",
    "Second-largest Hawaiian island",
    "Second-largest Hawaiian island",
    "Island ESE of Oahu",
    "Home of Haleakala National Park",
    "Windsurfing mecca",
    "Kahakuloa Head locale",
    "Island next to Molokai",
    "Hawaiian isle",
    "Wailuku is its county seat",
    "Wailuku's county",
    "Site of Haleakala National Park",
    "Hawaiian island",
    "Lanai's county",
    "Where Haiku is",
    "Haleakala National Park setting",
    "The Pacific's \"Valley Isle\"",
    "Hawaiian island",
    "Hawaiian isle",
    "Hawaii's \"Valley Isle\"",
    "Windsurfers' mecca",
    "Lanai neighbor",
    "Neighbor of Lanai",
    "Island near Lanai",
    "Second-largest of the Hawaiian islands",
    "Home of Haleakala National Park",
    "Haleakala's locale",
    "Part of Hawaii",
    "View from Molokai",
    "Lanai neighbor",
    "Haleakala Crater locale",
    "Haleakala National Park site",
    "So-called \"Valley Isle\"",
    "Kahului's locale",
    "Windsurfers' mecca"
  ],
  "info": [
    "Data",
    "Lowdown",
    411,
    "Scoop",
    "Lowdown",
    "Details",
    "\"For more ___ ...\"",
    "Dope",
    "Dope",
    "Thumbnail bio, e.g.",
    "Data",
    "Help desk offering",
    "Skinny",
    "What a help desk provides",
    "Skinny",
    "Dope",
    "Skinny",
    "\"For more ___ ...\"",
    "Skinny",
    "Scoop contents",
    "The \"I\" of F.Y.I., for short",
    "Dope",
    "Help desk offering",
    "Dirt",
    "Facts and figures, for short",
    "Lowdown",
    "Skinny",
    "Skinny",
    "\"For more ___ ...\"",
    "Poop",
    "Google users seek it",
    "Dope",
    "Dope",
    "Contents of a scoop",
    "Dope",
    "Stats, e.g.",
    "Skinny",
    "Skinny",
    "It's gathered by scouts",
    "Dope",
    "Dirt",
    "It's available at a \"?\" sign",
    "The skinny",
    "Poop",
    "Dope",
    "Poop",
    "Data",
    "Dope",
    "Data",
    "Dope",
    "Dope",
    "Poop",
    "Facts",
    "Facts",
    "The skinny",
    "Data",
    "The low-down",
    "Lowdown",
    "Stats, e.g.",
    "Dope",
    "Dirt",
    "Dope",
    "Data",
    "Poop",
    "Data",
    "Data",
    "It comes in a scoop",
    "Data",
    "Dope",
    "Dope"
  ],
  "else": [
    "\"What ___ is new?\"",
    "\"Anything ___?\"",
    "\"What ___?\"",
    "\"What ___ is new?\"",
    "Otherwise",
    "\"What ___?\"",
    "Conditional keyword for a programmer",
    "Alternatively",
    "\"If all ___ fails ...\"",
    "Part of a coding conditional",
    "\"Is there anything ___?\"",
    "If-___ (computer programming statement)",
    "Instead",
    "Otherwise",
    "\"Anyone ___?\"",
    "Choice word",
    "\"What ___?\" (store clerk's query)",
    "Word after nothing, something and anything",
    "Alternatively",
    "If-then counterpart",
    "\"If all ___ fails ...\"",
    "Conditional word",
    "\"If all ___ fails ...\"",
    "\"Or ___!\"",
    "Otherwise",
    "Instead",
    "\"Who ___ knows?\"",
    "\"Or ___!\" (end of an ultimatum)",
    "Ultimatum word",
    "\"What ___?\"",
    "\"What ___ is there?\"",
    "\"What ___ would you like?\"",
    "If's counterpart, in programming",
    "If-then-___ (kind of logic statement)",
    "Instead",
    "In addition",
    "\"Anybody ___?\"",
    "\"What ___?\"",
    "Otherwise",
    "If not",
    "\"When all ___ fails ...\"",
    "Word after who, what, when, where or why",
    "\"Where ___?\"",
    "Word after who or how",
    "If-then-___ (computer coding statement)",
    "Alternatively",
    "\"What ___?\"",
    "Alternatively",
    "Besides",
    "\"What ___ is there?\"",
    "Part of a programmer's conditional",
    "Otherwise",
    "\"... or ___!\"",
    "Word after who, what, when, where, why or how",
    "Something ___",
    "Besides that",
    "\"That's something ___!\"",
    "Otherwise",
    "\"Anything ___?\"",
    "Word after who, what or anything",
    "Otherwise",
    "Word after who, what, where, when, why or how",
    "Counterpart to \"if,\" in computer science",
    "In addition",
    "\"What ___ is new?\"",
    "Otherwise",
    "Different",
    "Otherwise",
    "Alternatively",
    "Otherwise",
    "\"Who ___?\"",
    "Programming keyword",
    "\"Who ___?\"",
    "Word after who, what or where",
    "\"What ___?\"",
    "Instead",
    "Follower of anything and everything",
    "Alternatively",
    "In addition",
    "Other than that",
    "\"What ___ is new?\"",
    "Choice word",
    "\"Who ___?\"",
    "\"Who ___?\"",
    "\"You and who ___?\" (fighting words)",
    "Alternatively",
    "Other",
    "Besides",
    "\"Nothing ___ will do\"",
    "\"When all ___ fails, read the instructions\"",
    "In addition",
    "\"What ___?\"",
    "Alternatively",
    "\"Anything ___?\"",
    "\"So what ___ is new?\"",
    "\"If all ___ fails ...\"",
    "What may follow anything?",
    "Computer coding keyword",
    "\"What ___ is new?\"",
    "Further",
    "Alternatively",
    "Alternatively",
    "\"What ___?\"",
    "\"... or ___!\" (threat)",
    "Contrarily",
    "As well",
    "\"Somethin' ___\" (Eddie Cochran song)",
    "\"So what ___ is new?\"",
    "Computer programming command",
    "Ultimate word of an ultimatum",
    "Alternatively",
    "In other ways",
    "Otherwise",
    "Threatening word",
    "Different",
    "Threat ender",
    "Word after who, what or where, but rarely when",
    "\"So what ___ is new?\"",
    "Alternative indicator",
    "\"... or ___!\"",
    "Different",
    "\"Anything ___?\" (waiter's query)",
    "\"What ___ is new?\"",
    "Alternatively",
    "If not",
    "\"If all ___ fails ...\"",
    "Or follower",
    "\"What ___?\"",
    "Choice word",
    "\"Anything ___?\"",
    "Alternatively",
    "\"What ___?\"",
    "\"Anything ___?\"",
    "Choice word",
    "Threat-ending word",
    "\"Or ___ what?\"",
    "\"Or ___!\" (threat)",
    "Additionally",
    "Further",
    "In addition",
    "Ultimate word in an ultimatum",
    "Or ___",
    "Besides",
    "\"What ___?\"",
    "Something ___ (a lulu)",
    "\"Who ___?\"",
    "Ultimatum ender",
    "Instead",
    "More",
    "\"If all ___ fails ...\"",
    "Otherwise",
    "Additional",
    "It can follow anything",
    "Aside from that",
    "\"You're something ___!\"",
    "Further",
    "If not",
    "\"Anything ___?\"",
    "End of a warning",
    "\"That's something ___\"",
    "\"Anything ___?\"",
    "\"What ___?\"",
    "\"If all ___ fails ...\"",
    "\"You're something ___!\"",
    "Cannonball Adderley's \"Somethin' ___\"",
    "Otherwise",
    "\"Or ___!\"",
    "End of an ultimatum",
    "\"Anything ___?\"",
    "Otherwise",
    "Ultimatum's end",
    "\"Anything ___?\"",
    "Programming command",
    "It often follows something",
    "Other",
    "Ultimatum's ultimate word",
    "Last word in an ultimatum",
    "\"Anything ___?\"",
    "\"If all ___ fails ...\"",
    "Something ___ (a wow)",
    "Choice word",
    "More",
    "Alternatively",
    "\"What ___?\"",
    "\"Anything ___?\"",
    "\"Anything ___\" (Woody Allen film)",
    "If not",
    "Further",
    "\"Anything ___?\"",
    "\"What ___?\" (clerk's question)",
    "Besides",
    "Choice word",
    "\"You're something ___!\"",
    "Besides that",
    "\"Anyone ___?\"",
    "\"... or ___!\" (threat)",
    "Alternatively",
    "Additionally",
    "\"Anything ___?\"",
    "Otherwise",
    "Besides that",
    "Otherwise",
    "Alternatively",
    "\"Or ___!\"",
    "Additionally",
    "Different",
    "\"Who ___?\"",
    "\"Do it or ___!\"",
    "Otherwise",
    "\"What ___?\"",
    "\"What ___ can I say?\"",
    "\"?à or ___!\" (threat)",
    "Ultimatum ender",
    "Otherwise",
    "Otherwise",
    "On the other hand",
    "Other than",
    "Moreover",
    "Otherwise",
    "Additional",
    "\"What ___?\"",
    "Other",
    "Additionally",
    "Besides",
    "Additionally",
    "More",
    "\"What ___?\"",
    "Choice word",
    "Alternatively",
    "\"Anything ___\" (Woody Allen film)",
    "Ultimatum ender",
    "\"Do it, or ___!\"",
    "In addition",
    "\"What ___?\"",
    "\"What ___?\"",
    "Other than this",
    "\"If all ___ fails ...\"",
    "\"So what ___ is new?\"",
    "If not",
    "Different",
    "In addition",
    "Choice word",
    "If not",
    "Otherwise",
    "If not",
    "Further",
    "Something ___",
    "Otherwise",
    "Other",
    "\"What ___?\"",
    "Otherwise",
    "Besides",
    "Besides that",
    "Different",
    "\"Anything ___?\"",
    "Choice word",
    "\"If all ___ fails ...\"",
    "In addition",
    "Additionally",
    "Additionally",
    "\"What ___ is new?\"",
    "\"So what ___ is new?\"",
    "Ultimatum word",
    "Besides",
    "Last word in an ultimatum",
    "Different",
    "\"What ___ could I do?\"",
    "Further",
    "It may follow something",
    "\"If all ___ fails ...\"",
    "\"What ___?\"",
    "\"Anything ___?\"",
    "\"... or ___!\"",
    "Other",
    "Different",
    "Apart from this",
    "In any other way",
    "\"... or ___!\"",
    "\"What ___ is new?\"",
    "More",
    "If not",
    "\"What ___?\"",
    "Differently",
    "Besides that",
    "Otherwise",
    "If not",
    "\"So what ___ is new?\"",
    "In addition",
    "Otherwise",
    "It ends a threat",
    "Further",
    "Besides",
    "G. & S.'s Lord High Everything ___",
    "Otherwise",
    "\"What ___?\"",
    "Apart from this",
    "More",
    "\"So what ___ is new?\"",
    "Last word in a threat",
    "\"If all ___ fails...\"",
    "Choice word",
    "Alternative word",
    "\"Or ___!\"",
    "Additionally",
    "Otherwise",
    "Choice word",
    "Different",
    "Otherwise",
    "Choice word",
    "Threatening word",
    "Word after who or what",
    "Unnamed alternative",
    "Other",
    "In addition",
    "Choice word",
    "\"What ___ can I say?\"",
    "Otherwise",
    "Something ___ (a wow)",
    "Ultimatum ender",
    "If not",
    "\"If all ___ fails...\"",
    "More",
    "Otherwise",
    "\"What ___ can I say?\"",
    "If not",
    "Otherwise",
    "Choice word",
    "\"What ___ is new?\"",
    "\"Or ___!\"",
    "Additionally",
    "Otherwise",
    "Otherwise",
    "Otherwise",
    "Otherwise",
    "Word ending a threat",
    "95-Down finisher",
    "Otherwise",
    "Besides that",
    "Or ___ (if not)",
    "Otherwise",
    "Otherwise",
    "Otherwise",
    "Differently",
    "Otherwise",
    "Different",
    "Instead",
    "Differently",
    "Otherwise",
    "In addition",
    "Word in ultimatum",
    "Otherwise",
    "Otherwise",
    "\"What ___ is new?\"",
    "In addition to that",
    "Choice word",
    "Otherwise",
    "Other",
    "Differently",
    "Otherwise",
    "Otherwise",
    "Otherwise",
    "Otherwise",
    "Otherwise",
    "Ultimatum word",
    "Other",
    "\"What ___ is new?\"",
    "Choice word",
    "Besides",
    "Option word",
    "Otherwise",
    "Otherwise",
    "\"Or___!\" (veiled threat)",
    "Choice word",
    "In addition",
    "Otherwise",
    "Additionally",
    "Other",
    "Alternatively",
    "Otherwise",
    "Alternative word"
  ],
  "glug": [
    "Sound of drinking",
    "When repeated, water cooler sound",
    "Water cooler noise",
    "Drain sound"
  ],
  "ibeg": [
    "\"___ to differ!\"",
    "\"___ to differ!\"",
    "\"___ to differ\"",
    "\"___ of you ...\"",
    "\"___ to disagree\"",
    "\"___ to differ\"",
    "\"___ of you ...\"",
    "\"___ of you ...\"",
    "\"___ to differ\"",
    "\"___ your pardon!\"",
    "\"___ to differ\"",
    "\"___ to differ\"",
    "\"___ of you ...\"",
    "\"___ to differ!\"",
    "\"___ your pardon!\"",
    "\"___ your pardon?\"",
    "\"___ to differ\"",
    "\"___ to differ!\"",
    "\"___ your pardon!\"",
    "\"___ of you ...\""
  ],
  "vips": [
    "Bigwigs",
    "A-list group at an event",
    "Some line cutters, in brief",
    "Bigwigs",
    "Big names",
    "Limo-riding sorts",
    "Bigwigs",
    "Stars",
    "Big shots, for short",
    "Somebodies",
    "C.E.O.'s, e.g.",
    "Bigwigs",
    "Honchos",
    "High muck-a-mucks",
    "Big guns, for short",
    "C.E.O.'s and B.M.O.C.'S",
    "Big wheels"
  ],
  "easy": [
    "Rated 1 out of 4 stars, as a sudoku",
    "\"Piece of cake!\"",
    "\"Whoa boy, settle down\"",
    "Not a problem",
    "A snap",
    "Like a Monday crossword",
    "\"No sweat!\"",
    "Like pie, it's said",
    "Like child's play",
    "\"No sweat!\"",
    "\"Careful now\"",
    "\"Cool your jets!\"",
    "\"No problemo!\"",
    "\"No problemo\"",
    "Like falling off a log",
    "\"It's a snap!\"",
    "\"No sweat\"",
    "Like a Monday crossword, typically",
    "\"No problem at all!\"",
    "A snap",
    "\"Piece of cake\"",
    "Like Monday crosswords, relatively speaking",
    "\"Don't panic\"",
    "\"Calm down, ace\"",
    "\"Not so fast!\"",
    "\"I'm ___\" (\"Whatever you suggest\")",
    "\"Whoa, slow down there, partner!\"",
    "\"Don't get all worked up!\"",
    "\"Let's not get too excited now\"",
    "Like a Monday crossword puzzle, relatively speaking",
    "\"Chill ...\"",
    "Eggs over ___",
    "Undemanding",
    "\"Relax\"",
    "Like one-star puzzles",
    "\"Piece of cake\"",
    "\"Hey, relax!\"",
    "Simple",
    "\"Piece of cake!\"",
    "___ as pie",
    "\"Hold on there, cowboy!\"",
    "\"Settle down now\"",
    "\"___ now\"",
    "\"Whoa now!\"",
    "Hardly brow-furrowing",
    "\"Calm down now ...\"",
    "\"Duck soup\"",
    "\"Calm down now\"",
    "\"___ does it!\"",
    "\"Don't get all worked up!\"",
    "\"No sweat\"",
    "Like a walk in the park",
    "\"No sweat!\"",
    "\"Calm down!\"",
    "\"Don't get so excited!\"",
    "Unhurried",
    "\"Whoa! Calm down!\"",
    "\"___ does it\"",
    "\"Let's not do anything crazy\"",
    "\"Don't strain\"",
    "\"No prob\"",
    "\"Calm down, big fella\"",
    "See 1-Across",
    "Gradual",
    "Like this puzzle ... not!",
    "\"Careful, now!\"",
    "\"Duck soup!\"",
    "\"Calm down\"",
    "Word with chair or street",
    "\"Careful now\"",
    "\"Child's play!\"",
    "Simple",
    "\"Piece of cake!\"",
    "Skill level option",
    "\"Don't get too excited now!\"",
    "\"No problemo\"",
    "With 84-Across, very simple",
    "Word before come and go",
    "No trouble",
    "\"No problem\"",
    "\"Piece of cake!\"",
    "\"Duck soup!\"",
    "\"Calm down ...\"",
    "\"'Tain't nothin'!\"",
    "\"No sweat\"",
    "Trouble-free",
    "Unhurried",
    "\"Like taking candy from a baby!\"",
    "Effortless",
    "Like pie?",
    "Like falling off a log",
    "\"Whoa, boy!\"",
    "?ô___ does it?ö",
    "\"Piece of cake!\"",
    "\"Duck soup!\"",
    "\"No sweat\"",
    "Unforced",
    "Readily persuadable",
    "It may come before going",
    "Carefree",
    "\"No sweat\"",
    "Unhurried",
    "\"Calm down...\"",
    "\"Duck soup\"",
    "\"No problem!\"",
    "\"Don't rush into anything!\"",
    "Cushy",
    "\"No problem\"",
    "Effortless",
    "\"No sweat!\"",
    "\"Don't be startled\"",
    "Uncomplicated",
    "Accommodating",
    "\"Calm down\"",
    "Effortless",
    "This does it!",
    "\"Slow down\"",
    "\"Can do!\"",
    "\"Careful there!\"",
    "No sweat",
    "Unforced",
    "\"No problemo!\"",
    "\"___ as 1, 2, 3\"",
    "Like falling off a log",
    "Good-tempered",
    "Smooth",
    "Indulgent",
    "Painless",
    "\"Don't overdo it\"",
    "Word before come and go"
  ],
  "nato": [
    "Grp. with peacekeeping operations",
    "___ alphabet",
    "Post-W.W. II alliance",
    "Kind of alphabet",
    "Alliance formed in April 1949",
    "Alliance HQ'd in Brussels",
    "Grp. founded to resist Communism",
    "Defense alliance since 1949",
    "Post-W.W. II alliance",
    "Kind of alphabet",
    "Grp. whose alphabet starts Alfa, Bravo, Charlie ...",
    "Grp. that Ronald Reagan once supported",
    "With 115-Across, communication system that's a hint to the answer to each starred clue",
    "It. is in it",
    "Grp. founded by 12 countries",
    "Montenegro joined it in 2017",
    "___ alphabet",
    "Its alphabet goes from Alfa to Zulu",
    "Western defense grp.",
    "Turkey club?",
    "Kind of alphabet",
    "Brussels-based defense grp.",
    "Post-W.W. II alliance",
    "Transoceanic alliance since 1949",
    "Alliance that keeps a wary eye on Russia",
    "Defense alliance since 1949, for short",
    "Grp. that has added 12 members since the end of the Cold War",
    "Its flag is dark blue, symbolizing an ocean",
    "What 26 countries in Eur. belong to",
    "Grp. whose members account for more than 50% of the world's defense spending",
    "Western mil. alliance",
    "Grp. with forces in Afghanistan",
    "Grp. with peacekeeping forces",
    "Grp. whose initials in French are the reverse of its English initials",
    "Western mil. alliance",
    "Defender of the West",
    "Grp. joined by Albania and Croatia in 2009",
    "Peacekeeping grp.",
    "Western alliance since 1949",
    "Peacekeeping grp.",
    "Grp. with an alphabet",
    "Defense acronym",
    "The U.K. is in it, but Ire. is not",
    "Port. is part of it",
    "Strike authorizer",
    "Turkey club?",
    "Cold war force",
    "Defense grp. headquartered in Belgium",
    "Intl. group with many generals",
    "Defense grp. since 1949",
    "Intl. group whose initials in English and French are reversed",
    "Western mil. alliance",
    "Alliance since '49",
    "Brussels-based alliance",
    "Western alliance, for short",
    "Spain joined it in 1982",
    "Its HQ is in Brussels",
    "Org. with peace-keeping forces",
    "Warsaw Pact counterforce",
    "Mil. alliance since 1949",
    "Brussels-based org.",
    "Group with a secy. gen.",
    "Grp. conducting Operation Deny Flight",
    "It has ports in Port.",
    "Cold war side",
    "Org. with troops",
    "Cold war winner",
    "Grp. founded in Washington on 4/4/1949",
    "Like some troops",
    "Turkey club?",
    "Iceland is part of it: Abbr.",
    "___ forces",
    "Alliance since '49",
    "Cold war side",
    "Grp. that outlasted the Warsaw Pact",
    "It began in H.S.T.'s administration",
    "One side in the cold war",
    "Defense grp.",
    "Defense grp. since 1949",
    "Cold war winner",
    "Alliance acronym",
    "Grp. with forces",
    "Alliance acronym",
    "Cold war winner",
    "Defense acronym",
    "The U.S. is in it",
    "Defense grp.",
    "Expanding grp.",
    "Growing alliance",
    "Grp. that spans an ocean",
    "Major alliance",
    "Grp. with a secretary general",
    "Force in Eur.",
    "Grp. with its HQ in Brussels",
    "Org. for Lord Robertson",
    "1999 air strike authorizer",
    "Much of Eur. is in it",
    "Defense org. since 1949",
    "Org. with a compass star as its symbol",
    "Britain's Lord Ismay was its first head",
    "Peacekeeping grp.",
    "Partnership for Peace grp.",
    "Kind of commander",
    "Expanding org.",
    "Cold war defense grp.",
    "Cold war winner",
    "Like some peacekeepers",
    "Operation Allied Force grp.",
    "Its HQ is in Brussels",
    "Brussels-based org.",
    "Security grp.",
    "Peacekeeping force in Bosnia",
    "Org. formed to contain Communism",
    "Alliance since 1949",
    "Defense acronym",
    "Cold war force",
    "Peace-keeping grp.",
    "Like some forces in the Balkans",
    "Body that includes SHAPE",
    "Expanding grp.",
    "Partnership for Peace grp.",
    "Eisenhower held a 1950 post in it",
    "Warsaw Pact counterforce",
    "Org. with a secretary general",
    "Org. expanding into Eastern Europe",
    "Org. involved in Bosnia",
    "Kind of forces",
    "Brussels-based org.",
    "Org. whose symbol is a four-pointed compass",
    "Kind of forces",
    "Force in Bosnia",
    "1949 alliance",
    "Partnership for Peace grp.",
    "Brussels-based grp.",
    "Defense grp. since 1949",
    "Alliance since 1949",
    "Its HQ is in Brussels",
    "Pact since 1949",
    "Defense acronym",
    "Its HQ is in Brussels",
    "European defense grp.",
    "Group with HQ in Brussels"
  ],
  "crab": [
    "Constant complainer",
    "Maryland delicacy",
    "One walking sideways",
    "Cantankerous sort",
    "Curmudgeon",
    "___ Nebula, part of the constellation Taurus",
    "Grouch",
    "Fiddler on the reef?",
    "One who complains, complains, complains",
    "Sideways scuttler",
    "Grouch",
    "Grumble",
    "Whine lover?",
    "Zodiac symbol",
    "Constant complainer",
    "Sidling sea creature",
    "California roll ingredient",
    "Zodiac symbol",
    "Zodiac symbol",
    "Kvetch",
    "Zodiac symbol",
    "Sea creature with pincers",
    "Curmudgeon",
    "Grouch",
    "Shore dinner staple",
    "Creature that sidles",
    "Sea creature that moves sideways",
    "Crotchety sort",
    "Zodiac creature",
    "Kvetch",
    "Sourpuss",
    "Kvetcher",
    "Find fault",
    "Maryland seafood specialty",
    "Grouch",
    "Sea creature that sidles",
    "Kvetching sort",
    "Sea creature that moves sideways",
    "Zodiac symbol",
    "Squawker",
    "One who complains, complains, complains",
    "Beach sidler",
    "Ill-tempered one",
    "Grouch",
    "Bellyache",
    "Move sideways",
    "Shore catch",
    "Horseshoe ___",
    "Taurus : Bull :: Cancer : ___",
    "Grouch",
    "Grouch",
    "Grumble",
    "Grouch",
    "Grumbler",
    "Disagreeable sort",
    "Twins follower",
    "Constant complainer",
    "Sourpuss",
    "Grouch",
    "Grouch",
    "Callaloo ingredient",
    "Complain",
    "Sourpuss",
    "Cancer, zodiacally",
    "This may have a hard or soft shell",
    "Grump",
    "Hermit ___",
    "Grouch",
    "Fiddler, for one",
    "Cancer, zodiacally",
    "Grouch",
    "Cancer's symbol",
    "Scrooge's sign?"
  ],
  "hark": [
    "Quaint \"Listen!\"",
    "Start of a carol title",
    "\"Listen!,\" quaintly",
    "Listen to Christmas carolers?",
    "\"___! The Herald Angels Sing\"",
    "\"Listen up!,\" old-style",
    "\"Pay ye attention!\"",
    "*Christmas carol starter",
    "\"Listen!,\" old-style",
    "\"Listen!\"",
    "\"Listen!\"",
    "Quaint cry",
    "\"Listen!\"",
    "Christmas carol opener",
    "\"Listen!\"",
    "\"Listen!\"",
    "Carol start",
    "\"Listen!\"",
    "\"Listen!\"",
    "\"Listen!\"",
    "Return to, with \"back\"",
    "Listen",
    "\"Listen!\"",
    "Refer, with \"back\"",
    "\"Listen up,\" old style"
  ],
  "gila": [
    "___ monster",
    "___ monster",
    "___ monster",
    "Kind of monster",
    "River to the Colorado",
    "River that meets the Colorado at Yuma",
    "Colorado feeder / Sacha Baron Cohen character",
    "___ monster",
    "___ monster (desert denizen)",
    "Colorado tributary",
    "Tributary of the Colorado",
    "Sonoran Desert river",
    "___ monster (desert lizard)",
    "___ monster",
    "Colorado tributary",
    "River to the Colorado",
    "River of Phoenix",
    "Arizona county or river",
    "Colorado feeder",
    "___ monster",
    "Arizona's ___ Mountains",
    "River of Yuma and Phoenix",
    "Arizona county or river",
    "Tributary of the Colorado",
    "Grand Canyon State stream",
    "Coolidge Dam's river",
    "River to the Colorado",
    "Coolidge Dam river",
    "___ monster",
    "Colorado feeder",
    "Arizona river",
    "Arizona river",
    "___ monster",
    "Coolidge Dam's river",
    "Southwestern river",
    "Tributary of the Colorado",
    "Kind of monster",
    "___ monster",
    "River to the Colorado",
    "Colorado feeder",
    "___ monster",
    "___ monster",
    "Arizona river",
    "Colorado tributary",
    "Type of monster",
    "Reptilian \"monster\"",
    "Desert monster"
  ],
  "oeuf": [
    "Egg: Fr.",
    "Egg: Fr.",
    "Ingredient in une quiche",
    "\"Why do the French have only one egg for breakfast? Because one egg is an ___\" (old joke)",
    "Egg: Fr.",
    "One of a dozen in un frigidaire",
    "French ingredient in French toast",
    "A4",
    "Omelette ingredient",
    "Omelette ingredient",
    "Omelette ingredient",
    "Pierre's breakfast choice"
  ],
  "gobi": [
    "Mongolian desert",
    "Mongolian desert",
    "Geographical locale whose name means \"waterless place\"",
    "Where the first velociraptor fossil was discovered (1923)",
    "Home to Bactrian camels",
    "Desert crossed by the ancient Silk Road",
    "Desert along the Silk Road",
    "Desert crossed by the Silk Road",
    "Marco Polo crossed it",
    "Desert that ancient traders crossed",
    "Mongolian desert",
    "Silk Road desert",
    "Desert that occasionally gets snow",
    "Mongolian desert",
    "\"Place without water,\" in Mongolian",
    "Asian desert",
    "Mongolian desert",
    "Dry stretch in Mongolia",
    "Home to Mongolian nomads",
    "Area crossed by Marco Polo",
    "Expanse south of Ulan Bator",
    "Mongolian expanse",
    "Asian desert",
    "Home to some Mongolian nomads",
    "Asian desert",
    "\"Crouching Tiger, Hidden Dragon\" setting",
    "Mongolian desert",
    "Mongolian desert",
    "Arid Asian region",
    "Expanse crossed by Marco Polo",
    "Desert bordered by steppe land",
    "Mongolian desert",
    "Mongolian expanse",
    "Mongolian desert",
    "Asian expanse",
    "Much of Mongolia",
    "Mongolian expanse",
    "Home to some Mongolian nomads",
    "A large part of Mongolia",
    "Asian expanse",
    "Mongolian desert",
    "Much of Mongolia",
    "Mongolian desert",
    "It means \"place without water\" in Mongolian",
    "Mongolian desert",
    "Asian expanse",
    "Desert in 6 Across",
    "Vast desert",
    "Mongolian desert",
    "Dry Mongolian expanse",
    "Desert of dinosaur finds"
  ],
  "parm": [
    "Eggplant ___ (cheesy dish, informally)",
    "Breaded and topped with tomato sauce and mozzarella, for short",
    "Chicken or veal dish, in brief",
    "Cheese offered tableside at Italian restaurants, informally",
    "Chicken ___ (certain entree, informally)",
    "Veal ___ (informal dish)",
    "Chicken or veal dish, in brief",
    "Veal topper, informally",
    "Veal or chicken dish, for short",
    "Chicken ___ (Italian dish, informally)"
  ],
  "orin": [
    "Either separately ___ combination",
    "\"... now ___ the future\"",
    "\"... now ___ the future\"",
    "\"Little Shop of Horrors\" dentist",
    "\"Little Shop of Horrors\" dentist",
    "\"Mourning Becomes Electra\" role"
  ],
  "roam": [
    "Wander",
    "Proceed here and there",
    "Travel all over the place",
    "Range",
    "Travel aimlessly",
    "Go far and wide",
    "Travel about",
    "Wander",
    "Go here and there",
    "Incur cellphone charges, maybe",
    "Go here and there",
    "Go exploring, say",
    "Go outside the calling area, say",
    "Drift",
    "Gad about",
    "Incur cellphone charges, maybe",
    "Go hither and yon",
    "Move aimlessly",
    "Lack direction",
    "Meander",
    "Wander",
    "Travel aimlessly",
    "Tramp",
    "What buffalo do in \"Home on the Range\"",
    "Wander",
    "Stray",
    "Use a cell phone outside one's local calling area",
    "Meander",
    "Lack direction",
    "Use a cell phone outside one's calling area",
    "What nomads do",
    "Circumambulate",
    "Gad about",
    "Wander",
    "Go far and wide",
    "Gad about",
    "Range",
    "Gad about",
    "Stray",
    "Drift",
    "Go from pillar to post",
    "Wayfare",
    "Wander",
    "Range",
    "Be nomadic",
    "Live nomadically",
    "Wander",
    "Gad about",
    "Not stay put",
    "Wander",
    "Wander",
    "Lack direction",
    "Be itinerant",
    "Not stay put",
    "Stray",
    "Meander",
    "Knock around",
    "Traipse",
    "Have itchy feet",
    "Knock about",
    "Wander",
    "Range",
    "Wander",
    "Ramble",
    "Wander",
    "Ramble",
    "Travel far and wide",
    "Gad about",
    "Emulate Odysseus",
    "Globe-trot",
    "Wander",
    "Range",
    "Wander",
    "Drift",
    "Meander",
    "Stray",
    "Meander",
    "\"Where'er I ___...\": Goldsmith, \"The Traveller\"",
    "Follow one's feet",
    "Indulge one's wanderlust"
  ],
  "bolt": [
    "Leave in a hurry",
    "Runner Usain",
    "Flash of lightning",
    "Zeus's weapon",
    "What often follows thunder and lightning",
    "Leave in a hurry",
    "Leave quickly",
    "Fabric purchase",
    "Something sticking out of Frankenstein's neck",
    "Leave in a hurry",
    "Track star with an apt name",
    "Aptly named Olympics star",
    "Leave in a hurry",
    "Leave in a hurry",
    "Split in a hurry",
    "Aptly named sprinter Usain",
    "Unit of cloth or lightning",
    "Leave in a hurry",
    "Take off quickly",
    "Nut's partner",
    "Door fastener",
    "Hightail it out",
    "Make a break for it",
    "Length of fabric",
    "Lightning zigzag",
    "Cloth measure",
    "Flee"
  ],
  "frat": [
    "Sorority's counterpart, informally",
    "Organization with pledges, informally",
    "School house?",
    "Rushing group, informally",
    "___ bro",
    "Kegger locale",
    "Rush home?",
    "___ row (some blocks in a college town)",
    "___ boy",
    "Rush home?",
    "Greek group that's not in Greece",
    "Same-sex union?",
    "Greek house, for short",
    "Brotherhood, for short",
    "___ party",
    "Group that takes pledges, informally",
    "It seeks pledges annually",
    "Band of brothers?",
    "Same-sex household?",
    "Brother's place, informally",
    "Sig Ep, e.g.",
    "House on campus",
    "Campus brotherhood, briefly",
    "Word with house or boy",
    "Rushing home?",
    "Sorority's counterpart, for short",
    "\"Animal House\" house",
    "Beer bash venue",
    "Single-sex house, usually",
    "Animal house, say",
    "Group that rushes",
    "Kegger locale",
    "Frequent party planner, for short",
    "Rush week participant",
    "Rush week venue, for short",
    "Univ. house",
    "School house",
    "Rushing group",
    "Sig Chi, e.g.",
    "Greek group",
    "Sig Chi, e.g.",
    "Kind of party",
    "Pledges' group, for short",
    "Beer bust locale",
    "Greek house",
    "Group known for pranks, in short",
    "Where boys will be boys",
    "Greek house, for short",
    "Keg party locale",
    "Social group",
    "School house",
    "Toga party site",
    "Greek group, for short",
    "Traditional hazing site",
    "\"Animal House\" house",
    "\"Animal House\" grp.",
    "Kind of house",
    "Where boys will be boys",
    "Toga party venue",
    "Where boys will be boys",
    "B.M.O.C.'s house, maybe",
    "College party site",
    "Brotherhood, for short",
    "Sigma Chi, e.g.",
    "Young men's club",
    "Brotherhood"
  ],
  "body": [
    "Word before language or temperature",
    "Corpus",
    "Word with shop or shot",
    "Soul mate?",
    "Hairstyling factor",
    "Evidence in a murder mystery",
    "Partner of soul",
    "Part of a shampoo promise",
    "Soul mate?",
    "See 69-Across",
    "With 20-Across, natural energy source",
    "Vintner's concern",
    "*See 9-Across",
    "Factor in a wine rating",
    "Winetaster's concern",
    "Factor in a wine review",
    "House or senate",
    "Main part",
    "Shampoo promise",
    "Principal part",
    "Kind of language",
    "Good wine quality",
    "Wine or hair quality",
    "Miss Marple discovery"
  ],
  "does": [
    "Performs",
    "They might go for a few bucks",
    "Carries out",
    "These can go for a lot of bucks",
    "Imitates",
    "___ justice",
    "\"Stupid is as stupid ___\" (line from \"Forrest Gump\")",
    "Pulls off",
    "Executes",
    "Bucks' mates",
    "Some deer",
    "Some deer",
    "\"That ___ it!\"",
    "Executes",
    "\"Sure ___!\"",
    "Gets along",
    "Pulls off",
    "Effectuates",
    "Fawning females",
    "Works on",
    "Bucks' mates",
    "\"Mares eat oats and ___ eat oats\"",
    "Performs",
    "They might bring in a few bucks",
    "Bucks' partners",
    "Impersonates",
    "Discharges",
    "Fawns' mothers",
    "Bucks' mates",
    "Travels at a speed of",
    "Executes",
    "John and Jane, in case titles",
    "Female deer",
    "They may go for big bucks",
    "Pulls off",
    "Takes care of",
    "Executes",
    "Hinds",
    "Accomplishes",
    "Mother deer",
    "Executes",
    "Harts' sweethearts",
    "They may be fawning",
    "Executes",
    "Hinds",
    "Renders",
    "Bucks' mates",
    "Buck passers?",
    "Bucks' mates",
    "Bucks' mates",
    "Is suitable (for)",
    "Executes",
    "Manages",
    "Accomplishes",
    "Bucks' mates",
    "Accomplishes",
    "Female deer",
    "Bunnies' mummies",
    "John and Jane"
  ],
  "dern": [
    "\"Wild\" actress Laura",
    "\"Marriage Story\" Oscar winner",
    "Laura with an Oscar and an Emmy",
    "\"Big Little Lies\" co-star of Witherspoon and Kidman",
    "Actress Laura",
    "Laura of \"Marriage Story\"",
    "Laura of \"Big Little Lies\"",
    "Laura of \"Big Little Lies\"",
    "Laura of \"Star Wars: The Last Jedi\"",
    "2013 Best Actor nominee for \"Nebraska\"",
    "Bruce of \"The Hateful Eight\"",
    "Laura of \"Blue Velvet\"",
    "Dad-blamed",
    "Laura of \"The Fault in Our Stars\"",
    "\"Nebraska\" star, 2013",
    "\"Nebraska\" star, 2013",
    "Bruce of \"Nebraska\"",
    "Laura of \"Jurassic Park\"",
    "Laura of \"Rambling Rose\"",
    "2009 Golden Globe winner for \"Recount\"",
    "Laura of \"Jurassic Park\"",
    "Laura of \"Blue Velvet\"",
    "Laura of \"Jurassic Park\"",
    "\"Coming Home\" co-star",
    "\"Jurassic Park\" actress",
    "\"Monster\" actor, 2003",
    "Laura of \"I Am Sam\"",
    "\"Citizen Ruth\" actress, 1996",
    "Laura of \"I Am Sam\"",
    "Neill's \"Jurassic Park\" co-star",
    "Actress Laura",
    "\"Jurassic Park\" actress",
    "\"Jurassic Park\" actress",
    "Bruce or Laura of Hollywood",
    "Actress Laura",
    "Laura of \"Jurassic Park\"",
    "Hollywood's Bruce or Laura",
    "Hollywood's Bruce or Laura",
    "\"Jurassic Park\" actress",
    "Bruce or Laura",
    "Gosh-awful",
    "Actor Bruce",
    "Bruce of \"Family Plot\"",
    "Dad-blamed",
    "\"After Dark, My Sweet\" actor, 1990",
    "\"Coming Home\" co-star",
    "Laura or Bruce of film",
    "Bruce or Laura of Hollywood",
    "\"Jurassic Park\" actress Laura",
    "\"The 'Burbs\" co-star",
    "Bruce of \"That Championship Season\"",
    "Filmdom's Bruce or Laura",
    "\"After Dark My Sweet\" star",
    "Bruce or Laura",
    "Laura of \"Jurassic Park\"",
    "\"Jurassic Park\" actress"
  ],
  "laps": [
    "Units in track or swimming",
    "Race units",
    "See 1-Across",
    "200 in a 500",
    "Passes, in a way",
    "Swimmers' units",
    "200 at a 500",
    "Swimmers' back-and-forths",
    "Formula One units",
    "200 in the Indianapolis 500",
    "Pool activity",
    "Sitting spots on Santas",
    "Pool units",
    "Swimmers do them",
    "Track units",
    "Swimmers do them",
    "Pool regimen",
    "Swimmers' distances",
    "Swimmers may do them",
    "Running in circles?",
    "Swim routine",
    "Trips around the track",
    "Goes one up on",
    "Swimming units",
    "Napkin holders?",
    "Overtakes, in a way",
    "Runners' units",
    "Pool exercise",
    "Swimmer's routine",
    "Indy 500's 200",
    "More than once around the track",
    "Swimmer's practice",
    "Places for Pekes",
    "Napkin holders",
    "Swimmer's regimen",
    "Track passes",
    "Pool activity",
    "Baby holders",
    "Pool exercise",
    "Baby holders",
    "Children's seats"
  ],
  "nero": [
    "Roman emperor who succeeded his adoptive father",
    "Roman emperor who said before dying \"What an artist the world is losing in me!\"",
    "Emperor who ruled for more than 13 years, dying at age 30",
    "___ Wolfe, armchair detective",
    "Emperor just before the Year of the Four Emperors",
    "Infamous emperor",
    "Historic megalomaniac",
    "Detective Wolfe",
    "\"Fiddling\" emperor",
    "\"I, Claudius\" role",
    "Leader who succeeded his adoptive father",
    "Historical role for Peter Lorre in \"The Story of Mankind\"",
    "Emperor who, in actuality, played the lyre, not the violin",
    "Model for a bust at the Musei Capitolini",
    "Builder of the Domus Aurea",
    "Master detective Wolfe",
    "Old Roman autocrat",
    "Ancient Rome's Circus of ___",
    "Emperor who rebuilt Rome after it burned",
    "Last emperor of the Julio-Claudian dynasty",
    "Leader whose death sparked the Year of the Four Emperors",
    "Husband of Octavia",
    "Famed \"fiddler\"",
    "He was emperor at 16, dead at 30",
    "Claudius's successor",
    "Enemy captain in 2009's \"Star Trek\" film",
    "Wolfe of crime fiction",
    "First-century megalomaniac",
    "Ruler said to have fiddled while Rome burned",
    "Roman-Parthian War figure",
    "Fiddling emperor",
    "Character in \"I, Claudius\"",
    "Emperor accused of starting the Great Fire of Rome",
    "\"I, Claudius\" figure",
    "Enemy of the early Christians",
    "Ruler preceding the Year of the Four Emperors",
    "During whose reign Peter was crucified",
    "Roman emperor known for his vanity",
    "Mad stepson in \"I, Claudius\"",
    "Son of Agrippina",
    "Emperor who committed matricide",
    "Husband of ancient Rome's Poppaea Sabina",
    "Wolfe of mystery",
    "\"Quo Vadis\" character",
    "\"Fiddling\" Roman emperor",
    "Nephew of Caligula",
    "Wager of war against Parthia",
    "Julio-Claudian dynasty ruler",
    "Peter on a piano",
    "Emperor at the Circus Maximus",
    "\"I, Claudius\" role",
    "Great-grandson of Mark Antony",
    "Villain in the 2009 \"Star Trek\" film",
    "Face on a coin of A.D. 64",
    "Emperor after Claudius",
    "Pianist Peter",
    "Emperor who built the Domus Aurea",
    "Tutee of Seneca",
    "2009 \"Star Trek\" villain",
    "Emperor said to have fiddled while Rome burned",
    "Last Julio-Claudian emperor",
    "\"Quo Vadis\" role",
    "Nephew of Caligula",
    "1951 historical role for Peter Ustinov",
    "Emperor said to have fiddled while Rome burned",
    "Sleuth Wolfe",
    "Crime solver Wolfe of fiction",
    "Colossal statue outside ancient Rome's Colosseum",
    "Pianist Peter",
    "Tyrannical Roman emperor",
    "Famous fiddler",
    "Last Julio-Claudian emperor",
    "Rotund Wolfe",
    "Character in \"I, Claudius\"",
    "Emperor who \"fiddled\"",
    "Roman tyrant",
    "Literary Wolfe",
    "Historical subject of a Boito opera",
    "Franco of \"Camelot\"",
    "Emperor who married his stepsister",
    "Lancelot portrayer, 1967",
    "First-century coup victim",
    "He was born Lucius Domitius Ahenobarbus",
    "Detective ___ Wolfe",
    "Emperor who married his stepsister",
    "Member of the Julio-Claudian dynasty",
    "Wolfe of whodunits",
    "Villain in 2009's \"Star Trek\"",
    "\"I, Claudius\" figure",
    "Peter at the ivories",
    "Emperor who fiddled around?",
    "\"Quo Vadis\" role",
    "Role played by 52-Across in \"The Story of Mankind\"",
    "Adopted son of Claudius",
    "\"Quo Vadis\" role",
    "\"I, Claudius\" role",
    "\"Quo Vadis\" role",
    "Stout's Wolfe",
    "Charles Laughton's role in \"The Sign of the Cross\"",
    "Fictional detective Wolfe",
    "\"Camelot\" actor Franco",
    "Last ruler of the Julio-Claudian dynasty",
    "\"Quo Vadis\" role",
    "Emperor who reputedly fiddled while Rome burned",
    "Subject of a Boito opera",
    "Successor to Claudius I",
    "\"Quo Vadis\" role",
    "Great-grandson of Marc Antony",
    "Coup victim of A.D. 68",
    "Detective Wolfe of fiction",
    "Agrippina's slayer",
    "Franco of film",
    "Portrait on a coin of A.D. 64",
    "Role in \"The Sign of the Cross\"",
    "Whodunit hero Wolfe",
    "Adopted son of Claudius",
    "Ancient libertine",
    "Rome's fifth emperor",
    "He was declared a public enemy by the Senate",
    "Hated ruler of old",
    "\"Quo Vadis?\" emperor",
    "Claudius's successor",
    "Emperor who presided over a great fire",
    "Roman imperator",
    "1951 Peter Ustinov role",
    "Octavia's offer?",
    "Subject of a giant statue at Rome's ancient Colosseum",
    "Fictional Wolfe",
    "Claudius's successor",
    "1951 Peter Ustinov historical role",
    "Role in Racine's \"Britannicus\"",
    "Fictional detective Wolfe",
    "\"Quo Vadis\" character",
    "Poisoner of Britannicus",
    "Handel opera",
    "\"I, Claudius\" role",
    "\"I, Claudius\" role",
    "Student of Seneca",
    "Fiddling emperor, they say",
    "\"Quo Vadis\" role",
    "His last words were \"What an artist the world is losing in me!\"",
    "The Senate declared him a public enemy",
    "Claudius I's successor",
    "Emperor who poisoned Britannicus",
    "Detective Wolfe",
    "He ordered Seneca's death",
    "Galba's predecessor",
    "Imperious Roman",
    "Infamous dictator",
    "Peter of the Philadelphia Pops",
    "Roman \"fiddler\"",
    "Galba's predecessor",
    "Role in \"The Coronation of Poppea\"",
    "Husband of Poppaea Sabina",
    "His dying words were \"What an artist the world is losing in me!\"",
    "Roman \"fiddler\"",
    "Rex introduced him",
    "Poppaea's husband",
    "Husband of Poppaea",
    "Peter Lorre's role in \"The Story of Mankind\"",
    "Agrippina's slayer",
    "Agrippina's son",
    "Fiddling emperor",
    "Detective Wolfe",
    "In legend, he fiddled in a fire",
    "Rex's stout detective",
    "Stout fellow?",
    "Rex's sleuth",
    "Pianist Peter",
    "Great-great-grandson of Augustus",
    "Emperor in \"Quo Vadis?\"",
    "Pianist Peter",
    "Fiddler while Rome burned",
    "Detective Wolfe",
    "Infamous Roman",
    "Prowling Wolfe",
    "Concern for Claudius",
    "Student of Seneca",
    "Emperor with a burning ambition?",
    "Peter or the Wolfe?",
    "\"Camelot\" actor Franco",
    "Octavia's husband",
    "Rebuilder of Rome",
    "Boito opera",
    "Concern for Claudius",
    "Leader of A.D. 54",
    "\"Camelot\" Lancelot Franco___",
    "\"Quo Vadis?\" character",
    "Sleuth Wolfe",
    "Ustinov in \"Quo Vadis?\"",
    "Claudius's stepson",
    "Handel opera",
    "Stepson of Emperor Claudius",
    "Handel opera",
    "Franco of ?ôCamelot?ö",
    "Claudius I's successor",
    "Nephew of Caligula",
    "\"Quo Vadis?\" figure",
    "Famed fiddler",
    "Stout's sleuth Wolfe",
    "Poppaea's third husband",
    "Roman \"fiddler\"",
    "Claudius' successor",
    "Imperious emperor",
    "Roman \"fiddler\"",
    "\"Camelot\" co-star",
    "Agrippina's. son",
    "Pianist Peter",
    "Poppaea's husband",
    "Nephew of Caligula",
    "Grammy-winning pianist",
    "Fiery fiddler",
    "Claudius's adopted son"
  ],
  "naan": [
    "Tandoor bread",
    "Serving with dal makhani",
    "Vindaloo accompaniment",
    "Leavened flatbread",
    "Tandoori bread",
    "Chapati alternative",
    "Some tandoori fare",
    "Indian flatbread",
    "Bread accompanying saag paneer",
    "Tikka masala go-with",
    "Indian bread",
    "Palindromic food",
    "Bread baked in a tandoor",
    "Indian flatbread",
    "Indian bread",
    "Curry go-with",
    "Palindromic bread",
    "Bread often served with curry",
    "Indian flatbread",
    "Bread served with vindaloo",
    "Tandoor-baked bread",
    "Indian bread",
    "Indian flatbread",
    "Indian bread",
    "Indian bread",
    "Indian flatbread",
    "Delhi bread",
    "Indian flatbread",
    "Indian bread",
    "Tandoori flatbread",
    "Side in an Indian restaurant",
    "Vindaloo accompaniment",
    "Tandoor-baked bread",
    "Chicken tikka go-with",
    "It may be eaten with tikka masala",
    "Bread from a tandoor",
    "Asian flatbread",
    "Bread with chicken tikka masala",
    "Tandoor-baked bread",
    "Tandoori-baked bread",
    "Tandoor-baked bread",
    "Indian bread",
    "Indian bread",
    "Indian bread"
  ],
  "loon": [
    "Bird on the Canadian dollar",
    "Bird with a mournful cry",
    "Minnesota's state bird",
    "Minnesota's \"crazy\" state bird",
    "Avian diver",
    "State bird of Minnesota",
    "Symbol of craziness",
    "Bird with blood-red eyes",
    "Bird on Walden Pond in \"Walden\"",
    "Symbolic bird in \"On Golden Pond\"",
    "Bird called a \"diver\" in the U.K.",
    "Minnesota's state bird",
    "Wacko",
    "Bird on Canada's $1 coin",
    "Avian symbol of Ontario",
    "Bird with a laughlike call",
    "Nut job",
    "Bird on a Canadian dollar",
    "Wacko",
    "Crazy sort",
    "\"Crazy\" bird",
    "Screwball",
    "*Kook",
    "Fruitcake",
    "Nut case",
    "\"Laughing\" bird",
    "Addlebrain",
    "Wacko",
    "Crazy-sounding bird",
    "Crazy",
    "Canadian dollar bird",
    "Crackpot",
    "Wacko",
    "Canadian coin image",
    "Diving bird",
    "Crazy as a ___",
    "Nut",
    "Nut",
    "Web-footed bird",
    "Large diving bird",
    "Sharp-billed diver",
    "Bird with a dagger-shaped bill",
    "Crackpot",
    "\"Crazy\" bird",
    "Nut job",
    "Diving bird with a weird call",
    "Minnesota's state bird",
    "Nut",
    "Wacko",
    "Nutcase",
    "Wacko",
    "Diving bird",
    "Nut",
    "Nutcase",
    "Crazy as a ___",
    "Screwball",
    "Nutcase",
    "Symbol of craziness",
    "Diving bird",
    "Screwball",
    "\"Crazy\" bird",
    "Diving bird",
    "Bird on a Canadian dollar",
    "\"Crazy\" bird",
    "Nutcase",
    "Diving bird",
    "Crackpot",
    "Crazy bird?"
  ],
  "alum": [
    "Grad",
    "Grad",
    "Homecoming returnee, for short",
    "Grad",
    "Person in a \"Class of ...\"",
    "'18 honoree, today",
    "Grad",
    "Many a university donor, informally",
    "Homecoming returnee, informally",
    "Bill Clinton vis-a-vis Georgetown and 54-Down",
    "Grad",
    "Diploma displayer, for short",
    "One with '18 after one's name, say",
    "Legacy student's relative, for short",
    "Application to a cut",
    "Occasional \"S.N.L.\" host, to \"S.N.L.\"",
    "Many a college interviewer, in brief",
    "Many a college applicant's interviewer, for short",
    "Name on many a college hall, informally",
    "Reunion attendee, briefly",
    "See 50-Across",
    "Stereotypical pennant waver, colloquially",
    "Many a school benefactor",
    "Obama vis-a-vis Columbia",
    "One in the class of '12 or '13, now",
    "'12 or '13, now",
    "Eisenhower vis-a-vis West Point, informally",
    "Diploma holder, for short",
    "Many a donor, in brief",
    "Many a college interviewer",
    "Grad",
    "Class Notes subject, informally",
    "Grad",
    "'10 or '11 person, now",
    "Grad",
    "'10 grad now, e.g.",
    "Reuniongoer",
    "Grad",
    "Grad",
    "'08 classmate, now",
    "Graduate from Calumet",
    "Chemical used to cure animal skins",
    "Homecoming attendee, in brief",
    "Reunion attendee, for short",
    "Many a team booster",
    "Homecoming returnee",
    "Fund-raising target, briefly",
    "Homecoming attendee, for short",
    "Homecoming figure, for short",
    "Styptic agent",
    "'06 class member, e.g.",
    "Homecoming attender",
    "Homecoming attendee, for short",
    "Jefferson, to William and Mary",
    "Grad",
    "Homecoming guest",
    "Many a homecoming attendee",
    "Grad",
    "Person in the '00 class, e.g.",
    "Class of '98 member, e.g.",
    "Grad",
    "Reunion attendee",
    "Class reunion attender",
    "School grad",
    "Reunion attendee",
    "Many a homecoming attendee",
    "Grad",
    "Reunion attendee",
    "One with an old school tie",
    "Many a scholarship provider",
    "Class of '77 member, now",
    "Homecoming attendee",
    "Wound application",
    "Dubya, to Yale",
    "Grad",
    "Homecoming guest",
    "Grad",
    "Styptic stuff",
    "Homecoming figure",
    "Astringent substance",
    "Astringent",
    "College endower, often",
    "Astringent",
    "Sheepskin holder",
    "Styptic pencil stuff",
    "Reuniongoer",
    "Alma mater visitor",
    "Reuniongoer",
    "Homecoming visitor",
    "Grad",
    "Reuniongoer, for short",
    "Graduate, for short",
    "Reunion attender",
    "Grad",
    "Grad",
    "Potassium ___ (astringent)",
    "Styptic substance",
    "Former student",
    "Reuniongoer, informally",
    "One who reunes"
  ],
  "awes": [
    "Elicits a \"Whoa\" from, say",
    "Blows away",
    "Fills with wonderment",
    "Flabbergasts",
    "Blows away",
    "Commands respect from",
    "Knocks the socks off",
    "Bowls over",
    "Floors",
    "Astonishes",
    "Knocks over",
    "Knocks over",
    "Amazes",
    "Knocks the socks off",
    "Wows",
    "Throws for a loop",
    "Bowls over",
    "Fills with wonder",
    "Leaves slack-jawed",
    "Leaves dumbstruck",
    "Wows",
    "Bowls over",
    "Throws for a loop",
    "Doesn't just grab",
    "Really impresses",
    "Bowls over",
    "Stupefies",
    "Fills with wonder",
    "Fills with wonder",
    "Inspires reverence in",
    "Floors",
    "Wows",
    "Bowls over",
    "Knocks for a loop",
    "Wows",
    "Bedazzles",
    "Daunts",
    "Moves",
    "Blows away",
    "Floors",
    "Impresses",
    "Amazes",
    "Causes gasps",
    "Wows",
    "Impresses greatly",
    "Bowls over",
    "Dazzles",
    "Electrifies",
    "Commands respect",
    "Blows away, so to speak",
    "Bowls over",
    "Blows away",
    "Cows, maybe",
    "Takes one's breath away",
    "Makes one's jaw drop",
    "Wows",
    "Amazes",
    "Dazzles",
    "Inspires wonder",
    "Knocks the socks off",
    "Wows",
    "Flabbergasts"
  ],
  "cena": [
    "Wrestling star John",
    "W.W.E. legend John",
    "Pro wrestling star John",
    "W.W.E. star John",
    "John of pro wrestling"
  ],
  "opah": [
    "Moonfish",
    "Moonfish",
    "Another name for the moonfish",
    "Hawaiian food fish",
    "Brightly colored fish",
    "Colorful fish",
    "Colorful, warm-blooded fish",
    "Fish whose name is a celebrity's name minus an R",
    "Hawaiian seafood",
    "Hawaiian menu fish",
    "Great white shark prey",
    "Sushi fish",
    "Sashimi fish",
    "Fish also called a Jerusalem haddock",
    "Sunfish or moonfish",
    "Moonfish",
    "Mako shark prey",
    "Brightly colored fish",
    "Fish also called a Jerusalem haddock",
    "Moonfish",
    "Moonfish",
    "Brightly colored fish",
    "Brilliantly colored fish",
    "Brightly colored food fish",
    "Colorful fish",
    "Fish with scarlet fins",
    "Moonfish",
    "Brightly colored fish",
    "Moonfish",
    "Colorful fish",
    "Moonfish",
    "Colorful food fish",
    "Moonfish",
    "Colorful fish",
    "Brightly colored fish",
    "Colorful fish"
  ],
  "napa": [
    "Destination for oenophiles",
    "California vineyard valley",
    "Wine region of California",
    "Major oenotourism destination",
    "Where many stop and smell the roses",
    "Cabernet county",
    "U.S. viticulture region",
    "California tour locale",
    "Auto parts giant",
    "Mecca for oenophiles",
    "California's ___ Valley",
    "California wine valley",
    "___ cabbage",
    "Valley with many cabs?",
    "Beringer Vineyards' county",
    "Auto parts giant",
    "Pep Boys competitor",
    "Big car-care chain",
    "Major region for viticulture",
    "California wine valley",
    "California county east of Sonoma",
    "Chain for a mechanic",
    "Auto parts giant",
    "River whose source is Mount Saint Helena",
    "California county or its seat",
    "Auto parts giant",
    "Chain for car mechanics",
    "Big name in auto parts",
    "Wine tour area",
    "California valley",
    "California wine valley",
    "___ Valley",
    "Mecca for oenophiles",
    "Vintners' valley",
    "California's ___ Valley",
    "Carquest competitor",
    "Sonoma neighbor",
    "California wine valley",
    "Wine region",
    "California wine valley",
    "California valley known for 37-Across",
    "Vintners' valley",
    "California's ___ Valley",
    "Vacation spot for some oenophiles",
    "California wine valley",
    "Bay Area county",
    "Vintner's valley",
    "Vintner's valley",
    "Sonoma neighbor",
    "Auto parts giant",
    "Wine valley",
    "Auto parts giant",
    "Wine valley",
    "Wine valley",
    "Auto parts giant",
    "Sonoma neighbor",
    "California wine valley",
    "Wine area",
    "Store frequented by mechanics",
    "California wine valley",
    "Big name in auto parts",
    "Valley of vintners",
    "Winemaking valley",
    "Wine county",
    "Winemaking county",
    "Vintners' valley",
    "California winegrowing county",
    "California county or its seat",
    "California wine valley",
    "Wine valley in California",
    "California wine valley",
    "Auto parts brand",
    "California city, county or river",
    "Big auto parts company",
    "Vintners' valley",
    "Viticultural valley",
    "Valley of vintages",
    "Big wine valley",
    "City north of San Pablo Bay",
    "City north of Vallejo",
    "Big name in auto supplies",
    "California wine valley",
    "Sonoma neighbor",
    "Wine area",
    "Wine county",
    "Neighbor of Sonoma",
    "Western wine center",
    "California's___Valley",
    "Western wine valley",
    "Wine valley",
    "California wine region",
    "California wine region",
    "California valley"
  ],
  "ilya": [
    "Longtime hockey star Kovalchuk",
    "Soviet author Ehrenburg",
    "1958 Physics co-Nobelist ___ Frank",
    "Kovalchuk of the N.H.L.",
    "Three-time N.H.L. All-Star Kovalchuk",
    "Three-time N.H.L. All-Star Kovalchuk",
    "Three-time N.H.L. All-Star Kovalchuk",
    "The N.H.L.'s Kovalchuk",
    "___ Ulyanov, Vladimir Lenin's father",
    "Soviet author Ehrenburg",
    "Kovalchuk of the N.H.L.",
    "There is, in France",
    "Figure skater Kulik",
    "Russian-born Chemistry Nobelist___Prigogine",
    "Russian writer Ehrenburg"
  ],
  "icky": [
    "Gross",
    "Repulsive",
    "Unpleasant",
    "Eliciting an \"Eww!\"",
    "Ugh-worthy",
    "Highly off-putting",
    "Like goo",
    "Unpleasant",
    "Gross",
    "Gross",
    "Like creepy-crawlies",
    "Like glop",
    "Repulsive",
    "\"Eww! Gross!\"",
    "Gross",
    "Gross",
    "Very unpleasant",
    "Gross, in kidspeak",
    "Totally gross",
    "Like worms",
    "Highly distasteful",
    "Gross",
    "Gross",
    "Gross",
    "Like squashed insects",
    "Highly distasteful",
    "Repugnant, to Junior"
  ],
  "deed": [
    "Exploit",
    "Document that never lacks a title",
    "One of 28 in Monopoly",
    "Card for Boardwalk or Park Place",
    "Document showing ownership",
    "Exploit",
    "Homeowner's document",
    "Homeowner's need",
    "Notarized paper",
    "Archived document",
    "Exploit",
    "Title",
    "Document kept in a safe",
    "Exploit",
    "Proof of ownership",
    "Document recorded at a government office",
    "Achievement",
    "\"___ I Do\" (1926 jazz standard)",
    "Monopoly card",
    "Proof that a property is yours",
    "Monopoly holding",
    "Ownership document",
    "Home paper",
    "Bit of derring-do",
    "Safe-deposit box item, maybe",
    "Something good for a scout, say",
    "Monopoly acquisition",
    "Homeowner's proof",
    "Monopoly card",
    "It's signed, sealed and delivered",
    "Title",
    "Achievement",
    "One may be good or dirty",
    "Owning evidence",
    "Title",
    "Homeowner's paper",
    "Something good for a Boy Scout?",
    "Exploit",
    "Monopoly card",
    "Scout's doing",
    "Land owner's document",
    "Title",
    "It's done",
    "Action",
    "Monopoly card",
    "Thing to do",
    "Lockbox document",
    "Home paper",
    "Monopoly acquisition",
    "Monopoly card",
    "A scout may do a good one",
    "Monopoly card",
    "Monopoly card",
    "Proof of purchase",
    "Strongbox document",
    "It's been done",
    "Exploit",
    "Act",
    "Monopoly card",
    "Property title",
    "Owner's proof",
    "Scout's doing",
    "Monopoly card",
    "Owner's acquisition",
    "Monopoly card",
    "Owner's certificate",
    "Exploit",
    "Safe deposit box item",
    "Monopoly card",
    "Feat",
    "Doing",
    "Owner's acquisition",
    "Home paper",
    "Exploit",
    "Do it!",
    "Monopoly acquisition",
    "Closing document",
    "Lockbox document",
    "Safe deposit box item, perhaps",
    "Exploit",
    "Ownership document",
    "Proof of ownership",
    "Act",
    "Exploit",
    "It's done",
    "Monopoly card",
    "Title",
    "Proof of title",
    "Exploit",
    "Monopoly card",
    "Title",
    "Monopoly piece",
    "Act",
    "Actual performance",
    "Boy Scout's act",
    "Part of an estate"
  ],
  "menu": [
    "Something rectangular that might have more than four sides",
    "What a QR code at a restaurant might link to",
    "Handout from a maître d'",
    "DoorDash list",
    "List of courses",
    "Word with dinner or dollar",
    "List of available courses",
    "Clickable list",
    "One might be dropped in a to-go bag",
    "Word after \"pop-up\" or \"drop-down\"",
    "Restaurant handout",
    "List of choices",
    "Link on a restaurant's Yelp page",
    "Ordering aid",
    "Carte that comes before the course",
    "It might start with \"For Starters\"",
    "List of options",
    "It's fixed for a prix fixe meal",
    "Drop-down item",
    "Writing on many a chalkboard",
    "Course list",
    "Kind of bar",
    "Ordering aid",
    "Choices, choices",
    "Card with the headings \"Appetizers,\" \"Entrees\" and \"Desserts\"",
    "Aid in picking sides",
    "Waiter's handout",
    "It may start with \"Starters\"",
    "Where to look for starters",
    "Remote button",
    "Meal plan",
    "Restaurant handout",
    "Preprandial reading",
    "File ___",
    "Diner's card",
    "Many a flier under a door",
    "Array of options",
    "Many a Yelp link",
    "Restaurant window display",
    "Restaurant posting",
    "See 13-Down",
    "Help list, e.g.",
    "List that goes from Appetizers to Desserts",
    "Course list",
    "It might start with \"Starters\"",
    "A large one offers many courses",
    "List on a laptop",
    "Computer list",
    "It might end with \"Beverages\"",
    "It may be touch-screen",
    "Restaurant handout",
    "It might start with \"Starters\"",
    "Computer offering",
    "Restaurant reading",
    "___ items",
    "It may be written on a blackboard",
    "Restaurant offering",
    "It may start with \"Starters\"",
    "File, Edit or Help",
    "Pull-down list",
    "See 51-Down",
    "Restaurant posting",
    "PC pop-up",
    "List of alternatives",
    "A small one helps the indecisive",
    "It might include hot dogs and baked beans",
    "Plan for dinner",
    "Dish describer",
    "Button on an iPod",
    "Beanery handout",
    "PC pop-up",
    "Chalkboard writing at a cafe",
    "Selection screen",
    "Waiter's handout",
    "List of options",
    "Maitre d's offering",
    "Course offerer",
    "File ___",
    "Waiter's offering",
    "Waiter's offering",
    "Waiter's handout",
    "Course list?",
    "PC listing",
    "Restaurant posting",
    "Food card",
    "Remote control button",
    "Carte du jour",
    "Course listing",
    "Some blackboard writing",
    "Orders come from it",
    "Computer offering",
    "Restaurant lines?",
    "Computer screen offering",
    "Dessert ___",
    "Certain computer screen",
    "Answering machine offering, sometimes",
    "Restaurant posting",
    "Diner handout",
    "Waiter's handout",
    "Host's handout",
    "Restaurant listings",
    "Taster's choices",
    "List of choices",
    "It's taken after an order is given",
    "Restaurant handout",
    "Children's ___",
    "Waiter's card",
    "Waiter's offering",
    "See 98-Down",
    "Restaurant card",
    "Waiter's offering",
    "Restaurant handout",
    "28-Down handout",
    "Children's ___",
    "Computer offering",
    "Presenter of choices",
    "Computer's option list",
    "It may help one choose sides",
    "Choice reading?",
    "Item often left at apartment doors",
    "Dinner table?",
    "List",
    "Computer list",
    "Computer list",
    "Carte",
    "Maitre d's offering",
    "Dessert ___",
    "Diner's card",
    "What's cooking",
    "Bill of fare",
    "Diner's card",
    "Software choices",
    "Restaurant reading",
    "Dishes",
    "Maitre d's offering",
    "Waiter's offering",
    "Carte du jour",
    "Computer list",
    "List of options",
    "Software listing",
    "Computer listing",
    "Waiter's handout",
    "Course offerer",
    "PC display",
    "Diner's card",
    "Diner's card",
    "Computer list",
    "Diner's guide",
    "Waiter's handout",
    "Computer offering",
    "Restaurant request"
  ],
  "spit": [
    "Two-player card game",
    "What the nests in bird's-nest soup are made of",
    "Two-player card game",
    "Rain slightly",
    "Rain unsteadily",
    "Command from a dentist",
    "Small peninsula",
    "Hot rod?",
    "Stick in the fire?",
    "Pig roast need",
    "Barbecue rod",
    "Fast-paced two-player card game",
    "Small cape",
    "Show disrespect, in a way",
    "Roasting rod",
    "Bar at a roast",
    "Card game requiring quick reflexes",
    "Rod at a pig roast",
    "Polish's partner",
    "Balls or fire preceder",
    "Rotisserie rod",
    "Rain slightly",
    "Narrow projection of land into the sea",
    "___ and polish",
    "Meat sticker",
    "Skewer",
    "Skewer",
    "Rotisserie rod",
    "Stick in the fire",
    "Polish's partner",
    "Rod at a roast",
    "Hot rod?",
    "Bar in a bar and grill",
    "BBQ aid",
    "Hot rod?",
    "Rod at a pig roast",
    "Expectorate",
    "Rod at a pig roast",
    "It turns at a pig roast",
    "Rain, but just barely",
    "Fall in scattered drops, as rain",
    "Roasting rod",
    "Fast-moving card game",
    "Kind of curl",
    "Polish partner?",
    "Polish's partner",
    "Barbecue item",
    "Barbecue bar",
    "Roasting rod",
    "Restaurant bar?",
    "Roasting rod",
    "Polish's partner",
    "Kind of curl",
    "It turns on the fire",
    "Polish companion",
    "Tiny isthmus",
    "Barbecue accessory",
    "Polish partner",
    "Brochette",
    "Rain slightly",
    "Polish's partner",
    "Barbecue bar",
    "It turns on the cooker",
    "Roasting device",
    "Kabob thingamabob",
    "BBQ rod",
    "Mini-peninsula"
  ],
  "rain": [
    "Reason to reschedule",
    "It may come in buckets",
    "BBQ spoiler",
    "Weather often associated with Vancouver",
    "Desert rarity",
    "Drops from the sky",
    "Come down, in a way",
    "Nursery rhyme word repeated before \"go away\"",
    "Parade spoiler",
    "Common 68-Across forecast",
    "It often occurs following a car wash, seemingly",
    "\"Purple ___\" (Prince hit)",
    "It can come in sheets",
    "It may come in sheets",
    "Drought ender",
    "Makeup of some sheets",
    "Drops out of the sky",
    "Spring fall",
    "Pour",
    "Parade spoiler",
    "Drought ender",
    "Reason for a game delay",
    "Game stopper",
    "Game stopper",
    "Shower",
    "Drizzle, say",
    "Picnic spoiler",
    "Pour",
    "It may come in buckets",
    "Puddle source",
    "Pavement pounder",
    "Reason for a delay, perhaps",
    "Cause of delay",
    "Picnic problem",
    "It often messes tresses",
    "Precipitation",
    "Cause for a game delay",
    "Parade stopper",
    "Drops from the sky",
    "Word repeated before \"go away\"",
    "Word after \"ppd.\" on a sports page",
    "Mac user's motivation",
    "Reason for a makeup game",
    "Cause of a game cancellation",
    "Drought relief",
    "Common Seattle forecast",
    "Not be fair?",
    "Outdoor event planner's worry",
    "Common cause of postponement",
    "Pour",
    "Drought relief",
    "Game delayer",
    "Eavesdropper?",
    "Picnic hamperer",
    "Game stopper",
    "Pour",
    "Farmer's prayer",
    "Parade spoiler",
    "Flooding cause",
    "Game delayer",
    "Drops down from above",
    "Pour",
    "It may delay things",
    "Pour",
    "Cause for umbrellas",
    "Desert rarity",
    "Parade stopper",
    "Parade stopper",
    "Shower",
    "Pour",
    "Drops from the sky",
    "Acid ___",
    "Ballgame spoiler",
    "Wet forecast",
    "Drops from the sky",
    "Pitter-patter maker",
    "Drops from above",
    "Picnicker's worry",
    "Parade spoiler",
    "Gobi rarity",
    "Reservoir filler",
    "Subject of some prayers",
    "Parade spoiler, perhaps",
    "Reason for postponement",
    "Fall in April?",
    "A pluviometer measures it",
    "Picnic hamperer",
    "Precipitate",
    "Seeders make it",
    "Storm production",
    "Shower",
    "Many a Seattle weather forecast",
    "Sahara rarity",
    "Picnic spoiler",
    "Farmer's prayer, perhaps",
    "Reason for postponement",
    "Pour",
    "Picnic hamperer",
    "See 47-Across",
    "Kind of check",
    "Picnic hamperer",
    "Parade spoiler",
    "Wet forecast",
    "Classic Maugham short story",
    "Precipitate",
    "Drizzle",
    "Sprinkle",
    "Pour",
    "Parade stopper",
    "Sprinkle",
    "Pitter-patter, perhaps",
    "Precipitate",
    "Classic play based on a Maugham tale"
  ],
  "lain": [
    "Remained in bed, e.g.",
    "Reclined",
    "Reclined",
    "Lie in the past?",
    "Reclined",
    "Reclined",
    "Reclined",
    "Reclined",
    "Reclined",
    "Been in bed",
    "Reclined",
    "Done nothing",
    "Gone flat?",
    "Reclined",
    "\"This skull has ___ in the earth ...\": \"Hamlet\"",
    "Reclined",
    "Reclined",
    "Reposed",
    "Been in bed (with)",
    "Stretched out",
    "Reposed",
    "Reposed",
    "Reclined",
    "Gone flat?",
    "Reclined",
    "Been abed",
    "Been in bed",
    "Reclined",
    "Stretched out",
    "Been intimate (with)",
    "Been in bed",
    "Reclined",
    "Rested",
    "Been in bed",
    "Stretched out",
    "Reclined",
    "Remained in bed",
    "Reposed",
    "Reclined",
    "Rested",
    "Reclined",
    "Stretched out",
    "Reclined",
    "Been abed",
    "Reposed",
    "Stretched out",
    "Reclined",
    "Reclined",
    "Reclined",
    "Reclined",
    "Reposed",
    "Reposed",
    "Been in bed"
  ],
  "abee": [
    "\"... sting like ___\"",
    "Have ___ in one's bonnet",
    "Busy as ___",
    "\"... sting like ___\"",
    "Busy as ___",
    "Busy as ___",
    "Have ___ in one's bonnet",
    "Busy as ___",
    "\"Sting like ___\"",
    "Busy as ___",
    "Have ___ in one's bonnet",
    "Answer to the folk riddle \"Worker in yellow clothes\"",
    "Busy as ___",
    "Busy as ___",
    "Busy as ___",
    "Busy as ___",
    "Last words of an Ali boast",
    "\"... sting like ___\"",
    "\"As brisk as ___ in conversation\": Samuel Johnson",
    "\"...sting like ___\"",
    "Have ___ in one's bonnet",
    "Have ___ in one's bonnet",
    "End of a Muhammad Ali catch phrase",
    "Have ___ in one's bonnet",
    "Have ___ in one's bonnet",
    "Busy as ___",
    "Have___ in one's bonnet",
    "Busy as ___"
  ],
  "meup": [
    "\"Build ___ Buttercup\" (1968 hit by the Foundations)",
    "Pick-___ (refreshing drink)",
    "\"Beam ___ ?à\"",
    "\"Beam ___, Scotty!\""
  ],
  "conn": [
    "N.Y. neighbor",
    "Where Hartford is: Abbr.",
    "Its state song is \"Yankee Doodle\": Abbr.",
    "Mass. neighbor",
    "Hartford's state: Abbr.",
    "Mass. neighbor",
    "Didi of \"Grease\" and \"Grease 2\"",
    "Stamford's state: Abbr.",
    "Fifth state to ratify the Constitution: Abbr.",
    "The Nutmeg State: Abbr.",
    "Mass. neighbor",
    "Smythe of hockey",
    "N.Y. neighbor",
    "Fifth state to ratify the Constitution: Abbr.",
    "State where Geo. W. Bush was born",
    "Part of New Eng.",
    "Hockey's ___ Smythe Trophy",
    "It's south of Mass.",
    "George W. Bush's birthplace: Abbr.",
    "Fifth state to join the Union: Abbr.",
    "One of the 13: Abbr.",
    "N.Y. neighbor",
    "N.Y. neighbor",
    "R.I. neighbor",
    "Didi of \"Grease\"",
    "'40s boxer Billy"
  ],
  "fits": [
    "Partner of starts",
    "Conforms",
    "Suits",
    "Is the right size",
    "Partner of starts",
    "___ and starts",
    "Goes on perfectly",
    "Conniptions",
    "Conniptions",
    "Is snug",
    "They may be thrown",
    "Partner of starts",
    "Looks good on",
    "Partner of starts",
    "What a tough puzzle can give you",
    "Looks perfect on",
    "Emotional outbursts",
    "Is appropriate"
  ],
  "ifso": [
    "\"In that case ...\"",
    "In that case",
    "In that case",
    "\"In that case ...\"",
    "Should that be the case",
    "In that case",
    "\"Should that be the case ...\"",
    "\"Given that ...\"",
    "Contingency phrase",
    "Should that be the case",
    "Supposing that",
    "\"In that case ...\"",
    "Contingency phrase",
    "\"In that case ...\"",
    "Then",
    "\"In that case ...\"",
    "\"In that case ...\"",
    "Hypothetical phrase",
    "\"Supposing that's true ...\"",
    "\"In that case ...\"",
    "Assuming that's true",
    "\"In that case ...\"",
    "\"Assuming that's true ...\"",
    "Hypothetical words",
    "\"In that case ...\"",
    "\"Assuming that's true ...\"",
    "In that case",
    "In that case",
    "\"In that case ...\"",
    "\"In that case ...\"",
    "Then preceder",
    "\"In that case ...\"",
    "In that case",
    "Assuming that's true",
    "In that case ...",
    "\"Provided that is the case ?à\"",
    "In case it's true",
    "\"In that case ?à\"",
    "In case that's true",
    "In the event it's true",
    "Assuming that's true",
    "In case it's true",
    "Supposing that",
    "Hypothetical words",
    "Should that be the case",
    "Assuming that",
    "In that case",
    "Assuming that's true",
    "Conditional words"
  ],
  "dano": [
    "Paul of \"Little Miss Sunshine\"",
    "Paul of \"There Will Be Blood\"",
    "Actor Paul of \"There Will Be Blood\"",
    "Paul of \"There Will Be Blood\"",
    "Actor Paul of \"There Will Be Blood\"",
    "Paul of \"There Will Be Blood\"",
    "\"Hawaii Five-O\" nickname",
    "\"Hawaii Five-O\" crime-fighter, informally",
    "Longtime soap actress Linda",
    "Paul of \"There Will Be Blood\"",
    "Linda of soaps",
    "Hawaii Five-O crimefighter, informally",
    "Linda of soaps",
    "Soap actress Linda"
  ],
  "tiki": [
    "Maori for \"image\"",
    "___ bar",
    "___ torch",
    "Kind of torch",
    "First man, in Maori mythology",
    "Suspect #8",
    "___ torch",
    "Polynesian carving",
    "Polynesian carving",
    "___ bar",
    "Kind of torch",
    "___ torch",
    "Word with torch or bar",
    "Kind of torch on \"Survivor\"",
    "Kind of torch on \"Survivor\"",
    "Place to order a mai tai, maybe",
    "Polynesian idol",
    "Kind of torch at a luau",
    "Carved Polynesian talisman",
    "___ torch (outdoor party lighting)",
    "Polynesian carving",
    "Kind of torch",
    "Kind of torch",
    "Cook Island carving",
    "\"Kon-___\"",
    "___ bar",
    "Disneyland's Enchanted ___ Room",
    "Kind of lamp at a luau",
    "Polynesian carving",
    "Kind of torch on \"Survivor\"",
    "Polynesian carving",
    "Polynesian pendant",
    "Kind of torch",
    "Kind of torch on \"Survivor\"",
    "\"Kon-___\"",
    "Polynesian pendant",
    "Polynesian carving",
    "Polynesian amulet figure",
    "See 13-Down",
    "Polynesian carving",
    "Polynesian carving",
    "\"Kon-___\"",
    "Polynesian pendant",
    "Kon-___ (Heyerdahl raft)",
    "Heyerdahl's \"Kon-___\"",
    "\"Kon-___\"",
    "Kon ___"
  ],
  "ripa": [
    "Kelly of \"Live\"",
    "Seacrest's partner on morning TV",
    "\"Live\" co-host beginning in 2001",
    "Kelly on the Hollywood Walk of Fame",
    "Gifford's talk show successor",
    "\"Live With Kelly and Ryan\" co-host",
    "Kelly seen live in the morning",
    "Morning TV co-host",
    "\"Live With Kelly and Ryan\" co-host",
    "Gifford's successor on TV",
    "Kelly of morning TV",
    "Last name in morning TV",
    "Kelly of morning TV",
    "TV's Kelly",
    "Kelly of \"Live! With Kelly and Michael\"",
    "\"Hope & Faith\" actress Kelly",
    "Kelly of \"Live With Regis and Kelly\"",
    "Kelly of morning TV",
    "TV host Kelly",
    "Gifford's talk-show replacement",
    "Philbin's \"Live\" co-host",
    "Gifford's replacement as Philbin's co-host",
    "Morning show co-host",
    "TV host Kelly",
    "Kelly of morning TV",
    "\"Live!\" co-host",
    "Kelly of \"Live With Regis and Kelly\""
  ],
  "rdas": [
    "Nutritional figs.",
    "Nutrition figs.",
    "Food figs.",
    "Nutrition amts.",
    "Nutritional figs.",
    "Nutritional figs.",
    "Vitamin amts.",
    "Nutritional figs.",
    "Food label figs.",
    "Stds. for A and E, e.g.",
    "They're good stds. to follow",
    "Nutritionists' nos.",
    "Vitamin label amts.",
    "Nutritional stds.",
    "Vitamin bottle info, for short",
    "Nutritional amts.",
    "Vitamin amts.",
    "Stds. important to the health-conscious",
    "Cereal box nos.",
    "Food figs.",
    "Intake optima: Abbr.",
    "Intake optima: Abbr.",
    "Vitamin amts.",
    "Vitamin bottle figs.",
    "Good amts. to take in",
    "Vitamin bottle info, for short",
    "Info on grocery pkgs.",
    "Vitamin amts.",
    "Suggestions on food labels: Abbr.",
    "Food product figs.",
    "Vitamin amts.",
    "Suggestions on food labels: Abbr.",
    "Nutritional info",
    "Figures to be maintained?",
    "Vitamin bottle info",
    "Dietary figures, for short",
    "Vitamin bottle info",
    "Vitamin bottle info",
    "Nutritionist's amts.",
    "F.D.A. figures",
    "Vitamin amts."
  ],
  "ital": [
    "Leaning right: Abbr.",
    "Leaning to the right: Abbr.",
    "Stressed, in a way: Abbr.",
    "Bold alternative: Abbr.",
    "Proofreader's abbr.",
    "Proofreader's notation",
    "Slanted type: Abbr.",
    "It leans right: Abbr.",
    "Slanted in print: Abbr.",
    "Typographer's abbr.",
    "It adds stress: Abbr.",
    "Emphatic type: Abbr.",
    "A title might be presented in it: Abbr.",
    "Stressed type: Abbr.",
    "Alternative to quotes: Abbr.",
    "Not this type?: Abbr.",
    "Lang. in which \"friends\" is 24-Across",
    "Right-leaning: Abbr.",
    "Biased writing?: Abbr.",
    "Font option: Abbr.",
    "Slanty type: Abbr.",
    "Emphatic type: Abbr.",
    "Right-leaning type: Abbr.",
    "Rom. tongue",
    "Type used for emphasis: Abbr.",
    "Proofreading abbr.",
    "Slanted type: Abbr.",
    "Font option: Abbr.",
    "Leaning, in a way: Abbr.",
    "Emphatic type: Abbr.",
    "Romance lang.",
    "Venetian's lang.",
    "Typesetter's choice: Abbr.",
    "Slanted type: Abbr.",
    "Titles are often put in it: Abbr.",
    "Printer's type: Abbr.",
    "Type that leans: Abbr.",
    "Type type: Abbr.",
    "Slanty type: Abbr.",
    "Galley marking",
    "Tilting type: Abbr.",
    "Slanted type: Abbr.",
    "Type choice: Abbr.",
    "They lean to the right: Abbr.",
    "Leaning, as type: Abbr.",
    "Emphasized: Abbr.",
    "Slanted: Abbr.",
    "Slanted, as type: Abbr.",
    "Proofreader's notation",
    "Emphatic type: Abbr.",
    "Type type: Abbr.",
    "Type spec: Abbr.",
    "Big World Cup power: Abbr.",
    "Romance lang.",
    "Slanted type: Abbr.",
    "Emphatic type: Abbr.",
    "Emphatic type: Abbr.",
    "Romance lang.",
    "Not roman: Abbr.",
    "Med. land",
    "Leaning letters: Abbr.",
    "Slanted type: Abbr.",
    "Stressed type: Abbr.",
    "Slanted type: Abbr.",
    "Like some type: Abbr.",
    "It doesn't stand up straight: Abbr.",
    "Slanted type: Abbr.",
    "Like slanted type: Abbr.",
    "Emphatic type: Abbr.",
    "Type type: Abbr.",
    "Romance lang.",
    "Slanty type: Abbr.",
    "Biased type, briefly",
    "Type spec.",
    "Slanted: Abbr.",
    "Type of type: Abbr.",
    "From Umbria: Abbr.",
    "Underlining equiv.",
    "Romance lang.",
    "Like some type: Abbr.",
    "Type style: Abbr.",
    "Slanted type: Abbr.",
    "Type type: Abbr.",
    "Printing style: Abbr.",
    "Type style: Abbr."
  ],
  "graf": [
    "Four-time Australian Open champ",
    "World No. 1 tennis player between Navratilova and Seles",
    "Steffi of tennis fame",
    "Steffi on the court",
    "Tennis player with a record 377 cumulative weeks ranked No. 1",
    "Tennis's Steffi",
    "Only tennis player to have won each Grand Slam event at least four times",
    "Tennis's Steffi",
    "Navratilova rival",
    "1989 AP Female Athlete of the Year",
    "Five-time U.S. Open winner",
    "Winner of 14 tennis majors in the 1990s",
    "Seven-time Wimbledon champ",
    "Tennis's Steffi",
    "Five-time U.S. Open champ",
    "___ Spee (old German warship)",
    "1988 tennis Grand Slam winner",
    "Golden Slam winner of 1988",
    "Agassi partner",
    "Seven-time Wimbledon winner",
    "Five-time U.S. Open champ",
    "Seven-time Wimbledon champ of the 1980's and 90's",
    "Tennis champ Steffi",
    "Tennis's Steffi",
    "Tennis?Æs Steffi",
    "Five-time U.S. Open champ",
    "Austrian earl",
    "1988 Grand Slam winner",
    "Seles rival",
    "Seles foe",
    "1991 Wimbledon champ",
    "Match for Seles"
  ],
  "grow": [
    "Blossom",
    "Produce on a farm",
    "Bloom or balloon",
    "Raise on a farm",
    "Get taller",
    "Expand",
    "What teens do that most twentysomethings don't",
    "Swell",
    "Increase",
    "Mushroom or balloon",
    "Mushroom",
    "Balloon",
    "\"Oh, ___ up!\"",
    "Shoot up",
    "Burgeon",
    "Wax",
    "Wax",
    "Get bigger",
    "Develop",
    "Develop",
    "Wax",
    "Wax",
    "Snowball",
    "Cultivate",
    "Multiply",
    "Wax",
    "Sprout",
    "Increase",
    "Wax",
    "Mushroom",
    "Swell",
    "Burgeon"
  ],
  "trey": [
    "Long shot, informally",
    "Unlikely trick taker",
    "Basket made from behind the arc",
    "Basket shot from behind the arc, in hoops slang",
    "Deuce topper",
    "Successful shot from downtown, in basketball lingo",
    "It beats a deuce",
    "War loser, usually",
    "War loser, usually",
    "Rare trick taker",
    "Card that just beats a deuce",
    "Shot from behind the arc, informally",
    "Deuce follower",
    "Smaller cousin of a four-in-hand?",
    "Deuce topper, in cards",
    "Loser in war, usually",
    "Long basket, in hoops lingo",
    "Deuce topper",
    "\"South Park\" writer Parker",
    "Long shot, in hoops",
    "Three-point shot, in slang",
    "ESPN anchor Wingo",
    "Certain domino number",
    "It's in the cards",
    "Deuce topper, in cards",
    "One before four",
    "Deuce beater",
    "Uncommon trick taker",
    "Small heart, say",
    "Low part of a hand",
    "Three-point shot, in hoops slang",
    "Two topper",
    "It rarely takes anything",
    "Deuce topper",
    "Card below a four",
    "Four's inferior",
    "Part of a low straight",
    "Deuce topper",
    "Low club",
    "Four's inferior",
    "Certain domino",
    "Rare trick-taker",
    "Rare trick taker",
    "Card above a deuce",
    "Three, in cards",
    "It doesn't take much",
    "Long basket, in hoops lingo",
    "Small diamond?",
    "It doesn't take much",
    "Three in a casino",
    "Low part of a hand",
    "Deuce taker",
    "Long basket, in basketball slang",
    "It takes two",
    "Deuce topper",
    "It's no honor",
    "It's just over 14-Across",
    "One more than 47-Down",
    "Rare trick taker",
    "Yarborough component, perhaps",
    "Rare trick-taker",
    "Low card",
    "Small club, say",
    "Poker card",
    "Deuce topper",
    "Deuce topper",
    "Deuce topper"
  ],
  "ruts": [
    "Pattern of monotony",
    "Hazards for rural travel",
    "Dirt road hazards",
    "Reasons to resurface",
    "Dead-end jobs, perhaps",
    "Wagon trails have them",
    "Monotonous routines",
    "Metaphorical low points",
    "Furrows",
    "What wagon wheels may make",
    "Boring routines",
    "Boring routines",
    "Deep grooves",
    "Dead-end jobs",
    "Boring routines",
    "Where wheels roll",
    "Road grooves",
    "They're hard to get out of",
    "Causes of some spinning wheels",
    "Dead-end jobs",
    "Tracks in mud",
    "Country road features",
    "Sticking places",
    "Channels"
  ],
  "ogre": [
    "Folk tale figure",
    "Fairy tale villain",
    "Fairy tale baddie",
    "Fairy tale foe",
    "Oni, in Japanese folklore",
    "Fiona or Shrek",
    "Big meanie",
    "Folklore fiend",
    "Fairy tale villain",
    "Figure that might give a child nightmares",
    "People eater",
    "Big brute",
    "Shrek, for one",
    "Villain defeated by Puss in Boots",
    "Despotic boss",
    "Fairy tale baddie",
    "Folklore fiend",
    "Fairy tale menace",
    "People eater",
    "Dungeons & Dragons monster",
    "\"Puss in Boots\" villain",
    "Shrek, e.g.",
    "Dungeons & Dragons figure",
    "Fearsome figure of folklore",
    "Mythical meanie",
    "Hated figure",
    "Ruthless sort",
    "Fairy tale villain",
    "Fairy tale meanie",
    "Fairy tale villain",
    "\"Tom Thumb\" antagonist",
    "Hence / Monster",
    "Horrid sort",
    "Fairy tale baddie",
    "Horrible person",
    "Menacing fairy tale figure",
    "Brutish sort",
    "Fairy tale fiend",
    "Shrek, for one",
    "Manlike monster",
    "Fearsome figure",
    "Folklore baddie",
    "Grim Grimm beast",
    "Shrek, e.g.",
    "Shrek, for one",
    "Fantasy beast",
    "Princess Fiona, after sunset",
    "Grotesque folklore figure",
    "Folklore fiend",
    "Fantasy game role",
    "\"Hop-o'-My-Thumb\" villain",
    "Fairy tale meanie",
    "Shrek, for one",
    "Monster",
    "Grendel in \"Beowulf,\" e.g.",
    "Nasty sort",
    "Fairy tale monster",
    "Tyrannical sort",
    "Shrek, e.g.",
    "Hulking beast",
    "Folklore villain",
    "Man-eater",
    "One carrying a spiked club, maybe",
    "People eater, perhaps",
    "Grimm (or grim?) character",
    "Grimm figure",
    "Man-eater",
    "Meanie",
    "Meanie",
    "Fairy tale villain",
    "Brute",
    "Major menace",
    "Fairy tale figure",
    "Mean sort",
    "Unlikely mate for a princess",
    "Creature outwitted by Hop-o'-My-Thumb",
    "Shrek, e.g.",
    "Shrek, e.g.",
    "Brute",
    "Dungeons & Dragons figure",
    "Gruesome sort",
    "The giant in \"Jack and the Beanstalk,\" e.g.",
    "\"Hop-o'-My-Thumb\" figure",
    "Folklore villain",
    "Shrek, e.g.",
    "Villain",
    "Grotesque giant",
    "Monster",
    "Figure in \"Jack and the Beanstalk\"",
    "Common enemy in Dungeons & Dragons",
    "No Mr. Nice Guy",
    "Purple people eater, e.g.",
    "Dungeons & Dragons figure",
    "\"Puss in Boots\" villain",
    "Damsel distresser",
    "Minotaur, e.g.",
    "Shrek, for one",
    "Storybook character",
    "\"Puss in Boots\" figure",
    "Figure in Tom Thumb tales",
    "Baddie in Perrault's tales",
    "\"Fee fi fo fum\" sayer",
    "Big brute",
    "Ymir, for one",
    "Scary figure",
    "Meanie",
    "One may put a damsel in distress",
    "Figure in Magic: The Gathering",
    "Grimm figure",
    "Villain",
    "Meanie",
    "Nightmare figure",
    "Monster",
    "Man-eating giant",
    "Brute",
    "Fee-faw-fum",
    "No gentle giant",
    "Big brute",
    "Cruel one",
    "Shrek, for one",
    "Dungeons & Dragons character",
    "Tyrannical sort",
    "Cruel person",
    "Dungeons & Dragons figure",
    "Nasty sort",
    "Fairy-tale fiend",
    "Fairy tale meanie",
    "Fairy-tale menace",
    "Hideous sort",
    "Fairy-tale meanie",
    "Shrek, for one",
    "Frightener",
    "Nasty sort",
    "Snarly sort",
    "Hideous sort",
    "Shrek, for one",
    "Meanie",
    "Meanie",
    "Hideous sort",
    "Villain",
    "Shrek, e.g.",
    "Storybook meanie",
    "Shrek, for one",
    "Meanie",
    "Shrek, for one",
    "Brute",
    "Blackguard",
    "Folklore fiend",
    "No friendly fellow",
    "Folklore meanie",
    "Shrek, e.g.",
    "Brute",
    "Grimm villain",
    "Grimm beast",
    "Unpleasant one",
    "Mean man",
    "Shrek, e.g.",
    "Folklore fiend",
    "Beast",
    "\"The Lord of the Rings\" figure",
    "Shrek, for one",
    "Fiend",
    "Fairy tale villain",
    "Meanie",
    "Tyrant",
    "Monster of a boss?",
    "Horrible boss",
    "Grimm figure",
    "Folklore meany",
    "Fairy tale villain",
    "\"Fee, fi, fo, fum\" caller",
    "Meanie",
    "Fiend",
    "Grimm beast",
    "Monster",
    "Ghoul",
    "Tyrant",
    "Fairy tale menace",
    "Fiend of dreams",
    "No Mr. Nice Guy",
    "Folklore fiend",
    "Grimm villain",
    "Snake, so to speak",
    "Fiend",
    "Mean one",
    "Very nasty sort",
    "More than a goblin",
    "Grimm figure",
    "Fearsome one",
    "Bugbear",
    "Meanie",
    "Very nasty sort",
    "Brute",
    "Evil one",
    "The Grinch was one",
    "Grimm character",
    "Meanie",
    "Nasty sort",
    "Menace",
    "Brute",
    "Bogeyman",
    "Grimm villain",
    "Fiend",
    "Nightmarish boss",
    "Meanie",
    "Meanie",
    "Fairy tale villain",
    "Flesh-eating giant",
    "Man-eating giant",
    "Fairy tale villain",
    "Monster",
    "Meanie",
    "Inhuman brute",
    "Bugaboo",
    "Folklore villain",
    "Brute",
    "Grimm character",
    "Brute",
    "Cruel one",
    "Grimm villain",
    "Bogeyman",
    "Folklore fiend",
    "Dungeons & Dragons beast"
  ],
  "reef": [
    "Area with lots of schools",
    "Hazard in maritime travel",
    "Home of Marlin and Nemo in \"Finding Nemo\"",
    "Habitat becoming bleached by global warming",
    "Ecosystem built by corals",
    "Diving spot",
    "Shipwreck site, maybe",
    "Natural feature near Queensland",
    "Biodiverse habitat",
    "Many a scuba destination",
    "Sound barrier?",
    "Home for a clown fish",
    "Shipwreck site, perhaps",
    "Shallow water obstacle",
    "Coral formation",
    "Where to find some very wet sponges",
    "Place for a sponge",
    "Place divers explore",
    "Coral bleaching locale",
    "Skin diving locale",
    "See 41-Across",
    "Good area for snorkeling",
    "Bikini feature",
    "Boater's hazard",
    "The Keys, essentially",
    "Feature of Hawaii's Molokini Crater",
    "Good snorkeling locale",
    "Shipping hazard",
    "Snorkeling spot",
    "Snorkeling locale",
    "Natural barrier",
    "Snorkeling site",
    "Ship hazard",
    "Island protector",
    "Coral formation",
    "Shipwreck site",
    "Hazardous obstruction",
    "Shipwreck locale",
    "Snorkeling spot",
    "Snorkeling site",
    "*Cal ___",
    "Scuba venue",
    "Site of many a shipwreck",
    "Shorten, as a sail",
    "Wreck site",
    "Offshore ridge",
    "Where a yacht might run aground",
    "Snorkler's interest",
    "Wreck site",
    "Coral formation",
    "Snorkeling site",
    "Bikini feature",
    "Atoll protector",
    "Coral ridge",
    "Natural breakwater",
    "Snorkeling site",
    "Part of a sail",
    "Snorkeler's haunt",
    "Shipwreck cause, sometimes",
    "Atoll protector",
    "Natural barrier",
    "Sailing danger",
    "Water hazard",
    "Coral construction",
    "Coral ___",
    "Coral formation",
    "Sea-diving area",
    "Site of a wreck",
    "Lagoon perimeter",
    "Coral ridge",
    "Sailor's peril",
    "Nautical danger",
    "Shorten, as a sail",
    "Cause of grounding",
    "Marine hazard",
    "Wreck of the Hesperus cause",
    "Shipwreck site",
    "Where ships run aground",
    "Sailing hazard",
    "Mariner's peril"
  ],
  "elko": [
    "Western city on the Humboldt River",
    "Nevada county or its seat",
    "Nevada gold-mining town",
    "Nevada city",
    "City in Nevada",
    "Nevada city on the Humboldt River",
    "Nevada city",
    "City in Nevada",
    "Nevada city",
    "Nevada city",
    "First home of the University of Nevada",
    "Nevada county or its seat",
    "Nevada county",
    "Nevada's second-largest county",
    "Town on the Humboldt River",
    "Nevada's second-largest county",
    "Nevada city",
    "Nevada county",
    "City on the Humboldt",
    "Nevada city",
    "Town ENE of 53-Down",
    "Nevada county or its seat",
    "Pony express stop",
    "Nevada town",
    "Town in Nevada",
    "Nevada city or county",
    "Nevada town"
  ],
  "soup": [
    "Stock holder",
    "Something you're not likely to fork out?",
    "Juice (up)",
    "Introductory course?",
    "Make more powerful, with \"up\"",
    "Campbell's product",
    "Salad alternative",
    "Vichyssoise or borscht",
    "Bisque or gazpacho",
    "You might put stock in it",
    "First course, sometimes",
    "You may put stock in it",
    "Campbell's product",
    "Antithesis of 32-Down",
    "Primordial ___",
    "Campbell's product",
    "Introductory course?",
    "With 57-Down, memorable \"Seinfeld\" character, with \"the\"",
    "Alternative to salad",
    "Introductory course?",
    "Gazpacho, e.g.",
    "See 24-Across",
    "Borscht, e.g.",
    "Starter, perhaps",
    "Campbell's product",
    "Added power, in slang",
    "Introductory course?",
    "Starting fare, often",
    "*Salad partner",
    "First course option",
    "It's not forked out",
    "Sandwich go-with",
    "Short course",
    "Egg drop, e.g.",
    "Extra horsepower, slangily",
    "Progresso product",
    "\"Du jour\" item",
    "Mulligatawny, for one",
    "First course, often",
    "Starting fare, often",
    "Make more powerful, with \"up\"",
    "Progresso product",
    "*Start of the word ladder",
    "Extra horsepower, in slang",
    "Campbell's product",
    "Early course",
    "Thick fog, in slang",
    "Elective course?",
    "First course, maybe",
    "First in a group leader to 28 Down",
    "Broth"
  ],
  "frag": [
    "Grenade, in video games",
    "Hand grenade, in slang",
    "Certain grenade, for short",
    "Assault, as a commanding officer",
    "Wound, in a way, as a fellow G.I.",
    "Assault with a grenade, as a superior officer",
    "Assault with a grenade, as a commanding officer"
  ],
  "luca": [
    "Title hero of a 2021 Pixar film",
    "___ Brasi (\"The Godfather\" role)",
    "___ Brasi, enforcer in \"The Godfather\"",
    "___ Brasi (Corleone hood)"
  ],
  "tuna": [
    "\"... piano, but you can't ___ fish\" (classic joke punch line)",
    "___ fish sandwich",
    "Deep-sea catch",
    "Common ingredient in a poke bowl",
    "Fish frequently caught by newts",
    "Fish sometimes served tartare",
    "\"Yellowfin\" fish",
    "Hobbes's favorite food in \"Calvin and Hobbes\"",
    "Sandwich fish",
    "Common casserole ingredient",
    "___ steak",
    "Fish in some salads",
    "\"Chicken of the sea\"",
    "Skipjack, e.g.",
    "Fish that's a sushi staple",
    "StarKist product",
    "Sushi fish",
    "Chicken of the Sea product",
    "___ salad",
    "Ahi, but not mahi mahi",
    "Hobbes's favorite food in \"Calvin and Hobbes\"",
    "Sushi bar supply",
    "___ roll (sushi offering)",
    "___ salad",
    "___ salad",
    "___ salad",
    "Fancy Feast choice",
    "Bluefin, e.g.",
    "Sushi fish",
    "Melt ingredient",
    "Deli salad fish",
    "Bluefin, for one",
    "Melt ingredient",
    "Sushi staple",
    "Deli sandwich choice",
    "Main ingredient in tekka maki",
    "Kind of melt",
    "Yellowfin, e.g.",
    "___ salad",
    "Yellowfin, e.g.",
    "Casserole option",
    "Kind of steak",
    "Salad ingredient",
    "Kind of steak",
    "Sashimi selection",
    "Bluefin or yellowfin",
    "Albacore or yellowfin",
    "Chicken of the Sea product",
    "Sandwich filler",
    "Sandwich filler",
    "Sushi selection",
    "Salade nicoise need",
    "Sandwich fish",
    "Sushi fish",
    "Another white meat",
    "Kind of steak",
    "___ sandwich",
    "Fish in a salad",
    "Sandwich fish",
    "Popular sandwich filler",
    "Yellowfin, e.g.",
    "Albacore",
    "It may be served on 8-Down",
    "Salade nicoise ingredient",
    "Yellowfin, e.g.",
    "Bluefin, e.g.",
    "Yellowfin, e.g.",
    "Charlie, for one",
    "Florida catch",
    "Kind of steak",
    "Sandwich filler",
    "Charlie, for one",
    "Cape Cod catch",
    "Star-Kist product",
    "Bumble Bee, e.g.",
    "Melt ingredient",
    "Sandwich filler",
    "Sushi staple",
    "Salad plate scoopful",
    "Kind of sandwich",
    "Sandwich filler",
    "Star-Kist product",
    "___ fish sandwich",
    "Casserole staple",
    "Kind of sandwich",
    "Bluefin"
  ],
  "erst": [
    "Long ago, long ago",
    "Lead-in to while",
    "Formerly, once",
    "Once, back in the day",
    "Once, once",
    "Formerly, in old times",
    "German for \"first\"",
    "Once, at one time",
    "Previously, previously",
    "Once, in olden days",
    "Back when, long ago",
    "Whilom",
    "Way back then, way back when",
    "Once, once",
    "At one time, once",
    "Long ago, long ago",
    "Lead-in to while",
    "Formerly, once",
    "Once, old-style",
    "Once, quaintly",
    "Formerly, once",
    "Beginner for a while?",
    "Formerly, in the past",
    "Formerly, old-style",
    "Lead-in to while",
    "Long ago, once",
    "Lead-in to while",
    "Previously, in old usage",
    "Once, old-style",
    "While preceder",
    "Once, in former times",
    "At one time in the past?",
    "Aforetime",
    "\"... which ___ from heat did canopy the herd\": Shak.",
    "In the past, once",
    "Formerly, once",
    "Previously archaic?",
    "Once, a long time ago",
    "In the past, once",
    "\"The even mead, that ___ brought sweetly forth ...\": \"Henry V\"",
    "Formerly, in olden days",
    "Once, old-style",
    "Once, in the past",
    "Formerly, once",
    "Formerly, in old usage",
    "Lead-in to while",
    "Once, formerly",
    "Once, once",
    "First, in Frankfurt",
    "At one time, at one time",
    "Formerly archaic?",
    "Lead-in to while",
    "Before, once",
    "\"Step the meek fowls where ___ they ranged\": Emerson",
    "Lead-in to while",
    "Once, once",
    "Lead-in to while",
    "Once, in the past",
    "Once, old-style",
    "Once, long ago",
    "First, in Frankfurt",
    "Once, in old times",
    "Once, old-style",
    "Formerly, once",
    "Once, once",
    "Once, long ago",
    "Lead-in to while",
    "Back then, back when",
    "Formerly, old-style",
    "Archaic adverb",
    "Once, old-style",
    "\"Where ___ was thickest fight\": Milton",
    "Formerly, once",
    "Long ago, long ago",
    "Once, once",
    "At first, once",
    "Once, once",
    "Once, long ago",
    "Formerly, formerly",
    "Once, once",
    "Once, once",
    "Once, once upon a time",
    "Formerly, once",
    "Once, once",
    "Originally, once",
    "Once, long ago",
    "Formerly, once",
    "Once before?",
    "While lead-in",
    "At first, in Frankfurt",
    "Lead-in to while",
    "Once, of old",
    "Once, once",
    "\"Step the meek fowls where ___ they ranged\": Emerson",
    "Once, once",
    "While lead-in",
    "Formerly, formerly",
    "Start with while",
    "Once, once",
    "Formerly, formerly",
    "Once, once",
    "Once, once",
    "Formerly, once",
    "Once, once",
    "Once, once",
    "At one time, at one time",
    "Once, once",
    "Once, once upon a time",
    "Once, once",
    "Word with while",
    "In the past, in the past",
    "Once, once",
    "In the past, in the past",
    "Formerly, formerly",
    "At one time, at one time",
    "Once, once",
    "Once, once",
    "Start with while",
    "Once, once",
    "Once, once",
    "Formerly, formerly",
    "Formerly",
    "At one time, at one time",
    "Start for while",
    "Once, once",
    "Formerly, once",
    "Formerly, formerly",
    "Once, once",
    "Once, once",
    "Start with while",
    "Once",
    "Once, once",
    "Formerly, once",
    "Once, long ago",
    "Former \"formerly\"",
    "Formerly, formerly",
    "Once, once",
    "Formerly, once",
    "Whilom",
    "Aforetime",
    "While opener",
    "Once, once",
    "Formerly",
    "Formerly",
    "Once, once"
  ],
  "coda": [
    "Bars that close",
    "Musical finale",
    "End notes",
    "End notes",
    "Musical finale",
    "End of a concerto",
    "Closing bars",
    "Sonata finale",
    "Concluding musical section",
    "End of a movement",
    "Musical finale",
    "Endnotes?",
    "End piece",
    "Led Zeppelin's final studio album, appropriately",
    "Sonata ending",
    "Opus ending",
    "Musical closing",
    "Musical ending",
    "Musical ending",
    "Final measures?",
    "End",
    "End notes?",
    "Musical conclusion",
    "End notes?",
    "Musical finale",
    "Opposite of an intro, musically",
    "Conclusion",
    "Conclusion",
    "Movement part",
    "It ends with two bars",
    "Closing bars",
    "Musical finale",
    "End notes",
    "Musical finale",
    "Musical ending",
    "Sonata finale",
    "Ending",
    "Musical epilogue",
    "Closing notes",
    "Last part",
    "Finale",
    "Conclusion",
    "Musical wrap-up",
    "End piece",
    "Finale of a sort",
    "End piece",
    "Musical tail",
    "Close of a musical composition",
    "Musical finale",
    "Musician's ___ mark",
    "Musical conclusion",
    "Pas de deux finale",
    "Closing musical passage",
    "End piece",
    "Musical ending",
    "End notes?",
    "Composition closure"
  ],
  "aton": [
    "Scores",
    "Lots",
    "Loads",
    "Lots",
    "Buckets",
    "Oodles and oodles",
    "Oodles",
    "Loads",
    "Lots and lots",
    "Plenty",
    "Gobs",
    "Lots and lots",
    "Bunches",
    "Zillions",
    "Heaps",
    "Oodles",
    "Super-often",
    "Oodles",
    "Heaps",
    "Slews",
    "Huge amounts",
    "Heaps",
    "Lots",
    "Very much",
    "Loads",
    "Oodles",
    "Oodles and oodles",
    "Lots",
    "Heaps",
    "Loads",
    "Lots",
    "Lots",
    "Loads",
    "Immensely",
    "Lots and lots",
    "Oceans",
    "Heaps",
    "Lots and lots",
    "Heaps",
    "Lots",
    "Lots",
    "Lots",
    "Loads",
    "Lots",
    "Having ___ of fun",
    "Rafts",
    "Heaps",
    "Immensely",
    "Loads",
    "Greatly",
    "Oodles",
    "Gobs and gobs",
    "Lots",
    "Swarms",
    "Immensely",
    "Loads",
    "Like ___ of bricks",
    "Loads",
    "Lots",
    "Gobs",
    "Heaps",
    "Piles",
    "Went over like ___ of bricks",
    "Oodles",
    "Loads",
    "Lots and lots",
    "Oodles",
    "Heaps",
    "Lots and lots",
    "Lots and lots",
    "Loads",
    "Buckets",
    "Lots",
    "Loads and loads",
    "Loads",
    "Loads",
    "Like ___ of bricks",
    "Lots",
    "Immensely",
    "Heaps",
    "Gobs",
    "Gobs",
    "Plenty, and then some",
    "Heaps",
    "Lots",
    "Heaps and heaps",
    "Plenty",
    "Like ___ of bricks",
    "Immensely",
    "Piles",
    "Immensely",
    "Lots",
    "Scads",
    "Immensely",
    "Huge amounts",
    "Heaps and heaps",
    "Heaps",
    "Loads",
    "Amenhotep IV's god",
    "Lots",
    "Hit ___ (batted well)",
    "Like ___ of bricks",
    "Like ___ of bricks",
    "\"This must weigh ___ !\"",
    "Solar disk"
  ],
  "oeno": [
    "Greek goddess who could turn water into wine",
    "Wine: Prefix",
    "Wine opener?",
    "Wine: Prefix",
    "Prefix meaning \"wine\"",
    "Prefix for vintners",
    "Wine: Prefix",
    "Wine: Prefix",
    "Vintner's prefix",
    "Wine: Prefix",
    "Prefix with -phile",
    "Wine: Prefix",
    "Wine: Prefix",
    "Vintners' prefix",
    "Prefix with -phile",
    "Sommelier's prefix",
    "Vintner's prefix",
    "Prefix with -phile",
    "Vintner's prefix",
    "Wine: Prefix",
    "Vintner's prefix",
    "Vintner's prefix",
    "Sommelier's prefix",
    "Vintner's prefix",
    "Wine: Prefix",
    "Wine: Prefix",
    "Wine: Prefix",
    "Wine: Prefix",
    "Prefix with -phile",
    "Wine: Prefix",
    "Wine: Prefix",
    "Wine, for starters",
    "Wine combiner",
    "Vintner's prefix",
    "Wine: Prefix",
    "Wine: Prefix",
    "Wine: Prefix"
  ],
  "palm": [
    "You might get a date from it",
    "Provider of a lifeline",
    "What a psychic may read",
    "It can be greased",
    "Acai tree, e.g.",
    "Image on the South Carolina flag",
    "Fourth word ...",
    "Coconut tree",
    "It may be read by a psychic",
    "Conceal cleverly",
    "Coconut's place",
    "With 132-Across, place to get a date",
    "Something read at a carnival",
    "Conceal, in a way",
    "One with hot dates, maybe",
    "Lifeline's location",
    "Tree in California",
    "Date maker",
    "It's within your grasp",
    "Conceal in the hand",
    "Where to find the Mercury line and the Girdle of Venus",
    "Conceal, as a coin",
    "Oil producer",
    "Date producer",
    "One might read a few lines from it",
    "Conceal, in a way",
    "Oil source",
    "Body part that's sometimes \"greased\"",
    "Magician's hiding spot",
    "Tree in Miami",
    "Pilot's place?",
    "It may be read",
    "Date maker",
    "It can make a date",
    "Conceal, as cards",
    "Lifeline site",
    "Tree in Miami",
    "Conceal, as a card",
    "Oasis tree",
    "Award of honor",
    "Coconut's source",
    "Date tree",
    "Take a la magicians",
    "___ Springs",
    "Secretly take"
  ],
  "orbs": [
    "Objects of stargazers' gazes",
    "Balls in the sky",
    "Celestial bodies",
    "Planets, e.g.",
    "Planets in the sky",
    "Globes",
    "Spheres",
    "Planets and moons",
    "Round figures",
    "Globes",
    "Spheres",
    "Sun, moon and stars",
    "Crystal balls, e.g.",
    "Space balls",
    "Some overhead light covers",
    "The sun and the moon",
    "Spheres, in poetry",
    "Spheres",
    "Eyes, to bards",
    "Royal balls",
    "Balls",
    "Partners of scepters",
    "Orrery components",
    "Round figures",
    "Balls",
    "Sun and moon, poetically",
    "Spheres",
    "Spheres",
    "Planets, to poets",
    "Spheres",
    "Partners of scepters",
    "Stars, e.g.",
    "Heavenly bodies",
    "Round bodies",
    "The sun, moon and stars",
    "Venus and others",
    "Earth and moon",
    "Heavenly bodies",
    "Planets, e.g.",
    "Scepter toppers",
    "Some Christmas ornaments",
    "Many Christmas ornaments",
    "Christmas tree ornaments, typically",
    "Regal symbols",
    "Planets or stars",
    "Symbols of authority",
    "The sun, moon and stars",
    "Sun, moon, etc.",
    "Eyes, poetically",
    "Planets, to poets",
    "Planets",
    "Spheres",
    "Christmas bulbs, e.g.",
    "Spheres",
    "\"Fiery\" things, to Shakespeare",
    "Eyes",
    "Spheres",
    "Planets and such",
    "Globes",
    "Eyes, poetically",
    "Celestial array",
    "Sun and moon, for example",
    "Eyes",
    "Moons, e.g.",
    "Globes",
    "Eyes, in poetry",
    "Spheres",
    "Stars, e.g.",
    "Emblems of power",
    "Basketballs, but not footballs",
    "Parts of royal regalia",
    "Shelley's eyes",
    "Symbols of kingly power",
    "Peepers",
    "Planets and such",
    "Planetarium sights",
    "The sun, the moon and the stars",
    "Scepters' go-withs",
    "Peepers",
    "Eyes, in poetry",
    "Eyes",
    "Eyeballs",
    "Sci-fi objects",
    "Moons"
  ],
  "cosi": [
    "\"So,\" in Italian",
    "Italian for \"so\"",
    "So, in Sicily",
    "\"___ Fan Tutte\"",
    "\"___ Fan Tutte\"",
    "Mozart's \"___ Fan Tutte\"",
    "\"___ Fan Tutte\"",
    "Mozart's \"___ Fan Tutte\"",
    "\"___ Fan Tutte\"",
    "Mozart opera title opening",
    "\"___ fan tutte\"",
    "Mozart's \"___ Fan Tutte\"",
    "So, in Salerno",
    "Mozart's \"___ Fan Tutte\"",
    "Mozart's \"___ Fan Tutte\"",
    "\"___ fan tutte\"",
    "\"___ fan tutte\"",
    "\"___ fan tutte\"",
    "\"___ sia\" (Italian \"amen\")",
    "Start of a Mozart title",
    "So, in Sorrento",
    "Thus, in Trieste",
    "\"___ fan tutte\"",
    "Mozart masterpiece, to an opera buff",
    "\"___ fan tutte\"",
    "\"___ fan tutte\"",
    "Mozart's \"___ fan tutte\"",
    "Mozart opera title starter",
    "So, in Sorrento",
    "\"___ fan tutte\"",
    "First word in a Mozart title",
    "Thus, to Luciano",
    "\"___ fan tutte\"",
    "When repeated, middling, in Milano",
    "Mozart \"___ fan tutte\"",
    "Mozart's \"___ fan tutte\""
  ],
  "fiat": [
    "Mandate",
    "Order",
    "Diktat",
    "Maserati's parent company",
    "Order",
    "Auto company since 1899",
    "Turin-based automaker",
    "Decree",
    "Order",
    "Italian carmaker",
    "Company that owns Ferrari",
    "Order",
    "Majority owner of Chrysler",
    "Direction from on high",
    "Italian carmaker",
    "Order",
    "Nondemocratic rule",
    "Autostrada auto",
    "Order",
    "Decree",
    "Decree",
    "It's the law",
    "Order",
    "Show of absolute power",
    "Command",
    "Order",
    "Via Veneto car",
    "Auto that debuted in 1899",
    "Order",
    "Order",
    "Brava maker",
    "Arbitrary decree",
    "Dictate",
    "Decree",
    "Mandate",
    "Edict",
    "Italian car"
  ],
  "heed": [
    "Follow",
    "Follow",
    "Mind, as a warning",
    "Opposite of disregard",
    "Mind",
    "Follow",
    "Pay attention to",
    "Attention",
    "Obey",
    "Mind",
    "Follow",
    "Follow closely",
    "Be mindful of",
    "Give attention",
    "Listen to",
    "Mind",
    "Take counsel from",
    "Take seriously",
    "Pay attention to",
    "Follow",
    "Obey",
    "Pay attention to",
    "Follow",
    "Follow, as advice",
    "Not brush off",
    "Mind",
    "Follow",
    "Pay attention to",
    "Mind",
    "Obey",
    "Consideration",
    "Pay mind to",
    "Listen to",
    "Follow",
    "Take note of",
    "Attention",
    "Pay attention to",
    "Take seriously",
    "Take note of",
    "Listen to",
    "Act on, as advice",
    "Take note of",
    "Consideration",
    "Obey",
    "Listen to",
    "Observe",
    "Observe",
    "Take notice",
    "Pay mind to",
    "Listen up",
    "Follow, as advice"
  ],
  "peck": [
    "Unit of measure in a tongue twister",
    "Little kiss",
    "Hunt-and-___ typing",
    "Fraction of a bushel",
    "Hit with the bill?",
    "1/4 bushel",
    "Briefest of kisses",
    "Modest kiss",
    "Amount for Peter Piper",
    "Eat like a bird",
    "Hunt-and-___ (typing method)",
    "Quantity picked by Peter Piper",
    "Eat like a bird",
    "Atticus Finch portrayer ... or something finches do",
    "Pickled peppers measure",
    "Quarter of a bushel",
    "Actor Gregory of \"To Kill a Mockingbird\"",
    "Quantity of pickled peppers Peter Piper picked",
    "Eat like a bird",
    "Amount of trouble?",
    "Apple quantity",
    "Amount of corn",
    "Chicken bite",
    "Eat like a bird",
    "Author of \"The Road Less Traveled\"",
    "Captain Ahab of film",
    "Eat like a chicken"
  ],
  "imho": [
    "Preface to sharing one's P.O.V.",
    "Letters preceding a controversial take",
    "\"If you're asking me,\" in textspeak",
    "Prelude to a perspective",
    "Modest qualifier, online",
    "\"If you ask me ...,\" in a text",
    "Texting preamble",
    "\"Here's what I think,\" in textspeak",
    "\"If you ask me,\" to texters",
    "Comment section disclaimer",
    "\"If you ask me ...,\" for short",
    "It may mean \"I'm about to tell you you're wrong\"",
    "Texter's qualifier",
    "Initialism whose third initial often isn't true",
    "\"Here's what I think,\" briefly",
    "Forum disclaimer",
    "\"If you ask me,\" in textspeak",
    "Disclaimer before some Internet comments",
    "Start of a web address?",
    "Modern \"methinks\"",
    "\"If you ask me,\" in chat rooms",
    "Texter's disclaimer",
    "Texter's \"As I see it ...\"",
    "\"The way I see it,\" briefly",
    "\"I would say ...,\" in texts",
    "Internet commenter's initialism",
    "\"If you ask me,\" in textspeak",
    "Modest \"Methinks,\" online",
    "Letters of faux modesty",
    "Chat room opener",
    "Blogger's preface",
    "Preface online",
    "\"If you ask me,\" in a chat room",
    "Internet initialism",
    "\"If you ask me,\" online",
    "Chat room shorthand for \"Here's what I think\"",
    "Online initialism",
    "Chat room initialism"
  ],
  "roti": [
    "Bread whose name derives from the Sanskrit for \"bread\"",
    "Indian flatbread",
    "Side order with curry",
    "Flat, round bread cooked on a griddle",
    "Indian flatbread",
    "Indian flatbread",
    "Indian flatbread",
    "Indian flatbread",
    "Indian flatbread",
    "Indian flatbread",
    "Flatbread sometimes served with curry",
    "Roasted: Fr.",
    "Indian flatbread",
    "Pita-like bread",
    "Indian bread",
    "___ de boeuf",
    "___ de boeuf",
    "___ de boeuf",
    "French roast",
    "Roasted: Fr.",
    "Indian flatbread",
    "Indian bread",
    "Roasted, in Rouen",
    "___ de boeuf",
    "French entree",
    "French roast",
    "French roast",
    "French chef's dish",
    "French entree",
    "Parisian entree"
  ],
  "loss": [
    "Certain tax write-off",
    "Wall Street woe",
    "The \"1\" of 10-1, say",
    "L's meaning, in box scores",
    "Datum for the second column",
    "Insurance estimate",
    "An overtime one counts as a point in the N.H.L.",
    "Profit's opposite",
    "Spreadsheet amount shown in parentheses",
    "Streak breaker, maybe",
    "Something to mourn",
    "Parenthetical figure, often",
    "Bereavement",
    "Bereavement",
    "Setback",
    "Failed investment",
    "Red ink amount",
    "Destruction",
    "Figure in red",
    "Perfect-record breaker",
    "Outcome of a 10-17 game",
    "Amount printed in red ink",
    "Firm fear",
    "Red ink entry",
    "Nonprofit?",
    "Red ink entry",
    "Result of a sack",
    "Red ink amount",
    "Surety concern",
    "Profit's opposite",
    "Kind of column",
    "Match disappointment",
    "Standings stat",
    "See 34-Across",
    "Erosion",
    "Red ink amount",
    "What red ink indicates",
    "What red ink represents",
    "Business concern",
    "What \"L\" stands for in box scores",
    "L's meaning, sometimes",
    "Impairment",
    "Win's opposite",
    "Subject for an insurance company",
    "Any Buffalo Bills Super Bowl result",
    "Red ink amount",
    "Defeat",
    "Sports stat",
    "Red-ink amount",
    "Deprivation",
    "Destruction"
  ],
  "sees": [
    "San Francisco-based candy brand",
    "Matches at a table",
    "Dates",
    "Isn't oblivious",
    "Goes out with",
    "Goes steady with",
    "Appreciates",
    "Matches",
    "Upscale candy brand",
    "Episcopates",
    "Goes with",
    "Notices",
    "Comprehends",
    "Grasps",
    "Goes out with",
    "Goes with",
    "Goes out with",
    "Determines (if)",
    "Matches, as a bet",
    "Matches, at a table",
    "Grasps",
    "Lays eyes on",
    "Matches, at a table",
    "Goes out with",
    "Meets with",
    "Gets the picture",
    "Calls, in poker",
    "Makes the connection",
    "Calls on",
    "Dates",
    "Observes",
    "Dates",
    "Receives, as a visitor",
    "Goes out with",
    "Matches",
    "Gets the gist",
    "Observes",
    "Goes with",
    "Gets",
    "Gets the picture",
    "Goes with",
    "Envisions",
    "Understands",
    "Dates",
    "Bishoprics",
    "Meets",
    "Takes out",
    "Attends",
    "Dates",
    "Dates",
    "Dates",
    "Gets",
    "Goes with",
    "Dates",
    "Gets a gander of",
    "Meets, as a bet",
    "Dates",
    "Matches, as a wager",
    "Inquires (about)",
    "Dates",
    "Attends",
    "Realizes",
    "Gets the picture",
    "Views",
    "Meets with",
    "Gets the point",
    "Dates",
    "Eyeballs",
    "Gets the picture",
    "Envisages",
    "Understands",
    "Regards",
    "Visits",
    "Catches",
    "Understands",
    "Understands",
    "Gets the picture",
    "Espies",
    "Witnesses",
    "Surveys",
    "Views",
    "Dates",
    "Regards",
    "Spots",
    "Keeps company with",
    "Recognizes",
    "Matches, in a way",
    "Employs pupils",
    "Receives",
    "Understands",
    "Dates",
    "Understands",
    "Gets",
    "Ecclesiastical offices",
    "Dates",
    "Notes",
    "Goes out with",
    "Perceives",
    "Matches a bet",
    "Goes out with regularly",
    "Matches",
    "Comprehends",
    "Spots"
  ],
  "sacs": [
    "Ink containers",
    "Ink holders in pens and squid",
    "Egg holders",
    "Plant pouches",
    "Pollen repositories",
    "Egg containers",
    "Pollen repositories",
    "Anatomical pouches",
    "Beneficial baseball outs, for short",
    "Alveoli, e.g.",
    "Ink containers for squids",
    "Some bunts or flies, for short",
    "Some bunts, for short",
    "Body bags?",
    "Anatomical pouches",
    "Egg containers",
    "Egg pouches",
    "Pouches",
    "Pouches",
    "Egg pouches",
    "Natural fluid containers",
    "Anatomical pouches",
    "Small fluid holders",
    "Anatomical pouches",
    "Bursae",
    "Some egg containers",
    "Wisconsin natives",
    "Baglets",
    "Black Hawk was their leader",
    "Anatomical holders",
    "Small pouches",
    "Some Oklahoma Indians",
    "Alveoli",
    "Pouches",
    "Bursae",
    "Black Hawk and tribesmen",
    "Body cavities",
    "Midwest Indians",
    "Pouches",
    "Pouches",
    "Pouches"
  ],
  "alex": [
    "Middle child on ABC's \"Modern Family\"",
    "Soccer star Morgan",
    "\"Roots\" author Haley",
    "Girlfriend on \"Orange Is the New Black\"",
    "Women's soccer star ___ Morgan",
    "\"Jeopardy!\" host Trebek",
    "Three-time N.H.L. M.V.P. Ovechkin",
    "Antihero of \"A Clockwork Orange\"",
    "\"Jeopardy!\" host Trebek",
    "Trebek with all the answers",
    "Mike Doonesbury's daughter in \"Doonesbury\"",
    "\"I'd like 'The New York Times Crossword' for $200, ___\"",
    "To whom \"I'll take ...\" is often said",
    "\"A Clockwork Orange\" protagonist",
    "\"Jeopardy!\" host Trebek",
    "\"A Clockwork Orange\" protagonist",
    "James Patterson sleuth Cross",
    "14-time All-Star Rodriguez",
    "\"A Clockwork Orange\" hooligan",
    "\"Family Ties\" boy",
    "\"Jeopardy!\" host Trebek",
    "\"Jeopardy!\" host Trebek",
    "Quizmaster Trebek",
    "\"A Clockwork Orange\" protagonist",
    "___ Cross, James Patterson detective",
    "\"A Clockwork Orange\" narrator",
    "\"Family Ties\" son",
    "\"A Clockwork Orange\" narrator",
    "\"Family Ties\" son",
    "The \"A\" in A-Rod",
    "\"A Clockwork Orange\" lead role",
    "Trebek of \"Jeopardy!\"",
    "Author Haley of \"Roots\" fame",
    "The \"A\" in A-Rod",
    "Trebek who says \"And the answer is ...\"",
    "\"I'll take 'The New York Times Crossword Puzzle' for $200, ___\"",
    "Slugger Rodriguez",
    "With 46-Down, writer of \"The Autobiography of Malcolm X\"",
    "\"I'll take Potpourri for $200, ___\"",
    "Doonesbury's daughter in \"Doonesbury\"",
    "The \"A\" in A-Rod",
    "\"'Starts With F' for a thousand, ___\"",
    "Third baseman Rodriguez",
    "\"Family Ties\" son",
    "___ Cross, James Patterson detective",
    "\"Family Ties\" son",
    "Baseball's Rodriguez",
    "Hoops Hall-of-Famer English",
    "\"Roots\" writer Haley",
    "\"Flash Gordon\" cartoonist ___ Raymond",
    "1959 Wimbledon winner ___ Olmedo",
    "\"'Potpourri' for a thousand, ___\"",
    "Emcee Trebek",
    "Michael J. Fox's role on \"Family Ties\"",
    "\"Potpourri for 1,000, ___\"",
    "\"A Clockwork Orange\" protagonist",
    "\"Roots\" author Haley",
    "\"Potent Potables for 400, ___\"",
    "TV's Trebek",
    "___ Cross, James Patterson detective",
    "Football's Karras",
    "\"Jeopardy!\" host Trebek",
    "\"Family Ties\" role",
    "Shortstop Rodriguez",
    "Haley who wrote \"Roots\"",
    "Emcee Trebek",
    "Judd's role on \"Taxi\"",
    "\"Fred Basset\" cartoonist Graham",
    "Comfort in bookstores",
    "\"Roots\" author ___ Haley",
    "Football's Karras",
    "\"Jeopardy!\" host Trebek",
    "\"Potent Potables for 200, ___\"",
    "Trebek of \"Jeopardy!\"",
    "\"Fred Basset\" cartoonist Graham",
    "Quizmaster Trebek",
    "Author Haley",
    "\"A Clockwork Orange\" hooligan",
    "Quizmaster Trebek",
    "\"Family Ties\" son",
    "Author Haley",
    "Emcee Trebek",
    "One of the sisters in \"Sisters\"",
    "\"The Black Stallion\" boy",
    "Trebek of \"Jeopardy!\"",
    "Novelist Haley",
    "\"Jeopardy!\" host Trebek",
    "\"Family Ties\" role",
    "\"Jeopardy!\" host Trebek",
    "Sister in \"Sisters\"",
    "\"'World Capitals' for 200, ___\"",
    "\"Family Ties\" boy",
    "\"Family Ties\" kid",
    "\"Potent Potables for 200, ___\"",
    "\"Flash Gordon\" originator ___ Raymond",
    "Author Haley",
    "First name in TV emceeing",
    "Trebek of \"Jeopardy!\"",
    "Mr. Trebek",
    "Author Haley",
    "Trebek of \"Jeopardy!\"",
    "\"Flash Gordon\" creator ___ Raymond",
    "Swoosie's \"Sisters\" role",
    "\"Roots\" author Haley",
    "\"Jeopardy!\" emcee Trebek",
    "Karrass of football",
    "\"A Clockwork Orange\" droogie",
    "\"Roots\" writer Haley",
    "Michael in \"Family Ties\"",
    "\"Fatal Attraction\" villain",
    "___ Raymond, originator of \"Flash Gordon\""
  ],
  "meat": [
    "Supermarket section",
    "Gist",
    "Gist",
    "Beef, e.g.",
    "Butcher's stock",
    "Potpie ingredient",
    "Ham or lamb",
    "Spam, for one",
    "Deli stock",
    "Flank or shank",
    "\"Turf\" half of surf and turf",
    "It may be well done",
    "Vegetarian's no-no",
    "Tenderizer target",
    "Vegetarian's no-no",
    "Substance",
    "Lamb, e.g.",
    "Paleo diet staple",
    "Gist",
    "Lamb, e.g.",
    "Heart of the matter",
    "Main thrust",
    "Inspection target of the 61-Across",
    "Most important part",
    "Nitty-gritty",
    "Pepperoni or sausage",
    "Gist",
    "Carnivore's fare",
    "Ham or lamb",
    "Essential part",
    "Vegetarian's no-no",
    "Sinatra's \"Meet ___ the Copa\"",
    "Entree, often",
    "What a vegan vetoes",
    "Vegetarian's no-no",
    "It may be cured",
    "Butcher's stock",
    "Gist",
    "Gist",
    "Essence",
    "Crux",
    "Crux",
    "___ and 38-Down",
    "Coconut filler",
    "Nut part",
    "Substance",
    "Butcher's stock",
    "Vegetarian's no-no",
    "Gist",
    "Vegetarians avoid it",
    "Gist",
    "Chuck, say",
    "Stuff in a locker",
    "Carnivore's intake",
    "Spam, e.g.",
    "Beef or bacon",
    "Essential part",
    "Substance",
    "Ham or hamburger",
    "Crux",
    "5-Down, e.g.",
    "Cold cuts, e.g.",
    "Vegetarian's no-no",
    "Butcher's stock",
    "Substance",
    "Chops, e.g.",
    "With 53-Across, a cleaver",
    "Vegan's no-no",
    "Herbivores avoid it",
    "Potatoes partner",
    "Butcher's goods",
    "Spam, ham or lamb",
    "Vegetarian's no-no",
    "Potatoes' partner",
    "Cold cuts, e.g.",
    "Vegetarians eschew it (NOT chew it)",
    "Ravioli filler",
    "Vegetarian's no-no",
    "Gist",
    "Nitty-gritty",
    "Lamb, e.g.",
    "Gist",
    "Partner of potatoes",
    "Butcher's stock",
    "Pith",
    "Crux",
    "Heart and soul",
    "Ham, lamb or Spam",
    "Crux",
    "Companion of potatoes",
    "Crux",
    "Butcher's stock",
    "Potatoes' partner",
    "Entree, often",
    "Nut's best part",
    "Gist",
    "Venison or veal",
    "Spam, ham or lamb",
    "Gist",
    "Quintessence",
    "Vegetarian's no-no",
    "It may be cured",
    "Sausage, e.g."
  ],
  "span": [
    "Run from here to there?",
    "Go over",
    "Reach across",
    "Measure of time or airplane wings",
    "Cross",
    "Go across",
    "Length",
    "Reach",
    "About 4,200 feet, for the Golden Gate Bridge",
    "Length of a bridge",
    "Bridge",
    "Extend across",
    "Bridge",
    "Cross",
    "4,200 feet, for the Golden Gate Bridge",
    "Go over",
    "Extend across",
    "Go across",
    "Part of an engineering project",
    "Bridge",
    "Bridge",
    "Team of oxen",
    "Bridge",
    "Bridge",
    "Bridge",
    "Decade or century",
    "Cross",
    "Range",
    "Length",
    "Bridge",
    "Go over",
    "Stretch over",
    "Breadth",
    "Stretch",
    "Reach across",
    "6-Across, for one",
    "Go across",
    "Period of time",
    "Month or year",
    "Reach across",
    "Period of time",
    "Go over",
    "Stretch of time",
    "Stretch",
    "Duration",
    "Approximately 1,600 feet, for the Brooklyn Bridge",
    "Cross",
    "Nine inches",
    "Bridge",
    "Bridge",
    "Hand measure",
    "Ending with time or life",
    "Go across",
    "Arch",
    "Go across",
    "Time period",
    "Wing-to-wing measure",
    "Full extent",
    "Time period",
    "Bridge",
    "The Golden Gate's is 4,200 feet",
    "Go over",
    "Go over",
    "Reach across",
    "C-___",
    "Bridge",
    "Nine inches",
    "Wings' measure",
    "Cross",
    "Time or life follower",
    "Cross",
    "Reach across",
    "Link, in a way",
    "Pair of oxen",
    "Attention amount",
    "Golden Gate section",
    "Farm team",
    "Go over",
    "Distance",
    "Attention ___",
    "C follower",
    "Bridge",
    "Nine inches",
    "Go over",
    "Time period",
    "Attention ___",
    "Animal team",
    "Extent",
    "Farm team",
    "Cross",
    "Bridge unit",
    "Bridge",
    "Bridge",
    "Distance between pillars",
    "Go over",
    "Bridge",
    "Extend over",
    "Bridge",
    "Bridge",
    "Wingspread",
    "Nine inches",
    "Bridge",
    "Go over",
    "Bridge",
    "Reach over",
    "Connect",
    "Twosome",
    "Cable TV's C-___",
    "Breadth",
    "Pass over",
    "Stretch over",
    "Cross",
    "Link",
    "Animal team"
  ],
  "mach": [
    "It's calculated relative to the speed of sound",
    "Speed measure",
    "Eponymous physicist Ernst",
    "Eponymous physicist Ernst",
    "Speed-of-sound ratio",
    "See 13-Down",
    "Physicist Ernst who studied shock waves",
    "Name often followed by a number",
    "Supersonic unit",
    "Word often followed by a numeral",
    "Number associated with a boom",
    "Physicist with an eponymous number",
    "Jet speed unit",
    "___ one",
    "Speed-of-sound number",
    "Jet speed measure",
    "Flight ratio",
    "Speed ratio",
    "Word before 1 or 2",
    "Airspeed unit",
    "High-speed number",
    "Supersonic number",
    "Kind of number",
    "Physicist Ernst"
  ],
  "bake": [
    "Make a turnover, say",
    "Really soak up the sun",
    "Lie in the sun",
    "Lie in the sun",
    "Put in a tandoor",
    "Pie recipe directive",
    "Excessively tan",
    "Lie on the beach",
    "Use the oven",
    "Get within range?",
    "Lie on a hot beach",
    "Prepare cookies or chicken, e.g.",
    "Oven dial word",
    "Make a 35-Across",
    "Lie on the beach, say",
    "Sit in the sun",
    "Make a cake, e.g.",
    "Lie in the sun",
    "Tan too long",
    "Make snaps, say",
    "Lie on the beach",
    "Enter a Pillsbury contest",
    "Sit in the sun",
    "Use a kiln",
    "Make brownies",
    "Make muffins",
    "Clam supper",
    "Shirr"
  ],
  "felt": [
    "Bowler material",
    "Pool table surface",
    "Perceived",
    "Sensed",
    "Fedora material",
    "Pool surface",
    "Pool surface",
    "Pool cover",
    "Arts and crafts material",
    "Sharpie tip material",
    "Craps table surface",
    "Had a hunch",
    "Pool surface",
    "Casino surface",
    "Believed",
    "Hat fabric",
    "With 54-Down, a derby, e.g.",
    "Wept (for)",
    "Had emotions",
    "Like some markers",
    "Experienced",
    "It's found on a casino table",
    "With 56-Across, features of some pens",
    "Intuited",
    "Believed",
    "Billiards surface",
    "Knew somehow",
    "Sensed",
    "Cushy fabric",
    "Had a sensation",
    "Beaver material",
    "Intuited",
    "Porkpie material",
    "Hat material",
    "Pool table cover",
    "Piano hammer material"
  ],
  "bill": [
    "Send an invoice",
    "Bird's beak",
    "Platypus feature",
    "With 29-Across, surprise in the mail",
    "Ten or twenty",
    "Unwelcome bit of mail",
    "New York City mayor de Blasio",
    "Unwelcome bit of mail",
    "One, for one",
    "Legislature's consideration",
    "Bit of avian anatomy",
    "Platypus part",
    "Unwanted mail",
    "Pecker",
    "One, for one",
    "Tab",
    "Check",
    "Green piece",
    "Unwelcome mail",
    "Tab",
    "Five or ten, e.g.",
    "Dun",
    "Player for coach Marv Levy",
    "Unwelcome mail",
    "Invoice",
    "Coo's partner",
    "\"Show Boat\" tune"
  ],
  "bite": [
    "Take the bait",
    "Quick snack",
    "Ask the obvious question, so to speak",
    "\"I'll ___\" (\"Try me\")",
    "What hungry fish do",
    "Dentist's instruction",
    "Nosh",
    "Orthodontist's concern",
    "Word after snake or sound",
    "Dentist's request",
    "Nosh",
    "Quick snack",
    "Spicy quality",
    "Dentist's directive",
    "Sharpness",
    "Fisher's wish",
    "Snack",
    "Nosh",
    "Fall for something",
    "Orthodontist's concern",
    "Morsel",
    "Very small serving",
    "Nosh",
    "Angler's hope",
    "Fisherman's hope",
    "Take the bait",
    "Dentist's concern",
    "Small snack",
    "Snack",
    "Snack",
    "Nosh",
    "Vampire's act",
    "Fisherman's wish",
    "Take the bait",
    "Light snack",
    "Angler's quest",
    "Nosh",
    "Sample",
    "Little nosh",
    "Orthodontist's concern",
    "Quick snack",
    "Itch cause, perhaps",
    "Dentist's request",
    "Dentist's request",
    "What unfriendly dogs do",
    "Incisiveness",
    "Emulate 22-Across",
    "Fishing luck",
    "Angler's hope",
    "Mad dog worry",
    "Angler's luck",
    "Quick lunch",
    "Dentist's request",
    "Angler's luck",
    "Fisherman's hope",
    "Be gullible",
    "Word with sound or dog",
    "Snack"
  ],
  "tort": [
    "Civil offense",
    "Libel or slander",
    "Fraud, for example",
    "Actionable misdeed",
    "Legal wrong",
    "Civil wrong",
    "One might result from negligence",
    "___ law",
    "Ralph Nader's American Museum of ___ Law",
    "Legal wrong",
    "Trespassing, for one",
    "Civil wrong",
    "Suit material?",
    "Libel or slander",
    "Wrong",
    "Basis of a lawsuit",
    "Slander or libel",
    "Material for a suit?",
    "Civil wrong",
    "Assault or battery",
    "Material for a suit?",
    "Wrong that's adjudicated in court",
    "Wrongful act",
    "Battery, e.g.",
    "Injury, in law",
    "Suit basis",
    "Basis of a lawsuit",
    "Battery, e.g.",
    "Libel, e.g.",
    "Slander, e.g.",
    "Makings of a suit",
    "Legal wrong",
    "Wrong",
    "Basis of a suit",
    "Basis for a suit",
    "Wrong",
    "Start of a suit",
    "Reason to press a suit, perhaps",
    "Suit material?",
    "Civil wrong",
    "Civil wrong",
    "Wrong",
    "Suit material?",
    "Reason for pressing a suit",
    "Wrong",
    "Reason for compensation",
    "Civil wrong",
    "Suit material?",
    "Trespass, e.g.",
    "Slander, e.g.",
    "Wrong",
    "Legal wrong",
    "Cause for a lawsuit",
    "Lawsuit basis",
    "Wrong",
    "Civil wrong",
    "Wrongful act",
    "Reason for a suit",
    "Reason to sue",
    "Legal matter",
    "Kind of damages",
    "Wrongful act",
    "Suit material, perhaps",
    "Civil wrong",
    "Matter to go to court over",
    "Suit material?",
    "Civil wrong",
    "Suit material, perhaps",
    "Libel, e.g.",
    "Civil wrong",
    "Lawsuit basis"
  ],
  "horn": [
    "Important part of a taxi",
    "Part of Africa or an orchestra",
    "Front part of a saddle",
    "\"Game over\" signal",
    "Sound that signifies the end of a basketball game",
    "Feature of Africa",
    "Road rage catalyst",
    "One of a satanic couple",
    "Part of a rhinoceros",
    "Part of a car alarm, maybe",
    "What Little Boy Blue blew",
    "Instrument used to play 25-Down",
    "Butt (in)",
    "Somalia's locale in Africa",
    "Game ender, at times",
    "Part of Africa",
    "Blast source",
    "\"Beep beep\" maker",
    "Crescent moon feature",
    "Honker",
    "Feature of Africa ... and some of its denizens",
    "Device making a 53-Down",
    "Tooter",
    "Middle of many a steering wheel",
    "Blast maker",
    "Beeper",
    "A driver may sit on it",
    "Game ender, perhaps",
    "Crescent-shaped tract of land",
    "Phone, slangily",
    "Gunpowder holder",
    "Honker",
    "Game ender, perhaps",
    "Powder holder",
    "It may be heard before an accident",
    "It may be French",
    "Warning device",
    "It clears the way",
    "Angry motorist's need",
    "Phone, slangily",
    "Taxi feature",
    "Little Boy Blue's instrument",
    "Phone, slangily",
    "Loudspeaker, informally",
    "Honker",
    "Headpiece?",
    "Glaciated mountain peak",
    "Telephone, slangily",
    "Brass piece",
    "Telephone, slangily",
    "Cornet",
    "Middle of a wheel, often",
    "Tooter",
    "___ of plenty",
    "Saddle part",
    "Cornucopia"
  ],
  "adue": [
    "Together, in music",
    "Together, in music",
    "For two, in music",
    "Together, in music",
    "For two voices in unison",
    "Together, in music",
    "Together, musically",
    "Together, musically",
    "In unison, in a way",
    "Together, in music",
    "Together, in music",
    "Together, musically",
    "Together, musically",
    "Together, in music",
    "Together, in music",
    "Not apart, in scores",
    "Scored together?",
    "Literally, \"by two\"",
    "Together, on musical scores",
    "Together, to Toscanini",
    "Together, in music",
    "Together, in music",
    "Together, in music",
    "In unison",
    "Together, musically",
    "Musically together",
    "To be played in unison",
    "Together, in music",
    "Musical instruction",
    "Together, on a score",
    "Sheet music instruction",
    "Together, in music",
    "Together, musically",
    "Together, musically",
    "Together, on a score",
    "As a twosome, musically",
    "Together, musically",
    "In unison, in a way",
    "Together, to Toscanini",
    "Together, in music",
    "Together, in music",
    "Together, in music",
    "Together, musically",
    "Together, in music",
    "Together, musically",
    "Together, musically",
    "Together, in music",
    "Musical direction",
    "Together, musically",
    "In unison, musically",
    "Together, musically",
    "Together, musically",
    "Together, musically",
    "Together, in music",
    "Musically together",
    "Musical direction",
    "In unison",
    "Together, musically",
    "Direction for Solti",
    "Together, in music",
    "Together, in music",
    "Together, musically"
  ],
  "ruly": ["Neat and orderly"],
  "fact": [
    "Datum",
    "Bit of trivia",
    "Fiction's opposite",
    "It's the truth",
    "Fiction's opposite",
    "Irrefutable point",
    "Truth we hold to be self-evident?",
    "\"The following is completely true:\"",
    "Almanac entry",
    "Fiction's opposite",
    "It's never wrong",
    "Non-fiction",
    "There are 336 dimples on a typical golf ball, for instance",
    "It's undeniable",
    "It's the truth",
    "Undisputed point",
    "It's not fancy",
    "Concern for a checker",
    "\"Is that a ___?\"",
    "Fiction's opposite",
    "Not fancy",
    "Datum",
    "It's true",
    "That's the truth",
    "It's true",
    "It's the truth",
    "Almanac bit",
    "Almanac datum",
    "Almanac tidbit",
    "It's true",
    "It's the truth",
    "Almanac tidbit",
    "\"Is that a ___?\"",
    "Datum",
    "It's true",
    "You'd better believe it!",
    "Not fiction",
    "Almanac tidbit",
    "Actuality",
    "Item for Sgt. Friday",
    "Not fancy?"
  ],
  "idol": [
    "Statue of Ganesha, e.g.",
    "Fan fave",
    "Golden calf, e.g.",
    "Role model",
    "Fan mail recipient",
    "Fetishized one",
    "One with tens of millions of Instagram followers, maybe",
    "Golden god",
    "Teen ___",
    "Pop sensation",
    "Fox talent show winner",
    "Pop star",
    "Giant star",
    "Celebrated figure",
    "Fan favorite",
    "Worshiped one",
    "Object of veneration",
    "Rocker, perhaps",
    "Fawned-over figure",
    "Talent show that jumped networks, familiarly",
    "Massive star",
    "Longtime singing talent show, familiarly",
    "Object of worship",
    "Subject of devotion",
    "Teen ___",
    "Fan fixation",
    "One with millions of Instagram followers, say",
    "Indiana Jones pursuit",
    "Fanzine favorite",
    "Revered one",
    "Fan favorite",
    "Immunity ___ (\"Survivor\" object)",
    "Item swiped by Indiana Jones at the start of \"Raiders of the Lost Ark\"",
    "Golden calf, e.g.",
    "Object of admiration",
    "Hero",
    "Kelly Clarkson was the first one, informally",
    "Entertainment Weekly interviewee",
    "Treasure hunter's loot, maybe",
    "TV's discontinued \"American ___\"",
    "Teenage dream?",
    "Golden calf, e.g.",
    "Revered one",
    "\"American ___\"",
    "Hot star",
    "One with devotees",
    "Taylor Swift, for one",
    "Recipient of 11-Down",
    "Golden calf, e.g.",
    "\"American ___\"",
    "Fan fixation",
    "Pop ___",
    "Subject of a Fox hunt",
    "Something false in the Bible?",
    "Winner of a popular TV talent show",
    "Bedroom poster subject",
    "Fox's \"American ___\"",
    "Golden god, say",
    "Heathen's figurine",
    "\"Survivor\" immunity token",
    "Stargazer's focus?",
    "Sacred cow",
    "Many a Rolling Stone cover subject",
    "Kelly Clarkson was the first \"American\" one",
    "One with millions of followers",
    "Something smashed by Abraham, in Jewish tradition",
    "Fox talent show, for short",
    "Carrie Underwood or Taylor Hicks",
    "Superstar",
    "Target of a Fox hunt?",
    "Worshiped one",
    "One on a pedestal",
    "Poster subject, maybe",
    "Person on a pedestal",
    "Winner of a Fox talent show beginning in 2002",
    "Fan's fixation",
    "One who's worshiped",
    "Fan club focus",
    "Michael Jordan, e.g.",
    "Much-loved celebrity",
    "Billy who sang \"Rebel Yell\"",
    "Fox hit since 2002, informally",
    "Favorite",
    "Fox's \"American ___\"",
    "Superstar",
    "Pedestal topper",
    "Massive star",
    "Kelly Clarkson, once",
    "Simon Cowell's show, for short",
    "Rock star, say",
    "Revered figure",
    "Miley Cyrus, to teens",
    "Very hot star",
    "Word after matinee or teen",
    "Tiger Beat cover subject",
    "Fox's \"American ___\"",
    "Elvis Presley or Marilyn Monroe",
    "Object of many screams",
    "Fan favorite",
    "Immunity ___ on \"Survivor\"",
    "Hero to many",
    "Golden deity, say",
    "Fox's \"American ___\"",
    "Wannabe's model",
    "Rock star, e.g.",
    "One on a pedestal",
    "It may be false",
    "Fox hit \"American ___\"",
    "Darling",
    "Taylor Hicks, e.g.",
    "Massive star",
    "Fox TV's \"American ___\"",
    "Rolling Stone cover subject",
    "Kelly Clarkson or Taylor Hicks",
    "One on a pedestal",
    "TV's \"American ___\"",
    "Hero type",
    "One with adoring fans",
    "British rocker Billy",
    "\"American ___\"",
    "Object of devotion",
    "Teen fave",
    "Fan fave",
    "Object of worship",
    "Provider of immunity on \"Survivor\"",
    "False god",
    "Object of worship",
    "Teen ___",
    "Superstar",
    "Hero",
    "Immunity item on \"Survivor\"",
    "\"American __\"",
    "Matinee ___",
    "Any Beatle, once",
    "Temple image",
    "Big star, say",
    "Favorite",
    "Fan mail recipient",
    "Pedestal figure",
    "Teen People cover subject",
    "Role model",
    "One doing commercial endorsements, maybe",
    "Hero-type",
    "Fan club's honoree",
    "Hollywood favorite",
    "Hit Fox show, in headlines",
    "TV's \"American ___\"",
    "Temple figure",
    "Person on a poster",
    "Matinee ___",
    "TV's \"American ___\"",
    "Any of the Beatles, once",
    "Elvis or Madonna, e.g.",
    "Loved one",
    "\"American ___\"",
    "Teen ___",
    "One often asked for an autograph",
    "Subject of adoration",
    "The golden calf was one",
    "\"American ___\"",
    "Hero",
    "\"American ___\"",
    "Fawning target",
    "One who's looked up to",
    "Teen ___ (heartthrob)",
    "Britney Spears, to teen girls",
    "Baal, e.g.",
    "Revered figure",
    "Teen fave",
    "Screen favorite",
    "Object of incense burning, maybe",
    "Fawned-over figure",
    "Elvis, once",
    "Second commandment no-no",
    "Graven image",
    "Poster boy?",
    "Hero",
    "Pedestal topper",
    "Favorite",
    "Not just a star",
    "Ricky Martin, for one",
    "Fetishist's object",
    "One getting bags of fan mail",
    "Pagoda sight",
    "Object of blind devotion",
    "Baal, e.g.",
    "Golden calf",
    "One who?Æs swooned over",
    "It might be surrounded by bows",
    "Britney Spears, to some",
    "Elvis or Fabian, once",
    "Darling",
    "Matinee hero",
    "Pedestal topper",
    "Hollywood favorite",
    "Screen fave",
    "Object of devotion",
    "Revered object",
    "Role model, maybe",
    "1-Across, e.g.",
    "Teen magazine profilee",
    "Object of devotion",
    "Favorite one",
    "Teen fave",
    "Part of a temple's decor, maybe",
    "Teen ___",
    "Hero",
    "Matinee hero",
    "Someone who's looked up to",
    "Second Commandment taboo",
    "Movie star",
    "One on a pedestal",
    "False god",
    "Pop star",
    "Person with fans",
    "Pop star, say",
    "Teen ___",
    "Elvis Presley, in the 50's and 60's",
    "1948 film \"The Fallen ___\"",
    "Joss",
    "What's all the screaming about?",
    "Elvis, for one",
    "Teen Beat cover subject",
    "Screen favorite",
    "Golen calf",
    "Commercial endorsement person, maybe",
    "Worshiped one",
    "Matinee hero",
    "Favorite",
    "Biblical no-no",
    "___worship",
    "Word with screen or teen",
    "Favorite",
    "Show biz star",
    "One who's adored",
    "Pagan god",
    "Teen's fave",
    "Sacred statue",
    "Rock's Billy ___",
    "Whom fans adore",
    "Object of adoration",
    "Tiki, e.g.",
    "Poster subject",
    "Pop star",
    "Baal, e.g.",
    "Elvis, for one",
    "Pedestal figure",
    "Worshiped one",
    "False god",
    "More than a celebrity",
    "Whom a wannabe wants to be",
    "Pedestal figure",
    "16 magazine profilee",
    "Rock's Billy"
  ],
  "onor": [
    "___ about",
    "Words before \"before\"",
    "___ about (approximately)",
    "___ about (approximately)",
    "___ about (approximately)",
    "___ about (approximately)",
    "Words before \"before\"",
    "___ close to schedule",
    "___ about (approximately)",
    "___ about (approximately)",
    "___ about (around)",
    "___ about",
    "___ about (approximately)",
    "Words before before",
    "___ about",
    "___ near",
    "___ before (no later than)",
    "___ about (approximately)",
    "___ off (light switch options)",
    "___ about",
    "___ before",
    "___ about (approximately)",
    "\"___ off?\"",
    "___ about",
    "___ about",
    "___ about (circa)",
    "___ about (roughly)",
    "___ around (close to)",
    "___ before",
    "___ about (roughly)",
    "___ about",
    "___ about (roughly)",
    "___ about",
    "___ about (near)",
    "\"___ off?\"",
    "___ around",
    "___ about (circa)",
    "___ before",
    "___ about (approximately)",
    "___ about (approximately)",
    "\"___ off?\"",
    "___ about",
    "___ about (roughly)",
    "___ about (roughly)",
    "\"... ___ before the deadline\"",
    "___ about (approximately)",
    "___ about (roughly)",
    "___ about (approximately)",
    "___ close to schedule",
    "___ about",
    "\"___ off?\"",
    "___ about (near)",
    "___ about (circa)",
    "___ about (approximately)",
    "___ about (approximately)",
    "___ about (roughly)",
    "___ about (approximately)",
    "___ about (around)",
    "Words before \"about\"",
    "___ about (approximately)",
    "___ about (circa)",
    "___ about",
    "___ about (lawyer's phrase)",
    "___ about",
    "___ about (approximately)",
    "___ off (switch choice)",
    "___about (approximately)",
    "___about (legal phrase)",
    "___about (legal phrase)",
    "___ about (legal phrase)",
    "___ about (around)",
    "___ about (approximately)",
    "___ about (lawyer's phrase)",
    "___ about (legal time frame)",
    "___ about (approximately)",
    "\"___ close to schedule\"",
    "___ about (time phrase)",
    "___ about (legalistic phrase)",
    "\"___ about...\""
  ],
  "ciao": [
    "\"Bye now!\"",
    "\"Arrivederci!\"",
    "Hello or goodbye",
    "\"See ya!\"",
    "\"Gotta go!\"",
    "\"Gotta run!\"",
    "\"Toodles!\"",
    "\"Toodles\"",
    "\"See you later!\"",
    "\"I'm off!\"",
    "\"So long\"",
    "Hello or goodbye",
    "Word that might accompany an air kiss",
    "\"Later!\"",
    "\"See ya\"",
    "\"Ta-ta!\"",
    "Firenze farewell",
    "\"See ya!\"",
    "\"So long, Sofia!\"",
    "\"Later\"",
    "\"See ya!\"",
    "Word of parting",
    "\"See ya!\"",
    "Hello or goodbye",
    "\"Ta-ta\"",
    "Informal goodbye",
    "Italian goodbye",
    "\"Goodbye, Guido!\"",
    "\"Ta-ta!\"",
    "\"Gotta go!\"",
    "Relative of aloha or shalom",
    "\"Bye now\"",
    "\"I'm gone\"",
    "\"Gotta run!\"",
    "\"Later\"",
    "Foreign farewell",
    "Italian \"bye\"",
    "Parting word",
    "\"Arrivederci\"",
    "\"Later!\"",
    "\"Gotta go!\"",
    "Bye for an Italian soccer team?",
    "Italian's \"bye\"",
    "\"Later!\"",
    "\"Ta-ta!\"",
    "\"It's been real\"",
    "\"Later\"",
    "\"I'm outta here!\"",
    "Farewell in 41-Down",
    "\"Gotta run!\"",
    "\"Toodles,\" in Milan",
    "\"See ya\"",
    "\"Bye\"",
    "\"Later, dude\"",
    "\"See ya!\"",
    "\"See ya!\"",
    "\"Ta-ta!\"",
    "Word of greeting and parting",
    "\"Later\"",
    "\"Later!\"",
    "\"Bye\"",
    "\"Ta-ta!\"",
    "?ôSee ya!?ö",
    "\"Ta-ta!\"",
    "\"See ya!\"",
    "\"Later!\"",
    "Giorgio's greeting",
    "\"See you later!\"",
    "\"See ya!\"",
    "\"See ya!\"",
    "\"Bye now\"",
    "Florence farewell",
    "\"See you later!\"",
    "\"See ya!\"",
    "Bye",
    "Cousin of \"Hasta luego\"",
    "\"Later, gator!\"",
    "\"Ta-ta!\"",
    "\"Later\"",
    "\"Ta-ta\"",
    "\"I'm history!\"",
    "Goodbye",
    "\"So long!\"",
    "\"Later\"",
    "\"Bye!\"",
    "\"Later!\"",
    "\"So long!\"",
    "\"Later\"",
    "\"Later!\"",
    "Tuscany ta-ta",
    "\"Bye!\"",
    "\"See you,\" in Sorrento",
    "\"Adios\"",
    "\"Cheerio, Luciano!\"",
    "\"Bye\"",
    "\"So long\"",
    "Genoa greeting",
    "Bye-bye",
    "Slangy goodbye",
    "Via Veneto farewell",
    "Ta-ta in Turin"
  ],
  "meth": [
    "Drug in \"Breaking Bad\"",
    "Crystal ___",
    "Kind of lab",
    "Crystal ___",
    "\"Breaking Bad\" commodity",
    "Kind of lab",
    "Kind of bust",
    "Kind of lab",
    "Target of a narc",
    "The \"M\" in S.M.U.: Abbr.",
    "___ lab",
    "Dangerous speed",
    "Crystal ___",
    "Christian denom.",
    "The ?ôM?ö in S.M.U.: Abbr.",
    "John Wesley's denom.",
    "Relative of a Bap. or Presb."
  ],
  "soot": [
    "Pipe buildup",
    "Santa's coat?",
    "Chimney coating",
    "It appears in stacks",
    "Annoyance for Santa",
    "Stain on Santa",
    "Flue buildup",
    "Flue problem",
    "Chimney buildup",
    "Chimney sweep's target",
    "Shade of black",
    "Fossil fuel residue",
    "Some air pollution",
    "Fire proof?",
    "Pipe buildup",
    "Diesel discharge",
    "Flue problem",
    "Chimney sweep's target",
    "Shade of black",
    "Shade of black",
    "Chimney black",
    "Santa's bootblack?",
    "Chimney sweep coating",
    "Sweep spots?",
    "It's found in stacks",
    "Lampblack",
    "Blackener of Santa's boots",
    "Chimney grime",
    "Film about a furnace",
    "Burning evidence",
    "Lampblack",
    "Blackener of Santa's suit",
    "Chimney buildup",
    "Symbol of blackness",
    "Stack buildup",
    "Some particulates",
    "Symbol of blackness",
    "Symbol of blackness",
    "Target of a sweep",
    "Chimney sweep's target",
    "Dirty coat",
    "Lampblack, essentially",
    "Shade of black",
    "Sweep's schmutz",
    "Epitome of blackness",
    "Pipe residue",
    "It's found in stacks",
    "It settles on sweeps",
    "Lampblack",
    "*Sweep ... coal ... Santa",
    "Chimney grime",
    "Dirty coat",
    "Pipe sullier",
    "Spot on Santa",
    "Chimney residue",
    "Smog contributor",
    "Grate stuff",
    "Santa soiler",
    "Flue buildup",
    "Lampblack",
    "Combustion byproduct",
    "Santa's coat?",
    "Santa's spot?",
    "Flue residue",
    "Chimney accumulation",
    "What chimney sweeps sweep",
    "Chimney covering",
    "Fire damage",
    "Lampblack",
    "Fly ash",
    "Air particulates",
    "Chimney dirt",
    "Chimney grime",
    "It's found in a stack",
    "Lampblack",
    "Stain on Santa",
    "Chimney grit",
    "Fly ash"
  ],
  "tong": [
    "Grab by pinching, as an ice cube",
    "Half of a salad bar implement",
    "Chinese secret society",
    "Half of a fireplace tool",
    "Pick up, as ice cubes",
    "Take one's lumps?",
    "Chinese gang",
    "Grab, as an ice cube",
    "Chinese secret society",
    "Chinatown gang",
    "Chinese mafia",
    "Chinese mafia",
    "Chinese secret society",
    "Lift, as ice cubes",
    "Chinese political party",
    "Half a tea service tool",
    "Ice picker-upper",
    "Chinatown secret society",
    "Chinese Mafia",
    "Half a picker-upper",
    "Chinese dynasty",
    "Chinese mafia",
    "Chinese secret society",
    "Chinese secret society",
    "Lift, as ice or oysters"
  ],
  "diem": [
    "Per ___",
    "Per ___",
    "Per ___ (daily)",
    "Carpe ___ (seize the day)",
    "The \"day\" in \"seize the day\"",
    "Carpe ___ (seize the day: Lat.)",
    "Per ___",
    "Carpe ___",
    "Per ___",
    "Per ___ (daily)",
    "Per ___",
    "Per ___",
    "Per ___ (by the day: Lat.)",
    "Per ___",
    "Per ___ (expense account amount)",
    "Per ___",
    "\"Carpe ___\"",
    "Per ___",
    "Carpe ___",
    "Ngo Dinh ___, South Vietnam's first president",
    "Per ___",
    "Per ___",
    "Per ___",
    "Per ___",
    "Per ___",
    "See 6-Down",
    "Per ___",
    "Per ___",
    "Per ___ (daily)",
    "Per ___",
    "South Vietnam's Ngo Dinh ___",
    "Carpe ___",
    "Per ___",
    "Vietnamese leader deposed in 1963",
    "Vietnamese dictator Ngo Dinh ___",
    "Vietnam's Ngo Dinh ___",
    "Per ___ (daily)",
    "Carpe ___",
    "Per ___",
    "Carpe ___ (seize the day)",
    "Carpe ___",
    "Per ___",
    "Per ___",
    "Per ___",
    "Per ___",
    "Carpe ___",
    "See 7 Down"
  ],
  "tide": [
    "Current event",
    "Its pods are poisonous to eat",
    "Changing fortune, metaphorically",
    "All alternative",
    "It comes in pods",
    "Cause of shore erosion",
    "Ocean motion",
    "Best-selling detergent brand",
    "It ebbs and flows",
    "Beach washer",
    "Fab alternative",
    "Competitor of Wisk",
    "Big-selling Procter & Gamble product",
    "Concern for beachcombers",
    "\"Style is an option. Clean is not\" sloganeer",
    "Sand castle's undoing",
    "Name that completes the old slogan \"Dirt can't hide from Intensified ___\"",
    "It comes in waves",
    "Sand castle's undoing",
    "Lifeguard's concern",
    "Daily riser",
    "'Bama, with \"the\"",
    "Beach washer",
    "It may be turned against you",
    "Surfer's concern",
    "Effect of the moon's gravity",
    "Ocean motion",
    "Shore washer",
    "Lunar effect",
    "Subject of some tables",
    "Geophysics topic",
    "Mariner's datum",
    "Procter & Gamble best seller",
    "Trend",
    "Cheer competitor",
    "\"The washday miracle\" sloganeer, once",
    "Beach washer",
    "Periodic riser",
    "Brand with a giant target in its logo",
    "Marigraph activator",
    "Tendency, as of events",
    "Rush",
    "Motion in the ocean",
    "Cause of shore erosion",
    "It ebbs and flows",
    "Ocean motion",
    "\"The washday miracle\" sloganeer, once",
    "Seashore washer",
    "Beach eroder",
    "Ocean motion",
    "Neap, e.g.",
    "It turns eventually",
    "Beachcomber's concern",
    "Lunar effect",
    "It ebbs and flows",
    "An almanac lists its highs and lows",
    "It rolls in",
    "\"The ___ stayeth for no man\": William Camden",
    "Daily riser",
    "Sweeping force",
    "Course of events",
    "Almanac topic",
    "\"...under the whelming ___\": Milton",
    "Beach washer",
    "Ocean motion",
    "Ocean motion",
    "Beach sweeper",
    "Coastal flooding factor",
    "___ over (carry through)",
    "Bay of Fundy feature",
    "Procter & Gamble brand since 1947",
    "Procter & Gamble brand",
    "Time's partner",
    "Ajax rival",
    "It's controlled by the moon",
    "It comes after Easter",
    "Ebb, e.g.",
    "Cause of beach erosion",
    "Wave maker"
  ],
  "isbn": [
    "Abbr. in a library catalog",
    "Some library catalog info, for short",
    "Jacket letters",
    "Card catalog ID",
    "Library ID",
    "Library catalog ID",
    "Fig. for a librarian",
    "Amazon fig.",
    "Library ID",
    "Amazon.com ID",
    "Publisher's ID",
    "Info on the reverse of a title page: Abbr.",
    "13-digit library info",
    "Library ID",
    "Library ID",
    "ID in a library vol.",
    "Novel ID",
    "Publisher's 13-digit ID",
    "Amazon.com ID",
    "978-0060935443, for Roget's Thesaurus",
    "ID on a dust jacket",
    "0-198-61186-2, e.g.",
    "Library ID",
    "Novel figure: Abbr.",
    "Card catalog abbr.",
    "Bibliophile's citation, for short",
    "Library cataloging datum, briefly",
    "Library ID",
    "ID in a library",
    "Copyright page info: Abbr.",
    "Novel ID"
  ],
  "nerf": [
    "Material for toy darts",
    "Super Soaker Soakzooka brand",
    "Soft, squishy material",
    "___ ball",
    "Kind of ball that's supersoft",
    "Brand of foam darts",
    "Game ball material",
    "Kind of ball for indoor play",
    "___ war (conflict unlikely to hurt anyone)",
    "___ ball",
    "Spongy toy material",
    "Harmless weapons maker",
    "Soft ball material",
    "Ball brand",
    "___ Blaster (toy gun)",
    "Abhors material for making toys",
    "Big name in foam-based weaponry",
    "Toy brand with soft sales?",
    "Spongy toy brand",
    "Soft ball",
    "Nonlethal ammo brand",
    "Gun brand not endorsed by the 111-Down",
    "Hasbro brand",
    "Hasbro brand",
    "Soft ball material",
    "Super Soaker brand",
    "Soft ball material",
    "Soft ball brand",
    "Big name in balls",
    "Soft ball brand",
    "Kind of gun",
    "Soft ball brand",
    "Brand of 45-Down balls",
    "Hasbro product",
    "___ ball",
    "Maker of the game Dart Tag",
    "___ ball (spongy plaything)",
    "Soft ball material",
    "Ball material",
    "___ ball",
    "Soft ball material",
    "Soft ball material",
    "Foam toy brand",
    "Soft ball brand",
    "Toy label launched in 1969",
    "Soft ball material",
    "Toy ball material",
    "Kind of football",
    "Kid's ball material",
    "Soft ball material",
    "Kid's ball material",
    "Toy ball brand",
    "Toy ball material",
    "Kids' indoor ball material",
    "___ball (popular toy)",
    "Spongy toy material"
  ],
  "cure": [
    "Prepare, as prosciutto",
    "Snake oil, purportedly",
    "Preserve, in a way",
    "Medical breakthrough",
    "Medical breakthrough",
    "Antidote",
    "Goal of cancer research",
    "Medical breakthrough",
    "Add salt to, maybe",
    "Medical research goal",
    "Quinine, for malaria",
    "Antidote",
    "Successfully treat",
    "Smoke or salt",
    "Purpose of many a shot",
    "Medical breakthrough",
    "___-all",
    "Medical success",
    "Fix",
    "Smoke, e.g.",
    "Salt or smoke",
    "Bug zapper?",
    "Make perfect again",
    "Doctor",
    "Solution",
    "Smoke or salt, maybe",
    "Medical breakthrough",
    "Medical discovery",
    "Pickle",
    "Medical breakthrough",
    "Relief",
    "Fix",
    "Quinine, for malaria",
    "Make jerky",
    "Treat successfully",
    "Pickle",
    "Smoke, perhaps",
    "Salt, perhaps",
    "Snake oil, purportedly",
    "Make jerky?",
    "Remedy",
    "Remedy",
    "Parish V.I.P.",
    "Medical researcher's goal",
    "Fix"
  ],
  "iams": [
    "Brand with a paw print in its logo",
    "Alpo competitor",
    "Brand with a paw print in its logo",
    "Purina alternative",
    "Purina competitor",
    "Alpo alternative",
    "Alpo alternative",
    "Alpo alternative",
    "Pet food brand",
    "Pet food with a paw print logo",
    "Big name in pet food",
    "Alternative to Beneful",
    "Dog food brand",
    "Maker of Healthy Naturals food",
    "Alpo alternative",
    "Pedigree alternative",
    "Brand with a paw print in its logo",
    "Big name in pet food",
    "Pet food brand",
    "Pedigree alternative",
    "Pet food company since 1946",
    "Big name in pet food",
    "Pet food brand",
    "Pet food brand",
    "Dog food brand",
    "Big name in pet foods",
    "Big pet food brand",
    "Nutritionist Paul who founded a pet food company",
    "Paul of pet food",
    "\"Good for life\" sloganeer",
    "Alpo alternative"
  ],
  "said": [
    "Made explicit, in a way",
    "Uttered",
    "Spoken",
    "Didn't just hint at",
    "Word after \"he\" and \"she\"",
    "Claimed",
    "Spit out",
    "Expressed",
    "Word after he or she",
    "Put into words",
    "Reputed",
    "Egypt's Port ___",
    "Put into words",
    "\"You ___ it, brother!\"",
    "\"That's what she ___\"",
    "Uttered",
    "\"He\" and \"she\" follower",
    "Stated",
    "Aforementioned",
    "Port ___",
    "Verbalized",
    "Announced",
    "Aforementioned",
    "Came out with",
    "Related",
    "\"That ___ ...\"",
    "Uttered",
    "Put into words",
    "Port ___ (Suez Canal city)",
    "Word after he and she",
    "Mentioned",
    "Remarked",
    "Not tacit",
    "Came out with",
    "Aforementioned",
    "Legalistic adjective",
    "Came out with",
    "Expressed",
    "Uttered",
    "Port of Egypt",
    "The Shirelles' \"Mama ___\"",
    "\"He\" and \"she\" follower",
    "Named before",
    "Stated",
    "Aforementioned",
    "Port ___, Egypt",
    "Port___",
    "Mentioned",
    "Aforementioned"
  ],
  "hike": [
    "Snap back?",
    "Sharp increase",
    "Walk in the woods",
    "Jack up",
    "Take a walk on the wild side?",
    "Go backpacking",
    "Trail activity",
    "Up",
    "It's a snap",
    "Shout after a series of numbers",
    "Gridiron cry",
    "Trek",
    "Recreational walk",
    "Cry after a series of numbers",
    "Shout before a snap",
    "Bring up",
    "Trek",
    "Scouting outing",
    "Explore Yosemite, perhaps",
    "QB's cry after a string of numbers",
    "Raise",
    "Go backpacking",
    "Scout outing",
    "Increase",
    "Word just before a snap",
    "Long walk",
    "Nature walk",
    "Lift",
    "Hoof it",
    "Lift",
    "Scouting outing",
    "It might follow \"one, two, three\"",
    "Take to the trail",
    "Go backpacking",
    "Raise",
    "It's a snap",
    "Go on a nature walk",
    "Quarterback's command",
    "Quarterback's bark",
    "Nature outing",
    "Snap request?"
  ],
  "lout": [
    "Boor",
    "Hooligan",
    "Oaf",
    "Lug",
    "Lummox",
    "Uncouth fellow",
    "Lummox",
    "Vulgarian",
    "Vulgarian",
    "Boor",
    "Lummox",
    "Oaf",
    "Boorish sort",
    "Brutish sort",
    "Yahoo",
    "Yahoo",
    "Vulgarian",
    "Lumpkin",
    "Palooka",
    "Yahoo",
    "Lummox",
    "Boor",
    "Boorish sort",
    "Oaf",
    "Lug",
    "Oaf",
    "Boor",
    "Yahoo",
    "Lug",
    "Boor",
    "Stumblebum",
    "Lummox",
    "Boor",
    "Dolt",
    "Oaf",
    "Ill-mannered one",
    "Boor",
    "Boor",
    "Palooka",
    "Galoot",
    "Oaf"
  ],
  "vale": [
    "Glen or dale",
    "View from a highland",
    "Glen",
    "Area between mountains",
    "Setting for peak viewing?",
    "Lowland, poetically",
    "Area between mountains",
    "Land between hills, poetically",
    "Low ground, poetically",
    "Low-lying area",
    "Low-lying area",
    "Hollow between hills",
    "Goodbye, in old Rome",
    "Low-lying region",
    "A river might run through it",
    "Low point",
    "Low-lying area",
    "Low-lying area",
    "Lowland",
    "Place between hills",
    "Lowland",
    "Low-lying area",
    "Where a stream may run",
    "Sheltered spot",
    "Hollow between hills",
    "Low-lying land",
    "Greece's ___ of Tempe",
    "Formal farewell",
    "Forum farewell",
    "Farewell, to Cicero",
    "Caesar's farewell",
    "Stream spot",
    "Low tract",
    "Caesar's farewell",
    "This ___ of tears (life)",
    "___of tears",
    "Lowland",
    "This ___ of tears",
    "___ of tears",
    "Goodbye, to Gaius",
    "Singer Jerry"
  ],
  "sped": [
    "Exceeded the legal limit",
    "Catalyzed, with \"up\"",
    "Whizzed (by)",
    "Went fast",
    "Flew",
    "Went over the limit, say",
    "Zipped",
    "Tore",
    "Dashed",
    "Went over the limit, say",
    "Zipped",
    "Tore",
    "Put the pedal to the metal",
    "Raced",
    "Earned a ticket, maybe",
    "Zoomed",
    "Zipped along",
    "Hit 100, say",
    "Put the pedal to the metal",
    "Fast-forwarded, with \"up\"",
    "Risked a ticket",
    "Burned rubber",
    "Went 90, say",
    "Hastened",
    "Was reckless, in a way",
    "Tore",
    "Tore",
    "Exceeded a limit",
    "Did 80 on the highway, say",
    "Earned a citation, maybe",
    "Hit 90, e.g.",
    "Ran",
    "Went like lightning",
    "Sprinted",
    "Hightailed it",
    "Barreled",
    "Reached 100, say",
    "Tore",
    "Floored it",
    "Did 80, say",
    "Went 80 or 90, say",
    "Hied",
    "Wasted no time",
    "Went 90, say",
    "Tore",
    "Tore",
    "Went flat-out",
    "Rushed",
    "Lost no time",
    "Made haste",
    "Whizzed",
    "Tore",
    "Whipped along",
    "Floored it",
    "Hurried",
    "Floored it",
    "Went like the dickens",
    "Hightailed it",
    "Earned a citation?",
    "Careered",
    "Hightailed it",
    "Floored it",
    "Tore",
    "Shot (off)",
    "Hightailed it",
    "Blew (through)",
    "Went fast",
    "Tore",
    "Hotfooted it",
    "Went like the wind",
    "Floored it",
    "Floored it",
    "Zipped",
    "Hightailed it",
    "Cannonballed",
    "Careered",
    "Earned a citation?",
    "Hightailed it",
    "Made tracks",
    "Lost no time",
    "Tore",
    "Tempted a trooper",
    "Hotfooted it",
    "Didn't dillydally",
    "Wasted no time",
    "Risked getting ticketed",
    "Zipped",
    "Risked a ticket",
    "Tore",
    "Went over the limit",
    "Went like a leadfoot",
    "Tempted a traffic cop",
    "Went 80, say",
    "Went like the dickens",
    "Hightailed it",
    "Scurried",
    "Scooted",
    "Tore",
    "Flew",
    "Floored it",
    "Went like the dickens",
    "Made tracks",
    "Raced",
    "Put the pedal to the metal",
    "Hotfooted it",
    "Exceeded the limit",
    "Scurried",
    "Earned a citation?",
    "Moved rapidly",
    "Shot, in a way",
    "Went 80 m.p.h.",
    "Tore (off)",
    "Did 100",
    "Hightailed it",
    "Raced",
    "Zoomed",
    "Hotfooted it",
    "Hightailed it",
    "Put the pedal to the metal",
    "Rushed",
    "Scurried",
    "Made tracks",
    "Raced",
    "Made haste",
    "Hightailed it",
    "Tore",
    "Raced",
    "Rushed",
    "Raced",
    "Exceeded the limit",
    "Dashed",
    "Invited a ticket?",
    "Expedited"
  ],
  "zeno": [
    "Philosopher known for his paradoxes",
    "Greek philosopher known for paradoxes",
    "One of his paradoxes claims that two objects can never really touch",
    "Figure in Plato's \"Parmenides\"",
    "Founder of Stoicism",
    "Aristotle called him the inventor of the dialectic",
    "Greek philosopher of paradox fame",
    "Greek philosopher known for paradoxes",
    "Paradoxical figure?",
    "Paradoxical Greek",
    "Paradoxical one",
    "Greek philosopher known for paradoxes",
    "Paradoxical fellow",
    "Greek philosopher who founded Stoicism",
    "Greek philosopher known for a paradox",
    "Philosopher known as \"the Stoic\"",
    "Stoicism founder",
    "Philosopher for whom a paradox is named",
    "Paradoxical Greek",
    "Early dialectician",
    "Paradoxical Greek",
    "Paradoxical Greek",
    "He claimed motion was illusionary",
    "Paradoxical fellow",
    "Founder of Stoicism",
    "Original Stoic",
    "Greek philosopher of paradox fame",
    "Paradoxical Greek",
    "Paradoxical philosopher",
    "Founder of the Stoics"
  ],
  "home": [
    "Smartphone button",
    "Browser button",
    "With 69-Across, advantage in sports",
    "Ithaca, to Odysseus",
    "Base after third base",
    "Catcher's place",
    "There's no place like it ... or a word that can precede either half of the answer to each starred clue",
    "\"Where the heart is\"",
    "With 18- and 19-Across, classic song that starts \"'Mid pleasures and palaces though we may roam\"",
    "See 17-Across",
    "There's no place like it",
    "There's no place like it, it's said",
    "See 55-Down",
    "Place for many an office",
    "Augusta National Golf Club, for the Masters",
    "Pentagonal plate",
    "Terse order to a chauffeur",
    "Typist's position",
    "It follows first, second and third",
    "Instruction to a chauffeur",
    "Part of a diamond",
    "___ office",
    "Common Web site link",
    "Terse directive to a chauffeur",
    "\"If a ___ is happy, it cannot fit too close\": O. Henry",
    "Where the heart is, they say",
    "Kind of cooking",
    "Word with run or rule",
    "Chauffeur's order",
    "\"Where the heart is\"",
    "Main Web page",
    "Part of HBO",
    "Directive to a chauffeur",
    "Web browser button",
    "Where the heart is",
    "Directive to James",
    "\"Where the heart is\"",
    "It's where the heart is",
    "Order to a chauffeur",
    "___ free",
    "How some things strike",
    "Opening Web site page",
    "\"Where the heart is\"",
    "\"Fourth base\"",
    "Site of scores of baseball players?",
    "\"___, James!\"",
    "Catcher's base",
    "One of two teams",
    "\"Fourth base\""
  ],
  "seem": [
    "Appear",
    "Appear",
    "Come across as",
    "Appear",
    "Appear",
    "Appear",
    "Feel",
    "Appear",
    "Appear",
    "Appear to be",
    "Appear",
    "Appear",
    "Appear to be",
    "\"So it may ___\"",
    "Appear to be",
    "Appear to be",
    "Appear (to be)",
    "Look like",
    "Appear",
    "Feel",
    "Appear",
    "Look",
    "Appear",
    "Appear",
    "Feel like",
    "Look",
    "Give the appearance of",
    "Appear",
    "Look",
    "Feel",
    "Appear",
    "Look",
    "Look",
    "Appear",
    "Appear",
    "Appear",
    "Look",
    "Appear to be",
    "Feel like",
    "Come across as",
    "Appear to be",
    "Come across as",
    "\"... bad as they ___\"",
    "Look to be",
    "Come across as",
    "Look",
    "Appear to be",
    "Sound",
    "Look",
    "Appear to be",
    "Come across as",
    "Look",
    "Come across as",
    "Appear",
    "Come across as",
    "Appear to be",
    "Show on the face of it",
    "Appear",
    "Look",
    "Appear",
    "Look",
    "Appear to be",
    "Be, apparently",
    "Look",
    "\"I ___ to recall...\"",
    "Appear",
    "Appear",
    "Come across as",
    "Appear",
    "Look",
    "Appear",
    "\"...not always what they ___\"",
    "Strike one as",
    "Appear",
    "Come across as",
    "Appear",
    "Look",
    "Give the impression",
    "Appear",
    "Look like",
    "Look",
    "Appear",
    "Look",
    "Appear",
    "Look to be"
  ],
  "lord": [
    "Tennyson, for one",
    "Member of Britain's upper house",
    "___ Speaker (Parliament title)",
    "Title for Voldemort",
    "Head of an estate",
    "Feudal figure",
    "The Almighty",
    "Next-to-last word of grace, often",
    "Title for Tennyson",
    "Word before \"have mercy!\"",
    "Almighty",
    "Feudal V.I.P.",
    "Title for Byron or Baltimore",
    "One \"a-leaping\" in a Christmas song",
    "\"___, is it I?\"",
    "One who minds his manors?",
    "Whom a thane attended",
    "___ of Darkness",
    "Lady's man",
    "Prayer opener",
    "Manor man",
    "Follower of \"O\"",
    "Serf's oppressor",
    "Prayer start",
    "He has manors",
    "Lady's man",
    "Prayer start",
    "Prayer addressee",
    "Lady's man",
    "House member",
    "Powerful person",
    "Lady's man",
    "\"___ of the Flies\"",
    "Real lady's man",
    "God",
    "Authority figure",
    "\"___ of the Flies\"",
    "Prayer opener",
    "Heavenly Father",
    "Byron or Tennyson",
    "Prayer receiver, with \"the\"",
    "Baron's title",
    "Surprised cry",
    "The Almighty",
    "Fiefdom owner",
    "The Almighty"
  ],
  "ewes": [
    "Dolly and her fellow clones, e.g.",
    "The first cloned mammals",
    "Bucolic beasts",
    "Suppliers of the milk for Roquefort cheese",
    "Some \"Babe\" characters",
    "Flock members",
    "Milk sources for feta cheese",
    "Some flock members",
    "Milk sources",
    "She sheep",
    "Members of a flock",
    "___ milk (source of Roquefort cheese)",
    "___ milk",
    "Members of a flock",
    "Ricotta sources",
    "___ milk",
    "Gimmers are young ones",
    "Females in wool",
    "Sources of Manchego cheese",
    "Merino mothers",
    "Much of a flock",
    "Feta sources",
    "___ milk",
    "Sources of ricotta cheese",
    "Bellwether's \"belles\"",
    "Lamb nursers",
    "Ovine mothers",
    "Some ovines",
    "Large part of some herds",
    "Rams' mates",
    "Lambs' mothers",
    "Meadow mamas",
    "Female Cotswolds",
    "Sources of feta and ricotta cheese",
    "Milk sources",
    "Farm females",
    "What shepherds may shepherd",
    "A lot of a flock",
    "Milk sources",
    "\"Baa-a-ad\" mothers?",
    "Milk providers",
    "Ovine mothers",
    "Much of a flock",
    "Mates for a 29-Across",
    "Milk sources",
    "Rams' madams?",
    "Barnyard females",
    "Things shepherds shepherd",
    "Flock females",
    "Providers of sheep's milk",
    "___ milk",
    "Half a flock, maybe",
    "Rams' mates",
    "___ milk",
    "Some livestock",
    "Lambs' kin",
    "Flock females",
    "Baa maids?",
    "Farm milk producers",
    "Meadow mamas",
    "Mas with baas",
    "Their milk is used to make Roquefort",
    "Lady bighorns",
    "Followers of a bellwether",
    "Wool coat wearers",
    "Females in the fold",
    "Lambs' ma'ams",
    "Some dairy stock",
    "Ones lambs follow",
    "Milk dispensers?",
    "Milk sources",
    "Pasture parents",
    "Flock members",
    "___ milk",
    "Lambs' mothers",
    "Wearers of wool coats",
    "They get fleeced",
    "Milk dispensers",
    "Cote inhabitants",
    "Wool sources",
    "Some livestock",
    "Farm females",
    "Rams' mates",
    "Wool sources",
    "Shropshire females",
    "Rams' mates",
    "She sheep",
    "Farm mothers",
    "Romano sources",
    "Rams' ma'ams",
    "Members of the flock",
    "They grow fat on the farm",
    "Parts of a herd",
    "Meadow group",
    "Lady bighorns",
    "Dolly and others",
    "Flock members",
    "Some sheep",
    "Rams' ma'ams",
    "She sheep",
    "Flock members",
    "Frequenters of 49-Across",
    "Farm females",
    "Baa-maids?",
    "Farm females",
    "Rams' mates",
    "Ones welcomed to the fold?",
    "Lambies' mammies",
    "Shropshire mothers",
    "Rams' dams",
    "Farm mothers",
    "Farm mothers",
    "Sheepcote matriarchs"
  ],
  "egos": [
    "Big personalities",
    "Freudian topics",
    "Parts of psyches",
    "Teamwork disrupters",
    "Clash of the titans?",
    "What coaches on star-laden teams must handle",
    "Swelled heads",
    "They may be set aside in a collaboration",
    "Common sources of conflict",
    "Things that sometimes clash in orchestras?",
    "Headaches for talent agents",
    "Power-hungry people have big ones",
    "Self-identities",
    "Many stars have big ones",
    "Things coaches handle",
    "Me, myself and I",
    "Problems that a group project might face",
    "Rational selves, to Freud",
    "Things bigheaded people have",
    "Big things in D.C. and Hollywood",
    "Obstacles to teamwork",
    "What team leaders must frequently manage",
    "Big features of reality TV",
    "Big things for megalomaniacs",
    "Bigwigs may have big ones",
    "Traits that clash",
    "Big heads",
    "Things that clash in Washington",
    "Parts of psyches",
    "Causes of bigheadedness",
    "They may clash in a theater",
    "They can be inflated or shattered",
    "Inflated senses of self",
    "Stars may have big ones",
    "I's",
    "Psychic mediators",
    "Divas have big ones",
    "Psych 101 subjects",
    "They benefit from boosters",
    "They're big among the stars",
    "Freudian subjects",
    "They can be massaged or inflated",
    "\"Super\" parts of the psyche",
    "Alphas might clash over them",
    "Vanity cases?",
    "Swelled heads",
    "Big things on Capitol Hill",
    "They're big on Wall Street",
    "Swelled heads",
    "Big heads",
    "Self centers",
    "Impediments to teamwork",
    "They may get in the way of an agreement",
    "Swelled heads",
    "Nice things to be massaged",
    "Some are fragile",
    "Inflated things?",
    "Swelled heads",
    "Causes of some celebrity clashes",
    "Freudian mediators",
    "Hollywood has some big ones",
    "Hollywood clashers",
    "I's",
    "They may need boosting",
    "Prima donnas' features",
    "They're subject to inflation and deflation",
    "They may clash",
    "Big heads",
    "They may be deal breakers",
    "Big ones can impede progress",
    "Divas have sensitive ones",
    "Deal breakers, on occasion",
    "Things that modest people lack",
    "Teamwork thwarters",
    "Stars can have big ones",
    "I's",
    "Prima donnas have big ones",
    "Cause of star wars?",
    "They may clash",
    "Things inflated with hot air?",
    "They can be stroked or bruised",
    "Yes-men feed them",
    "Inflated heads",
    "Massage targets?",
    "They're subject to inflation",
    "Washington has some big ones",
    "What modest people lack",
    "Inflated selves",
    "They can be inflated",
    "Sources of hurt feelings",
    "Psyche components",
    "Occasional clashers",
    "They're big in Hollywood",
    "Self centers",
    "They may need massaging",
    "Things to stroke",
    "Selves",
    "They may be stroked",
    "Vanity cases?",
    "They may clash among titans",
    "I's",
    "Battle of ___",
    "They're sometimes inflated",
    "Bigheads",
    "They may be involved in shouting matches",
    "Bruisable things",
    "They're big on Wall Street",
    "Selves",
    "Garry Trudeau's \"Check Your ___ at the Door\"",
    "Some are inflated",
    "People with big heads have them",
    "They can be inflated",
    "Divas have them",
    "I problems?",
    "They might clash",
    "Prima donna problems",
    "Modest people have small ones",
    "They may be hurt by falls",
    "They may be massaged",
    "They're big in Hollywood",
    "Inflatable things",
    "Freudian topics",
    "Conceits",
    "They can get in the way of a deal",
    "They're inflatable",
    "Self-involved ones",
    "They may be boosted",
    "Freud's I's",
    "Self-images",
    "Capital I's?",
    "They may be crushed",
    "I's",
    "Swelled heads",
    "Prima donna problems",
    "Clashing figures?",
    "Inflatable things",
    "The I's have them",
    "They may clash in business",
    "Subjects of clashes",
    "They're big in boardrooms",
    "Hindrances to settlements",
    "\"I\" problems",
    "They're inflatable",
    "Minds' I's?",
    "They get in the way of sound thinking",
    "They give people big heads",
    "They can be bruised",
    "Subjects of psychoanalysis",
    "Stars have big ones",
    "Freudian subjects",
    "Boosted items",
    "Clashing forces?",
    "Hollywood giants?",
    "Freudian interests",
    "Freudian topics",
    "They may clash on the set",
    "Conceits",
    "Causes of personality clashes",
    "Self-important people, colloquially",
    "Some are super",
    "Selves",
    "They're easily bruised",
    "Swelled heads",
    "Sometimes they're super",
    "They're big for conceited folks",
    "Selves",
    "Selves",
    "Conceits",
    "Selves",
    "Inflatable items",
    "Vanities",
    "Selves"
  ],
  "rent": [
    "Cancel ___ (tenant rights movement)",
    "Flat fee?",
    "It's $550 for 17-Across/ 8-Down with a hotel on it",
    "Counterpart of own",
    "Figure in home economics?",
    "Let (out)",
    "\"La Vie Boheme\" musical",
    "It may be high for a penthouse",
    "Landlord's due",
    "Landlord's income",
    "Hit musical set in 1990s New York",
    "Quote from a letter",
    "Let",
    "Dollars for quarters",
    "Torn",
    "$2 for Mediterranean Avenue, in Monopoly",
    "$250, for Mediterranean Avenue, even with a hotel on it",
    "Let",
    "$2,000, if you land on Boardwalk with a hotel",
    "Big figure in Manhattan?",
    "Let",
    "Studio figure",
    "Payment for tenancy",
    "Monopoly expense",
    "Tony-winning musical that begins and ends on Christmas Eve",
    "Apartment dweller's payment",
    "The check that's in the mail, maybe",
    "Broadway musical based on \"La Boheme\"",
    "Money due in Monopoly",
    "Musical with the songs \"Santa Fe\" and \"I Should Tell You\"",
    "$2,000 for Boardwalk, with a hotel",
    "Something rising in a gentrifying neighborhood",
    "Torn",
    "$2, for Mediterranean Avenue",
    "Studio fee",
    "Some overhead",
    "Monthly budget item, for some",
    "107-Down subject",
    "Full of tears",
    "Landlord's income",
    "Having a gaping hole, say",
    "Ripped",
    "Not buy, say",
    "$2 to $2,000, in Monopoly",
    "*Torn",
    "Torn",
    "Need to keep one's place?",
    "Musical with the song \"Seasons of Love\"",
    "Landlord's due",
    "The ___ Is Too Damn High Party",
    "Monopoly payment",
    "Check for a landlady",
    "Landlord's due",
    "It may be stabilized",
    "Landlord's check",
    "Cost of living?",
    "$50 for Boardwalk, in Monopoly",
    "Ripped",
    "Get a flat",
    "Apartment dweller's payment",
    "Digs cash?",
    "Payment in Monopoly",
    "Landlord's check",
    "Split",
    "\"One Song Glory\" musical",
    "Monopoly payment",
    "Broadway musical with the song \"Will I?\"",
    "Monopoly expense",
    "Check for letters",
    "Torn",
    "*Torn",
    "Payment in Monopoly",
    "Check for a landlord",
    "Payment in Monopoly",
    "Check for a place to stay",
    "Torn",
    "It helps one keep one's place",
    "Torn",
    "What homeowners don't have to pay",
    "Lease",
    "Monthly check",
    "Tenant's monthly check",
    "Best Musical of 1996",
    "Monthly money",
    "Let out",
    "Flat payment",
    "Flat rate?",
    "Landlord's due",
    "Letter amount",
    "Monthly payment",
    "Monthly expense",
    "Not own",
    "Patronize Hertz or Avis",
    "Apartment payment",
    "It's overhead",
    "It's usually due on the first of the month",
    "It'll allow you to keep your place",
    "Cost of living?",
    "It may be due on a duplex",
    "Not own, say",
    "Monthly bill, for many",
    "Cost of living?",
    "Let out",
    "It's $24 on Marvin Gardens",
    "Charter",
    "$50 Boardwalk outlay",
    "Landlord's due",
    "Where a big chunk of a paycheck may go",
    "Let",
    "Monthly budget item",
    "Monopoly payment",
    "Let",
    "Let",
    "Monthly expense",
    "'96 Tony winner for Best Musical",
    "\"La Vie Boheme\" musical",
    "Let",
    "Flat rate",
    "Ripped",
    "Let out",
    "Monthly budget item",
    "Let out",
    "Split",
    "Big part of many a family budget",
    "Jonathan Larson musical",
    "Monthly money",
    "1996 Tony winner",
    "Monthly check",
    "Big tear",
    "\"La Boheme,\" updated",
    "Musical based on \"La Boheme\"",
    "Let",
    "Torn",
    "1996 Broadway hit",
    "Cost of occupation",
    "It may be due on a duplex",
    "Not own",
    "1996 Tony musical",
    "Flat rate?",
    "Landlord's due",
    "1996 Tony-winning musical",
    "Schism",
    "Monthly due",
    "Bed check?",
    "Split",
    "Use U-Haul, e.g.",
    "Let",
    "Tenant's burden",
    "Flat bread?",
    "Monthly check",
    "Budget item",
    "\"___-a-Cop\" (Burt Reynolds flick)",
    "Lease",
    "Split",
    "Lease",
    "Overhead expense",
    "Flat rate",
    "Monthly due",
    "Take money for a spare room",
    "Torn",
    "Flat amount?",
    "Patronize Avis",
    "Flat payment?"
  ],
  "maya": [
    "___ Harris, sister and campaign chair of Kamala",
    "Rudolph on \"S.N.L.\"",
    "Ancient pyramid builders",
    "Six-time W.N.B.A. All-Star Moore",
    "___ Rudolph, portrayer of Kamala Harris on \"S.N.L.\"",
    "Rudolph with a parody of Kamala Harris on \"S.N.L.\"",
    "Moore whom Sports Illustrated called the \"greatest winner in the history of women's basketball\"",
    "Comic actress Rudolph",
    "Rudolph formerly of \"S.N.L.\"",
    "Architect Lin",
    "Itzamna worshipers",
    "People encountered by the conquistadors",
    "Ancient Central American",
    "Architect Lin",
    "Belize native",
    "Pre-Columbian civilization",
    "Rudolph of \"S.N.L.\"",
    "Poet Angelou",
    "Certain pyramid builders",
    "\"Apocalypto\" subject",
    "Builders at Uxmal",
    "Early Yucatan people",
    "Ancient pyramid builders",
    "Yucatan native",
    "Palenque native",
    "Early Yucatan dweller",
    "Poet Angelou",
    "Central American Indian",
    "Language of Mexico",
    "Guatemala natives",
    "Chichen Itza resident",
    "Poet Angelou",
    "Ancient Mexican",
    "Writer Angelou"
  ],
  "revs": [
    "Goes \"vroom vroom\"",
    "Cranks (up)",
    "Races in place",
    "Guns, as an engine",
    "Fires (up)",
    "Guns",
    "Guns",
    "Short spins?",
    "Amps (up)",
    "Intensifies, with \"up\"",
    "Turns briefly?",
    "Races before a race?",
    "Supercharges, with \"up\"",
    "Guns, as an engine",
    "Quick spins?",
    "Guns, as an engine",
    "Speeds (up)",
    "Powers (up)",
    "Prepares for dragging",
    "Makes roar",
    "Charges (up)",
    "Amps up",
    "Guns",
    "Tach figure, informally",
    "Guns, as an engine",
    "Amps up",
    "Wedding parties?: Abbr.",
    "Charges (up)",
    "Guns, as a motor",
    "Guns, as an engine",
    "Guns",
    "Guns",
    "Speeds up",
    "Relig. leaders",
    "Speeds (up)",
    "Engine speed, for short",
    "Guns",
    "Rotations, in garages",
    "Guns",
    "Guns, in a way",
    "Guns, as an engine",
    "Opposite of idles",
    "Guns",
    "Races, as a motor",
    "Guns",
    "Guns",
    "Guns"
  ],
  "asap": [
    "\"Stat!\"",
    "\"Like ... now!\"",
    "\"Stat!\"",
    "Inits. one might hear at the office",
    "Letters of urgency",
    "\"Hurry!\"",
    "Shortly, for short",
    "\"This minute!\"",
    "\"Do it now!\"",
    "\"Immediately!\"",
    "Urgent order",
    "\"By yesterday!\"",
    "\"Now!\"",
    "Rush order",
    "\"Chop-chop!\"",
    "\"Now!,\" in an order",
    "\"Stat!\"",
    "\"Stat!\"",
    "\"Now!\"",
    "Letters on a rush order",
    "Pronto",
    "\"Chop-chop!\"",
    "\"Stat\"",
    "On the double",
    "\"Stat!\"",
    "\"Hurry!,\" on an order",
    "\"Quickly!\"",
    "Stat",
    "\"Stat!\"",
    "\"Stat!\"",
    "Job order",
    "\"Right away!\"",
    "\"Now!\"",
    "\"Stat!\"",
    "Express letters?",
    "Urgent letters",
    "\"Rush!\"",
    "\"Stat\"",
    "Rush order",
    "\"Now!\"",
    "\"Hurry!\"",
    "Rush-rush",
    "Letters meaning \"Make it snappy!\"",
    "[Can't wait!]",
    "\"Right now!\"",
    "\"On the double!\"",
    "\"Right away!\"",
    "Memo directive",
    "\"Get cracking!\"",
    "Forthwith, on a memo",
    "\"Pronto!\"",
    "\"Stat!\"",
    "\"Make it snappy!\"",
    "\"On the double!\"",
    "Straightaway",
    "\"This can't wait!\"",
    "Stat",
    "[I need this immediately]",
    "Yesterday, so to speak",
    "\"Immediately!\"",
    "\"Now!\"",
    "\"Quickly!,\" in an order",
    "Immediately, on an order",
    "Pronto",
    "\"Now!\"",
    "Very quickly",
    "When some tasks must be done",
    "Pronto",
    "Memo letters",
    "Common reply to \"When do you want this?\"",
    "\"Quickly!\"",
    "Rush order",
    "\"Now!,\" on an order",
    "Chop-chop",
    "\"Immediately!\"",
    "\"Must be done NOW!\"",
    "When to do a pressing job",
    "Request under deadline",
    "\"Hurry!\"",
    "Quickly, quickly",
    "Inits. on a rush order",
    "\"It can't wait!\"",
    "\"Hurry!,\" on an order",
    "By yesterday, so to speak",
    "\"Make it snappy,\" on an order",
    "\"Now!\"",
    "Urgently",
    "Lickety-split, in a memo",
    "\"Hurry!\"",
    "Order to get moving",
    "Immediately, on a memo",
    "\"Chop-chop!\"",
    "\"Now!\"",
    "\"This is a priority!\"",
    "Chop-chop",
    "\"Stat!\"",
    "\"Now!,\" in a memo",
    "\"Now!\"",
    "Before all else",
    "P.D.Q.",
    "\"And make it snappy!\"",
    "P.D.Q.",
    "\"Quickly!,\" on an order",
    "Quick-like",
    "\"Now!\"",
    "\"Rush!,\" on an order",
    "\"On the double!\"",
    "With all haste",
    "Express letters?",
    "\"Chop-chop!,\" on a memo",
    "\"Quickly!,\" on an order",
    "\"Rush!\"",
    "\"Pronto!\"",
    "Stat",
    "\"Stat!\"",
    "\"Hurry!\"",
    "Pronto",
    "Dreaded letters for a procrastinator?",
    "\"Without delay\"",
    "\"Quickly,\" quickly",
    "Right away",
    "\"Rush!\" on an order",
    "\"I needed it by yesterday!\"",
    "Without dawdling",
    "\"Hurry!\"",
    "\"Hurry!\"",
    "\"The faster the better\"",
    "Order letters",
    "\"Pronto\"",
    "Request for special attention",
    "Quick request?",
    "P.D.Q.",
    "P.D.Q.",
    "Hurry-up order",
    "Pronto, on memos",
    "Order ender",
    "Quickly, in memos",
    "Rush!",
    "Quickly, in memos",
    "\"Quickly!\"",
    "P.D.Q.",
    "Quickly, quickly",
    "Quickly, briefly",
    "\"Quickly!\"",
    "\"Rush!\" order",
    "Pronto",
    "\"Rush!\"",
    "\"Quickly!\"",
    "Pronto!",
    "\"On the double!\"",
    "\"Pronto!\"",
    "\"Hurry!\"",
    "\"Rush!\"",
    "Order heading",
    "\"Quick!\"",
    "Order request",
    "Express letters?",
    "In very short order",
    "P.D.Q.",
    "\"Stat\"",
    "Letters akin to P.D.Q.",
    "Stat",
    "P.D.Q.",
    "P.D.Q.",
    "Rush order?",
    "\"Now!\"",
    "Stamp on an order",
    "Pronto",
    "Promptness prompter",
    "Pronto",
    "Urgent notation",
    "Request to a messenger",
    "\"Urgent!\"",
    "\"Make it snappy!\"",
    "Right away, on a memo",
    "\"By yesterday!\"",
    "\"Pronto!\"",
    "Order request",
    "Job order notation",
    "\"Hurry!\"",
    "\"Immediately!\"",
    "Rush job notation",
    "\"Now!\"",
    "Request on an order",
    "\"Hurry, please!\"",
    "\"By yesterday!\"",
    "P.D.Q.",
    "Express letters",
    "\"Make it quick!\"",
    "Exec's request",
    "Rush letters?",
    "\"...and make it snappy!\"",
    "Message on a work order",
    "\"Pronto!\"",
    "\"Stat!\"",
    "\"Pronto!\"",
    "Pronto",
    "In a hurry, for short",
    "Order on an order",
    "\"Don't dawdle!\"",
    "\"I needed it yesterday\" abbr.",
    "P.D.Q., updated",
    "Letters of urgency",
    "Quickly, quickly",
    "Chop-chop",
    "Quickly, in memos",
    "Rush job notation",
    "\"Immediately!\"",
    "How the boss wants things done, briefly",
    "Posthaste",
    "\"Stat!\"",
    "\"Rush!\"",
    "\"... and make it snappy!\"",
    "Pronto!",
    "Memo order",
    "Pronto",
    "\"Make it snappy!\"",
    "\"Now!\"",
    "P.D.Q.",
    "Very shortly, shortly",
    "\"Without delay\"",
    "Pronto!",
    "Pronto",
    "Now!",
    "Posthaste",
    "Without delay",
    "\"Hurry!\"",
    "Pronto",
    "Pronto",
    "\"Hurry!\"",
    "Pronto",
    "Pronto",
    "Quickly, quickly",
    "Memo sign-off",
    "P.D.Q.",
    "Quickly: Abbr.",
    "\"Rush!\"",
    "P.D.Q.",
    "Chop-chop",
    "Immediately"
  ],
  "ivan": [
    "First czar of Russia",
    "___ the Terrible",
    "___ Drago (\"Rocky IV\" villain)",
    "Any of several Russian czars",
    "The fourth one was \"terrible\"",
    "___ Drago, foe of Rocky Balboa",
    "Nobelist Pavlov",
    "\"Terrible\" Russian despot",
    "___ Drago, opponent of Rocky in \"Rocky IV\"",
    "Turgenev who wrote \"Fathers and Sons\"",
    "Man's name that spells a fictional people backward",
    "John, abroad",
    "\"Terrible\" czar",
    "___ the Terrible",
    "With 21-Across, U.S. Open champ of 1985-87",
    "Nobelist Pavlov",
    "Boxer Drago of \"Rocky IV\"",
    "Pavlov with a Nobel",
    "The middle Karamazov brother",
    "___ the Terrible",
    "Scientist Pavlov",
    "Novelist Turgenev",
    "\"Terrible\" Russian autocrat",
    "Trading insider Boesky",
    "Winnipeg's ___ Franko Museum",
    "Author Turgenev",
    "___ the Terrible",
    "Tolstoy character ___ Ilyich",
    "Tennis great Lendl",
    "\"Terrible\" czar",
    "Scientist Pavlov",
    "The Terrible ___",
    "Director Reitman",
    "Novelist Turgenev",
    "Nobelist Pavlov",
    "\"Rocky IV\" villain ___ Drago",
    "Lendl of tennis",
    "Tolstoy's \"The Death of ___ Ilyich\"",
    "\"Terrible\" czar",
    "___ the Great, leader of 1462-1505",
    "Dr. Pavlov",
    "___ the Terrible",
    "Nobelist Pavlov",
    "___ the Great",
    "One of the Brothers Karamazov",
    "___ Drago, \"Rocky IV\" villain",
    "John, in Russia",
    "Director Reitman",
    "Memorable 2004 hurricane",
    "Author Turgenev",
    "One of six Russian rulers",
    "___ the Terrible",
    "\"Terrible\" czar",
    "As a czar, he was terrible",
    "Dr. Pavlov",
    "Soviet Literature Nobelist Bunin",
    "Pavlov of Pavlov's dogs fame",
    "Destructive 2004 hurricane",
    "Memorable hurricane of 2004",
    "\"Oblomov\" novelist Goncharov",
    "\"A Life for the Tsar\" hero Susanin",
    "Director Reitman",
    "Novelist Turgenev",
    "Former Wall Street insider Boesky",
    "Film director Reitman",
    "___ the Terrible",
    "Terrible man?",
    "Tennis great Lendl",
    "Author Turgenev",
    "One of Dostoevsky's \"Brothers Karamazov\"",
    "\"Terrible\" czar",
    "Tennis great Lendl",
    "Catcher Rodriguez",
    "Turgenev or Boesky",
    "Author Turgenev",
    "Tennis great Lendl",
    "Nobelist Pavlov",
    "First czar of Russia",
    "One of several czars",
    "Tolstoy's ___ Ilyich",
    "Lead role in Rimsky-Korsakov's \"The Maid of Pskov\"",
    "Bizet opera title character",
    "\"Terrible\" one",
    "Russian Everyman",
    "A \"terrible\" 1-Across",
    "Writer Turgenev",
    "John, abroad",
    "Dr. Pavlov",
    "\"Terrible\" czar",
    "Physiologist Pavlov",
    "\"Terrible\" czar",
    "Wall Streeter Boesky",
    "Tolstoy hero",
    "\"Terrible\" czar",
    "\"Terrible\" czar",
    "Author Turgenev",
    "Lendl of tennis",
    "Wall Street's Boesky",
    "Tolstoy hero",
    "Tennis great Lendl",
    "Nobelist Pavlov",
    "Terrible czar",
    "Russian \"John\"",
    "Czar before Feodor I",
    "Glinka hero ___ Susanin",
    "Wall Street's Boesky",
    "Tennis's Lendl",
    "Film director Reitman",
    "Russian soldier",
    "Notorious czar",
    "Scientist Pavlov",
    "Russian \"John\"",
    "A Karamazov brother",
    "___ the Terrible",
    "Tolstoy's Ilyich",
    "Ill-tempered czar",
    "Sculptor Mestrovic",
    "___ the Terrible",
    "Mr. Lendl",
    "Netman Lendl",
    "A Karamazov",
    "Basil's successor"
  ],
  "thee": [
    "For whom the bell tolls",
    "Rapper Megan ___ Stallion",
    "You, long ago",
    "\"With this ring, I ___ wed\"",
    "\"With this ring, I ___ wed\"",
    "Pronoun in Proverbs",
    "\"America the Beautiful\" pronoun",
    "Pronoun in \"America\"",
    "Pronoun in both \"America\" and \"America the Beautiful\"",
    "What you might call a Friend",
    "The Gershwins' \"Of ___ I Sing\"",
    "\"Get ___ to a nunnery\": Hamlet",
    "\"Get ___ to a nunnery\": Hamlet",
    "Pilgrims' pronoun",
    "You, once",
    "Quaker pronoun",
    "Pronoun in \"America the Beautiful\"",
    "Final word of \"O Canada\"",
    "The old you?",
    "Pronoun repeated in \"America\"",
    "Pronoun in a wedding statement",
    "Quaker pronoun",
    "Quaker pronoun",
    "Prayer pronoun",
    "\"My country, 'tis of ___\"",
    "Quaker pronoun",
    "You, in the Bible",
    "Second person in the Bible",
    "Pronoun in hymns",
    "What you used to be?",
    "\"Nearer, My God, to ___\"",
    "\"Get ___ hence\": I Kings 17:3",
    "What you used to be?",
    "You, once",
    "You, to a Quaker",
    "You, once",
    "Second person in Genesis",
    "Quaker pronoun",
    "The way you used to be?",
    "\"America\" pronoun",
    "What you used to be",
    "For whom the bell tolls, in a John Donne poem",
    "Biblical pronoun",
    "Hymn pronoun",
    "Prayer pronoun",
    "Prayer pronoun",
    "What you used to be",
    "\"How do I love ___?\"",
    "The old you",
    "Friend's addressee, maybe",
    "Biblical pronoun",
    "Word before \"sweet land of liberty\"",
    "Pronoun in a wedding pledge",
    "You, to the Amish",
    "\"America, the Beautiful\" pronoun",
    "For whom the bell tolls",
    "\"America\" pronoun",
    "\"Of ___ I Sing\"",
    "What you used to be",
    "You, formerly",
    "\"God shed His grace on ___\"",
    "Quaker's \"you\"",
    "Quaker's \"you\"",
    "Pilgrim's pronoun",
    "Fare-well link",
    "Friends' pronoun",
    "Second person in the Bible",
    "Quaker pronoun",
    "Word between Friends",
    "Biblical pronoun",
    "\"How do I love ___? Let ...\"",
    "Word between Friends",
    "\"Of ___ I sing\"",
    "Pat Boone's \"___ I Love\"",
    "Word among Friends",
    "Fifth word of \"America\"",
    "Pronoun in a wedding vow"
  ],
  "hush": [
    "\"Zip your lip!\"",
    "Reaction to someone tapping a microphone, maybe",
    "\"Quiet!\"",
    "Word whispered by the quiet old lady in \"Goodnight Moon\"",
    "\"Zip your lip!\"",
    "\"Shut your mouth!\"",
    "\"Pipe down!\"",
    "\"Quit your crying\"",
    "\"Shut yo' mouth!\"",
    "\"That's enough out of you!\"",
    "... a mother with noisy kids",
    "Word to a crying child",
    "\"Quit your crying!\"",
    "Quiet",
    "Keep concealed, with \"up\"",
    "\"Pipe down!\"",
    "Silence",
    "When repeated, not to be repeated",
    "Theater admonition",
    "When doubled, confidential",
    "\"Stop your gabbin'!\"",
    "\"Pipe down!\"",
    "The sound of silence",
    "\"Not another word!\"",
    "Kind of money or puppy",
    "Silence"
  ],
  "mcat": [
    "Future doc's exam",
    "Chem major's exam, perhaps",
    "Exam for a wannabe doc",
    "7 1/2-hour exam, for short",
    "Exam for future docs",
    "Future internist's exam",
    "Exam for a future G.P.",
    "Exam for a future dr.",
    "Exam for future docs",
    "Future doc's exam",
    "Future dr.'s exam",
    "Hurdle for future docs",
    "Future doc's exam",
    "Future doc's exam",
    "Exam for a doc-to-be"
  ],
  "afts": [
    "Periods just past noon, informally",
    "Times before eves, in ads",
    "Some p.m. times",
    "When soap operas usually air, informally",
    "Times of day in classifieds",
    "Times past noon, informally",
    "Tea times: Abbr.",
    "Siesta times: Abbr.",
    "Times before eves",
    "Times before eves",
    "Talk show times: Abbr.",
    "Eve preceders",
    "Times of day, briefly",
    "Times in classifieds",
    "P.M. periods",
    "Times in classifieds",
    "Times of day, in classifieds",
    "Early p.m.'s",
    "P.M. periods",
    "P.M. times",
    "Backs of boats",
    "When some game shows air: Abbr.",
    "Ship positions",
    "Siesta times: Abbr.",
    "Teatimes: Abbr.",
    "P.M. periods",
    "P.M. times",
    "Parts of days, in the classifieds",
    "Tea times, for short",
    "They follow morns"
  ],
  "eire": [
    "Land of Blarney",
    "Homeland of James Joyce",
    "Dublin's land, to natives",
    "St. Patrick's home",
    "Dublin's land",
    "Its anthem is \"Amhran na bhFiann\"",
    "Dubliner's land",
    "European Union member, to natives",
    "Locale of Dingle and Donegal",
    "Land on the Celtic Sea",
    "Yeats's homeland",
    "Dublin's land",
    "Isle named for a Gaelic goddess",
    "U2 can call it home",
    "Dublin's land",
    "Aer Lingus destination",
    "Dubliner's home",
    "Cork's land",
    "Where a brogue is spoken",
    "Dublin's land",
    "Where the Shannon flows",
    "Yeats's land",
    "Land of Blarney",
    "Where lakes are loughs",
    "Blarney Stone land",
    "Land of Donegal and Dingle Bay",
    "Homeland of 9-Across",
    "Land of Blarney",
    "Galway Bay locale, to locals",
    "Isle named for a Gaelic goddess",
    "Samuel Beckett's homeland",
    "Dublin's land",
    "U2's homeland",
    "It goes from Carndonagh to Skibbereen",
    "Geographical name that's another geographical name backward",
    "Country name pronounced by natives in two syllables",
    "The Emerald Isle",
    "The Emerald Isle",
    "Dublin's land",
    "Hibernia",
    "Green land",
    "Name on some euros",
    "Land with a harp on its coat of arms",
    "Cork's locale",
    "Enya's homeland",
    "Gaelic ground",
    "Name on some European stamps",
    "\"Sing of old ___ and the ancient ways\": Yeats",
    "Its national anthem is \"Amhran na bhFiann\"",
    "What Dubliners call their homeland",
    "Dublin's land",
    "The Emerald Isle",
    "Land west of England",
    "The Auld Sod",
    "Home for Samuel Beckett",
    "Home to the sport of hurling",
    "Home for clover lovers",
    "Land of Blarney",
    "Limerick's land",
    "Homeland of Joyce and Yeats",
    "Name beside a harp on euro coins",
    "Where Guinness originates",
    "Mayo's land",
    "The Chieftains' home",
    "Where Donegal Bay is",
    "Yeats's homeland",
    "Land of the so-called \"Troubles\"",
    "Limerick land",
    "Europe's third-largest island",
    "Home of County Clare",
    "Where St. Patrick's Day is a national holiday",
    "Country whose name is an anagram of 10-Down",
    "The Old Sod",
    "Green land",
    "Name on some euros",
    "Where punts were spent",
    "Island republic",
    "James Joyce's home",
    "Limerick's home",
    "U2's home",
    "Revolutionary Michael Collins's country",
    "The Auld Sod",
    "Dublin's home",
    "Limerick locale",
    "Mayo's locale",
    "Yeats's home",
    "Irish republic",
    "It's west of the Isle of Man",
    "Yeats subject",
    "Leprechaun's land",
    "Cork's country",
    "The Old Sod",
    "Dublin's home",
    "Hibernian's land",
    "Dublin?Æs land",
    "Dublin's land",
    "Home for the Murphys and O'Connors",
    "Hibernia",
    "Colleen's country",
    "Home to Yeats",
    "Douglas Hyde's land",
    "What Dubliners call home",
    "Leprechaun's land",
    "Place to spend euros",
    "The auld sod",
    "Leprechaun's land",
    "Emerald Isle",
    "Dublin's home",
    "Leprechaun's land",
    "Dublin's land",
    "De Valera's land",
    "Cork's place",
    "Leprechaun land",
    "Old Sod",
    "Green land",
    "Land of Molly Bloom",
    "Land west of Wales",
    "Yeats's land",
    "Revolutionary Michael Collins's country",
    "De Valera's land",
    "Ireland, to the Irish",
    "Land of shamrocks",
    "Hibernia",
    "Emerald Isle",
    "Its flag is green, white and orange",
    "Limerick land",
    "Democracy since 1937",
    "Dublin's land",
    "Cork's country",
    "The Old Sod",
    "Where Dingle Bay is",
    "Land of the leprechauns",
    "St. Patrick's home",
    "Drogheda's locale",
    "Cork's locale",
    "Where Slyne Head is",
    "Leprechauns' home",
    "Where hurling originated",
    "Land west of Britain",
    "Republic since 1948",
    "Dublin's land",
    "Country name, 1937-49",
    "Dublin's land",
    "Where 2-Down live",
    "St. Patrick's locale",
    "Name on a European postage stamp",
    "Cork's place",
    "The Emerald Isle",
    "Limerick's location",
    "Dublin's land",
    "De Valera's country",
    "Cork's place",
    "The auld sod",
    "St. Patrick's land",
    "Dublin's home",
    "European state",
    "Dublin's land",
    "Irish republic",
    "It's west of Wales",
    "Beckett's homeland",
    "Cork's site",
    "The old sod",
    "Country since 1949",
    "Dublin's country",
    "Dublin's land",
    "It's west of England",
    "Joyce's land",
    "Gaelic Ireland",
    "Brian Friel's home",
    "Colleen's home",
    "Aer Lingus land",
    "Site of Galway Bay",
    "Mary Robinson's land",
    "Ireland",
    "Republic since 1948",
    "Limerick site",
    "De Valera's land",
    "Cork's locale"
  ],
  "rbis": [
    "A grand slam nets four of these, for short",
    "Box score column",
    "Stat for a D.H.",
    "Four are credited for a grand slam, briefly",
    "Four for a grand slam, briefly",
    "Cleanup hitter's stat",
    "Baseball stats sometimes called 39-Down",
    "Slugger's stat",
    "What sac flies produce",
    "They're usually not credited after errors, for short",
    "Pete Rose's 1,314, for short",
    "Hitters' stats",
    "A tater can produce up to four of these",
    "Ruth's 2,214",
    "Sluggers' stats",
    "Stats for sluggers",
    "M.L.B. Triple Crown category",
    "Stats for Aaron and Gehrig",
    "Hank Aaron's 2,297",
    "Hank Aaron finished his career with 2,297 of them, in brief",
    "Stat for A-Rod",
    "Diamond stats",
    "Babe Ruth's 2,220, for short",
    "Triple Crown winners must lead their league in these",
    "Hank Aaron led the N.L. in them four times",
    "Baseball stats",
    "Sac flies produce them",
    "Yount had 1,406 of them",
    "Diamond stats",
    "Sluggers' stats",
    "Sluggers' figs.",
    "Diamond stats",
    "Result of 26-Down, often",
    "Sluggers' stats",
    "Parts of box scores: Abbr.",
    "Stats for sluggers",
    "Sluggers' stats",
    "Diamond stats",
    "Swingers' stats",
    "Baseball stat",
    "Sluggers' stats",
    "Slugger's stat.",
    "Ballpark stat.",
    "D.H.'s pride",
    "Sluggers' stats",
    "Diamond stats",
    "Stats for Sosa",
    "Diamond production?",
    "Piazza deliveries?: Abbr.",
    "Diamond stats",
    "Aaron's 2,297",
    "McGwire stats",
    "Fenway Park stats",
    "Aaron's 2,297",
    "Baseball stats",
    "Power stats",
    "Power stats",
    "Grand slam foursome",
    "Ballpark figure",
    "Ruth's 2,213",
    "Grand slam foursome",
    "Grand slam foursome",
    "Sluggers' stats",
    "Stadium stats",
    "Results of cleanup work?",
    "Stat for which Cecil Fielder once led the A.L.",
    "Willie Mays stats"
  ],
  "sews": [
    "Does needlework",
    "Puts in stitches",
    "Stitches",
    "Stitches",
    "Patches (up)",
    "Stitches",
    "Stitches",
    "Follows a pattern?",
    "Puts in stitches",
    "Does some surgical work",
    "Takes part in a bee",
    "Wraps (up)",
    "Darns, e.g.",
    "Makes loop-the-loops?",
    "Ends, with \"up\"",
    "Puts in stitches",
    "Wraps (up)",
    "Puts in stitches",
    "Works on shifts, say",
    "Gives a darn?",
    "Has a seamy job?",
    "Works on one's jumper, say",
    "Tailors",
    "Uses a needle",
    "Repairs some tears",
    "Stitches",
    "Wraps (up)",
    "Wields a needle",
    "Puts in stitches",
    "Finishes, with \"up\"",
    "Tailors",
    "Stitches",
    "Wraps (up)",
    "Wraps (up)",
    "Leaves in stitches",
    "Darns",
    "Embroiders",
    "Finishes, with \"up\"",
    "Does tailoring",
    "Embroiders a bit",
    "Buttonholes, say",
    "Wraps (up)",
    "Wraps (up)",
    "Stitches",
    "Plies a needle",
    "Hems but doesn't haw",
    "Wraps (up)",
    "Does zigzags, maybe",
    "Stitches",
    "Uses a Singer",
    "Bastes",
    "Quilts, say",
    "Wraps (up)",
    "Uses a Singer",
    "Stitches",
    "Uses needle and thread",
    "Puts in stitches",
    "Patches things up?",
    "Stitches",
    "Ices, with \"up\"",
    "Puts on buttons",
    "Stitches",
    "Manipulates a needle",
    "Does needlework"
  ],
  "vera": [
    "Designer Wang",
    "Fashion icon Wang",
    "Aloe ___",
    "Designer Wang",
    "Aloe ___",
    "Woman's name that means \"truth\"",
    "Designer Wang",
    "Aloe ___",
    "Aloe ___",
    "Miles of \"Psycho\"",
    "Unseen \"Cheers\" wife",
    "Wang of fashion",
    "Stylish Wang",
    "Wang of fashion",
    "Wife whose face was never seen on \"Cheers\"",
    "___ Cruz (name on old Mexican maps)",
    "Aloe additive?",
    "Bea Arthur's \"Mame\" role",
    "Wang of fashion",
    "Wang of fashion",
    "Fashion's Wang",
    "Tennis star Zvonareva",
    "Miles of film",
    "Lynn who sang \"We'll Meet Again\"",
    "Designer Wang",
    "Aloe ___",
    "Designer Wang",
    "Missing from 16-Down",
    "Wang of fashion",
    "Aloe additive?",
    "Miles of film",
    "Girl's name that means \"true\"",
    "Norm's wife, on \"Cheers\"",
    "Aloe ___",
    "See 22-Across",
    "\"Laura\" author Caspary",
    "Designer Wang",
    "Designer Wang",
    "Actress Miles",
    "\"White Christmas\" dancer ___-Ellen",
    "Designer Wang",
    "\"Psycho\" actress Miles",
    "English novelist Brittain",
    "Aloe ___",
    "Actress Miles",
    "Norm's wife on \"Cheers\"",
    "Miles of Hollywood",
    "Miles of \"Psycho\"",
    "40's-50's singer___Lynn",
    "Aloe ___",
    "Novelist Brittain",
    "Actress Miles",
    "Actress Miles",
    "Ballerina Zorina",
    "Norm's wife, on \"Cheers\"",
    "Aloe ___ (lotion ingredient)"
  ],
  "iota": [
    "Tiny bit",
    "Tiny bit",
    "Least bit",
    "Scintilla",
    "Speck",
    "Smidgen",
    "Tiny bit",
    "Smidgen",
    "Greek vowel",
    "Leader of Kappa Lambda Mu?",
    "Speck",
    "Shred",
    "I, for Plato",
    "Little bit",
    "Tiny bit",
    "Cyclops's \"I\"",
    "Speck",
    "Speck",
    "Littlest bit",
    "I as in Icarus",
    "Smidge",
    "Smidgen",
    "Tiny bit",
    "Wee bit",
    "Speck",
    "\"Not one ___!\"",
    "Letter after theta",
    "Minute amount",
    "Speck",
    "Scintilla",
    "Wee bit",
    "Second letter after 118-Down",
    "Smidgen",
    "Minuscule amount",
    "Smidgen",
    "Speck",
    "Wee bit",
    "Smidgen",
    "Slight amount",
    "Tiny bit",
    "Teensy bit",
    "Slightest bit",
    "Skosh",
    "Bit",
    "Tiny bit",
    "Tiny bit",
    "Whit",
    "Teeny bit",
    "It's not much",
    "Smidge",
    "Letter after 11-Down",
    "Bit",
    "Tiny bit",
    "Not one ___",
    "Bit",
    "Wee bit",
    "Smidgen",
    "Smidgen",
    "Tiny bit",
    "Smidgen",
    "Smidgen",
    "Bit",
    "Letter after theta",
    "Letter before kappa",
    "Touch",
    "Least bit",
    "I as in Ilium?",
    "Ninth in a series",
    "Shred",
    "Smidge",
    "Greek \"I\"",
    "Tiniest bit of the Greek alphabet?",
    "Letter before kappa",
    "Really small amount",
    "Minuscule amount",
    "Smidgen",
    "Tiny bit",
    "Minute bit",
    "Itsy-bitsy bit",
    "Bit",
    "Wee bit",
    "Itsy-bitsy bit",
    "Hint",
    "Least bit",
    "Tiny bit",
    "Wee bit",
    "Small amount",
    "Jot",
    "Trace",
    "Tiny bit",
    "Tiniest bit",
    "Whit",
    "Letter after theta",
    "Jot",
    "Smidgen",
    "Tiny bit",
    "Wee bit",
    "Smidgen",
    "Itsy-bitsy bit",
    "Least bit",
    "Smidgen",
    "Insignificant amount",
    "Greek \"I\"",
    "Teeny bit",
    "Minute amount",
    "Jot",
    "Tiny bit",
    "Not one ___",
    "Speck",
    "Tiniest bit",
    "It's next to nothing",
    "Smidgen",
    "Smidgen",
    "Ninth in a series",
    "Greek \"I\"",
    "Letter before kappa",
    "Little bit",
    "Minute amount",
    "Teensy bit",
    "Tiny bit",
    "Itty bit",
    "Smidgen",
    "Tiny amount",
    "Letter after theta",
    "Small bit",
    "Tad",
    "Tiny amount",
    "Smidgen",
    "Bit",
    "Minute part",
    "Minuscule amount",
    "Bit",
    "Sliver",
    "Smidgen",
    "Small amount",
    "Small quantity",
    "Teensy bit",
    "No more than a drop",
    "Smidgen",
    "Teeny bit",
    "Tiny bit",
    "Letter before kappa",
    "Tiny bit",
    "Speck",
    "Tiny bit",
    "Bit",
    "Smidgen",
    "Smidgen",
    "Smidgen",
    "Smidgen",
    "Smidgen",
    "Bit",
    "Bit",
    "I as in Ithaca",
    "Trivial bit",
    "Minute amount",
    "Speck",
    "Scintilla",
    "Least bit",
    "Speck",
    "Itty-bitty bit",
    "Theta-kappa go-between",
    "Speck",
    "Shred",
    "Little bit",
    "Whit",
    "Jot",
    "Minute amount",
    "Tiny bit",
    "Little bit",
    "Infinitesimal amount",
    "Scintilla",
    "Smidgen",
    "Small amount",
    "Smidgen",
    "Tiny bit",
    "Tiny bit",
    "Tittle",
    "Greek letter",
    "Wee bit",
    "Whit",
    "Little bit",
    "Letter after theta",
    "Smidgen",
    "Bit",
    "Scintilla",
    "Scintilla",
    "Trace",
    "Bit",
    "Scintilla",
    "Tittle",
    "Greek vowel",
    "Small amount",
    "Tiniest bit",
    "Scintilla",
    "The least bit",
    "Whit",
    "Minimal bit",
    "Least bit",
    "Teeny bit",
    "Bit",
    "Ninth Greek letter",
    "Bit",
    "Bit",
    "Tiny bit",
    "Just a bit",
    "Tad"
  ],
  "gaza": [
    "Largest city in the Palestinian state",
    "Strip bordering Israel and Egypt",
    "Largest city of Palestine",
    "Palestinian territory bordering Israel",
    "Strip discussed in the Oslo Accords",
    "City captured during the Six-Day War",
    "Mideast's ___ Strip",
    "Mideast tinderbox",
    "Hot strip?",
    "Six-Day War battleground",
    "Mideast's ___ Strip",
    "___ Strip (much-fought-over area)",
    "___ Strip",
    "Strip in the Mideast",
    "Mideast's ___ Strip",
    "Mideast flash point",
    "Where Samson died",
    "Where Samson died",
    "Strip in the news",
    "___ Strip",
    "Biblical site of the temple of Dagon",
    "Huxley's \"Eyeless in ___\"",
    "One of the five chief cities of the Philistines",
    "___ Strip",
    "Strip on the Mediterranean",
    "Where Samson ground meal",
    "Disputed Mideast strip",
    "Strip name",
    "Mideast zone",
    "Area under Arafat's control",
    "Palestinian area",
    "Mideast \"strip\""
  ],
  "slab": [
    "Thick slice",
    "Hunk",
    "Piece of concrete",
    "Chunk of concrete",
    "Tabletop, perhaps",
    "Thick cut",
    "Big piece of cake",
    "Thick slice",
    "Hunk of meat",
    "Big piece of a sheet cake",
    "More than a sliver",
    "Chunk of concrete",
    "Thick piece of concrete",
    "Cut of meat",
    "Chunk of concrete",
    "Slice for a hearty appetite",
    "Hunk",
    "Sidewalk section, e.g.",
    "Foundation piece",
    "Hunk",
    "Hunk of meat loaf, say",
    "Chunk of cement, say",
    "Hearty slice",
    "Hunk",
    "Sidewalk square, e.g.",
    "Sarcophagus lid",
    "Cheese portion",
    "Thick serving",
    "Hunk",
    "Hunk",
    "Tombstone, e.g.",
    "Meat loaf serving, e.g.",
    "Hunk of marble, e.g.",
    "Simple headstone",
    "Thick slice",
    "Countertop, maybe",
    "Meatloaf serving",
    "Crypt cover",
    "Thick slice",
    "Thick slice",
    "Thick cut",
    "Thick piece",
    "Concrete section",
    "Thick slice",
    "Thick slice",
    "Concrete section",
    "Inscribed stone, maybe",
    "Thick slice",
    "Bacon portion",
    "Pitcher's rubber",
    "Generous slice of the pie",
    "Hunk",
    "Thick slice",
    "Meat loaf serving",
    "Mound feature",
    "Meat loaf serving",
    "Cut of marble",
    "Thick piece",
    "Hunk",
    "Thick piece",
    "Pitcher's rubber, in slang",
    "Hunk",
    "Tabletop, perhaps",
    "Flagstone, e.g.",
    "Thick slice",
    "Thick slice",
    "Thick slice",
    "Thick serving",
    "Thick slice"
  ],
  "meta": [
    "Self-referential, in modern lingo",
    "Self-referential",
    "Prefix with stasis or tarsus",
    "Like the clue for 103-Down?",
    "Like writing about writing",
    "Like writing about how to write",
    "Cleverly self-referential",
    "Like this clue",
    "Like a list of lists of lists",
    "Prefix with tarsal",
    "Self-referential",
    "Self-referential",
    "Like plays about plays, say",
    "Referring to this clue within this clue, e.g.",
    "Self-referential",
    "Like the \"Scream\" films",
    "Self-referential",
    "Like thinking about thinking",
    "Like a movie about people making a movie",
    "Like the \"Scream\" horror films",
    "Like some horror films, in modern lingo",
    "Prefix with physics",
    "Prefix with data",
    "Lead-in for physics ... and pieman?",
    "Self-referential, in modern lingo",
    "Self-referential, in modernspeak",
    "Self-referential, informally",
    "Self-referential, in modern lingo",
    "Carpal or tarsal starter",
    "*Prefix with physics",
    "Self-referential, in modern lingo",
    "Prefix with morphosis",
    "Self-referential, informally",
    "Having multiple layers of self-reference",
    "Prefix with carpal",
    "Prefix with physics",
    "Prefix with carpal",
    "Prefix with physical",
    "Prefix with physics",
    "Prefix with -zoic",
    "Prefix with physical",
    "Prefix with language",
    "Prefix with physical",
    "Intro to physics?",
    "Introduction to physics?",
    "Prefix with physical",
    "Prefix with linguistics",
    "Prefix with physical",
    "Prefix with tarsal",
    "Prefix with carpal",
    "Prefix with morphosis",
    "Introduction to physics?",
    "Prefix with phase",
    "Prefix with -morphous",
    "Prefix with physics",
    "Physics opening",
    "Intro to physics?",
    "Physical beginning",
    "Prefix with stasis",
    "Prefix with physical",
    "\"I ___ man who wasn't there\"",
    "Carpal or physical beginning",
    "Physician's start",
    "Physical start",
    "Physical beginning"
  ],
  "free": [
    "Gratis",
    "Unoccupied",
    "Liberate",
    "At no cost",
    "Like tap water in a restaurant",
    "Advertising claim that usually has a catch",
    "Let go",
    "Complimentary",
    "Bingo card's middle square",
    "Gratis",
    "They say there's no such thing as this kind of lunch",
    "Partner of 5-Across",
    "Bingo square",
    "For the taking",
    "Not in use",
    "Advertising buzzword",
    "Unoccupied",
    "Unjammed",
    "Not occupied",
    "Not taken",
    "Like the initial letters of the answers to the six starred clues, on \"Wheel of Fortune\"",
    "Craigslist section",
    "See 49-Down",
    "Not busy",
    "Center square of a bingo card",
    "Unoccupied",
    "Word on a bingo card",
    "Available",
    "For the taking",
    "Unattached",
    "Open",
    "Priceless?",
    "Available",
    "Gratis",
    "Costing nothing",
    "Unoccupied",
    "Unoccupied",
    "Big word in advertising",
    "Advertiser's \"magic word\"",
    "At leisure",
    "Unoccupied",
    "On the house",
    "Unoccupied, as a theater seat",
    "Sovereign",
    "Gratis",
    "Not tied down",
    "Sovereign",
    "Unfettered",
    "Unleash",
    "For the asking",
    "Unbind",
    "Gratis",
    "Liberate",
    "Unoccupied",
    "To be given away",
    "Remove from isolation",
    "Complimentary",
    "Without plans",
    "Unfetter",
    "Lacking plans",
    "Gratis",
    "Without cost",
    "Priceless?",
    "Without a date",
    "Advertising catchword",
    "At liberty",
    "Let loose",
    "Gratis",
    "Let go",
    "Priceless?",
    "At no cost",
    "At no cost",
    "For the taking",
    "At liberty",
    "Liberate",
    "Off the hook",
    "Gratis",
    "Not in use",
    "Unhitched",
    "At no charge",
    "At no cost",
    "Unfettered",
    "Gratis",
    "Complimentary",
    "Off duty",
    "Exempt",
    "Without plans",
    "Parole"
  ],
  "tecs": [
    "Sleuths, in old slang",
    "Gumshoes",
    "They're on the case, in slang",
    "Clue collectors, for short",
    "People on the case, for short",
    "Private eyes",
    "Private eyes, in slang",
    "Gumshoes, in old crime fiction",
    "Slag for sleuths",
    "P.I.'s",
    "Many pulp heroes, in slang",
    "Hammer or Spade types",
    "Private eyes, for short",
    "Shadows",
    "Shadows, briefly",
    "Private eyes, for short",
    "P.I.'s",
    "P.I.'s",
    "Private investigators, for short",
    "Private eyes",
    "Gumshoes",
    "Hired sleuths, slangily",
    "Private eyes, slangily",
    "Sleuths, for short",
    "Clue hunters, slangily",
    "P.I.'s",
    "Lt. Columbo et al.",
    "Gumshoes",
    "Clue finders",
    "Private eyes, in slang",
    "Gumshoes"
  ],
  "moat": [
    "What a drawbridge may bridge",
    "Protective trench",
    "Means of defense that doesn't actually have alligators",
    "Water under the bridge?",
    "Castle defense",
    "Feature of Tokyo's Imperial Palace",
    "Defensive ring",
    "Certain barrier to entry",
    "It's water under the bridge",
    "Certain siege defense",
    "Feature of a modern zoo",
    "Ring around a castle",
    "Feature of the Tokyo Imperial Palace",
    "Defensive ring",
    "Drawbridge locale",
    "Water under the bridge?",
    "Castle defense",
    "Castle protection",
    "Defense against a siege",
    "Protective zoo feature",
    "Castle obstacle",
    "Castle guard",
    "Advanced sandcastle feature",
    "Castle security system",
    "Zoo barrier",
    "Castle defense",
    "Castle protector",
    "Castle defense",
    "Castle protector",
    "A drawbridge may span one",
    "Castle encircler",
    "Palace protector",
    "Castle defense",
    "A drawbridge spans it",
    "Alternative to a fence",
    "Attack deterrent",
    "Zoo barrier",
    "Water under the bridge",
    "Tower of London feature",
    "Castle protection",
    "Castle defense",
    "Part of a defense",
    "Castle protector",
    "Water ring",
    "Castle's protection",
    "Stage protection",
    "It may hold the fort",
    "Fortress surrounder",
    "Castle defense",
    "Keep guard",
    "Ring of water",
    "Ring around the castle",
    "Defense mechanism",
    "Onetime means of defense"
  ],
  "onme": [
    "\"That one's ___\" (\"My bad\")",
    "\"You can count ___\"",
    "\"My treat!\"",
    "\"This one's ___\"",
    "\"This round's ___\"",
    "\"Lay it ___!\"",
    "\"You can count ___\"",
    "\"Don't Let the Sun Go Down ___\" (Elton John hit)",
    "\"I've got this!\"",
    "\"This one's ___\"",
    "\"I guess the joke's ___\"",
    "\"I'll spring for it\"",
    "\"This one's ___\"",
    "\"This round's ___\"",
    "\"My treat!\"",
    "Words of generosity",
    "\"This round's ___\"",
    "\"I've got this one\"",
    "\"Don't quit ___ now!\"",
    "\"This one's ___\"",
    "\"I'm buying!\"",
    "\"Got a Hold ___\" (1984 top 10 hit)",
    "\"I've got this one\"",
    "\"Don't Tread ___\" (early flag motto)",
    "Words of generosity",
    "\"You've Really Got a Hold ___\" (1963 hit)",
    "\"This one's ___\"",
    "Janis Joplin's \"Down ___\"",
    "\"Dinner's ___\"",
    "\"This round's ___\"",
    "Words of generosity",
    "\"Call ___\" (1974 hit)",
    "\"Down ___\" (1967 Janis Joplin song)",
    "\"This round's ___\"",
    "\"My treat\"",
    "\"This round's ___\"",
    "Def Leppard hit \"Pour Some Sugar ___\"",
    "\"This one's ___\"",
    "\"This round's ___\"",
    "\"This round's ___\"",
    "\"Take a Chance ___\" (Abba song)",
    "\"This one's ___\"",
    "See 58-Down",
    "\"All Eyez ___\" (1996 Tupac Shakur album)",
    "\"This one's ___!\"",
    "\"Put your wallet away\"",
    "\"Down ___\" (Janis Joplin song)",
    "\"This one's ___\"",
    "\"Don't Tread ___\" (old flag warning)",
    "\"Lean ___\"",
    "\"Take ___,\" 1985 #1 hit",
    "\"This one's ___\"",
    "\"This one's ___!\"",
    "\"This one's ___\"",
    "\"Lean ___\"",
    "\"It's ___\" (\"I'm buying\")",
    "\"Lay it ___!\"",
    "\"This one's ___\"",
    "Words that are a treat to hear?",
    "\"This one's ___!\"",
    "Treater's phrase",
    "\"Lean ___\"",
    "\"This one's ___!\"",
    "\"Lay it ___!\"",
    "\"Dinner's ___\"",
    "\"This one's ___\"",
    "\"This round's ___!\"",
    "\"This round's ___\"",
    "\"The joke's ___\"",
    "\"This one's ___\"",
    "Treater's words",
    "\"This round's ___!\"",
    "Treater's words",
    "\"My treat\"",
    "Treater's words",
    "Treater's words",
    "\"This one's ___\"",
    "\"The joke's ___!\"",
    "\"This one's___!\"",
    "\"Lean___\" (1989 movie)",
    "\"The joke's ___!\"",
    "\"This one's ___\"",
    "\"Lean ___\" (Bill Withers hit)",
    "\"This one's ___!\"",
    "\"Don't tread ___\""
  ],
  "maid": [
    "Amelia Bedelia, e.g.",
    "One of eight in \"The Twelve Days of Christmas\"",
    "Worker in \"The 12 Days of Christmas\"",
    "One with a feather duster, maybe",
    "Help around the house",
    "Hyatt hiree",
    "One heeding a \"Do not disturb\" sign",
    "One of eight in \"The 12 Days of Christmas\"",
    "Marian, in Robin Hood legend",
    "Cleaner",
    "Lady's attendant",
    "Worker in an apron",
    "Bride's ___ of honor",
    "Housekeeper",
    "Motel employee",
    "Cleaner",
    "One with a duster",
    "Old ___",
    "Domestic",
    "TV's Hazel, e.g.",
    "Domestic",
    "Picker-upper?",
    "One whose business is picking up",
    "Help",
    "See 17-Across",
    "Marian, for one",
    "Domestic",
    "Motel employee",
    "Duster",
    "Duster",
    "One of \"the help\"",
    "Miss",
    "Domestic",
    "Kind of service",
    "Meter ___",
    "Hotel housekeeper",
    "Lady in an apron",
    "One of a \"Mikado\" trio"
  ],
  "pars": [
    "Avoids a bogey, perhaps",
    "Course standards",
    "Standards",
    "Numbers on a golf course",
    "3, 4, 5 and occasionally more",
    "Scorecard figures",
    "Some scores in 34-Down",
    "3, 4 and 5, on golf courses",
    "Ends up even",
    "Fours on a course, often",
    "Course data",
    "Golf scorecard numbers",
    "Scorecard data",
    "Three, four and five, usually",
    "Most are 3, 4 or 5",
    "Course guides?",
    "Links numbers",
    "3, 4 and 5, but rarely 6",
    "Scores to shoot for",
    "Shoots well",
    "Shoots satisfactorily",
    "Links numbers",
    "Scores 72 on a 72 course",
    "Average figures",
    "Shoots an average score"
  ],
  "boil": [
    "Be hopping mad",
    "Fume",
    "Be hot under the collar",
    "Seethe",
    "Minute Rice instruction",
    "Be hot",
    "Be hot under the collar",
    "Be super-angry",
    "Directive in a pasta recipe",
    "Be hopping mad",
    "Be hot under the collar",
    "Be hot, hot, hot",
    "Seethe",
    "Heat to 212??",
    "Bring to a ___",
    "Seethe",
    "Heat to 212??",
    "Churn",
    "Swelter",
    "Swelter",
    "Bring to 212 degrees",
    "Be angry",
    "Be angry as heck",
    "Be angry",
    "Bubble",
    "Seethe",
    "Not merely warm",
    "Seethe",
    "Seethe",
    "Heat to more than 212??, as water",
    "Cook, as pasta",
    "Fume",
    "Recipe direction",
    "Seethe",
    "Be het up",
    "Bring to 212 degrees",
    "Canning instruction",
    "Seethe",
    "Seethe",
    "Be plenty hot",
    "More than simmer",
    "Swelter",
    "Be hopping mad",
    "Go over 212 degrees",
    "Be hopping mad"
  ],
  "snug": [
    "Tight-fitting",
    "Close-fitting",
    "Comfy-cozy",
    "Very close",
    "Warm and cozy",
    "Close and comfortable",
    "Tucked in, say",
    "Cozy",
    "Close-fitting",
    "Cozy",
    "Figure-hugging",
    "Close-fitting",
    "Tight",
    "\"The children were nestled all ___ in their beds\"",
    "Tight",
    "Cozy",
    "Like a bug in a rug",
    "Comfy-cozy",
    "Cozy",
    "Far from baggy",
    "Like a bug in a rug, according to an expression",
    "See 21-Down",
    "Cozy",
    "Form-fitting",
    "Tight, as a fit",
    "Like tights",
    "Pleasantly small",
    "Like a bug in a rug",
    "Tight",
    "Cozy",
    "Like a bug in a rug",
    "Not quite tight",
    "Tightfitting",
    "Tightfitting",
    "Comfy",
    "Closefitting"
  ],
  "sula": [
    "Toni Morrison title heroine",
    "Toni Morrison novel",
    "Toni Morrison novel",
    "Toni Morrison novel",
    "Toni Morrison novel",
    "Toni Morrison novel",
    "1973 novel title character surnamed Peace",
    "Novel by Toni Morrison",
    "1973 Toni Morrison novel",
    "Toni Morrison book",
    "Toni Morrison novel",
    "Toni Morrison novel"
  ],
  "seti": [
    "Grp. that hasn't yet found what it's looking for",
    "Org. behind the Carl Sagan Center",
    "Space-scanning org.",
    "Org. seeking alien life",
    "Org. that employs radio telescopes",
    "Alien-seeking org.",
    "\"Contact\" org.",
    "Org. seeking life in space",
    "Org. in Carl Sagan's \"Contact\"",
    "Program whose prospects are looking up?",
    "Astrobiologists' org.",
    "Subj. for a radio astronomer",
    "___ Institute (astronomers' org.)",
    "Radio listener grp.",
    "Project that analyzes electromagnetic radiation, for short",
    "Org. that listens for alien signals",
    "___ Institute, org. that makes use of the Allen Telescope Array",
    "Program that asks \"Are we aloe?,\" for short",
    "\"The X-Files\" program, for short",
    "Institute in the 1997 sci-fi film \"Contact\"",
    "\"The X-Files\" project, for short",
    "Alien-seeking program: Abbr.",
    "Space-scanning proj.",
    "\"The X-Files\" grp.",
    "Son of Ramses I",
    "\"The X-Files\" org.",
    "Son of Ramses I",
    "Father of Ramses II",
    "Egyptian king of the 19th dynasty",
    "\"The X-Files\" grp.",
    "Bristle: Prefix",
    "Ramses I's successor",
    "King of ancient Egypt"
  ],
  "kilo": [
    "Valuable load for a mule",
    "K, in the NATO alphabet",
    "Metric weight, informally",
    "Approximate weight of a liter of water",
    "Smuggler's unit",
    "The \"k\" of kHz",
    "About 2.2 pounds, for short",
    "Scale unit, in most of the world",
    "K, in the NATO alphabet",
    "What a mule may carry",
    "Drug bust quantity, casually",
    "Drug unit",
    "Narc's find, perhaps",
    "Letter before lima in the NATO phonetic alphabet",
    "Metric prefix",
    "Smuggler's unit",
    "Letter after Juliet in a phonetic alphabet",
    "Unit of contraband",
    "D.E.A. seizure, maybe",
    "Overseas shipping unit",
    "Narc's discovery, maybe",
    "Two pounds, plus",
    "Drug drop, maybe",
    "Smuggler's amount",
    "2.2 lbs., approximately",
    "Drug unit",
    "Narc's find, maybe",
    "It may be found in a bust",
    "Prefix with hertz",
    "Narc's find, perhaps",
    "Metric weight",
    "Narc's find",
    "Amount in a drug shipment",
    "Part of kg-m",
    "Narc's catch, maybe",
    "Narc's unit",
    "Start to byte",
    "Drug shipment, maybe",
    "Code word for \"K\"",
    "Drug shipment"
  ],
  "sway": [
    "Influence",
    "What trees do in the wind",
    "Influence",
    "What trees do in fierce storms",
    "Influence",
    "Clout",
    "Influence",
    "Go to and fro",
    "Have influence on",
    "Influence",
    "Command",
    "Swing in the breeze",
    "Influence",
    "Have influence on",
    "What many rulers hold",
    "Dominion",
    "Have an effect on",
    "Bend in the wind",
    "Dominion",
    "Influence",
    "Influence",
    "Influence",
    "Influence",
    "Go to and fro",
    "Go to and fro",
    "Swing in the breeze",
    "Influence",
    "Influence",
    "Influence",
    "Waffle"
  ],
  "dieu": [
    "\"___ merci!\" (French cry)",
    "\"Mon ___!\"",
    "\"Mon ___!\"",
    "Image on the ceiling of la chapelle Sixtine",
    "\"Mon ___!\" (French exclamation)",
    "\"Mon ___!\" (French cry)",
    "\"Mon ___!\"",
    "Foe of le diable",
    "\"Mon ___!\" (French cry)",
    "\"Mon ___!\"",
    "French prayer addressee",
    "\"Mon ___!\"",
    "Prie-___ (kneeling bench)",
    "L'homme upstairs?",
    "\"___ et mon droit\" (British monarch's motto)",
    "Target of Pierre's prayers",
    "Object of a French prayer",
    "Prie-___ (prayer bench)",
    "\"Mon ___!\"",
    "Diable battler",
    "Word in many French family mottoes",
    "God, to Godard",
    "\"Mon ___!\"",
    "God, in Paris",
    "Lord in France",
    "Prayer addressee, in Paris",
    "\"___ et mon droit\" (British royal motto)",
    "\"Mon ___!\"",
    "\"Mon ___!\"",
    "\"Mon ___!\"",
    "\"Mon ___!\"",
    "Prie-___ (prayer bench)",
    "\"Mon ___!\"",
    "\"__ et mon droit\" (motto of the British royalty)",
    "\"Mon ___!\"",
    "The Almighty, in Alsace",
    "Prie-___ (prayer desk)",
    "Poirot's \"Mon ___!\""
  ],
  "iate": [
    "\"No need to make me a plate\"",
    "\"No food for me, thanks\"",
    "\"No food needed for me\"",
    "\"Must've been something ___\"",
    "\"___ it up and spit it out\" (\"My Way\" lyric)",
    "\"It must be something ___\"",
    "\"Must've been something ___\"",
    "\"It must've been something ___\"",
    "\"It must've been something ___\"",
    "\"___ it up and spit it out\" (\"My Way\" lyric)",
    "\"Must've been something ___\"",
    "\"I can't believe ___ the whole thing!\"",
    "\"Must've been something ___\"",
    "\"Must've been something ___\"",
    "\"Must've been something ___\"",
    "\"Must be something ___\"",
    "\"?à ___ the whole thing!\"",
    "\"Must've been something ___\"",
    "?ôMust?Æve been something ___?ö",
    "\"I can't believe ___ ...\" (old ad catchphrase)",
    "\"Must've been something ___\"",
    "\"Must be something ___\"",
    "\"Must be something ___\"",
    "\"I can't believe ___...\"",
    "\"I can't believe ___...\""
  ],
  "sgts": [
    "They know the drill: Abbr.",
    "Capt.'s inferiors",
    "Ones ranked above cpls.",
    "Cpls.' superiors",
    "Barracks barkers: Abbr.",
    "Three-stripers: Abbr.",
    "Figs. in many police procedurals",
    "Pepper and others: Abbr.",
    "Pepper and Friday: Abbr.",
    "Barracks barkers: Abbr.",
    "They may be in a mess: Abbr.",
    "Friday and others: Abbr.",
    "Superiors to cpls.",
    "Army three-stripers: Abbr.",
    "Police dept. members",
    "Some officers: Abbr.",
    "Three-stripers: Abbr.",
    "Bilko and Friday: Abbr.",
    "Ones ranked above cpls.",
    "Some chevron wearers: Abbr.",
    "Some NCOs",
    "Company leaders: Abbr.",
    "Capt.'s inferiors",
    "Some police personnel: Abbr.",
    "Some police officers: Abbr.",
    "Pepper and Friday: Abbr.",
    "Some cops: Abbr.",
    "P.D. personnel",
    "Three-stripers: Abbr.",
    "Snorkel and colleagues: Abbr.",
    "Company V.I.P.'s: Abbr.",
    "Company leaders: Abbr.",
    "Company leaders: Abbr.",
    "Capt.'s inferiors",
    "Bilko and Pepper: Abbr.",
    "Some noncoms: Abbr.",
    "Some NCOs",
    "Company leaders: Abbr.",
    "Pepper and others: Abbr.",
    "Some police dept. personnel",
    "Some NCO's",
    "Boot camp barkers: Abbr.",
    "Some NCO's",
    "Cpls.' superiors",
    "Some N.C.O.'s",
    "Some N.C.O.'s",
    "Mess overseers: Abbr.",
    "Some noncoms: Abbr.",
    "Some drillers: Abbr.",
    "They report to Lts.",
    "Joe Friday and others: Abbr.",
    "Certain NCO's",
    "Police officers: Abbr.",
    "Subordinates of 99-Down",
    "Certain NCO's",
    "Bilko and York: Abbr.",
    "NCO club members",
    "Certain NCO's",
    "Noncoms: Abbr."
  ],
  "imin": [
    "\"Sign me up!\"",
    "\"Sign me up!\"",
    "\"Let's do this!\"",
    "\"Fold\" opposite",
    "\"Sign me up\"",
    "Cry from a successful hacker",
    "Poker player's declaration",
    "\"Sign me up!\"",
    "Volunteer's phrase",
    "\"Let's do it!\"",
    "Opposite of \"Too rich for my blood\"",
    "\"Sounds good to me!\"",
    "Declaration made while anteing up",
    "\"Sign me up!\"",
    "Successful hacker's declaration",
    "\"You can count on me\"",
    "\"Let's do this thing!\"",
    "Conspirator's agreement",
    "\"Let's do it!\"",
    "\"Sure, sign me up!\"",
    "Declaration after looking at one's cards",
    "\"Deal!\"",
    "Opposite of \"Too rich for me\"",
    "Poker declaration",
    "\"Sign me up!\"",
    "Cry at the start of a poker game",
    "\"Deal!\"",
    "Poker announcement",
    "\"Sign me up!\"",
    "\"You can count on me\"",
    "\"Sure, put me down for that\"",
    "Comment to a card dealer",
    "\"You can count on me\"",
    "Gang member's \"O.K.\" before a job",
    "\"You've got a deal!\"",
    "Gambler's declaration",
    "Poker declaration",
    "Poker cry ... or an apt title for this puzzle",
    "Hacker's cry of success",
    "Comment at the poker table",
    "Comment after looking at one's cards",
    "Poker player's declaration",
    "Poker declaration",
    "Poker declaration",
    "Poker player?Æs declaration",
    "Remark while putting chips in the pot",
    "Poker player's declaration",
    "What a folder doesn?Æt say",
    "\"Sounds good to me!\"",
    "Poker declaration",
    "Poker player's declaration",
    "Poker declaration",
    "\"Sounds good to me\"",
    "Declaration of participation",
    "Words from a nonfolder",
    "What a folder wouldn't say",
    "Poker declaration",
    "Poker player's declaration",
    "Poker call",
    "\"___ the Mood for Love\"",
    "\"___ love!\"",
    "Poker player's declaration"
  ],
  "near": [
    "Almost",
    "\"Warm\"",
    "Close by",
    "___ at hand",
    "Close",
    "Close by",
    "At hand",
    "Sharing many of the characteristics of",
    "___ miss",
    "Close",
    "Approximately",
    "In the neighborhood",
    "In the vicinity of",
    "Not distant",
    "About",
    "Bye",
    "___ relative",
    "A mere stone's throw from",
    "Close",
    "In close proximity",
    "Close",
    "Dear companion?",
    "In the offing",
    "Almost",
    "In the neighborhood",
    "Within view",
    "At hand",
    "___ East",
    "\"Warm\"",
    "Approaching",
    "Within earshot",
    "In the vicinity",
    "Close",
    "Approaching",
    "Close by",
    "Warm, say",
    "Around",
    "Almost",
    "___ beer",
    "Warm, so to speak",
    "Only a day away, say",
    "___ and 60-Across (all around)",
    "Get 44-Down",
    "In the vicinity",
    "Close to",
    "Hereabout",
    "Proximate",
    "With 65-Across, the starts of 20-, 26-, 43- and 51-Across taken together",
    "About",
    "Practically touching",
    "___ miss",
    "In the vicinity of",
    "By",
    "Close by",
    "Close",
    "About",
    "Close to",
    "Within easy reach",
    "\"___ as I can tell ...\"",
    "Within striking distance",
    "\"Warm\"",
    "Within earshot",
    "Close",
    "Within a stone's throw",
    "At hand",
    "At hand",
    "In the vicinity",
    "Approximate",
    "Approach",
    "Impending",
    "Come up to",
    "Within reach",
    "Circa",
    "Close",
    "Close",
    "Within a stone's throw",
    "Within earshot",
    "Around",
    "Within view",
    "Close in on",
    "Almost here",
    "Getting warm",
    "Impending",
    "Future preceder",
    "Close by",
    "Within earshot",
    "With 74-Down, part of Asia",
    "Around",
    "In the vicinity",
    "Fast approaching",
    "Well-nigh",
    "Close",
    "In the area",
    "In the offing",
    "Dear's partner",
    "Getting warm, say",
    "Around",
    "Getting warm",
    "Kind of miss",
    "Close",
    "Close",
    "Within range, maybe",
    "Approach",
    "Impending",
    "Foreseeable",
    "Close in on",
    "Approach",
    "Not far",
    "Imminent",
    "Next-door",
    "At hand",
    "Around",
    "Close",
    "Within view",
    "Warm, so to speak",
    "Close",
    "___ miss",
    "Imminent",
    "Kind of miss",
    "Close in on",
    "Close",
    "Intimate",
    "Approach",
    "Kind of catastrophe",
    "Within earshot",
    "By",
    "___ East",
    "Immediate",
    "Warm, so to speak",
    "Not distant",
    "Close",
    "Not distant",
    "Hereabout",
    "Close",
    "Handy to",
    "In the vicinity",
    "Close",
    "Problematic for presbyopes",
    "At hand",
    "Imminent",
    "Approach",
    "Nigh",
    "At hand",
    "About",
    "Close",
    "Approach",
    "Circa",
    "About",
    "Around",
    "Circa",
    "\"Warm\"",
    "Close"
  ],
  "toat": [
    "Just so",
    "Precisely",
    "Perfectly",
    "Precisely",
    "Perfectly",
    "Precisely",
    "Precisely",
    "Flawlessly",
    "Impeccably",
    "On the money",
    "Impeccably",
    "Perfectly",
    "Just so",
    "Precisely",
    "Just so",
    "Right on",
    "Without flaws",
    "Perfectly",
    "Perfectly",
    "Perfectly",
    "Fit ___ (be perfect on)",
    "Exactly as required",
    "On the nose",
    "Flawlessly",
    "Flawlessly",
    "Exactly",
    "Exactly",
    "Just right",
    "With no imperfections",
    "Perfectly",
    "Not just approximately",
    "Just so",
    "Just right",
    "Just so",
    "Perfectly",
    "One way of fitting",
    "Just so",
    "Exactly",
    "Just right",
    "Perfectly",
    "Precisely",
    "Exactly",
    "Not sloppily",
    "Exactly",
    "Precisely",
    "Exactly",
    "Exactly",
    "Flawlessly",
    "In every respect",
    "Perfectly",
    "Precisely",
    "Perfectly"
  ],
  "slaw": [
    "Hot dog topper",
    "Common side dish at a barbecue",
    "Cookout side dish",
    "Cole ___",
    "Dish that may come with a spork",
    "BBQ side",
    "Deli dish",
    "Common picnic side dish",
    "BBQ side dish",
    "Side dish at a barbecue",
    "Cole ___ (side dish)",
    "BBQ platter side",
    "Dish made with mayo",
    "Barbecue side dish",
    "Picnic side dish",
    "Barbecue side dish",
    "Side with a dog",
    "Picnic side dish",
    "Side of a diner",
    "BBQ side dish",
    "Sub side, maybe",
    "Picnic side dish",
    "Beanery side dish",
    "Deli side",
    "KFC side dish",
    "Picnic dish",
    "Raw side?",
    "Barbecue offering",
    "Salad alternative",
    "Part of many a KFC order",
    "Cabbage dish",
    "Cabbage dish",
    "Uncooked side",
    "Picnic side dish",
    "See 10-Across",
    "Cabbage salad",
    "Picnic staple",
    "Dish eaten with a spork",
    "Side order at K.F.C.",
    "Dish eaten with a spork",
    "Diner side",
    "Dish eaten with a spork",
    "Dish eaten with a spork",
    "Deli side dish",
    "Picnic dish",
    "Cole ___",
    "Side dish with fried clams",
    "Popular side",
    "Cole ___",
    "Side dish",
    "Cole ___",
    "Potluck dish",
    "Popular side",
    "Common side order",
    "Picnic dish",
    "Cole ___",
    "Picnic dish",
    "Picnic dish",
    "Order at KFC",
    "Picnic staple",
    "Burger side",
    "Popular picnic dish",
    "It's served in shreds",
    "Side order at KFC",
    "Deli side",
    "Head-shredded salad",
    "It usually comes on the side",
    "Chopped cabbage",
    "Diner side dish",
    "Deli side dish",
    "Deli dish",
    "It might accompany a hero",
    "Deli side order",
    "Cole ___",
    "Cole ___",
    "Common side order"
  ],
  "hies": [
    "Gets a move on, quaintly",
    "Speeds",
    "Speeds through Shakespeare?",
    "Goes quickly",
    "Gets a move on",
    "Rushes",
    "Rushes",
    "Rushes",
    "Hurries",
    "Hotfoots it, old-style",
    "Speeds",
    "Goes fast",
    "Rushes",
    "Rushes",
    "Gets the lead out, quaintly",
    "Hurries, quaintly",
    "\"Thus weary of the world, away she ___\": Shak.",
    "Speeds",
    "Hotfoots it",
    "Spurs (on)",
    "Beats it",
    "Doesn't dally",
    "Rushes",
    "Gets a move on",
    "Hastens",
    "Goes quickly",
    "Rushes",
    "Scurries",
    "Rushes",
    "Rushes along",
    "Hastens"
  ],
  "blot": [
    "Obscure, with \"out\"",
    "Smudge",
    "Rorschach image",
    "Inky mess",
    "Ink problem",
    "Rorschach test shape",
    "Try to contain, in a way, as a spill",
    "Ink stain",
    "Problem on a record",
    "Rorschach test element",
    "Bad thing on a record",
    "Rorschach image",
    "Record flaw",
    "Ink spill",
    "Stain",
    "Rorschach test image",
    "Printout problem",
    "Stain",
    "Bad mark",
    "Printing problem",
    "Black mark",
    "Ink stain",
    "Bad mark on one's reputation",
    "Inky mess",
    "Too much ink",
    "Stain",
    "Smudge",
    "Printer's mistake",
    "Dry with absorbent paper",
    "Part of a Rorschach test",
    "Inky mess",
    "Part of a psychological test",
    "Rorschach presentation",
    "Rorschach stain",
    "Smudge",
    "Delete, with \"out\"",
    "Stain",
    "Ink problem",
    "Ink smudge",
    "Rorschach test image"
  ],
  "stds": [
    "They may be set by industry grps.",
    "Quality control guidelines: Abbr.",
    "Regs.",
    "Conventions: Abbr.",
    "Yardsticks: Abbr.",
    "Regs.",
    "Rule book contents: Abbr.",
    "Touchstones: Abbr.",
    "Norms: Abbr.",
    "The E.P.A. issues them: Abbr.",
    "Benchmarks: Abbr.",
    "E.P.A. issuances: Abbr.",
    "Criteria: Abbr.",
    "Guidelines: Abbr.",
    "Some govt. issuances: Abbr.",
    "Regular set: Abbr.",
    "Fed. agencies may issue them",
    "Regular things: Abbr.",
    "They're normal: Abbr.",
    "Norms: Abbr.",
    "Criteria: Abbr.",
    "Govt. bodies may issue them",
    "Regs.",
    "Norms: Abbr.",
    "Censors have them: Abbr.",
    "Norms: Abbr.",
    "Norms: Abbr.",
    "Norms: Abbr.",
    "The usual amts.",
    "Norms: Abbr.",
    "F.C.C. concerns: Abbr.",
    "Benchmarks: Abbr.",
    "Criteria: Abbr.",
    "Principles: Abbr.",
    "Norms: Abbr.",
    "E.P.A. output: Abbr.",
    "Regulations: Abbr.",
    "Criteria: Abbr.",
    "Censor's list: Abbr.",
    "Criteria: Abbr.",
    "Yardsticks: Abbr.",
    "Benchmarks: Abbr.",
    "Benchmarks: Abbr.",
    "Norms: Abbr.",
    "Yardsticks: Abbr.",
    "Guides: Abbr.",
    "F.C.C. concerns: Abbr.",
    "Criteria: Abbr.",
    "Rules: Abbr.",
    "Norms: Abbr.",
    "Criteria: Abbr.",
    "Criteria: Abbr.",
    "Norms: Abbr.",
    "F.C.C. concerns: Abbr.",
    "Benchmarks: Abbr."
  ],
  "nell": [
    "Girl in \"The Old Curiosity Shop\"",
    "Dickens orphan",
    "\"The Old Curiosity Shop\" girl",
    "Dickens's Little ___",
    "\"Little\" Dickens girl",
    "Carter of \"Ain't Misbehavin'\"",
    "\"Little\" Dickens girl",
    "Dudley Do-Right's love",
    "\"Little\" heroine of Dickens's \"The Old Curiosity Shop\"",
    "Dickens heroine Trent",
    "Dickens heroine",
    "Dickens protagonist surnamed Trent",
    "Dickens orphan",
    "Dudley Do-Right's love",
    "\"Little\" Dickens girl",
    "Nagg's wife in Samuel Beckett's \"Endgame\"",
    "Dudley's love, in cartoons",
    "Dudley Do-Right's heartthrob",
    "\"Little\" Dickens character",
    "Dudley's love in old cartoondom",
    "\"The Old Curiosity Shop\" heroine",
    "1994 Jodie Foster title role",
    "Dickens heroine ___ Trent",
    "1994 Jodie Foster role",
    "Actress Carter",
    "Mistress of Charles II",
    "Dudley Do-Right's girlfriend",
    "1994 Jodie Foster title role",
    "Tony-winning Carter",
    "Dudley Do-Right's love",
    "Carter of \"Gimme a Break!\"",
    "___ Gwyn, mistress of Charles II",
    "Dudley Do-Right's love",
    "Dickens's \"little\" girl",
    "Carter of sitcomdom",
    "Little ___, Dickens girl",
    "Emmy- and Tony-winning Carter",
    "Dickens heroine",
    "Charles II's mistress",
    "\"Ain't Misbehavin'\" star Carter",
    "1994 Jodie Foster film",
    "Dickens orphan",
    "1994 film based on the play \"Idioglossia\"",
    "Jodie Foster film",
    "Dudley Do-Right's beloved",
    "Dickens girl",
    "1994 Jodie Foster title role",
    "Paramour of England's Charles II",
    "1994 Jodie Foster title role",
    "\"Little\" Dickens girl",
    "Carter of sitcoms",
    "Title role for Jodie Foster",
    "Dudley Do-Right's beloved",
    "Dudley Do-Right's love",
    "Title role for Jodie Foster",
    "Dudley Do-Right's love",
    "Little Dickens girl",
    "1994 Jodie Foster film",
    "\"The Old Curiosity Shop\" girl",
    "Carter of \"Gimme a Break\"",
    "Dickens girl",
    "Dickens girl",
    "\"Little\" Dickens girl",
    "Actress Carter",
    "1994 Jodie Foster film",
    "\"The Old Curiosity Shop\" heroine",
    "___ Gwyn, sweetheart of Charles II",
    "1994 Jodie Foster movie",
    "Dickensian orphan",
    "\"The Old Curiosity Shop\" heroine",
    "Dudley Do-Right's love",
    "Dudley Do-Right's love"
  ],
  "punt": [
    "Defer for now",
    "Get rid of the ball, in a way",
    "Give up (on)",
    "Fourth-and-long option",
    "Fourth-down play",
    "Result of a sack on third and long, maybe",
    "Common play on fourth down",
    "Throw in the towel",
    "Give up, at least for now",
    "Bad choice on first down",
    "Abandon one's efforts, informally",
    "Kick",
    "Give up, slangily",
    "Admit defeat, in a way",
    "Football kick",
    "Fourth down option",
    "Give up, slangily",
    "Fourth-down option",
    "Fourth-down option",
    "Fourth-down option"
  ],
  "ssrs": [
    "The Baltics, once: Abbr.",
    "Ukr. and Lith., once",
    "Historical group of 15, for short",
    "They became independent in 1991: Abbr.",
    "Historical group of 15, for short",
    "15 divs. on old maps",
    "They were wiped off the map in '91",
    "Moldova and Belarus, once: Abbr.",
    "Former political divs.",
    "Ukraine and others, once: Abbr.",
    "Ukr., Est. and Lith., once",
    "Parts of Eastern Eur., once",
    "Georgia and neighbors, once: Abbr.",
    "Ukr. and Lith., once",
    "Lat. and Lith., formerly",
    "They broke up in 1991: Abbr.",
    "United entities before 1991: Abbr.",
    "Georgia and Moldova, once: Abbr.",
    "Armenia and Azerbaijan, once: Abbr.",
    "Cold war-era lands: Abbr.",
    "Lith. and Lat., once",
    "Members of a former union: Abbr.",
    "Cold war grp.",
    "Red grp.",
    "Red states?: Abbr.",
    "Bygone Black Sea borderers: Abbr.",
    "C.I.S. members, once",
    "Part of Eastern Europe, once: Abbr.",
    "Former union members: Abbr.",
    "Bygone grp. of 15",
    "Rus. and Ukr., once",
    "Communist federation: Abbr.",
    "Erstwhile grp. of 15",
    "Georgia and others, once: Abbr.",
    "A lot of Eurasia, once: Abbr.",
    "Former grp. of 15",
    "Georgia et al., once: Abbr.",
    "Cold war grp.",
    "Lith. and Ukr., formerly",
    "Old union members: Abbr.",
    "Lith. and Ukr., once",
    "Georgia and Lithuania, once: Abbr.",
    "The Baltics, once: Abbr.",
    "Independence achievers of 1991: Abbr.",
    "Pre-1989 Eurasian political divs.",
    "Lith. and Lat., once",
    "They broke up in 1991: Abbr.",
    "Lat. and others, once",
    "Ukr. and Lat., once",
    "Old Eurasian locales: Abbr.",
    "Georgia and others, once: Abbr.",
    "Former union members: Abbr.",
    "A great deal of Eurasia, once: Abbr.",
    "Lith. and Ukr., formerly",
    "Georgia and Ukraine, once: Abbr.",
    "Estonia et al., once: Abbr.",
    "Former union mems.",
    "Old political divs.",
    "Est. and Lat., once",
    "Georgia et al., once: Abbr.",
    "Bygone pol. units",
    "Armenia and Georgia, once: Abbr.",
    "Azerbaijan and Ukraine, once: Abbr.",
    "Georgia et al., once: Abbr.",
    "States under Stalin: Abbr.",
    "C.C.C.P. units",
    "C.I.S. members, once",
    "Old polit. entities",
    "Lat., Lith. and Ukr., once",
    "Lith. and Azer., once",
    "Lith. and Lat., once",
    "Lat. and Lith., once",
    "Lat. and Lith., once",
    "Former states: Abbr.",
    "Lith. and Lat., once"
  ],
  "dyed": [
    "Unnatural, in a way",
    "Like blue hair, presumably",
    "Not natural, say",
    "Went green, perhaps?",
    "Applied Clairol to, perhaps",
    "Like pink or purple hair",
    "No longer gray, say",
    "Like Easter eggs, colorwise",
    "Not natural",
    "Went platinum?",
    "Like Easter eggs",
    "Turned brunette, maybe",
    "Like Easter eggs",
    "Not naturally red-haired, e.g.",
    "Like most clown wigs",
    "Blond now, say",
    "Colored, as Easter eggs",
    "Turned brown, say",
    "Like purple hair",
    "Brunette no more, say",
    "Got away from one's roots?",
    "Like roots, periodically?",
    "Went from black to red, say",
    "Like Easter eggs",
    "Like some blonds",
    "Highlighted, say",
    "Like orange hair",
    "Like some eggs",
    "Unnatural?",
    "Like Easter eggs",
    "Not natural, in a way",
    "Not natural",
    "Not naturally colored",
    "Did some batiking",
    "Not the original color",
    "Gone platinum?",
    "Like some changed locks",
    "Gray no more, say",
    "Not natural",
    "Like some wool",
    "Not natural",
    "Like batik fabrics",
    "Turned blue, maybe",
    "Not colored naturally",
    "Turned blue, maybe",
    "Not natural",
    "Changed locks?",
    "Like Easter eggs",
    "Not natural",
    "Not natural",
    "Not the original color",
    "Turned blue, say",
    "Like Easter eggs",
    "Turned blue, maybe",
    "Not natural",
    "Not the original color",
    "Turned blue, perhaps",
    "Not naturally blonde, e.g.",
    "Like Easter eggs",
    "Turned red?",
    "Tinted",
    "Changed colors",
    "Like Easter eggs",
    "Used henna",
    "Like Easter eggs",
    "Artificially blonde, say",
    "Stained",
    "Used Miss Clairol",
    "Unnaturally blonde, e.g.",
    "Not naturally colored",
    "Colored",
    "Did a hairdresser's job",
    "Like batik fabrics",
    "Not natural"
  ],
  "sext": [
    "Phone hotline?",
    "Risqué communiqué",
    "Send a naughty message",
    "Not-safe-for-work transmission",
    "Potential source of a political scandal",
    "Subject of a scandal, maybe",
    "Send a revealing image, say",
    "Barely communicate?",
    "Scandalous selfie, maybe",
    "Quick, suggestive message",
    "Suggestive transmission",
    "Blue note?",
    "Squire message",
    "Something dirty kept in a cell?",
    "Message one might not want the tabloids to see",
    "Send some pixxx?",
    "Have a heated phone exchange?",
    "Send private messages?",
    "Send explicit come-ons by cell phone",
    "Noon service, to ecclesiastics",
    "Noontime service"
  ],
  "eeny": [
    "Start of some decision-making",
    "Start of a counting-out rhyme",
    "Start of a counting-out rhyme",
    "\"___, meeny, miney, mo ...\"",
    "Playground rhyme starter",
    "\"___, meeny ...\"",
    "Wee, informally",
    "___-weeny (small)",
    "Start of a decision-making process",
    "Kids' rhyme starter",
    "Recess rhyme starter",
    "\"___, meeny, miney, mo ...\"",
    "Choice word?",
    "\"___ meeny miney mo\"",
    "Start of a count-off",
    "Counting-out rhyme start",
    "Kids' rhyme starter",
    "Start of a choosing rhyme",
    "Word of choice",
    "Start of a children's rhyme",
    "\"___, meeny, miney, mo\"",
    "Start of a playground rhyme",
    "Start of a counting rhyme",
    "Start of a selection process",
    "Start of a children's rhyme",
    "Choice word?",
    "Choosing-up word",
    "Start of a children's rhyme",
    "Start of a counting-out rhyme",
    "Start of some choice words?",
    "Start of a counting-out rhyme",
    "Start of a counting rhyme",
    "Choice word",
    "Start of a counting-out rhyme",
    "Choosing-up word",
    "Counting-out starter",
    "Start of a decision-making process",
    "Start of a kid's counting rhyme",
    "\"___, meeny, miney, mo\"",
    "Start of a decision-making process",
    "Start of a counting-out rhyme",
    "Counter's start",
    "Start of a counting-out rhyme",
    "\"___ meeny miney mo\"",
    "Chooser's start",
    "Counting-out bit",
    "Counting-out word",
    "First to be counted?",
    "Counting-out bit",
    "Counting-out rhyme starter",
    "Start of a children's rhyme",
    "Start of a selection process",
    "Start of a counting rhyme",
    "Part of a choosing-up routine",
    "Start of a counting-out rhyme",
    "One of a few \"choice\" parts",
    "Counting-out start",
    "Start of a count",
    "Counting-out word",
    "Start of a counting-out rhyme",
    "Counter's opening",
    "Start of a children's rhyme",
    "Start of a chooser's rhyme",
    "Start of a child's rhyme"
  ],
  "cait": [
    "\"I Am ___,\" onetime spinoff of \"Keeping Up With the Kardashians\""
  ],
  "akin": [
    "Related",
    "Like, with \"to\"",
    "Related",
    "Related",
    "Comparable (to)",
    "In the same family",
    "Similar (to)",
    "Comparable (to)",
    "Related",
    "Similar (to)",
    "Comparable (to)",
    "Analogous",
    "Comparable (to)",
    "Similar (to)",
    "Similar (to)",
    "In the same mold as, with \"to\"",
    "Similar (to)",
    "Similar (to)",
    "Of similar character",
    "Similar (to)",
    "Homologous",
    "Comparable (to)",
    "Related",
    "Like",
    "Related",
    "Similar (to)",
    "Similar",
    "Similar (to)",
    "Similar",
    "Comparable (to)",
    "Similar",
    "Related",
    "Very similar",
    "Blood-sharing?",
    "Similar (to)",
    "Similar",
    "Related (to)",
    "Similar (to)",
    "Of the same sort",
    "Sharing common alleles",
    "Blood-related",
    "Like, with \"to\"",
    "Comparable (to)",
    "Related",
    "Analogous (to)",
    "Having similar properties",
    "Comparable (to)",
    "Closely related",
    "Similar",
    "Closely related",
    "Closely related",
    "Related (to)",
    "Related",
    "Having similar genetics",
    "Related (to)",
    "Like siblings",
    "Closely related",
    "Closely related (to)",
    "Similar",
    "Related",
    "Consanguineous",
    "Blood-related",
    "Related",
    "Homologous",
    "Related (to)",
    "Similar (to)",
    "Related",
    "Similar (to)",
    "Related",
    "Parallel",
    "Closely related",
    "Related",
    "Parallel",
    "Related",
    "Comparable (to)",
    "Similar (to)",
    "Related",
    "Like",
    "Of the same sort",
    "Related",
    "Comparable",
    "Related (to)",
    "Cognate",
    "Similar",
    "Resembling, with \"to\"",
    "Comparable",
    "Like, with \"to\"",
    "Analogous",
    "Analogous",
    "Like, with \"to\"",
    "Parallel",
    "Similar",
    "Cognate",
    "Similar",
    "Analogous",
    "Analogous (to)",
    "Related",
    "Similar",
    "Similar",
    "Related",
    "Related",
    "Analogous",
    "Like, with \"to\"",
    "Related (to)",
    "Comparable",
    "Similar",
    "Analogous",
    "Related",
    "Similar",
    "Related",
    "Related",
    "Similar",
    "Similar"
  ],
  "bten": [
    "Apt bingo call to lose on, by the sound of it?",
    "Airplane whose name is also a vitamin",
    "Vitamin also known as PABA",
    "Bygone bomber whose name is a call in bingo",
    "Old bomber",
    "Vitamin a.k.a. para-aminobenzoic acid",
    "1930s bomber",
    "Bingo call",
    "Old bomber",
    "Classic Army bomber plane",
    "Bomber type",
    "Old Martin bomber",
    "Bingo call",
    "1930s bomber",
    "Revolutionary 1930s bomber",
    "Bingo call",
    "Bingo call",
    "Bingo call",
    "Square in the first column of bingo",
    "Bingo call",
    "Bingo call",
    "Bingo call",
    "Bingo call",
    "Bingo call"
  ],
  "elev": [
    "K2 fig.",
    "20,310 ft., for Denali",
    "Abbr. on a scenic overlook sign",
    "Your highness?: Abbr.",
    "Climber's concern: Abbr.",
    "Alt.",
    "Mountain fig.",
    "Mountain road sign abbr.",
    "Peak figure: Abbr.",
    "Stat for 26-Down: Abbr.",
    "Highness: Abbr.",
    "Map abbr.",
    "About 16,900 ft., for 3-Down",
    "Topographic map notation: Abbr.",
    "Mt. McKinley's is 20,320 ft.",
    "Stat. for 1-Down",
    "Map abbr.",
    "Hgt.",
    "Figure usu. in hundreds or thousands of feet",
    "Roadside sign abbr.",
    "Hgt.",
    "29,035 ft., for Mt. Everest",
    "City limits sign abbr.",
    "Your highness?: Abbr.",
    "Info along a mtn. road",
    "Architect's abbr.",
    "Map abbr.",
    "Hiker's stat.",
    "Feet above sea level: Abbr.",
    "Peak stat.",
    "Roadside sign abbr.",
    "Mtn. stat",
    "Atlas abbr.",
    "Mountain road sign abbr.",
    "Mountain stat.",
    "Height: Abbr.",
    "Abbr. on a contour map",
    "Abbr. on a mountain sign",
    "Atlas abbr.",
    "Ht.",
    "Ht.",
    "Mountain sign abbr.",
    "Hgt.",
    "Hgt.",
    "Hgt.",
    "Hgt.",
    "Hgt.",
    "Hgt.",
    "Mountain sign: Abbr.",
    "Height: Abbr.",
    "Mountain road abbr.",
    "Abbr. on a mountain sign",
    "Mountain sign abbr."
  ],
  "hosp": [
    "Drip locale: Abbr.",
    "Institution often named for a saint: Abbr.",
    "\"Scrubs\" locale: Abbr.",
    "O.R. locale",
    "Reason for a quiet zone: Abbr.",
    "Ambulance's destination: Abbr.",
    "O.R. or E.R. site",
    "Doctor's place: Abbr.",
    "I.C.U. locale",
    "R.N.'s locale",
    "The \"H\" in \"M*A*S*H\": Abbr.",
    "\"Chicago Hope\" setting: Abbr.",
    "O.R.'s locale"
  ],
  "acti": [
    "It's a start",
    "Dramatic beginning",
    "When the Battle of Yorktown occurs in \"Hamilton\"",
    "When Hamlet sees his father's ghost",
    "When Caesar is told to \"Beware the ides of March\"",
    "Broadway opening?",
    "Intermission preceder",
    "Prologue follower",
    "Broadway opening",
    "Dramatic opening",
    "Opening on Broadway?",
    "When the tempest occurs in \"The Tempest\"",
    "When Romeo first sees Juliet",
    "It starts when an overture's over",
    "Start of a play",
    "Broadway opening",
    "It follows a curtain-raising",
    "Play's start",
    "Start of a play",
    "Play starter",
    "First part of a play",
    "It follows a curtain-opening",
    "Opening on Broadway",
    "Play opener",
    "Opening of a play",
    "Prologue follower",
    "When the story begins, perhaps",
    "Early CliffsNotes subheading",
    "Dramatic opening",
    "Play's opening",
    "When Stanley cries \"Hey, Stella!\" in \"A Streetcar Named Desire\"",
    "Start of a play",
    "Play opener",
    "Play opener",
    "When the nude scene occurs in 10-Across",
    "When Caesar is told to beware",
    "Stage opening",
    "Prologue follower",
    "Theater opening",
    "When the tempest occurs in \"The Tempest\"",
    "Start in literature",
    "Play start",
    "Dramatic start",
    "Show opening",
    "Play opener",
    "Play starter",
    "When Maggie calls herself \"a cat on a hot tin roof\"",
    "Play's start",
    "Start on a stage",
    "Play opener",
    "Dramatic opening",
    "When Romeo meets Juliet",
    "Broadway opening",
    "When the shipwreck occurs in \"The Tempest\"",
    "It follows a curtain's rise",
    "Broadway opener",
    "Follow-up to a curtain opening",
    "Musical opening",
    "Drama opening",
    "When Hamlet sees the ghost",
    "Opera synopsis heading",
    "Play's start",
    "When Hamlet sees his father's ghost",
    "Start of a show",
    "Curtain-rising time",
    "Show opener",
    "Stage opening",
    "Dramatic opening",
    "Prologue follower",
    "When the witches first appear in \"Macbeth\"",
    "Stage opening",
    "When Carmen meets Don Jose",
    "Play starter",
    "Broadway opening?",
    "Start of a play",
    "Start of a play",
    "Play starter",
    "Opening on Broadway",
    "Play opener",
    "Broadway opener",
    "Opera opener",
    "Play opening",
    "Dramatic beginning",
    "Curtain-raising time",
    "Play's opening",
    "It follows a curtain-raising",
    "When King Lear disinherits Cordelia",
    "Play opener",
    "Opera opener",
    "Intermission preceder",
    "Play opener",
    "Overture follower",
    "Play start",
    "Curtain-parting time",
    "Stage beginning",
    "Play's opener",
    "Pre-intermission period",
    "Script starter",
    "Play opening"
  ],
  "syfy": [
    "Cable network with a rhyming name",
    "Cable network with movies like \"Sharktopus\" and \"Mansquito\"",
    "\"Sharknado\" channel"
  ],
  "vert": [
    "Couleur du brocoli",
    "Old TV adjustment: Abbr.",
    "\"Hor.\" neighbor, on old TVs",
    "Green: Fr.",
    "Heraldic shade",
    "Green, in heraldry",
    "Hor.'s opposite"
  ],
  "done": [
    "Worker's cry of triumph",
    "Spent",
    "What a \"R-r-r-ring!\" in the kitchen signifies",
    "[mic drop]",
    "Over",
    "\"It's a deal!\"",
    "\"You got it!\"",
    "Ready to serve",
    "Unable to continue",
    "Sufficiently cooked",
    "Washed up, careerwise",
    "All washed up",
    "All over",
    "Puzzle solver's happy shout",
    "Cry made while wiping the hands",
    "Ready to come off the stove",
    "Finished",
    "Ready to serve",
    "___ deal",
    "Finished",
    "Through",
    "\"I finished\"",
    "Ready, in the kitchen",
    "Ready to serve",
    "Over",
    "Exhausted",
    "Exhausted, with \"in\"",
    "Ready to come out of the 109-Down",
    "Successful solver's cry",
    "Over",
    "Cry that might be appropriate at this point in the puzzle",
    "Ready to serve",
    "Out of the oven, say",
    "Ready to be removed from the oven",
    "Through",
    "Wrapped up",
    "Accomplished",
    "Finito",
    "Through",
    "On the back burner, perhaps",
    "Wrapped up",
    "Exhausted, with \"in\"",
    "Completed",
    "Ready to come out of the oven",
    "Finito",
    "Ready to come off the stove",
    "\"It just isn't ___\"",
    "Ready",
    "\"It's a deal!\"",
    "Spent",
    "Ready to eat",
    "Contest winner's cry",
    "Cooked to perfection",
    "Fully cooked",
    "Cooked"
  ],
  "worn": [
    "Tired",
    "Weathered, say",
    "Frayed so?",
    "Threadbare",
    "Frayed, as clothing",
    "Tattered",
    "Showing signs of age",
    "Threadbare",
    "Threadbare",
    "Old-looking",
    "Showing signs of use",
    "Threadbare",
    "Dog-eared",
    "Sported",
    "Threadbare",
    "Threadbare",
    "Slightly tattered",
    "Dog-eared",
    "Beat-up",
    "Needing patches",
    "In need of patching",
    "Frayed",
    "Used",
    "No longer new",
    "Frayed",
    "Eroded"
  ],
  "agee": [
    "Posthumous Pulitzer winner of 1958",
    "Pulitzer-winning James",
    "C.I.A. whistle-blower Philip",
    "Palindromist Jon of \"Sit on a Potato Pan, Otis!\"",
    "Pulitzer-winning James",
    "Writer and film critic James",
    "Author James",
    "Jon who wrote and illustrated \"Palindromania!\"",
    "\"The Night of the Hunter\" screenwriter James",
    "James who wrote \"A Death in the Family\"",
    "\"Let Us Now Praise Famous Men\" writer",
    "James who wrote \"A Death in the Family\"",
    "Pulitzer-winning James",
    "Screenwriter James of \"The African Queen\"",
    "James who wrote \"A Death in the Family\"",
    "Pulitzer Prize winner for \"A Death in the Family\"",
    "Jon who wrote and illustrated \"Smart Feller, Fart Smeller and Other Spoonerisms\"",
    "Miracle Met Tommie",
    "James who won a posthumous Pulitzer",
    "James with a posthumous Pulitzer",
    "Pulitzer Prize-winning author James",
    "Jon who wrote and illustrated \"Palindromania!\"",
    "James who wrote \"A Death in the Family\"",
    "James with a posthumous Pulitzer",
    "1969 World Series hero Tommie",
    "Pulitzer-winning James",
    "1940s film critic James",
    "Jon who wrote and illustrated \"Go Hang a Salami! I'm a Lasagna Hog!\"",
    "James with a posthumous Pulitzer",
    "Pulitzer winner James",
    "\"A Death in the Family\" writer James",
    "Miracle Mets player Tommie",
    "James who wrote \"A Death in the Family\"",
    "\"Palindromania!\" writer Jon",
    "\"A Death in the Family\" novelist",
    "James who won a posthumous Pulitzer",
    "James who wrote \"Let Us Now Praise Famous Men\"",
    "Pulitzer winner James",
    "\"Inside the Company: C.I.A. Diary\" author Philip",
    "James who wrote \"A Death in the Family\"",
    "\"The African Queen\" screenwriter",
    "Pulitzer winner James",
    "Pulitzer winner James",
    "\"A Death in the Family\" novelist",
    "Writer James",
    "James who wrote \"A Death in the Family\"",
    "James who died three years before winning a Pulitzer",
    "Critic James",
    "Pulitzer-winning James",
    "Writer James",
    "\"The African Queen\" screenwriter James",
    "Writer James",
    "Outfielder Tommie of the Miracle Mets",
    "1966 A.L. Rookie of the Year",
    "Tommie of the Miracle Mets",
    "James who won a posthumous Pulitzer",
    "\"A Death in the Family\" author",
    "Writer James",
    "Writer James",
    "James who co-wrote the script for 39-Across",
    "Philip with a 1975 best seller on C.I.A. secrets",
    "\"Let Us Now Praise Famous Men\" writer",
    "Author who won a posthumous Pulitzer in 1958",
    "Tommie of the 1969 World Series Mets",
    "Pulitzer-winning James",
    "Pulitzer winner James",
    "Tommie of the Amazins",
    "Writer James",
    "Posthumous Pulitzer winner of 1958",
    "James who wrote \"A Death in the Family\"",
    "\"A Death in the Family\" author James",
    "\"A Death in the Family\" author",
    "Posthumous Pulitzer winner of 1958",
    "Winner of a posthumous Pulitzer",
    "Writer James",
    "Author James",
    "Tommie ___, 1966 A.L. Rookie of the Year",
    "Critic James",
    "\"The Night of the Hunter\" screenwriter",
    "Author James",
    "Posthumous Pulitzer winner",
    "\"The Morning Watch\" author",
    "Pulitzer winner James",
    "\"The Morning Watch\" writer James",
    "Writer James",
    "\"The African Queen\" scriptwriter",
    "\"The Morning Watch\" author",
    "James who wrote \"The Morning Watch\"",
    "Essayist/novelist James",
    "Pulitzer-winning writer James",
    "\"The Morning Watch\" writer, 1951",
    "1940?Æs film critic",
    "Philip who wrote a 1975 C.I.A. expose",
    "James who wrote \"A Death in the Family\"",
    "James who wrote \"The Morning Watch\"",
    "Winner of a posthumous Pulitzer",
    "Screenwriter James",
    "\"Let Us Now Praise Famous Men\" writer",
    "Critic James",
    "\"The Morning Watch\" writer, 1951",
    "\"A Death in the Family\" author James",
    "Tommie of the Miracle Mets",
    "\"A Death in the Family\" writer",
    "Noted 1940's film critic",
    "\"The Night of the Hunter\" screenwriter",
    "\"The Morning Watch\" author",
    "Onetime Mets slugger Tommie",
    "1958 Pulitzer winner",
    "\"The Morning Watch\" author",
    "Tommie in Mets history",
    "Pulitzer winner James",
    "\"The African Queen\" screenwriter",
    "\"The Night of the Hunter\" screenwriter",
    "\"Let Us Now Praise Famous Men\" writer",
    "\"A Death in the Family\" writer",
    "\"A Death in the Family\" writer",
    "Tommie of the Miracle Mets",
    "\"The Night of the Hunter\" screenwriter",
    "Pulitzer winner James",
    "Pulitzer winner James",
    "Author of \"A Death in the Family\"",
    "Wordsmith/ illustrator Jon",
    "Writer James",
    "Arthur of \"Hoop Dreams\"",
    "Pulitzer-winner James",
    "\"The Night of the Hunter\" screenwriter",
    "Pulitzer writer James",
    "1966 A.L. Rookie of the Year",
    "\"A Death in the Family\" author",
    "Onetime Met Tommie",
    "Writer James from Tennessee",
    "Miracle Mets lead-off man",
    "Pulitzer writer James",
    "\"The African Queen\" screenwriter James",
    "Pulitzer-winning writer James",
    "\"The Morning Watch\" novelist",
    "\"A Death in the Family\" author",
    "Author James",
    "Pulitzer winner James",
    "Onetime Time film critic James",
    "Humorist/illustrator Jon",
    "Tommie of the 60's-70's Mets",
    "Poet and novelist James",
    "Pulitzer winner James",
    "Pulitzer writer James",
    "\"A Death in the Family\" author",
    "Pulitzer writer James",
    "Pulitzer writer James",
    "\"Let Us Now Praise Famous Men\" writer",
    "\"The African Queen\" scriptwriter",
    "1958 Pulitzer author",
    "Baseball's Tommie",
    "\"The Night of the Hunter\" screenwriter",
    "1957 Pulitzer winner",
    "Memorable New York Met Tommie",
    "Contemporary author-illustrator Jon",
    "James who wrote \"A Death in the Family\"",
    "Arthur of \"Hoop Dreams\"",
    "James of letters",
    "\"A Death in the Family\" author",
    "\"The Morning Watch\" author",
    "Writer James",
    "Tommie of 60's-70's baseball",
    "\"Let Us Now Praise Famous Men\" writer",
    "Baseball's Tommie",
    "Baseball's Tommie",
    "?ôPermit Me Voyage?ö writer",
    "Pulitzer winner James",
    "Writer-critic James",
    "Writer James",
    "\"Let Us Now Praise Famous Men\" writer",
    "\"A Death in the Family\" writer James",
    "Palindromist Jon",
    "\"The African Queen\" screenwriter",
    "Author James",
    "Business exec William",
    "\"The Morning Watch\" author",
    "\"The African Queen\" screenwriter",
    "Author James",
    "C.I.A. profiler Philip",
    "\"The Night of the Hunter\" screenwriter",
    "1958 Pulitzer winner for fiction",
    "\"___on Film\" (1983 book set)",
    "\"Let Us Now Praise Famous Men\" author",
    "\"A Death in the Family\" author",
    "\"The African Queen\" scriptwriter"
  ],
  "ruhr": [
    "Germany's largest urban area",
    "German industrial valley",
    "River through Dortmund",
    "European coal center",
    "German industrial region",
    "Region in western Germany",
    "Germany's ___ Valley",
    "Tributary of the Rhine",
    "German industrial locale",
    "___ Valley, European steelmaking region",
    "Germany's ___ Basin",
    "German coal region",
    "German coal region",
    "Essen's river",
    "Germany's ___ Valley",
    "European coal center",
    "River to the Rhine",
    "River to the Rhine",
    "German region occupied by France and Belgium from 1923-25",
    "Essen's region",
    "Mulheim an der ___, Germany",
    "Essen's region",
    "Arnsberg is on it",
    "German industrial valley",
    "Essen's basin",
    "Where Essen is",
    "Duisburg's locale",
    "Essen's river",
    "German valley",
    "Essen basin",
    "German industrial valley",
    "German industrial region",
    "European coal area",
    "Where Wuppertal is",
    "Remscheid's region",
    "Essen's region",
    "River crossed by Allied forces in February 1945",
    "Essen's river",
    "German industrial region",
    "River to the Rhine",
    "German industrial valley",
    "Essen basin",
    "German industrial locale",
    "German industrial region",
    "Essen's river",
    "Bombed area in W.W. II",
    "German valley",
    "Krupp family home"
  ],
  "ritt": [
    "Martin who directed \"Norma Rae\"",
    "Film director Martin",
    "\"Nuts\" director Martin",
    "\"The Great White Hope\" director Martin",
    "\"Stanley & Iris\" director Martin",
    "\"Hud\" director",
    "\"Nuts\" director, 1987",
    "Director Martin",
    "Film director Martin",
    "Film director Martin",
    "Filmmaker Martin",
    "\"Norma Rae\" director",
    "\"Norma Rae\" director Martin",
    "\"Norma Rae\" director",
    "\"Norma Rae\" director",
    "Film director Martin",
    "\"Hud\" director",
    "\"Norma Rae\" director",
    "\"Norma Rae\" director",
    "\"Norma Rae\" director Martin",
    "\"The Sound and the Fury\" director",
    "\"Norma Rae\" director Martin",
    "\"Hud\" director Martin",
    "Director Martin",
    "\"Sounder\" director Martin"
  ],
  "host": [
    "Hope at the Oscars, e.g.",
    "Zoom call figure",
    "Whole bunch",
    "Myriad",
    "Website need",
    "Large number",
    "Myriad",
    "Parasite harborer",
    "Whole bunch",
    "Deer, to a tick",
    "Party person",
    "Chris Rock, for the 2016 Oscars",
    "Rio de Janeiro, for the 2016 Olympics",
    "Coveted late-night gig",
    "Have over, say",
    "Emcee",
    "Have over",
    "Human in \"Alien,\" e.g.",
    "Rio vis-a-vis the 2016 Olympics",
    "Invitation sender",
    "Legion",
    "One having an affair",
    "Myriad",
    "Person running the show",
    "Legion",
    "Party thrower",
    "Multitude",
    "Party giver",
    "Party giver",
    "Virus's need",
    "Parasite's home",
    "Desk job at 58 & 59-Across?",
    "Central computer",
    "Parasite's need",
    "Slew",
    "Party thrower",
    "Emcee",
    "Multitude",
    "Emcee",
    "Multitude",
    "Parasite supporter",
    "Party giver",
    "Party-giver",
    "Leno, notably",
    "Parasite's place",
    "Central computer",
    "Party thrower",
    "A party to a party?",
    "Person with a mike",
    "Virus's target",
    "Parasite's home",
    "Alex Trebek, e.g.",
    "Welcomer",
    "Party thrower",
    "Graft recipient",
    "Throng",
    "Legion",
    "Central computer",
    "Party thrower",
    "Party V.I.P.",
    "Jay Leno, e.g.",
    "Party fellow",
    "Emcee"
  ],
  "cuke": [
    "Salad veggie",
    "Garden item that can be brined, informally",
    "Salad veggie",
    "Item cut up for a salad, informally",
    "Salad veggie",
    "Pickled veggie",
    "Pickled veggie",
    "Green gourd, informally",
    "Salad veggie",
    "Salad veggie",
    "Pickle-to-be, slangily"
  ],
  "nuit": [
    "Ravel's \"Gaspard de la ___\"",
    "Dark period for Monet",
    "Debussy's \"___ d'Etoiles\"",
    "Dark time in France",
    "Ravel's \"Gaspard de la ___\"",
    "Van Gogh's \"Le Cafe de ___\"",
    "Night, in Nantes",
    "When the day's done, to Denis",
    "Period sans soleil",
    "Jour's opposite",
    "Counterpart of jour",
    "When French ghouls come out?",
    "When la Tour Eiffel lights up",
    "Van Gogh's \"Le Cafe de ___\"",
    "\"Bonne ___\" (French wish at bedtime)",
    "When the stars come out in Paris",
    "Jour's opposite",
    "\"Bonne ___\" (French send-off)",
    "___ de Noel (Christmas Eve): Fr.",
    "Dark period for France",
    "Van Gogh?Æs \"___ Etoilee a St. Remy\"",
    "Ravel's \"Gaspard de la ___\"",
    "Nice evening",
    "Soir follower",
    "Ravel's \"Gaspard de la ___\"",
    "\"Bonne ___!\"",
    "When les etoiles come out",
    "Night, in Nimes",
    "A night in Paris"
  ],
  "sort": [
    "Excel command",
    "Ilk",
    "Put in an order",
    "Kind",
    "Nothing of the ___",
    "Resolve, with \"out\"",
    "Kind",
    "Breed",
    "Class",
    "Instruction in a Word menu",
    "Put in proper piles",
    "Class",
    "Word processing function",
    "Kind",
    "Basic spreadsheet command",
    "Class",
    "Put in order",
    "Separate, as whites from colors",
    "Spreadsheet command",
    "Stripe",
    "Put into piles, say",
    "Manner",
    "Nature",
    "Word processing command",
    "Excel function",
    "Microsoft Excel command",
    "Ilk",
    "Arrange from A to Z, say",
    "Collate",
    "Kind",
    "Variety",
    "Do some post office work",
    "Type",
    "Spreadsheet function",
    "Manipulate data",
    "Common computer instruction",
    "Organize alphabetically, say",
    "Do a laundry chore",
    "Separate",
    "Put into piles",
    "*Put into piles",
    "Stripe",
    "Type",
    "Variety",
    "Type",
    "Fashion",
    "Ilk",
    "Ilk",
    "Stripe",
    "Computer command",
    "Alphabetize, e.g.",
    "Nature",
    "Kind",
    "Do a post office job",
    "Separate into lights and darks, say",
    "Kind",
    "Kind",
    "Database command",
    "Order",
    "Breed",
    "Work with mail",
    "Genre",
    "Separate into whites and darks, e.g.",
    "Nature",
    "Arrange",
    "Put into slots",
    "Nature",
    "Work with mail",
    "Color, so to speak",
    "Alphabetize, e.g.",
    "Pigeonhole",
    "Categorize",
    "Word processor command",
    "Make piles, say",
    "Prepare to wash, perhaps",
    "Arrange logically",
    "Do some postal work",
    "Breed",
    "Photocopier function",
    "Launderer's step",
    "Collate",
    "Kind",
    "Kind",
    "Work at the post office",
    "Ilk",
    "Systematize",
    "Lot",
    "Classify",
    "Type",
    "Put in piles",
    "Alphabetize",
    "Ilk",
    "Catalogue",
    "Separate the laundry",
    "Ilk"
  ],
  "ecig": [
    "Vaping device, informally",
    "Vaper's purchase",
    "Modern health risk, informally",
    "Juul, e.g., informally",
    "Vaper's device, informally",
    "Vaper's buy",
    "Juul, e.g.",
    "Juul, e.g., briefly",
    "Vaping device, informally",
    "Vaper's purchase, for short",
    "Juul, e.g., for short",
    "Vaping device, informally",
    "Vaper's device",
    "Vaper's device",
    "Vaper's device",
    "Vaping need, informally"
  ],
  "drum": [
    "Successfully solicit, with \"up\"",
    "Beat it!",
    "Refinery container",
    "Show impatience, as with the fingers",
    "Beat maker",
    "One to beat",
    "One expected to get beaten",
    "Snare or tom-tom",
    "Oil container",
    "Oil container",
    "Oil holder",
    "Bongo, e.g.",
    "Oil holder",
    "A rock band's name often appears on it",
    "Run (out of)",
    "Big barrel",
    "Large barrel",
    "Roll maker?",
    "Bongo or conga",
    "Bongo, for one",
    "It takes a beating",
    "Oil container",
    "Snare, maybe",
    "Beat it!",
    "Tom-tom",
    "Tap rhythmically",
    "Recipient of a beating"
  ],
  "yuma": [
    "Arizona city near the California border",
    "Arizona county or its seat",
    "Arizona city and county seat",
    "City and county of Arizona",
    "Arizona county or its seat",
    "Arizona county or its seat",
    "Where Cesar Chavez was born",
    "Desert in the Southwest",
    "Arizona birthplace of Cesar Chavez",
    "City on the Colorado",
    "Arizona city",
    "City WSW of Phoenix",
    "Arizona city",
    "City in Arizona",
    "City on the Colorado River",
    "Colorado River city",
    "Arizona city"
  ],
  "nina": [
    "NPR's ___ Totenberg",
    "Simone known as the \"High Priestess of Soul\"",
    "Actress Dobrev of \"The Vampire Diaries\"",
    "Jazz singer Simone",
    "With 44-Down, jazz great who sang \"I Put a Spell on You\"",
    "Ship of 1492",
    "Actress Dobrev of \"The Vampire Diaries\"",
    "It \"sailed the ocean blue\"",
    "Singer Simone",
    "Singer Simone",
    "Ship of 1492",
    "First in a historical trio",
    "First in a historical trio",
    "___ Myers, \"24\" character",
    "Jazzy Simone",
    "Ship of 1492",
    "Ship captained by Vicente Yanez Pinzon",
    "Companion of the Pinta and Santa Maria",
    "Ship of 1492",
    "Ship that sailed \"the ocean blue\"",
    "Jazz's Simone",
    "Singer Simone",
    "English novelist Bawden",
    "Pretty ballerina in an Abba song",
    "One of a 15th-century trio",
    "Granada girl",
    "Ship to the New World",
    "Historic ship whose real name was Santa Clara",
    "Noel Coward title woman \"from Argentina\"",
    "Ship that sailed \"the ocean blue\"",
    "Ingenue in Chekhov's \"The Seagull\"",
    "Jazz singer Simone",
    "One of Columbus's ships",
    "\"The Seagull\" ingenue",
    "One of a noted nautical trio",
    "Companion of the Pinta and Santa Maria",
    "One of a historical trio",
    "One of a sailing trio",
    "Ship of Columbus",
    "Singer Simone",
    "Vessel of 1492",
    "Historic ship",
    "Historic ship",
    "Historical ship",
    "NPR's Totenberg",
    "1492 vessel",
    "Zagat of restaurant guide fame",
    "It set sail in 1492",
    "Actress Foch",
    "First in a historical trio",
    "Sister ship of 104-Down",
    "Craft of 1492",
    "Ship of 1492",
    "15th-century vessel",
    "Mexican child",
    "Historic caravel",
    "Totenberg of NPR",
    "First of a famous sailing trio",
    "Ship of 1492",
    "Notable caravel",
    "Mexican child",
    "Name hidden by Hirschfeld",
    "Historic caravel",
    "Guadalajara girl",
    "La ___ (weather worry)",
    "15th-century traveler",
    "One of Columbus's fleet",
    "Ship of Columbus",
    "First of a traveling threesome",
    "One of Columbus's ships",
    "One of Columbus's ships",
    "Mexican child",
    "One of Columbus's ships",
    "15th-century maritime name",
    "Ship of 1492",
    "Hirschfeld's girl",
    "Perfumer ___ Ricci",
    "Historic caravel",
    "Singer Simone",
    "Ship of 1492",
    "Mexican girl",
    "Ship of 1492",
    "First of a famous trio",
    "Ship of 1492",
    "1945 Noel Coward song",
    "Caravel of 1492",
    "Companion of the Pinta",
    "Hirschfeld's daughter"
  ],
  "etal": [
    "Stand-in for the unnamed",
    "Latin list ender",
    "Bibliographic abbr.",
    "And others, in a list",
    "And others: Abbr.",
    "Abbr. after the third co-author, perhaps",
    "And others: Abbr.",
    "Series finale",
    "Bibliography abbr.",
    "And all the rest, in brief",
    "And others: Abbr.",
    "And others: Abbr.",
    "List ender",
    "Latin list lopper, in brief",
    "List curtailer",
    "And others, in a list",
    "Series finale abbr.",
    "Latin list ender",
    "And others, for short",
    "Latin catchall",
    "And others: Abbr.",
    "Bibliographer's abbr.",
    "Abbr. after a series of 52-Downs",
    "And others: Abbr.",
    "List shortcut",
    "End of some lists",
    "List-ending abbr.",
    "List-ending abbr.",
    "Series finale",
    "Abbr. at the end of an abridged roster",
    "List ender: Abbr.",
    "And others, in a bibliography",
    "Bibliography abbr.",
    "List-reducing abbr.",
    "Abbr. after a list",
    "List-ending abbr.",
    "And others, for short",
    "List shortcut",
    "Shortening for a bibliographer",
    "And others, for short",
    "Abbr. in a bibliographic citation",
    "Series curtailer: Abbr.",
    "Series ender: Abbr.",
    "List-ending abbr.",
    "Bibliography abbr.",
    "List-ending abbr.",
    "List-ending abbr.",
    "List-ending abbr.",
    "Stand-in for the unnamed",
    "Bibliographic abbr.",
    "Citation shortening",
    "Catchall abbr.",
    "List-ending abbr.",
    "And others: Abbr.",
    "Bibliographer's abbr.",
    "Bibliog. shortener",
    "Citation abbr.",
    "Citation abbr.",
    "Library indexing abbr.",
    "List abbr.",
    "And others: Abbr.",
    "Bibliographical abbr.",
    "Name series condenser",
    "Abbr. for the listless?",
    "Name-dropper's abbr.?",
    "List-ending abbr.",
    "List ender",
    "And others: Abbr.",
    "Bibliography abbr.",
    "List shortener",
    "Follower of a list of names",
    "List ender",
    "And others, for short",
    "Citation shortener",
    "And others: Abbr.",
    "Name dropper's notation?",
    "And others, briefly",
    "Citation abbreviation",
    "Bibliographical abbr.",
    "And others, for short",
    "List ender",
    "Roster curtailer: Abbr.",
    "End of many a list",
    "End of a series: Abbr.",
    "List space saver",
    "\"And others,\" in a bibliography",
    "Listing abbr.",
    "List shortener",
    "Card catalog abbr.",
    "Abbr. at the end of a list",
    "Endnote abbr.",
    "List-ending abbr.",
    "List ender",
    "List-ending abbr.",
    "Series finale, in brief",
    "Bibliographic abbr.",
    "Indexing abbr.",
    "And others: Abbr.",
    "Unnamed others, briefly",
    "Reference abbr.",
    "Citation abbreviation",
    "Series ender: Abbr.",
    "List-ending abbr.",
    "Indication that there's more: Abbr.",
    "Abbr. in a \"works cited\" list",
    "Abbr. after some names",
    "Name-dropper's abbr.?",
    "List-ending abbr.",
    "Citation abbreviation",
    "List-ending abbr.",
    "Footnote abbr.",
    "List-ending abbr.",
    "And others: Abbr.",
    "List ender: Abbr.",
    "And others, in a list",
    "List ender",
    "List ender",
    "Footnote abbr.",
    "Bibliographical abbr.",
    "Authors' list ender",
    "Bibliographical abbr.",
    "Card catalog abbr.",
    "And others, for short",
    "And others, briefly",
    "Substitute for some names",
    "Library indexing abbr.",
    "List ender",
    "Series ender",
    "Series ender",
    "Series terminal",
    "Library catalog abbr.",
    "List-ending abbr.",
    "List-ending abbr.",
    "List shortener",
    "Abbr. for unnamed co-conspirators?",
    "List ender",
    "Series finale",
    "List abbr.",
    "Library catalog abbr.",
    "Enumerator's ending",
    "Card catalog abbr.",
    "Bibliog. space saver",
    "List ender",
    "Series ender",
    "Footnote abbr.",
    "Catalog abbr.",
    "And others, in footnotes",
    "And others: Abbr.",
    "Catchall abbr.",
    "Series finale",
    "List ender",
    "List-ending abbr.",
    "Series follower: Abbr.",
    "List-ending abbr.",
    "And others, for short",
    "List-ending abbr.",
    "Footnote ender: Abbr.",
    "Series ending",
    "And others: Abbr.",
    "Abbr. in a library catalog",
    "List ender",
    "List-ending abbr.",
    "List ender",
    "Card catalog abbr.",
    "List-ending abbr.",
    "Common Latin abbr.",
    "List-ending abbr.",
    "Research paper abbr.",
    "Series ender",
    "List-shortening abbr.",
    "List ender",
    "Series finale",
    "Card catalogue abbr.",
    "And others, for short",
    "Bibliography abbr.",
    "And others: Abbr.",
    "Bibliographic abbr.",
    "And others: Abbr.",
    "Bibliographical abbr.",
    "Bibliog. space saver",
    "Bibliographic abbr.",
    "Bibliographical abbr.",
    "Abbreviated Latin phrase",
    "List-ending abbr.",
    "Bibliography abbr.",
    "Substitute for unmentioned text",
    "Bibliog. space saver",
    "Catalog card abbr.",
    "Inclusive abbr.",
    "List ender",
    "List-ending abbr.",
    "Plus more: Abbr.",
    "List ender",
    "List abbr.",
    "Catchall abbr.",
    "List shortener",
    "Substitution in a list",
    "Abbr. in a listing",
    "List ender",
    "Common Latin abbr.",
    "List ender",
    "And others: Abbr.",
    "Catalog card abbr.",
    "Roster abbr.",
    "And others: Abbr.",
    "List finish",
    "List wrap-up",
    "And others: Abbr.",
    "Catchall abbr.",
    "Footnote abbr.",
    "And others, for short",
    "Series ender",
    "And others: Abbr.",
    "List ender",
    "List ender",
    "Series finale: Abbr.",
    "Catchall abbr.",
    "Roster abbr.",
    "Catchall abbr.",
    "Plus others",
    "List-ending abbr.",
    "List ender",
    "Bibliographical abbr.",
    "Bibliographic abbr.",
    "List shortener",
    "Bibliography abbr.",
    "End of a list of names",
    "Abbr. at the end of a list",
    "Handy abbr.",
    "Substitute for the unnamed",
    "List-shortening abbr.",
    "Library card catalogue abbr.",
    "List-ending abbr.",
    "Catchall abbr.",
    "And others, in brief",
    "List ender",
    "List ender",
    "And others: Abbr.",
    "Catchall abbr.",
    "List shortener",
    "Useful Latin abbr.",
    "List-ending abbr.",
    "And others: Abbr.",
    "Bibliographical abbr.",
    "And more",
    "List ender",
    "Bibliographer's abbr.",
    "List extending abbr.",
    "And others: Abbr.",
    "List shortener",
    "Enumeration shortener",
    "List ender",
    "And others: Abbr.",
    "Card catalogue abbr.",
    "Bibliography abbr.",
    "Library index abbr.",
    "List ender",
    "List ender",
    "List ender",
    "List shortener",
    "Library catalogue abbr.",
    "Bibliographical abbr.",
    "List extender",
    "Kin of etc.",
    "Collective abbr.",
    "And that?Æs not all",
    "Card catalogue abbr.",
    "List ender"
  ],
  "cody": [
    "Buffalo Bill's surname",
    "Wyoming town named for a frontiersman",
    "Wyoming town that's home to the Buffalo Bill Museum",
    "Buffalo Bill's surname",
    "Buffalo Bill",
    "Buffalo Bill",
    "Wyoming town near Yellowstone",
    "Wild West Show star",
    "Wyoming town named for a frontiersman",
    "Scout of renown"
  ],
  "trod": [
    "Walked",
    "Took some steps",
    "Stepped",
    "Walked over",
    "Trampled",
    "Walked heavily",
    "Walked (on)",
    "Walked over",
    "Stepped",
    "Stepped",
    "Stepped heavily (on)",
    "Walked (on)",
    "Clomped (on)",
    "Walked heavily",
    "Stepped (on)",
    "Stamped",
    "Stepped (on)",
    "Took steps",
    "Stepped",
    "Put one's shoes on?",
    "Tromped (on)",
    "Walked",
    "Walked all over",
    "Trampled (on)",
    "Stepped",
    "Put one's foot down",
    "Stepped",
    "Put shoes on?",
    "Put one's foot down",
    "Walked",
    "Trampled (on)",
    "Trampled",
    "___ the boards (acted)",
    "Put one's foot down",
    "Walked",
    "Trampled (on)",
    "Stepped (on)",
    "Padded",
    "Trampled",
    "Trampled",
    "Walked along",
    "Stepped",
    "Set foot (on)",
    "Walked on",
    "Stepped (on)",
    "Set foot (on)",
    "Walked (on)",
    "Walked on",
    "Walked on",
    "Well-___",
    "Walked over",
    "Trampled",
    "Stepped",
    "Plodded",
    "Walked (on)",
    "Walked over",
    "Walked (on)",
    "Tramped",
    "Walked over",
    "Stepped (on)",
    "Padded",
    "Trampled",
    "Stepped",
    "Set foot (on)",
    "Walked",
    "Walked (on)",
    "Walked (on)",
    "Tramped (on)",
    "Stepped",
    "Set foot (on)",
    "Walked (on)",
    "Walked (on)",
    "Repressed, with \"on\"",
    "Walked all over",
    "Stepped (on)",
    "Stepped",
    "Trampled",
    "Marched along",
    "Trampled",
    "Like taken paths",
    "Marched"
  ],
  "noho": [
    "N.Y.C. nabe near N.Y.U.",
    "Manhattan neighborhood west of the East Village",
    "Home of N.Y.C.'s Colonnade Row",
    "L.A.'s ___ Arts District",
    "Lower Manhattan area",
    "Neighborhood in New York or L.A.",
    "Manhattan neighborhood next to the East Village",
    "Artsy L.A. district",
    "Big Apple neighborhood",
    "Big Apple neighborhood",
    "Manhattan neighborhood east of N.Y.U.",
    "Big Apple neighborhood next to the Bowery",
    "N.Y.C. area near the Village",
    "Area near Manhattan's Union Square",
    "Area west of the Bowery",
    "Big Apple neighborhood",
    "Neighborhood west of the Bowery",
    "N.Y.C. neighborhood",
    "It's west of New York's East Village",
    "Big Apple neighborhood west of the East Village",
    "Manhattan neighborhood",
    "Manhattan neighborhood",
    "Neighborhood next to N.Y.C.'s East Village",
    "Manhattan neighborhood"
  ],
  "alfa": [
    "___ Romeo",
    "High-end Italian auto, informally",
    "___ Romeo (Italian auto)",
    "Popular Italian car, informally",
    "Lead-in for Romeo",
    "Letter before Lima and after Foxtrot when spelling 27-Down",
    "Bravo preceder",
    "___ Romeo",
    "Italian car, informally",
    "Soviet nuclear-powered submarine",
    "___ Romeo",
    "Letter before bravo",
    "___ Romeo (Italian car)",
    "___ Romeo",
    "___ Romeo (Italian car)",
    "___ Romeo",
    "\"Bravo\" preceder",
    "___ Romeo",
    "Soviet attack sub",
    "Sporty auto, for short",
    "Zulu's counterpart",
    "Sporty auto, for short",
    "___ Romeo",
    "Romeo's precursor?",
    "___ Romeo",
    "Introduction for Romeo?",
    "Two before Charlie",
    "Romeo's predecessor?",
    "First in a series of 26",
    "___ Romeo (Italian car)",
    "___ Romeo",
    "___ Romeo",
    "Letter preceding bravo",
    "Starting code word",
    "Car driven by James Bond in \"Octopussy,\" for short",
    "Romeo might go after it",
    "Preceder of bravo in a radio alphabet",
    "European sports car, informally",
    "Soviet sub class",
    "___ Romeo (car)",
    "Italian auto, for short",
    "Italian wheels",
    "___-Romeo",
    "Sporty Italian auto, for short",
    "\"Bravo\" preceder",
    "___ Romeo (sports car)",
    "Italian sports car, briefly",
    "Letter in radio communications",
    "Italian car, briefly",
    "___ Romeo (Italian car)",
    "Sports car, familiarly",
    "Code word for \"A\"",
    "Sporty car, for short",
    "___ Romeo",
    "Radio code word",
    "Italian sports car, for short",
    "Lancia competitor, for short",
    "___ Romeo (sports car)",
    "Popular sports car, informally",
    "___ Romeo",
    "A, in communications",
    "Romeo introducer?",
    "Code word",
    "___ Romeo (sports car)",
    "Italian auto, briefly",
    "A, in communications",
    "___ Romeo",
    "Via Veneto auto, briefly",
    "Sports car, briefly",
    "___ Romeo (Italian auto)",
    "Code word for A",
    "Class of submarines",
    "A substitute?",
    "A, in communications",
    "___ Romeo (Italian auto)",
    "___ Romeo (sports car)",
    "Italian car, briefly",
    "___ Romeo",
    "___ Romeo",
    "Italian sports car, informally",
    "___Romeo",
    "Italian auto ___Romeo",
    "A, in code",
    "Code word for \"A\"",
    "\"A\" in radio lingo",
    "___, Bravo, Charlie,...",
    "Italian sports car, informally",
    "___ Romeo",
    "___ Romeo",
    "\"A\" code word",
    "___ Romeo (automobile)"
  ],
  "sown": [
    "Planted",
    "Spread all around",
    "Scattered about",
    "Planted",
    "Planted",
    "Ceded",
    "Planted",
    "Planted",
    "Planted",
    "Planted",
    "Like wild oats",
    "Put in the ground, in a way",
    "Planted",
    "Scattered, as seed",
    "Distributed",
    "Planted",
    "In the earth, now",
    "Scattered",
    "In the ground, in a way",
    "Spread out",
    "Disseminated",
    "Implanted"
  ],
  "doug": [
    "___ Jones, former Alabama senator",
    "1990s Nickelodeon show about a preteen boy",
    "Alabama senator Jones",
    "Illusionist Henning",
    "Flutie of football",
    "QB Flutie",
    "Magician Henning",
    "Baseball's Drabek",
    "Magician Henning",
    "QB Flutie",
    "Animated show on Nickelodeon",
    "QB Flutie",
    "Magic man Henning",
    "McClure of \"The Virginian\"",
    "Magician Henning",
    "Magician Henning"
  ],
  "umno": [
    "\"Yeah ... I don't think so\"",
    "\"Let me think ... hmm, I don't think so\"",
    "\"That's ... never gonna happen\"",
    "\"Let me think ... huh-uh\""
  ],
  "peet": [
    "Actress Amanda",
    "Actress Amanda",
    "Actress Amanda",
    "Actress Amanda of \"Togetherness\"",
    "\"2012\" actress Amanda",
    "Amanda of \"Syriana\"",
    "\"Syriana\" actress Amanda",
    "Amanda of \"The Whole Nine Yards\""
  ],
  "eels": [
    "Some creatures in the ocean's \"midnight zone\"",
    "Flotsam and Jetsam in \"The Little Mermaid\"",
    "Fish whose blood is toxic to humans",
    "Some are shockers",
    "Creatures that can have two sets of jaws and teeth",
    "Fish in the order Anguilliformes",
    "Serpentine swimmers",
    "Wrigglers",
    "Prey for barracudas",
    "Jellied delicacies",
    "Fish that wriggle",
    "Flotsam and Jetsam, in \"The Little Mermaid\"",
    "Slinky swimmers",
    "Some bottom-dwellers",
    "Popular bait for catching striped bass",
    "Slippery fishes",
    "Congers and others",
    "Sand-burrowing marine creatures",
    "Sea cave dwellers",
    "Fishes that may shock you",
    "Certain reef dwellers",
    "Sushi bar stock",
    "Meals for seals",
    "Congers, e.g.",
    "Congers and morays",
    "Slippery swimmers",
    "Ingredients in some London pies",
    "Slithery fishes",
    "Morays and congers",
    "Slippery ones",
    "Ambush predators of the sea",
    "Slippery fish",
    "Morays, e.g.",
    "Mud ___ (bottom-dwelling fish)",
    "Reef dwellers",
    "Sleek swimmers",
    "Sushi fish",
    "88-Down, e.g.",
    "Some use electric organs",
    "Fish caught in pots",
    "Ones unable to swim straight?",
    "Slithery fish",
    "27-Down predators",
    "Fish caught in pots",
    "Some nonkosher fish",
    "Slender fish",
    "Slippery ones",
    "Creatures with electrocytes",
    "Deep shockers",
    "They're difficult to grasp",
    "Coral reef dwellers",
    "Elusive types",
    "Unagi sources",
    "Aquatic shockers",
    "Hydroelectricity providers?",
    "Snaky swimmers",
    "Some jellied dishes",
    "Sources of some leather",
    "They might store electric charges",
    "Fish that can move equally well forward and backward",
    "Fish with only minute fins",
    "Spiny ___ (aquarium fish)",
    "Morays, e.g.",
    "Catch in pots",
    "Sniggler's take",
    "Snaky swimmers",
    "Reef dwellers",
    "They're caught in pots",
    "Slippery swimmers",
    "Often-smoked fish",
    "Wrigglers",
    "Slithery fishes",
    "Jellied dishes in England",
    "They may shock you",
    "Congers",
    "Seals' meals",
    "Some sushi fare",
    "Catch in pots",
    "Elusive swimmers",
    "They're caught in pots",
    "Fish caught in pots",
    "Morays",
    "They're caught in pots",
    "Aquatic zappers",
    "Coral reef dwellers",
    "Lengthy lurkers of the deep",
    "Slippery sorts",
    "Pickled delicacies",
    "Unagi, in a sushi bar",
    "Wriggling fishes",
    "Kin of hagfish",
    "Fish caught in pots",
    "Lampreys, e.g.",
    "Smoked delicacies",
    "Denizens of the Sargasso Sea",
    "Sushi fish",
    "Snakelike fish",
    "Users of electrolocation",
    "Some are shockers",
    "Sinewy creatures",
    "Snigglers' prey",
    "Smoked fish",
    "Squiggly swimmers",
    "Slender fish",
    "Snigglers' catches",
    "Swamp ___ (predatory fish)",
    "Sushi fish",
    "\"The Little Mermaid\" baddies",
    "Snaky swimmers",
    "Unagi and anago, at a sushi bar",
    "They may be charged in the water",
    "Sushi fish",
    "Snakelike fish",
    "Fish captured in pots",
    "Fish lacking ventral fins",
    "Snakelike swimmers",
    "Reef lurkers",
    "They may be smoked or pickled",
    "Wrigglers",
    "Congers",
    "Snaky fishes",
    "Congers and kin",
    "Japanese menu items",
    "Grown-up elvers",
    "Slippery critters",
    "Sushi supplies",
    "Trattoria entree",
    "Spawning fish",
    "Sushi supplies",
    "Wriggly fish",
    "Congers",
    "Wriggly fish",
    "Lampreys",
    "Wriggly fish",
    "Slippery ones",
    "Collared or jellied dishes",
    "Members of a wriggly field?",
    "Congers",
    "Wrigglers",
    "Slippery customers",
    "Mud ___ (salamanders)",
    "Sushi fare",
    "Sushi provisions",
    "Moves via twists",
    "Spitchcocks",
    "Jellied delicacies",
    "Slippery fish",
    "Electricity generators",
    "Grigs",
    "Sushi fare",
    "Grown-up grigs",
    "Sigmoid swimmers",
    "Sigmoid swimmers",
    "Undulant ones"
  ],
  "carr": [
    "Late media columnist David",
    "Apt surname for a mechanic",
    "Best-selling crime novelist Caleb",
    "Longtime media columnist David",
    "Vikki who sang \"It Must Be Him\"",
    "Nicholas ___, author of the 2010 best seller \"The Shallows\"",
    "Novelist Caleb",
    "Caleb who wrote \"The Italian Secretary\"",
    "Late media journalist David",
    "Mystery author John Dickson ___",
    "Author John Dickson ___",
    "Mystery author John Dickson ___",
    "Lloyd in the College Football Hall of Fame",
    "Mystery writer John Dickson ___",
    "Caleb who wrote \"The Alienist\"",
    "Caleb who wrote \"The Alienist\"",
    "Author John Dickson ___",
    "\"The Emperor's Snuff-Box\" novelist",
    "Novelist Caleb",
    "Baker v. ___, landmark 1962 Supreme Court case",
    "Singer Vikki",
    "\"The Emperor's Snuff-Box\" novelist John Dickson ___",
    "Vikki who sang \"It Must Be Him\"",
    "Baker v. ___ (landmark Supreme Court voting rights case)",
    "Caleb who wrote \"The Alienist\"",
    "Mystery writer John Dickson ___",
    "Novelist known for \"locked-room\" mysteries",
    "Writer John Dickson ___",
    "Baker v. ___, 1962 Supreme Court case",
    "\"It Walks by Night\" novelist",
    "Novelist Caleb",
    "Mystery writer John Dickson ___",
    "Mystery writer who created Dr. Gideon Fell",
    "\"The Alienist\" author",
    "\"It Must Be Him\" singer Vikki",
    "\"It Walks by Night\" author",
    "\"It Must Be Him\" singer, 1967",
    "Author John Dickson ___",
    "Detective novelist John Dickson ___",
    "Singer Vikki",
    "\"The Witch of the Low Tide\" mystery novelist",
    "Songstress Vikki",
    "\"The Alienist\" author",
    "Ragtime pianist called \"Fingers\"",
    "Writer famous for locked-room mysteries",
    "Author John Dickson ___",
    "N.F.L. co-founder Joe",
    "Singer Vikki",
    "Dr. Gideon Fell's creator",
    "Mystery writer John Dickson ___",
    "Singer Vikki",
    "Mystery writer John Dickson ___",
    "60's vocalist Vikki"
  ],
  "genx": [
    "Cohort before millennials, for short",
    "Boomers' offspring",
    "Like high school and college students of the '80s-'90s, e.g.",
    "Post-boomer group",
    "MTV's early fan base",
    "MTV's earliest viewers, mostly",
    "Boomers' babies",
    "Post-baby boomer group, for short",
    "Boomers' kids",
    "Baby boomers' kids, informally",
    "Demographic group, briefly",
    "Boomers' kids"
  ],
  "ogle": [
    "It's not a good look",
    "Eye lewdly",
    "Look lecherously",
    "Gawk at",
    "Eye creepily",
    "Stare at, as a creep might",
    "Randy look",
    "Satyr's stare",
    "Exhibit a male gaze, perhaps",
    "Eye amorously",
    "It's not a good look",
    "Gawk at",
    "Look at with lust",
    "Give the glad eye",
    "Eye hungrily",
    "It's not a good look",
    "Check out, in a way",
    "Look at wolfishly",
    "Check out",
    "Spend a long time checking out?",
    "Girl-watch or boy-watch",
    "Beach look?",
    "Body check?",
    "Look at on the beach, say",
    "Look upon with lust",
    "Eye amorously",
    "Have a bad view?",
    "Check out",
    "Devour with the eyes",
    "Perform a full-body scan?",
    "Leer",
    "Look lecherously",
    "Eye",
    "Gaze longingly upon",
    "Make goo-goo eyes at",
    "Check out",
    "Study too much, say",
    "Check out",
    "Gape at",
    "Creepy look",
    "Do a body scan?",
    "Look up and down",
    "Eyeball, in a way",
    "Eye amorously",
    "Inspect the figures?",
    "Give the once-over",
    "Watch like a wolf",
    "Check out",
    "Get an eyeful",
    "Eye amorously",
    "View lasciviously",
    "Check the figures?",
    "Look while delivering a line",
    "Get an eyeful",
    "Give the eye",
    "Eye",
    "Creep's peep",
    "Perform a body scan on?",
    "Look like a creep?",
    "Eye amorously",
    "Wolf's look",
    "Look like a sleaze",
    "Leer",
    "Watch on the beach, maybe",
    "Check out",
    "Give the twice-over?",
    "Rubberneck",
    "Look badly?",
    "Gawk at",
    "Look like a creep",
    "Look at lustfully",
    "Look at amorously",
    "Leer at",
    "Eye",
    "Eye desirously",
    "Regard impolitely",
    "Undress with the eyes",
    "Eye",
    "Eyeball",
    "Eye",
    "Watch like a wolf",
    "Eye",
    "Give the once-over",
    "Gawk at",
    "Gawk at",
    "Give a come-hither look",
    "Regard impolitely",
    "Give a come-hither look",
    "Give a body check?",
    "Eyeball",
    "Eye",
    "Leer at",
    "Eye",
    "Go beyond a once-over",
    "Look like a creep",
    "Look at long ?à and with longing",
    "Make eyes at",
    "View all over",
    "Eyeball",
    "Gape at",
    "Gawk at, as on the beach",
    "Eye",
    "Gawk at",
    "Leer at",
    "Eye up and down",
    "Girl-watch, e.g.",
    "Gawk at",
    "Look up and down",
    "Watch at a strip club, maybe",
    "Stare at",
    "Girl-watch",
    "Stare at",
    "Impertinent look",
    "Look at flirtatiously",
    "Look at flirtatiously",
    "Gawk at",
    "Eye rakishly",
    "Eye amorously",
    "Undress with one's eyes",
    "Eye amorously",
    "Look like a wolf",
    "Eyeball",
    "Look at a looker, perhaps",
    "Lust after, visually",
    "Look accompanying a line",
    "View up and down",
    "Stare impertinently",
    "Check out, so to speak",
    "Watch amorously",
    "Eyeball",
    "Look at lustfully",
    "Give the look to",
    "Look like a wolf",
    "Look at flirtatiously",
    "Lothario's look",
    "Act the letch",
    "Eye up and down",
    "Make eyes at",
    "Eye",
    "Look like a wolf?",
    "Make eyes at",
    "Look up and down",
    "Eye",
    "Eye up and down",
    "Eye rakishly",
    "Lecher's look",
    "Hungry look",
    "Watch the figures?",
    "Eye up and down",
    "Look lustfully",
    "Look longingly at",
    "Emulate Groucho Marx",
    "Look at flirtatiously",
    "Oeillade",
    "Rubberneck",
    "Study a la studs",
    "Eye amorously",
    "Flirt with, maybe",
    "Stare at",
    "Girl-watch or boy-watch",
    "Leer at",
    "Interested look",
    "Make eyes at",
    "Give a longing look",
    "Leer",
    "Stare",
    "Play the wolf",
    "Get a load of",
    "Make goo-goo eyes at",
    "Eye of the wolf?",
    "Girl- or boy-watch",
    "Check out, so to speak",
    "Gape at",
    "Eyeball",
    "Early film star Charles ___",
    "Give the eye",
    "Leer at",
    "Eye",
    "Give the eye",
    "Look up and down",
    "Girl-watch",
    "Look up and down",
    "Eye at the beach",
    "Show infatuation with",
    "Give the eye",
    "Gaze amorously",
    "Eye"
  ],
  "ream": [
    "Chew (out)",
    "Sheets often come in it",
    "Chew (out)",
    "500 sheets of paper",
    "Extract juice from",
    "Criticize harshly, with \"out\"",
    "20 quires = 1 ___",
    "Bawl (out)",
    "500 sheets of paper",
    "500 sheets of paper",
    "500 sheets",
    "Castigate, with \"out\"",
    "Paper purchase",
    "Stack of sheets",
    "Enlarge, in a way",
    "500 sheets of paper",
    "Copier paper buy",
    "20 quires",
    "Chew (out)",
    "Set of sheets?",
    "Curse (out)",
    "500 sheets of paper",
    "Staples staple",
    "Widen, as a gun barrel",
    "Bawl (out)",
    "500 sheets of paper",
    "20 quires of paper",
    "Paper quantity",
    "500 sheets",
    "Berate, with \"out\"",
    "Enlarge a hole in",
    "Severely reprimand, with \"out\"",
    "500 sheets of paper",
    "Bawl out",
    "Print shop unit",
    "500 sheets of paper",
    "Criticize severely, with \"out\"",
    "500 sheets",
    "Berate, with \"out\"",
    "Set of sheets",
    "Curse out",
    "500 sheets",
    "\"The Office\" unit",
    "Copier unit",
    "500 sheets",
    "Paper purchase",
    "500 sheets",
    "500 sheets",
    "Stationery order",
    "Chew (out)",
    "Chew out",
    "Photocopier tray capacity, maybe",
    "Stationer's unit",
    "Paper quantity",
    "Printer's amount",
    "Swindle, in slang",
    "Enlarge, in a way",
    "Paper purchase",
    "Bawl out in no uncertain terms",
    "Stationery store buy",
    "Kinko's unit",
    "Paper purchase",
    "Widen, in a way",
    "Printing unit",
    "Print shop purchase",
    "Enlarge, as a hole",
    "Bawl (out)",
    "Enlarge, as a hole",
    "500 sheets",
    "Defraud",
    "Stationery quantity",
    "Printer's unit",
    "Use a juicer on",
    "Enlarge, as a hole",
    "Printer's amount",
    "Do boring work",
    "500 sheets",
    "Set of sheets?",
    "Printer's amount",
    "Stationer's order",
    "Typist's purchase",
    "Stationery order",
    "Stationer's order",
    "500 sheets",
    "Chew out",
    "Stationery purcnase",
    "Print shop order",
    "Printer's___",
    "Paper quantity",
    "Paper quantity",
    "500 sheets",
    "Use a juicer",
    "Paper quantity",
    "Defraud",
    "Stationery order",
    "Lambaste, with \"out\"",
    "500 sheets",
    "Get juice from",
    "Clear of debris",
    "Enlarge, as a hole",
    "20+ quires"
  ],
  "bari": [
    "Italian home to the Basilica of St. Nicholas",
    "Capital of Italy's Apulia region",
    "Port in southern Italy",
    "Southern Italian port",
    "City and province of southern Italy",
    "Adriatic port",
    "Italian city on the Adriatic",
    "Port of Italy",
    "Port on the Adriatic",
    "Adriatic seaport",
    "Italian port",
    "Italian city associated with the real-life Saint Nicholas",
    "Italian province or seaport",
    "Italian seaport that's home to Saint Nicholas's relics",
    "Basilica di San Nicola locale",
    "Italian port on the Adriatic",
    "Capital of the Apulia region",
    "Italian port on the Adriatic",
    "Adriatic port",
    "Italian port",
    "Apulia's capital",
    "Adriatic seaport",
    "Italian port on the Adriatic",
    "Adriatic port",
    "Adriatic port",
    "Adriatic seaport",
    "Adriatic port",
    "Adriatic port",
    "Adriatic port",
    "Italian port on the Adriatic",
    "Adriatic seaport",
    "Historic Adriatic port"
  ],
  "ohio": [
    "Where I-70 meets I-71",
    "River past Louisville",
    "Home of Cincinnati",
    "Home of the Polka Hall of Fame and Pro Football Hall of Fame",
    "Reds state?",
    "River that starts in Pittsburgh",
    "Birthplace of 35-Across",
    "Home to the Cedar Point amusement park",
    "State that's said to be \"high in the middle\"",
    "Lima locale",
    "North/South divide, with \"the\"",
    "Home of the first man to walk on the moon, the first American to orbit Earth and the first American woman to walk in space",
    "See 12-Across",
    "Pro Football Hall of Fame locale",
    "Home to Xenia and Zanesville, the most populous U.S. cities starting with \"X\" and \"Z\"",
    "University in Athens",
    "Neighbor of Pennsylvania",
    "Columbus's home",
    "Separator of Indiana and Pennsylvania",
    "State said to be \"high in the middle\"",
    "River originating in Pittsburgh",
    "Columbus's home",
    "River that formed an extension of the Mason-Dixon line",
    "Interstates 70 and 71 cross in its capital",
    "Toledo's home",
    "Where I-70 meets I-75",
    "Home to Bowling Green",
    "Akron's home",
    "Home of Wright State University",
    "Lima's home",
    "River into which the Big Sandy and the Little Sandy flow",
    "Only state with a nonrectangular flag",
    "Neil Young song about Kent State",
    "Cleveland's state",
    "John Kasich's state",
    "Answer to the old riddle \"What's round on the ends and high in the middle?\"",
    "Its postal abbreviation is also an exclamation",
    "Home of the first full-time automobile service station",
    "College sports' ___ Valley Conference",
    "Cincinnati's home",
    "Mississippi River's largest tributary",
    "Closely watched state on election night",
    "Home for the College of Wooster",
    "Lima's place",
    "River that starts at Pittsburgh",
    "Its first capital was Chillicothe, 1803-10",
    "___ Drive, thoroughfare by the Lincoln Memorial in Washington",
    "Home of Miami University",
    "River into which the Great Miami flows",
    "Home of the U.S.'s largest cities whose names start with X and Z",
    "Louisville's river",
    "Athens's home",
    "The Wright brothers' home",
    "The Allegheny and Monongahela join to form it",
    "What the French once called \"la Belle Riviere\"",
    "Home of Lima and Toledo",
    "Answer to the old riddle \"What's round on the sides and high in the middle?\"",
    "Home to the Browns and the Reds",
    "State with a large Amish population",
    "Cairo's river",
    "Indiana's smallest county or the river it touches",
    "Indiana/Kentucky border river",
    "Home of the Rock and Roll Hall of Fame",
    "Mississippi River's largest tributary",
    "Cincinnati's place",
    "So-called Mother of Presidents",
    "One of the Three Rivers",
    "River forming the borders of parts of five states",
    "Kent State locale",
    "River that ends at Cairo",
    "1970 hit by Crosby, Stills, Nash and Young",
    "Title locale in a Leonard Bernstein song where \"life was so cozy\"",
    "River into which the Big Sandy flows",
    "Its license plates say \"Birthplace of Aviation\"",
    "The Bobcats of the Mid-American Conference",
    "One of the Three Rivers",
    "One of Pittsburgh's three rivers",
    "Home to Columbus",
    "One of the states touched by 15-Across",
    "Allegheny + Monongahela",
    "Akron's home",
    "Birthplace of seven U.S. presidents",
    "Wheeling's river",
    "I-90 runs through it",
    "Athens's setting",
    "Canton's home",
    "Mid-American Conference team",
    "Grant's birthplace",
    "Where John Glenn was senator",
    "Miami River's outlet",
    "Birthplace of seven U.S. presidents",
    "Buckeye State",
    "One of the rivers near Three Rivers Stadium",
    "Home to Athens",
    "Buckeye State",
    "Kent State's state",
    "So-called Birthplace of Aviation",
    "Canton's home",
    "Baltimore partner",
    "The Buckeye State",
    "Perennial battleground state",
    "Cairo's river",
    "Kent State state",
    "Birthplace of seven U.S. presidents",
    "Home to Columbus",
    "John Glenn's state",
    "The Buckeye State",
    "Home for Ulysses G. Grant",
    "Cincinnati's home",
    "Akron's home",
    "Columbus's home",
    "Home of the First Ladies National Historic Site",
    "Where Athens and Dublin are",
    "Where the Reds and the Browns play",
    "Buckeye's home",
    "Cincinnati's home",
    "See 9-Across",
    "Home to Columbus",
    "Ulysses Grant's birthplace",
    "Where Canton is",
    "Buckeye State",
    "West Virginia border river",
    "Where Ivory soap and the pop-top can were invented",
    "Buckeye's home",
    "Buckeye's home",
    "Wheeling's river",
    "\"The Drew Carey Show\" setting",
    "___ Valley Conference",
    "See 63-Across",
    "Not-so-lazy river",
    "Cairo's river",
    "Louisville's river",
    "Where Lima is",
    "Louisville's river",
    "The Buckeye State",
    "Wheeling's river",
    "Home to Columbus",
    "Oberlin locale",
    "Birthplace of seven Presidents",
    "Toledo's home",
    "Louisville's river",
    "Kent's state",
    "Mississippi feeder",
    "Athens's home",
    "Glenn's state",
    "Akron's home",
    "One of the three rivers of Three Rivers Stadium",
    "Bobcats of college football",
    "Louisville's river",
    "Columbus's home",
    "Hit protest song of 1970",
    "Miami University location",
    "Buckeye State",
    "Miami of ___",
    "Buckeye State",
    "Home to 19 Congressmen",
    "Xenia's state",
    "Wheeling's river",
    "Sherwood Anderson's \"Winesburg, ___\"",
    "Football Hall of Fame locale",
    "Xenia's home",
    "Columbus's home"
  ],
  "egad": [
    "\"Holy mackerel!\"",
    "\"Goodness gracious!\"",
    "\"Holy moly!\"",
    "\"Wow!,\" quaintly",
    "\"Holy moly!\"",
    "\"My, my!\"",
    "\"Omigosh!\"",
    "\"Holy moly!\"",
    "\"Zoinks!\"",
    "\"Omigosh!\"",
    "\"Jiminy Christmas!\"",
    "\"Good heavens!\"",
    "\"Gracious!\"",
    "\"Good gravy!\"",
    "\"Heavens to Murgatroyd!\"",
    "\"Yipes!\"",
    "\"Heavens!\"",
    "\"My word!\"",
    "\"Holy Toledo!\"",
    "\"Holy moly!\"",
    "\"Zounds!\"",
    "\"Heavens to Betsy!\"",
    "\"My stars!\"",
    "\"Holy cow!\"",
    "\"Heavens!\"",
    "\"Holy mackerel!\"",
    "\"Gosh!\"",
    "Old-fashioned outburst",
    "\"OMG!,\" old-style",
    "\"Yipe!\"",
    "\"Great Scott!\"",
    "\"Yikes!\"",
    "\"That was unexpected!\"",
    "\"Yikes!\"",
    "\"Zoinks!\"",
    "\"Goodness gracious!\"",
    "\"OMG!,\" old-style",
    "Whimsical outburst",
    "\"Holy moly!\"",
    "\"Heavens to Betsy!\"",
    "Quaint shout",
    "\"My word!\"",
    "Often-affected outburst",
    "\"Holy cats!\"",
    "\"Holy guacamole!\"",
    "Old-style oath",
    "Old-timey oath",
    "Quaint euphemism",
    "\"Horrors!\"",
    "\"Gosh almighty!\"",
    "\"Heavens to Betsy!\"",
    "\"Dear me!\"",
    "\"My heavens!\"",
    "\"My word!\"",
    "\"Yikes!\"",
    "Edwardian expletive",
    "Oath of old",
    "\"Good grief!\"",
    "Minced oath",
    "\"Yikes!\"",
    "\"Zounds!\"",
    "\"Yikes!\"",
    "G-rated oath",
    "Surprised reaction",
    "Oath from a renegade",
    "\"Yikes!\"",
    "Old expletive",
    "\"Holy cow!\"",
    "\"Glorioski!\"",
    "\"Holy cow!\"",
    "\"Goodness gracious!\"",
    "\"Zounds!\"",
    "Old-time oath",
    "\"Heavens!\"",
    "\"Great Scott!\"",
    "Quaint exclamation",
    "\"Zounds!\"",
    "\"Good gracious!\"",
    "\"Holy moly!\"",
    "\"Zounds!\"",
    "\"Yikes!\"",
    "\"Yikes!\"",
    "\"Yikes!\"",
    "Old oath",
    "\"Yikes!\"",
    "\"Heavens to Betsy!\"",
    "\"Omigosh!\"",
    "\"Wow!\"",
    "\"Oh, golly!\"",
    "\"Yikes!\"",
    "Melodramatic outburst",
    "\"Yikes!\"",
    "\"Zounds!\"",
    "\"Zounds!\"",
    "\"Holy Toledo!\"",
    "Quaint outburst",
    "Old-time exclamation",
    "\"Holy smokes!\"",
    "Old oath",
    "Victorian outburst",
    "\"Good grief!\"",
    "\"Zounds!\"",
    "Quaint cry of shock",
    "Quaint cry of horror",
    "\"Holy Toledo!\"",
    "\"Yipe!\"",
    "\"Holy smoke!\"",
    "\"Yikes!\"",
    "\"Zounds!\"",
    "\"Goodness gracious!\"",
    "Relative of \"Oh, no!\"",
    "\"Heavens to Betsy!\"",
    "\"Yikes!\"",
    "Old-fashioned outburst",
    "\"Goodness gracious!\"",
    "\"Omigosh!\"",
    "Dated expletive",
    "\"Good heavens!\"",
    "Old-time oath",
    "\"Yikes!\"",
    "Old oath",
    "\"Zounds!\"",
    "Cry of shock",
    "\"My word!\"",
    "Quaint outburst",
    "Antiquated outburst",
    "\"Holy smokes!\"",
    "Major Hoople's holler",
    "Old-time oath",
    "\"Our Boarding House\" oath",
    "\"Holy moly!\"",
    "Old-time oath",
    "\"Zounds!\"",
    "\"Holy cow!\"",
    "\"Omigosh!\"",
    "\"Holy cow!\"",
    "\"Holy smokes!\"",
    "Major Hoople's outburst, in old comics",
    "Old-time oath",
    "\"Horrors!\"",
    "Cry of mock horror",
    "Antiquated euphemism",
    "\"Holy moly!\"",
    "\"My stars!\"",
    "\"Holy Toledo!\"",
    "\"Yipes!\"",
    "\"By gar!\"",
    "\"Yikes!\"",
    "\"Holy moly!\"",
    "\"My word!\"",
    "\"Omigosh!\"",
    "\"Holy moly!\"",
    "\"Heavens to Betsy!\"",
    "\"Holy mackerel!\"",
    "\"Holy cow!\"",
    "\"Yikes!\"",
    "Cousin of \"Omigod!\"",
    "\"Yikes!\"",
    "Mild oath",
    "\"Holy moly!\"",
    "\"Yikes!\"",
    "Mild epithet",
    "\"Holy smokes!\"",
    "\"Holy cow!\"",
    "\"Omigod!\"",
    "\"Zounds!\"",
    "\"Yikes!\"",
    "\"Holy moly!\"",
    "Major Hoople's exclamation, in the comics",
    "\"Oh, my!\"",
    "Minced oath of old",
    "\"Heavens!\"",
    "\"Holy cow!\"",
    "\"Holy moly!\"",
    "\"Yipes!\"",
    "\"Holy moly!\"",
    "\"Holy smoke!\"",
    "\"Holy moly!\"",
    "\"Zounds!\"",
    "\"Holy cow!\""
  ],
  "oboe": [
    "Instrument played by indie rock's Sufjan Stevens",
    "Wind with a three-octave range",
    "Strauss's \"Concerto in D Major for ___ and Small Orchestra\"",
    "Instrument that an orchestra tunes to",
    "Double-reeded aerophone with keys",
    "Instrument with a flared end",
    "Wind up on the pitch?",
    "One with a solo in Brahms's Symphony No. 1",
    "Relative of a cor anglais",
    "Instrument featured in 36-Across",
    "One of two or three in a typical orchestra",
    "O, in the W.W. II Army/Navy alphabet",
    "Light wind?",
    "Instrument heard in \"I Got You, Babe\"",
    "Cousin of a clarinet",
    "Orchestra tuner",
    "Reed in a pit",
    "Peter preceder, in a phonetic alphabet",
    "It leads the orchestra in tuning",
    "Instrument from the French for \"high wood\"",
    "Instrument with a brief solo in Beethoven's Fifth",
    "It has cork and a bell",
    "Slender woodwind",
    "Wind in a pit",
    "Cousin of a bassoon",
    "Letter in the W.W. II phonetic alphabet",
    "Instrument with metal keys",
    "Source of some penetrating notes",
    "Instrument that represents the duck in \"Peter and the Wolf\"",
    "Wind in a pit",
    "Instrument whose name sounds like a rebuke of Obama's dog",
    "___ d'amore",
    "Item with a bore and a bell",
    "High wind",
    "Letter before Peter in a phonetic alphabet",
    "One of two to four in a standard orchestra",
    "Its \"reeds are a pain / And the fingering's insane,\" per Ogden Nash",
    "Instrument with cane blades",
    "Relative of a musette",
    "Instrument that an orchestra tunes to",
    "Letter before Peter in the Joint Army/Navy Phonetic Alphabet",
    "Instrument with octave keys",
    "Orchestra reed",
    "High wind",
    "It's usually behind a viola in an orchestra",
    "Orchestra reed",
    "Musical instrument with a flared end",
    "Double-reed woodwind",
    "Something that may be found in a pit",
    "The duck in \"Peter and the Wolf\"",
    "Instrument that begins an orchestra's tune-up",
    "Slender woodwind",
    "Woodwind descended from the shawm",
    "Instrument whose name means \"high wood\"",
    "Instrument similar to a cor anglais",
    "Double-reed instrument",
    "High wind",
    "Heckelphone lookalike",
    "Relative of a clarinet",
    "Wind quintet member",
    "Cousin of a bassoon",
    "Cousin of a clarinet",
    "Letter before Peter in a phonetic alphabet",
    "Double-reed woodwind",
    "Cousin of a cor anglais",
    "Instrument that tunes an orchestra",
    "Light wind?",
    "Relative of a bassoon",
    "Slender reed",
    "Instrument used to set the pitch for an orchestra",
    "High wind?",
    "O, in a phonetic alphabet",
    "Conical woodwind",
    "Strauss wrote a concerto in D for it",
    "Instrument called \"an ill wind that nobody blows good\"",
    "Certain woodwind",
    "Instrument with metal keys",
    "Concerto soloist, perhaps",
    "Double-reeded instrument",
    "Instrument with a bell",
    "Bassoon relative",
    "Cousin of a bassoon",
    "The duck in Prokofiev's \"Peter and the Wolf\"",
    "Woodwind able to provide an orchestra's tuning note",
    "Sounder of the tuning note at the start of an orchestra rehearsal",
    "Instrument heard in Sonny & Cher's \"I Got You Babe\"",
    "\"An ill wind ...\" instrument",
    "Cousin of an English horn",
    "Wind in front of a stage",
    "Letter before Peter in an old phonetic alphabet",
    "Instrument for Johann Jacob Bach",
    "A reed",
    "Double-reed woodwind",
    "Member of a pit crew?",
    "High-pitched wind",
    "Melancholy instrument",
    "___ d'amore (instrument)",
    "Wind up on the stage?",
    "Wind in a pit",
    "Double-reed instrument",
    "Cousin of a heckelphone",
    "Kind of reed",
    "\"O\" in the old Army phonetic alphabet",
    "Instrument held with two hands",
    "Instrument you blow into",
    "Wind in a pit",
    "Wind with a wide range",
    "Cousin of a bassoon",
    "One found in the woods",
    "Double reed",
    "Light wind?",
    "___ d'amore",
    "Poulenc's \"Sonata for ___ and Piano\"",
    "Certain aerophone",
    "Baby bassoon?",
    "Melancholy woodwind",
    "Letter before Peter in old radio lingo",
    "Double-reed woodwind",
    "Instrument with a conical bore",
    "Radio letter between Nan and Peter",
    "It's blown",
    "One of the winds",
    "Concert wind",
    "Preceder of Peter in a phonetic alphabet",
    "The duck in \"Peter and the Wolf\"",
    "\"O\" in a phonetic alphabet",
    "Peter and the Wolf's \"duck\"",
    "See 27-Across",
    "Penetrating wind",
    "Bassoon's little cousin",
    "Double-reed instrument",
    "Wind up on stage?",
    "Tuning note instrument",
    "Cousin of an English horn",
    "Double-reeded woodwind",
    "Slender woodwind",
    "Symphony member",
    "Slender instrument",
    "___ d'amore",
    "Cousin of a bassoon",
    "Cousin of a bassoon",
    "\"O\" in old radio lingo",
    "Wind up on stage?",
    "Wind that can be piercing",
    "Penetrating reed",
    "Certain woodwind",
    "___ family, including bassoons and English horns",
    "Woodwind",
    "Old radio word for the letter O",
    "Musical instrument that's blown into",
    "Slender instrument",
    "Woodwind",
    "Plaintive woodwind",
    "Relative of an English horn",
    "Certain 55-Across",
    "Orchestra seat",
    "Symphony member",
    "Reed in a pit",
    "Clarinet's kin",
    "It has finger holes",
    "Reed instrument",
    "Woodwind",
    "Instrument played with the mouth",
    "Certain band member",
    "Woodwind",
    "Woodwind instrument",
    "Tubular instrument",
    "Wind in a pit",
    "Long, thin musical instrument",
    "Mitch Miller's instrument",
    "\"O\" in old radio lingo",
    "Wind instrument",
    "Kin to a clarinet",
    "Woodwind",
    "Cousin of the bassoon",
    "Organ stop",
    "Reed section member",
    "Woodwind",
    "Woodwind",
    "Reed section member",
    "Snake charmer, in musician's slang",
    "Kind of concerto",
    "Concerto instrument",
    "Shawm descendant",
    "Slender reed",
    "\"O\" in old radio lingo",
    "Long, slender instrument",
    "Double reed",
    "Woodwind",
    "Wind quintet member",
    "Sweet-toned musical instrument",
    "Instrument that's blown into",
    "It's in the winds",
    "Hand-held musical instrument",
    "Double-reed woodwind",
    "Cousin of a bassoon",
    "Woodwind",
    "Philharmonic part",
    "Orchestra part",
    "Bassoon's cousin",
    "Word from the French for \"high wood\"",
    "Cousin of the English horn",
    "___ d'amore",
    "Clarinet cousin",
    "\"O\" in old radio lingo",
    "Woodwind",
    "Double-reed instrument",
    "Mitch Miller's instrument",
    "Mitch Miller's instrument",
    "Its pitch is high",
    "Ensemble part",
    "Cousin of a clarinet",
    "Double-reed instrument",
    "Relative of the English horn",
    "Mitch Miller's instrument",
    "Double-reed",
    "___d'amour",
    "Orchestra part",
    "Wide-range reed",
    "\"O\" to ham operators, once",
    "Kind of pipe",
    "Cousin of the bassoon",
    "Shawm descendant",
    "Melancholy instrument",
    "Hautboy",
    "Sound lower than a flute",
    "Cousin of the English horn",
    "Bassoon relative",
    "Slender instrument",
    "Tubular instrument",
    "Orchestral instrument",
    "O, once, to hams",
    "Relative of the English horn",
    "Woodwind",
    "___ d'amore (baroque instrument)",
    "Penetrating wind",
    "Mitch Miller's instrument",
    "Cousin of the English horn",
    "The duck in \"Peter and the Wolf\"",
    "Heckelphone",
    "Hautboy",
    "Woodwind",
    "Relative of the heckelphone",
    "Double-reed woodwind",
    "Reed",
    "Woodwind",
    "Mitch Miller's instrument",
    "Woodwind instrument",
    "Tuneful pipe",
    "Philharmonic instrument",
    "Relative of the flute",
    "The duck in \"Peter and the Wolf\"",
    "Organ stop",
    "Literally \"high wood\"",
    "Reed of note"
  ],
  "flus": [
    "Winter bugs",
    "Winter ailments",
    "Winter bugs",
    "Seasonal threats",
    "Some shot targets",
    "Fever causes",
    "Unwelcome strains",
    "Bugs",
    "Strains in the winter?",
    "Nasty bugs"
  ],
  "fats": [
    "Part of the food pyramid",
    "What a keto diet is high in",
    "Nutrition Facts listings",
    "Domino, familiarly",
    "They can be saturated",
    "They're encouraged on a ketogenic diet",
    "Expansion group?",
    "Part of the food pyramid",
    "Antoine Domino Jr., familiarly",
    "Domino often played?",
    "Domino getting played",
    "Suet and blubber",
    "Sometimes-saturated substances",
    "Domino, e.g.",
    "Some are saturated",
    "Good or bad intake",
    "Some nutrients",
    "Trans ___",
    "Domino, e.g.",
    "Dietary needs",
    "Dietary needs",
    "Minnesota ___",
    "Trans ___",
    "Chief parts of adipose tissue",
    "Saturated substances",
    "Saturated substances",
    "Minnesota ___",
    "Minnesota ___",
    "Some dietary intake",
    "50's-60's singer ___ Domino",
    "Minnesota ___",
    "Nonmuscular parts",
    "Dieter's concerns",
    "Minnesota appellation",
    "With 44 Across, \"Ain't Misbehavin\" songwriter",
    "Hustler from Minnesota"
  ],
  "plum": [
    "Fruit in the William Carlos Williams poem \"This Is Just to Say\"",
    "Shade of purple",
    "Choice",
    "Professor in a library, perhaps",
    "Desirable, as a job",
    "Coveted, as a position",
    "Juicy fruit with a pit",
    "Color close to puce",
    "Coveted, as a position",
    "Purple shade",
    "Professor ___, Clue suspect",
    "Job everyone wants",
    "Desirable, as a job",
    "Choice job",
    "Extremely desirable",
    "Windfall",
    "Coveted",
    "Pudding fruit",
    "Desirable thing",
    "Coveted prize",
    "Choice assignment",
    "Prune, before drying",
    "Jack Horner's find",
    "Prized",
    "Purple color",
    "Prune, formerly",
    "Kind of tomato",
    "Horner's find",
    "Jack Horner's surprise",
    "High-paying easy job",
    "Deep purple"
  ],
  "dodo": [
    "Bird that had no natural predators until humans arrived",
    "Bird with a reduplicative name",
    "Knucklehead",
    "Dumb bunny",
    "Bird in \"Alice's Adventures in Wonderland\"",
    "\"Dumb\" bird",
    "Dummy",
    "Bird akin to the nene?",
    "Numbskull",
    "Nincompoop",
    "Epitome of stupidity",
    "\"Dumb\" bird",
    "Bubblehead",
    "Slow-witted sort",
    "Blockhead",
    "Dummkopf",
    "Dim bulb",
    "Numbskull",
    "Dummy",
    "Knucklehead",
    "Dummy",
    "Dullard",
    "Nincompoop",
    "Birdbrain",
    "Ninny",
    "Dummy",
    "Airhead",
    "Dummy",
    "Dimwit",
    "Awarder of a thimble to Alice, in \"Alice's Adventures in Wonderland\"",
    "\"Alice in Wonderland\" character",
    "Dimwit",
    "Dumb ox",
    "Nitwit",
    "Dummy",
    "Part of Mauritius's coat of arms",
    "Animal on Mauritius's coat of arms",
    "Bye-bye birdie?",
    "Thickhead",
    "Numbskull",
    "Long-gone bird",
    "Dumbbell",
    "Extinct bird",
    "Dumbbell",
    "Lamebrain",
    "One slow on the uptake",
    "Dummy",
    "Dull-witted one",
    "Dummkopf",
    "Numbskull",
    "Knucklehead",
    "Dumb cluck",
    "Birdbrain",
    "Extinct bird",
    "Old fogy",
    "Numbskull",
    "Bird that's more than rare",
    "Nitwit",
    "Pinhead",
    "Wonderland bird",
    "Winged one in Wonderland",
    "A genius? No",
    "Dummy",
    "Dummy",
    "No Einstein",
    "Dumb cluck",
    "Dumbbell",
    "___ bird",
    "Fuddy-duddy",
    "Extinct bird not known for its intelligence",
    "Dumbbell",
    "Dummy",
    "Dunderhead",
    "Numbskull",
    "Idiot",
    "Extinct bird",
    "Old fogy"
  ],
  "tvad": [
    "Campaign expense",
    "Spot between programs, e.g.",
    "Show stopper",
    "Something often skipped using a DVR",
    "It might be muted",
    "Prompter of a channel change, maybe",
    "Infomercial, e.g.",
    "Big Super Bowl purchase",
    "Broadcast commercial",
    "Spot to watch",
    "Infomercial, e.g.",
    "Spot, maybe",
    "It may run during a campaign",
    "Spot on a small screen",
    "Certain spot",
    "Show stopper?",
    "30- or 60-second spot",
    "Spot",
    "Big Super Bowl expense",
    "30-second spot, e.g.",
    "Inside pitch?",
    "Campaign expense",
    "Bit of Madison Ave. planning",
    "Spot",
    "30-second spot, e.g."
  ],
  "desk": [
    "Its underside might be covered in gum",
    "Dorm room feature",
    "Pupil's place",
    "Much can be written on this",
    "Classroom assignment",
    "Piece of dorm room furniture",
    "Work station",
    "News ___",
    "Spot for a teacher's apple or Apple",
    "Place for an anchor",
    "Schoolroom assignment",
    "Office furniture",
    "Metro ___",
    "A cluttered one is a sign of a cluttered mind, it's said",
    "Rolltop, e.g.",
    "Place for a laptop other than a lap",
    "Feature of a late-night show set",
    "Anchor's place",
    "What many writers write on",
    "2-Down fixture",
    "Foreign ___",
    "Library carrel, basically",
    "Newsroom post",
    "___ jockey (office worker)",
    "Newspaper post",
    "News station",
    "___ job",
    "Post-monologue spot for Jay Leno",
    "Many a security point",
    "Secretary, e.g.",
    "Information ___",
    "Typewriter's spot",
    "Anchor's place",
    "Piece of office furniture",
    "Cubicle fixture",
    "Word after foreign or city",
    "PC site",
    "Office holder, of sorts",
    "You may work hard at this",
    "Place for a blotter",
    "Secretary, e.g.",
    "Office furniture",
    "Drawer holder",
    "Secretary, for one",
    "Kneehole site",
    "Editor's ___",
    "Office necessity",
    "Work station",
    "Secretary, e.g.",
    "Telephone site",
    "Place for drawers",
    "Office station",
    "Work station",
    "Pigeonhole's place",
    "Work station",
    "Place for a phone",
    "Inkstand's site",
    "Place to work",
    "Education station",
    "Study piece",
    "Literary site?",
    "Place to work",
    "With 62-Down, office worker's ensemble",
    "Education station",
    "Station",
    "Pigeonhole's place",
    "Pigeonhole's place",
    "Newsroom fixture",
    "Dorm room feature",
    "Foreign ___",
    "PC site",
    "Study furniture",
    "Drawer site",
    "Work space",
    "City ___",
    "Carrel filler",
    "PC place",
    "Secretary, for one",
    "Escritoire",
    "Where a student sits",
    "Schoolroom fixture",
    "Escritoire",
    "Newspaper department",
    "Secretary, e.g.",
    "Office station",
    "Escritoire",
    "Carrel"
  ],
  "tale": [
    "Yarn",
    "That's some story",
    "Account",
    "Fairy ___",
    "Account",
    "A tall one is exaggerated",
    "Yarn",
    "Made-up story",
    "Story",
    "\"A ___ of Two Cities\"",
    "Whodunit, e.g.",
    "What a fisherman might bring home even if he doesn't catch any fish",
    "Account",
    "Something to spin",
    "Fish story",
    "Shakespeare's \"The Winter's ___\"",
    "Yarn",
    "Chaucer offering",
    "It may be recounted",
    "Fish story",
    "\"The Twilight Zone\" episode, usually",
    "See 47-Across",
    "Bit of campfire entertainment",
    "Related thing",
    "Minstrel's offering",
    "Account from Scheherazade",
    "Chaucer piece",
    "Woven piece",
    "Campfire story",
    "Offering from the Brothers Grimm",
    "Something not to be believed",
    "Yarn",
    "\"Paul Bunyan and Babe the Blue Ox,\" e.g.",
    "Relation?",
    "Fish story",
    "It might be spun around a campfire",
    "It's often handed down",
    "Scheherazade offering",
    "Poe writing",
    "88-Across, for one",
    "Dickens wrote one about two cities",
    "One can be tall",
    "Fisherman's relation?",
    "Account",
    "Something that's related",
    "Chaucer piece",
    "Something to weave",
    "Recitation by Scheherazade",
    "Something that's spun",
    "\"Treasure Island,\" for one",
    "Fireside recitation",
    "Related thing",
    "Fisherman's whopper?",
    "\"Treasure Island,\" e.g.",
    "Poe story, e.g.",
    "Tall story",
    "Romance, e.g.",
    "Something that's spun",
    "Output from Washington Irving",
    "Legend",
    "A fisherman might bring back a big one",
    "Something to spin",
    "Falsehood",
    "Yarn",
    "Fisherman's story",
    "Chronicle",
    "It may be supernatural",
    "Hard-to-believe story",
    "Account",
    "Poe product",
    "Story",
    "Story",
    "Chaucer offering",
    "Fable",
    "\"The Gift of the Magi,\" e.g.",
    "Something to spin",
    "Narrative",
    "Grimm offering",
    "Raconteur's offering",
    "Uncle Remus offering",
    "Any Poe story",
    "It may be spun around a campfire",
    "Whopper",
    "Load of bunk",
    "Romance, e.g.",
    "It may be tall",
    "Any \"Twilight Zone\" episode",
    "It's related",
    "Something that's spun",
    "Writing of Chaucer",
    "Scheherazade's lifesaver",
    "Whopper",
    "Romance, perhaps",
    "Yarn that is spun",
    "Something spun",
    "Raconteur's offering",
    "Yarn",
    "Whopper",
    "Chaucer piece",
    "Big lie",
    "Tall story",
    "Spellbinder",
    "A fisherman may spin one",
    "Wife of Bath's offering",
    "It's tall when exaggerated",
    "It's hard to believe",
    "Rumor",
    "Whopper",
    "A fisherman may bring one home",
    "Account",
    "Whopper",
    "Scheherazade specialty",
    "It may be spun at sea",
    "It may be spun",
    "Don't believe it",
    "Yarn",
    "A fisherman may spin one",
    "Woolly yarn",
    "Ballad, often",
    "Saga",
    "Poe writing",
    "Fisherman's offering?",
    "Yarn",
    "It's hard to believe",
    "Scheherazade specialty",
    "Saga",
    "Myth",
    "Account",
    "Some story",
    "\"Peter Rabbit,\" for one",
    "Spun yarn",
    "Sailor's tall one",
    "Suspicious story",
    "Saga",
    "Story",
    "It's hard to believe",
    "Paul Bunyan account",
    "\"Peter Rabbit,\" for one",
    "Whopper",
    "Whopper",
    "Whopper",
    "It's hard to believe",
    "Scheherazade's recital",
    "Falsehood",
    "Yarn",
    "Doubtful story",
    "Tall writing?",
    "Yarn",
    "Chaucer piece"
  ],
  "moth": [
    "Fly-by-night type?",
    "Insect drawn to flames",
    "It comes to light",
    "Bulb circler",
    "Closet pest that loves wool",
    "Unpleasant find in a sweater",
    "___-eaten",
    "Creature on the movie poster for \"The Silence of the Lambs\"",
    "Insect that shorted out an early computer, spawning the term \"computer bug\"",
    "Hole maker",
    "See 14-Across",
    "Something that might come to light?",
    "Fly-by-night?",
    "Porch light circler",
    "Wool lover",
    "Lover of light",
    "One drawn to a flame",
    "Pest attracted to light",
    "Lepidopterist's study",
    "*One attracted to a flame",
    "Woolly bear, eventually",
    "Phototropic flier",
    "Closet pest",
    "Wool eater",
    "Armyworm, eventually",
    "Closet invader",
    "Hole maker",
    "Cutworm, eventually",
    "Sweater eater",
    "Closet eater?",
    "Cocoon contents",
    "Maker of holes",
    "It's bad to keep in the closet",
    "Sweater eater",
    "Butterfly's cousin",
    "Hole maker"
  ],
  "hoof": [
    "Centaur's foot",
    "Body part where a shoe goes",
    "Its sound in old westerns was often simulated by a coconut",
    "Clip-clop sound producer",
    "Foot for the Greek god Pan",
    "Place for a shoe",
    "Clop maker",
    "Split part of a reindeer",
    "What a horseshoer shoes",
    "Part of a horse that's shod",
    "Place for a shoe",
    "Farrier's focus",
    "Where a horseshoe goes",
    "Horse's foot",
    "Dance, slangily",
    "Place for a shoe",
    "Foot that goes clop",
    "Hard thing to walk on?",
    "Dog : paw :: horse : ___"
  ],
  "ehow": [
    "Website with articles like \"10 Surprising Ways to Use Mayonnaise Around Your Home\"",
    "Popular D.I.Y. site",
    "Popular self-help website",
    "Website with step-by-step tutorials",
    "Popular D.I.Y. website"
  ],
  "tree": [
    "Data structure with a root node",
    "Dendrogram, more familiarly",
    "Apple or maple",
    "Corner",
    "Arborist's interest",
    "The Berenstain Bears live in one",
    "Arboretum specimen",
    "Shoe accessory",
    "Diagram of relationships",
    "Where a sloth spends most of its life",
    "One putting down roots?",
    "Christmas ___",
    "Every family has one",
    "Leaves home?",
    "Christmas purchase that's quickly thrown out",
    "Yggdrasil, in Norse mythology",
    "Word with bay or family",
    "Birch or beech",
    "One leaving in the spring?",
    "\"The Giving ___\" (Shel Silverstein title)",
    "Maple or oak",
    "Genealogical chart",
    "Mango or persimmon",
    "Part of an arbor",
    "Diagram of possibilities",
    "Ancestry.com diagram",
    "Olive or peach",
    "Christmas ___",
    "What the upright yoga pose vrikshasana simulates",
    "Genealogist's work",
    "Part of a grove",
    "Nursery item",
    "See 37-Across",
    "Sequoia, e.g.",
    "Playhouse locale, perhaps",
    "Obstacle for a drone",
    "Logician's chart",
    "Peach or walnut",
    "Skier's obstacle",
    "Word with family or Christmas",
    "Almond or pecan",
    "Balsa or balsam",
    "Shade provider",
    "Oak or elm",
    "Kind of diagram",
    "Redwood or dogwood",
    "Olive or avocado",
    "Squirrel's home",
    "Rare sight on a steppe",
    "Evolutionary diagram",
    "Larch or birch",
    "See 128-Across",
    "Part of a canopy",
    "Arborist's focus",
    "Christmas ___",
    "Genealogical drawing",
    "39-Across, e.g.",
    "Image in the Timberland logo",
    "Knot holder",
    "Every family has one",
    "See 40-Down",
    "Beech or birch",
    "Obstacle for a golfer",
    "Ring bearer",
    "Yew, too",
    "Tire swing site",
    "It can be sappy",
    "Symbol on the state flag of Maine or South Carolina",
    "Tag base, perhaps",
    "Decision-maker's drawing",
    "Gallows ___",
    "Plum or pear",
    "Family ___",
    "___ rings",
    "Coat rack",
    "Word with family or shoe",
    "Bay, for one",
    "Bay, say ... or bring to bay",
    "Golfer's obstacle",
    "Home of some frogs",
    "See 9-Across",
    "___-hugger",
    "See 53-Across",
    "Peach or beech",
    "___ of life (part of 70-Across)",
    "Certain diagram",
    "Cedar or cypress",
    "Site of many a cat rescue",
    "Focus for an arborist",
    "A state symbol",
    "Put in a difficult spot",
    "Orange or olive",
    "Arborist's study",
    "Lineage depiction",
    "See 93-Across",
    "Every family has one",
    "Kind of surgeon",
    "Evolutionary chart",
    "See 57-Across",
    "Back into a corner",
    "Almond or pecan",
    "Apple or maple",
    "Pistachio or almond",
    "Word with family or fruit",
    "Dendrologist's subject",
    "See 53-Across",
    "___ of Souls, Na'vi temple in \"Avatar\"",
    "Genealogy chart",
    "Gallows",
    "Cheshire cat's place",
    "Bird's home",
    "One leaving in the spring",
    "Corner",
    "Home for a 28-Across",
    "Thing depicted by this puzzle's circled letters",
    "Thing hidden in each of the movie names in this puzzle",
    "Trap, in a way",
    "Logic diagram",
    "See 89-Down",
    "Logician's drawing",
    "Alder or elder",
    "Possessor of many rings",
    "Place for a house",
    "Olive or apple",
    "Decision-making diagram",
    "Logic diagram",
    "Christmas ___",
    "Kind of diagram",
    "Fig or fir",
    "Walnut or willow",
    "Peach or apple",
    "Ring holder",
    "Branch headquarters?",
    "Genealogical diagram",
    "Alder or elder",
    "See 51-Across",
    "Sycamore or cypress",
    "Sequoia, for one",
    "Peach or beech",
    "Elder, e.g.",
    "Forest unit",
    "Nest site",
    "Kind of diagram",
    "Birch or beech",
    "Elder, e.g.",
    "Apple or pear, e.g.",
    "Leaves home?",
    "Koala's hangout",
    "Peach or beech",
    "Arborist's concern",
    "Pedigree shower",
    "Palm, e.g.",
    "Cheshire cat's hangout",
    "Brazil, for one",
    "Leaves home?",
    "Corner",
    "Every family has one",
    "Place for a knot",
    "Locust or larch",
    "Elm or elder",
    "Forest unit",
    "Ancestry record",
    "Arboretum sight",
    "Nursery offering",
    "Corner",
    "Genealogist's work",
    "Ring bearer",
    "Plum or gum",
    "Lumber source",
    "Elm or oak",
    "Hammock holder",
    "Branch location",
    "Gallows",
    "Sloth's home",
    "Genealogist's work",
    "Center of a Christmas display",
    "Bring to bay",
    "Shoe shaper",
    "Elder or alder",
    "Family ___",
    "Part of a windbreak, maybe",
    "Place to find dates",
    "Orchard unit",
    "Leaves home?",
    "Apple or maple",
    "With 7-Down, it's seen on some trunks",
    "Swing site",
    "Part of a copse",
    "Corner",
    "Pecan or walnut",
    "Family portrait, of sorts",
    "One may undergo surgery",
    "Beech or birch",
    "Shade giver",
    "Corner",
    "Hammock's attachment",
    "Chart with many lines",
    "Elder or alder",
    "Ring site",
    "Squirrel's home",
    "Corner",
    "Squirrel's home",
    "Arboreal creature with sticky feet",
    "Shade giver",
    "Family map",
    "Branch site",
    "Shoe stiffener",
    "Branch headquarters?",
    "Lemur's hangout",
    "Shade provider",
    "Ebony or mahogany",
    "Genealogical work",
    "Kind of squirrel",
    "It has a bark but no bite",
    "It may require surgery",
    "Kind of diagram",
    "Keep from escaping",
    "Logic diagram",
    "Relative diagram",
    "Golfer's obstacle",
    "Genealogy display",
    "Tarzan's home",
    "Elder, for one",
    "Arbor Day honoree",
    "Cobbler's need",
    "Shade giver",
    "Mahogany or maple",
    "Sloth's home",
    "Leaves' home",
    "Corner",
    "Kind of toad",
    "Site for a swing",
    "Kind of diagram",
    "Chestnut or walnut",
    "Genealogical chart",
    "Cherry or apple",
    "Shade giver",
    "Trap",
    "Elder or alder",
    "Shoe insert",
    "Genealogist's handiwork",
    "Arboretum item",
    "Gum or plum, e.g.",
    "Arboretum item",
    "Gallows",
    "Corner",
    "Genealogy chart",
    "Birch or larch",
    "Balsam, e.g.",
    "Cobbler's need",
    "Logical diagram",
    "Arboreal locale",
    "Place for a small house",
    "Nest site",
    "Clothes hanger",
    "Pecan or poplar",
    "Gallows",
    "It's better known for its bark than its bite",
    "Christmas centerpiece",
    "Genealogist's work",
    "Shoe support",
    "Corner",
    "Math diagram",
    "Pin down, in a way",
    "Math diagram",
    "Genealogist's work"
  ],
  "plus": [
    "Also",
    "Not only that",
    "It makes an \"A\" even better",
    "\"Not to mention ...\"",
    "Advantage",
    "Grade upgrade",
    "Pro",
    "Selling point",
    "Asset",
    "Also",
    "+",
    "Grade enhancer",
    "___-size model",
    "+",
    "+",
    "+",
    "Advantage",
    "___ sizes",
    "\"As if that weren't enough ...\"",
    "It's beneficial",
    "In addition to",
    "Opposite of 37-Across",
    "+",
    "Besides",
    "Asset",
    "Underscore neighbor",
    "\"As if that weren't enough ...\"",
    "Asset",
    "Calculator symbol",
    "\"Not only that ...\"",
    "Bonus",
    "Asset",
    "Added value",
    "Anode indicator",
    "Grade elevator",
    "\"In addition ...\"",
    "And",
    "+",
    "+",
    "Grade enhancer",
    "Not only that",
    "+",
    "And",
    "Furthermore",
    "Good thing",
    "\"Besides that ...\"",
    "In addition",
    "Sign used in a 21-Across",
    "TRAMS",
    "\"Not only that...\"",
    "In addition",
    "Asset",
    "In addition",
    "Asset",
    "Advantage",
    "Extra",
    "What's more",
    "___ fours",
    "Asset",
    "Furthermore",
    "Add-on",
    "Besides",
    "+"
  ],
  "knot": [
    "Pretzel, basically",
    "Shoelace annoyance",
    "Granny, e.g.",
    "Metaphor for a tricky problem",
    "Shoestring woe",
    "Masseur's target",
    "Hitch, say",
    "One nautical mile per hour",
    "Gut feeling?",
    "Garden hose annoyance",
    "Clove hitch, e.g.",
    "Hard-to-solve problem",
    "Hard-to-undo tie",
    "Problem with a shoelace",
    "Macrame feature",
    "Tie that's hard to untie",
    "Tough problem",
    "End of one's rope?",
    "Clump of hair",
    "Tie the ___ (wed)",
    "Muscle problem",
    "Part of a tied tie",
    "Cluster",
    "Shoelace problem",
    "Masseur's target",
    "Muscle malady",
    "Speed unit",
    "Speed unit",
    "Granny, for one",
    "Windsor, for one",
    "Muscle problem",
    "Tough problem",
    "Rope expert's favorite radio station?",
    "Complicated problem",
    "Ship's speed unit",
    "Granny ___",
    "Marriage bond",
    "Word with slip or slide",
    "Granny ___",
    "It may stop a saw",
    "Shoelace problem",
    "Ball of string?",
    "1.85 kilometers/hour",
    "Masseur's target",
    "Shoelace problem",
    "Stomach tightness",
    "Shoelace problem",
    "Tight tie",
    "Shoelace problem",
    "Pretzel shape",
    "Shoelace problem",
    "Bad spot for a nail",
    "Cluster",
    "Sailor's unit",
    "Board defect",
    "Tie-up",
    "Perplexity",
    "Shoelace problem",
    "Nautical unit",
    "Cluster",
    "Hitch, e.g.",
    "Speed measure",
    "Bow",
    "Windsor or sheepshank",
    "Gut feeling?",
    "Shoelace woe",
    "Shoelace problem",
    "Masseur's target",
    "Speed unit",
    "Wedlock, so to speak",
    "Cluster",
    "Complicated problem",
    "Wood panel feature",
    "Cat's-paw, e.g.",
    "Half hitch, e.g.",
    "Measure of speed",
    "Stomach woe",
    "Clove hitch, e.g.",
    "Shoelace problem",
    "Half hitch, e.g.",
    "Bow, e.g.",
    "Group of toads",
    "Shoelace problem",
    "Scout's handiwork",
    "Granny, e.g.",
    "Stevedore's, e.g.",
    "Puzzle",
    "Carpenter's woe"
  ],
  "bara": [
    "Theda of silent films",
    "Silents sex symbol",
    "Theda of the silents",
    "Theda of early films",
    "1910's film star known as The Vamp",
    "Silents siren",
    "\"A Fool There Was\" star, 1915",
    "Theda ___ of the silents",
    "Theda of the silents",
    "Theda of silents",
    "See 17-Down",
    "Vamp Theda",
    "Vamp Theda",
    "Actress Theda",
    "\"Cleopatra\" star, 1917",
    "The Vamp",
    "Theda of Hollywood",
    "Silent film star Theda",
    "Theda the vamp",
    "Theda of 1917's \"Cleopatra\"",
    "Screen siren",
    "Actress Theda of the silents",
    "Femme fatale Theda",
    "Theda \"The Vamp\""
  ],
  "sosa": [
    "Baseball's Slammin' Sammy",
    "Onetime Cubs slugger Sammy",
    "Only player with three 60+ home run seasons",
    "Sammy with 609 career home runs",
    "He hit his 600th home run in 2007",
    "Sammy on a 1998 cover of Newsweek",
    "Baseball's \"Slammin' Sammy\"",
    "Slugger Sammy",
    "Baseball's Slammin' Sammy",
    "One of Sports Illustrated's two Sportsmen of the Year in 1998",
    "1998 N.L. M.V.P.",
    "Fifth player to hit 600 homers",
    "Cubs slugger with 609 home runs",
    "Baseball's Slammin' Sammy",
    "Member of the 600 home run club",
    "Sammy who was the 1998 N.L. M.V.P.",
    "Cubs slugger Sammy",
    "Major-leaguer with three 60+ home run seasons",
    "Seven-time All-Star Sammy",
    "He was traded between Chicago teams in 1992",
    "Six-time Silver Slugger winner",
    "1998 home run race participant",
    "Slugger Sammy",
    "Cub #21 of the 1990s-2000s",
    "Slugger Sammy",
    "Member of the 600 home run club",
    "1998 Roberto Clemente Award winner",
    "1997-99 N.L. strikeout leader",
    "Slugger Sammy",
    "Honoree of a 1998 New York ticker-tape parade",
    "McGwire's friendly home-run rival",
    "Dominican-born player in the 600 club",
    "Hitter of 66 in '98",
    "Baseball star in Senate steroid hearings",
    "McGwire rival, once",
    "Sportsman of the Year co-winner of 1998",
    "Future star athlete who debuted with the Rangers in 1989",
    "Sportsman of the Year co-winner of 1998",
    "First batter to hit a home run against every Major League Baseball team",
    "Member of the 500 Home Run Club",
    "Dominican-born player with more than 600 homers",
    "600-homer club member",
    "Sports Illustrated 1998 co-Sportsman of the Year",
    "Three-time 60-homer man",
    "Dominican-born baseball star",
    "Diamond cutter?",
    "Athlete feted with a New York City ticker-tape parade in 1998",
    "Slugger Sammy",
    "1998 N.L. M.V.P. from Chicago",
    "1998 N.L. M.V.P.",
    "Hitter from San Pedro de Macoris",
    "Slugger Sammy",
    "Sluggin' Sammy",
    "Dominican slugger",
    "Sports Illustrated's 1998 co-Sportsman of the Year",
    "Cubs' sluggin' Sammy",
    "Wrigley Field slugger",
    "1998 National League M.V.P.",
    "Cub of note",
    "Slugging Sammy",
    "Wrigley Field star",
    "1998 N.L. M.V.P.",
    "Cub with a club",
    "1998 National League M.V.P.",
    "Slugger Sammy",
    "Swinging Sammy",
    "Slugger Sammy",
    "Cub with a club",
    "McGwire's onetime home-run rival",
    "One of Maris's overtakers",
    "1998 baseball M.V.P.",
    "1998 N.L. M.V.P.",
    "One of Maris's overtakers",
    "Slugger Sammy",
    "Slammin' Sammy",
    "Cubs slugger Sammy",
    "1998 N.L. M.V.P.",
    "Chicago diamond cutter?",
    "1998 N.L. M.V.P.",
    "Slugger Sammy",
    "Slugger Sammy"
  ],
  "pies": [
    "Things set on windowsills to cool",
    "Traditional Thanksgiving desserts",
    "Pizzas, e.g.",
    "Pizzas, e.g.",
    "Facefuls in slapstick",
    "They have edible shells",
    "Circlegraph shapes",
    "Items thrown in Three Stooges shorts",
    "Bakery array",
    "Slapstick props",
    "Desserts akin to cobblers",
    "Some county fair contest entries",
    "Quiches, e.g.",
    "Pizzas",
    "\"The Worst ___ in London\" (\"Sweeney Todd\" song)",
    "Baker's dozen, maybe",
    "Bakery display",
    "Chiffon creations",
    "Slapstick missiles",
    "Wares encountered by Simple Simon",
    "Burlesque props",
    "Pizzas",
    "\"Chatt'ring\" birds in Shakespeare",
    "Bakers' wares",
    "They're cut into wedges",
    "They have wedges",
    "Bakers' wares",
    "Pizzas",
    "Mincemeat desserts",
    "They have shells",
    "Slapstick comedy items",
    "Desserts with crusts",
    "Slapstick projectiles",
    "Comic's missiles",
    "Some charts",
    "Slapstick ammo",
    "Crusty ones",
    "Pecan and pumpkin",
    "Cobblers",
    "Baker's wares",
    "Jokester's props",
    "Mrs. Smith's wares",
    "Some charts",
    "Sondheim's \"The Worst ___ in London\""
  ],
  "kara": [
    "___ Zor-El (Supergirl's real name)",
    "___ Zor-El, real name of Supergirl",
    "___ Lawson, W.N.B.A. star-turned-coach for the Boston Celtics",
    "___ Zor-El, Supergirl's birth name",
    "Russia's ___ Sea (arm of the Arctic Ocean)",
    "___ Zor-El, Supergirl's birth name",
    "\"American Idol\" judge DioGuardi",
    "___ Sea (arm of the Arctic Ocean)",
    "Supergirl's real name",
    "Ted Kennedy's eldest"
  ],
  "dupe": [
    "One getting suckered",
    "Trick",
    "Fool",
    "Deceive",
    "Fool",
    "Fool",
    "Hornswoggle",
    "Bamboozle",
    "Con",
    "Take for a ride",
    "Suck in",
    "Hornswoggle",
    "Sucker",
    "Bamboozle",
    "Hoodwink",
    "Pull a fast one on",
    "Sucker",
    "Take for a ride",
    "Victim of a prank",
    "One who's suckered",
    "Copy",
    "Pigeon",
    "Fool",
    "Gull",
    "Scam victim",
    "Trick",
    "Hoodwink",
    "Trick",
    "Bamboozle",
    "Con",
    "Hoodwink"
  ],
  "aiwa": [
    "Japanese speaker brand",
    "Classic name in audio equipment",
    "Japanese electronics company bought by Sony in 2002",
    "Japanese audio brand",
    "Japanese consumer electronics brand",
    "Competitor of Sanyo and Bose",
    "Onetime electronics giant",
    "Onetime giant in consumer electronics",
    "Audio equipment brand",
    "Former Sanyo competitor",
    "Onetime big name in Japanese electronics",
    "Sanyo competitor",
    "Former consumer electronics company bought by Sony",
    "Sanyo competitor",
    "Electronics company bought by Sony",
    "Sony brand",
    "Sanyo competitor",
    "Sanyo competitor",
    "Sony subsidiary",
    "Sony competitor",
    "Sanyo competitor",
    "Audio equipment brand",
    "Panasonic competitor",
    "Sony competitor"
  ],
  "iris": [
    "Tall purple flower",
    "Blue-violet shade",
    "Body part that's also a woman's name",
    "What surrounds the pupil",
    "Part of the eye",
    "Author Murdoch played onscreen by Kate Winslet and Judi Dench",
    "State flower of Tennessee",
    "Eye part covered by the cornea",
    "Divine messenger",
    "Flower named for a goddess",
    "Greek goddess of the rainbow",
    "Colored part of the eye",
    "Cousin of a crocus",
    "Part of the eye",
    "Pupil's place",
    "Female name that's the name of a female assistant backward",
    "\"Black ___,\" Georgia O'Keeffe painting at the Met",
    "A lid usually covers it at night",
    "Early-blooming ornamental",
    "The rainbow personified",
    "It's often brown or blue",
    "Colored part of the eye",
    "State flower of Tennessee",
    "Good name for an optometrist",
    "Apt name for an ophthalmologist",
    "Pupil surrounder",
    "Blue or hazel eye part",
    "Showy purple bloom",
    "Flower girl?",
    "Pale purple shade",
    "It's all about the pupil",
    "Flower whose name means \"rainbow\"",
    "\"Bearded\" flower",
    "It's responsible for controlling a pupil",
    "It's in the eye of the beholder",
    "A pupil is in the middle of it",
    "Goddess who caused the Trojan women to riot in the \"Aeneid\"",
    "It's in the eye of the beholder",
    "Rainbow goddess",
    "Eye piece",
    "What surrounds an aperture",
    "It has space for one pupil",
    "Goddess in \"The Tempest\"",
    "\"Bearded\" bloom",
    "Flag in a garden",
    "Spring bloom",
    "Long-stemmed flower",
    "Spring bloomer",
    "Body part used in some recognition systems",
    "Showy bloom",
    "Colored part of the eye",
    "Georgia O'Keeffe subject",
    "Colored part of the eye",
    "The white surrounds it",
    "Color also known as endive blue",
    "Attraction for a butterfly",
    "Girl's floral name",
    "Pupil's place",
    "Flower in a van Gogh painting",
    "Part of the eye",
    "Colored eye part",
    "Pupil surrounder",
    "Part of the eye around the pupil",
    "It controls the amount of light admitted",
    "Colored part of the eye",
    "Rainbow goddess",
    "Purple spring bloomer",
    "Bearded flower",
    "It might be blue, green or brown",
    "Middle of the eye",
    "Eye part",
    "1998 Goo Goo Dolls hit",
    "Tennessee's state flower",
    "Shade akin to lavender",
    "Colored part of the eye",
    "What the white of an eye surrounds",
    "Subject of many Georgia O'Keeffe paintings",
    "Eye part",
    "Eye part",
    "Colorful ring",
    "Pupil surrounder",
    "It's light-sensitive",
    "Flag in a garden",
    "Bearded bloom",
    "Flag, horticulturally",
    "Rainbow goddess",
    "One of Tennessee's state symbols",
    "Pupil's surrounder",
    "Bearded flower",
    "Pupil surrounder",
    "\"Bearded\" bloom",
    "1998 song by the Goo Goo Dolls that was #1 for 18 weeks",
    "Eye part",
    "Van Gogh floral subject",
    "Object in a Monet painting",
    "___ scan (biometric authentication method)",
    "Eye part",
    "Rainbow goddess",
    "You can see through it",
    "Rainbow goddess",
    "Goddess of the rainbow",
    "Pale purple shade",
    "It's surrounded by white",
    "It's usually blue, green or brown",
    "Camera part",
    "Colored eye part",
    "Something to look through",
    "Part of the eye",
    "Tennessee's state flower",
    "Camera component",
    "Pupil's locale",
    "Pupil controller",
    "See 109-Across",
    "It may be blue, brown or green",
    "\"Bearded\" flower",
    "2001 film for which Jim Broadbent was named Best Supporting Actor",
    "Dench/Winslet title role of 2001",
    "Showy flower",
    "Writer Murdoch",
    "Glad family",
    "Camera diaphragm",
    "Bearded bloom",
    "Center of the eye",
    "Subject for Monet",
    "Part of the eye",
    "Van Gogh flower",
    "Flower of one's eye?",
    "Showy flower",
    "It's usually blue or brown",
    "\"Bearded\" bloom",
    "Freesia's family",
    "Pupil's place",
    "Light controller",
    "Tennessee's state flower",
    "Freesia's relative",
    "Eye part",
    "Electra's daughter",
    "Rainbow",
    "Flower with a bulb",
    "Showy bloom",
    "Camera part",
    "Part of the eye",
    "Tennessee's state flower",
    "Place for a pupil",
    "Spring bloom",
    "Part of the uvea",
    "Camera diaphragm",
    "It's in the eye of the beholder",
    "Pupil's place",
    "It gives the eye color",
    "Perfume source",
    "Photographer's diaphragm",
    "Rainbow goddess",
    "Garden beauty",
    "Eye part",
    "Colored part of a ball?",
    "Blue flag, e.g.",
    "Showy flower",
    "Where a pupil sits",
    "Bit of eye makeup",
    "Tennessee's state flower",
    "Pupil locale",
    "Spring bloom",
    "Part of the eye",
    "Spring flower",
    "Camera diaphragm",
    "Flower of one's eye?",
    "Eye part",
    "Spring flower",
    "Pupil's place",
    "Messenger of the gods, in the \"Iliad\"",
    "See-through item",
    "Bulbed flower",
    "Eye part",
    "You can see through it",
    "Showy flower",
    "Rainbow goddess",
    "Eye part",
    "Writer Murdoch",
    "Flower of one's eye?",
    "Showy flower",
    "The blue of baby blues",
    "Flag",
    "Spring flower",
    "Rainbow",
    "Author Murdoch",
    "Writer Murdoch",
    "Novelist Murdoch",
    "Part of the eye"
  ],
  "saps": [
    "Drains",
    "Fools",
    "Easily fooled sorts",
    "Suckers or syrups",
    "Dim bulbs",
    "Patsies",
    "Gullible sorts",
    "Dupes",
    "They're easily taken",
    "Ones easily taken",
    "Drains",
    "Depletes",
    "Boobs",
    "Drains (from)",
    "Suckers",
    "Drains, as energy",
    "Con artists' targets",
    "Lunkheads",
    "Drains, as one's energy",
    "Chumps",
    "Maple syrup sources",
    "They're easily fleeced",
    "Suckers",
    "They get taken easily",
    "Gullible ones",
    "Easy marks",
    "Drains",
    "Weakens",
    "Drains",
    "Drains",
    "Suckers",
    "Boneheads",
    "With 44-Across, 1940 Laurel and Hardy film",
    "Depletes, as strength",
    "Fools",
    "Drains",
    "Depletes",
    "Hardly geniuses",
    "Uses up",
    "Enervates",
    "Gullible sorts",
    "Nincompoops",
    "Con artists' prey",
    "Gullible sorts",
    "Easy marks",
    "Suckers",
    "Scam targets",
    "Suckers",
    "Gullible ones",
    "Ninnies",
    "Wears out",
    "Exhausts",
    "Weakens",
    "Easy dupes",
    "Scam victims",
    "Chumps",
    "Drains",
    "Ones easily fooled",
    "Ninnies",
    "Exhausts",
    "Schmos",
    "Dupes",
    "Ninnies",
    "Patsies",
    "Enervates",
    "Weakens",
    "Enervates",
    "Drains of stamina",
    "Weakens",
    "Easy marks",
    "Drains"
  ],
  "writ": [
    "___ large",
    "Habeas corpus, e.g.",
    "Legal order",
    "Judicial order",
    "Certiorari, e.g.",
    "___ large",
    "Habeas corpus, e.g.",
    "Order in the court",
    "Legal order",
    "Letter of the law?",
    "Summons, e.g.",
    "Order in the court",
    "Means of enforcing compliance",
    "Means of enforcing compliance",
    "Legal document",
    "Certiorari, e.g.",
    "Order in the court",
    "Habeas corpus, e.g.",
    "Bench warrant, e.g.",
    "Formal order",
    "Habeas corpus, for one",
    "Bailiff's concern",
    "Judicial directive",
    "Judge's order",
    "___ of assistance (search warrant)",
    "Legal order",
    "Habeas corpus, e.g.",
    "Bailiff's execution",
    "Habeas corpus or mandamus",
    "Order in the court",
    "Lawyer's document",
    "Letter of the law?",
    "Court order",
    "Habeas corpus, e.g.",
    "Order in the court",
    "Certiorari, e.g.",
    "Scribbled, old-style",
    "Court instrument",
    "Order in the court",
    "Legal paper",
    "Habeas corpus, for one",
    "Holy ___",
    "Order",
    "Mittimus or mandamus",
    "Formal order",
    "An order of the court"
  ],
  "trou": [
    "Pants, in slang",
    "Pants, slangily",
    "Garment that may be \"dropped\"",
    "Pants, slangily",
    "What may descend before the moon?",
    "Drop ___",
    "Pants, in slang",
    "Pants, slangily",
    "Drop ___",
    "Drop ___ (moon)",
    "Short pants?",
    "Pants, in slang",
    "Drop ___",
    "Pants, slangily",
    "Drop ___ (moon)",
    "Drop ___ (start to strip)",
    "Drop ___",
    "Pants, slangily",
    "Drop ___",
    "Drop ___ (start to disrobe)",
    "Drop ___ (moon)",
    "Cut-off pants?",
    "Drop ___",
    "Pants, in brief",
    "Drop ___ (start to strip)",
    "Drop ___ (moon)"
  ],
  "ifit": [
    "\"___ walks like a duck ...\"",
    "\"___ Makes You Happy\" (Sheryl Crow tune)",
    "\"___ ain't broke ...\"",
    "\"___ looks like a duck ?à\"",
    "\"___ ain't broke ?à\"",
    "\"___ ain't broke ...\"",
    "\"___ ain't broke ...\""
  ],
  "wick": [
    "See 59-Across",
    "Absorb, as body moisture",
    "What's tall when it's young and short when it's old? (old riddle)",
    "Keeper of the flame?",
    "Keeper of the flame?",
    "It has a light at one end",
    "Lighted part of a candle",
    "Spot for a flame",
    "Lit part",
    "Part of a Zippo",
    "Part of an oil lamp",
    "Lighter feature",
    "Part of a cigarette lighter",
    "Wax insert, perhaps"
  ],
  "anet": [
    "Working without ___",
    "Caught in ___",
    "Work without ___",
    "Work without ___",
    "Working without ___",
    "Working without ___",
    "\"Without ___\" (1990 live Grateful Dead album)",
    "Operating without ___",
    "Without ___ (riskily)",
    "Flying without ___",
    "Without ___ (dangerously)",
    "Work without ___",
    "Operating without ___",
    "Working without ___",
    "Work without ___ (be daring)",
    "Work without ___",
    "Without ___ (daringly)",
    "Without ___ (unsafely)",
    "Working without ___",
    "Without ___ (riskily)",
    "\"Without ___\" (Grateful Dead album)",
    "Dill herb",
    "Van Halen's \"Live Without ___\"",
    "Work without ___",
    "Working without ___",
    "Working without ___",
    "Without ___ (dangerously)",
    "Without ___ (dangerously)",
    "Working without ___",
    "\"Fisherman With ___\" (1868 Frederic Bazille painting)",
    "Without ___ (unprotected)",
    "Without ___ (daringly)",
    "\"They have prepared ___ for my steps\": Psalms 57:6",
    "Work without ___ (be daring)",
    "Work without ___ (be daring)",
    "Working without ___",
    "Turn ___ profit",
    "Without ___ (daringly)",
    "Working without ___",
    "Work without ___",
    "Without ___ (riskily)",
    "Work without ___ (take risks)",
    "Working without ___",
    "Work without ___",
    "Without ___ (daringly)",
    "Without ___ (daringly)",
    "Turn ___ profit",
    "Work without ___ (be daring)",
    "Operating without ___ (taking risks)",
    "Work without ___ (risk injury)",
    "Without___(riskily)",
    "Operating without ___",
    "Operating without ___",
    "Without ___ (daringly)"
  ],
  "iraq": [
    "Baghdad's land",
    "Baghdad's land",
    "Baghdad's land",
    "Modern-day home of where the biblical Abraham was born",
    "Baghdad's land",
    "Modern home of the ancient king Gilgamesh",
    "Neighbor of Turkey",
    "Modern locale of ancient Sumer",
    "Where the Tigris and Euphrates flow",
    "Where dinars buy dinners",
    "Where the Tigris and Euphrates meet",
    "Country invaded in 2003",
    "Former Baath Party stronghold",
    "One of the I's of ISIS",
    "Country created by the Treaty of Sevres, 1920",
    "Neighbor of 10-Down",
    "Early 2000s war zone",
    "Setting for \"Three Kings,\" 1999",
    "Modern-day locale of ancient Nineveh",
    "\"The Hurt Locker\" setting",
    "\"The Hurt Locker\" setting",
    "2000s service site",
    "One side in a 1980s war",
    "Saddam Hussein's land",
    "\"Axis of evil\" member",
    "\"The Hurt Locker\" locale",
    "2000s service site",
    "Mesopotamia, today",
    "What some veterans recall",
    "Operation Desert Storm setting",
    "Neighbor of Kuwait",
    "\"The Hurt Locker\" setting",
    "Modern locale of ancient Ur",
    "Where many a veteran has served",
    "Baghdad's home",
    "Babylon's site, today",
    "Baath party place",
    "Modern home of ancient Ur",
    "With 33-Down, topic in the 2008 presidential campaign",
    "Modern home of the ancient Akkadian empire",
    "Mesopotamia, today",
    "2008 campaign issue",
    "Script meaning \"God is great\" appears on its flag",
    "It includes Mesopotamia",
    "Where Mosul is",
    "Where the Euphrates flows",
    "Country whose flag says \"God is great\"",
    "Former Baathist state",
    "Baghdad's land",
    "2003 war zone",
    "Where Mosul is",
    "Home of some Kurds",
    "Country under longtime U.N. sanctions",
    "Operation Desert Storm target",
    "Modern site of ancient Nineveh",
    "Sumer, nowadays",
    "Subject of U.N. sanctions",
    "Faisal I's land",
    "Land of the Tigris and Euphrates",
    "Amara?Æs nation",
    "Mideast hot spot",
    "Kirkuk's country",
    "Gulf war foe",
    "Babylon's land",
    "Mideast hot spot",
    "Foe of 31-Across",
    "Desert Storm target",
    "Where dinars buy dinner",
    "Invader of 1990",
    "Turkey neighbor",
    "Gulf war foe",
    "Jordan neighbor",
    "Baghdad's land",
    "Modern site of ancient Kish",
    "Ur locale"
  ],
  "gaze": [
    "Steady look",
    "Fixed look",
    "Stare intensely",
    "Peer",
    "Steady look",
    "Goggle",
    "The Sphinx's is \"blank and pitiless as the sun,\" per Yeats",
    "Steady look",
    "Good, long look",
    "Look at the stars, say",
    "Look (over)",
    "It's often piercing",
    "Intent look",
    "Intent look",
    "Look",
    "No mere glance",
    "See stars?",
    "Look into a crystal ball",
    "Goggle",
    "Long look",
    "It's often fixed",
    "Look at, as stars",
    "Stare intently",
    "It may be steely",
    "Use a crystal ball",
    "Look (at)",
    "Surveyor's look",
    "Peer (at)",
    "See stars, maybe",
    "A crystal ball user has it",
    "Intent look",
    "Goggle",
    "Stare, as at a crystal ball",
    "Steady look",
    "Look",
    "Stare"
  ],
  "mont": [
    "___ Blanc",
    "French peak",
    "___ Blanc, highest of the Alps",
    "___ Blanc",
    "France's Pic de Rochebrune, e.g.",
    "High point of geographie",
    "Pyrenees peak",
    "State that borders three Can. provinces",
    "___ Cervin (11-Down, to French speakers)",
    "Wyo. neighbor",
    "Peak in les Alpes",
    "Something to scale, in Somme",
    "___ Cenis Tunnel, in the Alps",
    "___ Blanc",
    "Alpine sight",
    "Pyrenees peak",
    "___ Blanc",
    "Blanc, for one"
  ],
  "rope": [
    "Need for tug of war",
    "What's pulled through a pulley",
    "Part of a pulley",
    "It may be looped for a throw",
    "Cordon (off)",
    "Material for a rock climber's harness",
    "Skip it!",
    "Mountaineer's need",
    "Plastic Clue weapon",
    "Need for tug-of-war",
    "Part of a hangman drawing",
    "Rappeller's need",
    "Weapon in Clue",
    "Weapon in Clue",
    "Skip it",
    "Tire swing part",
    "Tug-of-war need",
    "1948 Hitchcock thriller",
    "One thrown at a rodeo",
    "Ensnare, with \"in\"",
    "It's fit to be tied",
    "Riata, e.g.",
    "Lasso",
    "Weapon in Clue",
    "Lasso",
    "It might be skipped",
    "Hitchcock classic seemingly filmed in one continuous take",
    "Part of a bell tower",
    "Lariat material",
    "Use a lasso on",
    "Weapon in Clue",
    "Snare, in a way",
    "Hillbilly's belt",
    "Lariat",
    "Weapon in Clue",
    "Hitchcock thriller",
    "Weapon in the game Clue",
    "Lifeline, maybe",
    "Cowboy's aid",
    "Wrestling ring encloser",
    "Lasso",
    "Skip it",
    "Piece of rodeo gear",
    "Ring side",
    "One may be skipped",
    "Lariat",
    "Part of a climber's gear",
    "Composition of some ladders",
    "Mountain-climbing aid",
    "Hitchcock title",
    "Catch, in a way",
    "Catch rodeo-style",
    "Hitchcock film",
    "Hitchcock classic",
    "Noose material",
    "Skip it",
    "It may be skipped",
    "Lasso",
    "Weapon in the game Clue",
    "Lifeguard's equipment",
    "Lifesaving equipment",
    "Noose material",
    "1948 Hitchcock thriller",
    "Skip it",
    "It's pulled on a pulley",
    "Lariat",
    "1948 Hitchcock thriller",
    "Hitchcock title",
    "Lariat",
    "Entice, with \"in\"",
    "Noose maker",
    "Hillbilly's belt",
    "Hitchcock thriller",
    "Cowboy's equipment",
    "Catch in a way",
    "Lasso",
    "Velvet barrier",
    "Lariat",
    "Hitchcock nail-biter",
    "Lasso",
    "1948 Hitchcock nail-biter",
    "With 6-Down, Ali maneuver"
  ],
  "bosc": [
    "Long-necked pear",
    "Common baking pear",
    "Variety of pear",
    "Variety of pear",
    "Bartlett alternative",
    "Brownish pear",
    "Pear variety",
    "Pear variety",
    "French horticulturist after whom a variety of fruit is named",
    "Pear variety",
    "Good pear for poaching",
    "Winter pear",
    "\"The aristocrat of pears\"",
    "Winter pear",
    "Pear variety",
    "Pear type",
    "Pear variety",
    "Pear variety",
    "Fruit variety with a sweet-spiced flavor",
    "Winter pear",
    "\"The aristocrat of pears\"",
    "Pear type",
    "Pear variety",
    "Anjou kin",
    "Winter pear",
    "Pear type",
    "Seckel's cousin",
    "Pear variety",
    "Pear type",
    "Winter pear",
    "Pear variety",
    "Pear type",
    "Pear type",
    "Pear variety",
    "Pear type",
    "Large pear",
    "Greenish-yellow pear",
    "Pear variety",
    "Pear type",
    "Anjou alternative",
    "Anjou alternative",
    "Pear type",
    "Pear type",
    "49-Down variety",
    "Anjou alternative",
    "Popular pear",
    "Pear variety",
    "Pear type",
    "Greenish-yellow pear"
  ],
  "lose": [
    "Finish second, say",
    "With 10-Down, miss the boat",
    "Go down",
    "Ditch",
    "Ditch",
    "Ditch",
    "Be bested",
    "Go down",
    "Misplace",
    "Misplace",
    "Come up short",
    "Misplace",
    "Hit the top in Tetris, e.g.",
    "Finish second",
    "Misplace",
    "Take off, as weight",
    "Bow (to)",
    "Shake",
    "Shake",
    "Get whipped",
    "Take the booby prize",
    "Mislay",
    "Misplace",
    "Leave in the dust",
    "Come up short",
    "Go home empty-handed, say",
    "Get blitzed",
    "Get whipped",
    "Get checkmated",
    "Score the 3 in a 4-3 game",
    "Place, e.g.",
    "Shake, in a way",
    "Come in last",
    "Get checkmated",
    "Shake",
    "Place last, say",
    "Take the booby prize",
    "Ditch",
    "Shake",
    "Shake",
    "Leave in the dust",
    "Leave behind",
    "Give the slip to",
    "Shake off",
    "Misplace",
    "Get rid of",
    "Alternative to draw",
    "Get whipped",
    "Misplace",
    "Mislay",
    "Shake",
    "Shake",
    "Forfeit",
    "Fail to medal",
    "With 5-Across, blow a fuse",
    "Ditch",
    "Shake off",
    "Shake",
    "Squander",
    "Get checkmated",
    "Give the slip to",
    "Leave in the dust",
    "Fall (to)",
    "Word before face or heart",
    "Come in behind the others",
    "Waste",
    "Misplace",
    "Meet one's Waterloo",
    "Be deprived of",
    "Shed",
    "Come in last",
    "Mislay",
    "Suffer deprivation",
    "Give the slip to",
    "Mislay",
    "Be Beaten",
    "Get upset",
    "Forfeit",
    "Miss out on a prize",
    "Shake, so to speak",
    "Come in second",
    "Give the slip to",
    "Mislay",
    "Shed",
    "Misplace",
    "Forfeit",
    "Mislay",
    "Misplace",
    "Shake, in away",
    "Mislay",
    "Make a successful getaway from"
  ],
  "rosa": [
    "Civil rights pioneer Parks",
    "Santa ___, Calif.",
    "\"Brooklyn Nine-Nine\" detective Diaz",
    "Parks of Montgomery",
    "___ Klebb, Bond villain in \"From Russia With Love\"",
    "Santa ___, Calif.",
    "___ Diaz, detective on \"Brooklyn Nine-Nine\"",
    "Civil rights activist Parks",
    "Parks who is a civil rights hero",
    "Parks in civil rights history",
    "Santa ___",
    "Santa ___, Calif.",
    "Santa ___, Calif.",
    "\"Brooklyn Nine-Nine\" detective Diaz",
    "Parks who stood up for the right to sit down",
    "Parks of Alabama",
    "Parks of the civil rights movement",
    "Sub ___ (confidentially)",
    "Gift on el 14 de febrero",
    "Parks who sparked a boycott",
    "Parks in 1955 news",
    "Bonheur who painted \"The Horse Fair\"",
    "Gift on el dia de los enamorados",
    "Parks staying put",
    "Pink-colored, to Pedro",
    "Parks in 1955 news",
    "Santa ___, Calif.",
    "Santa ___, Calif.",
    "Santa ___, Calif.",
    "Parks in a bus",
    "Sub ___",
    "Santa ___, Calif.",
    "Monte ___ (one of the Alps)",
    "Parks in front of a bus?",
    "Parks with no intention of moving",
    "Parks in Alabama",
    "___ Bud, schoolgirl in \"The Mystery of Edwin Drood\"",
    "Civil rights pioneer Parks",
    "Parks in a bus",
    "Civil rights pioneer Parks",
    "Santa ___, Calif.",
    "See 53-Down",
    "Santa ___",
    "Parks who received the Presidential Medal of Freedom",
    "Civil rights pioneer Parks",
    "Parks of civil rights fame",
    "Civil rights figure Parks",
    "\"The Accumulation of Capital\" author Luxemburg",
    "Parks who wouldn't sit still for injustice",
    "Flower genus that's also a woman's name",
    "Sub ___ (confidentially)",
    "Santa ___, Calif.",
    "Sub ___ (secretly)",
    "Parks of Montgomery",
    "Monte ___, highest point in the Pennine Alps",
    "Parks who pioneered in civil rights",
    "Artist Bonheur",
    "Late civil rights activist Parks",
    "Parks of Alabama",
    "Spanish flower",
    "Artist Bonheur",
    "Santa ___, Calif.",
    "Parks of Montgomery",
    "Parks in Alabama",
    "Civil rights activist Parks",
    "Painter Bonheur",
    "Spanish flower",
    "Civil rights figure ___ Parks",
    "Montgomery's Parks",
    "Santa ___, Calif.",
    "___ Dartle, woman in \"David Copperfield\"",
    "Edwin Drood's betrothed",
    "Alpine peak Monte ___",
    "Parks on a bus",
    "Sub ___ (in secret)",
    "Santa ___",
    "Parks in 1955 news",
    "Painter Bonheur",
    "Soprano Ponselle",
    "Parks in 1955 news",
    "Parks on a bus",
    "Parks of civil rights fame",
    "A flower in Florence",
    "Santa ___",
    "Parks in 1955 news",
    "\"___ Luxemburg\" (1986 film biography)",
    "Santa ___",
    "Parks on a bus",
    "Sub ___ (secretly)",
    "Parks who wouldn't sit still for injustice",
    "Painter Bonheur",
    "Civil rights activist Parks",
    "Parks of civil rights",
    "Santa ___",
    "Bonheur or Parks",
    "Santa ___",
    "Painter Bonheur",
    "Santa ___, Calif.",
    "Parks who wouldn't take discrimination sitting down",
    "Santa ___, Calif.",
    "Spingarn Medal winner Parks",
    "Montgomery bus rider Parks",
    "Sub___",
    "Sub___ (secretly)",
    "Mrs. Parks from Montgomery",
    "Civil rights figure Parks",
    "Diva Ponselle",
    "Sub ___",
    "Civil rights figure Parks",
    "Soprano Ponselle",
    "Eco's \"Il nome della ___\"",
    "Edwin Drood's betrothed",
    "Sub ___",
    "Sub ___",
    "Parks of Alabama"
  ],
  "crux": [
    "Pivotal point",
    "Heart",
    "Essential point",
    "Central point",
    "Essence",
    "Pivotal point",
    "Key target of resolution",
    "Central point, as of an argument",
    "Key point",
    "Nub",
    "Gist",
    "Central point",
    "Core",
    "Meat",
    "Heart",
    "Heart",
    "Heart",
    "Point",
    "Heart",
    "Heart",
    "Essence",
    "Key point",
    "Central point",
    "Central point",
    "Heart",
    "Heart of the matter",
    "Gist",
    "Pivotal point",
    "Heart",
    "Essential point"
  ],
  "join": [
    "Button at the start of a Zoom call",
    "Enlist",
    "Connect",
    "Unite",
    "Enroll in",
    "Bridge",
    "Sign up",
    "Couple",
    "Couple",
    "Make one",
    "Bridge",
    "Make one",
    "Solder, say",
    "Enlist in",
    "Enroll in",
    "Amalgamate"
  ],
  "pina": [
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ colada",
    "Colada fruit",
    "___ cloth (lingerie fabric)",
    "___ colada",
    "Tropical fruit, in Toledo",
    "___ colada",
    "___ colada",
    "Mexican plantation product",
    "___ colada",
    "___ colada",
    "___ colada",
    "Fruit in a mixed drink",
    "___ colada",
    "___ colada",
    "___ cloth (lingerie fabric)",
    "___ colada",
    "___ colada",
    "___ colada",
    "___ cloth (lingerie fabric)"
  ],
  "iras": [
    "Cousins of 401(k)s",
    "C.D. holders, maybe",
    "Some are traditional, in brief",
    "Some tax shelters, for short",
    "Parts of some seniors' financial plans, for short",
    "Savings plans for old age, in brief",
    "401(k) alternatives",
    "Tax-advantaged investment tools, for short",
    "Savings plans for one's later years, in brief",
    "Portfolio options, for short",
    "Some \"traditional\" investments, for short",
    "401(k) alternatives",
    "Golden-years savings vehicles, for short",
    "Some S.&L. offerings",
    "Set-asides for old age, for short",
    "Nest eggs for later years, in brief",
    "Nest eggs for the golden yrs.",
    "S.&L. offerings",
    "Portfolio parts, for short",
    "They're best left untouched, generally speaking",
    "Some savings, for short",
    "Things opened by many employees, for short",
    "Fidelity offerings, for short",
    "They can roll over, briefly",
    "Some nest eggs, briefly",
    "Tax law subjects",
    "Some savings plans, for short",
    "Certain tax shelters, for short",
    "Some savings plans, in brief",
    "Some plans for the future, briefly",
    "Portfolio parts, briefly",
    "Investments for old age, for short",
    "Certain nest eggs, for short",
    "Financial reserves for later years, in brief",
    "Some tax shelters, for short",
    "Some investments, for short",
    "Portfolio options, for short",
    "Savings options for the golden yrs.",
    "Gershwin and Glass",
    "401(k) cousins",
    "1040 subjs.",
    "Plans for one's golden years, briefly",
    "Personal reserve funds, for short",
    "Portfolio holdings, briefly",
    "S&L offerings",
    "S&L offerings",
    "Bank offerings, for short",
    "Options for salting away, briefly",
    "Some nest eggs, for short",
    "Gershwin and others",
    "Some nest eggs, briefly",
    "Parts of finan. portfolios",
    "Nest eggs, briefly",
    "Investment options, for short",
    "401(k) alternatives",
    "Things that can be rolled over, for short",
    "Investments usually held for yrs. and yrs.",
    "Some investments, for short",
    "Form 1040 declarations, for short",
    "Nest eggs, for short",
    "Annual contributions may be made to them",
    "Nest eggs, briefly",
    "S. & L. offerings",
    "Income source for seniors, for short",
    "Nest eggs for seniors: Abbr.",
    "Levin and Gershwin",
    "401(k) cousins",
    "Accts. for old age",
    "Bank offerings",
    "Long-term finan. plans",
    "They're sometimes rolled over",
    "Some savings",
    "Bank offerings",
    "Brokerage offerings, for short",
    "Certain caches, for short",
    "Some J. P. Morgan hldgs.",
    "Attendant on Cleopatra, in Shakespeare",
    "81-Down's recommendations",
    "Nest eggs, for short",
    "Some bank hldgs.",
    "Savings plans",
    "Roth offerings",
    "401(k) cousins",
    "Elderly's svgs.",
    "Annual contribution sites, for short",
    "Some nest eggs",
    "Some investments, for short",
    "Subject of Form 1040, line 15a",
    "Portfolio contents, for short",
    "Some nest eggs",
    "Bank offerings, for short",
    "Some mutual fund accts.",
    "Some nest eggs: Abbr.",
    "Tax plan staples",
    "They may be rolled over",
    "Seniors' nest eggs, for short",
    "Certain kitties, for short",
    "Tax-deferred svgs.",
    "Some nest eggs: Abbr.",
    "Savings for the elderly",
    "Savings plans, for short",
    "Levin and Gershwin",
    "Bank offerings",
    "Nest eggs, for short",
    "Nest eggs: Abbr.",
    "Nest eggs",
    "Tax deferral plans",
    "Some nest eggs",
    "Retirement nest eggs"
  ],
  "path": [
    "Trail",
    "Course of action",
    "Trail",
    "Track",
    "Way",
    "Sea route, e.g.",
    "Walkway",
    "Trail",
    "Hiker's route",
    "Solution to a maze",
    "Course",
    "It may be beaten",
    "Way",
    "Something hikers take",
    "Maze solution",
    "It might be worn by a hiking group",
    "Orbit, e.g.",
    "What 6-Down means",
    "Lane",
    "Trail",
    "Way to go",
    "Walk",
    "Walkway",
    "Way to go",
    "Trail",
    "Way to go",
    "It's trodden",
    "Walkway",
    "Way to go",
    "Trail",
    "Walkway",
    "Trodden track",
    "Way",
    "Garden walkway",
    "Route",
    "Direction",
    "It may be worn in the woods",
    "Hiker's route",
    "Approach",
    "Way to go",
    "Hiking locale",
    "Trodden track",
    "Way",
    "Course",
    "Tao, literally",
    "Lane",
    "Trodden way",
    "Orbit",
    "Way to go"
  ],
  "uzis": [
    "Israeli submachine guns",
    "Israeli submachine guns",
    "Compact arms",
    "Action film weapons",
    "Israeli arms",
    "Israeli magazine holders",
    "Commando weapons",
    "High-powered guns",
    "Tomb Raider weaponry",
    "Israeli submachine guns",
    "Weapons in action films",
    "Guns first used in the Suez Crisis",
    "Commando movie accessories",
    "Submachine guns",
    "Pieces in a Mideast armory",
    "Yom Kippur War weaponry",
    "Commando weapons",
    "Israeli arms",
    "Rambos might wield them",
    "\"Munich\" arms",
    "Weapons with telescoping bolts",
    "Items for Rambos",
    "Fearsome weapons",
    "Powerful arms",
    "Commandos' arms",
    "Israeli weapons",
    "Mideast guns",
    "Commando weapons",
    "Strong arms?",
    "Submachine guns",
    "Props in a Schwarzenegger flick",
    "Banned weapons",
    "Israeli guns",
    "Terrorists' weapons"
  ],
  "fora": [
    "\"It's ___ good cause\"",
    "Places to hold discussions",
    "Debate venues",
    "Fit ___ king",
    "Roman marketplaces",
    "Meeting places",
    "Public venues",
    "___ song (cheaply)",
    "Words with time or song",
    "Fit ___ king",
    "Roman marketplaces",
    "Meeting places",
    "Roman squares",
    "Opportunities for discussion",
    "Roman squares",
    "Public discussion venues",
    "Discussion spots",
    "\"___ good time, call ?à\"",
    "\"___ good time ?à\"",
    "Public assemblies",
    "Places of open discussion",
    "___ song",
    "Roman meeting places",
    "\"My kingdom ___ horse!\"",
    "___ song (cheaply)",
    "___ song",
    "Ancient marketplaces",
    "___ song",
    "Roman marketplaces",
    "\"Queen ___ Day\" (old game show)"
  ],
  "snap": [
    "Jacket fastener that's not a button",
    "Jacket fastener",
    "One of the Rice Krispies mascots",
    "[Make it quick!]",
    "Go postal",
    "Show appreciation at a poetry slam",
    "Cereal mascot since 1933",
    "Cinch",
    "[Just like *that*]",
    "Lead-in to chat or dragon",
    "Lose one's cool",
    "Become unhinged",
    "Go berserk / Some cookware",
    "Break audibly",
    "Speak sharply",
    "[Just like ... that!]",
    "Crackle and Pop's buddy",
    "Go bananas",
    "\"Oh, you got me!\"",
    "\"___ out of it!\"",
    "Reach a breaking point",
    "Pic",
    "Breeze",
    "Spur-of-the-moment",
    "Pic",
    "Take a shot",
    "Go postal",
    "\"Oh, ___!\"",
    "Breeze",
    "Lose it",
    "\"Oh, ___!\" (\"Good one, girlfriend!\")",
    "Clothing fastener",
    "Suddenly lose patience",
    "Lose it",
    "Piece of cake",
    "Football play starter",
    "\"___, crackle, pop\"",
    "Sound that completes the phrase \"Just like ...!\"",
    "Easy-peasy task",
    "Rude way to call a waiter",
    "Get the picture",
    "Hypnotist's signal",
    "Lose it",
    "___, crackle, pop",
    "Jiffy",
    "Breeze",
    "Lose it",
    "___, crackle, pop",
    "Partner of Crackle and Pop",
    "Jiffy",
    "Jacket fastener",
    "When repeated, [Hurry it up!]",
    "Ginger cookie",
    "[Like that!]",
    "Go off",
    "___, Crackle and Pop",
    "Betray irritability",
    "Crackle and pop's partner",
    "Pic",
    "[Just like that!]",
    "[Just like that!]",
    "Result of excessive bending",
    "Jacket fastener",
    "Lose it",
    "Crackle and Pop's companion",
    "*Photo",
    "Spontaneous",
    "Breeze",
    "Sound heard with the phrase \"Just like that!\"",
    "Polaroid",
    "Start of a play to the quarterback",
    "Flip out",
    "Velcro alternative",
    "Go postal",
    "Jacket fastener",
    "Go postal",
    "[Just like that!]",
    "Completely lose patience",
    "\"___, crackle, pop\"",
    "Become unhinged",
    "Spur-of-the-moment",
    "Instant",
    "Breeze",
    "Hypnosis ender",
    "Go bananas",
    "Onslaught of cold weather",
    "Lose it",
    "It makes clothes close",
    "Go bonkers",
    "Rice Krispies sound",
    "Hypnotic trance breaker",
    "Go bananas",
    "Clothes closer",
    "Speak testily",
    "Quick pic",
    "Jacket feature",
    "Hike",
    "Go postal",
    "Succumb to stress",
    "Piece of cake",
    "Hike",
    "Attention-getting sound",
    "Rice Krispies sound",
    "Certain cookie",
    "Sound preceding crackle and pop",
    "Lose it",
    "Jacket part",
    "Breeze",
    "\"___ out of it!\"",
    "Go ballistic",
    "Ginger cookie",
    "Cinch",
    "Say sharply",
    "Flip out",
    "With 104-Across, Edible pod",
    "Suddenly lose it",
    "Rice Krispies sound",
    "Go bonkers",
    "Go postal",
    "Instantaneous",
    "Parka closer",
    "Jacket closer",
    "Polaroid",
    "Become unhinged",
    "Polaroid",
    "Like some decisions",
    "Like some judgments",
    "Velcro alternative",
    "Ginger cookie",
    "Piece of cake",
    "Breeze",
    "Go postal",
    "Ginger cookie",
    "Jacket part",
    "Say quickly",
    "Ginger cookie",
    "Lose it",
    "Lose it",
    "Kind of judgment",
    "Ginger cookie",
    "It may be cold",
    "Go off the deep end",
    "Fastener",
    "Say with annoyance",
    "Polaroid",
    "Breeze",
    "Breeze",
    "Jacket fastener",
    "Become unhinged",
    "Rice Krispies sound",
    "First of a familiar trio",
    "Polaroid",
    "Cinch",
    "Easy task",
    "It can close clothes",
    "It's full of ginger",
    "Cinch",
    "Breeze",
    "Simple task",
    "Break suddenly",
    "Lose it",
    "Hike",
    "Rice Krispies sound",
    "Brittle cookie",
    "Breeze",
    "Lose it",
    "Cereal sound",
    "Break sharply",
    "Fastener",
    "Lose it",
    "Lose it",
    "Piece of cake"
  ],
  "yens": [
    "Longs (for)",
    "Urges",
    "Desires",
    "Yearnings",
    "Hankerings",
    "Cravings",
    "Hankerings",
    "Urges",
    "Desires",
    "Hungers",
    "Hankerings",
    "Hankerings",
    "Longings",
    "Itches",
    "Longings",
    "Wants",
    "Thirsts",
    "Thirsts",
    "Desires",
    "Desires",
    "Desires",
    "Itches",
    "Hankerings",
    "Longings",
    "Itches",
    "Cravings",
    "Aches (for)",
    "Hankerings",
    "Longings",
    "Desires",
    "Impulses",
    "Joneses",
    "Big appetites",
    "Strong desires",
    "Strong desires",
    "Wishes",
    "Urges",
    "Desires",
    "Urges",
    "Pines",
    "Desires",
    "Longings",
    "Urges",
    "Achings",
    "Cravings",
    "Achings",
    "Aches (for)",
    "Hankerings",
    "Cravings",
    "Itches",
    "Hankerings",
    "Itches",
    "Hankerings",
    "Longings",
    "Cravings",
    "Urges",
    "Desires",
    "Itches",
    "Cravings",
    "Longings",
    "Desires",
    "Desires",
    "Hankerings",
    "Pinings",
    "Desires",
    "Itches",
    "Cravings",
    "Hankerings",
    "Hankerings",
    "Desires",
    "Itches",
    "Hankerings",
    "Hankerings",
    "Longs",
    "Longings",
    "Desires",
    "Hungers",
    "Driving needs",
    "Hungers",
    "Hankerings",
    "Longs",
    "Cravings",
    "Hankers",
    "Itchings",
    "Thirsts",
    "Cravings",
    "Cravings",
    "Itches",
    "Hankerings",
    "Desires",
    "Hankers",
    "Hankerings",
    "Deep urges",
    "Cravings",
    "Aches",
    "Cravings",
    "Cravings",
    "Desires",
    "Cravings"
  ],
  "lolz": [
    "Sarcastic internet laughter",
    "Response to a funny meme",
    "Online hilarity"
  ],
  "apia": [
    "Samoan capital",
    "Capital of Samoa",
    "South Seas capital",
    "World capital on the island of Upolu",
    "Where Samoa Airways is based",
    "Pacific island capital",
    "Pacific capital",
    "Polynesian capital",
    "Capital of Samoa",
    "Pacific capital",
    "Capital where Robert Louis Stevenson died",
    "Capital of Samoa",
    "Samoan capital",
    "Pacific port",
    "Samoan capital",
    "Samoa's capital",
    "Capital ENE of Fiji",
    "Pacific capital",
    "Samoan capital",
    "Pacific port",
    "Pacific capital",
    "South Pacific capital",
    "Samoan port",
    "Capital of Samoa",
    "Capital ENE of Fiji",
    "Capital near Faleolo International Airport",
    "Headquarters for Polynesian Airlines",
    "Samoan capital",
    "Where Polynesian Airlines is based",
    "Pacific capital",
    "Samoan port",
    "Samoan capital",
    "Capital of Samoa",
    "Samoan capital",
    "Capital where tala are spent",
    "Capital on Upolu island",
    "Capital whose Parliament house is called Fale Fono",
    "Samoan capital",
    "Samoa's capital",
    "Capital of Samoa",
    "Samoan port",
    "Samoan capital",
    "Pacific capital",
    "Capital of Samoa",
    "Samoan port",
    "Samoan capital",
    "Capital ENE of Fiji",
    "Pacific capital",
    "Western Samoa's capital",
    "Samoan capital",
    "City near which Robert Louis Stevenson is buried",
    "Samoan capital",
    "Western Samoa's capital",
    "Samoan capital",
    "Samoan capital"
  ],
  "dina": [
    "Actress Merrill",
    "Actress Merrill of \"BUtterfield 8\"",
    "Merrill in movies",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill of \"Desk Set\"",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill",
    "Actress Merrill"
  ],
  "kpop": [
    "Genre for BTS or Blackpink",
    "Seoul music",
    "Music genre for Psy and BTS",
    "Music genre from Asia"
  ],
  "yoga": [
    "Practice whose name means, literally, \"union\"",
    "Exercise that might be done on a mat",
    "Some quiet exercise",
    "Popular fitness class",
    "What could make you take a deep breath",
    "Meditative practice",
    "Hindu-inspired exercise",
    "Mind-body exercise",
    "Class with mats",
    "Class with a flexible schedule?",
    "Exercise on a mat",
    "Exercise discipline",
    "Exercise with a cobra pose",
    "Means to deep spiritual insight",
    "Exercise on a mat",
    "Class at a Y",
    "Exercise in which you might sit cross-legged",
    "What might get you through a quiet stretch?",
    "Spa class",
    "Exercise that may involve sitting cross-legged",
    "Thoughtful exercise",
    "Posture-improving exercises",
    "Meditation on a mat",
    "Eastern discipline",
    "\"Salute to the sun\" discipline",
    "Exercise with crossed legs",
    "Quiet exercise",
    "Poses in a studio?",
    "Exercises sometimes done cross-legged",
    "Home stretches?",
    "Lotus position discipline",
    "Eastern discipline",
    "Spiritual exercise",
    "Meditation method",
    "Meditative exercises",
    "It may put you in a difficult position",
    "Series of postures, basically",
    "Tranquil discipline",
    "Lotus position discipline",
    "Kind of class",
    "Quiet exercises",
    "Eastern discipline",
    "Eastern discipline",
    "Hatha-___",
    "Kind of master",
    "Form of discipline",
    "Certain exercises",
    "Spiritual discipline",
    "Some exercises",
    "Cross-legged exercises"
  ],
  "isit": [
    "\"Really, though?\"",
    "\"Really?\"",
    "\"That so?\"",
    "\"That true?\"",
    "\"Do you think!?\"",
    "Doubter's question",
    "Question of doubt",
    "\"Oh, really?\"",
    "\"Really?\"",
    "Doubter's question",
    "\"Oh, really?\"",
    "\"___ soup yet?\"",
    "\"___ something I said?\"",
    "Part 1 of a wordplay-related quip",
    "\"What time ___?\"",
    "\"What time ___?\"",
    "\"Really?\"",
    "\"___ any wonder?\"",
    "\"That so?\"",
    "\"This ___!\"",
    "\"This ___\" (Michael Jackson album)",
    "\"___ true?\"",
    "Start of a 20 Questions question",
    "\"?à or ___ just me?\"",
    "?ôWhat ___ now??ö",
    "\"What time ___?\"",
    "\"What ___ now?\"",
    "\"What ___?\"",
    "\"What ___ now?\"",
    "\"From where ___ ...\"",
    "\"What ___?\"",
    "Start of a guess",
    "\"___ something I said?\"",
    "\"Who ___?\" (knock response)",
    "\"From where ___...\"",
    "\"What ___ now?\"",
    "?ôWho ___??ö",
    "\"___ me?\"",
    "\"This ___\" (1979 Loggins hit)",
    "See 51-Across",
    "Does the chasing in tag",
    "\"___ Love\" (1986 hit)",
    "\"...or ___ me?\"",
    "\"___ just me or...?\"",
    "\"Whose Life ___ Anyway?\" (1981 movie)",
    "\"___ true?\"",
    "\"___ any wonder?\""
  ],
  "sans": [
    "Word in many font names",
    "Minus",
    "With 61-Across, like some typefaces",
    "Lacking",
    "Without",
    "Without",
    "___ souci (carefree)",
    "Comic ___ (typeface)",
    "Lacking",
    "___-serif",
    "With 76-Across, like Arial and Helvetica",
    "Minus",
    "___-serif font",
    "Lacking",
    "Without",
    "___-serif typeface",
    "___ souci",
    "___-serif",
    "___ souci (carefree)",
    "Comic ___ (typeface)",
    "Avec's opposite",
    "Without",
    "___-culotte",
    "Without: Fr.",
    "Lacking",
    "Without: Fr.",
    "Lacking",
    "Word repeated four times in the last line of Shakespeare's \"All the world's a stage\" speech",
    "French word whose opposite is 2-Down",
    "Without: Fr.",
    "___ fil (wireless, in Paris)",
    "Without: Fr.",
    "Without",
    "Lacking, to Louis",
    "___-culotte",
    "Without: Fr.",
    "Without",
    "Without",
    "With 27-Down, like some letters",
    "Without",
    "___-serif",
    "Without",
    "___ souci",
    "___ souci",
    "Minus",
    "Lacking",
    "Without",
    "Lacking",
    "Without: Fr.",
    "___ serif",
    "Without",
    "Lacking",
    "Without: Fr.",
    "Without: Fr.",
    "___-serif (type style)",
    "___ doute (certainly): Fr.",
    "Without",
    "Without",
    "Without: Fr.",
    "Without",
    "Without",
    "Avec's opposite",
    "Lacking",
    "Without",
    "Minus"
  ],
  "slit": [
    "Thin incision",
    "Feature of some dresses",
    "Opening made by a letter opener",
    "Narrow cut",
    "Narrow opening",
    "Thigh-baring dress feature",
    "Narrow cut",
    "Open with a letter opener",
    "Not much light can get through it",
    "Pupil of a lizard, e.g.",
    "Razor cut",
    "Buttonhole, e.g.",
    "Narrow cut",
    "Cut, as with a letter opener",
    "Cut with a knife",
    "Pupil of a snake's eye, often",
    "Use a scalpel on",
    "Narrow opening",
    "Use a scalpel on",
    "Sexy skirt feature",
    "Narrow opening",
    "Skirt feature",
    "Buttonhole, for example",
    "Narrow cut",
    "Start of many an operation",
    "Buttonhole, e.g.",
    "Operation creation",
    "Thin opening",
    "Cut open",
    "Microchannel",
    "Narrow cut",
    "Cut",
    "Knife incision",
    "Revealing skirt feature",
    "Turtle's eye, often",
    "Narrow cut",
    "Narrow opening",
    "Narrow cut",
    "Thin opening",
    "Gill opening",
    "Narrow cut",
    "Skirt feature",
    "Skirt feature",
    "Cut",
    "Opening",
    "Skirt feature",
    "Feature of Capri pants",
    "Narrow opening",
    "Start of a tear",
    "Cut",
    "Crack",
    "Cut",
    "Cut like a letter opener",
    "Skirt feature",
    "Use a knife",
    "Skirt opening",
    "Incision",
    "Eye opening for a squint",
    "Cut open",
    "Squinting eye, e.g.",
    "Buttonhole, e.g.",
    "Kind of skirt",
    "Gill opening",
    "Cat's eye, sometimes",
    "Buttonhole",
    "Kind of skirt",
    "Stitch target",
    "Skirt feature",
    "Feature of some skirts",
    "Open, in a way",
    "Eye opening, maybe",
    "Buttonhole",
    "Open, as an envelope",
    "Cut",
    "Buttonhole, essentially",
    "Skirt feature",
    "Incision",
    "Cut",
    "Lance",
    "Open, in a way",
    "Use a letter opener",
    "Cut in a skirt",
    "Skirt opening",
    "Use a letter opener",
    "Use a letter opener",
    "Skirt feature",
    "Letter opening?",
    "Eye opening"
  ],
  "oars": [
    "Set of 50 on the Argo, in myth",
    "Cousins of paddles",
    "Sticks in the water",
    "Sticks in water",
    "Crew's control?",
    "Props for some beach house decor, maybe",
    "Scullers' gear",
    "Propeller blades?",
    "Several of them could be used in a row",
    "Rowboat propellers",
    "Boaters' implements",
    "Rows",
    "Crew gear",
    "Galley equipment",
    "Crew's need",
    "Things rested on, metaphorically",
    "Rowers may have a row of them",
    "They go in locks",
    "Sticks in the water?",
    "Crew crew",
    "Boaters' implements",
    "Outrigger projections",
    "Things sometimes seen in banks",
    "Crew implements",
    "Set at sea?",
    "Sculls",
    "Galley propellers",
    "Set for a regatta",
    "Regatta gear",
    "Rowboat rowers",
    "Longship propellers",
    "Rows",
    "Relatives of paddles",
    "They're in locks on a boat",
    "Whaleboat features",
    "Rowing implements",
    "Coxswain's teammates",
    "Propulsion gear",
    "They're often seen in banks",
    "They're sometimes seen in banks",
    "Rowers",
    "Dinghy duo",
    "Dipped sticks?",
    "Scull propellers",
    "Things in locks",
    "Water skimmers",
    "Rowboat pair",
    "Sculler's gear",
    "Coxswain's lack",
    "Things locked into place",
    "Scullers' needs",
    "Gear for gondolas",
    "Items fitting in rowlocks",
    "Boathouse gear",
    "They're inserted in locks",
    "Equipment for strokes",
    "What some use to ply their craft?",
    "Crew members",
    "Crew members",
    "They're found in banks",
    "Dinghy pair",
    "Implements using fulcrums",
    "Dinghy movers",
    "Some scullers' trophies",
    "Couple in a rowboat",
    "Crew's control?",
    "Crew members",
    "Things usually held while facing backward",
    "Bireme gear",
    "Bowmen's implements",
    "Pair in a dinghy",
    "Galley's many",
    "Scullers' needs",
    "Crew equipment",
    "Propels a shell",
    "Sculls",
    "Galley gear",
    "They move shells",
    "Pair at sea",
    "Crew needs",
    "Argo's array",
    "Dinghy pair",
    "Galley workers",
    "Paddles",
    "Paddles",
    "\"Stop rowing\" command",
    "They may be found in banks",
    "They can make waves",
    "They're used during a row",
    "Rows",
    "Coxswain's command",
    "Sculling equipment",
    "Regatta participants",
    "Eight in a row?",
    "Henley crew",
    "Boat propellers",
    "Trireme gear",
    "They may be arranged in banks",
    "Galley propellers",
    "Dinghy propellers",
    "Pair at sea",
    "Crew members",
    "Boaters pull them",
    "Hydrodynamic duo",
    "They fit in locks",
    "Dory gear",
    "Boat propellers",
    "Wherry equipment",
    "They sweep at the regatta",
    "Regatta blades",
    "They're in galley banks",
    "Sculls",
    "Boat propellers",
    "Galley items",
    "Boat propellers",
    "Dinghy's thingies",
    "Galley propellers",
    "Trireme gear",
    "Rows",
    "Galley features",
    "Paddles",
    "Rows",
    "Boating couple",
    "Rest on one's ___",
    "Paddles",
    "Propels, in a way",
    "They go into locks",
    "Coxswain's crew",
    "Galley propellers"
  ],
  "jinn": [
    "Spirit in Arabian myth",
    "Islamic spirit",
    "Islamic spirit",
    "Spirit of Islamic myth",
    "Spirit, in Islamic myth",
    "Supernatural spirit",
    "Arabian demon",
    "Islamic spirit"
  ],
  "mott": [
    "Abolitionist Lucretia",
    "Lucretia ___, abolitionist and women's rights advocate",
    "Lucretia ___, pioneer in women's rights",
    "Women's rights pioneer Lucretia",
    "Big name in applesauce",
    "Applesauce eponym",
    "Seneca Falls orator Lucretia",
    "Glam rock band ___ the Hoople",
    "Applesauce eponym",
    "Glam rock's ___ the Hoople",
    "19th-century women's rights advocate",
    "Unofficial \"Main Street\" of New York's Chinatown",
    "Rock's ___ the Hoople",
    "Social reformer Lucretia",
    "Pioneering women's rights advocate",
    "Feminist Lucretia",
    "1946 Nobel Peace Prize winner John",
    "Pioneering U.S. feminist",
    "Street in New York's Chinatown",
    "Street in New York's Chinatown",
    "Early feminist Lucretia",
    "Rock's ___ the Hoople",
    "Women's rights advocate Lucretia",
    "Women's rights crusade Lucretia",
    "Nobel physicist Sir Nevill",
    "Early feminist Lucretia"
  ],
  "twit": [
    "Ninny",
    "Turkey",
    "Nincompoop",
    "Ninny",
    "Dipstick",
    "Nincompoop",
    "Complete fool",
    "Ninny",
    "Ninny",
    "Fool",
    "Ninny",
    "Doofus",
    "Birdbrain",
    "Nincompoop",
    "Twerp",
    "Dunderhead",
    "Blockhead",
    "Ninny",
    "Goose",
    "Ridicule",
    "Chucklehead",
    "Birdbrain",
    "Boob",
    "Knucklehead",
    "Irritating sort",
    "Nincompoop",
    "Dweeb",
    "Ninny",
    "Nettlesome person",
    "Schmo",
    "Pipsqueak",
    "Dweeb",
    "Twerp",
    "Blockhead",
    "Nincompoop",
    "Dweeb",
    "Needle",
    "Hardly a brainiac",
    "Little jerk",
    "Needle",
    "Tease",
    "Little nothing",
    "Simple Simon",
    "Nincompoop",
    "Ridicule",
    "Ridicule",
    "Bozo",
    "Needle",
    "Insignificant one",
    "Nudnik"
  ],
  "lief": [
    "Gladly, old-style",
    "Gladly, old-style",
    "Gladly, old-style",
    "Happily, old-style",
    "Willing, old-style",
    "Willingly",
    "Gladly",
    "Willingly",
    "Gladly",
    "Willingly",
    "Willingly",
    "Willingly",
    "Willingly",
    "Gladly",
    "Gladly"
  ],
  "lapd": [
    "First law enforcement org. in the U.S. to hire a female officer (1910)",
    "Org. for Lt. Columbo",
    "Org. in \"Die Hard\"",
    "Org. in \"Die Hard\"",
    "\"Lethal Weapon\" force, in brief",
    "West Coast law force, for short",
    "\"Dragnet\" force, briefly",
    "SoCal force",
    "Sgt. Friday's org.",
    "Pacific force, for short",
    "\"Lethal Weapon\" force, briefly",
    "\"Let's Be Cops\" org.",
    "Hollywood force, in brief",
    "Sgt. Friday's force",
    "\"Dragnet\" force, in brief",
    "\"Colors\" org.",
    "SoCal squad",
    "Org. for Lt. Columbo",
    "Grp. in \"Die Hard\"",
    "Org. in 2005's Oscar-winning \"Crash\"",
    "\"Columbo\" org.",
    "Columbo's employer, for short",
    "Joe Friday's employer, for short",
    "\"Dragnet\" force, in brief",
    "Pacific force, for short",
    "Sgt. Friday's org.",
    "Org. in TV's \"Adam 12\"",
    "Org. for Lt. Columbo",
    "\"Lethal Weapon\" force: Abbr.",
    "Sgt. Friday's force",
    "\"Dragnet\" force, for short",
    "Columbo's employer, for short",
    "\"Dragnet\" org.",
    "\"Beverly Hills Cop\" org.",
    "Sgt. Friday's employer"
  ],
  "sour": [
    "Lip-puckering",
    "Like lemons",
    "Sweet's counterpart",
    "Mixed drink with lemon or lime juice",
    "Whiskey drink",
    "Whiskey drink",
    "In a foul mood",
    "Go bad, as milk",
    "Off-key",
    "Bad way to turn",
    "Lip-puckering",
    "Cocktail order",
    "Causing face-clenching, maybe",
    "Sweet's partner",
    "Go bad",
    "Like unripe apples",
    "Causing the lips to pucker",
    "Go bad",
    "Lip-puckering",
    "Making the mouth pucker, say",
    "Skittles variety",
    "Go bad",
    "Go bad",
    "Like a lemon",
    "Whiskey drink",
    "Go bad",
    "None too pleasant",
    "Whiskey ___",
    "Bar order",
    "Like a lime",
    "Grumpy",
    "Kind of grapes",
    "Become disenchanted",
    "Causing puckering, perhaps",
    "Down (on)",
    "Off, as a note",
    "Whisky drink",
    "Bar order",
    "Tart",
    "Lemonlike",
    "Tart"
  ],
  "that": [
    "\"Just like ___!\"",
    "Word uttered while pointing",
    "Pointer's word",
    "All ___",
    "All ___ (really cool)",
    "\"Take ___!\"",
    "\"Roger ___\"",
    "\"___ said ...\"",
    "Pointer's pronoun",
    "\"I don't wanna be ___ guy, but ...\"",
    "\"So ___ happened\"",
    "Unnamed object",
    "\"How's ___?\"",
    "Pointer's request?",
    "\"All About ___ Bass\" (2014 #1 hit by 5-Down)",
    "\"Fancy ___!\"",
    "All ___",
    "\"___ is so you!\"",
    "\"Don't give me ___!\"",
    "Word said while pointing",
    "The \"id\" in \"id est\"",
    "\"Did I do ___?\"",
    "All ___",
    "\"Not ___!\"",
    "\"I heard ___!\"",
    "\"What's ___?\"",
    "To the extent ___",
    "This's partner",
    "\"___ reminds me ...\"",
    "*\"Take ___!\"",
    "Fancy follower",
    "\"Take ___!\"",
    "Partner of 37-Across",
    "\"Not ___!\"",
    "Word accompanying finger-pointing",
    "\"___ so?!\"",
    "\"Take ___!\"",
    "Isley Brothers hit \"___ Lady\"",
    "Just one of those things?",
    "\"How about ___?!\"",
    "This isn't it",
    "Pointer's reference",
    "\"Forget ___!\"",
    "\"What's ___?\"",
    "\"Take ___!\"",
    "\"___ 70's Show\"",
    "Pointer's pronoun",
    "\"Take ___!\"",
    "\"___ does it!\"",
    "\"Is ___ all there is?\"",
    "\"How 'bout ___?!\"",
    "\"___ does it!\"",
    "See 12-Down",
    "___ is to say",
    "\"How about ___?\"",
    "One of those?",
    "Pointer's word",
    "Just one of those things",
    "It's just one of those things",
    "\"___ does it!\"",
    "What this isn't",
    "\"Imagine ___!\"",
    "\"___ depends...\"",
    "The \"id\" in \"id est\"",
    "\"Fancy ___!\"",
    "What this isn't",
    "Not this",
    "\"Anything but ___!\"",
    "\"___ does it!\"",
    "The \"id\" in \"id est\"",
    "\"Anything but ___!\"",
    "\"___reminds me...\"",
    "\"Take___!\"",
    "Just one of those things",
    "Object of finger-pointing?",
    "One of those",
    "This and ___",
    "\"___ said,...\"",
    "\"Take ___!\"",
    "This isn't it",
    "\"___does it!\""
  ],
  "robo": [
    "Lead-in to call",
    "Modern lead-in to call",
    "Start of some futuristic toy names",
    "Slangy prefix meaning \"mechanical\"",
    "Movie lead-in to Cop",
    "___-call",
    "\"___Cop\"",
    "___-call (automated solicitation)",
    "Cyborg's beginning?",
    "\"___Cop\"",
    "Film lead-in for Cop",
    "Prefix with cop, in a film title",
    "Slangy prefix meaning \"mechanical\"",
    "\"___-Man\" (1974 spy/sci-fi film)",
    "Kind of cop"
  ],
  "bday": [
    "Cause for an onslaught of yearly txts",
    "Annual celebration, for short",
    "Present time, for short",
    "Kind of card, familiarly",
    "Cause for cake and candles, in brief",
    "Annual celebration, for short",
    "Xmas, for Jimmy Buffett",
    "Present time, informally",
    "Party time, for short",
    "Time to blow out the candles, for short",
    "Fourth of July, for Calvin Coolidge, informally",
    "Time for a party, in brief",
    "Celebration time, for short"
  ],
  "endo": [
    "High-quality cannabis, in slang",
    "Prefix meaning \"inner\"",
    "Prefix meaning \"within\"",
    "Prefix with spore or sperm",
    "Inner: Prefix",
    "Inner: Prefix",
    "Prefix with sperm",
    "Prefix with -plasm",
    "Prefix meaning \"internal\"",
    "Prefix with -genous",
    "Marijuana, in modern slang",
    "Prefix with -morphic",
    "Prefix with parasite",
    "Prefix with spore",
    "Prefix with -scope",
    "Within: Prefix",
    "Medical prefix with -scope",
    "Thermal lead-in",
    "Inner: Prefix",
    "Prefix with -morphism",
    "Within: Prefix",
    "Prefix with -therm",
    "Prefix with skeleton",
    "Prefix with -morphism",
    "Prefix with skeleton",
    "Internal: Prefix",
    "Prefix with skeleton",
    "Within: Prefix",
    "Prefix with -plasm",
    "Inner: Prefix",
    "Prefix with spore",
    "Prefix with skeleton",
    "Prefix with skeleton",
    "Root canal, in dentist-speak",
    "Opposite of ecto-",
    "Prefix with spore",
    "Prefix with skeleton",
    "Prefix with -crine",
    "Prefix with scope",
    "Inner: Prefix",
    "Within: Prefix",
    "Opposite of exo-",
    "Prefix with derm",
    "Prefix with skeleton",
    "Prefix with skeleton",
    "Prefix with plasm or morphic",
    "Within: Prefix",
    "Opposite of ecto-",
    "Prefix with centric",
    "Prefix with morph",
    "Prefix with genetic",
    "Within: Prefix",
    "Prefix with -genous",
    "Prefix with skeleton",
    "Prefix with spore",
    "\"The Samurai\" novelist Shusaku",
    "Prefix with skeleton",
    "Opposite of ecto-",
    "Prefix with -derm or -therm",
    "Within: Prefix",
    "Inner: Prefix",
    "Prefix with -morph",
    "Prefix with scope",
    "Prefix with plasm",
    "Within: Prefix",
    "Within: Prefix",
    "Prefix with skeleton",
    "Prefix with scope or spore",
    "Opposite of exo-",
    "Opposite of exo-",
    "Plasm prefix",
    "Beginning with blast or carp",
    "Prefix with scope or skeleton",
    "Skeleton's head"
  ],
  "emma": [
    "Classic novel with the line \"You must be the best judge of your own happiness\"",
    "Actress Watson of the Harry Potter films",
    "Poet Lazarus",
    "Title matchmaker in an 1815 novel",
    "Actress Watson of the \"Harry Potter\" films",
    "Noted 1815 comedy of manners",
    "1815 novel made into films in 1996 and 2020",
    "YouTube star Chamberlain, whom The Atlantic called \"the most talked-about teen influencer in the world\"",
    "Jane Austen novel",
    "1815 novel of romantic mis-understandings",
    "Watson of \"Beauty and the Beast\"",
    "Anarchist/political activist Goldman",
    "Top female baby name of 2014-18",
    "Heiress of Hartfield, in an 1816 novel",
    "Jane Austen novel",
    "Jane Austen heroine",
    "Novel on which the film \"Clueless\" is based",
    "Top name in a Social Security Administration list every year from 2014 to 2017",
    "Jane Austen title woman",
    "Stone that's a star",
    "Stone that's cast",
    "It dethroned Sophia as the #1 baby girl's name in the U.S. in 2014",
    "Actress Stone",
    "Literary matchmaker",
    "Miss Woodhouse, in literature",
    "Watson or Thompson of 2017's \"Beauty and the Beast\"",
    "Jane Austen heroine",
    "Austen matchmaker",
    "Title heroine who says \"I would much rather have been merry than wise\"",
    "Madame Bovary",
    "Novel character with \"a comfortable home and happy disposition\"",
    "1996 Gwyneth Paltrow title role",
    "Poet Lazarus",
    "Jane Austen novel on which \"Clueless\" is based",
    "Stone in Hollywood",
    "Miss Woodhouse of Hartfield",
    "Jane Austen novel",
    "Actress Stone of \"Birdman\"",
    "George Knightley's love interest, in literature",
    "Jane Austen novel",
    "Miss Woodhouse of Highbury",
    "Watson who played Hermione Granger",
    "Austen title heroine",
    "Stone of \"The Help\"",
    "Jane Austen heroine",
    "Jane Austen classic",
    "Title character from the village of Highbury, 1815",
    "Title heroine described in the first lines of her novel as \"handsome, clever and rich\"",
    "Watson who played Hermione Granger",
    "Literary matchmaker",
    "Actress Stone of \"The Help\"",
    "Watson of the Harry Potter films",
    "Watson of the Harry Potter films",
    "Anarchist Goldman",
    "Actress Watson",
    "Austen heroine",
    "Title matchmaker of early 19th-century literature",
    "Mrs. 55-Down on \"The Avengers\"",
    "1996 Gwyneth Paltrow title role",
    "Jane Austen meddler",
    "Austen novel",
    "Classic novel that ends with two weddings",
    "Austen classic",
    "Novel on which \"Clueless\" is based",
    "Actress Thompson",
    "Title heroine who says \"One half of the world cannot understand the pleasures of the other\"",
    "Madame Bovary",
    "Novel on which \"Clueless\" is based",
    "Actress Thompson of \"Howards End\"",
    "Title heroine described in the first sentence of her novel as \"handsome, clever and rich\"",
    "Jane Austen novel",
    "Jane Austen novel",
    "Actress Samms",
    "Austen's Woodhouse",
    "Jane Austen heroine",
    "Novel on which \"Clueless\" is based",
    "Gwyneth Paltrow title role",
    "Rachel's baby on \"Friends\"",
    "Madame Bovary",
    "Madame Bovary",
    "Jane Austen novel",
    "Jane Austen heroine",
    "Madame Bovary",
    "Lazarus or Goldman",
    "Meddlesome heroine of an 1816 novel",
    "Thompson with an Oscar",
    "Title heroine of 1816",
    "Madame Bovary",
    "Mrs. Peel of \"The Avengers\"",
    "Jane Austen classic",
    "Literary matchmaker",
    "Jane Austen heroine",
    "\"Friends\" baby",
    "Madame Bovary",
    "Miss Woodhouse of fiction",
    "Lazarus or Goldman",
    "Austen novel",
    "Jane Austen novel",
    "1998 role for Uma",
    "The avenging Mrs. Peel",
    "Austen novel",
    "Mme. Bovary",
    "Austen novel",
    "Austen heroine",
    "Flaubert heroine",
    "Madame Bovary",
    "Austen heroine",
    "Mrs. Peel of \"The Avengers\"",
    "Austen heroine",
    "Austen heroine",
    "Oscar winner ___ Thompson",
    "Actress Thompson",
    "Austen heroine",
    "Austen heroine",
    "Austen novel made into a 1996 movie",
    "Thompson of \"Sense and Sensibility\"",
    "Jane Austen novel",
    "19th-century educator Willard",
    "Austen heroine",
    "Austen's Woodhouse",
    "Austen heroine",
    "Austen heroine",
    "Mystery author Lathen",
    "1816 novel made into a 1996 film",
    "Oscar-winning Thompson",
    "Madame Bovary",
    "Comic Austen work",
    "Poet Lazarus",
    "Madame Bovary",
    "Jane Austen novel",
    "Poet Lazarus",
    "Lady Hamilton",
    "Austen title",
    "Mrs. Peel from \"The Avengers\"",
    "Poet Lazarus",
    "Poet Lazarus",
    "Poet Lazarus",
    "Madame Bovary",
    "Austen heroine",
    "Anarchist Goldman",
    "Heroine of an Austen novel",
    "Thompson of films",
    "Austen's Woodhouse",
    "Thompson of \"Howards End\""
  ],
  "huhs": [
    "Confused responses",
    "Quizzical responses",
    "Confused responses",
    "Confused responses",
    "Bewildered utterances",
    "Expressions of disbelief"
  ],
  "fate": [
    "\"A man's character is his ___\": Heraclitus",
    "Destiny",
    "What the opening motif of 36-Across is said to represent",
    "Kismet",
    "Destiny",
    "It's unavoidable",
    "Kismet",
    "Kismet",
    "Destiny",
    "What the Roman goddess Fortuna controls",
    "Theme in some time travel fiction",
    "It's meant to be",
    "Writing on the wall, so to speak",
    "It \"will find a way,\" according to Virgil",
    "It's inescapable",
    "Topic to ask a fortuneteller about",
    "\"The stars\"",
    "Destiny",
    "Lot in life",
    "It may be tempted",
    "It's unavoidable",
    "White-robed figure of Greek mythology",
    "Writing on the wall",
    "The way the cookie crumbles",
    "Destiny",
    "Conventional explanation for a tragic event",
    "Karma",
    "You can't escape it",
    "Them's the breaks",
    "Emerson said intellect annuls it",
    "It may be tempted",
    "Predestination",
    "Lot in life",
    "Kismet",
    "Destiny",
    "Horoscope revelation",
    "Lot",
    "It may be tempted",
    "Lot in life",
    "What will be",
    "Kismet",
    "Lot in life",
    "Inevitability",
    "Kismet",
    "Doom",
    "One way to explain a coincidence",
    "\"___ laughs at probabilities\": Bulwer-Lytton",
    "Kismet",
    "Fortune",
    "There's no changing it",
    "It may be tempted",
    "It may have a twist",
    "Kismet",
    "Lot",
    "Kismet",
    "Kismet",
    "Oracle's reading",
    "Lot",
    "Fortune",
    "Kismet"
  ],
  "cory": [
    "Booker in Washington",
    "\"Boy Meets World\" boy",
    "Sen. Booker of New Jersey",
    "\"Boy Meets World\" boy",
    "\"Boy Meets World\" boy",
    "\"Mister ___\" (1957 Tony Curtis film)",
    "\"Richard ___\" (E.A. Robinson poem)"
  ],
  "tang": [
    "Sharp quality",
    "Taste with a little zip",
    "Grocery product with orange packaging",
    "Sharp quality",
    "Piquancy",
    "Drink disliked by Buzz Aldrin [true fact!]",
    "Strong, sharp smell",
    "Kool-Aid alternative",
    "Zippy flavor",
    "Zest",
    "\"It's a kick in a glass\" sloganeer, once",
    "Chinese dynasty of 1,200 years ago",
    "Little bite",
    "Kraft Foods drink",
    "Aftertaste, e.g.",
    "Orange-flavored powdered drink",
    "Zesty flavor",
    "Little bite",
    "Zest",
    "Bite",
    "Zingy taste",
    "Powdered drink mix",
    "General Foods introduced it in 1957",
    "Salsa quality",
    "Zingy taste",
    "Bite",
    "Sharp flavor",
    "Bite",
    "Zestiness",
    "Punch",
    "Bite",
    "Punch",
    "Little kick",
    "Little kick",
    "Bite",
    "Ersatz juice",
    "Slight trace",
    "Flavor to remember",
    "Sharp taste",
    "Astronauts' ade",
    "Bite",
    "Distinctive taste",
    "Chinese dynasty",
    "Surprise for the taste buds",
    "Supply on old spaceships"
  ],
  "sibs": [
    "Close ones, informally",
    "Bro and sis",
    "Some members of the fam",
    "Some family folks, informally",
    "Bros, e.g.",
    "Some kin",
    "Close kin, for short",
    "Genealogical grouping, informally",
    "Kids in the fam",
    "Some bunk bed sharers, for short",
    "Quads, e.g.",
    "Brothers and sisters, for short",
    "Brothers and sisters, for short",
    "Participants in some rivalries, briefly",
    "Bros, e.g.",
    "Ones sharing a branch of a tree",
    "What an only child lacks",
    "Young rivals, often",
    "Rivals for the folks' attention, maybe",
    "Bedroom sharers, informally",
    "Bedroom sharers, maybe",
    "They're all in the family",
    "They're all in the family",
    "They're all in the family",
    "Estate sharers, often",
    "Brothers and sisters",
    "Brothers and others",
    "Fam. members",
    "Family members",
    "Twins, e.g.",
    "Brother and sister",
    "Household rivals",
    "Brothers, e.g."
  ],
  "esta": [
    "\"___ bien\"",
    "\"Como ___?\"",
    "___ noche (tonight: Sp.)",
    "___ noche (tonight: Sp.)",
    "\"Como ___ usted?\"",
    "\"Como ___?\"",
    "Part of the Spanish conjugation of \"to be\"",
    "This, in Tijuana",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "___ noche (tonight: Sp.)",
    "\"___ bien\"",
    "This: Sp.",
    "This: Sp.",
    "___ noche (tonight: Sp.)",
    "What's this, Senor?",
    "\"Como ___ usted?\"",
    "It is, en espanol",
    "\"Como ___ usted?\"",
    "\"Como ___?\" (\"How are you?\": Sp.)",
    "This: Sp.",
    "\"Como ___?\"",
    "This: Sp.",
    "\"Como\" follower",
    "\"Como ___ usted?\"",
    "\"My Baby No ___ Aqui\" (Garth Brooks song)",
    "It is, in Ibiza",
    "\"Como ___?\"",
    "\"Como ___?\"",
    "Spanish 101 word",
    "It is in Spain",
    "Juanita's \"this\"",
    "\"Como ___?\"",
    "This: Sp.",
    "\"___ bien\" (Spanish for \"It's good\")",
    "\"Como ___?\"",
    "This, in Toledo",
    "\"Como ___?\"",
    "Spanish demonstrative",
    "\"Como ___?\"",
    "\"Como ___ usted?\"",
    "This, to Picasso",
    "\"Como ___?\"",
    "\"Como ___ usted?\"",
    "This, in Madrid",
    "It is, in Peru",
    "This one, in Acapulco",
    "Spanish for \"are\"",
    "Are, in Argentina",
    "Is in Spain?",
    "\"Como ___ usted?\"",
    "Spanish pronoun",
    "\"Donde ___ ...?\"",
    "\"Como ___ usted?\"",
    "It is in Peru",
    "This, in Havana",
    "Spanish pronoun",
    "\"Como ___ usted?\"",
    "\"Donde ___ ...?\"",
    "Is, to Isabella",
    "Spanish pronoun",
    "\"Como ___ usted?\"",
    "\"Como ___?\" (with a hint to this puzzle's theme)",
    "This, in Madrid",
    "\"Como ___ usted?\"",
    "Spanish for \"are\"",
    "\"Como ___?\"",
    "\"Como ___?\"",
    "Are, in Avila",
    "Is Spanish?",
    "This, in Toledo",
    "\"Como ___ usted?\"",
    "?ôComo ___ usted??ö",
    "Are, in Aragon",
    "Are, in Avila",
    "___ noche (tonight, in Tijuana)",
    "Spanish pronoun",
    "This, in Toledo",
    "\"Como ___?\"",
    "\"Como ___?\"",
    "Spanish form of \"to be\"",
    "\"Como ___ usted?\"",
    "This, south of the border",
    "Form of Spanish \"to be\"",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "This, in Tijuana",
    "This, south of the border",
    "This, in Toledo",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "This, in Havana",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "What this is in Spain",
    "This one, in Spain",
    "\"Como ___?\"",
    "Are, in Argentina",
    "Spanish 101 verb",
    "This, south of the border",
    "Are, in Aragon",
    "\"Como ___ usted?\"",
    "This, to Tomas",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "This, to Pedro",
    "\"Como ___ usted?\"",
    "\"Como___usted?\"",
    "This, to Juan",
    "\"Como___usted?\"",
    "\"Como___usted?\"",
    "\"Como___ usted?\"",
    "Word following donde",
    "\"Como ___ usted?\"",
    "\"Como ___ usted?\"",
    "\"___ bien\" (\"That's fine\"): Sp.",
    "\"Como ___?\"",
    "\"??Como ___ usted?\"",
    "\"___ bien\" (all right!): Sp.",
    "This, senor",
    "This, in Barcelona",
    "It is in Spain",
    "\"Como ___ usted?\"",
    "This senora",
    "This senora"
  ],
  "lime": [
    "Cuba libre ingredient",
    "Garnish for a gimlet",
    "Shade of green",
    "Gimlet garnish",
    "See 38-Across",
    "Garnish for a Corona",
    "Garnish for a Corona",
    "Shade of green",
    "Tart, green fruit",
    "One of the two flavors in Sprite",
    "Green shade",
    "Gimlet garnish",
    "Corona garnish",
    "\"You put the ___ in the coconut ...\"",
    "See 17-Down",
    "Vitamin C source",
    "Lemon-___ (Kool-Aid flavor)",
    "Twist choice",
    "Green shade",
    "7-Up flavor",
    "Gimlet garnish",
    "Foul-line material",
    "Jell-O flavor",
    "Sprite flavor",
    "It might be added with a twist",
    "Fertilizer ingredient",
    "Cola additive",
    "Mexican beer go-with",
    "Sno-cone choice",
    "Lawn additive",
    "Drink garnish",
    "Green fruit drink flavor",
    "CaO",
    "Green shade",
    "Tom Collins ingredient",
    "Wedge in a bottleneck?",
    "Gimlet flavoring",
    "Pucker producer",
    "Fruit for a twist",
    "Whitewash",
    "Twist, sometimes",
    "Lawn application",
    "Kiln output",
    "Vitamin C source",
    "Many a twist",
    "Green flavor",
    "Greenish",
    "Lawn care product",
    "Margarita fruit",
    "Gimlet garnish",
    "Collins juice",
    "Shade of green",
    "Welles's \"The Third Man\" role",
    "Citrus fruit",
    "Margarita fruit",
    "Margarita garnish",
    "Gardener's purchase",
    "Whitewash ingredient",
    "Lemon go-with",
    "Club soda fillip",
    "7-Up ingredient",
    "Treat the lawn",
    "Kool-Aid flavor",
    "Margarita ingredient",
    "Lawn-and-garden shop purchase",
    "Juice flavor",
    "Lawn care product",
    "Green shade",
    "Citrus flavor",
    "Lemon's partner",
    "Rickey ingredient",
    "Soil sweetener"
  ],
  "feud": [
    "Family matter, maybe",
    "Fight protractedly",
    "Nasty, long-running dispute",
    "Rhubarb with deep roots?",
    "Ongoing dispute",
    "Long row",
    "Quarrel",
    "Blood problem, maybe",
    "Fight between late-night hosts, e.g.",
    "Not just a tiff",
    "Long-term hostility",
    "Bad blood",
    "Long row",
    "Hatfield/McCoy affair",
    "Hatfield/McCoy affair",
    "Row between houses?",
    "\"Family ___\"",
    "Family problem",
    "More than a tiff",
    "Clash of clans",
    "Clash of clans",
    "TV's \"Family ___\"",
    "Family problem",
    "Family troubles",
    "Hatfield-McCoy affair",
    "More than an argument"
  ],
  "soar": [
    "Touch the sky",
    "Succeed and then some",
    "What kites do in the wind",
    "Fly high",
    "Fly high",
    "Zoom up",
    "Fly majestically",
    "What spirits can do",
    "Fly high",
    "Fly high",
    "Opposite of plummet",
    "Not just increase",
    "Fly high",
    "Rise quickly",
    "What prices do during hyperinflation",
    "Fly high",
    "Go through the roof",
    "What spirits can do",
    "Take off",
    "What spirits may do",
    "Really go up",
    "Take to the skies",
    "Wing it?",
    "Fly high",
    "Take off",
    "Take off",
    "Go sky-high",
    "Rocket",
    "Go up, up, up",
    "Take off",
    "Increase dramatically",
    "Go up, up, up",
    "Go into the wild blue yonder",
    "Rise rapidly",
    "Triple, quadruple or more",
    "Shoot up",
    "Zoom up",
    "Rocket",
    "What some prices and spirits do",
    "Fly high",
    "Skyrocket",
    "Take off",
    "Skyrocket",
    "Hit the stratosphere",
    "Fly high",
    "Fly into the wild blue yonder",
    "Go out of sight, as gas prices",
    "Take off",
    "Wing it?",
    "Really take off",
    "Take to the skies",
    "Go up, up, up",
    "Really take off",
    "Take to the sky",
    "Fly high",
    "Shoot up",
    "Zoom",
    "Go parasailing",
    "Take off",
    "Fly like an eagle",
    "Rocket",
    "Take off",
    "Go up",
    "Rise quickly",
    "Fly high",
    "Fly high",
    "What prices may do",
    "Rise dramatically",
    "Fly high",
    "Take 5, clue 4",
    "Increase sharply",
    "Go sky-high",
    "Plummet's opposite",
    "*Third step",
    "Take off",
    "Take off",
    "Take wing",
    "Skyrocket",
    "Fly high",
    "Take off",
    "Fly like an eagle",
    "Skyrocket",
    "Fly high",
    "Catch the wind under one's wings",
    "Zoom",
    "Shoot up",
    "Skyrocket",
    "Glide aloft",
    "Go parasailing",
    "Fly high",
    "Increase dramatically",
    "Skyrocket",
    "Zoom",
    "Fly high",
    "Zoom",
    "Fly high",
    "Take off",
    "What hopes may do",
    "Rocket",
    "Eagles do it",
    "Emulate Icarus"
  ],
  "mako": [
    "One-named Japanese-born actor with a star on the Hollywood Walk of Fame",
    "Relative of a great white",
    "Speedy shark",
    "Fast-swimming shark",
    "Fast-swimming shark",
    "Ocean menace",
    "\"Shortfin\" or \"longfin\" fish",
    "Kind of shark",
    "Shark on some menus",
    "Dangerous shark",
    "Warm-blooded shark",
    "Cousin of a hammerhead",
    "Speedy fish",
    "Powerful shark",
    "Certain shark",
    "Shark variety",
    "Powerful shark",
    "Deadly shark"
  ],
  "shut": [
    "Close",
    "Closed",
    "Closed",
    "\"___ your piehole!\"",
    "Out of business",
    "Close",
    "Open's opposite",
    "Close",
    "Turn (off)",
    "Clam (up)",
    "Block",
    "Sealed",
    "Seal",
    "Closed",
    "Close",
    "Boarded up",
    "Seal up",
    "Out of business",
    "Bolted",
    "Latched",
    "Close",
    "Out of business",
    "Latched",
    "Barricade",
    "Close",
    "Put out of business",
    "Close",
    "Barred",
    "Closed",
    "Barricade, with \"in\"",
    "Seal",
    "Close",
    "Unopened",
    "Out of business",
    "Close",
    "Close",
    "Close",
    "Close down",
    "Out of business",
    "Folded up",
    "Latched",
    "Sealed"
  ],
  "sows": [
    "Plants",
    "Farm females",
    "Farm females",
    "Scatters, as seeds",
    "Makes one's bed?",
    "Plants in a field",
    "Seeds",
    "Piglets' mothers",
    "Tosses, as seeds",
    "Farm females",
    "Promulgates",
    "Farm females",
    "Disseminates",
    "Pigtailed mothers?",
    "Foments",
    "Some eaters at troughs",
    "Scatters seeds",
    "Propagates",
    "Scatters, as seed",
    "Circulates",
    "Farm animals",
    "Females with pig-tails",
    "Promulgates",
    "Farm females",
    "Pen mothers",
    "Farm females",
    "Farm females",
    "Propagates",
    "Starts a crop",
    "Miss Piggy and others"
  ],
  "abit": [
    "___ much",
    "Not much",
    "Slightly",
    "Not much",
    "Ever so slightly",
    "Not much",
    "Slightly",
    "Not much",
    "Sort of",
    "Not much",
    "Somewhat",
    "Not much",
    "Not much",
    "Slightly",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Some",
    "Not very much",
    "\"This won't hurt ___!\"",
    "Slightly",
    "Not much",
    "Not much",
    "To some degree",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "\"It won't hurt ___\"",
    "Not much",
    "Slightly",
    "Before long, after \"in\"",
    "Somewhat",
    "Somewhat",
    "Just slightly",
    "Some",
    "Not much",
    "Slightly",
    "Partially",
    "Not much",
    "Somewhat",
    "Kind of",
    "Not much",
    "Some",
    "Some",
    "Somewhat",
    "Not much",
    "\"This won't hurt ___\"",
    "Some",
    "Somewhat",
    "Not much",
    "Slightly",
    "Somewhat",
    "\"This won't hurt ___\"",
    "Some",
    "Slightly",
    "Slightly",
    "Some",
    "Some",
    "Some",
    "Somewhat",
    "Slightly",
    "\"This won't hurt ___!\"",
    "Some",
    "Rather",
    "Somewhat",
    "Rather",
    "Rather",
    "Some",
    "\"Isn't that ___ much?\""
  ],
  "note": [
    "Put down, in a way",
    "Make mention of",
    "A or B, but not Y or Z",
    "A student may pass it",
    "It's not a lot to jot",
    "Word often followed by a colon",
    "Observe",
    "Mark down",
    "Point out",
    "Reminder to oneself, perhaps",
    "C or D",
    "Make mention of",
    "Bill",
    "Fa or la",
    "Observe",
    "Do, re or mi",
    "\"See here ...\"",
    "Do, re or mi",
    "Brief letter",
    "Word that can follow the ends of 20- and 54-Across and 4- and 26-Down",
    "Point out",
    "Observe",
    "A, B or C",
    "A sixteenth is a short one",
    "Memo",
    "A or B, but not O",
    "Memorandum",
    "Chord part",
    "Memo",
    "One on the staff?",
    "___ to self",
    "Musical production",
    "Staff member?",
    "Memo",
    "Small letter",
    "Make mention of",
    "Memo",
    "Spot on the staff?",
    "\"Consider ...\"",
    "Post-it, e.g.",
    "Score component",
    "Part of a melody",
    "Half, quarter or eighth follower",
    "Do, re or mi",
    "Point out",
    "Post-it, e.g.",
    "One given a staff position?",
    "See 59-Across",
    "One with a staff position?",
    "Do, for example",
    "Post-it message",
    "Score unit",
    "Post-it",
    "A flat, e.g.",
    "Observe",
    "Middle C, e.g.",
    "Memo",
    "Written reminder",
    "Do, say",
    "Post-it",
    "A security",
    "Do, for example",
    "Post-it message",
    "Do, e.g.",
    "Musical mark",
    "Something passed in music class?",
    "Melody part",
    "Sol, say",
    "Staff member?",
    "One with a staff position",
    "Staff member?",
    "Word before a colon",
    "G, e.g.",
    "\"Name That Tune\" clue",
    "Scribbling",
    "Post-It",
    "Quaver or semiquaver",
    "Certificate",
    "Reminder",
    "Put down",
    "Quick letter",
    "Fridge posting",
    "Post-It, e.g.",
    "Any letter from A to G",
    "Score unit",
    "Memo",
    "Distinction",
    "Post-It",
    "Observe",
    "E or G, e.g.",
    "Memo",
    "Do, say",
    "See 42-Across",
    "T or C follower",
    "Memo",
    "Score unit",
    "Short letter",
    "It can fall flat",
    "Do, re or mi, e.g.",
    "Bit of marginalia",
    "Quaver, e.g.",
    "Semiquaver, e.g.",
    "Form of a thank-you",
    "Memorandum",
    "A or B, e.g.",
    "Observe",
    "Paper money",
    "Memo",
    "Memo",
    "Score unit",
    "Observe"
  ],
  "erin": [
    "Pseudonymous children's author Hunter",
    "Sportscaster Andrews",
    "Ireland, poetically",
    "\"___ Brockovich\"",
    "Sportscaster Andrews",
    "Environmental activist Brockovich",
    "Sportscaster Andrews",
    "Ireland, to poets",
    "\"___ go bragh!\"",
    "Blarney Stone site",
    "Andrews of \"Dancing With the Stars\"",
    "Ireland, poetically",
    "Land o' leprechauns",
    "Ireland, in poetry",
    "Ireland, to an Irish poet",
    "Receptionist on \"The Office\"",
    "Sports reporter Andrews",
    "CNN anchor Burnett",
    "Home of the Blarney Stone",
    "\"___ go bragh!\"",
    "Land of leprechauns",
    "Popular Irish girl's name",
    "Secretary on \"The Office\"",
    "Ireland, to poets",
    "The Emerald Isle",
    "Land for O'Connor or O'Casey",
    "\"Dancing With the Stars\" co-host Andrews",
    "The Emerald Isle",
    "\"Happy Days\" actress Moran",
    "Leprechauns' land",
    "\"___ go bragh!\"",
    "Poetic Ireland",
    "Land celebrated on March 17",
    "Enya's land",
    "\"___ go bragh!\"",
    "Home to an 8-Down",
    "Burnett of CNN",
    "One side of St. George's Channel",
    "The Emerald Isle",
    "CNN host Burnett",
    "Land celebrated on March 17",
    "Consumer advocate Brockovich",
    "Burnett of CNN",
    "\"Dancing With the Stars\" co-host Andrews",
    "Newswoman Burnett",
    "Mayo's place",
    "\"___ go bragh!\"",
    "The Auld Sod",
    "\"The Night Circus\" author Morgenstern",
    "___ Andrews, co-host of \"Dancing With the Stars\"",
    "Home of the Lia Fail",
    "\"The Night Circus\" author Morgenstern",
    "Word before \"go\" in a seasonal phrase",
    "Activist Brockovich",
    "The Auld Sod",
    "Land of the poet Mairtin O Direain",
    "Environmental activist Brockovich",
    "Hibernia",
    "Fairies' land",
    "The Emerald Isle",
    "Blarney Stone home",
    "Andrews of Fox Sports",
    "CNN anchor Burnett",
    "Girl's name that's also a place name",
    "Sons of ___ (ethnic pride group)",
    "Leprechaun's home",
    "CNN's Burnett",
    "Where many last names start with \"O\"",
    "Land o' blarney",
    "Journalist Burnett of 55-Down",
    "Ireland",
    "Blarney source",
    "Country in a Thomas Moore poem",
    "Leprechaun land",
    "Land o' blarney",
    "Sportscaster Andrews",
    "Limerick's land",
    "ESPN reporter Andrews",
    "Place name before and after \"Oh\" in a Thomas Moore title",
    "Leprechaun's land",
    "Joyce's land",
    "St. Paddy's land",
    "St. Patrick's land",
    "Secretary on \"The Office\"",
    "Celtic land",
    "Hibernia",
    "John-Boy Walton's sister",
    "\"___ Brockovich\"",
    "Celtic land",
    "Hibernia",
    "A Walton",
    "Land that's saluted in this puzzle",
    "\"___ go bragh!\"",
    "Sons of ___ (group promoting Irish heritage)",
    "Moran of \"Happy Days\"",
    "Port ___, seaside resort on the Isle of Man",
    "The Auld Sod",
    "Land o' leprechauns",
    "\"___ Brockovich\"",
    "Land o' the Irish",
    "\"The emerald of Europe\"",
    "Isle of poetry",
    "Land o' blarney",
    "\"___ go bragh!\"",
    "Home of 1-Down",
    "Yeats's land, poetically",
    "Poetic land",
    "Land o' blarney",
    "Poetic country name",
    "The Isle of Man's Port ___",
    "Land of leprechauns",
    "Subject of some Thomas Moore poetry",
    "\"___ go bragh!\"",
    "One of the Waltons",
    "Killarney's land",
    "Actress Moran of \"Happy Days\"",
    "Land o' blarney",
    "March slogan word",
    "Activist Brockovich",
    "Land of poetry",
    "Poetic name for Ireland",
    "Nickname for Ireland",
    "Name for an Irish lass",
    "\"___ Brockovich\"",
    "Name meaning, literally, west island",
    "Leprechaun's land",
    "Dublin's home",
    "\"___ go bragh!\"",
    "Faerie land",
    "___ Brockovich, Julia Roberts title role",
    "Land o' blarney",
    "Dublin?Æs land, in poetry",
    "Sister on \"The Waltons\"",
    "Girl's name (or a hint to this puzzle's theme)",
    "\"___ go bragh\"",
    "Green land?",
    "Irish girl's name",
    "Oscar-winning role for Julia",
    "___ Brockovich, Oscar role for Julia Roberts",
    "Leprechaun land",
    "Port ___ (Isle of Man resort)",
    "\"___ Brockovich\"",
    "Ireland, in poesy",
    "One of the Waltons",
    "Sean O'Faolain's \"Come Back to ___\"",
    "Leprechauns' land",
    "Land of literature",
    "Land of O'Kelly and O'Keeffe",
    "Hibernia",
    "\"___ Brockovich\"",
    "\"___ go bragh\"",
    "March 17 slogan word",
    "Home for Murphy and O'Brien",
    "\"___ go bragh\"",
    "Yeats's isle",
    "Land of Killarney",
    "Irish lass's name",
    "Julia's Oscar-winning role",
    "Leprechaun's land",
    "Leprechaun land",
    "Actress Moran",
    "\"Come Back to ___\" (old song)",
    "Name for an Irish lass",
    "Hibernia",
    "Hibernia",
    "Leprechaun's land",
    "Land of fairies",
    "Land of Tralee",
    "St. Patrick's home",
    "2000 title role for Julia",
    "Leprechaun's land",
    "Name for an Irish lass",
    "The Emerald Isle",
    "Actress Gray",
    "___ go bragh",
    "Gray of \"Six Pack\"",
    "Ireland, poetically",
    "Leprechaun's land",
    "Land of sentimental verse",
    "The Emerald Isle",
    "Snakeless isle",
    "Ireland",
    "Beloved subject of Thomas Campbell",
    "St. Patrick's land",
    "Green land",
    "The Emerald Isle",
    "Neighbor of Britannia",
    "Land of poetry",
    "Shamrock land",
    "Land of the leprechauns",
    "The old sod",
    "Leprechauns' land",
    "Joyce's homeland",
    "Ireland",
    "Name for a colleen",
    "A Walton",
    "Yeats's land",
    "Irish name",
    "One of the Waltons",
    "Land of elves",
    "Thomas Moore's land",
    "Land of 47-Down",
    "Object of March celebrations",
    "Thomas Moore's homeland",
    "Ireland",
    "Land of literature",
    "\"___ go bragh\"",
    "\"___ go bragh\"",
    "Neighbor of Albion",
    "Land of poetry",
    "Yeats's land",
    "Land of poetry",
    "Ireland",
    "Moran of \"Happy Days\"",
    "Land of poetry",
    "Land whose name means \"west island\"",
    "Hibernia",
    "\"___go bragh!\"",
    "Land of the Blarney stone",
    "St. Paddy's Day girl",
    "Moran of \"Happy Days\"",
    "Davis of TV's \"Sinbad\"",
    "Green land",
    "Irish lass?Æs name",
    "Ireland",
    "Yeat's home",
    "Leprechaun's turf",
    "Thomas Moore subject",
    "Moran of \"Happy Days\"",
    "Brian Boru's land",
    "Actress Gray",
    "Dublin's land",
    "Bedelia's home, in a folk song",
    "Actress Moran",
    "Green land",
    "Sister of John-Boy Walton",
    "William Drennan poem of 1795",
    "Hibernia",
    "Leprechaun's land",
    "Green land",
    "St. Patrick's home",
    "Shillelagh land",
    "The old sod",
    "Green acres?",
    "Actress Moran"
  ],
  "dams": [
    "Blocks",
    "Stops up",
    "Blocks off",
    "Civil engineering projects",
    "Creators of artificial lakes",
    "Challenges for salmon",
    "Beaver projects",
    "Lake creators",
    "Beavers build them",
    "Blockades, of a sort",
    "Blocks (up)",
    "Beaver constructions",
    "Projects for beavers",
    "Blocks",
    "Beaver constructions",
    "Reservoir producers",
    "Hoover and others",
    "Power sources",
    "Stops up",
    "Blocks",
    "Hungry Horse and Mossyrock",
    "Farm mothers",
    "Stops up",
    "Stops up",
    "Beavers' constructions",
    "Stops up",
    "Creek constrictors",
    "Hoover and Roosevelt, e.g.",
    "Beavers' constructions",
    "Beaver projects"
  ],
  "neer": [
    "\"I ___ saw true beauty till this night\": Romeo",
    "Poet's contraction",
    "Start to do well?",
    "\"... and ___ the twain shall meet\"",
    "\"What, will these hands ___ be clean?\": Lady Macbeth",
    "\"... ___ the twain shall meet\"",
    "Not e'en once",
    "___-do-well",
    "Beginning to do well?",
    "Contraction lacking just a \"v\"",
    "Aye's opposite, poetically",
    "Contraction missing a V",
    "\"Success is counted sweetest / By those who ___ succeed\": Emily Dickinson",
    "When Romeo says he \"saw true beauty\" before seeing Juliet",
    "\"A fuller blast ___ shook our battlements\": \"Othello\"",
    "Start to do well?",
    "\"So sweet was ___ so fatal\": Othello",
    "Opposite of alway",
    "Aye's opposite, in verse",
    "Poetic adverb",
    "Poetic contraction",
    "\"___ the twain shall meet\"",
    "Aye's opposite, poetically",
    "Thomas Moore's \"___ Ask the Hour\"",
    "Not e'en once",
    "Not e'en once",
    "___-do-well",
    "Opposite of alway",
    "___-do-well",
    "Not e'en once",
    "Poetic contraction",
    "___-do-well",
    "Not e'en once",
    "Start to do well?",
    "Poetic adverb",
    "Not e'en once",
    "___-do-well",
    "Poetic adverb",
    "Not e'en once",
    "___-do-well",
    "\"Faint heart ___ won ...\"",
    "Poetic adverb",
    "Poetic adverb",
    "___-do-well",
    "Not e'en once",
    "At no time, poetically",
    "___-do-well",
    "___-do-well",
    "Poetic negative",
    "Not e'en once",
    "___-do-well",
    "___-do-well",
    "Not e'en once",
    "Poetic adverb",
    "___-do-well",
    "Not e'en once",
    "Literary adverb",
    "___-do-well",
    "At no time, in poetry",
    "At no time, to poets",
    "\"...and ___ the twain shall meet\"",
    "___-do-well",
    "Poet's adverb",
    "___-do-well",
    "Do-well starter",
    "Poetic contraction",
    "Poetic adverb",
    "Poet's contraction",
    "___-do-well",
    "___-do-well",
    "___-do-well",
    "Do-well intro",
    "Poetic contraction",
    "Poetic contraction",
    "Not e'en once",
    "\"___the twain shall meet\"",
    "Poetic contraction",
    "Beginning to do well?",
    "___-do-well",
    "Opposite of alway",
    "___-do-well",
    "___-do-well (good for nothing)",
    "Poetic contraction",
    "Not e'en once",
    "___-do-well",
    "___-do-well",
    "Opposite of alway",
    "\"___ was the sky so deep a hue\": Warner",
    "At no time, to poets",
    "___-do-well",
    "Poet's contraction",
    "___-do-well",
    "Not e'en once",
    "Not aye"
  ],
  "hoho": [
    "Alternative to a Ding Dong",
    "Jolly laugh",
    "Snack cake with a swirly filling",
    "Alternative to a Yodel or a Swiss Roll",
    "Christmas cheer?",
    "Deep laugh",
    "Brown-and-white treat",
    "Santa's favorite snack cake?",
    "Treat similar to a Yodel",
    "Cake similar to a Yodel",
    "Syllables from Santa",
    "Hostess snack cake",
    "Chocolaty treat",
    "Jolly laugh",
    "Twinkie alternative",
    "Jolly laugh",
    "Alternative to a Twinkie",
    "Jolly exclamation",
    "Guffaw",
    "Deep laugh",
    "Popular cream-filled cake",
    "Alternative to a Twinkie",
    "Seasonal greeting",
    "Christmas sound",
    "Cry from Santa",
    "Jolly response",
    "That's a laugh",
    "Santa sounds",
    "Christmas exclamation",
    "Deep laugh",
    "\"Oh, very funny!\"",
    "Jolly sound",
    "Belly laugh",
    "Belly laugh"
  ],
  "delt": [
    "It's found near a trap",
    "Muscle targeted by military presses, in brief",
    "Muscle that can be exercised by dumbbells, for short",
    "Neighbor of a pec",
    "Shoulder muscle, briefly",
    "Press target, informally",
    "Upper body muscle, for short",
    "Vaccination muscle, for short",
    "Shoulder muscle, informally",
    "Shoulder muscle, to a gym rat",
    "Shoulder muscle, for short",
    "Fraternity member or muscle, briefly",
    "Shoulder muscle, for short",
    "Pitching muscle, for short",
    "Shoulder press target, briefly",
    "Arm raiser, informally",
    "Shoulder muscle, for short",
    "Shoulder muscle, briefly",
    "Shoulder muscle, informally",
    "Shoulder muscle, briefly",
    "Shoulder muscle, briefly",
    "Shoulder muscle, briefly",
    "Shoulder muscle, briefly",
    "Arm raiser, informally",
    "Shoulder muscle, briefly",
    "Shoulder muscle, informally",
    "Shoulder muscle, briefly"
  ],
  "aqua": [
    "Shade akin to turquoise",
    "Greenish blue",
    "Watery shade",
    "Shade at the beach",
    "Lead-in to \"man\" in superhero-dom",
    "Water color",
    "Lead-in to culture",
    "Shade at the beach",
    "Shade similar to turquoise",
    "Blue hue",
    "___ fortis (another name for nitric acid)",
    "Light blue",
    "Shade at the beach",
    "Cool shade",
    "Shade akin to cerulean",
    "Blue shade",
    "Bluish-green",
    "Blue hue",
    "Pale blue hue",
    "An official color of the Miami Dolphins",
    "Light blue",
    "Shade of blue",
    "Light blue hue",
    "Beautiful water hue",
    "Color of McCartney's \"Sgt. Pepper\" uniform",
    "With 53-Down, pioneering mechanical breathing apparatus",
    "Light blue",
    "Blue shade",
    "Shade at the swimming pool",
    "Pastel blue",
    "Water, in Latin",
    "Greenish blue",
    "With 18-Across, an old term for brandy",
    "Soft shade",
    "A pastel",
    "Swimming pool shade",
    "44-Across shade",
    "Relative of cerulean",
    "Pastel hue",
    "Blue shade",
    "A pastel",
    "Blue-green",
    "Water",
    "Water color",
    "Watery hue",
    "Pastel hue",
    "Light blue",
    "Shade at the beach?",
    "Blue hue",
    "Shade close to beryl",
    "Light greenish blue",
    "Shade of many a swimming pool basin",
    "Pastel hue",
    "Shade at a swimming pool",
    "Blue hue",
    "Light shade",
    "Shade of blue",
    "Pastel hue",
    "Pale hue",
    "Like the sea",
    "Greenish-blue",
    "Greenish-blue",
    "Pastel shade",
    "Like the ocean",
    "Pale hue",
    "Like the sea",
    "Water",
    "Blue hue",
    "Pool shade",
    "Pool shade",
    "Liquid, to a pharmacist",
    "Somewhat blue",
    "Shade of blue",
    "Water color",
    "Cool shade",
    "Shade by the beach?",
    "Pastel shade",
    "Greenish-blue",
    "Blue hue",
    "Soothing shade",
    "Soft shade",
    "Blue shade",
    "Color of water",
    "Water color",
    "Water color",
    "Greenish blue",
    "Plane starter?",
    "___ Velva",
    "Light greenish-blue",
    "Blue hue",
    "Swimming pool shade",
    "Light greenish-blue",
    "Soothing color",
    "Pastel hue",
    "Greenish-blue",
    "Soothing hue",
    "Light hue",
    "Marine leader?",
    "Water",
    "Marine shade",
    "Water color",
    "Shade of blue",
    "Prefix with plane",
    "___-lung",
    "Blue hue",
    "Greenish-blue",
    "Blue hue",
    "___ pura",
    "Water color",
    "Shade of blue",
    "Greenish-blue",
    "A pastel",
    "Water color",
    "Water color",
    "Marine shade",
    "Turquoise",
    "Water color",
    "Water, for Cato",
    "Shade of blue",
    "Pastel",
    "Sea shade",
    "Crayon color",
    "Greenish-blue",
    "Shade of blue",
    "H2O",
    "Greenish-blue",
    "Shade of blue",
    "Plane starter",
    "Lung opening?",
    "Greenish-blue",
    "A pastel",
    "Water color"
  ],
  "hugh": [
    "Actor Laurie of \"House\"",
    "Jackman of \"X-Men\"",
    "Grant for a filmmaker?",
    "Grant for filmmaking?",
    "Actor Grant",
    "Downs of \"20/20\"",
    "The \"H\" of W.H. Auden"
  ],
  "allo": [
    "French greeting",
    "French greeting",
    "Gallic greeting",
    "Gallic greeting",
    "When doubled, popular 1980s-'90s British sitcom",
    "Brisk, in mus."
  ],
  "cnet": [
    "Tech review site",
    "Website for techies",
    "Tech news website",
    "Site with tech tips",
    "Tech info site",
    "Tech-focused website",
    "Tech news source",
    "Popular tech news site",
    "Tech news site",
    "Website with gadget reviews",
    "Website for tech whizzes",
    "Tech review site",
    "Popular tech news site",
    "Tech news website",
    "Site that reviews 44-Down products",
    "Popular tech review site",
    "Website with \"The Next Big Thing\" videos",
    "Popular tech review site",
    "Online tech review site",
    "Big tech review site",
    "Tech media Web site founded in 1994",
    "Tech news site",
    "Site for techies",
    "Online tech news resource",
    "Popular tech news site"
  ],
  "icon": [
    "Standout in one's field",
    "It can represent a folder",
    "Safari's compass, e.g.",
    "Pic to click",
    "App image",
    "Where a phone might be tapped",
    "Google's red, yellow, green and blue \"G,\" e.g.",
    "Clickable symbol",
    "Princess Diana, for one",
    "Much-admired person",
    "Desktop image",
    "A blue compass, for the browser Safari",
    "Green \"X\" for Microsoft Excel, e.g.",
    "Stylized \"W\" for Microsoft Word, e.g.",
    "Recognizable figure",
    "Symbol on a computer screen",
    "Supercelebrity",
    "Word after computer or fashion",
    "Wastebasket or folder, maybe",
    "Big \"G\" for Google, e.g.",
    "Mao Zedong or Mahatma Gandhi",
    "Super superstar",
    "Blue \"W\" for Microsoft Word, e.g.",
    "Folder, maybe",
    "Click it",
    "St. Patrick, for the Irish",
    "Clickable item",
    "Smartphone screen image",
    "Desktop sight",
    "Desktop image",
    "Religious image",
    "Trash bin on a computer screen, e.g.",
    "App image",
    "Something to mouse over",
    "Sacred image",
    "Safari's is a compass",
    "Cher or Dolly Parton, e.g.",
    "Desktop pic",
    "Clickable image",
    "Toolbar image",
    "iPad screen feature",
    "Compass for the web browser Safari, e.g.",
    ":-), for one",
    "Seattle's Space Needle or St. Louis's Gateway Arch",
    "Clickable symbol",
    "A browser has one",
    "Gateway Arch, for St. Louis, e.g.",
    "John Lennon, e.g.",
    "Pop ___",
    "Worshiped one",
    "File folder, e.g.",
    "Universally known symbol",
    "Desktop feature",
    "Clickable computer image",
    "Uncle Sam, for one",
    "Folder, sometimes",
    "Desktop item",
    "Che Guevara or Lady Gaga",
    "Folder, e.g.",
    "Two eighth notes, for iTunes, e.g.",
    "Screen figure",
    "Only thing between you and an open window?",
    "Scissors, for \"cut,\" on a PC",
    "Jackie O, e.g.",
    "Che Guevara, e.g.",
    "Desktop picture",
    "Pic you can click",
    "Recycle bin, for one",
    "Clicked pic",
    "Something to click",
    "Pic to click",
    "Elvis, e.g.",
    "Desktop graphic",
    "Revered one",
    "Desktop folder, e.g.",
    "A program usually has one",
    "David or Victoria Beckham, e.g.",
    "Clickable image",
    "Clickable screen symbol",
    "Pic to click",
    "Symbol",
    "Something to click",
    "Representation",
    "Screen figure",
    "U2's Bono, e.g.",
    "Computer image",
    "Bogart was one",
    "Much-respected person",
    "Sect symbol",
    "Desktop feature",
    "Elvis or Madonna",
    "Screen symbol",
    "Computer symbol",
    "Something a computer user may click",
    "Smiley face, e.g.",
    "Universally known figure",
    "Pointer's target",
    "Desktop feature",
    "Computer symbol",
    "Trash can, e.g., on a computer",
    "Computer screen image",
    "James Dean, e.g.",
    "It may be seen in a window",
    "Screen pic",
    "Bit of Windows dressing?",
    "Desktop marker",
    "Trash can, perhaps",
    "On-screen image",
    "Screen symbol",
    "Image",
    "Symbol",
    "Screen image",
    "Click site",
    "Computer image",
    "Orthodox Church feature",
    "Trash bin, e.g.",
    "PC pictograph",
    "Mao, for one",
    "James Dean or Marilyn Monroe",
    "Trash can on a desktop, e.g.",
    "Clicked image",
    "Clickable image",
    "Computer image",
    "Spot for a cursor, maybe",
    "PC pic",
    "Many a celebrity",
    "Trash can, for one",
    "Clickable symbol",
    "Click-on item",
    "Cathedral display",
    "Object of esteem",
    "Religious figure",
    "Religious image",
    "Garbage pail, maybe",
    "PC picture",
    "AOL logo, e.g.",
    "Representation",
    "Windows picture",
    "Computer signpost",
    "Menu symbol",
    "It's clicked on a computer",
    "What a model might become",
    "Screen symbol",
    "Computer image",
    "It's clicked on a computer",
    "It stands for something",
    "Object of devotion",
    "Computer screen image",
    "Perfect representative",
    "Symbol",
    "Windows picture",
    "Screen symbol",
    "PC pic",
    "Token",
    "Representation",
    "\"User-friendly\" feature",
    "Sacred image",
    "Object of devotion",
    "Symbol",
    "Simulacrum",
    "PC directory feature",
    "Religious image",
    "Computer symbol",
    "Macintosh sign",
    "Macintosh screen symbol",
    "Computer symbol"
  ],
  "daft": [
    "Preposterous",
    "Addlebrained",
    "Cockamamie",
    "Mad",
    "Bats",
    "Harebrained",
    "Out to lunch",
    "Wacky",
    "Bonkers",
    "Bananas",
    "Touched",
    "Crazy",
    "Touched",
    "Cuckoo",
    "Batty",
    "Birdbrained",
    "Touched in the head",
    "Loco",
    "Nutty",
    "Nuts",
    "Not playing with a full deck",
    "Cuckoo",
    "Nutty",
    "Off one's rocker",
    "Crackers",
    "Harebrained",
    "Potty",
    "Bonkers",
    "Ditsy",
    "Senseless",
    "Batty",
    "Crackers",
    "Touched",
    "Potty",
    "Off one's trolley",
    "A bit cuckoo",
    "Not all there",
    "Bonkers",
    "Ditzy",
    "Off one's trolley",
    "Unbalanced",
    "Loony",
    "Off one's trolley",
    "Crackers",
    "Nutty",
    "Wacky",
    "Bonkers",
    "A penny short of a dime",
    "Crackers",
    "Unhinged"
  ],
  "equi": [
    "Lead-in to -vocal",
    "Prefix with angular or lateral",
    "Prefix with distant",
    "Prefix with poise",
    "Prefix with distant",
    "What can open laterally?",
    "Distant leader?",
    "Prefix with angular",
    "Prefix with lateral",
    "Prefix with lateral",
    "Prefix with distant",
    "Prefix with lateral",
    "Prefix with distant",
    "Prefix with distant",
    "Prefix with distant",
    "Prefix with distant",
    "Prefix with angular",
    "Prefix with lateral",
    "Prefix with lateral",
    "Prefix akin to iso-",
    "Prefix with lateral",
    "Prefix with lateral",
    "Prefix with angular",
    "Prefix with lateral",
    "Prefix with distant",
    "Relative of iso-",
    "Even start",
    "Prefix with lateral",
    "Prefix with distant",
    "Latin counterpart of \"iso-\"",
    "Lateral leader",
    "Lateral beginning",
    "Lateral lead-in"
  ],
  "lunn": [
    "Sally ___ (English teacake)",
    "Sally ___ (sweet bun)",
    "Sally ___ (teacake)",
    "Sally ___ (teacake)",
    "Sally ___ (kind of teacake)"
  ],
  "abet": [
    "Help wanteds?",
    "Help in wrongdoing",
    "Criminally aid",
    "Help out with a job",
    "Look out for, say",
    "Help with a heist",
    "Help with a holdup",
    "Help to hold up, say",
    "\"Not on ___!\"",
    "Aid in illegal activity",
    "Lend a hand when one shouldn't",
    "Lend a helping hand, in a bad way",
    "Help with a job",
    "Help badly",
    "Not on ___",
    "Help with a crime",
    "Act as a lookout for, say",
    "Help the offense?",
    "Partner of aid",
    "Assist in a crime",
    "Keep watch for, maybe",
    "Provide job support",
    "Aid and ___",
    "Back on the job?",
    "Provide cover for, say",
    "Aid in mischief",
    "Drive a getaway car for, say",
    "Collude with",
    "Help illegally",
    "Assist in crime",
    "Help badly?",
    "Help in a heist",
    "Assist with a heist, say",
    "Partner of aid",
    "Assist with a job",
    "Aid in a scam, e.g.",
    "Assist, as an outlaw",
    "Help complete a job",
    "Lend a dirty hand to",
    "Help in crime",
    "Help to harm",
    "Give an underhanded hand?",
    "Aid in a scam, e.g.",
    "Help illicitly",
    "Drive the getaway car for, say",
    "Look out for, say",
    "Assist with a job",
    "Aid and ___",
    "Aid's partner",
    "Case the place for, say",
    "Help in wrongdoing",
    "Help in crime",
    "Help pull off a crime",
    "Provide a gun for, maybe",
    "Look out for, say",
    "Get hush money from, for instance",
    "Help with a job",
    "Aid's partner",
    "\"Not on ___!\"",
    "Help in wrongdoing",
    "Give shelter, perhaps",
    "Aid and ___",
    "Help, wrongly",
    "Help out in a bad way",
    "Help in a heist, say",
    "Opposite of hinder",
    "Support when one shouldn't",
    "Help in a bad way",
    "Help in crime",
    "Look out for?",
    "Assist in evil",
    "Help with a prank",
    "Help in mischief",
    "Support in skullduggery",
    "Assist in shady doings",
    "Help illegally",
    "Aid in crime",
    "Help in wrongdoing",
    "Help with wrongdoing",
    "Be a lookout for, maybe",
    "Support",
    "Look out for, say",
    "Aid in wrongdoing",
    "Lend support to",
    "Help in a getaway, e.g.",
    "Help when one shouldn't",
    "Assist illegally",
    "Help in crime",
    "Aid's partner",
    "Help hold up",
    "Drive away, as a thief?",
    "Support, in a way",
    "Help in a heist",
    "Look out for?",
    "Aid and ___",
    "Help in a heist",
    "Aid's partner",
    "Assist in crime",
    "Aid in crime",
    "Assist in wrongdoing",
    "Aid and ___",
    "Help in crime",
    "Aid by being a lookout, e.g.",
    "Opposite of hinder",
    "Play lookout for, for example",
    "Assist in wrongdoing",
    "Assist in crime",
    "Aid in crime",
    "Help in piracy, e.g.",
    "Assist in crime",
    "Aid and ___",
    "Aid's partner",
    "Help in crime",
    "Encourage, in a way",
    "\"Not on ___!\"",
    "Aid, in a way",
    "Be a lookout for, say",
    "Support, in a way",
    "Aid in crime",
    "Support when one shouldn't",
    "Help in a heist",
    "Assist, in a way",
    "\"Not on ___\"",
    "Assist in high jinks",
    "Help in crime",
    "Help at a holdup",
    "Act as a shill for, e.g.",
    "Help",
    "Help in ways one shouldn't",
    "Help in a holdup",
    "Help",
    "\"Not on ___!\" (\"No way!\")",
    "Aid's partner",
    "Help in a heist",
    "\"Not on ___!\"",
    "Help in crime",
    "Help in a heist",
    "Act as lookout, say",
    "Help, as a prankster",
    "Aid in crime",
    "Help, as a lawbreaker",
    "Aid in a felony",
    "Be an accessory to",
    "Aid's partner",
    "Help in a holdup",
    "Help in holding up",
    "Help in crime",
    "Opposite of hinder",
    "Advance the cause of",
    "Assist, in a way",
    "Help a hoodlum",
    "Give relief to a thief",
    "Aid's partner",
    "Be an accessory to",
    "\"Not on ___!\"",
    "Aid in crime",
    "Help with the heist",
    "Help a hood",
    "Help at a heist",
    "Provide with a hideout, perhaps",
    "Look out for, maybe",
    "Assist illegally",
    "Not on ___",
    "Help at a heist",
    "Help in a heist",
    "\"Not on ___!\" (\"No way!\")",
    "Help",
    "Be a partner in crime",
    "Assist in crime",
    "Help in a heist",
    "Assist in malfeasance",
    "Help at a heist",
    "Drive the getaway car, perhaps",
    "Aid's partner",
    "Act as lookout, e.g.",
    "Assist",
    "Help a crook",
    "Play the henchman",
    "Sanction misdeeds",
    "Be an accessory to the crime",
    "Help in crime",
    "Help in the holdup",
    "Help in the getaway",
    "Be a party to",
    "Aid and ___",
    "Aid and ___",
    "Help in mischief",
    "Help in crime",
    "Egg on",
    "Help a crook",
    "Help",
    "Act as a lookout",
    "Help",
    "Incite",
    "Drive the get-away car, maybe",
    "Assist a prankster"
  ],
  "ymca": [
    "Org. with gym memberships",
    "Many a gym locale, for short",
    "Community facility that often has a gym and pool, in brief",
    "Village People hit with a spelled-out title",
    "Community gym org.",
    "\"It's fun to stay at the ___\" (1978 hit lyric)",
    "Org. offering athletic memberships",
    "Often-pantomimed hit song of the 1970s",
    "Village People hit with a spelled-out title",
    "Gay anthem of 1978",
    "Family rec center",
    "\"Spirit, mind and body\" org.",
    "Workout site, for short",
    "Where \"you can hang out with all the boys,\" in song",
    "Facility often referred to by its first letter",
    "Song that was a hit for a spell in the 1970s?",
    "Exercise venue, for short",
    "Neighborhood org. since 1844",
    "Hit from the 1978 disco album \"Cruisin'\"",
    "Geneva-based org. encouraging healthy living",
    "Where \"it's fun to stay\" in a 1978 hit",
    "Many a gym locale",
    "Where there are \"many ways to have a good time,\" in a 1978 hit",
    "Family rec facility",
    "\"It's fun to stay at the ___\": Village People",
    "\"A place you can go,\" in a 1979 #2 hit",
    "Village People hit whose title completes the line \"It's fun to stay at the ...\"",
    "Place to play b-ball",
    "Gym locale, for short",
    "Where \"you can do whatever you feel,\" in a hit 1978 song",
    "Workout locale, for short",
    "1978 disco hit",
    "Org. that organizes camps",
    "1978 hit with the lyric \"You can get yourself clean, you can have a good meal\"",
    "Community ctr.",
    "Urban athlete's locale, in brief",
    "1978 Village People hit",
    "\"A place you can go ?à when you're short on your dough,\" in a 1979 hit",
    "Gym site, for short",
    "Place to work out",
    "Pool site, for short",
    "Pool site, maybe",
    "Swimming pool site, for short",
    "Pool provider, often",
    "Sir George Williams's org.",
    "Gym site",
    "Gym site",
    "Disco standard",
    "Pool site, maybe",
    "Community gym site",
    "No. 2 hit of 1978-79",
    "Gym site",
    "1979 disco classic",
    "Community org. with a gym",
    "Disco perennial",
    "Place to play b-ball",
    "Where basketball and volleyball were first played",
    "1978 Village People hit",
    "Gym site",
    "\"It's fun to stay at the___\" (1978 song lyric)",
    "Community org.",
    "1979 disco hit",
    "Community athletic events sponsor: Abbr.",
    "1978 Village People hit",
    "1979 Village People hit"
  ],
  "flea": [
    "Kind of market",
    "Target of products from Bio-Groom and Wondercide",
    "Hartz collar target",
    "Small jumper",
    "Dog biter",
    "Lab problem",
    "___ collar",
    "Back biter, maybe",
    "Flying circus performer?",
    "One having a small bite?",
    "Bother for Bowser",
    "Pet pest",
    "Pit bull biter",
    "Pet pest",
    "Pet's teensy tormentor",
    "One going for a little bite?",
    "Cat nipper?",
    "Subject of a Spot check?",
    "One in the doghouse?",
    "One nipping Nipper, maybe",
    "It might avoid a collar",
    "Pet peeve?",
    "Small part of a pound?",
    "Kind of collar",
    "Leaping insect",
    "It can get under your skin",
    "It may be in the doghouse",
    "Tiny annoyance",
    "One jumping in a doghouse?",
    "Boxer's annoyance",
    "Tiny jumper",
    "Little irritant",
    "Formicide: ant :: pulicide : ___",
    "Pet's tiny tormentor",
    "Host seeker",
    "Small circus performer",
    "One going to the dogs?",
    "Circus performer",
    "Setter settler",
    "Target of some collars",
    "Pet's pest",
    "Pet peeve?",
    "Typhus carrier",
    "Cause of an itch",
    "Boxer's problem",
    "Hopper",
    "Kind of market or circus",
    "Dog biter",
    "Bother for a boxer",
    "Dog botherer",
    "Kind of market",
    "Kind of bag",
    "Lover of fur?",
    "Kind of collar",
    "Circus participant",
    "Dog hounder",
    "Kind of collar",
    "Dog biter",
    "Dog's annoyance",
    "Dog's bane",
    "Dog dogger",
    "Doggone pest?"
  ],
  "lima": [
    "Home to the oldest continuously functioning university in the Americas (since 1551)",
    "World capital established in 1535",
    "Home of the oldest university in the Americas (founded 1551)",
    "Bean type",
    "Bean town?",
    "Andean capital",
    "Peru's capital",
    "Capital of Peru",
    "World capital whose name is a kind of bean",
    "2019 Pan American Games site",
    "Certain bean",
    "Peru's capital",
    "Fourth-largest city in the Americas",
    "City founded by Pizarro in 1535",
    "Peru's capital",
    "Certain bean",
    "City of the Kings",
    "Succotash bean",
    "Succotash bit",
    "City where TV's \"Glee\" is set",
    "Site of the oldest university in South America",
    "Peru's largest city",
    "Vegetable on a vine",
    "Peru's capital",
    "Capital on the Rimac River",
    "Bean town?",
    "Bean town?",
    "___ bean",
    "Andean capital",
    "Home of the oldest university in the continental Americas",
    "South American capital",
    "___ bean",
    "Capital near the ruins of the ancient city Pachacamac",
    "City founded by Pizarro",
    "Capital near the port of Callao",
    "Peru's capital",
    "Capital south of Quito",
    "Bean town?",
    "Andes capital",
    "Soup bean",
    "Bean town?",
    "Baby ___ (tender vegetable)",
    "City founded by Pizarro",
    "Stew bean",
    "Capital of Peru",
    "Peru's capital",
    "South American capital",
    "City founded by Pizarro",
    "Pizarro founded it",
    "Capital near Callao",
    "Peru's capital",
    "Ciudad de los Reyes",
    "Vegetable soup bean",
    "Stew bean",
    "Andes capital",
    "Peru's capital",
    "Peru's capital",
    "Stew bean",
    "Bean town?",
    "Peru's capital",
    "Succotash ingredient",
    "City north of Dayton",
    "Pizarro's capital",
    "Soup bean",
    "Succotash bean",
    "Peruvian or Ohioan city",
    "Peru's capital",
    "Ohio city",
    "Home of the oldest university in the Americas",
    "Peru's capital",
    "Peru's capital",
    "South American capital",
    "\"City of Kings\""
  ],
  "limo": [
    "Stretch of the red carpet?",
    "Long ride?",
    "Hard vehicle to park",
    "Premiere arrival",
    "Wedding rental",
    "Many a prom night rental",
    "A long way to go?",
    "Wheels of fortune?",
    "Transport to a red carpet",
    "Sight at a red-carpet event",
    "Something that's long and steep?",
    "Prom night rental",
    "Member of a motorcade",
    "Ride to an awards show",
    "Long way to go?",
    "Extended rental?",
    "Luxury rental",
    "Promgoer's ride, maybe",
    "Long ride",
    "Car that may have a bar",
    "Wheels of fortune?",
    "Exec's car, maybe",
    "Bride's ride",
    "Long way to go?",
    "Bar car?",
    "\"Stretch\" car",
    "Chauffeur-driven auto",
    "Long wheels",
    "Promgoers' car",
    "It might roll up to a red carpet",
    "\"Stretch\" car, in brief",
    "Prom rental",
    "V.I.P.'s transport",
    "Stretch ___",
    "V.I.P.'s vehicle",
    "Fancy wheels",
    "Prom night transportation",
    "Wedding rental",
    "Door-to-door delivery service?",
    "Brass wheels?",
    "Car that comes with a driver",
    "It's short for a long car",
    "V.I.P.'s ride",
    "Prom night transportation",
    "Car for a star",
    "Lincoln, maybe",
    "Stretch for the stars?",
    "Long car, for short",
    "Chauffeur-driven vehicle",
    "Bar locale",
    "Celeb's way around town",
    "Airport pickup",
    "Prom night wheels",
    "Tinted windows locale",
    "Celebrity's wheels",
    "Prom night tradition",
    "Car with a bar, perhaps",
    "Bride and groom's vehicle",
    "Star vehicle?",
    "Big partygoer?",
    "Wheels of fortune?",
    "Long ride?",
    "Oscar night transport",
    "Prom night transportation",
    "Chauffeur's spot",
    "Prom night rental",
    "C.E.O.'s convenience",
    "Wedding day sight",
    "Minibar site",
    "Transportation to the 5-Down",
    "Car with a bar",
    "Prom night transport",
    "Fancy wheels",
    "Car with a bar",
    "V.I.P. vehicle",
    "Prom rental",
    "Car with a bar",
    "Star vehicle",
    "It can move a star",
    "Wheels of fortune?",
    "Wheels on Oscar night",
    "Prom night rental",
    "Wheels for wheels",
    "Luxury wheels",
    "At times, it's a stretch",
    "Fancy wheels",
    "Prom night transport",
    "It can be a stretch"
  ],
  "lady": [
    "\"___ Bird\" (2017 film)",
    "Courtly title",
    "Greta Gerwig's \"___ Bird\"",
    "___ Luck",
    "___ Godiva",
    "Word in many Catholic church names",
    "___ Gaga",
    "With 70-Across, nickname for a celebrated performer born on April 25, 1917",
    "Disney toon often pictured eating spaghetti",
    "\"Downton Abbey\" title",
    "Word after leading or cleaning",
    "Certain noble",
    "Lord's partner",
    "\"Downton Abbey\" title",
    "Gentleman's partner",
    "Disney dog",
    "Margaret Thatcher, e.g.",
    "Tramp's partner in a Disney film",
    "See 5-Across",
    "Gentleman's partner",
    "___ Gaga",
    "Disney dog",
    "Gent's partner",
    "Tramp's love",
    "One may be in waiting",
    "Animated film pooch",
    "Title cocker spaniel in a Disney film",
    "See 1-Across",
    "Curtsier",
    "___ Day, March 25",
    "Disney dog",
    "The Beatles' Madonna, e.g.",
    "Madame",
    "Disney dog",
    "The Beatles' \"___ Madonna\"",
    "1955 Disney title character",
    "Lord's mate",
    "Lord's mate",
    "Tramp's partner",
    "Madam",
    "Dance partner",
    "___ Luck",
    "Disney dog",
    "More than a miss",
    "Luck, to some",
    "Kenny Rogers #1 song",
    "\"That was no___. That...\"",
    "1980 Kenny Rogers hit",
    "Disney dog",
    "1955 Disney dog",
    "\"The ___ doth protest...\""
  ],
  "rhea": [
    "Titaness of myth",
    "Mother of Zeus (and an anagram of 15-Across)",
    "Actress Seehorn of \"Better Call Saul\"",
    "Largest South American bird",
    "Mother of Zeus",
    "Perlman of \"Cheers\"",
    "Second-largest moon of Saturn",
    "Moon of Saturn named for a Titaness",
    "Greek \"Mother of the Gods\"",
    "Relative of an ostrich",
    "Relative of the emu",
    "Cousin of an emu",
    "Second-largest moon of Saturn",
    "Actress Perlman of \"Cheers\"",
    "Sister and wife of Cronus, in myth",
    "Perlman of \"Cheers\"",
    "Bird named for a Titan",
    "Large bird of the pampas",
    "Actress Perlman of \"Cheers\"",
    "Grandmother of 14-Across",
    "Titaness in Greek myth",
    "Bird named for a Titaness",
    "Sister and wife of Cronus",
    "Perlman of \"Cheers\"",
    "Flightless South American bird",
    "Sister of Cronus",
    "Moon of Saturn",
    "Actress Seehorn of \"Better Call Saul\"",
    "Emmy winner Perlman",
    "Cousin of an ostrich",
    "Second-largest moon of Saturn",
    "Ostrichlike bird",
    "Mother of Zeus",
    "Moon of Saturn",
    "Perlman of \"Cheers\"",
    "Flightless bird",
    "Second-largest moon of Saturn",
    "Flightless bird",
    "Sister of Phoebe, in myth",
    "\"Cheers\" actress Perlman",
    "Saturn's second-largest moon",
    "Ostrich's cousin",
    "Bird with a mythological name",
    "Flightless bird of South America",
    "Cousin of an ostrich",
    "___ Silvia, mother of Romulus and Remus",
    "She hid Zeus from Cronus",
    "Funny Caroline",
    "Cousin of an emu",
    "Perlman of \"Cheers\"",
    "Mother of the Gods",
    "Relative of an ostrich",
    "Flightless bird",
    "Largest bird in the Americas",
    "Perlman of \"Cheers\"",
    "Cousin of a cassowary",
    "Mother of Hades",
    "Stand-up comedian Caroline",
    "Perlman of \"Cheers\"",
    "Perlman of \"Cheers\"",
    "Relative of an ostrich",
    "Daughter of Uranus",
    "Hera's mother",
    "Mother of Hades",
    "Perlman of \"Cheers\"",
    "Perlman of \"Cheers\"",
    "Perlman of \"Cheers\"",
    "Cassowary?Æs cousin",
    "Mother of Zeus",
    "Perlman of \"Cheers\"",
    "Hera's mother",
    "Perlman of \"Cheers\"",
    "Demeter's mother",
    "Actress Perlman",
    "Bird whose male hatches the eggs",
    "Mother of Zeus",
    "Perlman of \"Cheers\"",
    "Moon of Saturn",
    "Saturn satellite",
    "Ostrichlike bird",
    "Perlman of \"Cheers\"",
    "Perlman of \"Cheers\"",
    "Goddess whose children were swallowed by Cronus",
    "Mother of Hades",
    "Three-toed bird",
    "Emmy winner Perlman",
    "Mother of 67-Across",
    "Mother of Hades",
    "Cassowary's cousin",
    "Mother of Hera",
    "Three-toed bird",
    "Actress Perlman",
    "Hera's mother",
    "Consort of Cronus",
    "Perlman of \"Cheers\"",
    "Mother of Poseidon",
    "Mother of Hera",
    "Actress Perlman",
    "Ostrich cousin",
    "Moon of Saturn",
    "Pearl player, in a 1996 sitcom",
    "Perlman of \"Pearl\"",
    "Mother of Hera and Zeus",
    "Mother of Zeus",
    "Flightless bird",
    "Wife of Cronus",
    "Perlman of \"Cheers\"",
    "Mother of Zeus",
    "Poseidon's mother",
    "Cousin of the emu",
    "Perlman of \"Cheers\""
  ],
  "saks": [
    "Store near Rockefeller Center, familiarly",
    "Noted retailer across the street from Rockefeller Center",
    "Fifth Avenue concern",
    "Noted Fifth Avenue emporium",
    "Nordstrom rival",
    "Nordstrom competitor",
    "Fifth Avenue retailer",
    "Lord & Taylor rival, informally",
    "Nordstrom rival",
    "Fifth Avenue retailer",
    "Nordstrom rival",
    "Fifth Avenue retailer",
    "Store whose shoe department has its own ZIP code (10022-SHOE)",
    "Barneys rival",
    "Noted Fifth Avenue emporium",
    "Neiman Marcus competitor",
    "Fifth Avenue landmark",
    "Competitor of Bloomie's",
    "Bergdorf competitor",
    "Retailer beginning in 1867",
    "\"The Odd Couple\" director",
    "Gimbel contemporary",
    "\"The Odd Couple\" director",
    "\"Mame\" director of stage and screen",
    "Rival of Neiman's",
    "Bloomingdale's rival",
    "Store originally in Herald Square",
    "\"The Odd Couple\" director, 1968",
    "Competitor of Bloomingdale's",
    "Nordstrom rival",
    "Fifth Avenue retailer",
    "Nordstrom rival",
    "___ Fifth Avenue",
    "New York merchant Horace",
    "Fashionable store since 1902",
    "\"The Odd Couple\" director",
    "Nordstrom competitor",
    "Competitor of Bloomies",
    "Fifth Avenue name",
    "\"Mame\" director Gene",
    "Fifth Avenue store",
    "Noted name in retailing",
    "Fifth Avenue name",
    "Fifth Avenue outfit"
  ],
  "leos": [
    "Some summer deliveries",
    "Some summer babies",
    "Births between Cancer and Virgo",
    "Proud, fiery types, they say",
    "Many August births",
    "They may be conceived around Halloween",
    "Action-oriented sorts, supposedly",
    "Many August births",
    "Some summer babies",
    "Some August newborns",
    "13 religious heads",
    "Some summer births",
    "Ones born before Virgos",
    "Affectionate, fiery types, supposedly",
    "Composer Janacek",
    "13 popes, so far",
    "Many August babies",
    "Independent, noble types, it's said",
    "Ones sharing Durocher's astrological sign",
    "Some July arrivals",
    "Bill Clinton and Arnold Schwarzenegger, to the stars?",
    "A majority of August births",
    "Many August babies",
    "Czech composer Janacek",
    "Late July babies",
    "13 popes, so far",
    "Some summer babies",
    "Many August births",
    "Thirteen popes",
    "13 pontiffs",
    "Control-driven people, supposedly",
    "Fire sign people",
    "Composer Janacek",
    "Composer Janacek",
    "Most people born in August",
    "Thirteen popes",
    "Ones born in late July",
    "Czech composer Janacek",
    "13 popes",
    "Most August births",
    "13 popes",
    "Composer Janacek",
    "Composer Janacek",
    "Proud, energetic, domineering ones, they say",
    "McKern and Carroll"
  ],
  "ibid": [
    "Footnote abbr.",
    "Footnote abbr.",
    "Ditto, in scholarly journals",
    "[Same source as before]",
    "Footnote abbr.",
    "Abbr. in a footnote",
    "[See above]",
    "Footnote abbr.",
    "Reference notation",
    "Citation abbr.",
    "Term paper abbr.",
    "Footnote abbr.",
    "Footnote abbr.",
    "Scholar's reference abbr.",
    "Sourcing abbr.",
    "Footnote abbr.",
    "Bibliog. equivalent of \"ditto\"",
    "Footnote abbr.",
    "Abbr. after an author's name, maybe",
    "Footnote abbr.",
    "Footnote abbr.",
    "Footnote abbr.",
    "Bibliographical abbr.",
    "Footnote abbr.",
    "Bridge words",
    "Footnote abbr.",
    "Footnote abbr.",
    "Footnote abbr.",
    "Bibliographic abbr.",
    "Footnote abbr.",
    "Referential abbr.",
    "Term paper abbr.",
    "Footnote abbr.",
    "Footnote abbr.",
    "Start of a bridge declaration",
    "Footnote note",
    "Footnote abbr.",
    "Footnote abbr.",
    "Kin of op. cit.",
    "Footnote abbr.",
    "Footnote abbr.",
    "As before, in footnotes",
    "Footnote abbr.",
    "Footnote note",
    "Footnote abbr.",
    "\"How much am ___?\" (auction query)",
    "Footnote abbr.",
    "Footnote note"
  ],
  "pare": [
    "Whittle (down)",
    "Shave",
    "Trim",
    "Trim",
    "Trim",
    "Remove the peel from",
    "Trim, with \"down\"",
    "Trim",
    "Cut (down)",
    "Peel",
    "Decorticate",
    "Lessen, as expenses",
    "Cut back",
    "Whittle down",
    "Trim, as costs",
    "Cut back on, as expenses",
    "Edit, in a way",
    "Remove, as a potato peel",
    "Whittle down",
    "Take a coat off",
    "Cut back",
    "Whittle",
    "Trim, with \"down\"",
    "Trim",
    "Whittle (down)",
    "Shave",
    "Shave",
    "Skin",
    "Peel",
    "Whittle down",
    "Skin",
    "Cut, as expenses",
    "Peel",
    "Whittle",
    "Peel",
    "Peel",
    "Whittle down",
    "Whittle (down)",
    "Cut back",
    "Trim",
    "Trim",
    "Cut back",
    "Cut back",
    "Shave",
    "Whittle",
    "Peel",
    "Cut back",
    "Prune",
    "Skin",
    "Reduce, as expenses",
    "Shave",
    "Reduce, as expenses",
    "Excoriate",
    "Trim",
    "Whittle (down)",
    "Lessen, with \"down\"",
    "Whittle down",
    "Whittle",
    "Scale down",
    "Peel",
    "Downsize",
    "Whittle",
    "Cut back",
    "Cut back",
    "Trim",
    "Curtail",
    "Scale down",
    "Trim",
    "Skin",
    "Peel",
    "Reduce, as expenses",
    "Shave",
    "Peel, as an apple",
    "Peel",
    "Trim",
    "Peel",
    "Reduce",
    "Prune",
    "Trim"
  ],
  "stay": [
    "Directive before \"awake\" or \"woke\"",
    "Obedience school command",
    "Linger",
    "Command that one shouldn't follow",
    "Common dog command",
    "Legal postponement",
    "Not go anywhere",
    "One week, for many beach house rentals",
    "Opposite of \"heel\"",
    "Don't go",
    "Pause in a legal process",
    "Canine command usually spoken slowly in a low voice",
    "Hotel visit",
    "Obedience school command",
    "\"Don't go!\"",
    "Collar stiffener",
    "Bit of training accompanying \"Sit!\" and \"Speak!\"",
    "Time at a hotel",
    "\"Don't go!\"",
    "Legal pause",
    "Not fly the coop",
    "Time at a hotel",
    "Neither raise nor fold, in poker",
    "Not split",
    "Canine command",
    "\"___ With Me\" (Sam Smith hit)",
    "Command that a dog shouldn't follow",
    "Not skedaddle",
    "Hang around",
    "\"Don't leave me!\"",
    "Something booked on Travelocity",
    "Blackjack choice",
    "Certain court order",
    "Certain court order",
    "Canine command",
    "\"Don't go anywhere\"",
    "Dog command",
    "Neither raise nor fold",
    "Remain",
    "\"Don't move, Bowser!\"",
    "Lab directive?",
    "Lab order",
    "Hang around",
    "Judge's issuance",
    "\"Don't leave!\"",
    "Spend the night",
    "Not be off",
    "Not go",
    "Judge's order",
    "\"Don't go!\"",
    "Dog command",
    "With 40-Across, coloring advice ... and literally so",
    "Canine command",
    "Stick around",
    "Canine order",
    "Hotel visit",
    "Supporting piece",
    "Command to Fido",
    "Legal suspension",
    "Dog command",
    "A judge might issue one",
    "Hang around",
    "Not split",
    "Deferral",
    "Command for Rover",
    "Order to Fido",
    "Hang around",
    "Command to Fido",
    "Dog command",
    "Dog command",
    "Command to a dog",
    "\"Don't go!\"",
    "Dog command",
    "Corset stiffener",
    "Hang in there",
    "\"Don't leave!\"",
    "Command to Fido",
    "Dog command",
    "Don't dash off",
    "Collar support",
    "Command to Fido",
    "Don't take off",
    "Not leave",
    "Host's request",
    "\"Don't move!\"",
    "\"Don't go!\"",
    "Hang out",
    "Nautical guy",
    "\"Sit\" follower",
    "Reinforcement",
    "Postponement",
    "Mast support",
    "Word to 17-Across",
    "Deferral",
    "Quote, part 6",
    "Command to a boxer",
    "Sojourn",
    "Corset part",
    "Corset part",
    "Command to Fido",
    "Admonition to Fido",
    "Supporting member",
    "Gallows reprieve",
    "Corset prop",
    "Abide",
    "\"Don't move!\"",
    "Blackjack option",
    "Command to Rover",
    "Judge's order",
    "Command to Fido",
    "Corset part",
    "Command to Spot",
    "Postponement",
    "Arrest of action",
    "Gallows reprieve",
    "Canine command",
    "Judge's order",
    "Command to Fido",
    "Collar stiffener",
    "Judge's order",
    "Corset feature",
    "Command to a dog",
    "Legal delay",
    "Gallows reprieve",
    "Layover",
    "Dog command",
    "Mast-steadying rope",
    "Stick around"
  ],
  "omni": [
    "Luxury hotel chain",
    "Prefix with present",
    "Discontinued Dodge",
    "Hilton alternative",
    "Prefix with potent",
    "Hilton competitor",
    "Prefix with potent",
    "Lead-in to -bus",
    "Prefix with -vore",
    "Hyatt alternative",
    "Prefix with -vore or -scient",
    "Luxury hotel brand",
    "Hyatt alternative",
    "Prefix with potent or present",
    "Prefix with directional",
    "Old Dodge",
    "Marriott competitor",
    "Hilton alternative",
    "Upscale hotel chain",
    "Prefix with present",
    "Westin competitor",
    "Lead-in to science",
    "Hilton alternative",
    "Upscale hotel chain",
    "All in front?",
    "Upscale hotel chain",
    "Upscale hotel chain",
    "Introduction to science?",
    "Marriott competitor",
    "Prefix with directional",
    "Marriott competitor",
    "Luxury hotel chain",
    "Former Dodge",
    "Upscale hotel chain",
    "Marriott competitor",
    "All: Prefix",
    "Upscale hotel chain",
    "Marriott competitor",
    "Hilton alternative",
    "Prefix with potent",
    "Upscale hotel chain",
    "1970s-'80s Dodge",
    "[Hotels]",
    "Upscale chain hotel",
    "All at the start?",
    "Prefix with directional",
    "Prefix with science",
    "Luxury hotel chain",
    "Hyatt alternative",
    "Bygone Dodge",
    "Wyndham alternative",
    "One of the books in the Book of Mormon",
    "Upscale hotel company",
    "Marriott alternative",
    "Prefix with present",
    "Prefix with present",
    "Luxury hotel name",
    "Prefix with potent",
    "Prefix with present",
    "Onetime Dodge",
    "Prefix with directional",
    "Hawks' old haunt",
    "Old Dodge",
    "Old Dodge model",
    "Big name in luxury hotels",
    "All at the front?",
    "Prefix with potent or present",
    "Hyatt alternative",
    "Present opener?",
    "Westin alternative",
    "All: Prefix",
    "Bygone science/sci-fi magazine",
    "It means everything",
    "Bygone Dodge",
    "Big name in hotels",
    "Onetime science magazine",
    "Prefix with directional",
    "Prefix with potent",
    "1970s-'80s Dodge",
    "Present opener?",
    "Old Dodge",
    "Hyatt alternative",
    "Prefix with present",
    "Former Dodge",
    "Bygone Dodge",
    "Magazine founded by Bob Guccione",
    "It means everything",
    "Book of Mormon book",
    "First Dodge with front-wheel drive",
    "Prefix with potent",
    "Directional lead-in",
    "Present opener?",
    "Prefix with potent",
    "___ Hotels (luxury chain)",
    "Old Dodge",
    "Bygone Dodge",
    "Prefix with potent",
    "Prefix with potent",
    "Upscale hotel chain",
    "Prefix with potent",
    "Hotel chain",
    "Popular hotel chain",
    "Old Dodge",
    "Old Dodge",
    "Present opener?",
    "Magazine that had a \"UFO Update\"",
    "Directional lead-in",
    "Prefix with science",
    "Prefix with potent",
    "Book in the Book of Mormon",
    "Magazine founded by Bob Guccione",
    "Bygone science magazine",
    "Old Dodge model",
    "Prefix with potent",
    "Present opener?",
    "It means everything",
    "Old Dodge",
    "Present opener?",
    "Onetime sister magazine of Penthouse",
    "Bygone science magazine",
    "Introduction to science?",
    "Old Dodge",
    "Prefix with potent",
    "Onetime Atlanta arena",
    "Old Dodge",
    "Old Dodge",
    "All: Prefix",
    "Where the Hawks used to play",
    "Prefix meaning 27-Across",
    "It means everything",
    "Present prefix",
    "Former Atlanta arena",
    "Hawks' former home",
    "___ Hotels (luxury chain)",
    "Old Dodge",
    "Potent leader?",
    "Prefix with directional",
    "Universally: Prefix",
    "Prefix with potent",
    "Prefix with present",
    "Prefix with potent",
    "Old Dodge",
    "Hawks' former arena",
    "All, to start with",
    "Former Atlanta arena",
    "Prefix with science",
    "Home of the Hawks, with \"the\"",
    "Old Dodge",
    "Prefix meaning 56-Across",
    "Old Dodge",
    "N.B.A. venue, with \"the\"",
    "Atlanta arena, with \"the\"",
    "Old Dodge",
    "Hawks' home court, with \"The\"",
    "Hawks' arena",
    "Discontinued Dodge",
    "Hotel chain name",
    "Atlanta arena, with \"The\"",
    "Hawks' arena, the \"The\"",
    "Science magazine",
    "Science magazine",
    "Discontinued Dodge",
    "Prefix with science",
    "Atlanta sports site, with \"The\"",
    "Former Dodge model",
    "Popular science magazine",
    "Popular science magazine",
    "Atlanta Hawks arena, with \"The\"",
    "Bus starter",
    "Potent leader"
  ],
  "bert": [
    "Muppet with a unibrow",
    "Muppet with a big orange nose",
    "Ernie's partner on TV",
    "Muppet with a unibrow",
    "Muppet who sings \"I Refuse to Sing Along\"",
    "Muppet with a unibrow",
    "Q*___ (1980s arcade game)",
    "Ernie's \"Sesame Street\" buddy",
    "Pitcher Blyleven with 3,701 strikeouts",
    "Cockney pal of Mary Poppins",
    "\"Sesame Street\" character with a unibrow",
    "One of a pair of fictional roommates",
    "Kids?Æ TV character with a thick unibrow",
    "Q*___ (vintage video game)",
    "Lahr who played the Cowardly Lion",
    "Ernie's Muppet pal",
    "___ Parks, former \"Miss America\" host",
    "Puppet of children's TV",
    "\"Mary Poppins\" fellow",
    "A Muppet",
    "Ernie's TV pal",
    "Ernie's \"Sesame Street\" pal",
    "\"Sesame Street\" name",
    "?ôSesame Street?ö regular",
    "Lahr of \"The Wizard of Oz\"",
    "Ernie's \"Sesame Street\" pal",
    "Former Miss America host Parks",
    "One of the Bobbsey twins",
    "Lahr or Parks",
    "A Sesame Streeter",
    "Oscar: Felix:: Ernie: ___",
    "Friend of Ernie",
    "Emcee Parks"
  ],
  "ontv": [
    "See 31-Across",
    "Airing",
    "Where \"Star Trek\" and \"Mission: Impossible\" originated",
    "Airing",
    "Being broadcast",
    "Airing",
    "\"As seen ___\"",
    "Airing, in a way",
    "Playing",
    "Where reruns run",
    "Airing",
    "Airing",
    "Airing",
    "Airing",
    "Airing",
    "Widely seen",
    "\"As seen ___\"",
    "Airing",
    "Airing",
    "\"As advertised ___!\"",
    "How much advertising is done",
    "Airing",
    "How many a product is advertised",
    "Where to see \"E.R.\" or \"Ellen\"",
    "Airing",
    "Airing"
  ],
  "grad": [
    "June celebrant, informally",
    "___ program",
    "___ school",
    "One temporarily donning a cap",
    "Gown wearer, informally",
    "Many a May or June honoree",
    "___ school",
    "May or June gown wearer",
    "Commencement celebrant, in brief",
    "One coming to homecoming, maybe",
    "___ school",
    "June celebrant, for short",
    "Alum",
    "One finally done with finals?",
    "___ school",
    "Ending with Lenin or Stalin",
    "'13 or '14, now",
    "Mortarboard tosser",
    "Mortarboard tosser",
    "Tassel sporter",
    "Sheepskin holder",
    "Former Lenin adherent?",
    "Diploma holder",
    "Many a 21-Down celebrant",
    "Mortarboard tosser",
    "Cap and gown wearer",
    "Alum",
    "Diploma holder",
    "Alum",
    "Last year's sr.",
    "June celebrant",
    "Alum",
    "Alum",
    "Alum",
    "Kind of student",
    "Alum",
    "Sheepskin holder",
    "Mortarboard wearer",
    "Alum",
    "Kind of student",
    "June honoree, for short",
    "Alum"
  ],
  "puss": [
    "\"___ in Boots\" (fairy tale)",
    "Mug",
    "Mug",
    "Face, slangily",
    "Kisser",
    "Face, slangily",
    "Mug",
    "Kisser",
    "Face, slangily",
    "Kisser",
    "Cat",
    "Mug",
    "Kitty"
  ],
  "oxen": [
    "Cart pullers",
    "Animals in a yoke",
    "Some travelers along the Oregon Trail",
    "They work as a team",
    "Team members that can pull more than their weight on the field?",
    "Team pulling a plow",
    "Yoked pair in a field",
    "Farm team",
    "Big farm workers",
    "Yoked animals",
    "Plow pullers",
    "Strong team",
    "Creatures captured in Hercules' 10th labor",
    "Draft picks?",
    "Headstrong animals",
    "Ones with a lot of pull in the agricultural world?",
    "Draft picks?",
    "The yoke's on them",
    "Animals in a yoke",
    "Animals in a yoke",
    "Plural animal name that does not end in \"-s\"",
    "Wagon pullers",
    "Field team",
    "Yoked animals",
    "Plow beasts",
    "The yoke's on them",
    "Symbols of strength",
    "Pullers in pairs",
    "Ones with a lot of pull?",
    "Plow animals",
    "\"Olly olly ___ free\"",
    "Plow pullers",
    "Draft team",
    "Team members",
    "Plow-pulling pair",
    "Drawing pair, perhaps",
    "Symbols of strength",
    "The yoke's on them",
    "Yoked pair",
    "Draft team",
    "Plow team",
    "They're drafted for service",
    "Plow pullers",
    "Pair with a plow",
    "Beasts in a span",
    "Paul Bunyan's Babe and others",
    "Yoked pair",
    "Some team members",
    "Pullers in pairs",
    "Cart-pulling beasts",
    "Plow team",
    "Team components",
    "Farm team",
    "Animals that might hear \"gee\" and \"haw\"",
    "Plow team",
    "Plow team",
    "Yak pack",
    "Teamed beasts",
    "Workers in the field",
    "They've got a lot of pull",
    "Big pullers",
    "Plow pullers",
    "Symbols of strength",
    "Plow pullers",
    "Plow pullers",
    "Plow pullers",
    "Strong team",
    "Animals in harness",
    "Plowers",
    "Yoked beasts",
    "Yoke wearers",
    "Plow pullers",
    "Draft choices?",
    "Field team",
    "Coachmen : horses :: bullwhackers : ___",
    "Plow team",
    "Yaks",
    "Yoked team",
    "Members of a span",
    "Farm team",
    "Alternatives to mules",
    "Yokemates",
    "Strong team",
    "The yoke's on them",
    "Chinese calendar animals",
    "Yoked beasts",
    "Babe and others",
    "They can take a yoke",
    "What Hercules captured from Geryon",
    "Farm team",
    "Yoked pair",
    "Yoked beasts",
    "Draft team",
    "Big bovines",
    "The yoke's on them",
    "Farm team",
    "Team members",
    "Draft picks?",
    "Plow pullers",
    "Big bovines",
    "Beasts in a span",
    "The yoke's on them",
    "They have pull",
    "Teammates",
    "Ones with pull?",
    "Span pair",
    "Team components",
    "Team mates?",
    "Big bovines",
    "The yoke's on them",
    "Conestoga haulers",
    "Farm team",
    "Pre-tractor farmer's need",
    "Plow animals",
    "Epitome of 41-Across",
    "Farm team",
    "They can take a yoke",
    "Plow pullers",
    "Farm team",
    "The yoke's on them",
    "Team members",
    "Yoke wearers",
    "They can take a yoke",
    "The yoke's on them",
    "Yaks",
    "They have pull",
    "Plow animals",
    "Cart pullers",
    "Ex-bulls",
    "Slow movers",
    "Farm team",
    "Clumsy ones?",
    "Beasts of burden",
    "Plow pullers",
    "The yoke's on them"
  ],
  "capn": [
    "___ Crunch",
    "Cook's handle, maybe",
    "Boss of a bo's'n",
    "Informal title of respect",
    "___ Crunch",
    "Quaker's ___ Crunch cereal",
    "___ Crunch (cereal)",
    "Quaker Oats' ___ Crunch",
    "___ Crunch",
    "Skipper, informally",
    "___ Bill Weedles (Land of Oz character)",
    "___ Crunch (Quaker cereal)",
    "Title for Horatio Magellan Crunch, on cereal boxes",
    "Quaker's ___ Crunch",
    "___ Crunch (Quaker cereal)",
    "Cereal box title",
    "\"___ Andy's Ballyhoo\" (\"Show Boat\" song)",
    "Superior of a bos'n",
    "___ Crunch",
    "Whom mateys address",
    "First mate's superior, informally",
    "Crunch's title",
    "Rank informality?",
    "___ Andy (\"Show Boat\" role)",
    "One with many hands, informally",
    "___ Crunch",
    "\"Aye\"-catcher?",
    "See 10-Down",
    "Informal title of respect",
    "Crunch's rank"
  ],
  "game": [
    "Scrabble or cribbage",
    "Scrabble or Boggle",
    "Charades or dominoes",
    "Minecraft or Fortnite",
    "Up (or down) for something",
    "Feeling amenable (found hidden in this clue!)",
    "With 50-Down, athlete's intense expression",
    "Up (for)",
    "Hearts or spades",
    "Poker or snooker",
    "Up for it",
    "Up for something",
    "Up (for) or down (for)",
    "League of Legends or World of Warcraft",
    "Ready to take part",
    "Tag, for example",
    "Go, for one",
    "\"___ over\" (dispiriting message)",
    "Up for something",
    "First word in 104-Across",
    "Warden's charge",
    "Match part",
    "Tiddlywinks or tag",
    "Willing",
    "Jacks, e.g.",
    "Willing to go along",
    "Willing",
    "Up for it",
    "Deer and fox, e.g.",
    "Othello, e.g.",
    "Up for something",
    "Charades, e.g.",
    "Up for it",
    "Cops and robbers, e.g.",
    "See 69-Across",
    "Ready and willing",
    "Go fish, e.g.",
    "Up for it",
    "Ready and willing",
    "Willing",
    "Pictionary, e.g.",
    "Brave, in a way",
    "Well-disposed",
    "Willing to take part",
    "Willing",
    "Plucky",
    "Clue, for one",
    "Willing",
    "Warden's charge",
    "Kind of warden",
    "Charades, e.g.",
    "Word with park or plan",
    "Foxes, e.g.",
    "Willing",
    "Ready for action",
    "Willing",
    "Hearts or darts",
    "Charades or shuffleboard",
    "Animals, to hunters",
    "Willing",
    "Hunter's prey",
    "Amenable",
    "Willing"
  ],
  "left": [
    "Exited",
    "Remaining",
    "Split",
    "Split",
    "Took off",
    "Pointing in this direction: <--",
    "What \"<--\" signifies",
    "It's just not right",
    "Split",
    "Departed",
    "Liberals, with \"the\"",
    "Liberals, with \"the\"",
    "Took off",
    "One half of a 10-Down",
    "Flew the coop",
    "Took a powder",
    "Southpaw's side",
    "Liberals, with \"the\"",
    "Remaining or gone",
    "Not right",
    "Port side",
    "Headed out",
    "Sailor's port",
    "Went",
    "Liberals, with \"the\"",
    "Took off",
    "Southpaw's side",
    "Hit the road",
    "Hit the road",
    "Took off",
    "Took a powder",
    "Took a powder",
    "Liberals, with \"the\"",
    "It's just not right",
    "Half of a one-two",
    "Fast place",
    "Took a powder",
    "Hit the road",
    "Certain boxing blow",
    "Southpaw's strength",
    "Remaining",
    "Unused",
    "Remaining",
    "Forsaken",
    "Hightailed it",
    "Took a powder"
  ],
  "spex": [
    "Glasses, informally",
    "Glasses, in slang",
    "Glasses, in adspeak",
    "Eyewear, informally",
    "X-ray ___",
    "X-ray ___ (gag gift)",
    "Glasses, informally",
    "X-ray ___",
    "See 50-Across",
    "X-ray ___ (novelty item)",
    "X-ray ___",
    "X-ray ___, joke shop offering",
    "Glasses, slangily",
    "X-ray ___ (kids' goggles)",
    "Glasses, commercially",
    "Glasses, in ads"
  ],
  "sets": [
    "Some volleyball actions",
    "Where scenes are made",
    "Hardens",
    "6-1, 4-6 and 7-6, in tennis",
    "Hardens",
    "Divisions of tennis matches",
    "TVs",
    "Preceders of spikes, in volleyball",
    "Goes down",
    "Goes down, as the sun on the horizon",
    "Collectors' goals",
    "Gym units",
    "Goes down",
    "Makeup of a match",
    "Hardens",
    "Offerings in a bridal registry",
    "Even numbers and odd numbers, in math",
    "Goes down, as the sun",
    "Live musicians play them",
    "Backgrounds in theater",
    "Groups of reps",
    "Match makers?",
    "Firms",
    "Dips below the horizon",
    "You might give them props",
    "6-3, 2-6, 7-6 and others",
    "Gels",
    "Goes down",
    "Match makeup",
    "Volleyball actions between bumps and spikes",
    "Gig composition",
    "Tennis match units",
    "Match makeup",
    "Court units",
    "Match makers?",
    "Establishes",
    "Gets fixed",
    "Squash units",
    "Goes down",
    "Gels",
    "Tennis units",
    "Philatelic goals",
    "Collections",
    "Tennis units",
    "Loses liquidity",
    "Complete collections",
    "They usually end at six",
    "Three in a match, maybe",
    "Where props are seen",
    "Places for shooting stars",
    "Collections",
    "Completists' goals",
    "Collectors' completions",
    "Becomes fixed",
    "Math items represented using { and }",
    "Pushes (off)",
    "What the sun does at dusk",
    "Movie lot sights",
    "Squash match units",
    "Hollywood constructions",
    "Places for props",
    "Studio constructions",
    "Places for play things?",
    "Things some designers design",
    "6-1, 3-6 and 7-5, in tennis",
    "7-6, 2-6, 6-4, e.g.",
    "Fixes",
    "Mounts in a frame",
    "What matches are made of",
    "Preceders of spikes in volleyball",
    "Numismatists' goals",
    "Fixes the time on, as a clock",
    "Collect-'em-all collections",
    "Where scenes are seen",
    "Studio stages",
    "Gets firm",
    "Match maker?",
    "See 16-Across",
    "Lot sights",
    "Goes down",
    "Puts in its place",
    "Back lot lot",
    "In-groups",
    "Goes down",
    "Collectors' goals",
    "Starts (out)",
    "Nears the western horizon",
    "Some volleyball hits",
    "Film designers' designs",
    "Some volleyball shots",
    "Complete collections",
    "Places for props",
    "Social groups",
    "Match parts",
    "7-6, 3-6 and 6-4, e.g.",
    "Fixes",
    "Salon specialties",
    "Three in a match, maybe",
    "Gels",
    "Tennis units",
    "Studio sites",
    "Math groups",
    "Match maker?",
    "Match parts",
    "Solidifies",
    "Movie locations",
    "Studio sights",
    "6-2, 5-7, 6-3, etc.",
    "Installs",
    "Solidifies",
    "Certain volleyball shots",
    "Designer works",
    "Arranges, as music",
    "Studio stages",
    "Three are a match",
    "Goes down",
    "6-3, 5-7 and 6-4, e.g.",
    "Salon offerings",
    "TV's",
    "Where movies are made",
    "Coagulates",
    "Hardens",
    "6-3, 4-6, 7-6, e.g.",
    "Tennis units",
    "Match parts",
    "Places for props",
    "Fixes",
    "Goes down",
    "Match components",
    "Hardens",
    "Rigidifies",
    "6-3, 4-6, 6-1, e.g.",
    "Uses rollers",
    "Units for Sampras",
    "Parts of matches",
    "Goes down",
    "Match parts",
    "Tennis units",
    "They're designed in Hollywood",
    "Prepares the dinner table",
    "Collectors' goals",
    "Slowly sinks",
    "Televisions"
  ],
  "kona": [
    "Coffee-growing locale of Hawaii",
    "District on Hawaii's west coast",
    "Kind of bean on the Big Island",
    "High-quality coffee variety",
    "Coffee grown on the Big Island",
    "Big Island coffee",
    "Hawaiian coffee region",
    "Big Island coffee",
    "Hawaiian coffee region",
    "Coastal region of Hawaii",
    "Mauna Loa coffee",
    "Kind of coffee",
    "Coffee cultivated on Mauna Loa",
    "District in Hawaii",
    "Prime coffee-growing area in Hawaii",
    "Hawaii's ___ Coast",
    "Hawaiian coastal area",
    "Storm-bringing wind in Hawaii",
    "___ winds (herald of warm and muggy weather)",
    "Hawaii's ___ Coast",
    "Coffee variety",
    "Hawaiian storm",
    "Hawaii's ___ Coast",
    "Hawaiian coffee",
    "Hawaiian winter wind",
    "North or South district in Hawaii"
  ],
  "haul": [
    "U-___",
    "U-___",
    "Booty",
    "Lug along",
    "Transport by truck",
    "Tow",
    "Lug",
    "Burglar's booty",
    "Carry by semitrailer, say",
    "Burglar's take",
    "Drag",
    "Lug",
    "Schlep",
    "Robbers' take",
    "Major swag",
    "Transport",
    "Robbers' gain",
    "Cart",
    "Lug",
    "Burglar's booty",
    "Cart",
    "Take",
    "Burglar's take",
    "Thieves' take",
    "Tow",
    "Considerable winnings, informally",
    "It may follow U",
    "Spoils",
    "Thieves' booty",
    "U-___",
    "Stolen loot",
    "Transport, as a load",
    "Cargo",
    "Do a teamster's job"
  ],
  "wows": [
    "Amazes",
    "Possible reactions to shocks",
    "Really impresses",
    "Bowls over",
    "Bowls over",
    "Blows away",
    "Knocks dead",
    "Knocks the socks off",
    "Astounds",
    "Blows away",
    "Blows away",
    "Doesn't just impress",
    "Sends, in a way",
    "Blows away",
    "Really impresses"
  ],
  "help": [
    "FAQ page, e.g.",
    "What a good Samaritan offers",
    "A leg up",
    "Pitch in",
    "Rightmost option in most menu bars",
    "\"Can I get a hand here?!\"",
    "Beatles song, album or movie",
    "Pitch in",
    "Throw a line to",
    "Butler, e.g.",
    "Toolbar feature",
    "Lend a hand",
    "Succor",
    "1965 Beatles song or movie",
    "Album after \"Beatles VI\"",
    "Butlers and maids",
    "Toolbar heading",
    "Information desk offering",
    "Fab Four film",
    "It may be hired",
    "Cry to a St. Bernard?",
    "\"S.O.S.!\"",
    "Fab Four flick",
    "See 46-Across",
    "Counseling, e.g.",
    "Fab Four film",
    "1965 #1 hit with an exclamation point in its title",
    "Counseling, e.g.",
    "Butlers and maids",
    "Aid",
    "Butler, maid, etc.",
    "It's sometimes called for",
    "Beatles song or movie",
    "1965 movie with an exclamation point in its title",
    "Man around the house, maybe",
    "Fab Four film of '65",
    "PC menu selection",
    "Servants",
    "Beatles hit of 1965",
    "PC key",
    "\"S O S!\"",
    "Teen movie of 1965",
    "Fab Four flick",
    "Maids"
  ],
  "ilks": [
    "Kinds",
    "Sorts",
    "Varieties",
    "Sorts",
    "Varieties",
    "Types",
    "Types",
    "Classes",
    "Varieties",
    "Classes",
    "Classes",
    "Breeds",
    "Sorts",
    "Types",
    "Kinds",
    "Sorts",
    "Sorts",
    "Types",
    "Sorts",
    "Classes",
    "Varieties",
    "Breeds, so to speak",
    "Sorts",
    "Genres",
    "Sorts",
    "Sorts"
  ],
  "lots": [
    "Divisions of a subdivision",
    "Oodles",
    "Things to draw or cast",
    "Plenty",
    "A whole bunch",
    "Oodles",
    "A ton",
    "Land parcels",
    "Things kids sometimes draw",
    "Auction units",
    "Quite a few",
    "Quite a bit",
    "A bushelful",
    "Auction units",
    "A bunch",
    "A ton",
    "Heaps",
    "Loads",
    "Hugely",
    "A whole bunch",
    "With 11-Across, biblical woman who met a bad end",
    "A great deal",
    "Very much",
    "Plenty",
    "They may be odd",
    "Auction groups",
    "Oodles",
    "Oodles",
    "Some are odd",
    "Loads",
    "Builders' sites",
    "A whole bunch",
    "Scores",
    "Things to draw",
    "A truckload",
    "Mountains",
    "Oodles",
    "They're drawn",
    "They may be odd",
    "They may be drawn",
    "Oodles",
    "Mucho",
    "Plenty",
    "Gobs",
    "Plenty",
    "Plenty",
    "Subdivision subdivisions",
    "Many",
    "A good deal",
    "Plenty",
    "With 19-Across, a pillar of the Bible",
    "A good deal"
  ],
  "pyre": [
    "It rhymes with fire, appropriately",
    "Public firing?",
    "Flammable structure",
    "End for Joan of Arc",
    "Apt rhyme for \"fire\"",
    "Funeral mass",
    "Location from which the phoenix rose",
    "Funeral fire",
    "\"And our love become a funeral ___\" (lyric from the Doors' \"Light My Fire\")",
    "Phoenix construction",
    "Funeral sight",
    "Combustible heap",
    "Stand in the flames",
    "Funeral fire",
    "Combustible pile",
    "Combustible heap",
    "Sticks around a stake",
    "Combustible pile",
    "Combustible heap",
    "Fiery stack"
  ],
  "disc": [
    "Frisbee, e.g.",
    "Compact ___",
    "___ golf",
    "LP, e.g.",
    "The \"D\" of D.J.",
    "Hockey puck, e.g.",
    "It may be slipped to a doctor",
    "CD part",
    "Frisbee, e.g.",
    "LP, e.g.",
    "LP, e.g.",
    "Virtually every coin",
    "Frisbee, e.g.",
    "Frisbee, e.g.",
    "Alternative to a download",
    "Oreo component",
    "Tiddlywink or Frisbee",
    "Kind of brake",
    "Tiddlywink, e.g.",
    "Frisbee, e.g.",
    "Either side of an Oreo",
    "Frisbee, checker or tiddlywink",
    "Tiddlywink or Frisbee",
    "Flying ___",
    "45, e.g.",
    "Piece in the game Othello",
    "45 or 78",
    "*CD part",
    "A laser might read it",
    "LP or 45",
    "45, e.g.",
    "Kind of brake",
    "Drum alternative, vehicularly",
    "Wink in tiddlywinks, e.g.",
    "Wink in tiddlywinks, e.g.",
    "45, for one",
    "45, e.g.",
    "45 or 78",
    "Modern music holder",
    "Frisbee, e.g.",
    "Computer insert",
    "The \"D\" of D.J.",
    "Frisbee, e.g.",
    "Frisbee, e.g.",
    "Record",
    "The \"D\" in CD",
    "45, e.g.",
    "Tiddlywink, e.g.",
    "Laser ___",
    "Data source",
    "File holder",
    "Piece from the game Othello",
    "The \"D\" of C.D.",
    "Checker, e.g.",
    "Kind of jockey",
    "Platter",
    "Part of CD",
    "Kind of brake",
    "Part of D.J.",
    "Part of D.J.",
    "The \"D\" of C.D.",
    "Part of D.J.",
    "Platter",
    "Platter",
    "Harrow blade"
  ],
  "ohoh": [
    "\"Pick me! Pick me!\"",
    "\"Pick me, pick me!\"",
    "\"Pick me! Pick me! I know!\"",
    "\"I got it! I got it!\"",
    "Eager pupil's exclamation",
    "\"Pick me! Pick me!\"",
    "\"I know the answer!\"",
    "\"I know the answer!\"",
    "Cry of anticipation",
    "Cry before \"I know!,\" in a classroom",
    "\"Pick me! Pick me!\"",
    "\"I know! I know!\"",
    "\"Pick me! Pick me!\"",
    "\"Wait, I know that!\"",
    "Cry of excitement",
    "\"Call on me! I know this!\"",
    "Excited pupil's shout",
    "\"Call on me! Call on me!\"",
    "\"I almost forgot ...!\"",
    "Cry before \"I know!\"",
    "\"I know! I know!\"",
    "\"Whoops!\"",
    "\"This is bad!\"",
    "\"You'd better watch out!\"",
    "Eager pupil's cry",
    "\"This looks like trouble!\"",
    "\"I know that one!\"",
    "Cry of anticipation",
    "Anticipatory cry",
    "Cry of anticipation",
    "Cry of anticipation",
    "\"Pick me! I know the answer!\"",
    "Eager cry",
    "\"I almost forgot ...!\"",
    "\"We got trouble!\"",
    "Cry of eagerness",
    "Cry of excitement",
    "Expectant cry",
    "Eager student's cry",
    "\"Oops!\"",
    "\"There's a problem!\"",
    "\"You mean, this isn't waterproof?\"",
    "Anticipatory cry",
    "Cry of eagerness",
    "Excited cry",
    "\"Here comes trouble!\"",
    "Anticipatory exclamation",
    "\"Look out...\"",
    "Sounds before \"I've got it!\"",
    "Cry of anticipation",
    "\"Oops!\"",
    "\"Whoops!\"",
    "Cry of excitement",
    "\"Oops!\"",
    "\"Looks like trouble\""
  ],
  "hums": [
    "Carries a tune, in a way",
    "Doesn't remember the words, say",
    "Runs perfectly",
    "Plays a kazoo",
    "Operates perfectly",
    "Runs smoothly",
    "Keeps one's mouth shut?",
    "Runs smoothly",
    "Symptoms for a car mechanic",
    "Drones",
    "Runs smoothly",
    "Bustles",
    "Accompanies musically sans words"
  ],
  "sesh": [
    "Informal get-together",
    "Rehearsal, e.g., in slang",
    "Informal meeting",
    "Group hangout time, slangily"
  ],
  "teal": [
    "Blue-green shade",
    "Color on Jacksonville Jaguar uniforms",
    "Bluish hue",
    "Blue-green shade",
    "Relative of turquoise",
    "Shade akin to turquoise",
    "Color akin to cyan",
    "Shade akin to turquoise",
    "Pond swimmer",
    "Blue-green shade",
    "Relative of aqua",
    "Bluish green",
    "Blue-green",
    "Blue-green hue",
    "Greenish blue",
    "Color akin to cyan",
    "Old Crayola color akin to Tropical Rain Forest",
    "Blue shade",
    "Color akin to turquoise",
    "Background color of a $100 bill",
    "Relative of turquoise",
    "Green-blue",
    "Blue-green",
    "Mallard relative",
    "Blue-green",
    "Duck or one of its colors",
    "Greenish shade",
    "Greenish shade",
    "Freshwater duck",
    "Blue-green shade",
    "Greenish blue",
    "Blue shade",
    "Cousin of a canvasback",
    "Blue-green",
    "Relative of turquoise",
    "Blue-green hue",
    "Greenish-blue",
    "Blue-winged duck",
    "Cousin of a greenwing",
    "Small dabbler",
    "Florida Marlins color",
    "Pond denizen",
    "Bluish hue",
    "Dabbling duck",
    "Blue shade",
    "Shade of blue",
    "Green-blue",
    "Greenish-blue",
    "Blue shade",
    "Blue hue",
    "Hue close to aqua",
    "Relative of a mallard",
    "Bluish green",
    "Bluish green",
    "Pond duck",
    "Blue-green",
    "Dabbling duck",
    "Pond duck",
    "Shade of blue",
    "Shade of blue",
    "Freshwater duck",
    "Freshwater duck",
    "Blue-green",
    "Duck in a pond",
    "Cousin of a canvasback",
    "Shade of blue",
    "Cousin of a canvasback",
    "Pond swimmer",
    "Freshwater duck",
    "Mallard's cousin",
    "Shade of blue",
    "Shade of blue",
    "Cousin of a canvasback",
    "Shade of blue",
    "Dabbling duck",
    "Popular game bird",
    "Color close to aqua",
    "Marsh duck",
    "Blue-green",
    "Small duck",
    "Pond swimmer",
    "Shade of blue",
    "Greenish blue",
    "Dabbling duck",
    "Cousin of the pintail",
    "Dabbling duck",
    "Marsh duck",
    "Blue hue",
    "Darker than turquoise",
    "Freshwater duck",
    "Greenish blue",
    "Shade of blue",
    "Blue-green"
  ],
  "ping": [
    "Radar sound",
    "Contact electronically",
    "With 65-Across, another name for 37-Across",
    "Notification sound",
    "Worrisome engine noise",
    "Contact briefly electronically",
    "Worrisome engine sound",
    "___-Pong",
    "Radar sound",
    "With 57-Across, game that includes the starts of 17-, 29-, 48- and 64-Across",
    "Unwanted engine sound",
    "Worrisome engine sound",
    "... a table tennis ball (with 45-Across)",
    "Worrisome engine sound",
    "Pinball sound",
    "Unwelcome auto noise",
    "Sonar sound",
    "Sonar pulse",
    "Bullet sound",
    "Engine sound",
    "Unwanted engine sound",
    "Engine knock",
    "Striking sound",
    "Engine sound",
    "Worrisome car sound",
    "Engine sound",
    "Ricocheting bullet sound",
    "Engine knock",
    "Engine knock"
  ],
  "edie": [
    "Actress Falco",
    "Actress Falco",
    "Singer Brickell",
    "Actress Falco",
    "1960s \"It Girl\" Sedgwick",
    "Pop singer Brickell",
    "Falco with four Emmys",
    "Singer Brickell",
    "Actress McClurg",
    "Falco with Emmys for two different series",
    "Actress Falco",
    "Singer Brickell",
    "Emmy winner Falco",
    "___ Sedgwick, 1960s \"It Girl\"",
    "Rock's Brickell",
    "Actress Falco",
    "Falco of TV and film",
    "Falco of \"The Sopranos\"",
    "Actress Falco",
    "Actress Falco",
    "1960s \"It Girl\" Sedgwick",
    "Actress Sedgwick in Warhol films",
    "Falco of \"The Sopranos\"",
    "Emmy-winning Falco",
    "Sedgwick of Warhol films",
    "Actress Falco of \"Nurse Jackie\"",
    "Falco of \"The Sopranos\"",
    "\"The Sopranos\" Emmy winner Falco",
    "Woman's name that sounds like its first two letters",
    "Singer Brickell who fronted the New Bohemians",
    "Actress Falco",
    "\"Desperate Housewives\" housewife",
    "One of the housewives on \"Desperate Housewives\"",
    "Brickell or Falco",
    "Actress Falco",
    "Emmy-winning Falco",
    "Sedgwick in Warhol films",
    "Actress Falco",
    "Falco of \"Nurse Jackie\"",
    "Falco of \"Nurse Jackie\"",
    "Pop singer Brickell",
    "Falco of \"Nurse Jackie\"",
    "Falco of \"The Sopranos\"",
    "Falco of \"Nurse Jackie\"",
    "\"Desperate Housewives\" role",
    "Brickell who sang \"What I Am\"",
    "Falco of \"The Sopranos\"",
    "Falco of \"Nurse Jackie\"",
    "Emmy winner Falco",
    "Pop singer Brickell",
    "Sedgwick in Warhol films",
    "Falco of \"Nurse Jackie\"",
    "Falco of \"Nurse Jackie\"",
    "Beggar in Sir Walter Scott's \"The Antiquary\"",
    "1957 Tony winner Adams",
    "Lou Grant's ex on \"The Mary Tyler Moore Show\"",
    "Emmy-winning Falco",
    "Falco of 23-Across",
    "With 52-Across, lead female role in TV's \"Peter Gunn\"",
    "Falco who played Carmela on \"The Sopranos\"",
    "Warhol associate ___ Sedgwick",
    "\"Desperate Housewives\" role",
    "Falco of \"The Sopranos\"",
    "\"Desperate Housewives\" housewife",
    "Singer Brickell",
    "Actress Sedgwick",
    "Actress Falco",
    "Actress Falco",
    "Pop singer Brickell",
    "Real estate agent on \"Desperate Housewives\"",
    "Actress McClurg",
    "\"The Sopranos\" Emmy winner Falco",
    "Pop singer Brickell",
    "Sedgwick of Warhol films",
    "Warhol actress Sedgwick",
    "Underground film actress Sedgwick",
    "Adams of \"The Ernie Kovacs Show\"",
    "Falco of \"The Sopranos\"",
    "Singer Brickell",
    "___ Britt on \"Desperate Housewives\"",
    "Singer Brickell",
    "Actress Sedgwick",
    "Peter Gunn's girlfriend",
    "Emmy winner Falco",
    "One of the housewives on \"Desperate Housewives\"",
    "Actress Falco",
    "Emmy winner Falco",
    "Actress Falco",
    "Falco of \"The Sopranos\"",
    "Pop's Brickell",
    "Falco of \"The Sopranos\"",
    "Actress McClurg",
    "Falco of \"The Sopranos\"",
    "Singer Brickell",
    "Peter Gunn's girlfriend",
    "Falco of \"The Sopranos\"",
    "Falco of \"The Sopranos\"",
    "Singer Adams",
    "Falco of \"The Sopranos\"",
    "Singer Brickell",
    "Singer/actress Adams",
    "Actress Falco",
    "Falco of \"The Sopranos\"",
    "Singer Adams",
    "Actress Falco",
    "Falco of \"The Sopranos\"",
    "Adams of \"The Jack Paar Show\"",
    "Comedic actress McClurg",
    "Falco of \"The Sopranos\"",
    "Emmy winner Falco",
    "Chanteuse Adams",
    "Actress McClurg",
    "Emmy winner Falco",
    "Emmy winner Falco",
    "Falco of \"The Sopranos\"",
    "Falco of \"The Sopranos\"",
    "Emmy-winning Falco",
    "Plimpton book subtitled \"An American Biography\"",
    "Warhol pal ___ Sedgwick",
    "\"The Sopranos\" Emmy winner Falco",
    "Singer Brickell",
    "Actress McClurg",
    "Singer Brickell",
    "1982 George Plimpton best seller",
    "Newswoman Magnus",
    "Singer Adams",
    "1982 Plimpton best seller",
    "Singer Brickell",
    "Peter Gunn's girlfriend",
    "Singer Adams",
    "Newswoman Magnus",
    "Chanteuse Adams",
    "90's singer Brickell",
    "Singer Adams",
    "Actress McClurg",
    "Actress McClurg",
    "Pop singer Brickell",
    "Singer Adams",
    "60's cigar pitchwoman Adams",
    "Newswoman Magnus",
    "Actress McClurg",
    "Warhol icon Sedgwick",
    "Pop singer Brickell",
    "Actress McClurg",
    "Singer Brickell",
    "Actress McClurg",
    "\"What I Am\" singer Brickell",
    "Singer Brickell",
    "\"What I Am\" singer Brickell",
    "Lou's ex-wife on \"Mary Tyler Moore\"",
    "Actress McClurg",
    "Adams of 50's TV",
    "Actress McClurg",
    "Singer Brickell",
    "Actress Adams",
    "Actress McClurg",
    "Peter Gunn's lady",
    "Mrs. Paul Simon",
    "Newswoman Magnus",
    "Peter Gunn's girlfriend ___ Hart",
    "Pop singer Brickell",
    "1982 Stein/Plimpton biography"
  ],
  "less": [
    "Not so much",
    "More, as the saying goes",
    "What's more, it's said",
    "Off",
    "Amount to make do with",
    "Not so much",
    "Subtracting",
    "Minimalist's desire",
    "Minus",
    "Off",
    "What frugal people make do with",
    "Minus",
    "Not so much",
    "Not as",
    "Not so great",
    "More limited",
    "Minus",
    "To a smaller degree",
    "What 1 is to 2 and 2 is to 3",
    "Taking away",
    "Take away",
    "Diminished by",
    "Not so much",
    "What irregulars go for",
    "Lower",
    "A reduced state",
    "Not so great",
    "Minus",
    "Dieter's amount",
    "Minus",
    "Off, pricewise",
    "\"___ is more\"",
    "With 34-Across, what \"<\" means",
    "Not so great",
    "-",
    "Off",
    "Minus",
    "Amount that's settled for",
    "Not including",
    "Subtracting",
    "Subtracting",
    "Minus",
    "More's opposite",
    "Minus",
    "Minus",
    "More limited",
    "More, in a saying",
    "Nothing ___",
    "Minus",
    "Subtracted by",
    "Minus",
    "Much ___",
    "Not including",
    "Minus",
    "Not so much",
    "Discounted by",
    "Minus",
    "What dieters eat",
    "Sale word",
    "Marked down",
    "Not so much",
    "Reduced by",
    "Minus",
    "Not as much",
    "To a smaller degree",
    "Minus",
    "Take away",
    "Reduced by",
    "Not so much",
    "Taking off",
    "Marked down",
    "Lacking",
    "Not as much",
    "With a discount of",
    "More's opposite",
    "Off",
    "Minus",
    "Exhibiting greater moderation",
    "Not as much",
    "Subtracting",
    "More or ___",
    "Not so much",
    "Not as",
    "Minus",
    "Not as much",
    "Nothing ___",
    "Result of downsizing",
    "Subtracted by",
    "Discounted",
    "Not so much",
    "Minus",
    "Lacking",
    "Minus",
    "Lower",
    "On sale",
    "Not so much",
    "Minus",
    "More, in a saying",
    "Not so great",
    "More or ___",
    "Sale-priced",
    "Not so much",
    "Discounted by",
    "Discounted by",
    "More or ___",
    "Amount to make do with",
    "Nothing ___",
    "Amount to make do with",
    "A reduced state",
    "With a discount of",
    "Diminished by",
    "\"Nothing ___\" (bargainer's demand)",
    "Minus",
    "Not so much",
    "Minus",
    "Reduced by",
    "Comparative form of little",
    "Not so much",
    "Reduced",
    "Off",
    "Minus",
    "\"Make do\" amount",
    "Minus",
    "Discounted by",
    "Take away, in a way",
    "Minus",
    "Not so much",
    "Subtracting",
    "What you pay at sales",
    "Minus",
    "Off",
    "Minus",
    "Minus",
    "Decreasingly",
    "Minus",
    "Minus",
    "Not so much"
  ],
  "perm": [
    "Style that makes waves",
    "Waves to a hairdresser?",
    "Do for a few months?",
    "Salon job",
    "Long-lasting hair wave, informally",
    "Salon job, briefly",
    "Salon offering",
    "Request at a hair salon, informally",
    "Request at a hair salon",
    "Curl maker",
    "Salon job",
    "Chemically treated hairstyle",
    "Locks in place for a while?",
    "Tightener of locks",
    "Salon job",
    "Wavy do",
    "It can make waves",
    "Hair job at a salon",
    "It'll curl your hair",
    "Salon offering",
    "Salon treatment",
    "Salon option",
    "Make waves?",
    "It can make waves",
    "Durable do",
    "Salon job",
    "It'll curl your hair",
    "Straight-to-curly transformation, informally",
    "Hair salon job",
    "Salon job",
    "Salon job, informally",
    "Do",
    "Make waves",
    "Salon job",
    "Wave maker",
    "Hair wave",
    "Hair curler",
    "Salon job",
    "Certain wave, for short",
    "Make waves?",
    "Short wave?",
    "Salon service",
    "Salon offering",
    "Give a wave?",
    "Make waves, for short?",
    "Short wave?",
    "Salon job",
    "It can curl your hair",
    "Salon treatment"
  ],
  "jane": [
    "Doe in a court case",
    "___ Addams, first American woman to receive the Nobel Peace Prize",
    "Plain ___",
    "Primatologist Goodall",
    "TV's \"___ the Virgin\"",
    "Name of England's Nine Days Queen",
    "\"___ the Virgin\" (CW show)",
    "Author Austen",
    "Half of a swinging couple?",
    "Eldest Bennet sister in \"Pride and Prejudice\"",
    "Primatologist Goodall",
    "Plain ___",
    "Dick's running mate",
    "\"Sweet\" girl of song",
    "One of a jungle couple",
    "Porter created by Burroughs",
    "With 2-Down, book that includes the line \"Conventionality is not morality\"",
    "One of the Jetsons",
    "With 35-Down, fictional heroine who says \"I am no bird; and no net ensnares me\"",
    "Doe being defended",
    "One of Spot's masters",
    "Tarzan's woman",
    "Name after \"you\"",
    "Tarzan's love",
    "Girl with the dog Spot",
    "\"A Thousand Acres\" novelist Smiley",
    "One of a primer pair",
    "Chimpanzee researcher Goodall",
    "Jungle woman",
    "John or ___ Doe",
    "Young women's magazine",
    "With 11-Down, Bronte heroine",
    "Plain ___",
    "With 29-Across, \"Barbarella\" star",
    "One of Spot's masters",
    "Tarzan's love",
    "Christie's Miss Marple",
    "Primer girl",
    "Part of a jungle pair",
    "Inamorata of Tarzan",
    "Mrs. Jetson"
  ],
  "raps": [
    "Freestyles, perhaps",
    "Performs like Iggy Azalea",
    "Freestyles, perhaps",
    "Drake's output",
    "Many modern chart-toppers",
    "Songs by Lil Wayne, Lil Yachty or Lil Uzi Vert",
    "Knocks",
    "Mimics Nicki Minaj",
    "Performs like Lil Wayne or Lil' Kim",
    "Freestyles, say",
    "Releases of Drake and Cardi B",
    "Knocks",
    "Some performances at the Apollo",
    "Emulates Jay Z",
    "T-Pain and Ice-T output",
    "Emulates Jay Z and Master P",
    "Knocks",
    "Does a Ludacris impersonation",
    "Spits rhymes",
    "Criticizes",
    "2006's \"Ms. New Booty\" and others",
    "Speaks ill of",
    "Staples of seances",
    "Chins or jaws",
    "Does some freestyling",
    "They often include samples",
    "Criticizes",
    "62-Across offerings",
    "Emulates AZ or T.I.",
    "Notorious B.I.G. releases",
    "Emulates Eve",
    "Emulates Eminem",
    "Criticizes",
    "Emulates Lil' Kim or Lil Wayne",
    "Speaks ill of",
    "Upbraids",
    "Emulates Eminem",
    "Emulates Eminem",
    "Emulates Eminem",
    "Does hip-hop",
    "Seance noises",
    "Knocks",
    "Repertoire for Will Smith",
    "Criticizes",
    "Vocalizes like the Beastie Boys",
    "Hip-hop repertory",
    "Will Smith songs",
    "Some corporal punishment",
    "Queen Latifah songs",
    "Knocks",
    "Songs by Queen Latifah",
    "Hip-hop hits",
    "90's songs",
    "Criticizes",
    "Criticizes",
    "Talks gangsta-style",
    "Hip songs",
    "Hip-hop songs",
    "Ice-T servings?",
    "Seance sounds",
    "Criticizes",
    "Snoop Doggy Dogg songs",
    "D.J. Jazzy Jeff songs",
    "Entertains like Hammer"
  ],
  "sots": [
    "Barflies",
    "They may need to be cut off",
    "Lushes",
    "They're often lit",
    "Drunkards",
    "Ones always tossing things back?",
    "They're often blitzed",
    "Longtime members of the bar?",
    "Elbow-benders",
    "Drunks",
    "Drunkards",
    "Barflies",
    "They often get smashed",
    "They're often loaded",
    "They're often blitzed",
    "Drunkards",
    "Lit group",
    "They're often wasted",
    "They're often fried",
    "Teetotalers they're not",
    "They're often wasted",
    "Dipsos",
    "Ones often calling the shots?",
    "Boozehounds",
    "They're often loaded",
    "Boozers",
    "Bar habitues",
    "Members of the bar?",
    "Drunkards",
    "Elbow-benders",
    "Tipplers",
    "They're often fried",
    "They're lit",
    "Tipplers",
    "Tipplers",
    "Drunkards",
    "Barflies",
    "Ones likely to go \"hic!\"",
    "Whom bouncers might bounce",
    "Tipplers",
    "They knock back lots",
    "They're often 9-Down",
    "Lushes",
    "Tight group?",
    "Habitual tipplers",
    "They may need to dry out",
    "They may get shots frequently",
    "Dipsomaniacs",
    "They're high-minded",
    "Swillbellies",
    "Drunks",
    "Dipsos",
    "Breathalyzer test flunkers",
    "Stewbums",
    "Sponges",
    "Boozers",
    "Drunkards",
    "Hooch hounds",
    "Drunkards",
    "Bar habitues",
    "Lushes",
    "Juiceheads",
    "They're plowed"
  ],
  "opie": [
    "Role for \"Ronny\" Howard",
    "1960s Ron Howard TV role",
    "Early Ron Howard role",
    "Mayberry boy of 1960s TV",
    "Boy on \"The Andy Griffith Show\"",
    "Man's name that sounds like two consecutive letters of the alphabet",
    "Aunt Bee's charge on \"The Andy Griffith Show\"",
    "Relative of Aunt Bee",
    "Mayberry moppet of 1960s TV",
    "Andy Taylor's kid on old TV",
    "Member of the Sons of Anarchy on FX's \"Sons of Anarchy\"",
    "Role for a young Ron Howard",
    "Concern for TV's Aunt Bee",
    "Boy of Mayberry",
    "Andy's boy on \"The Andy Griffith Show\"",
    "Onetime Ron Howard role",
    "1960s TV character who says \"Aw, shucks\"",
    "Big name among radio shock jocks",
    "Mayberry boy",
    "'60s sitcom son",
    "Mayberry moppet",
    "Anthony's former partner in radio",
    "Big name in morning radio",
    "Mayberry boy",
    "Bee relative",
    "Mayberry kid",
    "Anthony's longtime partner on satellite radio",
    "Early Ron Howard role",
    "Satellite radio's \"The ___ & Anthony Show\"",
    "Taylor boy of Mayberry",
    "1960s TV boy",
    "Anthony's partner in radio",
    "\"The Andy Griffith Show\" boy",
    "Anthony's XM Radio partner",
    "Helen Crump Taylor's TV stepson",
    "Moppet of black-and-white TV",
    "___ Taylor, old sitcom character",
    "Taylor on \"The Andy Griffith Show\"",
    "TV sitcom boy who liked to fish",
    "Redheaded boy of 1960s TV",
    "Grandnephew in 1960s TV",
    "Boy of Mayberry",
    "Mayberry moppet",
    "Early Ron Howard role",
    "1960s TV boy",
    "Andy's TV son",
    "Mayberry boy",
    "Bee's charge",
    "Mayberry moppet",
    "1960s TV boy",
    "Mayberry boy",
    "Mayberry boy",
    "Redheaded kid of old TV",
    "Mayberry boy",
    "\"The Andy Griffith Show\" boy",
    "English artist John who's buried at St. Paul's Cathedral",
    "Mayberry boy",
    "Pupil of Miss Crump, on TV",
    "\"The Andy Griffith Show\" boy",
    "Classic TV role for Ronny Howard",
    "Early Ron Howard role",
    "Sheriff Andy Taylor's kid",
    "Mayberry boy",
    "Mayberry boy",
    "Mayberry lad",
    "Artist John, known as the Cornish Wonder",
    "Talk radio's ___ & Anthony",
    "Early TV role for Ron Howard",
    "Aunt Bee's charge on \"The Andy Griffith Show\"",
    "Taylor boy on \"The Andy Griffith Show\"",
    "Radio's \"___ & Anthony Show\"",
    "Red-headed boy of 60's TV",
    "Mayberry kid",
    "Mayberry boy",
    "Lad clipped by Floyd the barber",
    "Ron Howard role",
    "Bee's nephew, in old TV",
    "Andy's boy in 60's TV",
    "Sheriff Taylor's son",
    "Miss Crump's pupil, on TV",
    "Bee's charge",
    "Mayberry boy",
    "English painter John",
    "Taylor boy of 60's TV",
    "Nickname of radio shock jock Greg Hughes",
    "60's TV boy",
    "Mayberry boy",
    "60's TV kid",
    "Mayberry moppet",
    "Aunt Bee's boy",
    "English painter John",
    "Mayberry kid",
    "60's TV tyke",
    "60's TV boy",
    "Andy Taylor's TV son",
    "English painter called the Cornish Wonder",
    "Mayberry lad",
    "English painter John ___",
    "Sheriff Taylor's son",
    "Mayberry minor",
    "19th-century humorist ___ Read",
    "60's TV boy",
    "Taylor boy, in 60's TV",
    "Aunt Bee's charge",
    "Andy Taylor's son, in 60's TV",
    "Aunt Bee's boy",
    "Young Ron Howard role",
    "Aunt Bee's charge",
    "Sheriff Taylor's kid",
    "Mayberry boy",
    "60's role for Ron Howard",
    "Andy's boy, in 60's TV",
    "Ron Howard role",
    "Mayberry boy of TV",
    "Sheriff Taylor's son, in 60's TV",
    "60's TV boy",
    "\"The Andy Griffith Show\" role",
    "Ronny Howard TV role",
    "Mayberry denizen",
    "Andy Taylor's son",
    "Ron Howard's first TV role",
    "Mayberry lad",
    "Mayberry boy",
    "Ron Howard TV role",
    "Humorist-novelist Read",
    "Aunt Bee's charge",
    "Mayberry lad",
    "One of TV's Taylors",
    "Mayberry moppet",
    "Mayberry boy",
    "Mayberry moppet",
    "Bee's charge, in Mayberry",
    "Andy Griffith's TV son",
    "Mayberry resident",
    "Ron Howard TV role"
  ],
  "kids": [
    "Joshes",
    "Jokes",
    "Department store section",
    "Ribs",
    "Babies grow into them",
    "One of the blanks in the cereal slogan \"___ are for ___\"",
    "Baby goats",
    "Swing-set set",
    "Farm young",
    "See 59-Down",
    "Rug rats",
    "Isn't serious",
    "Joshes",
    "With 70- and 71-Across, what the middle of this puzzle is",
    "Grade-schoolers",
    "Baby goats",
    "Youngsters",
    "Joshes",
    "Tykes",
    "Much-discussed 1995 movie",
    "Linkletter subjects",
    "Juveniles",
    "Toddlers"
  ],
  "bath": [
    "One of the B's in BB&B",
    "Huge financial loss, so to speak",
    "One might be drawn",
    "Room with a sink and medicine cabinet",
    "Cat hate",
    "Jets might be found in this",
    "Difficult thing to give a cat",
    "Wet bar locale?",
    "Big loss, figuratively",
    "Room with a tub, informally",
    "Room often next to a bedroom",
    "\"Splish Splash\" spot",
    "Setting for David's \"The Death of Marat\"",
    "Smallish room",
    "Financial shellacking",
    "It may be drawn at night",
    "Subject of dozens of Degas paintings",
    "Something that's drawn",
    "Setting for candlelit romance",
    "The Wife of ___ (Chaucer character)",
    "Some like it hot",
    "Activity with bubbles",
    "Turkish ___",
    "Something a cat doesn't like",
    "Pre-bedtime ritual",
    "Huge financial loss",
    "Soaking site",
    "It's drawn for the dirty",
    "Big loss",
    "Some like it hot",
    "Wash-up",
    "The Wife of ___ (Chaucer story teller)",
    "Shellacking",
    "Ordeal for Rover, perhaps",
    "Shower alternative",
    "A dirty person may draw one",
    "Cleaning solution?",
    "Shellacking",
    "Alternative to a shower",
    "Serious business loss",
    "Chaucer's Wife of ___",
    "Wife of ___ (Chaucer pilgrim)",
    "Alternative to a shower",
    "City near Bristol"
  ],
  "yolo": [
    "Daredevil's hashtag",
    "Devil-may-care attitude, in modern parlance",
    "#carpediem",
    "Rationale for a dumb stunt, in modern slang",
    "Devil-may-care motto, in brief",
    "Modern acronym for \"seize the day\"",
    "\"This is probably dumb but I'm doing it anyway\" hashtag",
    "Motto for a modern risk-taker, for short",
    "Modern acronym for \"Seize the day!\"",
    "Modern \"Carpe diem\"",
    "Modern acronym meaning \"carpe diem\"",
    "Modern acronym suggesting \"seize the day\""
  ],
  "lend": [
    "Confer, as credibility",
    "Act like a shark, in a way",
    "Impart",
    "Supply temporarily",
    "Give for a while",
    "Furnish",
    "Offer mortgages",
    "Advance, as money",
    "Impart",
    "Furnish temporarily",
    "Advance",
    "Impart",
    "Impart",
    "Impart",
    "Extend credit",
    "Furnish",
    "Act the pawnbroker",
    "Give for a while",
    "Furnish",
    "Advance",
    "Advance",
    "Give away temporarily",
    "Give up for a while",
    "Furnish",
    "Impart",
    "Impart",
    "Furnish",
    "Libraries do it",
    "Impart",
    "Give temporarily",
    "Afford",
    "Furnish",
    "What usurers do",
    "Advance",
    "Play Shylock",
    "Give temporarily",
    "Furnish",
    "Advance, as money",
    "Give temporarily",
    "Impart",
    "Allow temporary use of",
    "Make advances?",
    "Advance",
    "Provide pro tem",
    "Do banker's work",
    "Give for a while",
    "Do banker's work",
    "Furnish for now",
    "Extend credit",
    "Give obligingly",
    "Allow to use",
    "What libraries do",
    "Put up, as money",
    "Give for a while",
    "Have an interest in interest",
    "Borrow's opposite",
    "Play the pawnbroker",
    "Extend credit",
    "Advance",
    "Advance",
    "Advance",
    "Impart",
    "Provide",
    "Advance",
    "Help a borrower",
    "Contribute obligingly",
    "Advance",
    "Allow the use of",
    "Impart",
    "Give temporarily",
    "Furnish",
    "Impart"
  ],
  "park": [
    "Get into a lot",
    "Place to walk a dog",
    "Place name in Monopoly",
    "More than a boat, but less than an idea",
    "Use a lot?",
    "The \"P\" of PRNDL",
    "The \"P\" of PRNDL",
    "New York's Washington Square ___",
    "Place in New York City?",
    "You're not going anywhere if you're in this",
    "The \"P\" of PRNDL",
    "Part of PRNDL",
    "See 10-Down",
    "Bench locale",
    "The P of PRNDL",
    "Driving test directive",
    "Place to stroll",
    "It may be above first",
    "Picnic spot",
    "Use a lot",
    "Place the car",
    "London's Hyde, e.g.",
    "Green scene",
    "Common"
  ],
  "noti": [
    "Response to the Little Red Hen",
    "\"Me? Uh-uh!\"",
    "\"Me? Never!\"",
    "Demurring words",
    "Denial of responsibility",
    "\"Me? Are you kidding?!\"",
    "\"You've got the wrong person\"",
    "\"Me? Never!\"",
    "Possible reply to \"Who's responsible?\"",
    "Words of denial",
    "\"Don't look at me!\"",
    "Self-denial?",
    "\"The Little Red Hen\" refusal",
    "Cry of innocence",
    "Cry of innocence",
    "\"Don't count on me\"",
    "\"Don't blame me!\"",
    "Cry of innocence",
    "Curt denial",
    "Claim of innocence",
    "Words of denial",
    "Terse demurral",
    "Terse denial",
    "\"Don't look at me!\"",
    "\"The Little Red Hen\" response",
    "Words said with a look of innocence",
    "\"Wasn't my fault!\"",
    "Response to the Little Red Hen",
    "Reply in \"The Little Red Hen\"",
    "\"Don't look at me!\"",
    "Reply to the Little Red Hen",
    "\"Don't look at me\"",
    "Terse denial",
    "Answer to the Little Red Hen",
    "Denier's comment",
    "\"Don't look at me!\"",
    "Words of denial",
    "Reply to the Little Red Hen",
    "Reply of innocence",
    "\"Don't look at me\"",
    "\"Don't look at me!\"",
    "Reply to the Little Red Hen",
    "Innocent's claim",
    "Words of denial",
    "Reply to the Little Red Hen",
    "Brief denial",
    "Response to the Little Red Hen",
    "Words of denial",
    "Denier's words",
    "\"Don't look at me!\"",
    "Reply to the Little Red Hen",
    "\"Forget it, Little Red Hen!\"",
    "\"Don't look at me!\"",
    "\"Don't look at me!\"",
    "Stiff denial",
    "\"Don't look at me!\"",
    "Reply to the Little Red Hen",
    "Reply to the Little Red Hen",
    "Reply to the Little Red Hen",
    "Words of denial",
    "Denial of a sort",
    "Reply to the Little Red Hen",
    "Reply to the Little Red Hen",
    "Reply to the Little Red Hen",
    "Reply to the Little Red Hen"
  ],
  "erse": [
    "Language related to Manx",
    "Gaelic language",
    "Source of the word \"trousers\"",
    "Gaelic language",
    "Language that gave us \"spunk\" and \"slogan\"",
    "Gaelic tongue",
    "Language in which \"Hello, how are you?\" is \"Halo, ciamar a tha thu?\"",
    "Highland tongue",
    "Highland language",
    "Gaelic language",
    "Scot's tongue",
    "Gaelic tongue",
    "Scots Gaelic",
    "European language",
    "Highland tongue",
    "Cousin of Manx",
    "Gaelic language",
    "Celtic tongue of the British Isles",
    "Old World language",
    "Gaelic tongue",
    "Irish Gaelic",
    "Gaelic tongue",
    "Highland tongue",
    "Language that gave us \"smithereens\"",
    "Source of the word \"trousers\"",
    "Language that gave us \"slogan,\" originally meaning \"battle cry\"",
    "Scottish Gaelic",
    "Gaelic tongue",
    "Language spoken around Loch Ness",
    "Scot's language",
    "The language Gaidhlig",
    "Language for a 37-Down",
    "Language from which \"clan\" comes",
    "\"Plaid\" and \"spunk\" derive from it",
    "Language from which \"hubbub\" comes",
    "Scottish Gaelic",
    "Language from which \"spunk\" is derived",
    "Relative of Manx",
    "Gaelic tongue",
    "Scots Gaelic",
    "Gaelic tongue",
    "European tongue",
    "Relative of Manx",
    "Highland tongue",
    "Celtic tongue",
    "Gaelic",
    "Manx relative",
    "European tongue",
    "Gaelic tongue",
    "European tongue",
    "Highlands Gaelic",
    "Manx relative",
    "Celtic language",
    "Gaelic tongue",
    "Highlands tongue",
    "Celtic dialect",
    "Gaelic tongue",
    "Gaelic language",
    "It's spoken in Stornoway",
    "Gaelic",
    "European tongue",
    "Gaelic",
    "Hebrides tongue",
    "European tongue",
    "It's heard in the Highlands",
    "Gaelic tongue",
    "Highlands tongue",
    "It's spoken in Stornoway",
    "Gaelic tongue",
    "Highlands tongue",
    "Language from which \"galore\" comes",
    "European tongue",
    "Language of the British Isles",
    "Gaelic tongue",
    "Gaelic",
    "Gaelic",
    "European language",
    "Scots Gaelic",
    "Irish relative",
    "Gaelic tongue",
    "Goidelic tongue",
    "Gaelic tongue",
    "Scottish Gaelic",
    "Scottish Gaelic",
    "Skye writing",
    "Gaelic",
    "Highlander's tongue",
    "Gaelic",
    "Talk of the Gaelic",
    "Manx tongue",
    "Gael's tongue",
    "Celtic tongue",
    "Limerick language",
    "Irish Gaelic",
    "Old World language",
    "European language",
    "Old World language",
    "Highland tongue",
    "European tongue",
    "Gaelic",
    "Highland dialect",
    "Scots Gaelic",
    "Gaelic",
    "Irish language",
    "Language spoken in Stornoway",
    "Highlands tongue",
    "Hebrides tongue",
    "Highlands tongue",
    "Limerick language",
    "Relative of Manx",
    "Gael's tongue",
    "Irish offshoot",
    "Highland tongue",
    "Irish offshoot",
    "Language that gave us the word \"whisky\"",
    "Hebrides language",
    "Scots tongue",
    "Gaelic",
    "Language spoken in Dingwall",
    "European language",
    "Talk of the Gaelic",
    "Gaelic",
    "Gaelic",
    "Gaelic",
    "Scottish Gaelic",
    "Relative of Manx",
    "Highlands tongue",
    "European language",
    "Gaelic",
    "Highland Gaelic",
    "Relative of Manx",
    "Gaelic",
    "Gaelic",
    "Highlands tongue",
    "Gaelic",
    "Robert the Bruce's tongue",
    "Highland tongue",
    "Celtic tongue",
    "European tongue",
    "Scottish tongue",
    "Hebrides language"
  ],
  "repo": [
    "Take back, for short",
    "Certain bank job",
    "Take back, in a way",
    "Bank takeback, for short",
    "Seized property, for short",
    "Bank job of a sort",
    "Defaulter's loss, informally",
    "Default consequence",
    "Seized vehicle, for short",
    "Takeback of a car, for short",
    "Tow job, maybe",
    "Possible consequence of default, in brief",
    "Possible result of late payments, informally",
    "It might be taken to the pound",
    "Action to collect on a debt",
    "Bank takeback",
    "Defaulter's comeuppance",
    "Certain tow job",
    "Seized car, for short",
    "Certain cheap car, informally",
    "Auctioned car, perhaps",
    "Take back, in a way",
    "Car in a tow lot, perhaps",
    "Defaulter's auto, often",
    "Certain tow job, for short",
    "Golf takeback, maybe",
    "Seized vehicle, informally",
    "Pickup that gets picked up, perhaps",
    "Lost wheels, maybe",
    "Take back, in a way",
    "Vehicle seized for auction",
    "Seizure in a driveway, maybe",
    "Many a deadbeat's car, eventually",
    "Possible result of owing money",
    "Bad recollection?",
    "Seized car, for short",
    "Towed-away auto, maybe",
    "\"___ Man\"",
    "\"___ Man,\" Emilio Estevez film",
    "2010 film \"___ Men\"",
    "Forced return?",
    "Government auction action",
    "Tow job, maybe",
    "Bank take-back",
    "\"___ Man\" (Emilio Estevez film)",
    "It happens by default",
    "Take back",
    "Seized vehicle",
    "Unwanted collection",
    "Takeback, briefly",
    "Property after a default, for short",
    "Defaulter's loss, informally",
    "\"___ Man\"",
    "Seized property, informally",
    "Seized vehicle",
    "Certain seizure",
    "Credit report tarnisher, briefly",
    "Seized vehicle",
    "Auctioned property, maybe",
    "Seized vehicle",
    "Credit report damager, briefly",
    "Defaulter's loss",
    "Defaulter's loss",
    "Seized vehicle",
    "Defaulter's concern",
    "Offering at a government auction",
    "\"___ Man,\" Emilio Estevez movie",
    "\"___ Man\" (1980's cult film)",
    "Tow job",
    "Bank take-back, briefly",
    "Defaulter's loss",
    "Car making a return trip?",
    "Used auto, perhaps",
    "Towed vehicle, maybe",
    "Defaulter's comeuppance",
    "Certain preowned vehicle",
    "Bank take-back",
    "Seized vehicle",
    "Auctioned auto, often",
    "Bank take-back",
    "Unpaid loan result, sometimes",
    "Defaulter's comeuppance",
    "Hauled-away car, maybe",
    "Possible result of 40-Down nonpayment",
    "Possible result of nonpayment",
    "Seized vehicle",
    "Tow truck hook-up, maybe",
    "Auction vehicle, often",
    "\"___ Man\" (1984 movie)",
    "Tow job",
    "Short collection?",
    "Bank action, briefly",
    "Certain resale item, informally",
    "Defaulter's loss",
    "Seized vehicle",
    "\"___ Man\" (Estevez film)",
    "Bank take-back",
    "Action after a default",
    "Bank takeback",
    "Certain securities deal",
    "Debtor's woe",
    "Defaulter's worry",
    "Bank take-back",
    "Property taken back",
    "1984 film \"___ Man\"",
    "\"___ Man\" (1984 film)",
    "Nonpayment result",
    "Cult film \"___Man\"",
    "Many a used car",
    "Cult film \"___ Man\"",
    "?ô___ Man?ö (1984 film)",
    "\"___ Man\" (Estevez film)",
    "Cult film \"___ Men\"",
    "\"___ Man\" (1984 flick)",
    "Bank take-back",
    "Certain investor's agreement, for short",
    "Estevez film \"___ Man\"",
    "\"___ Man\" (Estevez flick)"
  ],
  "lair": [
    "Retreat",
    "Bear's retreat",
    "Den",
    "Thieves' place",
    "Hideout",
    "Den",
    "Villain's retreat",
    "Where a supervillain schemes",
    "Villain's hideout",
    "Retreat",
    "Retreat",
    "Burrow",
    "Villain's hangout",
    "Den",
    "The Lonely Mountain, for Smaug",
    "Place to hole up",
    "Hideout",
    "Home in the woods",
    "Hideout",
    "Den",
    "Burrow",
    "Den",
    "Den",
    "Den",
    "Plotter's place",
    "Burrow, perhaps",
    "Thieves' place",
    "Den",
    "Batcave, e.g.",
    "Where a lion hides",
    "Pirates' hangout",
    "Retreat",
    "Hideout",
    "Retreat",
    "Spot of seclusion",
    "Cubs' place",
    "Lion's home",
    "Home in the woods",
    "Place to hibernate",
    "Lion's den",
    "Hideout",
    "Refuge",
    "Wilderness home",
    "Hideout",
    "Dragon's ___ (early video game)",
    "Dragon's ___ (early video game)",
    "Cub's place",
    "Place to hole up",
    "Hollow, perhaps",
    "Den",
    "Hidey-hole",
    "Hideout",
    "Retreat",
    "Place to hide",
    "Den",
    "Burrow",
    "Bandit's refuge",
    "Nest",
    "Hideaway",
    "Refuge",
    "Den",
    "Den",
    "Retreat",
    "Den",
    "Den",
    "Den",
    "Den",
    "Inner sanctum",
    "Hideaway",
    "Where cubs are raised",
    "Hideaway",
    "Den",
    "Den",
    "Hidden spot",
    "Pride's place?",
    "Cub's hangout",
    "Den",
    "Retreat",
    "Retreat",
    "Place to hole up",
    "Den",
    "Place to hibernate",
    "Lion's den",
    "Resting place",
    "Animal house?",
    "Animal house",
    "Den",
    "Animal shelter",
    "Retreat",
    "Retreat",
    "Retreat",
    "Hideaway",
    "Cougar's retreat",
    "Lion's den",
    "Animal house",
    "Animal shelter",
    "Lion's home",
    "Hideaway",
    "Hideout",
    "House of Leo?",
    "Hideout",
    "Place to hibernate",
    "Burrow",
    "Retreat",
    "Beastly home",
    "Hideout",
    "Hideaway",
    "Covert",
    "Hideout",
    "Burrow",
    "Hideaway",
    "Hideout",
    "Den",
    "Cozy hollow",
    "Den",
    "Covert",
    "Snuggery",
    "Thieves' hideout",
    "Lion's den"
  ],
  "arat": [
    "\"Was it ___ I saw?\" (classic palindrome)",
    "Smell ___ (sense something fishy)",
    "\"How now!\" follower in \"Hamlet\"",
    "\"How now! ___?\": Hamlet",
    "Poor as ___ (destitute)",
    "\"How now! ___?\": Hamlet",
    "Trapped like ___",
    "\"I smell ___!\"",
    "\"I smell ___\"",
    "\"Was it ___ I saw?\" (cat's palindrome)",
    "Smell ___ (be suspicious)",
    "\"I smell ___\"",
    "\"I smell ___\"",
    "\"Was it ___ I saw?\" (classic palindrome)",
    "\"Was it ___ I saw?\" (classic palindrome)",
    "Smell ___ (be suspicious)",
    "Shakespearean question after \"How now!\"",
    "Smell ___",
    "\"Bond Smells ___\" (\"Diamonds Are Forever\" soundtrack number)",
    "\"I smell ___!\"",
    "\"I smell ___!\"",
    "\"I smell ___\"",
    "Smell ___ (be leery)",
    "\"I smell ___!\"",
    "Lilian Jackson Braun's \"The Cat Who Smelled ___\"",
    "What Hamlet called Polonius",
    "Trapped like ___",
    "\"I smell ___!\"",
    "\"I smell ___!\"",
    "\"I smell ___\"",
    "Smell ___",
    "\"Smelling ___\" (Mike Leigh play)",
    "\"I smell ___\"",
    "Smell ___",
    "\"I smell ___!\"",
    "Smell ___ (be wary)",
    "Smell ___ (sense wrong)",
    "\"How now! ___?\": Hamlet",
    "\"I smell ___!\"",
    "Like ___ in a trap",
    "Smell ___",
    "\"I smell ___!\"",
    "Smell ___",
    "\"I smell___!\"",
    "Smell___",
    "Smell ___ (detect wrongdoing)",
    "Trapped like ___",
    "What the suspicious smell",
    "\"I smell ___\"",
    "Smell ___ (be suspicious)"
  ],
  "achy": [
    "Feeling it after a marathon, say",
    "Feeling tender",
    "Sore, as after a workout",
    "Like legs in the days after a marathon",
    "Needing some kneading, say",
    "Sore",
    "Feeling fluish, in a way",
    "Fluish, perhaps",
    "Sore",
    "Sore, as 56-Across",
    "Like many flu sufferers",
    "Sore",
    "Sore",
    "Sore, as from overexercise",
    "Acting up, in a way",
    "Hurting",
    "Sore all over",
    "Bruised, say",
    "Hurting",
    "Hurting",
    "Sore all over",
    "Sore",
    "Hurting all over",
    "Sore",
    "Hurting",
    "Stiff and sore",
    "Acting up",
    "Like flu victims",
    "\"___ Breaky Heart\" (1992 hit)",
    "Sore",
    "Coming down with something, maybe",
    "Fluish, perhaps",
    "Sore",
    "Stiff in the joints",
    "Stiff and sore",
    "Sore",
    "Sore",
    "Sore",
    "Hurting",
    "Hurting",
    "Stiff in the joints",
    "Having pains",
    "\"___ Breaky Heart\"",
    "\"___ Breaky Heart\"",
    "Bruised"
  ],
  "view": [
    "Perspective",
    "Penthouse perk",
    "Perspective",
    "Unit for a YouTube video",
    "What an essay presents",
    "Panorama, e.g.",
    "Scene",
    "ABC show on weekday mornings, with \"The\"",
    "Perspective",
    "Penthouse perk",
    "Panorama",
    "Asset of an oceanfront home",
    "Way of thinking",
    "Menu with zoom options",
    "Stance",
    "Outlook",
    "Penthouse perk",
    "It's afforded by a scenic overlook",
    "Feature of a house in the hills",
    "Op-ed's offering",
    "Penthouse feature",
    "*Panama",
    "Penthouse asset",
    "Tower-top attraction",
    "Panorama",
    "Pleasant hotel room feature",
    "Vista",
    "Penthouse feature",
    "Perspective",
    "Desirable hotel room feature",
    "Penthouse feature",
    "Scene",
    "Perspective",
    "Prospect",
    "Vista",
    "Panorama",
    "Opinion"
  ],
  "beau": [
    "Steady, maybe",
    "Sweetheart",
    "Ken, to Barbie",
    "Boyfriend",
    "One who goes a-courting",
    "Lover boy",
    "Lover boy",
    "One looking for a match?",
    "Suitor",
    "Lover",
    "Swain",
    "Lover boy",
    "Fellow bringing roses, perhaps",
    "Boyfriend",
    "Boy with a bouquet",
    "Buyer of a dozen roses, maybe",
    "Steady guy",
    "Sender of a billet-doux",
    "Lover boy",
    "Ken, to Barbie",
    "Serenader, maybe",
    "Belle's caller",
    "Bridges of note",
    "Certain squeeze",
    "Steady guy",
    "Sweetheart",
    "Boyfriend",
    "Steady guy",
    "Ball boy?",
    "Steady",
    "Lover boy",
    "Belle's gent",
    "Boyfriend",
    "Steady, say",
    "Steady, perhaps",
    "Steady",
    "Sweetheart",
    "Bringer of wine and flowers",
    "Swain",
    "Brummell or Bridges",
    "Steady",
    "Boyfriend",
    "Date to remember?",
    "Sweetheart",
    "Lady's man",
    "Admirer",
    "Loverboy",
    "Wooer",
    "Steady",
    "Squire",
    "Lady's man",
    "Belle's man",
    "Bridges of Hollywood",
    "Romeo",
    "___ ideal (perfect model)",
    "Sweetie",
    "Bridges of Hollywood",
    "Boyfriend",
    "Gallant"
  ],
  "golf": [
    "Letter between foxtrot and hotel in the NATO alphabet",
    "Driving test, of a sort",
    "Sport that returned to the Summer Olympics in 2016 after a 112-year absence",
    "Club game",
    "See 29-Down",
    "Play a round",
    "\"An expensive way of playing marbles,\" per G. K. Chesterton",
    "Stay on course?",
    "Sport that's been called \"a good walk spoiled\"",
    "Green activity",
    "It's full of holes and traps",
    "Sport with woods and Woods",
    "Play Pebble Beach, say",
    "Driving passion?",
    "Miniature ___",
    "Make the rounds?",
    "Play a round",
    "\"A good walk spoiled,\" said Twain",
    "Game on a green",
    "The Masters game",
    "Pro-am game",
    "Game on the greens",
    "Course game"
  ],
  "idly": [
    "How people often scroll through social media",
    "Without much thought",
    "Without aim",
    "How thumbs are twiddled",
    "Without purpose",
    "One way to stand by",
    "How doodles are generally drawn",
    "Without really thinking",
    "Without purpose",
    "Without purpose",
    "Without forethought",
    "One way to sit by",
    "One way to stand by",
    "Without much effort",
    "One way to sit by",
    "How doodles are drawn",
    "Without thinking",
    "Sit ___ by",
    "How doodles are usually drawn",
    "How thumbs are twiddled",
    "Without aim",
    "Without much thought",
    "Without purpose",
    "Way to stand by",
    "With no apparent purpose",
    "Without thinking",
    "One way to sit",
    "Without doing anything",
    "Way to stand by",
    "Without much thought",
    "Without purpose",
    "Frivolously",
    "Lazily",
    "One way to stand by",
    "Sans purpose",
    "In a lazy way",
    "Without purpose",
    "Without serious thought",
    "One way to stand by",
    "Without purpose",
    "Without thought",
    "Sans purpose",
    "Without thinking",
    "Casually",
    "Without effort",
    "Without urgency",
    "Without forethought",
    "Without much thought"
  ],
  "here": [
    "Word that becomes its own opposite by putting a \"T\" at the front",
    "See 66-Down",
    "Reply in a roll call",
    "\"Take this!\"",
    "On earth",
    "\"___, boy!\"",
    "\"___ we go again ...\"",
    "\"Take this ...\"",
    "\"Take one\"",
    "\"Take one\"",
    "\"Take it!\"",
    "Not yonder",
    "\"___ goes nothin'!\"",
    "\"___, boy!\"",
    "\"Now see ___!\"",
    "\"___ comes trouble!\"",
    "\"Take this\"",
    "\"___, boy\"",
    "Roll call response",
    "\"___, boy!\"",
    "\"Present\"",
    "\"___, boy!\"",
    "\"___ goes nothing!\"",
    "\"This is yours\"",
    "\"Is this the spot?\"",
    "\"___, boy!\" (cry to Rover)",
    "\"Is this the spot?\"",
    "\"___, boy!\"",
    "\"Take it!\"",
    "On hand",
    "\"All yours!\"",
    "\"Catch!\"",
    "Homeroom response",
    "\"Take this\"",
    "\"Just take it\"",
    "In no other place",
    "\"___ goes!\"",
    "Call to a dog",
    "Shout from one who's on a roll?",
    "\"___ goes!\"",
    "Roll-call call",
    "\"Catch!\"",
    "\"You can have this\"",
    "\"Take this!\"",
    "\"Take one\"",
    "Possible response to name-calling?",
    "Roll call response",
    "Call to Rover",
    "\"Take this!\"",
    "\"Kilroy was ___\"",
    "Roll-call call",
    "\"Look ___ ?à\"",
    "Word turned into its own opposite by putting a T in front",
    "Hither",
    "\"Take one\"",
    "\"___ goes\"",
    "___ and now",
    "At this point",
    "Word said while raising one's hand",
    "\"Take one!\"",
    "?ôLook ___!?ö",
    "\"Take this!\"",
    "Repeated call to a dog",
    "\"Take this!\"",
    "\"Catch!\"",
    "This spot",
    "\"From ___ to Eternity\"",
    "On earth",
    "\"Catch!\"",
    "Epitaph opener",
    "Epitaph starter",
    "On earth",
    "Call to Fido",
    "Roll call response",
    "\"___ goes!\"",
    "Call to Fido",
    "\"Take one!\"",
    "\"Try this!\"",
    "Epitaph starter",
    "Epitaph opener",
    "Now's partner",
    "Present",
    "\"Take one!\"",
    "Present",
    "Not missing, in a way",
    "Roll call response",
    "\"___,\" said Tom presently",
    "See 67-Across",
    "Accounted for, in a way",
    "Roll call response",
    "\"Take this!\"",
    "\"Help yourself\"",
    "\"Grab ahold!\"",
    "Roll call reply",
    "Epitaph starter",
    "Roll call call",
    "Partner of now",
    "\"Take this!\"",
    "Roll call reply",
    "Epitaph beginning",
    "\"Take this!\"",
    "\"Take this!\"",
    "At this point",
    "In this place",
    "Roll call response",
    "\"Present\"",
    "In this place",
    "Roll call word",
    "Opposite of \"absent\"",
    "Present",
    "\"Present\"",
    "Present",
    "\"Catch!\"",
    "Roll call answer",
    "Roll-call response",
    "In the spot",
    "\"Take this\"",
    "Roll-call yell",
    "\"You are ___\"",
    "Now's partner",
    "\"___ goes!\"",
    "Now's partner",
    "\"You are ___\"",
    "Now partner"
  ],
  "amie": [
    "Companion in Brittany",
    "Many a demoiselle d'honneur",
    "Grenoble gal pal",
    "Friend in France",
    "German : Freundin :: French : ___",
    "Haydee, to the Count of Monte Cristo, par exemple",
    "Female friend, in France",
    "Female friend from France",
    "Opposite of une adversaire",
    "Parisian pal",
    "One who might become a fiancee",
    "Friend for Francoise",
    "Friendly femme",
    "Guy's gal",
    "Brest friend",
    "French female friend",
    "Beau's girl",
    "French girlfriend",
    "Female friend in France",
    "Many a fete d'anniversaire attendee",
    "Friend to a Frenchman",
    "Catherine, to Jules et Jim",
    "Female friend of Francois",
    "Gallic gal pal",
    "Lady friend, in Lille",
    "Female friend for Francois",
    "French female friend",
    "Female pen pal, maybe",
    "Possible subject of a French scandal",
    "Gallic girlfriend",
    "French lady friend",
    "French female friend",
    "French date, often",
    "French girlfriend",
    "Gallic girlfriend",
    "French girlfriend",
    "Mon ___",
    "Many a fete d'anniversaire attendee",
    "French girlfriend",
    "French girlfriend",
    "Friend en francais",
    "Francoise, to Francois, maybe",
    "Man's Brest friend?",
    "Parisian girlfriend",
    "Gallic girlfriend",
    "French girlfriend",
    "Date for Denis",
    "Beau's girl",
    "French girlfriend",
    "Familiar femme",
    "Friend of Fifi",
    "Guy's girl",
    "French female friend",
    "Gallic gal pal",
    "French girlfriend",
    "Date in France?",
    "French girlfriend",
    "Confidante, say",
    "Savoie sweetheart",
    "French girlfriend",
    "Billet-doux writer",
    "French lady friend",
    "Le Havre honey",
    "Femme friend",
    "French girlfriend",
    "Gerard's girlfriend",
    "Parisian girlfriend",
    "Billet-doux recipient",
    "Friend who's francaise",
    "Cherie",
    "Gallic girlfriend",
    "Pen pal in Paris, perhaps",
    "Gallic girlfriend",
    "Gallic girlfriend",
    "Billet-doux writer",
    "Gaston's girlfriend",
    "French girlfriend",
    "French girlfriend",
    "Valentine for Valery",
    "Foreign friend",
    "Parisian girlfriend",
    "Caen confidante",
    "Valery's valentine",
    "Gallic girlfriend",
    "Guy's girl",
    "French gal pal",
    "Friendly femme",
    "Friend of Nancy",
    "Valentine for Valery",
    "Jeanne to Jean, peut-etre",
    "Gallic girlfriend",
    "French companion",
    "Jacques's steady",
    "Provencal pal",
    "Billet-doux writer",
    "Gerard's girlfriend",
    "French pal",
    "Gaston's girl",
    "Foreign pen pal",
    "18-Across confidante",
    "Friendly femme",
    "Brest friend",
    "French friend",
    "Gallic girlfriend",
    "French girlfriend",
    "French girlfriend",
    "Friend of Francoise",
    "Honey, in Le Havre",
    "French pen pal, maybe",
    "Sedan sweetie",
    "Troubadour's subject, perhaps",
    "Female friend of Francois",
    "Female friend, in France",
    "Gallic girlfriend",
    "French friend",
    "Grenoble girlfriend",
    "Simone de Beauvoir, to Sartre",
    "Gallic girlfriend",
    "Alain's girlfriend",
    "Friend, to Francoise",
    "Friend of Francois",
    "Gallic girlfriend",
    "Cherie, e.g.",
    "Gallic girlfriend",
    "Flandre friend",
    "Paris pal",
    "Parisian lady friend",
    "French friend",
    "Friend en francais",
    "She's a sweetie in Tahiti",
    "Friend to Colette",
    "Foreign friend",
    "Gallic girlfriend",
    "Friend abroad",
    "Friend of the famille",
    "Gallic girlfriend",
    "Feminine friend",
    "Girlfriend abroad",
    "Suisse sweetheart",
    "Gallic girlfriend",
    "She might get a billet-doux",
    "Gallic girlfriend",
    "Friend of Francois",
    "French friend",
    "Pierre's date",
    "Sweetheart, in Savoie",
    "Henri's squeeze",
    "Chere woman"
  ],
  "eegs": [
    "Brain diagnostics, for short",
    "Brain wave readers, for short",
    "Brain tests, in brief",
    "Brain readings, for short",
    "Head lines, briefly?",
    "Dr.'s orders",
    "Thought patterns, in brief?",
    "Some hosp. tests",
    "Seizure sensors, for short",
    "Neurosurgeons' readouts, for short",
    "Brain tests, for short",
    "Brain readings, for short",
    "Hosp. procedures",
    "Head lines, for short?",
    "Pieces of one's mind?: Abbr.",
    "Hosp. printouts",
    "Lines of thought, for short?",
    "Mind readers?: Abbr.",
    "Some wave catchers: Abbr.",
    "Hosp. readings",
    "Lines of thought, for short?",
    "Hosp. charts",
    "Hosp. readouts",
    "Brain tests, briefly",
    "Hosp. tests",
    "Brain tests: Abbr.",
    "Brain scans, for short",
    "Brain test results, for short",
    "Hosp. printouts",
    "Brain tests, for short",
    "Some tests, for short",
    "Brain tests, for short"
  ],
  "vein": [
    "Blood line",
    "Target for a phlebotomist",
    "Line on a leaf",
    "Miner's find",
    "\"In that ___ ...\"",
    "Artery's counterpart",
    "Course through the body?",
    "Red Cross hot line?",
    "Thin blue line?",
    "Mark in marble",
    "Way to a man's heart?",
    "Blood line",
    "Target for Dracula",
    "Manner",
    "Shot's target, maybe",
    "Blood line",
    "Marble feature",
    "Part of an internal network",
    "Way to one's heart",
    "Thin blue line, say",
    "Rib of a leaf",
    "Ore site",
    "Coal site",
    "Style",
    "Phlebotomy target",
    "Manner, as of writing",
    "Blood carrier",
    "Marble marking",
    "Blue vessel",
    "Gold source"
  ],
  "lobe": [
    "Temporal ___",
    "Part of an ear or brain",
    "Where a stud might go",
    "Part of a brain or a 59-Down",
    "Ring site",
    "Place for a stud",
    "Place for a piercing",
    "Brain part",
    "Piercing place",
    "Brain or ear part",
    "Piercing spot",
    "Temporal ___",
    "Rounded projection",
    "Brain part",
    "Brain section",
    "Ear part",
    "Gray area?",
    "Ear part",
    "Ear part",
    "Bottom of a 40-Down",
    "Ring site",
    "Brain part",
    "Leaf feature",
    "Ear or leaf part",
    "Ear or leaf feature",
    "Ear part",
    "Stud site",
    "Earring site",
    "Brain section",
    "Ear part",
    "Stud site",
    "Ear part",
    "Leaf feature",
    "Brain area",
    "Stud site",
    "Ear piece",
    "Brain section",
    "Rounded part",
    "Place for an earring",
    "Ring setting?",
    "Leaf part",
    "Stud site",
    "Stud site",
    "Piercing place",
    "Brain area",
    "Ear part",
    "Earlap",
    "Ear dangler",
    "Ear part",
    "Earring site",
    "Leaf projection",
    "Ear part",
    "Earring locale",
    "Ring site",
    "Ear part",
    "Leaf part",
    "Part of a leaf, perhaps",
    "Earring site",
    "Hoop's locale, perhaps",
    "Section of the brain",
    "Earring locale",
    "Earring site"
  ],
  "sigh": [
    "[Here we go again ...]",
    "Wistful sound",
    "Dramatic exhalation",
    "Pine (for)",
    "[Oh, well]",
    "Eye roll accompanier, often",
    "[Ah, me!]",
    "[Things seemed to be going so well]",
    "[Ah, me]",
    "[That's so ... sad]",
    "[Ugh, here we go again]",
    "It may accompany an eye roll",
    "[Alas]",
    "[Ask me what's wrong]",
    "Heaved \"ho\"?",
    "[Oh, well]",
    "[Ah, me]",
    "[Ah, me]",
    "[Not that again]",
    "___ of relief",
    "Weary reaction",
    "Sound of relief",
    "Indication of longing",
    "Dramatic exhalation",
    "Sound of resignation",
    "Sound of relief",
    "Nostalgic person's response",
    4615,
    "[Isn't he dreamy?!]",
    ":-<, in a chat room",
    "Nostalgic person's utterance",
    "One may be heaved",
    "[Ah, me]",
    "Sound of relief",
    "['Tis a pity!]",
    "Pine (for)",
    "Charlie Brown utterance",
    "It might evoke pity",
    "Sound of relief",
    "Audibly lament",
    "[Oh, well]",
    "Salman Rushdie's \"The Moor's Last ___\"",
    "Lovelorn's utterance",
    "Something to heave",
    "Suspire",
    "Word from the lovelorn",
    "Sound frustrated",
    "Sound exasperated",
    "Relieved sound"
  ],
  "rugs": [
    "Afghans, e.g.",
    "Challenges for Roombas",
    "Persians, e.g.",
    "Some throws",
    "Toupees, in slang",
    "Persians, e.g.",
    "Bearskins, maybe",
    "Persians, e.g.",
    "Kirmans, e.g.",
    "Places for naps?",
    "Toupees, slangily",
    "Persians, e.g.",
    "Toupees, slangily",
    "Hairpieces, slangily",
    "Hairpieces",
    "Bearskins, maybe",
    "Area covers",
    "Carpets",
    "Items that are piled",
    "Toupees"
  ],
  "eres": [
    "\"De dónde ___?\" (Spanish 101 query)",
    "You are, in Spain",
    "\"___ Tu\" (1974 song)",
    "You are, south of the border",
    "Part of the conjugation of the Spanish \"to be\"",
    "You are, in Spain",
    "You are, in Yucatan",
    "You are, in espanol",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu,\" 1974 pop hit",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "\"Tu ___ mi amor\"",
    "You are: Sp.",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "You are, in Yucatan",
    "\"___ Tu\" (1974 hit)",
    "You are: Sp.",
    "\"___ Tu\" (1974 hit)",
    "You are, in Aragon",
    "You are: Sp.",
    "\"___ Tu\" (1974 hit)",
    "Start of a Cockney toast",
    "Part of a Spanish 101 conjugation",
    "\"You are\" in Spain",
    "Start of a Cockney toast",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "Start of a Cockney toast",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 song)",
    "\"___ to you!\" (Cockney toast)",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "\"So ___ to you, Fuzzy-Wuzzy\": Kipling",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (1974 hit)",
    "\"___ Tu\" (70's hit)",
    "\"___ Tu\" (1974 hit)",
    "1974 hit \"___Tu\"",
    "\"___Tu\" ('74 hit)",
    "Start of a cockney toast"
  ],
  "ussr": [
    "It raised a major red flag",
    "Founding member of the U.N. Security Council",
    "Name in a noted '90s breakup",
    "Red state, once, for short",
    "Geographical inits. until 1991",
    "Red states, once",
    "Founding member of the U.N. Security Council, for short",
    "Georgia was once a part of it",
    "The Beatles' \"Back in the ___\"",
    "Red letters?",
    "State led by Lenin, in brief",
    "Collapsed red giant?",
    "Land in a Beatles song",
    "1922-91 initials",
    "American ally in W.W. II",
    "Country in a Beatles title",
    "Red letters?",
    "America's Cold War foe, for short",
    "Space race competitor, for short",
    "Country in a classic Beatles title",
    "1991 breakup newsmaker",
    "One 29-Across of the U.S. in W.W. II",
    "Lenin's land, for short",
    "Gorbachev's land, for short",
    "Gorbachev's land",
    "Lenin's land, for short",
    "Boycotter of the '84 L.A. Olympics",
    "Khrushchev's land, for short",
    "Initialism in a Beatles title",
    "Group of red states, for short?",
    "Red giant that disintegrated?",
    "Miracle on Ice loser of '80",
    "Anti-Ballistic Missile Treaty signatory, briefly",
    "Stalin's land, in brief",
    "\"Back in the ___\"",
    "Apt anagram of \"Russ.\"",
    "Reagan's \"evil empire,\" for short",
    "Locale in a Beatles title",
    "Old NATO target",
    "Red letters?",
    "Red state, once",
    "1-Down's land",
    "Cold war inits.",
    "SALT party",
    "Treaty signer with Ger. in 1939",
    "The Beatles' \"Back in the ___\"",
    "\"Evil empire\" initials",
    "Red giant, once?",
    "Amer. ally in W.W. II",
    "Country with a hammer-and-sickle flag, for short",
    "Country that disappeared in '91",
    "Erstwhile empire, in brief",
    "BOAC destination, in song",
    "\"Back in the ___\"",
    "Lenin's land, for short",
    "Locale in a 1968 Beatles song",
    "\"Back in the ___\"",
    "It disappeared on Dec. 26, 1991",
    "CCCP, in English",
    "Former part of 11-Down: Abbr.",
    "\"Back in the ___\"",
    "56-Across's cold war foe",
    "See 4-Down",
    "Cold war foe, for short",
    "Mir launcher",
    "Soyuz launcher",
    "Reagan's \"evil empire\"",
    "Cold war inits.",
    "Stalingrad's land, for short",
    "Cold war superpower",
    "\"Evil empire\" of the '80s",
    "The Beatles' \"Back in the ___\"",
    "Five-Year Plan implementer, for short",
    "U.S. ally in W.W. II",
    "Yalta conf. locale",
    "SALT I signer",
    "U.N. member through 1991",
    "\"Red\" letters",
    "Brezhnev's land, in brief",
    "Gorbachev was its last leader: Abbr.",
    "Lenin's land, for short",
    "15-Down rival, once",
    "Former empire inits.",
    "Cold war initials",
    "SALT party",
    "Cold war inits.",
    "Red letters?",
    "Gorbachev was its last leader: Abbr.",
    "\"Back in the ___\"",
    "Locale in a Beatles song",
    "W.W. II ally",
    "Cold war inits.",
    "\"Back in the ___\"",
    "Where Kalinin was pres.",
    "Country founded in 1922: Abbr.",
    "Moscow's land, once: Abbr.",
    "Former empire",
    "Former \"evil empire\"",
    "Cold war foe",
    "Cold war initials",
    "SALT signatory",
    "Khrushchev's concern",
    "Boycotter of the '84 L.A. Olympics",
    "Warsaw Pact land",
    "Russia was part of it: Abbr.",
    "Brezhnev's land",
    "Cold war inits.",
    "The Beatles' \"Back in the ___\"",
    "Subj. of a 1991 breakup",
    "The Beatles' \"Back in the ___\"",
    "Former world power, for short",
    "Atlas abbr. before 1991",
    "It broke up in Dec. 1991",
    "One of the Allies of W.W. II",
    "Stalin's domain",
    "Red letters?",
    "Former G.D.R. ally",
    "Pre-1991 atlas abbr.",
    "Soyuz launcher",
    "1980 Olympics host",
    "Once-mighty initials",
    "90-Down's land",
    "Stalin's realm",
    "Bear country?",
    "C.I.S. predecessor",
    "C.I.S. predecessor",
    "The Beatles' \"Back in the ___\"",
    "Brezhnev's land",
    "Stalin's land",
    "Red letters",
    "Country in a 1969 Beatles song",
    "Lenin's land: Abbr.",
    "Red letters",
    "SALT participant",
    "Space race participant",
    "Ex-superpower",
    "Former empire",
    "1980 Olympics host",
    "Locale in a Beatles song",
    "Stalin ruled it"
  ],
  "into": [
    "See 6-Down",
    "A fan of",
    "Loving",
    "Division word",
    "Really feeling",
    "Nuts about",
    "Keen on",
    "Keen on",
    "Take ___ account",
    "\"What's gotten ___ you?\"",
    "Having as a hobby",
    "A fan of",
    "Excited about",
    "Passionate about",
    "Hooked on",
    "Excited about",
    "Mad about",
    "Loving",
    "Avid about",
    "Feeling",
    "\"What's gotten ___ you?\"",
    "Sondheim's \"___ the Woods\"",
    "\"What's gotten ___ you?\"",
    "Digging",
    "Digging",
    "See 41-Across",
    "Fascinated by",
    "Keen on",
    "See 13-Across",
    "Wild about",
    "___ pieces",
    "Wild about",
    "Really digging",
    "Word after bump or break",
    "Really liking",
    "Word after back or break",
    "\"What's gotten ___ you?\"",
    "Infatuated with",
    "Digging",
    "Loving",
    "Keen on",
    "Digging, so to speak",
    "See 18-Down",
    "Van Morrison song \"___ the Mystic\"",
    "Digging",
    "Digging ... or word after \"digging\"",
    "Krakauer's \"___ the Wild\"",
    "Call ___ question",
    "Hooked on, say",
    "Digging",
    "See 53-Down",
    "Fascinated by",
    "Really liking",
    "Word after bang, break or bump",
    "Loving",
    "Hooked on",
    "Wild about",
    "Keen on",
    "Fascinated by",
    "Digging",
    "Call ___ question",
    "Sondheim's \"___ the Woods\"",
    "Take ___ account",
    "Digging",
    "Crazy about",
    "Enthusiastic about",
    "Preoccupied with",
    "Get ___ the habit",
    "Absorbed by",
    "See 44-Across",
    "Having as a hobby",
    "Concerned with",
    "Grooving on",
    "Fascinated by",
    "Diggin'",
    "Obsessed with",
    "Preoccupied with",
    "Really digging",
    "Really liking, informally",
    "Put ___ words",
    "\"___ Thin Air\" (1997 best seller)",
    "Look ___ (study)",
    "Wild about",
    "Fascinated by",
    "Plow ___",
    "Mad about",
    "Keen on",
    "Gung-ho about",
    "Digging",
    "Division preposition",
    "Wrapped up with",
    "Enjoying",
    "Grooving on",
    "Concerned with",
    "Having as a hobby",
    "Get ___ shape",
    "Turned on by",
    "Making a hobby of",
    "Wild about",
    "\"What's gotten ___ you?\"",
    "Keen about",
    "Turned on by",
    "Look ___ (study)",
    "Passionate about",
    "See 58-Down",
    "Studying closely",
    "\"What's gotten ___ you?\"",
    "Digging, so to speak",
    "Fascinated by",
    "A fan of",
    "Enthusiastic about",
    "Engrossed by",
    "Keen about",
    "Get ___ shape",
    "Word after bump or jump",
    "\"What's gotten ___ you?\"",
    "Turned on by",
    "Keen about",
    "Put ___ words",
    "Fascinated by",
    "Excited by",
    "Enthusiastic about",
    "Turned on by",
    "Captivated by",
    "Loving",
    "Captivated by",
    "Obsessed with",
    "Excited about",
    "Division word",
    "Wild about",
    "See 58A",
    "Turned on by",
    "Digging, so to speak",
    "Keen on",
    "\"___ My Heart\" (1930 song)",
    "Grooving on",
    "Absorbed by",
    "Division word",
    "Division word",
    "Division word",
    "Absorbed by",
    "Against",
    "Get ___ (access)",
    "Van Morrison's \"___ the Mystic\"",
    "Eagerly studying",
    "Absorbed by",
    "Go ___ detail",
    "Get ___ trouble",
    "Engrossed by",
    "Gung-ho about",
    "Enjoying, in slang",
    "Digging, so to speak",
    "Gung-ho about",
    "Put ___ question",
    "Fascinated by",
    "See 1-Across",
    "\"___ each life some...\"",
    "\"What's gotten ___ you?\"",
    "Go ___ detail",
    "Absorbed by",
    "\"___ each life...\"",
    "Showing a fancy for",
    "Division word",
    "Division word",
    "Passionate about",
    "Liking",
    "Passionately studying",
    "Fascinated by",
    "Crazy about",
    "Disappear___thin air",
    "Word with look or back",
    "Dividend preceder",
    "Look___ (probe)",
    "\"___the valley of Death...\"",
    "Having as a hobby",
    "Divider's word",
    "Look ___ (study)",
    "Sondheim's \"___ the Woods\"",
    "What's gotten ___ you?",
    "Division word",
    "Wild about",
    "Gung-ho about",
    "Division word",
    "Hooked on",
    "Look ___ (explore)",
    "Absorbed by",
    "\"___ the Night,\" 1985 film",
    "Occupied with",
    "'85. film, \"___ the Night\"",
    "Sondheim's \"___ the Woods\""
  ],
  "lees": [
    "Waste at a winery",
    "Wine dregs",
    "Alternative to Levi's",
    "Levi's alternatives",
    "Alternatives to Levi's",
    "Popular jeans",
    "Bottom of the barrel",
    "Sediment",
    "Alternative to Levi's",
    "Dregs",
    "Refuse at a bar",
    "Some jeans",
    "Some jeans",
    "Wine bottle residue",
    "Some jeans",
    "Remains after the aging process",
    "Vintner's dregs",
    "Sake brewery byproduct",
    "Some jeans",
    "Bottom-of-the-barrel stuff",
    "Cask dregs",
    "Onetime Virginia V.I.P.'s",
    "Levi's alternative",
    "Some jeans",
    "Dregs",
    "Some jeans",
    "Dregs",
    "Some jeans",
    "\"The jeans that built America\"",
    "Vat waste",
    "Remains",
    "Refuse",
    "Some jeans",
    "Performers Peggy and Pinky",
    "Bottom of the barrel",
    "Famous Virginia family",
    "Refuse",
    "Some jeans",
    "Historic Virginia family",
    "Remains",
    "Remains",
    "Popular jeans",
    "Pinky and Spike",
    "Venerable Virginia family",
    "Remains",
    "Historic Virginia family",
    "Dregs",
    "Directors Spike and Ang",
    "Vat waste",
    "Popular jeans",
    "Dregs",
    "Popular jeans",
    "Wranglers alternative",
    "Some jeans",
    "Virginia clan",
    "Bottom of the barrel",
    "Noted Virginia family",
    "Some jeans, informally",
    "Bottom of the barrel",
    "What settles",
    "Wine vat waste",
    "Directors Ang and Spike",
    "Wine sediment",
    "Popular jeans",
    "Popular jeans",
    "Chablis sediment",
    "Wine dregs",
    "Part that doesn't dissolve",
    "Sediment",
    "Ultra Cord jeans",
    "Wine sediment",
    "Refuse",
    "Noted Virginia family",
    "Some popular jeans",
    "Settlings",
    "Wine sediment",
    "Wine sediment",
    "Undrunk portion",
    "Sediment",
    "Dregs",
    "Bottom of the barrel",
    "Bottom of the barrel",
    "Sediment"
  ],
  "luxe": [
    "Sumptuous",
    "Fancy-y-y",
    "Splendor",
    "Opulence",
    "Like a five-star hotel",
    "Sumptuousness",
    "Elegance",
    "Elegance",
    "Poshness",
    "Sumptuous",
    "Posh",
    "Sumptuousness",
    "Posh",
    "Elegance",
    "Elegance",
    "Elegance",
    "Sumptuous",
    "Richness",
    "Poshness",
    "Sumptuousness",
    "Elegance",
    "Opulence",
    "Splendor",
    "Sumptuousness",
    "De ___ hotel",
    "De ___ (opulent): Fr.",
    "Sumptuousness",
    "Opulence",
    "Elegance",
    "Richness",
    "Fineness",
    "Opulence",
    "Elegance"
  ],
  "eves": [
    "They're ahead of their time",
    "Anticipatory times",
    "Times for some vigils",
    "Dark times, informally",
    "Times before the present?",
    "Brinks",
    "Big nights",
    "Nights when kids have a hard time falling asleep",
    "Special holiday periods",
    "After-work times, in classifieds",
    "December 24 and 31, e.g.",
    "Lead-in days",
    "Cusps",
    "Times when you might have trouble sleeping",
    "Times of fitful sleep, maybe",
    "Two big nights in December",
    "Anticipatory days",
    "Typical after-work times, for short",
    "Cusps",
    "Anticipatory times",
    "Nervous nights, maybe",
    "Holiday lead-ins",
    "Night times, in classifieds",
    "Brinks",
    "Times in classifieds",
    "Portentous nights",
    "After-work times, in classifieds",
    "When to call, in some ads",
    "Days before",
    "*Times to call, in ads",
    "Times in want ads",
    "Times to call, in some want ads",
    "Times to revel",
    "12/24 and 12/31",
    "Real estate ad abbr.",
    "Lead-ins to holidays",
    "Nights before holidays",
    "Lead-in periods",
    "\"___ Bayou\" (1997 Samuel L. Jackson film)",
    "Times in classifieds",
    "When to call, in some ads",
    "Times to revel, maybe",
    "Times to party",
    "Preceding nights",
    "Abbr. after some telephone numbers",
    "Anticipatory times",
    "Times of anticipation",
    "December 24 and 31, e.g.",
    "Times before",
    "Temporal brinks",
    "Expectant times",
    "Times in classifieds",
    "Preparatory times",
    "Times to call, in ads",
    "Day followers, in want ads",
    "Lead-in periods",
    "December 24 and 31",
    "After-dark times, in classifieds",
    "Twain's \"___ Diary\"",
    "December 24 and 31",
    "December 24 and 31",
    "Preceding times",
    "Party times",
    "Nights before",
    "Preholiday nights",
    "Just-prior periods",
    "When to call, in some want ads",
    "Party times",
    "Nights before",
    "Days before big events",
    "Times to call, in classifieds",
    "Sunset followers",
    "Preceding periods",
    "12/24 and 12/31",
    "Holiday cusps",
    "Times in classifieds",
    "Holiday times",
    "Preceding nights",
    "Certain celebration times",
    "Holiday beginnings",
    "Eventful times",
    "Nights, in classifieds",
    "Impending times",
    "Holiday nights",
    "Night times"
  ],
  "sloe": [
    "Fruit used to flavor the liqueur patxaran",
    "Sour-tasting fruit",
    "Fruit tree also known as a blackthorn",
    "___ gin fizz",
    "Liqueur flavor",
    "Fruit in some gin",
    "Plant of the genus Prunus",
    "Fruity liqueur base",
    "Cousin of a plum",
    "___-eyed",
    "Liqueur flavor",
    "Sharply sour fruit",
    "Blackthorn fruit",
    "Plum used to flavor gin",
    "___ gin fizz",
    "Tart English jelly fruit",
    "Popular gin flavoring",
    "Liqueur flavor",
    "___-eyed",
    "___ gin fizz",
    "Plum relative",
    "Sharp-tasting fruit",
    "Apricot relative",
    "Allegheny plum, e.g.",
    "Tart fruit",
    "Juicy fruit",
    "Plum look-alike",
    "___ gin fizz",
    "___ gin fizz",
    "Tart plum",
    "Relative of a cherry plum",
    "Liqueur flavor",
    "Astringent fruit",
    "Gin flavoring",
    "Plumlike fruit",
    "___ gin fizz",
    "Plumlike fruit",
    "Gin flavoring",
    "Gin flavorer",
    "___-eyed",
    "Shrub akin to the cherry plum",
    "___ gin fizz",
    "___ gin fizz",
    "Gin flavoring",
    "Astringent fruit",
    "Gin flavoring",
    "Gin flavoring",
    "Fruit used in English jelly",
    "Plumlike fruit",
    "Blackthorn",
    "Gin flavoring",
    "Blackthorn",
    "Gin flavorer",
    "Gin flavoring",
    "___-eyed",
    "Blue-black berrylike fruit",
    "Blackthorn fruit",
    "Gin flavor",
    "Relative of a plum",
    "Plant with dark purple berries",
    "___-eyed",
    "Gin fruit",
    "Fruit used to flavor liqueur",
    "Plumlike fruit",
    "Tart plum",
    "___-eyed",
    "Sour fruit",
    "Dark-skinned fruit",
    "Gin flavorer",
    "Astringent fruit",
    "Wild plum",
    "Blackthorn",
    "Thorny Eurasian shrub",
    "Gin flavoring",
    "Plumlike fruit",
    "___ gin fizz",
    "__ gin fizz",
    "Sour fruit",
    "___ gin fizz",
    "___ gin",
    "___ gin fizz",
    "Wild plum",
    "Member of the rose family",
    "Blackthorn",
    "___-eyed",
    "___-eyed",
    "Gin flavoring",
    "Sour fruit",
    "Blackthorn",
    "Hedge plant with white flowers",
    "___-eyed",
    "Blackthorn fruit",
    "___-eyed",
    "Gin flavor",
    "Sour fruit",
    "___-eyed",
    "___ gin fizz",
    "___-eyed",
    "Gin flavoring",
    "Gin flavor",
    "Tart plum",
    "___ gin fizz",
    "Blackthorn fruit",
    "___-eyed",
    "___ gin fizz",
    "Spiny shrub",
    "Kind of gin",
    "Fruit used for preserves",
    "___-eyed",
    "Wild plum",
    "Shrub of the rose family",
    "___-eyed",
    "Blackthorn fruit",
    "___-eyed",
    "Wild plum",
    "Gin flavor",
    "Dark purple fruit",
    "___eyed",
    "Blackthorn fruit",
    "Liqueur flavor",
    "Gin fizz flavor",
    "Blackthorn fruit",
    "Wild plum",
    "Gin flavoring",
    "Blackthorn",
    "Fizz flavor",
    "Gin fruit",
    "___ eyed",
    "Blackthorn",
    "___ gin fizz",
    "Flowering shrub",
    "Blackthorn fruit",
    "___ gin",
    "___-eyed",
    "___ gin",
    "___ gin",
    "Kind of gin"
  ],
  "wkrp": [
    "1970s-'80s sitcom station",
    "Sitcom station",
    "Cincinnati radio station of bygone TV",
    "Cincinnati sitcom station",
    "1970s-'80s sitcom locale",
    "Radio station on TV",
    "Johnny Fever's station",
    "Les Nessman's station",
    "Les Nessman's station in a 1978-82 sitcom",
    "Cincinnati sitcom station",
    "Dr. Johnny Fever's station, in 1970s-'80s TV",
    "Call letters on 1970s-'80s TV",
    "Johnny Fever's workplace, in 1970s-'80s TV",
    "1978-82 sitcom locale",
    "Sitcom station",
    "Where Venus Flytrap worked, on an old sitcom",
    "Radio station on old TV",
    "1970's-80's sitcom setting",
    "Dr. Johnny Fever's station",
    "Cincinnati letters"
  ],
  "syms": [
    "Sylvia of jazz",
    "Sylvia of jazz",
    "Sylvia of jazz",
    "Sylvia of jazz",
    "Sylvia of jazz",
    "Saloon singer Sylvia",
    "Sylvia who played the Queen Mother in \"The Queen\"",
    "\"I Could Have Danced All Night\" singer, 1956",
    "\"___ by Sinatra\" (1982 collaborative jazz album)",
    "Player of the Queen Mother in \"The Queen,\" 2006",
    "Jazz singer Sylvia",
    "Sylvia of \"The World of Suzie Wong\"",
    "British actress Sylvia",
    "Sylvia ___, whom Sinatra once called the \"world's greatest saloon singer\"",
    "Singer Sylvia",
    "Sylvia of jazz",
    "\"Asylum\" star Sylvia",
    "Cabaret singer Sylvia"
  ],
  "flap": [
    "Kerfuffle",
    "Tent feature",
    "Big to-do",
    "Feature on the back of some pajamas",
    "Epiglottis, for one",
    "Kerfuffle",
    "Envelope part",
    "Kerfuffle",
    "Commotion",
    "Brouhaha",
    "To-do",
    "Envelope part",
    "To-do",
    "Try to get airborne, maybe",
    "Pajamas' rear opening",
    "To-do",
    "What bird wings do",
    "To-do",
    "Big to-do",
    "Scandal",
    "Uproar",
    "To-do",
    "Noisy to-do",
    "Brouhaha",
    "Hoo-ha",
    "What flags do in the wind",
    "To-do",
    "Hoo-ha",
    "Letter opener?",
    "Ruckus",
    "Book jacket part",
    "Envelope part",
    "Brouhaha",
    "Part of an envelope",
    "To-do",
    "Paper airplane part",
    "Envelope part",
    "Scandal",
    "Tizzy",
    "Beat, as wings",
    "Hullabaloo"
  ],
  "sips": [
    "Tries, in a way",
    "Tests one's spirit?",
    "Tries, maybe",
    "Doesn't guzzle",
    "Nurses",
    "Drinks in moderation",
    "Opposite of downs",
    "Nurses",
    "Opposite of downs",
    "Doesn't toss back, say",
    "Nurses at a bar",
    "Doesn't gulp",
    "Some samples",
    "Nurses",
    "Small tastes",
    "Drinks bourbon neat, maybe",
    "Nurses at the bar",
    "Uses a 39-Across",
    "Nurses, at the bar",
    "Small drafts",
    "Tests the water?",
    "Nurses a drink",
    "Tastes",
    "Nurses at a bar",
    "Samples",
    "Doesn't guzzle",
    "Tastes",
    "Drinks daintily",
    "Nurses",
    "Doesn't guzzle",
    "Doesn't guzzle",
    "Has a taste of, as wine",
    "Tastes",
    "Nurses",
    "Doesn't swill",
    "Tentative actions",
    "Nurses",
    "Tastes",
    "Tests the water?",
    "Nurses",
    "Tries the wine",
    "Thimblefuls",
    "Nurses",
    "Tastes",
    "Enjoys at leisure"
  ],
  "mega": [
    "Big, in adspeak",
    "___ Millions",
    "Ginormous",
    "___ Millions",
    "The \"M\" of MHz",
    "Uber alternative?",
    "What can go before watt",
    "___ Millions (multistate lottery)",
    "___ Millions",
    "Big intro?",
    "Prefix with vitamin",
    "Very large, informally",
    "Enormous, informally",
    "___ Millions (multistate lottery)",
    "Big, in ads",
    "The \"M\" of MB",
    "\"Big\" prefix",
    "___ Millions (multistate lottery)",
    "Very, informally",
    "___ Millions (lottery)",
    "Huge: Prefix",
    "Part of MHz",
    "Prefix with bucks",
    "Prefix with star or bucks",
    "Bit or hit lead-in",
    "___ Millions (multistate lottery)",
    "Prefix with phone",
    "Big, big, big: Prefix",
    "Prefix with vitamins",
    "Prefix with dose",
    "Kilo- times 1,000",
    "Prefix with bucks",
    "Prefix with phone",
    "Start of something big?",
    "Start of something big?",
    "Prefix with millions",
    "___ Millions lottery",
    "Start of something big?",
    "Prefix with bytes or bucks",
    "Super: Prefix",
    "Prefix with byte",
    "Start of something big?",
    "Prefix with star or bucks",
    "Prefix with store",
    "Prefix with store",
    "Prefix with bucks",
    "Prefix with bucks or phone",
    "Prefix with dose",
    "Part of MB",
    "Prefix with phone",
    "Prefix with byte or buck",
    "Prefix with phone",
    "Start of something big?",
    "Phone or cycle preceder",
    "Prefix with dose",
    "Phone intro",
    "Prefix with bucks or bytes"
  ],
  "sega": [
    "Total War game company",
    "Genesis creator",
    "Super Monkey Ball company",
    "Company whose most famous product once claimed it \"does what Nintendon't\"",
    "Sonic the Hedgehog company",
    "Sonic the Hedgehog creator",
    "Maker of the old Dreamcast game console",
    "Dreamcast maker of old",
    "Nintendo rival",
    "Video game giant",
    "Forever and a day / Genesis maker",
    "Genesis name",
    "Maker of the game Zaxxon",
    "Company in the book \"Console Wars\"",
    "Brand name derived from the phrase \"Service Games\"",
    "Dreamcast console maker",
    "Sonic the Hedgehog creator",
    "Game company that created Sonic the Hedgehog",
    "Gaming giant",
    "Onetime Nintendo rival",
    "Phantasy Star maker",
    "Its mascot is Sonic the Hedgehog",
    "Arcade giant",
    "Creator of Saturn",
    "[Video games]",
    "Tokyo-based game maker",
    "Maker of Dreamcast games",
    "Big name in video games",
    "Genesis source",
    "Sonic creator",
    "Dreamcast maker",
    "___ Genesis, old game console",
    "Gaming giant",
    "Streets of Rage maker",
    "___ Genesis",
    "Sonic the Hedgehog's company",
    "Zaxxon maker",
    "Knuckles the Echidna's company",
    "Genesis origin?",
    "Genesis maker",
    "Video game manufacturer",
    "Company whose mascot is Sonic the Hedgehog",
    "Big maker of consoles",
    "___ Genesis (old video game console)",
    "Creator of Genesis",
    "Dreamcast game company",
    "Nintendo competitor",
    "Company founded in 1940 as Standard Games",
    "Game Gear company",
    "Sonic the Hedgehog's company",
    "Game company behind Sonic the Hedgehog",
    "Genesis creator",
    "Genesis creator",
    "Nintendo rival",
    "Nintendo rival",
    "Big video game maker",
    "Big name in games",
    "Creator of Genesis",
    "Creator of Genesis",
    "Dreamcast company",
    "Mortal Kombat maker",
    "Dreamcast game maker",
    "Nintendo rival",
    "Genesis maker",
    "\"___ does what Nintendon't\" (old slogan)",
    "Game Gear company",
    "Dreamcast maker",
    "Dreamcast maker",
    "Arcade name",
    "Genesis source",
    "Nintendo competitor",
    "Nintendo rival",
    "Nintendo rival",
    "Nintendo rival",
    "Dreamcast producer",
    "Video game name",
    "Big name in video games",
    "Big video game name",
    "Big name in computer games",
    "Big name in games",
    "Big name in games",
    "Sonic the Hedgehog maker",
    "Big name in video games",
    "Big name in games",
    "Nintendo rival",
    "Electronic game giant",
    "Nintendo rival",
    "Nintendo rival",
    "Nintendo rival"
  ],
  "medi": [
    "Lead-in to care",
    "Prefix on some first-aid products",
    "Start to care?",
    "Lead-in to care",
    "Health plan prefix",
    "Start of the names of some health care plans",
    "Lead-in to care",
    "Central: Prefix",
    "Start to care?"
  ],
  "tout": [
    "Talk up",
    "Hype",
    "Hype",
    "Promote",
    "Promote",
    "Talk up",
    "Push",
    "Push",
    "Push",
    "Praise highly",
    "Ballyhoo",
    "Offerer of hot tips",
    "Ballyhoo",
    "Hype up",
    "Promote",
    "Push",
    "Talk up",
    "Brazenly promote",
    "Praise",
    "Talk up",
    "Recommend highly",
    "Ballyhoo",
    "Promote",
    "Talk up",
    "Ballyhoo",
    "Ballyhoo",
    "Talk up",
    "Plug",
    "Plug",
    "Tip seller",
    "Brag about",
    "Track tipster",
    "Ballyhoo",
    "Track tipster",
    "Praise",
    "Recommend publicly",
    "Race track figure",
    "Praise loudly"
  ],
  "imdb": [
    "Where you might search for a lead?",
    "Online source for film facts, in brief",
    "Website with Oscars recaps",
    "Film site",
    "Website for film buffs",
    "Showbiz reference site",
    "What has casts of thousands?",
    "Online source of film trivia",
    "It gets clicks for flicks",
    "Big online source for film info",
    "Website for film buffs",
    "Web site for cinephiles"
  ],
  "inky": [
    "Stained, in a way",
    "Black",
    "Like presses",
    "Black",
    "Extremely dark",
    "Black as night",
    "Pitch-black",
    "Pitch-black",
    "Jet-black",
    "Black",
    "One of the Pac-Man ghosts",
    "Black",
    "Black",
    "Black as night",
    "Like a starless sky",
    "Jet black",
    "Pitch-black",
    "Black",
    "Deep black",
    "Black",
    "Black",
    "Pitch-dark",
    "Blackened",
    "Black",
    "Very dark",
    "Black",
    "Black and wet",
    "Black",
    "Black",
    "Like an octopus's defense",
    "Like an octopus's defense",
    "Very dark",
    "Black",
    "Like printers' fingers"
  ],
  "beta": [
    "Prerelease, in Silicon Valley",
    "Stage that may have bugs",
    "Early stage for some bugs?",
    "Testing stage",
    "Version before a stable release",
    "With 49-Down, early tryout",
    "Testing stage",
    "What bugs are found in",
    "___ testing",
    "Not final",
    "Software version for testing",
    "A new computer program may be in it",
    "What bugs are found in",
    "Testing stage",
    "It may be full of bugs",
    "Testing stage",
    "See 22-Across",
    "Un-debugged software version, perhaps",
    "Letter after alpha",
    "Test letter?",
    "Alpha, ___, gamma ...",
    "Not the final version",
    "Old VHS rival",
    "Kind of test",
    "Software prototype",
    "___ decay",
    "Alpha-gamma connector",
    "Software prototype",
    "Test software release",
    "What bugs are often found in",
    "Not fully tested",
    "Early software version",
    "Gamma preceder",
    "Kind of blocker",
    "Software test version",
    "Test stage",
    "Test version",
    "Commercial prefix with -max or -cam",
    "Test version",
    "Key letter",
    "Not yet final, in a way",
    "Alpha, ___, gamma ...",
    "Second in a series",
    "Second of 24",
    "Early version",
    "Test version",
    "Old VCR format",
    "Test stage",
    "Test model",
    "Test version",
    "Phi ___ Kappa",
    "Early release",
    "It's not the final release",
    "Kind of blocker",
    "___ blocker",
    "Second in a series",
    "Not-quite-final software",
    "Gamma preceder",
    "Nuclear physics prefix",
    "Loser to VHS",
    "With 61-Down, Pollux, e.g.",
    "___-test",
    "As yet unmarketed, as software",
    "Phi ___ Kappa",
    "Software test version",
    "Kind of test",
    "Kind of test",
    "Second in a series",
    "Kind of test",
    "Phi ___ Kappa",
    "___ carotene",
    "Second in order",
    "Certain constellation star",
    "Kind of test",
    "VHS alternative",
    "Kind of testing",
    "VHS alternative",
    "Home video format",
    "Kind of blocker",
    "Key letter",
    "___ carotene",
    "___ blocker",
    "Kind of blocker",
    "Phase constant, in physics",
    "Obsolescent VCR format",
    "Kind of star",
    "___ carotene",
    "VHS alternative"
  ],
  "oahu": [
    "Isle known as \"The Gathering Place\"",
    "Where Honolulu is",
    "Island WNW of Molokai",
    "Where the Ko'olau range is located",
    "Honolulu's home",
    "Island where it once rained for 331 days straight",
    "Home of Waikiki Beach",
    "Most populous Hawaiian island",
    "Where to visit the U.S.S. Arizona Memorial",
    "Home to the landmark Koko Crater",
    "Home of Daniel K. Inouye International Airport",
    "Hawaiian honeymoon destination",
    "Hanauma Bay locale",
    "Where the Ko'olau Range is located",
    "Locale of a noted 1941 attack",
    "Island with a royal palace",
    "Home of the all-vowel town Aiea",
    "Diamond Head's island",
    "Island with a state capital",
    "Third-largest of the Hawaiian Islands",
    "Honolulu's island",
    "Home to Interstates H-1, H-2 and H-3",
    "Island formed by two shield volcanoes",
    "Locale for Charlie Chan",
    "Home to the Triple Crown of Surfing",
    "Locale of a 12/7/1941 attack",
    "Where Waikiki Beach is",
    "Hanauma Bay locale",
    "Four-letter island name with three syllables",
    "Setting for 19-Across",
    "Home to the historic Moana Hotel",
    "Obama's birthplace",
    "Hawaiian island",
    "U.S.S. Missouri's resting site",
    "Location of Waimea Valley",
    "Home of Pearl City",
    "Island with Diamond Head",
    "Home of the all-vowel-named town Aiea",
    "National Memorial Cemetery of the Pacific locale",
    "Home of Charlie Chan",
    "Iolani palace locale",
    "Home of the first Dole plantation",
    "Locale of Kaneohe Bay",
    "Honolulu's island",
    "First Ironman locale",
    "Setting for the George Clooney film \"The Descendants\"",
    "Site of the U.S.'s only royal palace",
    "Pearl Harbor location",
    "Island that may have an apostrophe in its name",
    "Frequent vacation locale for Obama",
    "Home of Hanauma Bay",
    "U.S. island with a royal palace",
    "Honolulu's home",
    "\"From Here to Eternity\" setting",
    "Home of Waikiki Beach",
    "Birthplace of 22-Across",
    "Home of the Waianae Range",
    "Honolulu's home",
    "Obama's birthplace",
    "Island with Yokohama Bay",
    "Obama's birthplace",
    "Diamond Head setting",
    "Obama's birthplace",
    "Locale of a 12/7/1941 attack",
    "Nuuanu Pali Lookout locale",
    "Waikiki locale",
    "It was created by two volcanoes",
    "Diamond Head's isle",
    "Obama's birthplace",
    "Hawaiian island",
    "Hanauma Bay locale",
    "Home of 102-Down",
    "Home of Punchbowl Crater",
    "Home of the highways H1 and H2",
    "Kailua Bay's setting",
    "Where Obama was born",
    "Home of highways H-1, H-2 and H-3",
    "Aloha Tower site",
    "Diamond Head locale",
    "Home of Wheeler Army Airfield",
    "Home of Mount Ka'ala",
    "Honolulu's home",
    "\"Lost\" filming locale",
    "Locale of the highways H1 and H2",
    "Home of Pearl City",
    "Home of golf's Sony Open",
    "Island nicknamed the Gathering Place",
    "Honolulu's home",
    "Island with Waimea Bay",
    "Bishop Museum setting",
    "Waikiki Beach locale",
    "Iolani Palace locale",
    "Pearl Harbor site",
    "Waikiki locale",
    "Pearl Harbor's site",
    "Pearl City setting",
    "Filming location of ABC's \"Lost\"",
    "Locale of Interstate H1",
    "Where Pearl City is",
    "Home of the bygone Aloha Bowl",
    "Home of the superhighways H1, H2 and H3",
    "Home of Interstate H1",
    "\"Magnum P.I.\" setting",
    "Diamond Head's home",
    "Home to Honolulu",
    "\"From Here to Eternity\" island",
    "Island with Kaala Peak",
    "Diamond Head site",
    "Hawaiian island",
    "Aiea locale",
    "Home of Iolani Palace",
    "Where Pearl City is",
    "Island known as \"The Gathering Place\"",
    "Where Pearl Harbor is",
    "Home to Honolulu",
    "Pearl Harbor locale",
    "Diamond Head locale",
    "See 54-Across",
    "Where football's Pro Bowl is played",
    "Site of \"interstates\" H1, H2 and H3",
    "Koko Head locale",
    "Honolulu's island",
    "\"Magnum, P.I.\" setting",
    "Diamond Head locale",
    "Diamond Head locale",
    "Honolulu's location",
    "Pearl Harbor locale",
    "Waikiki's island",
    "Hawaiian island",
    "\"Hawaii Five-O\" locale",
    "Diamond Head locale",
    "Home of Wheeler Air Force Base",
    "Kahuku setting",
    "Home of Hickam Air Force Base",
    "Island near Kauai",
    "Island near Kauai",
    "Diamond Head locale",
    "Hawaiian \"gathering place\"",
    "Site of the Koolau Range",
    "Diamond Head site",
    "Honolulu's site",
    "Honolulu's island",
    "Pearl Harbor site",
    "Kauai neighbor",
    "Site of Hickam Air Force Base",
    "Waikiki locale",
    "Honolulu locale"
  ],
  "grid": [
    "Graph component",
    "Off the ___",
    "Electrical ___",
    "Part of a crossword",
    "Electrical system",
    "Where to enter answers in a crossword",
    "Electrical system",
    "Where to enter this puzzle's answers",
    "Sudoku feature",
    "Graph paper pattern",
    "Power system",
    "Photomap overlay",
    "Diagram",
    "Crossword pattern",
    "Lines on a radar screen",
    "Electrical network",
    "See 1-Across",
    "Traffic chart",
    "Graph pattern",
    "Map overlay",
    "Street plan, e.g.",
    "Mapmaker's aid",
    "Radar screen",
    "Crossword maker's canvas",
    "Part of a crossword",
    "Framework",
    "Part of a crossword",
    "Part of a control tower screen",
    "Electric power network"
  ],
  "howl": [
    "Cry of pain ... or laughter",
    "Beat poem allegedly inspired by a peyote vision",
    "Haunted house sound",
    "Laugh loudly",
    "Laugh uproariously",
    "Wind sound",
    "Complain loudly",
    "Laugh hard",
    "Laugh uproariously",
    "Bay",
    "Wolf's cry",
    "Wolf's sound",
    "Laugh heartily",
    "Wolf's sound",
    "Ginsberg classic",
    "React to something hilarious",
    "Vigorous protest",
    "Ginsberg poem",
    "Laugh uproariously",
    "Holler",
    "1956 Allen Ginsberg poem",
    "Riot",
    "Loud laugh",
    "Sound from a coyote",
    "Quite a joke"
  ],
  "pisa": [
    "___ Pizza (punny trattoria name)",
    "Galileo's birthplace",
    "City on the Arno",
    "Leaning Tower city",
    "Leaning Tower city",
    "With 52-Down, home of the Leaning Tower",
    "City west of Florence",
    "Italian city you might be \"leaning\" toward visiting?",
    "Tuscany tower site",
    "City with a noted tower",
    "Piazza dei Miracoli locale",
    "City with a famous bell tower",
    "Galileo's hometown",
    "Birthplace of Galileo",
    "Galileo Galilei Airport city",
    "Where Fermi studied",
    "City on the Arno",
    "Italy's Tower of ___",
    "Hometown of the mathematician Fibonacci",
    "City down the river from Florence",
    "Knights' Square site",
    "University of ___, where Andrea Bocelli earned a law degree",
    "Site of some Galileo experiments",
    "Leonardo of ___ (mathematician who wrote about the 33-Across)",
    "Home of Galileo Galilei Airport",
    "Noted tower site",
    "Camposanto Monumentale locale",
    "See 53-Down",
    "Leaning Tower site",
    "City on the Arno",
    "Torre Pendente city",
    "Piazza dei Miracoli town",
    "City noted for its campanile",
    "Site of some Galileo experiments",
    "Council of ___, 1409",
    "Home of Galileo Galilei International Airport",
    "Arno city",
    "City with una torre pendente",
    "Where Fermi went to university",
    "Birthplace of Galileo",
    "Galileo's birthplace",
    "Noted tower site",
    "Leaning Tower city",
    "Its biggest attraction is on a list",
    "Leaning tower site",
    "1589-92 teaching venue for Galileo",
    "Noted bell tower site",
    "Galileo's home",
    "Italian tourist city",
    "Tuscany tourist city",
    "University city since 1343",
    "Home of a famous tilter",
    "Council city of 1409",
    "1409 Council site",
    "Galileo's birthplace",
    "Its campanile is famous",
    "City on the Arno",
    "Council of ___ (1409 assembly)",
    "Leaning Tower's city",
    "Tower locale",
    "Tower site",
    "Council site of 1409",
    "Tower site",
    "Famous tower locale",
    "Site of a famous campanile",
    "Galileo's birthplace",
    "Council city of 1409",
    "Tower location",
    "Tower site",
    "City on the Arno",
    "Galileo?Æs birthplace",
    "Marble tower site",
    "Council of ___, 1409",
    "Italian flower town",
    "City on the Arno",
    "City north of Livorno",
    "Galileo's home",
    "Tilting-tower town"
  ],
  "obis": [
    "They might be tied using a taiko musubi (\"drum knot\")",
    "Ties to the Japanese?",
    "Japanese sashes",
    "Eastern ties",
    "Japanese sashes",
    "Geishas' sashes",
    "Kimono securers",
    "Geishas' sashes",
    "Geishas often draw them",
    "Broad sashes",
    "Geishas' wear",
    "Some wraps",
    "Bands seen at Japanese weddings",
    "Tokyo ties",
    "Middle parts of Japan?",
    "Sometimes-satin items of apparel",
    "They have bows",
    "Accessories whose colors may indicate rank",
    "Waist products",
    "\"Madama Butterfly\" wear",
    "Waist products",
    "Bands of geishas",
    "Japanese sashes",
    "Kimono sashes",
    "Geishas draw them",
    "Eastern ties",
    "Parts of Japanese bridal costumes",
    "Tokyo ties",
    "Eastern sashes",
    "Ties around the middle",
    "Some are tied in \"cho cho\" fashion",
    "Kimono accessories",
    "\"The Mikado\" accessories",
    "Sashes",
    "They may be tied up in Tokyo",
    "Sashes in \"The Mikado\"",
    "Ties with bows",
    "Accessories for Cio-Cio-San",
    "Wide sashes",
    "Some wraps",
    "Overseas ties",
    "Eastern ties",
    "Kimono sashes",
    "\"The Mikado\" attire",
    "Waist circlers",
    "Kimono sashes",
    "Japanese sashes",
    "Japanese middle managers?",
    "Japanese wraps",
    "They go to waist",
    "Sashes",
    "Kimono ties",
    "Sashes",
    "Sashes"
  ],
  "isup": [
    "\"Time ___!\"",
    "Was just on deck",
    "\"Your time ___!\"",
    "Bats",
    "Leads (by)",
    "Bats",
    "Bats",
    "Has an at-bat",
    "\"The jig ___!\"",
    "\"Which Way ___?\" (1977 film)",
    "\"The jig ___!\"",
    "\"The Bronx ___...\"",
    "Goes to bat",
    "\"The jig ___!\"",
    "\"The jig___!\"",
    "\"The jig___!\""
  ],
  "mead": [
    "Viking beverage",
    "World's oldest alcoholic beverage",
    "Renaissance Faire quaff",
    "Quaff made with honey",
    "Honey-based drink",
    "Lake on the Arizona/Nevada border",
    "Drink at Valhalla",
    "Honey brew",
    "\"Honey wine\"",
    "Renaissance Faire quaff",
    "\"Beowulf\" beverage",
    "Product of fermenting honey",
    "\"Beowulf\" quaff",
    "Quaff for Beowulf",
    "Honeyed drink",
    "Fermented honey drink",
    "Flagon contents, perhaps",
    "Drink in \"Beowulf\"",
    "Lake ___, created by Hoover Dam",
    "Honey drink",
    "Big name in notebooks",
    "Drink in \"Beowulf\"",
    "Hoover Dam's Lake ___",
    "Big maker of notebooks",
    "\"Beowulf\" quaff",
    "Honeyed drink",
    "Honeyed drink",
    "Lake ___, reservoir on the Colorado",
    "Hearty drink made with honey",
    "Honey drink",
    "Fermented honey drink",
    "\"Beowulf\" beverage",
    "Ale relative",
    "Honey drink",
    "\"Beowulf\" beverage",
    "Sweet drink",
    "Open area for Robin Hood's men",
    "Beverage for Beowulf",
    "Drink for Robin Hood",
    "\"Canterbury Tales\" drink",
    "Drink for Beowulf",
    "\"Coming of Age in Samoa\" author",
    "Fermented drink",
    "Pasture, in poetry",
    "Drink of the ancients",
    "Anthropologist Margaret",
    "Anthropologist Margaret",
    "Pasture, to Shakespeare",
    "Drink of old",
    "Lake formed by Hoover Dam"
  ],
  "fife": [
    "Small wind instrument",
    "Piccolo relative",
    "Small flute",
    "Drum go-with",
    "Instrument in the painting \"The Spirit of '76\"",
    "Small flute",
    "Barney of Mayberry",
    "\"The Spirit of '76\" instrument",
    "County of St. Andrews, Scotland",
    "Taylor's deputy on TV",
    "Flute in a march",
    "Drum's partner",
    "Fourth of July marcher's accompaniment",
    "Drum accompanier",
    "Marching band instrument",
    "?ôSpirit of 76?ö instrument",
    "Colonial flute"
  ],
  "tbar": [
    "Help up a slope",
    "One way up a mountain",
    "Ski slope conveyance",
    "Way up to go downhill",
    "Skier's conveyance",
    "Transport up a bunny slope",
    "One way to the top",
    "Lift on a ski slope",
    "Skier's aid",
    "Lift at a resort",
    "Bit of a lift",
    "Tower on a mountain",
    "Skier's way up a mountain",
    "Way up a ski mountain",
    "Lift up the ski slopes",
    "Skier's convenience",
    "Means of avoiding an uphill climb",
    "Uphill aid for skiers",
    "Rope tow alternative",
    "Ski lift",
    "Way up a mountain",
    "It's a lift",
    "Skier's lift",
    "Transport method usually used in the winter",
    "Elevator used by skiers",
    "Way up",
    "Way up?",
    "Aid in an uphill climb",
    "It can give you a lift",
    "What can help you toward a peak performance?",
    "One way to the top",
    "It'll grab you by the seat of your pants",
    "Means of reaching a peak level?",
    "Way up a mountain",
    "Pico Mountain innovation of 1940",
    "Boarder's way up",
    "Lift, of a sort",
    "Ski lift",
    "Boarder's aid",
    "Sturdy mountain climber?",
    "Funicular alternative",
    "Way up a slope",
    "Transport on a slope",
    "Mountain climber?",
    "Ski lift",
    "Alternative to a carpet lift",
    "Apparatus for pull-ups?",
    "Way up the slope",
    "Mountain lift",
    "Way up a ski slope",
    "Easy way to the top",
    "Skiers' lift",
    "One way to get to the top",
    "Winter lift?",
    "Skier's transport",
    "Skier's transport",
    "Boarders board it",
    "It'll give you a lift",
    "Way to the top",
    "Way to the top",
    "Way up",
    "Alpine sight",
    "Way up a mountain",
    "Ski lift",
    "Way up the mountain",
    "Mountain lift",
    "Way up?",
    "Skier's transport",
    "Lift in Aspen",
    "Lift for two",
    "Skier's transport",
    "Ski lift",
    "Mountain climber?",
    "Tahoe transport",
    "Ski lift",
    "Lift",
    "Slopes lift",
    "Way up a slope",
    "Lift provider",
    "Structural piece",
    "With 3-Down, way up for a downhiller",
    "Way uphill",
    "Aid to skiers",
    "Skier's way up",
    "Chairlift alternative",
    "With 10-Across, skier's aid",
    "Alpine transport",
    "Way up some hills",
    "Winter lift",
    "Way up for a downhiller",
    "Ski lift",
    "Antiquated alpine apparatus",
    "Ski lift",
    "Schussboomer's transport",
    "It provides a lift",
    "Ski lift",
    "Skier's transport",
    "Ski lift",
    "Stowe tow",
    "Mountain sight",
    "Sight on a slope",
    "Slope sight",
    "Transportation on the slopes",
    "Stowe lift",
    "Alpine transport",
    "It can give you a lift",
    "Skier's aid",
    "Mountain transport",
    "Alpine elevator",
    "Way up a hill",
    "Uphill conveyance",
    "Skiers' aid",
    "Skier's aid",
    "Stowe tow",
    "Skier's aid",
    "Ski lift",
    "Ski lift",
    "Leg up for skiers",
    "Skier's aid",
    "Skier's aid",
    "Skier's aid",
    "It gives skiers a lift",
    "Skier's aid"
  ],
  "phat": [
    "It was cool in the '90s",
    "Cool, '90s-style",
    "Excellent, in dated slang",
    "Dope",
    "Totally dope, in dated slang",
    "Cool, in dated slang",
    "Excellent, informally",
    "Cool, in '90s slang",
    "Cool, in old slang",
    "Excellent, in 1990s slang",
    "Excellent, in dated slang",
    "Excellent, in slang",
    "Excellent, in dated slang",
    "Excellent, slangily",
    "Super, slangily",
    "Excellent, slangily",
    "Excellent, slangily",
    "Excellent, slangily",
    "Terrific, in slang",
    "Excellent, in slang",
    "Excellent, in modern slang",
    "Cool, in slang",
    "First-rate, slangily",
    "Terrific, in slang",
    "Very fine, in slang",
    "Excellent, in modern slang",
    "Great, in slang"
  ],
  "iron": [
    "Throne material on \"Game of Thrones\"",
    "De-wrinkle",
    "What might help you get out of a rough spot?",
    "Club type",
    "Wrinkle remover",
    "Pressing need",
    "Alternative to a wood, in golf",
    "Press",
    "Shellfish have lots of this",
    "Classic Monopoly token",
    "One letting off steam",
    "Fe, chemically",
    "___ horse",
    "Soldering device",
    "With 7-Down, early historical time",
    "Item often numbered from 3 to 9",
    "Fe, on the periodic table",
    "Unwavering",
    "Monopoly token replaced in 2013 by a cat",
    "Metal that rusts",
    "Golf club that's not a wood",
    "Remove wrinkles from",
    "Wedge, for one",
    "Sand wedge, for one",
    "Hotel amenity with a cord",
    "Weights, informally",
    "Decrease?",
    "Smooth",
    "Nutrient in lentils and liver",
    "Go over one's wardrobe?",
    "Unyielding",
    "Resource in the Mesabi Range",
    "With 117-Down, Mesabi Range output",
    "With 53-Across, goethite, e.g.",
    "Mineral plentiful in kale",
    "Press",
    "Sand wedge, e.g.",
    "Press",
    "Smooth over",
    "Core component",
    "Clothes presser",
    "Clothes unwrinkler",
    "Weights, so to speak",
    "De-crease",
    "Pitching wedge, for one",
    "Fe, to chemists",
    "Spinach is rich in it",
    "You might take it out for a drive",
    "Club that's usually numbered from 3 to 9",
    "Monopoly token replaced in 2013",
    "Fe, in chemistry",
    "Clothes presser",
    "Mineral abundant in kale",
    "Press",
    "Its symbol is Fe",
    "See 4-Down",
    "Something spinach has",
    "Press",
    "Unwrinkler",
    "\"Pumping ___\" (1977 docudrama featuring 48-Across)",
    "Wood alternative",
    "Unyielding",
    "Weights, to a weightlifter",
    "See 21-Down",
    "What bodybuilders pump",
    "Clothes presser",
    "Fe, chemically",
    "Weights, colloquially",
    "Fe, chemically",
    "Unyielding",
    "A Monopoly token",
    "Unbending",
    "Press",
    "Wood alternative",
    "Unrelenting",
    "Wood alternative",
    "Steel component",
    "One letting off steam",
    "Fe, to scientists",
    "A Monopoly token",
    "It's pumped",
    "With 11-Down, prehistoric period",
    "Fe, on the periodic table",
    "It may let off steam",
    "Monopoly token",
    "Do a post-washing chore",
    "Mineral in hemoglobin",
    "Press",
    "Cast-___ stomach",
    "Wood alternative",
    "Monopoly token",
    "It may be pumped",
    "Hotel room amenity",
    "Press",
    "Element that can precede the starts of 20-, 31-, 47- and 54-Across",
    "Monopoly token",
    "Wood alternative",
    "Hard stuff",
    "Monopoly game token",
    "Unyielding",
    "___ Man, comics hero",
    "Mill material",
    "Fairway club",
    "Pressing need?",
    "Press",
    "One of nine in golf",
    "Fe, to chemists",
    "Monopoly token",
    "Club that's not a wood",
    "Press",
    "Laundry implement that might make a 43-Down",
    "Fe, chemically",
    "Tiger club",
    "26 on a table",
    "Press",
    "Do post-laundry work",
    "Get the wrinkles out of",
    "Unwrinkle",
    "Some pump it",
    "Flatten",
    "Waffle maker",
    "Common supplement",
    "Flatten",
    "Flattener",
    "Hematite component",
    "Kind of horse",
    "Monopoly token",
    "Press",
    "Smooth",
    "Essential nutrient",
    "Monopoly token",
    "What spinach is rich in",
    "Laundry item",
    "Will or fist preceder",
    "Wrinkle remover",
    "Like some wills",
    "Flatten, in a way",
    "Geritol ingredient",
    "\"Pumping ___\"",
    "Press",
    "Mineral in spinach",
    "Fe, to a chemist",
    "\"Pumping ___\"",
    "Press",
    "Multivitamin supplement",
    "Pressing need",
    "Wrinkle remover",
    "#26",
    "Its symbol is Fe",
    "Item in a bag",
    "Steam ___",
    "Steel ingredient",
    "Press",
    "Mashie, e.g.",
    "Golf bag item",
    "Monopoly game token",
    "Club at a club",
    "Golfer's selection",
    "It's sometimes cast",
    "It goes across the board",
    "Numbered club",
    "Unwrinkle",
    "Steel ingredient",
    "Pistol, slangily",
    "Vitamin pill addition",
    "Like Dumas's mask",
    "Tend to pressing business",
    "Part of steel",
    "Mesabi Range deposit",
    "Smooth (out)",
    "It can be cast",
    "Wrinkle remover",
    "Unwavering",
    "Nutrient in spinach",
    "Smooth",
    "Monopoly token",
    "Appliance with a cord and a board",
    "Press",
    "Smooth (out)",
    "Branding tool",
    "Wrinkle remover",
    "Clothes presser",
    "Remove the wrinkles from",
    "It may be pumped",
    "Vitamin tablet supplement",
    "Get the wrinkles out",
    "Caddie's offering",
    "It's pumped in gyms",
    "Anemic's need",
    "Much of the earth's core",
    "Smooth",
    "Resolve, with \"out\"",
    "A magnet attracts it",
    "Press",
    "It smooths things over",
    "Wrinkle remover",
    "Vitamin tablet supplement",
    "Unyielding",
    "Flatten",
    "Multivitamin supplement",
    "Player's club",
    "Inflexible",
    "De-wrinkle",
    "One-a-Day ingredient",
    "Maker of a brand name?",
    "Dewrinkle",
    "Press agent?",
    "Vitamin supplement",
    "Driver's selection",
    "Appliance on a board",
    "Press",
    "Unbending",
    "Monopoly token",
    "Word after pig or before horse",
    "It's a scorcher",
    "Clothes presser",
    "Press, as clothes",
    "Smooth",
    "Smooth",
    "Heavy metal",
    "Decrease?",
    "Golf club",
    "Press",
    "Collar straightener",
    "Golf club",
    "Part of multivitamin",
    "De-wrinkle",
    "Caddie's offering",
    "Shade of gray",
    "Fe, in chemistry",
    "Get the wrinkles out",
    "Monopoly token",
    "Item in a laundry",
    "Steel base",
    "De-wrinkle",
    "Monopoly token",
    "Press",
    "Collar straightener",
    "Multivitamin ingredient",
    "Magnetic attraction",
    "Dietary supplement",
    "Remove the wrinkles from",
    "Press clothes",
    "Press",
    "Nutritional necessity",
    "Caddie's offering"
  ],
  "rift": [
    "Big break",
    "Serious divide",
    "Break in relations",
    "It may precede a breakup",
    "Serious break in relations",
    "Falling-out",
    "Falling-out",
    "Falling-out",
    "Fissure",
    "Schism",
    "Schism",
    "It's the geologist's fault",
    "Disagreement",
    "Estrangement",
    "Major fault",
    "Falling-out result",
    "Estrangement",
    "Friends' feud",
    "Divide",
    "Separation",
    "Friendship ender",
    "Start of a breakup",
    "Falling-out",
    "Break",
    "Seeds of a divorce",
    "Falling out",
    "Estrangement",
    "Break",
    "Fissure",
    "Falling out",
    "Fissure",
    "Split",
    "Break",
    "It's the geologist's fault",
    "Diplomatic to-do",
    "Diplomatic woe",
    "Schism",
    "Break in relations",
    "Fissure",
    "Break in friendly relations",
    "Break in relations",
    "Break in relations",
    "Split",
    "Falling-out"
  ],
  "capt": [
    "Rank below adm.",
    "Georg von Trapp's title in \"The Sound of Music\": Abbr.",
    "Airline V.I.P.: Abbr.",
    "Lt.'s superior",
    "One with special quarters: Abbr.",
    "Abbr. by Hook or by Cook",
    "Lt.'s superior",
    "U.S.N. rank",
    "Cook, e.g.: Abbr.",
    "Hook or Cook: Abbr.",
    "\"Aye, aye!\" hearer: Abbr.",
    "Hands are under one: Abbr.",
    "Skipper: Abbr.",
    "Voyage V.I.P.",
    "Lt.'s superior",
    "Kangaroo, for one: Abbr.",
    "Cook, for one: Abbr.",
    "Four-striper: Abbr.",
    "Kirk, e.g.: Abbr.",
    "Nemo, e.g.: Abbr.",
    "Lt.-Maj. go-between",
    "Title for Nemo or Queeg: Abbr.",
    "Hook or Cook: Abbr.",
    "U.S.N. V.I.P.",
    "Demoted maj."
  ],
  "tier": [
    "Seating section",
    "Piece of cake?",
    "Rank",
    "Piece of cake?",
    "Ranking component",
    "Category",
    "Cake layer",
    "Top ___",
    "Seating section",
    "Story",
    "Level",
    "Arena section",
    "Piece of cake?",
    "Stadium ticket specification",
    "Bleacher feature",
    "Stadium ticket price factor",
    "Mezzanine, e.g.",
    "Part of a wedding 9-Across",
    "Theater seating info",
    "Seating specification",
    "Piece of cake?",
    "Level",
    "Wedding cake layer",
    "Level",
    "Theater ticket price factor",
    "Grand ___ (opera house section)",
    "Feature of a big cake",
    "Balcony, for one",
    "Level",
    "Bleacher feature",
    "Stadium projection, maybe",
    "Cake layer",
    "Echelon",
    "Level",
    "Level",
    "Balcony, e.g.",
    "Cake layer",
    "Rank",
    "Level",
    "Rank",
    "Layer",
    "Arena section",
    "Seating specification",
    "Seating area",
    "Second-___",
    "Echelon",
    "Echelon",
    "Wedding cake feature",
    "Stadium area",
    "Row",
    "Level",
    "Hierarchy part",
    "Cake part",
    "Part of a wedding cake",
    "Piece of cake?",
    "Layer",
    "Stadium level",
    "Auditorium balcony, e.g.",
    "Layer",
    "It may hang out in a sports stadium",
    "Level",
    "Top-___ (best)",
    "Arena ticket specification",
    "Level",
    "Wedding cake feature",
    "Top-___ (leading)",
    "Detail on some tickets",
    "Stadium section",
    "Level",
    "Stadium part",
    "Level",
    "Stratum",
    "Rank",
    "Level",
    "Seating datum",
    "Ticket info, maybe",
    "Stage",
    "Bleacher feature",
    "Bank",
    "Class",
    "Level",
    "Level",
    "Amphitheater section",
    "Level",
    "Keyboard section",
    "Layer",
    "Bank",
    "Rank",
    "Echelon",
    "Ticket info",
    "Seating level",
    "Section of seats",
    "Seating section",
    "Level",
    "Factor in ticket pricing",
    "Bireme section",
    "Cake feature, sometimes",
    "Rank",
    "Level",
    "Seating section",
    "Bank",
    "Echelon",
    "Bank",
    "Level",
    "Seating section",
    "Level",
    "Bank",
    "Opera house level",
    "Level",
    "Wedding cake feature",
    "Bleacher feature",
    "Part of a seat assignment",
    "Bleacher feature",
    "Rank",
    "Layer",
    "Ballpark level",
    "Level",
    "Stadium area",
    "Stratum",
    "Piece of cake?",
    "Tower part",
    "Bleacher feature",
    "Stadium level",
    "Stadium section",
    "Wedding cake feature",
    "Bleacher feature",
    "Galley feature",
    "Stadium section",
    "Ticket info, maybe",
    "Class",
    "Fan belt?",
    "Stadium level",
    "Category",
    "Row",
    "Gallery",
    "Deck",
    "Rank",
    "Wedding cake feature",
    "Stadium area",
    "Stadium section",
    "Gallery",
    "Deck",
    "Row",
    "Level",
    "Rank",
    "Level",
    "Level",
    "Stadium level",
    "Level"
  ],
  "whet": [
    "Put an edge on",
    "Hone",
    "Arouse",
    "Sharpen",
    "Sharpen",
    "Sharpen",
    "Make keen",
    "Stimulate",
    "Hone",
    "Excite, as an appetite",
    "Stimulate",
    "Pique",
    "Hone",
    "Stimulate",
    "Put an edge on",
    "Stimulate",
    "Stimulate",
    "Stimulate",
    "Sharpen",
    "Sharpen",
    "Sharpen",
    "Stimulate",
    "Stimulate, as the appetite",
    "Put an edge on",
    "Sharpen",
    "Sharpen, as a knife",
    "Sharpen",
    "Stimulate",
    "Sharpen",
    "Stimulate",
    "Stimulate",
    "Sharpen",
    "Hone",
    "Stimulate",
    "Be an appetizer for"
  ],
  "pacs": [
    "Funders of many campaign ads, in brief",
    "\"Super\" orgs.",
    "Campaign support grps.",
    "Campaign supporters",
    "Primary funding sources, briefly",
    "Moneyed campaign orgs.",
    "Campaign grps.",
    "\"Super\" campaign orgs.",
    "Campaign coffer fillers",
    "Big donors to office seekers",
    "Donation-soliciting grps.",
    "Campaign funders, for short",
    "Lobbying grps.",
    "Subjects of fed. election regulations",
    "D.C. fund-raisers",
    "Special interest grps.",
    "Campaign grps.",
    "Helps for Reps. and Dems.",
    "Big donors of a sort"
  ],
  "html": [
    "Website designer's code",
    "Web designer's code",
    "Web file format, for short",
    "Web designer's code",
    "Code edited by a webmaster",
    "Web developer's code",
    "Web programming inits.",
    "Blogger's code",
    "Building site code?",
    "WWW letters",
    "WWW programming code",
    "Web designer's code",
    "Counterpart of JavaScript",
    "Webmaster's medium",
    "Many pages are written in it",
    "Code with tags",
    "Web file format, for short",
    "Abbr. after a period",
    "Kind of tag for a Web designer",
    "Web programmer's medium",
    "WWW code",
    "WWW letters",
    "What a webmaster may master",
    "Webmaster's lingo",
    "WWW code",
    "WWW letters",
    "Web code",
    "Web programmer's medium",
    "Web code",
    "Web programmer's medium",
    "www page creation tool",
    "World Wide Web code"
  ],
  "ared": [
    "Run ___ light",
    "Cezanne's \"Boy in ___ Vest\"",
    "\"Wielding ___ Sword\" (Piers Anthony novel)",
    "Cezanne's \"Boy in ___ Vest\"",
    "Cezanne's \"Boy in ___ Vest\""
  ],
  "kemp": [
    "Dole's 1996 running mate",
    "Running mate in the 1996 presidential election",
    "Dole's 1996 running mate",
    "Jack who ran for vice president in 1996",
    "Dole's running mate of 1996",
    "Dole's 1996 running mate",
    "Gridder-turned-politician Jack",
    "Pro footballer turned congressman",
    "Shawn of the N.B.A.",
    "Dole's running mate, 1996",
    "1996 veep hopeful",
    "Dole's running mate, 1996",
    "Dole's 1996 running mate",
    "Shawn of the Sonics",
    "1996 running mate",
    "Republican V.I.P. Jack",
    "Big name in G.O.P. circles"
  ],
  "atra": [
    "Gillette razor brand",
    "Gillette razor handle",
    "Gillette brand",
    "Blade brand",
    "___ Plus (brand with a \"Lubrastrip\")",
    "Big name in razors",
    "Gillette razor option",
    "Shaving brand",
    "___ Plus (grooming brand)",
    "Razor brand",
    "Gillette ___ Plus",
    "Razor brand",
    "Gillette razor brand",
    "Blade brand",
    "Sister brand of Gillette's Sensor",
    "Gillette product",
    "Gillette brand",
    "Mach3 forerunner",
    "Relative of the Contour Plus",
    "Blade handle?",
    "Product with a Lubrastrip",
    "Razor brand",
    "Cutting-edge brand?",
    "Gillette brand",
    "Gillette brand",
    "Gillette brand",
    "Gillette brand",
    "First razor with a pivoting head",
    "___ Plus",
    "Shaving brand",
    "Trac II successor",
    "First razor with a pivoting head",
    "Sensor forerunner",
    "Gillette razor brand",
    "Gillette brand",
    "Cutting-edge product?",
    "Razor brand",
    "Razor handle?",
    "___ Plus (pharmacy purchase)",
    "Razor introduced in 1977",
    "Razor brand",
    "Mach3 predecessor",
    "___ Plus (razor brand)",
    "Gillette product",
    "Cutting-edge brand",
    "Razor brand",
    "Gillette offering",
    "Razor brand",
    "Pioneering razor with a pivoting head",
    "Gillette brand",
    "Gillette razor",
    "Gillette model",
    "Gillette product",
    "Razor brand",
    "Blade handle?",
    "Razor brand",
    "Grooming brand",
    "First razor with a pivoting head",
    "Brand in a medicine cabinet",
    "Gillette razor",
    "Razor brand",
    "Razor brand",
    "Gillette razor",
    "Gillette razor",
    "Trac II alternative",
    "Gillette razor",
    "First razor with a pivoting head",
    "Razor brand",
    "Brand in a bathroom",
    "Pivoting razor",
    "Toiletry brand introduced in 1977",
    "Alternative to a Trac II",
    "Gillette brand",
    "Grooming brand introduced in 1977",
    "Gillette ___ Plus",
    "Razor name",
    "Gillette razor",
    "Gillette razor",
    "Gillette brand",
    "Gillette brand",
    "Blade brand",
    "Razor name",
    "Razor brand",
    "Popular shaver",
    "Razor brand",
    "Gillette razor",
    "Gillette product",
    "Razor's handle",
    "Razor name",
    "Gillette product",
    "Gillette product",
    "Razor brand",
    "Razor brand",
    "Sensor forerunner",
    "Name on a razor",
    "Gillette product",
    "Gillette razor",
    "Razor brand",
    "Gillette brand",
    "Razor brand",
    "Gillette product",
    "Gillette product",
    "Gillette brand",
    "___ Plus razor",
    "Razor brand",
    "Gillette brand",
    "Gillette product",
    "Gillette product",
    "Razor brand",
    "Gillette brand",
    "Gillette product",
    "Razor brand",
    "Razor brand"
  ],
  "they": [
    "\"... or so ___ say\"",
    "Nonbinary pronoun",
    "Bridge scorecard heading",
    "Place to swim or work out, informally",
    "Place to go to swim, informally",
    "Unidentified gossip source, often",
    "Not we",
    "\"___ shut me up in Prose\" (Emily Dickinson poem)",
    "Anonymous news source",
    "Rock's ___ Might Be Giants",
    "Unspecified news source, often",
    "Unnamed others",
    "Locale for a Village People hit, informally",
    "He and she",
    "He and she",
    "\" ... or so ___ say\"",
    "Place to swim and play b-ball, say",
    "Place with a gym",
    "Community hangout, informally",
    "\"What were ___ thinking?\"",
    "\"___ Say,\" 1939 #1 Artie Shaw hit",
    "___ Might Be Giants (rock group)",
    "Unnamed ones",
    "\"If ___ only knew!\"",
    "He and she",
    "Place to swim or play b-ball",
    "\"So ___ say\"",
    "He and she",
    "\"___ went thataway!\"",
    "Those people",
    "He and she",
    "Unnamed ones",
    "Unnamed ones",
    "\"___went thataway\"",
    "Unnamed ones",
    "Community center, for short",
    "\"___ also serve who ...\""
  ],
  "tata": [
    "\"Ciao\"",
    "\"Goodbye, my friend!\"",
    "\"Bye!\"",
    "\"Au revoir!\"",
    "Parting word",
    "\"Toodles!\"",
    "Bye line?",
    "\"Abyssinia\"",
    "\"G'bye\"",
    "Line out the door?",
    "\"See ya!,\" for a Brit",
    "\"Toodle-oo!\"",
    "\"Cheerio!\"",
    "\"Toodle-oo!\"",
    "\"So long!\"",
    "\"Later, luv!\"",
    "\"Ciao\"",
    "\"Bye!\"",
    "\"Toodle-oo!\"",
    "\"See ya!\"",
    "\"Later, luv\"",
    "\"Toodles!\"",
    "\"Ciao!\"",
    "\"Bye for now!\"",
    "\"So long, dear boy\"",
    "\"Later, old chap\"",
    "\"I'm off!\"",
    "\"Toodles!\"",
    "\"Toodles!\"",
    "\"Toodles,\" in Twickenham",
    "Soho send-off",
    "\"So long!\"",
    "\"Buh-bye!\"",
    "\"Toodles!\"",
    "\"Fare thee well!\"",
    "\"Cheerio\"",
    "Bye at Wimbledon",
    "\"S'long\"",
    "Checkout line?",
    "\"See ya!\"",
    "Father, in Xhosa, and a nickname for 36-Across",
    "\"Cheerio!\"",
    "\"Cheerio!\"",
    "\"Toodles!\"",
    "\"Catch ya later!\"",
    "\"I'm history\"",
    "\"Bye for now\"",
    "\"Ciao\"",
    "British goodbye",
    "\"Gotta run!\"",
    "\"Toodles\"",
    "\"Cheerio!\"",
    "Heathrow takeoff sound?",
    "\"I'm off!\"",
    "\"Toodle-oo\"",
    "\"Abyssinia\"",
    "\"Gotta run!\"",
    "Pre-takeoff cry?",
    "\"Toodles\"",
    "Brighton bye-bye",
    "\"Ciao\"",
    "\"Ciao!\"",
    "\"Ciao!\"",
    "\"Toodles!\"",
    "\"Bye-bye!\"",
    "\"Later\"",
    "\"So long, dahling\"",
    "\"Ciao!\"",
    "\"Later\"",
    "Cry before disappearing",
    "\"Ciao\"",
    "\"See ya\"",
    "Informal farewell",
    "\"I'm history\"",
    "\"Bye now\"",
    "\"Later\"",
    "Parting word",
    "\"See ya!\"",
    "\"Off for now, love\"",
    "So long",
    "\"Bye\"",
    "?ôSee ya?ö",
    "\"So long!\"",
    "\"Toodle-oo\"",
    "\"Au revoir!\"",
    "\"Later!\"",
    "\"Toodles!\"",
    "\"Toodle-oo!\"",
    "It's said when taking off",
    "\"Later\"",
    "\"Ciao!\"",
    "It's heard from one taking off",
    "\"Later\"",
    "\"Later\"",
    "Splitting syllables",
    "\"Bye-bye\"",
    "\"Toodle-oo!\"",
    "\"Later\"",
    "\"Later\"",
    "\"Till we meet again\"",
    "\"Ciao\"",
    "\"See you\"",
    "\"Later!\"",
    "\"Later!\"",
    "\"Bye!\"",
    "\"Later!\"",
    "\"Bye-bye!\"",
    "\"Ciao!\"",
    "\"Ciao\"",
    "\"Later\"",
    "\"See ya\"",
    "Socialite's \"bye\"",
    "Going away statement",
    "\"See ya!\"",
    "\"Cheerio!\"",
    "\"Cheerio!\"",
    "\"See ya!\"",
    "\"Hasta luego!\"",
    "So long, in Soho",
    "\"Toodle-oo\"",
    "\"Ciao\"",
    "\"Toodle-oo!\"",
    "Toodle-oo",
    "\"Bye!\"",
    "\"See you later\"",
    "\"Bye-bye\"",
    "\"So long\"",
    "\"Later\"",
    "\"Bye!\"",
    "\"Cheerio!\"",
    "Bye",
    "London goodbye",
    "Soho \"so long\"",
    "\"Later, Jeeves\"",
    "Bye",
    "\"So long\"",
    "\"See ya\"",
    "Repetitious",
    "\"Later\"",
    "\"See you\"",
    "Soho so-long",
    "So long",
    "\"So long\"",
    "?ôBye-bye?ö"
  ],
  "tkts": [
    "Times Square sign for B'way fans",
    "Speeders' comeuppances: Abbr.",
    "Times Sq. bargain booth",
    "B'way booth in Times Square",
    "Times Square booth sign",
    "Times Square sign shown in lowercase letters",
    "B'way buys",
    "B'way booth in Times Square",
    "B'way buys",
    "Motorists' citations: Abbr.",
    "Longtime Times Sq. site",
    "Ducats: Abbr.",
    "Sign seen in Times Square",
    "Times Square sign",
    "Times Square sign"
  ],
  "waco": [
    "Texas Ranger Hall of Fame locale",
    "U.S. city whose name is composed of two state abbreviations",
    "Home of the Dr Pepper Museum",
    "City on the Brazos River",
    "U.S. radio station whose call letters spell the name of its city",
    "Baylor's home",
    "1993 Branch Davidians/F.B.I. standoff site",
    "Texas site of a 1993 siege",
    "Baylor's home",
    "City between Dallas and Austin",
    "Branch Davidians' home",
    "Texas city where Dr Pepper was created",
    "1993 standoff site",
    "City on the Brazos River",
    "Texas home to Baylor University",
    "Texas home of Baylor University",
    "___ siege (1993 newsmaker)",
    "Only major U.S. city with a radio station whose call letters spell the city's name",
    "Where Snickers, Skittles and Starburst are manufactured",
    "Texas city on the Brazos",
    "Texas Ranger Hall of Fame and Museum site",
    "Home of the Texas Sports Hall of Fame",
    "Baylor University's home",
    "Baylor's city",
    "City midway between Austin and Dallas",
    "Texas home of Baylor University",
    "So-called \"Heart of Texas\"",
    "Baylor's home",
    "1993 standoff site",
    "Home of the Dr Pepper Museum",
    "Steve Martin's birthplace",
    "Dr Pepper Museum locale",
    "Nearest major airport to Bush's Crawford ranch",
    "Texas city on the Brazos",
    "Home to the Dr Pepper Museum",
    "Baylor's city",
    "\"Cross the Brazos at ___\" (1964 country hit)",
    "City on the Brazos",
    "1993 standoff site",
    "City near Crawford, Tex.",
    "City where Dr Pepper was invented",
    "1966 film western set in Texas",
    "Baylor's home",
    "Baylor's home",
    "Baylor's city",
    "City with a radio station that has the same call letters as the city's name",
    "City on the Brazos",
    "City where Dr Pepper originated, 1885",
    "Chisholm Trail town",
    "City on the Brazos",
    "City named for an Indian tribe",
    "City by a lake of the same name",
    "Baylor's home",
    "Texas city on the Brazos",
    "City on the Brazos",
    "Home of Baylor University",
    "1993 standoff site",
    "Seat of McLennan County, Texas",
    "City on the Brazos",
    "City on the Brazos",
    "Western city or its radio station",
    "City on the Brazos",
    "1993 news site",
    "Baylor University site",
    "Texas city",
    "Texas city",
    "City on the Brazos"
  ],
  "sara": [
    "Singer Bareilles",
    "Singer Bareilles",
    "Singer Bareilles",
    "Singer Bareilles",
    "Singer Bareilles who wrote and composed the music for Broadway's \"Waitress\"",
    "___ Lee (dessert brand)",
    "Shepard who wrote \"Pretty Little Liars\"",
    "New York City's ___ Delano Roosevelt Park",
    "Singer Bareilles",
    "\"Storms are brewin'\" in her eyes, in a 1986 #1 hit",
    "___ Blakely, Spanx founder and self-made billionaire",
    "Manhattan's ___ D. Roosevelt Park",
    "With 8-Down, dessert brand that \"nobody doesn't like\"",
    "1986 #1 Starship hit with the lyric \"I'll never find another girl like you\"",
    "Gilbert of \"The Conners\"",
    "Tegan and ___ (pop duo)",
    "Actress Ramirez of \"Grey's Anatomy\"",
    "Gilbert of \"Roseanne\" and \"The Conners\"",
    "\"Roseanne\" actress Gilbert",
    "Singer/songwriter Bareilles",
    "Starship's second #1 hit, after \"We Built This City\"",
    "\"Sweet love of my life,\" in a 1976 Bob Dylan song",
    "___ Lee",
    "Grammy and Tony nominee Bareilles",
    "Singer Bareilles",
    "Bareilles who sang \"Love Song\"",
    "Gilbert of \"The Talk\"",
    "Poet Teasdale",
    "Fleetwood Mac hit",
    "Poet Teasdale",
    "Detective fiction author Paretsky",
    "F.D.R.'s mother",
    "Singer Bareilles",
    "Singer Bareilles with the 2007 hit \"Love Song\"",
    "\"The poet in my heart,\" per a Fleetwood Mac song",
    "1979 Fleetwood Mac hit",
    "___ Lee",
    "Manhattan's ___ D. Roosevelt Park",
    "\"___ Smile\" (1976 top 5 hit)",
    "Poet Teasdale",
    "Ramirez of \"Grey's Anatomy\"",
    "Song title for both Fleetwood Mac and Starship",
    "Actress Rue of \"Rules of Engagement\"",
    "Title girl in a 1979 Fleetwood Mac hit",
    "___ Lee cakes",
    "Singer Bareilles with the 2007 top 10 hit \"Love Song\"",
    "\"___ Smile,\" first hit for Hall & Oates",
    "___ Lee bakery",
    "Ramirez of \"Spamalot\"",
    "F.D.R.'s mother",
    "Tony-winning \"Spamalot\" actress Ramirez",
    "Poet Teasdale",
    "1986 #1 hit for Starship",
    "Poet Teasdale",
    "Title sister played by Shirley MacLaine, 1970",
    "1979 Fleetwood Mac hit",
    "1980s Geena Davis sitcom",
    "Old-time actress Haden",
    "___ Lee cakes",
    "\"CSI\" woman",
    "___ Lee cakes",
    "Bob Dylan's first wife and the title of a song about her",
    "1980s Geena Davis sitcom",
    "Title girl in a 1986 Starship hit",
    "Mystery author Paretsky",
    "Missing from 98-Across",
    "1980's sitcom starring Geena Davis",
    "Detective writer Paretsky",
    "1980's Geena Davis sitcom",
    "1979 Fleetwood Mac hit",
    "___ Lee cakes",
    "___ Lee Corp. (Fortune 500 company)",
    "Sister ___, title character of a 1970 film",
    "1986 #1 Starship hit",
    "___ Delano Roosevelt",
    "Title girl of a 1986 #1 Starship hit",
    "Gilbert of \"Roseanne\"",
    "\"Nobody doesn't like ___ Lee\"",
    "Poet Teasdale",
    "\"Two Mules for Sister ___\" (1970 film)",
    "Song from Dylan's \"Desire\"",
    "Poet Teasdale",
    "1986 #1 hit for Starship",
    "Author Paretsky",
    "___ Delano Roosevelt",
    "1986 #1 hit by Starship",
    "___ Lee",
    "Gilbert of \"Roseanne\"",
    "Mystery writer Paretsky",
    "Poet Teasdale",
    "Poet Teasdale",
    "F.D.R.'s mother",
    "Mystery writer Paretsky",
    "Mrs. Roosevelt",
    "Poet Teasdale",
    "Franklin D.'s mother",
    "Poet Teasdale",
    "\"___ Smile\" (Hall & Oates hit)",
    "F.D.R.'s mother",
    "___ Lee cakes",
    "\"Nobody doesn't like ___ Lee\"",
    "Poet Teasdale",
    "___ Lee Corporation",
    "Poet Teasdale",
    "Poet Teasdale",
    "___ Lee Corporation",
    "Franklin D.'s mother",
    "Poet Teasdale",
    "With 55-Down, corporation named after the founder's daughter",
    "F.D.R.'s mother",
    "Gilbert of \"Roseanne\"",
    "\"___ Smile\" (1976 hit)",
    "She played Darlene on \"Roseanne\"",
    "___ Lee cakes",
    "Crime mystery writer Paretsky",
    "1980's Geena Davis sitcom",
    "With 3-Down, a Chicago bakery",
    "Franklin D.'s mother",
    "\"___ Smile\" (1976 hit)",
    "1986 Starship hit",
    "F.D.R.'s mother",
    "\"Two Mules for Sister ___\"",
    "Gilbert of \"Roseanne\"",
    "1986 #1 hit for Starship",
    "Gilbert of \"Roseanne\"",
    "Title sister of a 1970 film",
    "___ Lee cakes",
    "___ Lee cakes",
    "Poet Teasdale",
    "Film \"Two Mules for Sister ___\"",
    "Geena Davis sitcom",
    "___ Lee",
    "Actress Gilbert of \"Roseanne\"",
    "Title sister in an Eastwood film",
    "Actress Allgood",
    "Poet Teasdale",
    "___ Lee cheesecake",
    "Hall and Oates' \"___ Smile\"",
    "With 22 Down, a cake brand",
    "F.D.R.'s mother ___ Delano",
    "\"___ Smile\" (1976 Hall & Oates hit)",
    "Allgood of stage and screen",
    "1986. #1 hit by Starship",
    "Lee or Teasdale",
    "___ Delano (F.D.R.'s mother)",
    "Cakemaker Lee",
    "F.D.R.'s mother",
    "Poet Teasdale"
  ],
  "skip": [
    "Problem on a vinyl record",
    "Sound like a broken record",
    "Uno card with a slashed circle",
    "Pass up",
    "Cut",
    "Pass on",
    "Problem with a 45-r.p.m. record",
    "What old records and happy-go-lucky people may do",
    "Not go to",
    "Leave undone",
    "Bypass",
    "Record problem",
    "Hop, ___ and a jump",
    "Hop, ___ and a jump",
    "Record problem",
    "Pass over",
    "LP problem",
    "Needle problem",
    "Problem with an old 45",
    "Playback problem",
    "Not attend",
    "Deejay's bane",
    "Overlook",
    "Pass over",
    "Not go to",
    "Needle problem",
    "Go from first to third, say",
    "Pass on",
    "Record problem",
    "LP problem",
    "With 69-Across, split",
    "Old record problem",
    "Problem for a deejay",
    "Record problem",
    "Record problem",
    "Leave out",
    "Omit",
    "LP flaw",
    "Ignore",
    "Go past",
    "Disregard",
    "Record problem",
    "Gambol",
    "LP flaw",
    "Pass over",
    "Deejay's worry",
    "Record problem",
    "Pass over",
    "Pass over",
    "Abscond",
    "Hop-jump intervener",
    "Pass up",
    "Jump",
    "Leave hastily",
    "Leave out"
  ],
  "redo": [
    "Mulligan, to a golfer",
    "Take two",
    "Overhaul",
    "Command+Y, on a Mac",
    "What a let in tennis affords",
    "Edit menu option",
    "Overhaul",
    "Start over",
    "Overhaul",
    "Second attempt, informally",
    "Overhaul",
    "Second attempt, informally",
    "Another go",
    "Take a mulligan on",
    "Option in an Edit menu",
    "Mulligan, in golf",
    "Second chance",
    "Overhaul",
    "Overhaul",
    "Take another shot at",
    "Overhaul",
    "Mulligan",
    "Change the decor of",
    "Face-lift",
    "Control+Y on a PC",
    "Fresh styling",
    "Control+Y on a PC or Command+Y on a Mac",
    "Overhaul",
    "Start over",
    "Fresh styling",
    "Overhaul",
    "Change",
    "Another attempt",
    "Give a face-lift",
    "Give a face-lift",
    "Overhaul",
    "Start all over",
    "Edit menu command",
    "Start all over",
    "Second version",
    "Change",
    "Make over",
    "Make over",
    "Request after a failure, sometimes",
    "Make over",
    "Make over",
    "Fresh take, informally",
    "Make over completely",
    "Makeover",
    "Mulligan, say",
    "Edit menu command",
    "Start over on",
    "Give a makeover",
    "Make over",
    "Take from the top",
    "Make over",
    "Edit menu command",
    "Make over",
    "Work over",
    "Make over",
    "Second serve, for example",
    "Fix up",
    "Change",
    "Makeover",
    "Make over",
    "Make over",
    "Decorate again",
    "Change the decor of",
    "Change the decor of",
    "Make over",
    "Fix",
    "Change, as the decor",
    "Make over",
    "Fix",
    "Make over",
    "Try again",
    "Make over",
    "Renovation",
    "Give new decor",
    "Completely fix",
    "Make a change in the decor",
    "Make over",
    "Fix up",
    "Make over",
    "Make over",
    "Start over",
    "More than patch up",
    "Make over",
    "Start over",
    "Fix up",
    "Fix up",
    "Overhaul",
    "Spruce up",
    "Fix up",
    "Decorate anew",
    "Fix",
    "Fix up",
    "Go over a second time",
    "Change",
    "Change the furnishings",
    "Makeover",
    "Overhaul",
    "Fix up",
    "Decorate anew",
    "Overhaul",
    "Fix",
    "Overhaul",
    "Change the decor",
    "Fix up",
    "Overhaul",
    "Completely fix",
    "Make over",
    "Make over",
    "Fix up",
    "Overhaul",
    "Word processor command",
    "Start from scratch",
    "Start over",
    "Start all over",
    "Overhaul",
    "Start again from scratch",
    "Go back to square one on",
    "Give a makeover",
    "Change the decor",
    "Work over",
    "Give a face lift",
    "Update, maybe",
    "Make over",
    "Change the decorations",
    "Take it from the top",
    "Make over",
    "Make over",
    "Overhaul",
    "Give a new look",
    "Change the decor",
    "Start from scratch",
    "Effect a makeover",
    "Fix, artwise",
    "Effect a makeover",
    "Second draft, informally",
    "Make over",
    "Try again",
    "Change the decor",
    "Try again",
    "Write over",
    "Change the decor"
  ],
  "shak": [
    "Frequent quotation attribution: Abbr.",
    "Common English lit subj.",
    "Source of the phrases \"cakes and ale\" and \"milk of human kindness\": Abbr.",
    "Most-quoted author in the O.E.D.: Abbr.",
    "Big section of Bartlett's: Abbr.",
    "Common quotation attribution: Abbr."
  ],
  "clam": [
    "Happy as a ___",
    "End of a \"happy\" simile",
    "Chowder ingredient",
    "Smacker",
    "Geoduck, e.g.",
    "Shut (up)",
    "Silent type",
    "Tough person to get information from",
    "One keeping a secret, metaphorically",
    "Quahog or geoduck",
    "Silent sort",
    "Tight-lipped sort",
    "Chowder flavor",
    "Raw bar offering",
    "One who's tight-lipped",
    "Refuse to talk, with \"up\"",
    "Simoleon",
    "Dollar, in slang",
    "Symbol of happiness",
    "Place for steamers",
    "Tight-lipped sort",
    "Paella ingredient, perhaps",
    "Chowder ingredient",
    "Happy as a ___",
    "Tight-lipped sort",
    "Blabber's opposite",
    "Paradigm of happiness",
    "Buck",
    "Symbol of uncommunicativeness",
    "Stop talking, with \"up\"",
    "Chowder morsel",
    "Buck",
    "Symbol of happiness",
    "Buck",
    "Good secret-keeper",
    "Quahog, e.g.",
    "Silent sort",
    "Dollar, slangily",
    "Secretive sort",
    "Littleneck, e.g.",
    "Silent one",
    "Buck",
    "Secretive sort",
    "Soup or sandwich ingredient",
    "One who's close-mouthed",
    "Shut (up)",
    "Steamer, e.g.",
    "Chowder morsel",
    "One who won't tell",
    "Chowder morsel",
    "Smacker",
    "Hardly a blabbermouth",
    "Symbol of happiness",
    "Shut (up)",
    "Hardly a blabbermouth",
    "Bisque bit",
    "Symbol of noncommunication",
    "Hardly a blabbermouth",
    "Smacker",
    "Dollar, in slang",
    "With 15-Across, linguini topping",
    "Silent one",
    "Symbol of happiness",
    "Hardly a blabbermouth",
    "Silent one",
    "Quahog",
    "Paradigm of happiness",
    "Happy one"
  ],
  "ette": [
    "Suffix with bachelor",
    "Suffix with Smurf",
    "Suffix with towel",
    "Suffix with Smurf",
    "Suffix with launder",
    "Suffix with kitchen",
    "Suffix with novel",
    "Suffix with bachelor or kitchen",
    "Suffix with kitchen",
    "Trix alternative",
    "Suffix with kitchen",
    "Suffix with major",
    "Suffix with cigar",
    "Suffix with bachelor",
    "Rock follower?",
    "Suffix with bachelor",
    "Suffix with luncheon or kitchen",
    "Relative of -ess",
    "Suffix with disk",
    "Diminutive suffix",
    "Suffix with cigar",
    "Suffix with major",
    "Feminine ending",
    "Suffix with towel",
    "Relative of -ine",
    "Suffix with cigar",
    "Suffix with disk",
    "Suffix with kitchen",
    "Suffix with kitchen",
    "Novel's end?",
    "Relative of -kin",
    "Novel conclusion?",
    "Suffix with major",
    "Trix alternative?",
    "Little: Suffix",
    "Cigar butt?",
    "Suffix with Rock",
    "Sermon follower",
    "Couch attachment?",
    "Suffix with luncheon",
    "Suffix with kitchen",
    "Suffix with cigar",
    "Novel addendum?",
    "Suffix with major",
    "Suffix with cigar",
    "Cigar's end?",
    "Suffix with kitchen",
    "Major closing?",
    "Suffix with Rock",
    "Small: Suffix",
    "Suffix with towel",
    "Cigar's end?",
    "Kitchen extension?",
    "Novel conclusion?",
    "Ending for a female Smurf",
    "Un-P.C. suffix, to many",
    "Suffix with disk",
    "Suffix with major",
    "Little: Suffix",
    "Diminutive ending",
    "Suffix with cigar",
    "Couch extension?",
    "Suffix with kitchen or room",
    "Cousin of -ule",
    "Major conclusion?",
    "Suffix with major",
    "Suffix with kitchen",
    "Suffix with disk",
    "Rock's end",
    "Novel ending?",
    "Suffix with novel",
    "Suffix with super",
    "Major conclusion?",
    "Suffix meaning \"little\"",
    "Suffix with super",
    "Sermon ending?",
    "Suffix with marion",
    "Suffix with cigar",
    "Suffix with kitchen",
    "Major finale?",
    "Suffix with launder",
    "Suffix with major",
    "Feminine name ending",
    "Female name ending",
    "Suffix with super",
    "Suffix with cigar",
    "Suffix with leather",
    "Suffix with novel",
    "Novel conclusion?",
    "Major conclusion",
    "Kitchen extension?",
    "Ending with major",
    "Kitchen add-on?",
    "Suffix with super",
    "Suffix with kitchen",
    "Kitchen addition",
    "Novel ending",
    "Kitchen extension",
    "Suffix with major",
    "Suffix with major",
    "Suffix with lay",
    "Feminine suffix",
    "Suffix with couch",
    "Woman's name suffix",
    "Sermon ending?",
    "Room add-on?",
    "Suffix with sermon",
    "Suffix with kitchen",
    "Suffix with disk or novel",
    "Leather finish?",
    "Suffix with major",
    "Sermon ender?",
    "Diminutive suffix",
    "Suffix with Rock",
    "Novel conclusion?",
    "Launder ending",
    "Ranch add-on?",
    "Major ending",
    "Suffix with 29-Down",
    "Luncheon follower",
    "Sermon's conclusion?",
    "Kitchen annex?",
    "Paul feminizer",
    "Suffix with luncheon",
    "Suffix with quart",
    "Usher follower?",
    "Major addition?",
    "Novel ending?",
    "Suffix with kitchen",
    "Suffix with towel",
    "Super ending",
    "Kitchen suffix",
    "Suffix with cigar",
    "Suffix with novel",
    "Sermon ender",
    "Suffix with super",
    "Kitchen extension?",
    "Suffix with disk",
    "Sermon conclusion?",
    "Little ending",
    "Woman's name suffix",
    "Novel ending?",
    "Suffix with fleur",
    "Novel conclusion",
    "Cigar end?",
    "Ending with Smurf or Rock",
    "Wagon attachment?",
    "Major finale?",
    "Novel ending",
    "\"Rock\" suffix",
    "Politically incorrect suffix",
    "Suffix with leather",
    "Palindromic suffix",
    "Kin of -ule",
    "Suffix with leather",
    "Suffix with spinner",
    "Follower of Jean?",
    "Little on the end?",
    "Kitchen extension",
    "Suffix with disk",
    "Politically-incorrect ending",
    "Suffix with room",
    "Non-P.C. suffix",
    "Novel ending",
    "Suffix with bachelor",
    "Cigar ending",
    "Feminine suffix",
    "Suffix with disk",
    "Suffix with disk",
    "Suffix with lay",
    "Suffix with satin",
    "Diminutive suffix",
    "Suffix with disk",
    "Suffix with cigar",
    "Suffix with kitchen",
    "Suffix with Rock",
    "Kitchen finish",
    "Suffix with ranch",
    "Usher tack-on",
    "Small: Suffix",
    "Suffix with Rock",
    "Suffix with cigar or major",
    "Feminizing suffix",
    "Cigar tip",
    "Major addition",
    "Suffix with super or major",
    "Little: Suffix",
    "Maison follower",
    "Feminine ending",
    "Suffix with novel",
    "Diminutive suffix",
    "Major suffix",
    "Wagon add-on",
    "Cigar tip",
    "Major ending",
    "Little: Suffix",
    "Kitchen addition?",
    "Kitchen extension",
    "Little on the end",
    "Leather add-on",
    "Suffix with kitchen",
    "Cigar tip",
    "Small: Suffix",
    "Room extension",
    "Cigar's end",
    "Leather attachment",
    "Novel ending",
    "Cigar's end",
    "Luncheon follower",
    "Marion's finish",
    "Suffix with kitchen or usher",
    "Luncheon finisher",
    "\"Major\" suffix",
    "Cigar ending"
  ],
  "ovum": [
    "Egg to be fertilized",
    "Cell with 23 unpaired DNA strands",
    "Tube traveler?",
    "Egg cell",
    "Largest cell in the human body",
    "Egg",
    "Tube traveler",
    "Egg cell",
    "Sex-ed subject",
    "It goes down a fallopian tube",
    "Fertility clinic cell",
    "Bank asset that's frozen?",
    "Fertility doctor's focus",
    "Egg cell",
    "Reproductive cell",
    "Ooplasm locale",
    "Fertility clinic cell",
    "Part of a preconception?",
    "Certain gamete",
    "Egg, biologically",
    "Certain gamete",
    "Egg",
    "Reproductive seed",
    "Egg",
    "Egg cell",
    "It may go down a tube",
    "Egg",
    "Breakfast item in old Rome?",
    "What a follicle holds",
    "One in the sac",
    "Start of something big?",
    "Gamete",
    "Egg",
    "Egg",
    "Egg cells",
    "Female egg"
  ],
  "coin": [
    "To ___ a phrase",
    "Penny or nickel",
    "Loonie or toonie",
    "Money that may go in a slot",
    "With 55-Down, actions that can be performed nine times in this puzzle without affecting any of the clues?",
    "Sacagawea dollar, e.g.",
    "Come up with",
    "The Canadian loonie or toonie, e.g.",
    "Invent",
    "Close-up magician's prop",
    "One called upon to decide",
    "See 49-Across",
    "Invent",
    "Invent, as a phrase",
    "Eagle, e.g.",
    "Nickel or dime",
    "Create, as a phrase",
    "Be the first to use",
    "Parking meter filler",
    "Mint",
    "Invent",
    "Nickel or dime",
    "With 42-Across, starts of pro football games",
    "It's flipped in this puzzle",
    "It's slipped in a slot",
    "Football referee's need",
    "Invent, as a phrase",
    "Half-dollar, say",
    "Conflict resolver, at times",
    "Kind of purse",
    "Nickel or copper, but not tin",
    "Louis d'or, e.g.",
    "Counterpart to paper",
    "Fountain throwaway?",
    "Invent",
    "Susan B. Anthony is on one",
    "Mint",
    "Originate",
    "Originate, as a phrase"
  ],
  "wise": [
    "Like an owl, in a simile",
    "Sage",
    "\"It is impossible to love and to be ___\": Francis Bacon",
    "Sage",
    "Full of sass",
    "Sage",
    "Three ___ Men",
    "Sagacious",
    "Smart-alecky",
    "Fresh",
    "Solomonic",
    "Learned",
    "One way to crack",
    "Smart-alecky",
    "Like Athena",
    "Impudent",
    "Owlish",
    "Sassy",
    "With 8-Across, Sonia Sotomayor, self-professedly",
    "Like an oracle",
    "Oracular",
    "___ as an owl",
    "Fresh",
    "Learned",
    "Sage",
    "Sobriquet for Charles V, with \"the\"",
    "Smart-mouthed",
    "Impudent",
    "Hip",
    "Smart-alecky",
    "Solomonlike",
    "Knowing",
    "Sensible",
    "Cheeky",
    "Sage",
    "Smart-alecky",
    "Like Solomon",
    "Smart-alecky",
    "Sage",
    "Smart-alecky",
    "All-knowing",
    "Smart-alecky",
    "Saucy",
    "Pansophic",
    "Sage",
    "Chip maker",
    "Judicious",
    "Chip maker",
    "Like Solomon",
    "Sassy",
    "Owllike",
    "Smart-alecky"
  ],
  "hale": [
    "Patriot Nathan",
    "Physically fit",
    "In great shape",
    "Robust",
    "In fine fettle",
    "Robust",
    "In prime condition",
    "Robust",
    "Robust",
    "In good shape",
    "In good shape",
    "In great shape",
    "Robust",
    "In prime condition",
    "In fine fettle",
    "Fit",
    "In the pink",
    "In fine fettle",
    "Physically fit",
    "In fine shape",
    "Well",
    "Sound in body",
    "Robust",
    "In fine fettle",
    "Sound",
    "___ and hearty",
    "Fit",
    "Sound",
    "Fit",
    "Fit",
    "Sound",
    "Strong",
    "Robust",
    "Fit",
    "In the pink",
    "Sound",
    "In fine fettle",
    "In the pink",
    "Robust",
    "Hearty's partner",
    "Sarah Josepha ___, who wrote \"Mary Had a Little Lamb\"",
    "Robust",
    "Robust",
    "In good shape",
    "___ and hearty",
    "Hardy",
    "Revolutionary with a regret",
    "In good health",
    "Vigorous",
    "In good shape",
    "Fit as a fiddle",
    "Robust",
    "Robust",
    "Hearty's companion",
    "Sound",
    "Healthy",
    "Infirmity-free",
    "Vigorous",
    "Sound",
    "In good health",
    "In good health",
    "In fine fettle",
    "Robust",
    "Well",
    "Vigorous",
    "In fine fettle",
    "Sound",
    "Robust",
    "Sound in body",
    "Irwin of the P.G.A.",
    "Clearly in good health",
    "Sound",
    "In vigorous health",
    "Hearty partner",
    "Robust",
    "Revolutionary hero Nathan",
    "Robust",
    "Hearty companion",
    "Patriot Nathan",
    "Fit",
    "Renowned \"regretter\"",
    "Robust",
    "Vigorous",
    "Patriot of 1776",
    "Sound",
    "Patriot Nathan",
    "Sound",
    "Hearty accompaniment?"
  ],
  "race": [
    "10K or marathon",
    "See 38-Across",
    "See 30-Across",
    "Speed",
    "Tear",
    "With 107-Across, bad sort of competition",
    "Marathon, e.g.",
    "Tour de France, e.g.",
    "Campaign ... or a campaign topic",
    "Hurry",
    "Tear",
    "What lovers' hearts do",
    "Speed",
    "Political competition",
    "5K or 10K",
    "Census datum",
    "It can be presidential",
    "Speedway event",
    "Go a mile a minute",
    "Hurry",
    "Sprint",
    "Campaign ... or campaign topic",
    "Marathon, e.g.",
    "Subject of much Dave Chappelle humor",
    "Campaign",
    "Census form info",
    "Campaign",
    "Marathon or sprint",
    "Dash",
    "Exceed the speed limit, maybe",
    "Hustle",
    "5K or 10K",
    "Tear",
    "Occasion for the fleet",
    "Speed competition",
    "Easter egg roll, say",
    "10K, e.g.",
    "Go to the tape?",
    "Political contest",
    "Indy 500, e.g.",
    "Steeplechase, e.g.",
    "Where \"They're off!\" may be heard",
    "Indy 500, e.g.",
    "Go like the dickens",
    "See 10-Across",
    "Human ___",
    "5K, for one",
    "It may concern arms or contain legs",
    "What dragsters and some hearts do",
    "Marathon, e.g.",
    "Talladega 500, e.g.",
    "Put the pedal to the metal",
    "Competition",
    "10K or marathon",
    "Tear",
    "See 18-Across",
    "Tour de France, e.g.",
    "A long one may have legs",
    "Tear",
    "Dash",
    "Derby",
    "Preakness, for one",
    "Run for the money",
    "People",
    "10K, e.g.",
    "Derby",
    "What hearts sometimes do",
    "Le Mans event",
    "5K, e.g.",
    "Beat fast",
    "5K or 10K",
    "Cannonball",
    "Marathon",
    "One may be on its last leg",
    "Dash",
    "10K, for one",
    "Humans, e.g.",
    "100-meter, e.g.",
    "Hurtle",
    "440 or 10K",
    "Derby",
    "Marathon",
    "Derby",
    "Mudder's day event",
    "Derby, e.g.",
    "It starts and ends with a line",
    "Event for which 9-Across is needed",
    "Sprint",
    "Gallop",
    "Tear",
    "Derby",
    "Battle the clock",
    "200-meter, e.g.",
    "Family of man",
    "Regatta",
    "Word with arms or foot",
    "Marathon",
    "Derby",
    "Dash",
    "Heat",
    "Run",
    "Three-legged ___",
    "Motocross, e.g.",
    "Compete, in a way",
    "Camptown activity",
    "Competition",
    "500, e.g.",
    "Campaign",
    "Derby",
    "Le Mans, e.g.",
    "Ethnic group",
    "Tear",
    "Iditarod, e.g.",
    "Campaign"
  ],
  "cfcs": [
    "Ozone-harming compounds, for short",
    "Ozone destroyers, for short",
    "Ozone-destroying chemicals, for short",
    "Ozone destroyers, for short",
    "Ozone destroyers, for short"
  ],
  "esos": [
    "Those: Sp.",
    "Those: Sp.",
    "Those, to Jose",
    "Those, in Quito",
    "Those, in Segovia",
    "Those: Sp.",
    "Those, in Spain",
    "Those, in Tijuana",
    "Just those of Juan's things?",
    "Those, in Mexico",
    "Those, in Taxco",
    "See 23-Across",
    "Those: Sp.",
    "Those: Sp.",
    "Those, to Jose",
    "Those south of the border?",
    "Those, to Jose",
    "Those, to Jorge",
    "Those, to Juan",
    "Those, in Tijuana",
    "Those, to Teodoro",
    "Those Spaniards",
    "Those south of the border",
    "Those, in Toledo",
    "Those muchachos",
    "Those, in Toledo",
    "Those, to Teodoro",
    "Those, in Mexico",
    "Those, in Tijuana",
    "Those, to Carlos",
    "Those, to Teodoro",
    "Those caballeros",
    "Those, in San Jose",
    "Those, to Jose",
    "Those caballeros",
    "Those, to Tomas",
    "Those, to Jose",
    "Those, to Munoz",
    "Those, to Jose",
    "Those, to Tomas",
    "Those, in Tegucigalpa",
    "Those, to Jose",
    "Those caballeros",
    "Those of Juan's things",
    "Those, to Tomas",
    "Those, Madrid",
    "Those, in Tegucigalpa",
    "Just those of Juan things?"
  ],
  "pace": [
    "Focus of a marathon runner's training",
    "Pick up the ___",
    "Rate",
    "With 5-Across, warm-up circuits for race car drivers",
    "Walk back and forth",
    "Duel unit",
    "Tempo",
    "Clip",
    "Walk nervously back and forth",
    "Going rate",
    "Something to set or pick up",
    "Tempo",
    "Tempo",
    "Walk back and forth nervously",
    "Go back and forth",
    "Tempo",
    "Distance unit of about 30 inches",
    "Tempo",
    "Show anxiety, in a way",
    "What a lead runner sets",
    "Marathoner's concern",
    "Tempo",
    "Tempo",
    "Marathon runner's stat",
    "Duel unit",
    "Distance unit on a treasure map",
    "Step",
    "Tempo",
    "Speed",
    "Show anxiety, in a way",
    "Clip",
    "R.I.P. part",
    "Eight minutes/mile in a marathon is a good one",
    "Tempo",
    "Tempo",
    "Walk a hole in the carpet, maybe",
    "A slowpoke may be asked to pick it up",
    "Wear out the carpet, maybe",
    "Work off nervous energy, in a way",
    "Double time, for one",
    "Kind of car seen at Indy",
    "A cadet might be asked to pick it up",
    "A sergeant might ask soldiers to pick it up",
    "Gait",
    "Walk like an expectant dad",
    "Show worry in the waiting room, maybe",
    "A sergeant might ask a soldier to pick it up",
    "Walk to and fro",
    "Await delivery anxiously",
    "Speed",
    "Show worry",
    "Tempo",
    "The \"P\" in a Latin R.I.P.",
    "Clip",
    "Part of R.I.P.",
    "Dueler's unit",
    "Horse's motion",
    "A lap a minute, e.g.",
    "Act the expectant father",
    "Step",
    "It's set by a runner",
    "It might need to be picked up",
    "Stride",
    "Tempo",
    "Walk back and forth",
    "Show worry",
    "Walk nervously",
    "Distance unit in duels",
    "Walk back and forth",
    "Wear out the carpet in the waiting room",
    "Show restlessness, in a way",
    "Clip",
    "Rate",
    "Walk the waiting room",
    "Clip",
    "Jog, e.g.",
    "Big Apple university",
    "Step",
    "New York's ___ University",
    "Tempo",
    "It may be picked up or set",
    "Tempo",
    "Stride",
    "Runners try to pick it up",
    "Manhattan campus",
    "Speed"
  ],
  "pact": [
    "Truce",
    "Covenant",
    "Triumph of diplomacy",
    "Solemn agreement",
    "Bargain",
    "Hostilities ender",
    "Entente",
    "Treaty",
    "Treaty",
    "Agreement",
    "A handshake may seal one",
    "Bargain",
    "Covenant",
    "Nafta, for one",
    "Binder?",
    "Summit success",
    "Treaty",
    "Diplomatic goal",
    "Summit conference goal",
    "Deal",
    "Treaty",
    "Joining of opposite sides",
    "Agreement",
    "Peacemaker's goal",
    "War ender",
    "Accord",
    "Concordat",
    "War ender",
    "Treaty",
    "Treaty",
    "Hostilities ender",
    "Agreement",
    "Warsaw ___",
    "Hostilities ender",
    "Result of a handshake, maybe",
    "Diplomat's goal",
    "Treaty",
    "Treaty",
    "Munich ___ of 1938",
    "Nafta, for one",
    "Hostilities ender",
    "Treaty",
    "Agreement",
    "A handshake may seal it",
    "Contract",
    "Agreement",
    "Agreement",
    "War ender",
    "Agreement",
    "Treaty",
    "Agreement",
    "Treaty",
    "Bargain",
    "Bargain",
    "Corcordat",
    "Treaty",
    "Warsaw ___",
    "Alliance basis",
    "Agreement",
    "Covenant",
    "Bargain",
    "Accord",
    "Tontine, e.g.",
    "Warsaw ___",
    "Deal",
    "Covenant"
  ],
  "atop": [
    "Standing on",
    "On",
    "Standing above",
    "Upon",
    "Straddling",
    "Crowning",
    "Perched on",
    "On",
    "On",
    "Riding on",
    "On",
    "On",
    "Surmounting",
    "Resting on",
    "Riding on",
    "On",
    "Riding, say",
    "Surmounting",
    "On",
    "Above",
    "At the apex of",
    "On",
    "On",
    "On",
    "On",
    "Sitting on",
    "Surmounting",
    "Crowning",
    "Crowning",
    "On",
    "Straddling",
    "Surmounting",
    "On",
    "O",
    "Looking down on?",
    "Resting upon",
    "Perched on",
    "Opposite of under",
    "On",
    "Capping",
    "Covering",
    "Surmounting",
    "Resting on",
    "Capping",
    "Riding on",
    "On",
    "Surmounting",
    "Resting on",
    "Capping",
    "Resting on",
    "On",
    "Surmounting",
    "On",
    "Mounted on",
    "Surmounting",
    "At the peak of",
    "Surmounting",
    "On",
    "Perched on",
    "Perched on",
    "On the summit of",
    "Upon",
    "Over",
    "Perched on",
    "Perched on",
    "Perched on",
    "Having surmounted",
    "Capping",
    "On",
    "Above",
    "On",
    "Sitting on",
    "Straddling",
    "On the peak of",
    "Opposite of under",
    "On",
    "Surmounting",
    "Perched on",
    "Sitting on",
    "Sitting on",
    "Perched on",
    "On",
    "On",
    "Over",
    "Perched on",
    "On",
    "Straddling",
    "Straddling",
    "At the peak of",
    "On",
    "On",
    "Straddling",
    "On",
    "Capping",
    "Crowning",
    "Above",
    "Straddling",
    "Mounted on",
    "On",
    "Riding",
    "Crowning",
    "Looking down from",
    "Surmounting",
    "At the peak of",
    "On",
    "Upon",
    "At the peak of",
    "On",
    "Above",
    "On",
    "At the summit of",
    "On",
    "On",
    "On",
    "On",
    "At the summit of",
    "Resting on",
    "Perched on",
    "Resting on",
    "Looking down on?",
    "Surmounting",
    "Superimposed on",
    "Resting on",
    "Riding",
    "Sitting on",
    "Straddling",
    "Surmounting",
    "Right at one's peak",
    "On",
    "On",
    "On",
    "Surmounting",
    "On",
    "At the summit of",
    "Resting on",
    "Riding",
    "Sitting on",
    "On the peak of",
    "Covering",
    "Over",
    "On",
    "Over",
    "Over",
    "Crowning",
    "Over",
    "Straddling",
    "At the acme of",
    "Resting on",
    "On",
    "Surmounting",
    "Over",
    "Resting on",
    "At the apex of",
    "Superimposed",
    "Upon",
    "On",
    "Over",
    "Upon",
    "In a superior position to",
    "Over",
    "Surmounting",
    "Over",
    "Over",
    "Upon",
    "Straddling",
    "On",
    "Superior to",
    "On",
    "At the summit of",
    "Not under",
    "In a good lookout position",
    "Over",
    "At the summit of"
  ],
  "rofl": [
    "Texter's \"Hilarious!\"",
    "\"That's hilarious!,\" in a text",
    "Not just \"ha ha\"",
    "&#x1F602; &#x1F602; &#x1F602;",
    "\"Hilarious!,\" online",
    "\"Hilarious!,\" briefly",
    "\"2 funny!!!\"",
    "Texter's expression spelled out by the starts of 18-, 28-, 46- and 59-Across"
  ],
  "tofu": [
    "Soy something",
    "Vegan protein source",
    "Mapo ___ (traditional Sichuan dish)",
    "Mapo ___ (spicy Sichuan dish)",
    "Meat substitute",
    "Vegetarian choice",
    "Meat substitute",
    "Food sold in blocks",
    "Vegan staple",
    "Staple of a vegan diet",
    "Protein-rich vegan staple",
    "High-protein food that often comes in cubes",
    "Vegan-friendly protein source",
    "Vegetarian's protein source",
    "Vegetarian's protein source",
    "Bean curd",
    "Pretty tasteless food",
    "Protein-rich food",
    "Tasteless stuff",
    "Protein source for vegetarians",
    "Part of many a vegan's diet",
    "Sukiyaki ingredient",
    "It's sold in tubs",
    "Bean curd",
    "Food that wiggles",
    "Vegan protein option",
    "Some soft white blocks",
    "Good source of protein",
    "Bean curd",
    "High-protein food often found in vegetarian cuisine",
    "Vegan's protein source",
    "Vegetarian's protein source",
    "Vegan's protein source",
    "Vegetarian's protein source",
    "Blocks in a healthful diet?",
    "Food that may come in small cubes",
    "Good source of protein",
    "Vegetarian's protein source",
    "Hot-and-sour soup ingredient",
    "Sukiyaki ingredient",
    "Meat substitute",
    "Soy protein source",
    "Sukiyaki ingredient",
    "Soy product",
    "Vegetarian staple",
    "Soybean product",
    "High-protein food",
    "Good source of protein",
    "Some health food",
    "Health store offering",
    "Soybean dish",
    "Sukiyaki ingredient",
    "Protein source",
    "Hot and sour soup ingredient",
    "Bean curd",
    "58-Across ingredient",
    "Vegetarian staple",
    "Modern protein source",
    "Soup ingredient",
    "Protein source",
    "Multipurpose food item",
    "Health food item",
    "Bean paste",
    "Protein-rich edible",
    "Health-food store staple"
  ],
  "elis": [
    "Certain Ivy Leaguers",
    "Some Ivy Leaguers",
    "Skull and Bones members",
    "Yale students",
    "Ancient Greek land that hosted the Olympics",
    "Ancient land where the Olympics began",
    "Yale students",
    "Jodie Foster and Meryl Streep, collegiately",
    "Whitney and Manning",
    "Site of ancient Greek Olympics",
    "Whiffenpoofs, e.g.",
    "Big Green rivals",
    "New Haven collegians",
    "Some Ivy Leaguers",
    "Yale Whale players",
    "Yale students",
    "Bush 41 and Bush 43, for two",
    "Yale students, informally",
    "\"___ Coming\" (1969 hit)",
    "Dick Cheney and George W. Bush, for two",
    "New Haven alumni",
    "Bulldogs",
    "Home of the ancient Olympic games",
    "21-Down students",
    "Skull and Bones members",
    "Yale students",
    "Big Red rivals",
    "Yale students",
    "Yalies",
    "Taft and Bush, collegiately",
    "Participants in the annual Safety Dance",
    "New Haven collegians",
    "Many students on \"Gilmore Girls\"",
    "Some Ivy Leaguers",
    "Home of the ancient Olympics",
    "Y's guys?",
    "\"We'll give a long cheer for ___ men\" (\"Down the Field\" lyric)",
    "Some Ivy Leaguers",
    "Skull and Bones members",
    "\"___ Coming\" (1969 Three Dog Night hit)",
    "Their mascot is Handsome Dan",
    "\"___ Coming\" (1969 hit)",
    "Some Ivy Leaguers",
    "Bush and Kerry, once",
    "Some Connecticut collegians",
    "Some Ivy Leaguers",
    "Bush and Kerry, collegiately",
    "\"___ Coming\" (1969 Three Dog Night hit)",
    "Yale students",
    "Ivy League team",
    "New Haven collegians",
    "Crimson rivals",
    "Crimson rivals",
    "?ô___ Coming?ö (1969 hit)",
    "Some Ivy Leaguers",
    "Elm City students",
    "\"Boola Boola\" singers",
    "George and George W., e.g.",
    "The Bushes, collegiately",
    "\"Y\" sporters",
    "Yalies",
    "Yalies",
    "Ivy League squad",
    "Yale students",
    "\"Boola Boola\" singers",
    "Ancient Olympics site",
    "Dubya and classmates",
    "Yale players",
    "48-Down team",
    "Crimson rivals",
    "\"Boola Boola\" collegians",
    "George and George W., e.g.",
    "Whitney and Wallach",
    "Whiffenpoofs",
    "New Haven collegians",
    "Site of the ancient Olympic games",
    "Yalies",
    "Bulldogs",
    "Ancient Olympics site",
    "Ivy Leaguers",
    "\"___ Coming\" (1969 hit)",
    "\"The Whiffenpoof Song\" singers",
    "Tigers foe",
    "Yalies",
    "12-time Ivy League football champs",
    "Ancient ally of Sparta",
    "Clinton and Bush, e.g.",
    "Ivy League team",
    "\"___ Coming\" (1969 hit)",
    "Connecticut Ivy Leaguers",
    "Some Ivy Leaguers",
    "Crimson rivals",
    "Whiffenpoofs",
    "Yalies",
    "Bulldogs",
    "New Haven collegians",
    "Site of ancient Olympia",
    "Yale Bowl hosts",
    "Ivy League team",
    "Some Ivy Leaguers",
    "Elm City collegians",
    "Ivy Leaguers",
    "Bulldogs",
    "Ivy leaguers",
    "Yale Bowl hosts",
    "New Haven students",
    "Yalies",
    "\"___ Coming\" (1969 hit)",
    "Some Ivy Leaguers",
    "Crimson rivals",
    "Skull and Bones members",
    "Certain Ivy Leaguers",
    "Whitney and Wallach",
    "Site of the first Olympics",
    "Some Ivy Leaguers",
    "Crimson foes",
    "Whiffenpoof Society members",
    "Crimson rivals",
    "Boola-boola cheerers",
    "\"___ Coming\" (1969 pop hit)"
  ],
  "fwiw": [
    "Qualification shorthand",
    "\"Just sayin',\" in shorthand",
    "Texting equivalent of \"This is just my opinion, but ...\"",
    "Letters suggesting \"I'll just go ahead and throw this out\"",
    "\"Here are my thoughts,\" online",
    "Qualifier in texts",
    "Just my opinion, in a tweet",
    "\"Take that as you will,\" in Internet shorthand",
    "Bit of cyberchat shorthand"
  ],
  "peru": [
    "Its national drink is the pisco sour",
    "Indiana city that's 100 miles west of Lima, Ohio",
    "Country where Quechua and Aymara are spoken",
    "Home of Machu Picchu",
    "Home to a majority of the world's alpacas",
    "Major exporter of artichokes and gold",
    "Country between Ecuador and Bolivia",
    "Home of the 11,000-foot-deep Cotahuasi Canyon",
    "Home of the oldest university in the Western Hemisphere (founded in 1551)",
    "Lima's home",
    "Mario Vargas Llosa's country",
    "Original home of Paddington Bear",
    "Machu Picchu locale",
    "Arequipa is its second-largest city",
    "Lima's land",
    "Source of the Amazon",
    "South American territory in the game Risk",
    "Lima's home",
    "Major quinoa exporter",
    "Birthplace of Paddington Bear",
    "Land bordering Lake Titicaca",
    "Mario Vargas Llosa's home",
    "Its national animal is the vicuna",
    "Country located in what was once the Inca Empire",
    "Big silver exporter",
    "Paddington Bear's country of origin",
    "Yma Sumac's homeland",
    "Homeland of Literature Nobelist Mario Vargas Llosa",
    "Country south of Ecuador",
    "Lima's land",
    "Country mentioned in Sinatra's \"Come Fly With Me\"",
    "Home of the Norte Chico civilization",
    "Home of former U.N. Secretary General Javier Perez de Cuellar",
    "Territory on a Risk board",
    "Llama land",
    "Indiana town that's home to the International Circus Hall of Fame",
    "Land with chinchillas",
    "Where the 2003 true-life film \"Touching the Void\" is set",
    "Land conquered by Conquistadors",
    "Andean land",
    "El Misti's location",
    "Lima's locale",
    "Chile's northern neighbor",
    "Arequipa is its second-largest city",
    "Machu Picchu's land",
    "Where Simon Bolivar was once president",
    "Big copper exporter",
    "Its coat of arms includes a vicuna",
    "Equatorial land",
    "Indiana town where Cole Porter was born and buried",
    "Locale for Che Guevara in \"The Motorcycle Diaries\"",
    "Home of novelist Mario Vargas Llosa",
    "Lima's land",
    "Land of Lima and llamas",
    "The Putumayo River forms part of its northern border",
    "Llama country",
    "One of the losers in the War of the Pacific",
    "The Pan-American Highway runs through it",
    "Andean land",
    "Its flag has a vicuna on it",
    "Indiana town where Cole Porter was born",
    "Where Lima is",
    "Modern home of the 10-Down",
    "Cuzco's country",
    "Lima's land",
    "Land north of Chile",
    "Indiana city named for a foreign country",
    "Andean territory",
    "Cuzco's home",
    "Big silver exporter",
    "Lima's land",
    "Andean land",
    "Indiana town where Cole Porter was born",
    "33-Down's home today",
    "Llama land",
    "Andean country",
    "Its highest point is Huascaran",
    "El Misti's locale",
    "Conquistador's locale",
    "Lima's locale",
    "Cuzco's country",
    "Where the nuevo sol is spent",
    "Neighbor of Ecuador",
    "Machu Picchu site",
    "Llama's land",
    "Site of El Misti volcano",
    "Indiana town where Cole Porter was born",
    "Big copper exporter",
    "Big cocaine exporter",
    "Home for llamas",
    "Lima's land",
    "Where the Maranon flows",
    "Home of the writer Mario Vargas Llosa",
    "Andean land",
    "Where the Ucayali begins",
    "Pan American Highway land",
    "Big copper exporter",
    "It borders the state of Amazonas",
    "Major silver exporter",
    "Big copper exporter",
    "Fujimori's land",
    "Big coca producer",
    "Big copper exporter",
    "Amazon's source",
    "Where the Amazon rises",
    "Llama country",
    "Incas' land",
    "Home to Shining Path guerrillas",
    "Alpaca habitat",
    "Pizarro conquest",
    "24-Down town where Cole Porter was born",
    "Lima's land",
    "Mountainous land",
    "Land of the llama",
    "Home of the U.N.'s Javier Perez de Cuellar",
    "Country north of Chile",
    "Old Spanish viceroyalty",
    "Callao is its chief port",
    "Major fish exporter",
    "Lima's land",
    "Where El Misti volcano is",
    "Andes land",
    "Lima's land",
    "Lima's locale",
    "Novelist Mario Vargas Llosa's home",
    "Where the Amazon originates",
    "Lima's land",
    "Where the Ucayali flows",
    "Lima's land",
    "Where the Amazon arises",
    "Lima's land",
    "Huascaran is its highest point",
    "Aymara is spoken here",
    "Llama land",
    "Where Cuzco is",
    "President Fujimori's land"
  ],
  "esme": [
    "Actress ___ Creed-Miles",
    "Salinger heroine",
    "\"___ & Roy\" (children's TV series)",
    "Salinger title girl",
    "Salinger heroine",
    "Name in a Salinger title",
    "Salinger title teen",
    "Salinger title girl",
    "Salinger dedicatee",
    "J. D. Salinger title character",
    "Name in a Salinger title",
    "Titular Salinger girl",
    "Salinger title girl",
    "\"A Series of Unfortunate Events\" villainess",
    "Salinger title girl",
    "Salinger's \"For ___ - With Love and Squalor\"",
    "Salinger heroine",
    "Title girl in a J. D. Salinger story",
    "Salinger heroine",
    "Salinger dedicatee",
    "Salinger girl",
    "Salinger girl",
    "Salinger's \"For ___ - With Love and Squalor\"",
    "Salinger's \"For ___ - With Love and Squalor\"",
    "Salinger title girl",
    "Salinger title girl",
    "Salinger girl",
    "Salinger heroine",
    "Salinger girl",
    "Heroine in one of Salinger's \"Nine Stories\"",
    "Salinger title girl",
    "\"Nine Stories\" title girl",
    "Salinger girl",
    "Woman in all four \"Twilight\" novels",
    "Carlisle Cullen's wife in \"Twilight\"",
    "Salinger heroine",
    "The farmer's wife in \"Babe\"",
    "Celebrity biographer Hawes",
    "Olaf's girlfriend in Lemony Snicket books",
    "Saki story whose title character is a hyena",
    "Salinger girl",
    "Salinger dedicatee",
    "Salinger dedicatee",
    "Celebrity biographer Hawes",
    "Salinger title girl",
    "Title girl of a \"Nine Stories\" story",
    "Salinger lass",
    "Salinger girl",
    "Salinger heroine",
    "\"Love and Squalor\" girl of literature",
    "Salinger girl",
    "Salinger title character",
    "Salinger heroine",
    "Salinger girl",
    "First of \"The Chronicles of Clovis\"",
    "Salinger girl",
    "Salinger story subject",
    "Saki title",
    "Salinger orphan",
    "Salinger title character",
    "Wife's name in \"Babe\"",
    "Salinger dedicatee",
    "Salinger dedicatee",
    "Salinger dedicatee",
    "Salinger heroine",
    "Salinger dedicatee",
    "Saki story",
    "Salinger dedicatee",
    "Salinger dedicatee",
    "Salinger girl",
    "Salinger girl",
    "Name in a Salinger title",
    "Salinger heroine",
    "Seal in Disney's \"20,000 Leagues Under the Sea\"",
    "Salinger girl",
    "Salinger girl",
    "Salinger dedicatee",
    "Salinger's \"For ___--With Love and Squalor\""
  ],
  "vows": [
    "Statements of will?",
    "Wedding exchange",
    "Wedding words",
    "Things two-timers break",
    "Binders",
    "I do's, e.g.",
    "Things sometimes exchanged",
    "Promises",
    "Words said at an altar",
    "Wedding exchange"
  ],
  "usda": [
    "Letters on a stamp",
    "Meat-inspecting org.",
    "___-certified organic",
    "Grp. that determines what a 24-Down is",
    "The Forest Service is part of it, in brief",
    "National School Lunch Program org.",
    "Forest Service's agcy.",
    "Org. concerned with grades",
    "___-approved",
    "Org. to take your beefs to?",
    "Prime rater, for short",
    "Prime rating org.",
    "Letters on a stamp",
    "Enforcer of the Fed. Meat Inspection Act",
    "Org. inspecting 64-Across",
    "Letters on some meat packaging",
    "Food safety org.",
    "Org. that implemented the Food Stamp Act",
    "Letters of certification?",
    "Fed. food inspector",
    "Meat stamp",
    "Prime letters?",
    "Org. that rates meat \"Choice\" or \"Prime\"",
    "Letters on a stamp",
    "Letters on meat packaging",
    "Meat quality rater, for short",
    "Fed. agency entrusted with food safety",
    "Food safety org.",
    "Govt. meat-stamping org.",
    "Food-stamping org.",
    "Food stamp?",
    "\"Choice\" agcy.",
    "Org. that gives approval",
    "___-approved",
    "Food-stamping org.",
    "Certified letters?",
    "___ prime",
    "Beef quality graders: Abbr.",
    "Stamp letters",
    "___-approved",
    "Food safety grp.",
    "Meat stamp inits.",
    "Letters before Choice or Prime",
    "Stamp letters",
    "Big employer of inspectors: Abbr.",
    "Stamp of approval?",
    "Meat marking",
    "Fed. food inspectors",
    "Org. that rejects bad eggs",
    "Food stamp",
    "Stamp of approval?",
    "Inspector's employer: Abbr.",
    "Stamp of approval",
    "Stamp of approval",
    "Letters on a food stamp",
    "Stamp-of-approval letters",
    "Inspectors' grp.",
    "Stamp of approval?",
    "Meat approver, for short",
    "Initials in food certification",
    "Dairy or ranch regulator: Abbr.",
    "Org. that oversees food stamps",
    "Letters stamped on meat",
    "Packinghouse stamp",
    "Abbr. on egg cartons",
    "Meat-stamp letters",
    "Stamp of approval",
    "Meat inspection inits.",
    "Food stamp agcy.",
    "Crop overseer, for short",
    "Meat stamp",
    "Stamp of approval?: Abbr.",
    "Food inspection inits."
  ],
  "oops": [
    "\"My b!\"",
    "\"My b!\"",
    "\"My mistake!\"",
    "\"Well, that was stupid of me!\"",
    "Accident report?",
    "Klutz's cry",
    "[head slap]",
    "Klutz's cry",
    "\"My bad!\"",
    "\"Uh-oh!\"",
    "\"My b\"",
    "\"Didn't intend for that!\"",
    "\"Beg pardon!\"",
    "\"My bad!\"",
    "\"My bad!\"",
    "\"I'm such a klutz!\"",
    "Klutz's utterance",
    "\"That was dumb of me\"",
    "Error report?",
    "\"My bad!\"",
    "Klutz's cry",
    "Klutz's utterance",
    "\"My bad!\"",
    "\"My mistake!\"",
    "Omission statement?",
    "\"My bad!\"",
    "\"My bad!\"",
    "\"My bad!\"",
    "Word after a splat",
    "Schlemiel's cry",
    "Fumble follower",
    "Boo-boo follower",
    "\"I shouldn't have done that\"",
    "Worrying comment from a surgeon",
    "\"Well, that was stupid of me!\"",
    "\"My bad!\"",
    "Mumble after a fumble",
    "\"My bad\"",
    "\"My bad!\"",
    "Word of dismay",
    "Bungler's cry",
    "\"I goofed\"",
    "\"Stupid me!\"",
    "Dropping sound",
    "Cry from a butterfingers",
    "\"Not good!\"",
    "Klutz's cry",
    "\"My bad!\"",
    "Word from a klutz",
    "\"Uh-oh\"",
    "\"Sorry about that!\"",
    "\"My bad\"",
    "\"My bad!\"",
    "Bumbler's cry",
    "\"My bad!\"",
    "Cry from a butterfingers",
    "Klutz's cry",
    "Unwelcome word from a surgeon",
    "\"So sorry!\"",
    "\"My mistake!\"",
    "Apology preceder",
    "\"I dropped it!\"",
    "Error message?",
    "\"My bad\"",
    "Error message?",
    "It may follow an omission",
    "Unwelcome word from a surgeon?",
    "Word following an omission",
    "\"Stupid me!\"",
    "Cry over spilled milk",
    "Cry over spilled milk",
    "Butterfingers' remark",
    "\"Sorry 'bout that!\"",
    "Kin of \"Uh-oh!\"",
    "Unwelcome word at an antique shop",
    "Klutz's utterance",
    "Lummonx's cry",
    "?ôSo sorry!?ö",
    "All-thumbs apology",
    "Cousin of \"uh-oh\"",
    "Butterfingers' cry",
    "Bumbler's blurt",
    "Bad word to hear at a china shop",
    "Faux pas follower"
  ],
  "icee": [
    "Brain freeze cause, maybe",
    "Drink similar to a slushie",
    "Cause of some brain freeze",
    "Drink with a dome-shaped lid",
    "Cold treat",
    "Drink in a red, white and blue cup",
    "Sugary frozen beverage",
    "Summer cooler",
    "Drink similar to a Slurpee",
    "Drink that can cause brain freeze",
    "Relative of a Slurpee",
    "Drink brand with a polar bear in its logo",
    "Cold, cold drink",
    "Cold, cold drink",
    "Summer cooler",
    "Summertime cooler",
    "Drink that can cause brain freeze",
    "Drink that can cause brain freeze",
    "Summer refresher",
    "Frozen drink",
    "Frozen drink brand",
    "Cold drink at Burger King",
    "Drink brand with a polar bear symbol",
    "Frozen drink brand",
    "Drink commonly served with a spoon-straw",
    "Slushy drink brand",
    "Popular frozen drink",
    "Maker of Arctic Blast and Java Freeze beverages",
    "Java Freeze maker",
    "Pepsi Freeze is one of its flavors",
    "Arctic Blast maker",
    "Brain-freezing treat",
    "Slushy drink",
    "Drink brand symbolized by a polar bear",
    "Possible cause of brain freeze",
    "Drink brand with a polar bear mascot",
    "Frozen drink brand",
    "Summer cooler",
    "Frozen beverage brand",
    "Frozen beverage brand",
    "Frozen drink brand",
    "---",
    "Slush Puppie alternative",
    "Frozen drink brand",
    "Frozen drink brand",
    "Summer cooler",
    "Summer cooler",
    "Frozen drink brand",
    "Summer cooler",
    "Big name in frozen confections"
  ],
  "slim": [
    "Like the odds of finding a needle in a haystack",
    "Improbable",
    "Next to nothing, as a chance",
    "Nickname for a really thin guy",
    "Like 100-1 odds",
    "Svelte",
    "Slender",
    "Not very likely",
    "Like a long shot's chances",
    "Like long chances",
    "Negligible",
    "Like some chances",
    "Not much, but better than none",
    "Nickname for a lanky cowboy",
    "Nickname for a lanky guy",
    "Remote",
    "Remote",
    "Like some pickings",
    "Thin",
    "Skinny",
    "Reduce",
    "Hardly stocky",
    "Dude ranch nickname",
    "Fat, as a chance",
    "Unpromising, as a chance",
    "Slight, as chances",
    "Spare",
    "Gracile",
    "Diet",
    "Post-diet, ideally",
    "Slender",
    "___ to none (long odds)",
    "Meager",
    "Slender",
    "Diet",
    "Willowy",
    "Poor",
    "Slight",
    "Insignificant",
    "Meager",
    "Negligible",
    "Poor",
    "Narrow",
    "Negligible",
    "Not likely",
    "Fat-free?",
    "Nickname for a 6-foot 140-pounder, say",
    "Scanty",
    "Common cowboy nickname",
    "Like some chances",
    "Insignificant",
    "Meager",
    "Svelte",
    "Twiggy",
    "Kind of chance",
    "Not fat"
  ],
  "elal": [
    "Airline based near Tel Aviv",
    "Israeli airline",
    "It doesn't offer flights on Saturday mornings",
    "Carrier for many a 15-Across",
    "Airline to Ben Gurion",
    "Airline that doesn't schedule flights on Shabbat",
    "Airline of 61-Across",
    "\"Home away from home\" sloganeer",
    "Carrier that offers only kosher meals",
    "Carrier that operates only six days a week",
    "Carrier with a blue-striped fleet",
    "Carrier with King David Lounges",
    "Airline to Ben Gurion Airport",
    "Travel option for Birthright trips",
    "Alternative to Israir Airlines",
    "Airline in the early 1950s' Operation Ali Baba",
    "Carrier to the Mideast",
    "Israeli carrier",
    "Airline with a flag in its logo",
    "It holds the world record for most passengers on a commercial airliner [1,088]",
    "Its first flight went from Geneva to Tel Aviv",
    "Airline that flies only six days a week",
    "Airline with famously tight security",
    "Its inaugural flight was from Geneva to Tel Aviv",
    "Its inaugural flight was in 1948",
    "Carrier to the Holy Land",
    "President Chaim Weizmann was on its first flight",
    "Holy Land line",
    "Airline to Ben Gurion",
    "Airline to Tel Aviv",
    "Company with two lameds in its name",
    "Literally, \"skyward\"",
    "Hebrew for \"to the skies\"",
    "Company with the King David Club",
    "JFK-to-TLV option",
    "Airline to the Holy Land",
    "Company whose name is derived from a passage in Hosea",
    "It means \"skyward\"",
    "Company that follows Shin Bet security procedures",
    "Airline that doesn't fly on religious holidays",
    "Airline to Israel",
    "Carrier that owns the airline Sun d'Or",
    "Airline that doesn't fly on the Sabbath",
    "Carrier to Ben Gurion",
    "Carrier to Israel",
    "Airline that doesn't fly on the Sabbath",
    "\"Home away from home\" sloganeer",
    "Israeli carrier",
    "Tel Aviv lander",
    "It's grounded every Saturday",
    "Israeli carrier",
    "Commercial name that means, literally, \"skyward\"",
    "Mideast carrier",
    "Carrier to Ben-Gurion",
    "Israeli carrier",
    "Mideast carrier",
    "It's grounded on Friday nights",
    "Mideast carrier",
    "Offerer of the Matmid frequent flier club",
    "Carrier whose name means \"skyward\"",
    "Carrier to Tel Aviv",
    "It's grounded on the Sabbath",
    "Carrier to Tel Aviv",
    "Airline whose meals are all kosher",
    "JFK-to-TLV carrier",
    "Israeli carrier",
    "Its maiden flight carried its country's president home",
    "Flier to Ben-Gurion",
    "Commercial name that literally means \"to the skies\"",
    "Supersecure airline",
    "International company with the slogan \"Home away from home\"",
    "Company whose slogan is \"Home away from home\"",
    "Carrier to Tel Aviv",
    "Its liners have stars on them",
    "Alternative to Arkia or Israir",
    "Israeli flag carrier",
    "Airline that flew the humanitarian Operation Solomon",
    "Mideast carrier",
    "Airline that doesn't fly on Saturday",
    "Ben-Gurion carrier",
    "Mideast airline",
    "Airline to Ben-Gurion",
    "Carrier to Tel Aviv",
    "Israir alternative",
    "JFK-to-TLV option",
    "Airline since 1948",
    "Airline with the King David Lounge",
    "Operation Exodus participant",
    "Mideast flier",
    "Israir alternative",
    "Carrier whose name means \"skyward\"",
    "Airline founded in 1948",
    "Carrier at J.F.K.",
    "Flier to Tel Aviv",
    "Airline to Tel Aviv",
    "Company famous for its safety record",
    "Carrier whose name means \"skyward\"",
    "Ben Gurion arrival",
    "Carrier with blue-striped jets",
    "Flier to Ben-Gurion",
    "Its flight attendants' greeting is \"Shalom\"",
    "Big international carrier",
    "Airline with a kosher menu",
    "Israeli airline",
    "Mideast carrier",
    "Notably secure carrier",
    "It's known for its security",
    "Carrier to Israel",
    "Mideast carrier",
    "Lander at Ben Gurion",
    "Company whose name is derived from a passage in Hosea",
    "Carrier to J.F.K.",
    "Airline launched in 1948",
    "Israeli airline",
    "Mideast carrier",
    "\"Raid on Entebbe\" airline",
    "Carrier to the Mideast",
    "Israeli airline",
    "Lod Airport carrier",
    "Israeli airline",
    "It's known for its tight security",
    "Airline to Tel Aviv",
    "Israeli airline",
    "Overseas carrier",
    "Carrier with tight security",
    "Airline that serves only kosher food",
    "State-run airline",
    "Airline to Israel",
    "Airline to Ben-Gurion",
    "Company with famous security",
    "Carrier since 1948",
    "Airline since 1948",
    "Lander at Ben-Gurion",
    "It serves Jerusalem",
    "Airline to Israel",
    "Airline with King David Lounges",
    "Traveler to Tel Aviv",
    "Israeli airline",
    "Pride of Israel",
    "Airline to Tel Aviv",
    "It lands at Lod",
    "Carrier to 54-Down",
    "Overseas carrier",
    "Carrier to Ben-Gurion",
    "Airline to Israel",
    "Flier to J.F.K.",
    "Carrier at J.F.K.",
    "Airline to Ben Gurion",
    "It serves Tel Aviv",
    "Airline to Tel Aviv",
    "Two-word airline name",
    "Mideast carrier",
    "Carrier to Tel Aviv",
    "Tel Aviv carrier",
    "Israeli airline",
    "Mideast carrier",
    "Carrier to Jerusalem",
    "Airline to Tel Aviv",
    "One way to get to Jerusalem",
    "Ben-Gurion carrier",
    "Mideast carrier",
    "Airline from Lod airport",
    "Overseas carrier",
    "Mideast carrier",
    "Airline name drawn from Hosea",
    "Airline since 1948",
    "Mideast carrier",
    "Mideast flier",
    "Airline name that's derived from Hosea",
    "Israeli airline",
    "Carrier to Tel Aviv",
    "Israeli airline",
    "Mideast carrier",
    "Israeli airline",
    "Airline to Ben-Gurion",
    "Airline to Tel Aviv",
    "Mideast airline",
    "Mideast carrier",
    "Airline to Jerusalem",
    "Mideast carrier",
    "Israeli airline",
    "Airline to Ben-Gurion",
    "Israeli carrier",
    "Mideast carrier",
    "Mideast airline",
    "Carrier to 41 Across",
    "Lod airport airline",
    "Upwardly mobile Israeli group?",
    "Airline to Jerusalem",
    "Airline to Haifa",
    "Airline to Ben-Gurion"
  ],
  "half": [
    "12/24, e.g.",
    "Either part of a yin-yang symbol, e.g.",
    "One of a pair",
    "Like 50 U.S. senators",
    0.5,
    0.5,
    0.5,
    "Almost a controlling interest",
    "30 minutes, in the N.F.L.",
    "Hoops division",
    "Word with pay or page",
    "Fair share, maybe",
    "Football break",
    "Game part",
    "Soccer segment",
    "Soccer period",
    "Two quarters",
    "Top or bottom, in baseball",
    "With 69-Across, ship of 55-Across",
    "Either 50 of 50/50",
    "One of two that make one",
    "50 percent",
    "30 minutes, in the N.F.L.",
    "With 38- and 40-Across, grocery purchase ... or what can be found in some other Across answers in this puzzle",
    "Game division",
    0.5,
    0.5,
    "Not quite a majority",
    "50/50 share",
    "See 41-Down",
    "Fair share, maybe",
    "Better ___",
    "Football game division",
    "Game segment",
    "Better ___",
    "One of two, e.g.",
    "24 minutes, maybe",
    "Game portion",
    "Big share",
    "24 minutes of basketball",
    "Two quarters",
    "Fair share, maybe",
    "Soccer segment",
    "See 64-Across",
    0.5,
    "50 percent",
    "Kennedy item",
    "Sports period",
    0.5,
    "50/50 shape",
    "Moiety",
    "Sports period",
    "Moiety",
    "___ nelson",
    "Gridiron period"
  ],
  "elio": [
    "___ Perlman, role for Timothée Chalamet in \"Call Me by Your Name\"",
    "Italian writer Vittorini",
    "Chacon of the 1960s Mets",
    "Chacon of the 1962 Mets",
    "Film director Petri",
    "Shortstop Chacon of the 1962 Mets",
    "Film director Petri",
    "Film director Petri",
    "Director Petri",
    "60's Mets shortstop Chacon",
    "Film director Petri",
    "Chacon of the 1962 Mets",
    "1962 Met ___ Chacon"
  ],
  "byrd": [
    "Explorer Richard who made the first flight over the South Pole",
    "Admiral who explored the Antarctic",
    "Disputed North Pole visitor",
    "Robert ___, longest-serving senator in U.S. history (51 years)",
    "\"With ___ at the South Pole,\" first documentary to win an Oscar (for cinematography)",
    "Late West Virginia senator Robert",
    "Longest-serving senator in U.S. history",
    "Longtime West Virginia senator",
    "Polar explorer Richard",
    "Polar explorer",
    "Noted polar explorer",
    "Longtime West Virginia senator",
    "Polar-izing figure?",
    "Polar explorer",
    "Founder of Little America",
    "Polar explorer Richard",
    "Former Senate chief"
  ],
  "epps": [
    "Omar of \"Love & Basketball\"",
    "Actor/comedian Mike",
    "Stand-up comedian Mike",
    "Dr. Foreman player on \"House\"",
    "Omar of Fox's \"House\"",
    "Omar of TV and film",
    "\"House\" actor Omar",
    "Omar in Hollywood",
    "Foreman portrayer on \"House\"",
    "Omar of \"House\"",
    "Hayes portrayer in \"The Mod Squad,\" 1999",
    "\"House\" actor",
    "Actor Omar of \"House\"",
    "Dr. Foreman's portrayer on \"House\"",
    "Omar of TV's \"House\"",
    "Omar of TV's \"House\"",
    "Actor Omar of TV's \"House\"",
    "\"Alfie\" actor, 2004",
    "Omar of \"The Mod Squad,\" 1999",
    "\"The Mod Squad\" co-star, 1999",
    "Omar of \"The Mod Squad,\" 1999",
    "\"Love and Basketball\" co-star",
    "Omar of \"Scream 2\"",
    "\"The Mod Squad\" co-star, 1999",
    "Actor Omar",
    "Actor Omar",
    "Omar of \"Scream 2\""
  ],
  "mats": [
    "Rolls around while exercising?",
    "Gym array",
    "Some framing supplies",
    "Yoga class sights",
    "Gymnasts' supplies",
    "Some gym gear",
    "Yoga equipment",
    "Sights at a martial arts center",
    "Pilates class sights",
    "Tangles",
    "\"Welcome\" sights",
    "Yoga supplies",
    "Gym surfaces",
    "Dojo needs",
    "Wrestling pads",
    "Yoga equipment",
    "Cushions for tumblers",
    "Wrestling surfaces",
    "Gym gear",
    "Gymnastics school supply",
    "Gym equipment",
    "Sushi-rolling accessories",
    "Small rugs",
    "Dojo accessories",
    "Gym floor covers",
    "Photo studio supplies",
    "Guest welcomers",
    "Shoes are wiped on them",
    "\"Welcome\" sites",
    "\"Welcome\" sights",
    "Gym equipment",
    "Gymnasts' needs",
    "Dining table sights",
    "Vaulters' landing places",
    "They may be left at one's doorstep",
    "Foot pads",
    "Wrestling surfaces",
    "They welcome people at the door",
    "Wrestling areas",
    "Wilander of Wimbledon",
    "Gymnastic equipment",
    "Where pins are made",
    "Wrestlers' needs"
  ],
  "chat": [
    "Quick talk",
    "Text back and forth, say",
    "Informal conversation",
    "F.D.R.'s fireside event",
    "Heart-to-heart, maybe",
    "Gossip, e.g.",
    "Shoot the breeze",
    "Some back-and-forth",
    "Converse",
    "Small talk",
    "Convo",
    "Schmooze",
    "Heart-to-heart, e.g.",
    "I.M.'ing session",
    "Schmooze",
    "Confab",
    "Converse",
    "Activity in a virtual room",
    "Chin",
    "Web ___",
    "Facebook feature",
    "Many people do it online",
    "What some people do in an online \"room\"",
    "Bit of back-and-forth",
    "Online activity",
    "Online activity",
    "Something to do in a virtual room",
    "Casual conversation",
    "Tete-a-tete",
    "___ room",
    "Online activity",
    "Tete-a-tete",
    "Online pastime",
    "Rap",
    "___ room",
    "Chew the fat",
    "Online event",
    "Tete-a-tete",
    "Rap",
    "Web conversation",
    "Rap",
    "Many people now do it online",
    "No formal discourse",
    "Talk",
    "On-line activity",
    "Tete-a-tete",
    "Cyberspace conversation",
    "Confab",
    "Powwow",
    "Chin",
    "Talk",
    "Chew the fat",
    "Modern kind of room",
    "Heart-to-heart",
    "Heart-to-heart",
    "Exchange pleasantries",
    "Heart-to-heart",
    "Tete-a-tete",
    "Schmooze",
    "Yellow-breasted bird",
    "Prattle",
    "Bat the breeze"
  ],
  "dark": [
    "What nyctophobia is the fear of",
    "Like yin but not yang",
    "Not in operation, as a Broadway theater",
    "Word before web or chocolate",
    "Noirish",
    "Macabre",
    "___ horse (surprise candidate)",
    "Forbidding",
    "Word with horse or meat",
    "With 24-Across, witchcraft, e.g.",
    "Nighttime",
    "Giving no performances",
    "Unlit",
    "Alternative to white, as turkey meat",
    "Gloomy",
    "Noirish",
    "Chocolate choice",
    "Like some turkey meat",
    "With 4-Down, black magic",
    "With 54-Across, black magic",
    "Foreboding",
    "Not currently in use, as a theater",
    "Like some turkey meat",
    "Not white, as meat",
    "After dusk",
    "Unlit",
    "Thanksgiving meat request",
    "Swarthy",
    "Stygian",
    "Turkey meat request"
  ],
  "odie": [
    "Slobbery cartoon character",
    "Garfield's canine pal",
    "Big-tongued comics character",
    "Comics dog who walks on two feet",
    "Jon Arbuckle's dog",
    "Comics character often kicked off a table",
    "Cartoon character often shown with his tongue out",
    "Performer at a canine talent show in \"Garfield: The Movie\"",
    "Comic strip canine",
    "Slobbery toon",
    "Garfield's frenemy",
    "Comics character who once, surprisingly, solved a sudoku puzzle",
    "Cartoon dog",
    "Drooling dog of the comics",
    "Garfield's foil in the comics",
    "Dog created by Jim Davis",
    "Yellow dog of the funnies",
    "Slavering toon",
    "Slobbering cartoon character",
    "\"Garfield\" drooler",
    "\"Garfield\" dog",
    "\"Garfield\" dog",
    "Yellow dog in the funnies",
    "\"Garfield\" dog",
    "Dog in \"Garfield\"",
    "Often-punted comics character",
    "Brown-eared comics character",
    "Comics character who once solved a sudoku",
    "\"Garfield\" barker",
    "Comic strip dog",
    "Comics canine",
    "Toon beagle",
    "Cartoon dog with a big tongue",
    "Dog bullied by Garfield",
    "Often-kicked comics canine",
    "Cartoon pooch",
    "Comics beagle",
    "Cartoon beagle",
    "Comic strip dog with a long tongue",
    "Comics character who almost never speaks",
    "Dog often messed with by Garfield",
    "Comics canine",
    "Jim Davis cartoon dog",
    "\"Garfield\" dog",
    "Pooch in a Jim Davis cartoon",
    "His vet is Liz Wilson",
    "Jon Arbuckle's pooch",
    "Occasionally punted comics canine",
    "Slobbery cartoon character",
    "Dog in a cat comic",
    "\"Garfield\" canine",
    "Drooling canine in the funnies",
    "Cartoon canine",
    "Pooch in panels",
    "Tongue-lolling comics character",
    "Comics canine",
    "Garfield's foil",
    "Comics pooch",
    "\"Garfield\" canine",
    "Funny papers pooch",
    "Jon's comics canine",
    "Beagle in the funnies",
    "Garfield's housemate",
    "\"___ Unleashed!\" (cartoon volume)",
    "Drooling dog in \"Garfield\"",
    "Cartoon canine",
    "Garfield's foil",
    "One of Jon Arbuckle's pets",
    "Garfield's pal, in the funnies",
    "Comics canine",
    "\"Garfield\" canine",
    "Comics dog",
    "\"Garfield\" foil",
    "\"Garfield\" dog",
    "Comic strip canine",
    "___ Cologne (skunk of old cartoons)",
    "Jim Davis dog",
    "\"Garfield\" dog",
    "Cartoon canine",
    "\"Garfield\" dog",
    "Jon Arbuckle's dog",
    "Garfield's pal",
    "Comics canine",
    "Garfield's pal",
    "\"Garfield\" dog",
    "Cartoon dog",
    "Garfield's foil",
    "Jon Arbuckle's dog",
    "\"Garfield\" dog",
    "Cartoon dog",
    "Likely foil for Garfield",
    "Garfield's foil",
    "Comic strip dog",
    "Jim Davis creation",
    "Garfield's stooge",
    "Dog in a panel",
    "Garfield's foil",
    "Jim Davis dog",
    "Garfield's foil",
    "Comics pooch",
    "Comics dog",
    "Comics canine",
    "\"Garfield\" dog",
    "Comic strip dog",
    "Comics dog",
    "Comics pooch",
    "Jim Davis's dog",
    "Jim Davis pooch",
    "\"Garfield\" dog",
    "Dog drawn by Jim Davis",
    "Jim Davis dog",
    "Comics dog",
    "\"Garfield\" dog",
    "Garfield's canine pal",
    "Jon Arbuckle's dog",
    "Comics dog",
    "Jim Davis canine",
    "Garfield's foil",
    "\"Garfield\" dog",
    "\"Garfield\" dog",
    "\"Garfield\" dog",
    "Jon Arbuckle's dog",
    "Comics canine",
    "Garfield's foil, in the comics",
    "Jim Davis character",
    "Garfield's foil",
    "Cartoon dog",
    "Garfield's pal",
    "Jim Davis cartoon dog",
    "Garfield's bane, in the comics",
    "Cartoon dog",
    "Garfield's foil",
    "Garfield's pal",
    "Garfield pal",
    "Dog of the comics",
    "Garfield's pal",
    "Garfield's canine pal"
  ],
  "noes": [
    "Vetoes",
    "Discouraging words",
    "Opposites of 76-Down",
    "Obstinate responses",
    "Disappointing R.S.V.P.s",
    "Discouraging words",
    "Refusals",
    "Shouts of dissent",
    "Replies from con men?",
    "All those against",
    "Dissents",
    "Opposite of 32-Down",
    "Rejections",
    "\"Regrets\" and others",
    "Negatives",
    "Denials",
    "Vetoes",
    "Terrible twos responses",
    "Deal killers",
    "Disappointing R.S.V.P.'s",
    "Passage blockers",
    "Con man's responses?",
    "They don't respond favorably",
    "Column on a questionnaire",
    "They're not positive",
    "Some votes",
    "They're not positive",
    "Rejections",
    "Turndowns",
    "Column of boxes on a questionnaire",
    "Some Ouija answers",
    "Turndowns",
    "Turndowns",
    "Refusals",
    "Answers from a 49-Across",
    "Some votes",
    "Some votes",
    "Some votes",
    "Turndowns",
    "Turndowns",
    "Turndowns",
    "Proposal killers",
    "Refusals",
    "Brushoffs",
    "Turndowns",
    "Discouraging words",
    "Rejections",
    "Turndowns",
    "Refusals",
    "Vetoes",
    "Thumbs-down reactions",
    "Denials",
    "Denials",
    "Rebuffs",
    "Refusals",
    "Denials",
    "Two-year-olds' chorus",
    "Some replies",
    "They might be heard a thousand times",
    "Refusals",
    "Turndowns"
  ],
  "asps": [
    "Ancient symbols of royalty",
    "Vipers with upturned snouts",
    "Deadly snakes",
    "Certain hieroglyphs",
    "Snakes in hieroglyphics",
    "Snakes spawned by Medusa's blood, in Greek myth",
    "Nile snakes",
    "Egyptian snakes",
    "Menaces to Indiana Jones",
    "Nile biters",
    "Creatures that produce neurotoxins",
    "Ophidian menaces",
    "African menaces",
    "Cousins of garters",
    "The snakes in the movie line \"Snakes. Why'd it have to be snakes?\"",
    "\"Raiders of the Lost Ark\" menaces",
    "Not what Indiana joneses for",
    "Egyptian menaces",
    "Hieroglyphics snakes",
    "Menaces that name other menaces if you put a \"w\" at the start",
    "Deadly snakes",
    "Ancient symbols of sovereignty",
    "Lethal injection providers?",
    "Egyptian vipers",
    "Nile vipers",
    "Egyptian slitherers",
    "Venomous Nile dwellers",
    "Nile reptiles",
    "Ancient symbols of royalty",
    "Hieroglyphics creatures",
    "Cobras of Egypt",
    "Sources of some lethal injections",
    "Deadly snakes",
    "Snakes along the Nile",
    "Ones with natural curls?",
    "53-Across menaces",
    "Hooded vipers",
    "Egyptian lowlife?",
    "Lethal injection administerers",
    "Venomous vipers",
    "Egyptian menaces",
    "Saharan slitherers",
    "Coiled killers",
    "Nile biters",
    "Phobia of 100-Down",
    "Egyptian symbols of royalty",
    "Egyptian snakes",
    "Cold-blooded killers",
    "Nile reptiles",
    "Old symbols of royalty",
    "Nile reptiles",
    "Egyptian symbols of royalty",
    "Nile nippers",
    "Hooded menaces",
    "Egyptian slitherers",
    "Nile slitherers",
    "Nasty vipers",
    "Nile snakes",
    "Hieroglyphic figures",
    "Deadly biters",
    "Dangerous biters",
    "Nile slitherers",
    "Cairo cobras",
    "Nile snakes",
    "Nile slitherers",
    "Ancient emblems of royalty",
    "Certain cobras",
    "\"?à the cruel venom of ___\": Deut. 32",
    "Wriggly biters",
    "Venom producers",
    "Nile reptiles",
    "Dangerous slitherers",
    "Nile slitherers",
    "Nile slitherers",
    "Some cobras",
    "Nile cobras",
    "Old royal emblems",
    "Cousins of 58-Down",
    "Figures on some Egyptian relics",
    "Sacred creatures of old",
    "Ancient Egyptian royal emblems",
    "Old Egyptian symbols",
    "Nile slitherers",
    "Poisonous snakes",
    "Indiana Jones's dread",
    "Vile Nile creatures",
    "Pharaohs' sacred symbols",
    "Egyptian snakes",
    "Hieroglyph images",
    "Venomous snakes",
    "Herpetologists' subjects",
    "\"Raiders of the Lost Ark\" danger",
    "Nile reptiles",
    "Reptiles of the genus Naja haje",
    "Slithering strikers",
    "Figures on Pharaohs' headdresses",
    "Egyptian cobras",
    "Nile vipers",
    "Dangerous nestful",
    "\"Raiders of the Lost Ark\" menaces",
    "Egyptian vipers",
    "Nile creatures",
    "Venomous ones",
    "Religious symbols of old Egypt",
    "Nile snakes",
    "Egyptian menaces",
    "Horned vipers",
    "Egyptian threats",
    "Egyptian snakes",
    "Sarcophagus decorations",
    "Horned vipers",
    "Old World snakes",
    "Cleopatra's snakes",
    "Venomous varmints",
    "Mean snakes",
    "Symbols of Egyptian royalty",
    "Nile snakes",
    "African snakes",
    "Cobras",
    "\"Raiders of the Lost Ark\" creatures",
    "Cobras",
    "Egyptian cobras",
    "Horned vipers",
    "Sacred symbols at Thebes",
    "Vipers",
    "Sacred serpents",
    "Cleo's snakes",
    "Indiana Jones perils",
    "Horned vipers"
  ],
  "nabs": [
    "Hauls in",
    "Catches, as a crook",
    "Snatches",
    "Puts the cuffs on",
    "Snags",
    "Catches",
    "Catches",
    "Snatches",
    "Catches red-handed",
    "Snatches",
    "Cops",
    "Slaps the cuffs on",
    "Pinches",
    "Arrests",
    "Busts",
    "Catches, as a perp",
    "Picks up",
    "Seizes",
    "Cuffs",
    "Bags",
    "Catches red-handed",
    "Catches in the act",
    "Gets",
    "Catches in the act",
    "Makes a collar",
    "Catches",
    "Slaps the cuffs on",
    "Collars",
    "Busts",
    "Captures",
    "Snatches",
    "Catches",
    "Nails",
    "Collars",
    "Picks up",
    "Catches",
    "Catches in the act",
    "Collars",
    "Catches red-handed",
    "Arrests",
    "Apprehends",
    "Catches",
    "Catches",
    "Collars"
  ],
  "ecko": [
    "Fashion designer Marc",
    "Clothing designer Marc",
    "Fashion brand with a rhinoceros logo",
    "Marc of fashion",
    "Fashion designer Marc",
    "Fashion designer Marc",
    "Clothing brand with a long vowel mark in its name",
    "Fashion designer Marc"
  ],
  "furs": [
    "PETA pet peeves",
    "Pelts",
    "Garments in summer storage, perhaps",
    "Cold-weather wear",
    "Skunk and such",
    "Heavy coats",
    "Wraps",
    "Cloakroom inventory",
    "Expensive wraps",
    "Ermines and such",
    "Otter and others",
    "Astor's inventory",
    "Fitch and muskrat",
    "Minks and sables"
  ],
  "lida": [
    "\"___ Rose\" (song from \"The Music Man\")",
    "\"___ Rose\" (song from \"The Music Man\")",
    "\"___ Rose\" (song from \"The Music Man\")",
    "\"___ Rose\" (song from \"The Music Man\")",
    "\"___ Rose\" (song from \"The Music Man\")"
  ],
  "club": [
    "Iron, e.g.",
    "See 116-Across",
    "Elks ___",
    "The \"C\" of F.C. Barcelona",
    "Multidecker sandwich",
    "Appurtenance for a cartoon Neanderthal",
    "Caddie's selection",
    "Extracurricular group",
    "Coterie",
    "Sandwich usually served with toothpicks",
    "Cotton or country follower",
    "Putter or 9-iron",
    "Sandwich with toothpicks",
    "Neanderthal accompanier, in cartoons",
    "Night life setting",
    "Crude weapon",
    "Sam's ___",
    "Kind of sandwich",
    "Wood or iron",
    "Certain triple-decker",
    "Listing in a high-school yearbook",
    "Cabaret, e.g.",
    "Driver, e.g.",
    "Iron, for one",
    "Wood for Woods",
    "Black card",
    "Triple-decker",
    "Word with night or bridge",
    "\"Join the ___\"",
    "37-Down, e.g.",
    "Neanderthal's weapon",
    "Association",
    "Bridge group, or bid",
    "Whop, in a way",
    "Putter, for one",
    "Triple-decker sandwich",
    "Kind of car or sandwich"
  ],
  "toro": [
    "Bull mascot of the Houston Texans",
    "Big name in lawn care",
    "Director Guillermo del ___",
    "Snow blower brand",
    "Fatty tuna, in Japanese cuisine",
    "Lawn care brand",
    "Matador charger",
    "Runner in Pamplona",
    "Big name in snow blowers",
    "Snow blower maker",
    "Matador's opponent",
    "Lawn mower brand",
    "Snow blower brand",
    "Fatty tuna part, at a sushi restaurant",
    "Big name in mowers",
    "Fatty cut of fish at a sushi bar",
    "Pamplona runner",
    "Bullfight bull",
    "John Deere rival",
    "Ring combatant",
    "One heading to the cape?",
    "Chant at a bullfight",
    "15-Across, in Spanish",
    "Ring figure in \"Carmen\"",
    "Bull run participant?",
    "One frequently being waved at",
    "Spanish bull",
    "Ring foe of Manolete",
    "Zodiaco animal",
    "One seeing red?",
    "Source of a body piercing?",
    "Runner in Pamplona",
    "Picador's opponent",
    "Bullring bull",
    "Corrida creature",
    "Symbol in el zodiaco",
    "Runner in \"The Sun Also Rises\"",
    "Bull in a bullfight",
    "Ring figure",
    "Snowthrower brand",
    "Mower maker",
    "Lawnmower handle",
    "Matador's foe",
    "Bilbao bull",
    "Deere competitor",
    "Snapper rival",
    "Matador charger",
    "Santiago charger",
    "Mower maker",
    "Corrida charger",
    "Lawn-Boy alternative",
    "Bullfight figure",
    "Ring figure",
    "Corrida beast",
    "Bull in a bullring",
    "Lawn mower brand",
    "El Cordobes adversary",
    "Lawn mower brand",
    "Bullring bull",
    "Bull in an arena",
    "Lawn mower maker",
    "Ring rampager",
    "Tuna at a sushi restaurant",
    "Dominguin's opponent",
    "Corrida animal",
    "Lawn mower brand",
    "Bullfight bull",
    "Tuna belly, at a sushi bar",
    "Side of a fight that almost always loses",
    "Mower brand",
    "Dangerous charger",
    "Foe for El Cordobes",
    "Bullfight bull",
    "Matador's opponent",
    "Matador's threat",
    "Ring fighter",
    "Ring foe",
    "Lawn mower maker",
    "Fighting bull",
    "Bullfight bull",
    "Ring combatant",
    "Mower maker",
    "Bullfight bull",
    "Ring combatant",
    "Pamplona attraction",
    "Manolete opponent",
    "Picador's target",
    "Deere competitor",
    "Matador's foe",
    "Matador's foe",
    "Arena contender",
    "Matador's foe",
    "Corrida performer",
    "Bullring figure",
    "One who sees red?",
    "Bull in Chihuahua",
    "Pamplona runner"
  ],
  "atms": [
    "They take cards or cash",
    "Bread producers?",
    "You can bank on them",
    "Bill passers, briefly",
    "Drive-up bank conveniences",
    "Push-button bankers",
    "Helps for short people, for short",
    "PIN points?",
    "They're loaded with cash",
    "Conveniences at many cash-only businesses",
    "$$$ holders",
    "Fixtures in every Vegas casino",
    "Some bill collectors",
    "Places to get quick cash",
    "Security cam sites",
    "20 dispensers",
    "Places where people have withdrawals?",
    "Things you may dispense with?",
    "Convenience store conveniences",
    "Cash caches, briefly",
    "You might be recorded using them",
    "Ones passing notes?",
    "Bank $$$ dispensers",
    "Where some people go through withdrawals?",
    "PIN points",
    "Convenience store conveniences",
    "Features of many drive-thrus",
    "Convenience store sights",
    "Bread producers",
    "Bread boxes?",
    "Bill producers, for short",
    "Cash dispensers, for short",
    "Bread dispensers",
    "Bill producers",
    "Ones pressed for cash, briefly",
    "Notes come out of them",
    "$20 bill providers, for short",
    "$20 bill dispensers",
    "Dinero dispensers",
    "Note passers?",
    "Accepters of deposits",
    "$20 dispensers",
    "Green machines?",
    "Cashpoints",
    "Means to ease withdrawals",
    "Bill dispensers",
    "Devices you can bank on, briefly",
    "PIN requesters",
    "Bill producers",
    "20 places?",
    "Mall conveniences",
    "Screen sites",
    "S. & L. conveniences",
    "20 places?",
    "Places to get quick money, quickly",
    "PIN takers",
    "They can fix shortages",
    "Tourist stop conveniences",
    "PIN takers",
    "20 places?",
    "Bill producers",
    "Keypad locales",
    "Balance providers",
    "PIN spotters?",
    "Bill producers",
    "Balance providers, for short",
    "24-hour conveniences, for short",
    "$ dispensers",
    "PIN points",
    "20 providers",
    "Some drive-thru features, briefly",
    "20's dispensers",
    "20's dispensers, briefly",
    "Buck passers?",
    "20's locales",
    "Places of withdrawal",
    "They're filled with dough",
    "Cash dispensers, for short",
    "20's producers",
    "Modern mall features",
    "They're pressed for cash",
    "After-hours money sources, for short",
    "Bill passers",
    "They're filled with bills",
    "Bill producers, briefly",
    "20 producers?",
    "Bank adjuncts",
    "20's suppliers",
    "24-hr. conveniences",
    "People withdraw from these",
    "Ersatz bankers",
    "Some banks have them"
  ],
  "shop": [
    "Oniomania, n. the uncontrollable desire to ___",
    "Store",
    "Union workplace",
    "Get the goods",
    "Selling point?",
    "Boutique",
    "See 22-Across",
    "Get groceries, say",
    "\"___ till you drop\"",
    "Prime directive?",
    "Class with drills",
    "Cutting class?",
    "Class with drills",
    "Word with coffee or gift",
    "Chamber of commerce?",
    "Ice cream ___",
    "Go to the mall",
    "Word with work or window",
    "Fill time at an airport, say",
    "\"Little ___ of Horrors\"",
    "Word with gift or thrift",
    "Cutting class?",
    "Butchery or bakery",
    "Boutique",
    "Union ___",
    "Get many price quotes",
    "Word with china or chop",
    "Woodworking or metalworking class",
    "Class of tools?",
    "High-school class",
    "Factory",
    "Steward's domain",
    "Search for 37-Downs",
    "Butcher's, baker's or candlestick maker's",
    "Awning site",
    "Yellow Pages listing",
    "Pick stuff up",
    "Machinist's workplace",
    "Kind of steward",
    "Butcher's or bakery",
    "High school class",
    "Industrial arts class",
    "Talk ___",
    "Word with chop or swap",
    "Factory",
    "See 18-Across",
    "Home ec's counterpart",
    "Haberdashery, e.g.",
    "Mall tenant",
    "Carpentry class",
    "High school course",
    "Get the goods",
    "Window-___",
    "Boutique",
    "Word with chop or sweat",
    "Business",
    "Work area",
    "Kind of talk",
    "Boutique",
    "Boutique",
    "Boutique",
    "Mall division",
    "Word with closed or window",
    "Factory",
    "Class that uses 29-Across",
    "The Old Curiosity, e.g.",
    "Market place"
  ],
  "bark": [
    "Speak in a husky voice?",
    "Tree cover",
    "Something produced by a dogwood tree ... or a dog",
    "Dog sound",
    "Fir coat?",
    "Lab report?",
    "Communicate like a drill sergeant",
    "Medium for love letters?",
    "Dog's warning",
    "Where you might see some initials",
    "\"Speak\" response",
    "\"Woof!,\" e.g.",
    "It may be worse than a bite",
    "Give orders like a drill sergeant",
    "Cinnamon source",
    "Tree hugger?",
    "Intruder's deterrent, maybe",
    "First alert, often",
    "Initials may be carved in it",
    "Burglar's worry, maybe",
    "Intruder alert?",
    "Burglar's deterrent, maybe",
    "Boxer's comeback?",
    "Kennel cry",
    "Yap",
    "Bowwow",
    "Trunk cover",
    "Sergeant's voice"
  ],
  "leto": [
    "Jared who played the Joker in \"Suicide Squad\"",
    "Jared of \"Dallas Buyers Club\"",
    "Best Supporting Actor winner for \"Dallas Buyers Club\"",
    "Oscar-winning Jared",
    "Mother of Apollo and Artemis",
    "Oscar winner Jared",
    "Actor Jared",
    "Jared of \"Dallas Buyers Club\"",
    "\"Dallas Buyers Club\" Oscar winner",
    "Mother of Artemis",
    "Jared of \"Dallas Buyers Club\"",
    "Jared who won an Oscar for \"Dallas Buyers Club\"",
    "Actor Jared of \"Suicide Squad\"",
    "Jared who won an Oscar for \"Dallas Buyers Club\"",
    "Oscar winner Jared of \"Dallas Buyers Club\"",
    "Mother of Artemis",
    "Oscar winner for \"Dallas Buyers Club\"",
    "Jared of \"Dallas Buyers Club\"",
    "Actor Jared of \"Dallas Buyers Club\"",
    "Jared of \"Dallas Buyers Club\"",
    "Actor Jared of \"My So-Called Life\"",
    "Actor Jared",
    "Jared of \"Mr. Nobody\"",
    "Goddess pursued by Hera",
    "Consort of Zeus",
    "Mother of Apollo",
    "Actor Jared",
    "Duke Atreides in \"Dune\"",
    "Actor Jared",
    "Actor Jared",
    "Daughter of Phoebe",
    "Jared of \"Panic Room\"",
    "Actor Jared",
    "Mother of Apollo and Artemis",
    "Mother of Apollo",
    "Mother of Artemis",
    "Jared of \"Panic Room\"",
    "Mother of Apollo",
    "\"My So-Called Life\" actor Jared",
    "Mother of Apollo",
    "Mother of Apollo",
    "Mother of Apollo",
    "Mother of Apollo",
    "Mother of Apollo",
    "Mother of Apollo"
  ],
  "fema": [
    "Disaster relief org.",
    "Disaster relief org.",
    "Branch of the D.H.S.",
    "Government disaster org.",
    "Disaster relief org.",
    "Hurricane relief org.",
    "Damage control org.",
    "Part of the Dept. of Homeland Security",
    "Relief org. after hurricanes and tornadoes",
    "Disaster aid org.",
    "Org. that prepares flood maps",
    "Relief provider, for short",
    "Disaster relief acronym",
    "What comes as a relief?: Abbr.",
    "Damage control grp.",
    "Damage-assessing grp.",
    "Disaster preparedness org.",
    "Disaster relief org.",
    "Org. criticized in \"When the Levees Broke\"",
    "U.S. disaster relief org.",
    "Org. created by Carter in 1979",
    "Part of the Dept. of Homeland Security since 2003",
    "Damage assessment grp.",
    "Relief provider, for short",
    "Govt. disaster relief org.",
    "Disaster relief org.",
    "Govt. disaster-aid inits."
  ],
  "undo": [
    "Void",
    "Reverse",
    "Ctrl+Z",
    "Computer command to go back",
    "Excel command",
    "Loosen, as laces",
    "Ctrl+Z",
    "Render null and void",
    "Computer command",
    "\"Erase\" on a computer",
    "Computer command",
    "Reverse",
    "Backup software option?",
    "Countermand",
    "Computer command for the error-prone",
    "Repeal",
    "Reverse",
    "Reverse",
    "Word processing command",
    "Command-Z command",
    "Bring to naught",
    "Mistyping remedy",
    "Control+Z computer command",
    "Edit menu option",
    "\"Changed my mind\" computer command",
    "Reverse",
    "Cancel",
    "Edit command",
    "Helpful computer command",
    "Command to return to a former state",
    "Loosen, as laces",
    "\"Go back,\" on an edit menu",
    "Reverse",
    "Reverse",
    "Reverse",
    "Control+Z on a PC",
    "Edit menu option",
    "\"Go back,\" on an edit menu",
    "Reverse, as an action",
    "Cancel",
    "\"Go back\" computer command",
    "Take back",
    "Reverse",
    "Menu option",
    "Menu choice",
    "Erase",
    "Reverse, on a PC",
    "Computer command",
    "Ruin",
    "Release",
    "Nullify",
    "Cancel, on a PC",
    "Release the buttons of",
    "Destroy",
    "Reverse",
    "Reverse, as an action",
    "Computer correction command",
    "Word processor command",
    "Ruin",
    "Word processor command",
    "Reverse",
    "Word processor option",
    "Bring to ruin",
    "Ruin",
    "Loosen",
    "Annul",
    "Take back",
    "Cancel",
    "Destroy",
    "Take apart",
    "Ruin",
    "Choice on an Edit menu",
    "Reverse",
    "Reverse",
    "Nullify",
    "Menu option",
    "Reverse",
    "Word processing command",
    "Word processor command",
    "Word processing command",
    "Word processing command",
    "Take apart",
    "Nullify",
    "Reverse",
    "Reverse",
    "Disassemble",
    "Loosen",
    "Take apart",
    "Loosen",
    "Take apart",
    "Take apart",
    "Ruin",
    "Bring down",
    "Bring to ruin",
    "Strike down",
    "Pull strings?",
    "Reverse, as damage",
    "Take apart",
    "Nullify",
    "Cancel",
    "Bring to ruin",
    "Destroy",
    "Nullify",
    "Fix a faux pas",
    "Reverse",
    "Disconnect",
    "Remove, as a knot",
    "Reverse"
  ],
  "spot": [
    "Lend a few bucks to, say",
    "Assist with some heavy lifting",
    "Word before check ... or a pattern alternative to a check",
    "See 33-Across",
    "Catch sight of",
    "Feature of a cheetah's coat",
    "Difficult situation",
    "Help out, as a bench-presser",
    "Notice",
    "Dry cleaner's challenge",
    "Help out, in a gym",
    "TV ad",
    "Spy",
    "Dog unlikely to have a solid coat",
    "Difficult situation",
    "Ad",
    "Help in a gym",
    "Difficult situation",
    "Tricky situation",
    "Dalmatian feature",
    "Catch sight of",
    "What you might call a dog",
    "Espy",
    "Help at the gym, say",
    "Dick and Jane's dog",
    "TV or radio ad",
    "Leopard's marking",
    "Word after beauty or blind",
    "Bleach target",
    "Lend, slangily",
    "Catch sight of",
    "Feature of a Dalmatian's coat",
    "Advance",
    "\"See ___ run\"",
    "Gym request",
    "Pretreater target",
    "Good name for a Dalmatian",
    "Mark",
    "Lend for a short while",
    "Bind",
    "\"Great\" red feature of Jupiter",
    "Lend, informally",
    "Good name for a Dalmatian",
    "Add",
    "Parking space",
    "Locate, as Waldo",
    "Good name for a Dalmatian",
    "Dry cleaner's challenge",
    "Give a handicap of",
    "\"Out, damned ___!\"",
    "Descry",
    "Dick and Jane's dog",
    "\"You missed a ___!\"",
    "Primer pooch",
    "Good name for a Dalmatian",
    "Catch sight of",
    "Relative of Rover",
    "Predicament",
    "Commercial",
    "Predicament",
    "Catch a glimpse of",
    "Lend, informally",
    "Parking place",
    "Soft or sweet follower",
    "Primer dog",
    "Advance, slangily",
    "Advance, slangily",
    "Kind of check",
    "Pick out",
    "Butterfly wing feature",
    "Predicament",
    "Theater light",
    "Good name for a Dalmatian",
    "Parking place",
    "Detect",
    "Smudge",
    "Kind of announcement",
    "Smudge",
    "\"X\" may mark it",
    "Good name for a Dalmatian",
    "Primer pet",
    "Poor name for a solid-colored dog",
    "Measles symptom",
    "\"You missed a ___!\"",
    "Descriptive dog name",
    "Predicament",
    "Predicament",
    "Cheetah feature",
    "Smudge",
    "See",
    "Ad",
    "Cleaner's challenge",
    "Dick and Jane's dog",
    "Predicament",
    "Lady Macbeth's problem",
    "TV ad",
    "Traditional pooch name",
    "Difficult position",
    "TV commercial",
    "Detect",
    "\"Fix\"",
    "Common dog's name",
    "Fido's cousin",
    "Smudge",
    "TV ad",
    "Difficult situation",
    "Predicament",
    "Espy",
    "Lady Macbeth wanted one out",
    "Common dog's name",
    "Recognize",
    "Espy",
    "It can be soft or blind",
    "Difficult position",
    "Flyspeck"
  ],
  "post": [
    "Station",
    "Blog entry",
    "Grape-Nuts maker",
    "Share on social media",
    "Share on social media",
    "Comment on a blog",
    "Tweet, e.g.",
    "Use Instagram, e.g.",
    "Online comment",
    "Blog update, e.g.",
    "Facebook entry",
    "Put on YouTube, e.g.",
    "Blog entry",
    "Honeycomb maker",
    "Diplomatic assignment",
    "Writing on the Wall?",
    "Guarded place",
    "Sentry's station",
    "Blog entry",
    "Blogger's bit",
    "Mail",
    "A Webmaster may approve it",
    "Blog comment",
    "Letters",
    "Part of P.O. or P.S.",
    "Station",
    "Blog bit",
    "Assignment",
    "Station",
    "Washington daily",
    "Washington paper, with \"the\"",
    "The \"P\" of PX",
    "Put up",
    "Assignment",
    "Hitching place",
    "With 81-Down, like some recoveries",
    "Place on duty",
    "Mail delivery, to Brits",
    "Either end of a gate",
    "Drop in the mail",
    "Put up",
    "Publicize",
    "Appointment",
    "Part of 108-Down",
    "American Legion place",
    "Put up",
    "From pillar to ___",
    "Ambassadorship, e.g.",
    "Assignment",
    "Announce",
    "Assignment",
    "Hitching ___",
    "Mail",
    "Starting gate"
  ],
  "whiz": [
    "Fly (by)",
    "Expert, informally",
    "Genius",
    "Brainiac",
    "Phenom",
    "Cheez ___",
    "Zoom",
    "Crackerjack",
    "Phenom",
    "Shoot (by)",
    "Ace",
    "Brainiac",
    "Virtuoso",
    "Crackerjack"
  ],
  "hutu": [
    "Native Rwandan",
    "Ethnic group of Rwanda and Burundi",
    "Native of Rwanda",
    "Member of the largest Rwandan ethnic group",
    "Native Rwandan",
    "Rwandan group",
    "Native Rwandan",
    "Rwandan people",
    "Certain Rwandan",
    "Rwanda native"
  ],
  "hyde": [
    "Misanthrope of Victorian literature",
    "___ Park, New York birthplace of F.D.R.",
    "___ Park Corner (London tube station)",
    "Jekyll's bad side",
    "Douglas ___, first president of Ireland",
    "___ Amendment, controversial 1976 Congressional measure",
    "Jekyll's bad side",
    "London's ___ Park",
    "Fictional Mr.",
    "Jekyll's counterpart",
    "Chicago's ___ Park",
    "Literary alter ego",
    "One side of a Stevenson character",
    "Jekyll's alter ego",
    "Stevenson villain",
    "___ Park (Chicago neighborhood once home to Obama)",
    "Title Mr. of literature",
    "London's ___ Park",
    "Jekyll's counterpart",
    "Bad side of literature?",
    "___ Park (F.D.R.'s home)",
    "Jekyll's alter ego",
    "Half of a famous split personality",
    "Stevenson's misanthrope",
    "Jekyll's counterpart",
    "London's ___ Park",
    "Fictional Mr.",
    "Douglas ___, first president of Ireland",
    "Henry who once headed the House Judiciary Committee",
    "1995-2001 House Judiciary Committee chairman",
    "Jekyll's bad side",
    "Jekyll's alter ego",
    "London's ___ Park",
    "Mr. of 19th-century fiction",
    "Stevenson fiend",
    "London's ___ Park",
    "Jekyll's counterpart",
    "Henry of the House Judiciary Committee",
    "London's ___ Park",
    "Fictional \"Mr.\"",
    "Famous alter ego",
    "London's ___ Park",
    "Evil alter ego",
    "Jekyll's alter ego",
    "Noted park name",
    "Stevenson scoundrel",
    "Jekyll's counterpart",
    "F.D.R.'s ___ Park",
    "London park name",
    "Jekyll's counterpart",
    "Jekyll's alter ego",
    "Stevenson character",
    "Park visited by Sherlock Holmes",
    "London's ___ Park",
    "Fictional Mr.",
    "London park",
    "Jekyll's counterpart"
  ],
  "serb": [
    "Tesla, e.g.",
    "Participant in a 1990s civil war",
    "Tesla, e.g.",
    "Speaker of a language that has both Cyrillic and Latin alphabets",
    "Dinar spender",
    "Member of a Macedonia minority",
    "Neighbor of a Montenegrin",
    "Belgrade denizen",
    "Tennis's Novak Djokovic, by birth",
    "Belgrade native",
    "Southern Slav",
    "Kosovo native",
    "Belgradian, e.g.",
    "Novak Djokovic, for one",
    "Tennis's Novak Djokovic, e.g.",
    "Tesla, e.g.",
    "Tennis's Ana Ivanovic, for one",
    "Balkan native",
    "Belgrade resident",
    "Belgrade native",
    "Balkan resident",
    "Tennis's Novak Djokovic, by birth",
    "Balkan native",
    "Dinar earner",
    "Balkan native",
    "Many a Bosnian",
    "Dinar spender",
    "Belgrade native",
    "Landlocked European",
    "Landlocked European",
    "Balkan native",
    "Dinar earner",
    "Belgrade resident",
    "Balkan native",
    "Dweller along the Danube",
    "Dweller on the Morava River",
    "Nikola Tesla, for one",
    "Belgrade native",
    "Belgrade native",
    "Belgrade resident",
    "Balkan native",
    "Certain Slav",
    "A Balkanite",
    "Novi Sad resident",
    "1999 war combatant",
    "Certain Slav",
    "Certain dweller on the Danube",
    "Macedonian's neighbor",
    "Kosovo war participant",
    "Dweller on the Danube",
    "Dweller along the Morava River",
    "Belgrade native",
    "Belgrade native",
    "Many a Bosnian",
    "Belgrade native",
    "European in the news",
    "Vojvodina resident",
    "Macedonian's neighbor",
    "Romanian's neighbor",
    "Balkan native",
    "Kosovo combatant",
    "Native of Novi Sad",
    "Belgrade native",
    "Combatant in Kosovo",
    "Cyrillic alphabet user",
    "Balkan combatant",
    "Albanian foe",
    "Certain Balkan",
    "Slobodan Milosevic, e.g.",
    "Recent fighter",
    "Native of Novi Sad",
    "Author Ivo Andric, for one",
    "Bosnian ___",
    "Slobodan Milosevic, e.g.",
    "Certain Slav",
    "Certain Bosnian",
    "Dweller along the Morava",
    "Croat's neighbor",
    "Novi Sad native",
    "Belgrade dweller",
    "Milosevic, e.g.",
    "Novi Sad native",
    "Belgrade resident"
  ],
  "geri": [
    "Pop star Halliwell of the Spice Girls",
    "___ Halliwell, real name of Ginger Spice",
    "Spice Girl ___ Halliwell",
    "Former Spice Girl Halliwell",
    "Pop singer Halliwell",
    "Spice Girl Halliwell",
    "Spice Girl Halliwell",
    "Ginger Spice's first name",
    "\"The Facts of Life\" actress Jewell",
    "Spice Girl Halliwell",
    "Ex-Spice Girl Halliwell",
    "Halliwell of the Spice Girls",
    "Spice Girl Halliwell",
    "Ex-Spice Girl Halliwell",
    "Spice Girl Halliwell",
    "One of the Spice Girls",
    "Spice Girl a k a Ginger",
    "Former Spice Girl Halliwell",
    "Halliwell, formerly of the Spice Girls",
    "Jewell of \"The Facts of Life\""
  ],
  "aped": [
    "Made an impression?",
    "Mirrored",
    "Acted and spoke like",
    "Did a pantomime of",
    "Mimicked",
    "Parodied",
    "Mimicked",
    "Followed closely?",
    "Made an impression?",
    "Made like",
    "Took off on",
    "Made like",
    "Acted like",
    "Took after",
    "Mimicked",
    "Parroted",
    "Mimicked",
    "Mimicked",
    "Made like",
    "Mimicked",
    "Made fun of, in a way",
    "Parodied",
    "Mimicked",
    "Imitated",
    "Parroted",
    "Made like",
    "Mimicked",
    "Copied",
    "Copied",
    "Mimicked",
    "Mimicked",
    "Took off on",
    "Mimicked",
    "Acted like",
    "Made fun of, in a way",
    "Made like",
    "Copied",
    "Did like",
    "Mimicked",
    "Made like",
    "Took off on",
    "Did a sendup of",
    "Simulated",
    "Sent up",
    "Did the same as",
    "Parodied",
    "Took off on",
    "Parodied",
    "Parroted",
    "Imitated",
    "Impersonated",
    "Made like",
    "Acted like",
    "Burlesqued",
    "Did a Little bit",
    "Copied",
    "Mimicked",
    "Parroted",
    "Mirrored",
    "Parroted",
    "Copied",
    "Copied",
    "Mimicked",
    "Parroted",
    "Mirrored",
    "Copied",
    "Took off on",
    "Imitated",
    "Mimicked",
    "Followed closely",
    "Copied",
    "Mimed",
    "Made fun of, in a way",
    "Copied",
    "Mirrored",
    "Copied"
  ],
  "citi": [
    "Big Four bank, for short",
    "Credit card name",
    "Queens's ___ Field",
    "Commercial lead-in to card",
    "New York's ___ Field",
    "Bank with M.L.B. naming rights, for short",
    "New York's ___ Field",
    "___ Field (home to the Mets)",
    "Big Four bank, informally",
    "Lead-in to bank",
    "New York's ___ Field",
    "Commercial lead-in to group",
    "Queens's ___ Field",
    "New York's ___ Field",
    "Bank opening?",
    "___ Field",
    "Commercial lead-in to bank",
    "___ Field",
    "___ Field",
    "New York's ___ Field",
    "Field opening?",
    "One of American banking's Big Four, for short",
    "Bank opening?",
    "Big name in credit cards",
    "Municipality, in some financial names",
    "Commercial prefix with bank",
    "Commercial prefix with bank",
    "Big prefix in banking"
  ],
  "eros": [
    "God depicted in a figure called an \"amoretto\"",
    "Bow-wielding god",
    "God who \"loosens the limbs and weakens the mind,\" per Hesiod",
    "One who takes a bow before success rather than after?",
    "Greek god of love",
    "Psyche's mate in Greek mythology",
    "Greek god pictured with wings and a bow",
    "One taking a bow for getting couples together?",
    "Concept in Freudian psychology",
    "God whose name is an apt anagram of \"rose\"",
    "Greek counterpart of Cupid",
    "Another name for Cupid",
    "God who becomes a goddess when an \"r\" is removed",
    "One variety of love",
    "Love god",
    "Mythical matchmaker",
    "Freudian concept",
    "Mythical archer",
    "Shooter of arrows",
    "Son of Aphrodite and Ares",
    "Freudian focus",
    "Bow-wielding god",
    "Mischievous boy of myth",
    "Greek god with a bow",
    "Statue in Piccadilly Circus",
    "Psyche's beloved",
    "Mythological figure who takes a bow",
    "Another name for Cupid",
    "Relative of philia and agape, to the Greeks",
    "\"The Garden of ___\" (Oscar Wilde poem)",
    "Fourth god to exist, in Greek myth",
    "Greek equivalent of Cupid",
    "Love symbol that names another love symbol if you move the first letter to the end",
    "Match maker?",
    "Life instinct, in psychology",
    "Arrow-shooting figure",
    "Son of Aphrodite",
    "Freudian \"will to live\"",
    "\"The great binder and loosener,\" per Jung",
    "One taking a bow in Greek art",
    "Matchmaker of myth",
    "Love god",
    "Boy with a bow and arrow",
    "February 14th figure",
    "Bow-toting deity",
    "Olympian with a bow",
    "God with a bow",
    "Sexual love",
    "Destination of NASA's NEAR",
    "Greek matchmaker",
    "Love god",
    "Libidinous god",
    "Statue in London's Piccadilly Circus",
    "Boy taking a bow",
    "Winged archer of myth",
    "Oscar Wilde poem \"The Garden of ___\"",
    "Inspiration for a troubadour",
    "Winged figure of myth",
    "\"The Garden of ___,\" Oscar Wilde poem",
    "Counterpart of 1-Across",
    "\"Now ___ shakes my soul\": Sappho",
    "God with a bow and arrow",
    "Amor's counterpart",
    "Libido",
    "Lover of Psyche",
    "Son of Aphrodite",
    "Arrow-shooting Greek god",
    "God with a quiver",
    "Mythological lover boy",
    "Son of 30-Down",
    "Bow-toting god",
    "Lover of Psyche",
    "Figure taking a bow?",
    "Symbol of love",
    "Figure with arrows",
    "Lover of Psyche",
    "Winged Greek god",
    "Arrow shooter of Greek myth",
    "Early wielder of a bow and arrow",
    "Arrow-shooting Greek god",
    "Heart-piercing figure",
    "Lust, deified",
    "Love personified",
    "Classical bow wielder",
    "Love god",
    "Arrow shooter",
    "God with a bow and arrow",
    "God whose name is 6-Down reversed",
    "Cupid's Greek counterpart",
    "Love god",
    "Bow-toting god",
    "Baby taking a bow?",
    "Cupid, to the Greeks",
    "Greek god with a bow and arrow",
    "Love child?",
    "Cupid, to the Greeks",
    "Freud's libido",
    "Greek god whose name is one letter off from 118-Down",
    "Amor's Greek counterpart",
    "Greek god with a bow and arrow",
    "Cupid, to the Greeks",
    "First near-Earth asteroid to be discovered",
    "Libido",
    "Winged Greek god",
    "Olympic archer",
    "Greek god who figures in an annual holiday",
    "Greek love god",
    "Opposite of Thanatos, to Freud",
    "One taking a bow?",
    "Cupid's Greek counterpart",
    "Winged Greek god",
    "Love god",
    "Lover boy?",
    "Greek Cupid",
    "Counterpart of Thanatos, in Freudian psychology",
    "Psyche's love",
    "Asteroid on which a NASA probe landed in 2001",
    "One taking a bow?",
    "Lustful god",
    "Cupid's Greek counterpart",
    "Subject for Freud",
    "Bow-wielding god",
    "Offspring of Chaos, to Hesiod",
    "Cupid",
    "Archer who aims for the heart",
    "Libido",
    "Personification of desire",
    "Piccadilly Circus statue",
    "Lover boy?",
    "Carrier of a bow and arrows",
    "God of love",
    "Love child?",
    "Psyche's love",
    "Troubadour's inspiration",
    "Greek love god",
    "Greek Cupid",
    "Bow-wielding deity",
    "Asteroid discovered in 1898",
    "Son of Aphrodite",
    "First asteroid landed on by a spacecraft",
    "Love god",
    "\"The Garden of ___\" (Wilde poem)",
    "\"Aeneid\" figure",
    "One who might take a bow",
    "First asteroid orbited by a NASA spacecraft",
    "Olympian archer",
    "Greek love god",
    "Greek god of love",
    "February 14 figure",
    "Taker of a bow?",
    "Cupid, to the Greeks",
    "Cupid's Greek counterpart",
    "Desire personified",
    "London statue originally called the Shaftesbury Monument",
    "Life instinct, to Freud",
    "Olympic archer",
    "Lustful god",
    "Deity with a bow and arrow",
    "Love",
    "God of love",
    "\"Aphrodite and ___\" (classic art subject)",
    "Love symbol",
    "The libido, in psychiatry",
    "Archer of myth",
    "Lover boy?",
    "Arrow shooter",
    "Name hidden in seven other answers in this puzzle",
    "Olympic archer",
    "Cupid's counterpart",
    "Son of Aphrodite",
    "Love personified",
    "Greek counterpart of 27-Down",
    "Cupid's counterpart",
    "Piccadilly Circus statue",
    "Greek god sometimes pictured as blindfolded",
    "February figure",
    "Daring 1960's Ralph Ginzburg magazine",
    "Ardent love",
    "Love symbol",
    "Son of Aphrodite",
    "\"Aeneid\" figure",
    "Son of Aphrodite",
    "Libido",
    "Cupid's Greek counterpart",
    "Oscar Wilde poem \"The Garden of ___\"",
    "God of love",
    "One taking a bow?",
    "Bow and arrow carrier",
    "Son of Aphrodite",
    "God of love",
    "Libidinous deity",
    "Oscar Wilde poem \"The Garden of ___\"",
    "God often depicted unclothed in art",
    "Desire",
    "Libido, in psychiatry",
    "Lover who abandoned Psyche",
    "Power of love",
    "Son of Aphrodite",
    "Son of Chaos",
    "Asteroid landed on in 2001",
    "Love inspirer",
    "One of the primal gods in Greek myth",
    "Where the NEAR space probe landed",
    "Son of Aphrodite",
    "Son of Aphrodite",
    "One taking a bow?",
    "First asteroid landed on by a NASA craft",
    "Libido",
    "Asteroid viewed by the NEAR spacecraft, 2000",
    "Life force, to Freud",
    "Symbol of love",
    "See 45-Down",
    "Cupid",
    "Piccadilly Circus statue",
    "Cupid, to the Greeks",
    "Lustful god",
    "Love child",
    "Psyche's love",
    "Winged youth of myth",
    "Boy armed with bows and arrows",
    "Antony's faithful aide, in \"Antony and Cleopatra\"",
    "Libido",
    "Son of Aphrodite",
    "Boy who's bowed",
    "Asteroid discovered in 1898",
    "Love symbol",
    "Servant of Antony in \"Antony and Cleopatra\"",
    "Libido",
    "Asteroid first seen in 1898",
    "Piccadilly Circus statue",
    "God offended by Daphnis",
    "Piccadilly Circus statue",
    "Psyche's lover",
    "\"Theogony\" figure",
    "Love's inspiration",
    "Love child?",
    "Controversial 1960's magazine",
    "February 14 figure",
    "Son of Aphrodite",
    "Greek god of love",
    "Dart shooter",
    "Passion personified",
    "God of love",
    "Son of Aphrodite",
    "February 14 figure",
    "God offended by Daphnis",
    "Passion personified",
    "Valentine's Day visitor",
    "Son of Aphrodite",
    "\"Plan 9 From Outer Space\" alien",
    "Asteroid discovered in 1898",
    "Cupid",
    "Servant of Antony in \"Antony and Cleopatra\"",
    "Greek love god",
    "Sexual desire",
    "God with a bow",
    "Greek Cupid",
    "Greek love god",
    "February 14 name",
    "Piccadilly Circus figure",
    "Mythological arrow-shooter",
    "Sexual love",
    "Valentine's Day figure",
    "Cupid",
    "Cupid",
    "\"Antony and Cleopatra\" character",
    "Piccadilly Circus statue",
    "Libido",
    "Winged god",
    "Little Archer",
    "Desirous Greek god",
    "Boy who takes a bow?",
    "Piccadilly Circus statue",
    "Love of Greece?",
    "February 14 figure",
    "Son of Aphrodite",
    "Magazine for which publisher Ralph Ginzburg went to jail",
    "Romance symbol",
    "Piccadilly Circus statue"
  ],
  "numb": [
    "\"Asleep\"",
    "Tingling, in a way",
    "Feeling no pain",
    "Like an arm that's been slept on too long",
    "Use an ice pack on",
    "Give an epidural, e.g.",
    "Unfeeling",
    "Under the effects of Novocain",
    "Senseless",
    "Shoot with Novocain, say",
    "Senseless",
    "Unfeeling",
    "Feeling no pain",
    "Unfeeling",
    "Under the effects of Novocain",
    "Insensitive",
    "Without feeling",
    "Unfeeling",
    "Ready for surgery, in a way",
    "Senseless",
    "Deaden",
    "All tingly",
    "More than shocked",
    "Not feeling",
    "Feeling the effects of Novocaine",
    "Sans senses"
  ],
  "hill": [
    "One of a Roman septet",
    "Knoll",
    "Bit of raised land",
    "Ending with Capitol or Faith",
    "See 33-Across",
    "Congress, with \"the\"",
    "Setting of Sisyphus' perpetual rock-pushing",
    "Marathon runner's bane",
    "Rise",
    "What Jack and Jill went up",
    "Reason to downshift",
    "Over the ___",
    "Capitol ___",
    "One of a Roman septet",
    "Challenge for Jack and Jill",
    "Rise",
    "Washington's Capitol ___",
    "King's position, in a game",
    "Site of Jack and Jill's spill",
    "Sledding spot",
    "Sledder's spot",
    "San Francisco's Nob ___",
    "Sledder's spot",
    "Sledding spot",
    "San Francisco's Nob ___",
    "Bunker ___",
    "See 13-Down",
    "Challenge, metaphorically",
    "Eminence",
    "___ Valley (\"Back to the Future\" locale)",
    "Rise",
    "Challenge, metaphorically",
    "Grade",
    "Capitol site, with \"the\"",
    "Elevation",
    "Highway sign",
    "Marathoner's ordeal",
    "Auto racer Graham"
  ],
  "eave": [
    "Roof part",
    "Rain gutter locale",
    "Spot for an icicle",
    "Rafter neighbor",
    "Place to hang holiday lights",
    "Roof part",
    "Home's edge",
    "Projecting edge",
    "Rain gutter site",
    "Roof part",
    "Part of a roof",
    "Christmas light locale",
    "What goes above and beyond?",
    "Overhang",
    "Roof feature",
    "Window shade?",
    "Part of a house that projects",
    "Where icicles may hang",
    "Gutter locale",
    "Projected expense for a roofer?",
    "Water diverter",
    "Part of the roof with the gutter",
    "Icicle's place",
    "Shade provider",
    "Origination point of some drips",
    "Building's rain diverter",
    "Wasp's nest site",
    "Gutter cleaner's work area",
    "Part of the roof where icicles form",
    "Place for an icicle",
    "Ice dam locale",
    "Overhang",
    "Ice dam site",
    "Mansard overhang",
    "Icicle site",
    "Icicle's place",
    "Gutter locale",
    "Locale for Christmas lights",
    "Roof overhang",
    "Mansard part",
    "House overhang",
    "Shade provider",
    "Place for a wasp's nest",
    "Gutter site",
    "Icicle site",
    "Roof overhang",
    "Roof part",
    "Overhang",
    "Hangover?",
    "Roof projection",
    "Shade provider",
    "Nesting place for a bird",
    "Christmas light site",
    "Roof extension",
    "Rain gutter site",
    "Nesting site",
    "Roof overhang",
    "Something a dome lacks",
    "Roof's edge, often",
    "Holder of a runoff?",
    "Builder's projected expense?",
    "Projecting edge",
    "Gutter site",
    "Roof overhang",
    "Place where leaves are collected",
    "Christmas light site",
    "Icicle site",
    "Projecting edge on a roof",
    "Roof projection",
    "Runoff site",
    "Shade provider",
    "Overhang",
    "Gutter site",
    "Ice dam site",
    "Projecting edge",
    "One hanging around the house",
    "Icicle's place",
    "Wall protector",
    "Overhang",
    "Roof overhang",
    "Rain collector",
    "Roof overhang",
    "Gutter site",
    "Overhang",
    "Roof overhang",
    "Trough site",
    "Shade provider",
    "Overhang",
    "Gutter locale",
    "Roof's edge",
    "Gutter location",
    "Trough site",
    "House shader",
    "Wasp's nest site",
    "Roof part",
    "Gutter locale",
    "Gutter site",
    "Roof's edge",
    "Icicle site",
    "Place for a wasp's nest",
    "Where Christmas lights may be hung",
    "House overhang",
    "Gutter site",
    "Hanger-on?",
    "Roof overhang",
    "Spot for an icicle",
    "Gutter neighbor",
    "Projection",
    "Mansard overhang",
    "Roof projection",
    "Icicle's locale",
    "Roof edge",
    "Place for a gutter",
    "Hangover?",
    "Roof overhang",
    "Mansard extension",
    "Overhang",
    "Gutter site",
    "Overhang",
    "Overhang",
    "Drip site",
    "Overhang",
    "Roof's edge",
    "Roof overhang",
    "Sun shade",
    "Overhang",
    "Leaf gatherer",
    "Roof overhang",
    "It may drip",
    "Sun shade",
    "___trough (rain runoff site)",
    "Roof edge",
    "Place for hangers-on?",
    "Projecting shape",
    "Roof overhang",
    "Homeowner's hangover?",
    "Overhang",
    "Icicle holder",
    "___ spout (house part)",
    "Gutter site",
    "Roof overhang",
    "Roof projection"
  ],
  "bffs": [
    "Buds that are very close",
    "Closest of pals, for short",
    "Inseparable buds",
    "Tightest of pals, in brief",
    "People who are tight, in modern lingo",
    "More than buds",
    "Contacts list faves",
    "Closest of pals, informally",
    "People who are totes close",
    "Lifelong pals, slangily",
    "Bosom buddies, in modern lingo",
    "Close buds"
  ],
  "sump": [
    "Drainage collector",
    "Basement feature",
    "Kind of pump",
    "Drainage pit",
    "Cesspool",
    "Drainage pit",
    "Water collector",
    "Drainage pit",
    "Waste reservoir",
    "Well",
    "Drainage pool",
    "Place for a pump",
    "Cesspool",
    "Cesspool",
    "Water pit",
    "Water hole",
    "Water pit",
    "Water collector",
    "Well",
    "Cesspool",
    "Water collector",
    "Its usefulness goes to waste",
    "Waste reservoir"
  ],
  "epee": [
    "Sports event in which athletes try to avoid being touched",
    "Fencing blade",
    "Olympic poker?",
    "Tool for a duel",
    "Heavier alternative to a foil",
    "One-on-one Olympic event",
    "Fencing sword",
    "Something there's almost no point to?",
    "Relative of a foil",
    "Modern pentathlon event",
    "Dueling sword",
    "Sports sword",
    "Weapon with two accents in its name",
    "Something waved in the Olympics",
    "Olympic pentathlete's need",
    "Weapon with a bell guard",
    "Sword with a sensor",
    "Weapon used when saying \"En garde!\"",
    "Relative of a foil",
    "Blade with a bell guard",
    "Fencing sword",
    "Sword for an Olympian",
    "Sword's name with two accents",
    "Sports event with two diacritics in its name",
    "One-on-one Olympics event",
    "Competition whose opponents try to touch each other",
    "Blade with no sharp edge",
    "Need for a modern pentathlon",
    "Event in every Summer Olympics since 1900",
    "Duel tool",
    "Weapon with a bell guard",
    "Pentathlon event",
    "Blade in a sporting match",
    "French for \"sword\"",
    "Sporting sword",
    "Sports item with a sensor",
    "Fencing sword",
    "Fencing option",
    "Weapon in fencing",
    "Pentathlete's need",
    "Fencing blade",
    "One guarded in a duel?",
    "Summer Olympics event",
    "Tool for a duel",
    "Electrified bit of sports equipment",
    "Jabber?",
    "Olympics sword",
    "Dueler's option",
    "Cousin of a foil",
    "It may be waved at the Olympics",
    "Sport played on a piste",
    "Poker game?",
    "French pioneer of sign language",
    "___ du combat",
    "Sports event with electronic scoring",
    "One guarded at the Olympics",
    "Sport with double touches",
    "Weapon with a bell guard",
    "Dueler's sword",
    "Sporting sword",
    "Abbe de l'___, pioneer in sign language",
    "Fencing implement",
    "Modern pentathlon event",
    "Summer Olympics event",
    "Olympics sword",
    "Event with touches",
    "Sport with automated scoring",
    "Blunted blade",
    "Dueling implement",
    "Lunging sport",
    "Sporting weapon",
    "Fencing blade",
    "Sport with a French name",
    "Modern pentathlon event",
    "Only event in which Venezuela medaled at the 2012 Olympics",
    "Event with body cords",
    "Electronically scored duel",
    "Olympic sword",
    "Light weapon",
    "Three-sided weapon",
    "Lightish blade",
    "Sword you score points with",
    "Thrust item",
    "French-derived word with two accents",
    "___ du combat",
    "Sporting sword",
    "Olympic event with electrified equipment",
    "Sport with touches",
    "Weapon for fencing",
    "Athlete's pointer?",
    "Sport with lunges",
    "Fencing sword",
    "Form of fencing",
    "Item to thrust",
    "It serves a duel purpose",
    "Fencer's sword",
    "Olympic blade",
    "Electric weapon",
    "\"Touching\" Olympic event",
    "Olympian's blade",
    "Sport with lunges",
    "Thin blade",
    "Modern pentathlon event",
    "Cousin of a foil",
    "Thing with a bell guard",
    "Tool for a duel",
    "Sport whose name has two accents",
    "Descendant of the smallsword",
    "Fencing sword",
    "Individual and team event at the Olympics",
    "Foil alternative",
    "Sword of sport",
    "Item of sports equipment approximately 43\" long",
    "Napoleonic army weapon",
    "One not making the cut?",
    "Triple-edged sword",
    "Fencing blade",
    "Sword: Fr.",
    "Parrying weapon",
    "Fencing sword",
    "Blunted blade",
    "Modern pentathlon event",
    "Blade of Grasse",
    "Dueling sword",
    "Flexible blade",
    "Sporting sword",
    "Olympics blade",
    "Guarded weapon",
    "Blunted blade",
    "Sport with lunges",
    "Fencing sword",
    "It may be waved at the Olympics",
    "Sport with jabs",
    "Abbe de l'___, pioneer in sign language",
    "Three-sided blade",
    "Sport with masks",
    "Foil alternative",
    "Blunted sword",
    "Pentathlon need",
    "Olympics blade",
    "Sport with arm-waving",
    "Modern pentathlon event",
    "Sporting sword",
    "Sporting sword",
    "Sport with masks",
    "Three-sided blade",
    "A masked competitor waves it",
    "Fencer's blade",
    "Sporting sword",
    "Modern pentathlon event",
    "Abbe de l'___, sign language pioneer",
    "Safe sword",
    "Thrusted thing",
    "Fencer's weapon",
    "Its point is guarded",
    "Olympics blade",
    "Sporting blade",
    "Pentathlon weapon",
    "Sport with a mask",
    "Fencing piece",
    "Sport with masks",
    "Duel tool",
    "Olympics event",
    "Fencing blade",
    "Fencing blade",
    "Abbe de l'___ (pioneer in sign language)",
    "Fencing piece",
    "Sporting blade",
    "Sporting blade",
    "One-on-one sport",
    "Three-sided sword",
    "\"One-touch\" sport",
    "It involves a wave of the hand",
    "Sport in which players wear masks",
    "Sporting blade",
    "One-on-one sport",
    "Blunted blade",
    "Sporting sword",
    "Fencing blade",
    "Olympics sticker",
    "Its playing area is 2 x 14 meters",
    "Fencing need",
    "Fencing weapon",
    "Fencing rapier",
    "Modern pentathlete's need",
    "Olympics event",
    "There's little point to it",
    "Blunted blade",
    "Fencing sword",
    "One-on-one sport",
    "Light blade",
    "It may be thrust",
    "See 45-Across",
    "Cousin of a foil",
    "It has a fluted blade",
    "Blunted blade",
    "Foil alternative",
    "Sporting blade",
    "Sporting blade",
    "One-on-one sport",
    "It lacks a cutting edge",
    "Fencing need",
    "It's almost pointless",
    "It has a blunt end",
    "Sport played on a 6-foot by 40-foot strip",
    "Sporting blade",
    "\"En garde\" weapon",
    "It's almost pointless",
    "Foil relative",
    "Alternative to a singlestick",
    "It's pointless",
    "Touching activity",
    "Sporting weapon",
    "Players wear masks for this",
    "One-on-one sport",
    "It has a guarded tip",
    "Blunted weapon",
    "Sign language pioneer Abbe de l'___",
    "Pointless event?",
    "Guarded rapier",
    "Fencing sword",
    "One-on-one sport",
    "Duel tool",
    "Sporting sword",
    "Modern pentathlon need",
    "Parrier's equipment",
    "Sporting blade",
    "One-on-one sport",
    "First weapon to be electrified",
    "Fencer's blade",
    "Sportsman's blade",
    "Triple-edged sword",
    "Foil's heavier cousin",
    "Blunted blade",
    "Three-sided sword",
    "Sport in which players don't want to get tips",
    "Olympics equipment",
    "Sporting rapier",
    "Swordplay",
    "One-on-one sport",
    "Sport played to three points",
    "Fencing blade",
    "Item of Olympic equipment",
    "Fencer's weapon",
    "Fencing weapon",
    "Fencing blade",
    "Olympics event since 1900",
    "Three-sided sword",
    "Modern pentathlon event",
    "Foil's kin",
    "Sport played to three points",
    "Sporting sword",
    "Foil relative",
    "Dueler's weapon",
    "Fencer's sword",
    "Part of a three-weapon competition",
    "35-inch blade",
    "Fencing rapier",
    "Olympic sword",
    "Olympic sport",
    "Olympic event",
    "Fencer's blade",
    "Abbe de l'___ (sign language pioneer)",
    "Pentathlon equipment",
    "Duel tool",
    "Foil relative",
    "Dueling blade",
    "Fencing tool",
    "Sporting blade",
    "Fencer's blade",
    "Rapier",
    "It's often thrust upon someone",
    "Rapier",
    "Dueling method",
    "One-on-one sport",
    "Sword with a guard",
    "Parrier's equipment",
    "Sport in which players wear metal jackets",
    "Sword of sport"
  ],
  "acta": [
    "Official proceedings",
    "Court proceedings",
    "Official proceedings",
    "Court proceedings",
    "Parliamentary proceedings, e.g.",
    "Official records",
    "Official proceedings",
    "Court records",
    "___ Diurna (daily Roman notices)",
    "Court proceedings",
    "Court records",
    "___ fool (be silly)",
    "Official proceedings",
    "Exitus ___ probat (the end justifies the means)",
    "Court proceedings",
    "Courthouse records",
    "They're found within minutes",
    "Court records",
    "___ Senatus (old Roman records)",
    "Records",
    "Parliamentary doings, e.g.",
    "Court minutes",
    "Proceedings",
    "Recorded events",
    "Recorded proceedings",
    "Proceedings",
    "Recorded proceedings",
    "Court records",
    "Court doings",
    "Court records",
    "Records",
    "Official records",
    "Minutes",
    "Proceedings",
    "Official proceedings",
    "___ Sanctorum",
    "Official proceedings",
    "Official records",
    "Official proceedings",
    "Ancient Roman decrees",
    "Recorded proceedings",
    "\"___ Sanctorum\"",
    "Official records",
    "___ part (play on stage)",
    "Proceedings",
    "Proceedings",
    "Official records"
  ],
  "itry": [
    "Modest reply to a compliment",
    "Response of modesty",
    "Humblebragger's words",
    "Humble response to a compliment",
    "\"Just doin' my job\"",
    "Humble response to a compliment",
    "Modest response to a compliment",
    "Modest reply to a compliment",
    "Reply that's a bit of a humblebrag",
    "Humble response to a compliment",
    "Humble response",
    "Modest response to a compliment",
    "Humble reply to a compliment",
    "Modest response to praise",
    "Humble response to praise",
    "Modest response to kudos",
    "Humble response to a compliment",
    "Modest response to praise",
    "Humble reply to praise",
    "Humble response to praise",
    "Humble reply"
  ],
  "evac": [
    "Removal from danger, informally",
    "Emergency operation, for short",
    "Emergency procedure, briefly",
    "Plan to leave shortly?",
    "Emergency rescue, briefly",
    "Rescue mission, briefly",
    "FEMA request, briefly",
    "Removal from harm's way, for short",
    "Rescue mission, briefly",
    "\"M*A*S*H\" maneuver, for short",
    "Emergency exodus operation, briefly",
    "Rescue op",
    "Removal from harm's way, for short",
    "Fire drill activity, briefly",
    "Emergency removal of people, for short",
    "FEMA recommendation, briefly",
    "Some Red Cross work: Abbr."
  ],
  "tory": [
    "One leaning to the right",
    "Boris Johnson, for one",
    "Theresa May, for one",
    "Conservative",
    "Conservative",
    "Labourite's opponent, in British politics",
    "Major ally?",
    "Anti-Revolutionary of 1776",
    "Churchill, e.g.",
    "Churchill, e.g.",
    "Conservative",
    "David Cameron, e.g.",
    "British Conservative",
    "Winston Churchill, e.g.",
    "Revolution-era loyalist",
    "Winston Churchill, e.g.",
    "British Conservative",
    "Canadian Conservative",
    "Major or Thatcher, e.g.",
    "Disraeli, e.g.",
    "Conservative Brit",
    "Naturalist Roger ___ Peterson",
    "Whig's rival",
    "Conservative",
    "Whig's rival",
    "Revolution opposer",
    "Winston Churchill, politically",
    "John Major, e.g.",
    "Winston Churchill, e.g.",
    "Sir Robert Peel, notably",
    "Loyalist",
    "Major, for example",
    "Conservative",
    "English conservative",
    "Major affiliation"
  ],
  "bops": [
    "Conks",
    "Noggin knocks",
    "Conks",
    "Noggin knocks",
    "Moves energetically",
    "Conks on the head",
    "Runs (around), informally",
    "Knocks on the noggin",
    "Hits with one's fist",
    "Bits of Three Stooges violence",
    "Conks on the head",
    "Dances to jazz",
    "Hard knocks",
    "Hard knocks",
    "Conks",
    "Hits, in slang",
    "Conks"
  ],
  "merc": [
    "Chicago exchange, in brief",
    "Chi-Town exchange",
    "Bygone Ford make, informally",
    "Bygone Ford make, briefly",
    "Chicago exchange, briefly",
    "Chicago exchange, in brief",
    "Former Ford make, informally",
    "Chicago market, with \"the\"",
    "Bobcat or Cougar, for short",
    "Bygone Ford car, informally",
    "Former Ford offering, for short",
    "Chicago exchange, informally, with \"the\"",
    "Grand Marquis, e.g., briefly",
    "Chicago exchange, for short",
    "Chicago exchange, for short",
    "Ford auto, briefly",
    "Grand Marquis, e.g., for short",
    "Gun for hire",
    "Chicago futures exchange, for short",
    "Ford product, briefly",
    "Sable or Montego, for short",
    "Montego or Grand Marquis, for short",
    "Ford product, informally",
    "Thermometer fill: Abbr.",
    "Ford offering, for short",
    "Chicago exchange, for short",
    "Ford product, briefly",
    "Ford product, briefly",
    "Grand Marquis, for short",
    "Cougar, briefly",
    "Ford Motor product, informally",
    "Certain Ford, briefly",
    "Ford make, for short",
    "Certain Ford, for short",
    "Certain exchange, for short, with \"the\"",
    "First planet: Abbr.",
    "Hired soldier, in slang",
    "One of the exchanges, for short",
    "Hired soldier, in slang"
  ],
  "peak": [
    "Like times that are the most expensive",
    "Zenith",
    "Climax",
    "Word after mountain or before season",
    "Pinnacle",
    "Maximum",
    "Where people are drawn to scale?",
    "Top",
    "Hit a high point",
    "Everest, for one",
    "Mountaineer's goal",
    "Mountain climber's conquest",
    "Trough's opposite",
    "Prime",
    "Busiest",
    "Reach a high",
    "Top",
    "Like some airline travel periods",
    "Reach a high",
    "Point on a line graph",
    "Busiest",
    "Scaler's goal",
    "Busiest",
    "Tiptop",
    "Pinnacle",
    "Busiest",
    "Top out",
    "Point on a graph",
    "Any Alp",
    "Mountain",
    "Mountain",
    "Tiptop",
    "High spot"
  ],
  "bold": [
    "\"Be ___\" (motto for Wikipedia contributors)",
    "Audacious",
    "Gutsy",
    "Daring",
    "Fearless",
    "*Brash",
    "Font option",
    "Willing to take risks",
    "Yellow's opposite",
    "Forward",
    "Typeface option",
    "Not timid",
    "Stressed, in a way",
    "Fearless",
    "Barefaced",
    "Forward",
    "Forward",
    "Barefaced",
    "Not reticent",
    "Not shrinking",
    "Gutsy"
  ],
  "avia": [
    "Running shoe brand",
    "New Balance competitor",
    "Adidas competitor",
    "Big name in running shoes",
    "Reebok competitor",
    "Nike rival",
    "Adidas competitor",
    "Big name in athletic footwear",
    "Saucony competitor",
    "Running shoe brand",
    "Running shoe brand",
    "Asics competitor",
    "Reebok rival",
    "Puma alternative",
    "Activewear brand",
    "Sportswear brand",
    "Nike alternative",
    "New Balance competitor",
    "Reebok competitor",
    "Big name in sports shoes",
    "Reebok competitor",
    "Athletic footwear brand since 1979",
    "Big name in running shoes",
    "Reebok competitor",
    "New Balance competitor",
    "Athletic shoe brand",
    "Nike competitor",
    "Sports shoe brand",
    "Sports shoe brand",
    "Nike competitor",
    "Reebok alternative",
    "Adidas alternative",
    "Athletic shoe brand",
    "Big name in athletic footwear",
    "Puma rival",
    "Reebok rival",
    "Adidas alternative",
    "New Balance competitor",
    "Converse competitor",
    "Adidas alternative",
    "Converse alternative",
    "Nike competitor",
    "Maker of running shoes",
    "Nike competitor",
    "Reebok rival",
    "Nike competitor",
    "Keds competitor",
    "Sneakers brand",
    "New Balance competitor",
    "Converse competitor",
    "Former sneaker brand",
    "Athletic shoe company"
  ],
  "mall": [
    "Destination for a shopaholic",
    "Shopping center",
    "Shopping ___",
    "Sheltered walk",
    "The world's largest one is in Chengdu, China (covering 18 million square feet)",
    "___ of America",
    "Development that might compete with a downtown",
    "Segway cop's workplace, maybe",
    "Seventh word ...",
    "D.C.'s National ___",
    "D.C. tourist destination",
    "Site of Santa sightings",
    "Washington attraction",
    "Promenade",
    "Outdoor promenade",
    "___ rat",
    "Promenade",
    "Chambers of commerce?",
    "Shopaholic's mecca",
    "Shopping mecca",
    "\"Scenes From a ___\" (Woody Allen film)",
    "Suburban gathering place",
    "Washington locale, with \"the\"",
    "Strip ___",
    "Shopping ___",
    "Washington locale, with \"the\"",
    "Collection of outlets?",
    "Shopaholic's heaven",
    "Place for a spending spree",
    "Popular teen hangout",
    "With 12-Down, a modern idler",
    "Spree spot",
    "Retailer's place",
    "Teen hangout",
    "Shopaholic's hangout",
    "Shopaholic's hangout",
    "Popular teen hangout",
    "Sears locale",
    "Shoppers' haven",
    "Symbol of suburbia",
    "Shopping center",
    "Teen hangout"
  ],
  "dawg": [
    "Friend, in slang",
    "Georgia athlete, familiarly",
    "\"Hi and Lois\" pooch",
    "Hip-hop pal",
    "Homey",
    "Homey",
    "Homey",
    "Bud, slangily",
    "Terrytoons' Deputy ___",
    "Homey",
    "Deputy ___ (Terrytoons character)",
    "Homey",
    "Cartoondom's Deputy ___",
    "Deputy ___ (toon)",
    "\"Wassup, ___?\"",
    "Family pet in \"Hi and Lois\"",
    "Flagston family pet",
    "Hip-hop friend",
    "TV toon Deputy ___",
    "Deputy ___ (cartoon canine)",
    "\"Deputy ___\" (TV toon)",
    "\"Hi and Lois\" family pet",
    "Animated TV deputy",
    "Cartoon deputy",
    "Hi and Lois's pet",
    "Terrytoons' Deputy ___"
  ],
  "cagy": [
    "Evasive",
    "Hard to fool",
    "Showing deviousness",
    "Cunning",
    "Sly",
    "Sly",
    "Shrewd",
    "Shrewd",
    "Shrewd",
    "Shrewd",
    "Sly",
    "Hard to corner"
  ],
  "rona": [
    "Writer Jaffe",
    "Novelist Jaffe",
    "Author Jaffe",
    "Woman's name that becomes another woman's name if you switch the first and third letters",
    "Barrett of gossip",
    "Barrett of old gossip",
    "Jaffe who wrote \"Mazes and Monsters\"",
    "Writer Jaffe",
    "Longtime first name in gossip",
    "Author Jaffe",
    "Writer Jaffe",
    "First name in gossip",
    "Literary Jaffe",
    "Novelist Jaffe",
    "First name in gossip",
    "First name in gossip",
    "Gossipy Barrett",
    "Author Jaffe",
    "Barrett of gossip",
    "Gossipy Barrett",
    "First name in gossip",
    "Author Jaffe",
    "Novelist Jaffe",
    "Novelist Jaffe",
    "\"Five Women\" author Jaffe",
    "Author Jaffe",
    "Author Jaffe",
    "Columnist Barrett",
    "Jaffe or Barrett",
    "Barrett or Jaffe",
    "First name in Hollywood gossip",
    "Jaffe who wrote \"Five Women\"",
    "First name in gossip",
    "Novelist Jaffe",
    "Barrett of gossip",
    "Author Jaffe",
    "Novelist Jaffe",
    "Gossip queen Barrett",
    "Gossipy Barrett",
    "Gossipy Barrett",
    "Gossipy Barrett",
    "Writer Jaffe",
    "First name in gossip",
    "Jaffe who wrote \"The Best of Everything\"",
    "First name in gossip",
    "Novelist Jaffe",
    "Author Jaffe",
    "Novelist Jaffe",
    "Novelist Jaffe",
    "First name in Hollywood dirt",
    "Novelist Jaffe",
    "First name in Hollywood dirt-dishing",
    "Author Jaffe",
    "Author Jaffe",
    "Gossipy Barrett",
    "Writer Jaffe",
    "Novelist Jaffe",
    "Gossipy Barrett",
    "Gossipy Barrett",
    "Gossipy Barrett",
    "First name in gossip",
    "Writer Jaffe",
    "Barrett known for dishing",
    "Novelist Jaffe",
    "Barrett known for her dish",
    "Gossipy Barrett",
    "Gossipy Barrett",
    "\"Class Reunion\" author Jaffe",
    "Columnist Barrett",
    "Writer Jaffe",
    "Gossipy Barrett",
    "Author Jaffe",
    "Hollywood insider Barrett",
    "Gossiper Barrett",
    "Writer Jaffe",
    "Gossipy Barrett",
    "First name in gossip",
    "Novelist Jaffe",
    "She?Æs ?ôa Hedda?ö of her time",
    "Novelist Jaffe",
    "Gossip queen Barrett",
    "Author Jaffe",
    "Novelist Jaffe",
    "Gossipy Barrett",
    "Talkative Barrett",
    "Barrett of gossip"
  ],
  "rove": [
    "Go here and there",
    "Go here and there",
    "Wander",
    "Meander",
    "Ramble",
    "Wander about",
    "Wander",
    "Not go in a straight line",
    "Knock about",
    "Wander about",
    "Wander",
    "Range",
    "Go here and there",
    "Wander",
    "Republican strategist Karl",
    "Go here, there and everywhere",
    "Wander about",
    "Karl who advised Bush 43",
    "Go all over",
    "Be a drifter",
    "Wander",
    "Travel far and wide",
    "Not stay put",
    "Politico with the memoir \"Courage and Consequence\"",
    "Cover lots of ground",
    "Go all over",
    "Drift",
    "Wander",
    "Go here and there",
    "Travel the country",
    "Go here and there",
    "Gad about",
    "Wander about",
    "Gad about",
    "Wander",
    "Bush adviser Karl",
    "Wander",
    "Be itinerant",
    "Drift",
    "Wander",
    "Go all over",
    "Be peripatetic",
    "Cover ground?",
    "Range",
    "Gad about",
    "Gad about",
    "Travel around",
    "Amble",
    "Tramp",
    "Gad about",
    "Gad about",
    "Traipse",
    "Meander",
    "Wander",
    "Move about",
    "Perambulate"
  ],
  "raft": [
    "Primitive boat",
    "Vessel in which to shoot the rapids",
    "Inflatable transport",
    "Large quantity",
    "Whole host",
    "Vessel that rhymes with a nautical direction",
    "Whole lot",
    "Whole slew",
    "Log craft",
    "Transport for Huck Finn",
    "Means of going down a 36-Down",
    "Huckleberry Finn carrier",
    "See 43-Across",
    "Captain's logs?",
    "Flat floater",
    "Marooned sailor's means of escape",
    "Whole bunch",
    "Lot",
    "Means of a castaway's escape, maybe",
    "Large number",
    "Castaway's construction",
    "Whitewater craft",
    "Shooter through whitewater rapids",
    "Castaway's makeshift vessel",
    "Huck Finn's transport",
    "Craft in which to go down a river, say",
    "Finnish transport?",
    "Camper's rental",
    "Vessel in \"Cast Away\"",
    "Whole lot",
    "Whole slew",
    "Slew",
    "Marooned sailor's construction",
    "Slew",
    "Huck Finn's conveyance",
    "Host",
    "Huck Finn's transport",
    "Inter-island conveyance",
    "Makeshift river conveyance",
    "Vessel that's poled",
    "Flat floater",
    "Slew",
    "Item that may be blown up",
    "Toast, in a diner",
    "Flat floater",
    "Slew",
    "Tom Hanks's escape in \"Cast Away\"",
    "Lot",
    "Air-filled item, maybe",
    "Whole slew",
    "Conveyance for Huckleberry Finn",
    "Slew",
    "Inflatable item, maybe",
    "Large number",
    "Transport for Huck Finn",
    "Rapids transit?",
    "Huck Finn's conveyance",
    "Great quantity",
    "White-water carrier",
    "Emergency vehicle",
    "Castaway's transportation",
    "Toast to the chef",
    "Piece of toast, in dinerese",
    "Lifesaver, maybe",
    "Huck Finn's transport",
    "Huck Finn's craft",
    "\"Kon-Tiki\" craft",
    "Toast, in diner slang",
    "Large number"
  ],
  "moma": [
    "Place to see Dalí, Ernst and Kahlo, informally",
    "Place to see a Kandinsky or Calder",
    "Place to see Warhol's \"Campbell's Soup Cans\"",
    "Attraction in midtown N.Y.C.",
    "Home to Matisse, Mondrian and Monet, informally",
    "N.Y.C.'s home to Matisse's \"The Dance\"",
    "Home of Dali's \"The Persistence of Memory,\" informally",
    "N.Y.C. attraction",
    "Home of van Gogh's \"The Starry Night,\" informally",
    "N.Y.C. cultural center",
    "Midtown Manhattan cultural attraction, for short",
    "Home of \"Christina's World,\" familiarly",
    "Home to Mondrian's \"Broadway Boogie Woogie\"",
    "Where to see a van Gogh in N.Y.C.",
    "N.Y.C. cultural center",
    "N.Y.C. home of Magrittes and Matisses",
    "Home to \"The Persistence of Memory,\" informally",
    "N.Y.C. institution with works of Warhol and Dali",
    "N.Y.C. home to works of Picasso",
    "Where Matisses hang in N.Y.C.",
    "N.Y.C. tourist attraction",
    "Home to Andrew Wyeth's \"Christina's World,\" informally",
    "N.Y.C. home of van Gogh",
    "Home to van Gogh's \"The Starry Night,\" in brief",
    "Sculpture garden setting in N.Y.C.",
    "Home of Mondrian's \"Broadway Boogie Woogie\"",
    "Home of \"Christina's World,\" for short",
    "Guggenheim alternative in N.Y.C.",
    "N.Y.C. attraction",
    "N.Y.C. cultural institution",
    "N.Y.C. cultural center",
    "N.Y.C. cultural center",
    "Where \"Guernica\" was hung during W.W. II",
    "Home of Cezanne's \"The Bather,\" in brief",
    "N.Y.C. tourist attraction",
    "N.Y.C. cultural attraction",
    "Where to see some of 32-Down's work",
    "N.Y.C. gallery",
    "Place to see 20th-century paintings in N.Y.",
    "N.Y.C. cultural center",
    "N.Y.C. cultural center",
    "N.Y.C. home of \"The Starry Night\"",
    "Site of a 1929 exhibition by 107-Down",
    "Cultural center estab. in 1929",
    "Where Picassos hang in N.Y.C.",
    "N.Y.C. cultural center",
    "N.Y.C. gallery",
    "N.Y.C. gallery",
    "N.Y.C. cultural institution",
    "Site for a Cezanne: Abbr.",
    "N.Y. institution on 53d Street"
  ],
  "slum": [
    "Relative of a shantytown",
    "Dickensian setting",
    "Neighborhood neglected by local government",
    "Subject of \"How the Other Half Lives\"",
    "Urban blight",
    "Candidate for urban renewal",
    "Dickensian setting",
    "Gentrification target, maybe",
    "Hardly a high-rent district",
    "Jacob Riis subject",
    "Trenchtown, for one",
    "Blighted urban area",
    "Ratty area",
    "Neglected neighborhood",
    "Urban renewal target",
    "Squalid digs",
    "Run-down area",
    "Urban blight",
    "Squalid neighborhood",
    "Urban renewal target",
    "Tenement locale",
    "Take the low road, in a way",
    "Pigsty",
    "Associate with riffraff",
    "Urban blight",
    "Kind of lord",
    "Place ruled by a lord?",
    "Rundown area",
    "It needs renewal",
    "Poor part of town"
  ],
  "badu": [
    "Singer Erykah",
    "R&B singer Erykah",
    "Erykah of R&B",
    "Grammy winner Erykah ___",
    "\"On&On\" singer Erykah",
    "\"On&On\" singer Erykah ___"
  ],
  "hole": [
    "Swiss cheese feature",
    "Weakness in an argument",
    "Course division",
    "One of 42 on a Connect Four board",
    "Metaphor for indebtedness",
    "Shovel's creation",
    "Evidence left by a moth",
    "Drain opening",
    "Ozone layer problem",
    "Darn it!",
    "Problem with a sock",
    "Woodchuck's escape route",
    "Logical flaw",
    "Golfer's target",
    "Ozone problem",
    "Doughnut feature",
    "Golf target",
    "One of 18 on a golf course",
    "Deficit, informally",
    "Part of a round",
    "Running back's target",
    "Tunnel, e.g.",
    "One of 18 on a golf course",
    "Darned thing",
    "Place for an ace",
    "Puncture",
    "Course component",
    "Argument weakness",
    "One of 18 on a golf course",
    "Golfer's target",
    "Puncture",
    "Boring result",
    "Darn it",
    "Financial predicament",
    "Pocket problem",
    "Negative space",
    "Doughnut's center",
    "Golf unit",
    "Place for a numbered flag",
    "Pail problem",
    "Ace place?",
    "Place for an ace",
    "Course division",
    "Financial predicament",
    "Driver's destination?",
    "Links unit",
    "Green feature",
    "Can you dig it?",
    "Burrow",
    "Space",
    "Eyelet",
    "Mender's target",
    "Pocket problem",
    "Green target",
    "Links target",
    "Flag location",
    "Aperture",
    "Problem in the defense lines",
    "19th ___ (golf clubhouse)",
    "Doughnut's middle",
    "Bind, so to speak",
    "Tunnel",
    "Mending site",
    "Reason for darning",
    "Swimming ___",
    "Golfer's object",
    "Mender's target",
    "Bagel's middle",
    "Tunnel, e.g.",
    "Pit",
    "Green target",
    "Patch site"
  ],
  "grog": [
    "Sailor's libation",
    "Watered-down rum",
    "Swabbie's liquor allotment, once",
    "Sailor's quaff",
    "Buccaneer's quaff",
    "Sea spirits",
    "Tar liquid",
    "Pirate's quaff",
    "Salty drink?",
    "Limey's drink",
    "Privateer's potation",
    "Seaman's swig",
    "Matey's libation",
    "Old Navy libation",
    "Crew quaff",
    "Royal Navy drink of old",
    "Sea dog's libation",
    "Part of an old Royal Navy ration",
    "Rum drink",
    "Sailor's drink",
    "Sailor's rum drink",
    "Ocean spirits",
    "Drink on board",
    "Old-time sailor's drink",
    "Limey's quaff",
    "Rum drink",
    "Hot drink",
    "Seaman's quaff",
    "Tavern order",
    "Old-fashioned sailors' drink",
    "Drink on the drink",
    "Tar's toddy",
    "Corsair's quaff"
  ],
  "pear": [
    "D'Anjou, for one",
    "Liqueur flavor",
    "Lute shape",
    "Orchard fruit",
    "___ drop (British sweet treat)",
    "Tree for a partridge, in a Christmas song",
    "Prickly ___ (variety of cactus)",
    "Big-bottomed fruit",
    "Fruit with an obovate shape",
    "Bottom-heavy fruit",
    "Fruit that flavors liqueurs",
    "Flavor of some eau de vie",
    "Fruit in a holiday gift box",
    "Oviform : egg :: pyriform : ___",
    "Fruit in a fruit basket",
    "It may be poached",
    "Fruit often seen in still lifes",
    "Brandy fruit",
    "Partridge's tree, in a Christmas song",
    "Fruit that's sometimes poached",
    "Word with prickly or alligator",
    "Juicy fruit",
    "Jelly Belly flavor",
    "Compote ingredient",
    "Fruit in a still-life painting",
    "Schnapps choice",
    "Partridge's preferred tree",
    "Distinctively shaped fruit",
    "Schnapps flavor",
    "Nectar flavor",
    "Dentiform : tooth :: pyriform : ___",
    "Orchard product",
    "Kind of brandy",
    "Dentiform : tooth :: pyriform : ___",
    "Bartlett, e.g.",
    "Tree in bloom in a Van Gogh painting",
    "Brandy fruit",
    "Shape of a mandolin's body",
    "Peelable fruit",
    "Kind of brandy",
    "Tree in a Christmas song",
    "Popular brandy flavor",
    "Nectar source",
    "Kind of nectar",
    "Tree in a Christmas carol",
    "Still-life item",
    "Brandy flavor",
    "Alligator ___",
    "Kind of orchard",
    "Anjou, for one",
    "Lute shape",
    "Orchard item",
    "Nectar source",
    "Gallbladder's shape",
    "Nectar flavor",
    "Fall fruit",
    "Compote fruit",
    "Fruit cooked in cream and sugar",
    "Anjou or Seckel",
    "Nectar flavor",
    "Prickly ___",
    "Fruit basket selection",
    "Fruit baked in wine",
    "Anjou, e.g.",
    "Oviform : egg :: pyriform : ___",
    "Fruit cocktail fruit",
    "Liqueur flavor",
    "Compote fruit",
    "Syrup flavor",
    "Prickly ___",
    "Brandy fruit",
    "Bartlett, for one",
    "Bergamot, e.g.",
    "Nectar flavor",
    "Egg : oval :: ___ : pyriform",
    "Seckel or Anjou",
    "Still-life subject",
    "Earth's shape"
  ],
  "dole": [
    "Portion (out)",
    "First and only president of the Republic of Hawaii",
    "Ration",
    "Food company with a sunburst in its logo",
    "Loser to Clinton in 1996",
    "Agricultural giant with the mascot Bobby Banana",
    "Bob who lost to Bill",
    "Handouts, with \"the\"",
    "Hand (out)",
    "Its plantation features the Pineapple Garden Maze",
    "Giant in the fruit and vegetable market",
    "Fruit giant",
    "Company whose mascot is Bobby Banana",
    "McCain : 2008 :: ___ : 1996",
    "World's largest fruit company",
    "James who was known as the Pineapple King",
    "Charity allotment",
    "Relief",
    "Founder of an Oahu plantation",
    "Republican candidate between Bush and Bush",
    "Clinton's 1996 opponent",
    "Loser to Clinton",
    "Hand (out)",
    "Apportionment",
    "Labor secretary under the first George Bush",
    "Hand (out)",
    "Big name in pineapples",
    "Hand (out)",
    "Ration (out)",
    "Senate majority leader, 1985-87",
    "Charity",
    "Welfare payments, e.g.",
    "Bob who lost to Bill Clinton",
    "Food company with a sun in its logo",
    "Charity",
    "North Carolina senator",
    "1996 Republican standard-bearer",
    "Big name in pineapples",
    "Welfare, with \"the\"",
    "1996 runner-up",
    "Handout",
    "Charity distribution",
    "Loser in 1996",
    "1996 candidate Bob",
    "Lott's predecessor",
    "1996 also-ran",
    "Big name in fruit",
    "Share",
    "Allotment",
    "Politico Elizabeth",
    "Hand (out)",
    "Elizabeth of the Red Cross",
    "1996 Clinton challenger",
    "Former Senate leader",
    "Handout",
    "Welfare, with \"the\"",
    "Big name in Hawaii",
    "Relief",
    "Senator from Kansas",
    "___Food Company",
    "Handouts",
    "Big name in the pineapple biz",
    "Senate V.I.P.",
    "Ration",
    "Charity",
    "Major employer in Hawaii"
  ],
  "anos": [
    "12-Down en español",
    "Years, in Spain",
    "Years abroad",
    "Calendar spans with tildes",
    "Years, in Madrid",
    "\"Cien ___ de Soledad\" (Gabriel Garcia Marquez novel)",
    "A long time, in Mexico",
    "They always come with mayo",
    "Years on the Yucatan",
    "Parts of decadas",
    "Years abroad",
    "A long time in Barcelona",
    "Gabriel Garcia Marquez's \"Cien ___ de Soledad\"",
    "Spanish years",
    "Mayo containers?",
    "Calendario spans",
    "Years abroad",
    "Calendario units",
    "Years in Mexico",
    "Decada divisions",
    "Yucatan years",
    "Periodos de 52 semanas",
    "Parts of decadas",
    "Parts of una decada",
    "Years, in the Yucatan",
    "2007 y 2008, por ejemplo",
    "A long time in Lisbon",
    "Calendario units",
    "Calendario units",
    "Decada divisions",
    "Years in Isabella's reign",
    "Years in Spain",
    "Periods containing 2-Downs",
    "Years abroad",
    "Long times in Lima",
    "Years in Havana",
    "A long time in Mexico",
    "Una decada has 10",
    "Years on the Yaqui",
    "Years in Spain",
    "Yucatan years",
    "Enero-to-diciembre periods",
    "Years in Toledo"
  ],
  "como": [
    "Italy's Lake ___",
    "Spanish for \"how\"",
    "Lake in \"Casino Royale\"",
    "Italy's Lake ___",
    "Italian city where Pliny the Elder and Younger were born",
    "\"___ te llamas?\"",
    "Spanish interrogative",
    "\"Till the End of Time\" singer",
    "Perry with the 1956 #1 hit \"Hot Diggity\"",
    "\"___ te llamas?\" (Spanish 101 question)",
    "\"It's Impossible\" crooner",
    "\"It's Impossible\" singer",
    "Italian silk center",
    "\"Juke Box Baby\" singer",
    "Lombardy's Lake ___",
    "Lake north of Milan",
    "Italian resort lake",
    "\"___ esta usted?\"",
    "Singer with the first gold record single",
    "\"Juke Box Baby\" singer, 1956",
    "Italian lake",
    "\"Hot Diggity\" singer",
    "\"Catch a Falling Star\" singer, 1958",
    "\"Hot Diggity\" singer",
    "Old \"Supper Club\" radio host",
    "Lake at the foothills of the Alps",
    "\"Juke Box Baby\" singer, 1956",
    "Emmy-winning show host, 1955-56",
    "Crooner Perry",
    "Lake in northern Italy",
    "TV host, 1948-63"
  ],
  "tera": [
    "Prefix with byte",
    "Precursor to a big flop?",
    "Computing prefix",
    "Prefix with watt",
    "Prefix with watt",
    "Numerical prefix from the Greek for \"monster\"",
    "Prefix with byte",
    "Giga- x 1,000",
    "Prefix with byte",
    "Beginning to flop?",
    "Prefix with watt",
    "Mega- times a million",
    "Prefix with byte",
    "Lead-in to flops",
    "Prefix with byte",
    "Prefix with flop",
    "Trillion: Prefix",
    "Prefix with 3-Down",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Computer prefix meaning 2 to the 40th power",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Prefix with flop",
    "Trillion: Prefix",
    "Prefix with hertz",
    "Prefix with byte",
    "Trillion: Prefix",
    "Prefix with hertz",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Trillion: Prefix",
    "Lead-in for flops or hertz"
  ],
  "isnt": [
    "Stevie Wonder's \"___ She Lovely\"",
    "Lacks existence",
    "\"Money ___ everything\"",
    "\"___ it true ...?\"",
    "Fails to be",
    "Fails to be",
    "\"___ She Lovely\" (Stevie Wonder song)",
    "\"If someone ___ careful ...\"",
    "\"And who ___?\"",
    "\"Who ___?\"",
    "\"... but who ___?\"",
    "\"___ that something?!\"",
    "\"___ It Time\" (1977 hit for the Babys)",
    "That right introduction?",
    "\"___ It Romantic?\"",
    "\"Freedom ___ free\"",
    "Ain't the way it should be?",
    "Fails to be",
    "\"___ it ironic?\"",
    "\"And who ___?\"",
    "\"That ___ funny\"",
    "\"___ that special?\"",
    "\"That ___ you\"",
    "\"___ it, though?\"",
    "\"___ it ironic?\" (1996 song lyric)",
    "\"Freedom ___ free\"",
    "\"___ it ironic?\"",
    "\"Who ___?\"",
    "Ain't fixed?",
    "\"___ She Lovely\"",
    "\"___ it rich?\"",
    "\"... and who ___?\"",
    "\"___ that sweet?\"",
    "\"___ it rich?\" (Sondheim lyric)",
    "Ain't fixed?",
    "Ain't right?",
    "No longer exists",
    "\"___ it?\"",
    "\"Tepper ___ Going Out\" (Calvin Trillin novel)",
    "Ain't correct?",
    "Fails to be",
    "Word of denial",
    "\"Freedom ___ free\"",
    "\"Who ___?\"",
    "\"This ___ what I expected\"",
    "\"Freedom ___ free\"",
    "\"___ that cute?!\"",
    "\"And who ___?\"",
    "Exists no more",
    "George Harrison's \"___ It a Pity\"",
    "\"Coffee ___ my cup of tea\": Samuel Goldwyn",
    "Rodgers and Hart's \"___ It Romantic?\"",
    "\"___ It Time\" (1977 hit)",
    "\"Money ___ everything!\"",
    "George Harrison's \"___ It a Pity\"",
    "\"Money ___ everything\"",
    "Ain't as it should be?",
    "Abba's \"Love ___ Easy\"",
    "\"___ It Grand, Boys\" (Irish standard)",
    "\"___ She Lovely\"",
    "George Harrison's \"___ It a Pity\"",
    "Lives no more",
    "Fred Astaire's \"___ This a Lovely Day\"",
    "\"___ It Romantic?\"",
    "\"___ it a pity?\"",
    "Fails to be",
    "Fails to be",
    "Stevie Wonder's \"___ She Lovely\"",
    "\"___ that special?!\"",
    "Proves otherwise, briefly",
    "\"___ It Time\" (1977 hit)",
    "\"Money ___ everything\"",
    "\"And who ___?\"",
    "\"Who ___?\"",
    "First word in \"Send in the Clowns\"",
    "\"___ it a shame\"",
    "\"___ that special?!\"",
    "\"___ It a Pity\" (1970 song)",
    "Ain't right?",
    "Rodgers and Hammerstein's \"___ It Kinda Fun?\"",
    "\"___ She Lovely\" (Stevie Wonder song)",
    "That special introduction?",
    "\"It ___ so\"",
    "\"Winning ___ everything\"",
    "Lacks life",
    "Fails to be",
    "\"It ___ Fair\" (Sammy Kaye hit)",
    "That special leader?",
    "\"___ it rich?\"",
    "Ain't correct?",
    "\"___ It Romantic?\"",
    "\"___ She Lovely\"",
    "\"___ It Romantic?\"",
    "\"This ___ my day!\"",
    "Ain't right?",
    "Has no life",
    "\"___ She Lovely?\"",
    "Ain't correct?",
    "\"This ___ fair!\"",
    "The Platters' \"It ___ Right\"",
    "\"___ It Romantic?\"",
    "\"Well, ___ that special!\"",
    "Doesn't exist",
    "\"___ It Romantic?\"",
    "\"___ It Romantic?\"",
    "George Harrison's \"___ It a Pity\"",
    "\"___ It Kinda Fun\" (1945 song)",
    "\"Money ___ everything!\"",
    "First word of \"Send in the Clowns\"",
    "\"___ It Romantic?\"",
    "\"___Life Strange\" (1972 hit)",
    "\"It___Fair\" (1950 hit)",
    "\"___ It a Pity\"",
    "\"___ It a Pity?\"",
    "Gershwin's \"___ It a Pity?\"",
    "Fails to be",
    "Does not exist",
    "\"It ___ Fair\" (Sammy Kaye hit)",
    "Gershwin?Æs ?ô___ It a Pity??ö",
    "\"___ It Romantic?\""
  ],
  "neyo": [
    "R&B singer with a hyphenated stage name",
    "\"So Sick\" hitmaker of 2006",
    "R&B singer with the 2006 hit \"So Sick\"",
    "R&B singer with the 2006 #1 hit \"So Sick\"",
    "Singer with the 2012 hit \"Let Me Love You\"",
    "R&B singer with the hits \"So Sick\" and \"Miss Independent\"",
    "R&B singer with the hits \"So Sick\" and \"Mad\"",
    "Singer with the 2006 #1 hit \"So Sick\""
  ],
  "jazz": [
    "With 68-Across, what the trio in this puzzle's clues is trying to promote",
    "Enliven, with \"up\"",
    "All that ___",
    "Grammy category",
    "Meaningless talk, in slang",
    "Bebop, e.g.",
    "Nonsense",
    "Genre for 37-Across",
    "What makes cats happy?",
    "Newport festival music",
    "See 17-Across",
    "Liven (up)",
    "Cats play it",
    "Holiday music?",
    "Charlie Parker's music",
    "Bebop"
  ],
  "fans": [
    "Enthusiasts",
    "Whiffs",
    "They might make waves",
    "Ones waiting for autographs, e.g.",
    "Ballpark figures",
    "Rooters",
    "Completely misses the ball",
    "Season ticket holders, notably",
    "Ones yelling 36-Across",
    "Some screamers",
    "Clappers",
    "Rooters",
    "Strikes out",
    "Pre-air conditioning coolers",
    "Strikes out",
    "Gear for Sally Rand"
  ],
  "ssns": [
    "Tax IDs",
    "Application figs.",
    "Info on tax forms",
    "Credit application figs.",
    "Some I.R.S. data, in brief",
    "Digital IDs",
    "Some sensitive info, for short",
    "IDs assigned at birth: Abbr.",
    "Entries on 1040s, for short",
    "1040 figures, for short",
    "Payroll dept. IDs",
    "Figs. on tax forms",
    "Identifiers on tax returns: Abbr.",
    "W-2 IDs",
    "Certain IDs",
    "I.R.S. IDs",
    "Payroll dept. figs.",
    "I.R.S. figs.",
    "Some I.R.S. data, for short",
    "Some Form 1040 data, for short",
    "Joint acct. info",
    "Application figs.",
    "Hacking targets, for short",
    "Taxpayers' IDs",
    "IDs collected by H.R.",
    "Figs. in a 3-2-4 format",
    "IDs that are often not displayed in full: Abbr.",
    "Some personnel info: Abbr.",
    "Invoice figs.",
    "ID thieves' targets",
    "Govt. IDs",
    "Phishing targets, for short",
    "W-9 fields: Abbr.",
    "Payroll dept. info",
    "Payroll IDs",
    "Figs. with two hyphens",
    "I.R.S. IDs",
    "Phishing loot: Abbr.",
    "Job application info, for short",
    "Employee data: Abbr.",
    "Identity theft targets: Abbr.",
    "ID theft targets",
    "ID figures",
    "ID theft targets",
    "Nine-digit govt. IDs",
    "Strings used in payroll depts.",
    "Personnel IDs",
    "Payroll stub IDs",
    "Govt. database entries",
    "I.R.S. figures: Abbr.",
    "Some employment records: Abbr.",
    "Identity theft targets: Abbr.",
    "Payroll dept. figs.",
    "Some IDs: Abbr.",
    "Payroll IDs",
    "Hyphenated IDs",
    "I.R.S. data: Abbr.",
    "Dashed figs.",
    "I.R.S. data: Abbr.",
    "Hyphenated figs.",
    "Payroll dept. ID's",
    "Credit application figs.",
    "I.R.S. ID's",
    "Some personal data: Abbr.",
    "Info that may be phished: Abbr.",
    "Workers' ID's",
    "Some ID's",
    "Form data: Abbr.",
    "Figs. with two hyphens",
    "ID's with two hyphens",
    "Payroll dept. ID's",
    "Payroll dept. ID's",
    "Info put on some schedules: Abbr.",
    "Nine-digit ID's",
    "Figs. in bank records",
    "Figs. in identity theft",
    "Personal ID's",
    "ID's sought in phishing",
    "ID's for the I.R.S.",
    "Workers' ID's",
    "ID's for the I.R.S.",
    "I.R.S. ID's",
    "I.R.S. info",
    "Some ID's",
    "ID's, of sorts",
    "Workers need them: Abbr.",
    "Some ID's",
    "Nine-digit ID's",
    "ID's of a sort",
    "Employees' ID's: Abbr.",
    "Personnel data: Abbr.",
    "Personnel data: Abbr."
  ],
  "sunk": [
    "Like a ship on an ocean floor",
    "Like many ships in the Bermuda Triangle",
    "Like ships on the ocean floor",
    "Destroyed, as an armada",
    "Doomed",
    "X'd out completely, in the game battleships",
    "Bankrupted",
    "Shipwrecked",
    "Done for",
    "At the ocean's bottom, as a ship",
    "Done for",
    "Bankrupt",
    "Bankrupt, so to speak",
    "Submerged"
  ],
  "dino": [
    "T. rex, e.g.",
    "Bird's long-ago relative, informally",
    "Rat Pack nickname",
    "T. rex, e.g.",
    "T. rex, e.g.",
    "Rat Pack nickname",
    "Rat Pack nickname",
    "Natural history museum exhibit, informally",
    "11-Down pet",
    "Snorkasaurus of toondom",
    "T. Rex, e.g.",
    "Green critter in the Sinclair gas logo",
    "Cartoon pet of note",
    "Cartoon character voiced by Mel Blanc",
    "\"The Flintstones\" pet",
    "Natural history museum exhibit, for short",
    "The Flintstones' pet",
    "Flintstones' pet",
    "Rat Pack nickname",
    "Source of jumbo eggs, in brief",
    "Flintstones' pet",
    "Flintstones' pet",
    "Rat Pack nickname",
    "The Flintstones' pet",
    "Pet in old cartoons",
    "T. Rex, e.g.",
    "Pet on \"The Flintstones\"",
    "Rat Pack nickname",
    "Flintstones pet",
    "The Flintstones' pet",
    "Bedrock pet",
    "Bedrock denizen",
    "Film producer De Laurentiis",
    "Rat Pack name",
    "The Flintstones' pet",
    "Flintstones pet",
    "\"The Tiger and the Pussycat\" director Risi",
    "\"Serpico\" producer De Laurentiis",
    "Producer De Laurentiis",
    "Pebbles's pet",
    "Classic Ferrari",
    "Singer Martin, to friends",
    "Cartoon pet",
    "Flintstones pet",
    "Martin nickname",
    "Flintstone pet",
    "Producer De Laurentiis",
    "Flintstones pet",
    "Flintstones' pet",
    "The Flintstones' \"snorkasaurus\""
  ],
  "arms": [
    "What sleeves hold",
    "Sets, as a security system",
    "Things that may be open or folded",
    "SALT component",
    "Second Amendment focus",
    "Assets for QBs",
    "Missiles and such",
    "Gives weapons to",
    "Provides pieces for",
    "Sites of biceps and triceps",
    "Navy and Air Force vis-a-vis the military",
    "Weaponizes",
    "Regenerable parts of a sea star",
    "Some of Lockheed Martin's business",
    "Parts missing from the Venus de Milo",
    "Branches",
    "With 65-Down, technological escalations",
    "Vests don't cover them",
    "Ones holding hands?",
    "What the Venus de Milo lacks",
    "Weaponry",
    "Combat supplies",
    "See 49-Down",
    "They may be heavy or open",
    "___ running",
    "___ deal",
    "Huggers",
    "Some outlawed international trade",
    "Things you can bear",
    "Heraldic drawing",
    "Rifles and such",
    "Venus de Milo knock-offs?",
    "Equips for war",
    "An octopus has eight",
    "Materiel",
    "Subject of illicit trade",
    "___ race",
    "Some materiel",
    "Weaponry",
    "Shaw's \"___ and the Man\"",
    "Hug givers",
    "Treaty subject",
    "M-1's and AK-47's",
    "Kind of race",
    "Shaw's \"___ and the Man\"",
    "Jacket parts",
    "Cache contents",
    "Sleeves cover them",
    "Kind of race",
    "Gives a piece",
    "Kind of dealer",
    "Kind of dealer",
    "Weaponry",
    "Prepares to fight",
    "Uzis and AK-47's",
    "Treaty subject",
    "Supplies with weapons",
    "Magazines have them",
    "Venus de Milo's lack",
    "Second Amendment subject",
    "\"There is little reason in ___\": Virgil",
    "Makeshift cradle",
    "Pentagon inventory",
    "They're sometimes twisted",
    "Embracers",
    "Hand holders",
    "Kind of race",
    "Equips for war",
    "Branches",
    "Weaponry",
    "Subject of illicit trade",
    "Shaw title starter",
    "Treaty subject",
    "Black market goods",
    "Hand holders?",
    "Weaponry",
    "Ordnance",
    "Weaponry",
    "Kind of buildup"
  ],
  "abba": [
    "First group with a #1 Billboard hit, alphabetically",
    "Pop group with the hits 17-, 39- and 61-Across",
    "Band whose \"Gold: Greatest Hits\" has sold over 30 million copies",
    "Quatrain rhyme scheme",
    "Pop group with a dedicated museum in Stockholm",
    "Alphabetically first group in the Rock & Roll Hall of Fame",
    "Group with a member-centric acronym",
    "Group with the hits \"Honey, Honey\" and \"Money, Money, Money\"",
    "Swedish group that once comprised two married couples",
    "Band that won the 1974 Eurovision Song Contest",
    "\"I Do, I Do, I Do, I Do, I Do\" band, 1975",
    "\"Mamma Mia\" group",
    "\"Dancing Queen\" group",
    "\"Dancing Queen\" group",
    "Hebrew for \"father\"",
    "So-called \"enclosed\" rhyme scheme",
    "Group consisting of Agnetha, Benny, Bjorn and Anni-Frid",
    "Palindromic band with the palindromic song title \"SOS\"",
    "1974 Eurovision winner that went on to international stardom",
    "Pop group whose name is also a rhyme scheme",
    "\"Take a Chance on Me\" group",
    "\"Mamma Mia!\" group",
    "Pop group whose name derives from the initials of its members' first names",
    "Group that inspired \"Mamma Mia!\"",
    "Band whose songs are featured in a hit 2001 musical and 2018 movie",
    "Biblical \"father\"",
    "Group that inspired Broadway's \"Mamma Mia!\"",
    "Swedish pop quartet that won the 1974 Eurovision contest",
    "Band with a symmetrical logo",
    "Group with the hit \"Waterloo\"",
    "\"Fernando\" group",
    "\"Waterloo\" band",
    "\"Fernando\" band",
    "Bishop's title in the Coptic Church",
    "Palindromic band name",
    "Pop group that broke through at the 1974 Eurovision contest",
    "Group whose first U.S. hit was \"Waterloo\"",
    "\"Rock Me\" group, 1975",
    "Group with the hit 1978 album \"The Album\"",
    "Pop group with a backward \"B\" in its name",
    "Ones repeating \"I do\" in 1976?",
    "Mideast pops?",
    "Group featured in \"Mamma Mia!\"",
    "Eban of Israel",
    "Palindromic band name",
    "Group that inspired \"Mamma Mia!\"",
    "Group whose last Top 40 hit was \"When All Is Said and Done\"",
    "\"Mamma Mia!\" group",
    "Group whose music is heard in \"Mamma Mia!\"",
    "\"Take a Chance on Me\" group",
    "Singers of \"Voulez-Vous\" and \"Waterloo\"",
    "\"Mamma Mia\" singers",
    "\"Mamma Mia\" group",
    "\"Fernando\" group",
    "1970s-'80s group with a palindromic name",
    "Group whose name combines the first letters of its members' names",
    "\"I Do, I Do, I Do, I Do, I Do\" group",
    "\"Dancing Queen\" group",
    "Alphabetically first inductee in the Rock and Roll Hall of Fame",
    "Pop group whose first Top 40 album was, appropriately, \"Arrival\"",
    "\"Take a Chance on Me\" group",
    "\"SOS\" band",
    "\"Dancing Queen\" group",
    "Inspiration for Bjorn Again",
    "\"Chiquitita\" group",
    "Pop group whose name is coincidentally a rhyme scheme",
    "\"Dancing Queen\" group",
    "Source of the music for a 2001 theatrical hit",
    "\"Mamma Mia\" quartet",
    "\"Money, Money, Money\" band",
    "\"___ Gold,\" 1992 album that has sold 28 million copies worldwide",
    "Eban of Israel",
    "\"Super Trouper\" group, 1980",
    "\"Waterloo\" pop group",
    "Pop group that inspired a 2001 Broadway musical",
    "Pop group whose music was the basis of a hit 2001 Broadway musical",
    "\"Mamma Mia\" group",
    "\"Voulez-Vous\" pop group",
    "\"I Do, I Do, I Do, I Do, I Do\" group",
    "Alphabetically first pop group with a #1 hit",
    "\"Mamma Mia\" pop group",
    "\"Waterloo\" quartet",
    "Pop group with a hit Broadway musical",
    "\"Dancing Queen\" quartet",
    "Pop music acronym",
    "\"Take a Chance on Me\" singers",
    "\"Mamma Mia\" quartet",
    "Acronymic pop group name",
    "\"Fernando\" group",
    "\"Mamma Mia\" pop group",
    "\"Chiquitita\" quartet",
    "\"Dancing Queen\" quartet",
    "\"Take a Chance on Me\" group",
    "\"Mamma Mia\" group",
    "\"Dancing Queen\" quartet",
    "\"Waterloo\" band",
    "\"Mamma Mia!\" group",
    "Group behind a 2001 Broadway musical",
    "Palindromic pop group",
    "\"Dancing Queen\" pop group",
    "Father, in the Bible",
    "Quatrain rhyme scheme",
    "\"Chiquitita\" quartet",
    "\"S.O.S.\" band",
    "\"Fernando\" singers",
    "\"SOS\" pop group",
    "\"Waterloo\" pop group",
    "\"Mamma Mia\" pop group",
    "\"I Do, I Do, I Do, I Do, I Do\" group",
    "Title for some bishops",
    "Biblical \"father\"",
    "\"Dancing Queen\" group",
    "\"Dancing Queen\" group",
    "Redondilla rhyme scheme",
    "\"Fernando\" singers",
    "\"Knowing Me, Knowing You\" group",
    "Quatrain pattern",
    "\"Dancing Queen\" music group",
    "Title for some bishops",
    "\"S.O.S.\" pop group",
    "Top-selling pop group of the 70's",
    "\"Fernando\" group",
    "Common rhyme scheme",
    "With 2-Down, \"My People\" author",
    "Coptic bishop's title",
    "\"SOS\" singers",
    "1970's hitmakers from Sweden",
    "Palindromic pop group",
    "Group with the 1976 hit \"Fernando\"",
    "Rhyme scheme",
    "With 49-Down, former Israeli statesman",
    "Statesman Eban",
    "Rhyme scheme for Mr. Eban?",
    "\"Dancing Queen\" pop group",
    "\"Dancing Queen\" pop group",
    "Eastern Church title",
    "With 45-Down, author of \"My People\"",
    "\"Dancing Queen\" pop group",
    "\"Waterloo\" pop band",
    "Biblical invocation to God",
    "\"Dancing Queen\" pop group",
    "Father, in the Bible",
    "Group that did \"I Do, I Do, I Do, I Do, I Do\"",
    "Pop group in \"Muriel's Wedding\"",
    "\"I Do, I Do, I Do, I Do, I Do\" singers",
    "Palindromic pop quartet",
    "Palindromic pop group",
    "Group with the hit ?ôWaterloo?ö",
    "Palindromic name in pop music",
    "Israel's Eban",
    "\"Dancing Queen\" pop group",
    "Israel's Eban",
    "Palindromic pop group"
  ],
  "gail": [
    "___ Devers, three-time Olympic track gold medalist",
    "Olympic track gold medalist Devers",
    "Olympic sprinting champion Devers",
    "Columnist Collins",
    "Collins on the Op-Ed page",
    "Journalist Sheehy",
    "Borden who founded the Borden Co.",
    "1992 Olympic track star ___ Devers",
    "Author Sheehy",
    "Author Sheehy",
    "Max ___ of \"Barney Miller\"",
    "Writer Sheehy",
    "Writer Godwin",
    "Runner Devers"
  ],
  "tess": [
    "Thomas Hardy's \"___ of the D'Urbervilles\"",
    "Supermodel Holliday",
    "\"Guarding ___\" (1994 Shirley MacLaine movie)",
    "Thomas Hardy title character",
    "Thomas Hardy title heroine",
    "Supermodel Holliday",
    "Harper of \"No Country for Old Men\"",
    "Julia Roberts's character in the \"Ocean's\" films",
    "\"___ of the d'Urbervilles\"",
    "Hardy heroine",
    "\"Ocean's Twelve\" role",
    "Julia Roberts's role in the \"Ocean's\" series",
    "Diminutive for Theresa",
    "___ Trueheart, Dick Tracy's love",
    "Trueheart of the comics",
    "Thomas Hardy title heroine",
    "Danny Ocean's ex-wife in \"Ocean's Eleven\"",
    "1979 Roman Polanski film",
    "\"___ of the D'Urbervilles\"",
    "Thomas Hardy heroine",
    "___ Trueheart, Dick Tracy's sweetheart",
    "___ Trueheart (Dick Tracy's wife)",
    "Danny Ocean's wife",
    "___ Trueheart, Dick Tracy's love",
    "1980 Oscar nominee directed by Roman Polanski",
    "Danny's love in \"Ocean's Eleven\"",
    "Hardy heroine",
    "Danny Ocean's ex-wife in \"Ocean's Eleven\"",
    "Hardy heroine",
    "Hardy heroine",
    "1979 Roman Polanski film",
    "Dick Tracy's girl",
    "The Durbeyfield girl, in literature",
    "\"___ of the D'Urbervilles\"",
    "The working girl in \"Working Girl\"",
    "Cher's role in \"Burlesque\"",
    "Angel Clare's wife, in literature",
    "Dick Tracy's girl",
    "Actress Harper of \"No Country for Old Men\"",
    "Wife of Angel Clare, in literature",
    "One of the Durbeyfields",
    "___ Ocean, Julia Roberts's \"Ocean's Eleven\" role",
    "Thomas Hardy heroine",
    "\"___ of the Storm Country\"",
    "Trueheart of the comics",
    "Literary title character called \"a pure woman\"",
    "\"___ of the D'Urbervilles\"",
    "Jamie Lee Curtis's \"Freaky Friday\" role",
    "Hardy heroine",
    "Mrs. Dick Tracy",
    "\"Guarding ___\" (1994 MacLaine movie)",
    "Julia Roberts's role in \"Ocean's Eleven\"",
    "Actress Harper",
    "\"___ of the D'Urbervilles\"",
    "Trueheart of \"Dick Tracy\"",
    "\"___ of the D'Urbervilles\"",
    "Kinski title role",
    "\"___ of the D'Urbervilles\"",
    "Head angel in \"Touched by an Angel\"",
    "\"___ of the D'Urbervilles\"",
    "Trueheart of \"Dick Tracy\"",
    "___ Trueheart of \"Dick Tracy\"",
    "\"___ of the D'Urbervilles\"",
    "Hardy heroine",
    "Hardy heroine",
    "\"___ of the D'Urbervilles\"",
    "\"___ of the D'Urbervilles\"",
    "Hardy soul",
    "Hardy girl",
    "Julia's role in \"Ocean's Twelve\"",
    "Hardy character",
    "\"Working Girl\" girl",
    "1979 Nastassia Kinski title role",
    "\"___ of the D'Urbervilles\"",
    "Hardy heroine",
    "\"___ of the D'Urbervilles\"",
    "\"___ of the D'Urbervilles\"",
    "___ Trueheart of \"Dick Tracy\"",
    "Hardy heroine",
    "\"Touched by an Angel\" character",
    "\"___ of the D'Urbervilles\"",
    "\"Working Girl\" girl",
    "Trueheart of the comics",
    "Harper of Hollywood",
    "Hardy character",
    "\"Dick Tracy\" gal",
    "1994 Shirley MacLaine title role",
    "Harper of \"Tender Mercies\"",
    "Hardy heroine",
    "The girl in \"Working Girl\"",
    "1979 Kinski title role",
    "\"The Lover of Horses\" author Gallagher",
    "Dick Tracy's Trueheart",
    "\"Guarding ___\" (1994 movie)",
    "Tracy's Trueheart",
    "One of the Durbeyfields",
    "Thomas Hardy heroine",
    "Actress Harper",
    "Durbeyfield lass",
    "\"___ of the D'Urbervilles\"",
    "Hardy soul?",
    "Hardy heroine",
    "Hardy heroine",
    "1979 Nastassja Kinski role",
    "Actress Harper",
    "One of the Durbeyfields",
    "Hardy heroine",
    "Actress Harper",
    "\"Red River\" woman ___ Millay",
    "MacLaine movie \"Guarding ___\"",
    "1994 Shirley MacLaine title role",
    "Hardy soul?",
    "\"___ of the D'Urbervilles\"",
    "\"A Pure Woman\" of an 1891 novel",
    "Dick Tracy's love",
    "Miss Trueheart",
    "Hardy girl",
    "1979 Polanski film",
    "Hardy heroine",
    "Durbeyfield girl",
    "Mrs. Dick Tracy",
    "\"Guarding ___\" (1994 movie)",
    "Miss Durbeyfield",
    "___ Millay of ?ôRed River?ö",
    "Poet Gallagher",
    "Hardy girl",
    "1994 title role for Shirley MacLaine",
    "Dick Tracy?Æs wife",
    "Actress Harper",
    "___ Millay of \"Red River\"",
    "Hardy heroine",
    "1979 Nastassja Kinski role",
    "Miss Trueheart of the comics",
    "Actress Harper",
    "Hardy girl",
    "D'Urbervilles lass",
    "Miss Trueheart",
    "Miss Durbeyfield",
    "Joanne Dru's \"Red River\" role",
    "1994 Shirley MacLaine role",
    "Hardy heroine",
    "Chester Gould femme",
    "1994 film \"Guarding ___\""
  ],
  "show": [
    "See 1-Across",
    "Make an appearance",
    "Have a visible baby bump",
    "With 16-Down, decisive confrontation",
    "\"Friends\" or \"Frasier\"",
    "Third place",
    "Finish third",
    "What must go on, proverbially",
    "Evince",
    "Display",
    "Place after win and place",
    "Gallery event",
    "\"24\" or \"48 Hours\"",
    "Lose one's place?",
    "See 24-Across",
    "Look pregnant",
    "Place after place",
    "Keep an appointment",
    "Keep an appointment",
    "Mean",
    "Keep an appointment",
    "Display",
    "Air",
    "Program",
    "Third-place finish",
    "Vegas attraction",
    "It's just a little out of place",
    "Come in third",
    "Third place",
    "See 54-Down",
    "Film or play",
    "Prove",
    "Demonstrate",
    "Movie",
    "Third place",
    "Exhibition",
    "___ and tell",
    "Program",
    "Third place at the race track",
    "Stopper opener?",
    "Third place at Belmont",
    "Third place",
    "Take third"
  ],
  "jest": [
    "Jokey comment",
    "Not be serious",
    "Kid",
    "Bantering remark",
    "Kid",
    "Nonserious remark",
    "Do some leg-pulling",
    "Many a true word is spoken in this",
    "\"Surely you ___\"",
    "Witticism",
    "Pull one's leg",
    "\"Surely you ___\"",
    "Witticism",
    "What ribs are delivered in",
    "Words said in fun",
    "Witty remark",
    "Say jokingly",
    "Comment not to be taken seriously",
    "Fun",
    "Kid around",
    "Witty remark",
    "One-liner, e.g.",
    "Laughing matter",
    "\"Surely you ___!\"",
    "Taunt",
    "Witty remark",
    "Joke",
    "Don't be serious",
    "Not be serious",
    "Sport",
    "Witty remark",
    "Taunt",
    "Bit of banter",
    "Witty remark",
    "It's said with a poke in the ribs",
    "\"Surely you ___!\"",
    "Quip",
    "One-liner, e.g."
  ],
  "apso": [
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___ (dog breed)",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___ (dog)",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Tail end of a dog?",
    "Part of a dog breed's name",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___ (dog breed)",
    "Lhasa ___ (dog)",
    "Tail of a dog?",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___ (dog)",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Dog tail?",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___ (dog)",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___ (dog)",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa___ (dog)",
    "Lhasa ___",
    "Lhasa ___",
    "Lhasa ___ (terrier)",
    "Lhasa ___ (terrier type)"
  ],
  "slew": [
    "Whole lot",
    "Whole bunch",
    "Whole lot",
    "Host",
    "Whole passel",
    "Host",
    "Whole lot",
    "Whole bunch",
    "Myriad",
    "Boatload",
    "Whole bunch",
    "Dispatched, as a dragon",
    "Whole bunch",
    "Killed, as a dragon",
    "Big bunch",
    "Seattle ___ (1977 Triple Crown horse)",
    "Whole heap",
    "Big bunch",
    "Abundance",
    "Did in, as a dragon",
    "Killed, as a dragon",
    "Dispatched, as a dragon",
    "Whole bunch",
    "Dispatched, as a dragon",
    "Whole bunch",
    "Host",
    "Boatload",
    "Big bunch",
    "Multitude",
    "Raft",
    "Killed, as a dragon",
    "Bunch",
    "Boatload",
    "Boatload",
    "Knocked off",
    "Large amount",
    "Whole bunch",
    "Large number",
    "Ton",
    "Ton",
    "Bunch",
    "Plethora",
    "Multitude",
    "Dispatched, as a dragon",
    "Boatload",
    "Large amount",
    "What Cain did to 35-Across",
    "Whole bunch",
    "Whole lot",
    "Knocked off",
    "Large number",
    "Ton",
    "Raft",
    "Large number",
    "Lot",
    "Knocked off, in a way",
    "Whole lot",
    "Whole bunch",
    "Multitude",
    "Whole bunch",
    "Whole lot",
    "Whole bunch",
    "Boatload",
    "Offed",
    "Whole lot",
    "Raft",
    "Whole lot",
    "Whole bunch",
    "Throng",
    "Did away with",
    "Offed",
    "Ocean",
    "Lot",
    "Load",
    "Horde",
    "Great deal",
    "Did in",
    "Did in",
    "Great deal",
    "Lot",
    "Large number",
    "Whole bunch",
    "Bushel and a peck",
    "Bunch",
    "Wiped out",
    "Great deal",
    "Countless number",
    "Wiped out in battle",
    "Gobs",
    "Bunch"
  ],
  "pawn": [
    "One of 16 in a chess set",
    "\"___ Stars\" (long-running show in which experts appraise and buy antiques)",
    "Lowly chess piece",
    "Puppet",
    "Get some quick money for, say",
    "Little man",
    "One getting an across-the-board promotion?",
    "One of 16 in a chess set",
    "Hostage",
    "Future queen, maybe",
    "One with promotional potential",
    "Lowly chess piece",
    "Least powerful member of the board?",
    "Lowly chess piece",
    "Piece in front of a king or queen",
    "One may be placed before a king",
    "Promotable piece",
    "One with a round head",
    "One on the front line",
    "Castle protector, maybe",
    "Board member?",
    "Director's opposite",
    "Future queen?",
    "Frequent chess sacrifice",
    "One on the way to a promotion?",
    "En passant capture",
    "Stooge",
    "Hock",
    "Chess sacrifice, often",
    "Used one",
    "Mere tool",
    "Cat's-paw",
    "Future queen, maybe"
  ],
  "drab": [
    "Dull-colored",
    "Dull-colored",
    "Not very bright",
    "Opposite of colorful",
    "Lacking pizazz",
    "Gray, say",
    "Dreary",
    "Lacking brightness",
    "Humdrum",
    "Visually uninspiring",
    "Lacking pizazz",
    "Grayish",
    "Dull-colored",
    "In need of some color",
    "Dull-colored",
    "Dull-colored",
    "Humdrum",
    "Evoking a \"ho-hum\"",
    "Dull",
    "Ho-hum",
    "Lackluster",
    "Void of any va-va-voom",
    "Blah",
    "Grayish",
    "Lacking color",
    "Dull-colored",
    "Colorless",
    "Not very bright",
    "Evoking an \"eh\"",
    "Cheerless",
    "Dull as dishwater",
    "Blah",
    "Colorless",
    "Ho-hum",
    "Grayish",
    "Frumpy",
    "Lackluster",
    "Far from festive",
    "Lackluster",
    "Visually dull",
    "Gray",
    "Humdrum",
    "Unexciting",
    "Grayish",
    "Brown family member",
    "Hardly exciting",
    "Colorless",
    "Cheerless",
    "Hardly colorful",
    "Colorless",
    "Colorless",
    "Lackluster",
    "Cheerless",
    "Visually blah",
    "Dully colored",
    "Colorless",
    "Unexciting"
  ],
  "ropy": [
    "Fibrous",
    "Viscous",
    "Viscous",
    "Like thick vines",
    "Thickly fibrous",
    "Stringy",
    "Like some muscles",
    "Like some muscles",
    "Cordlike",
    "Not pouring smoothly, as a liquid",
    "Fibrous",
    "Viscous",
    "Glutinous"
  ],
  "bare": [
    "Naked",
    "Nude",
    "Spartan",
    "Lacking vegetation",
    "Reveal",
    "Like Old Mother Hubbard's cupboard",
    "Unadorned",
    "Pure and simple",
    "Unadorned",
    "Expose",
    "Like Mother Hubbard's cupboard",
    "See 22-Across",
    "Lead-in to bones or knuckles",
    "Unpainted, say",
    "Lacking adornment",
    "Lacking adornment",
    "Unadorned",
    "Unpainted, say",
    "Uncovered",
    "Unembellished",
    "Unadorned",
    "Stripped",
    "Expose",
    "Exposed to the elements",
    "Naked",
    "Reveal",
    "Exposed",
    "Unveiled",
    "Stripped",
    "Unadorned",
    "Display",
    "Like Mother Hubbard's cupboard",
    "Not decent",
    "Expose",
    "Show fully",
    "Uncovered",
    "Prepared to streak",
    "Unvarnished",
    "Stripped",
    "Stripped",
    "Unadorned",
    "Expose",
    "Au naturel",
    "Empty",
    "Unadorned",
    "Like a skinny-dipper",
    "Plain",
    "Reveal",
    "Not clothed",
    "In the raw",
    "Show fully",
    "Undecorated",
    "In the buff",
    "Like a streaker",
    "Unclothed",
    "Like some necessities",
    "In the raw",
    "Exposed",
    "Uncover"
  ],
  "silo": [
    "Large grain container",
    "Cereal container",
    "Isolate so as to hinder cooperation and communication in business",
    "Part of a prairie skyline",
    "Tower over the field",
    "Isolated team of workers, in business-speak",
    "Grain location",
    "Cylindrical construction",
    "Place for an Atlas",
    "It may be next to an elevator",
    "Building by a barn",
    "Barn adjunct",
    "Farm structure",
    "Shelter for a Minuteman",
    "Storage tower",
    "Farm tower",
    "Part of many a rural skyline",
    "Missile's home",
    "Fodder's place, but not a mudder's",
    "Missile ___",
    "Barn neighbor",
    "Farm storage building",
    "Farm tower",
    "Structure with a rounded top",
    "It may have a high grain content",
    "Bomb shelter?",
    "Rural storage",
    "Corn Belt tower",
    "Minuteman's location",
    "Crop holder",
    "Storage site",
    "Forage storage",
    "Titan's place",
    "Round storehouse",
    "Place to store coal, perhaps",
    "Rural grain holder",
    "Storage spot",
    "Fodder holder",
    "Forage storage",
    "Place for grain",
    "Big bin",
    "Titan's home",
    "Storage for forage",
    "Tower that's typically scaled from the outside",
    "Titan's home",
    "Barn neighbor",
    "Farm tower",
    "Titan's place",
    "Titan's place",
    "It's raised on a farm",
    "Building beside a barn",
    "Rural skyline sight",
    "Feed holder",
    "The place of one's fodder?",
    "Storage space",
    "Storage site",
    "Barn adjunct",
    "Farm feature",
    "Unit of storage",
    "Plains cylinder",
    "Round building",
    "Underground facility",
    "Building next to a barn",
    "Fodder's place",
    "Prairie building",
    "Storage site",
    "Titan's place",
    "Grain ___",
    "It's raised on a farm",
    "Storage place",
    "Word with wheat or missile",
    "Minuteman's housing",
    "Minuteman's place",
    "Domed building",
    "Rural structure",
    "One may be raised on a farm",
    "Storage cylinder",
    "Launch site",
    "Round farm building",
    "Missile holder",
    "Round building",
    "Patriot's place",
    "Forage holder",
    "Farm building",
    "Countryside cylinder",
    "Fodder holder",
    "Missile berth",
    "My fodder's place?",
    "Fodder holder",
    "Missile housing",
    "Rural sight",
    "Fodder holder",
    "Where fodder is stored",
    "Fodder storage site",
    "Missile housing",
    "Farm structure",
    "Fodder figure",
    "Storehouse",
    "Missile storage site",
    "Military installation",
    "MX housing",
    "Storage tower",
    "Cylindrical building",
    "Farm building",
    "Farm building",
    "Titan's locale",
    "Storage site",
    "Titan's home"
  ],
  "oats": [
    "Porridge, essentially",
    "Sea ___ (grass seen in sand dunes)",
    "Overnight ___ (trendy breakfast)",
    "Word after wild or rolled",
    "Feedbag fill",
    "Breakfast grains",
    "Supply for General Mills",
    "Stable diet?",
    "Some food for a horse",
    "Porridge ingredients",
    "Wild ___",
    "Feedbag feed",
    "Honey Bunches of ___",
    "Rolled ___",
    "Things that may be rolled or wild",
    "Quaker ___",
    "Breakfast bar bits",
    "Grains used in Cheerios",
    "Stable stuff",
    "Feedbag bits",
    "Honey Bunches of ___",
    "Muesli morsels",
    "Healthy yogurt mix-ins",
    "Proverbial \"wild\" things that are sown",
    "The \"O's\" of Cheerios",
    "___ 'n Honey (granola bar option)",
    "Food in a feedbag",
    "Puffed ___",
    "Stable bagful",
    "Quaker ___",
    "Some Arabian food",
    "Horse's feedbox fill",
    "Feel one's ___ (be frisky)",
    "Bran muffin topping",
    "Fare that may be rolled",
    "Horse feed",
    "Granola bar ingredients",
    "Energy bar ingredients",
    "17-Across ingredients, often",
    "Wild things?",
    "Stable diet?",
    "Part of a stable diet",
    "Feeling one's ___",
    "Cholesterol-lowering food",
    "Feedbag bits",
    "Lucky Charms ingredients",
    "Bay filler",
    "Cereal staple",
    "Cheerios are made with them",
    "Wild ones may be sown",
    "Feel one's ___ (be confident)",
    "Wild ___",
    "Muesli ingredients",
    "Trotter fodder",
    "Horse food",
    "Wild ___",
    "Horse course",
    "Wild ___",
    "Basis of Cheerios",
    "Feedbag's fill",
    "Dobbin's dinner",
    "Bagful for Dobbin",
    "They're sometimes rolled",
    "Horse feed",
    "Sow what?",
    "Some are wild",
    "Quaker ___",
    "Rolled items",
    "Feedbag feed",
    "Quaker ___",
    "Quaker ___",
    "Quaker ___",
    "Quaker ___",
    "Horse feed",
    "They may be rolled",
    "Granola bits",
    "Horse feed",
    "Bay filler",
    "Dinner for Dobbin",
    "Mare's meal",
    "They can be rolled",
    "Stable diet?",
    "Feedbag fill",
    "Filly filler",
    "Horse's fare",
    "Rolled items",
    "They may be wild",
    "Big crop in Iowa",
    "Feedbag feed",
    "Feed bag fill",
    "They may be wild",
    "Mudder fodder",
    "Mare fare",
    "Horse's mouthful",
    "Feed bag contents",
    "Feed bag contents",
    "Scout's dinner",
    "Feedbox filler",
    "Haggis ingredient",
    "Dobbin's dinner",
    "Horse feed",
    "Stable particles",
    "Toasted ___",
    "Toasted ___",
    "Equine dinner",
    "Mare's feed",
    "Farm feed",
    "Treat for Count Fleet"
  ],
  "auld": [
    "\"___ Lang Syne\"",
    "\"___ Lang Syne\"",
    "\"Should ___ acquaintance ...\"",
    "\"___ Lang Syne\"",
    "\"___ Lang Syne\"",
    "Word in a New Year's Eve song",
    "\"In the days of ___ lang syne\"",
    "\"___ Lang Syne\"",
    "January 1 title word",
    "Word with which to laud the new year",
    "Holiday tune title starter",
    "\"___ Robin Gray\" (classic Scottish ballad)",
    "\"Should ___ acquaintance be forgot ...\"",
    "Seasonal song title opener",
    "\"___ Lang Syne\"",
    "\"___ Lang Syne\"",
    "Robert Burns title starter",
    "___ Hornie (Satan)",
    "Part of a seasonal song",
    "Seasonal song word",
    "New Year's song title starter",
    "Holiday song title starter",
    "Annual song title starter",
    "\"___ Lang Syne\"",
    "New Year's Eve adjective",
    "Like a Burns acquaintance",
    "Burns title starter",
    "Word sung at a New Year's Eve party",
    "\"___ Lang Syne\"",
    "\"___ Lang Syne\"",
    "\"___ Lang Syne\"",
    "Barrie's \"___ Licht Idylls\"",
    "Anne Lindsay's \"___Robin Gray\"",
    "\"___ Lang Syne\"",
    "\"___ Lang Syne\"",
    "Anne Lindsay's \"___ Robin Gray\"",
    "\"___ Lang Syne\"",
    "Year-end word",
    "1771 ballad, \"___, Robin Gray\""
  ],
  "gnat": [
    "Pesky insect",
    "Insect you may swat away",
    "Itty-bitty biter",
    "Insect flying in a cloud",
    "Pesky insect",
    "One creating a little buzz at an outdoor party",
    "Flier in a black cloud",
    "Pesky swarmer",
    "Tiny nuisance",
    "Tiny buzzer",
    "Summer pest",
    "Flying pest",
    "Part of a dark cloud",
    "Pesky insect",
    "Small bother",
    "Swarming pest",
    "Flying nuisance",
    "Pesky flier",
    "Summer swarmer",
    "Component of a summer cloud",
    "Little irritant",
    "Annoying insect",
    "Little annoyance",
    "Itsy-bitsy biter",
    "Metaphor for insignificance",
    "Cookout annoyance",
    "Annoying sort",
    "Zapper target",
    "Tiny nuisance",
    "Flying pest",
    "Little bugger",
    "Annoying pest",
    "Insect in a summer swarm",
    "Part of a black cloud",
    "Tiny irritant",
    "Part of a moving cloud",
    "Pesky insect",
    "Cookout irritant",
    "Flying pest",
    "Wee pest",
    "Buzzing pest",
    "One buzzing off?",
    "Part of a buzzing swarm",
    "Strained-at bug, in an idiom",
    "Swarming annoyance",
    "Swarm member",
    "Buzzing annoyance",
    "Punkie",
    "One attracted to vinegar",
    "Pesky insect",
    "Zapper's casualty",
    "Punkie",
    "Annoyance at a barbecue",
    "Annoying buzzer",
    "Airborne irritant",
    "Tiny irritant",
    "*Pest you might slap",
    "Pesky flier",
    "Airborne irritant",
    "Snack for a dragonfly",
    "Pesky swarmer",
    "Swarm member",
    "Swatter's target",
    "Member of a pesky swarm",
    "Black fly, e.g.",
    "Pesky biter",
    "Little annoyance",
    "Swarm member",
    "Little biter",
    "Itsy-bitsy biter",
    "Cause for slapping oneself",
    "Itsy-bitsy biter",
    "Flying irritant",
    "Swarming pest",
    "Itsy-bitsy biter",
    "Small distraction",
    "Picnic pest",
    "No-see-um",
    "Little biter",
    "Punkie",
    "Tiny swarmer",
    "Pest",
    "Itsy-bitsy biter",
    "Dipterous one",
    "Swarm member",
    "Annoying insect",
    "Summer pest",
    "Black ___ (fishing fly)",
    "Punkie",
    "Punkie",
    "Pesky insect",
    "Black fly",
    "No-see-um",
    "Picnic pest",
    "Punkie",
    "No-see-um",
    "Small buzzer",
    "Pesky insect",
    "Pesky flier",
    "Strain at a ___",
    "Flying pest",
    "Winged pest",
    "Buzzing pest",
    "Midge",
    "Punkie",
    "Tiny pest",
    "Small annoyance",
    "Pesky insect",
    "Annoying buzzer",
    "Biting insect",
    "No-see-um",
    "Pest",
    "Relative of the midge",
    "Biter",
    "Little pest",
    "Backbiter?",
    "Annoying buzzer"
  ],
  "lane": [
    "Something to notice in passing?",
    "Word with express or through",
    "Part of a track",
    "One of several on a superhighway",
    "Highway division",
    "Runner's space",
    "Follower of Lovers' or Lois",
    "See 39-Across",
    "Bowling reservation",
    "\"Stay in your ___\"",
    "\"Stay in your ___!\"",
    "Penny or memory follower",
    "Narrow street",
    "Shipping route",
    "Superman's love interest Lois ___",
    "Running track part",
    "Swimmer's assignment",
    "H.O.V. ___",
    "Part of a basketball court",
    "Word with fast or fire",
    "The New Yorker film critic Anthony",
    "Sprinter's assignment",
    "Strike setting",
    "It's between the lines",
    "Narrow street",
    "Driving range?",
    "One of several at a toll plaza",
    "Strike zone?",
    "Bike ___",
    "Alternative to Drive or Road",
    "Lois at the Daily Planet",
    "Track assignment",
    "Lois of the Daily Planet",
    "46-Down division",
    "Rural route",
    "A bridge might have one",
    "There may be one for \"8 items or less\"",
    "Race space",
    "Roadway division",
    "\"Penny ___\"",
    "Bowler's assignment",
    "Swim meet assignment",
    "Lovers' ___",
    "Word with exit or express",
    "Pool or carpool concern",
    "Race assignment",
    "Bowler's assignment",
    "Sprinter's assignment",
    "Lois of the Daily Planet",
    "Sprinter's assignment",
    "Lois of the Daily Planet",
    "Swim meet division",
    "Lois of the Daily Planet",
    "Place for pins",
    "Tollbooth access",
    "Word with bus or memory",
    "Narrow way",
    "It has gutters on each side",
    "Strip between stripes",
    "Olympic swimmer's assignment",
    "Site associated with this puzzle's theme",
    "Space between dotted lines, maybe",
    "See 84-Down",
    "Race assignment",
    "Runner's assignment",
    "Narrow path",
    "Pool path",
    "Pool path",
    "Part of an interstate",
    "Word with turning or memory",
    "Highway division",
    "Swim meet division",
    "Narrow street",
    "What traffic cones may show",
    "Strike zone?",
    "Narrow way",
    "Part of a racetrack",
    "Track assignment",
    "The Beatles' \"Penny ___\"",
    "Nathan of stage and screen",
    "Pool division",
    "Lois of \"Superman\"",
    "See 32-Across",
    "Colleague of Kent",
    "Driver's place",
    "Runner's assignment",
    "Lois of the Daily Planet",
    "Area between stripes",
    "Narrow street",
    "Driving place",
    "One may be turning",
    "Swimmer's assignment",
    "Hurdler's assignment",
    "Locale for pins",
    "Alley",
    "Passing ___",
    "Space between the dotted lines",
    "Sprinter's assignment",
    "Channel",
    "Pool demarcation",
    "Country way",
    "Kind of shift",
    "Shady route",
    "Pool division",
    "Highway division",
    "A driver may change one",
    "It may lead to a strike",
    "Country road",
    "Way",
    "Rural route",
    "Shady way",
    "Tony winner Nathan",
    "Wagon path",
    "Narrow street",
    "Strip at a swim meet",
    "Rural route",
    "Swimmer's path",
    "Narrow way",
    "Country path",
    "Quiet street",
    "Penny or Lois"
  ],
  "base": [
    "Reliable supporters",
    "Acid's opposite",
    "It might be stolen in full view",
    "Core political supporters",
    "Politician's core support",
    "It's halfway around a diamond",
    "Acid's opposite",
    "One's most ardent supporters",
    "Core political support",
    "Lead-in to line",
    "First, second or third",
    "Military post",
    "Word before metal or instinct",
    "Runner's place",
    "Ignoble",
    "Part of a trophy",
    "___ 10",
    "Political group unlikely to be swayed",
    "Place to lead a private life?",
    "Plinth, for a pillar",
    "The \"5\" of 5<sup>2</sup>",
    "Two, for binary arithmetic",
    "Diamond bag",
    "Sea of Tranquillity, for the Apollo 11 astronauts",
    "Like metals used by 1-Across",
    "Location in a game of tag",
    "General headquarters?",
    "First, second, third or home",
    "Acid neutralizer",
    "First, second, third or home",
    "Corner of a diamond",
    "It's high on the pH scale",
    "First, second, third or home",
    "___ 10",
    "Runner's place",
    "Hard-core followers, in politics",
    "First, second or third, on a diamond",
    "Dishonorable",
    "Military site",
    "First, second, third or home",
    "Private residence?",
    "Contemptible",
    "Barracks locale",
    "First, second, third or home",
    "It's frequently stolen",
    "When stolen, it stays in place",
    "Mean",
    "Bottom",
    "Barracks locale",
    "Depraved",
    "Pyramid part",
    "Kind of coat",
    "Resting place",
    "Kind of pay or path",
    "Headquarters",
    "Slider's goal",
    "Corner of a diamond",
    "Private area?",
    "First, second or third",
    "See 52-Down",
    "Kind of metal",
    "See 13-Down",
    "It may react with an acid",
    "Pedestal",
    "Item often stolen yet left where it is",
    "Barracks site",
    "It may be stolen",
    "Corner of a diamond",
    "Kind of path or pay",
    "Cornerstone",
    "Safari camp",
    "Pedestal",
    "First or second, e.g.",
    "Starting point",
    "Morally low",
    "Low",
    "Military HQ",
    "Mean",
    "Diamond corner",
    "Pyramid part",
    "Groundwork",
    "Hide-and-seek spot",
    "Air Force facility",
    "Headquarters",
    "First or home, e.g.",
    "Home port"
  ],
  "oaky": [
    "Like many chardonnays",
    "Barrel-flavored, as wine",
    "Tasting of wood, as some chardonnays",
    "Like some chardonnays",
    "Like some chardonnays",
    "Like some wines",
    "Tasting of wood, as some wines",
    "Like some Chardonnay",
    "Tasting of wood, as some spirits",
    "Tasting like certain wood"
  ],
  "send": [
    "iPhone button with an up arrow on it",
    "Email button",
    "Bad button to hit by accident",
    "Post",
    "Wire or cable",
    "Button indicated by a paper airplane icon",
    "Overjoy",
    "Mail",
    "Thrill",
    "Drop in the mailbox",
    "Email button that's all too easy to hit by mistake",
    "Post",
    "Elate",
    "Male",
    "Email button",
    "Make rhapsodic",
    "Thrill",
    "Email button",
    "Text message button",
    "Transmit",
    "Transmit",
    "What to click after finishing an email",
    "Texting button",
    "Overnight, say",
    "\"You ___ Me\" (1957 R&B hit)",
    "Thrill",
    "Texter's button",
    "Summon, with \"for\"",
    "Bad thing to hit if one didn't mean to \"reply all\"",
    "Turn on",
    "Elate",
    "Email button",
    "E-mail command",
    "Ship",
    "Make rhapsodic",
    "Transmit",
    "---",
    "Transport",
    "Thrill",
    "Transmit",
    "E-mail command",
    "Text messaging command",
    "E-mail command",
    "Thrill",
    "E-mail, say",
    "Radio, e.g.",
    "Delight",
    "Text message command",
    "Thrill",
    "E-mailer's button",
    "Fax button",
    "Make rhapsodic",
    "E-mail command",
    "Dispatch",
    "Fax function",
    "Fax, say",
    "Dispatch",
    "E-mail command",
    "Cell phone button",
    "Telegraph, say",
    "E-mail command",
    "Enrapture",
    "E-mail command",
    "Transmit",
    "Walkie-talkie button",
    "Fax or FedEx",
    "Thrill",
    "E-mail option",
    "Thrill",
    "Fax or FedEx",
    "E-mail, e.g.",
    "E-mailer's option",
    "Cell-phone button",
    "Enrapture",
    "Cell-phone button",
    "Transmit",
    "E-mail command",
    "SLIPUP",
    "Export",
    "Cause to go",
    "Fax, say",
    "Dispatch",
    "E-mail command",
    "Excite",
    "Fax",
    "Use Western Union, e.g.",
    "Radio, e.g.",
    "Fedex, e.g.",
    "Fax button",
    "Thrill",
    "Post",
    "E-mail command",
    "Fax button",
    "E-mail, e.g.",
    "Computer command",
    "E-mail menu choice",
    "Thrill",
    "Enrapture, slangily",
    "Delight",
    "Menu choice for e-mail",
    "Transceiver button",
    "Post",
    "Post"
  ],
  "aged": [
    "Like Parmesan, but not mozzarella",
    "Like fine wines",
    "Barrel-___",
    "Like sharp cheddar",
    "Like wine and cheese, typically",
    "Venerable",
    "Like Gruyere or Grandpa",
    "Like wine, but not grape juice",
    "Elderly",
    "Like Scotch, for a minimum of three years",
    "Elderly",
    "Like straight bourbon, for a minimum of two years",
    "Like bourbon",
    "Elderly",
    "Elderly",
    "Yellowed or grayed, perhaps",
    "Like fine wines",
    "Middle-___",
    "Like good Scotch",
    "Got up there",
    "Like good Scotch",
    "Like fine wines and cheeses",
    "Up in years",
    "Like Scotch whisky",
    "Like Gruyere cheese, for 5-12 months",
    "Like fine wine",
    "Got on in years",
    "Middle-___",
    "Venerable",
    "Acquired wisdom, per a saying",
    "Put in for extra time?",
    "Elderly",
    "Kept underground, maybe",
    "Kept in the cellar, maybe",
    "Like centenarians",
    "Like fine whiskeys",
    "Long in the tooth",
    "Like some wines and cheeses",
    "Old",
    "Superannuated",
    "Like many ports",
    "Kept in a cellar, perhaps",
    "Yellowing, maybe",
    "Like some ports",
    "Matured",
    "Ripened",
    "Like sharp cheddar",
    "Like some cheeses",
    "Like relics",
    "Getting on in life",
    "Like wine",
    "Long in the tooth",
    "Like some cigars",
    "Elderly",
    "Old",
    "Creaky, maybe",
    "Got on",
    "Like centenarians",
    "Like good wine",
    "Seasoned",
    "Got mellower",
    "Like ripe cheeses",
    "Ready to get drunk, perhaps",
    "In a bottle for a long time",
    "Like fine wines",
    "Elderly",
    "Wrinkled, maybe",
    "Seasoned",
    "Sat in a cask",
    "Got gray",
    "Old",
    "Like fine cigars",
    "Elderly",
    "Matured",
    "Like some cheeses",
    "Seasoned",
    "Like mellower wines",
    "Like wine",
    "Long in the tooth",
    "Got gray",
    "Got on in years",
    "Like some ports",
    "Like some ports",
    "Got older",
    "Like fine wine",
    "Mellowed",
    "Getting on in years",
    "Like fine wine",
    "Middle-___",
    "Improved, in a way",
    "Elderly",
    "Let sit",
    "Not young",
    "Got on",
    "Like some cheddar cheese",
    "Like sharp cheese",
    "Like fine wine",
    "Old-timers, with \"the\"",
    "Medicare-eligible, maybe",
    "Like fine wine",
    "Elderly",
    "Like good burgundy",
    "Like good wine",
    "Ripened",
    "Elderly",
    "Elderly",
    "Matured",
    "Elderly",
    "Hoary",
    "Fossilized",
    "Turned gray",
    "Prepared brandy",
    "Elderly",
    "Elderly",
    "Like some cheeses",
    "Like good cheese",
    "Like some cheeses"
  ],
  "copy": [
    "Roger's cousin?",
    "\"Roger that\"",
    "See 43-Down",
    "\"Got that\"",
    "Cheat on a test, in a way",
    "Walkie-talkie answer",
    "Fake",
    "Backup",
    "Text",
    "Reproduce",
    "Clone",
    "Material for an editor",
    "Cheat, in a way",
    "\"I read you\"",
    "Clone",
    "City desk shout",
    "\"I read you\"",
    "\"I hear you,\" to a CB operator"
  ],
  "coop": [
    "Certain apartment",
    "Joint venture",
    "Alternative to a condo",
    "Shut (up)",
    "Chicken pen",
    "Place to pick up chicks",
    "Fowl territory?",
    "Chicken condo?",
    "Farm enclosure ... or a farmers' group",
    "Chicken house",
    "Home for hens",
    "Something to buy into",
    "Urban housing option",
    "Chicken's place",
    "Many a city dwelling",
    "Shut (up)",
    "Kind of board",
    "Prison, slangily",
    "Restrain, with \"up\"",
    "*Pen",
    "Place to brood",
    "Hen's home",
    "Hen's place",
    "It's for the birds",
    "Pen",
    "Store on Harvard Square since 1882",
    "Fowl territory?",
    "Place with a board",
    "Urban dwelling, for short",
    "Entity with shareholders",
    "It may be under a board",
    "Condo's cousin",
    "Place for pigeons",
    "Chicken site",
    "Farmers' association",
    "Group enterprise",
    "Prison, slangily",
    "City dwelling",
    "Certain apartment",
    "Farm combine?",
    "Group enterprise",
    "Group shop"
  ],
  "oily": [
    "Like a masseuse's hands, often",
    "Smooth-tongued",
    "Lubricated",
    "Like sardines in a can",
    "Suspiciously flattering, say",
    "Smooth-talking",
    "Oleaginous",
    "Kind of boid that catches the woim?",
    "Like sardines",
    "Overly ingratiating",
    "Overly flattering",
    "Like unwashed hair",
    "In need of a shampoo, say",
    "Like sulfuric acid",
    "Unctuous",
    "Slick",
    "Slick",
    "Prone to acne, say",
    "Like many sunscreens",
    "Like mechanics' hands",
    "Unctuous",
    "Like unwashed hair",
    "Too suave",
    "Like some hair",
    "Opposite of dry, as hair",
    "Greasy",
    "Slick",
    "Needing shampoo, say",
    "Like hands after eating potato chips",
    "Like a mechanic's hands",
    "Suave, and then some",
    "Too smooth",
    "Slick",
    "Too smooth",
    "Like olives or peanuts",
    "Slick",
    "Greasy",
    "Lubricative",
    "Slick",
    "Smarmy",
    "Like unwashed hair, maybe",
    "Overly suave",
    "Suave to a fault",
    "Slick",
    "Unctuous",
    "Greasy",
    "Greasy",
    "Like service station rags",
    "Not to be trusted",
    "In need of a shampoo",
    "Slick",
    "Like unwashed hair",
    "Bootlicking",
    "Smooth-tongued",
    "Smooth-talking",
    "Like auto shop floors",
    "Unctuous",
    "Overly smooth",
    "Overly adulatory",
    "Like garage floors",
    "Slick",
    "Slick",
    "Pinguid",
    "Unctuous",
    "Hardly trustworthy",
    "Pinguid",
    "Unctuous",
    "Too glib"
  ],
  "etat": [
    "French word whose plural is its English translation backward",
    "French political division",
    "Coup target",
    "Division politique",
    "Coup d'___",
    "\"L'___ c'est moi\" (declaration of Louis XIV)",
    "Florida, e.g., to the French",
    "Conseil d'___ (adviser to un president)",
    "Coup d'___",
    "Coup d'___",
    "Conseil d'___",
    "French government",
    "Entite politique francaise",
    "Coup d'___",
    "Nouveau-Mexique, e.g.",
    "Californie, e.g.",
    "Coup d'___",
    "L'___ de droit (the rule of law)",
    "Coup d'___",
    "Division politique",
    "Basse-Californie, e.g.",
    "Coup d'___",
    "Division d'une carte",
    "Caroline du Sud, e.g.",
    "Indiana, e.g., to Lafayette",
    "Virginie, e.g.",
    "\"L'___ c'est moi\"",
    "Coup d'___",
    "Conseil d'___",
    "Division politique",
    "State of France",
    "\"L'___ c'est moi\"",
    "Coup d'___",
    "Coup d'___",
    "Virginie, par exemple",
    "Floride, par exemple",
    "Coup d'___",
    "Coup d'___",
    "Conseil d'___",
    "Californie, e.g.",
    "Californie, e.g.",
    "Ministre d'___",
    "Conseil d'___ (French government body)",
    "Pennsylvanie, e.g.",
    "Coup d'___",
    "State in French",
    "Division politique",
    "Coup d'___",
    "\"L'___ c'est moi\": Louis XIV",
    "Lafayette's state?",
    "Coup d'___",
    "___ de malaise",
    "\"L'___ c'est moi\"",
    "One of 31 in Mexique",
    "South Dakota, to Pierre",
    "Coup d'___",
    "Coup d'___",
    "Floride, e.g.",
    "Target of un coup",
    "Coup d'___",
    "Ministre d'___",
    "Coup d'___",
    "Conseil d'___",
    "Virginie ou Pennsylvanie",
    "Ministre d'___",
    "Coup d'___",
    "Affaires d'___",
    "Homme d'___",
    "Virginie, e.g.",
    "Conseil d'___",
    "Division du Mexique",
    "L'___ de catastrophe naturelle",
    "Louisiane ou Floride",
    "Pennsylvanie, e.g.",
    "French political division",
    "Conseil d'___",
    "Unite politique",
    "Louisiana, e.g., to Louis",
    "Union member: Fr.",
    "Coup d'___",
    "Caroline du Sud, e.g.",
    "Caroline de Nord, e.g.",
    "Louisiana, e.g., in Orleans",
    "Louis XIV, to himself?",
    "Louis XIV, self-referentially",
    "Virginie, e.g.",
    "\"L'___ c'est moi\"",
    "Camus's \"L'___ de siege\"",
    "Coup d'___",
    "Louisiana, e.g., in Orleans",
    "South Dakota, to Pierre",
    "Union member, in France",
    "Part of l'union",
    "Nouveau Mexique, e.g.",
    "Californie, e.g.",
    "Virginia, in Versailles",
    "Camus's \"L'___ de siege\"",
    "Washington, to Lafayette",
    "Coup d'___",
    "Province, to the French",
    "South Dakota, to Pierre",
    "\"L'___ c'est moi\": Louis XIV",
    "Maine, e.g., in Metz",
    "New York, e.g., to Henri",
    "\"L'___, c'est moi\"",
    "Coup d'___",
    "Homme d'___",
    "Division politique",
    "Camus's \"L'___ de siege\"",
    "Washington in Paris, e.g.",
    "\"L'___, c'est moi\"",
    "Homme d'___ (statesman)",
    "Pennsylvanie, e.g.",
    "___-major (staff office)",
    "Division politique",
    "\"L'___ c'est moi\"",
    "Coup d'___",
    "11-Down, e.g., to Henri",
    "Chirac's state",
    "\"L'___ c'est moi\": Louis XIV",
    "Coup d'___",
    "Affaires d'___",
    "\"L'___ c'est moi\"",
    "State of France",
    "French political division",
    "\"L'___ c'est moi\": Louis XIV",
    "Coup d'___",
    "\"L'___ c'est moi\"",
    "State of France",
    "Relative of une province",
    "French state"
  ],
  "dank": [
    "Cold and damp",
    "Cold and damp, as a cellar",
    "Cold and humid",
    "Cold and moist, as a cellar",
    "Cold and damp, as a dungeon",
    "Like caves with streams running through them",
    "Like some cellars",
    "Cold and wet",
    "Like dungeons",
    "Like sea caves",
    "Like many basements",
    "Like dungeons, typically",
    "Like many a cellar",
    "Like dungeons, typically",
    "Dungeonlike",
    "Like dungeons, typically",
    "Clammy",
    "Cold and damp, as a basement",
    "Like a damp cellar",
    "Dungeonlike",
    "Moist, as a cellar",
    "Dungeonlike",
    "Cellarlike",
    "Cool and damp",
    "Cold and wet",
    "Dungeonlike"
  ],
  "chem": [
    "With 51-Down, where solutions are kept, in brief",
    "Physics prereq., maybe",
    "Nobel Prize category: Abbr.",
    "Bond analysts' field?: Abbr.",
    "___ lab",
    "Sci. class",
    "H.S. class",
    "With 5-Down, where reactions take place",
    "Sci. course",
    "Sci. class"
  ],
  "mist": [
    "Airdrops?",
    "Sight in a produce aisle",
    "Morning weather phenomenon",
    "Navigation hazard",
    "Wet cloud",
    "Waterfall feature",
    "Vegetable aisle freshener",
    "Waterfall feature, often",
    "Aerosol spray",
    "Spray",
    "Tears can create one",
    "Humidifier output",
    "It hangs around the Amazon",
    "Spray setting",
    "Drops in the air",
    "\"Gorillas in the ___\"",
    "Fine spray",
    "Tear up",
    "Aerosol spray",
    "Atomizer output",
    "Waterfall effect",
    "Wet blanket?",
    "Add moisture",
    "Waterfall feature",
    "Spray",
    "Visibility reducer",
    "Aerosol output",
    "Drops in the air",
    "Atomizer's release",
    "Drink served over cracked ice",
    "Niagara Falls feature",
    "Atomizer output",
    "Fine spray",
    "Rain forest feature",
    "Atomizer's output",
    "Alcoholic drink served over cracked ice",
    "Brume",
    "Waterfall phenomenon",
    "Spray",
    "Spray",
    "Vapor form",
    "Use an atomizer",
    "Morning haze",
    "Aerosol",
    "Navigation hazard",
    "Fog",
    "Spray",
    "\"Gorillas in the ___\"",
    "Blurrer"
  ],
  "toms": [
    "Big name in slip-on shoes",
    "___ of Maine (toothpaste)",
    "Male turkeys",
    "Some turkeys",
    "___ River, N.J.",
    "Some gobblers",
    "Some cats",
    "Drum kit components",
    "\"Uncle ___ Cabin\"",
    "Some cats",
    "___ River, N.J.",
    "Male tabbies",
    "\"Uncle ___ Cabin\"",
    "___ of Maine toothpaste",
    "Parts of a drum kit",
    "Tabbies' mates",
    "Thumb and others",
    "Some cats",
    "Big gobblers",
    "Gobblers",
    "Some gobblers",
    "Male turkeys",
    "___ River, N.J.",
    "Male turkeys",
    "Gobblers",
    "Some cats",
    "Male turkeys",
    "Some cats",
    "Gobblers",
    "Big gobblers",
    "Tough turkeys",
    "___River, N.J.",
    "Prowling cats",
    "Tough turkeys, maybe"
  ],
  "meds": [
    "Scrip specs",
    "They're prescribed by docs",
    "Rx pickups",
    "Pharma products",
    "Cabinetful, informally",
    "Pharmaceuticals, briefly",
    "Contents of some childproof containers, for short",
    "Doc's orders",
    "Rx items",
    "Doc's orders",
    "Pharma supply",
    "Rx things",
    "Pharmacy stock, informally",
    "Pill bottle contents, informally",
    "Doc's orders",
    "Small doses?",
    "Docs prescribe them",
    "Drugstore stock, for short",
    "Hospital administration, briefly",
    "Many mail-order items, briefly",
    "Dispensary stock, for short",
    "Some are covered by insurance, informally",
    "Drugs, informally",
    "Rx items",
    "Pharmacy stock, for short",
    "Drugs, briefly",
    "Pills, slangily",
    "Drugs, briefly",
    "Docs prescribe them",
    "Some are O.T.C.",
    "Club ___ (resorts)",
    "Club ___ (resorts)",
    "Pre ___ (future doctors)"
  ],
  "self": [
    "Lead-in to service or sacrifice",
    "Lead-in to love or care",
    "___-evaluation",
    "Word before taught or effacing",
    "Who \"I\" refers to",
    "Introvert's focus",
    "Subject most familiar to a portrait painter",
    "What the \"s\" stands for in \"scuba\"",
    "What \"I\" or \"me\" refers to",
    "Individual",
    "___-portrait",
    "Narcissist's focus",
    "___-worth",
    "Identity",
    "\"___-starter\" (resume cliche)",
    "Number one, to some",
    "Lead-in to preservation",
    "Common portrait subject",
    "Mirror image",
    "Mirror image?",
    "Conde Nast title",
    "Autobiographer's subject",
    "Narcissist's love",
    "Freudian concern",
    "\"To thine own ___ be true\"",
    "Starter starter?",
    "You, to you, or me, to me",
    "\"Note to ___ ...\"",
    "___-help",
    "\"Note to ___ ...\"",
    "Kind of help",
    "Popular magazine",
    "Narcissist's love",
    "\"Note to ___ ?à\"",
    "Frequent portrait subject",
    "Ego",
    "Individual",
    "___-serve",
    "Narcissist's love",
    "Ego",
    "Employer, to many",
    "\"Note to ___ ...\"",
    "Popular women's magazine",
    "\"To thine own ___ be true\"",
    "Prefix with defense and destruct",
    "Ego",
    "___-help",
    "Narcissist's love",
    "Starter starter?",
    "Kind of awareness",
    "The first \"S\" in S.A.S.E.",
    "\"To thine own ___ be true\"",
    "Narcissist's love",
    "The individual",
    "Women's magazine",
    "Solipsist's preoccupation",
    "Prefix with starter",
    "Subject of psychoanalysis",
    "Kind of help",
    "Individual",
    "Egotist's concern",
    "One's entity",
    "Ego",
    "Women's health magazine",
    "Part of S.A.S.E.",
    "Ending for him or her",
    "___-serve (gas sign)",
    "Kind of portrait",
    "Auto-",
    "Individual",
    "Ego",
    "New Woman rival"
  ],
  "cced": [
    "Looped in, in a way",
    "Included in an email, in brief",
    "Included, in a way",
    "Included in an email chain",
    "Looped in, in a way",
    "Kept in the loop, in a way",
    "Looped in, in a way",
    "Sent a dupe email to",
    "Included on an email",
    "Included via email",
    "Like associates, on some e-mails",
    "E-mailed a dupe to",
    "Sent duplicates to, briefly",
    "Sent a duplicate, briefly"
  ],
  "dint": [
    "Force",
    "By ___ of",
    "Exertion",
    "Force",
    "Force",
    "Force",
    "Effort",
    "Force",
    "Means (of)",
    "Force",
    "Force",
    "Exertion",
    "Force",
    "Force",
    "Force",
    "Force",
    "Force",
    "Effort",
    "Force",
    "Exertion",
    "Exertion",
    "Force",
    "Effort"
  ],
  "cert": [
    "Official document, informally",
    "Warrant, e.g.: Abbr.",
    "Warrant, e.g.: Abbr.",
    "___ denied (Supreme Court phrase)",
    "Part of C.D.: Abbr.",
    "Kind of check: Abbr.",
    "Breath mint in a roll, informally",
    "Diploma, e.g.: Abbr.",
    "Like some checks: Abbr.",
    "Superior court writ: Abbr.",
    "Legal writ, in brief",
    "Supreme Court writ, familiarly",
    "Like some checks: Abbr.",
    "Legal writ, for short",
    "Notarized doc., e.g.",
    "Part of C.P.A.: Abbr.",
    "Like some checks: Abbr.",
    "U.S.D.A. stamp abbr.",
    "Dick Francis book \"Dead ___\"",
    "Part of C.D.: Abbr.",
    "Dick Francis book \"Dead ___\"",
    "Dick Francis book \"Dead ___\"",
    "\"Dead ___\" (Dick Francis novel)",
    "Legal writ, for short",
    "Legal writ from an appeals court, briefly",
    "Retsyn-filled mint"
  ],
  "gabe": [
    "TV journalist Pressman with a Peabody and 11 Emmys",
    "Comedy's Kaplan or baseball's Kapler",
    "Major-league manager Kapler",
    "1998-2010 major-leaguer Kapler",
    "One of the officemates on \"The Office\"",
    "Sitcom teacher of Vinnie and Boom Boom",
    "Kaplan of \"Welcome Back, Kotter\"",
    "Kaplan of \"Welcome Back, Kotter\"",
    "\"Welcome Back, Kotter\" role",
    "Kaplan who co-hosted six seasons of \"High Stakes Poker\"",
    "Comic Kaplan",
    "Kaplan of \"Welcome Back, Kotter\"",
    "Kotter of ?ôWelcome Back, Kotter?ö",
    "Kotter of 70's TV",
    "Kotter of \"Welcome Back, Kotter\"",
    "Comedian Kaplan",
    "Kotter of \"Welcome Back, Kotter\"",
    "Actor Kaplan",
    "Comic Kaplan",
    "Mr. Kaplan",
    "Kotter of 70'sTV"
  ],
  "mows": [
    "Does a groundskeeper's job",
    "Overwhelms, with \"down\"",
    "Does a landscaper's job",
    "Cuts the grass",
    "Does some diamond cutting?",
    "Puts blades to blades, say",
    "Does course work?",
    "Trims the lawn",
    "Routs, with \"down\"",
    "Cuts (down)",
    "Does yard work",
    "Does some lawn work",
    "Cuts down",
    "Overwhelms, with \"down\"",
    "Tends to the lawn",
    "Cuts blades",
    "Scythes",
    "Cuts (down)",
    "Puts blades to blades?",
    "Cuts inches off yards",
    "Cuts"
  ],
  "exts": [
    "Tel. number add-ons",
    "Personal organizer nos.",
    "They're added on bus. lines",
    "Tel. no. add-ons",
    "Tel. no. add-ons",
    "Office nos.",
    "Parts of office tel. nos.",
    "Company lines?: Abbr.",
    "Bus. phone components",
    "Office phone nos."
  ],
  "pika": ["Rodentlike relative of a rabbit"],
  "adhd": [
    "Condition that fidget tools can help with, for short",
    "Focus problem, for short",
    "Condition treated by Adderall, for short",
    "Condition that affects concentration, for short",
    "Cause of some impulsive behavior, in brief",
    "Condition that Adderall may be prescribed for, in brief",
    "Reason to grant extra testing time, for short",
    "Reason that some students struggle in school, for short",
    "Condition treated with Ritalin, for short",
    "Condition treated with Ritalin, in brief",
    "Ritalin target, for short",
    "Reason for an Adderall prescription, for short",
    "What Adderall treats, for short",
    "Condition that causes fidgeting, informally",
    "Condition for some distracted kids, for short",
    "What Ritalin helps treat, for short",
    "Adderall target, briefly",
    "Cause of some impulsive behavior, for short",
    "Adderall target, for short",
    "Condition for the easily distracted, for short"
  ],
  "rear": [
    "Bring up",
    "Bring up",
    "Tush",
    "Caboose's location",
    "Keister",
    "Derriere",
    "What scared horses do",
    "Bring up ... or something that might be brought up",
    "\"Caboose\"",
    "Raise",
    "Body part whose name contains another body part",
    "Bring up ... or something brought up",
    "Delivery door location, often",
    "Tush",
    "Parent",
    "Keister",
    "Part of the body that's spanked",
    "Caboose, for a train",
    "Parent",
    "Where the engine is in a Porsche 911",
    "Back end",
    "Tale",
    "Tail end",
    "Raise",
    "Behind",
    "Word before window or end",
    "Kind of admiral",
    "Raise",
    "Derriere",
    "Back",
    "[Another arrangement of the letters in the grid]",
    "Keister",
    "Where airplane bathrooms are, often",
    "Derriere",
    "Backside",
    "Bench warmer?",
    "Area jiggled while twerking",
    "Backside",
    "It's sat upon",
    "Sit on it",
    "Back",
    "Raise",
    "Rump",
    "Raise, as young",
    "Where to enter the theater, usually",
    "Backside",
    "Sit on it",
    "Stern",
    "Caboose",
    "Caboose",
    "Fanny",
    "Keister",
    "Can",
    "Beta dog's view",
    "Duff",
    "Front's opposite",
    "Full moon view?",
    "Derriere",
    "Derriere",
    "Can",
    "Tail end",
    "Paddler's target",
    "Derriere",
    "Back end",
    "Can",
    "Parent",
    "Behind",
    "Derriere",
    "Moon unit?",
    "Behind",
    "Where the bag of gifts is stowed on a sleigh",
    "Bring up, as children",
    "Trunk location",
    "Derriere",
    "Backside",
    "Tail end",
    "Parent",
    "Derriere",
    "Behind",
    "Paddler's target",
    "Fanny",
    "Behind",
    "Stern",
    "Employee entrance location",
    "Chair filler",
    "Posterior",
    "Fanny",
    "Bring up",
    "Bring up",
    "Bring up",
    "Back",
    "Backside",
    "Backseat",
    "Derriere",
    "Bring up",
    "Derriere",
    "End",
    "Bus part",
    "Bring up, as children",
    "Spot for a spanking",
    "Derriere",
    "Bring up",
    "Caboose",
    "Rise up",
    "Stern",
    "Bring up",
    "Stern",
    "Caboose's spot",
    "Bring up",
    "Behind",
    "Derriere",
    "Bring up",
    "Care for",
    "Parent",
    "Derriere",
    "Exit location, often",
    "Raise, as kids",
    "Kind of admiral",
    "Parent",
    "Relatively safe military position",
    "Derriere",
    "Area away from the battle",
    "Back",
    "Trunk's locale",
    "Stern",
    "Bring up",
    "Bring up",
    "Bring up",
    "Caboose",
    "Bring up",
    "Nurture",
    "Caboose",
    "Raise",
    "Stern",
    "Kind of admiral",
    "Caboose",
    "Caboose",
    "Caboose, figuratively",
    "Stern",
    "Seat",
    "Stern",
    "Backside",
    "Bring up",
    "Bring up",
    "Kind of guard or end",
    "Backside",
    "Bring up",
    "Front's opposite",
    "Parent",
    "Behind",
    "Caboose",
    "Rise up",
    "Hitchcock's \"___ Window\"",
    "Caboose position",
    "\"Step to the ___:",
    "Back",
    "Stern",
    "Erect",
    "In the back",
    "Bring up",
    "Raise",
    "Hitchcock's \"___ Window\""
  ],
  "swim": [
    "Compete in a Summer Olympics event",
    "Start a triathlon",
    "Do the butterfly, e.g.",
    "1960s fad dance, with \"the\"",
    "You might go for one at the beach",
    "Do laps in a pool",
    "Do the breaststroke, e.g.",
    "Exercise at the Y, maybe",
    "Thick of things, in a manner of speaking",
    "Do the breaststroke, say",
    "Travel like a ray",
    "Triathlon start",
    "Do the crawl, say",
    "Make some waves",
    "1960s dance, with \"the\"",
    "Dip",
    "Sink's alternative",
    "Go up the creek without a paddle?",
    "Do laps",
    "Go down a lane, maybe",
    "Do laps, perhaps",
    "Do the crawl",
    "Pool exercise",
    "Triathlon leg",
    "Travel in trunks?",
    "Go through channels?",
    "Thick of things",
    "Sidestroke, e.g.",
    "Dog-paddle, say",
    "Crawl, e.g.",
    "60's dance",
    "Sink's alternative"
  ],
  "spec": [
    "Particular, for short",
    "De-tailed detail?",
    "Building detail",
    "Blueprint bit",
    "A house may be built on it",
    "On ___ (without a firm commitment)",
    "Something a house might be built on",
    "Something you might write an article on",
    "Something journalists may work on",
    "Kind of sheet",
    "Kind of sheet",
    "Building direction, briefly",
    "Detail, in brief",
    "Blueprint detail",
    "Particular in a design",
    "Design detail",
    "Guideline for a freelancer, for short",
    "Design feature",
    "What many writers write on",
    "Something a journalist may work on",
    "Provide design details for",
    "Design detail",
    "Guideline for a contractor, for short",
    "Product detail, briefly",
    "What many designers work on",
    "Prototype detail, briefly",
    "Bit of design info",
    "Not commissioned, after \"on\"",
    "Provide technical details for",
    "Architect's detail, for short",
    "Design detail, briefly",
    "Particular, informally",
    "It may be submitted to an architect",
    "Architect's detail, for short",
    "An article may be written on it",
    "Short detail?",
    "Technical work requirement",
    "Design detail, briefly",
    "Design detail, for short",
    "It's risky to build a house on this",
    "Job detail",
    "A writer may work on it",
    "Particular",
    "You may work on it",
    "Small detail?",
    "You might not get paid while working on it",
    "Blueprint detail",
    "Something risky to work on",
    "Project detail, for short",
    "___ sheet",
    "A writer may work on it",
    "Planning detail",
    "Project particular",
    "Engineering detail",
    "Mil. designation",
    "Blueprint detail, briefly",
    "Design detail",
    "Some houses are built on it",
    "Blueprint detail",
    "Particular, for short",
    "Detail in a builder's plan",
    "On ___ (without a contract)",
    "Contractor's detail, for short",
    "Work detail",
    "Job detail, briefly",
    "Not assigned, after \"on\"",
    "An article may be written on it",
    "Planning detail",
    "Kind of sheet",
    "On ___ (like some jobs)",
    "Some work may be done on it",
    "Small detail?",
    "Particular, for short",
    "On ___ (without a contract)",
    "An article might be written on it",
    "Not guaranteed, after \"on\"",
    "Some writers work on it",
    "Small detail?",
    "On ___ (without assurance of payment)",
    "On ___ (like much freelance work)",
    "Blueprint item",
    "On ___ (without commitment)",
    "Work detail, for short",
    "Work-order detail",
    "Plan detail",
    "On ___ (like some writers' assignments)",
    "On___ (for later approval)",
    "Blueprint detail",
    "Investor's work, for short",
    "On ___ (freelancer's terms)"
  ],
  "oaty": [
    "Like some high-fiber cereal",
    "Like some granola bars",
    "Like Cheerios",
    "Like some breakfast bars",
    "Like Cheerios",
    "Like Cheerios",
    "Like some cereals",
    "Like some hot cereals",
    "Like porridge",
    "Like Cheerios",
    "Like Cheerios",
    "Like some high-fiber cereal",
    "Like some cereal",
    "Like Cheerios"
  ],
  "hast": [
    "Biblical verb with \"thou\"",
    "Possess, as thou might",
    "Possess, in the Bible",
    "Biblical verb",
    "Dost possess",
    "Own, in the past",
    "Verb with \"thou\"",
    "Verb with \"thou\"",
    "\"And ___ thou slain the Jabberwock?\": Carroll",
    "Shakespeare's own?",
    "Biblical verb",
    "Biblical verb",
    "Verb with \"thou\"",
    "Biblical verb",
    "Verb with thou",
    "Biblical verb",
    "Biblical verb with \"thou\"",
    "Biblical verb",
    "\"And ___ thou slain the Jabberwock?\"",
    "Biblical verb",
    "Biblical verb",
    "Have in the past",
    "Biblical verb",
    "\"___ thou eaten of the tree?\": Genesis",
    "Possess, in the Bible"
  ],
  "kobe": [
    "All-time leading scorer for the Lakers, familiarly",
    "Southern Japanese port city",
    "Kind of beef",
    "Recently retired Laker great, to fans",
    "Southern Japanese port city",
    "Longtime teammate of 12-Down",
    "___ beef",
    "Laker legend Bryant",
    "Kind of beef",
    "2007-08 N.B.A. M.V.P., to fans",
    "Bryant of the 35-Across",
    "Japanese port",
    "Japanese port",
    "Japanese beef center",
    "Japanese port",
    "Kind of beef",
    "Asian city heavily bombed during W.W. II",
    "City near Osaka",
    "Teammate of 55-Down",
    "Hoopster Bryant",
    "Port on Osaka Bay",
    "1995 earthquake site",
    "Japanese seaport",
    "1995 earthquake site",
    "1995 earthquake site",
    "Honshu city"
  ],
  "plat": [
    "___ du jour (bistro special)",
    "City planner's map",
    "Surveyor's map",
    "Real estate reference",
    "Map showing property divisions",
    "___ du jour",
    "Area map",
    "Realty reference",
    "Subdivision map",
    "Builder's map",
    "Surveyor's map",
    "Land office map",
    "Area map",
    "Surveyor's map",
    "Survey map",
    "Surveyor?Æs work",
    "Surveyor's map",
    "___ du jour",
    "Map",
    "Developer's reference",
    "Land map",
    "Land map part",
    "Surveyor's map",
    "Surveyor's work",
    "Dreadlocks feature",
    "Subdivision of land",
    "Surveyor's map",
    "Surveyor's chart",
    "Surveyor's work",
    "Braid",
    "Bit of real estate",
    "Survey chart"
  ],
  "peep": [
    "Nest protest",
    "\"I don't want to hear a ___ out of you!\"",
    "Look-see",
    "Little protestation",
    "Slightest protest",
    "Small protest",
    "Tiniest complaint",
    "Tiny protest",
    "Hatchery sound",
    "Tiniest complaint",
    "\"Not a ___ out of you!\"",
    "Slightest of complaints",
    "Coop sound",
    "Slightest complaint",
    "Tiniest objection",
    "Baby bird's sound",
    "Slightest sound",
    "Chick's chirp",
    "Furtive look",
    "Slightest protest",
    "Sound in a hatchery",
    "Least complaint",
    "Coop call",
    "Hatchery sound",
    "Wren's sound",
    "Hatchling's sound",
    "Chick's sound",
    "Chick's sound",
    "Slightest protest",
    "\"Don't make a ___!\"",
    "Slight remark",
    "Chick's sound",
    "Small sound",
    "Chick's sound",
    "Budding twitter",
    "Tiniest complaint",
    "Coop sound",
    "Look-see",
    "First appearance, as of dawn",
    "Incubator noise",
    "Slight complaint",
    "Look furtively",
    "Tiniest protest",
    "Tiniest sound",
    "Tiny protest",
    "Hatchery sound"
  ],
  "unpc": [
    "Like some insensitive remarks, for short",
    "Potentially offensive",
    "Potentially offensive",
    "Potentially offensive, in brief",
    "Not using sensitive language, say",
    "Potentially insulting",
    "Like dumb blonde jokes",
    "Not socially acceptable",
    "Like Bill Maher, notably",
    "Sexist, say",
    "Potentially offensive",
    "Like calling a woman a \"chick,\" say",
    "Potentially offensive",
    "Ignoring modern sensibilities, for short"
  ],
  "poke": [
    "Give a pointer?",
    "Rude touch",
    "Extend (out)",
    "Jab",
    "Raw fish dish",
    "Hawaiian raw fish dish",
    "___ fun",
    "Slow sort, informally",
    "What you might do to a turtle that's withdrawn into its shell",
    "Slow sort, informally",
    "Put a finger on, in a way",
    "Nudge",
    "Gentle reminder",
    "Dawdler",
    "Contact on Facebook",
    "Prod",
    "Prod",
    "Elbow",
    "Dawdle",
    "Search here and there",
    "Jab",
    "Pig's container, in a saying",
    "Jab between the ribs, say",
    "Elbow",
    "Go slowly (along)",
    "Move slowly (along)",
    "Prod",
    "Jab",
    "Small bag",
    "Stick",
    "Test by touching",
    "Elbow",
    "Jab",
    "___ fun at (ridicule)",
    "Creep (along)",
    "Elbow",
    "Laggard, informally",
    "Elbow",
    "Jab",
    "Cowboy, informally",
    "Nudge",
    "Place for a pig",
    "Pig's place",
    "Small bag",
    "Saunter, with \"along\"",
    "Bag, in dialect",
    "Jab"
  ],
  "bono": [
    "U2 frontman",
    "Lead singer for U2",
    "Popular singer born Paul David Hewson",
    "Rock musician with a knighthood",
    "Pro ___",
    "Rock singer who was a Time co-Person of the Year",
    "Pro ___",
    "U2's lead singer",
    "Noted rocker/humanitarian",
    "U2 lead singer",
    "Cher's son Chaz",
    "Knighted U2 singer",
    "U2's frontman",
    "Dublin-born musician",
    "U2 frontman",
    "Pro ___ (like some law work)",
    "Pro ___",
    "Pro ___ (how some law work is done)",
    "One-named rock star",
    "Irishman who was a Time magazine Person of the Year in 2005",
    "Good for Caesar?",
    "Singer who is part owner of Forbes magazine",
    "U2 frontman",
    "Time magazine Person of the Year, 2005",
    "Time magazine 2005 co-Person of the Year",
    "Pro ___",
    "Musician among Time's 2005 Persons of the Year",
    "Globetrotting rock star/political activist",
    "Singer at the Live Aid concert",
    "Pro ___",
    "One-named rocker",
    "\"With or Without You\" singer",
    "Rock singer in social causes",
    "Pro ___ (like some legal work)",
    "Pro ___",
    "Rock star turned activist",
    "Sonny who sang \"Laugh at Me\"",
    "Rock star with many causes",
    "U2's frontman",
    "U2 frontman",
    "Pro ___ (like some lawyers' work)",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Singer-turned-Congressman",
    "Pro ___",
    "One-named rock singer",
    "Pro ___",
    "Pro ___",
    "Sonny on the Hill",
    "Cher's ex",
    "Pro ___ (like some legal work)",
    "Well-known California congressman",
    "Pro ___ (like some legal work)",
    "Ex of 17-Across"
  ],
  "trip": [
    "It costs about twice as much if it's round",
    "Junket",
    "Start off on the wrong foot, maybe?",
    "Journey",
    "Word with round or road",
    "Booking.com booking",
    "Power ___",
    "Stumble",
    "Take a fall",
    "What you might do if you skip a step",
    "Drop acid",
    "It often comes before the fall",
    "Trigger, as an alarm",
    "Set off",
    "Orbitz booking",
    "Take a header",
    "Journey",
    "Set off, as a security alarm",
    "Word after guilt or field",
    "Excursion",
    "Stumble",
    "Hit the ground running?",
    "Fall over one's feet",
    "Voyage",
    "Set off",
    "You would usually buy a round one",
    "Paradoxically, when it's round it's not circular",
    "Take a spill",
    "Excursion",
    "One usually buys a round one",
    "Excursion",
    "Psychedelic experience",
    "Spill",
    "Far-out experience",
    "Common game show prize",
    "Stumble over the corner of a rug, say",
    "See 63-Across",
    "Pratfall",
    "Dancing misstep",
    "Lead-in to -meter",
    "Take a header",
    "Junket",
    "Junket",
    "Make a misstep",
    "Go sprawling",
    "Exciting experience, in slang",
    "Move gracefully or move clumsily",
    "Send sprawling",
    "Junket",
    "Activate, as a switch",
    "Misstep",
    "Word with field or guilt",
    "Fall preceder",
    "Excursion",
    "Excursion",
    "Klutzy move",
    "Source of embarrassment",
    "It may be round",
    "Fall preceder",
    "Spill",
    "Fall preceder?",
    "Touch off",
    "Fall preceder, perhaps",
    "Misstep",
    "Suffer a public embarrassment, maybe",
    "Dancer's woe",
    "Kind of wire",
    "Excursion",
    "Send headlong",
    "Junket",
    "Stumble",
    "Exciting experience, in slang",
    "Junket",
    "Excursion",
    "Stumble",
    "Misstep",
    "Fall preceder",
    "Begin a fall",
    "Junket",
    "Public embarrassment",
    "Do LSD",
    "Stumble",
    "Stumble",
    "Stumble",
    "Stumble"
  ],
  "tram": [
    "One way around Disneyland",
    "Disneyland transport",
    "Part of San Francisco's Muni system",
    "Theme park conveyance",
    "Certain public transport",
    "Train to a plane",
    "Mine transport",
    "Disneyland transport",
    "Tube alternative",
    "Trolley",
    "Mine transport",
    "Trolley",
    "Ride around some parking lots",
    "Streetcar",
    "Disneyland sight",
    "Mine vehicle",
    "Vegas hotel transport",
    "Trolley",
    "Public transport option",
    "Disneyland vehicle",
    "Disney World conveyance",
    "Miner's aid",
    "Electric car",
    "Chairlift alternative",
    "Ride in London",
    "It follows a mine line",
    "Toronto transport",
    "Coal cart",
    "Vehicle that makes pit stops?",
    "Disney World transport",
    "Disneyland sight",
    "Carrier of coal from a mine",
    "Disney World transport",
    "Cable carrier?",
    "Vehicle on tracks",
    "Mine transport",
    "Transport on rails",
    "Transport for a short distance",
    "Track runner?",
    "Piccadilly Station sight",
    "Streetcar",
    "Vehicle on rails",
    "It goes by rail",
    "Disneyland sight",
    "Tube alternative",
    "Airport conveyance",
    "One way around town",
    "One going through a tunnel, perhaps",
    "Public transportation",
    "Way to Roosevelt Island",
    "Roller in a pit",
    "Way around London, once",
    "Streetcar",
    "Maker of pit stops?",
    "Ore carrier",
    "Omnibus alternative",
    "Downhill/uphill conveyance",
    "Coal carrier",
    "Cable car",
    "Streetcar",
    "Urban bell site",
    "Way around London, once",
    "Overhead transport",
    "The cart before the ores",
    "Log carrier",
    "Public transport",
    "Alpine transport",
    "Car on rails",
    "Disney World transport",
    "Ore carrier",
    "Mine car",
    "Coal carrier",
    "Airport conveyance",
    "Bygone London transport",
    "Monorail unit",
    "Mine vehicle",
    "Coal car",
    "21-Across carrier",
    "Way around London",
    "Vienna transport",
    "Roosevelt Island transport",
    "Car on rails",
    "Trolley",
    "Cable car",
    "Urban transport",
    "Cable car",
    "Streetcar",
    "Streetcar",
    "Stratford streetcar",
    "Amusement park transport",
    "Streetcar"
  ],
  "lyon": [
    "City on the Rhône",
    "City on the Rhone",
    "Home to French silk makers",
    "City at the confluence of the Rhone and Saone",
    "French city historically known for silk",
    "Home of the international headquarters of Interpol",
    "City on the Rhone",
    "Lord ___ (overseer of Scottish heraldry)",
    "Mary who founded Mount Holyoke College",
    "City near Saint-Exupery International Airport",
    "Sue of \"Lolita\"",
    "City on the Rhone",
    "City on the Rhone",
    "Sight from the Rhone",
    "Capital of Rhone",
    "Where the Rhone and the Saone meet",
    "French silk center",
    "City on the Rhone",
    "Birthplace of Antoine de Saint-Exupery",
    "Rhone's capital",
    "City on the Rhone",
    "Where the Rhone and the Saone meet",
    "Center of French resistance in W.W. II",
    "Gastronomic capital of France",
    "French silk center",
    "\"Hell's Angels\" star Ben",
    "Where the Saone and Rhone meet",
    "Rhone's capital",
    "City northeast of St.-Etienne",
    "\"Lolita\" star Sue",
    "City on the Rhone and Saone",
    "Mount Holyoke founder Mary",
    "Rhone/Saone city",
    "French silk center",
    "City on the Rhone",
    "Where the Rhone meets the Saone"
  ],
  "rest": [
    "One of the R's in R&R",
    "Break",
    "Time out?",
    "Break",
    "Balance",
    "Indicator to be quiet",
    "Common medical advice",
    "One \"R\" in R&R",
    "Conserve energy",
    "Break",
    "What to do on the Sabbath, per the Bible",
    "11-Down and such",
    "Squiggle on a musical score",
    "\"God ___ Ye Merry, Gentlemen\"",
    "Recharge, perhaps",
    "Others",
    "An \"R\" in R&R",
    "At ___ (stationary)",
    "Musical pause",
    "Something to take lying down",
    "\"Give it a ___\"",
    "Balance",
    "Take five",
    "With 23-Across, picnic table locale",
    "The first \"R\" of R&R",
    "Time out?",
    "Symbol of silence",
    "Unwind",
    "Breather",
    "Breather",
    "Be at leisure",
    "Chill",
    "Balance",
    "Break",
    "The first \"R\" of R&R",
    "Chill out",
    "Slumber",
    "Breather",
    "Pause",
    "Staff break?",
    "The first \"R\" of R&R",
    "See 63-Across",
    "Leftovers",
    "Part of R&R",
    "Pause",
    "Note's counterpart",
    "Take a timeout",
    "The first \"R\" in R&R",
    "Doctor's prescription, often",
    "One \"R\" in R&R",
    "Lie down for a while",
    "Relaxation",
    "Stop what you're doing",
    "Conclude in court",
    "Hinge (upon)",
    "Word before home and room",
    "A little shuteye",
    "One with a staff position?",
    "Ones remaining",
    "\"Parade ___!\"",
    "Musical notation",
    "Unwind",
    "Time off",
    "Breather",
    "Recharge, say",
    "Ubiquitous prescription",
    "Lie down for a while",
    "Silent interval",
    "Staff sign",
    "Kick back",
    "Kind of day for a competitive cyclist",
    "Something to take lying down?",
    "One of the R's of R&R",
    "Musical interlude",
    "Others",
    "What's left",
    "Relaxation",
    "Do some recharging",
    "Sign for a musician not to play",
    "Take five",
    "Leftover part",
    "See 41-Across",
    "Breather",
    "Relax",
    "Doctor's directive",
    "Observe the Sabbath",
    "Staff symbol",
    "Take a breather",
    "Balance",
    "Sleep",
    "Others",
    "Remainder",
    "Doctor's recommendation, often",
    "Quietude",
    "Observe the Sabbath",
    "Kick back",
    "Doctor's prescription",
    "Dormancy",
    "Take five",
    "Put one's feet up",
    "Pause",
    "Take five",
    "What remains",
    "Break",
    "What's left",
    "Lie down on the job?",
    "Conclude one's argument",
    "Others",
    "What's left",
    "Take a siesta",
    "Recharge one's batteries",
    "Lie",
    "Break",
    "Break",
    "Pause",
    "Breather",
    "Cease pleading",
    "Musical sign",
    "Take a break",
    "Leftovers",
    "Break",
    "What is left",
    "Take ten",
    "Not play",
    "Break",
    "Take a few minutes",
    "Breather",
    "Take a breather",
    "Break",
    "Remainder",
    "Holiday",
    "What the defense may do",
    "Take a break",
    "Sabbath activity",
    "Pause",
    "Take a load off",
    "Musical mark",
    "No longer work",
    "Breather",
    "Staff symbol",
    "Break",
    "Take a load off",
    "Musical mark",
    "Everything else",
    "Unwind",
    "Take five",
    "Take five",
    "Part of R & R",
    "Balance",
    "With 16-Across, places to pull over",
    "Lie",
    "What's left",
    "Take five",
    "Take a load off",
    "Take a breather",
    "Put one's legs up",
    "Take five",
    "Recess",
    "Take five",
    "Take five",
    "Doctor's advice, perhaps",
    "Staff symbol",
    "Catch one's breath",
    "Take five",
    "Siesta",
    "Be quiet",
    "Take five",
    "Downtime",
    "Medical advice, often",
    "Take 3, clue 2",
    "Take it easy",
    "Take five",
    "Silence of the staff",
    "Medical advice, often",
    "Musical interval",
    "Others",
    "Musical symbol",
    "Lull",
    "Sit a spell",
    "Take it easy",
    "Others",
    "Sit a spell",
    "Balance",
    "Scorer's mark",
    "Leftovers",
    "With 113-Down, a roadside sight",
    "Break",
    "Break",
    "Word with cure or room",
    "Relax",
    "Staff break?",
    "Take five",
    "Others",
    "Cessation",
    "Observe the Sabbath",
    "Kind of home or room",
    "Quietus",
    "Sabbath activity",
    "Take five",
    "Unwind",
    "Peace of mind",
    "Day of ___",
    "Crash, so to speak",
    "Pause in the music",
    "Loll",
    "Relaxation",
    "Rely (on)",
    "Conclude one's case",
    "Breathing spell",
    "What's left",
    "See 15 Across",
    "Balance",
    "Log some z's",
    "Those not listed",
    "Seventh day activity",
    "There's none for the weary",
    "\"Give it a ___\"",
    "Time out for music",
    "Slumber",
    "Relax"
  ],
  "simp": [
    "Nincompoop",
    "Fool",
    "Fool",
    "Dimwit",
    "Dunderhead",
    "Fool",
    "Nitwit",
    "Boob",
    "Lamebrain",
    "Not the brightest bulb on the tree",
    "Lamebrain",
    "Nincompoop",
    "Fool",
    "Nitwit",
    "Fool",
    "Fool",
    "Fool",
    "Birdbrain",
    "Fool",
    "Ninny",
    "Dimwit",
    "Easy mark",
    "Ignoramus"
  ],
  "thus": [
    "So",
    "So",
    "Start of a conclusion",
    "Ergo",
    "So",
    "\"Ergo ...\"",
    "\"That means ...\"",
    "Accordingly",
    "As follows",
    "As follows",
    "Beginning of a conclusion",
    "Start of a conclusion",
    "So",
    "Hence",
    "So",
    "So",
    "Ergo",
    "As a result",
    "Consequently",
    "Consequently",
    "So",
    "As follows",
    "Conclusion starter",
    "In this way",
    "Start of a conclusion",
    "See 27-Across",
    "So",
    "As a result",
    "___ far",
    "So",
    "So",
    "Sic",
    "Therefore",
    "Comme ca",
    "Consequently",
    "In this way",
    "Ergo",
    "So",
    "Accordingly",
    "So"
  ],
  "dell": [
    "Farmer's place?",
    "Locale in a children's song title",
    "Farmer's place, in song",
    "Wooded valley",
    "Inspiron maker",
    "Competitor of Acer and HP",
    "Tech company founder Michael",
    "\"The Farmer in the ___\"",
    "PC brand",
    "Big name in PCs",
    "Farmer's place",
    "\"The Farmer in the ___\"",
    "Small, secluded, wooded valley",
    "Hollow",
    "Big name in computers",
    "Computer maker",
    "Texas computer giant started in a dorm room",
    "Big maker of 59-Down",
    "Farmer's place, in a children's ditty",
    "Hollow",
    "Big name in computers",
    "Hollow",
    "Secluded valley",
    "Computer exec Michael",
    "Compaq competitor",
    "Compaq competitor",
    "29-Down competitor",
    "Farmer's whereabouts",
    "Hollow",
    "Brand of computer",
    "Wooded valley",
    "Low spot",
    "Big computer maker",
    "Farmer's place, in song",
    "Big name in computers",
    "Compaq competitor",
    "Vale",
    "Place for a farmer?",
    "Valley",
    "Big name in paperbacks",
    "Austin-based computer company",
    "Big name in computers",
    "Hollow",
    "Name in book publishing since 1943",
    "Farmer's locale?",
    "Wooded valley",
    "Farmer's place, in rhyme",
    "Secluded spot",
    "Farmer's place, in song",
    "Valley",
    "\"Heigho! the derry oh\" setting",
    "Avant-garde writer Floyd",
    "Name in paperback publishing"
  ],
  "ager": [
    "Golden ___",
    "Follower of new or golden",
    "New ___",
    "Smoking or drinking, e.g.",
    "Constant stress or heavy drinking",
    "Golden ___",
    "UV rays, to the skin",
    "Smoking or stress",
    "Ending with golden or teen",
    "Ending with teen",
    "Ending with teen",
    "The presidency, notably",
    "Golden ___",
    "Stress, it's said",
    "Golden ___ (senior)",
    "Antiquing substance",
    "Golden ___",
    "New ___",
    "New ___",
    "Sun or stress",
    "New ___ (Enya type)",
    "Teen follower",
    "Smoking, say",
    "Golden ___ (senior)",
    "Golden ___ (senior)",
    "Heavy drinking, e.g.",
    "Ripening agent",
    "Golden ___",
    "Worrying, for one",
    "New ___",
    "Sun exposure, for one",
    "Follower of new or golden",
    "Golden ___ (senior citizen)",
    "New ___ (57-Down, for one)",
    "Golden ___",
    "Teen-___",
    "Ripening agent",
    "Golden ___ (senior)",
    "\"Happy Days Are Here Again\" composer",
    "Golden ___ (senior)",
    "Golden ___",
    "Chemical used by document forgers",
    "Worry, it's said",
    "Source of gray hairs, maybe",
    "Golden or teen follower",
    "Wrinkle or gray hair producer",
    "Stress, say",
    "New-___",
    "Sun, to skin",
    "New-___ (devotee of crystals and incense)",
    "\"Happy Days Are Here Again\" composer",
    "Antiquing agent",
    "Golden-___",
    "Golden-___",
    "Ripening agent",
    "New-___",
    "It produces wrinkles",
    "Antiquing aid",
    "Golden-___",
    "Worry, it's been said",
    "Golden-___",
    "Time, for wine",
    "Golden finish?",
    "Golden finish?",
    "Ethylene vis-a-vis fruit",
    "Ending with golden or teen",
    "\"Happy Days Are Here Again\" composer",
    "Ripening factor",
    "Cause of gray hair",
    "Ripening agent",
    "Antiquing agent",
    "Worry, perhaps",
    "Golden-___",
    "Antiquing agent",
    "Stress, for one",
    "\"Hard Hearted Hannah\" composer",
    "\"Ain't She Sweet?\" composer",
    "Finish for teen or golden",
    "\"Hard Hearted Hannah\" composer",
    "Ripener",
    "Art forger's aid",
    "Opposite of a wrinkle remover?",
    "Stress, for one",
    "Teen follower",
    "Ending with teen or golden",
    "Ripening agent",
    "\"Ain't She Sweet?\" composer",
    "The sun, to the skin",
    "Song composer Milton",
    "Cause of gray hair",
    "\"Happy Days Are Here Again\" composer Milton",
    "Ripening agent",
    "Ripening agent"
  ],
  "slop": [
    "Hog food",
    "Mysterious cafeteria offering",
    "Unappetizing bowlful",
    "Pig food",
    "Swill for swine",
    "Not stay in the bucket",
    "Messy meal for pigs",
    "School cafeteria food, pejoratively",
    "Beltway insiders / Spill (over)",
    "Trough filler",
    "Not stay in the pail, say",
    "Sow chow",
    "School cafeteria food, stereotypically",
    "Spill (over)",
    "Spill over",
    "Sty fare",
    "Food for hogs",
    "Farm feed",
    "Zero-star fare",
    "Spill",
    "Spillover",
    "Zero-star fare",
    "Feed, as pigs",
    "Spill (over)",
    "Haute cuisine by no means",
    "Haute cuisine it's not",
    "Hog's food",
    "Swine swill",
    "Not stay in the bucket, say",
    "Spill",
    "It's hardly haute cuisine",
    "Barely edible fare",
    "Spill (over)",
    "Undesirable serving",
    "Haute cuisine it's not",
    "Swill",
    "Hardly four-star cuisine",
    "Unappetizing serving",
    "Sty fare",
    "Eats, but barely",
    "Spill",
    "Undesirable serving",
    "Sow chow",
    "Swill",
    "Pig feed",
    "Hogwash",
    "Spill a little",
    "Feed, as pigs",
    "Lousy food",
    "Pen provisions",
    "Farm fare",
    "No haute cuisine",
    "Dinner from a bucket",
    "Spill (over)",
    "Unappetizing food",
    "Refuse",
    "Hardly haute cuisine",
    "A four-star meal it's not",
    "Pig food",
    "Hogwash",
    "Ladled-out food",
    "Swill",
    "Waste",
    "Sty chow",
    "Hog food",
    "Hog filler?"
  ],
  "liev": [
    "Actor Schreiber",
    "Actor Schreiber",
    "Schreiber of \"Spotlight\"",
    "Actor/director Schreiber",
    "Schreiber who won a Tony for \"Glengarry Glen Ross\"",
    "Tony winner Schreiber",
    "Actor Schreiber",
    "Actor Schreiber",
    "Schreiber who won a Tony for \"Glengarry Glen Ross\"",
    "Schreiber of \"X-Men Origins: Wolverine\"",
    "Schreiber of the \"Scream\" films",
    "Schreiber of \"The Manchurian Candidate\""
  ],
  "stem": [
    "Academic acronym",
    "Part of a wineglass or watch",
    "Arise (from)",
    "Acronym for some academic grants",
    "Part of a musical note",
    "Part of a cherry you don't eat",
    "Subjects in which women have traditionally been underrepresented, for short",
    "Root/blossom connector",
    "Long part of a rose",
    "Part of a wineglass",
    "Derive (from)",
    "Fruit part that's thrown away",
    "Check",
    "Florist's cutting",
    "Topic in education policy",
    "Cherry throwaway",
    "What a prefix or suffix gets added to",
    "Goblet feature",
    "Big acronym in education",
    "Part of a musical note",
    "Wine glass part",
    "Modern education acronym",
    "Pipe part",
    "Nonhumanities subjects, for short",
    "Curtail",
    "Brain or watch part",
    "Cherry throwaway",
    "Pipe part",
    "Modern education acronym",
    "Fruit throwaway",
    "Asparagus, mostly",
    "Modern educational acronym",
    "Component not found on a digital watch",
    "Modern education acronym",
    "A snifter has a short one",
    "Check",
    "Part of a flower",
    "Subj. group with a noted gender imbalance",
    "Thorn's site on a rose",
    "Non-humanities acronym",
    "Hold back",
    "Stick in the ground?",
    "Check",
    "Part of a plant or a wineglass",
    "Stalk",
    "Throwaway part of an apple",
    "___ fields",
    "A cherry may be served with it",
    "Result (from)",
    "Flower stalk",
    "Flute section",
    "Blossom support",
    "Wineglass feature",
    "Bloom's support",
    "Originate",
    "Cherry part",
    "Stern's opposite",
    "Flower's support",
    "Mushroom piece",
    "Winder on a watch",
    "Leaf support",
    "Green part of a flower",
    "Musical note part",
    "Derive (from)",
    "Stern's opposite",
    "Bloom support",
    "Hold back",
    "Stanch",
    "Garden support",
    "Spring (from)",
    "Pipe part",
    "Flower stalk",
    "Part of many musical notes",
    "Blossom supporter",
    "Part of a mushroom",
    "Long part of a rose",
    "What an inflectional ending is added to",
    "Flower holder",
    "Morel morsel",
    "Skier's turn",
    "Fruit waste",
    "Pipe part",
    "Staunch",
    "___ cell research",
    "Brain component",
    "Bring to a standstill",
    "Originate",
    "The brain has one",
    "Cherry throwaway",
    "Derive (from)",
    "___ to stern",
    "Glass bottom",
    "Goblet part",
    "Caudex, in botany",
    "Part of a flower or wineglass",
    "Supporter of nature",
    "Restrain",
    "Brain part",
    "Pipe part",
    "Flower stalk",
    "___ cell research",
    "Goblet feature",
    "Fruit holder",
    "Leaf's support",
    "Leaf holder",
    "Arise (from)",
    "Flower holder",
    "Flower stalk",
    "Kind of cell",
    "Hold back",
    "Kind of cell",
    "Part of a rose",
    "Flower part",
    "Thorn site",
    "Faucet part",
    "Originate",
    "Originate",
    "Originate (from)",
    "Originate (from)",
    "Bud holder",
    "Half note feature",
    "Check",
    "Rose part",
    "Cut off",
    "It may be thorny",
    "Goblet feature",
    "Stalk",
    "Watch part",
    "Iris part",
    "Hold back",
    "Plant part",
    "Wineglass part",
    "Forward part",
    "Florist's unit",
    "Check",
    "Florist's cutting",
    "Banana stalk",
    "Dam up",
    "Watch part",
    "Stern's opposite",
    "Main part of a word",
    "Flower holder",
    "Opposite of 61-Across",
    "Stern's opposite",
    "Spoon handle",
    "Flute part",
    "Plant part",
    "Cut flower",
    "Ship's front",
    "Check",
    "Supporter of botany",
    "Fruit holder",
    "Flower supporter",
    "Stalk of bananas",
    "Part of a rose",
    "Pipe part",
    "Rose part",
    "Pipe piece",
    "Fruit/tree connector",
    "Plant part",
    "Part of a musical note",
    "Check",
    "Check",
    "Dam up",
    "Goblet feature",
    "Florist's unit",
    "Wineglass feature",
    "Florist's cutting",
    "Word root",
    "Originate",
    "Check",
    "Flower stalk",
    "Fruit holder",
    "Ship's front",
    "Stalk",
    "Wineglass part",
    "Check",
    "Watch part",
    "Stanch",
    "Half-note feature",
    "Frond holder",
    "Derive (from)",
    "Cherry leftover",
    "Part of a pipe",
    "Word with ware or winder",
    "Shut off",
    "Watch part"
  ],
  "alla": [
    "Penne ___ vodka",
    "___ breve (cut time)",
    "___ prima (painting technique)",
    "Mozart's \"Rondo ___ Turca\"",
    "Penne ___ vodka",
    "\"It was ___ dream\"",
    "Pasta ___ Norma",
    "___ breve",
    "Penne ___ vodka",
    "Gnocchi ___ Romana",
    "Penne ___ vodka",
    "Penne ___ vodka",
    "___ breve",
    "___ breve",
    "Penne ___ vodka",
    "Milan's Teatro ___ Scala",
    "Penne ___ vodka",
    "Teatro ___ Scala",
    "___ breve (2/2 time)",
    "___ breve",
    "Part of many ristorante dish names",
    "___ francese",
    "\"___ salute!\" (Italian drinking toast)",
    "___ breve (2/2 time in music)",
    "Penne ___ vodka",
    "\"It was ___ mistake\"",
    "\"... and to ___ good-night\"",
    "___ prima (painting technique)",
    "Milan's Teatro ___ Scala",
    "___ francese",
    "___ breve",
    "___ breve (musical direction)",
    "___ breve",
    "___ parmigiana",
    "Teatro ___ Scala",
    "Teatro ___ Scala",
    "\"It was ___ mistake!\"",
    "___ breve",
    "___ breve",
    "___ Romana (chef's phrase)",
    "Actress Nazimova",
    "___ breve",
    "___ breve (2/2 time, in music)",
    "___ breve (music marking)",
    "___ breve",
    "\"It was ___ mistake!\"",
    "___ breve",
    "___ breve",
    "___ breve (2/2 time)",
    "Milan's Teatro ___ Scala",
    "Teatro ___ Scala",
    "\"It was ___ joke!\"",
    "___ breve",
    "Teatro ___ Scala",
    "\"It was ___ mistake!\"",
    "\"...and to ___ good night!\"",
    "\"___ vostra salute\" (Italian toast)",
    "___ breve",
    "___ breve (2/2 time)",
    "\"... and to ___ good night\"",
    "Actress Nazimova",
    "___ breve (2/2 time)"
  ],
  "keep": [
    "Retain",
    "Maintain",
    "Not go bad",
    "Medieval stronghold",
    "Not go bad",
    "Remain fresh",
    "Hold on to",
    "Retain",
    "Stay fresh",
    "Castle part",
    "Not go bad",
    "Stay good",
    "Stay fresh",
    "Not spoil",
    "Not spoil",
    "Hang on to ... or a word that can precede either half of the answer to each starred clue",
    "Not give up",
    "Not go bad",
    "Castle stronghold",
    "Not rot",
    "Opposite of throw away",
    "Not toss",
    "Retain",
    "Not rot",
    "Stay fresh",
    "Not rot",
    "Maintain",
    "Stay fresh",
    "Stay fresh",
    "Castle part",
    "Refuse to release",
    "Uphold",
    "Retain",
    "Withhold",
    "Hoard",
    "Hang onto",
    "Subsistence",
    "Stay fresh",
    "Donjon",
    "Castle section",
    "Preserve",
    "Maintain"
  ],
  "sods": [
    "Lays the groundwork?",
    "Installs, as a lawn",
    "They may cover a lot of ground",
    "Does some yard work",
    "Groundskeepers' supplies",
    "Gains yardage?",
    "Installs, as a lawn",
    "Rolls out the green carpet?",
    "Puts down roots?",
    "Providers of football game coverage?",
    "Covers, as a football field",
    "Lays the groundwork for?",
    "Repairs, as a golf green",
    "Lays the groundwork for?",
    "Lawn starters",
    "Rolls out for sale at a nursery",
    "Covers, as the earth",
    "Patches, as a fairway",
    "Does some yard work",
    "Landscaping supplies",
    "Landscapers' supplies",
    "Installs, as an outfield",
    "Grass clumps",
    "Lays down the lawn",
    "Lays down the lawn",
    "Adds turf to",
    "Plot cover-ups?",
    "Poor blokes",
    "Chaps, in British slang",
    "They may cover a lot of ground",
    "Covers the earth",
    "Does lawn work",
    "Starts a lawn",
    "Lays down the lawn",
    "Swards",
    "Does lawnwork",
    "Lays tuft",
    "Lays down the lawn",
    "Covers some ground",
    "Transplants grass",
    "Does lawnwork",
    "Lays down the lawn"
  ],
  "jams": [
    "Printer paper problems",
    "Undirected sessions",
    "Quandaries",
    "Extemporizes, in a way",
    "Tough spots",
    "Some jazz sessions",
    "Predicaments",
    "Copier malfunctions",
    "Printer malfunctions",
    "Photocopier malfunctions",
    "Traffic tie-ups",
    "Copy job delayers",
    "Sweet spreads",
    "Photocopier woes",
    "Fixes",
    "Informal jazz sessions",
    "Combo sessions",
    "Photocopier problems",
    "Tough spots to get out of",
    "Some jazz performances",
    "Toast toppings",
    "Plays impromptu",
    "Fixes",
    "Photocopier problems",
    "Fixes",
    "Slam-dunks",
    "Plays extemporaneously",
    "Ties up",
    "Fixes",
    "Tight spots"
  ],
  "holy": [
    "\"O ___ Night\" (Christmas song)",
    "Sacred",
    "Word that can precede water or war",
    "Word before Ghost or Grail",
    "Fit to be canonized",
    "Sacred",
    "H.R.E. part",
    "Sacrosanct",
    "Like the water in a baptism",
    "___ war",
    "With 30-Down, object of a hunt",
    "The ___ Bible",
    "___ Bible",
    "\"Monty Python and the ___ Grail\"",
    "\"___ cow!\"",
    "Like an 8-Down",
    "Fit to be a saint",
    "Blessed",
    "Heaven-born",
    "\"___ smokes!\"",
    "\"O ___ Night\"",
    "Devout",
    "Smoke or order preceder",
    "Start of many an exclamation from Robin",
    "Sacred",
    "Word with smoke or cow",
    "Sacred",
    "Sacrosanct",
    "\"O ___ Night\"",
    "Sacred"
  ],
  "scot": [
    "Native of Glasgow, e.g.",
    "Person who may speak with a brogue",
    "Firth person?",
    "Get off ___-free",
    "Edinburgh native",
    "Watt, e.g.",
    "___-free",
    "One whose coat of arms displays a unicorn",
    "Mary Stuart, for one",
    "Traditional wearer of plaid",
    "Orkneyan or Shetlander",
    "Orkney resident",
    "___-free",
    "Aberdeen resident",
    "Macbeth, e.g.",
    "One from the Land of Cakes",
    "Edinburgh native",
    "Person whose name might start with Mc-",
    "Tartan wearer",
    "Macbeth, e.g.",
    "J. K. Rowling, by residence",
    "___-free",
    "One celebrating the holiday Hogmanay",
    "___-free",
    "Kilt wearer",
    "Duncan or Banquo",
    "The economist Adam Smith, for one",
    "Alexander Graham Bell, for one",
    "Many a person whose name starts \"Mc-\"",
    "Sean Connery, for one",
    "Macbeth or Macduff",
    "Cock-a-leekie eater",
    "Automaker David Dunbar Buick, by birth",
    "Alexander Graham Bell, by birth",
    "Inverness native",
    "Many a wearer of plaid",
    "Burns, e.g.",
    "Rob Roy, e.g.",
    "Native of Ayr",
    "Loch Lomond local",
    "Kilt wearer",
    "Edinburgh native",
    "Highlander, e.g.",
    "Resident of the Land of Cakes",
    "Sean Connery, nationally speaking",
    "Bluebonnet",
    "Glaswegian, e.g.",
    "St. Andrew's Day observer",
    "Many a person whose name starts Mac",
    "Sean Connery, e.g.",
    "Robert Louis Stevenson, e.g.",
    "___-free",
    "Nae-sayer?",
    "St. Andrews golf club member",
    "Robert Burns, e.g.",
    "59-Across, e.g.",
    "One born near the Butt of Lewis",
    "Speaker with a burr",
    "Mathematician Napier, for one",
    "Tam sporter",
    "Caber caster",
    "Nae sayer",
    "Highlander",
    "Sean Connery, for one",
    "Sean Connery, for one",
    "Angus, maybe",
    "___-free",
    "Aberdeen native",
    "Dundee denizen",
    "Dweller in Clydebank",
    "Highlander",
    "Dundee citizen",
    "Aberdeen native",
    "Dundee denizen",
    "Caber tosser",
    "Person whose name starts Mc-, maybe",
    "Person whose name starts with Mac-, maybe",
    "Sean Connery, for one",
    "Macintosh, e.g.",
    "Highlander",
    "Person whose name begins \"Mc-,\" often",
    "Robert Burns, for one",
    "Kilt wearer",
    "Falkirk citizen",
    "Tartan wearer",
    "Part of the U.K.",
    "Burns, e.g.",
    "Dundee dweller",
    "Dundee denizen",
    "Sean Connery, e.g.",
    "Robert Burns, e.g."
  ],
  "aria": [
    "Opera solo",
    "Singer's time to shine",
    "\"O mio babbino caro,\" e.g.",
    "Las Vegas resort with a musical name",
    "Prima donna's big moment",
    "\"Vesti la giubba,\" in \"Pagliacci\"",
    "Diva's delivery",
    "Puccini piece",
    "Something a diva may sing",
    "Piece by Puccini",
    "Vegas casino beside the Bellagio",
    "\"Salome\" solo",
    "\"This is prophetic\" in \"Nixon in China,\" e.g.",
    "Igor Stravinsky's \"Fair lady, gracious gentlemen,\" e.g.",
    "Piece for Leontyne Price",
    "Soliloquy relative",
    "Song for a diva",
    "\"Brava!\" elicitor",
    "Beethoven's \"Ah! perfido,\" e.g.",
    "Diva's delivery",
    "Met demand, maybe",
    "Opera solo",
    "Single's bars?",
    "Operatic showpiece",
    "Opera solo",
    "\"Vissi d'arte,\" in \"Tosca\"",
    "Something that can be performed da capo",
    "Operatic highlight",
    "Stage highlight",
    "Number of operas?",
    "Donizetti's \"Pour mon ame,\" e.g.",
    "Las Vegas casino with a musical name",
    "Opera term that's sometimes a woman's name",
    "One of five in \"La Boheme\"",
    "Verdi's \"La donna e mobile,\" for one",
    "Opera solo",
    "\"O mio babbino caro,\" e.g.",
    "It might have a cadenza",
    "It's frequently in Italian",
    "Tune from \"Turandot\"",
    "Met solo",
    "Opera solo",
    "Hotel/casino on the Vegas Strip",
    "Renee Fleming performance",
    "Libretto listing",
    "Soprano's solo",
    "Gershwin's \"Summertime,\" e.g.",
    "Operatic solo",
    "Song for a diva",
    "Part of a score, maybe",
    "Vegas hotel with a musical name",
    "\"Madama Butterfly\" highlight",
    "\"Nessun dorma,\" for one",
    "Wagner's \"Liebestod,\" e.g.",
    "\"Brava!\" elicitor",
    "Diva's opportunity to shine",
    "It may end on a high note",
    "Cantatrice's delivery",
    "Song for a coloratura",
    "Opera highlight",
    "\"Celeste Aida,\" e.g.",
    "Opera highlight",
    "Mozart's \"Se vuol ballare,\" for one",
    "Song for a diva",
    "Something delivered by a diva",
    "Opera highlight",
    "Opera melody",
    "Diva's solo",
    "\"O mio babbino caro,\" for one",
    "Las Vegas casino with a musical name",
    "Henry Purcell's \"Dido's Lament,\" e.g.",
    "Vegas resort with a musical name",
    "Andrea Bocelli delivery",
    "\"Eri tu,\" but not \"Eres Tu\"",
    "Met solo",
    "Song for a diva",
    "Opera part",
    "\"Di quella pira,\" e.g.",
    "Rossini's \"Largo al factotum,\" e.g.",
    "Lavish Vegas casino opened in 2009",
    "Diva's delivery",
    "Diva's delivery",
    "Scena component",
    "Las Vegas casino opened in 2009",
    "Diva's delivery",
    "Dvorak's \"O Silver Moon,\" for one",
    "Performance often in Italian",
    "\"Vissi d'arte,\" e.g.",
    "Copland's \"Laurie's Song,\" e.g.",
    "Bocelli piece",
    "\"Casta diva,\" e.g.",
    "Diva's number",
    "Puccini's \"Un bel di,\" e.g.",
    "Any \"Salome\" solo",
    "Umber at the opera",
    "Verdi's \"Ernani! Ernani, involami,\" e.g.",
    "\"Celeste Aida,\" for one",
    "Operatic solo",
    "Prima donna's delivery",
    "Puccini piece",
    "Song that may be performed with supertitles",
    "Andrea Bocelli offering",
    "Gershwin's \"Summertime\" is one",
    "Piece at the Met",
    "P.D.Q. Bach's \"I'm the Village Idiot,\" e.g.",
    "Vegas casino with a musical name",
    "Diva's delivery",
    "Song for a diva",
    "Something for trill-seekers?",
    "Opera part",
    "\"La donna e mobile,\" e.g.",
    "Song heard at 15-Across",
    "Da capo ___",
    "Cantatrice's delivery",
    "Mozart piece",
    "La Scala performance",
    "Puccini piece",
    "\"Votre toast,\" e.g.",
    "Diva's delivery",
    "Handel bars?",
    "Prima donna's delivery",
    "La Scala presentation",
    "Dame Joan Sutherland delivery",
    "Song in a libretto",
    "Operatic selection",
    "What the fat lady sings?",
    "Opera solo",
    "Da capo ___",
    "\"Nessun dorma,\" for one",
    "\"Celeste Aida,\" e.g.",
    "17-Down piece",
    "Operatic solo",
    "Operatic highlight",
    "Song on a stage",
    "Menotti's \"Lullaby,\" for one",
    "A cabaletta is a short one",
    "Met melody",
    "\"Caro nome,\" for one",
    "\"Caro nome,\" e.g.",
    "Solo for Pavarotti",
    "Met melody",
    "Soprano ___",
    "\"Che gelida manina,\" e.g.",
    "Mozart's \"Il mio tesoro,\" e.g.",
    "Puccini's \"Nessun dorma,\" for one",
    "\"Habanera\" from \"Carmen\" is one",
    "\"Lullaby,\" for one",
    "\"O patria mia,\" e.g.",
    "Diva's delivery",
    "\"Summertime,\" e.g.",
    "Price number",
    "Diva's solo",
    "Song for a diva",
    "Diva's number",
    "\"Votre toast,\" for one",
    "Classical singer's selection",
    "\"Celeste Aida,\" e.g.",
    "16-Across's \"La donna e mobile,\" e.g.",
    "\"O terra, addio,\" e.g.",
    "\"William Tell\" component",
    "High-culture strains",
    "24-Across's \"Zeffiretti lusinghieri,\" e.g.",
    "Unlikely number for a rock concert",
    "Verdi's \"Di Provenza il mar,\" e.g.",
    "Verdi's \"E il sol dell'anima,\" for one",
    "Scena segment",
    "Diva's delivery",
    "Met highlight",
    "Da capo ___ (Baroque piece)",
    "Mozart's \"Madamina,\" e.g.",
    "Diva's delivery",
    "Melody for Dame Nellie Melba",
    "Oratorio highlight",
    "Diva's song",
    "Andrea Bocelli delivery",
    "Met highlight",
    "___ da capo",
    "\"Sempre libera\" e.g.",
    "Met solo",
    "\"Il mio tesoro,\" e.g.",
    "Song for Dame Nellie Melba",
    "1998 Andrea Bocelli operatic album",
    "Diva's delivery",
    "Solo piece",
    "Saint-Saens song",
    "Met highlight",
    "Dramatic solo",
    "63-Across, e.g.",
    "\"Di quella pira,\" e.g.",
    "Musical solo",
    "Puccini piece",
    "Part of a score",
    "___ di bravura (piece showing off a singer's vocal agility)",
    "La Scala highlight",
    "Puccini piece",
    "Opera solo",
    "Diva's delivery",
    "Coloratura piece",
    "Opera highlight",
    "Cantatrice's offering",
    "Star turn",
    "Diva's delivery",
    "Solo",
    "Stage solo",
    "Diva's solo",
    "\"Tosca\" number",
    "Oratorio highlight",
    "Song that may include some high notes",
    "Dramatic strains",
    "Opera highlight",
    "Met solo",
    "48-Across feature",
    "Solo",
    "Operatic solo",
    "Song for a diva",
    "Number for one",
    "\"Dite alla giovine,\" e.g.",
    "\"Mi chiamano Mimi,\" e.g.",
    "Opera solo",
    "Diva's lines",
    "Mozart composition",
    "Met number",
    "Solo for Renata Scotto",
    "Opera solo",
    "Puccini piece",
    "Diva's solo",
    "It might hit the high notes",
    "Diva's delivery",
    "Operatic solo",
    "Solo",
    "Solo appropriate for this puzzle",
    "Met song",
    "La Scala song",
    "Andrea Bocelli piece",
    "Song for Sills",
    "Operatic song",
    "Greek nymph with a musical-sounding name",
    "\"Willow Song,\" e.g.",
    "Mezzo's tune",
    "Massenet's \"Vision fugitive,\" e.g.",
    "Opera song",
    "\"William Tell\" part",
    "Pavarotti performance",
    "Oratorio segment",
    "Song for a diva",
    "Solo",
    "Song for a diva",
    "Horne solo",
    "Pavarotti specialty",
    "*Opera ... coloratura ... \"Eri tu\"",
    "Diva's delivery",
    "Opera highlight",
    "Operatic song",
    "Part of a libretto",
    "Berg's \"Der Wein,\" e.g.",
    "Diva's moment",
    "What the fat lady sings",
    "Met song",
    "Part of a score, maybe",
    "Met selection",
    "Opera excerpt",
    "Met song",
    "Price piece",
    "Strauss's \"Mein Herr Marquis,\" e.g.",
    "Song for Lily Pons",
    "Part of an oratorio",
    "___ cantabile (gentle, sad song)",
    "Solo piece",
    "Part of a fantasia",
    "Met song",
    "Song with trills",
    "\"Tutte le feste,\" e.g.",
    "Bellini work",
    "Diva's delivery",
    "It may be accompanied by an orchestra",
    "Pavarotti piece",
    "Opera solo",
    "Number for one",
    "Oratorio portion",
    "Song of \"Salome\"",
    "Diva's delivery",
    "\"Una voce poco fa,\" e.g.",
    "Mozart's \"Dalla sua pace,\" e.g.",
    "Diva's song",
    "Solo, of a sort",
    "Part of a score, maybe",
    "Mozart's \"Madamina,\" e.g.",
    "Domingo delivery",
    "Diva's song",
    "Some trills from Sills",
    "Pavarotti solo",
    "Song for Carmen",
    "Strain at La Scala",
    "Song for a diva",
    "Soprano's song, maybe",
    "Air on stage",
    "Coloratura's piece",
    "Bit of Mozart's art",
    "Met song",
    "Soprano's song, maybe",
    "Price presentation",
    "Star turn at La Scala",
    "Mozart's \"Il mio tesoro,\" e.g.",
    "Mozart's \"Madamina,\" e.g.",
    "La Scala highlight",
    "Vocal solo",
    "Met score",
    "Voice stretcher",
    "Diva's offering",
    "Diva's song",
    "Diva's piece",
    "Diva's song",
    "Coloratura's piece",
    "Verdi's \"Caro nome,\" e.g.",
    "Strains at Covent Garden",
    "Diva's big moment",
    "\"Vissi d'arte,\" e.g.",
    "Coloratura's piece",
    "Song for Carmen",
    "Mozart's \"Il mio tesoro,\" e.g.",
    "Met highlight",
    "\"Song of the Golden Calf,\" e.g.",
    "\"Tosca\" tune",
    "Pavarotti solo",
    "9-Across solo",
    "Soprano's song, maybe",
    "Verdi's \"D'amor sull'ali rosee,\" e.g.",
    "Lily Pons specialty",
    "\"Toreador Song,\" e.g., in \"Carmen\"",
    "Operatic solo",
    "Bartoli performance",
    "Battle strain?",
    "Stage solo",
    "Pavarotti piece",
    "\"Summertime,\" e.g., in \"Porgy and Bess\"",
    "Song for Aida",
    "Horne solo",
    "La Scala solo",
    "\"Mi chiamano Mimi,\" e.g.",
    "Operatic solo",
    "51-Down highlight",
    "\"Turandot\" tune",
    "Song for Madama Butterfly",
    "\"Caro nome,\" e.g.",
    "\"Un bel di,\" e.g.",
    "Sills offering",
    "Song from Placido",
    "___da capo",
    "\"Figaro\" song",
    "Sills solo",
    "\"Jewel Song,\" e.g.",
    "La Scala song",
    "\"Quanto e bella,\" e.g.",
    "\"La vendetta,\" e.g.",
    "Diva's big moment",
    "Domingo solo",
    "\"Le reve\" or \"Nessun dorma\"",
    "Met solo",
    "Cousin of a canzone",
    "Pavarotti selection",
    "Domingo forte",
    "Piece for 21-Across",
    "Jessye Norman specialty",
    "\"Tosca\" tune",
    "\"Elsa's Dream,\" e.g.",
    "What the fat lady sings?",
    "Battle song",
    "Sills solo",
    "Coloratura's piece",
    "Sills solo",
    "\"O patria mia,\" e.g.",
    "\"Se vuol ballare,\" e.g.",
    "\"Un bel di,\" for one",
    "___ da capo",
    "Oratorio solo",
    "Sills song",
    "Song for one",
    "\"Flower Song,\" e.g.",
    "Melody",
    "Lover of Apollo",
    "Battle song",
    "La Scala presentation",
    "___ da capo",
    "Kathleen Battle offering",
    "Solo",
    "___ de capo",
    "Sills solo",
    "\"Jewel Song,\" e.g.",
    "Kathleen Battle offering"
  ],
  "slot": [
    "Opening on a schedule",
    "Opening for a coin",
    "Place for a coin",
    "Opening for a time ... or a dime",
    "Position to fill",
    "Part of a schedule",
    "TV time",
    "Pay phone feature",
    "Feature of many a mailbox",
    "Place on a schedule",
    "What a coin may go in",
    "Give an appointment to",
    "8:00-9:00 p.m. in prime time, e.g.",
    "Opening for a coin",
    "Part of a TV schedule",
    "Tab's counterpart",
    "Space on a schedule",
    "Opening at work",
    "One of two areas on a football line",
    "Casino opening",
    "Opening on a schedule",
    "Schedule position",
    "Feature of a one-armed bandit",
    "8:00-9:00 on TV, e.g.",
    "Where to drop a coin",
    "Booking time",
    "Token opening",
    "Screw feature",
    "Opening in a schedule",
    "It may be quarter-sized",
    "Vending machine feature",
    "8:00-9:00 on a schedule, e.g.",
    "Opening for a coin",
    "Part of a schedule",
    "Parking meter opening",
    "A.T.M. feature",
    "Opening for a coin",
    "Hockey area in front of the crease",
    "Casino machine",
    "Schedule",
    "Schedule",
    "Area close to a hockey net",
    "Gambler's opening?",
    "Nickelodeon opening",
    "8:00-9:00, say",
    "One-armed bandit",
    "Where a tab goes",
    "___ machine",
    "Where a tab is inserted",
    "Section of a hockey rink in front of the goal",
    "___ machine",
    "Part of a schedule",
    "See 22-Down",
    "One-armed bandit",
    "Part of a piggy bank",
    "Place on the schedule",
    "Opening for a coin",
    "Schedule",
    "Turnstile feature",
    "Opening for Vegas",
    "Schedule space",
    "Opening",
    "Opening",
    "Token taker",
    "One-armed bandit",
    "Opening",
    "Coin opening",
    "Opening for a tab",
    "Coin opening",
    "Piggy-bank aperture",
    "Schedule",
    "Token taker",
    "Schedule position",
    "Place on a schedule",
    "Place for mail",
    "Word with car or machine",
    "*Opening ... machine ... schedule",
    "Opening for a mail carrier",
    "8:00-8:30, e.g., on a schedule",
    "Token taker",
    "Position to fill",
    "Job opening",
    "Nickelodeon feature",
    "Lemon producer?",
    "Token taker",
    "Schedule position",
    "See 28-Down",
    "Kind of car",
    "Gumball machine feature",
    "Place on the schedule",
    "Part of a jukebox",
    "Space on a schedule",
    "Opening for a coin",
    "Coin hole",
    "Kind of car",
    "Opening",
    "Coin opening",
    "Mail opening",
    "Put on the schedule",
    "Schedule opening",
    "Opening for a coin",
    "One-armed bandit's gullet",
    "Kind of car or machine",
    "Sequence position",
    "Schedule",
    "Where the coin goes",
    "Groove",
    "Word with coin or time",
    "Type of machine",
    "Jukebox part",
    "Tab's target",
    "Schedule position",
    "Vending machine part"
  ],
  "rung": [
    "Step on a ladder",
    "Like bells in carillons",
    "Step on a ladder",
    "Like bells",
    "Like the Liberty Bell in 1846, for the last time",
    "Step on a ladder",
    "What'll give you a leg up?",
    "Step up or down",
    "Small step up?",
    "Step on a ladder",
    "Step on a ladder",
    "Provider of a leg up?",
    "Ladder step",
    "Like a bell",
    "Ladder step",
    "One small step for a man?",
    "It helps you get a leg up",
    "Hierarchy level",
    "Step on a ladder",
    "Hierarchy level",
    "Crosspiece",
    "Ladder step",
    "Pealed",
    "Crosspiece",
    "Step",
    "Ladder unit"
  ],
  "hens": [
    "Scarce as ___ teeth",
    "Nested layers?",
    "Female grouses",
    "___ teeth (rarity)",
    "Certain lawyers",
    "Roosters' mates",
    "Lady birds",
    "Ones sitting on eggs",
    "Roosters' mates",
    "Certain layers",
    "Farmyard females",
    "___ teeth",
    "Chick magnets?",
    "___ teeth",
    "Roosters ... or not roosters?",
    "They tend to brood",
    "Birds in a clutch",
    "Female lobsters",
    "Female fowl",
    "Farm fowl",
    "\"Cluck, cluck\" makers",
    "Brooding types",
    "Layers",
    "Coop group",
    "Chickens that come home to roost",
    "Nest egg protectors",
    "Layers",
    "Three French ___",
    "Egg layers",
    "Some lobsters",
    "Layers",
    "Some lobsters",
    "Nesters",
    "Nesters",
    "Feathered females",
    "Natural incubators",
    "They're inclined to brood",
    "Chicks follow them",
    "Setters",
    "Cluckers",
    "Egg layers",
    "___ teeth",
    "Fowl",
    "Some are cooped up",
    "Nest egg protectors?",
    "Kind of teeth",
    "Egg holders?",
    "Cluckers",
    "Some roasters",
    "Coop group",
    "Feathered layers",
    "Coop group",
    "Egg producers",
    "Egg containers",
    "Female octopi",
    "Brooding sorts",
    "Roosters' mates",
    "Egg producers",
    "Layers",
    "Roosters' mates",
    "Fusspots",
    "Layers",
    "Rooster's mates",
    "Cluckers",
    "Biddies",
    "Some party attenders",
    "Layers",
    "Biddies",
    "Cluckers",
    "Plymouth Rocks, e.g.",
    "Ones who brood",
    "Female lobsters",
    "Chick watchers",
    "They peck at their food"
  ],
  "coax": [
    "Encourage with sweet talk",
    "Wheedle",
    "Sweet-talk",
    "Wheedle",
    "Cajole",
    "Employ cajolery on",
    "Inveigle",
    "Goad gently",
    "Soft-soap",
    "Sweet-talk",
    "Use friendly persuasion",
    "Soft-soap",
    "Inveigle",
    "Wheedle",
    "Cajole",
    "Sweet-talk",
    "Bring around",
    "Say \"Pretty please?,\" say",
    "Urge",
    "Certain cable, informally",
    "Sweet-talk",
    "Soft-soap",
    "Cajole",
    "Try to persuade",
    "Cajole",
    "Try to persuade",
    "Wheedle",
    "Sweet-talk",
    "Gently persuade",
    "Sweet-talk",
    "Sweet-talk",
    "Wheedle",
    "Wheedle",
    "Use low-key persuasion",
    "Soft-soap",
    "Cajole",
    "Sweet-talk",
    "Persuade cagily"
  ],
  "dyes": [
    "Easter egg colorers",
    "Doesn't stay natural?",
    "Henna and others",
    "Makes blue, say",
    "Gives a new color",
    "Salon colorings",
    "Some winks",
    "Batik supplies",
    "Supplies for Easter 65-Across",
    "Producers of highlights",
    "Congo red and gentian violet",
    "Easter stock",
    "Colors, as Easter eggs",
    "Colors, as Easter eggs",
    "Turns a different shade, say",
    "Salon tints",
    "Colors",
    "Food factory stock",
    "Salon supplies",
    "Colors",
    "Salon selections",
    "Goes from blonde to brunette, say",
    "Items in many lists of ingredients",
    "Indigo and others",
    "Turns brown, maybe",
    "Changes the locks?",
    "*Does some batiking",
    "Hair colorers",
    "They make the highlights in highlights",
    "Easter rabbits' needs?",
    "Clairol products",
    "Woad and anil, for two",
    "Some food additives",
    "Shoemakers' supplies",
    "Reddens, maybe",
    "Goes platinum?",
    "Gray concealers",
    "Darkens, maybe",
    "Turns red, perhaps",
    "Hides the gray",
    "Tattooist's supply",
    "Colors, as Easter eggs",
    "Lock changers?",
    "Henna and others",
    "Turns red, say",
    "Salon supplies",
    "Gives hue to a do",
    "Salon supplies",
    "Colors",
    "Blackens, perhaps",
    "Salon applications",
    "Stains",
    "Salon supply",
    "Vat preparations",
    "Brunette-to-redhead jobs",
    "Colors",
    "Colors",
    "Gray removers",
    "Gets rid of the gray",
    "Changes color",
    "They may provide highlights",
    "Isn't natural",
    "Isn't a natural",
    "Batiking needs",
    "They may get in your hair",
    "Rugmakers' supplies",
    "Some blues",
    "Hair salon stock",
    "Photography supplies",
    "Chrome yellow and others",
    "Changes color, in a way",
    "Henna and others",
    "Henna and indigo",
    "Uses henna",
    "Does batik",
    "Henna and others",
    "Easter supplies",
    "Color changers",
    "Salon supplies",
    "Aids in disguises",
    "Henna and others",
    "Goes blonde?",
    "Shoemakers' bottles"
  ],
  "nips": [
    "Puppy bites",
    "Cheese ___ (snack)",
    "Little bites",
    "Barely defeats",
    "Defeats by a hair",
    "Small drinks",
    "Small swigs",
    "Itty-bitty bites",
    "Playful bites",
    "Barely beats",
    "Nabisco's Cheese ___",
    "Puppy bites",
    "Cheese ___ (Nabisco product)",
    "Beats at the buzzer, say",
    "Sots' shots",
    "Sips from flasks",
    "Little belts",
    "Cheese ___",
    "Playful bites",
    "Thimblefuls",
    "Edges",
    "Beats by a hair",
    "Tweaks",
    "Barely defeats",
    "Shots for sots",
    "Cheese ___",
    "Defeats narrowly",
    "Just beats",
    "Puppy bites",
    "Cheese ___",
    "Barely catches",
    "Cheese ___",
    "Checks the growth of",
    "Little snorts",
    "Chills",
    "Narrowly defeats",
    "Barely beats",
    "Beats by a hair",
    "Drams",
    "Beats by a nose",
    "Small bites",
    "Narrowly defeats",
    "Lifts",
    "Cheese___ (popular snack food)",
    "Bites",
    "Indulgences for 54-Across",
    "Puppy bites",
    "Small drinks",
    "Pinches",
    "Tiny portions",
    "Quick bites"
  ],
  "bard": [
    "The ___ of Avon (Shakespeare)",
    "Famous literary nickname, with \"The\"",
    "Shakespeare, for one",
    "Balladeer",
    "Shakespearean title",
    "Homer, for one",
    "Shakespeare, for one",
    "Medieval entertainer",
    "Poet",
    "Shakespeare, for one",
    "Teller of tales",
    "Shakespeare, with \"the\"",
    "Shakespeare or Browning",
    "Minstrel",
    "Shakespeare, e.g.",
    "Odist, e.g.",
    "Poet",
    "One using the metric system?",
    "Good college for poets?",
    "The ___ of Avon",
    "Harp toter of yore",
    "Robert Burns was one",
    "Troubadour",
    "Sonneteer"
  ],
  "ecru": [
    "Sandy hue",
    "Earth tone",
    "Neutral paint color",
    "Very light brown",
    "Beigeish shade",
    "Stationery shade",
    "Light beige",
    "Cream alternative",
    "Buff relative",
    "Khaki-like color",
    "Stationery color",
    "Beige-ish",
    "Wedding dress shade",
    "Relative of cream",
    "Neutral tone",
    "Neutral shade",
    "Color of unbleached silk",
    "Neutral shade",
    "Color of raw linen",
    "Beige-ish",
    "Neutral tone",
    "Hosiery shade",
    "Eggshell shade",
    "Bathroom tile shade",
    "Shade of raw linen",
    "Sandy shade",
    "Shade darker than eggshell",
    "Shade akin to sand",
    "Shade similar to camel",
    "Linen shade",
    "Shade of many a lampshade",
    "Off-white",
    "Off-white shade",
    "Almond-ish hue",
    "Neutral shade",
    "Neutral color",
    "Beige relative",
    "Color similar to almond",
    "Neutral color",
    "Relative of beige",
    "Nude alternative",
    "Beige-ish",
    "Very light brown",
    "Pantyhose shade",
    "Shade akin to beige",
    "Cream-toned",
    "Shade akin to almond",
    "Beige",
    "Sandy shade",
    "Stationery shade",
    "Napkin shade, maybe",
    "Stationery hue",
    "Off-white shade",
    "Color of raw silk",
    "Light brown shade",
    "Off-white shade",
    "Hosiery shade",
    "Soft shade",
    "Hosiery hue",
    "Lace shade",
    "Dull shade",
    "It's similar to cream",
    "Shade for shades",
    "Light brown",
    "Light shade",
    "Tawny",
    "Hose color",
    "Cousin of beige",
    "It's similar to cream",
    "Stocking shade",
    "Literally, \"raw\"",
    "Light brown",
    "Hosiery hue",
    "Hosiery shade",
    "Neutral shade",
    "Silk shade",
    "Shade shade",
    "Hosiery hue",
    "Creamy color",
    "Hose shade",
    "Lingerie shade",
    "Neutral shade",
    "Beige",
    "Neutral shade",
    "Hose hue",
    "Neutral tone",
    "Unbleached: Fr.",
    "Light shade",
    "Alternative to nude",
    "Linen shade",
    "Off-white",
    "Neutral color",
    "Brown family member",
    "Cream",
    "Lampshade shade, perhaps",
    "Sandy hue",
    "Hose shade",
    "Like raw silk",
    "Neutral shade",
    "Off-white",
    "Linen hue",
    "Shade of beige",
    "Stocking shade",
    "Linen hue",
    "Almond",
    "Neutral color",
    "Neutral shade",
    "Doily shade",
    "Hosiery shade",
    "Linen color",
    "Natural shade",
    "Neutral shade",
    "Grayish yellow",
    "Light brown",
    "Raw linen color",
    "Like raw silk",
    "Neutral tone",
    "Brown relative",
    "Creamy shade",
    "Stocking shade",
    "Creamlike",
    "Unflashy color",
    "Hosiery shade",
    "Beige",
    "Almond",
    "Drapery color",
    "Neutral color",
    "Beige",
    "Linen color",
    "Hose hue",
    "Hose hue",
    "L'eggs shade",
    "Brown family member",
    "Linen hue",
    "Tannish",
    "Brown hue",
    "Light brown",
    "Stocking shade",
    "Light brown",
    "Almond",
    "Hosiery shade",
    "Like raw silk",
    "Tannish",
    "Neutral hue",
    "Linen color",
    "Decorator's shade",
    "Light beige",
    "Beige",
    "Stocking shade",
    "Tannish",
    "Stocking shade",
    "Very light brown",
    "Curtain shade",
    "Color of linen",
    "Beige hue",
    "Brown shade",
    "Beige",
    "Hosiery shade",
    "Hosiery shade",
    "Sandy shade",
    "Beige",
    "Light beige",
    "Linen color",
    "Grayish yellow",
    "Linen hue",
    "Beige",
    "Fashion shade",
    "Shirt shade",
    "Beige shade",
    "Neutral shade",
    "Eggshell",
    "Tannish color"
  ],
  "eats": [
    "Has dinner",
    "Polishes off",
    "Gobbles up",
    "Begrudgingly accepts, as a loss",
    "Chow",
    "Puts away",
    "Grub",
    "Gets down",
    "Vittles",
    "Grub",
    "Chow",
    "Din-din",
    "Takes a course",
    "Takes the cake?",
    "Has a nosh",
    "Has a 42-Down",
    "Vittles",
    "Grub",
    "Chuck wagon fixin's",
    "Vittles",
    "Grub",
    "Munchies, say",
    "Grub",
    "Vittles",
    "Has supper",
    "Chows down",
    "Grub",
    "Gets down",
    "Vittles",
    "Grub",
    "Chomps on",
    "Has supper",
    "Vittles",
    "Noshes",
    "Works on a course",
    "Grub",
    "Reduces the fare?",
    "Takes courses?",
    "Grub",
    "Din-din",
    "Chow",
    "Grub",
    "Hot dogs, say",
    "Snacks on",
    "Vittles",
    "Chow",
    "Tummy filler",
    "Sinks a sub?",
    "Manducates",
    "Has a beef?",
    "Vittles",
    "Vittles",
    "Grub",
    "Professor says \"Qualifying races,\" pupil suggests ...",
    "Wears (away)",
    "Puts away plates",
    "Chow",
    "Grabs dinner",
    "Chow",
    "Has dinner",
    "Grub",
    "Food, informally",
    "Grub",
    "Greasy spoon sign",
    "Din-din",
    "Sups",
    "Beanery sign",
    "Acts on a gut feeling?",
    "Absorbs, as a loss",
    "Chow",
    "Downs",
    "Beanery sign",
    "Fills the bill?",
    "Grub",
    "Packs away",
    "Takes in",
    "Diner sign",
    "Diner sign",
    "Vittles",
    "Diner sign",
    "Diner sign",
    "Grub",
    "Chow",
    "Chow",
    "Food, slangily",
    "Diner sign",
    "Beanery sign",
    "Truck stop sign",
    "Gets down",
    "Vittles",
    "Takes in",
    "Takes a course?",
    "Stops fasting",
    "Diner sign",
    "Grub",
    "Chow",
    "Breaks bread",
    "Grub",
    "Grub",
    "Supper, say",
    "Chow",
    "Dines",
    "Emulates Pac-Man",
    "Chows down",
    "Chow",
    "Partakes of",
    "Diner sign",
    "Gnaws (through)",
    "Takes in",
    "Grub",
    "Diner sign",
    "Grub",
    "Vittles",
    "Food",
    "Beanery sign",
    "Chow",
    "Chow",
    "Tucks away",
    "Bolts down",
    "Chow",
    "Consumes",
    "Victuals",
    "Grub",
    "Chow",
    "Patronizes the Four Seasons, e.g.",
    "Feasts upon",
    "Lunches",
    "Vittles",
    "Chow",
    "Hash house sign",
    "Puts away",
    "Corrodes, with \"away\"",
    "Roadside sign",
    "Grub",
    "Gnaws",
    "Grub",
    "Grub",
    "Attacks a sub?",
    "Gobbles",
    "Diner sign",
    "Takes a loss on, in slang",
    "Sups",
    "Grub",
    "Hash house sign",
    "Grub"
  ],
  "voce": [
    "Viva ___ (aloud)",
    "Mezza ___",
    "Sotto ___ (quietly)",
    "Viva ___ (orally)",
    "Sotto ___ (under one's breath)",
    "Viva ___",
    "Viva ___",
    "Viva ___",
    "Mezza ___",
    "Viva ___ (by word of mouth)",
    "Viva ___",
    "Viva ___",
    "Una ___ (unanimously)"
  ],
  "acne": [
    "Retin-A target",
    "Adolescent outburst?",
    "Teen's facial woe",
    "Target of the skin cream Retin-A",
    "Unwanted breakout",
    "One target of doxycycline",
    "Prom night worry",
    "Bad marks for a high schooler",
    "Face covering of a sort",
    "Pimples",
    "Source of teenage self-consciousness",
    "Undesirable marks?",
    "Pimply outbreak",
    "Promgoer's concern, maybe",
    "Bumps on the path to adulthood?",
    "Dermatologist's target",
    "Oil crisis?",
    "What ancient Egyptians treated with honey",
    "Skin problem",
    "Target of an astringent",
    "Rough spots?",
    "Effect of surplus oil",
    "Red facial spots",
    "Rough spots for teens?",
    "Result of an oil surplus",
    "Oil crisis?",
    "Teenager's woe",
    "Problem to face?",
    "Embarrassing spots?",
    "Doxycycline target",
    "Teenage skin malady",
    "Breakout of a sort",
    "Something to clear up?",
    "Teen woe",
    "Pimples",
    "Certain eruption",
    "Proactiv target",
    "Clearasil target",
    "Woe for some 51-Acrosses",
    "Something cleared up by Clearasil",
    "Proactiv target",
    "Teen's affliction",
    "Bumps on the head?",
    "Proactiv treats it",
    "Teen spots",
    "Spots to hide?",
    "Worrisome marks in high school?",
    "Red in the face?",
    "Cause of date night stress",
    "Complexion changer",
    "Something no one wants to face?",
    "Some red marks",
    "Bad marks in high school?",
    "Clearasil target",
    "Marks of adolescence",
    "Retin-A treats it",
    "Teenager's bane",
    "Spots annoying teens",
    "Teen headache",
    "___ cream",
    "Proactiv target",
    "See 1-Across",
    "Trouble spots?",
    "Middle school marks?",
    "Case that may be treated with cream",
    "Some minor eruptions",
    "Side effect of steroid use",
    "Oxy 10 target",
    "High-school spots?",
    "Oil deposit problem",
    "Some red spots",
    "Adolescent breakout",
    "Problem to face?",
    "Cause of some teen angst",
    "Skin woe",
    "Neutrogena target",
    "Reason to use Clearasil",
    "It can make the face red",
    "Unwanted spots",
    "Trouble spots?",
    "Reason to use Retin-A",
    "Proactiv treats it",
    "Oxy 5 target",
    "Indication of teen stress, maybe",
    "Teen breakout",
    "Unwanted spots",
    "What adolescents may fight",
    "Situation a teen wants cleared up?",
    "Prom night worry",
    "Bad marks gotten in high school?",
    "This might make you red in the face",
    "Teen affliction",
    "Dots over eyes?",
    "Benzoyl peroxide target",
    "Result of some oil deposits",
    "Retin-A treats it",
    "Unwanted spots",
    "Adolescent outbreak",
    "Juvenile development",
    "It might make you red in the face",
    "Bad marks?",
    "Rough condition to face?",
    "Dreaded prom night sight",
    "Adolescent outbreak",
    "Unwanted spots",
    "Adolescent outburst",
    "It can leave you red-faced",
    "Teen annoyance",
    "Teen trouble",
    "Stridex target",
    "Clearasil target",
    "Bad spots?",
    "Clearasil target",
    "Stridex target",
    "General breakout",
    "Retin-A treats it",
    "Tough problem to face?",
    "Juvenile outbreak",
    "Retin-A treats it",
    "Face trouble",
    "Unwanted spots",
    "Spots before the eyes?",
    "Adolescent's outbreak",
    "Teen worry",
    "Dermal flareup",
    "Skin breakout",
    "Skin woe",
    "Teen spots?",
    "Bad spots for a date",
    "Retin-A treats it",
    "Unappealing skin condition",
    "Stridex target",
    "Skin outbreak",
    "Clearasil target",
    "Bad spots",
    "Something that needs to be cleared up",
    "Facial flare-up",
    "Teen spots?",
    "Result of an oil surplus?",
    "Adolescent development",
    "Stridex target",
    "Teen spots?",
    "Trouble spots for teens",
    "Clearasil target",
    "Pockmark cause",
    "High school outbreak?",
    "Bad marks",
    "High school breakout",
    "Teen spots",
    "Teen affliction",
    "Teen spots?",
    "Rough problem to face?",
    "High school breakout",
    "Trouble spots?",
    "Clearasil target",
    "It's breaking out",
    "Teenage woe",
    "Target of some creams",
    "Teen spots",
    "It may be seen breaking out",
    "Bad marks",
    "Unpopular spots",
    "Teen-y problem",
    "Unpopular spots",
    "Bad spots?",
    "Certain breakout",
    "Teen woe",
    "Tetracycline target",
    "Dermatologist's case",
    "Teen woe",
    "Teen worry",
    "Clearasil target",
    "Oxy-5 target",
    "Teen trial",
    "Breakout, of a sort",
    "Spots for teens?",
    "Teen tormentor",
    "Clearasil target",
    "Teen woe",
    "Bad marks?",
    "Spots on the face",
    "Teen woe",
    "Benzoyl peroxide target",
    "High school problem",
    "Teen bugbear",
    "Teen trauma",
    "An embarrassing problem to face?"
  ],
  "dens": [
    "Hangout rooms",
    "Lairs",
    "Where bears hibernate",
    "Studies",
    "Retreats",
    "Places to play cards, often",
    "Hibernation stations",
    "Cozy retreats",
    "Spots for wet bars",
    "Spots in which to lie low",
    "Places of iniquity",
    "Cub Scout units",
    "Homes for cubs",
    "Places to sleep",
    "Homes in the woods",
    "Places for trophies",
    "Homes in the woods",
    "Scout groups",
    "Some refuges",
    "Cubs' places",
    "Places to hibernate",
    "Places to hibernate",
    "Bears' homes",
    "Paneled rooms, maybe",
    "Animal shelters",
    "Scouting groups",
    "Fox network?",
    "Men's studies?",
    "TV rooms",
    "Studies",
    "Entertainment centers, maybe",
    "Studies",
    "TV spots",
    "Lairs",
    "Retreats",
    "Burrows, e.g.",
    "Many man caves",
    "Places to relax",
    "Paneled rooms, often",
    "TV rooms",
    "Rooms with recliners",
    "Places for La-Z-Boys",
    "Retreats",
    "Comfy retreats",
    "Hideaways",
    "Places to put your feet up",
    "Places to sleep",
    "Shelters",
    "Hollows",
    "Vulpine abodes",
    "TV rooms",
    "Family rooms",
    "Retreats",
    "Burrows",
    "Homes in the woods",
    "Retreats",
    "Lions' lairs",
    "Animal shelters",
    "Retreats",
    "Bears' lairs",
    "Poker game locales",
    "Family rooms",
    "Caves",
    "Cub Scout groups",
    "Scout groups",
    "Trophy rooms",
    "Some holes in the ground",
    "Reading areas",
    "Studies",
    "Wolves' creations",
    "Homes in the hills",
    "TV rooms",
    "Cub Scout groups",
    "Places for trophy displays",
    "TV sites",
    "Studies",
    "Retreats",
    "Book-lined rooms",
    "Lairs",
    "Iniquity locales",
    "Cub Scout groups",
    "Lairs",
    "Burrows",
    "Scout groups",
    "Game rooms",
    "TV rooms",
    "Retreats",
    "Hollows",
    "TV rooms",
    "Scouting units",
    "Cubs' hangouts",
    "Caves",
    "Fox homes",
    "Couch potatoes' spots",
    "Retreats",
    "Studies",
    "Scout groups",
    "Cubs' hangouts",
    "Trophy rooms",
    "TV rooms",
    "Libraries",
    "Book-lined rooms",
    "Retreats",
    "Cub groups"
  ],
  "trio": [
    "The Powerpuff Girls, e.g.",
    "Three for the show?",
    "Bell Biv DeVoe or Bananarama",
    "Threesome",
    "More than a pair",
    "Half a sextet",
    "The Powerpuff Girls, e.g.",
    "The Jonas Brothers or Dixie Chicks",
    "Small jazz group",
    "The band Ben Folds Five, oddly",
    "Quartet minus one",
    "Athos, Porthos and Aramis, e.g.",
    "Wynken, Blynken and Nod, e.g.",
    "Snap, Crackle and Pop, e.g.",
    "Nirvana, e.g.",
    "Sextet halved",
    "Chamber music group, often",
    "Donald Duck's nephews, e.g.",
    "Peter, Paul and Mary, e.g.",
    "Witches in \"Macbeth,\" e.g.",
    "Huey, Dewey and Louie, e.g.",
    "Alvin and the Chipmunks, e.g.",
    "The biblical wise men, by tradition",
    "Rock's Cream was one",
    "The Supremes, e.g.",
    "The Gabor sisters, e.g.",
    "Half of a sextet",
    "Duo plus one",
    "Athos, Porthos and Aramis, e.g.",
    "Huey, Dewey and Louie, e.g.",
    "Many a chamber piece",
    "The Bee Gees, for much of their career",
    "Caspar, Melchior and Balthazar, e.g.",
    "The Magi, e.g.",
    "Quartet minus one",
    "Threesome",
    "Donald Duck's nephews, e.g.",
    "Jazz combo, often",
    "The Dixie Chicks, e.g.",
    "The Marx Brothers, often",
    "Rock's Police or Cream, e.g.",
    "Small combo",
    "\"Jeopardy!\" contestants, e.g.",
    "Peter, Paul & Mary, e.g.",
    "Cream, for instance",
    "Destiny's Child, e.g.",
    "Lady Antebellum, e.g.",
    "ZZ Top, for one",
    "TLC, e.g.",
    "The Rice Krispies mascots, e.g.",
    "The Ronettes, e.g.",
    "Cream, for example",
    "TLC, e.g.",
    "The Jonas Brothers, e.g.",
    "Threesome ... or a hint to this puzzle's theme",
    "Snap, Crackle and Pop, e.g.",
    "Duo + one",
    "Charlie's Angels, e.g.",
    "Quartet minus one",
    "Rock's Cream, e.g.",
    "Charlie's Angels, e.g.",
    "Wynken, Blynken and Nod, e.g.",
    "The Stooges, e.g.",
    "The Magi, e.g.",
    "Cream, e.g.",
    "Rock music's Rush, for one",
    "Chamber group, often",
    "The witches in \"Macbeth,\" e.g.",
    "The Jimi Hendrix Experience, e.g.",
    "Chad Mitchell ___ (1960s folk band)",
    "Duo plus one",
    "The Brady boys or girls",
    "Alvin and the Chipmunks, e.g.",
    "The Ghostbusters, e.g.",
    "The Stooges, e.g.",
    "Stooges, e.g.",
    "ZZ Top, e.g.",
    "Charlie's Angels, e.g.",
    "String ___",
    "Small combo",
    "Chamber music piece",
    "ZZ Top, e.g.",
    "The Police, e.g.",
    "Rock's Green Day, for one",
    "The witches in \"Macbeth,\" e.g.",
    "Peter, Paul and Mary, e.g.",
    "Rock's Green Day, e.g.",
    "Peter, Paul and Mary, e.g.",
    "Huey, Dewey and Louie, e.g.",
    "Many a jazz combo",
    "Quartet, after a defection",
    "Charlie's Angels, e.g.",
    "Wynken, Blynken and Nod, e.g.",
    "ZZ Top, musically",
    "The Fleetwoods, for example",
    "Jazz group, often",
    "Threesome",
    "Small combo",
    "Small combo",
    "Cream was one",
    "Small combo",
    "Peter, Paul and Mary, e.g.",
    "Half a sextet",
    "Haydn string composition",
    "The Dixie Chicks, e.g.",
    "The Bee Gees, e.g.",
    "Duet plus one",
    "The Stooges, for instance",
    "Backup singers, often",
    "The Police, e.g.",
    "The Supremes, e.g.",
    "Rock's Cream, e.g.",
    "Duet plus one",
    "Rock's Cream, for instance",
    "The Stooges, e.g.",
    "Donald Duck's nephews, e.g.",
    "Cream, of 1960's rock",
    "Wynken, Blynken and Nod, e.g.",
    "The Magi, e.g.",
    "Quartet minus one",
    "Peter, Paul and Mary, e.g.",
    "Minuet follower",
    "Small combo",
    "Pop music's TLC, e.g.",
    "Threesome",
    "Country music's Dixie Chicks, e.g.",
    "Beethoven's \"Archduke ___\"",
    "Dumas's musketeers, for example",
    "Small group of carolers",
    "Quartet after a desertion",
    "Dumas's Musketeers, e.g.",
    "Pop music's Hanson, e.g.",
    "Andrews Sisters, e.g.",
    "Fiddlers for 33-Across, e.g.",
    "Donald Duck's nephews, e.g.",
    "The Fleetwoods, e.g., of 50's-60's pop",
    "The Fates, e.g.",
    "The Bee Gees, e.g.",
    "Beethoven's \"Archduke ___\"",
    "Tom, Dick and Harry, e.g.",
    "Beethoven piano piece",
    "The Magi, e.g.",
    "Run-D.M.C., e.g.",
    "Crosby, Stills and Nash, e.g.",
    "Crosby, Stills and Nash, e.g.",
    "The Supremes, e.g.",
    "Chamber group, maybe",
    "Jazz combo, often",
    "The Supremes, e.g.",
    "Rock music's Police, e.g.",
    "38-Down, for example",
    "Rap music's TLC, e.g.",
    "Threesome",
    "Rock music's Police, e.g.",
    "The Andrews Sisters, e.g.",
    "Wynken, Blynken and Nod, e.g.",
    "Rock's ZZ Top, e.g.",
    "Lounge group, maybe",
    "Fates, e.g.",
    "Threesome",
    "Rock's Salt-N-Pepa, e.g.",
    "Rap's Salt-N-Pepa, e.g.",
    "50-Down, e.g.",
    "Andrews Sisters, e.g.",
    "Wynken, BIynken and Nod, e.g.",
    "The Magi, e.g.",
    "The Bee Gees, e.g."
  ],
  "maim": [
    "Seriously hurt",
    "Seriously hurt",
    "Hurt badly",
    "Really hurt",
    "Seriously injure",
    "Seriously wound",
    "Seriously injure",
    "Hurt badly",
    "Cripple",
    "Unhand or disarm?",
    "Injure badly",
    "Kneecap, e.g.",
    "More than mar",
    "Hurt badly",
    "Seriously hurt",
    "Incapacitate",
    "Incapacitate",
    "Injure severely",
    "Injure",
    "Hurt badly",
    "Wing, e.g.",
    "Cripple",
    "Seriously injure",
    "Wing, in a way",
    "Wing, say",
    "Injure seriously",
    "Cripple",
    "Hurt severely",
    "Disfigure",
    "Cripple",
    "Hurt",
    "Mangle",
    "Critically injure",
    "Disable",
    "Wing, say",
    "Injure",
    "Seriously wound",
    "Hurt badly",
    "Injure",
    "Impair",
    "Disable",
    "Kneecap, e.g.",
    "Impair",
    "Disfigure",
    "Impair",
    "Incapacitate",
    "Wound"
  ],
  "loci": [
    "Focal points",
    "Sets of points, in math",
    "Sets of points, in math",
    "Sets of points on graphs",
    "Sets of plotted points",
    "Sets of points on graphs",
    "Sets of points, in math",
    "Important positions",
    "Central points",
    "Particular points",
    "Focal points",
    "Centers of activity",
    "Centers of attention",
    "Positions",
    "Sets of points, mathematically",
    "Sets in geometry",
    "Many curves, in math",
    "Graph points",
    "Places",
    "Centers of activity",
    "Points",
    "Places",
    "Sets of points, in math",
    "Collections of points in math",
    "Math sets",
    "Centers of activity",
    "Points",
    "Venues",
    "Places",
    "Places",
    "Geometric sets",
    "Centers of activity",
    "Places",
    "Centers of activity",
    "Hubs",
    "Centers of activity",
    "Spots",
    "Sets of points",
    "Centers of activity",
    "Sites",
    "Points",
    "Graph points",
    "Points on a math test?",
    "Places",
    "Mathematical sets",
    "Centers of activity",
    "Mathematical sets of points",
    "Points",
    "Places",
    "Points"
  ],
  "neck": [
    "Guitar part",
    "Head turner",
    "Narrow part of a bottle",
    "Part of a guitar",
    "Make out",
    "Pet",
    "Narrow part",
    "Head turner?",
    "Spoon",
    "Make out",
    "Adam's apple locale",
    "Part of a bottle",
    "Make out",
    "Part bitten by a vampire",
    "Guitar part",
    "Make out",
    "Make out",
    "Canoodle",
    "Make out",
    "Head turner",
    "Bottle part",
    "Locale for an Adam's apple",
    "Isthmus",
    "Spoon",
    "Make out",
    "Chicken part that's good for soup",
    "Part of a bottle or a guitar",
    "Place for a crick",
    "Make out",
    "Strings run along one",
    "Head/shoulders connector",
    "Thing to wring",
    "Thin part",
    "Win margin",
    "Part of a bottle",
    "Crick site",
    "Violin part",
    "Make out",
    "Make out",
    "Chain site",
    "Bottle part",
    "Hourglass part",
    "Where to go for the jugular",
    "It may be stuck out",
    "Guitar part",
    "Make out",
    "Make out",
    "Isthmus",
    "Make out",
    "Giraffe's prominent feature",
    "Make out",
    "Shirt measurement",
    "Make out",
    "Spoon",
    "Item in a giblets package",
    "Spoon"
  ],
  "usaf": [
    "Letters on an F-22 Raptor",
    "The Thunderbirds are in it, for short",
    "Bomber letters",
    "Mil. branch with B-52s",
    "Service begun in 1947, for short",
    "Letters on a B-52",
    "Abbr. on a B-52",
    "Org. with the ad slogan \"It's not science fiction. It's what we do every day\"",
    "Ace's setting: Abbr.",
    "Thunderbirds' org.",
    "Falcons' home: Abbr.",
    "Falcons' grp.",
    "Stealth bomber org.",
    "B-1 letters",
    "Thunderbirds' org.",
    "B-52 org.",
    "Jet set?: Abbr.",
    "Military letters",
    "Thunderbird grp.",
    "Org. with an academy near Colo. Springs",
    "Org. with an acad. near Colo. Spr.",
    "Grp. with F-16's",
    "Flight suit stitching",
    "Mil. fliers",
    "Colorado academy grp.",
    "Defense Dept. branch",
    "Insignia on some fighters",
    "Initials at Colo. Springs",
    "B-2 letters",
    "Inits. for some pilots",
    "Letters on a B-52",
    "Flyboy's org.",
    "Letters on a B-2",
    "B-1 insignia",
    "It has its academy in Colo. Spr.",
    "Bomber initials",
    "Bomber letters",
    "Fliers' mil. branch",
    "F-111 fliers: Abbr.",
    "Colorado Springs inits.",
    "Fliers?Æ org.",
    "Colo. acad."
  ],
  "djed": [
    "Provided tunes for a party, in brief",
    "Changed one's tune, in brief?",
    "Played at work, for short",
    "Spun wax, say",
    "Played at work, informally?",
    "Played at a party, say",
    "Spun records, say",
    "Handled tunes at a dance, say",
    "Provided the music for a party, informally",
    "Entertained at a reception, maybe",
    "Handled the music at a rave",
    "Played records at a party",
    "Played records at a party, say"
  ],
  "norm": [
    "Benchmark",
    "Good name for an average Joe",
    "About 460 inches of rain per year, on Kauai's Mt. Waialeale",
    "Standard",
    "Societal expectation",
    "It's what you would expect",
    "Nothing special, with \"the\"",
    "Middle of a bell curve",
    "It's to be expected",
    "Standard",
    "Average guy?",
    "What to expect",
    "Standard",
    "What you can expect",
    "It's so typical",
    "It's no surprise",
    "Expected amount",
    "What par isn't for most golfers",
    "It's to be expected",
    "Standard",
    "Christie novel title that, without spaces, is a man's name",
    "It's what's to be expected",
    "An I.Q. of about 100, e.g.",
    "What's typical",
    "Unsurprising outcome",
    "Usual figure",
    "What's expected",
    "Expected outcome",
    "Abram of \"This Old House\"",
    "Only patron on \"Cheers\" to appear in all 275 episodes",
    "What's expected",
    "Having two or three kids in a family, nowadays",
    "Usual",
    "\"Cheers\" barfly",
    "Rule",
    "What's expected",
    "Par for the course",
    "\"Cheers\" character",
    "Standard",
    "It's typical",
    "What's expected",
    "Average",
    "It's not out of the ordinary",
    "Benchmark",
    "Name for an average guy?",
    "Standard",
    "Standard",
    "What's expected",
    "Typical amount",
    "Bell curve peak",
    "100 for an I.Q.",
    "Standard",
    "Standard",
    "Average guy?",
    "What is expected",
    "\"Cheers\" regular",
    "Benchmark",
    "Benchmark",
    "Test standard",
    "Comparison figure",
    "Convention",
    "Standard",
    "\"Cheers\" regular",
    "Vera's TV husband",
    "100, I.Q.-wise",
    "Benchmark",
    "Group standard",
    "Math calculation",
    "Standard",
    "\"Cheers\" character",
    "Model",
    "\"Cheers\" patron",
    "\"Cheers\" stoolie?",
    "Average guy?",
    "Standard",
    "\"Cheers\" habitue",
    "Standard",
    "\"Cheers\" role",
    "\"Cheers\" habitue"
  ],
  "itoo": [
    "Classic Langston Hughes poem",
    "\"Am ___ late?\"",
    "Opening of a classic Langston Hughes poem",
    "Classic Langston Hughes poem",
    "\"Am ___ sensitive?\"",
    "\"___, sing America\" (start of a Langston Hughes poem)",
    "Classic Langston Hughes poem",
    "\"Me as well\"",
    "Hughes poem that mentions \"the darker brother\"",
    "\"Was ___ hard on them?\"",
    "\"Am ___ early?\"",
    "Classic Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem with the lines \"Nobody'll dare / Say to me, / 'Eat in the kitchen,' / Then\"",
    "Langston Hughes poem with the lines \"They send me to eat in the kitchen / When company comes\"",
    "\"Am ___ late?\"",
    "Poem referencing \"the darker brother\"",
    "Langston Hughes poem",
    "\"Am ___ fat?\"",
    "\"Was ___ hard ...?\"",
    "\"___, sing America\": Langston Hughes",
    "Poem with the lines \"Nobody'll dare / Say to me, / 'Eat in the kitchen'\"",
    "Langston Hughes poem",
    "\"Am ___ late?\"",
    "Langston Hughes poem",
    "Hughes poem with the line \"They send me to eat in the kitchen\"",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "\"___, dislike it\" (start of Marianne Moore's \"Poetry\")",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Words of agreement",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Words of agreement",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Langston Hughes poem",
    "Classic Langston Hughes poem"
  ],
  "coco": [
    "First name in fashion",
    "Team ___ (late-night host's following)",
    "2017 Pixar hit",
    "Chanel perfume",
    "Pixar film that premiered in Mexico",
    "Animated feature Oscar winner after \"Zootopia\"",
    "Fashion designer Chanel",
    "First name in fragrances",
    "2017 Disney/Pixar film set in the Land of the Dead",
    "Popular 2017 Pixar film set in Mexico",
    "Chanel of perfume fame",
    "Hit 2017 computer-animated film ... or a hint to 20-, 27-, 49- and 58-Across",
    "Designer Chanel",
    "First name in fashion",
    "Designer Chanel",
    "Conan nickname",
    "O'Brien's Team ___",
    "Conan O'Brien's Team ___",
    "Madame Chanel",
    "First name in fashion",
    "Emmy-winning character actor James",
    "First name in fragrances",
    "Fashion's Chanel",
    "Popular fragrance from France",
    "Designer Chanel",
    "Chanel of fashion",
    "Designer Chanel",
    "Chanel of fashion",
    "1969 Broadway musical",
    "Chanel of fashion",
    "\"Only When I Laugh\" actor James",
    "Chanel fragrance",
    "First name in fashion",
    "Designer Chanel",
    "First name in fashion",
    "First name in perfumery"
  ],
  "mutt": [
    "Many a rescue dog",
    "A little of this, a little of that",
    "One might be measured by the pound",
    "Mixed-breed dog",
    "Unlikely entrant in a Westminster show",
    "Many a rescue",
    "Mix",
    "What you might call a dog",
    "Half-and-half, maybe",
    "Dog that's a little of this, a little of that",
    "Labradoodle, e.g.",
    "Cockerdoodle, e.g.",
    "Kennel club reject, no doubt",
    "It's part this, part that",
    "Mongrel",
    "Unregistered sort",
    "Mixed breed",
    "Half-and-half, maybe",
    "Dog that's a little of this, a little of that",
    "Unlikely dog for a canine registry",
    "Kennel club rejectee",
    "Not the finest dog",
    "No purebred",
    "Cross that's not well regarded",
    "A purebred it's not",
    "No Westminster contender",
    "Bud Fisher cartoon character",
    "American Kennel Club outcast"
  ],
  "clog": [
    "Back up",
    "Target of a plumber's snake",
    "Problem for a plumber",
    "Snake target",
    "Pipe problem",
    "Put a stop to?",
    "Plumbing problem",
    "Stopping point?",
    "Traffic problem",
    "Target for a snake",
    "Drano target",
    "Plumbing problem",
    "Gutter problem",
    "Jam up",
    "Drain cleaner target",
    "Job for a snake",
    "Pipe problem",
    "Jam",
    "Drano target",
    "Cousin of a 55-Down",
    "Footwear that's hard to run in",
    "Backup cause",
    "What paper towels do to a toilet",
    "Block",
    "Job for a drain cleaner",
    "Blockage",
    "Thick-soled shoe",
    "Drano target",
    "Flow stopper",
    "Flow stopper",
    "Plunger's target",
    "Drain problem",
    "Folk dancer's shoe",
    "Plug up",
    "Block",
    "Drain problem",
    "Stop (up)",
    "Stop up"
  ],
  "todd": [
    "Christine ___ Whitman, first female governor of New Jersey",
    "Mary Lincoln's maiden name",
    "Newsman Chuck",
    "Newsman Chuck",
    "\"Meet the Press\" host Chuck",
    "Chuck of \"Meet the Press\"",
    "Newsman Chuck",
    "Mary Lincoln's maiden name",
    "Chuck of NBC News",
    "Sondheim's \"Sweeney ___\"",
    "Chuck of \"Meet the Press\"",
    "Mrs. Lincoln's maiden name",
    "Mary Lincoln, nee ___",
    "Chuck of NBC News",
    "One of the Palins",
    "Rocker Rundgren",
    "Sarah Palin's husband",
    "Former E.P.A. chief Christine ___ Whitman",
    "Liz Taylor's husband before Fisher",
    "Sondheim's \"Sweeney ___\"",
    "Sondheim protagonist",
    "Mary who married a future president",
    "\"Sweeney ___\"",
    "Mary in the White House",
    "See 17-Across",
    "Broadway's \"Sweeney ___\"",
    "Mary in the White House",
    "Rock's Rundgren",
    "Mary ___ Lincoln",
    "The fox in Disney's \"The Fox and the Hound\"",
    "Mike who produced \"Around the World in 80 Days\"",
    "Taylor's third",
    "Sondheim's \"Sweeney ___\"",
    "Mrs. Lincoln's maiden name",
    "Mrs. Lincoln's maiden name",
    "One of Taylor's eight",
    "See 16-Across",
    "Abe's Mary",
    "Elizabeth Taylor's third",
    "Sondheim's Sweeney",
    "Lincoln in-law",
    "Mrs. Lincoln's maiden name",
    "Thelma of \"The Maltese Falcon,\" 1931",
    "One First Lady's maiden name",
    "Taylor's third",
    "Liz Taylor's third",
    "Tennis star ___ Martin",
    "Sondheim's \"Sweeney ___\"",
    "Lincoln in-law",
    "Taylor?Æs third",
    "One of Taylor's exes",
    "Lincoln in-law",
    "Lincoln in-law"
  ],
  "gday": [
    "Victorian greeting",
    "Greeting Down Under",
    "Greeting in Victoria",
    "Aussie greeting",
    "Oz salutation",
    "Down Under greeting",
    "Aussie \"Mornin'!\"",
    "Barbie greeting",
    "Greeting in Oz",
    "Sheila's welcome",
    "Victorian's greeting",
    "\"Hello\" Down Under",
    "Aussie greeting",
    "2000 Olympics greeting",
    "Hello from Canberra",
    "___ : hello :: hooroo : goodbye",
    "Mate's hello",
    "Mate's cry",
    "Aussie greeting",
    "Greeting for a mate"
  ],
  "uris": [
    "\"Exodus\" novelist",
    "\"QB VII\" author Leon",
    "Author Leon",
    "\"Mila 18\" novelist",
    "\"QB VII\" author, 1970",
    "\"Exodus\" author",
    "See 50-Down",
    "\"Mila 18\" novelist",
    "\"Topaz\" novelist, 1967",
    "Author Leon",
    "Leon who wrote \"The Haj\"",
    "\"Topaz\" author Leon",
    "\"O'Hara's Choice\" novelist",
    "20th-century novelist whose first name is an anagram of 66-Down",
    "\"The Haj\" author",
    "\"Trinity\" novelist",
    "\"Trinity\" novelist",
    "\"A God in Ruins\" novelist",
    "Leon who wrote \"Exodus\"",
    "Literary Leon",
    "\"O'Hara's Choice\" novelist, 2003",
    "\"Trinity\" author",
    "\"Exodus\" author",
    "\"Exodus\" author",
    "Author of \"Trinity\"",
    "\"Topaz\" author",
    "\"A God in Ruins\" novelist, 1999",
    "\"Redemption\" novelist, 1995",
    "\"Exodus\" author",
    "\"Exodus\" author Leon",
    "\"QB VII\" novelist",
    "\"Trinity\" author",
    "\"Trinity\" author",
    "\"The Haj\" author",
    "Leon ___, who wrote \"Mila 18\"",
    "\"Mila 18\" author",
    "\"Battle Cry\" author",
    "\"Topaz\" author",
    "Writer who created the writer Gideon Zadok",
    "\"Armageddon\" author",
    "\"Exodus\" author",
    "\"Topaz\" author",
    "Novelist who wrote the screenplay for \"Gunfight at the O.K. Corral\"",
    "\"Battle Cry\" author",
    "\"The Haj\" novelist",
    "\"Mila 18\" author",
    "\"Exodus\" author",
    "\"Mitla Pass\" author",
    "\"Battle Cry\" novelist",
    "\"QB VII\" author",
    "\"Mitla Pass\" author",
    "\"Exodus\" author",
    "\"The Haj\" author",
    "\"Battle Cry\" novelist",
    "\"A God in Ruins\" novelist",
    "\"The Haj\" author",
    "\"A God in Ruins\" novelist",
    "\"Trinity\" novelist",
    "\"Mila 18\" author",
    "\"The Haj\" writer",
    "\"A God in Ruins\" novelist",
    "\"Exodus\" author",
    "\"Exodus\" author",
    "\"QB VII\" author",
    "\"Trinity\" author",
    "\"Exodus\" author",
    "\"QB VII\" author",
    "\"Exodus\" author",
    "\"Mitla Pass\" author",
    "\"Redemption\" author",
    "\"Exodus\" author",
    "\"Mitla Pass\" author",
    "\"Trinity\" author",
    "\"Trinity\" author",
    "\"Battle Cry\" author",
    "\"Mila 18\" novelist",
    "\"Trinity\" novelist",
    "\"Trinity\" author",
    "\"Exodus\" author",
    "\"Topaz\" author",
    "\"Exodus\" novelist",
    "\"Trinity\" author",
    "\"QB VII\" author",
    "?ôBattle Cry?ö author",
    "\"QB VII\" author",
    "\"The Haj\" author",
    "?ôThe Haj?ö novelist",
    "\"Mila 18\" author",
    "\"QB VII\" author",
    "\"The Haj\" author",
    "\"Trinity\" author"
  ],
  "gait": [
    "Dressage criterion",
    "Trot or canter",
    "Dressage concern",
    "Shamble, e.g.",
    "Trot or canter",
    "It may be jaunty",
    "Trot or lope",
    "Pace",
    "Way to go",
    "Walk, e.g.",
    "Walk or trot",
    "Trot or canter",
    "Clip",
    "John Wayne had a distinctive one",
    "Trot or canter",
    "Trot or canter",
    "Amble, e.g.",
    "Walk, e.g.",
    "Walk or trot",
    "Manner of going",
    "Jog, say",
    "Way to go",
    "Saunter, e.g.",
    "Horse's motion",
    "Trot or canter",
    "Horse's pace",
    "Way to go",
    "Amble or shamble",
    "Trot or canter",
    "Walk way?",
    "Trot or canter",
    "Walk, trot or canter",
    "Trot or canter, e.g.",
    "Dressage factor",
    "Carriage",
    "Subject of equitation"
  ],
  "deja": [
    "Already: Fr.",
    "___ vu",
    "Already: Fr.",
    "___ vu",
    "___ vu",
    "Already: Fr.",
    "Lead-in to \"vu\"",
    "___ vu",
    "___ vu",
    "___ vu",
    "Already: Fr.",
    "___ vu",
    "___ vu",
    "___ vu",
    "___ vu",
    "___ vu",
    "___ vu",
    "\"It's ___ vu all over again\" (Yogiism)",
    "___ vu",
    "Already in France?",
    "___ vu",
    "Already, in Arles",
    "___ vu",
    "___ vu",
    "___ vu",
    "Already in Paris",
    "___ vu",
    "___ vu",
    "___ vu",
    "___ vu",
    "\"It was ___ vu all over again\"",
    "___ vu",
    "Already, in Allier",
    "\"It's ___ vu all over again\": Yogi Berra",
    "___ vu",
    "___ vu"
  ],
  "coos": [
    "Acts lovey-dovey",
    "This is what it sounds like when doves cry",
    "Dove sounds",
    "Dove sounds",
    "Dove calls",
    "Parrots a pigeon?",
    "Dove sounds",
    "Pillow talk?",
    "Bird sounds",
    "Talks romantically",
    "Talks lovingly",
    "Says lovingly",
    "*Cote calls",
    "Whispers sweet nothings",
    "___ Bay, Ore.",
    "Whispers sweet nothings",
    "Lovers' exchanges",
    "Engages in baby talk",
    "Talks amorously",
    "Baby sounds",
    "Lover's sounds"
  ],
  "cede": [
    "Give up",
    "Turn over",
    "Hand over",
    "Give up",
    "Opposite of annex",
    "Let go of",
    "Let go of",
    "Give up",
    "Surrender",
    "Give up",
    "Give up",
    "Turn over",
    "Grant",
    "Hand over",
    "Let go of",
    "Give way",
    "Relinquish",
    "Give up",
    "Turn over",
    "Let go of",
    "Give up",
    "Relinquish",
    "Relinquish",
    "Turn over",
    "Surrender",
    "Give over",
    "Give up",
    "Surrender",
    "Give up",
    "Hand over",
    "Surrender",
    "Surrender",
    "Turn over",
    "Give up",
    "Turn over (to)",
    "Give up",
    "Surrender",
    "Surrender",
    "Give up",
    "Yield",
    "Give up",
    "Turn over",
    "Relinquish",
    "Turn over",
    "Grant",
    "Give up",
    "Hand over",
    "Surrender",
    "Opt to drop",
    "Give up, as rights",
    "Grant",
    "Turn over",
    "Surrender",
    "Relinquish",
    "Relinquish",
    "Yield",
    "Turn over",
    "Give up",
    "Turn over",
    "Give up",
    "Give up",
    "Turn over",
    "Let go",
    "Turn over",
    "Turn over",
    "Grant",
    "Grant",
    "Give",
    "Turn over",
    "Hand over",
    "Give up",
    "Give up, as territory",
    "Turn over",
    "Give up",
    "Give up",
    "Hand over",
    "Turn over",
    "Grant",
    "Sign over, as rights",
    "Turn over",
    "Hand over",
    "Turn over",
    "Let go",
    "Grant",
    "Let have",
    "Hand over (to)",
    "Grant",
    "Give over",
    "Give up",
    "Give up",
    "Hand over",
    "Hand over",
    "Relinquish",
    "Yield",
    "Give up",
    "Give up",
    "Give up",
    "Turn over",
    "Grant",
    "Renounce",
    "Sign away",
    "Relinquish",
    "Give up",
    "Turn over",
    "Give up",
    "Transfer",
    "Relinquish",
    "Hand over",
    "Relinquish",
    "Grant",
    "Give up",
    "Give up",
    "Surrender",
    "Yield",
    "Surrender",
    "Transfer, as property",
    "Yield",
    "Relinquish",
    "Yield",
    "Yield",
    "Sign away",
    "Turn over",
    "Grant",
    "Yield",
    "Sign over",
    "Part with"
  ],
  "boor": [
    "Insensitive sort",
    "Oaf",
    "Crass, classless sort",
    "Uncouth person",
    "Yahoo",
    "Trollish sort",
    "Vulgarian",
    "Vulgar sort",
    "Oafish sort",
    "Uncouth one",
    "Lout",
    "Unmannered sort",
    "Unsuave sort",
    "Unrefined sort",
    "Uncouth sort",
    "Socially inept sort",
    "Knuckle-dragger",
    "Bad party guest",
    "Unmannered fellow",
    "Crude sort",
    "Unwelcome guest",
    "Crude dude",
    "Insensitive sort",
    "Unpleasant guest",
    "Rude person",
    "Philistine",
    "Vulgarian",
    "Lout",
    "Yahoo",
    "Rude one",
    "Yahoo",
    "Galoot",
    "Churl",
    "Vulgarian",
    "Yokel",
    "Yahoo"
  ],
  "indy": [
    "Ford vehicle, familiarly",
    "Annual May race, informally",
    "___ 500",
    "Midwest capital, informally",
    "Annual race, for short",
    "Archaeologist played by Harrison Ford, informally",
    "\"500\" race, familiarly",
    "Midwest capital, to locals",
    "Ark finder, familiarly",
    "Annual \"500\"",
    "___ 500",
    "Annual race, colloquially",
    "Memorial Day race, informally",
    "Hoosier capital, informally",
    "May race, informally",
    "May event, informally",
    "Ford role, familiarly",
    "Four-time Harrison Ford film role",
    "___ 500",
    "Movie hero with a fedora, familiarly",
    "Annual May event, informally",
    "Annual racing classic",
    "May race, familiarly",
    "___ 500",
    "1981 adventure film hero",
    "1980's movie hero, informally",
    "Dr. Jones, to friends",
    "Memorial Day weekend event, briefly",
    "May event, for short",
    "Annual May event, for short",
    "Home of the Brickyard 400",
    "May event, for short",
    "___ 500",
    "Memorial Day weekend event, for short",
    "Sporting event since 1911",
    "Race place, familiarly",
    "May race, informally",
    "500 spot",
    "Memorial Day event, for short",
    "500 spot",
    "Race place",
    "Race place",
    "Annual Memorial Day event",
    "Memorial Day race, informally",
    "Sports happening since 1911",
    "Memorial Day weekend event",
    "___ 500",
    "___ 500",
    "Memorial Day weekend event"
  ],
  "part": [
    "Splitting of hairs?",
    "Something tried out for in a tryout",
    "Auditioner's hope",
    "Line usually on the left or right side",
    "Component",
    "Thespian's quest",
    "It may be just a bit",
    "Cameo, for one",
    "Line at a barbershop",
    "Go separate ways",
    "Tenor in a barbershop quartet, e.g.",
    "Go separate ways",
    "Role",
    "Reader's goal",
    "Cameo, for one",
    "Auditioner's hope",
    "A comb makes one",
    "Head line?",
    "Break up",
    "Bass in a barbershop quartet, e.g.",
    "Segment",
    "Thespian's aim",
    "Goal of a screen test",
    "Line formed at a barbershop?",
    "Hair line?",
    "Reader's goal",
    "Role",
    "A comb makes one",
    "Hair line",
    "Sunder",
    "Hair splitter",
    "Headline?",
    "Role to play",
    "Role",
    "Goal of a reading",
    "Result of hair-splitting?",
    "Split",
    "Role",
    "Hair line",
    "Hair-splitter?",
    "Actor's goal",
    "A quarter, e.g.",
    "I or II, e.g.",
    "It's cast",
    "Hair line",
    "Separate",
    "Hollywood job",
    "Something to read for",
    "Countertenor, maybe",
    "Divide with a comb",
    "Chapter",
    "Hair division",
    "Portion",
    "Actor's goal",
    "Auditioner's aim",
    "Take different paths",
    "Segment",
    "Role",
    "Hair splitter",
    "Thespian's quest"
  ],
  "mops": [
    "Thick manes",
    "Their heads get dirty",
    "Cleans (up)",
    "Fab Four hairdos",
    "Hairdressers' challenges",
    "Challenges for stylists",
    "Sticks in the closet?",
    "Items in buckets",
    "Beatlesque dos",
    "Does some floor work",
    "Unruly dos",
    "Objects of some hand-wringing?",
    "They may clear the deck",
    "Swabs",
    "Barbers' challenges",
    "Swabs",
    "Cleans (up)",
    "Swabs"
  ],
  "dans": [
    "Dojo levels",
    "Judo levels",
    "In: Fr.",
    "QB Marino and others",
    "Marino and others",
    "Dapper ones",
    "Fancy fellows?",
    "Aykroyd et al.",
    "Judo levels",
    "McGrew and others"
  ],
  "arne": [
    "\"Rule, Britannia\" composer",
    "___ Duncan, Obama education secretary",
    "\"A-Hunting We Will Go\" composer",
    "___ Duncan, Obama education secretary",
    "Duncan of the Obama cabinet",
    "Obama education secretary Duncan",
    "Thomas ___, \"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "Composer of \"A-Hunting We Will Go\"",
    "___ Duncan, Obama education secretary",
    "Composer Thomas",
    "___ Saknussemm, discoverer of the center of the earth in \"Journey to the Center of the Earth\"",
    "Former education secretary Duncan",
    "Duncan of Obama's cabinet",
    "Egg chair designer Jacobsen",
    "Duncan of Obama's cabinet",
    "Education secretary Duncan",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer Thomas",
    "English composer Thomas",
    "Obama education secretary Duncan",
    "\"Rule Britannia\" composer",
    "Obama education secretary Duncan",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "Obama education secretary ___ Duncan",
    "Former Minnesota governor Carlson",
    "\"Rule, Britannia\" composer",
    "His first opera was \"Rosamund\"",
    "Composer Thomas",
    "\"Rule, Britannia\" composer Thomas",
    "\"Rule, Britannia\" composer",
    "Swedish Chemistry Nobelist Tiselius",
    "Composer Thomas",
    "Composer of \"Dido and Aeneas\"",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "1948 Chemistry Nobelist Tiselius",
    "Olympic gold-medal swimmer ___ Borg",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "\"Rosamund\" composer",
    "\"Comus\" composer",
    "\"Artaxerxes\" composer",
    "\"Thomas and Sally\" composer",
    "\"Rule Britannia\" composer",
    "\"Artaxerxes\" composer",
    "Danish architect ___ Jacobsen",
    "\"Rule Britannia\" composer",
    "\"Alfred\" composer",
    "\"Rise, Glory, Rise\" composer",
    "\"Alfred\" and \"Judith\" composer",
    "\"Alfred\" composer",
    "\"Rise, Glory, Rise\" composer",
    "Composer Thomas",
    "\"Rule Britannia\" songwriter",
    "\"Judith\" composer",
    "\"Rule Britannia\" composer",
    "?ôComus?ö composer",
    "\"Alfred\" composer",
    "\"Blow, Blow, Thou Winter Wind\" composer",
    "\"Rule, Britannia\" composer",
    "Drury Lane composer of the 1700's",
    "\"Rule, Britannia\" composer",
    "Composer Thomas",
    "\"The Masque of Alfred\" composer",
    "\"Rule, Britannia\" composer",
    "\"Rule Britannia\" composer",
    "\"Alfred\" composer",
    "English composer Thomas",
    "English composer",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "Gov. Carlson of Minnesota",
    "Composer Thomas",
    "Composer Thomas",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer",
    "\"Masque of Alfred\" composer",
    "\"Rule, Britannia\" composer",
    "\"Tom Thumb\" composer",
    "\"Judith\" composer",
    "English composer Thomas",
    "Composer Thomas",
    "British music man",
    "\"Judith\" composer",
    "\"Rule, Britannia\" composer",
    "\"Rule, Britannia\" composer"
  ],
  "rica": [
    "Costa ___",
    "Costa ___",
    "Villa ___ de la Vera Cruz (historic city name)",
    "Costa ___",
    "Costa ___",
    "Costa ___",
    "Costa ___",
    "Costa ___",
    "Like a well-off senora",
    "Costa ___",
    "Like a Madrilenian millionairess",
    "Costa ___",
    "Costa ___",
    "Costa ___",
    "Villa ___ (town near Atlanta)",
    "Costa ___",
    "Villa ___, gold-mining center in 19th-century Georgia",
    "Wealthy, as a duena",
    "Costa ___",
    "Costa follower",
    "Costa ___",
    "Villa ___ (town near Atlanta)",
    "Costa ___",
    "Costa ___",
    "Costa ___",
    "Costa follower",
    "Costa ___",
    "Costa ___",
    "Costa ___",
    "Costa ___"
  ],
  "oped": [
    "The first one in The New York Times appeared in 1970",
    "Angled piece",
    "Column with an angle",
    "___ page",
    "Take in the newspaper",
    "___ piece",
    "Essay offering an alternative viewpoint",
    "Kind of column",
    "Slanted column",
    "Coup for a newspaper freelancer",
    "Bit of punditry",
    "Newspaper opinion piece",
    "Like some columns",
    "Take in the paper",
    "Piece of punditry",
    "Column that leans to one side?",
    "Slanted paper lines?",
    "Newspaper essay",
    "Newspaper piece with a viewpoint",
    "Piece designed to sway",
    "Feature usually near readers' letters",
    "___ page",
    "Piece that can go left or right",
    "Leaning column?",
    "Page with many views",
    "With 113-Down, it's full of opinions",
    "George Will piece",
    "Newspaper think piece",
    "___ page",
    "___ page",
    "Weigh-in section?",
    "Newspaper opinion piece",
    "Kind of column",
    "Slanted column?",
    "Newspaper opinion piece",
    "___ page (newspaper part)",
    "Page with convictions",
    "Essayist's newspaper piece",
    "Piece of a newspaper?",
    "Kind of column",
    "Piece of punditry",
    "Columnist's column",
    "Page with views",
    "Newspaper piece",
    "Place for an opinion",
    "Kind of column",
    "Like some columns",
    "Newspaper essay",
    "Kind of column",
    "Newspaper feature",
    "Newspaper piece",
    "Pundit's piece",
    "Kind of column",
    "Newspaper's ___ page",
    "Kind of column",
    "Not a news piece",
    "Paper feature",
    "Kind of column",
    "Guest column",
    "It's a matter of opinion",
    "Maureen Dowd piece",
    "Newspaper page",
    "Kind of page",
    "Paper piece",
    "Kind of page",
    "Kind of page",
    "Newspaper's ___ page",
    "Newspaper's essay forum",
    "Modern newspaper part",
    "Kind of column",
    "Kind of page",
    "Kind of page",
    "Essay page, for short",
    "Like some columns",
    "Kind of page",
    "Newspaper page",
    "Newspaper page for essayists",
    "___ page (place for a 9-Down)",
    "Newspaper page",
    "Kind of page",
    "___ piece (Safire column)",
    "Commentators' page",
    "Like a William Safire piece"
  ],
  "icbm": [
    "Certain warhead transport, in brief",
    "Silo filler, for short",
    "Cold War weapon, for short",
    "Titan II, e.g.",
    "Subj. of a military test",
    "Silo filler, in brief",
    "Subj. of a National Historic Site outside Wall, S.D.",
    "Titan or Atlas, for short",
    "Atlas or Titan, for short",
    "Cold War weapon inits.",
    "Transoceanic flier, briefly",
    "Minuteman III, e.g.",
    "Warhead carrier, for short",
    "Weapon with a warhead, in brief",
    "Subj. of 1991's Start treaty",
    "SALT topic",
    "Titan or Atlas, briefly",
    "Nuclear weapon delivery device, for short",
    "SALT concern",
    "Warhead weapon, for short",
    "Long-range weapon, for short",
    "Titan, e.g.",
    "Part of the U.S. arsenal",
    "Long-range weapon, for short",
    "Long-range weapon, briefly",
    "Sub's weapon, perhaps",
    "Minuteman, e.g.: Abbr.",
    "SALT concern",
    "Silo filler, for short",
    "Weapon in U.S./Russ. negotiations",
    "Minuteman, e.g.: Abbr.",
    "Warhead weapon, briefly",
    "Mil. weapon that can cross an ocean",
    "Long-range weapon, for short",
    "SALT topic",
    "SALT subject",
    "Weapon that can cross the Atl. or Pac.",
    "Titan II, perhaps",
    "Weapon in modern warfare",
    "Warhead carrier, for short",
    "Silo contents, for short",
    "Part of a nuclear arsenal, for short",
    "S.D.I. concern",
    "Part of a nuclear arsenal, for short",
    "Weapon in a silo, for short",
    "Silo occupant",
    "Nuclear weapon, for short",
    "Part of the U.S. arsenal",
    "Part of a nuclear arsenal"
  ],
  "riaa": [
    "Certifier of music sales, for short",
    "Pirate-fighting org.",
    "Org. fighting copyright infringement",
    "Gold-certifying org.",
    "Org. certifying albums as gold or platinum",
    "Lobbying org. that fights music piracy",
    "Pirate-fighting org.",
    "Antipiracy org.",
    "Gold-certifying grp.",
    "Org. that fought Napster",
    "Fighter of pirates, in brief",
    "Org. that combats illegal file sharing"
  ],
  "alka": [
    "___-Seltzer",
    "___-Seltzer",
    "___-Seltzer",
    "___-Seltzer",
    "Seltzer lead-in",
    "Seltzer starter",
    "___-Seltzer",
    "___-Seltzer",
    "___-Mints (Rolaids rival)",
    "___-Mints chewable antacid",
    "___-Seltzer",
    "___-Seltzer",
    "___-Seltzer",
    "___-Seltzer",
    "___-Seltzer",
    "___-Seltzer",
    "Seltzer starter"
  ],
  "kite": [
    "Symbol of highness",
    "High flier",
    "Toy for a windy day",
    "Toy in windy weather",
    "High flier",
    "Toy on a string",
    "Toy on a string",
    "Franklin's flier",
    "Toy with a tail",
    "Toy with a tail",
    "Item often \"eaten\" by a tree in \"Peanuts\"",
    "Toy with a tail",
    "Diamond in the sky?",
    "Charlie Brown toy that's often \"eaten\" by a tree",
    "Flier with a ground connection?",
    "One of many lost by Charlie Brown",
    "The wind unwinds it",
    "Cousin of an eagle",
    "Toy you might enjoy while running",
    "Relative of a hawk",
    "Toy at the beach",
    "March plaything",
    "Toy with a cross frame",
    "Toy sometimes seen on a beach",
    "Frequent flier?",
    "Toy with a tail",
    "End of many a string",
    "Sky box?",
    "Toy with a tail",
    "Flying toy",
    "Wind-up toy?",
    "Cousin of a hawk",
    "Decorative toy",
    "Box ___",
    "Fly (around)",
    "Airborne unit?",
    "Paper-and-string flier",
    "Item designed to be blown up?",
    "Wind instrument?",
    "Swallow-tailed bird",
    "Toy on a string",
    "Toy with a tail",
    "Franklin's flier",
    "Relative of a falcon",
    "Bad check",
    "High flier",
    "Pass, as a forged check",
    "Toy with strings attached",
    "Tom of golf",
    "Graceful bird",
    "It's found on the end of a string"
  ],
  "offs": [
    "Gets rid of, so to speak",
    "Slays, in gang talk",
    "Does a hit on",
    "Takes out",
    "Murders, mob-style",
    "Does in, in mob slang",
    "\"Takes care of\"",
    "Whacks",
    "Takes out",
    "Does in",
    "Slays, informally",
    "Whacks",
    "Does a mob hit on",
    "Does away with",
    "Does in",
    "Wastes",
    "Wastes",
    "Wastes",
    "Does in",
    "Wastes",
    "Whacks",
    "Eliminates, gangster-style",
    "Switch positions",
    "Ices",
    "Takes out, so to speak",
    "Ices",
    "Does in",
    "Kills, in slang",
    "Kills, in mob slang",
    "Rubs out",
    "Rubs out",
    "Murders, slangily",
    "Murders, mob-style",
    "Slays, in slang",
    "Switch settings",
    "Liquidates, so to speak",
    "Switch settings",
    "Rubs out",
    "Liquidates",
    "Wastes, in mob slang",
    "Many buttons",
    "Rubs out",
    "Switch settings"
  ],
  "mork": [
    "TV alien played by Robin Williams",
    "Breakout role for Robin Williams",
    "Robin Williams role that was out of this world?",
    "Sitcom alien",
    "Sitcom character who curses by shouting \"Shazbot!\"",
    "TV character who said \"K.O.\" instead of \"O.K.\"",
    "Sitcom father of Mearth",
    "TV alien",
    "Robin Williams TV character",
    "TV character first seen on \"Happy Days\"",
    "70's-80's TV alien"
  ],
  "rips": [
    "Some jeans features",
    "Copies, as from a CD",
    "Tears",
    "Tears",
    "Bad things for astronaut suits to have",
    "Tears",
    "Tears",
    "Dangerous currents",
    "Common sights at pants knees",
    "Castigates",
    "Home run swings",
    "Makes cool, in a way, as jeans",
    "Copies from CD to PC",
    "Rents",
    "Features of some jeans",
    "Slams",
    "Tatters",
    "Criticizes in no uncertain terms",
    "Criticizes harshly",
    "Steals, with \"off\"",
    "Tears",
    "Lights (into)",
    "They may be put in stitches",
    "Slangy insults",
    "Saws with the grain",
    "Places for patches",
    "Castigates",
    "Manhandles",
    "Home run swings",
    "Saws with the grain",
    "Laces (into)",
    "Tears",
    "Hightails it",
    "Criticizes hard",
    "Attacks, with \"into\"",
    "Splits",
    "Vituperates, with \"into\"",
    "Torn and tide, e.g."
  ],
  "erma": [
    "Humorist Bombeck",
    "Aretha's sister who sang \"Piece of My Heart\"",
    "Columnist Bombeck",
    "Bombeck who wrote \"At Wit's End\"",
    "Singer Franklin, Aretha's older sister",
    "Aretha Franklin's Grammy-nominated sister",
    "Funny Bombeck",
    "Bombeck who wrote \"I Lost Everything in the Post-Natal Depression\"",
    "\"At Wit's End\" humorist Bombeck",
    "Humorist Bombeck",
    "Humorous Bombeck",
    "Columnist Bombeck",
    "Wry Bombeck",
    "\"Forever, ___\" (1996 humor book)",
    "Funny Bombeck",
    "Bombeck who wrote \"Housework, if you do it right, will kill you\"",
    "Humorist Bombeck",
    "Humor columnist Bombeck",
    "\"Aunt\" of a 1979 best seller",
    "Singer Franklin who was Aretha's elder sister",
    "Funny Bombeck",
    "Humorous Bombeck",
    "Humorist Bombeck",
    "Funny Bombeck",
    "Humorist Bombeck",
    "First name in home humor",
    "Franklin who sang \"Piece of My Heart\"",
    "Singing sister of Aretha Franklin",
    "Funny Bombeck",
    "Funny Bombeck",
    "Funny Bombeck",
    "___ Franklin, Grammy-nominated gospel/R&B singer",
    "Columnist Bombeck",
    "Humorist Bombeck",
    "\"Aunt\" with a 1979 best seller",
    "\"Forever, ___\" (1996 humor book)",
    "Aretha's Grammy-nominated sister",
    "Witty Bombeck",
    "Columnist Bombeck",
    "Franklin who sang \"Piece of My Heart\"",
    "Humorist Bombeck",
    "Gospel singer Franklin",
    "Bombeck who wrote \"The Grass Is Always Greener Over the Septic Tank\"",
    "Witty Bombeck",
    "Humorist Bombeck",
    "Title aunt in a 1979 best seller",
    "Humorist Bombeck",
    "First name in humor",
    "Humorist Bombeck",
    "Humorist Bombeck",
    "\"Piece of My Heart\" singer Franklin",
    "Title aunt in a 1979 best seller",
    "Wry Bombeck",
    "First name in humor",
    "Humorist Bombeck",
    "\"Aunt\" with a 1979 best seller",
    "Witty Bombeck",
    "Humorist Bombeck",
    "First name in humor",
    "Humorist Bombeck",
    "Humorist Bombeck",
    "First name in newspaper humor",
    "Grammy-nominated Franklin",
    "Columnist Bombeck",
    "Writer Bombeck",
    "First name in humor",
    "Aretha Franklin's Grammy-nominated sister",
    "\"Aunt\" with a 1979 best seller",
    "Humorist Bombeck",
    "First name in humor",
    "\"Forever, ___\" (1996 humor book)",
    "Witty Bombeck",
    "Humorist Bombeck",
    "\"Forever, ___\" (1996 humor book)",
    "Franklin who sang \"Piece of My Heart\"",
    "Aunt with a \"Cope Book\"",
    "\"Aunt\" with a \"Cope Book\"",
    "First name in home humor",
    "Columnist Bombeck",
    "Columnist Bombeck",
    "Columnist Bombeck",
    "Witty Bombeck",
    "Aunt with a \"Cope Book\"",
    "Wit Bombeck",
    "Columnist Bombeck",
    "Humorist Bombeck",
    "\"Aunt\" with a \"Cope Book\"",
    "Humorist Bombeck",
    "Witty Bombeck",
    "First name in humor",
    "Clever Bombeck",
    "Columnist Bombeck",
    "Witty Bombeck",
    "Witty Bombeck",
    "Humorous Bombeck",
    "Witty Bombeck",
    "Humorist Bombeck",
    "Writer Bombeck",
    "Humorist Bombeck",
    "Humorist Bombeck",
    "Author Bombeck",
    "Bombeck of \"At Wit's End\"",
    "Humorist Bombeck",
    "\"Aunt\" with a 1979 best seller",
    "First name in humor",
    "Humor columnist Bombeck",
    "First name in humor",
    "Humorist Bombeck",
    "Humorist Bombeck",
    "Wit Bombeck",
    "Writer Bombeck",
    "Writer Bombeck",
    "First name in humor",
    "Witty Bombeck",
    "Humorist Bombeck",
    "Bombeck who's \"at wit's end\"",
    "Columnist Bombeck",
    "Humorist Bombeck",
    "Columnist Bombeck",
    "First name in humor",
    "Columnist Bombeck",
    "Author Bombeck",
    "Columnist Bombeck",
    "Writer Bombeck",
    "Bombeck, the columnist",
    "Columnist Bombeck",
    "Anecdotal Bombeck",
    "\"Cope Book\" Aunt"
  ],
  "bias": [
    "Predisposition",
    "Leaning",
    "Slant",
    "Prejudice",
    "Diagonal kind of cut",
    "Concern for a poll",
    "Angle",
    "Statistician's worry",
    "Discrimination",
    "It's not fair",
    "Leaning",
    "Lean",
    "Partiality",
    "Selection ___",
    "Favoritism",
    "Media slant",
    "A.C.L.U. target",
    "Statistician's concern",
    "Prejudice",
    "Skewed view",
    "Editorial slant",
    "Angle",
    "Diagonal",
    "Leaning",
    "Skew",
    "Diagonal",
    "Predilection",
    "Favoritism",
    "Pollster's worry",
    "Subject for a media ombudsman",
    "Judge's no-no",
    "Color",
    "Diagonal",
    "Sampling problem",
    "Pollster's concern",
    "Favoritism or discrimination",
    "Favoritism",
    "Slant",
    "Leaning",
    "Partiality",
    "It colors commentary",
    "Kind of crime",
    "Diagonal",
    "Civil rights concern",
    "Kind of crime",
    "Diagonal",
    "Hiring no-no",
    "Slant",
    "Objectivity spoiler",
    "Prejudice",
    "Judge's no-no",
    "Pollster's worry",
    "Leaning",
    "Bent",
    "Partiality",
    "Leaning",
    "*Sixth step",
    "Civil rights concern",
    "Slant",
    "Diagonal",
    "Polling problem",
    "Partiality",
    "It's unfair",
    "Slant",
    "Opinion of others?",
    "Leaning",
    "One of the Seven Sages of ancient Greece",
    "Leaning",
    "It's not fair!",
    "Leaning",
    "Kind of crime"
  ],
  "enya": [
    "\"Orinoco Flow\" singer",
    "One-named Irish singer",
    "One-named Irish singer",
    "\"Shepherd Moons\" singer, 1991",
    "One-named singer with the hit \"Orinoco Flow\"",
    "Four-time Grammy winner for Best New Age Album",
    "Ireland's best-selling solo artist",
    "Singer born Eithne Ni Bhraonain",
    "One-named singer with four Grammys",
    "\"Watermark\" singer, 1988",
    "Popular singer born in County Donegal",
    "\"May It Be\" singer, 2001",
    "One-named Irish singer",
    "Artist with the 7x platinum album \"A Day Without Rain\"",
    "One-named Irish singer",
    "One-named New Age singer",
    "Grammy-winning singer of \"Shepherd Moons\"",
    "Singer heard in the first \"Lord of the Rings\" movie",
    "New Age singer from Ireland",
    "One-named New Age singer",
    "One-named New Age singer",
    "\"Dark Sky Island\" singer",
    "Singer with the 2008 album \"And Winter Came ...\"",
    "One-named singer from County Donegal",
    "Writer/singer of an Elvish song for \"The Lord of the Rings\"",
    "\"Dark Sky Island\" singer, 2015",
    "New Age Grammy winner",
    "Performer of the 2001 Oscar-nominated song \"May It Be\"",
    "Singer who has recorded in Tolkien's Elvish language",
    "One-named Irish Grammy winner",
    "\"Trains and Winter Rains\" singer, 2008",
    "One-named singer with multiple Grammys",
    "Ireland's best-selling solo artist",
    "\"Watermark\" singer",
    "One-named singer with four Grammys",
    "\"And Winter Came ...\" singer, 2008",
    "New Age star from Ireland",
    "Singer with the triple-platinum album \"The Memory of Trees\"",
    "\"A Day Without Rain\" singer",
    "\"May It Be\" singer, 2001",
    "Self-titled platinum album of 1986",
    "Four-time Best New Age Album Grammy winner",
    "Singer born Eithne Ni Bhraonain",
    "New Age singer from Ireland",
    "\"Orinoco Flow\" singer",
    "2006 Grammy winner for \"Amarantine\"",
    "\"Watermark\" vocalist",
    "One-named New Age singer",
    "\"A Day Without Rain\" artist",
    "\"Paint the Sky With Stars\" singer",
    "New Ager who sings in Irish Gaelic",
    "\"Shepherd Moons\" Grammy winner",
    "Grammy winner from County Donegal, Ireland",
    "Recording artist made famous by the BBC series \"The Celts\"",
    "New Age Grammy winner",
    "\"And Winter Came ...\" singer",
    "\"And Winter Came ...\" artist",
    "1987 album later rereleased as \"The Celts\"",
    "One-named Irish Grammy winner",
    "\"May It Be\" singer, 2001",
    "Mononymous four-time Grammy winner",
    "Singer with the 4x platinum album \"Watermark\"",
    "Irish pop star",
    "Grammy winner from County Donegal",
    "Popular singer born in County Donegal",
    "Grammy winner for \"Amarantine\"",
    "Singer with the compilation \"A Box of Dreams\"",
    "\"And Winter Came ...\" artist",
    "\"And Winter Came ...\" artist",
    "\"A Day Without Rain\" singer",
    "\"Orinoco Flow\" singer, 1989",
    "One-named New Age singer",
    "Singer with the 2008 gold record \"And Winter Came ...\"",
    "\"Orinoco Flow\" singer",
    "\"Only Time\" singer, 2001",
    "Irish singer with eight platinum U.S. albums",
    "\"Watermark\" vocalist",
    "2001 Oscar nominee for Best Original Song, \"May It Be\"",
    "\"Amarantine\" Grammy winner",
    "\"Paint the Sky With Stars: The Best of ___\" (1997 album)",
    "New Age superstar",
    "\"Caribbean Blue\" singer",
    "2001 Oscar nominee for the song \"May It Be\"",
    "Singer with the double-platinum album \"The Memory of Trees\"",
    "\"Paint the Sky With Stars\" singer",
    "Singer with the 1992 quintuple-platinum album \"Shepherd Moons\"",
    "\"A Day Without Rain\" singer",
    "Singer with an Oscar-nominated song in \"The Lord of the Rings\"",
    "Singer born Eithne Ni Bhraonain",
    "\"May It Be\" singer, 2002",
    "\"A Day Without Rain\" singer",
    "\"Only Time\" singer",
    "\"A Day Without Rain\" singer",
    "Single-named singer",
    "\"Only Time\" singer",
    "\"Book of Days\" songstress",
    "\"Only Time\" singer",
    "\"Only Time\" singer",
    "\"The Memory of Trees\" Grammy winner",
    "\"Orinoco Flow\" singer",
    "\"A Day Without Rain\" singer, 2000",
    "Single-named singer",
    "\"Only Time\" singer",
    "One-named New Ager",
    "Artist with the quadruple platinum album \"Shepherd Moons\"",
    "Oscar nominee for a song in \"The Lord of the Rings\"",
    "\"A Day Without Rain\" singer",
    "New Age singer",
    "One-named Irish singer",
    "\"Orinoco Flow\" singer",
    "Grammy winner for \"A Day Without Rain\"",
    "\"Pure Moods\" singer",
    "\"A Day Without Rain\" singer",
    "One-named New Age singer",
    "\"Only Time\" singer",
    "Singer of the multimillion-selling album \"Watermark\"",
    "\"Orinoco Flow\" singer",
    "\"Orinoco Flow\" singer",
    "Popular one-named singer",
    "Irish pop singer",
    "Popular New Age singer",
    "One-named Irish singer",
    "New Age music superstar",
    "\"Orinoco Flow\" singer"
  ],
  "wile": [
    "Bit of cunning",
    "Bit of cunning",
    "Trick",
    "Craftiness",
    "Craftiness",
    "Subterfuge",
    "Cunningness",
    "Magician's skill",
    "___ E. Coyote",
    "Trick",
    "Chicanery",
    "Cunningness",
    "Sneaky trick",
    "Bit of trickery",
    "Sly trick",
    "Bit of cunning",
    "Trick",
    "Artifice",
    "Sly trick",
    "Cunning trick",
    "Craftiness",
    "Stratagem",
    "Trick",
    "Beguile",
    "___ E. Coyote",
    "Subterfuge"
  ],
  "ares": [
    "Supervillain in Marvel comics",
    "Greek god whose name sounds like a zodiac sign",
    "Enemy of Wonder Woman",
    "Son of Zeus",
    "Father of Eros",
    "Symbol of militarism",
    "Greek counterpart of Mars",
    "Apollo's half brother",
    "Son of Zeus",
    "The Amazons were the daughter of this god, in myth",
    "Bellicose deity",
    "Mission name in \"The Martian\"",
    "Bellicose Greek god",
    "Father of the Amazons, in Greek myth",
    "Son of 62-Down",
    "Son of Zeus",
    "Trojan War participant",
    "Greek god who fought with the mortal Hercules",
    "Greek war god",
    "Mars' counterpart, in Greek myth",
    "Wonder Woman foe",
    "God often depicted with a shield",
    "Father of Deimos and Phobos",
    "Villain in \"Wonder Woman\"",
    "\"Wonder Woman\" antagonist",
    "Battle god for the Greeks",
    "Warlike Greek god",
    "\"Wonder Woman\" antagonist",
    "Father of the Amazons, in myth",
    "Father of Deimos and Phobos, in Greek myth",
    "Trojan ally in the \"Iliad\"",
    "Symbol of militarism",
    "Foe in \"Wonder Woman\"",
    "Lover of Aphrodite",
    "Father of Phobos, the god of fear",
    "God depicted with a spear",
    "Father of Phobos",
    "God depicted in a helmet",
    "Symbol of bloodlust",
    "One of the Twelve Olympians",
    "Son of Zeus",
    "Greek counterpart of Mars",
    "Greek counterpart to Mars",
    "Warrior on Mount Olympus",
    "Pugnacious Olympian",
    "Belligerent Greek god",
    "Olympian war god",
    "Father of Harmonia",
    "God who sounds like he was mentioned in the preceding clue",
    "Mars, to the Greeks",
    "Mythical father of Harmonia, strangely enough",
    "Father of Fear, in myth",
    "Mars, to the Greeks",
    "War force",
    "Helmeted deity",
    "Lover of Aphrodite",
    "\"Wrath of the Titans\" antagonist",
    "God whose name is a homophone of a zodiac sign",
    "Belligerent son of Zeus",
    "God with a shield",
    "Frequent foe of Wonder Woman",
    "Mars, to some",
    "Helmeted god",
    "Olympian war god",
    "Diomedes speared him",
    "Husband of Otrera",
    "Mars' Greek counterpart",
    "God whose name is a homophone of a zodiac sign",
    "Father of 38-Across",
    "God wounded by Diomedes in the \"Iliad\"",
    "Greek 16-Across",
    "Bellicose figure",
    "Warlike deity",
    "Father of Phobos",
    "Greek war god",
    "God with a shield",
    "Half brother of Athena",
    "Aphrodite's lover",
    "Father of Harmonia, in myth",
    "\"Xena: Warrior Princess\" role",
    "Father of Deimos and Phobos, in myth",
    "One of the Twelve Olympians",
    "Mars : Roman :: ___ : Greek",
    "God in the 49-Across",
    "Half brother of Athena",
    "Longtime enemy of Wonder Woman",
    "His chariot was drawn by fire-emitting horses",
    "Bellicose deity",
    "Greek god of war",
    "Greek counterpart of Mars",
    "Hawk with a crested helmet",
    "Vultures were sacred to him",
    "Half brother of Hercules",
    "His chariot was drawn by four fire-breathing horses",
    "Greek war god",
    "Father of Deimos and Phobos",
    "God of war",
    "Father of Harmonia",
    "Greek war god",
    "NASA launch vehicle",
    "War god on Olympus",
    "Belligerent deity",
    "God who cuckolded Hephaestus",
    "Mars' Greek counterpart",
    "God with a chariot drawn by fire-breathing stallions",
    "Mars' Greek counterpart",
    "Bellicose god",
    "God with a crested helmet and spear",
    "Mars, to the Greeks",
    "Trojan ally in the \"Iliad\"",
    "War god on Olympus",
    "U.S. rocket with a name from classical myth",
    "Mars' counterpart",
    "Trojan ally, in the \"Iliad\"",
    "Lover of Aphrodite",
    "Greek war god",
    "Hawkish Olympian",
    "His horses were Flame and Terror",
    "Bellicose god",
    "Vultures were sacred to him",
    "Greek war god",
    "Mars, to the Greeks",
    "Tuesday is named for his Germanic counterpart",
    "Father of Deimos",
    "Bellicose god",
    "Father of Harmonia",
    "U.S. rocket with a name from Greek myth",
    "Dogs were sacred to him",
    "Aphrodite?Æs lover",
    "Son of Zeus",
    "Son of Hera",
    "Mars' counterpart",
    "Mars counterpart",
    "Harmonia's father",
    "Ancient Athens's Temple of ___",
    "Warlike god",
    "Mars' counterpart",
    "Greek war god",
    "\"Iliad\" warrior",
    "Olympian hawk",
    "War maker",
    "God wounded in the Trojan War",
    "Spear carrier of myth",
    "Hawkish deity",
    "Greek war god",
    "Mars' counterpart",
    "War god",
    "Mars' counterpart",
    "God of war",
    "God of war",
    "___ Vallis (where the Mars Pathfinder landed)",
    "Bellicose god",
    "God defeated twice by Hercules",
    "Greek war god",
    "Harmonia's father",
    "Mars's Greek counterpart",
    "Greek war god",
    "War maker",
    "War deity",
    "Greek war god",
    "Mars, to Uranus",
    "Lover of Aphrodite",
    "Warmonger",
    "Belligerent deity",
    "Hawkish god",
    "Greek war god",
    "Son of Zeus",
    "Slayer of Adonis, in myth",
    "Bellicose deity",
    "God of war",
    "Harmonia's father",
    "Athens's Temple of ___",
    "God of war",
    "Father of Harmonia",
    "Greek god of war",
    "Belligerent Olympian",
    "Match for Mars",
    "Aphrodite's lover",
    "God seen on \"Xena: Warrior Princess\"",
    "Son of Hera",
    "Bellicose deity",
    "Hill of ___ (site near the Acropolis)",
    "Lover of Aphrodite",
    "God of war",
    "God of war",
    "Vultures were sacred to him",
    "Greek war god",
    "Father of Phobos",
    "Son of Zeus",
    "War god",
    "Lover of Aphrodite",
    "He favored the Trojans",
    "Son of Zeus",
    "Father of Fear and Panic",
    "Son of Zeus",
    "Vultures were sacred to him",
    "Lover of Aphrodite",
    "Half brother of Hermes",
    "God shown with a burning torch",
    "Trojan ally, in the \"Iliad\"",
    "God of war",
    "Greek war god",
    "\"Iliad\" figure",
    "Greek Mars",
    "Warlike Olympian",
    "God of war",
    "Son of Hera",
    "Father of Deimos and Phobos",
    "Greek Mars",
    "God of war",
    "Consort of Aphrodite",
    "Father of Eros",
    "Greek war god",
    "Trojan War god",
    "War deity",
    "Mars's Greek counterpart",
    "Homer's \"scourge of mortals\"",
    "Assister of the Trojans",
    "Bellicose Olympian",
    "Greek Mars",
    "Olympian hawk",
    "War deity",
    "Bellicose deity",
    "War god",
    "Counterpart of Mars",
    "Lover of Aphrodite",
    "War deity",
    "Son of 41 Down",
    "Lover of Aphrodite",
    "Trojan War figure",
    "Son of Hera",
    "Olympic hawk",
    "Armored god",
    "War god",
    "Lover of Aphrodite"
  ],
  "kemo": [
    "___ Sabe (the Lone Ranger, to Tonto)",
    "___ sabe",
    "___ Sabe (the Lone Ranger, to Tonto)",
    "___ sabe",
    "___ sabe",
    "___ Sabe",
    "___ Sabe",
    "Word for the Lone Ranger",
    "Word from Tonto"
  ],
  "tend": [
    "Be inclined (to)",
    "Look after, as a fire, bar or flock",
    "Lean",
    "Be in charge of, as a bar",
    "Gravitate (toward)",
    "Watch, as a bar",
    "Look after",
    "Manage, as a bar",
    "___ bar",
    "Handle",
    "Watch, as the bar",
    "Babysit",
    "Watch over",
    "Be inclined (to)",
    "Keep, as a garden",
    "See to",
    "Minister (to)",
    "Be disposed (to)",
    "Care for, as a garden",
    "Shepherd",
    "Watch over",
    "Be inclined (to)",
    "Be inclined",
    "Take care of, as a garden",
    "Mind",
    "Watch",
    "Look after",
    "Work, as a bar",
    "Keep alive, as a fire",
    "Lean",
    "Gravitate",
    "Look after, as a bar",
    "Be inclined (to)",
    "Care for, with \"to\"",
    "Shepherd",
    "Cultivate",
    "Be disposed (to)",
    "Gravitate",
    "Be inclined (to)",
    "Lean",
    "Care for, with \"to\"",
    "Oversee",
    "Manage, as a bar",
    "Be inclined",
    "Look after",
    "Oversee",
    "Look after",
    "Gravitate",
    "Shepherd",
    "Minister (to)",
    "Watch over",
    "See to",
    "Look after",
    "Be inclined",
    "Minister to",
    "Watch",
    "Take care of",
    "Baby-sit",
    "Gravitate (toward)",
    "Lean",
    "Keep an eye on",
    "Care for",
    "Gravitate",
    "Be inclined",
    "Look after",
    "Watch",
    "Care for",
    "Keep an eye on",
    "Watch",
    "Care for",
    "Take care of",
    "Care for",
    "Incline",
    "Take care of",
    "Incline",
    "Take care of",
    "Conduce",
    "Take care of",
    "Look after",
    "Guard",
    "Care for",
    "Shepherd",
    "Lean",
    "Look after",
    "Mind",
    "Manage, as a bar",
    "Be inclined",
    "Take care of",
    "Look after",
    "Take care of",
    "Watch over",
    "Work at a bar"
  ],
  "owow": [
    "Response to a sting",
    "\"I'm in pain! I'm in pain!\"",
    "\"Geez! That stings!\""
  ],
  "rave": [
    "Gush praise",
    "Give five stars, say",
    "Four-star",
    "Praiseful appraisal",
    "Gush",
    "Party that might not start till midnight",
    "Go on and on about something",
    "High praise",
    "Four-star review",
    "Four-star review",
    "Party that might have a bouncer",
    "Gushing review",
    "Party hearty",
    "Go gaga",
    "Talk wildly",
    "All-nighter, maybe",
    "Party that might start after midnight",
    "Party with glow sticks, maybe",
    "* * * * *, say",
    "Four-star",
    "Hoped-for review",
    "All-nighter, maybe",
    "Four stars, say",
    "Four-star review",
    "Critic's high praise",
    "Four-star review",
    "All-night bash",
    "Four-star piece, say",
    "All-night party",
    "High praise",
    "All-night dance party",
    "Major party",
    "High praise",
    "Gig for a deejay",
    "Event starting at 2 a.m., maybe",
    "Wildly positive",
    "Great review",
    "\"I loved, loved, loved that!\" review",
    "Four-star review",
    "Five-star review",
    "Party with techno music, perhaps",
    "\"Thumbs way up!\" review",
    "Over-the-top review",
    "Quotable review",
    "Four stars, perhaps",
    "Gush",
    "Thumbs-up review",
    "Talk like a madman",
    "Four-star review",
    "Rant",
    "Carry on",
    "Dance party",
    "Boffo review",
    "Talk crazily",
    "Glowing review",
    "Overnight dance party",
    "Hearty party",
    "Quotable review",
    "All-night bash",
    "Teen happening",
    "Gush",
    "Enthusiastic write-up",
    "All-night party",
    "**** review",
    "Go gaga",
    "Thumbs-up write-up",
    "Carry on",
    "Rip-roaring success",
    "Carry on",
    "All-night party",
    "Great review",
    "**** review",
    "Four-star",
    "Four-star",
    "Carry on",
    "Four-star review",
    "Go bonkers",
    "Teen party",
    "Certain review",
    "Four-star review",
    "Hoped-for review",
    "Carry on",
    "Angel's delight",
    "Be gaga over",
    "90's party",
    "Carry on",
    "Pan's opposite",
    "Great review",
    "Go crazy",
    "Rant's companion",
    "Four-star review",
    "Strong thumbs-up review",
    "Wild party",
    "90's party",
    "Highly complimentary",
    "Five-star review",
    "Speak deliriously",
    "Enthusiastic review",
    "Boisterous party",
    "Pan's opposite",
    "Laud loudly",
    "Scream and shout",
    "Like some reviews",
    "Pan's opposite",
    "Four-star review",
    "Pan's opposite",
    "Coveted review"
  ],
  "bomb": [
    "Go over terribly with an audience",
    "Fail spectacularly",
    "Fail ignominiously",
    "Fail big-time",
    "Box-office dud",
    "Flame out",
    "Zero-star movie",
    "Flop",
    "One with a terrible opening",
    "Crash and burn",
    "Fail miserably",
    "*Dud",
    "Lay an egg",
    "\"Ishtar\" or \"Heaven's Gate,\" famously",
    "Complete flop",
    "Total flop",
    "0-star movie",
    "Fail miserably",
    "Less than a one-star movie",
    "Turkey",
    "Hit's opposite",
    "Screen flop",
    "Long, high pass",
    "Long pass",
    "Long pass",
    "Big flop",
    "Fiasco",
    "Clunker",
    "Lay an egg",
    "Drop explosives on"
  ],
  "them": [
    "The outsiders",
    "They/___ pronouns",
    "Not us",
    "\"How do you like ___ apples?\"",
    "The enemy",
    "Not us",
    "The challengers",
    "Gender-neutral pronoun",
    "The antagonists",
    "British Invasion band that launched Van Morrison's career",
    "Silent part of \"mnemonic\"",
    "The opposing side",
    "One side of a feud",
    "British Invasion band",
    "Us competitor",
    "The other side",
    "Us vs. ___",
    "The opposing side",
    "Novel in Joyce Carol Oates's Wonderland Quartet",
    "Classic sci-fi film billed as \"a horror horde of crawl-and-crush giants\"",
    "The people vs. us",
    "The opposition",
    "The opposition",
    "Classic black-and-white film featuring gigantic irradiated ants",
    "\"It's us against ___\"",
    "Opponents of us",
    "Not us",
    "Joyce Carol Oates novel",
    "1954 sci-fi movie with an exclamation point in its title",
    "Those guys",
    "Classic 1954 sci-fi film",
    "The people over there",
    "The other side",
    "Not us",
    "Outsiders",
    "1954 horror film about giant ants",
    "Not us",
    "The opponents",
    "Unnamed ones",
    "The folks over there",
    "\"It's us against ___\"",
    "1954 sci-fi film",
    "Not us",
    "1969 Joyce Carol Oates novel",
    "The enemy",
    "Unnamed ones",
    "The enemy",
    "1969 Oates novel",
    "Unnamed others",
    "Sci-fi film of 1954",
    "Those characters",
    "Not us",
    "1969 Oates novel"
  ],
  "rack": [
    "Strain, as one's brain",
    "Where things might be left hanging",
    "Eight lamb chops, typically, or a frame for 15 pool balls",
    "Billiards accessory",
    "Spice holder",
    "Billiards need",
    "Deer hunter's trophy",
    "One of four in a Scrabble set",
    "Pool table triangle",
    "Billiards need",
    "Triangle on a pool table",
    "Part of a dishwasher",
    "Pool need",
    "Torment",
    "Pool accessory",
    "Billiards need",
    "Spice holder",
    "___ of lamb",
    "Pool accessory",
    "Holder of billiard balls",
    "15-ball cluster",
    "Billiard hall item",
    "Pool need",
    "Billiards equipment",
    "Spice holder",
    "Pinion's partner",
    "Torture device",
    "Pool device",
    "Poolroom aid",
    "Torture device",
    "Torture chamber item",
    "Ruin's partner",
    "Wall fixture"
  ],
  "redd": [
    "Actor Foxx of \"Sanford and Son\"",
    "Chris of \"S.N.L.\"",
    "Funny Foxx",
    "Funny Foxx",
    "Comic Foxx",
    "Foxx of \"Sanford and Son\"",
    "\"Tennessee Waltz\" lyricist ___ Stewart",
    "Actor Foxx",
    "TV's Foxx",
    "Comedian Foxx",
    "Comedian Foxx",
    "Comedian Foxx",
    "Foxx of \"Sanford and Son\"",
    "2004 N.B.A. All-Star Michael",
    "Funny Foxx",
    "Comic Foxx",
    "Funnyman Foxx",
    "Foxx of \"Sanford and Son\"",
    "Funnyman Foxx",
    "Comic Foxx",
    "Funny Foxx",
    "First name in 70's-80's TV",
    "Foxx on the box",
    "Foxx of \"Sanford and Son\"",
    "Comic Foxx",
    "Demond's 70's TV co-star",
    "Foxx of \"Sanford and Son\"",
    "He played Fred the junkman",
    "Demond's co-star, in 70's TV",
    "Fred's portrayer on \"Sanford\"",
    "First name in 70's TV comedy",
    "First name in TV comedy",
    "Comedian Foxx",
    "Funnyman Foxx",
    "Comedian Foxx",
    "Funny Foxx",
    "Foxx of \"Sanford and Son\"",
    "First name in TV comedy",
    "Foxx of \"Sanford and Son\"",
    "Funnyman Foxx",
    "Funnyman Foxx",
    "He played Fred on \"Sanford and Son\""
  ],
  "safe": [
    "An umpire's outstretched arms signifies this",
    "Call at home",
    "Ump's call",
    "Unlikely to cause controversy",
    "On base",
    "Bread box?",
    "On base, say",
    "A good one is hard to crack",
    "Untagged",
    "Heist target",
    "One way to play it",
    "On base",
    "Uncontroversial",
    "___ and sound",
    "Partner of sound",
    "Something behind a painting, maybe",
    "On base",
    "Untagged?",
    "Not tagged",
    "First or second call, maybe",
    "Opposite of out",
    "How the cautious play it",
    "Jewel box?",
    "Cry at home, maybe",
    "See 32-Across",
    "Burglary target",
    "Bread box?",
    "Secure",
    "Untouchable?",
    "Call at first base, maybe",
    "Not threatened",
    "See 23-Across",
    "Bank fixture",
    "Trustworthy",
    "Not risky",
    "Bombproof, say",
    "Opposite of \"out\" in baseball",
    "Jewelry box?",
    "In an ark, say",
    "First call?",
    "Official's call with outspread arms",
    "Not out",
    "Out's opposite",
    "Call at home",
    "Sound's partner",
    "Call at first",
    "Ump's call with outstretched arms",
    "On base",
    "Out of harm's way",
    "Hotel room amenity",
    "Ump's call",
    "Home free",
    "Call with outstretched arms",
    "Opposite of \"Out!\"",
    "Dependable",
    "Umpire's call",
    "Risk-free",
    "Base line?",
    "Diamond call",
    "Sound's partner",
    "Gem holder",
    "Opposite of out",
    "Call at first",
    "Not out",
    "Box that crackers go into?",
    "Not put out",
    "Office feature, sometimes",
    "Under lock and key",
    "Call at home?",
    "Call from home?",
    "Not sorry, perhaps",
    "Out of reach",
    "Having escaped a tag",
    "Cracker's target",
    "Diamond holder",
    "Reliable",
    "Two-palms-down signal",
    "Decision made at home?",
    "Hotel convenience",
    "First call?",
    "Stock holder?",
    "Not 62-Across",
    "Sound's partner",
    "Protected",
    "Sometimes cracked container",
    "Protected",
    "A hole in the wall?",
    "Umpire's call",
    "Hotel convenience",
    "Ump's call",
    "Not out",
    "Ump's call",
    "Ump's call",
    "Not out",
    "Nonpoisonous",
    "Out's opposite"
  ],
  "cber": [
    "One who's radio-active?",
    "Trucker on a radio",
    "Trucker who relays \"bear traps\"",
    "One who asks \"Got your ears on?\"",
    "Person who might say \"10-4, good buddy\"",
    "Trucker with a transmitter",
    "One with a handle on the transportation industry?",
    "Bear trap fearer",
    "Trucker on a radio",
    "One navigating breakers?",
    "One connected with the force?",
    "One attached to a handle",
    "Certain radio user",
    "\"Good buddy\"",
    "Traffic reporter, you might say",
    "\"Roger that\" sayer",
    "Handle user",
    "Semi conductor?",
    "One with a handle",
    "Lonely trucker, perhaps",
    "Trucker on the air",
    "Good buddy",
    "\"Ten-four\" speaker",
    "\"Good buddy\"",
    "\"Good buddy\"",
    "Radio-active one?",
    "Good buddy",
    "Buddy on the road",
    "Radio \"good buddy\"",
    "\"Good buddy\"",
    "One with a handle",
    "\"Good buddy\"",
    "Semi conductor?",
    "Many a trucker",
    "Good buddy",
    "One who might report seeing a bear",
    "\"10-4\" speaker",
    "Trucker with a handle",
    "Smokey spotter",
    "Good buddy",
    "One who's driving on air?",
    "Radio-active driver",
    "Many a trucker",
    "Person with a handle",
    "Good buddy",
    "Semi conductor?",
    "Smokey detector",
    "Driver who talks",
    "Handle holder?",
    "Breaker, maybe",
    "Speaker on the road",
    "One with a handle",
    "Rubber Duck, for one",
    "Good buddy",
    "Trucker with a radio",
    "One with a handle",
    "One with a handle"
  ],
  "look": [
    "\"Check this out!\"",
    "\"Here's the thing ...\"",
    "\"___ alive!\"",
    "Gander",
    "\"Here's the thing ...\"",
    "\"Attention!\"",
    "Take a gander",
    "\"See for yourself!\"",
    "\"Behold!\"",
    "\"The way I see it ...\"",
    "Have a gander",
    "\"Hey, over here!\"",
    "Inspect, with \"at\"",
    "\"See for yourself!\"",
    "Expression",
    "___-see",
    "\"Over there!\"",
    "\"There!\"",
    "Start of an explanation",
    "Collier's rival",
    "Crossing word"
  ],
  "bart": [
    "\"The Simpsons\" character voiced by Nancy Cartwright",
    "First name of the only fictional character in Time's list of the 100 Most Important People of the Century",
    "Brother of TV's Lisa and Maggie",
    "Milhouse's toon friend",
    "Transportation system that stops at SFO",
    "Cartoon boy who makes many prank calls",
    "TV boy with spiked hair",
    "Milhouse's best friend, on TV",
    "TV character whose middle name is JoJo",
    "Cartoon character voiced by Nancy Cartwright",
    "Metro : Washington :: ___ : San Francisco",
    "Former baseball commissioner Giamatti",
    "One of Homer's greatest creations?",
    "Perpetual 10-year-old of TV",
    "Cartoon boy who can be described by an anagram of his name",
    "Olympic gold-medal gymnast Conner",
    "TV character who says \"I didn't think it was physically possible, but this both sucks and blows\"",
    "TV character who says \"Don't have a cow, man!\"",
    "Driving alternative in S.F.",
    "Starr of the N.F.L.",
    "S.F. rail service",
    "One of TV's Simpsons",
    "\"Oliver!\" composer Lionel ___",
    "Quarterback Starr",
    "TV cartoon boy",
    "Stagecoach robber Black ___",
    "\"Oliver!\" composer",
    "One of the Maverick brothers",
    "Homer's boy",
    "Simpson boy",
    "One of TV's Maverick brothers",
    "Bret Maverick's brother",
    "M.T.A.: Boston::___: San Francisco",
    "Master Simpson",
    "Starr of the Packers",
    "TV bad boy",
    "Lone Ranger foe Black ___",
    "One of the Simpsons",
    "S.F. train system"
  ],
  "butt": [
    "End of a cigarette or rifle",
    "Bottom",
    "What's left of a cigarette",
    "Ashtray item",
    "Rear end",
    "Tush",
    "Keister",
    "Ram",
    "Joke's target",
    "Joke's target",
    "Prankster's patsy",
    "Thing in an ashtray",
    "You might grind it out",
    "Object of ridicule",
    "Ram",
    "Set end to end",
    "Rifle part",
    "Rifle part",
    "Rifle part",
    "Joke target",
    "Scapegoat",
    "Joke's target",
    "Ram",
    "Ram"
  ],
  "huey": [
    "Nephew of Donald Duck",
    "___ Lewis and the News",
    "Chopper in the Vietnam War",
    "Vietnam War copter",
    "___ Lewis and the News",
    "Nephew of Donald Duck",
    "U.S. military chopper",
    "First of a Disney trio",
    "Army helicopter",
    "Louisiana's Long",
    "Military helicopter, informally",
    "A nephew of Donald's",
    "Politico Long",
    "Long in politics",
    "Radical Newton",
    "The Kingfish, to friends"
  ],
  "toni": [
    "Morrison who said \"A writer's life and work are not a gift to mankind; they are its necessity\"",
    "Literature Nobelist Morrison",
    "Nobel laureate Morrison",
    "Singer Watson, a.k.a. Tones and I, with the 2019 hit \"Dance Monkey\"",
    "Nobel winner Morrison",
    "Morrison who wrote \"Beloved\"",
    "Nobel laureate Morrison",
    "First name of the most-selected author in Oprah's Book Club",
    "Nobel and Pulitzer winner Morrison",
    "Novelist Morrison",
    "___ Kukoc, 6'11\" N.B.A. star of 1993-2006",
    "Author Morrison",
    "Author Morrison",
    "Writer Morrison",
    "Morrison who wrote \"Beloved\"",
    "Collette of \"United States of Tara\"",
    "\"Beloved\" author Morrison",
    "Literature Nobelist Morrison",
    "Writer Morrison",
    "Singer Tennille",
    "Braxton with seven Grammys",
    "Tennille of pop music's Captain & Tennille",
    "Author Morrison",
    "\"Beloved\" author Morrison",
    "Tennille of tunes",
    "Collette of \"United States of Tara\"",
    "\"Beloved\" author Morrison",
    "\"Mickey\" vocalist ___ Basil",
    "Novelist Morrison",
    "\"Mickey\" singer Basil",
    "Lydman of the N.H.L.",
    "Literature Nobelist Morrison",
    "Collette of \"The Sixth Sense\"",
    "Novelist Morrison",
    "\"Beloved\" author Morrison",
    "Author Morrison",
    "Actress Collette",
    "___ Twins (pair in old ads for home perm kits)",
    "___ Sailer, three-time 1956 skiing gold medalist",
    "Cager Kukoc",
    "1956 Olympic skiing sensation ___ Sailer",
    "Literature Nobelist Morrison",
    "Cager Kukoc",
    "Product whose ads featured twins",
    "Singer Braxton",
    "Nobel laureate Morrison",
    "Singer Braxton",
    "Brand of home perm kits",
    "Literature Nobelist Morrison",
    "\"Beloved\" author Morrison",
    "Author Morrison",
    "Author Morrison",
    "N.B.A. star Kukoc",
    "\"Beloved\" writer Morrison",
    "Author Morrison",
    "Novelist Morrison",
    "Braxton of song",
    "Singer Braxton",
    "N.B.A.'s Kukoc",
    "Novelist Morrison",
    "Braxton or Tennille",
    "Three-time skiing gold medalist ___ Sailer",
    "Writer Morrison",
    "Singer Tennille",
    "Singer Braxton",
    "Singer Tennille",
    "\"Beloved\" author Morrison",
    "Nobelist Morrison",
    "N.B.A.'s Kukoc",
    "Actress Collette of \"The Sixth Sense\"",
    "Novelist Morrison",
    "\"Sula\" novelist Morrison",
    "\"Beloved\" author Morrison",
    "Singer Braxton",
    "Novelist Morrison",
    "Singer Tennille",
    "Novelist Morrison",
    "Actress Collette of \"Emma\"",
    "Novelist Morrison",
    "\"Beloved\" author Morrison",
    "Singer Braxton",
    "Cager Kukoc",
    "Tennille of the Captain and Tennille",
    "\"Mickey\" singer Basil",
    "Nobelist Morrison",
    "Singer Tennille",
    "Grammy-winning Braxton",
    "Author Morrison",
    "Three-time skiing gold medalist Sailer",
    "Singer Braxton",
    "\"Beloved\" author Morrison",
    "Singer Basil",
    "Singer Tennille",
    "Grammy winner Braxton",
    "Singer Breton",
    "Author Morrison"
  ],
  "gawk": [
    "Gape",
    "Rubberneck",
    "Goggle",
    "Get an eyeful",
    "Rubberneck",
    "Rubberneck",
    "Rubberneck",
    "Hang around at an accident scene",
    "Stare (at)",
    "Rubberneck",
    "Rubberneck",
    "Stare (at)",
    "Stare, like a tourist"
  ],
  "bull": [
    "Taurus symbol",
    "Animal in a Wall Street sculpture",
    "New York Stock Exchange symbol",
    "Zodiac animal",
    "Bronze animal in New York's financial district",
    "Zodiac symbol",
    "Zodiac symbol",
    "Headstrong one",
    "Kind of market",
    "Kind of market",
    "Torero's foe",
    "Certain charger",
    "Wall Street optimist",
    "Optimist, of sorts",
    "Positive Wall Street figure",
    "Kind of pen"
  ],
  "rami": [
    "Oscar-winning Malek",
    "Best Actor winner Malek",
    "Actor Malek",
    "Best Actor winner Malek",
    "Best Actor winner Malek",
    "Malek who won a Best Actor Oscar for \"Bohemian Rhapsody\"",
    "Malek of \"Mr. Robot\""
  ],
  "talk": [
    "Format of much AM radio",
    "Be a stool pigeon",
    "Lecture",
    "\"Tell me what you know!\"",
    "Popular AM radio format",
    "Succumb to interrogation",
    "With 41-Across, discuss",
    "Converse",
    "Yak",
    "It's cheap, proverbially",
    "Cheap commodity?",
    "Lecture",
    "Converse",
    "Hype, with \"up\"",
    "Spill the beans",
    "Confess",
    "Make a deal with the feds, say",
    "Spill the beans",
    "With 38-Across, popular entertainment",
    "Chitchat",
    "Rumors",
    "Radio format",
    "Report",
    "Scuttlebutt"
  ],
  "pity": [
    "Feel sorry for",
    "See 35-Across",
    "Feel for",
    "___ party",
    "\"Alas!\"",
    "\"What a ___\"",
    "\"Too bad!\"",
    "Opposite of schadenfreude",
    "Shame",
    "\"What a ___!\" (\"Too bad!\")",
    "Regrettable situation",
    "It may be \"aw\"-inspiring",
    "Unfortunate circumstance",
    "\"The scavenger of misery,\" per Shaw",
    "Opposite of schadenfreude",
    "\"A woman's ___ often opens the door to love\": Henry Ward Beecher",
    "\"___ makes suffering contagious\": Nietzsche",
    "Unfortunate development",
    "Terrible shame",
    "Compassion",
    "Compassion",
    "\"What a shame\"",
    "Compassion",
    "Wistful comment, after \"a\"",
    "Bleed for",
    "Feel sorry for",
    "Feel sorry for",
    "Ruefulness",
    "Feeling for the unfortunate",
    "Feel sorry for",
    "Compassion",
    "Compassion"
  ],
  "aura": [
    "Surrounding glow",
    "Je ne sais quoi",
    "Glow",
    "Target for holistic healing",
    "Otherworldly glow",
    "Mood",
    "Surrounding glow",
    "Atmosphere",
    "Spiritual energy",
    "Magnetic quality",
    "Energy field, in holistic medicine",
    "Vibe",
    "Distinctive quality",
    "General vibe",
    "New Age energy field",
    "Surrounding glow",
    "Ambience",
    "Otherworldly glow",
    "Mystical glow",
    "Mystique",
    "Atmosphere",
    "Corona, for one",
    "Distinctive atmosphere",
    "Ambient quality",
    "Ambience",
    "Vibe",
    "Vibe",
    "See 79-Down",
    "Glow",
    "Indefinable presence",
    "Energy field, of sorts",
    "Natural radiation?",
    "Distinctive quality",
    "Halo effect",
    "Otherworldly glow",
    "Surrounding glow",
    "Subtle emanation",
    "Special quality",
    "Air",
    "Mystical glow",
    "Glow",
    "Halo, e.g.",
    "That certain something",
    "Mysterious glow",
    "Saintly glow",
    "Vibe",
    "Glow",
    "Mystique",
    "Air",
    "Glow",
    "Certain something",
    "Something many a celebrity carries in public",
    "Ambience",
    "Subtle glow",
    "Otherworldly glow",
    "Charismatic effect",
    "Seer's perception",
    "Special glow",
    "Je ne sais quoi",
    "Mystique",
    "Subtle glow",
    "Goddess of breezes",
    "Surrounding glow",
    "Companion of Artemis whom Zeus changed into a spring",
    "Magical glow",
    "Corona",
    "Surrounding glow",
    "Mystique",
    "Ambiance",
    "A person may have one of invincibility",
    "Surrounding glow",
    "Surrounding glow",
    "Feeling",
    "Overall feel",
    "Kirlian photography image",
    "Mystique",
    "A celebrity carries one",
    "Mystique",
    "Distinctive atmosphere",
    "Atmosphere",
    "Special glow",
    "Mystique",
    "What a seer may see",
    "Vibrations",
    "Subtle glow",
    "A president usually has one",
    "Mystique",
    "Glow",
    "Ambience",
    "A celebrity might be bathed in one",
    "Feeling",
    "Mystique",
    "Feeling",
    "Emanation",
    "Air",
    "Mystique",
    "Atmosphere",
    "Ambience",
    "Mystique",
    "Halo",
    "Subtle glow",
    "Mysterious quality",
    "That certain something",
    "Subtle quality",
    "Distinctive quality",
    "A star may have one",
    "Heavenly glow",
    "Mystique",
    "Field of energy",
    "Surrounding glow",
    "A celebrity may have one",
    "That certain something",
    "Atmosphere",
    "Certain something",
    "Feel",
    "Luminous radiation",
    "Companion of Artemis",
    "Personification of a gentle wind, in Greek myth",
    "Mystic enveloper",
    "What a celebrity may carry",
    "Air",
    "Ambience",
    "In myth she was turned into a spring",
    "Atmosphere",
    "Pervasive quality",
    "Glow",
    "\"___ Lee\" (classic song)",
    "Radiant glow",
    "Surrounding glow",
    "Something about you",
    "Mystique",
    "Something in the air",
    "Atmosphere",
    "Halo, e.g.",
    "A psychic may sense it",
    "Halo, e.g.",
    "Princess Diana had one",
    "Vibrant glow",
    "Certain something",
    "A movie star may carry one",
    "Kirlian photography image",
    "Emanation",
    "Personification of the wind",
    "Glow",
    "It's all around you",
    "Heavenly glow",
    "Surrounding glow",
    "Mystique",
    "Ambiance",
    "Glow",
    "Nimbus",
    "Ambience",
    "Mystique",
    "New Ageish glow",
    "Feeling",
    "A movie star may carry one",
    "Glow",
    "A celebrity may have one",
    "Atmosphere",
    "Atmosphere",
    "Ambience",
    "Glow",
    "Glow",
    "Halo",
    "Enveloping glow",
    "Surrounding glow",
    "Ambience",
    "Surrounding glow",
    "Atmosphere",
    "New Age-ish glow",
    "Mystique",
    "Atmosphere",
    "General look",
    "Goddess of gentle winds",
    "Kirlian photography phenomenon",
    "Distinctive quality",
    "Air",
    "Ambience",
    "Atmosphere",
    "Morning wind personified",
    "Atmosphere",
    "Ambiance"
  ],
  "bent": [
    "Like the wire in paper clips",
    "Natural talent",
    "Crooked",
    "Set (on)",
    "Corrupt, in British slang",
    "Tendency",
    "Like lop ears",
    "Like the wire in clothes hangers",
    "Like a pole-vaulter's pole mid-vault",
    "Like a paper clip",
    "Disposition",
    "Corrupt, in British slang",
    "Flexed, as at the elbow",
    "Crooked",
    "Like some ears and rules",
    "Like some straws",
    "Inclination",
    "Inclination",
    "Inclination",
    "Crooked",
    "Predilection",
    "Wacky, as humor",
    "Out of line?",
    "Not straight",
    "Out of alignment",
    "Not straight",
    "Having one's heart set (on)",
    "Inclination",
    "Not straight",
    "Angled",
    "Contorted",
    "Like staples",
    "Inclination",
    "Like a shepherd's staff",
    "Out of shape",
    "Crooked",
    "Propensity",
    "Not straight",
    "Not straight",
    "Out of shape",
    "Having an angle",
    "Bowed",
    "Like the wire in a croquet wicket",
    "Leaning",
    "Needing straightening",
    "Inclination",
    "Twisted",
    "Like some ears and elbows",
    "Propensity",
    "Not straight",
    "Proclivity",
    "Flair",
    "Predisposition",
    "Determined with ?ôon?ö",
    "Natural inclination",
    "Propensity",
    "Twisted"
  ],
  "inns": [
    "Quaint lodgings",
    "Some Tripadvisor listings",
    "Opening settings for \"The Canterbury Tales\" and \"Treasure Island\"",
    "Rest stops",
    "Travel brochure listings",
    "Some establishments next to airports",
    "Road trip accommodations",
    "Stopovers",
    "Stops on the road",
    "Establishments at many highway interchanges",
    "Places to exchange dollars for quarters",
    "Cozy spots to stop",
    "Travel guide listings",
    "Places to stay",
    "Lodgings",
    "Traveler's boarding areas?",
    "Places to crash on road trips",
    "They provide quarters for dollars",
    "Stonewall and others",
    "B&Bs",
    "Hostlers' workplaces",
    "Bed-and-breakfasts",
    "Cluster at many a highway interchange",
    "B&Bs",
    "Orbitz options",
    "Overnight lodges",
    "Roadside stops",
    "Establishments that often have porte cocheres",
    "Stopovers for wayfarers",
    "Priceline possibilities",
    "Holiday spots?",
    "Country ___ & Suites",
    "Stopover points",
    "Comfort spots?",
    "Overnight stops on the road",
    "Overnight lodgings",
    "Overnight stops on road trips",
    "Resort options",
    "Tolkien's Prancing Pony and others",
    "Restful places",
    "Priceline possibilities",
    "B&Bs",
    "Michelin Guide recommendations",
    "Some stopovers",
    "Sights at many interstate exits",
    "B&Bs",
    "Quaint stopovers",
    "Lodges",
    "Stops in the country",
    "Sleep sites",
    "Middle-earth's Green Dragon and Prancing Pony",
    "Places to put up",
    "Quaint lodgings",
    "They have keepers",
    "B&B's",
    "They have rates and ratings",
    "Bed-and-breakfasts",
    "Wayhouses",
    "Quaint lodgings",
    "Quaint roadside stops",
    "Bed-and-breakfasts",
    "Places for guests",
    "Imarets, e.g.",
    "B&B's",
    "Stops on the road",
    "Quaint stopovers",
    "Rustic lodgings",
    "Places to stay the night",
    "Places to sleep",
    "Alehouses",
    "Cozy retreats",
    "Hostel environments",
    "They have guests",
    "Roadside stops",
    "Lodges",
    "___ of Court",
    "Rustic stopovers",
    "Crash sites?",
    "Cozy places to stay",
    "Roadside stops",
    "Rustic lodgings",
    "Auberges",
    "Rustic retreats",
    "Holiday ___",
    "Highway havens",
    "Lodges",
    "Post houses",
    "B & B's",
    "Lodges",
    "Vacationers' stops",
    "Halfway houses",
    "Getaway places",
    "Ramadas and such",
    "Resting places",
    "Travelers' stopovers",
    "Lodges",
    "They're accommodating",
    "Vacationers' stops",
    "Days ___ (business chain)",
    "Places to stay",
    "Rural retreats",
    "Watering holes",
    "Travelers' stops",
    "Taverns",
    "Travel brochure listings",
    "Hostels",
    "Days ___ (highway sights)",
    "B. & B.'s",
    "Holiday ___",
    "New Hampshire is famous for them",
    "Places to overnight",
    "Stopping-off sites",
    "Travel guide list",
    "Things keepers keep",
    "Sights near highway exits",
    "Stopping points",
    "London's ___ of Court",
    "Bed and breakfast sites",
    "London's ___ of Court",
    "Lodges",
    "Places to overnight",
    "Lodges",
    "Bed-and-breakfasts",
    "They can put you up",
    "Keepers keep them",
    "Dining sites",
    "Hostelries",
    "British legal societies",
    "Tourist establishments",
    "B & B's",
    "Roadhouses",
    "Travel guide listings"
  ],
  "honk": [
    "Sound of a car or goose",
    "\"Out of my way!\" indicator",
    "Angry driver's signal",
    "Traffic signal?",
    "Alert for a distracted driver",
    "Respond to a bumper sticker, maybe",
    "[The light turned green! Go!]",
    "Start of many a bumper sticker",
    "First word of many bumper stickers",
    "Beep",
    "Toot one's horn",
    "Signal to a road hog",
    "What taxis do",
    "\"___ if you ...\" (bumper sticker)",
    "Bumper sticker starter",
    "Goose sound",
    "Sign of impatience",
    "It means \"Out of my way!\""
  ],
  "agua": [
    "Water: Sp.",
    "Contents of un rio",
    "Liquido vital",
    "H2O, south of the border",
    "Spanish water",
    "Eau, across the Pyrenees",
    "Contents of the Rio Grande",
    "What un desierto lacks",
    "Water, in Oaxaca",
    "Chaser of un trago de tequila",
    "Tequila chaser",
    "Contents of el oceano",
    "Requirement for un bano",
    "Water, south of the border",
    "Golfo contents",
    "Contents of un lago",
    "Water, to Juanita",
    "Quito quaff",
    "Contents of el oceano",
    "Oceano contents",
    "Fuego extinguisher",
    "Lago or rio contents",
    "Rarity in un desierto",
    "Spanish water",
    "Rain, in Spain",
    "What un desierto lacks",
    "Arizona's ___ Fria River",
    "H-dos-O?",
    "Sed quencher",
    "It flows in un rio",
    "Chihuahua drink",
    "Rio makeup",
    "Contents of un lago",
    "Water, in Juarez",
    "Rio contents",
    "Content of la mar",
    "El oceano, por ejemplo",
    "Laguna composition",
    "Main contents of the Spanish",
    "Rio contents",
    "Lago composition",
    "Eau, across the Pyrenees",
    "Contents of the Spanish Main",
    "About three-fourths of la Tierra",
    "Chihuahua drink",
    "Lago composition",
    "Composition of the Spanish Main",
    "Mar makeup",
    "Contents of una fontana",
    "It'll douse a fuego",
    "Rio contents",
    "It's off the coast of Spain",
    "Lago filler",
    "Liquido vital",
    "The rain in Spain",
    "What un lago holds",
    "Lago contents",
    "Orinoco flow",
    "Spanish water",
    "H-dos-O?",
    "It's wet in Juarez",
    "Mexican water",
    "Rio composition",
    "Lago contents",
    "Lago contents",
    "The rain in Spain",
    "Request from a thirsty Spaniard",
    "Rio contents",
    "The rain in Spain",
    "HdosO",
    "Fuego extinguisher",
    "Water for Juan",
    "Spanish water",
    "El oceano, por ejemplo",
    "Lago contents",
    "Oaxaca water",
    "Tijuana water",
    "Water, in Cadiz",
    "Water, to Joaquin",
    "Water, in Oaxaca",
    "Golfo de Cadiz",
    "Juanita's water",
    "It flows in the Ebro",
    "Juarez water"
  ],
  "bile": [
    "Ill humor",
    "Vitriol",
    "Bad temper",
    "Ill humor",
    "Ill temper",
    "Nastiness",
    "Ill temper",
    "Vitriol",
    "Ancient humor",
    "Vitriol",
    "Peevishness",
    "Ill will",
    "Ill humor",
    "Choler",
    "Liver secretion",
    "Vitriol",
    "Peevishness",
    "Caustic comments",
    "Nastiness",
    "Nasty comments",
    "Digestion aid",
    "Ill humor",
    "Digestion aid",
    "Spleen",
    "Irascibility",
    "Digestive juice",
    "Venom",
    "Ill temper",
    "Ill temper",
    "Choler",
    "Gall",
    "Peevishness",
    "Ill temper"
  ],
  "byte": [
    "Unit that may be preceded by kilo-, mega- or giga-",
    "The \"B\" of MB",
    "Computer data unit",
    "Memory unit",
    "Part of a gig",
    "Memory unit",
    "Tiny information unit",
    "Tiny memory unit",
    "Tiny storage unit",
    "Small memory amount",
    "10100110, e.g.",
    "Small storage space",
    "Small storage unit",
    "One-billionth of a gig",
    "Giga- follower",
    "Eight bits",
    "Tiny part of a computer's memory",
    "Part of a gig",
    "60-Across of computer memory",
    "Computer memory measure",
    "Word often prefixed with kilo-",
    "Computer unit",
    "Computer memory unit",
    "Memory unit",
    "Memory unit",
    "Computer unit",
    "Information unit",
    "The \"B\" in KB and MB",
    "Memory unit",
    "Kilo- or mega- follower",
    "One-thousandth of a K",
    "A computer processes it",
    "Tech magazine",
    "Information unit",
    "Memory unit",
    "It doesn't take long to process",
    "Information unit",
    "Group of bits, in data storage",
    "Data amount",
    "Memory unit",
    "Storage unit",
    "Data unit",
    "Computer magazine",
    "Computer unit",
    "Computer unit",
    "Memory unit",
    "Computer info quantity",
    "\"PC World\" rival",
    "Memory unit",
    "Computer unit"
  ],
  "noir": [
    "Black: Fr.",
    "Film ___",
    "Hardly a rosy picture",
    "Black: Fr.",
    "Like some detective fiction",
    "Neo-___ (film genre)",
    "Pinot ___",
    "Some detective fiction, informally",
    "Crime film genre",
    "Modifier for \"film\" or \"pinot\"",
    "Film ___",
    "\"Strangers on a Train\" film genre",
    "1950's \"The Asphalt Jungle,\" for one",
    "Gritty genre",
    "Gritty genre",
    "Pinot ___",
    "Couleur du cafe",
    "Neo-___",
    "Genre for \"Chinatown\" or \"The Big Sleep\"",
    "Pinot ___",
    "Opposite of blanc",
    "Cafe ___",
    "Genre for the novelist Patricia Highsmith",
    "Crime film genre",
    "Dark film genre, informally",
    "Genre that \"The Long Goodbye\" is based on",
    "Detective fiction genre",
    "Rouge alternative",
    "Dark film genre",
    "Rouge counterpart",
    "Black: Fr.",
    "Dark genre",
    "Alternative to rouge in roulette",
    "Roulette bet that's not rouge",
    "Film ___",
    "Film genre",
    "Genre spoofed by \"The Cheap Detective\"",
    "Film genre",
    "Mitchum's genre",
    "Film ___",
    "Dark film genre",
    "Pinot ___ (wine)",
    "Hard-boiled crime genre",
    "\"The Big Sleep\" film genre",
    "Film style",
    "Genre of \"The Set-Up,\" 1949",
    "Pinot ___",
    "Hard-boiled, in a way",
    "Genre for \"The Maltese Falcon\"",
    "Dark picture",
    "Cafe ___",
    "Bet that's not rouge",
    "Dark genre",
    "Film style",
    "Film category",
    "Pinot ___",
    "Film ___",
    "Film ___ (movie genre)",
    "Dark film genre, for short",
    "Pinot ___ (wine)",
    "Film ___",
    "Dark film genre, informally",
    "Film ___",
    "Black, as roulette numbers",
    "Film genre",
    "Some fiction, informally",
    "Black, as la nuit",
    "Film ___",
    "Guy ___ (Garrison Keillor character)",
    "Roulette color",
    "Color of some wine grapes",
    "Charles de Gaulle's jet",
    "Not rouge",
    "Film ___",
    "Film follower",
    "Like some roulette numbers",
    "Roulette bet",
    "Casino call",
    "Film ___",
    "Roulette bet",
    "Film genre",
    "Film genre",
    "Cafe ___",
    "Film ___",
    "Film ___",
    "Roulette bet",
    "Jet, at Orly?"
  ],
  "dial": [
    "Telephone",
    "Tuner that's turned",
    "Product offering \"round the clock protection\"",
    "Bygone TV part",
    "Face of a clock",
    "Word after speed or drunk",
    "\"Don't touch that ___!\"",
    "People are told not to touch it",
    "Rotary phone feature",
    "Competitor of Ivory and Coast",
    "Part of a gauge",
    "\"Don't touch that ___!\"",
    "Face with numbers",
    "First antibacterial soap brand",
    "Ratchet (up)",
    "Word after direct or drunk",
    "Something people have often been told not to touch",
    "Ratchet (up)",
    "Speed ___",
    "Lifebuoy competitor",
    "Call",
    "Oven part",
    "Zest alternative",
    "Clock face",
    "Oven feature",
    "Gauge part",
    "Lock feature",
    "Call up",
    "\"Don't touch that ___!\"",
    "Old-fashioned phone feature",
    "Radio feature",
    "Use a rotary phone",
    "Combination lock feature",
    "Radio part",
    "Popular soap",
    "Rotary telephone part",
    "Fine-tuner",
    "Use an old phone",
    "Radio tuner",
    "Dove competitor",
    "Retro phone feature",
    "Radio tuner",
    "Radio feature",
    "Use a rotary phone",
    "Dashboard item",
    "Watch face",
    "Call",
    "Locker inset",
    "Decoder feature",
    "Obsolescent phone part",
    "Obsolescent phone feature",
    "Radio feature",
    "Obsolescent phone feature",
    "Tuner",
    "Face of time?",
    "Control knob",
    "Clock face",
    "Watch face",
    "Disappearing phone feature",
    "Tuner",
    "\"___ M for Murder\"",
    "Ring up",
    "I-XII locale",
    "Watch face",
    "Dove rival",
    "Phone",
    "Lever 2000 competitor",
    "Ring up",
    "Numbered circle",
    "Push-button alternative",
    "Watch's face",
    "Clock part",
    "Radio knob",
    "540-1600 on a radio"
  ],
  "even": [
    "Like n, where n = 2k (and \"k\" is a whole number)",
    "Like the two 40-Across in the grid for this answer",
    "Deadlocked",
    "Neck and neck",
    "It's not odd",
    "\"I can't ___\"",
    "Like 2, 4, 6, 8 ...",
    "Level",
    "Balanced",
    "Deadlocked",
    "Like 2, 4, 6, 8 ...",
    "What avengers get",
    "Squared up",
    "50-50",
    "Flush",
    "In a dead heat",
    "Fair",
    "Divisible by two",
    "Perfectly matched",
    "Having gone tit for tat",
    "\"I can't ___\"",
    "Well-matched",
    "Well-planed",
    "Neither up nor down",
    "Still",
    "Fair and balanced",
    "Square",
    "Like only one prime number",
    "What people who are hurt may try to get",
    "Flush",
    "Uniform",
    "Flush",
    "Flush",
    "All squared up",
    "Like 2016, but not 2015",
    "Deadlocked",
    "Still",
    "With 34-Across, 50-50 chance ... or a hint to answering six equations in this puzzle",
    "Like 2014 but not 2015",
    "Square",
    "Tied",
    "Level",
    "Like Olympic years",
    "50-50",
    "Tied up",
    "Equitable",
    "Like 2, 4, 6, 8 ...",
    "Neck and neck",
    "Tied, scorewise",
    "Smooth",
    "Not odd",
    "Like 2, 4, 6, 8, etc.",
    "Unchanging",
    "Squared up",
    "Squared up",
    "Like certain odds, paradoxically",
    "Having settled the score",
    "Like 2, 4, 6, 8, etc.",
    "Tied, as a score",
    "Not odd",
    "Deadlocked",
    "Not notched",
    "50-50",
    "Unlike one",
    "*Tied",
    "Square",
    "Like each answer in this puzzle - also each word in each clue - in length",
    "Tied, as a score",
    "Neck and neck",
    "Tie up",
    "Still",
    "Smooth",
    "Tied up",
    "Tied",
    "Drawn",
    "On a plane?",
    "Balanced",
    "Square",
    "50/50",
    "Flush (with)",
    "Tied, as a score",
    "Square",
    "Tied up",
    "Square",
    "___ so",
    "Tied up",
    "All paid up",
    "Knotted up",
    "Roulette bet",
    "Having nobody owing anybody",
    "Equitable",
    "20-20, e.g.",
    "Fifty-fifty",
    "Square",
    "Not sticking out",
    "Square",
    "Flush",
    "Tied in score",
    "Tied",
    "Smooth",
    "Not odd",
    "Knotted, scorewise",
    "On a par",
    "Square",
    "Not easily angered",
    "Unexcitable",
    "In a tie",
    "Unvarying",
    "Paid in full",
    "Neck and neck",
    "Squared",
    "Smooth",
    "Fifty-fifty",
    "Neck and neck",
    "Square",
    "Flush",
    "Having neither side ahead",
    "Not bumpy",
    "Not lopsided",
    "Flush",
    "Smooth",
    "In a dead heat",
    "Not odd",
    "Not bumpy",
    "Flush",
    "Neck and neck",
    "Tied up",
    "Roulette bet",
    "Roulette bet",
    "Flush (with)",
    "Not odd",
    "Like some money",
    "Integrally divisible by two",
    "Level",
    "With no ends sticking out",
    "Like 60",
    "Flush",
    "Tied",
    "Constant",
    "Lacking bumps",
    "Flush",
    "Tied",
    "What feuding families may get",
    "Uniform",
    "Fifty-fifty",
    "Still",
    "Balanced",
    "So much as",
    "Composed",
    "Flush",
    "Uniform",
    "Like left-hand page numbers",
    "Neck and neck",
    "What one isn't",
    "Flat",
    "Neck and neck",
    "Tied, as a score",
    "Fair",
    "Odd partner",
    "Tied",
    "Balanced",
    "\"___as we speak...\"",
    "Neck and neck",
    "On the level",
    "Fair",
    "Uniform",
    "Level",
    "Tied",
    "Tied",
    "Tied",
    "Deadlocked",
    "Regular",
    "50-50"
  ],
  "rick": [
    "Singer Astley",
    "Stack of hay or straw",
    "\"Here's looking at you, kid\" speaker",
    "\"Casablanca\" bistro owner",
    "Heap of hay",
    "Cafe proprietor in \"Casablanca\"",
    "Ilsa's love",
    "Basketball coach Pitino",
    "Pile of hay",
    "Gov. Perry of Texas",
    "Actor Moranis",
    "\"Casablanca\" role",
    "\"Casablanca\" cafe owner",
    "Ilsa's love",
    "\"Casablanca\" cafe owner",
    "\"Casablanca\" role",
    "Humphrey's \"Casablanca\"",
    "Barry of basketball",
    "Basketball's ___ Barry"
  ],
  "icer": [
    "Cake topper",
    "Pastry decorator",
    "Cake topper",
    "One who writes sweet words?",
    "Finisher of cakes",
    "\"Happy Birthday\" writer, maybe",
    "One doing a mob hit",
    "Cake finisher",
    "Bakery employee",
    "Decorator with good taste?",
    "Certain bakery worker",
    "Bakeshop worker",
    "Champagne bucket",
    "Cake decorator",
    "Cake finisher",
    "One with a glazed-over expression?",
    "Champagne bucket",
    "\"Happy Birthday\" writer, say",
    "Cupcake finisher",
    "Cake decorator",
    "One putting finishing touches on a cake",
    "Kitchen tool with a tube",
    "Bakery employee",
    "Patisserie employee",
    "Pastry prettifier",
    "Certain rose creator",
    "Froster",
    "Rink athlete, informally",
    "Hit man",
    "One putting on finishing touches",
    "Hockey skater, informally",
    "Cake decorator",
    "Hit man",
    "Bakery gizmo",
    "Pastry finisher",
    "Cake decorator",
    "Pastry chef's aide",
    "Pastry chef, at times",
    "Hit man",
    "Bakery employee",
    "Rose creator, maybe",
    "Producer of sweet messages?",
    "Bakery employee",
    "Bakery worker",
    "Patisserie gizmo",
    "Cake finisher",
    "\"Happy Birthday\" writer",
    "Cake decorator",
    "Cake decorator",
    "One treating swelling, perhaps",
    "Patisserie employee",
    "Cake decorator",
    "Petit four finisher",
    "\"Happy Anniversary\" writer",
    "Cupcake topper",
    "\"Happy Birthday\" writer",
    "Bakery implement",
    "One who puts on a spread?",
    "Cake finisher",
    "Champagne bucket",
    "Hit man, so to speak",
    "Hit man",
    "Cake finisher",
    "Cake finisher",
    "Cake finisher",
    "Patisserie employee",
    "Bakery worker",
    "Pastry chef, at times",
    "Pastry chef's aide",
    "Bakery worker",
    "Cake decorator",
    "You might have to eat this person's words",
    "Bakery worker",
    "\"Happy Birthday\" writer",
    "Bakery gizmo",
    "Cake decorator",
    "Writer of \"Happy Birthday\"",
    "Petit four finisher",
    "Bakery worker",
    "Cake decorator"
  ],
  "drug": [
    "What the doctor ordered",
    "Love is sometimes compared to one",
    "Miraculously effective medicine",
    "It may put you to sleep",
    "Dope, say",
    "Narcotize",
    "Sedate, say",
    "It may knock you out",
    "Pharmacopoeia selection",
    "Knock out, in a way",
    "Slip a Mickey to",
    "Knock out, say",
    "It may pick you up",
    "Pharmaceutical",
    "Psychedelic, say",
    "Disease fighter",
    "Designer ___",
    "Knock out, in a way",
    "The \"D\" in F.D.A.",
    "Regulated item",
    "The \"D\" of D.E.A.",
    "Pharmacist's sale",
    "Knock out"
  ],
  "espo": [
    "Bruins legend Phil, to fans",
    "Rink star Phil, to fans",
    "Boston Bruins legend, to his teammates",
    "Boston legend Phil, to fans",
    "Hockey Hall of Fame nickname",
    "Boston Garden nickname",
    "Hockey's Phil, to fans",
    "Former Orr teammate, familiarly",
    "1969 and 1974 Hart Trophy winner, familiarly",
    "1969 N.H.L. M.V.P., familiarly",
    "Bruin Phil, familiarly",
    "Classic Bruin nickname",
    "Hockey great Phil, familiarly",
    "Hockey great Phil, familiarly",
    "Bruin Phil, affectionately",
    "Orr teammate, familiarly",
    "60's-70's N.H.L. nickname",
    "Famed Bruin's nickname",
    "Former Orr teammate, familiarly",
    "Hockey great Phil, familiarly",
    "Ice star Phil, to friends"
  ],
  "stop": [
    "\"Cut it out!\"",
    "\"Cut that out!\"",
    "Hang it up",
    "What the Supremes said to do \"in the name of love\"",
    "What an outstretched arm with an open palm can mean",
    "Octagonal street sign",
    "Put the kibosh on",
    "Subway station",
    "What red might mean",
    "Word before or after short",
    "\"Enough already!\"",
    "\"That's enough!\"",
    "\"Hold it right there!\"",
    "\"Hold it!\"",
    "\"Enough!\"",
    "The Supremes' \"___! In the Name of Love\"",
    "\"You're killing me!\"",
    "Check",
    "What a red traffic light means",
    "Word with \"f\" or full",
    "Where people get off",
    "\"Enough already!\"",
    "Knock off",
    "Quit",
    "\"Enough!\"",
    "Station",
    "\"Halt!\"",
    "Subway ___",
    "Anagram of \"pots\"",
    "Octagonal sign",
    "Heed a red light",
    "Octagonal street sign",
    "\"Enough already!\"",
    "\"Hold it!\"",
    "\"Freeze!\" ... or, when broken into three parts, how the answer for each of the six starred clues goes",
    "What a red light signifies",
    "Telegraphy word",
    "Cry to a tickler",
    "\"Freeze!\"",
    "Knock off",
    "Call it a day",
    "With 58-Down, drop by",
    "What a raised hand may signal",
    "Knock off",
    "\"I've had enough!\"",
    "Station",
    "\"Enough already!\"",
    "Obstruct",
    "[Red]",
    "\"Freeze!\"",
    "\"Uncle!\"",
    "Pull over",
    "Word before \"You're killing me!\"",
    "With 28-Across, like some traffic",
    "Sign outside a post office",
    "Pull up",
    "Ticklee's cry",
    "\"Enough, you're killing me!\"",
    "Halt",
    "Organ piece",
    "Metro map feature",
    "Cry after the sound of a bell",
    "\"Cut it out!\"",
    "\"Avast!\"",
    "Ticklee's cry",
    "Period in English literature",
    "\"Cut it out!\"",
    "Go no further",
    "\"Halt!\"",
    "\"Hold everything!\"",
    "With 2-Down, handle perfectly, as a car",
    "\"That's enough!\"",
    "Cease",
    "\"That's enough!\"",
    "Pull over",
    "Lie to, in nautical lingo",
    "Ticklee's cry",
    "\"That's enough!\"",
    "Break",
    "\"No more!\"",
    "Station",
    "Obey a red light",
    "Cry from a tickled person",
    "Train station",
    "Organ part",
    "Train station",
    "\"Enough!\"",
    "Knock off",
    "\"I don't want to hear any more!\"",
    "Market order",
    "Station",
    "Point on a bus map",
    "*Sign ... organ ... telegram",
    "Organ knob",
    "Red light",
    "Desist",
    "Station",
    "Pull the plug on",
    "Sign on the corner",
    "Knock off",
    "Don't go on",
    "Organ knob",
    "Check",
    "\"Quit it!\"",
    "Direction at many a corner",
    "Cop's cry",
    "Knock off",
    "Clog (up)",
    "Cease and desist",
    "See red?",
    "\"All ___!\" (captain's cry)",
    "Telegram punctuation",
    "Station",
    "Freeze",
    "What red means",
    "Respond to seeing red?",
    "\"Cut that out!\"",
    "Quash",
    "Nonplus",
    "Depot",
    "Red, to a motorist",
    "Boarding place",
    "Red light directive",
    "Station",
    "Cautious advice",
    "Call it a day",
    "Word in an octagon",
    "Halt",
    "\"Knock it off!\"",
    "It may be full",
    "Knock off",
    "Red's signification",
    "\"Cut it out!\"",
    "Halt",
    "Hold back",
    "\"Knock it off!\"",
    "VCR button",
    "Huxley's \"Time Must Have a ___\"",
    "Point on a bus map",
    "Tour part",
    "Pull up",
    "Pull up",
    "What to do when you see red",
    "\"No more!\"",
    "\"Cool it!\"",
    "Quash",
    "\"Avast!\"",
    "Red's signification",
    "Quit",
    "Organ setting",
    "Common sign"
  ],
  "burs": ["Stickers", "Prickly plant parts", "Stickers", "Nut cases?"],
  "pepa": [
    "Salt's musical partner",
    "Stage name for hip-hop's Sandra Denton",
    "Salt's hip-hop counterpart",
    "Sandra Denton, in hip-hop's \"Whatta Man\" trio",
    "Stage name of rapper Sandra Denton",
    "Hip-hop's Salt-N-___",
    "Rap's Salt-N-___",
    "Musical partner of DJ Spinderella and Salt",
    "Salt's partner",
    "Rapper Sandra Denton's stage name",
    "Spice in a rap trio's name",
    "Pop music's Salt-N-___",
    "1990's group Salt-N-___"
  ],
  "laos": [
    "Where \"khop jai\" means \"thank you\"",
    "So-called \"Land of a Million Elephants\"",
    "Vientiane's land",
    "Landlocked Asian country",
    "One of the only remaining \"people's republics\"",
    "Part of Indochina",
    "Neighbor of Cambodia",
    "Myanmar neighbor",
    "Borderer of the Mekong",
    "Vietnam neighbor",
    "Landlocked Asian country",
    "Where to be among Hmong",
    "Neighbor of China",
    "One of 14 lands neighboring China",
    "Vientiane's country",
    "Where to be among the Hmong",
    "Vientiane's country",
    "Neighbor of Vietnam",
    "Neighbor of Myanmar",
    "It has a Marxist-Leninist ideology",
    "Country whose name becomes its language when you drop its last letter",
    "French colony until 1953",
    "Where Vientiane is the capital",
    "Landlocked Asian land",
    "Where Luang Prabang is",
    "Ho Chi Minh trail setting",
    "Big producer of 40-Down",
    "Landlocked land",
    "Thailand/Vietnam separator",
    "Land in the Golden Triangle",
    "1960s U.S. bombing target",
    "Vientiane's land",
    "French colony until 1953",
    "Ho Chi Minh Trail locale",
    "Neighbor of Vietnam",
    "Where kips are cash",
    "Hmong homeland",
    "Neighbor of Thailand",
    "Vientiane's country",
    "Neighbor of Cambodia",
    "Mekong River land",
    "Land of a Million Elephants",
    "Golden Triangle land",
    "Part of French Indochina",
    "Neighbor of Vietnam",
    "Vientiane's land",
    "China neighbor",
    "Mekong River locale",
    "Land west of Vietnam",
    "Myanmar neighbor",
    "Where you might be among Hmong",
    "Part of French Indochina until 1949",
    "Golden Triangle country",
    "Home of the Hmong",
    "Part of old French Indochina",
    "Land with monsoons",
    "Its flag has a big white circle in the middle",
    "Neighbor of Vietnam",
    "Vietnam War locale",
    "Mekong River land",
    "Vientiane's locale",
    "Vientiane's land",
    "Where to spend kips",
    "Mekong River locale",
    "Landlocked land",
    "Communist land",
    "Big tin exporter",
    "The Mekong flows through it",
    "Vientiane's land",
    "Mekong River nation",
    "Neighbor of Cambodia",
    "Neighbor of Vietnam",
    "Golden Triangle country",
    "It's 60% Buddhist",
    "Neighbor of Vietnam",
    "Some Indochinese",
    "Where kip are spent",
    "Neighbor of Vietnam",
    "Thailand neighbor",
    "Golden Triangle country",
    "Vientiane is its capital",
    "Where to spend a kip",
    "One of the world's last remaining People's Democratic Republics",
    "It's in 19-Across",
    "Former monarchy",
    "Mekong River country",
    "Mekong River land",
    "Land bordered by the Mekong",
    "Neighbor of Vietnam",
    "Neighbor of Cambodia",
    "Ho Chi Minh trail locale",
    "Plain of Jars locale",
    "China neighbor",
    "Part of Indochina",
    "Neighbor of China",
    "Ho Chi Minh Trail locale",
    "Luang Prabang's land",
    "Landlocked Asian country",
    "Vientiane's land",
    "Where the kip is currency",
    "Plain of Jars locale",
    "Vientiane's land"
  ],
  "dons": [
    "Opposite of \"takes off\"",
    "Tattaglia and Barzini, in \"The Godfather\"",
    "Puts on",
    "Mob bosses",
    "Family heads",
    "Spanish gentlemen",
    "Barzini and others, in \"The Godfather\"",
    "Mob summit figures",
    "Puts on",
    "Oxford teachers",
    "Gets into",
    "\"The Godfather\" figures",
    "Oxford profs",
    "Puts on",
    "Mafia bigwigs",
    "Underworld V.I.P.'s",
    "Puts on",
    "Puts on",
    "Underbosses' bosses",
    "Puts on",
    "English university V.I.P.s",
    "Gets into",
    "Gets into",
    "J. R. R. Tolkien and C. S. Lewis, at Oxford",
    "Mafia chiefs",
    "Puts on",
    "Syndicate leaders",
    "Mario Puzo wrote about them",
    "Gets into",
    "Puts on",
    "Oxford figures",
    "Gets into",
    "Mafia bosses",
    "Slips into",
    "Puts on",
    "Puts on",
    "British school officials",
    "Family figures?",
    "Cambridge academics",
    "Underworld figures",
    "Capos",
    "Grandees"
  ],
  "aoki": [
    "Grammy-nominated D.J. Steve",
    "World Golf Hall-of-Famer Isao",
    "Isao of the P.G.A.",
    "Isao in the Golf Hall of Fame",
    "Isao of golf",
    "Isao of golf",
    "Isao of the Golf Hall of Fame",
    "P.G.A.'s Isao",
    "Golfer Isao",
    "Golfer Isao ___",
    "Golfer Isao ___",
    "Benihana founder Rocky ___",
    "Golfer Isao ___",
    "1983 Hawaiian Open winner",
    "Golfer Isao",
    "Golfer Isao",
    "Isao ___ of the P.G.A.",
    "Golfer Isao ___",
    "Golfer Isao ___"
  ],
  "alva": [
    "Thomas ___ Edison",
    "Thomas Edison's middle name",
    "Edison's middle name",
    "Preceder of Edison",
    "Part of 20-Across",
    "Thomas Edison's middle name",
    "Thomas Edison's middle name",
    "Middle name of the inventor of the electrographic vote recorder",
    "Thomas ___ Edison",
    "Edison's middle name",
    "The \"A\" in Thomas A. Edison",
    "Menlo Park middle name",
    "Edison's middle name",
    "T.A.E. part",
    "Part of T.A.E.",
    "Edison's middle name",
    "Menlo Park middle name",
    "Thomas ___ Edison",
    "Thomas ___ Edison",
    "Thomas Edison's middle name",
    "An inventor's middle name",
    "Menlo Park middle name",
    "Middle name at Menlo Park",
    "Menlo Park middle name",
    "T.A.E. part",
    "Thomas ___ Edison",
    "Thomas ___ Edison",
    "Part of T.A.E.",
    "Thomas ___ Edison",
    "Part of T.A.E.",
    "Edison's middle name",
    "Duke of ___, historic Spanish general",
    "Thomas ___ Edison",
    "Thomas ___ Edison",
    "Thomas ___ Edison",
    "Thomas ___ Edison",
    "Menlo Park middle name",
    "The \"A\" of Thomas A. Edison",
    "Edison's middle name",
    "Thomas Edison's middle name",
    "Thomas ___ Edison",
    "The \"A\" in Thomas A. Edison",
    "Edison's middle name",
    "Edison's middle name",
    "Thomas ___ Edison",
    "Edison's middle name",
    "Peace Nobelist Myrdal",
    "Edison's middle name",
    "Peace Nobelist Myrdal",
    "Tenor Luigi"
  ],
  "rahm": [
    "Obama chief of staff Emanuel",
    "Emanuel of Democratic politics",
    "Chicago mayor Emanuel",
    "Chicago mayor Emanuel",
    "Chicago mayor Emanuel",
    "Chicago mayor Emanuel",
    "First name in Chicago politics",
    "Chicago mayor Emanuel",
    "First name in Chicago politics",
    "Chicago mayor Emanuel",
    "First name in Chicago politics",
    "Chicago mayor Emanuel",
    "Chicago mayor Emanuel"
  ],
  "pols": [
    "Campaign pros",
    "D.C. types",
    "D.C. bigwigs",
    "Stumpers?",
    "Ones skewered in P. J. O'Rourke's \"Parliament of Whores\"",
    "Capitol insiders",
    "D.C. types",
    "Hill people",
    "D.C. players",
    "Campaign pros",
    "The in crowd?",
    "Experts in vote-getting",
    "D.C. bigwigs",
    "The in crowd?",
    "Folks in smoke-filled rooms",
    "Stumpers?",
    "Washington insiders",
    "Pro campaigners",
    "They're seen on C-Span",
    "D.C. bigwigs",
    "Ward heelers",
    "Hustings figures"
  ],
  "http": [
    "___://",
    "Internet address starter",
    "URL opener",
    "Link letters",
    "Assumed part of some addresses",
    "Certain address starter",
    "URL starter",
    "Address starter",
    "Beginning of a link",
    "Web address starter",
    "Online lead-in",
    "Letters before a colon",
    "Start of a web address",
    "\"://\" preceder",
    "URL start",
    "URL starter",
    "Web address starter",
    "Internet address starter",
    "Letters before ://",
    "Common address start",
    "Letters at the top of a page",
    "Web starter",
    "Web opening",
    "Millions of addresses start with it",
    "Link letters",
    "Letters starting an address",
    "URL starter",
    "URL starter",
    "Techie's address starter?",
    "Start of a URL",
    "Internet start-up?",
    "U.R.L. start",
    "Link letters",
    "Internet address opener",
    "U.R.L. start",
    "Internet address starter",
    "URL starter",
    "U.R.L. lead-in",
    "Web address lead-in",
    "Letters before ://www",
    "Start of many addresses",
    "Start of a Web address",
    "Web site address starter",
    "Web address start",
    "Modern address starter",
    "Assumed part of a Web address",
    "Start of many addresses today",
    "URL starter",
    "Start of many addresses"
  ],
  "enry": [
    "Professor 'iggins",
    "Professor 'iggins",
    "Professor Higgins, to Eliza",
    "Mr. 'iggins in \"My Fair Lady\"",
    "\"Just you wait, ___ 'iggins ...\"",
    "Professor 'iggins",
    "\"Just you wait, ___ 'iggins ...\"",
    "Eliza's mentor, to Eliza",
    "One of eight English kings, to a 45-Across",
    "Professor 'iggins",
    "Professor 'iggins",
    "Professor 'iggins",
    "Broadway's Prof. 'iggins",
    "Eliza's tutor, to Eliza",
    "Prof. 'iggins",
    "Professor 'iggins",
    "Professor Higgins, to Eliza",
    "\"Just you wait, ___ 'iggins...\"",
    "Liza's mentor, to Liza",
    "Professor 'iggins",
    "Professor 'iggins, to Eliza",
    "Mr. 'iggins"
  ],
  "ipso": [
    "Itself: Lat.",
    "___ facto",
    "___ jure (law phrase)",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ jure",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "Literally, \"itself\"",
    "Literally, \"itself\"",
    "___ jure",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ jure (legal term)",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ jure (by the law itself)",
    "With 5-Across, phrase of clarification",
    "___ facto",
    "Archie Bunker's word before \"fatso\"",
    "With 28-Down, logician's phrase",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ jure (legal phrase)",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ facto",
    "With 6-Down, a logician's phrase",
    "___ jure (by law)",
    "___ facto",
    "___ facto",
    "___ facto",
    "___ jure (by law)",
    "___ facto",
    "___ facto",
    "___ facto",
    "___facto",
    "___facto",
    "___ facto",
    "___ jure",
    "___ facto",
    "___ facto",
    "___ jure (by operation of law)",
    "___ facto",
    "___ facto"
  ],
  "inge": [
    "Pulitzer-winning playwright from Independence, Kan.",
    "\"Picnic\" dramatist William",
    "Writer nicknamed the \"Playwright of the Midwest\"",
    "\"Picnic\" playwright William",
    "\"Bus Stop\" playwright",
    "\"The Dark at the Top of the Stairs\" playwright",
    "\"Bus Stop\" playwright",
    "\"Bus Stop\" dramatist William",
    "\"Picnic\" playwright",
    "O'Neill contemporary",
    "\"Picnic\" playwright",
    "\"Picnic\" Pulitzer winner William",
    "His gravestone says simply \"PLAYWRIGHT\"",
    "\"Picnic\" playwright",
    "Playwright William",
    "Playwright William",
    "Playwright William",
    "\"Picnic\" playwright",
    "William who wrote \"The Dark at the Top of the Stairs\"",
    "\"Picnic\" playwright",
    "Playwright who wrote \"What is originality? Undetected plagiarism\"",
    "\"Splendor in the Grass\" screenwriter",
    "\"Picnic\" playwright",
    "\"Splendor in the Grass\" screenwriter",
    "Writer William",
    "\"My Son Is a Splendid Driver\" novelist, 1971",
    "\"Bus Stop\" playwright",
    "So-called \"Playwright of the Midwest\"",
    "\"Picnic\" playwright William",
    "\"Picnic\" playwright",
    "Playwright William",
    "\"Natural Affection\" playwright",
    "\"Bus Stop\" playwright",
    "Playwright William",
    "\"Bus Stop\" playwright",
    "Playwright William",
    "\"Good Luck, Miss Wyckoff\" novelist",
    "\"Bus Stop\" playwright",
    "William who wrote \"The Dark at the Top of the Stairs\"",
    "\"The Dark at the Top of the Stairs\" playwright",
    "Pulitzer playwright of 1953",
    "Playwright William",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"Splendor in the Grass\" Oscar winner",
    "\"A Loss of Roses\" playwright",
    "\"Bus Stop\" playwright",
    "\"Come Back, Little Sheba\" playwright",
    "William ___ Center for the Arts, in Kansas",
    "\"Splendor in the Grass\" writer",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"The Dark at the Top of the Stairs\" playwright",
    "\"Splendor in the Grass\" writer",
    "Kansas-born playwright",
    "\"Picnic\" Pulitzer-winner William",
    "\"Bus Stop\" playwright",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"Splendor in the Grass\" screenwriter",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"Bus Stop\" writer",
    "Pulitzer winner for \"Picnic\"",
    "\"Good Luck, Miss Wyckoff\" writer",
    "\"Picnic\" playwright",
    "\"Bus Stop\" playwright",
    "\"Splendor in the Grass\" screenwriter",
    "\"Bus Stop\" playwright",
    "\"Splendor in the Grass\" Oscar winner",
    "\"Where's Daddy?\" dramatist",
    "\"Splendor in the Grass\" Oscar winner",
    "\"Picnic\" playwright",
    "\"Bus Stop\" playwright",
    "\"Bus Stop\" playwright",
    "\"Picnic\" playwright",
    "\"A Loss of Roses\" playwright",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"Come Back, Little Sheba\" playwright",
    "\"Good Luck, Miss Wyckoff\" novelist",
    "\"A Loss of Roses\" playwright",
    "Playwright William",
    "\"Come Back, Little Sheba\" playwright",
    "\"The Dark at the Top of the Stairs\" writer",
    "\"Splendor in the Grass\" screenwriter",
    "1953 Pulitzer winner for Drama",
    "1953 Pulitzer playwright",
    "Longtime dean of St. Paul's",
    "1953 Pulitzer playwright",
    "\"Picnic\" planner",
    "\"Picnic\" playwright",
    "\"Picnic\" playwright",
    "\"The Dark at the Top of the Stairs\" playwright",
    "\"Picnic\" writer",
    "\"The Dark at the Top of the Stairs\" writer",
    "\"Where's Daddy?\" playwright",
    "\"Bus Stop\" playwright",
    "1953 Pulitzer dramatist",
    "1953 Pulitzer playwright",
    "\"Bus Stop\" writer",
    "Pulitzer playwright William",
    "\"Picnic\" writer",
    "\"Bus Stop\" playwright",
    "?ôSplendor in the Grass?ö screenwriter",
    "Pulitzer playwright",
    "\"Picnic\" writer",
    "Oxford's \"Gloomy Dean\"",
    "\"Splendor in the Grass\" screenwriter",
    "Pulitzer dramatist of 1953",
    "\"Good Luck Miss Wyckoff\" writer",
    "\"Picnic\" playwright",
    "\"Little Sheba\" playwright",
    "\"Come Back, Little Sheba\" playwright",
    "\"Come Back, Little Sheba\" playwright",
    "\"The Dark at the Top of the Stairs\" playwright"
  ],
  "bora": [
    "Tora ___, Afghanistan",
    "When doubled, a Pacific paradise",
    "When repeated, a Polynesian getaway",
    "When repeated, a Pacific tourist destination",
    "When doubled, a South Seas island",
    "When doubled, a South Pacific isle",
    "When doubled, island near Tahiti",
    "Tora ___ (Afghan area)",
    "When repeated, a Polynesian island",
    "When doubled, a 19-Across",
    "When doubled, a Polynesian island",
    "Adriatic wind",
    "When doubled, a Pacific island",
    "When repeated, a South Seas getaway",
    "When repeated, part of French Polynesia",
    "When repeated, an idyllic place",
    "When repeated, an island NW of Tahiti",
    "When repeated, one of the Society Islands",
    "Cold Adriatic wind"
  ],
  "seen": [
    "Not overlooked",
    "Observed",
    "Taken in",
    "Dated",
    "Recognized",
    "Acknowledged",
    "Spotted",
    "\"No man hath ___ God ...\"",
    "Perceived",
    "Recognized",
    "What children should be (but not heard), they say",
    "Spotted",
    "Witnessed",
    "Spotted",
    "Spotted",
    "Attended (to)",
    "What children should be, and not heard, they say",
    "Spotted",
    "Witnessed",
    "Not overlooked",
    "Taken in",
    "Attended (to)",
    "In view",
    "Unlike the wind",
    "Out in public",
    "In public",
    "Observed",
    "In view",
    "Spotted (containing the last part of the \"double quote\")",
    "In public",
    "On TV, say",
    "Observed",
    "Viewed",
    "Out and about",
    "Spotted",
    "\"As ___ on TV\"",
    "Witnessed",
    "Observed",
    "What children should be, and not heard, they say",
    "Spotted",
    "Understood",
    "Caught",
    "Unlike radio shows",
    "Exposed",
    "Spotted",
    "On TV, say",
    "On videotape, say",
    "Caught",
    "Discerned",
    "Comprehended",
    "Admitted to a doctor's office",
    "Caught sight of",
    "Spotted",
    "Comprehended",
    "Detected",
    "In view",
    "Spotted",
    "Discovered",
    "Spotted",
    "Viewed",
    "Spotted",
    "Viewed",
    "Like some bets and patients",
    "In the public eye",
    "Eyed",
    "\"... ___ and not heard\"",
    "In the public eye",
    "In view",
    "Spotted",
    "Spotted",
    "Beheld",
    "In the public eye",
    "Glimpsed",
    "Not secret",
    "Patient status",
    "Taken in",
    "Public",
    "Observed",
    "Witnessed",
    "Glimpsed",
    "In public",
    "Noticed",
    "Witnessed",
    "Witnessed",
    "Glimpsed",
    "Caught, in a way",
    "Visited",
    "Perceived",
    "Espied",
    "Grasped",
    "Espied",
    "Witnessed",
    "\"As ___ on TV!\"",
    "Taken in",
    "Not obscured",
    "No longer secret",
    "Spotted",
    "Dated",
    "Dated",
    "Spotted",
    "Viewed",
    "Beheld",
    "Exposed",
    "In view",
    "Not hidden",
    "Spotted",
    "Visible",
    "Viewed",
    "Caught in the act",
    "Spotted",
    "Dated",
    "Spotted",
    "Laid eyes on",
    "Witnessed",
    "No longer secret",
    "Detected",
    "Glimpsed",
    "Surveyed",
    "In the public eye",
    "Gone out with",
    "Caught sight of",
    "Spotted",
    "Looked over",
    "Viewed",
    "Visualized",
    "Spotted",
    "Spotted",
    "Visualized",
    "Surveyed",
    "Spotted",
    "Met",
    "In view",
    "Detected",
    "Observed",
    "Noticed",
    "Spotted",
    "Witnessed",
    "Spotted",
    "Picked up on",
    "Spotted",
    "Examined",
    "Observed",
    "Spotted"
  ],
  "nolo": [
    "Defendant's plea, for short",
    "___ contendere",
    "Court plea, in brief",
    "Certain court plea, in brief",
    "___ contendere",
    "Court plea, in brief",
    "Court plea, briefly",
    "___ contendere",
    "Court plea, briefly",
    "___ contendere",
    "Certain plea, for short",
    "Certain plea, briefly",
    "___ contendere",
    "Plea opener",
    "___ contendere",
    "___ contendere (court plea)",
    "Plea opener",
    "Literally, \"I do not wish to\"",
    "Court plea, briefly",
    "___ contendere",
    "Short plea",
    "Court plea, for short",
    "___ contendere (court plea)",
    "___ contendere",
    "Defendant's plea, briefly",
    "Court plea, informally",
    "___ contendere",
    "___ contendere",
    "Part of a plea",
    "Start of a plea",
    "Defendant's plea, for short",
    "Certain plea, for short",
    "Court plea, informally",
    "Defendant's plea, informally",
    "Court plea, for short",
    "Court plea, in brief",
    "Court plea, briefly",
    "___ contendere",
    "___ contendere (court plea)",
    "Court plea, for short",
    "Beginning of a plea",
    "Court plea, for short",
    "Certain plea, informally",
    "Court plea, for short",
    "___ contendere",
    "___ contendere",
    "Brief plea",
    "Plea, briefly",
    "Common plea, informally",
    "Literally, \"I am unwilling\"",
    "___ contendere",
    "Legal plea, for short",
    "Legal plea, for short",
    "Defendant's plea, briefly",
    "___ contendere",
    "Agnew's plea, for short",
    "___ contendere",
    "___ contendere (court plea)",
    "Court plea, informally",
    "Court plea, informally",
    "Court plea, briefly",
    "Ambivalent plea, for short",
    "___ contendere",
    "Beginning of a plea",
    "Start of a legal plea"
  ],
  "tine": [
    "Fork point",
    "Fork point",
    "Point on a buck",
    "Point for Poseidon",
    "One of three on an oyster fork",
    "Pea picker-upper",
    "Fork prong",
    "Fork prong",
    "Fork prong",
    "One of four on a fork",
    "One of four on a fork, often",
    "Point in a kitchen",
    "Pitchfork point",
    "Food sticker",
    "Fork part",
    "Spork part",
    "Utensil's end",
    "Fork part",
    "Antler part",
    "What's the point of an eating utensil?",
    "Fork prong",
    "Table poker?",
    "Stick with it",
    "Prong",
    "Fork part",
    "Fork prong",
    "Table poker?",
    "Pitchfork part",
    "Sticker on a plate",
    "Point of eating?",
    "Part of a farm harrow",
    "Fork prong",
    "Trident feature",
    "Fork part",
    "*Small part of a spork",
    "Pitchfork part",
    "Long prong",
    "Sticker?",
    "Sticking point",
    "Fork prong",
    "Antler feature",
    "Fork prong",
    "Point",
    "Trident part",
    "Item component usually seen in threes or fours",
    "Prong",
    "Antlers point",
    "Point at the dinner table?",
    "Sticking point",
    "Rake feature",
    "Poker?",
    "Pitchfork sticker",
    "Fork part",
    "Pitchfork part",
    "Trident feature",
    "Fork feature",
    "It comes to a point",
    "Fork part",
    "Fork part",
    "Food sticker",
    "It has a point",
    "Rake part",
    "Prong",
    "Food sticker",
    "Fork feature",
    "Point at the dinner table?",
    "Antler's point",
    "Pitchfork prong",
    "Sticking point",
    "Rake part",
    "Prong",
    "Fork prong",
    "Antler point",
    "Prong",
    "Prong",
    "Fork point",
    "Food stabber",
    "Fork part",
    "Spearer, of sorts",
    "Fork prong",
    "Prong",
    "Trident feature",
    "Point at the dinner table",
    "Prong",
    "One point of eating?",
    "Point at the table"
  ],
  "hume": [
    "David who took 15 years to write \"The History of England\"",
    "Philosopher David",
    "Brit discussing American politics",
    "Actor Cronyn",
    "Noted Brit in the news",
    "David ___, philosopher influenced by Locke",
    "\"An Enquiry Concerning Human Understanding\" philosopher",
    "Brit in the news",
    "\"An Enquiry Concerning Human Understanding\" philosopher",
    "Brit of Fox News",
    "Fox News political commentator",
    "Brit of Fox News",
    "\"An Enquiry Concerning the Principles of Morals\" philosopher",
    "\"Dialogues Concerning Natural Religion\" author",
    "\"An Enquiry Concerning Human Understanding\" author",
    "Actor Cronyn",
    "\"Essays Moral and Political\" philosopher",
    "Philosopher David",
    "Journalist Brit",
    "Actor Cronyn",
    "\"A Treatise of Human Nature\" writer",
    "\"An Enquiry Concerning Human Understanding\" essayist",
    "\"History of England\" author David",
    "TV newsman Brit",
    "Philosopher David",
    "Leader of philosophical skepticism",
    "Actor Cronyn",
    "\"A Treatise of Human Nature\" writer",
    "Actor Cronyn",
    "TV correspondent Brit"
  ],
  "etes": [
    "Somme times",
    "Seasons in Québec",
    "They end in septembre",
    "Some overseas seasons",
    "Vacation times on la Cote d'Azur",
    "Verb after vous",
    "French summers",
    "\"Vous ___ ici\" (French for \"You are here\")",
    "Times when the French go en vacances",
    "They begin in juin",
    "Senegal summers",
    "\"Vous ___ ici\"",
    "Times for many Tours tours",
    "Are, in Arles",
    "Are, in Amiens",
    "Verb with \"vous\"",
    "Summers on the Seine",
    "Conjugation part between \"sommes\" and \"sont\"",
    "Summers on the Seine",
    "Verb with \"vous\"",
    "Universite breaks",
    "Seasons in Lyon",
    "Part of the conjugation of \"etre\"",
    "Lycee breaks",
    "Verb with \"vous\"",
    "Hot times in la cite",
    "Summers in St.-Tropez",
    "Tour de France times",
    "Hot times in Haiti",
    "Quarters in Quebec?",
    "Verb with \"vous\"",
    "Times when the French fry?",
    "Nantes seasons",
    "Verb with \"vous\"",
    "Seine summers",
    "Summers in France",
    "\"Vous ___ ici\"",
    "When the French toast?",
    "24-Down, in Dijon",
    "Hot times on the Riviera",
    "Summers in la cite",
    "Hot times on the Riviera",
    "Busy times on the French Riviera",
    "Verb with \"vous\"",
    "\"Vous ___ ici\" (French map indication)",
    "Summers on the Seine",
    "Are, in Arles",
    "Dijon seasons",
    "Somme times",
    "\"Vous ___ ici\"",
    "Part of a French 101 conjugation",
    "Form of the French \"to be\"",
    "Hot times in France",
    "Portions of les annees",
    "French summers",
    "Form of \"to be\" with \"vous\"",
    "Soissons seasons",
    "Are, in Alencon",
    "Are, in Arles",
    "Summers on the Riviera",
    "Riviera seasons",
    "Verb with vous",
    "Summers on the Riviera",
    "Seine summers",
    "Summers on the Riviera",
    "Are, in Arles",
    "Giverny summers",
    "Summers in Quebec",
    "Verb with vous",
    "Summers abroad",
    "Summers on the Seine",
    "Verb with \"vous\"",
    "Times of l'annee",
    "Summers abroad",
    "They precede automnes",
    "Times when le mercure rises",
    "Vacation times in Verdun",
    "Summers in Europe",
    "Hot times in Haiti",
    "Hot seasons in Quebec",
    "Holiday times abroad",
    "When people take tours in Tours?",
    "Summers in Rouen",
    "Times for les vacances",
    "Form of the verb \"etre\"",
    "Overseas seasons",
    "Summers in Quebec",
    "French 101 verb",
    "Summers in France",
    "French summers",
    "Summers in la cite",
    "Part of the French ?ôto be?ö",
    "They end in Septembre",
    "Times for vacances",
    "Part of French \"to be\"",
    "They begin in juin",
    "Summers abroad",
    "When the French fry?",
    "French quarters",
    "Alpine off-seasons",
    "Hot times abroad",
    "Tours seasons",
    "Hot times on the Riviera",
    "French summers",
    "Tour de France times",
    "French seasons",
    "When tourists take tours of Tours",
    "When ecoles let out",
    "Juillet times",
    "Summers on the Riviera",
    "Times abroad",
    "Hot times in Bordeaux",
    "Summers in Montreal",
    "Somme summers",
    "Seasons on the Somme",
    "Vacation times abroad",
    "Noisy-le-Sec summers",
    "Parisian summers",
    "Hot times in Saint-Tropez",
    "Vous ___ (you are): Fr.",
    "Vacation times abroad",
    "Tours summers",
    "Times spent on la plage",
    "Parisian summers",
    "Followers of printemps",
    "Strasbourg summers",
    "Dijon dog days",
    "Hot times on the Riviera",
    "Times when ecoles are not in session",
    "Summers, in Haiti",
    "Hot times on the Riviera",
    "Noisy-le-Sec seasons",
    "Somme summers"
  ],
  "eine": [
    "Strauss's \"___ Nacht in Venedig\"",
    "German article",
    "\"___ Nacht in Venedig\" (operetta)",
    "German article",
    "Strauss's \"___ Alpensinfonie\"",
    "German article",
    "Article in Die Welt",
    "Aachen article",
    "Article in Der Spiegel",
    "A, in Austria",
    "\"___ kleine Nachtmusik\"",
    "Mozart title starter",
    "One from Germany",
    "Mozart's \"___ kleine Nachtmusik\"",
    "Start of a Mozart title",
    "Article in Die Zeit",
    "\"___ kleine Nachtmusik\"",
    "\"___ kleine Nachtmusik\"",
    "Article in Arnsberg",
    "Mozart's \"___ kleine Nachtmusik\"",
    "Essen article",
    "\"___ kleine Nachtmusik\"",
    "A, in Austria",
    "One in a Kindergarten?",
    "Strauss's \"Tausend und ___ Nacht\"",
    "A, to Mozart",
    "German article",
    "A, in Altdorf",
    "Berlin article",
    "One of Mozart's?",
    "Berlin article",
    "One from Germany",
    "One in Germany",
    "One in Germany",
    "24-Across article",
    "\"___ kleine Nachtmusik\"",
    "A, to Zimmermann",
    "German article",
    "Stern article",
    "Article in Die Zeit",
    "Aachen article",
    "\"___ kleine Nachtmusik\"",
    "\"Nosferatu, ___ Symphonie des Grauens\"",
    "Angela Merkel's one",
    "\"___ kleine Nachtmusik\"",
    "A in German class?",
    "Aachen article",
    "Strauss's \"___ Nacht in Venedig\"",
    "Die Zeit article",
    "German article",
    "Berlin article",
    "\"___ kleine Nachtmusik\"",
    "A, in Austria",
    "Hamburger's article",
    "Bild article",
    "German \"a\"",
    "A single, in Stuttgart",
    "German article",
    "Article in Die Zeit",
    "\"___ kleine Nachtmusik\"",
    "Deutsche article",
    "Mozart's \"a\"",
    "Start of a Mozart title",
    "German article",
    "Article in Die Zeit",
    "Article in Der Spiegel",
    "A as in Aachen",
    "German \"a\"",
    "German \"a\"",
    "German article",
    "Article in Berlin",
    "\"___ kleine Nachtmusik\"",
    "\"___ kleine Nachtmusik\"",
    "Article for Mozart",
    "German article",
    "Mozart's \"___ kleine Nachtmusik\"",
    "Mozart article",
    "Strauss's \"___ Alpensinfonie\"",
    "Marxian article",
    "\"___ kleine Nachtmusik\"",
    "German \"a\"",
    "Article in Stern",
    "Article in Der Spiegel",
    "Article for Mozart",
    "\"___ kleine Nachtmusik\"",
    "German article",
    "\"___ kleine Nachtmusik\"",
    "\"___ kleine Nachtmusik\"",
    "German article",
    "One from Hanover",
    "\"___ kleine Nachtmusik\"",
    "Deutsche article",
    "German article",
    "\"___ kleine Nachtmusic\"",
    "One, to Wilhelm",
    "German article",
    "Die Zeit article",
    "A as in Aachen",
    "German article",
    "\"___ kleine Nachtmusik\"",
    "Article written by Nietzsche",
    "Strauss's \"___ Nacht in Venedig\"",
    "Strauss's \"___ Nacht in Venedig\"",
    "Wilhelm's one",
    "Der Spiegel article",
    "Berlin one",
    "A, to Mozart",
    "\"___kleine Nachtmusik\"",
    "One abroad",
    "\"___ kleine Nachtmusik\"",
    "German article",
    "\"___ kleine Nachtmusik\""
  ],
  "tiff": [
    "Two in a row, say?",
    "Petty quarrel",
    "Spat",
    "Quarrel",
    "Small quarrel",
    "Small scrap",
    "Short row",
    "Petty quarrel",
    "Spat",
    "Little slapfest",
    "Bit of a dustup",
    "Slight fight",
    "Short row",
    "Quarrel",
    "Bickering",
    "Clash",
    "Spat",
    "Quarrel",
    "Argument",
    "Spat"
  ],
  "prop": [
    "Hold (up)",
    "Stage item",
    "Skull, for Hamlet when he says \"Alas, poor Yorick!\"",
    "Comedian's visual",
    "Top hat, to a magician",
    "Item held by an actor",
    "Play thing",
    "Gun full of blanks, maybe",
    "Kind of plane",
    "Play thing",
    "Theater stage item",
    "What holds up well?",
    "California ballot measure, informally",
    "Kind of jet",
    "Cauldron or sword in \"Macbeth,\" say",
    "Holdup?",
    "Stagehand's responsibility",
    "Top hat, cane or monocle",
    "Groucho's cigar, e.g.",
    "Set piece",
    "Play thing",
    "Telephone on a stage, e.g.",
    "Support, with \"up\"",
    "Set item",
    "Brace",
    "Skull in \"Hamlet,\" e.g.",
    "Charlie Chaplin's cane, e.g.",
    "Play thing",
    "Item in an actor's hand",
    "Play thing",
    "Support, with \"up\"",
    "Stage device",
    "Stage item",
    "Stage piece",
    "Support, with \"up\"",
    "Stage accessory",
    "Yorick's skull, for one",
    "Shore (up)",
    "Stage telephone, e.g.",
    "Kind of jet",
    "Hold (up)",
    "Support",
    "Stage piece",
    "Stage bit",
    "Plaything"
  ],
  "must": [
    "Sine qua non",
    "Requirement",
    "\"Everything ___ go!\"",
    "\"You ___ be kidding!\"",
    "Closet-y smell",
    "It's compulsory",
    "Evidence of disuse",
    "Can't-miss event",
    "Has to",
    "Event not to be missed",
    "Essential",
    "Be compelled to",
    "Have to",
    "Has to",
    "Moldiness",
    "New wine",
    "Essential",
    "Nonnegotiable item"
  ],
  "arcs": [
    "Doesn't go straight, in a way",
    "Rainbows, for example",
    "Story lines",
    "Things drawn with compasses",
    "Paths of fly balls",
    "Paths of falling stars",
    "Rainbows, e.g.",
    "Rainbow shapes",
    "Their lengths are computed by (&Theta; &divide; 360) x 2&pi;r",
    "Parabolas, essentially",
    "Some lines drawn with protractors",
    "Trajectories for fly balls",
    "Rainbows, e.g.",
    "Parabola shapes",
    "Paths of pendulums",
    "Circle segments",
    "Jump shot paths",
    "Parenthetical figures?",
    "Story lines",
    "Compass drawings",
    "Compass tracings",
    "Parentheses, e.g.",
    "Things that are tossed usually go in them",
    "Curves",
    "Hyperbola parts",
    "Ones going in circles?",
    "Paths of lobs",
    "Slow pitches have them",
    "Circle parts",
    "Fly balls, e.g.",
    "Three-point lines in basketball, e.g.",
    "Rockets' paths",
    "Typical golf shots",
    "Jump shots have them",
    "Circle segments",
    "Welding bands?",
    "Missile paths",
    "Pinball paths",
    "Some trajectories",
    "Firecrackers' paths",
    "Indirect lines",
    "Rainbows, basically",
    "Doesn't go straight",
    "Slurs, in music",
    "Electrical bridges",
    "Curved trajectories",
    "They may be drawn with compasses",
    "Tennis lobs, e.g.",
    "Paths of pop-ups",
    "Rainbow shapes",
    "Rainbow shapes",
    "Hyperbola parts",
    "Pendulum paths",
    "Bends",
    "Circle segments",
    "Travels like a skyrocket",
    "Musical ties, essentially",
    "Circle segments",
    "Meteor paths",
    "Circle parts",
    "Curved lines",
    "Not the most direct routes from point to point",
    "Electric discharges",
    "Rainbows, e.g.",
    "Pinball paths",
    "Parabolic paths",
    "Parentheses, e.g.",
    "Parentheses, essentially",
    "Pinball paths",
    "Pendulum paths",
    "Parentheses, e.g.",
    "Rainbows",
    "Rainbows",
    "Circle parts",
    "Pinball paths",
    "Planetary paths",
    "Semicircles",
    "Bows",
    "Moves like a comet",
    "Bows",
    "Bridges of electricity",
    "Rainbows",
    "Meteors' paths",
    "Compass doodles",
    "Bows",
    "Missile trajectories",
    "Rainbows",
    "Firecracker paths",
    "Electrical paths"
  ],
  "oslo": [
    "European royal capital",
    "Setting for Jo Nesbo's best-selling crime novels",
    "World capital where the Nobel Peace Prize is awarded",
    "Locale of the annual Nobel Peace Prize ceremony",
    "Capital near the North Sea",
    "World capital on a fjord",
    "Norway's capital",
    "City called a \"kommune\" by its inhabitants",
    "1952 Winter Olympics host",
    "Norway's capital",
    "Norway's capital",
    "World capital at around the same latitude as St. Petersburg",
    "Nordic capital",
    "Where Edvard Munch's \"The Scream\" is displayed",
    "Capital on a fjord",
    "Where the Nobel Peace Prize winner is announced",
    "1993's ___ Accords",
    "Norway's capital",
    "City where \"Peer Gynt\" premiered",
    "Norwegian city with the Munch Museum",
    "Best Play Tony winner with a geographical name",
    "World capital near the 60th parallel",
    "Nobel Peace Prize city",
    "Norway's capital",
    "Scandinavian capital founded in the mid-11th century",
    "Flight hub for Norwegian",
    "Norway's capital",
    "Capital on the same parallel as Seward, Alaska",
    "Munch Museum city",
    "City where you can view Edvard Munch's \"The Scream\"",
    "Capital due north of the northern tip of Denmark",
    "Home to Queen Sonja",
    "2017 Tony winner for Best Play",
    "World capital with a nearly car-free city center",
    "Nobel Peace Prize city",
    "___ Accords (1990s peace agreements)",
    "City that's home to the Viking Ship Museum",
    "Capital of the world's happiest country, per a 2017 U.N. survey",
    "Nobel Peace Prize city",
    "World capital on a fjord",
    "Capital situated on the same latitude as St. Petersburg, Russia",
    "2017 Tony-winning play about 1990s diplomacy",
    "Capital of the Land of the Midnight Sun",
    "European capital",
    "2017 Best Play winner",
    "Norway's capital",
    "World capital on the 60th parallel",
    "World capital whose seal depicts St. Hallvard",
    "Munch Museum's city",
    "1993 accord city",
    "Destination of some SAS flights",
    "Viking Ship Museum city",
    "World capital with 40 islands within its city limits",
    "___ Accords",
    "Capital of Norway",
    "1993 and 1995's ___ Accords",
    "Norwegian capital",
    "Capital of Norway",
    "___ Accords",
    "Scandinavian capital",
    "Capital with the Norsk Folkemuseum",
    "Capital known for 300 years as Christiania",
    "Location for Munch's \"The Scream\"",
    "Norway's capital",
    "Kristiania, after 1925",
    "World capital half of which consists of forest",
    "European capital on a fjord",
    "Setting for Ibsen's \"Hedda Gabler\"",
    "Norway's capital",
    "Home for King Harald",
    "Capital near the 60th parallel",
    "City in Los Lobos?",
    "\"Hedda Gabler\" setting",
    "Capital NNW of Copenhagen",
    "Norway's capital",
    "Norway's capital",
    "Norway's capital",
    "Winter Olympics site after St. Moritz",
    "Home of the first U.N. secretary general",
    "Europe's Tiger City",
    "Four-time host of the Nordic World Ski Championships",
    "Capital with more than 300 lakes within its limits",
    "Capital of Norway",
    "Where the Storting meets",
    "1952 Winter Olympics host",
    "Home to King Harald V",
    "Capital near the 60th parallel",
    "Kon-Tiki Museum city",
    "Norway's capital",
    "Where the Storting sits",
    "Norway's capital",
    "Nobel Peace Center site",
    "Home for Ibsen",
    "Birthplace of Queen Sonja",
    "Olympics locale where the first figure-skating triple jump was landed",
    "Home of Literature Nobelist Sigrid Undset",
    "City founded by King Harald III",
    "City where \"Peer Gynt\" premiered",
    "Norway's capital",
    "Norwegian city",
    "Nobel Peace Prize city",
    "Christiania, today",
    "Nobel Institute city",
    "Capital near the 60th parallel",
    "Norway's capital",
    "Capital near Lillehammer",
    "Norway's capital",
    "Home of Hallvard's ruined cathedral",
    "Home of the Gardermoen airport",
    "Site of the Ibsen Museum",
    "Where the Storting parliament sits",
    "Cold capital",
    "Norway's capital",
    "Munch Museum's locale",
    "Nobel Peace Prize city",
    "Home of King Harald V",
    "Capital on a fjord",
    "Capital once called Christiania",
    "___ Accords of 1993",
    "___ City Hall, Nobel ceremony locale",
    "Capital on a fjord",
    "Where the traitorous Vidkun Quisling lived",
    "Nobel Peace Prize city",
    "Home of the Viking Ship Museum",
    "Peace Prize city",
    "Knesset : Jerusalem :: Storting : ___",
    "Norway's capital",
    "Capital on a 69-Across",
    "Capital NNW of Copenhagen",
    "Where the Nobel Peace Prize is awarded",
    "Norway's capital",
    "___ Accords of 1993",
    "Capital of 18-Across",
    "Munch Museum's home",
    "German-occupied capital in W.W. II",
    "Nobel Institute city",
    "Orly : Paris :: Gardermoen : ___",
    "Norway's capital",
    "___ Accords",
    "Capital near the 60th parallel",
    "Kon-Tiki Museum site",
    "1993 Israeli/Palestinian accords site",
    "1952 Winter Olympics site",
    "Where the Storting sits",
    "Nobel city",
    "Giant slalom's first Olympic venue",
    "Sonja Henie's Norwegian birthplace",
    "Capital at 60 degrees latitude",
    "Olympics city after St. Moritz",
    "1952 Winter Olympics site",
    "Cold capital",
    "Cold capital",
    "1952 Olympics site",
    "Kon-Tiki Museum site",
    "Norway's capital",
    "Capital on a fjord",
    "Capital NW of Drammen",
    "1952 Winter Olympics site",
    "Storting meeting place",
    "Christiania, now",
    "Norway's capital",
    "1952 Olympics host",
    "Sight from a fjord",
    "See 64-Down",
    "King Harald's capital",
    "Parliament city",
    "Northern capital",
    "Norway's capital",
    "Capital NNW of Copenhagen",
    "1952 Olympics site",
    "___ Accords, 1993",
    "Cold capital",
    "Winter Olympics site after St. Moritz",
    "Norway's capital",
    "Where Ibsen worked",
    "Knesset : Jerusalem :: Storting : ___",
    "Ibsen's home",
    "Norway's capital",
    "Christiania, now",
    "Capital on a fjord",
    "Northern capital",
    "Peace Prize city",
    "Quisling's city",
    "Kon-Tiki Museum site",
    "1993 accord site",
    "Capital once known as Christiania",
    "Norway's capital",
    "Norway's capital",
    "Frogner Park's home",
    "City ESE of Bergen",
    "Norway's capital",
    "Karl Johans Gate is its main thoroughfare",
    "Norwegian capital",
    "Capital near the 60th parallel",
    "Edvard Munch Museum locale",
    "Capital on a fjord",
    "1952 Olympics venue",
    "Quisling's city",
    "First Olympic venue for giant slalom",
    "Capital on a fjord",
    "Capital near Lillestrom",
    "Quisling's city",
    "1952 Winter Olympics site",
    "Capital captured by the Germans 4/9/40",
    "Nobel Peace Prize city",
    "Norway's capital",
    "1952 Winter Olympics site",
    "Capital on a fjord",
    "Capital on a fjord",
    "Northerner's home",
    "2-Down's capital",
    "Norway's capital",
    "Northern capital",
    "Sonja Henie's birthplace",
    "Nobel Institute city",
    "Mideast peace talks site",
    "Capital on a fjord",
    "Capital on the 60th parallel",
    "1993 peace accord city",
    "Capital near the 60th parallel",
    "City founded by Harald III",
    "Cold capital",
    "Edvard Munch Museum site",
    "Norse capital",
    "1952 Winter Olympics site",
    "Christiania, now",
    "Where Dick Button won gold",
    "Norway's capital",
    "Artist Edvard Munch's home",
    "Where the Storting sits",
    "Cold capital",
    "Capital near the 60th parallel",
    "Nobel Peace Prize city",
    "European port",
    "___ Fjord (inlet of the Skagerrak)",
    "Christiania, today",
    "Norse capital",
    "Akershus Castle site",
    "Sonja Henie's birthplace",
    "Where marathoner Grete Waitz was born",
    "Where the Storting meets",
    "Scandinavian capital",
    "Capital city founded in 1050",
    "Northern capital",
    "___ Fjord",
    "Capital on a fjord",
    "Seat of King Olaf's rule",
    "1952 Olympics host",
    "Sonja Henie's hometown",
    "Norway's capital",
    "Norway's capital",
    "Scandinavian port",
    "City south of Lillehammer",
    "Trygve Lie's home",
    "Capital on a fjord",
    "Where the Kon-Tiki is preserved",
    "Where the Storting sits",
    "Frogner Park locale",
    "Norse capital",
    "Capital on the 60th. parallel",
    "King Harald's capital",
    "City two hours south of Lillehammer",
    "City south of Hamar",
    "Christiania, today",
    "Sonja Henie's home",
    "Ibsen's home",
    "Kon-Tiki Museum site",
    "Christiania today"
  ],
  "pert": [
    "Sassy",
    "Sassy",
    "Fresh",
    "Cheeky",
    "Cheeky",
    "Vivacious",
    "Sassy",
    "Sassy",
    "Fresh",
    "Jaunty",
    "Cheeky",
    "Impudent",
    "Smart",
    "Sassy",
    "Saucy",
    "Flip",
    "Smart-alecky",
    "Cheeky",
    "Vivacious",
    "Prell rival",
    "Fresh-mouthed",
    "Fresh",
    "Smart-mouthed",
    "Flippantly cocky",
    "Smart",
    "Vivacious",
    "Bouncy",
    "Fresh",
    "Impudent",
    "Cheeky",
    "Sassy",
    "Saucy",
    "Prell competitor",
    "Forward",
    "Fresh",
    "Hair product from Procter & Gamble",
    "Sassy",
    "Procter and Gamble shampoo",
    "Smart",
    "Saucy",
    "Saucy",
    "Smart-alecky",
    "Saucy",
    "Sassy",
    "Saucy",
    "Saucy",
    "Saucy",
    "Flippant",
    "Hardly coy",
    "Lively",
    "Fresh",
    "Lively",
    "Fresh-mouthed",
    "Forward",
    "Impudent",
    "Saucy",
    "Forward",
    "Overly familiar, maybe",
    "Full of pep",
    "Flippant",
    "Fresh-mouthed",
    "Lively",
    "Saucy",
    "Chipper",
    "Sprightly",
    "Saucy",
    "Smart-mouthed",
    "Forward",
    "Sprightly",
    "Saucy",
    "Alternative to Prell",
    "Sassy",
    "Saucy",
    "Forward",
    "Vivacious",
    "Vivacious",
    "Saucy",
    "Saucy",
    "Suave rival",
    "Natty",
    "Chipper",
    "Sprightly",
    "Saucy",
    "Saucy"
  ],
  "hype": [
    "Pump up",
    "Build up",
    "It may lead up to a letdown",
    "Build up",
    "Bumped-up publicity",
    "Promote excessively",
    "\"The world's greatest ...,\" e.g.",
    "Boost",
    "Build up",
    "Big buildup",
    "Promotional hoopla",
    "Build up",
    "Overly promote",
    "Something not to be believed?",
    "Talk up",
    "Build up",
    "Press for a hit?",
    "Extravagant publicity",
    "Ballyhoo",
    "Ballyhoo",
    "Advertising, basically",
    "Ballyhoo",
    "Oversell",
    "Talk up",
    "Some hot air",
    "Lots of talk",
    "Publicist's job",
    "Ballyhoo",
    "Ballyhoo",
    "It's hard to live up to",
    "Ballyhoo",
    "Ballyhoo",
    "Big buildup",
    "Oversell",
    "Promote big-time",
    "Ballyhoo",
    "Hoopla",
    "Promotional overkill",
    "Overpublicize",
    "Overpromotion",
    "Ballyhoo",
    "Public relations overkill",
    "Publicity",
    "Preposterous publicity",
    "Ballyhoo",
    "Publicity"
  ],
  "loge": [
    "Box on Broadway",
    "Theater seating option",
    "Elite seats",
    "Pricey seating option",
    "Seating request",
    "Premium ticket spec",
    "Stadium section",
    "Balcony section",
    "High-priced theater section",
    "Theater box",
    "Area between an upper and lower deck",
    "Pricey seating option",
    "Special seating section",
    "Ticket option",
    "Box in a theater",
    "Theater section",
    "Pricey seating section",
    "Special seating area",
    "Orchestra alternative",
    "Theater area",
    "High-priced ticket option",
    "Second-level seating",
    "High-priced seating area",
    "Front of a mezzanine",
    "Expensive box",
    "Box",
    "Good place to sit",
    "Ticket choice",
    "Theater section",
    "Seating section",
    "Stadium section",
    "Expensive box",
    "High-priced ticket request",
    "Orchestra alternative",
    "Theater section",
    "Ticket choice",
    "Pricey theater section",
    "Box",
    "Theater section",
    "Stadium section",
    "High-priced ticket area",
    "Place to use a lorgnette",
    "Seating area",
    "Theater box",
    "Concert hall section",
    "Balcony section",
    "Orchestra alternative",
    "Theater area",
    "Opera house box",
    "Theater box",
    "Theater section",
    "Balcony section",
    "Alternative to orchestra",
    "Seating request",
    "Opera house box",
    "Box"
  ],
  "ahoy": [
    "Shouted greeting",
    "\"___ there!\"",
    "\"___, matey!\"",
    "Call for all hands on deck?",
    "Sailor's greeting",
    "Greeting to a \"matey\"",
    "Shout for 44-Across",
    "Greeting at sea",
    "Greeting at sea",
    "Salutation at sea",
    "Hail on a bridge",
    "\"Land ___!\"",
    "Hearty greeting?",
    "Exclamation that's made up of two shorter exclamations",
    "Sailor's call",
    "\"Hey there, matey!\"",
    "Chips ___! (cookie brand)",
    "Ship-to-ship call",
    "Matey's greeting",
    "Chips ___!",
    "Maritime greeting",
    "Sailor's call",
    "\"Hey, sailor!\"",
    "Cry to a matey",
    "Hello, on 27-/44-Across",
    "\"___, matey!\"",
    "It goes from one vessel to another",
    "Ship-to-ship communication",
    "Cry to a mate",
    "Seafarer's greeting",
    "Greeting to a matey",
    "Stern cry?",
    "Ship-to-ship call",
    "Cry from a deck",
    "Main call",
    "Stern cry?",
    "Cry to a matey",
    "Ship-to-ship communication",
    "Sailor hailer",
    "Sailor's greeting",
    "Skipper's cry",
    "Port-of-call call",
    "Shout before throwing a rope",
    "Cry to a matey",
    "Sailor's hail",
    "Salty greeting?",
    "Mate's greeting",
    "\"Hey, sailor!\"",
    "Bridge call",
    "Sailor's hello",
    "Sailor's hail",
    "Chips ___! (cookies)",
    "Sea line",
    "Sailor's greeting",
    "Yawl call",
    "Deck call",
    "Salty shout",
    "Mariner's cry",
    "Call to a mate",
    "Ship-to-shore line?",
    "Mate's shout",
    "Call for a mate",
    "Attention-getter at sea",
    "Yawl call",
    "Mate's call",
    "Sailor's cry",
    "Hey there, at sea",
    "Hail, on the briny",
    "Greeting at sea",
    "It passes between decks",
    "Call to a mate",
    "Hello, of sorts",
    "Sailor's salutation",
    "Tar hail",
    "Greeting at sea",
    "On-board greeting",
    "\"Hey!\" on the Hesperus",
    "Hearty greeting",
    "Sailing hailing",
    "Cry from a ship",
    "Popeye's greeting",
    "Call on a yawl",
    "\"Hey there!\"",
    "High-seas greeting",
    "Crow's-nest cry",
    "Sailor's cry",
    "Seaman's shout",
    "\"You there!\""
  ],
  "mile": [
    "Word with air or square",
    "One of 3,365 in U.S. Route 20",
    "It's shorter on land than at sea",
    "Marathon marking",
    "A miss is as good as one, they say",
    "What a marker may mark",
    "___ marker",
    "Millrose Games highlight",
    "1/500 of the Indianapolis 500",
    "Number on a marathon marker",
    "Runner's unit",
    "Go the extra ___",
    "1/500 of the Indianapolis 500",
    "About 4% of a marathon",
    "It may be marked on a racetrack",
    "Fair distance",
    "League part",
    "80% of the Kentucky Derby",
    "Race that once had a four-minute barrier",
    "Kind of marker",
    "Event not run in the Olympics",
    "Eight furlongs",
    "Hiker's measure",
    "Bannister's length",
    "Length of 14 2/3 football fields",
    "Distance between some posts",
    "Lap, maybe",
    "Square ___",
    "Eight furlongs",
    "1/500 of the Indianapolis 500",
    "Horse-race distance",
    "5,280 feet",
    "Distance not run in the Olympics",
    "Denver, the ___ High City",
    "Miss's equal",
    "Two-thirds of the Belmont Stakes",
    "1/500 of the Indianapolis 500",
    "Track event not in the Olympics",
    "Treadmill unit",
    "Marathon unit",
    "Fair distance",
    "Derby distance, maybe",
    "Miss's equivalent, in a saying",
    "What a marker may mark",
    "320 rods",
    "Miss equivalent",
    "One turn of an odometer",
    "Ryun's run",
    "One in 500?",
    "Roger Bannister's distance",
    "Four laps, sometimes",
    "Ryun's run"
  ],
  "tray": [
    "It helps make waiting easier",
    "It goes up during takeoff",
    "Eating surface on an airplane",
    "Part of a high chair",
    "It's used to carry out an order",
    "Airplane seat attachment",
    "Something to hold while waiting",
    "Platter",
    "Server load?",
    "Holder of a cafeteria meal",
    "Drive-in restaurant need",
    "Highchair surface",
    "What someone may be holding while waiting",
    "Airline seat part",
    "Airplane seat attachment",
    "Printer part",
    "A waiter carries plates on it",
    "Dumbwaiter item",
    "Plate setting",
    "Highchair feature",
    "Breakfast-in-bed item",
    "Buffet meal carrier",
    "Waiting aid",
    "TV dinner holder",
    "Waiter's aid",
    "Highchair feature",
    "Kind of table",
    "Cafeteria carrier",
    "Cafeteria carrier",
    "Server's need",
    "Server's burden",
    "Carhop's load",
    "Meal-in-bed supporter",
    "Surgical setup",
    "Cafeteria item",
    "Carhop's need",
    "Highchair feature",
    "Carhop's aid",
    "Cafeteria carrier",
    "Caterer's carrier",
    "Carhop's load",
    "Lazy Susan",
    "Buffet aid",
    "Busboy's pickup",
    "Carhop's burden",
    "Waiter's load",
    "Waiter's burden",
    "Caterer?Æs item",
    "Breakfast-in-bed item",
    "Old dog in a Stephen Foster song"
  ],
  "lens": [
    "You can see right through it",
    "Magnifier, e.g.",
    "Common prescription item",
    "Choice for a cinematographer",
    "Point of view, metaphorically",
    "Part of the eye",
    "2-Down part",
    "You can see right through it",
    "It helps you focus",
    "Part of binoculars",
    "What keeps things in focus?",
    "Part of the eye",
    "Focusing aid",
    "Part of the eye",
    "Telescope part",
    "It may be labeled 2x or 3x",
    "It helps you focus",
    "Zoom, e.g.",
    "Microscope part",
    "Part of the eye",
    "Cataract site",
    "It changes one's perspective",
    "Fisheye or zoom",
    "Microscope part",
    "Camera part",
    "Light beam bender",
    "Contact, say",
    "Eye or camera part",
    "Word after hand or zoom",
    "Something you can see through",
    "Part of the eye",
    "Eye part",
    "Main part of a monocle",
    "Cataract location",
    "Perspective provider",
    "Cinematography choice",
    "Camera part",
    "Monocle part",
    "Periscope part",
    "Periscope part",
    "See-through object",
    "Camera part",
    "The \"L\" of S.L.R.",
    "Microscope part",
    "Magnifier",
    "Microscope part",
    "Monocle, e.g.",
    "It may change your perspective",
    "Monocle part",
    "Eye part",
    "Monocle part",
    "Eye part",
    "Monocle, basically",
    "___ cap",
    "Projector part",
    "Eye part",
    "Magnifier",
    "Word with telephoto or zoom",
    "Shutterbug's purchase",
    "You can see right through it",
    "Monocle part",
    "Lorgnette part",
    "Shoot, slangily",
    "Put on film, in Variety-speak",
    "Camera's eye",
    "Lorgnette piece",
    "Contact, say",
    "Optical device",
    "Something found in specs?",
    "Cataract site",
    "Lorgnette part",
    "Telescope part",
    "Eye piece",
    "Periscope part",
    "Something to see through",
    "Film, in Variety-speak",
    "Fisheye ___",
    "Something to shoot through",
    "See-through item",
    "Monocle, basically",
    "Microscope part",
    "Place for a cap",
    "Camera attachment",
    "Eyeglass part",
    "Monocle part",
    "Contact, e.g.",
    "Eye",
    "Microscope part",
    "Cinematographer's concern",
    "Part of S.L.R.",
    "Telescope part",
    "Periscope piece",
    "Zoom, e.g.",
    "Telescope part",
    "Bifocal, e.g.",
    "See 22-Down",
    "Word with contact or zoom",
    "Film, in Hollywood lingo",
    "Slide projector part",
    "Zoom ___",
    "Frame insert",
    "Camera part",
    "Contact, perhaps",
    "Eye",
    "Brownie's eye?",
    "Microscope part",
    "Camera part",
    "Microscope part",
    "Camera's eye",
    "Cataract site",
    "Brownie point?",
    "Eye part",
    "Camera part",
    "Cariou and Dawson",
    "Hubble component",
    "Brownie's eye?"
  ],
  "brie": [
    "Big wheel at a party?",
    "Gooey spread",
    "Cheese sometimes paired with fig jam",
    "Its exterior is the edible mold Penicillium candidum",
    "Cheese with a white rind",
    "Camembert cousin",
    "Cheese that goes well with apples",
    "Spread in a spread",
    "Cracker topping spread with a knife",
    "Cheese similar to Camembert",
    "Grocer's wheel",
    "Cocktail party spread",
    "Imported wheels?",
    "Party spread",
    "Wheel that runs?",
    "Cracker topper",
    "French cheese",
    "See 19-Across",
    "Gooey cheese",
    "Relative of Camembert",
    "White spread",
    "Soft cheese",
    "Cheese at a cocktail party",
    "Easy-to-spread cheese",
    "Soft cheese",
    "Spreadable cheese",
    "Cheese popular with crackers",
    "Creamy cheese",
    "Big wheel at a party?",
    "Cracker topper",
    "Historic French region",
    "*Distant relative of Monterey",
    "Soft white cheese",
    "Creamy cheese",
    "Cheese with a moldy rind",
    "It might be baked for an appetizer",
    "Cracker topper",
    "French district that lent its name to a foodstuff",
    "Cracker topper",
    "Cheese from France",
    "Cheese named for a historic French region",
    "Spreadable cheese",
    "Imported French wheel?",
    "Camembert alternative",
    "French cheese",
    "Mold-ripened cheese",
    "Cracker topper",
    "White cheese",
    "It could run over a plate",
    "French cheese",
    "French cheese",
    "Mold-ripened cheese",
    "Cheese on crackers",
    "High-calorie cheese",
    "Cheese region",
    "Cracker topper",
    "Hors d'oeuvre topper",
    "Relative of Camembert",
    "Cracker topper",
    "Camembert kin",
    "Cheese on crackers",
    "Ripened cheese",
    "Soft cheese",
    "Hors d'oeuvre cheese",
    "Soft cheese",
    "Cheese served with crackers",
    "French cheese",
    "Soft cheese",
    "Soft cheese",
    "Soft cheese",
    "Soft cheese",
    "Spreadable cheese"
  ],
  "gwen": [
    "Journalist Ifill who was depicted on a postage stamp in 2020",
    "Singer Stefani",
    "Grammy winner Stefani",
    "Rocker Stefani",
    "Grammy-winning Stefani",
    "Late journalist Ifill",
    "Rocker Stefani",
    "\"Spider-Man\" girl",
    "Rocker Stefani",
    "Dancer Verdon",
    "Broadway singer/actress Verdon",
    "Rocker Stefani",
    "___ Torrence, American sprinter who won three gold medals at the 1992 and 1996 Olympics",
    "\"Jubilee Trail\" novelist Bristow",
    "Verdon of \"Damn Yankees\"",
    "Tony-winning actress Verdon",
    "Verdon of Broadway",
    "PBS host Ifill",
    "Verdon of \"Damn Yankees\"",
    "___ Stefani, lead singer of No Doubt",
    "Poet Harwood",
    "Broadway star Verdon",
    "Newswoman Ifill",
    "The original Lola, in \"Damn Yankees\"",
    "Broadway's Verdon",
    "Broadway star Verdon",
    "Lola player in \"Damn Yankees\"",
    "Verdon of \"Red Head\""
  ],
  "fair": [
    "\"Not ___!\"",
    "Word repeated in \"What's ___ is ___\"",
    "So-so",
    "Ump's call for a batted ball",
    "Not foul, as a baseball hit",
    "\"You make a point\"",
    "Within bounds",
    "Expo",
    "Exposition",
    "Middling",
    "Within bounds",
    "___ to middling",
    "Carnival",
    "Just",
    "Place to see a Ferris wheel",
    "Not foul",
    "Not bad",
    "50/50, say",
    "Like a fly ball off the foul pole",
    "Barometer reading",
    "Cloudless",
    "Not cheating",
    "Like a pro rata division, say",
    "Umpire's ruling",
    "C-worthy",
    "Like some maidens",
    "Place to buy cotton candy",
    "Midway point?",
    "Expo",
    "Without a cloud in the sky",
    "Ferris wheel site",
    "Playable",
    "Evenhanded",
    "Light-skinned",
    "On the foul line, ironically",
    "Worth a C",
    "C",
    "?ôEh?ö",
    "So-so",
    "Evenhanded",
    "Worth a C",
    "Middling",
    "In bounds",
    "Like some maidens of myth",
    "O.K.",
    "Worth a C",
    "Place for a ride",
    "C",
    "C-worthy?",
    "C-worthy?",
    "Like home plate",
    "Carnival",
    "Blond",
    "Kind of shake",
    "Comely",
    "So-so",
    "Inside the foul line",
    "Objective",
    "So-so",
    "Equitable",
    "Blond",
    "Comely"
  ],
  "ices": [
    "Snacks that drip",
    "Treats, as a swollen ankle",
    "Clinches",
    "Cold summer treats",
    "Tends to, as a sprain",
    "Puts frosting on",
    "Treats, as a sprain",
    "Helps reduce the swelling of, say",
    "Summer coolers",
    "Clinches",
    "Knocks off",
    "Finishes, as a cake",
    "Finishes, as a cake",
    "Boardwalk treats",
    "Freezes, with \"over\"",
    "Decorates, as a cake",
    "Summer coolers",
    "Sorbets, e.g.",
    "Summer coolers",
    "Finishes, as a cake",
    "Tends to, as a sprain",
    "Treats for swelling, as a joint",
    "Locks up",
    "Ones getting a good licking?",
    "Puts away",
    "Treats, as a sprain",
    "Bumps off",
    "Knocks off",
    "Desserts good for a hot day",
    "Cools, as drinks",
    "Hot-weather treats",
    "Puts frosting on",
    "Puts away",
    "Decorates, as a cake",
    "Clinches",
    "Clinches",
    "Sews up",
    "Puts away",
    "Knocks off",
    "Decorates, as a cake",
    "Cool treats",
    "Frosty desserts",
    "Summer coolers in tiny cups",
    "Does in",
    "Summer treats",
    "Cold treats",
    "Seals, in a way",
    "Secures",
    "Locks up",
    "Does in",
    "Summer coolers",
    "Prepares, as Champagne",
    "Cooling treats",
    "Frozen treats",
    "Summer treats",
    "Does in",
    "Cool treats",
    "Frosts, as a cake",
    "Clinches, as a victory",
    "Clinches",
    "Boardwalk treats",
    "Ensures, slangily",
    "Sweet treats",
    "Clinches",
    "Cools down",
    "Finalizes",
    "Clinches",
    "Desserts that give chills",
    "Cold desserts",
    "Clinches",
    "Clinches",
    "Settles with certainty",
    "Sews up",
    "Wraps up, so to speak",
    "Boardwalk refreshments",
    "Nails down",
    "Italian refreshments",
    "Frosts, as a cake",
    "Boardwalk coolers",
    "Clinches",
    "Sews up",
    "Summer coolers",
    "Pizzeria desserts",
    "Hot-weather desserts",
    "Chills",
    "Boardwalk purchases",
    "Secures",
    "Boardwalk treats",
    "Ensures, slangily",
    "Summer desserts",
    "Beach treats",
    "Summer treats",
    "Freezes over",
    "Secures, informally",
    "Sherbets' cousins",
    "Finishes the cake",
    "Clinches",
    "Low-fat desserts"
  ],
  "anns": [
    "St. ___, neighborhood in north London",
    "St. ___ Bay, Jamaica",
    "Writers Patchett and Brashares",
    "St. ___ (common church name)",
    "Raggedy ___ (dolls)",
    "Jamaica's St. ___ Bay",
    "St. ___ (London neighborhood)",
    "Manchester's St. ___ Church",
    "Raggedy ___ (dolls)",
    "Jamaica's St. ___ Bay",
    "St. ___ Head Lighthouse (Welsh landmark)",
    "Saint ___ Bay, Jamaica",
    "Raggedy ___ (dolls)",
    "Richards and Reinking",
    "Harding and Wilson",
    "Landers and others",
    "Miller and Lee",
    "Richards and Miller",
    "Actress Sothern et al.",
    "Landers and others",
    "Raggedy ___ (dolls)",
    "Wilson and Harding, e.g.",
    "Reinking and Richards",
    "Sheridan and others",
    "Harding and Wilson",
    "Author Beattie et al.",
    "Raggedy ones",
    "Novelist Radcliffe et al.",
    "Novelist Radcliffe and others",
    "Miller and Sothern",
    "Author Rule and others",
    "Writer Beattie and others"
  ],
  "boon": [
    "Windfall",
    "Godsend",
    "Timely benefit",
    "Gift from heaven",
    "Godsend",
    "Benefit",
    "Benefit",
    "Godsend",
    "Windfall",
    "Blessing",
    "Windfall",
    "*Godsend",
    "Blessing",
    "Great benefit",
    "Big help",
    "Something to be thankful for",
    "Godsend",
    "Benefit",
    "Windfall",
    "Blessing",
    "Kind of companion",
    "Godsend",
    "Blessing",
    "Kind of companion",
    "Largess",
    "Unexpected blessing",
    "Unexpected advantage"
  ],
  "ober": [
    "Upper: Ger.",
    "High, in German names",
    "Upper: Ger.",
    "Upper, in Saxony",
    "\"From Here to Eternity\" actor Philip"
  ],
  "miss": [
    "Not catch",
    "Be off the mark",
    "Not catch",
    "Bad shot",
    "\"___ me?\"",
    "Notice the absence of",
    "___ America pageant",
    "Yearn for",
    "Master's counterpart",
    "Pageant title",
    "Be errant, say",
    "Fail to connect",
    "Start of a pageant winner's title",
    "Get wrong",
    "What a 61-Across will be called for only a little while longer",
    "The Rolling Stones' \"___ You\"",
    "See 1-Across",
    "___ Piggy",
    "Fail to notice",
    "Hit's opposite",
    "Whiff",
    "___ America",
    "Oversight",
    "Pageant title",
    "Swiss ___",
    "Overlook",
    "\"Little ___ Sunshine\"",
    "Girl",
    "Hit's alternative",
    "Go wide of, say",
    "Fail to catch",
    "Air ball, e.g.",
    "Fail to make",
    "Forgo",
    "Shoot past, e.g.",
    "Get wrong",
    "Fail to see",
    "___ America",
    "Long for",
    "Overlook",
    "Young lady",
    "Overshoot, say",
    "Hit alternative",
    "Fail to see",
    "Overlook",
    "See 25-Across",
    "Hit alternative",
    "Ole ___",
    "Failure",
    "Strindberg's \"___ Julie\"",
    "Pageant title",
    "Hit or ___",
    "Strindberg's \"___ Julie\"",
    "Failed attempt"
  ],
  "aire": [
    "Ending with some large numbers",
    "One-percenter suffix",
    "Moneyed suffix",
    "Frigid suffix",
    "Suffix with billion",
    "Suffix with large numbers",
    "Suffix for the 1%",
    "Rich finish?",
    "Suffix with million",
    "Suffix with high numbers",
    "River through Yorkshire",
    "Rich person's suffix?",
    "Suffix with concession",
    "Suffix with zillion",
    "Yorkshire river",
    "Suffix for the wealthy",
    "Suffix with concession",
    "Suffix with trillion",
    "Suffix with zillion",
    "Suffix for the wealthy",
    "It flows through Knottingley",
    "Suffix with zillion",
    "Suffix with Frigid-",
    "Suffix with billion",
    "Suffix with billion",
    "Suffix with concession",
    "Suffix with million",
    "Suffix with zillion",
    "Suffix with billion",
    "Suffix with billion",
    "Suffix with billion",
    "Frigid finish?",
    "River of Leeds",
    "Del ___ (Los Angeles suburb)",
    "Ending with some very large numbers",
    "Leeds's river",
    "Suffix with million",
    "Frigid finish",
    "Large number suffix",
    "Concession closer?",
    "Frigid add-on",
    "Leeds's river",
    "Leeds's river",
    "River to Leeds",
    "Frigid follower?",
    "Suffix with concession",
    "Frigid finish",
    "Yorkshire river",
    "Suffix added to large numbers",
    "Frigid finish",
    "Suffix with concession",
    "Yorkshire river",
    "Suffix with million",
    "Billion follower",
    "Frigid finish",
    "Suffix with million",
    "Suffix with concession",
    "Leeds's river",
    "Suffix with concession",
    "Suffix with billion",
    "Suffix with million",
    "River of Leeds?",
    "Rich person's suffix",
    "Concession attachment",
    "Rich person's suffix",
    "Yorkshire river",
    "Frigid suffix",
    "Million or billion suffix",
    "Aisne tributary",
    "Suffix with billion",
    "It follows a million",
    "Yorkshire river",
    "Leeds's river",
    "River through Leeds",
    "Yorkshire river",
    "River through Leeds"
  ],
  "brer": [
    "___ Rabbit",
    "Family member, in rural dialect",
    "Title in Uncle Remus stories",
    "Title for a fox",
    "___ Fox",
    "Title in Uncle Remus tales",
    "___ Rabbit",
    "___ Rabbit",
    "___ Rabbit",
    "___ Rabbit",
    "\"Song of the South\" appellation",
    "Title in children's literature",
    "Backwoods relative",
    "___ Rabbit",
    "Title in an Uncle Remus story",
    "Family member, in dialect",
    "Southern contraction",
    "___ Rabbit",
    "___ Fox",
    "___ Rabbit",
    "Term of address used by Uncle Remus",
    "Title before Rabbit or Fox",
    "Uncle Remus title",
    "Southern appellation",
    "Word before Rabbit or Fox",
    "Rabbit's title",
    "Harris's ___ Rabbit",
    "___ Fox of Uncle Remus tales",
    "___ Rabbit",
    "___ Rabbit",
    "Uncle Remus title",
    "___ Rabbit",
    "Uncle Remus title",
    "Backwoods relative",
    "Southern honorific",
    "Sib in dialect",
    "Joel Chandler Harris title",
    "Uncle Remus honorific",
    "___ Fox",
    "Relative of Remus?",
    "___ Fox",
    "Rabbit's title",
    "Joel Chandler Harris title",
    "___ Rabbit",
    "Southern sibling",
    "___ Fox",
    "Rabbit or Fox preceder",
    "Rabbit's title",
    "\"Uncle Remus\" epithet",
    "___Bear (\"Uncle Remus\" character)",
    "Uncle Remus term of address",
    "___Rabbit",
    "___Fox",
    "Title for a rabbit",
    "___ Rabbit",
    "___ Rabbit",
    "___ Rabbit",
    "Rabbit's title",
    "___ Rabbit",
    "___ Rabbit"
  ],
  "sadr": [
    "Baghdad's ___ City",
    "___ City (Baghdad district)",
    "Baghdad's ___ City",
    "Iraq's ___ City",
    "Baghdad's ___ City",
    "___ City (Baghdad suburb)",
    "Baghdad's ___ City",
    "Baghdad's ___ City",
    "___ City (Baghdad area)",
    "___ City (Baghdad area)",
    "Baghdad's ___ City",
    "Iraq's ___ City",
    "Baghdad's ___ City",
    "Abolhassan Bani-___ (first president of Iran)",
    "Iraq's ___ City",
    "Baghdad's ___ City",
    "Baghdad's ___ City",
    "___ City (Baghdad district)",
    "Former Iranian president Bani-___",
    "Iran's Bani-___",
    "Former Iranian president Bani ___"
  ],
  "ecto": [
    "___ Cooler, \"Ghostbusters\"-inspired Hi-C flavor",
    "Prefix with -morph",
    "Prefix with -plasmic",
    "Prefix with plasm",
    "Outside: Prefix",
    "Outside: Prefix",
    "Prefix with -derm",
    "Prefix with -derm",
    "Beginning to morph?",
    "Prefix with -plasm",
    "Prefix with -plasm",
    "Outer: Prefix",
    "Outside: Prefix",
    "Prefix with -plasm",
    "Prefix with -plasm",
    "Opposite of endo-",
    "Outer: Prefix",
    "Outer: Prefix",
    "Outside: Prefix",
    "Prefix with -morph",
    "Prefix with -dermal",
    "Outer: Prefix",
    "___-1 (\"Ghostbusters\" vehicle)",
    "Prefix with plasm",
    "Opening for outside?",
    "Outer: Prefix",
    "Outer: Prefix",
    "Outer: Prefix",
    "Prefix with -derm",
    "Plasm prefix",
    "Prefix with morph",
    "Prefix with plasm",
    "Opposite of endo-",
    "Prefix with plasm",
    "Prefix with plasm",
    "Outer: Prefix",
    "Prefix with plasm",
    "Opposite of endo-",
    "Outer: Prefix",
    "Start to morph?",
    "Outer: Prefix",
    "Prefix with parasite",
    "Outside opening?",
    "Prefix with -derm",
    "Prefix with parasite",
    "Prefix with plasm",
    "Outer: Prefix",
    "Opposite of endo-",
    "Prefix with plasm",
    "Prefix with -therm",
    "Outside: Prefix",
    "Prefix with -morph",
    "Prefix with plasm",
    "Prefix with -morphic",
    "Outside: Prefix",
    "Prefix with -derm",
    "Outer: Prefix",
    "Exterior: Prefix",
    "Prefix with plasm",
    "Prefix used in \"Ghostbusters\""
  ],
  "psas": [
    "Some TV spots, briefly",
    "Ad Council offerings, for short",
    "Antismoking spots, e.g.",
    "NBC's \"The More You Know\" spots, e.g.",
    "Some TV ads, for short",
    "Filler ads, in brief",
    "Ad Council output, for short",
    "\"Don't text and drive\" spots, e.g., for short",
    "Pro bono spots, briefly",
    "MADD ads, e.g.",
    "G.I. Joe's \"Knowing is half the battle\" spots, e.g.",
    "They run free on TV, in brief",
    "Anti-D.U.I. ads, e.g.",
    "Many educ. spots",
    "Anti-D.U.I. ads, e.g.",
    "Ad Council output, for short",
    "Some health warnings: Abbr.",
    "Some TV spots, briefly",
    "Free TV spots, for short",
    "Smokey Bear spots, for short",
    "Some health info ads, for short"
  ],
  "taco": [
    "Quesadilla alternative",
    "Dish that might be seasoned with adobo sauce",
    "___ Bell",
    "Order by the border",
    "___ Bell",
    "___ Tuesday (modern restaurant promotion)",
    "Order at Chipotle",
    "Word before sauce or truck",
    "Treat from a truck, maybe",
    "Part of a Chipotle order",
    "Mexican entree in a shell",
    "___ truck",
    "Food truck offering, maybe",
    "It might be topped with guacamole",
    "Carne asada holder",
    "Hard or soft menu item",
    "It comes in a shell",
    "___ Bell",
    "Chalupa alternative",
    "Order across the border",
    "Kind of shell",
    "___ Bell (restaurant chain)",
    "___ stand",
    "___ Bell",
    "Mexican dish served in a shell",
    "Entree in a shell",
    "Del ___ (fast-food chain)",
    "___ truck",
    "Qdoba offering",
    "Buffet with shells",
    "Tex-Mex serving",
    "___ Bell",
    "Choco ___ (Klondike treat)",
    "It's folded before a meal",
    "Dish you might sprinkle cheese on",
    "___ Bell",
    "Burrito alternative",
    "Tijuana treat",
    "Burrito alternative",
    "Bell or shell preceder",
    "Crunchy sandwich",
    "Food item often seasoned with cilantro",
    "___ de carne asada",
    "Del ___ (fast-food chain)",
    "Snack with carne asada, maybe",
    "Mexican snack",
    "___ Bell",
    "Crunchy munchie",
    "Kind of shell",
    "___ Bell",
    "Tex-Mex fare",
    "Quinceanera treat",
    "Dish that might come with mole sauce",
    "Tijuana fare",
    "___ Bell",
    "Its shell may be soft",
    "Filled fare",
    "___ Bell",
    "___ salad (dish with ground beef)",
    "Kind of shell",
    "It's folded before dinner",
    "Soft or crunchy snack",
    "Folded fast food",
    "Mexican restaurant offering",
    "Crunchy munchie",
    "Sonora snack",
    "Stuffed tortilla",
    "Snack in a shell",
    "Preceder of Bell or shell",
    "Sonora snack",
    "Shell food?",
    "Food in a shell",
    "Tex-Mex staple",
    "Food with cheese topping",
    "66-Across snack",
    "Kind of shell",
    "Kind of shell",
    "South-of-the-border order",
    "Food item that can be soft or hard",
    "___ Bell",
    "Snack with a shell",
    "Stuffed tortilla",
    "___ Bell",
    "Kind of salad",
    "Snack in a shell",
    "Kind of shell",
    "Stuffed shell",
    "Mexican serving",
    "Mexican sandwich",
    "Mexican fast food",
    "Crunchy munchie",
    "Chalupa alternative",
    "___ Bell",
    "Cinco de Mayo dish",
    "Edible shell",
    "Tex-Mex snack",
    "Dish that's usually served with sauce",
    "Bell or shell preceder",
    "Tex-Mex order",
    "___ Bell",
    "Meal in a shell",
    "Folded item",
    "Crunchy munchie",
    "Food whose name means, literally, wadding",
    "___ Bell",
    "Sandwich that crunches",
    "Kind of shell",
    "Kind of salad",
    "___ Bell",
    "Kind of shell",
    "Kind of shell",
    "Snack in a shell",
    "Meal in a shell",
    "Word before shell or Bell",
    "Meat-filled treat",
    "Sandwich with a crunch",
    "Sonora snack",
    "Crunchy dish",
    "Crunchy sandwich",
    "Bit of fast food",
    "Crunchy sandwich",
    "Mexican sandwich",
    "Tex-Mex snack",
    "Guacamole's place",
    "Sandwich with fixin's",
    "Filled tortilla",
    "This may have a hard or soft shell",
    "Two-handed snack",
    "Lunch with a crunch",
    "South-of-the-border sandwich",
    "___ Bell",
    "Tijuana treat",
    "Cantina snack",
    "Fast food offering",
    "Kind of shell"
  ],
  "wins": [
    "See 38-Across",
    "Comes out ahead",
    "Tournament-seeding consideration",
    "\"W\" column in the standings",
    "Scorecard column",
    "Standings column",
    "Sports column",
    "First part of a record",
    "First part of a record",
    "Season tally",
    "First number in a record",
    "Victories",
    "Comes out on top",
    "Sports column",
    "Sports column?",
    "Sports column?",
    "Sports column",
    "Successes",
    "Takes the cake",
    "Team data",
    "First number in season records",
    "Sports page stats",
    "Comes in first"
  ],
  "bros": [
    "Warner ___ (film company)",
    "Warner ___",
    "Frat members",
    "Warner ___",
    "Male buds",
    "Frat dudes",
    "Some sibs",
    "Warner ___",
    "Frat boy types",
    "Surfer dudes, e.g.",
    "Sibs ... or Sigs, maybe",
    "Mates",
    "Buddies",
    "Family business abbr.",
    "Frat members",
    "Homies",
    "Parker ___ (game co.)",
    "See 28-Across",
    "Shingle abbr.",
    "Warner ___",
    "Warner ___",
    "Warner ___",
    "Part of some co. names",
    "Boys in the 'hood",
    "Abbr. in many company names",
    "Warner ___",
    "Boys in the 'hood",
    "Ringling ___",
    "Warner ___",
    "Smith ___ cough drops"
  ],
  "rama": [
    "Incarnation of Vishnu in a Sanskrit epic",
    "Sita's love, in Hindu lore",
    "Hindu avatar",
    "Seventh avatar of Vishnu",
    "Hindu deity of virtue",
    "Hindu avatar",
    "Commercial follower of \"-o-\"",
    "Hindu embodiment of virtue",
    "Ending with Cine-",
    "Any of nine kings of Thailand",
    "\"___ Lama Ding Dong\" (1961 hit)",
    "Hindu hero",
    "Hindu epic hero",
    "Hero of a Hindu epic",
    "Spin-o-___ (360-degree hockey maneuver)",
    "Name of nine Thai kings",
    "Arthur C. Clarke's \"Rendezvous With ___\"",
    "Blue-skinned deity",
    "\"___ Lama Ding Dong\" (1961 hit)",
    "\"___ Lama Ding Dong,\" 1961 hit for the Edsels",
    "Cine- suffix",
    "Arthur C. Clarke's \"Rendezvous With ___\"",
    "Hindu hero",
    "Avatar of Vishnu",
    "Hindu avatar",
    "Avatar of Vishnu",
    "Slayer of Ravana in Hindu myth",
    "\"___ Lama Ding Dong\" (1961 nonsense hit)",
    "Commercial name suffix",
    "Suffix with Cine-",
    "\"___ Lama Ding Dong\" (1961 hit)",
    "Hindu deity",
    "One of nine Siamese kings",
    "Avatar of Vishnu",
    "\"___ Lama Ding Dong\"",
    "Thai title",
    "Hindu hero",
    "See 1-Down",
    "Subject of many Sanskrit epics",
    "Start of a 1961 nonsense song",
    "Ending with Cine- or cyclo-",
    "Avatar of Vishnu",
    "1961 hit \"___ Lama Ding Dong\"",
    "Hindu deity",
    "Name of nine Thai kings",
    "Clarke?Æs ?ôRendezvous with ___?ö",
    "Spectacular suffix",
    "Hindu epic hero",
    "Vishnu incarnation",
    "Sita's husband, in myth"
  ],
  "esas": [
    "Those: Sp.",
    "Those: Sp.",
    "Those: Sp.",
    "Those ninas",
    "Those, in Madrid",
    "Spanish pronoun",
    "Those, to Jose",
    "Spanish pronoun",
    "Maria's \"those\"",
    "Those, in Toledo",
    "Those girls, to Juanita",
    "Pamplona pronoun",
    "Those, in Tijuana",
    "Those, to Tomas",
    "Those, in Toledo",
    "Those: Sp.",
    "Juan's \"those\"",
    "Those, in Madrid",
    "Those, to Munoz",
    "Those: Sp.",
    "Spanish pronoun",
    "Spanish pronoun",
    "Those in La Mancha",
    "Those, to Munoz",
    "Those, to Jose",
    "Those, to Jose",
    "Juanita's \"those\"",
    "Those: Sp.",
    "Those muchachas",
    "Those, to Juanita"
  ],
  "yoke": [
    "Farm connector",
    "Make into a couple",
    "Hitch together",
    "Join",
    "It goes around the neck",
    "Oxen connector",
    "Join together",
    "Coupling device",
    "Put together, as a team",
    "It keeps a team together",
    "For which two heads are better than one?",
    "Oxen connector",
    "Team unifier",
    "Joiner of a team",
    "Harness for oxen",
    "It can hold a team together",
    "Device for a plow team",
    "One placed on a team",
    "Oxen holder",
    "Oxen connector",
    "Farm link",
    "Coupler",
    "Oxen's harness",
    "Oxen holder",
    "Hitch, as oxen",
    "Tie",
    "Coupler",
    "Coupling device",
    "Oxen connector",
    "Team need",
    "Bind",
    "Oxen's harness",
    "Coupling",
    "Oxen harness",
    "Hook up",
    "Symbol of servitude",
    "Harness fitting"
  ],
  "egon": [
    "Painter Schiele",
    "\"Ghostbusters\" character ___ Spengler",
    "Expressionist Schiele",
    "Ghostbuster Spengler",
    "Dr. ___ Spengler, \"Ghostbusters\" role",
    "___ Krenz, last Communist leader of East Germany",
    "Dr. ___ Spengler (\"Ghostbusters\" role)",
    "Von Furstenberg of fashion",
    "Ghostbuster Spengler",
    "Certain Ghostbuster",
    "Painter Schiele",
    "Von Furstenberg of fashion",
    "Expressionist Schiele",
    "Austrian painter Schiele",
    "Fashion's ___ von Furstenberg",
    "Designer von Furstenberg",
    "One of the Ghostbusters",
    "Austrian-born composer Wellesz",
    "Painter Schiele",
    "Expressionist Schiele",
    "Designer von Furstenberg",
    "Austrian Expressionist Schiele",
    "Designer von Furstenberg",
    "\"The Hermits\" painter Schiele",
    "Designer von Furstenberg",
    "Painter Schiele",
    "Designer von Furstenberg",
    "Designer von Furstenberg"
  ],
  "ssts": [
    "Fast former fliers, for short",
    "Concordes et al.",
    "Old Concordes, in brief",
    "Onetime trans-Atlantic fliers, for short",
    "Retired fliers, for short",
    "Concordes, for short",
    "Bygone boomers, for short",
    "J.F.K. landers until 2003",
    "Fliers until '03",
    "Some speedsters, for short",
    "Boomers of old, in brief",
    "Old Mach 2 fliers, for short",
    "Retired jets, for short",
    "Old J.F.K. fliers",
    "They got grounded after streaking",
    "Bygone jets, informally",
    "Former Air France fleet members, for short",
    "Concordes, in brief",
    "Old Air France fleet, for short",
    "Concordes",
    "Bygone boomers",
    "They were retired in '03",
    "Grounded trans-Atlantic fliers, for short",
    "Former faves of jet-setters",
    "Booming jets of old, in brief",
    "Onetime high fliers",
    "Big \"birds\" of old",
    "Concordes, e.g., for short",
    "Retired fliers",
    "Retired fliers",
    "Onetime landers at LAX",
    "Concordes, e.g.",
    "Fast \"birds\"",
    "Concordes, for short",
    "Former fliers",
    "Bygone fliers",
    "Retired boomers",
    "Former high fliers",
    "Orly birds, once",
    "Fleet fleet, once, in brief",
    "Concordes, briefly",
    "Onetime J.F.K. visitors",
    "They had Machmeters, briefly",
    "Onetime J.F.K. arrivals",
    "Retired fleet",
    "Former fast jets",
    "Old trans-Atlantic jets, for short",
    "Fleet fleet, once",
    "Jet-setters' jets, once",
    "Concorde fleet",
    "Tupolev Tu-144s, e.g.",
    "They no longer boom",
    "They had adjustable noses",
    "Old trans-Atlantic speedsters",
    "Out-of-commission cruisers",
    "Bygone J.F.K. landers",
    "Former fast fliers",
    "Bygone barrier breakers",
    "Former jets to J.F.K.",
    "Bygone \"birds\"",
    "Bygone boomers",
    "Jet-setters' jets, once",
    "Fleet that was permanently retired in 2003",
    "Concordes, briefly",
    "Retired fliers",
    "Jets that boom",
    "Onetime French fleet",
    "Old boom makers",
    "Retired fliers",
    "Fleet that was very fleet",
    "Retired fleet",
    "Concordes",
    "Heathrow sights, once",
    "Jet-setters' jets, once",
    "Former boomers",
    "Bygone fliers",
    "Out-of-commission cruisers",
    "Retired fleet",
    "Grounded speedsters",
    "Onetime French fleet",
    "J.F.K. landers, once",
    "Out-of-commission cruisers: Abbr.",
    "Discontinued fliers, quickly",
    "Boomers, once?",
    "Grounded fleet",
    "Retired Atl. fliers",
    "Retired fliers, for short",
    "Retired fliers",
    "Grounded jets",
    "Onetime Aerospatiale products",
    "Former J.F.K. sights",
    "Former Concorde fleet",
    "Speedy fliers, for short",
    "Concordes",
    "Part of Air France's fleet until 2003",
    "Items for a transportation museum, now",
    "Fast ones, for short",
    "Swift fliers, for short",
    "Ocean crossers",
    "Fast jets, briefly",
    "Streakers, briefly",
    "Some arrivals at 44-Across",
    "Fliers from De Gaulle",
    "Jet set jets",
    "Mach 2 fliers",
    "Fast fliers",
    "Boom makers, for short",
    "Speedy fliers",
    "Air France assets",
    "Sleek fleet, briefly",
    "Concordes",
    "Boom producers",
    "Orly risers?",
    "Jet set",
    "Fast fliers",
    "British Airways fleet",
    "Planes in the news",
    "J.F.K. arrivals",
    "Sleek streakers",
    "Fast fleet",
    "High fliers",
    "Members of a fast fleet",
    "Sleek fleet",
    "N.Y.-Paris carriers",
    "Concordes",
    "Tu-144 and others",
    "Alternatives to 747's",
    "Part of the Air France fleet",
    "Loud streakers",
    "Parts of the Air France fleet",
    "Fast jets, for short",
    "Transporters since '76",
    "Mach I breakers",
    "Fast Atl. crossers",
    "Air France fleet members",
    "J.F.K. arrivals",
    "Atl. fliers",
    "Mach-makers: Abbr.",
    "They're not baby boomers",
    "J.F.K. jets",
    "Creators of booms",
    "J.F.K. arrivals",
    "High fliers",
    "Some jets",
    "Frequent fliers",
    "Tu-144 and others",
    "Fast fleet",
    "Boom makers",
    "Barrier breakers",
    "J.F.K. jet set",
    "Mach 1 fliers",
    "Atl. speedsters",
    "J.F.K. sights",
    "Fleet fleet?",
    "J.F.K. arrivals",
    "Trans-Atlantic fliers",
    "Fast planes",
    "Sonic boom creators",
    "Concordes",
    "Fleet fleet",
    "Fast planes",
    "Boom causers",
    "Travelers' timesavers",
    "Fast fliers",
    "J.F.K. sights",
    "Fast planes",
    "J.F.K. sights",
    "Heathrow sights",
    "Fast fliers",
    "Jet-set jets",
    "Frequent fliers",
    "Fast planes",
    "Speedy jets",
    "Speedy planes",
    "Jets",
    "Heathrow visitors",
    "Concorde et al.",
    "Fast fliers, briefly",
    "J.F.K. drop-ins",
    "Trans-Atlantic speedsters",
    "Orly birds?"
  ],
  "gaps": [
    "Spaces between teeth",
    "Orthodontic challenges",
    "Memory problems",
    "Problems of amnesia",
    "Problems with a collection",
    "Breaches",
    "Empty spaces",
    "Reasons for braces",
    "Vacancies",
    "Phalanx's weaknesses",
    "Fissures",
    "Intervals",
    "Breaches",
    "Breaks",
    "Timeline breaks",
    "Discontinuities",
    "Openings",
    "Breaks",
    "Openings",
    "Empty spaces",
    "Lacunae",
    "Some chain clothing stores",
    "Openings",
    "Breaches"
  ],
  "bony": [
    "Like a whole-cooked fish, say",
    "Like fish that can be difficult to eat",
    "Quite gaunt",
    "Like fish that are difficult to eat",
    "Far from fat",
    "Too thin",
    "Way too thin",
    "Skeletal",
    "Gaunt",
    "Really thin",
    "Thinner than thin",
    "Anorexic",
    "Lacking meat, so to speak",
    "More than lean",
    "Gaunt",
    "Scrawny",
    "Emaciated",
    "Overly thin",
    "Skinny",
    "Lean"
  ],
  "pram": [
    "Stroller, to a Brit",
    "Tot toter, in Tottenham",
    "Baby carriage, to Brits",
    "Surrey carriage",
    "Baby buggy, to Brits",
    "British baby buggy",
    "Stroller to Soho",
    "Brit's baby buggy",
    "Nanny's vehicle",
    "Cornwall carriage",
    "Coventry park sight",
    "Hyde Park stroller",
    "Brit's baby buggy",
    "Baby carriage",
    "It's pushed in Kensington Gardens",
    "Baby buggy",
    "Tot toter",
    "Baby buggy",
    "Nappy wearer's transport",
    "Item on a park walk",
    "Baby buggy",
    "British baby bearer",
    "A pusher may push it",
    "Buggy",
    "It's pushed in Hyde Park",
    "Kensington Gardens sight",
    "Surrey carriage",
    "Hyde Park sight",
    "Baby buggy"
  ],
  "nena": [
    "\"99 Luftballons\" band, 1984",
    "\"99 Luftballons\" singer",
    "\"99 Red Balloons\" singer",
    "\"99 Luftballons\" singer",
    "German pop star who once had a #2 song",
    "Singer with the 1984 hit \"99 Luftballons\"",
    "One-named German singer who was a one-hit wonder",
    "Singer of \"99 Luftballons\"",
    "\"99 Luftballons\" singer",
    "\"99 Luftballons\" pop group",
    "\"99 Luftballons\" singer",
    "\"99 Luftballons\" singer, 1984",
    "\"99 Luftballons\" singer",
    "\"99 Luftballons\" singer",
    "\"99 Luftballons\" singer, 1984",
    "\"99 Luftballons\" hit-maker of 1984",
    "\"99 Luftballons\" band, 1984",
    "\"99 Red Balloons\" singer, 1984",
    "\"99 Luftballons\" pop group",
    "\"99 Luftballons\" pop group",
    "\"99 Luftballons\" singer",
    "\"99 Luftballons\" singer"
  ],
  "arod": [
    "Baseball great who was once engaged to J.Lo",
    "Sports star who split with J.Lo in 2021",
    "Three-time A.L. M.V.P. (2003, 2005 and 2007), informally",
    "Youngest M.L.B. player to hit 500 homers",
    "Newsmaking ex-Yankee, informally",
    "Major-league All-Star turned TV analyst, informally",
    "Yankee nickname until 2016",
    "Nickname for an ESPN baseball commentator",
    "Half of a celebrity couple with J.Lo starting in 2017",
    "Former Yankee slugger, familiarly",
    "Longtime Yankee teammate of Sandman",
    "Former Yankee nickname",
    "Star Yankees 3B for 10 seasons",
    "Yank who retired with 2,086 R.B.I.",
    "Former Yank with 25 career grand slams",
    "14-time M.L.B. All-Star, to fans",
    "Bygone Yankee nickname",
    "Onetime Yankee nickname",
    "Longtime Yankee nickname",
    "Yank with 25 grand slams",
    "Yankee nickname beginning in 2004",
    "Youngest player ever to hit 500 home runs, familiarly",
    "Yankee slugger with the most M.L.B. career grand slams, informally",
    "Member of the 3,000-hit club, informally",
    "Biogenesis scandal figure of '13",
    "Longtime Yankee nickname",
    "Yankees teammate of Captain Clutch",
    "Biogenesis scandal nickname",
    "Youngest 600-homer man, informally",
    "Longtime teammate of Mr. November",
    "Longtime Yankee nickname",
    "Youngest 600-homer man, informally",
    "Nickname of a three-time A.L. M.V.P.",
    "Yankees' #13, to fans",
    "Hot corner Yank",
    "Big Apple baseball name",
    "See 51-Across",
    "Longtime Yankee moniker",
    "Longtime Yankee nickname",
    "Yankee superslugger, to fans",
    "Nickname since 1999",
    "A.L. M.V.P. in 2005 and 2007, informally",
    "Nickname of a Yankee with a $275 million contract",
    "Longtime Yankee third baseman, informally",
    "Yankee nickname starting in 2004",
    "Yankees' \"$275 million man,\" informally",
    "Longtime Yankee nickname",
    "10-time Silver Slugger Award winner, familiarly",
    "Yankee nickname starting in 2004",
    "Baseball nickname that's a portmanteau",
    "Yankee acquisition of '04",
    "#13 in the Bronx, informally",
    "Three-time A.L. M.V.P., informally",
    "Yankee nickname starting in 2004",
    "Youngest player to join the 500-homer club",
    "Yankee nickname starting in 2004",
    "Yankee nickname starting in 2004",
    "Three-time A.L. M.V.P., familiarly",
    "Nickname among major-league sluggers",
    "Dominican-American major-league slugger, to fans",
    "2003 and '05 A.L. M.V.P.",
    "2003 A.L. M.V.P., to fans",
    "Noted shortstop's nickname",
    "Yankee nickname starting 2004",
    "2003 A.L. M.V.P., familiarly",
    "Nickname in which \"A\" stands for Alex",
    "Noted A.L. shortstop, for short",
    "Ranger nickname"
  ],
  "ibms": [
    "Some old PCs",
    "Many mainframes",
    "Some mainframe computers",
    "Some early computers",
    "Some old PCs",
    "Some classic computers",
    "Some early PCs",
    "Some servers",
    "Some old PCs",
    "Many old terminals",
    "Certain servers",
    "Many early PCs",
    "Apples might be compared to them",
    "Deep Blue and others",
    "Some laptops",
    "Many servers",
    "Some early PCs",
    "They were once cloned",
    "Classic PC's",
    "Apples can be compared to them",
    "Many P.C.'s",
    "Some PC's",
    "Many PC's",
    "Some PC's",
    "Apple alternatives",
    "Cloned computers"
  ],
  "root": [
    "Plant part that most herbicides attack",
    "Original cause",
    "Two, for four",
    "Weed killer's target",
    "Rummage (around)",
    "Ginger, e.g.",
    "Plant part",
    "Rummage (through)",
    "The Gaelic \"uisge beatha,\" meaning \"water of life,\" for \"whiskey\"",
    "Ginseng, e.g.",
    "___ canal",
    "Cheer (for)",
    "Tree anchor",
    "Herbicide's target",
    "Potential drain obstruction",
    "Edible part of a carrot or radish",
    "Yam or turnip",
    "Rummage (through)",
    "Word sung three times before \"for the home team\" in \"Take Me Out to the Ball Game\"",
    "Cheer (for)",
    "Most important part of a carrot or turnip plant",
    "Cheer (for)",
    "i, for -1",
    "What fans do",
    "Yam or turnip",
    "A cube has one",
    "Fundamental part",
    "Plant holder?",
    "Carrot, radish or parsnip",
    "Part of a plant or tooth",
    "Rummage",
    "Underground part of a plant",
    "Carrot or beet",
    "Dig like a pig",
    "Source",
    "Cheer (for)",
    "It may be square",
    "Tooth or plant part",
    "Cheer (for)",
    "Etymologist's interest",
    "Source",
    "Carrot or radish",
    "Origin",
    "Underlying cause",
    "Lexicographic concern",
    "Etymological basis",
    "Etymologist's concern",
    "Canine part",
    "Poke (around)",
    "Etymology info",
    "Cheer (for)",
    "Tooth part",
    "Cheer (for)",
    "Tree's anchor",
    "Extirpate, with \"out\"",
    "Etymologist's concern",
    "Radish or carrot",
    "Origin",
    "Tooth part",
    "Cheer (for)",
    "What many a word has",
    "Hair implant?",
    "Tooth part",
    "See 53-Down",
    "One may be square",
    "Cheer (for)",
    "What fans do",
    "Dig like a pig",
    "Dig (for)",
    "Carrot, e.g.",
    "Underground part",
    "Take 5, clue 1",
    "Kind of vegetable",
    "Source",
    "Origin",
    "Dig like a pig",
    "___ canal",
    "Dig like a pig",
    "Cheer (for)",
    "Edible part of a parsnip",
    "Cheer",
    "Pull (out)",
    "Applaud (for)",
    "Cheer",
    "Carrot or turnip, e.g.",
    "Cheer (for)",
    "Plant anchorer",
    "2, to 4 or 8",
    "Tooth part",
    "Eradicate, with \"out\"",
    "Cheer (for)",
    "Cheer (for)",
    "Kind of canal",
    "What fans do",
    "Plant holder",
    "Underground stem",
    "Licorice or sassafras",
    "Plant anchor",
    "Unearth, with \"up\"",
    "Fundamental",
    "Parsnip, e.g.",
    "Radix, botanically",
    "Cheer (for)",
    "Cheer (for)",
    "Kind of beer",
    "Cheer (for)",
    "1905 Secretary of State"
  ],
  "nyet": [
    "3-Down's \"no\"",
    "Russian \"no\"",
    "Moscow turndown",
    "Politburo objection",
    "Russian refusal",
    "Turndown from Putin",
    "Moscow turndown",
    "Vote in the Security Council",
    "Russian rejection",
    "Da's opposite",
    "No, in Moscow",
    "A bit of disputin' from Putin?",
    "Opposite of \"da\"",
    "Kremlin rejection",
    "Something said by a put-out Putin?",
    "Da's opposite",
    "Security Council vote",
    "Duma dissent",
    "Refusal from Putin",
    "European refusal",
    "Non : French :: ___ : Russian",
    "Khrushchev's dissent",
    "Russian refusal",
    "Putin's refusal",
    "Da's opposite",
    "Vladimir's veto",
    "Veto from Vladimir",
    "Putin put-down?",
    "Security Council veto",
    "Vladimir's veto",
    "Word before and after \"Tovarich\" in a \"Doctor Zhivago\" number",
    "Mr. ___ (moniker for Andrei Gromyko)",
    "Medvedev's denial",
    "Opposite of \"da\"",
    "Da's opposite",
    "Maxim's denial",
    "Security Council vote",
    "Nein : German :: ___ : Russian",
    "Duma disavowal",
    "Dostoyevsky's denial",
    "Comment put in by Putin, perhaps",
    "Natasha's refusal",
    "Refusenik's refusal",
    "Dmitri's denial",
    "Putin input?",
    "Duma dissent",
    "Nikita's no",
    "Foreign denial",
    "Vote in the Duma",
    "Duma denial",
    "Nikita's \"no\"",
    "Russian refusal",
    "Putin's rejection",
    "No, to Natasha",
    "Duma demurral",
    "Reply put in by Putin?",
    "Russian's refusal",
    "Overseas refusal",
    "Da's opposite",
    "Russian rejection",
    "Putin's refusal",
    "Putin's refusal",
    "No, in Moscow",
    "No, in Novgorod",
    "Da's opposite",
    "Summit refusal?",
    "Nikita's \"no\"",
    "Czar's reply to protesters",
    "Boris's refusal",
    "Foreign refusal",
    "Reply from Boris",
    "No, to Nikita",
    "Russian rejection",
    "Raisa's refusal",
    "Da's opposite",
    "Reply to a refusenik",
    "Da's opposite",
    "One Security Council vote",
    "Nikita's no",
    "\"No way, Sergei!\"",
    "Yeltsin veto",
    "Word to a refusenik"
  ],
  "tome": [
    "\"The way I see it ...\"",
    "Antithesis of light reading?",
    "\"Personally ...\"",
    "A lot of volume?",
    "A lot to read",
    "It's bound to be big",
    "Large, scholarly book",
    "\"Personally ...\"",
    "\"The way I see it ...\"",
    "Gutenberg's Bible, e.g.",
    "Weighty work",
    "\"The Complete Works of William Shakespeare,\" e.g.",
    "\"Personally ...\"",
    "One might require an oversize jacket",
    "Sao ___ and Principe",
    "\"The Complete Works of William Shakespeare,\" e.g.",
    "Regal volume",
    "Large volume",
    "\"Personally ...\"",
    "A-F or G-K, maybe",
    "Audubon's \"The Birds of America,\" e.g.",
    "Heavy work",
    "\"The Complete Works of Shakespeare,\" e.g.",
    "Heavy work",
    "Heavy volume",
    "Heavy reading?",
    "One-volume works of Shakespeare, e.g.",
    "Unabridged dictionary, e.g.",
    "Big book",
    "Hefty volume",
    "One with an extra-wide spine",
    "Any volume of the Oxford English Dictionary",
    "Big book",
    "No light reading",
    "Heavy book",
    "It may need a big jacket",
    "Heavy volume",
    "Dictionary, often",
    "Space hog in a library",
    "A lot of volume?",
    "\"The way I see it ?à\"",
    "Hefty volume",
    "It may need a big jacket",
    "Substantial volume",
    "Heavy reading?",
    "A-B or C-D, e.g.",
    "Unabridged dictionary, e.g.",
    "It might need two hands to be removed from a shelf",
    "Hefty volume",
    "Heavy book",
    "Hefty volume",
    "Volume",
    "A-E, maybe",
    "Library book",
    "Webster's Unabridged, e.g.",
    "1,000-pager, e.g.",
    "Voluminous volume",
    "Scholarly book",
    "Encyclopedia unit",
    "Large-print edition of the Bible, e.g.",
    "Sao ___",
    "A-K or L-Z, e.g.",
    "Heavy reading",
    "Heavy reading?",
    "\"Sock it ___!\"",
    "Bibliophile's purchase",
    "Volume",
    "Unabridged, e.g.",
    "Unabridged dictionary, e.g.",
    "Bartlett?Æs Familiar Quotations, e.g.",
    "Learned book",
    "Considerable volume",
    "Heavy reading",
    "Bibliophile's purchase",
    "Heavy reading?",
    "Volume",
    "Library item",
    "Library volume"
  ],
  "yelp": [
    "Website where you go to see the stars?",
    "React to a stubbed toe, maybe",
    "App with a Reservations feature",
    "Squeal of pain",
    "Source for restaurant reviews",
    "Website with crowdsourced reviews",
    "Website for crowdsourced reviews",
    "Go \"Ow, ow, OW!\"",
    "Crowdsourced review site",
    "Squeal in pain",
    "App with restaurant reviews",
    "Online site for business reviews",
    "Distress call",
    "Business review company with a 2012 I.P.O.",
    "Website with a \"Write a Review\" button",
    "Website for customer reviews",
    "Dog sound",
    "Cry of alarm",
    "Puppy's plaint",
    "Pound cry",
    "High bark",
    "Canine cry",
    "Small protest",
    "Kennel cry",
    "Pound sound",
    "Canine plaint",
    "Puppy's cry",
    "Canine cry",
    "Cry of pain",
    "Cry out",
    "Outcry",
    "Pound sound",
    "Cry out",
    "Kennel sound",
    "Puppy's cry",
    "Kennel plaint",
    "Quick cry",
    "Hand-in-the-car-door reaction",
    "Fox's cry",
    "Shrill cry",
    "Canine cry",
    "Pound sound",
    "Squeal",
    "Kennel cry",
    "Cry of pain",
    "Fox's shrill cry",
    "Cry of pain",
    "Kennel sound",
    "Cry out",
    "Fox hunt cry"
  ],
  "olin": [
    "Actor Ken or actress Lena",
    "Actress Lena",
    "Lena of \"Chocolat\"",
    "\"Thirtysomething\" actor Ken",
    "TV actor/director Ken",
    "Lena of \"Havana\"",
    "Lena of \"Chocolat\"",
    "Lena of Hollywood",
    "\"This Is Us\" producer Ken",
    "Engineering college in Needham, Mass.",
    "Actress Lena",
    "Actress Lena of \"Chocolat\"",
    "Actor Ken",
    "Co-star of TV's \"thirtysomething\"",
    "Massachusetts' ___ College",
    "Hollywood's Ken or Lena",
    "Actress Lena",
    "Ken of \"thirtysomething\"",
    "Lena of \"Havana\"",
    "Ken of \"Brothers & Sisters\"",
    "Lena of \"Chocolat\"",
    "\"Alias\" actress",
    "Actress Lena",
    "Actress Lena",
    "Ammunition giant",
    "Actress Lena",
    "Lena who played Irina Derevko on \"Alias\"",
    "\"Bang Bang Orangutang\" actress, 2005",
    "Lena of \"Havana\"",
    "Lena of \"Chocolat\"",
    "Swedish-born \"Chocolat\" actress",
    "\"The Reader\" actress Lena",
    "Lena of \"Chocolat\"",
    "Ken of \"thirtysomething\"",
    "Ken of \"thirtysomething\"",
    "Lena or Ken of film",
    "Lena of \"Chocolat\"",
    "\"Hollywood Homicide\" actress, 2003",
    "\"Thirtysomething\" actor",
    "Lena of \"Polish Wedding\"",
    "Actress Lena",
    "Actress Lena",
    "\"Romeo Is Bleeding\" co-star, 1993",
    "Lena of \"Havana\"",
    "Actress Lena",
    "Lena of \"Chocolat\"",
    "Hollywood's Ken or Lena",
    "Actress Lena",
    "Big corporation in copper alloys",
    "Actress Lena",
    "Actress Lena",
    "Leading ammunition maker",
    "Lena of ?ôThe Unbearable Lightness of Being?ö",
    "Lena of \"Chocolat\"",
    "Lena of \"Chocolat\"",
    "Actor Ken",
    "Lena of \"Chocolat\"",
    "\"Hollywood Homicide\" actress, 2003",
    "Ken of \"thirty-something\"",
    "Lena of \"Chocolat\"",
    "Lena of \"Havana\"",
    "\"Thirtysomething\" actor",
    "Lena of \"Havana\"",
    "Actress Lena",
    "Actor Ken",
    "\"Thirtysomething\" co-star",
    "Hollywood's Ken or Lena",
    "Actress Lena",
    "Hollywood's Ken or Lena",
    "Lena of \"Havana\"",
    "Giant chemicals corporation",
    "Actress Lena",
    "Big chemical company",
    "Lena or Ken of film",
    "\"thirtysomething\" star",
    "Fortune 500 chemicals company",
    "Actress Lena",
    "Ken or Lena of Hollywood",
    "Chemicals giant",
    "Actress Lena",
    "See 45-Across",
    "Actress Lena",
    "Golfer Dutra",
    "\"Romeo Is Bleeding\" actress",
    "\"Havana\" actress",
    "Leading ammunition maker",
    "Actress Lena",
    "\"EZ Streets\" actor Ken",
    "\"thirty-something\" actor",
    "___Corporation (ammunition maker)",
    "Actress Lena",
    "Music critic Downes",
    "Corporate name in chemicals",
    "Actor Ken or actress Lena",
    "Giant chemicals company",
    "Lena of recent films"
  ],
  "mean": [
    "Signify",
    "Average",
    "Average",
    "Word before \"Streets\" and \"Girls\" in film titles",
    "Statistician's calculation",
    "Word before streak or business",
    "\"See what I ___?\"",
    "Not nice",
    "Dirty, as tricks",
    "2.5, for the set {1, 2, 3, 4}",
    "Unkind",
    "Signify",
    "Intend",
    "Signify",
    "Not nice",
    "Average",
    "Vicious",
    "Downright nasty",
    "Statistics class figure",
    "Signify",
    "Nasty",
    "Have in mind",
    "*Standard deviation deviates from it",
    "Be defined as",
    "Represent",
    "Skillful, slangily",
    "Cussed",
    "Nasty",
    "Stand for",
    "With 35-Down, have no malice",
    "Like a bully",
    "Stand for",
    "Black-hearted",
    "Uncharitable",
    "Stingy",
    "Like taking candy from a baby?",
    "Intend",
    "Have as a definition",
    "Signify",
    "Nasty",
    "Average",
    "Cruel",
    "Willfully tightening the screws, say",
    "Nasty",
    "Suggest",
    "Bell curve figure",
    "Signify",
    "Hard-hearted",
    "Lowdown",
    "Playing dirty tricks",
    "Bad-tempered",
    "Bell curve figure",
    "Imply",
    "Stand for",
    "Penny-pinching",
    "Statistics calculation",
    "Stand for",
    "Penny-pinching",
    "Unkind",
    "Aim",
    "Penny-pinching",
    "Villainous",
    "Part of G.M.T.",
    "Penny-pinching",
    "Ignoble",
    "Nasty",
    "Stand for",
    "Middle figure",
    "Penny-pinching",
    "Part of G.M.T.",
    "Ill-tempered",
    "Important statistic",
    "Base",
    "Intend",
    "Intend",
    "Say truly",
    "Like Dickens's Murdstone"
  ],
  "void": [
    "Most of outer space",
    "Invalidate",
    "Whole lot of nothing",
    "Like a redeemed coupon",
    "Word written across a bad check",
    "There's nothing in it",
    "Nothingness",
    "Null and ___",
    "Invalidate, as a check",
    "Tear up, so to speak",
    "Empty",
    "Emptiness",
    "Cancel",
    "Make invalid",
    "Not binding",
    "Empty space",
    "Set aside",
    "Deep space, for the most part",
    "No longer valid",
    "Vast emptiness",
    "Word on a sample check",
    "Not legally binding",
    "Empty",
    "Empty",
    "Out of cards in a suit",
    "Inoperative"
  ],
  "nota": [
    "The \"N\" of N.B.",
    "\"___ problem\"",
    "___ bene",
    "The \"N\" of N.B.",
    "\"___ problem\"",
    "___ bene",
    "\"___ chance!\"",
    "\"___ problem!\"",
    "___ bene",
    "___ bene",
    "Part of N.B.",
    "Part of N.B.",
    "Part of N.B.",
    "\"___ chance!\"",
    "___ bene",
    "Part of N.B.",
    "\"___ chance!\"",
    "___ bene",
    "Part of N.B.",
    "___ bene",
    "Part of N.B.",
    "___ care in the world",
    "\"___ chance\"",
    "___ bene",
    "Do, re or mi, in Italy",
    "The \"N\" of N.B.",
    "\"___ chance!\"",
    "Part of N.B.",
    "___ bit",
    "Part of N.B.",
    "___ whole lot",
    "___ chance",
    "Part of N.B.",
    "\"___ chance!\"",
    "___ bene",
    "___ pretty sight",
    "___ care in the world",
    "\"___ chance\"",
    "___ care in the world",
    "Part of N.B.",
    "Part of N.B.",
    "___ one",
    "___ lot (few)",
    "___bene",
    "___chance",
    "___ bene",
    "\"___ chance!\"",
    "Part of N.B.",
    "___ bene",
    "___ bene",
    "Mark: Lat.",
    "___ cloud in the sky",
    "___ bene"
  ],
  "bibb": [
    "Lettuce type",
    "Tender kind of lettuce",
    "Variety of lettuce",
    "Kind of lettuce",
    "Lettuce variety",
    "Lettuce variety",
    "Lettuce variety",
    "Lettuce variety",
    "Kind of lettuce",
    "Kind of lettuce"
  ],
  "adab": [
    "Not much, as of cream",
    "Not much, as of lotion",
    "Not much at all",
    "Just ___ (very little)",
    "Not much, as of paint",
    "Not very much",
    "Very little",
    "Not much at all"
  ],
  "welt": [
    "Swollen mark",
    "Possible symptom of an allergic reaction",
    "Mark left by a whip",
    "Reminder of a hit",
    "Result of a lashing",
    "Evidence of a whipping",
    "Lash mark",
    "Part 5 of the word ladder",
    "Evidence of a big hit",
    "Possible result of an allergic reaction",
    "Result of a whipping",
    "Sore spot",
    "Mark of punishment",
    "Lash mark",
    "Whip mark",
    "Shoe strengthener",
    "Whipping memento",
    "Seamstress's strip",
    "Whip mark",
    "Whipping reminder"
  ],
  "obey": [
    "Heed",
    "Follow",
    "Evil hypnotist's directive",
    "Toe the line",
    "Heed",
    "Not question",
    "Follow orders",
    "\"Do as I say!\"",
    "Toe the line",
    "Comply with",
    "Observe",
    "Do as one's told",
    "Earn a treat, say",
    "\"You must ___\" (order to an earthling)",
    "Observe",
    "Follow orders",
    "Follow, as orders",
    "Mind",
    "Do as expected",
    "Comply with",
    "Mind",
    "Follow",
    "Follow",
    "Not rock the boat",
    "Succumb to mind control",
    "Observe",
    "Do as told",
    "Mind",
    "\"___ your thirst\" (former Sprite slogan)",
    "Bow to",
    "Toe the line",
    "Follow orders",
    "Passe wedding vow verb",
    "Toe the line",
    "Mind",
    "Toe the line",
    "Love, honor and ___",
    "\"You must ___\"",
    "Mind",
    "Word removed from many modern wedding vows",
    "Heed",
    "Hypnotist's directive",
    "Toe the line",
    "Stay in line",
    "Follow",
    "Follow",
    "What a hypnotist may tell you to do",
    "Follow",
    "Comply with",
    "Outdated wedding-vow word",
    "\"You ... will ... ___!\"",
    "Mind",
    "Hypnotist's imperative",
    "Toe the line",
    "Follow orders",
    "Word in old wedding vows",
    "Follow",
    "Command to a wayward child",
    "Follow orders",
    "Observe",
    "Do as directed",
    "Listen to",
    "Follow orders",
    "Mind",
    "Old wedding vows word",
    "Bow to",
    "Follow",
    "Toe the line",
    "Mind",
    "Toe the line",
    "Follow orders",
    "Follow",
    "Mind",
    "Pay heed",
    "\"You will ___\" (hypnotist's phrase)",
    "Comply",
    "Listen to",
    "Observe",
    "Mind",
    "Observe",
    "Mind",
    "Heed",
    "Mind",
    "Follow the rules",
    "Mind",
    "Mind",
    "Follow",
    "Comply",
    "Do as directed",
    "Follow",
    "Follow, as orders",
    "Follow the code",
    "Do what you're told",
    "Follow instructions",
    "Word from a hypnotist",
    "Observe",
    "Follow orders",
    "Passe word in marriage vows",
    "Follow orders",
    "Mind",
    "Old-fashioned wedding word",
    "Comply with",
    "Observe",
    "Be loyal to",
    "Word in old wedding vows",
    "Follow",
    "Be loyal to"
  ],
  "bunt": [
    "Many a sacrifice play in baseball",
    "Baseball hit that doesn't go far",
    "It may be a sacrifice",
    "Part of a squeeze play",
    "Small hit",
    "Part of a suicide squeeze",
    "Make a sacrifice, perhaps",
    "Batter's ploy",
    "Minor hit",
    "It may be a sacrifice",
    "Short hit, in baseball",
    "Diamond sacrifice",
    "It doesn't get far from home",
    "Surprise hit?",
    "It can be a sacrifice",
    "Batter's ploy"
  ],
  "cope": [
    "Make do",
    "Deal (with)",
    "Deal with it",
    "Deal (with)",
    "Get along",
    "Manage",
    "Handle adversity",
    "Deal (with)",
    "Handle things",
    "Hack it",
    "Get by",
    "Deal (with)",
    "Deal",
    "Deal (with)",
    "Make do",
    "Hack it",
    "Endure",
    "Deal",
    "Manage, somehow",
    "Manage",
    "Make do",
    "Handle",
    "Manage",
    "Deal (with)",
    "Handle the situation",
    "Handle, with \"with\"",
    "Deal (with)",
    "Rise to the occasion"
  ],
  "sons": [
    "Ares and Apollo, to Zeus",
    "Sequel to Pearl S. Buck's \"The Good Earth\"",
    "Princes, e.g.",
    "FX network's \"___ of Anarchy\"",
    "\"___ and Lovers\" (D. H. Lawrence novel)",
    "\"The Brady Bunch\" threesome",
    "Lead-in to \"of Liberty\" or \"of Anarchy\"",
    "Jacob had 12 of them",
    "Daughters' counterparts",
    "Last word in many company names",
    "George H. W. Bush had four",
    "Company name ender after \"&\"",
    "Some heirs",
    "George Foreman Jr., George Foreman III, George Foreman IV, etc.",
    "Princes, e.g.",
    "End of some business names",
    "Mamas' boys",
    "Ending of many a firm's name",
    "Juniors",
    "Mamas' boys",
    "Word after \"&\" in some store names",
    "End of many company names",
    "Pearl S. Buck's sequel to \"The Good Earth\"",
    "They may have \"II\" or \"III\" after their names",
    "Heirs, but not heiresses",
    "End of some firm names",
    "Daughters' counterparts",
    "Steinway & ___",
    "End of many company names",
    "End of many business names",
    "End of some company names",
    "Steinway & ___ (piano maker)",
    "Male issue",
    "Princes but not princesses",
    "End of many company names",
    "See 63-Across",
    "\"___ and Lovers\" (D. H. Lawrence book)",
    "What England's George VI lacked",
    "Some Father's Day callers",
    "Word in many business names",
    "Daughters' counterparts",
    "Some young 'uns",
    "Princes, but not princesses",
    "Princes, e.g.",
    "About half of all deliveries",
    "Beneficiaries of primogeniture",
    "Chips off the old block",
    "Princes, e.g.",
    "Princes, e.g.",
    "Royal heirs",
    "Offspring",
    "Conclusion of many firm names",
    "Heirs, often",
    "What Henry VIII wanted",
    "Charlie Chan's #1 and #2",
    "King's desires",
    "Shem and Ham, to Noah",
    "D.H. Lawrence's \"___and Lovers\"",
    "Pearl S. Buck novel",
    "Some heirs",
    "TV's \"My Three___\"",
    "Sequel to Buck's \"The Good Earth\"",
    "Arthur Miller's \"All My ___\"",
    "Some heirs"
  ],
  "alma": [
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "Michigan college",
    "President Coin in the \"Hunger Games\" series",
    "___ Reville, Alfred Hitchcock's wife and collaborator",
    "Heroine of Tennessee Williams's \"Summer and Smoke\"",
    "Michigan college town",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ Reville, Hitchcock's wife and collaborator",
    "___ mater",
    "___ mater",
    "___ mater",
    "Soprano Gluck",
    "___ mater",
    "Michigan college",
    "Michigan college or its town",
    "___ mater",
    "Book of Mormon book",
    "Michigan college",
    "Michigan college",
    "Michigan college",
    "___ mater",
    "Michigan college town",
    "Presbyterian-founded Michigan college",
    "___ mater",
    "___ mater",
    "Longest book of the Book of Mormon",
    "___ mater",
    "Soprano Gluck",
    "Soprano Gluck",
    "Book of Mormon's longest book",
    "Michigan's ___ College",
    "Michigan college",
    "Michigan town or college",
    "Girl in Tennessee Williams's \"Summer and Smoke\"",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "Soprano Gluck",
    "___ mater",
    "Michigan college or its town",
    "___ mater",
    "Diva Gluck",
    "Michigan college or its town",
    "___ mater",
    "Michigan's ___ College",
    "Diva Gluck",
    "___ mater",
    "Name from the Latin for \"nourishing\"",
    "___ mater",
    "___ mater",
    "___ mater",
    "___ mater",
    "\"Summer and Smoke\" heroine",
    "___ mater",
    "Michigan college or its town",
    "Soprano Gluck",
    "___ mater",
    "Soprano Gluck",
    "\"Summer and Smoke\" heroine",
    "___ mater",
    "___ mater",
    "___ mater",
    "Soprano Gluck",
    "___ mater",
    "___ mater",
    "Michigan college",
    "Mrs. Alfred Hitchcock",
    "Michigan college",
    "Michigan college",
    "Soprano Gluck",
    "Mrs. Alfred Hitchcock",
    "Heroine of Tennessee Williams's \"Summer and Smoke\"",
    "Michigan college or its town",
    "Soprano Gluck",
    "Heroine of Tennessee Williams's \"Summer and Smoke\"",
    "Soprano Gluck",
    "Book of the Book of Mormon",
    "Michigan town or its college",
    "___ mater",
    "___ mater",
    "Michigan town or its college",
    "___ mater",
    "___ mater",
    "___ mater",
    "___mater",
    "___mater",
    "Michigan college",
    "Soprano Gluck",
    "___ mater",
    "Heroine of Tennessee William's \"Summer and Smoke\"",
    "___ mater",
    "Michigan college town"
  ],
  "cued": [
    "Fed a line to",
    "Signaled to start",
    "Like forgetful actors",
    "Given the signal",
    "Ready to play, with \"up\"",
    "Readied to play",
    "Like many a forgetful actor",
    "Prompted",
    "Ready to be played",
    "Like some actors going on stage",
    "Given a line",
    "Gave lines to",
    "Fed a line",
    "Helped with a line",
    "Gave a nod to, perhaps",
    "Prompted",
    "Fed lines to",
    "Fed lines to, as an actor",
    "Signaled",
    "Signaled",
    "Prompted"
  ],
  "opry": [
    "Grand Ole ___",
    "Nashville music mecca, informally",
    "Grand Ole ___",
    "Grand Ole ___",
    "Nashville landmark, familiarly",
    "\"Grand Ole\" venue",
    "\"Grand Ole\" thing",
    "Nashville venue, informally",
    "Grand Ole ___",
    "Nashville site, familiarly",
    "Nashville music mecca, for short",
    "\"Live at the ___\" (Patsy Cline album)",
    "See 38-Across",
    "Grand Ole ___",
    "Down-home entertainment",
    "C & W mecca, with \"the\"",
    "___ house (down-home music site)",
    "Word after \"Ole\"",
    "Grand Ole ___",
    "Grand Ole ___",
    "Down-home entertainment",
    "Music, Nashville-style",
    "Grand Ole ___",
    "Grand Ole ___"
  ],
  "boro": [
    "The Bronx or Brooklyn, informally",
    "Manhattan, e.g., informally",
    "Any one of five in the Big Apple",
    "Brooklyn, e.g., informally",
    "___ Park (B'klyn neighborhood)",
    "New York's Five ___ Bike Tour",
    "Queens, e.g., informally",
    "Staten Isl., e.g.",
    "Urban division, informally",
    "Queens, e.g., informally",
    "Brooklyn or the Bronx, informally",
    "Bklyn., e.g."
  ],
  "dont": [
    "\"___ mention it!\"",
    "Journey's \"___ Stop Believin'\"",
    "Terse caution",
    "Terse bit of advice",
    "\"I strongly advise against that\"",
    "Faux pas",
    "Word of warning",
    "Terse caution",
    "\"Cut that out!\"",
    "Discouraging advice",
    "Word before know and care",
    "With 30- and 53-Down, 1964 Beach Boys hit",
    "Word of warning",
    "Succinct warning",
    "\"Cut it out!\"",
    "\"Stop it!\"",
    "Advice regarding touching a hot stove",
    "\"___ Stop\" (Bill Clinton theme song)",
    "Quip, part 2",
    "Terse bit of advice",
    "Cautionary note",
    "Terse warning",
    "\"Cut it out!\"",
    "\"No!\"",
    "\"Stop that!\"",
    "\"Cease and desist\"",
    "1958 #1 Elvis Presley hit",
    "Word said with a hand-slap",
    "Stop order?",
    "Prohibition",
    "Cautionary advice",
    "Quote, part 5",
    "One-word advice",
    "Word of warning",
    "1958 Elvis song",
    "1958 Presley #1 hit",
    "Word of warning",
    "No-no",
    "A word of warning",
    "#1 Presley hit",
    "Word of warning"
  ],
  "sift": [
    "De-clump, as flour",
    "Sort (through)",
    "Sort (through)",
    "Sort through, as evidence",
    "Winnow",
    "Strain",
    "Search",
    "Search (through)",
    "Search (through), as evidence",
    "Go (through)",
    "De-lump, as flour",
    "Search (through)",
    "Go (through)",
    "Strain",
    "Screen",
    "Separate out",
    "Sort (out)",
    "Sort (through)",
    "Sort through",
    "Search (through)",
    "Go (through), as evidence",
    "Go (through)",
    "Separate",
    "Refine, as flour",
    "Separate, as flour or ashes",
    "Screen",
    "Investigate, in a way",
    "Search (through)"
  ],
  "loch": [
    "Scotland's ___ Lomond",
    "Partially landlocked bay",
    "Highland body",
    "Nessie's home",
    "Scotland's ___ Lomond",
    "Narrow arm of the sea",
    "Shetland Islands sight",
    "___ Ness",
    "___ Ness",
    "___ Ness monster",
    "Fishing spot for Scots",
    "___ Ness monster",
    "___ Lomond",
    "Lomond, e.g.",
    "___ Ness monster",
    "Scotland's ___ Awe",
    "___ Ness monster",
    "___ Ness monster",
    "___ Lomond",
    "Shetland Islands sight",
    "___ Ness monster",
    "___ Ness monster",
    "Ness, e.g.",
    "___ Ness monster",
    "___ Fyne, Scotland",
    "Monster-sighting spot",
    "Tay, say",
    "___ Fyne, Scotland",
    "___ Ness monster",
    "Scotland's ___ Fyne",
    "Place to summer in Scotland",
    "___ Ness",
    "Monster's home",
    "Leven, for one",
    "___ Tay, Scotland",
    "Ness, for one",
    "Large pond",
    "___ Ness"
  ],
  "good": [
    "\"I'm glad to hear it\"",
    "Not spoiled",
    "Rating between excellent and fair",
    "Well-behaved",
    "\"Well done\"",
    "\"Well done!\"",
    "Well-behaved",
    "Not too shabby",
    "Tasty",
    "\"I'm glad!\"",
    "The \"G\" in EGBDF",
    "With 65-Across, comment that might be heard after the start of 17-, 28-, 45- or 59-Across",
    "With 37-Down, what 17-, 24-, 47- and 58-Across are all said to bring",
    "Well-behaved",
    "With 44-Down, kindness",
    "Through the uprights",
    "B, gradewise",
    "\"Well done!\"",
    "Dandy",
    "Fine",
    "B or B+, say",
    "In bounds",
    "Saintly",
    "Yummy",
    "Deserving a thumbs-up",
    "Not counterfeit",
    "Well-behaved",
    "With 53-Down, smarts",
    "Close, as friends",
    "Sound",
    "Philanthropy",
    "Fair beater",
    "Yummy",
    "Tasty",
    "B or better",
    "Saintly",
    "B, gradewise",
    "Well-mannered"
  ],
  "wail": [
    "Really play that saxophone",
    "Cry of woe",
    "Cry like a baby",
    "Sound from a banshee",
    "What sirens do",
    "Howl",
    "Act like a baby, maybe",
    "Cry",
    "Complain loudly",
    "Give a powerful electric guitar performance",
    "Keen",
    "Banshee's cry",
    "Lament loudly",
    "Cry like a baby",
    "Cry",
    "Sound of a sax",
    "Siren's sound",
    "Banshee's sound",
    "Play a sax solo, maybe",
    "Blow a mean horn",
    "Yowl",
    "Banshee's cry",
    "Cry one's head off",
    "Banshee's cry",
    "Cry like a banshee",
    "Mourn out loud",
    "Cry out",
    "Siren's sound",
    "Sound like a siren",
    "Sirens do it",
    "Sing the blues",
    "Lament"
  ],
  "nook": [
    "Modern reading option ... or where to read it?",
    "Cozy place",
    "Corner",
    "Cozy spot",
    "Recessed area in a kitchen",
    "Corner recess",
    "Recess in a room",
    "Kindle competitor",
    "Recess",
    "Corner",
    "___ and cranny",
    "Cozy corner",
    "New tablet of 2011",
    "Reading place ... or reading device",
    "Kindle competitor",
    "Recess",
    "Kindle competitor",
    "Sony Reader competitor",
    "Cozy dining spot",
    "Barnes & Noble electronic reader",
    "Cozy corner",
    "Cozy spot",
    "Cozy corner",
    "Hiding place",
    "Dinette spot",
    "Corner",
    "Breakfast place",
    "Cozy spot",
    "Cozy corner",
    "Breakfast place",
    "Secluded spot",
    "Alcove",
    "Cozy spot",
    "Hiding place",
    "Dinette spot",
    "Reading spot",
    "Hiding place",
    "Breakfast area",
    "Small bay",
    "Breakfast ___",
    "Shady spot",
    "Corner",
    "Secluded spot",
    "Hide-and-seek hideout",
    "Place for a dinette set",
    "Cozy corner",
    "Out-of-the-way place",
    "Breakfast area",
    "Cranny's partner",
    "Quiet spot"
  ],
  "orgy": [
    "Dionysian ritual",
    "Function with no limits?",
    "Lovefest, literally",
    "Unrestrained revelry",
    "Anything-goes party",
    "Out-of-control revelry",
    "Wild revelry",
    "Wild revelry",
    "Bacchanal",
    "Rowdy revelry",
    "Occasion of overindulgence",
    "Bacchanalian bash",
    "Wild time",
    "Wild party",
    "Saturnalia",
    "Bacchanalia",
    "Hardly an exercise in restraint",
    "Unrestrained revelry",
    "Spree",
    "Period of indulgence",
    "Unrestrained revelry",
    "Bacchanalian revelry",
    "Liberal party?",
    "Unlikely event for puritans",
    "Immoderate indulgence",
    "Binge",
    "Binge",
    "Bacchanalian revelry",
    "Unrestrained revelry",
    "Excessive indulgence",
    "Wild revelry",
    "Bacchanalian blast",
    "Wild revelry",
    "Wild goings-on",
    "Spree",
    "Unrestrained revelry",
    "Bacchanal",
    "Binge",
    "Wild revelry",
    "Occasion of immoderation",
    "Wild time",
    "Unbridled indulgence",
    "Wild romp",
    "Bacchanal",
    "Bacchanalian event",
    "Bout of debauchery",
    "Wild party",
    "Overindulgence"
  ],
  "byob": [
    "Notation on a party invite",
    "Abbr. about alcohol on a party invitation",
    "Invitation stipulation",
    "Party spec",
    "[Guests must provide their drinks]",
    "Letters of invitation?",
    "Party rule, for short",
    "Drinker's party instruction, for short",
    "Message indicating \"adult beverages not supplied\"",
    "Abbr. often preceding 29-Down",
    "Abbr. often near \"R.S.V.P.\"",
    "Abbr. in many a party invitation",
    "Like many restaurants without a liquor lic.",
    "Part of an invitation to an imbiber",
    "Invitation notation",
    "Invitation stipulation",
    "Letters on some invitations",
    "Invite letters",
    "Informal invitation letters",
    "Party directive, briefly",
    "P.S. on an invitation",
    "Letters of invitation",
    "T.G.I.F. party request"
  ],
  "dour": [
    "Like Eeyore",
    "Hardly cheery",
    "Gloomy",
    "Visibly forbidding",
    "Gloomy",
    "Gloomy",
    "Stern-looking",
    "Gloomy",
    "Gloomy",
    "Gloomy",
    "Forbidding",
    "Forbidding, as an expression",
    "Gloomy",
    "Sullen",
    "Grumpy",
    "Harsh",
    "Gloomy",
    "Gloomy",
    "Forbidding",
    "Ill-humored",
    "Sullen",
    "Far from perky",
    "Sullen",
    "Ill-humored",
    "Saturnine",
    "Saturnine",
    "Glum",
    "Moody",
    "Sullen",
    "GRUMPY",
    "Gloomy",
    "Forbidding",
    "Hardly upbeat",
    "Gloomy",
    "Forbidding",
    "Gloomy",
    "Gloomy",
    "Grim",
    "Gloomy"
  ],
  "diva": [
    "Hard-to-please type",
    "Cher or Adele",
    "La Scala star",
    "Recipient of a \"Brava!\"",
    "Go full ___ (throw a world-class hissy fit)",
    "Prima donna",
    "Temperamental type",
    "Agent's handful, say",
    "Performer inclined to throw tantrums",
    "Tantrum thrower",
    "Any singer in the lead role of 41-Across",
    "Tempestuous talent",
    "Persnickety performer",
    "Demanding sort",
    "Many an exploding star",
    "Demanding sort",
    "Bette Midler, e.g.",
    "Demanding sort",
    "Demanding sort",
    "Palais Garnier star",
    "Singer with a reputation for being self-centered",
    "La Scala headliner",
    "Hard-to-please celeb",
    "Demander of special treatment",
    "She may try an agent",
    "Hissy-prone missy",
    "One who might receive roses at the end of a performance",
    "\"Me, me, me\" sort",
    "Opera star",
    "Aria singer",
    "Temperamental performer",
    "Curtain call maker, maybe",
    "Aria singer",
    "See 11-Down",
    "Prima donna",
    "Temperamental sort",
    "Singer who definitely has her own dressing room",
    "Vain sort",
    "Demanding star, maybe",
    "Egoistical, demanding sort",
    "Tantrum thrower, maybe",
    "Met star",
    "Singer with attitude",
    "One with her own dressing room, surely",
    "Leading lady",
    "Opera star",
    "She's got the music in her",
    "Opera persona",
    "Met attraction",
    "Callas, for one",
    "Operatic soloist",
    "Tosca is one",
    "Temperamental star",
    "One who hits the high notes",
    "La Scala star",
    "Bette Midler's \"___ Las Vegas\"",
    "Woman who can carry a tune",
    "Beverly Sills, e.g.",
    "Opera star",
    "Battle, e.g.",
    "Opera star",
    "Literally, \"goddess\"",
    "Midler's \"___ Las Vegas\"",
    "Prima donna",
    "Baker or Battle, e.g.",
    "Tebaldi or Te Kanawa",
    "Covent Garden performer",
    "Upscale singer?",
    "Met # 1?",
    "\"1982 Beineix thriller",
    "1982 movie thriller"
  ],
  "sfpd": [
    "Eddie Murphy's org. in \"48 Hrs.\"",
    "West Coast law force, for short",
    "\"Dirty Harry\" org.",
    "Employer of Detective Lindsay Boxer in a series of James Patterson novels",
    "Dirty Harry's grp.",
    "\"Dirty Harry\" org.",
    "Dirty Harry's org.",
    "West Coast law force, for short",
    "Cal tecs?",
    "Org. that started during the California gold rush",
    "West Coast cop squad, for short",
    "Law force in 1960s-'70s TV's \"Ironside\"",
    "Bay Area law force inits.",
    "Bay Area force: Abbr.",
    "Org. in the \"Dirty Harry\" movies",
    "Org. in \"Monk\"",
    "\"Bullitt\" law enforcement org.",
    "Bay Area force: Abbr.",
    "Dirty Harry's org.",
    "\"Monk\" org.",
    "Cop squad in \"Monk\": Abbr.",
    "Law enforcement org. featured in \"Bullitt\"",
    "Org. in \"Monk\"",
    "Bay Area law enforcement org.",
    "Dirty Harry's employer: Abbr.",
    "Org. in TV's \"Nash Bridges\"",
    "Real-life org. seen in \"Bullitt\"",
    "Bay Area blues: Abbr.",
    "Org. in TV's \"Nash Bridges\"",
    "Calif. force",
    "Employer of TV's Nash Bridges, briefly",
    "Bay Area patrollers: Abbr.",
    "Dirty Harry's grp.",
    "\"The Lineup\" grp.",
    "Dirty Harry's employer: Abbr."
  ],
  "upto": [
    "As much as",
    "No more than",
    "Before",
    "As many as",
    "Engaged in",
    "Until",
    "\"I've had it ___ here!\"",
    "\"What have you been ___?\"",
    "Until",
    "Until",
    "\"What've you been ___?\"",
    "Doing",
    "Scheming about",
    "Planning",
    "Until",
    "Plotting",
    "At the discretion of",
    "___ no good",
    "___ no good",
    "As far as",
    "___ no good",
    "___ no good",
    "___ snuff",
    "Till",
    "Incumbent on",
    "Doing",
    "Not exceeding",
    "Plotting",
    "As many as",
    "___ no good",
    "\"What've you been ___?\"",
    "\"What've you been ___?\"",
    "Engaged in",
    "Capable of",
    "Engaged in",
    "Machinating",
    "Until",
    "Till",
    "Plotting",
    "\"Whatcha ___?\"",
    "See 54-Across",
    "As far as",
    "___ no good",
    "___ no good",
    "___ snuff",
    "Doing",
    "As far as",
    "___ snuff",
    "___ the task",
    "Words before speed or snuff",
    "Preceding",
    "Machinating",
    "\"It's ___ you!\"",
    "___ no good",
    "Doing",
    "Capable of",
    "Reaching as far as",
    "\"What have you been ___?\"",
    "Ready for",
    "Doing, so to speak",
    "___no good",
    "___ no good",
    "Until"
  ],
  "cans": [
    "Some recyclables",
    "Survivalist's stockpile",
    "Gives the boot",
    "Food drive collection",
    "Sends packing",
    "Sacks",
    "Pink-slips",
    "Fires",
    "Pink-slips",
    "Pink-slips",
    "Film holders",
    "Cashiers",
    "Axes",
    "Boots",
    "Recycle bin fillers",
    "Lets go",
    "Keisters",
    "Pink-slips",
    "Gives the boot",
    "Gives the heave-ho",
    "Gives the boot",
    "Fires",
    "Cashiers",
    "Sends packing",
    "Fires",
    "Grocery line?",
    "Trash holders",
    "Pink-slips",
    "Fires",
    "Axes",
    "Gives the heave-ho",
    "Fires",
    "Gives the heave-ho"
  ],
  "than": [
    "As compared to",
    "Better ___ ever",
    "Comparative word",
    "Better ___",
    "Other ___",
    "Follower of greater or lesser",
    "It follows more or less",
    "What may follow bigger or better",
    "Lighter-air link",
    "Word often appearing after a comparative",
    "Holier-___-thou",
    "Comparison word",
    "Hotter ___ hell",
    "Comparison word",
    "Comparative indicator",
    "More ___ enough",
    "What may follow bigger or better",
    "Comparative word",
    "Greater or lesser follower",
    "Other ___",
    "Larger ___ life",
    "Word of comparison",
    "Comparative follower",
    "Comparative word",
    "See 107-Down",
    "More or less follower",
    "Common conjunction",
    "Comparison connector",
    "Part of a comparison",
    "More ___ enough",
    "Comparison word",
    "Comparative follower",
    "Comparative follower",
    "Word with greater or rather",
    "Comparison connector",
    "Holier ___ thou",
    "Comparison word",
    "Comparative word",
    "Larger ___ life",
    "More or less follower",
    "Comparison word",
    "Comparative word",
    "Lighter ___ air",
    "Holier ___ thou",
    "Comparison word",
    "Comparative word",
    "Larger ___ life",
    "Part of a comparison",
    "Compared to",
    "Part of a comparison",
    "Part of a comparison",
    "Holier-___-thou",
    "More___meets the eye",
    "In comparison with",
    "Comparison word",
    "\"More ___ You Know\" (1929 song)",
    "Ricky Nelson's \"Sweeter ___ You\""
  ],
  "gnaw": [
    "Eat (at)",
    "Trouble, with \"at\"",
    "Irk, with \"at\"",
    "Bite like a beaver",
    "Chew (on), beaver-style",
    "Chew on like a beaver",
    "Chew (on)",
    "Nibble (on)",
    "Plague, with \"at\"",
    "Nibble (on)",
    "Plague, with \"at\"",
    "Make a mouse hole, say",
    "What rats may do to baseboards",
    "Badly bother, with \"at\"",
    "Act the rat",
    "Chew (on)",
    "Plague, with \"at\"",
    "Chew like a rat",
    "Be a busy beaver?",
    "Act like a beaver",
    "Act like a rat, say",
    "Bother, with \"at\"",
    "Chew like a beaver",
    "Make mouseholes, maybe",
    "Bother, with \"at\"",
    "Wear down",
    "Chew (on)",
    "Really bother, with \"at\"",
    "Be a busy beaver",
    "Not just nibble",
    "Munch on like a mouse",
    "Act like a rat",
    "Chew (on)",
    "Chew on",
    "Bite like a beaver",
    "Work on a bone, say",
    "Bother, with \"at\"",
    "Beleaguer, with \"at\"",
    "Eat indelicately",
    "Chew like a rat",
    "Chew on",
    "Be beaverish",
    "Chew (on)",
    "Work like a beaver",
    "Chew (on)",
    "Nibble away",
    "Chew on celery, e.g.",
    "Bite like a beaver",
    "Wear down little by little",
    "Chew (on)",
    "Chew (on)",
    "Nibble",
    "Vex, with \"at\"",
    "Nibble away",
    "Chew, like a beaver",
    "Nibble",
    "Chew like a beaver",
    "Chew (on)",
    "Put some teeth into",
    "Eat beaver-style",
    "Chew",
    "Chew (on)",
    "Be an eager beaver",
    "Emulate a rodent",
    "Bother, with \"at\"",
    "Plague, with \"at\"",
    "___ at (torment)",
    "Nibble",
    "Chew like a beaver"
  ],
  "minn": [
    "St. Cloud State University's state: Abbr.",
    "Sen. Al Franken's state: Abbr.",
    "Ont. neighbor",
    "Brooklyn Park setting: Abbr.",
    "Wisc. neighbor",
    "Where Bob Dylan was born: Abbr.",
    "Lake Wobegon's locale: Abbr.",
    "Voyageurs Natl. Park locale",
    "Voyageurs Natl. Park locale",
    "Its motto is L'Etoile du Nord: Abbr.",
    "Neighbor of Wis."
  ],
  "loll": [
    "Laze",
    "Veg out",
    "Laze",
    "Take it easy",
    "Veg out",
    "Laze",
    "Take it easy",
    "Laze",
    "Laze",
    "Laze",
    "Lounge lazily",
    "Lie around",
    "Veg out",
    "Rest in a hammock, say",
    "Idle",
    "Hang loose",
    "Veg out",
    "Laze",
    "Lounge around",
    "Lie a lot",
    "Laze",
    "Lounge around",
    "Hang around",
    "Do nothing and like it",
    "Laze about",
    "Relax",
    "Stretch out on a sofa, say",
    "Lounge",
    "Take up the entire sofa",
    "Lie around",
    "Lounge",
    "Take it easy",
    "Vegetate",
    "Hang loose",
    "Veg out",
    "Take it easy",
    "Laze",
    "Take it easy",
    "Lounge",
    "Hang out",
    "Veg out",
    "Veg out",
    "Hang out",
    "Hang (around)",
    "Laze about",
    "Take it easy",
    "Hang loose",
    "Be a couch potato",
    "What some vacationers do",
    "Loaf about",
    "Recline lazily",
    "Lounge",
    "Take it easy",
    "Laze",
    "Hang out",
    "Take it easy",
    "Take it easy",
    "Kick back",
    "Laze"
  ],
  "iuds": ["Birth control options", "Family planning options, briefly"],
  "noms": [
    "Delicious food, in modern slang",
    "Listings in une encyclopedie",
    "Jules et Jim, e.g.",
    "List in an actor's resume, informally",
    "Club aliases, for short",
    "Jules et Jim, par exemple",
    "Pierre, Francois, etc.",
    "Jules et Jim, e.g.",
    "French registry listings",
    "Athos, Porthos and Aramis, e.g.",
    "Annuaire listings",
    "Andre, Francoise, etc."
  ],
  "duma": [
    "Putin's parliament",
    "Russian assembly",
    "Russian assembly",
    "Legislative body of Russia",
    "Russian legislature",
    "Russian council",
    "Russian legislature",
    "Russian diet",
    "Russian legislature",
    "Russian council",
    "Russian legislature",
    "Lower chamber of Russia's parliament",
    "Russian diet?",
    "Russian assembly",
    "Russian assembly",
    "Russian legislature",
    "Russian assembly",
    "Russian legislature",
    "Russian parliament",
    "Russian parliament",
    "Russian parliament",
    "Old Russian assembly",
    "Old Russian assembly"
  ],
  "nsfw": [
    "Warning made with H.R. in mind",
    "Trailer advisory",
    "\"Avoid watching this at the office,\" briefly",
    "[Warning: explicit content]",
    "\"Careful where you watch this,\" in emails",
    "Office-inappropriate, in web shorthand",
    "You might be careful opening something with this label",
    "[Avoid watching this in front of the boss]",
    "Office-inappropriate, briefly",
    "Letters of warning on internet sites",
    "[Warning: explicit content]",
    "Warning letters next to a web link",
    "Warning letters next to a link",
    "Warning initials above an Internet link",
    "Warning next to an internet link",
    "Warning letters on some graphic videos",
    "Tag for inappropriate online photos",
    "Online heads-up"
  ],
  "ahem": [
    "\"Beg pardon ...\"",
    "\"I'm right here\"",
    "[I can hear you, you know]",
    "Audible nudge",
    "\"Sorry to intrude ...\"",
    "[Pardon me!]",
    "[Um ... I'm standing right here]",
    "Faux cough",
    "Throat clearer",
    "\"Uh ... excuse me\"",
    "\"If I may interject ...\"",
    "\"Hello ... I'm right here\"",
    "*cough, cough*",
    "\"If I may say a few words ...\"",
    "[I'm still here, you know]",
    "\"Um, don't look now, but ...\"",
    "Conversation stopper",
    "\"Uh, excuse me\"",
    "\"Um, don't look now, but ...\"",
    "*cough*",
    "\"Excuse me\"",
    "\"Hello ... I'm right here\"",
    "Slight interruption",
    "\"Um, pardon me\"",
    "\"I'd like to make a point ...\"",
    "\"Pardon ...\"",
    "\"Don't forget about me\"",
    "\"Beg pardon ...\"",
    "\"Aren't you forgetting something?\"",
    "Cousin of \"um\"",
    "\"I'm right here, you know\"",
    "\"Um, pardon ...\"",
    "Word of pardon",
    "Kiss interrupter, maybe",
    "\"Um, excuse me\"",
    "\"Excuse me\"",
    "\"Pardon the interruption ...\"",
    "[Attention, please ...]",
    "[Pardon]",
    "[May I have your attention?]",
    "\"Uh, excuse me\"",
    "Throat-clearing",
    "\"Excuse me\"",
    "Throat clearer",
    "\"Could we get started, please ...\"",
    "\"Uh, excuse me ...\"",
    "\"Don't forget about me\"",
    "Tap-on-the shoulder alternative",
    "\"Don't you forget about me\"",
    "\"Excuse me ...\"",
    "\"Excuse me ...\"",
    "Sound before \"Your fly is open\"",
    "\"Attention please ...\"",
    "Subtle warning sound",
    "\"Pardon me\"",
    "Sound before \"Your, um, fly is open\"",
    "\"May I speak?!\"",
    "\"Uh, pardon me\"",
    "Quiet cough",
    "\"Uh, excuse me\"",
    "Call for attention",
    "Attention getter",
    "Polite way to interrupt someone",
    "Attention getter",
    "\"Pardon me\"",
    "Faux cough",
    "\"Beg pardon ...\"",
    "\"Sorry to intrude ...\"",
    "\"Excuse me\"",
    "\"Um, excuse me\"",
    "\"Uh ... excuse me\"",
    "Start of a break-in?",
    "Attention-getter",
    "\"Excuse me ?à\"",
    "\"Um, pardon me ...\"",
    "Throaty sound",
    "\"Don't forget about me\"",
    "\"Your slip is showing\"",
    "\"Pardon\"",
    "\"Excuse me ?à\"",
    "Meaningful little cough",
    "Throat-clearing sound",
    "\"What am I, invisible?\"",
    "\"Excuse me ...\"",
    "\"Beg pardon ...\"",
    "Clearing, of sorts",
    "\"Excuse me ...\"",
    "Pause filler",
    "\"Um, I hate to interrupt ...\"",
    "Throat clearing",
    "Slight interruption",
    "Coughlike utterance",
    "Interruption",
    "\"Don't forget about me\"",
    "Interrupter's word",
    "\"Excuse me ...\"",
    "\"Excuse me ...\"",
    "\"Did you forget about me?\"",
    "\"Excuse me\"",
    "\"Beg pardon ...\"",
    "Sound of a frog?",
    "Start of a break-in",
    "\"May I have your attention?\"",
    "\"Excuse me...\"",
    "Verbal break-in",
    "[Pardon]",
    "\"Excuse me...\"",
    "Interruption starter",
    "Start of a break-in",
    "\"Excuse me\"",
    "\"Pardon me\"",
    "\"Um, pardon me\"",
    "\"Excuse me...\"",
    "Attention-getter",
    "Doubt-conveying interjection",
    "Throat clearer",
    "\"Pardon me\"",
    "\"Excuse me\"",
    "Throat-clearing sound",
    "\"Um, excuse me\"",
    "\"Beg your pardon\"",
    "Polite interruption",
    "\"Excuse me\"",
    "\"Excuse me\"",
    "\"Pardon...\"",
    "\"Excuse me...\"",
    "Sound of a break-in?",
    "\"Pardon me...\"",
    "\"Excuse me...\"",
    "\"Excuse me...\"",
    "\"Psst\" alternative",
    "\"Excuse me...\"",
    "\"Beg pardon\"",
    "\"Pardon me\"",
    "\"Pardon me!\""
  ],
  "nous": [
    "More than just moi",
    "\"A ___ la Liberte\" (1931 Rene Clair film)",
    "Entre ___",
    "What we may be overseas?",
    "Entre ___",
    "Entre ___ (confidentially)",
    "Us, abroad",
    "Us outside the U.S.",
    "Entre ___",
    "Entre ___",
    "Pronoun with \"sommes\"",
    "Entre ___",
    "Entre ___",
    "Entre ___",
    "Entre ___",
    "Entre ___ (confidentially)",
    "We, oui?",
    "Entre ___",
    "Entre ___",
    "Entre ___",
    "Entre ___"
  ],
  "aint": [
    "\"___ Nobody\" (hit for Rufus and Chaka Khan)",
    "\"___ Too Proud\" (hit musical about the Temptations)",
    "\"___ 2 Proud 2 Beg\" (TLC hit)",
    "Slangy contraction",
    "Aren't wrong?",
    "Colloquial contraction",
    "\"___ That Good News,\" 1964 Sam Cooke album",
    "\"___ it the truth!\"",
    "\"You ___ kiddin'!\"",
    "\"___ No Mountain High Enough\"",
    "\"___ that somethin'?\"",
    "Nonstandard negative",
    "\"If it ___ broke ...\"",
    "\"You ___ kiddin'!\"",
    "Slangy negative",
    "Informal negation",
    "\"___ so\" (\"Nuh-uh\")",
    "\"___ No Sunshine\" (1971 hit for Bill Withers)",
    "Slangy negative contraction",
    "\"___ It Fun\" (2014 Best Rock Song Grammy winner)",
    "\"___ that somethin'?\"",
    "Colloquial contraction",
    "Isn't bad?",
    "\"___ it the truth!\"",
    "Nonstandard negative",
    "Slangy form of 60-Across",
    "\"You ___ kiddin'!\"",
    "\"You ___ Seen Nothing Yet\" (1974 #1 hit)",
    "Slangy rebuttal to 65-Across",
    "Fats Waller's \"___ Misbehavin'\"",
    "\"___ it the truth!\"",
    "\"It ___ happenin'\"",
    "\"Sorry, that ___ happenin'!\"",
    "\"Hit 'em where they ___\"",
    "What shouldn't follow you?",
    "\"It ___ over till ...\"",
    "25-Down, nonstandardly",
    "Informal contraction",
    "\"___ Nobody's Business\" (old blues standard)",
    "\"Is you is or is you ___ ma' baby?\"",
    "\"It ___ right\"",
    "Isn't incorrect?",
    "Ungrammatical contraction",
    "Isn't wrong?",
    "\"___ She Sweet\"",
    "\"___ No Woman,\" 1973 hit for the Four Tops",
    "\"___ Got No\" (\"Hair\" song)",
    "\"Politics ___ beanbag\" (Mr. Dooley maxim)",
    "With 48-Across, \"... and that ___!\"",
    "\"___ No Sunshine\" (1971 Grammy-winning song)",
    "Is not misused?",
    "\"___ Nobody\" (1983 Chaka Khan hit)",
    "\"___ it the truth!\"",
    "\"Is so!\" retort",
    "Isn't misused?",
    "Fats Waller's \"___ Misbehavin'\"",
    "\"___ Misbehavin'\"",
    "\"___ No Mountain High Enough\" (1970 #1 hit)",
    "Marvin Gaye's \"___ That Peculiar\"",
    "Is not on the street?",
    "\"You ___ kiddin'!\"",
    "Verb not in the king's English",
    "Is no more, informally",
    "It may get under a grammarian's skin",
    "\"___ it the truth!\"",
    "\"If it ___ broke ...\"",
    "Aretha Franklin's \"___ No Way\"",
    "\"___ it the truth!\"",
    "\"___ She Sweet?\"",
    "\"That ___ hay!\"",
    "\"Say it ___ so!\"",
    "\"___ it the truth!\"",
    "\"___ She Sweet\"",
    "\"It ___ Necessarily So\"",
    "Nonstandard contraction",
    "\"___ She Sweet?\"",
    "\"Is so!\" rebuttal",
    "Is not on the street?",
    "Isn't informal?",
    "\"___ We Got Fun?\"",
    "Grammarian's bugaboo",
    "\"___ Too Proud to Beg\" (1966 hit)",
    "Isn?Æt wrong?",
    "\"___ No Sunshine\" (1971 hit)",
    "Pat Boone's \"___ That a Shame\"",
    "\"___ it the truth!\"",
    "\"It ___ Necessarily So\"",
    "\"It ___ Necessarily So\"",
    "\"___ Misbehavin'\"",
    "\"___ Too Proud to Beg\" (1966 hit)",
    "Picnic pest, in this puzzle?",
    "Gershwin's \"It ___ Necessarily So\"",
    "\"It ___ pretty!\"",
    "Isn't wrong?",
    "\"___ That a Shame\" (#1 hit for 18-Across)",
    "\"___ Misbehavin'\"",
    "\"You ___ seen nothin' yet!\"",
    "1971 Grammy song \"___ No Sunshine\"",
    "\"___ That a Shame\" (1955 hit)",
    "Isn't on the street?",
    "\"___ That a Shame\"",
    "Aretha Franklin's \"___ No Way\"",
    "\"That___hay!\"",
    "Four Tops hit \"__No Woman\"",
    "\"___ it the truth?\"",
    "Grammarian's eyebrow raiser",
    "\"___ We Got Fun?\"",
    "\"Is so!\" rebuttal",
    "\"___ We Got Fun?\"",
    "\"I ___ Got Nobody\" (20's hit)",
    "Old song \"___ She Sweet?\"",
    "Temptations' \"___ Too Proud to Beg\""
  ],
  "oren": [
    "___ Ishii, Lucy Liu's character in \"Kill Bill\"",
    "___ Ishii, Lucy Liu's \"Kill Bill\" character",
    "Israeli conductor Daniel ___",
    "Opera conductor Daniel",
    "Israeli conductor Daniel",
    "___ Ishii (\"Kill Bill\" character)",
    "___ Ishii (\"Kill Bill\" character)",
    "Israeli conductor Daniel",
    "___ Ishii, character in \"Kill Bill\"",
    "Israeli opera conductor Daniel",
    "Opera conductor Daniel ___"
  ],
  "sogs": ["Soaks"],
  "ctrl": [
    "With 8-Down, part of some shortcuts",
    "Lead-in to P or C on a PC",
    "2017 double-platinum debut album for SZA",
    "___-X (cut)",
    "Abbr. before C, F, V or X",
    "PC combo key",
    "Bottom-left PC key",
    "___-V (\"paste\" on a PC)",
    "PC key",
    "___-Alt-Del",
    "Relative of Alt on a keyboard",
    "Part of many common shortcuts",
    "Keyboard key abbr.",
    "PC key",
    "PC key",
    "Shortening for shortcuts",
    "Computer key near Shift",
    "Lower-left keyboard key",
    "Corner key",
    "Key never used by itself",
    "PC key",
    "___-C",
    "___-Alt-Del",
    "PC key",
    "Bottom-row PC key",
    "Abbr. associated with certain shortcuts",
    "Laptop key"
  ],
  "rida": ["Rapper Flo ___", "Rap artist Flo ___"],
  "name": [
    "Receptionist's query",
    "___ of the game",
    "What every make of auto has",
    "Identify",
    "Word after pen or pet",
    "Partner of rank and serial number",
    "\"___ your price!\"",
    "\"You ___ it\"",
    "Reputation",
    "\"What's in a ___?\" (line from Juliet)",
    "See 51-Down",
    "Word with full or file",
    "Reputation",
    "First field in an online form, often",
    "Handle",
    "Big shot",
    "Luminary",
    "Identify",
    "Identify",
    "Arnold, Ronald or Roland",
    "Tag line?",
    "Reputation",
    "Subject for one studying onomastics",
    "\"Hello!\" sticker info",
    "Peter, Paul or Mary",
    "April, May or June",
    "Appoint to office",
    "You may drop a big one",
    "Tag ... or a word that can precede tag",
    "Big shot",
    "Moniker",
    "File directory heading",
    "Finger",
    "Attach a handle to",
    "Identify",
    "Kind of recognition",
    "Facebook entry",
    "Identify",
    "Questionnaire blank",
    "It may be assumed",
    "Specify",
    "Identify",
    "Pinpoint, say",
    "Dub",
    "\"The ___ Game\" (1965 Shirley Ellis hit)",
    "Handle",
    "Identify",
    "Identify",
    "ID",
    "It may be dropped",
    "It might be dropped",
    "You can make one for yourself",
    "Finger",
    "Big shot",
    "Partner of rank and serial number",
    "Handle",
    "Specify",
    "Designate",
    "Specify, in a way",
    "Title",
    "Well-known",
    "\"What's in a ___?\": Juliet",
    "Tom, Dick or Harry",
    "Big star",
    "Something to go by",
    "Tattle on",
    "Partner of rank and serial number",
    "Partner of rank and serial number",
    "Finger, in a way",
    "Appoint",
    "Epithet",
    "Celebrity",
    "With 50- and 53-Down, apt title for this puzzle",
    "Dogtag info",
    "Address preceder",
    "It's given to a newborn",
    "Somebody",
    "Celeb",
    "See 30-Across",
    "April or May",
    "Identify",
    "Be specific about",
    "Handle",
    "Specify",
    "Driver's license datum",
    "Reputation",
    "Peter, Paul or Mary",
    "Handle",
    "Christen",
    "Label",
    "Finger, so to speak",
    "Application form information",
    "Reputation",
    "Christen",
    "Identify",
    "Dub",
    "Specify",
    "Reputation",
    "Sobriquet",
    "Appoint",
    "\"Tag\"",
    "What Lucy Stone kept at her 1855 wedding",
    "Dub",
    "Moniker",
    "Word with pen or pet",
    "Big shot",
    "Put the finger on"
  ],
  "brat": [
    "It's just the wurst!",
    "One might be hard to sit for",
    "Bit of German street food, informally",
    "Angel's opposite",
    "Dennis the Menace, e.g.",
    "Cookout item, for short",
    "Unruly kid",
    "Angel's antithesis",
    "Army ___",
    "Seasoned pork sausage, informally",
    "Young miscreant",
    "Certain sausage, informally",
    "Babysitter's challenge",
    "Spoiled kid",
    "Sitter's headache",
    "Baby sitter's headache",
    "Certain pack member",
    "Misbehaver",
    "No angel",
    "Angel's antithesis",
    "Handful, maybe",
    "Imp",
    "Hardly a little angel",
    "Kid with frequent temper tantrums",
    "Spoiled kid",
    "Uncouth youth",
    "Grill option, for short",
    "Kid you might feel like smacking",
    "Little terror",
    "Teacher's handful",
    "Tart-tongued tyke",
    "Troublemaker",
    "Fresh kid",
    "Stink maker",
    "Sitter's headache",
    "Babysitter's headache",
    "See 53-Down",
    "No angel",
    "No angel",
    "Babysitter's bane",
    "Handful for a baby-sitter",
    "Child most likely to be spanked",
    "Handful, maybe",
    "Dennis the Menace, at times",
    "Baby sitter's nightmare",
    "Fresh kid",
    "Impish kid",
    "Tantrum thrower",
    "Troublemaker",
    "Enfant terrible",
    "Babysitter's bane",
    "Enfant terrible",
    "Sassy young 'un",
    "Little demon",
    "Prankster"
  ],
  "elly": [
    "Name that sounds like two of its letters",
    "\"For Better or for Worse\" mom",
    "Woman's name that sounds like its second and first letters, respectively",
    "___ May Clampett of old TV",
    "___ May of \"The Beverly Hillbillies\"",
    "___ May Clampett of 1960s TV",
    "\"For Better or for Worse\" matriarch",
    "___ May Clampett of \"The Beverly Hillbillies\"",
    "___ May Clampett of \"The Beverly Hillbillies\"",
    "___ May of \"The Beverly Hillbillies\"",
    "Soprano Ameling",
    "Mom in \"For Better or for Worse\"",
    "___ May Clampett of 60's TV",
    "60's TV's ___ May Clampett",
    "___ May of \"The Beverly Hillbillies\""
  ],
  "fuel": [
    "Stoke",
    "Gas or coal",
    "Logs, for a fire",
    "Sustain",
    "Oil or kerosene",
    "Gas, oil or coal",
    "Fill-up filler",
    "Fire's need",
    "Uranium or plutonium, for a nuclear reactor",
    "What an electric car doesn't need",
    "Precamping purchase",
    "Coke, for one",
    "Peat or propane",
    "Coal, e.g.",
    "Gasoline or peat",
    "Gasohol, e.g.",
    "It may be pumped",
    "Feed",
    "Gasohol, e.g.",
    "Natural gas, for one",
    "Gas or oil",
    "Petrol, e.g.",
    "Give power to",
    "Ammunition"
  ],
  "goth": [
    "Post-punk sort",
    "Dark kind of look",
    "One wearing black eyeliner and ripped jeans, say",
    "Sort of person heavily into eyeliner",
    "Roman Empire invader",
    "Invader of old Rome",
    "Person dressed in black",
    "One dressed in black, maybe",
    "Style with dark clothes and heavy eyeliner",
    "Postpunk movement",
    "Early Black Sea settler",
    "Certain typeface: Abbr.",
    "Ancient Germanic invader",
    "Invader of old Rome",
    "Ancient Rome conqueror",
    "Barbarian"
  ],
  "sect": [
    "Theravada or Mahayana vis-à-vis Buddhism",
    "Ones who've split",
    "It's broken off",
    "Order, of a sort",
    "Shakers or Quakers",
    "Sunni or Shia, in Islam",
    "Quakers, for one",
    "&sect;, in a legal footnote: Abbr.",
    "Religious group",
    "Result of a religious schism",
    "Religious offshoot",
    "Mennonites, e.g.",
    "Shakers, e.g.",
    "Result of a religious schism",
    "Religious offshoot",
    "Amish, e.g.",
    "Broken-off branch",
    "Yazidis, e.g.",
    "Splinter group",
    "Abbr. on a stadium ticket",
    "Lutheranism in 1521, e.g.",
    "Result of a split decision?",
    "Sunni or Shia",
    "Religious splinter group",
    "Breakaway group",
    "Ending with inter-",
    "Topic in a world religions course",
    "Shiites or Sunnis",
    "Splinter group",
    "Splinter group",
    "Religious faction",
    "Religious offshoot",
    "Group out of the mainstream",
    "Splinter group",
    "One may be Protestant",
    "Shiites or Sunnis",
    "Certain offshoot",
    "Zen Buddhists, e.g.",
    "Splinter group",
    "Church offshoot",
    "Friends, e.g.",
    "Zealots' group, maybe",
    "Small denomination",
    "Zen Buddhism, e.g.",
    "Religious offshoot",
    "Not a mainstream religion",
    "Religious offshoot",
    "Shia, e.g.",
    "It's split",
    "Splinter group",
    "Faction",
    "It's splintered",
    "Group of prayers",
    "Shia, e.g.",
    "Result of a schism",
    "Sunnis, e.g.",
    "Splinter group",
    "Part that's broken off",
    "Quakers or Shakers",
    "Sunnis, e.g.",
    "Shia, e.g.",
    "Broken-off branch",
    "Shi'ites, e.g.",
    "Shakers, e.g.",
    "Shiites or Amish",
    "Divergent doctrine developer",
    "Faction within a faith",
    "Religious offshoot",
    "Nazarenes, once",
    "The Amish, e.g.",
    "Broken-off branch",
    "Split-off group",
    "Religious group",
    "Splinter group",
    "Religious offshoot",
    "It's broken off",
    "Splinter group",
    "Small group of believers",
    "Religious offshoot",
    "Splinter group",
    "Religious offshoot",
    "Zealots, for one",
    "Minority group",
    "Faction",
    "Splinter group",
    "Spinoff group",
    "Small denomination",
    "Faction",
    "Faction",
    "Cainites, e.g.",
    "Schismatic group",
    "Small denomination",
    "Nazarenes, for one",
    "The Amish, e.g.",
    "Splinter group",
    "Branch",
    "Sunni, e.g.",
    "Faction",
    "Following",
    "Split-off group",
    "Religious splinter group",
    "Faction",
    "The shakers, e.g.",
    "Mennonites, e.g.",
    "Religious offshoot",
    "Denomination",
    "Splinter group",
    "Nonmainstream group",
    "Fringe group",
    "Pharisees, e.g.",
    "Branch",
    "Splinter group",
    "Religious offshoot",
    "Splinter group",
    "Branch Davidians, e.g.",
    "Church offshoot"
  ],
  "pigs": [
    "Gluttons",
    "Muppet trio the Oinker Sisters, e.g.",
    "In a ___ eye",
    "\"Little\" trio in kiddie lit",
    "Fare in \"blankets\"",
    "Pen pals?",
    "They want the most",
    "Litter producers, in two different senses",
    "Napoleon and Snowball, in \"Animal Farm\"",
    "Gluttons",
    "Not the daintiest of eaters",
    "Livestock",
    "Grunters",
    "Hardly neatniks",
    "Gluttons",
    "Pen pals?",
    "Sty inhabitants",
    "Sty residents",
    "Not the sort to share"
  ],
  "arty": [
    "Pretentious, in a way",
    "Affected, in a way",
    "Chichi",
    "Bohemian",
    "La-di-da",
    "Pseudocultured",
    "Pretentious",
    "Decidedly not lowbrow",
    "Bohemian",
    "Pretentious",
    "Hipsteresque, in a way",
    "Affected",
    "Like many modern black-and-white films",
    "Bohemian",
    "Bohemian",
    "Given to pretension",
    "Pretentiously showy",
    "Like many indie films",
    "Affectedly creative",
    "Pretentious",
    "Like many student films",
    "Pretentious",
    "Like many hipsters",
    "Painterish",
    "Affectedly cultured",
    "Pretentious",
    "Like many gallerygoers",
    "Hanging out in galleries, say",
    "Fancified, say",
    "Like many a gallerygoer",
    "Like many indies",
    "Chichi",
    "Pretentious",
    "Bohemian",
    "Pseudo-stylish",
    "Pseudo-cultured",
    "Pretentiously styled",
    "Pseudo-cultured",
    "Given to showy affectation",
    "Like many indie films",
    "Pretentious",
    "Like Manhattan's East Village",
    "Culturally showy",
    "Painterish",
    "Warholian",
    "Pseudo-cultured",
    "Pseudocultured",
    "Too-too",
    "Chichi",
    "Too stylish, maybe",
    "A bit pretentious",
    "Chichi",
    "Like some craft show displays",
    "Pretentious",
    "Having a goatee and beret, say",
    "Pseudocultured",
    "So SoHo",
    "Pretentious",
    "Creative",
    "Showing signs of culture",
    "Bohemian",
    "Chichi",
    "Bohemian",
    "Pretentious",
    "Like some SoHo parties",
    "Dilettantish",
    "Painterlike",
    "More than creative",
    "Like would-be bohemians",
    "Affected",
    "Painterish",
    "Pseudosophisticated",
    "So-o-o-o SoHo",
    "Dilettantish",
    "Aesthetically pretentious",
    "Too-too",
    "Like some SoHo shops",
    "Pseudo-aesthetic",
    "Pretentious",
    "Like some museumgoers",
    "Bohemian",
    "Pretentious",
    "Pseudo-esthetic",
    "Pretentious",
    "Pretentious",
    "Creative",
    "Dilettantish",
    "Bohemian",
    "Affectedly creative",
    "Pretentious",
    "Bohemian",
    "Pretentiously cultured",
    "Dilettantish",
    "Superficially cultured",
    "Pretentious",
    "Bohemian",
    "Too stylish, perhaps",
    "Chichi",
    "Pseudoesthetic",
    "Like some salon conversation"
  ],
  "fees": [
    "Requirements with some applications",
    "Retainers, e.g.",
    "Loads of investments?",
    "Charges",
    "Charges",
    "Bank charges",
    "Charges",
    "\"___ and restrictions may apply\"",
    "Charges",
    "Professional charges",
    "Finders' keepers?",
    "Charges",
    "Tuition and others",
    "Retainers, e.g.",
    "Bank list",
    "Charges from counsel",
    "They're always charged",
    "Airfare add-ons",
    "Schedule listings",
    "Honoraria",
    "Honoraria",
    "Professionals' earnings",
    "Lawyers' charges",
    "Users' costs",
    "Professional charges",
    "Loads of mutual funds",
    "Doctor's income",
    "Lawyers' charges",
    "Doctors' charges",
    "Lawyers' charges",
    "Payments to doctors",
    "Agent's income",
    "Payments",
    "Attorney's income",
    "Legal___",
    "Licensing ___"
  ],
  "envy": [
    "\"Our ___ always lasts longer than the happiness of those we ___\": Heraclitus",
    "\"That most odious and antisocial of all passions\": John Stuart Mill",
    "Sin often associated with green",
    "What the Joneses may elicit",
    "One of a well-known septet",
    "Sin associated with the color green",
    "\"Pain at another's good,\" per Plutarch",
    "\"Men always hate most what they ___ most\": H. L. Mencken",
    "Go green?",
    "One of the seven deadly sins",
    "\"___, like lightning, seeks the highest places\": Livy",
    "A deadly sin",
    "\"Green-eyed monster\"",
    "Green-eyed monster",
    "A deadly sin",
    "\"Pity is for the living, ___ is for the dead\": Twain",
    "Green-eyed monster",
    "\"As rust corrupts iron, so ___ corrupts man\": Antisthenes",
    "Green-eyed monster",
    "Part of a famous septet",
    "Green-eyed monster",
    "\"Men always hate most what they ___ most\": Mencken",
    "A deadly sin",
    "A deadly sin",
    "It's a sin",
    "\"___, like lightning, seeks the highest places\": Livy",
    "\"Men always hate most what they ___ most\": Mencken",
    "\"A kind of praise\": John Gay",
    "It \"slayeth the silly one\": Job",
    "Need to keep up with the Joneses",
    "A deadly sin",
    "Comparer's problem, maybe",
    "A deadly sin",
    "\"___ slayeth the silly one\": Job",
    "It's a sin",
    "Deadly desire",
    "One of 7-Down",
    "\"The tax which all distinction must pay\": Emerson",
    "Covet",
    "Begrudge",
    "Object of desire"
  ],
  "wont": [
    "Habit",
    "\"I ___ bite\"",
    "Usual practice",
    "Habit",
    "Practice",
    "Refuses to",
    "Custom",
    "\"I ___ tell\"",
    "The Who's \"___ Get Fooled Again\"",
    "Refuses to",
    "Custom",
    "Will's opposite",
    "Habit",
    "Custom",
    "Refuses to",
    "Refuses to",
    "Stubborn person's word",
    "Habit",
    "Refuses to",
    "Refuses",
    "\"I ___ tell!\"",
    "Custom",
    "Pattern of behavior",
    "\"I ___ Grow Up\" (\"Peter Pan\" song)",
    "Practice",
    "Habit",
    "Practice",
    "Custom",
    "Is obstinate"
  ],
  "gits": [
    "Skedaddles, cowboy-style",
    "Beats it, in rural lingo",
    "Skedaddles",
    "Skedaddles",
    "Beats it, out of the city",
    "Vamooses",
    "Moves it, informally",
    "Skedaddles",
    "Amscrays",
    "Skedaddles, in Dogpatch",
    "Vamooses",
    "Beats it, in dialect"
  ],
  "doll": [
    "Woody in \"Toy Story,\" e.g.",
    "Toy with tabs and interchangeable outfits",
    "Honeycakes",
    "Honey",
    "\"Guest\" at a child's tea party",
    "Barbie or Ken",
    "Filmdom's Chucky, for one",
    "Occupant of a small house",
    "Hon",
    "Sweets",
    "Sweetums",
    "Sweetheart",
    "Raggedy Ann, e.g.",
    "Toots",
    "Real looker",
    "Sweetie",
    "Barbie or Ken",
    "Barbie, e.g.",
    "Toots",
    "Barbie or Ken",
    "Honeybunch",
    "\"Mama\" sayer",
    "Sugar",
    "Honeybunch",
    "Honey",
    "Dress (up)",
    "\"Mama\" speaker",
    "Gussy (up)",
    "Barbie, for one",
    "Dreamboat",
    "\"Mama\" speaker",
    "Sweetie pie",
    "Sweetie pie",
    "Tot's tote",
    "Cabbage Patch Kid, e.g.",
    "G.I. Joe, basically",
    "Cutie",
    "Kewpie,. e.g.",
    "Kewpie",
    "Sweetheart",
    "Cabbage Patch item"
  ],
  "perk": [
    "Access to an expense account, perhaps",
    "Free snacks in the break room, e.g.",
    "Become more attentive, with \"up\"",
    "Employee's reserved parking space, for one",
    "Corner office, maybe",
    "Brighten (up)",
    "Become attentive, with \"up\"",
    "Corner office, maybe",
    "Private jet, maybe",
    "Brighten (up)",
    "Corner office, e.g.",
    "Company car or key to the executive bathroom",
    "Company car, maybe",
    "Private jet, e.g.",
    "Employee discount, e.g.",
    "Use of a corporate jet, say",
    "Fringe benefit",
    "Reserved parking space for an exec, maybe",
    "Fringe benefit",
    "Executive's extra",
    "Use of a company car, e.g.",
    "Free use of a company car, say",
    "Use of a company car or private washroom, say",
    "Bonus",
    "Liven (up)",
    "Extra",
    "Free gym membership, e.g.",
    "Extra",
    "Become lively, with \"up\"",
    "Benefit",
    "Fringe benefit",
    "Lift (up)",
    "Dental plan, maybe",
    "Enliven, with \"up\"",
    "Fringe benefit",
    "Executive extra",
    "Brighten, with \"up\"",
    "Job benefit",
    "Become attentive, with \"up\"",
    "Make 1-Down, e.g.",
    "Brew coffee",
    "Enliven, with \"up\""
  ],
  "mine": [
    "Quarry",
    "Go for the gold?",
    "\"Hands off!\"",
    "\"I call dibs!\"",
    "Get the lead out?",
    "Not yours",
    "\"Dibs!\"",
    "Word before and after \"all\"",
    "Hog's squeal?",
    "Place from which to withdraw deposits",
    "Word repeated in \"It's ___, all ___!\"",
    "\"You can't have that!\"",
    "Seven Dwarfs' workplace",
    "\"Gimme!\"",
    "\"Hands off!\"",
    "See 13-Down",
    "Toddler's assertion",
    "Where workers may get the shaft?",
    "Possible response to \"Whose is this?\"",
    "Miser's cry",
    "Greedy person's cry before and after \"all\"",
    "Selfish person's cry before and after \"all\"",
    "Go for the gold?",
    "Word of greed",
    "Tram locale",
    "Elevator locale",
    "Selfish cry before and after \"all\"",
    "Harbor danger",
    "Fielder's cry",
    "Cry from a selfish child",
    "Do groundbreaking work?",
    "Gold digger's destination",
    "Infielder's cry",
    "\"Sixteen Tons\" singer's workplace",
    "Grabber's cry",
    "Go for the gold",
    "Dig",
    "Go for the gold?",
    "Greedy cry",
    "Doubles partner?Æs call",
    "Sweeper's target?",
    "Submarine danger",
    "Greedy cry",
    "Get the gold?",
    "Harbor hazard",
    "Coal hole",
    "Outfielder's cry",
    "Greedy cry",
    "Outfielder's call",
    "Miser's pronoun",
    "Outfielder's cry",
    "Not yours or theirs",
    "Warship danger",
    "Coal site",
    "Greedy cry",
    "Sub sinker",
    "Destroyer destroyer",
    "Not yours",
    "Repeated cry in a children's argument",
    "Ore store",
    "It's full of shafts",
    "Word repeated in \"It's ___! All ___!\"",
    "Outfielder's cry",
    "Try to get the lead, maybe",
    "It gets the shaft",
    "Miser's pronoun",
    "Not yours",
    "Dig this!",
    "First word of \"The Battle Hymn of the Republic\"",
    "Where to go for the gold",
    "Colliery"
  ],
  "hood": [
    "Part of academic regalia",
    "Nabe",
    "Part of a parka",
    "Little Red Riding ___",
    "Cobra feature",
    "Part of a parka",
    "Sweatshirt part",
    "Car part called a bonnet in England",
    "Little Red Riding ___",
    "Home turf",
    "Engine cover",
    "Place for a stove light",
    "Part of the Grim Reaper's getup",
    "Tough",
    "Bit of attire at an initiation ceremony",
    "Place with homies",
    "What a mechanic works under",
    "Part of a parka",
    "Stove cover",
    "Part of Obi-Wan Kenobi's costume",
    "See 6-Down",
    "Thing to look under",
    "Punk",
    "Where to hang, in slang",
    "Ruffian",
    "Part of a parka",
    "Local area, slangily",
    "Where the boyz are",
    "Homeboy's turf",
    "Tough",
    "Where homeys hang",
    "Engine cover",
    "Homeboy's place",
    "Tough",
    "Cascades mount",
    "Where the boyz are",
    "Sweatshirt part, perhaps",
    "Tough guy",
    "Flynn portrayal",
    "Punk",
    "Cowl",
    "\"Boyz N the ___\" (1991 film)",
    "Ruffian"
  ],
  "imno": [
    "\"___ expert ...\"",
    "\"___ doctor, but ...\"",
    "Mae West's \"___ Angel\"",
    "\"___ expert, but ...\"",
    "\"___ expert, but ...\"",
    "\"___ fool ...\"",
    "\"___ expert, but ...\"",
    "\"___ expert, but ...\"",
    "Words before expert or fool",
    "\"___ expert, but ...\"",
    "\"___ expert, but ...\"",
    "\"___ expert, but ...\"",
    "\"___ fool ...\"",
    "\"___ Angel,\" 1933 film",
    "\"___ Angel\" (Mae West movie)",
    "\"___ expert, but ?à\"",
    "\"___ Angel\" (1933 comedy)",
    "Mae West's \"___ Angel\"",
    "\"___ expert, but ...\"",
    "\"___ expert, but ...\"",
    "\"___ expert, but ...\"",
    "\"___ Angel\" (Mae West film)",
    "\"___ Angel\" (Mae West film)",
    "Mae West's \"___ Angel\""
  ],
  "vlog": [
    "YouTube journal posting",
    "Certain YouTube posting",
    "YouTube journal",
    "Certain YouTube posting",
    "Many a YouTube upload",
    "Journal on YouTube, maybe"
  ],
  "foci": [
    "Points of interest",
    "A hyperbola has two",
    "Points of convergence",
    "Hubs",
    "Centers of attention",
    "What an ellipse's major axis passes through",
    "Centers of attention",
    "Two key points on an ellipse's major axis",
    "An ellipse has two",
    "Central points",
    "Centers of circles",
    "Pair in an ellipse",
    "Centers of attention",
    "A hyperbola has two",
    "Meeting points",
    "Places where lines meet",
    "Points that may have rays",
    "Meeting points",
    "An ellipse has two of them",
    "Central points",
    "Earthquakes' points of origin",
    "Convergence points",
    "Origins of earthquakes",
    "Central points",
    "Points of convergence",
    "Geometry points"
  ],
  "warm": [
    "Getting close, in a guessing game",
    "Getting close, perhaps",
    "Friendly",
    "Getting close",
    "In the 70s, say",
    "Getting close, in a guessing game",
    "Toasty",
    "Close, in a guessing game",
    "Quite cordial",
    "Close, in a guessing game",
    "Toasty",
    "Tune (up)",
    "Toasty",
    "\"Getting close\"",
    "\"Close\"",
    "In the 70's or so",
    "Cordial",
    "In the 80's, temperaturewise",
    "Friendly",
    "Welcoming",
    "Friendly",
    "Devoted, as friends",
    "\"Close\"",
    "Toasty",
    "Friendly",
    "Toasty",
    "Getting close",
    "Toasty",
    "Cordial"
  ],
  "emts": [
    "Some first responders, in brief",
    "Lifesavers, for short",
    "CPR experts",
    "First responders, for short",
    "Some workers on standby, for short",
    "Ambulance workers, in brief",
    "Some volunteers at music festivals, for short",
    "CPR specialists",
    "Revivalists, for short?",
    "CPR experts",
    "Experts in 41-Across",
    "Defibrillator pros",
    "CPR givers",
    "Some legal speeders, briefly",
    "Crew at a big accident",
    "Ambulance crew, for short",
    "Grp. with a saving plan?",
    "Revivalists, for short?",
    "They may respond for the unresponsive, for short",
    "CPR experts",
    "Revival V.I.P.s, perhaps",
    "They might work at a revival, for short",
    "Ambulance workers, for short",
    "Accident figures, for short",
    "Producers of many revivals, for short",
    "Trauma experts, briefly",
    "Lifesavers, briefly",
    "Triage pros",
    "Some N.F.L. workers",
    "Some paddle wielders, briefly",
    "Defib team",
    "Drs. often take over for them",
    "CPR experts",
    "Crew at a pileup",
    "First responders, for short",
    "911 V.I.P.'s",
    "CPR pros",
    "Pack of lifesavers?",
    "First-aid experts, briefly",
    "Ones trying to increase circulation, for short?",
    "Ones dispatched by 911 calls",
    "CPR experts",
    "Figures at a pileup",
    "CPR experts",
    "Rescue crew, briefly",
    "Rescue team, briefly",
    "Lifesavers, for short",
    "Experts at CPR",
    "Defibrillator users, for short",
    "911 responders",
    "CPR experts",
    "CPR pros",
    "Many are trained in childbirth: Abbr.",
    "Ones called to an accident, briefly",
    "CPR deliverers",
    "Legal speeders, for short",
    "911 responders, for short",
    "Trauma team: Abbr.",
    "Some lifesavers",
    "CPR administrators",
    "911 responders: Abbr.",
    "Important people on the scene, for short",
    "Ambulance grp.",
    "CPR pros",
    "Life-saving team",
    "CPR pros",
    "Stretcher carriers, briefly",
    "C.P.R. givers",
    "People who do chest-thumping, for short",
    "Ambulance staffers: Abbr.",
    "Ambulance personnel, for short",
    "\"Chicago Hope\" extras, familiarly",
    "CPR pros",
    "911 responders: Abbr."
  ],
  "cute": [
    "Like many baby animals",
    "\"Don't get ___!\"",
    "Like koalas and pandas",
    "Like cat videos, typically",
    "Like puppies and kittens",
    "Smart-alecky",
    "Warranting a heart on Instagram, say",
    "Reply to a bit of cleverness",
    "Overclever",
    "Darling",
    "Cherubic",
    "Like many a teen idol",
    "Buttonlike?",
    "Like a baby with dimples, say",
    "Just adorable",
    "Puppyish",
    "Adorable",
    "Like puppies",
    "Precious",
    "Puppylike",
    "Babyish",
    "Adorable",
    "Baby-faced",
    "Baby-faced",
    "Like a button",
    "Buttonlike?",
    "Appealing",
    "Baby-faced",
    "Baby-faced",
    "Baby-faced",
    "Clever",
    "Precious"
  ],
  "soon": [
    "In a moment",
    "Procrastinator's promise",
    "Any day now",
    "Any minute now",
    "\"Just be patient\"",
    "\"So ___?\"",
    "Response to \"Are we there yet?\"",
    "In a jiffy",
    "\"Coming ___\" (store sign)",
    "Any minute",
    "Procrastinator's promise",
    "Procrastinator's promise",
    "Any moment",
    "By and by",
    "Procrastinator's favorite word",
    "Any day now",
    "Any second now",
    "Shortly",
    "___-to-be",
    "Anon",
    "The \"cetera\" of \"et cetera\"",
    "Vague response to \"When?\"",
    "In a day, say",
    "Frequent answer to \"When?\"",
    "In a day, say",
    "\"Not much longer\"",
    "Before you know it",
    "Any minute now",
    "Procrastinator's response",
    "Before long",
    "In the near future",
    "In short order",
    "\"So ___?\"",
    "Shortly",
    "In a jiffy",
    "\"Any day now\"",
    "Expeditiously",
    "By and by",
    "Response to an impatient person",
    "Sometime today, say",
    "Shortly",
    "\"Any day now\"",
    "Procrastinator's promise",
    "Sometime today, say",
    "In next to no time",
    "Within the hour",
    "In a bit",
    "By and by",
    "Any day now",
    "In a minute",
    "Just minutes from now",
    "\"Any day now ...\"",
    "Quickly",
    "In no time",
    "Possible answer to \"When?\"",
    "By and by",
    "Presently",
    "Erelong",
    "Presently",
    "Before you know it",
    "\"In a minute\"",
    "Any day now",
    "In a minute",
    "Procrastinator's promise",
    "In a bit",
    "Speedily",
    "Word in a promise",
    "Shortly",
    "Anon",
    "Before you know it",
    "1935 Rodgers and Hart song",
    "Gershwin song of 1930",
    "Comforting reply to \"When?\"",
    "Directly",
    "Any minute",
    "In a moment",
    "By and by",
    "Promise word",
    "In a mo"
  ],
  "hunh": [
    "\"Say what?\"",
    "\"Come again?\"",
    "\"What the ...?\"",
    "\"Whadja say?\""
  ],
  "stun": [
    "Bowl over",
    "Shock",
    "Knock for a loop",
    "Wow, just wow",
    "Leave agape",
    "Boggle the mind",
    "Phaser setting",
    "Knock for a loop",
    "Amaze",
    "Daft / Daze",
    "Flabbergast",
    "Dumbfound",
    "Bring to a standstill, say",
    "Phaser setting",
    "Zap, in a way",
    "Floor",
    "Leave thunderstruck",
    "Sci-fi phaser setting",
    "Floor",
    "Sci-fi weapon setting",
    "Dumbfound",
    "Stupefy",
    "Floor",
    "Floor",
    "Amaze",
    "Phaser setting",
    "Rock",
    "Use a Taser on",
    "Taser, say",
    "Leave thunderstruck",
    "Render open-mouthed",
    "Gobsmack",
    "\"Set phasers to ___\"",
    "Bedazzle",
    "Stupefy",
    "Stagger",
    "Floor",
    "Floor",
    "Gobsmack",
    "Overwhelm",
    "Knock out",
    "Phaser setting",
    "Zap with a Taser",
    "Render speechless",
    "Make jaws drop",
    "Phaser setting",
    "Leave open-mouthed",
    "Knock for a loop",
    "Send reeling",
    "Surprise greatly",
    "Flabbergast",
    "Leave slack-jawed",
    "Cold-cock",
    "Shock",
    "\"Star Trek\" weapon setting",
    "Leave speechless",
    "Phaser setting",
    "Knock for a loop",
    "Phaser setting",
    "Surprise greatly",
    "Zap",
    "Bowl over",
    "Phaser setting, on \"Star Trek\"",
    "Blow out of the water",
    "Phaser setting",
    "Leave slack-jawed",
    "Overpower",
    "Knock for a loop",
    "Flabbergast",
    "Leave speechless",
    "Flabbergast",
    "Bowl over",
    "Wow",
    "Sandbag",
    "Bowl over",
    "Take aback",
    "Leave speechless",
    "Bowl over",
    "Flabbergast",
    "Phaser setting",
    "Bowl over",
    "Jar",
    "Zap",
    "Flabbergast",
    "Flabbergast",
    "Flabbergast",
    "Jar",
    "Phaser setting",
    "Floor",
    "Render speechless",
    "Stupefy",
    "Stupefy",
    "Kind of gun",
    "Kind of gun",
    "Daze",
    "Bowl over",
    "Shock",
    "Bowl over",
    "Throw for a loop",
    "Bedaze",
    "Stagger",
    "Knock for a loop",
    "Astound",
    "Daze"
  ],
  "weed": [
    "Marijuana",
    "Ganja",
    "Do some yardwork",
    "Goldenrod, e.g.",
    "Sort (through)",
    "Gardener's bane",
    "Something you don't want in the bed",
    "Dandelion, for one",
    "Wacky tobacky, in part",
    "Gardener's bane",
    "Dandelion, e.g.",
    "Unwanted garden growth",
    "Do some yard work",
    "Do some yard work",
    "Pull up dandelions and crab grass",
    "Herbicide target",
    "Marijuana, informally",
    "Do some gardening",
    "Goldenrod, e.g.",
    "Whacked plant",
    "Thistle or goldenrod",
    "Unwanted 36-Across",
    "Marijuana, slangily",
    "Dandelion, e.g.",
    "Work in the garden",
    "Dandelion or goldenrod, e.g.",
    "Thistle, e.g.",
    "Marijuana",
    "Lawn intruder",
    "Garden invader",
    "Garden intruder",
    "Work in the garden",
    "Narc's target",
    "Garden intruder",
    "Crab grass, e.g.",
    "Cig",
    "Nut sedge, e.g.",
    "It's often rooted out",
    "Herbicide target",
    "Herbicide's target",
    "Garden crasher",
    "Gardener's problem",
    "Lawn eyesore",
    "Herbicide target",
    "Dandelion, e.g.",
    "Tobacco, slangily",
    "Pull dandelions, e.g.",
    "Unwelcome growth"
  ],
  "earl": [
    "\"Downton Abbey\" title",
    "___ Simmons, real name of the late rapper DMX",
    "Title on \"Downton Abbey\"",
    "British noble",
    "Actor James ___ Jones",
    "Jazz pianist Hines",
    "Third rank of the peerage",
    "Noble sort",
    "Noble rank",
    "Grey's title, in the tea aisle",
    "Viscount's superior",
    "Title below marquess",
    "\"Downton Abbey\" title",
    "___ Grey tea",
    "Rank below marquis",
    "17th ___ of Oxford, author of Shakespeare's plays, by some accounts",
    "Tupper of Tupperware fame",
    "___ of Sandwich",
    "British title holder",
    "___ Grey tea",
    "Marquis's subordinate",
    "Rank between viscount and marquess",
    "Lady's man",
    "Countess's man",
    "Rank below marquis",
    "Title on \"Downton Abbey\"",
    "Overseas court figure",
    "Viscount's superior",
    "Rank below marquis",
    "Peer group member?",
    "___ of Sandwich",
    "English title",
    "Court figure",
    "Rank above viscount",
    "Scruggs on a banjo",
    "Warren of the Warren Commission",
    "British nobleman",
    "Authority over sheriffs in England",
    "N.B.A. Hall-of-Famer Monroe",
    "___ of Sandwich",
    "___ of Sandwich",
    "Viscount's superior",
    "Marquis's inferior",
    "Jimmy Carter's middle name",
    "___ of Warwick (War of the Roses figure)",
    "Monroe of the N.B.A.",
    "One going to court?",
    "Rank below a marquis",
    "Middling noble rank",
    "___ of Sandwich",
    "Title below marquis",
    "Marquess's subordinate",
    "___ of Sandwich",
    "Sandwich man?",
    "Robin Hood, the ___ of Huntington",
    "N.B.A. legend Monroe with a signature spin move",
    "Broadway columnist Wilson",
    "Banjoist Scruggs",
    "Husband of a countess",
    "Warren of the Supreme Court",
    "Countess's husband",
    "___ of Sandwich",
    "Banjo picker Scruggs",
    "Warren of the Supreme Court",
    "Sandwich rank",
    "Banjo-picker Scruggs",
    "___ of Oxford",
    "Countess's husband",
    "Husband of a countess",
    "Count, now",
    "___ Grey",
    "NBC's \"My Name Is ___\"",
    "Rank above viscount",
    "___ of Sandwich",
    "Tupper of Tupperware fame",
    "Certain peer",
    "British nobleman",
    "___ of Essex",
    "___ of Sandwich",
    "Nobleman",
    "___ of Salisbury",
    "___ of Sandwich",
    "Count's counterpart",
    "___ of Avon",
    "Banjo-plucking Scruggs",
    "Sandwich man?",
    "Peer",
    "Chief Justice ___ Warren, majority opinion writer for 19- and 49-Across",
    "Benjamin Disraeli, e.g.",
    "Counterpart of a count",
    "Jazz pianist Hines",
    "Anthony Eden, e.g.",
    "___ Grey tea",
    "Banjoist Scruggs",
    "Nobleman",
    "A peer",
    "Disraeli was one",
    "Certain estate owner",
    "Rank above viscount",
    "Countess's mate",
    "Anthony Eden, for one",
    "British title",
    "Viscount's superior",
    "Equivalent of a European count",
    "James ___ Carter",
    "Person at court",
    "\"Duke of ___\" (1962 hit)",
    "N.B.A. Hall-of-Famer Monroe",
    "Eden was one",
    "Eden, for one",
    "Contemporary count",
    "Countess's husband",
    "___ of Sandwich",
    "British peer",
    "Benjamin Disraeli, e.g.",
    "___ of Sandwich",
    "___ of Sandwich",
    "Title for Robert Walpole",
    "Title meaning \"chieftain\"",
    "Marquis's inferior",
    "Man addressed as \"My Lord\"",
    "Rank below marquis",
    "British blue blood",
    "British blueblood",
    "Hereditary title",
    "Count, now",
    "Suffolk, for one, in Shakespeare",
    "Lady's man",
    "Viscount's superior",
    "Sandwich man?",
    "English estate owner, maybe",
    "Shakespeare's ___ of Salisbury",
    "Nobleman",
    "Lady's man",
    "With 6-Across, tea type",
    "___ Spencer, brother of Princess Diana",
    "Nobleman",
    "Noble",
    "Knicks great Monroe",
    "Rank below marquis",
    "P.B.A. Hall-of-Famer Anthony",
    "Jimmy Carter's middle name",
    "Subject of peer pressure?",
    "One addressed as \"my lord\"",
    "___of Suffolk",
    "Gentleman's title",
    "Count, in England",
    "Rank between viscount and marquis",
    "James ___ Carter",
    "Marquis's inferior",
    "Carter's middle name",
    "___ marshal (British officer)",
    "Campbell of the N.F.L.",
    "Actor James ___ Jones",
    "Jazzman Hines",
    "Carter's middle name",
    "British title",
    "Manor head, maybe",
    "Scruggs of bluegrass",
    "Man with a title",
    "Travel writer Thollander"
  ],
  "busy": [
    "Occupied with many things",
    "Packed",
    "On another call",
    "Ornate",
    "Tied up, as a phone line",
    "Ornate",
    "Tied up",
    "At it",
    "Like the proverbial beaver",
    "On the go",
    "Like a madras pattern",
    "In use",
    "Toiling away",
    "Like some signals",
    "Not free",
    "Overdecorated"
  ],
  "jeep": [
    "Vehicle in \"M*A*S*H\"",
    "Wrangler, for one",
    "Way off base?",
    "Private transportation?",
    "Wrangler, for one",
    "\"Go anywhere, do anything\" sloganeer",
    "Grand Cherokee, e.g.",
    "Major mode of transportation?",
    "Ride for Hawkeye or Radar",
    "Cherokee, for one",
    "Humvee forerunner",
    "Army transport",
    "Versatile vehicle",
    "Way off base?",
    "Rugged transportation",
    "Base transportation",
    "Cherokee maker",
    "Transport for rough terrain",
    "Army wheels",
    "Military vehicle",
    "Cherokee, for one",
    "Wrangler, e.g.",
    "Vehicle since 1940"
  ],
  "spud": [
    "Potato, informally",
    "Tater",
    "Tater",
    "Tater",
    "Bubble and squeak ingredient, slangily",
    "Source of fries",
    "Tater",
    "Potato, informally",
    "Tater",
    "Tater",
    "Chips, initially",
    "Source of chips",
    "Pre-fries?",
    "Source of some carbs",
    "Tater",
    "25-Down, informally",
    "It has eyes that can't see",
    "Tater",
    "One with eyes for a cook?",
    "Peeler's target, informally",
    "Potato, informally",
    "K.P. unit",
    "Tater",
    "Common tater",
    "Tater",
    "Tater",
    "Starch source, informally",
    "Starchy veggie",
    "Idaho, informally",
    "An Idahoan might hoe one",
    "Tater",
    "Tater",
    "Potato",
    "Tater",
    "Tater",
    "Tater",
    "Tater",
    "Common tater",
    "Tater",
    "Tater"
  ],
  "goer": [
    "Ending with church or party",
    "Ending with church or party",
    "Attendee",
    "Skipper's opposite",
    "Ending with church or movie",
    "Attendee",
    "Attendee",
    "Convention conclusion?",
    "Church annex?",
    "Suffix with party",
    "It may follow convention",
    "Attendee",
    "It may follow convention",
    "Suffix with movie",
    "Suffix with theater",
    "Suffix with theater",
    "One who's always moving",
    "Attendee",
    "Attendee",
    "Attendee",
    "Attendee",
    "Traveler",
    "One on the move",
    "Attendee",
    "Jet-set sort",
    "No stay-at-home",
    "Attendee",
    "Traveling type"
  ],
  "laze": [
    "Lounge around",
    "Do nothing",
    "Do nothing",
    "Chill out",
    "Loaf (around)",
    "Unwind",
    "Loll",
    "Veg out",
    "Take it easy",
    "Lie about",
    "Not work at all",
    "Lie in a hammock all afternoon, e.g.",
    "Be a couch potato",
    "Not do one darn thing",
    "Kick back",
    "What many do on a day off",
    "Loll",
    "Spend an afternoon in a hammock, e.g.",
    "Be a couch potato",
    "Spend half the afternoon in a hammock, e.g.",
    "Goof off",
    "Veg out",
    "Loll",
    "Do little",
    "Loll",
    "Lounge",
    "Take it easy",
    "Lounge",
    "Take it easy",
    "Loaf",
    "Relax",
    "While away the time",
    "Lounge",
    "Take it easy",
    "Take it easy",
    "Use a hammock",
    "Loll about",
    "Loaf"
  ],
  "blah": [
    "Boring",
    "Yawn-inducing",
    "Bo-o-oring",
    "Meh-feeling",
    "Lackluster",
    "Bo-o-oring",
    "Uninspired",
    "Low on the excite-o-meter",
    "When tripled, \"and so on and so forth\"",
    "Utterly uninspiring",
    "Humdrum",
    "Ho-hum",
    "Eliciting a \"meh\"",
    "When tripled, et cetera",
    "Boring",
    "Yawn-inducing",
    "Opposite of exciting",
    "Not so hot",
    "Meh",
    "Unexciting",
    "Ho-hum",
    "Unexciting",
    "Shrug-worthy",
    "Lacking pizazz",
    "Unexciting",
    "Snooze-inducing",
    "Bor-r-ring",
    "Uninspiring",
    "Eliciting a \"So what?\"",
    "Unexciting",
    "Boring",
    "Dull",
    "Lackluster",
    "Ennui-inducing",
    "Dull",
    "Not piquant",
    "Not so hot",
    "Eliciting a \"So what?\"",
    "Totally unexciting",
    "\"Been there, done that\" feeling",
    "Lacking pizazz",
    "Ho-hum",
    "When tripled, et cetera",
    "Unexciting",
    "Nondescript",
    "Ho-hum",
    "Dull",
    "Insipid",
    "Ho-hum",
    "Unexciting",
    "Dry-as-dust",
    "Prosaic",
    "Hardly thrilling",
    "Mediocre",
    "Hardly spine-tingling",
    "Bor-r-ring",
    "Ho-hum",
    "Dullsville",
    "Hardly exciting",
    "Punk",
    "Dry-as-dust",
    "Insipid",
    "Not piquant",
    "Humdrum",
    "Sans verve"
  ],
  "poem": [
    "Sonnet or ode",
    "A short one by Ogden Nash reads \"Parsley / is gharsley\"",
    "Muhammad Ali's \"Me! Whee!,\" e.g.",
    "It's measured in both feet and meters",
    "Amanda Gorman's \"The Hill We Climb,\" for one",
    "Ode or sonnet",
    "It may be measured in feet",
    "One adorns the Statue of Liberty",
    "Haiku, for one",
    "Many a hymn, essentially",
    "Greeting card text, often",
    "Work with feet?",
    "Work by Wordsworth or Whitman",
    "Burns writing",
    "\"The Star-Spangled Banner,\" basically",
    "It \"should not mean / But be,\" per Archibald MacLeish",
    "\"A ___ should not mean / But be\": Archibald MacLeish",
    "Whitman sampler?",
    "Offering in The New Yorker",
    "46-Down, for one",
    "Gray piece",
    "Something to scan",
    "It's never finished, only abandoned, per Paul Valery",
    "Scanning work, often",
    "It has feet in a line",
    "Limerick or sonnet",
    "It may be measured by a meter",
    "Ditty, e.g.",
    "Masters piece",
    "Rhapsody, e.g.",
    "Gray lines",
    "\"Jabberwocky,\" for one",
    "\"Odyssey,\" for one",
    "Prior work",
    "Frost lines",
    "Ode or haiku",
    "Frost lines",
    "Work with feet",
    "Stressful work?",
    "Hallmark card text, often",
    "Feature of many a sympathy card",
    "Pope's work",
    "Pound piece",
    "Work with feet",
    "Elegy, e.g.",
    "\"A Dream Within a Dream,\" e.g.",
    "Robert Frost piece",
    "Lay",
    "Limerick, e.g.",
    "Ode or haiku",
    "\"A ___ should not mean / But be\": MacLeish",
    "Burns writing",
    "\"Jabberwocky,\" for one",
    "Collection of staves",
    "Greeting card feature, often",
    "Robert Frost writing",
    "Field work",
    "It may scan",
    "Work with feet",
    "Sonnet, e.g.",
    "Fancy foot work?",
    "Lay, e.g.",
    "Limerick, e.g.",
    "It has many feet",
    "Haiku, e.g."
  ],
  "oath": [
    "It's a crime to lie under it",
    "\"I will prevent disease whenever I can, for prevention is preferable to cure,\" e.g.",
    "One might speak under it",
    "\"Solemn\" words",
    "\"I do solemnly swear ...,\" e.g.",
    "Solemn affirmation",
    "Curse",
    "\"I do solemnly swear ...,\" e.g.",
    "Four-letter word for a four-letter word",
    "Swear words",
    "Words at a swearing-in ceremony",
    "\"I do solemnly swear ...,\" e.g.",
    "Statement often starting \"I ...\"",
    "Solemn vow",
    "Bit of salty language",
    "Curse word",
    "Inauguration recitation",
    "Vow",
    "Curse",
    "___ of office",
    "Hippocratic ___",
    "It might be bleeped",
    "Formal guarantee",
    "Promise",
    "Inauguration recitation",
    "Words from the witness stand",
    "Naturalization requirement",
    "Vow",
    "Swearing-in statement",
    "Darn, e.g.",
    "Swear words?",
    "\"I do solemnly swear ...,\" e.g.",
    "Curse",
    "Something sworn",
    "Something taken on the stand",
    "It can be a curse",
    "Inauguration recitation",
    "Something taken by a scout",
    "Judicial administration?",
    "Court recitation",
    "\"I do solemnly swear ... ,\" e.g.",
    "Inaugural feature",
    "Inauguration Day highlight",
    "\"Ye gods!,\" for one",
    "Inauguration Day words",
    "Words of commitment",
    "It's taken in court",
    "Inauguration recital",
    "Courtroom recitation",
    "Words to live by",
    "Bleeped word",
    "Four-letter word, aptly",
    "___ of office",
    "Gadzooks, e.g.",
    "Courtroom affirmation",
    "Pledge",
    "Swear words",
    "Word of honor",
    "Quadrennial White House administration",
    "Many an office has one",
    "It's sworn at a swearing-in",
    "Inauguration highlight",
    "It follows \"Repeat after me\"",
    "Subject for a censor",
    "Courtroom pledge",
    "Dangerous thing to lie under",
    "Something to bleep, maybe",
    "Swear words",
    "___ of office",
    "Promising words",
    "\"@#$%!,\" e.g.",
    "Inauguration Day recital",
    "Bleeper's target",
    "Courtroom affirmation",
    "It may be bleeped",
    "Pledge",
    "\"@#$%!,\" e.g.",
    "See 68-Across",
    "\"I do\" or \"Drat!\"",
    "It's said with a raised hand",
    "103-Down, mildly",
    "Words of commitment",
    "Swearing-in words",
    "Swear words?",
    "Colorful language",
    "Many an office has one",
    "Blast, for instance",
    "Curse",
    "Gadzooks, for one",
    "Inauguration declaration",
    "Swearing-in words",
    "It may be minced",
    "\"Holy smoke!,\" e.g.",
    "It might be bleeped out",
    "Presidential administration",
    "Many an office has one",
    "Solemn promise",
    "It may be minced",
    "Four-letter word",
    "Curse",
    "Sworn word",
    "Witness stand statement",
    "Vow",
    "Scout recitation",
    "Swear word",
    "Swear words",
    "Words to live by",
    "Hippocratic___",
    "\"Gadzooks,\" e.g.",
    "Pledge",
    "Curse",
    "___ of office",
    "\"Drat!\" is a mild one",
    "Courtroom ritual",
    "Vow",
    "___ of office",
    "\"I do\" preceder",
    "Swearword"
  ],
  "ohok": [
    "\"I get it now\"",
    "\"That makes sense now\"",
    "\"Sure, I guess\"",
    "\"Ah, now I see\"",
    "\"Um, sure\"",
    "\"Well, all right then\"",
    "\"Well, I guess\"",
    "\"Um, sure\"",
    "\"Hmm, gotcha\"",
    "\"Now I get it\""
  ],
  "sups": [
    "Has an evening meal",
    "Dines",
    "Takes evening courses?",
    "Dines",
    "Has dinner",
    "Dines",
    "Dines",
    "Doesn't merely snack",
    "Takes night courses?",
    "Takes evening courses?",
    "Takes night courses?",
    "More than noshes",
    "Dines",
    "Dines",
    "Eats",
    "Dines",
    "Banquets",
    "Banquets",
    "Banquets",
    "Has a late meal",
    "Takes some courses?",
    "Takes courses?",
    "Has dinner"
  ],
  "sobs": [
    "Weeps loudly",
    "Bawls",
    "Wails",
    "Doesn't just whimper",
    "Doesn't just tear up",
    "Blubbers",
    "Breaks down",
    "Sp-[gasp]-speaks like th-[sniffle]-this",
    "Doesn't just tear up",
    "Breaks down",
    "Cries a river",
    "Doesn't just tear up",
    "Cries out loud",
    "Displays disconsolation",
    "Bawls",
    "They're good for crying out loud",
    "Breaks down",
    "Cries out loud",
    "Doesn't just tear up",
    "Shows disconsolateness",
    "Lamentations",
    "Cries convulsively",
    "Is visibly miserable",
    "Blubbers",
    "Bawls",
    "Boohoos",
    "Sounds after a tragedy",
    "Wails",
    "They come in fits",
    "Sounds of sorrow",
    "Breaks down",
    "Sad sounds",
    "Wails",
    "Doesn't just tear up",
    "Weeps uncontrollably",
    "Sounds of unhappiness",
    "Breaks down, in a way",
    "So-and-sos",
    "Has a bawl",
    "Is grief-stricken"
  ],
  "vary": [
    "Change into different forms",
    "Not stay the same",
    "Be inconsistent",
    "\"Your mileage may ___\"",
    "\"Results may ___\"",
    "Fluctuate",
    "Fluctuate",
    "Results may do this, in commercials",
    "Diversify",
    "Modulate",
    "Modulate",
    "Results may do this",
    "Differentiate",
    "Be different",
    "Diversify",
    "Deviate",
    "Diversify",
    "\"Prices ___\"",
    "Shift",
    "Fluctuate"
  ],
  "zeta": [
    "Actress Catherine ___-Jones",
    "Letter before 19-Across",
    "Sixth of 24",
    "Letter that, surprisingly, is not the end of the Greek alphabet",
    "Sorority letter",
    "It follows epsilon",
    "Epsilon follower",
    "Greek \"Z\"",
    "Riemann ___ function",
    "Sixth in a series",
    "Z abroad",
    "Fraternity letter",
    "Sixth in a series",
    "Actress Catherine ___-Jones",
    "Sorority letter",
    "Certain sorority woman",
    "Epsilon follower",
    "Epsilon follower",
    "Sixth in a pledge's recitation",
    "Sorority girl",
    "Letter after epsilon",
    "Letter that's not really at the end of the Greek alphabet",
    "Certain sorority member",
    "Certain sorority chapter",
    "Sixth-brightest star in a constellation",
    "Second after delta",
    "Letter after epsilon",
    "Sweater letter",
    "Sixth Greek letter",
    "Greek's sixth",
    "Actress Catherine ___-Jones",
    "Sixth-brightest star",
    "Epsilon follower",
    "Letter after epsilon",
    "Constellation's sixth brightest star",
    "Sorority letter",
    "Fraternity letter",
    "Epsilon follower",
    "Epsilon's follower",
    "Epsilon follower",
    "Epsilon follower",
    "Epsilon follower"
  ],
  "reba": [
    "Singer McEntire",
    "Mail lady on \"Pee-wee's Playhouse\"",
    "Singer McEntire",
    "Early 2000s sitcom set near Houston",
    "McEntire of country music",
    "Platinum-certified country album of 1988",
    "Singer McEntire",
    "Bygone sitcom set around Houston",
    "McEntire known as \"The Queen of Country\"",
    "Country's McEntire",
    "McEntire with a twang in her voice",
    "Singer McEntire",
    "Singer McEntire",
    "First name in country",
    "Onetime CW sitcom",
    "Country's McEntire",
    "1988 chart-topping country album",
    "First name in country music",
    "McEntire at the Grand Ole Opry",
    "Country's McEntire",
    "2000s sitcom starring a country singer",
    "Singer McEntire",
    "Eponymous sitcom star of the 2000s",
    "Country name",
    "McEntire of country",
    "Single mom in a 2000s sitcom",
    "Sitcom mom of Cheyenne and Kyra",
    "\"___ #1's\" (2005 country music album)",
    "Former sitcom featuring a #1 singer",
    "\"I'm a Survivor\" sitcom",
    "Single-mom sitcom of the 2000s",
    "Singer McEntire",
    "Big name in country",
    "Musical McEntire",
    "\"Pee-wee's Playhouse\" mail lady",
    "Country's McEntire",
    "First name in country",
    "Old WB sitcom",
    "1988 #1 country album",
    "Sitcom with the character B.J.",
    "Singer McEntire",
    "Singer McEntire",
    "Sitcom with the character B.J.",
    "McEntire of country music",
    "Singer McEntire",
    "Singer McEntire",
    "1988 chart-topping country album",
    "Country's McEntire",
    "Sitcom set in Houston",
    "Hart family sitcom",
    "McEntire of country and western",
    "Country's McEntire",
    "Singer McEntire",
    "Hart family sitcom",
    "Country singer McEntire",
    "Sitcom about a Texas soccer mom",
    "Sitcom with the theme song \"I'm a Survivor\"",
    "Musical McEntire",
    "McEntire of country",
    "Country name",
    "Country star's sitcom",
    "Sitcom about the Hart family",
    "Country crooner McEntire",
    "WB sitcom",
    "Musical McEntire",
    "Country's McEntire",
    "___ Hart, sitcom title character",
    "Sitcom about a Texas soccer mom",
    "Self-titled WB sitcom",
    "C & W's McEntire",
    "Singer McEntire",
    "Self-titled WB sitcom",
    "WB show named for its star",
    "Country's McEntire",
    "Country music's McEntire",
    "Country artist McEntire",
    "Singer McEntire",
    "WB sitcom",
    "Self-titled WB comedy",
    "1988 platinum-selling country album",
    "Singer McEntire",
    "Country singer McEntire",
    "Singer McEntire",
    "Country name",
    "Singer McEntire",
    "First name in country",
    "Singer McEntire",
    "Singer McEntire",
    "Platinum country album of 1988",
    "Faulkner's ___ Rivers",
    "Singer McEntire",
    "Country name",
    "First name in country",
    "First name in country music",
    "C&W's McEntire",
    "Singer McEntire",
    "1988 country album",
    "Singer McEntire",
    "First name in country",
    "Singer McEntire",
    "Singer McEntire",
    "First name at Opryland USA",
    "First name in country",
    "1988 country album",
    "Singer McEntire",
    "Midianite leader of the Old Testament",
    "First name in country",
    "1988 country album",
    "Country's McEntire",
    "1968 million-selling country album",
    "Singer McEntire",
    "Singer McEntire",
    "Songbird McEntire",
    "Singer McEntire",
    "Ms. McEntire",
    "Singer McEntire"
  ],
  "owls": [
    "You can't say they won't give a hoot!",
    "Real head-turners",
    "Creatures that can have asymmetrical ears, which aid in hunting",
    "Symbols of wisdom",
    "Birds that can turn their heads 270?? in either direction",
    "They give a hoot",
    "Corvine : crows :: strigine : ___",
    "Hooters",
    "Murder : crows :: parliament : ___",
    "Birds that hoot",
    "\"Wise\" ones",
    "\"Wise\" sorts",
    "Real head-turners?",
    "Pride : lions : parliament : ___",
    "Avian hooters",
    "Birds whose heads can move 270??",
    "Hogwarts delivery system",
    "Hogwarts' fifth-year exams, for short",
    "Hooters",
    "Knot : toads :: parliament : ___",
    "Temple University's team",
    "Head-turning night fliers",
    "Big-eyed birds",
    "Messengers at Hogwarts",
    "Mail deliverers at Hogwarts",
    "Ones taking night flights?",
    "Late-night fliers",
    "Big-eyed birds",
    "Their necks can turn 270 degrees",
    "Temple University team",
    "Fifth-year exams at Hogwarts",
    "Nocturnal hunters",
    "Mice catchers",
    "They're known for head-turning",
    "Birds whose heads can rotate 135 degrees left or right",
    "Mouse catchers",
    "Mice catchers",
    "Big-eyed birds",
    "Birds with rare binocular vision",
    "Temple University team, with \"the\"",
    "Barn birds",
    "Night fliers",
    "Hooters",
    "Barn denizens",
    "Mice catchers",
    "Mice catchers",
    "Mice catchers",
    "Good mousers",
    "Rice University nickname",
    "Birds in barns",
    "Two residents of the Old Man's beard, in a Lear verse",
    "They fly by night",
    "Predators on mice",
    "Birds in barns",
    "Wise ones",
    "Temple footballers",
    "Some are horned",
    "Nocturnal preyers",
    "Wise birds",
    "Night predators"
  ],
  "leak": [
    "Reveal, as confidential information",
    "Faucet problem",
    "Plumbing woe",
    "Concern for a plumber or government official",
    "Reason to replace a fuel line",
    "Unauthorized disclosure",
    "Drip, drip, drip",
    "Security lapse",
    "Not keep a secret",
    "Ceiling stain's cause",
    "Pipeline problem",
    "Release a la Edward Snowden",
    "Boon for an investigative journalist",
    "Roof problem",
    "Plumbing problem",
    "Hiss cause",
    "Lapse in secrecy",
    "Tip for a reporter, maybe",
    "Julian Assange posting",
    "Puddle producer, perhaps",
    "Publicist's headache",
    "Scuba diver's worry",
    "Unexpected info source",
    "Unauthorized preview, say",
    "Tap mishap",
    "Drip, say",
    "Unauthorized disclosure",
    "Drip from a pipe, e.g.",
    "Faucet fault",
    "News source, perhaps",
    "Plumbing problem",
    "Little hole, maybe",
    "Pipe problem",
    "Newshawk's source, often",
    "Tap trouble",
    "Reason to call a plumber",
    "Puddle's cause, maybe",
    "Plumbing problem",
    "Breach of security",
    "Puddle cause",
    "Unofficial news source",
    "Patch's place",
    "Security lapse",
    "Pipe problem",
    "Roof problem",
    "Security concern",
    "Boater's worry",
    "Reporter's news source",
    "Aquarist's annoyance",
    "Breach of secrecy",
    "Faucet failure",
    "Job for a plumber",
    "Betrayal of sorts",
    "Reporter's news source",
    "Deep throat",
    "Security breach",
    "Deep Throat, e.g.",
    "News source, sometimes",
    "Secrecy problem",
    "Pipe hole",
    "Washington news source, maybe",
    "Plumber's concern",
    "Washington story, maybe",
    "Plumber's concern"
  ],
  "emmy": [
    "Award won multiple times by \"Modern Family\" and \"All in the Family\"",
    "Part of EGOT",
    "Award shaped like a winged woman",
    "Statuette that weighs 6 pounds 12 1/2 ounces",
    "Award for a soap, maybe",
    "Primetime ___",
    "TV award",
    "Tony's cousin",
    "One of nine for Tina Fey",
    "One of 22 for Jon Stewart",
    "It depicts a winged woman holding an atom",
    "Cousin of a Golden Globe",
    "Daytime ___",
    "TV award",
    "Small-screen award",
    "TV award",
    "Oscar : film :: ___ : TV",
    "Award won for 39- and 41-Across's programs",
    "Comedy series award, maybe",
    "Prize since 1949",
    "Television award",
    "Prize since 1949",
    "Longtime Susan Lucci quest",
    "TV honor",
    "Lucci's award, finally",
    "Susan Lucci's 1999 award",
    "Kind of nomination",
    "Prize since 1949",
    "TV award",
    "\"Frasier\" honor",
    "Susan Lucci's eluder",
    "Award for Oprah",
    "A person might earn one for a score",
    "Lucci's elusive prize",
    "Prize for Oprah",
    "Award for a sitcom",
    "TV prize",
    "Award since 1949",
    "Oscar's cousin",
    "TV's Oscar",
    "Prize since 1948"
  ],
  "plop": [
    "Sound of sitting down heavily",
    "When repeated, start of an old antacid slogan",
    "Sit (down) heavily",
    "Make a splash",
    "Sound preceding a ripple",
    "Sit (down) hard",
    "Alka-Seltzer sound",
    "Sit (down) heavily",
    "Fall heavily",
    "Make a splash",
    "Tiny splash",
    "Sound associated with ripples",
    "Alka-Seltzer-into-water sound",
    "... a person sitting down",
    "Sit heavily",
    "Raindrop sound",
    "Sit (down)",
    "Raindrop sound",
    "Sit (down)",
    "When repeated, start of an old antacid jingle",
    "Sit (down)",
    "Fall sound",
    "Alka-Seltzer sound",
    "Sit (down)",
    "Alka-Seltzer jingle starter",
    "Sitting sound",
    "Make a splash",
    "Kerplunk",
    "Raindrop sound",
    "Sit (down)",
    "Word in an Alka-Seltzer ad",
    "Alka-Seltzer sound",
    "Raindrop's fall",
    "Sitting-down sound",
    "Sit (down)"
  ],
  "rafa": [
    "Tennis's Nadal, informally",
    "Tennis's Nadal, familiarly",
    "Tennis's Nadal, to fans",
    "Tennis star Nadal, to fans",
    "Tennis great Nadal, to fans",
    "Nickname for a two-time Wimbledon winner"
  ],
  "arlo": [
    "1968 self-titled folk album",
    "Singer/songwriter Parks with the 2021 album \"Collapsed in Sunbeams\"",
    "Guthrie who performed at Woodstock",
    "\"___ and Janis\" (comic)",
    "Folk singer Guthrie",
    "Janis's partner in the comics",
    "Comic strip \"___ and Janis\"",
    "Main dinosaur in Pixar's \"The Good Dinosaur\"",
    "\"___ and Janis\" (comic strip)",
    "Singer Guthrie",
    "Folkie Guthrie",
    "Folk singer Guthrie",
    "Guthrie at Woodstock",
    "1968 self-titled folk album",
    "Folk singer Guthrie",
    "With 41-Down, \"Alice's Restaurant\" singer",
    "First name at Woodstock",
    "Singer Guthrie",
    "Janis's partner in the comics",
    "Janis's spouse in the comics",
    "Dinosaur in Pixar's \"The Good Dinosaur\"",
    "Guthrie who performed at Woodstock",
    "Guthrie who sang at Woodstock",
    "Half of a comic strip duo",
    "The good dinosaur in Pixar's \"The Good Dinosaur\"",
    "Janis's husband in the funnies",
    "Comic strip husband",
    "Guthrie of Rising Son Records",
    "Folk legend Guthrie",
    "First name at Woodstock",
    "Singer Guthrie",
    "Woody offshoot?",
    "Guthrie who sang at Woodstock",
    "1968 live folk album",
    "Singer Guthrie",
    "First name in folk",
    "First name in folk",
    "\"Alice's Restaurant\" singer Guthrie",
    "Janis's cartoon husband",
    "Woodstock artist Guthrie",
    "Folkie who chronicled Alice",
    "Janis's spouse, in the comics",
    "Singer Guthrie",
    "Singer Guthrie",
    "Singer Guthrie",
    "Janis's partner in the funnies",
    "Comic strip \"___ and Janis\"",
    "First name at Woodstock",
    "Folkie Guthrie",
    "1968 live folk record",
    "Folkie Guthrie",
    "Folk singer Guthrie",
    "Folkie Guthrie",
    "First name at Woodstock",
    "Alice's chronicler",
    "Joan followed him at Woodstock",
    "Folkie who sang of Alice",
    "Folkie Guthrie",
    "He sang about Alice's restaurant",
    "Janis's comic-strip husband",
    "Guthrie with a guitar",
    "Comic strip \"___ and Janis\"",
    "Folkie Guthrie",
    "Guthrie who sang about Alice's Restaurant",
    "Janis's comic strip hubby",
    "Folkie Guthrie",
    "Janis's comics partner",
    "Singer Guthrie",
    "Man's name that's an anagram of 108-Down",
    "Author/poet Bates",
    "Guthrie at Woodstock",
    "He preceded Joan at Woodstock",
    "One of a comic-strip married couple",
    "Guthrie with a guitar",
    "Guthrie who sang \"Alice's Restaurant\"",
    "Janis's comics husband",
    "One of Woody's stock at Woodstock",
    "Live folk album of 1968",
    "1968 live folk album",
    "First name in folk",
    "Folkie Guthrie",
    "Singer Guthrie",
    "Janis's hubby in the funnies",
    "Folkie Guthrie",
    "\"___ and Janis\" (comic strip)",
    "\"___ and Janis\" (comic strip)",
    "1968 folk album",
    "Guthrie who sang about Alice",
    "Janis's hubby in the funnies",
    "1968 live folk album",
    "Folkie Guthrie",
    "Singer Guthrie",
    "1968 album with the song \"John Looked Down\"",
    "Folkie Guthrie",
    "A Guthrie",
    "Janis's partner in the comics",
    "A Guthrie",
    "Musical Guthrie",
    "Singer Guthrie",
    "Guthrie who sang \"City of New Orleans\"",
    "Folk's Guthrie",
    "A Guthrie",
    "Folkie Guthrie",
    "Folk singer Guthrie",
    "Name on \"Alice's Restaurant\"",
    "Singer Guthrie",
    "Live folk album of 1968",
    "Folk singer Guthrie",
    "Singer Guthrie",
    "Folksinger Guthrie",
    "Singer Guthrie",
    "Woody's son",
    "\"___ and Janis\" (comic strip)",
    "Guthrie who sang at Woodstock",
    "Folk singer Guthrie",
    "1968 folk album",
    "Author/poet Bates",
    "Alice's chronicler, in song",
    "\"___ & Janis\" (comic strip)",
    "Janis's husband in the funnies",
    "Singer Guthrie",
    "Folk singer Guthrie",
    "\"___ & Janis\" (comic)",
    "A Guthrie",
    "Woody's musical son",
    "Singer Guthrie",
    "Singer Guthrie",
    "Author/poet ___ Bates",
    "Alice's chronicler",
    "Guthrie the younger",
    "Singer Guthrie",
    "1968 folk album",
    "Alice's chronicler",
    "He sang about Alice",
    "Folk forename",
    "Guthrie who sang \"The City of New Orleans\"",
    "Comic strip \"___ & Janis\"",
    "Janis's partner, in the comics",
    "Folk's Guthrie",
    "Folk singer Guthrie",
    "Singer Guthrie",
    "Singer Guthrie",
    "Singer Guthrie",
    "Folk singer Guthrie",
    "Alice's chronicler",
    "Singer Guthrie",
    "He sang of Alice",
    "Comic strip \"___ and Janis\"",
    "Guthrie with a guitar",
    "Folk's Guthrie",
    "Singer Guthrie",
    "Folk singer Guthrie",
    "Janis's comic strip mate",
    "Comic strip \"___ & Janis\"",
    "1967 folk album",
    "First name at Woodstock",
    "He sang of Alice",
    "He sang about Alice",
    "Folk's Guthrie",
    "Singer Guthrie",
    "Singer Guthrie",
    "He sang about Alice",
    "First name in folk",
    "\"Alice's Restaurant\" name",
    "Woody Guthrie's son",
    "Singer Guthrie",
    "Woody's son",
    "Singer Guthrie",
    "Singer Guthrie",
    "First name in folk",
    "One of the Guthries",
    "Singer Guthrie",
    "A Guthrie",
    "Guthrie the younger",
    "Guthrie the younger",
    "\"Alice's Restaurant\" singer",
    "Singer Guthrie",
    "Singer Guthrie",
    "First name in folk",
    "Guthrie of folk",
    "Singer Guthrie",
    "Singer Guthrie",
    "Folkie Guthrie",
    "Woody's boy",
    "Mr. Guthrie",
    "Woody's son",
    "Young Guthrie",
    "He sang about Alice",
    "A Guthrie",
    "A Guthrie",
    "1967 folk album",
    "Mr. Guthrie",
    "Folkie Guthrie",
    "Singer Guthrie",
    "Mr. Guthrie",
    "Alice's Restaurant patron",
    "First name in folk music",
    "Singer Guthrie",
    "A Guthrie",
    "Woody's boy",
    "Woody's boy",
    "Big name in 60's folk music",
    "Woody's kid",
    "Singer Guthrie",
    "Alice's chronicler",
    "A Guthrie",
    "Mr. Guthrie",
    "Guthrie the younger",
    "Woody's son"
  ],
  "erno": [
    "Rubik with a cube",
    "Rubik with a famous cube",
    "\"Cubist\" Rubik",
    "___ Laszlo (cosmetics brand)",
    "Puzzlemaker Rubik",
    "Puzzle inventor Rubik",
    "Puzzlemaker Rubik",
    "Puzzle designer Rubik",
    "\"Um ... sorry!\"",
    "Toymaker Rubik",
    "Cosmetician Laszlo",
    "Cube maker Rubik",
    "Cubemeister Rubik",
    "Rubik who invented Rubik's Cube",
    "\"Cubist\" Rubik",
    "Cosmetician ___ Laszlo",
    "Hungarian name meaning \"sincere\"",
    "Celebrity cosmetician Laszlo",
    "Puzzle inventor Rubik",
    "___ Rapee, longtime Radio City Music Hall conductor",
    "Puzzler Rubik",
    "Cube creator Rubik",
    "Cubic Rubik",
    "Resistance leader in Woody Allen's \"Sleeper\"",
    "Professzor Rubik",
    "Puzzlemaker Rubik",
    "Composer Dohnanyi",
    "Rubik of cube fame",
    "Designer/architect Goldfinger",
    "Inventor Rubik",
    "Composer Dohnanyi",
    "Cosmetics maker ___ Laszlo",
    "Cube inventor Rubik",
    "___ Laszlo skin care products",
    "Pianist/composer Dohnanyi",
    "Rubik who invented Rubik's Cube",
    "Cosmetics maker ___ Laszlo",
    "\"Ruralia Hungarica\" composer Dohnanyi",
    "Puzzlemaker Rubik",
    "Composer Dohnanyi",
    "Rubik of cube fame",
    "Dohnanyi who composed \"Ruralia Hungarica\"",
    "Puzzlemaker Rubik",
    "Cube maker Rubik",
    "Pianist/composer Dohnanyi",
    "Cube creator Rubik",
    "Cube inventor Rubik",
    "Cosmetics maker ___ Laszlo",
    "Rubik of Rubik's cube fame",
    "Hungarian pianist von Dohnanyi",
    "Cubic Rubik",
    "Brain-teasing Rubik",
    "With 10-Down, \"Charmaine\" songwriter",
    "Cubemaster Rubik",
    "Brain-teasing Rubik",
    "Cubemaker Rubik",
    "Cubic Rubik",
    "John Beck's \"Sleeper\" role",
    "Cube inventor Rubik",
    "Cubemaker Rubik",
    "Cubist Rubik",
    "\"Diane\" composer Rapee",
    "\"Cubist\" Rubik",
    "Cubemeister Rubik",
    "Mr. Rubik",
    "Mr. Rubik",
    "Mr. Rubik",
    "Cube inventor Rubik",
    "Cubemeister Rubik",
    "Inventor Rubik",
    "Cubemaker Rubik",
    "Cubemeister Rubik",
    "\"Cubist\" Rubik"
  ],
  "same": [
    "\"Likewise\"",
    "\"Likewise\"",
    "\"Likewise\"",
    "Uniform",
    "With 65-Down, \"Ditto\"",
    "\"Me too\"",
    "Identical",
    "Common bar order, with \"the\"",
    "\"Me too!\"",
    "\"Me too!\"",
    "Uncreative bar order, with \"the\"",
    "\"Ditto\"",
    "\"The ___ to you!\"",
    "\"Me too!!!\"",
    "\"Ditto\"",
    "Uniform",
    "\"Ditto\"",
    "\"Me too\"",
    "Unchanged",
    "Word that can precede sex",
    "\"___ here!\"",
    "\"___ difference!\"",
    "\"Likewise\"",
    "\"Ditto\"",
    "Corresponding",
    "Unchanged",
    "Aforementioned",
    "Identical",
    "Matching",
    "Old story's beginning?",
    "Old story intro?",
    "Equal",
    "\"___ here\"",
    "Identical",
    "\"Me, too\"",
    "\"Ditto\"",
    "[See blurb]",
    "The very ___",
    "Identical",
    "Aforementioned",
    "Common bar order, with \"the\"",
    "Matching",
    "Unoriginal order, with \"the\"",
    "Kind of difference, oxymoronically",
    "Very",
    "Unchanged",
    "Ditto",
    "All the ___",
    "Common order, with \"the\"",
    "\"___ here\"",
    "Changeless",
    "Unaltered",
    "Very",
    "Identical",
    "Ditto, with \"the\"",
    "Identical",
    "Identical",
    "Bar order, with \"the\"",
    "Ditto",
    "Corresponding",
    "Aforementioned",
    "Previously said",
    "Unaltered",
    "What \"tauto-\" means",
    "Restaurant order, maybe, with \"the\"",
    "Unvaried",
    "\"___ here\"",
    "Ditto",
    "All the ___",
    "Identical",
    "Matching",
    "\"___ here\" (\"Ditto\")",
    "\"___ here\"",
    "Word said before \"time\" and \"place\"",
    "Just the ___",
    "Copycat's request",
    "Cloned",
    "The very ___",
    "Ditto",
    "Indistinguishable",
    "Bar order, with \"the\"",
    "Unvarying",
    "Frequent restaurant order, with \"the\"",
    "Identical",
    "Ditto",
    "Equal",
    "\"Sighted sub, sank ___\"",
    "Ditto",
    "Unvaried",
    "Unchanged",
    "Ditto",
    "Equivalent",
    "Corresponding",
    "Identical",
    "Unchanged",
    "Aforementioned",
    "Ditto"
  ],
  "blue": [
    "Racy",
    "Pale ___ dot (Earth)",
    "Risque",
    "Like most of New York State's flag",
    "Feeling sad",
    "Like Alice's gown in \"Alice in Wonderland\"",
    "Down",
    "Color like indigo",
    "Midnight, e.g.",
    "Like the upper half of Haiti's flag",
    "Like Cookie Monster and Grover",
    "Off-color, paradoxically",
    "The \"B\" of Roy G. Biv",
    "The \"B\" in Roy G. Biv",
    "Word before cheese or chip",
    "Not at all chipper",
    "Feeling down",
    "Erotic",
    "See 1-Across",
    "Down in the dumps",
    "Grotto color at 61-Across",
    "Feeling down",
    "Bawdy",
    "Down",
    "Picasso's ___ Period, 1901-04",
    "Down",
    "Like a first-place ribbon",
    "Smurf-colored",
    "With 4-Down, longtime jazz record label",
    "With 52-Across, Thomas Gainsborough portrait, with \"The\"",
    "Azure",
    "Sad",
    "Ocean-colored",
    "Waiting to exhale?",
    "X-rated",
    "Like a robin's egg",
    "Down in the dumps",
    "Off-color",
    "Depressed",
    "Azure",
    "Sky-colored",
    "Bummed out",
    "Off-color",
    "Sad",
    "See 5-Down",
    "Depressed",
    "Like robbins' eggs",
    "One side in the Civil War",
    "Cyan",
    "Dispirited",
    "In a funk"
  ],
  "bore": [
    "Make yawn",
    "Put to sleep",
    "Sleep-inducing pill?",
    "___ someone to tears",
    "Snoozefest",
    "Yawner",
    "Induce ennui in",
    "Snoozer",
    "Gun measure",
    "Snoozer",
    "Do some drills?",
    "Barrel diameter",
    "Drill (into)",
    "Hollow out",
    "\"A person who talks when you wish him to listen,\" per Ambrose Bierce",
    "Auger",
    "\"Zzz\" inducer",
    "Tiresome sort",
    "\"Every hero becomes a ___ at last\": Emerson",
    "Make yawn",
    "Drill a hole",
    "Snoozefest",
    "One who talks only about himself, say",
    "Ho-hum sort",
    "One who's easier to pray for than to visit, according to C. S. Lewis",
    "Droner, usually",
    "Put to sleep",
    "Drone, e.g.",
    "Drill",
    "Barrel statistic",
    "Bloviator, often",
    "Had on one's back",
    "Make yawn",
    "Drag",
    "Ear bender",
    "Yawn inducer",
    "Yawn producer",
    "Make yawn",
    "Crashing sort",
    "Schmo",
    "Wearisome one",
    "Drill",
    "Yawner",
    "Yawner",
    "Yielded",
    "Put to sleep, maybe",
    "Cause to yawn",
    "Gun stat",
    "Unwanted guest",
    "Hardly the life of the party",
    "Word with crashing or tidal",
    "Use an auger",
    "Reduce to tears, maybe",
    "Drill",
    "Brought forth",
    "Crashing type?",
    "Drill",
    "Barrel diameter",
    "Make yawn",
    "Drip",
    "Yawn inducer",
    "Dull fellow",
    "Endured",
    "Yawn producer"
  ],
  "arco": [
    "With the bow, musically",
    "West Coast gas brand",
    "Gas brand that's also a musical direction",
    "Bowed, to a cellist",
    "With the bow, musically",
    "With the bow, in music",
    "Direction to a cellist",
    "With the bow, in music",
    "Played with the bow, in music",
    "Counterpart of pizzicato, in music",
    "West Coast gas brand",
    "With the bow, in music",
    "California-based gas company",
    "Music direction meaning \"with the bow\"",
    "Gas brand rendered in all capital letters",
    "West Coast gas brand",
    "Gas giant since 1966",
    "Chevron competitor",
    "Oil giant that's part of the Tesoro Corporation",
    "___ Arena (past Kings home)",
    "BP sale of 2013",
    "West Coast gas chain",
    "With a bow, on a score",
    "Using the bow, in music",
    "Instruction to play with the bow",
    "Instruction for a violinist",
    "Western gas brand",
    "With the bow, in music",
    "With the bow, musically",
    "With the bow, in music",
    "\"Giovanna d'___\" (Verdi opera)",
    "Western U.S. gas brand",
    "\"With the bow,\" to a violinist",
    "West Coast gas brand",
    "California-based oil giant",
    "Sacramento's former ___ Arena",
    "Western U.S. oil giant",
    "Western U.S. gasoline giant",
    "BP subsidiary",
    "Oil company acronym",
    "Not pizzicato",
    "___ Arena (Kings' home)",
    "U.S. gas chain",
    "Sacramento's ___ Arena",
    "Bowed, in music",
    "Western U.S. gas giant",
    "Bowed, to a violist",
    "Bowed, in music",
    "Sacramento's ___ Arena",
    "Bowed, in music",
    "With the bow, in music",
    "Not pizzicato",
    "Kings' arena",
    "Exxon alternative",
    "Chevron competitor",
    "With the bow, in music",
    "Sacramento's ___ Arena",
    "It's a gas",
    "With the bow, in music",
    "Big name in oil",
    "Oil company acronym",
    "Big energy supplier",
    "With 63-Across, where the Kings play in Sacramento",
    "West Coast gas brand",
    "Sacramento's ___ Arena",
    "Sacramento's ___ Arena",
    "Big name in oil",
    "With the bow, to a violinist",
    "With a bow, to Stern",
    "Cellist's direction",
    "___ Arena (Kings' home)",
    "With the bow, in music",
    "With the bow, in music",
    "Sacramento's ___ Arena",
    "Big name in oil",
    "Direction to a cellist",
    "\"Giovanna d'___\" (Verdi opera)",
    "\"Giovanna d'___\" (Verdi opera)",
    "Big name in the oil biz",
    "With the bow, in music",
    "Chevron competitor",
    "With a bow, to Stern",
    "Sacramento's ___ Arena",
    "Big oil company",
    "L.A.-based petroleum giant",
    "Mobil rival",
    "Sacramento's ___Arena",
    "Mobil rival",
    "With a bow, to Stern",
    "With the bow, to a violinist",
    "With the bow, to a cellist",
    "Major oil corp.",
    "Sacramento arena",
    "Bowed, in music",
    "Sacramento arena",
    "With a bow, musically",
    "With the bow, in music",
    "With a bow, musically"
  ],
  "flub": [
    "Bungle",
    "Screw up",
    "Goof",
    "Mess up",
    "Botch",
    "Bollix up",
    "Bungle",
    "Mess up",
    "Mess up",
    "Blunder",
    "Bungle",
    "Bungle"
  ],
  "phds": [
    "High degrees, for short",
    "Major academic achievements",
    "Degrees after M.A.s",
    "Many faculty members, in brief",
    "Achievements of Henry Kissinger and Martin Luther King Jr., in brief",
    "Most profs",
    "Many profs",
    "Elite group of grads",
    "Many tenured profs",
    "Degs. for many professors",
    "Condoleezza Rice and Martin Luther King Jr., for two",
    "Degrees of magnitude?",
    "Goals of some candidates",
    "Nth degrees?",
    "Upper-tier academics",
    "Many college profs",
    "Degrees of excellence?",
    "Pursuits of some candidates, for short",
    "Many academics, for short",
    "Degs. for many profs",
    "Highest degrees",
    "Grad school achievements",
    "Profs' degs.",
    "Advanced degs.",
    "Academics' degrees",
    "Many profs.",
    "Many college profs",
    "Profs have them",
    "Goals of some candidates, for short",
    "Highest degrees",
    "Master's superiors?",
    "Thesis penners",
    "Professors' degs.",
    "High degrees?",
    "Academic types",
    "Many college profs.",
    "Third degrees",
    "Some scholars, for short",
    "Third degrees, usually"
  ],
  "jews": [
    "Pesach observers",
    "Seder celebrants",
    "Shul attendees",
    "Moe, Larry and Curly, ethnically",
    "A majority of Israelis",
    "Saturday worshipers",
    "Charge of Moses",
    "Jacob and Abraham, e.g.",
    "\"My People\" subject",
    "Feast of Lights observers"
  ],
  "rich": [
    "Laughable",
    "Like the 1%",
    "Deep-pocketed",
    "Like sauces in French cuisine, typically",
    "High-G.D.P.",
    "Like a billionaire",
    "Like Mr. Moneybags",
    "Like triple fudge brownies",
    "Worth a great deal",
    "Amusingly ironic",
    "Wealthy",
    "Decadent, as cake",
    "Rolling in it",
    "High in calories",
    "\"That's ___\" (sarcastic response)",
    "Laughable",
    "End of the word ladder",
    "Calorific",
    "Laughable",
    "Like the people on the Forbes 400 list",
    "Among the 1%, so to speak",
    "Rolling in green",
    "Rolling in it",
    "High in calories",
    "More than well-off",
    "Filthy ___",
    "Full of calories",
    "Sumptuous",
    "Very chocolaty, say",
    "Very sweet and full of calories",
    "Highly amusing",
    "Having megamillions",
    "Delicious but fattening",
    "Loaded",
    "On easy street",
    "Full-bodied",
    "Rolling in dough",
    "Rolling in the dough",
    "Loaded",
    "Loaded",
    "Rolling in dough",
    "Like chocolate-chocolate cake",
    "Deep",
    "Mellow-toned",
    "Rolling in dough",
    "Rolling in money",
    "Lucky in the lottery, maybe",
    "Too amusing for words",
    "Rolling in dough",
    "Opulent",
    "Like a German chocolate cake",
    "Imitator Little",
    "Abundantly supplied",
    "Well-off"
  ],
  "imay": [
    "\"It's a possibility for me\"",
    "Noncommittal response to \"You coming?\"",
    "\"It's possible\"",
    "Wishy-washy R.S.V.P.",
    "Noncommittal response",
    "\"It's a possibility for me\"",
    "\"Perhaps\"",
    "\"Possibly\"",
    "\"___ as well\"",
    "Polite words after \"if\"",
    "Wishy-washy reply",
    "Wishy-washy response",
    "Reply suggesting \"perhaps\"",
    "Wishy-washy response",
    "Words after \"if\" or before \"as well\"",
    "\"If ___ suggest ...\"",
    "\"If ___ be so bold ...\"",
    "\"If ___ suggest ...\"",
    "\"If ___ be so bold ...\"",
    "Noncommittal words",
    "\"If ___ be so bold...\""
  ],
  "ruth": [
    "The \"R\" of R.B.G.",
    "The \"R\" of R.B.G.",
    "The \"R\" of the Supreme Court's R.B.G.",
    "Supreme Court justice ___ Bader Ginsburg",
    "M.L.B. star ejected from 87-Across",
    "Book after Judges",
    "First name on the Supreme Court",
    "Compassion for the misery of others",
    "Legendary #3 on the diamond",
    "Pity",
    "I Samuel preceder",
    "First name on the Supreme Court",
    "Book that begins \"In the days when the judges ruled, there was a famine in the land\"",
    "First name on the Supreme Court",
    "\"Whither thou goest, I will go\" speaker",
    "See 1-Across",
    "Hitter of 714 home runs",
    "Gordon of \"Harold and Maude\"",
    "Babe with a bat",
    "1923 A.L. M.V.P.",
    "Whence the line \"Whither thou goest, I will go\"",
    "Justice ___ Bader Ginsburg",
    "Slugger called the Sultan of Swat",
    "Buzzi of \"Laugh-In\"",
    "Crime novelist Rendell",
    "Book named for a woman",
    "Baby ___",
    "Book after Judges",
    "Ancestress of King David",
    "Bride of Boaz",
    "It begins with the story of Naomi",
    "Baseball's Bambino",
    "OUTFIELDER",
    "Buzzi of \"Laugh-In\"",
    "See 49-Down",
    "It begins \"In the days when the judges ruled...\"",
    "Babe's name",
    "Gordon or Ginsburg",
    "< Player with this retired number",
    "Dr. Westheimer",
    "Diamond great",
    "Legendary Yankee",
    "Judges' successor",
    "The Bambino",
    "Wife of Boaz",
    "Compassion"
  ],
  "nast": [
    "Condé ___",
    "Artist once called \"The President Maker\"",
    "Early illustrator of Uncle Sam",
    "Who originated the modern image of Santa Claus",
    "Cartoonist Thomas",
    "Father of the American Cartoon",
    "Tammany Hall cartoonist",
    "Cartoonist Thomas",
    "Cartoonist who popularized Uncle Sam",
    "Illustrator Thomas",
    "Conde ___ (magazine publisher)",
    "Harper's Weekly cartoonist Thomas",
    "Conde ___ (magazine company)",
    "Father of the American Cartoon",
    "Earliest-born member of the Cartoon Hall of Fame",
    "Conde ___",
    "Boss Tweed nemesis",
    "Reconstruction-era cartoonist",
    "Conde ___ (magazine company)",
    "Tammany tiger creator",
    "Thomas with a sharp pen",
    "Conde ___",
    "Boss Tweed lampooner",
    "Thomas who lampooned Boss Tweed",
    "Creator of the G.O.P. elephant",
    "Old Harper's Weekly cartoonist",
    "Cartoonist Thomas",
    "Noted elephant designer",
    "Santa drawer",
    "Conde ___",
    "G.O.P. elephant originator",
    "Conde ___ (magazine publisher)",
    "Cartoonist who took on Boss Tweed",
    "Political cartoonist Thomas",
    "Creator of the Tammany Hall tiger",
    "Creator of 1867's \"Grand Caricaturama\"",
    "Thomas with a pointed pen",
    "Cartoonist Thomas",
    "Cartoonist who created the Tammany Hall tiger",
    "He bought Vogue in 1909",
    "Santa's drawer",
    "Tweed twitter Thomas",
    "Illustrator Thomas",
    "Boss Tweed skewerer",
    "Cartoonist Thomas who attacked Boss Tweed",
    "Creator of the Tammany Hall tiger",
    "Harper's Weekly cartoonist",
    "Big name in magazine publishing",
    "Tweed nemesis",
    "Illustrator who lampooned Tweed",
    "Democratic donkey designer",
    "Illustrator Thomas",
    "Creator of the G.O.P. elephant",
    "Conde ___ (publisher)",
    "Boss Tweed caricaturist",
    "Harper's Weekly artist",
    "Tammany skewerer",
    "Cartoonist Thomas",
    "Harper's Weekly caricaturist",
    "Tammany tiger's creator",
    "Boss Tweed skewerer",
    "Cartoonist Thomas",
    "Donkey drawer",
    "Magazine publisher Conde ___",
    "Democratic donkey designer",
    "Boss Tweed's nemesis",
    "Democratic donkey designer",
    "Classic political cartoonist",
    "Political cartoonist Thomas",
    "Political cartoonist Thomas",
    "Caricaturist Thomas",
    "Harper's Weekly cartoonist",
    "G.O.P. elephant's creator",
    "Harper's Weekly cartoonist",
    "Glamour founder",
    "Boss Tweed nemesis",
    "Tweed Ring lampooner",
    "Frank Leslie illustrator",
    "\"Harper's Weekly\" cartoonist",
    "Cartoonist Thomas",
    "Boss Tweed lampooner",
    "Santa drawer",
    "Tweed's thorn"
  ],
  "june": [
    "When \"Ma is gettin' kittenish with Pap,\" in \"Carousel\"",
    "Big month for weddings",
    "When summer officially starts",
    "Solstice time",
    "It's \"bustin' out all over,\" in song",
    "6, written out",
    "Beaver's mom on \"Leave It to Beaver\"",
    "Month for many Geminis",
    "Father's Day month",
    "6, written out",
    "Busy time for ministers",
    "Graduation month",
    "Royal Ascot time",
    "Kind of wedding",
    "Part of summer"
  ],
  "manu": [
    "English football powerhouse, to fans",
    "English football powerhouse, to fans",
    "Four-time N.B.A. champ Ginobili"
  ],
  "lute": [
    "Serenade need, perhaps",
    "Instrument with a bent neck",
    "Troubadour's accompaniment",
    "Caravaggio's \"The ___ Player\"",
    "Instrument broken over Hortensio's head in \"The Taming of the Shrew\"",
    "Renaissance stringed instrument",
    "Renaissance Faire instrument",
    "Instrument with a bent neck",
    "Elizabethan stringed instrument",
    "Troubadour's stringed instrument",
    "Pear-shaped stringed instrument",
    "Minstrel's instrument",
    "Stringed instrument for a madrigal",
    "Troubadour's instrument",
    "Subject of a lesson for Katharina in \"The Taming of the Shrew\"",
    "One with a long neck and a rounded body",
    "Vermeer's \"Woman With a ___\"",
    "Minstrel's instrument",
    "It's featured in two Vivaldi concertos",
    "Ancestor of a banjo",
    "Renaissance instrument",
    "Instrument that's plucked",
    "Relative of a mandolin",
    "Cousin of a mandolin",
    "Old balladeer's instrument",
    "It has a low bridge",
    "Old instrument that's strummed",
    "Accompaniment for a madrigal",
    "It has strings attached",
    "Vermeer's \"Woman With a ___\"",
    "Cousin of a mandolin",
    "Balladeer's aid",
    "Guitar forerunner",
    "Renaissance musicmaker",
    "Minstrel's instrument",
    "Renaissance instrument",
    "Guitar forerunner",
    "Pear-shaped instrument",
    "Vermeer's \"Woman With a ___\"",
    "String instrument",
    "Madrigal accompaniment",
    "Relative of a mandolin",
    "Cousin of the banjo",
    "Long-necked instrument",
    "Guitar relative",
    "Pear-shaped instrument",
    "Instrument shaped like a 69-Across",
    "Mandolin's ancestor",
    "Fretted instrument",
    "Cousin of a bandore",
    "Pear-shaped instrument",
    "Guitar relative",
    "Minstrel's accompaniment",
    "Renaissance instrument",
    "Guitar's ancestor",
    "Accompaniment for a pavane",
    "Baroque instrument",
    "Guitar's ancestor"
  ],
  "chum": [
    "Cause of a feeding frenzy",
    "Fishing bait",
    "Buddy",
    "Pal",
    "Amigo",
    "Close one",
    "Shark attractant",
    "Bud",
    "Bud",
    "Bud",
    "Bait thrown overboard",
    "Buddyroo",
    "Bosom buddy",
    "Pal",
    "One to hang with",
    "Confidant",
    "Crony",
    "Bud",
    "Buddy",
    "Bud",
    "Buddy",
    "Fish bait",
    "Pal",
    "Good buddy",
    "Ground-up bait",
    "Buddy",
    "Fish scraps",
    "Ground bait for fish",
    "Bud",
    "Buddy",
    "Buddy",
    "Cohort",
    "Intimate",
    "Sidekick",
    "Sidekick",
    "Buddy",
    "Playmate"
  ],
  "pith": [
    "Crux",
    "Crux of the matter",
    "The white stuff in an orange",
    "Bitter part of an orange",
    "Essential part",
    "Heart",
    "Crux",
    "Essential element",
    "Substance",
    "Soft plant tissue",
    "Essence",
    "Core",
    "Heart of the matter",
    "Heart of the matter",
    "Heart",
    "Heart of the matter",
    "Essence",
    "Central part",
    "Core",
    "Tropical helmet material",
    "Nitty-gritty",
    "Heart or soul",
    "Central part",
    "Kind of helmet",
    "Marrow",
    "Core",
    "Meat",
    "Essence",
    "Heart of the matter",
    "___ helmet (safari wear)",
    "Essence",
    "Core",
    "Material for a topi"
  ],
  "toes": [
    "Answer that would be more apt at 10 Down?",
    "Things stuck in clogs",
    "\"Little piggies\"",
    "Tips for a shoeshiner",
    "Word with tippy or twinkle",
    "Ring bearers",
    "They might be curled or dipped",
    "Things sandals lack",
    "Little dippers?",
    "Parts of the body that may be wiggled",
    "Things some stretchers try to touch",
    "Stretchers may touch them",
    "Digits in flats, maybe",
    "Pedicurists work on them",
    "Ballet supporters",
    "Last inch or so of a foot",
    "Base 10?",
    "Tips of wingtips",
    "Ducky web sites?",
    "\"Little piggies\"",
    "Low digits",
    "\"Little piggies\"",
    "Tot's \"piggies\"",
    "Pirouette points",
    "Drives obliquely",
    "The \"ten\" in \"hang ten\"",
    "Low digits",
    "The \"ten\" in \"hang ten\"",
    "Web sites?",
    "Low digits",
    "Sock parts",
    "\"Little piggies\"",
    "\"Little piggies\"",
    "Lower 10",
    "\"Little piggies\"",
    "Pirouette points",
    "Where nails should not be hammered",
    "They may get stepped on",
    "Bad place to drop a heavy box",
    "Shoe tips",
    "Low digits",
    "Lower ten",
    "Little piggies",
    "Ones getting socked?",
    "Piggies",
    "Little piggies",
    "Stocking tips",
    "Nail holders",
    "Low digits",
    "Dactylitis locale",
    "11 to 20, for some counters",
    "Flirts may use theirs",
    "They may be corny",
    "Web site?",
    "Clumsy dancer's obstacles",
    "Pedicurists work on them",
    "There are five per foot",
    "Clumsy dancer's problems",
    "Wing tips' tips",
    "Ten below?",
    "Piggies",
    "\"Piggies\"",
    "Low digits",
    "Frostbite sites",
    "On one's ___",
    "Stocking parts",
    "Pedal pushers?",
    "Digits",
    "Ballerina's strong points",
    "\"Piggies\""
  ],
  "orig": [
    "Master: Abbr.",
    "Nee: Abbr.",
    "Not a copy: Abbr.",
    "Not a facsimile: Abbr.",
    "Before retitling: Abbr.",
    "Not a copy: Abbr.",
    "Not a copy: Abbr.",
    "One often duped: Abbr.",
    "Not a photocopy: Abbr.",
    "First: Abbr.",
    "Copier input: Abbr.",
    "Not a copy: Abbr.",
    "Not a dupe: Abbr.",
    "Not an imit.",
    "Not a copy: Abbr.",
    "Not a copy: Abbr.",
    "First: Abbr.",
    "Not a dup.",
    "Not a reproduction: Abbr.",
    "Before markdown: Abbr.",
    "Before being retitled: Abbr.",
    "Xerox material: Abbr.",
    "It goes on a photocopier: Abbr.",
    "At first: Abbr.",
    "Pg. in a photocopier",
    "At first: Abbr.",
    "At first: Abbr.",
    "Copyrightable, in a way: Abbr.",
    "Xerox insert: Abbr.",
    "Photocopier's need: Abbr.",
    "Something copied: Abbr.",
    "Not a dup.",
    "Not a copy: Abbr.",
    "First ed.",
    "The first: Abbr.",
    "Not a copy: Abbr.",
    "Not a dupl.",
    "Not a dupl.",
    "Not a copy: Abbr.",
    "Not a copy: Abbr.",
    "Not a copy: Abbr.",
    "Not secondhand: Abbr.",
    "Primary source: Abbr.",
    "First ed."
  ],
  "usna": [
    "Jimmy Carter's alma mater, briefly",
    "Sch. on Chesapeake Bay",
    "Sch. with 50+ alums who went on to become astronauts",
    "Annapolis inst.",
    "Its graduates include more than 50 astronauts, in brief",
    "Sch. for future admirals",
    "Jack Ryan's teaching post in Tom Clancy novels, briefly",
    "Annapolis inst.",
    "Roger Staubach's sch.",
    "Pres. Carter's alma mater",
    "Pres. Carter's alma mater",
    "\"From knowledge, sea power\" org.",
    "Jimmy Carter's alma mater: Abbr.",
    "Sen. McCain's alma mater",
    "John McCain's alma mater: Abbr.",
    "H. Ross Perot's alma mater: Abbr.",
    "Alma mater for Adm. Richard Byrd: Abbr.",
    "McCain's alma mater: Abbr.",
    "John McCain's alma mater: Abbr.",
    "John McCain's alma mater, briefly",
    "West Point rival, for short",
    "Inst. for midshipmen",
    "School for a future ens.",
    "Sch. that Roger Staubach played football for",
    "Annapolis inst.",
    "Jimmy Carter's coll.",
    "Ens. producer",
    "School for a future ens.",
    "Annapolis sch.",
    "Sch. for midshipmen",
    "Alma mater of Jimmy Carter: Abbr.",
    "Annapolis sch.",
    "Annapolis sch.",
    "Annapolis institution, for short",
    "Annapolis inits.",
    "Annapolis inits.",
    "Mil. institution since 1845",
    "Annapolis initials",
    "The Midshipmen: Abbr.",
    "Annapolis sch.",
    "Annapolis inits.",
    "Jimmy Carter alma mater: Abbr.",
    "Sch. founded in 1845",
    "Annapolis sch.",
    "Annapolis inits."
  ],
  "rely": [
    "Count",
    "Hinge (on)",
    "Count (on)",
    "Trust",
    "Hinge (on)",
    "Depend (on)",
    "Rest (on)",
    "Depend",
    "Hinge (on)",
    "Depend (on)",
    "Depend (on)",
    "Bank",
    "Hinge (on)",
    "Bank (on)",
    "Bank",
    "Depend",
    "Depend (on)",
    "Depend (on)",
    "Be dependent",
    "Depend (on)",
    "Bank (on)",
    "Hinge (upon)",
    "Bet (on)",
    "Count (on)",
    "Count (on)",
    "Depend (on)",
    "Depend",
    "Count (on)",
    "Count (on)",
    "Depend (on)",
    "Hinge",
    "Bank",
    "Have a dependency",
    "Fall back (on)",
    "Bank",
    "Lean",
    "Count",
    "Depend",
    "Lean (on)",
    "Trust, with \"on\"",
    "Depend (on)",
    "Depend (on)",
    "Count (on)",
    "Count (on)",
    "Depend",
    "Depend (on)",
    "Count (on)",
    "Be sure of, with \"on\"",
    "Depend (on)",
    "Count",
    "Count (on)",
    "Count (on)",
    "Lean",
    "Bank (on)",
    "Depend (on)",
    "Depend (on)",
    "Count",
    "Depend",
    "Count (on)",
    "Count (on)",
    "Bank",
    "Lean (on)",
    "Depend (on)",
    "Depend",
    "Bank (on)",
    "Trust, with \"on\"",
    "Lean (on)",
    "Count",
    "Be dependent",
    "Reckon (on)",
    "Depend (on)",
    "Bank",
    "Depend (on)",
    "Bank (on)",
    "Bank",
    "Lean (on)",
    "Depend (on)",
    "Have faith",
    "Be dependent",
    "Lean (on)",
    "Bank (on)",
    "Count (on)",
    "Depend (on)",
    "Swear by, with \"on\"",
    "Have trust",
    "Count",
    "Depend (on)",
    "Depend (on)",
    "Bank (on)",
    "Bank (on)",
    "Bank",
    "Depend (on)",
    "Count",
    "Depend (on)",
    "Bank",
    "Count (on)",
    "Bank",
    "Count"
  ],
  "frau": [
    "German title",
    "Madame, across the Rhine",
    "German title",
    "Mrs., in Munster",
    "Madame, across the Rhine",
    "Woman of the Haus",
    "Munich missus",
    "Mrs., abroad",
    "Mrs., abroad",
    "Lady of the Haus",
    "Mrs., in Munich",
    "Munster Mrs.",
    "German title",
    "One with a mister in Munster",
    "Herr's honey",
    "Lady of the Haus",
    "Herr's her",
    "Herr's wife",
    "___ Blucher (forbidding \"Young Frankenstein\" character)",
    "Lady of the Haus",
    "Munich Mrs.",
    "Lady of the Haus",
    "Married woman abroad",
    "Lady abroad",
    "Overseas Mrs.",
    "Herr's mate",
    "Berlin Mrs.",
    "German honey",
    "Lady of the Haus",
    "Teutonic title",
    "Mrs., in Munich",
    "Mrs. abroad",
    "Woman of the haus",
    "Woman of die Welt",
    "Swiss Mrs., maybe",
    "Herr's her",
    "Hessian title",
    "The lady of the Haus",
    "Mrs. Katzenjammer, e.g.",
    "German wife",
    "Mainz Mrs.",
    "Fritz's missus",
    "Lady of the Haus",
    "Haus wife",
    "Lady of the haus"
  ],
  "atty": [
    "Court fig.",
    "Profession for Elle Woods in \"Legally Blonde\": Abbr.",
    "J.D. holder: Abbr.",
    "One with briefs, briefly",
    "Court fig.",
    "Counsel: Abbr.",
    "Court fig.",
    "Lawyer: Abbr.",
    "Part of D.A.: Abbr.",
    "With 27-Down, firm figure: Abbr.",
    "Fiction's Atticus Finch, e.g.: Abbr.",
    "Trial fig.",
    "Courtroom fig.",
    "Adept arguer: Abbr.",
    "J.D. holder",
    "Firm part: Abbr.",
    "Court fig.",
    "Lincoln, e.g., before he was pres.",
    "Lawyer: Abbr.",
    "Case worker: Abbr.",
    "Advocate: Abbr.",
    "A.B.A. member: Abbr.",
    "Pro in briefs?: Abbr.",
    "Esq.",
    "Suspect's request: Abbr.",
    "Part of D.A.: Abbr.",
    "Firm member: Abbr.",
    "Lawyer: Abbr.",
    "A.B.A. member: Abbr.",
    "Trial fig.",
    "Suit maker: Abbr.",
    "Defendant's need: Abbr.",
    "Trial fig.",
    "Mason or McBeal: Abbr.",
    "Firm member: Abbr.",
    "Lawyer: Abbr.",
    "Dept. of Justice employee",
    "Lawyer: Abbr.",
    "Advocate: Abbr.",
    "One who's practicing: Abbr.",
    "One whose name can be followed by \"Esq.\": Abbr.",
    "Suit maker: Abbr.",
    "A.B.A. member: Abbr.",
    "A.B.A. member: Abbr.",
    "Brief writer: Abbr.",
    "Mason, e.g.: Abbr.",
    "One who works with a code: Abbr.",
    "Suspect's demand: Abbr.",
    "Ally McBeal, e.g.: Abbr.",
    "One into the bar scene?: Abbr.",
    "Bar member: Abbr.",
    "Certain partner: Abbr.",
    "\"The Practice\" role: Abbr.",
    "Ally McBeal, e.g.: Abbr.",
    "One with an \"Esq.\" tag",
    "Bar worker: Abbr.",
    "Reno, for one: Abbr.",
    "Bar assoc. member",
    "Bar member: Abbr.",
    "Dist. ___",
    "Dist. ___",
    "J.D. holder",
    "Lawyer: Abbr.",
    "Court sort, for short",
    "A.B.A. member",
    "___ Gen.",
    "Case worker: Abbr.",
    "Lawyer: Abbr.",
    "Lawyer: Abbr."
  ],
  "leno": [
    "2014 Television Hall of Fame inductee",
    "TV host with a \"Garage\"",
    "Jay between Johnny and Jimmy",
    "\"The Tonight Show\" host before and after O'Brien",
    "Jay formerly of late-night",
    "Jay formerly of late-night TV",
    "Jay who preceded Jimmy Fallon",
    "Successor of Carson",
    "TV host who won a Mark Twain Prize for American Humor",
    "Successor to Paar's successor",
    "Jay who preceded Jimmy Fallon",
    "Billy Crystal was his first guest",
    "Jay with jokes",
    "Jay once seen nightly",
    "Jay who preceded Jimmy",
    "Longtime host who wrote \"Leading With My Chin\"",
    "Longtime TV figure known for his garage",
    "Jay who preceded Jimmy Fallon",
    "Fallon predecessor",
    "Former late-night host Jay",
    "Longtime leader in late-night",
    "Jay formerly of late-night",
    "Former late-night luminary",
    "Jay formerly of \"The Tonight Show\"",
    "Deliverer of thousands of monologues",
    "James Douglas Muir ___ (TV host's birth name)",
    "2014 TV retiree",
    "Recently retired Jay",
    "Subject of the 1994 best seller \"The Late Shift\"",
    "*2014 TV retiree",
    "\"Leading With My Chin\" memoirist",
    "One side of a famous NBC feud",
    "Longtime \"Headlines\" reader",
    "TV host with a college degree in speech therapy",
    "TV monologist",
    "Late-night wars participant",
    "Funnyman Jay",
    "Jay who jests",
    "O'Brien's late-night predecessor and successor",
    "Who once remarked \"You can't stay mad at somebody who makes you laugh\"",
    "Noted reader of headlines",
    "Host of a nightly TV show taped in Burbank",
    "NBC host Jay",
    "A successor to 22-Across",
    "O'Brien's predecessor",
    "See 19-Across",
    "Funnyman Jay",
    "Star viewed at night",
    "Dyslexic TV host with a college degree in speech therapy",
    "Last name in TV talk",
    "\"You're not famous until my mother has heard of you\" quipper",
    "Longtime NBC host",
    "TV host known for his mandibular prognathism",
    "Jay that chatters",
    "Celebrity who testified at the 2005 Michael Jackson trial",
    "Late-night name",
    "\"Jaywalking\" personality",
    "Jay who does \"Jaywalking\"",
    "Longtime NBC star",
    "\"Nightline\" competitor, informally",
    "Entertainer who owns Big Dog Productions",
    "Nightly TV presence beginning 5/25/92",
    "Lantern-jawed Jay",
    "Monologist of note",
    "Late-night TV \"jaywalker\"",
    "\"Jaywalking\" guy",
    "Jay of late-night",
    "Big name in late-night",
    "\"Jaywalker\" of late-night TV",
    "TV comic who wrote \"If Roast Beef Could Fly\"",
    "Follower of the news",
    "Carson's successor",
    "Nightly monologue deliverer",
    "Nightly TV star beginning 5/25/92",
    "Lantern-jawed celeb",
    "Late-night funnyman",
    "Late-night host",
    "Late-night Jay",
    "Carson's successor",
    "He follows the news",
    "Jay watched by owls",
    "Late-night host",
    "Member of a long-running TV duel",
    "Late-night name",
    "TV host who wrote \"Leading With My Chin\"",
    "NBC host",
    "One who follows the news",
    "He's seen late",
    "Successor to Allen",
    "Lantern-jawed celeb",
    "Jay seen at night",
    "Letterman rival",
    "Letterman rival",
    "Late-night name",
    "Jay of NBC",
    "Someone to see before retiring?",
    "Carson's late-night successor",
    "Jay seen at night",
    "Noted monologuist",
    "Nightly comic",
    "TV host with bandleader Eubanks",
    "Jay of \"The Tonight Show\"",
    "Big-chinned chinner",
    "O'Brien's lead-in",
    "Major NBC star",
    "One who follows the news",
    "NBC host",
    "Letterman rival",
    "Late-night name",
    "Follower of the news",
    "Late-night host Jay",
    "Late-night name",
    "NBC stalwart",
    "Follower of the news?",
    "Jay who chins with guests",
    "Comic Jay",
    "Late-night regular",
    "Carson's successor",
    "Jester Jay",
    "Carson's successor",
    "Jay who has Monday night \"Headlines\"",
    "Big name in comedy",
    "Letterman rival",
    "TV host who does \"Headlines\"",
    "Carson successor",
    "He follows the news",
    "Late-night host",
    "Letterman rival",
    "Big star at night",
    "Letterman rival",
    "Late-night schmoozer Jay",
    "Jay___",
    "Late-night host",
    "He follows the news",
    "NBC star",
    "Jay of note",
    "Emcee Jay",
    "Late-night host",
    "Host Jay",
    "Open-weave fabric",
    "Late-night star",
    "Letterman rival",
    "Host Jay",
    "Late-night star"
  ],
  "hams": [
    "Spirals out over the winter holidays?",
    "Unlikely Oscar winners",
    "Overplays, with \"up\"",
    "They may be cured",
    "Overly theatrical sorts",
    "Bad actors",
    "Far from subtle actors",
    "Scenery chewers",
    "Christmas purchases",
    "Overactors",
    "Unsubtle performers",
    "They lack subtlety",
    "Prosciutto and others",
    "The Three Stooges, e.g.",
    "Certain radio enthusiasts",
    "On-air hobbyists?",
    "Easter roasts",
    "Easter roasts",
    "Soupy Sales and others",
    "Unlikely Oscar nominees",
    "Many skit actors",
    "Easter servings",
    "Actors who mug",
    "Scene stealers",
    "Master thespians they're not",
    "Muggers",
    "Backs of the thighs",
    "Hickory-smoked items",
    "Radio operators",
    "Back of the thigh",
    "Radio operators",
    "Radio amateurs",
    "Scenery chewers",
    "Sandwich meats",
    "Easter dinners",
    "Shortwavers"
  ],
  "gene": [
    "Splicing target",
    "Something editable using CRISPR technology",
    "Good name for a biology teacher?",
    "Kind of therapy",
    "___ therapy",
    "DNA carrier",
    "___ Roddenberry, first TV writer on the Hollywood Walk of Fame",
    "Subject of interest to a 23andMe user",
    "Code part",
    "Wilder who played Willy Wonka",
    "Tiny inheritance?",
    "Target of some therapy",
    "\"Dominant\" thing",
    "Chromosome component",
    "Something in a pool",
    "Transmitter of freckles or blond hair",
    "Bit of inheritance?",
    "Good name for someone tracing family history?",
    "DNA sequence",
    "Wilder who played Willy Wonka",
    "Target of splicing",
    "One with a feature role?",
    "Trait transmitter",
    "With 42-Down, \"Frosty the Snowman\" singer",
    "It's made up of DNA",
    "Allele, e.g.",
    "Heredity transmitter",
    "Code part",
    "Means of inheritance",
    "Part of a family inheritance",
    "Element of one's inheritance",
    "Controversially patented thing",
    "What you might have for bad eyesight",
    "Kind of pool",
    "Something passed down the line",
    "Trait origin",
    "Character builder?",
    "___ therapy",
    "Hereditary unit",
    "Heredity unit",
    "Replicator, e.g.",
    "*Not rough",
    "Trait transmitter",
    "Mapped item",
    "Chromosome component",
    "Pool item?",
    "Pulitzer-winning journalist Weingarten",
    "Blood type determinant",
    "Means of inheritance",
    "Characteristic dictator",
    "Part of one's inheritance",
    "There's one for curly hair",
    "Heredity unit",
    "Part of a pool",
    "Part of some pools",
    "Characteristic governor",
    "One that's passed along",
    "Means of inheritance",
    "Family hand-me-down?",
    "Chromosome part",
    "Part of a code",
    "There might be one for depression",
    "DNA element",
    "With 44-Across, Champion rider",
    "Chromosome carrier",
    "Chromosome part",
    "Subject of modern \"mapping\"",
    "\"The Match Game\" host Rayburn",
    "Trait carrier",
    "Trait carrier",
    "Intron and exon site",
    "Heredity unit",
    "It may be given from father to son",
    "Component of some pools",
    "Chromosome constituent",
    "Hereditary ruler?",
    "Pool part?",
    "Filial inheritance",
    "It might make the hair blond",
    "It has a code",
    "Characteristic carrier",
    "Good name for a DNA expert",
    "Characteristic carrier",
    "Heredity unit",
    "Spliced item",
    "Heredity carrier",
    "It may be dominant",
    "Heredity carrier",
    "Hackman of Hollywood",
    "It has a code",
    "Characteristic carrier",
    "Pool member",
    "DNA carrier",
    "Provider of coded instructions",
    "Kind of therapy",
    "___ pool",
    "Part of an inheritance?",
    "Inheritance factor",
    "___ splicing",
    "Part of a code",
    "Kind of therapy",
    "Blue eyes producer, maybe",
    "Trait carrier",
    "Heredity transmitter",
    "Something to splice",
    "Item in a pool",
    "Trait determinant",
    "Kind of therapy",
    "Kind of therapy",
    "Hair coloring carrier",
    "Kind of pool",
    "Biological trait carrier",
    "It may be dominant",
    "Character builder?",
    "Trait carrier",
    "Subject of passing concern?",
    "Kind of pool",
    "Actor Wilder",
    "Barry of \"Bat Masterson\"",
    "It helps build character",
    "Means of inheritance",
    "It may be dominant",
    "Appearance determinant",
    "An Autry",
    "20?Æs heavyweight Tunney",
    "Hereditary factor",
    "Subject of some engineering",
    "Allele",
    "___ mapping (modern science effort)",
    "It builds character",
    "DNA component",
    "Molecular biology topic",
    "Mr. McCarthy, familiarly",
    "Kind of therapy",
    "Personality determinant",
    "Kind of pool",
    "Kind of pool"
  ],
  "damp": [
    "Like dewy grass",
    "Conducive to mold, maybe",
    "Like a just-used towel",
    "Still on the line, say",
    "Like some cellars",
    "Wettish",
    "Clammy",
    "Deaden acoustically",
    "Like unfinished laundry",
    "Clammy",
    "Moist",
    "Like morning grass, typically",
    "Wettish",
    "Not dry",
    "Wettish",
    "Subject to mildew, perhaps",
    "Moist",
    "Requiring more time in the dryer",
    "Clammy",
    "Like many a cellar",
    "Drizzly",
    "Humid",
    "Steamy",
    "Not all wet?",
    "Like many a cellar",
    "Still drying",
    "Like some cellars",
    "Wettish",
    "Wettish",
    "Like many a cellar",
    "Extinguish",
    "Dewy",
    "Extinguish",
    "Like some basements",
    "Slightly wet",
    "Ideal, as greenhouse soil",
    "Mine vapor",
    "Not quite dry",
    "Dewy",
    "Moist",
    "Retardant"
  ],
  "avon": [
    "Cosmetics giant",
    "Door-to-door sales company",
    "Bath water?",
    "Enterprise once known as the California Perfume Company",
    "Stream of Shakespeare",
    "Stratford-upon-___",
    "Stratford-upon-___",
    "Bell-ringing makeup company",
    "Longtime name in cosmetics",
    "Company with a for-profit foundation?",
    "Bard of ___",
    "Bard of ___ (Shakespeare)",
    "Big name in cosmetics",
    "Big publisher of romance novels",
    "Door-to-door giant",
    "Shakespeare's stream",
    "Classic paperback publisher",
    "Bell-ringing cosmetics company",
    "Shakespeare's stream",
    "\"___ calling\"",
    "River through Bath",
    "Stratford-upon-___",
    "Maker of Scentini fragrances",
    "River through Bath, England",
    "Shakespeare's stream",
    "___ Barksdale, drug dealer on \"The Wire\"",
    "Makeup maker",
    "What makes consumers blush?",
    "Publisher of the old All-True Detective Cases comic books",
    "Stratford-upon-___",
    "Stratford-upon-___",
    "Mary Kay rival",
    "Skin So Soft maker",
    "Shakespeare's stream",
    "Stratford-upon-___",
    "Call girl employer?",
    "River through Bristol",
    "Beauty care brand",
    "___-by-the-Sea, N.J.",
    "River through Bristol",
    "River through Bath",
    "Stratford-upon-___",
    "Drug dealer on \"The Wire\"",
    "Sweet Swan of ___ (epithet for Shakespeare)",
    "\"___ calling!\"",
    "Classic publisher of paperbacks",
    "___ lady (doorbell ringer)",
    "Paperback publisher since 1941",
    "Anthony Eden, Earl of ___",
    "Big name in paperback publishing",
    "Stratford-upon-___",
    "Sir Anthony Eden, 1st Earl of ___",
    "Stratford-upon-___",
    "___ Lady (decades-old commercial creation)",
    "The Bard of ___ (Shakespeare)",
    "\"Thou soft-flowing\" stream of literature",
    "River beside the Royal Shakespeare Theatre",
    "Brand name that might ring a bell?",
    "Stratford-upon-___",
    "Earl of ___ (Sir Anthony Eden)",
    "Connecticut town named for an English river",
    "Cosmetics giant",
    "It has ringers on its team",
    "Major publisher of romance novels",
    "Company calling?",
    "Classic door-to-door marketer",
    "The Bard of ___ (Shakespeare)",
    "Bard of ___",
    "Bell-ringing cosmetics company",
    "Bard of ___ (Shakespeare)",
    "Skin So Soft seller",
    "Massachusetts town named for a river in England",
    "Mary Kay competitor",
    "Foundation maker",
    "___ lady",
    "Skin So Soft maker",
    "\"The company for women\" sloganeer",
    "\"___ calling!\"",
    "With 68-Across, bell ringer",
    "Mary Kay rival",
    "Bell-ringing company",
    "The Bard's river",
    "Literary stream",
    "Doorbell-ringing company",
    "Shakespeare's stream",
    "Mary Kay rival",
    "It's known for its bell ringers",
    "Major paperback publisher",
    "\"Soft flowing\" stream of poetry",
    "Shakespeare's stream",
    "Shakespeare, the Bard of ___",
    "Stratford's stream",
    "Bard's river",
    "Bard of ___",
    "Foundation producer",
    "English river",
    "Stream of literature",
    "\"___ calling\"",
    "Stratford's stream",
    "Mary Kay competitor",
    "Stratford's river",
    "Big publisher of romance novels",
    "Shakespearean stream",
    "Stratford's river",
    "37-Across was its first earl",
    "Bard of ___",
    "River through Wiltshire",
    "Bristol's county",
    "Bristol's county, in England",
    "It's known for its bell ringers",
    "Bell-ringer of commercialdom",
    "\"___ calling\"",
    "Name that rings a bell?",
    "Its calling is calling",
    "Stratford-upon-___",
    "Stratford's stream",
    "Hearst book division",
    "Revlon rival",
    "Mary Kay competitor",
    "Big paperback publisher",
    "Hearst's ___ Books",
    "Mary Kay competitor",
    "Stratford's stream",
    "Bard's river",
    "It has a calling",
    "Stratford's stream",
    "English Channel feeder",
    "Stratford's stream",
    "Bard's stream",
    "Bell ringer",
    "Bath's county",
    "\"Lucid\" stream, to Thomas Gray",
    "(Ding-dong) \"___ calling\"",
    "River at Bristol",
    "Calling company?",
    "Shakespeare, the Bard of ___",
    "Big name in cosmetics",
    "Bath's county",
    "Calling company?",
    "The Bard's river",
    "View from Stratford",
    "Stratford's river",
    "Bard's river",
    "\"Sweet Swan of ___!\": Jonson",
    "Door-to-door cosmetics company",
    "Frequent caller?",
    "Mary Kay competitor",
    "River to the Severn",
    "Stratford's river",
    "Popular theater name",
    "Stratford's river",
    "Noted paperback publisher",
    "Warwick's river",
    "Stratford's river",
    "Cosmetic name",
    "\"___ calling\"",
    "Jonson's \"Sweet Swan of ___!\"",
    "Stratford stream",
    "Calling company"
  ],
  "gato": [
    "Cat, in Catalonia",
    "Feline: Sp.",
    "Cancun kitty",
    "Cat, to Catarina",
    "Purrer in Peru",
    "Chat, across the Pyrenees",
    "Animal in una casa",
    "Kitty, in Segovia",
    "Cancun kitty",
    "Cat in una casa",
    "Chihuahua cat",
    "Mexican mouse chaser",
    "Mexican mouse catcher",
    "Mouse catcher, in Madrid",
    "Cordoba cat",
    "Pet in a casa",
    "Raton chaser",
    "Meower, in Madrid",
    "Catarina's cat",
    "Cat, in Castile"
  ],
  "levi": [
    "___ Eshkol, third prime minister of Israel",
    "Jeans maker Strauss",
    "One of Jacob's 12 sons",
    "Dolly's last name in \"Hello, Dolly!\"",
    "One of the 12 tribes of Israel",
    "Tribe of Moses and Aaron",
    "Blue jeans pioneer Strauss",
    "Jeans maker Strauss",
    "Blue jeans pioneer Strauss",
    "___ Strauss & Co.",
    "___ Strauss",
    "___ Strauss & Co.",
    "1880s-'90s veep ___ P. Morton",
    "Big name in denim",
    "Israelite tribe progenitor",
    "___ Strauss & Co. (jeans maker)",
    "Founder of one of the 12 tribes of Israel",
    "___ Strauss & Co.",
    "Big name in jeans",
    "One of the 12 tribes of Israel",
    "___ Johnston, former fiance of Bristol Palin",
    "Jeans maker Strauss",
    "Italian writer Primo",
    "___ Strauss jeans",
    "Veronese masterpiece \"The Feast in the House of ___\"",
    "___ Strauss jeans",
    "Champion cyclist Leipheimer",
    "Dolly the matchmaker",
    "Italian author Primo",
    "Big name in denim",
    "Johnston in 2008-09 news",
    "Jeans maker ___ Strauss",
    "___ Strauss jeans",
    "Denim pioneer Strauss",
    "Benjamin Harrison's vice president, ___ P. Morton",
    "Strauss of jeans",
    "Son of Leah",
    "Singer Stubbs of the Four Tops",
    "___ Strauss & Co.",
    "___ Strauss jeans",
    "First name among clothiers",
    "Son of Leah",
    "1890's veep ___ P. Morton",
    "___ Strauss & Co.",
    "Big name in denim",
    "Son of Leah",
    "Strauss of jeans fame",
    "\"The Periodic Table\" author Primo ___",
    "Strauss of denim",
    "Dolly of \"Hello, Dolly!\"",
    "Jeans purveyor Strauss",
    "Forebear of one of Israel's 12 Tribes",
    "Author of \"Christ Stopped at Eboli\"",
    "Son of Jacob and Leah",
    "Carlo who wrote \"Christ Stopped at Eboli\"",
    "___ Strauss & Co.",
    "Stubbs of the Four Tops",
    "Son of Jacob",
    "First name in jeans",
    "Former Israeli leader ___ Eshkol",
    "Jeans maker Strauss",
    "Ford attorney general Edward ___",
    "First name in jeans",
    "Dolly of \"Hello, Dolly!\"",
    "___ P. Morton (1890's veep)",
    "Israel's Eshkol",
    "1889 Vice President ___ P. Morton",
    "\"The Drowned and the Saved\" author",
    "___ Strauss & Co.",
    "Abolitionist Coffin",
    "Dolly of \"Hello, Dolly!\"",
    "1890's Veep ___ P. Morton",
    "Dolly ___ of \"Hello, Dolly!\"",
    "___ Strauss & Co.",
    "___ Strauss & Co.",
    "Dolly ___ of \"Hello, Dolly!\"",
    "___ Strauss & Co.",
    "First name in jeans",
    "First name in jeans",
    "Founder of one of the 12 tribes",
    "Son of Jacob and Leah",
    "___ Strauss jeans",
    "___ Strauss (jeans maker)",
    "1890's Vice President ___ P. Morton",
    "Four Tops leader___Stubbs",
    "Manufacturer Strauss",
    "Jeans maker Strauss",
    "Matthew, originally",
    "1890's Vice President ___ P. Morton",
    "Jeans maker Strauss"
  ],
  "niki": [
    "Caro who directed 2020's \"Mulan\"",
    "Director Caro",
    "Supermodel Taylor"
  ],
  "opts": [
    "Picks",
    "Goes (for)",
    "Decides",
    "Chooses",
    "Exercises one's discretion",
    "Decides",
    "Picks, with \"for\"",
    "Chooses, with \"for\"",
    "Makes a choice",
    "Chooses, with \"for\"",
    "Chooses",
    "Chooses, with \"for\"",
    "Goes (for)",
    "Chooses (to)",
    "Chooses",
    "Picks, with \"for\"",
    "Chooses",
    "Decides one way or the other",
    "Withdraws, with \"out\"",
    "Pulls (out of)",
    "Stops waffling",
    "Chooses (to)",
    "Elects (to)",
    "Decides (to)",
    "Chooses a course",
    "Goes (for)",
    "Decides (to)",
    "Elects",
    "Chooses, with \"for\"",
    "Goes (for)",
    "Makes a choice",
    "Goes (for)",
    "Makes a pick",
    "Pulls (out)",
    "Decides",
    "Declines, with \"out\"",
    "Makes a choice",
    "Selects, with \"for\"",
    "Fishes or cuts bait, say",
    "Declines, with \"out\"",
    "Elects",
    "Plays, with \"in\"",
    "Decides",
    "Doesn't decline, with \"in\"",
    "Chooses",
    "Declines, with \"out of\"",
    "Withdraws, with \"out\"",
    "Stops waffling",
    "Gets off the fence",
    "Takes, with \"for\"",
    "Chooses, with \"for\"",
    "Takes, with \"for\"",
    "Chooses",
    "Chooses",
    "Withdraws, with \"out\"",
    "Chooses, with \"for\"",
    "Takes, with \"for\"",
    "Withdraws, with \"out\"",
    "Goes (for)",
    "Goes (for)",
    "Makes up one's mind (to)",
    "Picks, with \"for\"",
    "Elects",
    "Chooses (to)",
    "Goes (for)",
    "Takes a course",
    "Prefers, with \"for\"",
    "Checks a particular box",
    "Chooses, with \"for\"",
    "Chooses",
    "Withdraws, with \"out\"",
    "Goes (for)",
    "Picks",
    "Pulls (out)",
    "Declines to take part, with \"out\"",
    "Decides",
    "Makes a choice [and one more example of this puzzle's theme]",
    "Prefers, with \"for\"",
    "Chooses",
    "Makes a choice (for)",
    "Goes (for)",
    "Decides",
    "Makes a choice",
    "Chooses, with \"for\"",
    "Selects, with \"for\"",
    "Chooses",
    "Makes choices",
    "Chooses",
    "Chooses",
    "Picks one",
    "Votes (for)",
    "Co-___ (appropriates)",
    "Gets off the fence, so to speak",
    "Withdraws, with \"out\"",
    "Chooses",
    "Chooses",
    "Elects",
    "Decides",
    "Makes a choice",
    "Chooses",
    "___out (withdraws)",
    "Withdraws, with out",
    "Picks, with \"for\"",
    "Chooses",
    "___ out (withdraws)",
    "Goes (for)",
    "Goes (for)"
  ],
  "tied": [
    "Without a leader?",
    "10 to 10, say",
    "One up, for example",
    "Knotted (up)",
    "One-to-one, say",
    "Got even with",
    "Headed for overtime",
    "50-50, say",
    "Caught up to, say",
    "Headed for overtime",
    "Not up or down",
    "Going into extra innings",
    "Even",
    "Even",
    "Even",
    "Like the score 7-7",
    "One to one, say",
    "Caught up to, in a way",
    "20-20, e.g.",
    "Bound",
    "One to one, for example",
    "Like games that head into overtime",
    "Even",
    "Heading into overtime",
    "Knotted up",
    "Even",
    "Headed for overtime",
    "One all, say",
    "Even",
    "Like a game heading into extra innings",
    "Undecided, you might say",
    "The same, in a way",
    "One-to-one, e.g.",
    "Even",
    "Drawn",
    "Lashed (up)",
    "Knotted up",
    "Ready to go into overtime",
    "Headed for overtime",
    "50-50, say",
    "Laced up",
    "Having no loose ends",
    "20-20, e.g.",
    "All square",
    "Even-steven",
    "Needing overtime",
    "Seven up, e.g.",
    "Requiring sudden death",
    "Even",
    "Lashed",
    "Headed for overtime",
    "At deuce",
    "Ready for overtime",
    "Like a 4-4 score",
    "Seven up, e.g.",
    "In a bind",
    "Bound",
    "Related (to)",
    "Even",
    "Pulled some strings",
    "Headed for overtime",
    "Even",
    "Bound",
    "Even",
    "Bound"
  ],
  "elmo": [
    "Love to Hug ___ (plush doll)",
    "Friend of Cookie Monster",
    "Muppet who once helped Emeril on \"Emeril Live\"",
    "Resident at 123 Sesame Street",
    "Muppet who sings in the \"Try, Try Again\" song",
    "Furry red monster of children's TV",
    "Friend of Mr. Noodle on children's TV",
    "Red Muppet on \"Sesame Street\"",
    "One of Zoe's best friends on \"Sesame Street\"",
    "Muppet with a falsetto",
    "Orange-nosed Muppet",
    "Red Muppet",
    "Muppet with a smartphone friend named Smartie",
    "Falsetto-voiced Muppet",
    "Redhead on kids' TV",
    "Ticklish Muppet",
    "\"Tickle me\" doll",
    "Kids' TV character who speaks in a falsetto",
    "Muppet who refers to himself in the third person",
    "\"Sesame Street\" monster",
    "Monster of TV",
    "Ticklish Muppet",
    "Furry, red TV character",
    "Dagwood's bratty neighbor",
    "Adventurer in Grouchland",
    "Falsetto-voiced Muppet",
    "Little red monster",
    "\"Tickle Me\" doll",
    "Kids' TV character who refers to himself in the third person",
    "Red Muppet on \"Sesame Street\"",
    "Mr. Noodle's friend on \"Sesame Street\"",
    "Muppet who speaks in a falsetto",
    "Ticklish \"Sesame Street\" character",
    "Adventurer in Grouchland in a 1999 film",
    "Adorable \"Sesame Street\" monster",
    "Redhead on kids' TV",
    "Muppet with an orange nose",
    "Children's TV character who refers to himself in the third person",
    "Admiral Zumwalt",
    "Muppet with his own \"World\"",
    "\"Tickle Me\" toy",
    "Furry red monster on \"Sesame Street\"",
    "Red three-year-old of TV",
    "Ticklish red Muppet",
    "Falsetto-voiced Muppet",
    "Journeyer through Grouchland, in a 1999 film",
    "Saint who is one of the Fourteen Holy Helpers",
    "Admiral Zumwalt",
    "Pollster Roper",
    "Neighbor of Dagwood, in the funnies",
    "Patron saint of sailors",
    "Sailors' saint",
    "Interrupter of Dagwood's naps",
    "Pal of the starts of 17-, 24-, 40-, 52- and 66-Across",
    "Friend of Zoe and Abby",
    "Patron saint of sailors",
    "\"Tickle Me\" doll",
    "Patron saint of sailors",
    "Tickle Me ___",
    "Adm. Zumwalt, chief of naval operations during the Vietnam War",
    "Citizen of Sesame Street",
    "Must-have toy of 1996",
    "Muppet with a goldfish named Dorothy",
    "Red-haired PBS star",
    "Googly-eyed Muppet",
    "Sailor's saint",
    "Patron saint of sailors",
    "Pollster Roper",
    "Pollster Roper",
    "Red Muppet",
    "Ticklish one?",
    "Mr. Noodle's friend on \"Sesame Street\"",
    "\"Blondie\" tyke",
    "Red resident of Sesame Street",
    "Pollster Roper",
    "Sailor's saint",
    "Friend of Dorothy, on \"Sesame Street\"",
    "\"Redhead\" on \"Sesame Street\"",
    "\"Sesame Street\" character with a goldfish named Dorothy",
    "\"Tickle me\" doll",
    "Sailor's patron saint",
    "Ticklish muppet",
    "\"Tickle me\" guy",
    "Adm. Zumwalt",
    "See 52-Across",
    "Sailor's saint",
    "Zoe's friend, on \"Sesame Street\"",
    "\"Tickle me\" guy",
    "A Muppet",
    "Adm. Zumwalt",
    "Kids' TV character voiced by Kevin Clash",
    "Red \"Sesame Street\" character",
    "Muppet who's tickled",
    "Sailors' saint",
    "Patron saint of sailors",
    "A Sesame Streeter",
    "Ticklish one",
    "Pollster Roper",
    "Friend of Big Bird",
    "Tickle Me ___",
    "Fad doll, once",
    "A Muppet",
    "Ticklish one?",
    "\"Tickle me\" doll",
    "\"Blondie\" boy",
    "\"Tickle Me\" boy",
    "Neighbor of the Bumsteads",
    "Adm. Zumwalt",
    "Tarzan player ___ Lincoln",
    "Rosie O'Donnell's Muppet friend",
    "Ticklish doll guy",
    "\"Blondie\" boy",
    "One of the Muppets",
    "\"Tickle-me\" character",
    "Ticklish fellow",
    "\"Tickle me\" doll",
    "Pollster Roper",
    "\"Blondie\" kid",
    "Adm. Zumwalt",
    "Tickle Me doll",
    "Red Muppet",
    "Tarzan portrayer Lincoln",
    "Tickle-me doll",
    "Ticklish one",
    "\"Sesame Street\" Muppet",
    "Giggler of Sesame Street",
    "One who's tickled",
    "One of the Muppets",
    "\"Tickle me\" doll",
    "Pollster Roper",
    "\"Tickle Me\" doll",
    "Early Tarzan player Lincoln",
    "\"Blondie\" character",
    "Naples's Castle of St. ___",
    "Adm. Zumwalt",
    "One of the Muppets",
    "Adm. Zumwalt",
    "Aden. Zumwalt",
    "Pollster Roper",
    "Admiral Zumwalt",
    "Movie Tarzan ___Lincoln"
  ],
  "noel": [
    "December number",
    "Name derived from the Latin for \"to be born\"",
    "Christmas carol",
    "Christmas season",
    "Word repeatedly sung before \"Born is the king of Israel\"",
    "Yuletide",
    "Season to be full of cheer",
    "Holiday song",
    "\"The First ___\" (carol)",
    "Seasonal song",
    "\"Do You Hear What I Hear?,\" e.g.",
    "Christmas carol",
    "Winter air",
    "Time of good cheer",
    "London's ___ Coward Theater",
    "Holiday air",
    "'Tis the season",
    "Yule tune",
    "Rhyme for \"Israel,\" in a carol",
    "Word sung four times before \"Born is the King of Israel\"",
    "\"Angels We Have Heard on High,\" e.g.",
    "Holiday-time song",
    "\"O Sanctissima,\" e.g.",
    "Festive season",
    "Coward who said \"I love criticism just so long as it's unqualified praise\"",
    "Season to be jolly",
    "\"We Three Kings of Orient Are,\" e.g.",
    "Coward from England",
    "Wassailer's tune",
    "\"Christians Awake,\" e.g.",
    "Carol",
    "Yuletide song",
    "Christmas song",
    "\"O Come, All Ye Faithful,\" e.g.",
    "Time to stuff stockings",
    "\"Angels From the Realms of Glory,\" e.g.",
    "\"Joy to the World,\" for one",
    "Christmas song",
    "Rex Harrison's singer/actor son",
    "Christmas",
    "\"Frosty\" air?",
    "Seasonal song ... or a phonetic hint to 18-, 23-, 37-, 52- and 59-Across",
    "Holiday time",
    "Winter air",
    "Good name for someone born on Dec. 25",
    "\"Silent Night\" or \"Away in a Manger\"",
    "Holiday number",
    "Winter air",
    "\"The Seven Joys of Mary,\" e.g.",
    "\"Joyeux\" time",
    "Seasonal air",
    "Coward with a pen",
    "Year-end air",
    "Christmas carol",
    "Song sung around Christmas",
    "Advent number",
    "\"The first ___, the angel did say ...\"",
    "Advent song",
    "Coward of the theater",
    "December air",
    "\"Away in a Manger,\" for one",
    "Yuletide",
    "Coward of the stage",
    "\"O Sanctissima,\" e.g.",
    "Seasonal song",
    "Carol",
    "Coward of note",
    "Christmas song",
    "Family-gathering time",
    "Present time in France?",
    "\"The First ___\"",
    "Christmas carol",
    "Song by candlelight",
    "Song sung on doorsteps",
    "December air",
    "Seasonal air",
    "Advent song",
    "Wassailer's song",
    "Christmas song",
    "Season to be jolly",
    "Period ending January 6",
    "Holiday tune",
    "Season for carols",
    "Song sung by candlelight, perhaps",
    "Holiday song",
    "December air",
    "\"Joyeux ___\" (French greeting)",
    "Season of giving",
    "Christmas season",
    "Time for eggnog",
    "Christmas",
    "Yuletide",
    "Seasonal tune",
    "Winter air",
    "Carol",
    "Choral rendition",
    "Christmas song",
    "\"O Sanctissima,\" e.g.",
    "Present time",
    "Carol",
    "Stocking-up time?",
    "Yuletide",
    "Carol",
    "Sophisticated Coward",
    "Wassailer's song",
    "Holiday song",
    "Winter air",
    "Christmas",
    "December air",
    "Carol",
    "Christmastime",
    "Winter air",
    "Christmas carol",
    "\"The Seven Joys of Mary,\" e.g.",
    "Winter holiday",
    "Carol",
    "December air",
    "Coward of note",
    "Christmas song",
    "Christmas carol",
    "Season of peace",
    "Christmas carol",
    "Present time",
    "Christmas",
    "Winter time",
    "Winter air",
    "\"Joyeux___\"",
    "Carol",
    "Yuletide",
    "Holiday song",
    "Carol",
    "Author Behn",
    "Winter air",
    "Coward of films",
    "Holiday season",
    "Carol",
    "\"The First ___\"",
    "\"The First___\""
  ],
  "avec": [
    "With, in France",
    "With, at a cafe",
    "With, en francais",
    "Con, across the Pyrenees",
    "Pas sans",
    "\"___ les compliments de l'auteur\" (inscription in a French book)",
    "With: Fr.",
    "Opposite of sans",
    "\"Voulez-vous coucher ___ moi ce soir?\"",
    "\"___ plaisir!\"",
    "With, to Renoir",
    "\"___ plaisir\"",
    "With: Fr.",
    "With: Fr.",
    "Opposite of sans",
    "\"Voulez-vous coucher ___ moi?\" (lyric in a 1975 #1 hit)",
    "\"Mit,\" across the Rhine",
    "With, on le menu",
    "With: Fr.",
    "\"Voulez-vous coucher ___ moi ce soir?\"",
    "Opposite of sans",
    "With: Fr.",
    "French word whose opposite is 66-Across",
    "Tours \"with\"",
    "___ plaisir",
    "Opposite of sans",
    "French preposition",
    "Matisse's \"Jeune fille ___ tiare\"",
    "___ raison (sensible: Fr.)",
    "With: Fr.",
    "\"___ votre permission\"",
    "Picasso's \"Colombe ___ fleurs\"",
    "With: Fr.",
    "With: Fr.",
    "Opposite of sans",
    "Opposite of sans",
    "___ permission",
    "Paul Valery's \"La soiree ___ M. Teste\"",
    "Le menu word",
    "Opposite of sans",
    "\"___ plaisir\"",
    "\"___ plaisir\"",
    "___ plaisir",
    "Word on la carte",
    "\"___ plaisir, monsieur\"",
    "\"___ plaisir!\"",
    "With: Fr.",
    "Tours with?",
    "With, to Maurice",
    "With: Fr.",
    "With, en francais",
    "___ plaisir",
    "With: Fr.",
    "\"___ ca!\" (\"Nonsense!\"): Fr.",
    "Tours with?",
    "___ plaisir",
    "With: Fr.",
    "Opposite of sans",
    "___plaisir",
    "___ votre permission",
    "Opposite of sans",
    "French for 52 Across",
    "Opposite of sans",
    "With: Fr.",
    "With: Fr."
  ],
  "mere": [
    "\"___ Christianity\" (C. S. Lewis book)",
    "Slight",
    "___ mortals",
    "___ mortal",
    "Little more than",
    "No more than",
    "Measly",
    "Simple",
    "___ mortals",
    "Just",
    "Piddling",
    "Insignificant",
    "Little more than",
    "Simple",
    "Nothing more than",
    "Pure and simple",
    "Nothing more than",
    "Only",
    "Slight",
    "Just",
    "Only",
    "Parisian mother",
    "___ mortal",
    "Just",
    "Simple",
    "Not more than",
    "A ___ pittance",
    "Just",
    "Little more than",
    "Piddling",
    "Piddling",
    "Nothing but",
    "Plain and simple",
    "Simple",
    "No better than",
    "Nothing more than",
    "Insignificant",
    "___ mortal",
    "Enfant bearer",
    "Pure and simple",
    "Trifling",
    "Trivial",
    "Simple",
    "Simple",
    "Just",
    "Trifling",
    "Nothing more than",
    "\"A ___ technicality!\"",
    "Slight",
    "Nothing but",
    "Nothing more than",
    "\"A ___ bagatelle!\"",
    "Piddling",
    "Piddling",
    "Plain and simple",
    "Pure",
    "French family member",
    "Piddling",
    "Petty",
    "Simple",
    "Insignificant",
    "Pond, in Liverpool",
    "Piddling",
    "Slight",
    "Simple",
    "Slight",
    "Simple",
    "No more than",
    "Plain and simple",
    "Being nothing more than",
    "Plain and simple",
    "Only",
    "Insignificant",
    "Exclusive of anything else",
    "Trifling",
    "Paltry",
    "Paltry",
    "___ mortals",
    "\"A ___ formality!\"",
    "Nothing more than",
    "Little more than",
    "Nothing more than",
    "Kind of technicality?",
    "Paltry",
    "Nothing but",
    "Simple",
    "Nothing more than",
    "Insignificant",
    "Simple",
    "A ___ child",
    "Insignificant",
    "Poetic pond",
    "No more than",
    "Just",
    "Nothing other than",
    "A ___ technicality",
    "\"A ___ formality\"",
    "Bare",
    "Slight",
    "___ mortals",
    "Simple",
    "A ___ pittance",
    "Insignificant",
    "French parent",
    "Bebe watcher",
    "A ___ pittance",
    "Simple",
    "Unimportant",
    "Nothing more than",
    "French mother",
    "Parisian parent",
    "Nothing more than",
    "A ___ bagatelle",
    "Scant",
    "Mademoiselle's mother",
    "Bare",
    "Only",
    "Marseilles mother",
    "Just",
    "Only",
    "Just",
    "Slight",
    "Word before pittance",
    "Sea, in old verse",
    "Pool, in poetry"
  ],
  "walt": [
    "Frazier in the Basketball Hall of Fame",
    "\"Breaking Bad\" protagonist",
    "Poet Whitman",
    "Cartoonist Kelly",
    "\"Breaking Bad\" protagonist",
    "Poet Whitman",
    "___ Disney Company",
    "First name in popular shorts",
    "Cartoonist Kelly",
    "Good call letters for Radio Disney?",
    "Philadelphia's ___ Whitman Bridge",
    "___ Disney Pictures",
    "Kelly or Whitman",
    "Poet Whitman",
    "Wall Street Journal columnist Mossberg",
    "Disney or Whitman",
    "First name in animation",
    "Disney, for one",
    "\"Pogo\" cartoonist Kelly",
    "Mickey's creator",
    "Animator Disney",
    "Cartoonist Kelly",
    "With 96-Down, movie pioneer",
    "Creator of Mickey and Goofy",
    "Poet Whitman"
  ],
  "cola": [
    "Pop choice",
    "Pop variety",
    "Fountain option",
    "Drink such as Pepsi",
    "What a fountain may provide",
    "Flavor of some bottle-shaped gummies",
    "Mixer option",
    "Mixer option",
    "Pepsi-___",
    "Pop, maybe",
    "Pepsi, for one",
    "Common bar mixer",
    "Pez flavor",
    "Pepsi, e.g.",
    "___ wars (longtime advertising battle)",
    "Ingredient in a Roy Rogers",
    "Pepsi, e.g.",
    "RC, for one",
    "RC, for one",
    "Pop",
    "Pepsi, for one",
    "It's made with syrup",
    "___ wars",
    "Bar mixer",
    "Pepsi-___",
    "Pepsi-___",
    "Cuba libre ingredient",
    "Slurpee flavor",
    "Coca-___",
    "Coca-___",
    "See 43-Across",
    "Common part of a Happy Meal",
    "Buzz on \"The Simpsons,\" e.g.",
    "Soda fountain option",
    "Pepsi, e.g.",
    "Mixer choice",
    "Aerated beverage",
    "Pepsi-___",
    "It's no good when it's flat",
    "RC, e.g.",
    "Dark quaff",
    "Cherry ___",
    "Mixer",
    "Caffeine source",
    "___ + grenadine + maraschino cherry = Roy Rogers cocktail",
    "Mixer option",
    "Popular pop",
    "Pay increase to keep up with inflation, in brief",
    "Fast-food drink",
    "RC or Jolt",
    "Drink mixer",
    "Pepsi, e.g.",
    "Pepsi, but not 7-Up",
    "Cuba libre ingredient",
    "Carbonated choice",
    "RC, e.g.",
    "Fizzy drink",
    "Bubbly drink",
    "Pepsi, for one",
    "Cherry ___",
    "RC, for one",
    "Coke or Pepsi",
    "Pepsi-___",
    "It may be decaffeinated",
    "Float's base, maybe",
    "With 13-Down, they may include taste tests",
    "Drink with fast food",
    "What 7-Up isn't",
    "Pepsi, e.g.",
    "Cuba libre ingredient",
    "Pepsi or RC",
    "Coke, for one",
    "Pepsi or RC",
    "Cuba libre ingredient",
    "RC, e.g.",
    "See 4-Down",
    "Pepsi, for one",
    "Two-liter bottle contents",
    "Caffeine-yielding nut",
    "7-Up alternative",
    "Fizzy drink",
    "Cherry ___",
    "Snack bar drink",
    "Pepsi, e.g.",
    "Caffeine source",
    "Teetotaler's choice",
    "Carbonated canful",
    "Pepsi, for one",
    "Fountain choice",
    "Soft drink",
    "Caffeine source",
    "Brown beverage",
    "Pop",
    "Pepsi, e.g.",
    "Drink type",
    "Pepsi, e.g.",
    "R.C., e.g.",
    "Fizzy drink",
    "Some kind of a nut"
  ],
  "kelp": [
    "Plantlike growth held up by gas-filled bladders",
    "Food for a sea urchin",
    "Part of a forest that can grow as fast as two feet a day",
    "Aquatic source of iodine",
    "Growth in an underwater \"forest\"",
    "Marine growth rich in iodine",
    "Edible seaweed",
    "Seaweed used as food",
    "Seaweed variety",
    "Large brown algae",
    "Underwater growth",
    "Food for sea urchins",
    "Sea palm or badderlocks",
    "Sustenance for a sea urchin",
    "Food for sea urchins",
    "Sea food?",
    "Health food",
    "Edible seaweed",
    "Seaweed",
    "Health food from the sea",
    "Iodine source"
  ],
  "rumi": [
    "13th-century Persian mystic who is one of the best-selling poets in the U.S."
  ],
  "vino": [
    "Meal accompaniment at a trattoria",
    "Asti Spumante, per esempio",
    "Chianti or Asti Spumante",
    "Antipasto pairing",
    "Chianti, in Chianti",
    "Soave, e.g.",
    "Trattoria menu heading",
    "Soave or Orvieto",
    "Chianti or Orvieto",
    "Veritas provider?",
    "Trattoria order",
    "Chianti or Soave",
    "In ___ veritas",
    "Chianti, e.g.",
    "\"In ___ veritas\"",
    "Italian wine",
    "Chianti, e.g."
  ],
  "rock": [
    "Hollywood's Dwayne Johnson, with \"the\"",
    "Be really impressive, informally",
    "Alternative to rap and R&B",
    "One radio format",
    "Shift in one's seat, perhaps",
    "Scissors topper, in a game",
    "Wear, and look great doing it",
    "Loser to paper",
    "The White Stripes' genre",
    "What a fist might represent",
    "Bolder",
    "Roll's partner",
    "Big ring stone, slangily",
    "Swing alternative",
    "Try to put baby to sleep, maybe",
    "Musical genre pioneered by Bill Haley and His Comets",
    "Much modern popular music",
    "Radio format",
    "Sling missile",
    "Firm foundation",
    "Seesaw",
    "Put to sleep, in a way",
    "Music genre since the 50's",
    "Led Zeppelin's genre",
    "Boulder",
    "Genre of 46-Across"
  ],
  "shoe": [
    "Wedge, e.g.",
    "Old woman's home, in a children's rhyme",
    "One tapped for a stage show?",
    "Something a cobbler may hold",
    "Pump or oxford",
    "Card holder at a casino",
    "Unconventional home in a nursery rhyme",
    "Clog or pump",
    "Slipper or sandal",
    "Nike product",
    "Loafer or pump",
    "Makeshift fly swatter",
    "Something removed at a T.S.A. checkpoint",
    "Unit around one foot?",
    "Flat, e.g.",
    "One going everywhere on foot?",
    "Wedge or pump",
    "There might be a spat about this",
    "One going on foot?",
    "Brake plate",
    "Loafer, e.g.",
    "Clog or pump",
    "One going on foot?",
    "Clodhopper, e.g.",
    "Last place?",
    "Nursery rhyme dwelling",
    "Item depicted by this puzzle's circled letters",
    "It's about a foot",
    "What's under an arch",
    "It has a silent tongue",
    "Oxford or loafer",
    "Pump or loafer",
    "Home for a certain old woman",
    "One going on foot?",
    "Clog, e.g.",
    "It's a little longer than a foot",
    "Wingtip or wedge",
    "Do some blacksmith's work on",
    "Place to put a tap",
    "Last thing",
    "Brogue, e.g.",
    "Khrushchev's impromptu gavel",
    "Monopoly token",
    "Monopoly token",
    "Oxford, e.g.",
    "Part of a brake",
    "Old woman's home, in a nursery rhyme",
    "Last thing",
    "Old woman's home in a nursery rhyme",
    "Mule, e.g.",
    "What a cobbler works on",
    "Something fit to be tied?",
    "Something sold in half sizes",
    "Hoof protector",
    "Loafer, for one",
    "Home for an \"old woman\" in a nursery rhyme",
    "Mule or clog",
    "Pump, e.g.",
    "Place for a horn",
    "Tongue site",
    "Brake part",
    "Place for a tap",
    "Clog or pump",
    "Dealing box, in blackjack",
    "It may contain a tree",
    "Pump",
    "Sole supporter?",
    "Pedal pusher",
    "Tongue-tied one?",
    "It's about a foot",
    "One that gets tongue-tied?",
    "Loafer, e.g.",
    "It's about a foot",
    "*Blacksmith ... Mother Goose ... tongue",
    "Brake part",
    "Pump, e.g.",
    "Jeff MacNelly comic",
    "Wing tip, e.g.",
    "It may contain a tree",
    "There may be a spat about it",
    "High heel, e.g.",
    "Tongue locale",
    "Word with strings or horns",
    "Sneaker, e.g.",
    "It's about a foot",
    "Tongue's locale",
    "Nursery rhyme residence",
    "Pump, e.g.",
    "Part of a brake",
    "Last item",
    "Some people take a shine to it",
    "Last wrapper",
    "Camera device",
    "Sole protector",
    "Jeff MacNelly comic strip",
    "Oxford, for one",
    "Florsheim product",
    "Sole possessor?",
    "Oxford, e.g.",
    "Tree locale",
    "Cramped Mother Goose dwelling"
  ],
  "lush": [
    "Verdant",
    "Very green",
    "Verdant",
    "Like rain forest vegetation",
    "Like rain forests",
    "Like green, green vegetation",
    "Really green",
    "Like the Amazon rain forest",
    "Thick and green",
    "Luxuriant",
    "Growing luxuriantly",
    "Verdant",
    "Sot",
    "Verdant",
    "Sot",
    "Drunkard",
    "Like a well-kept lawn",
    "Verdant",
    "Sot",
    "Drunk",
    "W. C. Fields persona",
    "Boozehound",
    "Verdant",
    "Verdant",
    "Heavy drinker",
    "Abundant",
    "Luxurious",
    "Verdant",
    "Verdant",
    "Opulent",
    "Luxuriant",
    "Luxuriant, as vegetation",
    "Rummy"
  ],
  "geek": [
    "Nerdy sort",
    "Be an enthusiastic dweeb, with \"out\"",
    "Techie or Trekkie",
    "Tech whiz",
    "Carnival performer",
    "Nerd",
    "\"The Big Bang Theory\" sort",
    "Tech enthusiast, say",
    "Poindexter",
    "Sheldon of \"The Big Bang Theory,\" for one",
    "Tech expert, as it were",
    "Nerd (out)",
    "Show one's nerdy side, with \"out\"",
    "Sideshow oddity",
    "Overindulge in a brainy subject, with \"out\"",
    "Be the ultimate nerd, with \"out\"",
    "Fanboy or fangirl",
    "Tech whiz, say",
    "Computer whiz",
    "Many a Comicon attendee",
    "Many a Silicon Valley hiree",
    "Circus performer",
    "Tech whiz",
    "Nerd",
    "Techie, stereotypically",
    "Uncool sort",
    "Nerd",
    "Social misfit",
    "Nerd",
    "Nerd's friend",
    "Carnival weirdo",
    "Sideshow performer"
  ],
  "onit": [
    "\"Roger that, boss!\"",
    "\"Will do\"",
    "\"I'll get right to work!\"",
    "\"I got this!\"",
    "Hop ___ (get to work)",
    "\"I'll get right ___\"",
    "Words after get or sleep",
    "\"I've got that covered\"",
    "\"Get ___!\"",
    "\"Right away, boss!\"",
    "Doing the job",
    "\"Leave this to me\"",
    "\"Step ___!\"",
    "Taking action",
    "\"I'll do that job\"",
    "\"I'll take care of that\"",
    "Taking care of things",
    "\"Will do!\"",
    "Doing the job",
    "Doing the job",
    "Taking action",
    "\"I'll get this done\"",
    "\"Sleep ___\"",
    "Terse response to an order",
    "Getting the job done",
    "Doing the job",
    "\"I'll take care of that\"",
    "Not dilly-dallying",
    "\"You can count ___\"",
    "Taking care of business",
    "Tending to the matter",
    "Not procrastinating",
    "See 23-Across",
    "Kool & the Gang's \"Get Down ___\"",
    "Not procrastinating",
    "\"Get ___!\"",
    "\"Step ___!\" (\"Hurry up!\")",
    "\"I'm ___!\"",
    "Doing the job",
    "Not procrastinating",
    "\"I'm working ___\"",
    "\"Don't bet ___!\"",
    "Words after step or sleep",
    "Not procrastinating",
    "Not procrastinating",
    "\"I'm ___!\" (\"Can do!\")",
    "Taking care of business",
    "\"I'm ___!\"",
    "\"I'm ___!\" (\"Will do!\")",
    "\"Get ___!\" (\"Stop procrastinating!\")",
    "Handling the matter",
    "Taking care of business",
    "Doing the job",
    "\"I'll do that right away!\"",
    "Taking care of things",
    "\"I'll get right ___!\"",
    "Taking care of business",
    "Sleep ___",
    "\"I'm working ___!\"",
    "Taking care of the situation",
    "\"Get ___!\"",
    "Attending to the matter",
    "Attending to a task",
    "\"Put a lid ___!\"",
    "\"I'm working ___!\"",
    "Tending to the matter",
    "Step ___ (hurry)",
    "Taking care of matters",
    "\"I'm working ___!\"",
    "Taking care of a job",
    "\"Don't bet ___!\"",
    "\"Sleep ___\"",
    "Taking care of business",
    "Attending to the case",
    "Taking action",
    "\"Let me sleep ___\"",
    "Doing what needs to be done",
    "\"Step ___!\"",
    "\"Step ___!\"",
    "\"I'm ___!\"",
    "\"Step ___!\"",
    "\"I'll get ___!\"",
    "\"Put a lid ___!\"",
    "\"I'm ___!\"",
    "\"Step ___!\"",
    "\"Don't bet ___!\"",
    "\"Put a lid ___!\"",
    "\"I'll get right ___!\"",
    "\"Put a lid ___!\"",
    "\"Get ___!\"",
    "\"Step ___!\"",
    "\"Get ___!\" (boss's order)",
    "\"Get___!\" (\"Start working!\")",
    "\"Step___!\"",
    "\"Get___!\"",
    "\"Step ___!\""
  ],
  "urns": [
    "Locales for some Grecian art",
    "Coffeehouse dispensers",
    "Some drink dispensers",
    "Vases",
    "Banquet vessels",
    "Some catering equipment",
    "Dispensers at banquets",
    "Cremation containers",
    "Some wares in a china shop",
    "Large coffee holders",
    "Ash containers",
    "Some catering equipment",
    "Large coffee holders",
    "Big vases",
    "Things often found by the dessert table",
    "Caterers' dispensers",
    "Many resting places",
    "Containers for serving coffee",
    "Some garden ornaments",
    "Vases",
    "Some Bronze Age artifacts",
    "Some heavy planters",
    "Coffee containers",
    "Vases",
    "Some of a caterer's inventory",
    "Fancy flower holders",
    "Samovars",
    "Samovars",
    "Java servers",
    "Caterer's coffee containers",
    "Large coffee holders",
    "Still-life subjects",
    "Mantelpiece pieces",
    "Holders of some dry bouquets",
    "Cemetery sights",
    "Java servers",
    "Some funerary ware",
    "Some gravesite decorations",
    "Vases",
    "Decorative items",
    "Catering hall sights",
    "Some pieces in an archaeological museum",
    "Vases",
    "Mantel pieces",
    "Crypt alternatives",
    "Some cloisonne pieces",
    "Ash containers",
    "Mantel pieces",
    "Fancy club trophies",
    "Samovars",
    "Buffet dispensers",
    "Java dispensers",
    "Brunch buffet items",
    "Coffee dispensers",
    "Footed receptacles",
    "Portico adornments",
    "Big coffee holders",
    "Cemetery sights",
    "Vases",
    "Some servers",
    "Coffee holders",
    "Coffee holders",
    "Coffee vessels",
    "Starbucks hardware",
    "Pottery class projects",
    "Vases",
    "Coffee shop sights",
    "Some burial vessels",
    "Items in niches",
    "Garden decorations",
    "Garden decorations",
    "Decorative vases",
    "Some servers",
    "Buffet table items",
    "Coffee dispensers",
    "They may be put on pedestals",
    "Cemetery sights",
    "Coffee servers",
    "Coffee houses?",
    "Sizable servers",
    "Beverage servers",
    "Coffeehouse equipment",
    "Old-fashioned ballot boxes",
    "Coffee brewers",
    "Some relics",
    "Still-life subjects",
    "Tomb items",
    "Amphorae",
    "Tomb articles",
    "Samovars",
    "Coffee dispensers"
  ],
  "pony": [
    "One providing a ride at a fair, maybe",
    "My Little ___ (Hasbro toy)",
    "Carousel figure",
    "Pay (up)",
    "Small equine",
    "Kind of keg",
    "Polo mount",
    "Carnival ride provider",
    "Pay (up)",
    "Little horse on the prairie?",
    "Ride at a kids' fair",
    "Smallish equine",
    "My Little ___ (kids' toy line)",
    "Pay (up)",
    "Pay, with \"up\"",
    "Youngster's mount",
    "Pay, with \"up\"",
    "Pay (up)",
    "Small mount",
    "Shetland",
    "Small liqueur glass",
    "Polo need",
    "Pay (up)",
    "Small liqueur glass"
  ],
  "hear": [
    "Catch wind of",
    "Get wind of",
    "\"I won't ___ it!\"",
    "*\"Amen to that!\"",
    "Get wind of",
    "When doubled, \"I agree!\"",
    "When repeated, a cry of approval",
    "Adjudicate, as a case",
    "Get wind of",
    "Catch wind of",
    "Catch, in a way",
    "Try, as a case",
    "Learn (of)",
    "Get wind of",
    "When repeated, \"Amen!\"",
    "Sense, as a 14-Across",
    "Catch",
    "Catch, in a way",
    "Catch some waves?",
    "\"... ___ no evil ...\"",
    "When doubled, a cry of approval",
    "Try, as a case",
    "Pick up, in a way",
    "Catch, in a way",
    "Learn (of)",
    "Get wind of",
    "Try, as a case",
    "When repeated, words of agreement",
    "Sense, in a way",
    "When repeated, a cry of approval",
    "Pick up",
    "When repeated, a cry of approval",
    "\"Did you ___ that?\"",
    "Be informed of",
    "Consider officially",
    "Get wind of",
    "Listen to",
    "Eavesdrop",
    "Get wind of",
    "Take, as testimony",
    "Try, as a case",
    "Take, as oral arguments",
    "\"___ me out\"",
    "Audition",
    "Take, as testimony",
    "Consider, as a case",
    "Learn via the grapevine",
    "Get an earful"
  ],
  "oder": [
    "German/Polish border river",
    "Poland/Germany border river",
    "Baltic Sea feeder",
    "It forms part of the Polish/German border",
    "River through Pomerania",
    "River through Silesia",
    "Frankfurt's river",
    "River to the Baltic",
    "Baltic Sea feeder",
    "River through Wroclaw",
    "River separating Germany and Poland",
    "Frankfurt an der ___",
    "Baltic Sea feeder",
    "Baltic Sea feeder",
    "Baltic feeder",
    "Polish/German border river",
    "The Warta's outlet",
    "River to the Baltic",
    "Wroclaw's river",
    "German/Polish border river",
    "German border river",
    "Baltic Sea feeder",
    "___-Neisse Line",
    "European boundary river",
    "Baltic feeder",
    "Wroclaw's river",
    "The Szczecin Lagoon is an extension of its mouth",
    "___-Neisse Line",
    "Baltic feeder",
    "___-Neisse Line (border in Europe)",
    "Polish border river",
    "German/Polish border river",
    "Szczecin's river",
    "European border river",
    "River of Brandenburg",
    "German border river",
    "Germany's ___-Spree Canal",
    "German border river",
    "River to the Baltic",
    "River to the Baltic",
    "Frankfurt an der ___",
    "Baltic Sea feeder",
    "River of Brandenburg",
    "River of Frankfurt",
    "Frankfurt's river",
    "Part of the German/Polish border",
    "Frankfurt's river",
    "River to the Baltic",
    "River to the Baltic",
    "Baltic Sea tributary",
    "Frankfurt's river",
    "Frankfurt's river",
    "Germany's Frankfurt an der ___",
    "Breslau's river",
    "European boundary river",
    "River through Frankfurt",
    "Baltic Sea feeder",
    "Treaty of Versailles river",
    "Neisse River's outlet",
    "German border river",
    "___-Spree Canal",
    "The ___-Neisse Line",
    "German river",
    "European border river",
    "Frankfurt's river",
    "Baltic feeder",
    "Opole's river",
    "German-Polish border river",
    "Frankfort on the ___",
    "German-Polish border river",
    "German-Polish border river",
    "Baltic Sea feeder",
    "Wroclaw's river",
    "Silesian river",
    "German border river",
    "German-Polish border river",
    "Baltic feeder",
    "German border river",
    "Baltic Sea feeder",
    "Czech river"
  ],
  "sana": [
    "Yemen's capital",
    "Yemen's capital",
    "Capital across the Red Sea from Asmara",
    "Mideast capital supposedly founded by a son of Noah",
    "Yemeni capital",
    "Capital near Aden",
    "Yemen's capital",
    "Mideast capital",
    "Yemen's capital",
    "Where Yemen Airways is based",
    "Mideast capital",
    "Capital near 10-Down",
    "Capital largely surrounded by high clay walls",
    "Yemen's capital",
    "Mideast capital",
    "Capital on the Arabian Peninsula",
    "Yemen's capital",
    "Walled city of the Mideast",
    "Mideast capital",
    "Yemen's capital",
    "Arabian capital",
    "Mideast capital",
    "Mideast capital",
    "Yemen Gate locale",
    "Mideast capital",
    "Arabian peninsula city",
    "Arabian capital",
    "Mideast capital",
    "Arabian Peninsula city",
    "Arab capital",
    "Capital of Yemen",
    "Mideast capital",
    "Yemen's capital",
    "Yemen's capital",
    "Mideast capital",
    "Mideast capital",
    "Yemen's capital",
    "Yemen's capital",
    "Mideast capital",
    "Yemen's capital",
    "Yemen's capital",
    "Yemen's capital",
    "Capital of Yemen",
    "Yemen's capital",
    "Capital of Yemen",
    "Mens___ in corpore sano",
    "Mideast capital"
  ],
  "sirs": [
    "Opening of some formal letters",
    "Old-fashioned letter opener",
    "Round Table figures",
    "Paul McCartney and Elton John",
    "Formal letter opener",
    "Old letter opener",
    "Gentlemen",
    "They're dubbed",
    "\"Dear ___\" (letter starter)",
    "McCartney and Jagger, for two",
    "Formal letter opener",
    "Impersonal letter starter",
    "Beknighted souls?",
    "Sean Connery and Roger Moore, e.g.",
    "Generic addressees",
    "Patrick Stewart and Alan Cumming, e.g.",
    "Knights",
    "Letter opener",
    "Elton John and Paul McCartney",
    "Formal letter opening",
    "Madams' partners",
    "\"Dear\" ones",
    "Letter start",
    "Old letter salutation",
    "\"Dear\" ones",
    "Dear ___",
    "Brief salutation",
    "Knighted ones",
    "Paul McCartney and others",
    "Knights",
    "Ones who've been tapped on the shoulder",
    "Some nobility",
    "Elton John and others",
    "\"Dear\" ones",
    "Old letter opener",
    "Noblemen",
    "Gentlemen",
    "Old-fashioned letter opener",
    "\"Dear\" ones",
    "Men",
    "Some British parliamentarians",
    "Old-fashioned letter opener",
    "They're dubbed",
    "Knights in shining armor",
    "\"Dear\" ones",
    "Lancelot and others",
    "Business salutation",
    "Dear people?",
    "Participants in 32-Across",
    "Round Table participants",
    "Non-P.C. letter opening",
    "\"Dear\" ones",
    "Lords",
    "Madams' men",
    "Word often followed by a colon",
    "Letter opener",
    "Letter opener",
    "Knights",
    "Sexist letter start",
    "Noel Coward and others",
    "Letter opener",
    "They're dubbed",
    "Dear follower",
    "Gielgud and Olivier, e.g.",
    "House of Lords members",
    "Dear ones?",
    "These are always dubbed",
    "Letter opener?"
  ],
  "hell": [
    "Classic AC/DC song represented by the falling circled letters and this answer",
    "Word spelled with \"double hockey sticks\"",
    "Nether regions",
    "Satan's domain",
    "\"___ is other people\": Sartre",
    "\"Other people,\" per Sartre",
    "Frequent Bosch setting",
    "Where to go \"for the company,\" per Mark Twain",
    "Fire place?",
    "It's divided into nine circles",
    "Fire place?",
    "Dante's \"Inferno\"",
    "\"A half-filled auditorium,\" to Frost",
    "Epitome of hotness",
    "Subject of Dante's \"Inferno\"",
    "Something to catch from scolding parents",
    "The pits",
    "Misery",
    "Satan's domain",
    "Hades",
    "\"War is ___\"",
    "\"A place where you have nothing to do but amuse yourself\": Shaw",
    "It may be raised at a party",
    "The pit",
    "What may be raised at celebrations",
    "Something to catch or raise",
    "Extreme torment",
    "Fiery place",
    "Living ___",
    "Something to pay?",
    "Hot spot",
    "Paradise's opposite",
    "Scratch pad?",
    "What partyers raise",
    "Alternative to high water",
    "High water alternative",
    "\"War is ___\"",
    "Site of 7-Down",
    "High water's partner",
    "\"Drat!' magnified",
    "Terrible rigor",
    "\"The Divine Comedy\" locale"
  ],
  "come": [
    "\"___ as you are\"",
    "Show up",
    "Accept an invitation, say",
    "Easy ___, easy go",
    "\"Join us!\"",
    "Command to a dog",
    "Command to Rover",
    "Wasted word to a housecat",
    "\"Follow me!\"",
    "\"Here, boy!\"",
    "Make it",
    "Dog command",
    "\"Here, boy!\"",
    "Terse invitation",
    "Accompany, with \"with\"",
    "Command to Fido",
    "Change one's opinion",
    "Dog command",
    "Take place",
    "Turn up",
    "With 54-Down, approach with a line",
    "Order to Rover",
    "Word of invitation",
    "Arrive",
    "Rain or shine preceder",
    "Beckoning word",
    "Draw near",
    "Partner of 18-Down",
    "Dog command",
    "Tag along",
    "Invitation word",
    "Arrive",
    "Approach",
    "Dog command",
    "\"Be our guest!\"",
    "Invitation word"
  ],
  "brad": [
    "Flat-headed fastener",
    "___ Rutter, \"Jeopardy!\" contestant with the all-time highest winnings ($4.9+ million)",
    "Small wire nail",
    "Baseboard fastener",
    "Oscar-winning Pitt",
    "With 21-Across, \"Once Upon a Time ... in Hollywood\" co-star",
    "Thin fastener",
    "Pitt of \"The Big Short\"",
    "Boy's name that's a hardware item",
    "Small nail",
    "Little nail",
    "Actor Pitt",
    "Stationery securer",
    "With 59-Down, star of the work revealed by the first letters of the Across clues, which hint at this puzzle's theme",
    "Fastener in a punched hole",
    "Country music's Paisley",
    "Thin nail",
    "Thin nail",
    "Hardware store offering",
    "Thin fastener",
    "Small nail",
    "Small fastener",
    "Thin fastener",
    "Wire nail",
    "Janet's fiance in \"The Rocky Horror Picture Show\"",
    "Thin nail",
    "\"Marmaduke\" cartoonist Anderson",
    "Slender nail",
    "Small fastener",
    "Thin nail",
    "Actor Pitt",
    "Thin nail",
    "Thin fastener",
    "Actor Pitt",
    "Pitt of \"Meet Joe Black\"",
    "It gets hit on the head",
    "Hollywood heartthrob Pitt",
    "Small fastener",
    "\"The Rocky Horror Picture Show\" hero",
    "Small fastener",
    "Carpenter's nail",
    "Small fastener",
    "Thin nail",
    "Actor Pitt",
    "Slender nail",
    "Finishing nail",
    "Heartthrob actor Pitt",
    "Wire nail",
    "Slender nail",
    "Wire nail",
    "Thin nail",
    "Small nail",
    "Slender nail"
  ],
  "lure": [
    "Tackle box item",
    "The fly in fly-fishing, e.g.",
    "Entice",
    "Anglerfish's light, e.g.",
    "Item in a tackle box",
    "Tackle box item",
    "Tempt",
    "Draw",
    "Bonus upon signing, e.g.",
    "Play the siren to",
    "Attraction",
    "Entice",
    "Tackle item",
    "It may be on the line",
    "It'll help you get a bite",
    "Neon sign, e.g.",
    "Bit of fishing gear",
    "It's at the end of the line",
    "What catnip is to a cat",
    "Entice",
    "Draw in",
    "Bait",
    "Inducement",
    "Siren",
    "Hook",
    "Tackle box item",
    "Tackle box item",
    "Loss leader, e.g.",
    "Tackle box item",
    "This might be at the end of the line",
    "Come-on",
    "Angler's purchase",
    "Tackle box item",
    "Tackle box item",
    "Tackle box item",
    "Fisher's plug",
    "Come-on",
    "Play the siren",
    "Tackle box item",
    "Play the siren",
    "Artificial bait",
    "Tempt",
    "Decoy",
    "Fishing item",
    "Tackle box item",
    "Attraction",
    "Bait, sometimes",
    "Entice",
    "Carrot, on occasion",
    "Tacklebox item",
    "Bait",
    "Entice",
    "Drawing card"
  ],
  "hera": [
    "Wife of Zeus",
    "Sister and wife of Zeus",
    "Goddess who cursed Echo to just repeat the words of others",
    "Loser of a beauty contest, in myth",
    "Daughter of Cronus",
    "Goddess in a chariot pulled by peacocks",
    "Noted beauty contest loser",
    "Greek ally in the \"Iliad\"",
    "Queen on Olympus",
    "Goddess of marriage",
    "Juno's Greek counterpart",
    "Zeus' wife",
    "Queen of Heaven in the \"Iliad\"",
    "Queen of the Greek gods",
    "Also-ran for the golden apple, in myth",
    "Zeus' wife",
    "One sitting on a celestial throne",
    "Olympian deity",
    "Mother of Ares and Hephaestus",
    "Mother of Ares",
    "Goddess involved in Hercules' labors",
    "Goddess in a chariot drawn by peacocks",
    "Wife of Zeus",
    "Queen of Olympus",
    "\"Great ___!\" (Wonder Woman cry)",
    "Goddess who competed for the apple of discord",
    "Goddess of marriage",
    "Wife of Zeus",
    "Juno, to the Greeks",
    "Daughter of Cronus",
    "Queen in Greek myth",
    "Vindictive one, in myth",
    "Wife of Zeus",
    "Queen of myth",
    "Juno, in Greece",
    "Goddess whose name is an anagram of her mother's",
    "Wife of Zeus",
    "She punished Echo",
    "Queen of Olympus",
    "Zeus' wife",
    "Juno's counterpart",
    "Vengeful goddess",
    "Daughter of Cronus",
    "Goddess with a cow as an emblem",
    "Wife of Zeus",
    "Wife of Zeus",
    "Queen of the heavens",
    "Queen on Mount Olympus",
    "Jealous Olympian",
    "Goddess whose bird was a peacock",
    "Jealous queen of myth",
    "Zeus' wife",
    "Ox-eyed queen of myth",
    "Mother of Ares",
    "Goddess of matrimony",
    "Sister and wife of Zeus",
    "Zeus' spouse",
    "\"Ox-eyed lady\" in the \"Iliad\"",
    "Vindictive goddess",
    "Wife of Zeus",
    "Mother of Ares",
    "Mother of Ares",
    "Mother of Hephaestus",
    "Zeus' wife",
    "Vengeful Olympian",
    "Frequent quarreler with Zeus",
    "Consort of Zeus",
    "Ancient Olympia's Temple of ___",
    "Vindictive Olympian",
    "Bickerer in the \"Iliad\"",
    "Vindictive Greek goddess",
    "Wife of Zeus",
    "Jealous wife of myth",
    "Hectorer of Zeus",
    "Jealous wife in Greek myth",
    "Daughter of Cronus",
    "Queen of the Heavens",
    "Zeus's wife",
    "Queen of Olympus",
    "Ares' mother",
    "Bickerer in the \"Iliad\"",
    "Greek queen of heaven",
    "Daughter of Cronus",
    "Daughter of Cronus",
    "Sister of Zeus",
    "Wife of Zeus",
    "Wife of Zeus",
    "Zeus's wife",
    "Daughter of Cronus",
    "Juno's Greek counterpart",
    "Goddess whose name means \"chosen one\"",
    "Queen of the heavens",
    "Apple of discord contender",
    "Queen of the heavens",
    "Mother of Hephaestus",
    "Olympus queen",
    "Heavenly queen",
    "Patroness of the Argonauts"
  ],
  "exit": [
    "Sign at a highway interchange",
    "___ strategy",
    "It takes people out",
    "Kind of row with extra legroom",
    "\"___, pursued by a bear\" (Shakespearean stage direction)",
    "___ stage left",
    "Info on a highway billboard",
    "Lighted sign by a stairwell",
    "Way out",
    "On-ramp's opposite",
    "Sign by a fire escape",
    "___ interview",
    "Interstate billboard info",
    "Out",
    "One end of a maze",
    "Road sign with an arrow",
    "Take one's leave",
    "Fire drill objective",
    "Sign with an arrow",
    "Highway sign next to an off-ramp",
    "Way out",
    "Turnoff for drivers",
    "Off-ramp sign",
    "Next ___",
    "It's not a welcome sign",
    "Way off",
    "It's found on the side of a highway",
    "Freeway sign",
    "Final menu option, maybe",
    "Lit sign in a theater",
    "Highway number",
    "Go",
    "Way out",
    "Last file menu option, often",
    "What to do when you have nothing left to say?",
    "Get off the highway",
    "*Depart",
    "Red-letter word",
    "Way out",
    "Leave the auditorium",
    "Lighted sign over a doorway",
    "Interstate sign with an arrow",
    "Lighted sign near a stairway",
    "Way off",
    "Interstate sign",
    "Sign by stairs, often",
    "Theater sign",
    "[Green]",
    "Departure",
    "Tollbooth site",
    "Sign near a stairway",
    "Out",
    "Lighted sign above a door",
    "Emergency door sign",
    "Door to the outside",
    "Stage direction after an actor's last line",
    "Turnoff",
    "Lighted sign over a door",
    "Place to pay a toll, perhaps",
    "Way out",
    "___ row",
    "Stage direction",
    "Number on an Interstate sign",
    "Escape route",
    "It's often marked with a number",
    "Lighted sign in a theater",
    "\"No ___\"",
    "Take off",
    "Sartre's \"No ___\"",
    "Out",
    "Where to get off",
    "Leave",
    "___ row",
    "Door sign",
    "Off-ramp",
    "Leave the scene",
    "Turnpike toll-paying locale",
    "Sign in the dark",
    "Way out sign",
    "It might be shown to one who's seen it all",
    "Escape route",
    "Door sign",
    "Turnpike turn-off",
    "Departure",
    "Off ramp",
    "Way out",
    "Way out",
    "Where to get off",
    "Lighted sign",
    "It's taken when leaving",
    "It's a turnoff",
    "Door sign",
    "___ interview",
    "Sign above a door",
    "Way to go?",
    "Get off the road",
    "Way out",
    "Leave the stage",
    "Lighted sign",
    "Turnoff",
    "Leave",
    "Way out",
    "Lighted sign over a door",
    "Where to get off",
    "Get away",
    "Door sign",
    "Withdrawal",
    "Illuminated sign",
    "Passage out",
    "Turnpike feature",
    "Way to go",
    "Kind of poll",
    "Sartre's \"No ___\"",
    "Mandated safety sign",
    "Sign by a door",
    "Retreat",
    "Split, so to speak",
    "Departure",
    "Turnoff",
    "Kind of poll",
    "Kind of poll",
    "Highway diversion",
    "Actor's direction",
    "Leave",
    "Door",
    "Theater sign",
    "Cobb's \"___ Laughing\"",
    "Take off",
    "Script word",
    "Kind of poll",
    "Debouchment"
  ],
  "irma": [
    "Soul singer Thomas",
    "Memorable hurricane of 2017",
    "Diner manager in \"Garfield\"",
    "Destructive 2017 hurricane",
    "___ Thomas, the Soul Queen of New Orleans",
    "Woman whose name is an anagram of MIRA",
    "___ Bunt, \"On Her Majesty's Secret Service\" henchwoman",
    "\"___ la Douce\" (1963 film)",
    "Rombauer of cookery",
    "___ Bunt, villainess in \"On Her Majesty's Secret Service\"",
    "Rombauer of cooking",
    "\"___ la Douce\"",
    "___ Pince, librarian at Hogwarts",
    "\"___ la Douce\"",
    "\"Garfield\" waitress",
    "\"Harry Potter\" librarian Pince",
    "Rombauer of cookery",
    "\"___ la Douce\" (Billy Wilder film)",
    "Rombauer who wrote \"Joy of Cooking\"",
    "\"___ la Douce\"",
    "\"___ la Douce\"",
    "\"___ la Douce\"",
    "\"___ la Douce\"",
    "\"Joy of Cooking\" author Rombauer",
    "\"___ la Douce\"",
    "\"___ la Douce\"",
    "\"My Friend ___\" of 1950s TV",
    "\"The Mystery of ___ Vep,\" 1990s Off Broadway play",
    "\"___ la Douce\"",
    "Diner manager/waitress in \"Garfield\"",
    "Bygone radio \"friend\"",
    "\"___ la Douce\" (1963 film)",
    "Cookbook writer Rombauer",
    "2006 Grammy-winning blues singer ___ Thomas",
    "___ Thomas, the Soul Queen of New Orleans",
    "\"___ la Douce\"",
    "\"Garfield\" waitress",
    "\"___ la Douce,\" 1963 film",
    "Rombauer of cookery",
    "Neighbor of Hi and Lois",
    "Neighbor of Hi and Lois, in the funnies",
    "\"Joy of Cooking\" author Rombauer",
    "\"___ la Douce\"",
    "\"___ la Douce\" (1963 film)",
    "Owner/waitress in \"Garfield\"",
    "\"My Friend ___\" of 50's TV",
    "Cookbook author Rombauer",
    "\"Joy of Cooking\" author Rombauer",
    "\"My Friend ___\" of old radio/TV",
    "\"My Friend ___\" (old radio/TV series)",
    "\"___ la Douce,\" 1963 film",
    "Cookery's Rombauer",
    "\"The Joy of Cooking\" author Rombauer",
    "\"___ la Douce,\" 1963 film",
    "1963 film \"___ la Douce\"",
    "\"___ la Douce\"",
    "\"Garfield\" waitress",
    "\"___ la Douce\"",
    "?ô___ la Douce?ö",
    "Cookbook writer Rombauer",
    "\"The Joy of Cooking\" author Rombauer",
    "\"The Joy of Cooking\" author Rombauer",
    "\"My Friend ___\" (1949 flick)",
    "\"___ la Douce\" (1963 film)",
    "1963 title role for Shirley",
    "Advice columnist Kurtz",
    "1950's TV title role for Marie Wilson",
    "\"My Friend\" of old radio",
    "Old radio's \"My Friend ___\"",
    "\"___ La Douce\"",
    "___ Thomas, the Soul Queen of New Orleans",
    "Cookbook author Rombauer",
    "\"___ la Douce\"",
    "\"___ la Douce\"",
    "___ la Douce",
    "1996 French film \"___ Vep\"",
    "\"The Joy of Cooking\" writer Rombauer",
    "\"The Mystery of ___ Vep\" (Charles Ludlam play)",
    "Soprano in \"Louise\"",
    "Soprano in \"Louise\"",
    "\"My Friend\" at the movies",
    "1963 Shirley MacLaine role",
    "Soprano in \"Louise\"",
    "Actress Seigel of \"Fernwood 2-Night\"",
    "___ la Douce",
    "\"___ la Douce\"",
    "Dagwood's sweetheart before Blondie",
    "Early Shirley role",
    "\"___ la Douce\"",
    "\"My Friend\" of 50's TV",
    "\"My friend\" of early TV",
    "___la Douce",
    "\"___la Douce\"",
    "\"___la Douce\"",
    "La Douce",
    "\"The Joy of Cooking\" author Rombauer",
    "\"___ la Douce\"",
    "\"The Joy of Cooking\" author Rombauer",
    "Rombauer of cookbook fame",
    "Role for Shirley in '63",
    "\"___ La Douce\""
  ],
  "lazy": [
    "___ Susan (dining table centerpiece)",
    "Like a couch potato",
    "Indolent",
    "Far from industrious",
    "Word before Susan or Sunday",
    "Slothful",
    "Unlikely to hustle",
    "Slothful",
    "Unlike a go-getter",
    "Indolent",
    "Slow-moving",
    "Unenterprising",
    "Do-nothing",
    "Never getting off one's duff",
    "Indolent",
    "Slothful",
    "Slothful",
    "Shirking working",
    "Slow-moving",
    "Having no get-up-and-go",
    "Slothful",
    "Like summer days, often"
  ],
  "jail": [
    "Martin Luther King's \"Letter From Birmingham ___\"",
    "Corner space in Monopoly",
    "One corner on a Monopoly board",
    "Pokey",
    "Corner square in Monopoly",
    "Monopoly space with the words \"Just Visiting\"",
    "One corner of a Monopoly board",
    "One corner of a Monopoly board",
    "Monopoly space that says \"Just Visiting\"",
    "Lock up",
    "Monopoly square between Connecticut Avenue and St. Charles Place",
    "Corner Monopoly square",
    "First corner after \"Go\" in Monopoly",
    "See 33-Across",
    "Prison",
    "See 33-Down",
    "\"The Usual Suspects\" setting",
    "Lockup",
    "One corner of a Monopoly board",
    "One corner on a Monopoly board",
    "Lockup",
    "Corner square in Monopoly",
    "Incarcerate",
    "Cooler",
    "One corner of a Monopoly board",
    "Monopoly corner square",
    "Monopoly square",
    "Monopoly square",
    "Monopoly square",
    "Corner after \"GO\"",
    "Clink",
    "Fine alternative",
    "Corner square in Monopoly",
    "Set in \"Die Fledermaus\"",
    "Clink",
    "Lock up",
    "Monopoly corner square",
    "Lift:elevator::bridewell:___",
    "One corner in Monopoly",
    "Incarcerate",
    "\"Go directly to ___\""
  ],
  "ious": [
    "Debtors' notes",
    "Red slips of paper?",
    "Some obligations",
    "Notes from players who can't pay",
    "Debt docs",
    "Chits",
    "Red state handouts?",
    "Chits",
    "Written promises",
    "Promises to pay",
    "Alternatives to chips, say",
    "They indicate shyness",
    "Brief promises?",
    "Slips in pots",
    "Poor writer's scribblings?",
    "Papers in poker pots",
    "Notes in pots",
    "Notes from short people?",
    "Notes in a poker pot",
    "Notes in a pot",
    "Evidence that one is short",
    "Creditor's collection",
    "Short notes",
    "Slips in pots",
    "Debtors' notes",
    "Bettors' promises, e.g.",
    "Slips in a pot",
    "Things in pots",
    "Gamblers' letters",
    "Markers",
    "Markers",
    "Markers",
    "Borrower's handouts",
    "Chits",
    "Notes in a pot, maybe",
    "Written promises",
    "Markers",
    "Some poker settlements",
    "Cash substitutes",
    "Tabs, of sorts",
    "Poker players' markers",
    "Markers",
    "Debtors' notes",
    "Markers"
  ],
  "cent": [
    "Penny",
    "Bit of change",
    "Tenth of a dime",
    "Euro division",
    "Coin with Lincoln on it",
    "Word before sign or after red",
    "Lincoln's place",
    "Small change",
    "Euro denomination",
    "Dollar division",
    "1850s Flying Eagle, e.g.",
    "Euro division",
    "Copper",
    "Penny",
    "50 ___",
    "Small price to pay",
    "Bit of dough",
    "Since 2010 it's had a shield on its back",
    "Small change in the eurozone",
    "Penny",
    "See 5-Down",
    "Euro fraction",
    "Flying Eagle, for one",
    "Small price to pay",
    "50 ___ (\"Candy Shop\" rapper)",
    "Rap's 50 ___",
    "Small change",
    "Copper",
    "Coin whose front was last redesigned in 1909",
    "?ó",
    "Minimal change",
    "?ó",
    "Penny",
    "Fraction of a euro",
    "Follower of red or 50",
    "1/100 of a euro",
    "Minimal money",
    "Paltry payment",
    "Copper",
    "Small change",
    "Least change",
    "Unit of change",
    "Penny",
    "Penny",
    "Penny",
    "Trifling amount, in a phrase",
    "1/100 of a euro",
    "Minimal change",
    "Gumball cost, once",
    "Small change",
    "Copper",
    "Piddling payment",
    "Smallest postage hike",
    "Euro part",
    "Division of a euro",
    "Small price to pay",
    "Copper",
    "Small price to pay",
    "Lincoln picture site",
    "Indian head, once",
    "Penny",
    "Tiny payment",
    "Small coin",
    "Copper",
    "Flying eagle, e.g.",
    "Tiny payment",
    "Copper",
    "Copper",
    "Penny",
    "Flying Eagle, 1856-58",
    "Copper",
    "Lincoln head",
    "Copper",
    "Not one red___",
    "Flying eagle of the 1850's",
    "Copper",
    "\"Red\" coin",
    "One in a hundred",
    "Not worth a red ___",
    "Minimal change"
  ],
  "taxi": [
    "Move from the gate to the runway, say",
    "Something hailed on city streets",
    "Pickup order?",
    "Vehicle with a medallion",
    "Conveyance preceding Uber and Lyft",
    "One alternative to Uber",
    "Curbside call",
    "Car with a meter",
    "Old workplace sitcom with Danny DeVito as a dispatcher",
    "Place for a medallion",
    "Lyft alternative",
    "Prepare to go on the runway",
    "Leave from the gate",
    "Prepare to take off",
    "Rainy day rarity",
    "Call with a raised hand",
    "Cry on the street",
    "Curbside call",
    "Cry in place of a whistle, maybe",
    "City dweller's yell",
    "Move on or off the runway",
    "Cry outside an airport",
    "Leave the gate, say",
    "Where business is picking up?",
    "Meter site",
    "Get set to take off",
    "Call from a curb",
    "Fleet vehicle",
    "Part of a pickup line?",
    "Move from gate to runway",
    "Cry often made after a whistle",
    "Streetside shout",
    "Move before taking off",
    "Continue after landing",
    "Cab",
    "What planes do after landing",
    "Shout made with a raised arm",
    "Meter reader's place",
    "Vehicle with a medallion",
    "Airport waiter?",
    "See 56-Across",
    "Get ready to take off",
    "Cry before screeching brakes, maybe",
    "Modern advertising medium",
    "Cab",
    "Alternative to the subway",
    "Ride that's hailed",
    "It's hailed by city dwellers",
    "Kind of stand",
    "Vehicle with a medallion",
    "Waiter at a hotel",
    "Hotel waiter?",
    "Hack",
    "Fare carrier",
    "Hailer's cry",
    "Kind of service",
    "Picker-upper",
    "Shout made with an outstretched hand",
    "Prepare for takeoff",
    "Kind of stand",
    "Travel before takeoff",
    "Head to the terminal, say",
    "Approach the gate, say",
    "Vehicle that's hailed",
    "Meter site",
    "Member of a fleet, perhaps",
    "Way around town",
    "Hack",
    "Get ready to take off",
    "It's metered",
    "One with a checkered past, possibly",
    "Curbside call",
    "Travel across a tarmac",
    "Hack",
    "It might make a career in the city",
    "Subway alternative",
    "Hack's vehicle",
    "Checker, perhaps",
    "Subway alternative",
    "Kind of stand",
    "El alternative",
    "It has a horn and charges",
    "Picker-upper",
    "Provider of a pick-me-up?",
    "Rainy day rarity",
    "Airport/hotel connection",
    "Curbside cry",
    "Place for a checkered career?",
    "Fare catch?",
    "Downtown cruiser",
    "Get ready to fly",
    "Checker, perhaps",
    "Curbside call",
    "Airport waiter?",
    "Sight at a station",
    "Kind of stand",
    "Prepare to fly",
    "Medallion site",
    "It has a prominent horn",
    "Hack",
    "Cry that stops traffic",
    "Picker-upper",
    "Curbside call",
    "It has a bill of fare",
    "Curbside call",
    "Hack",
    "Curbside call",
    "Shout in bad weather?",
    "Car with a meter",
    "It's hailed by city dwellers",
    "Metered vehicle",
    "It's hailed by city dwellers",
    "Danza/DeVito sitcom",
    "Curbside cry",
    "Checker, maybe",
    "Curbside cry",
    "Judd Hirsch sitcom",
    "Approach the terminal"
  ],
  "head": [
    "Chairperson, e.g.",
    "With 70-Across, call it a night, say",
    "Circle in the game hangman",
    "Good thing to keep above water",
    "Chief",
    "Keep it under your hat!",
    "Herd unit",
    "Source of a river",
    "Noggin",
    "Key word #2",
    "Foam",
    "Foam on a beer",
    "Big part of an Easter Island sculpture",
    "With 23-Down, deli product",
    "Captain",
    "Noggin",
    "Focus of a phrenologist",
    "Top dog",
    "With 37-Down, complete",
    "With 37-Across, hair accessory ... or a literal hint to 19-, 27-, 47- and 56-Across",
    "President",
    "Apt attachment to the starts of 14-, 17-, 35- and 43-Across",
    "Fourth row",
    "Commander",
    "Foam",
    "John, at sea",
    "Ranch unit",
    "Lucille Ball, notably",
    "Noggin",
    "Beanball target",
    "With 40A, a firebrand",
    "Nincompoop",
    "Cattle rancher's unit",
    "Brain's site",
    "Cattle count",
    "Cattle in a cattle drive",
    "Commander",
    "Crisis point",
    "Principal",
    "Skull",
    "Come to a ___",
    "Intellect",
    "Superintendent",
    "The first H in 4-H",
    "President",
    "Climax",
    "Kind of waiter or water",
    "Supreme",
    "Govern"
  ],
  "thin": [
    "Like Girl Scout \"Mints\"",
    "Weak, as a case",
    "Become less dense, with \"out\"",
    "Flimsy",
    "Add water to, say",
    "Attenuated",
    "Slender",
    "Attenuated",
    "Slender",
    "Like dangerous ice",
    "Slender",
    "Skin-and-bones",
    "Flimsy, as an excuse",
    "Watery",
    "Gracile",
    "Skinny",
    "Weak, as a plot",
    "___ as a rail",
    "Not safe to skate on, say",
    "Dilute",
    "Skinny",
    "Lacking volume",
    "Underweight",
    "Slender",
    "Dilute",
    "Weak",
    "Waiflike",
    "Svelte",
    "Like a beanpole",
    "Waiflike",
    "Watered down",
    "Like India paper",
    "Slicing request",
    "Hairline",
    "Dilute",
    "Waiflike",
    "Dilute",
    "Lean",
    "Significantly underweight",
    "Like a dime",
    "Watered-down",
    "Dilute",
    "Diaphanous",
    "Slender",
    "Watery",
    "Skinny",
    "Lean"
  ],
  "neal": [
    "1963 Best Actress Patricia",
    "Cassady who inspired Kerouac",
    "Actress Patricia of \"Breakfast at Tiffany's\"",
    "Speculative fiction writer Stephenson",
    "Actor McDonough",
    "Beat writer ___ Cassady",
    "Actress Patricia of \"Hud\"",
    "___ Cassady, traveling companion of Jack Kerouac",
    "Actress Patricia of \"Hud\"",
    "Comic Brennan",
    "Onetime radio host Boortz",
    "Sci-fi/historical fiction writer Stephenson",
    "Actress Patricia",
    "Poet Cassady who was a friend of Jack Kerouac",
    "Oscar-winning Patricia",
    "Best Actress Oscar winner Patricia",
    "Boortz of talk radio",
    "Radio host Boortz",
    "Newman's \"Hud\" co-star",
    "\"Hud\" Oscar winner Patricia",
    "Actress Patricia",
    "NPR host Conan",
    "\"Hud\" Oscar winner",
    "Beat poet Cassady",
    "\"Hud\" Oscar winner",
    "Actress Patricia",
    "NPR host ___ Conan",
    "Boortz of talk radio",
    "\"Cryptonomicon\" novelist Stephenson",
    "\"Hud\" Oscar winner",
    "Patricia ___, Best Actress for \"Hud\"",
    "\"Batman\" theme composer Hefti",
    "\"Hud\" Oscar winner",
    "Actress Patricia of \"The Subject Was Roses\"",
    "\"Hud\" Oscar winner Patricia",
    "Patricia who won an Oscar for \"Hud\"",
    "\"Hud\" Oscar winner",
    "Oscar winner Patricia",
    "Broten of hockey fame",
    "\"Hud\" Oscar winner",
    "\"Hud\" star",
    "Patricia of \"Breakfast at Tiffany's\"",
    "Best Actress of 1963",
    "Broten of hockey fame",
    "\"Hud\" Oscar-winner",
    "Oscar-winner Patricia",
    "\"Hud\" Oscar winner",
    "Hockey great ___ Broten",
    "1963 Newman co-star",
    "Actress Patricia",
    "Patricia of \"Hud\"",
    "Patricia who co-starred in \"The Fountainhead\"",
    "Patricia of \"A Face in the Crowd\"",
    "Patricia of \"Hud\"",
    "Oscar winner Patricia",
    "Big band trumpeter Hefti",
    "Actress Patricia",
    "\"Hud\" Oscar winner Patricia",
    "Actress Patricia",
    "Country crooner McCoy",
    "\"Hud\" Oscar winner",
    "1963 Oscar winner",
    "\"Hud\" star",
    "1963 Best Actress"
  ],
  "till": [
    "Cash register drawer",
    "Much change may come from this",
    "Where bills pile up",
    "Where a cashier puts money",
    "Cash register drawer",
    "Break ground",
    "Work the soil",
    "Ones or tens place",
    "Part of a cash register",
    "Ones place",
    "Where bills pile up",
    "Work with a plow",
    "Cash holder",
    "Bill collector",
    "Cash register",
    "Do ground-breaking work",
    "One holding notes",
    "Eminem's \"___ I Collapse\"",
    "Cashier's tray",
    "Work the land",
    "Cultivate",
    "Prepare for planting",
    "Cash drawer",
    "Store drawer",
    "Cash register",
    "Where bills go",
    "Cash holder",
    "Bill collector?",
    "Place for change",
    "Cash cache",
    "Plow",
    "Place to stack money",
    "Cash cache",
    "Ten's place",
    "Work the land",
    "Money drawer",
    "Pull a plow",
    "Cash register part",
    "Bill site",
    "Cash drawer",
    "Cultivate",
    "Money drawer",
    "Cultivate",
    "Money drawer",
    "Money drawer",
    "Cash holder",
    "Cash drawer",
    "Part of a cash register"
  ],
  "ruin": [
    "Pompeii or Machu Picchu",
    "Pompeii, e.g.",
    "Pompeii, for one",
    "Wreck",
    "Destruction",
    "Wreckage",
    "Destroy",
    "Reduce to rubble",
    "Wreck",
    "The Parthenon or Machu Picchu",
    "Wreck",
    "Completely screw up",
    "Destroy",
    "Bankrupt",
    "Devastation",
    "Perdition",
    "Many an archaeological site",
    "Destroy",
    "Total",
    "Spoil",
    "Torpedo",
    "Pompeii, e.g.",
    "Waist",
    "Bankruptcy",
    "Impoverish",
    "Leave destitute",
    "Make unusable",
    "Torpedo",
    "Crush",
    "Utter collapse",
    "Damage beyond repair",
    "Bankrupt",
    "Go to rack and ___",
    "Archaeological site",
    "Machu Picchu, for one",
    "Bring to naught",
    "Archaeological site",
    "Remains to be seen?",
    "Pauperize",
    "Destroy",
    "Undo",
    "Completely botch",
    "Completely botch",
    "Botch",
    "Destroy",
    "Crush",
    "Pompeii, e.g., today",
    "Destroy",
    "Render useless",
    "Wreckage",
    "Undo",
    "Botch",
    "Tear down",
    "Havoc",
    "Totally trash",
    "Archaeological site",
    "Downfall",
    "Demolish",
    "Break, financially",
    "Rack's partner",
    "Total",
    "Botch",
    "Totally botch",
    "Havoc",
    "Completely botch",
    "Archeologist?Æs site",
    "Undo",
    "Totally botch",
    "Completely bollix",
    "Bankruptcy",
    "Make a mess of",
    "Archeological site",
    "Bankrupt",
    "Destroy",
    "Bankrupt",
    "Trash",
    "Completely botch",
    "Destroy",
    "Havoc",
    "Rack's partner",
    "Devastation",
    "Botch"
  ],
  "took": [
    "Nabbed",
    "Swiped",
    "Helped oneself to",
    "Made off with",
    "Grabbed",
    "Brought (along)",
    "Conned",
    "Bamboozled",
    "Swiped",
    "Filched",
    "Scammed",
    "Won",
    "Shot, as a photo",
    "Walked off with",
    "Opposite of gave",
    "Applied oneself (to)",
    "Lifted",
    "Captured",
    "Captured",
    "Was in, as a class",
    "Swiped",
    "Grabbed",
    "Transported"
  ],
  "orel": [
    "Pitcher Hershiser",
    "Birthplace of Ivan Turgenev",
    "1988 Cy Young winner Hershiser",
    "City whose name means \"eagle\" in Russian",
    "Dodgers broadcaster Hershiser",
    "Pitcher Hershiser",
    "Cy Young Award winner Hershiser",
    "Pitcher Hershiser",
    "Cy Young winner Hershiser",
    "Cy Young winner Hershiser",
    "City on the Oka River",
    "City northeast of Kiev",
    "Hershiser who was Sports Illustrated's 1988 Sportsman of the Year",
    "Hershiser of the 1980s-'90s Dodgers",
    "Ivan Turgenev's birthplace",
    "Hershiser who once pitched 59 consecutive scoreless innings",
    "City SSW of Moscow",
    "Pitcher Hershiser",
    "Pitcher Hershiser",
    "Pitcher Hershiser",
    "Pitcher Hershiser",
    "Pitcher Hershiser",
    "Dodger Hershiser",
    "Pitcher Hershiser",
    "Russian city and oblast",
    "Righty Hershiser",
    "Oka River city",
    "Baseball analyst Hershiser",
    "Pitcher-turned-sportscaster Hershiser",
    "Pitcher Hershiser",
    "ESPN's Hershiser",
    "Oblast between Kursk and Tula",
    "Hershiser on the mound",
    "City SSW of Moscow",
    "Pitcher Hershiser",
    "Pitcher Hershiser",
    "Russian city on the Oka",
    "Sportscaster Hershiser",
    "Russian oblast or its capital",
    "Baseball analyst Hershiser",
    "City liberated during the Battle of Kursk",
    "City founded by Ivan IV",
    "Cy Young winner Hershiser",
    "City on the Oka",
    "Baseball's Hershiser",
    "Sportscaster Hershiser",
    "1988 Cy Young winner Hershiser",
    "Turgenev's home in Russia",
    "Russian city on the Oka",
    "Russian city or oblast",
    "City south of Moscow",
    "Ivan Turgenev's birthplace",
    "City south of Moscow",
    "City SSW of Moscow",
    "Pitcher Hershiser",
    "Turgenev museum site",
    "City on the Oka",
    "Russian city on the Oka",
    "Hurler Hershiser",
    "City south of Moscow",
    "Pitcher Hershiser",
    "Oblast capital SSW of Moscow",
    "Russian city on the Oka",
    "Former Dodger Hershiser",
    "Hurler Hershiser",
    "City founded by Ivan IV",
    "Pitcher Hershiser",
    "City south of Moscow",
    "City south of Moscow",
    "Pitcher Hershiser",
    "Turgenev's birthplace",
    "Russian city, site of heavy W.W. II fighting",
    "Baseball's Hershiser",
    "City south of Moscow",
    "City on the Oka",
    "Pitcher Hershiser",
    "1988 World Series M.V.P. Hershiser",
    "City on the Oka",
    "Turgenev's birthplace",
    "City on the Oka",
    "Pitcher Hershiser",
    "Oblast on the Oka",
    "Baseball's Hershiser",
    "Hurler Hershiser",
    "Pitcher Hershiser",
    "City SSW of Moscow",
    "Hurler Hershiser",
    "Pitcher Hershiser",
    "Hurler Hershiser",
    "Pitcher Hershiser",
    "Hurler Hershiser",
    "Pitcher Hershiser",
    "Pitcher Hershiser",
    "Moundsman Hershiser",
    "Hurler Hershiser",
    "City south of Moscow",
    "Russia's ___ Region",
    "Turgenev's birthplace",
    "Turgenev?Æs birthplace",
    "Hurler Hershiser",
    "Hurler Hershiser",
    "Pitcher Hershiser",
    "Oka River city",
    "Hurler Hershiser",
    "Pitcher Hershiser",
    "Former teammate of Darryl",
    "Pitcher Hershiser",
    "City south of Moscow",
    "City south of Moscow",
    "Pitcher Hershiser",
    "Mr. Hershiser",
    "Heavily damaged city of W.W. II"
  ],
  "vice": [
    "Police unit, informally",
    "Wickedness",
    "Excessive drinking or gambling",
    "\"Miami ___\"",
    "Pence, e.g.",
    "Number two",
    "Smoking cigars, e.g.",
    "Number two",
    "It's just wrong",
    "Underworld activities",
    "Greed, for one",
    "Gambling or drinking",
    "Nasty habit",
    "Gambling too much, e.g.",
    "Police target",
    "#2, informally",
    "Second",
    "Rehab target",
    "Bad habit",
    "Bad habit, so to speak",
    "Number two",
    "Gambling, e.g.",
    "Bad habit, so to speak",
    "Number 2",
    "Number two",
    "Gambling, e.g."
  ],
  "case": [
    "Something that can be tried or cracked",
    "Cover for a smartphone",
    "Violin holder",
    "Instance",
    "Beer purchase",
    "Liquor store purchase",
    "Cold ___",
    "Ukulele accessory",
    "Lawyer's assignment",
    "Detective's assignment",
    "Try this!",
    "Instants",
    "Bulk purchase",
    "Something to try",
    "Detective's assignment",
    "Beer purchase",
    "Job for a sleuth",
    "Nominative or accusative",
    "Nominative, e.g.",
    "Lawyer's assignment",
    "Each episode of \"Law & Order,\" say",
    "24 bottles of beer",
    "See 7-Down",
    "Beer buy",
    "Matter of law",
    "Big beer buy",
    "See 19-Across",
    "Portmanteau, e.g.",
    "Job for the Hardy Boys",
    "Detective work",
    "\"___ closed!\"",
    "Detective's assignment",
    "Detective's assignment",
    "What a sleuth tries to close",
    "24 cans of beer, e.g.",
    "Docket item",
    "Jewelry store feature",
    "Lawyer's assignment",
    "Private eye's project",
    "Item on a docket",
    "Medical patient",
    "Costco quantity",
    "Look over before holding up",
    "Scope out, pre-heist",
    "Word in many Perry Mason titles",
    "Law assignment",
    "It's built for a trial",
    "Grammar concern",
    "Legal assignment",
    "Purchase for a beer blast",
    "Part of a judge's workload",
    "Purchase for a beer blast",
    "Word with legal or lower",
    "Docket item",
    "Instance",
    "24 cans of beer",
    "Whodunit title word",
    "Lawyer's assignment",
    "Costco quantity",
    "Matter for a judge",
    "Job for Perry Mason",
    "Lawyer's undertaking",
    "Wine unit",
    "Size up",
    "Carryall",
    "Steve ___, founder of 6-Down",
    "Four six-packs",
    "Possessive, e.g.",
    "24 cans",
    "Beer order",
    "Mason's assignment",
    "Detective's assignment",
    "Detective's assignment",
    "Big beer buy",
    "Lawyers make it",
    "Matter of grammar",
    "Part of a judge's docket",
    "Job for Holmes",
    "Beer buy",
    "Word in many Gardner titles",
    "Ventura's on it",
    "Detective's job",
    "Detective's assignment",
    "File object",
    "Four six-packs",
    "Liquor shop order",
    "Task for Holmes",
    "Job for Perry Mason",
    "Instance"
  ],
  "lola": [
    "Kinks song that Weird Al Yankovic parodied as \"Yoda\"",
    "Name spelled out in a Kinks hit",
    "Singer/dancer/actress Falana",
    "Lover of Tony in a hit 1978 song",
    "Kinks song set \"down in old Soho\"",
    "She said \"Little boy, gonna make you a man,\" in a Kinks song",
    "The \"she\" in the lyric \"She would merengue and do the cha-cha\"",
    "Name spelled out in a Kinks hit",
    "\"Damn Yankees\" vamp",
    "1970 hit for the Kinks",
    "Bugs Bunny's girlfriend",
    "The \"her\" in the lyric \"I met her in a club down in old Soho\"",
    "She \"walked like a woman and talked like a man,\" in a Kinks song",
    "1970 hit with a spelled-out title",
    "\"Damn Yankees\" seductress",
    "Who sings \"A Little Brains, A Little Talent\" in \"Damn Yankees\"",
    "Popular fragrance that's a girl's name",
    "The \"she\" in the lyric \"I'm not the world's most physical guy, / But when she squeezed me tight she nearly broke my spine\"",
    "___ Bunny (Bugs Bunny's girlfriend)",
    "Dancer in \"a club down in old Soho\"",
    "She \"drank champagne and danced all night,\" in song",
    "1970 hit about a girl with \"a dark brown voice\"",
    "Showgirl in the song \"Copacabana\"",
    "Transvestite of song",
    "She gets whatever she wants in \"Damn Yankees\"",
    "1970 Kinks album title starter",
    "1970 Kinks hit",
    "Woman who \"drank Champagne and danced all night,\" in song",
    "Hit song with the line \"When she squeezed me tight she nearly broke my spine\"",
    "\"Damn Yankees\" seductress",
    "The \"she\" in the lyric \"She walked up to me and she asked me to dance\"",
    "Bugs Bunny's girlfriend",
    "Role for 23-Across in \"Damn Yankees\"",
    "Name repeated in the lyric \"Whatever ___ wants, ___ gets\"",
    "The \"her\" in the lyric \"I met her in a club down in old Soho\"",
    "\"Damn Yankees\" woman who gets what she wants",
    "\"Damn Yankees\" vamp",
    "1970 Kinks song",
    "In a Kinks hit s/he \"walked like a woman and talked like a man\"",
    "\"She was ___ in slacks\" (part of an opening soliloquy by Humbert Humbert)",
    "1970 Kinks hit",
    "1970 hit with the lyric \"Girls will be boys and boys will be girls\"",
    "\"Run ___ Run\" (1998 thriller)",
    "Name repeated in \"Whatever ___ wants, ___ gets\"",
    "Doc's wife in \"Come Back, Little Sheba\"",
    "Kinks hit with a spelled-out title",
    "1970 Kinks hit",
    "\"Damn Yankees\" siren",
    "\"Damn Yankees\" vamp",
    "Whatever she wants, she gets, in song",
    "Joe Hardy's temptress",
    "1970 Kinks hit",
    "\"Damn Yankees\" vamp",
    "Joe Hardy's temptress, on Broadway",
    "\"Damn Yankees\" role",
    "1970 Kinks hit",
    "\"Damn Yankees\" seductress",
    "Name spelled out in a 1970 hit",
    "\"Run ___ Run\" (1998 film)",
    "\"Damn Yankees\" vamp",
    "Kinks song of 1970",
    "\"Damn Yankees\" vamp",
    "Anouk Aimee title role",
    "Notorious mistress Montez",
    "\"Run ___ Run\" (1998 film)",
    "Singer Falana",
    "\"Come Back, Little Sheba\" wife",
    "1970 Kinks hit",
    "\"Whatever ___ wants ...\"",
    "\"Copacabana\" showgirl",
    "Girl's name spelled out in a 1970 hit",
    "1970 Kinks hit",
    "Anouk Aimee title role of 1961",
    "\"The Art of Beauty\" author Montez",
    "\"Damn Yankees\" girl",
    "\"Damn Yankees\" seductress",
    "Singer Falana",
    "\"Damn Yankees\" vamp",
    "1970 Kinks hit",
    "\"Whatever ___ Wants\" (1955 song)",
    "\"Damn Yankees\" vamp",
    "\"Damn Yankees\" vamp",
    "\"Damn Yankees\" temptress",
    "1970 Kinks hit",
    "1970 Kinks hit",
    "\"Come Back, Little Sheba\" wife",
    "Degas's \"Miss ___ at the Cirque Fernando\"",
    "\"Damn Yankees\" vamp",
    "Gwen in \"Damn Yankees\"",
    "1970 Kinks hit",
    "Gwen Verdon role",
    "\"Damn Yankees\" siren",
    "\"Damn Yankees\" vamp",
    "She gets what she wants",
    "\"Whatever ___ wants...\"",
    "Actress Albright",
    "1970 Kinks hit",
    "\"Damn Yankees\" role",
    "\"Damn Yankees\" role",
    "She gets what she wants",
    "\"Damn Yankees\" seductress",
    "Singer Falana",
    "Falanaor Montez",
    "\"Damn Yankees\" vamp"
  ],
  "grub": [
    "Chow",
    "Vittles",
    "Chow",
    "Vittles",
    "Eats",
    "Eats",
    "Chow",
    "Chow",
    "Eats",
    "Eats",
    "Vittles",
    "Cowboy chow",
    "Vittles",
    "Oater fare",
    "Chuck wagon fare",
    "Chuck wagon load",
    "Eats",
    "Eats",
    "Food that is \"rustled up\"",
    "Eats",
    "Eats",
    "Eats",
    "Chow",
    "Vittles",
    "Chuck wagon fare",
    "Chow"
  ],
  "lyes": [
    "Some strong solutions",
    "Harmful substances to swallow",
    "Caustic cleaners",
    "Caustic compounds",
    "Caustic solutions",
    "Drain decloggers",
    "Strong cleansers",
    "Very basic things",
    "They burn",
    "Cleaning solutions",
    "Basic solutions",
    "Cleaning agents",
    "Caustic alkalis",
    "Caustic cleaners",
    "Burning solutions",
    "Caustic cleansers",
    "Caustic cleaners",
    "High-pH solutions",
    "Basic solutions",
    "They burn",
    "Corrosive cleaning agents",
    "Corrosive alkalis",
    "Caustic compounds",
    "Caustic solutions",
    "Products of wood ashes",
    "Caustic chemicals",
    "Soapmaking substances",
    "Strong cleaners",
    "Caustic alkalis",
    "Alkaline solutions",
    "NaOH solutions",
    "Cleaning cabinet supplies",
    "Strong cleaners",
    "Cleaning cabinet supplies",
    "Percolation products",
    "Caustic materials",
    "Potassium and sodium hydroxides",
    "Caustic solutions",
    "Soapmaker's supplies",
    "Cleaning solutions",
    "Cleaning agents"
  ],
  "goad": [
    "Spur",
    "Spur",
    "Prod",
    "Egg on",
    "Provoke",
    "Prod",
    "Egg on",
    "Egg on",
    "Egg on",
    "Egg on",
    "Prod",
    "Comment like \"Come on, you know you want to\"",
    "Push",
    "Prod",
    "Provoke",
    "Prod",
    "Prod",
    "Provoke",
    "Pointed encouragement",
    "Incite",
    "Prod",
    "Egg on",
    "Push",
    "Urge",
    "Goose",
    "Poke",
    "More than nudge",
    "Ox driver",
    "Push",
    "Push",
    "Prod",
    "Provoke",
    "Incite",
    "Stick with the herd?",
    "Poke",
    "Push",
    "Egg on",
    "Cattle driver",
    "Prod",
    "Prod",
    "Spur",
    "Incite",
    "Prod",
    "Prod",
    "Prod",
    "Spur",
    "Egg on",
    "Egg on",
    "Pointed stick"
  ],
  "lame": [
    "How kids might describe dad jokes",
    "Fancy fabric",
    "Metallic fabric",
    "Like a horse that can no longer be ridden",
    "___ duck",
    "Fabric with sheen",
    "Shiny fabric",
    "Unfunny, as a joke",
    "Uncool",
    "Sorry",
    "Hardly adequate",
    "Wake",
    "Totally uncool",
    "Not cool, informally",
    "Unbelievable, say",
    "Flimsy",
    "Unconvincing",
    "Limping, say",
    "Weak",
    "Hobbling, say",
    "Uncool",
    "Unconvincing",
    "Half-baked",
    "Pricey fabric",
    "Unconvincing",
    "Showy wear",
    "Pathetic",
    "Weak",
    "Poor, as an excuse",
    "Poor, as excuses go",
    "Formal fabric",
    "Beneath serious consideration",
    "Inadequate",
    "Hobbling",
    "Weak, as an effort",
    "Not really believable",
    "___ duck",
    "Hobbling",
    "Dress material for a ball",
    "Hobbling",
    "Poor, as excuses go",
    "Poor, as excuses go",
    "Unconvincing",
    "Pathetic",
    "Like some excuses",
    "Unconvincing",
    "Pathetic",
    "Shiny gold fabric",
    "Flimsy, as an excuse",
    "Liberace fabric",
    "Fancy fabric",
    "Getting hardly any laughs",
    "Hobbling",
    "Weak, as an excuse",
    "Couturier's fabric",
    "Crippled",
    "Fabric with gold or silver threads",
    "Unable to walk",
    "Disabled",
    "Gold ___",
    "Poor, as excuses go",
    "Unconvincing",
    "Glittery material",
    "Hardly believable",
    "Hardly believable",
    "Sorry",
    "Pathetic",
    "Shiny fabric",
    "Break a leg?",
    "Glittery fabric",
    "Ineffectual",
    "Metallic fabric",
    "Shoot in the foot?",
    "Weak, as an excuse",
    "Hamstrung",
    "Costly cloth"
  ],
  "moog": [
    "Big name in synthesizers",
    "Robert who pioneered in electronic music",
    "Keyboard instrument",
    "Famed synthesizer",
    "Instrument in \"Switched-On Bach\"",
    "Synthesizer pioneer Robert",
    "Synthesizer designer Robert",
    "\"Switched-On Bach\" instrument",
    "Synthesizer designer Robert",
    "Electronic music pioneer Robert",
    "Eponymous instrument maker Robert",
    "\"A Clockwork Orange\" instrument",
    "Electronic music pioneer Robert",
    "Electronic music pioneer",
    "___ synthesizer",
    "Synthesizer pioneer",
    "Synthesizer innovator Bob",
    "___ synthesizer",
    "Synthesizer pioneer",
    "Keyboard instrument",
    "Many a synthesizer",
    "Music synthesizer",
    "___ synthesizer",
    "Synthesizer man",
    "Synthesizer inventor",
    "Modern music maker",
    "Synthesizer"
  ],
  "mano": [
    "Spanish hand",
    "Word before and after \"a\"",
    "Hand: Sp.",
    "Hand, in Honduras",
    "Word before and after \"a\"",
    "Spanish hand",
    "Hand on a hacienda",
    "Mexican's hand",
    "Hand, to Javier",
    "Hand, to Jose",
    "Hand: Sp.",
    "A leader and follower?",
    "Ranchero's hand",
    "Hand for a mariachi band?",
    "Word before and after \"a\"",
    "Jose's hand",
    "\"Look ___ hands!\"",
    "Palma's place",
    "Ranchero's hand",
    "Pedro's hand in Oman",
    "Hombre's hand",
    "A la ___ (nearby: Sp.)",
    "\"Look ___ hands!\"",
    "War preceder",
    "Word before and after \"a\"",
    "Hand, to Hernando",
    "War preceder",
    "Word before and after \"a\"",
    "Hand: Sp.",
    "___-war bird",
    "Rancho hand?",
    "Rancho hand?",
    "\"Look ___ hands!\"",
    "Hand: Sp.",
    "Jorge's hand",
    "\"Look ___ hands!\"",
    "Hand, to Hernando",
    "___-war bird (predatory flier)",
    "Jorge's hand",
    "Hand, to Herrea",
    "___ War",
    "Horse racing's ___ War"
  ],
  "amor": [
    "Spanish love",
    "\"Omnia vincit ___\" (\"Love conquers all\": Lat.)",
    "God of love",
    "\"Mi ___\" (Spanish words of endearment)",
    "Spanish opposite of odio",
    "Subject of una balada",
    "\"De ___ y de Sombra\" (Isabel Allende novel)",
    "\"___ Gitano,\" Spanish-language hit for Beyonce and Alejandro Fernandez",
    "Spanish sweetheart",
    "Subject for Enrique Iglesias",
    "Frequent topic for Pablo Neruda",
    "Love of mythology?",
    "Beloved: Lat.",
    "Love, in Latin",
    "Mythical shooter",
    "Spanish sweetheart",
    "Roman god of love",
    "Omnia vincit ___",
    "Latin love",
    "Latin lover's love",
    "God of love",
    "Caligula's love",
    "Latin love",
    "Roman god of love",
    "What conquers \"omnia,\" in a phrase",
    "Opposite of odio",
    "Another name for Cupid",
    "Cupid, or his concern",
    "Baby with a bow",
    "Love, in Lisbon",
    "Love personified",
    "Italian ballad subject",
    "Love: Lat.",
    "Subject for a mariachi band",
    "Roman god of love",
    "Bow-toting deity",
    "Roman god of love",
    "Omnia vincit ___",
    "Enrique Iglesias song subject",
    "Latin love",
    "Caesar's love",
    "Love personified",
    "Topic for Catullus",
    "Winged god",
    "Theme for Julio Iglesias",
    "Love overseas",
    "The love of Juan's life?",
    "Love, in 51-Down",
    "Antony's love",
    "Love of Spain",
    "Valentine's Day figure",
    "Love, to a Latino",
    "Son of Venus",
    "Antony's love",
    "Love overseas",
    "Cupid",
    "Bow-toting god",
    "Marc Antony's love",
    "___ patriae",
    "Son of Venus",
    "Marc Antony's love",
    "Love, personified",
    "God of love",
    "Lover boy?",
    "Love, in Lima",
    "Bow and arrow user",
    "Nero's love",
    "Marc Antony's love",
    "\"___ vincit insomnia\" (Christopher Fry quip)",
    "Eros, to Romans",
    "Valentine's Day cherub",
    "Love god",
    "Marc Antony's love",
    "Love",
    "Roman god of love",
    "Love personified",
    "Marc Antony's love",
    "Bow-wielding boy",
    "God of love",
    "Subject for Ovid",
    "Another name for Cupid",
    "Cupid's counterpart",
    "Love figure",
    "Love god",
    "Love personified",
    "Livy's love",
    "Cupid",
    "De Falla's \"El ___ Brujo\"",
    "Love, in Lima",
    "Love god",
    "Topic for Ovid",
    "Omnia vincit ___",
    "Archer of myth",
    "Cupid",
    "God of love",
    "1944 Bing Crosby hit",
    "Love",
    "Archer of myth",
    "Livy's love",
    "Son of Venus",
    "Love, Spanish-style",
    "Archer of myth",
    "Cupid",
    "Boy with a bow",
    "Picasso's love",
    "___ patriae",
    "Love, Spanish-style",
    "Deity with a bow",
    "Archer of myth",
    "Iglesias tune",
    "Cupid",
    "Love, to Luis",
    "Love, Spanish-style",
    "Omnia vincit ___",
    "Son of Venus",
    "Marc Antony's love",
    "Love overseas",
    "\"Orfeo ed Euridice\" god",
    "Love, to Livy",
    "Latin love",
    "Cupid",
    "Omnia vincit ___",
    "Roman Eros",
    "1944 Bing Crosby hit",
    "Don Juan's emotion",
    "Valentine's Day matchmaker",
    "Love, to Livy",
    "Love personified",
    "Boy with a bow",
    "\"Orfeo ed Euridice\" soprano",
    "Love, overseas",
    "Cupid",
    "Love, in Oviedo",
    "Love, to Livy",
    "Son of Venus",
    "Love, to Livy",
    "Julio Iglesias hit",
    "Cupid",
    "1944 Andy Russell hit",
    "___ patriae (patriotism)",
    "It makes the mundus go round",
    "Love, Spanish-style",
    "Subject in Virgil's \"Eclogues\"",
    "Cupid",
    "'44 Crosby hit",
    "Eros",
    "Bing Crosby best seller"
  ],
  "nebs": [
    "Birds' bills",
    "Birds' bills",
    "Bird bills",
    "Bird bills",
    "Bird bills",
    "Birds' beaks",
    "Beaks",
    "Bills",
    "Bills",
    "Bills",
    "Beaks",
    "Beaks",
    "Beaks",
    "Bills",
    "Bills"
  ],
  "alpo": [
    "Popular dog 105-Down",
    "Brand for Rover",
    "T-Bonz treats brand",
    "\"Quick, get that dog some ___\" (old ad slogan)",
    "Prime Cuts Extra Gravy brand",
    "Prime Cuts dog food brand",
    "T-Bonz dog treat brand",
    "Big name in dog food",
    "Big name in dog food",
    "Prime Cuts and T-Bonz brand",
    "Brand with Prime Cuts",
    "Canned brand",
    "Dog food brand",
    "Iams competitor",
    "\"Real dogs eat meat\" sloganeer",
    "Purina One alternative",
    "Brand in the pet food aisle",
    "Pet food brand",
    "Brand for Fido",
    "Competitor of Pedigree",
    "Dinner in a bowl",
    "Brand with a Gravy Cravers line",
    "Purina One alternative",
    "Dog food brand",
    "Purina product line",
    "Pedigree alternative",
    "Prime Cuts brand",
    "Canned food made by Nestle",
    "Food for Fido",
    "Canned food you don't eat",
    "Brand in a bowl",
    "Dog Chow alternative",
    "Brand once pitched by Garfield",
    "Iams competitor",
    "Bowlful for Bowser",
    "Dinner in a can, maybe",
    "Boxer's fare?",
    "Chow chow chow brand",
    "Brand once pitched by Lorne Greene",
    "Brand for woofers, but not tweeters?",
    "Dinner in a can",
    "Mighty Dog rival",
    "Bowser's bowlful",
    "Mighty Dog competitor",
    "Food brand that was the sole sponsor of the first \"60 Minutes\" broadcast",
    "Purina brand",
    "Dog food brand",
    "Meal-in-a-can brand",
    "\"Generations of healthy, happy pets\" sloganeer",
    "Rival rival",
    "Prime Cuts maker",
    "Greyhound fare?",
    "Boxer's fare",
    "Meat dish often served with gravy",
    "Lab food?",
    "Bowlful for Spot",
    "Dinner that typically comes in a bowl",
    "Purina competitor",
    "Brand in a can",
    "Brand for Fido",
    "Food with gravy",
    "Food in bags or cans",
    "Brand for Fido",
    "Best friend's dinner, maybe",
    "Chow chow chow",
    "Dinner in a bowl",
    "Spike's bowlful",
    "Food in a can",
    "Gravy Train competitor",
    "Brand for Bowser",
    "Labrador food?",
    "Doggie dinner",
    "Food for Fido",
    "Brand that's canned",
    "Dog dish filler",
    "\"For a long and healthy life\" sloganeer",
    "Food in a bowl",
    "Bowser's bowlful",
    "Dog food brand",
    "Chow chow chow",
    "Brand for Rover",
    "Bowlful for Bowser",
    "Nestle pet food brand",
    "\"Generations of healthy, happy pets\" sloganeer",
    "Friskies brand",
    "Food for Fido",
    "Boxer's fare",
    "Food for Fido",
    "Chow chow chow",
    "Brand for Bowser",
    "Brand for Bowser",
    "Ken-L Ration competitor",
    "\"Generations of healthy, happy pets\" brand",
    "Purina competitor",
    "Fare for Fido",
    "Gravy Train competitor",
    "Brand for Bowser",
    "Purina alternative",
    "Food for Fido",
    "Bowser's bowlful",
    "___ Snaps (dog treats)",
    "Rival of Rival",
    "Chow chow, maybe",
    "Rival rival",
    "10-Down's food, perhaps",
    "Rival rival",
    "Rival rival",
    "Pet food brand",
    "Canine bowlful",
    "Rival rival",
    "Pick of the litter?"
  ],
  "food": [
    "See 104-Down",
    "Word after soul or solid",
    "Word with pyramid or court",
    "Gourmand's passion",
    "\"Fish are friends, not ___\" (line from \"Finding Nemo\")",
    "What's thrown in a cafeteria fight",
    "It's picked up in a mess",
    "Vittles",
    "What's spread in a spread",
    "Kind of pyramid",
    "Chow",
    "___ pyramid, four examples of which are seen in this puzzle",
    "Groceries",
    "Victuals",
    "Grocery offering",
    "Interstate sign",
    "Kind of chain",
    "Stomach filler",
    "Nourishment",
    "Cuisine",
    "It's sometimes \"junk\"",
    "Fare",
    "Eat it",
    "Provisions",
    "Rations",
    "Mimi Sheraton subject",
    "It's of alimentary importance"
  ],
  "arya": [
    "Daughter of Ned Stark on \"Game of Thrones\"",
    "Name of the girl on \"Game of Thrones\" who said \"A girl has no name\"",
    "Maisie Williams's role on \"Game of Thrones\"",
    "Killer of the Night King on \"Game of Thrones\"",
    "___ Stark, \"Game of Thrones\" protagonist"
  ],
  "cars": [
    "Things not allowed in New York's Central Park",
    "Parts of roller coasters",
    "2006 film with the tagline \"Keep it wheel\"",
    "Mustang and Pinto, for two",
    "Dealers have lots of them",
    "Autos",
    "Sleepers, e.g.",
    "What a jam is packed with",
    "2006 film with massive profits in related toy sales",
    "Detroit factory output",
    "Some ferry cargo",
    "They may get into a jam",
    "2006 Pixar film",
    "Train parts",
    "Sleeper and others",
    "Literally, with 19-Across, a Western state capital",
    "Autos",
    2006,
    "Autos",
    "Traffic components",
    "Hit 2006 Disney-Pixar film",
    "Autos",
    "They get tired",
    "Dodgem units",
    "Bumper ___",
    "Consumer Reports topic",
    "Mercury and Saturn, but not Uranus",
    "Guys' chat topic",
    "Lotful",
    "A shopping mall has lots of these",
    "Mustang and Impala",
    "Train segments",
    "\"Shake It Up\" band, with \"the\"",
    "They're always tired",
    "Bumper ___",
    "Mercury and Saturn, for instance",
    "Detroit products",
    "Road runners?",
    "Lincoln and others",
    "What lots have lots of",
    "Train components"
  ],
  "trix": [
    "Magician's favorite cereal?",
    "\"Absofruitalicious\" cereal, in ads",
    "Colorful breakfast bowlful",
    "Product with six fruity flavors",
    "Brand with a rabbit symbol",
    "Colorful cereal",
    "One of the blanks in the cereal slogan \"___ are for ___\"",
    "Colorful corn balls",
    "Cereal that reverted to spherical shapes in 2007",
    "Colorful breakfast option",
    "Multicolored breakfast food",
    "General Mills offering",
    "Honeycomb alternative",
    "\"___ are for kids\" (ad slogan)",
    "Cereal advertised with a \"silly rabbit\"",
    "Fare \"for kids\"",
    "Cereal \"for kids\"",
    "Rabbit food?",
    "Cereal whose ads feature a \"silly rabbit\"",
    "Cereal \"for kids\"",
    "Cereal that's \"for kids\"",
    "Cereal with a rabbit mascot",
    "Cereal for 34-Down",
    "Brand pitched by a rabbit",
    "Multicolored breakfast cereal",
    "Cereal not for rabbits",
    "General Mills brand",
    "Cereal \"for kids\"",
    "Kids' stuff?"
  ],
  "gout": [
    "Affliction of Benjamin Franklin",
    "Joint malady",
    "Cause of joint pain",
    "Joint problem",
    "Joint ailment",
    "Joint issue",
    "Toe woe",
    "Joint woe that afflicted Benjamin Franklin",
    "Toe trouble",
    "Joint problem",
    "Joint inflammation",
    "Joint problem",
    "Problem in the joints",
    "Joint deposit?",
    "Pain in the joints",
    "Foot problem"
  ],
  "ammo": [
    "Snowballs, in a snowball fight",
    "Food, in a food fight",
    "Bullets and such",
    "Hunting store purchase",
    "Bullets and such",
    "Arsenal supply",
    "Info compiled for a debate, metaphorically",
    "See 61-Across",
    "It might be found on a belt",
    "Info to use against somebody, metaphorically",
    "Bullets and BBs",
    "Cartridge contents",
    "Some rounds",
    "They may make the rounds",
    "Some rounds",
    "It's loaded",
    "What a bandoleer holds",
    "Police rounds",
    "Arsenal stock",
    "Guns & ___ magazine",
    "Bullets, informally",
    "Buckshot and such",
    "Rounds of bullets",
    "Armory supply, informally",
    "Bandoleer filler",
    "BBs and bullets",
    "What might go on a belt",
    "Magazine filler",
    "Bullets, BB's and such",
    "Bullets and such",
    "Gun belt contents",
    "Cartridges, e.g.",
    "Bullets, informally",
    "Cannonballs and such",
    "Stuff to be loaded",
    "It might be in a belt",
    "Cartridge filler",
    "It's loaded",
    "Bullets",
    "Shot, e.g.",
    "Shells and bullets",
    "Guns' partner",
    "It's found in arms",
    "Bullets",
    "Bandoleer contents",
    "Need for heat",
    "Bullets and BBs",
    "It's found in chambers",
    "Survivalist's stockpile",
    "It fills barrels",
    "BB's and shells",
    "Gun dealer's stock",
    "Magazine's contents",
    "BB's, e.g.",
    "Magazine contents",
    "Some cartridges",
    "Kind of belt",
    "Bullets and such",
    "Magazine supply",
    "Chamber stock",
    "It's loaded",
    "Pellets, e.g.",
    "Buckshot and such",
    "Shot, for short",
    "Ordnance component",
    "BB's and bullets",
    "It's loaded",
    "Magazine contents",
    "Bullets, e.g.",
    "It flies through barrels",
    "Contents of some barrels",
    "BB's and bullets",
    "Shells, etc.",
    "Rounds",
    "Magazine section?",
    "Bullets",
    "BB's, e.g.",
    "Bullets",
    "Magazine supplies",
    "Bullets",
    "It's shot",
    "Shots, for short",
    "Bullets, e.g.",
    "It's found in a round",
    "Bullets, e.g.",
    "Missiles and such",
    "Shots, for short",
    "Bullets",
    "Shots, for short",
    "Survivalist's stockpile",
    "Shells, for short",
    "Shells, e.g.",
    "Shot, e.g.",
    "Bullets and such",
    "Shells and such",
    "BB's and such",
    "Rounds, say",
    "Shot, e.g.",
    "Shooter filler",
    "Bullets and such",
    "Bullets",
    "Armory holdings",
    "Magazine contents",
    "Grenades, e.g.",
    "BB's",
    "Bullets",
    "Cartridge fill",
    "Bullets",
    "Army need",
    "Howitzer need",
    "BB's",
    "BB's"
  ],
  "gena": [
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Oscar nominee Rowlands",
    "Rowlands of \"A Woman Under the Influence\"",
    "Actress Rowlands",
    "\"Baywatch\" actress ___ Lee Nolin",
    "Actress Rowlands",
    "Rowlands of \"Gloria\"",
    "Actress Rowlands",
    "Rowlands of \"Gloria\"",
    "Actress Rowlands",
    "Rowlands of \"Another Woman\"",
    "___ Lee Nolin of \"Baywatch\"",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Rowlands of \"Hope Floats\"",
    "Rowlands of ?ôGloria?ö",
    "Co-star of Mia in \"Another Woman\"",
    "Rowlands of \"Gloria\"",
    "Rowlands of \"Light of Day\"",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands",
    "Actress Rowlands"
  ],
  "mind": [
    "Look after",
    "Care about",
    "See 57-Across",
    "Have a problem with",
    "Be bothered by",
    "Look after",
    "\"Do you ___?\"",
    "Have an objection",
    "Look after",
    "See 49-Down",
    "With 72-Across, motto of a fitness trainer?",
    "Care",
    "What an idea comes to",
    "Intellect in 1500",
    "Take exception to",
    "Give a darn",
    "It's a \"terrible thing to waste\"",
    "Take care of, as a store",
    "Care",
    "Intellect",
    "Attention",
    "Be bothered",
    "Attention",
    "\"Do you ___?\"",
    "Listen and obey",
    "Look after",
    "Watch over",
    "Care",
    "Pay no ___"
  ],
  "lick": [
    "Short jazz riff",
    "Jazz riff",
    "Spank",
    "Defeat",
    "Beat",
    "Show of affection from a dog",
    "Beat",
    "Beat soundly",
    "Salt ___",
    "Spank",
    "Whup",
    "Drub",
    "Trounce",
    "Shellac"
  ],
  "chan": [
    "Johnny with 10 World Series of Poker bracelets",
    "Jackie of \"Rush Hour\"",
    "Jackie on the Hollywood Walk of Fame",
    "Jackie of \"Shanghai Noon\"",
    "Jackie of \"Rush Hour\"",
    "Fictional Charlie",
    "Jackie of \"Rush Hour\"",
    "Jackie of \"The Tuxedo\"",
    "Jackie of \"Shanghai Knights\"",
    "See 3-Down",
    "Action film star Jackie",
    "Martial artist Jackie",
    "Actor Jackie who's his own stuntman",
    "Jackie with acting chops",
    "Jackie of \"Shanghai Noon\"",
    "Charlie who said \"Waiting for tomorrow waste of today\"",
    "\"Keeper of the Keys\" was the last novel he was featured in",
    "Martial artist Jackie",
    "Detective in \"The Shanghai Cobra\"",
    "Fictional character who first appeared in \"The House Without a Key\"",
    "Peter Sellers parodied him in \"Murder by Death\"",
    "\"The Shanghai Cobra\" hero, 1945",
    "\"Rush Hour\" star, 1998",
    "Actor Jackie",
    "\"The Chinese Parrot\" hero",
    "\"Rush Hour\" star, 1998",
    "Sleuth created by Biggers",
    "\"Rumble in the Bronx\" star",
    "Director and star of \"Armour of God\"",
    "Movie sleuth",
    "Classic detective",
    "\"The Chinese Parrot\" hero",
    "Detective of note",
    "\"The Black Camel\" detective",
    "\"The House Without a Key\" hero",
    "Detective Charlie",
    "\"The Chinese Parrot\" hero",
    "\"Behind That Curtain\" detective",
    "\"Sky Dragon\" detective",
    "Honolulu-based detective",
    "Detective Charlie",
    "Detective Charlie",
    "\"The Chinese Parrot\" detective",
    "Earl Derr Biggers hero",
    "Detective with a number one son",
    "Role for Oland"
  ],
  "ward": [
    "City section",
    "See 105-Across",
    "Fend (off)",
    "Voting district",
    "Prison division",
    "Fend (off)",
    "See 21-Down",
    "Certain charge",
    "Fend (off)",
    "Voting district",
    "Prison unit",
    "Fend (off)",
    "___ of the state",
    "Stave (off)",
    "One in custody",
    "Voting area",
    "Prison division",
    "Fend (off)",
    "Charge",
    "Charge",
    "Voting district",
    "City district",
    "One in custody",
    "Prison area",
    "Precinct",
    "Voting district",
    "Hospital unit",
    "Voting district",
    "Place for a heeler (or a healer)",
    "One of TV's Cleavers",
    "Voting district",
    "District",
    "Estella, to Miss Havisham"
  ],
  "acer": [
    "Big name in laptops",
    "Taiwanese electronics giant",
    "Laptop brand",
    "HP competitor",
    "Taiwanese PC maker",
    "Good server",
    "Taiwan-based computer giant",
    "Lenovo competitor",
    "Taiwanese computer giant",
    "One providing great service?",
    "Computer company with a Predator line",
    "Big name in laptops",
    "Tennis serving whiz",
    "Big name in laptops",
    "Taiwanese computer giant",
    "Taiwan-based electronics giant",
    "Big name in laptops",
    "Multinational electronics company",
    "\"Explore beyond limits\" sloganeer",
    "Taiwanese computer brand",
    "Dell competitor",
    "Computer company with the slogan \"Explore beyond limits\"",
    "Taiwan-based computer maker",
    "Lenovo alternative",
    "A-1 tennis server",
    "One scoring on a serve",
    "Taiwanese PC maker",
    "Taiwanese computer brand",
    "Taiwan-based computer giant",
    "\"A touch more\" sloganeer",
    "Big name in computers",
    "Taiwanese PC maker",
    "Taiwanese computer giant",
    "Aspire PC maker",
    "Serena Williams, often",
    "Lenovo competitor",
    "TravelMate notebook maker",
    "Computer giant",
    "Taiwan-based computer giant",
    "Tennis whiz",
    "Computer maker",
    "Taiwan-based computer maker",
    "Big name in PCs",
    "Big PC maker",
    "Taiwanese computer maker",
    "One not allowing a volley",
    "Expert server",
    "One-hit wonder?",
    "One who has a quick point to make?",
    "Stellar server",
    "Big name in desktop computers",
    "Whiz at tennis serves",
    "Super server",
    "Andy Roddick, at times",
    "Great server",
    "One with a sterling service",
    "Toshiba competitor",
    "Super server",
    "Fast server?",
    "Tennis serving whiz",
    "Service expert?",
    "Maple genus",
    "Whiz of a tennis server",
    "Strong server",
    "Maple genus",
    "Pete Sampras, notably",
    "Tennis serving whiz",
    "Super server",
    "Pete Sampras, sometimes",
    "Hot server",
    "Tennis whiz",
    "One who might slip one past you",
    "Court whiz",
    "Pete Sampras, at times",
    "Service expert",
    "One with a fastball?",
    "Tennis whiz",
    "Pete Sampras, often",
    "One adept at serving",
    "Top service provider",
    "Super server",
    "Cannonball maker, say",
    "Agassi, at times",
    "Test killer",
    "Perfect server",
    "Pete Sampras, in a way",
    "Excellent server",
    "Scorer on serve",
    "Sampras, at times",
    "One who gives special service?",
    "Super server, in tennis",
    "Maple genus",
    "Tennis's Ivanisevic, often"
  ],
  "line": [
    "Train route",
    "\"I forgot what I'm supposed to say here!\"",
    "Betting odds",
    "With 19-Across, Cotton-Eyed Joe, e.g.",
    "Script unit",
    "Railroad division",
    "A cameo might have one",
    "Conversation piece",
    "Bank annoyance",
    "Queue",
    "Airport security holdup",
    "What T.S.A. Precheck helps people avoid",
    "\"y = 2x,\" e.g.",
    "Subway ___",
    "Shoppers' headache",
    "Queue",
    "\"Come here often?,\" e.g.",
    "Checkout headache",
    "Queue",
    "\"Come here often?,\" e.g.",
    "Dialogue unit",
    "It's often long at Disneyland",
    "Word that can follow each part of the answers to the six starred clues",
    "See 53-Across",
    "Extra desire?",
    "Very short note",
    "Parallel, e.g.",
    "Bit of dialogue",
    "Singles bar delivery",
    "Queue",
    "y = 3x + 5 representation, e.g.",
    "\"Come here often?\", e.g.",
    "Checkout annoyance",
    "Bit of dialogue",
    "Queue",
    "Quick note",
    "Queue",
    "Unemployment office sight",
    "Singles bar delivery",
    "Y-axis, for one",
    "Prompt delivery",
    "Queue",
    "Airport delay?",
    "\"Come here often?,\" e.g.",
    "Word that can follow the starts of 17-, 27-, 44- and 59-Across",
    "Queue",
    "See 61-Across",
    "See 7-Down",
    "Play bit",
    "Pickup shtick?",
    "Propositional phrase?",
    "Bank annoyance",
    "Come-on",
    "Queue",
    "Impatient person's annoyance",
    "\"Don't I know you from somewhere?,\" e.g.",
    "Queue",
    "Impatient person's dread",
    "Area of expertise",
    "\"Do you come here often?,\" e.g.",
    "Ancestry",
    "Quick note",
    "Prompter's whisper",
    "\"Come here often?,\" e.g.",
    "Field of work",
    "Field of work",
    "Trade",
    "Queue",
    "Queue",
    "An actor may trip over one",
    "Singles bar icebreaker",
    "\"What's your sign?\" is one",
    "Bank sight",
    "Trade",
    "Result of a gas shortage",
    "Sailor's rope",
    "Unemployment office sight",
    "Help from a lifeguard",
    "Queue",
    "Field of work",
    "Pickup shtick?",
    "Betting odds",
    "Come-on",
    "Route",
    "\"You come here often?\" e.g.",
    "Come-on",
    "Glib comment",
    "Word with drawing or drive",
    "Come-on",
    "Queue",
    "Supermarket phenomenon",
    "Rule",
    "Motor vehicle bureau feature",
    "Queue",
    "Come-on",
    "Queue",
    "Part of a fishing trio"
  ],
  "ails": [
    "Isn't 100%",
    "Suffers (from)",
    "Troubles",
    "Is down with something",
    "Troubles",
    "Plagues",
    "Troubles",
    "Is sick",
    "Has a bug",
    "Suffers",
    "Is sick",
    "Is under the weather",
    "Feels sick",
    "Afflicts",
    "Feels bad",
    "Suffers",
    "Distresses",
    "Isn't well",
    "Is unwell",
    "Is sick",
    "Has something",
    "Distresses",
    "Has the bug, say",
    "Isn't fine",
    "Is bedridden, say",
    "Feels lousy",
    "Is indisposed",
    "Isn't well",
    "Afflicts",
    "Languishes",
    "Distresses",
    "Isn't O.K.",
    "Has a fever, say",
    "Needs medicine",
    "Is peaked",
    "Suffers bodily woes",
    "Good for what ___ you",
    "Bothers",
    "Is bedridden",
    "Is indisposed",
    "Hurts",
    "Needs a doctor",
    "Has a temperature, say",
    "Is under the weather",
    "Afflicts",
    "Troubles",
    "Suffers",
    "Is laid up",
    "Is sick",
    "Feels punk",
    "Is unwell",
    "Feels fluish",
    "Afflicts",
    "Is not well",
    "Is under the weather",
    "Is sick",
    "Feels bad",
    "\"What ___ you?\" (doc's query)",
    "Is under the weather",
    "Distresses",
    "Is under the weather",
    "Is bedridden",
    "Isn't doing well",
    "Is unwell",
    "Suffers",
    "Is under the weather",
    "Troubles",
    "Troubles",
    "Is near bankruptcy",
    "Has a bug",
    "Is sick",
    "Isn't feeling good",
    "Troubles",
    "Suffers",
    "Feels punk",
    "Bothers",
    "Doesn't feel right",
    "Is sick",
    "Troubles",
    "Is bedbound",
    "Feels bad",
    "Is sick",
    "Aches",
    "Afflicts",
    "Is under the weather",
    "Is sickly",
    "Is green around the gills",
    "Afflicts",
    "Troubles"
  ],
  "apbs": [
    "Police dept. alerts",
    "Some urgent messages, in brief",
    "Police dept. alerts",
    "\"Be on the lookout\" messages, for short",
    "Blue alerts, in brief",
    "Some lookout notices, for short",
    "\"Be on the lookout\" alerts, for short",
    "Black-and-white alerts, briefly",
    "They may precede high-speed chases, in brief",
    "\"Be on the lookout\" messages, for short",
    "Old calls from HQ",
    "Black-and-white alerts, briefly",
    "Black-and-white transmissions, briefly?",
    "Heat alerts, for short?",
    "Alerts to cruisers, for short",
    "They're issued to cruisers, briefly",
    "\"Be on the lookout\" alerts, for short",
    "Urgent alerts, briefly",
    "Some calls to smokeys",
    "Police dept. alerts",
    "They might prevent getaways, briefly",
    "Police dept. notices",
    "Getaway alerts, for short",
    "Alerts, for short",
    "\"Be on the lookout\" messages, briefly",
    "Some radio dispatches, for short",
    "Calls for squads, in short",
    "Getaway stoppers, briefly",
    "Police dept. alerts",
    "Some alerts, for short",
    "Squad cars get them, in brief",
    "Some alerts, for short",
    "P.D. alerts",
    "They're sent out from HQ",
    "\"N.Y.P.D. Blue\" alerts",
    "N.Y.P.D. calls",
    "Police radio messages, for short",
    "P.D. alerts"
  ],
  "frog": [
    "Animal symbol of fertility in ancient Egypt",
    "Minor throat problem",
    "Fairy tale prince, perhaps",
    "Throat problem",
    "Fairy tale alter ego",
    "Talking animal in some fairy tales",
    "Throat problem",
    "Creature that goes \"ribbit\"",
    "Island hopper?",
    "Kermit, e.g.",
    "Parts of it may be revealed in biology class",
    "Jumper",
    "Creature that goes \"ribbit!\"",
    "Mark Twain's Dan'l was one",
    "Throat problem",
    "Prince before being kissed, in a fairy tale",
    "Symbol of the WB network",
    "Enchanted prince, perhaps",
    "Budweiser ad creature",
    "Croaker",
    "Kind of kick",
    "Kermit, e.g.",
    "Tadpole, eventually",
    "Throat problem?"
  ],
  "gish": [
    "Lillian ___, the First Lady of American Cinema",
    "Lillian of silent films",
    "\"Mystic Pizza\" actress Annabeth",
    "Lillian of silents",
    "\"Intolerance\" actress Lillian",
    "Actress Lillian",
    "\"Intolerance\" actress",
    "\"The Whales of August\" actress"
  ],
  "fish": [
    "With 11-Down, what each of this puzzle's groups of circles represents",
    "Source of some omega-3s",
    "Drop a line, say",
    "School group",
    "Drop a line?",
    "Porgy and bass",
    "What trawlers trawl for",
    "Bouillabaisse base",
    "One taking the bait",
    "Angle",
    "Look (for), as a compliment",
    "Use the items found at the ends of the answers to 20-, 31-, 47- and 56-Across",
    "Salmon or sole",
    "Symbol of Christianity",
    "Hamilton ___, two-term secretary of state under Grant",
    "It may be landed with a hook",
    "Take the bait?",
    "Shore dinner entree",
    "Menu section",
    "Menu heading",
    "Something hidden in 20-, 28-, 43- and 52-Across ... or landed with the help of 1-, 10-, 37- and 63-Across",
    "Go for a bite?",
    "They take the bait",
    "Angle",
    "Angle (and a three-word hint to this puzzle's theme)",
    "Seek at random, with \"for\"",
    "Easy mark, in cards",
    "Porgy and bass",
    "School group?",
    "Angle",
    "Dangle poles over a pier, say",
    "School group?",
    "Person easily duped",
    "Drop a line?",
    "Ichthyologist's study",
    "Catch a few rays?",
    "A part of this starts 17-, 30-, 48- and 63-Across",
    "Drop a line?",
    "Denizens of the 46-Across",
    "Porgy and bass",
    "Christian symbol",
    "Burbot or turbot, e.g.",
    "Bass, for one",
    "Alternative to 13 Across"
  ],
  "owed": [
    "Was in the red",
    "Like debts",
    "Overdue",
    "Due",
    "\"Never in the field of human conflict was so much ___ by so many to so few\": Churchill",
    "Was bankrupt, say",
    "Wasn't square",
    "Was in the red",
    "*Had charges",
    "Was behind, in a way",
    "Was short",
    "Due",
    "Had a balance",
    "Was behind",
    "Outstanding",
    "Was in the red",
    "Due",
    "Was shy",
    "Had to hand it to?",
    "Saw red?",
    "Had a bill, still",
    "Was in debt",
    "Had to settle",
    "Was in the red",
    "Wasn't in the black",
    "Was outstanding",
    "Outstanding",
    "Was in the red",
    "Was beholden to",
    "Had payments due",
    "Outstanding",
    "Didn't have enough",
    "Outstanding",
    "Had creditors",
    "Ran a tab",
    "Outstanding",
    "Unsettled, in a way",
    "Expected, as payment",
    "Overdue",
    "Due",
    "Receivable",
    "Was in the hole",
    "Like money to be repaid",
    "Was behind",
    "Outstanding",
    "Missed a payback opportunity?",
    "Was in the hole",
    "Due",
    "Had markers out",
    "Was in debt",
    "Was in debt",
    "Had to give it to?",
    "Was in arrears"
  ],
  "hook": [
    "See 1-Down",
    "Repeated part of a pop song",
    "Selling point",
    "Tee shot goof",
    "Swift ending for a bad stage performance",
    "Attention-grabbing riff, perhaps",
    "Catchy part of a song",
    "Left or right, say",
    "Velcro component",
    "Catchy musical phrase",
    "What many a pop song has",
    "Worm's place on a fishing line",
    "End of a wooden arm",
    "Boxer's punch",
    "End of a fishing line",
    "You can hang your hat on it",
    "Golf goof",
    "Catch",
    "It may be on the line",
    "Catchy part of a song"
  ],
  "smut": [
    "Crude content",
    "Raw material?",
    "Salacious stuff",
    "Blue material",
    "Object of dirty looks?",
    "N.S.F.W. stuff",
    "Dirty pics",
    "Salacious stuff",
    "Unlikely book club recommendation",
    "X-rated stuff",
    "Blue books?",
    "Filth",
    "Obscene material",
    "Prurient material",
    "X-rated material",
    "Google SafeSearch target",
    "Some banned literature",
    "Porn",
    "It's blue",
    "James Joyce's \"Ulysses,\" per a 1921 court decision",
    "Porn",
    "Nasty stuff",
    "Material blocked by parental controls",
    "Sanitization target",
    "Blue material",
    "Filth",
    "Porn",
    "Porn",
    "Blue stuff",
    "Dirt",
    "Net Nanny no-no",
    "Offensive lines?",
    "X-rated stuff",
    "Blue prints?",
    "Great literature it's not",
    "Dirty reading",
    "Dirty magazines and such",
    "Blue matter",
    "Scuzz",
    "Blue prints?",
    "Tom Lehrer's anti-censorship song",
    "Certain fungus",
    "Dirty reading",
    "Dirty literature",
    "Blue material",
    "Contents of some plain brown wrappers",
    "It's not clean",
    "Moralist's target",
    "Skin flicks and such",
    "Federal Communications Commission target",
    "Blue books?",
    "Censor's target",
    "Blue books?",
    "It's not clean",
    "Censor's concern",
    "No children's viewing",
    "Dirt",
    "Blue books",
    "Porn",
    "Porn",
    "Dirty stuff",
    "Porn",
    "Censor's target",
    "Porn",
    "Filth",
    "Censor's target",
    "Blue books?",
    "Dirty literature",
    "Censor's target",
    "Porn",
    "Soot particle",
    "Indecent literature",
    "Peep shows and the like",
    "Indecent literature"
  ],
  "kale": [
    "Trendy ingredient in a healthy smoothie",
    "Nondairy source of calcium",
    "Curly-leafed cabbage",
    "Member of the cabbage family",
    "Healthful leaf vegetable",
    "Leafy vegetable that can be green or purple",
    "Trendy superfood",
    "Moolah",
    "Long green",
    "Popular smoothie ingredient",
    "___ chips (trendy snack food)",
    "Moolah",
    "Curly cabbage",
    "Green vegetable with tightly curled leaves",
    "Trendy smoothie ingredient",
    "Vitamin-rich green vegetable",
    "Salad green",
    "Salad green",
    "Vegetable with curly leaves",
    "Cabbagelike vegetable",
    "Some salad greens",
    "Leafy vegetable",
    "Do-re-mi",
    "Crinkly vegetable",
    "Cabbagelike vegetable",
    "Vegetable rich in calcium and vitamin K",
    "Moolah",
    "Leafy green",
    "Moola",
    "Moolah",
    "Moolah",
    "Moolah",
    "Moolah",
    "Scratch",
    "Scratch",
    "Purple or green vegetable",
    "Leafy vegetable",
    "Curly cabbage",
    "Long green",
    "Cabbagelike vegetable",
    "Cabbagelike plant",
    "Moolah",
    "Moolah",
    "Moolah",
    "Moola",
    "Do-re-mi",
    "Curly cabbage",
    "Long green",
    "Cabbage's cousin",
    "Mustard family member",
    "Cabbagelike vegetable",
    "Cabbagelike vegetable",
    "Bucks",
    "Hardy cabbage",
    "Cabbage",
    "Curly cabbage",
    "Dough",
    "Mustard family member",
    "Do-re-mi",
    "Moolah",
    "Moolah",
    "Simoleons",
    "Moolah",
    "Cabbage",
    "Curly-leafed vegetable",
    "Hardy cabbage",
    "Moolah"
  ],
  "bras": [
    "Items purportedly burned outside the Miss America Pageant in 1968",
    "They might be wireless",
    "Garments typically fastened in the back",
    "Beach tops",
    "They might be backless",
    "Things displayed by mannequins",
    "They may be strapless or padded",
    "Some Maidenform products",
    "Two-piece tops",
    "They're measured in cups",
    "Beach fronts?",
    "Beach tops",
    "Their sizes are measured in cups",
    "Frederick's of Hollywood purchases",
    "Support providers",
    "Victoria's Secret merchandise",
    "They give women a lift",
    "Some intimates",
    "They're hooked behind the back",
    "Cup holders?",
    "They're worn under blouses",
    "Intimate apparel",
    "Some intimate apparel",
    "They're uplifting",
    "Garments that usually clasp in the back",
    "Support group?",
    "They have supporting roles",
    "Bikini parts",
    "Bikini parts",
    "They provide contours",
    "They have shoulder straps",
    "Playtex products",
    "Maidenform products",
    "They're uplifting",
    "Support group?",
    "Support group?",
    "They were once burned",
    "Tops",
    "Pieces of two-pieces",
    "Bikini tops",
    "Arm, to Armand",
    "Women's support group?",
    "Monokinis lack them",
    "Bosom companions"
  ],
  "trim": [
    "Barbershop job",
    "Quick haircut",
    "Quick haircut",
    "Prune",
    "Sleek",
    "Remove, as fat",
    "Barbershop request",
    "Crop",
    "In good shape",
    "Svelte",
    "Adjust with Photoshop, maybe",
    "Fit",
    "Request at the barber's",
    "In good physical condition",
    "Clip or snip",
    "Slender",
    "Short cut",
    "Skirt embellishment",
    "A little off?",
    "Cut back a bit",
    "Quick cut",
    "Adjust, as sails",
    "Request to a barber",
    "Job for a barber",
    "Decorate, as a Christmas tree",
    "Barber's job",
    "Edging",
    "At one's fighting weight, say",
    "Decoration",
    "Not a major haircut",
    "In shape",
    "Fit",
    "Crop",
    "Far from pudgy",
    "\"Just a little off\" at the barber's",
    "Add to or remove from",
    "In shape",
    "In good shape",
    "Quick job in a barbershop",
    "Tassels, e.g.",
    "Svelte",
    "Quick haircut",
    "Request of a barber",
    "Barbershop request",
    "Free of excess matter",
    "Bit off the top",
    "Quick job for a barber",
    "Barber's job",
    "Edge",
    "Far from fat",
    "Well-kept",
    "Curtail",
    "In shape",
    "Deck",
    "Fit",
    "Svelte",
    "Barber's job",
    "In good physical condition",
    "Quick cut",
    "Barbershop request",
    "Well-groomed",
    "Remove the fat from",
    "Quick haircut",
    "Use clippers",
    "A little off",
    "Tonsorial work",
    "Clean-cut",
    "Neat",
    "Tidy",
    "In good condition",
    "Barbershop request",
    "Tonsorial procedure",
    "Barbering job",
    "In shape",
    "Adjust the sails"
  ],
  "dumb": [
    "Word with luck or waiter",
    "Word after play or before luck",
    "Like a \"doh!\" moment",
    "Word said three times while smacking the forehead",
    "Cry made repeatedly while slapping the forehead",
    "Sharp's opposite",
    "Half-baked",
    "Boneheaded",
    "Senseless",
    "Boneheaded",
    "Boneheaded",
    "Word before luck or cluck",
    "Inane",
    "Boneheaded",
    "Slow",
    "Moronic",
    "One way to be struck",
    "Hardly Einsteinian",
    "Asinine",
    "Like some errors",
    "Half-witted",
    "Slow-witted",
    "Speechless",
    "Speechless",
    "Type of luck"
  ],
  "prim": [
    "Victorian",
    "Like a pearl-clutcher",
    "Stiffly formal",
    "Partner of proper",
    "Stiffly formal",
    "Overly proper",
    "___ and proper",
    "Schoolmarmish",
    "Adhering to old-fashioned modesty",
    "Stiffly formal",
    "Demure",
    "Strait-laced",
    "Proper partner?",
    "Stiff",
    "Schoolmarmish",
    "Starchy",
    "Overly demure",
    "Not at all loose",
    "Strait-laced",
    "___ and proper",
    "Wearing a long dress and a collar buttoned to the top, maybe",
    "Governessy",
    "Perhaps a little too neat",
    "Strait-laced",
    "Governessy",
    "Not fond of dirty jokes, surely",
    "Overly stiff",
    "Strait-laced",
    "First part",
    "Inhibited",
    "Strait-laced",
    "Governessy",
    "Prissy",
    "Schoolmarmish",
    "Buttoned-up all the way",
    "Victorian",
    "Schoolmarmish",
    "Stiffly neat",
    "Proper's partner",
    "Stiffly neat",
    "Stiffly neat",
    "Prissy",
    "Schoolmarmish",
    "Schoolmarmish"
  ],
  "ankh": [
    "Egyptian cross",
    "Egyptian symbol of life",
    "Sacred cross in ancient Egypt",
    "Egyptian key of life",
    "Decorative motif in ancient Egyptian art",
    "Symbol of life",
    "Egyptian cross",
    "Cross with a loop",
    "Symbol of life in ancient Egypt",
    "Figure also called the crux ansata",
    "Rosetta Stone symbol",
    "Egyptian symbol of life",
    "Key of the Nile",
    "Egyptian \"key of life\"",
    "Egyptian symbol of life",
    "Symbol of life",
    "Hippie's cross",
    "Emblem of life",
    "Ancient key",
    "Egyptian cross",
    "Egyptian symbol of life",
    "Hippie's cross",
    "Egyptian cross",
    "Cross with a loop",
    "Egyptian symbol of life",
    "Hieroglyphics cross",
    "Old cross",
    "Emblem of life",
    "Symbol of life",
    "Pharaoh's symbol",
    "Pharaoh's cross",
    "Cross of old",
    "Egyptian cross",
    "Egyptian symbol of life",
    "Cross of Egypt",
    "Symbol of life",
    "Cross with a loop",
    "Egyptian cross",
    "Ancient cross",
    "Good luck symbol for King Tut",
    "Egyptian cross",
    "Symbol of life"
  ],
  "crit": [
    "Lit ___",
    "Lit ___ (college class)",
    "Lit ___",
    "Reviews, collectively: Abbr.",
    "Lit ___",
    "Reviews of books and such: Abbr.",
    "Lit ___ (coll. course)",
    "Lit ___ (coll. course)",
    "Lit ___",
    "Lit ___",
    "Lit ___",
    "Lit ___ (college course)",
    "See 56-Down",
    "Lit ___ (college course, slangily)",
    "Lit ___ (college course)",
    "Lit ___ (college course, informally)",
    "Lit-___ (college study)"
  ],
  "mime": [
    "One \"trapped\" in an invisible box",
    "Play charades",
    "Performer without a speaking part",
    "Give the silent treatment?",
    "Performer in makeup, typically",
    "Marcel Marceau, e.g.",
    "Act out",
    "Charades player, essentially",
    "Act out",
    "Act out",
    "One who's speechless",
    "Some performance art",
    "Have a silent role?",
    "Go without saying?",
    "Street performer in an \"invisible box\"",
    "Storyteller who needs no words",
    "Silent performer",
    "Marcel Marceau, for one",
    "Give the silent treatment?",
    "Marcel Marceau, for one",
    "Act out",
    "Handy sort?",
    "Silent performer",
    "Street performer",
    "Performer who doesn't say a word",
    "Charades player",
    "Performer in whiteface",
    "Charades player",
    "Silent type",
    "Gesturer",
    "Street performer",
    "Show without a line?",
    "Quiet type?",
    "Silent performer",
    "Charade",
    "Charades, essentially",
    "Performance art",
    "Charades, essentially",
    "Marcel Marceau, e.g.",
    "Handy performer?",
    "One who gives the silent treatment?",
    "He's not one to talk",
    "The silent type",
    "Quiet, expressive one"
  ],
  "rosh": [
    "___ Hashana",
    "___ Hashana (Jewish holy day)",
    "Start of a holy day?",
    "Start of a Jewish holiday?",
    "___ Hashana",
    "___ Hashana",
    "___ Hashanah",
    "___ Hashana",
    "Hebrew for \"beginning\"",
    "___ Hashanah",
    "___ Hashana",
    "Hebrew for \"beginning\"",
    "Beginning, in Hebrew",
    "Start of a religious observance?",
    "___ Hashana",
    "___ Hashana",
    "___ ha-Shanah",
    "___ ha-Shanah",
    "___ Hodesh",
    "Hebrew \"beginning\"",
    "___ ha-Shanah",
    "___ ha-Shanah"
  ],
  "kane": [
    "\"Citizen ___\"",
    "\"Citizen ___\"",
    "Orson Welles's \"Citizen ___\"",
    "Danity ___, girl group with a self-titled 2006 #1 album",
    "Cartoonist Bob who co-created Batman",
    "Hearst-like film character",
    "\"Citizen ___\"",
    "Xanadu resident",
    "\"Citizen ___\"",
    "Batman co-creator Bob",
    "Title film character based on William Randolph Hearst",
    "1941 Welles role",
    "Classic Orson Welles role",
    "1941 film \"citizen\"",
    "Batman co-creator Bob",
    "See 38-Across",
    "Orson Welles's \"Citizen ___\"",
    "He died at Xanadu",
    "Rapper Big Daddy ___",
    "\"Citizen\" of film",
    "Film character based on Hearst",
    "Publisher of the fictional New York Inquirer",
    "Sugar ___, Marilyn Monroe's role in \"Some Like It Hot\"",
    "Batman creator Bob",
    "\"Citizen ___\"",
    "Sugar ___ of \"Some Like It Hot\"",
    "Classic Welles role",
    "\"Citizen ___\"",
    "Classic Welles role",
    "\"High Noon\" lawman",
    "\"High Noon\" marshal",
    "Classic Welles role",
    "Xanadu resident",
    "Welles's \"Citizen\"",
    "Film \"citizen\"",
    "Jeffrey Archer's \"___ and Abel\"",
    "Welles character",
    "Will ___ of \"High Noon\"",
    "\"Citizen ___\"",
    "\"High Noon\" sheriff Will ___",
    "\"Citizen ___\"",
    "Actress Carol of \"Taxi\"",
    "Welles citizen",
    "\"Citizen ___\"",
    "See 45-Down",
    "Actress Carol"
  ],
  "oust": [
    "Remove from power",
    "Drive (from)",
    "Remove via a coup, say",
    "Remove from power",
    "Bounce",
    "Boot",
    "Remove from power",
    "Boot from office",
    "Boot",
    "Force from office",
    "Boot from power",
    "Boot out",
    "Depose",
    "Send packing",
    "Dethrone",
    "Send packing",
    "Boot from power",
    "Boot",
    "Bounce",
    "Expel from power",
    "Remove, as in a coup",
    "Boot",
    "Eject",
    "Remove, as a dictator",
    "Boot out",
    "Bump",
    "Boot",
    "Boot",
    "Give the heave-ho",
    "Run out",
    "Kick out",
    "Depose",
    "Unseat",
    "Remove from power",
    "Boot",
    "Run out",
    "Boot from office",
    "Give the boot to",
    "Throw out",
    "Evict",
    "Get rid of",
    "Boot",
    "Give the heave-ho",
    "Evict",
    "Dethrone",
    "Un-elect",
    "Bounce",
    "Boot",
    "Bump",
    "Depose",
    "Remove from office",
    "Bounce",
    "Bump",
    "Kick out",
    "Bump from office",
    "Dethrone",
    "Give the heave-ho",
    "Bounce",
    "Topple from power",
    "Depose",
    "Boot",
    "Bounce",
    "Bounce",
    "Remove from office",
    "Boot",
    "Dislodge",
    "Bounce",
    "Boot",
    "Evict",
    "Bounce",
    "Supplant",
    "Give the boot to",
    "Pull off a coup"
  ],
  "seat": [
    "One of 435 in the House",
    "Plane assignment",
    "Airline assignment",
    "Run for it!",
    "Something sought for the House biennially",
    "Pants part",
    "\"Have a ___\" (request in a waiting room)",
    "Trousers part",
    "What an usher ushers you to",
    "Politician's goal",
    "Candidate's goal",
    "\"Save me a ___!\"",
    "Candidate's goal",
    "Boarding pass info",
    "Broadway score?",
    "Usher's destination",
    "Bench or chair",
    "Ticket specification",
    "Candidate's quest",
    "County center",
    "Banana ___",
    "Spot in Congress",
    "Judicial position",
    "Airplane ticket info",
    "Pew, for one",
    "Politician's goal",
    "Accommodate, as passengers",
    "Box ___",
    "What a bottom may be on top of",
    "Pants part that gets a lot of wear",
    "Plane assignment",
    "Fanny",
    "Do an usher's job",
    "You may take one before dinner",
    "Run for it",
    "Election goal",
    "Pants part",
    "Place on a bus",
    "Bottom",
    "Chair or sofa",
    "Politician's goal",
    "Boarding pass datum",
    "Pants part",
    "Usher's offer",
    "Pants part",
    "Chair or pew",
    "Plane assignment",
    "You might run for it",
    "Usher in",
    "Bottom",
    "Center of power",
    "Broadway \"score\"",
    "Politician's goal",
    "Stock exchange purchase",
    "Settle, for one",
    "Candidate's goal",
    "Politician's goal",
    "Backside",
    "Political prize",
    "Accommodate",
    "Accommodate, in a way",
    "Musical chairs goal",
    "Candidate's goal",
    "Pants part",
    "Something to run for",
    "Concert ticket",
    "Pants part",
    "County center",
    "Candidate's goal",
    "Place in a box?",
    "Subway rider's wish",
    "Government center",
    "Headquarters",
    "Center of government",
    "Place in the House",
    "Pants part",
    "Where county offices are",
    "Plane reservation",
    "Goal in musical chairs",
    "Stock exchange buy",
    "Ticket info",
    "Musical chairs goal",
    "Saddle, e.g.",
    "Subway wish",
    "Officeholder's office",
    "Parliament prize",
    "Stock exchange membership",
    "Pants part",
    "Plane reservation",
    "Parking place?",
    "Rush-hour subway rarity",
    "Pants part",
    "Spot in the Senate",
    "Musical chairs goal",
    "Rush-hour rarity",
    "Spot in the Senate",
    "Pants part",
    "Senator's claim",
    "Office",
    "Chair",
    "Pants part",
    "\"Have a___\"",
    "Ticket specification",
    "Bus rider's prize",
    "Install",
    "Plane reservation",
    "Stock exchange position",
    "Stool or stoop",
    "50 Down membership",
    "Straphanger's search",
    "Bucket in a Buick",
    "Pants part",
    "Membership on an exchange",
    "Bottom",
    "Membership on Wall Street",
    "Hassock",
    "Board membership",
    "Plane reservation",
    "Pants part",
    "Capital",
    "Provide space for",
    "Backside",
    "Pants part",
    "Install in office",
    "Usher"
  ],
  "craw": [
    "Where something unpleasant may stick",
    "Stick in one's ___",
    "Where something annoying might be stuck",
    "Stick in one's ___",
    "Bird food holder",
    "Where annoying things stick",
    "Throat part",
    "43-Across in one's ___",
    "Stick in one's ___",
    "Where something unpleasant sticks",
    "Place where things get stuck",
    "Gullet",
    "Esophageal pouch",
    "Stick in one's ___",
    "Bird's gullet",
    "Stick in one's ___",
    "Animal stomach",
    "Stick in one's ___",
    "Stick in one's ___",
    "Sticking point?",
    "Sticking point",
    "Gullet",
    "Holder of bird food?",
    "Stick in one's ___",
    "Sticking point?",
    "Part of a bird's gullet",
    "Bird crop",
    "Sticking point?",
    "Sticking point",
    "Avian food holder",
    "Sticking point",
    "Crop",
    "Sticking point",
    "Stick in one's ___",
    "Stick in one's ___",
    "Sticking point",
    "Animal stomach",
    "Animal stomach",
    "Sticky place?"
  ],
  "hour": [
    "Golden ___ (time shortly before sunset)",
    "Happy ___",
    "Common lecture length",
    "Word after half or happy",
    "Time of day",
    "Time of day",
    "Witching ___",
    "Unit of work",
    "Period of a revolution?",
    "Midnight, for one",
    "1/24 of a day",
    "\"Spring forward, fall back\" unit",
    "The \"xx\" of xx:yy",
    "One of 24 in a day",
    "Number before a colon",
    "Part of a day",
    "H-___",
    "Time",
    "Appointed time",
    "11 or 12, but not 13",
    "Length of many a TV drama",
    "60 minutes",
    "Cuckoo announcement",
    "Twelve to one",
    "Appointed time",
    "2:00 or 3:00",
    "Period of time",
    "Billing unit",
    "1:00, e.g.",
    "Happy ___",
    "Lesson duration, often",
    "Daylight savings saving",
    "Chime time, with \"the\"",
    "2:00 or 3:00",
    "?ô___ of the Wolf?ö (Bergman film)",
    "Clock division",
    "TV drama length, usually",
    "Time",
    "Word with rush or credit",
    "Appointed time",
    "Time piece",
    "Time",
    "Kind of hand"
  ],
  "mini": [
    "iPad ___",
    "Above-the-knee skirt",
    "___-golf",
    "___ Cooper",
    "British car",
    "Cheaper option of a tech device, maybe",
    "Easy-to-park car",
    "Dress that ends above the knee",
    "Small-scale",
    "Certain skirt",
    "Cute-sized",
    "1\" version of a 15-Across",
    "Bite-size, say",
    "What doesn't go to great lengths?",
    "Small",
    "Skirt once often worn with go-go boots",
    "Leg-revealing item of apparel",
    "iPad ___",
    "Revealing skirt",
    "5x5 crossword, e.g.",
    "Cooper preceder",
    "iPod or iPad variety",
    "___ Cooper",
    "Fun-size, say",
    "Short shift?",
    "It's bigger than a Nano",
    "Bar opening?",
    "Prefix with bus",
    "___ Cooper (car)",
    "Certain iPod or skirt",
    "Dress not for the self-conscious",
    "Height of fashion?",
    "Pint-size",
    "See 16-Across",
    "Skirt that exposes a lot of thigh",
    "Attire not for the modest",
    "Certain iPod",
    "Pint-size",
    "Certain iPod",
    "Skirt style",
    "___ Cooper (car)",
    "Smaller than compact",
    "___ Cooper (popular car)",
    "Subcompact",
    "Subcompact",
    "Prefix with skirt or series",
    "Skirt that shows off legs",
    "Series opener?",
    "Peewee",
    "Short skirt",
    "With 10-Across, place to get milk and bread",
    "*Skirt ... van ... computer",
    "Revealing attire",
    "Maxi's opposite",
    "Short skirt",
    "Skirt that's not for the modest",
    "Alternative to hot pants",
    "Start with bar or car",
    "Tiny car",
    "Compacter than compact",
    "Tiny model",
    "Small skirt",
    "Abbreviated version",
    "Itsy-bitsy skirt",
    "60's dress style",
    "Small version",
    "Little introduction",
    "Prefix with series",
    "Sub-subcompact",
    "Brief",
    "Skirt style",
    "Abbreviated version",
    "Revealing dress",
    "Small model",
    "60's fashion"
  ],
  "asin": [
    "Z ___ zebra",
    "Z ___ zebra",
    "Spelling clarification",
    "Words of clarification when spelling",
    "X ___ xylophone",
    "Words of explanation",
    "P ___ psychology (unhelpful spelling clarification)",
    "\"Can you give an example ...?\"",
    "A ___ apple",
    "Explanatory words",
    "Explanatory words",
    "Words clarifying a spelling",
    "Alphabet book phrase",
    "D-day divider?",
    "Explanatory words",
    "Clarifying words",
    "Words of clarification",
    "Speller's words of clarification",
    "X ___ xylophone",
    "X ___ xylophone",
    "Clarifying link",
    "Q ___ queen",
    "Spelling clarification",
    "\"It's ___\" (Pet Shop Boys hit)",
    "Z ___ zebra",
    "Z ___ zebra",
    "X ___ xylophone",
    "Speller's phrase",
    "Clarifying phrase",
    "S ___ sugar",
    "Part of a speller's clarification",
    "X ___ xylophone",
    "J ___ judge",
    "T ___ tiger",
    "A ___ apple",
    "Z ___ zebra",
    "Alphabet book phrase",
    "\"It's ___ to tell a lie\"",
    "Q ___ queen",
    "Words of clarification",
    "A ___ apple",
    "Speller's words",
    "Z ___ zebra",
    "Z ___ zebra",
    "Abecedarian phrase",
    "\"Q ___ queen\"",
    "A ___ apple",
    "Abecedary link",
    "Speller's phrase",
    "Alphabet book phrase",
    "\"Q ___ queen\"",
    "Z ___ zebra",
    "Speller's phrase",
    "Q ___ queen",
    "Abecedary link",
    "Alphabet book phrase",
    "X ___ xylophone",
    "Speller's phrase",
    "X ___ xylophone",
    "Z ___ zebra",
    "Words of explanation in spelling",
    "Alphabet book phrase",
    "\"Q ___ queen\"",
    "Speller's phrase",
    "Z ___ zebra",
    "Abecedarian phrase",
    "\"B___bug\"",
    "A-apple link",
    "Q-queen connector",
    "Abecedary phrase",
    "Z-zebra link",
    "Abecedary phrase",
    "\"S ___ sugar\"",
    "\"Z ___ zebra\"",
    "Clarifying words",
    "Z ___ zebra",
    "M ___ Mary",
    "\"It's ___ to tell a lie\"",
    "Z ___ zebra"
  ],
  "paws": [
    "Dogs' \"dogs\"",
    "Hound's \"hands\"",
    "Feet in a pound",
    "Print sources, maybe",
    "Labs might offer them",
    "Things with nails sticking out",
    "What cats clean themselves with",
    "What dogs \"shake\" with",
    "Things at the ends of dogs' legs",
    "Handles clumsily",
    "Mitts",
    "Things that big nails stick out of",
    "Handles roughly",
    "What dogs \"shake hands\" with",
    "Cat's dogs?",
    "Feline face cleaners",
    "They have soles",
    "Rover's \"hands\"",
    "They make tracks",
    "Body parts with claws",
    "Padded things",
    "Cats' dogs",
    "Cat's feet",
    "Dog's \"dogs\"",
    "Bears' hands",
    "Isn't gentle with",
    "Canine quartet",
    "Furry feet",
    "Dog's \"dogs\"",
    "Bears' hands",
    "Kit's mitts",
    "Some are south"
  ],
  "liam": [
    "Youngest Hemsworth brother",
    "Youngest of the Hemsworth brothers",
    "Writer O'Flaherty",
    "Actor Neeson",
    "Actor Hemsworth",
    "One of Hollywood's Hemsworths",
    "Neeson of \"Schindler's List\"",
    "Neeson of \"Taken\"",
    "Actor Hemsworth of \"The Hunger Games\"",
    "Hemsworth of \"The Hunger Games\"",
    "Actor Hemsworth of \"The Hunger Games\"",
    "One Direction member Payne",
    "Hemsworth of \"The Hunger Games\"",
    "___ Payne, One Direction heartthrob",
    "One of the acting Hemsworths",
    "Novelist O'Flaherty",
    "Neeson of \"Taken\"",
    "Actor Hemsworth of \"The Hunger Games\"",
    "Neeson of \"Taken\"",
    "Neeson of \"Taken\"",
    "Actor Neeson",
    "Neeson of \"Clash of the Titans\"",
    "Irish statesman Cosgrave",
    "Neeson of \"Kinsey\"",
    "Writer O'Flaherty",
    "Irish folk musician O'Flynn",
    "Writer O'Flaherty",
    "Neeson of \"Rob Roy\"",
    "Author O'Flaherty",
    "\"The Informer\" author O'Flaherty",
    "Former Irish P.M. ___ Cosgrave",
    "Novelist O'Flaherty",
    "Actor Neeson",
    "Novelist O'Flaherty",
    "British rocker Gallagher",
    "Neeson of \"Kinsey\"",
    "British rocker Gallagher",
    "Neeson of \"Nell\"",
    "1970's Irish P.M. Cosgrave",
    "He played Oskar in \"Schindler's List\"",
    "Neeson of \"Rob Roy\"",
    "Former Irish P.M. Cosgrave",
    "Novelist O'Flaherty",
    "Neeson of \"Nell\"",
    "Actor Neeson",
    "Former Irish P.M. ___ Cosgrave",
    "Author O'Flaherty",
    "Actor Neeson",
    "Actor Neeson",
    "Neeson of \"Nell\"",
    "Neeson of film",
    "Actor Neeson",
    "Neeson of \"Darkman\"",
    "Former Irish Prime Minister Cosgrave"
  ],
  "oval": [
    "Like the Kia and Hyundai logos",
    "Cartoon speech bubble, often",
    "Like the head of a tennis racket",
    "Presidential office shape",
    "Like most ice rinks",
    "Elliptical",
    "Like zero",
    "Kennington cricket ground, with \"the\"",
    "Like the U.S. president's office",
    "Track shape",
    "Like skating rinks, typically",
    "Racetrack shape",
    "___ Office",
    "Like the logos for Subaru and Toyota",
    "Egg-shaped",
    "Trotter's course",
    "You might make one in your lap",
    "Like some tracks",
    "Rose Bowl, e.g.",
    "Running track, typically",
    "Indianapolis Motor Speedway, e.g.",
    "Shape for a locket",
    "Squished circle",
    "Track shape",
    "Like the head of a tennis racket",
    "Like the president's office",
    "Many a toy train track",
    "Egg-shaped",
    "Like the Kia logo",
    "Like O's in most typefaces",
    "Squished circle",
    "Not quite circular",
    "Cricket field shape",
    "London cricket ground, with \"the\"",
    "Like the heads of many hairbrushes",
    "Shape of the president's office",
    "Track type",
    "Like the Hyundai logo",
    "Like some lockets",
    "Speed-skating venue",
    "Track shape",
    "Track figure",
    "Track, often",
    "Zero, for one",
    "Elliptical",
    "42-Across shape",
    "Speed skater's path",
    "Like the leaves of a trailing arbutus",
    "Dove soap shape",
    "Elliptical",
    "One of three in Toyota's logo",
    "Ellipsoidal",
    "Nascar circuit",
    "Like faces, typically",
    "Cameo shape",
    "Like skating rinks, typically",
    "Like many lockets",
    "Like olives",
    "Indianapolis 500 track, e.g.",
    "Like the Ford logo",
    "Ellipsoid",
    "Roundish",
    "Zero, e.g.",
    "Amphitheater shape",
    "Eyecup's shape",
    "Eyeglass lens shape",
    "___ Office",
    "Zero-shaped",
    "Racetrack",
    "Lionel train layout, often",
    "White House office shape",
    "Horse course",
    "Track shape",
    "Football-shaped",
    "Like the rim of an eyecup",
    "Amphitheater",
    "Like Ford's logo",
    "Like some lockets",
    "Like the president's office",
    "Lionel layout, maybe",
    "Egg-shaped",
    "Place for a run",
    "Like many a clothes basket",
    "Racetrack shape",
    "Frame shape",
    "Locket shape",
    "Apricot-shaped",
    "Like most cameos",
    "Racetrack",
    "Racer's path",
    "Face shape",
    "Locket shape",
    "Running track",
    "Horse course",
    "Roundish",
    "The White House's ___ Office",
    "Like \"O\"",
    "Track shape",
    "Picture frame shape",
    "Lionel train layout, maybe",
    "Track shape",
    "Like one White House office",
    "Race site",
    "Egg shape",
    "Indy path",
    "Track",
    "Caplet shape",
    "Certain coffee table shape",
    "Lap path, perhaps",
    "Racetrack",
    "Like Poe's \"Portrait\"",
    "Oxeye window shape",
    "Many a toy train track",
    "Flattened figure",
    "Like an oxeye window",
    "Stadium shape, maybe",
    "White House office shape",
    "Shape of the president's office",
    "Like some picture frames",
    "Orbital path, usually",
    "White House office shape",
    "Certain window shape",
    "Lionel track layout, maybe",
    "Race track",
    "Place for a run",
    "Race track shape",
    "Model train layout, often",
    "Race place",
    "Stadium",
    "Racer's path",
    "Derby track",
    "Decorative window shape",
    "Face shape",
    "Roundish",
    "Skating rink, e.g.",
    "Elliptical",
    "Race track shape",
    "Face's shape, approximately",
    "Race track",
    "Aqueduct, e.g.",
    "___ Office",
    "Elliptical",
    "Geometry's ___ of Cassini",
    "Site for a race",
    "Track shape",
    "Like an oxeye window",
    "Race site",
    "Elliptical",
    "Planetary path",
    "Track figure",
    "White House's ___ Office",
    "Elliptical",
    "Paramecium?Æs shape",
    "Track shape",
    "Flattened circle",
    "White House office shape",
    "Race form",
    "Race track",
    "Track-shaped",
    "Window shape, maybe",
    "Ellipsoid",
    "Amphitheater",
    "Flattened circle",
    "Geometry's ___ of Cassini",
    "Elliptical",
    "Race track feature",
    "Race track",
    "Face shape",
    "Amphitheater",
    "Zero-shaped",
    "Elliptical",
    "Egg-shaped"
  ],
  "attn": [
    "Abbr. for routing of mail",
    "Package directive: Abbr.",
    "Memo header",
    "Memo abbreviation",
    "Abbr. at the top of an office memo",
    "Memo abbr.",
    "Abbr. on an envelope",
    "Envelope abbr.",
    "Envelope abbr.",
    "Start of a subj. line",
    "Abbr. on an envelope",
    "Memo heading abbr.",
    "Abbr. before a memo recipient's name",
    "Routing abbr.",
    "Memo heading abbr.",
    "Envelope abbr.",
    "Abbr. before a colon and a name",
    "Abbr. before a name on a memo",
    "Memo abbr.",
    "Memo abbr.",
    "Abbr. on a cover sheet",
    "Interoffice email abbr.",
    "Letter-routing abbr.",
    "Memo abbr.",
    "Memo abbr.",
    "Abbr. at the top of a memo",
    "Routing aid: Abbr.",
    "Subj. line alert",
    "Abbr. at the start of a memo",
    "Envelope abbr.",
    "Mailing label abbr.",
    "Cover sheet abbr.",
    "Memo abbr.",
    "Memo heads-up",
    "Envelope abbr.",
    "Routing abbr.",
    "Start of a memo heading",
    "Memo abbr.",
    "Abbr. before a colon",
    "E-mail heading: Abbr.",
    "Envelope abbr.",
    "Memo abbr.",
    "Memo abbr.",
    "Fax cover sheet abbr.",
    "Routing aid: Abbr.",
    "Abbr. before a name on top of a memo",
    "Memo heading",
    "Abbr. before a name on a memo",
    "Abbr. before a name in a memo",
    "Routing abbr.",
    "Abbr. at the top of a memo",
    "Abbr. at the top of a memo",
    "Envelope abbr.",
    "Ltr. routing aid",
    "Abbr. on an envelope",
    "Letter-routing abbr.",
    "Letter routing abbr.",
    "Memo starter",
    "Abbr. before a name on an envelope",
    "Memo abbr.",
    "Ltr. recipient pinpointer",
    "Mailing label abbr.",
    "Mailing label abbr.",
    "Ltr. routing aid",
    "Abbr. before the name of a memo recipient",
    "Abbr. before a colon",
    "Abbr. often before a name",
    "Cover sheet abbr.",
    "Abbr. on an envelope",
    "Env. abbr.",
    "Memo abbr.",
    "Memo-heading abbr.",
    "Memo heading abbr.",
    "Env. directive",
    "Letter abbr.",
    "Env. notation",
    "Envelope abbr.",
    "Memo abbr.",
    "Envelope abbr.",
    "Envelope abbr.",
    "Abbr. that may precede a colon",
    "Abbr. on an envelope",
    "Env. abbreviation",
    "Memo abbr.",
    "Bus. envelope notation",
    "Abbr. on an order",
    "Memo abbr.",
    "Business letter abbr.",
    "Abbr. at the top of a memo",
    "Memo abbr.",
    "Abbr. on 14-Across",
    "Envelope abbr.",
    "Memo abbr.",
    "Abbr. on some letter headings",
    "Env. notation",
    "Envelope abbr.",
    "Envelope abbr.",
    "Business letter abbr.",
    "Bus. letter directive",
    "Abbr. on a business letter",
    "Abbr. on an invoice",
    "Envelope abbr.",
    "Abbr. that often precedes a colon",
    "\"Now hear this!\": Abbr.",
    "Memo abbr.",
    "Envelope abbr.",
    "Abbr. on an envelope",
    "Memo starter: Abbr.",
    "Memo starter",
    "Envelope abbr.",
    "Envelope abbr.",
    "Memo abbr.",
    "Envelope abbr.",
    "Envelope abbr.",
    "Memo abbr.",
    "Envelope abbr.",
    "Letter abbr."
  ],
  "airs": [
    "Snooty manners",
    "Broadcasts",
    "Shows, as shows",
    "Puts on ... or things put on",
    "What phonies put on",
    "Puts on TV",
    "High-society people may put them on when in public",
    "Put on ___",
    "Broadcasts",
    "Affected manner",
    "Lilting melodies",
    "Puts on TV or radio",
    "Is broadcast",
    "Faux sophistication",
    "Puts on TV",
    "Puts on TV",
    "Affectation",
    "Hauteur",
    "Highfalutin attitude",
    "Broadcasts",
    "What snobs put on",
    "What snobs put on",
    "Haughty affectation",
    "Puts on TV",
    "Pretension",
    "Pretense",
    "What hoity-toity people put on",
    "Runs",
    "Broadcasts",
    "Snobs put them on",
    "Gets out in the open",
    "They may be put on",
    "Simple folks don't put them on",
    "Hoity-toity manners",
    "Makes public",
    "Show of superiority",
    "Broadcasts",
    "Goes on TV",
    "Lovers of expensive furs may put them on",
    "Pretense",
    "Shows",
    "Snobs put them on",
    "Is shown",
    "Haughty mannerisms",
    "Broadcasts",
    "Shows on TV",
    "Shows, as programs",
    "You can put them on",
    "Puts on ?à or puts on ___",
    "Broadcasts",
    "Puts on ... or things that are put on",
    "They're sometimes put on",
    "Snootiness",
    "What a snob might put on",
    "Hauteur",
    "Broadcasts",
    "Makes known",
    "Broadcasts",
    "Puts on TV",
    "Snobs put them on",
    "They're sometimes put on",
    "Haughtiness",
    "Haughty pose",
    "Affectation",
    "Affectation",
    "Haughtiness",
    "Goes on TV",
    "Pretense",
    "Superciliousness",
    "Haughtiness",
    "Broadcasts",
    "Affectation",
    "Put on ___ (act snooty)",
    "Put-ons?",
    "Televises",
    "Superciliousness",
    "Something to put on?",
    "Celebrities may put them on",
    "A show of vanity",
    "Haughtiness",
    "Pretense",
    "Snooty put-on",
    "Broadcasts",
    "Broadcasts",
    "Tunes",
    "Snooty attitude",
    "Highfalutin manner",
    "Puts on TV",
    "Haughtiness",
    "Publicizes",
    "Melodies",
    "They may be put on",
    "Broadcasts",
    "Snobbery",
    "Pretension"
  ],
  "paid": [
    "Stamp on an invoice",
    "Not outstanding",
    "Red stamp word",
    "Forked over",
    "Word stamped on an invoice",
    "Accountant's stamp",
    "Bookkeeper's stamp",
    "Bookkeeper's stamp",
    "Like the best kind of vacation",
    "Not working as a volunteer",
    "Not free",
    "With 15-Down, spent way too much money for something",
    "Accountant's stamp",
    "Invoice stamp",
    "Settled up",
    "Took care of, as bills",
    "No longer owed",
    "Took care of, as a bill",
    "Covered, in a way",
    "Office stamp",
    "Bill stamp",
    "Settled up",
    "No longer owed",
    "Coughed up",
    "Stamp with a date",
    "Settled, as a bill",
    "Invoice stamp",
    "Settled",
    "Put up the cash",
    "Receipt stamp",
    "Nice word to see on a bill",
    "Invoice stamp",
    "Invoice stamp"
  ],
  "grew": [
    "Increased, as the pot",
    "Got bigger",
    "Burgeoned",
    "Increased",
    "Developed",
    "Rose",
    "Sprouted",
    "Sprouted",
    "Ballooned",
    "Waxed",
    "Evolved",
    "Increased",
    "Matured",
    "Opposite of contracted",
    "Got bigger",
    "Got longer, like Pinocchio's nose",
    "Sprouted",
    "Multiplied",
    "Mushroomed",
    "Waxed",
    "Filled out",
    "Shot up",
    "Boomed",
    "Shot up",
    "Got bigger",
    "Got larger",
    "Mushroomed",
    "Shot up",
    "Rose",
    "Burgeoned",
    "Shot up",
    "Sprouted",
    "Waxed",
    "Deepened",
    "Shot up",
    "Waxed",
    "Matured",
    "Snowballed",
    "Waxed",
    "Developed",
    "Increased",
    "Ballooned"
  ],
  "swag": [
    "Freebies at a corporate event",
    "Promotional goodies handed out at an event",
    "It might come in a branded tote bag",
    "Appropriate initials of \"stuff we all get\"",
    "Pirate's plunder",
    "Gala giveaways",
    "Party goodies",
    "Convention handouts",
    "___ bag",
    "Sponsors' giveaways",
    "Goodie bag contents",
    "\"___ Surfin'\" (2008 rap song)",
    "Goodies in a goody bag",
    "Free goodies at an event",
    "___ bag (event handout)",
    "Lavish party favors",
    "___ bag (party giveaway)",
    "___ bag (party handout)",
    "Event handouts",
    "Coolness, in modern slang",
    "Goodie bag filler",
    "Giveaways at events",
    "Pirate's loot",
    "Ill-gotten gains",
    "Party giveaways, in slang",
    "Booty",
    "Boodle",
    "Loot",
    "Booty",
    "Plunder, slangily",
    "Spoils",
    "Ill-gotten gains",
    "Booty",
    "Booty",
    "Festoon",
    "Loot",
    "Booty, in slang",
    "Loot",
    "Spoils",
    "Spoils",
    "Booty",
    "Valuables",
    "Booty",
    "Booty",
    "Decorative drapery",
    "Spoils",
    "Loot"
  ],
  "ican": [
    "The Who's \"___ See for Miles\"",
    "Volunteer's words",
    "Volunteer's offer",
    "Volunteer's words",
    "Confident self-assessment",
    "Words of confidence",
    "Optimist's credo",
    "Volunteer's offer",
    "Volunteer's phrase",
    "Words of self-empowerment",
    "Words of confidence",
    "Words of confidence",
    "Volunteer's response",
    "\"___ help\"",
    "Volunteer's words",
    "Statement of confidence",
    "Statement of confidence",
    "\"Not if ___ help it!\"",
    "Confident cry",
    "Bullish declaration",
    "Volunteer's offer",
    "Declaration of determination",
    "\"___ See for Miles\" (song by the Who)",
    "Self-motivational mantra",
    "Optimistic assertion",
    "Confident assertion",
    "Eager beaver's assertion",
    "\"___ hardly believe it!\"",
    "Start of a \"Name That Tune\" bid",
    "\"Not if ___ help it!\"",
    "Words of confidence",
    "Volunteer's words",
    "\"Let me know if ___ help\"",
    "Volunteer's words",
    "Words of confidence",
    "Self-confident assertion",
    "\"___ almost taste it!\"",
    "Sammy Davis Jr.'s \"Yes ___\"",
    "\"...___ do better!\"",
    "Self-confident words",
    "\"If ___ make it there...\"",
    "\"___Help\" (1974 song)",
    "Sammy Davis Jr. book \"Yes ___\""
  ],
  "brew": [
    "Batch of beer",
    "Be in the works",
    "Begin to develop",
    "Be in the works",
    "Coffee or beer, informally",
    "Be in development",
    "Prepare, as tea",
    "Steep",
    "Steep",
    "Pub offering",
    "Java, for one",
    "Make stout",
    "Java, e.g.",
    "Witches' ___",
    "Make in a cauldron",
    "Steep",
    "Cold one",
    "Begin to form",
    "Yeasty drink",
    "Be in preparation",
    "Suds",
    "Suds",
    "Beer or ale",
    "Beer, informally",
    "Beer, say",
    "Contrive",
    "Concoct",
    "Samuel Adams, e.g.",
    "Make beer, e.g.",
    "Suds",
    "Cold one",
    "Ale or coffee",
    "Start to form, as a storm",
    "Steep",
    "Make coffee",
    "Ale",
    "Witch's___"
  ],
  "adin": [
    "Tennis score after deuce",
    "Tennis score after deuce",
    "Tennis score after deuce",
    "Tennis score favoring the server",
    "Tennis player's chance to hold serve",
    "What results from an ace on a deuce",
    "Deuce follower",
    "Score before deuce, maybe",
    "Possible score after 40-all",
    "Tennis score just before winning a game",
    "Pre-service announcement?",
    "Deuce follower",
    "Score after deuce",
    "Certain game point",
    "Game point, in tennis",
    "Score after deuce",
    "Game point situation",
    "Deuce preceder, maybe",
    "Score after deuce",
    "Court edge",
    "Score before deuce, maybe",
    "Deuce follower",
    "Point before \"game\"",
    "Score after deuce",
    "Deuce follower",
    "Situation after deuce",
    "Score after deuce",
    "Deuce follower, in tennis",
    "Score right before a win, maybe",
    "Deuce follower",
    "Server's edge",
    "Point before \"game,\" maybe",
    "Good situation for a server",
    "Tennis edge",
    "Score just before winning",
    "Situation before many a game is won",
    "Score after deuce",
    "Deuce follower",
    "Score before winning a game, maybe",
    "Score just before victory, maybe",
    "Deuce follower",
    "Score just before winning",
    "Score after deuce",
    "Score after deuce",
    "Tennis edge",
    "Tennis score",
    "Deuce follower",
    "Possible score just before winning",
    "Score just before winning, maybe",
    "Tennis score after deuce",
    "Tennis score",
    "Tennis score",
    "Game point situation",
    "Tennis edge",
    "Score just before winning a game",
    "Tennis score after deuce",
    "Tennis score after deuce",
    "Tennis score",
    "Serving edge",
    "Point after deuce",
    "Tennis score",
    "Tennis score after deuce",
    "Score after deuce",
    "Tennis score after deuce",
    "Situation favoring the server",
    "Score after deuce",
    "Server's edge",
    "It may follow 40-40",
    "Tennis score after deuce",
    "Score after deuce",
    "Score in tennis",
    "Tennis edge",
    "Server's edge, to Sampras",
    "Deuce follower, in tennis",
    "Deuce follower",
    "Game point situation",
    "Situation favoring the server",
    "Server's edge, on the court",
    "Game point, in tennis",
    "Tennis score after deuce",
    "Tie-breaking point",
    "Tennis score after deuce",
    "Tennis score",
    "Score after deuce, in tennis",
    "Point before \"game\"",
    "Score after deuce",
    "Tennis score after deuce",
    "Tennis score",
    "Score after deuce, in tennis",
    "Open score",
    "Point after deuce, maybe",
    "It's not the breaking point",
    "Court call",
    "Service edge",
    "Tennis score",
    "Deuce follower",
    "Point after deuce",
    "Deuce follower",
    "Plus a point",
    "Tennis score",
    "Court situation",
    "Tennis score",
    "It follows deuce in tennis",
    "Tennis score",
    "Tennis score",
    "Score after deuce",
    "Tennis score",
    "Tennis score",
    "Tennis score",
    "Tennis score",
    "Court score",
    "Tennis score"
  ],
  "baum": [
    "Writer who created Oz",
    "Toto's creator",
    "Early 20th-century author who foresaw TV and wireless telephones",
    "Author who inspired the musical \"Wicked\"",
    "Creator of Dorothy and the Cowardly Lion",
    "Glinda's creator",
    "Creator of Oz",
    "42-Across's creator",
    "Oz creator",
    "Toto's creator",
    "Creator of Oz",
    "Creator of Princess Ozma",
    "\"Mother Goose in Prose\" author, 1897",
    "Author L. Frank ___",
    "Princess Ozma's creator",
    "Creator of Oz",
    "Creator of 23-Across",
    "Toto's creator",
    "\"Father Goose\" author"
  ],
  "opel": [
    "German auto since 1899",
    "Car with a lightning bolt logo",
    "German car once owned by General Motors",
    "German auto",
    "German auto make",
    "German automaker",
    "German auto sold mainly in Europe",
    "Sister company of Peugeot",
    "Volkswagen competitor",
    "Affordable German car",
    "Automaker sold by G.M. in 2017",
    "German automaker",
    "Automaker with the slogan \"Wir leben Autos\"",
    "Volkswagen competitor",
    "European automaker",
    "European sister brand of Buick",
    "German-based G.M. subsidiary",
    "German auto make",
    "European automaker",
    "Real gem of a car?",
    "Automaker that originally sold sewing machines",
    "European G.M. division",
    "German automaker",
    "German auto",
    "Many a car on the autobahn",
    "Maker of the Insignia",
    "General Motors subsidiary",
    "German automaker",
    "Car with a lightning bolt in its logo",
    "Automaker Adam",
    "Automaker since 1899",
    "Autobahn auto",
    "German auto make",
    "\"Wir leben Autos\" sloganeer",
    "G.M. German car",
    "German car",
    "German car",
    "Audi alternative",
    "Carmaker since 1899",
    "General Motors acquisition of 1929",
    "2009 G.M. spinoff",
    "German-made car since 1899",
    "Autobahn auto",
    "Volkswagen competitor",
    "Autobahn auto",
    "European car",
    "German-built auto",
    "Auto on the autobahn",
    "Autobahn auto",
    "European auto",
    "European auto",
    "European car",
    "Auto pioneer Adam",
    "European car",
    "Kadett maker",
    "Kadett automaker",
    "Astra maker",
    "European automaker",
    "European car",
    "Autobahn auto",
    "European auto",
    "German automaker",
    "German automaker",
    "European car",
    "Kadett maker",
    "German auto",
    "Auto company founder Adam",
    "German-built car",
    "European car",
    "European car",
    "European auto import",
    "Auto import from Europe",
    "European auto",
    "Auto import from Europe",
    "European auto"
  ],
  "tool": [
    "Level or bevel",
    "Saw or screwdriver",
    "Jerk, slangily",
    "Level or lathe",
    "Level, for one",
    "Soldering 50-Down, e.g.",
    "Easily influenced person",
    "Hammer or sickle",
    "Reamer or wrench",
    "Unwitting accomplice",
    "Stooge",
    "One of many in a Swiss Army knife",
    "Screwdriver or hammer",
    "It might include \"copy\" and \"paste\"",
    "Jack, hammer or jackhammer",
    "Easily manipulated sort",
    "Level, for one",
    "Plane, for one",
    "Hoe or hatchet",
    "Wedge or sledge",
    "Plane, e.g.",
    "Pick, say",
    "Jerk, slangily",
    "Drive (along)",
    "Handyman's belt item",
    "One being used",
    "Drill, for one",
    "Plane, e.g.",
    "Utility belt item",
    "Lever or level",
    "Hammer or saw",
    "Hammer or tongs",
    "Awl, for one",
    "Shed item",
    "Maul or awl",
    "Ax or awl",
    "\"Man is a ___-using animal\": Thomas Carlyle",
    "Diligent student, in slang",
    "Cleaver or lever",
    "Awl or axe",
    "Shed item",
    "Labor saver",
    "Hammer or hoe",
    "Not the real decision-maker",
    "Chisel or gouge",
    "Hammer or tongs",
    "Useful item",
    "Gouge or chisel",
    "Drive (around)",
    "Archaeological dig find",
    "Hammer or sickle",
    "Plane or square, e.g.",
    "Drill, to a dentist",
    "Level, e.g.",
    "Plane, e.g.",
    "Handyman's need",
    "Edger, e.g.",
    "Neolith or paleolith",
    "Cat's-paw",
    "Kit item",
    "Neolith, e.g.",
    "\"Home Improvement\" prop",
    "Bushwhacker, e.g.",
    "Hammer or sickle",
    "Die, e.g.",
    "Hammer or sickle, e.g.",
    "Cat's-paw",
    "Gimlet or screwdriver",
    "Drive (along)",
    "Plane, e.g.",
    "Hammer or sickle",
    "Puppet",
    "Implement",
    "Chisel, e.g.",
    "Screwdriver, e.g.",
    "Pistol, in gang slang",
    "Cat's-paw",
    "Wrench, e.g.",
    "File, e.g.",
    "Stooge"
  ],
  "doha": [
    "Capital of Qatar",
    "Mideast capital",
    "2022 World Cup venue",
    "Capital of Qatar",
    "Site of the 2022 World Cup",
    "Qatar's capital",
    "Capital of Qatar",
    "Capital of Qatar",
    "Qatari capital",
    "Capital of Qatar",
    "Qatar's capital",
    "2022 World Cup city",
    "Capital of Qatar",
    "Qatar's capital",
    "Qatar's capital",
    "Capital whose name means \"big tree\" in Arabic",
    "Qatar's capital",
    "Qatari capital",
    "Qatar's capital",
    "Capital east of Riyadh",
    "Qatar's capital",
    "Qatar's capital",
    "Qatar's capital",
    "Qatar's capital",
    "Qatar's capital"
  ],
  "didi": [
    "\"Where ___ go wrong?\"",
    "Conn of \"Grease\"",
    "Actress Conn of \"Grease\"",
    "\"What ___ tell you?\"",
    "\"Where ___ go wrong?\"",
    "Conn of \"Grease\"",
    "\"Rugrats\" mom",
    "\"What ___ do to deserve this?\"",
    "Actress Conn",
    "\"Where ___ go wrong?\"",
    "Actress Conn",
    "Beckett character",
    "Conn of \"Benson\"",
    "Conn of \"Grease\"",
    "Conn of \"Benson\"",
    "Actress Conn of \"Benson\""
  ],
  "shin": [
    "Climb, as a rope",
    "Well-guarded area in sports?",
    "Guard's place",
    "Body part that's also a Hebrew letter",
    "One guarded in football",
    "Hebrew letter whose name is also a body part",
    "Hebrew letter on a dreidel",
    "A knee sock covers it",
    "Hebrew letter on a dreidel",
    "Clamber up, as a pole",
    "Lower part of the leg",
    "___ splints (runner's ailment)",
    "___ splints (jogger's woe)",
    "Where capri pants stop",
    "Body part guarded in soccer",
    "Where the tibia is",
    "Leg bone",
    "One guarded in soccer",
    "A guard may protect it",
    "Leg part",
    "Body part that's often bumped",
    "Lower part of the leg",
    "Ankle-knee connector",
    "Climb using all four limbs",
    "Tibia's place",
    "It's just over a foot",
    "Part of the leg that's often kicked",
    "Much-kicked body part",
    "Soccer injury site",
    "A guard may protect it",
    "Goalkeeper's guarded area",
    "It's just over a foot",
    "Leg part below the knee",
    "It's usually over a foot",
    "One guarded in a soccer game",
    "Place for a guard, in soccer",
    "Kick target",
    "Tibia's place",
    "It's just over a foot",
    "It may be kicked in anger",
    "Calf accompanier",
    "Go up",
    "Climb, as a pole",
    "Climb",
    "Tibia's place",
    "Tibia",
    "Kicker?Æs object",
    "Tibia's locale",
    "Something not to kick",
    "Knee/ankle connector",
    "Painful place to be kicked",
    "Clamber up",
    "Leg part",
    "It's below the knee",
    "Place for a guard",
    "Tibia",
    "Place for splints",
    "Tibia",
    "Kicker's target",
    "Bad place for a splint",
    "Hebrew letter",
    "Ankle-knee connector",
    "Climb",
    "Much-kicked body part",
    "Leg part",
    "Climb a pole",
    "Kind of splints",
    "Climb a pole"
  ],
  "tele": [
    "Prefix with -graph",
    "Lead-in to prompter",
    "Cast opener",
    "Play starter?",
    "Prefix meaning \"far away\"",
    "Head of communications?",
    "TV opening?",
    "Rhyming prefix with novela",
    "Prompter or printer lead-in",
    "Prefix with commute",
    "Marketer's start?",
    "Prefix with vision or market",
    "Prefix with -graph",
    "What marketers might follow",
    "Head of communications?",
    "Prefix with -port",
    "Lead-in to type",
    "Communications leader?",
    "Prefix with cast",
    "Part of 1-Down",
    "TV component",
    "Intro to marketing?",
    "\"SCTV\" segment?",
    "The \"T\" of TV",
    "TV part",
    "Commercial prefix with dyne",
    "TV segment",
    "The \"T\" in TV",
    "Prefix with copier",
    "Marketing leader?",
    "Prefix with facsimile",
    "The \"T\" of TV",
    "Prefix with conference",
    "Start to communicate?",
    "MTV segment?",
    "Prefix with kinetic",
    "Conference intro",
    "TV part",
    "HDTV component",
    "MTV segment?",
    "Cast or gram beginning",
    "TV component",
    "Prefix with graph",
    "Marketing leader?",
    "Cast leader?",
    "Prefix with conference",
    "Marketing intro?",
    "Conference start?",
    "Phony start?",
    "Marketing leader?",
    "Prefix with pictures",
    "Modern conference beginning",
    "Prefix with photo",
    "Prefix with commuting",
    "Market leader?",
    "Commercial prefix with mundo",
    "Prefix with scope or photo",
    "The \"T\" in Britain's ITV",
    "The \"T\" in TV",
    "Prefix with phone",
    "Prefix with com",
    "Prefix with drama",
    "Prefix with conference",
    "Modern prefix with banking",
    "Play opening?",
    "The \"T\" of TCI",
    "Prefix with type",
    "Prefix with conferencing",
    "Prefix with photo",
    "Prefix with marketing",
    "Graphic head?",
    "Introduction to marketing?",
    "Prefix with com",
    "Prefix with type",
    "Cast leader",
    "TV part",
    "Boob tube, in Britain: Var.",
    "Prefix with conference or commute",
    "Communications prefix",
    "Prefix with photo or phone",
    "Conference lead-in",
    "Prefix with port",
    "Part of MTV",
    "Phone or photo preceder",
    "Prompter beginning",
    "Prompter's beginning",
    "Prefix with photo or type",
    "Prefix with gram",
    "The \"T\" in TV",
    "Marketer's start",
    "Conference start",
    "Prefix with gram or graph",
    "Prefix with meter or motor",
    "Prefix with marketing",
    "Prefix with play or scope",
    "Prefix with port or play",
    "Communications leader?"
  ],
  "pool": [
    "Compile",
    "Game suggested by the ends of 20-, 38- and 55-Across and 11- and 34-Down",
    "Spot for cannonballs",
    "Summer hangout",
    "Sight in front of the Lincoln Memorial",
    "Summer hangout",
    "Reporters' group",
    "Summer Olympics venue",
    "Game for bankers?",
    "Billiards game",
    "Hotel amenity",
    "Place to swim",
    "Rack-it game?",
    "Hotel amenity",
    "Ladder, part 2",
    "People pick pockets in it",
    "Shared funds",
    "Facility often closed in the winter",
    "Summer hangout",
    "Its winner beats the loser with a stick",
    "March Madness activity",
    "Feature of the Buckingham Palace grounds",
    "Summer cooler",
    "See 36-Across",
    "Game involving banks",
    "Shooting game",
    "Summer hangout",
    "Price-manipulating group",
    "Gene group",
    "Certain hotel amenity",
    "Banking is an important part of it",
    "Kitty",
    "Betting group",
    "Lifeguard's watch",
    "Betting group",
    "Shooting game",
    "Gene group",
    "Betting group",
    "\"Dirty\" activity",
    "Parlor game",
    "People shoot it for fun",
    "Office wagering",
    "\"Dirty\" activity",
    "Bit of office fun",
    "Summer hangout",
    "It may be reflecting",
    "Sunbathing locale",
    "Jackpot",
    "Combine",
    "\"Dirty\" game",
    "Combine",
    "Betting sum",
    "Kind of ball or hall",
    "Kind of cue",
    "Lifeguard's beat"
  ],
  "puma": [
    "Sponsor of Usain Bolt",
    "Adidas alternative",
    "Adidas competitor",
    "Mountain lion",
    "Sneaker named for a cat",
    "Animal also called a Nittany lion",
    "Fast feline",
    "Creature also called a catamount",
    "Reebok rival",
    "Moose predator",
    "Mountain cat",
    "Reebok rival",
    "Alternative to 53-Down",
    "Cousin of a jaguarundi",
    "Sneaker brand",
    "Shoe brand",
    "Mountain cat",
    "Company with a cat in its logo",
    "Deer hunter",
    "Shoe named for a cat",
    "Mountain lion",
    "Elk's enemy",
    "Big brand in athletic footwear",
    "Wild cat",
    "Powerful feline",
    "Sportswear brand",
    "Big name in sneakers",
    "Deer hunter",
    "Fast feline",
    "Cougar",
    "Reebok rival",
    "Catamount",
    "Adidas alternative",
    "Member of the genus Felis",
    "Mountain lion",
    "Night prowler",
    "Mountain lion",
    "Rancher's enemy",
    "Ranch menace",
    "Fast feline",
    "Mountain lion",
    "Sneaker brand",
    "Cougar",
    "Catamount",
    "Cougar"
  ],
  "eked": [
    "Just got (by)",
    "Stretched (out)",
    "Just got (by)",
    "Scraped (out)",
    "Scraped (out)",
    "Managed to get, with \"out\"",
    "Scratched (out)",
    "Barely made, with out",
    "Scraped (out)",
    "Just made (out)",
    "Managed, with \"out\"",
    "Narrowly made, with \"out\"",
    "Scraped (out)",
    "Barely obtained, with \"out\"",
    "Barely got, with \"out\"",
    "Squeezed (out)",
    "Managed, with \"out\"",
    "Barely made, with \"out\"",
    "Barely got, with \"out\"",
    "Just got (by)",
    "Squeezed (by)",
    "Barely managed, with \"out\"",
    "Barely earned, with \"out\"",
    "Squeezed (out)",
    "Squeezed (out)",
    "Scraped (out)",
    "Just managed, with \"out\"",
    "Scratched (out)",
    "Squeezed (out)",
    "Just managed, with \"out\"",
    "Squeezed (out)",
    "Scratched (out)",
    "Squeezed",
    "Managed, with \"out\"",
    "___ out (barely made)",
    "Barely made, with \"out\"",
    "Squeezed",
    "Squeezed (out)",
    "Managed, with \"out\"",
    "Barely managed, with \"out\"",
    "Squeezed",
    "Scraped (out)",
    "Scratched (out)",
    "Scratched (out)",
    "Squeezed",
    "Scratched (out)",
    "Supplemented, with \"out\"",
    "Made (out)",
    "Squeaked (out)",
    "Scratched (out)",
    "___ out a living (scraped by)",
    "Squeezed (out)",
    "Stretched (out)",
    "Managed, with \"out\"",
    "Squeezed (out)",
    "Managed, with \"out\"",
    "Scraped (out)",
    "Made do",
    "Scraped (out)",
    "Squeezed (out)",
    "___out (barely got)",
    "Made (out)",
    "Made, with ?ôout?ö",
    "___ out (just managed)",
    "Squeezed (out)",
    "Managed, with \"out\"",
    "Barely managed, with \"out\""
  ],
  "omar": [
    "Actor ___ Benson Miller",
    "Muhammad's father-in-law",
    "Midwest colleague of Representative Ocasio-Cortez",
    "Minnesota congresswoman Ilhan",
    "Actor Epps of \"House\"",
    "Congresswoman Ilhan",
    "Poet Khayyam",
    "Ilhan ___, first Somali-American elected to Congress",
    "Actor Epps",
    "Actor Epps of \"House\"",
    "Actor Dorsey of TV's \"Queen Sugar\"",
    "___ Bongo, president of Gabon for 42 years",
    "Actor Sharif",
    "Minnesota representative Ilhan ___",
    "Ilhan ___, one of the first two Muslim women elected to Congress",
    "___ Little, \"The Wire\" character",
    "Actor and bridge expert Sharif",
    "Actor Epps",
    "Mosque of ___ (shrine in Jerusalem)",
    "General Bradley, W.W. II commander",
    "Actor Epps",
    "Grandson of Esau",
    "\"The Wire\" stickup man",
    "Actor Epps",
    "Stickup man on \"The Wire\"",
    "Actor Epps",
    "Vizquel with 11 Gold Gloves",
    "W.W. II general Bradley",
    "Mosque of ___ (Jerusalem shrine)",
    "Bradley with many medals",
    "Actor Epps",
    "___ Little, stickup man on \"The Wire\"",
    "Epps of \"House\"",
    "Longtime Sudanese president ___ al-Bashir",
    "Longtime Sudanese president ___ al-Bashir",
    "All-Star second baseman Infante",
    "Sudanese president ___ al-Bashir",
    "Poet ___ Khayyam",
    "Poet Khayyam",
    "Baseball All-Star Infante",
    "Poet Khayyam",
    "Sharif of \"Doctor Zhivago\"",
    "Stickup man on \"The Wire\"",
    "Barbra's \"Funny Girl\" co-star",
    "Bradley with five stars",
    "Palestinian nominee for Best Foreign Language Film of 2013",
    "Obama's favorite character on \"The Wire\"",
    "Poet ___ Khayyam",
    "\"The Wire\" antihero",
    "All-Star Infante",
    "\"Ah, my Beloved, fill the Cup that clears\" poet",
    "Poet Khayyam",
    "\"While you live, / Drink!\" poet",
    "Stickup man on \"The Wire\"",
    "Gen. Bradley",
    "Sharif who played half of 3-Down",
    "\"Rubaiyat\" poet",
    "___ the Tentmaker",
    "Highly decorated Bradley",
    "\"'Tis all a Chequer-board of Nights and Days\" poet",
    "\"The thoughtful soul to solitude retires\" poet",
    "\"ER\" actor Epps",
    "Bridge maven Sharif",
    "Baseball's Minaya",
    "___ Little, \"The Wire\" gangster",
    "Epps of \"House\"",
    "Jerusalem's Mosque of ___",
    "\"The Rubaiyat\" poet ___ Khayyam",
    "Epps or Sharif",
    "Funny co-star in \"Barbra's Girl\"",
    "___ Little, \"The Wire\" antihero",
    "General Bradley",
    "Baseball G.M. Minaya",
    "Epps of \"House\"",
    "Sharif of \"Doctor Zhivago\"",
    "W.W. II general Bradley",
    "Poet Khayyam",
    "___ Little, \"The Wire\" gangster",
    "Poet Khayyam",
    "Five-star Bradley",
    "Gen. Bradley",
    "Taliban leader",
    "Poet known as \"the Tentmaker\"",
    "Epps of TV's \"House\"",
    "\"The Rubaiyat\" poet",
    "Baseball exec Minaya",
    "Actor Epps",
    "\"A Jug of Wine ...\" poet",
    "Sharif of \"Funny Girl\"",
    "Title poet in a 1957 biopic",
    "Sharif of \"Doctor Zhivago\"",
    "Poet ___ Khayyam",
    "Poet who wrote \"The moving finger writes; and, having writ, moves on\"",
    "With 6-Down, Doctor Zhivago's portrayer",
    "Gen. Bradley",
    "Actor Epps",
    "Early astronomer for whom a lunar crater is named",
    "Epps of TV's \"House\"",
    "Sharif of \"Doctor Zhivago\"",
    "Baseball's Vizquel",
    "Epps of \"Scream 2\"",
    "\"Rubaiyat\" poet",
    "Actor Epps",
    "W.W. II general Bradley",
    "Gen. Bradley",
    "Sharif of film",
    "Former Panamanian leader Torrijos",
    "Gen. Bradley",
    "Bridge maven Sharif",
    "\"A Book of Verses underneath the Bough\" poet",
    "Bridge guru Sharif",
    "Sharif of film",
    "___ Torrijos Herrera, former head of Panama",
    "Actor Epps",
    "W.W. II general Bradley",
    "Duke of 63-Down",
    "Sharif of film",
    "Bradley or Sharif",
    "Sharif of \"Funny Girl\"",
    "Mullah Muhammad ___",
    "Taliban mullah",
    "?ôRubaiyat?ö poet",
    "Taliban mullah",
    "Bridge master Sharif",
    "Poet Khayyam",
    "\"Lion of the Desert\" role",
    "Bradley or Sharif",
    "Epps of \"Higher Learning\"",
    "Actor Epps",
    "Gen. Bradley",
    "Taliban mullah",
    "Gen. Bradley",
    "Source of the line \"The Leaves of Life keep falling one by one\"",
    "Actor Sharif",
    "Mullah ___, former Afghan leader",
    "Bradley or Sharif",
    "Jerusalem's Mosque of ___",
    "\"A loaf of bread ...\" poet",
    "Gen. Torrijos",
    "General under Dwight",
    "Taliban leader",
    "Former Army chief of staff Bradley",
    "Sharif of \"Doctor Zhivago\"",
    "Famed quatrain writer",
    "Barbra's \"Funny Girl\" co-star",
    "Epps of \"The Mod Squad,\" 1999",
    "Five-star Bradley",
    "Jerusalem's Mosque of ___",
    "Gen. Bradley",
    "All-Star shortstop Vizquel",
    "Gen. Bradley",
    "Gen. Bradley",
    "12th-century mathematician",
    "Jazz drummer ___ Hakim",
    "\"A Jug of Wine, a Loaf of Bread - and Thou\" poet",
    "Bradley, the G.I.'s General",
    "Actor Epps",
    "Baseball's Vizquel",
    "Jerusalem's Mosque of ___",
    "\"A Jug of Wine, a Loaf of Bread--and Thou\" poet",
    "Actor Sharif",
    "Early writer on algebra",
    "Baseball's Vizquel",
    "Persian poet",
    "Baseballer Vizquel",
    "Gen. Bradley",
    "He played Yuri in \"Doctor Zhivago\"",
    "Poet Khayyam",
    "Poet who wrote of \"Sultan after Sultan with his Pomp\"",
    "\"The Leaves of Life keep falling one by one\" poet",
    "First name in bridge",
    "Baseball's Vizquel",
    "Gen. Bradley",
    "\"The Leaves of Life keep falling one by one\" poet",
    "Gen. Bradley",
    "Sheik ___ Abdel Rahman",
    "Bridge whiz Sharif",
    "\"The Rubaiyat\" poet",
    "Sharif of \"Doctor Zhivago\"",
    "Gen. Bradley",
    "Bridge expert Sharif",
    "Poet portrayed by Vincent Price in \"Son of Sinbad\"",
    "Bradley, the G.I.'s General",
    "Barbra's \"Funny Girl\" co-star",
    "Five-star Bradley",
    "12th-century poet",
    "90's actor Epps",
    "\"Rubaiyat\" poet",
    "First name in bridge",
    "\"ER\" actor Epps",
    "Barbra's 1968 co-star",
    "Jerusalem's Mosque of ___",
    "Early writer on algebra",
    "Gen. Bradley",
    "Bradley or Sharif",
    "Gen. Bradley",
    "Gen. Bradley",
    "First name in Persian poetry",
    "Gen. Bradley",
    "Barbra's 1968 co-star",
    "Bridge expert Sharif",
    "Caliph who founded Cairo",
    "Versifying astronomer",
    "Gen. Bradley",
    "Poet translated by FitzGerald",
    "Gooding of ?ôHangin?Æ with Mr. Cooper?ö",
    "Poet who wrote a-a-b-a verses",
    "Actor Sharif",
    "Poet translated by FitzGerald",
    "Barbra's \"Funny Girl\" co-star",
    "Actor Sharif",
    "Gen. Bradley",
    "Poet and tentmaker's son",
    "Persian cat?",
    "Jerusalem's Mosque of ___",
    "Gen. Bradley",
    "Sheik Abdel Rahman",
    "General under Dwight",
    "Tentmaker of fame",
    "Poet Khayyam",
    "Early astronomer",
    "Poet translated by FitzGerald",
    "Barbra's co-star in '68",
    "General Bradley"
  ],
  "died": [
    "\"I literally ___\" (millennial's overstatement)",
    "Fell off, as laughter",
    "Went pffft!",
    "Word on a gravestone",
    "Ran out of juice",
    "Broke down",
    "Stopped working",
    "Went kaput",
    "Expired",
    "Went on the fritz",
    "Didn't make it",
    "Stopped working",
    "Was incredibly embarrassed, in slang",
    "Quit running",
    "Was mortified, hyperbolically",
    "Was overcome with embarrassment, in slang",
    "Burned out",
    "Conked out",
    "Went kaput",
    "Fizzled out",
    "Tombstone word",
    "Went out, as a fire",
    "Went kaput",
    "Went out, as a fire",
    "Stopped working, as an engine",
    "Went pffft",
    "Went out, as a fire",
    "Burned out",
    "Conked out",
    "Didn't get a single laugh",
    "Lost vitality",
    "Fizzled out",
    "Fizzled"
  ],
  "slam": [
    "Criticize forcefully",
    "Poetry showdown",
    "Close with a bang",
    "Bad-mouth",
    "Verse-vs.-verse event",
    "Shut loudly",
    "Roast",
    "Criticize severely",
    "Poetry competition",
    "Insult",
    "Dis",
    "Body ___",
    "Big pan?",
    "Tear into",
    "Tear into",
    "Bad-mouth",
    "Dis",
    "With 11-Down, bash",
    "Close with a bang",
    "Dis",
    "Poetry fest",
    "Grand ___ (baseball or bridge feat)",
    "Verbal assault",
    "Close with a bang",
    "Pro wrestling move",
    "Compliment's opposite",
    "Dis",
    "Poetry ___",
    "Pan",
    "Dis",
    "Criticize",
    "Shut loudly",
    "Poetry contest",
    "Door-closing sound",
    "Major dis",
    "Not shut quietly",
    "Not close gently",
    "Lambaste",
    "Verbally assault",
    "Lace into",
    "Close angrily",
    "Dis",
    "Criticize severely",
    "Verbal assault",
    "Big bridge win",
    "Kind of dance",
    "Angry response",
    "Lambaste",
    "Verbal assault",
    "Harshly criticize",
    "Dis",
    "Bridge feat",
    "Bridge feat",
    "Nasty criticism",
    "Word after body or grand",
    "Verbal assault",
    "Hit hard",
    "Sharp personal criticism",
    "Dis",
    "Close loudly",
    "Cutting criticism",
    "Lambaste",
    "Culbertson coup",
    "Insult",
    "Door sound",
    "Brickbat",
    "Bridge achievement",
    "Shut noisily",
    "Bridge or wrestling feat",
    "Close loudly",
    "Knock",
    "Shut noisily",
    "Bridge accomplishment",
    "Shut loudly",
    "Putdown",
    "Bridge feat",
    "Bridge feat",
    "Wrestling maneuver",
    "Put down",
    "Go out with a bang",
    "Bridge feat",
    "Bridge feat",
    "Bridge feat",
    "Bridge desideratum",
    "Bridge player's goal"
  ],
  "cone": [
    "Part of the eye",
    "Cup alternative, at times",
    "Pine product",
    "Part of an obstacle course",
    "It gets a licking",
    "Driving test obstacle",
    "Dunce cap, basically",
    "Traffic ___",
    "Traffic director",
    "Alternative to a cup",
    "Roadwork indicator",
    "Shape of a sushi hand roll",
    "Roadwork indicator",
    "Part of a rocket",
    "Ice cream purchase",
    "Summer treat that melts in the sun",
    "Dairy Queen order",
    "Traffic director",
    "Sundae alternative",
    "Baskin-Robbins order",
    "Eye part",
    "Retina feature",
    "Dunce cap shape",
    "Dunce cap shape",
    "Dairy Queen purchase",
    "See 37-Down",
    "Traffic director",
    "Dairy product container",
    "Ice cream treat",
    "Dairy Queen purchase",
    "Dunce cap shape",
    "Traffic marker",
    "Test track obstacle",
    "Juniper product",
    "Gelato holder",
    "Dunce cap, geometrically",
    "Dunce cap, geometrically",
    "Ice cream purchase",
    "Volcanic formation",
    "Highway marker",
    "New Year's Eve party hat, essentially",
    "It gets a licking",
    "Cooling treat",
    "Dairy Queen order",
    "It's got a point",
    "Traffic director",
    "Retina feature",
    "Scoop holder",
    "Traffic marker",
    "Construction site sight",
    "Ice cream holder",
    "Traffic marker",
    "Christmas decoration",
    "Test course obstacle",
    "Traffic ___",
    "Ice cream treat",
    "Ice cream holder",
    "Dunce cap shape",
    "Traffic director",
    "See 9-Down",
    "Summer treat",
    "Construction zone sight",
    "Traffic ___",
    "Volcano apex",
    "Baskin-Robbins purchase",
    "Dunce cap, essentially",
    "Retina part",
    "Volcano's shape",
    "Dairy Queen order",
    "Pitcher David",
    "Sprinkle site"
  ],
  "dunn": [
    "Former major-league slugger Adam nicknamed \"Big Donkey\"",
    "Brooks & ___ (country duo)",
    "Musical duo Brooks & ___",
    "Comedian Nora",
    "Singing partner of Brooks",
    "Country music's Holly",
    "Comedienne Nora"
  ],
  "weir": [
    "Olympic figure skating commentator Johnny",
    "Olympic figure skater Johnny",
    "Small dam",
    "Small dam",
    "Small dam",
    "Small dam",
    "Small dam",
    "Small dam",
    "Small dam",
    "Peter who directed \"Picnic at Hanging Rock\"",
    "Small dam",
    "Dam that aids in fish-catching",
    "Small dam",
    "Small dam",
    "Milldam",
    "Dam in a stream",
    "Dam across a creek",
    "Small dam",
    "\"Witness\" director Peter",
    "Milldam",
    "Peter who directed \"The Truman Show\"",
    "Small dam",
    "Stream blocker",
    "Dam",
    "Small dam",
    "1999 Broadway hit, with \"The\"",
    "Small river dam",
    "Milldam",
    "Film director Peter",
    "Dam",
    "\"Gallipoli\" director",
    "\"Witness\" director Peter",
    "Dam"
  ],
  "lara": [
    "Spencer of \"Good Morning America\"",
    "Dr. Zhivago's love",
    "With 80-Across, longtime CBS News correspondent",
    "Croft of Tomb Raider",
    "Nymph who divulged Jupiter's affair with Juturna, in Ovid",
    "Dr. Zhivago's love",
    "Yuri Zhivago's love",
    "Journalist Logan",
    "___ Croft, comic book heroine",
    "Actress ___ Flynn Boyle",
    "Actress ___ Flynn Boyle",
    "Doctor Zhivago's love",
    "Film treasure hunter Croft",
    "Logan of CBS News",
    "Logan of \"60 Minutes\"",
    "Boris Pasternak heroine",
    "Logan of CBS News",
    "Tomb raider ___ Croft",
    "Boris Pasternak heroine",
    "___ Spencer, co-anchor of ABC's \"Good Morning America\"",
    "Newswoman Logan",
    "Doctor Zhivago's love",
    "___ Croft, \"Tomb Raider\" role",
    "Yuri's love in \"Doctor Zhivago\"",
    "Actress ___ Flynn Boyle",
    "\"Doctor Zhivago\" woman",
    "___ Croft (Angelina Jolie role)",
    "Doctor Zhivago's love",
    "Logan of \"60 Minutes\"",
    "Spencer of \"Good Morning America\"",
    "Newswoman Logan",
    "Pasternak heroine",
    "Yuri's beloved, in literature",
    "\"Doctor Zhivago\" role",
    "___ Croft, comic book heroine",
    "Actress ___ Flynn Boyle",
    "\"Doctor Zhivago\" role",
    "\"60 Minutes\" correspondent Logan",
    "Zhivago's love",
    "Dr. Zhivago's love",
    "\"___ Croft: Tomb Raider\"",
    "Logan of \"60 Minutes\"",
    "Superman's mother",
    "Actress ___ Flynn Boyle",
    "\"___ Croft: Tomb Raider\"",
    "Actress ___ Flynn Boyle",
    "\"60 Minutes\" correspondent Logan",
    "Byron poem",
    "___ Croft, Angelina Jolie role",
    "Newswoman Logan",
    "___ Croft, title role for Angelina Jolie",
    "Zhivago's love",
    "Doctor Zhivago's love",
    "Her theme song was a 1966 hit",
    "___ Flynn Boyle of \"Twin Peaks\"",
    "Yuri's love in \"Dr. Zhivago\"",
    "Dr. Zhivago's love",
    "Newswoman Logan",
    "1965 Julie Christie role",
    "Boris Pasternak heroine",
    "Zhivago's love",
    "Logan of CBS News",
    "1814 Byron poem",
    "Yuri's love, in \"Doctor Zhivago\"",
    "Tomb raider of film, ___ Croft",
    "\"Tomb raider\" Croft",
    "Dr. Zhivago's love",
    "\"Antiques Roadshow\" host Spencer",
    "Boris Pasternak heroine",
    "\"___ Croft Tomb Raider\" (2001 film)",
    "Zhivago's love",
    "Pasternak heroine",
    "Superman's mother",
    "Pasternak heroine",
    "Superman's mother",
    "Zhivago's love",
    "\"___ Croft: Tomb Raider\" (2001 flick)",
    "Superman's mother",
    "___ Flynn Boyle of \"The Practice\"",
    "Starring role for Julie Christie, 1965",
    "Pasternak heroine",
    "Actress ___ Flynn Boyle",
    "Superman's mother",
    "Superman's mother",
    "Angelina played her in \"Tomb Raider\"",
    "Yuri's love, in a Pasternak book",
    "Zhivago's love",
    "\"___ Croft: Tomb Raider\" (2001 movie)",
    "Woman with a 1960's movie theme",
    "1814 Byron poem",
    "Zhivago's love",
    "Superman's mother",
    "Superman's mother",
    "Actress ___ Flynn Boyle",
    "Superman's mother",
    "Byron poem",
    "Julie in \"Doctor Zhivago\"",
    "Olympic skier Magoni",
    "Her theme was a hit",
    "Superman's mother",
    "Pasternak lady",
    "Zhivago's love",
    "\"Dr. Zhivago\" name",
    "Zhivago's love",
    "Yuri's love",
    "Lord Byron poem",
    "Zhivago's love",
    "Lord Byron hero",
    "Zhivago's love",
    "\"Doctor Zhivago\" heroine",
    "Byron poem",
    "1814 Byron poem",
    "Actress ___ Flynn Boyle",
    "Zhivago's love",
    "Superman's mother",
    "Zhivago's love",
    "Narrative Byron poem",
    "Zhivago's love"
  ],
  "imac": [
    "Big Apple",
    "Apple on the teacher's desk?",
    "Desktop computer covered by AppleCare",
    "Certain Apple",
    "Desktop debut of 1998",
    "Apple on a desktop",
    "Big Apple?",
    "Apple variety",
    "Popular Apple product",
    "Tech debut of 1998",
    "Big Apple?",
    "Apple variety",
    "Apple introduced in 1998",
    "Apple computer",
    "Egg-shaped computer, once",
    "\"Say hello to ___\" (ad slogan of 1998)",
    "Apple on a desk",
    "Desktop item since 1998",
    "Apple computer",
    "Big name in desktops",
    "Apple desktop",
    "Apple computer",
    "Apple desktop",
    "Chromebook competitor",
    "OS X runner",
    "Part of a long line of Apples",
    "Apple on a desk",
    "Big Apple?",
    "Desktop computer that runs Safari",
    "Apple desktop",
    "Apple introduction of 1998",
    "It may run the Sierra OS",
    "Apple desktop",
    "Apple that might be seen on a teacher's desk",
    "Apple offering",
    "Yosemite runner",
    "Apple variety",
    "Popular desktop computer",
    "Tech debut of 1998",
    "Computer that sounds like a theater when pluralized",
    "Non-PC office purchase",
    "Apple variety",
    "Apple desktop",
    "Apple since 1998",
    "Apple offering",
    "Apple offering",
    "Computer whose second letter is capitalized",
    "Computer that runs OS X",
    "Old \"There's no step 3!\" sloganeer",
    "One might run Lion or Leopard",
    "Apple product",
    "Apple variety",
    "Apple product",
    "Jobs creation?",
    "Non-PC choice",
    "Apple computer",
    "Apple computer",
    "Desktop brand",
    "G3, G4 or G5",
    "Apple choice",
    "Leopard's home?",
    "Apple computer",
    "Jobs plan, once",
    "Apple offering",
    "Apple debut of 1998",
    "Big Apple?",
    "Computer that once came in Bondi Blue",
    "Apple product",
    "Computer serviced at Genius Bars",
    "Non-PC computer",
    "Apple computer",
    "Apple computer",
    "Apple offering",
    "Big name in desktops",
    "Computer that once came in \"flavors\"",
    "Leopard's home?",
    "Computer debut of 1998",
    "G4 or G5",
    "Ballyhooed new product of 1998",
    "Apple computer",
    "Apple variety",
    "Jobs offering of 1998",
    "Product promoted as having both \"beauty\" and \"brains\"",
    "Non-PC purchase",
    "Product once advertised with the catchphrase \"There's no step 3!\"",
    "Computer with an iSight camera",
    "Computer once with an egg-shaped design",
    "Computer that uses OS X",
    "Product whose 1-Across is a 61-Across",
    "Apple on a teacher's desk?",
    "Computer that originally came in \"flavors\"",
    "Computer introduced by Steve Jobs",
    "G3 or G4 computer",
    "Apple variety",
    "Colorful computer",
    "*Apple variety",
    "Apple variety",
    "Un-PC computer?",
    "Computer since 1998",
    "Apple variety",
    "OS X user, maybe",
    "Apple of many colors",
    "Apple computer",
    "Apple that may be green or red",
    "Apple best seller",
    "All-in-one computer",
    "Apple variety",
    "Apple piece",
    "PC alternative",
    "Apple variety",
    "Apple product",
    "Apple production",
    "Apple that may be green or red",
    "Apple that's brightly colored",
    "PC competitor",
    "Apple not for eating",
    "Colorful computer",
    "Popular computer",
    "Colorful Apple",
    "Apple product",
    "Apple of a sort",
    "Colorful computer",
    "Apple product"
  ],
  "vane": [
    "Windmill part",
    "One providing directions",
    "Barn topper",
    "Provider of directions to a farmer",
    "Pointer for a meteorologist?",
    "Pointer for a weather person?",
    "Barn topper",
    "Fan part",
    "Wind instrument",
    "Barn topper",
    "Weather indicator",
    "Revolver with the letters N-E-W-S",
    "Provider of directions?",
    "Source of current information?",
    "It shows which way the wind blows",
    "Roof topper",
    "Something you always need directions to use?",
    "It turns on a turbine",
    "Barn topper",
    "Often winded part",
    "Device that may include an anemometer",
    "Rooster holder, maybe",
    "Barn topper",
    "Barn topper",
    "Wind instrument",
    "Wind instrument",
    "Windmill blade",
    "Wind instrument",
    "Barn topper",
    "Rooftop sight",
    "Turbine part",
    "Windmill part",
    "Rooster site, maybe",
    "Weather indicator",
    "Windmill blade",
    "Barn topper",
    "Indicator of current trends?",
    "It may turn on a rooster",
    "Place for a rooster",
    "Wind instrument",
    "Arrow site",
    "Barn accessory",
    "Rooster locale",
    "Windmill blade",
    "Barn topper",
    "Farm pointer?",
    "Roof topper",
    "Barn topper",
    "It's blowin' in the wind",
    "Wind instrument?",
    "Windmill segment"
  ],
  "aces": [
    "Las Vegas W.N.B.A. team",
    "Super-duper",
    "Super-duper",
    "\"Pocket rockets,\" in poker",
    "Pocket rockets, in poker",
    "Standouts on the pitcher's mound",
    "Leaders of the pack?",
    "AA",
    "Vanquishers of kings?",
    "Nice poker holdings",
    "Prized blackjack cards",
    "Super, in slang",
    "\"Bullets,\" in poker",
    "Star pitchers",
    "Near impossibilities on par-5 holes",
    "Terrific, in dated slang",
    "Opposite of bombs",
    "Just fabulous",
    "Mavens",
    "Hero fighter pilots",
    "The best, informally",
    "Air Medal recipients",
    "High cards",
    "Terrific, in slang",
    "Nails",
    "Not just passes",
    "The best, in slang",
    "Super-duper",
    "Crackerjack",
    "Bullets, in cards",
    "Wonderful, in old slang",
    "The Red Baron and others",
    "Whizzes",
    "Part of a dead man's hand",
    "Cards sometimes hidden up sleeves",
    "First-class, in slang",
    "Ones in suits?",
    "Hero war pilots",
    "High/low cards",
    "Big dogs in dogfights",
    "King toppers",
    "Clubs to beat people with?",
    "Nails",
    "Cards that top kings",
    "Hunky-dory",
    "Air force heroes",
    "Excellent, slangily",
    "Superb, in slang",
    "Some war heroes",
    "Blasts through",
    "They aren't returned",
    "Items up one's sleeve, maybe",
    "Hand helpers",
    "Pair in a dead man's hand",
    "Some tennis winners",
    "Masters",
    "Untouchable tennis serves",
    "They're worth 1 or 11 in blackjack",
    "One-spot cards",
    "Golf rarities",
    "They outrank kings",
    "Crackerjacks",
    "Provides service that can't be beat?",
    "King beaters",
    "Hotshots",
    "Unreturned tennis serves",
    "Bullets, in Texas hold'em",
    "Big diamonds, maybe",
    "King beaters",
    "Breezes through",
    "Strong hold'em holding",
    "Sports winners",
    "Reno's AAA baseball team",
    "Perfect tennis serves",
    "Nails",
    "Good blackjack holdings",
    "One-spot cards",
    "Nails",
    "Star pitchers",
    "Great, in slang",
    "Top guns",
    "Killer serves",
    "They're out of reach",
    "Scores quickly?",
    "Tip-top",
    "Super-duper",
    "Assets in poker",
    "One-spot cards",
    "Big hearts?",
    "Super-duper",
    "11's in blackjack",
    "First-rate",
    "Components of good deals",
    "Top guns",
    "Top-notch",
    "Super-duper",
    "Hero aviators",
    "Super-duper",
    "Breezes through, as a test",
    "Quick points",
    "They're not returned",
    "Bullets",
    "Air force heroes",
    "They're worth 1 or 11 in blackjack",
    "The Red Baron and others",
    "Links rarities",
    "Ideal serves",
    "Does perfectly",
    "Deck quartet",
    "They're worth 1 or 11 points",
    "Great, slangily",
    "Decorated pilots",
    "Whiz kids",
    "The best three in a three-of-a-kind",
    "Pair in a dead man's hand",
    "High cards",
    "Golf coups",
    "Serves well done",
    "Doesn't merely pass",
    "Top four",
    "Super-duper",
    "Whizzes",
    "Tennis statistic",
    "They're part of a good deal",
    "First-rate, slangily",
    "Court coups",
    "Singles",
    "Bullets, to bettors",
    "Bullets, in card slang",
    "Sneaks one past",
    "The tops",
    "One-spots",
    "Air force heroes",
    "One-spots",
    "They're good for tricks",
    "Makes a hole-in-one",
    "Ones that may be high?",
    "Top parts of suits",
    "Whizzes",
    "Top-notch",
    "Cards up one's sleeve?",
    "Hand helpers",
    "Scores on a serve",
    "Some 120 m.p.h. serves",
    "Tops",
    "Passes easily",
    "Crackerjacks",
    "They're nonreturnable",
    "They're unreturnable",
    "A very good pair",
    "One-spots",
    "Super-duper",
    "Good blackjack holdings",
    "Versatile blackjack holdings",
    "Whizzes",
    "Puts one past",
    "First-rate",
    "They're good with tricks",
    "Part of a dead man's hand",
    "Blackjack bases",
    "Old radio's \"Easy___\"",
    "Poker bullets",
    "Big hand?",
    "Bridge honors",
    "Whizzes",
    "King toppers",
    "High pair",
    "Court coups",
    "Virtuosos",
    "Top guns",
    "Gets an A",
    "Bullets",
    "Experts",
    "Fine poker holdings",
    "They're sometimes wild",
    "Old radio favorite \"Easy ___\"",
    "They're good with tricks"
  ],
  "sack": [
    "Hit the ___",
    "Hacky ___ (game)",
    "Give the ax",
    "Fire",
    "QB tackle",
    "Ax",
    "Hit the ___ (go to bed)",
    "Fire, as from a job",
    "Picnic race need",
    "Tackle, in a way",
    "Give the heave-ho",
    "Pink-slip",
    "Bag",
    "Blitzing linebacker's feat",
    "Can",
    "Fire",
    "Lineman's datum",
    "Bed, slangily",
    "Pillage",
    "Picnic racer's gear",
    "Pink-slip",
    "Fire",
    "Pink-slip",
    "Bag",
    "Fire",
    "Big bag",
    "Ax",
    "Fire",
    "Can"
  ],
  "alle": [
    "Everybody: Ger.",
    "Everybody, to Erich",
    "It means everyone to Hans",
    "Everyone, in Essen",
    "Everything, in Germany",
    "Everything, on the Ems",
    "Pas ___ (gentle ballet step)",
    "Every, in Essen"
  ],
  "nude": [
    "Like some nail polish shades",
    "Lipstick shade",
    "Statue that might offend a bluenose",
    "Titian's \"Venus Anadyomene,\" e.g.",
    "Like photos that violate one of Instagram's community guidelines",
    "Michelangelo's \"David,\" for one",
    "Like Rodin's \"The Thinker\"",
    "Titian's \"Venus of Urbino,\" e.g.",
    "Classic art subject",
    "With nothing on",
    "Naked",
    "Like Venus in \"The Birth of Venus\"",
    "Kind of beach",
    "Unclothed",
    "In the altogether",
    "Beige shade",
    "\"David,\" e.g.",
    "Like Michelangelo's \"David\"",
    "Like Rodin's \"The Thinker\"",
    "Like some sunbathers",
    "Many a Rubens subject",
    "Like the athletes in the ancient Olympics",
    "Like Michelangelo's \"David\" or Rodin's \"The Thinker\"",
    "Fully exposed",
    "On a streak?",
    "As one entered the world",
    "\"The Thinker,\" for one",
    "Michelangelo's David, e.g.",
    "Ready for skinny-dipping",
    "Naked",
    "Like the upper half of the Venus de Milo",
    "Michelangelo's David, e.g.",
    "Like Michelangelo's David",
    "Not having a stitch on",
    "Pantyhose shade",
    "Art class figure",
    "Painter's subject",
    "Hosiery shade",
    "Botticelli figure",
    "Hosiery shade",
    "Unclothed",
    "In the raw",
    "Art subject",
    "Like Botticelli's Venus",
    "Classic artist's subject",
    "Au naturel",
    "In the altogether",
    "Painting type",
    "Art subject",
    "Painting not for the demure",
    "Stocking shade",
    "Revealing work of art?",
    "Titian's \"Venus of Urbino,\" for one",
    "Art class model",
    "Au naturel",
    "Stocking shade",
    "Hosiery shade",
    "Classic art subject",
    "Uncovered",
    "Goya's Maja is one",
    "Kind of beach",
    "Marcel Duchamp subject",
    "Skin-colored",
    "Classic art subject",
    "Art subject",
    "Hosiery shade",
    "Fully exposed",
    "Unattired",
    "Fully exposed",
    "Au naturel",
    "Botticelli?Æs Venus, e.g.",
    "Manet's \"Olympia,\" e.g.",
    "Playboy pie",
    "Bare",
    "Duchamp subject",
    "Like 52-Across"
  ],
  "dido": [
    "Legendary queen once depicted on Tunisian currency",
    "Legendary queen and founder of Carthage",
    "Queen who made Carthage prosper",
    "First queen of Carthage",
    "Aeneas' love",
    "Mischievous trick",
    "Mythical queen of Carthage",
    "\"White Flag\" singer, 2003",
    "Founder of Carthage, in myth",
    "Character with the aria \"When I am laid in earth\"",
    "Singer with the 4x platinum album \"No Angel\"",
    "First queen of Carthage",
    "Tragically heartbroken figure of myth",
    "Lover of Aeneas",
    "Featured singer on Eminem's \"Stan\"",
    "\"Thank You\" singer, 2001",
    "Founder and first queen of Carthage",
    "\"White Flag\" singer, 2003",
    "Queen of Carthage who loved Aeneas",
    "One-named singer with the 2001 hit \"Thank You\"",
    "Clever prank",
    "Singer of the 2001 hit \"Thankyou\"",
    "\"Thank You\" singer, 2001",
    "Aeneas abandoned her",
    "Prank",
    "Queen abandoned by Aeneas",
    "Queen of Carthage",
    "Lover of Aeneas",
    "Founder of Carthage",
    "Purcell's \"___ and Aeneas\"",
    "Carthage founder",
    "Lover of Aeneas",
    "Monkeyshine",
    "Queen of Carthage, in myth",
    "Antic"
  ],
  "andy": [
    "Comedian Richter",
    "___ Murray, two-time Wimbledon champ",
    "Woody and Buzz's owner in \"Toy Story\"",
    "___ Dwyer, role for Chris Pratt on \"Parks and Recreation\"",
    "Tennis's Murray or Roddick",
    "\"Toy Story\" boy",
    "Guitarist Summers of the Police",
    "Coach Reid of the 2020 Super Bowl-winning Chiefs",
    "Artist Warhol",
    "Tennis great Roddick",
    "\"Toy Story\" boy",
    "\"Toy Story\" boy",
    "Funny Samberg",
    "First name in pop art",
    "Samberg of \"Brooklyn Nine-Nine\"",
    "Talk show host Cohen",
    "Capp of classic comics",
    "Soup can painter Warhol",
    "Boy who owns Buzz Lightyear and Woody",
    "Roddick or Rooney",
    "Tennis's Murray",
    "Artist Warhol",
    "With 41-Down, first tennis player to win two Olympic singles gold medals",
    "Artist Warhol",
    "Garcia of \"Ocean's ...\" movies",
    "Roddick who won the 2003 U.S. Open",
    "\"Toy Story\" boy ... or, with the circled letters, a hint to 20-, 39- and 53-Across",
    "Crooner Williams",
    "Samberg formerly of \"S.N.L.\"",
    "Samberg formerly of \"S.N.L.\"",
    "Murray who's highly raked",
    "Humorist Rooney",
    "Handy-___",
    "\"Amos 'n' ___\"",
    "\"The ___ Williams Christmas Album\"",
    "Handy ___",
    "Red's pal in \"The Shawshank Redemption\"",
    "Tennis's Roddick",
    "British royal, informally",
    "Artist Warhol",
    "Roy Rogers sidekick Devine",
    "Granatelli of auto racing",
    "Pop artist Warhol",
    "He's a doll",
    "\"Handy\" one",
    "Auto racer Granatelli",
    "Kaufman or Rooney",
    "With 28-Down, noted 20th-century American artist, informally",
    "Williams of song",
    "Tennis champion Roddick",
    "\"Only the Paranoid Survive\" author ___ Grove",
    "Mayberry man",
    "Capp of comics",
    "Artist Warhol",
    "Raggedy fellow",
    "\"Handy\" one",
    "Rooney of \"60 Minutes\"",
    "Rag doll's name",
    "Artist Warhol",
    "\"Show Boat\" cap'n",
    "Cap'n ___ of \"Show Boat\"",
    "Gump of the comics",
    "Cap'n Hawks of \"Show Boat\"",
    "Raggedy Ann's friend",
    "Rooney of \"60 Minutes\"",
    "Capp of the comics",
    "\"Handy\" man",
    "British royal, informally",
    "Amos's partner",
    "Capp of the comics",
    "Hardy boy",
    "Gump of the comics",
    "Artist Warhol",
    "First name on \"60 Minutes\"",
    "Rooney of \"60 Minutes\"",
    "Opie's dad, on 60's TV",
    "Hardy boy",
    "English prince's nickname",
    "Auto racer Granatelli",
    "One of the Gibb brothers",
    "Crooner Williams",
    "Humorist Rooney"
  ],
  "kerr": [
    "Model Miranda",
    "See 4-Down",
    "Deborah who starred in \"The King and I\"",
    "N.B.A. coach Steve",
    "Deborah of \"The King and I\"",
    "N.B.A. coach Steve",
    "Deborah of \"The King and I\"",
    "Deborah of \"The King and I\"",
    "Bygone Broadway critic Walter",
    "Six-time N.B.A. champion Steve",
    "N.B.A. head coach Steve",
    "Deborah who was nominated for a record six Best Actress Oscars without ever winning",
    "Theater critic Walter",
    "Deborah of \"The King and I\"",
    "Michael Jordan teammate Steve",
    "Deborah of \"The King and I\"",
    "Deborah of \"Tea and Sympathy\"",
    "Deborah of \"The King and I\"",
    "\"The King and I\" actress",
    "Deborah of \"The King and I\"",
    "Steve ___, 1990s teammate of Michael Jordan",
    "Deborah of \"The King and I\"",
    "Deborah of \"The King and I\"",
    "Jean who wrote \"Please Don't Eat the Daisies\"",
    "Deborah nominated for six Academy Awards",
    "Deborah who starred in \"Tea and Sympathy\"",
    "\"The Galloping Gourmet\" host Graham",
    "\"An Affair to Remember\" star, 1957",
    "Lancaster's \"From Here to Eternity\" co-star",
    "Deborah of \"The King and I\"",
    "Deborah of 54-Across",
    "Six-time Best Actress nominee",
    "\"The King and I\" co-star",
    "\"The King and I\" co-star",
    "Brynner's co-star in film's \"The King and I\"",
    "\"From Here to Eternity\" actress",
    "Brynner's co-star in \"The King and I\"",
    "\"From Here to Eternity\" actress",
    "See 58-Down",
    "\"The Silent Clowns\" author",
    "\"The King and I\" co-star",
    "Critic Walter",
    "Actress Deborah",
    "\"The King and I\" actress",
    "The Galloping Gourmet",
    "Chef Graham",
    "Either star of \"Tea and Sympathy\"",
    "Lifetime achievement Oscar winner Deborah"
  ],
  "wend": [
    "Make, as one's way",
    "Make, as one's way",
    "Proceed on one's way",
    "Meander",
    "Travel",
    "Meander",
    "Meander",
    "Go, as one's way",
    "Proceed",
    "Go along, as one's way",
    "Make, as one's way",
    "Proceed",
    "Proceed",
    "Make (one's way)",
    "Travel",
    "Proceed",
    "Make, as one's way",
    "Proceed",
    "Make (one's way)",
    "Make (one's way)",
    "Proceed",
    "Proceed",
    "Proceed",
    "Travel"
  ],
  "cams": [
    "Some dash mounts",
    "Skyping needs",
    "Reality show gear, informally",
    "Home security devices, for short",
    "They take vids",
    "Wheels inside a car",
    "Video recorders, briefly",
    "PC video gear, for short",
    "Video shooters, for short",
    "They take pics",
    "Some security aids",
    "Rotators under the hood",
    "Engine parts",
    "Bank security devices",
    "They may be overhead",
    "Machine cylinders",
    "Machine parts",
    "Rotating engine parts",
    "Engine disks",
    "Engine parts",
    "Machine parts",
    "Engine pads"
  ],
  "vape": [
    "Juul, e.g.",
    "Use a Juul, say",
    "Use a Juul, say",
    "Use an e-cig",
    "Smoke an e-cigarette"
  ],
  "rico": [
    "Puerto ___",
    "Puerto ___",
    "Puerto ___",
    "Part of P.R.",
    "Anti-mob law, for short",
    "\"___ Suave\" (1991 hit)",
    "The \"R\" of P.R.",
    "Part of P.R.",
    "Mob-busting law, for short",
    "Opposite of pobre",
    "Puerto ___",
    "Having mucho dinero",
    "Wealthy: Sp.",
    "Moneyed, in Madrid",
    "Little Caesar in \"Little Caesar\"",
    "Anti-Mafia measure, briefly",
    "Like un millonario",
    "Puerto ___",
    "Main hood in \"Little Caesar,\" 1931",
    "Puerto ___",
    "Puerto ___",
    "___ law (acronymic 1970 measure)",
    "\"Little Caesar\" gangster",
    "Puerto ___",
    "Possessing many pesos",
    "Puerto ___",
    "\"He wore a diamond\" in \"Copacabana\"",
    "Opposite of pobre",
    "First baseman Brogna",
    "Puerto ___",
    "Affluent, in Acapulco",
    "\"Copacabana\" antagonist",
    "It's part of P.R.",
    "Opposite of pobre",
    "Puerto ___",
    "Antimob acronym",
    "Puerto ___",
    "\"Little Caesar\" character",
    "Puerto ___",
    "Wealthy, in Juarez",
    "1991 Gerardo hit \"___ Suave\"",
    "See 53-Down",
    "\"___ Suave\" (1991 hip-hop hit)",
    "Anticrime acronym since 1970",
    "Man in Manilow's \"Copacabana\"",
    "Puerto ___",
    "Puerto ___",
    "Ex-Bosoxer Petrocelli",
    "\"Little Caesar\" gangster",
    "Puerto ___",
    "Anticrime acronym",
    "Not pobre",
    "Baseball's Brogna",
    "Part of P.R.",
    "\"Little Caesar\" role",
    "Puerto ___",
    "\"Miami Vice\" cop",
    "Wealthy, in Madrid",
    "Edward G. Robinson's role in \"Little Caesar\"",
    "Law used to fight organized crime, acronymically",
    "Part of P.R.",
    "Con mucho dinero",
    "Racketeering statute, for short",
    "\"Little Caesar\" role",
    "Puerto ___",
    "Carty of baseball",
    "Carty of baseball"
  ],
  "ulna": [
    "Bone on the pinkie side of the forearm",
    "It plays a role in arm-twisting",
    "It has coronoid and styloid processes",
    "Something you have up your sleeve",
    "It's parallel to a radius",
    "Humerus attachment",
    "Longer forearm bone",
    "It reaches to touch one's hand",
    "Arm twister?",
    "Bone alongside the radius",
    "Humerus connection",
    "Neighbor of the radius",
    "It's found by the radius",
    "Bone involved in pronation",
    "Part of the body whose name is both English and Latin",
    "Radius neighbor",
    "Bone in a wing or arm",
    "What's got the upper hand?",
    "Neighbor of the funny bone",
    "Bone below the elbow",
    "Bone parallel to the radius",
    "Radius's neighbor",
    "It parallels a radius",
    "Literally, \"elbow\"",
    "Arm twister's need?",
    "Bone below the elbow",
    "Fifth-longest bone in the human body",
    "One involved in arm-twisting?",
    "Fibula : leg :: ___ : arm",
    "Bone near the funny bone",
    "Forearm bone",
    "Bone below the elbow",
    "Radius neighbor",
    "Radius neighbor",
    "It's below the humerus",
    "Forearm bone",
    "Bone under a watchband",
    "Bone next to the radius",
    "Radius neighbor",
    "Arm bone",
    "Humerus neighbor",
    "It extends from the elbow",
    "Bone connected to the oblique cord",
    "Bone under a watch",
    "Arm part",
    "Bone paralleling the radius",
    "Forearm bone",
    "It connects to the wrist",
    "Wrist/elbow connector",
    "Wrist/elbow connector",
    "Forearm bone",
    "Bone on the pinkie side",
    "Arm part",
    "Bone that parallels the radius",
    "Forearm part",
    "Locale of the radial notch",
    "It parallels the radius",
    "Bone connected to the supinator muscle",
    "Radius's neighbor",
    "Wrist-elbow connector",
    "Bone involved in pronation",
    "Arms runner?",
    "It runs parallel to the radius",
    "It's below the elbow",
    "Bone meaning \"elbow\" in Latin",
    "Radius neighbor",
    "Arm part",
    "Limb bone",
    "Forearm bone",
    "It's hinged with the humerus",
    "Bone that means \"elbow\" in Latin",
    "It's parallel to a radius",
    "Forearm part",
    "One out on a limb?",
    "Arm bone",
    "One out on a limb",
    "Bone near the radius",
    "One of a skeletal pair",
    "Forearm part",
    "Neighbor of the radius",
    "It runs down a limb",
    "Elbow/hand connector",
    "It runs down one's arm",
    "Skeleton component",
    "Arm bone",
    "Forearm bone",
    "Armbone",
    "Arm bone",
    "Site of a Monteggia's fracture",
    "It connects to the elbow",
    "Bone by the humerus",
    "It runs down the arm",
    "Radius paralleler",
    "It parallels a radius",
    "Arm bone",
    "Forearm bone",
    "Skeleton part",
    "It parallels the radius",
    "Armbone",
    "It's involved in arm-twisting",
    "Bone below the elbow",
    "Radius neighbor",
    "Forearm bone",
    "Bone parallel to the radius",
    "Armbone",
    "It parallels a radius",
    "Elbow-wrist connection",
    "It connects to the wrist",
    "Carpus connector",
    "Bone under a watch",
    "Humerus neighbor",
    "Elbow-wrist connection",
    "It parallels a radius",
    "Radius neighbor",
    "Wrist-elbow connector",
    "Forearm bone",
    "Wrist attachment",
    "Forearm bone",
    "Arm bone",
    "Part of the arm",
    "It parallels the radius",
    "Forearm bone",
    "Arm part",
    "It's parallel to the radius",
    "Skeletal unit",
    "Forearm bone",
    "Arm part",
    "It's near the crazy bone",
    "Arm bone",
    "It parallels the radius",
    "Humerus neighbor",
    "Skeleton part",
    "Part of the arm",
    "Radius's partner",
    "Radius neighbor",
    "Armbone",
    "Forearm bone",
    "Wrist-elbow connector",
    "Radius's partner",
    "Skeleton part",
    "Arm bone",
    "Forelimb part",
    "Limb part",
    "Forearm bone",
    "Humerus neighbor",
    "Forearm part",
    "Armbone",
    "Humerus neighbor",
    "Radius neighbor",
    "Arm bone",
    "Armbone"
  ],
  "pads": [
    "Products of Always or Stayfree",
    "Homes, in slang",
    "Bottoms of dogs' feet",
    "Artificially inflates",
    "Stationer's stock",
    "Saddle accessories",
    "Football gear",
    "Cushions",
    "Launch locations",
    "Fudges, in a way, as an expense report",
    "Bottoms of paws",
    "Some football gear",
    "Homes, colloquially",
    "Cushions",
    "Writing tablets",
    "Small notebooks",
    "Bruise preventers",
    "Brake parts",
    "Goalie protectors",
    "Crash sites?",
    "Brake components",
    "Residences, in slang",
    "Cushions",
    "Launch sites",
    "Fills out",
    "Launch sites ... or crash sites",
    "Adds to dishonestly",
    "Places for notes",
    "Tackle's protection",
    "Writers' blocks",
    "In-line skating gear",
    "Stationer's stock",
    "Stationery store purchases",
    "Pew parts",
    "Linemen's protectors",
    "Football gear",
    "Roller derby equipment",
    "Walks softly",
    "Bruise preventers",
    "Goalie gear",
    "Footballer's protection",
    "Softens",
    "Falsifies accounts"
  ],
  "carb": [
    "Low-___ diet",
    "Potato or pasta, informally",
    "Atkins diet no-no",
    "Paleo diet restriction, informally",
    "Keto diet no-no",
    "Low-___ diet",
    "Low-___ diet",
    "Engine part, briefly",
    "Potato or pasta, informally",
    "Dual-___ engine",
    "Nutrition unit in pasta, informally",
    "Bit of pasta, for short",
    "Pasta, e.g., informally",
    "Rice, e.g., informally",
    "Dual-___",
    "Low-___ diet",
    "Prepare for a marathon, say, with \"up\"",
    "See 43-Across",
    "Pasta or potato, for short",
    "Dual-___ engine",
    "Atkins diet no-no",
    "Low-___ diet",
    "Low-___ diet",
    "___ loading",
    "Low-___ diet",
    "Low-___",
    "Low-___",
    "Atkins no-no",
    "Low-___ diet",
    "Engine part, for short",
    "Pasta or potato, to an athlete",
    "Energy provider, in slang",
    "Sugar or starch, in slang",
    "Hot rod part"
  ],
  "adia": [
    "Sarah McLachlan hit that's 51-Down spelled in reverse",
    "1998 Sarah McLachlan hit",
    "___ Barnes, W.N.I.T.-winning basketball coach",
    "Three-syllable woman's name meaning \"gift\"",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan song",
    "1998 Sarah McLachlan hit",
    "1998 hit from the album \"Surfacing\"",
    "1998 Sarah McLachlan hit",
    "1998 hit that begins \"___, I do believe I failed you\"",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "#3 hit from the 1997 album \"Surfacing\"",
    "Sarah McLachlan hit",
    "1998 Sarah McLachlan song",
    "Hit from the 1997 album \"Surfacing\"",
    "Top 10 hit for Sarah McLachlan",
    "1998 Sarah McLachlan hit",
    "\"___, I do believe I failed you\" (opening of a 1998 hit)",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "Top 5 song by Sarah McLachlan",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit",
    "1998 Sarah McLachlan hit"
  ],
  "poor": [
    "Not up to expectations",
    "Rich's opposite",
    "Substandard",
    "D-worthy",
    "Meriting a \"D,\" say",
    "Have-nots, with \"the\"",
    "D",
    "\"How ___ are they that have not patience!\": Iago",
    "Lame",
    "D-worthy",
    "Like a young Jay Gatsby",
    "D-worthy",
    "Slipshod",
    "D-worthy",
    "Third-class",
    "D",
    "\"You ___ thing!\"",
    "Inadequate",
    "D",
    "Start of a word ladder whose first and last words are suggested by 36-Across",
    "What \"D\" means",
    "Less than fair",
    "Ones protected by a safety net, with \"the\"",
    "Destitute",
    "Like a D-",
    "Robin Hood's beneficiaries, with \"the\"",
    "Below C level?",
    "Description of 15-Down",
    "Meager",
    "Unfortunate",
    "Mean",
    "Hardscrabble",
    "D",
    "Impoverished",
    "Like Ali Baba, at first",
    "\"D\"",
    "Meager",
    "Hood's beneficiaries, with \"the\"",
    "Needy",
    "Inept",
    "D",
    "Poverty-stricken",
    "Robin's beneficiaries",
    "Needy",
    "\"D\"",
    "Hard up",
    "Impoverished",
    "D",
    "Opposite of rich",
    "Needy",
    "\"Alas ___ Yorick...\"",
    "Needy",
    "Hard up",
    "Like Franklin's Richard",
    "Rating a D"
  ],
  "ploy": [
    "Stratagem",
    "Devious maneuver",
    "Devious maneuver",
    "Scheme",
    "Scheme",
    "Stratagem",
    "Sneaky tactic",
    "Stratagem",
    "Maneuver",
    "Stratagem",
    "Subterfuge",
    "Maneuver",
    "Something from one's bag of tricks",
    "Trick",
    "Clever tactic",
    "Sly maneuver",
    "Sneaky tactic",
    "Maneuver",
    "Stratagem",
    "Ruse",
    "Sneaky maneuver",
    "Feint",
    "Stratagem",
    "Ruse",
    "Scheme",
    "Stratagem",
    "Tactic"
  ],
  "rood": [
    "Church cross",
    "Where one might find Jesus",
    "Crucifix",
    "Cross",
    "Crucifixion symbol",
    "Cross",
    "Crucifix",
    "Cross",
    "Cross",
    "Crucifix",
    "Crucifix",
    "Crucifix",
    "Cross",
    "Chancel cross",
    "Crucifix",
    "Crucifix",
    "Crucifix",
    "Crucifix",
    "Cross",
    "Chancel entrance display",
    "Crucifix",
    "Cross",
    "Chancel decoration",
    "Crucifix",
    "Crucifix",
    "Cross",
    "Crucifix"
  ],
  "reed": [
    "Slender plant",
    "Bit of thatching",
    "Papyrus, e.g.",
    "Harmonica piece",
    "Certain mouthpiece attachment",
    "Rocker Lou",
    "Clarinet piece",
    "Bassoon attachment",
    "Donna of old TV",
    "Saxophonist's accessory",
    "Plant by a pond",
    "Liberal arts college in Portland, Ore.",
    "Part of many a woodwind",
    "Marsh plant",
    "Marsh plant",
    "Woodwind category",
    "Elk, for one / Plant in a bog",
    "Clarinet insert",
    "College from which Steve Jobs dropped out",
    "Accordion part",
    "Bassoon part",
    "Marsh grass",
    "Clarinet or sax",
    "English horn, e.g.",
    "Clarinet piece",
    "Thatching material",
    "Rush, e.g.",
    "English horn, for one",
    "College in Portland, Ore.",
    "Saxophone, e.g.",
    "Clarinetist's need",
    "Walter ___ hospital",
    "College from which Steve Jobs dropped out",
    "Plant that's not cultivated",
    "Sax, e.g.",
    "Critic Rex",
    "Harmonica piece",
    "Bassoon, e.g.",
    "Epitome of thinness",
    "___ instrument",
    "Stalk",
    "One of two in an English horn",
    "Swamp growth",
    "Bit of thatching",
    "Crumhorn, e.g.",
    "Bassoon part in two pieces",
    "Action hero's underwater breathing aid",
    "Stick by the water?",
    "Clarinetist's need",
    "Clarinet or sax",
    "Harmonica part",
    "Double-___ (oboe, e.g.)",
    "Stalk by a stream",
    "Plant in a bog",
    "Saxophonist's need",
    "Papyrus, e.g.",
    "Epitome of thinness",
    "Marsh growth",
    "Wetlands plant",
    "Marsh plant",
    "Crumhorn, e.g.",
    "Thatching material",
    "Oboe or clarinet",
    "Portland, Ore., college from which Steve Jobs dropped out",
    "Stalk in a marsh",
    "English horn, e.g.",
    "Papyrus plant, e.g.",
    "Wind element",
    "Woodwind item",
    "One of two in a 47-Across",
    "Sax player's need",
    "Shoot in a swamp",
    "Accordion part",
    "Marsh growth",
    "Bit for a basket weaver",
    "Clarinetist's purchase",
    "Hollow-stemmed plant",
    "Fen bender",
    "Swamp thing",
    "Portland college",
    "Marsh plant",
    "English horn, e.g.",
    "Rush, e.g.",
    "Oboe part",
    "Saxophone, e.g.",
    "Rush",
    "Oboist's need",
    "Marsh plant",
    "Marsh plant",
    "Matting material",
    "Cattail, e.g.",
    "Hollow plant",
    "Oboe or sax",
    "Oregon college",
    "Pastoral pipe",
    "Clarinet, for one",
    "Many an instrument",
    "Bassoonist's buy",
    "Marsh growth",
    "Marsh growth",
    "Bagpipe, e.g.",
    "Bassoon, e.g.",
    "Harmonium part",
    "Oboe, for one",
    "Bassoon, e.g.",
    "College in Portland, Ore.",
    "Swamp thing",
    "Clarinet component",
    "It may be blowing in the wind",
    "Mouthpiece attachment",
    "Film critic Rex",
    "Marsh plant",
    "Critic Rex",
    "Saxophone part",
    "Bulrush, e.g.",
    "Bit of thatch",
    "Kind of instrument",
    "Pastoral pipe",
    "Cattail, e.g.",
    "Feature of some winds",
    "Marsh growth",
    "Symbol of slimness",
    "Rustic pipe",
    "Hollow-stemmed plant",
    "Oboe, e.g.",
    "Clarinet part",
    "Kind of instrument",
    "College in Portland, Ore.",
    "Critic Rex",
    "Kind of organ",
    "Stalk in a swamp",
    "Rustic pipe",
    "Kind of organ",
    "Woodwind",
    "Epitome of thinness",
    "Stalk by the river",
    "Bit of thatching",
    "Instrumental piece",
    "Marsh plant",
    "Pastoral pipe",
    "Army surgeon Walter",
    "Marsh stalk",
    "Thatcher's need",
    "Swamp thing",
    "Oliver of \"Oliver!\"",
    "He wrote \"Ten Days That Shook the World\"",
    "Melodeon part",
    "Saxophone, e.g.",
    "Sax or oboe",
    "\"The Brady Bunch\" regular",
    "Avant-garde rocker Lou",
    "Musical pipe",
    "Marsh plant",
    "Portland college",
    "Clarinet part",
    "Epitome of thinness",
    "Marsh plant",
    "Marsh growth",
    "Saxophone, e.g.",
    "Marsh plant",
    "Critic Rex",
    "Bassoon, e.g.",
    "Sax, for one",
    "Epitome of thinness",
    "Hautboy, e.g.",
    "\"The Third Man\" director",
    "Walter ___ Hospital"
  ],
  "rind": [
    "Cocktail garnish",
    "Tough skin",
    "What's hard about a melon?",
    "Orange discard",
    "Addition to a compost pile",
    "Watermelon waste",
    "Peel",
    "Zest source",
    "Compost heap bit",
    "Lemon oil source",
    "A waste of good food?",
    "Thick skin",
    "Source of zest",
    "Cantaloupe cover",
    "Zest",
    "Zest",
    "Orange exterior",
    "Outside of a watermelon",
    "Watermelon covering",
    "Marmalade ingredient",
    "Thick skin",
    "Watermelon hull",
    "Brie exterior",
    "Melon's cover",
    "Outside of a watermelon",
    "Outside of a watermelon",
    "Orange or watermelon cover",
    "38-Across covering",
    "Drink garnish, often",
    "Skin of a fruit",
    "Part that's cut off and thrown away",
    "Brie coat",
    "Melon exterior",
    "Zest",
    "Peel",
    "Drink garnish",
    "Cocktail garnish",
    "Orange coat",
    "Zest producer",
    "Marmalade ingredient",
    "Cheese coating",
    "Melon protector",
    "Marmalade ingredient",
    "Orange peel",
    "Coating",
    "Outer layer",
    "Produce protection",
    "Orange throwaway",
    "Orange cover",
    "Coat",
    "Zest",
    "Watermelon part",
    "Peel",
    "Unwanted coat?",
    "Peel",
    "Twist in a drink",
    { "formula": "+Peel", "result": { "error": "#NAME?" } },
    "Orange exterior",
    "Melon throwaway",
    "Watermelon waste",
    "Cheese coating",
    "Marmalade ingredient",
    "The outside",
    "Watermelon's coat",
    "Throwaway part",
    "Part that's thrown away",
    "Watermelon waste"
  ],
  "pots": [
    "Stew containers",
    "Crab traps",
    "Some ironware",
    "Greenhouse containers",
    "Lobster traps",
    "Kitchen and garden vessels",
    "Some kitchenware",
    "Stock to put stock in",
    "They may be cast-iron",
    "Piles of chips, say",
    "Kitties",
    "Kitchenware",
    "Supply nursery",
    "Kitties",
    "What Texas hold'em tables hold",
    "Bets build them",
    "Flower holders",
    "Some ironware",
    "Some crockery",
    "Big hands often take them",
    "Nursery stock",
    "Kitchenware",
    "Stew holders",
    "Kitties",
    "Gardening supplies",
    "Batches of stakes",
    "Partner of pans",
    "Kitties",
    "Florists' needs",
    "Cookware",
    "Big winnings"
  ],
  "keri": [
    "Actress Russell",
    "Actress Russell of \"The Americans\"",
    "Actress Russell of \"Felicity\" and \"The Americans\"",
    "Popular moisturizing lotion",
    "Russell of \"The Americans\"",
    "Actress Russell of \"Felicity\"",
    "Body lotion brand",
    "Russell of \"Felicity\"",
    "\"Felicity\" star Russell",
    "Actress Russell of \"Waitress\"",
    "R&B singer Hilson"
  ],
  "veto": [
    "Ixnay",
    "Refuse to pick up the bill?",
    "Gubernatorial option",
    "Presidential bill-killer",
    "Presidential threat",
    "Kill bill?",
    "Reject",
    "Presidential prerogative",
    "Quash",
    "Nix",
    "\"I forbid,\" to Caesar",
    "Executive turndown",
    "Ixnay",
    "Presidential prerogative",
    "Block from the White House",
    "Presidential rejection",
    "Ixnay",
    "Presidential \"no\"",
    "Block in Washington",
    "Keep from going through",
    "Bill killer",
    "Nix, presidentially",
    "Presidential prerogative",
    "Challenge to Congress",
    "Pork chop?",
    "Nix",
    "Nix by Nixon, e.g.",
    "Nix",
    "President's prerogative",
    "Denial",
    "Gubernatorial right",
    "Literally, \"I forbid\"",
    "Kind of power",
    "Literally, \"I forbid\"",
    "Kill, in a way",
    "Quash",
    "Emphatic no",
    "Nix from Nixon, e.g.",
    "Quash",
    "Congress-thwarting move",
    "Clinton denial",
    "Turn down officially",
    "Kill a bill",
    "Say no to",
    "Presidential no",
    "Overrule"
  ],
  "hawk": [
    "Peddle",
    "Atlanta hoopster",
    "War supporter",
    "Pro-war sort",
    "Neocon, e.g.",
    "Pro-war sort",
    "Peddle",
    "Warmonger",
    "One watching very, very closely",
    "Sharp-eyed sort",
    "Dove's opposite",
    "Sharp-eyed sort",
    "Atlanta cager",
    "Keen observer",
    "*Peddle",
    "Atlanta cager",
    "Peddle",
    "Peddle",
    "Pro-war sort",
    "Peddle",
    "Good watcher",
    "Dove's opposite",
    "Peddle",
    "Peddle"
  ],
  "buoy": [
    "Marina marker",
    "Hearten",
    "Floating marker for a sailor",
    "Lift",
    "Give a lift",
    "Water mark?",
    "Uplift",
    "Hazard warning",
    "Channel marker",
    "Bay bobber",
    "Uplift",
    "Marking float",
    "Underwater hazard marker",
    "Gull's perch"
  ],
  "shoo": [
    "\"Amscray!\"",
    "Drive (away)",
    "\"Git!\"",
    "\"Out, out!\"",
    "\"Get outta here!\"",
    "\"Git!\"",
    "\"Begone!\"",
    "\"Begone!\"",
    "\"Begone!\"",
    "\"Buzz off!\"",
    "Shout to a pest",
    "\"Out!\"",
    "\"Away!\"",
    "Something you can do with flies",
    "\"Away, fly!\"",
    "\"Scram!\"",
    "\"Vamoose!\"",
    "Dismiss unceremoniously",
    "Wave off",
    "\"You're not welcome!\"",
    "\"Git!\"",
    "Chase off",
    "\"Go on, git!\"",
    "It may be said with a brushing motion",
    "\"Git!\"",
    "\"Scat!\"",
    "\"Buzz off!\"",
    "\"Scram!\"",
    "\"Beat it!\"",
    "\"Get out of here, fly!\"",
    "\"Scat!\"",
    "\"Out!\"",
    "\"Amscray!\"",
    "Drive away",
    "\"Get outta here!\"",
    "\"Git!\"",
    "Send off",
    "\"Out!\"",
    "\"Go away!\"",
    "Order to disperse",
    "\"Scram!\"",
    "\"Git out!\"",
    "Drive away",
    "\"Scram!\"",
    "Drive off",
    "\"Buzz off!\"",
    "\"Git!\"",
    "\"Get lost!\"",
    "\"Vamoose!\"",
    "Chase away",
    "\"Git!\"",
    "\"Get lost!\"",
    "Drive away",
    "\"Begone!\"",
    "Drive off",
    "\"Get lost!\"",
    "Frighten away",
    "Chaser, in a way",
    "___-in",
    "\"Beat it!\"",
    "\"Beat it!\"",
    "Spot remover?",
    "\"Begone!\"",
    "It's said with a wave of the hand",
    "\"Begone!\"",
    "\"Beat it!\"",
    "\"Git!\"",
    "\"Amscray!\"",
    "Word to a fly",
    "\"Scat, cat!\"",
    "\"Scram!\"",
    "\"Begone!\"",
    "\"Begone!\"",
    "\"Git!\"",
    "\"Away!\"",
    "Insect repellent?",
    "Drive away",
    "\"Beat it!\"",
    "\"Buzz off!\"",
    "Word to a fly",
    "Wave away",
    "Word to a fly",
    "Drive away",
    "Drive out",
    "\"Go away!\"",
    "\"Out!\"",
    "\"Beat it!\"",
    "\"Get outta here!\"",
    "Drive away",
    "\"Off you go!\"",
    "\"Git!\""
  ],
  "saki": [
    "\"The Open Window\" story writer",
    "H. H. Munro's pseudonym",
    "H. H. Munro pseudonym",
    "Noted pseudonym in short story writing",
    "H. H. Munro's pen name",
    "H. H. Munro's pen name",
    "H. H. Munro pseudonym",
    "H. H. Munro pseudonym",
    "\"The Chronicles of Clovis\" author",
    "Contemporary of O. Henry",
    "\"Esme\" writer",
    "\"The Open Window\" story writer",
    "Author who famously ended a short story with the line \"Romance at short notice was her specialty\"",
    "\"The Open Window\" writer",
    "\"Reginald\" writer",
    "Pseudonym of H. H. Munro",
    "\"The Square Egg\" author",
    "Literary pen name",
    "\"The Story-Teller\" storyteller",
    "\"Tobermory\" writer",
    "Pseudonym of H. H. Munro",
    "\"The Square Egg\" writer",
    "Pseudonym of H. H. Munro",
    "Alias for H. H. Munro",
    "\"The Open Window\" writer",
    "\"The Open Window\" writer",
    "Literary pen name",
    "H. H. Munro's pseudonym",
    "\"Tobermory\" writer",
    "Sushi bar order: Var.",
    "Alias of H. H. Munro",
    "Literary pseudonym",
    "H. H. Munro's pen name",
    "\"Reginald\" author",
    "H. H. Munro, pseudonymously",
    "\"The Open Window\" writer",
    "Noted short-story writer",
    "\"The Chronicles of Clovis\" writer",
    "Pseudonymous short-story writer",
    "H. H. Munro, pseudonymically",
    "\"The Open Window\" storyteller",
    "\"Reginald\" writer",
    "\"Tobermory\" writer",
    "\"The Square Egg\" author"
  ],
  "bees": [
    "Nectar collectors",
    "Humbugs?",
    "Honeybunch?",
    "Contents of an apiary",
    "What apiphobes fear",
    "Makers of fine combs",
    "They might break out in hives",
    "\"Subjects\" of a queen, not a king",
    "Honey bunches?",
    "Colony members",
    "Wax makers",
    "Honey bunch?",
    "Some queens",
    "Mockingbird prey",
    "Apiphobe's bane",
    "Honey makers",
    "Buzzers",
    "Some socials",
    "Domesticated insects",
    "Producers of some storage cells",
    "Theme of this puzzle",
    "Some contests",
    5338,
    "Flower fanciers",
    "Quilters' parties",
    "Spelling contests",
    "Ones with combs",
    "Spelling contests",
    "Wax makers",
    "Buzzers",
    "Some contests",
    "14-Across residents",
    "Epitomes of busyness",
    "Flower fanciers",
    "Competitions",
    "Apiary residents",
    "Wax producers",
    "Waggle dance performers",
    "Hive dwellers",
    "Some parties",
    "Hive dwellers",
    "Hive denizens",
    "Hive denizens",
    "Honey makers",
    "Killer ___",
    "Sex education subject?"
  ],
  "oral": [
    "Like many unofficial agreements",
    "Like some interviews",
    "Kind of cavity",
    "Kind of test it's hard to cheat on",
    "Like a contract that's said to be \"not worth the paper it's written on\"",
    "Unwritten exam",
    "Said aloud",
    "Like some contracts and contraceptives",
    "___ tradition",
    "Like some contraceptives",
    "Like dissertation defenses",
    "Said aloud",
    "Test that's all talk",
    "Not written",
    "___ surgeon",
    "Kind of test whose answers can't be erased",
    "Spoken",
    "Freud's first stage",
    "___ tradition",
    "___ health",
    "Like a thermometer that's put in the mouth",
    "Word-of-mouth",
    "What comes before \"B\"?",
    "Unwritten",
    "Kind of hygiene",
    "Kind of rinse",
    "Of the mouth",
    "Kind of exam that's not written",
    "___ cavity",
    "Kind of exam",
    "Kind of fixation",
    "Like instructions you have to hold in your mind",
    "Taken in tablet form, say",
    "Taken by mouth",
    "Spoken",
    "Kind of argument",
    "Hurdle for a Ph.D. candidate, typically",
    "Kind of history or hygiene",
    "Articulated",
    "Kind of history",
    "___ exam",
    "___ fixation (Freud topic)",
    "Kind of history",
    "Like some accounts",
    "Spoken test",
    "Like some hygiene",
    "Like some arguments",
    "Like some medication",
    "Spoken, not written",
    "Mouthy?",
    "Like classic hospital thermometers",
    "Viva voce",
    "Student's viva voce",
    "Like much tribal history",
    "Spoken",
    "Like some contracts",
    "Kind of contraception",
    "Like a legal deposition",
    "Like illegal charades clues",
    "Kind of cavity",
    "Kind of vaccine",
    "Nerve-racking test, for some",
    "Kind of fixation",
    "___-B",
    "Kind of tradition",
    "Demanding test",
    "Like some traditions",
    "Spoken",
    "Like some contraceptives",
    "Kind of tradition",
    "Said aloud",
    "Grilling test",
    "Like many presentations",
    "___ hygiene",
    "Spoken",
    "Kind of surgery",
    "___ instruction",
    "Like much folklore",
    "Verbalized",
    "Kind of testimony",
    "Like some surgery",
    "Spoken",
    "___ fixation",
    "Like the Sabin polio vaccine",
    "Face-to-face exam",
    "___ contraceptive",
    "___-B",
    "Kind of rinse",
    "Like some contraception",
    "30-Down, sometimes",
    "Said aloud",
    "Kind of defense",
    "Word-of-mouth",
    "Spoken",
    "Like dental surgery",
    "Face-to-face test",
    "___ vaccine",
    "Like some exams",
    "Spoken",
    "Grueling grilling",
    "Like slander, as opposed to libel",
    "Not written",
    "Exam format",
    "Kind of exam",
    "___ contraceptive",
    "Exam given face-to-face",
    "Like some confessions",
    "Transmitted by speech",
    "Not written, as a test",
    "Spoken",
    "Face-to-face exam",
    "Like some hygiene",
    "Read aloud",
    "Like traditional epic poetry",
    "Kind of history",
    "Kind of hygiene",
    "Requirement for some degree candidates",
    "___ surgeon",
    "Like some histories",
    "Voiced",
    "Like most folklore",
    "Spoken",
    "Kind of vaccine",
    "Face-to-face test",
    "Passed on by taletellers",
    "Voiced",
    "Kind of cavity",
    "Exam format",
    "Not written",
    "Said aloud",
    "Spoken",
    "Spoken",
    "Kind of tradition",
    "Student's challenge",
    "Said aloud",
    "Like paperless exams",
    "Spoken",
    "Spoken",
    "Face-to-face exam",
    "Test that's hard to cheat on",
    "Spoken",
    "Uttered",
    "Not written",
    "Said",
    "Kind of surgeon",
    "Thesis defense, often",
    "Kind of exam",
    "Kind of history",
    "Pronounced",
    "Spoken",
    "Kind of hygiene",
    "Face-to-face exam",
    "Tough test",
    "Kind of hygiene",
    "Word-of-mouth",
    "Something to explain your way through",
    "Test type",
    "Kind of history",
    "Spoken",
    "Much-dreaded exam",
    "Administered with a spoon, say",
    "Like slander",
    "Kind of history",
    "Exam sans pencils",
    "Uttered",
    "Viva-voce",
    "Spoken",
    "Like some medicines",
    "Not on paper",
    "*Exam ... dentist ... Roberts",
    "Spoken",
    "Kind of agreement",
    "Word-of-mouth",
    "Kind of argument",
    "Spoken",
    "Dental school exam?",
    "Unwritten",
    "Kind of tradition",
    "Spoon-administered",
    "Spoken",
    "___-B (Gillette division)",
    "Kind of tradition",
    "Like some surgery",
    "Like some traditions",
    "Voiced",
    "Viva-voce",
    "Kind of exam",
    "Like some thermometers",
    "Spoken",
    "Spoken",
    "Kind of arguments",
    "Kind of hygiene",
    "Kind of account",
    "Like some testimony",
    "Like the Sabin vaccine",
    "Like a folk tale",
    "Like some surgery",
    "Kind of exam",
    "Like some vaccines",
    "Kind of history",
    "Kind of surgery",
    "Kind of testimony",
    "Rev. Roberts",
    "Spoken",
    "Kind of tradition",
    "Kind of history",
    "Kind of testimony",
    "Kind of thermometer",
    "Spoken",
    "Face-to-face exam",
    "Like some traditions",
    "Kind of surgeon",
    "Voiced",
    "Examination format",
    "Face-to-face exam",
    "Not written",
    "Like some antibiotics",
    "Like some testimony",
    "Grad student's grilling",
    "Master's ordeal",
    "Mouth-to-mouth",
    "Kind of exam or history",
    "Like some votes",
    "Nuncupative",
    "Mouthy?",
    "Spoken",
    "Viva-voce",
    "Big exam",
    "Like some vaccines",
    "Like some votes",
    "Rev. Roberts",
    "Like much testimony",
    "Kind of testimony",
    "Spoken",
    "Like some history",
    "Kind of thermometer",
    "Kind of surgery",
    "Kind of history",
    "Grueling exam",
    "Kind of hygiene",
    "Face-to-face test",
    "Word-of-mouth",
    "Kind of hygiene",
    "Mouth-to-mouth",
    "Spoken",
    "Like some medicines",
    "Intense exam",
    "Unwritten",
    "Spoken",
    "Kind of history",
    "Like most testimony",
    "Kind of history",
    "Spoken",
    "Unwritten",
    "Intense exam",
    "Start for hygiene or Roberts",
    "Type of exam",
    "Not written",
    "Exam type",
    "Kind of surgery",
    "Kind of hygiene",
    "Kind of testimony",
    "Freudian stage",
    "Vaccine type",
    "Kind of history",
    "Face-to-face exam",
    "Spoken",
    "Not injected",
    "Voiced",
    "___ surgery",
    "Ph.D. exam",
    "Kind of tradition",
    "Not written",
    "Word-of-mouth",
    "Pronounced",
    "Kind of vaccine",
    "Face-to-face exam",
    "Like gentlemen's agreements",
    "Rigorous test",
    "Reverend Roberts",
    "Nuncupative",
    "Feared test",
    "Like some medicines",
    "Sounded",
    "Word-of-mouth"
  ],
  "save": [
    "It might be represented by a floppy disk icon",
    "Action under a File menu",
    "Take into account?",
    "Put money in the bank",
    "Hold on to",
    "Success for a closer in baseball",
    "Back up on disk",
    "Excepting",
    "Hang on to",
    "Goalie's goal",
    "Relief pitcher's success",
    "Command under \"File\"",
    "\"___ the Whales\" (bumper sticker)",
    "Except for",
    "Computer command",
    "With the exception of",
    "*OS",
    "Credit to a reliever",
    "Except",
    "Stat for a pitcher",
    "Pitching stat",
    "Pitcher's datum",
    "Goalkeeper's glory",
    "Build up a nest egg",
    "Bank",
    "Except for",
    "Closer's triumph",
    "Put money in the bank",
    "Computer command",
    "Stuff the piggy bank",
    "Reliever's triumph",
    "Word processing command",
    "Put money in the bank",
    "Goalie's feat",
    "Set aside",
    "Put away",
    "Not spend",
    "Put away for a rainy day",
    "Word processor command",
    "Except for",
    "Reliever's triumph",
    "Put away",
    "Commercial catchword",
    "Except for",
    "Prepare for a rainy day",
    "Goalie's feat",
    "Bank",
    "Computer command",
    "Goalie's feat",
    "Goalie's action",
    "Relief pitcher's goal",
    "Goalie's goal",
    "Put money in the bank",
    "Word processor command",
    "Reliever's goal",
    "Copy to a floppy",
    "What pack rats do",
    "Soccer announcer's cry",
    "Set aside",
    "Barring",
    "Reliever's triumph",
    "Prepare for a rainy day",
    "Copy to a floppy",
    "Computer command",
    "Build up interest?",
    "Stat for a goalie",
    "PC command",
    "Conserve",
    "Reliever's goal",
    "Reliever's quest",
    "Cut costs",
    "Except",
    "Buy U.S. bonds",
    "Pitcher's achievement",
    "Goal preventer",
    "Computer command",
    "Banker's byword",
    "Pack rat's motto",
    "But",
    "Lay by",
    "Relief pitcher's feat"
  ],
  "hewn": [
    "Cut down",
    "Axed",
    "Chopped down",
    "Chopped down",
    "Cut",
    "Chopped",
    "Cut",
    "Chopped",
    "Cut",
    "Rough-___ (not smoothly finished)",
    "Chopped",
    "Rough-___ (unfinished)",
    "Chopped",
    "Given the ax?",
    "No longer standing, as a tree",
    "Chopped down",
    "Carved",
    "Cut",
    "Cut, as wood",
    "Cut",
    "Axed",
    "Chopped down",
    "Rough-___",
    "Carved",
    "Carved out",
    "Axed"
  ],
  "upcs": [
    "Lines at the cash register, for short?",
    "Supermarket IDs",
    "Scanned IDs",
    "Supermarket IDs",
    "Scannable symbols on store items, for short",
    "Market IDs",
    "Short lines at the checkout?",
    "Modern proofs-of-purchase, for short",
    "Food pkg. markings",
    "Proofs of purchase, often: Abbr.",
    "Short lines at a store: Abbr."
  ],
  "hdtv": [
    "Vizio or Panasonic product",
    "Set in a man cave",
    "Good resolution provider",
    "Modern viewing for couch potatoes",
    "Provider of a good picture",
    "Product from Panasonic",
    "Modern entertainment device",
    "LG product",
    "Best Buy buy",
    "It'll give you a clear picture",
    "Sony offering",
    "Modern home entertainment option",
    "It has a good resolution",
    "Modern set in the family room",
    "RCA or Samsung product",
    "Sharp product from Sharp",
    "Cutting-edge set",
    "Digital signal receiver",
    "Modern show shower",
    "Part of a home entertainment system",
    "Modern wall hanging",
    "Modern viewing option, for short",
    "Broadcasting option",
    "Samsung or RCA product",
    "Plasma medium?",
    "Modern viewer's option, briefly",
    "Modern viewer's choice",
    "Sharp image producer, briefly"
  ],
  "plow": [
    "Snow clearer",
    "Prepare a field for seeding",
    "Get off the street, in a way",
    "Post-blizzard need",
    "Ground breaker",
    "Get ready for planting",
    "Close follower of a team?",
    "Winter truck attachment",
    "Go forcefully (through)",
    "John Deere creation",
    "Apparatus pulled by oxen",
    "Caterpillar product",
    "Post-blizzard vehicle",
    "Work determinedly (through)",
    "Artery-cleaning aid",
    "Snow clearer",
    "It's driven through something driven",
    "Winter truck attachment",
    "Blizzard battler",
    "It turns up in a field",
    "Pickup attachment",
    "Break ground, in a way",
    "Caterpillar product",
    "Winter truck attachment",
    "Team's burden",
    "Team's burden",
    "Bulldoze",
    "Slog (through)",
    "Winter hookup",
    "Furrow former",
    "Furrow former",
    "It turns up on the farm",
    "Clear the roads, in a way",
    "David Mamet's \"Speed-the-___\"",
    "Make rows",
    "Winter truck accessory",
    "A tractor pulls it",
    "Farm need",
    "Truck attachment",
    "Clear (out)"
  ],
  "taro": [
    "___ chips (Hawaiian snack)",
    "Pacific crop",
    "___ cake (dim sum dish)",
    "Certain paddy crop",
    "Poi ingredient",
    "Root used in making poi",
    "Root in Polynesian cuisine",
    "Pacific root vegetable",
    "Starchy tuber",
    "Root in Polynesian cuisine",
    "Crop grown in paddies",
    "Bubble tea flavor",
    "Its leaves are used for the Hawaiian dish laulau",
    "Tahitian crop",
    "Root of Polynesia",
    "Luau tuber",
    "Root vegetable sometimes made into chips",
    "What poi is made from",
    "Powdered ingredient in sweet teas and smoothies",
    "___ chips (trendy snack food)",
    "Food from a root",
    "Tropical tuber",
    "Poi source",
    "Ingredient in 22-Across",
    "Poi base",
    "Elephant's-ear",
    "Poi source",
    "Pacific dietary staple",
    "___ cake (Chinese New Year delicacy)",
    "___ cake (dim sum staple)",
    "Plant used in making poi",
    "Its edible root is called an eddo",
    "Root used to make poi",
    "Polynesian dietary staple",
    "Poi plant",
    "Starch source",
    "Arum family member",
    "Tropical food that is poisonous if eaten raw",
    "Poi source",
    "Poi source",
    "Edible root",
    "Tropical vegetable also known as elephant's-ear",
    "Starchy tropical root",
    "Hawaiian staple",
    "Poi source",
    "Poi ingredient",
    "Poi source",
    "Root used for poi",
    "Hawaiian staple",
    "Poi source",
    "Hawaiian root",
    "South Seas staple",
    "Poi source",
    "Relative of a potato",
    "Tropical tuber",
    "South Seas starch source",
    "Poi source",
    "Tropical tuber",
    "Tropical tuber",
    "Member of the arum family",
    "Poi source",
    "Poi base",
    "Tropical tuber",
    "Hawaiian tuber",
    "Plant grown in field ponds",
    "Poi root",
    "Hawaiian tuber",
    "Hawaiian crop",
    "Poi plant",
    "South Seas food staple",
    "Edible root",
    "Hawaiian harvest",
    "It's harvested in Hawaii",
    "Tropical root",
    "Starch source",
    "South Seas staple",
    "Plant with an edible root",
    "Member of the arum family",
    "Plant used in making poi",
    "Starch source",
    "Elephant's-ear",
    "Poi, essentially",
    "Hawaiian tuber",
    "5-Down source",
    "Root in Hawaiian cookery",
    "Poi ingredient",
    "Tropical root",
    "Tropical food staple",
    "Hawaiian tuber",
    "Poi source",
    "Poi base",
    "Tropical root",
    "Tropical tuber",
    "Product of the coco yam",
    "Edible rootstock",
    "Tropical food plant",
    "Tropical starch",
    "Poi ingredient",
    "Tahitian dish"
  ],
  "harp": [
    "Angel's instrument",
    "Instrument with a soundbox",
    "Relative of a zither",
    "Belabor, with \"on\"",
    "Heavenly instrument",
    "Image on an Irish euro",
    "Relative of a zither",
    "Heavenly sound?",
    "Instrument for an angel",
    "First instrument heard in the Beatles' \"She's Leaving Home\"",
    "Symbol on an Irish euro coin",
    "Celestial strings",
    "Plucked instrument",
    "Instrument on Ireland's coat of arms",
    "Instrument capable of glissandi",
    "Icon on Guinness bottles",
    "Belabor, with \"on\"",
    "Angel's instrument",
    "Symbol of Ireland",
    "Dwell",
    "Many-stringed instrument",
    "Angelic music maker",
    "Heavenly strings",
    "Prop for a Marx brother",
    "Marx brother's instrument",
    "Heavenly sound",
    "Heavenly sight",
    "Plucked item",
    "King David's instrument",
    "Angel's instrument",
    "Kind of seal",
    "47-stringed instrument",
    "Instrument held between the knees",
    "Irish national symbol",
    "It's music to St. Peter's ears",
    "Beethoven's \"___ Quartet\"",
    "Dwell (on)",
    "Angel's instrument",
    "Beethoven's \"___ Quartet\"",
    "Complain",
    "David's instrument"
  ],
  "book": [
    "Library item",
    "Enter into a calendar",
    "Something bound to sell?",
    "Reserve",
    "Musical component",
    "Reserve",
    "Stamp purchase",
    "Portable diversion",
    "It can be read on a 10-Down",
    "The Bible, e.g.",
    "See 82-Across",
    "Musical credit",
    "Library item",
    "TV-Turnoff Week option",
    "Opera libretto",
    "Make reservations",
    "Engage, as an entertainer",
    "Library unit",
    "Enter criminal charges against"
  ],
  "gory": [
    "Bloody",
    "Like some details",
    "Like a lot of zombie movies",
    "Rating an R rating, say",
    "Gruesome, say",
    "Like some details",
    "Bloody",
    "Like the \"Saw\" movies",
    "Graphically violent",
    "Like some details",
    "Warranting an R, perhaps",
    "Highly graphic",
    "Bloody",
    "Like Wes Craven movies",
    "___ details",
    "Fit for a King?",
    "Bloody",
    "Not for the faint of heart"
  ],
  "taye": [
    "Actor Diggs",
    "Film performer Diggs",
    "Actor Diggs",
    "Actor Diggs",
    "Actor Diggs",
    "\"Rent\" actor Diggs",
    "Diggs of \"How Stella Got Her Groove Back\"",
    "Diggs of \"Rent\"",
    "\"Private Practice\" actor Diggs",
    "\"Rent\" actor Diggs",
    "Diggs of \"Rent\""
  ],
  "pane": [
    "Window square",
    "Stamp collector's purchase",
    "Glassy square?",
    "Bulk purchase at the post office",
    "Bread, in Bologna",
    "You might see right through it",
    "Porthole part",
    "You can usually see right through it",
    "Part of a French door",
    "Window section",
    "Window part",
    "Window component",
    "Stamp collector's unit",
    "Window unit",
    "One getting framed",
    "Postal sheet",
    "Transparent sheet",
    "Door plate, maybe",
    "Subject of a frame job?",
    "Transparent piece",
    "It might be stained",
    "Glazier's unit",
    "Window part",
    "Window segment",
    "Philatelic collectible",
    "It's suitable for framing",
    "Greenhouse square",
    "It might be surrounded by a sash",
    "Window-shopping purchase?",
    "One of several on a French door",
    "17-Down part",
    "Italian bread",
    "Stamp sheet",
    "Plexiglas piece",
    "Window shopper's selection",
    "It may be framed",
    "Italian bread",
    "Glass piece",
    "Window section",
    "Glazier's sheet",
    "Italian bread",
    "You can see right through it",
    "Window section",
    "Frame filler",
    "Plexiglas unit",
    "Oft-framed piece",
    "French door plate",
    "Plexiglas unit",
    "Glass piece",
    "Sheet of stamps",
    "Window section",
    "Wainscot section",
    "Item in a frame",
    "French door part",
    "Piece of glass",
    "Glazier's unit",
    "Glazier's fitting",
    "Sash contents",
    "Glazier's item",
    "Window shopper's buy",
    "It's transparent",
    "French door part",
    "Philatelist's purchase",
    "See-through item",
    "Glazier's unit",
    "Glazier's item",
    "Philatelist's purchase",
    "Windex target",
    "Glazier's sheet",
    "Prepared with bread crumbs, in cookery",
    "Window section",
    "Philatelist's sheet",
    "Fanlight section",
    "Glass square",
    "Glazier's section"
  ],
  "olaf": [
    "Snowman in \"Frozen\"",
    "Disney character who says \"Some people are worth melting for\"",
    "Singing animated snowman",
    "Count in Lemony Snicket books",
    "\"Frozen\" character",
    "Count in Lemony Snicket books",
    "\"Frozen\" snowman",
    "One of Snoopy's brothers, in \"Peanuts\"",
    "Saint who lent his name to a Minnesota college",
    "\"Frozen\" snowman",
    "\"Frozen\" snowman",
    "Count of children's literature",
    "Patron saint of Norway",
    "N.C.A.A.'s St. ___ Oles",
    "Villainous count in the Lemony Snicket books",
    "Snowman in \"Frozen\"",
    "Count in Lemony Snicket books",
    "Saint for whom a Minnesota college is named",
    "One of two Danish kings",
    "Snowman in 2013's \"Frozen\"",
    "King in an Elgar title",
    "Patron saint of Norway",
    "Count of Lemony Snicket",
    "Snowman in Disney's \"Frozen\"",
    "Scandinavian saint",
    "Minnesota's St. ___ College",
    "Dedicatee of a famous Tallinn church",
    "Norway's patron saint",
    "Count ___, character in the Lemony Snicket books",
    "Norwegian king",
    "Patron saint of Norway",
    "Minnesota's St. ___ College",
    "Count ___ (Lemony Snicket villain)",
    "Patron saint of carvers",
    "Count ___ (2004 Jim Carrey role)",
    "Minnesota's St. ___ College",
    "Norwegian king",
    "Minnesota's St. ___ College",
    "Count ___ of Lemony Snicket books",
    "Minnesota's St. ___ College",
    "Minnesota's St. ___ College",
    "Patron saint of Norway",
    "Norway's patron saint",
    "Minnesota's St. ___ College",
    "Minnesota's St. ___ College",
    "Count ___, villain in Lemony Snicket books",
    "Norwegian monarch",
    "Norwegian saint",
    "Father of Magnus the Good",
    "King ___ II known as \"The Stout\"",
    "Count ___, villain in Lemony Snicket books",
    "Patron saint of Norway",
    "Patron saint of Norway",
    "Nordic saint",
    "Norwegian saint",
    "Norwegian king",
    "Norway's patron saint",
    "N.H.L. goalie Kolzig",
    "Norway's patron saint",
    "Regal Norwegian name",
    "\"King ___\" (Elgar cantata)",
    "One of five Norwegian kings",
    "Minnesota's St. ___ College",
    "Norwegian saint",
    "Name of five Norwegian kings",
    "Minnesota's St. ___ College",
    "King in an Elgar work",
    "Canute expelled him",
    "Elgar's \"King ___\"",
    "Norwegian saint",
    "One of five Norwegian kings",
    "Elgar's \"King ___\"",
    "Minnesota's St. ___ College",
    "Norway's patron saint",
    "Norwegian king",
    "Minnesota's St. ___ College",
    "Patron saint of Norway",
    "Canute's foe",
    "King Harald's predecessor",
    "Norwegian saint",
    "Patron saint of Norway",
    "Name of two Danish kings",
    "Elgar?Æs ?ôKing ___?ö",
    "Patron saint of Norway",
    "One of five Norse kings"
  ],
  "anne": [
    "Hathaway of \"The Devil Wears Prada\"",
    "Actress Hathaway",
    "Only daughter of Elizabeth II",
    "Writing sister of Charlotte and Emily",
    "Bronte who wrote \"Agnes Grey\"",
    "Erin Doherty's role on \"The Crown\"",
    "Diarist Frank",
    "Hathaway of \"Ocean's 8\"",
    "Either of two wives of King Henry VIII",
    "Poet Carson",
    "Girl of Green Gables",
    "Author Rice who created the vampire Lestat",
    "Mother of Hamnet Shakespeare",
    "Moody who wrote \"Coming of Age in Mississippi\"",
    "Princess in \"The Crown\"",
    "Sullivan who taught Helen Keller",
    "Actress Hathaway",
    "Actress Hathaway",
    "Youngest of the Bronte sisters",
    "Hathaway of Hollywood",
    "Green Gables girl",
    "Last of the Stuarts",
    "Last of the Stuarts",
    "Actress Hathaway of \"The Devil Wears Prada\"",
    "Archer of film",
    "Mother of the Virgin Mary",
    "British royal",
    "English princess",
    "Member of the British royal family",
    "Frank with a diary",
    "Rice left on a shelf, maybe",
    "Queen ___ style",
    "One of the Brontes",
    "Sci-fi writer McCaffrey, who was the first woman to win a Hugo for fiction",
    "Name shared by two of Henry VIII's wives",
    "With 108-Across, not talking loudly on a cell, e.g.",
    "Fashion's Klein",
    "Last monarch of the House of Stuart",
    "Last Stuart queen",
    "Mother of the Virgin Mary",
    "Either of two wives of Henry VIII",
    "Grandmother of Jesus",
    "Hathaway of \"The Intern\"",
    "Last monarch of the House of Stuart",
    "Diarist Frank",
    "William and Mary's successor",
    "Actress Hathaway",
    "Sullivan who taught Helen Keller",
    "Virgin Mary's mother",
    "Boleyn, Bronte or Bancroft",
    "Carson who won the 2001 T. S. Eliot Prize for Poetry",
    "Youngest Bronte",
    "Longtime Princess Royal",
    "Aunt of Prince William",
    "Fashion designer Klein",
    "With 25-Down, women's fashion designer",
    "Prince Harry's aunt",
    "Rice who has written about werewolves, mummies and vampires",
    "Colonial heretic Hutchinson",
    "English princess who competed in the 1976 Olympics",
    "One of the Brontes",
    "Rice on a shelf",
    "___ Bradstreet, America's first published poet",
    "Hathaway of \"Becoming Jane\"",
    "Actress Hathaway",
    "Hathaway of \"Les Miserables\"",
    "English princess",
    "Writer Katherine ___ Porter",
    "Oscar winner Hathaway",
    "Actress Bancroft",
    "Meara of comedy",
    "One of two of Henry VIII's six",
    "Daughter of James II",
    "Queen in \"The Three Musketeers\"",
    "Rice on shelves",
    "Last monarch of the House of Stuart",
    "Queen with a \"lace\"",
    "Actress Hathaway",
    "Archer of film",
    "___ of Denmark (James I's queen consort)",
    "Mrs. Charles Lindbergh",
    "Actress Hathaway of \"The Princess Diaries\"",
    "One of two wives of Henry VIII",
    "Frank writing in a diary",
    "Writer Rice",
    "Meara of comedy",
    "One of the Brontes",
    "Author McCaffrey",
    "James I's queen consort",
    "Rice on a shelf",
    "Sister of Charlotte and Emily",
    "Prince Charles's sister",
    "Rice who wrote \"The Vampire Chronicles\"",
    "Mrs. Shakespeare",
    "Sister of Charles",
    "Jesus' maternal grandmother",
    "\"The Diary of ___ Frank\"",
    "Last of the Stuarts",
    "Rice who wrote of vampires",
    "First name of Henry VIII's second",
    "___ Page, woman in \"The Merry Wives of Windsor\"",
    "English princess",
    "Actress Hathaway",
    "Predecessor of George I",
    "Saint ___, mother of the Virgin Mary",
    "One of the Bronte sisters",
    "Author Rice",
    "Monarch immediately after William and Mary",
    "Sister of Prince Charles",
    "Windsor princess",
    "___ Elliot, heroine of Jane Austen's \"Persuasion\"",
    "Charles Lindbergh's wife",
    "Mother of Queen Elizabeth I",
    "Queen Elizabeth's daughter",
    "Actress Heche",
    "Aunt of Prince Harry",
    "Tyler or Jackson",
    "\"___ of the Thousand Days\" (1969 film)",
    "Mrs. Shakespeare",
    "___ of Cleves, English queen",
    "Missing from 73-Down",
    "With 36-Across, popular comedic actress",
    "Tyler who wrote \"Breathing Lessons\"",
    "Diarist Frank",
    "Diarist Frank",
    "\"Green Gables\" girl",
    "Rice who wrote \"Vittorio the Vampire\"",
    "Poet Sexton",
    "One of the Bronte sisters",
    "Last Stuart monarch",
    "Novelist McCaffrey",
    "Writer Rice",
    "Princess born 8/15/1950",
    "Writer Rice",
    "Stuart monarch",
    "Beheaded Boleyn",
    "One of two wives of Henry VIII",
    "English princess",
    "Last of the Stuarts",
    "Stuart queen",
    "Actress Heche",
    "Diarist Frank",
    "Last of the House of Stuart",
    "With 40-Down, popular fashion label",
    "Last of the Stuarts",
    "British royal",
    "Green Gables girl",
    "Rice in a bookstore",
    "Henry VIII's second or fourth",
    "Fantasy author McCaffrey",
    "___ Boleyn, queen to Henry VIII",
    "Frank or Francis",
    "Charlotte and Emily's sister",
    "Green Gables girl",
    "British royal",
    "Elizabeth I's mother",
    "One of the Lindberghs",
    "Archer of note",
    "Green Gables girl",
    "Sister of Charles and Andrew",
    "Author Rice",
    "See 25-Across",
    "Game show host Robinson",
    "Elizabeth I's mother",
    "\"The Weakest Link\" host Robinson",
    "Sister of King Arthur",
    "Girl of Green Gables",
    "Author Rice",
    "Henry VIII's second or fourth",
    "Actress Bancroft",
    "\"Green Gables\" girl",
    "Frank or Francis",
    "Daughter of James II",
    "\"___ of Green Gables\"",
    "British royal",
    "Queen ___ style",
    "Either of two wives of Henry VIII",
    "Frank girl",
    "Archer of \"Patriot Games\"",
    "Shakespeare's ___ Hathaway",
    "French actress Parillaud",
    "Actress Revere of \"National Velvet\"",
    "Diarist Frank",
    "Writer Rice",
    "Poet Sexton",
    "Diarist Frank",
    "Actress Archer",
    "Sister of Emily and Charlotte",
    "Designer Klein",
    "One of two of the VIII's six",
    "Edgar Lee Masters's \"___ Rutledge\"",
    "One of the Windsors",
    "Frank or Francis",
    "Beheaded Boleyn",
    "Daughter of James II",
    "Author Tyler",
    "Author Rice",
    "Sister of King Arthur",
    "Diarist Frank",
    "Queen before George I",
    "Queen in Dumas's \"Twenty Years After\"",
    "Writer Lindbergh",
    "Jackson or Tyler",
    "Elizabeth II's only daughter",
    "Actress Jeffreys",
    "___ Morrow Lindbergh",
    "Last Stuart ruler",
    "One of the Brontes",
    "Actress Archer",
    "Shakespeare's Hathaway",
    "British princess",
    "Novelist Tyler",
    "Henry VIII's second wife",
    "William III's successor",
    "Mrs. Shakespeare",
    "Prince Charles's sister",
    "Green Gables girl",
    "Sister of King Arthur",
    "Mrs. Shakespeare",
    "Lady ___ of \"Richard III\"",
    "\"___ of the Thousand Days\"",
    "Green Gables girl",
    "A Bronte",
    "Author Tyler",
    "House of Stuart monarch",
    "Poet Sexton",
    "___ Boleyn",
    "Archer of film",
    "Helen Keller's teacher ___ Sullivan",
    "Montgomery's \"___ of Green Gables\"",
    "Author Rice",
    "Mrs. Lindbergh",
    "Writer Rice",
    "Poet Sexton",
    "Actress Jeffreys",
    "One of the Brontes",
    "Last of the Stuarts",
    "Will's wife",
    "Educator Sullivan",
    "Murray of song",
    "Novelist Tyler",
    "Actress Archer",
    "Poet Bradstreet"
  ],
  "pier": [
    "Where boats tie up",
    "Santa Monica landmark",
    "Waterfront projection",
    "Loading area",
    "Where a fishing boat ties up",
    "Fishing site",
    "Common fishing spot",
    "It's on the waterfront",
    "Waterfront 68-Across location",
    "Berth place",
    "Jetty",
    "Fishing place",
    "Dockside platform",
    "Place to get a bite?",
    "Launch site",
    "Boardwalk adjunct",
    "Building support",
    "Place to drop a line from",
    "Fishing spot",
    "Place to fish from",
    "It's on top of piles",
    "___ pressure (cause of a jetty collapse)",
    "Seafood restaurant locale",
    "Landing place",
    "It's a shore thing",
    "Place for loading and unloading",
    "Where ships dock",
    "Place to load and unload",
    "Debarking point",
    "Loading site",
    "Docking station",
    "Unloading site",
    "Brighton landmark",
    "Yacht spot",
    "Fishing spot",
    "Waterfront walkway",
    "Site of many tie-ups",
    "Fishing locale",
    "Quay",
    "Square pillar",
    "Landing area",
    "Waterfront walk",
    "Waterfront sight",
    "Place for a stroll",
    "Place for seagulls to sit",
    "Embarkation location",
    "Mooring place",
    "Loading site",
    "Liner's landing",
    "Waterfront walk",
    "Sloop slip",
    "Jetty",
    "Docking spot",
    "Docking spot",
    "Place for a shore dinner",
    "Where a ship comes in",
    "Marina sight",
    "Loading site",
    "Where to alight from a lighter",
    "Place on piles",
    "Unloading site",
    "Dock",
    "Place for a fisherman",
    "Dock",
    "Jetty",
    "Ship's landing",
    "Dock",
    "Berth place",
    "Loading site",
    "Berth place",
    "Berth place",
    "Boardwalk abutter",
    "Fishing site",
    "Berth place",
    "Bridge support",
    "Loading/unloading locale",
    "Landing",
    "Loading site",
    "Jetty",
    "Boat's departure site"
  ],
  "cran": [
    "Commercial lead-in to Apple",
    "Prefix for many Ocean Spray products",
    "Start of some juice portmanteaus",
    "Juice name starter",
    "Grape, Cherry or Strawberry lead-in",
    "Lead-in to Apple",
    "Start of some blended juice names",
    "Part of some fruit drink names",
    "Prefix with many fruit names",
    "Prefix in many juice names",
    "Start of some blended juice names",
    "Start of some juice blend names"
  ],
  "meow": [
    "___ Mix, brand for Whiskers",
    "Cat's 'sup?",
    "Ribbit : frog :: ___ : cat",
    "[More tuna, please!]",
    "Ask for Friskies, maybe",
    "Copy cats?",
    "Cry for attention, maybe",
    "\"Got milk?\"",
    "\"Feed me!,\" maybe",
    "Cry around mealtime",
    "\"Look who's being catty!\"",
    "Cat call",
    "Cat's cry",
    "Cry that means \"I want some milk\" (or who knows what?)",
    "It may mean \"Pet me!\"",
    "Cry for attention, maybe",
    "___ Mix",
    "Speak Persian?",
    "Request for milk, maybe",
    "Cry over spilled milk?",
    "Cat call",
    "\"Got milk?\" cry, perhaps",
    "Feline's \"feed me\"",
    "Copy cats?",
    "Feline line",
    "___ Mix",
    "Copy cats",
    "Persian's call",
    "\"What's New Pussycat?\" response?",
    "Cat's plaint",
    "Tabby's cry",
    "Plaint near a bowl of milk",
    "___ Mix",
    "Sound around a saucer of milk",
    "Request for attention, maybe",
    "Speak Persian?",
    "Catty comeback",
    "Cry for attention",
    "Catty call",
    "Cat's cry",
    "Abyssinian greeting",
    "Persian cry",
    "Pussy's cry",
    "Cat sound",
    "Cat call",
    "\"Got milk?\" request",
    "Feline line",
    "Catty comment",
    "Copy cats?",
    "Siamese \"please\"",
    "Cat's hello",
    "Cat's plaint",
    "Siamese-speak",
    "Cat call",
    "\"Got milk?\" request?",
    "Feline line",
    "Catty remark",
    "Plaint at a door",
    "Siamese sound",
    "Plaintive cry",
    "Word in Morris code?",
    "Doorsill cry",
    "Feeding time sound",
    "Word purrfect?",
    "Kitty's comment",
    "Kitty cry",
    "Catty remark"
  ],
  "thru": [
    "Kind of traffic, familiarly",
    "By way of, for short",
    "No ___ traffic",
    "\"Kiss Me ___ the Phone\" (2009 #3 hit)",
    "Drive-___ window",
    "Traffic sign word",
    "No ___ traffic",
    "Drive-___ window",
    "Drive-___",
    "Drive follower",
    "Drive-___",
    "Kind of street",
    "Directional word, for short",
    "Drive-___",
    "Drive-___ window",
    "Drive-___",
    "Kind of street, informally",
    "\"Whatever Gets You ___ the Night\" (Lennon song)",
    "Kind of street",
    "Drive-___",
    "Drive-___",
    "Via, quickly",
    "Kind of street",
    "Drive-___",
    "Kind of street",
    "Word shortening on a traffic sign",
    "Connecting, briefly",
    "Like some traffic",
    "Street sign word",
    "Dolly Parton's \"Travelin' ___\"",
    "By way of, briefly",
    "Like some streets",
    "No ___ traffic",
    "Like some traffic, for short",
    "Like some traffic",
    "No ___ Street",
    "No ___ Traffic",
    "Drive-___",
    "In and out, quickly",
    "Drive-___",
    "Drive-___",
    "Like some traffic",
    "Kind of traffic",
    "Not blocked",
    "Like some streets",
    "Drive-___",
    "Like some traffic",
    "By way of, briefly",
    "See-___",
    "By way of, briefly",
    "By way of, briefly",
    "Like some streets",
    "Not exiting, as traffic",
    "Drive-___",
    "Drive-___",
    "Kind of traffic",
    "\"No ___ traffic\"",
    "Drive-___",
    "Drive-___",
    "Like some traffic",
    "Like some traffic",
    "Like some traffic",
    "Drive-___",
    "Kind of street",
    "Drive-___",
    "Fast-food drive-___",
    "Drive-___ window",
    "By way of"
  ],
  "ptsd": [
    "Possible condition for a war vet, for short",
    "V.A. concern, for short",
    "Affliction for many a vet",
    "V.A. concern",
    "War vet's affliction, for short",
    "Veteran's woe, for short",
    "Condition once called \"shell shock,\" for short",
    "Vet's malady, for short",
    "All-too-common V.A. diagnosis",
    "War vet's affliction, for short",
    "Condition once called \"shell shock,\" for short"
  ],
  "toed": [
    "Word with \"two\" or \"three\" to describe a sloth",
    "Open-___ sandals",
    "Pigeon-___",
    "Word that follows steel, open or pigeon",
    "Pointy-___",
    "Drove diagonally",
    "Steel-___ boots",
    "Drove diagonally",
    "Square-___ (prim)",
    "___ the line (obeyed)",
    "Pointy-___",
    "Pigeon-___",
    "Pigeon-___",
    "___ the line (behaved)",
    "Nailed obliquely",
    "Web-___",
    "Drove obliquely",
    "Pigeon-___",
    "Hammered obliquely",
    "___ the line (obeyed)",
    "Pigeon-___",
    "Steel-___",
    "Two-___ sloth",
    "See 33-Across",
    "Pigeon-___",
    "Word with pigeon or open",
    "Hit obliquely, as a nail",
    "Digital",
    "Nailed obliquely",
    "Dactylic"
  ],
  "piet": [
    "Abstract artist Mondrian",
    "Artist Mondrian",
    "Abstract artist Mondrian",
    "Painter Mondrian",
    "Painter Mondrian",
    "Painter Mondrian",
    "Painter Mondrian",
    "Artist Mondrian",
    "Artist Mondrian",
    "Painter Mondrian",
    "Painter Mondrian",
    "Artist Mondrian",
    "Artist Mondrian",
    "Painter Mondrian",
    "Mathematician Hein",
    "Artist Mondrian",
    "Artist Mondrian",
    "Artist Mondrian"
  ],
  "brit": [
    "One for whom underwear is pants",
    "Bath occupant, say",
    "Adele or Ed Sheeran",
    "Londoner, e.g., informally",
    "Lancastrian or Liverpudlian",
    "Londoner, e.g., informally",
    "One who's \"knackered\" when exhausted, informally",
    "One whose car has a bonnet and tyres",
    "One who's happy about acquiring a few extra pounds, informally",
    "\"Blimey!\" sayer",
    "Subject of Queen Elizabeth, informally",
    "Londoner or Glaswegian, informally",
    "Musical lead-in to pop",
    "\"I say\" sayer",
    "One from London Town, e.g.",
    "Piltdown man, say",
    "One loyal to the Union Jack, informally",
    "Dover soul",
    "\"I say\" sayer",
    "One who says \"loo\" instead of \"john\"",
    "Londoner, e.g.",
    "Programme watcher, say",
    "One who may say \"I say\" a lot",
    "London native, informally",
    "Person under the Union Jack",
    "One who puts U in disfavor?",
    "Telly watcher",
    "Londoner, e.g., for short",
    "One who watches the telly",
    "Londoner or Liverpudlian, e.g.",
    "Londoner, e.g., informally",
    "One who says \"I say, old chap\"",
    "Old chap, say",
    "Elton John, e.g.",
    "\"I say!\" sayer",
    "Liverpudlian, e.g.",
    "One wearing pyjamas?",
    "Subject of Elizabeth",
    "Falklands War participant",
    "Jolly old chap",
    "Falklands war participant",
    "Londoner, e.g.",
    "Yank ally",
    "Londoner",
    "Limey",
    "Londoner, e.g.",
    "Tommy Atkins, e.g.",
    "Fergie, for one",
    "Englishman, in slang"
  ],
  "rhys": [
    "Matthew of \"The Americans\"",
    "Jean who wrote \"Wide Sargasso Sea\"",
    "Jean who wrote \"Wide Sargasso Sea\"",
    "Novelist Jean with the 1966 best seller \"Wide Sargasso Sea\"",
    "Actor Ifans of \"The Amazing Spider-Man\"",
    "Novelist Jean who wrote \"Wide Sargasso Sea\"",
    "John ___-Davies of the \"Lord of the Rings\" trilogy",
    "\"Wide Sargasso Sea\" novelist, 1966",
    "Actor Jonathan ___ Meyers",
    "Jean who wrote \"Wide Sargasso Sea\"",
    "\"Wide Sargasso Sea\" author",
    "\"Voyage in the Dark\" novelist",
    "Actor John ___-Davies",
    "\"Wide Sargasso Sea\" author"
  ],
  "hers": [
    "Like Queen Anne's lace?",
    "\"I'm ___, she's mine\" (repeated lyric in \"Do Wah Diddy Diddy\")",
    "For the lady",
    "One of a pair of towel markings",
    "\"And, touching ___, make blessed my rude hand\": Romeo",
    "Half of some matching sets",
    "That woman's",
    "The wife's, e.g.",
    "That woman's",
    "That lady's",
    "For the lady",
    "Half of some matched sets",
    "Word repeated by Romeo in \"As mine on ___, so ___ is set on mine\"",
    "His and ___",
    "Partner of his",
    "Word on a towel",
    "For the goose, not the gander?",
    "Half of a matched set",
    "Towel designation",
    "One of a pair of towel markings",
    "The lady's",
    "Not his",
    "His and ___",
    "Towel stitching",
    "Certain vanity plate for husband-and-wife cars",
    "Towel stitching",
    "One of a matched set",
    "Towel embroidery",
    "One of a matched set",
    "One of two bath towels",
    "\"___ to Hold\" (1943 film musical)",
    "Towel embroidery",
    "His mate",
    "His and ___",
    "One of a matched pair",
    "His and ___",
    "One of a matched pair, maybe",
    "His's partner",
    "Like one of a certain pair of watches",
    "One of a pair of vanity plates",
    "One of a matching pair",
    "His's partner",
    "Vanity plate in a two-car household",
    "Towel pronoun",
    "Vanity plate in a two-car household",
    "For the wife",
    "Half a matched set",
    "One of a matched set",
    "\"___ to Hold\" (1943 musical romance)",
    "Half a matched set",
    "Towel stitching",
    "Towel stitching",
    "The lady's",
    "For the woman",
    "That lady's",
    "Linen marking",
    "For the lady",
    "Word on a towel",
    "Towel word",
    "Not his",
    "Towel word",
    "Woman's pronoun",
    "Towel tag"
  ],
  "neet": [
    "Onetime hair removal brand",
    "Nair rival, once",
    "Bygone Nair rival",
    "Old competitor of Bikini Bare",
    "Old brand in the shaving aisle",
    "Classic hair removal brand",
    "Onetime Nair alternative",
    "\"Faster than shaving\" brand",
    "Bygone brand in the shaving aisle",
    "Classic depilatory brand",
    "Onetime big name in hair removal",
    "Onetime competitor of Nair",
    "Classic brand of hair remover",
    "Classic hair removal brand",
    "Onetime hair-removal name",
    "Classic brand of hair remover",
    "Bygone name in hair removal",
    "Old brand in the shaving aisle",
    "Brand seen near razors, once",
    "Bygone depilatory",
    "Classic brand of hair remover",
    "Nair competitor",
    "Nair competitor",
    "Depilation brand",
    "Depilatory brand",
    "Waxing alternative",
    "Nair competitor",
    "Brand in the shaving aisle",
    "Choice in hair removal",
    "Nair rival",
    "Hair removal brand",
    "Hair removal brand",
    "Nair rival",
    "Depilatory brand",
    "Brand of depilatory",
    "Depilatory brand",
    "Depilatory brand",
    "Nair rival",
    "Nair competitor"
  ],
  "reid": [
    "Joy of MSNBC",
    "Actress Tara",
    "Frist's successor as Senate majority leader",
    "John ___, secret identity of the Lone Ranger",
    "Former Senate majority leader who was once an amateur boxer",
    "Senate majority leader after Frist",
    "Tara of \"American Pie\"",
    "Tara of the \"Sharknado\" films",
    "Britt ___, real name of the Green Hornet",
    "Former Nevada senator Harry",
    "Senate majority leader before McConnell",
    "Frist's successor as Senate majority leader",
    "Senator Harry of Nevada",
    "Frist's successor as Senate majority leader",
    "\"Criminal Minds\" agent with an I.Q. of 187",
    "Senate majority leader Harry",
    "\"American Pie\" actress Tara",
    "Frist's successor as Senate majority leader",
    "Successor to Frist as Senate majority leader",
    "Frist's successor as Senate majority leader",
    "\"American Pie\" actress Tara",
    "Frist's successor as majority leader",
    "Actor/director Tim",
    "Actor Tim of \"WKRP in Cincinnati\"",
    "Journalist Whitelaw ___",
    "Daschle's successor as Senate minority leader",
    "Tim of \"WKRP\"",
    "Tim of \"Sister, Sister\"",
    "John ___, the Lone Ranger",
    "Newspaper publisher Whitelaw ___",
    "Nevada senator Harry",
    "\"Common sense\" philosopher Thomas",
    "Nevada Sen. Harry",
    "Nevada's Sen. Harry ___",
    "Journalist Whitelaw ___",
    "Nevada Sen. Harry ___",
    "Tim of \"WKRP in Cincinnati\"",
    "Old-time actor Wallace ___",
    "The Lone Ranger's real identity",
    "Tony-winning actress Beryl",
    "Actor Elliott of \"Gentlemen Prefer Blondes\"",
    "John ___ (the Lone Ranger)",
    "Tim of \"WKRP in Cincinnati\""
  ],
  "bieb": [
    "Pop star nickname, with \"the\"",
    "Nickname of a 2010s pop idol, with \"the\"",
    "Nickname of a 2010s pop idol, with \"the\"",
    "\"Boyfriend\" singer, to fans, with \"the\""
  ],
  "thad": [
    "Longtime Ohio State basketball coach Matta",
    "Jazz trumpeter Jones",
    "___ Cochran, Mississippi senator from 1978 to 2018",
    "N.B.A.'s Young, familiarly",
    "Jazz trumpeter Jones",
    "Trumpeter Jones",
    "Jazzman Jones",
    "Mississippi senator Cochran",
    "\"A Child Is Born\" trumpeter Jones",
    "Jazz trumpeter/composer Jones",
    "Mississippi senator Cochran",
    "Jazz musician Jones",
    "Sen. Cochran of Mississippi",
    "Sen. Cochran of Mississippi",
    "Sen. Cochran of Mississippi",
    "Jones of jazz",
    "Sen. Cochran",
    "Senator Cochran",
    "Mississippi Senator Cochran"
  ],
  "bays": [
    "Warehouse loading areas",
    "They have big mouths",
    "Large inlets",
    "Howls",
    "American Pharoah and others",
    "Dark horses",
    "Howls at the moon",
    "Serenades the moon",
    "Parts of planes in which to put 18-Across",
    "Raises a howl",
    "Dark horses",
    "Cries like a wolf",
    "Howls like a dog",
    "Cargo areas",
    "Seattle Slew and others",
    "Seabiscuit and Citation, e.g.",
    "Certain horses",
    "Howls",
    "Ululates",
    "Howls",
    "Reddish-brown horses",
    "Howls"
  ],
  "zack": [
    "___ Morris, lead character on TV's \"Saved by the Bell\"",
    "\"The Suite Life of ___ & Cody\" (bygone teen sitcom)",
    "2009 Cy Young Award winner Greinke",
    "Lead character on \"Saved by the Bell\"",
    "\"An Officer and a Gentleman\" hero",
    "\"An Officer and a Gentleman\" hero"
  ],
  "eval": ["Assessment, for short", "Assessment: Abbr.", "Assessment: Abbr."],
  "prig": [
    "Moralizing sort",
    "Uptight sort",
    "Unlikely swinger",
    "Fussy sort",
    "Mrs. Grundy type",
    "Self-righteous sort",
    "Victorian",
    "Bluenose",
    "Bluenose",
    "Puritan",
    "Fussbudget",
    "Fussy sort",
    "Unlikely Playboy Channel watcher",
    "Bluenose",
    "Hardly a libertine",
    "Stuffed shirt",
    "Bluenose"
  ],
  "lung": [
    "Pulmonologist's study",
    "It's breath-taking",
    "Iron ___",
    "Breather",
    "Inspiring part of the body?",
    "See 52-Down",
    "Breather",
    "Breathing space?",
    "One housed in a chest",
    "Where inhaled air goes",
    "Alveoli site",
    "Air bag?",
    "One getting an inspiration?",
    "Breather",
    "Aqua-___",
    "Where inhaled air goes",
    "Pulmonary organ",
    "It's found in a chest",
    "Chest part",
    "Air bag?",
    "Place for a breath of fresh air?",
    "Air bag?",
    "What \"pneumo-\" means",
    "It's breath-taking",
    "Chest organ",
    "The \"pneumo\" in pneumonia",
    "Item in a chest",
    "Bronchiole locale"
  ],
  "afew": [
    "Not lots",
    "Hardly any",
    "Some",
    "Three or four, say",
    "Some",
    "Some",
    "Some",
    "Not many",
    "Some",
    "Not many",
    "Two or three",
    "Not many",
    "Some",
    "What tipplers may have",
    "Three or four",
    "Not many",
    "Not many",
    "Not many",
    "Not many",
    "Not many",
    "More than one",
    "Several",
    "Some",
    "Not too many",
    "Two or three",
    "Not too many",
    "Not many",
    "Three or four",
    "Some",
    "Rough estimate",
    "Some",
    "Some",
    "Three or four",
    "Not many",
    "Three or four, say",
    "Not many",
    "Not many",
    "Not many",
    "Not many",
    "\"We're looking for ___ good men\"",
    "Quite ___ (many)",
    "\"___ Good Men\"",
    "\"___ Good Men\"",
    "Some"
  ],
  "kiln": [
    "Hot pot spot",
    "Lime might be added to one",
    "Place for a firing",
    "Hot spot for a pot",
    "Drying oven",
    "Firing locale",
    "Oven",
    "Oven for pottery",
    "Drying oven",
    "Potter's oven",
    "Ceramist's need",
    "Firing locale",
    "Hot pot locale",
    "Potter's oven",
    "Pottery oven",
    "Pottery need",
    "Oven",
    "Potter's oven",
    "Site of numerous firings",
    "Hot pot spot",
    "Brick baker",
    "Ceramist's oven",
    "Where firings occur",
    "Ceramist's need",
    "Hot spot",
    "Furnace",
    "Glazier's oven",
    "Place for a firing",
    "Oven",
    "Place for a firing",
    "Place for a firing",
    "Site of many firings",
    "Pottery oven",
    "Brick oven",
    "Hot spot",
    "Pottery oven",
    "Brick baker",
    "Brickmaker's furnace",
    "Potter's need",
    "Where firings take place on a daily basis",
    "Potter's oven",
    "Potter's furnace",
    "Potter's need"
  ],
  "jaws": [
    "Thriller with a two-note theme",
    "1975 thriller film with three sequels",
    "Thriller set around Amity Island",
    "Movie with famous \"dun dun\" theme music",
    "Highest-grossing film before \"Star Wars\"",
    "Movie with an iconic theme that starts with two alternating notes",
    "1975 summer blockbuster",
    "Best Picture nominee with three sequels",
    "Vise parts",
    "Thriller set on Amity Island",
    "Henchman first seen in \"The Spy Who Loved Me\"",
    "Classic movie shot on Martha's Vineyard",
    "1975 shark thriller",
    "___ of Life",
    "Vise parts",
    "Awesome parts of a Museum of Natural History display",
    "Vise parts",
    "Highest-grossing film before \"Star Wars\"",
    "Head pieces",
    "Jabbers",
    "1975 Spielberg thriller",
    "1975 thriller that took a big bite at the box office",
    "Gum holders",
    "Classic shark movie",
    "Blockbuster movie of 1975",
    "Spielberg blockbuster",
    "1975 Spielberg film",
    "Wrench features",
    "1975 movie thriller with a sequel in 3-D",
    "They may be crushing",
    "Talks",
    "Spielberg thriller",
    "Top-\"grossing\" film of 1975",
    "1975 shark blockbuster",
    "Shark tale",
    "1975 Spielberg hit",
    "Scare film of '75"
  ],
  "putt": [
    "Hardly a long shot",
    "Short stroke",
    "When doubled, miniature golf",
    "Short shot",
    "When repeated, a club game?",
    "What might get the ball rolling",
    "Get the ball rolling?",
    "Last shot, often",
    "Tap in, perhaps",
    "It's by no means a long shot",
    "When repeated, miniature golf",
    "When repeated, an engine sound",
    "Not go for a drive?",
    "Miniature golf shot",
    "Six-footer, maybe",
    "Fifth stroke, often",
    "Any miniature golf shot",
    "Tap-in, e.g.",
    "Something sunk",
    "Strike gently",
    "Short shot",
    "Short stroke",
    "Minor stroke",
    "Last shot?",
    "Any miniature golf shot",
    "When doubled, an engine sound",
    "Green shot",
    "Short shot",
    "Short shot",
    "Roll at a hole"
  ],
  "inon": [
    "Words after walk or cash",
    "Privy to, as a secret",
    "Privy to",
    "Sit ___ (audit)",
    "Close ___ (approach)",
    "Privy to",
    "Aware of",
    "A part of",
    "Knowledgeable of",
    "A part of",
    "Privy to",
    "A party to",
    "A party to",
    "Privy to",
    "Privy to",
    "Close ___ (approach)",
    "Wise to",
    "Aware of",
    "Get ___ the ground floor",
    "Privy to",
    "Well aware of",
    "See 45-Across",
    "Hip to",
    "Zero ___ (near)",
    "Privy to",
    "Hip to",
    "Wise to",
    "Not blind to",
    "Zoom ___",
    "Aware of",
    "Privy to",
    "___ a secret",
    "Having complicity with",
    "Informed of",
    "Muscle ___",
    "___ a secret",
    "Home ___",
    "Home ___",
    "Closing ___ (surrounding)",
    "Hip to",
    "A party to",
    "Sharing",
    "Get ___ the ground floor",
    "Not excluded from",
    "Barge ___",
    "Knowing, as a secret",
    "Get ___ the ground floor",
    "Privy to",
    "Complicit with",
    "Privy to",
    "Listen ___ (hear via eavesdropping)",
    "Privy to",
    "Home ___",
    "___ a secret",
    "Zero ___",
    "Wise to",
    "Privy to",
    "Zero ___",
    "Knowing firsthand",
    "Informed about",
    "Privy to",
    "Home ___",
    "Knowing, as something private",
    "Home ___",
    "Wise to",
    "___ a secret",
    "A part of",
    "Not excluded from",
    "Zero ___",
    "Start ___",
    "Wise to",
    "Knowing, as a secret",
    "Privy to",
    "Knowing, as a secret",
    "Privy to",
    "Get ___ a good thing",
    "Get ___ (share the rewards of)",
    "Get ___ the ground floor",
    "Barge ___",
    "Hip to",
    "Home ___",
    "Get ___ a good thing",
    "Get ___ a good thing",
    "A party to",
    "Home ___ (near)",
    "Privy to",
    "Wise to",
    "Privy to",
    "Look ___",
    "A party to",
    "Look ___ (visit)",
    "___ a good thing",
    "Look ___",
    "Involved with",
    "Privy to",
    "Aware of",
    "Sit ___",
    "Partaking of",
    "___ a secret",
    "___ a good thing",
    "Privy to",
    "Privy to",
    "Get ___ the ground floor",
    "___ a secret",
    "Barge ___",
    "A party to",
    "Get ___ the ground floor",
    "Apprised of",
    "Privy to",
    "Privy to",
    "Let ___ a secret",
    "___ the ground floor",
    "Let ___ a secret",
    "Look ___ (visit briefly)",
    "___ a secret",
    "___ a secret",
    "A party to",
    "Get ___ the ground floor",
    "A party to",
    "Sharing",
    "A party to",
    "A party to",
    "Not excluded from",
    "Get___the ground floor",
    "Apprised of",
    "Let___ (reveal)",
    "Aware of",
    "Privy",
    "Privy to",
    "Aware of",
    "Aware of",
    "Sharing, as a secret",
    "Privy to",
    "A party to, as a secret",
    "Get ___ the ground floor"
  ],
  "aprs": [
    "Loan figs.",
    "Car loan nos.",
    "Loan figs.",
    "Lenders' figs.",
    "Truth-in-lending figs.",
    "Credit card bill nos.",
    "Credit figs.",
    "Mortgage figs.",
    "Loan figs.",
    "Loan figs.",
    "Yearly loan figs.",
    "Auto loan nos.",
    "Lender terms: Abbr.",
    "Loan figs."
  ],
  "bebe": [
    "One in the care of una madre",
    "Spanish newborn",
    "___ Rexha, pop singer with the 2017 #2 hit \"Meant to Be\"",
    "Tony winner Neuwirth",
    "Tony winner Neuwirth",
    "French newborn",
    "Neuwirth of \"Frasier\"",
    "Actress Daniels or Neuwirth",
    "Spanish newborn",
    "Early film star Daniels",
    "Newborn abroad",
    "One who's just arrived in Mexico?",
    "Nixon pal Rebozo",
    "Neuwirth of Broadway",
    "Madre's treasure",
    "Tiny addition to la familia",
    "Tony winner Neuwirth",
    "New member of la familia",
    "Spanish newborn",
    "Neuwirth who won a Tony for \"Chicago\"",
    "Tony winner Neuwirth",
    "Emmy winner Neuwirth",
    "New member of la familia",
    "One of the singing Winans family",
    "Spanish infant",
    "Tony winner Neuwirth",
    "Daniels of early films",
    "Enfant",
    "Neuwirth of \"Chicago\"",
    "Emmy-winning actress Neuwirth",
    "Daniels of the silents",
    "Nogales newborn",
    "Actress Daniels of the silents"
  ],
  "vest": [
    "Common item of fishing apparel",
    "Confer, as power",
    "Third piece of a three-piece",
    "Word with life or flak",
    "Bulletproof garment",
    "Legally confer, as a power",
    "Word after life or bulletproof",
    "Endow (with)",
    "Item under a suit jacket",
    "Protective garment",
    "Sweater ___",
    "Bit of SWAT garb",
    "One of three pieces",
    "Three-piece piece",
    "Part of a fashion ensemble, maybe",
    "Item under a jacket, maybe",
    "One piece of a three-piece suit",
    "Life ___",
    "Third piece of a three-piece suit",
    "Undershirt, in Britain",
    "Flotation device",
    "Police protection",
    "Endow",
    "Endow with authority",
    "Life ___",
    "Grant",
    "Clothing item worn over a shirt",
    "Flak jacket, e.g.",
    "A piece of a three-piece",
    "Third piece",
    "Jacket accompanier",
    "Clothe",
    "It may be under a jacket",
    "Buttoned item",
    "___-pocket (tiny)",
    "It's kept close to the chest",
    "Sleeveless garment",
    "One of three pieces",
    "Preserver",
    "Life jacket, e.g.",
    "Provide, as with legal authority",
    "V-neck garment",
    "Suit part",
    "Third piece of three"
  ],
  "eton": [
    "One of the schools named in the Public Schools Act of 1868",
    "___ mess, English dessert of berries, meringue and whipped cream",
    "School with a 15th-century chapel",
    "Early educator of George Orwell",
    "007's alma mater",
    "School founded by Henry VI",
    "School founded by Henry VI",
    "\"Chariots of Fire\" filming locale",
    "___ mess (traditional English dessert)",
    "Where Ian Fleming spent much of the 1920s",
    "Prep school near Windsor Castle",
    "School attended by 20 prime ministers",
    "Preparatory school since 1440",
    "Boys' school near Windsor",
    "English school that's a rival of Harrow",
    "Princely school",
    "Alma mater of George Orwell and Henry Fielding",
    "School for James Bond",
    "Radley rival",
    "King's College of Our Lady of ___ Beside Windsor",
    "School founded by Henry VI",
    "School attended by princes William and Harry",
    "Historic town in Berkshire",
    "English school on the Thames",
    "Competitor of Rugby",
    "Word before collar, jacket or College",
    "School founded by King Henry VI",
    "Rival school of Winchester",
    "Boys' school near Windsor",
    "Rival school of Harrow and Radley",
    "Kind of collar",
    "Cricket rival of Harrow",
    "School that lent its name to a collar",
    "Kind of collar",
    "School for young royals",
    "School founded by Henry VI",
    "George Orwell's alma mater",
    "Very old school",
    "School in Berkshire",
    "British prep school",
    "Harrow and Radley rival",
    "Neighbor of Windsor Castle",
    "Kind of blue that's close to green",
    "\"A Yank at ___\" (Mickey Rooney film)",
    "School for James Bond",
    "Captain Hook attended it",
    "Prep school for some English princes",
    "School on the Thames",
    "Regatta foe of Radley",
    "Prestigious British boys' school",
    "School attended by Lord Grantham on \"Downton Abbey\"",
    "___ jacket",
    "View from Windsor Castle",
    "School for Prince Harry",
    "School for James Bond",
    "Alma mater for David Cameron",
    "School for an English prince",
    "English school",
    "Kind of jacket",
    "Feeder school for Oxford and Cambridge",
    "School for James Bond",
    "___ blue (color named for a school)",
    "English institution since 1440",
    "William and Harry attended it",
    "Captain Hook's alma mater",
    "Captain Hook's alma mater",
    "School attended by 007",
    "John Maynard Keynes's alma mater",
    "Aldous Huxley's school",
    "Prime Minister David Cameron's alma mater",
    "___ Dorney, locale of 2012 Olympic rowing",
    "Ian Fleming's alma mater",
    "Captain Hook's alma mater",
    "Old school",
    "School for English princes",
    "David Cameron's alma mater",
    "George Orwell's alma mater",
    "School attended by King's Scholars",
    "School on the Thames",
    "James Bond's school",
    "School on the Thames",
    "It has boys aged 13 to 18",
    "007's school",
    "School near the Royal Windsor Racecourse",
    "School on the Thames",
    "Historic institution near Slough",
    "George Orwell's alma mater",
    "School attended by James Bond ... and Ian Fleming",
    "School for Prince Harry",
    "School near Windsor Castle",
    "\"A Yank at ___,\" Mickey Rooney flick",
    "School attended by 007",
    "British prep school",
    "Where Aldous Huxley taught George Orwell",
    "Town at one end of the Windsor Bridge",
    "School near Windsor Castle",
    "School for princes William and Harry",
    "English collar",
    "Royal educator",
    "Cornwallis's school",
    "Neighbor of Slough",
    "School attended by princes",
    "English town near Windsor Bridge",
    "Locale of famous playing fields",
    "\"A Yank at ___\" (1942 Mickey Rooney film)",
    "School where Aldous Huxley taught George Orwell",
    "English prince's alma mater",
    "School of William and Harry",
    "Education provider since 1440",
    "\"The chief nurse of England's statesmen\"",
    "James Bond was kicked out of it",
    "Where Huxley taught Orwell",
    "School in Berkshire, England",
    "School named in the Public Schools Act of 1868",
    "Famous boys-only school",
    "___ Society (English debating group)",
    "Prince's school",
    "Princely prep school",
    "School for Prince William",
    "School for King's Scholars",
    "School since 1440",
    "Historic school on the Thames",
    "Prep school that's over 500 years old",
    "School for William and Harry",
    "___ jacket",
    "School since 1440",
    "School with King's Scholars",
    "Neighbor of Slough",
    "School on the Thames",
    "School near Windsor",
    "Harrow rival",
    "Thames town",
    "George Orwell's alma mater",
    "Prince's school",
    "Harrow rival",
    "School for Prince William",
    "Gray ode subject",
    "Royal educator",
    "School attended by Ian Fleming",
    "School on the Thames",
    "Harrow rival",
    "Thames academy",
    "Berkshire school",
    "Harrow rival",
    "English prep school",
    "\"The ___ Boating Song\"",
    "Prince William's school",
    "School on the Thames",
    "School for princes William and Harry",
    "Town on the Thames",
    "___ collar",
    "Educator of 18 British P.M.'s",
    "Town on the Thames",
    "Prince William's school",
    "Berkshire town",
    "English prep school",
    "School founded in 1440",
    "Berkshire school",
    "A footbridge from Windsor leads to it",
    "Berkshire school",
    "George Orwell's alma mater",
    "Kind of collar",
    "Thames school",
    "It was founded in 1440",
    "School near Slough",
    "Rugby competitor",
    "School with historic playing fields",
    "School near Windsor",
    "Harrow's rival",
    "School started by Henry VI",
    "007's school",
    "Its playing fields are famous",
    "Town connected by bridge to Windsor",
    "Harrow rival",
    "English school since 1440",
    "Town on the Thames",
    "Harrow rival",
    "School on the Thames",
    "Kind of jacket",
    "School for British princes",
    "Royal educator",
    "School with historic playing fields",
    "School on the Thames",
    "Prince's school",
    "Orwell's alma mater",
    "Common background for British P.M.'s",
    "007's alma mater",
    "Kind of collar",
    "Shelley's alma mater",
    "Open-fronted jacket",
    "Noted town in Buckinghamshire",
    "Harrow's rival",
    "Shelley's alma mater",
    "Royal's school",
    "Kind of collar",
    "College founded by a king",
    "Collar type",
    "Prince William's school",
    "It's across the Thames from Windsor",
    "Harrow's rival",
    "Venerable English institution",
    "Prince William's school",
    "English prince's school",
    "Buckinghamshire town",
    "Harrow's rival",
    "Kind of jacket",
    "Thomas Gray's alma mater",
    "George Orwell's alma mater",
    "Prince William's school",
    "School founded in 1440",
    "British prep school",
    "___ collar",
    "Shade of blue",
    "College on the Thames",
    "Harrow rival",
    "Thames town",
    "Harrow's rival",
    "Uniform collar",
    "007's alma mater",
    "George Orwell's alma mater",
    "Collar type",
    "School founded by Henry VI",
    "Thames school",
    "Kind of collar",
    "Beau Brummell's school",
    "English school",
    "Henry VI founded it",
    "007's school",
    "007's alma mater",
    "School founded in 1440",
    "Subject of a Thomas Gray ode",
    "Short jacket"
  ],
  "boot": [
    "British trunk",
    "Word after combat or cowboy",
    "A rancher might pull one over a calf",
    "Kick out",
    "Italy's shape",
    "Kick (out)",
    "Italy's shape",
    "Start up, in a way",
    "Result of a parking violation ... as illustrated four times in this puzzle?",
    "Throw out unceremoniously",
    "Eject",
    "Italy's shape",
    "Unseat",
    "Wellington, e.g.",
    "Part of firefighter attire",
    "Italy's outline",
    "Pink-slip",
    "Italy's shape",
    "Bounce",
    "Start up",
    "Fire",
    "Evict",
    "Overshoe",
    "Flub, as a grounder",
    "Start, as a computer"
  ],
  "pees": [
    "Supply center?",
    "Repeated things in \"Peter Piper picked a peck of pickled peppers\"",
    "Sound recording copyright symbols",
    "Shopping center?",
    "What \"Peter Piper picked a peck of pickled peppers\" has a lot of",
    "Insignia on Pirates uniforms",
    "Pair of nappies?",
    "Supply center?",
    "Preceders of cues, alphabetically",
    "Silent parts of 20-, 36- and 49-Across"
  ],
  "conj": [
    "If, and or but: Abbr.",
    "Or or nor: Abbr.",
    "Or or nor: Abbr.",
    "\"And\" or \"or\": Abbr.",
    "If, and or but: Abbr.",
    "And, e.g.: Abbr.",
    "Dictionary abbr.",
    "And, e.g.: Abbr."
  ],
  "tyra": [
    "TV host Banks",
    "Actress Ferrell of \"White Men Can't Jump\"",
    "Model and TV host Banks",
    "Banks of \"America's Next Top Model\"",
    "Host Banks of \"America's Next Top Model\"",
    "Model Banks",
    "Supermodel Banks",
    "Banks on a runway",
    "Banks of \"America's Got Talent\"",
    "Banks of \"America's Next Top Model\"",
    "Host Banks of TV's \"America's Next Top Model\"",
    "Banks with a lot of money",
    "Model Banks",
    "TV host Banks",
    "Banks with many spreads",
    "Banks raking in the money?",
    "Banks on a runway",
    "Banks of daytime TV",
    "Banks on a Sports Illustrated cover",
    "Banks on TV",
    "Banks of TV talk",
    "Banks on the runway",
    "Model Banks",
    "Banks on a runway",
    "Banks of note",
    "Banks on a runway",
    "Banks on a runway",
    "First name in modeling",
    "Model Banks",
    "Cover girl Banks",
    "Model Banks",
    "Banks on the runway",
    "Model Banks"
  ],
  "doom": [
    "Bad fortune",
    "Modern lead-in to scrolling",
    "Make toast?",
    "Gloom's partner",
    "Death or destruction",
    "Ruinous end",
    "Inevitable ruin",
    "Dr. ___ (archenemy of the Fantastic Four)",
    "Temple of ___",
    "\"Indiana Jones and the Temple of ___\"",
    "Make a goner",
    "Tragic end",
    "Gloom's partner",
    "Mount ___ (volcano in Mordor)",
    "Alarmist's topic",
    "Awful end",
    "Bad end",
    "Something often thought of as impending",
    "Destiny",
    "Inevitable destruction",
    "Armageddon",
    "Banish to Hades",
    "Dire prophecy",
    "Banish to Hades",
    "Fate",
    "Dire fate",
    "Ensure the failure of",
    "Ruination",
    "Leading shoot-'em-up computer game",
    "Apocalypse",
    "Fearful future",
    "Dreadful end",
    "Bad end",
    "Bad end",
    "Condemn",
    "Destine to disaster",
    "Fate",
    "Popular PC shooting game",
    "Sentence",
    "Awful end",
    "Condemn",
    "Destine for trouble",
    "Apocalypse"
  ],
  "tosh": [
    "Brit's \"Nonsense!\"",
    "Poppycock",
    "Reggae's Peter",
    "Comedian Daniel",
    "Comedy Central host Daniel",
    "Baloney, in Bristol",
    "Nonsense",
    "Peter of reggae",
    "Bilgewater",
    "Comedy Central's \"___.0\"",
    "Comedy Central's \"___.0\"",
    "Weekly Comedy Central host",
    "Brit's \"Baloney!\"",
    "Musician who was a trailblazing Rastafarian",
    "Nonsense, to a Brit",
    "Peter of reggae",
    "Nonsense",
    "\"Poppycock!\"",
    "Reggae musician Peter",
    "Brit's \"Baloney!\"",
    "Brit's \"Baloney!\"",
    "Reggae pioneer Peter",
    "Reggae singer Peter",
    "Rot",
    "Nonsense",
    "Nonsense, in England",
    "Reggae artist Peter"
  ],
  "crat": [
    "Political suffix",
    "Suffix with klepto-",
    "Political suffix",
    "Suffix with Dixie",
    "Suffix with theo-",
    "Techno- tack-on",
    "Suffix with pluto-",
    "Suffix with techno-",
    "Auto finish?",
    "Bureau add-on?",
    "Political suffix",
    "Governmental suffix",
    "Suffix with pluto-",
    "Suffix with techno-",
    "Political suffix",
    "Suffix with Dixie",
    "Suffix with techno-",
    "Political suffix",
    "Pluto or auto ending",
    "Suffix with demo-",
    "Suffix with techno-",
    "Ending with Dixie",
    "Suffix with Dixie",
    "Pluto or bureau add-on"
  ],
  "barb": [
    "Hurtful remark",
    "Hurtful remark",
    "Good name for someone with a sharp tongue?",
    "Pointed remark",
    "Part of a prison fence",
    "Offensive line",
    "Fishhook feature",
    "Provocative remark",
    "Sharp remark",
    "Zinger",
    "*Part of a fishhook",
    "\"Yo mama\" joke, e.g.",
    "Fishhook part",
    "Cutting put-down",
    "Dig",
    "Sharp put-down",
    "Biting comment",
    "Stinging remark",
    "Zinging remark",
    "Nasty remark",
    "Cutting remark",
    "Zinger",
    "Zinger",
    "Unnice comment",
    "Zinger",
    "Part of a fishhook",
    "Stinging comment",
    "Sticker",
    "Caustic comment",
    "Cutting remark",
    "Arrowhead feature",
    "Cutting remark",
    "Fishhook feature",
    "Fishhook part",
    "Unkind comment",
    "Nasty remark",
    "Cutting remark",
    "Putdown",
    "Fishhook's end",
    "Fishhook feature",
    "Stinging remark",
    "Cutting remark",
    "Fishhook's end",
    "Zinger",
    "Cutting remark",
    "End of a fishhook",
    "Impolite remark",
    "Biting comment",
    "Unkind remark",
    "Fishhook part"
  ],
  "robs": [
    "Overcharges, so to speak",
    "Wins undeservedly over",
    "Appropriates inappropriately?",
    "Steals",
    "Rips off",
    "Holds up",
    "Steals from",
    "Holds up",
    "Burgles",
    "Steals from",
    "Holds up",
    "Sticks up",
    "Swindles",
    "Rips off",
    "Fleeces, perhaps",
    "Plunders",
    "Burglarizes",
    "Sticks up",
    "Sticks up",
    "Holds up",
    "Snatches a purse from, say",
    "Burgles",
    "Sticks up",
    "Steals from",
    "Sticks up",
    "Knocks over, so to speak",
    "Steals from",
    "Holds up",
    "Plunders",
    "Unfairly deprives (of)",
    "Holds up",
    "Rolls, so to speak",
    "Knocks over",
    "Mulcts",
    "Holds up"
  ],
  "onel": [
    "First-year legal student, familiarly",
    "First-year law student",
    "Student in a Contracts class, typically",
    "Like Nash's lama",
    "What the British don't spell \"marvelous\" or \"canceled\" with",
    "Student taking Torts or Property",
    "Intro to Torts student",
    "First-year law student",
    "Hillary Clinton in 1969 or Bill Clinton in 1970",
    "Student taking Contracts, maybe",
    "First-year law student",
    "First-year J.D. student",
    "Person typically taking Torts and Contracts",
    "Student taking Civil Procedure, most likely",
    "Student in Torts or Contracts, most likely",
    "Scott Turow title",
    "Torts course taker, typically",
    "1977 law school memoir",
    "Law school newbie",
    "Main character in \"The Paper Chase,\" e.g.",
    "Like Nash's lama (not llama)",
    "First-year law student",
    "Scott Turow memoir",
    "First-year J.D. student",
    "\"The ___ lama, he's a priest\": Nash",
    "\"The ___ lama, he's a priest\"",
    "First-year Harvard law student",
    "Like Nash's lama",
    "1977 memoir set at Harvard",
    "First-year J.D. student",
    "Scott Turow's first book",
    "First-year J.D. candidate",
    "Scott Turow title",
    "Scott Turow work set at Harvard",
    "Scott Turow memoir",
    "Law school newcomer",
    "Scott Turow's first book",
    "Scott Turow title",
    "Scott Turow book",
    "Scott Turow title",
    "First-year Harvard law student",
    "Scott Turow's first book",
    "Scott Turow work",
    "Like Nash's lama",
    "Scott Turow's first book",
    "Scott Turow book",
    "Like Nash's lama",
    "1977 Scott Turow book",
    "Scott Turow book",
    "Scott Turow title",
    "1977 Turow book",
    "Scott Turow's first book",
    "Like Nash's lama",
    "Scott Turow book",
    "First-year Harvard law student",
    "Like Nash's lama",
    "Like Nash's lama",
    "Scott Turow book",
    "\"The ___ lama, he's...\": Nash",
    "\"The___lama...\"",
    "Scott Turow title",
    "Like Nash's lama",
    "Scott Turow book",
    "Like Nash's lama",
    "Scott Turow book",
    "Like Nash's lama",
    "1977 Scott Turow book",
    "Like Nash's lama",
    "Like Nash's \"lama\""
  ],
  "gets": [
    "Grasps",
    "Picks up",
    "Grasps",
    "Tricks",
    "Comes down with",
    "Acquires",
    "Figures out",
    "Receives",
    "Trips up",
    "Understands",
    "Groks",
    "Acquires",
    "Comprehends",
    "Fathoms",
    "Groks",
    "Savvies",
    "Lands",
    "Groks",
    "Savvies",
    "Is allowed (to)",
    "With 8-Down, takes a lot of shots, say",
    "Understands",
    "Understands",
    "Understands",
    "Comprehends",
    "Comprehends",
    "With 9-Down, is compatible",
    "Comes down with",
    "Realizes",
    "Understands",
    "Understands",
    "Is amused by",
    "Understands",
    "Comes down with",
    "Understands",
    "Follows",
    "Understands",
    "Exacts revenge on",
    "Seizes",
    "Groks",
    "Contracts",
    "Catches on",
    "Appreciates",
    "Comprehends",
    "Comes down with",
    "Secure",
    "Comes by",
    "Understands",
    "\"Smoke ___ in Your Eyes\"",
    "Realizes",
    "Fetches"
  ],
  "itsy": [
    "Teeny",
    "Teeny",
    "Tiny, informally",
    "Teeny-weeny",
    "Small, informally",
    "Minuscule, informally",
    "Minute, informally",
    "Li'l",
    "Lilliputian, to a little 'un",
    "Teeny-tiny",
    "Teeny",
    "Teeny, for short",
    "Teeny",
    "Wee, informally",
    "Really tiny",
    "Tiny, informally",
    "Teeny",
    "Part of a spider's description, in a children's song",
    "Lilliputian, informally",
    "Teeny",
    "Teeny",
    "Tiny, to a tot",
    "Wee, informally",
    "___-bitsy",
    "Minute, informally",
    "Not big at all, informally",
    "Opposite of 55-Down, informally",
    "___-bitsy",
    "Teeny, informally",
    "___-bitsy",
    "Minute, informally",
    "Minute, informally",
    "Start of a spider's description, in song",
    "Wee, informally",
    "\"___ Bitsy Spider\"",
    "Wee, to a wee 'un",
    "___-bitsy",
    "Teeny, for short",
    "___-bitsy",
    "Teeny, slangily",
    "___-bitsy",
    "Tiny, informally",
    "Tiny, informally",
    "Wee",
    "___-bitsy",
    "___-bitsy",
    "___-bitsy",
    "Teeny",
    "Teeny",
    "___-bitsy",
    "Diminutive, for short",
    "Tiny, informally",
    "Tiny start?",
    "Wee, in brief",
    "Tiny, for short",
    "Minuscule",
    "Bitsy beginning",
    "___-bitsy",
    "Small beginning?"
  ],
  "ears": [
    "Prominent elephant features",
    "A katydid's are found on its legs, strangely enough",
    "Wearable Disneyland memento",
    "Pointy features on Vulcans and elves",
    "Part of a Mickey Mouse costume",
    "Distinguishing features of Mr. Spock",
    "Prominent part of a Mickey Mouse costume",
    "Distinctive bunny features",
    "Wax holders",
    "Spots for hammers and anvils",
    "Prominent parts of goblins",
    "Big parts of donkeys",
    "Distinctive features of Mr. Spock",
    "Bears ___ (national monument in Utah)",
    "Reception figures?",
    "\"Antennae\"",
    "Prominent Dumbo features",
    "They may ring after parties",
    "Good listeners",
    "Good listeners",
    "\"Antenna\"",
    "Headphones cover them",
    "Antennae, so to speak",
    "Ones catching some waves",
    "What barbers \"lower\"",
    "Things with rings ... that may be ringing",
    "Antennae, so to speak",
    "Huskers' targets",
    "Corny things?",
    "What friends, Romans and countrymen lent, in Shakespeare",
    "They may ring or have rings",
    "Spots for hammers and anvils",
    "Contents of some farm bushels",
    "They may be boxed",
    "Big rabbit features",
    "Prominent features of Alfred E. Neuman",
    "Prominent parts of a George W. Bush caricature",
    "Corn units",
    "All ___",
    "Canal sites",
    "Hearing things",
    "\"I'm all ___\"",
    "Bloodhounds have big ones",
    "They're near temples",
    "Hammer holders",
    "They pop on planes",
    "Sound system parts?",
    "All ___",
    "Where you can find hammers and anvils",
    "Servings of corn",
    "Roadside stand units",
    "Drum containers",
    "Stirrup sites",
    "Head set",
    "Distinctive features of Mr. Spock",
    "Makeshift pencil holders",
    "Farm stand units",
    "Floppy parts of a dachshund",
    "Sites for studs",
    "Anvil sites",
    "Notable Dumbo features",
    "They pop on planes",
    "Toreador's reward",
    "Corn units",
    "All ___",
    "Body parts that may be bent",
    "Some people can wiggle them",
    "C.B. equipment, to a C.B.?Æer",
    "Prominent donkey features",
    "Canal sites",
    "Word with rabbit or all",
    "Elephant flappers",
    "Listening devices",
    "Word with rabbit or roasting",
    "Pitcher parts",
    "Stethoscope holders",
    "All ___",
    "Canal zones?",
    "Canal zones?",
    "Harp seal's lack",
    "Corn units",
    "Marc Antony request",
    "Listeners",
    "They're often lent",
    "Big rabbit features",
    "They may be ringing",
    "Receiver accessory, slangily",
    "Big dachshund features",
    "Pencil holders, sometimes",
    "Listening devices",
    "Supports for specs",
    "Side flaps",
    "Piercing places",
    "Rabbit ___",
    "Corn units",
    "Obstacles for barbers",
    "Big donkey features",
    "They may be lent",
    "Anvil locations",
    "All ___",
    "Shuckers' units",
    "Rabbit ___",
    "Canal sites",
    "Corn units",
    "Pairs with drums",
    "Snakes lack them",
    "\"Jug handles\"",
    "Where to find anvils",
    "They may be pricked",
    "Surrogate listener",
    "They perform a balancing act",
    "Big elephant features",
    "What otoscopes examine",
    "Prominent rabbit features",
    "Auricles",
    "Headset, to hams",
    "Prominent donkey features",
    "Jug___",
    "Dumbo features",
    "Roasting items",
    "Up to one's ___",
    "Headset, to hams",
    "Prominent features of Alfred E. Neuman",
    "All ___ (attentive)",
    "Flappers?",
    "Roasting items",
    "Gossips are all this"
  ],
  "pass": [
    "\"No bid,\" in bridge",
    "Opening between mountains",
    "\"No thanks\"",
    "Get an A, B, C or D",
    "Comment at a bridge table",
    "\"Family Feud\" option",
    "Hail Mary, for one",
    "\"You can skip me\"",
    "See 45-Down",
    "Ticket",
    "Entry ticket",
    "Make the grade",
    "Hail Mary, for one",
    "Mountain route",
    "Get by",
    "Fail's opposite",
    "Slip by",
    "Free ticket",
    "Not run, maybe",
    "Go beyond",
    "Get any grade above an F",
    "Bomb, e.g.",
    "\"No bid\"",
    "Take advantage of a broken line",
    "Court transfer?",
    "Turn down, with \"on\"",
    "Air play?",
    "Meet the requirements",
    "Say no",
    "Throw",
    "Object of a scout's search",
    "Bridge call",
    "Get by",
    "Free ticket",
    "Hail Mary, e.g.",
    "Not fail",
    "Part of a wagon train's route",
    "Free ticket",
    "Route for a wagon train",
    "[No bid]",
    "Final bid",
    "Frontier scout's discovery",
    "Quarterback's move",
    "Leave behind ... or not leave behind",
    "Bridge option",
    "Go by",
    "Hail Mary, e.g.",
    "Avoid summer school",
    "Get by",
    "Quarterback's option",
    "Mountain spot",
    "Gridiron move",
    "Certain school grade",
    "Bridge declaration",
    "Site of a mountain route",
    "Quarterback's tactic",
    "\"No bid\"",
    "Freebie",
    "Make the grade?",
    "Skip a turn",
    "Mountain path site",
    "Gridiron option",
    "Overtake",
    "Bridge comment",
    "Make the grade?",
    "Quarterback's option",
    "It's often made freshly",
    "Bidder's option",
    "Be approved",
    "Grade school grade",
    "Go by",
    "\"No bid\"",
    "Narrow route",
    "Bridge option",
    "Head-'em-off site",
    "Bridge option",
    "Mountain route",
    "Ticket"
  ],
  "jedi": [
    "User of the Force",
    "Movie knight",
    "Forceful one?",
    "\"Return of the ___\" (Episode VI of \"Star Wars\")",
    "\"The Last ___\"",
    "Knight in a popular film franchise",
    "Obi-Wan Kenobi, for one",
    "Some saber wielders",
    "Lightsaber wielder",
    "Force-ful one?",
    "___ knight",
    "Yoda, e.g.",
    "Force-ful characters?",
    "Revered \"Star Wars\" figure",
    "Warrior monk of sci-fi",
    "User of the Force",
    "Sci-fi knight",
    "Force user",
    "Darth Vader, once",
    "Sci-fi knight",
    "Lightsaber user",
    "Yoda, notably",
    "\"Episode VI\" returnee",
    "Yoda, for one",
    "Lightsaber wielder",
    "Obi-Wan Kenobi, for one",
    "\"Star Wars\" knight",
    "Knight at the movies",
    "Council member in \"Star Wars\"",
    "Knight at the movies",
    "\"Star Wars\" warrior",
    "\"Star Wars\" warrior",
    "Skywalker, e.g.",
    "\"Star Wars\" knight",
    "___ Knight",
    "\"Star Wars\" knight",
    "Luke Skywalker, e.g.",
    "Obi-Wan, for one",
    "Luke Skywalker, e.g.",
    "Obi-Wan, for one",
    "\"Star Wars\" knight",
    "Skywalker, e.g."
  ],
  "cmon": [
    "\"Hurry!\"",
    "\"Let's go already!\"",
    "\"Let's get going!\"",
    "\"What are you waiting for?!\"",
    "\"Seriously ...?!\"",
    "\"You're killin' me!\"",
    "\"Get your act together!\"",
    "\"Seriously?!\"",
    "\"You're pulling my leg!\"",
    "\"Let's go!\"",
    "\"Get serious!\"",
    "\"Oh, quit your joshin'!\"",
    "\"Get outta here!\"",
    "\"Let's go!\"",
    "\"Hurry up!\"",
    "\"You have gotta be kidding me!\"",
    "\"Shake a leg!\"",
    "\"Don't be a spoilsport!\"",
    "\"You've gotta be kidding!\"",
    "\"Let's get goin'!\"",
    "\"Let's go!\"",
    "\"Shake a leg!\"",
    "\"Get the lead out!\"",
    "\"Don't be shy!\"",
    "\"You know you want to!\"",
    "\"Follow me!\"",
    "\"Let's go!\"",
    "\"Let's get crackin'!\"",
    "\"Shake a leg!\"",
    "\"Don't be so shy!\"",
    "\"Get the lead out!\"",
    "\"Follow me!\"",
    "\"Don't gimme that!\"",
    "\"Time's a-wastin'!\"",
    "\"Shake a leg!\"",
    "\"Follow me!\"",
    "\"Follow us!\"",
    "\"Let's roll\"",
    "\"Let's go!\"",
    "\"You gotta be kidding!\"",
    "\"Let's go!\"",
    "\"Follow me!\"",
    "\"Let's go!\""
  ],
  "loco": [
    "Cuckoo",
    "Wacky",
    "Off the wall",
    "Bananas",
    "Bats",
    "Bananas",
    "Spanish nuts",
    "Batty",
    "Unhinged",
    "Nuts",
    "Unscrewed",
    "Plumb crazy",
    "Wacko",
    "Bats",
    "Bananas",
    "Plumb crazy",
    "Off one's rocker",
    "Totally baked",
    "Gonzo",
    "Stark raving mad",
    "Way off",
    "Mad",
    "Nuts",
    "Daffy",
    "Bats",
    "Plumb crazy",
    "Bonkers",
    "Plain crazy",
    "Bats",
    "Bonkers",
    "Bonkers",
    "More than a little off",
    "Bats",
    "Plumb crazy",
    "Bats",
    "Daft",
    "Lead-in to motion",
    "Bananas",
    "Plumb crazy",
    "Plumb crazy",
    "Nuts",
    "Cockeyed",
    "Crackers",
    "Wacky",
    "In ___ parentis (legal doctrine)",
    "Out of one's gourd",
    "Crackers",
    "Plumb crazy",
    "Nuts",
    "Cracked",
    "Tetched in the head",
    "Nutso",
    "Kooky",
    "Unglued",
    "Bananas",
    "Crazy in Cancun",
    "Off one's rocker",
    "Bats",
    "Crackers",
    "Off one's rocker",
    "Nuts or crackers",
    "Wacky",
    "Kooky",
    "Over the edge, so to speak",
    "Buggy",
    "As written, in music",
    "Unhinged",
    "Cockeyed",
    "Plumb crazy",
    "In ___ parentis",
    "Plumb crazy",
    "Batty",
    "In ___ parentis",
    "Bats"
  ],
  "york": [
    "Part of N.Y.U.",
    "City near Leeds with historic walls",
    "House involved in the Wars of the Roses",
    "Pennsylvania county or its seat",
    "Duke of ___, title for Prince Andrew",
    "British city after which the Big Apple is named",
    "Duchess of ___",
    "Pennsylvania city",
    "Pennsylvania city or its county",
    "Part of N.Y.S.E.",
    "City at the confluence of the Ouse and Foss",
    "Fergie's duchy",
    "Duke of ___, Shakespearean character",
    "Part of N.Y.C.",
    "Sergeant in a 1941 film",
    "Sarah, Duchess of ___",
    "Richard III's house",
    "Duchess of ___",
    "Lancaster foe",
    "Actor Dick of \"Bewitched\"",
    "English royal house",
    "Big Apple namesake"
  ],
  "wars": [
    "Subjects of many epics",
    "\"All ___ are civil ___, because all men are brothers\" (quote attributed to Francois Fenelon)",
    "Hawks push them",
    "Private engagements?",
    "Polemologists study them",
    "They take place in theaters",
    "General interests?",
    "Events of 1914-18 and 1939-45",
    "Does battle",
    "\"Star ___,\" biggest movie of 1977",
    "Some are declared",
    "They may be revolutionary",
    "Punic ___",
    "Woden's domain",
    "Does battle",
    "See 15-Across",
    "The \"W\" in V.F.W.",
    "\"Star ___\"",
    "The \"W\" in V.F.W.",
    "\"Ring out the thousand ___ of old\": Tennyson",
    "See 31-Across",
    "Part of V.F.W.",
    "Treaties end them",
    "Treaty preceders",
    "Occasions when the arms are raised",
    "Shooting matches?",
    "Battles",
    "Treaties end them",
    "Part of V.F.W.",
    "\"Star ___\"",
    "Part of V.F.W.",
    "Jihads",
    "Declared items"
  ],
  "heal": [
    "Get better, as a wound",
    "Recover",
    "Make well",
    "Be on the mend",
    "Mend",
    "Get better",
    "Mend",
    "Get better",
    "Make well",
    "Get better",
    "Close up, say",
    "Get better",
    "Regain hit points, in video games",
    "Be on the mend",
    "Get better",
    "Mend",
    "Get better",
    "Recover from a break, say",
    "Get well",
    "\"Physician, ___ thyself\"",
    "Get better",
    "Get better",
    "Get better",
    "Set right",
    "Make sound",
    "Knit, maybe",
    "Get better, as a cut",
    "Cure",
    "Make well",
    "Make well",
    "Get better",
    "Close up, perhaps",
    "Patch up",
    "Recover",
    "Make better",
    "Knit, in a way",
    "Get better",
    "Patch up",
    "Knit, e.g.",
    "Make sound",
    "Get better",
    "Become better",
    "Recover",
    "Mend",
    "Mend",
    "Get well",
    "Cure",
    "Get better",
    "Time can do it",
    "Make whole",
    "Be doctor to"
  ],
  "sock": [
    "Fashion no-no with a flip-flop",
    "One of a pair that often goes missing",
    "Item sometimes \"lost\" in a clothes dryer",
    "See 94-Across",
    "Wallop",
    "Punch",
    "Wallop",
    "Darn it",
    "What may not come out in the wash?",
    "Wallop",
    "Punch",
    "Nonmatching item, maybe",
    "Punch",
    "Punch",
    "Buffet",
    "Laundromat loss, maybe",
    "Something odd about the wash?",
    "Haymaker",
    "Paste",
    "Wallop",
    "Punch",
    "\"Pow!\"",
    "Plaster",
    "Wind indicator",
    "Slug",
    "Wallop"
  ],
  "pbjs": [
    "Sandwiches that may use Skippy and Smuckers, informally",
    "Many tykes' lunches",
    "Common lunchbox sandwiches, informally"
  ],
  "live": [
    "\"___ from New York, it's ...\"",
    "Happening now",
    "\"And ... we're ___!\"",
    "Not prerecorded",
    "Word in the corner of a TV news broadcast",
    "Caption on breaking news",
    "Happening now, as a telecast",
    "\"___ a little!\"",
    "Performing now",
    "Opposite of \"on tape\"",
    "___-blogging",
    "Word in the corner of some news broadcasts",
    "\"___ a little!\"",
    "Start of a Saturday night catchphrase",
    "Like the Rolling Stones album \"Get Yer Ya-Ya's Out!\"",
    "The \"L\" of \"S.N.L.\"",
    "Unexploded",
    "As it happens",
    "___-blog",
    "Real-time",
    "In concert",
    "\"We're ___!\"",
    "Unexploded",
    "In the flesh",
    "\"___ and Let Die\" (Paul McCartney hit)",
    "Opposite of taped",
    "Not prerecorded",
    "Not recorded",
    "Like some wires",
    "In person",
    "Not recorded",
    "Reside (in)",
    "Not filmed",
    "Part of S.N.L.",
    "Like some bait",
    "Not prerecorded",
    "In person",
    "Not prerecorded",
    "As it happens",
    "Still in play",
    "Like some ammunition",
    "Part of \"S.N.L.\"",
    "In the flesh",
    "Walk the earth",
    "Like most sports telecasts",
    "In person",
    "Not taped",
    "Not prerecorded",
    "Learn's partner",
    "In play",
    "CNN word",
    "Not taped",
    "Have vitality",
    "Full of current",
    "CNN screen word",
    "As we speak",
    "Performing",
    "___ wire",
    "As it occurs",
    "\"___, from New York...\""
  ],
  "gain": [
    "Loss offsetter",
    "Investment goal",
    "Profit",
    "Benefit",
    "Put on",
    "Make headway",
    "Pick up, as yards in football",
    "Profit",
    "Profit",
    "Positive market move",
    "Appreciation",
    "Come by",
    "Profit",
    "Taxable amount for an investor",
    "Put on, as weight",
    "Put on weight",
    "Appreciate",
    "Loss's opposite",
    "Loss's opposite",
    "Bad news for a dieter",
    "Upside",
    "Yards advanced",
    "Dieter's dread",
    "Put on",
    "Come out ahead",
    "Loss's opposite",
    "Acquire",
    "Yardage pickup",
    "Positive yardage",
    "Put on",
    "Spring ahead",
    "Profit",
    "Pick up",
    "Football yardage",
    "Football yardage",
    "Get ahead",
    "Get ahead"
  ],
  "tush": [
    "Derrière",
    "Fanny",
    "Behind",
    "Heinie",
    "Derriere",
    "Keister",
    "Sitting area?",
    "Rear",
    "Bottom",
    "Heinie",
    "Backside",
    "Rear",
    "Caboose",
    "Keister",
    "Sitting spot",
    "Backside",
    "Rear",
    "Rear end",
    "\"Oh, go on!\"",
    "\"Oh, pshaw!\"",
    "1975 ZZ Top hit"
  ],
  "rolo": [
    "Chocolate candy with a caramel center",
    "Chocolate/caramel candy",
    "Hershey caramel-filled candy",
    "Candy wrapped in a tube",
    "Popular caramel candy",
    "Hershey brand",
    "Hershey chocolate",
    "Chocolate/caramel candy",
    "Hershey chocolate-and-caramel candy",
    "Nestle candy brand",
    "Chocolate candy brand",
    "Hershey's caramel candy",
    "Hershey candy",
    "Caramel candy brand",
    "Hershey brand",
    "Caramel-filled candy",
    "Hershey brand",
    "Chewy candy treat",
    "Caramel-filled treat",
    "Chocolate-and-caramel brand",
    "Hershey's candy",
    "Hershey brand",
    "Chewy candy",
    "Popular caramel candy",
    "Caramel candy brand",
    "Popular caramel candy",
    "Caramel candy from Hershey",
    "Hershey brand",
    "Hershey brand",
    "Caramel candy brand",
    "Hershey candy"
  ],
  "orzo": [
    "Rice-shaped pasta",
    "Minestrone ingredient",
    "Ricelike pasta",
    "Grain-shaped pasta",
    "Rice-shaped pasta",
    "Ricelike pasta",
    "Rice-shaped pasta",
    "Pasta also called risoni",
    "Rice-shaped pasta",
    "Ricelike pasta",
    "Pasta common in minestrone",
    "Minestrone soup ingredient",
    "Ricelike pasta",
    "Pasta used in soups and salads",
    "Rice-shaped pasta",
    "Ricelike pasta",
    "Rice-shaped pasta",
    "Pasta option",
    "Little pasta",
    "Rice-shaped pasta",
    "Pasta eaten with a spoon",
    "Ricelike pasta",
    "Minestrone ingredient",
    "Ricelike pasta",
    "Rice alternative",
    "Rice-shaped pasta",
    "Italian soup pasta",
    "Rice-size pasta",
    "Tiny pasta",
    "Ricelike pasta",
    "Tiny bits of pasta",
    "Type of pasta",
    "Literally, \"barley\"",
    "Italian side dish",
    "Literally, \"barley\"",
    "Ricelike pasta",
    "Very small pasta",
    "Pasta eaten with a spoon",
    "Pasta used in soups",
    "Minestrone morsels",
    "Rice-shaped pasta",
    "Ricelike pasta",
    "Ricelike pasta",
    "Rice-shaped pasta",
    "Petite pasta",
    "Italian side dish",
    "Pasta used in soups",
    "Grainy pasta",
    "Kind of 63-Across",
    "Soup pasta",
    "Italian soup ingredient"
  ],
  "bozo": [
    "Clown of renown",
    "Dum-dum",
    "Doofus",
    "Nincompoop",
    "Lamebrain",
    "Classic clown name",
    "Dum-dum",
    "No-brainer?",
    "Classic TV clown",
    "Doofus",
    "Schmo",
    "Numbskull",
    "Buffoon",
    "Doofus",
    "Clown's name",
    "Knucklehead",
    "Lamebrain",
    "Dolt",
    "Fool",
    "Numbskull",
    "Dumb cluck",
    "Chowderhead",
    "Doofus",
    "Schmo",
    "Nincompoop",
    "Schmo",
    "Classic clown",
    "Classic clown",
    "Classic clown",
    "Chucklehead",
    "Nitwit",
    "Old TV clown",
    "Popular clown",
    "Nincompoop",
    "Clown's name",
    "Galoot",
    "Doofus",
    "Old TV clown",
    "Clown",
    "Numbskull",
    "Stupidhead",
    "Classic clown",
    "TV clown",
    "Dummy",
    "Numbskull",
    "Jerk",
    "Goofball",
    "Dumb ox",
    "Dolt"
  ],
  "czar": [
    "Prerevolution Russian leader",
    "V.I.P. of industry",
    "Magnate",
    "Industry magnate",
    "Pre-Communist Russian ruler",
    "Old Russian ruler",
    "Powerful person",
    "Industry kingpin",
    "Old Russian autocrat",
    "Industry authority",
    "Peter or Paul",
    "Ruler until 1917",
    "Powerful politico",
    "Drug ___",
    "Government policy chief",
    "Industry magnate",
    "Russia's Nicholas I or II",
    "Department head",
    "Peter the Great, for one",
    "White House policy chief",
    "Peter or Ivan",
    "Ivan the Terrible, for one",
    "Old Russian autocrat",
    "3-Down issuer",
    "High-level appointee",
    "Peter the Great or Ivan the Terrible",
    "Executive branch V.I.P.",
    "White House policy honcho",
    "Ruler toppled by the Bolsheviks",
    "Powerful person",
    "Political appointee",
    "Title first used by Simeon I of Bulgaria",
    "Bolshevik's foe",
    "Baron",
    "White House policy appointee",
    "White House appointment",
    "Industry honcho",
    "Autocrat",
    "Drug ___",
    "Winter Palace figure",
    "Mogul",
    "Drug ___",
    "Autocrat of old",
    "Father of a grand duke",
    "Mogul",
    "Peter the Great, for one",
    "Powerful person",
    "Energy ___",
    "Head boss",
    "Industry big shot",
    "Old-time ruler",
    "Ivan, for one",
    "Powerful person",
    "Bygone autocrat",
    "Old Russian autocrat",
    "Old Russian autocrat",
    "Drug ___ (government position)",
    "Leader until 1917",
    "Nicholas, e.g.",
    "Pre-Communist Russian ruler",
    "6-Down, e.g.",
    "Old Russian leader",
    "Alexander, e.g.",
    "Grand duke's father",
    "Winter Palace resident",
    "Industry magnate",
    "Ivan the Terrible, e.g.",
    "Winter Palace ruler",
    "Mogul",
    "Grand duke's father",
    "Winter Palace dweller",
    "Winter Palace resident",
    "Drug ___ (Washington pooh-bah)",
    "Drug___",
    "55 Down, e.g.",
    "Old-time dictator",
    "Basil, for one",
    "Nicholas or Peter",
    "Anticrime boss",
    "Pre-1917 honcho",
    "Ivan, e.g.",
    "All-powerful one",
    "Boss",
    "Basil, e.g.",
    "Drug ___",
    "Kaiser kin"
  ],
  "ipod": [
    "Cher holder?",
    "Player that debuted in 2001",
    "Popular holiday gift of 2001",
    "Device outmoded by the smartphone",
    "It can carry a tune",
    "___ Touch",
    "Gadget that once came with a click wheel",
    "Album holder",
    "Mini, for one",
    "Product whose introduction was music to people's ears?",
    "Apple device with earbuds",
    "Device outmoded by smartphones",
    "Device sold with earbuds",
    "Apple debut of 2001",
    "Listening device?",
    "Light music source?",
    "Device with earbuds",
    "Apple's ___ Touch",
    "Device that can be linked to an auto stereo",
    "Device once sold in a U2 Special Edition",
    "Shuffle, e.g.",
    "With 32-Down, Apple release of 2005",
    "Apple music player",
    "\"Mini\" music player",
    "Many a museum audio guide",
    "Music lover's carry-along",
    "Player in a pocket",
    "Item clipped on a waistband, perhaps",
    "___ Touch",
    "Mini, for one",
    "Device with a click wheel",
    "___ Shuffle",
    "Destination for many a music download",
    "Modern record holder?",
    "Portable product for music lovers",
    "Brainchild of 57-Across",
    "Touch, e.g.",
    "Gadget whose name was inspired by \"2001: A Space Odyssey\"",
    "*Touch, e.g.",
    "Touch, for one",
    "Touch, e.g.",
    "It can carry a tune",
    "Apple MP3 player",
    "Item with earbuds",
    "Popular MP3 player",
    "Song holder",
    "Music device with earbuds",
    "Player in a docking station",
    "Mini, e.g.",
    "It's found in many pockets nowadays",
    "Shuffle or 67-Across, e.g.",
    "Holder of many tracks",
    "Product with earbuds",
    "Its first ad touted \"1,000 songs in your pocket\"",
    "Product with earbuds",
    "It can help you carry a tune",
    "Popular MP3 player",
    "Device with a Nano model",
    "Song holder",
    "Device with a scroll wheel",
    "Music box?",
    "Popular player since 2001",
    "Apple product",
    "Apple product"
  ],
  "jive": [
    "\"___ Talkin',\" #1 hit for the Bee Gees",
    "Malarkey",
    "Don't you believe it!",
    "Nonsense talk",
    "Phony talk",
    "Nonsense",
    "Baloney",
    "Jazz jargon",
    "Nonsense",
    "Cat's tongue?",
    "Deceptive talk, in slang",
    "Hipster's jargon",
    "Baloney",
    "Hepcat's talk",
    "Talk of swingers",
    "See 24-Down",
    "Nonsensical talk",
    "Baloney",
    "Baloney",
    "Fast talk",
    "Jazz talk",
    "Nonsense talk",
    "Jazz lingo",
    "Jazzy talk",
    "Baloney",
    "Nonsense",
    "Foolin'",
    "Glib patter",
    "Flimflam",
    "Swing music"
  ],
  "erik": [
    "Composer Satie",
    "The Phantom in \"The Phantom of the Opera\"",
    "Composer Satie",
    "Leading role in \"The Phantom of the Opera\"",
    "___ the Red",
    "The Phantom in \"The Phantom of the Opera\"",
    "Explorer ___ the Red",
    "___ Killmonger, \"Black Panther\" villain",
    "Composer Satie",
    "N.B.A. coach Spoelstra",
    "\"CHiPs\" actor Estrada",
    "Composer Satie",
    "___ the Red",
    "\"The Phantom of the Opera\" lead role",
    "N.B.A. coach Spoelstra",
    "Tenor in \"The Flying Dutchman\"",
    "N.B.A. coach Spoelstra",
    "The Phantom of the Opera",
    "Pitcher Bedard",
    "Composer Satie",
    "The Phantom of the Opera",
    "Christine's lover in \"The Phantom of the Opera\"",
    "\"The Flying Dutchman\" tenor",
    "Senta's suitor in \"The Flying Dutchman\"",
    "Estrada of \"CHiPs\"",
    "Composer Satie",
    "\"___ the Viking,\" 1989 film starring Tim Robbins",
    "Christine's lover in \"The Phantom of the Opera\"",
    "Finnish composer Bergman",
    "Composer Satie",
    "Edgar-winning writer Larson",
    "\"CHiPs\" actor Estrada",
    "Composer Satie",
    "\"Isaac's Storm\" author Larson",
    "Tenor in \"The Flying Dutchman\"",
    "Estrada of \"CHiPs\"",
    "Composer Satie",
    "Composer Satie",
    "Composer Satie",
    "Real name of 29-Down",
    "Best-selling author Larson",
    "Actor Estrada",
    "Actor Estrada",
    "The Phantom of the Opera",
    "Composer Satie",
    "Composer Satie",
    "Composer Satie",
    "Estrada of \"CHiPs\"",
    "Composer Satie",
    "The Phantom of the Opera",
    "Nobelist poet ___ Axel Karlfeldt",
    "The Phantom of the Opera",
    "Lover in \"The Flying Dutchman\"",
    "Composer Satie",
    "Quarterback Kramer",
    "The Phantom of the Opera's name",
    "Actor Estrada",
    "Composer Satie",
    "Estrada of \"CHiPs\"",
    "Estrada of \"CHiPs\"",
    "Composer Satie",
    "The Phantom of the Opera",
    "Composer Satie",
    "Senta's suitor in \"The Flying Dutchman\"",
    "The Phantom of the Opera",
    "Tenor in \"The Flying Dutchman\"",
    "Actor Estrada",
    "Actor Estrada",
    "Actor Estrada",
    "Tenor in \"The Flying Dutchman\"",
    "Estrada of \"CHiPs\"",
    "QB Kramer",
    "\"Der Fliegende Hollander\" tenor",
    "Swedish poet Karlfeldt",
    "Composer Satie",
    "One of the Menendez brothers",
    "Composer Satie",
    "Composer Satie",
    "He played Ponch on \"CHiPs\"",
    "Actor Estrada",
    "Composer Satie",
    "Dancer Bruhn"
  ],
  "itll": [
    "\"___ pass\"",
    "\"___ never work\"",
    "\"___ pass\"",
    "\"___ pass\"",
    "\"___ pass\"",
    "\"___ be over soon\"",
    "Word said before \"do\"",
    "\"___ be fun!\"",
    "\"___ pass\"",
    "\"___ do\"",
    "\"___ never work!\"",
    "\"___ be all right\"",
    "\"___ pass\"",
    "\"___ just take a minute\"",
    "\"___ never work!\"",
    "\"___ have to wait\"",
    "\"___ do\"",
    "\"___ be O.K.\"",
    "\"___ cost you!\"",
    "\"___ do\"",
    "\"___ turn up\"",
    "\"___ be a cold day in ...\"",
    "\"___ be all right\"",
    "\"One day ___ all make sense\"",
    "\"___ be a cold day in hell ...\"",
    "\"___ do\" (\"That's fine\")",
    "\"___ do for now\"",
    "\"___ be O.K.\"",
    "\"___ be a cold day in hell ...\"",
    "\"___ cost you!\"",
    "\"___ never fly!\"",
    "\"___ have to do\"",
    "\"___ cost you!\"",
    "\"___ do you good\"",
    "\"___ never work!\"",
    "\"___ do\"",
    "\"___ cost you!\"",
    "\"___ cost you\"",
    "\"___ do you good\"",
    "\"___ never work!\"",
    "\"___ be a cold day in hell ...\"",
    "\"___ cost you\"",
    "\"___ do\"",
    "\"___ do\"",
    "\"___ be a cold day...\"",
    "\"___ never fly!\"",
    "\"___ be a cold day in hell...\"",
    "\"___ be a cold day...\"",
    "\"___ be all right\"",
    "\"___be a cold day in...\"",
    "\"___ be a cold day in hell...\"",
    "\"___ just take a minute\"",
    "\"___ never fly\"",
    "\"___ do you good!\"",
    "\"___ be a cold day in Hell...\""
  ],
  "drip": [
    "Good fashion sense, in modern slang",
    "Leak proof?",
    "What melting ice cream cones do",
    "Icicles and burning candles both do this",
    "One method of coffee-making",
    "Insipid one",
    "Evidence of a leak",
    "What icicles do",
    "Reason to call a plumber",
    "Small plumbing problem",
    "Problem for a plumber",
    "Trouble with a tap",
    "Cause of some insomnia",
    "What icicles do",
    "Cause of insomnia, maybe",
    "French press alternative",
    "Result of a melting icicle",
    "Coffee brewing style",
    "Faucet problem",
    "Sound that can prevent sleeping at night",
    "Create an icicle, say",
    "Echoing sound in a cave",
    "Reason to call a plumber",
    "Total bore",
    "Burning candle feature",
    "Faucet annoyance",
    "Hardly Mr. Personality",
    "No Mr. Personality",
    "Stalactite producer",
    "Slow leak",
    "Insomnia cause",
    "Sleep-preventing sound, perhaps",
    "Leak sound",
    "What icicles do",
    "Faucet trouble",
    "Slo-o-ow leak",
    "Icicle feature",
    "Tap trouble",
    "Intravenous hookup",
    "IV",
    "With 15-Down, hangs on the line",
    "Coffeemaker style",
    "IV flow",
    "IV ___",
    "What icicles do",
    "What icicles do",
    "Annoyance from a faucet",
    "What icicles do",
    "Hardly Mr. Personality",
    "Insomnia cause",
    "Hardly Mr. Personality",
    "Annoyance for an insomniac",
    "What icicles do",
    "Coffeemaker style",
    "Icicle feature",
    "Plumbing problem",
    "Icicle former",
    "Socially challenged sort",
    "Mr. Unexciting",
    "Cause for a plumber",
    "What icicles do",
    "Tap trouble",
    "Faucet problem",
    "Job for a plumber",
    "Stalactite producer",
    "Faucet problem",
    "Stalactite former",
    "Cause of an icicle",
    "Mr. Unexciting",
    "Faucet annoyance",
    "Sink sound",
    "Result of melting",
    "Boring one",
    "Party pooper",
    "Persistently annoying sound",
    "Plumbing problem",
    "Boring one",
    "Bore",
    "Radiator drainpipe",
    "Nebbish",
    "Nerd",
    "Drying method",
    "Insomnia cause?",
    "Faucet fault",
    "Hardly a Prince Charming"
  ],
  "sent": [
    "Fired off, say",
    "Email folder",
    "On the way",
    "Email notification, maybe",
    "Email outbox folder",
    "Text message status",
    "On the way",
    "Launched",
    "On the way",
    "Shipped",
    "Text message status",
    "Email folder",
    "On its way",
    "Elated",
    "Text message status",
    "Outbox folder",
    "On its way",
    "Mailed",
    "Word of confirmation on a messaging app",
    "Shipped",
    "On its way",
    "Email folder",
    "Mailed",
    "On its way",
    "Posted",
    "No longer a draft, say",
    "Email folder",
    "Transmitted",
    "Mailed",
    "Over the moon",
    "Enraptured",
    "Directed",
    "On its way",
    "Posted",
    "Emailed, say",
    "In the mail",
    "Email folder",
    "Tickled, and how!",
    "In rapture",
    "Mailed",
    "Posted",
    "Transmitted",
    "Cast",
    "In the mail",
    "E-mail folder",
    "E-mail folder heading",
    "On the way",
    "Dispatched",
    "Text message status",
    "Transmitted, as an S O S",
    "Transmitted",
    "Shipped",
    "Put on cloud nine",
    "Directed",
    "Shipped",
    "E-mail folder",
    "Text message status",
    "Already in the mail",
    "E-mail folder",
    "Propelled",
    "With 33-Across, remitted",
    "Text message status",
    "Full of rapture",
    "Let fly",
    "In the mail",
    "Mailed",
    "Posted",
    "Propelled",
    "Radioed, e.g.",
    "Shipped off",
    "Transmitted",
    "In the mail",
    "Elated",
    "Turned on",
    "Made rhapsodic",
    "Posted",
    "On its way",
    "Launched",
    "Thrilled",
    "Thrilled",
    "Mailed",
    "___ mail (e-mail folder)",
    "Faxed, e.g.",
    "Mailed",
    "Posted, say",
    "On its way",
    "E-mail status",
    "Conveyed",
    "Thrilled",
    "In the mail",
    "Cast",
    "Enthralled",
    "Already off",
    "In the mail",
    "Shipped",
    "Really moved",
    "In ecstasy",
    "Dispatched",
    "E-mailed",
    "In the mail",
    "On the way, in a way",
    "In the mail",
    "Faxed",
    "E-mailed",
    "Posted",
    "Mailed",
    "Let fly",
    "Shipped",
    "Off",
    "Mailed",
    "Transmitted",
    "Delighted",
    "Mailed",
    "In ecstasy",
    "Ordered to go",
    "Dispatched",
    "In the mail",
    "Mailed",
    "Mailed",
    "Posted",
    "Enraptured",
    "Faxed",
    "Mailed",
    "On its way",
    "Mailed",
    "E-mailed",
    "Shipped",
    "On its way",
    "Elated",
    "In rapture",
    "E-mailed",
    "Mailed",
    "FedExed",
    "Off",
    "Dispatched",
    "U.P.S.'ed",
    "Mailed",
    "Used E-mail",
    "Telegraphed",
    "Faxed",
    "On its way, as a message",
    "Posted",
    "Elated",
    "Telegraphed",
    "Posted",
    "Parodied, with \"up\"",
    "E-mailed",
    "Faxed",
    "Mailed",
    "Transmitted",
    "E-mailed",
    "Posted",
    "Mailed",
    "Enraptured",
    "Wired",
    "Commissioned",
    "Faxed",
    "Mailed",
    "Posted",
    "Faxed",
    "Wired",
    "Posted",
    "Faxed",
    "Shipped",
    "Faxed",
    "Shipped",
    "Posted",
    "Told to go"
  ],
  "slog": [
    "Grind",
    "Trudge",
    "Long, hard work",
    "Painful proceeding",
    "Trudge",
    "Tough going",
    "Long, boring task",
    "Difficult endeavor",
    "Long, tedious effort",
    "Laborious task",
    "Make slo-o-o-ow progress",
    "Interminable task",
    "Endless chore",
    "Uphill climb, say",
    "Laborious process",
    "Trudge",
    "Tough going",
    "Tough trek",
    "Slow, hard progress",
    "Walk, as through mud",
    "Tramp",
    "Walk wearily",
    "Trudge",
    "Trudge",
    "Struggle (through)",
    "Plod along",
    "Tramp",
    "No walk in the park",
    "Toil",
    "Trudge",
    "Walk with difficulty",
    "Trudge",
    "Move slowly",
    "Move with difficulty",
    "Plod along",
    "Toil wearily",
    "Wade through mud",
    "Wade (through)",
    "Plug away",
    "Trudge through the mire",
    "Struggle (through)",
    "Trudge",
    "Work long and hard",
    "Work hard",
    "Toil wearily",
    "Walk heavily",
    "Toil",
    "Trudge",
    "Work long and hard",
    "Work long and hard",
    "Tramp"
  ],
  "imam": [
    "One giving a khutbah sermon",
    "Mosque leader",
    "Prayer leader",
    "Mosque leader",
    "Certain religious leader",
    "Mosque V.I.P.",
    "One leading the faithful in prayer",
    "Scholar of Islamic law",
    "See 73-Across",
    "Title for Iran's Ruhollah Khomeini",
    "___ Reza shrine (Iranian holy site)",
    "Religious leader usually sporting a beard",
    "Prayer leader",
    "Certain congregation leader",
    "Muslim leader",
    "Muslim leader",
    "Eastern ecclesiastic",
    "Certain prayer leader",
    "Muslim cleric",
    "Mosque leader",
    "Man of Allah",
    "Official in a turban",
    "Mosque leader",
    "Muslim scholar",
    "Muslim leader",
    "Muslim leader",
    "Kuwait V.I.P.",
    "Muslim leader",
    "Mosque leader",
    "Muslim leader",
    "Muslim leader",
    "Religious scholar",
    "Sermon leader",
    "Koran reciter",
    "Sunni sermonizer",
    "Worship leader",
    "Shiite leader",
    "Mosqued man?",
    "Shiite leader",
    "Shi'ite leader",
    "Religious leader in a turban",
    "Mosque V.I.P.",
    "Muslim holy man",
    "Muslim holy man",
    "Iraq's ___ Ali Shrine",
    "Mosque overseer",
    "Muslim leader",
    "Muslim leader",
    "Certain cleric",
    "Holy man",
    "One appointed by God, to believers",
    "Prayer leader",
    "Official required to have a beard",
    "Muslim leader",
    "Mosque V.I.P.",
    "Mosque V.I.P.",
    "Islamic holy man",
    "Prayer leader",
    "Prayer leader",
    "Holy Iraqi",
    "Muslim leader",
    "Prayer leader",
    "Muslim holy man",
    "Mosque head",
    "Muslim leader",
    "Mosque V.I.P.",
    "Mosque V.I.P.",
    "Muslim holy man",
    "Muhammad al-Mahdi, for one",
    "Mosque bigwig",
    "Turbaned priest",
    "Shiite leader",
    "Shi'ite leader",
    "Mosque V.I.P.",
    "Muslim holy man",
    "Mosque V.I.P.",
    "Mosque officiator",
    "Mosque V.I.P.",
    "Mosque V.I.P.",
    "The Ayatollah, for one",
    "Descendant of Fatima, to Shiites",
    "Mideast title",
    "Islamic leader",
    "Mosque priest",
    "Mosque priest",
    "Mosque priest",
    "Muslim leader",
    "Muslim title",
    "Muslim leader",
    "Mideastern V.I.P.",
    "Teheran V.I.P.",
    "Muslim priest"
  ],
  "sotu": ["Annual presidential address, for short"],
  "jesu": [
    "Christ, to Bach",
    "Bach's \"___, Joy of Man's Desiring\"",
    "Figure in some hymns",
    "Bach's \"___, Joy of Man's Desiring\"",
    "Bach's \"___, meine Freude\"",
    "\"___, meine Freude\" (Bach motet)",
    "Pieta figure, literarily",
    "Bach's \"___, Joy of Man's Desiring\"",
    "Name in a hymn",
    "Bach's \"___, Joy of Man's Desiring\"",
    "Bach's \"___, Joy of Man's Desiring\"",
    "Bach's \"joy of man's desiring\"",
    "Proper name in Masses",
    "\"___, Joy of Man's Desiring\"",
    "Savior, to Bach",
    "Name in many a hymn",
    "Proper name in Masses"
  ],
  "lieu": [
    "Place",
    "Stead",
    "In ___ of (replacing)",
    "Place",
    "\"In ___ of flowers ...\"",
    "Place",
    "Word usually found between \"in\" and \"of\"",
    "Place",
    "Stead",
    "In ___ of (replacing)",
    "In ___ of",
    "Place",
    "In ___ of (replacing)",
    "Place",
    "Place",
    "In ___ of",
    "\"In ___ of flowers ...\"",
    "In ___ of (replacing)",
    "Stead",
    "In ___ of",
    "Place",
    "Place",
    "Place",
    "Place",
    "In ___ of",
    "Stead",
    "In ___ of",
    "In ___ of (replacing)",
    "Place",
    "In ___ of (as a replacement for)",
    "Stead",
    "In ___ of",
    "Stead",
    "Place",
    "In ___ of",
    "Place",
    "In ___ of (replacing)",
    "In ___ of",
    "In ___ of (replacing)",
    "Place",
    "Stead",
    "Place",
    "Stead",
    "In ___ of",
    "Place",
    "Place",
    "Stead",
    "Stead",
    "Stead",
    "In ___ of (standing in for)",
    "In ___ of (replacing)",
    "Place",
    "Place",
    "Stead",
    "In ___ of (instead of)",
    "Substitute position",
    "Place",
    "Place",
    "In ___ of",
    "Place",
    "In ___ of (replacing)",
    "Place",
    "Place",
    "In ___ of (substituting for)",
    "Place",
    "Stead",
    "Place",
    "Stead",
    "Place",
    "Stead",
    "Place",
    "Place",
    "Place",
    "In ___ of",
    "Place",
    "Stead",
    "Stead",
    "Stead",
    "Stead",
    "Place",
    "Place",
    "Place",
    "Stead",
    "Stead",
    "Place",
    "Stead",
    "Stead",
    "In___ of (as a substitute for)",
    "Place",
    "Stead",
    "In-of connector",
    "Stead",
    "In ___ of",
    "In ___ of",
    "Stead"
  ],
  "kong": [
    "King of Skull Island",
    "See 1-Across",
    "Major ___, \"Dr. Strangelove\" character who rides the bomb",
    "Diddy ___ (peanut-shooting Nintendo character)",
    "King ___",
    "Beast on Skull Island, informally",
    "Skull Island beast, for short",
    "\"King ___\"",
    "\"King ___\"",
    "\"King ___\"",
    "Empire State Building climber, for short",
    "\"The Eighth Wonder of the World,\" informally",
    "Donkey ___",
    "Skull Island denizen, for short",
    "Donkey ___ (game)",
    "\"King ___ Lives\" (much-ballyhooed 1986 film flop)",
    "King ___",
    "King ___",
    "King ___",
    "Big ape",
    "King of Skull Island",
    "King ___",
    "\"King ___\""
  ],
  "shue": [
    "Actress Elisabeth",
    "Actress Elisabeth",
    "\"CSI\" actress Elisabeth",
    "Elisabeth of \"CSI\"",
    "Actor Andrew of 1990s TV",
    "Elisabeth of \"Hamlet 2\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "\"Molly\" title role player, 1999",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Actress Elisabeth",
    "\"Leaving Las Vegas\" actress",
    "Actress Elisabeth",
    "Elisabeth of \"Cocktail\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Andrew of \"Melrose Place\"",
    "Oscar-nominated actress for \"Leaving Las Vegas\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "\"Leaving Las Vegas\" actress",
    "\"Soapdish\" actress",
    "\"Leaving Las Vegas\" co-star",
    "Actress Elisabeth",
    "\"Melrose Place\" actor",
    "Actress Elisabeth",
    "Andrew of \"Melrose Place\"",
    "Elisabeth of \"Leaving Las Vegas\"",
    "Cage's \"Leaving Las Vegas\" co-star",
    "Cage co-star in \"Leaving Las Vegas\"",
    "Actor Andrew",
    "\"Leaving Las Vegas\" actress",
    "\"Leaving Las Vegas\" star",
    "\"Leaving Las Vegas\" actress",
    "\"Leaving Las Vegas\" co-star"
  ],
  "talc": [
    "Powder in the powder room",
    "What goes right to the bottom?",
    "After-bath application",
    "Powder in a medicine cabinet",
    "Powder for a gymnast",
    "After-bath powder",
    "Mineral on the Mohs scale",
    "Rash decision?",
    "Barber's powder",
    "Anti-rash powder",
    "Soft mineral",
    "Mineral in bath powder",
    "Bottom topper?",
    "Soft mineral",
    "Bathroom powder",
    "Soapy powder mineral",
    "It might be patted on the back",
    "Barber's supply",
    "Soothing powder",
    "Barber's sprinkle",
    "After-bath powder",
    "Masseur's supply",
    "Mohs scale mineral",
    "Baby powder ingredient",
    "Some powder",
    "Baby powder component",
    "After-bath powder",
    "Powder on a puff",
    "It may be put on after a shower",
    "Bottled mineral",
    "Soft powder",
    "Soft powder",
    "Baby powder ingredient",
    "It gets patted on the bottom",
    "Soothing sprinkle",
    "Mineral in tailor's chalk",
    "Rash treatment",
    "Rash soother",
    "Rash preventer",
    "Tailor's chalk, typically",
    "Baby powder ingredient",
    "1 on the Mohs scale",
    "Creator of a bathroom cloud",
    "Face powder ingredient",
    "French chalk mineral",
    "Soapstone, e.g.",
    "Anti-rash agent",
    "Locker room supply",
    "Soft powder",
    "Soapstone, e.g.",
    "Soapstone, say",
    "After-bath powder",
    "Bathroom powder",
    "Supply at a changing station",
    "1 on the Mohs scale",
    "Rash response?",
    "Locker room supply",
    "Fine powder",
    "Toiletry item",
    "Baby powder mineral",
    "After-bath powder",
    "Easily-scratched mineral",
    "Barber's supply",
    "Soft stuff",
    "Rash treatment",
    "Gym bag stuff",
    "Sprinkler's need?",
    "After-bath powder",
    "Locker room supply",
    "Soft mineral",
    "Bath powder ingredient",
    "Chalk ingredient",
    "Rash soother",
    "Baby shower?",
    "Barber's powder",
    "After-bath powder",
    "Barber's supply",
    "Powder ingredient",
    "Bathroom powder",
    "Mineral in pesticide",
    "Bath powder",
    "Powder base",
    "Magnesium silicate",
    "Diamond : 10 :: ___ : 1",
    "It might be patted on the back",
    "It may go right to the bottom",
    "One end of the Mohs' scale",
    "Locker room supply",
    "Soothing application",
    "Soapstone component",
    "Locker room powder",
    "French chalk",
    "Bathroom supply",
    "Common powder",
    "Sprinkle after a shower",
    "Baby powder ingredient",
    "Locker room supply",
    "Soft rock",
    "Fine-grained mineral",
    "Soapstone constituent",
    "Bath powder",
    "Drying powder",
    "Covering for a baby's bottom",
    "Baby powder",
    "Soft mineral",
    "Bath powder",
    "Soft mineral",
    "Bath product",
    "Mineral powder",
    "Magnesium silicate",
    "1 on the Mohs' scale",
    "Drying powder",
    "Bathroom sprinkle",
    "Baby powder",
    "Sprinkle after a shower?",
    "Soft mineral"
  ],
  "moab": [
    "Gateway city to Utah's Arches National Park",
    "Ancient kingdom in modern-day Jordan",
    "City near Canyonlands and Arches National Parks",
    "Biblical kingdom in modern-day Jordan",
    "Ancient Dead Sea kingdom",
    "Utah town near Arches and Canyonlands National Parks",
    "Ancient kingdom east of the Dead Sea",
    "Seat of Utah's Grand County",
    "Biblical kingdom",
    "City south of Utah's Arches National Park",
    "Biblical kingdom or its Utah namesake",
    "City near Arches National Park",
    "Ancient neighbor of Judah",
    "Biblical kingdom east of the Dead Sea",
    "Biblical kingdom where Moses died",
    "Kingdom in ancient Jordan",
    "Biblical kingdom",
    "Biblical kingdom",
    "Old Dead Sea kingdom",
    "Ancient land along the Dead Sea",
    "Biblical kingdom",
    "Old Testament kingdom",
    "Town near Arches National Park",
    "Utah biking mecca",
    "Land where Moses died",
    "Ancient kingdom east of the Dead Sea",
    "Biblical kingdom",
    "Land subjugated by 106-Down",
    "Ancient kingdom of Jordan",
    "Ruth's land, in the Bible",
    "Ancient Dead Sea kingdom"
  ],
  "sire": [
    "Father",
    "Father",
    "Old-timey title",
    "Father",
    "\"Your majesty\"",
    "Beget",
    "\"Your majesty\"",
    "Bull, e.g.",
    "Father",
    "Term of address in \"The Wizard of Id\"",
    "Beget",
    "What to call a king",
    "Beget",
    "\"Your Majesty\"",
    "Royal title",
    "Regal term of address",
    "Father horse",
    "Part of a pedigree",
    "Courtly term of address",
    "\"Your majesty\"",
    "Entry in an equine family tree",
    "Top of the line?",
    "Part of a bloodline",
    "How to address a king",
    "Royal title",
    "Stud, often",
    "Bold Ruler, to 64-Across",
    "Arabian parent",
    "Dam's companion",
    "Beget",
    "Stable father figure?",
    "Father of a foal",
    "Highness",
    "Part of an equine pedigree",
    "Part of a horse's bloodline",
    "Term for a crown",
    "Father, as a mudder",
    "What to call a king",
    "Retired racehorse, maybe",
    "What to call a crown",
    "Stud on a stud farm",
    "Filly's father",
    "Part of a horse's genealogy",
    "\"Your Majesty\"",
    "\"Sovereign\"",
    "Father",
    "What to call a king",
    "Father",
    "King",
    "Beget",
    "Beget",
    "\"Your majesty\"",
    "Give rise to",
    "Beget",
    "Produce",
    "Beget",
    "Breeder",
    "Foal's father",
    "Beget",
    "Beget",
    "King",
    "Father",
    "Part of a horse's pedigree",
    "Breed",
    "Whom a courtier may address",
    "Lord, old-style",
    "Dam's counterpart",
    "Court addressee",
    "Part of an equine pedigree",
    "What to call a king",
    "Crown wearer",
    "Father",
    "Beget",
    "\"Your majesty\"",
    "Word for a king",
    "Father",
    "Filly's father",
    "Engender",
    "Bring into being",
    "Filly's father",
    "Retired racehorse, maybe",
    "Your highness",
    "Progenitor",
    "\"Your majesty\"",
    "Beget",
    "Part of a pedigree",
    "Address fit for a king",
    "Beget",
    "Beget",
    "Regal term of address",
    "\"Your excellency\"",
    "Father",
    "Madonna's record label",
    "Father",
    "Equine parent",
    "Father",
    "Your Majesty",
    "Archaic form of address",
    "Filly's father",
    "Term of address at court",
    "King's address",
    "Father",
    "Stable parent",
    "Part of an equine family tree",
    "King",
    "King's address"
  ],
  "fear": [
    "Have a sneaking suspicion",
    "Quaking cause",
    "\"Never ___!\"",
    "One cause of goose bumps",
    "\"The path to the dark side,\" per Yoda",
    "\"The foundation of most governments,\" per John Adams",
    "Source of a nightmare",
    "Chicken characteristic",
    "Phobia",
    "\"___ not!\"",
    "Goose bumps cause",
    "Phobia",
    "Haunted house feeling",
    "\"___ not!\"",
    "What a chicken feels",
    "What chickens have",
    "\"The oldest and strongest emotion of mankind,\" per H. P. Lovecraft",
    "\"___ is not a lasting teacher of duty\": Cicero",
    "\"___ leads to anger, anger leads to hate, hate leads to suffering\": Yoda",
    "Be frightened",
    "Chicken quality",
    "Dread",
    "Phobia",
    "\"That little darkroom where negatives are developed,\" per Michael Pritchard",
    "Cause of quaking",
    "Cause of quailing",
    "Alarm",
    "Cause of some quaking",
    "Goose bumps cause",
    "Phobia",
    "Cold feet",
    "Partner of trembling",
    "\"___ not\"",
    "Bad thing to live in",
    "Shake in one's boots",
    "Phobia",
    "Alarm",
    "Cold feet, so to speak",
    "Four Freedoms subject",
    "Cause of cold sweat",
    "Cause of goosebumps",
    "Affright",
    "Foreboding",
    "\"___ not!\"",
    "\"An instructor of great sagacity\": Emerson",
    "One cause of trembling",
    "North Carolina's Cape ___",
    "Four Freedoms subject",
    "North Carolina's Cape ___",
    "Terror",
    "Nightmare",
    "Bugaboo",
    "Heartquake",
    "Dread",
    "Panic",
    "Butterflies",
    "Dread",
    "Dread",
    "Misgiving",
    "Cape ___, N.C.",
    "Trepidation"
  ],
  "peke": [
    "Nickname for a toy",
    "Chinese lap dog, informally",
    "Toy dog, briefly",
    "Chinese toy, for short",
    "Toy from China",
    "Lap dog, informally",
    "Chow alternative",
    "Small dog, informally",
    "Yappy dog, briefly",
    "Lap dog, for short",
    "Cutesy-poo dog",
    "Lap dog",
    "Small dog, informally",
    "Little yipper",
    "Lap yapper",
    "Little dog, for short",
    "Lap dog, for short",
    "Chow alternative",
    "Toy dog",
    "Lap dog",
    "Short dog, for short",
    "Small dog breed, for short"
  ],
  "wilt": [
    "Lose one's vigor",
    "Droop",
    "Old-time N.B.A. great Chamberlain",
    "Droop from lack of water",
    "What thirsty flowers do",
    "Part 4 of the word ladder",
    "Go soft, in a way",
    "Crumble under pressure",
    "Droop, as flowers",
    "Droop in the heat",
    "What picked flowers may do",
    "Lose freshness, as a flower",
    "Suffer from the heat",
    "Flag",
    "Word before thou",
    "Suffer from the heat",
    "Fade",
    "Not take the heat well",
    "Suffer from the heat",
    "Weaken",
    "Lose freshness, as lettuce",
    "Shrivel",
    "Droop",
    "Lose freshness",
    "Basketball's Chamberlain"
  ],
  "loki": [
    "Father of Jörmungandr",
    "Troublemaker of 1-Down",
    "Norse troublemaker",
    "Norse trickster",
    "\"The Avengers\" role",
    "Enemy of the Avengers",
    "Aesir trickster",
    "Troublemaking Norse god",
    "Mythical mischief-maker",
    "Shape-shifter of Norse mythology",
    "Villain in \"The Avengers\"",
    "God whose name sounds like a word meaning \"understated\"",
    "Crafty Norse god",
    "Shape-shifting Norse god",
    "Shape-shifting Norse trickster",
    "\"The Avengers\" supervillain",
    "Mischievous Norse god",
    "Deity in the Edda",
    "Trickster of myth",
    "\"The Avengers\" villain, 2012",
    "Mythical con man",
    "Thor's archenemy in comics",
    "Mischievous Norse god",
    "\"The Avengers\" villain",
    "Norse prankster",
    "Crafty Norse god",
    "Mythological trickster who was punished by being held to a rock",
    "Norse trickster",
    "Mischievous Norse god",
    "Shape-shifting giant of myth",
    "Norse deity of mischief",
    "Trickster in Norse myth",
    "Mischief maker of myth",
    "Evildoer of Asgard",
    "Evil Norse god",
    "Evildoing Norse god",
    "God of discord, in Scandinavia",
    "Mischievous Norse god",
    "Norse god of discord",
    "Hel raiser?",
    "Enemy of Thor",
    "Hel's father, in myth"
  ],
  "gari": ["Ginger at a sushi bar"],
  "teem": [
    "Brim",
    "Swarm (with)",
    "Be plentiful",
    "Overflow (with)",
    "Abound (with)",
    "Be lousy (with)",
    "Crawl (with)",
    "Be overrun (with)",
    "Abound",
    "Abound",
    "Overflow (with)",
    "Overflow",
    "Swarm (with)",
    "Swarm (with)",
    "Be swarming (with)",
    "Be a crowd",
    "Be thick (with)",
    "Overflow (with)",
    "Abound (with)",
    "Be present in large quantity",
    "Abound",
    "Be full",
    "Be overflowing",
    "Swarm (with)",
    "Swarm",
    "Overflow",
    "Be overrun",
    "Overflow (with)",
    "Swarm",
    "Swarm",
    "Brim",
    "Overflow",
    "Abound",
    "Burst",
    "Abound (with)",
    "Abound (with)",
    "Overflow",
    "Crawl (with)",
    "Be overrun (with)",
    "Overbrim (with)",
    "Burst (with)",
    "Abound",
    "Swarm",
    "Overflow",
    "Abound (with)",
    "Crawl (with)",
    "Swarm",
    "Abound (with)",
    "Brim",
    "Overflow (with)",
    "Swarm",
    "Bristle (with)",
    "Swarm",
    "Brim",
    "Overflow",
    "Be loaded",
    "Be prolific",
    "Crawl (with)",
    "Abound",
    "Overbrim",
    "Crawl (with)",
    "Overflow",
    "Brim (with)",
    "Abound (with)",
    "Swarm (with)",
    "Abound",
    "Overflow",
    "Overflow",
    "Crawl (with)",
    "Swarm",
    "Abound",
    "Overflow",
    "Crawl (with)",
    "Swarm",
    "Overbrim",
    "Swarm",
    "Overflow",
    "Crawl (with)",
    "Be rife (with)",
    "Abound",
    "Swarm",
    "Crawl (with)",
    "Abound",
    "Swarm",
    "Superabound",
    "Abound",
    "Overrun",
    "Be fertile",
    "Overflow",
    "Abound",
    "Burst (with)",
    "Be full"
  ],
  "prod": [
    "Goad",
    "Egg on",
    "Urge on",
    "Cow poke?",
    "Poke",
    "Nudge",
    "Spur",
    "Western shocker",
    "Elbow, maybe",
    "Push",
    "Spur",
    "Stick on the range?",
    "Nudge",
    "Poker",
    "Push",
    "Pointed implement",
    "Kick in the rear, maybe",
    "Goad",
    "Driving tool",
    "Cowherd's aid",
    "Driver on a ranch",
    "Impel to action",
    "Motivate",
    "Cowherd's tool",
    "Get going",
    "Poke",
    "Stimulus",
    "It may give a shock on a ranch",
    "Cattle rancher's tool",
    "Egg on",
    "Nudge forward",
    "Elbow",
    "Egg on",
    "Push",
    "Give a pointer to?",
    "Goad",
    "Egg on",
    "Cattle encourager",
    "Encourage",
    "Cattle ___",
    "Poke",
    "Stick with a stick",
    "Egg on"
  ],
  "digs": [
    "Pad",
    "Crib",
    "Does an investigation",
    "Is into",
    "Archaeological sites",
    "Pad",
    "Makes holes",
    "Shovels",
    "Pad",
    "Quarters, informally",
    "Abode, informally",
    "Pad",
    "Home, informally",
    "Lodgings, informally",
    "Is hip to",
    "Rummages (through)",
    "Pad",
    "Grooves on",
    "Pad",
    "Archeological sites",
    "Pad",
    "Living quarters, informally",
    "Pad",
    "Nasty remarks",
    "Lodgings",
    "Quarters"
  ],
  "lone": [
    "Without a match",
    "___ Star",
    "Single",
    "Singular",
    "Only",
    "Like the star on Texas' flag",
    "Sole",
    "Sole",
    "Singular",
    "Solitary",
    "Sole",
    "Unaccompanied",
    "___ Star State",
    "Only",
    "Unaccompanied",
    "Lacking company",
    "Solo",
    "Unattended",
    "Single",
    "Sole",
    "___ Star State (Texas)",
    "Isolated",
    "Sole",
    "Singular",
    "Single",
    "\"___ Survivor\" (2013 war film)",
    "Unaided",
    "Singular",
    "Single",
    "Isolated",
    "\"The ___ Ranger\"",
    "Star or wolf preceder",
    "___ Ranger",
    "Lacking company",
    "Single",
    "Isolated",
    "Unique",
    "Unaccompanied",
    "Situated by itself",
    "Like a maverick",
    "Solo",
    "Solo",
    "Unattended",
    "Isolated",
    "Unaccompanied",
    "The ___ Ranger",
    "Singular",
    "Sole",
    "Companionless",
    "Lacking a partner",
    "Set apart",
    "\"The ___ Ranger\"",
    "Eremitic",
    "Unfrequented",
    "Like an eremite",
    "Without a partner",
    "Solitary",
    "Unaccompanied",
    "With 28-Down, a 1996 western",
    "Unfrequented",
    "\"The ___ Ranger\"",
    "Single",
    "Kind of wolf",
    "Only",
    "Unattended",
    "Like a recluse",
    "Unaccompanied",
    "Kind of wolf",
    "Hermitic",
    "Isolated",
    "Unaided",
    "Single",
    "Sole",
    "Isolated",
    "Unattended",
    "Single",
    "Set apart",
    "Solitary",
    "Like Silver's rider",
    "Unattended",
    "Individual",
    "Secluded",
    "\"The ___ Ranger\"",
    "Unparalleled",
    "Like Silver's rider",
    "Separated",
    "Abandoned",
    "Unattached",
    "Kind of hand, in euchre",
    "Solitary",
    "Solitary",
    "Unattended",
    "___ gunman theory",
    "Sole",
    "Kind of wolf",
    "Unattached",
    "Isolated",
    "Like a star for 46-Down",
    "Sole",
    "Solitary"
  ],
  "bale": [
    "Roll in the hay?",
    "Hay unit",
    "Bundle of hay",
    "Bundle up",
    "Hoedown seat",
    "Assemble in a field, say",
    "Seat at a hootenanny",
    "Roll in the hay?",
    "Christian in Hollywood",
    "Farm block",
    "Farm unit",
    "Barn seat",
    "Sight on a \"Hee Haw\" set",
    "Roll in the grass?",
    "It's bound to leave the field",
    "Christian of film",
    "Christian of \"The Dark Knight Rises\"",
    "Bundle of cotton",
    "Barn dance seat",
    "Roll in a field",
    "Farm delivery",
    "Paper quantity",
    "It may be tied up in farmwork",
    "\"Oklahoma!\" set piece",
    "Unit of cotton",
    "Makeshift seat at a rodeo",
    "Seat at a barn dance",
    "Cotton unit",
    "Plantation creation",
    "Straw unit",
    "Hay unit",
    "Bundle in a barn",
    "Hay bundle",
    "Make a bundle",
    "Bundle up",
    "It may be held together by twine",
    "Seat at a hootenanny",
    "It's bound with twine",
    "Place for a hayfork",
    "Bundle up",
    "Bundle up",
    "Bundle",
    "Cotton press output",
    "Hay bundle",
    "Make a bundle",
    "Wired package",
    "Bind with haywire",
    "Make a bundle",
    "Cotton unit",
    "Cotton unit",
    "Hoedown sight",
    "Make a bundle",
    "Barn dance seat",
    "Heavy load",
    "Farm wagon item",
    "Make a bundle",
    "Make a bundle",
    "Hootenanny sight",
    "Hoedown seat",
    "Cotton bundle",
    "Cotton quantity",
    "Bundled cotton",
    "Cotton bundle",
    "Farm unit",
    "Bundle up",
    "Cotton bundle",
    "Group of turtles",
    "\"Oklahoma!\" prop",
    "Hoedown prop"
  ],
  "enos": [
    "905-year-old in Genesis",
    "First son of Seth",
    "Baseball Hall-of-Famer Slaughter",
    "Grandson of Adam and Eve",
    "Book of Mormon book",
    "Grandson of Eve",
    "Emmy- and Tony-nominated actress Mireille",
    "Only ape to orbit the earth (1961)",
    "Son of Seth",
    "Book of Mormon book",
    "Biblical figure born to a 105-year-old father",
    "Baseball Hall-of-Famer Slaughter",
    "Nephew of Cain",
    "1980s spinoff of \"The Dukes of Hazard\"",
    "Ancestor of Methuselah",
    "Grandson of Adam and Eve",
    "Baseball's Slaughter",
    "Space chimp of 1961",
    "Slaughter who was a star of the 1946 World Series",
    "\"The Dukes of Hazzard\" spinoff",
    "1961 Project Mercury chimp",
    "Book of Mormon book",
    "Grandson of Adam",
    "Slaughter of the Cardinals",
    "1961 space chimp",
    "Nephew of Cain",
    "Book of Mormon prophet",
    "Genesis grandson",
    "Project Mercury primate",
    "1961 space chimp",
    "Book of Mormon prophet",
    "Biblical figure said to have married his sister Noam",
    "Nephew of 8-Down",
    "Grandson of 47-Across and 37-Down",
    "Book of Mormon book",
    "Nonhuman Earth orbiter of 1961",
    "\"The Killing\" star Mireille ___",
    "Book of Mormon prophet",
    "Genesis grandson",
    "Grandson of Adam",
    "Officer on TV's \"The Dukes of Hazzard\"",
    "Son of Seth",
    "Slaughter in the 1946 World Series",
    "Grandson of Eve",
    "First chimp to orbit Earth",
    "Baseball's Slaughter",
    "Biblical son begat by a 105-year-old father",
    "Grandson of Adam and Eve",
    "Deputy sheriff in \"The Dukes of Hazzard\"",
    "First son of Seth",
    "1961 space chimp",
    "Slaughter in baseball",
    "Slaughter in baseball",
    "Nephew of Cain",
    "Genesis figure",
    "Son of Seth",
    "Grandson of Adam",
    "Grandson of 21-Across",
    "Nephew of Abel",
    "Hazzard County deputy",
    "Book in the Book of Mormon",
    "Slaughter who dashed home to win the 1946 World Series",
    "Cabell who was the 1978 N.L. at-bats leader",
    "1980 TV spinoff",
    "Grandson of Adam",
    "Grandson of Adam",
    "Son of Seth",
    "Fourth book of the Book of Mormon",
    "Genesis son",
    "Son of Seth",
    "Son of Seth",
    "Son of Seth",
    "Third-generation man, in the Bible",
    "Book of Mormon book",
    "Outfielder Slaughter in the Baseball Hall of Fame",
    "Great-great-great-grandfather of Methuselah",
    "Biblical 905-year-old",
    "Genesis grandson",
    "Fourth book of the Book of Mormon",
    "Slaughter in the Baseball Hall of Fame",
    "Grandson of Adam",
    "Genesis son",
    "\"The Dukes of Hazzard\" spinoff",
    "1961 \"spacechimp\"",
    "Grandson of Adam",
    "Mercury-Atlas 5 rider",
    "Book in the Book of Mormon",
    "Enoch's great-great-grandfather",
    "Genesis son",
    "1961 space chimp",
    "Fourth book of the Book of Mormon",
    "Great-great-great-great-great grandfather of Noah",
    "Grandson of Adam",
    "Son of Seth",
    "Slaughter in baseball",
    "\"The Dukes of Hazzard\" deputy",
    "Orbiting chimp of 1961",
    "1961 earth orbiter",
    "Son of Seth",
    "Short-lived TV spinoff of 1980",
    "Book after Jacob in the Book of Mormon",
    "Book of the Book of Mormon",
    "Hazzard County lawman",
    "Early 80's TV police comedy",
    "Baseball's Slaughter",
    "Slaughter in Cooperstown",
    "1961 space chimp",
    "\"The Dukes of Hazzard\" spinoff",
    "1940's-50's All-Star ___ Slaughter",
    "Slugger Slaughter",
    "Space chimp of 1961",
    "Baseball's Slaughter",
    "Grandson of Adam",
    "Genesis son",
    "Son of Seth",
    "Slaughter the slugger",
    "Book of the Book of Mormon",
    "Old Testament figure",
    "Son of Seth",
    "Grandson of Adam",
    "Early space chimp",
    "Son of Seth",
    "Son of Seth",
    "1961 space chimp",
    "Genesis name",
    "Son of Seth",
    "Slaughter of the 1940's-50's Cardinals",
    "Slaughter of Cooperstown",
    "Baseball's Slaughter",
    "\"The Dukes of Hazzard\" spin-off",
    "Baseball Hall-of-Famer Slaughter",
    "Grandson of Adam",
    "1940's-50's All-Star ___ Slaughter",
    "1980's TV police comedy",
    "Genesis grandson",
    "1961 chimp in space",
    "Son of Seth",
    "Genesis son",
    "Genesis grandchild",
    "Grandson of Adam",
    "Grandson of Adam",
    "Slaughter of baseball",
    "Grandson of Eve",
    "Hall-of-Famer Slaughter",
    "Chimp in a spacesuit",
    "\"The Dukes of Hazzard\" spinoff",
    "Baseball's Slaughter",
    "TV spinoff of 1980",
    "\"The Dukes of Hazzard\" spinoff",
    "Grandson of Adam",
    "Baseballer Slaughter",
    "Genesis man",
    "Son of Seth",
    "Son of Seth",
    "Nephew of Abel",
    "Baseball's Slaughter",
    "Grandson of Adam",
    "Grandson of Eve",
    "Grandson of Adam",
    "Grandson of Eve",
    "Son of Seth",
    "Western Electric founder ___ Barton",
    "Baseball's Slaughter",
    "Son of Seth",
    "Genesis grandson",
    "Biblical patriarch",
    "Space chimp of 1961",
    "Grandson of Adam",
    "Son in Genesis",
    "Deputy sheriff of TV's Hazzard County",
    "Busby Berkeley's real last name",
    "Son of Seth",
    "Baseball's ___ Slaughter",
    "Little ___, of \"Smokey and the Bandit\" movies",
    "1961 space chimp",
    "Son of Seth",
    "Son of Seth",
    "1940's Cardinal ___ Slaughter",
    "Grandson of Adam and Eve",
    "Son of Seth",
    "Chimp in space",
    "Son of Seth",
    "Nephew of Abel",
    "1961 chimp in space",
    "Son of Seth",
    "Slaughter in baseball",
    "\"The Dukes of Hazzard\" spinoff",
    "Chimp in space",
    "\"The Dukes of Hazzard\" spinoff",
    "Nephew of Cain",
    "Grandson of 5-Down",
    "TV deputy from Hazzard",
    "Genesis son",
    "Hazzard County lawman",
    "\"The Dukes of Hazzard\" spinoff",
    "Seth's son",
    "Man's name meaning \"mortal\"",
    "1980's police comedy",
    "Genesis name",
    "Grandson of Adam",
    "Son of Seth",
    "Son of Seth",
    "Hazzard County deputy",
    "1961 space chimp",
    "Genesis name",
    "Son of Seth",
    "Slaughter on the ballfield",
    "Son of Seth",
    "Baseball's Slaughter",
    "Hall-of-Famer Slaughter",
    "Baseball's Slaughter",
    "NASA chimp",
    "Grandson of Adam",
    "Grandson of Adam",
    "Baseball's Slaughter",
    "?ôDukes of Hazzard?ö spinoff",
    "Book in the Book of Mormon",
    "Nephew of Abel",
    "Son of Seth",
    "Slaughter in baseball",
    "Biblical son",
    "\"Country\" Slaughter",
    "Son of Seth",
    "Genesis son",
    "Grandson of Adam",
    "Country Slaughter",
    "Son in Genesis",
    "Hazzard County officer, on TV",
    "60's space chimp",
    "Son of Seth",
    "Grandson of Adam",
    "Son of Seth",
    "Cain's nephew",
    "Western Electric co-founder Barton",
    "Son of Seth",
    "1980's TV police comedy",
    "Son of Seth",
    "Grandson of Adam",
    "Cardinal Slaughter",
    "Western Electric founder ___ Barton",
    "Slaughter in Cooperstown",
    "Seth's son"
  ],
  "refi": [
    "Offer you might have less interest in, for short?",
    "Loan option, briefly",
    "A.P.R.-lowering option",
    "Advantageous mtge. deal",
    "Something of little interest, a homeowner hopes",
    "Homeowner's action, for short",
    "Mortgage adjustment, for short",
    "Mortgage deal, for short",
    "Many a new loan, for short",
    "Bank offering, briefly",
    "Mortgage adjustment, briefly",
    "Lower one's A.P.R., perhaps",
    "New home loan deal, in short",
    "Mortgage adjustment, briefly",
    "New mortgage deal, informally",
    "Mtge. broker's come-on",
    "Bank offering, informally",
    "Debtor's option, briefly",
    "Altered mortgage, briefly",
    "Mortgage adjustment, for short",
    "Certain mortgage, briefly",
    "Change of mortgage, informally",
    "Change of a mortgage, slangily",
    "Bank offering, briefly"
  ],
  "smee": [
    "Aide to Captain Hook",
    "Bob Hoskins's role in 1991's \"Hook\"",
    "Disney henchman with long, white sideburns",
    "Hook's henchman",
    "Hook's sidekick",
    "Hook associate",
    "Mr. ___ (\"Peter Pan\" character)",
    "Hook's sidekick",
    "Hook's helper",
    "Hook's henchman",
    "Jolly Roger pirate",
    "Hook's right hand",
    "Hook's henchman",
    "Bo'sun for Captain Hook",
    "Pirate's mate, in literature and film",
    "\"Hook\" role",
    "Captain Hook's henchman",
    "\"Man who stabbed, so to speak, without offence,\" in a 1911 novel",
    "Captain Hook's sidekick",
    "\"Peter Pan\" buccaneer",
    "Mr. of \"Peter Pan\"",
    "Jolly Roger crewman",
    "Captain Hook henchman",
    "Captain Hook's right hand",
    "\"Return to Never Land\" role",
    "Jolly Roger pirate",
    "Hook's hand",
    "Captain Hook's mate",
    "Storybook pirate",
    "Role in \"Hook\"",
    "Hook hand",
    "Hook helper",
    "Crewman on the Jolly Roger",
    "Hook accompanier",
    "\"Luff, you lubber\" speaker of literature",
    "Peter Pan pursuer",
    "\"Peter Pan\" pirate",
    "Hook's henchman",
    "Barrie's \"oddly genial man\"",
    "Hook's right-hand man",
    "Literary pirate",
    "Captain Hook's henchman",
    "\"Peter Pan\" pirate",
    "\"Peter Pan\" pirate",
    "Right-hand man for a man with no right hand",
    "Portly pirate",
    "Hook go-with?",
    "\"Peter Pan\" pirate",
    "Captain Hook's henchman",
    "Disney pirate, 1953",
    "Captain Hook's mate in \"Peter Pan\"",
    "Captain Hook's henchman",
    "Fictional pirate",
    "\"Peter Pan\" pirate",
    "Bob Hoskins's role in \"Hook\"",
    "\"Peter Pan\" pirate",
    "Hook hand",
    "Fictional sailor",
    "Fictional pirate",
    "J. M. Barrie pirate",
    "Hook's mate",
    "Capt. Hook's sidekick",
    "Barrie buccaneer",
    "Barrie buccaneer",
    "\"Peter Pan\" pirate",
    "\"Peter Pan\" pirate",
    "Fictional pirate",
    "\"Peter Pan\" pirate",
    "Captain Hook's henchman",
    "Hook hand",
    "Hook's underling",
    "Hook's mate",
    "Barrie bad guy",
    "\"Peter Pan\" pirate",
    "\"Peter Pan\" character",
    "\"Peter Pan\" pirate",
    "\"Peter Pan\" role",
    "Hoskins's role in \"Hook\"",
    "Bob Hoskins role of 1991",
    "Hook's mate",
    "Barrie pirate",
    "Hoskins role in \"Hook\"",
    "Mr. ___ of \"Peter Pan\"",
    "\"Peter Pan\" man",
    "\"Peter Pan\" role",
    "Captain Hook's helper",
    "Barrie baddie",
    "Barrie baddie",
    "\"Peter Pan\" pirate",
    "Jolly Roger crewman",
    "Capt. Hook's companion",
    "Bob Hoskins's role in \"Hook\"",
    "Fictional pirate",
    "Hook's mate",
    "Captain Hook's sidekick",
    "Bob Hoskins's role in \"Hook\"",
    "Captain Hook's assistant",
    "Fictional pirate",
    "\"Peter Pan\" pirate",
    "Captain Hook's assistant",
    "\"Peter Pan\" character",
    "Hook's mate",
    "Never Never Land dweller",
    "\"Peter Pan\" pirate",
    "Hoskins role in \"Hook\"",
    "Captain Hook's right hand, so to speak",
    "Hook's henchman",
    "Bob Hoskins in \"Hook\"",
    "Hook's henchman"
  ],
  "tamp": [
    "Pack down",
    "Pack down",
    "Press (down)",
    "Press (down)",
    "Pack (down)",
    "Pack (down)",
    "Drive (down)",
    "Pack down",
    "Pat down, as pipe tobacco",
    "Pat (down)",
    "Pack (down)",
    "Pack down",
    "Pack (down)",
    "Press down",
    "Press down",
    "Pat",
    "Push (down)",
    "Press down, as pipe tobacco",
    "Pack down",
    "Pack down",
    "Pack (down)",
    "Pound (down)",
    "Pound (down)",
    "Pack (down)",
    "Pack (in)",
    "Compact"
  ],
  "tact": [
    "It's been called \"the art of making a point without making an enemy\"",
    "Delicate touch",
    "Delicacy",
    "Careful word choice, maybe",
    "Diplomacy",
    "\"The ability to step on a man's toes without messing up the shine on his shoes,\" per Harry Truman",
    "Savoir-faire",
    "Phrasing so as not to offend, say",
    "Diplomat's forte",
    "Diplomat's skill",
    "Asset for a press secretary",
    "Social adroitness",
    "\"It is ___ that is golden, not silence\": Samuel Butler",
    "Lout's lack",
    "\"The art of making a point without making an enemy\"",
    "Politician's asset",
    "\"The ability to describe others as they see themselves,\" per Lincoln",
    "It \"teaches you when to be silent,\" per Disraeli",
    "What might be revealed in silence",
    "Boor's lack",
    "Diplomacy",
    "Skill needed when being asked \"Does this dress make me look fat?\"",
    "Call or email",
    "Savoir-faire",
    "Careful wording, maybe",
    "\"___ teaches you when to be silent\": Disraeli",
    "Careful phrasing, perhaps",
    "Ambassador's asset",
    "Saying \"I'm not sure that dress looks perfect on you,\" e.g.",
    "Mediation asset",
    "Skill not displayed by asking \"Have you put on weight?\"",
    "What it takes not to say \"I see you've put on a little weight\"",
    "Asset in answering the question \"Does this dress make me look fat?\"",
    "\"___ teaches you when to be silent\": Disraeli",
    "Mediator's skill",
    "Churls lack it",
    "Ambassador's forte",
    "Diplomacy",
    "\"The ability to describe others as they see themselves\": Lincoln",
    "Poise",
    "Diplomat's forte",
    "What it may take to answer the question \"Does this make me look fat?\"",
    "Diplomacy",
    "Delicacy",
    "Opposite of gaucheness",
    "Savoir-faire",
    "Delicateness",
    "Diplomat's asset",
    "Diplomat's asset",
    "Social skill",
    "Delicacy at a state dinner?",
    "Diplomat's asset",
    "Public relations need",
    "Remaining silent, sometimes",
    "Yahoos lack it",
    "Boor's lack",
    "Oafs lack it",
    "Diplomacy",
    "Discretion",
    "Good host's gift",
    "Social grace",
    "Sign of refinement",
    "Savoir-faire",
    "Playing to someone's vanity, maybe",
    "Mediator's skill",
    "Delicacy",
    "Knowing when to be silent, e.g.",
    "Diplomatic trait",
    "Delicate use of words",
    "Certain grace",
    "Diplomat's forte",
    "Social asset",
    "Mediator's skill",
    "Remaining silent, at times",
    "Peacekeeping skill",
    "Mediator's skill",
    "Savoir-faire",
    "Diplomat's skill",
    "Miss Manners subject",
    "Diplomat's specialty",
    "Sensitivity",
    "Savior-faire",
    "Diplomacy",
    "White lie, perhaps",
    "Way with words",
    "Way with words"
  ],
  "rill": [
    "Small stream",
    "Small stream",
    "Streamlet",
    "Small stream",
    "Small brook",
    "Streamlet",
    "Streamlet",
    "Moon marking",
    "Moon marking",
    "Streamlet",
    "Streamlet",
    "Streamlet",
    "Small stream",
    "Lunar trench",
    "Small stream",
    "Streamlet",
    "Brook",
    "Small brook",
    "Moon valley",
    "Streamlet",
    "Lunar trench",
    "Tiny stream",
    "Brook",
    "Small brook",
    "Brook",
    "Streamlet",
    "Brooklet",
    "Small brook",
    "Brooklet",
    "Streamlet",
    "Brooklet",
    "Small brook",
    "Brook",
    "Brook",
    "Small brook",
    "Brooklet"
  ],
  "peps": [
    "Livens (up)",
    "Juices (up)",
    "Fires (up)",
    "Livens (up)",
    "Fires (up)",
    "Energizes, with \"up\"",
    "Fires (up)",
    "Spices (up)",
    "Lives",
    "Excites, with \"up\"",
    "Livens (up)",
    "Livens, with \"up\"",
    "Energizes, with \"up\"",
    "Fires (up)",
    "Enlivens, with \"up\"",
    "Perks (up)",
    "Picks (up)",
    "Livens (up)",
    "Vivifies, with \"up\"",
    "Livens (up)",
    "Picks (up)",
    "Enlivens, with \"up\"",
    "Stirs (up)",
    "Livens (up)",
    "Enlivens, with \"up\"",
    "Invigorates with \"up\"",
    "Enlivens, with \"up\""
  ],
  "gush": [
    "Say all sorts of nice things",
    "Effuse",
    "Overdo it on the praise",
    "Emote",
    "Sudden outburst",
    "Ooh and aah",
    "Wax rhapsodic",
    "Be oversentimental",
    "Opposite of ooze",
    "Effuse",
    "Talk effusively"
  ],
  "fiji": [
    "Major exporter of mineral water",
    "Country whose name is a brand of bottled water",
    "Island group whose name is a brand of water",
    "Part of Oceania [9]",
    "Country whose name is one letter different from a mountain",
    "Its flag includes an image of a cocoa pod",
    "Former British colony in the South Pacific",
    "Island neighbor of Tonga and Tuvalu",
    "Island that Truman wants to go to in \"The Truman Show\"",
    "The Yasawa Islands are part of it",
    "Land of 300+ islands",
    "Part of Captain Cook's explorations",
    "Pacific retreat",
    "Country with coups d'etat in 2000 and 2006",
    "Pacific island nation",
    "Vanuatu neighbor",
    "Golfer Vijay Singh's homeland",
    "Pacific island nation",
    "Land SW of Samoa",
    "Where Suva is",
    "Suva's land",
    "So-called \"crossroads of the South Pacific\"",
    "Pacific island getaway",
    "Suva's country",
    "Land in which men wear skirts called sulus",
    "South Pacific republic",
    "Big sugar exporter",
    "British colony until 1970",
    "South Seas getaway",
    "Big coconut exporter",
    "Suva is its capital",
    "Pacific nation",
    "Oceania republic"
  ],
  "msrp": [
    "Car sticker fig.",
    "Starting point for a car sale negotiation: Abbr.",
    "Car sticker abbr.",
    "Car sticker fig.",
    "Car sticker fig.",
    "Letters on an auto sticker",
    "Car sticker fig.",
    "Fig. on a window (and, with luck, not through the roof)",
    "Fig. often discounted",
    "Abbr. on a car sticker",
    "Window sticker fig.",
    "Car showroom sticker inits.",
    "Fig. near an m.p.g. rating",
    "Showroom window no.",
    "Dealer's amt.",
    "Car sticker fig.",
    "Car ad fig.",
    "Car sticker letters",
    "Sticker inits.",
    "Car dealer's starting point: Abbr.",
    "No. on a car",
    "Letters on a car sticker",
    "New car sticker fig.",
    "Sticker abbr.",
    "Letters on a new car sticker",
    "Amt. at a car dealership",
    "No. on a new-car sticker"
  ],
  "lait": [
    "Brest milk",
    "Drink for un bebe",
    "Milk: Fr.",
    "French vanilla ice cream ingredient",
    "Something to pour dans votre cafe",
    "Cafe additive",
    "Cafe au ___",
    "Product from une vache",
    "It might be found in a cafe",
    "Bebe's need",
    "The addition",
    "It may be pasteurise",
    "Brest milk",
    "Cafe lightener",
    "Addition to cafe",
    "Bebe's need",
    "Serving with gateau, maybe",
    "Chocolat au ___",
    "Bebe's intake",
    "Cafe au ___",
    "Starbucks menu word",
    "Cafe addition",
    "Milk, abroad",
    "Cafe au ___",
    "Bebe's intake",
    "Cafe lightener",
    "Cafe partner",
    "Cafe enhancer",
    "Cafe lightener",
    "Cafe additive",
    "Cafe au ___",
    "Cafe additive",
    "Cafe au ___",
    "Bebe fare",
    "Cafe additive",
    "Cafe au ___",
    "Cafe au ___",
    "Beverage from une vache",
    "Cafe cooler",
    "Cafe au ___",
    "Brest milk",
    "Cafe additive",
    "Cafe au lait",
    "Cafe addition",
    "Cafe additive",
    "Cafe au ___",
    "Cafe additive"
  ],
  "crag": [
    "Spot for an ibex",
    "Rocky outcropping",
    "Perch for a mountain goat",
    "Rock formation",
    "Handhold for a rock climber",
    "Ibex's perch",
    "Rock climber's challenge",
    "Climber's challenge",
    "Steep rock face",
    "Jagged cliff",
    "Rocky outcropping",
    "Steep, rugged cliff",
    "Cliff feature",
    "Mountain goat's perch",
    "Climber's handhold",
    "Rocky outcrop",
    "Scaling challenge",
    "Bluff bit",
    "Perch for a bighorn",
    "Peak projection",
    "Bit of a bluff",
    "Mountaineer's grip",
    "Escarpment",
    "Mountain climber's grip",
    "Mountain goat's spot",
    "Rugged rock formation",
    "Mountain goat's perch",
    "Rock outcropping",
    "Perch for an ibex",
    "Aerie area",
    "Rock climber's handhold",
    "Mountaineer's challenge",
    "Rugged rock",
    "Rugged rock",
    "Cliff feature",
    "Mountain goat's perch",
    "Rock climber's challenge",
    "What a climber may clutch",
    "Part of a cliff",
    "Rocky projection",
    "Outcropping",
    "Climber's rock",
    "Rugged rock",
    "Mountaineer's challenge",
    "Lookout point",
    "Spot on a cliff",
    "Rock projection",
    "Rugged rock",
    "Mountain goat's perch",
    "Prominence",
    "Outcropping",
    "Spot for a mountain goat",
    "Projecting rock",
    "Projecting rock",
    "Rocky height"
  ],
  "inga": [
    "Scandinavian name whose masculine equivalent ends in \"-var\"",
    "\"Young Frankenstein\" character played by Teri Garr",
    "\"Young Frankenstein\" role",
    "Actress Swenson of \"Benson\"",
    "Old TV actress Swenson",
    "Female role in \"Young Frankenstein\"",
    "Lab assistant in \"Young Frankenstein\"",
    "Swenson of \"Benson\"",
    "Teri Garr's role in \"Young Frankenstein\"",
    "Swenson of \"Benson\"",
    "\"Young Frankenstein\" assistant",
    "Woman who took a \"roll in ze hay\" in \"Young Frankenstein\"",
    "Swenson of \"Benson\"",
    "Actress Swenson",
    "Personal assistant in \"Young Frankenstein\"",
    "\"Young Frankenstein\" assistant",
    "Actress Cadranel of TV's \"Lost Girl\"",
    "\"Young Frankenstein\" character",
    "Lab assistant for Dr. Frederick Frankenstein",
    "\"Young Frankenstein\" girl",
    "Young Frankenstein married her",
    "\"Roll in ze hay\" enthusiast in \"Young Frankenstein\"",
    "\"Young Frankenstein\" role",
    "\"Young Frankenstein\" woman",
    "Actress Swenson of \"Benson\"",
    "Actress Swenson of \"Benson\"",
    "Prince in Baum's \"Rinkitink in Oz\"",
    "Swenson of \"Benson\"",
    "\"Young Frankenstein\" role",
    "Actress Swenson of \"Benson\"",
    "Teri Garr's \"Young Frankenstein\" role",
    "Prince in an L. Frank Baum \"Oz\" book",
    "Swenson of \"Benson\"",
    "TV's Swenson",
    "Swenson of \"Benson\"",
    "Teri Garr's \"Young Frankenstein\" role",
    "Actress Swenson",
    "Actress Swenson of \"Benson\"",
    "\"Young Frankenstein\" woman",
    "Swenson of \"Benson\"",
    "\"Young Frankenstein\" woman",
    "Actress Swenson",
    "TV's Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "L. Frank Baum prince",
    "Swenson of \"Benson\"",
    "Actress Swenson",
    "Actress Swenson",
    "Swenson of \"Benson\"",
    "Swenson of \"Benson\"",
    "Swenson of \"Benson\"",
    "Actress Swenson",
    "Swenson of \"Benson\"",
    "Swenson of \"Benson\"",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Actress Swenson",
    "Swenson of \"Benson\""
  ],
  "expo": [
    "Show with booths",
    "Fair",
    "Event at a convention center",
    "Fair",
    "Brand of markers",
    "Really big show",
    "Large fair, informally",
    "Big fair",
    "Trade show",
    "Brand of dry-erase markers",
    "Product showcase",
    "Fair",
    "Fair with booths",
    "Fair",
    "1967 Montreal event",
    "Business fair",
    "Fair",
    "World's fair, for short",
    "Event at a convention center",
    "Comic-Con, e.g.",
    "World's fair, e.g.",
    "Comic Con, for one",
    "World's fair, e.g.",
    "Place for a pavilion",
    "Montreal baseballer, once",
    "Major showcase",
    "Fair",
    "Event that may have a \"-con\" suffix in its name",
    "Fair",
    "World's fair",
    "World's fair, e.g.",
    "World's fair",
    "Big show",
    "Big show",
    "World's fair, e.g.",
    "Fair",
    "Fair",
    "Site of some unveilings",
    "Event with booths",
    "Int'l fair",
    "Place for a pavilion",
    "Fair",
    "Convention center event, for short",
    "World's fair",
    "Shanghai's ___ 2010",
    "Fair",
    "Giant fair",
    "Fair",
    "___ 67 (onetime Montreal event)",
    "Fair",
    "Major-league team member through 2004",
    "1967 Montreal attraction",
    "National, previously",
    "Brief display?",
    "Fair",
    "Big show",
    "Public show, for short",
    "Giant fair",
    "Really big show",
    "Fair",
    "Montreal N.L.'er",
    "Big show",
    "Really big show",
    "Big show",
    "Montreal ballplayer",
    "Major show",
    "Montreal player",
    "Fair",
    "Montreal player",
    "Olympic Stadium athlete",
    "Big show",
    "Olympic Stadium player",
    "Really big show",
    "World's fair",
    "Northern major-leaguer",
    "World's fair, for short",
    "Big showcase",
    "Montreal player",
    "Industrial show",
    "Fair",
    "Canadian baseballer",
    "Big showcase",
    "Fair"
  ],
  "dian": [
    "Gorilla expert Fossey",
    "Fossey who studied gorillas",
    "Gorilla watcher Fossey",
    "Anthropologist Fossey",
    "\"Gorillas in the Mist\" writer Fossey",
    "Fossey who was \"in the mist\"",
    "First name in 39-Down research",
    "Primatologist Fossey",
    "Longtime model Parkinson of \"The Price Is Right\"",
    "Anthropologist Fossey",
    "Primatologist Fossey",
    "Gorilla expert Fossey",
    "Longtime \"The Price Is Right\" model Parkinson",
    "Zoologist Fossey",
    "Zoologist Fossey",
    "Naturalist Fossey",
    "Gorilla watcher Fossey",
    "Fossey who did gorilla research",
    "Naturalist Fossey",
    "Gorilla researcher Fossey",
    "Model Parkinson",
    "Anthropologist Fossey",
    "Anthropologist Fossey",
    "Gorilla researcher Fossey",
    "Naturalist Fossey",
    "Zoologist Fossey",
    "Naturalist Fossey",
    "Gorilla researcher Fossey",
    "Primatologist Fossey"
  ],
  "grin": [
    "Cheshire cat's signature feature",
    "Toothy smile",
    "Beam",
    "Small beam",
    "D, in an emoticon",
    "___ and bear it",
    "Sheepish look",
    "Smile",
    "Beam",
    "Beam",
    "Smile",
    "Small beam?",
    "Smile",
    ":-), in an e-mail",
    "Beam",
    "Sheepish look, maybe",
    "Prankster's look",
    "Sign of satisfaction",
    "Meaning of the emoticon :-D",
    "Use one's zygomatic muscles",
    "Sign of sheepishness",
    "Expression for the Joker",
    "Wink accompanier",
    "Put on a happy face",
    ":D, in an e-mail",
    "Show pleasure",
    "Smile",
    "Small beam",
    "Smile",
    "Alfred E. Neuman feature",
    "Show of amusement",
    "Smile",
    "Sheepish look",
    "Beam",
    "Smile",
    "Alfred E. Neuman expression",
    "Friendly look",
    "Smile",
    "Happy face",
    "\"You got me!\" look",
    "Winner?Æs look",
    "Sheepish look",
    "Jack-o'-lantern feature",
    "It may be silly",
    "Mouth widener",
    "Beam",
    "Jack-o'-lantern feature",
    "Sheepish look",
    "Beam",
    "Show amusement",
    "Jack-o'-lantern feature",
    "Broad beam"
  ],
  "eggs": [
    "They run when they're broken",
    "Confident juggler's props",
    "Dozens of them are sold",
    "Items carried in spoons in an outdoor race",
    "Early birds?",
    "Fragile projectiles",
    "Easter basketful",
    "Brunch partner of 46-Down",
    "Baker's dozen?",
    "They're of little use unless they're cracked",
    "Vegan no-nos",
    "Nestful",
    "Things in an Easter basket",
    "Grade A items in the dairy aisle",
    "Parts of an ovo-lacto vegetarian's diet",
    "Custard base",
    "Objects of an Easter hunt",
    "Things dyed for Easter",
    "\"Sunny-side up\" order",
    "Quiche needs",
    "Waffle ingredients",
    "Ones in an annual hunt",
    "Nestful",
    "\"Grade A\" purchase",
    "Grocery staple",
    "Messy Halloween missiles",
    "Jeweled Faberge objects",
    "They're bought by the dozen",
    "Bakers' supply",
    "Neighborhood vandalism ammo",
    "Hens lay them",
    "Chicks hatch from them",
    "They're usually white or brown",
    "Orders at a 55-Down",
    "Drives (on)",
    "Future platypi",
    "With 14-Across, breakfast order?",
    "They often take a beating",
    "With spurs on",
    "Shell food?",
    "Coop finds",
    "Halloween purchase",
    "Custard ingredients",
    "Deviled things",
    "These may be coddled",
    "See 36-Across",
    "Dairy aisle purchase",
    "Adam and Eve, at a diner",
    "Protesters' ammo",
    "Baker's stock",
    "Coven : witches :: clutch : ___",
    "Order that may be scrambled or sunny-side up",
    "Prods, with \"on\"",
    "Souffle needs",
    "Caviar, essentially",
    "Sunnyside up servings",
    "They're found under layers",
    "Urges (on)",
    "Cartonful, maybe",
    "Baker's dozen?",
    "Omelet base",
    "Items in a 22-Across",
    "Food for snakes",
    "Dairy aisle section",
    "Poached edibles",
    "Roe",
    "Roe",
    "Breakfast choice",
    "Breakfast staple",
    "___ Benedict",
    "Things to sit on",
    "Baker's dozen",
    "Easter features",
    "Souffle sine qua non",
    "Hatchery items",
    "Spurs",
    "Urges, with \"on\"",
    "Breakfast order",
    "Taunts",
    "Custard base",
    "Hecklers' missiles",
    "Shirred items",
    "Quiche ingredients",
    "They'll be hunted in April",
    "Hash-house order",
    "Grocery buy"
  ],
  "isis": [
    "Goddess sister of Nephthys",
    "Goddess often depicted holding an ankh",
    "Egyptian goddess of life",
    "Goddess pictured with a solar disk above her",
    "Goddess wearing a vulture headdress",
    "Goddess depicted with horns",
    "Goddess represented wearing a solar disk",
    "Mother of Horus",
    "Title woman in a 1976 Dylan song",
    "Org. with a feared black-and-white flag",
    "Ancient mother goddess",
    "Egyptian goddess with a repetitive name",
    "\"Aida\" goddess",
    "Goddess with cow's horns",
    "Pompeii's Temple of ___",
    "Goddess with a throne headdress",
    "Hadji group, briefly",
    "Sunni jihadist grp.",
    "Mideast grp.",
    "Sister and wife of Osiris",
    "Memphis deity",
    "Egyptian goddess whose headdress was shaped like a throne",
    "Sun disk wearer, in myth",
    "Nile deity",
    "Member of the Ennead",
    "Cow-horned deity",
    "Nile deity",
    "Protector of the dead, in Egyptian myth",
    "Nile deity",
    "Goddess with a headdress depicting a throne",
    "Sister of Nephthys",
    "Mother of Horus, in Egyptian myth",
    "\"Aida\" chorus subject",
    "Fertility goddess",
    "Goddess who restored Osiris to life",
    "DC Comics superheroine",
    "Mother of Horus",
    "Mother goddess in Egyptian mythology",
    "Wife of Osiris",
    "Goddess depicted with a cow's horns",
    "Cow-headed goddess",
    "Goddess worshiped at the Temple of Philae",
    "Egyptian fertility goddess",
    "Goddess who wed her brother",
    "Goddess with cow's horns",
    "Egyptian fertility goddess",
    "Wife of Osiris",
    "Horned goddess",
    "Wife of Osiris",
    "Protector of the dead, in myth",
    "Mother of Horus",
    "Mother of Horus",
    "Egyptian fertility goddess",
    "Sister of Nephthys",
    "Egyptian fertility goddess",
    "Fertility goddess",
    "Egyptian goddess",
    "Egyptian deity",
    "Egyptian fertility goddess",
    "Egyptian fertility goddess",
    "Mother of Horus",
    "Goddess with the gift of life",
    "Fertility goddess",
    "Goddess in \"Aida\"",
    "Her symbol was a cow",
    "Nature goddess",
    "Egyptian fertility goddess",
    "Horned goddess",
    "Goddess of fertility",
    "Goddess pictured in Egyptian tombs",
    "Sister of Osiris",
    "Wife of Osiris",
    "Veil of ___ (mystery)",
    "Sister of Nephthys",
    "Cow-headed goddess",
    "Egyptian mother goddess",
    "Another name for the upper Thames",
    "Sister and wife of Osiris",
    "Mother of Horus",
    "Egyptian goddess",
    "Keb and Nut's daughter",
    "Osiris?Æs wife",
    "Horus's mother"
  ],
  "gent": [
    "Courteous chap",
    "Chap",
    "Fellow",
    "Bloke",
    "Refined chap",
    "Mannerly man",
    "Fellow",
    "Man who might tip his cap",
    "Lady's man",
    "Man of urbanity",
    "Hat tipper, maybe",
    "Mannerly man",
    "Lady's man",
    "Hat-tipping sort",
    "Fellow",
    "Squire",
    "Mannerly man, briefly",
    "Mannerly man",
    "Mannerly guy",
    "Lady's man",
    "Lady's partner",
    "Fellow",
    "Square dance partner",
    "Lady's partner",
    "Lady's escort",
    "Mannerly sort",
    "Mannerly sort",
    "Chap",
    "Hat-tipper, say",
    "Man with a top hat and cane",
    "Lady's man",
    "Lady's escort",
    "Lady's man",
    "Lady's man",
    "Lady's escort",
    "Chap",
    "Polite fellow",
    "Courteous chap",
    "Chap",
    "Lady's man",
    "Well-bred chap",
    "Lady's escort",
    "Squire",
    "Chap",
    "Lady's partner",
    "Bloke",
    "Courteous guy",
    "Urbane fellow",
    "Nob",
    "Lady's partner"
  ],
  "lego": [
    "Kind of block",
    "Simple building block",
    "Kind of block",
    "Toy celebrated at Brickworld conventions",
    "Brick made of acrylonitrile butadiene styrene",
    "Toy that hurts when you step on it barefoot",
    "Commercial lead-in to land",
    "Plastic brick brand",
    "Locking block",
    "Medium on display at Brickworld",
    "Small construction piece",
    "Fitting toy for a child?",
    "Small construction unit?",
    "Big name in building blocks",
    "Block maker",
    "Toy block brand",
    "Toy block brand",
    "Toy block brand",
    "Toy block brand",
    "Part of some small buildings",
    "Small construction company",
    "Toy behind several international theme parks",
    "Toy building brick",
    "Building block",
    "Playroom block",
    "Toy construction piece",
    "Theme of several theme parks",
    "Toy brick maker",
    "2014's \"The ___ Movie\"",
    "Kind of block",
    "Kind of brick",
    "Kid's block",
    "Building block brand",
    "Big name in construction",
    "Plastic brick brand",
    "Building block brand",
    "Plastic block brand",
    "Kind of block",
    "Brick maker",
    "Block brand",
    "It produces more than 20 billion bricks annually",
    "Brand of blocks",
    "Block brand",
    "Danish-based toy company",
    "Toy block brand",
    "Toy block brand",
    "Toy company whose name is an anagram of 124-Across",
    "Toy name preceding \"land\"",
    "Plastic building block",
    "Well-known maker of two-by-fours",
    "Construction company",
    "Toy block brand",
    "Brickmaking company",
    "Big name in blocks",
    "Brand of blocks",
    "Big name in construction",
    "Building toy brand",
    "Toy block maker",
    "Popular building block",
    "Theme park company",
    "Toy block company",
    "Block brand",
    "Plastic block maker",
    "Danish toy company",
    "Toy brand",
    "Building block brand",
    "Kind of block",
    "Toy block brand",
    "Building block",
    "Kind of block",
    "Toy building block",
    "Kind of blocks",
    "Big name in construction",
    "Kind of blocks",
    "___-mutton",
    "Building block company",
    "Lincoln Log competitor",
    "Brick maker",
    "Brand name that happens to be Latin for \"I read\"",
    "Big name in little blocks",
    "Kind of blocks",
    "Building block maker"
  ],
  "scab": [
    "Evidence of a recent scrape",
    "Wound up on top?",
    "Line crosser, of a sort",
    "Natural cover",
    "Sign of a wound's healing",
    "Strikebreaking worker",
    "Part of a natural repair process",
    "Picket line crosser",
    "Temporary cover",
    "Worker during a strike",
    "Striker's replacement",
    "One who crosses the line",
    "Provider of protective coverage",
    "Union concern",
    "One crossing the line?",
    "Sign of healing",
    "Strike fear?",
    "Persona non grata to a striker [1]",
    "One crossing the line?",
    "Cut covering",
    "Evidence of injury",
    "One who has crossed the line?",
    "One who crosses the line",
    "Sign of healing",
    "Healing cover",
    "Part of the healing process",
    "Fill-in for a striking worker",
    "Target of union hatred",
    "Wound protector",
    "Evidence of bodily harm",
    "One crossing a line",
    "Union opponent",
    "Union deserter, maybe",
    "Strikebreaker",
    "One crossing through the strike zone?",
    "Picket line crosser",
    "Very unpopular worker",
    "Sign of healing",
    "Healing formation",
    "Picket line crosser",
    "Worker during a walkout",
    "Walkout defier",
    "Cover for a wound",
    "Unpopular worker",
    "Wound protector",
    "Evidence of injury",
    "Strike defier",
    "One crossing a line",
    "Strikebreaker",
    "Union foe",
    "Object of a union's anger",
    "Union foe",
    "One who crosses the line?",
    "Line crosser?",
    "Healing sign",
    "Healing sign",
    "Picket line ignorer",
    "Union foe",
    "One who crosses the line?",
    "Wound cover",
    "One who's not out on called strikes",
    "Sign of healing",
    "Sore point?",
    "Sign of healing",
    "Object of union protest",
    "Sign of healing",
    "Fink",
    "Healing sign",
    "Sign of healing",
    "Striker's cry",
    "Picket line pariah",
    "Union betrayer",
    "Strikebreaker",
    "Sign of healing",
    "Strikebreaker",
    "Temporary protector",
    "Sign of healing"
  ],
  "prat": [
    "Lead-in to fall",
    "Boob, on the Tube",
    "Heinie",
    "Idiot, in British slang",
    "Fool, in British slang",
    "Idiot, in Britspeak",
    "Lead-in to fall",
    "Fool, in British slang",
    "Bonehead, to Brits",
    "Rear end",
    "Rear end",
    "Backside, slangily",
    "Something to fall on",
    "Burlesque landing spot",
    "Duff",
    "Rear end, in a fall",
    "Something to fall back on?",
    "Rear end, site of many falls",
    "Kind of fall",
    "Kind of fall",
    "Kind of fall"
  ],
  "lobo": [
    "Timber wolf",
    "Timber wolf",
    "Rebecca in the Basketball Hall of Fame",
    "University of New Mexico mascot",
    "University of New Mexico mascot",
    "Western wolf",
    "University of New Mexico symbol",
    "Timber wolf",
    "1970s-'80s TV sheriff",
    "Timber wolf",
    "Timber wolf",
    "Timber wolf",
    "New Mexico athlete",
    "Western wolf",
    "Animal that howls",
    "University of New Mexico athlete",
    "Gray wolf",
    "Western wolf",
    "Gray wolf",
    "Timber wolf",
    "Timber wolf",
    "Gray wolf",
    "Timber wolf",
    "Timber wolf",
    "Gray wolf",
    "\"Rio ___\" (John Wayne flick)",
    "Timber wolf",
    "See 4-Down",
    "Timber wolf",
    "Gray wolf",
    "Western wolf",
    "Gray wolf",
    "Western wolf",
    "Timber wolf",
    "Wolf, in Juarez",
    "Rockies animal",
    "Claude Akins TV role",
    "Western wolf",
    "Western livestock peril",
    "Wolf, in Juarez",
    "The gray wolf",
    "Western wolf"
  ],
  "came": [
    "Showed up",
    "Showed",
    "Showed up",
    "Arrived",
    "Arrived",
    "Hit town",
    "Arrived",
    "Showed",
    "Arrived",
    "Showed",
    "With 125-Across, got the booby prize",
    "Made the scene",
    "Derived (from)",
    "Checked in, say",
    "Showed up",
    "Advanced",
    "Arrived",
    "Showed",
    "Showed up",
    "Amounted",
    "Arrived",
    "Showed up",
    "Signed in, say",
    "Showed up",
    "Pulled in",
    "Amounted (to)",
    "Proceeded",
    "Was present",
    "Originated",
    "Arrived",
    "Drew (near)",
    "Arrived",
    "Showed up",
    "Pulled in",
    "Answered a summons",
    "Woke up, with \"to\"",
    "With 5-Down, approached",
    "Emanated",
    "Wasn't absent",
    "Occurred",
    "Showed up",
    "Arrived",
    "Arrived",
    "Showed up",
    "Arrived",
    "Showed up",
    "Blew in, so to speak",
    "Pulled into",
    "Arrived"
  ],
  "bonn": [
    "Capital of West Germany",
    "Beethoven's birthplace",
    "Capital of West Germany",
    "Beethoven's German birthplace",
    "Where Beethoven was born",
    "Cold War capital",
    "European capital until 1990",
    "Old capital of Europe",
    "Cold war capital",
    "Old 58-Down capital",
    "Capital of West Germany",
    "Bygone European capital",
    "West Germany's capital",
    "East Berlin's counterpart during the cold war",
    "World capital of 1949-1990",
    "Beethoven's birthplace",
    "Beethoven's birthplace",
    "City on the Rhine",
    "City near the Westerwald",
    "Beethoven's birthplace",
    "Rhine city",
    "Beethoven's birthplace",
    "Beethoven's birthplace",
    "West German capital",
    "Cold war capital",
    "Former German capital",
    "Former German capital",
    "Beethoven's birthplace"
  ],
  "jags": [
    "Bouts of crying, say",
    "Some pricey cars, for short",
    "Bouts, as of crying",
    "Sharp projections",
    "Pricey British cars, for short",
    "Sprees",
    "Sprees",
    "Some sports cars, informally",
    "Pricey cars, informally",
    "Some British sports cars, briefly",
    "Binges",
    "Sprees",
    "Sporty cars, briefly",
    "Flings",
    "Bouts",
    "Fancy sports cars, for short",
    "Certain sports cars, informally",
    "Some sports cars, for short",
    "Binges"
  ],
  "raze": [
    "Tear down",
    "Bring down",
    "Demolish",
    "Demolish",
    "Demolish",
    "Level",
    "Give a dynamite finish?",
    "Demolish",
    "Level, as with a wrecking ball",
    "Level",
    "Bulldoze",
    "Bring down the house?",
    "Level",
    "Bulldoze",
    "Knock down",
    "Word whose antonym is its own homophone",
    "Bulldoze",
    "Demolish",
    "Level",
    "Deconstruct?",
    "Demolish",
    "Demolish",
    "Demolish",
    "Bring down",
    "Completely demolish",
    "Level",
    "Tear down",
    "Demolish",
    "Level",
    "Bulldoze",
    "Level",
    "Tear down",
    "Flatten",
    "Level",
    "Level",
    "Demolish",
    "Demolish",
    "Level",
    "Flatten",
    "Level",
    "Level",
    "Tear down",
    "Completely demolish",
    "Flatten",
    "Bring the house down",
    "Bring down the house?",
    "Bring down the house",
    "Flatten",
    "Bring down the house",
    "Bring down the house",
    "Lay waste to",
    "Level",
    "Destroy",
    "Level",
    "Bulldoze"
  ],
  "pleb": [
    "Commoner",
    "Member of hoi polloi",
    "Common sort",
    "Proletarian",
    "Ordinary person",
    "Commoner",
    "Commoner",
    "Commoner",
    "Commoner, for short",
    "Commoner",
    "Commoner",
    "Commoner",
    "Everyman",
    "Commoner",
    "Roman commoner"
  ],
  "wnba": [
    "New York Liberty's org.",
    "Org. with Fire and Sparks",
    "Sun bloc?",
    "Org. for the Indiana Fever and the Atlanta Dream",
    "Org. for the Los Angeles Sparks and New York Liberty",
    "Org. for the New York Liberty",
    "Sun and Sky org.",
    "Lady hoopsters' org.",
    "Org. for the New York Liberty",
    "Sports org. with the teams Sun and Sky",
    "Org. with the New York Liberty",
    "The L.A. Sparks play in it",
    "Org. with the Sun and Sky",
    "Hoop grp.",
    "The Sun and Mercury are in it: Abbr.",
    "Los Angeles Sparks' org.",
    "Professional bouncers' org."
  ],
  "yaws": [
    "Turns sharply, as an aircraft",
    "Oscillates about a vertical axis",
    "Swerves at sea",
    "Doesn't quite go straight",
    "Deviations of a ship's course",
    "Goes off on a tangent?",
    "Swerves at sea",
    "Deviates at 74-Down",
    "Doesn't go straight",
    "Swerves, at sea",
    "Swerves at sea"
  ],
  "mitt": [
    "Something for a chef to have on hand?",
    "Baseball glove",
    "Baseball catcher",
    "Fly catcher",
    "Hand, informally",
    "Helpful thing to have on hand?",
    "Ball catcher",
    "Bit of baseball gear",
    "Barack's opponent in 2012",
    "Catcher's glove",
    "Something catching?",
    "Useful thing to keep on hand?",
    "2012 election name",
    "Barack's re-election opponent",
    "2012 political chant",
    "Politico Romney",
    "\"Leather,\" in baseball",
    "Hand, in slang",
    "Oven user's aid",
    "A baker might have a hand in it",
    "Baker's accessory",
    "Necessity when playing hardball",
    "Ball catcher",
    "Paw",
    "Ball catcher",
    "Fly-catching aid",
    "Baseball glove",
    "Hand protector",
    "Paw",
    "Yankee's wear",
    "Hand holder",
    "Fly catcher?",
    "Hand holder",
    "Baseball glove",
    "Glove",
    "Paw",
    "It has a pocket",
    "Berra had a hand in it",
    "Fly catcher",
    "Baseball glove",
    "Hand holder",
    "Piece of hazmat equipment",
    "Catcher's catcher",
    "Hand protector",
    "Rawlings product",
    "Dugout item",
    "Catcher's need",
    "Hand holder",
    "Pitcher's target",
    "Catcher's need",
    "Burn protection",
    "Hand holder?",
    "Paw",
    "Catcher's catcher",
    "Oven ___",
    "Item often kept on hand?",
    "Diamond target",
    "Catcher's catcher",
    "It may be catching",
    "Cook's protection",
    "Pitcher's target",
    "Hand holder",
    "Fly catcher",
    "Catcher's glove",
    "Chef's protector",
    "Paw"
  ],
  "cask": [
    "Winery container",
    "Container in a tasting room",
    "Cooper's creation",
    "Wine holder",
    "Container for amontillado",
    "Port vessel",
    "Wine barrel",
    "Distillery item",
    "Holder of spirits",
    "Poe's \"The ___ of Amontillado\"",
    "Poe's \"The ___ of Amontillado\"",
    "Cooper's handiwork",
    "Wine barrel",
    "Wine barrel",
    "Cellar item",
    "Port container",
    "Port container",
    "Wine vessel",
    "Vintner's container",
    "Port container",
    "Wine holder",
    "Cellar container",
    "Wine holder",
    "Wine holder",
    "Brewer's need",
    "Liquor holder",
    "Cooper's creation",
    "Vineyard container",
    "It has the spirit",
    "Hogshead",
    "Amontillado holder",
    "Wine shipment",
    "Hogshead"
  ],
  "zeke": [
    "\"___ and Luther\" (onetime Disney Channel sitcom)",
    "The Big Bad Wolf, in old cartoons",
    "\"The Wizard of Oz\" farmhand",
    "Farmhand in \"The Wizard of Oz\"",
    "First name of the wolf in Disney's \"The Big Bad Wolf\"",
    "\"The Wizard of Oz\" farmhand",
    "Farmworker in \"The Wizard of Oz\"",
    "Farmworker who became the Cowardly Lion in Dorothy's dream",
    "Basketball's Isiah Thomas, to fans",
    "Bratkowski in the Packers Hall of Fame",
    "The Cowardly Lion's Kansas counterpart",
    "Quarterback Bratkowski",
    "Common dog's name",
    "Cowardly Lion's name"
  ],
  "isla": [
    "Bit of land in the sea: Sp.",
    "___ Verde (locale of San Juan's airport)",
    "Piece of land in el oceano",
    "Gran Canaria or Mallorca, por ejemplo",
    "Tierra en el agua",
    "Majorca, e.g.: Sp.",
    "Mallorca, e.g.",
    "Actress Fisher",
    "Espanola, por ejemplo",
    "Mallorca o Menorca, por ejemplo",
    "Spanish key",
    "Actress Fisher",
    "Cuba, por ejemplo",
    "Any of the Galapagos, e.g.",
    "Part of las Filipinas",
    "La Espanola, for one",
    "Tenerife, por ejemplo",
    "Trinidad o Tobago",
    "Any of las Filipinas",
    "\"La ___ Bonita\" (Madonna hit)",
    "Spanish getaway locale",
    "Luzon, por ejemplo",
    "Any of the Baleares",
    "\"La ___ Bonita\" (1987 Madonna hit)",
    "Tierra surrounded by agua",
    "Santo Domingo is on one",
    "Cuba, por ejemplo",
    "Creta, e.g.",
    "Any of the Galapagos",
    "Luzon, e.g.",
    "Cancun, por ejemplo",
    "___ de Margarita",
    "Puerto Rico, e.g.",
    "Tierra en el agua",
    "Jamaica, por ejemplo",
    "Cuba, por ejemplo",
    "Venezuela's ___ Margarita",
    "Luzon, e.g.",
    "La Palma, e.g.",
    "Cuba, por ejemplo",
    "Part of un archipielago",
    "Espanola, e.g.",
    "Gran Bretana, e.g.",
    "Mallorca, e.g.",
    "Puerto Rico, por ejemplo",
    "Cuba, e.g., to Cubans",
    "Chile's ___ de Pascua",
    "Espanola, for one",
    "Cuba, por ejemplo",
    "Mallorca or Menorca, por ejemplo",
    "\"La ___ Bonita\" (Madonna song)",
    "Spot en el mar",
    "La Espanola, e.g.",
    "It's surrounded by agua",
    "Spanish vacation site",
    "Land in un lago",
    "Puerto Rico, e.g.",
    "Cuba, por ejemplo",
    "Madonna's \"La ___ Bonita\"",
    "Destinacion de vacaciones",
    "Gran Canaria, for one",
    "Ibiza, e.g.",
    "Cuba, por ejemplo",
    "Land in el mar",
    "Margarita, e.g.",
    "___ Margarita, in the Caribbean",
    "Ibiza, e.g., to Spaniards",
    "Mallorca, e.g.",
    "Vacation place for Carlo",
    "Spanish key",
    "Madonna's \"La ___ Bonita\"",
    "Hispaniola, e.g.",
    "Mallorca, e.g.",
    "Cuba, por ejemplo",
    "___ Mujeres (neighbor of Cancun)",
    "Mallorca, e.g.",
    "Cuba or Mallorca",
    "Mallorca or Menorca, e.g.",
    "___ de Leon, Spain",
    "Tierra del Fuego, e.g.",
    "Hispaniola, por ejemplo",
    "Mallorca, e.g.",
    "Cuba, por ejemplo",
    "Any of the Galapagos",
    "Madonna's \"La ___ Bonita\"",
    "Mallorca, e.g.",
    "___ Mujeres, Mexico",
    "Spain's Ibiza, e.g.",
    "Mallorca, e.g.",
    "Cuba o Puerto Rico",
    "Mallorca, e.g.",
    "Mallorca, por ejemplo",
    "___ Mujeres, Mexico"
  ],
  "peas": [
    "Challenging vegetables to eat with a fork",
    "They're often served mushy in England",
    "Food that may be pureed for babies",
    "Samosa ingredients",
    "Subject of early genetics research",
    "Shepherd's pie ingredients",
    "Small, spherical vegetables",
    "Stew morsels",
    "Pod fillers",
    "Some baby food",
    "Common tidbits in fried rice",
    "Wasabi ___ (bar snack)",
    "9-Down bits",
    "Birds Eye bagful",
    "Wasabi ___ (bar snack)",
    "Pasta primavera ingredients",
    "Snow ___",
    "Snow ___",
    "Food items catapulted with a spoon, maybe",
    "Snow ___",
    "Black-eyed ___",
    "Pod contents",
    "Green Giant bagful",
    "Carrots' plate-mates",
    "Samosa ingredients",
    "Shepherd's pie ingredients",
    "Accompaniers of carrots in a Birds Eye package",
    "Little vegetables that roll",
    "Fried rice ingredients",
    "Pod contents",
    "What \"matar\" means on an Indian menu",
    "Row in a garden",
    "Goober ___",
    "\"Split\" soup ingredients",
    "Pod contents",
    "Potpie ingredients",
    "Mushy ___ (British dish)",
    "Pod contents",
    "Items split in soup",
    "Gregor Mendel research subject",
    "Some have black eyes",
    "Shepherd's pie ingredients",
    "Stew ingredients",
    "Stew veggies",
    "Vegetables that roll",
    "They're split for soup",
    "Potpie ingredients",
    "They may be split",
    "Row in a shell?",
    "Black-eyed ones",
    "Exemplars of twinship",
    "\"Sweet\" things",
    "Shooters may need them",
    "They can be sweet",
    "They can be sweet",
    "Exemplars of twinship",
    "Potpie ingredients",
    "They may be strained in young families",
    "Subjects of Mendelian experiments",
    "Some are split",
    "Split ___",
    "Shooter's ammo",
    "Stew ingredients",
    "Items hard to eat with a knife",
    "Stew vegetables",
    "Like ___ in a pod",
    "Kid's ammo",
    "They're often split",
    "Pierre's pois",
    "They're sometimes split",
    "Vegetables",
    "Birds Eye product",
    "Child's ammo"
  ],
  "soak": [
    "Lo-o-ong bath",
    "Take a lo-o-ong bath",
    "Do a prewashing job",
    "Enjoy a nice long bath",
    "Boozehound",
    "Take a long bath",
    "Spa session",
    "Washing machine cycle",
    "Jacuzzi session",
    "Overcharge",
    "Get into some hot water?",
    "Overcharge",
    "Overcharge and how",
    "Enjoy a tub",
    "Good, long bath",
    "Good, long bath",
    "Impregnate",
    "Fleece",
    "Loll in a tub",
    "Take to the cleaners",
    "Marinate",
    "Overcharge",
    "Toper",
    "Drench",
    "Get really wet",
    "Marinate",
    "Overcharge",
    "Saturate",
    "Drunkard",
    "Relaxing bath",
    "Gouge",
    "Long bath",
    "Long bath",
    "Take to the cleaners",
    "Saturate",
    "Steep",
    "Saturate",
    "Charge too much",
    "Overcharge, slangily",
    "Good, long bath",
    "Tarry in a tub",
    "Laze in the tub",
    "Overcharge",
    "Good long bath",
    "Clean, as dentures",
    "Drench",
    "Immerse",
    "Saturate",
    "Good, long bath",
    "Steep"
  ],
  "eyre": [
    "Brontë's \"Jane ___\"",
    "Literary character who says \"I will be myself\" to Mr. Rochester",
    "Bronte's \"Jane ___\"",
    "\"Jane ___\"",
    "Bronte's \"Jane ___\"",
    "Bronte heroine",
    "Jane of British lit",
    "Lake ___, Australia's lowest point",
    "Literary governess",
    "Literary heroine who says \"Even for me life had its gleams of sunshine\"",
    "Governess at Thornfield",
    "Jane who falls for Edward Rochester",
    "Jane Rochester, nee ___",
    "Literary character self-described as \"poor, obscure, plain and little\"",
    "Literary governess",
    "Jane who said \"I can live alone, if self-respect and circumstances require me so to do\"",
    "Orphan of British literature",
    "Fictional hiree at Thornfield",
    "Literary Jane who says \"No net ensnares me; I am a free human being with an independent will\"",
    "Bronte's \"Jane ___\"",
    "Literary Jane",
    "Lake ___ (Australia's lowest point)",
    "\"The ___ Affair\" (Jasper Fforde novel)",
    "Jane who becomes Mrs. Rochester",
    "Bronte title heroine",
    "See 7-Down",
    "Bronte heroine",
    "Bronte governess",
    "Lake ___ (largest lake in Australia)",
    "Literary Jane",
    "Bronte's \"Jane ___\"",
    "Literary character who says \"Gentle reader, may you never feel what I then felt!\"",
    "See 51-Across",
    "Bronte heroine",
    "Attendee of the fictional Lowood Institution for girls",
    "Literary governess's surname",
    "Bronte heroine",
    "\"Gentle reader, may you never feel what I then felt!\" speaker",
    "Fictional Jane",
    "Jane of literature",
    "Heroine who says \"I resisted all the way: a new thing for me\"",
    "Name abandoned for Rochester",
    "\"Jane ___\"",
    "Australia's Lake ___ National Park",
    "\"Reader, I married him\" heroine",
    "Bronte's \"Jane ___\"",
    "Jane at Thornfield",
    "Rochester's love",
    "Bronte's Jane",
    "\"Notes on a Scandal\" director Richard",
    "Rochester's beloved governess",
    "Bronte's Jane",
    "Fictional governess",
    "Orphan of literature",
    "Jane Rochester's maiden name",
    "Bronte heroine",
    "Bronte heroine",
    "Jane who loved Rochester",
    "Fictional governess",
    "Bronte heroine",
    "\"Jane ___\"",
    "Lake ___, lowest point in Australia",
    "Bronte heroine",
    "Largest lake in Australia",
    "Bronte heroine",
    "Bronte heroine",
    "Literary governess",
    "Jane who was courted at Thornfield Hall",
    "Bronte's \"Jane ___\"",
    "Jane of a Bronte novel",
    "Fictional Jane",
    "Bronte governess",
    "Rochester's love",
    "Lake ___, lowest point in Australia",
    "Literary governess",
    "Bronte heroine",
    "Fictional Jane",
    "Bronte heroine",
    "Orphan of literature",
    "Fictional Jane",
    "Bronte heroine",
    "Bronte's Jane",
    "Bronte's \"Jane ___\"",
    "Bronte heroine",
    "Thornfield Hall governess",
    "Rochester's love",
    "Fictional orphan",
    "Rochester's love",
    "Salt lake of South Australia",
    "Bronte heroine",
    "Fictional governess",
    "See 64-Across",
    "Fictional Jane",
    "\"Jane ___\"",
    "Bronte heroine",
    "Thornfield governess",
    "Australia's Lake ___",
    "A plain Jane",
    "Australia's largest lake",
    "Fictional Jane",
    "Bronte woman",
    "Fictional Jane",
    "Bronte heroine",
    "Strong-willed Jane",
    "Jane who becomes Mrs. Rochester",
    "Fictional surname of 1847",
    "Plain Jane",
    "Jane who stayed at Thornfield",
    "Heroine of 1847",
    "Bronte heroine",
    "Australia's largest lake",
    "Young Fontaine role",
    "Bronte heroine",
    "Jane who loved Mr. Rochester",
    "Bronte heroine",
    "Fictional Jane",
    "Governess of Thornfield",
    "Bronte heroine",
    "Rochester's Jane",
    "\"Jane ___\"",
    "Rochester's beloved",
    "Gothic governess",
    "Literary homophone for 99-Across",
    "\"Jane ___\"",
    "Rochester's beloved",
    "Rochester's beloved"
  ],
  "nosh": [
    "Quick bite to eat",
    "Quick snack",
    "Bite between meals",
    "Have a bite",
    "Little something to eat",
    "Small bite, say",
    "Light bite",
    "Nibble (on)",
    "Snack (on)",
    "Munch between meals",
    "Snack",
    "Bite",
    "Between-meal bite",
    "Snack",
    "Quick bite",
    "Munch on chips, say",
    "Little something",
    "Bite",
    "Have a knish, say",
    "Light snack",
    "Little something",
    "Have a little something",
    "Bite",
    "Have a little something",
    "Snack",
    "Snack",
    "Twinkies or cookies, e.g.",
    "Between-meal bite",
    "Cheat on a diet",
    "Bite",
    "Diet breaker, maybe",
    "Bag of chips, maybe",
    "Bag of chips, say",
    "Munch on chips, maybe",
    "Chips and dips, say",
    "Snack",
    "Snack (on)",
    "Nibble",
    "Have some chips, say",
    "Nibble",
    "Bite",
    "Cheat on one's diet",
    "Bite",
    "Nibble",
    "Snack",
    "Bite",
    "Bite",
    "Snack",
    "Raid the refrigerator",
    "Quick bite",
    "Have a blintz, maybe",
    "Snack"
  ],
  "dais": [
    "Platform for a ceremony",
    "Place for a throne",
    "Speaker's platform",
    "Oration station",
    "Stand taken by a speaker?",
    "Place for speakers",
    "Speaker's platform",
    "Stand for a speaker",
    "Speaker's spot",
    "Place for a prize ceremony",
    "Rostrum",
    "Place for a throne",
    "Place for a throne",
    "Head table's place",
    "Lectern locale",
    "Locale for a speaker and honorees",
    "Roast locale",
    "Recitation station",
    "Speaker's place",
    "Speaker's stand",
    "Roast setting",
    "Stand taken by a debater",
    "Lectern's locale",
    "Talking point?",
    "Honoree's place",
    "Honoree's spot",
    "Setting for a roaster",
    "Speech setting",
    "Emcee's spot",
    "What many audiences face",
    "V.I.P. locale",
    "Locale for a seat of honor",
    "Setting for an Inaugural Address",
    "Speaker's place",
    "Place for a lectern",
    "Place for a panel",
    "Roast setting",
    "Speech spot",
    "Place of honor",
    "Place for a seat of honor",
    "Speaking spot",
    "Honoree's spot",
    "Toaster setting?",
    "Honoree's place",
    "Talking point?",
    "It may be a step up",
    "Speaker's place",
    "Honoree's spot",
    "Place for a throne",
    "Where an honoree may sit",
    "Honored guest's spot",
    "Roaster's spot",
    "Honored guest's site",
    "Roast spot",
    "Roaster's spot",
    "Oration location",
    "Speaker's platform",
    "Platform for a guest of honor",
    "Roasting platform",
    "Honoree's locale",
    "Oration station",
    "Roaster's spot",
    "Oration station",
    "Seat of honor location",
    "Campaigner's stand",
    "Speaker's spot",
    "Place for 39-Across",
    "Place of honor",
    "Speaker's spot",
    "Speaker's spot",
    "Stand in ceremony?",
    "Place for a lecture",
    "Performance site",
    "Seat setting",
    "Roast site",
    "Roasting platform",
    "Place for a throne",
    "Speaker's place",
    "Speaker's platform",
    "Speaker's platform",
    "Site for a seat of honor",
    "Honoree's spot",
    "Roaster's place?",
    "Site for a seat of honor",
    "Seat of honor locale",
    "Throne site",
    "Roaster's place",
    "Platform",
    "Orator's perch",
    "Speaker's stand",
    "Speaker's spot",
    "Speech site",
    "Speaker's platform"
  ],
  "jump": [
    "Follower of hop and skip",
    "Sharp increase",
    "Word with seat or suit",
    "Sudden start",
    "Bound",
    "Stalled driver's request",
    "Hurdle",
    "Word that can precede each set of circled letters, forming a literal hint for entering certain answers in this puzzle",
    "Leap",
    "A.A.A. offering",
    "Parachutist's act",
    "Parachute ___",
    "Ambush",
    "Start, as a dead battery"
  ],
  "doth": [
    "\"The lady ___ protest too much\": \"Hamlet\"",
    "Biblical verb",
    "\"The fool ___ think he is wise\": Shak.",
    "Performs, to Shakespeare",
    "\"The lady ___ protest too much\"",
    "\"The lady ___ protest too much\": Shak.",
    "\"The lady ___ protest too much\"",
    "Obsolete auxiliary",
    "\"How then ___ he now see?\": John 9:19",
    "Archaic auxiliary",
    "\"The lady ___ protest ...\"",
    "Shakespearean verb",
    "\"How ___ the little busy bee...\"",
    "Performs, for King James",
    "Biblical verb",
    "\"The lady ___ protest too much\": Shak.",
    "Biblical verb",
    "Does once?"
  ],
  "mgrs": [
    "People in charge: Abbr.",
    "Supervisors: Abbr.",
    "Corp. V.I.P.'s",
    "Supermarket V.I.P.'s: Abbr.",
    "Baseball team V.I.P.'s: Abbr.",
    "Some hirers: Abbr.",
    "Nonunion employees: Abbr.",
    "Bus. bosses",
    "Major League V.I.P.'s",
    "Torre and Valentine: Abbr.",
    "Baseball bosses: Abbr."
  ],
  "jude": [
    "\"Hey ___\" (Beatles hit)",
    "He was told to \"take a sad song and make it better\"",
    "Patron saint of lost causes",
    "Title fellow in a Beatles song",
    "Revelation comes after it",
    "Law with many parts",
    "The Beatles' \"Hey ___\"",
    "Penultimate book of the Bible",
    "Title fellow in a Beatles song"
  ],
  "fake": [
    "Like many Rolex watches sold on the street",
    "Like bot accounts on social media",
    "Like C.G.I.",
    "Like an orange tan, say",
    "Embarrassment for an art curator",
    "Like many Rolex watches sold on the street",
    "Not real",
    "Clifford Irving's \"Autobiography of Howard Hughes,\" e.g.",
    "Like knockoff goods",
    "Like some lashes and tans",
    "Forgery",
    "Handoff that isn't",
    "What some consider the 38-Across to be",
    "With 24-Across, bluff",
    "Impostor",
    "Like a flea-market Gucci, probably",
    "Deceptive play",
    "Sham",
    "False move",
    "Counterfeit",
    "Faux",
    "Like some furs",
    "Mountebank",
    "Sham"
  ],
  "term": [
    "Six years, for a U.S. senator",
    "Normal length of a pregnancy",
    "Word with paper or limit",
    "Life is a long one",
    "Six years, for a U.S. senator",
    "___ limits (political issue)",
    "Bring to ___",
    "A U.S. senator's is six years",
    "Politician's time",
    "Contract period",
    "Life, for one",
    "Life is a bad one",
    "Semester, e.g.",
    "Contract stipulation",
    "Five years, for the U.N. secretary general",
    "Four years, for a president",
    "Describe as",
    "Nine-month pregnancy",
    "Semester, e.g.",
    "Stretch before giving birth",
    "Prisoner's sentence",
    "Semester, e.g.",
    "President's four years, e.g.",
    "Semester, e.g.",
    "Six years, for a U.S. senator",
    "Four years, for a president",
    "Four years, for a U.S. president",
    "Kind of life insurance",
    "Six years, for a 23-Across",
    "Stretch in a seat",
    "___ limits (election issue)",
    "Six years, for a senator",
    "Time in a seat",
    "Semester",
    "Time spent in a seat",
    "Lexicographer's study",
    "Four years for a U.S. president",
    "Course",
    "Period in office",
    "Presidential time",
    "Glossary entry",
    "Semester, e.g.",
    "Prison sentence",
    "Kind of paper",
    "Time in office",
    "Glossary part",
    "Something to serve",
    "School session",
    "Contract stipulation",
    "Five to ten, e.g.",
    "Six years, in the 46-Across",
    "Two years in the House",
    "Four years, maybe",
    "Carter had one",
    "Duration",
    "Insurance policy specification",
    "Six years, for a senator",
    "Period",
    "___ limit",
    "What-you-may-call-it?",
    "School session",
    "Presidential run",
    "Semester",
    "Stretch",
    "Time for a President or a convict",
    "Office time",
    "Life is a long one",
    "Designation",
    "Life, for one",
    "Four years, for a President"
  ],
  "util": [
    "Electricity or water, e.g.: Abbr.",
    "Abbr. that rhymes with \"bill,\" appropriately",
    "Gas or electric: Abbr.",
    "Elec., e.g.",
    "Water, e.g.: Abbr.",
    "Monopoly property: Abbr.",
    "Elec. or water",
    "Sewage co., e.g.",
    "Con Ed, e.g.: Abbr.",
    "Monthly expense: Abbr.",
    "Elec., e.g.",
    "Water co., e.g.",
    "Gas, e.g.: Abbr.",
    "Power co.",
    "Monopoly buy: Abbr.",
    "Electric or water co.",
    "Regulated bus.",
    "Gas, e.g.: Abbr.",
    "Govt.-regulated business",
    "Elec., e.g.",
    "Certain Monopoly sq.",
    "Gas, e.g.: Abbr.",
    "Monopoly square: Abbr.",
    "Gas, say: Abbr.",
    "Water supplier, e.g.: Abbr.",
    "Electricity or water: Abbr.",
    "Gas, e.g.: Abbr.",
    "Subj. of state regulation",
    "Cautious stock inv.",
    "Monopoly purchase: Abbr.",
    "Gas or elec., e.g.",
    "Elec. company, e.g.",
    "Gas or elec. co."
  ],
  "cats": [
    "2019 box-office flop described by one critic as \"Les Meowsérables\"",
    "Musical featuring the Jellicle Ball",
    "2019 musical film with a substantial C.G.I. component",
    "Pets that purr",
    "Purrers",
    "Musical with Rum Tum Tugger",
    "Burmese and Himalayans",
    "Jazzmen",
    "Jazz musicians",
    "*Jazz devotees, informally",
    "1983 Tony-winning musical",
    "Meowers",
    "Lions and tigers",
    "Musical with Mungojerrie and Jennyanydots",
    "Meowers",
    "Broadway smash whose poster image consisted of just two eyes",
    "Tigers, e.g.",
    "Jazz devotees",
    "Boppers",
    "Burmese and others",
    "Musical with the song \"Mr. Mistoffelees\"",
    "Hipsters",
    "Cool guys",
    "Tabbies",
    "Meowers",
    "Longest-running show in Broadway history until 1/9/06",
    "Jaguars, e.g.",
    "Alley tally",
    "See 3-Down",
    "Broadway hit with 7,000+ performances",
    "Noted Broadway debut of 10/7/82",
    "Broadway debut of 10/7/82",
    "\"Cool\" ones",
    "Be-boppers",
    "Noted debut of 10/7/82",
    "See 48-Down",
    "Lions and tigers",
    "Be-boppers",
    "\"Memory\" musical",
    "See 46-Across",
    "Hipsters",
    "Notable opening of 10/7/82",
    "Familiars, often",
    "Siamese and Persian",
    "Jivers",
    "Long-running Broadway show",
    "Jive men",
    "1983 Tony musical",
    "\"Jellicle Ball\" musical",
    "Musical with the song \"Memory\"",
    "Musical that opened 10/7/82"
  ],
  "tnut": [
    "Fastener that leaves a flush surface",
    "Certain bolt holder",
    "Threaded fastener",
    "Letter-shaped fastener",
    "Bit of letter-shaped hardware",
    "Hardware fastener",
    "Flanged fastener",
    "Letter-shaped fastener",
    "Fastener with a flange",
    "Letter-shaped fastener",
    "Fastener named for its shape",
    "Metal fastener",
    "Letter-shaped fastener",
    "Flush fastener",
    "Hardware bit",
    "Fastener with a flange",
    "Letter-shaped fastener",
    "Letter-shaped metal fastener",
    "Fastener designed to leave a flush surface",
    "Letter-shaped fastener",
    "Letter-shaped fastener",
    "Hardware bit",
    "Carpentry fastener",
    "Flanged fastener",
    "Letter-shaped fastener",
    "Letter-shaped bolt fastener",
    "Letter-shaped fastener",
    "Carpentry fastener",
    "Letter-shaped fastener",
    "Letter-shaped fastener",
    "Flanged fastener",
    "Hardware bit",
    "Wide-headed fastener",
    "Carpentry fastener",
    "Letter-shaped fastener",
    "Hardware fastener",
    "Letter-shaped fastener",
    "Construction bit",
    "Wide-headed fastener",
    "Threaded holder",
    "Bit of hardware",
    "Carpenter's metal piece",
    "Letter-shaped hardware item",
    "Bolt holder",
    "Letter-shaped fastener",
    "Threaded fastener",
    "Letter-shaped fastener",
    "Threaded holder",
    "Hardware item",
    "Threaded fastener",
    "Bolt holder",
    "Threaded metal fastener",
    "Threaded metal fastener",
    "Construction piece",
    "Carpenter's fastener",
    "Item in a carpenter's kit",
    "Hardware item",
    "Hardware item"
  ],
  "tall": [
    "Lofty",
    "Like some orders",
    "Like people who are much looked up to",
    "Like Wookiees",
    "Like tales you can't believe",
    "Like most centers in basketball",
    "Always bumping one's head on doorways, say",
    "One way to stand",
    "Ironically, small Starbucks size",
    "Skyscraping",
    "Fictitious",
    "With 32-Across, whopper",
    "One way to walk",
    "With one's head in the clouds?",
    "Kind of order",
    "Like some orders or tales",
    "Unbelievable, say",
    "Men's suit specification",
    "Starbucks size",
    "Like basketball centers",
    "Like Dubai's Burj Khalifa",
    "Skyscraping",
    "Towering",
    "Like a difficult order",
    "Like most N.B.A. players",
    "One way to stand",
    "Like Magic?",
    "Seven-foot, say",
    "Like an N.B.A. center",
    "One way to stand",
    "Like some orders",
    "Albee's \"Three ___ Women\"",
    "Unbelievable",
    "Hard to believe",
    "High",
    "Hard to believe",
    "Looked up to?",
    "Towering",
    "Built like Wilt",
    "Bumping one's head on the ceiling, say",
    "Farfetched",
    "Towering",
    "Like most N.B.A. stars",
    "Like some orders",
    "Unbelievable",
    "Like some tales or orders",
    "One way to stand",
    "Like skyscrapers",
    "Fanciful",
    "Seven-foot, e.g.",
    "Farfetched",
    "Like a six-footer",
    "Unbelievable",
    "Like most basketball stars",
    "One way to stand",
    "Like giants",
    "Like many an order",
    "Exaggerated, as a story",
    "Like a seven-footer",
    "Like most N.B.A. players",
    "Ready to be mowed, as grass",
    "Like some stories",
    "Six-foot or more",
    "Towering",
    "Seven-foot, e.g.",
    "Seven-foot, e.g.",
    "Rangy",
    "Kind of order",
    "Improbable",
    "Six-foot two, for example",
    "Dark and handsome companion",
    "Hard to believe",
    "Statuesque",
    "Hard to believe",
    "Fanciful, as a story"
  ],
  "mars": [
    "What two Vikings have explored",
    "Land of Opportunity?",
    "Destination of the rover Perseverance",
    "What areology is the study of",
    "Counterpart of Venus",
    "Home to the 72,000-foot volcano Olympus Mons",
    "Neighbor of the asteroid belt",
    "Mount Olympus, at 72,000 feet, is its highest peak",
    "Milky Way maker",
    "Opportunity's on it",
    "Where Matt Damon was stranded in a 2015 film",
    "Chocolate treat since 1932",
    "Curiosity's locale",
    "Where Spirit landed in 2004",
    "Twix maker",
    "Target of NASA's Rover mission",
    "Setting of the 2012 film \"John Carter\"",
    "Roman 18-Across",
    "Fourth rock from the sun",
    "Maker of 31-Across",
    "\"The Sands of ___\" (Arthur C. Clarke's first science fiction novel)",
    "Milky Way maker",
    "Viking's destination",
    "Rover's home",
    "Phoenix landing site, 2008",
    "Location of Olympus Mons",
    "Home of the invaders in Wells's \"The War of the Worlds\"",
    "Viking landing site",
    "Deimos orbits it",
    "Neighbor of Earth",
    "___ rover",
    "Snickers alternative",
    "Land of Opportunity?",
    "Twix candy maker",
    "Maker of Twix and Starburst",
    "Pathfinder explored it",
    "Viking's destination",
    "\"Invaders From ___\" (1953 sci-fi)",
    "Pathfinder's target",
    "Sci-fi setting",
    "Phobos orbits it",
    "Third-smallest of nine",
    "\"Total Recall\" setting",
    "One of the worlds in \"The War of the Worlds\"",
    "Where the Vikings landed",
    "Global Surveyor's destination",
    "Milky Way maker",
    "\"The War of the Worlds\" base",
    "Olympus Mons site",
    "Sojourner's resting place",
    "Either \"M\" of M&M's",
    "Pathfinder's locale",
    "Olympus Mons's locale",
    "\"The War of the Worlds\" base",
    "Kind of bar",
    "Snickers company",
    "\"War of the Worlds\" base camp",
    "Damages",
    "___ Observer (1992 mission)",
    "Flash Gordon serial site",
    "Viking touchdown site"
  ],
  "iced": [
    "Coffee order specification",
    "Like some coffee and sprains",
    "___ coffee",
    "Shunned, with \"out\"",
    "Chilled",
    "Coffee specification",
    "___ tea",
    "Coffee order",
    "Finished, as a cake",
    "Like some sprains and tea",
    "Like some sprains and champagnes",
    "Clinched",
    "___ tea (summer drink)",
    "Finished up, as cupcakes",
    "Wasted",
    "Coffee shop menu adjective",
    "Bumped off",
    "Put away",
    "Sewn up",
    "Tended to, as a strain",
    "Covered in frosting",
    "Whacked",
    "Treated, as a sprained ankle",
    "One way to serve coffee",
    "Put away",
    "Stuck in the freezer",
    "Like many cakes",
    "With 21-Across, like many rivers in winter",
    "Treated, as a sprained ankle",
    "Put away",
    "Clinched",
    "Like some twisted ankles",
    "Hit",
    "On the rocks",
    "Put away",
    "Whacked",
    "Like some coffee",
    "Like birthday cakes",
    "Kind of tea",
    "Knocked off, in a way",
    "Prevented from swelling, maybe",
    "Clinched",
    "Like a gin fizz",
    "Put away",
    "Sewed up",
    "Clinched",
    "Sewed up",
    "Decorated, as a cake",
    "Coffee choice",
    "Like some coffee",
    "Like summer drinks",
    "Bumped off",
    "Clinched",
    "Put away",
    "Clinched",
    "Secured, as a deal",
    "Like shrimp during shipping",
    "Clinched",
    "Frigid",
    "What a swollen joint should be",
    "Topped a torte",
    "Like some coffee",
    "One way to serve coffee",
    "Wasted",
    "Treated a sprain, perhaps",
    "Frosted",
    "Sewed up",
    "Bumped off",
    "Sewed up",
    "Like some drinks",
    "Bumped off",
    "Kind of tea",
    "Clinched, as a victory",
    "Like some tea",
    "Like white wine at a restaurant",
    "Decorated, as a cake",
    "Chilled the Chablis",
    "Like an eclair",
    "Like most cupcakes",
    "Like summer tea",
    "In the cooler",
    "Ensured",
    "Like many cakes",
    "Like some tea",
    "Settled, as a deal",
    "Froze"
  ],
  "moto": [
    "\"Hello ___\" (old cellphone ad line)",
    "Con ___ (with animation, musically)",
    "Mr. of detective fiction",
    "Mr. ___ (fictional sleuth)",
    "Mr. ___ (Marquand sleuth)",
    "Mr. ___ (Peter Lorre role)",
    "Sporting lead-in to cross",
    "Mr. ___ (Peter Lorre role)",
    "Sleuth played by Lorre",
    "\"Right You Are, Mr. ___\" (1957 novel)",
    "Mr. ___ (Peter Lorre film sleuth)",
    "Mr. ___, Japanese sleuth",
    "Mr. ___, John P. Marquand detective",
    "Mysterious Mr.",
    "Japanese sleuth Mr. ___",
    "Con ___ (animatedly, in music)",
    "John P. Marquand detective",
    "Mr. of mysteries",
    "Marquand's Mr.",
    "Con ___ (rather fast, in music)",
    "Marquand sleuth",
    "\"Think Fast, Mr. ___\" (1937 mystery)",
    "Repeated role for Lorre",
    "Marquand's Mr. ___",
    "Peter Lorre role Mr. ___",
    "Role for Lorre",
    "Fictional detective",
    "Marquand sleuth",
    "Lorre role"
  ],
  "pion": [
    "Subatomic particle",
    "Zero-spin particle",
    "Subatomic particle",
    "Subatomic particle that is a nuclear binder",
    "Subatomic particle",
    "Short-lived particle",
    "Subatomic particle",
    "Semistable subatomic particle",
    "Nuclear binder",
    "Subatomic particle"
  ],
  "slrs": [
    "Some nice cameras, for short",
    "Some cameras, in brief",
    "Some pro cameras, for short",
    "Some Nikons, for short",
    "Some cameras, for short",
    "Some photog purchases",
    "Some 35mm cameras",
    "Some cameras, for short",
    "Photogs' choices",
    "Some cameras, for short",
    "Some cameras, for short",
    "Some shooters, for short",
    "Some 35mm cameras",
    "Some cameras, for short",
    "Shooters for pros",
    "Pentax Spotmatic and Nikon F2, for short",
    "Some cameras, for short",
    "Some shooters, briefly",
    "Camera types, for short",
    "Camera shop display, for short",
    "Some cameras, for short",
    "Some cameras, for short",
    "Certain cameras, for short",
    "Some cameras: Abbr."
  ],
  "itsa": [
    "\"___ deal\"",
    "\"___ trap!\"",
    "Celebratory words before \"boy\" or \"girl\"",
    "\"___ miracle!\"",
    "\"___ joke\"",
    "\"___ girl!\"",
    "\"___ small world after all\"",
    "\"___ jungle out there\"",
    "\"___ girl!\"",
    "\"___ long story\"",
    "\"___ date\"",
    "\"___ girl!\"",
    "\"___ date!\"",
    "\"___ deal!\"",
    "\"___ boy!\"",
    "\"___ deal!\"",
    "\"___ girl!\"",
    "Words with shame or boy",
    "\"___ living\"",
    "\"___ bird! ...\"",
    "\"___ sign!\"",
    "\"___ small world!\"",
    "\"___ small world\"",
    "\"___ Heartache\" (Bonnie Tyler hit)",
    "\"___ boy!\"",
    "\"___ deal!\"",
    "Midwife's words before boy or girl",
    "\"___ job\"",
    "Guy Lombardo's \"___ Lonely Trail\"",
    "\"___ date!\"",
    "\"___ small world!\"",
    "Bonnie Tyler's \"___ Heartache\"",
    "\"___ deal!\"",
    "Guy Lombardo's \"___ Lonely Trail\"",
    "\"___ Long Way to Tipperary\"",
    "\"___ done deal\"",
    "\"___ Boy\" (\"Tommy\" song)",
    "Lead-in to shame or boy",
    "\"___ Wonderful Life\"",
    "\"___ long story\"",
    "\"___ boy!\"",
    "\"___ small world...\"",
    "Words with shame or boy",
    "\"___ New World\" (Arlen/Gershwin song)",
    "\"___ wrap!\"",
    "\"___ date!\"",
    "Frank Capra's \"___ Wonderful Life\"",
    "\"___ boy!\"",
    "With 35-Down, a birth announcement",
    "\"___ deal!\"",
    "\"___ boy!\"",
    "\"___ Boy\" (\"Tommy\" song)",
    "\"___wrap!\"",
    "\"___deal!\"",
    "Berlin's \"___ Lovely Day Today\"",
    "\"___ Heartache\" (1978 hit)",
    "Bird-plane link",
    "\"___ Grand Night for Singing\"",
    "\"___ boy!\"",
    "\"___ boy!\"",
    "\"___ boy!\"",
    "\"___girl!\""
  ],
  "tvma": [
    "Rating for risqué shows",
    "Rating for \"South Park\"",
    "Rating that may signify profanity or violence",
    "\"Orange Is the New Black\" rating",
    "Rating for \"Game of Thrones\" and \"House of Cards\"",
    "For 17+ viewers",
    "Rating for many HBO shows",
    "Adult rating for the small screen"
  ],
  "oeds": [
    "Massive ref. books",
    "Multivolume ref. works",
    "Ref. works that can run $1,000+",
    "Library references, briefly",
    "Brit. reference sets",
    "Refs. that may occupy whole shelves",
    "Ref. books sometimes sold with magnifying glasses",
    "Meaningful sets, for short?",
    "Library refs.",
    "Ref. works sometimes sold with magnifying glasses",
    "Hefty refs.",
    "Lib. references",
    "They start A-B, C, D-E, etc.",
    "Multivolume refs.",
    "Ref. volumes",
    "Library refs.",
    "British refs.",
    "Multivolume ref. works",
    "English refs.",
    "Multivolume refs.",
    "Multivolume dicts."
  ],
  "ceos": [
    "Co. captains?",
    "Business suits?",
    "Corp. V.I.P.s",
    "Biz biggies",
    "Biz bigs",
    "Firm leaders, for short",
    "Co. heads",
    "Tops of corp. ladders",
    "Some suits",
    "High-ranking suits",
    "Wall St. bigwigs",
    "Company heads, in brief",
    "Biz bigs",
    "Some \"American Greed\" subjects, for short",
    "Board hirees",
    "Corp. bigwigs",
    "Board hirees",
    "Board of directors hirees, for short",
    "Mitt Romney and others, once",
    "Figs. in company reports",
    "Business brass",
    "Big suits",
    "Ones pictured in corp. reports",
    "Fortune profilees, for short",
    "Firm wheels, for short",
    "Co. bigwigs",
    "Business honchos",
    "Corporate V.I.P.'s",
    "Corp. kingpins",
    "Co. honchos",
    "Corporate V.I.P.'s",
    "High-ranking suits",
    "Bus. bigwigs",
    "Corp. heads",
    "Corporate V.I.P.'s",
    "Big suits",
    "Some Fortune mag. readers",
    "Corporate heads, for short",
    "Corporate honchos, for short",
    "Bus. bigwigs",
    "Theme of this puzzle",
    "Company V.I.P.?Æs",
    "Corp. heads",
    "Corp. bigwigs",
    "Business V.I.P.'s",
    "Boardroom V.I.P.'s",
    "Corp. heads",
    "Head honchos",
    "Bill Gates and Steve Jobs",
    "Corp. magnates",
    "Boardroom bigwigs",
    "Top dogs",
    "Bus. bigwigs",
    "Corp. V.I.P.'s",
    "Firm bosses",
    "Fortune profilees",
    "Forbes profilees",
    "Biz biggies",
    "Company V.I.P.'s",
    "Bus. bigwigs",
    "Fortunes profiles"
  ],
  "eely": [
    "Slippery",
    "Slippery",
    "Elusive, in a way",
    "Hard to handle, in a way",
    "Slippery",
    "Hard to grasp, say",
    "Like a moray",
    "Slippery",
    "Slithery",
    "Elusive",
    "Slippery",
    "Tough to get ahold of",
    "Slippery",
    "Slippery as a fish",
    "Slippery",
    "Hard to grasp",
    "Slippery like a fish",
    "Tough to grasp",
    "Slippery",
    "Snakelike",
    "Snakelike",
    "Difficult to grasp",
    "Slithery",
    "Slithery",
    "Slimy",
    "Hard to hold",
    "Wriggling",
    "Evasive",
    "Hard to grasp",
    "Snakelike",
    "Sinuous",
    "Squirming",
    "Hard to pin down",
    "Slippery",
    "Hard to hold",
    "Hard to catch",
    "Wriggling",
    "Slippery",
    "Hard to hold",
    "Like elvers",
    "Serpentlike",
    "Hard to grasp",
    "Slippery",
    "Slippery",
    "Wriggly",
    "Hard to pin down",
    "Hard to pin down",
    "Slippery"
  ],
  "mags": [
    "In Touch and Out, for two",
    "Newsstand display, for short",
    "Newsstand display, informally",
    "Food & Wine and Field & Stream",
    "Hot rod wheels",
    "S.I. and O, e.g.",
    "High-performance wheels",
    "Rack purchases, briefly",
    "16 and Seventeen, for short",
    "They're often sold with papers",
    "O and W, e.g.",
    "Flashy car accessories",
    "S.I. and Cosmo",
    "Cosmo and GQ, e.g.",
    "Mad, Elle and Mademoiselle, briefly",
    "Certain pubs",
    "Cosmo and People, e.g."
  ],
  "otoh": [
    "Texter's \"Then again ...\"",
    "Conversely, in brief",
    "Texted lead-in to an alternative",
    "\"But then again ...,\" in a text",
    "\"That said ...,\" in a text",
    "Texter's transition",
    "\"Then again ...,\" in texts",
    "Texting alternative?",
    "\"However ...,\" in texts",
    "\"However,\" in textspeak",
    "\"Alternatively ...,\" in texts",
    "Tweeter's \"Then again ...\"",
    "Texter's transition",
    "\"Then again ...,\" in texts",
    "However, in a tweet",
    "\"Then again ...,\" in a tweet",
    "\"Then again ...,\" to a texter",
    "\"Looking at it a different way,\" in texts",
    "Quick \"however\"",
    "Texter's \"Alternatively ...\"",
    "Conversely, in texts",
    "Texter's \"However ...\"",
    "Alternatively, online",
    "\"Looking at it differently ...,\" to a texter",
    "\"Conversely ...,\" online",
    "However, in a tweet",
    "Then again, in text messages",
    "Contrarian's abbreviation",
    "\"That being said,\" in textspeak",
    "Texter's \"Then again ...\"",
    "Internet shortening to introduce a different view",
    "Alternatively, in Internet lingo"
  ],
  "oaks": [
    "Trees under which truffles might grow",
    "Symbols of strength",
    "Acorn producers",
    "Acorn-producing trees",
    "Symbols of might",
    "Twelvesome in \"Gone With the Wind\"",
    "Acorn producers",
    "Wine barrel sources",
    "Many wine barrels come from them",
    "Symbols of might",
    "Sturdy ones",
    "Homes for squirrels",
    "Dumbarton ___ Conference (1944 meeting that laid the groundwork for the U.N.)",
    "Trees loved by squirrels",
    "Acorn producers",
    "\"Mighty\" things",
    "Battle of Fair ___, 1862",
    "Kentucky ___, annual Louisville race",
    "Acorn sites",
    "Sherman ___, Calif.",
    "Acorn droppers",
    "Shade providers",
    "Sturdy ones",
    "Producers of 46-Downs",
    "Thousand ___, Calif.",
    "Thousand ___, Calif.",
    "Stately florae",
    "Fair ___ (Civil War battle site)",
    "Pacific ___ College",
    "Dumbarton ___ (D.C. estate)",
    "Twelve ___ (Tara neighbor)",
    "Squirrels' haunts",
    "Thousand ___",
    "Twelve ___",
    "Slow-growing trees",
    "\"'Neath the ___\" (Wellesley school song)",
    "Annual horse race, with \"The\"",
    "Acorn sources",
    "Druidic worship sites",
    "Annual Epsom race, with \"the\"",
    "Annual Epsom race, with \"the\"",
    "Twelve ___ (\"G.W.T.W.\" locale)",
    "Squirrels' hangouts",
    "Dumbarton ___ (1944 meeting site)",
    "Results of 100 Across",
    "Forest florae",
    "Twelve ___ (\"G.W.T.W.\" home)"
  ],
  "oded": [
    "Took too much, for short",
    "Took too much, briefly",
    "Had more than one could handle",
    "Really binged, briefly",
    "Indulged to excess, with \"on\"",
    "Had too much, for short",
    "Had too much, in brief",
    "Took too much of a drug, briefly",
    "Had too much, briefly",
    "Took too much, briefly",
    "Had too much, briefly",
    "Had too much ecstasy, for short?",
    "Had way too much, briefly",
    "Glutted (on), briefly",
    "Didn't stop in time, say",
    "Showed no restraint, in brief",
    "Took too much",
    "Experimented too much?",
    "Had need of an E.R., maybe",
    "Exceeded the speed limit?",
    "Took too many pills, briefly",
    "Did too much, in a way",
    "Had too much",
    "Went too far on a trip, in brief",
    "Did too much",
    "Had too much, briefly",
    "Took too much of a drug, briefly",
    "Took too much, in short",
    "Took too much, briefly",
    "Had too much, briefly"
  ],
  "axon": [
    "Something hitting a nerve?",
    "Neural transmitter",
    "Part of a nerve cell",
    "Impulse transmitter",
    "Nerve cell part",
    "Neural conductor",
    "Impulse transmitter",
    "Neuron appendage",
    "White matter component",
    "Impulse transmitter",
    "Part of a euro",
    "One acting on impulse?",
    "Neural conductor",
    "One acting on impulse?",
    "Impulse carrier",
    "Impulse carrier",
    "Nerve cell projection",
    "Impulse path",
    "Neural transmitter",
    "Dendrite's counterpart",
    "Neural transmitter",
    "Nerve appendage",
    "Neuron part",
    "Biological transmitter",
    "Neural transmitter",
    "Nerve impulse transmitter",
    "A transmitter",
    "Part of a neuron",
    "Impulse transmitter",
    "Bit of a nerve",
    "Neural transmitter",
    "Neural appendage",
    "Neurotransmission site",
    "Dendrite's counterpart",
    "Impulse transmitter",
    "Neuron part",
    "Nerve cell part",
    "Nerve impulse conduit",
    "Nerve impulse conduit",
    "Impulse carrier"
  ],
  "hugo": [
    "Best Picture-nominated 2011 film based on a children's book",
    "Victor who wrote \"Odes et Ballades\"",
    "Who's the Boss?",
    "Victor in France, once",
    "Science fiction award",
    "___ Boss (German fashion house)",
    "Victor who wrote \"The Hunchback of Notre Dame\"",
    "Victor who wrote \"Les Miserables\"",
    "Science fiction award",
    "Black on the Supreme Court",
    "Boss of fashion",
    "Novelist Victor",
    "Venezuela's Chavez",
    "Venezuela's Chavez",
    "\"La Legende des Siecles\" poet",
    "Science fiction prize",
    "Literary award shaped like a rocket",
    "Monster hurricane of 1989",
    "Writer who was a source for Verdi's \"Rigoletto\"",
    "Author Victor",
    "\"Les Miserables\" author",
    "Boss of fashion",
    "Jurist Black",
    "Justice Black",
    "Novelist Victor",
    "\"Les Miserables\" novelist",
    "Memorable 1989 hurricane",
    "Justice Black",
    "Victor who wrote \"Les Miserables\"",
    "Science fiction award",
    "Supreme Court Justice Black",
    "\"Les Miserables\" author",
    "Justice Black",
    "Science writer Gernsback",
    "Science fiction's ___ Award",
    "Quasimodo's creator"
  ],
  "made": [
    "Self-___",
    "Fashioned",
    "Inducted, as a Mafioso",
    "Have it ___ (be assured of success)",
    "Prepared, as dinner or a bed",
    "Wasn't late for",
    "Not rumpled, as a bed",
    "Manufactured",
    "Not occurring naturally",
    "Earned",
    "Like a fully initiated Mafia member",
    "Like a neat bed",
    "Took home",
    "Took home",
    "Constructed",
    "With 16-Across, mob inductee",
    "In the Mafia",
    "Arrived in time for",
    "Fixed, as dinner",
    "Whipped up",
    "Showed up in time for",
    "Created",
    "Synthetic",
    "Took in",
    "Synthetic",
    "Not natural",
    "\"___ in China\"",
    "___-to-order",
    "With 50-Across, acted",
    "Concocted",
    "Built",
    "Like beds, at times",
    "With 7-Down, wasn't inane",
    "Fabricated",
    "Earned",
    "Fashioned",
    "Earned",
    "Earned",
    "Contrived",
    "Word with hand or home",
    "Fashioned",
    "Fabricated",
    "Whipped up",
    "Like some beds",
    "Synthetic",
    "Created",
    "Custom-___",
    "Have it ___",
    "Assured of success",
    "Synthetic",
    "Fashioned",
    "Assembled",
    "Like good beds",
    "Compelled",
    "Not occurring naturally",
    "Cooked",
    "Composed, as a bed",
    "Have it ___",
    "Forced (to)",
    "Fashioned",
    "Whipped up"
  ],
  "mast": [
    "Schooner feature",
    "Naval post",
    "One of two on the Titanic",
    "What a sail is tied to",
    "A boom might come out of it",
    "Post on a ship",
    "It's rigged",
    "Place for a crow's-nest",
    "It may be rigged",
    "Schooner feature",
    "Staff at sea",
    "Ship's pole",
    "Clipper feature",
    "Place for a topgallant",
    "Crow's-nest site",
    "Boom support",
    "Flag holder",
    "It may be rigged",
    "Flag support",
    "Spar",
    "Canvas holder",
    "Pirate's support?",
    "Locale for a lashing",
    "Sail holder",
    "Sail support",
    "Sail holder",
    "Yard supporter",
    "C-D",
    "Ensign holder",
    "Sail support",
    "Sail supporter",
    "Flag holder",
    "Schooner part",
    "Canvas holder",
    "Captain's ___ (naval disciplinary hearing)",
    "Sail supporter",
    "Site of some lashes",
    "Crow?Æs-nest spot",
    "It holds a yard",
    "Boom support",
    "Upright at sea",
    "A crow's-nest is atop it",
    "Schooner part",
    "Ship timber",
    "Schooner part",
    "It's rigged",
    "Spar",
    "It might be rigged",
    "Crow's-nest site",
    "Rigging attachment",
    "Sail holder",
    "\"Two Years Before the ___\"",
    "One of two on a brig",
    "Keel attachment",
    "A crow's-nest tops it",
    "It's rigged",
    "It holds a yard",
    "Spar",
    "Trestletree site",
    "Whipping site at sea",
    "Schooner part",
    "Spar",
    "It's rigged",
    "Captain's ___ (shipboard hearing)",
    "It may be rigged",
    "Bonaventure, for one",
    "Sail support",
    "Harbor pole",
    "Crow's-nest spot",
    "Crow's-nest site"
  ],
  "dare": [
    "Impetus behind a prank, maybe",
    "Truth's counterpart",
    "Challenge",
    "Alternative to truth?",
    "Choice in a sleepover game",
    "Take a chance",
    "Show gumption",
    "Venture",
    "One choice in a party game",
    "\"... if you ___!\"",
    "Option for people who can't handle the truth?",
    "\"Don't you ___!\"",
    "Impetus for a bungee jump, maybe",
    "Test of one's backbone",
    "Virginia ___, first English child born in America (1587)",
    "\"I bet you won't dive off the high board with your eyes closed,\" e.g.",
    "Have the nerve",
    "\"Truth or ___?\"",
    "Precursor to a game of chicken",
    "Display one's guts",
    "Inspiration for a \"Jackass\" stunt, maybe",
    "Challenge",
    "Playground provocation",
    "\"Do I ___?\"",
    "Say \"You can't do that!\" to, say",
    "\"I bet you won't go bungee jumping,\" e.g.",
    "Reason to make a prank call, maybe",
    "Truth alternative",
    "Virginia ___ (noted 1587 birth)",
    "Show pluck",
    "Have the nerve",
    "Be bold enough",
    "Be bold enough",
    "Show some spunk",
    "Show some spunk",
    "Alternative to truth in a party game",
    "Partner of truth",
    "Brave",
    "Risk",
    "Have the gumption",
    "Show spunk",
    "Bell the cat",
    "Beard",
    "Have the nerve",
    "\"How ___ you?!\"",
    "Brave",
    "Have the nerve",
    "\"I ___ you!\"",
    "It may make you do something foolish",
    "Be plucky enough",
    "Appear on \"Fear Factor,\" say",
    "Take risks",
    "Throw down the gauntlet",
    "Colonial Virginia",
    "Double ___",
    "Certain bet",
    "Show bravery",
    "Advice for the timid",
    "Don't be a chicken",
    "Truth or ___ (slumber party game)",
    "Have the nerve",
    "\"I double-___ you!\"",
    "Be bold",
    "Start of a prank, maybe",
    "Brave",
    "\"Try me\" preceder",
    "Bell the cat",
    "\"Bet you can't...,\" e.g.",
    "Challenge",
    "\"You wouldn't ___!\"",
    "Have the nerve",
    "Brave",
    "Have the guts",
    "Brave",
    "Defy",
    "Have the nerve",
    "Truth alternative",
    "Challenge",
    "Bell the cat",
    "Face",
    "Venture",
    "The brave do it",
    "Truth alternative",
    "Take on",
    "Venture",
    "Brave",
    "Throw down the gauntlet",
    "Madonna's \"Truth or ___\"",
    "Brazen",
    "Boldly attempt",
    "\"How ___ you!\""
  ],
  "bogs": [
    "Weighs (down)",
    "Weighs (down)",
    "Peaty places",
    "Some wetlands",
    "Swamps",
    "Fens",
    "Cranberry picking sites",
    "Things near Baskerville Hall",
    "Swamps",
    "Peaty places",
    "Marshes",
    "Cranberries thrive here",
    "Peat sources",
    "Gets stuck, with \"down\"",
    "Slows (down)",
    "Sloughs"
  ],
  "sing": [
    "Be a rat",
    "*Storied New York prison",
    "Let secrets out",
    "Belt out a tune",
    "Snitch",
    "With 72-Across, noted maximum security prison",
    "With 71- and 72-Across, classic Louis Prima tune",
    "Spill one's secrets",
    "Serenade",
    "Be a prima donna, e.g.",
    "Make a note of?",
    "Rat",
    "Turn state's evidence",
    "Be in a 32-Down, e.g.",
    "Perform on \"Glee,\" perhaps",
    "Sound really good",
    "Rat on the Mob",
    "Perform on \"American Idol,\" e.g.",
    "Do lullabies, e.g.",
    "Be a fink",
    "Snitch",
    "Save one's own neck, maybe",
    "Squeal",
    "Belt, perhaps",
    "Do a number",
    "Hootenanny, e.g.",
    "Blab freely",
    "Doubled, a pen",
    "Tell all",
    "Belt out",
    "Blab",
    "Be a rat",
    "Rat",
    "Perform in a glee club",
    "Let the air out?",
    "Blab",
    "Be a stool pigeon",
    "Rat",
    "Carol",
    "Community event",
    "Blab",
    "Spill the beans",
    "Tell all",
    "Produce strains",
    "Serenade",
    "Warble"
  ],
  "loin": [
    "\"Sir\" might be found at the start of it",
    "Cut of a pork roast",
    "Cut of pork",
    "Tender ender?",
    "Beef cut",
    "Butcher's cut",
    "Pork cut",
    "Butcher's cut",
    "Cut above the flank",
    "Cut off the back",
    "Choice cut",
    "Meat cut that may be \"tender\"",
    "Kind of chop",
    "Allocation of some pork spending?",
    "Cut of meat",
    "Chop source",
    "Pork cut",
    "Choice cut",
    "Short cut?",
    "Pork cut",
    "Butcher's offering",
    "Cut above the flank",
    "Butcher's cut",
    "Pork ___",
    "Butcher's cut",
    "Cut off the spine",
    "Butcher's offering",
    "Butcher's cut",
    "Cut off the back",
    "Order at a butcher's",
    "Butcher's cut",
    "Cut of beef",
    "Chop order",
    "Kind of chop",
    "Cut of meat",
    "Cut of meat",
    "Pork cut",
    "Pork purchase",
    "Tender ender?",
    "Kind of cloth",
    "Pork cut",
    "A little lamb",
    "Beef buy",
    "Beef buy",
    "Kind of cloth",
    "Meat purchase",
    "Pork cut",
    "Butcher's cut",
    "Chop cut",
    "Butcher's order",
    "Cut of beef",
    "Mutton cut",
    "Butcher's cut",
    "Beef cut",
    "Kind of cloth"
  ],
  "puzo": [
    "Mario who wrote \"The Godfather\"",
    "\"The Last Don\" author",
    "Mario who wrote \"The Godfather\"",
    "Author of the line \"It means Luca Brasi sleeps with the fishes\"",
    "Two-time Oscar-winning screenwriter",
    "\"The Last Don\" author",
    "\"The Godfather\" author",
    "\"The Godfather\" novelist",
    "Mario who wrote \"The Godfather\"",
    "Vito Corleone's creator",
    "Vito Corleone's creator",
    "\"Fools Die\" author",
    "\"The Godfather\" novelist",
    "1972 and '74 Oscar-winning screenwriter",
    "Corleone's creator",
    "\"The Godfather\" author",
    "\"The Sicilian\" author"
  ],
  "spew": [
    "Go on and on bitterly",
    "What a shaken soda bottle will do when uncapped",
    "What a volcano might do",
    "Gush",
    "What shaken soda cans do after being opened",
    "Gush",
    "Pour forth",
    "Expel forcefully",
    "Erupt",
    "Vent violently",
    "Gush",
    "Blow out",
    "Shoot out, as 14-Across",
    "Send out a jet",
    "Blow, as from a volcano",
    "Spurt forcefully",
    "Shoot out",
    "Gush",
    "Violently send out",
    "Discharge, as from a volcano",
    "Eject, as angry words",
    "Erupt",
    "Spit out",
    "Erupt",
    "Expel",
    "Erupt",
    "Expel, as lava",
    "Throw off",
    "Emit, as lava",
    "Expel forcibly",
    "Spout forth, as venom",
    "Gush",
    "Throw out",
    "Eject, as 16-Across",
    "Shoot out",
    "Gush",
    "Hurl everywhere",
    "Eject in all directions",
    "Gush",
    "Eject, as lava",
    "Gush forth",
    "Gush",
    "Gush",
    "Erupt",
    "Erupt",
    "Gush",
    "Send flying",
    "Gush",
    "Gush",
    "Shoot",
    "Gush",
    "Erupt",
    "Gush",
    "Gush forth",
    "More than trickle",
    "Gush out",
    "Eruption",
    "Release, as lava",
    "Gush forth",
    "Send all over the place",
    "Gush",
    "Release forcibly",
    "Gush",
    "Gush",
    "Blow one's top",
    "Gush forth"
  ],
  "gynt": [
    "Peer of Ibsen",
    "Ibsen's \"Peer ___\"",
    "Peer ___",
    "Peer onstage",
    "Ibsen's \"Peer ___\"",
    "Peer on a stage",
    "\"Peer ___\"",
    "Ibsen character",
    "\"Peer ___\"",
    "\"Peer ___\""
  ],
  "esto": [
    "\"Qué es ___?\" (\"What's this?\": Sp.)",
    "This, in Spanish",
    "This: Sp.",
    "This: Sp.",
    "This, to Tomas",
    "___ perpetuum (let it be everlasting: Lat.)",
    "Idaho motto word",
    "Latin word on Missouri's state seal",
    "This, in Taxco",
    "This, in Tijuana",
    "This, in Tijuana",
    "This, in Tijuana",
    "This, to Tomas",
    "This, in Tijuana",
    "This, to Tomas",
    "Idaho motto opener",
    "This, in Toledo",
    "\"___ dignus\" (Latin motto)",
    "\"Salus populi suprema lex ___\" (Missouri's motto)",
    "\"___ perpetua\" (Idaho's motto)",
    "This, in Tijuana",
    "___ perpetuum (let it be everlasting)",
    "\"___ perpetua\" (Idaho's motto)",
    "Idaho motto starter",
    "This, to Jorge",
    "This, to Tomas",
    "Start of Idaho's motto",
    "Start of Idaho's motto",
    "This, to Teodoro",
    "Last word of Missouri's motto",
    "\"___ perpetua\" (Idaho's motto)",
    "End of Missouri's motto",
    "___ Perpetua (Idaho's motto)",
    "Pablo's this",
    "This, south of the border",
    "This, in Taxco",
    "\"___ Perpetua\" (Idaho's motto)",
    "Last word of Missouri's motto",
    "This, in Toledo",
    "\"Salus populi suprema lex ___\" (Missouri's motto)",
    "This, to Jorge",
    "Idaho motto starter",
    "This, to Jorge",
    "Idaho motto starter",
    "___ perpetuum (let it be everlasting)",
    "___ perpetua (Idaho's motto)",
    "___ perpetua (Idaho's motto)",
    "This, to Jorge",
    "This, in Tijuana",
    "\"___ Perpetua\" (Idaho's motto)",
    "This, to Cervantes",
    "This, in Mexico",
    "\"What's this, Pedro?\"",
    "\"___perpetua\" (Idaho's motto)",
    "\"___perpetua\" (Idaho's motto)",
    "\"___perpetua\" (Idaho's motto)",
    "\"___ perpetua\" (Idaho's motto)",
    "This: Sp.",
    "This, in Madrid",
    "This: Sp."
  ],
  "awls": [
    "Saddle-making tools",
    "Hole punchers",
    "Some shop tools",
    "Leatherwork tools",
    "Leather-punching tools",
    "Hole-making tools",
    "Leatherworking implements",
    "They have boring jobs",
    "Hole punches",
    "Strip pokers?",
    "Leatherworkers' tools",
    "Boring things in shops",
    "Tools for cobblers",
    "Punchers for a belt",
    "Saddle-making tools",
    "Hole punchers",
    "Hole-making tools",
    "Some pokers",
    "Hide pokers",
    "Leatherworking tools",
    "Hole punchers",
    "Hole-punching tools",
    "Woodworking tools",
    "Cobblers' needs",
    "Poking tools",
    "Belt-hole makers",
    "Pointy tools",
    "Punching devices",
    "Leather stickers",
    "Cobblers' tools",
    "Cobblers' tools",
    "Punchers",
    "Hole-making tools",
    "Sharp punches",
    "Some punches",
    "Punches",
    "Punch tools",
    "Saddlery needs",
    "Pointed tools",
    "Hand-held holers",
    "Shoemaker's tools",
    "Marking tools",
    "Shop tools",
    "Puncturing tools",
    "Shoemaker's tools",
    "Piercing tools"
  ],
  "bryn": [
    "___ Mawr College",
    "___ Mawr College",
    "___ Mawr College",
    "Welsh word that starts a noted college name",
    "___ Mawr",
    "___ Mawr College",
    "___ Mawr",
    "Leader in women's education?",
    "___ Mawr",
    "___ Mawr",
    "Welsh word in a Pennsylvania college name",
    "___ Mawr College",
    "___ Mawr",
    "___ Mawr",
    "___ Mawr, Pa.",
    "___ Mawr, Pa."
  ],
  "riis": [
    "Muckraking journalist Jacob",
    "Reformer Jacob who wrote \"How the Other Half Lives\"",
    "Muckraking journalist Jacob",
    "Reformer Jacob who wrote \"How the Other Half Lives\"",
    "Pioneering photojournalist Jacob",
    "Muckraker who pushed for \"model tenements\"",
    "Social reformer Jacob",
    "Social reformer Jacob",
    "New York's Jacob ___ Park",
    "\"The Battle With the Slum\" writer",
    "Muckraker Jacob",
    "Muckraker Jacob",
    "Jacob who wrote \"How the Other Half Lives\"",
    "Muckraker Jacob",
    "Social reformer Jacob",
    "Muckraker Jacob",
    "Jacob who wrote \"How the Other Half Lives\"",
    "Jacob who wrote \"How the Other Half Lives\"",
    "Early photojournalist Jacob",
    "New York City park name",
    "\"Children of the Tenements\" author",
    "Reformer born in Denmark",
    "Jacob ___ Park, in Queens",
    "Social historian Jacob",
    "Social reformer Jacob",
    "Social reformer Jacob",
    "Jacob who wrote \"How the Other Half Lives\"",
    "New York's Jacob ___ Park",
    "Crusading journalist for the old New York Sun",
    "Urban reformer Jacob",
    "\"How the Other Half Lives\" author",
    "\"How the Other Half Lives\" author",
    "New York's Jacob ___ Park",
    "\"How the Other Half Lives\" author",
    "Reformer Jacob",
    "Reformer Jacob",
    "\"The Children of the Poor\" author",
    "Reformer Jacob",
    "Jacob of journalism",
    "\"How the Other Half Lives\" author Jacob",
    "Chronicler of slum life",
    "Reformer Jacob",
    "Reformer Jacob",
    "Social reformer Jacob",
    "Social reformer Jacob"
  ],
  "tete": [
    "Rodin's thinker?",
    "Dome of Notre-Dame?",
    "Head overseas",
    "Place for un beret",
    "Head: Fr.",
    "Place for un beret",
    "French bean",
    "Chapeau site",
    "Place for un beret",
    "Head to France?",
    "Head of Napoleon's army?",
    "French bean?",
    "Part lopped off by la guillotine",
    "Marie Antoinette lost hers in la Revolution francaise",
    "French thinker?",
    "Spot for un chapeau",
    "Head of the French department?",
    "___ de Moine (Swiss cheese)",
    "Head overseas?",
    "Mal de ___ (French woe)",
    "Idee origin",
    "First of many body parts in \"Alouette\"",
    "Head to Paris?",
    "Head of the Seine?",
    "Head of une ecole?",
    "Loss from a guillotine",
    "Head: Fr.",
    "Marie Antoinette's loss",
    "Head of Quebec",
    "What les cheveux grows on",
    "Where le nez is",
    "Casse-___ (French brainteaser)",
    "Department head?",
    "Place for un beret",
    "Rodin's thinker?",
    "Head of Haiti",
    "Head of Haiti",
    "French bean?",
    "Head of Quebec",
    "Place for un beret",
    "Something plucked in 41-Across",
    "Coup de ___ (sudden impulse: Fr.)",
    "Place for a beret",
    "Head: Fr.",
    "Head of Notre Dame",
    "Where le nez is",
    "Beret holder",
    "Head of France?",
    "French bean?",
    "French noodle?",
    "French noodle?",
    "Head, in an ecole",
    "Head, to Henri",
    "French head",
    "French head",
    "Place for a beret",
    "Head of Quebec",
    "French thinker?",
    "Cabeza, across the Pyrenees",
    "Head, in France",
    "Tours head",
    "French head",
    "Where to put un chapeau",
    "Head of l'Academie",
    "Departement head",
    "Beret site",
    "Louis XVI lost his",
    "Head of Haiti",
    "Chapeau holder",
    "Word before and after \"a\"",
    "Head of Haiti",
    "Head in a guillotine?",
    "It may be capped, in Caen",
    "French noodle?",
    "French for 65-Across",
    "Beret setting",
    "French noodle?",
    "Place for a beret",
    "French bean",
    "French noggin",
    "French bean?",
    "Head of France?",
    "French head",
    "French bean?",
    "Quebecois head",
    "Leader of France",
    "French thinker?",
    "Les oreilles are attached to it",
    "Noggin, in Nantes",
    "Department head?",
    "Marie Antoinette lost hers",
    "Head overseas",
    "French bean",
    "Block of Brie?",
    "___ de veau (French dish)",
    "Cabeza, north of the Pyrenees",
    "Chapeau's spot",
    "Top of France",
    "Head for the Riviera?",
    "Head, to Henri",
    "What a logical Parisian uses",
    "French bean?",
    "French noodle?",
    "Head, to Henri",
    "Head of the Sorbonne",
    "Chapeau's perch",
    "Mal de ___",
    "Guillotined part",
    "Pied's opposite",
    "Beret holder",
    "What a chapeau covers",
    "A beret covers it",
    "Where les yeux are",
    "Where le nez is",
    "French bean?",
    "A head of France",
    "___ de pont (bridgehead)",
    "Henri's head",
    "Head of Haiti",
    "Henri's head",
    "Head of France",
    "Head of Le Havre",
    "Noggin, in Nice",
    "Parisian head",
    "Place for a chapeau",
    "Head overseas",
    "Henri's head",
    "Mal de___"
  ],
  "zeal": [
    "Fervency",
    "True believer's quality",
    "Passion",
    "Passion",
    "Fervor",
    "Fervor",
    "Fervor",
    "Enthusiasm",
    "Gusto",
    "Fervency",
    "Enthusiasm",
    "Intense dedication",
    "Passion",
    "Fervor",
    "Enthusiasm",
    "Gusto",
    "Get-up-and-go",
    "Fervor",
    "Enthusiasm",
    "Groupie's trait",
    "Fire",
    "Fire",
    "Fervor",
    "Fervor",
    "Ardor",
    "Fire",
    "Passion",
    "Intense enthusiasm",
    "Enthusiasm",
    "Fire",
    "Fire",
    "Passion",
    "Fervor",
    "Antithesis of apathy",
    "Fire",
    "Ardor",
    "Gung-ho feeling",
    "Gung-ho feeling",
    "Fire",
    "Enthusiasm",
    "Fire",
    "Ardor",
    "Enthusiasm",
    "Gusto",
    "Gung-ho quality",
    "Eagerness",
    "Passion",
    "Ardor",
    "Passion",
    "Fire",
    "Gung-ho quality",
    "Gung-ho feeling",
    "Fire",
    "Enthusiasm",
    "Intensity",
    "Ardor",
    "Fervor",
    "Fire",
    "Enthusiasm",
    "Gung-ho feeling",
    "Intensity",
    "Fire",
    "Ardor",
    "Get-up-and-go"
  ],
  "asof": [
    "Words before a date",
    "Since",
    "Starting",
    "From",
    "From",
    "Starting on",
    "Beginning",
    "Starting",
    "From",
    "Beginning, datewise",
    "Starting from",
    "Starting",
    "Starting on",
    "Starting on",
    "Since",
    "Beginning, timewise",
    "Starting",
    "Since",
    "Beginning on",
    "Since",
    "Starting",
    "Beginning",
    "Since",
    "Starting on",
    "Beginning with",
    "From",
    "Starting from",
    "Dating from",
    "From",
    "Beginning on",
    "Beginning on",
    "Since",
    "Starting on",
    "From",
    "Starting from",
    "Beginning",
    "From",
    "From",
    "On and after",
    "Starting",
    "Starting on",
    "On and after",
    "Since",
    "___ late",
    "Starting from",
    "Up until",
    "Starting",
    "Starting",
    "___ this minute",
    "Up until",
    "Commencing",
    "Beginning on",
    "Starting",
    "Starting from",
    "Starting from",
    "Beginning on",
    "Dating from",
    "Starting",
    "Starting from",
    "Beginning",
    "Dating from",
    "From",
    "Since",
    "From",
    "Beginning on",
    "From",
    "Since",
    "From",
    "Beginning",
    "Starting",
    "Beginning on",
    "Up to, temporally",
    "Beginning",
    "Beginning",
    "From",
    "Beginning at",
    "Beginning",
    "Since",
    "Beginning",
    "By",
    "Starting",
    "Since",
    "Starting",
    "Date-setting phrase",
    "From",
    "Beginning (then)",
    "Beginning (then)"
  ],
  "pcbs": [
    "E.P.A. targets since the '70s",
    "Banned pollutants",
    "Chemicals proscribed by '70s legislation",
    "Subj. of the Toxic Substances Control Act of 1976",
    "Banned pollutants",
    "They've been banned in the U.S. since '79",
    "Environmental pollutants, for short",
    "Noxious compounds, briefly",
    "E.P.A. targets",
    "Potential landfill pollutants, for short",
    "Some coolant fluids, for short",
    "Chem. pollutants",
    "Banned pollutants, briefly",
    "Chemicals banned by Congress in '76",
    "Dumpsite pollutants, for short",
    "Banned chemicals",
    "Banned pollutants, briefly",
    "Environmental toxins, for short",
    "Highly toxic pollutants",
    "Some pollutants",
    "Cancer-causing compounds"
  ],
  "baas": [
    "What do ewe say?",
    "Neighs : horses :: ___ : sheep",
    "Bleats",
    "\"Old MacDonald Had a Farm\" sounds",
    "Peeps heard by Bo Peep",
    "Meadow cries",
    "Rural calls",
    "Barnyard bleats",
    "Farm cries",
    "Farm sounds",
    "Calls to Bo Peep",
    "Calls from a 27-Down",
    "Sounds in \"Old MacDonald Had a Farm\"",
    "Pastoral sounds",
    "Flock sounds",
    "Sounds heard by 57-Across",
    "Missed signals from Little Boy Blue, maybe",
    "Kids' greetings",
    "Meadow calls",
    "Barnyard calls",
    "Sheep cries",
    "Lambs' laments",
    "Sheep cries",
    "Flock sounds",
    "Farm calls",
    "These break the silence of the lambs",
    "Sheep cries",
    "Farm calls",
    "Pastoral sounds",
    "Bleats",
    "49-Down cries",
    "Pastoral sounds",
    "Devious wolves' cries",
    "Sheepish sounds",
    "Sheep calls",
    "Meadow sounds",
    "Calls to a shepherd",
    "Meadow murmurs",
    "Ewes' calls",
    "Cotswold cries",
    "Farm sounds",
    "Bleats",
    "Meadow sounds",
    "Pastoral sounds",
    "Sheep cries",
    "Shropshire sounds",
    "Pasture sounds",
    "Cheviot sounds"
  ],
  "lama": [
    "Dalai ___",
    "Spiritual leader",
    "Dalai ___",
    "Dalai ___",
    "Tibetan spiritual guide",
    "Teacher of the dharma",
    "Spiritual guide",
    "Eastern priest",
    "Priest from on high?",
    "Monastery head",
    "Prayer wheel turner",
    "High priest?",
    "Religious figure from on high?",
    "Mantra-chanting priest",
    "Dalai ___",
    "Tibetan monk",
    "Tibetan spiritual guru",
    "\"Rama ___ Ding Dong\" (1961 hit)",
    "Tibetan priest",
    "Former Potala Palace resident",
    "\"Lost Horizon\" figure",
    "Monastery resident",
    "Dalai ___",
    "Tibetan priest",
    "Tibetan priest",
    "Tibetan priest",
    "Asian spiritual guide",
    "Dalai ___",
    "Buddhist monk",
    "\"Rama ___ Ding Dong\" (1961 hit)",
    "Spiritual guide",
    "Holy man in an Ogden Nash verse",
    "Figure in \"Lost Horizon\"",
    "Prayer wheel user",
    "One-L person, in an Ogden Nash poem",
    "Priest in an Ogden Nash verse",
    "Eastern title",
    "See 30-Down",
    "Himalayan priest",
    "Priest of the East",
    "Temple tender",
    "Holy man",
    "Holy man of Tibet",
    "Tibetan priest",
    "Part of an order",
    "Priest",
    "Man who's taken vows",
    "Tibetan monk",
    "Tibetan for \"superior one\"",
    "Tibetan monk",
    "Buddhist monk",
    "Tibetan priest",
    "Eastern priest",
    "Dalai ___",
    "Priest from the East",
    "Dalai ___",
    "Tibetan monk",
    "Tibetan V.I.P.",
    "See 46-Across",
    "Tibetan monk",
    "Tibetan holy man",
    "Priest of the East",
    "Priest of the East",
    "Tibetan monk",
    "Dalai ___",
    "Tibetan monk",
    "Mahayana master",
    "Tibetan monk",
    "Shangri-La cleric",
    "Tibetan priest",
    "Nash fellow",
    "Priest of the East",
    "Tibetan holy man",
    "Buddhist priest",
    "Asian monk",
    "Nash's priest",
    "Vajrayana teacher",
    "Priest of the East",
    "One-l Nash subject",
    "Panchen ___ (spiritual leader)",
    "Religious leader",
    "Mongol monk",
    "Literally, superior one",
    "Spiritual leader",
    "Persecuted priest",
    "Dalai ___"
  ],
  "hues": [
    "Shades",
    "Turquoise and topaz",
    "Ruby and sapphire",
    "Colors",
    "Shades",
    "Cream and eggshell, for two",
    "Painters' picks",
    "What some swatches preview",
    "Tints",
    "They can be saturated",
    "Color tones",
    "Tints",
    "Peach and orange",
    "Colors",
    "Apricot and tangerine",
    "Tones",
    "Color tones",
    "Shades",
    "Shades",
    "Tints",
    "Rose and burgundy, e.g.",
    "Tints",
    "Roy G. Biv septet",
    "Rainbow display",
    "Colors",
    "Shades",
    "Emerald and ruby",
    "Tones",
    "Shades",
    "Shades",
    "Orange and lemon, e.g.",
    "Lemon and orange, e.g."
  ],
  "buns": [
    "Supply at a barbecue",
    "Cheeks",
    "Places dogs go at cookouts",
    "Hot dog holders",
    "Tush",
    "Hamburger holders",
    "Rolls for dogs",
    "Hot dog holders",
    "Some picnic supplies",
    "Weenie roast needs",
    "Barbecue items",
    "*Eighth step",
    "Schoolmarm's hairdos"
  ],
  "prez": [
    "Head of a country, informally",
    "Veep's boss",
    "Air Force One honcho",
    "Chief exec",
    "W., once",
    "Abe or Ike",
    "W. or Bam",
    "W. or J.F.K.",
    "Firm honcho",
    "Bossman or bosswoman",
    "Veep's boss",
    "W, for one",
    "Firm honcho",
    "White House resident, informally",
    "White House dweller, informally",
    "\"Hail to the Chief\" chief",
    "Clinton, slangily"
  ],
  "whew": [
    "\"That was close!\"",
    "\"Barely made it!\"",
    "\"That was close!\"",
    "\"That was a close one!\"",
    "\"That was a close one!\"",
    "\"That was a bear!\"",
    "\"That was close!\"",
    "\"That was exhausting!\"",
    "Sound heard during a heat wave",
    "\"That's finally done!\"",
    "\"That was close!\"",
    "Nail-biter's cry",
    "\"Thank goodness!\"",
    "\"I'm glad that's over!\"",
    "\"That was close!'",
    "Forehead wiper's exclamation",
    "\"I'm glad that's over!\"",
    "\"Thank heaven that's over!\"",
    "\"That was close!\"",
    "\"That was a close one!\"",
    "Cry after a close call",
    "\"That was close!\"",
    "Near-miss exclamation",
    "Exclamation of relief",
    "\"I'm glad that's over!\"",
    "\"That was close!\""
  ],
  "leah": [
    "Two-time Emmy winner Remini",
    "Actress Remini",
    "Biblical sister of Rachel",
    "Jacob's first wife, in the Bible",
    "Jacob's first bride",
    "Remini who co-starred on \"The King of Queens\"",
    "Jacob's first wife",
    "Jacob's first wife, in the Bible",
    "Biblical sister of Rachel",
    "Jacob's first wife",
    "Jacob's wife",
    "Wife of Jacob",
    "Wife of Jacob",
    "Matriarch of six of the 12 Tribes of Israel",
    "\"The King of Queens\" co-star Remini",
    "Wife of Jacob and in-law of 25-Across",
    "Mother of Levi and Judah",
    "Mrs. Rabin of Israel",
    "One of Jacob's wives",
    "Genesis matriarch",
    "In-law of Esau",
    "Sister of Rachel, in the Bible",
    "Sister of Rachel",
    "Jacob's first wife",
    "Jacob's first wife",
    "Wife of Jacob",
    "\"Dressing Rich\" author Feldon",
    "Woman's name meaning \"weary\" in Hebrew",
    "Jacob's first wife",
    "Sister of Rachel",
    "Daughter of Laban, in the Bible",
    "Jacob's first wife",
    "One of Laban's daughters",
    "Jacob's wife",
    "Sister of Rachel",
    "Daughter of 28-Down",
    "Matriarch of six of the 12 Tribes of Israel",
    "Mother of Judah",
    "Wife of Jacob",
    "Jacob's first wife",
    "Rachel's older sister",
    "Wife of Jacob",
    "Title girl in a 1962 Roy Orbison hit",
    "Actress Remini of \"The King of Queens\"",
    "Wife of Jacob",
    "Sister of Rachel",
    "Wife of Jacob",
    "Zebulun's mother, in the Bible",
    "Wife of Jacob",
    "First wife of Jacob",
    "Mrs. Rabin",
    "Mrs. Rabin",
    "Wife of Jacob",
    "Mother of Levi and Judah",
    "Mrs. Rabin",
    "Wife of Jacob",
    "Wife of Jacob",
    "Mrs. Rabin of Israel",
    "Jacob's first wife",
    "Mother of Reuben and Simeon",
    "Jacob's first wife",
    "Jacob's first wife",
    "Jacob's first wife",
    "\"Does This Make Me Look Fat?\" author Feldon",
    "Mrs. Rabin",
    "Jacob's first wife",
    "Judah's mother",
    "Mrs. Rabin",
    "Title woman of a 1962 Roy Orbison hit",
    "Jacob's first wife",
    "Judah's mother",
    "Wife of Jacob",
    "Rachel's sister, in the Bible",
    "Rachel's sister",
    "Wife of Jacob",
    "Wife of Jacob",
    "Mother of Judah",
    "Mother of Levi and Judah",
    "One of Jacob's wives",
    "Daughter of Laban",
    "Rachel's sister",
    "Biblical name meaning \"weary\"",
    "Jacob's first wife",
    "Matriarch buried at Hebron",
    "Wife of Jacob",
    "Jacob's first wife",
    "Jacob's first wife",
    "Ancestress of six tribes of Israel",
    "Wife of Jacob",
    "One of Esau's in-laws",
    "Wife of Jacob",
    "Rachel's older sister",
    "Levi's mother",
    "Wife of Jacob"
  ],
  "cube": [
    "27, to 3",
    "Die, say",
    "343, to 7",
    "Mecca's holy Kaaba, e.g.",
    "1,000 or 1,000,000",
    "Die, say",
    "21-Down unit",
    "64 or 1,000",
    "Six-sided solid",
    "Take to a higher power",
    "Shape for Rubik",
    "Die, for example",
    "27, to 3",
    "It has six sides",
    "Rubik creation",
    "Die",
    "1, 8 or 27",
    "Crouton, maybe",
    "See 2-Down",
    "Ice unit",
    "27, to 3",
    "Cut into kabobs"
  ],
  "vats": [
    "Fixtures in a chocolate factory",
    "Big tubs",
    "Industrial vessels",
    "Brewery sights",
    "Containers at chocolate factories",
    "Brewery containers",
    "Big mixing containers",
    "Tubs",
    "Vintners' vessels",
    "Winery tubs",
    "Aging equipment?",
    "Textile factory containers",
    "Tanks",
    "Brewery lineup",
    "37-Across vessels",
    "Aging vessels?",
    "Winery containers",
    "Drums",
    "Fermentation locations",
    "Brewery units",
    "Chocolate factory needs",
    "Fermentation locations",
    "Dye holders",
    "Distillery items",
    "Big containers",
    "Dye containers",
    "Brewery equipment",
    "Winery equipment",
    "Vintners' vessels",
    "Vintners' needs",
    "Wine-making equipment",
    "Distillery items",
    "Tanks",
    "Chocolate factory sights",
    "Wine holders",
    "Tubs",
    "Winery fixtures"
  ],
  "izod": [
    "Big name in polo shirts",
    "Polo alternative",
    "Preppy clothing brand",
    "Men's sportswear brand",
    "Polo competitor",
    "Polo competitor",
    "Polo brand",
    "Tommy Hilfiger alternative",
    "Preppy clothing line",
    "Maker of PerformX sportswear",
    "Phillips-Van Heusen subsidiary",
    "New Jersey's ___ Center",
    "Lacoste competitor",
    "Preppy clothing brand",
    "Sport shirt brand",
    "Polo competitor",
    "Classic brand in men's apparel",
    "Polo competitor",
    "Shirt brand that once had a crocodile symbol",
    "Brand associated with a crocodile logo",
    "Polo competitor",
    "Polo Ralph Lauren competitor",
    "Polo alternative",
    "Polo alternative",
    "Shirt brand",
    "Big name in sport shirts",
    "Preppy shirt brand",
    "Big name in golf shirts",
    "___ Center, home of the New Jersey Nets",
    "Polo alternative",
    "Big shirt maker",
    "Big name in sportswear",
    "Preppy shirt label",
    "Big name in shirts",
    "Polo alternative",
    "Popular shirt label",
    "Shirt label name",
    "Popular shirt maker",
    "Popular shirt label",
    "Polo competitor",
    "Preppy sportswear label",
    "Shirt name",
    "Arrow alternative",
    "Company with a crocodile logo",
    "Sportswear brand",
    "Name associated with crocodiles",
    "Shirt brand",
    "Kind of shirt",
    "Shirt with a reptilian logo",
    "Polo shirt producer",
    "Label on some sportswear",
    "Classic shirt brand",
    "Shirt label",
    "Shirt brand",
    "Company with a reptile logo",
    "Big name in menswear",
    "Polo competitor",
    "___ Lacoste",
    "Shirt name",
    "Shirt brand",
    "First name in fashion",
    "___Lacoste",
    "Shirt label",
    "Shirt stitching",
    "Polo shirt brand",
    "___ Lacoste",
    "Shirt brand name"
  ],
  "quid": [
    "___ pro quo",
    "Pounds",
    "___ pro quo",
    "Pounds",
    "British pound, informally",
    "Pound",
    "British pound, informally",
    "Pound sterling",
    "British pound, informally",
    "Pounds, informally",
    "Mouthful of tobacco",
    "Pound, in Piccadilly",
    "Pound sterling, informally",
    "British pound, informally",
    "Pounds",
    "Chewable wad",
    "Piccadilly pound"
  ],
  "gave": [
    "Donated",
    "Collapsed, with \"in\"",
    "Buckled",
    "Donated",
    "Handed over",
    "Buckled",
    "Donated",
    "Buckled",
    "Donated",
    "Donated",
    "\"I ___ at the office\"",
    "Said uncle",
    "Yielded",
    "Was charitable",
    "Collapsed",
    "Yielded",
    "Crumpled",
    "Was philanthropic",
    "Collapsed under pressure",
    "Crumpled",
    "Lent",
    "Collapsed",
    "Collapsed",
    "Yielded",
    "Donated",
    "Contributed",
    "Imparted"
  ],
  "wove": [
    "Interlaced threads",
    "Created a tapestry",
    "Entwined",
    "Roved unpredictably",
    "Told, as a yarn",
    "Spun",
    "Made a rug, e.g.",
    "Zigzagged",
    "Didn't go straight",
    "Used a loom",
    "Didn't go straight",
    "Used a loom",
    "Twilled, e.g.",
    "Had work looming?",
    "Braided",
    "Emulated Arachne",
    "Used a loom",
    "Knitted",
    "Used a loom",
    "Crisscrossed",
    "Made a tapestry",
    "Worked like Arachne",
    "Didn't go straight",
    "Made by hand, as a rug",
    "Used a loom"
  ],
  "fess": [
    "Admit, with \"up\"",
    "Own (up)",
    "Admit, with \"up\"",
    "Own (up)",
    "Own (up)",
    "Own (up)",
    "Cop (to)",
    "Admit, with \"up\"",
    "Talk, with \"up\"",
    "Own (up)",
    "Admit, with \"up\"",
    "Come clean, with \"up\"",
    "Come clean, with \"up\"",
    "Parker of \"Old Yeller\"",
    "Admit, with \"up\"",
    "Own (up)",
    "Utter guilt, with \"up\"",
    "Come clean, with \"up\"",
    "Admit (to), with \"up\"",
    "Come clean, with \"up\"",
    "Own (up)",
    "Come clean, with \"up\"",
    "Own (up to)",
    "Come clean, with \"up\"",
    "Own (up)",
    "Own (up to)",
    "Come clean, with \"up\"",
    "Own (up to)",
    "Come clean, with \"up\"",
    "Own (up)",
    "Come clean, with \"up\"",
    "Come clean, with \"up\"",
    "Come clean, with \"up\"",
    "Order to an imp, with \"up\"",
    "Concede, with \"up\"",
    "Come clean, with \"up\"",
    "Admit, with \"up\"",
    "Actor Parker",
    "Actor Parker"
  ],
  "zane": [
    "Grey who wrote \"Riders of the Purple Sage\"",
    "Rachel ___, seven-season TV role for Meghan Markle",
    "Novelist Grey",
    "Billy of \"Titanic\"",
    "Author Grey",
    "Billy of \"Titanic\"",
    "Billy of \"Titanic\"",
    "First name in westerns",
    "Billy who played the Phantom in \"The Phantom\"",
    "Billy of \"Titanic\"",
    "Novelist Grey",
    "Grey who wrote about the Old West",
    "Writer Grey",
    "Western writer Grey",
    "Western writer Grey",
    "Western writer Grey",
    "Western author Grey",
    "Grey who wrote westerns",
    "Western writer Grey",
    "Actor Billy of \"Titanic\"",
    "\"The Phantom\" star Billy",
    "Author Grey",
    "\"Titanic\" co-star, 1997",
    "Actor Billy of \"Titanic\"",
    "Billy of \"Titanic\"",
    "Western writer Grey",
    "Actor Billy of \"Titanic\"",
    "Writer Grey",
    "Author Grey",
    "Grey in literature",
    "Western author Grey",
    "Western writer Grey",
    "Actor Billy of \"Titanic\"",
    "Novelist Grey",
    "Author Grey",
    "Author Grey",
    "Actor Billy of \"Tombstone\"",
    "Novelist Grey",
    "\"Riders of the Purple Sage\" author Grey",
    "Western writer Grey",
    "Writer Grey",
    "First name in westerns",
    "Western novelist Grey"
  ],
  "oafs": [
    "Ham-handed sorts",
    "Dunderheads",
    "Bumbling bunch",
    "Bumbling sorts",
    "Lummoxes",
    "Clods",
    "Clumsy sorts",
    "Klutzes",
    "Lumbering sorts",
    "Bozos",
    "Klutzes",
    "Stumblebums",
    "Bumblers",
    "Boobs",
    "Dullards",
    "Lummoxes",
    "None-too-bright sorts",
    "Apes",
    "Lugs",
    "Boobs",
    "Klutzes",
    "Clodhoppers",
    "Dummkopfs",
    "Lummoxes",
    "Lubbers",
    "Bunglers",
    "Big lugs",
    "Daily trippers?",
    "Klutzy sorts",
    "Fall guys?",
    "Blockheads",
    "Bozos",
    "Klutzes",
    "They have no grace",
    "Bungling sorts",
    "Buffoons",
    "Boobs",
    "Bozos",
    "Thickheads",
    "Dim bulbs",
    "Blockheads",
    "Daily trippers?",
    "Galoots",
    "Klutzes",
    "Meatheads",
    "Bozos",
    "Dimbulbs",
    "Graceless group",
    "Klutzes",
    "Bozos",
    "Klutzes",
    "Gumps",
    "Buffoons",
    "Clods",
    "Lummoxes",
    "Churls",
    "Galoots",
    "Schmoes",
    "Clods",
    "Clods",
    "Schmos",
    "Blockheads",
    "Clods",
    "Dunces"
  ],
  "swam": [
    "Participated in the first third of a triathlon",
    "Didn't just float",
    "Didn't sink, say",
    "Got one's kicks at the pool?",
    "Did a backstroke, say",
    "Traveled in trunks?",
    "Went through channels?",
    "Traveled in trunks, say",
    "Competed in the first leg of a triathlon",
    "Did laps, say",
    "Stroked",
    "Began a triathlon",
    "Did laps",
    "Didn't sink",
    "Participated in a pool, say",
    "Skinny-dipped",
    "Took a dip",
    "Did one leg of an Ironman competition",
    "Did laps",
    "Did laps, maybe",
    "Did laps, say",
    "Started a triathlon",
    "Was in a meet, maybe",
    "Did laps",
    "Did laps, say",
    "Went from bank to bank?",
    "Didn't sink",
    "Dog-paddled",
    "Did laps in a pool",
    "Did one-third of a triathlon",
    "Did the crawl, say",
    "Stroked",
    "Did the butterfly",
    "Emulated Janet Evans",
    "Crawled, say",
    "Was dizzy",
    "Did the butterfly, e.g.",
    "Dog-paddled, e.g.",
    "Did the butterfly",
    "Traveled horizontally",
    "Emulated Janet Evans",
    "Emulated Leander",
    "Used a natatorium",
    "Frog-kicked",
    "Did laps in the pool",
    "Did the crawl",
    "Flutter-kicked"
  ],
  "duds": [
    "Clothing, informally",
    "Hardly smashes",
    "Garb",
    "Fizzling fireworks",
    "Movies with big budgets and no audience",
    "Toggery",
    "Bombs without bags",
    "Things that are put on ... or don't go off",
    "Getup",
    "Bombs",
    "Clothes, informally",
    "Outfit",
    "Bombs without bangs",
    "Threads",
    "Toggery, informally",
    "Turkeys",
    "They don't go off",
    "Bombs that don't explode",
    "Edsels, commercially",
    "Garb",
    "Toggery"
  ],
  "brah": ["Dude, slangily"],
  "gump": [
    "Oscar-winning Hanks role of 1994",
    "Oscar-winning Hanks role",
    "Oscar-winning Hanks role",
    "Forrest ___, 1994 Oscar-winning role",
    "Classic Hanks role",
    "Andy of old comics",
    "Old comic strip family name",
    "Hanks role",
    "1994 Oscar-winning role"
  ],
  "mite": [
    "Tiny bit",
    "Tiny amount",
    "Tiny bit",
    "Smidgen",
    "Tiny bit",
    "Smidge",
    "Wee bit",
    "Tiny bug",
    "Tiny arachnid ... or tiny amount",
    "Bit",
    "Small bit",
    "Teensy bit",
    "Itty-bitty biter",
    "Pesky arachnid",
    "Tiny bit",
    "Iota",
    "Tiny critter",
    "Itsy-bitsy biter",
    "Itsy-bitsy bug",
    "Small amount",
    "Tiny biter",
    "Small thing",
    "Little critter",
    "Pittance",
    "Bit",
    "Tiny bit",
    "Wee parasite",
    "Tiny bit",
    "Little bit",
    "Minim"
  ],
  "burn": [
    "Be angry",
    "Set on fire",
    "Scald, e.g.",
    "Seriously overcook",
    "Sunbathe too long",
    "Make, as a copy of a CD",
    "Dermatologist's concern",
    "Swindle, slangily",
    "Use as fuel",
    "*___ center",
    "It's measured in degrees",
    "Swindle, slangily",
    "Seriously break the trust of, slangily",
    "Beachgoer's woe",
    "Stay too long on the beach",
    "Create, as a CD",
    "Swindle, slangily",
    "Make, as a CD",
    "Scorch",
    "Incinerate",
    "Squander",
    "'69 Brando film",
    "Beachgoer's worry",
    "Swindle, slangily",
    "Overcook",
    "1970 Brando movie"
  ],
  "ibar": [
    "Skyscraper support",
    "Letter-shaped construction support",
    "Building girder",
    "Letter-shaped beam",
    "Crosspiece in a grate, often",
    "Letter-shaped beam",
    "Construction girder",
    "Skyscraper support",
    "Flanged support",
    "Certain steel beam",
    "Bridge support",
    "Letter-shaped girder",
    "Construction piece",
    "Building beam",
    "Construction support",
    "Skyscraper piece",
    "Letter-shaped beam",
    "Letter-shaped construction component",
    "Building unit with flanges",
    "Certain metal beam",
    "Skyscraper component",
    "Construction support",
    "Construction support",
    "Letter-shaped support",
    "Construction beam",
    "Beam of support",
    "Skyscraper support",
    "Flanged structural element",
    "Beam in a bridge",
    "Letter-shaped structural support",
    "Support beam",
    "Letter-shaped beam",
    "Bridge unit",
    "Building piece",
    "Construction piece",
    "Letter-shaped structural piece",
    "Construction beam",
    "Letter-shaped building support",
    "Certain girder",
    "Letter-shaped part of a grate",
    "Letter-shaped beam",
    "Letter-shaped girder",
    "Bridge piece",
    "Building beam",
    "Steel grating component",
    "Certain girder",
    "Letter-shaped girder",
    "Girder with flanges",
    "Building girder",
    "Structural support",
    "Construction beam",
    "Skyscraper support",
    "Construction beam",
    "Strong support",
    "High-rise member",
    "Construction beam",
    "Building beam",
    "Letter-shaped building beam",
    "Support beam",
    "Support beam",
    "Building beam",
    "Building beam",
    "Construction piece",
    "Construction support",
    "Bridge support",
    "Building support",
    "Structural beam",
    "Construction site sight",
    "Construction piece",
    "Building support",
    "Building beam",
    "Supporting member",
    "Construction piece",
    "Construction support",
    "Construction piece",
    "Building support",
    "Construction piece",
    "Bridge support",
    "Construction piece",
    "Steel support"
  ],
  "coif": [
    "Do",
    "Hairstyle",
    "Salon job",
    "Hairdo",
    "Do",
    "Bangs, e.g.",
    "Lock combination?",
    "Do",
    "Salon creation",
    "Stylist's creation",
    "Do",
    "Salon creation",
    "Hairdo",
    "Do",
    "Salon creation",
    "Do",
    "Arrangement of locks",
    "Do",
    "Arrangement of the hair",
    "Bob, e.g.",
    "Do",
    "Do",
    "Hairstyle",
    "Hairdo",
    "Do",
    "Hairdo",
    "Hairdo"
  ],
  "clap": [
    "Loud, sharp sound",
    "Keep time, in a way",
    "Keep the beat, in a way",
    "Applaud",
    "Put your hands together",
    "Give someone a hand",
    "Applaud",
    "___ eyes on (see)",
    "Keep time with the beat, maybe",
    "Give it up, so to speak",
    "Applaud",
    "Give someone a hand",
    "Thunderous sound",
    "Give one a hand",
    "Thunderous sound",
    "Applaud",
    "Go hand to hand?",
    "Give it up, so to speak",
    "Sound in a storm",
    "Show one's appreciation, in a way",
    "What fans do",
    "Loud sound in a storm",
    "Thunderous noise",
    "Flamenco sound",
    "Honor an honoree, say",
    "Thunderous sound",
    "Show of hands?",
    "Go hand to hand?",
    "Pat-a-cake element",
    "Make some noise",
    "Put one's hands together",
    "Applaud",
    "Give a hand?",
    "Applaud",
    "Sound of thunder",
    "Show appreciation, in a way",
    "Summon a servant, maybe",
    "... thunder",
    "Loud, abrupt sound",
    "Show approval, in a way",
    "Applaud",
    "Show appreciation",
    "Keep time, in a way",
    "Join hands?",
    "Thunderous sound",
    "Fan sound",
    "Express approval, in a way",
    "Mark time, in a way",
    "Give a hand",
    "Thunder sound",
    "Express approval",
    "Very loud sound",
    "Loud noise",
    "Sound of thunder",
    "Express glee",
    "Thunderpeal",
    "Join hands?",
    "Sound of shutters in the wind",
    "Thank the singer",
    "Applaud",
    "Bit of thunder",
    "Show appreciation",
    "Show appreciation at a concert",
    "Thunder sound",
    "Switch activator, sometimes",
    "Show one's appreciation",
    "Applaud",
    "Turn on the lights, in a way",
    "Applaud",
    "Sudden noise",
    "Applaud",
    "Applaud",
    "Keep time manually",
    "Storm sound"
  ],
  "yawl": [
    "Fore-and-aft-rigged sailboat with two masts",
    "Two-masted sailing vessel",
    "Two-masted craft",
    "Small two- master",
    "Two-masted vessel",
    "Small boat",
    "Two-master",
    "Two-master"
  ],
  "blur": [
    "Photo mishap",
    "The night before on the day after, maybe",
    "Busy day, in retrospect",
    "Make fuzzy",
    "Fuzzy picture",
    "Photo of a speeding car, maybe",
    "Hazy image",
    "Image of a speeding car, maybe",
    "Hazy memory",
    "Memory of a very busy day, maybe",
    "Picture problem",
    "Obscure",
    "Vague memory",
    "Time-lapse photography phenomenon",
    "Censor, in a way",
    "You can hardly see it",
    "Fuzzy image",
    "Photo mishap",
    "Confuse",
    "Result of a long exposure, often",
    "Astigmatic's view",
    "Memory, sometimes",
    "Fuzzy image",
    "Moving picture?",
    "Sports photographer's bane",
    "Make unclear",
    "Become hazy",
    "Mr. Magoo's vision",
    "Make less clear",
    "Picture problem",
    "The world, to Mr. Magoo",
    "Result of using the wrong film speed, maybe",
    "Bad photo",
    "Astigmatic's view",
    "Memories of a whirlwind trip, maybe",
    "Out-of-focus picture",
    "Photography woe",
    "Cloud",
    "Smear",
    "Fast runner on slow film",
    "Obscure",
    "Smudge",
    "Obfuscate"
  ],
  "goto": [
    "Most-often-used",
    "Attend",
    "Attend, as an event",
    "With 10-Down, turn in",
    "Attend",
    "Attend",
    "First-choice",
    "Attend",
    "Visit",
    "Attend",
    "With 56-Across, a Monopoly order",
    "Attend, as a party",
    "Signal for a programmer's jump",
    "Head for",
    "Seek out",
    "Attend",
    "Common command in Basic programming",
    "___ guy (one who gets things done)",
    "Head for",
    "Head for",
    "Attend",
    "Reach",
    "Attend",
    "Start of a treasure hunt instruction",
    "Head for",
    "Basic computer command",
    "Head for",
    "Attend",
    "Attend",
    "Attend",
    "Don't skip",
    "Attend",
    "Jail or pot lead-in",
    "Computer command",
    "BASIC command",
    "Sunday-meeting link",
    "Computer programming phrase",
    "\"___Jail\" (Monopoly directive)",
    "\"___jail\" (Monopoly directive)",
    "Computer programming phrase",
    "Lead-in for jail or pot"
  ],
  "doer": [
    "More than just a talker",
    "Not the passive sort",
    "Action figure?",
    "No goldbrick",
    "Busy beaver",
    "Executor",
    "Active person",
    "Ending with evil",
    "Go-getter",
    "Active person",
    "Ending for evil or wrong",
    "Not just a talker",
    "Thinker's counterpart",
    "Not just a talker",
    "Go-getter",
    "Busy bee",
    "No idle person",
    "Activist",
    "Action figure?",
    "Go-getter",
    "Active person",
    "Active sort",
    "Action figure?",
    "Active sort",
    "No bystander",
    "Hardly an idler",
    "No layabout",
    "Active sort",
    "Active one",
    "Accomplisher",
    "Active sort",
    "Person of action",
    "Idler's opposite",
    "No couch potato",
    "Action figure?",
    "Person of action",
    "One who's not a procrastinator",
    "Go-getter",
    "Active sort",
    "Perpetrator",
    "No couch potato, him",
    "No layabout",
    "Mover and shaker",
    "Performer",
    "Not the retiring type",
    "Live wire, so to speak",
    "Hardly a side-liner",
    "No mere spectator",
    "One on the go",
    "Wrong end?",
    "No layabout",
    "Live wire, so to speak",
    "Opposite of a thinker",
    "Activist",
    "Activist",
    "Active one",
    "Active person",
    "Energetic one",
    "Not an idler",
    "Mover and shaker",
    "Activist",
    "Active one",
    "Person of action"
  ],
  "mire": [
    "Swampy stretch",
    "Bog down",
    "Bog",
    "Stick in the mud",
    "Wetlands feature",
    "Bog down",
    "Swampland, e.g.",
    "Bog",
    "Embroil",
    "Mud",
    "Swamp",
    "Mucky ground",
    "Stick fast",
    "Muck",
    "Muck",
    "Bog",
    "Muck",
    "Mud",
    "Swamp",
    "Muck",
    "Swamp",
    "Bog",
    "Plowing problem",
    "Stick in the mud",
    "Entangle",
    "Stick in the mud",
    "Swampy ground",
    "Bog",
    "Boggy stuff",
    "Muddy area",
    "Get stuck",
    "Entangle",
    "Bog down",
    "Bog down",
    "Muck",
    "Peat source",
    "Muck",
    "Bog",
    "Entangle",
    "Difficult place to walk",
    "Bad thing to get stuck in",
    "Wallowing place",
    "Bog down",
    "Gooey ground",
    "Bog",
    "Swamp",
    "Swamp",
    "Bog",
    "Oozy ground",
    "Bog",
    "Muck",
    "Cause of getting stuck",
    "Soggy ground",
    "Bog",
    "Bog",
    "Goo",
    "Plowing woe",
    "Deep mud",
    "Bog",
    "Bog",
    "Bog",
    "Slough",
    "Deep mud",
    "Morass",
    "Entangle",
    "Swampy goo",
    "Bog",
    "Swamps area",
    "Muck",
    "Entangle",
    "Bog",
    "Slough",
    "Swamp"
  ],
  "genl": [
    "Mil. leader",
    "Bradley or Patton: Abbr.",
    "Robt. E. Lee, e.g.",
    "Four-star rank: Abbr.",
    "Lee label, for short",
    "Mil. bigwig",
    "Robt. E. Lee, e.g.",
    "U.S.A.F. V.I.P.",
    "One with star power?: Abbr.",
    "Four-star leader: Abbr.",
    "Col.'s superior",
    "Lee, e.g.: Abbr.",
    "Pentagon fig.",
    "Robt. E. Lee, e.g.",
    "Robt. E. Lee, e.g.",
    "Powell or Scott: Abbr."
  ],
  "psis": [
    "Pitchfork-shaped letters",
    "Electric flux symbols",
    "Sorority letters",
    "Symbols of wave functions",
    "Trident-shaped letters",
    "Letters between chis and omegas",
    "Fraternity letters",
    "Penultimate letters",
    "Trident-shaped letters",
    "Greek letters resembling tridents",
    "Wave function symbols",
    "Greek consonants",
    "Electric flux symbols",
    "Fraternity letters",
    "Next-to-last Greek letters",
    "Sorority letters",
    "Fraternity letters",
    "Pitchfork-shaped letters",
    "Fraternity characters",
    "Letters before omegas",
    "Pitchfork-shaped letters",
    "Fraternity characters",
    "Letters after chis",
    "Fraternity letters",
    "Fraternity letters",
    "Greek letters that resemble pitchforks",
    "Greek letters that look like pitchforks",
    "Fraternity letters",
    "Characters in a frat house?",
    "Pitchfork-shaped letters",
    "Greek letters",
    "Trident-shaped letters",
    "Pitchfork-shaped letters",
    "They're trident-shaped",
    "Penultimate letters",
    "Trident-shaped letters",
    "Electric flux symbols",
    "Trident-shaped letters",
    "Trident-shaped letters",
    "Particle flux density symbols",
    "They're pitchfork-shaped",
    "Trident-shaped letters",
    "They're trident-shaped",
    "They're trident-shaped",
    "They're painted on some frat houses",
    "Trident-shaped symbols",
    "Letters that look like tridents",
    "Letters before omegas",
    "Greek penultimates",
    "Telepathy and clairvoyance, e.g.",
    "Electric flux symbols",
    "Letters before omegas",
    "Greek consonants"
  ],
  "saul": [
    "Literature Nobelist Bellow",
    "Literature Nobelist Bellow",
    "Philistine-fighting king",
    "Biblical king who sought the counsel of the Witch of Endor",
    "AMC's \"Better Call ___\"",
    "Anointed one in the Book of Samuel",
    "First king of Israel",
    "For whom David played the harp",
    "Bellow in a bookstore",
    "Handel work featuring David",
    "King who consulted the Witch of Endor",
    "Israel's first king",
    "Old Testament king",
    "Author Bellow",
    "Handel oratorio king",
    "Old Testament king",
    "First king of Israel",
    "Fellow named Bellow",
    "Handel oratorio",
    "Bellow in a library",
    "Literature Nobelist Bellow",
    "Bellow in the library",
    "Handel oratorio",
    "Book of Samuel character",
    "David's biblical predecessor",
    "\"Herzog\" author Bellow",
    "King of a Handel oratorio",
    "Handel oratorio",
    "Jonathan's father, in the Bible",
    "Author Bellow",
    "Author Bellow",
    "First king of Israel"
  ],
  "cain": [
    "First offer?",
    "Caleb represents him in \"East of Eden\"",
    "Something a complainer might raise",
    "Brother of Seth, in the Bible",
    "Problematic firstborn",
    "Genesis son",
    "Third person",
    "Who asked \"Am I my brother's keeper?\"",
    "Herman who ran for the 2012 Republican nomination",
    "\"Am I my brother's keeper?\" speaker",
    "Initial offer?",
    "Abel's brother",
    "Third person",
    "Mark of ___",
    "Brother of Abel",
    "Genesis brother",
    "First son",
    "First murderer",
    "Crop farmer of Genesis",
    "Lord Byron biblical drama",
    "\"Double Indemnity\" writer, 1936",
    "It may be raised at a party",
    "Brother of Abel",
    "James who wrote \"The Postman Always Rings Twice\"",
    "First born?",
    "Genesis brother",
    "First child",
    "Genesis firstborn",
    "Biblical sibling",
    "Brother of Abel",
    "Genesis son",
    "Noted exile",
    "First offer?",
    "Raise ___ (cause a ruckus)",
    "Genesis son",
    "Nod resident",
    "This may be raised",
    "\"Double Indemnity\" author",
    "Early offspring",
    "\"Double Indemnity\" novelist",
    "Old Testament murderer",
    "He went east of Eden",
    "\"The Postman Always Rings Twice\" author",
    "Dean of \"Lois & Clark\"",
    "Early tiller",
    "\"And the Lord set a mark upon ___\"",
    "He wrote \"The Postman Always Rings Twice\""
  ],
  "boop": [
    "\"I touched your nose!\" sound",
    "Cutesy sound accompanying a poke",
    "Hit playfully on the nose, slangily",
    "Betty ___",
    "Big-eyed Betty",
    "Betty who appeared in \"Who Framed Roger Rabbit\"",
    "Toon toned down for the 1930s Hays Code",
    "Curly-haired toon",
    "Flirty toon Betty ___",
    "Betty ___",
    "Betty of comics",
    "Betty of \"Dizzy Dishes\"",
    "Betty of cartoon fame",
    "Betty ___",
    "Betty ___",
    "Old comics flapper",
    "Betty of cartoons",
    "1930's comics girl",
    "Betty of cartoons"
  ],
  "boxy": [
    "Squarish",
    "Squarish",
    "Squarish",
    "Hardly aerodynamic",
    "Far from aerodynamic",
    "Hardly streamlined, as a car",
    "Like the Honda Element",
    "Like many 80's cars",
    "Squared at the edges",
    "Not streamlined"
  ],
  "ross": [
    "One of the friends on \"Friends\"",
    "Antarctica's ___ Sea",
    "Antarctica's ___ Ice Shelf",
    "One of the friends on \"Friends\"",
    "Harold who co-founded The New Yorker magazine",
    "One of the friends on \"Friends\"",
    "One of the friends on \"Friends\"",
    "Diana who led the Supremes",
    "One of the friends on \"Friends\"",
    "One of the friends on \"Friends\"",
    "Op-ed writer Douthat",
    "\"Friends\" character ___ Geller",
    "One of the friends on \"Friends\"",
    "Antarctica's ___ Ice Shelf",
    "Who set the standard for the United States?",
    "One of the friends on \"Friends\"",
    "Sewer of note",
    "___ Sea (Bay of Whales locale)",
    "One of the friends on \"Friends\"",
    "Clooney's \"ER\" role",
    "Antarctica's ___ Ice Shelf",
    "Katharine of \"The Graduate\"",
    "\"King Richard II\" lord",
    "\"Friends\" friend",
    "\"ER\" doctor",
    "Woman depicted in \"The Birth of Old Glory\"",
    "With 46-Across, Antarctic waters",
    "Supreme leader?",
    "Seamstress Betsy",
    "One of TV's \"Friends\"",
    "Supreme leader",
    "Politico Perot",
    "Famed sewer",
    "\"Mahogany\" star, 1975",
    "Nellie ___, first female state governor",
    "\"ER\" doctor",
    "Supreme Diana",
    "Flagmaker Betsy",
    "Clooney's \"ER\" role",
    "Katharine of \"The Graduate\"",
    "One of the friends on \"Friends\"",
    "\"Friends\" character",
    "Historical sewer",
    "Founding editor of The New Yorker",
    "She had a 1980 #1 hit with \"Upside Down\"",
    "Friend in \"Friends\"",
    "With 40-Across, McMurdo Sound locale",
    "One of the friends on \"Friends\"",
    "Lord in Shakespeare's \"Richard II\"",
    "\"The Goodbye Girl\" director Herbert",
    "Flagmaker Betsy",
    "Supreme leader",
    "\"Lady Sings the Blues\" star",
    "The supreme Supreme",
    "\"ER\" doctor",
    "\"Mahogany\" vocalist Diana",
    "Singer Diana",
    "\"Friends\" role",
    "Antarctic explorer Sir James",
    "Supreme Diana",
    "Seamstress Betsy",
    "Supreme Diana",
    "The supreme Supreme",
    "\"ER\" doc",
    "Bill and Bob's opponent",
    "\"E.R.\" doctor",
    "Flagmaker Betsy",
    "Flagwoman?",
    "Diana of the Supremes",
    "Betsy or Diana",
    "The New Yorker founder Harold",
    "Seamstress Betsy",
    "\"ER\" medic",
    "See 24 Down",
    "Mr. Perot",
    "Seamstress Betsy",
    "Longtime New Yorker editor",
    "Famous sewer",
    "Founding New Yorker editor",
    "Flagmaker Betsy"
  ],
  "enzi": [
    "Mike who served as a Wyoming senator from 1997 to 2021",
    "Senator Mike from Wyoming",
    "Wyoming senator Mike"
  ],
  "plod": [
    "Lumber",
    "Move laboriously",
    "Tread slowly",
    "Not tread lightly",
    "Trudge",
    "Trudge",
    "Lumber along",
    "Go laboriously",
    "Lumber",
    "Proceed slowly",
    "Get through dull work",
    "Opposite of race",
    "Plug along",
    "Walk wearily",
    "Trudge (along)",
    "Lumber",
    "Work monotonously",
    "Move slowly",
    "Don't tread lightly",
    "Trudge (through)",
    "Lumber",
    "Proceed slowly",
    "Trudge",
    "Trudge",
    "Proceed slowly",
    "Plug away",
    "Trudge",
    "Trudge",
    "Trudge",
    "Trudge",
    "Trudge",
    "Slog",
    "Plug along",
    "Trudge",
    "Slog (through)",
    "Walk wearily",
    "Trudge"
  ],
  "brut": [
    "Word on a Champagne bottle",
    "Champagne specification",
    "Fragrance created by Faberge",
    "Very dry, as Champagne",
    "Very dry, as Champagne",
    "Very dry, as Champagne",
    "Like some sparkling wines",
    "Polo alternative",
    "Faberge cologne",
    "Very dry",
    "Very dry",
    "Aftershave sold in green bottles",
    "Polo alternative",
    "Faberge cologne",
    "Very dry, as wine",
    "English Leather alternative",
    "Very dry",
    "Very dry, as Champagne",
    "Very dry, as wine",
    "Champagne designation",
    "Like some champagne",
    "Very dry",
    "Old Spice alternative",
    "Champagne word",
    "Very dry",
    "Like some champagne"
  ],
  "jibs": [
    "Triangular sails",
    "Triangular sails",
    "Sails fixed to bowsprits",
    "Yacht parts",
    "Triangular sails",
    "Sloops' headsails",
    "Sails on sloops",
    "Some sails",
    "Sails",
    "Triangular sails"
  ],
  "swan": [
    "Lead-in to son or song",
    "___ song",
    "The ugly duckling, eventually",
    "Former ugly duckling, in story",
    "Equine : horse :: cygnine : ___",
    "Bird that usually mates for life",
    "Lake or dive preceder",
    "___ song",
    "What the Ugly Duckling became",
    "The Ugly Duckling, actually",
    "A cygnet is a baby one",
    "Graceful avian swimmer",
    "Word before dive or song",
    "Bird in a Hans Christian Andersen tale",
    "Ugly Duckling, eventually",
    "Pen, e.g.",
    "Graceful trumpeter",
    "Pavlova portrayed one over 4,000 times",
    "Pond swimmer",
    "Zeus' disguise when fathering Helen of Troy",
    "\"___ Lake\"",
    "Bird in a bevy",
    "Constellation next to the Dragon, with \"the\"",
    "Trumpeting bird",
    "Trumpeter with a prominent neck",
    "Kind of song",
    "Graceful swimmer",
    "Park lake denizen",
    "Ugly duckling, eventually",
    "Symbol of gracefulness",
    "Sweet ___ of Avon (epithet for Shakespeare)",
    "Pen, e.g.",
    "\"___ Lake\"",
    "Zeus, in another form",
    "Bird on a lake",
    "Epitome of grace",
    "Exemplar of grace",
    "Long-necked bird",
    "Ugly Duckling, in reality",
    "Pen with eggs",
    "Pavlova portrayal",
    "Bird in a bevy",
    "Whooper, e.g.",
    "E. B. White's \"The Trumpet of the ___\"",
    "Trumpeter in the park",
    "Lohengrin, the Knight of the ___",
    "Park lake denizen",
    "Ugly Duckling, eventually",
    "Kind of song",
    "Exemplar of grace",
    "Beautiful swimmer",
    "Kind of dive",
    "Certain trumpeter",
    "Graceful bird",
    "Majestic swimmer",
    "Pen that swims",
    "\"I will play the ___, and die in music\": Emilia",
    "Ugly duckling, ultimately",
    "Northern constellation, with \"the\"",
    "Gottfried, in \"Lohengrin\"",
    "Ugly Duckling, actually"
  ],
  "bowe": [
    "Who defeated Holyfield to become the world heavyweight boxing champion in 1992",
    "Ring champ Riddick",
    "Heavyweight champ Riddick",
    "Heavyweight champ Riddick",
    "Champion between Holyfield reigns",
    "Boxer Riddick",
    "He took Holyfield's title away in 1992",
    "Heavyweight champ Riddick",
    "Boxer Riddick",
    "1992-93 heavyweight champ"
  ],
  "nope": [
    "Informal denial",
    "\"That's a negatory!\"",
    "\"Huh-uh\"",
    "Slangy refusal",
    "\"Huh-uh\"",
    "\"In your dreams!\"",
    "\"Negatory\"",
    "Yep's opposite",
    "\"Ain't happening\"",
    "\"Uh-uh\"",
    "Yep's opposite",
    "\"Fuhgeddaboudit\"",
    "\"Uh-uh!\"",
    "Colloquial denial",
    "\"Uh-uh\"",
    "Slangy dissent",
    "\"I don't think so\"",
    "\"Fat chance!\"",
    "\"Uh-uh\"",
    "\"Can't help ya!\"",
    "\"Don't think so!\"",
    "\"You're mistaken\"",
    "\"Ain't gonna happen\"",
    "\"Not a chance\"",
    "\"Uh-uh\"",
    "\"Ixnay\"",
    "\"Uh-uh\"",
    "Casual rejection",
    "Informal demurral",
    "Informal demurral",
    "\"I don't think so\"",
    "\"Uh-uh\"",
    "\"Uh-uh\"",
    "\"I don't think so\"",
    "Informal rejection",
    "\"Uh-uh!\"",
    "\"Forget about it\"",
    "Yep's opposite",
    "\"Huh-uh\"",
    "\"Uh-uh\"",
    "Opposite of 15-Across",
    "\"Uh-uh\"",
    "Slangy denial",
    "\"Uh-uh\"",
    "Slangy denial",
    "\"Uh-uh\"",
    "\"Uh-uh\"",
    "Yep's opposite",
    "\"I don't think so\"",
    "\"Uh-uh\"",
    "\"Forget it!\"",
    "Slangy denial",
    "\"Huh-uh\"",
    "Nah",
    "\"Don't think so\"",
    "\"Negatory!\"",
    "Casual dissent",
    "Informal refusal"
  ],
  "read": [
    "Perceptive sense",
    "What many children begin to do in kindergarten",
    "Do a parent's bedtime activity",
    "Run over",
    "Email status",
    "Inbox label counterpart of \"New\"",
    "Show literacy",
    "Text notification before a time stamp",
    "Went over",
    "Try for a part",
    "Text message status",
    "Follow the script",
    "Get a ___ on someone",
    "Homophone of 46-Across",
    "Enjoy Wilde or Wilder, say",
    "Interpret",
    "Studied",
    "Like books and tea leaves",
    "Peruse",
    "Enjoy a book",
    "Went over",
    "Enjoy literature",
    "Like some tea leaves",
    "Scanned",
    "What library patrons do",
    "Librarian's urging",
    "Use a Kindle, say",
    "Scan, as a U.P.C.",
    "Interpret",
    "Librarian's urging",
    "Audition (for)",
    "Do library study",
    "With 40-Across, infer something ... and literally so",
    "Interpret",
    "Enjoyed London or France",
    "Interpret",
    "Peruse",
    "Go over Time?",
    "Librarian's imperative",
    "Use cue cards",
    "Go for a part",
    "Deciphered",
    "Size up",
    "Try for a role",
    "Reference books?",
    "Go through volumes",
    "Try for a role",
    "Go through",
    "Use the library",
    "Scan",
    "Interpret",
    "\"___ my lips!\"",
    "Prophesy",
    "Scan",
    "Take to mean",
    "Try for a part",
    "Take in the mail",
    "Get between the covers?",
    "Examine volumes",
    "Interpret",
    "Make out",
    "Like books",
    "Scanned",
    "Scan, say",
    "Use a library",
    "Use a book",
    "Audition",
    "Interpret",
    "Try for a part",
    "Devour, in a way",
    "Do library research",
    "Librarian's advice",
    "Library urging",
    "Peruse",
    "Did Time?",
    "Study",
    "Pored over",
    "Construed",
    "Library byword",
    "Deciphered",
    "Soothsay",
    "Understand",
    "Interpret",
    "Crack the books",
    "Do one of the three R's",
    "Audition for a part",
    "Interpret",
    "Study, as text",
    "Library poster message",
    "Interpret",
    "Pore over",
    "Bone up on",
    "Used the library",
    "\"Alive\" author Piers Paul ___",
    "Interpret"
  ],
  "vela": [
    "Soccer star Carlos",
    "Curtainlike partitions, biologically",
    "The Sail constellation"
  ],
  "lyme": [
    "___ disease",
    "Kind of disease with a telltale bull's-eye rash",
    "Eponymous town in southeastern Connecticut",
    "Historic town in SE Connecticut",
    "___ disease",
    "Tick-borne affliction",
    "Old ___, Conn.",
    "___ disease",
    "___ disease",
    "___ disease",
    "Old ___, Conn.",
    "Old ___, Conn.",
    "East ___, Conn.",
    "Old ___, Conn.",
    "Connecticut community",
    "Old___, Conn.",
    "___ disease (tick affliction)"
  ],
  "noob": [
    "One getting pwned in online gaming",
    "Gaming novice, slangily",
    "Gaming novice, slangily",
    "Beginner, in modern lingo",
    "Easy kill in Fortnite, say",
    "Novice gamer",
    "Tyro, in modern parlance",
    "Gaming neophyte",
    "Inexperienced gamer, in slang",
    "Beginner, in video game lingo",
    "Video game neophyte, informally",
    "Neophyte, informally",
    "Pro's opposite, in slang"
  ],
  "cera": [
    "Michael of \"Scott Pilgrim vs. the World\"",
    "Actor Michael of \"Juno\"",
    "Michael of \"Superbad\"",
    "Actor Michael of \"Juno\"",
    "Michael of \"Arrested Development\"",
    "\"Juno\" actor Michael",
    "Michael of \"Juno\"",
    "Star of \"Scott Pilgrim vs. the World,\" 2010",
    "Michael of \"Arrested Development\"",
    "Michael of \"Juno\"",
    "Michael of \"Arrested Development\"",
    "Michael of \"Arrested Development\"",
    "Michael of \"Juno\"",
    "Michael of \"Superbad\"",
    "Michael of \"Arrested Development\"",
    "Michael of \"Arrested Development\"",
    "Michael of \"Juno\"",
    "Michael of \"Superbad\"",
    "Michael of \"Juno\"",
    "Michael of \"Superbad\"",
    "Michael of \"Scott Pilgrim vs. the World\"",
    "Michael of \"Juno\" and \"Superbad\""
  ],
  "ives": [
    "St. ___, locale in an English nursery rhyme",
    "James Merritt ___, pioneer in American lithography",
    "Singer/actor who narrated 1964's \"Rudolph the Red-Nosed Reindeer\"",
    "Currier and ___",
    "Composer Charles",
    "\"Central Park in the Dark\" composer",
    "Saint in a children's rhyme",
    "Currier's partner",
    "Composer of the \"Concord\" Sonata",
    "Currier and ___",
    "Currier's partner in lithography",
    "Burl who sang about Rudolph",
    "\"Riders in the Sky\" singer, 1949",
    "\"Venus in Fur\" playwright David",
    "\"A Little Bitty Tear\" singer, 1962",
    "James Merritt ___, pioneering lithographer",
    "Composer Charles",
    "Composer Charles",
    "Saint of a children's rhyme",
    "Currier and ___",
    "St. ___, Cornwall",
    "First major publisher of board games in the U.S.",
    "Composer Charles",
    "\"A Little Bitty Tear\" singer, 1962",
    "St. ___, place in a children's rhyme",
    "Burl of stage and song",
    "St. in a children's rhyme",
    "Burl who won an Oscar for \"The Big Country\"",
    "\"Universe Symphony\" composer",
    "Currier's partner in lithography",
    "\"The Unanswered Question\" composer, 1908",
    "Currier's partner in lithography",
    "Currier and ___",
    "\"Three Places in New England\" composer",
    "Big Daddy player on 1950s Broadway",
    "Composer Charles",
    "\"Three Places in New England\" composer",
    "St. ___ (Cornwall resort town)",
    "Currier and ___",
    "Currier's partner",
    "Composer Charles",
    "Noted Americana lithographer",
    "\"Concord Sonata\" composer",
    "Name on many prints",
    "\"A Little Bitty Tear\" hitmaker, 1962",
    "Big name in lithography",
    "Currier's partner",
    "\"Concord\" Sonata composer",
    "\"Variations on 'America'\" composer",
    "\"Funny Way of Laughin'\" singer, 1962",
    "Currier's partner in lithography",
    "\"A Little Bitty Tear\" hit maker, 1962",
    "American composer who pioneered in polytonality",
    "Lithographer of Americana",
    "Frederic who invented halftone photoengraving",
    "\"A Little Bitty Tear\" singer",
    "Currier's partner",
    "Folk singer Burl",
    "\"The Celestial Country\" composer",
    "Lithographer James",
    "\"A Little Bitty Tear\" singer, 1962",
    "Currier's partner",
    "Currier's partner",
    "Big Daddy portrayer, in \"Cat on a Hot Tin Roof\"",
    "\"Concord Sonata\" composer",
    "Currier's partner in lithography",
    "Pulitzer winner for \"Symphony No. 3\"",
    "Currier's companion",
    "Currier's partner",
    "\"Concord\" composer",
    "\"A Little Bitty Tear\" singer, 1962",
    "\"A Little Bitty Tear\" singer, 1962",
    "Noted lithographer",
    "\"O For a Thousand Tongues\" composer",
    "Singer Burl",
    "\"Concord Sonata\" composer",
    "\"Concord\" sonata composer",
    "St. ___ (English seaside resort)",
    "\"Concord Sonata\" composer",
    "Patron saint of lawyers",
    "Oscar winner in \"The Big Country\"",
    "\"As I was going to St. ___...\"",
    "Composer Charles",
    "\"The Camp Meeting\" composer",
    "1958 Oscar winner for \"The Big Country\"",
    "Noted name in lithography",
    "1947 Pulitzer composer"
  ],
  "crip": [
    "\"___ Camp,\" 2020 Oscar-nominated documentary",
    "Blood's foe",
    "Gang member associated with the color blue",
    "Blood enemy",
    "L.A. gang member",
    "Noted avoider of the color red",
    "Blood rival",
    "Blood rival",
    "L.A. gangster",
    "Blood's counterpart",
    "Blood's rival",
    "L.A. gang member",
    "Los Angeles gang member"
  ],
  "cady": [
    "Women's rights pioneer Elizabeth ___ Stanton",
    "Suffragist Elizabeth ___ Stanton",
    "Women's rights pioneer Elizabeth ___ Stanton",
    "Suffragist Elizabeth ___ Stanton",
    "Women's rights pioneer Elizabeth ___ Stanton",
    "Women's rights pioneer Elizabeth ___ Stanton",
    "Children's illustrator Harrison ___",
    "19th-century women's rights advocate Elizabeth ___ Stanton"
  ],
  "axed": [
    "Cut",
    "Eighty-sixed",
    "Cut",
    "Got rid of",
    "Removed from the schedule, as a TV show",
    "Gave the heave-ho",
    "Fired",
    "Gave the pink slip",
    "Fired",
    "Fired",
    "Canned",
    "Pink-slipped",
    "Canceled",
    "86'ed",
    "Let go",
    "Cut from the payroll",
    "Cut, in a way",
    "Pink-slipped",
    "Brought down, as a tree",
    "Dismissed",
    "Let go",
    "Got rid of",
    "Reduced drastically",
    "Given the heave-ho",
    "Chopped down",
    "Fired",
    "Pink-slipped",
    "Gave the boot",
    "Abruptly dismissed",
    "In a split way",
    "Canned",
    "Canned",
    "Canned",
    "Pink-slipped",
    "Canned",
    "Dumped",
    "Laid off, as workers",
    "Gave the boot",
    "Let go",
    "Pink-slipped",
    "Took a bough?",
    "Gave the boot",
    "Dismissed",
    "Given the heave-ho",
    "Canned",
    "Chopped",
    "Sent packing",
    "Involuntarily out of a job",
    "Canned",
    "Cut",
    "Canned",
    "Fired",
    "Canned",
    "Canned",
    "Pink-slipped",
    "Got rid of, in a way",
    "Pink-slipped",
    "Let go",
    "Canned",
    "Pink-slipped",
    "Gave the boot",
    "Fired",
    "Canceled",
    "Let go",
    "Terminated",
    "Dismissed",
    "Pink-slipped",
    "Let go",
    "Canceled"
  ],
  "sony": [
    "PlayStation's creator",
    "___ Pictures, one of Hollywood's Big Five studios",
    "___ Pictures",
    "PlayStation company",
    "RCA competitor",
    "Conglomerate originally named Tokyo Tsushin Kogyo",
    "Tokyo-based electronics giant",
    "PlayStation maker",
    "Electronics giant",
    "Owner of Columbia Pictures",
    "Maker of Aibo robotic pets",
    "PlayStation maker",
    "Memory Stick manufacturer",
    "Vaio manufacturer",
    "LG Electronics competitor",
    "PlayStation maker",
    "___ Pictures",
    "\"make.believe\" sloganeer",
    "\"Like no other\" sloganeer",
    "___ Pictures (entertainment company)",
    "Electronics giant",
    "Discman maker",
    "Sharp rival",
    "RCA competitor",
    "Mitsubishi competitor",
    "MGM Studios owner",
    "Major TV brand",
    "Matsushita competitor",
    "Camcorder maker",
    "PlayStation maker",
    "Company that introduced the transistor radio in 1952",
    "Walkman maker",
    "Magnavox rival",
    "PlayStation 2 maker",
    "Walkman maker",
    "Big name in television",
    "PlayStation maker",
    "PlayStation maker",
    "Walkman maker",
    "Sharp competitor",
    "Handycam maker",
    "Big name in electronics",
    "Trinitron TV maker",
    "Walkman maker",
    "Big name in electronics",
    "Electronics giant",
    "CD player maker",
    "Fisher rival",
    "Audio industry giant",
    "Company co-founded by Akio Morita",
    "Hitachi competitor"
  ],
  "pasa": [
    "\"Qué ___?\"",
    "\"Que ___?\"",
    "\"No ___ nada\" (\"It's all good\": Sp.)",
    "\"El Condor ___\" (1970 Simon & Garfunkel hit)",
    "Simon & Garfunkel's \"El Condor ___\"",
    "\"Que ___?\"",
    "Simon & Garfunkel's \"El Condor ___\"",
    "\"No ___ nada\" (\"Don't worry about it\": Sp.)",
    "\"Que ___?\"",
    "\"Que ___?\"",
    "Simon & Garfunkel's \"El Condor ___\"",
    "\"Que ___?\"",
    "\"Que ___?\"",
    "\"Que ___?\"",
    "\"Que ___?\"",
    "?ôQue ___??ö",
    "\"Que ___?\"",
    "See 35-Across",
    "\"Que ___?\""
  ],
  "rote": [
    "Memorization",
    "Mindless",
    "Learn by ___",
    "Mechanical",
    "Going over something again and again and again",
    "Mechanical",
    "Tedious way to learn",
    "Boring way to learn",
    "Mechanical repetition",
    "Repetitive means of learning",
    "Mechanical learning",
    "You can learn something by this",
    "Memorization",
    "Repetitive learning",
    "Monotonous learning",
    "*Turn, as a wheel",
    "Memorization",
    "Learning by recitation",
    "Learn by ___",
    "Tedious learning method",
    "Mechanical",
    "Mindless",
    "Drills that can bore",
    "Learning by memorization",
    "Memorization",
    "Repetitive learning",
    "Repetition",
    "Repetition",
    "Tedious way to learn",
    "Learning method",
    "Learning by flash cards, e.g.",
    "Repetitive learning technique",
    "Repetitive routine",
    "Mechanical",
    "Saying again and again",
    "Memorization method",
    "You might give a speech by this",
    "Mechanical",
    "Habit",
    "Learning method",
    "Memory work",
    "Memorization",
    "Education by memorization",
    "Repetitive process",
    "Fixed course",
    "Some learning",
    "Old-fashioned education",
    "School drills, say",
    "Learning method",
    "Unthinking",
    "Monotonous learning",
    "Learning may be done by it",
    "Memorization technique",
    "Learning style",
    "Classroom drudgery",
    "Boring way to learn",
    "Way to learn, after \"by\"",
    "Uncreative education method",
    "Some schoolwork",
    "Uncreative learning method",
    "Classroom drills",
    "Superficial teaching method",
    "Repetitive learning method",
    "Classroom routine",
    "Uncreative response",
    "Mindless repetition",
    "Uninspired teaching method",
    "Learning style",
    "Learning by flashcards, e.g.",
    "Automatic course",
    "Memorization method",
    "Mechanical course",
    "Kind of memorization",
    "Mechanical learning",
    "Learning system",
    "Learning the times table, e.g.",
    "Habit",
    "Dull routine",
    "Kind of learning",
    "Some learning",
    "Uncreative education",
    "Some schoolwork",
    "School drills",
    "Learning method",
    "Mechanical method",
    "Some learning",
    "Old-style education",
    "Alternative to the new math",
    "Learning method",
    "Set system",
    "Mechanical, after \"by\"",
    "Old-fashioned learning",
    "Habitual procedure",
    "Uncreative education",
    "Learning method",
    "Unthinking response",
    "Old-fashioned learning method",
    "Dull routine",
    "Repetition",
    "Learning method",
    "Some schoolwork",
    "Mechanical memorization"
  ],
  "boss": [
    "Top dog",
    "\"Like a ___!\"",
    "Office head",
    "Head honcho",
    "Head honcho",
    "Cool, colloquially",
    "Order (around)",
    "Director",
    "Big enchilada",
    "Higher-up",
    "Office head",
    "Office monitor",
    "Office V.I.P.",
    "Hirer/firer",
    "Order (around)",
    "Give orders to",
    "Head of an office",
    "One who's in charge",
    "Decision-maker",
    "One giving orders",
    "Terrific, in slang",
    "One calling the shots",
    "Rad",
    "Head honcho",
    "Honcho",
    "One with fire power?",
    "Big guy",
    "\"Who's the ___?\"",
    "Head honcho",
    "Order (around)",
    "Bruce Springsteen, with \"the\"",
    "Order (around)",
    "Padrone",
    "Big cheese",
    "One giving orders",
    "Cool, once",
    "Chief",
    "Push (around)",
    "Order (around)",
    "Political V.I.P.",
    "Anagram of 71-Down",
    "Order (around)",
    "Director",
    "Political V.I.P.",
    "Supervisor",
    "Tweed, for one",
    "Head honcho",
    "Office chief",
    "Commander",
    "Give orders",
    "Office honcho"
  ],
  "duty": [
    "___-free",
    "Responsibility",
    "Responsibility",
    "Word with free or bound",
    "Job description detail",
    "Airport patrons often avoid it",
    "Import tax",
    "Word before free or bound",
    "Shirkers shirk it",
    "Carrying out the trash, maybe",
    "Assigned task",
    "Scout's pledge word",
    "Tax",
    "It's an imposition",
    "Responsibility",
    "Word before free or calls"
  ],
  "quip": [
    "Bon mot",
    "Witty remark",
    "Witty remark",
    "Witticism",
    "One-liner",
    "Witticism",
    "Bon mot",
    "Witticism",
    "W. C. Fields's \"I am free of all prejudice. I hate everyone equally,\" e.g.",
    "Instance of levity",
    "Steven Wright's \"I intend to live forever. So far, so good,\" e.g.",
    "One-liner",
    "Witty bit",
    "Bon mot",
    "One-liner",
    "Wise words?",
    "Bon mot",
    "Will Rogers specialty",
    "Bon mot",
    "Wit's remark",
    "Witticism",
    "Groucho remark",
    "Witticism",
    "Leno line",
    "Groucho remark",
    "Bon mot",
    "Witticism",
    "Witty remark",
    "Wisecrack",
    "Crack",
    "Will Rogers line",
    "Wilde comment",
    "Wisecrack",
    "Witticism"
  ],
  "mcan": [
    "Thom ___ (shoe brand)",
    "Last name in shoes",
    "Last name on a shoe box",
    "Classic shoe name",
    "Thom ___ shoes",
    "Thom ___ shoes",
    "Last name on a 40-Down",
    "Thom ___ shoes",
    "Big name in footwear",
    "Thom ___ shoes",
    "Thom ___ footwear",
    "Thom ___ shoes",
    "Big name in footwear",
    "Big name in footwear",
    "Name on a shoe",
    "Footwear giant Thom ___",
    "Thom ___ shoes",
    "Big name in footwear",
    "Thom ___ shoes",
    "Shoemaker Thom",
    "Thom ___ shoes",
    "Thom ___ shoes",
    "Thom ___ shoe stores"
  ],
  "flee": [
    "Scram",
    "Make tracks",
    "Head for the hills",
    "Escape",
    "Hightail it away",
    "Get out of Dodge",
    "Run away",
    "Escape",
    "Escape (from)",
    "Head for the hills",
    "Scoot",
    "Get out of town",
    "Hightail it away",
    "Amscray",
    "Abscond",
    "Fugitate",
    "Hightail it",
    "Leave, as out of fear",
    "Run for it",
    "Cut out",
    "Take off",
    "Take off",
    "Bolt",
    "Disappear",
    "Run off",
    "Opposite of charge",
    "Skip",
    "Hightail it",
    "Run for it",
    "Beat it",
    "Take off",
    "Run",
    "Fugitate",
    "Run away",
    "Turn tail",
    "Take off",
    "Run",
    "Take off",
    "Run away",
    "Go on the lam",
    "Turn tail",
    "Hightail it",
    "Make tracks",
    "Exit quickly",
    "Run away",
    "___ Bailey",
    "Make tracks",
    "Take off",
    "Run for it",
    "Head for the hills",
    "Escape battle",
    "Beat it",
    "Bolt",
    "Hightail it",
    "Run",
    "Vamoose",
    "Disappear",
    "Run",
    "Beat a retreat",
    "Skedaddle",
    "Cut and run",
    "Escape"
  ],
  "pile": [
    "Rug buyer's consideration",
    "Word with junk or dog",
    "Accumulate, with \"up\"",
    "Stack",
    "Laundry room accumulation",
    "Stack (up)",
    "Bundle",
    "Nap",
    "Mound",
    "Load of money",
    "Mount, with \"up\"",
    "Reactor",
    "Tidy sum",
    "Ton",
    "Carpet surface",
    "Untidy stack",
    "Heap",
    "Accumulation",
    "Quite a tidy sum",
    "Carpet feature",
    "Heap",
    "It may be down",
    "Carpet feature",
    "Mound",
    "Accumulate, with \"up\"",
    "Carpet choice",
    "Work unit",
    "Stack",
    "Lot of loot",
    "Carpet quality",
    "Tidy sum",
    "Lot of loot",
    "Quite a cache",
    "Aggregate",
    "Good amount of money",
    "Stack",
    "Fortune",
    "Nap",
    "Stack",
    "Lots of money",
    "Mound",
    "Stack",
    "Enter as a group, with \"in\"",
    "Pier foundation",
    "Accumulation",
    "Nuclear reactor",
    "Carpet surface",
    "Mass",
    "Bundle"
  ],
  "tase": [
    "Stun, in a way",
    "Stun, in a way",
    "Stun, in a way",
    "Stun, in a way",
    "Zap",
    "Criminal charge?",
    "Issue a charge against",
    "Stun with a gun",
    "Give quite a shock",
    "Shock, in a way",
    "Subdue through electric shock",
    "Hit with a charge",
    "Zap, in a way",
    "Hit with a big charge",
    "Zap, in a way",
    "Stun with a gun",
    "Electrically stun",
    "Incapacitate, in a way",
    "Subdue with a stun gun",
    "Shock, in a way",
    "Shock, in a way",
    "Incapacitate, in a way",
    "Shock, in a way",
    "Shock, in a way",
    "Zap, in a way",
    "Hit with a charge",
    "Take down with a charge",
    "Stun with a charge",
    "Zap, in a way",
    "Stun, as a suspect",
    "Zap with a stun gun",
    "Stun, as with a police gun",
    "Hit with a charge",
    "Stun, in a way"
  ],
  "mono": [
    "Like early Elvis records",
    "Common campus health diagnosis",
    "Lead-in to rail",
    "The \"M\" of MSG",
    "Not stereo",
    "Part of MSG",
    "Like early recordings",
    "Like early Elvis recordings",
    "Like early LPs",
    "One-track",
    "Like old records",
    "Little bit of MSG?",
    "One-track",
    "Like original Buddy Holly and the Crickets recordings",
    "One up front?",
    "Part of MSG",
    "\"The kissing disease\"",
    "Like early vinyl records",
    "Pre-stereo format",
    "Source of some fevers",
    "MSG ingredient?",
    "One to start?",
    "Teen woe",
    "Like early Elvis albums",
    "Early Beatles songs are in it",
    "Like early Beatles recordings",
    "Not stereo",
    "Precursor to 41-Across",
    "Like the earliest Beatles recordings",
    "Like early Elvis Presley albums, e.g.",
    "With 69-Across, what each set of letters in parentheses represents",
    "One beginning?",
    "Like old recordings",
    "The \"M\" in MSG",
    "Pre-stereo sound",
    "Like early LP's",
    "Like 1950's recordings",
    "Stereo forerunner",
    "Pre-stereo",
    "Like the Beatles' early work",
    "Pre-stereo",
    "Like early Beatles recordings",
    "Like old records",
    "Like old recordings",
    "Like early records",
    "Like some old records",
    "Pre-stereo",
    "Pre-stereo",
    "Glandular fever, for short",
    "Like old records",
    "Not stereo",
    "Kind of recording",
    "One-track",
    "Pre-stereo sound",
    "Like '50s LP's",
    "Stereo precursor"
  ],
  "male": [
    "Billy or tom",
    "Like sea horses that give birth",
    "Like every secretary of state until 1997",
    "Like drone bees",
    "Like drone bees",
    "Tom turkey or billy goat",
    "Eighth word ...",
    "Like flowers' stamens",
    "M, on a form",
    "Like some plugs",
    "Like father, like son?",
    "Like stallions",
    "Like all students at Atlanta's Morehouse College",
    "Having X and Y chromosomes",
    "XY",
    "Like him but not her",
    "Like guys",
    "Having X and Y chromosomes",
    "Like blokes",
    "Like one end of an electric cord",
    "Pronged, as an electrical plug",
    "Like bright red cardinals",
    "Like some plugs",
    "Y chromosome carrier",
    "Father or son",
    "___ soprano",
    "Stag",
    "Like some electrical plugs",
    "Buck or boar",
    "Stag or stallion",
    "Like a cob",
    "Selective service registrant",
    "Like Eton attendees",
    "32-Down option",
    "Stag party attendee",
    "___ bonding",
    "Stag party attender",
    "Having XY chromosomes",
    "Stag",
    "Owner of the Y?",
    "Like stallions and bulls",
    "Questionnaire option, often",
    "Kind of bonding",
    "One who can't bear family life?",
    "Like a parent who can't bear you?",
    "Drone, e.g.",
    "Driver's license designation",
    "Drone, e.g.",
    "Stag attender",
    "Having a Y chromosome",
    "Like a stag",
    "Kind of bonding",
    "Penthouse reader",
    "Like a buck or boar",
    "Employment form category",
    "Cob or drake, e.g.",
    "Type of bonding?",
    "Bull, cob or tom, e.g."
  ],
  "marm": [
    "Provincial schoolteacher stereotype",
    "School closing?",
    "Teacher in a one-room schoolhouse",
    "Head of the class, in pioneer schools",
    "Quaint schoolteacher",
    "Old schoolmistress",
    "Old-time schoolteacher",
    "School ___",
    "School lady",
    "Old female country teacher",
    "Teacher, in dialect",
    "School ___",
    "Old-fashioned lady"
  ],
  "gaga": [
    "Lovesick",
    "Very enthusiastic",
    "Lady ___, anthem singer at Biden's inauguration",
    "Nuts (over)",
    "Go ___ over",
    "Awestruck",
    "Completely infatuated",
    "Infatuated",
    "Mad",
    "Wildly enthusiastic",
    "Head over heels",
    "Giddy-brained",
    "Wild about, with \"over\"",
    "Lady ___ (pop diva)",
    "Lady ___",
    "Bananas",
    "\"Born This Way\" singer Lady ___",
    "Out of one's gourd",
    "Blown away",
    "Lady ___",
    "Daffy",
    "Crazy",
    "Totally smitten",
    "Totally smitten",
    "Totally nuts",
    "Carried away",
    "Bowled over",
    "Like one smitten",
    "Infatuated",
    "Foolishly enthusiastic",
    "Dotty",
    "Totally smitten",
    "Wide-eyed",
    "Totally nuts",
    "Dotty",
    "Head over heels (over)",
    "Extremely thrilled",
    "Enthusiastic, and then some",
    "Crazy",
    "Madly in love",
    "Bug-eyed",
    "Madly in love",
    "Infatuated",
    "Madly in love",
    "Totally nuts",
    "Crazy",
    "Nuts (over)",
    "Over the edge",
    "Baby talk",
    "Dotty",
    "Crazy (over)",
    "Dotty",
    "Completely infatuated",
    "Enraptured",
    "Loopy",
    "Daft",
    "Dotty",
    "Loopy",
    "Dotty",
    "Dotty",
    "Infatuated"
  ],
  "edge": [
    "Strategic starting piece in a jigsaw puzzle",
    "Cutting part of a knife",
    "Beat out",
    "Over the ___",
    "Brink",
    "One of 30 on a regular dodecahedron",
    "Fringe",
    "Lucky thing to hit in Ping-Pong",
    "Barely beat",
    "Narrow advantage",
    "Sharp quality",
    "Advantage",
    "Yardstick part",
    "Beat by a whisker",
    "Sharp part",
    "Sharp side of a blade",
    "Competitive advantage",
    "Precipice",
    "Narrow advantage",
    "Lip",
    "Beat narrowly, with \"out\"",
    "Border",
    "Nose out",
    "Advantage",
    "Advantage",
    "Beat by a nose",
    "One of 12 on a cube",
    "Lucky thing to get in Ping-Pong",
    "Nip in the end",
    "Perimeter",
    "Rim",
    "Leg up",
    "Cutting-___ (pioneering)",
    "Margin",
    "Lip",
    "Best, but barely",
    "Lip",
    "Lip",
    "The answer to this clue is located on one",
    "Lip",
    "Just beat",
    "Beat by a whisker",
    "Narrow advantage",
    "Advantage",
    "Border",
    "Barely beat",
    "Fringe",
    "Beat (out)",
    "Nervous people are on it",
    "Advantage",
    "Brink",
    "Rim",
    "Favorable margin",
    "Barely beat",
    "Border",
    "Slight advantage",
    "Precipice",
    "Perimeter",
    "Perimeter",
    "Beat by a whisker",
    "Advantage",
    "Sharp part",
    "Limit",
    "Leg up",
    "Rim",
    "Lip",
    "Razor feature",
    "Periphery",
    "Nose out",
    "Threshold",
    "Living on the ___",
    "Slight advantage",
    "Nose out",
    "Nose out",
    "Rim",
    "Kind of piece in a jigsaw puzzle",
    "Best by a bit",
    "Helpful kind of piece for a jigsaw puzzle doer",
    "Best, but barely",
    "Skirt",
    "Nose (out)",
    "Cusp",
    "Upper hand",
    "Rim",
    "Nose out",
    "Nose (out)",
    "Beat, barely",
    "What people are on during a nail-biter",
    "Advantage",
    "What a jumpy person is on",
    "Upper hand",
    "Defeat, barely",
    "Ad in, e.g.",
    "Work (in)",
    "Upper hand",
    "Margin",
    "Boundary",
    "S.C. Johnson shaving gel",
    "Sharpness",
    "Vantage",
    "Sedative target, with \"the\"",
    "Beat by a point or two",
    "Advantage",
    "Brink",
    "Leg up",
    "Sharpness",
    "Brink",
    "Periphery",
    "Advantage",
    "Beat (out)",
    "Periphery",
    "Upper hand",
    "Scoring advantage",
    "Rim",
    "Just beat",
    "Nose (out)",
    "Lip",
    "Upper hand",
    "Cusp",
    "Just squeeze by",
    "Part that's sharp",
    "Just beat",
    "Hipness",
    "Barely beat",
    "Rim ?à or trim",
    "Upper hand",
    "Good thing to have in competition",
    "Advantage",
    "Part of a box",
    "Head start",
    "Beat slightly",
    "Nose (out)",
    "Upper hand",
    "Barely better",
    "What a sphere lacks",
    "Advantage",
    "Trim, in a way",
    "Upper hand",
    "Drop-off point",
    "Add a fringe to",
    "Head start",
    "Beat, but barely",
    "Better chance",
    "Competitive ___",
    "Sharpness",
    "Leg up",
    "Ragged part",
    "Cusp",
    "Nose (out)",
    "Just beat",
    "Upper hand",
    "Barely beat",
    "Nose (out)",
    "Sidle",
    "Barely beat",
    "Margin",
    "Brink",
    "Advantage",
    "Add fringe to",
    "Result of honing",
    "Sidle",
    "Nose (out)",
    "Racer's need",
    "Greater likeliness to win",
    "Border",
    "Perimeter",
    "Cutting part",
    "Leg up",
    "Nose (out)",
    "Nose (out)",
    "Halftime lead, e.g.",
    "Narrowly defeat",
    "Upper hand",
    "Sharpness",
    "Jumping-off point",
    "Rim",
    "Sharp quality",
    "Upper hand",
    "Upper hand",
    "Advantage",
    "Competitive advantage",
    "Rim",
    "Skirt",
    "Site of abutment",
    "Slight advantage",
    "Upper hand",
    "Border",
    "Beat by a nose",
    "Superiority",
    "Cutting part",
    "Leg up",
    "Halftime lead, e.g.",
    "Fringe",
    "Leg up",
    "Head start",
    "Just beat",
    "Barely beat, with \"out\"",
    "Fringe",
    "Just beat",
    "Lip",
    "Keenness",
    "Advantage",
    "Nose out",
    "Verge",
    "Leg up",
    "Margin",
    "Nose (out)",
    "Brink",
    "Advantage",
    "Winning margin",
    "Handicap",
    "Periphery",
    "Touch of acrimony",
    "Touchiness",
    "Slight advantage",
    "Rim",
    "Border",
    "On___ (anxious)",
    "Verge",
    "Leading___",
    "Trim, as a photograph",
    "Beat (out)",
    "Head start",
    "Sharpness",
    "Hem",
    "Borderline",
    "Rim",
    "Sharp part",
    "Lip",
    "TV's \"The ___ of Night\"",
    "Hem",
    "Advantage",
    "Move laterally",
    "Honer's target",
    "Narrowly defeat",
    "Skirt",
    "Advantage",
    "Sharpen",
    "1988 Dick Francis thriller, with \"The\"",
    "Advantage",
    "Sidle",
    "Nose (out)",
    "Sharpen, as a knife",
    "Fisher's \"Postcards From the ___\"",
    "Barely beat, with \"out\"",
    "Keenness, as of appetite",
    "Lip",
    "Sharpen",
    "Skirt",
    "Advantage",
    "Advantage",
    "Competitive advantage"
  ],
  "itza": [
    "Chichén ___ (Mayan ruins city)",
    "Chichen ___ (Mayan city)",
    "Chichen ___ (Mayan ruins)",
    "Native people of Guatemala",
    "Chichen ___ (Mayan ruins)",
    "Chichen ___ (Mayan city)",
    "Chichen ___ (Mayan city)"
  ],
  "aviv": [
    "Tel ___, Israel",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Hebrew for \"spring\"",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___, Israel",
    "Tel ___, Israel",
    "Tel ___",
    "Part of a city name that means \"spring\" in Hebrew",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___, Israel",
    "Tel ___",
    "Tel ___-Jaffa",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___",
    "Tel ___"
  ],
  "mahi": [
    "Hawaiian for \"strong,\" which, when doubled, means \"very strong\"",
    "When doubled, another name for dorado",
    "When repeated, a Hawaiian fish",
    "When doubled, a Hawaiian fish",
    "Hawaiian entree, for short",
    "When doubled, a dolphinfish",
    "Dolphinfish, informally",
    "When doubled, a Hawaiian fish",
    "When repeated, Hawaiian menu item",
    "When doubled, a Hawaiian fish",
    "When doubled, a Hawaiian fish",
    "When repeated, a food fish",
    "Hawaiian fish, on some menus",
    "When doubled, a food fish",
    "When doubled, a fish",
    "Split fish?",
    "Hawaiian fish, on menus",
    "When repeated, a Hawaiian fish",
    "When doubled, a shore dinner order",
    "When repeated, a dolphinfish",
    "Hawaiian fish, informally",
    "Half of a fish?",
    "When repeated, a food fish",
    "Hawaiian fish, on menus",
    "When doubled, a seafood entree",
    "When repeated, a fish",
    "When repeated, a dolphin",
    "When doubled, a dolphinfish",
    "When repeated, a Hawaiian fish",
    "When repeated, a fish",
    "Half of a Hawaiian fish"
  ],
  "foil": [
    "Thwart",
    "Hershey's Kiss covering",
    "Chocolate kiss wrap",
    "Thwart",
    "Covering for leftovers",
    "Wrap for leftover food",
    "Thwart",
    "Comic's sidekick",
    "It's a wrap",
    "Stymie",
    "Wrap in sheets",
    "Thwart",
    "That's a wrap",
    "Stooge",
    "Straight man, for a comedian",
    "Wrap for leftovers",
    "Stooge",
    "Abbott, to Costello, e.g.",
    "Frustrate",
    "Patsy",
    "Thwart",
    "Thwart",
    "Aluminum sheet",
    "It's a wrap",
    "Thwart",
    "Mirror backing"
  ],
  "sofa": [
    "Place to crash",
    "Couch",
    "Remote hiding spot?",
    "Seating for two or more",
    "Place to veg",
    "Tough job for a mover, maybe",
    "Guest bed when you don't have an actual guest bed",
    "Sleeper that never dreams",
    "Guest bed, in a pinch",
    "Loose change \"collector\"",
    "Divan",
    "It has arms, legs and feet, but no hands",
    "Ottoman's partner",
    "Loose change \"collector\"",
    "Remote hiding place?",
    "Resting place",
    "Davenport",
    "Living room piece",
    "Furniture item that might seat three",
    "Soft spot",
    "Seat with cushions",
    "Davenport",
    "Staple of a waiting room",
    "Soft seat",
    "Spouse's sleeping place after a fight, maybe",
    "Furniture on which a guest might sleep",
    "Resting place",
    "Davenport",
    "___ bed",
    "Couch",
    "Divan",
    "Cushioned seat",
    "Convertible, maybe",
    "Common crash site?",
    "Coin \"swallower\"",
    "Couch",
    "Cushion site",
    "Soft seat",
    "Crash site?",
    "Couch",
    "TV viewer's vantage point",
    "Seat for two or more",
    "Sectional, e.g.",
    "It has arms, legs and a back",
    "Couch",
    "Soft seat",
    "Chesterfield, e.g.",
    "Spot for a nap",
    "Company seat?",
    "TV watcher's seat",
    "TV watcher's vantage point",
    "Remote hiding place?",
    "Couch",
    "Soft seat",
    "One might be a pull-out",
    "Couch",
    "Upholstered piece",
    "Davenport",
    "Crash site?",
    "Cushion site",
    "Davenport",
    "Resting place",
    "Guest bed, maybe",
    "Sectional, say",
    "Rec room staple",
    "Couch",
    "Divan",
    "Davenport",
    "Soft spot",
    "Comfy spot",
    "Sitter's choice",
    "Convertible, perhaps",
    "Divan",
    "Parlor piece",
    "Reading place",
    "Davenport",
    "Dozer's spot",
    "Sectional",
    "Chesterfield, for one",
    "Sectional, e.g.",
    "Place to plop down",
    "Parlor seat",
    "Remote post?",
    "Coin-losing site",
    "Convertible, for one",
    "Coin collector?",
    "Remote location",
    "Lounge",
    "Francois Boucher's \"Nude Lying on a ___\"",
    "Divan",
    "Couch potato's place",
    "Lounger's perch",
    "Parlor piece",
    "It might be a convertible",
    "Parlor piece",
    "It can hide a bed",
    "Seat for two or more",
    "Divan",
    "Divan"
  ],
  "twix": [
    "Candy sold in pairs",
    "It comes from Mars",
    "Candy bar packaged in twos",
    "Mars candy bar with caramel and milk chocolate",
    "Candy bar with chocolate and caramel around a wafer",
    "Mars candy",
    "\"Two for me. None for you\" candy bar",
    "Chocolate-and-caramel candy bar",
    "\"Two for me. None for you\" candy",
    "Candy bar that comes two to a pack",
    "Chocolate bar with a long biscuit and caramel",
    "Bar from Mars",
    "Candy bar featured in a \"Seinfeld\" episode",
    "Mars candy",
    "Product from Mars",
    "Popular chocolate bar"
  ],
  "plug": [
    "Promote, as a product",
    "What goes into a socket",
    "Commercial pitch",
    "Stop up ... or talk up",
    "Payola payoff",
    "Drain stopper",
    "Drain stopper",
    "Advertise",
    "Promote",
    "Electric cord's end",
    "Ballyhoo",
    "Advertise",
    "Advertisement",
    "Commercial endorsement",
    "Mention publicly",
    "Outlet center?"
  ],
  "hart": [
    "Stand-up comic Kevin",
    "Male deer",
    "\"The Lady Is a Tramp\" lyricist",
    "Male deer",
    "N.H.L.'s ___ Memorial Trophy",
    "Rodgers's partner, in song",
    "Either of two married supersleuths of 1970s-'80s TV",
    "\"Blue Moon\" lyricist",
    "Comedian Kevin",
    "Politico Gary",
    "Antlered animal",
    "\"B.C.\" creator Johnny",
    "Male deer",
    "Male deer",
    "See 55-Across",
    "\"Pal Joey\" lyricist",
    "Male deer",
    "Doe's mate",
    "Antlered deer",
    "Lyricist Lorenz ___",
    "___ Senate Office Building",
    "\"Pal Joey\" lyricist",
    "1988 presidential hopeful",
    "ANTLERED BEAST",
    "\"Blue Moon\" lyricist",
    "Stag",
    "He's a deer",
    "Trophy won by 10-Down, 1967 and 1968",
    "Male deer",
    "Hind's mate",
    "\"B.C.\" creator",
    "\"Where or When\" lyricist",
    "\"B.C.\" cartoonist",
    "\"The Man Who Came to Dinner\" playwright",
    "\"Blue Moon\" lyricist"
  ],
  "dirt": [
    "Gossip, informally",
    "Gardener's supply",
    "Gossip",
    "Skinny",
    "Gossip",
    "Juicy gossip",
    "Scandal fodder",
    "TMZ fodder",
    "Gossip",
    "Gossip",
    "Malicious gossip",
    "Word before poor or cheap",
    "Gossip",
    "Scuttlebutt",
    "Reputation ruiner",
    "Gossip",
    "Partner of grease",
    "Scandalmonger's love",
    "Dope",
    "Scuttlebutt",
    "Lowdown",
    "Lowdown",
    "Gossip",
    "Earth",
    "Scuttlebutt",
    "Lowdown",
    "Tabloid fodder",
    "Gossip",
    "Skinny",
    "Skinny",
    "What a vacuum cleaner vacuums",
    "Gossip fodder",
    "Gossipmonger's stuff",
    "Scuttlebutt",
    "Incriminating information",
    "Tabloid fodder",
    "Squalor",
    "Malicious gossip",
    "Lowdown",
    "Gossip",
    "Tabloid talk",
    "Scandalous gossip",
    "Grime",
    "Scuttlebutt",
    "Vacuum's target",
    "What a vacuum vacuums"
  ],
  "woos": [
    "Tries to win over",
    "Goes a-courting",
    "Courts",
    "Showers with flowers and chocolates, maybe",
    "Pursues romantically",
    "Courts",
    "Tries to win, as a damsel",
    "Tries to win",
    "Tries to win",
    "Tries to get",
    "Goes after, in a way",
    "Romances",
    "Courts",
    "Tries to win",
    "Courts",
    "Romances",
    "Courts",
    "Coaxes",
    "Seeks favor with",
    "Courts"
  ],
  "king": [
    "Checker that makes it to the other side of the board",
    "Leader memorialized by the Stone of Hope statue near the National Mall",
    "With 115-Across, <span>&#x1F98D; &#x1F469; &#x1F3E2;</span>",
    "Piece between a bishop and a queen",
    "Checker after reaching the other side of the board",
    "Powerful checker",
    "Bed selection",
    "Double-decker checker",
    "Cowboy, in poker slang",
    "Double-decker checker",
    "Hotel room option",
    "Piece moved in castling",
    "Queen's mate",
    "It may be checked",
    "Double-decker checker",
    "Word before snake or crab",
    "Hopper around a board",
    "Double checker",
    "Double-decker checker",
    "With 2-Down, a famous stage father",
    "Picture card",
    "Bed choice",
    "Kind of snake or crab",
    "With 17-Across, Shakespeare tragedy",
    "Double-decker checker",
    "Alan, Larry or Stephen",
    "\"Stride Toward Freedom\" author",
    "1/17/94 honoree"
  ],
  "draw": [
    "Frequent result between two chess grandmasters",
    "Appeal",
    "It adds one point to a soccer team's standing in the English Premier League",
    "Pick a card, any card",
    "Lure",
    "Kind of poker",
    "One-up, say",
    "Compete in Pictionary",
    "Make faces, perhaps",
    "One end of a chess game",
    "Tournament director's responsibility",
    "Action in go fish",
    "Make a cartoon, e.g.",
    "Take from the deck",
    "Result of a king vs. king endgame",
    "Stalemate",
    "Stud alternative",
    "Command in Uno",
    "Start of a Scrabble game",
    "Take a card",
    "Start of a tournament ... or the end of a match?",
    "Attract, as an audience",
    "Box office need",
    "Inconclusive outcome",
    "Attract",
    "Gunslinger's cry",
    "Shootout shout",
    "Something you can do to the starts of 17-, 21-, 37-, 53- and 60-Across",
    "Duel action?",
    "Sketch",
    "Challenge in a western",
    "20-20, e.g.",
    "Shout at a shootout",
    "Pick a card",
    "Chess result, sometimes",
    "Ring result",
    "End even",
    "Poker variety",
    "Take a card",
    "Gunfight command",
    "Sketch",
    "No-win situation",
    "Old West command",
    "Performer who fills the club",
    "Gunslinger's command",
    "Select a winner in a sweepstakes",
    "Western command",
    "Challenge to a gunslinger",
    "Select at random",
    "Tie",
    "Tie",
    "Command in a western",
    "Pull a pistol",
    "Tie",
    "Sketch",
    "Gunslinger's command",
    "Pull in",
    "Extract",
    "Dead heat",
    "Gunslinger's command"
  ],
  "nova": [
    "PBS science show since 1974",
    "Observation from an observatory",
    "Emmy-winning science series",
    "Sighting in a telescope",
    "Exploding star",
    "Starburst?",
    "Massive explosion",
    "Celestial explosion",
    "Flare way up?",
    "The first recorded one was noted by the Greek scientist Hipparchus in 134 B.C.",
    "PBS series since 1974",
    "___ Scotia",
    "___ Caesarea, original name of New Jersey",
    "___ Scotia",
    "Salmon variety",
    "___ Scotia",
    "Smoked salmon",
    "Astronomical event",
    "Salmon at a deli",
    "Blast from the past, astronomically speaking?",
    "Burst in space",
    "Bygone Chevy model",
    "Terra ___ (old name for Newfoundland)",
    "Astronomer's sighting",
    "Massive explosion",
    "Deli purchase",
    "Deli item that's 14-Across backward",
    "Bagel topper",
    "Exploding star",
    "Star burst",
    "Stellar phenomenon",
    "Brilliant 13-Down",
    "PBS science series",
    "Astronomical figure?",
    "Long-running PBS science series",
    "Kind of lox",
    "Rising star?",
    "PBS science series",
    "Suddenly bright star",
    "Bright star",
    "Exploding star",
    "PBS staple since 1974",
    "Super star",
    "___ Scotia",
    "Astronomer's sighting",
    "Astronomer's sighting",
    "Star of changing brightness",
    "Cured and smoked salmon",
    "With 46-Down, site of Cape Breton Island",
    "Exploding star",
    "Exploding star",
    "Source for an outburst",
    "Astronomer's study",
    "Pop star?",
    "Stellar phenomenon",
    "Astronomical sighting",
    "Brilliant star",
    "Big star",
    "Astronomer's study",
    "Bagel stuffer",
    "Exploding star",
    "Bagel topper",
    "Explosive star",
    "Popular PBS science series",
    "PBS science show",
    "Bagel shop request",
    "Exploding star",
    "Stellar spectacular",
    "PBS science show",
    "Deli order",
    "___ Scotia",
    "Bossa ___",
    "Bagel stuffer",
    "Bagel stuffer",
    "Bygone Chevy",
    "\"Super\" star",
    "___ Scotia",
    "\"Super\" sight",
    "PBS science series",
    "Space heater?",
    "PBS classic",
    "PBS Emmy winner",
    "Spectacular star",
    "Brunch item",
    "PBS offering",
    "Astral flareup",
    "___ Scotia",
    "Stellar phenomenon",
    "Exploding star",
    "Astronomer's sighting",
    "Astronomical sighting",
    "Astronomer's sighting",
    "Suddenly bright star",
    "Variable star",
    "\"Super\" star",
    "Bygone Chevy",
    "Popular salmon",
    "PBS science series",
    "___ Scotia",
    "Temporary superstar",
    "Old Chevy model",
    "PBS science program",
    "Science series since 1974",
    "Exploding star",
    "Smoked salmon",
    "Award-winning science show"
  ],
  "boca": [
    "___ Raton, Fla.",
    "Where many snowbirds winter, for short",
    "___ Raton, Fla.",
    "Florida city, informally",
    "Florida beach city, informally",
    "___ Raton, Fla.",
    "Locale of the Evert Tennis Academy, familiarly",
    "___ Raton, Fla.",
    "___ Raton, Fla.",
    "Florida city, for short",
    "___ Raton, Fla.",
    "City south of West Palm",
    "Florida city, informally",
    "It's about 20 miles north of Lauderdale",
    "___ Raton, Fla.",
    "___ Raton, Fla.",
    "___ Burger (veggie patty)",
    "Florida city, for short",
    "Florida city, for short",
    "Delray Beach neighbor, for short",
    "City south of Delray Beach, for short",
    "Part of Florida's Gold Coast, informally",
    "Palm Beach County city, for short",
    "___ Grande, Fla.",
    "Lauderdale is south of it",
    "City just south of Delray",
    "City down the coast from West Palm",
    "City south of West Palm",
    "Florida city, informally",
    "Florida city, informally",
    "Florida city, informally",
    "___ Raton, Fla.",
    "___ Raton, Fla.",
    "Florida city, informally",
    "___ Grande, Fla.",
    "___Raton, Fla.",
    "Iberian \"mouth\"",
    "___ Raton, Fla.",
    "___ Raton"
  ],
  "spar": [
    "Practice for a bout",
    "Lightly box",
    "Trade jabs (with)",
    "Feud (with)",
    "Rigging pole",
    "Trade blows",
    "Argue (with)",
    "Go a few rounds",
    "Trade jabs",
    "Mast",
    "Go for a few rounds",
    "Bicker (with)",
    "Trade jabs (with)",
    "Prep for the ring",
    "Not fight seriously",
    "Trade blows",
    "Bicker (with)",
    "Warm up for a bout, say",
    "Box a bit",
    "Trade barbs or blows",
    "Prep for a match",
    "Quarrel (with)",
    "Practice boxing",
    "Trade jabs (with)",
    "It's often rigged",
    "Sail support",
    "Vigorously debate (with)",
    "Shadowbox",
    "Argue (with)",
    "Practice boxing",
    "Trade rights, say",
    "Not fight all-out",
    "Sail support",
    "Lock horns (with)",
    "Ship's pole",
    "Practice boxing",
    "Practice boxing",
    "Go a few rounds",
    "Go a few rounds",
    "Practice for a prizefight",
    "Have words (with)",
    "Boom or gaff",
    "Trade punches in training",
    "Box for a drill",
    "Wrangle",
    "Argue (with)",
    "Sail support",
    "Practice with gloves on",
    "Debate (with)",
    "Go at it",
    "Ship part",
    "Rigging support",
    "Train for a boxing match",
    "Lightly box",
    "Practice boxing",
    "Nautical pole",
    "Box a bit",
    "Engage in debate",
    "Exchange jabs or gibes",
    "Lock horns (with)",
    "Boom",
    "Lock horns (with)",
    "Box gently",
    "Box lightly",
    "Train in a ring",
    "Argue (with)",
    "Sail support",
    "Tangle (with)",
    "Work to help one get 57-Down",
    "Fight, but not all-out",
    "Debate (with)",
    "Airplane wing supporter",
    "Boom",
    "Not really fight",
    "Exchange jabs",
    "Boom",
    "Quarrel (with)",
    "Work out in the ring",
    "Exchange ripostes",
    "Warm up in the ring",
    "Duke it out",
    "Trade punches",
    "Practice boxing",
    "Clash (with)",
    "Bandy words",
    "Crack heads",
    "Train",
    "Quarrel",
    "Butt heads",
    "Boom",
    "Exchange blows",
    "Wrangle",
    "Wrangle",
    "Practice, pugilistically",
    "Rigging support",
    "Box lightly",
    "Trade jabs",
    "Trade punches",
    "Box",
    "Have words (with)",
    "Sailboat feature",
    "Box without a prize",
    "Bandy words",
    "Rigging support",
    "Practice one's one-two",
    "Debate (with)",
    "Practice punching",
    "Sail support",
    "Nautical yard",
    "Joust verbally",
    "It may be rigged",
    "Work with a pug",
    "Box",
    "Go a round or two",
    "Dispute",
    "Go back and forth (with)",
    "Practice in the ring",
    "Practice like a pug",
    "Box a bit",
    "Practice in the ring",
    "Go at each other",
    "Joust verbally",
    "Practice in the ring",
    "Box with headgear",
    "Box",
    "Practice in the ring",
    "Ready for a fight",
    "Debate (with)",
    "Practice in the ring",
    "Gaff or boom",
    "Bandy words",
    "Wrangle",
    "Quarrel",
    "Argue (with)",
    "Bandy words",
    "Work out in a ring",
    "Nautical pole",
    "Fight, but not for real",
    "Verbally joust (with)",
    "Jab back and forth",
    "Practice with Rocky",
    "Have words (with)",
    "Dispute",
    "Box, but not seriously",
    "Do some punching",
    "Bicker",
    "Practice with Rocky",
    "Bandy words",
    "Practice boxing",
    "Boom",
    "Practice pugilism",
    "Box lightly",
    "Trade jabs (with)",
    "Box",
    "Box",
    "Rigging supporter",
    "Pole",
    "Practice a la Marciano",
    "Box lightly",
    "Mineral name ending",
    "Boom or box",
    "Box",
    "Crystalline rock",
    "Argue",
    "Wrangle",
    "Tangle (with)",
    "Practice with a palooka"
  ],
  "orgs": [
    "A.C.L.U. and others",
    "Sch. yearbook section",
    "List for charitable givers, for short",
    "Assns.",
    "A.A.A. and B.B.B.",
    "56-Down and others: Abbr.",
    "Assns.",
    "Assns.",
    "A.A. and A.A.A.",
    "Mensa and others: Abbr.",
    "Societies: Abbr.",
    "Sch. yearbook section",
    "Assns.",
    "Grps.",
    "A.A. and A.A.A., for two",
    "Grps.",
    "Assns.",
    "Assns.",
    "A.A.A. and B.B.B., e.g.",
    "Grps.",
    "N.A.A.C.P. and others",
    "Some Web site owners: Abbr.",
    "Grps.",
    "WHO and NOW: Abbr.",
    "Yearbook sect.",
    "P.T.A. and N.E.A., for two",
    "Assns.",
    "E-mail address endings",
    "Subjects of many H.S. yearbook photos",
    "Special-interest grps.",
    "Assns."
  ],
  "vies": [
    "Fights (for)",
    "Strives for victory",
    "Goes head-to-head",
    "Goes (for)",
    "Competes (for)",
    "Battles",
    "Tries to win",
    "Contests",
    "Competes (for)",
    "Goes (for)",
    "Is a contender",
    "Struggles",
    "Struggles",
    "Runs (for)",
    "Contends",
    "Goes head-to-head",
    "Competes",
    "Struggles"
  ],
  "pere": [
    "Father, in French",
    "Parisian priest",
    "___ Noel (boss of 65-Across, in France)",
    "Champagne pop",
    "Pops up in France?",
    "French homophone of 30-Down",
    "Father, in France",
    "France's ___ Noel",
    "Member of la famille",
    "Father: Fr.",
    "Parisian parent",
    "Euro pop?",
    "Van Gogh's \"Portrait of ___ Tanguy\"",
    "Father of the mariee",
    "Juin honoree",
    "Membre de la famille",
    "Father, in France",
    "___ Noel (French Santa Claus)",
    "Man in la famille",
    "___ Noel (French Santa Claus)",
    "Old man of Orleans",
    "Van Gogh's \"Portrait of ___ Tanguy\"",
    "A Dumas",
    "Senior, in French names",
    "___ Noel",
    "___ Lachaise (Paris's largest cemetery)",
    "French father",
    "___ Noel",
    "Euro pop?",
    "\"Senior\" in French names",
    "Member of la famille",
    "A Dumas",
    "Man in la famille",
    "___ Noel",
    "Francois's father",
    "\"Le ___ Goriot\"",
    "___ Noel",
    "Jean-Luc's father",
    "French father",
    "II, to III, par exemple",
    "A Dumas",
    "Father, in France",
    "Le ___ Noel",
    "Dumas senior",
    "Title for Marquette",
    "Paris's Cimetiere du ___ Lachaise",
    "Balzac's \"Le ___ Goriot\"",
    "One of the Dumas",
    "___ Noel (holiday figure)",
    "Balzac novel \"Le ___ Goriot\"",
    "___Marquette"
  ],
  "lori": [
    "Country singer McKenna",
    "Chicago mayor Lightfoot",
    "Actress Petty",
    "Chicago mayor Lightfoot",
    "Chicago mayor Lightfoot",
    "Greiner of \"Shark Tank\"",
    "Actress Loughlin of \"Full House\"",
    "Chicago mayor Lightfoot",
    "Actress Petty of \"A League of Their Own\"",
    "Rick's wife on \"The Walking Dead\"",
    "Actress Petty of \"Tank Girl\"",
    "Singer of \"Footloose\"",
    "Actress Singer of \"Footloose\" and TV's \"Fame\"",
    "Actress Singer",
    "Petty around Hollywood",
    "Petty of \"A League of Their Own\"",
    "Actress Singer",
    "Loughlin or Petty of Hollywood",
    "Actress Loughlin of \"90210\"",
    "Singer learning a script",
    "Actress Singer",
    "Loughlin of \"Full House\"",
    "Loughlin of \"90210\"",
    "Actress Loughlin of \"Full House\"",
    "Actress Singer of \"Footloose\"",
    "Actress Loughlin of \"90210\"",
    "\"Full House\" actress Loughlin",
    "Actress Petty",
    "Loughlin of \"90210\"",
    "___ Lemaris, early love of Superman",
    "\"Tank Girl\" actress Petty",
    "Actress Petty of \"A League of Their Own\"",
    "Actress Martin, star of TV's \"National Velvet\"",
    "Actress Martin who starred in TV?Æs ?ôNational Velvet?ö",
    "Actress Petty",
    "Folk singer McKenna",
    "Romance author Foster",
    "Loughlin of \"Full House\"",
    "Actress Singer of \"Footloose\"",
    "Actress Petty",
    "\"Pieces of Me\" singer McKenna",
    "Petty of \"Point Break\"",
    "Loughlin of \"Full House\"",
    "Actress Petty",
    "Actress Petty of \"Point Break\"",
    "Actress Singer of \"Footloose\"",
    "Actress Petty",
    "Actress Singer",
    "Singer of the screen",
    "Actress Petty",
    "Loughlin of \"Full House\"",
    "Loughlin of \"Full House\"",
    "Actress Loughlin of ?ôFull House?ö",
    "Actress Loughlin",
    "Actress ___ Singer",
    "Miss Loughlin of \"Full House\"",
    "Actress ___ Singer"
  ],
  "usps": [
    "Org. that delivers",
    "Mailing letters?",
    "\"We deliver for you\" sloganeer, for short",
    "Org. with a Change My Address portal on its website",
    "\"We deliver for you\" org.",
    "Org. that delivers",
    "Offerer of package deals, in brief",
    "Mail letters?",
    "Org. with stamps",
    "Mail letters",
    "Org. suggested by the starts of 17-, 31-, 41- and 62-Across",
    "Mail letters",
    "Inits. on a mail truck",
    "Census form deliverer: Abbr.",
    "Org. that trademarked \"Pony Express\" in 2006",
    "Mail carrier's grp.",
    "Letter carriers' grp.",
    "Mail letters",
    "One way to send pkgs.",
    "\"We deliver for you\" sloganeer: Abbr.",
    "Deliverer of many pkgs.",
    "Letter carriers' org.",
    "Org. whose workers may be left carrying the bag",
    "Mail org.",
    "Its symbol is an eagle: Abbr."
  ],
  "suss": [
    "Figure (out)",
    "Figure (out)",
    "Make (out)",
    "Figure (out)",
    "Puzzle (out)",
    "Deduce, with \"out\"",
    "Puzzle (out)",
    "Figure (out)",
    "Figure (out)",
    "Figure (out)",
    "Figure (out)",
    "Figure (out)"
  ],
  "thea": [
    "Woman's name meaning \"goddess\"",
    "Fritz Lang collaborator ___ von Harbou",
    "Mother of Helios",
    "Which train goes to Harlem, in song",
    "Mother of Helios",
    "\"___-Team\"",
    "Mother of Eos and Selene",
    "Mother of Selene",
    "Mother of Eos and Helios",
    "Mother of Helios",
    "\"Take ___ Train\"",
    "Ellington's \"Take ___ Train\"",
    "Mother of Helios",
    "Singer/songwriter Gilmore",
    "Sister and wife of Hyperion",
    "TV's \"___-Team\"",
    "\"___-Team\"",
    "Hedda's schoolmate in \"Hedda Gabler\"",
    "Which train to take, in song",
    "Mother of Helios",
    "Mother of Helios",
    "\"___-Team\"",
    "___-Team (this puzzle's theme?)",
    "\"___ Team\"",
    "Former ABC sitcom",
    "Train in a celebrated song",
    "Certain Brooklyn-Manhattan train",
    "\"Take ___ Train\"",
    "\"Take___Train\"",
    "\"Take ___ Train\"",
    "\"Take ___ Train\""
  ],
  "suva": [
    "Capital of Fiji",
    "Capital of Fiji",
    "Fiji's largest city",
    "Capital of 26-Down [21]",
    "South Pacific's largest city",
    "Largest city in the South Pacific",
    "Main campus site of the University of the South Pacific",
    "Capital on the island of Viti Levu",
    "Capital of Fiji",
    "Fiji's capital",
    "Fiji's capital"
  ],
  "para": [
    "Olympic athlete category",
    "Legal assistant, briefly",
    "Prefix with legal or military",
    "Prefix with medic or military",
    "Law firm aide, informally",
    "Prefix with normal",
    "Brief researcher, briefly",
    "Professional aide, for short",
    "Prefix with legal or trooper",
    "Sports category prefix",
    "Chute opener?",
    "Law office worker, for short",
    "Prefix with normal",
    "Law office worker, for short",
    "Chute opener?",
    "Law office worker, informally",
    "One who may get a rap at a law office",
    "Prefix with normal",
    "Law firm aide, for short",
    "Chute opening?",
    "Professional helper, for short",
    "Prefix with normal",
    "Firm assistant, briefly",
    "Jumper, briefly",
    "Plane-jumping G.I.",
    "Law firm aide, for short",
    "Prefix with professional",
    "Prefix with gliding",
    "Chute opener?",
    "Brief researcher, briefly",
    "Prefix with legal",
    "Law assistant, for short",
    "Plane-jumping G.I.",
    "Legal opener",
    "Law firm employee, for short",
    "Legal aide, briefly",
    "Prefix with legal",
    "Prefix with graph",
    "Prefix with medic",
    "Professional prefix",
    "Law firm employee, informally",
    "Prefix with legal",
    "Prefix with sailing",
    "Phrase starter",
    "Prefix with legal or chute",
    "Chute opening",
    "Prefix with legal or medic",
    "Normal opening",
    "Prefix with graph",
    "Prefix with glider",
    "Legal preceder",
    "Trooper?Æs opening",
    "Legal lead-in",
    "Normal start"
  ],
  "gmat": [
    "Exam for some aspiring C.E.O.s",
    "Exam for future M.B.A.s",
    "B-school applicant's hurdle",
    "M.B.A. hopeful's exam",
    "Coll. senior's exam",
    "Common B-school requirement",
    "Exam for a Wharton applicant: Abbr.",
    "Coll. senior's hurdle",
    "M.B.A. hopeful's hurdle",
    "B-school entrance exam"
  ],
  "dude": [
    "One stoner to another",
    "\"C'mon, man!\"",
    "___ ranch",
    "Bro",
    "Bro",
    "\"My man!\"",
    "\"Bro!\"",
    "\"My man!\"",
    "\"My man!\"",
    "Guy",
    "Bro",
    "Ranch visitor",
    "\"My man!\"",
    "Young guy, slangily",
    "Bro",
    "\"Yo, ___!\"",
    "Fellow, slangily",
    "Guy",
    "Ranch visitor",
    "Dapper fellow",
    "Bro",
    "Bro",
    "Fancy dresser",
    "Chap",
    "Jack-a-dandy",
    "Chick mate",
    "Surfer's sobriquet",
    "Fella"
  ],
  "snip": [
    "Impertinent sort",
    "Scissor cut",
    "Scissors sound",
    "Trim",
    "Sound heard in a delivery room",
    "Quick scissors cut",
    "Small cut",
    "Classic editing room sound",
    "Impertinent sort",
    "Salon sound",
    "Small scissor cut",
    "Use shears",
    "Scissors sound",
    "Cutlet?",
    "Action at a bris",
    "Use scissors on",
    "Scissor cut",
    "Do a mohel's job",
    "Little scissor cut",
    "Insignificant person",
    "Short cut",
    "Quick cut",
    "Barbershop sound",
    "Bit of barbering",
    "Short cut",
    "Salon sound",
    "Impertinent one",
    "Salon sound",
    "Do some barbering",
    "Do a little barbering",
    "Short cut",
    "Fabric scrap, say",
    "Use tiny scissors",
    "Sassy sort",
    "Trim",
    "Sound made while working on a mop",
    "Tiny scissor cut",
    "Presumptuous sort",
    "Impertinent sort",
    "Salon sound",
    "Quick cut",
    "Salon sound",
    "Paper doll-making sound",
    "Salon sound",
    "Salon sound",
    "Prune a bit",
    "Sassy one",
    "Salon sound",
    "Scissor",
    "Salon sound",
    "Small bit",
    "Sound at a salon",
    "Unrespectful sort",
    "Sassy one",
    "Small scissor cut",
    "Scissors cut",
    "Quick cut",
    "Impertinent one",
    "Sassy sort",
    "Presumptuous one",
    "Use scissors",
    "Delicate cut",
    "Cutlet?",
    "Salon sound",
    "Short cut?",
    "Saucy sort",
    "Barbershop sound",
    "Cut with scissors",
    "Scissors sound",
    "Back-talker",
    "Sassy one",
    "Little cut",
    "Impertinent person",
    "Scissors cut",
    "Barber's motion",
    "Impertinent one",
    "Barber's motion",
    "Impertinent one",
    "Tiny scissors cut",
    "Barbershop sound",
    "Use scissors",
    "Trim",
    "Impertinent one",
    "Short cut",
    "Use shears",
    "Salon sound",
    "Impertinent one",
    "Scissors cut",
    "Bit",
    "Impudent imp",
    "Barber's motion",
    "Barber's motion",
    "Impertinent one",
    "Trim",
    "Fragment",
    "Insolent tad",
    "Cut",
    "Impertinent one",
    "Impudent nobody",
    "Cut",
    "Trim a tress",
    "Barbershop sound",
    "Cut",
    "Barber's action",
    "Use scissors",
    "Slight person",
    "Small cut",
    "Quick cut",
    "Barber's cut"
  ],
  "mari": [
    "Activist Copeny known as \"Little Miss Flint\"",
    "Husband, in France",
    "French spouse",
    "Husband: Fr.",
    "French husband",
    "Epouse's mate",
    "Hubby, in Paris"
  ],
  "plot": [
    "Kind of device used in filmmaking",
    "\"The ___ thickens\"",
    "*The outcome of a story might hinge on one",
    "What a spoiler spoils",
    "Cemetery purchase",
    "Whodunit's essence",
    "Secret plan",
    "What a spoiler may spoil",
    "Cook up a conspiracy",
    "Parcel of land",
    "What a spoiler might spoil",
    "What cabalists do",
    "Garden",
    "Map (out)",
    "A bad one may contain holes",
    "Garden ___",
    "Secret plan",
    "Novelist's need",
    "Churchyard unit",
    "Underhanded plan",
    "Coup start",
    "Storyline",
    "It might consist of a 19-Across",
    "Cook up",
    "One to grow on?",
    "Makings of a coup",
    "Scheme",
    "Storyline",
    "With 38-Down, common device on \"The Twilight Zone\"",
    "Connive",
    "It may thicken",
    "Lead-up to a coup",
    "Bit of intrigue",
    "Novel idea",
    "It often thickens",
    "Paranoiac's worry",
    "Represent graphically",
    "Paranoid's fear",
    "Story line",
    "An author might give it away",
    "Mark on a graph",
    "Paranoiac's worry",
    "Garden area",
    "\"The ___ thickens\"",
    "Basic thread",
    "Parcel of land",
    "It sometimes thickens",
    "Cabal's plans",
    "Novelist's need",
    "Novel creation",
    "It's followed by moviegoers",
    "Scheme",
    "Ruse",
    "Scheme",
    "Scheme",
    "Cabal",
    "Scheme",
    "Novelist's concern",
    "Boy gets / loses / gets girl, e.g.",
    "Graph",
    "Novel action"
  ],
  "dday": [
    "Decision-making time",
    "It's called \"le jour J\" in France",
    "Critical time",
    "\"Do or die\" time",
    "An exhibit at F.D.R.'s presidential library is dedicated to it",
    "Its beaches begin five answers in this puzzle",
    "Depiction in \"Saving Private Ryan\"",
    "Historic Normandy event",
    "When Juno Beach was attacked",
    "1944-06-06T00:00:00.000Z",
    "Event code-named Operation Neptune",
    "Attack time in W.W. II",
    "Offensive date",
    "Critical time",
    "W.W. II turning point",
    "Time to strike",
    "Decisive time",
    "When the Battle of Normandy started",
    "Attack-launch time",
    "1944-06-06T00:00:00.000Z",
    "Make-or-break date",
    "When H-Hour happens",
    "Critical time of action",
    "Decisive time",
    "\"Now or never\" time",
    "Time of 1944's Operation Neptune",
    "Operation time",
    "When Juno and Gold Beach were assaulted",
    "When to begin an operation",
    "1944-06-06T00:00:00.000Z",
    "*Setting for \"Saving Private Ryan\"",
    "Decisive time",
    "Time of reckoning",
    "Subject of the book \"Six Armies in Normandy\"",
    "When all one's planning is put to the test",
    "Event depicted in \"Saving Private Ryan\"",
    "1944-06-06T00:00:00.000Z",
    "\"It's now or never\" time",
    "1944-06-06T00:00:00.000Z",
    "Do-or-die time",
    "*Critical time",
    "Omaha Beach time",
    "W.W. II turning point",
    "W.W. II event",
    "Decisive time",
    "Memorable June event",
    "1944-06-06T00:00:00.000Z",
    "Do-or-die time",
    "Make-or-break time",
    "W.W. II turning point",
    "Make-or-break time",
    "40's turning point",
    "1944-06-06T00:00:00.000Z",
    "When to launch an invasion",
    "Decisive time",
    "1944-06-06T00:00:00.000Z",
    "Time to attack",
    "W.W. II turning point",
    "Time to attack",
    "Time for a big event",
    "Time of reckoning",
    "1944-06-06T00:00:00.000Z",
    "Time to attack",
    "Decisive time (or theme of this puzzle)",
    "\"Saving Private Ryan\" depiction",
    "\"Saving Private Ryan\" event",
    "Time to act",
    "1944-06-06T00:00:00.000Z",
    "When zero hour occurs",
    "Time of decision",
    "\"Saving Private Ryan\" re-enactment",
    "Make-or-break time",
    "Time of decision",
    "Time to act",
    "1944-06-06T00:00:00.000Z",
    "1944-06-06T00:00:00.000Z",
    "1944-06-06T00:00:00.000Z",
    "Make-or-break time",
    "1944-06-06T00:00:00.000Z",
    "1944-06-06T00:00:00.000Z",
    "Normandy event",
    "1944-06-06T00:00:00.000Z",
    "Time to attack",
    "When Operation Overlord took place",
    "1944-06-06T00:00:00.000Z",
    "Its password was \"Mickey Mouse\""
  ],
  "real": [
    "Heartfelt",
    "Not a joke, say",
    "\"Get ___!\"",
    "Genuine",
    "Unimagined?",
    "Very, informally",
    "Honest-to-goodness",
    "Not staged",
    "100 centavos, in Brazil",
    "\"Let's get ___!\"",
    "Not made up",
    "Keep it ___ (be honest)",
    "\"Get ___!\"",
    "Veritable",
    "Very, informally",
    "Concrete",
    "Not imaginary",
    "\"Get ___\"",
    "Genuine",
    "Honest-to-goodness",
    "Not made up",
    "\"Keep it ___\"",
    "Word exclaimed with \"Get\" or \"Too\"",
    "De facto",
    "Bona fide",
    "Tangible",
    "Not a knockoff",
    "Not staged",
    "\"Get ___!\"",
    "Like e, but not i",
    "Legitimate",
    "All-natural",
    "Authentic",
    "Unfeigned",
    "Genuine",
    "\"Get ___!\"",
    "Legitimate",
    "Not fantastic",
    "What some consider the 38-Across to be",
    "Unimagined",
    "Very, informally",
    true,
    "\"Keep it ___\"",
    "Unembellished",
    "Down-to-earth",
    "Not knocked off",
    "Not fantastic",
    "Existing",
    "Unlike fairies",
    "True-to-life",
    "Not imaginary",
    "Down-to-earth",
    "Flesh-and-blood",
    "Concrete",
    "Flesh-and-blood",
    "Coin on the Spanish Main",
    "Unexaggerated",
    "Authentic",
    "Authentic",
    "Down-to-earth",
    "Sincere",
    "Not a hologram, say",
    "Genuine",
    "Bona fide",
    "True-to-life",
    "Honest",
    "Genuine",
    "Material",
    "Not fake",
    "\"Get ___!\"",
    "Unaffected",
    "Unaltered",
    "Bona fide",
    "MTV's \"___ World\"",
    true,
    "Concrete",
    "Honest-to-goodness",
    "Concrete",
    "\"Get ___!\"",
    "Honest-to-God",
    "\"Get ___!\"",
    "Concrete",
    "Not made up",
    "Bona fide",
    "Factual",
    "Kind of number",
    "Tangible",
    "Not imagined",
    "Honest",
    "___ world",
    "Not fake",
    "Word with life or time",
    "Genuine",
    "\"Get ___!\"",
    "Authentic",
    "Not fanciful",
    "Down-to-earth",
    "Concrete",
    "Card-carrying",
    "Concrete",
    "Not fantastic",
    "Heartfelt",
    "Bona fide",
    "\"Get ___!\"",
    "Kind of time",
    "Authentic",
    "Down-to-earth",
    "Bona fide",
    "Not bogus",
    true,
    "MTV's \"The ___ World\"",
    "Touchable",
    "Bona fide",
    "Sincere",
    "Not imaginary",
    "See 45-Down",
    "Unaffected",
    "Lifelike",
    "True-to-life",
    "Genuine",
    "Not imagined",
    "Convincing",
    "Bona fide",
    "Bona fide",
    "True-to-life",
    "Unimagined",
    "Bona fide",
    "Coin on the Spanish Main",
    "Kind of estate",
    "\"Get ___!\"",
    "Kind of estate"
  ],
  "avid": [
    "Voracious",
    "Voracious",
    "Gung-ho",
    "Zealous",
    "Like big fans",
    "Ardent",
    "Devoted",
    "Fervent",
    "Gung-ho",
    "Rah-rah",
    "Like a superfan",
    "Ardent",
    "Hard-core",
    "Passionate",
    "Hard-core",
    "Enthusiastic",
    "Gung-ho",
    "Like devoted fans",
    "Enthusiastic",
    "Gung-ho",
    "Dedicated",
    "Gung-ho",
    "Devoted",
    "Ardent",
    "Like a 6-Down",
    "Like superfans",
    "Gung-ho",
    "All fired up",
    "Fervent",
    "Like noisy fans",
    "Like die-hard fans",
    "Enthusiastic",
    "Zealous",
    "Keen",
    "Eager",
    "Not blase",
    "Enthusiastic",
    "Voracious",
    "Eager",
    "Devoted",
    "Keen",
    "Fervent",
    "Diehard",
    "Gung-ho",
    "Diehard",
    "Fanlike",
    "Rah-rah",
    "Devouring",
    "Zealous",
    "Eager",
    "Keen",
    "Gung-ho",
    "Gung-ho",
    "Gung-ho",
    "Zealous",
    "Like many fans",
    "All fired up",
    "Enthusiastic",
    "Like some fans",
    "Rah-rah",
    "Enthusiastic",
    "Eager",
    "Rah-rah",
    "Greedy",
    "Gung-ho",
    "Gung-ho",
    "Like fans",
    "Ardent",
    "Enthusiastic",
    "Fired up",
    "Like an 8-Down",
    "Dedicated",
    "Gung-ho",
    "Eager",
    "Ardent",
    "Passionate",
    "Keen",
    "Enthusiastic",
    "Keen",
    "Devoted",
    "Fervent",
    "Zealous",
    "Eager",
    "Gung-ho",
    "Rah-rah",
    "Gung-ho",
    "Eager",
    "Devoted",
    "More than willing",
    "Keen",
    "Cupidinous"
  ],
  "nino": [
    "Young 'un in Yucatán",
    "Boy, in Barcelona",
    "Miguel in \"Coco,\" por ejemplo",
    "Spanish youngster",
    "El ___ (Pacific Ocean phenomenon)",
    "Young muchacho",
    "El ___ (weather phenomenon)",
    "El ___ (weather phenomenon)",
    "El ___ (weather phenomenon)",
    "Pinata smasher, maybe",
    "Child in Chile",
    "El ___",
    "El ___ (weather phenomenon)",
    "El ___",
    "Young muchacho",
    "Hombre, once",
    "Little muchacho",
    "Spanish boy",
    "Hombre, formerly",
    "New Mexican, e.g.",
    "Muchacho",
    "Madre's boy",
    "Spanish boy",
    "Chile child",
    "Italian boxer Benvenuti",
    "Bilbao boy",
    "Padre's boy",
    "El ___",
    "Hombre, once",
    "El ___ (weather phenomenon)",
    "Young muchacho",
    "Spanish boy",
    "Chihuahua child",
    "Bogota boy",
    "El ___ (Pacific Ocean phenomenon)",
    "El ___",
    "El ___ (weather factor)",
    "Spanish boy",
    "Youngster from 17-Across",
    "Oaxaca youngster",
    "Spanish child",
    "El ___ (Pacific phenomenon)",
    "Chihuahua child",
    "Boy, in Barcelona",
    "El ___",
    "Muchacho",
    "Boy, in Bogota",
    "Cuban boy",
    "El ___ (weather phenomenon)",
    "Madre's little one",
    "Madre's delivery",
    "Chiapas child",
    "Enfant, across the Pyrenees",
    "Boy of Bogota",
    "Spanish boy",
    "Boy abroad",
    "Child, in Chile",
    "El ___ (weather phenomenon)",
    "El ___",
    "El ___ (ocean current)"
  ],
  "rend": [
    "Tear",
    "Tear",
    "Tear apart",
    "Tear up",
    "Tear",
    "Tear to shreds",
    "Tear to bits",
    "Tear in two",
    "Tear to bits",
    "Tear apart",
    "Tear to pieces",
    "Tear apart",
    "Lacerate",
    "Tear",
    "Tear apart",
    "Tear violently",
    "Tear apart",
    "Tear apart",
    "Pull apart",
    "Tear apart",
    "Tear apart",
    "Tear up",
    "Tear",
    "Cleave",
    "Split",
    "Split",
    "Shred",
    "Tear apart",
    "Split",
    "Tear apart",
    "Split up",
    "Tear",
    "Tear",
    "Split apart",
    "Divide",
    "Tear apart",
    "Split",
    "Tear",
    "Rip up",
    "Split apart",
    "Split",
    "Tear apart",
    "Tear",
    "Rip apart",
    "Tear to bits",
    "Split",
    "Split",
    "Rupture",
    "Slash",
    "Divide",
    "Split",
    "Tear apart",
    "Tear to bits",
    "Tear to pieces",
    "Tear up",
    "Split",
    "Split",
    "Cleave",
    "Rip apart",
    "Pull apart",
    "Tear asunder",
    "Rip",
    "Split",
    "Tear",
    "Tear apart",
    "Rip",
    "Sunder",
    "Split",
    "Tear",
    "Split",
    "Tear",
    "Rip",
    "Split",
    "Split apart",
    "Split apart",
    "Tear"
  ],
  "roes": [
    "Some deer",
    "Salmon and sturgeon delicacies",
    "Some deer",
    "Some deer",
    "Graceful antlered animals",
    "Tobiko and ikura, on a sushi menu",
    "Some deer",
    "Caviars",
    "Caviars",
    "Some antlered animals",
    "Some Eurasian deer",
    "Some expensive hors d'oeuvres",
    "Caviars",
    "Some deer",
    "Old World deer",
    "Masses of fish eggs",
    "Some deer",
    "Some reddish deer",
    "European deer",
    "Some deer",
    "Richard and Jane in court",
    "European deer",
    "Shad delicacies",
    "Some deer",
    "Some deer",
    "Fictitious Richard et al.",
    "Some deer",
    "Shad delicacies",
    "Antlered beasts",
    "Old World game animals",
    "Agile deer",
    "Old World deer",
    "Small deer"
  ],
  "inat": [
    "___ the finish",
    "___ the finish (having potential to win)",
    "___ the beginning",
    "___ the finish",
    "___ the kill",
    "___ the finish",
    "___ the kill",
    "___ the buzzer",
    "___ the finish",
    "___ the kill",
    "___ the finish",
    "___ the beginning (present from the start)",
    "___ the finish",
    "___ the finish",
    "___ the finish",
    "___ the kill",
    "___ the kill",
    "___ the finish"
  ],
  "bosn": [
    "Cap'n's mate",
    "Nautical title, informally",
    "J. M. Barrie's Mr. Smee, e.g.",
    "Cap'n's underling",
    "Cap'n's mate",
    "Rigging handler, briefly",
    "Cap'n's underling",
    "Anchorman, briefly?",
    "Cap'n's underling",
    "Ship's petty officer, informally",
    "Cap'n's underling",
    "One in charge of a ship's rigging",
    "Cap'n's mate",
    "Deck crew's boss, briefly",
    "Cap'n's mate",
    "Rigging pro",
    "Anchorman?"
  ],
  "unos": [
    "Chicago-style pizza chain, familiarly",
    "Pizza chain since 1943, familiarly",
    "Spanish for \"some\"",
    "Ones on Telemundo",
    "Pizzeria chain, casually",
    "Some chain pizzerias",
    "Big pizza chain, informally",
    "Some, in Salamanca",
    "Popular pizza place, informally",
    "Some, in Santiago",
    "Several, in Seville",
    "Pizzeria chain, informally",
    "Pizzeria chain, familiarly",
    "Article in Hoy",
    "Pizzeria chain since 1943, informally",
    "Popular pizza/grill chain, informally",
    "Spanish ones",
    "Spanish articles",
    "Spanish articles",
    "Juan's ones",
    "Ten make diez",
    "Juarez ones"
  ],
  "deco": [
    "Architectural style of Nebraska's capitol building, informally",
    "Roaring Twenties style",
    "Art ___",
    "Retro style",
    "Style influenced by Cubism",
    "Art ___",
    "Erte's art",
    "Art ___",
    "Contemporary of Modernism",
    "Style of Radio City Music Hall, informally",
    "Chrysler Building's style, briefly",
    "Like the 30 Rock building",
    "Style of the Chrysler Building, informally",
    "Retro skyscraper style",
    "Empire State Building style, informally",
    "Like the BBC's headquarters, architecturally",
    "Art ___",
    "See 2-Down",
    "Miami Beach architectural style, informally",
    "1930s art style, informally",
    "Art ___ (1920s-'30s architectural style)",
    "Chrysler Building style, informally",
    "30 Rock's architectural style",
    "Some '30s design",
    "Radio City's architectural style",
    "Art ___",
    "Retro style",
    "Art ___ (1920s-'30s movement)",
    "1920s-'30s style, informally",
    "Art ___",
    "Art ___",
    "Retro art style",
    "Art ___",
    "Retro style",
    "Art ___",
    "'33 Chicago World's Fair style",
    "So-called \"style moderne\"",
    "Like many Miami Beach buildings",
    "Roaring Twenties look",
    "Art ___ (1920s-'30s style)",
    "Art ___ (1920s-'30s style)",
    "Like Rockefeller Center",
    "Art ___",
    "Like the Empire State Building",
    "Like some '39 New York World's Fair buildings",
    "South Beach style",
    "Like the Chrysler Building",
    "Art ___",
    "Like Radio City Music Hall, informally",
    "Like some 1930s design, informally",
    "Like Manhattan's G.E. Building",
    "Like N.Y.C.'s Rockefeller Center",
    "Like Erte's style",
    "Art style, informally",
    "Art ___",
    "Art ___ (7-Down genre)",
    "Art ___",
    "Some art design, informally",
    "Art ___",
    "Retro style",
    "Art follower",
    "Like New York's Radio City Music Hall, informally",
    "Art ___",
    "Like the Normandie, stylewise",
    "Retro art style",
    "Erte's forte",
    "Like some MOMA art",
    "Art follower",
    "Art style, familiarly",
    "Design style, informally",
    "See 57-Down",
    "20's look",
    "Art ___",
    "Erte's art",
    "Art ___",
    "Art ___"
  ],
  "usmc": [
    "Gomer Pyle's outfit: Abbr.",
    "Parris Island grp.",
    "\"Semper Fi\" grp.",
    "Pvt. Pyle's outfit",
    "\"Gomer Pyle, ___\"",
    "\"Semper Fi\" org.",
    "Devil dog's outfit: Abbr.",
    "Gomer Pyle's org.",
    "\"The Few, the Proud ...\" grp.",
    "\"Semper Fidelis\" grp.",
    "\"Semper Fi\" grp.",
    "Parris Isl. outfit",
    "Commandant's outfit: Abbr.",
    "\"Semper Fi\" org.",
    "Mil. branch",
    "Letters at Camp Lejeune",
    "Iwo Jima Memorial honorees: Abbr.",
    "Gomer Pyle's org.",
    "Military inits.",
    "Gomer Pyle's grp.",
    "Letters at Camp Lejeune",
    "\"Semper fi\" grp.",
    "Quantico initials"
  ],
  "nate": [
    "Stand-up comedian Bargatze",
    "Man's name that rhymes with a number",
    "Silver, for one",
    "___ the Great of children's literature",
    "Statistician Silver",
    "Polling expert Silver",
    "\"Great\" boy detective",
    "___ the Great, detective of children's stories",
    "Statistician Silver",
    "FiveThirtyEight creator Silver",
    "___ the Great of children's literature",
    "___ Parker, director and star of 2016's \"The Birth of a Nation\"",
    "\"Big\" comics character",
    "\"Great\" sleuth of kid-lit",
    "\"Big ___\" (comic strip)",
    "Archibald or Thurmond of the N.B.A.",
    "Silver of fivethirtyeight.com",
    "___ the Great of children's literature",
    "___ the Great of children's lit",
    "Statistician Silver",
    "___ Grey, alter ego of Marvel's X-Man",
    "___ Ruess, lead singer of Fun",
    "Silver of fivethirtyeight.com",
    "Kids' detective ___ the Great",
    "Silver of fivethirtyeight.com",
    "Rapper ___ Dogg",
    "\"Great\" detective of children's literature",
    "\"Great\" kid-lit detective",
    "\"Great\" detective of kiddie lit",
    "Campaign/election analyst ___ Silver",
    "___ the Great of children's literature",
    "Basketball player Archibald",
    "___ Dogg of R&B/hip-hop",
    "With 20-Down, kiddie-lit counterpart of Sherlock Holmes",
    "Archibald or Thurmond of the N.B.A.",
    "___ the Great of children's books",
    "\"Great\" boy detective",
    "\"Great\" kid-lit detective",
    "___ the Great (detective of kids' books)",
    "N.B.A.'s Robinson",
    "\"Big\" kid in the comics",
    "Former Celtic Archibald",
    "Basketball great Archibald",
    "1970's baseball All-Star ___ Colbert",
    "Hoopster Archibald",
    "\"Six Feet Under\" role",
    "\"Six Feet Under\" character",
    "Archibald of the N.B.A.",
    "\"Great\" detective of kid lit",
    "___ the Great (boy detective)",
    "\"Great\" detective of children's lit",
    "\"Six Feet Under\" son",
    "\"Sabrina the Teenage Witch\" actor Richert",
    "Son on \"Six Feet Under\"",
    "Hoopster Thurmond",
    "___ the Great, juvenile detective",
    "Archibald of the N.B.A.",
    "Hoopster Archibald",
    "Basketball Hall-of-Famer Archibald",
    "\"Six Feet Under\" son",
    "Comic strip \"Big ___\"",
    "\"Big ___\" (comic strip)",
    "Basketball's Archibald",
    "___ the Great (boy detective)",
    "Tiny Archibald",
    "Hoopster Archibald",
    "1970's baseball All-Star Colbert",
    "Basketball Hall-of-Famer Archibald",
    "N.B.A.'s Archibald",
    "Basketball's Thurmond",
    "___ the Great (boy detective)",
    "Celtics' Archibald",
    "Hoops Hall-of-Famer Thurmond",
    "Hoopster Archibald",
    "McMillan of the court",
    "Cager Archibald",
    "Hoopster Archibald",
    "Basketball's Archibald",
    "Hoopster Archibald",
    "\"Tiny\" Archibald",
    "\"Big ___\" of the comics page",
    "Basketball's Archibald",
    "___ the Great (juvenile detective)",
    "Hoops great Archibald",
    "Newton of the N.F.L.",
    "Archibald of basketball",
    "Basketball's Thurmond",
    "Hoopster Thurmond",
    "Basketball great Thurmond",
    "Hoopster Archibald",
    "Thurmond of the N.B.A.",
    "N.B.A. star Thurmond",
    "N.B.A.'s Archibald",
    "Basketball's Archibald",
    "Thurmond of hoops",
    "N.B.A.'s Thurmond",
    "N.B.A.'s Archibald"
  ],
  "andi": [
    "\"___ Am Telling You\" (song from \"Dreamgirls\")",
    "\"___ Love Her\" (Beatles ballad)",
    "\"___ quote ...\"",
    "\"The King ___\"",
    "The Beatles' \"___ Love Her\"",
    "\"... ___ can't get up!\"",
    "\"You ___\" (2011 Lady Gaga song)",
    "The King's followers?",
    "\"... ___ can't get up!\"",
    "Quote lead-in?",
    "\"I've fallen ... ___ can't get up!\"",
    "\"___ quote ...\"",
    "\"You ___\" (2004 Celine Dion hit)",
    "\"You ___\" (1982 Eddie Rabbitt hit)",
    "\"The King ___\"",
    "Me, myself ___",
    "The Beatles' \"___ Love Her\"",
    "1947 romantic comedy \"The Egg ___\"",
    "Follower of myself",
    "\"___ quote ...\"",
    "\"Me, myself ___\"",
    "\"___ quote ...\"",
    "\"The King ___\"",
    "\"The Egg ___\"",
    "\"Me, myself ___\"",
    "\"___ quote ...\"",
    "\"Me, myself ___\"",
    "The Beatles' \"___ Love Her\"",
    "Perry Como's \"___ Love You So\"",
    "\"The King ___\"",
    "\"The King ___\"",
    "\"The King ___\"",
    "\"...___ can't get up!\"",
    "Como's \"___ Love You So\"",
    "1941 Glenn Miller chart topper \"You ___\"",
    "The Beatles' \"___ Love Her\"",
    "\"The King ___\"",
    "Como?Æs ?ô___ Love You So?ö",
    "The Beatles' \"___ Love Her\"",
    "TheBeatles' \"___ Love Her\"",
    "\"The King ___\"",
    "\"The Egg ___\""
  ],
  "karl": [
    "Lagerfeld of fashion",
    "Marx who co-wrote \"The Communist Manifesto\"",
    "Designer Lagerfeld",
    "Marx who co-wrote \"The Communist Manifesto\"",
    "Marx who wasn't one of the Marx Brothers",
    "N.B.A. coaching great George ___",
    "Sociologist Mannheim",
    "Jazz player Malone",
    "Malden or Marx",
    "Communism theorist Marx",
    "Designer Lagerfeld",
    "Bush adviser Rove",
    "Benz of Mercedes-Benz fame",
    "Rove in politics",
    "N.B.A. coach George ___",
    "Jazz great Malone",
    "Marx with a manifesto",
    "Bush adviser Rove",
    "Baedeker of the Baedeker travel guides",
    "Hoopster Malone",
    "Jazz's Malone",
    "One of the Wallendas",
    "First name in Communism",
    "Wallenda family patriarch",
    "Baron Munchhausen",
    "Patriarch of the Flying Wallendas",
    "Marx with a manifesto",
    "Jazz great Malone",
    "Baedeker of the Baedeker travel guides",
    "First name in Communism",
    "Auto engineer Benz",
    "Auto pioneer Benz",
    "Swiss theologian Barth",
    "Basketball's Malone",
    "Marx who wrote \"Das Kapital\"",
    "See 31-Across"
  ],
  "teat": [
    "Udder end",
    "Suckling site",
    "A kid drinks from one",
    "Nipple",
    "Milk dispenser",
    "Nipple",
    "Something farm-squeezed?",
    "Nipple",
    "Milkmaid's handful",
    "Nursing need",
    "Milk dispenser",
    "Milk source",
    "Suckling site",
    "What a milkmaid holds",
    "Milk source",
    "*Milk source",
    "Milk dispenser",
    "Animal milk source",
    "Milker's handhold",
    "Milk source",
    "An udder thing",
    "Part of a baby bottle",
    "Nursing need",
    "Udder part",
    "Suckling spot",
    "Mammilla",
    "Suckling spot",
    "Sow feature",
    "Mammilla"
  ],
  "sick": [
    "Warped, as a sense of humor",
    "Ailing",
    "Down with the flu, say",
    "Really cool, in slang",
    "Totally awesome, in slang",
    "\"Awesome!\"",
    "Ailing",
    "Start of a \"recuperative\" word ladder ending at 73-Across",
    "___ as a dog",
    "Onetime competitor of Mad magazine",
    "Really cool, in slang",
    "Down with the flu, say",
    "Green around the gills",
    "___ as a dog",
    "Twisted",
    "Really warped",
    "Twisted",
    "Really warped",
    "Twisted",
    "Under the weather",
    "Down with something",
    "Not well",
    "Under the weather",
    "Mentally twisted",
    "Like some jokes",
    "Mentally twisted",
    "Kind of bay",
    "Down with the flu",
    "Under the weather"
  ],
  "onea": [
    "Suited to serve",
    "Fit for military service",
    "Building superintendent's apartment number, often",
    "Super's apartment, often",
    "Fit for military service",
    "Draft classification",
    "Seat for a priority boarder, maybe",
    "Apartment number for many a building superintendent",
    "What Elvis Aaron Presley's middle name is spelled with on his birth certificate",
    "Prime draft status",
    "___ Day (supplement)",
    "Prime draft category",
    "Most fit to fight",
    "Service designation",
    "___ Day vitamins",
    "Draftable",
    "Prime draft pick",
    "Highest draft category",
    "Suited to serve",
    "Prime draft category",
    "Draft classification",
    "Eminently draft-worthy",
    "Fit for service",
    "Fit for service",
    "Fit for service",
    "Suited to serve",
    "Prime draft status",
    "Top draft status",
    "Qualified to serve",
    "Draft-worthy",
    "Prime draft classification",
    "Draft classification",
    "Ready for service",
    "Prime draft classification",
    "Fit for service",
    "Highly draftable ... or a feature of the word \"draft\"",
    "Prime draft pick",
    "Highly desirable to Uncle Sam?",
    "Eligible to serve",
    "Suitable for induction",
    "Required to serve, maybe",
    "Most likely to be called up",
    "Eligible to be called up",
    "Fit for drafting",
    "Draft status",
    "Fit for duty, draftwise",
    "Prime draft status",
    "Fit to serve",
    "Prime window seat",
    "Draft-ready",
    "Most eligible for service",
    "Most eligible to be drafted",
    "Fit to serve",
    "Eminently draftable",
    "Prime draft classification",
    "Decidedly eligible, in a way",
    "Draftable",
    "Ready for service",
    "Prime draft status",
    "Fit to be called up",
    "Fit for service",
    "Most eligible for military service",
    "Ready to be called",
    "Fit to serve",
    "With 70-Across, vitamin bottle phrase",
    "Fit for duty",
    "Ready to serve",
    "Fit for duty",
    "29-Down's number, maybe",
    "First to be called up",
    "Fit to serve",
    "Draft status",
    "Top status",
    "Ready to serve",
    "___ Day",
    "Prime draft status",
    "Ready for service",
    "Suitable for service",
    "___-Day",
    "Sans deferment",
    "Fit to serve",
    "Line starter in \"Hot Cross Buns\"",
    "Top-rated, in a way",
    "Prime draft status",
    "Prime status",
    "Draft status",
    "Fit for service",
    "Fit to serve",
    "Good to go, in the military",
    "Unpopular classification, in the 60's",
    "Ready to serve",
    "Service status",
    "Most fit to serve",
    "Most likely to be called up",
    "Fit for drafting",
    "Fit to serve",
    "Prime S.S.S. classification",
    "Top draft level",
    "Liable to be called",
    "Draft status",
    "Draft-board classification",
    "Fit for drafting",
    "Most eligible, in a way",
    "Prime draft status",
    "Fit for service",
    "Convenient apartment",
    "Quite qualified to serve",
    "Service status",
    "Draftable",
    "Available to serve",
    "Fit to serve",
    "First to be called up",
    "Fit to serve",
    "Most qualified to serve",
    "___-Day vitamins",
    "Service status",
    "Most qualified to serve?",
    "Service status",
    "Most fit for the forces",
    "Most qualified to serve",
    "Draftable",
    "Eligible for service",
    "High draft rating",
    "Conscriptable",
    "Suitable for service",
    "Serviceable?",
    "Super's apartment, often",
    "Most qualified to serve",
    "Serviceable?",
    "Ready to serve",
    "Draftable",
    "Readily conscriptable",
    "Eminently draftable",
    "Most qualified to serve",
    "First to be called",
    "___-Day vitamins",
    "Suitable for serving",
    "Ready to go in",
    "Draft classification",
    "Perfectly draftable",
    "Draft status",
    "Fit for service",
    "Most draftable",
    "Most eligible",
    "Prime draft classification",
    "How draft dodgers didn't want to be classified",
    "Perfectly healthy, to the Army",
    "Liable to be called",
    "___-Day (vitamin brand)",
    "Like some eligibles, once",
    "Most eligible, once",
    "Suitable for service",
    "Ready for service",
    "Super's apartment number, maybe",
    "Draft designation",
    "___-Day vitamins",
    "Draft status",
    "Readily draftable",
    "Draft rating",
    "?ôHot cross buns, ___ penny, two...?ö",
    "Top status",
    "Draft classification",
    "Draft status",
    "Prime draft status",
    "Fit to serve",
    "Eminently draftable",
    "Draft status",
    "Militarily fit",
    "Ripe for drafting",
    "___ -Day vitamins",
    "Having no deferments",
    "Unwanted classification, once",
    "Eminently draftable"
  ],
  "iggy": [
    "Punk's Pop",
    "Azalea with the 2014 #1 hit \"Fancy\"",
    "Rapper Azalea with the 2014 hit \"Fancy\"",
    "Rapper ___ Azalea",
    "___ Azalea (\"Fancy\" rapper)",
    "Punk rock's ___ Pop",
    "Pop of rock",
    "Azalea of rap",
    "Azalea with the 2014 #1 hit \"Fancy\"",
    "Azalea with the 2014 #1 hit \"Fancy\"",
    "Pop's ___ Pop",
    "___ Pop, 2010 Rock and Roll Hall of Fame inductee",
    "Pop's ___ Pop",
    "Rock's ___ Pop",
    "Rock's ___ Pop",
    "Rock singer ___ Pop"
  ],
  "eddy": [
    "Water whirl",
    "Small whirlpool",
    "Small whirlpool",
    "Swirl",
    "Small whirlpool",
    "Water swirl",
    "Swirl",
    "Swirl above a drain",
    "Small vortex",
    "Drain feature",
    "Water whirl",
    "Small whirlpool",
    "Circular water current",
    "It goes clockwise or counterclockwise depending on the hemisphere",
    "Stream disturbance",
    "Water swirl",
    "Turning point?",
    "It's a small whirl after all",
    "Mini-whirlpool",
    "Start to go down the drain",
    "Vortex in the sink",
    "Small whirlpool",
    "Start to go down the drain",
    "Swirl",
    "Small whirlpool",
    "Cause of some turbulence",
    "Small vortex",
    "Water whirl",
    "Small whirlpool",
    "Little sucker?",
    "Drain feature",
    "Whirling water",
    "Whirl",
    "It's a small whirl after all",
    "Whitewater phenomenon",
    "Water whirled",
    "Sight near a drain",
    "Spin zone?",
    "Small vortex",
    "Turning point?",
    "Dust devil, e.g.",
    "Whirling water",
    "Small vortex",
    "Swirl",
    "Whirling water",
    "It may be found near a drain",
    "Water swirl",
    "Possible result of pulling the plug",
    "Whirling water",
    "Swirl",
    "Mini-whirlpool",
    "Certain air current",
    "Current happening?",
    "Result of pulling the plug?",
    "Drainage indicator",
    "Whirlpool",
    "Whirling current",
    "The Christian Science Monitor founder",
    "Swirl",
    "Water whirled",
    "Vortex",
    "Christian Science founder",
    "Whirling water",
    "Whirlpool",
    "Countercurrent",
    "Whirling water",
    "Water swirl",
    "Drain feature",
    "Current happening?",
    "Water whirl",
    "Swirl",
    "Drain indicator",
    "Draining effect?",
    "Little spin",
    "Water swirl",
    "Small revolver",
    "MacDonald's partner in old movies",
    "Small whirlpool",
    "It may be found near a drain",
    "Water awhirl",
    "Swirl",
    "Small whirlwind",
    "Whirling water",
    "Vortex",
    "Swirl",
    "Countercurrent",
    "Swirl",
    "Singer Nelson of 30's-40's film",
    "Drain sight",
    "Swirl",
    "Vortex",
    "Whirling current",
    "Small whirlpool",
    "Whirl",
    "Singer Arnold",
    "Turning point",
    "Countercurrent",
    "Mini-whirlpool",
    "Whirl",
    "Water whirled",
    "Whirling water",
    "Swirl",
    "Whirlpool",
    "Countercurrent",
    "Vortex",
    "Whirlpool",
    "Small whirlpool",
    "Whirlpool",
    "Water whirled",
    "Christian Science founder",
    "Turning point?",
    "Where debris gets caught",
    "Go round and round",
    "Swirl",
    "Vortex",
    "Mini-whirlpool",
    "Whirl",
    "Christian Science founder",
    "Whirlpool",
    "Water whirl",
    "Whirlpool",
    "Swirl",
    "Christian Science founder",
    "Christian Scientist Mary Baker ___",
    "Small whirlpool",
    "Whirlpool",
    "Whirlpool",
    "Christian Science founder",
    "Swirl",
    "Nelson of 30's musicals"
  ],
  "este": [
    "Spanish direction",
    "Ciudad del ___, Paraguay",
    "Direction in which el sol rises",
    "Point on a vane in Spain",
    "Direction in Espana",
    "Sunrise direction, in Seville",
    "Renaissance family name",
    "Toward sunrise: Sp.",
    "Aristocratic Italian name of old",
    "Spanish direction",
    "Italia's Casa d'___",
    "Certain direccion",
    "Where the sun rises, in Mexico",
    "Durango direction",
    "Punta del ___ (Uruguayan resort)",
    "Sunrise's direction, in Sonora",
    "Toward el sol naciente",
    "Tequila sunrise direction",
    "Tivoli's Villa d'___",
    "Direccion geografica",
    "Big name in Renaissance patronage",
    "Villa d'___",
    "Villa d'___",
    "Sevilla-to-Granada direction",
    "Villa d'___",
    "Villa d'___",
    "Villa d'___",
    "Villa d'___",
    "Oriente",
    "Sunrise direction, in Sonora",
    "Villa d'___",
    "Villa d'___",
    "Toward the Atlantic, in Mexico",
    "Sunrise direction in Spain",
    "Veracruz vane direction",
    "Spanish direction",
    "90?? from norte",
    "City in Padua province",
    "90?? from sur",
    "House of ___ (European dynasty)",
    "Desde Madrid a Mallorca",
    "House of ___ (European princely dynasty)",
    "Ciudad del ___ (second-largest city in Paraguay)",
    "European princely dynasty name",
    "Villa d'___",
    "90 degrees from sur",
    "City in Padua province",
    "Villa d'___",
    "90 degrees from sur",
    "Ferrara family name",
    "City near Padua",
    "City in Veneto",
    "Noble Lombard family name",
    "Ferrara ruling family",
    "Renaissance family name",
    "Italy's Villa d'___",
    "City SW of Padua",
    "Direccion from which the sun rises",
    "Tivoli's Villa d'___",
    "Renaissance family name",
    "City near Padua",
    "90 degrees from sur",
    "Spanish direction",
    "Famed Ferrara family",
    "Villa d'___",
    "Ferrara family name",
    "Longtime Ferrara family name",
    "Direction from which el sol rises",
    "90?? from norte",
    "Renaissance family name",
    "Punta del ___, Uruguay",
    "Italian Renaissance art patron",
    "Spanish direction",
    "Renaissance family name",
    "Villa d'___",
    "Renaissance family name",
    "90?? from norte",
    "Italian noble family name",
    "Old Modena family name",
    "Toward sunrise, in Mexico",
    "Villa d'___",
    "Villa d'___",
    "Town in the Euganean Hills",
    "Historic Italian family name",
    "Renaissance patron",
    "Cadiz compass point",
    "Town near Padua",
    "Medieval Italian fortress city",
    "Alberto Azzo was a noted one",
    "NE Italian city",
    "Italian fortress site",
    "Renaissance Italian family name",
    "This, in Espana",
    "Spanish compass point",
    "53-Down, in Italia",
    "Ferrara family name",
    "Villa d'___",
    "Renaissance patron",
    "Noble Italian family name",
    "Italy's Villa d'___",
    "Villa d'___",
    "Renaissance family name",
    "Direction in which el sol rises",
    "Medieval fortress city in Italy",
    "This, in Tampico",
    "Villa d'___",
    "90?? from norte",
    "Direction from which el sol rises",
    "Princely family name of old",
    "Noted Renaissance patron",
    "Boiardo patron",
    "Boiardo's patron",
    "Villa d'___",
    "90?? from sur",
    "Family of patrons of the arts",
    "Early Ferrara ruling house",
    "Where to see a Sonora sunrise",
    "One of four direcciones",
    "Site of Roman ruins",
    "Isabella d'___",
    "Punta del ___, Uruguayan resort",
    "Borgia in-law",
    "Renaissance art patron",
    "It's right on a Spanish map",
    "This, to Luis",
    "Italian duchess Beatrice d'___",
    "Direction in Durango",
    "Boiardo's patron",
    "Tasso's patron",
    "Italian art patron",
    "City near Padua",
    "From Santiago to Buenos Aires",
    "Ferrara family name",
    "Spanish direction",
    "Borgia in-law",
    "Renaissance name of fame",
    "Renaissance art patron",
    "Villa d'___",
    "Renaissance name of fame",
    "Point on Columbus's compass",
    "City near Padua",
    "Ferrara family name",
    "Villa d'___",
    "Point on Magellan's compass",
    "Sunrise direction, in Sonora",
    "Villa d'___",
    "Villa d'___",
    "Where el sol rises",
    "Villa d'___",
    "Isabella d'___ (Titian subject)",
    "Italian princely family name",
    "Villa d'___",
    "Isabella d'___ (famed beauty)",
    "Direction in Durango",
    "90 (degrees) from norte",
    "Marchese d'___ (Italian nobleman)",
    "First Family of Ferrara",
    "Villa-building family",
    "Punta del___, Uruguay",
    "Town near Padua",
    "Villa d'___",
    "Ferrara family name",
    "Beatrice d'___ (fabled beauty)",
    "Villa d'___",
    "Famous Ferrara family",
    "Historic Italian family name",
    "Town near Padua",
    "Italian noble house",
    "90 degrees from norte",
    "Villa d'___",
    "Villa d'___",
    "Italy's Isabella d'___",
    "Villa-building family",
    "Town near Padua",
    "Italian art patron",
    "It's \"east\" of Toledo",
    "Borgia in-law",
    "Noted Renaissance name",
    "Villa d'___",
    "Villa d'___",
    "Tasso's patron",
    "Ferrara family",
    "Town near Padua",
    "Noted Ferrara family"
  ],
  "wife": [
    "\"The Good ___\"",
    "One who's taken a vow",
    "Mrs.",
    "Desdemona, to Othello",
    "Portia de Rossi, to Ellen DeGeneres",
    "Bachelor's least favorite radio station?",
    "Certain union member",
    "One who's done the \"I do's\"",
    "See 51-Across",
    "Not a single person",
    "Ring bearer",
    "Tenth Commandment word",
    "Good radio station for a bride?",
    "The \"Judy\" of Punch and Judy",
    "Ring bearer",
    "\"The Farmer's ___\" (1928 Hitchcock film)",
    "Bride, after the vows",
    "Lois, to Hi",
    "One who's been down the aisle",
    "Helen, to Menelaus",
    "44-Across, e.g.",
    "Bachelor's lack",
    "Ring bearer",
    "She said \"I do\"",
    "Katharina, to Petruchio",
    "Missus",
    "See 10-Across",
    "Talese's \"Thy Neighbor's ___\""
  ],
  "navi": [
    "Blue-skinned race in \"Avatar\"",
    "Blue race in \"Avatar\"",
    "Blue race in \"Avatar\"",
    "Pandorans in \"Avatar\"",
    "\"Avatar\" race",
    "Language of Pandora",
    "Blue-skinned race in \"Avatar\"",
    "\"Avatar\" people",
    "Riders on Direhorses in \"Avatar\"",
    "Language spoken on Pandora",
    "Humanoid race in \"Avatar\""
  ],
  "antz": [
    "DreamWorks's first animated film",
    "1998 DreamWorks film",
    "Animated film of 1998",
    "Second U.S. feature-length computer-animated movie, after \"Toy Story\"",
    "Hit 1998 animated film",
    "Movie that came out about the same time as \"A Bug's Life\"",
    "Animated bug film",
    "DreamWorks's first animated film",
    "Film with the protagonist \"Z\"",
    "Hit 1998 animated movie",
    "1998 animated film loosely based on \"Brave New World\"",
    "Animated 1998 film set in Central Park",
    "DreamWorks's first animated film",
    "1998 film featuring Princess Bala",
    "Dreamworks's first animated film",
    "1998 film featuring Colonel Cutter",
    "Animated bug film of 1998",
    "Animated film featuring the voices of Gene Hackman and Sylvester Stallone",
    "1998 animated film with a queen",
    "Animated film hit of 1998",
    "1998 animated bug film",
    "1998 animated bug film",
    "Animated bug movie of 1998",
    "Computer-animated hit film of 1998",
    "1998 animated film with the voice of Woody Allen",
    "Film cartoon with the voice of Woody Allen",
    "1998 animated film"
  ],
  "lois": [
    "___ Lane",
    "Comic strip title character who is Beetle Bailey's sister",
    "___ Lane, lover of Superman",
    "Clark's crush at the Daily Planet",
    "Children's author Lowry",
    "Pioneering silent director Weber",
    "Trixie's mom, in the comics",
    "Children's author Lowry",
    "Writer Lowry with two Newbery Medals",
    "Lane in Metropolis",
    "Amy Adams's \"Man of Steel\" role",
    "Wife on TV's \"Family Guy\"",
    "Lane in a strip",
    "Mom on \"Family Guy\"",
    "Mom on \"Malcolm in the Middle\"",
    "\"Family Guy\" wife",
    "Co-worker of Clark",
    "___ Lowry, children's writer",
    "\"Family Guy\" wife",
    "Newbery Medal-winning author Lowry",
    "Clark's gal in \"Superman\"",
    "Lane of the Daily Planet",
    "Lane in Metropolis",
    "Trixie's mom, in the comics",
    "\"Family Guy\" mom",
    "Colleague of Clark at the Daily Planet",
    "\"Such Good Friends\" novelist Gould",
    "Cohort of Clark",
    "Hi Flagston's wife, in the comics",
    "\"Family Guy\" mom",
    "With 85-Down, old DC Comics spinoff",
    "Daily Planet worker",
    "Two-time Newbery winner ___ Lowry",
    "Children's author Lowry",
    "Dik Browne's \"Hi and ___\"",
    "Title mom in a comic strip",
    "\"Superman\" role",
    "Newbery-winning author ___ Lowry",
    "Clark?Æs crush",
    "Hi's partner",
    "TV role for 67-Across",
    "Clark's partner",
    "___ Lane, admirer of Superman",
    "Half of a cartoon duo",
    "Colleague of Clark at The Daily Planet",
    "Lane in Metropolis",
    "Lane of \"Superman\"",
    "Two-time Newbery winner Lowry",
    "Novelist Gould",
    "Clark's girl",
    "Perry underling",
    "Clark's interest",
    "Mrs. Flagston of the comics",
    "Hi's helpmate, in the comics",
    "One of the Flagston family, in the funnies",
    "Novelist Gould",
    "Partner for Clark",
    "Hi's helpmate",
    "TV's \"___& Clark\"",
    "Superman's sweetie",
    "Teri Hatcher TV role",
    "TV's \"___ and Clark\"",
    "Maxwell of 007 films",
    "One of Perry's reporters",
    "\"Daily Planet\" reporter",
    "Hi's partner",
    "Ad exec George ___",
    "Fictional newswoman"
  ],
  "hors": [
    "___ d'oeuvres",
    "___ d'oeuvre",
    "___ de combat (out of action due to injury)",
    "Start of a start of a menu?",
    "___ d'oeuvres",
    "___ concours (unrivaled: Fr.)",
    "___ de combat",
    "___ de combat",
    "___ de combat",
    "___ d'oeuvre",
    "___ de combat",
    "___ de combat",
    "___ d'oeuvre",
    "___ concours (not competing)",
    "___ d'oeuvre",
    "___ de combat",
    "___ d'oeuvre",
    "___ de combat",
    "Outside of France?",
    "___ de combat",
    "___d'oeuvres",
    "___d' oeuvres",
    "___ de combat",
    "___de combat"
  ],
  "fuji": [
    "Peak viewable from Tokyo on a clear day",
    "Mountain overlooking Tokyo",
    "Big name in camera film",
    "Name on a blimp",
    "It's big in Japan",
    "Honshu peak",
    "It blew in 1707",
    "Japan's tallest peak",
    "Volcano viewable from Tokyo",
    "Film maker",
    "Digital camera brand",
    "Kodak competitor",
    "Mount held sacred by pilgrims",
    "Honshu peak",
    "Kodak competitor",
    "Japanese volcano",
    "Honshu peak"
  ],
  "nest": [
    "Sticky spot?",
    "It may go out on a limb",
    "Structure put together by a crane",
    "Tree house?",
    "Cozy home",
    "Spot for a sitting duck",
    "Sticky pad?",
    "Place to find the birds and the bees?",
    "Where the baby bird gets the worm",
    "Exterminator's target",
    "Where talk is cheep?",
    "Where a nuthatch hatches",
    "Store one inside another",
    "Hatchlings' home",
    "Sticks together?",
    "Fit together like matryoshka dolls",
    "Stick-y pad?",
    "\"Empty ___\" (1980s-'90s sitcom)",
    "Bird's home",
    "Twitter platform?",
    "Make a homey home",
    "Bird's home",
    "Catbird seat?",
    "Place for baby birds",
    "Fit one inside the other",
    "Beginning point for a first flight",
    "Twitter site",
    "Flight destination?",
    "Container for eggs",
    "Home in the forest",
    "Spot for brooding",
    "Jay's place",
    "A crane might hover over one",
    "Start and end of many a flight",
    "Ants' home",
    "Home composed of twigs",
    "Swiftly built home?",
    "One out on a limb?",
    "Chick's place",
    "What matryoshka dolls do",
    "Cozy spot",
    "Where eggs hatch",
    "Fit snugly",
    "Something studied by a caliologist",
    "Fit (in)",
    "Place to brood",
    "Egg holder",
    "Home in the sticks?",
    "With 102-Across, future funds",
    "First flight locale",
    "Where to get eggs",
    "Home located in the sticks?",
    "Rail construction",
    "Hive, in effect",
    "Beech house?",
    "Be domestic",
    "Leaves home?",
    "Natural hatchery",
    "Hornets' home",
    "Hornets' home",
    "One out on a limb?",
    "Robin's haven",
    "Hideout",
    "Snuggery",
    "Egg-hatching spot",
    "Get a home in order",
    "Site for a diet of worms?",
    "Egg holder",
    "It might be out on a limb",
    "Wren den",
    "Digs in twigs",
    "Where 38-Across lay 1-Across ... or a word hidden in 20-, 27-, 44- and 51-Across",
    "Fit (in)",
    "Wasp's home",
    "Partridge family setting",
    "Settle in",
    "Snug retreat",
    "Cozy home",
    "Fit (inside)",
    "It might hold a few swallows",
    "Egg holder",
    "\"Cheep\" accommodations",
    "Cheeper lodging?",
    "Home in the sticks?",
    "Sticks in a bowl?",
    "Digs of twigs",
    "Roofless home",
    "Where eggs hatch",
    "See 10-Down",
    "Digs in the forest",
    "Settle in",
    "Home in a 90-Down",
    "See 48-Down",
    "Kite flying destination?",
    "Snipers' place",
    "Some dolls can do it",
    "Comfy spot",
    "Love ___",
    "Where eggs are laid",
    "Cozy retreat",
    "Fit snugly",
    "Leaves home?",
    "Place for eggs",
    "Fit snugly",
    "Cardinal's home",
    "Hatchling's site",
    "Wasps' home",
    "Group of spies",
    "Swift production?",
    "Home of straw, maybe",
    "Hatchling's home",
    "Home, informally",
    "Cozy home",
    "Place to brood",
    "Diet of worms locale?",
    "Site for brooding",
    "Fit (in)",
    "Crane construction",
    "Cheep accommodations?",
    "Snug retreat",
    "Hatchling's home",
    "Egg container",
    "Hatchling's home",
    "Make a home",
    "Home in a tree",
    "Hotbed",
    "Hatchling's home",
    "Egg holder",
    "Bird's home",
    "Den",
    "Den",
    "Cozy retreat",
    "Leaves home?",
    "Bird sanctuary",
    "It may go out on a limb",
    "Egg holder",
    "Where stilts may be found",
    "Viper's home",
    "Catbird seat?",
    "Branch headquarters?",
    "Cozy retreat",
    "Digs in a tree",
    "Fit one inside another",
    "Egg holder",
    "Egg holder",
    "Build a tree house?",
    "Catbird seat?",
    "Colony",
    "Home, informally",
    "Hatching place",
    "Arboreal abode",
    "Spider's home",
    "Where a brood is raised",
    "\"Cheep\" accommodations?",
    "Love ___",
    "Kite's home",
    "Birth place",
    "Egg holder",
    "Twiggy digs",
    "Layer's lair",
    "Crane site",
    "Oriole's abode",
    "Breeding place",
    "Starting point for a flight test?",
    "Wasp's home",
    "Habitat for 28-Down",
    "Digs of twigs",
    "Airy home",
    "It may be out on a limb",
    "Arbor abode",
    "Jay's home",
    "Avian haunt",
    "Cardinal point?",
    "Flies home?",
    "Catbird seat?",
    "Insect's home",
    "Den",
    "It's under layers",
    "Hornet's home",
    "Home tweet home?",
    "Incubation station",
    "\"___-ce pas?\"",
    "Nutcracker suite",
    "It may be feathered",
    "Round home",
    "It may be out on a limb",
    "Leaves home?",
    "Robin's residence",
    "Swallow flat",
    "Love ___",
    "Cozy home",
    "Kind of egg",
    "Hornets' home",
    "Queen's home",
    "Home in the woods",
    "Emulate the birds and the bees",
    "Hotbed",
    "Digs to twigs",
    "Robin's home",
    "Home built in the spring",
    "Egg holder",
    "Bird pad",
    "Stick-y position?",
    "\"___-ce pas?\"",
    "Tree house",
    "Cozy place",
    "Snuggery",
    "Cozy niche",
    "Chinese soup ingredient",
    "Make a home",
    "Machine-gun bunker",
    "Hatching site",
    "Place for eggs",
    "Hatching post?",
    "Brood",
    "Egg container",
    "Branch headquarters?",
    "Swallow hole?",
    "Vespiary"
  ],
  "ados": [
    "Hubbubs",
    "Hubbubs",
    "Bustles",
    "Troubles",
    "Dustups",
    "Fusses",
    "Bustles",
    "Commotions",
    "Stirs",
    "Troubles",
    "Big scenes",
    "Turmoils",
    "Hubbubs",
    "Stirs",
    "Bothers",
    "Kerfuffles",
    "Flaps",
    "Kerfuffles",
    "Troubles",
    "Brouhahas",
    "Commotions",
    "Kerfuffles",
    "Hoo-has",
    "Commotions",
    "Flaps",
    "Rackets",
    "Flurries",
    "Commotions",
    "Hubbubs",
    "Big rushes",
    "Fusses",
    "Commotions",
    "Troubles",
    "Brouhahas",
    "Flaps",
    "Commotions",
    "Kerfuffles",
    "Brouhahas",
    "Buzzes",
    "Big deals",
    "Kerfuffles",
    "Foofaraws",
    "Hullabaloos",
    "Commotions",
    "Commotions",
    "Big deals",
    "Fusses",
    "Kerfuffles",
    "Bustles",
    "Commotions",
    "Tumults",
    "Flutters",
    "Fusses",
    "Fusses",
    "Fusses",
    "Commotions",
    "Commotions",
    "Fusses",
    "Commotions",
    "Flaps",
    "Hullabaloos",
    "Stinks",
    "Bothers",
    "Hubbubs",
    "Stirs",
    "Hubbubs",
    "Ruckuses",
    "Hectic episodes",
    "Stirs",
    "Bustles",
    "Hullabaloos",
    "Hoo-has",
    "Fusses",
    "Ceremonies",
    "Hoo-has",
    "Fusses",
    "Fusses",
    "Hullabaloos",
    "Uproars",
    "Commotions",
    "Tempests in teapots",
    "Ructions",
    "Stirs",
    "Hornets' nests",
    "Fusses",
    "Bustles",
    "Pothers",
    "Commotions",
    "Flurries",
    "Hurly-burlies",
    "Commotions",
    "Fusses",
    "Hubbubs",
    "Hubbubs",
    "Flaps",
    "Commotions",
    "Fusses"
  ],
  "huge": [
    "Leviathan",
    "Very consequential",
    "Bigger than big",
    "Colossal",
    "Like a leviathan",
    "Massive",
    "Mammoth",
    "Ginormous",
    "Donald Trump catchword",
    "Gigantic",
    "Leviathan-like",
    "Monster",
    "Enormous",
    "Tremendous",
    "Whopping",
    "Overwhelming",
    "Very large",
    "Record-setting",
    "Extremely popular",
    "Extremely popular",
    "All over the media",
    "Bigger than big",
    "Bigger than big",
    "Monster, so to speak",
    "Massive",
    "Extremely successful",
    "Whopping",
    "More than large",
    "Bigger than the both of us",
    "Elephantine",
    "Gargantuan",
    "Tremendous"
  ],
  "lava": [
    "Stream from a volcano",
    "Volcanologist's study",
    "Molten flow",
    "Rolling rock?",
    "Volcanic output",
    "\"Ingredient\" in molten chocolate cake",
    "Volcanic flow",
    "Outpouring from a volcano",
    "Volcano's spew",
    "Volcano's spew",
    "Volcanic flow",
    "What obsidian forms from",
    "Volcanic rock",
    "It flows and glows",
    "Rock that rolls?",
    "___ flow",
    "Composition of some beds",
    "It goes with the flow",
    "What a volcano erupts",
    "Volcanic output",
    "Volcano's output",
    "Coulee's contents",
    "___ cake (rich dessert)",
    "Chocolate ___ cake (dessert with a molten center)",
    "Slow flow",
    "Soap with pumice",
    "Mauna Kea emission",
    "Volcanic emission",
    "Volcanic flow",
    "Soap brand that contains pumice",
    "Volcanic emission",
    "Source of pumice",
    "Dangerous outpouring",
    "Volcano output",
    "Obsidian rock producer",
    "Certain lamp goo",
    "Source of venanzite",
    "Cone former",
    "Rolling stone?",
    "Volcano's output",
    "Pumice source",
    "Material from a volcano",
    "Molten flow",
    "Abrasive soap brand",
    "Leucite source",
    "Molten flow",
    "Etna product",
    "Volcanic output",
    "It may be found in a cone",
    "Flow in a coulee",
    "___ lamp (1960s novelty)",
    "Source of basalt",
    "Content of some cones",
    "58-Down output",
    "Volcanic discharge",
    "Source of pumice",
    "Source of basalt",
    "Rolling rock",
    "Soft rock?",
    "Magma, after surfacing",
    "Volcano output",
    "Source of basalt",
    "___ lamp",
    "Volcanic flow",
    "Rolling rock?",
    "Volcano flow",
    "Composition of some beds",
    "Popular soap",
    "Rolling rock",
    "Hot rock",
    "Hot issue",
    "Hot rock",
    "Pelee spew",
    "Source of leucite and rhyolite",
    "Popular hand soap",
    "Basalt source",
    "Slow roller",
    "Obsidian, once",
    "Output of Mount Etna",
    "Cone material",
    "Obsidian, before cooling",
    "Rock on the roll?",
    "Hawaiian bubbly?",
    "Volcanic flow",
    "Contents of some cones",
    "Rolling rock?",
    "___ Beds National Monument, Calif.",
    "It goes with the flow",
    "It may be found in a cone",
    "Etna output",
    "Stromboli output",
    "Hot issue",
    "Volcanic flow",
    "Result of venting?",
    "Hot stuff",
    "Basalt source",
    "Soap brand",
    "Volcano flow",
    "Volcanic flow",
    "It goes with the flow",
    "Liquid rock",
    "Hot rocks",
    "Procter & Gamble soap",
    "Kind of lamp",
    "Hot issue?",
    "Volcanologist's concern",
    "Volcano output",
    "Hot issue?",
    "Proctor & Gamble soap",
    "Etna output",
    "Popocatepetl emission",
    "Kilauea flow",
    "Volcanic flow",
    "Mt. Etna flow",
    "Volcano flow",
    "Etna output",
    "Kind of flow",
    "Igneous flow",
    "Hot rock",
    "Pelee output",
    "Kind of flow"
  ],
  "agra": [
    "Taj Mahal locale",
    "City that's home to three UNESCO World Heritage Sites",
    "Taj Mahal city",
    "Taj Express destination city",
    "Asian city on the Yamuna River",
    "Indian tourist mecca",
    "Taj Mahal city",
    "The Taj City",
    "Taj Mahal city",
    "City that's home to the Taj Mahal",
    "Home of the Taj Mahal",
    "Home of the Taj Mahal",
    "Taj Mahal city",
    "City that inspired a palace in \"Aladdin\"",
    "Where Mumtaz Mahal is entombed",
    "Taj Mahal locale",
    "Taj Mahal city",
    "Onetime Mughal capital",
    "Taj Mahal city",
    "Famed Indian burial site",
    "Indian city whose name is an anagram of some Indian music",
    "Home to a famous mausoleum",
    "Where the Taj Mahotsav festival is held",
    "Much-photographed mausoleum site",
    "Tourist destination SSE of Delhi",
    "Site of Akbar the Great's tomb",
    "Uttar Pradesh city",
    "City in \"Slumdog Millionaire\"",
    "Indian drawer?",
    "Taj Mahal locale",
    "Site of the \"crown of palaces\"",
    "Taj Mahal city",
    "Indian tourist mecca",
    "Tourist city on the Yamuna",
    "Asian tourist magnet",
    "Site of a famed mausoleum",
    "Home of the Unesco World Heritage Site Fatehpur Sikri",
    "Site of one of the world's most famous onion domes",
    "Onetime capital of the Mughal Empire",
    "Site of the Taj Mahal",
    "City SSE of New Delhi",
    "Indian tourist destination",
    "Hindustan capital of old",
    "Taj Mahal city",
    "Noted mausoleum site",
    "Pearl Mosque home",
    "City that's home to three Unesco World Heritage Sites",
    "Noted Indian burial site",
    "Pearl Mosque city",
    "Indian tourist locale",
    "___ Fort (World Heritage Site in India)",
    "Locale in \"Slumdog Millionaire\"",
    "Home to a much-visited tomb",
    "Taj Mahal site",
    "Taj Mahal city",
    "Locale of a much-visited mausoleum",
    "Indian tourist city",
    "\"Slumdog Millionaire\" locale",
    "Taj Mahal site",
    "Indian tourist city",
    "Taj Mahal city",
    "City SE of New Delhi",
    "Indian tourist mecca",
    "Indian tourist locale",
    "Train stop between Delhi and Mumbai",
    "\"Slumdog Millionaire\" locale",
    "Indian fort locale",
    "Tourist city between Jaipur and Lucknow",
    "Site of a much-visited mausoleum",
    "Indian tourist city",
    "Taj Mahal site",
    "Red Fort city",
    "Indian tourist city",
    "Indian tourist mecca",
    "Taj Mahal's home",
    "Taj Mahal site",
    "Taj Mahal site",
    "Taj Mahal site",
    "Onetime capital of India",
    "Taj Mahal site",
    "Taj Mahal city",
    "Taj Mahal site",
    "Pearl Mosque locale",
    "Indian tourist stop",
    "Famous mausoleum site",
    "Taj Mahal site",
    "Indian tourist city",
    "Indian tourist site",
    "Taj Mahal site",
    "Taj Mahal locale",
    "Indian tourist city",
    "Indian tourist city",
    "Taj Mahal city",
    "Taj Mahal city",
    "Uttar Pradesh city",
    "Home of the Taj Mahal",
    "Center of Mughal architecture",
    "Taj Mahal site",
    "Indian tourist city",
    "Indian mecca",
    "Taj Mahal site",
    "Pearl Mosque locale",
    "Site of the Taj Mahal",
    "City on the Jumna",
    "Uttar Pradesh city",
    "Taj Mahal locale",
    "Taj Mahal site",
    "Uttar Pradesh tourist site",
    "Old Mogul capital",
    "Taj Mahal site",
    "Taj Mahal's city",
    "Site of Mumtaz Mahal's tomb",
    "Taj Mahal site",
    "Taj Mahal site",
    "Taj Mahal city",
    "Where Shah Jahan mourned",
    "City on the Yamuna River",
    "Indian tourist locale",
    "Old Mogul capital",
    "Taj Mahal home",
    "Indian tourist stop",
    "Taj Mahal site",
    "Taj Mahal city",
    "Taj Mahal site",
    "Pearl Mosque site",
    "Indian tourist site",
    "Indian tourist town",
    "City in Uttar Pradesh",
    "Uttar Pradesh city",
    "Where Shah Jahan is entombed",
    "Uttar Pradesh city",
    "Taj Mahal site",
    "Mogul capital of India",
    "Pearl Mosque locale",
    "Taj Mahal site",
    "Taj Mahal site",
    "Pearl Mosque site",
    "Indian tourist mecca",
    "Taj Mahal site",
    "Pearl Mosque site",
    "Taj Mahal site",
    "Taj Mahal site",
    "City SSE of Delhi",
    "Taj Mahal site",
    "Taj Mahal site",
    "Pearl Mosque city",
    "Mogul capital until 1658",
    "Taj Mahal city",
    "Taj Mahal site",
    "Taj Mahal site",
    "Shah Jahan's building site",
    "Taj Mahal site",
    "Pearl Mosque site",
    "Taj Mahal city"
  ],
  "garb": [
    "Attire",
    "Attire",
    "Duds",
    "Dress",
    "Attire",
    "Duds",
    "Worn things",
    "Apparel",
    "Duds",
    "Threads",
    "Attire",
    "Distinctive dress",
    "Raiment",
    "Apparel",
    "Stuff to wear",
    "Duds",
    "Clothing",
    "Shirts and skirts",
    "Threads",
    "Clothing",
    "Vestments, e.g.",
    "Vestments, e.g.",
    "Clothing",
    "Dress",
    "Habiliments",
    "Dress",
    "Duds",
    "Apparel",
    "Duds",
    "Habit",
    "Habiliments",
    "Uniforms",
    "Glad rags"
  ],
  "disk": [
    "Hockey puck, e.g.",
    "Plate, e.g.",
    "Shape of a U.F.O.",
    "Plate, e.g.",
    "Spine part",
    "U.F.O. shape",
    "It may slip in the back",
    "\"Slipped\" backbone part",
    "Poker chip, e.g.",
    "Tiddlywink, e.g.",
    "Part of the spine",
    "It's committed to memory",
    "Harrow blade",
    "Frisbee",
    "Floppy",
    "Frisbee",
    "Magnetic ___",
    "Drive insert",
    "Computer insert"
  ],
  "crow": [
    "Bird that caws",
    "Tribe of southern Montana",
    "Native American tribe of Montana",
    "Say \"Ta-da!,\" say",
    "Brag",
    "The bird in Hitchcock's \"The Birds\"",
    "Boast",
    "Boast",
    "Cock-a-doodle-doo",
    "Celebrate gloatingly",
    "Cornfield menace",
    "Eschew modesty",
    "Say \"Ta-da!,\" say",
    "Be exultant",
    "Exult",
    "Brag",
    "Vaunt",
    "Brag",
    "Cock-a-doodle-doo",
    "Say it loud, say it proud",
    "Montana Indian",
    "Siouan Indian",
    "Boast",
    "Montana tribe",
    "Cornfield bird",
    "Rooster's cry",
    "Unpleasant thing to eat",
    "Exult",
    "Exult",
    "Montana tribe",
    "Not be a polite winner",
    "Brag",
    "Meal for the humble?",
    "Cawer",
    "Bird in a cornfield",
    "Boast",
    "Montana tribe",
    "Exult (over)",
    "Be exultant",
    "Brag",
    "Brag",
    "Eschew humility",
    "Brag",
    "Boast",
    "Brag"
  ],
  "cosa": [
    "___ Nostra",
    "It's a thing in Mexico",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "Thing: Sp.",
    "Mexican thing",
    "___ Nostra (crime group)",
    "___ Nostra",
    "___ Nostra",
    "Thing, in Spain or Italy",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra",
    "___ Nostra"
  ],
  "pros": [
    "Amateurs no more",
    "Advantages",
    "Athletes first allowed in the Olympics in 1988",
    "Reasons to do something",
    "Reasons to do something",
    "\"Leave it to the ___\"",
    "Those in favor",
    "All those in favor",
    "Upsides",
    "... and advantages found in them",
    "They play for pay",
    "Reasons to do something",
    "Arguments for",
    "Whizzes",
    "___ and cons",
    "Crackerjacks",
    "Counterparts of amateurs",
    "Wizards",
    "Advantages",
    "Mavens",
    "Mavens",
    "Old hands",
    "Whizzes",
    "Experts",
    "Advantages",
    "All in favor",
    "Some arguments",
    "All in favor",
    "Mavens",
    "They're all for it",
    "Whizzes",
    "Old hands",
    "Specialists",
    "Debate list",
    "Advantages",
    "Some Olympians, nowadays",
    "Masters",
    "Major-leaguers",
    "N.F.L., e.g., with \"the\"",
    "Some Olympians, nowadays",
    "Whizzes",
    "Mavens",
    "Whizzes",
    "Crackerjacks",
    "Partner of cons",
    "N.F.L., e.g., with \"the\"",
    "Cons' cons",
    "Cons' counterpart",
    "Some debating points",
    "U.S. Open players, usually",
    "Debate arguments",
    "Specialists",
    "Cowboys and Indians, e.g.",
    "They get paid",
    "Arguments for",
    "Favorable factors",
    "Mavens"
  ],
  "wing": [
    "Part of a bird or museum",
    "Totally improvise",
    "Alternative to a drumstick",
    "Part of a party",
    "Turkey piece",
    "Turkey piece",
    "Either side of an airplane",
    "Improvise, with \"it\"",
    "Bit of bar food",
    "Something in a KFC bucket",
    "Building extension",
    "Buffalo ___",
    "Bird's \"arm\"",
    "Pegasus appendage",
    "Museum area",
    "What birds take",
    "Flier's flapper",
    "Addition",
    "Hockey position",
    "Building extension",
    "Rugby position",
    "Prayer partner?",
    "Wound, but not fatally",
    "Birds take this",
    "White House section",
    "Chicken order",
    "Faction",
    "Annex",
    "Hospital extension",
    "Flight controller?",
    "Hockey position",
    "Flight",
    "Fly",
    "Museum extension",
    "Museum add-on",
    "Hockey position",
    "Hockey position"
  ],
  "deft": [
    "Adroit",
    "Skillful",
    "Like a magician's hands",
    "Nimble-fingered",
    "Skillful",
    "Nimble",
    "Adroit",
    "Magicianlike",
    "Nimble-fingered",
    "Nimble-fingered",
    "Quick",
    "Artful",
    "Nimble",
    "Nimble-fingered",
    "Nimble-fingered",
    "Skillful",
    "Like a pickpocket's fingers",
    "Nimble",
    "Adroit",
    "Adept",
    "Magicianlike",
    "Magicianlike",
    "Skillful",
    "Skillful",
    "Dexterous",
    "Skilled",
    "Skillful",
    "Nimble",
    "Clever",
    "Skillful",
    "Nimble",
    "Nimble",
    "Nimble",
    "Adroit",
    "Handy"
  ],
  "miff": [
    "Peeve",
    "Put in a snit",
    "Make peeved",
    "Rub the wrong way",
    "Bother",
    "Tee off",
    "Tick off",
    "Tick off",
    "Put out",
    "Tee off",
    "Off tee",
    "Tee off",
    "Tick off",
    "Upset",
    "Tick off",
    "Offend",
    "Tee off",
    "Annoy",
    "Peeve",
    "Tick off",
    "Offend",
    "Upset",
    "Tick off"
  ],
  "bank": [
    "Establishment that's usually closed on Sundays",
    "Where one might go through withdrawal?",
    "Safe space?",
    "Save, in a way",
    "The buck stops here",
    "Place to find a pen and teller",
    "Carom",
    "Carom",
    "Certificate of deposit offerer",
    "It might hold your interest",
    "Statement issuer",
    "Plane maneuver",
    "Billiard cushion",
    "Possible drive-thru site",
    "C.D. seller",
    "Check point?",
    "With 5-Down, one seen in a cage",
    "Place of interest?",
    "Carom",
    "Chase Manhattan, e.g.",
    "Center of interest"
  ],
  "sits": [
    "Poses for a photo",
    "Doesn't go anywhere",
    "Plops down",
    "Takes a chair",
    "Wrests",
    "Poses",
    "Goes unused",
    "What a judge does for much of the day",
    "Gathers dust",
    "Protests peacefully, in a way",
    "Rides the bench",
    "Takes a chair",
    "Goes unused",
    "Models, in a way",
    "Uses a 49-Down",
    "Poses",
    "Serves on a panel",
    "Takes the bench",
    "Makes a lap",
    "Opposite of rises",
    "Poses (for)",
    "Keeps the nest warm",
    "Is unused",
    "Responds to a dog command",
    "Plops down",
    "Collects splinters, so to speak",
    "Uses a pew",
    "Tends tots",
    "Uses a stool",
    "Rests",
    "Uses a stool",
    "Models",
    "Parks in a pew",
    "Goes unused",
    "Does a job for parents, maybe",
    "Looks after little ones",
    "Isn't put to use",
    "Is on the bottom?",
    "Meets",
    "Is idle",
    "Plops down",
    "Doesn't move",
    "Poses",
    "Is in session",
    "Prepares to be shot",
    "Meets",
    "Stays idle",
    "Isn't off one's rocker",
    "Poses",
    "Takes a chair",
    "Rests",
    "Poses (for)",
    "Poses",
    "Goes unused",
    "Perches",
    "Perches",
    "Poses"
  ],
  "gaia": [
    "Earth goddess",
    "Mother to the Titans",
    "Greek goddess of the earth",
    "Greek goddess of Earth",
    "Earth goddess",
    "Earth goddess",
    "Greek goddess of the earth: Var.",
    "Earth as an organism",
    "Mother and wife of Uranus: Var.",
    "Greek earth goddess: Var.",
    "Daughter of Chaos",
    "Greek earth goddess: Var.",
    "Earth goddess: Var.",
    "Controversial James Lovelock book"
  ],
  "gina": [
    "Actress Gershon",
    "Rodriguez who starred in \"Jane the Virgin\"",
    "Gal pal of Dennis the Menace",
    "\"Jane the Virgin\" actress Rodriguez",
    "Actress Lollobrigida",
    "Actress and former mixed martial arts champion Carano",
    "Actress Carano of \"Fast & Furious 6,\" 2013",
    "Gershon of \"Rescue Me\"",
    "Martin's wife on the 1990s sitcom \"Martin\"",
    "Actress Lollobrigida",
    "Actress Gershon of \"Bound\"",
    "Gershon of \"Showgirls\"",
    "Actress Lollobrigida",
    "Actress Gershon",
    "Actress Lollobrigida",
    "Actress Lollobrigida",
    "Actress Gershon",
    "Actress Gershon",
    "Actress Gershon",
    "Actress Lollobrigida",
    "Gershon of film",
    "1962 Johnny Mathis hit",
    "Actress Gershon",
    "Actress Gershon",
    "\"Face/Off\" actress Gershon",
    "Lollobrigida of film",
    "Actress Gershon",
    "Actress Lollobrigida",
    "Actress Lollobrigida",
    "Actress Lollobrigida",
    "Actress Lollobrigida",
    "Ms. Lollobrigida"
  ],
  "play": [
    "Make music",
    "Off-hours activity",
    "With 62-Down, lie motionless",
    "With 25-Across, get as much approval from an audience as possible",
    "Work of Shakespeare",
    "Recorder button",
    "Work's opposite",
    "DVD remote button",
    "DVD button",
    "DVD remote button",
    "The \"thing,\" to Hamlet",
    "Opposite of work",
    "Ump's call after \"Time!\"",
    "Gambit",
    "iTunes button",
    "___ on words",
    "Frolic",
    "Be in the game",
    "School recess",
    "With 35-Down, much-anticipated cry every April",
    "Bit of theatrics",
    "Latitude",
    "Remote button",
    "With 39-Across, pretend",
    "Be in the game or in the band",
    "Leeway",
    "Game move",
    "See 12-Down",
    "Pass or run",
    "Thespian production",
    "Something to stage",
    "See 32-Across",
    "DVD menu option",
    "Go (along)",
    "Wiggle room",
    "With 79-Down, something to do in the yard",
    "Maneuvering room",
    "VCR button",
    "Be in the game",
    "Latitude",
    "Flexibility",
    "Cavort",
    "VCR button",
    "QB's call"
  ],
  "poof": [
    "[And like magic ... it's gone!]",
    "[And ... it's gone!]",
    "\"And ... it's gone!\"",
    "[Just like that, it's gone!]",
    "[Like magic!]",
    "[It's gone!]",
    "[And it's gone!]",
    "[It's gone!]",
    "[Just like that!]",
    "Magician's word",
    "Magician's word",
    "[Gone ... instantly!]",
    "[It's gone!]",
    "Sound accompanying a cloud of smoke",
    "Disappearing word",
    "Magician's sound effect",
    "Magical sound effect",
    "[It's gone!]",
    "[It's gone!]",
    "\"Now you see it, now you don't!\"",
    "Magician's word",
    "Magician's sound effect"
  ],
  "elec": [
    "Wind farm output: Abbr.",
    "The \"E\" in PG&E: Abbr.",
    "Certain utility: Abbr.",
    "\"Juice\": Abbr.",
    "Standard util.",
    "Tesla power source: Abbr.",
    "Wire transfer?: Abbr.",
    "Like some fans and fences: Abbr.",
    "Monthly bill: Abbr.",
    "Like many stoves: Abbr.",
    "Kind of engr.",
    "G.E. component: Abbr.",
    "Physics class subj.",
    "A.C. or D.C.",
    "The \"E\" of G.E.: Abbr.",
    "Monopoly util.",
    "Monopoly util.",
    "A util.",
    "Util. bill",
    "Like many appliances: Abbr.",
    "T.V.A. output",
    "Basic util.",
    "Monthly bill: Abbr.",
    "It's measured in amperes: Abbr.",
    "It may be D.C.",
    "Engineering subj.",
    "\"Juice\": Abbr.",
    "Util. bill",
    "Util. bill",
    "Monthly util. bill",
    "Kind of engr.",
    "A util.",
    "Basic util.",
    "Physics class subj.",
    "Kind of engr.",
    "It's A.C. or D.C.",
    "Household power: Abbr.",
    "What a conductor conducts: Abbr.",
    "Outlet output: Abbr.",
    "A util.",
    "Util. bill",
    "A.C., e.g.",
    "Utility supply: Abbr.",
    "A.C. or D.C., e.g.",
    "Major util.",
    "The \"E\" in P.G.&E.: Abbr.",
    "Wire transfer?: Abbr.",
    "Gas's partner: Abbr.",
    "Utility co. output",
    "T.V.A. concern: Abbr.",
    "Basic util.",
    "Juice: Abbr.",
    "Kind of engr.",
    "T.V.A. product",
    "Con Ed power: Abbr.",
    "It's sent by wire: Abbr.",
    "Certain util.",
    "Utility abbr.",
    "Kind of engr.",
    "T.V.A. output",
    "Certain util.",
    "A util.",
    "Monthly bill: Abbr.",
    "Util. bill",
    "Engr.'s specialty",
    "T.V.A. output",
    "A.C./D.C. power",
    "Util. power",
    "T.V.A. output: Abbr.",
    "Kind of short, for short",
    "T.V.A. product: Abbr.",
    "Volta's subj.",
    "It gives you a charge: Abbr.",
    "Household power: Abbr.",
    "Part of G E.: Abbr.",
    "Part of G.E.: Abbr."
  ],
  "reek": [
    "Stink",
    "Really smell",
    "Smell really bad",
    "What junkyards do",
    "Stink",
    "Stink to high heaven",
    "Need a bath badly",
    "Fetor",
    "Stink to high heaven",
    "Be offensive, in a way",
    "Need a bath badly",
    "Make a big stink",
    "Give off, with \"of\"",
    "Stink",
    "Need a bath badly",
    "Be mephitic",
    "Make a big stink",
    "Stink to high heaven",
    "Raise a big stink?",
    "Stink",
    "Be a stinker",
    "Need a bath badly",
    "Stink up the joint",
    "Smell horrible",
    "Stink up the joint",
    "Smell",
    "Need a bath badly",
    "Stink",
    "Noisome smell",
    "Be offensive, in a way",
    "Stink",
    "Need a bath badly",
    "Stink to high heaven",
    "Really need to bathe",
    "Raise a stink",
    "Stink",
    "Intolerable smell",
    "Effluvium",
    "Smell horrible",
    "Need a bath badly",
    "Smell something fierce",
    "Smell strongly",
    "Stink to high heaven",
    "Smell bad",
    "Really smell",
    "Cause a stench",
    "Raise a stink",
    "Smell",
    "Stench",
    "Offend olfactorily",
    "Stench",
    "Stink",
    "Smell",
    "Smell to high heaven",
    "Assault the nostrils",
    "Stink",
    "Need a bath",
    "Need Ban"
  ],
  "turk": [
    "Seljuk Empire citizen",
    "Neighbor of an Armenian",
    "Young ___",
    "Ottoman",
    "Istanbul resident",
    "Ankara native",
    "Izmir native",
    "Neighbor of a Georgian",
    "*Relative of an Azerbaijani",
    "Neighbor of an Iraqi",
    "Ankara native",
    "Istanbul resident",
    "Istanbul native",
    "Istanbul native",
    "Izmir inhabitant",
    "Izmir resident",
    "Istanbul native"
  ],
  "beak": [
    "Bill",
    "Proboscis",
    "Schnozz",
    "Bill",
    "A pelican has a big one",
    "Schnozz",
    "Cardinal feature",
    "Bill",
    "Schnozz",
    "A toucan has a colorful one",
    "Bird bill",
    "Bill",
    "Kite part",
    "Bill",
    "Rail part",
    "Bill",
    "Schnoz",
    "Nut cracker, perhaps",
    "Schnozzola",
    "Bill",
    "Kite feature",
    "Schnozz",
    "Bird's-eye view?",
    "Kite part",
    "Preener, perhaps",
    "Bill",
    "Quite a nose",
    "Schnozzola",
    "Honker"
  ],
  "pegs": [
    "Cello quartet",
    "Categorizes",
    "Gets out in dodgeball, say",
    "Square ones won't fit into round holes",
    "Cribbage scorekeepers",
    "Jumping pieces in a classic wooden puzzle",
    "Classifies (as)",
    "Croquet needs",
    "Cribbage or croquet needs",
    "Violin tuners",
    "Things to hang coats on",
    "Places to hang hats",
    "Mastermind game pieces",
    "Cribbage markers",
    "Viola parts",
    "Violin quartet",
    "Cribbage score keepers",
    "Identifies",
    "Coatrack projections",
    "Baseball throws",
    "Identifies",
    "Things to hang hats on",
    "Ringtoss equipment",
    "Hard throws to first base, say",
    "Coatrack parts",
    "Cribbage pieces",
    "Cribbage markers",
    "Tent holders",
    "Coatroom features",
    "Hard baseball throws",
    "Cribbage needs",
    "Cribbage equipment",
    "Good baseball throws",
    "Classifies",
    "Gamepieces",
    "Game pieces",
    "Identifies",
    "Places for coats",
    "Throws",
    "Cribbage counters",
    "Outfielders' throws"
  ],
  "clod": [
    "Hunk",
    "Buffoon",
    "Oaf",
    "Lump of soil",
    "Dummkopf",
    "Dirt ball",
    "Lump",
    "Dirt clump",
    "Chucklehead",
    "Clumsy sort",
    "Lummox",
    "Lamebrain",
    "Oaf",
    "Lug",
    "Bonehead",
    "Dolt",
    "Dummkopf",
    "Dirt clump",
    "Lunkhead",
    "Blockhead",
    "Oaf",
    "Chowderhead",
    "Chump",
    "Blockhead",
    "Knucklehead",
    "Ignoramus",
    "Dullard",
    "Simpleton",
    "Dense one",
    "Lump of dirt",
    "Dummkopf",
    "Knucklehead",
    "Doofus",
    "Oaf",
    "Doofus",
    "Dolt",
    "Dolt",
    "Oaf",
    "Chunk or clunk",
    "Lunkhead",
    "Blockhead",
    "Knucklehead",
    "Lummox",
    "Lunkhead",
    "Lamebrain",
    "Dolt",
    "Bumbler"
  ],
  "ttop": [
    "Classic moonroof alternative",
    "Stingray feature, once",
    "Sporty option",
    "Sporty car option",
    "Sporty roof feature",
    "Sporty car roof",
    "Sporty car option",
    "'Vette option",
    "Corvette Stingray feature",
    "Sporty auto feature",
    "Sunroof alternative",
    "Roof style of some Corvettes",
    "'Vette option",
    "The Grand Prix used to have one",
    "Pontiac Trans Am option",
    "Corvette feature",
    "Old Trans Am feature",
    "Corvette feature",
    "Sporty car feature",
    "Sporty car roof",
    "Onetime Mustang option",
    "'Vette choice",
    "Sporty option",
    "Relative of a convertible",
    "Muscle car roof option",
    "'Vette roof option",
    "'Vette roof option",
    "Roof with removable panels",
    "Datsun 280ZX option",
    "Sports car option",
    "Mustang option",
    "'Vette option",
    "Muscle car feature",
    "*'Vette option",
    "Sunroof or moonroof alternative",
    "'Vette roof option",
    "Sports car option",
    "Auto roof option",
    "Sporty car feature",
    "Feature of many a sports car",
    "Sporty car roof option",
    "'Vette roof option",
    "Sporty auto roof",
    "Sporty car roof",
    "Sporty car feature",
    "Sunroof alternative",
    "Moonroof alternative",
    "Sporty car addition",
    "Convertible alternative",
    "'Vette design detail",
    "'Vette option",
    "'Vette option",
    "Sporty car feature",
    "Feature of many a Corvette",
    "Sporty sunroof"
  ],
  "ipos": [
    "Corp. debuts",
    "Uber and Lyft had theirs in 2019, for short",
    "Market announcements, for short",
    "W.S.J. announcements",
    "Major N.Y.S.E. events",
    "Some W.S.J. topics",
    "They begin trading, for short",
    "Corp. milestones",
    "Wall St. debuts",
    "Big steps for young companies, for short",
    "Wall St. debuts",
    "Some investment opportunities, in brief",
    "N.Y.S.E. debuts",
    "Stock exchange debuts, briefly",
    "Wall St. Journal listings",
    "Wall St. Journal news subjects",
    "Debuts on Wall St.",
    "Debuts on the N.Y.S.E.",
    "Some Morgan Stanley announcements, for short",
    "CNBC news topics, for short",
    "Jobs for some underwriters, for short",
    "N.Y.S.E. debuts",
    "Wall St. debuts",
    "Corp. capital raisers",
    "Wall St. intros",
    "N.Y.S.E. debuts",
    "Some brokerage biz",
    "Wall St. opportunities",
    "New issuances, for short",
    "They're new on Wall St.",
    "Financial page listings, briefly"
  ],
  "mien": [
    "Comportment",
    "Countenance",
    "Comportment",
    "Appearance",
    "Personal manner",
    "Bearing",
    "Appearance",
    "Countenance",
    "Carriage",
    "Countenance",
    "Appearance",
    "Appearance",
    "Carriage",
    "Appearance",
    "Look",
    "Air",
    "Air",
    "Way of comporting oneself",
    "Air",
    "Appearance",
    "Bearing",
    "Bearing",
    "Appearance",
    "Bearing",
    "Air",
    "Demeanor",
    "Look",
    "Air",
    "Look",
    "Look",
    "Bearing",
    "Bearing",
    "Appearance",
    "Demeanor",
    "Bearing",
    "Comportment",
    "Deportment",
    "Comportment",
    "Demeanor",
    "Personal air",
    "Bearing",
    "Bearing",
    "Appearance",
    "Air",
    "Carriage",
    "Manner",
    "Bearing",
    "Appearance",
    "Manner",
    "Bearing",
    "Demeanor",
    "Demeanor",
    "Bearing",
    "Bearing",
    "Appearance",
    "Carriage",
    "Appearance",
    "Bearing"
  ],
  "raul": [
    "One of the Castros",
    "Fidel's brother and successor in Cuba",
    "One of the Castros",
    "___ Julia, actor who played Gomez Addams",
    "Fidel's brother",
    "Julia of Hollywood",
    "Leader Castro",
    "Fidel Castro's brother and successor",
    "Brother of Fidel",
    "1980s Argentine president Alfonsin",
    "Fidel Castro's brother",
    "One of the Castros",
    "\"Tempest\" Golden Globe nominee Julia",
    "Brother of Fidel",
    "1980s Argentine president ___ Alfonsin",
    "Fidel Castro's brother",
    "Fidel Castro's brother",
    "1970s tennis star Ramirez",
    "Fidel Castro's brother",
    "Actor Julia",
    "One of the Castros",
    "One of the Castros",
    "1980's Argentine president ___ Alfonsin",
    "Brother of Fidel",
    "Julia on screen",
    "With 43-Down, a president's brother",
    "Outfielder Mondesi",
    "Outfielder Mondesi",
    "Actor Julia",
    "Julia of \"The Addams Family\"",
    "Actor Julia",
    "Julia on screen",
    "Brother of Fidel",
    "Tennis's Ramirez",
    "Tennis player Ramirez",
    "Actor Julia",
    "Actor Julia"
  ],
  "hoss": [
    "Farm animal, in farm-speak",
    "Equine animal, in rural dialect",
    "Nickname for Eric Cartwright on \"Bonanza\"",
    "Pardner's mount",
    "Carriage puller, in rural dialect",
    "\"Bonanza\" brother",
    "Little Joe's half brother of old TV",
    "\"Bonanza\" brother",
    "\"Bonanza\" role",
    "\"Bonanza\" brother",
    "Pardner's mount",
    "Brother of Little Joe on '60s TV",
    "\"Bonanza\" brother",
    "\"Bonanza\" brother",
    "Pardner's mount",
    "\"Bonanza\" son",
    "Pardner's mount",
    "Ranch animal, informally",
    "\"Bonanza\" brother",
    "\"Bonanza\" brother",
    "Dan Blocker role on 60's TV",
    "\"Bonanza\" brother",
    "\"Bonanza\" brother",
    "Pardner's mount",
    "\"Bonanza\" brother",
    "One of the Cartwright boys",
    "The middle son, on 60's TV",
    "\"Bonanza\" role",
    "Brother of Little Joe",
    "Dan Blocker TV role",
    "One of the Cartwrights",
    "Ponderosa name"
  ],
  "bibs": [
    "What babies and marathoners both use",
    "Handouts to lobster eaters",
    "Front covers",
    "Protective wear for lobster eaters",
    "Things often seen near lobsters",
    "Restaurant attachments?",
    "*Things to drool over?",
    "Spareribs eaters' wear",
    "Places for double dribbles?",
    "Protective wear for lobster eaters",
    "Drool catchers",
    "Stain blockers",
    "Lobster house supplies",
    "Strained pea catchers",
    "Lobster eaters' needs",
    "Lobster eaters' accessories"
  ],
  "yews": [
    "Ornamental trees",
    "Trees symbolizing death in Celtic culture",
    "Trees used for archery bows",
    "Trees with red berrylike fruit",
    "Trees whose name sounds like letters of the alphabet",
    "Trees used for making wands",
    "Trees with red berrylike fruit",
    "Topiary trees",
    "Trees for making longbows",
    "Some conifers",
    "Bearers of bright red arils",
    "English churchyard trees",
    "Composition of some hedgerows",
    "Trees yielding archery bow wood",
    "Coniferous trees",
    "Some evergreens",
    "Certain conifers",
    "Grove in many an English churchyard",
    "Hedgerow trees",
    "Trees used to make archery bows",
    "Evergreen trees",
    "They have boughs for bows",
    "Certain evergreens",
    "Fir trees",
    "Some archery bows",
    "Old World evergreens",
    "English churchyard features",
    "Evergreens",
    "Woods used in cabinetmaking",
    "Evergreens"
  ],
  "nigh": [
    "\"The time is ___\"",
    "Close",
    "Practically",
    "Virtually",
    "Just about",
    "Near",
    "Imminent",
    "Practically",
    "Approaching",
    "Close by, in poems",
    "Close by",
    "Near",
    "Close by",
    "Near",
    "\"And stay by my cradle till morning is ___\"",
    "Close by, in poetry",
    "Almost",
    "Approaching",
    "Close at hand",
    "Anear"
  ],
  "cree": [
    "One of the official languages of Canada's Northwest Territories",
    "Native Canadian",
    "First Nations group",
    "First Nations tribe",
    "Some Montanans",
    "One of the First Nations of Canada",
    "Language from which \"Saskatchewan\" comes",
    "One of the 11 official languages of Canada's Northwest Territories",
    "Saskatchewan native",
    "Some Canadian natives",
    "Canadian Plains tribe",
    "Northern Montana tribe",
    "Manitoba tribe",
    "Plains dwellers",
    "Saskatchewan native",
    "Plains native",
    "One of the Northwest Territories' official languages",
    "Canadian native",
    "First Nations tribe",
    "Tribe of the Canadian Plains",
    "Canadian Plains tribe",
    "Native Canadian",
    "Hudson Bay native",
    "Northern Plains people",
    "Canadian Indian",
    "Ontario tribe",
    "Algonquian tribe",
    "Manitoba tribe",
    "First Nations tribe",
    "Dweller in a 59-Across",
    "Manitoba native",
    "Canadian natives",
    "Micmac relative",
    "Canadian native",
    "Algonquian tongue",
    "Native Canadian",
    "Canadian native",
    "James Bay native",
    "Saskatchewan Indian",
    "Indian of the northern Plains",
    "Language closely related to Montagnais",
    "Native Canadian",
    "Plains Indian",
    "Algonquian Indian",
    "Native Canadian",
    "Manitoba native",
    "Language from which \"Saskatchewan\" is derived",
    "Canadian tribe",
    "Onetime buffalo hunter",
    "Canadian tribe",
    "Western Canada native",
    "Canadian native",
    "Chief Big Bear, for one",
    "Saskatchewan tribe",
    "Canadian Indian",
    "Algonquian language",
    "Tribe in Manitoba",
    "Algonquian language",
    "Language from which \"pemmican\" is derived",
    "Ontario natives",
    "Early buffalo hunters",
    "Indigenous Canadian",
    "Tribe in Manitoba",
    "Canadian Indian",
    "Chief Big Bear, e.g.",
    "Canadian prairie tribe",
    "Manitoba Indian",
    "Buffalo hunter",
    "Plains Indian",
    "Manitoba native",
    "Ontario tribe",
    "Old buffalo hunter",
    "Prairie Indian",
    "Indigene of the Great Lakes area",
    "Language akin to Ojibwa",
    "Manitoba native",
    "Montana native",
    "Ontario native",
    "Algonquian Indian",
    "Saskatchewan native",
    "Saskatchewan native",
    "Ontario native",
    "Ontario tribe",
    "Saskatchewan tribe",
    "Manitoba Indian"
  ],
  "dele": [
    "\"Drop it,\" editorially",
    "Strike at a newspaper office?",
    "Strikeout mark",
    "Cut, editorially",
    "Edit out",
    "Get out of the line",
    "Remove, to an editor",
    "\"Remove,\" to a typesetter",
    "Edit out",
    "Editorial strike-out",
    "Edit out",
    "Take out",
    "Take out",
    "Strike out",
    "Get out of a sentence?",
    "Take out",
    "Strike",
    "Take out",
    "Cut",
    "Word often written in red",
    "Strike out",
    "Remove, as text",
    "Proofer's mark",
    "Drop",
    "Proofreader's mark",
    "Strike mark",
    "Takeout sign?",
    "Strike out",
    "Get out",
    "Strike out",
    "Strike out",
    "Remove, as text",
    "Take-out order?",
    "Strike out",
    "Strike out, to a typesetter",
    "Editor's strikeout",
    "Proofer's mark",
    "Remove, to a typesetter",
    "Strike from a manuscript",
    "Take out",
    "Stet's opposite",
    "Expunge, as text",
    "Expunge",
    "Typographer's strike",
    "Take out",
    "Take-out order?",
    "Take-out sign",
    "Take-out order?",
    "Expunge",
    "Strike",
    "Strike out, as text",
    "Take out of context?",
    "Take out",
    "Strike",
    "Remove from a manuscript",
    "Red-pencil",
    "Take out",
    "Drop",
    "Strike out",
    "Strike out",
    "Strike out",
    "Remove, in editing",
    "Take out",
    "Excise, as text",
    "Take a letter?",
    "Stet's opposite",
    "Take out",
    "Proofreader's mark",
    "Take out",
    "Takeout sign",
    "Strike out, as copy",
    "Proofreader's mark",
    "Take out",
    "Excise",
    "Take out of print",
    "Marginal mark"
  ],
  "hurl": [
    "Chuck",
    "Throw with power",
    "Let fly",
    "Let fly",
    "Pitch",
    "Fling",
    "Pitch",
    "Throw",
    "Throe",
    "Toss",
    "Project with a lot of momentum",
    "Cast",
    "Cast",
    "Throw",
    "Cast",
    "Pitch",
    "Chuck",
    "Pitch",
    "Throw",
    "Cast",
    "Toss",
    "Fling",
    "Cast",
    "Pitch",
    "Pitch",
    "Sling",
    "Sling",
    "Sling",
    "Throw",
    "Be a pitcher",
    "Catapult",
    "Pitch",
    "Sling",
    "Throw hard",
    "Emulate Ryan",
    "Make a pitch",
    "Catapult",
    "Pitch",
    "Throw",
    "Pitch",
    "Pitch"
  ],
  "tobe": [
    "See 30-Across",
    { "formula": "-elect", "result": { "error": "#NAME?" } },
    "Words before and after \"or not\" in a Shakespeare quote",
    "With 39- and 40-Across, classic Shakespearean question phonetically suggested by 17-, 23-, 47- and 59-Across",
    "See 38-Across",
    "Choice A for Hamlet",
    "Fit ___ tied",
    "\"Hamlet\" soliloquy starter",
    "\"___ honest ...\"",
    "Father-___",
    "Meant ___",
    "Words before and after \"or not\"",
    "\"Hamlet\" soliloquy starter",
    "\"That is ___ expected\"",
    "One option for Hamlet",
    "Words repeated in \"___ or not ___\"",
    "Words before and after \"or not\"",
    "\"Hamlet\" soliloquy starter",
    "\"___ honest with you ...\"",
    "Repeated words in a famous soliloquy",
    "Option for Hamlet",
    "Soliloquy starter",
    "Mother-___",
    "Soliloquy starter",
    "Soliloquy start",
    "Soliloquy start",
    "One of Hamlet's options",
    "Start of a Hamlet soliloquy",
    "Alternative to 24 Down",
    "Start of a classic question",
    "Option for Hamlet"
  ],
  "alig": [
    "Sacha Baron Cohen character",
    "Prank interviewer who referred to Buzz Aldrin as \"Buzz Lightyear\"",
    "Longtime Sacha Baron Cohen persona",
    "Longtime Sacha Baron Cohen character",
    "\"Interviewer\" who asked Buzz Aldrin whether people on the moon were friendly",
    "Sacha Baron Cohen persona",
    "TV character with the catchphrase \"Booyakasha!\"",
    "Sacha Baron Cohen character",
    "Sacha Baron Cohen persona",
    "Character with the tagline \"Booyakasha!\"",
    "TV persona giving prank interviews"
  ],
  "erle": [
    "Writer ___ Stanley Gardner",
    "Mystery writer ___ Stanley Gardner",
    "First name in court fiction",
    "First name in courtroom fiction",
    "Halliburton of the Halliburton Company",
    "___ Stanley Gardner of detective fiction",
    "Writer ___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "Director ___ C. Kenton",
    "Man's name that comes from an English noble",
    "Film director ___ C. Kenton",
    "First name in mysteries",
    "First name in courtroom fiction",
    "Gardner who wrote \"The Case of the Negligent Nymph\"",
    "Writer ___ Stanley Gardner",
    "First name in court fiction",
    "Creator of the lawyer Perry",
    "Director ___ C. Kenton",
    "Writer ___ Stanley Gardner",
    "Film director ___ C. Kenton",
    "Detective fiction writer ___ Stanley Gardner",
    "First name in mystery",
    "First name in detective fiction",
    "___ Stanley Gardner (Perry Mason's creator)",
    "First name in mystery",
    "Novelist ___ Stanley Gardner",
    "First name in mysteries",
    "Writer ___ Stanley Gardner",
    "\"House of Frankenstein\" director ___ C. Kenton",
    "Writer ___ Stanley Gardner",
    "Filmmaker ___ C. Kenton",
    "Man's name that sounds noble",
    "___ Stanley Gardner",
    "___ Stanley Gardner",
    "First name in mystery",
    "First name in mysteries",
    "Oilman ___ P. Halliburton",
    "Writer ___ Stanley Gardner",
    "Director ___ C. Kenton",
    "Mystery writer ___ Stanley Gardner",
    "First name in mysteries",
    "\"Out of the Silence\" novelist Cox",
    "Contemporary of Ngaio",
    "Gardner of mystery",
    "___ Stanley Gardner",
    "\"House of Dracula\" director ___ C. Kenton",
    "Writer ___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "\"Phineas Finn\" character Barrington ___",
    "___ Stanley Gardner",
    "Eponymous oilman Halliburton",
    "___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "First name in court fiction",
    "First name in mysteries",
    "Writer ___ Stanley Gardner",
    "___ P. Halliburton, founder of the Halliburton company",
    "Perry Mason's creator ___ Stanley Gardner",
    "First name in courtroom drama",
    "Writer ___ Stanley Gardner",
    "Author ___ Stanley Gardner",
    "First name in mystery writing",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner of mysteries",
    "Mystery writer ___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "\"House of Frankenstein\" director ___ C. Kenton",
    "First name in courtroom drama",
    "___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "Director ___ C. Kenton",
    "___ Stanley Gardner",
    "Creator of Perry and Della",
    "First name in court fiction",
    "Film director ___ C. Kenton",
    "First name in mystery",
    "Creator of the secretary Della",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "First name in courtroom fiction",
    "First name in courtroom fiction",
    "Perry's creator",
    "First name in mystery",
    "___ Stanley Gardner",
    "Peer of Agatha",
    "___ Stanley Gardner",
    "Character in Trollope's \"Phineas Finn\"",
    "First name in mysteries",
    "Colleague of Agatha",
    "___ Stanley Gardner",
    "Creator of the secretary Della",
    "___ Stanley Gardner",
    "Peer of Dashiell",
    "First name in mysteries",
    "Writer ___ Stanley Gardner",
    "Halliburton of the Halliburton Company",
    "Writer ___ Stanley Gardner",
    "First name in mysteries",
    "___ Stanley Gardner",
    "First name in mysteries",
    "Writer ___ Stanley Gardner",
    "Agatha contemporary",
    "___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "Film director ___ C. Kenton",
    "Della's creator",
    "Ellery contemporary",
    "___ Stanley Gardner",
    "\"House of Dracula\" director ___ C. Kenton",
    "Della's creator",
    "___ Stanley Gardner",
    "First name in detective fiction",
    "1930's-40's film director ___ C. Kenton",
    "Mystery writer Gardner",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "Colleague of Dashiell",
    "Writer ___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "Creator of Perry and Della",
    "___ Stanley Gardner",
    "___ Stanley Gardner",
    "Colleague of Dashiell",
    "Author ___ Stanley Gardner",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "First name in mysteries",
    "Writer ___ Stanley Gardner",
    "Perry's progenitor",
    "First name in mystery writing",
    "___ Stanley Gardner",
    "First name in mystery writing",
    "\"House of Frankenstein\" director ___ C. Kenton",
    "Writer ___ Stanley Gardner",
    "Contemporary of Agatha",
    "First name in mysteries",
    "Contemporary of Dashiell",
    "Writer ___ Stanley Gardner",
    "___ Stanley Gardner",
    "First name in detective fiction",
    "Perry's creator",
    "___ Stanley Gardner",
    "Author ___ Stanley Gardner",
    "___ Stanley Gardner",
    "Director Kenton",
    "Name on over 75 whodunits",
    "___ Stanley Gardner",
    "Agatha contemporary",
    "First name in mystery",
    "First name in mystery",
    "Perry's creator",
    "Della's creator",
    "Perry creator",
    "Lawyer/writer Gardner",
    "Author Gardner",
    "Gardner a k a A. A. Fair",
    "First name in mystery",
    "Contemporary of Agatha",
    "First name in mysteries",
    "___Stanley Gardner",
    "Perry's creator",
    "First name in courtroom drama",
    "___ Stanley Gardner",
    "Perry's creator",
    "First name in mysteries",
    "___ Stanley Gardner",
    "First name in whodunits",
    "___ Stanley Gardner",
    "Gardner of mysteries",
    "First name in mysteries",
    "Gardner of mysteries",
    "First name in mysteries",
    "___ Stanley Gardner",
    "First name in mysteries",
    "First name of mysteries",
    "Perry's creator",
    "___ Stanley Gardner",
    "Della's creator",
    "First name in mysteries",
    "First name in mysteries",
    "First name in mystery writing",
    "A Gardner",
    "Gardner of mysteries",
    "Perry's creator",
    "First name in mysteries",
    "Perry's creator",
    "First name in detective fiction"
  ],
  "damn": [
    "\"Nooooo!\"",
    "\"Phooey!,\" only stronger",
    "\"Nuts!\"",
    "Execrate",
    "\"___ Yankees\"",
    "\"Shucks!,\" only stronger",
    "\"___ the torpedoes ...!\"",
    "Very, informally",
    "\"Phooey!,\" only stronger",
    "Curse",
    "Send to hell",
    "Cry after \"hot\"",
    "\"Aw, hell!\"",
    "\"___ Yankees\"",
    "Banish to Hades",
    "\"Lookin' good!\"",
    "\"Nuts!\"",
    "\"Oh, hell!\"",
    "\"___ Yankees\"",
    "\"Nuts!\"",
    "\"Aw, hell!\"",
    "Accurse",
    "Butler's expletive",
    "Accurse",
    "Confounded",
    "\"___ straight!\"",
    "Censure",
    "Curse",
    "\"Confound it!\"",
    "Accurse",
    "Censure",
    "\"___ Yankees\"",
    "Angry outburst",
    "More than \"Phooey!\"",
    "Cousin of \"Shucks!\"",
    "Cousin of \"Phooey!\"",
    "Dramatic word for the Yankees",
    "Denounce in no uncertain terms",
    "Not-so-mild oath",
    "Butler's last word",
    "Butler's expletive",
    "Opposite of bless",
    "More than \"phooey!\"",
    "Confounded"
  ],
  "cnbc": [
    "\"Fast Money\" channel",
    "\"Fast Money\" channel",
    "\"Jay Leno's Garage\" channel",
    "\"Closing Bell\" channel",
    "\"Mad Money\" network",
    "News network with a stock ticker",
    "Stock watcher's network",
    "\"American Greed\" channel",
    "Network for market monitors",
    "Network for business news",
    "\"Closing Bell\" channel",
    "Lawrence Kudlow's network",
    "Jim Cramer's network",
    "\"The Kudlow Report\" airer",
    "What may give you the business?",
    "\"Mad Money\" airer",
    "\"Squawk Box\" airer",
    "\"Mad Money\" network",
    "\"Squawk on the Street\" airer",
    "\"Fast Money\" network",
    "\"Mad Money\" network",
    "\"Power Lunch\" channel",
    "Channel with the show \"Closing Bell\"",
    "Network that covers the N.Y.S.E.",
    "Business TV channel",
    "\"Closing Bell\" airer",
    "Tim Russert venue",
    "\"Kudlow & Cramer\" channel",
    "Investor's channel"
  ],
  "iles": [
    "A group of them is called un archipel",
    "Parts of Polynesie francaise",
    "Bits of terre in la mer",
    "Les Aleoutiennes, e.g.",
    "Composition of Indonesie",
    "Specks in la mer",
    "Dots in la mer",
    "Corsica et d'autres",
    "Spots in la Seine",
    "Parts of Polynesie",
    "Dots in la mer",
    "Saint-Pierre et Miquelon, e.g.",
    "Martinique et Guadeloupe",
    "___ de la Societe",
    "Makeup of les Caraibes",
    "Mille ___ (part of Quebec with a rhyming name)",
    "Parts of un archipel",
    "Parts of a French archipelago",
    "Composition of Polynesie",
    "Dots in la mer",
    "Reunion and others",
    "Wallis and Futuna",
    "Bits of land in la Seine",
    "France's ___ d'Hyeres",
    "Martinique et Corsica",
    "What lies in the Seine",
    "Saint Barthelemy et d'autres",
    "___ de la Societe",
    "Specks in the Seine",
    "French Polynesia constituents",
    "Isolated French places",
    "Composition of some French chains",
    "Martinique et Guadeloupe",
    "Parts of la Polynesie",
    "Components of un archipel",
    "Seine sights",
    "Bits of land in la Mediterranee",
    "Parts of l'Oceanie",
    "Martinique et Guadeloupe",
    "Seine sights",
    "Quebec's ___ de la Madeleine",
    "Guadeloupe and Martinique",
    "Guadeloupe and others",
    "Parts of Polynesie",
    "St. Pierre et Miquelon",
    "Martinique et Saint Croix",
    "Features of geographie",
    "Reunion and others",
    "France's ___ des Saintes",
    "L'eau lands?",
    "___ de la Societe",
    "Tahiti and others",
    "St. Pierre and Miquelon",
    "___ Normandes (Channel Islands)",
    "Mer sights",
    "Nouvelle Caledonie and others",
    "France's ___ de Glenans",
    "Spots in the Seine, for instance",
    "Seine sights",
    "Antilles, e.g.",
    "___de la Societe",
    "Montreal, Tahiti, etc.",
    "___du Salut",
    "France's ___ de Glenans",
    "Little pieces of France",
    "Aits in Arles",
    "Martinique and others"
  ],
  "rhye": ["\"Seven Seas of ___\" (Queen's first radio hit)"],
  "gala": [
    "Big do",
    "Soiree, say",
    "Soiree, say",
    "Black-tie charity event, maybe",
    "Black-tie affair, maybe",
    "Big to-do",
    "Luxurious affair",
    "Big bash",
    "Lavish soiree",
    "Black-tie affair",
    "Fancy bash",
    "Occasion to dress up",
    "Bash",
    "Black-tie affair",
    "Big do",
    "Fancy affair",
    "Black-tie event",
    "Ritzy shindig",
    "Big do",
    "Black-tie party",
    "Fancy party",
    "Grand party",
    "Big do",
    "Kennedy Center happening",
    "Festive occasion",
    "Big ball",
    "Grand party",
    "Many an opening event",
    "Pricey event",
    "Grand party",
    "Festive",
    "Kind of ball",
    "Many a party at Kennedy Center",
    "Bash",
    "Black-tie affair",
    "Certain ballroom event",
    "Black-tie affair",
    "Certain fund-raiser",
    "Do for a V.I.P.?",
    "Big bash",
    "Jacket-and-tie affair",
    "Big bash",
    "Blowout",
    "Big do",
    "The Oscars, e.g.",
    "Big bash",
    "Showy",
    "Many a charity event",
    "Inaugural ball, e.g.",
    "Festive",
    "Festive",
    "Festive",
    "Grand party",
    "Festive party",
    "Big benefit, say",
    "Hearty party",
    "Celebration",
    "Ball",
    "Blowout",
    "Event covered by paparazzi",
    "Festive event",
    "Black-tie dinner, say",
    "Big bash",
    "Fancy do",
    "Big bash",
    "Many a fund-raiser",
    "Big do",
    "Festive",
    "Ball",
    "Society news",
    "Big bash",
    "The Oscars, e.g.",
    "Big bash",
    "Festive",
    "Big party",
    "Showy",
    "Bash",
    "Benefit, often",
    "Joyous celebration",
    "Festive",
    "Fund-raiser, often",
    "Hollywood do",
    "Showy",
    "Blowout",
    "Celebration",
    "Grand old party",
    "Wingding",
    "Festive",
    "Lavish party",
    "Festival"
  ],
  "bend": [
    "Something you might do \"over backward\"",
    "Finally give in after initial pushback",
    "Unstraighten, as a wire",
    "Genuflect, e.g.",
    "Flex",
    "Compromise",
    "Fudge, as a rule",
    "Distort, as the truth",
    "Stoop",
    "Not stay rigid",
    "Show flexibility",
    "River feature",
    "Crook",
    "Dangerous place to pass a car",
    "Stoop",
    "Give a little",
    "Not stay rigid",
    "Give a bit",
    "Take a bow?",
    "Flex",
    "Distort",
    "Make curves",
    "River feature",
    "Curve",
    "Deep knee ___",
    "River curve"
  ],
  "mend": [
    "Fix",
    "Repair",
    "Repair",
    "Tape or patch",
    "Darn",
    "Put back together",
    "On the ___ (recuperating)",
    "Patch up",
    "Fix",
    "Repair",
    "Darn, as socks",
    "Heal",
    "Make sound",
    "Repair",
    "Darn",
    "Fix",
    "Rectify",
    "Repair",
    "Patch up",
    "Tape, say",
    "Repair",
    "Darn, as socks",
    "Fix up",
    "Fix",
    "Patch up",
    "Fix up",
    "Darn, as socks",
    "Part of an old sock, maybe",
    "Make whole",
    "Darn",
    "Knit, maybe",
    "Make whole",
    "Recuperate",
    "Heal",
    "Right",
    "Improve",
    "Make sound",
    "Get better",
    "Sew up",
    "Put right",
    "Darn",
    "Get better, so to speak",
    "Repair",
    "Darn",
    "Repair",
    "Fix",
    "Doctor",
    "Knit",
    "Tape",
    "Doctor",
    "Darn",
    "Fix",
    "Darn",
    "Patch",
    "Darn",
    "Repair",
    "Patch"
  ],
  "luna": [
    "Moon goddess",
    "___ Bar (protein snack)",
    "Moon goddess",
    "Moon goddess",
    "Roman counterpart of the Greek goddess Selene",
    "Roman moon goddess",
    "Counterpart of the Roman god Sol",
    "___ Lovegood, friend of Harry Potter",
    "Roman moon goddess",
    "Moth whose name is Latin for \"moon\"",
    "Selene's Roman counterpart",
    "With 5-Across, lime-green nocturnal insect",
    "1960s-'70s Soviet space program",
    "Coney Island's ___ Park",
    "\"Two-horned queen of the stars,\" per Horace",
    "Lime green 25-Across",
    "Moon goddess",
    "Moon goddess",
    "___ moth",
    "Goddess of the moon",
    "___ Park, classic Coney Island amusement locale",
    "Counterpart of Selene",
    "___ moth",
    "___ Park (Coney Island destination)",
    "Old Coney Island's ___ Park",
    "Long-tailed moth",
    "___ moth",
    "Counterpart of Apollo",
    "___ moth",
    "___ 9, first spacecraft to land softly on the moon",
    "___ Park, old Coney Island attraction",
    "Roman moon goddess",
    "___ II, first man-made object to reach the moon",
    "Soviet space probe",
    "___ moth",
    "Large moth",
    "Kind of moth",
    "Moon goddess",
    "1960's Soviet moon program",
    "Moon goddess",
    "Early Soviet space probe",
    "Moon over Milano",
    "Moon, personified",
    "Isabel Allende's \"Eva ___\"",
    "Selene's counterpart",
    "Moon goddess",
    "Coney Island's ___ Park",
    "Moth with translucent spots on its wings",
    "Pioneering Russian spacecraft series",
    "Kind of moth",
    "Moon goddess",
    "50's-70's Soviet spacecraft series",
    "Large green moth",
    "Moon goddess",
    "Moon goddess",
    "Moon goddess",
    "Moon goddess",
    "Kind of moth",
    "Selene's counterpart"
  ],
  "mint": [
    "Ideal condition for collectibles",
    "Item on a hotel pillow",
    "Mojito garnish",
    "Make, as money",
    "Huge fortune",
    "Green shade",
    "Pristine",
    "In perfect condition",
    "Still in the box, perhaps",
    "Producer of change",
    "Unused",
    "Where money is made",
    "Moneymaking enterprise",
    "Philadelphia tourist attraction",
    "It might come with a bill",
    "Coin grade",
    "Moneymaking concern",
    "Toothpaste flavor",
    "Philadelphia landmark",
    "Breath freshener",
    "One making lots of money",
    "Ingredient in many toothpastes",
    "Major money maker",
    "Money maker",
    "Mouthwash flavor",
    "Money maker",
    "Perfect",
    "Lot of money",
    "One making lots of money",
    "Philadelphia landmark since 1792",
    "Item on a hotel pillow",
    "Breath freshener",
    "In perfect condition",
    "Coin grade higher than fine",
    "Perfect",
    "Money maker",
    "Theater snack item",
    "Bill producer",
    "Where change is made",
    "Make money",
    "Like new",
    "Make change?",
    "Moneymaking venture",
    "Like new",
    "Make money",
    "Perfect",
    "In flawless shape",
    "Coin",
    "San Francisco or Denver facility",
    "Basil, e.g.",
    "Pillow candy",
    "Perfect",
    "Breath freshener",
    "Money maker"
  ],
  "indo": [
    "___-European languages",
    "___-European languages",
    "Prefix with China",
    "Lead-in to China",
    "Intro to Chinese?",
    "___-European",
    "___-European language",
    "Prefix with Germanic",
    "___-European",
    "___-European",
    "Prefix with Germanic",
    "Prefix with European",
    "Prefix with China",
    "___-European",
    "Intro to Chinese?",
    "___-European languages",
    "Chinese leader?",
    "European leader?",
    "___-European",
    "Opening China?",
    "Intro to Chinese?",
    "___-Aryan",
    "Prefix with Chinese",
    "___-Aryan",
    "___-European languages",
    "___-European",
    "___-European languages",
    "___-European",
    "Prefix with Germanic",
    "European leader?",
    "Introduction to Chinese?",
    "___-European",
    "Prefix with Aryan",
    "Chinese leader?",
    "___-European",
    "Prefix with European",
    "Prefix with European",
    "Prefix with China",
    "___-Iranian languages",
    "___-European",
    "___-European",
    "___-European",
    "___-European",
    "Prefix with European",
    "___-Aryan",
    "European leader?",
    "___-European",
    "___-European",
    "___-European",
    "Prefix with China",
    "Prefix with Chinese",
    "Prefix with China",
    "___-Aryan (language group)",
    "___-European",
    "___-European",
    "___ -European",
    "___ -European",
    "___-China",
    "Prefix with China"
  ],
  "clop": [
    "See 1-Down",
    "Sound of a wooden shoe",
    "Common sound in Amish country",
    "Western sound effect",
    "Oater sound",
    "Sound heard at equestrian events",
    "Sound on a trail",
    "Wooden shoe's sound",
    "Hoofbeat",
    "Horse sound",
    "Walking sound",
    "Sound effect in a western",
    "Horse's footfall",
    "Sound on cobblestone streets",
    "Hoof sound",
    "Hoof sound",
    "Hoofbeat",
    "Hoof sound",
    "Shoe sound",
    "Clip chaser",
    "Hoof sound",
    "Hoof sound",
    "Footfall",
    "Hoof sound",
    "Hoofbeat"
  ],
  "lavs": [
    "Loos",
    "Loos",
    "W.C.s",
    "W.C.'s",
    "Loos",
    "W.C.'s",
    "Restrooms, informally",
    "Johns"
  ],
  "finn": [
    "See 42-Down",
    "\"There warn't no home like a raft ...\" speaker",
    "Fictional boy who rafted down the Mississippi",
    "Nordic native",
    "Hero role in \"The Force Awakens\"",
    "Markka spender, once",
    "Huckleberry ___",
    "Lead role in \"Star Wars: The Force Awakens\"",
    "Fictional boy who claimed \"All kings is mostly rapscallions\"",
    "Neighbor of a Swede",
    "Jean Sibelius, for one",
    "Huckleberry ___",
    "Eero Saarinen, by birth",
    "Trollope's \"Phineas ___\"",
    "Huckleberry ___",
    "Twain family name",
    "Spender of markkas, once",
    "Certain Scandinavian",
    "Fjord explorer?",
    "Huckleberry ___",
    "Laplander, maybe",
    "Helsinki native",
    "Many an Olympic skiing gold medalist",
    "A Mickey",
    "Twain family name",
    "Huckleberry or Mickey",
    "Russian's neighbor",
    "Huckleberry ___"
  ],
  "leon": [
    "Rock's Kings of ___",
    "Larry's housemate on \"Curb Your Enthusiasm\"",
    "Boxer Spinks who upset Muhammad Ali",
    "Soul singer Bridges",
    "Revolutionary Trotsky",
    "Nuevo ___ (Mexican state)",
    "Explorer Ponce de ___",
    "Revolutionary Trotsky",
    "Rock's Kings of ___",
    "Province of NW Spain",
    "Rock's Kings of ___",
    "Spanish provincial capital",
    "Historic kingdom in Spain",
    "Former C.I.A. director Panetta",
    "Nuevo ___, state in Mexico",
    "The first \"L\" of L. L. Bean",
    "Czolgosz who shot McKinley",
    "Spanish province",
    "Former C.I.A. chief Panetta",
    "Spanish province or its capital",
    "Second-largest city in Nicaragua",
    "Kings of ___ (\"Use Somebody\" band)",
    "Kings of ___ (\"Use Somebody\" band)",
    "Rock's Kings of ___",
    "Defense secretary Panetta",
    "Provincial capital NW of Madrid",
    "C.I.A. director Panetta",
    "Explorer Ponce de ___",
    "Trotsky of Russia",
    "Nuevo ___, Mexican state bordering Texas",
    "Pugilist Spinks",
    "Province NW of Madrid",
    "Rock singer Russell",
    "Oil magnate Hess",
    "Ponce de ___",
    "One of Emma's lovers in \"Madame Bovary\"",
    "Nicaragua's second-largest city",
    "Nuevo ___ (state in Mexico)",
    "Trotsky or Spinks",
    "Singer Redbone",
    "City NNW of Madrid",
    "Nicaragua's second-largest city",
    "Revolutionary Trotsky",
    "Ancient Spanish kingdom",
    "Spinks or Trotsky",
    "Boxer Spinks",
    "Ponce de ___",
    "City NNW of Madrid",
    "Writer Uris",
    "Ponce de ___",
    "\"Exodus\" author Uris",
    "Rocker Russell",
    "Ponce de ___",
    "Henry James biographer Edel",
    "Province of Spain",
    "Boxer Spinks",
    "Ponce de ___",
    "Clinton aide Panetta",
    "Trotsky or Uris",
    "Former capital of Nicaragua",
    "The first \"L\" of L. L. Bean",
    "Old Spanish kingdom",
    "Singer Redbone",
    "Castile neighbor",
    "Bolshevik Trotsky",
    "Old Spanish kingdom",
    "Old Spanish kingdom",
    "Folk star Redbone",
    "Spinks of the ring",
    "\"Dog Day Afternoon\" character",
    "Revolutionary Trotsky",
    "Ponce's birthplace",
    "City NW of Madrid",
    "Nicaragua's second-largest city",
    "Singer Russell of 70's music",
    "Former capital of Nicaragua",
    "Revolutionary Trotsky",
    "Mr. Trotsky",
    "Clinton aide Panetta",
    "Writer Uris",
    "Panetta of the White House",
    "Panetta of the White House",
    "Pianist Fleisher",
    "Watergate prosecutor Jaworski",
    "Clinton aide",
    "Spanish province or capital",
    "Prosecutor Jaworski",
    "Revolutionary Trotsky",
    "Ancient land of Spain"
  ],
  "irae": [
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Of wrath,\" in a hymn title",
    "\"Dies ___\" (Latin hymn)",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Roman \"of wrath\"",
    "\"Dies ___\" (Latin hymn)",
    "\"Of wrath,\" in a hymn title",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (Latin hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Requiem Mass word",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Requiem hymn word",
    "\"Of wrath,\" in a hymn title",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Deus ___\" (1976 sci-fi novel)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\" (Latin hymn)",
    "\"Dies ___\" (hymn)",
    "Of wrath, in a Latin hymn",
    "___ caelestes (divine wrath: Lat.)",
    "\"___ Dies\"",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (Latin hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Requiem title word",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Requiem Mass hymn word",
    "\"Dies ___\"",
    "Requiem title word",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\" (hymn)",
    "Latin hymn \"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Dies ___",
    "\"Dies ___\" (liturgical poem)",
    "\"Dies ___\"",
    "?ôDies ___?ö",
    "End of a requiem title",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___, dies illa\"",
    "\"Dies ___\"",
    "Title word of a song from Mozart's \"Requiem\"",
    "Requiem Mass word",
    "\"Dies ___\"",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Requiem Mass word",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Dies ___",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\" (hymn)",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "Hymn \"Dies ___\"",
    "\"Dies ___\"",
    "Of wrath, in a hymn",
    "Dies___",
    "\"Dies ___\"",
    "Hymn word",
    "\"Dies ___\"",
    "Latin hymn word",
    "\"Dies ___\"",
    "Of wrath, in a hymn",
    "\"Dies ___\"",
    "\"Dies ___\"",
    "See 111 Across",
    "\"Dies ___\""
  ],
  "clip": [
    "With 1-Across, sound of a carriage horse",
    "Alternative to a staple",
    "Cut out",
    "Video segment",
    "Film excerpt",
    "Fast pace",
    "Trim",
    "Excerpt",
    "Money holder",
    "Film excerpt",
    "Trailer segment",
    "Rate of speed",
    "Bit from a movie",
    "Hair holder",
    "Scissor cut",
    "Trim, as a hedge",
    "Good pace, informally",
    "Part of a televised movie review",
    "YouTube upload",
    "Video segment",
    "Fast pace",
    "Cut out, as coupons",
    "Bit of a TV reviewer's review",
    "Hair accessory",
    "\"At the Movies\" bit",
    "YouTube selection",
    "Tie fastener",
    "\"Good\" rate",
    "Movie snippet",
    "Money holder",
    "YouTube offering",
    "Incomplete picture?",
    "Pace",
    "Ammo holder",
    "Cut short",
    "Cut the hair of",
    "Good rate of speed",
    "Fast pace",
    "Football no-no",
    "Scissor",
    "Fleece",
    "Part of some film reviews",
    "Football foul",
    "Kind of joint",
    "Film fragment",
    "Illegal block",
    "Illegal block",
    "Fast pace",
    "Movie excerpt",
    "Office holder?",
    "Word with film or cartridge",
    "Football foul",
    "Cut, as nails",
    "Biker's aid",
    "Kind of joint",
    "Football no-no",
    "Kind of joint",
    "Fleece"
  ],
  "lace": [
    "Doily feature",
    "Tie up",
    "More than a card, but less than a track bet",
    "Doily material",
    "Wedding dress material",
    "Some curtain trim",
    "Fine fabric",
    "Get ready to play, with \"up\"",
    "Spike",
    "Something found near the tongue?",
    "Spike",
    "13th-anniversary gift",
    "Fancy collar material",
    "Spike",
    "Fancy collar material",
    "Castigate, with \"into\"",
    "One poked through the eye?",
    "Spike, as punch",
    "Add punch to, as the punch",
    "Tie (up)",
    "\"Arsenic and Old ___\"",
    "Tablecloth material",
    "Veil material",
    "Shoestring",
    "Teddy material",
    "Wedding gown material",
    "Doily material",
    "Some frills",
    "Spike",
    "Spike, as punch",
    "Fabric that doesn't block much light",
    "Negligee material",
    "Collar material",
    "Doily material",
    "Spike, as the punch",
    "Feature of many a bodice",
    "Wedding dress material",
    "Wedding dress material",
    "Doily material",
    "Add a kick to",
    "Skate part",
    "Bodice fastener",
    "Valentine decoration",
    "Some needlework",
    "Queen Anne's ___",
    "Feature of many a wedding dress",
    "Frilly material",
    "Frill",
    "Shoestring",
    "Some frills",
    "Tie up",
    "Open fabric",
    "Tie up",
    "Add kick to",
    "Mennonite decoration",
    "Delicate fabric",
    "Prepare to play, with \"up\"",
    "Tablecloth material",
    "Chantilly product",
    "Scarf material",
    "Strengthen, in a way",
    "Tie up",
    "Make more potent",
    "Rose point, e.g.",
    "Rip (into)",
    "Doily material",
    "Spike",
    "Irish export",
    "Shoestring",
    "Alencon product",
    "Filigree",
    "Some froufrou",
    "Some needlework",
    "Shoestring",
    "Shoestring",
    "Valenciennes, e.g.",
    "Spike, as the punch",
    "Wedding finery",
    "Teddy material",
    "Chantilly ___",
    "Doily material"
  ],
  "juno": [
    "Queen of the gods, in Roman myth",
    "Hera's Roman counterpart",
    "Mother of Mars, in myth",
    "Roman goddess, protector of women and marriage",
    "Title teen in a 2007 hit indie film",
    "Hera, to the Romans",
    "2007 title role for Ellen Page",
    "2007 Ellen Page film",
    "2007 Ellen Page title role",
    "2007 Best Picture nominee with a mythological name",
    "Pantheonic queen",
    "2007 film that won the Academy Award for Best Original Screenplay",
    "2007 title role for Ellen Page",
    "Daughter of Saturn",
    "Jupiter's wife",
    "D-Day beach",
    "O'Casey's \"___and the Paycock\"",
    "Goddess named in the opening of the \"Aeneid\"",
    "Goddess often pictured in a peacock-drawn chariot",
    "Queen of heaven",
    "O'Casey play \"___ and the Paycock\""
  ],
  "huck": [
    "With 19-Across, pal of Tom Sawyer",
    "Pap's son, in literature",
    "Twain hero, informally",
    "Literary pal of Tom",
    "1884 literary hero, informally",
    "Twain hero"
  ],
  "zone": [
    "Designate as \"commercial\" or \"residential,\" e.g.",
    "Certain basketball defense",
    "Man-to-man alternative",
    "The \"Z\" of ZIP code",
    "Certain basketball defense",
    "Word that can follow time, danger or neutral",
    "City planners' designation",
    "Bit of planning board planning",
    "Man-to-man alternative",
    "See 2-Down",
    "Counterpart of man-to-man",
    "Alternative to a man-to-man defense",
    "\"The Twilight ___\"",
    "The \"Z\" of DMZ",
    "Tract",
    "Court area",
    "Man-to-man alternative",
    "Bus schedule specification",
    "The \"Z\" in DMZ",
    "Neighborhood",
    "Designate \"commercial\" or \"single-family,\" e.g.",
    "The \"Z\" of DMZ",
    "Man-to-man alternative",
    "District",
    "Area",
    "It might be neutral",
    "Basketball defense",
    "Delivery area",
    "Be hazily inattentive, in slang",
    "Type of court defense",
    "Factor in shipping costs",
    "Defense type",
    "Court defense",
    "In the ___ (focused)",
    "Region",
    "Part of DMZ",
    "Kind of defense",
    "Part of DMZ",
    "Basketball defense",
    "Basketball defense",
    "Defense type",
    "District",
    "Area",
    "Postal delivery area",
    "Stop listening, with \"out\"",
    "Belt",
    "Defense type",
    "Time ___",
    "Commercial ___",
    "Belt",
    "Court defense",
    "Region",
    "Belt",
    "Locale",
    "Taxi map division",
    "Neighborhood",
    "District"
  ],
  "sere": [
    "Parched, as a desert",
    "Desertlike",
    "Exceedingly dry",
    "Bone-dry",
    "Extremely dry",
    "Dry",
    "Withered",
    "Dry as a bone",
    "Dried up",
    "Bone-dry",
    "Bone-dry",
    "Saharan",
    "Withered",
    "Very dry",
    "Bone-dry",
    "Dry",
    "Desiccated",
    "Dry",
    "Drought-ridden",
    "Wizened up",
    "Withered",
    "Dry",
    "Dried out",
    "Dry",
    "Parched",
    "Dry as dust",
    "Saharan",
    "Desertlike",
    "Gobi-like",
    "Bone-dry",
    "Like the Atacama",
    "Drought-stricken",
    "Desertlike",
    "Bone-dry",
    "Sun-baked",
    "Very dry",
    "Parched",
    "Scorched",
    "Not at all wet",
    "Sun-damaged",
    "Desiccated",
    "Arid",
    "Bone-dry",
    "Lacking moisture",
    "Dried out",
    "Bone-dry",
    "Withered",
    "Withered",
    "Sun-burned",
    "Drought-stricken",
    "Anhydrous",
    "Withered",
    "Like the Sahara",
    "Parched",
    "Dry",
    "Bone-dry",
    "Withered",
    "Like a desert",
    "Arid",
    "Bone-dry",
    "Desertlike",
    "Dry",
    "Saharan",
    "Dehydrated",
    "Sun-cracked",
    "Withered",
    "Dried up",
    "Parched",
    "Parched",
    "Withered",
    "Dried up",
    "Withered",
    "Desertlike",
    "Heat-cracked",
    "Sun-cracked",
    "Parched",
    "Damaged by drought",
    "Drought-damaged",
    "Dried up",
    "Like the Gobi",
    "Withered",
    "Withered",
    "Drought-ridden",
    "Dry as dust",
    "Dry",
    "Dried up",
    "Dry as dust",
    "Dehydrated",
    "Withered",
    "Parched",
    "Moistureless",
    "Dried up",
    "Withered",
    "Withered",
    "Dry",
    "Bone-dry",
    "Dry",
    "Dried up",
    "Desiccated",
    "Like the surface of Mars",
    "Arid",
    "Dried up",
    "Withered",
    "Dried up",
    "Withered",
    "Parched",
    "Wizened",
    "All dried up",
    "Like autumn leaves",
    "Arid",
    "Wizened",
    "Unwatered",
    "Dried",
    "Devoid of moisture",
    "Desiccated",
    "Withered",
    "Decidedly not marshy"
  ],
  "bing": [
    "Alternative to Google",
    "Kind of cherry",
    "Google alternative",
    "Microsoft search engine",
    "Google alternative",
    "Cherry variety",
    "Google alternative",
    "A search may be done with it",
    "Google rival",
    "___ cherry",
    "Cherry variety",
    "Cherry variety",
    "\"Road\" picture partner for Bob",
    "\"Ba-da-___, ba-da-boom\"",
    "___ cherry",
    "Cherry variety",
    "___ cherry",
    "\"Ba-da-___!\"",
    "Rudolf who once headed the Met",
    "Cherry variety",
    "___ cherry",
    "Cherry type",
    "Met manager, 1950-72",
    "\"5,000 Nights at the Opera\" author"
  ],
  "yale": [
    "Alma mater of five U.S. presidents",
    "Where to find the Whiffenpoofs, who have been singing since 1909",
    "Rival of Harvard",
    "Harvard rival",
    "Bulldogs' home",
    "Alma mater of Samuel Morse",
    "Alma mater for many a Supreme Court justice",
    "The Collegiate School, today",
    "Where Bill and Hillary Clinton met",
    "School with the motto \"Lux et veritas\"",
    "College that awarded the first Ph.D. in the U.S.",
    "Noah Webster's alma mater",
    "Bulldogs' school in the Ivy League",
    "Where Flash Gordon played polo",
    "___ Daily News (paper since 1878)",
    "Connecticut Ivy",
    "Alma mater for Bush 41 and Bush 43",
    "Harvard's archrival",
    "Last Ivy League school alphabetically",
    "Harvard rival",
    "School for Rory Gilmore of \"Gilmore Girls\"",
    "Connecticut Ivy",
    "Its Latin motto translates to \"Light and truth\"",
    "Harvard rival",
    "Home of the Beinecke Library",
    "Big lock maker",
    "Where Bill and Hillary Clinton met",
    "Alma mater for five U.S. presidents",
    "Connecticut Ivy",
    "Connecticut Ivy",
    "Brown competitor",
    "Harkness Tower locale",
    "New Haven school",
    "See 32-Across",
    "It's a lock",
    "New Haven school",
    "School where the Clintons met",
    "Alma mater of presidents #41, 42 and 43",
    "Elis' school",
    "Connecticut Ivy",
    "Alma mater for Bill and Hillary Clinton",
    "Big name in locks",
    "Noah Webster's alma mater",
    "School for 64-Across",
    "Ivy with deep roots",
    "Lock name",
    "Where the Clintons got law degrees",
    "Columbia rival",
    "Brown alternative",
    "Setting for many episodes of TV's \"Gilmore Girls\"",
    "Where the Clintons met",
    "Where Bill and Hillary met",
    "Site of Beinecke Library",
    "Ivy League school",
    "Like some locks",
    "Its motto is \"Lux et veritas\"",
    "Harvard rival",
    "Winner of a 1925 intercollegiate crossword championship",
    "An Ivy",
    "Where the Clintons met",
    "Home of the Peabody Museum of Natural History",
    "Institution since 1701",
    "Its motto is \"Lux et veritas\"",
    "Lock giant",
    "College where an athlete might wear a \"Y\"",
    "Bush's alma mater",
    "Connecticut campus",
    "George W. Bush's alma mater",
    "Lock name",
    "President Taft's alma mater",
    "Big name in locks",
    "Bowl site",
    "President Taft's alma mater",
    "George W. Bush's alma mater",
    "Brown rival",
    "Lock maker",
    "New Haven institution",
    "Bush's alma mater",
    "Bush's alma mater",
    "Ivy League school",
    "Brown foe",
    "See 59-Across",
    "Kind of lock",
    "Part of the Ivy League",
    "Bush's alma mater",
    "See 44-Down",
    "Harvard rival",
    "Nathan Hale's alma mater",
    "Lock producer",
    "George Bush's alma mater",
    "Eli's school",
    "Where Bill met Hillary",
    "Brown rival",
    "Where Gerald Ford went to law school",
    "New Haven school",
    "Harvard rival",
    "Brown opponent",
    "Jodie Foster's alma mater",
    "William Howard Taft's alma mater",
    "N.F.L. Hall-of-Famer ___ Lary",
    "Institution since 1701",
    "Lockmaker of note",
    "Where the Clintons met",
    "Noah Webster's alma mater",
    "Brown alternative",
    "Jodie Foster's alma mater",
    "___ Bowl",
    "The Clintons' alma mater",
    "Where Bill met Hillary",
    "Lockmaker",
    "Where the Clintons met",
    "Word on a lock",
    "Locksmith Linus",
    "Eli's halls",
    "Where the Clintons met",
    "Name on many locks",
    "Buckley's \"God and Man at ___\"",
    "Rival of Brown",
    "One 39-Across"
  ],
  "beep": [
    "Sound much heard in traffic",
    "What to leave a phone message after",
    "Smoke detector alert",
    "Rush-hour sound",
    "After the ___ (when to leave a phone message)",
    "Arcade game sound",
    "Smartphone sound",
    "Blast from the passed?",
    "Appliance sound",
    "Toot",
    "Low-battery signal",
    "Microwave sound",
    "Sound made by a 48-Down",
    "Audible warning on the road",
    "Sound heard during gridlock",
    "When repeated, Road Runner's call",
    "When repeated, \"Out of the way!\"",
    "Attention getter",
    "Timer sound",
    "A message may be left after it",
    "When repeated, Road Runner's sound",
    "When repeated, the Road Runner's cry",
    "Intercom sound",
    "Caller's prompt",
    "Page",
    "Summon, in a way",
    "[Make way!]",
    "[Out of my way!]",
    "Modern summons",
    "Street sound",
    "Pager sound",
    "Toot",
    "Pager cue",
    "Toot",
    "Page sound",
    "Pager's sound",
    "\"Out of my way!\"",
    "Toot"
  ],
  "tenn": [
    "Nashville's home: Abbr.",
    "Dollywood's locale: Abbr.",
    "Lady Vols' home: Abbr.",
    "Graceland's home: Abbr.",
    "Nashville's home: Abbr.",
    "Locale of Franklin County ... or of Aretha Franklin's birth: Abbr.",
    "State with part of I-81: Abbr.",
    "Volunteer's place: Abbr.",
    "Where Graceland is: Abbr.",
    "Home to Dollywood and Graceland: Abbr.",
    "Where Davy Crockett was born: Abbr.",
    "It has eight neighbors: Abbr.",
    "Andrew Johnson's home: Abbr.",
    "Home of Cherokee Natl. Forest",
    "It's south of Ky.",
    "Home state of Andrew Johnson: Abbr.",
    "Volunteer's place: Abbr.",
    "Clingmans Dome is its highest point: Abbr.",
    "Volunteer State: Abbr.",
    "State trisected by a river of the same name: Abbr.",
    "Neighbor of 38-Down: Abbr.",
    "Home of Davy Crockett: Abbr.",
    "Home of Ft. Donelson Natl. Battlefield",
    "Dollywood's state: Abbr.",
    "Bristol locale: Abbr.",
    "Miss. neighbor",
    "T.V.A. part: Abbr.",
    "Ga. neighbor",
    "Dollywood locale: Abbr.",
    "Chattanooga's home: Abbr.",
    "Cherokee Natl. Forest locale",
    "Where Davy Crockett was born: Abbr.",
    "Where Memphis is: Abbr.",
    "Neighbor of Mo.",
    "Where the Hermitage is: Abbr.",
    "Where Dollywood is: Abbr.",
    "Clingmans Dome locale: Abbr.",
    "It's west of N.C.",
    "Ky.-Ala. divider",
    "State south of Ky.",
    "Neighbor of Ark.",
    "Cumberland R. locale",
    "Volunteer's home: Abbr.",
    "Volunteer State: Abbr.",
    "Part of T.V.A.: Abbr.",
    "N.C. neighbor"
  ],
  "ange": [
    "French celestial being",
    "___ ou Demon (Givenchy perfume)",
    "Image on the ceiling of la chapelle Sixtine",
    "Figure on the top of Paris's Notre-Dame",
    "Haloed one: Fr.",
    "Messenger de Dieu",
    "Being with une aureole",
    "Cherub at Notre Dame",
    "Celestial being, in France",
    "Figure depicted in une eglise",
    "Religious figure, to Pierre",
    "Image in the Notre Dame de Paris",
    "Chantilly seraph",
    "Parisian protector",
    "Haloed one, in France",
    "Halo wearer, in France",
    "Haloed one, in Le Havre",
    "Seraph of Sevres",
    "Noel vision",
    "Cherub, at Notre Dame",
    "Celeste being"
  ],
  "itis": [
    "\"No lie!\"",
    "\"No kidding?\"",
    "Words on either side of \"___ what ___\"",
    "Reply to \"Can this be true?\"",
    "\"True that\"",
    "\"Indeed\"",
    "Suffix with senior",
    "\"That so?\"",
    "Suffix with sinus",
    "End of an illness?",
    "\"That so?\" reply",
    "\"___ said ...\"",
    "Words said before and after \"what\"",
    "\"How sweet ___!\"",
    "Sinus suffix",
    "\"That so?\"",
    "Reply to 5-Down",
    "How many a medical problem ends?",
    "\"Really?\"",
    "Answer to \"That so?\"",
    "Medical suffix",
    "Medical suffix",
    "\"Really?\"",
    "\"Indeed\"",
    "Reply to \"Really?\"",
    "\"Really?\"",
    "Suffix with senior",
    "\"Indeed\"",
    "Suffix with senior",
    "\"Really?\"",
    "Medical suffix",
    "\"How sweet ___!\"",
    "Irritation suffix",
    "\"How sweet ___!\"",
    "End of an illness?",
    "Reply to \"That so?\"",
    "\"Can that be true?\"",
    "Reply to \"That so?\"",
    "\"How sweet ___!\"",
    "Medical suffix",
    "Medical suffix",
    "Reply to \"That so?\"",
    "\"How sweet ___!\"",
    "Reply to \"That so?\"",
    "\"How Sweet ___\"",
    "\"How sweet ___!\"",
    "Possible answer to \"Really?\"",
    "Sufferer's suffix",
    "Inflammatory suffix",
    "\"Really?\"",
    "Suffix with sinus",
    "Exclamation of confirmation",
    "Illness's end?",
    "\"How sweet ___!\"",
    "\"How sweet ___!\"",
    "\"How sweet ___!\"",
    "Reply to \"That a fact?\"",
    "\"See who ___\"",
    "\"Really?\"",
    "Suffix with adenoid",
    "Inflammatory ending?",
    "Medical suffix",
    "Suffix with sinus",
    "Medical suffix",
    "Reply to \"Can this be true?\"",
    "Medical suffix",
    "Malady suffix",
    "Medical suffix",
    "How many diseases end",
    "Medical suffix",
    "\"How sweet___!\"",
    "End of an affliction",
    "\"Tell it like___!\"",
    "\"How sweet ___!\"",
    "Medical suffix",
    "Start of a Stepquote",
    "Medical suffix",
    "Swell suffix?",
    "The Beatles' \"Yes ___\""
  ],
  "lube": [
    "___ job (bit of garage work)",
    "Auto maintenance job, informally",
    "Job for a grease monkey",
    "Grease, informally",
    "It lets things slide",
    "Squeak stopper",
    "Use a 30-Across",
    "Jiffy ___",
    "Friction fighter",
    "Garage job, for short",
    "Grease up",
    "Friction reducer",
    "Garage job",
    "Garage job",
    "Kind of job",
    "Garage job",
    "Grease, briefly",
    "Grease monkey's job",
    "Job that helps ease friction",
    "Garage job",
    "Grease job",
    "Truck treatment",
    "Oil, in mechanic-speak",
    "Garage job",
    "Auto servicing",
    "Grease monkey's task",
    "Car job",
    "Car job"
  ],
  "silk": [
    "Epitome of smoothness",
    "Material for ties or fine sheets",
    "Smooth sheet material",
    "___ Road, route for Marco Polo",
    "Part 2 of the word ladder",
    "Stocking material",
    "Web content",
    "Product of a domesticated insect",
    "*Lingerie material",
    "Symbol of smoothness",
    "Scarf material",
    "Crepe de Chine, e.g.",
    "Regal material",
    "Lingerie material",
    "Worm product",
    "Fine pajama material",
    "Suture material",
    "Slinky fabric",
    "Fine stocking material",
    "Tie material",
    "Stocking material",
    "Imported material",
    "Tie fabric",
    "Kimono fabric"
  ],
  "dome": [
    "There's a famous \"half\" one in Yosemite National Park",
    "Famous feature of the Florence Cathedral",
    "Half ___ (Yosemite attraction)",
    "Jefferson Memorial topper",
    "Taj Mahal feature",
    "Feature of Rome's Pantheon",
    "Rotunda feature",
    "Igloo, essentially",
    "AT&T Stadium feature",
    "Capitol Hill sight",
    "Top of the Capitol",
    "It may be retracted",
    "Jefferson Memorial topper",
    "___ of the Rock (Jerusalem landmark)",
    "Nonrectangular part of a skyline",
    "Observatory feature",
    "Capitol feature",
    "Taj Mahal feature",
    "Feature of some arenas",
    "Many a stadium cover",
    "Capitol's top",
    "___ of the Rock (Jerusalem shrine)",
    "Volcanic formation",
    "Hemisphere",
    "Volcano feature",
    "Indoor arena feature",
    "Planetarium roof",
    "Round top",
    "Baldpate",
    "Capitol topper",
    "Capitol feature",
    "Rotunda feature",
    "Head, slangily",
    "Capitol feature",
    "Taj Mahal feature",
    "Capitol feature",
    "Fuller construction",
    "Capitol topper",
    "Rotunda's crown",
    "Baldie's head",
    "St. Paul's top",
    "Bald head",
    "Igloo shape"
  ],
  "puts": [
    "Saves, with \"away\"",
    "\"Nobody ___ Baby in a corner\" (line from \"Dirty Dancing\")",
    "Places",
    "Sets (down)",
    "Places",
    "Plunks (down)",
    "Sets (down)",
    "Options for traders",
    "Wall Street transactions",
    "Lays (down)",
    "Places",
    "Throws a shot",
    "Implants",
    "Plunks (down)",
    "Attributes",
    "Sets"
  ],
  "yank": [
    "Quite a jerk",
    "Jerk move?",
    "Remove abruptly",
    "Big jerk",
    "Pull hard",
    "Reb's foe",
    "Tug sharply",
    "Pinstriper",
    "A lot of pull?",
    "American, in England",
    "Jerk hard",
    "Tug hard",
    "Big tug",
    "Bronx Bomber",
    "Pull sharply",
    "Pull hard",
    "Pull suddenly",
    "Bronx Bomber",
    "American, abroad",
    "U.S. soldier in W.W. II",
    "Big jerk",
    "An American, to a Brit",
    "Pull a fast one?",
    "American, in W.W. II",
    "American",
    "American",
    "W.W. II Army magazine"
  ],
  "ettu": [
    "\"You, too?!,\" playfully",
    "Famous almost-last words from Caesar",
    "Shocked response to Brutus",
    "Words immediately after Casca cries \"Speak, hands, for me!\"",
    "\"And you?,\" to Caesar",
    "\"You, too?!\"",
    "Senate rebuke",
    "\"Really? Is <em>nobody</em> on my side now?\"",
    "Some famous last words",
    "Caesar's accusation",
    "Words to a betrayer",
    "\"___, Brute?\"",
    "Senate rebuke",
    "Rebuke to Brutus",
    "Some famous last words",
    "Shakespeare's \"You, too?\"",
    "Censure of a senator",
    "Question to a betrayer",
    "Latin rebuke",
    "Question to a backstabber",
    "Words to a backstabber",
    "Dramatic \"You too?\"",
    "\"___, Brute!\"",
    "\"___, Brute?\"",
    "Words upon a shocked realization",
    "Roman rebuke",
    "Words to the disloyal",
    "Caesar's rebuke to Brutus",
    "Question for Brutus",
    "Start of a Julius Caesar quote",
    "Ides of March rebuke",
    "Classical rebuke",
    "Caesarean rebuke",
    "Rebuke to a traitor",
    "Lament after being backstabbed",
    "\"___, Brute!\"",
    "Caesar's accusation to Brutus",
    "Ides rebuke",
    "Rebuke to a traitor",
    "\"I thought you had my back!\"",
    "Words of rebuke",
    "Accusation to Brutus",
    "Response to an attack by a group of senators",
    "Dying rebuke",
    "Caesar's last gasp?",
    "\"___, Brute?\"",
    "Dramatic cry from people who get subbed",
    "Accusatory words",
    "Surprising words from Shakespeare?",
    "Caesar's rebuke to Brutus",
    "Roman rebuke",
    "Classical \"You too?\"",
    "\"___, Brute?\"",
    "Dramatic accusation",
    "Caesar's words to Brutus",
    "Dying words, in Shakespeare",
    "\"___, Brute?\"",
    "Rebuke to a traitor",
    "Cry of shocked hurt",
    "Words of betrayal",
    "Departing words?",
    "Shakespearean words following \"Speak, hands, for me!\"",
    "Traitor's rebuke",
    "Senate censure",
    "Accusatory words",
    "\"___, Brute?\"",
    "\"___, Brute?\"",
    "Words to a traitor",
    "Classic rebuke",
    "\"You too?\" a la Caesar",
    "Shakespeare rebuke",
    "Accusatory words",
    "Words from the betrayed",
    "Rebuke from Caesar",
    "Classical rebuke",
    "\"___, Brute?\"",
    "\"___, Brute?\"",
    "Roman rebuke",
    "Dramatic rebuke",
    "Caesarean rebuke",
    "\"___, Brute?\"",
    "Caesarean rebuke",
    "\"___, Brute?\"",
    "Caesarean rebuke",
    "Words to a traitor",
    "Caesar's almost-last words",
    "Words of reproach",
    "Accusatory words",
    "Some famous last words",
    "Classical accusation",
    "Response to a backstabber",
    "Dramatic rebuke",
    "Dying words",
    "Accusatory phrase",
    "\"___, Brute?\"",
    "Accusatory words",
    "Caesarean delivery?",
    "Rebuke from Caesar",
    "Words to Brutus",
    "Accusatory words",
    "\"Some friend you are,\" more classically",
    "Words to a traitor",
    "Ides of March utterance",
    "\"__, Brute?\"",
    "\"___, Brute?\"",
    "Caesarean rebuke",
    "Ides of March rebuke",
    "Cry of Caesar",
    "Emperor's rebuke",
    "Classic rebuke",
    "Reproachful words",
    "Ides rebuke",
    "Start of Caesar's last gasp",
    "Famed words to a backstabber",
    "\"___, Brute?\"",
    "Caesar's words to Brutus",
    "Comment of betrayal",
    "Disillusioned query",
    "Old accusation",
    "With 59-Across, words before \"Then fall, Caesar!\"",
    "Accusatory question",
    "Caesar's accusation",
    "Rebuke to Brutus",
    "Dying words",
    "Famous reproach",
    "Words heard in the Senate",
    "Words from Caesar",
    "\"___, Brute?\"",
    "Brute leader?",
    "Ides rebuke",
    "Brute leader?",
    "Senate accusation",
    "Famous dying words",
    "Accusatory response",
    "Caesar's cry",
    "Comment to a traitor",
    "Part of an accusation",
    "Dying words",
    "Words to a traitor",
    "Words of accusation",
    "\"___, Brute?\"",
    "Comment to a backstabber?",
    "Famed reproach",
    "\"___, Brute!\"",
    "Cry of Caesar",
    "Words of Caesar",
    "Accusation from Caesar",
    "\"___, Brute?\"",
    "Words to a traitor",
    "Words to a traitor",
    "March 15 question",
    "\"___, Brute?\"",
    "Words of Caesar",
    "Words from Caesar",
    "Famous words of accusation",
    "Some famous last words",
    "Famous last words",
    "Words of reproach",
    "Part of Caesar's reproach",
    "Words from Caesar",
    "\"___, Brute?\""
  ],
  "chic": [
    "Smart",
    "Smart",
    "In vogue",
    "Trendy",
    "Smart",
    "Very smart",
    "Smart",
    "Smart",
    "Fashion-forward",
    "Stylish",
    "Fashionable",
    "In vogue",
    "Tony",
    "Stylish",
    "Stylish",
    "Dressed in a stunning gown with pearls, say",
    "In",
    "Fashionable",
    "In vogue",
    "A la mode",
    "In fashion",
    "Fashionable",
    "In vogue",
    "All the rage",
    "Trendy",
    "In style",
    "Fashionable",
    "In vogue",
    "Smart",
    "In",
    "In vogue",
    "A la mode",
    "Soigne",
    "Very smart",
    "A la mode",
    "Smart",
    "Tony",
    "Fashionable",
    "In",
    "Radical ___",
    "A la mode",
    "Voguish",
    "Nattily dressed",
    "In",
    "Smart",
    "In",
    "With it",
    "Swank",
    "A la mode",
    "All the rage",
    "Stylish",
    "In",
    "Very smart",
    "\"In\"",
    "Hot",
    "Trendy",
    "Smart",
    "Smart",
    "Smart",
    "Smart",
    "Trendy",
    "Stylish",
    "Stylish",
    "Sophisticated",
    "With it, sartorially",
    "Smart",
    "Quite the rage",
    "In vogue",
    "In high style"
  ],
  "teak": [
    "Wood that's resistant to warping",
    "Hardwood option",
    "Wood for boat decks",
    "Cutting board wood",
    "Deck wood",
    "Water-resistant furniture wood",
    "Deck chair material",
    "Salad bowl wood",
    "Material for outdoor furniture",
    "With 55-Across, preservative for fine wood furniture",
    "Durable furniture wood",
    "Water-resistant timber",
    "Big Indonesian export",
    "Furniture wood",
    "Water-resistant wood",
    "Furniture hardwood",
    "Furniture material",
    "Deck wood",
    "Flooring wood",
    "Deck material",
    "Wood for shipbuilding",
    "Patio furniture wood",
    "Weather-resistant wood",
    "Hard wood",
    "Alternative to cedar",
    "Deck wood",
    "Heavy, durable furniture wood",
    "Cedar alternative",
    "Furniture wood",
    "Deck material",
    "Deck material",
    "Shipbuilding material",
    "Yacht material",
    "Like some patio furniture",
    "Warp-resistant wood",
    "Furniture wood",
    "Furniture wood",
    "Shipbuilder's wood",
    "Shipbuilder's stock",
    "Furniture wood",
    "Some furniture material",
    "Ship material",
    "Durable wood",
    "Furniture wood",
    "Furniture wood",
    "Shipbuilding wood",
    "Shipbuilder's wood",
    "Furniture wood",
    "Shipbuilder's choice",
    "Common office decor",
    "Furniture wood"
  ],
  "gale": [
    "It really blows",
    "Big blow",
    "Burst of laughter",
    "Big gust",
    "Big wind",
    "It's a big blow",
    "Big blow",
    "High wind",
    "Major blow",
    "50-mile-an-hour wind, e.g.",
    "Dorothy in \"The Wizard of Oz\"",
    "It's a blast",
    "Air force?",
    "Sayers portrayed in \"Brian's Song\"",
    "8 on the Beaufort scale",
    "Air force?",
    "Air force?",
    "Kind of force",
    "Big burst",
    "8 on the Beaufort scale",
    "One might make waves",
    "Big blow?",
    "Near-hurricane-force wind",
    "Dorothy ___ of \"The Wizard of Oz\"",
    "Beaufort scale category",
    "Loud outburst",
    "Kind of force",
    "It might make waves",
    "Air force?",
    "Strong wind",
    "Fast-moving wind",
    "Kind of force",
    "Storm wind",
    "Noisy outburst",
    "Kind of force",
    "Big blow",
    "Battering wind",
    "Forceful wind",
    "Nor'easter",
    "Oz visitor Dorothy",
    "Severe blow",
    "Strong draft",
    "Big blow",
    "Kind of force",
    "Easter, e.g., at sea",
    "Heavy blow",
    "Beaufort scale category",
    "Severe blow",
    "Battering wind",
    "Apt family name in \"The Wizard of Oz\"",
    "Kind of force",
    "Flag waver",
    "Tempest",
    "Big blow",
    "A big blow",
    "Peal of laughter",
    "10 on the Beaufort scale"
  ],
  "balk": [
    "Flinch (at)",
    "Illegal pitching motion",
    "Penalized move in baseball",
    "Be uncooperative",
    "Resist",
    "Hesitate",
    "Hesitate",
    "Pitcher's faux pitch",
    "Shy",
    "Baseball no-no",
    "Show reluctance",
    "Pitcher's boo-boo",
    "Illegal diamond delivery",
    "Umpire's call"
  ],
  "troy": [
    "Ancient home to Priam's Treasure",
    "Where Paris took Helen",
    "Home to Paris",
    "Setting for the \"Iliad\"",
    "Where Paris took Helen",
    "Helen of ___ (mythical beauty)",
    "Helen of ___",
    "Ancient city rediscovered in 1870",
    "Weight classification",
    "___ weight",
    "\"Iliad\" locale",
    "Legendary siege site",
    "Movie in which Brad Pitt plays Achilles",
    "Where Hecuba was queen",
    "Ancient city undone by a large wooden horse",
    "Priam's domain",
    "2004 film featuring Paris",
    "\"Iliad\" locale",
    "\"Iliad\" locale",
    "Home of Paris",
    "Ancient siege site",
    "One of Heinrich Schliemann's excavations",
    "\"Iliad\" locale",
    "Where Paris took Helen",
    "Helen's city",
    "City tricked with a wooden horse",
    "\"Iliad\" locale",
    "Where Patroclus met his end",
    "See 44-Down",
    "Its ruins are a Unesco World Heritage Site",
    "2004 Brad Pitt film",
    "2004 Brad Pitt film",
    "\"Iliad\" setting",
    "New York home of Rensselaer Polytechnic Institute",
    "Movie featuring Peter O'Toole as Priam",
    "Legendary siege site",
    "2004 Brad Pitt film",
    "King Priam's home",
    "Home of Paris",
    "\"Iliad\" city",
    "2004 Brad Pitt film",
    "___ weight",
    "Hudson River city",
    "2004 sword-and-sandals flick",
    "2004 Brad Pitt film",
    "?ôIliad?ö locale",
    "Setting for a Homeric epic",
    "Helen's land",
    "Legendary Greek conquest",
    "Kind of weight",
    "City tricked with a wooden horse",
    "City of Paris",
    "\"Troilus and Cressida\" setting",
    "Priam's home",
    "Paris's home, in myth",
    "City on the Hudson",
    "The N.F.L.'s Aikman",
    "Site of Russell Sage College",
    "\"Iliad\" city",
    "Kind of weight",
    "Helen of ___",
    "City to which Helen was abducted",
    "City near Albany",
    "Hecuba's home",
    "Where Helen was abducted to",
    "Kind of weight",
    "Home of the Trojans",
    "\"Aeneid\" locale"
  ],
  "raid": [
    "Enter unannounced, in a way",
    "Pest control product",
    "Midnight trip to the fridge, say",
    "Pirate's activity",
    "Brand with the redundant slogan \"Kills bugs dead\"",
    "Bust",
    "Visit at 2 a.m., say, as a fridge",
    "Bug spray from S.C. Johnson",
    "Apt rhyme for \"invade\"",
    "Antipest spray",
    "SC Johnson product with a lightning bolt in its logo",
    "Unwelcome cry at the front door",
    "Sally",
    "SEAL Team 6 mission",
    "\"Kills bugs dead!\" brand",
    "F.B.I. action",
    "Bust",
    "SC Johnson brand",
    "Police action",
    "A bust may come of it",
    "Early occurrence in \"Some Like It Hot\"",
    "World of Warcraft event",
    "Speakeasy owner's fear",
    "Bust",
    "Bust",
    "Activity in which the police may beat down a door",
    "Surprise attack",
    "Go commando?",
    "Stir at a speakeasy",
    "Police action on a gambling ring",
    "Bust",
    "Attack on the fridge, say",
    "S. C. Johnson brand",
    "Sudden military action",
    "Bust",
    "A.T.F. agents' activity",
    "Antibug spray",
    "Speakeasy's worry",
    "Drug bust, e.g.",
    "Black Flag alternative",
    "Bookie's worry",
    "S.C. Johnson spray",
    "Speakeasy risk",
    "Speakeasy's worry",
    "Many a bust",
    "Police action",
    "Bookie's worry",
    "Police cry",
    "Foray",
    "Bust, of a sort",
    "Crook's comeuppance",
    "Fridge foray",
    "Surprise attack",
    "Police action",
    "Bust",
    "Incursion",
    "S.C. Johnson spray",
    "Speakeasy owner's fear",
    "Warrant follower",
    "Narc's bust",
    "Bad news for a bookie",
    "Narc bust",
    "It's rough on roaches",
    "War party's act",
    "Police cry",
    "Bust, so to speak",
    "All bets are off after this",
    "Commando's outing",
    "Bust, so to speak",
    "Sally",
    "Suprise atack",
    "Foray",
    "Gamblers' fear",
    "S.C. Johnson brand",
    "Assault",
    "Harpers Ferry event",
    "Police action",
    "Foray"
  ],
  "hair": [
    "Beehive material",
    "Bangs on the head?",
    "What locks are made of",
    "Tiny margin of victory",
    "What some buns are made of",
    "Beehive contents",
    "___ metal (1980s music subgenre)",
    "Wig, e.g.",
    "Rapunzel's bounty",
    "It may be put in a bun",
    "Head locks",
    "Something off the top of your head?",
    "Tiny victory margin",
    "Bald person's lack",
    "Eyelash, e.g.",
    "Locks",
    "Locks",
    "Barbershop floor sweepings",
    "Common drain clogger",
    "Musical with the song \"Easy to Be Hard\"",
    "\"Aquarius\" musical",
    "Mop, say",
    "Bun contents",
    "Locks",
    "\"Easy to Be Hard\" musical",
    "It rarely has more than one part",
    "It'll grow on you",
    "Bit of evidence for a sleuth",
    "Beehive contents",
    "\"Flower power\" musical",
    "Locks",
    "Fur",
    "\"Aquarius\" musical",
    "Show subtitled \"The American Tribal Love-Rock Musical\"",
    "Della sells hers in \"The Gift of the Magi\"",
    "*Extremely narrow winning margin",
    "\"Aquarius\" musical",
    "Eyebrow makeup",
    "Do stuff",
    "Tresses",
    "Locks",
    "Rogaine user's desire",
    "Locks",
    "Rapunzel's abundance",
    "It'll grow on you",
    "\"Aquarius\" musical",
    "\"Ain't Got No\" musical",
    "It may be pulled back",
    "Musical with the song \"Frank Mills\"",
    "Barber's focus",
    "Salon sweepings",
    "With 10-Down, bun protectors",
    "Very slim margin",
    "Wig",
    "Hit 1968 musical",
    "It grows on you",
    "Wig, essentially",
    "Salon's concern",
    "It'll grow on you",
    "Locks",
    "It grows on you",
    "Telly Savalas's lack",
    "Salon focus",
    "Slight edge",
    "Shocks",
    "Tresses",
    "Do or dye, perhaps",
    "Locks",
    "Salon concern",
    "Bangs, e.g.",
    "Tresses",
    "\"Easy to Be Hard\" musical",
    "HYDROGEN GAS",
    "\"Let the Sunshine In\" musical",
    "\"Aquarius\" musical"
  ],
  "chew": [
    "Consider, with \"on\"",
    "What a puppy likes to do to toys and socks",
    "___ the fat (gossip, e.g.)",
    "Cogitate (on)",
    "Muse (on)",
    "Not just bite and swallow",
    "Mull (over)",
    "Meditate (on)",
    "Think (over)",
    "Ruminate (on)",
    "Ponder, with \"on\"",
    "Mull (over)",
    "Think (on)",
    "Meditate (on)",
    "Plug",
    "Copenhagen, e.g.",
    "\"___ your food\" (mother's admonition)",
    "Consider, with \"on\"",
    "Meditate (on)",
    "___ toy (pet shop purchase)",
    "Stick of gum, e.g.",
    "Bawl (out)",
    "Not just swallow",
    "Cogitate, with \"on\"",
    "Masticate",
    "Prepare to swallow",
    "Enjoy some gum",
    "Grind with the teeth",
    "Not just swallow whole",
    "Prepare to swallow",
    "Stick of gum, informally",
    "Consider, with \"over\"",
    "Wad",
    "Tobacco wad"
  ],
  "trac": [
    "Gillette razor name",
    "Gillette brand name",
    "___ II (Gillette razor)",
    "___ II razor",
    "Gillette razor name",
    "___ II (Gillette razor)",
    "All-___ (four-wheel-drive system on old Toyotas)",
    "Gillette's ___ II",
    "Gillette brand name",
    "Gillette brand name",
    "Gillette ___ II",
    "Star ___ (big name in exercise machines)",
    "___ II (razor brand)",
    "Gillette's ___ II",
    "Double-bladed ___ II razor",
    "Razor brand word",
    "___ II razor",
    "Gillette's ___ II",
    "___ II razor",
    "Snow-___ (winter vehicle brand)",
    "___ II razor",
    "Control ___ (four-wheel-drive system)",
    "___ II razor",
    "___ II (razor)",
    "___ II (razor brand)",
    "___ II (razor brand)",
    "Sport ___ (Ford Explorer model)",
    "___ II (razor brand)",
    "Ford Explorer Sport ___",
    "Sport___ (Ford Explorer model)",
    "7-Down product: Abbr.",
    "___ II (razor brand)",
    "___ II (razor brand)",
    "___ II razor",
    "___ II (razor brand)",
    "Deere product: Abbr."
  ],
  "rode": [
    "Teased incessantly",
    "Was on",
    "Took transportation",
    "Was a passenger",
    "Got transported",
    "Went by taxi or train",
    "Criticized continuously",
    "Pestered persistently",
    "Teased, teased, teased",
    "Went on horseback",
    "Went as a passenger",
    "Was a passenger",
    "Heckled",
    "Went by motorcycle, say",
    "Was a passenger",
    "Harassed persistently",
    "Went on",
    "Was a passenger",
    "Went in a vehicle",
    "Cycled, say",
    "Didn't go by foot",
    "Used bikes",
    "Traveled by bus",
    "Took a ferry, say",
    "Was conveyed",
    "Harried",
    "Hectored",
    "Went on horseback",
    "Used a bus, e.g.",
    "Hounded",
    "Depended (on)",
    "Teased mercilessly",
    "Hectored",
    "Used cars",
    "Hitchhiked, e.g.",
    "Used to get around",
    "Went on",
    "Was transported",
    "Was a passenger",
    "Took a cab",
    "Was a passenger",
    "Took a tram, e.g.",
    "Took a train, say",
    "Took a taxi",
    "Endured, with \"out\"",
    "Taunted",
    "Went on",
    "Went by bus",
    "Outlasted, with \"out\"",
    "Took public transportation",
    "Was contingent (on)",
    "Went by train",
    "Took transportation",
    "Badgered",
    "Harassed",
    "Teased mercilessly",
    "Went on horseback",
    "Harassed",
    "Harried",
    "Tormented",
    "Heckled",
    "Went by taxi",
    "Badgered",
    "Criticized",
    "Took a taxi",
    "Hectored"
  ],
  "tear": [
    "Streak",
    "Page break?",
    "Zoom",
    "All-out sprint",
    "Eye drop",
    "The apostrophe in :'-(",
    "Bender",
    "Sign of sadness",
    "Race",
    "Rip",
    "Sheds a ___ (24-Down)",
    "Bit of weeping",
    "Drop that might run down the face",
    "Do 80, say",
    "Reason for mending",
    "Drop running down the cheek",
    "Hightail it",
    "Common sports injury",
    "Makeup smearer",
    "Wardrobe malfunction",
    "Painful muscle injury",
    "Sign of sadness",
    "Go like heck",
    "Rip",
    "Material problem",
    "Mad rush",
    "Well up",
    "Career",
    "Careen",
    "Inflatable dinghy concern",
    "Spree",
    "Rip",
    "Drop when one is down?",
    "Drop of melodrama?",
    "Sprint",
    "Run like the wind",
    "Rampage",
    "Tiny bit of crying",
    "Glum drop",
    "Rush",
    "Eye drop?",
    "It might be seen out of the corner of one's eye",
    "Sign of sadness",
    "Jag",
    "Be dashing",
    "Go quickly",
    "Shed thing hidden in 16-, 25-, 39-, 49- and 59-Across",
    "Sail problem",
    "Zoom",
    "Spree",
    "Symbol of wistfulness",
    "Go like hell",
    "Race",
    "Go like heck",
    "Shred",
    "Sign of sorrow",
    "Rip",
    "Spacesuit worry",
    "Eye drop",
    "Spree",
    "Tendon trouble",
    "Eye drop",
    "Go like mad",
    "Zip",
    "Wrench (from)",
    "Hightail it",
    "Wrest",
    "Mar, in a way",
    "Do 80, say",
    "Tendon injury",
    "Eye drop",
    "Salty drop",
    "What a handkerchief may wipe away",
    "Fly",
    "Really run",
    "Make a mad dash",
    "Hotfoot it",
    "Get misty-eyed",
    "Go like mad",
    "Sprint",
    "Drop from the eye",
    "Sign of sorrow",
    "Get moist",
    "Go lickety-split",
    "Something shed",
    "Hightail it",
    "Spree",
    "Patch target",
    "Drop from the eye",
    "Rampage",
    "It's shed",
    "Floor it",
    "Place for a patch",
    "Wrest",
    "Burn up the road",
    "Sympathy evoker",
    "Zip",
    "Fly",
    "Race",
    "Get misty-eyed",
    "Toot",
    "Shed item",
    "Zip",
    "Wrest (from)",
    "Spree",
    "Smudge on a Dear John letter?",
    "Career",
    "Rupture",
    "Shed item",
    "Sign of weeping",
    "Zoom",
    "Rip",
    "Eye drop",
    "Go like lightning",
    "Hightail it",
    "Certain drop",
    "Get dewy-eyed",
    "Eye drop",
    "Salty drop",
    "Flurry",
    "Rip",
    "Sympathy evoker",
    "Shred",
    "Well up",
    "It may be taped",
    "Sign of pathos",
    "Salt deposit?",
    "Rend",
    "Peel (off)",
    "Bender",
    "Bit of saltwater?",
    "Binge",
    "Speed"
  ],
  "alli": [
    "\"That's ___ need\"",
    "\"For ___ care ...\"",
    "\"That's ___ ask\"",
    "\"___ Do Is Dream of You\"",
    "Sheryl Crow's \"___ Wanna Do\"",
    "\"___ Have\" (#1 Jennifer Lopez hit)",
    "\"___ Have to Do Is Dream\"",
    "\"___ Have\" (Jennifer Lopez #1 hit)",
    "\"___ Want for Christmas\"",
    "\"And ___ ask is a tall ship ...\": John Masefield",
    "\"That's ___ need!\"",
    "\"___ want for Christmas ?à\"",
    "\"___ want for Christmas ...\"",
    "Sheryl Crow's \"___ Wanna Do\"",
    "\"... for ___ care!\"",
    "\"___ want for Christmas ...\"",
    "\"That's ___ ask!\"",
    "\"___ Need\" (1985 hit)",
    "\"___ Desire\" (1953 Barbara Stanwyck film)",
    "\"___ Need\" (1985 Jack Wagner hit)",
    "\"___ Want for Christmas\"",
    "\"___ Do Is Dream of You\" (1934 hit)",
    "\"___ Desire\" (1953 Barbara Stanwyck movie)",
    "\"___ Wanna Do\" (Sheryl Crow hit)",
    "\"___ Have to Do Is Dream\"",
    "Sheryl Crow's \"___ Wanna Do\"",
    "\"For___know...\"",
    "\"___ want for Christmas...\"",
    "Cher's \"___ Really Want to Do\"",
    "\"___ Need Is the Girl\" (\"Gypsy\" song)",
    "1934 hit \"___ Do Is Dream of You\"",
    "\"___ Ever Need Is You\"",
    "1973 hit \"___ Know\"",
    "\"___ know is what...\""
  ],
  "dawn": [
    "Start",
    "Common duel setting",
    "Starting time for an attack",
    "Occur to, with \"on\"",
    "When crepuscular animals are active",
    "Beginning",
    "Sunrise",
    "Curfew for a vampire",
    "Beginning",
    "Vampire's bedtime",
    "Sunup",
    "Beginning",
    "Setting of the painting \"Washington Crossing the Delaware\"",
    "Start time for many a military mission",
    "*Beginning",
    "Common time to start on a trip",
    "Time for crowing",
    "Eos' domain",
    "Sunrise",
    "It begins as a crack",
    "Sunrise",
    "Arise",
    "Time to crow",
    "Sunup",
    "First light",
    "Time to crow",
    "It breaks every day",
    "Start of a new day",
    "Singing duo of TV",
    "Sunup",
    "Homer's was \"rosy-fingered\""
  ],
  "wore": [
    "Rocked, so to speak",
    "Abraded",
    "Had on",
    "Had on",
    "Sported",
    "Exhausted, with \"out\"",
    "Came out in",
    "Abraded",
    "Tired (out)",
    "Had on",
    "Put on",
    "Became threadbare",
    "Had on",
    "Sported, as a sports jacket",
    "Eroded (away)",
    "Dragged (on)",
    "Sported",
    "Put on",
    "Sported",
    "Abraded",
    "Tediously went (on)",
    "Had on",
    "Eroded",
    "Modeled",
    "Sported",
    "Sported",
    "Had on",
    "Abraded",
    "Eroded",
    "Had on",
    "Sported",
    "Sported",
    "Modeled",
    "Sported",
    "Became tattered",
    "Disappeared, with \"off\"",
    "Modeled",
    "Had on",
    "Sported",
    "Got threadbare",
    "Sported",
    "Was covered by",
    "Sported",
    "Abraded",
    "Had on",
    "Exhausted, with \"out\"",
    "Had on",
    "Eroded",
    "Sported",
    "Had on"
  ],
  "beef": [
    "Bone to pick",
    "Chuck, e.g.",
    "Bellyache",
    "Complaint",
    "Argument",
    "Major Argentine export",
    "Meat in a burger",
    "Bit of food ... or feud?",
    "Grievance",
    "Gripe",
    "Bellyache",
    "Complaint",
    "Gripe",
    "Kobe ___",
    "Hamburger meat",
    "Bellyache",
    "Bone to pick",
    "Kick",
    "Bone to pick",
    "Steak or ground round",
    "Grouse",
    "Complaint",
    "Burrito filler",
    "Carpaccio base",
    "Grievance",
    "Kick",
    "Complaint",
    "Protest",
    "Complaint",
    "Complain",
    "Filling for a taco"
  ],
  "says": [
    "\"Who ___?\"",
    "Simon ___",
    "Utters",
    "What Simon does",
    "\"Goes\"",
    "\"Goes\"",
    "\"Who ___?\"",
    "Simon ___",
    "Verbalizes",
    "\"___ you!\"",
    "\"___ who?\"",
    "States",
    "Comes out with",
    "Utters",
    "\"___ who?\"",
    "With 12-Down, turns down",
    "Simon ___",
    "Comes out with",
    "Reveals",
    "Declares",
    "Hazards a guess",
    "\"___ who?\"",
    "Simon ___",
    "Opines",
    "Word after \"he\" and \"she\"",
    "Utters",
    "\"___ who?\"",
    "Simon ___",
    "Makes known",
    "Simon ___",
    "Discloses",
    "Pronounces",
    "Comes out with",
    "Utters",
    "Articulates",
    "States",
    "\"___who?\"",
    "Pronounces",
    "Asserts"
  ],
  "srta": [
    "Quinceañera celebrant: Abbr.",
    "Spanish title: Abbr.",
    "Abbreviated title without the second, third, fourth and sixth letters",
    "Mlle., across the Pyrenees",
    "Mlle. : France :: ___ : Spain",
    "Sp. title",
    "Mlle., across the Pyrenees",
    "Mex. title that's an anagram of 58-Down",
    "Spanish title: Abbr.",
    "Dora the Explorer, e.g.: Abbr.",
    "Latina miss: Abbr.",
    "Mlle., in Managua",
    "Sp. miss",
    "Mlle., over the Pyrenees",
    "Mex. miss",
    "Dora the Explorer, for one: Abbr.",
    "Miss, abroad: Abbr.",
    "Latina title: Abbr.",
    "Mex. miss",
    "Tia's title, perhaps: Abbr.",
    "Spanish miss: Abbr.",
    "Majorca miss: Abbr.",
    "Mex. miss",
    "Mex. miss",
    "Overseas deb: Abbr.",
    "Latina lass: Abbr.",
    "Mlle., in Madrid",
    "Port. title",
    "Mex. miss",
    "Sp. title",
    "Mexican miss: Abbr.",
    "Mex. miss",
    "Mex. miss",
    "Miss Spain, say: Abbr.",
    "Mlle., in Mexico",
    "Lassie of Arg.",
    "Nina's title: Abbr.",
    "Miss hitting a pinata?: Abbr.",
    "Sp. miss",
    "Mlle., across the Pyrenees",
    "Sp. miss",
    "Muchacha: Abbr.",
    "Spanish counterpart of a mlle.",
    "Mex. miss",
    "Miss, south of the border: Abbr.",
    "Madrid Mlle.",
    "Mex. miss",
    "Miss from Mex.",
    "Mex. title",
    "Miss in Monterrey: Abbr.",
    "Mex. miss",
    "Sp. title",
    "Mex. miss",
    "Mex. miss",
    "Sp. girl",
    "Abbr. on an envelope to Mexico",
    "Mlle., in Madrid",
    "Spanish Mlle.",
    "Title for Miss Spain?: Abbr.",
    "Single mujer: Abbr.",
    "Abbr. on Mexican mail",
    "Mex. miss",
    "Mlle. from Acapulco",
    "Sp. title",
    "One available in Mex.",
    "Mex. title",
    "Sp. miss",
    "Miss Mexico, e.g.: Abbr.",
    "Sp. miss",
    "Fem. in una casa",
    "Young lady of Sp.",
    "Mexican Mlle.",
    "Miss, south of the border",
    "Spanish girl: Abbr.",
    "Sp. title",
    "Miss abroad: Abbr.",
    "Mex. title",
    "Title for a young Madrilena: Abbr.",
    "Mlle., in Manresa",
    "Miss overseas: Abbr.",
    "Little miss, in a mision",
    "Cousin of Mlle.",
    "Mlle., in Spain",
    "Mlle., in Marbella",
    "Mlle., in Mallorca",
    "Mlle. across the Pyrenees",
    "Sp. title",
    "Abbr. on a letter to Spain",
    "Madrid miss: Abbr.",
    "Mex. miss",
    "Counterpart of a Mlle.",
    "Mlle. from Manzanares",
    "Mlle.'s neighbor",
    "Mlle.'s pen pal, maybe",
    "Mlle., in Spain",
    "Cousin of a mlle.",
    "Mlle., across the Pyrenees"
  ],
  "ewan": [
    "Actor McGregor of \"Trainspotting\"",
    "Actor McGregor",
    "Actor McGregor",
    "McGregor of the \"Star Wars\" prequels",
    "McGregor who played Obi-Wan Kenobi",
    "Actor McGregor",
    "\"Trainspotting\" actor McGregor",
    "Actor McGregor",
    "McGregor who played a young Obi-Wan",
    "McGregor of \"Big Fish\"",
    "Actor McGregor",
    "Actor McGregor",
    "Singer/songwriter MacColl",
    "McGregor of \"Star Wars\" films",
    "Actor McGregor",
    "Actor McGregor",
    "McGregor of the \"Star Wars\" films",
    "Actor McGregor",
    "Actor McGregor",
    "Actor McGregor",
    "McGregor of \"Star Wars\" films",
    "Actor McGregor",
    "McGregor of film",
    "Actor McGregor",
    "Actor McGregor",
    "McGregor of \"Down With Love\"",
    "Obi-Wan portrayer, 1999",
    "McGregor of \"The Phantom Menace\"",
    "Actor McGregor",
    "Actor McGregor",
    "McGregor of \"Trainspotting\"",
    "McGregor of movies",
    "McGregor of \"Trainspotting\""
  ],
  "rian": ["\"Knives Out\" director Johnson", "Director Johnson"],
  "hoop": [
    "Court target",
    "Certain earring",
    "Hula ___",
    "Circular earring",
    "Circular kind of earring",
    "Free throw target",
    "Piece of equipment for 29-Down",
    "Certain earring",
    "Its diameter is roughly twice that of a basketball",
    "Earring shape",
    "Basket",
    "Basketball target",
    "Basketball rim",
    "Circus trainer's prop",
    "Hula ___",
    "Basketball backboard attachment",
    "Earring type",
    "Earring shape",
    "Score for a 34-Across player",
    "Bucket",
    "Earring style",
    "Basketball ___",
    "Cager's target",
    "Showy earring",
    "\"___ Dreams\" (1994 documentary film)",
    "Wicket",
    "Hula-___"
  ],
  "nler": [
    "Pirate or Padre, for short",
    "Cubbie or Card",
    "Met or Card",
    "Pirate, e.g., for short",
    "Cubbie, e.g.",
    "Red, e.g., for short",
    "D-back or Card",
    "D-back, e.g.",
    "Member of the Brew Crew, e.g.",
    "Pirate or Padre, for short",
    "Met, for one",
    "Red or Brave, for short",
    "Giant, e.g., briefly",
    "Dodger or Met, for short",
    "Met or Card",
    "Card, e.g.",
    "D-back, for one",
    "Pirate or Padre, briefly",
    "Red or Card",
    "Giant or D'back",
    "Pgh. Pirate, e.g.",
    "What an A is not",
    "Phillie or Card",
    "Card or Met",
    "Any of the Phillies, e.g.",
    "Cub or Red, briefly",
    "Brave, for instance",
    "Expo or Card, e.g.",
    "Red, for one",
    "D'back, e.g.",
    "63-Across, for short",
    "Red or Card",
    "Met or Card, for short",
    "Card, for one",
    "N.Y. Met, e.g.",
    "N.Y. Met, e.g.",
    "Mark McGwire, e.g.",
    "N.Y. Met or L.A. Dodger, e.g.",
    "Red or Card, for short"
  ],
  "hint": [
    "Bit of help",
    "Bit of help in an escape room",
    "Clue for the clueless",
    "Very small amount",
    "Clue",
    "60-Across, to 34-Across, e.g.",
    "This answer ends in \"T,\" e.g.",
    "Soupcon",
    "Help for the puzzled",
    "When repeated, a subtle (or sometimes not-so-subtle) comment",
    "Help for the flummoxed",
    "Bit to go on?",
    "Nudge",
    "Bit of color",
    "Help for a guesser",
    "What [wink wink] may signify",
    "Whiff",
    "Clue",
    "Clue",
    "Spill a little?",
    "Pointer",
    "\"This answer ends in a T,\" e.g.",
    "Subtle flavor",
    "Comment from over the shoulder, maybe",
    "\"The first word of the answer to each of the six starred clues describes the number of that clue,\" e.g.",
    "\"Warmer\" or \"colder\"",
    "Not say directly",
    "Help for the stumped",
    "Touch",
    "Charades gesture, e.g.",
    "Aid to the stumped",
    "Request from the stumped",
    "Heloise offering",
    "Help for the stumped",
    "Clue",
    "Suggestion",
    "Helpful ___",
    "Soupcon",
    "Act suggestively",
    "\"Warm\" or \"cold\"",
    "Clue",
    "Whisper",
    "Word to the wise",
    "Help for the puzzled",
    "Suggest",
    "Help for the stumped",
    "Soupcon",
    "Slightest amount",
    "Kibitzer's bit",
    "Trace"
  ],
  "uvea": [
    "Iris holder",
    "Pigmented part of the eye",
    "Unit of the eye containing the iris",
    "Eye part",
    "Iris's place in the eye",
    "Part of the eye",
    "Colored part",
    "Pacific island that's also the name of part of the body",
    "Eye layer",
    "Colored layer",
    "Eye part",
    "Layer below the sclera",
    "Colored part of the eye",
    "Iris's place",
    "Iris's place in the eye",
    "Eye part with the iris",
    "Layer of the 42-Down",
    "Pigmented layer",
    "Eye layer whose name derives from the Latin for \"grape\"",
    "Part of the eye",
    "Place for an iris",
    "Iris holder",
    "Eye part",
    "Iris's location",
    "Layer of the eye",
    "Part of the face whose name is derived from the Latin for \"grape\"",
    "Eye part",
    "Eye part",
    "Eye layer",
    "Iris holder",
    "Place for an iris",
    "Eye area",
    "Ciliary body setting",
    "Sclera neighbor",
    "Optic layer",
    "Ciliary body locale",
    "Eye layer",
    "Anatomical part named after the Latin for \"grape\"",
    "Eye part",
    "It surrounds a lens",
    "Iris's place",
    "Part of the eye that holds the iris",
    "Eye part",
    "Anatomical part whose name comes from the Latin for \"grape\"",
    "Part of the eye",
    "Iris's place",
    "Eye part",
    "Ophthalmologic study",
    "Iris holder",
    "Iris container",
    "Eye part",
    "48-Down holder",
    "Iris's place",
    "Iris's place",
    "Eye layer",
    "Part of the eye containing the iris",
    "Eye part",
    "Pupil's place",
    "Iris's place",
    "Pupil's setting",
    "Holder of a 101-Across",
    "Part of the eye",
    "Iris's place",
    "Iris holder",
    "Iris's locale",
    "Ophthalmological study",
    "Choroid coat location",
    "Part of the eye",
    "Eye part",
    "Eye part",
    "Colored part",
    "Part of the eye",
    "Iris's place",
    "Part of the eye",
    "Eye layer",
    "Eye part",
    "Part of the eye",
    "Part of the eye",
    "Part of the eye",
    "Iris's place",
    "Iris's place",
    "Eye layer",
    "Eye part"
  ],
  "barn": [
    "Setting for a hootenanny",
    "Hoedown locale",
    "Place to count sheep?",
    "Farm building with a loft",
    "Farm building",
    "Hay there!",
    "Building for bovines",
    "Traditionally red structure",
    "Old-fashioned dance venue",
    "Square dance site",
    "Stable environment?",
    "Square dance venue",
    "Pottery ___",
    "Place for a hoedown",
    "Place for an owl",
    "Where to take stock?",
    "Animal house",
    "Stock holder",
    "One raised in Amish country",
    "Home for an owl, maybe",
    "Stock keeper",
    "Loft's locale",
    "Place for a dance",
    "One raised on a farm",
    "Web site for Charlotte",
    "1-Down building",
    "Where the cows come home",
    "*Makeshift hangar",
    "Place for a hoedown",
    "One raised on a farm",
    "Hoedown site",
    "Building with a loft",
    "___-raising",
    "Building near a silo",
    "Hay there!",
    "Stalled construction",
    "Country dance locale",
    "Owl's home, maybe",
    "Hay there",
    "Country dance spot",
    "Old-fashioned dance locale",
    "Owl's hangout",
    "Source of some lows",
    "Building near a silo",
    "Shade of red",
    "Dance setting, perhaps",
    "It has a broad side",
    "Kind of dance",
    "Milking area",
    "Charlotte's web site",
    "Owl's hangout",
    "Farm building",
    "Kind of dance",
    "Farm building",
    "Summer theater, sometimes",
    "Old dance site",
    "Stable home",
    "Country ballroom?"
  ],
  "woot": [
    "\"Yippee!\"",
    "Informal cheer",
    "\"Yay!\"",
    "Joyful internet cry",
    "\"Wow!,\" in Internet-speak",
    "\"Yay!,\" in a text message"
  ],
  "gals": [
    "Some do-si-do partners",
    "Some square dance participants",
    "The ___ (gang for a ladies' night out)",
    "Half of square dance participants, typically",
    "Ben-___ (N.F.L. cheerleading squad)",
    "Guys' partners",
    "Some square dancers",
    "Guys' counterpart",
    "Dames",
    "Hoedown females",
    "Some square dancers",
    "Some dates",
    "Misses",
    "Guys' mates",
    "Hoedown participants",
    "Guys' dates",
    "Square dance partners",
    "Guys' pals",
    "Molls and dolls",
    "Women, casually",
    "Misses",
    "Misses",
    "Some club members, informally",
    "Some square dancers",
    "Guys' partners",
    "Guys' dates",
    "\"Buffalo ___\" (1844 song)",
    "Women, condescendingly",
    "Square dance partners",
    "\"Buffalo ___\" (old song)",
    "Rodeo women",
    "\"Chicks\"",
    "\"Buffalo ___\" (old song favorite)"
  ],
  "otos": [
    "Plains tribe members",
    "Plains people",
    "Some Siouans",
    "Plains tribe members",
    "Plains tribe",
    "Some Siouans",
    "Midwest tribe",
    "Relatives of Winnebagos",
    "Plains Indians",
    "Oklahoma tribesmen",
    "Little Thief's people",
    "Buffalo-hunting Indians",
    "Chief Whitehorse and tribesmen",
    "Siouan people",
    "Western tribe",
    "Plains tribe",
    "Platte River people",
    "Plains Indians",
    "People of the Platte, once",
    "Siouan speakers",
    "Platte River tribe",
    "Dwellers along the Platte, once",
    "Siouan people",
    "Western tribe",
    "Natives of Noble County, Okla.",
    "Former Platte River dwellers",
    "Early Nebraskans",
    "People of the Platte",
    "Platte River people",
    "Part of the Winnebago nation",
    "Siouan people",
    "People on the Platte",
    "Platte River tribe",
    "Platte River people",
    "Oklahoma tribe",
    "Nebraska natives",
    "Former Platte River inhabitants",
    "Platte River tribe",
    "Native Oklahomans",
    "Western Indians",
    "Western Indians",
    "Siouan Indians"
  ],
  "c3po": ["Sci-fi sidekick ... or a hint to 42- and 53-Across"],
  "abbr": [
    "E.g., e.g.",
    "Shortening, for short",
    "Dict. tag",
    "Ph.D., for one",
    "Shortened word, for short",
    "Mon., for Monday",
    "Dem. or Rep., e.g.",
    "Rep. or Dem., e.g.",
    "Cal. or Calif., for California",
    "OH or HI",
    "Self-descriptive crossword answer",
    "E.g., e.g.",
    "Answer that's an example of itself",
    "Lb. or oz., e.g.",
    "R.B.I. or E.R.A., e.g.",
    "E.g., e.g.",
    "39-Down, e.g.",
    "Certain dict. entry",
    "Answer that's an example of itself",
    "Shortened form, in shortened form",
    "This answer, for instance",
    "Tues., for Tuesday",
    "A.M. or P.M., e.g.",
    "This ans., e.g."
  ],
  "bury": [
    "Suppress, as a negative story",
    "Place underground",
    "Submerge",
    "Securely hide",
    "Hide",
    "Put six feet under",
    "Lay in a grave",
    "Completely repress",
    "Put under?",
    "Conceal",
    "Hide well",
    "Hide well",
    "Repress in memory"
  ],
  "darn": [
    "\"Rats!\"",
    "\"Phooey!\"",
    "\"Nuts!\"",
    "Mend, in a way",
    "\"Nuts!\"",
    "\"Aw rats!\"",
    "\"Shucks!\"",
    "\"Shoot!\"",
    "Mend, as socks",
    "\"Nuts!\"",
    "\"Shoot!\"",
    "Mend, as socks",
    "\"Nuts!\"",
    "Alternative to nuts?",
    "\"Geez!\"",
    "Timid swearword",
    "\"Phooey!\"",
    "\"Drat!\"",
    "\"Phooey!\"",
    "Tend to a hole",
    "\"Drat!\"",
    "Patch up",
    "\"Phooey!\"",
    "\"Rats!\"",
    "*\"Rats!\"",
    "Not-so-strong oath",
    "\"Rats!\"",
    "Doggone",
    "\"Nuts!\"",
    "Put in stitches",
    "\"Rats!\"",
    "\"Rats!\"",
    "\"Shoot!\"",
    "Get rid of holes",
    "Seamstress' cry upon making a mistake?",
    "Sew up",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "\"Shucks!\"",
    "Mend, in a way",
    "Mend",
    "Mend",
    "Put in stitches",
    "\"Fudge!\"",
    "\"Nuts!\"",
    "Extremely, informally",
    "\"Oh, crumb!\"",
    "\"Shucks!\"",
    "\"Oh, heck!\"",
    "Put in stitches",
    "Mend",
    "Fix, in a way",
    "Save, as socks",
    "\"Nuts!\"",
    "\"Rats!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Rats!\"",
    "Mend"
  ],
  "leoi": [
    "First of 13 popes",
    "Sainted fifth-century pope who met with Attila the Hun",
    "First pope to be called \"the Great\"",
    "Hilarius succeeded him in A.D. 461",
    "Fifth-century pope known as \"the Great\"",
    "Fifth-century pope known as \"the Great\"",
    "Sainted pope after Sixtus III",
    "First of a succession of 13",
    "Predecessor of Pope Hilarius",
    "First pope to be called \"the Great\"",
    "First pope with the title \"the Great\"",
    "Earliest pope to receive the title \"the Great\"",
    "Sainted fifth-century pope",
    "First in a string of 13 popes",
    "Fifth-century pope called \"the Great\"",
    "First pope called \"the Great\"",
    "Sainted fifth-century pope",
    "He was succeeded by his archdeacon Hilarius",
    "Pope from 440 to 461",
    "Sainted pope called \"the Great\"",
    "First of a string of 13",
    "Pope from 440 to 461",
    "Sainted fifth-century pope",
    "Saint known as \"the Great\"",
    "Sainted pope called \"the Great\"",
    "Fifth-century pope who was sainted",
    "Fifth-century pope who was sainted",
    "Pope who persuaded Attila not to attack Rome",
    "Start of a string of 13 Popes",
    "Pope called \"The Great\""
  ],
  "next": [
    "Coming right up",
    "\"Step right up!\"",
    "On deck",
    "Deli counter call",
    "Deli cry",
    "Barbershop call",
    "On deck",
    "Call from behind a counter",
    "First in line",
    "Call at a deli or barbershop",
    "Call from a counter",
    "Almost up",
    "Following",
    "Call from behind the deli counter",
    "Queue word?",
    "Cry at a deli counter",
    "Start of many a TV teaser",
    "Call at a deli counter",
    "Cry at a deli",
    "Queue cue",
    "With \"in\" and 41-Down, heir to the throne",
    "Service call?",
    "\"What ___?\"",
    "\"What ___?\"",
    "Cashier's cry",
    "Company founded by 57-Across",
    "On deck",
    "Soon to be up",
    "Barbershop call",
    "\"Step right up!\"",
    "Deli queue call",
    "Announcement for the head of a queue",
    "Cry at a doctor's office",
    "Barber's call",
    "Cry at a motor vehicle bureau",
    "Barber's call",
    "Boy ___ door",
    "What one at the head of a line likes to hear",
    "Call in a bakery",
    "Call at a deli",
    "Counter call",
    "Queue cue",
    "Barber shop call",
    "On deck",
    "Leading the queue",
    "Call in a bakery",
    "Waiting room call",
    "Vaccinator's call",
    "Casting director's dismissal",
    "Barbershop call",
    "At the front of the line",
    "Cashier's cry",
    "Waiting room call",
    "Shout at an open call",
    "Cry at a bakery",
    "Custom officer's cry",
    "Shout to waiters",
    "Doctor's office call",
    "Right after",
    "Barbershop call",
    "Barber's cry",
    "Cry at the doctor's office",
    "Following",
    "Succeeding",
    "Cry to the queue",
    "Following",
    "Waiting room call",
    "Bakery call",
    "Barber's cry",
    "Waiting room word",
    "Barber's call",
    "Contiguous",
    "Almost up",
    "Deli cry"
  ],
  "lice": [
    "Problems that come to a head?",
    "Little suckers",
    "Parasitic insects that suck",
    "Head residents?",
    "Subject of a school nurse's inspection",
    "Pet peeves?",
    "Canine woe",
    "Big nits",
    "Itchy dog's woe",
    "Dog pests",
    "Some ectoparasites",
    "Cause of head-scratching, perhaps",
    "Pet problem",
    "Woe for Fido",
    "Some creepy-crawlies",
    "Head case?",
    "Woe for Fido",
    "Nits, eventually",
    "Wingless parasites",
    "Vermin",
    "Some ectozoa",
    "Dog problem",
    "Head hunters' targets",
    "Small suckers",
    "Case for a vet",
    "Parasites",
    "Head residents?"
  ],
  "giza": [
    "Egypt's pyramids of ___",
    "Egyptian tourist spot",
    "Great Pyramid locale",
    "Great Sphinx locale",
    "Pyramid city close to Cairo",
    "Suburb of Cairo",
    "Egyptian pyramid city",
    "City with the Great Sphinx",
    "Egypt's third-largest city",
    "City on the Nile",
    "Egyptian pyramids locale",
    "Cairo suburb",
    "Sphinx site",
    "City near the Sphinx",
    "Sphinx site",
    "Sphinx site"
  ],
  "anin": [
    "Have ___ (be well connected)",
    "Have ___ with",
    "Have ___ with",
    "Has ___ (is connected)",
    "Have ___ (be advantageously networked)",
    "Have ___ with",
    "Have ___ with",
    "Have ___ (be connected)",
    "Have ___ (be connected)",
    "Have ___ with",
    "Have ___ with (know well)",
    "Have ___ with",
    "Have ___ with",
    "Have ___ with",
    "Have ___ (be connected)",
    "Has ___ with",
    "What the connected may have",
    "Has ___ with",
    "Has ___ with",
    "Has ___ (is connected)",
    "Have ___ (know someone)",
    "Have ___ (be connected)",
    "Has ___ (knows somebody)",
    "Has ___ with",
    "Have ___ with"
  ],
  "hahn": [
    "Actress Kathryn of \"WandaVision\"",
    "Chemistry Nobelist Otto",
    "Chemistry Nobelist Otto",
    "Chemistry Nobelist Otto",
    "Violin virtuoso Hilary",
    "Chemistry Nobelist Otto",
    "1944 Chemistry Nobelist Otto",
    "Chemistry Nobelist Otto",
    "Grammy-winning violinist Hilary",
    "Otto who helped discover nuclear fission",
    "Riordan's successor as Los Angeles mayor",
    "Nuclear fission Nobelist Otto",
    "Nuclear fission discoverer Otto",
    "See 58-Down",
    "Chemistry Nobelist Otto",
    "1944 Chemistry Nobelist",
    "See 70-Down",
    "Nuclear fission discoverer"
  ],
  "bond": [
    "James who likes his martinis \"shaken, not stirred\"",
    7,
    "James of Her Majesty's Secret Service",
    "Word spoken before and after \"James\"",
    "My word, maybe",
    "James ___ (007)",
    "What the answers to the starred clues share, in two ways",
    "\"GoldenEye\" spy",
    "Ornithologist James of whom Ian Fleming was a fan",
    "What atoms may have",
    7,
    7,
    "M asset",
    "Name said before and after James",
    "What atoms do",
    "Agent with many girls",
    "Source of interest",
    "It both precedes and follows James",
    7,
    "School funder, often",
    "Employee of M",
    "Link",
    "No nemesis",
    7,
    7,
    7,
    "Dr. No foe",
    7,
    "Get-out-of-jail money",
    7,
    "Link",
    "Covenant",
    7,
    7,
    "Investor's purchase"
  ],
  "paar": [
    "Jack who pioneered late-night talk",
    "Jack who once hosted \"The Tonight Show\"",
    "Early talk show host Jack",
    "1950s-'60s TV emcee Jack",
    "Carson's predecessor on \"The Tonight Show\"",
    "Allen's replacement on \"The Tonight Show\"",
    "Jack who was quick with a quip",
    "Jack of the old \"Tonight Show\"",
    "Late-night host before Carson",
    "Jack in the box, once?",
    "Allen's successor on late-night TV",
    "Onetime host of CBS's \"The Morning Show\"",
    "Host of 1950s TV's \"Bank on the Stars\"",
    "\"Up to ___\" (1952 game show)",
    "Carson's predecessor",
    "Onetime host of \"The Tonight Show\"",
    "Onetime name in late-night TV",
    "\"Up to ___,\" 1952 game show",
    "Carson predecessor",
    "Subject of the documentary \"Smart Television\"",
    "1950s-'60s NBC host",
    "Jack who once hosted \"The Tonight Show\"",
    "Carson's predecessor",
    "See 6-Across",
    "Host who said \"I kid you not\"",
    "1950s-'70s TV host",
    "Jack who quipped \"A funny thing happened to my mother one day: Me\"",
    "Jack who pioneered late-night talk",
    "Jack of early late-night TV",
    "Carson's predecessor",
    "Carson predecessor",
    "Onetime host of \"The Morning Show\" and \"The Tonight Show\"",
    "Early late-night name",
    "Punnily titled 1952 quiz show \"Up to ___\"",
    "Hugh Downs was his announcer",
    "Old late-night host",
    "Carson's predecessor on \"The Tonight Show\"",
    "Subject of the documentary \"As I Was Saying\"",
    "\"I kid you not\" speaker",
    "Jack of old TV",
    "Carson predecessor",
    "Early late-night name",
    "TV host with the instrumental theme \"I-M-4-U\"",
    "Carson's predecessor",
    "Host before Carson",
    "1952 quiz show \"Up to ___\"",
    "TV's Jack",
    "Carson's predecessor",
    "Early late-night host",
    "Bygone TV host",
    "1950's-60's NBC star",
    "He quarreled with Winchell",
    "Old TV host Jack",
    "Late-night pioneer",
    "Punnily titled 1952 quiz show \"Up to ___\"",
    "Pioneer talk show host",
    "Onetime late-night host Jack",
    "\"I Kid You Not\" autobiographer",
    "Carson's predecessor at NBC",
    "Jack of 50's-60's TV",
    "Carson's predecessor",
    "Old TV host Jack",
    "60's TV host",
    "Comic Jack",
    "Carson predecessor",
    "Famous name in TV talk"
  ],
  "roxy": [
    "Classic theater name",
    "Common theater name",
    "Classic theater name",
    "New York City theater where CinemaScope debuted",
    "Theater name",
    "Rock's ___ Music",
    "Classic theater name",
    "Classic theater name",
    "Classic theater name",
    "Classic theater name",
    "Classic theater name",
    "Classic theater name",
    "Classic Manhattan theater",
    "___ Music",
    "Classic theater name",
    "Classic theater name",
    "___ Music of 1970's-80's rock",
    "Classic theater name",
    "Former Big Apple theater showplace",
    "Classic theater name",
    "Classic theater name",
    "Common theater name",
    "Classic theater name",
    "Popular theater name",
    "Classic theater name"
  ],
  "veer": [
    "Careen",
    "Swerve (into)",
    "Depart from a straight line",
    "Sudden shift",
    "Turn sharply",
    "Turn sharply",
    "Swerve",
    "Zig or zag",
    "Not stay the course?",
    "Zig or zag",
    "Really go out of one's way?",
    "Go off course",
    "Zig or zag",
    "Take a turn",
    "Go off course",
    "Swerve",
    "Swerve",
    "Zig or zag",
    "Fork (off)",
    "Shift direction",
    "Tack",
    "Zig or zag",
    "Swerve",
    "Swerve",
    "Zig or zag",
    "Zig or zag",
    "Zig or zag",
    "Go off course",
    "Recourse?",
    "Zig or zag",
    "Turn off",
    "Angle (off)",
    "Deviate",
    "Suddenly change course",
    "Zig or zag",
    "Turn",
    "Zig or zag",
    "Zig",
    "Go off in a new direction",
    "Yaw",
    "Swerve",
    "Swerve",
    "Drive out of one's lane",
    "Tack, in a way",
    "Shift course",
    "Change course",
    "Change course",
    "Swerve",
    "Turn sharply"
  ],
  "yesm": [
    "Quaint affirmative",
    "Deferential affirmative",
    "Polite reply to Aunt Polly",
    "Quaint agreement",
    "Quaint affirmative",
    "Polite rural reply",
    "\"O.K.\" from Tom Sawyer",
    "Polite rural affirmative",
    "Backwoods agreement",
    "\"O.K.\" from Huck Finn",
    "Polite reply from a ranch hand",
    "Polite, old-fashioned assent",
    "Polite response from a ranch hand",
    "Backwoods assent",
    "Polite reply from a ranch hand",
    "Positive response to Aunt Polly"
  ],
  "ramp": [
    "A.D.A.-compliant entrance feature",
    "A.D.A.-compliance option",
    "Skatepark feature",
    "Increase, with \"up\"",
    "Ratchet (up)",
    "Aid for getting a boat in the water",
    "Way off, say",
    "Aid to accessibility",
    "Intensify, with \"up\"",
    "Equipment for a motorcycle jump",
    "Skateboarder's incline",
    "Inclination",
    "Help for motorcycle daredevils",
    "Artery connection",
    "Cloverleaf section",
    "X Games fixture",
    "Ratchet (up)",
    "Intensify, with \"up\"",
    "Skateboard park feature",
    "Up, with \"up\"",
    "Multistory parking garage feature",
    "Way up",
    "Interstate entrance or exit",
    "Highway exit",
    "Stair alternative",
    "Increase quickly, with \"up\"",
    "Highway access",
    "Alternative to stairs",
    "See 24-Across",
    "Freeway exit",
    "Entrance to an expressway",
    "Interstate exit",
    "Turnpike turnoff",
    "Exit, often",
    "Car ferry need",
    "Inclined path for wheelchairs",
    "Way up or down",
    "Interstate entrance/exit",
    "Alternative to steps",
    "Wheelchair-accessible route",
    "Frequent means of entry",
    "Way on or off a highway",
    "Wheelchair access",
    "Turnpike access",
    "Turnpike turnoff",
    "Wheelchair access",
    "Alternative to steps",
    "Freeway access",
    "Part of a loading zone, maybe",
    "Way off the highway",
    "Highway exit",
    "Way off",
    "Water-skier's apparatus",
    "Airport feature",
    "Garage feature",
    "Right for the disabled",
    "Freeway exit",
    "Expressway access",
    "Highway entrance",
    "Airplane exit",
    "Van adjunct",
    "Thruway entrance",
    "Stadium walkway",
    "Highway exit",
    "Water-skier's aid",
    "Steps bypass",
    "Gradient",
    "Mover's equipment",
    "Incline",
    "Access way",
    "Way on or off"
  ],
  "type": [
    "\"Sorry, you're just not my ___\"",
    "Classify, as blood",
    "Romantic preference",
    "Genre",
    "Kind",
    "Kind",
    "eHarmony info",
    "Write using a keyboard",
    "It used to be made of lead",
    "Sort",
    "Variety",
    "Genre",
    "Info for a dating site",
    "Info for a dating profile",
    "Redheads or book lovers, maybe",
    "Class",
    "Do data entry",
    "Use a keyboard",
    "Ilk",
    "Information gleaned from a dating site",
    "Bang out",
    "Genre",
    "Family",
    "Enter with a keyboard",
    "Font contents",
    "Variety",
    "Pound the keys",
    "Disclosure on eHarmony",
    "Ilk",
    "Variety",
    "Use a keyboard",
    "Use 7-Down",
    "Enter",
    "Hunt and peck, e.g.",
    "Use keys",
    "Family",
    "Pound a keyboard",
    "Letters",
    "Dating consideration",
    "Sort",
    "Use a keyboard",
    "Pound a Selectric",
    "Play a key role?",
    "Classification",
    "Book marks",
    "Key in",
    "A or B, e.g.",
    "Hunt and peck",
    "Classify, as blood",
    "A or O, e.g.",
    "Use a Smith-Corona",
    "O, e.g.",
    "Enter, in a way",
    "Bang out, in a way",
    "Pound the keyboard",
    "A or O, e.g.",
    "\"Not my ___\"",
    "Word with blood or touch"
  ],
  "peta": [
    "Antifur org.",
    "Rights org. whose logo includes a rabbit",
    "\"I'd rather go naked than wear fur\" grp.",
    "10<sup>15</sup>: Prefix",
    "\"I'd rather go naked than wear fur\" org.",
    "Org. with many banned Super Bowl ads",
    "Fur fighters?",
    "Org. against dog or cock fighting",
    "Antifur org.",
    "Prefix with byte that means 10<sup>15</sup>",
    "Org. that loves fur ... but not on humans",
    "\"Fur Is Dead\" org.",
    "Antifur org.",
    "Anti-bullfighting org.",
    "Sponsor of ads famous for nudity",
    "Antifur org.",
    "Fur fighters?",
    "Antifur org.",
    "Antifur org.",
    "Anti-fur org."
  ],
  "eban": [
    "Israeli statesman Abba ___",
    "Abba of Israel",
    "Abba of Israel",
    "Abba of Israel",
    "Abba of Israel",
    "2001 Israel Prize winner",
    "Abba who was born \"Aubrey\"",
    "Abba of Israel",
    "Nonmusical Abba",
    "Abba not known for singing",
    "Israel's Abba",
    "Abba of Israel",
    "Israel's first U.N. delegate",
    "Israel's Abba",
    "Abba of Israel",
    "Nonmusical Abba",
    "Israel's foreign minister during the Six-Day War",
    "29-year Knesset member",
    "Abba of Israel",
    "\"Voice of Israel\" author",
    "Israeli statesman Abba",
    "Host of PBS's \"Heritage: Civilization and the Jews\"",
    "Israel's Abba",
    "Abba of Israel",
    "\"Voice of Israel\" author",
    "Israel's Abba",
    "Israel's Abba",
    "Israel's Abba",
    "He spent 29 years in the Knesset",
    "Abba of Israel",
    "\"Voice of Israel\" author",
    "Abba of Israel",
    "Abba of Israel",
    "2001 winner of the Israel Prize",
    "Abba of Israel",
    "Israel's Abba",
    "Israel's Abba",
    "\"Voice of Israel\" writer",
    "Abba of Israel",
    "\"Voice of Israel\" author",
    "Israel's Abba",
    "Diplomat Abba",
    "\"Promised Land\" author",
    "Abba of Israel",
    "Diplomat born in Cape Town",
    "Israel's Abba",
    "Israel's Abba",
    "Israel's Abba",
    "Israel's Abba",
    "\"Voice of Israel\" author",
    "Meir contemporary",
    "\"Personal Witness\" author",
    "Israeli foreign minister during the Yom Kippur War",
    "Meir contemporary",
    "Israel's first U.N. representative",
    "Israeli statesman",
    "Israel's Abba",
    "Israel's Abba",
    "Longtime Israeli ambassador to Washington",
    "Israel's Abba",
    "Israel's first U.N. delegate",
    "See 14-Across",
    "\"Israel in the World\" author",
    "Longtime Israeli diplomat",
    "See 28-Down",
    "\"My People\" author",
    "Abba of Israel",
    "\"My People\" author",
    "Israeli diplomat",
    "Israel's Abba",
    "Israeli Abba",
    "Israeli statesman Abba",
    "Foreign statesman whose real first name is Aubrey",
    "Longtime Israeli foreign minister",
    "See 29-Down",
    "Noted Mideast diplomat",
    "Israel's Abba",
    "?ôMy People?ö author",
    "Israel's Abba",
    "\"My People\" writer",
    "Israel's Abba",
    "Former Israeli statesman",
    "Noted Israeli diplomat",
    "Israeli statesman Abba",
    "Israel's Abba ___",
    "\"My People\" author",
    "Israeli diplomat",
    "Statesman of 3-Down",
    "\"My People\" author",
    "\"Invoice of Israel\" author",
    "\"My People\" author",
    "\"Voice of Israel\" author, 1957"
  ],
  "anya": [
    "Historical novelist Seton",
    "\"The Queen's Gambit\" actress ___ Taylor-Joy",
    "Novelist Seton",
    "Seton who wrote \"Dragonwyck\"",
    "\"The Cherry Orchard\" daughter",
    "Reformed demon on \"Buffy the Vampire Slayer\"",
    "Novelist Seton",
    "Novelist Seton",
    "Actress Taylor-Joy of 2015's \"The Witch\"",
    "Novelist Seton",
    "Author Seton",
    "Writer Seton",
    "Author Seton",
    "\"Dragonwyck\" author Seton",
    "___ Amasova, \"The Spy Who Loved Me\" Bond girl",
    "Author Seton",
    "Novelist Seton",
    "Daughter in \"The Cherry Orchard\"",
    "\"Dragonwyck\" author Seton",
    "Novelist Seton",
    "Novelist Seton",
    "Author Seton",
    "Novelist Seton",
    "Novelist Seton",
    "\"Dragonwyck\" author Seton",
    "Novelist Seton",
    "\"Dragonwyck\" author Seton",
    "Novelist Seton",
    "\"The Cherry Orchard\" girl",
    "\"The Cherry Orchard\" girl",
    "Novelist Seton",
    "Author Seton",
    "Author Seton",
    "Author Seton",
    "Novelist Seton",
    "Author Seton",
    "Author Seton",
    "Author Seton",
    "\"The Cherry Orchard\" miss"
  ],
  "past": [
    "Historical",
    "Kind of tense",
    "Historian's concern",
    "Opposite of future",
    "It's prologue, they say",
    "It may be checkered",
    "Gone",
    "Word repeated in \"What's ___ is ___\"",
    "After the hour",
    "Historian's focus",
    "Word repeated in \"What's ___ is ___\"",
    "Over and done with",
    "Historian's interest",
    "Historical",
    "Over",
    "Future's opposite",
    "Bygone",
    "\"Every saint has a ___\": Oscar Wilde",
    "Former life",
    "Later than",
    "Over",
    "Future's opposite",
    "History",
    "Future's opposite",
    "History",
    "Events gone by",
    "History",
    "It might be checkered",
    "See 19-Across",
    "History",
    "Ex-",
    "Previous",
    "History, with \"the\"",
    "Onetime",
    "Former life",
    "Behind us all",
    "Like water under the bridge",
    "Over",
    "Over",
    "Historical",
    "Future of the present",
    "Previous",
    "History, with \"the\"",
    "Like some practice",
    "Future's opposite",
    "Gone by",
    "It may be checkered",
    "It may be shady",
    "After the hour",
    "Time machine's destination",
    "History",
    "After the hour",
    "History",
    "Kind of tense",
    "Beyond",
    "After",
    "History",
    "One may be checkered",
    "Auld lang syne, with \"the\"",
    "Kind of tense",
    "History",
    "Finished",
    "Historical",
    "Former life",
    "Yesterdays",
    "Bygone"
  ],
  "temp": [
    "Work as a sub",
    "Sub at the office",
    "Worker paid day by day, maybe",
    "Fill-in worker",
    "Sub (for)",
    "Fill-in",
    "Do some day jobs",
    "Sub",
    "Worker hired for the day",
    "Day worker",
    "Fill-in",
    "Stand-in, maybe",
    "Many a sub",
    "Work day by day, say",
    "Time's partner, informally",
    "Bank clock info",
    "Office sub",
    "Sub",
    "Sub (for)",
    "Short-term office worker",
    "Sub",
    "Office sub, perhaps",
    "Fill-in",
    "Office worker just for the day",
    "Fill-in",
    "Seasonal worker, say",
    "It may be measured in F. or C.",
    "Short-term worker, for short",
    "Crunch-time helper",
    "Per diem worker",
    "Certain sub",
    "Fill-in",
    "Sub at the office",
    "Desk-borrowing worker",
    "Sidewalk Santa worker, e.g.",
    "Per diem worker",
    "Fill-in",
    "Short-term worker",
    "Sub",
    "Sub",
    "Office worker just for the day",
    "Time's partner, in brief",
    "Fill-in",
    "Fill in",
    "Many a sub",
    "Sub",
    "Fill-in",
    "Fill-in",
    "Day worker, maybe",
    "Not a permanent employee",
    "Fill-in",
    "Fill in at the office",
    "Work between jobs",
    "Office fill-in",
    "Fill in (for)",
    "Fill-in",
    "Agency worker, for short",
    "Substitute"
  ],
  "oleg": [
    "Fashion designer Cassini",
    "Cassini who created the so-called \"Jackie look\"",
    "Man's name that becomes a toy when its first letter is moved to the end",
    "\"The Americans\" K.G.B. officer ___ Burov",
    "Restaurant cook on TV's \"2 Broke Girls\"",
    "Couturier Cassini",
    "___ Burov, K.G.B. officer on \"The Americans\"",
    "Designer Cassini",
    "Cassini of fashion",
    "First name in fashion",
    "'60s designer for Jackie",
    "Cassini who was dubbed Jackie Kennedy's \"Secretary of Style\"",
    "Gold-medal skater Vasiliev",
    "Designer Cassini",
    "Designer Cassini",
    "Designer Cassini",
    "Fashion designer Cassini",
    "2006-08 heavyweight champion Maskaev",
    "Cassini of fashion",
    "Designer Cassini",
    "Designer Cassini",
    "Cassini who designed for Jackie",
    "___ Kalugin, former K.G.B. general with the 1994 book \"Spymaster\"",
    "Cosmonaut Makarov",
    "Cassini of fashion",
    "Designer Cassini",
    "Designer Cassini",
    "Designer for Jackie",
    "Former heavyweight champ Maskaev",
    "Two-time figure-skating Olympic gold medalist Protopopov",
    "Designer Cassini",
    "Hockey player Tverdovsky",
    "Cassini of fashion",
    "Cosmonaut Makarov",
    "Designer for Jackie",
    "Designer Cassini",
    "Cassini of fashion",
    "Pianist Maisenberg",
    "Designer Cassini",
    "First name in fashion",
    "Soyuz cosmonaut Makarov",
    "Designer Cassini",
    "First name in fashion",
    "Couturier Cassini",
    "First name in fashion",
    "Designer Cassini",
    "First name in fashion",
    "Designer Cassini",
    "Cosmonaut Makarov",
    "Designer for Jackie",
    "Salyut 7 cosmonaut Atkov",
    "Designer for Jackie",
    "Designer to Jackie",
    "Designer Cassini",
    "Designer Cassini",
    "Designer for Jackie",
    "Skater Protopopov",
    "He had designs on Jackie",
    "Designer Cassini",
    "Designer Cassini",
    "Designer Cassini",
    "Designer Cassini",
    "Designer Cassini",
    "Cassini of fashion",
    "Russian figure skater Protopopov",
    "Designer Cassini",
    "Designer Cassini",
    "Designer Cassini",
    "Cassini with designs on Jackie",
    "Designer Cassini",
    "Couturier Cassini",
    "Designer Cassini",
    "He had designs on Jackie",
    "Designer Cassini",
    "Designer Cassini",
    "Designer Cassini",
    "First name in fashion",
    "Designer Cassini",
    "First name in fashion",
    "Viking prince of Kiev",
    "Cassini of fashion",
    "First name in fashion",
    "Mr. Cassini",
    "Fashion's Cassini",
    "First name in fashion",
    "Mr. Cassini",
    "Jackie's dresser",
    "First name in fashion",
    "First name in fashion",
    "First name in fashion"
  ],
  "hutt": [
    "Jabba the ___",
    "Jabba, for one, in \"Star Wars\"",
    "Alien such as Jabba",
    "Jabba, for one, in \"Star Wars\"",
    "Jabba the ___, \"Star Wars\" villain",
    "Jabba the ___ of \"Star Wars\"",
    "Jabba the ___ of \"Star Wars\"",
    "Jabba the ___ of \"Star Wars\"",
    "\"Star Wars\" name"
  ],
  "supe": [
    "Building manager, for short",
    "Building manager, informally",
    "School district higher-up, informally",
    "Person whom people take their complaints to, informally",
    "Apartment building V.I.P., in slang",
    "Apartment building V.I.P.",
    "Dweller in Apt. 1-A, say",
    "Apartment building overseer, informally",
    "Condo V.I.P.",
    "Condo V.I.P.",
    "Apartment building V.I.P., for short",
    "Tenant's contact, casually",
    "Manager, briefly",
    "Caretaker, for short",
    "Apartment V.I.P.",
    "Apartment manager, familiarly",
    "Apartment V.I.P.",
    "Movie extra, in brief",
    "Building manager, briefly",
    "Apartment building head, slangily"
  ],
  "turf": [
    "Surf's partner, on menus",
    "Neighborhood",
    "Sport of horse racing, with \"the\"",
    "Territory",
    "Area to defend",
    "Gang land",
    "Territory",
    "Gang land",
    "Gang's area",
    "Sport of horse racing, with \"the\"",
    "Gang land",
    "Gang land",
    "What a gang protects",
    "Territory",
    "Bailiwick",
    "Gang land",
    "Gang's domain",
    "Gang's area",
    "Gang?Æs neighborhood"
  ],
  "lays": [
    "Potato chip brand",
    "Brand with \"Classic\" and \"Wavy\" varieties",
    "Big name in chips",
    "Big name in chips",
    "Potato chip brand",
    "Big name in potato chips",
    "Big name in chips",
    "Brand of chips",
    "Wagers",
    "Puts down, as tile",
    "Wise alternative",
    "Major chip maker",
    "Potato chip brand owned by PepsiCo",
    "Puts down",
    "Places",
    "Places",
    "Sets (down)",
    "Places",
    "Ballads",
    "Sets (down)",
    "Puts",
    "Macaulay's \"___ of Ancient Rome\"",
    "Wise competitor",
    "Places",
    "Deposits",
    "Big name in chips",
    "Puts (down)",
    "Potato chip brand",
    "Minstrels' songs",
    "Alternative to Ruffles",
    "Macaulay's \"___ of Ancient Rome\"",
    "Minstrel songs",
    "Emulates hens",
    "Has an egg",
    "Tolkein's \"The ___ of Beleriand\"",
    "Competitor of Pringles",
    "Bets"
  ],
  "rois": [
    "Louis Treize, Louis Quatorze and others",
    "18 Louises",
    "French kings",
    "Old French line",
    "Echecs pieces",
    "French kings",
    "Louis XIV et Louis XVI",
    "58-Across leaders",
    "Gateau des ___ (Mardi Gras dessert)",
    "Louis XIV, Louis XVI et al.",
    "Beaucoup de Louises",
    "Important pieces in echecs",
    "Some Bourbons, par exemple",
    "18 Louises",
    "French kings",
    "Louis-Philippe and others"
  ],
  "asta": [
    "\"The Thin Man\" pooch",
    "Dog in classic films",
    "Old film dog",
    "\"The Thin Man\" dog",
    "Fictional schnauzer",
    "Dog in \"The Thin Man\"",
    "\"The Thin Man\" dog",
    "\"The Thin Man\" dog",
    "Film role for the dog Skippy",
    "Old movie dog",
    "\"The Thin Man\" canine",
    "Nonhuman 1930s film star",
    "Terrier of old whodunits",
    "1930s film canine",
    "The Charleses' pet",
    "Repeated film role for Skippy",
    "Top dog in Hollywood in the 1930s",
    "Movie dog",
    "Tinseltown terrier",
    "Dog for a \"gentleman detective\"",
    "Dog of 1930s-'40s mysteries",
    "\"The Thin Man\" pooch",
    "\"The Thin Man\" terrier",
    "Film dog",
    "Terrier in whodunits",
    "Terrier on the silver screen",
    "Tinseltown terrier",
    "\"The Thin Man\" dog",
    "Film terrier played by Skippy",
    "Dashiell Hammett hound",
    "Film canine",
    "\"The Thin Man\" canine",
    "The Charleses' pet",
    "Big-screen canine",
    "Nick and Nora's terrier",
    "Wirehair of the silver screen",
    "1930s film star with notable facial hair",
    "Nick Charles's dog",
    "Terrier in \"The Thin Man\"",
    "Screen role for Skippy the dog",
    "1930s film pooch",
    "Dog in whodunits",
    "Dog of old films",
    "Terrier in whodunits",
    "Skippy's most famous role",
    "Terrier in whodunits",
    "Hollywood pooch",
    "Literary schnauzer",
    "\"The Thin Man\" pooch",
    "Wirehair of film",
    "Hairy clue-sniffer",
    "Nick and Nora's pooch",
    "Dashiell Hammett hound",
    "\"The Thin Man\" dog",
    "Fictional wirehair",
    "Fictional clue sniffer",
    "\"The Thin Man\" pooch",
    "\"The Thin Man\" terrier",
    "Four-legged film star of the '30s",
    "Old film pooch",
    "Film terrier",
    "Four-legged Hammett character",
    "Dog in 1930s films",
    "\"The Thin Man\" pooch",
    "Hammett's canine creation",
    "Hammett pooch",
    "\"The Thin Man\" canine",
    "1930s film dog",
    "\"The Thin Man\" dog",
    "\"The Thin Man\" dog",
    "Film dog",
    "Screened terrier",
    "\"The Thin Man\" pooch",
    "\"The Thin Man\" dog",
    "Dog in \"The Thin Man\"",
    "Whodunit dog",
    "\"The Thin Man\" pooch",
    "Hammett canine",
    "Fictional wirehair",
    "44-Across's dog",
    "Aid in solving the disappearance of the Thin Man",
    "Nick and Nora's dog of story and film",
    "\"The Thin Man\" dog",
    "\"The Thin Man\" dog",
    "1934 film canine",
    "\"The Thin Man\" dog",
    "Nick and Nora's pooch",
    "\"The Thin Man\" pooch",
    "\"The Thin Man\" dog",
    "Schnauzer in Dashiell Hammett books",
    "\"The Thin Man\" canine",
    "Fictional terrier",
    "1930's film canine",
    "\"The Thin Man\" role",
    "Dashiell Hammett dog",
    "\"The Thin Man\" dog",
    "\"The Thin Man\" dog",
    "Wirehair of film",
    "Charles barker",
    "The Charleses' canine",
    "1930's movie dog",
    "\"The Thin Man\" pooch",
    "\"The Thin Man\" dog",
    "Dog star?",
    "The Charleses' canine",
    "Hammett pooch",
    "Film role played by Skippy",
    "Terrier of film",
    "Hammett creation",
    "Movie dog",
    "\"The Thin Man\" canine",
    "Dog star",
    "Hollywood quadruped",
    "Dog of detective fiction",
    "\"The Thin Man\" dog",
    "Movie dog",
    "Wirehair of film",
    "\"The Thin Man\" pooch",
    "Silent film star Nielsen",
    "Dog star",
    "Film dog",
    "Movie pooch",
    "Nick and Nora's pooch",
    "\"The Thin Man\" pooch",
    "Wire-haired terrier of film",
    "\"The Thin Man\" dog",
    "Hollywood canine",
    "Early movie dog",
    "Cinematic terrier",
    "The Charleses' canine",
    "Hammett hound",
    "Nick and Nora's dog",
    "Fictional wirehair",
    "Nick and Nora's pooch",
    "Celluloid terrier",
    "Nick and Nora's dog",
    "Nick and Nora's dog",
    "Nick and Nora's pet",
    "Sleuthing dog",
    "\"The Thin Man\" dog",
    "Movie pooch",
    "Fictional clue-sniffer",
    "Hammett pooch",
    "Dog star",
    "Hammett hound",
    "30's movie dog",
    "Barker of filmdom",
    "Fictional pooch",
    "Screen terrier",
    "Hammett Schnauzer",
    "Cinematic canine",
    "Barker of Hollywood",
    "\"The Thin Man\" dog",
    "Fictional wirehair",
    "Movie pooch",
    "Fox terrier of film",
    "Sleuths' canine",
    "\"The Thin Man\" pooch",
    "\"The Thin Man\" dog",
    "Cinema pooch",
    "Cinema canine",
    "Movie pooch",
    "Fictional pooch",
    "Nick and Nora's pooch",
    "Hammett hound",
    "Movie pooch",
    "Famous wirehair",
    "Nick and Nora's dog",
    "Movie pooch",
    "Nick and Nora's pooch",
    "Cinematic pooch",
    "Film dog",
    "Nora's pooch",
    "Movie pooch",
    "Cinema canine",
    "Fictional terrier",
    "Dog star",
    "Terrier of fiction",
    "Mystery dog"
  ],
  "jake": [
    "One of the Blues Brothers",
    "Gyllenhaal of \"Brokeback Mountain\"",
    "A-O.K.",
    "TV anchor Tapper",
    "Hunky-dory",
    "Actor Gyllenhaal",
    "Hunky-dory",
    "Hunky-dory",
    "O.K., in slang",
    "Copacetic",
    "Just dandy",
    "Gyllenhaal of \"Brokeback Mountain\"",
    "Actor Gyllenhaal",
    "Gyllenhaal of \"Love & Other Drugs\"",
    "Ducky",
    "Actor Gyllenhaal of \"Brokeback Mountain\"",
    "Prizefighter La Motta",
    "Hunky-dory",
    "Hunky-dory",
    "Hemingway's Barnes",
    "O.K.",
    "Hunky-dory",
    "Country bumpkin",
    "O.K., in slang",
    "Boxer LaMotta",
    "The Fatman's TV partner",
    "Senator in space ___ Garn",
    "Senator-astronaut Garn"
  ],
  "gash": [
    "Deep cut",
    "Deep cut",
    "Nasty wound",
    "Nasty cut",
    "Deep cut",
    "Wound around the body?",
    "Laceration",
    "Nasty cut",
    "Deep cut",
    "Knife injury",
    "Cause of a scar",
    "Nasty wound",
    "Nasty wound",
    "Big cut",
    "Deep cut",
    "Where a scar forms",
    "Bad cut",
    "Band-Aid site",
    "Big cut",
    "Long cut",
    "More than scrape",
    "Rent",
    "Wound",
    "Long cut",
    "More than a scrape",
    "Cut",
    "Deep cut",
    "Knife wound",
    "Wound",
    "Knife wound",
    "Knife wound",
    "Knife mark",
    "Cutlass cut",
    "Big cut"
  ],
  "rids": [
    "Frees (of)",
    "Disencumbers",
    "Frees (of)",
    "Empties (of)",
    "Purges",
    "Purges",
    "Relieves (of)",
    "Divests",
    "Frees (of)",
    "Clears (of)",
    "Frees (of)",
    "Purges",
    "Clears",
    "Frees",
    "Clears",
    "Empties (of)",
    "Frees (of)",
    "Relieves (of)",
    "Purges",
    "Clears",
    "Frees (of)",
    "Relieves",
    "Relieves (of)",
    "Purges",
    "Relieves",
    "Clears (of)",
    "Clears",
    "Clears (of)",
    "Eliminates",
    "Clears (of)"
  ],
  "west": [
    "270°",
    "Direction headed by a covered wagon",
    "Occident",
    "Sunset direction",
    "Direction for one who's been in Benin to go to Togo",
    "Direction that wagon trains headed",
    "Mae who said \"I used to be Snow White, but I drifted\"",
    "*School overlooking the Hudson",
    "Bridge position",
    "Foe of Russia, with \"the\"",
    "___ Point",
    "Sunset's direction",
    "This side of the puzzle",
    "Area with the last election results, with \"the\"",
    "Wicked Witch's home",
    "Rapper Kanye",
    "Bridge position",
    "Against the jet stream",
    "Enemy of ISIS, with \"the\"",
    "Sunset's direction",
    "Last part of the country to report election results",
    "270??",
    "Toward sunset",
    "___ Bank",
    "Into the sunset",
    "Cornel who wrote \"Race Matters\"",
    "What was due for some pioneers?",
    "Last part of the country to report election returns, usually, with \"the\"",
    "Direction in which the sun sets",
    "Pioneer's direction",
    "The half of the keyboard on which all of this puzzle's answers can be typed",
    "Rapper Kanye",
    "Sunset direction",
    "Communism battler, with \"the\"",
    "Rapper Kanye",
    "Communism battler, with \"the\"",
    "Side in an annual all-star game",
    "Toward sunset",
    "See 5-Down",
    "Part of the country where polls close later",
    "Pacific states, with \"the\"",
    "Cold war faction",
    "Direction for a wagon train",
    "Early Hollywood sex symbol",
    "Direction wagon trains headed",
    "Like some winds",
    "Toward sunset",
    "One side in an annual football game",
    "See 10-Across",
    "Left on a map",
    "All-star game team, maybe",
    "Point to the left",
    "Expansion target, with \"the\"",
    "It underwent the Enlightenment, with \"the\"",
    "Nathanael who wrote \"Miss Lonelyhearts\"",
    "Toward the sunset",
    "Direction for Greeley",
    "Shrine Bowl team",
    "Wild ___",
    "___ Bank",
    "9 o'clock, to some",
    "Wild place?",
    "___ Bank",
    "Left on a map",
    "Pioneer's heading",
    "Communism battler, with \"the\"",
    "A.F.C. division",
    "Cold war side, with \"the\"",
    "Wild place",
    "Toward sunset",
    "Sunset direction",
    "Sunset direction",
    "Sunset's direction",
    "Wagon train direction",
    "Frontierward",
    "___ Point",
    "Bridge seat"
  ],
  "form": [
    "1040, e.g.",
    "Application to fill out",
    "1040A, e.g.",
    "Bit of paperwork",
    "Waiting room distribution",
    "Mold",
    "W-2, e.g.",
    "1040, for one",
    "Fashion",
    "Mannequin",
    "Something to fill out",
    "Take 4, clue 3",
    "Word with tax or free",
    "Mold"
  ],
  "runs": [
    "Homecomings?",
    "Pantyhose problems",
    "Scores in baseball",
    "Directs",
    "Column in a baseball box score",
    "Heads up",
    "Worries for Great Depression banks",
    "Lines on a ski mountain map",
    "Coloratura's practice",
    "Areas in dog pounds",
    "Musical scales, e.g.",
    "Baseball stat",
    "Operates",
    "Scoreboard figure",
    "Baseball scores",
    "Directs",
    "Giant successes?",
    "Hose woes",
    "Beats a hasty retreat",
    "Baseball scores",
    "Kennel enclosures",
    "Scoreboard postings",
    "Shutout spoilers",
    "Baseball tally",
    "Hightails it",
    "They're always made at home",
    "Heads up",
    "Stocking flaws",
    "Box score column",
    "Homecomings?",
    "Heads up",
    "Grand slam foursome",
    "\"Ladders\" in hose",
    "Isn't colorfast",
    "Bank woes",
    "Scores of diamonds",
    "Hosiery snags"
  ],
  "ebbs": [
    "Weakens",
    "Goes down",
    "Flows' opposite",
    "Recedes",
    "Goes out",
    "Diminishes",
    "Retreats",
    "Flows back",
    "___ and flows",
    "Recedes",
    "Flows out",
    "Subsides",
    "Gradually diminishes",
    "Recedes, as the tide",
    "Subsides slowly",
    "Recedes",
    "Recedes, as the tide",
    "Goes back",
    "Diminishes",
    "Falls back",
    "Decreases",
    "Weakens",
    "Undergoes recession",
    "Recedes",
    "Dies down",
    "Recedes",
    "Pulls back",
    "Declines",
    "___ and flows",
    "Goes back",
    "Drifts away",
    "Recedes",
    "Goes down",
    "Lessens",
    "Recedes",
    "Flows back",
    "Flags",
    "Lessons",
    "Is refluent",
    "Flows back",
    "Goes back",
    "Wanes",
    "Goes back",
    "Dwindles",
    "Falls back",
    "Goes back",
    "Flows out",
    "Low points",
    "Weakens, as support",
    "Goes out to sea",
    "Declines",
    "Falls back",
    "Goes back",
    "Goes out",
    "Flows back",
    "Falls back",
    "Goes down",
    "Drops",
    "Declines",
    "Becomes less active",
    "Goes back out",
    "Goes out",
    "Weakens, as support",
    "Goes back to sea?",
    "Withdraws",
    "Goes back out",
    "Falls",
    "Subsides",
    "Recedes",
    "Fades",
    "Falls off",
    "Trails off",
    "Drops off",
    "Subsides, as the tide",
    "Declines",
    "Inverse of \"floods\"",
    "Recedes",
    "Retrocedes",
    "Falls off"
  ],
  "lotr": [
    "Series set in Middle-earth, for short",
    "Popular fantasy film franchise, for short",
    "Tolkien trilogy, for short",
    "Fantasy series that inspired \"Game of Thrones,\" briefly",
    "Tolkien's trilogy, for short"
  ],
  "back": [
    "Browser command",
    "Lower-priced edition of a book",
    "In town again",
    "With 28-Down, part of a golf course",
    "Front's opposite",
    "Behind",
    "Bet on",
    "Word that can follow the start of 20-, 38- or 50-Across",
    "Support",
    "No longer on vacation",
    "Support",
    "Support",
    "Missing word in 21-, 31-, 40- and 50-Across, applied literally",
    "Endorse",
    "See 8-Down",
    "With 118-Across, subject of an I.R.S. request",
    "Front's opposite",
    "Safety, for one",
    "See 1-Down",
    "Safety, e.g.",
    "Support",
    "Support"
  ],
  "fame": [
    "\"The perfume of heroic deeds,\" to Socrates",
    "\"A fickle food,\" per Emily Dickinson",
    "What stars have",
    "Celebrity",
    "Oscar-winning song of 1980",
    "\"A fickle food,\" to Emily Dickinson",
    "Stardom",
    "Renown",
    "Celebrity",
    "*Renown",
    "Greatness",
    "\"A fickle food upon a shifting plate,\" according to Emily Dickinson",
    "Starlet's dream",
    "Renown",
    "\"A fickle food upon a shifting plate\": Dickinson",
    "Renown",
    "Notability",
    "Recognition",
    "\"The shadow of Virtue\": Seneca",
    "Notability",
    "Recognition",
    "Public regard",
    "\"I would give all my ___ for a pot of ale\":\"Henry V\"",
    "Fortune's partner",
    "Fortune's partner",
    "Celebrity"
  ],
  "hoda": [
    "TV host Kotb",
    "Kotb of \"Today\"",
    "Newswoman Kotb",
    "Kotb of \"Today\"",
    "First name in morning TV",
    "Kotb on morning TV",
    "Matt's replacement on \"Today\"",
    "\"Today\" co-host Kotb"
  ],
  "jibe": [
    "Agree (with)",
    "Match up (with)",
    "Dovetail (with)",
    "Mesh (with)",
    "Match up, as accounts",
    "Agree (with)",
    "Be in harmony",
    "Agree (with)",
    "Agree",
    "Agree",
    "Agree",
    "See eye to eye",
    "Be in accord",
    "Be in sync",
    "Agree",
    "Be in harmony",
    "Agree (with)",
    "Be in harmony",
    "Be in accord",
    "Be in accord",
    "Be in harmony (with)",
    "Be in accord",
    "Be in harmony",
    "Agree",
    "See eye to eye"
  ],
  "poms": [
    "Yappy lap dogs, for short",
    "Yappy lap dogs, informally",
    "Certain lap dogs, informally",
    "Pooches with turned-up tails",
    "Small dogs, in brief",
    "Brits, to Aussies",
    "Short dogs, for short"
  ],
  "psec": [
    "Tiny fraction of a min.",
    "Time it takes light to travel .3 mm: Abbr.",
    "Tiny fraction of a min.",
    "One 60-trillionth of a min.",
    "Tiny fraction of a min.",
    "Tiny fraction of a min.",
    "Infinitesimal division of a min.",
    "1/60 of a trillionth of a min."
  ],
  "uhoh": [
    "\"This looks bad\"",
    "\"Here comes trouble ...\"",
    "\"Brace yourselves!\"",
    "\"This doesn't look good ...\"",
    "Swaying just before a disaster",
    "Worrisome word at a nuclear plant",
    "\"Someone made a boo-boo!\"",
    "\"Here's trouble!\"",
    "\"This looks like trouble!\"",
    "[Gulp!]",
    "\"This is looking bad\"",
    "\"Eeep!\"",
    "\"This can't be good\"",
    "Dread line?",
    "\"This doesn't look good\"",
    "\"A storm's a-brewin'\"",
    "\"Eeep!\"",
    "\"Looks like trouble!\"",
    "\"Here comes trouble!\"",
    "[Gulp!]",
    "\"I'm dead\"",
    "\"This looks bad\"",
    "Slip accompanier",
    "\"This can't be good\"",
    "\"I'm in trouble now!\"",
    "\"This is looking bad!\"",
    "\"That can't be good!\"",
    "\"Don't look now ...\"",
    "\"Oops-a-daisy\"",
    "\"We're in trouble now!\"",
    "\"This might get ugly\"",
    "\"This could get ugly\"",
    "\"We're in trouble!\"",
    "\"Looks like I goofed\"",
    "\"This isn't looking good\"",
    "\"Here comes trouble!\"",
    "\"This might get ugly!\"",
    "\"This isn't good!\"",
    "\"We're in trouble!\"",
    "\"This doesn't look good\"",
    "\"Yikes!\"",
    "\"Here comes trouble!\"",
    "\"Here comes trouble\"",
    "\"Did I just do what I think I did?!\"",
    "\"Whoops!\"",
    "\"Things aren't looking good\"",
    "\"Now we're in for it!\"",
    "\"Looks like trouble!\"",
    "\"Whoops!\"",
    "\"Oops!\"",
    "\"This means trouble!\"",
    "\"Start worrying\"",
    "\"This can't be good\"",
    "Goof follower",
    "\"This looks bad\"",
    "\"We're in trouble\"",
    "Comment after an accident",
    "\"That doesn't sound good\"",
    "\"I think I goofed\"",
    "Worrisome remark by a surgeon",
    "Slip acknowledgment",
    "\"Oops...\"",
    "\"This is bad!\"",
    "\"Looks like trouble!\"",
    "Concerned expression",
    "Post-accident utterance",
    "\"This isn't looking good...\"",
    "Catch phrase?",
    "\"Look out...\"",
    "Apprehension expression",
    "\"Oops!\"",
    "Bad thing to hear at a checkup",
    "\"Oops!\"",
    "Unsettling comment from a pilot",
    "\"Here comes trouble!\"",
    "\"Here comes trouble!\"",
    "\"Whoops!\"",
    "\"Whoops!\"",
    "\"Oops!\"",
    "\"Whoops\"",
    "\"Here comes trouble!\""
  ],
  "togs": [
    "Outfits",
    "Get-up",
    "Attire, informally",
    "Duds",
    "Clothing",
    "Getup",
    "Glad rags",
    "Attire",
    "Outfits",
    "Get-up",
    "Clothing",
    "Duds",
    "Threads",
    "Duds",
    "Duds",
    "Clothes",
    "Duds",
    "Getup",
    "Garb",
    "Clothing",
    "Duds",
    "Clothes",
    "Threads",
    "Outfit"
  ],
  "mich": [
    "State whose northern residents are known as Yoopers: Abbr.",
    "Lansing's home: Abbr.",
    "Thumb locale: Abbr.",
    "Pontiac's place: Abbr.",
    "State north of Ind.",
    "Neighbor of 9-Down"
  ],
  "wasi": [
    "\"___ close?\"",
    "\"So ___\" (words of agreement)",
    "See 39-Across",
    "\"What ___ thinking?!\"",
    "\"Now where ___?\"",
    "\"Where ___?\"",
    "\"Now, where ___?\"",
    "\"Where ___?\" (digression closer)"
  ],
  "parr": [
    "Surname of the Incredibles",
    "Mr. Incredible's actual surname",
    "Catherine who married Henry VIII",
    "Catherine who married Henry VIII",
    "Catherine who married Henry VIII",
    "Royal Catherine",
    "Henry VIII's sixth",
    "Catherine who married Henry VIII",
    "Royal Catherine",
    "Catherine who married Henry VIII",
    "Catherine who was the last wife of Henry VIII",
    "\"The Incredibles\" family name",
    "Henry VIII's sixth, Catherine ___",
    "Henry VIII's sixth",
    "Catherine ___, last wife of Henry VIII",
    "Catherine who survived Henry VIII",
    "Last name of Henry VIII's last",
    "Catherine, the last wife of Henry VIII",
    "Catherine of history",
    "Catherine who wed Henry VIII",
    "Henry VIII's last wife",
    "One of six of Henry VIII",
    "Henry VIII's sixth",
    "Catherine who was the last wife of Henry VIII",
    "\"St. Elmo's Fire\" singer John",
    "Henry VIII's sixth",
    "Catherine who was a queen",
    "One of Henry VIII's six",
    "Henry VIII's sixth",
    "Henry's sixth",
    "Henry VIII's sixth",
    "One of Henry VIII's six",
    "One of Henry's Catherines",
    "Catherine ___, wife of Henry VIII",
    "One of Henry VIII's six",
    "Catherine who survived Henry VIII",
    "Henry VIII's VIth",
    "Henry VIII's sixth",
    "Henry VIII's sixth",
    "Catherine ___ (Henry VIII's sixth)",
    "Young salmon",
    "Henry VIII's VIth"
  ],
  "this": [
    "\"Get ___ ...\" (\"Here's the best part ...\")",
    "The one over here",
    "\"Get ___!\"",
    "No, not that!",
    "\"Who is ___?\"",
    "\"What's ___?!\"",
    "TV's \"___ Is Us\"",
    "\"With ___ ring ...\"",
    "\"___ Is Us\" (65-Down drama)",
    "___ end up",
    "\"___ is the life!\"",
    "\"What is ___?\"",
    "\"___ ends here!\" (fighting words)",
    "\"___ American Life\"",
    "Just-once link",
    "What you see here",
    "\"Get a load of ___!\"",
    "\"Get ___!\"",
    "Partner of 40-Across",
    "First of two choices",
    "See 84-Down",
    "\"See ___?\"",
    "\"Get ___!\"",
    "1975 Natalie Cole hit \"___ Will Be\"",
    "\"Don't breathe a word of ___\"",
    "Radio's \"___ American Life\"",
    "That isn't it",
    "\"Who needs ___?\"",
    "\"I'm pointing at it\"",
    "\"What Child Is ___?\"",
    "The one here",
    "\"___ way, please\"",
    "\"What's ___?\"",
    "What that isn't",
    "1939 Glen Gray hit \"___ Night\"",
    "1950's TV's \"___ Is Your Life\"",
    "Walk-way connector",
    "\"Get ___!\"",
    "\"How's ___?\"",
    "What have we here",
    "Not that",
    "\"Get a load of ___!\"",
    "None of that?",
    "Not that",
    "Michael Moore's \"Downsize ___!\"",
    "\"Now hear ___!\"",
    "\"See ___?\"",
    "\"What's ___?\"",
    "With 7-Down, statement at a do-or-die moment",
    "\"Now hear ___!\"",
    "\"___ House\" (1991 hit)",
    "\"___Magic Moment\" (60's hit)",
    "The one here",
    "\"___ Is Spinal Tap\" (1984 movie)",
    "Tommy Dorsey's \"___ Is It\"",
    "\"___ little piggy...\""
  ],
  "ands": [
    "They may lead to longer sentences",
    "Addenda",
    "Added details",
    "No ifs, ___ or buts",
    "Connectors",
    "Additional stipulations",
    "\"No ifs, ___ or buts\"",
    "Some Boolean operators",
    "Extra conditions",
    "Added details",
    "They make connections",
    "\"No ifs, ___ ...\"",
    "+ + +",
    "Common connectors",
    "Bridge words",
    "No ifs, ___ or buts",
    "&&&",
    "Added stipulations",
    "No ifs, ___ or buts",
    "&&&",
    "Logical conjunctions, in mathematics",
    "English connections",
    "Added stipulations",
    "Added conditions",
    "English connections",
    "&&&&",
    "Some operators in Boolean logic",
    "Pluses",
    "They make connections",
    "&&&&",
    "Common connectors",
    "Added conditions",
    "Connectors",
    "Common connections",
    "Added conditions",
    "Common conjunctions",
    "Common bonds",
    "\"No ifs, ___ or buts\"",
    "Added stipulations",
    "Added stipulations",
    "\"No ifs, ___ ...\"",
    "\"No ifs, ___ or buts!\"",
    "No ifs, ___ or buts",
    "Common links",
    "& & &",
    "Added details",
    "Connectors appropriate to this puzzle",
    "Added stipulations",
    "Pluses",
    "Added details",
    "&&&",
    "Added stipulations",
    "Common conjunctions",
    "Common links",
    "See 62-Across",
    "Connective tissue of prose",
    "Common connections",
    "No ifs,___or buts",
    "Everyday connections",
    "Plus words",
    "Connectors",
    "Common connectors",
    "No ifs, ___ or buts",
    "No ifs,___ or buts",
    "No ifs, ___ ..."
  ],
  "camo": [
    "Some G.I. wear, in brief",
    "G.I.'s garb, at times",
    "Garb in a duck blind, informally",
    "Some G.I. wear, informally",
    "___ pants",
    "G.I. garb, for short",
    "G.I. garb, for short",
    "Some G.I. wear, for short",
    "Duck-hunting attire, informally",
    "___ pants (earth-toned apparel)",
    "Military wear, for short",
    "Hunting gear, informally",
    "Hunter's garb, for short",
    "Hunter's garb, for short"
  ],
  "wale": [
    "One-named rapper with the 2015 #1 album \"The Album About Nothing\"",
    "Corduroy rib",
    "Corduroy ridge",
    "Corduroy ridge",
    "Corduroy feature",
    "Corduroy rib",
    "Fabric rib",
    "Whipping evidence",
    "Heavy plank on the side of a wooden ship",
    "Whip mark",
    "Ship plank",
    "Cloth texture",
    "Fabric texture"
  ],
  "capo": [
    "Guitar accessory",
    "Don's partner in the underworld",
    "Sonny Corleone, for one",
    "Mafia head",
    "Ukulele accessory",
    "Crime boss",
    "Ukulele accessory",
    "Underworld boss",
    "Don",
    "Guitarist's key-changing aid",
    "Mafia bigwig",
    "Tool that raises a guitar's pitch",
    "Crime chief",
    "Guitar accessory",
    "Family head",
    "Family head",
    "Head of a family",
    "It can change one's tune",
    "String shortener",
    "Banjo accessory",
    "Aria da ___",
    "Family head",
    "Mob boss",
    "Mafia biggie",
    "Pitch-raising guitar device",
    "Crime bigwig",
    "Family head",
    "Crime boss",
    "Mafia bigwig",
    "Mafia bigwig",
    "Underworld figure",
    "Syndicate head",
    "Underworld higher-up",
    "Syndicate biggie",
    "Mafia head",
    "Syndicate V.I.P.",
    "Syndicate chief",
    "Mafia boss",
    "Mafia leader",
    "Crime boss",
    "Hit producer?",
    "Guitar accessory",
    "Mafia bigwig",
    "Syndicate bigwig",
    "Guitar accessory",
    "Family man?",
    "Mob boss",
    "Bar placed across a guitar fingerboard",
    "Mafia chief",
    "\"The Godfather\" figure",
    "Mafia chief",
    "Mafia kingpin",
    "Mafia kingpin",
    "\"The Godfather\" figure"
  ],
  "malt": [
    "Order at an ice cream parlor",
    "Kind of Ovaltine",
    "Drink with a straw",
    "Ice cream drink",
    "Colt 45, e.g., informally",
    "Grain used in making beer",
    "Like Colt 45 and Mickey's",
    "Brewer's ingredient",
    "Ice cream drink",
    "Germinated grain",
    "Barley product",
    "Brewer's need",
    "Source of some extracts",
    "Fountain order",
    "Fountain order",
    "Drink with a straw",
    "Shake alternative",
    "Brew choice",
    "Beer component",
    "Fountain order",
    "Brewer's ingredient",
    "Shake alternative",
    "___ liquor",
    "Fountain offering",
    "Brewer's stock",
    "Certain brew",
    "Ale ingredient",
    "___ liquor",
    "Fountain treat",
    "Fountain treat",
    "Drink with a foamy head",
    "Kind of syrup",
    "Scotch ingredient",
    "Ovaltine ingredient",
    "Many a beer",
    "Brewer's need",
    "Brewery supply",
    "Fountain order",
    "Soda shop treat",
    "Drive-in order",
    "Distiller's grain",
    "Stout ingredient",
    "Brewery grain",
    "Oast filler",
    "Cousin of a milkshake",
    "Soda fountain choice",
    "Fountain order",
    "Kind of liquor",
    "Fountain treat",
    "Fountain drink",
    "After-school drink",
    "Brewing grain",
    "Fountain treat",
    "Drink with a straw",
    "Ice-cream parlor order",
    "Foamy drink",
    "Ice cream treat",
    "Brewing ingredient"
  ],
  "halt": [
    "Cry before \"Who goes there?\"",
    "Guard's command",
    "Discontinue",
    "Quash",
    "\"Freeze!\"",
    "Stop",
    "Cry after \"Company\"",
    "Cry after \"Company\"",
    "Put a stop to",
    "\"Stop right there!\"",
    "\"That's close enough!\"",
    "Freeze",
    "Gatekeeper's cry",
    "\"Stop!,\" at a checkpoint",
    "Stop",
    "Stop",
    "Stop order?",
    "\"Stop right there!\"",
    "Pull up",
    "Sentry's order",
    "Parade-ground command",
    "Sentry's command",
    "\"Stop!\"",
    "Knock off",
    "Be hesitant",
    "Sentry's cry",
    "Drill shout",
    "Sentry's command",
    "\"Stop right there!\"",
    "\"Freeze!\"",
    "\"Don't take another step!\"",
    "Gatehouse cry",
    "Suspend",
    "Suspension",
    "Old-fashioned police cry",
    "\"Stop!\"",
    "Leave off",
    "Check",
    "Stop order?",
    "Shut down",
    "Sentry's cry",
    "Sentry's \"Stop!\"",
    "Sentry's cry",
    "Sentry's cry",
    "Sentry's cry",
    "Sentry's command",
    "Guard's cry",
    "Stoppage"
  ],
  "haut": [
    "___ monde (fashionable society)",
    "High, in Versailles",
    "High: Fr.",
    "___ monde",
    "Opposite of bas",
    "High in the French Alps",
    "High, in the Alps",
    "Chateau ___-Brion (Bordeaux wine)",
    "___ Rhin (French department)",
    "___ monde",
    "Henri?Æs high",
    "Chateau ___-Brion wine",
    "___ monde",
    "___ monde",
    "___ monde (high society)",
    "High, in the Alps",
    "___ monde (society)",
    "___ monde (society)",
    "___ monde (high society)",
    "___monde (high society)"
  ],
  "rued": [
    "Regretted",
    "Regretted",
    "Had some second thoughts about",
    "Like comments that require apologies",
    "Felt remorse for",
    "Wanted to take back, say",
    "Wished could be undone",
    "Regretted",
    "Deeply regretted",
    "Kicked oneself over",
    "Said \"mea culpa,\" say",
    "Recalled not fondly",
    "Lamented",
    "Wished otherwise",
    "Wanted to undo",
    "Thought better of",
    "Wished undone",
    "Was sorry about",
    "Regretted",
    "Was contrite",
    "Wished otherwise",
    "Felt remorse",
    "Had second thoughts about",
    "Felt sorry about",
    "Like many a mistake",
    "Regretted",
    "Regretted",
    "Wished undone",
    "Lamented",
    "Felt bad about",
    "Like some bad decisions",
    "Like past mistakes",
    "Was sorry about",
    "Wished undone",
    "Lamented",
    "Felt bad about",
    "Demonstrated contrition",
    "Felt repentance",
    "Bemoaned",
    "Like many a mistake",
    "Had second thoughts",
    "Reconsidered",
    "Felt sorry about",
    "Lamented",
    "Wept over"
  ],
  "ohso": [
    "Extremely",
    "Very",
    "Very",
    "Very",
    "Quite",
    "Exceedingly",
    "Too, too",
    "Very",
    "Extremely",
    "Very",
    "Exceedingly",
    "Very",
    "Quite",
    "Very",
    "Very",
    "Tres",
    "Emphatically",
    "Very very",
    "Very, very",
    "Very",
    "Very",
    "Very",
    "Really, really",
    "Quite",
    "Very",
    "\"___ that's your game!\"",
    "Excessively"
  ],
  "yeas": [
    "Votes in favor",
    "Consensus from a bloc",
    "Votes for",
    "Green lights in D.C.",
    "Up votes",
    "Words said in passing",
    "Votes in favor",
    "Things needed in passing?",
    "Thumbs-up votes",
    "Affirmative votes",
    "Pro votes",
    "Floor support?",
    "Passing comments?",
    "Pro side of a vote",
    "Motion carriers, at times",
    "Up side?",
    "Half of a vote",
    "See 39-Across",
    "Thumbs-ups",
    "Affirmatives",
    "Votes that are an anagram of 71-Across",
    "Senatorial agreements",
    "They vote first",
    "Approving votes",
    "Thumbs-up votes",
    "Pro team?",
    "Floor support?",
    "Votes for",
    "Votes opposite the nays",
    "Congressional assents",
    "Supportive side",
    "Some hand-raisers",
    "\"For\" votes",
    "House calls?",
    "First half of a Senate vote",
    "\"Pro\" votes",
    "Pros",
    "For words?",
    "Half of a vote",
    "Parliamentary nods",
    "Nays' opposites",
    "Affirmative votes",
    "Nay opposers",
    "Supporting votes",
    "Those for",
    "First to vote",
    "Thumbs-up votes",
    "Affirmative votes",
    "House votes",
    "Some votes",
    "Pro votes",
    "Voice votes",
    "\"Up\" side",
    "Yes votes",
    "Pro votes",
    "Senate support",
    "Senate sounds",
    "Endorsements",
    "Those for",
    "Voters' choices",
    "First side to vote",
    "Those for",
    "Votes for"
  ],
  "davy": [
    "Frontiersman Crockett",
    "Bandmate of Micky, Peter and Michael of the Monkees",
    "___ Jones's locker",
    "Crockett or Jones",
    "One of the Monkees"
  ],
  "mule": [
    "Symbol of stubbornness",
    "Farm animal that kicks",
    "Drug ___",
    "Animal relative of a hinny",
    "Half-assed sort?",
    "Stubborn animal",
    "Pack animal",
    "Lounging slipper",
    "Drug smuggler",
    "60-Down mascot",
    "Hybrid animal",
    "Pack carrier",
    "Stubborn animal",
    "Drug smuggler's courier",
    "Person taking drugs",
    "Grand Canyon rental",
    "Pack carrier",
    "Obstinate type",
    "Cart puller",
    "Stubborn animal",
    "Symbol of stubbornness",
    "Pack animal",
    "Stubborn beast",
    "Slipper",
    "Lounging footwear",
    "Stubborn beast",
    "Stubborn sort",
    "Bedroom slipper",
    "Uncooperative one",
    "Uncooperative one",
    "Plodding sort",
    "Stubborn sort",
    "One who won't budge",
    "Part of a wagon train",
    "Slipper without a back",
    "Stubborn one",
    "Wagon train puller",
    "Slipper",
    "Part of a wagon train",
    "House slipper",
    "Army mascot",
    "Obstinate one"
  ],
  "stud": [
    "Macho sort",
    "What might be on the tip of your tongue?",
    "Poker variety",
    "Tuxedo shirt fastener",
    "Small earring",
    "Starter earring",
    "Nose decoration",
    "Drywall support",
    "Upright building support",
    "Variety of poker",
    "Tuxedo shirt attachment",
    "Total hunk",
    "___ poker",
    "Horse for hire",
    "Bit of jewelry",
    "Shirt ornament",
    "Secretariat's father, for one",
    "Ladies' man",
    "Formal wear accouterment",
    "Hunky guy",
    "Macho guy",
    "Bit of jewelry",
    "Macho sort",
    "Tuxedo shirt button",
    "Formalwear accessory",
    "Macho man",
    "Hoop alternative",
    "Alternative to draw or hold 'em",
    "Hold 'em alternative",
    "Poker variety",
    "Ladies' man",
    "Ladies' man",
    "Small earring",
    "Manly man",
    "Ladies' man",
    "Dress shirt closer",
    "Gambler's option",
    "Draw alternative",
    "Simple earring",
    "Variety of poker",
    "Breeding horse",
    "Formal accessory",
    "It can be ear-piercing",
    "Poker variety",
    "Fastener",
    "Tuxedo shirt fastener",
    "It may be ear-piercing",
    "Mare's mate",
    "Poker variety",
    "Macho man",
    "Kind of bolt",
    "Poker variation",
    "Dress shirt ornament",
    "Wall upright",
    "Tire feature",
    "Virile type",
    "Breeder",
    "Poker game",
    "Tire part",
    "Formal accessory",
    "Poker choice",
    "Gambler's game",
    "Collar fastener",
    "Macho man",
    "Betting game, informally",
    "Five-card game",
    "Card game",
    "Draw alternative",
    "Collar fastener",
    "Betting game, informally",
    "Dickey fastener"
  ],
  "myra": [
    "Gore Vidal's \"___ Breckinridge\"",
    "Woman's name that becomes another woman's name when its vowels are switched",
    "\"___ Breckinridge\" (Gore Vidal novel)",
    "Breckinridge of fiction",
    "Name in a Gore Vidal title",
    "Pioneering woman lawyer Bradwell",
    "Dame Hess at a piano",
    "Vidal's \"___ Breckinridge\"",
    "Hess who was a dame",
    "Hess who was a dame",
    "\"___ Breckinridge\"",
    "\"___ Breckinridge\"",
    "Dame Hess",
    "Dame Hess",
    "Nicholas of ___, patron saint of mariners and thieves",
    "\"___ Breckinridge\"",
    "Keyboardist Hess",
    "Hess who was a dame",
    "Ancient city, home of St. Nicholas",
    "Vidal's Breckinridge",
    "Vidal's \"___ Breckinridge\"",
    "\"___ Breckinridge\"",
    "\"___ Breckinridge\"",
    "Vidal's Breckinridge",
    "Vidal's Breckinridge",
    "Pianist Hess"
  ],
  "ahab": [
    "\"Moby-Dick\" captain",
    "\"Moby-Dick\" captain",
    "Husband of Jezebel in the Bible",
    "\"Moby-Dick\" captain",
    "Captain with a whalebone leg",
    "Literary character played by Gregory Peck, Patrick Stewart and Orson Welles",
    "Literary protagonist named after a king of Israel",
    "Pursuer of an \"accursed white whale\"",
    "Model of vengeful obsession",
    "Melville captain",
    "One-legged, single-minded sea captain",
    "Monomaniacal captain of literature",
    "Fictional captain whose nickname is \"Old Thunder\"",
    "Monomaniac of fiction",
    "Starbuck's order giver",
    "Starbuck's order giver",
    "Literary character likened to a \"mute, maned sea-lion\"",
    "Melville monomaniac",
    "\"Grand, ungodly, godlike man,\" in literature",
    "Melville captain",
    "Captain of the Pequod",
    "Captain of literature",
    "Fictional character who says \"I'd strike the sun if it insulted me\"",
    "Mariner in a whale of a novel?",
    "\"Moby-Dick\" captain",
    "Literary character on whom Captain Hook is based",
    "Monomaniacal mariner",
    "Starbuck's order giver",
    "\"Moby-Dick\" captain",
    "Starbuck's superior",
    "Captain played by Patrick Stewart",
    "Fictional boss of Stubb and Flask",
    "Pequod captain",
    "1956 Gregory Peck role",
    "Starbuck's orderer",
    "Vengeful captain",
    "King of Kings",
    "\"Moby-Dick\" captain",
    "Ill-fated captain",
    "Fictional user of a 21-Across",
    "Starbuck's boss",
    "Literary character who says \"I'll chase him round Good Hope\"",
    "Fictional captain who said \"Thou damned whale!\"",
    "Vindictive Quaker of fiction",
    "\"Grand, ungodly, godlike man\" of fiction",
    "Melville captain",
    "Fictional character who says \"I now prophesy that I will dismember my dismemberer\"",
    "Ishmael's captain",
    "Queequeg's captain",
    "Fictional character who declares \"Sleep? ... I do not sleep, I die\"",
    "Captain with a \"regal overbearing dignity of some mighty woe\"",
    "Literary character who says \"For hate's sake, I spit my last breath at thee\"",
    "I Kings king",
    "Literary lead role for Gregory Peck in 1956",
    "Melville's obsessed captain",
    "Obsessed mariner",
    "Wicked king of Israel",
    "Melville's obsessed whaler",
    "King in I Kings",
    "\"Moby-Dick\" captain",
    "Vengeful Quaker of literature",
    "Melville mariner",
    "Ill-fated captain",
    "Captain of Stubb and Flask",
    "\"Moby-Dick\" captain",
    "Starring role for John Barrymore and Gregory Peck",
    "Captain of the Pequod",
    "Melville captain",
    "Jezebel's husband",
    "Melville captain",
    "One-legged literary character",
    "Starbuck's orderer",
    "Queequeg's captain",
    "Captain of literature",
    "Captain of the Pequod",
    "\"Moby Dick\" captain",
    "\"Moby Dick\" captain",
    "\"Moby-Dick\" captain",
    "Ishmael?Æs skipper",
    "\"A grand, ungodly, godlike man\" in fiction",
    "Obsessed captain",
    "Obsessive hunter of fiction",
    "Ishmael's captain",
    "Melville mariner",
    "\"Moby-Dick\" captain",
    "Pequod skipper",
    "\"Moby Dick\" whaler",
    "Pequod captain",
    "Whale watcher",
    "Pequod captain",
    "Captain for Stubb and Fedallah",
    "1956 Peck role",
    "Melville megalomaniac",
    "Fictional hunter",
    "Captain with a whalebone leg",
    "Captain of the Pequod",
    "Fictional whaler",
    "Whaler of fiction",
    "King of Kings",
    "Starbuck's skipper",
    "Ishmael's captain",
    "Pequod captain",
    "1956 Gregory Peck role",
    "Jezebel's husband",
    "Peck role of 1956",
    "Starbuck's superior",
    "King who married Jezebel",
    "Captain with the \"overbearing dignity of some mighty woe\"",
    "Jezebel's husband",
    "Fictional captain with an ivory leg",
    "Captain of the Pequod",
    "Famed whaler",
    "King in I Kings",
    "Moby-Dick chaser",
    "Queequeg's captain",
    "Starbuck's captain",
    "Obsessive whaler",
    "Whale of a captain?",
    "1956 Peck role",
    "Captain obsessed",
    "Pequod skipper",
    "Subject of Ishmael's narration",
    "Captain of literature",
    "Husband of Jezebel",
    "Pequod's captain",
    "Melville hero",
    "Jezebel's husband",
    "Pequod captain"
  ],
  "wiki": [
    "Community-maintained website",
    "Collaborative online reference",
    "Collaboratively written page",
    "User-edited web page",
    "Collaborative site",
    "Collaborative online site",
    "Collaborative online reference",
    "Reference page edited by a group",
    "Modern encyclopedia platform",
    "Popular online reference, for short",
    "Site for online collaboration",
    "Collaborative Web project",
    "Web site that users themselves may revise",
    "Open-ended reference",
    "Collaborative Web site",
    "User-edited Web site",
    "User-edited online reference"
  ],
  "tint": [
    "Slight coloring",
    "Limo window feature",
    "Shade",
    "Window option",
    "Colorist's concern",
    "Slight coloring",
    "Specs spec",
    "Windshield feature",
    "Color shade",
    "Shade",
    "Salon job",
    "Hue",
    "Auto option patented by 3M",
    "Salon job",
    "Slight coloration",
    "Formatting palette choice",
    "Slight coloring",
    "Photoshop addition",
    "Coloring",
    "Window treatment",
    "Feature of some lenses",
    "Glare preventer",
    "Windshield glare reducer",
    "Shade of color",
    "Salon job",
    "Salon job",
    "Paint choice",
    "Color lightly",
    "Salon job",
    "TV control",
    "Glare reducer",
    "Sunroof option",
    "Windshield feature",
    "Hue",
    "TV control",
    "Windshield feature",
    "Glasses option",
    "Salon job",
    "Salon application",
    "TV control",
    "Moonroof feature",
    "Windshield option",
    "Affect in a subtle way",
    "Feature of some glasses",
    "Shade",
    "Windshield option",
    "Salon request",
    "Salon request",
    "Salon job",
    "Eyeglasses option",
    "Pinkish yellow, e.g.",
    "Salon offering",
    "Windows application?",
    "Salon job",
    "Sunglasses feature",
    "Windshield option",
    "Hair colorer",
    "Clairol choice",
    "Windshield feature",
    "TV knob",
    "Spec for some specs",
    "TV knob",
    "Add color to",
    "Salon service",
    "TV knob",
    "Salon job",
    "Salon application",
    "Salon job",
    "TV knob",
    "Salon job",
    "Shade",
    "Hue",
    "TV control knob",
    "Salon offering"
  ],
  "ills": [
    "Societal problems",
    "Woes",
    "Targets of social reform",
    "Society's woes",
    "Woes",
    "Misfortunes",
    "Pandora released them",
    "Misfortunes",
    "They're no good",
    "Afflictions",
    "Troubles",
    "Societal problems",
    "Pandora's release",
    "Contents of Pandora's box",
    "Pains",
    "Societal troubles",
    "Pandora release",
    "Woes",
    "Woes",
    "Evils",
    "Misfortunes",
    "Drought, poverty and such",
    "Misfortunes",
    "Plagues",
    "Woes of society",
    "Misfortunes",
    "Shangri-la's lack",
    "Things to cure",
    "Troubles",
    "Woes",
    "Pain and suffering",
    "Misfortunes",
    "War, famine, etc.",
    "Drugs and crime, e.g.",
    "Woes",
    "Distresses",
    "Woes",
    "Misfortunes",
    "Woes",
    "Targets of remedies",
    "Subject of therapy",
    "Contents of Pandora's box, except for hope",
    "Poverty, pollution and such",
    "They're no good",
    "Tribulations",
    "Woes",
    "Sorry situations",
    "Pandora's boxful",
    "Woes",
    "Woes",
    "Troubles",
    "Troubles",
    "Misfortunes",
    "Poverty and war",
    "They're not good",
    "Sorry situations",
    "Troubles",
    "Panacea's targets",
    "Pandora's release",
    "Pandora's boxful",
    "Some are social",
    "Societal woes",
    "Misfortunes",
    "Troubles",
    "Unhappy happenings",
    "Pandora's boxful",
    "Misfortunes",
    "Troubles",
    "Misfortunes",
    "Woes",
    "Troubles",
    "Pandora's release",
    "Social problems",
    "Reform targets",
    "Problems, problems",
    "Misfortunes",
    "Problems",
    "Harms",
    "Troubles",
    "Problems",
    "Woes of the world",
    "Pandora's boxful",
    "Misfortunes",
    "Miseries",
    "Misfortunes",
    "Troubles",
    "Misfortunes",
    "Reformers' targets",
    "Woes",
    "Woes",
    "Troubles",
    "Afflictions",
    "Troubles",
    "Miseries"
  ],
  "tbsp": [
    "Recipe amt.",
    "One of 256 in a gal.",
    "Shortening used in recipes?",
    "Amt. of cooking oil",
    "Medicinal qty.",
    "Cookbook amt.",
    "1/16 of a cup: Abbr.",
    "1/8 of a standard stick of butter: Abbr.",
    "Rx dose: Abbr.",
    "Recipe measure: Abbr.",
    "1/256 of a gal.",
    "Amt. of cooking oil, maybe",
    "Recipe amt.",
    "Recipe amt.",
    "Recipe amt.",
    "Dosage amt.",
    "Measure of sugar: Abbr.",
    "Recipe abbr.",
    "Recipe abbr.",
    "Shortening used in recipes",
    "0.5 fl. oz.",
    "Med. dose",
    "Medicinal amt.",
    "Recipe abbr.",
    "Shortening in the kitchen?",
    "Half-oz.",
    "Cookbook amt.",
    "15-ml unit",
    "Recipe abbr.",
    "Kitchen meas.",
    "1/16 of a cup: Abbr.",
    "One sip, maybe: Abbr.",
    "Cook's meas.",
    "Recipe amt.",
    "Recipe amt.",
    "1/2 fluid oz.",
    "Cookbook abbr.",
    "Cookbook abbr.",
    "Recipe abbr.",
    "Cooking meas.",
    "Recipe amt."
  ],
  "acct": [
    "The \"A\" of I.R.A.: Abbr.",
    "Bank statement no.",
    "No. on a bank statement",
    "The \"A\" of I.R.A.: Abbr.",
    "Bank no.",
    "Bank no.",
    "No. at an insurance agency",
    "Statement no.",
    "___ no.",
    "Website subscriber's creation: Abbr.",
    "Saver's bank holding: Abbr.",
    "A debit card is linked to one: Abbr.",
    "Emailer's need: Abbr.",
    "___ no.",
    "A teller might update it: Abbr.",
    "It has a balance: Abbr.",
    "Bank holding: Abbr.",
    "No. at a brokerage",
    "Bank no.",
    "Bank no.",
    "Thing pulled up in a bank: Abbr.",
    "Bank no.",
    "Bank no.",
    "Billing no.",
    "It may require a password: Abbr.",
    "A.T.M. receipt abbr.",
    "No. on a bank statement",
    "The \"A\" of I.R.A.: Abbr.",
    "Ledger no.",
    "No. on a bank statement",
    "Part of I.R.A.: Abbr.",
    "___ no.",
    "C.D., e.g.: Abbr.",
    "I.R.A. part: Abbr.",
    "The \"A\" of I.R.A.: Abbr.",
    "Billing abbr.",
    "Depositor's holding: Abbr.",
    "The \"A\" in I.R.A.: Abbr.",
    "Bank statement no.",
    "___ no. (bank info)",
    "Insurer's file: Abbr.",
    "Brokerage file: Abbr.",
    "It's accessed with a no.",
    "Broker's file: Abbr.",
    "Depositor's holding: Abbr.",
    "It's got your number: Abbr.",
    "I.R.A. part: Abbr.",
    "It may be payable: Abbr.",
    "Billing abbr.",
    "Bank no.",
    "C.D., for one",
    "Part of I.R.A.: Abbr.",
    "What to make a dep. into",
    "___ exec.",
    "Svgs. ___",
    "It builds int.",
    "S&L deposit",
    "Bank I.R.A., e.g."
  ],
  "rowe": [
    "Mike of TV's \"Dirty Jobs\" and \"Somebody's Gotta Do It\"",
    "Investment firm T. ___ Price",
    "Mike who hosted \"Dirty Jobs\"",
    "Host Mike of \"Dirty Jobs\" and \"Somebody's Gotta Do It\"",
    "Investment firm T. ___ Price",
    "Investment firm T. ___ Price",
    "\"Dirty Jobs\" host Mike",
    "Host Mike of the Discovery Channel's \"Dirty Jobs\"",
    "\"Tamerlane\" dramatist Nicholas",
    "T. ___ Price (investment firm)",
    "T. ___ Price (investment firm)",
    "Investment firm T. ___ Price",
    "Investment house T. ___ Price",
    "Playwright who coined the term \"lothario\"",
    "Investment firm T. ___ Price",
    "\"Lady Jane Grey\" dramatist, 1715",
    "Brokerage house T. ___ Price",
    "English poet who wrote \"Lady Jane Grey\"",
    "T. ___ Price of finance",
    "British poet laureate, 1715-18",
    "\"Tamerlane\" dramatist Nicholas",
    "English poet laureate Nicholas",
    "\"Tamerlane\" tragedian",
    "\"Tamerlane\" playwright Nicholas",
    "Poet laureate Nicholas",
    "Poet laureate Nicholas"
  ],
  "atoi": [
    "Yours: Fr.",
    "Yours, in Tours",
    "Yours, to Yves",
    "\"Merci ___ aussi\"",
    "Yours, to Yvette",
    "Yours, in Tours",
    "Yours, in Tours",
    "Yours, in Tours",
    "What's yours in Montreal?",
    "French possessive",
    "Yours, in Tours",
    "Yours, in Tours",
    "To you, in Toulouse",
    "Yours, in Paris",
    "Parisian possessive",
    "Yours, in Tours",
    "Yours, in Ypres",
    "Yours, in Paris",
    "Yours, in Ypres",
    "Yours, in Tours",
    "Parisian possessive",
    "Yours, to Yves",
    "Tours \"yours\"",
    "Yours, in Tours",
    "Yours, in Giverny",
    "Yours, overseas",
    "Yours, in Nemours",
    "Yours: Fr.",
    "Yours, in Ypres",
    "Yours, in Tours",
    "French possessive",
    "Yours, to Yvette",
    "Yours, in Tours",
    "Pierre's yours",
    "Yours, to Yvette",
    "French possessive",
    "To you, to Yves",
    "Yours: Fr.",
    "Yours, in Tours",
    "Yours, en francais",
    "Yours, in Tours",
    "Yours, in Yonne",
    "Yours, in Tours",
    "Yours, in Paris",
    "Yours, in Paris",
    "Yours, in Tours",
    "To you, to Yves"
  ],
  "film": [
    "Cinema showing",
    "\"Argo\" or \"Fargo\"",
    "Old camera need",
    "___ Studies (college major)",
    "Take some shots",
    "Canon fodder?",
    "\"Annie\" or \"Annie Hall\"",
    "What each completed pair of theme answers in this puzzle is",
    "What soap may leave",
    "Kodak product",
    "Thin haze",
    "Certain residue",
    "Thin veneer",
    "Eastman invention",
    "\"M\" or \"V\"",
    "Shoot",
    "Medium of this puzzle's theme",
    "Patina"
  ],
  "lynx": [
    "Forest feline",
    "Minnesota W.N.B.A. team",
    "Snowshoe hare predator",
    "Wildcat with tufted ears",
    "Wildcat with tufted ears",
    "Cat with tufted ears",
    "Former Mercury",
    "Wildcat with tufted ears",
    "Wildcat",
    "Mercury model",
    "Cat with tufted ears",
    "Big cat",
    "Wildcat",
    "Sharp-sighted animal",
    "Short-tailed wildcat",
    "Wildcat",
    "Wildcat",
    "Wildcat",
    "___ eyed (sharp-sighted)",
    "Mountain cat"
  ],
  "kias": [
    "Optimas and Souls, in the auto world",
    "Souls, e.g.",
    "Hyundai alternatives",
    "Rios, e.g.",
    "Optima and Sedona",
    "Rondos, e.g.",
    "Some Korean exports",
    "Sorento and Sedona",
    "Big Korean exports",
    "Cars from Korea",
    "Rio and Sedona",
    "Sephia and Sportage",
    "Sorento and Sedona",
    "Korean autos"
  ],
  "rtes": [
    "GPS suggestions: Abbr.",
    "66 and others: Abbr.",
    "GPS suggestions: Abbr.",
    "Waze ways: Abbr.",
    "GPS suggestions: Abbr.",
    "UPS drivers' assignments: Abbr.",
    "Lines on a road map: Abbr.",
    "Hwys.",
    "Some delivery drivers' plans: Abbr.",
    "Some P.O. plans",
    "Arteries: Abbr.",
    "They're marked on maps: Abbr.",
    "Map lines: Abbr.",
    "Shipping specifications: Abbr.",
    "Hwys.",
    "GPS options: Abbr.",
    "U.P.S. driver assignments: Abbr.",
    "GPS recommendations: Abbr.",
    "51-Down and others: Abbr.",
    "Travel plans: Abbr.",
    "Shippers' plans: Abbr.",
    "A.A.A. courses",
    "GPS suggestions: Abbr.",
    "U.S. 1 and others: Abbr.",
    "Hwys.",
    "Blvds. and rds.",
    "GPS offerings: Abbr.",
    "72-Across and others: Abbr.",
    "GPS calculations: Abbr.",
    "Hwys.",
    "GPS choices: Abbr.",
    "GPS options: Abbr.",
    "Parade paths: Abbr.",
    "Hwys.",
    "Delivery persons' assignments: Abbr.",
    "Itinerary parts: Abbr.",
    "Delivery drivers' assignments: Abbr.",
    "Itinerary segments: Abbr.",
    "Ways to go: Abbr.",
    "Lines: Abbr.",
    "G.P.S. output: Abbr.",
    "66 and others: Abbr.",
    "MapQuest suggestions: Abbr.",
    "U.S. 1 and others: Abbr.",
    "G.P.S. output: Abbr.",
    "Ways: Abbr.",
    "MapQuest suggestions: Abbr.",
    "Some are rural: Abbr.",
    "Sts. and rds.",
    "A.A.A. recommendations: Abbr.",
    "Mail carriers' assignments: Abbr.",
    "Rounds: Abbr.",
    "Short ways to go?",
    "Numbered hwys.",
    "Delivery lines: Abbr.",
    "A.A.A. options",
    "Numbered rds.",
    "Numbered rds.",
    "U.P.S. drivers have them",
    "Drivers' choices: Abbr.",
    "Travel plans: Abbr.",
    "A.A.A. recommendations: Abbr.",
    "A.A.A. info",
    "Ways: Abbr.",
    "Itinerary abbrs.",
    "U.S. hwys., e.g.",
    "Numbered rds.",
    "Carriers' paths: Abbr.",
    "Numbered hwys.",
    "Many begin with I: Abbr.",
    "Post office chart: Abbr.",
    "Hwys.",
    "Itinerary: Abbr.",
    "Blvds. and rds.",
    "Direction givers' suggestions: Abbr.",
    "Hwys.",
    "Hwys.",
    "P.O. employees have them",
    "I-80 et al.",
    "Mail carriers' paths: Abbr.",
    "I-80 and U.S. 10, e.g.",
    "Postal workers have them: Abbr.",
    "Numbered rds.",
    "Triptik abbrs.",
    "A.A.A. suggestions",
    "Paper deliverers have them: Abbr.",
    "U.S. 1 and others",
    "A.A.A. suggestions",
    "Mail delivery paths: Abbr.",
    "A.A.A. recommendations",
    "Mail carriers have them: Abbr.",
    "Tpkes.",
    "U.S. 1 and others",
    "Address nos.",
    "Parts of itineraries: Abbr.",
    "Nos. on a road map",
    "Tpks.",
    "1 and 66, e.g.: Abbr.",
    "Numbered hwys.",
    "Hwys.",
    "Nos. in a travel guide",
    "Itinerary segs.",
    "Address nos.",
    "Map features: Abbr.",
    "Maps rds.",
    "66 and others: Abbr.",
    "Map abbrs.",
    "Tpks.",
    "Hwy. numbers",
    "Atlas lines: Abbr.",
    "A.A.A. offerings",
    "Map lines: Abbr.",
    "Fwys.",
    "Itineraries: Abbr.",
    "Blvds. and rds.",
    "Hwys.",
    "Hwys."
  ],
  "lisa": [
    "Simpson who is a Buddhist and a vegetarian",
    "Vanderpump of Bravo's \"Vanderpump Rules\"",
    "Daughter of Steve Jobs, after whom an early Apple computer was named",
    "___ Genova, author of \"Still Alice\"",
    "Lopes of R&B's TLC",
    "1983 Apple computer with a girl's name",
    "Most popular baby girl's name of the 1960s, per the Social Security Administration",
    "See 92-Across",
    "Leslie in the Women's Basketball Hall of Fame",
    "Toon named after one of Matt Groening's sisters",
    "Cartoon character who plays a saxophone",
    "Emmy-winning Kudrow",
    "\"The Simpsons\" girl",
    "Kudrow of \"Friends\"",
    "Crossword-solving girl on \"The Simpsons\"",
    "Simpson with a high I.Q.",
    "\"Homer and ___ Exchange Cross Words\" (2008 episode of \"The Simpsons\")",
    "Simpson who lost a crossword contest in 2008",
    "\"The Simpsons\" girl",
    "When repeated, singer of the 1987 #1 hit \"Head to Toe\"",
    "Bart Simpson's sister",
    "Bart's toon sister",
    "\"The Simpsons\" second grader",
    "___ Leslie, three-time W.N.B.A. M.V.P.",
    "Bart's intelligent sister",
    "\"Mona ___\"",
    "Simpson who said \"Beneath my goody two shoes lie some very dark socks\"",
    "Toon who plays a baritone sax",
    "Phoebe's portrayer on \"Friends\"",
    "Simpson girl",
    "Simpson girl",
    "\"Mona ___\"",
    "Bart Simpson's brainy sister",
    "Alaska senator Murkowski",
    "Actress Edelstein of TV's \"House\"",
    "Brainy Simpson",
    "Bart Simpson's brainy sister",
    "\"The Simpsons\" girl",
    "Marge's TV daughter",
    "Alaska senator Murkowski",
    "Precursor of the Apple Macintosh",
    "\"Mona ___\"",
    "Kudrow of \"Friends\"",
    "Kudrow of \"Friends\"",
    "\"I'm Not ___,\" 1975 #1 country hit by Jessi Colter",
    "\"The Simpsons\" brainiac",
    "Sen. Murkowski of Alaska",
    "Phoebe's player on \"Friends\"",
    "Elvis's daughter ___ Marie",
    "Soprano Saffer",
    "Heroine of Tchaikovsky's \"Pique Dame\"",
    "\"Stay\" singer ___ Loeb",
    "\"The Simpsons\" saxophonist",
    "With 49-Across, singer with the 1997 hit \"I Do\"",
    "1990's pop singer Stansfield",
    "Actress Kudrow",
    "Kudrow of \"Friends\"",
    "One of the Simpsons",
    "Actress Bonet",
    "Actress Kudrow",
    "A Simpson sister",
    "Phoebe's portrayer on \"Friends\"",
    "Sax-playing Simpson",
    "A Simpson",
    "Oliver's wife on \"Green Acres\"",
    "Actress Kudrow",
    "Soprano ___ Della Casa",
    "One of the Simpsons",
    "Actress Kudrow",
    "Actress Bonet",
    "One of the Simpsons",
    "\"Mona ___\"",
    "\"Yanks\" star Eichhorn",
    "One of the Simpsons",
    "Daughter of Homer and Marge",
    "\"Mona ___\"",
    "One of TV's Simpsons",
    "\"Mona ___\"",
    "\"The Official Preppy Handbook\" author Birnbach",
    "Daughter of Homer",
    "One of \"The Simpsons\"",
    "Actress Bonet",
    "___ Marie Presley",
    "Pop singer Stansfield",
    "___ Marie Presley-Jackson",
    "Macintosh predecessor",
    "\"Mona ___\"",
    "Broadway's Kirk",
    "One of the Simpsons",
    "One of the Simpsons",
    "'63 film \"David and ___\"",
    "Mona ___"
  ],
  "sold": [
    "Last word at an auction",
    "Convinced",
    "Auctioneer's cry",
    "Convinced",
    "Successful auctioneer's last word",
    "Completely convinced",
    "Convinced",
    "Red-lettered announcement added to a real estate sign",
    "Auctioneer's cry when dropping the hammer",
    "Cry when an auctioneer brings down the gavel",
    "\"Storage Wars\" cry",
    "Persuaded",
    "Convinced",
    "Convinced",
    "Gavel wielder's word",
    "Realtor's favorite sign",
    "Fully convinced",
    "Cry accompanied by a gavel rap",
    "Tag in an antique store",
    "Cry after the rap of a hammer",
    "Convinced",
    "\"Deal!\"",
    "Off the market",
    "Gone",
    "Auctioneer's shout",
    "\"You've got yourself a deal\"",
    "Cry from one using a hammer",
    "Convinced",
    "Cry from the block",
    "Auctioneer's closing word",
    "Persuaded",
    "\"You've got a deal!\"",
    "Tag word",
    "Convinced",
    "Auction cry",
    "Auction cry",
    "Cry of a person with a hammer",
    "Auctioneer's final word",
    "Antique show sign",
    "Convinced",
    "Unloaded, in a way",
    "Gung-ho (on)",
    "Auctioneer's closing word",
    "Shout from the block",
    "\"Gone,\" at an auction",
    "Realtor sign add-on",
    "Auctioned off",
    "Auctioneer's last word",
    "Marketed",
    "Sign at an antique shop",
    "Realty sign",
    "Auctioneer's cry",
    "Talked into"
  ],
  "kant": [
    "Philosopher Immanuel",
    "\"Critique of Pure Reason\" philosopher",
    "\"Critique of Pure Reason\" philosopher",
    "Philosopher Immanuel",
    "Philosopher Immanuel",
    "Philosopher who asked \"What is enlightenment?\"",
    "Transcendental aesthetic developer",
    "\"The Metaphysics of Morals\" author",
    "\"Copernican revolution\" philosopher",
    "\"Perpetual Peace\" thinker",
    "Categorical imperative philosopher",
    "\"Do what is right, though the world may perish\" writer",
    "Hume influenced him",
    "\"Critique of Judgment\" writer",
    "Philosophy 101 subject",
    "Konigsberg philosopher",
    "Writer who coined the phrase \"categorical imperative\"",
    "German philosopher Immanuel",
    "German philosopher",
    "\"Critique of Practical Reason\" writer",
    "\"Metaphysics of Morals\" writer",
    "\"Kritik der reinen Vernunft\" author",
    "\"Critique of Pure Reason\" writer",
    "?ôMetaphysics of Morals?ö author",
    "Enlightenment philosopher",
    "\"Perpetual Peace\" writer",
    "\"Critique of Pure Reason\" author",
    "Philosopher from Konigsberg"
  ],
  "list": [
    "What Santa checks twice",
    "Something Santa makes (and checks twice)",
    "Something Santa makes (and checks twice)",
    "Lean",
    "Lean, as a ship",
    "Roster",
    "Officially go (for)",
    "Manifest, e.g.",
    "Itemize",
    "Tilt",
    "Not be upright",
    "Errand-running aid",
    "It's just one thing after another",
    "Cant",
    "It may have check marks",
    "Tick off",
    "It may have bullets",
    "Not discounted",
    "Tick off",
    "Tilt",
    "Grocery shopper's aid",
    "Not stay fully upright",
    "Not discounted",
    "Santa checks his twice, in song",
    "It's just one thing after another",
    "Telephone book, essentially",
    "Aid to Santa",
    "Telemarketing aid",
    "Organizing aid",
    "Lean",
    "Roll",
    "Shopper's aid",
    "\"Schindler's ___\"",
    "Pros or cons, e.g.",
    "What Santa's making (and checking twice)",
    "Lean",
    "Shopper's aid",
    "Word with back or black",
    "Directory",
    "___ price",
    "Shopping aid",
    "Santa's reference",
    "Mailing to Santa",
    "Catalog",
    "Kind of price",
    "Tick off",
    "Top 10, e.g.",
    "Letter-to-Santa enclosure",
    "Santa checks it twice",
    "\"Schindler's ___\"",
    "Wish ___",
    "Tabularize",
    "Table of contents, e.g.",
    "It's just one thing after another",
    "Bibliography, basically",
    "Santa checks it twice",
    "Aid for Santa",
    "Incline",
    "Telephone book, essentially",
    "Register",
    "Tilt to one side",
    "Schindler's aid",
    "Shopping aid",
    "Aid for Santa",
    "Schindler document",
    "Roster",
    "Shopping aid",
    "Schindler's request",
    "Not discounted",
    "Tilt",
    "Letterman's \"Top Ten,\" e.g."
  ],
  "bail": [
    "___ reform, cause for the Marshall Project",
    "Free money?",
    "It can get you out of a place it rhymes with",
    "Try to stay afloat, perhaps",
    "Abandon an effort, in slang",
    "Help (out)",
    "Totally abandon one's plan",
    "Depart unceremoniously, in slang",
    "Skip out (on)",
    "Something an arrested person tries to \"make\"",
    "Get-out-of-jail money",
    "Trust fund?",
    "Get-out-of-jail money",
    "Help (out)",
    "What some bondsmen offer",
    "Something it's against the law to jump",
    "Dump water overboard",
    "Help (out)",
    "Cost to get out of 46-Across",
    "Throw out water",
    "Leave completely, in slang",
    "It's limited by the Eighth Amendment",
    "Get-out-of-jail money",
    "It may be skipped or jumped",
    "It may be jumped",
    "It may be forfeited",
    "Jump (out), as from a plane",
    "Keep a leaky boat afloat",
    "Release money",
    "Get-out-of-jail money",
    "Get-out-of-jail money",
    "Kind of bond",
    "Get-out-of-jail money",
    "Scoop (out)",
    "Get-out-of-jail money"
  ],
  "bucs": [
    "2021 Super Bowl champs",
    "Florida gridders, in headlines",
    "Tampa Bay players, familiarly",
    "Tampa team, informally",
    "Tampa Bay gridders, for short",
    "Tampa Bay players, for short"
  ],
  "deli": [
    "Where heroes are made",
    "Where you might find the starts of 18-, 26-, 44- and 59-Across",
    "Spot for a slicer",
    "Grocery section",
    "Place that may have your number",
    "Sandwich shop",
    "___ counter",
    "Place to get a knish",
    "Place to order bagels and lox",
    "Kosher ___",
    "Supermarket section",
    "Supermarket section",
    "New York's Katz's, for one",
    "Scale site",
    "Place to order a ham on rye",
    "Grocery section",
    "Supermarket section",
    "Substation?",
    "Supermarket section",
    "Carnegie ___ (famous eatery)",
    "Eatery with scales",
    "Place where people pick lox?",
    "Brat's place",
    "Bagel and lox purveyor",
    "Store where you might take a number",
    "Kind of counter",
    "Where heroes are made",
    "Lunchtime queueing spot",
    "Shop with egg salad and cold cuts",
    "Place to take a number, maybe",
    "\"Take a number\" place",
    "Place for a ham",
    "What may have the makings of a hero?",
    "Supermarket work station",
    "___ meat",
    "Place with \"Now Serving\" numbers",
    "Where a torpedo may be made",
    "Shop with 53-Across",
    "Reuben server",
    "Where a tongue can be found",
    "Hero maker",
    "Place to get a Reuben",
    "Slicer locale",
    "Hero's place",
    "Place to get an egg salad sandwich",
    "Hello ___, shop frequently seen on Letterman",
    "Sub station?",
    "Submarine base?",
    "Place to order a ham on rye",
    "Torpedo's place",
    "New York's Carnegie ___",
    "Poor boy provider",
    "Supermarket section",
    "Place for a counter claim?",
    "Party platter preparer",
    "Pastrami purveyor",
    "New York's Carnegie ___",
    "Meat slicer locale",
    "Swiss vendor",
    "Kosher ___",
    "Supermarket section",
    "Place for a bagel and a schmear",
    "Where to order egg salad",
    "Place for a ham",
    "Sub builder?",
    "Kosher ___",
    "Pastrami purveyor",
    "Swiss section",
    "Sandwich shop",
    "Place with a takeout line",
    "Sub builder",
    "Supermarket section",
    "Where a submarine might be spotted",
    "Sandwich shop",
    "Where heroes may be found",
    "Carnegie ___",
    "Sandwich shop",
    "Sub shop",
    "Bologna home",
    "Supermarket part",
    "Prosciutto purveyor",
    "Meat slicer site",
    "Site for slicers",
    "Where to find a hero",
    "Supermarket section",
    "New York's Carnegie ___",
    "Corner store",
    "Sandwich seller",
    "Where Swiss may be found",
    "Shop that slices meat",
    "Links locale",
    "Supermarket feature",
    "It may be kosher",
    "Where to find a hero",
    "Slicer site",
    "Hero maker",
    "Pickle purveyor",
    "Supermarket section",
    "Place to pick up a sandwich",
    "Where cold cuts are cut",
    "Place for a hero",
    "Pastrami place",
    "Takeout lunch provider",
    "Supermarket section",
    "Pickle place",
    "Supermarket section",
    "Sub station?",
    "Sandwich shop",
    "Where to nosh on a knish",
    "Where to get a hoagy",
    "Cold-cuts section",
    "Supermarket section",
    "Takeout shop",
    "Supermarket department",
    "Fast-food place",
    "Bagel shop",
    "Sub station?",
    "Sandwich shop",
    "Where heros are made",
    "Sandwich shop",
    "Kind of loaf",
    "It's full of baloney",
    "Hero's place",
    "Takeout shop",
    "Sub outlet"
  ],
  "bags": [
    "Birkin stock?",
    "Sacks",
    "Captures",
    "Luggage",
    "Suitcases",
    "Items checked at the airport",
    "Pieces of luggage",
    "Luggage",
    "First, second and third, but not fourth",
    "Suitcases",
    "Luggage",
    "Pocketbooks",
    "Diamond bases",
    "Carousel contents",
    "Trousers, slangily",
    "Supermarket supplies",
    "Does work at a checkout line",
    "They may be checked",
    "Captures",
    "Redcap's burden",
    "Catches",
    "Grocery holders",
    "Catches, as game",
    "Luggage",
    "Catches",
    "Luggage"
  ],
  "rite": [
    "Quinceañera, e.g.",
    "Hajj, e.g.",
    "The eucharist, e.g.",
    "Religious act",
    "Ceremony",
    "___ of passage",
    "It's a tradition",
    "Church observance",
    "Baptism, e.g.",
    "Any sacrament",
    "Initiation, e.g.",
    "Exorcism, e.g.",
    "Communion, e.g.",
    "Confirmation, e.g.",
    "Having your first shave or buying your first bra, e.g.",
    "Bar mitzvah or communion",
    "Bar mitzvah, e.g.",
    "Initiation practice",
    "Coronation, e.g.",
    "Quinceanera, for 15-year-old girls",
    "Something observed in church",
    "Ceremony",
    "Confirmation, e.g.",
    "Bar mitzvah or communion",
    "Mass observance",
    "Confession, e.g.",
    "Sacrifice, e.g.",
    "Religious act",
    "___ of passage",
    "Commencement, e.g.",
    "Ablution, e.g.",
    "It may be performed by people in robes",
    "Dedication, e.g.",
    "Bris or baptism",
    "Mass event",
    "Diet ___",
    "Bar mitzvah or communion",
    "One may provide passage",
    "Mass action",
    "Baptism, e.g.",
    "Baby naming, e.g.",
    "Religious observance",
    "It's a tradition",
    "Baptism or bar mitzvah",
    "Marriage, for one",
    "Observance",
    "Graduation, e.g.",
    "Bar mitzvah or bris",
    "Sacrament, e.g.",
    "Book of Hours entry",
    "Initiation, e.g.",
    "What a priest may prepare for",
    "Altar procedure",
    "Observance",
    "Graduation or confirmation",
    "Exorcism, e.g.",
    "Baptism or bar mitzvah",
    "Initiation, e.g.",
    "Liturgy",
    "Baptism or bar mitzvah",
    "Ceremony",
    "Benefit of clergy?",
    "Ceremony",
    "Formal ceremony",
    "Marriage, e.g.",
    "Altar happening",
    "Baptism or bris",
    "Baby naming, e.g.",
    "Observance",
    "Solemnity",
    "Ordination, e.g.",
    "Bris or confirmation",
    "Wedding, e.g.",
    "Passage prerequisite, perhaps",
    "Religious ceremony",
    "Baptism, for one",
    "Bris, e.g.",
    "Baptism or bris",
    "Bar mitzvah, e.g.",
    "Confirmation, for one",
    "Ceremonial act",
    "Initiation procedure",
    "Confession, e.g.",
    "Baptism, e.g.",
    "Initiation, e.g.",
    "Baptism, for one",
    "___ of passage",
    "Confirmation, e.g.",
    "Communion, e.g.",
    "Liturgy",
    "Liturgy",
    "Practice",
    "Baptism, for one",
    "Ceremony",
    "___ of passage",
    "Baptism, e.g.",
    "Ceremony",
    "Communion, e.g.",
    "Sacrifice, e.g.",
    "Passage ceremony",
    "Communion or baptism",
    "Marriage, e.g.",
    "Baptism, e.g.",
    "___ of passage"
  ],
  "emil": [
    "Man's name that spells a fruit backward",
    "Jannings who won the first Best Actor Oscar (1928)",
    "Oscar winner Jannings",
    "Boy's name that's a fruit spelled backward",
    "\"___ and the Detectives\" (Disney film)",
    "Disney's \"___ and the Detectives\"",
    "Oscar winner Jannings",
    "Pianist Gilels",
    "Man's name that spells a fruit backward",
    "Jannings who won the first-ever Oscar",
    "Pianist Gilels",
    "Disney boy helped by detectives",
    "Jannings who won the first Best Actor Oscar",
    "Actor Jannings",
    "Jannings who won the first Best Actor Oscar",
    "Oscar winner Jannings",
    "Oscar winner Jannings",
    "___ Zatopek, four-time Olympic track gold medalist",
    "Eagles tight end Igwenagu",
    "Title boy in a 1964 Disney film",
    "Painter Nolde",
    "Pianist Gilels",
    "Disney's \"___ and the Detectives\"",
    "Actor Jannings who won the first Best Actor Oscar",
    "Jannings of \"The Last Command\"",
    "Oscar winner Jannings",
    "Viennese-born composer ___ von Reznicek",
    "Actor Jannings, winner of the first Best Actor Oscar",
    "Jannings of \"The Last Command\"",
    "___ Jannings, Best Actor of 1928",
    "Mathematician Post or Artin",
    "First Best Actor winner Jannings",
    "Runner Zatopek",
    "1928 Oscar winner Jannings",
    "Disney's \"___ and the Detectives\"",
    "Actor Jannings",
    "Disney's \"___ and the Detectives\"",
    "German biographer ___ Ludwig",
    "Czech runner Zatopek",
    "Dr. Skoda of \"Law & Order\"",
    "Expressionist Nolde",
    "Graphic artist Nolde",
    "Title boy in a 1964 Disney film",
    "Painter Nolde",
    "1928 Oscar winner Jannings",
    "Disney's \"___ and the Detectives\"",
    "Napoleon biographer Ludwig",
    "Painter Nolde",
    "German Expressionist ___ Nolde",
    "Runner Zatopek",
    "Disney's \"___ and the Detectives\"",
    "Early Oscar winner Jannings",
    "Oscar winner Jannings",
    "Silents star Jannings",
    "Early Oscar winner ___ Jannings",
    "Jannings of old movies",
    "Pianist Gilels",
    "1948-52 Olympic track gold medalist Zatopek",
    "Oscar winner Jannings",
    "Pianist Gilels",
    "Actor Jannings",
    "1948 and 1952 Olympic track gold medalist ___ Zatopek",
    "Actor Jannings",
    "Literary Ludwig",
    "Painter Nolde",
    "Disney's \"___ and the Detectives\"",
    "Expressionist Nolde",
    "Runner Zatopek",
    "Actor Jannings",
    "Expressionist Nolde",
    "Jannings of \"The Blue Angel\"",
    "German Expressionist Nolde",
    "Czech runner Zatopek",
    "Actor Jannings",
    "Oscar winner Jannings",
    "Silents star Jannings",
    "Actor Jannings",
    "\"Man in Revolt\" author Brunner",
    "Pianist Gilels",
    "Disney's \"___ and the Detectives\"",
    "Biographer Ludwig",
    "Pianist Gilels",
    "Artist Nolde",
    "Oscar winner Jannings",
    "Olympic track champion Zatopek",
    "Pianist Gilels",
    "Disney's \"___ and the Detectives\"",
    "Biographer Ludwig",
    "Pianist Gilels",
    "Biographer Ludwig",
    "Biographer Ludwig",
    "German biographer ___ Ludwig",
    "Disney's \"___ and the Detectives\"",
    "Disney's \"___ and the Detectives\"",
    "Runner Zatopek",
    "Disney's \"___ and the Detectives\"",
    "Actor Jannings",
    "Olympic runner Zatopek",
    "Oscar winner Jannings",
    "Napoleon biographer Ludwig",
    "Jannings of \"The Blue Angel\"",
    "Actor Jannings",
    "Disney's \"___ and the Detectives\"",
    "Actor Jannings",
    "Actor Jannings",
    "Actor Jannings",
    "Actor Jannings",
    "Actor Jannings",
    "Actor Jannings",
    "Co-star for Marlene",
    "Actor Jannings",
    "Nobelist ___ von Behring",
    "German biographer Ludwig",
    "Pianist Gilels",
    "Biographer Ludwig",
    "Medicine Nobelist ___ Von Behring"
  ],
  "doit": [
    "\"Stop stalling!\"",
    "\"Just ___\" (Nike slogan)",
    "\"Get to work!\"",
    "\"I dare you!\"",
    "\"I dare you!\"",
    "\"I dare you!\"",
    "\"Stop procrastinating!\"",
    "Darer's cry",
    "\"Just ___\" (Nike slogan)",
    "\"That's an order!\"",
    "\"Just ___\" (Nike slogan)",
    "\"Just ___\" (47-Across slogan)",
    "\"Go ahead!\"",
    "\"Go ahead!\"",
    "\"Get busy!\"",
    "\"What are you waiting for?!\"",
    "Darer's cry",
    "\"Go ahead!\"",
    "Two-thirds of D.I.Y.",
    "\"Go on!\"",
    "\"Get cracking!\"",
    "Terse order",
    "Prodder's cry",
    "\"Go ahead!\"",
    "\"Act!\"",
    "That's an order",
    "\"Go ahead!\"",
    "\"I dare you!\"",
    "\"I dare you!\"",
    "Exclamation after \"What are you waiting for?\"",
    "\"Go ahead!\"",
    "\"Stop waiting around!\"",
    "\"Act now!\"",
    "\"Go ahead!\"",
    "\"Go ahead!\"",
    "1969 Jerry Rubin book",
    "Just conclusion?",
    "1969 Jerry Rubin book"
  ],
  "uhno": ["Casual, dismissive response", "\"You might think so, but ...\""],
  "jabs": [
    "Digs",
    "Pokes",
    "Biting comments",
    "Quick strikes",
    "Nasty comments",
    "Quick hits",
    "Quick punches",
    "Ring exchange",
    "Barbed comments",
    "Snarky comments",
    "Quick punches",
    "Quick punches",
    "Verbal zingers",
    "Pointed comments",
    "Injures with a pencil, say",
    "Pointed criticisms",
    "Rights, e.g.",
    "A quick left and a quick right",
    "Barbed comments",
    "Needles",
    "Lefts from Louis",
    "Hook alternatives",
    "Quick punches",
    "Barbed comments",
    "Boxer's pokes",
    "Certain punches",
    "Pointed criticisms",
    "Elbows"
  ],
  "alai": [
    "Second word of a game name that rhymes with the first",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Half a game name that rhymes",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "End of a game?",
    "Jai ___",
    "See 58-Across",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan range",
    "Asia's Trans ___ Range",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Half-court game?",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Half court game?",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan range",
    "Jai ___",
    "Jai ___",
    "Asia's Trans ___ Range",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Trans ___ (Kyrgyz/Tajik border range)",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Asia's Trans ___ mountains",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan range",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan range",
    "Jai ___",
    "Jai ___",
    "___ Mountains, near the Tian Shan range",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Asia's Trans ___ range",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan range",
    "Jai ___",
    "Jai ___",
    "Asia's Trans ___ mountains",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Half court game?",
    "Jai ___",
    "Jai ___",
    "Trans ___ (Asian range)",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Half court game?",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyz range",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Asia's Trans ___ mountain range",
    "Jai ___",
    "Jai ___",
    "Game ending?",
    "___ Mountains, part of the Tien Shan range",
    "Kyrgyzstan's ___ Mountains",
    "Jai ___",
    "Jai ___",
    "Part of a court game name",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "See 27-Across",
    "Jai ___",
    "Betting game ending",
    "Jai ___",
    "Asia's ___ Mountains",
    "Jai ___",
    "Asia's Trans ___ Mountains",
    "Jai ___",
    "Jai___",
    "___Mountains",
    "Jai___",
    "Jai___",
    "Kyrgyzstan's___Mountains",
    "Merry, in a game name",
    "Jai___",
    "Jai___",
    "Ball game finale?",
    "Jai___",
    "Jai___",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan's ___ Mountain",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan's ___ Mountains",
    "Jai ___",
    "Jai ___",
    "Kyrgyzstan's ___ Mountains",
    "Jai ___",
    "Kyrgyzstan's ___ Mountains",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyz mountains",
    "Jai ___",
    "Jai ___",
    "Jai ___",
    "Kyrgyz range",
    "Jai ___",
    "Jai ___",
    "Jai ___"
  ],
  "mart": [
    "Part of many a gas station",
    "Bazaar",
    "K follower",
    "Shopper's stop",
    "Gas station adjunct",
    "Place of commerce",
    "Emporium",
    "7-Eleven, e.g.",
    "Mini-___",
    "Gas station adjunct",
    "Many a service station adjunct",
    "Feature of many a gas station nowadays",
    "Five-and-ten, e.g.",
    "Selling point",
    "Bartering locale",
    "Suffix in many store names",
    "Trade center",
    "Shopping site",
    "Shopping locale",
    "Place to trade",
    "Gas station adjunct, often",
    "Shopping place",
    "Part of many a gas station nowadays",
    "K follower",
    "Place to trade",
    "K follower",
    "Retail store",
    "Store",
    "7-Eleven, e.g.",
    "Place of commerce",
    "Shopping place",
    "Grocery",
    "Trading center",
    "Gas station adjunct",
    "See 40-Across",
    "K follower",
    "K follower",
    "Place to trade",
    "Trading center",
    "Trading place",
    "Store cluster",
    "Trade center",
    "Superette, e.g.",
    "Wal-___",
    "Agora",
    "Shopping place",
    "Trading center",
    "Shopping place",
    "K follower",
    "Business hub"
  ],
  "weak": [
    "Like a bad signal",
    "Not very convincing",
    "Diluted",
    "Watered down, as coffee",
    "Watery, say",
    "Diluted",
    "Watered down",
    "Unconvincing",
    "Unsound, as an argument",
    "Impotent",
    "Watered down",
    "Wimpy",
    "Lacking muscle",
    "Unconvincing, as an argument",
    "Unlike Charles Atlas",
    "Watered-down",
    "Lacking strength",
    "Watery",
    "Implausible",
    "Frangible"
  ],
  "urey": [
    "Harold ___, Manhattan Project scientist",
    "Manhattan Project scientist Harold ___",
    "1934 Chemistry Nobelist Harold",
    "1934 Chemistry Nobelist Harold",
    "Chemistry Nobelist Harold",
    "Manhattan Project scientist",
    "1934 Chemistry Nobelist",
    "Chemistry Nobelist Harold",
    "Nobel chemist Harold",
    "1934 chemistry Nobelist"
  ],
  "pate": [
    "Dome",
    "Crown",
    "Fancy spread",
    "Spread out at a banquet?",
    "Crown",
    "Goose liver spread",
    "Fancy party spread",
    "Crostini topping",
    "Savory spread",
    "Cracker spread",
    "Spread out on a table",
    "Fancy spread",
    "Cracker topper",
    "Fancy spread",
    "Cracker topper",
    "Comb-over's locale",
    "Crown",
    "Spread on crackers",
    "Party spread",
    "Top of the head",
    "Brie alternative",
    "It may have a bald spot",
    "Crown",
    "Canape topper",
    "___ feuilletee (puff pastry)",
    "Spread choice",
    "Crown",
    "Certain spread",
    "French spread",
    "Cracker spread",
    "Crown",
    "Spread out at a party?",
    "Chopped liver spread",
    "Place to get conked",
    "Place of hard knocks?",
    "Crown",
    "Crown",
    "Crown",
    "Top of the head",
    "It's chopped liver",
    "Cocktail party spread",
    "Crown",
    "Crown",
    "Noggin",
    "An hors d'oeuvre",
    "Crown",
    "Cracker spread",
    "Crown",
    "Spread",
    "Fancy spread",
    "Crown",
    "Cracker topping",
    "Head",
    "Fancy chopped liver",
    "Crown",
    "Toast topping",
    "Noggin",
    "Hors d'oeuvre spread",
    "Dome",
    "Top of the head",
    "Cracker topper",
    "Cracker topping",
    "Head",
    "Cracker spread",
    "Crown",
    "Cracker spread",
    "Hors d'oeuvre spread",
    "___ de foie gras",
    "Spread for a spread"
  ],
  "flay": [
    "Severely criticize",
    "Savagely criticize",
    "Whip",
    "Whip",
    "Tongue-lash",
    "Whip",
    "Scold severely",
    "Criticize harshly",
    "Scold severely",
    "Skin",
    "Severely scold",
    "Excoriate",
    "Castigate"
  ],
  "pena": [
    "Enrique ___ Nieto, 2012-18 Mexican president",
    "Enrique ___ Nieto, former president of Mexico",
    "Michael who played the title role in 2014's \"Cesar Chavez\"",
    "Reform transportation secretary",
    "Federico of the Clinton cabinet",
    "Enrique ___ Nieto, Mexican president elected in 2012",
    "Secretary of energy under Clinton",
    "Mexican president Enrique ___ Nieto",
    "Slugger Carlos",
    "1980s All-Star catcher Tony",
    "Major-leaguer Tony or Alejandro",
    "Elizabeth of \"Lone Star,\" 1996",
    "Federico of the Clinton cabinet",
    "Former Royals manager Tony",
    "Tony who was the 2003 A.L. Manager of the Year",
    "Federico of Clinton's cabinet",
    "Former Royals manager Tony",
    "1990's transportation secretary",
    "Federico of the Clinton cabinet",
    "Clinton cabinet member Federico",
    "Baseball's Tony or Alejandro",
    "Catcher Tony",
    "Clinton cabinet member",
    "Three-time Gold Glove winner Tony",
    "Elizabeth of \"La Bamba\"",
    "Pitcher Alejandro",
    "Elizabeth of \"La Bamba\"",
    "Ex-Secretary Federico",
    "Former Transportation Secretary Federico",
    "Baseball's Tony or Alejandro",
    "Transportation Secretary beginning 1993",
    "Transportation Secretary Federico",
    "Cabinet member Federico",
    "Actress Elizabeth",
    "Transportation Secretary Federico",
    "Catcher Tony",
    "Baseball's Alejandro",
    "Transportation Secretary Federico",
    "Transportation Secretary Federico ___"
  ],
  "bell": [
    "Class ring?",
    "Drooping flower feature",
    "Bout ender",
    "Old AT&T symbol",
    "Producer of boxing rings",
    "Boxing ring producer",
    "Student's saver",
    "Toll provider?",
    "Sound signaling the start and end of class",
    "Collar attachment",
    "Steeple contents",
    "Man who found his calling?",
    "Carillon component",
    "Round end?",
    "Class stopper",
    "Ringer",
    "End-of-a-round sound",
    "Telephone man",
    "Carillon part",
    "Period ender",
    "Class ender",
    "Unit of nautical time",
    "Bicycle adjunct",
    "13-Down source",
    "Round sound",
    "End-of-class signal",
    "\"Saved by the ___!\"",
    "Dinger",
    "Telephone man",
    "___ Labs",
    "Campanile feature",
    "Big Ben, for one"
  ],
  "ngos": ["CURE and CARE, e.g."],
  "fist": [
    "Hard ball?",
    "Something at the end of the hook?",
    "Symbol of power",
    "It may pack a punch",
    "Word before bump or pump",
    "Punch ingredient?",
    "Hand ball?",
    "Duke",
    "Black Power symbol",
    "Symbol of authority",
    "Symbol of authority",
    "*It may be pumped or bumped",
    "Weapon for 21-Across",
    "Symbol of revolutionary power",
    "Solidarity symbol",
    "Black Power symbol",
    "Jawbreaker?",
    "One pounder",
    "One of two dukes",
    "Duke",
    "Symbol of domination",
    "Threatening sign",
    "Duke",
    "It's hard-hitting",
    "Hook deliverer",
    "It's closed for fighting",
    "70-Across weapon",
    "Pugilist's weapon",
    "Show of anger",
    "Boxer's weapon",
    "Puncher",
    "Fight sight"
  ],
  "debt": [
    "Student ___",
    "Student's burden, often",
    "___ ceiling",
    "Pileup after digging a hole",
    "Red cents?",
    "Matter of great interest for the United States",
    "Restructuring target",
    "Factor affecting a bond's rating",
    "It's nice when it's forgiven",
    "Red ink",
    "What many college students accrue",
    "Economic concern",
    "Word with ceiling or financing",
    "What may grow with interest",
    "Money owed",
    "Obligation",
    "___ relief",
    "Arrears",
    "Visa concern",
    "Something a shopaholic might be in",
    "Student woe",
    "It may be forgiven",
    "College student's accumulation",
    "Amount in an i.o.u.",
    "Kind of ceiling",
    "Hole to be dug out of?",
    "Thing often of interest?",
    "Amount owed",
    "It's not good when it's outstanding",
    "It's indicated in red",
    "2012 campaign issue",
    "Red ink",
    "Money owed",
    "Creation from plastic?",
    "Red ink",
    "It's not good when it's outstanding",
    "Cardholder's woe",
    "Red ink",
    "Credit card balance, e.g.",
    "Figure in red",
    "What shoppers may go into",
    "Amount owed",
    "You may get into it while shopping",
    "Bankruptcy cause",
    "Credit card bills, e.g.",
    "Collector's item?",
    "Bankruptcy cause",
    "Unpaid group of 7-Down",
    "Chapter 11 issue",
    "Cardholder's problem",
    "Borrower's burden",
    "It may be something of great interest",
    "Burden",
    "Card balance",
    "Balance due",
    "Chapter 11 issue",
    "Corporate woe",
    "You're strapped when you're in it",
    "What a marker indicates",
    "Result of some sprees",
    "Card balance",
    "___ of honor",
    "It may be outstanding",
    "It's outstanding",
    "Red ink",
    "Balance sheet item",
    "It's outstanding",
    "___ of gratitude",
    "Reason for imprisonment, once",
    "Charger's acquisition",
    "National ___",
    "Chapter 11 concern",
    "Credit's opposite",
    "Cause for Chapter 11",
    "Money owed",
    "Corporate concern",
    "Government worry",
    "Money owed",
    "Kind of financing",
    "Liability",
    "Financial worry",
    "Due",
    "Corporate problem"
  ],
  "rocs": [
    "Flying monsters in Dungeons & Dragons",
    "Elephant abductors of legend",
    "Fierce fliers of myth",
    "Elephant pluckers of myth",
    "Predators of elephants, in myth",
    "Fearsome birds",
    "Fabulous birds",
    "Birds in the \"Arabian Nights\"",
    "Giants of the sky, in myth",
    "Mythical birds",
    "Avian abductors",
    "Huge-taloned menaces",
    "Titanic-taloned terrorizers",
    "Birds said to feed their young with elephants",
    "Fabled fliers",
    "Mythical birds",
    "Enormous birds of myth",
    "Giant birds of lore",
    "Legendary elephant eaters",
    "Fabled fliers",
    "Fabled fliers",
    "Birds of the Arabian Nights",
    "Fliers in Arabian tales",
    "Mythical fliers",
    "Legendary fliers",
    "Large mythical birds",
    "?ôArabian Nights?ö creatures",
    "Legendary elephant eaters",
    "Enormous birds of myth"
  ],
  "ugly": [
    "Meanspirited",
    "Nasty, as a fight",
    "Like a fairy tale duckling",
    "Like Cinderella's stepsisters",
    "Hideous",
    "\"Things might get ___\"",
    "Like Christmas sweaters, stereotypically",
    "TV's \"___ Betty\"",
    "Vicious, as a fight",
    "Hideous",
    "Hideous",
    "Like Hans Christian Andersen's \"Duckling\"",
    "Unpretty",
    "Hardly fair",
    "Hostile",
    "Frightful",
    "In pre-swan mode",
    "Likely to break out into fighting",
    "Hard on the eyes",
    "Like a 16-Across",
    "Grotesque",
    "Hideous",
    "Hard to watch",
    "Hard on the eyes",
    "Hostile",
    "\"This is going to get ___\"",
    "Beyond homely",
    "Like a 29-Down",
    "Unfair?",
    "Hideous",
    "Like a gargoyle",
    "Unsightly",
    "Unappealing",
    "94-Across's opposite",
    "Hideous",
    "Like many Halloween masks",
    "Hideous",
    "Nasty",
    "\"The ___ American\"",
    "Unsightly",
    "Hideous",
    "Hideous",
    "Ill-favored",
    "Hideous"
  ],
  "sith": [
    "Galactic conquerors of film",
    "Darth Vader, e.g.",
    "Foe of the Jedi",
    "Wielders of the dark side of the Force",
    "Group on the dark side of the Force",
    "Jedi foe",
    "___ Lord (Jedi's foe)",
    "Jedi foes",
    "\"Revenge of the ___\" (\"Star Wars\" subtitle)",
    "Jedi foes",
    "Dark Lord of the ___ (\"Star Wars\" title)",
    "Foes of the Jedi",
    "Jedi foes, in \"Star Wars\"",
    "Jedi enemy",
    "\"Star Wars\" order",
    "Order against the Jedi, in \"Star Wars\""
  ],
  "boas": [
    "Fashion accessories that shed",
    "Showy wraps",
    "Burlesque accessories",
    "Constricting snakes",
    "Colorful neckwear",
    "Fancy items of neckwear",
    "Drag neckwear",
    "Showy accessories",
    "Drag show props",
    "Bright camp wear",
    "Drag queen's collection",
    "Constrictors",
    "Drag show apparel",
    "Camp accouterments",
    "Breathtaking creatures?",
    "Constricting snakes",
    "Fancy wraps",
    "Fancy items worn around the neck",
    "Snakes that constrict",
    "Fluffy scarves",
    "Givers of unfriendly hugs",
    "Feathery scarves",
    "Jungle menaces",
    "Portion of a drag queen's wardrobe",
    "Wraps on stage",
    "Givers of unfriendly hugs",
    "Squeezers",
    "Feathery scarves",
    "Some neckwear",
    "Feathery adornments",
    "Snakes with vestigial limbs",
    "They may hang by the neck",
    "Burlesque show accessories",
    "Feathery scarves",
    "Amazon menaces",
    "Burlesque show props",
    "Jungle crushers",
    "Fluff pieces",
    "Feathery scarves",
    "Some burlesque props",
    "Neck wraps",
    "*Fifth step",
    "Scarves",
    "Jungle squeezers",
    "They wrap their food well",
    "Drag queen's collection",
    "They may have a crush on you",
    "Jungle squeezers",
    "Girlie show props"
  ],
  "lent": [
    "Spring time",
    "Observance once known as Quadragesima (Latin for \"fortieth\")",
    "Spring time",
    "What Carnival precedes",
    "It begins on Ash Wednesday",
    "Religious observance that's also a past-tense verb",
    "Annual winter/spring observance",
    "Giving up time",
    "It begins on Ash Wednesday",
    "Advanced",
    "Fast time",
    "Advanced",
    "Post-Mardi Gras period",
    "Gave for a while",
    "Fast time?",
    "Time to give up?",
    "Time of self-sacrifice",
    "Not given, say",
    "Quitting time?",
    "Mardi Gras follower",
    "Not given permanently",
    "Did a baker's job",
    "Post-Carnival time",
    "Loaned",
    "Time to give up?",
    "Post-Carnival period",
    "Given for a time",
    "Period after Shrove Tuesday",
    "Time to abstain",
    "Carnival follower",
    "Carnival follower",
    "Time of forbearance",
    "Gave temporarily",
    "Spring time",
    "Carnival follower",
    "Time to give up?",
    "Period after Mardi Gras",
    "Time to give up?",
    "Period of abstinence",
    "Fronted, in a way",
    "Spotted",
    "Period of fasting",
    "Mardi Gras follower",
    "Pancake Day is the day before this begins",
    "Gave temporarily",
    "March fast?",
    "Gave for a while",
    "Advanced",
    "Carnival follower",
    "Advanced",
    "Mardi Gras follower",
    "When Quadragesima occurs",
    "Fast time?",
    "Advanced",
    "Passiontide time",
    "It starts on a Wednesday",
    "Time to give up?",
    "Spring season",
    "Advanced",
    "Solemn stretch",
    "Time to give up?",
    "Fast time",
    "Period of penitence",
    "Solemn stretch",
    "Carnival follower",
    "Fast time?",
    "Gave temporarily",
    "Fast time",
    "Carnival follower",
    "Spring period",
    "Gave for a while",
    "Spring stretch",
    "It begins on Ash Wednesday",
    "Loaned",
    "Fast time?",
    "Spring time",
    "When Passion Sunday falls",
    "Fast time",
    "Temporarily gone",
    "Easter preparation",
    "Fasting time",
    "Time to give up",
    "Carnival follower",
    "Solemn time",
    "When Passiontide falls",
    "Let use",
    "Mardi Gras follower",
    "Pre-Easter season",
    "Fast time",
    "Advanced",
    "Pre-Easter period",
    "Advanced",
    "Spring time",
    "Advanced",
    "Carnival's end",
    "Time to give up?",
    "Pre-Easter time",
    "Post-Mardi Gras period",
    "When hot cross buns are eaten",
    "Period of penitence",
    "Mardi Gras follower",
    "Fast time",
    "Easter ends it",
    "Advanced",
    "Fast time",
    "Fast time"
  ],
  "arti": [
    "Beginning of the Constitution: Abbr.",
    "Introductory painting class",
    "Where lines are drawn?",
    "Introductory drawing class",
    "Where to learn to draw?",
    "Introductory drawing class",
    "Basic drawing class",
    "Class for budding painters",
    "Introductory drawing class",
    "Class in which some basic strokes are learned",
    "Intro studio class",
    "Part of the Constitution after the Preamble: Abbr.",
    "Beginning drawing class",
    "Belle ___ (Italian culture)",
    "Beginning drawing class",
    "First drawing class, perhaps",
    "Painter's first class, possibly"
  ],
  "icet": [
    "Rapper with a hyphenated name",
    "Longtime co-star of Mariska Hargitay on \"Law & Order: SVU\"",
    "\"Rhyme Pays\" rapper",
    "\"Law & Order: SVU\" actor",
    "\"Law & Order: SVU\" co-star",
    "Platinum-selling rapper-turned-TV cop",
    "Grammy winner whose name sounds like a beverage",
    "Rapper/actor on \"Law & Order: SVU\"",
    "Rapper whose name sounds like a drink",
    "Portrayer of TV's Det. Fin Tutuola",
    "Tracy Marrow's stage name",
    "One of the first musicians to have an \"explicit content\" sticker on an album",
    "Rapper with the 1999 album \"The Seventh Deadly Sin\"",
    "\"O.G. Original Gangster\" rapper",
    "Rapper-turned-TV actor",
    "Player of TV's Det. Tutuola",
    "\"Law & Order: SVU\" actor",
    "\"Law & Order: SVU\" co-star",
    "Founder of Rhyme $yndicate Records",
    "Celebrity whose name sounds like a drink",
    "\"Home Invasion\" rapper",
    "Rapper/actor whose name sounds like a drink",
    "\"Rhyme Pays\" rapper",
    "\"Law & Order: SVU\" actor",
    "\"O.G. Original Gangster\" rapper",
    "\"Law & Order: SVU\" actor",
    "\"Rhyme Pays\" rapper",
    "Player of Fin Tutuola on TV",
    "One-named rapper-turned-actor",
    "\"Law & Order: SVU\" actor",
    "Actor who made his film debut in \"Breakin',\" 1984",
    "Rapper who co-starred in \"Ricochet\"",
    "\"O.G. Original Gangster\" artist",
    "\"Law & Order: SVU\" co-star",
    "Artist with the first hip-hop album to carry an explicit content sticker",
    "Player of TV's Det. Tutuola",
    "\"Home Invasion\" rapper",
    "\"Cop Killer\" singer who went on to play a cop on TV",
    "His debut album was \"Rhyme Pays\"",
    "\"Rhyme Pays\" rapper",
    "Rapper with the 1988 platinum album \"Power\"",
    "Entertainer born Tracy Marrow",
    "Rapper on \"Law & Order: SVU\"",
    "Rapper/actor on \"Law & Order: SVU\"",
    "Rapper turned actor",
    "\"Law & Order: S.V.U.\" actor",
    "\"Rhyme Pays\" rapper",
    "Rapper with the gold-record album \"O.G. Original Gangster\"",
    "\"Rhyme Pays\" rapper",
    "\"O.G. Original Gangster\" rapper",
    "\"Ricochet\" co-star",
    "He played J-Bone in \"Johnny Mnemonic\"",
    "Noted rapper/actor",
    "\"Rhyme Pays\" singer",
    "\"Ricochet\" actor, 1991",
    "Dr. Dre contemporary",
    "\"New Jack City\" co-star",
    "\"Body Count\" rapper",
    "\"6 'N the Mornin'\" rapper",
    "\"Original Gangster\" rapper",
    "\"Johnny Mnemonic\" actor",
    "Lead singer of the band Body Count",
    "Tracy Marrow's stage name",
    "Self-styled \"Original Gangster\"",
    "Cool-sounding rapper",
    "\"Body Count\" rapper",
    "\"Body Rock\" rapper",
    "Cool rapper",
    "Tracy Marrow, familiarly",
    "Big name in rap",
    "Singer who co-starred in \"Johnny Mnemonic,\" 1995",
    "\"Body Count\" rapper",
    "Rapper in \"Tank Girl\"",
    "80's-90's hip-hop star",
    "\"New Jack City\" star",
    "\"Rhyme Pays\" rapper",
    "\"Original Gangster\" rapper",
    "Tracy Marrow, familiarly",
    "Musician who co-starred in \"Trespass\"",
    "Rapper who co-starred in \"New Jack City\"",
    "\"Rhyme Pays\" rapper",
    "Rapper with the group Body Count",
    "Rapper of note",
    "Rap star/actor",
    "\"Body Count\" rap star",
    "Rapper ne Tracey Marrow",
    "Specialist in 60 Down",
    "\"Ricochet\" co-star",
    "Popular rapper",
    "Noted rap artist"
  ],
  "tugs": [
    "Barge haulers",
    "Harbor boats",
    "Yanks (on)",
    "Big jerks?",
    "Jerks",
    "Pulls",
    "Canal boats",
    "Harbor vessels",
    "Harbor workhorses",
    "Actions on shirtsleeves",
    "Yanks",
    "Water towers?",
    "Actions on heartstrings and pant legs",
    "Liner pushers",
    "Port workhorses",
    "They have a lot of pull",
    "Port boats",
    "Harbor boats"
  ],
  "bohr": [
    "Physics Nobelist who developed an early model of the atom",
    "Physics Nobelist Niels",
    "Physics Nobelist Niels",
    "Physicist who said \"Anyone who is not shocked by quantum theory has not understood it\"",
    "1922 Physics Nobelist",
    "Physics Nobelist Niels",
    "1920s-'30s debate opponent of Einstein",
    "Danish Nobelist",
    "Nobelist name of 1922 and 1975",
    "Nobelist Niels",
    "1922 Physics Nobelist",
    "Nobelist Niels",
    "Atom modeler",
    "1922 Physics Nobelist",
    "Nobel physicist Niels",
    "Physicist Niels",
    "Nobelist Niels",
    "Danish Nobelist Niels",
    "Physicist with an element named after him",
    "1922 Physics Nobelist",
    "Physicist Niels",
    "1922 Physics Nobelist",
    "Danish physicist Niels",
    "Noted Los Alamos scientist",
    "Physicist Niels",
    "1922 Nobelist in physics",
    "Pioneer in quantum theory",
    "Nuclear theorist Niels",
    "1922 Physics Nobelist",
    "___ theory, in physics",
    "Physicist Niels",
    "Physics Nobelist Niels",
    "1922 Physics Nobelist",
    "Planck contemporary",
    "Physicist Niels",
    "Physicist Niels"
  ],
  "ogod": [
    "Psalm starter",
    "Psalm 63 opening",
    "Hymn starter",
    "Hymn starter",
    "Start of some pleas",
    "Hymn starter",
    "Hymn words before \"beyond all praising\"",
    "Prayer starter",
    "Psalm start",
    "Hymn start",
    "Psalm starter",
    "Prayer start",
    "Start of an invocation",
    "Prayer start",
    "Hymn start",
    "Prayer start",
    "\"___, Our Help in Ages Past\" (hymn)",
    "Start of many a hymn",
    "\"___, Our Help in Ages Past\" (old hymn)"
  ],
  "site": [
    "Wikipedia, e.g.",
    "Construction area, e.g.",
    "Listing in a travel guide",
    "Something to build on",
    "Where it's at",
    "Yahoo!, but not \"Yahoo!\"",
    "It's where it's at",
    "Location",
    "Spot",
    "Something pinned on a map",
    "Building lot",
    "Web developer's development",
    "Word after test or web",
    "Historical ___",
    "Babylon, for the ancient Hanging Gardens",
    "Internet destination",
    "Facebook, for one",
    "Listing in a browser history",
    "Venue",
    "Something in a bookmarks tab",
    "Web locale",
    "Online destination",
    "Locus",
    "Spot",
    "Group of pages",
    "Web page",
    "Webmaster's creation",
    "Web presence",
    "What a building inspector inspects",
    "Venue",
    "Word after Web or camp",
    "Something to build on",
    "___-specific (like the answers at 1-, 41- and 73-Across)",
    "Set of Web pages",
    "Word with building or burial",
    "Web destination",
    "Where it's at",
    "Spot",
    "Web spot",
    "Part of the cyberworld",
    "Dig, for example",
    "Construction ___",
    "Launch ___",
    "Something to build on",
    "Map point",
    "Place to build",
    "Place",
    "Net-surfer's stop",
    "Net-surfer's stop",
    "Spot",
    "Location",
    "Locale",
    "Location",
    "It may have \".com\" in its name",
    "Spot",
    "Place",
    "Location",
    "Location",
    "It has a home page",
    "Webmaster's creation",
    "Webmaster's creation",
    "Yahoo.com, e.g.",
    "Web destination",
    "Spot",
    "Webmaster's creation",
    "Place",
    "Location",
    "Whereabouts",
    "Spot",
    "Word with work or Web",
    "Home page",
    "Builder's choice",
    "Locale",
    "Position",
    "Spot",
    "Location",
    "Spot",
    "Location",
    "Locate",
    "Venue",
    "Spot",
    "Location",
    "Locale",
    "Locus",
    "Where it's at",
    "Location",
    "Where it's happening",
    "Put in position",
    "Location",
    "Spot",
    "Spot",
    "Spot",
    "Location",
    "Planned setting",
    "Whereabouts"
  ],
  "ural": [
    "___ Mountains, dividers of Europe and Asia",
    "Name of a mountain range or a river",
    "River of Eurasia",
    "Territory east of Ukraine on a Risk board",
    "Third-longest river in Europe, after the Volga and Danube",
    "Risk territory that borders Siberia",
    "Risk territory bordering Ukraine and Afghanistan",
    "Border mountain between Europe and Asia",
    "Russia's ___ Mountains",
    "Asian territory in Risk",
    "River past Orsk and Orenburg",
    "River through Russia and Kazakhstan",
    "Like Mount Narodnaya",
    "Territory north of Afghanistan, in Risk",
    "River to the Caspian",
    "Territory in Risk",
    "River forming part of the boundary between Europe and Asia",
    "Asian territory in the game Risk",
    "River bisecting Orsk",
    "___ Mountains (range east of Moscow)",
    "Eurasia's ___ Mountains",
    "Eurasian boundary",
    "River past Orsk",
    "Russia's ___ Mountains",
    "Eurasia's ___ Mountains",
    "Risk territory west of Siberia",
    "Russia's ___ Airlines",
    "It flows to the Caspian",
    "Caspian Sea feeder",
    "___ Mountains (continent separator)",
    "Russia's ___ Mountains",
    "Russia's ___ Airlines",
    "Eurasia's ___ Mountains",
    "Russian river",
    "Russia's ___ Mountains",
    "___ Mountains (Eurasian range)",
    "River to the Caspian Sea",
    "Caspian Sea feeder",
    "River through Kazakhstan",
    "Russia's ___ Mountains",
    "It flows through Orsk",
    "River through Orsk",
    "Russia's ___ Mountains",
    "Caspian feeder",
    "Russia's ___ Mountains",
    "Risk board territory",
    "River to the Caspian",
    "Russia's ___ Mountains",
    "Caspian Sea feeder",
    "Russian/Kazakh river",
    "Russia's ___ Mountains",
    "Risk territory east of Ukraine",
    "Russia's ___ Airlines",
    "___ Mountains, Europe/Asia separator",
    "Territory in Risk",
    "The Sakmara feeds it",
    "Kazakh river",
    "Territory east of Ukraine on a Risk board",
    "Caspian Sea feeder",
    "___ Mountains (Asia/Europe separator)",
    "The Ilek is one of its tributaries",
    "Orenburg is on it",
    "Russian river",
    "River separating continents",
    "Magnitogorsk's river",
    "Orsk is on it",
    "Europe/Asia boundary river",
    "___ Mountains, Europe/Asia separator",
    "Orsk's river",
    "1,575-mile river known to some locals as the Zhayyq",
    "River through the Steppes of Asia",
    "Europe/Asia border river",
    "Eurasia's ___ Mountains",
    "Orsk is on it",
    "Russia's ___ Mountains",
    "River through Kazakhstan",
    "River through Kazakhstan",
    "River to the Caspian",
    "Orenburg's river",
    "Eurasia's ___ Mountains",
    "Caspian Sea feeder",
    "Russia's ___ Mountains",
    "Orsk's river",
    "Russian river",
    "Russia's ___ Mountains",
    "Caspian tributary",
    "Russian river",
    "Russian river",
    "River to the Caspian",
    "Russia's ___ Mountains",
    "Russian river",
    "Continental divide",
    "Orsk's river",
    "Russian river",
    "River that Kazakhs call Zhayyq",
    "River to the Caspian",
    "Caspian feeder",
    "Eurasia's ___ Mountains",
    "River to the Caspian",
    "River to the Caspian",
    "River to the Caspian Sea",
    "Russia's ___ Mountains",
    "Caspian feeder",
    "Russian river",
    "River to the Caspian",
    "Range separating two continents",
    "___ Mountains (Asian border)",
    "___-Altaic languages",
    "Russia's ___ Mountains",
    "Orsk's river",
    "Russia's ___ Mountains",
    "River to the Caspian",
    "Caspian feeder",
    "Russia's ___ Mountains",
    "River to the Caspian",
    "Russia's ___ Mountains",
    "Russia's ___ Mountains",
    "River to the Caspian",
    "Russia's ___ Mountains",
    "Orenburg's river",
    "___-Altaic languages",
    "Russian river",
    "Russia's ___ Mountains",
    "Part of the Europe-Asia boundary",
    "It flows for 1,575 miles",
    "Caspian feeder",
    "Europe's third-longest river",
    "___-Altaic languages",
    "Caspian Sea feeder",
    "Orsk's river",
    "Russia's ___ Mountains",
    "Russia's ___ Mountains",
    "Russian river",
    "Russia's ___ Mountains",
    "River to the Caspian",
    "Russian river or mountain",
    "Russia's ___ Mountains",
    "Caspian Sea feeder",
    "___ Mountains (edge of Asia)",
    "River of Russia",
    "River at Orsk",
    "River to the Caspian",
    "Caspian Sea feeder",
    "Russian river",
    "Russian river",
    "Caspian Sea feeder",
    "Russia's ___ Mountains",
    "Russia's ___ Mountains",
    "Russian river",
    "Russia's ___ Mountains",
    "Caspian tributary",
    "Russia's ___ Mountains",
    "Eurasia's ___ Mountains",
    "Caspian feeder",
    "Russia's ___ Mountains",
    "Orsk's river",
    "Mountain of central Russia",
    "___ -Altaic (language group)",
    "Caspian feeder",
    "Russia's ___ Mountains",
    "___ Mountains",
    "River to the Caspian",
    "River to the Caspian"
  ],
  "nuke": [
    "Zap",
    "Microwave",
    "Quickly heat up",
    "Zap",
    "Warm up in seconds",
    "Zap",
    "Zap",
    "Zap in the microwave",
    "Destroy, informally",
    "Zap",
    "Zap",
    "Zap in the microwave",
    "Certain W.M.D.",
    "Microwave",
    "Microwave, slangily",
    "Heat in a hurry",
    "Certain W.M.D.",
    "Zap",
    "Microwave",
    "Zap",
    "Heat up in a hurry",
    "Microwave",
    "Cook in the microwave",
    "Microwave",
    "Deadly missile",
    "Microwave",
    "Microwave",
    "Microwave, slangily",
    "Blow to kingdom come",
    "Wipe out",
    "Microwave, in slang"
  ],
  "gaye": [
    "Singer nicknamed the Prince of Motown",
    "\"What's Going On\" singer, 1971",
    "Marvin of Motown",
    "Music's Prince of Soul",
    "Marvin of Motown",
    "Singer with the 1971 hit \"Mercy Mercy Me\"",
    "Marvin of Motown",
    "Marvin who sang \"Let's Get It On\"",
    "\"Let's Get It On\" singer",
    "\"What's Going On\" singer",
    "Marvin of Motown",
    "\"What's Going On\" singer Marvin",
    "\"I'll Be Doggone\" singer, 1965",
    "\"What's Going On\" singer, 1971",
    "Soul's Marvin",
    "Marvin of Motown",
    "Marvin of Motown",
    "\"Let's Get It On\" singer",
    "1960's-70's singer Marvin",
    "Motown's Marvin",
    "Singer Marvin",
    "Singer Marvin",
    "\"Mercy Mercy Me\" singer, 1971",
    "\"Sexual Healing\" singer, 1982"
  ],
  "dada": [
    "Common first word for a baby",
    "Duchamp's art movement",
    "Movement based on deliberate irrationality",
    "Bit of baby talk",
    "Common first word",
    "\"Anti-art\" art movement",
    "Infant's early word",
    "Man Ray's genre",
    "Bit of baby babbling",
    "Marcel Duchamp's art style",
    "Art genre for Man Ray",
    "Duchamp's movement",
    "Circa-W.W. I art movement",
    "One a baby may cry out to",
    "With 53-Down, many Marcel Duchamp works",
    "\"___ means nothing\" (1918 manifesto declaration)",
    "Mama's counterpart",
    "Baby's word",
    "1910s-'20s art movement",
    "Surrealism forerunner",
    "Pop for a young person?",
    "It might come out of a small speaker",
    "Father, to a baby",
    "Style of Duchamp's \"Fountain\"",
    "It originated at Zurich's Cabaret Voltaire in the 1910s",
    "Baby's first word, maybe",
    "\"Antiart\" art",
    "Early vocabulary word",
    "Jean Arp's movement",
    "Early baby talk",
    "Precursor to Surrealism",
    "Baby's word",
    "Anti-art movement",
    "Word from the crib",
    "Early pop?",
    "Movement branded as \"anti-art\"",
    "1920s art movement",
    "Early 20th-century art movement",
    "Early baby word",
    "Some 20th-century art",
    "1910's-20's movement",
    "Man Ray's genre",
    "Infant's word",
    "20th-century art movement",
    "Baby's early word",
    "Jean Arp's art",
    "MoMA display",
    "Early baby word",
    "Early baby word",
    "Man Ray's movement",
    "Early baby word",
    "Duchamp's movement",
    "Arp's art",
    "Bit of baby talk",
    "Baby's first word, maybe",
    "Ernst's art",
    "Arp movement",
    "Arp's art",
    "Duchamp's art movement",
    "Duchamp's \"Mona Lisa,\" e.g.",
    "Some of Duchamp's art",
    "One of baby's first words",
    "Some 20's art",
    "Baby's first word",
    "Arp's art",
    "Early baby word",
    "Arp art",
    "Arp's art",
    "1910's-20's art style",
    "Arp's movement",
    "Some Duchamp art",
    "Man Ray's art",
    "Baby's utterance",
    "Hugo Ball movement",
    "Arp art",
    "Art genre",
    "Some MOMA art",
    "Irrational art",
    "Some MOMA works",
    "Tot's first word",
    "Hans Arp style",
    "Movement begun by Tristan Tzara"
  ],
  "rosy": [
    "Flushed, as cheeks",
    "Rubicund",
    "Optimistic",
    "Promising",
    "Optimistic",
    "Upbeat and hopeful",
    "Promising, as a future",
    "Like some cheeks in winter air",
    "Optimistic",
    "Full of promise, as an outlook",
    "Upbeat, as an outlook",
    "Like the dawn sky",
    "Pollyannaish",
    "Optimistic",
    "Flushed, as cheeks",
    "Reddish",
    "Pink",
    "Promising",
    "Looking up",
    "Pink",
    "Like some cheeks and outlooks",
    "Optimistic",
    "Like Santa's cheeks",
    "Upbeat",
    "Looking up",
    "Looking up",
    "Dawnlike",
    "Looking up",
    "Optimistic",
    "Looking up",
    "Looking up",
    "Looking up",
    "Pink",
    "Pinkish",
    "Having a positive outlook",
    "Like Santa's cheeks",
    "Pink, as cheeks",
    "Positive",
    "Promising good things",
    "\"Dawn of the ___ fingers ...\": The Odyssey",
    "Looking up",
    "Looking good",
    "Glowing",
    "Upbeat",
    "Optimistic",
    "Pink, as cheeks",
    "Promising",
    "Upbeat",
    "Looking up",
    "Optimistic",
    "Pinkish",
    "Promising",
    "Like some cheeks",
    "Like Santa's cheeks",
    "Like some outlooks",
    "Optimistic",
    "Flushed",
    "Word with cheeks or picture",
    "Looking up",
    "Encouraging",
    "Optimistic",
    "Optimistic",
    "Pink",
    "Optimistic",
    "Optimistic",
    "Cheerful",
    "Optimistic",
    "Looking up",
    "Cheerful"
  ],
  "nose": [
    "Important part of a bloodhound",
    "Smell of a rose",
    "\"Scenter\" of the face",
    "Bright part of Rudolph",
    "Facial feature missing from many emojis",
    "Part of a plane",
    "Part of a Groucho Marx disguise",
    "Middle of the face",
    "Prominent part of Snoopy",
    "Wine lover's asset",
    "There's a bridge at the top of it",
    "Frostbite site",
    "Something to hold near a skunk",
    "Smeller",
    "Sommelier's concern",
    "Honker",
    "Missing part of the Sphinx",
    "Elephant's trunk, basically",
    "Wine's aroma",
    "What smells",
    "Part of the body associated with sneezing, sniffling and snoring",
    "Wine quality",
    "Narrow margin",
    "Scent picker-upper",
    "___ cone",
    "Wine bouquet",
    "Part of many an emoticon",
    "Bloodhound's asset",
    "Oenophile's concern",
    "Sometimes-pierced body part",
    "1-Down quality",
    "Prominent part of a Groucho disguise",
    "Missing part of the Great Sphinx of Giza",
    "Torpedo part",
    "You might hold it by a trash can",
    "It smells a lot",
    "Aroma",
    "Beak",
    "Thing twitched on \"Bewitched\"",
    "Supporter of shades",
    "Red feature of Ronald McDonald",
    "What the hyphen in an emoticon often represents",
    "Bad thing to pick",
    "Abnormal part of Voldemort's visage",
    "Prominent part of a Groucho disguise",
    "Air port?",
    "It can be hooked or pierced",
    "Bridge locale",
    "It's in front of the cockpit",
    "Snoop (around)",
    "Thin margin of victory",
    "Beak or beat",
    "Slim win margin",
    "Nail-biting margin of victory",
    "Sniffer",
    "One can get stuffy",
    "A hook might give it a hook",
    "Front",
    "Narrow margin",
    "Rocket head",
    "Pliers part",
    "It has two holes",
    "Plane part",
    "Mr. Potato Head piece",
    "Holder of spectacles",
    "Oenophile's interest",
    "It may be wrinkled",
    "Prominent part of a Groucho disguise",
    "Tracking ability",
    "Look (around)",
    "It can sense scents",
    "It smells",
    "It can take your breath away",
    "Jet part",
    "Rhinoplasty target",
    "Scent detector",
    "Tissue's target",
    "Breather",
    "Small winning margin",
    "Scent receiver",
    "It may be wrinkled",
    "Sniffer",
    "Muzzle",
    "Bloodhound feature",
    "Identi-Kit selection",
    "Airplane part",
    "Snout",
    "Investigatory aid",
    "Beak",
    "Reporter's asset",
    "It smells",
    "Rudolph's beacon",
    "Narrow victory margin",
    "Schnozz",
    "Bridge site",
    "Winning margin, sometimes",
    "Bridge site",
    "Wine characteristic",
    "Durante's famous feature",
    "Cyrano's protrusion",
    "Find (out)",
    "See 35-Across",
    "Schnozzola",
    "Place for a ring",
    "Smoke detector?",
    "Very slim margin",
    "Detection ability",
    "Symbol of prying",
    "Carrot on a snowman, perhaps",
    "Beak",
    "Snoop (around)",
    "Snoop (around)",
    "The scenter of things?",
    "Rocket part",
    "It usually smells",
    "It smells",
    "Bloodhound's asset",
    "Plane part",
    "Narrow margin",
    "Boxer's punching spot",
    "Roman ___",
    "It smells great",
    "Newshawk's asset?",
    "Margin of victory, maybe",
    "Narrow margin of victory",
    "It may be held underwater",
    "Pliers part",
    "Scents organ",
    "Plane part",
    "Sniffer",
    "Smoke detector",
    "Part of a plane",
    "Barely beat, with \"out\"",
    "It smells a lot",
    "Winning margin, maybe",
    "Scent detection device",
    "Bloodhound's sensor",
    "Victory margin, at times",
    "Bridge site",
    "Rose bouquet",
    "Advance cautiously",
    "Pinocchio's giveaway",
    "Small winning margin",
    "Detecting ability",
    "Investigate, with \"around\"",
    "Pinocchio's give-away",
    "It may be stuffy",
    "Rocket's front",
    "It may really smell",
    "Pinocchio's polygraph",
    "Tracking power",
    "On the ___ (precisely)",
    "Airplane part",
    "Proboscis",
    "Ship's prow",
    "It won't get far by running",
    "Find, with \"out\"",
    "Schnozzola",
    "Winning margin, maybe"
  ],
  "mage": [
    "Figure in Final Fantasy",
    "World of Warcraft spellcaster",
    "Enchanting sort",
    "Dungeons & Dragons figure",
    "Dungeons & Dragons class",
    "Wizard",
    "Wizard",
    "Conjurer, old-style",
    "Spell caster",
    "Caster of spells",
    "Gandalf, for one",
    "Spell caster",
    "Caster of spells",
    "Sorcerer",
    "Master of spelling?",
    "Dungeons & Dragons spellcaster",
    "Spell caster",
    "Wizard, old-style",
    "Merlin, e.g.",
    "Old sorcerer"
  ],
  "cows": [
    "Longhorns, e.g.",
    "Bullies",
    "Anthropomorphic figures in many \"Far Side\" cartoons",
    "Bullies",
    "Female whales",
    "Intimidates",
    "Daunts",
    "Sights at 127-Across",
    "Intimidates",
    "Intimidates",
    "Bossy types?",
    "Female whales",
    "Mad ones are bad",
    "Intimidates",
    "Cud chewers",
    "Milk dispensers",
    "Scares",
    "Intimidates"
  ],
  "ahat": [
    "At the drop of ___ (instantly)",
    "At the drop of ___",
    "\"The Man Who Mistook His Wife for ___\" (1985 book)",
    "\"The Man Who Mistook His Wife for ___\" (1985 best seller)",
    "Answer to the old riddle \"What lies flat when empty, sits up when full?\"",
    "At the drop of ___",
    "Pull out of ___ (produce suddenly)",
    "\"___ Full of Sky\" (2004 Terry Pratchett novel)",
    "At the drop of ___",
    "\"I Had ___ When I Came In\" (old Irish tune)",
    "\"The Man Who Mistook His Wife for ___\" (Oliver Sacks book)",
    "At the drop of ___",
    "At the drop of ___",
    "At the drop of ___",
    "At the drop of ___",
    "\"The Man Who Mistook His Wife for ___\" (1985 book)"
  ],
  "main": [
    "Major water line",
    "Principal",
    "Common downtown street name",
    "Common street name",
    "Chief",
    "Principal",
    "Major water line",
    "Street through the middle of town",
    "Common street name",
    "Street likely to have the most stoplights",
    "Ocean",
    "Street one block over from Second, maybe",
    "Central street",
    "Water carrier",
    "Chief",
    "Principal",
    "Central",
    "Primary",
    "Alternative name for 1st Street, often",
    "Central street name",
    "Common street name",
    "Water carrier",
    "It might cross 1st, 2nd and 3rd",
    "Lead",
    "Water carrier",
    "Common street name",
    "Cardinal",
    "With 59-Across, A-B-C-A in the illustration",
    "Street that may be a U.S. highway",
    "Cardinal",
    "Primary",
    "Spanish ___",
    "Paramount",
    "Common street name",
    "Key",
    "Common street name",
    "Primary",
    "Primary",
    "Cardinal",
    "Cardinal",
    "Common street name",
    "Central street",
    "Most important",
    "Central street",
    "Primary",
    "It may be bounding",
    "Bounding site?",
    "Duct",
    "Water pipe",
    "Central",
    "Cardinal",
    "Street with stores, usually",
    "Foremost",
    "Central",
    "Common street name",
    "Kind of a drag",
    "Chief",
    "Water source",
    "Predominant",
    "Water conduit",
    "With 39-Across, featured boxing match",
    "Chief",
    "Conduit",
    "Chief",
    "Primary",
    "Popular street name",
    "Water artery"
  ],
  "nuns": [
    "Religious sisters",
    "Convent residents",
    "Convent residents",
    "\"The Sound of Music\" chorus",
    "Ones with good habits?",
    "Some parochial school teachers",
    "They're in a particular order",
    "Convent residents",
    "Convent residents",
    "Creatures of habit?",
    "Church women",
    "They may be in black-and-white",
    "Women in habits",
    "Order members",
    "*Ninth step",
    "Women with vows",
    "\"The Sound of Music\" extras",
    "\"The ___ Story\" (Audrey Hepburn movie)",
    "Cloistresses",
    "Women in habits",
    "Cloister inhabitants",
    "\"Sister Act\" extras",
    "Superior ones?",
    "They have their orders"
  ],
  "crew": [
    "Ship personnel",
    "Captain and company",
    "Workmates",
    "Captain's command",
    "Mission group",
    "Ones taking captain's orders",
    "All hands on deck",
    "J. follower",
    "They're all in the same boat",
    "Rapper's posse",
    "Captain's command",
    "Regatta group",
    "Staff",
    "Mates",
    "Rowing team",
    "Workers",
    "Sport with shells",
    "Team on the Thames",
    "Rowers",
    "Backstage bunch",
    "Sock style",
    "All hands",
    "Company",
    "Captain's hires",
    "Pilot and flight attendants",
    "Hands",
    "Pilot with flight attendants",
    "Work detail",
    "Manners?",
    "Hands",
    "Certain sweater, informally",
    "Work force",
    "\"Titanic\" extras",
    "Ship's complement",
    "All hands on deck",
    "Work party",
    "Stage workers",
    "Vessel's complement",
    "Manners?",
    "Lighter company",
    "All hands",
    "Airplane staff",
    "Kind of cut",
    "Ship's staff",
    "Rowing sport",
    "Deck hands",
    "Deckhands",
    "Workers",
    "Staff"
  ],
  "grim": [
    "Worrisome, as news",
    "Not looking good at all",
    "Not looking good",
    "On the dark side",
    "Bad",
    "Serious-looking",
    "Gloomy",
    "Hardly rosy",
    "Unsmiling",
    "Bad, as a prognosis",
    "Hardly rosy",
    "Dismal",
    "Bleak",
    "Like bad news"
  ],
  "ulta": [
    "___ Beauty (Sephora competitor)",
    "Big cosmetics chain",
    "Beauty supply chain",
    "Beauty products chain"
  ],
  "nana": [
    "Mama's mama",
    "Nickname for Grandma",
    "Pop-pop's partner",
    "Mom's mom",
    "Family nickname",
    "Nickname for grandma",
    "Dog in \"Finding Neverland\"",
    "Gram",
    "Mom's mom",
    "\"Peter Pan\" dog",
    "Mom's mom",
    "Zola title heroine",
    "Nickname for grandma",
    "\"Peter Pan\" dog",
    "Mama's mama",
    "\"Peter Pan\" dog",
    "Mom's mom",
    "Mom's mom",
    "One given to doting",
    "Mom's mom",
    "Grandmother, affectionately",
    "Granny",
    "Mom's mom",
    "Babe watcher, maybe",
    "Granny",
    "Grandma, familiarly",
    "\"Peter Pan\" dog",
    "\"Peter Pan\" dog",
    "Grams",
    "Grandma, affectionately",
    "Zola best seller",
    "Grandma",
    "Family nickname",
    "Family nickname",
    "Grandmother, affectionately",
    "Family moniker",
    "\"Peter Pan\" dog",
    "Favorite baby sitter, maybe",
    "Darling family pet",
    "Mom's mom, e.g.",
    "\"Peter Pan\" dog",
    "Child's caretaker",
    "\"Peter Pan\" dog",
    "Sha follower",
    "Tot minder",
    "Tot watcher",
    "Kid watcher",
    "\"Peter Pan\" dog",
    "\"Peter Pan\" dog",
    "\"Peter Pan\" dog",
    "Junior spoiler, perhaps",
    "Family nickname",
    "Darling dog",
    "Zola heroine",
    "Family term of endearment",
    "Dog of literature",
    "Mom's mom",
    "Dog of literature",
    "\"Peter Pan\" dog",
    "Zola novel",
    "Baby spoiler, perhaps",
    "Favored caretaker",
    "Zola courtesan",
    "\"Peter Pan\" dog",
    "Granny",
    "\"Peter Pan\" dog",
    "Zola novel",
    "Child spoiler?",
    "Zola heroine",
    "Mom's mom",
    "Family nickname",
    "Grandma",
    "Offerer of child support?",
    "Emile Zola novel",
    "\"Peter Pan\" pooch",
    "Barrie's Newfoundland",
    "Zola novel",
    "Title courtesan in an 1880 novel",
    "Babushka",
    "Granny",
    "Darling dog",
    "Zola heroine",
    "Who's minding the baby, maybe",
    "\"Peter Pan\" pooch",
    "Beloved family member",
    "\"Peter Pan\" pooch",
    "Caretaker for a baby",
    "1877 Manet painting",
    "Barrie pooch",
    "1926 Jean Renoir film",
    "\"Peter Pan\" pooch",
    "Beloved family member",
    "Dog in \"Peter Pan\"",
    "Children's caretaker",
    "Zola heroine",
    "\"Peter Pan\" dog",
    "Grandma, affectionately",
    "Zola's streetwalker",
    "Grandmother",
    "\"Peter Pan\" dog",
    "Baby caretaker",
    "1880 literary heroine",
    "Grandmother, affectionately",
    "Zola novel",
    "Zola courtesan",
    "Grammy",
    "Darling dog",
    "\"Peter Pan\" dog",
    "Grandmother",
    "\"Peter Pan\" dog",
    "Tyke watcher",
    "Zola bestseller",
    "Zola tale",
    "Grandma",
    "1880 Zola novel",
    "1934 film seductress",
    "Zola heroine",
    "Grandma"
  ],
  "ncos": [
    "Sgt. and cpl., e.g.",
    "E-4, E-5 and E-6, in the U.S. Navy, in brief",
    "E-4 and E-5, but not E-3",
    "Base runners, in brief?",
    "Sgts. and cpls.",
    "Sgts., e.g.",
    "Drill sgts., e.g.",
    "Base figs.",
    "M.Sgt. and others",
    "Some drillers, for short",
    "Sergeants major, e.g.: Abbr.",
    "Basic training figs.",
    "Some PX patrons",
    "Drill instructors, e.g.: Abbr.",
    "Cpls. and sgts.",
    "Mil. E-4 to E-9",
    "PX patrons",
    "Sgts. and cpls.",
    "PX users",
    "Cpls. and others",
    "Ones getting base pay?: Abbr.",
    "Some mil. careerists",
    "Three-stripers, e.g.: Abbr.",
    "Enlisted V.I.P.'s",
    "PX users",
    "Future 25-Down honorees: Abbr.",
    "Ones above 48-Acrosses",
    "Sgt. and sgt. maj.",
    "Sgts., e.g.",
    "Sgts. and such",
    "Sgts. and cpls.",
    "Rank leaders?: Abbr.",
    "March figures, for short",
    "Cpls., e.g.",
    "Mil. personnel",
    "Base figures, for short",
    "Base runners?: Abbr.",
    "Sgts. and cpls.",
    "E-4's, E-5's et al.",
    "Cpls. et al.",
    "Sgt. maj. and others",
    "Sgts., e.g.",
    "Ones ranked E-4 to E-9: Abbr.",
    "E-4 and E-9 rankings",
    "They've earned their stripes: Abbr.",
    "Drill sgts., e.g.",
    "Mil. members",
    "They've earned their stripes: Abbr.",
    "Chevron wearers: Abbr.",
    "Sgts. and cpls."
  ],
  "roos": [
    "Jumpy sorts, in brief",
    "Aussie animals",
    "Animals that 28-Across, informally",
    "Aussie jumpers",
    "Down Under jumpers, informally",
    "They're all over Down Under, informally",
    "Aussies with deep pockets?",
    "Bush critters",
    "Down Under hoppers, informally",
    "Down Under critters",
    "Ones jumping up Down Under, for short",
    "Aussie critters",
    "Aussie critters",
    "Aussie hoppers",
    "Aussie bounders",
    "Bipedal Aussies, informally",
    "72-Across, e.g., informally",
    "Short marsupials?",
    "Down Under springers",
    "Aussie jumpers",
    "Big hoppers, for short",
    "Aussie jumpers",
    "Little kangas",
    "Outback critters",
    "Down Under hoppers",
    "Little hoppers",
    "Jumpers, informally",
    "Little hoppers",
    "Marsupials, informally",
    "Outback critters",
    "Outback critters",
    "Aussie hoppers",
    "Pouch holders, for short",
    "Outback hoppers",
    "Hoppers",
    "Australian hoppers"
  ],
  "choo": [
    "Jimmy ___ (luxury shoe brand)",
    "Sound from a toy train",
    "When repeated, a toy train",
    "Follower of \"ah-ah-ah\"",
    "Shoe designer Jimmy",
    "When repeated, toy on a track",
    "When doubled, a child's train",
    "Shoe designer Jimmy",
    "Toy train sound",
    "With 1-Across, toy train",
    "When repeated, a train sound",
    "Part of a sneeze after \"ah-ah-ah ...\"",
    "When repeated, a toy train",
    "When repeated, a train sound",
    "Half a train?",
    "When repeated, a child's plaything",
    "Half a train?",
    "Sneeze syllable",
    "Ah follower",
    "Ah follower",
    "Ending for \"ah\"",
    "Half a train toot"
  ],
  "jury": [
    "Group sometimes said to be \"out\"",
    "Peer group",
    "Box of 12?",
    "See 22-Down",
    "\"12 angry men,\" e.g.",
    "Group of 12, maybe",
    "Trial group",
    "Decision makers"
  ],
  "bede": [
    "\"Venerable\" saint",
    "Sainted English historian",
    "Monk known as \"The Father of English History\"",
    "Sainted English historian",
    "Theologian called \"The Father of English History\"",
    "See 60-Across",
    "\"Venerable\" scholar of old England",
    "Eliot title surname",
    "George Eliot's \"Adam ___\"",
    "\"Venerable\" English historian",
    "The Venerable ___ (old English historian)",
    "Eliot protagonist",
    "\"Venerable\" monk",
    "Eliot's \"Adam ___\"",
    "Eliot hero",
    "\"Venerable\" monk",
    "Eliot's \"Adam ___\"",
    "\"Adam ___\"",
    "The Father of English History",
    "George Eliot's \"Adam ___\"",
    "Sainted historian",
    "\"Venerable\" English monk",
    "Eliot protagonist",
    "\"Adam ___\" (Eliot novel)",
    "Theologian who started the custom of dating events from the birth of Christ",
    "Saint known as \"The Venerable\"",
    "Eliot's \"Adam ___\"",
    "Venerable scholar of old England",
    "\"Venerable\" English writer",
    "Eliot title character",
    "Eliot's Adam ___",
    "Benedictine scholar",
    "\"Venerable\" English writer",
    "Eliot hero",
    "Eliot character",
    "Eliot hero",
    "Carpenter in an 1859 novel",
    "George Eliot's Adam",
    "Fictional Adam",
    "Literary carpenter Adam",
    "Eliot's \"Adam ___\"",
    "Early English historian",
    "Adam of fiction"
  ],
  "nlcs": ["It was first won by the N.Y. Mets in 1969"],
  "stuf": [
    "Word on some Oreo packages",
    "Double ___ Oreos",
    "Double ___ Oreos",
    "Double ___ Oreo",
    "Double ___ Oreo",
    "Double ___ Oreos"
  ],
  "dies": [
    "Runs out of juice",
    "Passes",
    "Conks out",
    "Runs out of gas",
    "Goes pfft!",
    "Is mortified, so to speak",
    "Conks out",
    "Passes on",
    "Runs out of energy",
    "Conks out",
    "Medieval hymn start",
    "Stamping tools",
    "Shuffles off this mortal coil",
    "Mint hardware",
    "Doesn't go on",
    "Expires",
    "Fizzles out",
    "Stops",
    "Runs out of steam",
    "Goes belly up",
    "Goes out",
    "Goes kaput",
    "Passes on",
    "Fizzles out",
    "Fizzles out",
    "Goes kaput",
    "Goes out, as a fire",
    "Doesn't go",
    "Fizzles out",
    "Goes out, in a way",
    "\"___ Irae\" (Latin hymn)",
    "Stops running",
    "Goes phhht!",
    "Conks out",
    "Goes kaput",
    "Burns out",
    "Goes pfft",
    "Stamping tools",
    "Loses all power",
    "Fizzles out",
    "With 69 Across, Requiem hymn",
    "Conks out"
  ],
  "ltds": [
    "Former Ford models",
    "Bygone Fords",
    "Erstwhile Fords",
    "Bygone Fords",
    "Old Fords",
    "Old Fords",
    "Former Ford full-sizes",
    "Bygone Fords",
    "Old Fords",
    "Old Fords",
    "Former Fords",
    "Old Fords",
    "Bygone Fords",
    "Old Fords",
    "Old Fords",
    "Old Fords",
    "Old Fords",
    "Some old Fords",
    "Old Fords",
    "Former Fords",
    "Bygone Fords",
    "Former Ford models"
  ],
  "ihop": [
    "Seller of Belgian waffles and French toast (fittingly, considering the \"I\" in its name)",
    "Where to order a Rooty Tooty Fresh 'N Fruity",
    "Sister restaurant of Applebee's",
    "Chain restaurant ... or what a kangaroo might say?",
    "Denny's competitor",
    "Seller of the Ultimate Waffle Sandwich",
    "Where a batter eventually goes to the plate?",
    "Onetime \"Come hungry. Leave happy\" sloganeer",
    "Rabbit's favorite chain restaurant?",
    "Commercial name that becomes a Native American tribe if you move its first letter to the end",
    "Flapjack franchise, briefly",
    "Sister chain of Applebee's",
    "Breakfast restaurant chain",
    "Blue-roofed eatery",
    "\"Eat up every moment\" sloganeer",
    "Breakfast chain",
    "Sister company of Applebee's",
    "Food chain",
    "Denny's alternative",
    "Co. that bought out Applebee's in 2007",
    "Acronym in casual dining",
    "Chain with links",
    "\"Come hungry. Leave happy\" sloganeer",
    "2007 purchaser of Applebee's",
    "Blue-roofed chain",
    "Breakfast chain, briefly",
    "Breakfast restaurant chain",
    "\"Come hungry. Leave happy\" sloganeer",
    "Rooty Tooty Fresh 'N Fruity establishment",
    "24-hour place to hit the links?",
    "Restaurant chain with syrup dispensers on every table",
    "Links chain",
    "Stack server",
    "Blue-roofed chain",
    "Place to order a stack, say",
    "Sister co. of Applebee's",
    "Breakfast place that's often open 24 hrs.",
    "Chain with many links",
    "Blue-roofed chain",
    "Stack-serving chain, for short",
    "Denny's competitor",
    "Flapjack eatery, for short",
    "Co. that bought Applebee's in 2007",
    "Restaurant chain since 1958",
    "Restaurant chain acronym",
    "Breakfast restaurant letters",
    "Restaurant acronym",
    "Breakfast spot, briefly",
    "Denny's alternative",
    "Chain restaurant with a blue roof",
    "Chain with many links",
    "Breakfast chain",
    "Restaurant chain founded in 1958 near L.A.",
    "Chain letters?",
    "24-hour breakfast place",
    "24-hr. breakfast place",
    "Restaurant chain since '58",
    "Place to get links",
    "Restaurant acronym",
    "Denny's alternative",
    "Blue-roofed eatery",
    "Restaurant chain, for short",
    "Breakfast chain, for short",
    "Breakfast restaurant chain",
    "Breakfast food chain",
    "Waffle House alternative",
    "Popular restaurant chain, for short",
    "Denny's competitor",
    "Breakfast acronym",
    "Restaurant chain acronym",
    "Chain with stacks",
    "Breakfast place, for short",
    "Noted restaurant chain",
    "Restaurant chain initials",
    "Chain with links?",
    "Popular restaurant chain, briefly",
    "Restaurant with waffles and such",
    "Hwy. eatery",
    "Breakfast restaurant chain",
    "Breakfast chain, informally",
    "Breakfast restaurant, for short",
    "Popular food chain, informally",
    "Breakfast restaurant chain, for short",
    "Breakfast restaurant chain, informally"
  ],
  "robb": [
    "Eldest Stark son on \"Game of Thrones\"",
    "Eldest Stark son on \"Game of Thrones\"",
    "Eldest Stark son on \"Game of Thrones\"",
    "___ Stark, half brother of Jon Snow on \"Game of Thrones\"",
    "L.B.J. in-law Charles",
    "Eldest Stark child on \"Game of Thrones\"",
    "L.B.J. in-law",
    "Eldest Stark child on \"Game of Thrones\"",
    "Former Virginia senator Chuck",
    "L.B.J. son-in-law Charles",
    "___ Report (luxury lifestyle magazine)",
    "Ex-Sen. Charles",
    "Former Virginia senator Charles",
    "___ Report (luxury magazine)",
    "The ___ Report (upscale magazine)",
    "See 80-Across",
    "Son-in-law of Johnson",
    "L.B.J. in-law",
    "Former Virginia senator",
    "L.B.J. son-in-law",
    "L.B.J. in-law",
    "Pitcher ___ Nen",
    "Senator from Virginia",
    "L.B.J. in-law",
    "Virginia Senator",
    "60's Presidential in-law",
    "60's Presidential in-law",
    "Southern senator",
    "L.B.J. son-in-law"
  ],
  "olav": [
    "Speed skater Johann ___ Koss, winner of four Olympic golds",
    "Common name among Norwegian royals",
    "Norway's patron saint",
    "Royal name of Norway",
    "Name of many Norwegian kings",
    "Patron saint of Norway",
    "Viking king of note",
    "Kingly name in Norway",
    "Patron saint of Norway",
    "Name of five Norwegian kings",
    "Name of five Norwegian kings",
    "Name of five Norwegian kings",
    "Norway's patron saint",
    "Norway's patron saint",
    "The People's King of Norway",
    "Name shared by five Norwegian kings",
    "Norway's patron saint",
    "King Harald's father",
    "Kingly name in Norway",
    "Norwegian royal name",
    "Johann ___ Koss, speed skater with four Olympic golds",
    "Norway's Order of St. ___",
    "Patron saint of Norway",
    "South Georgia's Prince ___ Harbor",
    "Name of five Norwegian kings",
    "Alternative to Ole or Edvard",
    "Norway's patron saint",
    "Patron saint of Norway",
    "One of five Norwegian kings",
    "Royal name in Norway",
    "Royal Norwegian Order of St. ___",
    "Patron saint of Norway",
    "Speed-skating champ Johann ___ Koss",
    "Norway's patron saint",
    "One of five Norwegian kings",
    "Patron saint of Norway",
    "Antarctica's Prince ___ Mountains",
    "Patron saint of Norway",
    "Norwegian saint",
    "Antarctica's Prince ___ Coast",
    "Sainted Norwegian king",
    "Scandinavian royal",
    "Former king of Norway",
    "King Harald's predecessor",
    "Sainted European king",
    "Patron saint of Norway",
    "Norwegian saint",
    "Norway's patron saint",
    "Gold-medal speed skater Johann ___ Koss",
    "One of five Norwegian kings",
    "King Harald's father",
    "Norway's patron saint",
    "Norwegian king",
    "Norse saint",
    "Son of Haakon VII",
    "Novelist Duun",
    "Norwegian king",
    "Patron saint of Norway",
    "One of five Norwegian kings",
    "Norwegian saint",
    "Any of several Norse royals",
    "Royal Norwegian name",
    "Norwegian king",
    "Sainted king",
    "Many a Norwegian",
    "Norwegian king",
    "Norwegian hero",
    "Norse saint",
    "Norwegian king",
    "Haakon's royal successor",
    "Skating gold medalist Johann ___ Koss",
    "Norwegian saint",
    "Gold-medal speed skater Johann ___ Koss",
    "King of Norway",
    "King Harald's predecessor",
    "King Harald's predecessor",
    "Late Norwegian king",
    "Norwegian king",
    "King Harald's father",
    "Norwegian king until 1991",
    "Norway's patron saint",
    "One of five Norwegian kings",
    "King Harald's father",
    "Late king of Norway",
    "Olympic skater Johann ___ Koss",
    "Patron saint of Norway",
    "Late king of Norway"
  ],
  "lous": [
    "Baseball's Gehrig and Piniella",
    "\"___ on First\" (1981 comedian's biography)",
    "Reed and Rawls",
    "Baseballers Brock and Piniella",
    "Reed and Costello",
    "Rawls and Reed",
    "Brock and Ferrigno"
  ],
  "orsk": [
    "Russian city on the Ural River",
    "Russian city on the Ural",
    "Ural River city",
    "Second biggest city in Russia's Orenburg region",
    "Ural River city",
    "Ural River city"
  ],
  "oleo": [
    "Butterlike spread",
    "Butter alternative",
    "Nondairy spread",
    "Bread spread",
    "Bread spread",
    "Imperial product",
    "Margarine",
    "Option for a roll",
    "A butter alternative",
    "Nondairy spread",
    "Nondairy substitute",
    "Imperial sticks, say",
    "Topping in kosher restaurants",
    "Butter substitute",
    "Margarine",
    "Margarine",
    "With 28-Down, butterlike product of beef fat",
    "Prefix with resin",
    "Bread spread",
    "Stick in the refrigerator",
    "Yellow stick",
    "Spread on bread",
    "I Can't Believe It's Not Butter! product",
    "Fleischmann's offering",
    "Some pats",
    "Margarine",
    "Imperial bars?",
    "Yellow sub?",
    "Promise product",
    "Food item in quarter-pound sticks",
    "Pat material, maybe",
    "Margarine",
    "Nondairy item in the dairy aisle",
    "Yellow spread",
    "Ersatz butter",
    "Promise product",
    "Butter substitute",
    "Polyunsaturated fat source",
    "Spread in the fridge",
    "Land O'Lakes product",
    "Bar next to butter, maybe",
    "Butter alternative",
    "Imperial offering",
    "Dairy-free spread",
    "Promise, e.g.",
    "Nondairy alternative",
    "Dairy section selection",
    "It's not butter",
    "Imperial offering",
    "Butter alternative",
    "Bread spread",
    "Nondairy dairy aisle item",
    "Land O'Lakes product",
    "Fleischmann's product",
    "Spread selection",
    "Toast topper",
    "Miles Davis jazz number",
    "Dairy case bar",
    "Fleischmann's product",
    "Margarine",
    "Tub filler",
    "Blue Bonnet, e.g.",
    "Pat makeup",
    "Butterlike spread",
    "Muffin stuffin'?",
    "Stick out in a restaurant?",
    "Something that's been clarified",
    "Promise, for one",
    "Margarine",
    "Nondairy product in the dairy section",
    "Margarine",
    "Spread selection",
    "Margarine",
    "Stick on a dish",
    "Lower-priced spread",
    "Land O Lakes product",
    "Margarine",
    "Spread in a spread",
    "Sub in a tub",
    "It may not need clarification",
    "Dinner spread",
    "Table spread",
    "Butter alternative",
    "Cooking staple",
    "Margarine",
    "Common spread",
    "It's a butter alternative",
    "Relative of butterine",
    "Product in a tub",
    "Blue Bonnet, e.g.",
    "Dairy case buy",
    "Spread stick",
    "Promise to a cook?",
    "Pan lubricant",
    "Margarine",
    "Dairy case item",
    "It melts in your mouth",
    "Yellow stick",
    "Promise kept in a tub, perhaps",
    "Parkay product",
    "Margarine",
    "Table spread",
    "Spread out on a table?",
    "Meltable food item",
    "Stick in a dish",
    "Pat on the buns?",
    "Dairy case item",
    "Substitute spread",
    "Toast topper",
    "Butter substitute",
    "It's high in trans fat",
    "Promise product",
    "Promise, for one",
    "Food product that melts",
    "Dairy aisle purchase",
    "Skillet lubricant",
    "Bread spread",
    "Some pats",
    "Dairy aisle buy",
    "It's sold in sticks",
    "Yellow spread",
    "It's sold in bars",
    "Skillet lubricant",
    "Spread on a dinner table",
    "Food item sold in bars",
    "Butter alternative",
    "Toast spread",
    "Blue Bonnet, e.g.",
    "Substitute in the kitchen",
    "Spread",
    "Promise, e.g.",
    "Yellow sub?",
    "You might stick a knife in it",
    "Butter alternative",
    "Table spread",
    "Muffin topper",
    "Meltable spread",
    "Promise, e.g.",
    "Butter alternative",
    "Prefix with resin",
    "Bread spread",
    "Imperial or Blue Bonnet",
    "Spread selection",
    "Butter substitute",
    "Toast topper",
    "Bread spread",
    "Imperial, for example",
    "Yellow spread",
    "Promise, for one",
    "Corn product",
    "Stick on the table",
    "Promise, e.g.",
    "Butter substitute",
    "Toast topper",
    "Spread for 8-Down",
    "Toast topping",
    "Pat material?",
    "Dairy case item",
    "Ersatz butter",
    "Yellow stick",
    "Nondairy spread",
    "Spreadable stick",
    "Butter look-alike",
    "Promise and such, in brief",
    "Food in bars",
    "Margarine",
    "It may be on a roll",
    "Blue Bonnet, e.g.",
    "Grocery stick",
    "It may be on a roll",
    "Imitation butter",
    "Bread spread",
    "Blue Bonnet product",
    "Prefix with resin",
    "Nondairy spread",
    "Corn product",
    "Toast topper",
    "Stick on the dinner table",
    "Spread served in bars",
    "It's spread in the dining room",
    "Dairy case item",
    "Yellow spread",
    "Prefix with resin",
    "Nondairy spread",
    "Grocery list item",
    "Butter substitute",
    "Butter alternative",
    "Stick in the dairy case",
    "Spread out on the dining table",
    "Butter substitute",
    "It's sold in bars",
    "Margarine",
    "Toast topper",
    "Butter substitute",
    "It may be on a roll",
    "It may be on a roll",
    "Sub in a tub",
    "Toast topper",
    "It's found in sticks",
    "Parkay product",
    "Muffin topper",
    "Nondairy topping",
    "Refrigerator bar?",
    "Dairy aisle buy",
    "Bogus butter",
    "Butter alternative",
    "Cooking staple",
    "It may be laid on thick",
    "Promise, e.g.",
    "It melts in your mouth",
    "Margarine",
    "Nondairy spread",
    "Bread spread",
    "It may be picked up in bars",
    "Food bar",
    "Pat on the buns?",
    "Bread spread",
    "Parkay, e.g.",
    "Stick in the fridge",
    "Innkeeper's spread",
    "Margarine",
    "Toast topper",
    "Bogus butter",
    "Ersatz butter",
    "Dairy aisle item",
    "Remedy for dry toast",
    "Sub in a tub",
    "Cheaper topper",
    "Food bar",
    "Margarine",
    "Bar at the dinner table",
    "Butterine",
    "Bread spread",
    "Early TV backdrop",
    "Remedy for dry toast",
    "Butterine",
    "Margarine",
    "Source of trans-fatty acids",
    "Blue Bonnet product",
    "Margarine",
    "Butterine",
    "Ersatz butter",
    "Low-fat alternative",
    "Kitchen tubful",
    "Dairy section purchase",
    "Dieter's spread",
    "It may come in pats",
    "It's sold in bars",
    "Margarine",
    "Stick in the fridge?",
    "Table spread",
    "Backdrop for a TV scene",
    "It may be on a roll",
    "The cheaper spread"
  ],
  "chas": [
    "Alternative to Chuck",
    "Cartoonist Addams",
    "Addams who created \"The Addams Family\"",
    "Cartoonist Addams",
    "Addams who created \"The Addams Family\"",
    "Alternative to Chuck",
    "English pop duo ___ & Dave",
    "Cartoonist Addams",
    "Addams who created the Addams family",
    "Cartoonist Addams",
    "Cartoonist Addams",
    "Cartoonist Addams",
    "Cartoonist Addams",
    "The New Yorker cartoonist Addams",
    "Alternative to Chuck",
    "Cartoonist Addams",
    "Chuck alternative",
    "Cartoonist Addams",
    "Cartoonist Addams, for short",
    "Chuck alternative",
    "Cartoonist Addams",
    "Princely abbr.",
    "Chuck alternative",
    "Cartoonist Addams",
    "Cartoonist Addams"
  ],
  "beat": [
    "Kind of poet",
    "Mix, as eggs",
    "Exhausted",
    "Trounce",
    "With 32-Down, 1950s counterculture figures",
    "Shellac",
    "Patrolman's rounds",
    "About ready to drop",
    "Bushed",
    "Pooped",
    "Tanned",
    "Wiped out",
    "Bushed",
    "Ready to turn in",
    "Recipe direction",
    "Exhausted",
    "Switch",
    "Ready to crash",
    "Rhythm",
    "Ready for the sack",
    "Cop's path",
    "Wiped out",
    "Jack Kerouac or Allen Ginsberg",
    "Tuckered out",
    "All in",
    "Thump",
    "Outscore",
    "Regular drumming",
    "Dancing stimulus",
    "Flatfoot's circuit",
    "Play, as drums",
    "Kerouac or Burroughs",
    "Overcome",
    "Ready for the sack",
    "Conquer",
    "Tuckered out",
    "Kerouac, e.g.",
    "Newswriter's specialty",
    "Tuckered out",
    "Rock and roll prerequisite",
    "Vanquished",
    "Cop's milieu",
    "Tuckered out",
    "Lick",
    "The conductor keeps it",
    "Cop's route",
    "Pooped",
    "___ Generation (Kerouac et al.)",
    "Pooped",
    "Finish ahead of",
    "Recipe instruction",
    "Pooped",
    "Pooped",
    "Worn out",
    "Bushed"
  ],
  "kato": [
    "Role for Jay Chou in 2011's \"The Green Hornet\"",
    "Green Hornet's sidekick",
    "Bruce Lee role based on an old radio character",
    "The Green Hornet's masked driver",
    "Bruce Lee's role in TV's \"The Green Hornet\"",
    "Batman : Robin :: Green Hornet : ___",
    "Assistant played by Bruce Lee",
    "Green Hornet's sidekick",
    "He fought Robin on an episode of \"Batman\"",
    "The Green Hornet's valet",
    "The Green Hornet's valet",
    "Green Hornet's sidekick",
    "Bruce Lee TV role",
    "The Green Hornet?Æs valet",
    "Green Hornet's sidekick",
    "Green Hornet's valet",
    "Green Hornet's aide",
    "\"The Green Hornet\" role",
    "The Green Hornet's sidekick",
    "Green Hornet's sidekick",
    "1920's Japanese premier",
    "Sidekick of the Green Hornet",
    "Mount ___ (Minnesota ski resort)",
    "O.J.'s famous houseguest",
    "Green Hornet's aide",
    "The Green Hornet's valet",
    "Green Hornet's aide",
    "Inspector Clouseau's valet"
  ],
  "aten": [
    "Athletic conference that UMass belongs to",
    "\"Do you have two fives for ___?\"",
    "Rates ___ (is perfect)",
    "What two fives are change for",
    "\"Can you break ___?\"",
    "Rate ___ (be perfect)",
    "\"... ___-foot pole!\"",
    "Athletic conf. for UMass",
    "\"... ___-foot pole!\"",
    "Egyptian sun deity",
    "U.N.C. Charlotte's athletic conf.",
    "Egyptian solar disk",
    "Rate ___ (be deemed flawless)",
    "Two fives for ___",
    "Amenhotep IV's god",
    "Egyptian sun god",
    "Give two fives for ___",
    "Solar disk",
    "Rate ___ (perform perfectly)",
    "Villanova's athletic conference, briefly",
    "Egyptian sun god",
    "Egyptian solar deity",
    "Rate ___ (be perfect)",
    "\"... two fives for ___?\"",
    "Rate ___ (be perfect)",
    "Amenhotep IV's god",
    "Egyptian solar deity",
    "Powerful U.S. military plane",
    "Fordham's basketball conference, informally",
    "See 115-Down",
    "Deity for Nefertiti",
    "Solar deity",
    "Rate ___ (be perfect)",
    "Two fives for___",
    "Solar disk",
    "Egyptian solar disk",
    "\"...___ o'clock scholar\"",
    "Egyptian sun god",
    "Egyptian sun disk",
    "Egyptian sun deity",
    "\"___ o'clock scholar\"",
    "Heliolater's deity",
    "Solar deity",
    "Ancient sun disk",
    "Ancient desk",
    "Amenhotep IVs sole deity"
  ],
  "hess": [
    "Big name in oil",
    "Gas company with toy trucks",
    "Oil magnate Leon who once owned the New York Jets",
    "Name on a green toy truck",
    "Dame Myra of piano fame",
    "Oil and gas giant",
    "Gas company famous for its toy trucks",
    "Gas company selling toy trucks",
    "Big name in oil",
    "Name on a toy truck",
    "Gas brand with a green-and-white logo",
    "ConocoPhillips competitor",
    "Energy company in the Fortune 100",
    "Big name in 59-Down exploration",
    "Physiology Nobelist Walter Rudolf ___",
    "Gas station name",
    "Russian chemist with a law of thermodynamics named after him",
    "Gas company famous for its toy trucks",
    "Pianist Myra",
    "BP competitor",
    "Physics Nobelist Victor who discovered cosmic radiation",
    "Name on toy fuel trucks",
    "Big name in oil",
    "Gas company that sells toy trucks",
    "Global energy company",
    "Pianist Dame Myra",
    "Amoco alternative",
    "Dame Myra",
    "Gas company with collectible toy trucks",
    "Big name in oil",
    "English pianist who was made a dame",
    "Gas company known for its toy trucks",
    "Pianist Dame Myra",
    "Oilman Leon who owned the New York Jets",
    "Amerada ___ (oil giant)",
    "Shell competitor",
    "Dame Myra",
    "Dame Myra",
    "Amerada ___ (Fortune 500 company)",
    "Amerada ___ (Fortune 500 company)",
    "Amerada ___ (petroleum giant)",
    "Pianist Dame Myra",
    "Pianist Myra",
    "Pianist Dame Myra",
    "Dame Myra",
    "Infamous Rudolf",
    "Pianist Myra",
    "Pianist Myra",
    "Pianist Myra",
    "Pianist Myra",
    "Pianist Myra",
    "Spandau's last prisoner",
    "Piano-playing Dame",
    "Dame Myra",
    "Amerada ___ (oil giant)",
    "Pianist Myra"
  ],
  "baku": [
    "Capital of Azerbaijan",
    "Transcaucasian capital",
    "Azerbaijan's capital",
    "Asian oil capital",
    "Capital on the Caspian Sea",
    "Azerbaijan's capital",
    "Capital of Azerbaijan",
    "Azerbaijan's capital",
    "Capital on the Caspian",
    "Capital on the Caspian",
    "Capital of Azerbaijan",
    "Azerbaijan's capital",
    "Capital on the Caspian",
    "Kasparov's birthplace"
  ],
  "beso": [
    "Show of amor",
    "Spanish kiss",
    "Kiss: Sp.",
    "Kiss, in Spanish",
    "\"I should ___ lucky\"",
    "Spanish for \"kiss\"",
    "Don Juan's kiss",
    "\"Eso ___\" (Paul Anka hit)",
    "Kiss: Sp.",
    "\"Eso ___\" (Paul Anka hit)",
    "Kiss, in a Paul Anka song",
    "\"Eso ___\" (Paul Anka hit)",
    "Don Juan's kiss",
    "\"You'd ___ Nice to Come Home To\""
  ],
  "bien": [
    "Adverb in both French and Spanish",
    "Common reply to \"Como estas?\"",
    "\"That's good\": Fr.",
    "\"Tres ___!\"",
    "French well",
    "First Indochina War's Battle of Dien ___ Phu",
    "\"Tres ___\"",
    "\"___ sur\" (\"Of course\": Fr.)",
    "\"Tres ___\" (\"Very well,\" in French)",
    "Opposite of badly: Fr.",
    "\"Tres ___\"",
    "French word of approval",
    "\"Tres ___!\"",
    "\"Tres ___\" (\"very well,\" in Paris)",
    "\"Tres ___!\"",
    "Tres ___",
    "Artesian \"well\"",
    "\"Tres ___!\"",
    "Good: Fr.",
    "\"Tres ___!\""
  ],
  "idig": [
    "\"Gotcha!,\" once",
    "Hipster's \"gotcha\"",
    "\"Understood, man\"",
    "\"Point taken,\" '60s-style",
    "\"Gotcha\"",
    "\"Sounds good!\"",
    "\"Understood, man\"",
    "\"Got it!,\" beatnik-style",
    "\"That's cool with me!\"",
    "\"Understood, dude\"",
    "\"Right on,\" to a hipster",
    "\"Gotcha, dude\"",
    "\"Gotcha, dude!\"",
    "Hippie's \"Got it!\"",
    "\"Cool, bro\"",
    "\"Gotcha, man\"",
    "\"Gotcha\"",
    "\"That's cool, man\"",
    "\"Loud and clear, bro\"",
    "\"Understood, man\"",
    "\"Gotcha\"",
    "\"Gotcha\"",
    "Hipster's \"Understood!\"",
    "\"Gotcha!\"",
    "\"Gotcha,\" to a beatnik",
    "Beatnik's \"Gotcha\"",
    "\"Gotcha!\"",
    "\"Got it\"",
    "\"Gotcha\"",
    "Beatnik's \"Gotcha!\"",
    "\"Gotcha\"",
    "\"That's clear to me\" in beat-speak",
    "\"Gotcha\"",
    "\"That's clear, man\"",
    "\"Perfectly clear, man!\"",
    "Answer to 10-Down",
    "\"Got it\"",
    "\"Got it\""
  ],
  "lark": [
    "Joy ride, for example",
    "Caper",
    "Bird symbolizing daybreak",
    "Fun adventure",
    "Escapade",
    "Carefree adventure",
    "Pacer",
    "Happy as a ___",
    "Merry adventure",
    "Prankster's activity",
    "It's just for fun",
    "Romp",
    "Meadow bird",
    "Bit of harmless mischief",
    "Escapade",
    "Caper",
    "See 38-Across",
    "Brownish songbird",
    "Caper",
    "Escapade",
    "Shenanigan",
    "Caper",
    "Paradigm of happiness",
    "Bird that \"at heaven's gate sings,\" in Shakespeare",
    "Just-for-fun activity",
    "Carefree outing",
    "Frolic",
    "Symbol of happiness",
    "Carefree adventure",
    "Songbird",
    "Escapade",
    "Caper",
    "Harmless prank",
    "Escapade",
    "Escapade",
    "Escapade",
    "Escapade",
    "Songbird",
    "Shenanigan",
    "Escapade",
    "Meadow bird"
  ],
  "crap": [
    "Losing dice roll",
    "\"Oh, no!\"",
    "Groan-inducing dice roll",
    "Losing casino roll",
    "Lose in a dice game, with \"out\"",
    "Losing casino roll",
    "Losing roll in a casino"
  ],
  "borg": [
    "Ice-___ (old tennis nickname)",
    "Tennis champ Bjorn",
    "Wimbledon champ, 1976-80",
    "Winner of 11 Grand Slam tennis titles",
    "\"Star Trek\" enemy, with \"the\"",
    "\"Star Trek\" villains",
    "McEnroe rival",
    "\"Star Trek: First Contact\" villains, with \"the\"",
    "Tennis star nicknamed \"Ice Man\"",
    "Five-time Wimbledon champ",
    "Wimbledon champ, 1976-80",
    "Tennis's Bjorn",
    "Winner of 11 Grand Slam tournament titles",
    "Villainous \"Star Trek\" collective, with \"the\"",
    "1976-80 Wimbledon champ",
    "Winner of Wimbledon for five consecutive years",
    "Evil \"Star Trek\" group, with \"the\"",
    "Wimbledon champ, 1976-80",
    "Wimbledon five-peater",
    "Tennis's 1979-80 Player of the Year",
    "Winner of five consecutive Wimbledons",
    "Five-time Wimbledon champ",
    "Five-time Wimbledon champion",
    "Tennis's Bjorn",
    "Wimbledon champ for five years straight",
    "Five-time Wimbledon champ",
    "Tennis champ Bjorn",
    "Five-time Wimbledon champ, 1976-80",
    "1976-80 Wimbledon champ",
    "Five-time Wimbledon champ",
    "McEnroe rival"
  ],
  "pelt": [
    "Bombard (with)",
    "Bombard, as with snowballs",
    "Pummel with snowballs, say",
    "Hide",
    "Hit repeatedly, as with snowballs",
    "Bombard",
    "Come down hard, as hail",
    "Bombard",
    "Animal hide",
    "Clobber with snowballs, say",
    "Bombard",
    "Animal hide",
    "Hide",
    "Hide",
    "Hide",
    "Hide",
    "Hide",
    "Attack with snowballs",
    "Primitive coat",
    "What hailstones do",
    "Trophy, of sorts",
    "Commodity for John Jacob Astor",
    "Throw things at",
    "Hide",
    "Indian barter item",
    "Shower with force",
    "Stone, e.g.",
    "Animal hide",
    "Trapper's ware",
    "Fur trader's fur",
    "Attack, as with eggs",
    "Trapper's prize",
    "Trapper's prize",
    "Skin",
    "Hit with snowballs, say",
    "Shower",
    "Trapper's prize",
    "Trapper's trophy",
    "Trapper's prize",
    "Throw things at",
    "Hit",
    "Attack, as with snowballs",
    "Neanderthal's wear",
    "Animal skin",
    "Barrage",
    "Cannonade",
    "Bombard",
    "Bombard",
    "Pound",
    "Hide",
    "Hide",
    "Bombard",
    "Leather-to-be",
    "Trapper's trophy",
    "It gets a tanning",
    "Trapper's trophy",
    "Hide",
    "Clobber",
    "Hide"
  ],
  "quiz": [
    "\"Jeopardy!,\" basically",
    "Interrogate",
    "Question",
    "Grill",
    "Grill",
    "Sporcle.com feature",
    "Pump, in a way",
    "It can be frightening when one is popped",
    "Grill",
    "It's often administered orally",
    "Classroom handout",
    "20 questions, say",
    "Memory jogger",
    "Interrogate",
    "Interrogate",
    "Kind of kid",
    "Check up on",
    "Regular Cosmo feature",
    "\"Jeopardy!\" is one",
    "\"Jeopardy!,\" essentially"
  ],
  "veal": [
    "What might be parm for the course?",
    "___ parm",
    "Beef alternative",
    "Main ingredient in Wiener schnitzel",
    "Kind of chop",
    "___ piccata",
    "Osso buco need",
    "Cutlet meat",
    "___ chops",
    "Calves' meat",
    "Osso buco need",
    "Young beef?",
    "Scaloppine, usually",
    "Calf's meat",
    "Calf product",
    "Osso buco, basically",
    "Calves' meat",
    "Word before Oscar or Orloff",
    "___ Oscar (entree)",
    "Wiener schnitzel meat",
    "Meat that's often served piccata",
    "___ provencale",
    "Saltimbocca ingredient",
    "Wiener schnitzel meat",
    "Butcher's offering",
    "Fricandeau, e.g.",
    "___ Marsala",
    "Baby beef",
    "Calf's meat",
    "Some white meat",
    "Calves?Æ meat",
    "Saltimbocca ingredient",
    "Scaloppine, usually",
    "Calf meat",
    "Calf's meat",
    "Medallion meat",
    "Kind of chops",
    "Saltimbocca base",
    "Some cutlet cuts",
    "___ piccata",
    "Kind of chop",
    "Beef alternative",
    "Kind of stew",
    "Kind of cutlet",
    "Calf's meat"
  ],
  "luau": [
    "Occasion for a roast",
    "Beach ball?",
    "Beach bash",
    "Island party",
    "Waikiki party",
    "Polynesian shindig",
    "Feast that might include lomi-lomi salmon",
    "Hawaiian feast",
    "Event with 48-Down dancing",
    "Party with pu pu platters",
    "Hawaiian shindig",
    "Outdoor feast",
    "Party with poi",
    "Party on a beach",
    "Hawaiian party",
    "Outdoor party",
    "Hilo do",
    "Common dance theme",
    "Hawaiian do",
    "Occasion to eat poi",
    "Torch-lit event",
    "Pig-out party?",
    "Party with a roast pig, perhaps",
    "Pig-roasting occasion",
    "Bash on a beach",
    "Hawaiian feast",
    "Island do",
    "Occasion to use a cooking pit",
    "Party with poi",
    "Island do",
    "Hawaiian feast",
    "Hawaiian wingding",
    "Event with ukulele entertainment",
    "Island shindig",
    "Waikiki wingding",
    "Hawaiian cookout",
    "Beach bash",
    "Pig and poi feast",
    "Island do",
    "Outdoor party",
    "Hawaiian feast",
    "Occasion to cook in an imu",
    "Outdoor feast",
    "Island do",
    "Where to wear a lei, maybe",
    "Island party",
    "Island feast",
    "Island get-together",
    "Party for lei wearers",
    "Poi party",
    "Hawaiian cookout",
    "Oahu wingding",
    "Maui bash",
    "Party with leis",
    "Pacific repast",
    "Waikiki wingding",
    "Something eaten with the palms?",
    "Big Island bash",
    "Meal accompanied by a hula",
    "Outdoor party",
    "Cookout in Honolulu",
    "Hilo feast",
    "Poi party",
    "Molokai meal",
    "Outdoor party",
    "Meal on Maui",
    "Where to see a hula",
    "Hawaiian do",
    "Hilo feast",
    "Molokai meal",
    "Where to wear a muumuu",
    "Waikiki Beach bash",
    "Oahu banquet",
    "Ho do",
    "Kaneohe event",
    "Pearl City party",
    "Outdoor feast",
    "Where humuhumunukunukuapuaa might be served"
  ],
  "fros": [
    "Some natural hairstyles, informally",
    "The Jackson 5 had some big ones, informally",
    "Big dos",
    "Some natural hairdos, for short",
    "Jackson 5 coifs, informally",
    "Bushy dos",
    "Big dos",
    "Bushy dos, informally",
    "Hairdos that are hard to wear hats with, familiarly",
    "'Dos you don't want to sit behind at movies",
    "Places for picks, informally",
    "Jackson 5 features, slangily",
    "Some bushes, for short"
  ],
  "gary": [
    "Libertarian politico Johnson",
    "SpongeBob's pet snail",
    "Larson who created \"The Far Side\"",
    "Personal aide to Selina Meyer on \"Veep\"",
    "Boy's name that's an Indiana city",
    "Actor Coleman or Oldman",
    "NW Indiana city",
    "U.S. Steel was once its biggest employer",
    "Player of golf",
    "Indiana city on Lake Michigan",
    "Player of golf",
    "Indiana birthplace of the Jackson 5",
    "Indiana city near Chicago",
    "\"The Far Side\" cartoonist Larson",
    "Politico Hart",
    "Hoosier industrial center",
    "With 29-Down, central role on \"Knots Landing\"",
    "Player who putts",
    "The Jacksons' hometown"
  ],
  "unum": [
    "\"One\" on ones",
    "E pluribus ___",
    "One, on a one",
    "E pluribus ___",
    "\"E pluribus ___\"",
    "\"E pluribus ___\"",
    "One, on a bill",
    "Motto word on the Great Seal",
    "One on a U.S. penny",
    "\"E pluribus ___\"",
    "One for the money?",
    "\"One,\" in a coin motto",
    "One for Caesar?",
    "One for the money?",
    "One at the U.S. Mint?",
    "Old one",
    "One on a one-dollar bill",
    "One in a mint?",
    "\"One\" on a penny",
    "One for the money?",
    "One for the money?",
    "\"One\" on a coin",
    "E pluribus ___",
    "Coin word",
    "E pluribus ___",
    "E pluribus ___",
    "Latin word in many pockets",
    "\"One\" on a one",
    "Ancient one",
    "E pluribus ___",
    "E pluribus ___",
    "Coin word",
    "U.S. coin word",
    "One for the money?",
    "Coined word?",
    "Coined word?",
    "E pluribus ___",
    "Latin word on a penny",
    "I, to Claudius",
    "One in numismatics",
    "\"One\" on a one",
    "E pluribus ___",
    "Word on a coin",
    "Coin word",
    "Word on a U.S. coin",
    "Latin quarter word?",
    "One of the U.S. coins",
    "U.S. coin word",
    "E pluribus ___",
    "Word on a coin"
  ],
  "bbqs": [
    "Occasions for roasts, for short",
    "Backyard gatherings, for short",
    "Some cookouts, informally",
    "Summer outdoor events, informally",
    "Tailgaters' activities, for short",
    "Outdoor meals with hamburgers or hot dogs, say, in brief",
    "Patio cookouts, for short",
    "Things associated with pits and spits, briefly",
    "Patio parties, briefly"
  ],
  "depp": [
    "\"Pirates of the Caribbean\" star",
    "Actor who voiced the title character in 2018's \"Sherlock Gnomes\"",
    "Johnny who made his film debut in \"A Nightmare on Elm Street\"",
    "Grindelwald's portrayer in \"Fantastic Beasts and Where to Find Them\"",
    "Wonka portrayer",
    "Johnny of 2005's \"Charlie and the Chocolate Factory\"",
    "Johnny of \"Chocolat\" and \"Charlie and the Chocolate Factory\"",
    "Tonto player of 2013",
    "Johnny of \"Sleepy Hollow\"",
    "Gilbert Grape portrayer",
    "Captain Sparrow portrayer",
    "2013 Tonto portrayer",
    "Actor whose breakout role was on TV's \"21 Jump Street\"",
    "\"Ed Wood\" star",
    "People's Sexiest Man Alive ... twice",
    "Pirate portrayer of film",
    "Co-star of DiCaprio in \"What's Eating Gilbert Grape\"",
    "Johnny who played Sweeney Todd",
    "Ed Wood player in \"Ed Wood\"",
    "Johnny of Hollywood",
    "Co-star of \"Blow,\" 2001",
    "Johnny of \"Pirates of the Caribbean\"",
    "Johnny who played Willy Wonka",
    "2005 portrayer of Wonka",
    "\"Secret Window\" star, 2004",
    "Johnny of \"Ed Wood\"",
    "\"Ed Wood\" title role player",
    "\"Don Juan DeMarco\" co-star",
    "Actor Johnny",
    "Actor Johnny",
    "\"Edward Scissorhands\" star",
    "\"Ed Wood\" star, 1994",
    "Johnny of \"Edward Scissorhands\""
  ],
  "flab": [
    "Toning target",
    "Love handles, essentially",
    "Extra inches",
    "Exerciser's target",
    "Exerciser's target",
    "Spare tire",
    "Exerciser's target",
    "Exerciser's target",
    "Exerciser's enemy",
    "Workout target",
    "Liposuction target",
    "Dieter's target",
    "Spare tire material",
    "Undesirable roll",
    "Blubber",
    "Inches for pinches",
    "Spare tire, so to speak",
    "Something Mr. Olympia lacks",
    "Gym target",
    "\"Spare tire\"",
    "Spare tire",
    "Spare tire, so to speak",
    "Workout target",
    "\"Spare tire\"",
    "Opposite of muscle",
    "Extra fat",
    "Exercise target",
    "Exerciser's target",
    "Love handles, essentially",
    "Exercise target",
    "\"Love handles\"",
    "Love handles, essentially",
    "\"Spare tire\"",
    "Trimming target",
    "Extra weight",
    "\"Spare tire\""
  ],
  "page": [
    "Newspaper unit",
    "Summon",
    "Leaf (through)",
    "\"Doctor Jones, you're needed at the front desk,\" e.g.",
    "Summon",
    "Part of a website",
    "Footnote info",
    "One running for the Senate?",
    "Beep",
    "Hill runner",
    "Call from the lobby, perhaps",
    "Web ___",
    "___ views (webmaster's data)",
    "One getting the message?",
    "See 112-Down",
    "It's bound to be turned",
    "Summon",
    "One of 15,490 in the first edition of the O.E.D.",
    "Rock legend Jimmy",
    "Call on an intercom, as a doctor",
    "Runner",
    "Summon via a P.A. system, e.g.",
    "Call for",
    "Beep, as a doctor",
    "Message runner",
    "Calendar unit",
    "Attendant",
    "Ask for by name",
    "Beep, say",
    "Contact at a hospital, say",
    "Senate errand runner",
    "Many an airport announcement",
    "Summon",
    "Newspaper unit",
    "Royal attendant",
    "Web site unit",
    "Beep",
    "Summon",
    "Use a beeper",
    "Lobby call",
    "Summon electronically, say",
    "Leaf unit",
    "Web designer's creation",
    "Webmaster's creation",
    "Use a beeper",
    "Doctor's interruption",
    "Leaf unit",
    "Beep",
    "Leaf",
    "Beep on a beeper",
    "Half of a leaf",
    "Reach on the road, perhaps",
    "Bellhop",
    "Summon",
    "Message bearer",
    "Senate runner",
    "Beep",
    "Leaf",
    "Summon",
    "Call to the phone",
    "Call by intercom"
  ],
  "carp": [
    "Bellyache",
    "Bellyache",
    "Complain",
    "Bellyache",
    "Complain querulously",
    "Goldfish, e.g.",
    "Find fault",
    "Cavil",
    "Complaining fish?",
    "Incessantly complain",
    "*Complain",
    "Gefilte fish option",
    "Be a kvetch",
    "Complain, complain, complain",
    "Be a nag",
    "Complain, complain, complain",
    "Beef",
    "68-Across, taking into account its 61-Across",
    "Keep the complaints coming",
    "Find fault to a fault",
    "Fault-find",
    "Complain",
    "Complaint",
    "Grouse",
    "Nitpick",
    "Beef",
    "Traditional Christmas Eve meal in Germany",
    "Cousin of a shiner",
    "Kick, so to speak",
    "Complain",
    "Beef",
    "Be a kvetch",
    "Beef",
    "Food fish",
    "Be a nag",
    "Grumble",
    "Kvetch",
    "Gefilte fish ingredient",
    "Pick apart",
    "Find fault",
    "Grouse",
    "Grouse",
    "Bellyache",
    "Browbeat, in a way",
    "Complain"
  ],
  "emme": [
    "1990s supermodel with a palindromic name",
    "Supermodel born Melissa Miller",
    "Model who wrote the 1996 book \"True Beauty\"",
    "J.Lo's daughter with a palindromic name",
    "One-named supermodel",
    "Model Melissa Aronson, familiarly",
    "Single-named supermodel",
    "One-named supermodel",
    "Noted plus-size model",
    "One-named supermodel",
    "One-named supermodel",
    "She's a plus",
    "Model born Melissa Miller",
    "One-named supermodel",
    "Noted plus-size model",
    "Noted plus-size model",
    "One-named supermodel",
    "\"Fashion Emergency\" host",
    "Big model",
    "One-named supermodel",
    "Name in plus-size modeling"
  ],
  "lilo": [
    "Stitch's pal, in film",
    "Stitch's human pal, in film",
    "\"___ & Stitch\"",
    "Disney's \"___ & Stitch\"",
    "Title girl in a 2002 Disney movie",
    "Friend of Stitch in the movies",
    "Disney title character surnamed Pelekai",
    "\"___ & Stitch\" (Disney film)",
    "Title character of a 2002 Disney film",
    "Movie pal of Stitch",
    "Disney title character from Hawaii",
    "Disney character from Hawaii",
    "Title girl in a 2002 Disney film",
    "\"___ & Stitch,\" 2002 animated film",
    "Stitch's pal in the movies",
    "Stitch's cartoon pal",
    "Disney's \"___ & Stitch\"",
    "Disney's \"___ & Stitch\""
  ],
  "rump": [
    "Posterior",
    "Kind of roast",
    "Back of a horse",
    "Backside",
    "___ steak (British term for a sirloin cut)",
    "Back end of a horse",
    "Posterior",
    "___ session (meeting after a legislative dissolution)",
    "Where a horse's tail is",
    "Bottom",
    "Backside",
    "Beef cut",
    "Behind",
    "Beef cut",
    "Beef cut",
    "Behind",
    "Rear",
    "Rear end",
    "Sit on it",
    "Cut of beef",
    "This is the end",
    "Roast cut",
    "Beef cut",
    "Upper cut?",
    "Roast cut",
    "Type of roast"
  ],
  "cpas": [
    "Workers with taxing schedules, for short?",
    "Tax pros, for short",
    "Tax return pros",
    "Frequent filers, for short",
    "Book reviewers, for short",
    "Tax pros, for short",
    "Big Four workers, for short",
    "Busy bees during tax season, for short",
    "No. crunchers",
    "Ones approving fin. statements",
    "Calculating sorts, in brief",
    "H&R Block staffers",
    "I.R.S. experts",
    "Busy Apr. workers",
    "Figureheads?",
    "They face liabilities in their work, in brief",
    "Number-crunching grp.",
    "Dealers in books and records",
    "I.R.S. experts",
    "Number crunchers, for short",
    "I.R.S. experts, you hope",
    "Excellent summers, for short?",
    "Calculating bunch, briefly",
    "Balancing pros",
    "No. crunchers",
    "Ones working on columns, for short",
    "I.R.S. workers",
    "Inspectors of fin. books",
    "Ernst & Young employees, for short",
    "Ones doing a balancing job?",
    "P. & L. statement preparers",
    "P. & L. preparers",
    "Calculating types",
    "Ones working with books",
    "1040 experts, for short",
    "Some tax advisers, for short",
    "Book auditors, for short",
    "Calculating folks?",
    "Checkers of entries, for short",
    "People with books, for short",
    "Ones filling out 1040's, for short",
    "They often fill out schedules",
    "Figureheads?",
    "Capital examiners, briefly",
    "Number crunchers, for short",
    "People doing book reviews?",
    "Sched. C preparers",
    "H&R Block employees",
    "Tax pros, for short",
    "No. crunchers",
    "No. brains?",
    "They work with many schedules",
    "Auditors",
    "They often itemize",
    "Workers in columns",
    "Calculating types",
    "Their days are numbered",
    "Auditors",
    "Corp. treasurers, maybe",
    "I.R.S. employees",
    "H & R Block workers",
    "Taxpayer reps, at times",
    "Bean counters, for short",
    "Ernst & Young staff",
    "They're busy in Apr.",
    "Professional bean counters"
  ],
  "heli": [
    "Prefix with pad",
    "Prefix with port",
    "Prefix with pad",
    "Lead-in to port",
    "Start to ski?",
    "Prefix with pad or port",
    "Prefix with port",
    "Prefix with port",
    "Prefix with pad",
    "Prefix with port",
    "Prefix with pad",
    "Port opener?",
    "Prefix with pad",
    "Prefix with pad",
    "Prefix with pad",
    "Port opener?",
    "Prefix with port or pad",
    "Port opening",
    "Start with pad or port",
    "Prefix with port",
    "Prefix with port or pad",
    "Port intro",
    "Port opening"
  ],
  "slue": [
    "Swivel on an axis",
    "Pivot",
    "Swivel around",
    "Pivot around an axis",
    "Zig or zag",
    "Swing around",
    "Turn on an axis",
    "Pivot on an axis",
    "Turn about",
    "Turn",
    "Zig or zag",
    "Swivel on an axis",
    "Turn on a pivot",
    "Turn around",
    "Swing around on an axis",
    "Fishtail, e.g.",
    "Turn on an axis",
    "Pivot",
    "Swing around",
    "Pivot",
    "Swing around",
    "Swing around",
    "Swing around",
    "Pivot",
    "Swing on an axis",
    "Turn on an axis",
    "Turn on a pivot",
    "Swing around",
    "Swing around",
    "Turn on a pivot",
    "Swing around",
    "Swing around",
    "Swing around",
    "Pivot",
    "Swing about",
    "Pivot around",
    "Turn about",
    "Pivot",
    "Swing Around",
    "Pivot",
    "Twist",
    "Twist around an axis",
    "Nautical twist",
    "Swing around",
    "Pivot",
    "Swing around",
    "Turn about",
    "Swing around"
  ],
  "asif": [
    "\"You wish!\"",
    "\"In your dreams!\"",
    "\"Puh-leeze!\"",
    "\"Yeah, right!\"",
    "\"Like, no way!\"",
    "\"That's rich!\"",
    "\"Yeah, I'm real sure!\"",
    "Statement often accompanied by an eye roll",
    "\"Not in a million years\"",
    "\"Yeah, right!\"",
    "\"In your dreams!\"",
    "\"You wish!\"",
    "\"Puh-lease!\"",
    "\"Yeah, right\"",
    "\"Yeah, right!\"",
    "Expression of doubt",
    "\"Yeah, right!\"",
    "With 36-Down, literally, beyond rational explanation",
    "\"You wish!\"",
    "\"In your dreams!\"",
    "\"Not likely!\"",
    "Valley girl's \"no\"",
    "\"Like that's gonna happen\"",
    "Like",
    "\"You wish!\"",
    "\"Keep dreaming!\"",
    "\"Yeah, right!\"",
    "\"Yeah, right!\"",
    "\"... ___ to say ...\"",
    "\"Highly doubtful\"",
    "\"I don't THINK so!\"",
    "\"In your dreams!\"",
    "\"___ I care\"",
    "\"Yeah, like that'll ever happen\"",
    "\"Yeah, right\"",
    "\"Dream on!\"",
    "\"Pshaw!,\" to a Valley girl",
    "\"Yeah, right!\"",
    "Derisive response to \"She thinks she's going to be homecoming queen\"",
    "Like",
    "\"Like that'll ever happen!\"",
    "\"A likely story\"",
    "\"Yeah ... whatever!\"",
    "\"Yeah, right!\"",
    "\"Keep dreaming!\"",
    "\"Keep dreaming!\"",
    "\"In your dreams!\"",
    "\"You wish!\"",
    "\"Like, no way!\"",
    "\"Like that'll ever happen!\"",
    "\"You wish!\"",
    "\"Dream on!\"",
    "\"You wish!\"",
    "\"Yeah, right!\"",
    "\"In your dreams!\"",
    "\"Dream on!\"",
    "\"Oh, that'll ever happen!\"",
    "\"Yeah, right!\"",
    "\"___ I care!\"",
    "\"You wish!\"",
    "\"Yeah, right!\"",
    "Phrase spoken by 16-Across",
    "Like",
    "Expression of ridicule",
    "\"Yeah, right!\"",
    "Valley girl's comeback",
    "\"Like, no way!\"",
    "\"No way that's gonna happen!\"",
    "Like",
    "\"Yeah, right!\" in teenspeak",
    "Catchphrase from \"Clueless\"",
    "\"___ I care!\"",
    "\"Oh, that'll happen!\"",
    "Like",
    "\"Like, no way!\"",
    "\"Oh sure, that'll ever happen\"",
    "Like",
    "\"Yeah, that'll ever happen\"",
    "\"Like, no way!\"",
    "Like",
    "\"Oh, you wish!\"",
    "Presuming that",
    "\"Oh, right!\"",
    "\"No way!\"",
    "\"Yeah, right!\"",
    "\"___ I cared!\"",
    "\"Yeah, right!\"",
    "Just like",
    "\"___I cared!\"",
    "Like"
  ],
  "noun": [
    "Word that might be \"proper\"",
    "What an article may come with?",
    "Friend or foe",
    "Feast or famine",
    "Verb preceder",
    "Follower of \"the,\" often",
    "You name it",
    "What you may call it",
    "Mad Libs prompt",
    "It's always something",
    "What you may call it?",
    "Something drawn when landing on a yellow square in Pictionary",
    "Person, place or thing",
    "Pluralizable thing",
    "What an adjective modifies",
    "Rock, paper or scissors",
    "Word that can be common",
    "Follower of \"a\" or \"the,\" often",
    "What \"n.\" means in a dictionary",
    "An example of itself",
    "One might appear next to an article",
    "Mad Libs label",
    "A follower?",
    "What an article may refer to",
    "Pluralizable word",
    "What you may call it?",
    "Mad Libs specification",
    "Trick or treat, e.g.",
    "Whatchamacallit?",
    "It may be declined",
    "Verb go-with",
    "You name it",
    "You name it",
    "Object, e.g.",
    "Verb accompanier",
    "Gerund, e.g.",
    "Adjective follower",
    "What you may call it",
    "It may be proper",
    "Word that's an example of itself",
    "Verb preceder, usually",
    "Proper ___",
    "You name it",
    "Thing, e.g.",
    "It may be proper",
    "Verb preceder",
    "Hook, line or sinker",
    "Self-identifying word",
    "Subject, usually",
    "Subject word",
    "Word before a verb, maybe",
    "It may be abstract",
    "Sentence subject, usually",
    "It may be subject to modification",
    "It could be proper",
    "Whatchamacallit?",
    "Something in writing?",
    "Verb preceder",
    "Sentence subject, often",
    "Word that's an example of itself",
    "Adjective follower",
    "One may be proper",
    "Sentence starter",
    "Verb, for example",
    "Friend or foe, e.g.",
    "Proper ___",
    "It may be proper",
    "It may be common",
    "What \"-ence\" forms",
    "Word ending in \"o\" in Esperanto",
    "61-Across, for example",
    "Verb accompanier",
    "Verb preceder",
    "Common thing?",
    "You name it",
    "Parser's concern",
    "Subject, usually",
    "It stands for something",
    "You name it",
    "It may be proper",
    "Subject, usually",
    "Sentence subject",
    "Sentence subject",
    "It may be proper",
    "Example, for example",
    "Grammatical subject"
  ],
  "gibe": [
    "Taunt",
    "Mock",
    "Sharp remark",
    "Razz",
    "Razz",
    "Dig",
    "Barb",
    "Taunt",
    "\"Your mama wears army boots,\" e.g.",
    "Snide remark",
    "Taunt",
    "Ridicule",
    "Razz",
    "Taunt",
    "Ridicule",
    "Razz",
    "Taunt",
    "Bronx cheer",
    "Scoff at",
    "Wisecrack",
    "Taunt",
    "Barbed remark",
    "Taunt",
    "Taunt",
    "Dig",
    "Jeer",
    "Mock",
    "Taunt",
    "Barb",
    "Brickbat"
  ],
  "blip": [
    "Spot on a radar screen",
    "Sonar signal",
    "Minor anomaly",
    "It's hardly worth noticing",
    "It might be on one's radar",
    "Minor deviation",
    "Random data point",
    "Dot on a screen",
    "Dot on a radar screen",
    "Tiny anomaly",
    "Spot at an airport",
    "Lie indicator on a polygraph test",
    "Plane, on a radar screen",
    "Radar screen point",
    "Tiny indicator",
    "Radar screen dot",
    "It's hardly a trend",
    "Momentary disruption",
    "Tiny bump on a graph",
    "Tiny light that's here and gone",
    "Radar image",
    "Sound on classic Pong",
    "Control tower image",
    "It's barely noticeable",
    "Radar sign",
    "It might be on one's radar",
    "Radar signal",
    "Radar image",
    "Brief interruption",
    "Radar image",
    "Spot on the radar",
    "Air traffic controller's concern",
    "Tiny bump on a graph",
    "It's monitored by an air traffic controller",
    "Radar screen image",
    "Radar screen image",
    "Tiny percentage in the polls",
    "Tiny amount",
    "Radar sighting",
    "Spot on a radar screen",
    "Sonar spot",
    "Radar signal",
    "Submarine, on sonar",
    "Radar screen dot",
    "Radar point",
    "Radar dot",
    "Radar screen image",
    "Brief upturn",
    "No big thing",
    "Point on a radar screen",
    "Radar screen image"
  ],
  "yeti": [
    "Supposed source of mysterious footprints in the Himalayas",
    "Figure on Disney's Expedition Everest ride",
    "Elusive legend",
    "Sight on Disney World's Expedition Everest ride",
    "\"The wild man of the snows\"",
    "Mythical creature seen on old Bhutanese stamps",
    "Appropriately named brand of coolers",
    "Subject of a 1960 expedition by Sir Edmund Hillary",
    "Legendary source of large footprints",
    "Hairy Himalayan humanoid",
    "Legendary humanoid",
    "Beast of the Himalayas",
    "Dubious Tibetan sighting",
    "Fabulous creature",
    "Supposed source of large footprints",
    "Mysterious sighting in the Himalayas",
    "Dubious sighting in the Himalayas",
    "Beast in rare \"sightings\"",
    "Supposed sighting in Tibet",
    "Himalayan mystery",
    "Legend of climbing expeditions",
    "Legendary mountain climber",
    "Mysterious sighting",
    "Himalayan legend",
    "Abominable Snowman",
    "Figure that's unbelievable?",
    "Cold-climate cryptid",
    "Himalayan myth",
    "Himalayan legend",
    "Subject of a onetime Nepali hunting license [true fact!]",
    "Himalayan legend",
    "Cryptozoological beast",
    "Apocryphal beast",
    "So-far-undiscovered one",
    "Seven-foot (or so) cryptid",
    "Humanoid cryptid",
    "Himalayan legend",
    "Giant of legend",
    "Elusive legend",
    "Mysterious figure",
    "Sasquatch's kin",
    "Subject of a blurry photo, maybe",
    "Hairy legend",
    "Mountain sighting",
    "Legend of the Himalayas",
    "Mountain sighting, maybe",
    "Himalayan beast",
    "Himalayan legend",
    "Asian of legendary status?",
    "Hirsute Himalayan",
    "Himalayan legend",
    "Hairy Himalayan",
    "Bigfoot's Asian cousin",
    "Hulking Himalayan of legend",
    "Elusive Himalayan creature",
    "Mysterious creature",
    "Subject of Nepalese legend",
    "Abominable Snowman",
    "Creature of legend",
    "Himalayan legend",
    "Cousin of Bigfoot",
    "Abominable Snowman",
    "Dubious sighting in the Himalayas",
    "Himalayan hoax",
    "Himalayan sighting",
    "Cryptozoological topic",
    "Abominable Snowman",
    "Abominable Snowman",
    "Mysterious creature",
    "Mythical Himalayan beast",
    "Hairy Himalayan",
    "Himalayan myth",
    "Elusive subject",
    "Sherpa sighting",
    "Sherpa sighting, maybe",
    "Hairy humanoid",
    "Object of scientific dispute",
    "Mysterious Himalayan",
    "The Abominable Snowman",
    "Himalayan legend",
    "Abominable Snowman",
    "Abominable Snowman",
    "Himalayan legend",
    "Elusive creature",
    "Elusive creature",
    "Cryptobiologist's interest, perhaps",
    "Abominable Snowman",
    "Abominable Snowman",
    "Tibetan sighting",
    "Abominable Snowman",
    "Tibetan legend",
    "Sasquatch cousin",
    "Abominable Snowman"
  ],
  "glob": [
    "Amorphous lump",
    "Apt rhyme for \"blob\"",
    "Shapeless mass",
    "Amorphous mass",
    "Semiliquid lump",
    "Liquidy lump",
    "Lump",
    "Gunky lump",
    "Amorphous lump",
    "Lump",
    "Soft, thick lump",
    "Dollop",
    "Semiliquid lump",
    "Amount squeezed from a tube",
    "Rounded lump",
    "Mayo serving",
    "Lump",
    "Whipped cream serving",
    "Lump of cream, for example",
    "Lump of clay, say",
    "Goo unit",
    "Liquidy lump",
    "Dollop",
    "Lump of jelly, e.g."
  ],
  "peal": [
    "Ring, as church bells",
    "Sound of church bells",
    "Ring",
    "Burst of laughter",
    "Toll",
    "Church bell sound",
    "Take its toll?",
    "Ring out",
    "Sound of thunder",
    "Ring",
    "Big Ben sound",
    "Carillon sound",
    "Ring",
    "Ring, as church bells",
    "Church bells' sound",
    "Ring",
    "Big ring",
    "Ring, as bells",
    "Ring",
    "Thunder sound",
    "Ring tone?",
    "Sound from a steeple",
    "Roll",
    "Bit of laughter",
    "Ring",
    "Sound of laughter",
    "Set of rings?",
    "Ring",
    "Loud succession of sounds",
    "Bell sound",
    "Ring",
    "Ring",
    "Thunder sound",
    "Ring",
    "Ring",
    "Sound, as bells",
    "Burst of laughter",
    "Reverberation",
    "Clap",
    "Ring",
    "Sound of thunder",
    "Thunder sound",
    "Clap",
    "Resound",
    "Ring",
    "Sound of laughter",
    "Bells' sound",
    "Sound of laughter",
    "Cannon sound",
    "Knell",
    "Sound, as a bell",
    "Toll"
  ],
  "fore": [
    "Shout after an errant drive",
    "\"Heads up!,\" to a golfer",
    "Warning from one holding an iron",
    "Anterior",
    "Duffer's warning",
    "Cry after an errant golf shot",
    "\"Look out!\"",
    "Like some plane exits",
    "Word of caution",
    "\"Look out!,\" to a golfer",
    "Golfer's cry",
    "Cry after a bad swing",
    "\"Heads up!\"",
    "Golfer's cry",
    "Front for \"front\"",
    "Links alert",
    "Driver's alert",
    "\"Heads up!\"",
    "*It may precede a stroke",
    "Golf cry",
    "Driver's warning?",
    "Woods call",
    "Driver's warning",
    "Fairway cry",
    "Cry after a bad swing",
    "Golfer's shout",
    "Cry heard on a fairway",
    "\"Watch out!\"",
    "Tee cry",
    "Duffer's cry",
    "Golfer's cry",
    "Cry while holding an iron",
    "Driver's warning",
    "Front",
    "Driver's alert",
    "Duffer's cry",
    "It may precede a stroke",
    "Warning at St. Andrews",
    "Golfer's alert",
    "\"Watch out!\"",
    "Driver's warning",
    "Golfer's cry",
    "Swinger's shout",
    "Golfer's cry",
    "Fairway warning",
    "Toward the bow",
    "Links cry",
    "Links alert",
    "Cry at Shinnecock Hills",
    "Golfer's shout"
  ],
  "ebon": [
    "Black, in poetry",
    "Dark hue",
    "Jet-black",
    "Black, to poets",
    "Black, in poetry",
    "Black",
    "Black, in poetry",
    "Jet",
    "Black, to a bard",
    "Black, in poetry",
    "\"Heaven's ___ vault, / Studded with stars unutterably bright\": Shelley",
    "Colored like ink in \"Love's Labour's Lost\"",
    "Black, to poets",
    "Black, to a bard",
    "Jet",
    "Poetic black",
    "Deep black",
    "Black shade",
    "Jet",
    "Black, to bards",
    "Jet",
    "Black, to bards",
    "Shade of a swan's bill in a Keats poem",
    "Black, to bards",
    "Black, in poetry",
    "Like black piano keys",
    "Black as night",
    "Ink-colored, in Shakespeare",
    "Dark black",
    "Black",
    "Black",
    "Black, to bards",
    "Like ink, poetically",
    "Like death's dart, in Shakespeare",
    "Black, to bards",
    "Deep black",
    "Black, in verse",
    "Raven",
    "Like heaven's vault, in a Shelley poem",
    "Like some keys",
    "Like japanned wood",
    "Raven-colored",
    "Dark, in verse",
    "Black",
    "Black, in poetry",
    "Black, poetically",
    "Black, to poets",
    "Like ink, to Shakespeare",
    "Black, to poets",
    "Black",
    "Shade of black",
    "Coal-black",
    "Black, in poetry",
    "Black, to Blake",
    "Black, in poetry",
    "Dark, to poets",
    "Deep black",
    "Jet",
    "Black",
    "Black",
    "Reflecting no light",
    "Black",
    "Ink's color, to Shakespeare",
    "Like some piano keys",
    "Black",
    "Inky",
    "Black, to Blake",
    "Poet's black",
    "Black, in poetry",
    "Sable",
    "Dark, to Donne",
    "Bard's black",
    "Jet",
    "Dark",
    "Black",
    "Black",
    "Lustrous black",
    "Dark, poetically"
  ],
  "been": [
    "Has-___",
    "Existed",
    "\"How ya ___?\"",
    "Served as",
    "\"It's ___ fun!\"",
    "Existed",
    "\"It's ___ real\"",
    "\"How you ___?\"",
    "Has-___",
    "\"How have you ___?\"",
    "Lived",
    "Has-___",
    "\"Have you ___ good?\"",
    "\"___ there, done that\"",
    "Has-___",
    "Lived",
    "Existed",
    "\"It's ___ real!\"",
    "\"___ there\"",
    "\"___ there, done that\"",
    "Fats Domino's \"I've ___ Around\"",
    "Lived",
    "\"___ here long?\"",
    "\"It's ___ real!\"",
    "\"It's ___ real\"",
    "\"It's ___ real!\"",
    "\"It's ___ real!\"",
    "\"___ here long?\"",
    "\"___ here long?\"",
    "\"I've ___ had!\"",
    "\"___ So Long\" (1958 hit)",
    "\"___ there, done that\"",
    "\"It's ___ real!\"",
    "Existed",
    "Existed",
    "\"It's ___ real\"",
    "Stayed",
    "Existed"
  ],
  "utep": [
    "Sch. on the Rio Grande",
    "The Miners of Conference USA, informally",
    "Sch. whose newspaper is The Prospector (\"Assayer of Student Opinion\")",
    "Lone Star State sch.",
    "Where Miners have majors",
    "Sch. near the Mexican border",
    "Lone Star State sch.",
    "Lone Star State sch.",
    "The Miners of the N.C.A.A.",
    "Sch. whose mascot is Paydirt Pete",
    "U.S. college whose campus is less than 1,000 ft. from Ciudad Juarez",
    "Conference USA sch.",
    "Lone Star State sch.",
    "Lone Star State sch. near the Rio Grande",
    "Sch. whose fight song is \"Miners Fight\"",
    "Lone Star State sch.",
    "Sch. with the mascot Paydirt Pete",
    "Miners' sch.",
    "Lone Star State sch.",
    "Sun Bowl Stadium sch.",
    "Conference USA sch.",
    "Lone Star State sch.",
    "Lone Star State sch.",
    "Sch. located on the Rio Grande",
    "Lone Star State sch.",
    "Sch. on the bank of the Rio Grande",
    "Sch. near the Rio Grande",
    "Lone Star State sch.",
    "Lone Star State sch.",
    "Lone Star State sch.",
    "Lone Star State sch.",
    "Western Athletic Conf. team",
    "The Miners of the Western Athletic Conf.",
    "Lone Star State sch.",
    "Lone Star sch.",
    "Lone Star State sch.",
    "Lone Star State sch.",
    "Western Athletic Conference sch.",
    "The Miners of the N.C.A.A.",
    "Lone Star State sch.",
    "Western Athletic Conf. team",
    "Texas Western, today: Abbr.",
    "Miners' sch."
  ],
  "wild": [
    "Like some parties and flowers",
    "Word repeated before \"West\" in a film and 1960s TV series",
    "Like some Uno cards",
    "See 49-Down",
    "Like jokers, sometimes",
    "Like some pitches",
    "Natural",
    "Like eights in crazy eights",
    "Wacky",
    "Untamed",
    "Uncombed, say",
    "Word repeated before West",
    "Uncontrolled",
    "Feral",
    "Out of control",
    "Unbroken",
    "Like jokers",
    "Junglelike",
    "From the jungle",
    "Like jokers, often",
    "Frantic",
    "Out of control",
    "Like jokers"
  ],
  "biff": [
    "\"Back to the Future\" antagonist",
    "\"Back to the Future\" bully",
    "\"Back to the Future\" antagonist",
    "Son of Willy Loman",
    "\"Back to the Future\" bully",
    "\"Back to the Future\" bully",
    "Son of Willy Loman",
    "\"Back to the Future\" bully",
    "Punch, slangily",
    "Son of Willy Loman"
  ],
  "jose": [
    "Muralist ___ Clemente Orozco",
    "San ___, Calif.",
    "___ Cuervo (tequila brand)",
    "\"No way\" man",
    "San ___ (Silicon Valley city)",
    "\"No way\" man",
    "Tenor Carreras",
    "Canseco who wrote \"Juiced\"",
    "Singer Feliciano",
    "Tenor Cura",
    "Outfielder Guillen",
    "Cuban patriot Marti",
    "Mexican muralist Orozco",
    "\"No way, ___!\"",
    "Dancer Limon",
    "Tenor Carreras",
    "\"No way, ___!\"",
    "Slugger Canseco",
    "\"No way, ___!\"",
    "1920's chess champion Capablanca",
    "Canseco of baseball",
    "San ___, Calif.",
    "San ___, Calif.",
    "Baseball's Canseco"
  ],
  "pooh": [
    "Animated character who wears a red shirt and no pants",
    "Honey-loving bear",
    "\"That's nonsense\"",
    "Winnie-the-___",
    "Hundred Acre Wood resident",
    "*Dismiss lightly",
    "Winnie-the-___",
    "\"Oh, quit being silly!\"",
    "When doubled, dismiss out of hand",
    "Friend of Tigger",
    "\"Oh, baloney!\"",
    "Pouty exclamation",
    "Kids' character who says \"People say nothing is impossible, but I do nothing every day\"",
    "\"Oh, nuts!\"",
    "\"Fiddlesticks!\"",
    "Kids' character who says \"A day without a friend is like a pot without a single drop of honey left inside\"",
    "\"Winnie-the-___\"",
    "Rabbit's friend",
    "Winnie-the-___",
    "\"Tush!\"",
    "Bear of children's lit",
    "Winnie-the-___",
    "___ Bear",
    "Bear who dreams of \"hunny\"",
    "\"What nonsense!\"",
    "Milne's bear",
    "\"Fiddlesticks!\"",
    "Winnie-the-___",
    "Winnie-the-___",
    "Winnie-the-___",
    "\"That's just silly!\"",
    "\"Fiddlesticks!\"",
    "Hundred Acre Wood denizen",
    "Winnie-the-___",
    "Milne bear",
    "Milne's \"The House at ___ Corner\"",
    "Literary bear",
    "Noted honey eater",
    "\"'Tain't nothin'!\"",
    "\"Hunny\" bear",
    "\"Oh, that's silly\"",
    "\"Tush!\"",
    "\"Fiddle-faddle!\"",
    "Pal of Piglet",
    "Milne bear",
    "Cry of contempt",
    "Christopher Robin's pal",
    "\"Fiddlesticks!\"",
    "Milne character",
    "Milne's___Corner",
    "Pal of Piglet",
    "Piglet's friend",
    "Famous corner in literature",
    "Bear of \"very little brain\"",
    "Tigger's friend"
  ],
  "ambi": [
    "Prefix meaning \"both\"",
    "Prefix with sexual",
    "Prefix whose last two letters are a prefix meaning the same thing",
    "Prefix with dexterity",
    "Prefix with dexterity",
    "Both, for openers",
    "Both: Prefix",
    "Prefix with -dextrous",
    "Prefix with -dextrous",
    "Prefix with dextrous",
    "Prefix with valent",
    "Both: Prefix",
    "Both: Prefix",
    "Prefix with dextrous",
    "Prefix with dextrous",
    "Both: Prefix",
    "Both: Prefix",
    "Prefix with dextrous",
    "Flexible prefix",
    "Prefix with syllabic",
    "Both, at the start",
    "Prefix with sexual",
    "Prefix with sexual",
    "Prefix with polar",
    "Opening with dexterity",
    "Prefix with valence",
    "Prefix with dextrous",
    "Prefix with dexterity",
    "Prefix with lateral",
    "Prefix with polar",
    "Prefix with -vert",
    "Prefix with polar",
    "Prefix with polar",
    "Both: Prefix",
    "Two-way prefix",
    "Prefix for both",
    "Prefix with valence",
    "Both: Prefix"
  ],
  "idyl": [
    "Pastoral poem",
    "Pastoral poem",
    "One of many writings by Theocritus",
    "Bit of bucolic verse",
    "Rustic poem",
    "Peaceful scene",
    "Pastoral verse",
    "Description of rustic life",
    "Pastoral verse",
    "Tranquil scene",
    "Pastoral poem",
    "Tennyson work",
    "Bucolic verse",
    "Short pastoral piece",
    "Quaint literary work",
    "Pastoral poem",
    "Bucolic poem",
    "Pastoral poem",
    "Pastoral composition",
    "Poetic work by Tennyson",
    "Tranquil scene",
    "Tranquil scene",
    "Tennyson work",
    "Tennyson work",
    "Quaint poem",
    "Romantic interlude",
    "Old-fashioned poem",
    "Charming scene",
    "Theocritus work",
    "Romantic interlude",
    "Romantic interlude",
    "Pastoral poem",
    "Romantic interlude",
    "Romantic interlude",
    "Pastoral poem",
    "Pastoral poem"
  ],
  "alts": [
    "Mountain map figs.",
    "Secondary social media accounts, in brief",
    "Hgts.",
    "Secondary personas, in role-playing game lingo",
    "Climbing figs.",
    "Hgts.",
    "Plane figs.",
    "Mtn. stats",
    "Cockpit readings: Abbr.",
    "Tree line stats.",
    "Relief map figs.",
    "Hgts.",
    "Flight stats.",
    "Mtn. stats",
    "Hgts.",
    "Fliers' concerns: Abbr.",
    "Computer keys: Abbr.",
    "Mtn. stats",
    "Cockpit data: Abbr.",
    "Rocky Mtn. highs?",
    "Cockpit readings: Abbr."
  ],
  "joys": [
    "Pleasures",
    "Delights",
    "Pleasures",
    "Life's pleasures",
    "Sorrows' opposites",
    "Sources of delight",
    "Delights"
  ],
  "tomb": [
    "Archaeologist's find",
    "___ of the Unknowns",
    "Dead zone?",
    "___ of the Unknowns",
    "The Taj Mahal, e.g.",
    "It's monumental",
    "The Taj Mahal or Great Pyramid",
    "Egyptian pyramid, e.g.",
    "\"Indiana Jones\" setting",
    "Setting for the very end of \"Aida\"",
    "Taj Mahal, e.g.",
    "Dead spot",
    "Egyptian pyramid, e.g.",
    "Sepulcher",
    "Many a monument",
    "Catacomb component",
    "Plot element",
    "Where writing is on the wall?",
    "Archaeologist's discovery",
    "Mummy's place",
    "Final \"Romeo and Juliet\" setting",
    "Dead center?",
    "Indiana Jones venue",
    "Tut's resting place",
    "___ of the Unknowns",
    "Dead center?",
    "Mausoleum",
    "Age-old robbers' target",
    "Mummy's locale",
    "Taj Mahal, e.g.",
    "Grant's is in New York",
    "___ of the Unknowns",
    "___ of Napoleon",
    "Pyramid, maybe",
    "Archaeological site",
    "Dead center?",
    "Dead center?",
    "Archaeological find",
    "Sarcophagus",
    "Archaeological find",
    "Egyptian pyramid, e.g.",
    "Resting place",
    "Mummy's home",
    "Vampire's hideout",
    "Taj Mahal, e.g.",
    "___ of the Unknowns",
    "Site at the end of \"Romeo and Juliet\"",
    "Grave",
    "Pyramid, perhaps",
    "Taj Mahal, e.g.",
    "Taj Mahal, e.g."
  ],
  "abby": [
    "Women's soccer star Wambach",
    "Dear one?",
    "\"Dear\" one",
    "\"Dear ___\" (1960s-'70s radio program)",
    "Pen name of columnist Pauline Phillips",
    "\"Dear\" one",
    "\"Dear\" advice-giver",
    "Women's soccer star Wambach",
    "Apt name for a nun?",
    "\"Dear\" advice columnist",
    "John Prine's \"Dear ___\"",
    "Ann's advice-giving sister",
    "\"Dear ___\"",
    "\"Dear\" columnist",
    "\"Eight Is Enough\" wife",
    "\"Dear\" dispenser of advice",
    "\"Dear\" advice-giver",
    "\"Dear\" one",
    "\"Dear\" one",
    "\"Dear\" advice-giver",
    "\"Dear\" lady",
    "Adviser since 1956",
    "Dear one",
    "Dear one"
  ],
  "lcds": [
    "Components of some calculators, for short",
    "Features of some 11-Down",
    "Some HDTVs",
    "Some TVs",
    "Some PC screens",
    "Some HDTVs",
    "Some Samsung products, for short",
    "Monitor things, briefly",
    "Some digital clock readouts, for short",
    "Some PC screens",
    "Some HDTV screens",
    "Watch things, for short",
    "Some screens, for short",
    "Some HDTV screens",
    "Some notebook screens, for short",
    "Watch things, briefly",
    "Some TV screens",
    "Some monitors, for short",
    "Watch things, for short"
  ],
  "regs": [
    "Rules, informally",
    "Rules' partner, for short",
    "Rules and ___",
    "Handbook info, for short",
    "Rules, for short",
    "Rules, briefly",
    "Rules, informally",
    "Rules, for short",
    "Govt. rules",
    "Rules and ___",
    "Rules, briefly",
    "Rules and ___",
    "Rules and ___",
    "Rules' partners, briefly",
    "Rules, quickly",
    "Govt. rules",
    "Rules, for short",
    "Rules, for short",
    "Laws, informally",
    "Rules, quickly",
    "Rules, for short",
    "Rules, shortly",
    "Brief bylaws",
    "Rules and ___",
    "Partner of rules, slangily",
    "Rules, informally"
  ],
  "cora": [
    "Celebrity chef Cat ___",
    "___ Crawley, countess on \"Downton Abbey\"",
    "Mr. Dithers's wife in \"Blondie\"",
    "\"The Last of the Mohicans\" daughter",
    "\"Downton Abbey\" countess",
    "\"Downton Abbey\" countess",
    "___ Crawley, countess on \"Downton Abbey\"",
    "Langston Hughes's \"___ Unashamed\"",
    "\"The Last of the Mohicans\" girl",
    "Mrs. Dithers of \"Blondie\"",
    "Julius Dithers's wife in \"Blondie\"",
    "Mrs. Dithers of the comics",
    "Mrs. Dithers in \"Blondie\"",
    "Mrs. Dithers of \"Blondie\"",
    "Mrs. Dithers in the comics",
    "Mrs. Dithers of \"Blondie\"",
    "Mrs. Dithers in \"Blondie\"",
    "\"The Last of the Mohicans\" girl",
    "Mrs. Dithers in \"Blondie\"",
    "\"The Last of the Mohicans\" woman",
    "Mrs. Dithers of the comics",
    "Mrs. Dithers of \"Blondie\"",
    "Mrs. Dithers in \"Blondie\"",
    "Mrs. Dithers, in ?ôBlondie?ö",
    "Mrs. Dithers",
    "Mrs. Dithers in \"Blondie\"",
    "Mrs. Dithers, in \"Blondie\"",
    "Mrs. Dithers",
    "Langston Hughes's \"___ Unashamed\"",
    "Mrs. Dithers of the comics",
    "\"The Last of the Mohicans\" girl",
    "Mrs. Dithers, in the comics",
    "\"The Postman Always Rings Twice\" wife",
    "\"The Last of the Mohicans\" girl",
    "\"Blondie\" character",
    "Infielder Joey ___",
    "\"The Last of the Mohicans\" girl",
    "Mrs. Dithers"
  ],
  "rues": [
    "Wishes undone",
    "They intersect in Montreal",
    "Bemoans",
    "Is sorry about",
    "Paris network",
    "Regrets",
    "French city grid",
    "French network",
    "Feels remorse over",
    "Laments",
    "Laments",
    "Regrets",
    "Regrets",
    "French urban network",
    "Regrets",
    "Sighs over, say",
    "Ones coming from the Arc de Triomphe?",
    "Ways of Charles de Gaulle?",
    "Streets of Quebec",
    "They intersect in Montreal",
    "Wishes undone",
    "Wishes undone",
    "Laments",
    "Parisian streets",
    "Regrets",
    "Regrets",
    "Wishes undone",
    "Has regrets",
    "Wishes undone",
    "Wishes undone",
    "Is repentant",
    "Paris gridwork",
    "Is penitent",
    "Paris grid",
    "They crisscross Paris",
    "Regrets",
    "Plan de Paris lines",
    "Regrets",
    "Has second thoughts about",
    "Regrets",
    "Is contrite",
    "Regrets",
    "Ways of Paris",
    "Has misgivings about"
  ],
  "exec": [
    "V.P., e.g.",
    "Suit",
    "Person in a boardroom, informally",
    "Higher-up",
    "Higher-up",
    "Board appointment, for short",
    "Suit",
    "Prez or veep",
    "Biz bigwig",
    "Prez, e.g.",
    "One high up on the corporate ladder, informally",
    "\"Suit\"",
    "C.F.O., e.g.",
    "Suit",
    "___ asst.",
    "Fortune reader, maybe",
    "Boardroom fig.",
    "Suit",
    "Suit",
    "Suit",
    "Corner office type",
    "Business suit",
    "C.O.O., e.g.",
    "Org chart topper",
    "Higher-up",
    "High-up in a corp.",
    "Corp. higher-up",
    "Suit",
    "Director-type",
    "Partner of legis. and jud.",
    "Judiciary checker: Abbr.",
    "Not jud. or leg.",
    "One often going by limo",
    "Big cheese",
    "Suit",
    "Business V.I.P.",
    "Acct. ___",
    "Board member",
    "Prez, e.g.",
    "Prez or veep",
    "Co. figure",
    "Company V.I.P.",
    "One hired by a corp. board",
    "One making firm decisions",
    "Co. bigwig",
    "Many a Barron's reader, for short",
    "Not legis. or jud.",
    "Prez, e.g.",
    "Suit",
    "Suit",
    "Company bigwig",
    "One who might have a prime corner office",
    "Brass figure?",
    "Business V.I.P.",
    "Co. honcho",
    "Board member, for short",
    "One of the higher-ups",
    "Office honcho",
    "Firm head",
    "Company V.I.P.",
    "Inc. reader, perhaps",
    "Firm head",
    "Suit",
    "Pres. or treas.",
    "Corp. kingpin",
    "Wharton grad, maybe",
    "Business biggie",
    "Corp. bigwig",
    "Prez",
    "Prez",
    "Company hotshot",
    "Board member, for short",
    "Big cheese",
    "Prez",
    "Big cheese",
    "Board member",
    "Corporate hotshot",
    "Barron's reader",
    "Suit, so to speak",
    "Top dog",
    "Bigwig",
    "Corporate chief",
    "Administrator, briefly",
    "Head honcho"
  ],
  "cyst": [
    "Dermatologist's concern",
    "Dermatologist's concern",
    "Nonvital surgery target",
    "Anatomical sac",
    "Dermatological concern",
    "Skin abnormality",
    "Dermatological sac",
    "Skin abnormality",
    "Dermatological problem",
    "Dermatologist's concern",
    "Growth under the skin",
    "Anatomical sac",
    "Skin abnormality",
    "Dermatological sac",
    "Dermatologist's concern",
    "Anatomical sac",
    "Dermatologist's concern",
    "Dermatologist's concern",
    "Dermatologist's concern",
    "Dermatologist's concern",
    "Target of some surgery",
    "Anatomical sac",
    "Dermatologist's concern",
    "Skin problem",
    "Dermatologist's diagnosis",
    "Dermatologist's concern",
    "Abnormal vesicle",
    "Closed sac",
    "Closed sac",
    "Skin abnormality",
    "Dermatologist's diagnosis"
  ],
  "whys": [
    "Reasons",
    "\"___ that?\"",
    "Reasons",
    "___ and wherefores",
    "Tot's repeated questions",
    "Explanations",
    "Reasons",
    "Reasons",
    "___ and wherefores",
    "Reasons",
    "Kids' questions",
    "Reasons",
    "Partner of wherefores",
    "Explanations",
    "Reasons",
    "Reasons",
    "Philosophers' questions",
    "Kids' questions"
  ],
  "tris": [
    "Push-up targets, for short",
    "Speaker in major-league baseball history",
    "Speaker in the Baseball Hall of Fame",
    "Speaker of baseball",
    "Speaker of baseball",
    "Baseball Hall-of-Famer Speaker",
    "Speaker in the Hall of Fame",
    "Speaker of the diamond",
    "Speaker in the Hall of Fame",
    "Speaker in old baseball",
    "Baseball great ___ Speaker",
    "Baseball Hall-of-Famer Speaker",
    "Speaker of note",
    "Speaker in the outfield",
    "Speaker on the diamond",
    "Speaker in the Baseball Hall of Fame",
    "Speaker of baseball",
    "Speaker of note",
    "Speaker from Cleveland",
    "Speaker of baseball",
    "Cooperstown nickname",
    "Speaker in the field",
    "Speaker of the Tribe",
    "Cooperstown nickname",
    "Speaker of Cooperstown",
    "Speaker of note",
    "Speaker of note",
    "Hall-of-Famer Speaker",
    "Speaker of baseball fame",
    "Speaker of baseball",
    "Actor Coffin of early TV",
    "Baseball's Speaker",
    "Cleveland's Speaker",
    "Speaker of baseball",
    "Speaker of fame",
    "Speaker at Cooperstown"
  ],
  "pecs": [
    "They can be found just above a six-pack",
    "Nice pair of boxers?",
    "Bench targets, for short",
    "Chest muscles, informally",
    "Bustline muscles, informally",
    "Body parts that sound like some units of measure",
    "Targets on \"chest day\"",
    "Muscle shirt wearer's pride",
    "Bodybuilder's pride",
    "Bench presser's pride, informally",
    "Bench presses build them, for short",
    "Chest muscles, for short",
    "Torso muscles, briefly",
    "Muscles worked by bench presses",
    "They can be found next to six-packs",
    "Muscles above the abs",
    "They're between delts and abs",
    "Weightlifter's pride",
    "They're above abs",
    "What bench presses enhance",
    "Prominent beefcake features",
    "Neighbors of 9-Down",
    "Short chest, for muscles",
    "Bench presser's pride",
    "Chest muscles, for short",
    "They're found around six-packs",
    "Chest muscles, briefly",
    "They're big among bodybuilders",
    "They ripple on bodybuilders",
    "They're above the abs",
    "Chest muscles, briefly",
    "Muscles for bench pressers",
    "Iron pumpers develop them",
    "Chest muscles, briefly",
    "Push-ups strengthen them",
    "They're developed by rowing",
    "Bodybuilders' targets",
    "Bodybuilders build them",
    "Iron-pumper's pride"
  ],
  "yalu": [
    "China/North Korea border river",
    "Asian river whose name is one letter away from an Ivy League college",
    "River to Korea Bay",
    "River to Korea Bay",
    "River to Korea Bay",
    "Korea Bay feeder",
    "Korea Bay feeder",
    "Korea Bay feeder",
    "Korea Bay feeder",
    "Korean border river",
    "China/Korea border river",
    "River into Korea Bay",
    "Manchurian border river",
    "Korea Bay feeder",
    "Manchurian border river",
    "River crossed in the Korean War",
    "Sup'ung Dam site",
    "Asian border river",
    "Manchurian border river"
  ],
  "robe": [
    "Legal cover-up?",
    "Cover-up in court",
    "Gear for the bench",
    "Justice's garb",
    "Legal cover?",
    "Courtroom coverage?",
    "Spa amenity",
    "Garment worn by John Roberts that's hidden in his name",
    "Attire for a Druid",
    "Spa wear",
    "Trial cover-up",
    "Justice's garment",
    "Spa amenity",
    "Item of loungewear",
    "Spa wear",
    "Attire for one lounging at a 58-Down",
    "It's a wrap",
    "Wizard's wear",
    "Cloth for a man of the cloth?",
    "Sauna garment",
    "Kimono, e.g.",
    "Spa wear",
    "Luxury hotel amenity",
    "Judge's garment",
    "Cabana wear",
    "\"Judge Judy\" coverage?",
    "Wizard's garment",
    "Judge's wear",
    "Judge's attire",
    "Belted attire",
    "Clothing court",
    "Boxer's name holder",
    "After-beach wear",
    "Something to put on before trying?",
    "Angel's garb",
    "Luxury hotel amenity",
    "Boxer's prefight attire",
    "Bench wear",
    "Cover-up",
    "It may be put on after a bath",
    "Boxer's attire",
    "Cover-up",
    "Hotel amenity",
    "Legal cover-up?",
    "Spa wear",
    "Boxer's wear",
    "Justice's attire",
    "Choir attire",
    "Judicial garb",
    "Vesture",
    "Day spa attire",
    "Choir garb",
    "Boxer's attire",
    "After-bath wear",
    "Seer's garb",
    "Dignified attire",
    "Morning garment",
    "Choir attire",
    "Justice's garb",
    "Courtroom cover-up",
    "After-bath wear",
    "It's a cover-up",
    "Judge's apparel",
    "Boxer's wear",
    "Judge's wear",
    "After-bath cover",
    "It's a cover-up",
    "Clarence Thomas's garb",
    "Biblical attire",
    "Caftan",
    "Boxer's garb",
    "Boxer's garb",
    "Caftan",
    "At-home cover-up",
    "Ring wear",
    "Jesus' attire",
    "After-shower wear",
    "Moses' attire",
    "Judicial cover?",
    "Kimono",
    "First film in CinemaScope, with \"The\""
  ],
  "slay": [
    "Dispatch, in a way",
    "Leave rolling in the aisles",
    "Look extremely stylish, slangily",
    "Kill, as a dragon",
    "Kill it",
    "Leave rolling in the aisles",
    "Rub out",
    "Knock dead at the comedy club",
    "What good comics do",
    "Knock 'em dead",
    "Leave rolling in the aisles",
    "Kill",
    "Leave in stitches",
    "Crack up",
    "Do in, so to speak",
    "Leave rolling in the aisles",
    "Leave rolling in the aisles",
    "Cause to roll in the aisles",
    "Knock off",
    "Dispatch",
    "Leave in stitches",
    "Off",
    "Put in stitches",
    "Crack up",
    "Kill",
    "Murder",
    "Put in stitches",
    "Whack",
    "Smite",
    "Overwhelm with humor",
    "Overwhelm, at a comedy club",
    "Fell",
    "Do in",
    "Do in",
    "Do in",
    "Do in",
    "Knock 'em dead",
    "Make heads roll?",
    "Have rolling in the aisles",
    "Do in",
    "Delight, slangily",
    "Bump off",
    "Cut down",
    "More than tickle",
    "Put in stitches",
    "Do in",
    "Do in, as a dragon",
    "Knock off",
    "Leave helpless with laughter",
    "Send rolling in the aisles",
    "Kill, as a dragon",
    "Do in",
    "Leave laughing",
    "Do in",
    "Off, so to speak",
    "Wow",
    "Do in",
    "Leave rolling in the aisles",
    "Murder",
    "Murder",
    "Overwhelm",
    "Dispatch",
    "Kill, as a dragon",
    "Assassinate"
  ],
  "yarn": [
    "Knitter's need",
    "Kitten's plaything",
    "Ball that might attract a cat",
    "Zany anecdote",
    "Tall tale",
    "Output of a spinning jenny",
    "It's a long story",
    "Knitting material",
    "Story you can hardly believe",
    "Soft ball?",
    "Soft ball?",
    "What a knitter might have a ball with?",
    "Sailor's tale",
    "Fisherman's tale",
    "Story that can't completely be believed",
    "Knitter's ball",
    "Tale",
    "Fisherman's relation?",
    "Ball material for a cat",
    "Kitten's plaything",
    "Ball material",
    "Adventurers' tale",
    "A knitter might have a ball with it",
    "Ball in a basket",
    "Paul Bunyan story",
    "It's sold in skeins",
    "It's hard to believe",
    "Tale",
    "Ball in a sewing room",
    "Ball in a basket, maybe",
    "Afghan makeup",
    "Ball in a basket",
    "Tall story",
    "Ball material",
    "Shaggy-dog story",
    "Folksy tale",
    "Knitting ball",
    "It's spun",
    "Tall tale",
    "Tall tale",
    "Tall tale",
    "Fish story",
    "Kitten's plaything",
    "Tale",
    "Looming choice",
    "Kitten's plaything",
    "Play material for a kitten",
    "Tar's tale",
    "Grandfather's relation?",
    "It's a long story",
    "\"The one that got away\"",
    "Fabrication",
    "Kitten's plaything",
    "\"The one that got away,\" say",
    "Shaggy-dog story",
    "Sewing shop purchase",
    "Tall one",
    "It comes in balls"
  ],
  "roeg": [
    "Director Nicolas",
    "Nicolas who directed \"The Man Who Fell to Earth\"",
    "Film director Nicolas",
    "\"Castaway\" director Nicolas",
    "\"Walkabout\" director Nicolas",
    "\"The Witches\" director Nicolas",
    "Nicolas who directed \"The Man Who Fell to Earth\"",
    "\"Castaway\" director, 1986",
    "Director Nicolas",
    "Filmmaker Nicolas",
    "Director Nicolas",
    "Director of \"The Witches,\" 1990",
    "\"Don't Look Now\" diretcor",
    "Director Nicolas",
    "Nicolas who directed \"The Man Who Fell to Earth\"",
    "Nicolas who directed \"The Man Who Fell to Earth\"",
    "\"Walkabout\" director Nicolas",
    "\"The Witches\" director Nicolas",
    "\"The Man Who Fell to Earth\" director",
    "\"The Witches\" director Nicolas",
    "\"The Witches\" director Nicolas",
    "Nicolas who directed \"The Man Who Fell to Earth\"",
    "Film director Nicolas",
    "Filmmaker Nicolas",
    "Film director Nicolas",
    "Film director Nicolas"
  ],
  "garp": [
    "Title character of a John Irving novel",
    "John Irving title character",
    "John Irving title hero",
    "Irving protagonist",
    "Robin Williams title role",
    "T. S. of literature",
    "Fictional writer in a John Irving best seller",
    "\"The World According to ___\"",
    "T. S. of literature",
    "\"The World According to ___\"",
    "John Irving title character",
    "John Irving title character",
    "\"The World According to ___\"",
    "Title film role for Robin Williams",
    "Robin Williams title role",
    "\"The World According to ___\"",
    "\"The World According to ___\"",
    "Robin Williams film role",
    "John Irving hero",
    "Irving hero",
    "\"The World According to ___\"",
    "1982 Robin Williams title role",
    "John Irving character T. S. ___",
    "Title hero in Glenn Close's first major film",
    "Irving hero",
    "\"The World According to ___\"",
    "Irving protagonist",
    "\"The World According to ___\"",
    "1978 Irving character",
    "Title role for Robin Williams",
    "John Irving title character",
    "Irving hero"
  ],
  "marc": [
    "Podcaster Maron",
    "Podcast host Maron",
    "Painter Chagall",
    "Podcaster Maron",
    "Designer Jacobs",
    "Singer Anthony",
    "Podcaster Maron",
    "Artist Chagall",
    "Designer Jacobs",
    "Artist Chagall or N.B.A. player Gasol",
    "Podcast host Maron",
    "Designer Jacobs",
    "Painter Chagall",
    "Podcaster Maron",
    "Fashion designer Ecko",
    "Comedian Maron",
    "Singer Anthony",
    "Painter Chagall",
    "\"WTF With ___ Maron\" (popular podcast)",
    "Fashion designer Jacobs",
    "Artist Chagall",
    "Singer Anthony",
    "Fashion designer Jacobs",
    "Painter Chagall",
    "Painter Chagall",
    "Singer Anthony",
    "Painter Chagall",
    "Jacobs of fashion",
    "Painter Chagall",
    "___ Cohn, 1991 Grammy winner for Best New Artist",
    "Playwright Connelly",
    "Singer ___ Anthony",
    "Linguist Okrand who created the Klingon language",
    "Painter Chagall",
    "Artist Chagall",
    "Playwright Connelly who won a Pulitzer for \"The Green Pastures\"",
    "French film director Allegret",
    "Artist Chagall",
    "Artist Chagall",
    "Latin singer Anthony",
    "Artist Chagall",
    "Artist Chagall",
    "1991 Grammy winner Cohn",
    "Artist Chagall",
    "Painter Chagall",
    "Playwright Connelly",
    "Painter Chagall",
    "___ Antony",
    "Actor Singer",
    "Artist Chagall",
    "Grammy winner Cohn",
    "Artist Chagall",
    "Pulitzer winner Connelly",
    "Painter Chagall",
    "Artist Chagall",
    "Painter Chagall",
    "Film director Allegret",
    "Singer Almond or actor Singer",
    "Playwright Connelly",
    "Grammy winner Cohn",
    "Dramatist Connelly",
    "Artist Chagall",
    "Cleopatra's love ___ Antony",
    "Grape brandy",
    "Antony of antiquity",
    "Cleopatra's love ___ Antony",
    "Pulitzer dramatist Connelly",
    "Dramatist Connelly",
    "French brandy",
    "German Expressionist Franz ___",
    "___ Antony",
    "___ Antony",
    "Artist Chagall",
    "Playwright Connelly"
  ],
  "garr": [
    "Teri of \"Tootsie\"",
    "Teri of \"Tootsie\"",
    "Teri with a \"Tootsie\" role",
    "\"One From the Heart\" actress, 1982",
    "\"Young Frankenstein\" co-star",
    "Teri with a big \"Tootsie\" role",
    "Teri of \"Young Frankenstein\"",
    "Teri who played Phoebe's mother on \"Friends\"",
    "Teri ___, Best Supporting Actress nominee for \"Tootsie\"",
    "Teri of \"Tootsie\"",
    "\"Oh, God!\" actress",
    "Teri of \"Tootsie\"",
    "\"Oh, God!\" co-star",
    "Teri of \"Tootsie\"",
    "Ralph who was the 1974 N.L. batting champ",
    "\"Dumb and Dumber\" actress",
    "\"Tootsie\" Oscar nominee",
    "Teri of \"Tootsie\"",
    "Teri of \"Close Encounters of the Third Kind\"",
    "Ralph ___, 1974 N.L. batting champ",
    "Teri of \"Young Frankenstein\"",
    "\"Mr. Mom\" co-star",
    "She had a \"Tootsie\" role",
    "Actress Teri",
    "Keaton's \"Mr. Mom\" co-star",
    "\"Tootsie\" co-star",
    "Actress Teri",
    "\"Young Frankenstein\" actress",
    "Teri of \"Dumb & Dumber\"",
    "See 24-Across",
    "\"Dumb & Dumber\" actress",
    "Actress who played Tootsie's tootsie",
    "Actress with a \"Tootsie\" role",
    "Teri of \"Tootsie\"",
    "Actress Teri",
    "1974 N.L. batting champ Ralph",
    "Actress Teri",
    "\"Mr. Mom\" co-star, 1983",
    "Teri of \"Tootsie\""
  ],
  "onup": [
    "\"Movin' ___\"",
    "\"Movin' ___\" (\"The Jeffersons\" theme)",
    "\"Movin' ___\"",
    "\"Movin' ___\" (\"The Jeffersons\" theme)",
    "\"Get ___\" (1967 hit for the Esquires)",
    "\"Movin' ___\"",
    "Curtis Mayfield's \"Move ___\"",
    "\"Movin' ___\"",
    "\"Movin' ___\" (TV theme song)",
    "\"Get ___,\" 1967 hit for the Esquires",
    "\"Movin' ___\" (\"The Jeffersons\" theme)",
    "\"Get ___\" (1967 hit for the Esquires)",
    "\"Movin' ___\" (\"The Jeffersons\" theme song)",
    "\"Movin' ___\"",
    "And higher, in cost",
    "\"Movin' ___\" (\"The Jeffersons\" theme)",
    "\"Get ___\" (1967 Esquires hit)"
  ],
  "twee": [
    "Too cute, on the Thames",
    "Precious, to a Brit",
    "Affectedly quaint",
    "___ pop (music genre featuring simple, catchy melodies)",
    "So, so cute",
    "Overly precious",
    "Too cute, in Cambridge",
    "Cutesy, in London",
    "___ pop, music genre since the 1980s",
    "Precious, to a Brit",
    "Affectedly dainty, in Derby",
    "Oh-so-quaint, in England",
    "Affectedly dainty, in England",
    "Affectedly quaint, in England",
    "Oh-so-dainty",
    "Overly precious, to a Briton"
  ],
  "saga": [
    "Story that goes on and on ... and on",
    "\"The Avengers\" franchise, e.g.",
    "It's a long story",
    "Quite a tale",
    "Multigenerational story",
    "Long story",
    "Major account",
    "Quite a tale",
    "Epic narrative",
    "The \"Odyssey,\" for one",
    "It's a long story",
    "Frank Herbert's \"Dune\" series, e.g.",
    "Long tale",
    "Long transmission of folklore, say",
    "Yarn that stretches?",
    "\"Game of Thrones,\" e.g.",
    "Family history, e.g.",
    "Story with many parts",
    "It's a long story",
    "Lengthy narrative",
    "Multigenerational tale",
    "\"Iliad,\" e.g.",
    "Story with many chapters",
    "Epic tale",
    "Many a Viking tale",
    "Story with many chapters",
    "Major account",
    "Family history, e.g.",
    "Novel format",
    "Story with many chapters",
    "\"Game of Thrones,\" e.g.",
    "\"Twilight,\" e.g.",
    "Multigenerational story",
    "Long, involved story",
    "Potential serial material",
    "\"Star Wars,\" e.g.",
    "Story that goes on and on",
    "It's a long story",
    "Tale written in runes, perhaps",
    "It's a long story",
    "Continuing drama",
    "\"The Lord of the Rings,\" e.g.",
    "Epic tale",
    "It takes a while to tell",
    "\"Twilight,\" e.g.",
    "Chronicle",
    "\"Star Wars,\" e.g.",
    "Era-spanning story",
    "\"Roots,\" for one",
    "Heroic legend",
    "Overdrawn account?",
    "Family history, e.g.",
    "It's a long story",
    "D. H. Lawrence's \"The Rainbow,\" e.g.",
    "It's a long story",
    "Siegfried and Sigmund's story, e.g.",
    "Drawn-out drama",
    "Tale",
    "It takes quite a while to tell",
    "It's a long story",
    "Account receivable?",
    "The \"Star Wars\" trilogy, for one",
    "Storyteller's challenge",
    "M. M. Kaye's \"The Far Pavilions,\" e.g.",
    "Big job for a teller",
    "Story that goes on and on",
    "\"Roots,\" for one",
    "Long story",
    "Multigenerational story",
    "It's a long story",
    "Detailed account",
    "Long story",
    "It's hard to tell",
    "It's a long story",
    "Tale of Vikings, perhaps",
    "It's a long story",
    "Roman-fleuve",
    "It's a long story",
    "Sweeping story",
    "Adventure",
    "It's hard to tell",
    "It's related to others",
    "\"Little House on the Prairie,\" e.g.",
    "Endless adventure",
    "Roman-fleuve",
    "Not a short story",
    "Thousand-plus-pager",
    "Long story",
    "Long story",
    "Quite a tale",
    "Michener tale, e.g.",
    "\"Roots,\" e.g.",
    "Chronicle",
    "Galsworthy genre",
    "It's a long story",
    "Long, drawn-out story",
    "It's a long story",
    "Many a Michener novel",
    "\"Beowulf,\" e.g.",
    "Michener genre",
    "An old story",
    "Michener's \"Hawaii,\" e.g.",
    "Tale of derring-do, e.g.",
    "It's a long story",
    "\"Volsunga ___\"",
    "Hero's tale",
    "It's a long story",
    "Heroic narrative",
    "Long account",
    "\"The Forsyte ___\"",
    "\"Lonesome Dove\" genre"
  ],
  "band": [
    "Green Day or Black Sabbath",
    "Extracurricular activity for a musician",
    "Hot Chocolate or Vanilla Fudge",
    "Wedding need ... or booking",
    "The Rolling Stones or Smashing Pumpkins",
    "Wedding expense",
    "The Eagles or the Byrds",
    "Wedding feature, in two different senses",
    "Posse, e.g.",
    "Tour group?",
    "Wedding staple",
    "Ring",
    "AM or FM specification",
    "Word after string or rubber",
    "See 35-Across",
    "Unadorned ring",
    "Halftime crew",
    "Wedding reception staple",
    "The Beatles, e.g.",
    "Company",
    "The Beatles or the Stones",
    "Wedding reception staple",
    "Wedding staple",
    "The Merry Men, e.g.",
    "Singer's backup",
    "Group at school athletic events",
    "Wedding entertainers",
    "Ring",
    "Boston or Chicago, e.g.",
    "Join",
    "Range of frequencies",
    "Ring",
    "Parade feature",
    "Troop",
    "Combo"
  ],
  "pogo": [
    "Comic strip that's the source of the line \"We have met the enemy and he is us\"",
    "___ stick",
    "___ stick",
    "Comic strip opossum",
    "___ stick",
    "\"We have met the enemy and he is us\" comic strip",
    "*Bounce on a stick",
    "Comics character who says \"Having lost sight of our objectives, we redoubled our efforts\"",
    "Bounce along, in a way",
    "\"We have met the enemy and he is us\" comic strip",
    "___ stick",
    "Okefenokee possum",
    "___ stick",
    "\"We have met the enemy and he is us\" speaker",
    "Cartoon opossum",
    "___ stick",
    "Cartoon possum",
    "Kind of stick",
    "Old toy trademark",
    "Comic strip character who said \"I refuse to have a battle of wits with an unarmed person\"",
    "Kelly's possum",
    "Classic Walt Kelly strip",
    "Walt Kelly creation",
    "Comics possum",
    "Okefenokee resident",
    "Okefenokee resident",
    "Kind of stick"
  ],
  "aunt": [
    "Rosemary Clooney, to George Clooney",
    "Em, to Dorothy, in \"The Wizard of Oz\"",
    "Uncle's wife",
    "Ena in \"Bambi,\" e.g.",
    "Woman with a 32-Down",
    "Bee or Em",
    "Uncle's wife",
    "___ Jemima",
    "Title for Princess Anne beginning in 1982",
    "Family title with two pronunciations",
    "One who cries \"Uncle!\"?",
    "May or Bee",
    "Not-so-distant relative",
    "May or Polly of fiction",
    "\"Please excuse my dear ___ Sally\" (math mnemonic)",
    "___ Viv, caretaker of the Fresh Prince of Bel-Air",
    "111-Across's partner",
    "___ Jemima",
    "You may have a great one in your family",
    "Hestia, to Artemis",
    "Either Abby or Martha in \"Arsenic and Old Lace\"",
    "Dahlia in Wodehouse novels, e.g.",
    "P. G. Wodehouse's ___ Agatha",
    "Petunia Dursley, to Harry Potter",
    "___ May, surrogate mother for Spider-Man",
    "Polly of literature, e.g.",
    "Unpaid sitter, perhaps",
    "Family member",
    "Chloe in \"Uncle Tom's Cabin,\" e.g.",
    "Uncle's partner",
    "See 39-Down",
    "Spider-Man's ___ May",
    "Volunteer baby sitter, sometimes",
    "One slightly higher in a tree",
    "Volunteer babysitter, maybe",
    "May in ?ôSpider-Man,?ö for one",
    "Eller of \"Oklahoma!,\" for one",
    "Polly, to Tom",
    "Uncle's partner",
    "Vargas Llosa novel \"___ Julia and the Scriptwriter\"",
    "Jemima, e.g.",
    "___ Sally (old throwing game)",
    "Jemima, e.g.",
    "Bee or Em",
    "Family member",
    "Em, to Dorothy",
    "Polly, to Tom",
    "Em, to Dorothy",
    "Godmother, sometimes",
    "___ Jemima",
    "Great-___",
    "12-Down partner",
    "Cousin's mother",
    "Bluebelle, e.g., in old TV ads",
    "Rhody, in an old song",
    "Clara, e.g., on \"Bewitched\"",
    "Bee or Em",
    "Reuniongoer",
    "Jemima, e.g.",
    "West End classic \"Charley's ___\"",
    "Jemima, e.g.",
    "Jemima, for one",
    "Polly, to Tom Sawyer",
    "Reuniongoer",
    "Alcott's Jo, for one",
    "Fritzi, to Nancy",
    "\"Go Tell ___ Rhody\"",
    "Mayberry's Bee Taylor, e.g.",
    "Greene's \"Travels With My ___\"",
    "Em or Polly",
    "Polly, to Tom Sawyer",
    "Genealogy figure",
    "Polly, to Tom",
    "Ethel, to John Jr.",
    "Fritzi Ritz, to Nancy",
    "Family member",
    "Godmother, often"
  ],
  "doze": [
    "Go out briefly?",
    "Catch 40 winks",
    "Nod off",
    "Go out for a bit",
    "Not pay attention during a lecture, say",
    "Get some quick rest",
    "Go out for a while?",
    "Nod off",
    "Nap",
    "Nod off",
    "Nod off",
    "Show inattention, say",
    "Nap",
    "Go out for a short time?",
    "Catch some Z's",
    "Catch some Z's",
    "What you might do after retiring",
    "Snooze",
    "Catch some Z's",
    "Nod off",
    "Not pay attention",
    "Not be alert",
    "Siesta",
    "Short time out?",
    "Catch a few Z's",
    "Take a nap",
    "Drift (off)",
    "Spend some time out?",
    "Not be alert",
    "Drift off",
    "Drop off",
    "Not be alert",
    "Drop off",
    "Nod off",
    "Catch some Z's",
    "Not be alert",
    "Drop off",
    "Nap",
    "Nod off",
    "Nod",
    "Catch some Z's",
    "Nap",
    "Nod off",
    "Take five",
    "Catch some Z's",
    "Nap"
  ],
  "army": [
    "One side in a college football rivalry since 1890",
    "General assembly?",
    "The Black Knights of college football",
    "West Point team",
    "Fighting unit",
    "College football rival of 104-Across",
    "Force on the ground",
    "Group that 5-Downs",
    "Host",
    "With 65-Across, fierce marcher",
    "Fighting force",
    "One side of a classic college football rivalry",
    "It \"marches on its stomach,\" per Napoleon",
    "Troop group",
    "See 1-Across",
    "West Point team",
    "Red ___",
    "___ ant",
    "With 40-Across, visitor on high-school career day",
    "Football rival of Rutgers",
    "Navy's gridiron rival",
    "Ant horde",
    "Host",
    "Camp group",
    "Team nicknamed the Black Knights",
    "Military force",
    "Legion",
    "\"Be All You Can Be\" group",
    "Major employer",
    "Mule team?",
    "Private group",
    "___ brat",
    "West Point team",
    "Host",
    "Drillers' group?",
    "___ brat",
    "The Cadets, in college sports",
    "It may go into action",
    "Group of companies",
    "Host",
    "Black Knights of college sports",
    "With 34-Down, soldier's son, say",
    "Black Knights of college football",
    "With 27-Across, Fort Lee, e.g.",
    "Kind of unit",
    "Word with Red or ant",
    "General assembly?",
    "Host",
    "Host",
    "Team from West Point",
    "General's command",
    "Host",
    "Host",
    "Palmer's gallery",
    "Land force",
    "\"Go, ___!\"",
    "Host"
  ],
  "leds": [
    "Energy-efficient illumination sources, for short",
    "Watch readouts, for short",
    "Watch readouts, briefly",
    "Display options, briefly",
    "Some indicator lamps, briefly",
    "Some readouts, briefly",
    "Watch readouts, for short",
    "They're often seen on scoreboards, for short",
    "Digital-display types",
    "Watch readouts, briefly",
    "Digital displays",
    "Digital displays"
  ],
  "rebs": [
    "Yanks' opponents in the Civil War",
    "Yanks' foes",
    "Yanks' foes",
    "Lee side?",
    "Bull Run victors",
    "Winners at the Battle of Chickasaw Bayou, for short",
    "Lee side, informally",
    "Yanks' Civil War foe",
    "Gray figures?",
    "Gen. Beauregard's men",
    "Civil War fighters",
    "12-Down soldiers, for short",
    "Manassas fighters",
    "One side in the Battle of Cold Harbor",
    "Revolting bunch",
    "Lee side",
    "Confederate soldiers, for short",
    "Gray side",
    "Fighters for Jeff Davis",
    "Lee's men",
    "Lee's men, for short",
    "Revolting ones",
    "Robert E. Lee's men",
    "Side in gray",
    "Some Antietam combatants",
    "Union strikers",
    "Soldiers in gray",
    "Civil War side",
    "Gen. Lee's troops",
    "Graycoats",
    "Civil War soldiers",
    "Graycoats",
    "Graycoats",
    "Yankee foes",
    "Losers at Vicksburg",
    "The Gray, informally",
    "Yankees' counterparts",
    "Soldiers for old Dixie",
    "Confederates",
    "Graycoats",
    "1860's Southerners",
    "Johnny ___ (soldiers)"
  ],
  "drop": [
    "Plummet",
    "Release, as a song, in modern lingo",
    "Release, as a single",
    "What prices do in bear markets",
    "Stop discussing",
    "Release, as a new album",
    "Word before \"a hint,\" \"a line\" or \"the ball\"",
    "Stop bringing up",
    "Tiniest sip",
    "Teensy amount",
    "Plunge",
    "Nosedive",
    "Lose, as a carrier might with a call",
    "Tiny bit",
    "Lozenge",
    "Exhibit extreme exhaustion",
    "Bit of rain",
    "One-third of a fire safety instruction",
    "Release to the public, informally",
    "Tiniest taste",
    "Wee bit",
    "Plummet",
    "Barely enough to wet one's whistle",
    "Plunge",
    "Bit of a trickle",
    "Plummet",
    "See 47-Down",
    "Plunge",
    "Sell-off, say",
    "Go south, as a stock market",
    "No longer insure",
    "Mention casually",
    "Tiny amount",
    "Fall",
    "Nosedive",
    "Pendant",
    "Plunge",
    "Withdraw from, as a case",
    "Trapdoor",
    "Roller coaster feature",
    "No longer carry",
    "Go south, as a market",
    "Lozenge",
    "Let go",
    "Globule",
    "Parachuting event",
    "Deliver by chute",
    "Plummet",
    "Discontinue",
    "Express casually",
    "Discontinue",
    "Spy's spot",
    "Steep slope",
    "Smith Brothers unit",
    "Plummet",
    "Let go of",
    "Word with anchor or dead",
    "Let go of",
    "Tiniest bit",
    "Discontinue",
    "Plummet",
    "Small amount",
    "Downslide",
    "Word after ear or tear",
    "Send by parachute",
    "Blackmailer's meeting point",
    "Trapdoor"
  ],
  "knob": [
    "Door part",
    "Bygone TV feature",
    "Etch A Sketch part",
    "Door handle",
    "Pinch : salt :: ___ : butter",
    "Gearshift part",
    "Opening part",
    "Person's head, in slang",
    "Radio dial",
    "Opening piece",
    "Radio tuner",
    "Old radio or TV part",
    "Door turner",
    "Remote ancestor?",
    "Aid in finding a station",
    "Adjustment means on a radio",
    "Pre-remote channel changer",
    "Old TV feature",
    "Turning point?",
    "Radio dial",
    "Channel changer",
    "Door opener",
    "Tree or door feature",
    "Radio feature",
    "It's held during an opening",
    "Drawer part",
    "Means of adjustment",
    "You can get a grip on it",
    "TV turner",
    "Door feature",
    "Door opener",
    "Bureau projection",
    "Door feature",
    "Door feature",
    "Door handle"
  ],
  "lire": [
    "Old Italian money",
    "Old Italian capital",
    "Vatican money, once",
    "Money replaced by euros",
    "Euros replaced them",
    "Notes in old Napoli",
    "Abandoned European capital",
    "Pre-euro money",
    "Old Roman coins",
    "Sicilians used to spend them",
    "Sicilian's millions",
    "Euros replaced them",
    "Old change in the Vatican",
    "Cassino cash, once",
    "Vatican money, once",
    "They're no longer tender in a typical trattoria",
    "Old Italian capital",
    "Bygone Italian coins",
    "Former European money",
    "Pre-euro Italian currency",
    "Stale Italian bread?",
    "Money replaced by the euro",
    "Pre-euro money in 2-Down",
    "Money replaced by euros",
    "Former European capital",
    "Erstwhile denaro",
    "Bygone Italian money",
    "Bygone coins",
    "Pre-euro cash",
    "Italian money, once",
    "Money with Garibaldi's picture",
    "Old Italian money",
    "Pre-euro money",
    "Pre-euro money",
    "Retired Italian money",
    "Euros replaced them",
    "Old money",
    "Old money",
    "Italian money",
    "Cassino cash",
    "Cremona cabbage",
    "Money in Milano",
    "Foreign currency",
    "Foreign money",
    "Italian money",
    "Marco's money",
    "What you might buy a Gucci bag in?",
    "Capital of Rome",
    "Three coins in la fontana?",
    "Italian cabbage?",
    "Italian cabbage",
    "Bills paid in Italy",
    "Sicilian dough",
    "San Marino money",
    "Italian money",
    "Parmesan bread",
    "Malta moolah",
    "Carrara cash",
    "Alternative to pounds",
    "Foreign-exchange listing",
    "Cassino cash"
  ],
  "grab": [
    "Get the attention of",
    "Word before bag or bar",
    "Take hold of",
    "Take rudely",
    "Word before bag or bar",
    "Get quickly, as lunch",
    "Snatch",
    "Partner of smash",
    "Seize",
    "Seize",
    "Snag",
    "Seizure",
    "Power ___",
    "Seize",
    "Clutch",
    "Suddenly get the attention of",
    "Seizure",
    "Appeal to",
    "Seize",
    "Seize",
    "Take in a hurry",
    "Interest, informally",
    "Act greedy",
    "Snap up",
    "Show greed or impatience",
    "Snatch",
    "Really appeal to",
    "Take the wrong way?",
    "Capture the attention of",
    "Suddenly interest",
    "Take hold of",
    "Interest greatly",
    "Get, as a cab",
    "Seize",
    "Snatch",
    "Capture",
    "Capture the attention of",
    "Interest, slangily",
    "Take 5, clue 3",
    "Seizure",
    "Excite, slangily",
    "Snatch",
    "Be rude at the dinner table",
    "Snatch",
    "Snag",
    "Catch",
    "Kind of bag",
    "Appropriate",
    "Excite, in slang",
    "Snatch",
    "Snatch",
    "Snatch"
  ],
  "ohme": [
    "\"Goodness gracious!\"",
    "\"Heavens to Betsy!\"",
    "World-weary exclamation",
    "\"Goodness!\"",
    "\"Heavens!\"",
    "\"Alas!\"",
    "Quaint words of worry",
    "Words of woe",
    "[sigh]",
    "Worrier's words",
    "Words of woe",
    "\"Alas\"",
    "Words of woe",
    "Words said with a sigh",
    "Words of woe",
    "Words of woe"
  ],
  "wink": [
    "Surreptitious bit of communication",
    "Indicator of interest on Match.com",
    "Instant",
    "Part of ;-)",
    "[I'm kidding!]",
    "Sleep unit?",
    "Indication that one is just teasing",
    "Instant",
    "[Just kidding!]",
    "Secret sign",
    "Flirtatious gesture",
    "Split second",
    "[Get the joke?]",
    "Flirtatious signal",
    "Subtle signal"
  ],
  "mojo": [
    "Lucky charm",
    "Confidence, slangily",
    "Air of confidence, in slang",
    "Self-confidence, informally",
    "See 25-Across",
    "Voodoo",
    "Magic charm",
    "Spelling aid?",
    "Voodoo spell",
    "Bad thing to lose",
    "Self-confidence, slangily",
    "\"It\"",
    "Magical power",
    "Luck that's workin' for ya",
    "Bad thing to lose",
    "Austin Powers's power",
    "It's stolen in an Austin Powers movie",
    "\"Mr. ___ Risin'\" (Jim Morrison biography)",
    "Voodoo charm",
    "Voodoo charm",
    "Lucky mystique",
    "\"Mr. ___ risin'\" (classic Doors lyric)",
    "Voodoo charm",
    "Magic charm",
    "Voodoo spell",
    "Magic charm",
    "Voodoo",
    "Voodoo charm",
    "Magic charm"
  ],
  "sank": [
    "Knocked in a pocket, in pool",
    "Holed, as a putt",
    "Made, as a putt",
    "Fell to the seabed",
    "What the Titanic did, famously",
    "Didn't float",
    "Put in the cup, as a golf ball",
    "Made, as a putt",
    "Sent to the ocean floor",
    "Went under",
    "Plummeted",
    "Set, as the sun",
    "Went under",
    "Went under",
    "Sent to the bottom",
    "Brought to ruin",
    "Torpedoed",
    "Lost seaworthiness",
    "Dropped",
    "Dipped",
    "Completed, as a putt",
    "Went bankrupt",
    "Put in the hole",
    "Lost buoyancy",
    "Collapsed",
    "Went under",
    "Blasted a hole in",
    "Went under",
    "Was the ruin of",
    "Torpedoed",
    "Dropped in the drink",
    "Sent to the bottom",
    "Settled",
    "Sent to the bottom",
    "Dropped, maybe",
    "Slumped",
    "Scuttled",
    "Went to the bottom"
  ],
  "hobo": [
    "Style of women's leather handbags",
    "___ spider, creature named for its presence around train tracks",
    "___ bag (fashion accessory)",
    "Wandering sort",
    "Bum",
    "So-called \"king of the road\"",
    "Vagabond",
    "Drifter",
    "King of the road",
    "Drifter",
    "Drifter",
    "Vagrant",
    "___ purse",
    "Down-on-his-luck wanderer",
    "Wanderer in search of work",
    "Type tending to drift",
    "Rail rider of old",
    "Vagabond",
    "Bum",
    "Tramp",
    "Rail rider",
    "Vagrant",
    "Wandering soul",
    "Drifting type",
    "One who's left holding the bag?",
    "Depression-era traveler",
    "Red Skelton persona",
    "Vagrant",
    "Bum",
    "Great Depression figure",
    "Boxcar hopper",
    "Tramp",
    "Boxcar rider",
    "Derelict",
    "Vagabond",
    "Tramp",
    "Sundowner",
    "King of the road",
    "Wanderer",
    "Old-fashioned camp resident",
    "Nonpaying train passenger",
    "Wanderer",
    "One who rides the rails",
    "Wanderer",
    "Odd jobs doer",
    "Transient",
    "Wanderer",
    "Bindle bearer",
    "Penniless person",
    "Wandering sort",
    "Rail rider",
    "Penniless traveler",
    "King of the road",
    "One for the road",
    "Bindle bearer",
    "Tramp",
    "\"Rolling stone\"",
    "Tramp",
    "Freight car hopper",
    "Tracks traveler",
    "Tramp",
    "Boxcar rider, maybe",
    "Wandering soul",
    "Tramp",
    "Traveling tramp",
    "One asking for a handout",
    "Ding-donger",
    "Wanderer",
    "Red Skelton persona",
    "King of the road",
    "Tramp",
    "Bindlestiff",
    "Skelton character",
    "Vagrant",
    "Sundowner"
  ],
  "abie": [
    "Man's nickname that sounds like consecutive letters of the alphabet",
    "Man's nickname that sounds like two letters of the alphabet",
    "Title lover in a 1922 Broadway hit",
    "\"___ Baby\" (\"Hair\" number)",
    "Irish Rose's love",
    "\"___ the Agent\" (old comic strip)",
    "Rose's love on old Broadway",
    "\"___ Baby\" (song from \"Hair\")",
    "Rose's guy, on Broadway",
    "\"___ Baby\" (song from \"Hair\")",
    "Rose's beau",
    "Irish Rose's beau",
    "Title character of 1920s Broadway",
    "Irish Rose's guy",
    "Title fellow in a 1922 Broadway hit",
    "Irish Rose's beau",
    "Anne Nichols title protagonist",
    "Title lover in a 1920s Broadway hit",
    "Broadway Rose-lover",
    "Rose's beau on Broadway",
    "\"___ the Agent\" (old comic strip)",
    "Mr. Levy of 1920s Broadway fame",
    "\"___ Baby\" (\"Hair\" song)",
    "Irish Rose's beau",
    "\"Irish Rose\" lover",
    "Rose's love, on Broadway",
    "Rosemary's love in a classic Anne Nichols play",
    "Guy with an Irish Rose, on old Broadway",
    "\"___ Baby\" (\"Hair\" song)",
    "\"___ Baby\" (\"Hair\" song)",
    "Rose's love, on Broadway",
    "Harry Hershfield comic \"___ the Agent\"",
    "Rose's love",
    "Title character in a 1922 Broadway hit",
    "Rose lover?",
    "Rose's husband, on Broadway",
    "Rose's Broadway beau",
    "Rose's beloved in a 1922 play",
    "Anne Nichols hero",
    "Anne Nichols hero",
    "Rose's fellow",
    "\"Hair\" song \"___ Baby\"",
    "Irish Rose's guy",
    "Irish Rose lover",
    "\"___ Baby\" (\"Hair\" song)",
    "Anne Nichols protagonist",
    "Guy with an Irish Rose",
    "Rose fancier",
    "Rose lover",
    "Guy with an Irish Rose",
    "Rose's fella",
    "His Rose was Irish",
    "Rose fancier",
    "Lover of an Irish Rose",
    "His Rose was Irish",
    "Rose's lover, on Broadway",
    "He had an Irish Rose",
    "Irish Rose lover",
    "Character created by Anne Nichols",
    "Broadway groom of 1922",
    "Anne Nichols stage hero"
  ],
  "drat": [
    "\"Phooey!\"",
    "\"Curses!\"",
    "\"Fiddlesticks!\"",
    "\"Confound it!\"",
    "\"Consarn it!\"",
    "\"Tarnation!\"",
    "\"Phooey\"",
    "\"Shoot!\"",
    "\"Nuts!\"",
    "\"Shucks!\"",
    "\"Darn it!\"",
    "\"Dagnabbit!\"",
    "\"Dagnabbit!\"",
    "\"Fiddlesticks!\"",
    "\"Darn!\"",
    "\"Consarn it all!\"",
    "\"Darn it!\"",
    "Alternative to hell?",
    "\"Darn it all!\"",
    "G-rated oath",
    "\"Dang!\"",
    "Blast alternative?",
    "\"Doggone!\"",
    "\"Phooey!\"",
    "\"Darn it!\"",
    "\"Nuts!\"",
    "\"Phooey!\"",
    "\"Fiddlesticks!\"",
    "\"Dang it!\"",
    "\"Fiddlesticks!\"",
    "\"Nuts!\"",
    "\"Dang!\"",
    "\"Curses!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Dang!\"",
    "\"Darn it all!\"",
    "\"Dagnabbit!\"",
    "\"Phooey!\"",
    "\"Fudge!\"",
    "\"Phooey!\"",
    "\"Darn!\"",
    "\"Shoot!\"",
    "\"Blast!\"",
    "\"Shoot!\"",
    "\"Shoot!\"",
    "\"Dang!\"",
    "\"Phooey!\"",
    "\"What rotten luck!\"",
    "\"Dagnabit!\"",
    "\"Dang!\"",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "\"Doggone it!\"",
    "\"Darn!\"",
    "\"Phooey!\"",
    "\"Nuts!\"",
    "\"Phooey!\"",
    "\"Nerts!\"",
    "\"Phooey!\"",
    "\"Dadgummit!\"",
    "\"Doggone it!\"",
    "\"Phooey!\"",
    "Mild complaint",
    "\"Curses!\"",
    "\"Doggone it!\"",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "Innocuous epithet",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "\"Doggone it!\"",
    "\"Oh, fudge!\"",
    "\"Phooey!\"",
    "Fieldsian expletive",
    "\"Phooey!\"",
    "\"Oh, bother!\"",
    "\"Phooey!\"",
    "\"Nuts!\"",
    "Relative of \"Fudge!\"",
    "\"Phooey!\"",
    "\"Shucks!\"",
    "\"Fudge!\"",
    "\"Fiddlesticks!\"",
    "\"Phoo!\"",
    "\"Darn it all!\"",
    "\"Oh, fudge!\""
  ],
  "keys": [
    "Phone, wallet, ___ (traveler's mental checklist)",
    "Janitor's janglers",
    "Parts of accordions",
    "Things janitors keep on rings",
    "Typically lost items that are \"found\" in the starts of 16-, 24-, 45- and 57-Across",
    "Shift and Tab, for two",
    "Tumbler turners",
    "E, F and G, but not H",
    "Unfortunate things to leave locked in a car",
    "Locale for the southernmost part of U.S. Route 1, with \"the\"",
    "See 52-Down",
    "What a designated driver takes",
    "Janitor's ringful",
    "Answer sheets",
    "Decryption needs",
    "Command and Control",
    "You might give them a ring",
    "Command and control",
    "Florida ___",
    "Piano pieces",
    "Unfortunate items to lock in a car",
    "Ringed set",
    "Critical elements",
    "A through G",
    "Specifications marked on 10-hole harmonicas",
    "Lockers",
    "What instrumentalists often change",
    "F sharp major and others",
    "Jingle-janglers",
    "People are always searching for them",
    "Custodian's ringful",
    "Items sometimes locked in a car (oops!)",
    "They have all the answers",
    "Frequently misplaced items",
    "Pocket jinglers",
    "PC things, which can be found at the starts of 14-, 22-, 37-, 46- and 61-Across",
    "Florida ___",
    "Phi Beta Kappa mementos",
    "Custodian's collection",
    "A and E, but not I, O or U",
    "Florida islets",
    "Florida vacation area",
    "Cryptographers' needs",
    "Florida ___",
    "Things on rings",
    "Frequently misplaced items",
    "Custodian's collection",
    "Accordion parts",
    "Ivories",
    "Custodian's need",
    "Florida vacation spot, with \"the\"",
    "Piano pieces?",
    "C minor and others",
    "Ivories and others",
    "Lock openers",
    "Jailer's need",
    "Ivories",
    "Low islands",
    "Florida ___",
    "Passe-partouts"
  ],
  "seek": [
    "Be it, say",
    "Partner of hide",
    "Look for",
    "Run for, as office",
    "Search for",
    "Look for",
    "Request",
    "Look for",
    "\"___ and ye shall find\"",
    "Look for",
    "Look for",
    "Car radio button",
    "Look for",
    "Car radio button",
    "Car radio button",
    "Go for",
    "Go after",
    "Curry",
    "Grope for",
    "Be \"it,\" in a game",
    "Hide-and-___",
    "Ask for",
    "Try to unearth",
    "Car radio mode",
    "Dig for",
    "Ask for",
    "Pursue",
    "Dig for",
    "Hide's partner",
    "Hunt for",
    "Request",
    "Nose out",
    "Run for",
    "Pursue",
    "Fish for",
    "Be \"it\"",
    "Look for",
    "Request",
    "Dig for",
    "Look for",
    "Search",
    "Pursue"
  ],
  "lulu": [
    "Humdinger",
    "Doozy",
    "Knockout",
    "Doozy",
    "Humdinger",
    "Humdinger",
    "Nickname for Louise",
    "Ripsnorter",
    "Doozy",
    "Dandy",
    "Doozy",
    "Doozy",
    "Beaut",
    "Dandy",
    "She's back in town, in a Fats Waller song",
    "It really stands out",
    "Doozy",
    "Ripsnorter",
    "Doozy",
    "Wower",
    "Humdinger",
    "Something special",
    "Doozy",
    "Berg opera",
    "Doozy",
    "\"To Sir With Love\" singer, 1967",
    "Corker",
    "Humdinger",
    "Lollapalooza",
    "Doozy",
    "Lollapalooza",
    "Whopper",
    "Lollapalooza",
    "Doozie",
    "Whopper",
    "Ripsnorter",
    "Lollapalooza",
    "Humdinger",
    "Humdinger",
    "Lollapalooza",
    "\"To Sir With Love\" singer",
    "Humdinger",
    "Doozy",
    "Humdinger",
    "Humdinger",
    "Lollapalooza",
    "Whopper",
    "Dilly",
    "Humdinger",
    "Humdinger",
    "Ripsnorter",
    "Humdinger",
    "Wowser",
    "Lollapalooza",
    "Knockout",
    "Lollapalooza",
    "Ripsnorter",
    "Lollapalooza",
    "Berg opera",
    "Oner",
    "Corker",
    "Humdinger",
    "Corker",
    "One of a kind",
    "Opera based on two Wedekind plays",
    "Oner",
    "Little comics girl",
    "Berg opera"
  ],
  "alte": [
    "___ Oper (famed German concert hall)",
    "German opposite of \"junge\"",
    "Old person, in Oldenburg",
    "___ Oper (historic concert hall in Frankfurt, Germany)",
    "Old one, in Oldenburg",
    "Berlin's ___ Nationalgalerie",
    "Der ___ (Adenauer)",
    "___ Sprachen (ancient languages: Ger.)",
    "Old one, in German",
    "Old one, in Austria",
    "Old one, in Oldenburg",
    "Old man: Ger.",
    "Old Hamburger?",
    "Adenauer nickname, with \"Der\"",
    "Der ___ (Konrad Adenauer)",
    "___ Oder (German river)",
    "Old one, along the Oder",
    "Old man, in Mannheim",
    "Der ___ (Konrad Adenauer)",
    "Aged Frankfurter?",
    "Germany's Konrad Adenauer, Der ___",
    "Old one: Ger.",
    "Old, to Oskar",
    "Der ___ Herr (the old man): Ger.",
    "Der ___ (Adenauer)",
    "Der ___ Fritz (Friedrich the Great)",
    "Der ___ (Konrad Adenauer)",
    "Der ___ (Adenauer)",
    "Der ___ (German \"father\")",
    "Der ___ (Adenauer)",
    "Der ___ (Adenauer)",
    "Adenauer nickname, with \"Der\"",
    "Der ___ (Adenauer)",
    "Old one: Ger.",
    "Konrad Adenauer, Der ___",
    "Adenauer, a k a Der ___",
    "Der ___ (Adenauer)",
    "Adenauer moniker Der ___",
    "Epithet for Adenauer",
    "Der ___ (Adenauer)",
    "Der ___ (Adenauer)",
    "Der ___ (Adenauer monicker)",
    "Der ___ (Adenauer)",
    "Der ___ (Adenauer)",
    "Old-timer in Wiesbaden",
    "Der ___ (Adenauer)",
    "Der ___ (Adenauer moniker)",
    "Part of an Adenauer epithet",
    "Das ___ (the old): Ger.",
    "Der ___ (Adenauer)"
  ],
  "omsk": [
    "Trans-Siberian Railway hub",
    "Locale of Dostoyevsky's exile",
    "Site of Dostoyevsky's exile",
    "Trans-Siberian Railway city",
    "Trans-Siberian Railway hub",
    "Siberian city",
    "City west of Novosibirsk",
    "Home of the Dostoyevsky Literary Museum",
    "Siberian city",
    "Oblast bordering Kazakhstan",
    "Siberian city",
    "Siberian city",
    "Dostoyevsky's exile city",
    "Trans-Siberian Railroad stop",
    "Dostoyevsky's exile city",
    "Stop on the Trans-Siberian Railway",
    "Trans-Siberian Railroad stop",
    "Trans-Siberian Railroad stop",
    "Trans-Siberian Railroad city",
    "Siberian river port",
    "City in SW Russia",
    "City on the Irtysh River",
    "Siberian city",
    "Trans-Siberian Railroad city",
    "Trans-Siberian Railroad stop",
    "Siberian city"
  ],
  "fogs": [
    "Mistifies?",
    "Clouds (up)",
    "Becomes obscure, in a way, with \"up\"",
    "Gets steamy, with \"up\"",
    "They're hard to see through",
    "Steams (up)",
    "Muddles",
    "Clouds (up)",
    "They're hard to see through",
    "Clouds (up)",
    "Obscures",
    "Frequent San Francisco conditions",
    "Reasons for lighthouses",
    "Heavy mists",
    "Airport dangers",
    "Beclouds, with \"up\"",
    "Obscures"
  ],
  "gags": [
    "Comic's repertoire",
    "Practical jokes",
    "Jokes",
    "Elements in a comic's repertoire",
    "Jokes",
    "Comedy bits",
    "Has a hard time swallowing",
    "Jokester's jokes",
    "Comic's offerings",
    "Comedian's stock in trade",
    "Stand-up comic's material",
    "Laughing stock?",
    "Comedian's stock",
    "One-liners",
    "April fools",
    "Comedian's stock",
    "Jokes that may be \"running\"",
    "Silences",
    "Comedian's stock",
    "Muzzles",
    "Comic's material",
    "Gets choked up",
    "Pranks",
    "Silences",
    "Shuts up, as a hostage",
    "Practical jokes",
    "One-liners"
  ],
  "zoos": [
    "Places to bear witness?",
    "Arboretums : trees :: ___ : animals",
    "Only places to find anteaters in the U.S.",
    "Places with lions and giraffes",
    "Crazy places",
    "Crazy places",
    "Madhouses",
    "Where the wild things are?",
    "Bronx and Central Park attractions",
    "Menageries",
    "Sites of some exhibits",
    "Places with exotic animals",
    "They feature creatures",
    "Sites of monkey business?",
    "Animal keepers",
    "Places for petting",
    "Where gnus snooze",
    "Where the wild things are",
    "Where leopards get spotted"
  ],
  "soto": [
    "Mississippi's De ___ National Forest",
    "___ speak",
    "Explorer Hernando de ___",
    "Words said before bed?",
    "___ speak",
    "Explorer Hernando de ___",
    "___ speak",
    "___ speak",
    "\"And ___ bed\"",
    "Florida's De ___ National Monument",
    "___ speak",
    "\"Licence to Kill\" Bond girl Talisa",
    "All-Star Cubs catcher Geovany",
    "\"___ speak\"",
    "\"And ___ bed\"",
    "De ___ (Dallas suburb)",
    "___ speak",
    "\"And ___ bed\"",
    "___ speak",
    "\"And ___ bed\"",
    "\"And ___ bed\"",
    "De ___",
    "Explorer Hernando de ___",
    "___ speak",
    "___ speak",
    "Explorer Hernando de ___",
    "\"And ___ bed\"",
    "Hernando de ___",
    "\"And ___ bed\": Pepys",
    "___ speak",
    "\"You Bet Your Life\" sponsor De ___",
    "\"___ make a long story short...\"",
    "___ speak",
    "\"And ___ bed\": Pepys",
    "___ speak",
    "___ speak (as it were)"
  ],
  "lyle": [
    "Lovett of country music",
    "Four-time Grammy winner Lovett",
    "Lovett of country music",
    "Singer Lovett",
    "Yankee Sparky who wrote \"The Bronx Zoo\"",
    "Sandy who won the 1985 British Open",
    "Country singer Lovett",
    "Sparky of the 1970s Yankees",
    "Cy Young Award winner Sparky",
    "Lovett of country",
    "Vocalist Lovett",
    "Lovett of country music",
    "Singer Lovett",
    "Julia Roberts ex ___ Lovett",
    "Singer Lovett",
    "Singer Lovett",
    "Singer Lovett",
    "Grammy winner Lovett",
    "Singer Lovett",
    "Cy Young award winner Sparky",
    "Football's Alzado",
    "Singer Lovett",
    "1988 Masters winner",
    "Golfer Sandy",
    "Sparky who pitched",
    "Singer Lovett",
    "1977 Cy Young winner from the Yankees",
    "Singer Lovett",
    "Actor Talbot",
    "\"The Bronx Zoo\" author",
    "Singer Lovett",
    "Singer Lovett"
  ],
  "rout": [
    "Runaway",
    "Give a drubbing",
    "Runaway victory",
    "Crushing defeat",
    "Drubbing",
    "Lopsided game",
    "Big loss",
    "32-0, e.g.",
    "Cream",
    "Decisive defeat",
    "Vanquish",
    "Blowout",
    "Defeat decisively",
    "Drubbing",
    "Wipe the floor with",
    "Blowout, as in sports",
    "Trounce",
    "Thrash",
    "Beat by a mile",
    "Lopsided win",
    "Blowout victory",
    "\"Laugher\"",
    "20-0 baseball score, say",
    "Drubbing",
    "Easy win",
    "Lopsided victory",
    "Runaway victory",
    "10 to 1, e.g.",
    "Win by a wide margin",
    "Mop the floor with",
    "Defeat decisively",
    "Decisive defeat",
    "Powerful defeat",
    "Really clobber",
    "Romp",
    "30-0, e.g.",
    "50-0, e.g.",
    "Win in a walk",
    "Lopsided win",
    "Drubbing",
    "Huge win",
    "Score of 100-0, say",
    "Defeat decisively",
    "Crush in competition",
    "Slaughter",
    "Football score of 60-0, say",
    "Overwhelming defeat",
    "28-0, e.g.",
    "Not a close game",
    "Landslide",
    "Overwhelm",
    "Win in a runaway",
    "Drubbing",
    "Shutout",
    "Beat badly",
    "Wipe the floor with",
    "Wipeout",
    "Defeat decisively",
    "Defeat soundly",
    "Lopsided win"
  ],
  "ispy": [
    "Car trip pastime",
    "Kids' observation game",
    "Road trip guessing game",
    "Popular children's book series with hidden objects",
    "Popular game that needs no equipment",
    "Kids' road trip game",
    "Game often played on car rides",
    "Kids' guessing game",
    "Kids' road trip game",
    "Popular children's \"find it\" book series",
    "Children's game with letters",
    "Old Cosby show",
    "TV show for which Bill Cosby won three Emmys",
    "Look-for-it children's game",
    "Best-selling children's book series by Walter Wick and Jean Marzollo",
    "Game with a spotter",
    "1960s Bill Cosby TV series",
    "Kids' guessing game",
    "Cosby/Culp TV series",
    "Kids play it",
    "Culp/Cosby TV series",
    "Early Cosby series",
    "Old Culp/Cosby series",
    "Classic kids' game",
    "2002 Eddie Murphy film",
    "Game of observation",
    "Cosby's first series",
    "1960's adventure series",
    "1960's Cosby show",
    "1960's espionage show",
    "Cosby show",
    "Kindergarten game",
    "Cosby series",
    "1960's espionage series",
    "Culp/Cosby TV series",
    "TV show about agents Robinson and Scott"
  ],
  "runt": [
    "Reddish-brown",
    "Little piggy",
    "Pipsqueak",
    "Peewee",
    "Peewee",
    "Littlest piggy",
    "Littlest in a litter",
    "Litter's littlest",
    "Small one",
    "Little pup",
    "Peewee pup",
    "Peanut",
    "Shrimp",
    "Little sucker?",
    "Shrimp",
    "Half-pint",
    "Squirt",
    "Half-pint",
    "Pipsqueak",
    "Underweight delivery, maybe",
    "Littlest sucker",
    "Pipsqueak",
    "Bullied baby, maybe",
    "Litter's littlest",
    "Shrimp",
    "Little squirt",
    "Pipsqueak",
    "Pipsqueak",
    "Hardly the pick of the litter",
    "Litter's littlest",
    "Litter member",
    "Shrimp",
    "Litter's littlest",
    "Litter critter",
    "Peewee",
    "Litter's littlest",
    "Shrimp",
    "Wee one",
    "Puny pup",
    "Litter littlest"
  ],
  "vibe": [
    "Feeling in the room",
    "Vague sense",
    "A shady person may give off a bad one",
    "Intangible feeling, informally",
    "Atmosphere",
    "Intuitive feeling, informally",
    "Mood",
    "Feeling, slangily",
    "Vague sense",
    "General feeling",
    "Feeling",
    "Something that's picked up",
    "Vague sense",
    "Feeling, slangily",
    "Part of an aura",
    "Aura being picked up",
    "Sensation, slangily",
    "Good thing to catch",
    "Feeling",
    "Sensation, slangily",
    "Feeling"
  ],
  "iwon": [
    "Exultant cry",
    "Excited cry after scratching a lottery ticket",
    "Shout of victory",
    "Lucky ticket holder's cry",
    "\"Victory is mine!\"",
    "Exultant cry",
    "Excited cry in a casino",
    "Triumphant boast",
    "\"Victory is mine!\"",
    "Champ's cry",
    "Victor's boast",
    "Victor's cry",
    "When repeated, a happy cry",
    "When repeated, lucky lottery purchaser's cry",
    "Game cry",
    "\"Victory is mine!\"",
    "Lucky lottery player's cry",
    "Triumphant cry",
    "Champion's shout",
    "Joyful cry",
    "Gloating cry",
    "Lottery player's cry of elation",
    "Cry of glee",
    "Exultant cry",
    "Game player's gleeful cry",
    "Lottery player's happy shout",
    "Victor's cry",
    "Exuberant casino cry",
    "Lottery player's exultant cry",
    "Lottery cry",
    "Exultant cry",
    "Cry after \"Yippee!\"",
    "Cry of glee"
  ],
  "suet": [
    "Type of animal fat",
    "Bird feeder fill",
    "Tallow source",
    "Plum pudding ingredient",
    "Food for a woodpecker",
    "Tallow source",
    "Fat used in mincemeat",
    "Candlemaking supply",
    "Bird feeder material",
    "Ingredient in Christmas pudding",
    "Haggis ingredient",
    "Bird feeder fill",
    "Kind of pudding",
    "Bird feeder fill",
    "It's for the birds",
    "Material for some soapmakers",
    "Winter bird feeder food",
    "Cake makeup for a feeder",
    "Raw beef fat",
    "It's for the birds",
    "Ingredient in some English pudding",
    "Fat used for tallow",
    "Tallow ingredient",
    "Tallow source",
    "Steamed pudding ingredient",
    "Bird feeder fill",
    "Blood sausage ingredient",
    "Fatty treat for birds",
    "Bird feeder fill",
    "Fat of the lamb",
    "Mincemeat ingredient",
    "Tallow source",
    "Tallow source",
    "Mincemeat ingredient",
    "Mincemeat ingredient",
    "Mutton product",
    "Fatty treat for birds",
    "Cooking fat",
    "Feeder filler",
    "Pudding ingredient",
    "Mincemeat need",
    "Feeder filler",
    "Tallow source",
    "Fat used in candlemaking",
    "Fatty tissue",
    "It's for the birds",
    "Tallow source",
    "Bird feeder food",
    "Tallow source",
    "Plum pudding ingredient",
    "Winter bird food",
    "Candlemaker's supply",
    "Woodpecker's snack",
    "Cooking fat",
    "Bird feeder fill",
    "Animal fat",
    "Kind of pudding",
    "Tallow source",
    "Tallow source",
    "Tallow source"
  ],
  "many": [
    "A host of",
    "Umpteen",
    "\"___ thanks!\"",
    "Word in the translation of \"e pluribus unum\"",
    "A bajillion",
    "Umpteen",
    "Way more than a few",
    "Lots of",
    "Oodles of",
    "See 9-Down",
    "Lots of",
    "___ moons",
    "___ a time",
    "Lots",
    "\"I had not thought death had undone so ___\": \"The Waste Land\"",
    "___ moons",
    "Word repeated before \"moons ago\"",
    "Oodles",
    "Lots of",
    "Tons of",
    "\"___ happy returns\"",
    "Umpteen",
    "Lots of",
    "Umpteen",
    "Quite a few",
    "A bunch of",
    "A ton of",
    "Loads of",
    "Umpteen",
    "Lots of",
    "Countless",
    "___ a time",
    "Numerous",
    "A goodly number",
    "Lots",
    "Oodles",
    "A zillion",
    "A zillion",
    "Umpteen",
    "More than a few",
    "Numerous",
    "Umpteen",
    "Lots",
    "Multitude",
    "\"___ are called...\"",
    "Lots",
    "Lots and lots"
  ],
  "odom": [
    "Leslie ___ Jr. of the original \"Hamilton\" cast",
    "Leslie ___ Jr., member of the original \"Hamilton\" cast",
    "\"Hamilton\" actor Leslie ___ Jr.",
    "See 36-Across",
    "Blue Moon ___, three-time World Series winner for the 1970s A's",
    "Former Laker Lamar",
    "2004-11 Lakers forward",
    "Lamar who married a Kardashian",
    "Blue Moon of 1960s-'70s baseball",
    "Longtime Laker Lamar",
    "Khloe Kardashian's married name",
    "Lamar who married a Kardashian",
    "1960s-'70s pitcher Blue Moon",
    "Kardashian spouse Lamar ___",
    "Lamar of the N.B.A.",
    "N.B.A. forward Lamar ___",
    "Baseball's Blue Moon",
    "Lakers star Lamar",
    "N.B.A. star nicknamed the Candy Man",
    "Defensive end Antwan",
    "1960s baseball All-Star Blue Moon ___",
    "N.B.A. star Lamar ___",
    "Baseball's Blue Moon",
    "Blue Moon of baseball",
    "1960's baseball Hall-of-Famer Blue Moon ___",
    "Baseball's Blue Moon",
    "Baseball's Blue Moon"
  ],
  "abbe": [
    "French monastery resident",
    "Title for Liszt",
    "Caen cleric",
    "French cleric",
    "Head of a French monastery",
    "French cleric",
    "French cleric",
    "Sermonizer in France",
    "French cleric",
    "French cleric",
    "French cleric",
    "Actress Lane of old TV",
    "French cleric",
    "Lane with smooth curves",
    "French religious title",
    "French cleric",
    "Singer Lane",
    "Religious figure",
    "French cleric",
    "French cleric",
    "French clergyman",
    "Member of the clerge",
    "Cannes cleric",
    "French cleric",
    "French cleric",
    "French cleric",
    "French clergyman",
    "Novelist Prevost",
    "Singer-actress Lane",
    "French author ___ Prevost",
    "Clerical title",
    "French cleric",
    "Clairvaux cleric",
    "French ecclesiastic",
    "Halevy's \"L' Constantin\"",
    "Singer Lane",
    "One who follows orders?"
  ],
  "cost": [
    "\"That'll ___ you\"",
    "\"It'll ___ you\"",
    "Number written in parentheses on an income statement",
    "\"That'll ___ you!\"",
    "Come to",
    "Total, in a way",
    "___ analysis",
    "Go for",
    "Price",
    "Not be free",
    "Price",
    "Benefit offsetter",
    "Add up to",
    "Go for",
    "Basis in accounting",
    "Kind of accounting",
    "Not be gratuitous",
    "Price to pay",
    "Price",
    "Price",
    "Amount to",
    "Were priced at",
    "Price",
    "Go for",
    "Part of COLA",
    "Fare, e.g.",
    "Damage, so to speak",
    "Sell for",
    "Outlay",
    "\"It'll ___ you\"",
    "How much to pay",
    "Run",
    "How much to pay",
    "Amount to",
    "Toll",
    "Price",
    "Go for",
    "Purchase price",
    "Outlay",
    "Price paid",
    "What you pay",
    "Price",
    "Price",
    "Price basis",
    "Kind of analysis",
    "Penalty",
    "Price tag",
    "Outlay",
    "Amount to pay",
    "Part of a profit calculation",
    "Kind of control",
    "Market amount",
    "Bargaining basis",
    "Penalty",
    "Price",
    "Commercial quotation",
    "Outlay",
    "Sacrifice",
    "Business basis",
    "Break-even amount",
    "Penalty",
    "Damage, so to speak",
    "Price",
    "Overhead"
  ],
  "nave": [
    "Where parishioners sit",
    "Basilica part",
    "Church area",
    "Where the pews are",
    "Place to pray",
    "Where a congregation congregates",
    "Church area",
    "Flock gathering place",
    "What the transept transects",
    "Congregation's place",
    "Area in front of a chancel",
    "Cathedral area",
    "Main part of a church",
    "Mass gathering site",
    "Cathedral area",
    "Mass gathering place",
    "Mass gathering place",
    "Congregational area",
    "Main seating area",
    "Place for a flock",
    "Congregation location",
    "Hub of a wheel",
    "Church part",
    "Area next to the narthex",
    "Center of a cathedral",
    "Basilica center",
    "Place to pray",
    "View from the pulpit",
    "Part of a basilica",
    "Church area",
    "Pew area",
    "Narthex neighbor",
    "Pews' place",
    "Basilica part",
    "Place for a mass meeting?",
    "Flock's area",
    "Place for prayer",
    "One end of a narthex",
    "Basilica's center",
    "Where the laity sits",
    "Narthex end",
    "Where the congregation sits",
    "Church center",
    "Pews' place",
    "Church section",
    "Pew area",
    "Central church area",
    "Pew locale",
    "Narthex neighbor",
    "Wheel hub",
    "Congregational area",
    "Church section",
    "Section flanked by aisles",
    "It borders the chancel",
    "It's probably filled on Easter",
    "Where the congregation congregates",
    "Pew locale",
    "Church part"
  ],
  "airy": [
    "Like an atrium",
    "Well-ventilated",
    "Like sponge cakes",
    "Letting in a breeze, say",
    "Light and open",
    "Breezy",
    "Light and graceful",
    "Not stuffy",
    "Well-ventilated",
    "Like an atrium",
    "Very light",
    "Light and open",
    "Like a souffle's texture",
    "Light and breezy",
    "Light as a feather",
    "Light and graceful",
    "Well-ventilated",
    "Light",
    "Not stuffy",
    "Insubstantial",
    "Hardly stuffy",
    "Delicate",
    "Well-ventilated",
    "Light",
    "Bright and breezy",
    "Open-roofed",
    "Light as a feather",
    "Open",
    "Light",
    "Breezy",
    "Full of breezes",
    "Unsubstantial",
    "Well-ventilated",
    "Light and open",
    "Light",
    "Well-ventilated",
    "Open to the breeze",
    "Breezy",
    "Subject to a draft",
    "Gossamer",
    "Well-ventilated",
    "Light",
    "Subject to breezes",
    "Open",
    "Insubstantial",
    "Open to breezes",
    "Ethereal",
    "Ethereal",
    "Light and open",
    "Insubstantial",
    "Not stifling",
    "Well ventilated",
    "Breezy"
  ],
  "dill": [
    "Kind of pickle",
    "Herb often used with potatoes",
    "___ pickle",
    "Parsley relative",
    "___ pickle",
    "It's in a pickle",
    "Pickle variety",
    "___ pickle",
    "Pickle flavorer",
    "Salmon seasoning",
    "Pickle variety",
    "Pickle flavoring",
    "Soup flavorer",
    "Gravlax ingredient",
    "Borscht flavorer",
    "Pickle flavoring",
    "It's in a pickle",
    "Salmon garnish",
    "Gravlax ingredient",
    "Flavorful seed source",
    "Pickle flavoring",
    "Deli freebie",
    "Certain pickle",
    "Pickle flavoring",
    "Pickling herb",
    "It's in a pickle",
    "Pickle flavoring",
    "Parsley's pungent relative",
    "Soup flavoring",
    "This can find itself in a pickle",
    "Food flavorer"
  ],
  "worm": [
    "Bit of bait",
    "Fishhook squirmer",
    "Wriggler on a fishhook",
    "Wriggly temptation for a fish",
    "Wriggling bait",
    "Fish bait",
    "Computer woe",
    "Internet scourge",
    "Fish bait",
    "Wriggler",
    "Bird's find",
    "Wriggle",
    "Inside of a jumping bean",
    "Tequila bottle additive",
    "It may be on a hook",
    "Apple spoiler",
    "Contemptible one",
    "Contemptible person",
    "It may be hooked",
    "Crawl",
    "Tequila additive",
    "Advance stealthily"
  ],
  "klee": [
    "Painter Paul",
    "Swiss painter Paul",
    "\"Twittering Machine\" artist",
    "Paul who painted \"Fish Magic\"",
    "Bauhaus figure",
    "Painter Paul [11]",
    "Swiss-German artist Paul",
    "\"Red Balloon\" painter Paul",
    "Artist Paul",
    "\"Der Goldfisch\" painter",
    "Swiss Surrealist",
    "\"Fish Magic\" painter",
    "Swiss artist Paul",
    "Noted Bauhaus artist",
    "Artist Paul",
    "Painter Paul",
    "Noted Bauhaus teacher",
    "Swiss abstractionist",
    "Swiss artist Paul",
    "Swiss artist Paul",
    "Swiss painter Paul",
    "Co-founder of the avant-garde Blue Four",
    "\"Ad Parnassum\" artist, 1932",
    "\"The Mocker Mocked\" artist",
    "Swiss artist Paul",
    "Swiss artist Paul",
    "Swiss artist Paul ___",
    "Swiss abstract painting",
    "Artist Paul",
    "\"Fish Magic\" painter",
    "Swiss artist Paul",
    "Whimsical Swiss artist",
    "Artist Paul",
    "Bauhaus School member",
    "\"The Goldfish\" painter",
    "\"The Twittering Machine\" artist",
    "\"Fish Magic\" painter",
    "\"The Thinking Eye\" artist",
    "Bauhaus artist",
    "\"The Mocker Mocked\" artist, 1930",
    "Artist Paul",
    "Bauhaus artist",
    "\"Twittering Machine\" artist",
    "Swiss painter Paul",
    "MOMA artist",
    "\"Twittering Machine\" artist",
    "Artist Paul",
    "Painter Paul",
    "\"The Mocker Mocked\" artist",
    "Swiss painter",
    "Swiss artist Paul",
    "Abstract artist Paul",
    "\"Red Balloon\" painter",
    "\"The Twittering Machine\" artist"
  ],
  "such": [
    "\"___ fun!\"",
    "\"___ is life\"",
    "Word found before and after \"and,\" in a phrase",
    "Similar examples",
    "\"___ is life\"",
    "No-luck connector",
    "So great",
    "___ as it is",
    "Word before or after \"as\"",
    "Quite",
    "\"___ is life ...\"",
    "To so high a degree",
    "\"___ is life\"",
    "The like",
    "Word before and after \"and\"",
    "\"___ a deal!\"",
    "Quite",
    "Word before and after \"and\"",
    "Similar things",
    "It may precede or follow \"as\"",
    "So great",
    "1964 Elvis hit \"___ a Night\"",
    "Very"
  ],
  "treo": [
    "Early smartphone model",
    "Bygone smartphone",
    "iPhone alternative, once",
    "Old Palm smartphone",
    "Early smartphone",
    "Smartphone preceded by the Pre",
    "Palm product",
    "Palm smartphone",
    "Palm phone",
    "Early smartphone",
    "BlackBerry rival",
    "Early smartphone",
    "Smartphone introduced in 2002",
    "BlackBerry rival"
  ],
  "meal": [
    "Square thing",
    "Brunch, e.g.",
    "Not just a snack",
    "What corn can be ground into",
    "Repast",
    "Mostly bygone airline amenity",
    "See 41-Down",
    "Offering now discontinued by most discount carriers",
    "Banquet",
    "More than a snack",
    "Soup kitchen offering",
    "Happy ___",
    "Ground-up fare",
    "Fast break?",
    "\"The Rachael Ray Show\" creation",
    "Breakfast or lunch",
    "___ plan",
    "Coarse powder",
    "Grace period?",
    "Board provision",
    "Brunch or dinner",
    "Rare airline offering, nowadays",
    "Lunch or dinner",
    "Group of courses",
    "Seder, e.g.",
    "Supper, e.g.",
    "Square thing",
    "Square ___",
    "Repast",
    "Prix fixe offering",
    "Airline rarity, nowadays",
    "Square thing",
    "Airline rarity, increasingly",
    "Supper, e.g.",
    "Board unit?",
    "Happy ___",
    "Breakfast, lunch or dinner",
    "Mess, e.g.",
    "Prix fixe offering",
    "Breakfast, lunch or dinner",
    "Freebie on a long flight",
    "\"Square\" thing",
    "Repast",
    "Banquet",
    "It may be prix fixe",
    "Square ___",
    "Banquet",
    "Picnic, e.g.",
    "One of three squares",
    "Ground-up corn",
    "See 30-Down",
    "Lunch or dinner",
    "Airline serving",
    "Buffet",
    "Repast",
    "Spread",
    "Square fare?",
    "Kind of ticket",
    "A good one should be square",
    "More than a snack",
    "Fast stop?",
    "Ground grain",
    "Repast",
    "Ground grain",
    "Grist",
    { "formula": "+Grist", "result": { "error": "#NAME?" } },
    "Farina",
    "More than a snack",
    "More than a snack",
    "Banquet",
    "More than a snack",
    "Spread",
    "Ground grain"
  ],
  "wide": [
    "Off the mark",
    "Like many missed field goals",
    "Out of the strike zone, in a way",
    "Vast",
    "Extensive",
    "Hard to get around, say",
    "Like some missed pitches",
    "W*W",
    "\"Open ___\"",
    "Broad",
    "Broad",
    "\"Open ___\"",
    "All-inclusive",
    "Like some loads",
    "Call on a pitch",
    "Like some smiles and loads",
    "Broad",
    "Off the mark",
    "Off, as a pitch",
    "Not narrow",
    "Outstretched",
    "Off the mark",
    "Off the mark",
    "Like some loads",
    "Partner of 85-Down",
    "Like some loads",
    "Plate ump's call",
    "Like a Windsor tie",
    "Pants specification",
    "Broad",
    "Off the mark",
    "All-inclusive",
    "Announcer's call on a pitch",
    "Astray",
    "Sweeping",
    "Not on target",
    "Broad",
    "Not on target"
  ],
  "romo": [
    "Ex-QB football analyst Tony",
    "Cowboys QB-turned-broadcaster",
    "Football analyst Tony",
    "Former QB Tony",
    "QB Tony",
    "Longtime Dallas Cowboys QB Tony",
    "QB Tony",
    "Tony of the Dallas Cowboys",
    "QB Tony"
  ],
  "brrr": [
    "\"It's co-o-old!\"",
    "\"It's c-c-c-cold!\"",
    "\"It's c-c-cold!\"",
    "\"It's f-f-f-freezing!\"",
    "Icy remark?",
    "\"I'm f-f-freezing!\"",
    "\"It's f-f-freezing!\"",
    "Chilly remark?",
    "Remark from a draft dodger?",
    "Comment from the chattering class?",
    "Winter exclamation",
    "Winter comment",
    "Arctic exclamation",
    "\"It's c-c-c-cold!\"",
    "Comment at International Falls, Minn.",
    "Winter comment",
    "Snowman's comment?"
  ],
  "floe": [
    "Hazard on an Arctic voyage",
    "Raft for a polar bear",
    "Sheet of ice",
    "Sheet",
    "White sheet",
    "Ice sheet",
    "Resting place for a polar bear",
    "Penguin's perch",
    "Arctic shipping hazard",
    "Arctic drifter",
    "Chunk of ice in the Arctic Ocean",
    "Ice chunk at sea",
    "Ice mass",
    "Moving ice",
    "Arctic ice",
    "Floating arctic mass",
    "Penguin's spot, maybe",
    "Floating arctic mass",
    "Polar hazard",
    "Ice sheet",
    "Penguin's hangout",
    "Arctic sight",
    "Arctic mass",
    "Big chunk of ice",
    "A polar bear might be found on one",
    "White sheet",
    "Weddell Sea phenomenon",
    "Sheet of ice",
    "Sheet of ice",
    "Ice sheet",
    "Polar bear's transport?",
    "Arctic ice",
    "Ice ___",
    "Arctic sight",
    "Ice sheet",
    "Baffin Bay hazard",
    "Arctic ice",
    "Hull hazard",
    "Arctic sight",
    "Sheet of ice",
    "Arctic phenomenon",
    "Certain sheet",
    "Baffin Bay sight",
    "Sheet",
    "Sheet of ice",
    "Ice ___",
    "Shipping hazard",
    "Bering Sea sighting",
    "White sheet",
    "Ice sheet",
    "Ice chunk"
  ],
  "afbs": [
    "Spots for fighter jets, in brief",
    "Where fighter jets are found: Abbr.",
    "Place for B-2s and B-52s: Abbr.",
    "Andrews and Edwards, for two: Abbr.",
    "Jet locales: Abbr.",
    "Edwards and others: Abbr.",
    "Homes for mil. planes",
    "Places to cool one's jets?: Abbr.",
    "Andrews, Langley, etc.: Abbr."
  ],
  "wrap": [
    "\"That's a ___\"",
    "Finish, with \"up\"",
    "Sandwich alternative",
    "Call it a day",
    "Bundle up",
    "Tortilla sandwich",
    "Finish filming",
    "Completion of filming",
    "\"That's a ___!\"",
    "Rolled sandwich",
    "Stop shooting",
    "Bundle up",
    "Finish (up)",
    "Close the set?",
    "Sandwich style",
    "Tinfoil, e.g.",
    "Shawl or stole",
    "Stop shooting",
    "Cylindrical sandwich",
    "Shawl, e.g.",
    "Shooting goal",
    "Finish shooting a movie",
    "Finish shooting",
    "Tortilla sandwich",
    "Sari, e.g.",
    "Alternative to a sandwich",
    "Cover up",
    "End of filming",
    "It precedes a cast party",
    "Wind (up)",
    "Trendy sandwich",
    "Shawl or stole",
    "Conclude, with \"up\"",
    "Shawl, e.g.",
    "End of shooting",
    "Mantle",
    "Tortilla, to a burrito",
    "\"That's a ___!\"",
    "Coat or shawl",
    "Tin foil, e.g.",
    "Bubble ___",
    "Tin foil, e.g.",
    "Shrink-___",
    "Saran, e.g.",
    "Gift ___",
    "Scarf",
    "Stop making a scene?",
    "Call it a day on the set",
    "Bundle up",
    "Bundle",
    "Enclose",
    "Present coverage",
    "Package",
    "Envelop"
  ],
  "hals": [
    "\"Young Man With a Skull\" painter",
    "Dutch artist Frans",
    "Frans ___, \"The Merry Drinker\" painter",
    "\"The Merry Drinker\" painter",
    "Frans who painted \"The Laughing Cavalier\"",
    "\"The Merry Drinker\" painter",
    "Dutch old master Frans",
    "\"Youth With a Skull\" painter",
    "Dutch Golden Age painter",
    "\"Lucas de Clercq\" portraitist, ca. 1635",
    "Descartes portraitist",
    "Dutch Golden Age painter",
    "\"The Laughing Cavalier\" artist",
    "Dutch artist Frans",
    "\"The Merry Drinker\" painter",
    "\"The Merry Drinker\" painter",
    "Dutch painter Frans",
    "\"The Laughing Cavalier\" artist",
    "Portraitist Frans",
    "Dutch portrait painter",
    "Haarlem painter Frans",
    "\"Merry Toper\" painter",
    "Rembrandt contemporary",
    "Dutch artist Frans",
    "Dutch portraitist Frans",
    "Actor Holbrook and others",
    "Dutch painter",
    "\"Shrovetide Revelers\" artist",
    "Rijksmuseum artist",
    "\"The Jolly Trio\" painter",
    "\"Archers of St. George\" artist",
    "Dutch portraitist Frans",
    "\"The Gypsy\" painter",
    "\"The Jolly Toper\" painter",
    "\"Laughing Cavalier\" painter"
  ],
  "jerk": [
    "Contemptible sort",
    "Schlemiel",
    "Yank",
    "Yank",
    "Treat badly",
    "Steve Martin comedy, with \"The\"",
    "So-and-so",
    "Bozo",
    "Ding-a-ling",
    "Creep",
    "Schmo",
    "Yo-yo",
    "Bozo",
    "Bozo",
    "Yo-yo",
    "Be rough with the reins",
    "Schlemiel",
    "Ding-a-ling",
    "Weight lifting maneuver",
    "Yank",
    "Boor"
  ],
  "maia": [
    "Eldest of the Pleiades",
    "Goddess of spring and rebirth",
    "Mother of Hermes",
    "Mother of Hermes",
    "Eldest of the Pleiades",
    "Hermes' mother",
    "Mother of Hermes",
    "Hermes' mother",
    "One of the Pleiades",
    "Mother of Hermes",
    "Mother of Hermes"
  ],
  "weds": [
    "Brings together",
    "Binds",
    "Melds",
    "Joins",
    "Exchanges \"I do's\"",
    "Joins",
    "Links",
    "Joins",
    "Unites",
    "Unites",
    "Gains a 54-Across",
    "Couples",
    "Joins",
    "Joins in holy matrimony",
    "Joins",
    "Joins as a couple",
    "Makes one",
    "Walks down the aisle",
    "Ends an engagement",
    "Forms a union",
    "Takes as one's spouse",
    "Forms a union",
    "Joins",
    "Takes to heart?",
    "Ties the knot",
    "Becomes the other half of?",
    "Joins",
    "Puts together",
    "Puts together",
    "Makes one",
    "Says \"I do\"",
    "Ties the knot with",
    "Marries",
    "Marries",
    "Marries",
    "Joins in holy matrimony",
    "Makes one",
    "Bonds",
    "Unites",
    "Splices",
    "Hooks up",
    "Unites",
    "Hitches",
    "Hitches"
  ],
  "dims": [
    "Brings down",
    "De-lights?",
    "Turns down",
    "Loses luster",
    "Loses brightness",
    "Darkens",
    "Makes less bright",
    "Darkens",
    "Turns down",
    "Darkens",
    "Turns down",
    "Lowers, in a way",
    "Turns down",
    "Turns down",
    "Makes indistinct",
    "Darkens",
    "Darkens",
    "Turns down, in a way",
    "Turns down",
    "Lowers",
    "Lowers, as the lights",
    "Turns down",
    "Lowers, in a way"
  ],
  "rife": [
    "Riddled",
    "Riddled (with)",
    "Loaded (with)",
    "Swarming (with)",
    "Replete",
    "Abundant",
    "Teeming",
    "Teeming",
    "Abounding",
    "Replete",
    "Widespread",
    "Widespread",
    "Bursting (with)",
    "Chockablock",
    "Teeming (with)",
    "Plenteous",
    "Rampant",
    "Filled",
    "Widespread",
    "In great supply",
    "Epidemic",
    "Plenteous",
    "Teeming (with)",
    "Prevalent",
    "In widespread use",
    "Teeming (with)",
    "Prevalent",
    "Teeming",
    "Abundant",
    "Prevalent",
    "Loaded",
    "Overrun",
    "Popping up all over",
    "Abounding",
    "Abundant",
    "Loaded",
    "Abundant",
    "Widespread",
    "Loaded",
    "Rampant",
    "Filled",
    "All over the place",
    "Teeming",
    "Widespread",
    "Abundant",
    "Epidemic",
    "Loaded (with)",
    "Loaded",
    "Abounding",
    "Abounding",
    "Abundant",
    "Prevalent"
  ],
  "salk": [
    "Eponymous founder of a San Diego research institute",
    "San Diego's ___ Institute",
    "Physician Jonas",
    "Kind of vaccine",
    "Kind of vaccine",
    "Polio vaccine developer",
    "Bacteriologist Jonas",
    "La Jolla's ___ Institute for Biological Studies",
    "Jonas who developed a polio vaccine",
    "___ vaccine",
    "Virologist Jonas",
    "Polio vaccine developer",
    "___ vaccine",
    "Polio vaccine developer",
    "Polio vaccine developer",
    "Scientist who won a 1977 Presidential Medal of Freedom",
    "Polio scientist",
    "___ Institute for Biological Studies",
    "Kind of vaccine",
    "Kind of vaccine",
    "Polio vaccine developer",
    "Polio vaccinator",
    "Polio vaccine discoverer",
    "___ vaccine",
    "Polio pioneer"
  ],
  "raja": [
    "Hindi for \"king\"",
    "Indian prince",
    "Indian title",
    "Indian royal",
    "Indian chief",
    "Indian prince",
    "Eastern V.I.P.",
    "Punjabi chief",
    "Bygone Bombay bigwig",
    "___ Rao, \"The Serpent and the Rope\" novelist",
    "Indian nobleman",
    "Delhi cheese?",
    "Indian prince",
    "Indian prince",
    "Big Indian",
    "The Blue ___ (Hank Azaria's \"Mystery Men\" role)",
    "Big Indian",
    "Big Indian",
    "Asian royal",
    "Indian chief",
    "Hindu prince",
    "Important Indian",
    "Indian prince",
    "Delhi prince",
    "Big Indian",
    "Indian prince",
    "Indian king",
    "Indian royal",
    "Prince of India",
    "Indian royal",
    "Indian king",
    "Punjabi prince",
    "Big Indian",
    "Big Indian",
    "King, in Hindi",
    "Big Indian",
    "Hindu nobility",
    "Indian prince",
    "Punjab potentate"
  ],
  "hack": [
    "Illegal accessing, of a sort",
    "Cope, with \"it\"",
    "Life ___ (timesaving trick)",
    "Untalented writer",
    "Cabbie",
    "Unrespected writer",
    "Be a high-tech criminal",
    "Break (into)",
    "Break into, as a computer",
    "Untalented writer",
    "Stomach",
    "Commit a computer crime",
    "Symptom of catarrh",
    "Succeed, with \"it\"",
    "Penny-a-worder, say",
    "Triter writer?",
    "Take an ax to",
    "ATTEMPT A BREAK-IN",
    "Unlikely candidate for a Pulitzer",
    "Unskilled writer",
    "Cabbie",
    "Chop none too carefully",
    "Literary drudge"
  ],
  "fuss": [
    "Kerfuffle",
    "Major to-do",
    "Something \"kicked up\"",
    "Commotion",
    "Hullabaloo",
    "Act the mother hen",
    "Something to kick up",
    "Bother",
    "To-do",
    "Complain",
    "Tempest in a teapot",
    "Bother",
    "Bother",
    "Tempest in a teapot",
    "Act the mother hen",
    "Bother",
    "Ado",
    "Bother",
    "Stew",
    "Ado"
  ],
  "owns": [
    "Accepts accountability for",
    "Admits, with \"up\"",
    "Completely embarrasses, slangily",
    "Totally rules",
    "Beats again and again, in slang",
    "Accepts responsibility for",
    "Takes responsibility for",
    "Has control over",
    "Trounces, slangily",
    "Has",
    "Dominates, informally",
    "Completely dominates",
    "Dominates, informally",
    "Has",
    "Controls",
    "Excels over, in slang",
    "Dominates, informally",
    "Takes responsibility for",
    "Completely dominates",
    "Defeats regularly, in slang",
    "Dominates",
    "Holds the deed to",
    "Possesses",
    "Doesn't rent",
    "Has control over",
    "Controls completely",
    "Has",
    "Holds the title to",
    "Holds the title to",
    "Fesses (up to)",
    "Defeats regularly, in sports lingo",
    "Admits, with \"up\"",
    "Isn't just a licensee",
    "Keeps",
    "Has",
    "Has complete domination over",
    "Has",
    "Holds the deed to",
    "Has",
    "Has",
    "Has",
    "Confesses, with \"up\"",
    "Has power over",
    "Controls",
    "Holds",
    "Has",
    "Acknowledges",
    "Has",
    "Possesses",
    "Has title to",
    "Admits, with \"up\"",
    "Admits (to), with \"up\"",
    "Has control over"
  ],
  "away": [
    "Not around",
    "Vacationing, say",
    "What a robber hopes to get?",
    "On vacation",
    "Like about half the games on a team's schedule",
    "Follower of fire or bombs",
    "Departed",
    "See 34-Across",
    "On vacation",
    "Like a game that's played on the road",
    "Not home",
    "\"Beat it!\"",
    "See 1-Across",
    "See 10-Across",
    "On vacation",
    "Out",
    "On vacation",
    "Out",
    "See 14-Across",
    "Not at home",
    "Like about half of a team's games",
    "Out",
    "Vacationing",
    "Like many games",
    "Not at home",
    "Out of town",
    "Not at home",
    "\"Shoo!\"",
    "Traveling",
    "Not home",
    "Gone",
    "On the road",
    "On the road",
    "On vacation",
    "On the road",
    "Not home",
    "On vacation",
    "Not home",
    "On vacation",
    "Not at home",
    "Not at home",
    "Like some league games",
    "Out of town",
    "On the road",
    "See 11-Down",
    "Kind of team",
    "On vacation",
    "On the road",
    "\"Off with you!\"",
    "Not home",
    "\"Shoo!\"",
    "On vacation",
    "Kind of game",
    "\"Shoo!\"",
    "On holiday",
    "Out of town",
    "Off",
    "Kind of game",
    "On vacation",
    "Kind of game",
    "\"Begone!\"",
    "Not 47 Across",
    "On the road",
    "\"Scram!\"",
    "Not at home",
    "On vacation",
    "On the road",
    "\"___in a manger...\""
  ],
  "moss": [
    "Soft \"seat\" in the woods",
    "Spanish ___",
    "Terrarium growth",
    "Spongy growth",
    "Something soft to sit on",
    "Bryophytic growth",
    "Shade of green",
    "Some greenery that's not grass",
    "Actress Elisabeth who's been on the cover of Rolling Stone, ironically",
    "Velvety growth",
    "What rolling stones don't gather",
    "Shaded growth",
    "Rolling stones lack it",
    "Growth on the forest floor",
    "Spanish ___",
    "Shade similar to artichoke green",
    "Cushiony ground cover",
    "Development on the north side?",
    "Swamp stuff",
    "Tree-hugger?",
    "Moor growth",
    "Nature's pillow?",
    "Shade of green",
    "Peat source",
    "Shade of green",
    "Cushiony forest seat",
    "Forest growth",
    "Trunk growth",
    "Trunk growth",
    "Velvety growth",
    "Trunk growth",
    "Forest growth",
    "Shaded growth",
    "It reproduces by spores",
    "Tree growth",
    "Tree-hugger?",
    "Playwright Hart",
    "Symbol of inactivity",
    "Forest growth",
    "Bryologist's study",
    "Rolling stones lack it",
    "Famous Hart",
    "Growth on the north side of trees",
    "Sphagnum, for one",
    "\"...gathers no___\"",
    "Liverwort kin",
    "Rolling stone's deficiency"
  ],
  "mics": [
    "Things that may be hot or dropped",
    "They get dropped for emphasis",
    "Speakers' aids, in brief",
    "Some lapel attachments"
  ],
  "wasp": [
    "Center of a sting operation?",
    "\"Ant-Man and the ___\" (2018 film)",
    "The \"murder hornet\" is one",
    "Insect that builds a paper nest",
    "Participant in a sting?",
    "Marvel superhero with the power to shrink, with \"the\"",
    "Flying insect with a narrow waist",
    "Stinging insect",
    "Stinger",
    "Stinger with a slender \"waist\"",
    "Big stinger",
    "Insect with a hanging nest",
    "Martin Van Buren was the first president who wasn't one",
    "Social worker?",
    "Female W.W. II enlistee",
    "One might be involved in a sting",
    "Big stinger",
    "Upper-crust sort, stereotypically",
    "Insect with a stinger",
    "Dangerous stinger",
    "Tarantula hawk, e.g.",
    "Direct descendant of the Mayflower Pilgrims, e.g.",
    "Target of many a New Yorker cartoon",
    "Attic nester",
    "Stinging flier",
    "Mud dauber, e.g.",
    "Stinger",
    "Velvet ant",
    "Thin-waisted flier",
    "Yellow jacket",
    "Winged stinger",
    "Colony member",
    "Nest builder in the eaves",
    "Big stinger",
    "Target of some humor in The New Yorker",
    "Ethnic acronym",
    "One of a traditional elite",
    "Insect in a colony",
    "Stinger",
    "Slender-waisted insect",
    "Mud dauber, e.g.",
    "Stinger",
    "Slender-bodied insect",
    "Member of a traditional establishment",
    "Stinger",
    "Kind of waist",
    "Hornet",
    "Big stinger",
    "Stinger",
    "Big stinger",
    "Marmon ___ (first auto to win the Indy 500)",
    "Mandrake the Magician's nemesis"
  ],
  "wash": [
    "Draw, colloquially",
    "Laundry",
    "Load of laundry",
    "Load of laundry",
    "Opposite of dirty",
    "Situation with equal losses and gains",
    "\"___ ME\" (phrase written on dirty cars)",
    "Dry streambed",
    "Laundry",
    "Dry's partner",
    "Do a traditional Monday chore",
    "It may be spinning",
    "Basketful of clothes",
    "Get the grime off",
    "Wadi",
    "Launder",
    "Scrub",
    "Take a 39-Across",
    "Launder",
    "Do the laundry",
    "Break-even enterprise",
    "No-loss, no-gain situation",
    "Laundry",
    "Laundry job",
    "Basketful, maybe",
    "No-loss, no-gain situation",
    "It may get agitated",
    "See 19-Down",
    "Partner of 55-Across",
    "Clothes in a basket, say",
    "Fab place?",
    "Hamper contents",
    "Household chore",
    "Do the dishes",
    "Drawers on a line, e.g.",
    "Wear's partner"
  ],
  "slat": [
    "Strip in the bedroom, maybe",
    "Wood strip",
    "Part of many a rocking chair",
    "Part of a window blind",
    "Strip in a window blind",
    "Venetian blind part",
    "Part of an aircraft wing",
    "Blind spot?",
    "Part of a bed's base",
    "Shutter strip",
    "Louver",
    "Airplane wing feature",
    "Blind side?",
    "Part of a crib",
    "Picket, e.g.",
    "Plane wing part",
    "Baby's crib part",
    "Adirondack chair part",
    "Blind spot?",
    "Crib piece",
    "Blind part",
    "Blind part",
    "Crib part",
    "Crib part",
    "Blind part",
    "Furniture strip",
    "Crib strip",
    "Bed support",
    "Crib unit",
    "Part of an Adirondack chair",
    "Blind spot?",
    "Blind part",
    "Thin strip",
    "Chairmaker's strip",
    "Blind strip",
    "Chair piece",
    "Venetian blind section",
    "Crib side part",
    "Venetian strip",
    "Furniture piece",
    "Adirondack chair element",
    "Unit for a chairmaker",
    "Airplane wing component",
    "Plane wing component",
    "Blind piece",
    "Blind component",
    "Crate part",
    "Part of a picket fence",
    "Blind part",
    "Strip on a bed",
    "Shutter piece",
    "Plane wing part",
    "Bed board",
    "Blind element",
    "Blind part",
    "Part of a blind",
    "Strip under the mattress",
    "Crib component",
    "Adirondack chair part",
    "Plane wing part",
    "Bed board",
    "Louver feature",
    "Crate part",
    "Bed board",
    "Part of a venetian blind",
    "Venetian blind part",
    "Venetian element",
    "It's in a blind",
    "Thin strip",
    "Narrow strip",
    "Bed support",
    "Blind strip",
    "Blind segment",
    "Blind segment",
    "Part of a 58-Across",
    "Part of a lobster pot",
    "Bed board",
    "Crib part",
    "Strip",
    "Blind piece",
    "Strip",
    "Louver part",
    "Blind part",
    "Bed board",
    "Part of a farm feeder",
    "Blind feature",
    "Wood strip used as a bed support",
    "Crate component",
    "Box-spring support",
    "Part of a deck chair",
    "Wooden piece",
    "Chair part",
    "Mattress support",
    "Blinds piece",
    "Blind segment",
    "Jalousie unit",
    "Crate component",
    "Narrow wood piece",
    "Bed support",
    "Stave",
    "Bed support",
    "Box spring support",
    "Bed piece",
    "Blinds crosspiece",
    "Mattress support",
    "Venetian-blind component",
    "Narrow board",
    "Chair support",
    "Chair support",
    "Blind part",
    "Part of a blind",
    "Venetian blind component",
    "Batten",
    "Chair part",
    "Bed-frame crosspiece",
    "Venetian strip",
    "Blind piece",
    "Louver"
  ],
  "dear": [
    "Darling",
    "Letter starter",
    "Cherished",
    "Pricey",
    "Treasured",
    "Darling",
    "Honey substitute?",
    "Precious",
    "Pricey",
    "Lambchop",
    "___ John",
    "___ John letter",
    "Letter opener",
    "Sweets",
    "Sweetie",
    "Honeybunch",
    "Sweetums",
    "With 28-Across, letter opener",
    "Immoderately priced",
    "Honey or sugar",
    "Sweetie",
    "Precious",
    "Precious",
    "Letter starter",
    "Prayer starter, often",
    "Honey",
    "Snookums",
    "Honey",
    "Valued",
    "Beloved",
    "Very expensive",
    "Babycakes",
    "Sweets",
    "Sugar",
    "Snookums",
    "\"___ Abby\"",
    "Angel",
    "Bosom",
    "Sugar",
    "Honeybunch",
    "Letter starter",
    "\"___ me\"",
    "Pet",
    "Cherished",
    "Pricey",
    "Pricey",
    "Sweetheart",
    "Turtledove",
    "Steep",
    "Baby",
    "\"My little chickadee\"",
    "Salutation starter",
    "Tootsie",
    "___ John",
    "\"___ me!\"",
    "Letter opener",
    "Sweetie",
    "Expensive",
    "Letter opener",
    "Sweetums",
    "Start of a diary entry",
    "Costly",
    "\"___ Diary ...\"",
    "Cupcake",
    "Honey",
    "Dollface",
    "Sweetie",
    "Not cheap",
    "Puddin'",
    "Turtledove",
    "Honeybun",
    "Turtledove",
    "Cherished",
    "Having great value",
    "Sweet",
    "Honeybun",
    "Cherished",
    "Precious",
    "Honeybunch",
    "Billet-doux beginner",
    "Darling",
    "Valuable",
    "Honey",
    "Cherished",
    "Expensive",
    "Honeybunch",
    "Expensive",
    "Steep",
    "Cherished",
    "Honeybunch",
    "Honey",
    "Sugar substitute?",
    "Cherished",
    "Honeybunch",
    "___ John",
    "Prized",
    "\"___ Abby\"",
    "Sweetie",
    "\"___ Diary...\"",
    "Sweetie"
  ],
  "dame": [
    "Female equivalent of a knight",
    "Helen Mirren or Judi Dench",
    "Julie Andrews or Helen Mirren",
    "Title for Judi Dench",
    "Lady's title",
    "Woman with a title",
    "Agatha Christie or Maggie Smith",
    "Maggie Smith, for one",
    "Title for Helen Mirren",
    "One appointed to the Royal Victorian Order",
    "Helen Mirren, e.g.",
    "Woman in a hard-boiled detective story",
    "Daphne du Maurier, e.g.",
    "Angela Lansbury, e.g.",
    "Agatha Christie title",
    "Broad",
    "\"Baa, Baa, Black Sheep\" figure",
    "Julie Andrews, for one",
    "\"There Is Nothin' Like a ___\" (\"South Pacific\" song)",
    "Lady of the knight?",
    "Title for Helen Mirren",
    "Agatha Christie title",
    "Broad",
    "With 116-Across, saucy Aussie",
    "Broad",
    "Notre ___",
    "British title",
    "British title",
    "Broad",
    "Melba, e.g.",
    "Broad",
    "British title",
    "Woman of distinction",
    "Notre ___",
    "Titled lady",
    "Actress Maggie Smith, e.g.",
    "Honored woman",
    "Titled lady",
    "Title for Agatha Christie",
    "British title",
    "Judi Dench, for one",
    "Gentlewoman",
    "Woman of rank",
    "Honored lady",
    "Female V.I.P.",
    "Broad",
    "Agatha Christie, e.g.",
    "Knight's wife, in olden times",
    "Lady's title",
    "Woman of distinction",
    "British title",
    "Edith Evans, for one",
    "Title for Kiri Te Kanawa",
    "Lady's title",
    "Nellie Melba, e.g.",
    "\"There is Nothin' Like a ___\"",
    "Peggy Ashcroft's title",
    "Baronet's wife",
    "\"There Is Nothin' Like a ___\"",
    "Lady of the knight",
    "Edith Evans, e.g."
  ],
  "rile": [
    "Irritate",
    "Word ladder, part 2",
    "Agitate",
    "Anger",
    "Anger",
    "Tick off",
    "Get to",
    "Tick off",
    "Bring to anger",
    "Work (up)",
    "Work (up)",
    "Tick off",
    "Tick off",
    "Get under the skin of",
    "Agitate, with \"up\"",
    "Tick off",
    "Vex",
    "Tick off",
    "Upset",
    "Really bug",
    "Perturb",
    "Tick off",
    "Cheese off",
    "Incense",
    "Make hot",
    "Tick off",
    "Agitate",
    "Wind up",
    "Peeve",
    "Inflame, with \"up\"",
    "Get to",
    "Make angry",
    "Ladder, part 6",
    "Get in a lather",
    "Put out",
    "Put out",
    "Antagonize",
    "Honk off",
    "Nettle",
    "Rub the wrong way",
    "Vex",
    "Get to",
    "Nettle",
    "Stir up",
    "Chafe",
    "Stir (up)",
    "Agitate",
    "Vex",
    "Make stew?",
    "Irritate",
    "Push too hard, maybe",
    "Anger",
    "Vex",
    "Stir up",
    "Stir (up)",
    "Vex",
    "Get to",
    "Bring to a boil",
    "Annoy",
    "Tee off",
    "Rub the wrong way",
    "Rub the wrong way",
    "Vex",
    "Disturb",
    "Stir up",
    "Peeve",
    "Anger, with \"up\"",
    "Tick off",
    "Stir up",
    "Stir",
    "Stir up",
    "Tee off",
    "Stir up",
    "Steam up",
    "Irritate",
    "Get to",
    "Agitate",
    "Nettle",
    "Anger, with \"up\"",
    "Tee off",
    "Nettle",
    "Tick off",
    "Rub the wrong way",
    "Irritate",
    "Anger",
    "Agitate",
    "Tick off",
    "Rub the wrong way",
    "Get to, so to speak",
    "Tick off",
    "Tee off",
    "Nettle",
    "Tick off",
    "Make angry",
    "Bug",
    "Provoke",
    "Madden",
    "Incense",
    "Heat up",
    "Stir up",
    "Irk",
    "Irritate",
    "Rankle",
    "Stir up",
    "Make mad",
    "Vex",
    "Get one's goat",
    "Irritate",
    "Annoy"
  ],
  "purr": [
    "Car or cat sound",
    "Speak softly with contentment",
    "Sign of cat love",
    "Reaction to scritches, maybe",
    "Sound of contentment",
    "Engine sound",
    "Engine sound",
    "Respond to a belly rub, say",
    "Contented cat sound",
    "What cats and some engines do",
    "Himalayan production",
    "Result of some heavy petting?",
    "Run smoothly",
    "Say sexily",
    "Pet sound",
    "Copy cats?",
    "Sign of a contented cat",
    "Sound from a sex kitten?",
    "Engine sound",
    "What gentle strokes may produce",
    "Run perfectly",
    "Contented sound",
    "Sound from a Burmese",
    "Say coquettishly",
    "Petted pet's sound",
    "Good engine sound",
    "Run smoothly",
    "Engine sound",
    "Sound of contentment",
    "\"I'm happy\" in Siamese?",
    "Sound of a tuned engine",
    "Sound of a fine-tuned engine",
    "Persian output",
    "Manx \"Thanks!\"",
    "Tabby talk",
    "Sound of a cat or engine",
    "Sound of contentment",
    "Result of tummy rubbing?",
    "Contented sound",
    "Rumble of contentment",
    "Good engine sound"
  ],
  "chao": [
    "Former transportation secretary Elaine",
    "Transportation secretary Elaine",
    "Elaine ___, cabinet member for both Bush and Trump",
    "Bush's labor secretary",
    "Elaine ___, first female Asian-American cabinet member",
    "Elaine ___, George W. Bush's only labor secretary",
    "Secretary of Labor Elaine",
    "___ Phraya (Asian river)"
  ],
  "lats": [
    "Back muscles, in brief",
    "Pull-up muscles, briefly",
    "Muscles worked by pull-ups, briefly",
    "Back muscles, briefly",
    "Workout target, for short",
    "Workout target",
    "Back muscles, for short",
    "Back muscles, for short",
    "Back muscles, for short",
    "Chin-up targets, briefly",
    "Chin-ups and pull-ups develop them",
    "Focus of some workouts",
    "Weightlifters build them, for short",
    "Rowers' muscles, for short",
    "Back muscles, for short",
    "Arm movers, briefly",
    "Side muscles, for short",
    "Some muscles, informally",
    "Back muscles, for short",
    "Back muscles, for short",
    "Bodybuilders build them",
    "Back muscles, for short"
  ],
  "pole": [
    "Connector between levels of a fire station",
    "Trolley car feature",
    "Flag holder",
    "Fishing tool",
    "Tree : Christmas :: ___ : Festivus",
    "Szczecin resident",
    "John Paul II, e.g.",
    "Strip club fixture",
    "Barbershop symbol",
    "John Paul II, e.g.",
    "Ladder, part 4",
    "Firehouse fixture",
    "John Paul II, e.g.",
    "Copernicus, e.g., by birth",
    "Decathlete's implement",
    "It may be + or -",
    "Subway car feature",
    "Gondolier's need",
    "Flag holder",
    "Gondolier's need",
    "Barber's emblem",
    "Extremity of the earth",
    "Make a gondola go",
    "Cold spot",
    "It's the end of the world",
    "Plum Nascar position",
    "Fishing need",
    "Vaulter's tool",
    "Kind of position",
    "Pool worker's aid",
    "Spar, e.g.",
    "John Paul II, e.g.",
    "Barbershop symbol",
    "Vault opener?",
    "Cold place",
    "End of the world?",
    "Barbershop emblem",
    "Explorer's quest, with \"the\"",
    "___ vault",
    "Gondolier's need",
    "Fishing need",
    "Tent support",
    "Gondola propeller",
    "John Paul II, e.g.",
    "Decathlete's need",
    "+ or -",
    "Barbershop emblem",
    "Either end of a magnet",
    "True north spot",
    "Axis end",
    "Axis end",
    "May, for one"
  ],
  "move": [
    "Change domiciles",
    "Relocate",
    "\"You're in my spot!\"",
    "With 40-Across, comment to someone who 30-Down",
    "Affect",
    "With 20-Across, relocate",
    "Touch emotionally",
    "Play in a game",
    "Shake a leg",
    "Propose in a meeting",
    "\"Gangway!\"",
    "Dance element",
    "Really touch",
    "Transport",
    "\"Outta my way!\"",
    "Bxe5 or 0-0-0, in chess",
    "Qxe5, e.g., in chess",
    "With 50-Across, surmount",
    "Turn in a game",
    "\"Outta my way!\"",
    "Relocate",
    "QxQ, e.g., in chess",
    "\"Shake a leg!\"",
    "Turn",
    "Budge",
    "Order from a person with a gun",
    "Pull up stakes",
    "Bit of game play",
    "Don't just sit there",
    "\"Get out of my seat!\"",
    "Propose at a meeting",
    "Impatient order",
    "Player's turn",
    "Bring to tears",
    "\"Your ___\"",
    "Change places",
    "\"Get going!\"",
    "Shake a leg",
    "Cop's order",
    "Actuate",
    "Give way",
    "Make a proposal",
    "Military command?"
  ],
  "youd": [
    "\"___ be surprised\"",
    "\"___ be surprised\"",
    "\"___ be surprised\"",
    "Irving Berlin's \"___ Be Surprised\"",
    "Common contraction",
    "\"___ better believe it!\"",
    "\"___Be So Nice to Come Home To\""
  ],
  "bali": [
    "Much-visited Indonesian isle",
    "It became a province of Indonesia in 1958",
    "Indonesian tourist destination",
    "Java neighbor",
    "Island with volcanoes and rice paddies",
    "So-called \"Island of the Gods\"",
    "So-called \"Island of the Gods\"",
    "Island known for its meditation retreats",
    "Indonesian tourist destination",
    "\"___ Ha'i\" (\"South Pacific\" song)",
    "East Indies tourist destination",
    "Indonesian island",
    "Island in the Coral Triangle",
    "Tourist haven with the capital Denpasar",
    "Java neighbor",
    "East Indies tourist destination",
    "1952 Hope/Crosby \"Road\" movie destination",
    "*Exotic getaway spot",
    "Indonesian tourist destination",
    "Island served by both AirAsia and Qantas airlines",
    "\"Eat, Pray, Love\" setting",
    "Hindu part of Indonesia",
    "Indonesian tourist haven",
    "\"Eat, Pray, Love\" locale",
    "Indonesian tourist mecca",
    "48-Across east of Java",
    "\"South Pacific\" setting",
    "Island with a Hindu majority",
    "The so-called Island of the Gods",
    "\"___ Ha'i\" (\"South Pacific\" song)",
    "Storied isle near Java",
    "Neighbor of Java",
    "2,100-square mile island with six volcanoes",
    "Indonesian vacation spot",
    "Tourist haven east of Java",
    "Java neighbor",
    "Hope/Crosby \"Road\" destination",
    "Java neighbor",
    "Denpasar is its capital",
    "Island with a Hindu majority",
    "A Bing Crosby/Bob Hope film locale",
    "Java neighbor",
    "\"___ Ha'i\" (\"South Pacific\" song)",
    "Island east of Java",
    "Mt. Agung locale",
    "Card game named for an island",
    "Neighbor of Java",
    "\"Road\" picture destination",
    "Part of Indonesia",
    "1952 Hope/Crosby \"Road\" destination",
    "Island near Java",
    "Vacation isle",
    "Neighbor of Java",
    "Exotic vacation spot",
    "?ô___ Ha?Æi?ö",
    "Java neighbor",
    "Island east of Java",
    "Island east of Java",
    "\"___ Ha'i\"",
    "Indonesian vacation destination",
    "\"Road\" film destination",
    "Island near Java",
    "Indonesian tourist destination",
    "Lesser Sunda island",
    "Indonesian island",
    "Hope/Crosby's \"Road to ___\"",
    "Java neighbor",
    "Island east of Java",
    "\"___ Ha'i\"",
    "Java neighbor",
    "Java neighbor",
    "Hope/Crosby \"Road\" destination",
    "Where Singaraja is",
    "Island near Java",
    "Island east of Java",
    "\"Road\" picture destination",
    "\"Road\" film destination",
    "South Seas paradise",
    "Island south of Borneo",
    "Hope-Crosby's \"Road to ___\"",
    "Island named for a figure in Hindu myth",
    "Neighbor of Java",
    "Hope-Crosby film destination",
    "Indonesian island",
    "Java neighbor",
    "Singaraja is its capital",
    "Island near Java",
    "\"Road\" picture destination",
    "Java neighbor",
    "Site of a \"Road\" film",
    "Java neighbor",
    "Singaraja's island"
  ],
  "ecce": [
    "\"___ Agnus Dei\" (Mass phrase)",
    "\"___ homo\"",
    "Latin \"Lo!\"",
    "Behold, to Brutus",
    "\"___ homo\"",
    "\"___ homo\"",
    "\"___ homo\"",
    "\"Behold!,\" to Caesar",
    "\"Look!,\" to Livy",
    "Behold, to Cicero",
    "\"Behold,\" to Brutus",
    "Colosseum cry",
    "\"___ homo\"",
    "\"Behold,\" to Brutus",
    "\"Behold,\" to Caesar",
    "\"Look!,\" in Latin",
    "___ signum (look at the proof: Lat.)",
    "\"___ homo\"",
    "\"___ homo\"",
    "\"Behold!,\" to Pilate",
    "Biblical trial word",
    "Behold, to Brutus",
    "Roman cry",
    "\"___ homo\"",
    "\"___ homo\" (declaration in John 19:5)",
    "\"___ homo\"",
    "Latin \"behold\"",
    "\"Behold,\" to Cicero",
    "\"___ homo\" (cry in John 19:5)",
    "Latin \"behold!\"",
    "Rossetti's \"___ Ancilla Domini\"",
    "___ homo",
    "Old Roman cry",
    "\"___ homo\"",
    "Old Roman cry",
    "___ signum (here is the proof)",
    "\"___ homo\"",
    "Livy?Æs ?ôLo!?ö",
    "___ signum (here is the proof)",
    "\"___ homo\" (Pilate's words)",
    "Brutus's \"Behold!\"",
    "Look, at the Forum?",
    "___ signum",
    "\"___ Homo\" (Titian painting)",
    "Behold, to Pilate",
    "With 36-Across, a depiction of Jesus",
    "\"___ agnus Dei\"",
    "___ signum (here is the proof)",
    "\"Behold!\"",
    "\"___ homo\" (declaration in John 19:5)",
    "Word from Pontius Pilate",
    "Behold, in old Rome",
    "Behold, in old Rome",
    "\"___ homo\"",
    "\"___ agnus Dei\" (Christian phrase)",
    "Behold, to Pilate",
    "\"Behold,\" to Boethius",
    "___homo (biblical phrase)",
    "\"___homo!\"",
    "___homo",
    "___ homo",
    "___ homo",
    "Behold, to Brutus",
    "___ homo",
    "___ signum",
    "___ homo",
    "___ homo",
    "Nietzsche's \"___ Homo\"",
    "\"Behold!\""
  ],
  "hasp": [
    "A chest often has a large one",
    "Treasure chest feature",
    "Feature of many a diary",
    "Lock securer",
    "Trunk fastener",
    "Shed door feature",
    "Part of a locker",
    "Part of a treasure chest",
    "Padlock's place",
    "Padlock holder",
    "Padlock holder",
    "Trunk part",
    "Door securer",
    "Metal fastener",
    "Door fixture",
    "Shutter shutter",
    "Barn door fastener",
    "Lock fastener",
    "Securing device",
    "Lock securer",
    "Gate hardware",
    "Door fixture",
    "Lock plate",
    "Lock part",
    "Door fastener",
    "Diary fastener",
    "Hinged closer",
    "Hinged fastener",
    "Hinged fastener",
    "Trunk closer",
    "Padlock holder",
    "Gate fastener",
    "Locker",
    "Lock securer",
    "Trunk fastener",
    "Door securer",
    "Lock location",
    "Door fixture",
    "Padlock piece",
    "Door fastener",
    "Fastener piece",
    "Place for a padlock",
    "Door fixture",
    "Lock holder",
    "When pinned, it's a lock",
    "Door latch",
    "Padlock's partner",
    "A pin may go through it",
    "Locking device",
    "What a padlock may fasten",
    "Lock part",
    "Lid fastener",
    "Door fastener",
    "Closer",
    "Fastening device",
    "Lid fastener",
    "Door holder",
    "It's a lock",
    "Fastener",
    "Door securer",
    "Fastening device"
  ],
  "veep": [
    "HBO satire starring Julia Louis-Dreyfus",
    "Number two",
    "HBO series about the politician Selina Meyer",
    "Hit HBO show for Julia Louis-Dreyfus",
    "HBO political satire",
    "HBO hit starring Julia Louis-Dreyfus",
    "#2 exec",
    "Prez's #2",
    "No. 2",
    "#2",
    "Resident of D.C.'s Observatory Circle",
    "Prez's #2",
    "Senate tie breaker",
    "27-Across, e.g., informally",
    "#2",
    "Number two",
    "L.B.J. or R.M.N., once",
    "Prez's backup",
    "Bush, in the 80's",
    "Prez's #2",
    "Nixon, once",
    "Dick Cheney, e.g.",
    "#2",
    "40-Across, informally",
    "Prez's stand-in",
    "Mondale or Quayle, e.g.",
    "Number two",
    "No. 2",
    "Agnew, once",
    "Prez's helper",
    "#2, informally",
    "Prez's stand-in"
  ],
  "brow": [
    "One might be raised by a skeptic",
    "A skeptic may raise one",
    "Something that's knitted",
    "Head piece?",
    "Eye liner?",
    "It's often knitted",
    "Sweat spot",
    "Arch above the eye",
    "It's arched above the eye",
    "Furrowed part of the head",
    "Knitted body part",
    "Forehead",
    "Furrowed part of the head",
    "Perspiration location",
    "It may be furrowed",
    "It may be wiped",
    "It may be mopped",
    "Sweat site",
    "Knitted item",
    "It's often wiped",
    "It might be arched"
  ],
  "days": [
    "What \"M\" and \"F\" are both short for",
    "Word after old or dog",
    "Salad ___",
    "___ Inn",
    "February has the fewest of them",
    "Standard work shifts",
    "Standard work shifts",
    "Tally on a prison wall",
    "They break at dawn",
    "Components of S-M-T-W-T-F-S",
    "Calendar units",
    "Spin cycles?",
    "Calendar components",
    "___ Inn",
    "Time pieces",
    "Prisoner's tally on a cell wall",
    "Word with \"Happy\" and \"Death Valley\" in old TV titles",
    "During working hours",
    "Calendar units",
    "Boxes of calendars?",
    "Quite a while",
    "From nine to five, in the classifieds",
    "During office hours",
    "Dog___",
    "Salad ___",
    "April hath 30",
    "Calendar pages",
    "TV's \"___ of Our Lives\"",
    "During office hours"
  ],
  "boys": [
    "Lizzo title lyric repeated three times before \"Make a girl go crazy\"",
    "Target demographic for Hot Wheels",
    "Word before and after \"will be\"",
    "All Harrow students",
    "All students at Eton",
    "Word before and after \"will be\"",
    "School door sign",
    "Word repeated in \"___ will be ___\"",
    "Lost ___ (\"Peter Pan\" group)",
    "Word before and after \"will be\"",
    "Most Little Leaguers",
    "Word before and after \"will be\"",
    "\"The History ___\" (Tony-winning play)",
    "Word before and after \"will be\"",
    "Restroom label",
    "Sign on a grade school door",
    "They're represented by blue",
    "Word before and after \"will be\"",
    "Some scouts",
    "___ Town",
    "Grade school door sign",
    "Word before and after \"will be\"",
    "Lads",
    "\"The ___ of Summer\"",
    "\"The ___ From Brazil\""
  ],
  "lisp": [
    "Sigmatism, by another name",
    "Misarticulate, in a way",
    "What Winthrop speaks with in \"The Music Man\"",
    "Tip-of-the-tongue phenomenon",
    "Not get some Z's, say?",
    "Oratory obstacle",
    "Sylvester's speech feature",
    "Imitate Daffy Duck",
    "Discuss thickness with a doctor?",
    "Sigmatism",
    "Thpeak like thith",
    "Have trouble with sass?",
    "Not be able to say \"say,\" say",
    "Challenge for a speech coach",
    "Someone who has it can't say it",
    "Favor a \"th\" sound",
    "Speak like Sylvester",
    "Winthrop's affliction in \"The Music Man\"",
    "*Not get some Z's?",
    "Daffy Duck has one",
    "Daffy Duck trademark",
    "Orator's challenge",
    "Talk like Daffy",
    "Slip of the tongue, maybe",
    "Say \"thay,\" e.g.",
    "Beth for Bess, e.g.",
    "Pronunciation difficulty",
    "Sibilant talk",
    "Daffy characteristic",
    "One way to make a sinner thinner",
    "Subject for a speech therapist",
    "Talk like Daffy",
    "Topic in speech therapy",
    "Saying \"th\" for \"s\"",
    "Speech problem",
    "Have trouble with assessors?",
    "Speak like Sylvester",
    "Imperfect speech",
    "Result of lingual protrusion",
    "Challenge for a speech coach",
    "Job for a speech coach",
    "Daffy Duck's impediment",
    "Have trouble with esses",
    "Speech problem",
    "Say \"z\" imperfectly",
    "Have trouble with sisters?",
    "Speech impediment",
    "Sigmatism",
    "Tot's talk, perhaps",
    "Speech problem",
    "Speaketh?",
    "Daffy Duck talk",
    "Kiddie talk?"
  ],
  "scum": [
    "Floating green layer",
    "Pond film",
    "Buildup in a fish tank",
    "Riffraff",
    "Lowest of the low",
    "Lowlife",
    "Lowlife",
    "Dregs of society",
    "It may be found on a tank",
    "Disgusting film",
    "Dirty film",
    "Cleaner target",
    "Film you don't want to see",
    "Pond film",
    "Refuse",
    "Pond gunk",
    "Cleaning target",
    "Soap ___ (shower buildup)",
    "Ugly film",
    "Pond gunk",
    "Pond dross",
    "Lowlife",
    "Pond gunk",
    "Film on ponds",
    "Riffraff",
    "Society's dregs",
    "Riffraff",
    "Lowlife",
    "Riffraff",
    "Riffraff",
    "Refuse",
    "Pond dross",
    "Riffraff",
    "Spray cleaner target",
    "Lowlife"
  ],
  "slob": [
    "Annoying roommate, maybe",
    "Pig",
    "Neatnik's opposite",
    "Slovenly type",
    "Gross figure",
    "Pig",
    "One covered with food stains, say",
    "Mess maker",
    "Neatnik's opposite",
    "One who's slow to pick things up?",
    "Home wrecker?",
    "Problematic roomie",
    "Challenging employer for a maid",
    "Neatnik's opposite",
    "One whose work is not picking up?",
    "Nonideal roommate",
    "Neatnik's opposite",
    "Hardly a natty dresser",
    "Barney Gumble of \"The Simpsons,\" e.g.",
    "Pig",
    "Person who uses a sleeve for a napkin, say",
    "Neatnik's opposite",
    "Sty resident",
    "One whose shirttail is always untucked, maybe",
    "One likely to leave things out?",
    "No neatnik",
    "Person with no hang-ups?",
    "Neatnik's opposite",
    "Neatnik's opposite",
    "Napkin eschewer, say",
    "No neatnik",
    "Untidy one",
    "Litter leaver",
    "Undesirable roomie",
    "Pig",
    "No neatnik",
    "Messy dresser",
    "One whose business isn't picking up?",
    "Hardly a neatnik",
    "One whose business isn't picking up",
    "Neatnik's opposite",
    "Messy dresser",
    "Pig",
    "Hardly a neatnik",
    "Vulgarian",
    "Neatnik's opposite",
    "Pig",
    "Hardly a fop",
    "Dinner's often on him",
    "Neatnik's counterpart",
    "Unwelcome roommate, perhaps",
    "Bachelor, stereotypically",
    "Oscar Madison, e.g.",
    "Not a neatnik",
    "Pig",
    "Poor fellow"
  ],
  "auer": [
    "Violinist Leopold",
    "Violinist Leopold",
    "Violin virtuoso Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violin virtuoso Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "\"Rhapsodie Hongroise\" composer",
    "Teacher of Heifetz",
    "Oscar nominee for \"My Man Godfrey\"",
    "Zimbalist's violin teacher",
    "Oscar-nominated \"My Man Godfrey\" actor, 1936",
    "Violinist Mischa",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Violinist Leopold",
    "Teacher of Heifetz",
    "Teacher of Heifetz",
    "Violinist Leopold",
    "Comic Russian actor Mischa",
    "Violinist Leopold",
    "Actor Mischa"
  ],
  "pews": [
    "Places for congregations",
    "Basilica benches",
    "Benches along an aisle",
    "Seats for parishioners",
    "Places for parishioners",
    "Bible supporters, often",
    "They provide backing for churchgoers",
    "Worshipers' seats",
    "Nave furniture",
    "Church seats",
    "Places for flocks",
    "Hymnals' storage spots",
    "Mass seating",
    "Flock's locale",
    "Sunday seats",
    "Sunday seats",
    "Congregation's location",
    "Sunday seats",
    "Mass seating",
    "Church benches",
    "Church seating",
    "Where hymnals are stored",
    "Seats with kneelers",
    "Seats with kneelers",
    "Church seats",
    "Chairs for prayers?",
    "Seats with cushions",
    "Sunday seats",
    "They hold hymnals"
  ],
  "foie": [
    "With 9-Down, goose liver",
    "___ gras",
    "___ gras",
    "Liver by the Loire?",
    "Pate base",
    "Source of pate",
    "Pate de ___ gras",
    "Liver in Lyon",
    "___ gras",
    "Liver at the Louvre",
    "Pate base",
    "Liver, in Le Havre",
    "Liver, in Lyon"
  ],
  "rase": [
    "Knock down, in Britain",
    "Demolish, British-style",
    "Level, to a Brit",
    "Tear down, in Tottenham",
    "Bring down, in England",
    "Destroy, in Devon",
    "Flatten, in Falmouth",
    "Level, in London",
    "Level, in London",
    "Flatten, in Britain",
    "Tear down, in England",
    "Level, in Leeds"
  ],
  "reqs": ["Academic musts, for short", "Not electives: Abbr."],
  "tedx": ["Lecture series focused on \"ideas worth spreading\""],
  "gras": [
    "See 22-Across",
    "Mardi ___",
    "Foie ___",
    "Fat, to Francois",
    "Fat: Fr.",
    "Mardi ___",
    "Lundi ___",
    "Mardi follower",
    "Foie ___",
    "Fat, to Francois",
    "Pate de fois ___",
    "Mardi ___",
    "Mardi ___",
    "Pate de foie ___",
    "Jours ___ (carnival days): Fr.",
    "Foie ___",
    "Mardi ___",
    "Lundi ___",
    "Fat, in France",
    "Mardi ___",
    "Mardi ___",
    "Mardi ___",
    "Not petit",
    "It comes after Mardi",
    "Pate de foie ___",
    "Mardi___",
    "Mardi___",
    "Mardi ___",
    "Fat, in France"
  ],
  "blow": [
    "Lose after being way ahead, as a lead",
    "Severe disappointment",
    "Crushing setback",
    "Huff and puff",
    "Setback",
    "Squander",
    "Serious punch",
    "Erupt",
    "Squander",
    "Sudden misfortune",
    "What winds do",
    "Diddle away",
    "Hard hit",
    "Squander",
    "Miss, as a chance",
    "Explode",
    "Big wind",
    "Squander",
    "Completely bungle",
    "Whammy",
    "Explode, as a volcano",
    "Squander",
    "Severe setback",
    "Ruin big-time",
    "Totally botch",
    "Big storm",
    "With 43-Across, use bubble gum?",
    "Throw away",
    "Gust",
    "Hard punch",
    "Joe ___",
    "Lick",
    "Gale",
    "Sudden shock",
    "Severe disappointment",
    "Crushing news"
  ],
  "burp": [
    "Emanation from Barney Gumble on \"The Simpsons\"",
    "Pat on the back, maybe",
    "Rude sound at the dinner table",
    "Pat on the back",
    "Let the air out?",
    "Bit of dinner impoliteness",
    "Reason for saying \"Pardon me\"",
    "\"Excuse you!\" elicitor",
    "Pat on the back, as a baby",
    "Cause for an \"Excuse me!\"",
    "Tupperware sound",
    "Pat on the back?",
    "Pat on the back?",
    "Pat on the back, as a baby",
    "Pat on the back",
    "Pat baby on the back"
  ],
  "bsix": [
    "Vitamin that could also be a bingo call",
    "Vitamin whose name rhymes with a car engine",
    "Vitamin whose name sounds like a bingo call"
  ],
  "imok": [
    "\"Nothing's broken\"",
    "\"Don't worry about me\"",
    "Reassuring words after an accident",
    "\"Don't worry, everything's fine\"",
    "\"Don't worry about me!\"",
    "\"How are you?\" response",
    "Response to \"You hurt?\"",
    "\"Don't worry about me!\"",
    "\"Don't worry about me!\"",
    "\"Nothing's broken\"",
    "\"Don't worry about me\"",
    "Reassuring reply",
    "Reassuring comment after a fall",
    "\"Everything's fine, thanks\"",
    "\"Don't worry about me\"",
    "\"No problem here\"",
    "\"Don't worry about me\"",
    "Response to \"How are you?\"",
    "\"Don't worry about me\"",
    "Reassuring words after an accident",
    "Comment after an accident",
    "\"Nothing's broken\"",
    "Comment after an accident",
    "Reassuring words",
    "Comment after an accident",
    "Reassuring response",
    "Reply to \"Are you hurt?\"",
    "Words of reassurance"
  ],
  "celt": [
    "Druid, e.g.",
    "Roman's foe in the Gallic Wars",
    "European of the Iron Age",
    "Speaker of Welsh or Breton",
    "TD Garden athlete, informally",
    "Influence for Enya, historically",
    "Knick rival",
    "Sixer rival",
    "Druid, e.g.",
    "Bill Russell or Larry Bird, briefly",
    "Boston player, for short",
    "Gaelic speaker",
    "Hibernian, for one",
    "European of the Iron Age",
    "Bird, e.g., once",
    "Boston cager, briefly",
    "Invader of Rome in 390 B.C.",
    "Knick rival",
    "Knick rival",
    "Boston cager, informally",
    "Druid, for one",
    "Irish native",
    "Bygone Briton",
    "Saxon foe",
    "Irishman, e.g.",
    "Gael, for one",
    "FleetCenter player, for short",
    "Irishman or Welshman",
    "Boston player, for short",
    "Boston player, informally",
    "Northwest European",
    "Breton, for one",
    "Stonehenge builder",
    "Scot, for one",
    "Welshman or Scot",
    "Scot or Breton, e.g.",
    "Boston athlete",
    "Cornishman",
    "Druid, e.g."
  ],
  "kola": [
    "Kind of nut with caffeine",
    "Nut chewed as a stimulant",
    "Kind of nut",
    "___ nut",
    "___ nut",
    "Caffeine source",
    "Rain forest nut",
    "Caffeine-laden nut",
    "African tree cultivated for its nuts",
    "Caffeine nut",
    "Caffeine-yielding nut",
    "Caffeine nut",
    "Nut with caffeine",
    "Nut for caffeine?",
    "Kind of nut",
    "Stimulating nut",
    "___ nut",
    "Caffeine source",
    "Caffeine nut",
    "Kind of nut",
    "___ nut (caffeine source)",
    "Caffeine source",
    "Nut used to flavor Coke",
    "Source of caffeine",
    "Caffeine-rich nut",
    "Kind of nut",
    "Kind of nut",
    "Kind of nut",
    "Lapland peninsula",
    "Kind of nut",
    "Beverage nut",
    "Soft drink nut"
  ],
  "mood": [
    "Emotional state",
    "What a smiley or frowny emoji indicates",
    "Mind-set",
    "An emoji may suggest it",
    "What an emoji might reveal",
    "What an emoji depicts",
    "Kind of music",
    "It might be set with candles",
    "Emotional state",
    "What an emoji might indicate",
    "Sullen state of mind",
    "Grammatical concept",
    "It may be happy or grumpy",
    "It may be indicated with a ring",
    "It may be set with candlelight",
    "Subjunctive, e.g.",
    "Temper",
    "Kind of lighting",
    "Something that swings",
    "___ ring",
    "Frame of mind",
    "It may be set with music",
    "Word before ring or swing",
    "State of mind",
    "General emotional state",
    "Frame of mind",
    "State of mind",
    "Atmosphere",
    "Glenn Miller's \"In the ___\"",
    "State of mind",
    "Temper",
    "Doldrums, for one",
    "Temper",
    "In the ___",
    "Anticipation or sadness, e.g.",
    "Kind of ring or swing",
    "What some rings measure",
    "It may be imperative",
    "It may swing",
    "Kind of ring or swing",
    "Happy or sad feeling",
    "General feeling",
    "\"___ Indigo\"",
    "Pervading tone",
    "Mind-set",
    "Emotional tone",
    "\"___ Indigo\"",
    "Temperament"
  ],
  "pure": [
    1,
    "Morally uncompromised",
    "Unadulterated",
    "Unalloyed",
    "Unsullied",
    1,
    "Without any filler",
    "Sheer",
    "Unsullied",
    1,
    "Utter",
    1,
    "*100%",
    1,
    "Chaste",
    "Unadulterated",
    "Utter",
    "Out-and-out",
    "Unadulterated",
    "Untarnished",
    1,
    "Unadulterated",
    "Spotless",
    "Unadulterated",
    "24-karat",
    "24-karat",
    "Unsullied",
    "Like 24-karat gold",
    "24-karat",
    "Utter",
    "Like the driven snow",
    "Containing no admixtures",
    1,
    "Like Galahad",
    "24-karat, goldwise",
    "Unalloyed",
    "Claim on an orange juice carton",
    "Undiluted",
    "Untouched",
    "Unmixed",
    "Unvarnished",
    "All-natural"
  ],
  "alps": [
    "Mont Blanc and Matterhorn",
    "Where edelweiss grows",
    "Mont Blanc's range",
    "Big skiing destination",
    "Feature of the Swiss Miss logo",
    "Swiss peaks",
    "Swiss peaks",
    "Bavaria's locale",
    "Locale of 10 Winter Olympics",
    "Natural barrier that Hannibal surmounted",
    "Mountains seen in \"The Sound of Music\"",
    "Range for 1-Across",
    "Destination for European hikers",
    "Swiss range",
    "Range for yodelers",
    "Tour de France setting",
    "Weisshorn and others",
    "High points of a European vacation?",
    "Frequent Winter Olympics setting",
    "Yodeling locale",
    "Range of the von Trapp singers",
    "Locale for 10 Winter Olympics",
    "Mont Blanc and Matterhorn",
    "Tour de France mountains",
    "Monch and Eiger, for two",
    "Setting for part of \"Frankenstein\"",
    "Albertville's locale",
    "Home of the Brenner Pass",
    "Challenge for Hannibal",
    "Swiss peaks",
    "High points of which five are found going up in this puzzle",
    "Liechtenstein's locale",
    "Bernese ___",
    "Terrain of northern Italy",
    "Peaks for yodelers",
    "Matterhorn's locale",
    "Chamonix setting",
    "Austrian peaks",
    "Swiss ___",
    "Swiss peaks",
    "Where lederhosen are worn",
    "High points of a European trip?",
    "Liechtenstein's locale",
    "Swiss peaks",
    "\"The Sound of Music\" setting",
    "Brenner Pass locale",
    "High places",
    "Range of some singers",
    "Climbers' challenges",
    "-",
    "View in northern Italy",
    "European river source",
    "Skiers' paradise",
    "Swiss peaks",
    "High points of a vacation?",
    "Yodelers' milieu",
    "Tour de France challenge",
    "Austrian peaks",
    "Edelweiss source",
    "Some climbers' conquests",
    "Bernese ___",
    "View from Lucerne",
    "Mountains crossed by Hannibal",
    "Skier's mecca",
    "View in northern Italy",
    "Backdrop for D. H. Lawrence's \"Women in Love\"",
    "Austrian peaks",
    "Skiing mecca",
    "\"The Sound of Music\" setting",
    "Matterhorn and others",
    "Where Davos is",
    "Heidi's home",
    "Mann's \"The Magic Mountain\" locale",
    "\"Swiss Family Robinson\" peaks",
    "Feature of 31-Down",
    "Savoy region",
    "Peaks near Bern",
    "Where Sherlock Holmes \"died,\" with \"the\"",
    "Piz Bernina group",
    "Italian ___",
    "1,000-kilometer chain",
    "Skiers' destination, with \"the\"",
    "Heidi's home",
    "Hannibal's challenge",
    "Where to find Aletsch Glacier",
    "Skiing mecca",
    "\"The Sound of Music\" backdrop",
    "Skiing mecca",
    "Mont Blanc's locale",
    "Swiss peaks",
    "Swiss heights",
    "\"The Sound of Music\" backdrop",
    "France's Savoy ___",
    "Skiing mecca",
    "Locale of the world's longest road tunnel",
    "Zurich's peaks",
    "European chain",
    "Weisshorn and others",
    "St. Bernards' beat",
    "Austrian peaks",
    "Yodeling locale",
    "French ___",
    "Environs of 1-Across",
    "Swiss peaks",
    "Mont Blanc site",
    "Heidi's home",
    "Zurich's and Zug's locale",
    "Swiss range",
    "Yodeling locale"
  ],
  "oles": [
    "Cheers at a futbol match",
    "Chorus for picadores",
    "Bullring cries",
    "Chorus at an arena de futbol",
    "Some loud chewers",
    "Accented cheers",
    "Some futbol cheers",
    "Bullring cheers",
    "Cheers at a corrida",
    "Praise for a picador",
    "Encouraging words",
    "Cheers for toreadors",
    "Shouts made with the waving of white hankies",
    "Whoops?",
    "Plaza de toros cries",
    "Cheers in un estadio",
    "Bullring shouts",
    "Cheers after a go-o-o-oal!",
    "Bullfight chorus",
    "Corrida cheers",
    "Shouts of support",
    "Exuberant flamenco cries",
    "Some World Cup cheers",
    "Hurrays for matadors",
    "Flamenco cheers",
    "Cries at a plaza de toros",
    "Flamenco cries",
    "Acclaim for picadors",
    "World Cup chorus",
    "Bullfight cries",
    "Some stadium cries",
    "World Cup chants",
    "Corrida shouts",
    "Accented cries",
    "Shouts at a futbol game",
    "World Cup sounds",
    "Cheers",
    "Cheers at a futbol match",
    "Some World Cup cheers",
    "Cheers for the matador",
    "Corrida sounds",
    "They ring in a ring",
    "Cheers for picadores",
    "Acapulco acclamations",
    "Plaudits, of a sort",
    "Pamplona shouts",
    "Corrida cheers",
    "Jubilant cries",
    "Plaza de toros sounds",
    "Cheers for toreros",
    "Cries during a paso doble",
    "Corrida cries",
    "Praise for toreadors",
    "Bullring bravos",
    "Rousing cheers",
    "Cheers for el matador",
    "Cheers for el toro",
    "They ring out in rings",
    "Cheers",
    "Hispanic huzzahs",
    "Arena yells",
    "Cheers",
    "Corrida cries",
    "Bullring hurrahs",
    "Bullfight cheers",
    "Shouts in a ring",
    "Barcelona cheers",
    "Rousing cheers",
    "Shouts to a matador",
    "Ringside shouts",
    "Cries to bullfighters",
    "Cheers",
    "Flamenco cries",
    "Bullfight cheers",
    "Hearty cheers",
    "Cries from the stands",
    "Arena shouts",
    "Shouts to toreadors",
    "Cheers for banderilleros",
    "Hearty cheers",
    "Arena shouts",
    "Cries after charges are made",
    "Bullfight bravos",
    "They're heard in rings",
    "Corrida cheers",
    "Encouraging words",
    "Encouraging words",
    "Jose's hurrays",
    "Plaudits, of a sort",
    "Corrida cries",
    "Cries for Joselito",
    "Motivation for Manolete",
    "Bullring cheers",
    "Bullfight cries",
    "Barcelona boosters",
    "Ring shouts",
    "Shouts for Juan Belmonte",
    "Corrida cheers",
    "Ring cheers",
    "Bullring cries",
    "Corrida cheers",
    "Bullfight cries",
    "South-of-the- border shouts",
    "Sounds from a plaza de toros"
  ],
  "cara": [
    "Sweetheart, in Salerno",
    "Sweetheart, in Rome",
    "Model/actress Delevingne",
    "Italian \"darling\"",
    "Italian \"dear\"",
    "Beloved, in Bologna",
    "\"___ Mia\" (1965 #4 hit for Jay & the Americans)",
    "___ mia (Italian term of endearment)",
    "Italian \"dear\"",
    "___ mia (Italian term of endearment)",
    "___ mia (Italian term of endearment)",
    "Irene of \"Fame\"",
    "With 53-Across, Italian sugar",
    "Irene of \"Fame\"",
    "Beloved, in Bologna",
    "Italian beloved",
    "Italian \"dear\"",
    "Italian beloved",
    "Opening word of many an Italian letter",
    "Cherished, in Cremona",
    "Italian sweetie",
    "Oscar-winning Irene",
    "Irene of \"Fame\"",
    "Italian sweetie",
    "Italian honey",
    "Irene of \"Fame\"",
    "Actress Irene of \"Fame\"",
    "Singer Irene",
    "Actress Williams of \"The Defiant Ones\"",
    "Cherished, as a bambina",
    "Italian dearie",
    "Dear, in Italy",
    "\"The ___ Williams Show\" of 1960's TV",
    "Dear, as a signorina",
    "See 34-Across",
    "Irene of \"Fame\"",
    "Dear, as a signorina",
    "Actress of \"Fame\" fame",
    "\"___ Mia\" (1965 hit)",
    "Singer Irene",
    "Irene of \"Fame\"",
    "Dear, to Dino",
    "Irene of \"Fame\" '"
  ],
  "vail": [
    "Steamboat Springs alternative",
    "Vacation locale for President Gerald Ford",
    "Rockies ski resort",
    "Colorado skiing mecca",
    "Colorado ski resort",
    "Colorado's ___ Mountain",
    "Colorado ski area",
    "Resort town on I-70",
    "Rocky Mountains resort",
    "Colorado skiing mecca",
    "Colorado resort",
    "Rockies resort",
    "Resort near Beaver Creek",
    "Colorado resort town",
    "Resort east of Glenwood Springs",
    "Colorado ski resort",
    "Colorado skiing destination",
    "Colorado resort",
    "Aspen alternative",
    "Colorado resort",
    "Resort near Copper Mountain",
    "Resort near Mt. Jackson",
    "Skiing mecca",
    "Colorado skiing haven",
    "Colorado ski town",
    "Colorado resort",
    "Rockies resort",
    "Resort near Copper Mountain",
    "Colorado resort",
    "Where some chichi ski",
    "Colorado skiing mecca",
    "Ski spot"
  ],
  "woks": [
    "Pans for potstickers",
    "Asian vessels",
    "Stir-fry cookers",
    "They're handled in Asian restaurants",
    "Concave kitchenware",
    "Stir-fry vessels",
    "Stir-fry vessels",
    "Vessels seen over fires",
    "Rice holders",
    "Round-bottomed vessels",
    "Chinese cookers",
    "Cooking vessels",
    "Thai restaurant cookware",
    "Asian cookers",
    "Pans for stir-frying",
    "Canton cookware",
    "Yan's pans",
    "Canton cookware",
    "Oriental cookware"
  ],
  "iler": [
    "Robert who played A. J. Soprano",
    "Robert of \"The Sopranos\"",
    "See 41-Down",
    "Robert of \"The Sopranos\"",
    "Robert of \"The Sopranos\"",
    "Robert of \"The Sopranos\"",
    "\"The Sopranos\" co-star",
    "Robert of \"The Sopranos\"",
    "Soprano player Robert",
    "Player of one of TV's Sopranos",
    "Robert of \"The Sopranos\"",
    "\"The Sopranos\" actor",
    "Robert of \"The Sopranos\"",
    "Robert of \"The Sopranos\"",
    "Robert of \"The Sopranos\"",
    "He played one of TV's Sopranos",
    "Robert of \"The Sopranos\"",
    "Robert of \"The Sopranos\"",
    "Robert of \"The Sopranos\"",
    "Actor Robert of \"The Sopranos\""
  ],
  "lust": [
    "Strong desire",
    "Hunger (for)",
    "___ for life",
    "Sexual appetite",
    "One of the seven deadly sins",
    "The hots",
    "Aphrodisia",
    "Passionate desire",
    "One of the seven deadly sins",
    "\"Curiosity ... is a ___ of the mind\": Hobbes",
    "Crime of those in Dante's second circle",
    "Intense hunger",
    "Hircosity",
    "The hots",
    "What an aphrodisiac may produce",
    "One of the seven deadly sins",
    "Carnal craving",
    "\"Love surfeits not, ___ like a glutton dies\": Shak.",
    "Satyr's feeling",
    "The hots",
    "One of seven in the film \"Se7en\"",
    "The hots",
    "Some people have it for life",
    "Libertine's feeling",
    "Theme of Nabokov's \"Lolita\"",
    "Insatiable desire",
    "Earthy desire",
    "A deadly sin",
    "It's a sin",
    "The hots",
    "Passionate desire",
    "Craving",
    "One of 7-Down",
    "Craving",
    "Driving desire",
    "Craving"
  ],
  "toll": [
    "Travel expense",
    "Turnpike expense",
    "Ring",
    "Cost that weighs heavily",
    "Ring",
    "Payment that many wait in line to make",
    "Something needed to raise the bar?",
    "Ring",
    "Bridge charge",
    "Car payment?",
    "Damage done",
    "Damage inflicted",
    "Commuter's cost",
    "Ring, as a bell in a steeple",
    "Going rate?",
    "Ring",
    "Commuter's expense",
    "Payment at many a New York bridge",
    "Single stroke",
    "Usage fee",
    "Ring",
    "Extent of damage",
    "Car payment",
    "___ call",
    "Crossing cost",
    "Cost to cross",
    "Peal",
    "Turnpike charge",
    "Going rate?",
    "Kind of call",
    "It may go in a basket",
    "Cost to cross",
    "Sound",
    "Bridge-crossing fee",
    "Damage done",
    "Ring",
    "Bridge fee",
    "Exaction",
    "Tunnel fee",
    "E-Z Pass payment",
    "Auto commuter's bane",
    "Kind of call",
    "Turnpike fee",
    "It's collected at a booth",
    "Exaction",
    "Extent of damage",
    "Travel expense",
    "Bridge or tunnel charge",
    "Highway robbery?"
  ],
  "town": [
    "You might skip it if you're in trouble",
    "\"Old ___ Road,\" longest-running #1 single in Billboard history (19 weeks)",
    "Word with boom or skip",
    "Burg",
    "\"My Kind of ___ (Chicago Is)\"",
    "Hamlet's relative",
    "Village",
    "Mini-metro",
    "Go to ___ on",
    "Ending with George or James",
    "Village",
    "Hamlet's cousin",
    "Burg",
    "It may have a square in the middle",
    "Burg",
    "Crier's place",
    "Hamlet's big brother",
    "See 2-Down",
    "See 10-Across",
    "See 38-Across",
    "Something painted red",
    "Country partner?",
    "It may be painted red",
    "Word with ghost or boom",
    "Out of ___ (away)",
    "Podunk, e.g.",
    "Square setting",
    "Burg",
    "Hamlet's cousin",
    "Word before house or hall",
    "Kind of hall",
    "Atlas dot",
    "Borough",
    "Burg",
    "Hamlet",
    "Word with China or ghost",
    "Burg",
    "Shute's \"A ___ Like Alice\"",
    "Burg"
  ],
  "sins": [
    "Behaves badly",
    "Makes a big mistake",
    "Things confessed during confession",
    "Things confessed in confessionals",
    "Repentance subjects",
    "Septet in Dante's \"Purgatorio\"",
    "Reasons to say \"I'm sorry\"",
    "Seven \"deadly\" things",
    "\"Deadly\" septet",
    "Confessor's confessions",
    "Confession subjects",
    "Sermon topics",
    "Things laid on scapegoats",
    "Breaks the Ten Commandments",
    "Things confessed at confession",
    "50-Down and others",
    "Stain producers",
    "Errs",
    "Some confessions",
    "Trespasses",
    "Confessional list",
    "\"Deadly\" septet",
    "Lust and envy, for two",
    "Trespasses",
    "Peccadilloes",
    "Baptism castoffs",
    "Does bad",
    "Faults",
    "Offenses",
    "Some are deadly",
    "Confession recitals",
    "Breaks a commandment",
    "\"Few love to hear the ___ they love to act\": Shak.",
    "Faults",
    "Confessional list",
    "32-Down et al.",
    "Displeases 57-Down",
    "They're recited in confessions",
    "Peccadilloes",
    "Impieties",
    "Subjects for forgiveness",
    "Wrongs",
    "They demand an expiation",
    "Seven are deadly",
    "Pride and envy, e.g.",
    "Wrongs"
  ],
  "rhee": [
    "Syngman ___, first South Korean president",
    "Onetime Korean statesman Syngman",
    "President during the Korean War",
    "Syngman of South Korea",
    "South Korea's first president",
    "Korea's Syngman ___",
    "Korean War leader",
    "Former South Korean president Syngman ___",
    "First president of South Korea",
    "Syngman ___, first president of South Korea",
    "South Korea's first president Syngman ___",
    "Korea's Syngman ___",
    "South Korea's first president",
    "Syngman ___, first president of South Korea",
    "Korea's Syngman",
    "Eisenhower's Korean counterpart",
    "Asian leader who had a Ph.D. from Princeton",
    "Prominent name in South Korean history",
    "Korea's Syngman ___",
    "Korean leader Syngman ___",
    "First president of Korea",
    "Korean president, 1948-60",
    "First president of South Korea",
    "Foreign leader exiled in Hawaii, 1960",
    "President exiled to Hawaii in 1960",
    "Onetime Korean president",
    "Former South Korean president",
    "Korean statesman",
    "First South Korean president",
    "First president of South Korea",
    "Korea's Syngman ___",
    "'50s South Korean leader",
    "Onetime Korean president",
    "First president of Korea",
    "Korean statesman"
  ],
  "aron": [
    "\"East of Eden\" twin",
    "Elvis's middle name",
    "Elvis's middle name",
    "Elvis ___ Presley",
    "Elvis's middle name",
    "Elvis ___ Presley",
    "Elvis ___ Presley",
    "Elvis's middle name",
    "Elvis's middle name",
    "Elvis's middle name",
    "Schoenberg's \"Moses und ___\"",
    "The King's middle name",
    "Elvis ___ Presley",
    "Elvis follower",
    "Elvis ___ Presley",
    "Elvis ___ Presley",
    "Elvis ___ Presley",
    "\"East of Eden\" son",
    "Elvis's middle name",
    "Elvis Presley's middle name",
    "Schonberg's \"Moses und ___\"",
    "Schonberg's \"Moses und ___\"",
    "\"East of Eden\" twin",
    "Middle name of \"the King\"",
    "Schoenberg's \"Moses und ___\"",
    "Elvis follower",
    "\"East of Eden\" twin",
    "Elvis's middle name",
    "Middle name of The King",
    "Elvis ___ Presley",
    "Elvis's middle name",
    "Opera \"Moses und ___\"",
    "\"East of Eden\" twin",
    "Schonberg opera \"Moses und ___\"",
    "One of the Trask brothers",
    "Elvis's middle name",
    "Elvis's middle name",
    "Elvis's middle name",
    "Elvis ___ Presley",
    "The King's middle name",
    "Elvis's middle name",
    "Arnold Schoenberg's \"Moses und ___\"",
    "Elvis's middle name",
    "Elvis ___ Presley",
    "___ Trask (\"East of Eden\" character)",
    "\"East of Eden\" brother",
    "Elvis's middle name",
    "\"East of Eden\" twin",
    "Elvis's middle name",
    "Schoenberg opera \"Moses und ___\"",
    "Elvis's middle name",
    "Elvis ___ Presley",
    "Elvis's middle name",
    "Elvis's middle name",
    "The King's middle name",
    "Schoenberg's \"Moses und ___\"",
    "\"East of Eden\" brother",
    "Elvis ___ Presley",
    "\"East of Eden\" character",
    "Elvis's middle name",
    "Elvis___Presley",
    "Schoenberg's \"Moses and ___\"",
    "Elvis's middle name",
    "The King's middle name",
    "Elvis ___ Presley",
    "Graceland name",
    "Middle name in Memphis",
    "Middle name of \"The King\"",
    "Elvis ___ Presley"
  ],
  "tony": [
    "Stylish",
    "Broadway award",
    "Chic",
    "Uptown, so to speak",
    "Props for a Broadway play?",
    "Upscale",
    "Fashionably high-class",
    "Orlando, in the music world",
    "\"West Side Story\" role",
    "Broadway award",
    "High-class",
    "Uptown",
    "What the last letter of 107-Down stands for",
    "Soprano on TV",
    "Chichi",
    "One of eight for Stephen Sondheim",
    "Broadway honor",
    "U.S. equivalent to the U.K.'s Laurence Olivier Award",
    "Commercial tiger's name",
    "Award won 21 times by Harold Prince",
    "\"The Spiderwick Chronicles\" co-author DiTerlizzi",
    "Frosted Flakes mascot",
    "Playwright's prize",
    "Broadway honor",
    "What \"1776\" got in 1969",
    "Lead role on 23-Across",
    "Accolade for a great play",
    "Broadway honor",
    "Theater award",
    "\"West Side Story\" fellow",
    "Broadway award",
    "Theater award",
    "Broadway award",
    "Swank",
    "\"West Side Story\" role",
    "Annual prize since 1947",
    "Broadway award",
    "Theater award",
    "Chichi",
    "Theater award",
    "One of TV's Sopranos",
    "Player's trophy",
    "Cousin of an Obie",
    "American Theatre Wing award",
    "\"Les Miserables\" award, 1987",
    "Play accolade",
    "Award for \"The Producers\"",
    "Crooner Bennett",
    "\"West Side Story\" gangleader",
    "June award",
    "Famous tiger",
    "\"Taxi\" role",
    "\"West Side Story\" beau",
    "Danza of \"Who's the Boss?\"",
    "Award for \"Kiss of the Spider Woman\""
  ],
  "mets": [
    "Team with \"Mr.\" and \"Mrs.\" mascots",
    "Citi Field team",
    "Baseball's \"Amazin's\"",
    "2015 World Series team",
    "___-Willets Point (subway station in Queens, N.Y.)",
    "\"Miracle\" team of 1969",
    "Subway Series squad",
    "N.L. East team",
    "Pro team with blue-and-orange jerseys",
    "1969 and 1986 World Series champs",
    "Yankees' hometown rivals",
    "Subway Series team",
    "1986 World Series champs",
    "51-Down team",
    "\"Meet the ___\" (major-league fight song)",
    "Team whose colors are blue and orange",
    "Team that got a new ballpark in 2009",
    "\"Meet the ___\" (major-league fight song)",
    "Citi Field team",
    "Willie Mays's last team",
    "Team Gil Hodges both played for and managed",
    "Citi Field team",
    "\"Amazin'\" team",
    "Citi Field team",
    "Subway Series participant",
    "Citi Field team, starting in 2009",
    "Last team managed by Casey Stengel",
    "\"Miracle\" team of 1969",
    "Shea squad",
    "\"Miracle\" team of 1969",
    "Team with a bridge in its logo",
    "Major-league team with the most season losses, 120, in the 20th century",
    "Queens subject?",
    "29-Down team",
    "Team since 1962",
    "1962 expansion team",
    "\"How 'bout them ___?\" (classic conversation segue)",
    "\"Miracle\" workers of 1969",
    "They beat the 39-Across in the 1986 8-Down",
    "2000 \"subway series\" losers",
    "1986 World Series champs",
    "Shea team",
    "1969 miracle team",
    "New York tickertape honorees, 1962, '69 and '86",
    "Subway Series team",
    "\"Miracle\" team of 1969",
    "Subway Series team",
    "Miracle workers of '69",
    "Team with a bridge in its logo",
    "\"Amazin' \" team",
    "1969 Series winners",
    "Giants' successors",
    "Team at Shea",
    "Expansion team of 1962",
    "New York nine",
    "Shea nine",
    "Queens team",
    "Flushing Meadows team",
    "1986 World Series champs",
    "Shea squad",
    "\"Miracle\" group",
    "Sports team since 1962",
    "Shea team",
    "Shea Stadium nine"
  ],
  "yoko": [
    "Collaborator on 1968's \"Two Virgins,\" familiarly",
    "\"Oh ___!\" (song from Lennon's \"Imagine\" album)",
    "\"Dear ___\" (\"Double Fantasy\" song)",
    "John's \"bed-in\" bedmate",
    "John's other half",
    "John Lennon's \"Dear ___\"",
    "John left Cynthia for her",
    "John Lennon's \"Dear ___\"",
    "Partner of John",
    "With 14-Down, \"Walking on Thin Ice\" singer",
    "John's mate",
    "Julian's stepmother"
  ],
  "coup": [
    "Masterstroke",
    "___ d'etat",
    "Quite an accomplishment",
    "Major success",
    "Brilliant move",
    "Government overthrow",
    "Masterstroke",
    "Masterstroke",
    "Takeover",
    "Putsch",
    "Qaddafi's rise to power, e.g.",
    "*Takeover",
    "Great move",
    "Feat",
    "Masterstroke",
    "Major success",
    "Great move",
    "Quite a feat",
    "Brilliant maneuver",
    "Masterstroke",
    "Clever accomplishment",
    "Stunning triumph",
    "Stunning success",
    "Masterstroke",
    "Grand achievement",
    "___ d'etat",
    "It may be bloodless",
    "Revolution result, possibly",
    "Startling success",
    "Sudden ouster"
  ],
  "atts": [
    "Courtroom figs.",
    "Figures on USA's \"Suits\": Abbr.",
    "\"The Good Wife\" figures: Abbr.",
    "Courtroom team: Abbr.",
    "Case builders: Abbr.",
    "They often want to settle: Abbr.",
    "Argument makers: Abbr.",
    "Lawyers: Abbr.",
    "Brief makers: Abbr.",
    "\"Esq.\" titleholders",
    "Court figs.",
    "Caseworkers?: Abbr.",
    "Some defenders: Abbr.",
    "Courtoom figures: Abbr.",
    "Firm parts: Abbr.",
    "Bar figures: Abbr.",
    "Lawyers: Abbr.",
    "Lawyers: Abbr.",
    "A.B.A. members",
    "A.B.A. members: Abbr.",
    "Bar mems.",
    "A.B.A. members",
    "Bar members, for short"
  ],
  "juke": [
    "Vintage diner fixture, in brief",
    "Diner fixture, informally",
    "Record player, briefly",
    "Music player, for short",
    "Diner music player, informally",
    "Music box, informally"
  ],
  "frio": [
    "Cold, in Caracas",
    "Cold: Sp.",
    "Opposite of caliente",
    "Cold, in Colombia",
    "Cold, in Caracas",
    "Cold in Tijuana"
  ],
  "gyro": [
    "Halal cart offering",
    "It's a wrap",
    "Greek sandwich",
    "Greek sandwich",
    "Rocket stabilizer",
    "Order from a food truck",
    "Food truck menu item",
    "Street cart offering",
    "Sandwich topped with tzatziki sauce",
    "Greek diner order",
    "Greek street food",
    "Taverna offering",
    "Greek sandwich",
    "Greek deli specialty",
    "Spinner",
    "Urban sidewalk vendor's offering",
    "Greek restaurant offering",
    "Sandwich from a sidewalk stand",
    "Deli order",
    "Spinner",
    "Greek sandwich",
    "Hearty sandwich",
    "Toy that whirls",
    "Greek sandwich",
    "Greek sandwich",
    "Pilot's instrument",
    "Lamb sandwich, in Greek cookery"
  ],
  "iffy": [
    "Uncertain",
    "Far from certain",
    "Doubtful",
    "Not for sure",
    "Doubtful",
    "Borderline",
    "Not definite",
    "Not certain at all",
    "Not settled",
    "Up in the air",
    "Touch-and-go",
    "Questionable",
    "Not at all certain",
    "Borderline",
    "Not solid",
    "In doubt",
    "Uncertain",
    "Far from certain",
    "Up in the air",
    "Uncertain",
    "Uncertain",
    "In doubt",
    "Up in the air",
    "Uncertain",
    "Conjectural",
    "Less than solid",
    "Questionable",
    "Doubtful",
    "Uncertain",
    "Dubious",
    "Questionable"
  ],
  "dane": [
    "Hamlet, for one",
    "Whose house might be in Aarhus",
    "Tennis player Caroline Wozniacki or actress Brigitte Nielsen",
    "Great ___",
    "One with possible Viking ancestry",
    "Great ___",
    "Hans Christian Andersen, by nationality",
    "Rosencrantz or Guildenstern",
    "Copenhagener, e.g.",
    "Someone for whom \"Thank you very much\" is \"Mange tak\"",
    "Hamlet, for one",
    "One for whom \"hello\" is \"hej\"",
    "Hamlet or Ophelia",
    "King Christian or Queen Margrethe",
    "Hamlet, for one",
    "Hamlet, e.g.",
    "Greenlander, by citizenship",
    "King Gorm the Old, e.g.",
    "Frederiksberg resident",
    "Rosencrantz or Guildenstern",
    "Polonius, for one",
    "Zealand resident",
    "Hamlet, e.g.",
    "Rosencrantz or Guildenstern",
    "King Claudius, e.g.",
    "Great ___",
    "Any King Christian I-X",
    "Great ___",
    "The Great ___ (Victor Borge's nickname)",
    "Kierkegaard, for one",
    "Hamlet, e.g.",
    "Hamlet, by nationality",
    "Philosopher Kierkegaard, e.g.",
    "Great ___",
    "Shakespeare's Gertrude, e.g.",
    "Alborg native",
    "Great ___",
    "Writer Isak Dinesen, e.g.",
    "Alborg native",
    "Ore spender",
    "Bohr or Borge",
    "Hamlet, e.g.",
    "Fjord explorer?",
    "Niels Bohr, e.g.",
    "Rosencrantz or Guildenstern",
    "Explorer Bering was one",
    "Odense citizen",
    "Hamlet, for one",
    "Niels Bohr, e.g.",
    "Scandinavian",
    "Kierkegaard, e.g.",
    "Hamlet, for one",
    "Ophelia or Laertes, e.g.",
    "Claudius subject",
    "Copenhagener",
    "Hamlet, e.g.",
    "Borge, for one",
    "Jutlander, e.g.",
    "Niels Bohr, e.g.",
    "Hans Christian Andersen, e.g.",
    "Hamlet, for one"
  ],
  "slav": [
    "Czech, e.g.",
    "___ Defense (classic chess opening)",
    "Many an Eastern European",
    "Many a person once trapped behind the Iron Curtain",
    "Ukrainian, e.g.",
    "Belarusian, e.g.",
    "Czech or Pole",
    "Macedonian, e.g.",
    "Czech or Croat",
    "Dweller along the Don",
    "Dalmatian or Croatian",
    "Pole, for one",
    "Balkan native",
    "Bulgarian, e.g.",
    "Belarussian, e.g.",
    "Bosnian, e.g.",
    "Pomeranian, e.g.",
    "Croat or Serb",
    "Pole, e.g.",
    "Many a Balkan native",
    "Bulgarian or Croat",
    "Serb or Pole",
    "Czech or Pole",
    "Pole or Czech",
    "Many a person behind the Iron Curtain",
    "Czech, for one",
    "Pomeranian, e.g.",
    "John Paul II, for one",
    "Eastern European",
    "Pole, for one",
    "Bulgarian or Czech",
    "Serbian or Pole",
    "Bosnian, e.g.",
    "Croat or Serb",
    "Pole, for one",
    "Czech, e.g., but not a Hungarian",
    "Balkan native",
    "Dweller along the Danube",
    "Pomeranian or Dalmatian",
    "Eastern European",
    "Czech or Croat",
    "Pole classification",
    "Dalmatian, e.g.",
    "Serb or Pole",
    "Czech or Serb",
    "Serb or Croat",
    "Pole or Bulgarian",
    "Montenegro native",
    "Montenegrin, e.g.",
    "Cyrillic alphabet user",
    "Pole, for one",
    "Many an old Hapsburg subject",
    "Bohemian, e.g.",
    "Croat, e.g.",
    "Czech or Serb",
    "Eastern European",
    "Serb or Croat",
    "Pole, e.g.",
    "Russian, e.g.",
    "Czech, e.g.",
    "Many a Balkan",
    "Pole, e.g.",
    "Croat or Bulgar",
    "Bulgarian or Croat",
    "Pole, e.g.",
    "Serb, e.g.",
    "Croat, e.g.",
    "Pole, e.g.",
    "Moravian, e.g.",
    "Eastern European",
    "East European",
    "Moravian, e.g.",
    "Pole, e.g.",
    "Eastern European",
    "Bulgar, e.g.",
    "Pole, for example",
    "Eastern European",
    "Eastern European",
    "Bulgarian, e.g.",
    "Croat, e.g.",
    "Czech or Pole",
    "Pole, e.g.",
    "Serb or Czech",
    "Tito, e.g.",
    "Eastern European",
    "Bulgar, e.g.",
    "Rembrandt's \"The Noble ___\"",
    "Pole, for instance",
    "Pole, e.g."
  ],
  "held": [
    "Kept a grip on",
    "Retained ... or detained",
    "Detained",
    "Not free to go",
    "Clutched",
    "Didn't give way",
    "Stood fast",
    "Cradled",
    "Had in hand",
    "Detained",
    "Contained",
    "In reserve",
    "Didn't give away",
    "In custody",
    "Detained",
    "In custody",
    "Believed",
    "Not allowed to go",
    "Gripped",
    "Didn't break",
    "Didn't break",
    "Retained",
    "In custody",
    "Grasped",
    "Had in hand",
    "Was of the opinion",
    "In custody",
    "Grasped",
    "Maintained",
    "Didn't give way",
    "Maintained",
    "Believed",
    "Remained fast",
    "Maintained",
    "Averred",
    "Embraced",
    "Was unyielding"
  ],
  "chug": [
    "Down quickly",
    "Down at the bar?",
    "Drink quickly",
    "Quaff quickly",
    "Guzzle down",
    "Move slowly (along)",
    "Finish all at once, in a way",
    "When repeated, party cry",
    "Down in a hurry",
    "Repeated cry at a beer blast",
    "Perform a bar dare",
    "Engine production",
    "Engine noise"
  ],
  "chia": [
    "___ Pet",
    "With 58-Across, 1980s fad that \"sprouted\"",
    "\"Pet\" with green \"fur\"",
    "Kind of seeds in health foods",
    "___ Pet",
    "___ Pet (kitschy gift)",
    "___ Pet (1980s fad item)",
    "\"Pet\" that's a plant",
    "\"Pet\" that needs watering",
    "___ Pets (1980s fad)",
    "Mint family plant harvested for its seeds",
    "___ seeds, ingredients in some health drinks",
    "Pet project?",
    "Plant whose seed is sold as a health food product",
    "Green \"pet\"",
    "___ Pet",
    "___ Pet (sprouting figurine)",
    "\"Pet\" that's a plant",
    "Popular plant \"pet\"",
    "Quickly growing \"pet\"",
    "1980s fad item",
    "Pet plant",
    "___ Pet",
    "___ pet (onetime fad item)",
    "Kind of pet",
    "Herbal \"pet\"",
    "Pet plant",
    "Mexican mint",
    "Mint plant",
    "Minty Mexican plant",
    "___ Pet (novelty item)",
    "___ Pet (novelty item)"
  ],
  "goin": [
    "Enter",
    "Enter",
    "Enter",
    "\"How's it ___?\"",
    "Enter",
    "\"How's it ___?\"",
    "Proceed, say",
    "Enter",
    "\"How's it ___?\"",
    "\"How's it ___?\"",
    "Penetrate",
    "Enter",
    "Enter",
    "Enter",
    "Enter",
    "Enter",
    "\"How's it ___?\"",
    "Enter",
    "Enter",
    "Enter",
    "Enter",
    "Enter",
    "Enter",
    "Enter",
    "Enter"
  ],
  "mull": [
    "Think (over)",
    "Ruminate",
    "Chew (over)",
    "Think (over)",
    "Bat back and forth, say",
    "Think (over)",
    "Weigh, with \"over\"",
    "\"Fernwood 2-Night\" star",
    "Think",
    "Think (over)"
  ],
  "goal": [
    "Score in hockey",
    "Objective for a soccer player",
    "Kickstarter figure",
    "Top of a fund drive thermometer",
    "One of three in a hat trick",
    "One-third of a hat trick",
    "Making the honor roll, e.g.",
    "Power play result, often",
    "Aim",
    "Finish line",
    "Result of a successful slap shot",
    "Sports announcer's scream",
    "Shutout spoiler",
    "Ambition",
    "Shout at a soccer game",
    "Aim",
    "Slap shot success",
    "World Cup highlight",
    "Hat trick component",
    "Successful kick",
    "One-third of a hat trick",
    "Soccer success",
    "Soccer commentator's cry",
    "Score for Mia Hamm",
    "Soccer announcer's cry",
    "Slap shot success",
    "Good shot",
    "Part of a hat trick",
    "Good shot",
    "Hockey score",
    "Fund-raising need",
    "It may be tended",
    "Hockey score",
    "Soccer score",
    "Announcer's cry at a soccer match",
    "Touchdown",
    "Ideal",
    "Caged puck",
    "Ambition"
  ],
  "intl": [
    "The \"I\" of I.M.F.: Abbr.",
    "Involving multiple states: Abbr.",
    "Abbr. in many airport names",
    "Part of I.M.F.: Abbr.",
    "I.M.F. part: Abbr.",
    "Worldwide: Abbr.",
    "Not domestic: Abbr.",
    "The \"I\" in I.B.M.: Abbr.",
    "The \"I\" in IHOP: Abbr.",
    "Part of I.M.F.: Abbr.",
    "Like many conglomerates: Abbr.",
    "U.N.-like",
    "Worldwide: Abbr.",
    "Worldwide: Abbr.",
    "Not just at home: Abbr.",
    "Part of I.S.B.N.: Abbr.",
    "Like many large cos.",
    "Like G8 meetings: Abbr.",
    "The \"I\" of I.M.F.: Abbr.",
    "Part of I.M.F.: Abbr.",
    "Part of I.L.G.W.U.: Abbr.",
    "Not domestic: Abbr.",
    "Like some crossings: Abbr.",
    "Like many J.F.K. arrivals",
    "Like many KLM and SAS flights",
    "Global: Abbr.",
    "Part of many airport names: Abbr.",
    "Like some relations: Abbr.",
    "Like some airports: Abbr.",
    "Part of IHOP: Abbr.",
    "Like the Olympics: Abbr.",
    "Like J.F.K. Airport: Abbr.",
    "Worldwide: Abbr.",
    "Like J.F.K. Airport",
    "Part of IBM: Abbr.",
    "The \"I\" in IHOP: Abbr.",
    "Abbr. starting some corp. names",
    "Not dom.",
    "Like the U.N.",
    "Like many orgs.",
    "Like some airports: Abbr.",
    "U.N.-like",
    "Worldwide: Abbr.",
    "Global: Abbr.",
    "Part of I.L.G.W.U.: Abbr."
  ],
  "haus": [
    "Abode in Aachen",
    "Place to live in Germany",
    "Rheinland residence",
    "Residence on the Rhein",
    "Rhein residence",
    "Hamburger shack?",
    "Hamburger's home",
    "Dusseldorf dwelling",
    "Aachen abode",
    "Herr's home",
    "Frau's abode",
    "Frau's spot",
    "Holstein abode",
    "Home, to Hans",
    "Aachen abode"
  ],
  "deal": [
    "Order to someone holding a deck of cards",
    "Green New ___",
    "Start a game of cards",
    "\"What's the big ___?\"",
    "\"What's the ___?\"",
    "You can't make one by yourself",
    "\"Let's shake on it\"",
    "\"You're on!\"",
    "\"Here's the ___ ...\"",
    "\"You're on!\"",
    "Negotiation goal",
    "\"You're on!\"",
    "One may be made with a handshake",
    "Bargain",
    "Negotiator's goal",
    "Square ___",
    "It's least palatable when raw",
    "Word said with a handshake",
    "An \"art\" for Donald Trump",
    "Pass out on game night?",
    "Donald Trump's \"The Art of the ___\"",
    "See 38-Down",
    "Hand out cards",
    "Give everyone a hand",
    "\"You're on!\"",
    "It may follow a cut",
    "New ___ (35-Across's program)",
    "25%-off price, e.g.",
    "Give out cards",
    "Word with a handshake",
    "Command to a person holding a deck of cards",
    "Order to the person holding the deck of cards",
    "Exclamation with a handshake",
    "Negotiation goal",
    "\"Agreed!\"",
    "Bargain",
    "Start a card game",
    "Cry made with a handshake",
    "Successful conclusion of a negotiation",
    "Give a card hand",
    "\"Are we agreed?\"",
    "TV word before and after \"or no\"",
    "\"Agreed!\"",
    "Start of a game",
    "Word said with a handshake",
    "It may be new, raw or big",
    "It might follow a cut",
    "It may follow a cut",
    "You might shake on it",
    "Do business",
    "Good buy",
    "Negotiation's end",
    "Hand out",
    "Sports page news",
    "Start a hand",
    "Question or answer in \"___?\" ... \"___!\"",
    "Do business",
    "Give a hand",
    "Handshake's meaning, maybe",
    "Dicker",
    "It may be raw",
    "Do business",
    "Cause for a handshake",
    "Give a hand?",
    "Bargain",
    "Sales rep's goal",
    "It may be raw",
    "Bargain-hunter's goal",
    "Give a hand?",
    "Begin a hand",
    "\"It's a ___!\"",
    "Do business",
    "Give a hand",
    "\"Agreed!\"",
    "\"Agreed!\"",
    "Card game start",
    "Card game start",
    "Administer",
    "Start of a card game",
    "Arrangement",
    "Contract",
    "Poker command",
    "Distribute, as cards",
    "Give a hand",
    "Big bargain",
    "Good buy",
    "Give a hand"
  ],
  "swak": [
    "Letters on love letters",
    "Letters on love notes",
    "Letters on love letters",
    "Love-letter letters",
    "Love letters?",
    "Love letter abbr.",
    "Valentine letters?",
    "Love letters",
    "Love letters?",
    "Abbr. on a love letter",
    "Letters on a love letter"
  ],
  "oper": [
    "Abbr. below \"0\" on a phone",
    "Abbr. on a 0 button",
    "Letters no longer seen on most phones",
    "Abbr. next to a star",
    "Abbr. between * and #",
    "Abbr. above \"0\"",
    "Abbr. on a phone dial",
    "It's just below 0: Abbr.",
    "Phone button abbr.",
    "Person who's a zero?: Abbr.",
    "Switchboard attendant: Abbr.",
    "Abbr. not found on most smartphones",
    "Telephone keypad abbr.",
    "Dial button sharing the \"0\"",
    "Phone abbr.",
    "Abbr. above 0 on a phone",
    "Phone button",
    "Abbr. accompanying 0",
    "Phone abbr.",
    "It can be found under TUV",
    "It's just under 8: Abbr.",
    "Job largely automated these days: Abbr.",
    "Button between * and #",
    "Phone abbr.",
    "It's to the left of #",
    "Button below TUV",
    "Button next to a *",
    "Phone co. employee",
    "Phone button",
    "Abbr. to the right of a star",
    "Phone button",
    "It's to the left of #",
    "Key next to *",
    "0 on a phone: Abbr.",
    "Phone abbr.",
    "0 letters",
    "Abbr. on some dials",
    "Abbr. on some dials",
    "0 on a phone: Abbr.",
    "Switchboard worker: Abbr.",
    "Button below the 8",
    "Phone abbr.",
    "Person who's a zero?: Abbr.",
    "Letters above 0",
    "Abbr. above zero",
    "0, on a telephone: Abbr.",
    "Letters above 0",
    "Telephone abbr.",
    "Phone button",
    "Dial letters",
    "Phone abbr.",
    "Abbr. on a phone",
    "Phone button"
  ],
  "eaus": ["Evian and Perrier", "Waters, informally"],
  "manx": [
    "Tail-less cat",
    "Tailless cat",
    "Cat with no tail",
    "Kind of cat",
    "With 11-Down, animal called \"stubbin\" by locals",
    "Tailless cat",
    "Language known to native speakers as Gaelg",
    "___ cat",
    "Stub-tailed cat",
    "Tailless cat",
    "Relative of Welsh",
    "Tailless cat",
    "With 1-Down, tailless pets",
    "Kind of cat",
    "Domestic cat",
    "Tailless cat",
    "Kind of cat",
    "Tailless cat",
    "Celtic language",
    "House cat"
  ],
  "pius": [
    "Papal name last taken in 1939",
    "12-time Vatican name",
    "Papal name chosen 12 times",
    "Papal name last used in 1958",
    "One of a dozen popes",
    "Name shared by 12 popes",
    "One of 12 popes",
    "Appropriate-sounding papal name",
    "One of 12 popes",
    "Pope with an appropriate-sounding name",
    "Papal name",
    "One of 12 popes",
    "Papal name",
    "Name of 12 popes",
    "Papal name first used in A.D. 140",
    "One of 12 popes",
    "Name of 12 popes",
    "Name of 12 popes",
    "Last Pope to be sainted",
    "20's-50's papal name",
    "Papal name",
    "Saint ___ (early Roman bishop)",
    "Papal name",
    "Name of 12 popes",
    "Papal name",
    "One of 12 popes"
  ],
  "holi": [
    "Hindu festival of colors",
    "Hindu festival of colors",
    "Colorful Hindu festival",
    "Hindu festival of colors",
    "Hindu spring festival"
  ],
  "maws": [
    "Gaping holes",
    "Cavernous openings",
    "Big mouths",
    "Traps and yaps",
    "Gaping things",
    "Mouths to feed?",
    "Monsters' mouths",
    "Gullets",
    "Gaping mouths",
    "Gorges",
    "Gullets",
    "Gaping holes",
    "Big mouths",
    "Big mouths",
    "Huge mouths",
    "Large openings",
    "Gullets",
    "Gaping mouths",
    "Dinosaurs' open mouths, e.g.",
    "Gullets"
  ],
  "tiny": [
    "Minute",
    "Wee",
    "Minute",
    "Minuscule",
    "Like poppy seeds",
    "Wee",
    "Wee",
    "Minuscule",
    "Like dollhouse furniture",
    "Miniature",
    "Insignificant",
    "Atomic",
    "Elfin",
    "Very insignificant",
    "Wee",
    "Pint-size",
    "Negligible",
    "Wee",
    "Peewee",
    "Baby",
    "With 35-Down, brother of Master Peter",
    "Facetious nickname for a giant",
    "Atomic",
    "Itsy-bitsy",
    "Pint-size",
    "Microscopic",
    "Like germs",
    "Runtish",
    "Peewee",
    "Wee",
    "Minute",
    "Causing squinting, perhaps",
    "Invisible to the naked eye",
    "Minuscule",
    "Very small",
    "Wee",
    "Itsy-bitsy",
    "Like champagne bubbles",
    "Pint-sized",
    "Minuscule",
    "Like 47-Down's bubbles",
    "Minuscule",
    "Microscopic",
    "Like microbes",
    "Baby",
    "Minuscule",
    "Wee",
    "Bantam",
    "Microscopic",
    "Like Albee's Alice",
    "Wee",
    "Minuscule"
  ],
  "year": [
    "Alumni grouping",
    "There is one between birthdays",
    "Coin collector's interest",
    "Reading on the dashboard of the DeLorean in \"Back to the Future\"",
    "Once around the sun",
    "MIX, for one",
    "What a cake candle often represents",
    "A light one goes a long way",
    "Copyright symbol follower, typically",
    "Auto specification",
    "Datum for a car aficionado",
    "Info for a graduate",
    "Number on a trophy",
    "Name tag info at an alumni event",
    "Info on a wine label",
    "Wine bottle datum",
    "1000 or 2000, but not 0",
    "It takes months to complete",
    "687 days, on Mars",
    "Orbital period",
    "2016, e.g.",
    "Class",
    "Wine datum",
    "It starts with a celebration",
    "Auto datum",
    "Calendar's scope",
    "Juniors, e.g.",
    "2013, e.g.",
    "Contract period, often",
    "Class ring datum",
    "Sentence unit",
    "Classic car datum",
    "Wine bottle datum",
    "Alumni grouping",
    "Oenophile's specification",
    "Oenologist's concern",
    "Number after a ?? symbol",
    "Sophomore or junior",
    "Information for an oenologist",
    "Oenophile's concern",
    "Strangely, it's shorter than a day on Venus",
    "Many a Roman numeral",
    "Vintage designation",
    "Sophomores, e.g.",
    "Career division, in sports",
    "Twelvemonth",
    "January to December",
    "Annum",
    "Once around the sun",
    "One of 57-Down, in English",
    "Info in a used car ad",
    "Vintage",
    "Wine info",
    "Class",
    "Graduating class info",
    "Wine bottle datum",
    "Orbital period",
    "Revolution time?",
    "Orbital period",
    "Vintage designation",
    "Reunion number",
    "Oenophile's concern",
    "Calendar span",
    "Time for a revolution",
    "Time to go once around the sun",
    "Oenologist's interest",
    "Wine vintage",
    "Wine label info",
    "Class identification",
    "Calendar's span",
    "Part of a sentence?",
    "Anniversary unit",
    "1776 or 1945",
    "Sophomore, for one",
    "Wine label info",
    "Wine info",
    "Freshman or sophomore",
    "Sentence division",
    "Two semesters",
    "Oenologist's interest",
    "Millennium unit",
    "Once around the sun",
    "Four seasons",
    "Two semesters",
    "Wine taster's concern",
    "Sentence unit",
    "With 28-Across, late December",
    "Birthday-to-birthday span",
    "2001, e.g.",
    "Junior, for one",
    "Subscription length, often",
    "'00, e.g.",
    "Wine connoisseur's concern",
    "2001, for one",
    "Two semesters",
    "Junior, e.g.",
    "687 days on Mars",
    "Vintage",
    "Vintage designation",
    "Wine label info",
    "1775 or 2001",
    "Vintage",
    "1492 or 2001",
    "Indentureship unit",
    "Revolutionary period",
    "Sophomore, e.g.",
    "1945, for one",
    "Vintage",
    "Orbit period",
    "Once around the sun"
  ],
  "twig": [
    "Really thin type",
    "Bit of kindling",
    "Bit of kindling",
    "Bit of kindling",
    "Itsy-bitsy branch",
    "Branch extension",
    "Branchlet",
    "Tiny bit of kindling",
    "Skinny-minny",
    "Really thin person",
    "New growth",
    "Chickadee's perch",
    "Tiny branch",
    "Bit of kindling",
    "One may help support a nest egg",
    "Divining rod",
    "Offshoot",
    "Nest part",
    "Bud holder",
    "Shoot",
    "Bud site",
    "Bit of wicker",
    "Nest part",
    "Bit of basketwork",
    "Baby branch",
    "Branch offshoot"
  ],
  "scud": [
    "Cold War missile type",
    "Move fast, as clouds",
    "Move quickly, as a cloud",
    "Missile first used in the Yom Kippur War",
    "Gulf War weapon",
    "Long-range guided missile",
    "\"Toy Story\" dog that shares its name with a missile",
    "Move quickly, as clouds",
    "Missile name",
    "Gulf war missile",
    "Gulf war missile",
    "Certain missile",
    "Gulf war missile",
    "Tactical ballistic missile",
    "Mideast missile",
    "Missile with a mobile launcher",
    "Iran-Iraq war weapon",
    "Move quickly, as clouds",
    "Gulf war missile",
    "Gulf war missile",
    "Hightail it",
    "Launcher's launch",
    "Gulf war missile",
    "Gulf war missile",
    "Gulf war missile",
    "Gulf war missile",
    "Desert Storm terror",
    "Gulf war menace",
    "Gulf war missile",
    "Gulf War missile",
    "Gulf war missile",
    "Mideast missile",
    "Wind gust"
  ],
  "prow": [
    "Front on the waterfront",
    "Stern's opposite",
    "Front end",
    "Front of a vessel",
    "Front of a freighter",
    "Stem",
    "Figurehead's place",
    "Front of a ship",
    "Figurehead locale",
    "Nose of a ship",
    "Boat stem",
    "Ship's front",
    "Boat tip",
    "Ship's christening spot",
    "Ferry's front",
    "Ferry's front",
    "Projecting front",
    "Ship's front",
    "Ship part",
    "Christening spot",
    "On-the-water front",
    "Ship's front",
    "Figurehead's place",
    "Ship's front",
    "Ship's front",
    "Ship's front",
    "Nautical nose",
    "Stem",
    "Figurehead's place",
    "Stem",
    "Ship's front",
    "Ship's front",
    "Figurehead site",
    "Fore-and-after's fore",
    "Ship, in poetry",
    "Ship's front",
    "Front end",
    "Ship, in poetry"
  ],
  "aeon": [
    "Word derived from the Greek for \"age\"",
    "Timeline swath",
    "\"___ Flux\" (2005 film)",
    "Very long time",
    "\"___ Flux\" (1990s sci-fi series)",
    "Long, long time",
    "More than a millennium",
    "MTV's \"___ Flux\"",
    "\"___ Flux\" (Charlize Theron film)",
    "Very long span",
    "\"___ Flux\" (1990s animated series)",
    "\"___ Flux\" (Charlize Theron film)",
    "Division of geology",
    "\"___ Flux,\" 2005 sci-fi film",
    "Forever and a day",
    "Unit in a geology book",
    "Many, many moons",
    "Long, long time",
    "Many millennia",
    "\"___ Flux\" (Charlize Theron movie)",
    "Part of the earth's history",
    "Many ages",
    "___ Flux (Charlize Theron role)",
    "Ages and ages",
    "Very long time",
    "Very long time",
    "Long, long time",
    "Big stretch?",
    "Time in earth's history",
    "Astronomical unit",
    "Long, long time",
    "Geological range",
    "\"___ Flux\" (Charlize Theron film)",
    "It goes on and on and on",
    "With 64-Across, 2005 Charlize Theron title role",
    "Long, long time",
    "Interminable time",
    "Long, long time",
    "Coon's age",
    "Ages and ages",
    "Long, long time",
    "Almost eternity",
    "Interminable time",
    "Boundless time",
    "Extended time",
    "Quite a stretch",
    "Long, long time",
    "Long, long time",
    "Mighty long time: Var.",
    "Astronomical unit",
    "Many millennia",
    "Long time",
    "Long, long time",
    "Long, long time",
    "Long, long time",
    "Many millennia",
    "It seems like forever",
    "Very long stretch",
    "Time without end",
    "Geological extent",
    "Ages",
    "Endless years",
    "Many a millennia",
    "Billion years",
    "Long, long time",
    "Ages and ages",
    "Seemingly forever",
    "Extended period",
    "Many years",
    "Ages",
    "Geological period",
    "Time spent in line, seemingly",
    "Ages and ages",
    "A billion years",
    "Long, long time",
    "Many millennia",
    "A long time"
  ],
  "akon": [
    "Singer whose name becomes a city if you add an \"R\" in the middle",
    "One-named singer with the 2006 hit \"Smack That\"",
    "One-named singer with the 2006 hit \"Smack That\"",
    "One-named singer with the 2007 #1 hit \"Don't Matter\"",
    "\"I Wanna Love You\" singer, 2006",
    "\"Smack That\" singer"
  ],
  "flew": [
    "Whizzed (by)",
    "Zoomed",
    "Aviated",
    "Raced",
    "Zipped",
    "Zipped (by)",
    "Whizzed",
    "Raced",
    "Aviated",
    "Sped",
    "Aviated",
    "Took off",
    "Gained popular acceptance",
    "Piloted",
    "Zipped (by)",
    "Zoomed",
    "Went really fast",
    "Went like the dickens",
    "Whooshed",
    "Sped",
    "Went by plane",
    "Whipped along",
    "Went by plane"
  ],
  "maam": [
    "Palindrome with an apostrophe in the middle",
    "Title for a lady",
    "Polite term of address",
    "Polite term of address",
    "\"Yes, ___!\"",
    "Polite title",
    "\"After you, ___\"",
    "Palindromic term of address",
    "Polite term of address",
    "\"Just the facts, ___\"",
    "Respectful term of address",
    "Sir's counterpart",
    "Word from a hat tipper, perhaps",
    "Hat-tipping word",
    "\"Yes, ___\"",
    "\"Thank you, ___\"",
    "Title with an apostrophe in the middle",
    "The \"m\" of \"yes'm\"",
    "Cowboy-to-lady address",
    "Title with an apostrophe",
    "Sir's counterpart, informally",
    "Term of address from a hat-tipper",
    "\"Just the facts, ___\"",
    "Sir's counterpart",
    "\"Yes, ___\" (gent's reply)",
    "Sir's counterpart",
    "What to call a lady",
    "Term of address for a lady",
    "Sir's counterpart",
    "Sir's counterpart",
    "Term of address from a hat-tipper",
    "Sir's complement",
    "Word said while tipping one's hat",
    "\"My dear lady\"",
    "Word said with a tip o' the hat",
    "Hat-tipper's word",
    "Palindromic title",
    "\"Just the facts, ___\"",
    "What to call a lady",
    "Woman, to a waiter",
    "Sir's partner",
    "Friday addressee?",
    "Sir's counterpart",
    "Miss alternative",
    "Sir's partner",
    "\"Yes, ___\"",
    "\"Yes, ___\"",
    "What to call a lady",
    "Word with yes, no or thank you",
    "Miss, eventually?",
    "Hat-tipper's word",
    "\"Yes, ___\"",
    "Sir's partner",
    "Webb address?",
    "Thank-you-___",
    "Term of politeness",
    "\"Thank you, ___!\"",
    "Word for a lady",
    "Sir's counterpart",
    "\"Yes, ___\"",
    "Sir's partner",
    "What to call a lady",
    "\"Yes, ___\"",
    "What a cowboy calls a lady",
    "\"Yes, ___!\"",
    "Hat tipper's word",
    "Sir's opposite",
    "Address for a lady",
    "Polite form of address",
    "\"Yes, ___!\"",
    "Palindromic term of address",
    "Polite address"
  ],
  "buds": [
    "Friends",
    "Beginning blossoms",
    "Amigos",
    "Springtime arrivals",
    "BFFs",
    "Taste makers?",
    "Some brewskis",
    "Ones to hang with",
    "Blossoms-to-be",
    "Pals",
    "Chums",
    "Future blooms",
    "Grafters' needs",
    "Chums",
    "Signs of winter's end"
  ],
  "ecco": [
    "Shoe brand originating in Denmark",
    "Danish shoe brand",
    "Danish shoe maker with more than 1,000 global stores",
    "Danish shoe company",
    "Shoe company founded in Denmark",
    "Behold, to Bellini"
  ],
  "anap": [
    "Take ___ (snooze)",
    "Take ___ (doze)",
    "\"I need ___\" (yawner's words)",
    "Take ___ (catch some Z's)",
    "Take ___ (doze)",
    "Take ___ (rest)",
    "Take ___ (rest)",
    "Take ___ (snooze)",
    "Take ___ (drop off briefly)",
    "Take ___ (doze)",
    "Take ___ (snooze)",
    "Take ___ (rest)",
    "Take ___ (snooze)",
    "Take ___ (rest)",
    "Take ___ (rest)",
    "Take ___ (rest)",
    "Take ___ (doze)"
  ],
  "mold": [
    "Shape, as clay",
    "Shape, as clay",
    "Creation of a dentist or a potter",
    "Shape",
    "Jell-O maker",
    "Unwanted buildup",
    "Jell-O maker",
    "Growth on old bread",
    "Gross growth",
    "See 4-Down",
    "It appears when things go bad",
    "Foundry form",
    "It may be broken by a maverick",
    "Go bad, in a way",
    "It's found in a foundry",
    "Get into shape?",
    "Dessert maker's equipment",
    "Shape"
  ],
  "soli": [
    "Most arias",
    "Many sopranos' songs",
    "Divas' deliveries",
    "Standout diva performances",
    "Most arias",
    "Songs for one",
    "Single numbers",
    "They're not complex numbers",
    "Recital numbers",
    "Arias, typically",
    "Singular efforts at La Scala?",
    "Arias, usually",
    "Piano sonatas, e.g.",
    "Most arias",
    "Cadenzas, e.g.",
    "Unaccompanied performances",
    "Songs for one",
    "Most arias",
    "Arias, e.g.",
    "Star turns",
    "Arias, usually",
    "Many diva performances",
    "Star turns in music",
    "Arias, e.g.",
    "Standout performances?",
    "Star turns",
    "Helpless performances?",
    "Arias, usually",
    "Songs for one",
    "Songs for one",
    "Arias, usually",
    "Songs for one",
    "Songs for one",
    "Special performances",
    "Featured vocalists' selections",
    "Coloraturas' performances",
    "Turns at the Met",
    "Star turns",
    "Arias",
    "Concerto features",
    "Songs for one",
    "Songs for one",
    "Recital numbers",
    "Arias, usually",
    "Things one sings?",
    "Arias",
    "Arias",
    "Performances for one",
    "Recital works",
    "Sun: Prefix",
    "By oneself: Prefix"
  ],
  "rory": [
    "___ Storm and the Hurricanes (Ringo's band before the Beatles)",
    "2011 U.S. Open champ McIlroy",
    "Golfer McIlroy",
    "\"Gilmore Girls\" girl",
    "One of the Gilmore Girls",
    "Actor Calhoun",
    "___ McIlroy, 2014 P.G.A. Player of the Year",
    "Actor Calhoun",
    "Golfer McIlroy who won the 2011 U.S. Open",
    "Cowboy actor Calhoun",
    "\"Gilmore Girls\" daughter",
    "Actor Calhoun",
    "Youngest of the Culkin brothers",
    "Calhoun of TV's \"The Texan\"",
    "One of the Kennedy clan",
    "Dublin's ___ O'More Bridge",
    "Actor Calhoun",
    "First name in old westerns",
    "Actor Calhoun",
    "Actor Calhoun",
    "Actor Calhoun",
    "Calhoun of \"How to Marry a Millionaire\"",
    "Actor Calhoun",
    "Calhoun of \"The Texan\"",
    "Actor Calhoun",
    "Calhoun of \"The Texan\"",
    "Actor Calhoun",
    "Calhoun of \"The Texan\""
  ],
  "maxi": [
    "Skirt down to the ankles",
    "___-pads (hygiene product)",
    "Dress style",
    "Calf coverer",
    "Ankle-length dress",
    "Long dress",
    "Kind of skirt",
    "Skirt that stops at the ankles",
    "Skirt type",
    "Dress style that covers the leg",
    "Ankle-length skirt",
    "Dress that covers the ankles",
    "Opposite of mini-",
    "Conservative skirt",
    "Ankle-length",
    "Mini's counterpart",
    "Long skirt",
    "Long skirt",
    "Ankle-length, maybe",
    "Calf-length dress",
    "Long skirt",
    "Start of something big?",
    "Big, in adspeak",
    "It's long in fashion",
    "Passe skirt style",
    "Large, in commercial lingo",
    "Start of something big",
    "Coat or skirt preceder",
    "Great start?",
    "Skirt for the modest",
    "Skirt style",
    "It's long on models",
    "It's long in fashion",
    "Long, for short",
    "Long garment",
    "Long, for short",
    "Long, as a garment",
    "Ankle-length skirt",
    "Fashionable 60's dress",
    "Shin-covering skirt",
    "Extra-long",
    "Mini's opposite",
    "Start of something big",
    "Long skirt",
    "60's-70's dress",
    "Skirt style",
    "Ankle-length",
    "Long dress"
  ],
  "dips": [
    "Some dance moves or stock market events",
    "Business slumps",
    "Salsa and guacamole",
    "Stock market events",
    "Dramatic dance moves",
    "Ballroom maneuvers",
    "Ballroom motions",
    "Showy ballroom moves",
    "Salsa and guacamole, for two",
    "Nincompoops",
    "Turns down",
    "Some market fluctuations",
    "Guacamole and salsa",
    "Leap-the-___ (world's oldest operating roller coaster)",
    "Uncool sorts",
    "Quick swims",
    "Party bowlfuls",
    "Some people or food at parties",
    "Tango moves",
    "Stock market turns",
    "Market corrections",
    "Falls off",
    "Ballroom dance maneuvers",
    "Parallel bar exercises",
    "Ballroom dance motions",
    "Rocky Road servings"
  ],
  "lamb": [
    "Symbol of gentleness",
    "Jesus, in a metaphor",
    "Exemplar of innocence",
    "Follower of Mary",
    "Rung #4 of the ladder",
    "Baby sheep",
    "Main ingredient in the dish lechazo",
    "Epitome of gentleness",
    "Ewe's offspring",
    "Symbol of gentleness",
    "One devoted to Mary?",
    "Staple of Greek cuisine",
    "Term of endearment",
    "Meat often served with mint jelly",
    "Very soft fleece source",
    "Shish kebab meat",
    "___ of God (epithet for Jesus)",
    "Sweet-tempered type",
    "Follower of Mary, in a nursery rhyme",
    "Symbol of gentleness",
    "Mary had a little one",
    "Baby sheep",
    "Kebab meat",
    "Souvlaki meat",
    "*\"... out like a ___\"",
    "Gentle creature",
    "Part of a gyro",
    "Gyro meat",
    "Dish that's often roasted",
    "Symbol of gentleness",
    "Innocent",
    "Mild-mannered type",
    "Easter serving",
    "Easily swindled sort",
    "Souvlaki meat",
    "Meat on a kabob, maybe",
    "Ewe's baby",
    "Baby in wool?",
    "Gentle animal",
    "Many a roast",
    "Souvlaki meat",
    "Easter serving",
    "Suckling sheep",
    "Gentle one",
    "Follower of Mary",
    "Easter entree",
    "Lion's antithesis",
    "Innocent",
    "Gyro meat",
    "One of 28-Across's charges",
    "Mary's follower, in verse",
    "Innocent",
    "Sweetie",
    "Gentle one",
    "Follower of Mary",
    "Naive one",
    "Mary's pet",
    "Lion's trusting companion",
    "Seder mainstay",
    "One in Mary's care",
    "Traditional Easter fare",
    "Innocent one",
    "\"Mrs. Battle's Opinions on Whist\" writer",
    "\"Tales from Shakespear\" cowriter",
    "Woolly one"
  ],
  "care": [
    "Handle with ___",
    "Mind",
    "Give a hoot",
    "Mind",
    "\"___ to elaborate?\"",
    "The \"C\" of I.C.U.",
    "Part of T.L.C.",
    "Part of T.L.C.",
    "___ package",
    "Customer ___",
    "Concern",
    "Prudence",
    "Something that people wish you would take when you leave",
    "Mined",
    "Concern",
    "Give a damn",
    "Nursing, say",
    "Management",
    "Worry",
    "Give a rip",
    "Obama follower?",
    "Worry",
    "Heedfulness",
    "Pains",
    "I*U",
    "Obama follower?",
    "Attention to detail",
    "Protection",
    "\"See if I ___!\"",
    "*Managed ___",
    "Serious attention",
    "Maintenance",
    "Give a darn",
    "Have concern",
    "Pains",
    "It may be managed or extended",
    "Give a darn",
    "___ package",
    "Give a hoot",
    "The \"C\" in T.L.C.",
    "Have concern",
    "Quip, part 3",
    "Attention",
    "Shirt tag info",
    "Kind of package",
    "Meticulousness",
    "Org. helping people in need",
    "Kind of package",
    "Humanitarian org.",
    "Give a hoot",
    "Meticulousness",
    "See 64-Across",
    "Give a darn",
    "Give a darn",
    "Meticulousness",
    "Guardianship",
    "Today it's managed",
    "Worry",
    "Kind of package",
    "Hospital work",
    "Give a hoot",
    "Give a hoot",
    "Diligence",
    "Give a hoot",
    "Worry",
    "Supervision",
    "Intl. relief org.",
    "Supervision",
    "Attention",
    "Relief provider since 1945",
    "\"Take ___\"",
    "Kind of package"
  ],
  "tads": [
    "Small amounts",
    "Smidges",
    "Little bits",
    "Little 'uns",
    "Young 'uns",
    "Bits",
    "Iotas",
    "Kids",
    "Striplings",
    "Bits",
    "Minor amounts",
    "Small amounts",
    "Young 'uns",
    "Squirts",
    "Tiny bits",
    "Small amounts",
    "Small fry",
    "Little ones",
    "Young ?Æuns",
    "Youngsters",
    "Little ones",
    "Smidgens",
    "Traces",
    "Kids",
    "Young 'uns",
    "Young 'uns",
    "Boys",
    "Young 'uns",
    "Small fry",
    "Little ones",
    "Jots"
  ],
  "roku": [
    "Device that makes a TV \"smart\"",
    "Digital media player since 2008",
    "Giant in media streaming",
    "TV streaming device",
    "Streaming media device",
    "Streaming media device"
  ],
  "cosy": [
    "Cloth used to cover a teapot, to Brits",
    "Brit's teapot cover",
    "London tea accessory",
    "Brit's tea ___",
    "Brit's teapot cover",
    "Brit's teapot cover",
    "Teapot covering"
  ],
  "merv": [
    "___ Griffin Enterprises",
    "First name in game shows",
    "Old TV's ___ Griffin Productions",
    "Griffin who created \"Wheel of Fortune\"",
    "Griffin who created \"Jeopardy!\"",
    "Game show maven Griffin",
    "TV's Griffin",
    "\"Jeopardy!\" creator Griffin",
    "Longtime first name in TV talk",
    "Griffin who created \"Wheel of Fortune\"",
    "First name in old TV talk",
    "Griffin who loved game shows",
    "\"___ Griffin's Crosswords\"",
    "Griffin who created \"Jeopardy!\"",
    "___ Griffin, 1960's-80's talk show host",
    "\"Jeopardy!\" creator ___ Griffin",
    "First name in game show production",
    "TV's Griffin",
    "First name in old TV talk",
    "TV's Griffin",
    "Ex-host Griffin",
    "Griffin of \"Jeopardy!\"",
    "TV's Griffin",
    "First name in TV talk",
    "Erstwhile talk-show host",
    "First name in casino ownership",
    "First name in game shows",
    "First name in TV talk",
    "Former talk-show host"
  ],
  "isto": [
    "\"What ___ be done?\"",
    ":",
    "Analogy phrase",
    "What \":\" stands for in an analogy",
    ":",
    "Which ___ say",
    "What \":\" means in an analogy",
    ":",
    ":",
    "Analogy connector",
    "Analogy words",
    "What \":\" can mean",
    ":",
    "Words in an analogy",
    "\"It ___ die for!\" (\"Yum!\")",
    "Analogy phrase",
    "Colon, on a test",
    "Colon, in analogies",
    "Analogy words",
    "Comparison words",
    ":, at times",
    "That ___ say",
    "\"What ___ be done?\"",
    "Colon's meaning, at times",
    "What \":\" means on some exams",
    "Analogy words",
    "Words in an analogy",
    "\"It ___ laugh\"",
    "\"What ___ be done?\"",
    "\"That's all there ___ it!\"",
    "Part of an analogy",
    "Analogy part",
    "Words in a ratio",
    "Lenin's \"What ___ Be Done?\"",
    "Analogy phrase",
    "What a colon may mean",
    "Analogy part",
    "Analogy part",
    "\"That's all there ___ it!\"",
    "Ratio phrase",
    "Words of comparison",
    "\"What ___ become of me?\"",
    "Middle of a ratio",
    "Ratio phrase",
    "Analogist's words",
    "Part of a proportional representation?",
    "Analogy phrase",
    "Analogy part",
    "What two dots may mean",
    "Analogy part",
    "Analogy words",
    "What a colon may mean",
    "Words of comparison",
    "Ratio words",
    "Analogy words",
    "\"To know me ___ ...\"",
    "Ratio phrase",
    "Colon translation",
    "What a colon may mean",
    "Analogy words",
    "Analogy words",
    "Analogy words",
    "Colon's meaning, in analogies",
    "Analogy words",
    "Analogy words",
    "Ratio words",
    "Analogy words",
    "Colon, in analogies",
    "Ratio words",
    "Relationship words"
  ],
  "crus": [
    "French vineyards",
    "French vineyards",
    "Vineyards of high quality",
    "Part of the leg above the ankle"
  ],
  "neut": [
    "Nongendered, as language: Abbr.",
    "Neither fem. nor masc.",
    "Like some German nouns: Abbr.",
    "Like some Ger. nouns",
    "Neither masc. nor fem.",
    "Neither fem. nor masc.",
    "Like \"das\" in Ger.",
    "Not taking sides: Abbr.",
    "Like some nouns: Abbr.",
    "Like some nouns in Lat.",
    "Neither masc. nor fem.",
    "In the middle: Abbr.",
    "Not masc. or fem.",
    "Neither masc. nor fem.",
    "Not masc. or fem."
  ],
  "vise": [
    "Bench tool",
    "Woodworker's clamp",
    "What you might use to get a grip on something",
    "What a headache might feel like",
    "Clincher",
    "Aid in getting a grip",
    "Tool with a rotating handle",
    "Squeezing tool",
    "Jaws on a table",
    "Gripper",
    "*Workshop sight, perhaps",
    "Woodworking class holder",
    "What a migraine might feel like",
    "Workbench gripper",
    "It has strong jaws",
    "Jaw site",
    "Shop device",
    "Wood shop device",
    "Workbench attachment",
    "It has two jaws",
    "Workbench attachment",
    "Workbench attachment",
    "Workshop gripper",
    "Clamp",
    "It may be gripping",
    "It has jaws and a mouth",
    "Bench item",
    "Workshop gripper",
    "Tight gripper",
    "Workbench clamp",
    "Carpenter's aid"
  ],
  "atan": [
    "___ impasse",
    "___ angle",
    "___ impasse",
    "___ all-time high",
    "___ all-time high",
    "___ early age",
    "Work on ___ (sunbathe)",
    "___ advantage",
    "Mussorgsky's \"Pictures ___ Exhibition\"",
    "___ end",
    "\"Pictures ___ Exhibition\"",
    "___ all-time high",
    "___ impasse",
    "___ undisclosed location",
    "Mussorgsky's \"Pictures ___ Exhibition\"",
    "Arrive ___ agreement",
    "___ all-time high",
    "___ end",
    "___ all-time high",
    "___ impasse",
    "Get ___ (bronze)",
    "___ end",
    "___ advantage",
    "___ end",
    "___ advantage",
    "___ impasse",
    "___ early age",
    "___ all-time high",
    "___ all-time high",
    "Get ___ (brown)",
    "___ all-time high",
    "___ impasse",
    "___ impasse",
    "___ impasse",
    "___ impasse",
    "___ end (stopped)"
  ],
  "stax": [
    "Label for Otis Redding and Isaac Hayes",
    "Record label for Otis Redding",
    "Memphis- based record label",
    "Classic record label for R&B and soul",
    "Classic Memphis-based record label",
    "Pringles alternative",
    "Record label for Otis Redding",
    "Record label for Booker T. & the MG's",
    "Record label for Booker T. & the M.G.'s",
    "Record label whose house band was Booker T. & the M.G.'s",
    "Classic R&B record label",
    "Chips-in-a-can brand from Lay's",
    "Record label for Sam & Dave and Booker T. & the MG's",
    "1960's soul record label",
    "1960's-70's soul record label"
  ],
  "sums": [
    "Bottom lines",
    "Totals",
    "Simplest arithmetic problems",
    "Additions",
    "Basic arithmetic",
    "Bottom lines",
    "Bottoms of columns",
    "Totals",
    "Additions",
    "Totals",
    "Bottom lines",
    "See 24-Across",
    "Arithmetic homework",
    "Tasks for calculators",
    "Additional answers?",
    "First graders' work"
  ],
  "etch": [
    "Really impress?",
    "Write with a chisel on stone",
    "Inscribe, as on a trophy",
    "Cut deeply",
    "Form, as a lasting memory",
    "Chisel, say",
    "Prepare a plate, in a way",
    "Mark indelibly",
    "Make a lasting impression",
    "Engrave",
    "Make art on glass or metal",
    "Write indelibly",
    "Write on metal, say",
    "Mark permanently",
    "Mark indelibly",
    "Really impress?",
    "Work like Durer",
    "Do monumental work?",
    "Leave a good impression?",
    "Fix permanently",
    "Leave a permanent mark on",
    "Impress deeply",
    "___ A Sketch",
    "Write permanently",
    "Set in stone, say",
    "Chisel, maybe",
    "Work on a gravestone, e.g.",
    "Make an engraving",
    "Put on the surface, in a way",
    "Write permanently",
    "Help make an impression?",
    "Carve in stone",
    "___ A Sketch",
    "Write on stone, say",
    "Prepare a plaque, perhaps",
    "Get a groove on?",
    "Carve into, as a plaque",
    "Impress clearly",
    "See 58-Down",
    "Do as Durer did",
    "Make an impression?",
    "Chisel, maybe",
    "Do some impressive work?",
    "Permanently mark",
    "Mark permanently",
    "Impress deeply",
    "Impress, as in the memory",
    "Engrave glass with acid",
    "Outline clearly",
    "Prepare a commemorative plate, say",
    "Do some impressions",
    "Impress permanently",
    "Draw like Albrecht Durer",
    "Emulate some of Goya's work",
    "Impress permanently",
    "Prepare a plate, perhaps",
    "Carve",
    "Make aquatints",
    "Clearly impress",
    "Create a lasting impression?",
    "Work with intaglio",
    "Write permanently",
    "Mark permanently",
    "Sharply outline",
    "Impress clearly",
    "Do art on glass, say",
    "Fix firmly",
    "Carve in stone",
    "Write on glass, e.g.",
    "Make some plates",
    "Delineate",
    "Engrave",
    "Impress clearly",
    "Work like Rembrandt, at times",
    "Make a good impression?",
    "Cut, as glass",
    "Work on glass, say",
    "Leave one's mark on",
    "Set in stone",
    "Make an impression",
    "Use acid",
    "Design on metal",
    "Inscribe permanently",
    "Impress, as in memory",
    "Mark permanently",
    "Make some designs",
    "Make a permanent impression",
    "Carve in stone",
    "Do work on glass, say",
    "Cut",
    "Work with acid",
    "Do art on metal, e.g.",
    "Delineate",
    "Make one's permanent mark",
    "Make an artistic impression",
    "Mark permanently",
    "Emulate Rembrandt",
    "Impress firmly",
    "Write on metal",
    "Inscribe with acid",
    "Emulate Durer",
    "Emulate Rembrandt, e.g.",
    "Leave one's mark?",
    "Delineate",
    "Make permanent",
    "Delineate",
    "Draw on copper, say",
    "Make permanent",
    "Carve",
    "Draw with acid",
    "Write with a point",
    "Inscribe for good",
    "Impress clearly",
    "Write permanently",
    "Produce art on copper, e.g.",
    "Permanently mark",
    "Leave one's mark on",
    "___ A Sketch (drawing toy)",
    "Make an aquatint",
    "Use acid",
    "Drop acid?",
    "Write painstakingly",
    "Do like Durer",
    "Make art on glass",
    "Do aquatints",
    "Delineate",
    "Delineate",
    "Imprint on glass",
    "Delineate",
    "Write with acid",
    "Emulate Durer",
    "Work like Durer",
    "Fix permanently",
    "Do art on glass",
    "Make a lasting impression?",
    "Put art on glass",
    "Print indelibly",
    "Delineate",
    "Imprint on glass",
    "Imprint, as in the memory",
    "Imprint"
  ],
  "ohto": [
    "\"___ be in England ...\"",
    "\"___ be in England\"",
    "\"___ be in England\" (Browning line)",
    "\"___ be in England\"",
    "\"___ be in England\": Browning",
    "\"___ be in England ...\"",
    "\"___ be in England\": Browning",
    "\"___ be in England\": Browning",
    "\"___ be in England\"",
    "\"___ be in England\": Browning",
    "\"___ be in England\"",
    "\"___ be in England\"",
    "\"___ be in England ...\"",
    "\"___ be in England ...\"",
    "\"___ be in England...\"",
    "\"___ be in England\"",
    "\"___ be in England...\"",
    "\"___ be in England\"",
    "\"___ be in England\": Browning",
    "\"___ be in England\": Browning",
    "\"___ be in England...\""
  ],
  "chex": [
    "It may be added to the mix",
    "Primary ingredient in the snack Muddy Buddies",
    "Cereal in a party mix",
    "Party mix cereal",
    "Cereal used in party mix",
    "___ Mix",
    "It may be thrown in the mix",
    "___ mix",
    "Cereal word after Rice, Wheat or Corn",
    "___ Mix (party staple)",
    "Follower of Corn, Rice and Wheat in cereal names",
    "Honey Nut ___",
    "Squares for breakfast",
    "Rectangular cereal",
    "Corn ___",
    "Food that's part of a square meal?",
    "Popular cereal"
  ],
  "ruse": [
    "Trick",
    "Ploy",
    "Sly stratagem",
    "Ploy",
    "Trick",
    "Underhanded plan",
    "Sneaky scheme",
    "Trick",
    "Fake-out",
    "Smoke screen",
    "Deception",
    "Trojan horse, e.g.",
    "Wile",
    "Device",
    "Artifice",
    "Bit of flimflam",
    "Setup for a surprise party",
    "34-Across, e.g.",
    "Part of the planning for many a surprise birthday party",
    "Trick",
    "Flimflam",
    "Subterfuge",
    "Machination",
    "Fake-out",
    "Deception",
    "Bait-and-switch, e.g.",
    "Deceit",
    "Put-up job",
    "Stratagem",
    "Subterfuge",
    "Dodge",
    "Machination",
    "Diversion",
    "Shenanigan",
    "Dodge",
    "Bit of chicanery",
    "Artifice",
    "Feint",
    "Dodge",
    "Trojan Horse, e.g.",
    "Deceit",
    "Dodge",
    "Beguilement",
    "Subterfuge",
    "Subterfuge",
    "Trick",
    "Stratagem",
    "Dodge",
    "Trick",
    "Dodge",
    "Ploy",
    "Jugglery",
    "Sneaky scheme",
    "Con",
    "Sly tactic",
    "Trick",
    "Dodge",
    "Stratagem",
    "Trojan horse, for example",
    "Subterfuge",
    "Subterfuge",
    "Artifice",
    "Misleading move",
    "Chicanery",
    "Dodge"
  ],
  "dote": [
    "Heap love (on)",
    "Spoil, with \"on\"",
    "Lavish attention (on)",
    "Fawn over, with \"on\"",
    "Pour love (on)",
    "What many grandparents do",
    "Shower affection (on)",
    "Show great fondness",
    "Lavish care (on)",
    "Coddle, with \"on\"",
    "Show adoration",
    "Act the grandparent, perhaps",
    "Spoil, with \"on\"",
    "Lavish love (on)",
    "Be sweet (on)",
    "Lavish affection (on)",
    "Be feeble-minded",
    "Focus lovingly (on)",
    "Lavish affection (on)",
    "Be stuck (on)",
    "Act like a grandma",
    "Lavish affection (on)",
    "Pour on the love",
    "Emulate a grandparent, maybe",
    "Favor cloyingly, with \"on\"",
    "Treat with grandmotherly love, with \"on\"",
    "Overdo the T.L.C.",
    "Be stuck (on)",
    "Spoil, with \"on\"",
    "Act feeblemindedly",
    "Spoil, with \"on\"",
    "Shower affection (on)",
    "Be too fond",
    "Decline with age",
    "Pamper, with \"on\"",
    "Be overly fond",
    "Bestow much love (on)",
    "Lavish affection (on)",
    "Idolize, with \"on\"",
    "Lavish attention (on)",
    "Lavish affection (on)",
    "Fuss over, with \"on\"",
    "Lose acuity",
    "Lavish affection (on)",
    "Show declining mental capacity",
    "Show senility",
    "Act grandmotherly",
    "Spoil, with \"on\"",
    "Spoil, with \"on\"",
    "___ on (spoil)",
    "Show fondness",
    "Love excessively, with \"on\"",
    "Lavish affection",
    "Fawn",
    "Shower attention (on)"
  ],
  "loew": [
    "Movie magnate Marcus",
    "Early movie mogul Marcus",
    "Big name in theaters",
    "Movie theater pioneer Marcus",
    "Theater magnate Marcus",
    "MGM founder Marcus",
    "1920-24 owner of Metro Pictures",
    "MGM founder",
    "Big name in cinemas",
    "MGM co-founder",
    "MGM mogul Marcus",
    "Theater mogul Marcus",
    "Co-founder of MGM",
    "Theater mogul Marcus",
    "MGM mogul Marcus",
    "Eponymous theater mogul",
    "MGM co-founder",
    "Marcus who co-founded 63-Down",
    "MGM co-founder",
    "MGM co-founder",
    "MGM co-founder",
    "Head of the old Metro Pictures",
    "Big name in movie theaters",
    "Movie mogul Marcus",
    "Early movie mogul",
    "MGM studios founder",
    "MGM co-founder",
    "MGM Studios founder",
    "MGM Studios founder",
    "Theater chain founder Marcus",
    "MGM co-founder",
    "Film pioneer Marcus",
    "M-G-M founder",
    "M-G-M founder",
    "M.G.M founder Marcus",
    "M-G-M co-founder Marcus"
  ],
  "llcs": [
    "Entities with pass-through taxation, briefly",
    "Hybrid business entities: Abbr.",
    "They can be taxed like partnerships, for short",
    "Some businesses: Abbr.",
    "Legal entities for partnerships: Abbr."
  ],
  "gore": [
    "Feature of a creature feature, perhaps",
    "Sight at a gladiatorial fight",
    "Cause of an R rating",
    "Carnage",
    "Horror movie stuff",
    "Stick it to?",
    "\"Saw\" stuff",
    "\"An Inconvenient Truth\" narrator",
    "2007 Peace Prize recipient",
    "Senator who created and introduced the High-Performance Computing Act of 1991",
    "What's seen in \"Saw\"",
    "Loser in a momentous 2000 Supreme Court case",
    "Reason to cover your eyes in the theater?",
    "Stab",
    "Cause for an R rating",
    "\"Saw\" sights",
    "Reason for an R rating",
    "Possible reason for an R rating",
    "Reason for an R rating",
    "45th American vice president",
    "Stick",
    "What's seen in \"Saw\"",
    "Colosseum spectacle",
    "\"Friday the 13th\" staple",
    "Ex-politico with a Nobel and an Emmy",
    "Blood",
    "2007 Peace Nobelist",
    "Al of \"An Inconvenient Truth\"",
    "Cause for an R, perhaps",
    "Horror film staple",
    "Al or Tipper",
    "2006 Oscar winner for his first film",
    "Reason for an R rating",
    "Clinton's number two",
    "Butchery",
    "Blood and guts",
    "Reason for an R rating",
    "It's messy",
    "Cheney?Æs predecessor",
    "Run through",
    "Horror film staple",
    "Reason for an R rating",
    "Reason for an R rating",
    "\"Earth in the Balance\" author",
    "Reason for an NC-17 rating, maybe",
    "Skirt insert",
    "Reason for an R rating",
    "Noted loser by a whisker",
    "Change a letter in 110-Across to spell...",
    "Horror film effects",
    "Reason for an \"R\" rating",
    "Al or Tipper",
    "Blood's partner",
    "\"It's My Party\" singer Lesley",
    "Staple of many video games",
    "Pierce with a tusk",
    "Reason for an NC-17 rating",
    "\"Earth in the Balance\" author Al",
    "Reason for an R rating",
    "Murder and such",
    "V-chips block it",
    "Clinton's #2",
    "Clinton's #2",
    "Second in command",
    "Skirt panel",
    "#2",
    "60's singer Lesley",
    "Clinton's Veep",
    "Blood and guts",
    "Environmentalist Al",
    "Current 5-Across",
    "\"Earth in the Balance\" author",
    "Quayle's successor",
    "Author Vidal",
    "Al from Tennessee"
  ],
  "drys": [
    "Temperance proponents",
    "Prohibitionists",
    "Prohibitionists",
    "Prohibitionists",
    "Prohibitionists",
    "Abstainers",
    "Prohibitionists",
    "Teetotalers",
    "Temperance proponents",
    "Temperance supporters",
    "Prohibitionists",
    "Prohibitionists",
    "Temperance advocates",
    "Prohibitionists",
    "W.C.T.U. members",
    "Teetotalers",
    "Supporters of the 18th Amendment",
    "Prohibitionists"
  ],
  "coma": [
    "Out-of-it state",
    "Food ___ (Thanksgiving feeling)",
    "Unconscious condition",
    "Time out?",
    "Long time out?",
    "Result of a serious head injury",
    "Bad state to be in",
    "Food ___ (Thanksgiving drowsiness)",
    "Food ___ (post-Thanksgiving meal condition)",
    "Out patient's state",
    "Head case?",
    "State made up of two state postal abbreviations",
    "Bad state to be in",
    "Senseless state",
    "Unconscious state",
    "Knocked-out state",
    "Result of a concussion, maybe",
    "Unconscious state",
    "Unresponsive state",
    "State it's not good to be in",
    "Result of trauma, maybe",
    "Unconscious state",
    "Result of a big hit, maybe",
    "Result of going out?",
    "1977 best seller set at Boston Memorial Hospital",
    "Unwakeful state",
    "What a person who's out may be in",
    "Stuporous sleep",
    "Result of a serious head injury",
    "What a conk on the head may produce",
    "You can be in it and out of it at the same time",
    "Possible result of a shock",
    "You may be in it if you're out of it",
    "Unconsciousness",
    "What someone who is out might be in",
    "Robin Cook thriller",
    "Deep sleep",
    "Hospitalized patient's state",
    "Deep sleep",
    "Unwakable state",
    "Deep sleep",
    "Thoughtlessness?",
    "Time out?",
    "What an out patient may be in",
    "Film \"sleeper\" of 1978",
    "It may be hard to get out of",
    "A person in it is out of it",
    "Unwakable state",
    "What you might be in when you're out",
    "Deep sleep",
    "Time out?",
    "Senselessness?",
    "Robin Cook thriller",
    "1978 movie thriller",
    "Knocked-out state",
    "1978 film sleeper?",
    "Robin Cook novel",
    "There's no sense in it",
    "Senseless state",
    "Hospital status",
    "Robin Cook best seller",
    "Comet's head",
    "1978 Bujold film",
    "Robin Cook book",
    "Hospitalized condition",
    "Robin Cook best seller"
  ],
  "ampm": [
    "Toggle on a clock",
    "Switch on a clock radio",
    "12-hour toggle on clocks",
    "Clock setting for an alarm",
    "Alarm clock toggle",
    "Around-the-clock, in a way",
    "Clock radio toggle switch",
    "Day-and-night, in a way",
    "Choices of time",
    "Like 12-hour clocks",
    "Red indication on a clock radio"
  ],
  "avow": [
    "Profess",
    "Confess openly",
    "Say frankly",
    "State",
    "Openly acknowledge",
    "Declare",
    "Confess",
    "Swear",
    "State without reservations",
    "Maintain",
    "Declare",
    "Swear",
    "Profess",
    "Profess",
    "Own up to",
    "Swear",
    "Declare",
    "Swear",
    "Openly declare",
    "State as a matter of fact",
    "Profess",
    "Affirm",
    "Profess",
    "Admit frankly",
    "State publicly",
    "Swear",
    "Swear",
    "Declare assuredly",
    "Swear to",
    "Pledge",
    "State firmly",
    "Frankly admit",
    "Say for certain",
    "Acknowledge",
    "Admit openly",
    "Swear",
    "Declare",
    "Acknowledge",
    "Profess",
    "Solemnly swear",
    "Acknowledge",
    "Maintain",
    "Swear to",
    "Declare frankly",
    "Declare openly",
    "Declare openly",
    "State openly",
    "Openly declare",
    "Confess openly",
    "Affirm",
    "Maintain",
    "State frankly",
    "Profess",
    "Declare",
    "Swear",
    "State categorically",
    "Maintain",
    "Assert",
    "Swear to",
    "Declare",
    "Confess",
    "Acknowledge",
    "State openly",
    "Declare openly",
    "Assert",
    "Admit openly",
    "Swear",
    "Declare openly",
    "Swear to",
    "Confess",
    "Swear",
    "State openly",
    "Acknowledge frankly",
    "Swear",
    "Confess openly",
    "Own up to",
    "Testify",
    "Swear",
    "Confess",
    "Acknowledge",
    "Confess",
    "Declare",
    "Swear",
    "Acknowledge",
    "Declare",
    "Affirm",
    "State with conviction",
    "Swear",
    "Confess",
    "Swear",
    "Confess",
    "Admit",
    "Confess",
    "Testify"
  ],
  "pays": [
    "Is worth it",
    "Is worthwhile",
    "Compensates",
    "Is worthwhile",
    "Picks up the bill",
    "Remunerates",
    "Is worth doing",
    "Yields",
    "Is beneficial",
    "Is worthwhile",
    "With 35-Down, joins the club, perhaps",
    "Is worth doing",
    "Coughs up, so to speak",
    "Is profitable",
    "Shells out",
    "Is beneficial",
    "Settles up",
    "Is advantageous",
    "Ponies up",
    "Compensates",
    "Settles",
    "Performs the traditional male role",
    "Is worthwhile",
    "Is worthwhile",
    "Is worthwhile",
    "Is beneficial",
    "Yields",
    "Remunerates",
    "Is worthwhile"
  ],
  "merl": [
    "Old World blackbird",
    "Blackbird",
    "Old World blackbird",
    "European blackbird",
    "Keyboardist Saunders",
    "Old World blackbird",
    "Blackbird",
    "Keyboardist Saunders",
    "Keyboardist Saunders who played with the Grateful Dead",
    "European black thrush",
    "Keyboardist Saunders",
    "Jazzman Saunders",
    "Jazzman Saunders",
    "Jazzman Saunders",
    "Old World blackbird",
    "Scottish blackbird",
    "Blackbird",
    "Blackbird"
  ],
  "hate": [
    "\"___ is too great a burden to bear\": Martin Luther King Jr.",
    "Not fancy at all",
    "Not at all fancy",
    "\"Haughty Juno's unrelenting ___\": Aeneid",
    "Detest",
    "\"In time we ___ that which we often fear\": Shak.",
    "\"The ___ U Give\" (2018 film)",
    "Loathe",
    "Bad blood",
    "\"Cherish those hearts that ___ thee\": Shak.",
    "\"I ___ quotation\": Emerson",
    "Despise",
    "\"My only love sprung from my only ___!\": Juliet",
    "Give zero stars, say",
    "Hardly fancy",
    "___-watch",
    "Give a zero-star review, say",
    "___ speech",
    "Loathe",
    "Kind of mail",
    "\"I ___ Hamlet\" (Paul Rudnick play)",
    "\"In time we ___ that which we often fear\": Shak.",
    "Hardly fancy?",
    "\"A failure of imagination,\" per Graham Greene",
    "Loathe",
    "Not go for at all",
    "Not fancy at all",
    "Show enmity toward",
    "Word on Harry Powell's left fingers in \"The Night of the Hunter\"",
    "\"Authority is never without ___\": Euripides",
    "Word before mail or crime",
    "Loathe",
    "Detestation",
    "Find awful",
    "Bad feeling?",
    "Not like",
    "\"Too great a burden to bear\": Martin Luther King Jr.",
    "Abominate",
    "Bete noire",
    "\"Haughty Juno's unrelenting ___\": Dryden",
    "Abhor",
    "Abhor",
    "Kind of mail",
    "Abhor",
    "Detest",
    "\"Unimaginable as ___ in Heav'n\": Milton",
    "Can't stomach",
    "Venom",
    "\"Cherish those hearts that ___ thee\": Shak.",
    "Can't take",
    "More than antipathy",
    "Loathe",
    "Kind of mail",
    "No fond feeling",
    "Ill will",
    "Odium",
    "Can't stomach",
    "\"Don't you just ___ it when ...?\"",
    "Motive for some crime",
    "Love's opposite",
    "\"Unimaginable as ___ in Heav'n\": Milton",
    "Not fancy at all",
    "Have trouble standing",
    "With 22-Across, Federal law enforcement matter",
    "Despise",
    "Crime motivation",
    "Abhor",
    "Bigot's emotion",
    "Kind of crime",
    "Despise",
    "Can't stand",
    "Abhor",
    "More than a peeve",
    "Loathe",
    "Detest",
    "Milton's \"immortal___\"",
    "Peg Bracken's \"I ___ to Cook Book\"",
    "Abominate",
    "\"I have seen war...I ___ war\": F.D.R.",
    "Venom",
    "Ill will",
    "Abhor",
    "Kind of mail",
    "Repugnance",
    "Detest",
    "Odium",
    "Odium"
  ],
  "nibs": [
    "Pen tips",
    "Ballpoint points",
    "Bits of roasted cocoa beans",
    "Crushed cacao beans used to make chocolate",
    "Sharpened points of quill pens",
    "Penpoints",
    "Ballpoint tips",
    "Penpoints",
    "Bills",
    "His ___ (the boss)",
    "Beaks",
    "Penpoints",
    "Writing points",
    "His ___ (self-important man)",
    "Quill points",
    "Pen points",
    "Points put on paper",
    "Penpoints",
    "Penpoints",
    "Pointed ends",
    "Tool points",
    "Pointed ends",
    "Penpoints",
    "Bird beaks"
  ],
  "nyro": [
    "Songwriter Laura",
    "Singer/songwriter Laura",
    "Laura who wrote and sang \"Wedding Bell Blues\"",
    "Singer/songwriter Laura",
    "\"Wedding Bell Blues\" composer Laura",
    "Singer/songwriter Laura",
    "\"Wedding Bell Blues\" singer Laura",
    "Singer/songwriter Laura",
    "Laura who wrote \"Wedding Bell Blues\""
  ],
  "jada": [
    "Actress/TV host ___ Pinkett Smith",
    "Actress ___ Pinkett Smith",
    "Actress ___ Pinkett Smith",
    "Actress ___ Pinkett Smith",
    "Nonsense song of 1918",
    "Novelty hit of 1919"
  ],
  "tans": [
    "Light browns",
    "Gets darker, in a way",
    "Sunbathes",
    "Larrups",
    "Whups",
    "Lies in the sun",
    "Lies by the pool, say",
    "Catches some rays",
    "Whups",
    "Many vacationers bring them home",
    "Tropical acquisitions, maybe",
    "Whips",
    "Some salon acquisitions",
    "Whups",
    "Gets color at the beach",
    "Gets browner",
    "They may be sprayed on",
    "Bronzes",
    "Shades at the beach",
    "Salon offerings",
    "Gives a good whuppin'",
    "Salon acquisitions",
    "Catches some rays",
    "Gets some color",
    "Bronzes",
    "What bronzers simulate",
    "Summer shades",
    "Bronzes at the beach",
    "Some vacationers' acquisitions",
    "Whips",
    "Evidences exposure to ultraviolet light",
    "Whips but good",
    "Whips",
    "Whips but good",
    "Gets some color",
    "Catches some rays",
    "Larrups",
    "Beach shades",
    "Light shades",
    "Beats the backside of",
    "Catches some rays",
    "Gets some color, as they say",
    "Khakis",
    "Whips",
    "Gets darker, in a way",
    "Shades from the sun",
    "Prepares, as leather",
    "Khakis",
    "Turns bronze",
    "Catches some rays",
    "Darkens",
    "Whups",
    "Responds to UV rays",
    "Beach shades",
    "Beach shades",
    "Summer colors",
    "Some vacationers' acquisitions",
    "Catches some rays",
    "Sun shades",
    "Suns",
    "Khakis",
    "Prepares leather",
    "Earth tones",
    "Makes leather",
    "Cures, as leather",
    "Uses a UV lamp",
    "Cures leather"
  ],
  "sand": [
    "Beach composition",
    "Beach bucketful",
    "Makeup of a beach",
    "Smooth",
    "Hourglass contents",
    "Makings of a castle at the beach",
    "Beach makeup",
    "What some castles are made of",
    "Composition of dunes",
    "Smooth, in a way",
    "Bunker fill",
    "Beach formation",
    "Contents of some banks",
    "Cement mixer input",
    "Beach blanket?",
    "Hourglass filler",
    "Smooth, in a way",
    "Beach castle material",
    "Make smooth",
    "Hourglass fill",
    "Take the edge off?",
    "Smooth",
    "Levee material",
    "Gear impediment",
    "Ocean liner?",
    "Innards of some clocks",
    "Beach composition",
    "Soft rock?",
    "Dune material",
    "Hourglass fill",
    "Hourglass contents",
    "Hourglass fill",
    "Trap filler",
    "Bank deposit",
    "Grit",
    "Smooth",
    "Work across the board?",
    "Smooth",
    "It may be driven during a storm",
    "Beach composition",
    "With 110-Down, a beach sight",
    "Smooth",
    "Hourglass fill",
    "Take the edge off",
    "Smooth, in a way",
    "It may cover a lot",
    "Smooth",
    "Smooth",
    "Smooth, in a way",
    "Kind of flea or dollar",
    "Contents of a playground box",
    "Beach, basically",
    "Beach, basically",
    "Kind of trap",
    "Hourglass fill",
    "Castle material",
    "Beach blanket?",
    "Dudevant's pseudonym",
    "Dudevant's pen name",
    "Novelist George",
    "Smooth",
    "Smooth, in a way",
    "Amandine Dupin's pen name",
    "\"Elle et Lui\" author",
    "\"Lelia\" author",
    "Smooth wood"
  ],
  "opps": [
    "Antonyms: Abbr.",
    "Enemies, in slang",
    "Antonyms: Abbr.",
    "Antonyms: Abbr."
  ],
  "dock": [
    "Unloading point",
    "Join in space",
    "Come to port",
    "Tie up at harbor",
    "Pier",
    "Boat loading area",
    "Where to tie one on?",
    "Marina fixture",
    "Place for a ship to come in",
    "Curtail",
    "Loading area",
    "Deduct from",
    "Pier",
    "Get into a slip"
  ],
  "cash": [
    "Unimaginative birthday gift",
    "Alternative to Venmo",
    "Paper alternative to plastic",
    "Alternative to Venmo",
    "Dollars and cents",
    "Till fill",
    "Bills that one doesn't mind piling up",
    "Check alternative",
    "Bills, e.g.",
    "Bills and coins",
    "Word before cow or crop",
    "Corn or cotton",
    "\"Cold, hard\" money",
    "___ crop",
    "Carry's partner",
    "A.T.M. supply",
    "Transaction option",
    "Legal tender",
    "Bills, e.g.",
    "Bills, e.g.",
    "Certain incentive",
    "Bills, e.g.",
    "Convert, in a way",
    "Johnny with a guitar",
    "Alternative to credit",
    "Bills and coins",
    "Credit's counterpart",
    "Till fill",
    "Word before cow or crop",
    "Choice at checkout",
    "Transaction option",
    "\"___ or charge?\"",
    "Mattress filler during a recession, maybe",
    "Bills and coins",
    "Bills and coins",
    "Some bills",
    "Bills, e.g.",
    "Alternative to plastic",
    "Money on hand",
    "Alternative to check or charge",
    "Bills, e.g.",
    "An A.T.M. dispenses it",
    "Not check or charge",
    "Sign at an A.T.M.",
    "Country singer Johnny",
    "Redeem, with ?ôin?ö",
    "Kind of cow",
    "Checkout clerk's question",
    "Word after ready or petty",
    "It's tender",
    "Purse filler",
    "Bills",
    "Alternative to check or credit",
    "Payment method",
    "Direct payment",
    "Simplest form of payment",
    "Simple payment method",
    "Bills",
    "Bills",
    "Bills, e.g.",
    "Bills",
    "Long green",
    "Long green",
    "Alternative to plastic",
    "Like some payouts",
    "Like some transactions",
    "Carry's partner",
    "Change, perhaps",
    "Bills and coins",
    "Payment option",
    "Carry's partner",
    "Money in a wallet",
    "Plastic alternative",
    "Bills",
    "Barrelhead bills",
    "Kind of cow",
    "The \"C\" of C.O.D.",
    "Change, e.g.",
    "Bills and coins",
    "Make liquid, in a way",
    "Fives and tens, e.g.",
    "___ and carry",
    "Payment means",
    "Bills and coins",
    "The \"C\" in J.C. Penney",
    "Neither check nor charge",
    "1987 Wimbledon winner",
    "Alternative to charge",
    "1987 Wimbledon winner"
  ],
  "ekes": [
    "Scrapes (out)",
    "Squeaks (out)",
    "Just makes, with \"out\"",
    "Just manages, with \"out\"",
    "Stretches (out)",
    "Squeaks (by)",
    "Just gets (by)",
    "Barely makes it, with \"by\"",
    "___ out a living (barely gets by)",
    "Barely gets, with \"out\"",
    "Grinds (out)",
    "Scratches (out)",
    "Squeezes (out)",
    "Squeezes (by)",
    "Barely scratches (out)",
    "___ out (barely manages)",
    "Barely makes, with \"out\"",
    "___ out a living (barely gets by)",
    "Gets by on, with \"out\"",
    "Barely gets (by)",
    "Barely makes, with \"out\"",
    "Gets (by)",
    "Stretches (out)",
    "Barely manages, with \"out\"",
    "Scratches (out)",
    "Just manages, with \"out\"",
    "Squeaks (by)",
    "___ out a living (gets by)",
    "Stretches, with \"out\"",
    "Scratches (out)",
    "Squeezes (out)",
    "Barely manages, with \"out\"",
    "Squeezes (out)",
    "Squeezes (out)",
    "Just manages, with \"out\"",
    "Squeezes (out)",
    "Barely manages, with \"out\"",
    "Scrapes (out)",
    "Manages, with \"out\"",
    "Just manages, with \"out\"",
    "Scratches (out)",
    "Just makes, with \"out\"",
    "Barely makes, with \"out\"",
    "Makes (out)",
    "Scratches (out)",
    "Makes (out)",
    "Lengthens, old-style",
    "Barely makes, with \"out\"",
    "Scrapes (out)",
    "Barely gets, with \"out\"",
    "Squeezes (out)",
    "Barely manages, with \"out\"",
    "Barely produces, with \"out\"",
    "Squeaks (out)",
    "Barely earns, with \"out\"",
    "Barely gets, with \"out\"",
    "Just makes, with \"out\"",
    "Squeezes (out)",
    "Stretches (out)",
    "Stretches (out)",
    "Squeaks (out)",
    "Manages, with \"out\"",
    "Just manages, with \"out\"",
    "Squeezes (out)",
    "___ out (barely gets)",
    "Scratches (out)",
    "Scrapes (out)",
    "Manages, with \"out\"",
    "Squeezes (out)",
    "Scrapes (out)",
    "Stretches, with \"out\"",
    "Squeezes (out)",
    "Barely makes, with \"out\"",
    "Scratches (out)",
    "Scratches (out)",
    "Just makes, with \"out\"",
    "Meagerly maintains, with \"out\"",
    "Barely gets, with \"out\"",
    "Manages, with \"out\"",
    "Scratches (out)",
    "Scratches (out)",
    "Makes (out)",
    "___ out (manages)",
    "Scratches (out)",
    "___ out (manages)",
    "Squeezes (out)",
    "Stretches, with \"out\"",
    "Stretches, with \"out\"",
    "Scratches (out)",
    "Barely makes, with \"out\"",
    "Gets by, with \"out\"",
    "___ out (supplements)",
    "Barely makes, with \"out\"",
    "___ out (scrapes by)",
    "Makes do, with \"out\"",
    "Squeezes (out)",
    "Supplements, with \"out\"",
    "Makes do, with \"out\""
  ],
  "gmen": [
    "New York football team, informally",
    "New York football team, to fans",
    "Feds",
    "F.B.I. guys",
    "4-Down personnel, informally",
    "Feds",
    "Fed. agents",
    "Figures in 2011's \"J. Edgar\"",
    "Mafioso foes",
    "Cagney classic of 1935",
    "Feds",
    "1935 Cagney crime film",
    "Feds",
    "Feds",
    "Pursuers of the Sopranos, for short",
    "Feds",
    "F.B.I. guys",
    "F.B.I. operatives",
    "Some raiders, informally",
    "Hood fighters",
    "Agents under J. Edgar Hoover, informally",
    "*Backup for Dick Tracy",
    "___ Detective (1930's-50's crime fiction magazine)",
    "Feds",
    "1935 James Cagney crime film",
    "Feds",
    "Feds",
    "They're on big cases",
    "Some gangbusters",
    "Feds",
    "F.B.I. figures",
    "X-file examiners",
    "\"The X-Files\" extras",
    "Feds",
    "Feds",
    "Feds",
    "Hoover subordinates",
    "J. Edgar Hoover underlings",
    "1935 Cagney film",
    "James Cagney thriller",
    "Hoover's force"
  ],
  "lewd": [
    "Apt rhyme for \"crude\" and \"rude\"",
    "Salacious",
    "Blue",
    "Lascivious",
    "Rhyme for rude and crude, appropriately",
    "NSFW",
    "R-rated, maybe",
    "Very off-color",
    "Crudely sexual",
    "Like the lyrics to Lil Wayne's \"Lollipop\" or 50 Cent's \"Candy Shop\"",
    "Blue",
    "Off-color, plus",
    "Apt rhyme of \"crude\"",
    "Obscene",
    "Indecent",
    "NC-17, maybe",
    "R-rated, maybe",
    "Smutty",
    "More than spicy",
    "Far from family-friendly",
    "Dirty",
    "Lascivious",
    "Blue",
    "Bawdy",
    "Not decent",
    "Beyond racy",
    "Dirty",
    "Not clean",
    "Less than decent",
    "Off-color",
    "Off-color",
    "Obscene",
    "Dirty",
    "Bawdy",
    "Loose, in a manner of speaking",
    "Downright blue",
    "More than suggestive",
    "Ribald",
    "Lecherlike",
    "Ribald",
    "Off-color",
    "Lascivious",
    "Satyric",
    "Obscene"
  ],
  "wine": [
    "\"Bottled poetry,\" according to Robert Louis Stevenson",
    "What an oenologist is an expert on",
    "Cabernet, e.g.",
    "Major export of Tuscany",
    "\"A constant proof that God loves us, and loves to see us happy,\" per Benjamin Franklin",
    "Oenophile's love",
    "Chalice filler",
    "\"One of the most civilized things in the world,\" per Hemingway",
    "\"One of the most civilized things in the world,\" per Hemingway",
    "Blush, e.g.",
    "Madeira or merlot",
    "\"It makes a man mistake words for thoughts,\" per Samuel Johnson",
    "With 105-Down, some amphorae",
    "Genteel dinner quaff",
    "Cabernet, for one",
    "Robert Louis Stevenson described it as \"bottled poetry\"",
    "Burgundy or Bordeaux",
    "Reddish purple",
    "Cabernet or merlot",
    "Shiraz, for one",
    "Merlot, for one",
    "Burgundy or Bordeaux",
    "Bouquet source",
    "Merlot, for one",
    "___ list",
    "Medoc or muscatel",
    "Press release?",
    "Port or claret",
    "Reddish purple",
    "Champagne, e.g.",
    "Chianti or Chablis",
    "Red or white",
    "Entertain, in a way",
    "Beaujolais, e.g.",
    "Champagne or Chianti",
    "Soave, e.g.",
    "Press release?",
    "Steward's charge",
    "Cellar contents",
    "Bouquet source",
    "Soave, e.g.",
    "Port, e.g.",
    "Beef bourguignon ingredient",
    "The grape",
    "Rioja, e.g.",
    "Oenologist's interest"
  ],
  "mwah": [
    "Exaggerated kiss sound",
    "[blown kiss]",
    "*big kiss*",
    "Air kiss sound",
    "[Blown kiss]",
    "[Kiss]",
    "\"Kisses, dahling!\"",
    "Air kiss sound",
    "\"Love ya!\"",
    "Sound of an air kiss",
    "[Kiss!]",
    "Sound of an air kiss",
    "\"Love ya!\"",
    "[air kiss]",
    "[Kiss]"
  ],
  "hgtv": [
    "Channel with a lot of house renovation shows",
    "\"Love It or List It\" channel",
    "Channel for renovators and remodelers",
    "\"Love It or List It\" channel",
    "\"Ellen's Design Challenge\" airer",
    "\"House Hunters\" network",
    "\"Property Brothers\" network",
    "\"Property Virgins\" cable channel",
    "\"House Hunters\" cable channel"
  ],
  "dela": [
    "Hip-hop's ___ Soul",
    "Designer Oscar ___ Renta",
    "Paris's Place ___ Bastille",
    "Creme ___ creme",
    "Creme ___ creme",
    "Hip-hop trio ___ Soul",
    "Creme-creme filler",
    "Creme ___ creme",
    "Creme ___ creme",
    "Place ___ Concorde",
    "Fashion's Oscar ___ Renta",
    "Hip-hop's ___ Soul",
    "Writer Francois ___ Rochefoucauld",
    "Boxer Oscar ___ Hoya",
    "Creme ___ creme",
    "Designer Oscar ___ Renta",
    "Paris's Place ___ Concorde",
    "Oscar ___ Hoya",
    "Creme ___ creme",
    "Boxing's Oscar ___ Hoya",
    "Boxer Oscar ___ Hoya",
    "Creme ___ creme",
    "Oscar ___ Renta",
    "Creme ___ creme",
    "Boxing's Oscar ___ Hoya",
    "Paris's Avenue ___ Republique",
    "Oscar ___ Renta",
    "Boxer Oscar ___ Hoya",
    "Oscar ___ Renta",
    "Oscar ___ Renta",
    "Poet Walter ___ Mare",
    "Creme ___ creme",
    "Oscar ___ Renta"
  ],
  "kill": [
    "Be wildly successful, as at a comedy club",
    "Switch off",
    "Block passage of",
    "Take out",
    "Hunter's pride",
    "\"A View to a ___\" (Bond film)",
    "Waste, as time",
    "Slay",
    "Extinguish",
    "Rub out",
    "Hunter's take",
    "Dispatch",
    "Veto"
  ],
  "docs": [
    "Many nonfiction films, for short",
    "Medics",
    "Google ___",
    "E.R. figures",
    "Word files, briefly",
    "Nonfiction films, for short",
    "Official paperwork, for short",
    "Creations in Word, informally",
    "Entries in two Oscar categories, slangily",
    "Hospital V.I.P.'s",
    "Nonfiction films, for short",
    "Government pubs., say",
    "E.R. folk",
    "Rx writers",
    "Medics",
    "Pros who practice",
    "Medics",
    "Bone setters",
    "Nonfictional films, for short",
    "Test conductors",
    "Nurses' workmates",
    "Diagnosers",
    "Shot orderers",
    "Life savers",
    "43-Across members",
    "A.M.A. members",
    "E.R. figures",
    "M.D.'s",
    "Papers, briefly",
    "People who are practicing",
    "Physicians, briefly",
    "Shrinks, e.g.",
    "Medics",
    "Sawbones",
    "Medics"
  ],
  "prof": [
    "Spelman figure, informally",
    "Grad student's mentor",
    "T.A.'s overseer",
    "College teacher, informally",
    "Campus V.I.P.",
    "\"Teach\" at a college",
    "College lecturer, for short",
    "Univ. instructor",
    "Univ. figure",
    "T.A.'s overseer",
    "Classy sort?",
    "T.A., someday?",
    "Prepresidential title for Bill Clinton or Woodrow Wilson: Abbr.",
    "Univ. lecturer",
    "T.A.'s superior",
    "Grad student's mentor",
    "Univ. V.I.P.",
    "One with class?",
    "Head of the class",
    "Class head",
    "Teach at a college?",
    "\"Teach\" at a college",
    "Lecturer, briefly",
    "Figure at State U",
    "Coll. instructor",
    "Fac. member",
    "One who leads class struggle?",
    "Academic handle",
    "Univ. teacher",
    "One teaching econ or psych, e.g.",
    "Class head",
    "\"Teach\"",
    "Head of the class",
    "Head of the class"
  ],
  "wiis": ["Devices sold with motion sensors"],
  "fave": [
    "#1 choice",
    "#1, informally",
    "Top choice, informally",
    "Top go-to",
    "Go-to choice, slangily",
    "Top choice, informally",
    "Top choice, informally",
    "Pet, informally",
    "Top pick, informally",
    "One you dig the most",
    "Top pick, slangily",
    "#1 thing",
    "Grooviest one"
  ],
  "eloi": [
    "Post-human race of sci-fi",
    "Sci-fi race mirroring the bourgeoisie",
    "Foe of the Morlocks, in sci-fi",
    "Wellsian race of the future",
    "\"My God!,\" to Jesus",
    "Morlock victims, in sci-fi",
    "Elite race in \"The Time Machine\"",
    "Posthuman race of literature",
    "\"The Time Machine\" race",
    "\"The Time Machine\" people",
    "Race of people in \"The Time Machine\"",
    "\"The Time Machine\" people",
    "Victims of the fictional Morlocks",
    "People in an H. G. Wells novella",
    "\"The Time Machine\" race",
    "Surface-dwelling race in \"The Time Machine\"",
    "Race in an H. G. Wells book",
    "\"The Time Machine\" race",
    "Victims of the Morlocks, in sci-fi",
    "Morlocks' prey, in sci-fi",
    "Morlock victims, in science fiction",
    "Fictional race of the distant future",
    "Sci-fi race",
    "\"The Time Machine\" people",
    "H. G. Wells race",
    "Race in classic science fiction",
    "Prey of the Morlocks",
    "\"The Time Machine\" vegetarians",
    "The Time Traveler's hosts",
    "\"The Time Machine\" people",
    "\"The Time Machine\" people",
    "Morlocks' enemy",
    "Weena's race, in fiction",
    "\"The Time Machine\" people",
    "Wells race",
    "Race in an H. G. Wells novel",
    "Future race of fiction",
    "\"The Time Machine\" race",
    "H. G. Wells race",
    "Word repeated before \"lama sabachthani\" in Mark 15",
    "\"The Time Machine\" leisure class",
    "\"The Time Machine\" people",
    "Morlock's counterpart in science fiction",
    "Frugivorous creatures of sci-fi",
    "Morlocks' victims in an H. G. Wells story",
    "\"The Time Machine\" people",
    "H. G. Wells people",
    "Patron saint of goldsmiths",
    "\"The Time Machine\" people",
    "Morlocks' victims, in an H. G. Wells story",
    "\"The Time Machine\" people",
    "H. G. Wells people",
    "\"The Time Machine\" race",
    "Wellsian race",
    "\"The Time Machine'\" race",
    "\"My God,\" in Aramaic",
    "Patron saint of goldsmiths",
    "Wellsian race",
    "\"The Time Machine\" people",
    "\"The Time Machine\" race",
    "Beautiful race in an H. G. Wells novel",
    "\"The Time Machine\" race",
    "Repeated word in Mark 15:34 that means \"my God\"",
    "Fictional upper class",
    "Weena's fictional race",
    "Patron saint of metalworkers",
    "\"The Time Machine\" race",
    "\"The Time Machine\" race",
    "Race created by H. G. Wells",
    "Wellsian race",
    "\"The Time Machine\" people",
    "\"The Time Machine\" race",
    "\"The Time Machine\" race",
    "Morlocks' prey in \"The Time Machine\"",
    "Beautiful people of literature",
    "Vegetarian people of fiction",
    "\"The Time Machine\" race",
    "\"The Time Machine\" people",
    "\"The Time Machine\" race",
    "\"The Time Machine\" people",
    "H. G. Wells race",
    "\"The Time Machine\" race",
    "Race in an H. G. Wells story",
    "Morlocks' victims in \"The Time Machine\"",
    "Patron saint of goldsmiths",
    "\"The Time Machine\" people",
    "\"The Time Machine\" leisure class",
    "\"The Time Machine\" race",
    "Morlock's foe in \"The Time Machine\"",
    "Race in \"The Time Machine\"",
    "\"The Time Machine\" people",
    "Patron saint of metalworkers",
    "\"The Time Machine\" people",
    "\"The Time Machine\" race",
    "\"The Time Machine\" people",
    "\"The Time Machine\" people",
    "Wells's oppressed race",
    "H. G. Wells race",
    "\"The Time Machine\" people",
    "\"The Time Machine\" people",
    "\"The Time Machine\" people",
    "The Morlocks ate them",
    "\"The Time Machine\" race",
    "\"The Time Machine\" people",
    "\"The Time Machine\" race",
    "\"The Time Machine\" people",
    "Patron saint of goldsmiths",
    "\"The Time Machine\" race",
    "Slaves of the Morlocks, in fiction",
    "\"The Time Machine\" people",
    "\"The Time Machine\" people"
  ],
  "ivey": [
    "Phil in the Poker Hall of Fame",
    "Two-time Tony-winning actress Judith",
    "Judith with two Tonys",
    "Tony-winning actress Judith",
    "Judith of \"The Devil's Advocate,\" 1977",
    "Phil of poker fame",
    "Poker star Phil",
    "\"Hurlyburly\" Tony winner Judith",
    "Judith ___, Tony winner for \"Steaming\"",
    "Actress Judith",
    "Actress Judith"
  ],
  "styx": [
    "River that comprises the fifth circle of Dante's Inferno",
    "Where Achilles took a dip?",
    "Daughter of Tethys in Greek mythology",
    "Charon's domain",
    "Charon's river",
    "Locale of Charon's ferry",
    "River of Hades",
    "River of the underworld",
    "Home to a mythical ferry",
    "River whose name comes entirely from the last eight letters of the alphabet",
    "River of the underworld",
    "River in which Achilles was dipped",
    "It goes to hell",
    "Band with the top 10 hits \"Lady\" and \"Babe\"",
    "Boundary between the earth and the underworld, in myth",
    "River to Hades",
    "Moon of Pluto discovered in 2012",
    "Something crossed in \"The Divine Comedy\"",
    "One hell of a river?",
    "River crossed by Orpheus",
    "River to Hades",
    "Underworld river",
    "River with a \"dreadful shore,\" in Shakespeare",
    "River to Hades",
    "River ferried by Charon",
    "River to 16-Across",
    "Boundary river",
    "Zeus swore oaths upon it",
    "Mother of Nike, in Greek myth",
    "Moon of 6-Down named for a mythological river",
    "River of the underworld, in myth",
    "River of Hades",
    "Where Achilles was dipped to make him invincible",
    "River of Hades",
    "Underworld route",
    "River of Hades",
    "\"Mr. Roboto\" band, 1983",
    "River ferried by Charon",
    "Boundary of myth",
    "Gods swear by it",
    "\"Come Sail Away\" band, 1977",
    "Frightful river to cross",
    "Dead river?",
    "Milton called it \"The flood of deadly hate\"",
    "Charon's river",
    "Hit 1970s-'90s band with a mythological name",
    "Hellish river",
    "Final course?",
    "River of Hades",
    "River of Hades",
    "Charon's workplace",
    "It circles Hades nine times",
    "Course for the dead?",
    "River of Hades",
    "Deep river?",
    "Classic rock group with a name from Greek myth",
    "Charon's river",
    "Band with the 1979 hit \"Babe\"",
    "Charon's river",
    "River to the underworld",
    "Charon crossed it",
    "Ferry river",
    "1970's-90's rock group with a name from Greek myth",
    "Charon crosses it",
    "Stream of mythology",
    "Underworld river",
    "Dead waters",
    "River to the underworld",
    "Thetis bathed Achilles in it, in myth",
    "Charon's river",
    "Mythological ferry locale",
    "Charon's river",
    "Charon's river",
    "Charon's river",
    "Charon's waterway",
    "\"The flood of deadly hate\": Milton",
    "River to Hades",
    "River whose name means \"hateful\"",
    "A tenth part of the waters of Oceanus",
    "Charon's river",
    "Name on which ancient oaths were taken",
    "Where Achilles was bathed",
    "Crossing for Charon",
    "Underworld flower",
    "Charon's crossing",
    "An oath on it was once held to be inviolable",
    "Charon's river",
    "Underworld river",
    "River of Hell",
    "River to the underworld",
    "Charon?Æs waterway",
    "Charon's river",
    "Charon's crossing",
    "Charon's river",
    "Charon's domain"
  ],
  "atob": [
    "Progress made in step one",
    "Tiny bit of progress",
    "Simple step",
    "First small bit of progress",
    "First leg of an itinerary",
    "Very limited range",
    "The first step",
    "Baby step?",
    "Tiny bit of progress",
    "One small step",
    "Small step",
    "Get from ___ (advance slightly)",
    "Basic first step",
    "Basic travel path",
    "First volume of an encyclopedia, perhaps",
    "First step in a series",
    "Basic travel path",
    "Get from ___ (progress slightly)",
    "Slight progress, after \"from\"",
    "Travel route with no points in-between",
    "Short range?",
    "Volume 1 of an encyclopedia, maybe",
    "Get from ___ (progress slightly)",
    "Hardly the full gamut",
    "Get from ___ (make progress)",
    "Get from ___ (progress)"
  ],
  "aneg": [
    "Rare blood type, for short",
    "Rare blood type, for short",
    "Rare blood type, for short",
    "Rare blood type, for short"
  ],
  "pubs": [
    "Some trivia venues",
    "Trivia contest locales",
    "Dublin hangouts",
    "Stout sites",
    "18-Across offerers",
    "Sots' spots",
    "Bars of Avon",
    "Pint sellers",
    "Bath bars",
    "Darts' places"
  ],
  "cabs": [
    "Airport queue",
    "Metered vehicles",
    "Line at an airport",
    "They might be caught in the rain",
    "Taxis",
    "They measure miles in meters",
    "Street fleet",
    "Stock at a wine bar",
    "Fleet on Fleet Street",
    "They're hailed on Broadway",
    "Vehicles with medallions",
    "Pickup line?",
    "Taxis",
    "Icons of New York City",
    "Ones whose business is picking up?",
    "Uber competitors",
    "Napa options, informally",
    "Semi parts",
    "Taxis",
    "Fleet on the street",
    "Much-hailed group",
    "Checkers, for instance",
    "Airport fleet",
    "They're often yellow or checkered",
    "Taxis",
    "Some urban rides",
    "Where truckers sit",
    "Semi-detachable parts?",
    "Ones with stands",
    "Vehicles with meters",
    "Street fleet",
    "Street fleet",
    "Hacks",
    "Taxis",
    "They're fare-minded",
    "Street fleet",
    "Airport lineup",
    "They're called on account of rain",
    "Yellow fleet",
    "Members of a yellow fleet",
    "They're hailed in cities everywhere",
    "Airport queue",
    "Hacks around the city",
    "City shortage in bad weather",
    "Crane operators' perches",
    "Stand vehicles",
    "Hacks"
  ],
  "biwa": ["Japan's largest lake, located NE of Kyoto", "Japan's largest lake"],
  "oola": [
    "\"Return of the Jedi\" dancer",
    "\"Return of the Jedi\" dancing girl",
    "Dancer enslaved by Jabba the Hutt",
    "Dancer in Jabba the Hutt's court, in \"Return of the Jedi\"",
    "\"Return of the Jedi\" dancing girl",
    "Dancing girl in \"The Return of the Jedi\"",
    "\"The Return of the Jedi\" girl",
    "Green-skinned dancing girl in a \"Star Wars\" film",
    "Green-skinned dancer in \"Star Wars\"",
    "Dancing girl in \"The Return of the Jedi\"",
    "\"Return of the Jedi\" girl"
  ],
  "insp": [
    "Clouseau's rank: Abbr.",
    "Police dept. figure",
    "Clouseau's rank: Abbr.",
    "Police rank: Abbr.",
    "Police dept. personage",
    "Gadget's rank in cartoons: Abbr.",
    "Clouseau, e.g.: Abbr.",
    "Police dept. figure",
    "P.D. rank",
    "Clouseau title: Abbr.",
    "P.D. rank",
    "Police dept. title",
    "Police dept. employee",
    "P.D. figure",
    "Supt.'s inferior",
    "N.Y.P.D. employee",
    "P.D. employee"
  ],
  "date": [
    "Go steady with",
    "See socially",
    "Calendar info",
    "See romantically",
    "One may be blind or hot",
    "10/10, say",
    "Fruit with a pit",
    "March 15, e.g.",
    "Good Tinder outcome",
    "Line on a contract",
    "Letter header",
    "Word with blind or expiration",
    "Time in ads",
    "Hoped-for result of swiping right on Tinder",
    "Go with",
    "1/2, for one",
    "Concert poster info",
    "Social engagement",
    "Possible outcome of an eHarmony match",
    "June 14, e.g.",
    "Romantic outing",
    "Take out",
    "See",
    "*Romantic outing",
    "Information on a check",
    "Questionnaire info",
    "January 1 for the Rose Bowl, e.g.",
    "See",
    "Fruit that grows in a cluster",
    "See",
    "See socially",
    "Dinner ___",
    "Fruit growing at an oasis",
    "7/20/69, for one",
    "Palm fruit",
    "December 25, e.g.",
    "Hot night out, perhaps",
    "7/4/1776, for one",
    "November 4, e.g.",
    "See",
    "Go out with",
    "Movie companion, maybe",
    "Fruit from a palm",
    "See",
    "Aug. 15, 1945, e.g.",
    "See",
    "Play the field, so to speak",
    "Take out",
    "See socially",
    "Dinner and a movie, perhaps",
    "Go with",
    "July 4, 1776, e.g.",
    "\"It's a ___!\"",
    "Go with",
    "See",
    "Go with",
    "Check information",
    "Palm fruit",
    "Go out with",
    "6/4/01, e.g.",
    "Watch feature, perhaps",
    "History test answer",
    "Palm fruit",
    "Check info",
    "Watch feature, perhaps",
    "Person to go out with",
    "Anniversary, e.g.",
    "Go with",
    "Whom one goes out with",
    "Go out with",
    "Do carbon-testing on",
    "Number in a letter",
    "Go steady with",
    "Anniversary, e.g.",
    "See",
    "Take out",
    "Prom partner",
    "Dinner and a movie, perhaps",
    "Pudding flavor",
    "Appointment",
    "Biblical fruit",
    "See",
    "Do an antique dealer's job",
    "Desert dessert",
    "It may be blind",
    "July 4, 1776, e.g."
  ],
  "milk": [
    "Cereal go-with",
    "Ingredient that turns a Black Russian into a White Russian",
    "Good source of calcium",
    "Skim or 2%",
    "What some formulas are based on",
    "White breakfast beverage",
    "Beverage that's graded",
    "Standard part of a food pyramid",
    "Use shamelessly",
    "Half of half-and-half",
    "Exploit, informally",
    "Drink with vitamin D",
    "Do some work on a dairy farm",
    "Formula, essentially",
    "Baby bottle contents",
    "Total requirement?",
    "Half of half-and-half",
    "Draw out",
    "Biscuit ingredient",
    "Cat's saucerful",
    "Exploit",
    "Vitamin D source"
  ],
  "appt": [
    "Entry in a doctor's calendar: Abbr.",
    "Receptionist's notation: Abbr.",
    "Datebook entry: Abbr.",
    "Datum for a secy.",
    "Datebook abbr.",
    "Calendar notation: Abbr.",
    "Sched. listing",
    "8-Down, e.g.: Abbr.",
    "Part of a sched.",
    "Datebook entry: Abbr.",
    "Date with an M.D.",
    "Date with an M.D.",
    "Date with a Dr.",
    "Engagement calendar entry: Abbr.",
    "Datum for a secy.",
    "Datebook entry: Abbr.",
    "Date with an M.D.",
    "Part of an M.D.'s sched.",
    "Datebook abbr.",
    "Date with a D.D.S., e.g.",
    "Date with a dr.",
    "Session with an M.D.",
    "Date with an M.D."
  ],
  "fury": [
    "Rage",
    "\"Beware the ___ of a patient man\": Dryden",
    "Anger",
    "One of a Greek trio",
    "Alecto, Megaera or Tisiphone",
    "Alecto, Megaera or Tisiphone",
    "Hurricane's force",
    "Storm",
    "TV horse introduced in 1955 ... or a Plymouth model introduced in 1956",
    "1950's-60's TV horse",
    "Wrath",
    "Fierceness"
  ],
  "make": [
    "\"Early in life, I had learned that if you want something, you had better ___ some noise\": Malcolm X",
    "\"___ me!\"",
    "Car brand",
    "Rhyming opposite of break",
    "\"Let's ___ a Deal\"",
    "Fabricate",
    "Concoct",
    "Cook up",
    "Produce",
    "Fabricate",
    "Fashion",
    "Manufacture",
    "Urn",
    "Whip up",
    "Fashion",
    "Mercury or Saturn",
    "Ford or Lincoln",
    "Fabricate",
    "Cobble together",
    "Ford or Chevrolet",
    "\"___ my day!\"",
    "Turn into a success",
    "Be in time for",
    "Lincoln, for one",
    "Brand",
    "Fulfill, as a bid",
    "Approach, with \"for\"",
    "Construct",
    "Oldsmobile, e.g.",
    "With 60-Down, bid",
    "Auto brand",
    "Line of cars",
    "Earn"
  ],
  "chen": [
    "Fifth-most-common family name in China",
    "Julie ___, host of TV's \"Big Brother\"",
    "TV host Julie",
    "Julie of TV's \"Big Brother\"",
    "\"Big Brother\" host Julie",
    "Julie ___, host of TV's \"Big Brother\"",
    "\"Big Brother\" host Julie",
    "Julie of \"The Early Show\"",
    "Julie of \"The Early Show\"",
    "Sixth-century Chinese dynasty"
  ],
  "some": [
    "A few",
    "More than none",
    "\"That's ___ nerve!\"",
    "A handful of",
    "Certain",
    "Part (of)",
    "A little",
    "More than none but less than all",
    "A taste",
    "A few",
    "Four or five, say",
    "Not all",
    "Body opening?",
    "Adjective-forming suffix",
    "\"___ help you are!\"",
    "Certain",
    "Certain",
    "Certain",
    "Partly",
    "\"___ nerve!\"",
    "\"Try ___\"",
    "A portion of",
    "A few",
    "Not a lot",
    "Amount between none and all",
    "Certain",
    "Not all",
    "Amount between all and none",
    "Certain",
    "A few",
    "A few",
    "A few",
    "Not all",
    "Not all",
    "A portion",
    "Five or ten, say",
    "A few",
    "A few",
    "Partly",
    "Not all",
    "A few",
    "Any",
    "A few",
    "\"___ Like It Hot\"",
    "Not all",
    "To a degree",
    "A smattering of",
    "A bit",
    "A few",
    "Incompletely",
    "A portion",
    "A portion",
    "A smattering of",
    "A bit",
    "Partially",
    "Approximately",
    "Certain",
    "\"___ Like It Hot\"",
    "A few",
    "A few",
    "\"___ nerve!\"",
    "A bit",
    "Between 0% and 100%",
    "A few",
    "Certain",
    "Not all",
    "A little",
    "\"___ Like It Hot\"",
    "A portion",
    "Ending with win or hand",
    "Between all and none",
    "Not all",
    "\"Have ___!\"",
    "\"Have ___!\"",
    "Time starter",
    "A few",
    "Not too many",
    "Several",
    "A bit",
    "A number of",
    "A little"
  ],
  "gorp": [
    "Hiker's snack",
    "Trail mix",
    "Trail mix",
    "Nosh on the trail",
    "Trail rider's concoction",
    "Backpacker's bagful",
    "Hiking snack",
    "Nutty stuff",
    "Hiker's snack",
    "Hiker's snack",
    "Trail fare",
    "Climber's snack",
    "Trail mix",
    "Backpack snack",
    "High-energy snack",
    "Trail mix"
  ],
  "vine": [
    "Tarzan's transport",
    "Plant on a trellis",
    "Trellis-climbing plant",
    "Tree hugger, as it were",
    "What Tarzan swings on",
    "Tree hugger?",
    "Street crossing Hollywood in Hollywood",
    "Grape or watermelon plant",
    "Poison ivy, e.g.",
    "Trunk attachment?",
    "2012 Twitter acquisition",
    "Ivy growth",
    "Kudzu, e.g.",
    "Hollywood crosser",
    "Melon's site",
    "Plant with tendrils",
    "Plant with tendrils",
    "Handful for Tarzan",
    "Ivy, for one",
    "Grape or watermelon",
    "Jungle climber",
    "Street crossing Hollywood",
    "Climber",
    "Botanical creeper",
    "Kudzu, for one",
    "Tarzan's transport",
    "Tarzan's transport",
    "Hollywood crosser",
    "Grape plant",
    "Ivy feature",
    "Hollywood crosser",
    "Tarzan's transport",
    "Morning glory, e.g.",
    "Grape place",
    "Jasmine, for one",
    "Hollywood cross street",
    "Kudzu, for one",
    "Ivy plant",
    "Jasmine or morning glory",
    "Creeper",
    "One climbing the walls",
    "Napa Valley sight",
    "Kudzu, e.g.",
    "Creeper",
    "Hollywood crosser"
  ],
  "jilt": [
    "Ditch at the last moment",
    "Stand up at the altar",
    "Leave at the altar",
    "Ghost at the altar?",
    "Say \"I don't\" to instead of \"I do\"?",
    "Leave standing at the altar",
    "Spurn, as a lover",
    "Leave at the altar",
    "Leave at the altar, say",
    "Dump, so to speak",
    "Abandon",
    "End it with suddenly",
    "Leave at the altar",
    "Leave high and dry",
    "Drop without warning",
    "Abruptly dump, as a lover",
    "Dump without warning",
    "Reject, as a beau",
    "Send a Dear John letter",
    "Dump, so to speak",
    "Leave alone",
    "Drop abruptly",
    "Dump",
    "Send a Dear John letter",
    "Leave in the lurch",
    "Send a Dear John letter"
  ],
  "sign": [
    "Ink",
    "+ or -",
    "Leo or Libra",
    "Omen",
    "Leo, for example",
    "Endorse",
    "+, $ or @",
    "Omen",
    "11-Down, for one",
    "Sharp or flat",
    "Leo or Libra",
    "Crop circle, some believe",
    "\"It's a ___\"",
    "Accept a contract",
    "Plus or minus, say",
    "Foreshadowing",
    "Billboard",
    "Stop or Do Not Pass",
    "Communicate silently",
    "Aries or Libra",
    "Nod, maybe",
    "Affix one's John Hancock",
    "Ink",
    "Affix one's John Hancock",
    "Not just initial",
    "Speak manually",
    "Gemini, for one",
    "Communicate by hand",
    "Make legally effective",
    "Pickup line request",
    "Communicate silently",
    "Endorse",
    "Writing on the wall, e.g.",
    "Omen",
    "Indicator",
    "Ink a contract",
    "Leo, for one",
    "Billboard",
    "Converse with the deaf",
    "Speak to deaf ears",
    "An auction bidder might give a high one",
    "Communicate silently",
    "Omen",
    "Speak with the hands",
    "Billboard",
    "Speak with the hands",
    "\"___ here\"",
    "Ink",
    "Convey via Ameslan",
    "Speak with one's hands",
    "Omen or \"Open\", e.g."
  ],
  "cusp": [
    "Brink of transition",
    "Transition point",
    "Edge",
    "Brink",
    "Inflection point",
    "Point of transition",
    "Brink",
    "Border",
    "Zodiacal border",
    "Transition point",
    "Where two branches of a curve meet, in math",
    "Point that marks the beginning of a change",
    "Astrological gray area",
    "Edge",
    "Crescent point",
    "Where astrological signs meet",
    "Pointed end",
    "Astrological transition point",
    "Crescent point",
    "Zodiacal delineation",
    "Where Virgo meets Libra, e.g.",
    "Crescent's tip",
    "Astrological point",
    "Zodiacal border"
  ],
  "gray": [
    "Gloomy, weatherwise",
    "With 32-Down, ambiguity ... or a hint to this puzzle's theme",
    "Neutral hue",
    "Color of an overcast sky",
    "Ash, e.g.",
    "Dreary",
    "Overcast",
    "Overcast",
    "Neutral hue",
    "Just for Men target",
    "Overcast",
    "Pre-dyed hair color, often",
    "Like a foreboding sky",
    "With 8-Down, source of an ethical dilemma",
    "Battleship shade",
    "South side?",
    "Confederate uniform color",
    "Pre-dye hair shade, often",
    "Lee's uniform color",
    "Looking like rain",
    "Overcast",
    "Black and white?",
    "Civil War side, with \"the\"",
    "Like many a winter sky",
    "Lee side?",
    "Lee side?",
    "See 107-Across",
    "Overcast",
    "Gloomy",
    "Equine hue"
  ],
  "dime": [
    "Two-fifths of one quarter",
    "Five-and-___",
    "Stopping point?",
    "Small change?",
    "Small change",
    "10 bucks, in slang",
    "Example of change",
    "Tiniest change",
    "Small change",
    "Coin with F.D.R.'s image",
    "Stopping point?",
    "Torch bearer",
    "Stopping point?",
    "Coin with a torch on the back",
    "Coin with F.D.R.'s profile",
    "Five-and-___",
    "It's smaller than a penny",
    "Torch site",
    "Torch site",
    "\"Thin\" coin",
    "Turning point?",
    "\"It's your ___\"",
    "Cost of an old phone call",
    "10-year prison sentence, in gang slang",
    "Makeshift screwdriver",
    "Site for Franklin Roosevelt",
    "Thin coin",
    "Stopping place?",
    "Torch bearer?",
    "Cost for a dozen, in a phrase",
    "Element of change",
    "10-year sentence, in slang",
    "Kind of store, once",
    "Symbol of thinness",
    "\"Thin\" coin",
    "Half of it is a nickel",
    "Bit of change",
    "See 42-Across",
    "F.D.R. locale",
    "Bygone pay phone amount",
    "Kind of store",
    "Five's partner",
    "Onetime phone call cost",
    "Cost of a minute call, maybe",
    "Bygone phone call cost",
    "___-a-minute (call rate)",
    "Stopping point?",
    "Torch bearer",
    "Type of novel",
    "Old phone call cost",
    "Kind of novel",
    "Stop on a ___",
    "Two nickels"
  ],
  "knit": [
    "Like some brows and berets",
    "Join",
    "Work with needles",
    "Make a jumper, say",
    "Like a cardigan",
    "Drawn together",
    "Intertwined",
    "Work with yarn",
    "Like many a beanie",
    "Sweater, e.g.",
    "Use a ball winder",
    "Linked",
    "Make some loops",
    "Put in stitches",
    "Grow together",
    "Grow together",
    "Furrow, as the brow",
    "Make a sweater, say",
    "Make a muffler, maybe",
    "Grow together",
    "Work on a muffler, say",
    "Pullover, e.g.",
    "Furrow",
    "Heal",
    "Link",
    "Make bootees, e.g.",
    "Make a sweater",
    "Work with needles",
    "Like a polo shirt",
    "Make a sweater",
    "Make winter wear, maybe",
    "Use needles",
    "Contract into folds",
    "Make 33-Across, say",
    "Wrinkled, as a brow",
    "Tricot, for one",
    "Stocking cap, for example",
    "Put in stitches",
    "Sweater, maybe",
    "Jersey, e.g.",
    "Make stockings, say",
    "Interlace",
    "Come together",
    "Furrow",
    "Join",
    "Word with close or hand",
    "Make bootees, say",
    "Heal, in a way",
    "Join",
    "Jersey, e.g.",
    "Heal, as bones",
    "Like some brows",
    "Sweater",
    "Joined together",
    "Jersey, e.g.",
    "Sweater",
    "Make an afghan",
    "Interlock",
    "Sweater, usually",
    "Make a scarf",
    "Sweater or sock",
    "Intertwine",
    "Grow together",
    "Make stockings",
    "Mend, as bones",
    "Make a cable stitch",
    "Make a sweater",
    "Grow together again",
    "Make a bootee"
  ],
  "ruby": [
    "Color that comes from the Latin for \"red\"",
    "Shade of red",
    "Shade of red",
    "Like Dorothy's slippers",
    "Deep red",
    "Color of Dorothy's slippers",
    "[40]",
    "Like some lips",
    "Like some lips",
    "Slippers' color in \"The Wizard of Oz\"",
    "Red gem",
    "Red shade",
    "Tiny type size",
    "Color of some hummingbird throats",
    "Lip shade",
    "Shade of red",
    "Shade of red"
  ],
  "tots": [
    "Little 'uns",
    "Little ones",
    "French fries alternative, informally",
    "Little 'uns",
    "See 48-Down",
    "Preschoolers",
    "Tykes",
    "Little ones",
    "Adds (up)",
    "Pre-K attendees",
    "Pre-K enrollees",
    "School charges?",
    "Young 'uns",
    "Tater ___",
    "Sandbox frequenters",
    "Tykes",
    "Preschoolers",
    "Bambini",
    "Day-care charges",
    "Day care charges",
    "Squirts",
    "Little ones",
    "Prekindergartners",
    "Small fry",
    "Babysitters' charges",
    "Adds (up)",
    "Small fry",
    "Day-care charges",
    "Preschoolers",
    "Preschoolers",
    "Young 'uns",
    "Little tykes",
    "Rug rats",
    "Peewee people",
    "Kindergarteners",
    "Small fry",
    "Little ones",
    "Small fry",
    "Rug rats",
    "Wee bits o' whiskey",
    "Wee ones",
    "Preschoolers",
    "Day-care users",
    "Kids",
    "Young 'uns",
    "Wee bairns"
  ],
  "cove": [
    "Natural recess",
    "Spot to lay anchor",
    "Quiet fishing spot",
    "Sheltered shoreline spot",
    "Pirate's hideout",
    "Small bay",
    "Arm that's tucked away",
    "Smuggler's hideaway",
    "Shore recess",
    "Natural harbor",
    "Boater's haven",
    "Inlet",
    "Cabot ___ (\"Murder, She Wrote\" setting)",
    "Yacht spot",
    "Place to moor a boat",
    "Mooring spot",
    "Pirate hide-out, often",
    "Quiet place to fish",
    "Coastal niche",
    "Sheltered bay",
    "*Place to moor",
    "Coastal feature",
    "Spot to moor",
    "Sheltered inlet",
    "Quiet fishing spot",
    "Place to moor a boat",
    "Place to moor",
    "Sheltered spot",
    "Place to moor",
    "Inlet",
    "Sheltered bay",
    "Place to moor a boat",
    "Mooring spot",
    "Bay",
    "Safe harbor",
    "Place to moor a boat",
    "Sheltered spot",
    "Dock site",
    "Shoreline shelter",
    "Inlet",
    "Sheltered nook",
    "Sheltered nook",
    "Inlet",
    "Where pirates moor",
    "Little bay",
    "Mooring site",
    "Shoreline recess",
    "Shoreline indentation",
    "Cabot ___ (\"Murder, She Wrote\" town)",
    "Bight"
  ],
  "pant": [
    "Hot dogs do this",
    "Short, quick breath",
    "Huff and puff",
    "Huff and puff",
    "What hot dogs do",
    "Breathe like a tired runner",
    "Breathe rapidly",
    "Gasp",
    "What dogs do in lieu of sweating",
    "What a hot dog might do",
    "___ leg",
    "Breathe hard, as after running",
    "Be winded",
    "Sound a hot dog makes?",
    "Have a series of sudden inspirations?",
    "Huff and puff",
    "Hot dog breath?",
    "Big huff?",
    "Huff and puff",
    "Runners do it",
    "Imitate a hot dog",
    "Huff and puff",
    "Huff and puff",
    "Huff and puff",
    "Huff and puff",
    "Show windedness",
    "Have dog breath?",
    "___ cuffs",
    "Sign of overexertion",
    "Be in need of air, perhaps",
    "Kind of leg",
    "Breathe hard",
    "Symbol of desire",
    "Gasp",
    "Dog's breath",
    "Have a yen (for)",
    "Huff and puff",
    "Puff",
    "Dog's breath",
    "Puff",
    "Utter breathlessly",
    "Huff and puff"
  ],
  "cork": [
    "Material harvested for its hydrophobic properties",
    "Bottle stopper",
    "Champagne bottle stopper",
    "Cab stopper?",
    "It's often at the end of a bottleneck",
    "Stop on a wine tour?",
    "Popped topper",
    "One may pop on New Year's Eve",
    "Blarney Castle's county",
    "Irish county",
    "It's popped on New Year's Eve",
    "What you might push a pushpin in",
    "Bottle part that goes \"pop!\"",
    "Fishing float",
    "*Angler's float",
    "Something to pop",
    "Stopper",
    "Stop up",
    "Stop",
    "Party popper?",
    "Stop at a vineyard",
    "Stopper",
    "New Year's popper",
    "Stopper"
  ],
  "spca": [
    "Org. concerned with lab safety?",
    "Org. with a pet cause?",
    "Pound sign?",
    "Zoophilist's org.",
    "Pet cause, for short",
    "Pet lovers' org.",
    "Watchdog org.?",
    "Persian defense org.?",
    "Shelter grp.",
    "Pet-friendly org.",
    "Pet lover's org.",
    "Watchdog org.?",
    "Org. advocating pet adoption",
    "Pet safety org.",
    "Pet protection agcy.",
    "Pet cause, in brief",
    "Pet lovers' org.",
    "Pound sign?",
    "Protector of stray cats and dogs, for short",
    "Org. concerned with toy safety",
    "Humane org.",
    "Pet care specialists, for short",
    "Grp. concerned with feeding the kitty",
    "Pet adoption org.",
    "Org. providing assistance to Afghans and Persians",
    "Vet employer, maybe: Abbr.",
    "Org. that shelters dogs and cats",
    "Pet adoption agcy.",
    "Pet lovers' org.",
    "Adoption advocacy org.",
    "Pet advocacy org.",
    "Pet welfare org.",
    "Shelter grp.",
    "Org. for cat and dog lovers",
    "Shelter org.",
    "Pet welfare org.",
    "Pet lovers' org.",
    "Pet adoption org.",
    "Pound sign letters",
    "Org. that promotes adoption",
    "Org. for the humane treatment of pets",
    "Org. for which a D.V.M. might work",
    "Pet lovers' org.",
    "Adoption agcy.",
    "Org. offering creature comforts?",
    "Grp. concerned with lab safety?",
    "Provider of creature comforts?: Abbr.",
    "Humane org.",
    "Shelter org.",
    "Shelter provider: Abbr.",
    "Humane grp.",
    "Spot remover?: Abbr.",
    "Shelter grp.",
    "Labrador retriever, for short?",
    "Pet protectors' org.",
    "Animal caretakers, for short",
    "Adoption agcy.",
    "Humane org.",
    "Spot checker?: Abbr.",
    "Pet lovers' org.",
    "Watchdog org.?",
    "Watchdog org.?",
    "Pet lovers' org.",
    "Humane org.",
    "Labrador retriever?",
    "Watchdog org.",
    "Pet protector, for short",
    "Shelter grp.",
    "Stray home",
    "Abbreviation for a pound",
    "Org. that safeguards pets",
    "Zoophilist's org.",
    "Org. that shelters strays",
    "Protection grp.",
    "Zoophilist's org.",
    "Adoption agcy.",
    "Watchdog watchdog: Abbr.",
    "Vet's employer: Abbr.",
    "Initials for a pound"
  ],
  "door": [
    "Symbol of opportunity",
    "Choice on \"Let's Make a Deal\"",
    "Choice on \"Let's Make a Deal\"",
    "It may swing or revolve",
    "Way in or out",
    "Word with stage or storm",
    "It might have golden locks",
    "Part of the 36-Across where the \"Ninety-Five Theses\" were posted",
    "Way in or way out",
    "It can be left 10-Across",
    "Word with fire or trap",
    "Way out",
    "One of three on \"Let's Make a Deal\"",
    "Symbol of opportunity",
    "\"If opportunity doesn't knock, build a ___\": Milton Berle",
    "\"Let's Make a Deal\" choice",
    "Opportunity, metaphorically",
    "Outlet",
    "Way out",
    "Peephole's place",
    "Opportunity, metaphorically",
    "\"Let's Make a Deal\" choice",
    "\"Let's Make a Deal\" choice",
    "Clue game board space",
    "It can be cracked",
    "It may be cracked open",
    "\"Let's Make a Deal\" choice",
    "One can be shown to you",
    "It could be revolving",
    "With 102-Across, dashboard warning light",
    "Entrance",
    "\"Let's Make a Deal\" choice",
    "Kind of prize",
    "One can be shown it",
    "It may get into a jamb",
    "Feature of an exit strategy?",
    "Word with chain or prize",
    "Choice on \"Let's Make a Deal\"",
    "It may get into a jamb",
    "Way out",
    "It's shown to some",
    "Opportunity, so to speak",
    "Way out",
    "Opportunity, metaphorically",
    "It may be a trap",
    "Entryway",
    "Way out",
    "Knob site",
    "Revolver, perhaps",
    "It's in a jamb",
    "Way out",
    "\"In\" place",
    "Way out",
    "\"Let's Make a Deal\" option",
    "Start with step or stop",
    "What an ostiary guards",
    "\"Let's Make a Deal\" choice",
    "Kind of prize",
    "Ostiary's post",
    "Entranceway",
    "\"Let's Make a Deal\" option",
    "It gets into a jamb",
    "Kind of prize",
    "It comes with a catch",
    "Postern, e.g.",
    "It's in a jamb",
    "\"Let's Make a Deal\" choice",
    "Way out",
    "\"Let's Make a Deal\" option",
    "Way out",
    "Jim Morrison, e.g."
  ],
  "navy": [
    "Blue shade",
    "Blue shade",
    "Shade of blue",
    "College football rival of 110-Across",
    "Shade of blue",
    "Shade of blue",
    "Alma mater of football great Roger Staubach",
    "The Midshipmen",
    "School whose 1910 football team went undefeated and unscored upon",
    "Dark shade of blue",
    "Shade of blue",
    "Armada",
    "Sub group",
    "Blue shade",
    "Blue shade",
    "Dark shade",
    "Its mascot is a goat",
    "Enlistee's choice",
    "Shade of blue",
    "Shade of blue",
    "Kind of bean",
    "Fleet",
    "Business suit shade",
    "With 36-Down, like some service uniforms",
    "Armed Forces option",
    "Dark blue",
    "Army competitor",
    "Dark blue",
    "The Midshipmen"
  ],
  "glow": [
    "What crystal jellies do when disturbed",
    "Give off light, as a firefly",
    "Shine",
    "What fireflies do",
    "Radiant look",
    "___ stick (trick-or-treater's accessory)",
    "U.F.O. feature, maybe",
    "Light from a lightning bug, e.g.",
    "Soft light",
    "Light from a halo",
    "Healthy look",
    "Radiation sign",
    "Moon shine",
    "Candle's light",
    "Gentle firelight",
    "Be visibly elated",
    "Ardor"
  ],
  "baha": [
    "___ Men (one-hit wonder group of 2000)",
    "___ Men, group with the 2000 hit \"Who Let the Dogs Out\"",
    "___ Men (\"Who Let the Dogs Out\" group)",
    "___ Men (\"Who Let the Dogs Out\" group)",
    "___ Men (\"Who Let the Dogs Out\" band)",
    "___ Men (\"Who Let the Dogs Out\" group)",
    "___ Men (\"Who Let the Dogs Out\" group)",
    "___ Men (\"Move It Like This\" group)",
    "___ Men (\"Who Let the Dogs Out\" group)",
    "___ Men with the 2000 hit \"Who Let the Dogs Out\"",
    "___ Men (\"Who Let the Dogs Out\" band)",
    "\"Who Let the Dogs Out\" group, the ___ Men"
  ],
  "baja": [
    "___ California",
    "Cabo's peninsula",
    "Cabo's peninsula",
    "___ California",
    "___ California",
    "Tijuana setting, informally",
    "State bordering California, informally",
    "Mexico's ___ California",
    "___ Fresh (Tex-Mex chain)",
    "Tijuana's locale",
    "___ Fresh (Tex-Mex chain)",
    "___ California",
    "Lower California, informally",
    "Cabo's peninsula",
    "___ Fresh (Tex-Mex restaurant chain)",
    "Tijuana's peninsula",
    "Opposite of alta",
    "State bordering the Pacific, informally",
    "___ 1000 (annual Mexican race)",
    "North American peninsula",
    "Lower California, for short",
    "___ California",
    "Home of Ensenada, informally",
    "Mexican peninsula",
    "___ 1000, annual Mexican off-road race",
    "Popular Mexican tourist destination",
    "Californian's vacation destination, maybe",
    "Low: Sp.",
    "___ California",
    "Tijuana locale",
    "Alta's opposite",
    "Mexican peninsula",
    "Low, in La Paz",
    "Mexican peninsula",
    "___ California",
    "___ California",
    "Mexican peninsula",
    "Mexican peninsula",
    "Lower, south of the border",
    "Mexicali locale, for short",
    "Peninsula south of California",
    "California peninsula",
    "Tijuana locale",
    "Lower California",
    "Lower California, familiarly",
    "Mexicali area",
    "___California",
    "___ California",
    "Tijuana's locale, informally",
    "___ California",
    "___ California",
    "Californian's vacation spot, informally",
    "Mexicali locale",
    "Mexican peninsula"
  ],
  "fops": [
    "Dandies",
    "Dandies",
    "Dandies",
    "Dandies",
    "Dandies",
    "Beau Brummells",
    "Dandies",
    "Dandy sorts",
    "Swells",
    "Dandy fellows",
    "Natty sorts",
    "Fancy dressers",
    "Stylish dressers",
    "Swells",
    "Popinjays",
    "Dandies"
  ],
  "spry": [
    "Moving around very nicely, thank you",
    "Lively for one's age",
    "Lively for one's age",
    "Quick on one's feet",
    "Nimble, especially for one's age",
    "Light on one's feet",
    "Able to move nicely",
    "Agile for one's age",
    "Not needing a cane, maybe",
    "Not needing a cane, say",
    "Opposite of doddery",
    "Not showing one's age, say",
    "Agile for one's age",
    "Lively",
    "Moving well",
    "Complimentary adjective for a grandpa",
    "Not at all creaky",
    "Surprisingly agile",
    "Like Santa Claus",
    "Nimble",
    "Quick",
    "Full of life for one's age",
    "Pretty agile for one's age",
    "Moving briskly",
    "Nimble",
    "Moving well for one's age",
    "Not even close to creaky",
    "Surprisingly agile for one's age",
    "Nimble for one's age",
    "Nimble for one's age",
    "Quick",
    "Nimble",
    "Agile, for a senior",
    "Energetic for one's age",
    "Surprisingly lively for one's age",
    "Nimble",
    "Not at all stiff",
    "Moving easily",
    "Agile for one's age",
    "Not showing one's age, in a way",
    "Full of pep",
    "Nimble",
    "Pretty agile for one's age",
    "Not at all lethargic",
    "Surprisingly lively",
    "Active",
    "Nimble",
    "Agile",
    "Lively",
    "Nimble",
    "Light-footed",
    "Lively, especially for one's age",
    "Light on one's feet",
    "Nimble",
    "Not slowing down a bit",
    "Active",
    "Hardly heavy-footed",
    "Peppy",
    "Active",
    "Twinkle-toed",
    "Moving well",
    "Lively",
    "Still getting around",
    "Agile",
    "Nimble",
    "Peppy",
    "Like a little old lady in tennis shoes?",
    "Not lethargic",
    "Nimble",
    "Getting around well",
    "Peppy",
    "Agile",
    "Nimble",
    "Limber",
    "Active",
    "Nimble",
    "Nimble",
    "Still active",
    "Nimble",
    "Light-footed"
  ],
  "perp": [
    "___ walk",
    "___ walk",
    "Mug shot subject",
    "One who gets booked, informally",
    "One nabbed by the fuzz",
    "One in custody, informally",
    "___ walk",
    "Felon, to a cop",
    "Kind of walk",
    "Cop's target",
    "Kind of walk",
    "Arrested suspect, informally",
    "Kind of walk",
    "Person in handcuffs, for short",
    "Collared one",
    "Miranda warning receiver, informally",
    "One chased in a car chase, for short",
    "One picked out of a lineup, informally",
    "Suspect, in police lingo",
    "One may be fingered",
    "Lawbreaker, in police lingo",
    "Cop's target",
    "Crook, to a cop",
    "Suspect, in cop lingo",
    "Suspect, to a cop",
    "Crook, in cop lingo",
    "Potential con",
    "Cop's collar",
    "Cop's target",
    "Whom a copper apprehends",
    "Guilty one, in copspeak",
    "Busted party",
    "Doer in a whodunit",
    "Cop's catch",
    "One for the record books?"
  ],
  "rice": [
    "University whose name is also a food",
    "Paella base",
    "Surname of national security advisers under both Bush 43 and Obama",
    "Houston university",
    "N.F.L. star ejected from 102-Across",
    "Crop in a paddy",
    "The Owls of Conference USA",
    "Crosstown rival of the University of Houston",
    "Staple of Chinese cuisine",
    "Minute ___",
    "Food grown in a paddy",
    "Source of sake",
    "Source of about 20% of the calories consumed by humanity",
    "Dish that may be \"sticky\"",
    "\"Dirty\" dish",
    "It might be wild or dirty",
    "See 44-Down",
    "Sake source",
    "Bed of ___",
    "Ladder, part 7",
    "Sushi ingredient",
    "Major Thai export",
    "Bush cabinet member",
    "Wedding staple",
    "Houston university",
    "Bowlful accompanying teriyaki",
    "Spanish ___",
    "Jambalaya need",
    "Force through a sieve",
    "See 34-Across",
    "The Owls of the N.C.A.A.",
    "Something needed for your sake?",
    "Serving with chop suey",
    "Paella need",
    "It may be served in a bed",
    "Sukiyaki side dish",
    "Kind of pudding",
    "Wedding fling?",
    "It may rain after a wedding",
    "Serving with chop suey",
    "\"Dirty\" Cajun dish",
    "Paella base",
    "Hopping John ingredient",
    "\"Dirty\" dish",
    "Wild ___",
    "Western Athletic conference team",
    "It may be polished",
    "Uncle Ben's specialty",
    "Pilaf staple",
    "Houston university",
    "Red beans go-with",
    "A bed in the kitchen?",
    "It may be polished",
    "Kind of paper",
    "Paddy crop",
    "Wedding shower?",
    "Almost puree",
    "Bridal shower?",
    "The Owls of the Western Athletic Conference",
    "Serving with sushi",
    "Houston university",
    "It may be polished",
    "Houston university",
    "Food found in a bed",
    "Minute ___",
    "Jambalaya need",
    "\"Interview With the Vampire\" author Anne",
    "Houston university",
    "Houston campus",
    "It's tossed after weddings",
    "\"Minute\" dish",
    "Houston university",
    "Uncle Ben's dish",
    "\"We, the People\" playwright",
    "Wedding shower?",
    "Houston school",
    "Sushi go-with",
    "Source of sake",
    "Minute ___",
    "\"Lasher\" writer",
    "\"Interview With the Vampire\" author"
  ],
  "owes": [
    "Is indebted to",
    "Isn't square",
    "Is shy, in a way",
    "Isn't in the black",
    "Is indebted to",
    "Gets a Venmo request, say",
    "Is indebted to",
    "Has bills",
    "Sees red?",
    "Is beholden to",
    "Is in debt",
    "Has debts",
    "Is in the hole",
    "Has a mortgage, say",
    "Is indebted to",
    "Has debts",
    "Takes credit?",
    "Isn't square, say",
    "Takes credit?",
    "Has debts",
    "Has a negative net worth",
    "Is in the hole",
    "Has a tab",
    "Is in the red",
    "Gets charged up?",
    "Is light",
    "Is shy, in a way",
    "Has debts",
    "Is in the red",
    "Has obligations",
    "Is shy",
    "Isn't in the clear?",
    "Is in hock",
    "Gets calls from collectors, say",
    "Is into",
    "Has an outstanding figure?",
    "Is short",
    "Is beholden to",
    "Is in the red",
    "Falls behind, in a way",
    "Is in the red",
    "Has chits out",
    "Has markers out",
    "Isn't solvent",
    "Has debts",
    "Runs a tab",
    "Has loans",
    "Is in arrears",
    "Must pay",
    "Is in the hole",
    "Has chits out",
    "Is behind",
    "Has a tab",
    "Has chits out",
    "Isn't keeping up with bills",
    "Can't pay",
    "Has markers out",
    "Has a tab",
    "Has chits out",
    "Isn't in the black",
    "Comes up short",
    "Has a tab",
    "Has a mortgage",
    "Is beholden to",
    "Isn't free and clear",
    "Has liabilities",
    "Sees red?",
    "Has something outstanding",
    "Has a tab",
    "Has creditors"
  ],
  "funk": [
    "\"I only got a seventh-grade education, but I have a doctorate in ___\": James Brown",
    "Sly and the Family Stone genre",
    "Blue state?",
    "Musical genre featuring slap bass",
    "Depression",
    "Name on a dictionary",
    "See 47-Across",
    "Depression"
  ],
  "acht": [
    "Funf + drei",
    "German digit",
    "Drei + funf",
    "Eight, to Dieter",
    "Vier + vier",
    "Zwei cubed",
    "Number of Planeten",
    "Half of sechzehn",
    "Number after sieben",
    "Zwei x vier",
    "Berlin octet",
    "Sieben follower",
    "Funf und drei",
    "One more than sieben",
    "Sieben follower",
    "Zwei cubed",
    "Number after sieben",
    "Eight, to Hans und Franz"
  ],
  "amps": [
    "Roadies' loads",
    "Some stage equipment",
    "Roadies' equipment",
    "Concert pieces",
    "Boosts, with \"up\"",
    "Boxes in an arena, maybe",
    "Rock music boosters",
    "Gig gear",
    "Units in Ohm's law",
    "Concert lineup",
    "Band aids",
    "Jazzes (up)",
    "Hookups at a gig",
    "Some sound equipment",
    "Audio equipment",
    "Volume enhancers",
    "Tour gear",
    "Rock band boosters",
    "Energizes, with \"up\"",
    "Stack at a rock concert",
    "Charges (up)",
    "Ratchets (up)",
    "Concert pieces",
    "Sound investments?",
    "Hypes (up)",
    "Some concert gear",
    "Concert blasters",
    "Increases, with \"up\"",
    "Sound boxes at a concert",
    "Sound boosters",
    "Concert stage equipment",
    "Stereo parts",
    "Current units",
    "Concert pieces",
    "Concert stack",
    "Intensifies, with \"up\"",
    "They're schlepped on tours",
    "Roadies carry them",
    "Gear to help you hear",
    "Concert blasters",
    "Concert equipment",
    "Band boosters",
    "Concert equipment",
    "Concert equipment",
    "Boosts, with \"up\"",
    "Concert hall equipment",
    "Roadies' loads",
    "Music boosters?",
    "Concert equipment",
    "Concert gear",
    "Ratchets (up)",
    "Intensifies, with \"up\"",
    "Rock concert blasters",
    "Excites, with \"up\"",
    "Hearing aids?",
    "Sound boosters",
    "Concert equipment",
    "Concert blasters",
    "Intensifies, with \"up\"",
    "They may stand at the side of the stage",
    "Some stage equipment",
    "Boosters, for short",
    "Roadie's load",
    "Concert array",
    "Concert array",
    "Concert gear",
    "Electrical units",
    "Bandstand boxes",
    "Band gear",
    "Word on a fuse",
    "Concert equipment",
    "Current units",
    "Big concert equipment",
    "Stereo parts",
    "Fuse units",
    "Woodstock gear",
    "Rockers' equipment",
    "Concert gear",
    "Roadie's responsibility",
    "Rock concert necessities",
    "Woodstock gear",
    "Roadies' loads",
    "Rock blasters?",
    "Rock concert equipment",
    "Coulombs per second",
    "Current matters",
    "Rock band equipment",
    "Electrical units",
    "Rock band equipment",
    "Sound equipment",
    "Galvanometers measure them",
    "Current units, for short",
    "Rock band equipment",
    "Concert hall equipment",
    "Music enhancers",
    "Rock band equipment"
  ],
  "beto": [
    "Texas politico O'Rourke",
    "Texas politico O'Rourke",
    "Politician O'Rourke"
  ],
  "reup": [
    "Enlist again",
    "Extend one's tour of duty",
    "Sign on for another tour",
    "Enlist again",
    "Sign on again",
    "Volunteer for another tour",
    "Extend, as an employment contract",
    "Continue one's military service",
    "Enroll for another year of duty",
    "Enlist again",
    "Extend one's service",
    "Go back for more, in a way",
    "Sign on again",
    "Go for additional service",
    "Start another tour",
    "Not go out of service?",
    "Pay one's dues again, say",
    "Start another tour",
    "Start another tour",
    "Extend one's tour",
    "Sign on for another tour",
    "Extend one's service life",
    "Extend one's service",
    "Go for another tour",
    "Start another tour",
    "Accept another tour of duty",
    "Sign on, as for another tour of duty",
    "Go for more duty",
    "Do another hitch",
    "Commit to another hitch",
    "Start another hitch",
    "Enlist again",
    "Start another hitch",
    "Go for another tour",
    "Enlist anew",
    "Ask for more service",
    "Sign on for another tour",
    "Start another hitch",
    "Enlist again"
  ],
  "nsec": [
    "Tiny fraction of a min.",
    "Tiny amount of time: Abbr.",
    "Tiny amount of time: Abbr.",
    "Instant: Abbr.",
    "Minuscule div. of a minute",
    "Approx. time it takes for light to travel one foot",
    "Fraction of time: Abbr.",
    "Tiny div. of a minute",
    "Tiny amount of time: Abbr.",
    "It's faster than the blink of an eye: Abbr.",
    "Minute minute part: Abbr.",
    "Tiny bit of time: Abbr.",
    "Tiny time interval: Abbr.",
    "Tiny fraction of time: Abbr.",
    "Fraction of a fraction of a min.",
    "Fraction of a min.",
    "Minute part of a minute: Abbr.",
    "Minuscule part of a min.",
    "Minute fraction of a min.",
    "Tiny time unit: Abbr.",
    "Tiny amount of time: Abbr.",
    "Instant: Abbr.",
    "Minuscule part of a 34-Down: Abbr.",
    "Minute part of a minute: Abbr.",
    "Fraction of an instant: Abbr.",
    "Minute part of a min.",
    "Minute part of a minute: Abbr.",
    "Tiny fraction of a min.",
    "Small interval of time: Abbr.",
    "Tiny fraction of a min.",
    "Tiny time unit: Abbr.",
    "Tiny bit of time: Abbr.",
    "Tiny time period: Abbr.",
    "Tiny unit of time: Abbr.",
    "Tiny fraction of a min.",
    "Minute part of a minute: Abbr.",
    "Tiny time period: Abbr.",
    "Tiny fraction of time: Abbr.",
    "Instant: Abbr.",
    "Tiny fraction of a min.",
    "Instant: Abbr.",
    "Tiny fraction of a min.",
    "Tiny fraction of a min."
  ],
  "lowe": [
    "Rob of \"St. Elmo's Fire\"",
    "Home-improvement chain eponym",
    "Rob of \"Parks and Recreation\"",
    "Major home-improvement chain founder",
    "Founder of a major appliance chain",
    "E. S. ___, game company that produced Yahtzee and Bingo",
    "Rob of \"Parks and Recreation\"",
    "Brat Pack name",
    "Lucius S. ___, hardware chain store eponym",
    "Brat Pack member Rob",
    "Rob of \"The West Wing\"",
    "Actor Rob of \"The West Wing\"",
    "Rob of \"Parks and Recreation\"",
    "Pitcher Derek",
    "Actor Rob who played a presidential aide on \"The West Wing\"",
    "E. S. ___, game company that popularized Yahtzee and Scribbage",
    "Actor Rob",
    "\"Oxford Blues\" star, 1984",
    "E. S. ___, old game company famous for Yahtzee and Bingo",
    "Pitcher Derek, 2004 Red Sox World Series hero",
    "Emmy nominee for \"The West Wing\"",
    "Brat Pack member",
    "Star of \"Youngblood,\" 1986",
    "Game company that originated Yahtzee",
    "Rob of \"The West Wing\"",
    "Rob of \"The West Wing\"",
    "Actor Rob",
    "\"The West Wing\" actor",
    "1993 Emmy winner Chad",
    "\"The West Wing\" co-star",
    "Rob of \"Wayne's World\"",
    "\"Oxford Blues\" star, 1984",
    "\"St. Elmo's Fire\" actor",
    "Chad of \"Life Goes On\"",
    "\"The Outsiders\" actor",
    "\"Frank & Jesse\" co-star",
    "\"St. Elmo's Fire\" actor Rob",
    "Rob of \"About Last Night...\"",
    "\"Bad Influence\" star",
    "Actor Rob",
    "Kitty Litter inventor Edward",
    "Actor Rob"
  ],
  "yawn": [
    "Action that may be \"contagious\"",
    "[That is so boring!]",
    "[So boring!]",
    "[Been there, done that]",
    "Jaw-dropping opening?",
    "Ho-hum response",
    "Have a big mouth?",
    "Demonstration of disinterest",
    "Low interest indicator",
    "[I'm bored]",
    "Act that's \"contagious\"",
    "Sign of fatigue",
    "Occurrence at many a lecture",
    "Open wide",
    "Sign of boredom",
    "[Been there, done that]",
    "[That is, like, so last week]",
    "[bor-r-ring!]",
    "Sign of boredom",
    "[Ho-hum]",
    "Indicate boredom",
    "Respond to a bore",
    "[bo-o-o-ring!]",
    "Gape",
    "Open wide",
    "Exhibit boredom"
  ],
  "cozy": [
    "Warm and snug",
    "Snug",
    "Like a warm bed on a winter night",
    "Snug as a bug in a rug",
    "Close, as a relationship",
    "Under a quilt, say",
    "Snug",
    "Snug",
    "Homey",
    "Cover for a teapot"
  ],
  "nape": [
    "Back of the neck",
    "Scruff",
    "It may get hot under the collar",
    "A collar might hide it",
    "Back of the neck",
    "Back of one's neck",
    "Back of the neck",
    "Where a cat may be picked up",
    "Scruff",
    "A scarf might cover it",
    "What a kitten is picked up by",
    "Scruff",
    "Back of the neck",
    "What a ponytail partially covers",
    "Scruff of the neck",
    "Common spot for a sunburn",
    "Mane area",
    "It might get hot under the collar",
    "What's exposed by a ponytail",
    "Scruff",
    "What a collar may cover",
    "Where a redneck gets red",
    "Where a ducktail tapers",
    "Nuzzling spot, maybe",
    "Pickup spot?",
    "Back of the neck",
    "Scruff of the neck",
    "End of a haircut?",
    "A ponytail hangs over it",
    "Scruff",
    "Spot for a farm laborer's sunburn",
    "Back of the neck",
    "A ponytail hangs over it",
    "Ponytail locale",
    "Place for a pickup?",
    "Scruff",
    "Mane area",
    "Target of a rabbit punch",
    "Back of the neck",
    "Back of the neck",
    "It's often cleaned up by a barber",
    "Rabbit punch target",
    "Place for pick-ups",
    "Pickup place for pets",
    "Kitty's pickup point",
    "Where a redneck gets red",
    "Back of the neck",
    "Rabbit punch landing site",
    "View from the back seat?",
    "Place brushed by a barber",
    "Pick-up spot?",
    "Target of a rabbit punch",
    "Area below the hairline",
    "Collar's locale",
    "Mane site",
    "A barber may shave this",
    "Place to be picked up?",
    "Where a haircut may end",
    "Collar neighbor",
    "Barbering area",
    "Back of the neck",
    "Place to pick up kittens",
    "Shoulder adjoiner",
    "Scruff",
    "Where a rabbit punch lands",
    "Resting place for a chignon",
    "Occiput neighbor",
    "Bun's place",
    "Pickup point?",
    "Scruff",
    "Back of the neck",
    "Back of the neck",
    "Spot for a necklace clasp",
    "Scruff",
    "Where a puppy may be picked up",
    "Back of the neck",
    "Pickup spot?",
    "Shirt tag irritation point",
    "Puppy pickup point",
    "Scruff",
    "Back of the neck",
    "Mane area",
    "Scruff",
    "Back of the neck",
    "Back of the neck",
    "Neck part",
    "Scruff",
    "Place to be picked up?",
    "Scruff",
    "Scruff",
    "Place for a necklace clasp",
    "Scruff",
    "Scruff",
    "Scruff",
    "Neck part"
  ],
  "womb": [
    "Gestation station?",
    "Baby's first home",
    "Fetal position?",
    "Baby's starting place",
    "\"Oven\"",
    "Development site",
    "Where a baby develops",
    "Where a fetus develops",
    "Development site",
    "Birth place",
    "Birth place",
    "Baby carrier?",
    "Prebirth berth",
    "Embryo's site",
    "Sonogram area",
    "Starting place"
  ],
  "limb": [
    "Place for a nest",
    "Life partner?",
    "Arm or leg",
    "Life partner",
    "Risky thing to go out on",
    "Partner for life",
    "Extremity",
    "Rung #3 of the ladder",
    "Partner for life",
    "Arm",
    "Something a sea star can regenerate",
    "Place for a nest",
    "An arm or a leg",
    "Arm or leg",
    "Life's partner",
    "Arm or leg",
    "Birdhouse locale",
    "Arm or leg",
    "Arm or leg",
    "It comes out of a trunk",
    "Bough",
    "Branch",
    "Trunk attachment",
    "What a do-it-yourself swing may hang from",
    "Appendage",
    "Life's partner",
    "Life partner?",
    "An arm or a leg",
    "One might be in a cast",
    "Tire swing supporter",
    "Flipper, e.g.",
    "Extension",
    "Arm or leg",
    "Tree branch",
    "It comes out of a trunk",
    "Arm or leg",
    "Arm or leg",
    "Bird perch",
    "Life's partner",
    "Bird's perch",
    "Life partner",
    "Life's partner",
    "An arm or a leg",
    "Arm or leg",
    "Bough",
    "Partner of life",
    "Treehouse underpinning",
    "Extension",
    "Place for a tire swing"
  ],
  "beeb": [
    "English channel, informally, with \"the\"",
    "English channel, with \"the\"",
    "Something to watch on the telly, with \"the\"",
    "Network on the telly, with \"the\"",
    "Alternative to Sky UK, with \"the\"",
    "Something to watch on the telly, with \"the\"",
    "Recipient of a royal charter, with \"the\"",
    "Channel that airs \"Sherlock,\" with \"the\"",
    "89-Down nickname, with \"the\"",
    "British network, with \"the\"",
    "U.K. news source, with \"the\"",
    "\"Sherlock\" channel, affectionately, with \"the\"",
    "English channel's nickname, with \"the\"",
    "English channel, familiarly, with \"the\"",
    "English broadcaster, with \"the\"",
    "Programme airer, with \"the\"",
    "Telly giant, with \"the\"",
    "\"Neighbours\" airer, with \"the\"",
    "Overseas broadcaster, with \"the\"",
    "English channel, with \"the\"",
    "BBC nickname, with \"the\""
  ],
  "seer": [
    "Dealer in futures?",
    "Oracle",
    "Tarot reader, e.g.",
    "Visionary",
    "Fortuneteller",
    "Palm reader, e.g.",
    "Future-gazer",
    "Sibyl",
    "Tiresias, in \"Oedipus Rex\"",
    "One with a crystal ball",
    "Future reporter",
    "Professor Trelawney in the Harry Potter books, e.g.",
    "One who knows what's coming",
    "Crystal ball user",
    "Future predictor",
    "Forward-looking sort",
    "One who has a ball at work?",
    "Futurist",
    "Future reporter",
    "Fortuneteller",
    "Person offering you a fortune",
    "Nostradamus, for one",
    "Oracle",
    "Oracle",
    "Forward-looking person?",
    "Future reporter",
    "Futures analyst?",
    "Cassandra, in Greek myth",
    "Prescient one",
    "Dealer in futures?",
    "Johnny Carson's Carnac the Magnificent, e.g.",
    "Tiresias in \"Oedipus Rex,\" e.g.",
    "Crystal ball gazer",
    "Fortuneteller",
    "Oracle",
    "Interpreter of omens",
    "Reader of tea leaves, e.g.",
    "Futures dealer?",
    "Forward-thinking type",
    "Visionary sort",
    "Cassandra, e.g.",
    "Nostradamus, e.g.",
    "One reading signs",
    "Professor Marvel in \"The Wizard of Oz,\" e.g.",
    "Tiresias, e.g., in Greek myth",
    "Clairvoyant",
    "Nostradamus, for one",
    "Cassandra, for one",
    "Reader of signs",
    "Clairvoyant",
    "Palm reader, e.g.",
    "Hogwarts professor Trelawney, e.g.",
    "Samuel, e.g., in the Bible",
    "Crystal gazer",
    "Diviner, e.g.",
    "Fortuneteller",
    "One involved in future deals?",
    "Sibyl",
    "Reader of omens",
    "Jeremiah, e.g., in the Bible",
    "Visionary",
    "Amphilochus, in Greek myth",
    "Palmist, e.g.",
    "One with special insight",
    "Crystal gazer",
    "Carson's Carnac, e.g.",
    "Dealer in futures?",
    "Amphilochus, in Greek myth",
    "Futures analyst?",
    "Futures dealer?",
    "\"Your Future\" sign displayer",
    "One holding a ball, maybe",
    "Palmist, e.g.",
    "Prophet",
    "Soothsayer",
    "Tarot card user",
    "Prophet",
    "Palm reader, e.g.",
    "Oracle",
    "Crystal ball user",
    "One with future prospects?",
    "Vision expert",
    "Cassandra, e.g.",
    "Prognosticator",
    "Oracle",
    "Palm reader, e.g.",
    "One who might issue a warning",
    "Prophet",
    "Futurist, of sorts",
    "Fortuneteller",
    "Crystal ball gazer",
    "Crystal ball user",
    "Nostradamus, reputedly",
    "Prescient one",
    "Tea leaf reader",
    "Sibyl",
    "Clairvoyant",
    "Merlin, e.g.",
    "Person with a ball",
    "Nostradamus, for one",
    "Clairvoyant",
    "One who gets what's coming",
    "Tea leaves reader",
    "Clairvoyant",
    "Futurist",
    "Merlin, for one",
    "One available for future reference?",
    "Amphilochus, in Greek legend",
    "Merlin, e.g.",
    "Nostradamus, for one",
    "One in the futures market?",
    "Mystic",
    "Student of palms",
    "Sibyl",
    "Swami",
    "Visionary",
    "Psychic",
    "Seance holder",
    "Clairvoyant",
    "Palmist, e.g.",
    "Prophet",
    "Fortuneteller",
    "Nostradamus, e.g.",
    "Oracle",
    "Psychic networker",
    "Wise guy",
    "Carson's Carnac, e.g.",
    "Person of the future",
    "Mystic",
    "Nostradamus, e.g.",
    "One with a crystal ball",
    "Prophet",
    "Oracle",
    "Clairvoyant",
    "Prophet",
    "Clairvoyant",
    "Tarot reader",
    "Geomancer",
    "Cassandra",
    "Diviner",
    "Crystal gazer"
  ],
  "lase": [
    "Zap with a light beam",
    "Hit with a beam, maybe",
    "Operate on with a beam",
    "Zap, in a way",
    "Zap lightly",
    "Beam at?",
    "Zap, in a way",
    "Cut with a beam",
    "Cut lightly?",
    "Cut with a ray",
    "Give off coherent light",
    "Zap with light",
    "Use a surgical beam on",
    "Do surgery on with a beam",
    "Do some high-tech surgery on",
    "Correct with surgery, maybe, as the eye",
    "Perform high-tech surgery on",
    "Reshape a cornea, say",
    "Use a surgical beam",
    "Do some modern surgery",
    "Beam intensely",
    "Shoot a ray",
    "Use a surgical beam",
    "Perform high-tech surgery",
    "Do some high-tech surgery",
    "Work like a certain medical device",
    "Beam intensely",
    "Give off intense light",
    "Function as a medical device",
    "Emit coherent light",
    "Shoot a light gun",
    "Emit coherent light"
  ],
  "cage": [
    "Zoo enclosure",
    "Hamster holder",
    "Pet shop purchase",
    "Home for a pet bird",
    "Bird's home",
    "\"A forest bird never wants a ___\": Ibsen",
    "Protection for a shark diver",
    "Pet store purchase",
    "Place for a parakeet",
    "Put behind bars",
    "Part of a pound",
    "Slugger's practice area",
    "Zoo keeper?",
    "Animal house?",
    "Enclosure for a pet bird",
    "Part of a pound",
    "Lock up",
    "Prop for Houdini",
    "Zoo keeper?",
    "Wrestling extravaganza enclosure",
    "Part of a pound",
    "Parakeet keeper",
    "Parrot holder",
    "Circus sight",
    "Where to put an old newspaper, maybe",
    "Rat's place",
    "Lion tamer's workplace",
    "Batter's box",
    "Zoo keeper?",
    "It may be for the birds",
    "Bar site",
    "With 34D, part of a pound",
    "Parrot's spot",
    "Parakeet's place",
    "Coop up",
    "It may be rattled",
    "Pet pad",
    "Home for some 66-Across",
    "Zoo fixture",
    "Exercise wheel locale",
    "Place for a swing",
    "Zoo feature",
    "Zoo feature",
    "Place to put an old newspaper",
    "Pet store purchase",
    "Nicolas of \"Con Air\"",
    "Hamster's home",
    "Place for a cashier, maybe",
    "Hamster's home",
    "Batting backstop",
    "Confine",
    "Part of a pound",
    "Confine",
    "Zoo fixture",
    "Tweety's home",
    "\"Amos & Andres\" star Nicolas"
  ],
  "calf": [
    "Foal : mare :: ___ : cow",
    "Baby hippo",
    "Soleus muscle locale",
    "Detached piece of ice",
    "Farm delivery",
    "Cow's newborn",
    "Baby whale",
    "Dinner serving in the Prodigal Son parable",
    "Dogie, e.g.",
    "Leg part",
    "Baby bovine",
    "Roping target",
    "Newborn on a ranch",
    "Dogie, e.g.",
    "Farm newborn",
    "Cow's offspring",
    "Bossy's offspring",
    "Barn young 'un",
    "A little lower",
    "Young whale",
    "Massage target for a runner",
    "Leg part",
    "Ranch newborn",
    "\"Golden\" one",
    "Baby elephant",
    "Muscle that a runner stretches",
    "A little lower",
    "Delivery in the field",
    "Young rhino",
    "Baby whale",
    "A little lower",
    "Baby whale",
    "Baby bovine",
    "A little lower?"
  ],
  "week": [
    "Line on a calendar",
    "Calendar row",
    "Time's time span",
    "Span for The New Yorker",
    "Pay unit, for some",
    "Vacation unit, often",
    "TV's \"This ___\"",
    "Sports Illustrated span",
    "Timespan for The Economist",
    "Quarter division",
    "Time span",
    "Work unit",
    "Pay period",
    "Work unit",
    "Half a fortnight",
    "TV Guide span",
    "Half a fortnight",
    "It's often reviewed in newspapers",
    "Time span",
    "10,080 minutes",
    "Hebdomad",
    "TV Guide span",
    "Calendar unit",
    "Seven days in May, e.g.",
    "It'll last for days",
    "Half a fortnight"
  ],
  "ishe": [
    "\"___ Dead?\" (Mark Twain play)",
    "\"___ for real?\" (\"Can you believe that guy?\")",
    "\"___ Dead?\" (Mark Twain play)"
  ],
  "uber": [
    "Company whose business is picking up?",
    "Lift provider",
    "Slangy prefix meaning \"super\"",
    "Really, informally",
    "Alternative to a taxi",
    "Cab alternative",
    "Ride provider",
    "Modern name in transportation",
    "Cab competitor",
    "Ride provider",
    "Commercial lead-in to X",
    "Pioneer in self-driving cars",
    "It'll take you for a ride",
    "Cab company competitor",
    "Lead-in to mensch",
    "___ Technologies, massively popular 2009 start-up",
    "Ride option",
    "9-Down alternative",
    "It has hundreds of thousands of drivers",
    "Ride offerer",
    "Company investing in self-driving cars",
    "Company whose business is picking up",
    "It'll give you a lift",
    "\"Everyone's private driver\" sloganeer",
    "There's an app for that",
    "\"Evolving the way the world moves\" sloganeer",
    "Lyft competitor",
    "Alternative to a cab",
    "Foe of the taxi industry",
    "Competitor of Lyft",
    "Lyft competitor",
    "Really, informally",
    "Transportation competitor of Lyft",
    "Modern alternative to a taxi",
    "Modern cab service alternative",
    "\"California ___ Alles\" (classic punk rock song)",
    "Modern prefix meaning \"super\"",
    "Prefix with geek",
    "Above, in Berlin",
    "\"Deutschland ___ Alles\"",
    "Superlative prefix",
    "Over, in Oberammergau",
    "Opposite of 4-Down",
    "German word slangily used to mean \"extremely\"",
    "Above, in Berlin",
    "Extremely, in combinations",
    "Super, slangily",
    "\"Deutschland ___ Alles\"",
    "Prefix with mensch",
    "Above, in Aachen",
    "Great: Prefix",
    "Above, in Berlin",
    "\"Gewehr ___!\" (German military order)",
    "\"Deutschland ___ alles\"",
    "Potsdam preposition",
    "Over in Germany",
    "\"Deutschland ___ Alles\"",
    "Over in Berlin",
    "\"Deutschland ___ Alles\"",
    "Over, in Berlin",
    "Not unter",
    "Over in Germany",
    "Over, in Osterreich",
    "Opposite of unter",
    "\"Deutschland ___ Alles\"",
    "Opposite of unter, in German",
    "Above, in Berlin",
    "Above, in Berlin",
    "Above, in Berlin",
    "German preposition",
    "Over, in Bonn",
    "\"Deutchland___alles\"",
    "Above, in Berlin",
    "Above, in Abensberg",
    "Over, to Otto",
    "\"Deutschland ___ alles\"",
    "\"Deutschland ___ Alles\"",
    "Over, in Essen"
  ],
  "usga": [
    "Links grp.",
    "Green org.",
    "Green-conscious grp.",
    "Links org.",
    "Org. whose website has many links?",
    "Links org.",
    "Grp. concerned with courses",
    "Links org.",
    "Bob Jones Award org.",
    "It serves many courses: Abbr."
  ],
  "leaf": [
    "Dinner table expander",
    "Part of some tables",
    "Grub for a grub",
    "Where photosynthesis occurs",
    "Part of a tree or a book",
    "Flip (through)",
    "Prominent part of Nestea's logo",
    "Page (through)",
    "Page (through)",
    "Canadian flag symbol",
    "Grub for a grub",
    "Thumb (through)",
    "It falls in the fall",
    "One that's stalked",
    "Dining table expander",
    "Fall faller",
    "Flip (through)",
    "Bit of foliage",
    "Vein holder",
    "Table expander",
    "Page",
    "Page",
    "Table extension",
    "Page (through)",
    "Book part",
    "Page",
    "Vein site",
    "Table section",
    "Fall faller",
    "Natural rustler",
    "Table extender",
    "Browse (through)",
    "Table extender",
    "Thumb (through)",
    "Page",
    "Riffle",
    "Two pages",
    "Table part",
    "Table extender",
    "Flip (through)",
    "Word after over or clover",
    "Rare book-dealer's unit",
    "Page (through)",
    "Green piece",
    "Page (through)",
    "Riffle",
    "Bud, eventually"
  ],
  "oast": [
    "Hops-drying oven",
    "Bit of brewing equipment",
    "Brewery fixture",
    "Brewery kiln",
    "It dries hops",
    "Brewery apparatus",
    "Brewery fixture",
    "Brewer's oven",
    "Brewery fixture",
    "Drying device",
    "Hops dryer",
    "Malt-drying 17-Across",
    "Brewing oven",
    "Hops kiln",
    "Hops dryer",
    "Brewer's equipment",
    "Hops kiln",
    "Kiln for hops",
    "Brewery dryer",
    "Kiln for hops",
    "Hops kiln",
    "Brewery fixture",
    "Drying kiln",
    "Hops drier",
    "Brewery fixture",
    "Kiln",
    "Brewery fixture",
    "Malt-drying kiln",
    "Hops-drying oven",
    "Drying chamber",
    "Brewer's kiln",
    "Brewer's kiln",
    "Kiln",
    "Kiln",
    "Brewer's equipment",
    "Hops-drying oven",
    "Kiln",
    "Place to hold hops",
    "Place to dry tobacco",
    "Kiln",
    "Hops kiln",
    "Brewer's equipment",
    "Brewer's oven",
    "Where hops are dried",
    "Tobacco-curing chamber",
    "Hops kiln",
    "Brewer's aid",
    "Malt kiln",
    "Kiln",
    "Kiln",
    "Kiln",
    "Malt kiln",
    "Brewery fixture",
    "Malt kiln",
    "Kiln",
    "Kiln for drying hops",
    "Kiln",
    "Hop-drying kiln",
    "Drying oven",
    "Drying kiln",
    "Brewery fixture",
    "Kiln",
    "Malt drier",
    "Drying oven"
  ],
  "asec": [
    "\"Gimme ___\"",
    "\"Just hold on ___!\"",
    "\"Just ___\"",
    "\"Gimme just ___\"",
    "\"Hold on ___!\"",
    "\"Wait ___!\" (\"Hold on!\")",
    "\"Wait ___!\"",
    "\"Wait just ___!\"",
    "\"Hold on ___!\"",
    "\"Just ___!\" (\"Be right there!\")",
    "\"Hang on ___\"",
    "\"Gimme ___\"",
    "\"Just ___!\"",
    "One mo",
    "\"Hang on ___!\"",
    "\"Gimme ___!\"",
    "\"Wait just ___!\"",
    "\"Just ___!\"",
    "\"Just ___!\"",
    "\"Just ___\"",
    "\"Be there in ___\"",
    "\"Wait just ___!\"",
    "\"Just ___!\" (\"Hold on!\")",
    "\"Just ___!\"",
    "\"Just ___!\"",
    "\"Wait ___!\"",
    "\"Wait ___!\"",
    "\"Just ___!\"",
    "\"Just ___\"",
    "\"Hold it ___!\"",
    "\"I'll be back in ___!\"",
    "\"Hold on ___!\"",
    "\"Wait ___!\"",
    "\"Just ___!\"",
    "\"Wait ___!\"",
    "\"Wait ___!\"",
    "\"Hey, wait ___\"",
    "\"Wait ___!\"",
    "Not long to wait",
    "\"Hold on ___!\"",
    "\"Hold on ___!\"",
    "\"Just ___!\"",
    "\"Hold on ___!\""
  ],
  "aray": [
    "Like ___ of sunshine",
    "Give ___ of hope",
    "Like ___ of sunshine",
    "Without ___ of hope",
    "Have___of hope"
  ],
  "well": [
    "\"I mean ...\"",
    "In good health",
    "\"Let me see ...\"",
    "*\"Whaddya know ...\"",
    "Source of fresh water",
    "Wishful place?",
    "Word said three times before \"What have we here?!\"",
    "\"I'm waiting ...?\"",
    "End of the word ladder",
    "Wisher's place",
    "\"I'm still waiting ...?\"",
    "With 38-Across, desiring happiness for someone",
    "Not down with anything",
    "Wishing site",
    "\"Now that you mention it ...\"",
    "Wishing place",
    "See 5-Down",
    "Utterance from Reagan mimics",
    "Wisher's spot",
    "Fit",
    "Place to make a wish",
    "Spring place",
    "When said three times, \"Lookee here!\"",
    "Healthy",
    "\"Um ...\"",
    "In good health",
    "In good health",
    "Reagan sentence starter",
    "Drawing place",
    "Water source",
    "Sound",
    "Bucket locale",
    "Commendably",
    "Word of hesitation",
    "Fountainhead",
    "Source of abundance"
  ],
  "jays": [
    "Members of the crow family",
    "Noisy birds",
    "Sox rival",
    "Ones with caws for alarm?",
    "Sox foes",
    "Crows' cousins",
    "A.L. East team, informally",
    "Blue birds",
    "Noisy birds",
    "Blue birds"
  ],
  "cfos": [
    "Corporate money managers, for short",
    "Green suits?",
    "Some company bigwigs, for short",
    "Money execs",
    "Ones concerned with cash flow, for short",
    "Bu$ine$$ execs",
    "Execs who handle the green",
    "Money execs",
    "$$$ bigwigs",
    "Corp. money managers",
    "Corp. money pros",
    "Some successful Wharton grads, for short",
    "Corp. money managers",
    "Corp. money execs",
    "Some budget planners, for short",
    "Corp. money managers",
    "Corp. money types",
    "Bottom-line bigwigs, in brief",
    "Corp. money execs",
    "Inc. article subjects",
    "Bus. honchos",
    "Money bigwigs, for short",
    "Corporate money mgrs.",
    "Corp. bigwigs",
    "Money honchos: Abbr.",
    "Corp. money managers",
    "Corporate money managers: Abbr.",
    "Corp. money managers"
  ],
  "hunk": [
    "Studmuffin",
    "Object of an ogler",
    "Babe magnet",
    "Dreamboat",
    "Many a bachelorette party hiree",
    "Typical romance novel love interest",
    "Pinup boy",
    "Playgirl calendar type",
    "Beefcake poser",
    "Gal's dream date",
    "Adonis",
    "\"Baywatch\" type"
  ],
  "ford": [
    "Eponym of the Detroit Lions field",
    "Largest employer in metro Detroit",
    "Cross",
    "Escape maker",
    "Only president to serve as both vice president and president without being elected to either office",
    "President after Nixon",
    "President after Nixon",
    "First assemblyman?",
    "See 55-Across",
    "Company that makes Lincoln and Mercury",
    "President who was born a King",
    "Crossing, of a sort",
    "Focus provider?",
    "Major employer in Detroit",
    "Crossing point",
    "Negotiator at Vladivostok, 1974",
    "Explorer maker",
    "Crossing point",
    "Escort company",
    "Cross",
    "Futura maker",
    "Tennessee Ernie ___"
  ],
  "rara": [
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "Hardly seen, to Seneca",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "Scarce, to Scipio",
    "___ avis",
    "___ avis",
    "___ avis",
    "Hard to find in Latin?",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "Hardly seen at the Forum?",
    "___ avis",
    "___ avis",
    "___ avis",
    "\"Ta-___ Boom-de-ay\"",
    "Uncommon, in ancient Rome",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "Hard to find, in old 13-Down",
    "Hard to find in Latin?",
    "___ avis",
    "___ avis",
    "Singular, to Caesar",
    "___ avis",
    "Opera ___ (classical music record label)",
    "___ avis",
    "It was uncommon at the Forum",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis (unusual one)",
    "Avis adjective",
    "Singular, to Caesar",
    "___ avis",
    "Unique, to Caesar",
    "___ avis",
    "Avis-like?",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "Hard to find, to Hadrian",
    "Like a certain avis",
    "Avis adjective",
    "___ avis",
    "Extraordinary, in a phrase",
    "Kind of avis",
    "___ avis",
    "Uncommon, as a Latin bird?",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "Avis lead-in",
    "Unusual, in Caesar's day",
    "___avis",
    "Avis opener",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis",
    "___ avis"
  ],
  "bray": [
    "Coarse farm sound",
    "Burro's call",
    "Hee-haw",
    "Sound like a jackass",
    "Act like a jackass",
    "Jackass's sound",
    "Donkey's cry",
    "Barnyard call",
    "Farm call",
    "Farm call",
    "Harsh call",
    "Burro's cry",
    "Act like an ass",
    "Farm call",
    "Barnyard cry",
    "Sound like an ass"
  ],
  "retd": [
    "Like many Ariz. residents",
    "Part of AARP: Abbr.",
    "No longer active: Abbr.",
    "Emeritus: Abbr.",
    "No longer serving: Abbr.",
    "On Medicare, say: Abbr.",
    "On a pension: Abbr.",
    "Like many a capt. or gen.",
    "Out of service?: Abbr.",
    "Like many a gen.",
    "Collecting a pension: Abbr.",
    "Part of AARP: Abbr.",
    "Like the SST fleet: Abbr.",
    "Out of practice?: Abbr.",
    "Part of AARP: Abbr.",
    "Like many offs.",
    "AARP part: Abbr.",
    "Part of AARP: Abbr.",
    "On Soc. Sec., say",
    "On Soc. Sec., typically",
    "No longer working: Abbr.",
    "Abbr. after many a general's name",
    "No longer on active duty: Abbr.",
    "No longer in the service: Abbr.",
    "Like some I.R.A. owners: Abbr.",
    "No longer working: Abbr.",
    "On a pension: Abbr.",
    "Sent back: Abbr.",
    "No longer working: Abbr."
  ],
  "oday": [
    "Danny ___, ventriloquist dummy for Jimmy Nelson",
    "Jazz vocalist Anita",
    "Jazz singer Anita",
    "Singer nicknamed \"The Jezebel of Jazz\"",
    "Anita of jazz",
    "Jazzy Anita",
    "See 27-Across",
    "Singer/reality TV personality Aubrey",
    "Anita of jazz",
    "R&B/pop singer Aubrey",
    "See 112-Across",
    "Jazz singer whose surname came from pig Latin",
    "Old jazz icon Anita",
    "Anita of jazz",
    "Jazz chanteuse Anita",
    "Anita of song",
    "Singer Alan or Anita",
    "Anita of jazz",
    "Singer called \"The Jezebel of Jazz\"",
    "Jazz singer who took her surname from pig Latin",
    "Anita who was nicknamed the Jezebel of Jazz",
    "Jazzy Anita",
    "Anita who sang \"Is You Is or Is You Ain't My Baby\"",
    "Jazzy Anita",
    "Jazz singer Anita",
    "Anita of jazz",
    "Performer with Krupa and Kenton",
    "Jazz singer Anita",
    "Jazz singer Anita",
    "Jazz singer Anita",
    "Anita who sang \"And Her Tears Flowed Like Wine\"",
    "Jazz singer Anita",
    "Singer Anita",
    "Singer Anita",
    "Jazz singer Anita",
    "Singer Anita",
    "Noted singer with Krupa & His Orchestra",
    "Singer Anita",
    "Singer Anita",
    "Jazz singer Anita",
    "Singer Anita",
    "Singer Anita",
    "Jazz singer Anita"
  ],
  "gmac": [
    "Finance co. rebranded as Ally Financial",
    "Onetime big inits. in car financing",
    "Former car-financing co.",
    "Co. now known as Ally Financial",
    "Org. for vehicle financing, once",
    "Former financing inits.",
    "Auto financing org., formerly",
    "Car financing inits.",
    "Auto financing co.",
    "Auto financing inits.",
    "Car financing co.",
    "Auto financing co.",
    "Auto financing co.",
    "Auto financing co.",
    "Big inits. in car financing"
  ],
  "nons": [
    "Dijon denials",
    "French refusals",
    "Rennes rejections",
    "Versailles votes",
    "Rouen refusals",
    "Vichy votes",
    "Versailles votes",
    "Votes in Versailles",
    "French denials",
    "Votes in Quebec",
    "French denials",
    "Refusals in Rouen"
  ],
  "dyne": [
    "10 micronewtons",
    "Unit of force",
    "Unit of force",
    "Small force",
    "10 micronewtons",
    "Unit of force",
    "Unit of force",
    "Centimeter-gram-second unit",
    "Bit of force",
    "Unit of force",
    "Force unit",
    "Unit of force",
    "Newton fraction",
    "Unit in an erg's definition",
    "Unit of force",
    "Unit of force",
    "Bit of force",
    "Unit of force",
    "Unit of force",
    "Small force",
    "Fraction of a newton",
    "Unit of force",
    "Unit of force",
    "Unit of force",
    "Newton part",
    "Unit of force",
    "Newton fraction",
    "Unit of force",
    "Fraction of a newton",
    "Unit of force",
    "Physics unit",
    ".00001 newton",
    "Centimeter-gram-second unit",
    "Bit of force",
    "Unit of force",
    "Unit of force",
    "Physics unit",
    "Unit of force",
    "Force unit",
    "Force unit",
    "Unit of force",
    "Physics unit",
    "Unit of force",
    "Unit of force",
    "Measure of force",
    "Unit of force",
    "Unit of force"
  ],
  "snog": [
    "Make out in England",
    "Kiss amorously",
    "French, say, to a Brit",
    "Make out, to a Brit",
    "Smooch, in Britspeak",
    "Make out, in Manchester",
    "Make out, at Hogwarts",
    "Canoodle, in Canterbury",
    "French, e.g., to Brits",
    "Kiss, to Brits",
    "Kiss, in 34-Down",
    "Make out, in York",
    "Make out, to Harry Potter",
    "Be amorous with, in Britain",
    "Kensington kiss",
    "Hug and kiss, to a Brit",
    "Kiss in Kensington",
    "Kiss, in \"Harry Potter\""
  ],
  "mock": [
    "Not serious",
    "Synthetic",
    "Just pretend",
    "Simulated",
    "Pretended",
    "Poke fun at",
    "Make fun of",
    "Make fun of",
    "Poke fun at",
    "Imitation",
    "Faux",
    "Like a kangaroo court",
    "Imitation",
    "Lampoon",
    "Pseudo"
  ],
  "stad": [
    "Town, in Scandinavia",
    "Dutch burg",
    "Suffix meaning \"city\" in some European place names",
    "Native South African village",
    "Native village, in South Africa",
    "South African village",
    "Native African village"
  ],
  "meir": [
    "\"Iron Lady\" of Israeli politics",
    "Head of government between Eshkol and Rabin",
    "Predecessor of Rabin as prime minister",
    "1960s-'70s Israeli leader",
    "Only female Israeli prime minister",
    "Israel's Golda",
    "Golda of Israel",
    "Predecessor of Rabin",
    "Israel's fourth prime minister",
    "Feldshuh's role in \"O Jerusalem\"",
    "Yom Kippur War politician",
    "Yom Kippur War figure",
    "Israel's Golda",
    "Historical role in Spielberg's \"Munich\"",
    "Golda of Israel",
    "She said \"Don't be humble. You're not that great\"",
    "Yom Kippur War politician",
    "\"My Life\" autobiographer, 1975",
    "Bergman's last role",
    "She resigned after the Yom Kippur War",
    "Predecessor of Rabin",
    "Tel Aviv's ___ Park",
    "Israel's Golda",
    "Kiev-born Israeli P.M.",
    "\"Golda's Balcony\" subject",
    "Golda of Israel",
    "Predecessor of Rabin",
    "Russian-born Israeli leader",
    "P.M. known as the Iron Lady",
    "Prime minister after Eshkol",
    "Head of state who resigned in 1974",
    "Prime minister raised in Milwaukee",
    "Golda of Israel",
    "Rabin's predecessor",
    "World leader who resigned in 1974",
    "Israel's Golda",
    "P.M. after Eshkol",
    "Golda of Israel",
    "Former Israeli P.M.",
    "Israeli P.M., 1969-74",
    "Predecessor of Rabin",
    "Dayan colleague",
    "Israel's Golda",
    "A founder of the state of Israel",
    "Dayan contemporary",
    "Israel's Golda",
    "Rabin's predecessor",
    "Bergman's last role",
    "60's-70's P.M.",
    "P.M. elected in October 1969",
    "Associate of Dayan",
    "1970's P.M.",
    "Eshkol's successor",
    "Rabin's predecessor",
    "Former Israeli P.M.",
    "Israel's Golda",
    "Dayan contemporary",
    "Israeli P.M. Golda",
    "?ôMy Life?ö autobiographer",
    "Israel's Golda",
    "Eshkol's successor"
  ],
  "atvs": [
    "Off-road four-wheelers, for short",
    "Beach patrol conveyances, for short",
    "Countryside conveyances, for short",
    "Rugged four-wheelers, for short",
    "Off-road rides, for short",
    "Off-road rides, for short",
    "Off-roaders, for short",
    "Off-roaders, for short",
    "Motocross racers, for short",
    "Relatives of dune buggies, for short",
    "Off-road bikes, for short",
    "Some four-wheelers, for short",
    "Rugged wheels, briefly",
    "Tanks, e.g., briefly",
    "Off-roaders, for short",
    "They go by the wayside",
    "Ones heading for the hills?: Abbr.",
    "Rugged transportation, for short",
    "Some ranchers drive them: Abbr.",
    "Off-road transports: Abbr.",
    "Durable transports, for short",
    "Dune buggies, for short"
  ],
  "nick": [
    "Pilfer, to Brits",
    "China flaw",
    "Shaving mishap",
    "Styptic pencil target",
    "\"Jolly old\" saint",
    "Scratch",
    "Shaving mishap",
    "Paper cut, e.g.",
    "Insignificant injury",
    "Small cut",
    "Make the cut?",
    "Notch",
    "Minor wound",
    "Cause for a Band-Aid",
    "Cutlet?",
    "Short cut",
    "Shaving injury",
    "Pottery flaw",
    "Notch",
    "Shaver's woe",
    "Shaving cut",
    "Cutlet?",
    "Shaving wound",
    "Shaving mishap"
  ],
  "skye": [
    "Isle of ___",
    "Scottish isle connected by road bridge to the mainland in the 1990s",
    "___ terrier",
    "Scottish terrier type",
    "Kind of terrier",
    "Terrier type",
    "___ terrier",
    "___ terrier",
    "Kind of terrier",
    "Terrier named after a Scottish island",
    "___ terrier",
    "Terrier type",
    "___ terrier",
    "___ terrier",
    "___ terrier",
    "___ terrier",
    "Kind of terrier",
    "Hebrides isle",
    "Island along Cuillin Sound",
    "___ terrier",
    "Kind of terrier",
    "___ terrier",
    "Hebrides isle",
    "Terrier type",
    "Terrier type",
    "Hebrides isle",
    "Kind of terrier",
    "Portree's isle",
    "Kind of terrier",
    "___ terrier",
    "Terrier type",
    "Hebridean island",
    "___ terrier",
    "Hebrides island",
    "Hebrides isle",
    "Scottish island",
    "___ terrier",
    "Actress Ione",
    "Hebridean isle",
    "Kind of terrier",
    "Kind of terrier",
    "See 23-Down",
    "Hebrides island",
    "Terrier type",
    "Inner Hebrides island",
    "Terrier type",
    "___ terrier"
  ],
  "cite": [
    "Mention",
    "Refer to",
    "Mention as an example",
    "Ile de la ___",
    "Give as an example",
    "Commend",
    "Put in a bibliography",
    "Give as an example",
    "Reference",
    "Ticket",
    "Name",
    "Commend",
    "Give proper attribution",
    "Ticket",
    "Name, as sources",
    "Recognize",
    "Put in a bibliography",
    "Quote",
    "Footnote, perhaps",
    "Mention in a footnote, say",
    "Reference",
    "Ile de la ___",
    "Recognize as a source",
    "Commend",
    "Reference",
    "Reference",
    "Name",
    "Reference",
    "Quote from, as a legal case",
    "Make an example of",
    "Give as an example",
    "Commend, as for outstanding service",
    "Name, in a way",
    "Refer to",
    "Refer to",
    "Give a ticket",
    "Make an example of",
    "Summon to court",
    "Mention, as in a court opinion",
    "Ticket",
    "Formally honor",
    "Hit with a ticket",
    "Bring up",
    "Commend, as for outstanding service",
    "Commend",
    "Quote",
    "Ticket",
    "Refer to",
    "Name",
    "Give as an example",
    "Refer to",
    "Specify",
    "Name",
    "Quote as an example",
    "Offer as proof",
    "Summon to court",
    "Ticket",
    "Give a ticket to",
    "Mention",
    "Give as an example",
    "Ticket",
    "Ticket",
    "Write up, as a speeder",
    "Commend officially",
    "Mention",
    "Serve with a summons",
    "Single out for praise",
    "Commend",
    "Refer to",
    "Give as an example",
    "Reference",
    "Serve with a summons",
    "Recognize",
    "Commend, as for honors",
    "Ile de la ___",
    "Give as a reference",
    "Give a ticket to"
  ],
  "kick": [
    "Rockette's move",
    "What 100-proof alcohol has",
    "Thrill",
    "Beef",
    "Bandy, as ideas",
    "What a spiked drink has",
    "Gun's recoil",
    "Give up, as a habit",
    "Sudden jolt",
    "Gun's recoil"
  ],
  "pogs": [
    "Collectible caps of the 1990s",
    "Fad game of the 1990s",
    "Collectible caps",
    "1990s fad",
    "Game discs",
    "Collectible caps"
  ],
  "etui": [
    "Case for small toiletries",
    "Decorative sewing case",
    "Needle case",
    "Needle case",
    "Tiny-scissors holder",
    "Sewing case",
    "Needle holder",
    "Sewing accessory",
    "Sewing case",
    "Small case",
    "Needle holder",
    "Needle case",
    "Sundries case",
    "French CD holder",
    "Tailor's case",
    "Small French case",
    "Tiny-scissors holder",
    "Needle case",
    "French CD holder",
    "Needle case",
    "Decorative kit",
    "Seamstress's aid",
    "Pin holder",
    "French CD holder",
    "Thread holder",
    "Needle holder",
    "Pins and needles holder",
    "Decorative needle case",
    "Container for folding scissors",
    "Decorative needle case",
    "It has many needles",
    "Pins and needles' place",
    "Place for a thimble",
    "Decorative sewing kit",
    "Item for a travel bag",
    "Special case?",
    "German iPod holder",
    "Needle holder",
    "One may get pins and needles",
    "Decorative case",
    "Item for a travel bag",
    "It gets notions",
    "Needlepoint shop purchase",
    "Sewing case",
    "Pin holder",
    "Place for pins",
    "Holder of notions",
    "Needle case",
    "It has its notions",
    "Decorative needle case",
    "Needle case",
    "One with notions",
    "Choice for small toiletries",
    "Place for pins",
    "Needle case",
    "Needle case",
    "Carrier for needles and pins",
    "Needle holder",
    "Needle case",
    "Needlecase",
    "Special case?",
    "Pin's place",
    "Pin holder",
    "Sewing case",
    "Pincushion alternative",
    "Pins' place",
    "Small case",
    "Pins and needles holder",
    "Sewing case",
    "Place to put a thimble",
    "Needle holder",
    "Needle holder",
    "Needle case",
    "A case of pins and needles",
    "Place for embroidery scissors",
    "Needle case",
    "It has notions",
    "Holder of combs, perfumes, etc.",
    "Case for small scissors",
    "Sewing case",
    "Pincushion alternative",
    "Place for small scissors",
    "Decorative case",
    "Needle case",
    "Toiletries case",
    "Pins and needles holder",
    "Needle case",
    "Sewing case",
    "Sundries case",
    "Needle case",
    "Holder for needles and things",
    "Ornamental case",
    "Needle case",
    "Needle case",
    "Needle case",
    "Needle case",
    "Needle case",
    "Toiletries case",
    "Sewing case",
    "Needle case",
    "Needle case"
  ],
  "kent": [
    "Superman's adoptive family name",
    "___ State, home of the Golden Flashes",
    "Lane hugger?",
    "R. J. Reynolds brand",
    "Clark of the Daily Planet",
    "Clark of the Daily Planet",
    "King Lear's loyal servant",
    "Surname at the Daily Planet",
    "Superman without a cape",
    "Superman, at other times",
    "___ State",
    "Clark ___ (Superman's alter ego)",
    "English county closest to Continental Europe",
    "Canterbury's home",
    "The Garden of England",
    "Clark ___, alter ego of Superman",
    "___ State (Ohio university)",
    "Alter ego who carries a notepad",
    "Clark ___, Superman's alter ego",
    "Canterbury's county",
    "Cigarette that once advertised the \"health benefits\" of its Micronite filter",
    "British American Tobacco brand",
    "Reeve or Reeves role",
    "Classic cigarette brand",
    "Frequent phone booth user",
    "Cigarette brand that sponsored \"The Dick Van Dyke Show\"",
    "Colleague of 38-Across",
    "Setting for part of \"King Henry VI, Part 2\"",
    "County of Dover, Delaware ?à or Dover, England",
    "Superman sans cape",
    "Reporter Clark",
    "North Dakota senator Conrad",
    "Lane co-worker at the Daily Planet",
    "Smallville surname",
    "Longtime Lorillard brand",
    "Superman's surname",
    "Canterbury's home",
    "___ State",
    "Superman, most of the time",
    "Clark of the Daily Planet",
    "Man hiding a cape",
    "County bordering London",
    "Reeve role",
    "Channel county",
    "Clark of Superman fame",
    "Superman's alter ego",
    "Lorillard brand",
    "Clark of The Daily Planet",
    "County on the Strait of Dover",
    "Ohio college town",
    "Fictional suit-changer",
    "Lane co-worker",
    "Lane's partner",
    "County bordering London",
    "Artist Rockwell",
    "Lane lover?"
  ],
  "saws": [
    "Tools with teeth",
    "Proverbs",
    "Aphorisms",
    "They might create divisions in the board",
    "Staples of \"Poor Richard's Almanack\"",
    "Parts of lumber mills",
    "Coping mechanisms?",
    "Makes the cut?",
    "Nuggets in \"Poor Richard's Almanack\"",
    "Mill devices",
    "Familiar sayings",
    "Dust-creating tools",
    "Aphorisms",
    "Lumber mill equipment",
    "Props for some magic shows",
    "Coping mechanisms?",
    "\"Let sleeping dogs lie\" and others",
    "Cuts",
    "Old sayings",
    "Toothed tools",
    "Fellers in the woods?",
    "Hardware store section",
    "Tools with teeth",
    "They may be big fellers",
    "Tooth holders",
    "Toothed tools",
    "Tools with teeth",
    "Familiar truths",
    "They may be pulled in two directions",
    "Often-told truths",
    "Wood cutters",
    "They can go into the grain",
    "They go back and forth to work",
    "What fellers need",
    "Toothy ones",
    "Trunk openers?",
    "They go back and forth in the woods",
    "Aphorisms",
    "Old sayings",
    "Words of wisdom",
    "Processes lumber"
  ],
  "eeoc": [
    "Workers' rights org. since 1965",
    "Workplace discrimination law enforcer, for short",
    "Workplace fairness agcy.",
    "Fair-hiring watchdog, for short",
    "Workplace antidiscrimination agcy.",
    "Fair-hiring org.",
    "Antidiscrimination org.",
    "Antidiscrimination agcy.",
    "Discrimination fighter: Abbr.",
    "Fair-hiring org.",
    "Job rights agcy.",
    "Fair-hiring org.",
    "Fair-hiring org.",
    "Fair-hiring org.",
    "Color-conscious grp.",
    "Fair-hiring grp.",
    "Fair-hiring org.",
    "Discrimination watchdog: Abbr.",
    "Fair-hiring watchdog: Abbr.",
    "Antidiscrimination agcy.",
    "Org. overseeing fairness in hiring"
  ],
  "siam": [
    "\"The King and I\" setting",
    "Onetime neighbor of French Indochina",
    "King's country in \"The King and I\"",
    "\"Anna and the King of ___\"",
    "Setting for \"The King and I\"",
    "\"The King and I\" setting",
    "Neighbor of India and China on a Risk board",
    "Country that changed its name in 1939",
    "Neighbor of India and China in Risk",
    "\"The King and I\" setting",
    "Setting for \"The King and I\"",
    "Thailand, before 1939",
    "Asian territory in Risk",
    "Where Anna met the king",
    "Twins' home?",
    "Neighbor of India and China in Risk",
    "Pre-1939 atlas name",
    "Land name before 1939",
    "Thailand, formerly",
    "Kingdom on old Asian maps",
    "Thailand, once",
    "Kingdom in \"The King and I\"",
    "Thailand, once",
    "Thailand, once",
    "Thailand, once",
    "Rama's kingdom",
    "\"The Bridge on the River Kwai\" setting",
    "\"The King and I\" setting",
    "Gulf of ___, body of water next to Viet Nam",
    "Chang and Eng's homeland",
    "\"The King and I\" setting",
    "Country name retired in 1949",
    "Kingdom of Broadway",
    "Historic silk center",
    "Prajadhipok's kingdom",
    "Anna's adopted homeland",
    "Old kingdom name",
    "Musical kingdom",
    "Pre-1939 atlas name",
    "Kingdom in a 1951 Broadway musical",
    "Now it's Thailand",
    "Rama V's land",
    "Land of kings Rama I to VIII",
    "Country for which a cat was named",
    "River Kwai locale, formerly",
    "Locale for Anna",
    "Rodgers and Hammerstein musical setting",
    "Gulf of ___ (Joseph Conrad story setting)",
    "Where Anna met the king",
    "Twin country?",
    "Thailand, once",
    "Musical kingdom",
    "Asia's Gulf of ___",
    "Country that changed its name in 1939",
    "Thailand, once",
    "Thailand, once",
    "Thailand, once",
    "Thailand, once",
    "Chang and Eng's homeland",
    "Prathet Thai, previously",
    "Kingdom on old Asian maps",
    "Rama VII's domain",
    "King Mongkut's realm",
    "River Kwai locale",
    "King's land in a Broadway musical",
    "Chulalongkorn's land",
    "Famous twins' birthplace",
    "King's home, on Broadway",
    "Twins' birthplace",
    "Realm of King Rama",
    "Where Anna taught",
    "Thailand, once",
    "Famous twins' home",
    "\"Anna and the King of ___\"",
    "Where Anna Leonowens taught",
    "\"The King and I\" setting",
    "\"The King and I\" locale",
    "Thailand, once"
  ],
  "fare": [
    "Cost of a ride",
    "Taxi charge",
    "Meter reading",
    "Get on",
    "Meter reading",
    "Rideshare app datum",
    "See 23-Across",
    "Grub",
    "What's to eat",
    "Get along",
    "It's taken for a ride",
    "MetroCard payment",
    "Ride, maybe",
    "Meter reading",
    "MetroCard cost",
    "Cost of a cab",
    "Commuting cost",
    "Cabby's client",
    "It's figured according to the 14-Down",
    "Price of a ride",
    "Charge",
    "Taxi charge",
    "Subway purchase",
    "Food",
    "Taxi rider",
    "Meter reading",
    "Diet",
    "Taxi rider",
    "Hack's customer",
    "Bus token, e.g.",
    "Meter reading, maybe",
    "Meter reading",
    "Paying passenger",
    "Ticket to ride",
    "Make out",
    "Transit token",
    "What's for dinner?",
    "Get along",
    "$1.50 for the first 1/5 mile, e.g.",
    "Things to eat",
    "Taximeter reading",
    "Diet",
    "The going price?"
  ],
  "mata": [
    "___ Hari (W.W. I spy)",
    "___ Hari",
    "Mother, in Mumbai",
    "___ Hari (W.W. I spy)",
    "___ Hari",
    "Spydom's ___ Hari",
    "___ Hari",
    "___ Hari (W.W. I spy)",
    "___ Hari",
    "___ Hari",
    "First name in spydom",
    "___ Hari",
    "___ Hari",
    "___ Hari",
    "___ Hari",
    "___ Hari (infamous spy)",
    "___ Hari",
    "First name in espionage",
    "___ Hari (W.W. I spy)",
    "___ Hari (spy)",
    "First name in spydom",
    "___-Utu (capital of Wallis and Futuna)",
    "Spy ___ Hari",
    "___ Hari",
    "Spy ___ Hari",
    "___ Hari (spy)",
    "___ Hari",
    "Spy ___ Hari",
    "___ Hari",
    "First name in espionage",
    "Spydom name",
    "First name in spydom",
    "First name in spydom",
    "Spy ___ Hari",
    "___ Hari",
    "___ Hari",
    "First name in spydom",
    "___ Hari",
    "First name in spying",
    "___ Hari"
  ],
  "wham": [
    "Cousin of \"Kapow!\"",
    "\"Kapow!\"",
    "\"Pow!\"",
    "Sound of an impact",
    "Sound from a sock",
    "\"Kapow!\"",
    "Hard smack",
    "\"Pow!\"",
    "\"Wake Me Up Before You Go-Go\" band",
    "Impact sound in the comics",
    "Relative of \"Smash!\"",
    "Kapow!",
    "\"Kapow!\"",
    "Impact sound",
    "Pow!",
    "Big blow",
    "\"Kapow!\"",
    "\"Pow!\"",
    "Pow!",
    "Impact sound",
    "Kapow!",
    "\"Pow!\"",
    "\"Pow!\"",
    "\"Pow!\"",
    "\"Kapow!\"",
    "Impact sound",
    "\"Ka-boom!\"",
    "\"Pow!\"",
    "\"Kapow!\"",
    "Kapow!",
    "Kapow!",
    "Kapow!",
    "Geroge Michael's old musical group",
    "\"Wake Me Up Before You Go-Go\" group",
    "Door-closing sound",
    "Smack!"
  ],
  "tman": [
    "I.R.S. agent, quaintly",
    "Bootlegger's foe",
    "Government sleuth, quaintly",
    "Catcher of counterfeiters, in old lingo",
    "Speakeasy investigator, informally",
    "Fed",
    "Prohibition enforcer, informally",
    "Fake bill tracer",
    "Tax fraud detector, informally",
    "I.R.S. agent, for short",
    "Laundering investigator, for short",
    "Untouchable, e.g.",
    "Counterfeiter fighter, informally",
    "Federal tax enforcer, informally",
    "Counterfeiter fighter",
    "Fed",
    "Certain Fed",
    "Fed concerned with forgery",
    "Revenuer",
    "I.R.S. agent, e.g., informally",
    "Fed in pursuit of counterfeiters",
    "No friend of the bootlegger",
    "Bootlegger's bugbear",
    "Certain Fed",
    "One trying to eliminate bad notes",
    "Federal agent investigating taxes, informally",
    "Tax investigator, for short",
    "Bad bill collector?",
    "Untouchable, e.g.",
    "Untouchable, say",
    "One of the Untouchables",
    "Eliot Ness, notably, for short",
    "Tax cheat chaser, informally",
    "Crime-fighter Eliot Ness, notably",
    "Fed",
    "I.R.S. agent, e.g.",
    "Fed",
    "Fed. agent in finances",
    "Eliot Ness, for one",
    "Certain fraud investigator, informally",
    "Fed",
    "Counterfeiter catcher",
    "Eliot Ness, notably",
    "Revenuer",
    "Fed",
    "Govt. agent",
    "I.R.S. worker",
    "Investigator, of sorts",
    "Eliot Ness, e.g.",
    "Fed",
    "Ness, for one",
    "Certain Fed",
    "Revenuer",
    "Ness, e.g.",
    "Revenuer",
    "Agt. such as Ness",
    "Govt. agent",
    "Govt. agent",
    "Govt. agent",
    "Govt. agent",
    "Government agt.",
    "Fed",
    "Federal investigator",
    "Fed",
    "Govt. agent",
    "U.S. narc",
    "Govt. investigator"
  ],
  "grip": [
    "Part of a golf club",
    "\"Get a ___!\"",
    "Camera crane operator",
    "Purchase",
    "Firm hold",
    "Clutch",
    "Weightlifter's concern",
    "Golf or tennis lesson topic",
    "Film set schlepper",
    "Enthrall",
    "Traction control",
    "Clutch",
    "Golf lesson topic",
    "Stagehand",
    "Traction",
    "Stage assistant",
    "Handle",
    "Subject of a golf lesson",
    "Part of a tennis racket",
    "Stagehand",
    "Part of golf instruction",
    "Tennis lesson subject",
    "Hang on tight?",
    "Basic of golf instruction",
    "Racket part",
    "Suitcase",
    "Handshake",
    "Theater schlepper",
    "Understanding",
    "Hold",
    "What cleats increase",
    "Tight hold",
    "Understanding",
    "Film crew member",
    "Stagehand",
    "Golf club part",
    "Handlebar feature",
    "\"Get a ___!\"",
    "Traveling bag",
    "Stagehand",
    "Clutch",
    "\"Get a ___ on it!\"",
    "Catch hold of"
  ],
  "riff": [
    "Repeated bit in jazz",
    "Expound (on)",
    "Recurring snippet in a bass line, say",
    "Speak extemporaneously (on)",
    "Melodic phrase",
    "Jazz line",
    "Jazz phrase",
    "Musical phrase",
    "Musical phrase",
    "Comedic spiel",
    "Take off (on)",
    "Jazz phrase",
    "Jazz bit",
    "Music bit",
    "Improvised bit",
    "Jazz lick",
    "Jazz bit",
    "Jazz phrase",
    "Bit of jazz",
    "Songwriter's starting point, maybe",
    "Jazz phrase",
    "Musical phrase",
    "Repeated musical phrase",
    "Jazz theme"
  ],
  "geld": [
    "Neuter, as a horse",
    "Neuter, as a male horse",
    "Fix, in a way",
    "Neuter",
    "Neuter, as a stud",
    "Neuter, as a stallion",
    "Neuter",
    "Neuter, as a male horse",
    "Neuter",
    "Desex, as a stallion",
    "Deprive of vitality",
    "Neuter, as a horse",
    "Neuter",
    "Neuter",
    "Neuter",
    "Neuter",
    "Neuter"
  ],
  "dior": [
    "Designer Christian",
    "Christian who said \"Happiness is the secret to all beauty\"",
    "Noted Christian",
    "Man of the cloth?",
    "Designer Christian",
    "Christian with a big house",
    "Fashionable Christian",
    "French fashion icon",
    "Who said \"A woman's perfume tells more about her than her handwriting\"",
    "Big name in handbags",
    "Gucci competitor",
    "Designer who said \"My dream is to save women from nature\"",
    "J'adore perfumer",
    "___ Homme (fashion line)",
    "J'adore perfume maker",
    "French Christian",
    "\"J'adore ___\" (ad slogan)",
    "Designer Christian",
    "Christian with some intelligent designs?",
    "Fashionable Christian",
    "Maker of the fragrance Sauvage",
    "Fashion designer Christian",
    "French Christian",
    "New Look pioneer",
    "Early Pierre Cardin employer",
    "One of its fragrances is Poison",
    "Christian of couture",
    "Gucci contemporary",
    "Parisian house of design",
    "Fashionable Christian",
    "One with designs on women",
    "J'adore fragrance maker",
    "New Look designer",
    "Christian name?",
    "___ Homme (perfume brand)",
    "J'adore perfumer",
    "Christian in France",
    "A-line creator",
    "Fashionable Christian",
    "Fashion designer Christian",
    "Christian in fashion",
    "Christian with many robes?",
    "Midnight Poison maker",
    "Early Saint-Laurent employer",
    "Christian of the cloth?",
    "Couturier Christian",
    "\"New Look\" designer of 1947",
    "New Look designer",
    "Designer Christian",
    "Pricey gown",
    "Classic Paris couture house",
    "Designer Christian",
    "\"New Look\" pioneer",
    "Stylish gown",
    "Stylish Christian",
    "Christian name",
    "Last name in fashion",
    "Fashionable dress",
    "Christian name",
    "New Look designer",
    "Christian from France",
    "New Look designer",
    "Stylish gown",
    "Big name in perfumes",
    "Classic dress",
    "French Christian",
    "\"New Look\" designer",
    "Noted Christian",
    "Maker of the fragrance Poison",
    "New Look designer",
    "House of ___",
    "New Look designer",
    "New Look designer",
    "Big name in fashion",
    "French Christian",
    "Big name in fashion",
    "\"New Look\" designer",
    "Designer Christian",
    "New Look designer",
    "Fashionable name",
    "Christian of note",
    "Parisian house of design",
    "Designer Christian"
  ],
  "vees": [
    "Savvy couple?",
    "Flying formations",
    "Pair of skivvies?",
    "Pair of skivvies?",
    "Some hand signals",
    "Geese formations",
    "Some aerial show formations",
    "Revolving features?",
    "Geese flying formations",
    "Flying geese formations",
    "Some necklines",
    "Winners' signs",
    "Churchillian gestures",
    "Winners' flashes",
    "Certain necklines",
    "Geese formations",
    "Flashed signs",
    "Flying formations",
    "Victory signs",
    "Winners' signs",
    "Winner's signs",
    "Flashed signs",
    "Some sweater necks",
    "Dress necklines"
  ],
  "plex": [
    "Suffix with mega- or multi-",
    "Movie trailer?",
    "Ending with multi-",
    "Theater trailer?",
    "Common movie house name ending",
    "Suffix with cine"
  ],
  "kurd": [
    "Member of an Iraqi minority",
    "Many a northern Iraqi",
    "Northern Iraqi",
    "Member of a Turkish minority",
    "Member of an Iraqi minority",
    "Many a northern Iraqi",
    "Many a Kirkuk resident",
    "Many a Kirkuk native",
    "Resident of 29-Down",
    "Native of Kirkuk",
    "Iranian mountain dweller",
    "Northern Iraqi"
  ],
  "mewl": [
    "Whimper",
    "Little cry",
    "Whimper",
    "Whine like a baby",
    "Whimper",
    "Whimper",
    "Cry like a baby",
    "Cry querulously",
    "Whimper",
    "Whimper like a baby",
    "Cry feebly",
    "Whimper",
    "Sound like a baby",
    "Cry like a baby",
    "Cry like a baby",
    "Whimper",
    "Whimper",
    "Sound like a baby",
    "Whine",
    "Cry like a baby",
    "Baby sound",
    "Whimper",
    "Whimper like a baby",
    "Whimper",
    "Whimper",
    "Act like a baby",
    "Act like a baby",
    "Cry like a baby",
    "Whimper",
    "Whimper",
    "Whimper",
    "Whimper",
    "Snivel"
  ],
  "kops": [
    "Keystone ___",
    "Keystone ___",
    "Keystone ___ of early film",
    "Slapstick group, briefly"
  ],
  "brio": [
    "Con ___ (musical direction)",
    "Vivacity",
    "Liveliness, in music",
    "Vim and vigor",
    "Panache",
    "Spirit",
    "Con ___ (briskly, in music)",
    "Verve",
    "Pizazz",
    "Pizazz",
    "Vivacity",
    "Pizazz",
    "Pizazz",
    "Gusto",
    "Pizazz",
    "Verve",
    "Vivacity",
    "Liveliness",
    "Vivacity",
    "Vigor",
    "Animation",
    "Vivacity",
    "Pizazz",
    "Pep",
    "Verve",
    "Vivacity",
    "Verve",
    "Get-up-and-go",
    "Esprit",
    "Vivacity",
    "Vigor",
    "Pizazz",
    "Con ___ (vigorously)",
    "Spirit",
    "Vigor",
    "Punch, so to speak",
    "Vivacity",
    "Get-up-and-go",
    "Vivacity",
    "Verve",
    "Vigor",
    "Zest for life",
    "Pizazz",
    "Verve"
  ],
  "seep": [
    "What frozen foods may do in paper grocery bags",
    "Percolate",
    "Ooze",
    "Ooze",
    "Trickle",
    "Escape slowly",
    "Ooze",
    "Creep out?",
    "Percolate",
    "Percolate",
    "Slip (through)",
    "Ooze",
    "Bleed (through)",
    "Flow slowly",
    "Percolate",
    "Move like groundwater",
    "Percolate",
    "Ooze",
    "Ooze",
    "Slip (through)",
    "Flow slowly",
    "Ooze",
    "Get through the cracks",
    "Slip through the cracks",
    "Ooze",
    "Osmose",
    "Trickle",
    "Leak slowly",
    "Fall through the cracks?",
    "Ooze",
    "Go out very slowly",
    "Trickle (through)",
    "Ooze",
    "Ooze",
    "Ooze",
    "Trickle",
    "Flow slowly",
    "Ooze",
    "Ooze",
    "Ooze",
    "Ooze",
    "Ooze",
    "Percolate",
    "Percolate",
    "Trickle out",
    "Ooze",
    "Ooze",
    "Percolate",
    "Percolate",
    "Dribble",
    "Leak slowly",
    "Trickle",
    "Slip through the cracks",
    "Percolate",
    "Leak slowly",
    "Ooze",
    "Ooze",
    "Penetrate slowly",
    "Ooze through the cracks",
    "Flow slowly",
    "Ooze",
    "Trickle (in)",
    "Ooze",
    "Ooze",
    "Ooze",
    "Escape through a crevice",
    "Trickle",
    "Ooze",
    "Slip through the cracks",
    "Dribble",
    "Filter",
    "Ooze",
    "Ooze",
    "Trickle (through)",
    "Ooze",
    "Trickle",
    "Get introduced slowly",
    "Slip (in)",
    "Ooze",
    "Percolate slowly",
    "Penetrate, with \"through\"",
    "Permeate",
    "Percolate",
    "Ooze",
    "Trickle",
    "Escape, in a way",
    "Exude",
    "Trickle",
    "Exude",
    "Trickle",
    "Transude",
    "Slip through the cracks",
    "Ooze",
    "Ooze",
    "Creep through the cracks",
    "Filter",
    "Osmose",
    "Ooze"
  ],
  "ursi": ["Bears: Lat.", "Bears: Lat.", "Latin bears", "Bears: Lat."],
  "gill": [
    "Bass organ",
    "Breathtaking sight in the ocean?",
    "Breathing aid",
    "Bass organ",
    "Underwater breather",
    "Underwater breathing apparatus",
    "Mushroom cap part",
    "Four fluid ounces",
    "Flounder's filter",
    "It obtains oxygen from water",
    "Fish organ",
    "Quarter-pint",
    "Fish's breathing organ",
    "Part of a fish",
    "Quarter pint",
    "Breathing organ"
  ],
  "skin": [
    "It's got you covered",
    "Foundation location",
    "Canvas for a tattoo",
    "Hide",
    "Exterior",
    "Scrape",
    "Complete coverage?",
    "Tattooist's canvas",
    "Scrape, as the knee",
    "Superficially injure",
    "It's all around you",
    "Banana discard",
    "Scrape, in a way",
    "\"Canvas\" for tattoos",
    "Scrape",
    "Scrape",
    "Scrape, as a knee",
    "Dermatologist's study",
    "A stake, metaphorically",
    "Inside protector",
    "Injure, as the knee",
    "Scrape",
    "Partner of bones",
    "Body protector",
    "Tattoo artist's \"canvas\"",
    "It's got you covered",
    "Where pores are",
    "Casing",
    "Bones' partner",
    "Scrape",
    "It may be fair",
    "It's got you covered",
    "Casing",
    "Scrape",
    "Defraud, slangily",
    "Flesh",
    "Scrape, as the knee",
    "Body wrapper",
    "Trapper's offering",
    "Injure, as a knee",
    "Scrape",
    "Excoriate",
    "Scrape, as the knee",
    "Hide but not hair",
    "Cheat, slangily",
    "Potato part",
    "Word before deep or dive"
  ],
  "fast": [
    "Breakneck ... or something to break",
    "Go to extremes, foodwise",
    "Observe Ramadan",
    "Observance on Yom Kippur or during Ramadan",
    "Fleet",
    "Not eat",
    "Observe Ramadan",
    "Word before food, paradoxically?",
    "Presto",
    "Ramadan observance",
    "With 59-Across, basketball tactic",
    "Completely dry, as a racetrack",
    "Speedy",
    "Express",
    "Eschew one's food?",
    "___ and 61-Across (one way to play)",
    "Unfading",
    "Partner of hard",
    "Lickety-split",
    "Quick",
    "Firmly fixed",
    "Like some friends",
    "Have no input?",
    "Like some friends",
    "Cut off all intake",
    "Speedy",
    "How a painkiller should act",
    "Lickety-split",
    "Quick",
    "Secured",
    "Firmly secured",
    "Firmly secured",
    "Observe Yom Kippur",
    "Like some tracks and talkers",
    "Certain protest",
    "Take nothing in",
    "Prisoner's protest",
    "Yom Kippur ritual",
    "Securely",
    "Ramadan observance",
    "Ultimate diet",
    "Sexually unrestrained",
    "Lent activity",
    "Quick",
    "On the double",
    "Crash diet",
    "Ramadan observance",
    "Certain protest",
    "Firm",
    "Accelerated",
    "Observe Yom Kippur, in a way",
    "Speedy",
    "Eschew food",
    "Eat not",
    "Opposite of gormandize",
    "\"Spartacus\" author",
    "Express"
  ],
  "jean": [
    "___ jacket",
    "Denim",
    "___ jacket",
    "Levi's material",
    "Denim fabric",
    "___ jacket (bit of casualwear)",
    "___ jacket (denim top)",
    "Western wear",
    "Actress Stapleton",
    "___ Kennedy Smith (sister of J.F.K.)",
    "Shepherd who co-wrote \"A Christmas Story\"",
    "Jacket material",
    "Pirate Lafitte",
    "Actress Stapleton of \"All in the Family\"",
    "___ material (sturdy fabric)",
    "Painter Fouquet",
    "Sturdy, twilled cotton fabric",
    "Pirate Laffite",
    "\"Finlandia\" composer Sibelius",
    "Psychologist Piaget",
    "Renoir, the film director",
    "Artist Millet or Corot",
    "Shepherd who wrote \"The Ferrari in the Bedroom\"",
    "Author Auel",
    "Sturdy cloth"
  ],
  "chub": [
    "Common baitfish in North American streams",
    "Common bait fish",
    "Carp family fish",
    "Thick-bodied fish",
    "Great Lakes fish",
    "Great Lakes fish",
    "Great Lakes fish"
  ],
  "tare": [
    "Scale amount",
    "Button on a deli scale",
    "Set to zero, as a scale",
    "Word on a deli scale",
    "Weight of an empty container",
    "Weight allowance",
    "Empty container's weight",
    "Amount subtracted from gross weight",
    "Weigh station factor",
    "Scale deduction",
    "Weight without a load",
    "Something discounted at a deli?",
    "Weight allowance",
    "Amount ignored in weighing",
    "Word on a scale",
    "Weight allowance",
    "Wrapper weight",
    "Weight of a container on a scale",
    "Weight allowance",
    "Container weight",
    "Unloaded truck's weight",
    "Scale deduction",
    "Weight not charged for",
    "Wrapping weight",
    "Gross minus net, to a trucker",
    "Weight not counted in pricing",
    "Weight not charged for",
    "Wrapper weight",
    "Container weight",
    "Car weight without fuel or load",
    "Gross-weight deduction",
    "Weight allowance"
  ],
  "ents": [
    "Docs treating vertigo",
    "Tolkien race",
    "Specialist M.D.s",
    "Creatures in Tolkien's Fangorn Forest",
    "Apnea-treating M.D.s",
    "Tolkien tree creatures",
    "Drs. that see head cases",
    "Tolkien creatures",
    "Tolkien tree creatures",
    "Vertigo diagnosers, briefly",
    "Mastoiditis specialists, for short",
    "Foes of Saruman in \"The Two Towers\"",
    "Head docs?",
    "Docs with little flashlights",
    "Wizards of aahs, for short?",
    "Middle-earth creatures",
    "Fangorn denizens",
    "M.D.'s with tiny flashlights",
    "Fangorn Forest denizens",
    "Specialty docs",
    "Tolkien forest creatures",
    "Tolkien tree creatures",
    "Tolkien's talking tree race",
    "Forest race of fantasy",
    "\"The Lord of the Rings\" tree creatures",
    "Tolkien's tree creatures",
    "Tolkien creatures",
    "Middle-earth creatures",
    "Tolkien's tree beings",
    "\"The Lord of the Rings\" tree creatures",
    "Sinusitis docs",
    "Fangorn Forest race",
    "Docs with penlights",
    "Tolkien creatures",
    "Tolkien tree creatures",
    "Med. specialists who might treat tonsillitis",
    "Humanoid trees in Tolkien",
    "Docs who might treat sinusitis",
    "Middle-earth creatures",
    "Tolkien creatures",
    "Tolkien creatures",
    "Tolkien race",
    "Tolkien's talking trees",
    "Certain specialty docs",
    "Warring Tolkien creatures",
    "Specialist M.D.'s",
    "Tolkien forest creatures",
    "M.D.'s who may cure snoring",
    "Middle-earth beings",
    "Tolkien creatures",
    "Tolkien tree creatures",
    "Some A.M.A. members",
    "Middle Earth creatures",
    "Otolaryngolo-gists, for short",
    "Tolkien's ___ of Fangorn",
    "Literary tree dwellers",
    "Tolkien tree creatures",
    "Tolkien forest creatures",
    "Tolkien tree-men",
    "Tolkien creatures",
    "Tolkien tree creatures",
    "Tolkien tree-men",
    "Tolkien tree giants",
    "Tolkien creatures",
    "Tolkien tree-men",
    "Tolkien tree giants",
    "Treebeard and others of Middle-earth",
    "Tolkein's forest giants",
    "\"Lord of the Rings\" tree people",
    "Tolkien creatures"
  ],
  "serf": [
    "Manorial worker",
    "Peon",
    "Drudge",
    "Medieval worker",
    "Menial worker",
    "Drudge",
    "One working on an estate",
    "Feudal worker",
    "Medieval drudge",
    "Manor occupant of yore",
    "One bound to the land",
    "One doing the lord's work",
    "Lord's subject",
    "Old-time worker",
    "Lowly worker",
    "Lowly worker",
    "Medieval toiler",
    "Feudal worker",
    "Lowly worker",
    "Feudal worker",
    "Underling of yore",
    "Feudal peasant",
    "Dennis in \"Monty Python and the Holy Grail,\" e.g.",
    "Medieval drudge",
    "One doing the lord's work",
    "Lord's worker",
    "Hard worker",
    "Feudal worker",
    "One bound to do work",
    "Vassal",
    "Vassal",
    "Worker on a 43-Across",
    "Feudal worker",
    "Feudal worker",
    "Toiler of yore",
    "Lord's worker",
    "Freeman's opposite",
    "Lowly worker",
    "Underclassman?",
    "Lowly laborer",
    "Lord's worker",
    "Master's worker",
    "Feudal drudge",
    "Feudal figure",
    "Bondsman",
    "Lowly worker",
    "Lowly worker",
    "Toiler",
    "Drudge",
    "One lorded over?",
    "One in bondage",
    "One making a feudal effort?",
    "Drudge of feudal times",
    "Medieval laborer",
    "One doing the lord's work",
    "Slave",
    "One who does the lord's work",
    "Bondman",
    "Lord's lackey",
    "Lord's worker",
    "Lord's worker",
    "One held in thrall",
    "Esne"
  ],
  "hoot": [
    "It's a laugh",
    "Night call",
    "Laugh riot",
    "Cry at night",
    "Hilarious sort",
    "Hilarious type",
    "Owl's sound",
    "Sidesplitter",
    "Laugh riot",
    "\"I don't give a ___\"",
    "Highly amusing experience",
    "Call at night",
    "Knee-slapper",
    "Blast",
    "Knee-slapper",
    "Happening with lots of laughs",
    "Holler's partner",
    "Knee-slapper",
    "Least bit of concern",
    "Thigh-slapper",
    "Laugh riot",
    "Owl sound",
    "Knee-slapper",
    "Knee-slapper",
    "Knee-slapper",
    "Derisive shout",
    "Knee-slapper",
    "Knee-slapper",
    "Something funny",
    "Funny situation",
    "Catcall",
    "Laugh-a-minute",
    "Catcall",
    "Jeer",
    "What 69-Across will grow up to do",
    "Least bit of concern",
    "Holler's partner",
    "Derisive sound",
    "Laugh and a half",
    "The least concern",
    "The least bit of concern"
  ],
  "stns": [
    "Listings on a train sched.",
    "Amtrak stops: Abbr.",
    "Depots: Abbr.",
    "Amtrak stops: Abbr.",
    "Metro map points: Abbr.",
    "Platform locales: Abbr.",
    "Radio choices: Abbr.",
    "Dots on some maps: Abbr.",
    "Boarding zones: Abbr.",
    "RR stops",
    "RR stops",
    "TV Guide listings: Abbr.",
    "Boarding places: Abbr.",
    "Channels: Abbr.",
    "Stops: Abbr.",
    "Stops along the line: Abbr.",
    "Debarkation points: Abbr.",
    "Places by the tracks: Abbr.",
    "Amtrak stops: Abbr.",
    "They're on a sched."
  ],
  "just": [
    "Fair",
    "Barely",
    "Solomonlike",
    "With 18-Across, \"To be on the safe side ...\"",
    "Exactly",
    "With \"in\" and 12-Down, as a precaution",
    "Fair-minded",
    "Merely",
    "Fair",
    "Solomonlike",
    "\"___ do it\"",
    "Like some deserts",
    "Barely",
    "Equitable"
  ],
  "ibis": [
    "Wetlands denizen",
    "Long-billed wading bird",
    "Long-billed wader",
    "One of the first birds released by Noah after the flood, in legend",
    "Long-legged wader",
    "Sacred symbol of ancient Egypt",
    "Sacred symbol of ancient Egypt",
    "Leggy wader",
    "Egyptian sacred bird",
    "Bird in Egyptian hieroglyphics",
    "Cousin of a spoonbill",
    "Hieroglyphic bird",
    "National bird of Trinidad and Tobago",
    "Animal avatar of Thoth",
    "Bird with a forcepslike bill",
    "Relative of a spoonbill",
    "Ancient hieroglyph",
    "Wading bird",
    "Sacred Egyptian bird",
    "Object of ancient Egyptian veneration",
    "Large bill holder",
    "Long-billed bird",
    "University of Miami mascot",
    "Nile bird",
    "Long-legged wader",
    "Ancient Egyptians revered it",
    "Sacred symbol of ancient Egypt",
    "Sacred bird of ancient Egypt",
    "Head of the Egyptian god Thoth",
    "Head of the Egyptian god Thoth",
    "Cousin of a stork",
    "Bird seen in hieroglyphics",
    "Relative of a stork",
    "Long-legged wader",
    "Object of veneration in ancient Egypt",
    "Everglades denizen",
    "Curve-billed wader",
    "Cousin of a stork",
    "Wading bird",
    "Big-billed bird",
    "Cousin of a spoonbill",
    "Sacred bird of the pharaohs",
    "Egyptian sacred bird",
    "One sacred to ancient Egyptians",
    "Wading bird",
    "Thoth had the head of one",
    "Wading bird",
    "Wading bird",
    "Wading bird",
    "Nile wader",
    "Incarnation of the Egyptian god Thoth",
    "Sacred ___",
    "Everglades wader",
    "Wading bird",
    "Wading bird",
    "Wading bird",
    "Nile bird",
    "Wading bird",
    "Ancient Egyptians held it sacred",
    "Hieroglyphics bird",
    "Nile wader",
    "Wading bird",
    "Egyptian art figure",
    "Head of the Egyptian god Thoth",
    "It was sacred in ancient Egypt",
    "Wading bird",
    "Spoonbill cousin",
    "Cousin of a heron",
    "Wading bird",
    "Sacred bird of the Nile",
    "Nile wader",
    "Long-legged wader",
    "Hieroglyph symbol",
    "Sacred bird of the Pharaohs",
    "Sacred Egyptian bird",
    "Wading bird",
    "Nile bird",
    "One that gets a leg up?",
    "Nile bird",
    "Long-legged bird",
    "Leggy wader",
    "Tropical wading bird",
    "Relative of the heron",
    "Hieroglyphic representation"
  ],
  "tack": [
    "Add (on)",
    "Bulletin board fastener",
    "Approach in handling something",
    "Bulletin board item",
    "Change course at sea",
    "Bulletin board fastener",
    "Strategy",
    "Course of action",
    "Sail a zigzag course",
    "Bulletin board fastener",
    "Yachter's heading",
    "Poster holder",
    "Sticker",
    "Bulletin board item",
    "Zigzag movement",
    "Bulletin board affixer",
    "Sail a zigzag course",
    "Bulletin board item",
    "Sailing maneuver",
    "Equestrian gear",
    "Small fastener",
    "Bulletin board sticker",
    "Yacht heading"
  ],
  "erbe": [
    "Flavorers in Italian cookery",
    "Kathryn of \"Law & Order: Criminal Intent\"",
    "Player of Det. Eames on \"Law & Order: Criminal Intent\"",
    "Kathryn of \"Law & Order: Criminal Intent\"",
    "Kathryn of \"Law & Order: Criminal Intent\""
  ],
  "rpgs": ["Dungeons & Dragons and others, for short"],
  "pard": [
    "Cowboy's pal",
    "Cowpoke's friend",
    "Pal on the range",
    "Western buddy",
    "Dude ranch handle",
    "Fellow cowboy",
    "Tex's sidekick",
    "Ponderosa pal",
    "Western pal",
    "Cowpoke's bud",
    "Sidekick",
    "Chum in chaps",
    "Tex's friend",
    "Bud",
    "Wrangler's buddy",
    "Frontier friend",
    "Cowboy's friend",
    "\"Howdy!\" sayer",
    "Cowpoke's buddy",
    "Friend on the trail",
    "One greeted on a ranch",
    "One greeted with a \"howdy\"",
    "Western friend",
    "Wrangler's pal"
  ],
  "walk": [
    "Base on balls",
    "Beat the rap",
    "Constitutional",
    "Go on strike",
    "Result of four balls, in baseball",
    "Send to base on balls",
    "Arcade",
    "Cement layer's work",
    "Base on balls",
    "Conserve gasoline, maybe",
    "Corner light",
    "Free pass, of a sort",
    "Hit alternative",
    "Street light",
    "Hoof it",
    "Not a hit or an out",
    "Hoof it",
    "Alternative to hit",
    "Beat the rap",
    "Constitutional"
  ],
  "calm": [
    "Placid",
    "Zero on the Beaufort scale",
    "Quiet",
    "Collected",
    "Fifth word ...",
    "Storm harbinger, maybe",
    "Composed",
    "What comes before the storm",
    "Chill, so to speak",
    "Unruffled",
    "Not windy",
    "Unruffled",
    "Not wavy, say",
    "Still",
    "Pacify",
    "0 on the Beaufort scale",
    "Like Type B personalities",
    "Smooth, as seas",
    "Storm's predecessor",
    "Not having big waves",
    "Sailboat stopper",
    "Simmer (down)",
    "Like the eye of a hurricane",
    "Tranquility",
    "Hard to fluster",
    "Sang-froid",
    "Yachter's woe",
    "Not choppy",
    "Serene",
    "Less than 1 m.p.h., as winds",
    "Storm preceder",
    "Peace and quiet",
    "Good trait to have in a crisis",
    "Yachter's woe",
    "Pacific",
    "Time of smooth sailing",
    "Serene",
    "Like the eye of a storm",
    "Yachter's bane",
    "Waveless",
    "Waveless",
    "Storm preceder",
    "Peace",
    "Like some seas",
    "Halcyon",
    "Not windy",
    "Not windy",
    "O on the Beaufort scale"
  ],
  "rime": [
    "Coat in winter",
    "Frosty coating",
    "Frost formed from fog",
    "Frosty encrustation",
    "Winter coat",
    "Cold-weather coat",
    "Dubai dignitary / Winter coat",
    "Hoarfrost",
    "Frosty film",
    "Winter coat",
    "Hoarfrost",
    "Icy coating",
    "Winter coat",
    "Frosty coating",
    "Frost",
    "Frost",
    "\"Ancient Mariner\" verse",
    "Extra layer in winter?",
    "Natural coat",
    "Frosty coating",
    "White coat",
    "White coat",
    "Hoarfrost",
    "Coating of frost",
    "Frost",
    "Nature film?",
    "Icy coating",
    "Winter coating",
    "Winter coat",
    "Natural coat",
    "White coat",
    "\"The ___ of the Ancient Mariner\"",
    "Icy coating",
    "Winter coating",
    "Frost",
    "Coleridge work",
    "Coleridge work",
    "Cold coating",
    "Frost",
    "Frost",
    "Frost",
    "Coleridge poem",
    "Relative of hoarfrost",
    "Frost"
  ],
  "casa": [
    "House in Mexico",
    "Home in Havana",
    "Spanish abode",
    "Spanish house",
    "Spanish word repeated in a welcoming phrase",
    "Word repeated in \"Mi ___ es su ___\"",
    "Home to Castro",
    "Spanish house",
    "Word repeated in \"Mi ___ es su ___\"",
    "Mexican quarters",
    "Un domicilio",
    "Home to Hernando",
    "Southwestern home",
    "Home for Jose",
    "One may be found on una calle",
    "Hidalgo home",
    "La mia es la tuya, they say",
    "House in Havana",
    "Home, in Honduras",
    "House, in Havana",
    "Sight along una calle",
    "Spanish house",
    "Residencia",
    "___ Rosada (Argentine presidential manor)",
    "Jorge's house",
    "House in Spain",
    "Hombre's home",
    "House on a hacienda",
    "Zorro's house",
    "Home in Havana",
    "Hombre's home",
    "House in Spain",
    "Latin quarters?",
    "Durango domicile",
    "Julio's home",
    "Latin quarters",
    "Spanish house",
    "Home overseas",
    "Home to Jose",
    "Huelva home",
    "Jose's home",
    "Dwelling in Durango",
    "Familia dwelling",
    "Jose's house",
    "Hombre's home",
    "___ Grande, Ariz.",
    "Pedro's home",
    "___ Grande, Ariz.",
    "Castro calls it home",
    "Hernando's hideaway?",
    "___ de Dios (church, in Spain)",
    "Huelva house",
    "Placido domicile",
    "Home for la familia",
    "Bambino's home",
    "Hacienda, e.g.",
    "Durango abode",
    "Site for a sala",
    "Hacienda, e.g.",
    "Sala site",
    "Pedro's house",
    "___ Grande, Ariz.",
    "___ Grande, Ariz."
  ],
  "mesa": [
    "Flat-topped hill",
    "Hill with no peak",
    "Big butte",
    "Painted Desert feature",
    "___ Verde National Park",
    "It's a butte and then some",
    "Large, flat-topped hill",
    "Tempe neighbor",
    "Flat-topped hill",
    "Small plateau",
    "Four-letter U.S. city with the highest population",
    "Canyonlands National Park feature",
    "Canyonlands National Park sight",
    "___ Verde National Park",
    "Big butte",
    "Big butte",
    "___ Verde National Park",
    "Grand Canyon sight",
    "Western plateau",
    "Formation with steep cliffs",
    "Spanish for \"table\"",
    "City between Tempe and Apache Junction",
    "Geological flat top",
    "Badlands sight",
    "Tenis de ___ (Ping-Pong, in Spain)",
    "Southwest hikers' destination",
    "Arizona's third-largest city",
    "___ Verde National Park",
    "U.S. city that becomes another U.S. city if you move the last letter to the front",
    "Small plateau",
    "Small plateau",
    "High land",
    "Natural table",
    "Badlands sight",
    "Small plateau",
    "Southwest city founded by Mormon pioneers",
    "Badlands feature",
    "Geographical formation whose name is Spanish for 9-Down",
    "Neighbor of Apache Junction",
    "Beaut of a butte?",
    "Mountain with a flat top",
    "Tableland",
    "Maricopa County city",
    "Taxco table",
    "Tijuana table",
    "Badlands formation",
    "Mini-plateau",
    "La ___, Calif.",
    "Pueblo site",
    "___ Verde National Park",
    "Badlands sight",
    "Badlands feature",
    "Costa ___",
    "Costa ___, Calif.",
    "Badlands sight",
    "Flat-topped land feature",
    "Badlands sight",
    "Small plateau",
    "Miniature plateau",
    "Mini-plateau",
    "Broad terrace with a steep side",
    "Badlands landform",
    "Miniature plateau",
    "Southwest sight",
    "Western flattop?",
    "Tableland",
    "Phoenix neighbor",
    "Silhouette in a Southwest skyline",
    "Painted Desert feature",
    "Tijuana table",
    "City founded by Mormon pioneers",
    "City south of the Salt River",
    "Big butte",
    "Winter home of the Chicago Cubs",
    "Small plateau",
    "Vaquero's view",
    "Arizona spring training site",
    "Table setting?",
    "It's surrounded by walls",
    "City near Phoenix",
    "Arizona city",
    "Badlands feature",
    "Flat-topped hill",
    "Costa ___, Calif.",
    "Flat formation",
    "City near Phoenix",
    "Monument Valley feature",
    "___ Verde",
    "Blue ___ Reservoir (Colorado's largest body of water)",
    "Arizona city",
    "High land",
    "Chicago Cubs spring training site",
    "Appropriately named Colorado county",
    "Steep-sided plateau",
    "Hopi Indian locale",
    "Tableland",
    "Part of a western landscape",
    "Arizona city",
    "___ Verde National Park",
    "City on the Salt River",
    "Tableland",
    "Phoenix neighbor",
    "Hopi reservation sight",
    "Western plateau",
    "La ___, Calif.",
    "City west of Apache Junction",
    "Rock wren's habitat",
    "Southwest sight",
    "Phoenix suburb",
    "City near Phoenix",
    "Tableland",
    "Idaho's ___ Falls",
    "Costa ___",
    "Southwestern sight",
    "Phoenix neighbor",
    "___ Verde National Park",
    "Southwestern desert feature",
    "New Mexico's Enchanted___",
    "Rimrock locale",
    "Mexican geologic feature",
    "Natural table",
    "Miniplateau",
    "Phoenix neighbor",
    "Arizona city",
    "Tableland",
    "Tableland",
    "City near Phoenix",
    "Flattop, of sorts"
  ],
  "mama": [
    "One of a baby's first words",
    "Crib cry",
    "Bear whose bed is too soft, in a children's story",
    "Baby's first word, maybe",
    "Frequent first word",
    "Papa's mate",
    "2Pac's \"Dear ___\"",
    "Who \"can't buy you love\" in an Elton John hit",
    "One of the Three Bears",
    "Word before \"Ooh, didn't mean to make you cry\" in Queen's \"Bohemian Rhapsody\"",
    "Call from a crib",
    "Baby ___",
    "Who \"said knock you out,\" in an LL Cool J hit",
    "Doll's cry",
    "\"When You're Good to ___\" (\"Chicago\" tune)",
    "\"When You're Good to ___\" (\"Chicago\" song)",
    "Pistol packer in a 1943 #1 hit",
    "___ Cass",
    "24-Down producer, informally",
    "First word, maybe",
    "Papa's mate",
    "Papa's partner",
    "Title acquired the moment someone is born?",
    "Doll's cry",
    "\"That's all right, ___\" (lyric from Elvis's first single)",
    "Doll call",
    "\"Baby ___\" (2008 comedy)",
    "___ Cass Elliot",
    "With 30-Down, \"Dream a Little Dream of Me\" singer",
    "___ Bear",
    "Remembered one?",
    "Owner of the bed that was too soft in \"Goldilocks and the Three Bears\"",
    "Papa's partner",
    "Elvis Presley's \"___ Liked the Roses\"",
    "One of the folks",
    "Baby carrier",
    "\"___ Said\" (1961 hit)",
    "Papa's mate",
    "Singer Michelle or Cass",
    "Word from the crib",
    "Doll's cry",
    "___ Bear",
    "Cradle call",
    "With 10-Across, popular 1960's-70's singer",
    "\"Don't Tell ___\" (\"Cabaret\" song)",
    "Remembered family member?",
    "Nursery cry",
    "Doll's cry",
    "\"Hot\" one",
    "1950's TV comedy/drama starring Peggy Wood",
    "Doll's cry",
    "Call from the nursery",
    "Papa's partner",
    "Baby's word",
    "\"Oh, ___!\"",
    "Doll's cry",
    "Cass, e.g.",
    "Red-hot one?",
    "Early 50's TV comedy",
    "First word out of one's mouth?",
    "Doll's cry",
    "One of the Three Bears",
    "Doll's utterance",
    "Person who hears \"goo-goo\"",
    "Doll's word",
    "Doll's cry",
    "Big ___",
    "Doll's cry",
    "Cry from a crib",
    "Baby's first word, maybe",
    "Doll's cry",
    "Family head",
    "\"Red hot\" person",
    "Peggy Wood's title role on 50's TV",
    "Doll's cry",
    "Doll's cry",
    "Doll's cry",
    "Nursery call",
    "Doll's cry",
    "\"Oh, ___!\"",
    "\"Pistol Packin' ___\"",
    "Baby doll's cry",
    "Doll's cry",
    "Peggy Wood title role on early TV",
    "Michelle Phillips, e.g., in 60's pop",
    "Blues singer Big___Thornton",
    "Nursery cry",
    "Family V.I.P.",
    "\"I Remember ___\"",
    "Connie Francis hit, 1960",
    "Papa's partner",
    "Doll's cry",
    "Peggy Wood TV role, 1949-56",
    "Doll's cry",
    "Doll's cry",
    "Baby's cry"
  ],
  "cuss": [
    "Talk a blue streak?",
    "Be profane",
    "Use four-letter words",
    "Ornery sort",
    "Ornery sort",
    "Ornery sort",
    "Ornery sort",
    "Codger",
    "Swear",
    "Talk a blue streak?",
    "Spit four-letter words",
    "Codger",
    "Ornery one",
    "Say \"%@&#!\"",
    "Talk a blue streak?",
    "With 4-Down, upbraid in no uncertain terms",
    "Talk a blue streak?",
    "Utter a few choice words",
    "Emulate a sailor",
    "Swear",
    "Ornery fellow",
    "Ornery sort"
  ],
  "core": [
    "Part of a reactor",
    "Word before curriculum or meltdown",
    "Apple throwaway",
    "Uneaten part of an apple",
    "Common ___",
    "Heart",
    "Middle Earth?",
    "Muscle group targeted by Pilates",
    "___ curriculum",
    "Mandatory courses",
    "Heart",
    "Abs and such",
    "Fitness center?",
    "Geological sample",
    "Heart",
    "Heart",
    "Compulsory courses",
    "Center",
    "Essence",
    "Part of a planet",
    "Abdominal and lower-back muscles, collectively",
    "Kind of curriculum",
    "Center",
    "Nuclear reactor part",
    "Part of the earth",
    "*Center",
    "Part of an apple or the earth",
    "Part of an apple",
    "Where a pear's seeds are",
    "Uneaten part of an apple",
    "Uneaten part of an apple",
    "Apple seeds' location",
    "Curriculum part",
    "Set of classes all students must take",
    "Heart",
    "Heart",
    "Very center",
    "Pith",
    "Middle earth?",
    "Heart",
    "Geological sample",
    "Apple throwaway",
    "Heart",
    "Nub",
    "Apple leftover",
    "Delicious leftover",
    "Essence",
    "Rights org.",
    "Like some college curricula",
    "Heart of a Baldwin",
    "Center",
    "Place for seeds",
    "Center",
    "Rights org.",
    "Apple leftover",
    "Apple leftover",
    "Heart",
    "Apple seeds' site",
    "Rotten to the ___",
    "Like some beliefs",
    "Heart",
    "Essence",
    "Reactor part",
    "Geologist's sample",
    "James Farmer's org.",
    "Inner workings",
    "Rights org. estab. 1942",
    "Part of the Earth",
    "Computer memory",
    "James Farmer grp.",
    "Roy lnnis's org."
  ],
  "hued": [
    "Shaded",
    "Shaded",
    "Toned",
    "Tinged",
    "Taking a certain tone",
    "Tinted",
    "In color",
    "Colored",
    "Many-___",
    "Tinted",
    "Colored",
    "Shaded",
    "Tinted",
    "Tinged",
    "Shaded"
  ],
  "demo": [
    "Population grouping, informally",
    "Version that's just for show",
    "Brief how-to",
    "Twentysomethings, e.g.",
    "Free trial version",
    "Show",
    "Showcase",
    "Show in a showroom, say",
    "Floor model",
    "Model on display",
    "You might prepare one for potential investors",
    "Prefix with -graphic",
    "Car in a showroom",
    "Tear down, in real-estate lingo",
    "Floor model",
    "Show piece",
    "Infomercial component",
    "Software giveaway",
    "Musical pitch?",
    "Display model",
    "Floor model, often",
    "Start of a massive renovation, for short",
    "Trade fair presentation",
    "Show, informally",
    "Infomercial part",
    "Show piece",
    "Show, informally",
    "Sample recording",
    "Show, quickly",
    "Test-drive",
    "Sampler",
    "Item in a music producer's in-box",
    "Audition tape",
    "Handout from an aspiring musician",
    "It's usually not for sale",
    "Try it out",
    "Promotional item",
    "___ tape",
    "Mailing to a record exec",
    "Floor model",
    "Record sent to a record producer",
    "Floor model",
    "Expo presentation",
    "Sampler",
    "Try it",
    "Showroom model",
    "Audition tape",
    "Showroom sample",
    "Tape for a music exec",
    "Audition tape",
    "Sampler, of a sort",
    "It may be cut in a studio",
    "Certain tape",
    "Certain tape",
    "Sales pitch enclosure, maybe",
    "Audition tape",
    "Record company's receipt",
    "Showroom car",
    "Try it!",
    "Music sampler",
    "What a test driver test-drives",
    "Test-driver's car",
    "It's just for show",
    "Car at an auto dealership",
    "It's fit to be tried",
    "Expo presentation",
    "Floor model",
    "Sample",
    "Try this!",
    "Take it out for a spin",
    "Showroom model",
    "Prototype, for short",
    "Sample tape",
    "Deejay's disk",
    "Showroom model",
    "Sample record",
    "Dealer's car",
    "Floor model",
    "Car for test-driving"
  ],
  "alga": [
    "Plant used in making biofuel",
    "Plant that lacks true roots and leaves",
    "It helps turn a pond green",
    "Bit of seaweed",
    "Film bit",
    "Bit of pond growth",
    "Bit of pond scum",
    "Simple plant",
    "Lichen component",
    "Word from the Latin for \"seaweed\"",
    "Bit in a fish tank",
    "Seaweed, e.g.",
    "Pool growth",
    "Pond growth",
    "Birdbath organism",
    "Fountain growth",
    "Pond organism",
    "Bit of birdbath gunk",
    "Bit of pond slime",
    "Bit of fish food",
    "Bit of marine life",
    "Simple aquatic plant",
    "Bit of seaweed",
    "Aquatic organism",
    "Bit of stagnant-water growth",
    "Sea grass, e.g.",
    "Film bit",
    "Morsel for a guppy",
    "Sea lettuce, e.g.",
    "Unwanted swimming pool bit",
    "Mussel morsel",
    "Bit of plankton",
    "Pool organism",
    "Bit of rootless flora",
    "Pond organism",
    "Pond organism",
    "Pond organism",
    "Sea lettuce, e.g.",
    "Aquatic organism",
    "Bit of aquarium growth",
    "Kelp, for one",
    "Pond organism",
    "Bit of plankton",
    "Chlorophyll-containing microorganism",
    "Bit of scum",
    "Sea lettuce, e.g.",
    "Aquatic plant",
    "Pond organism",
    "Tiny ponddweller",
    "Pond plant",
    "Aquarium growth",
    "Pond organism",
    "Eukaryotic organism",
    "Lowlife?",
    "It lacks roots",
    "Pond floater",
    "Aquatic plant",
    "Tiny organism",
    "Sea moss constituent",
    "Bit of seafloor flora",
    "Seaweed, for one",
    "Pond organism",
    "Morsel for a sea snail",
    "Bit of ocean flora",
    "Pond dweller",
    "Bit of plankton",
    "Water growth",
    "Microscopic organism",
    "Foot of a food chain",
    "Chlorophyta bit",
    "Marine ___",
    "Stonewort, e.g.",
    "Tiny aquatic plant",
    "Pond organism",
    "Pond dweller",
    "Bit of Chlorophyta",
    "Pond organism",
    "Bit of kelp, e.g.",
    "Microscopic life",
    "Microscopic organism",
    "Spirogyra or frog spit"
  ],
  "oreg": [
    "Wash. neighbor",
    "Wash. neighbor",
    "Where the John Day R. flows",
    "State north of Calif.",
    "Wash. neighbor",
    "Salem's state: Abbr.",
    "Neighbor of Wash.",
    "Wash. neighbor",
    "It touches the Pacific O.",
    "Wash. neighbor",
    "Its state tree is the Douglas fir: Abbr.",
    "Calif. neighbor",
    "Neighbor of Calif.",
    "33rd state: Abbr.",
    "Portland's state: Abbr."
  ],
  "dibs": [
    "\"This one's all mine!\"",
    "\"It's mine!\"",
    "\"That's mine!\"",
    "\"I call that!\"",
    "\"Mine!\"",
    "Rights, informally",
    "\"Mine!\"",
    "\"Mine!,\" in a schoolyard",
    "\"That's mine!\"",
    "\"That's mine!\"",
    "Claim exclamation",
    "Childish claim",
    "\"That's mine!\"",
    "Claimer's cry",
    "Claim",
    "Claimant's claim",
    "Have ___ on (claim)",
    "Claims, informally",
    "Claim-staker's claim",
    "First ___",
    "Rights",
    "Claim",
    "Rights",
    "Claim, informally"
  ],
  "pans": [
    "Trashes",
    "Green splats, on Rotten Tomatoes",
    "Looks for gold",
    "Works (out)",
    "Forty-niners' equipment",
    "Nightmares on opening night",
    "Opening night nightmares",
    "Creperie equipment",
    "\"Ugh\" reviews",
    "Pots' partners",
    "Skewers",
    "Trashes",
    "Slams",
    "Gives a bad review",
    "Frittata equipment",
    "Slams",
    "Gives two big thumbs down",
    "Goes for the gold?",
    "They're often seen nesting in a kitchen",
    "Nasty reviews",
    "Roasts",
    "Lousy reviews",
    "D- reviews",
    "Oil receptacles",
    "Rips",
    "Producers' fears",
    "Gives zero stars to",
    "Goes for the gold?",
    "Prospectors' receptacles",
    "Some film shots ... or reactions",
    "Partner of pots",
    "Opposites of a 39-Across",
    "Criticizes",
    "One-star ratings",
    "Gives no stars"
  ],
  "hard": [
    "Like Parmesan and pecorino",
    "Like a Saturday crossword",
    "Tough",
    "Like a rock and many a roll",
    "Like walnut shells",
    "With 60-Down, gotten by great effort",
    "Herculean",
    "Brain-busting",
    "Not squishy",
    "You might give this to someone you don't like",
    "Kind of rock or candy",
    "Fatiguing",
    "Brain-busting",
    "___ as a diamond",
    "Difficult",
    "Rocklike",
    "Obdurate",
    "Compact",
    "Rocklike",
    "Steellike",
    "Not liquidy",
    "Like some cider",
    "Verifiable, as facts",
    "Knotty",
    "Brain-busting",
    "Arduous",
    "Granitelike",
    "Stony",
    "Brainbusting",
    "Like some candy",
    "Like a Friday crossword",
    "Like Hannah's heart, in song",
    "Herculean",
    "Like #4 pencils",
    "Difficult",
    "Rocky",
    "Difficult",
    "Granitelike",
    "Fermented"
  ],
  "aeri": [
    "Atmospheric prefix",
    "Gas: Prefix",
    "Prefix with -form",
    "Gas: Prefix",
    "Gas: Prefix",
    "Gas: Prefix",
    "Ethereal: Prefix"
  ],
  "shun": [
    "Ostracize",
    "Avoid",
    "Have nothing to do with",
    "Ostracize",
    "Keep away from",
    "Ostracize",
    "Snub",
    "Avoid",
    "Cold-shoulder",
    "Cold-shoulder",
    "Avoid",
    "Cold-shoulder",
    "Avoid",
    "\"... ___ the frumious Bandersnatch!\": Carroll",
    "Avoid",
    "Avoid",
    "Steer clear of",
    "Eschew",
    "Keep away",
    "Ostracize",
    "Avoid contact with",
    "Keep one's distance from",
    "Stay away from",
    "Cold-shoulder",
    "Not go near",
    "Stay away from",
    "Avoid",
    "Cold-shoulder",
    "Treat as a pariah",
    "Ostracize",
    "Never touch",
    "Blackball",
    "Stay away from",
    "Cold-shoulder",
    "Give the cold shoulder",
    "Have nothing to do with",
    "Have no truck with",
    "Cold-shoulder",
    "Blackball",
    "Forgo",
    "Eschew",
    "Eschew",
    "Snub, in a way",
    "Avoid",
    "Stay away from",
    "Opposite of seek",
    "Avoid",
    "Treat like a pariah",
    "Avoid"
  ],
  "hang": [
    "Be left undecided",
    "Put up",
    "Suspend",
    "Put up, as a picture",
    "Chill (with)",
    "Chill, with \"out\"",
    "Chill (with)",
    "Be suspended",
    "Loiter, with \"out\"",
    "Chill (with)",
    "Put up, as a painting",
    "Get the ___ of",
    "\"___ tight\"",
    "Remain undecided",
    "Pend",
    "Word before tight or loose",
    "Pal around (with)",
    "Pal around (with)",
    "\"___ 'em!\" (mob's cry)",
    "\"___ in there!\"",
    "Pend",
    "Pend",
    "Spend time (with)",
    "Not be resolved",
    "Nail to the wall",
    "Send to the gallows",
    "Nail to the wall",
    "\"___ in there!\"",
    "Put up, as a picture",
    "Be undecided",
    "Suspend",
    "Kick back (with)",
    "Put up, as a picture",
    "Pend",
    "Put up on the wall",
    "Be dependent",
    "\"___ it all!\"",
    "Dangle",
    "Put up",
    "Suspend",
    "Be undecided",
    "Be undecided",
    "Be suspended",
    "Depend",
    "Suspend",
    "String up",
    "Suspend"
  ],
  "rays": [
    "Beams",
    "Southernmost team in the American League",
    "Lines in geometry",
    "Sea creatures that are a homophone of 47-Across",
    "They may be soaked up",
    "Florida pro team",
    "They may be caught at the beach ... or out at sea",
    "Fish that are flat and wide",
    "Tampa Bay ballplayers",
    "Travelers at the speed of light",
    "Sunbeams",
    "Tropicana Field team",
    "Sunbeams",
    "Beams",
    "You may catch them on a boat, in two different ways",
    "Sunbathers catch them",
    "Tampa Bay team",
    "They may be caught on a beach",
    "Sunbeams",
    "Skates",
    "They're soaked up at the beach",
    "Some stingers",
    "They can be caught at the beach",
    "They're caught at the shore",
    "Some stingers",
    "Beams",
    "Beams",
    "You can catch them on a beach",
    "Solar emissions",
    "Beachgoers catch them",
    "Sunbathers catch them",
    "Tanners catch them",
    "Suntanners catch them",
    "They can be caught on the beach",
    "They may be caught at the beach",
    "Marine fish",
    "They can't be caught indoors",
    "They're caught on beaches",
    "They're caught on beaches",
    "Tanners catch them",
    "They're often caught lying down",
    "They may be cosmic",
    "Whip-tailed swimmers",
    "Beams",
    "Catch some ___",
    "Sunbeams",
    "Skates in water",
    "They may be caught at the shore",
    "They may be caught on a beach",
    "Solar warmers",
    "Leonard and Charles"
  ],
  "dcup": [
    "Certain bra spec",
    "Victoria's Secret specification",
    "Intimate apparel size bigger than C",
    "Big bra feature",
    "Large bra feature",
    "Large bra feature"
  ],
  "kaye": [
    "Danny who played Walter Mitty",
    "\"American Pie\" actor Eddie ___ Thomas",
    "Danny who co-starred in \"White Christmas\"",
    "Comic actor Danny",
    "\"The Inspector General\" star, 1949",
    "See 1-Across",
    "\"The Danny ___ Show\" of the 1960s",
    "\"The Court Jester\" star, 1956",
    "\"The Court Jester\" star Danny",
    "\"Chickery Chick\" bandleader",
    "Walter Mitty portrayer, 1947",
    "\"The Far Pavilions\" author M. M. ___",
    "Comedian Danny of \"The Court Jester\"",
    "\"Swing and sway\" star"
  ],
  "fete": [
    "Bash",
    "Grand party",
    "Lavish celebration",
    "Lavish celebration",
    "Big affair",
    "Bash",
    "Gala",
    "Honor in a big way",
    "Big party",
    "Big do",
    "Have a dinner for, say",
    "Celebrate",
    "Throw a party for",
    "Gala",
    "Grandly celebrate",
    "Big party",
    "Wingding",
    "Roast, e.g.",
    "Big bash",
    "Big party",
    "Bash",
    "Honor with a roast, say",
    "Grand affair",
    "Big do",
    "Party",
    "Gala gathering",
    "Big bash",
    "Big do",
    "Elaborate entertainment",
    "Grandly honor",
    "Party",
    "Lavish entertainment",
    "Celebration",
    "White House affair, maybe",
    "Big bash",
    "Celebration",
    "Big bash",
    "Garden party",
    "Grand banquet",
    "Celebration",
    "Pay high honor to",
    "It may be an honor",
    "Big do",
    "Big bash",
    "Luau, e.g.",
    "Grand party",
    "Wine and dine",
    "Gala",
    "Big party",
    "Honor with a party",
    "Bash",
    "Treat lavishly",
    "Bash",
    "Bash",
    "Lavish party",
    "Big bash",
    "Celebration",
    "Entertain grandly",
    "Entertain grandly",
    "Wine and dine",
    "Gala"
  ],
  "dowd": [
    "Columnist Maureen",
    "Pulitzer-winning writer Maureen",
    "Op-Ed writer Maureen",
    "Pulitzer-winning writer Maureen",
    "Op-Ed columnist Maureen",
    "Pulitzer winner Maureen",
    "Columnist Maureen",
    "Sharp-penned Maureen",
    "Columnist Maureen",
    "Stewart's role in \"Harvey\"",
    "Stewart's role in \"Harvey\"",
    "\"Harvey\" hero Elwood P. ___",
    "\"Harvey\" character Elwood P. ___"
  ],
  "bneg": [
    "Rare blood type, for short",
    "Rare blood type, for short",
    "Rare blood type, for short",
    "Blood type, briefly"
  ],
  "alii": [
    "Et ___",
    "Et ___ (and others: Lat.)",
    "Et ___ (citation words)",
    "Others, in a Latin list",
    "Et ___",
    "Et ___ (and others)",
    "Listed \"others\"",
    "Et ___ (and others)",
    "Others of ancient Rome?",
    "Et ___ (and others)",
    "Et ___ (and others)",
    "Et ___ (and others: Lat.)",
    "Et ___ (and others)",
    "Others, in Latin",
    "Et ___",
    "Others, to Ovid",
    "Et ___ (and others)",
    "Et ___ (and others)",
    "Et ___ (and others)",
    "Et ___",
    "Et ___",
    "Et ___",
    "Others, when listing",
    "Others, in Latin",
    "\"Others\" in a Latin phrase",
    "Others, to Octavian",
    "Et ___",
    "Et ___",
    "Et ___ (and others)",
    "Et ___ (and others)",
    "Et ___",
    "Others, in the Forum",
    "Others, to Ovid",
    "Et ___",
    "Et ___ (and others)",
    "Others, in Latin",
    "Et ___ (and others)",
    "Others, to Octavius",
    "Others, in old Rome",
    "Others, in Latin",
    "Others, to Cicero",
    "Others, to Octavian",
    "Et ___",
    "Et ___",
    "Others, to Ovid",
    "Others: Lat.",
    "Et ___ (and others)",
    "Others, in lists",
    "Others, in Latin",
    "Et ___",
    "Et ___",
    "Others, to Ovid",
    "Et ___ (and others)",
    "Ovid's \"others\"",
    "Et ___",
    "Et___",
    "Et___",
    "Et ___ (and others)",
    "Et ___",
    "Et ___",
    "Et ___ (and others): Lat.",
    "Et ___",
    "Et ___"
  ],
  "jinx": [
    "Bring bad luck",
    "\"We said the same thing!\"",
    "Bit of black magic",
    "Hoodoo",
    "Saying \"There's no way we can lose now,\" say",
    "Curse",
    "Put the whammy on",
    "Curse",
    "Whammy",
    "Give bad luck",
    "Hurl curse words at?",
    "Curse",
    "One best avoided",
    "Bringer of misfortune",
    "Hoodoo",
    "Give bad luck",
    "Bad luck cause",
    "Foredoom to failure",
    "Curse"
  ],
  "ibex": [
    "Animal also called a steinbock",
    "Wild goat",
    "Alpine climber",
    "Alpine goat",
    "Mountain goat",
    "Alpine goat",
    "Mountain goat",
    "Alpine goat",
    "Wild Alpine goat",
    "Mountain goat",
    "Mountain goat",
    "Wild mountain goat",
    "Alpine goat",
    "Mountain goat",
    "Bearded mountain climber",
    "Horned mountain dweller",
    "Mountain climber",
    "Alpine goat",
    "Wild goat",
    "Long-horned goat",
    "Surefooted goat",
    "Eurasian goat",
    "Wild goat",
    "Horned rock climber",
    "Animal with curved horns",
    "Butter overseas",
    "Animal with twisted horns",
    "Wild goat",
    "Wild goat",
    "Wild goat",
    "Nimble mountain animal",
    "Wild goat",
    "Alpine climber",
    "Surefooted goat",
    "Wild goat",
    "Mountain dweller",
    "Old World goat",
    "Wild goat",
    "Mountain denizen",
    "Wild goat"
  ],
  "onyx": [
    "Dark rock",
    "Traditional gemstone for a seventh wedding anniversary",
    "Black gem with bands",
    "Black, banded gemstone",
    "Black gemstone",
    "Inky stone",
    "Shade of black",
    "Black gemstone",
    "First gemstone mentioned in the Bible",
    "Cameo material",
    "Jet black",
    "Cameo stone",
    "Jet-black gem",
    "Black rock",
    "Black ring material",
    "Inky",
    "Material used in many high-end chess sets",
    "Cameo stone",
    "Jet black",
    "Cameo gem",
    "Jet black",
    "Panther figurine material",
    "Deep black gem",
    "Stone for a Cancer, traditionally",
    "Chalcedony variety",
    "Jet-black gem",
    "Black",
    "Black",
    "Jet black",
    "Cameo gem",
    "Gem with colored bands",
    "Common cameo stone",
    "Ornamental stone",
    "Cameo choice",
    "Stone used for chess sets",
    "Black stone",
    "It might make a cameo appearance",
    "Quartz type",
    "Cameo gem",
    "Cameo stone",
    "Black",
    "Black beauty",
    "Cameo stone",
    "Jet black",
    "Cameo stone",
    "Jet black",
    "Jet black",
    "Black as night",
    "Cameo stone",
    "Striped stone",
    "Jet black",
    "Cameo stone",
    "Cameo stone",
    "Cameo stone",
    "Kind of marble",
    "Shade of black",
    "Banded chalcedony",
    "Cameo stone"
  ],
  "sxsw": [
    "Annual Austin festival, familiarly",
    "Annual Austin festival, for short",
    "Annual Austin festival, for short",
    "Annual Austin music-and-media festival, briefly",
    "Annual Austin festival, for short",
    "Annual Austin media event, informally",
    "Annual music and film festival founded in 1987, briefly",
    "Annual Austin festival, for short"
  ],
  "troi": [
    "Counselor Deanna on the U.S.S. Enterprise",
    "\"Star Trek: T.N.G.\" character with empathic abilities",
    "\"Star Trek: T.N.G.\" counselor",
    "Half-Betazoid \"Star Trek\" character",
    "Sci-fi character who graduated from Starfleet Academy in 2359",
    "\"Star Trek: T.N.G.\" role",
    "\"Star Trek: T.N.G.\" role",
    "\"Star Trek: T.N.G.\" counselor",
    "\"Star Trek: T.N.G.\" role",
    "\"Star Trek: T.N.G.\" counselor",
    "\"Star Trek: T.N.G.\" crew member",
    "Enterprise counselor",
    "Empath on the U.S.S. Enterprise",
    "\"Star Trek: T.N.G.\" role",
    "Onetime lover of Riker on \"Star Trek: T.N.G.\"",
    "Half-Betazoid on the Enterprise",
    "\"Star Trek: T.N.G.\" counselor",
    "\"Star Trek: T.N.G.\" role",
    "\"Star Trek: T.N.G.\" counselor",
    "Enterprise counselor",
    "Name on the Enterprise",
    "\"Star Trek: T.N.G.\" role",
    "Counselor on the Enterprise",
    "U.S.S. Enterprise counselor",
    "\"Star Trek: T.N.G.\" counselor Deanna",
    "Half-human counselor on \"Star Trek: T.N.G.\"",
    "Empathic counselor of sci-fi",
    "\"Star Trek: T.N.G.\" counselor Deanna",
    "Empath on \"Star Trek: T.N.G.\"",
    "\"Star Trek\" empath",
    "Picard's counselor on \"Star Trek: T.N.G.\"",
    "Counselor on \"Star Trek: T.N.G.\"",
    "Half-human \"Star Trek: T.N.G.\" character",
    "\"Star Trek: T.N.G.\" counselor Deanna",
    "Half-Betazoid on \"Star Trek: T.N.G.\"",
    "Enterprise counselor",
    "Counselor on \"Star Trek: T.N.G.\"",
    "\"Star Trek: T.N.G.\" counselor",
    "Adviser of Capt. Picard on \"Star Trek: T.N.G.\"",
    "\"Star Trek: T.N.G.\" counselor",
    "Enterprise counselor",
    "\"Star Trek: T.N.G.\" counselor",
    "\"Star Trek: T.N.G.\" role",
    "Capt. Picard's counselor",
    "Enterprise counselor Deanna",
    "Deanna of \"Star Trek: T.N.G.\"",
    "\"Star Trek\" counselor",
    "\"Star Trek\" counselor"
  ],
  "wasa": [
    "Third and fourth word in many limericks",
    "\"There ___ young...\" (common limerick start)"
  ],
  "uomo": [
    "Italian man",
    "Man, in Italian",
    "Man, in Milan",
    "Man, in Milan",
    "Fendi ___ (men's cologne)",
    "Man, in Milan",
    "Man, in Milano",
    "Man, in Roma",
    "L'___ Vogue, Italian fashion magazine",
    "Italy's L'___ Vogue magazine",
    "Man, in 68-Across",
    "Roman man",
    "Match for una donna",
    "Man, in Italy",
    "Italian man"
  ],
  "fomo": [
    "Anxiety about being excluded from the fun, for short",
    "Anxiety about exclusion, per a modern acronym"
  ],
  "bern": [
    "\"Feel the ___\" (bygone political slogan)",
    "\"Feel the ___\" (onetime political slogan)",
    "European capital",
    "Second-most populous Swiss canton, after Zurich",
    "Alpine capital",
    "Swiss capital",
    "Capital NW of Jungfrau",
    "\"Feel the ___\" (2016 campaign slogan)",
    "Capital on the Aare",
    "Capital of Switzerland",
    "Alpine capital",
    "City where Einstein developed his special theory of relativity",
    "Capital of Switzerland",
    "It's on the Aare",
    "European capital",
    "Universal Postal Union headquarters",
    "Capital founded in 1191",
    "Home of the landmark Zeitglockenturm clock tower",
    "European capital",
    "Universal Postal Union headquarters",
    "European capital",
    "Where Einstein published his special theory of relativity",
    "City on the Aare",
    "Alpine Museum city",
    "Home of the Universal Postal Union",
    "Swiss city",
    "Switzerland's second most populous canton",
    "Ursula Andress's birthplace",
    "Swiss capital"
  ],
  "nubs": [
    "Small lumps",
    "Gists",
    "Gists",
    "Worn-down pencils, say",
    "Lumps",
    "Central parts",
    "Central points",
    "Hearts",
    "Gists",
    "Gists",
    "Gists"
  ],
  "bear": [
    "One of a fairy tale threesome",
    "Callisto's animal form, in Greek mythology",
    "Put up with",
    "Uninvited \"guest\" at a campsite",
    "Direction word",
    "Put up with",
    "Difficult thing to do, informally",
    "Camp invader",
    "Handle",
    "Tolerate",
    "*Tough test, slangily",
    "Stand",
    "Black ___",
    "Pessimist",
    "Symbol on California's flag",
    "What a koala really isn't",
    "Animal that can stand upright",
    "Arduous challenge",
    "Wall Street pessimist",
    "Certain Wall Street type",
    "See 6-Across",
    "Put up with",
    "Winnie-the-Pooh, for one",
    "Really tough task",
    "With 27-Across, it collapsed in 2008",
    "Put up with",
    "Scavenger at Yellowstone",
    "Yogi, for one",
    "Market pessimist",
    "Campsite visitor",
    "Polar denizen",
    "Terribly tough task",
    "1980 Olympics mascot",
    "Carry",
    "Pessimist",
    "Den denizen",
    "Tough test, slangily",
    "Symbol on California's flag",
    "Grizzly",
    "Yogi or Smokey",
    "Pooh, for one",
    "With 79-Across, babes in the woods?",
    "Grizzly",
    "Stand",
    "Tough test, informally",
    "Kodiak, e.g.",
    "Den denizen",
    "Tolerate",
    "Symbol on California's flag",
    "See 5-Across",
    "One of a storied threesome",
    "Largest land carnivore",
    "Walton opera, with \"The\"",
    "Chicago footballer",
    "Baylor mascot",
    "Coach Bryant",
    "Yellowstone sighting"
  ],
  "cbgb": [
    "Classic N.Y.C. punk rock venue",
    "Bygone N.Y.C. punk club",
    "Legendary Manhattan music club",
    "Legendary N.Y.C. punk rock club",
    "Classic N.Y.C. venue for punk rock",
    "Former N.Y.C. club",
    "Bygone N.Y.C. punk club",
    "Old N.Y.C. club said to be the birthplace of punk"
  ],
  "xena": [
    "\"Hercules\" character who got her own show",
    "Lawless figure with legendary fighting skills",
    "TV warrior princess",
    "TV princess",
    "Leather-clad TV warrior",
    "Lucy Lawless title role",
    "Princess with superpowers",
    "\"Hercules\" spinoff",
    "TV warrior princess",
    "Lucy Lawless role",
    "\"Hercules\" spinoff, informally",
    "TV amazon",
    "TV's Warrior Princess",
    "TV's \"warrior princess\"",
    "The Warrior Princess",
    "Warrior princess of TV",
    "Lucy Lawless title role",
    "Princess known as the Defender of the Elijans",
    "Woman warrior",
    "TV's warrior princess",
    "TV heroine in a leather skirt",
    "Leather-wearing TV princess",
    "TV warrior for good",
    "Title TV character in a brown, skirted, leather outfit",
    "TV title character who said \"I'm not an Amazon\"",
    "TV warrior princess",
    "Lawless role",
    "TV's warrior princess",
    "TV opponent of Ares",
    "TV's warrior princess",
    "Lawless vehicle",
    "TV heroine who wielded a chakram as a weapon",
    "Lawless role",
    "TV princess",
    "Lucy Lawless TV role",
    "Lawless role",
    "TV's Warrior Princess",
    "Her sidekick was Gabrielle",
    "Gabrielle's sidekick, in a TV series",
    "\"Hercules\" spinoff",
    "TV's warrior princess",
    "Dwarf planet in the outer reaches of the solar system",
    "Lawless princess",
    "TV series featuring the war god Ares",
    "Sword-wielding heroine",
    "\"Hercules\" spinoff",
    "TV princess",
    "Lawless role",
    "Warrior princess of TV",
    "Lucy Lawless role",
    "Lucy Lawless title role",
    "Lucy Lawless role",
    "TV warrior",
    "Fictional princess",
    "TV's warrior princess",
    "Princess on the small screen",
    "Gabrielle's companion on TV",
    "TV's leather-clad princess",
    "TV sword wielder",
    "Spinoff of TV's \"Hercules\"",
    "TV's warrior princess",
    "Warrior princess of TV",
    "Lawless warrior",
    "\"Hercules\" TV spinoff",
    "Lawless character",
    "TV's Warrior Princess",
    "TV's Warrior Princess",
    "\"Warrior Princess\" of TV",
    "Lucy Lawless TV role"
  ],
  "tent": [
    "Outdoor wedding rental",
    "Rental for an outdoor reception",
    "Shelter by a campfire",
    "Mobile home?",
    "Circus enclosure",
    "Camping need",
    "Fair sight",
    "Circus site",
    "Scout's shelter",
    "Bedouin shelter",
    "Part of a hobo city",
    "Carnival cover",
    "Camp shelter",
    "Something that has low stakes?",
    "Circus structure",
    "Protection for an outdoor wedding",
    "Something a camper pitches",
    "Collapsible shelter",
    "Backpack item",
    "Festival setup",
    "Lodging for a night out?",
    "Fair sight",
    "Fair sight",
    "A big flap may be made about this",
    "Oxygen ___",
    "Big top, e.g.",
    "Camper's shelter",
    "Camper's carry-along",
    "Circus shelter",
    "Rental for an outdoor reception",
    "Setting for many a reception",
    "Safari setup",
    "Sherpa shelter",
    "It's pitched",
    "Cot spot",
    "Item packed by a mountain climber",
    "Roomy dress",
    "Revival setting",
    "Camp shelter",
    "Fair shelter",
    "Outdoor wedding rental",
    "Cot locale",
    "Backpack item, maybe",
    "Something to pitch",
    "Campsite sight",
    "Exhibition area",
    "Mountain climber's equipment",
    "You might have a stake in it",
    "Camper?Æs cover",
    "Wedding reception rental",
    "Dress without a waistline",
    "One up all night at camp?",
    "It's pitched",
    "Circus sight",
    "Nomadic home",
    "Campsite sight",
    "Fair sight",
    "Carnival sight",
    "Carnival sight",
    "Fair spot",
    "Circus site",
    "Pitched item",
    "Canvas",
    "Thing to have a stake in",
    "It may be pitched",
    "See 53-Across",
    "Netting site",
    "Jamboree shelter",
    "Campsite sight",
    "Camping gear",
    "Fair sight",
    "Mobile home?",
    "Safari equipment",
    "Carnival sight",
    "Oxygen ___",
    "Red Cross shelter",
    "It may be pitched",
    "Place for a revival",
    "Jamboree shelter",
    "Smaller cousin of 67-Across",
    "Reception site, maybe",
    "Oktoberfest sight",
    "Camp shelter",
    "Intensive care room sight",
    "Camp sight",
    "Oxygen ___",
    "Bivouac shelter",
    "Camping equipment",
    "Scout shelter",
    "Caterpillar construction",
    "Part of a backpacker's pack",
    "Camp shelter",
    "Caterpillar construction",
    "Home on the range"
  ],
  "seis": [
    "Solid green ball in un juego de billar",
    "Half of doce",
    "Tres y tres",
    "Dos y cuatro",
    "Six, in Seville",
    "Numero of Mexican states that border the U.S.",
    "Spanish 3 + 3",
    "Ocho - dos",
    "Six, in Seville",
    "Dos x tres",
    "Cinco follower",
    "Dos x tres",
    "Dos + dos + dos",
    "Number of strings on a Spanish guitar",
    "Spanish cardinal",
    "Cinco follower",
    "Cinco follower",
    "Uno y dos y tres",
    "Half of doce",
    "Number of wives of Enrique VIII",
    "Half of doce",
    "Tres y tres",
    "Number after cinco",
    "Evening hour, in Madrid",
    "Cinco follower",
    "Spanish half-dozen",
    "Twice tres",
    "Six, in Seville",
    "DOS + cuatro"
  ],
  "rims": [
    "Parts of volcanoes",
    "Lips",
    "Custom auto accessories",
    "Edges",
    "Custom auto accessories",
    "What some eyeglasses lack",
    "Edges, as of craters",
    "Glassless glasses",
    "Purchases at tire shops",
    "What eyeglass lenses fit in",
    "Hoopsters' hoops",
    "Optician's display",
    "Canyon vantage points",
    "They surround lenses",
    "Goes around",
    "Lens holders",
    "Outer limits",
    "Lens holders",
    "Eyeglass holders",
    "Canyon locales",
    "Basketball hoops",
    "Basketball hoops",
    "Brinks",
    "They're missed by airballs",
    "Tire holders",
    "Optician's display",
    "Just misses, as a putt",
    "Lens holders",
    "Lens holders",
    "Edges",
    "Just misses, as a hole",
    "Spectacle parts",
    "Eyeglass frames",
    "Custom auto accessories",
    "Holders of glasses",
    "Hoopsters' targets",
    "Edges",
    "What swish shots miss",
    "Nonglass parts of glasses",
    "Parts of hoops",
    "Parts of eyeglasses",
    "Net supports",
    "Lens holders",
    "Tire parts",
    "Opposite of hubs"
  ],
  "gigi": [
    "Horror director ___ Saul Guerrero",
    "Supermodel Hadid",
    "Best Picture of 1958",
    "Best Picture Oscar winner before \"Ben-Hur\"",
    "Best Picture of 1958",
    "Movie with the song \"I Remember It Well\"",
    "Caron title role of 1958",
    "Maurice Chevalier musical",
    "Lerner/Loewe musical set in Paris",
    "Broadway title role for Audrey Hepburn",
    "1958 title role for 21-Across",
    "1958 Best Song Oscar winner",
    "1945 Colette novel",
    "1958 Vincente Minnelli film",
    "Colette heroine",
    "Best Picture of 1958",
    "1958 Oscar winner for Best Song",
    "Musical with the song \"The Night They Invented Champagne\"",
    "Best Picture of 1958",
    "Leslie Caron role",
    "Musical with the song \"The Night They Invented Champagne\"",
    "\"Thank Heaven for Little Girls\" musical",
    "Best Picture of 1958",
    "Best Picture of 1958",
    "\"Thank Heaven for Little Girls\" musical",
    "1958 musical",
    "Lerner and Loewe musical",
    "Best Picture of 1958",
    "1958 movie musical",
    "Best Picture of 1958",
    "\"Thank Heaven for Little Girls\" musical",
    "Oscar-winning musical",
    "Best Picture of '58",
    "Oscar-winning song of 1958"
  ],
  "tilt": [
    "Not be on the level",
    "___ at windmills (fight imaginary foes)",
    "Inclination",
    "Pinball fail",
    "Bias",
    "Downfall in pinball",
    "List",
    "Bias",
    "Pinball game ender",
    "Feature of the earth's axis that causes the seasons",
    "Pinball foul",
    "Slit",
    "___ at windmills",
    "Steering wheel option",
    "Use a lance",
    "Illegal motion penalty?",
    "Pinball no-no",
    "Pinball infraction",
    "Camera movement",
    "Bias",
    "Result of pushing too hard?",
    "Knight's contest",
    "List",
    "Sports contest",
    "Pinball game ender",
    "Result of pushing too hard?",
    "See 56-Across",
    "Cause of rage against the machine?",
    "Bias",
    "Bias",
    "Arcade flub",
    "Game stopper",
    "Pinball foul",
    "Steering wheel option",
    "Feature of the earth",
    "List",
    "Pinball game ender",
    "Start to fall",
    "The earth is on one",
    "Pinball stopper",
    "Pinball error",
    "Lean slightly",
    "Abrupt ending of a sort",
    "Feature of the Earth",
    "Knight fight",
    "Sudden game ending",
    "Fight like a knight",
    "Pinball message",
    "Full ___",
    "Do a knight's work",
    "Pinball problem",
    "Work on one's knight moves?",
    "Pinball foul",
    "Conclusion of some games",
    "Bias",
    "Contest in \"Ivanhoe\"",
    "Knight fight",
    "It freezes your flippers",
    "Pinball no-no",
    "Show bias",
    "Car's steering option",
    "End of a certain game",
    "Lean",
    "Pinball no-no",
    "Pinball no-no",
    "Type of steering",
    "Joust",
    "Pinball goof",
    "Show bias"
  ],
  "paba": [
    "Onetime sunscreen ingredient",
    "Onetime sunblock agent",
    "UV blocker",
    "Letters on beach lotion",
    "Sunblock ingredient",
    "Sunscreen additive",
    "Sunscreen label abbr.",
    "Sunscreen compound",
    "Sunscreen ingredient",
    "Sunscreen additive",
    "Sunscreen ingredient",
    "Coppertone ingredient",
    "Ultraviolet light protector",
    "Sunscreen ingredient",
    "Sunscreen ingredient"
  ],
  "glom": [
    "Latch (onto)",
    "Grab (onto)",
    "Stick (onto)",
    "Grab (onto)",
    "Grab (onto)",
    "Latch (onto)",
    "Latch (onto)",
    "Grab (onto)",
    "Latch (onto)",
    "Latch (onto)",
    "Grab, with \"onto\"",
    "Grab, with \"onto\"",
    "___ onto",
    "Snatch, slangily",
    "Latch (onto)",
    "Grab, slangily",
    "___ onto (grab)",
    "Latch (onto)",
    "Grab (onto)",
    "Latch (onto)",
    "Latch (onto)",
    "Grab (onto)",
    "Latch (onto)",
    "Grab (onto)",
    "Grab, slangily",
    "Grab (onto)",
    "Latch (onto)",
    "___ onto (seize)",
    "Grab (onto)"
  ],
  "tuns": [
    "Vessels for vintners",
    "Wine casks",
    "945-liter units",
    "252-gallon measures",
    "Port vessels",
    "Ale holders",
    "Port holders",
    "Wine casks"
  ],
  "liza": [
    "Actress/YouTube star Koshy",
    "Entertainer Minnelli",
    "Gershwin title girl",
    "\"Frank, ___ & Sammy: The Ultimate Event\" (1989 documentary)",
    "Maugham's title girl of Lambeth",
    "Multitalented Minnelli",
    "Multitalented Minnelli",
    "Co-star of Joel in \"Cabaret\"",
    "\"___ of Lambeth\" (Maugham novel)",
    "One of Judy Garland's girls",
    "Dudley's \"Arthur\" co-star",
    "Minnelli of \"Arthur\"",
    "Minnelli of Broadway",
    "Multitalented Minnelli",
    "Oscar winner Minnelli",
    "\"Cabaret\" name",
    "Four-time-wed Minnelli",
    "Minnelli of \"Cabaret\"",
    "Maugham's \"___ of Lambeth\"",
    "Tony winner Minnelli",
    "\"___ of Lambeth\" (Maugham's first novel)",
    "Minnelli of stage and screen",
    "Minnelli with two Tonys",
    "\"___ With a Z\" (1972 Emmy-winning show)",
    "\"Show Girl\" tune",
    "One of Judy Garland's girls",
    "Somerset Maugham's \"___ of Lambeth\"",
    "Singer Minnelli",
    "\"Cabaret\" name",
    "\"___, With a Z\"",
    "Judy's girl",
    "1929 Gershwin song",
    "Maugham's \"___ of Lambeth\"",
    "Singer Minnelli",
    "TV's \"___ with a 'Z'\""
  ],
  "glue": [
    "Bond",
    "Arts-and-crafts supply",
    "Need for a model",
    "Sticker",
    "Strong bond",
    "Sticky stuff",
    "Sticker component",
    "People bond with it",
    "Word with stick or gun",
    "Archivist's supply",
    "Collagist's supply",
    "Bond film?",
    "Modelist's need",
    "Arts and crafts purchase",
    "Bond former",
    "Stick with it",
    "Something to bond with",
    "Affix",
    "Bond",
    "Stickum",
    "Stickum",
    "Sticker",
    "Bond",
    "Mucilage",
    "Rubber cement, e.g.",
    "Bond",
    "Envelope sealer",
    "Stickum",
    "Stick together",
    "Bond, but not James Bond",
    "Collagist's need",
    "Collagist's need",
    "Stick together",
    "Sticky stuff",
    "Borden product",
    "Model binder",
    "Stick",
    "Bond"
  ],
  "pegg": ["Simon of the \"Mission: Impossible\" movies"],
  "gros": [
    "Opposite of petit",
    "Large: Fr.",
    "French painter of Napoleonic scenes",
    "___ point (embroidery stitch)",
    "Canada's ___ Morne National Park",
    "___ point (certain stitch)",
    "___ point (certain stitch)",
    "___ point (embroidery stitch)",
    "___ point (embroidery stitch)",
    "Large, to Lafarge",
    "\"The Battle of Eylau\" painter",
    "\"Napoleon at Eylau\" painter"
  ],
  "coho": [
    "Small Pacific salmon",
    "Sockeye relative",
    "Salmon variety",
    "Fish also known as a blue jack",
    "Pacific salmon",
    "Salmon variety",
    "Alaskan salmon",
    "Silver salmon",
    "Great Lakes salmon",
    "Small salmon",
    "Small salmon",
    "Small salmon",
    "Fish also called \"blue jack\"",
    "Pacific salmon"
  ],
  "iamb": [
    "Foot with a short part and a long part",
    "Poetic foot with a short and a long syllable",
    "One of two in \"The Grapes of Wrath\"",
    "Metric unit",
    "Part of da-DUM, da-DUM, da-DUM",
    "Two-syllable poetic foot",
    "One of 70 in a Shakespearean sonnet",
    "Foot type",
    "Trochee's counterpart",
    "da-DAH",
    "Poetic measure",
    "\"Hurray\" or \"alas\"",
    "Foot used to keep rhythm?",
    "One of four in \"As I Was Going to St. Ives\"",
    "Poetic foot",
    "One foot in a line",
    "Foot of verse",
    "Vermont but not New Hampshire, e.g.?",
    "One-quarter of \"Whose woods these are I think I know\"",
    "Poetic foot",
    "Poet's foot",
    "Metric foot",
    "Foot type",
    "Poetic foot",
    "Foot",
    "One foot",
    "Metric unit",
    "Shakespeare's foot?",
    "Foot",
    "Metrical foot",
    "Prosodic foot",
    "Part of a meter",
    "Anapest's relative",
    "A foot in a line",
    "Prosodic foot",
    "Poetic foot",
    "Sonnet measure",
    "Two-syllable foot",
    "Part of a meter, maybe",
    "Certain foot",
    "da-DAH",
    "Poetic foot",
    "Metric foot",
    "da-DUM",
    "Sonnet part"
  ],
  "keen": [
    "Razor-sharp",
    "Sharp",
    "Sharp",
    "Enthusiastic",
    "Avid",
    "Eager",
    "Sharp-witted",
    "Sharp as a tack",
    "Intent",
    "Sharp",
    "Perspicacious",
    "Eager",
    "Sharp",
    "Sharp",
    "Enthusiastic",
    "Gung-ho",
    "Eager",
    "Sharp",
    "What was once cool?",
    "Astute",
    "Sharp",
    "Enthusiastic",
    "Sound like a banshee",
    "Gung-ho",
    "Gung-ho",
    "Itching (to)",
    "Sharp",
    "Razor-sharp",
    "Sharp as a tack",
    "Like an eagle's vision",
    "Nifty",
    "Like an eagle's vision",
    "Eager",
    "Sharply focused",
    "Penetrating",
    "Sharp",
    "Biting",
    "Sharp",
    "Neato",
    "Sharp",
    "Very sensitive",
    "Wail like a banshee",
    "Razor-sharp",
    "Neato",
    "Sharp as a tack",
    "Lament loudly",
    "Eager",
    "Insightful",
    "Hot to trot",
    "Sharp",
    "Honed",
    "Fine-edged",
    "Sharp",
    "Peachy-___",
    "Sharp",
    "Poignant"
  ],
  "find": [
    "Discovery",
    "Edit menu option",
    "See 49-Down",
    "Chance upon",
    "See 16-Across",
    "Ferret out",
    "Discovery",
    "Discovery",
    "Discover",
    "Lucky strike"
  ],
  "duns": [
    "Demands for payment",
    "Demands payment from",
    "Demands to be paid",
    "Demands for payment",
    "Solicits for payment"
  ],
  "swat": [
    "Give a \"Whap!\"",
    "Big bat",
    "De-bug?",
    "Clock",
    "Whack",
    "Big smack",
    "Hit hard",
    "Pop a fly?",
    "Pop flies?",
    "Thwack",
    "Letters on some bulletproof vests",
    "Big hit",
    "___ team (police unit)",
    "Whack",
    "Whack with a newspaper, perhaps",
    "Attempt to debug?",
    "Try to beat the buzzer?",
    "Hit hard",
    "Whack",
    "Sultan of ___ (nickname for 15-Across)",
    "Big hit",
    "Hit with a rolled-up newspaper, maybe",
    "Performer of high-risk operations",
    "Kind of team",
    "Police jacket letters",
    "Hit, as a fly",
    "Whack, as a fly",
    "Sacrifice fly?",
    "Smack hard",
    "Clock",
    "Sultan of ___ (Babe Ruth)",
    "Hit hard",
    "Team letters",
    "End of a fly? ... or the start of one?",
    "Go for, as a fly",
    "Hit hard",
    "Go after, as flies",
    "Hit hard",
    "Swing that rips the leather off the ball",
    "Big baseball hit",
    "___ team (police group)",
    "Try to hit, as a housefly",
    "Big blow",
    "Belt",
    "Hostage crisis group",
    "___ team",
    "Whack",
    "Swing at a fly",
    "Acronym on a police jacket",
    "Sultan of ___ (Babe Ruth)",
    "Hostage situation acronym",
    "Shot block, in hoops-speak",
    "Babe Ruth's \"sultanate\"",
    "Kind of team",
    "Police jacket letters",
    "Smack",
    "Smack",
    "Hit, as a homer",
    "Kind of team",
    "Cuff",
    "Thwack",
    "Acronym on some jackets",
    "Try for a fly",
    "Quite a hit",
    "Double or triple, possibly",
    "Hit a homer, e.g.",
    "Hit, as a fly",
    "Major hit",
    "Mighty hit",
    "Police ___ team",
    "Kind of team",
    "The Sultan of ___",
    "Take a swipe at",
    "Kind of team"
  ],
  "hubs": [
    "Nexuses",
    "O'Hare and LAX",
    "Connection points",
    "Where many connections are made",
    "Nerve centers",
    "Centers of activity",
    "Central points",
    "O'Hare and Dallas/Fort Worth",
    "LAX, O'Hare and others",
    "Centers",
    "Car parts that have caps",
    "Centers of wheels",
    "Parts of cars with caps",
    "Centers",
    "Major airports",
    "Meccas",
    "Cores",
    "Primary intersections",
    "Centers",
    "Centers of activity"
  ],
  "lops": [
    "Cuts with shears, maybe",
    "Chops (off)",
    "Removes as superfluous",
    "Cuts (off)",
    "Truncates",
    "Takes the top (off)",
    "Cuts off, as branches",
    "Prunes",
    "Eliminates as unnecessary",
    "Trims a tree",
    "Works on a hedgerow",
    "Prunes",
    "Takes a bough?",
    "Cuts, as branches",
    "Cuts with garden shears",
    "Chops (off)",
    "Cuts off",
    "Trims, as a tree",
    "More than snips",
    "Trims the tree"
  ],
  "syne": [
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "Champagne-fueled song finale",
    "\"Auld Lang ___\"",
    "Robert Burns's \"since\"",
    "\"Auld Lang ___\"",
    "Holiday song closer",
    "End of a song at a New Year's Eve party",
    "Ago, in an annual song",
    "\"Auld Lang ___\"",
    "End of a Burns poem heard annually",
    "Last word of an annual holiday song",
    "January 1 song title word",
    "End of a song often sung by inebriated people",
    "Burnsian \"ago\"",
    "1/1 title word",
    "\"Auld Lang ___\"",
    "Final word in a holiday tune",
    "Last word of a party song",
    "\"Auld Lang ___\"",
    "New Year's Eve word",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "Last word of a January 1 song",
    "Lang follower",
    "January 1 song word",
    "Part of a New Year's Eve party song",
    "\"Auld Lang ___\"",
    "End of a Scottish title sung at many parties",
    "\"Auld Lang ___\"",
    "End of a New Year?Æs classic",
    "January 1 song word",
    "New Year's Eve word",
    "Holiday song word",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "New Year's word",
    "Annual song ender",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "Scot's \"since\"",
    "Since then, in song",
    "End of a New Year's Eve song",
    "\"Auld Lang ___\"",
    "Song ender",
    "\"...auld lang ___\"",
    "\"Auld Lang ___\"",
    "January song ender",
    "Ago, in Aberdeen",
    "\"Auld Lang ___\"",
    "New Year's Eve song word",
    "\"Auld Lang ___\"",
    "End of a party song",
    "\"Auld Lang ___\"",
    "End of a Burns title",
    "Congenial song ending",
    "Auld lang ___",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "\"Auld Lang___\"",
    "New Year's song ending",
    "\"Auld Lang___\"",
    "\"Auld Lang___\"",
    "\"Auld Lang ___\"",
    "Since, New Year's Eve-style",
    "Auld lang ___",
    "Auld lang ___",
    "Since, in Scotland",
    "\"Auld Lang ___\"",
    "End of a 1/1 song",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "\"Auld Lang ___\"",
    "Nostalgic song ending",
    "January 1 word",
    "January 1 song ending",
    "New Year's word",
    "\"Auld Lang ___\""
  ],
  "laws": [
    "Ten Commandments, e.g.",
    "Legislatures write them",
    "House work?",
    "Clean Air Act and others",
    "What some bills become",
    "They're on the books",
    "Statutes",
    "There are three for motion",
    "Corpus juris contents",
    "Code contents",
    "Deuteronomy contents",
    "Acts",
    "House work?",
    "Statutes",
    "Statutes",
    "Parts of codes",
    "Doctor of ___ (degree)",
    "Statutes",
    "Delivery of Moses",
    "Code collection",
    "Subjects of Congressional debate",
    "Statutes",
    "Samuel ___, inventor of the stock ticker",
    "Breakable things",
    "See 33-Down",
    "Statutes",
    "Parts of a code",
    "Parts of a code",
    "Statutes",
    "Statutes",
    "Statutes",
    "Statutes",
    "Some are blue",
    "Items in a code",
    "Senate output"
  ],
  "neap": [
    "Kind of tide",
    "Half-moon tide",
    "___ tide",
    "Kind of tide",
    "___ tide",
    "Spring's opposite",
    "Tide type",
    "Kind of tide",
    "Kind of tide",
    "Kind of tide",
    "___ tide",
    "___ tide",
    "Like a half-moon tide",
    "Spring's cyclic counterpart",
    "___ tide (semimonthly event)",
    "Spring's counterpart, tidewise",
    "Spring's opposite",
    "Kind of tide",
    "Tide type",
    "___ tide",
    "Spring's counterpart",
    "___ tide",
    "___ tide",
    "Spring's opposite",
    "Certain tide",
    "___ tide",
    "Tidal term",
    "___ tide",
    "___ tide",
    "___ tide",
    "Kind of tide",
    "Spring's counterpart",
    "Kind of tide",
    "Opposite of spring",
    "Semimonthly tide",
    "Term on a tide table",
    "Tide type",
    "Opposite of spring, tidewise",
    "Certain tide",
    "Twice-a-month tide",
    "Tide type",
    "Kind of tide",
    "___ tide",
    "Kind of tide",
    "Spring's opposite",
    "Twice-a-month tide",
    "___ tide",
    "Occurrence in the moon's first quarter",
    "___ tide",
    "Occurrence after the first and third quarters of the moon",
    "Spring's opposite",
    "Tide type",
    "Minimal tide",
    "Tide type",
    "Kind of tide",
    "Certain tide",
    "Tide type",
    "Semimonthly tide",
    "Occurrence at the moon's first quarter",
    "Kind of tide",
    "Tide type",
    "Certain tide",
    "Spring's opposite",
    "Kind of tide",
    "___ tide",
    "Spring's opposite, oceanwise",
    "Tide type",
    "Kind of tide",
    "Recurring marine event",
    "Tide variety",
    "___ tide",
    "Semimonthly tide",
    "Tide type",
    "Kind of tide",
    "Kind of tide",
    "Tide type",
    "Kind of tide",
    "Half-moon tide",
    "Kind of tide",
    "Minimal high tide",
    "Tide type",
    "Type of tide",
    "Kind of tide",
    "Certain tide",
    "Tide type",
    "Minimum-range tide",
    "Certain tide",
    "One of the tides",
    "Tide type",
    "Alternative to spring",
    "Kind of tide",
    "___ tide",
    "Tide type",
    "___ tide",
    "Kind of tide",
    "Kind of tide",
    "___ tide",
    "Tide type"
  ],
  "ieds": ["Modern war hazards, for short", "Roadside bombs, for short"],
  "herr": [
    "German \"mister\"",
    "Man in Mannheim",
    "Mannheim mister",
    "Hamburger, maybe",
    "Man in Mannheim",
    "Mozart title",
    "Sir in the Ruhr",
    "Foreign term of address",
    "Mister abroad",
    "Munich mister",
    "Mann's man",
    "Title for Goethe",
    "Mister, overseas",
    "Frau's mate",
    "Mr. Hamburger?",
    "German \"Mr.\"",
    "Many a Berliner",
    "Mister : English :: ___ : German",
    "Frau's spouse",
    "Mein ___",
    "Mann's title",
    "Munster Mr.",
    "Frau's partner",
    "Mister, in Munster",
    "Frankfurt term of address",
    "Austrian title",
    "Frau's mate",
    "Frau's partner",
    "Frau's mate",
    "Munchen mister",
    "Ein Berliner, often",
    "Foreign address",
    "Mann of the Haus",
    "Munich mister",
    "Mr., abroad",
    "Frau's partner",
    "Frau's partner",
    "Title of respect abroad",
    "Munster Mister",
    "Frankfurter, maybe",
    "Mainz mister",
    "Munchen \"sir\"",
    "Munchen mister",
    "German \"Mr.\"",
    "Frau's mate",
    "Cologne mister",
    "Mr., in Munich",
    "Mister, abroad",
    "Mannheim mister",
    "Munich Mister",
    "What a Hamburger may be called",
    "Stuttgart title",
    "Mannheim Mr.",
    "Frau's counterpart",
    "Mister, in Bonn",
    "German mister",
    "Mainz Mr.",
    "Mister, in Munster",
    "Munchen mister",
    "German title",
    "Munich Mr.",
    "Mainz Mister",
    "Munster Mister",
    "Title for Mozart",
    "Munchen Mr.",
    "Mister, in Munich",
    "Munich Mister",
    "Frau's partner",
    "German mister",
    "Mister abroad",
    "Goethe title",
    "Mister, in Bonn",
    "Mister, in Munich",
    "Mister, in Munich",
    "Sir overseas"
  ],
  "xmen": [
    "Cyclops and Wolverine, for two",
    "Comics debut of 1963",
    "Cyclops and Beast, for two",
    "Some Marvel superheroes",
    "Superhero group including Beast and Cyclops",
    "Comics foes of Magneto",
    "Mutants of Marvel Comics",
    "Comic book mutants",
    "Marvel characters",
    "Superheroes of comics",
    "2000 Hugh Jackman movie",
    "Comic book heroes originally called the Merry Mutants",
    "Superheroes battling the evil Magneto",
    "Magneto's adversaries, in comic books",
    "Marvel Comics mutants",
    "Marvel Comics series",
    "Marvel Comics comic",
    "Comic book debut of 1963",
    "Comic book mutants",
    "Marvel Comics group",
    "Comic book supergroup",
    "Comic superheroes",
    "Marvel Comics heroes",
    "2000 sci-fi film",
    "Marvel superheroes",
    "Marvel superheroes",
    "Marvel Comics group",
    "Marvel Comics heroes",
    "Marvel superheroes",
    "Marvel superheroes",
    "Marvel superheroes",
    "Comic book heroes",
    "Mutant cartoon superheroes"
  ],
  "tivo": [
    "DVR system",
    "DVR brand",
    "Record for later, in a way",
    "Way to watch shows beginning in 1999",
    "Device for recording shows",
    "Save for later viewing, in a way",
    "DVR pioneer",
    "Big name in DVRs",
    "Program saver",
    "It lets you see what you missed",
    "DVR choice",
    "Prepare for a later showing, maybe",
    "Record for later viewing, maybe",
    "Recorder for couch potatoes",
    "Not watch live, say",
    "One way to record a show",
    "DVR brand",
    "Record for later viewing, in a way",
    "DVR system",
    "Show stopper?",
    "Record for later, in a way",
    "What may give pause to couch potatoes?",
    "Device with a hard disk",
    "Season Pass offerer",
    "Record for later viewing, in a way",
    "Program coordinator?",
    "Home recorder",
    "Save, in a way, as some shows",
    "Box in many dens",
    "Save for later viewing",
    "What may ensure the show goes on?",
    "It can stop the show",
    "Busy viewer's convenience",
    "Record for later viewing",
    "Box in many homes",
    "Recording device",
    "One way to record a program",
    "Modern recording option",
    "Modern subscription service",
    "Time-shifting device",
    "VCR alternative",
    "Popular video recorder",
    "Modern recorder",
    "TV show digitizer"
  ],
  "acab": [
    "Hail ___ (cry \"Taxi!\")",
    "\"Call me ___!\" \"O.K., you're ...!\"",
    "Hail ___",
    "Hail ___",
    "Hail ___ (cry \"Taxi!\")",
    "Hail ___",
    "Hail ___",
    "Hail ___"
  ],
  "doge": [
    "Onetime Venetian V.I.P.",
    "Resident of the Palazzo Ducale",
    "Old Italian V.I.P.",
    "Old Venetian V.I.P.",
    "Ruler with a palace near St. Mark's",
    "Old Italian magistrate",
    "___ of Venice",
    "Venetian V.I.P. of yore",
    "Bygone magistrate",
    "Venetian magistrate of old",
    "Old Venetian official",
    "\"___ Andrea Gritti\" (Titian portrait)",
    "Venetian bigwig",
    "Former Venetian magistrate",
    "Former Venetian V.I.P.",
    "Genoan official, once",
    "Former Italian leader",
    "Genoan V.I.P., once",
    "Venetian official, once",
    "Big cheese of Venice",
    "Venetian V.I.P.",
    "Venetian magistrate",
    "Venetian honcho",
    "Venetian magistrate"
  ],
  "gere": [
    "Richard of \"Chicago\"",
    "\"An Officer and a Gentleman\" star, 1982",
    "\"Pretty Woman\" co-star",
    "\"Arbitrage\" star, 2012",
    "Actor Richard",
    "\"Dr. T and the Women\" star, 2000",
    "\"Pretty Woman\" co-star",
    "Golden Globe-winning actor for \"Chicago\"",
    "Richard of \"Unfaithful\"",
    "\"Primal Fear\" star, 1996",
    "Richard of \"Shall We Dance?\"",
    "Richard of \"American Gigolo\"",
    "\"Days of Heaven\" co-star",
    "\"Chicago\" Golden Globe winner",
    "Richard of \"Pretty Woman\"",
    "\"The Hoax\" star, 2006",
    "\"Days of Heaven\" co-star, 1978",
    "Richard of \"American Gigolo\"",
    "\"Unfaithful\" co-star, 2002",
    "Actor Richard",
    "Richard of \"Bee Season\"",
    "First man featured on the cover of the U.S. edition of Vogue",
    "\"Sommersby\" star, 1993",
    "Roberts's \"Pretty Woman\" co-star",
    "Richard of \"Chicago\"",
    "\"Nights in Rodanthe\" star, 2008",
    "\"Runaway Bride\" co-star, 1999",
    "Richard of \"Bee Season\"",
    "\"Shall We Dance?\" star, 2004",
    "People's 1999 Sexiest Man Alive",
    "\"Primal Fear\" star, 1996",
    "\"Bee Season\" star, 2005",
    "\"The Cotton Club\" star",
    "\"Unfaithful\" co-star, 2002",
    "People's 1999 Sexiest Man Alive",
    "\"Pretty Woman\" actor",
    "Actor Richard of \"Chicago\"",
    "\"Chicago\" star, 2002",
    "Richard of \"Chicago\"",
    "Richard of \"Chicago\"",
    "\"King David\" star, 1985",
    "Richard of \"Chicago\"",
    "Richard of \"Primal Fear\"",
    "Lancelot player, 1995",
    "\"Pretty Woman\" star Richard",
    "\"Chicago\" star Richard",
    "\"American Gigolo\" star",
    "\"Chicago\" co-star, 2002",
    "\"Chicago\" star, 2002",
    "\"American Gigolo\" star",
    "Richard who played 35-Down",
    "Olin's \"Mr. Jones\" co-star, 1993",
    "\"Unfaithful\" star",
    "\"Mr. Jones\" star",
    "\"Power\" star, 1986",
    "\"An Officer and a Gentleman\" star",
    "\"Intersection\" star, 1994",
    "Actor Richard",
    "\"The Cotton Club\" co-star",
    "Richard of \"First Knight\"",
    "Richard of \"Runaway Bride\"",
    "\"Primal Fear\" star",
    "King David player, 1985",
    "?ôPretty Woman?ö co-star",
    "\"Autumn in New York\" co-star",
    "Richard of \"Pretty Woman\"",
    "\"Red Corner\" star",
    "Richard of \"Pretty Woman\"",
    "Richard of \"Runaway Bride\"",
    "Actor Richard",
    "Richard of \"Final Analysis\"",
    "\"Sommersby\" star",
    "Richard of \"Primal Fear\"",
    "\"Power\" star, 1986",
    "\"Primal Fear\" star",
    "\"Internal Affairs\" star, 1990",
    "\"King David\" star, 1985",
    "\"American Gigolo\" actor",
    "\"First Knight\" star",
    "Actor Richard",
    "Winger co-star, 1982",
    "\"Primal Fear\" star",
    "\"Sommersby\" star, 1993",
    "\"Power\" star, 1986",
    "\"Yanks\" star",
    "\"American Gigolo\" actor",
    "\"Pretty Woman\" star",
    "Richard of \"Pretty Woman\"",
    "\"Sommersby\" star"
  ],
  "lags": [
    "Problems with streaming",
    "Falls behind",
    "Delays",
    "Falls behind",
    "Isn't up to date",
    "Streaming hiccups",
    "Doesn't keep up",
    "Internet annoyances",
    "Live-streaming problems",
    "Tarries",
    "Doesn't keep up",
    "Streaming problems",
    "Hangs back",
    "Problems in synchronization",
    "Is seconds behind",
    "Doesn't keep up",
    "Fails to keep pace",
    "Trails",
    "Trails",
    "Falls behind",
    "Trails",
    "Dawdles",
    "Falls behind",
    "Isn't caught up",
    "Falls behind",
    "Slowdowns",
    "Brings up the rear",
    "Doesn't keep up",
    "Jet effects",
    "Brings up the rear",
    "Stays behind"
  ],
  "zoom": [
    "Modern meeting method",
    "It brings you closer to your subjects",
    "Go a mile a minute",
    "Speed along",
    "Go fast",
    "Quickly pan (in)",
    "With 9-Across, an auto ad slogan",
    "With 5-Across, quickly",
    "Camera feature",
    "Skyrocket",
    "Zip",
    "Streak",
    "Choice for a long shot",
    "Whiz (by)",
    "Camera shop item, informally",
    "Whoosh!",
    "Take off like a rocket",
    "When doubled, a Mazda catchphrase",
    "Camera feature",
    "Camera feature",
    "Camera feature",
    "Whiz",
    "Camera attachment, informally",
    "Modern camera feature",
    "Lens type",
    "Really move",
    "37-Down product",
    "Kind of lens",
    "Type of lens"
  ],
  "meme": [
    "Evil Kermit or Grumpy Cat",
    "Consuming Tide Pods, once, inexplicably",
    "Grumpy Cat or Doge, e.g.",
    "Low-effort internet joke",
    "Something frequently reposted",
    "Social media phenomenon",
    "Joke that goes viral on the internet",
    "Rickrolling or the Dancing Baby, e.g.",
    "One gets shared a lot",
    "Something that goes viral",
    "Internet sensation",
    "2018's debate over \"Yanny or Laurel,\" e.g.",
    "Idea that spreads popularly and widely",
    "Much-reshared post on social media",
    "Bit of viral web content",
    "Ice Bucket Challenge, e.g.",
    "Passing web fancy",
    "Many a Facebook repost",
    "Repeatedly reposted item",
    "Something trending online",
    "Lolcats, e.g.",
    "Twitter trending topic, maybe",
    "Piano-playing cat, once",
    "Viral phenomenon",
    "Idea that may spread via the Internet",
    "Viral phenomenon on the Web",
    "Internet ___",
    "Internet ___ (viral phenomenon)",
    "Same: Fr.",
    "Unit of cultural information",
    "Repetitive cry while waving a hand",
    "Hand-waver's cry",
    "Attention-getting cry",
    "Same: Fr.",
    "Repeated cry while waving a hand",
    "Same: Fr.",
    "Same, by the Seine"
  ],
  "majs": [
    "Officers above capts.",
    "Some mil. brass",
    "They report to lt. cols."
  ],
  "mode": [
    "Airplane ___",
    "A la ___",
    "One of math's three M's",
    "Figure in statistics",
    "Pie a la ___",
    "6, in the set [3,5,5,6,6,6,7]",
    "Manner of doing",
    "How it's done",
    "Pie a la ___",
    "Number in statistics",
    "How it's done",
    "Pie a la ___",
    "Manner",
    "Fashion",
    "Way",
    "Fashion",
    "Statistics calculation",
    "Fashion",
    "Calculator button",
    "Fashion",
    "Way",
    "A la ___ (with ice cream)",
    "Fashion",
    "Statistics figure",
    "Means",
    "Fashion",
    "Statistics calculation",
    "Math calculation",
    "Fashion",
    "Technique",
    "Statistical calculation",
    "Way it's done",
    "Method",
    "Pop music's Depeche___",
    "Means",
    "Methodology",
    "Fashion",
    "Method"
  ],
  "cujo": [
    "1981 Stephen King thriller",
    "Stephen King title about a dog",
    "Title canine in a Stephen King book",
    "1981 thriller whose title character is a St. Bernard",
    "Rabid dog in a Stephen King story",
    "1981 best seller set in Castle Rock, Me.",
    "1981 Stephen King novel",
    "Stephen King canine",
    "Stephen King canine",
    "Stephen King novel",
    "Stephen King thriller",
    "King work"
  ],
  "haps": [
    "Unfortunate events, old-style",
    "Comes to pass, old-style",
    "Unlucky accidents, old-style",
    "Occurrences",
    "Accidents",
    "Accidents",
    "Chance occurrences",
    "Comes to pass",
    "Occurs, poetically"
  ],
  "dork": [
    "Dweeb",
    "Square figure?",
    "Square one",
    "Nerd",
    "Almost any character on \"The Big Bang Theory\"",
    "Nerdy sort",
    "Unhip sort",
    "Uncool sort",
    "Social misfit",
    "Jerk",
    "Mr. Uncool",
    "Hardly Mr. Cool"
  ],
  "lean": [
    "Have a preference",
    "Tilt",
    "Rely (on)",
    "Rawboned",
    "Low in fat",
    "Low-fat",
    "Not fatty",
    "Show a bias",
    "Slant",
    "Bend to one side",
    "Not stand completely straight",
    "Like venison",
    "Lacking excess",
    "Not fat",
    "Ectomorphic",
    "Slim and trim",
    "Nonfatty",
    "Show bias",
    "Not fatty",
    "Like ostrich meat",
    "Like Jack Sprat's diet",
    "Not marbled, say",
    "Trim",
    "Spare",
    "Request to a butcher",
    "___ Cuisine",
    "Meager",
    "Like the food Jack Sprat eats",
    "Put pressure (on)",
    "Meat request",
    "Skin-and-bones",
    "Tilt",
    "Meager",
    "Show bias",
    "Low in fat",
    "List",
    "Get ready to fall, maybe",
    "Show a preference",
    "Be biased",
    "Cant",
    "Low in fat",
    "Scraggy",
    "Show preference",
    "Skin-and-bones",
    "Low-fat, as beef",
    "Ectomorphic",
    "Option at a butcher's, maybe",
    "What Mrs. Sprat couldn't eat",
    "Low in fat",
    "Tilt",
    "Trim",
    "Like a champion bodybuilder",
    "Dietary no-no for Mrs. Sprat",
    "Be biased",
    "Spare",
    "Not yielding much",
    "Not very profitable",
    "Depend (on)",
    "Low-fat",
    "Unlike a typical hot dog",
    "Like some meat",
    "Be precarious, maybe",
    "Like Jack Sprat's diet",
    "Tip",
    "No-no for Mrs. Sprat",
    "Like good hamburger meat",
    "Spare",
    "Like some meat",
    "Gravitate (toward)",
    "Spare",
    "Desirable to dieters",
    "Exert pressure (on)",
    "Like higher-priced beef",
    "Not having much fat",
    "Like pricier meat",
    "Like Jack Sprat's diet",
    "Low-fat",
    "Emulate Pisa's tower",
    "Mrs. Sprat's no-no",
    "Like Jack Sprat's diet",
    "Tilt",
    "Efficient",
    "Having little fat",
    "Supermarket meat label"
  ],
  "isso": [
    "Kindergarten comeback",
    "Recess retort",
    "Childish retort",
    "Playground rejoinder",
    "\"That ___ wrong\"",
    "\"And what ___ rare as a day in June?\": Lowell",
    "\"That ___ last year\"",
    "\"That ___ true\"",
    "Childish comeback",
    "\"That ___ stupid!\"",
    "Childish comeback",
    "\"That ___ you!\"",
    "Childish rejoinder",
    "Combative retort",
    "\"True!\"",
    "\"This ___ sudden!\"",
    "\"And what ___ rare as a day in June?\"",
    "Playground comeback",
    "\"That ___ unfair!\"",
    "\"That ___ you!\"",
    "\"That ___ you!\"",
    "\"And what ___ rare as...\""
  ],
  "nths": [
    "Indefinite degrees",
    "Some degrees",
    "High degrees",
    "Unspecified degrees",
    "Unspecified degrees",
    "Unspecified degrees",
    "Unspecified degrees"
  ],
  "pied": [
    "Patchy in color",
    "Mottled",
    "Having colors in blotches",
    "Varicolored",
    "Like slapstick comics, often",
    "Blotchy, in a way",
    "Like a calico",
    "___ Piper",
    "Splotchy",
    "Skewbald",
    "Multicolored",
    "Mottled",
    "Motley-colored",
    "Parti-colored",
    "Motley",
    "___ Piper",
    "Variegated",
    "Parti-colored",
    "Multicolored",
    "Having colored patches",
    "Motley",
    "Multicolored",
    "___ Piper",
    "Varicolored",
    "Jumbled",
    "Not solid-colored",
    "Skewbald",
    "Pinto",
    "Variegated",
    "Motley",
    "Not solid",
    "Patchy",
    "Like Hamelin's piper",
    "Like a piper of yore",
    "Patchy",
    "___ Piper",
    "___ Piper",
    "Multicolored",
    "Dappled",
    "Multicolored"
  ],
  "lade": [
    "Fill with freight",
    "Stow cargo",
    "Fill the hold, say",
    "Stow, as cargo",
    "Fill with cargo",
    "Put on",
    "Put on board",
    "Haul aboard",
    "Stow on a ship",
    "Put on",
    "Bring aboard",
    "Put weight on",
    "Put on board",
    "Put on board",
    "Pack cargo",
    "Get on board",
    "Stow, as cargo",
    "Put on cargo",
    "Ready for shipping",
    "Put on",
    "Stow, as cargo ... or an anagram of the last word of 17-, 35- or 52-Across",
    "Stow, as cargo",
    "Put great weight on",
    "Stow",
    "Put on",
    "Put on board",
    "Weigh down",
    "Stow cargo",
    "Put on board",
    "Put on board, as cargo",
    "Put on cargo",
    "Put on board",
    "Put on, as cargo",
    "Put on board, as cargo",
    "Put a burden on",
    "Fill with freight",
    "Take on",
    "Put on board",
    "Put on",
    "Fill with cargo",
    "Put on board, as cargo",
    "Stevedore",
    "Stevedore",
    "Stow, as cargo",
    "Fill the hold",
    "Fill a hold",
    "Load cargo",
    "Put on board"
  ],
  "gian": [
    "Italian sculptor ___ Lorenzo Bernini",
    "Composer ___ Carlo Menotti",
    "Italian sculptor ___ Lorenzo Bernini",
    "___ Lorenzo Bernini, designer of St. Peter's Square",
    "Composer ___ Carlo Menotti",
    "Composer ___ Carlo Menotti",
    "Composer ___ Carlo Menotti",
    "Composer ___ Carlo Menotti",
    "Sculptor ___ Lorenzo Bernini",
    "Composer ___ Carlo Menotti",
    "___ Carlo Menotti",
    "Italian artist Bernini",
    "Composer ___ Carlo Menotti",
    "Composer ___ Carlo Menotti",
    "Sculptor ___ Lorenzo Bernini",
    "Composer ___ Carlo Menotti",
    "Composer___ Carlo Menotti"
  ],
  "apts": [
    "Some Craigslist listings: Abbr.",
    "Rental units: Abbr.",
    "Complex units: Abbr.",
    "Some Craigslist listings: Abbr.",
    "Stacked quarters?: Abbr.",
    "Co-ops, maybe: Abbr.",
    "List in a 36-Down: Abbr.",
    "Bldg. units",
    "Flats: Abbr.",
    "Many urban homes: Abbr.",
    "Watergate units: Abbr.",
    "Rented living qtrs.",
    "Supers oversee them: Abbr.",
    "Many want-ad offerings: Abbr.",
    "Many urban homes: Abbr.",
    "City dwellings: Abbr.",
    "Living units: Abbr.",
    "Many N.Y.C. residences",
    "Rentals: Abbr.",
    "What supers supervise: Abbr.",
    "Rental units: Abbr.",
    "Studios, maybe: Abbr.",
    "Complex grp.",
    "Rental units: Abbr.",
    "Real estate sect. listings",
    "Living units: Abbr.",
    "Classified listings: Abbr.",
    "Many N.Y.C. dwellings",
    "Places for rent: Abbr.",
    "Want ad listings: Abbr."
  ],
  "posy": [
    "Bunch of flowers",
    "One in a pocketful",
    "What a bridesmaid might carry",
    "Fragrant prom present",
    "Small bouquet",
    "Bouquet",
    "Nosegay",
    "Floral arrangement",
    "Floral arrangement",
    "Nosegay",
    "Bouquet",
    "Nosegay",
    "Nosegay",
    "Nosegay",
    "Bouquet"
  ],
  "hulk": [
    "\"The Avengers\" role",
    "Any Mr. Olympia",
    "Dismantled ship",
    "See 44-Across",
    "Burned-out ship, e.g.",
    "\"Incredible\" one",
    "Leviathan",
    "Pumped-up body",
    "Shell",
    "\"The Incredible ___\""
  ],
  "bald": [
    "\"I knew I was going ___ when it was taking longer and longer to wash my face\": Harry Hill",
    "Lacking any tread",
    "Like a person who might be called \"chrome dome\"",
    "Hairless",
    "Unvarnished",
    "Like Sir Ben Kingsley",
    "Topless?",
    "___-faced lie",
    "*Like a treadless tire",
    "Completely bare",
    "Like some eagles and tires",
    "Having nothing to part with?",
    "Treadless",
    "Unlocked?",
    "Having no need for a comb",
    "Completely smooth",
    "Unable to part?",
    "Like Yul Brynner, famously",
    "Like badly worn tires",
    "Topless?",
    "Unlocked?",
    "Worn smooth",
    "Dis-tressed",
    "Shiny on top?",
    "Lacking cover?",
    "Like Yul Brynner or Telly Savalas",
    "Become unlocked?",
    "Unadorned",
    "Like some eagles",
    "Having no overhead?",
    "Like a candidate for hair transplant",
    "Shiny on top?",
    "Uncovered?",
    "Hairless",
    "Like a worn tire",
    "Like Ionesco's soprano",
    "Coming out on top?",
    "Unadorned",
    "Treadless",
    "Like some eagles or tires",
    "Eaglelike, perhaps",
    "Like Ike"
  ],
  "moan": [
    "Sad sound",
    "Cry of lament",
    "Haunted house sound",
    "Say \"Not this again!,\" say",
    "Sound from a ghost",
    "Kvetch",
    "Indication of pain or pleasure",
    "Haunted house sound",
    "Kvetch",
    "Haunted house sound",
    "Bellyache",
    "\"Not this again!,\" e.g.",
    "Low lament",
    "Ghostly sound",
    "Spooky sound",
    "Zombie's sound",
    "Haunted house sound",
    "Haunted house sound",
    "Bellyache",
    "\"Broccoli again?,\" e.g.",
    "Wind sound",
    "Complain",
    "Bellyache",
    "Grouse",
    "Complain",
    "Sound stressed, maybe",
    "Evidence of paranormal activity, perhaps",
    "Complain",
    "Haunted house sound",
    "Sound from a dungeon",
    "Seance sound",
    "Complain",
    "Reaction to bad news",
    "Gripe",
    "Bellyache",
    "Complain",
    "Openly grieve",
    "Kick",
    "Kick, in a way",
    "Express regret",
    "Haunted house sound",
    "Haunted house sound",
    "Complain",
    "Complain",
    "Reaction to bad news",
    "Lamentation",
    "\"Oh, my aching head!,\" e.g.",
    "\"Oh, my aching head!,\" e.g.",
    "Gripe",
    "Express regret",
    "Complaint",
    "Grumble",
    "Loud complaint",
    "Lament",
    "Bewail",
    "Punster's reward"
  ],
  "mugs": [
    "Makes a duck face, say",
    "38-Across containers",
    "Pub containers",
    "Makes faces",
    "Doesn't look normal",
    "Kissers",
    "Acts clownishly",
    "Hams it up for the camera",
    "Coffeehouse crockery",
    "Hot chocolate containers",
    "Tankard's kin"
  ],
  "null": [
    "Zero",
    "Empty",
    "Of no value",
    "No longer in force",
    "{ }, in mathematics",
    "Having no legal force",
    "Invalid",
    "Empty",
    "Void",
    "Empty, as a math set",
    "Lacking value",
    "Empty, in math",
    "Zero",
    "___ and void",
    "Invalid",
    "Zero",
    "No longer legal",
    "Lacking any elements",
    "Without value",
    "Nonexistent",
    "Legally invalid",
    "Zero",
    "Aleph-___",
    "Valueless",
    "Partner of void",
    "Zero",
    "Empty, in math",
    "Not valid",
    "Nonexistent",
    "Nonexistent",
    "Invalid",
    "Void's partner",
    "Void's partner",
    "Zero"
  ],
  "pine": [
    "What benchwarmers ride, with \"the\"",
    "Long",
    "Needle maker",
    "Tree on Maine's flag",
    "Air freshener scent",
    "Languish",
    "Derby car material",
    "Hanker (for)",
    "Material for a mountain cabin",
    "Long",
    "Fragrant wood",
    "Wood for many a mountain cabin",
    "Forest scent",
    "Long",
    "Ache",
    "Long",
    "___ Tree State (Maine)",
    "Material for many a ski lodge",
    "Common cleaning scent",
    "Woodsy odor",
    "Air freshener scent",
    "Itch (for)",
    "Cleaner fragrance",
    "Cabinetry option",
    "Long",
    "Yearn (for)",
    "Woodsy scent",
    "Tree with needles",
    "Flooring option",
    "Paneling material",
    "\"Knotty\" wood",
    "\"Lonesome\" tree",
    "Tar source",
    "___ tar (baseball team supply)",
    "Cone holder",
    "Yearn (for)",
    "Languish",
    "Ride the ___ (sit out a baseball game)",
    "Resin source",
    "Knotty wood",
    "Long",
    "Bench material",
    "Long",
    "Languish",
    "Tar source",
    "Languish",
    "Fresh scent",
    "Ponderosa ___",
    "Long",
    "Ache (for)",
    "Suffer, in a way",
    "Floor cleaner scent",
    "Resin source",
    "Yearn",
    "Tree with cones",
    "Long",
    "Log cabin material, maybe",
    "Durable wood",
    "Needle dropper",
    "Emulate Echo",
    "Scotch ___",
    "Construction wood",
    "Air freshener option",
    "Georgia ___",
    "Long",
    "Loblolly, e.g.",
    "Yearn",
    "Air freshener scent",
    "Cabin wood",
    "Air freshener scent",
    "Yearn",
    "Lonesome one",
    "Furniture wood",
    "Fir",
    "Yearn"
  ],
  "wino": [
    "Overindulger of the grape",
    "Certain drunkard",
    "One who tipples too much",
    "Tippler's favorite radio station?",
    "Lush",
    "Bowery boozer",
    "Lush's favorite radio station?",
    "Overindulging sort",
    "Lush",
    "Lush",
    "Drunk's favorite radio station?",
    "Port authority?",
    "Grape nut?",
    "Lush",
    "Rotgut buyer, perhaps",
    "One type of 66-Down",
    "Major downer?",
    "Excessive lover of the grape",
    "Thunderbird enthusiast?",
    "\"Brown bagger\"",
    "One who indulges too much in the grape",
    "Overindulger of the grape",
    "Lush",
    "Big tippler",
    "Lush",
    "Bowery bum",
    "Brown bagger",
    "Lush",
    "Thunderbird enthusiast?",
    "Brown bagger",
    "Port authority?",
    "Skid row sort",
    "Lush",
    "Dipsomaniac",
    "Lush, perhaps",
    "Bowery denizen",
    "Skid row radio station?",
    "Skid row type",
    "Souse"
  ],
  "anag": [
    "Stands up for dustpans, say: Abbr.",
    "Max Peel, for example: Abbr.",
    "Mediums for dummies, say: Abbr.",
    "\"I hate to be ___ ...\"",
    "Puzzling jumble: Abbr.",
    "Word jumble: Abbr."
  ],
  "wall": [
    "Part of a squash court",
    "Where stucco is stuck",
    "Great ___ of China",
    "What may follow fire or stone",
    "Word after Great or before Street",
    "Modern posting locale",
    "Hanging setting",
    "Mural site",
    "Bulwark",
    "Mural's locale",
    "Handball playing surface",
    "Rampart",
    "Mural site",
    "Stopping point",
    "It's common in row houses",
    "Washington's Vietnam Memorial, e.g.",
    "Spot for a hanging",
    "Parapet",
    "Berlin ruin",
    "The Great ___",
    "Bulkhead",
    "To Frost it's unloved"
  ],
  "hews": [
    "Chops down",
    "Hacks with an axe",
    "Chops down",
    "Chops",
    "Takes an ax to",
    "Chops",
    "Hacks",
    "Cuts with an ax",
    "Fells",
    "Chops",
    "Chops",
    "Cuts down",
    "Cuts",
    "Cuts",
    "Cuts",
    "Fells",
    "Chops"
  ],
  "lron": [
    "Scientology founder ___ Hubbard",
    "___ Hubbard, Scientology founder",
    "___ Hubbard of Scientology",
    "Scientology founder Hubbard",
    "Science fiction author Hubbard",
    "Author Hubbard",
    "Sci-fi's Hubbard",
    "___ Hubbard, Scientology founder",
    "Writer ___ Hubbard",
    "\"Dianetics\" author ___ Hubbard",
    "Author ___ Hubbard",
    "\"Dianetics\" author ___ Hubbard",
    "Scientologist ___ Hubbard",
    "___ Hubbard",
    "Scientologist ___ Hubbard",
    "\"Dianetics\" author ___ Hubbard",
    "Scientology's ___ Hubbard",
    "Hubbard of science fiction",
    "Scientologist Hubbard",
    "Scientology founder ___ Hubbard",
    "Scientologist ___ Hubbard",
    "___ Hubbard",
    "Hubbard of Scientology",
    "___ Hubbard",
    "___ Hubbard",
    "___ Hubbard",
    "Hubbard of fiction",
    "___ Hubbard",
    "Scientology founder ___ Hubbard",
    "\"Dianetics\" author ___ Hubbard",
    "Scientologist ___ Hubbard",
    "Scientologist ___ Hubbard",
    "Scientology guru ___ Hubbard",
    "Author ___ Hubbard",
    "\"Dianetics\" author ___ Hubbard",
    "Writer Hubbard",
    "Author Hubbard"
  ],
  "myst": [
    "Classic computer game set on an island",
    "Classic computer game set on an abandoned island",
    "Game predecessor of Riven",
    "Pioneering 1990s computer game",
    "Best-selling PC game before The Sims",
    "Hit 1990s computer game",
    "Popular game with the sequel Riven",
    "Pioneering first-person computer game",
    "Classic computer game set on an island",
    "Best-selling PC game of the 1990s",
    "Classic computer game set on an island",
    "Classic computer game, or its island world",
    "Video game island",
    "Best-selling computer game of the 1990s",
    "Computer game set on an island",
    "Classic computer game set on a seemingly deserted island",
    "Faddish 1990's computer game",
    "Popular game set on an island",
    "Pioneering computer game",
    "Computer adventure game",
    "Classic computer game",
    "Classic computer game",
    "Popular computer game",
    "Popular computer game",
    "Best-selling computer game",
    "Best-selling CD-ROM game"
  ],
  "buss": [
    "Kiss",
    "Bit of osculation",
    "Kiss",
    "Smooch",
    "Smooch",
    "Smooch",
    "Kiss",
    "Smack",
    "Smooch",
    "Kiss",
    "Kiss",
    "Smack",
    "Hearty kiss",
    "Kiss",
    "Kiss",
    "Smack"
  ],
  "orem": [
    "U.S. city just south of Timpanogos Cave National Monument",
    "Beehive State city",
    "Western city known as \"Family City U.S.A.\"",
    "City on Utah Lake",
    "City near Provo",
    "Mini-metropolis in Utah",
    "Beehive State city",
    "City near Provo Peak",
    "Self-described \"Family City U.S.A.\"",
    "City west of Provo Peak",
    "Utah Valley University city",
    "City on Utah Lake",
    "U.S. city that's an anagram of a world capital",
    "City near a 29-Across reservation",
    "Utah city",
    "Home of Utah Valley University",
    "City on Utah Lake",
    "City near Provo",
    "Utah Valley University city",
    "City SSE of 117-Across",
    "City near Provo",
    "City on Utah Lake",
    "Utah city",
    "City near Provo",
    "Utah city",
    "So-called Family City U.S.A.",
    "Utah city",
    "City near Provo",
    "Utah city",
    "Utah city",
    "City near Provo",
    "City in Utah",
    "Utah city",
    "Utah city",
    "City south of West Jordan on I-15",
    "Utah's \"Family City U.S.A.\"",
    "City near Provo",
    "City near Provo",
    "Home of Utah Valley State College",
    "City near Provo",
    "Self-styled \"Family City U.S.A.\"",
    "City on Utah Lake",
    "Utah city",
    "City NNW of Provo",
    "Utah city",
    "City next to the Uinta National Forest",
    "City east of Utah Lake",
    "City once named Provo Bench",
    "Utah city",
    "City near Provo",
    "Provo neighbor",
    "Its nickname is \"Family City, U.S.A.\"",
    "City south of Salt Lake City",
    "Utah city",
    "City near Provo",
    "Utah city",
    "Utah city",
    "City near Utah Lake",
    "Utah city",
    "Home to some 14-Acrosses",
    "Utah city",
    "Utah city",
    "City that's home to Novell, Inc.",
    "City near Provo",
    "Utah Lake is to its west",
    "Utah city",
    "City just east of Utah Lake",
    "Utah city",
    "City west of the Provo River",
    "City near Provo",
    "Utah city",
    "Utah Lake city",
    "Utah city",
    "City near Provo",
    "Utah city",
    "Provo neighbor",
    "City near Provo",
    "Provo neighbor",
    "City in Utah County",
    "Utah city",
    "City near Utah Lake",
    "Utah Lake city",
    "City near Provo",
    "City near Utah Lake",
    "City south of Salt Lake City"
  ],
  "flak": [
    "Strong criticism",
    "Pushback",
    "Criticism",
    "Irritating criticism",
    "Criticism, informally",
    "Bomber pilot's worry",
    "Complaints",
    "___ jacket (protective wear)",
    "Kind of jacket",
    "Criticism, informally",
    "Criticism",
    "Antiaircraft artillery",
    "Criticism",
    "Verbal brickbats",
    "Slams",
    "Kind of jacket",
    "Harsh criticism",
    "A lot of beef?",
    "___ jacket",
    "Strong criticism",
    "Criticism",
    "Antiaircraft fire",
    "Criticism"
  ],
  "thaw": [
    "Spring event",
    "Warm up",
    "Become more relaxed",
    "Undergo rapprochement",
    "Detente",
    "Take out of the freezer",
    "Spring awakening sign",
    "Icebreaker?",
    "Sign of spring",
    "Soften",
    "Deice",
    "Winter respite",
    "Melt",
    "Spring event",
    "With 68-Across, release philosopher John Stuart from cryonic suspension?",
    "Instruction on a frozen turkey",
    "Detente",
    "Relief for the snowbound",
    "Unfreeze",
    "Detente",
    "Spring warming",
    "Midwinter event, often",
    "Unfreeze",
    "Antifreeze?",
    "Spring phenomenon",
    "Springtime period",
    "Melting period",
    "De-ice",
    "Melt",
    "Get out of the cold?",
    "Warming trend",
    "Midwinter phenomenon, sometimes",
    "Detente",
    "Spring break?",
    "Remove from the freezer",
    "Detente",
    "Microwave option",
    "Sign of spring",
    "Opening for peace talks",
    "Become friendlier",
    "Loosen",
    "Take out of the freezer",
    "Loosen up",
    "Unfreeze",
    "Spring event",
    "Microwave option",
    "Become soft, say",
    "Spring occurrence",
    "Become friendlier",
    "Sign of spring",
    "Winter break",
    "Detente",
    "Get friendlier",
    "End of 14-Across",
    "Become less tense",
    "Become less reserved",
    "Spring event",
    "Spring thing?",
    "Soften up",
    "Rapprochement",
    "Warm up",
    "Spring happening",
    "Take out of the freezer",
    "Easing of tensions",
    "Relax",
    "Easing of tension",
    "Melt",
    "January \"warming\"",
    "Warm up",
    "Go soft"
  ],
  "rome": [
    "Sacking site in A.D. 410",
    "Spanish Steps city",
    "The Catholic Church, informally",
    "Foreign city that surrounds a country",
    "\"Coriolanus\" setting",
    "Long building project, in a cliche",
    "Capital of 19-Across",
    "Home of the Circus Maximus",
    "Where \"Tosca\" is set",
    "Legend says it arose on Palatine Hill",
    "The Eternal City",
    "\"The Bicycle Thief\" setting",
    "Setting for Broadway's \"The Light in the Piazza\"",
    "Part of a so-called \"grand tour\"",
    "Where all roads lead, it's said",
    "So-called \"Caput Mundi\" (\"Head of the World\")",
    "Olympics host after Melbourne",
    "Home to the Colosseum",
    "1960 Olympics host",
    "\"When in ___ ...\"",
    "\"La Dolce Vita\" setting",
    "City founded by a twin, in myth",
    "Setting for Hawthorne's \"The Marble Faun\"",
    "\"The Bicycle Thief\" setting",
    "Setting for \"Gladiator\"",
    "\"La Dolce Vita\" setting",
    "All roads lead to it, in a saying",
    "Terminus for all roads, in a saying",
    "\"Quo Vadis\" setting",
    "Vatican locale",
    "1960 Olympics host",
    "Setting for \"Coriolanus\"",
    "\"I, Claudius\" setting",
    "Home of the Arch of Constantine",
    "\"Gladiator\" setting",
    "City containing a country",
    "All roads lead to this, they say",
    "Where 51-Down was martyred",
    "Capitoline Museums locale",
    "See 62-Across",
    "See 40-Across",
    "The Vatican's home",
    "\"Coriolanus\" setting",
    "Center of a former empire",
    "Vatican's home",
    "Trevi Fountain locale",
    "The Eternal City",
    "Spanish Steps city",
    "\"When in ___ ...\"",
    "Santa Maria Maggiore locale",
    "Seven Hills city",
    "\"Gladiator\" setting",
    "Forum city",
    "\"Coriolanus\" setting",
    "Center of Catholicism",
    "The Eternal City",
    "City on the Tiber",
    "1960 Olympics site",
    "City on the Tiber",
    "\"La Dolce Vita\" setting",
    "1960 Olympics site",
    "Vatican's locale",
    "Tarpeian Rock's location",
    "Palatine Hill site",
    "\"Don Pasquale\" setting",
    "\"Coriolanus\" setting",
    "\"Coriolanus\" setting",
    "City on seven hills",
    "\"Spartacus\" setting",
    "Setting for \"Don Pasquale\"",
    "___ Beauty (apple variety)",
    "Site of St. Peter's",
    "Where the Vatican is",
    "Where the Vatican is",
    "Capitoline Museum site",
    "Road nexus, proverbially",
    "Forum site",
    "Appian Way terminus",
    "Vatican City site",
    "Seven Hills site",
    "Site of the 1960 Olympics",
    "Palatine Hill site",
    "Colosseum setting",
    "City inside the Servian Wall",
    "Multiday building project?",
    "Late, great composer",
    "Broadway's Harold"
  ],
  "lili": [
    "Actress Taylor of \"Mystic Pizza\"",
    "Actress Taylor",
    "1953 Leslie Caron title role",
    "Taylor of \"Public Enemies\"",
    "Actress Taylor of \"Mystic Pizza\"",
    "1953 Leslie Caron film",
    "1953 Leslie Caron musical",
    "Actress Taylor of \"Mystic Pizza\"",
    "Actress Taylor of \"Six Feet Under\"",
    "Title French orphan of film",
    "Taylor of \"Say Anything ...\"",
    "\"I Shot Andy Warhol\" actress Taylor",
    "Taylor of \"The Haunting\"",
    "Actress Taylor",
    "\"I Shot Andy Warhol\" star Taylor",
    "\"___ Marlene\" (W.W. II song)",
    "\"___ Marlene\" (W.W. II love song)",
    "French orphan of film",
    "Leslie Caron title role",
    "1953 Leslie Caron film",
    "Taylor of \"Mystic Pizza\"",
    "Leslie Caron musical",
    "\"___ Marlene,\" song of 1944",
    "Leslie Caron title role",
    "1953 Leslie Caron film",
    "Leslie Caron musical",
    "1953 Leslie Caron title role",
    "\"Hi-___, Hi-Lo\" (1953 hit)",
    "Title role for Leslie Caron",
    "\"___ Marlene\" (classic song)",
    "Nude dancer St. Cyr",
    "Actress Taylor of \"The Haunting\"",
    "Popular musical based on a Paul Gallico story",
    "1953 Leslie Caron film",
    "1953 Leslie Caron musical role",
    "Actress Taylor",
    "\"___ Marlene\" (1944 song)",
    "French orphan of film",
    "1950's pinup queen ___ St. Cyr",
    "Caron role",
    "Actress Taylor",
    "Caron role",
    "\"Hi-___, Hi-Lo\" (1953 film song)",
    "Leslie Caron role",
    "1953 Leslie Caron role",
    "1953 Mel Ferrer film",
    "Carnival girl, in the movies",
    "\"Darling ___\" (Hudson flick)",
    "\"Carnival!\" character",
    "Caron role",
    "1953 Leslie Caron film",
    "Leslie Caron role",
    "Film on which \"Carnival\" was based",
    "Movie base for Broadway's \"Carnival!\"",
    "1953 Leslie Caron film",
    "Caron film, 1953",
    "1953 Leslie Caron role",
    "Leslie Caron role"
  ],
  "hypo": [
    "Shot from a doc",
    "Doc's needle",
    "Prefix with allergenic",
    "Syringe, for short",
    "Shot from a doc",
    "Something an aichmophobe fears, briefly",
    "Shot deliverer",
    "Hospital sticker",
    "Doc's needle",
    "Syringe",
    "Syringe, for short",
    "It has a sticking point",
    "Needle, informally",
    "Dose deliverer",
    "Injection",
    "Prefix with glycemic",
    "Syringe",
    "Doc's needle",
    "Needle, for short",
    "Shot in the arm, maybe",
    "Shot in the arm",
    "Shot in the arm",
    "Shot maker",
    "Booster shot, e.g.",
    "Shot shooter",
    "Shot",
    "Short shot?"
  ],
  "ebay": [
    "Shopping site with a \"Toys\" section",
    "Site of some sniping",
    "Parent of Shopping.com",
    "Website where you can shop by category",
    "Where Warren Buffett sells an annual lunch date for charity",
    "Company whose Nasdaq symbol is its name",
    "Where the meaning of life was sold in 2000 for $3.26",
    "Website with a \"Buy It Now\" option",
    "Owner of StubHub",
    "Modern alternative to a garage sale",
    "Rare success story from the dot-com bubble",
    "Site with a \"Shop by category\" button",
    "It has lots on the internet",
    "Auction service since 1995",
    "Website with a \"Sell an item\" option",
    "Parent company of StubHub",
    "\"Buy It Now\" site",
    "Onetime owner of Skype",
    "It uses clicks in lieu of paddles",
    "Website with the heading \"Recently Viewed Items\"",
    "Company that bought (and later sold) Skype",
    "Fortune 500 company founded in 1995",
    "Website with a Watch list",
    "Site with the option \"Shop by category\"",
    "\"The meaning of life\" once sold on it for $3.26",
    "StubHub's parent",
    "Subject of the 2002 book \"The Perfect Store\"",
    "\"Buy it. Sell it. Love it\" company",
    "Site with Daily Deals",
    "Owner of StubHub",
    "Owner of Shopping.com",
    "\"Buy it new. Buy it now\" sloganeer",
    "Website with a \"Buy It Now\" button",
    "Site with a \"Time left\" display",
    "Company with a 1998 Nasdaq I.P.O. that hired its first employee in 1996",
    "Online auction site",
    "Many a collector's resource",
    "Tech company in the Fortune 500",
    "uBid competitor",
    "Owner of Half.com",
    "Shopping venue with the options \"Books\" and \"Toys & Hobbies\"",
    "Site with a \"Buy It Now\" option",
    "Site that began as AuctionWeb",
    "Subject of the 2002 book \"The Perfect Store\"",
    "Fortune 500 company founded in 1995",
    "Self-described \"World's Online Marketplace\"",
    "It may do your bidding",
    "Owner of Bill Me Later",
    "PayPal purchaser",
    "Company name whose second letter is capitalized",
    "Cyberspace marketplace",
    "Company whose Nasdaq symbol is the company's name",
    "Web site that includes the heading \"Dolls & Bears\"",
    "Parent company of Shopping.com",
    "Where Al Yankovic bought a \"Dukes of Hazzard\" ashtray, in song",
    "Web site with a \"Buy It Now\" option",
    "Cyberauction site",
    "Web site with a \"Buy It Now\" option",
    "\"Buy It Now\" site",
    "Online auction house",
    "Web site with the headings \"Toys & Hobbies\" and \"Music\"",
    "Its first sale was a broken laser pointer",
    "Competitor of uBid",
    "Web site with PowerSellers",
    "Online auction site",
    "24/7 auction site",
    "24/7 auction site",
    "Where to find the headings Books, Dolls & Bears, and Collectibles",
    "Fortune 500 company founded in 1995",
    "Virtual mart",
    "Silent auction site",
    "*Fortune 500 company based in San Jose, Calif.",
    "Company whose name is pig Latin for an insect",
    "Big dot-com headquartered in San Jose",
    "Something to bid on",
    "It may do your bidding",
    "Online flea market",
    "Shopping.com's parent company",
    "PayPal owner",
    "Online bazaar",
    "Auction site",
    "Online auction site",
    "Where many surfers shop",
    "Bidder's site",
    "Bidding site",
    "Bidding site",
    "Online auction house",
    "Noted 1998 initial public offering",
    "You may bid on it",
    "On-line auction house",
    "Company whose name is pig Latin for an insect",
    "Collectibles source",
    "24/7 auction site",
    "Internet market",
    "Popular site for collectors",
    "Site for cyberbidders",
    "Online auction site",
    "Place to do one's bidding",
    "Web auction site",
    "Cyberauction house",
    "Auctioneerless auction site",
    "Modern marketplace",
    "Where you may do your bidding?",
    "Popular auction site",
    "Online marketplace",
    "Where bidders wait online"
  ],
  "told": [
    "Got a schoolmate in trouble, maybe",
    "Snitched",
    "Didn't keep quiet",
    "Related",
    "Squealed",
    "*\"___ you!\" (\"See?!\")",
    "Upbraided, with \"off\"",
    "Tattled",
    "Sang",
    "Related",
    "Chided, with \"off\"",
    "Related",
    "Ordered",
    "Published",
    "Let the cat out of the bag",
    "Ratted",
    "Informed",
    "Tattled",
    "Ratted (on)",
    "All ___",
    "Spilled the beans",
    "Spilled the beans",
    "\"I ___ you!\"",
    "Spoiled the surprise",
    "Snitched",
    "Snitched",
    "Ratted",
    "Related",
    "Was a blabbermouth",
    "Was a rat",
    "Related",
    "Snitched",
    "Snitched",
    "Let the cat out of the bag",
    "Tattled",
    "Related",
    "Narrated",
    "Related",
    "Let in on",
    "Ratted (on)",
    "Let the cat out of the bag",
    "Squealed",
    "Blabbed",
    "Ratted (on)"
  ],
  "zips": [
    "Flies",
    "10001, 10002, etc., informally",
    "Speeds (along)",
    "Speeds",
    "Flies (along)",
    "99+ things in Alaska?",
    "Flies",
    "Speeds",
    "Postal aids, for short",
    "Compresses, in computerese",
    "Rushes (along)",
    "Parts of addresses"
  ],
  "roll": [
    "Dinner ___",
    "Sushi order",
    "\"Heads will ___!\"",
    "\"Let's ___!\"",
    "Kaiser ___",
    "Snake eyes or boxcars, in craps",
    "Toilet paper unit",
    "With 44- and 76-Across, way to put legislators on record ... or the start, middle and end of a word ladder",
    "Bundle of coins",
    "Monopoly action",
    "Item in a restaurant basket",
    "Restaurant freebie",
    "Sushi plate item",
    "See 108-Down",
    "Sushi selection",
    "Item often served with a pat of butter",
    "One of three in a Yahtzee turn",
    "\"Let's ___!\"",
    "Cinnabon purchase",
    "Sushi bar offering",
    "See 62-Across",
    "Move on casters",
    "Order to make a scene?",
    "Go round and round",
    "Take turns?",
    "Casino action",
    "See 11-Down",
    "Eight the hard way, e.g.",
    "Undulate",
    "Yaw relative, on an aircraft",
    "Rock's partner",
    "Sushi bar offering",
    "Bun",
    "Gymnastics move",
    "Game turn, maybe",
    "Toss of the dice",
    "Somersault",
    "Take a turn, in some games",
    "Sushi selection",
    "Frank holder",
    "40 quarters, e.g.",
    "\"Let's ___\"",
    "Dinner bread",
    "Air maneuver",
    "\"Let's ___\"",
    "Pitch's partner",
    "Class list",
    "\"___ Over Beethoven\"",
    "Sushi offering",
    "Somersault",
    "Food item served in a basket",
    "Lucky streak",
    "Coin collection",
    "Wallpaper unit",
    "Craps play",
    "Rock's partner",
    "See 14-Across",
    "Resound",
    "Somersault",
    "Restaurant freebie",
    "Throw, as dice",
    "Paper towel unit",
    "Go around in circles",
    "Members' list",
    "Bagel or bialy",
    "Fighter jet maneuver",
    "Bakery offering",
    "Attendance list",
    "Photog's purchase",
    "Word after kaiser or jelly",
    "Film purchase",
    "Start for a hero",
    "P-51 maneuver",
    "Dinner freebie",
    "Trundle",
    "Kaiser or Parker House",
    "Drum sound",
    "Restaurant freebie",
    "Kaiser, e.g.",
    "Undulate"
  ],
  "snub": [
    "Rebuff",
    "Affront",
    "Fail to invite, maybe",
    "Refuse to acknowledge",
    "Overlook rudely",
    "Surprising lack of Oscar recognition",
    "Rudely ignore",
    "Cold-shoulder",
    "Crowd favorite not getting nominated for an Oscar, e.g.",
    "Social slight",
    "Not send an expected invitation, say",
    "Brush-off",
    "Overlook for an Oscar nomination, say",
    "Ignore",
    "Slight",
    "Slight",
    "Social slight",
    "Brush-off",
    "Drop from the invitation list, say",
    "Rebuff",
    "Cold-shoulder",
    "Blunt",
    "Social slight",
    "Rudely ignore",
    "Treat rudely",
    "Brush off",
    "Give the cold shoulder",
    "Slight",
    "Social rebuff",
    "Give the cold shoulder",
    "Social slight",
    "Brushoff",
    "Deliberate affront",
    "Rudely ignore",
    "Rudely overlook",
    "Social rebuff",
    "Social slight",
    "Rebuff",
    "Elitist's rejection",
    "Put down",
    "Slight",
    "High-hat",
    "Slight"
  ],
  "will": [
    "Passing concern",
    "Something that's \"free\" (although that's debatable)",
    "Shakespeare, informally",
    "Document with the line \"I hereby bequeath ...\"",
    "Document that says \"I hereby bequeath ...\"",
    "What indicates everything that's left?",
    "Plans to",
    "Source of some power?",
    "It may be good or free",
    "See 85-Down",
    "With 47-Down, Oscar nominee for \"Ali\"",
    "Not just might",
    "Heir lines?",
    "Mental power",
    "It may be free for philosophers",
    "Something read in many a murder mystery",
    "It details what's left",
    "Self-control",
    "Heir lines?",
    "Something left behind",
    "\"Where there's a ___...\"",
    "Desire"
  ],
  "oked": [
    "Let proceed",
    "Approved",
    "Gave the go-ahead",
    "Ratified, for short",
    "Approved",
    "Ratified",
    "Green-lit",
    "Gave one's blessing to",
    "Gave the go-ahead",
    "Rubber-stamped",
    "Green-lighted",
    "Let pass",
    "Cleared",
    "Approved",
    "Gave the nod",
    "Assented",
    "Approved",
    "Gave a thumbs-up",
    "Signed off on",
    "Gave the go-ahead",
    "Cleared",
    "Endorsed",
    "Sanctioned",
    "Sanctioned"
  ],
  "abcs": [
    "Preschool recital",
    "Some kindergarten lessons",
    "Intro-level info",
    "Group featured on many \"Sesame Street\" episodes",
    "They can be sung to the tune of \"Twinkle Twinkle Little Star\"",
    "First things you learn",
    "Subject of a book that goes from apple to zebra",
    "Something taught in a 101 class, so to speak",
    "Pre-K group?",
    "Fundamentals",
    "Kindergarten fundamentals",
    "Basic facts",
    "Kindergarten instruction",
    "Rudiments",
    "Start of learning",
    "Fundamentals",
    "Things learned in \"The Alphabet Song\"",
    "Some kindergarten instruction",
    "Basics",
    "Basics",
    "Kindergarten learning",
    "Things kindergartners learn",
    "Basics",
    "Some rote learning",
    "Kindergarten lesson",
    "Basics of education",
    "They may be taught with a song",
    "Early lesson",
    "Simple studies",
    "Building blocks",
    "They're elementary",
    "Early teachings",
    "Kindergarten basics",
    "Kindergarten stuff",
    "School basics",
    "Basics",
    "Brass tacks",
    "Elementary school group?",
    "Early education",
    "Nuts and bolts",
    "Basics",
    "Kindergarten stuff",
    "Rudimentary run",
    "Kindergarten stuff",
    "Z is the last of them",
    "Fundamentals",
    "Basics",
    "Rudimentary education",
    "Early education",
    "\"Sesame Street\" lessons",
    "Kindergarten learning",
    "Early instruction",
    "Building blocks",
    "Rudiments",
    "Nuts and bolts",
    "\"Sesame Street\" subject",
    "Early education",
    "Kindergarten learning",
    "Rudiments",
    "Kindergarten basics",
    "Kiddie education",
    "The basics",
    "Basics",
    "Fundamentals",
    "They're not advanced studies",
    "Kindergarten instruction",
    "Basics",
    "Basics",
    "Rudiments",
    "Early education",
    "Kid's recitation",
    "Kindergarten stuff",
    "Primer material",
    "Introductory text",
    "Fundamentals",
    "\"Sesame Street\" skills",
    "Building blocks",
    "What kindergartners learn",
    "Preschooler's instruction",
    "Elementary education",
    "Tot's recitation",
    "Kindergarten lesson",
    "Early lesson",
    "Kid stuff",
    "Kindergartners learn them",
    "Rudiments",
    "\"Sesame Street\" learning",
    "Kindergarten education",
    "What tots are taught",
    "Some remedial work",
    "How-to stuff",
    "Basics",
    "Kindergarten lesson",
    "Basics",
    "The basics",
    "Rudiments",
    "Kindergarten stuff",
    "Fundamentals",
    "Kid stuff",
    "Kindergarten instruction",
    "Introductory material",
    "Basics",
    "Kindergarten learning",
    "Fundamentals",
    "Essentials",
    "Kindergarten instruction",
    "Essentials",
    "First-grade instruction",
    "Fundamentals"
  ],
  "kept": [
    "Stayed fresh",
    "Didn't spoil, as food",
    "Held",
    "Remained fresh",
    "Held on to",
    "Saved",
    "Saved for later",
    "Possessed",
    "Didn't spoil",
    "Didn't spoil",
    "Left in",
    "Kind of woman",
    "Retained",
    "Held on to",
    "Didn't surrender",
    "Held back",
    "Retained",
    "Didn't go stale",
    "Held on to",
    "Stayed fresh",
    "Held on to",
    "Had in stock",
    "Didn't spoil",
    "Set aside",
    "Didn't surrender",
    "Kind of woman",
    "Didn't hand off",
    "Preserved",
    "Didn't part with",
    "Retained",
    "Held on to",
    "Kind of woman",
    "Retained",
    "Hoarded"
  ],
  "tyke": [
    "Squirt",
    "Little squirt",
    "Young 'un",
    "Rug rat",
    "Little 'un",
    "Nipper",
    "Rug rat",
    "Rug rat",
    "Preschooler",
    "Youngster",
    "Rug rat",
    "Nipper",
    "Little fella",
    "Youngster",
    "Little one",
    "Little fellow",
    "Big Wheel rider",
    "Little kid",
    "Kid",
    "Daycare charge",
    "Little 'un",
    "Young 'un",
    "Nipper",
    "Young 'un",
    "Young 'un",
    "Moppet",
    "Toddler",
    "Small fry",
    "Rug rat",
    "Little one",
    "Kid",
    "Bambino",
    "Young 'un",
    "Kid",
    "Kindergartener",
    "Wee one",
    "Kid",
    "Young 'un",
    "Kid",
    "Toddler"
  ],
  "alek": [
    "Supermodel Wek",
    "Supermodel Wek",
    "Supermodel Wek",
    "Supermodel Wek",
    "Model ___ Wek",
    "Supermodel Wek",
    "Film director Keshishian",
    "Supermodel Wek",
    "Fashion model Wek",
    "Fashion model Wek",
    "Director Keshishian"
  ],
  "tons": [
    "Rafts",
    "A whole bunch",
    "Weights that may be \"short\" or \"long\"",
    "A bushelful",
    "Heaps",
    "Bunches",
    "A lot",
    "Scads",
    "Plenty",
    "Plenty",
    "A whole bunch",
    "Lots",
    "Cargo measures",
    "Rafts",
    "A lot",
    "Truckloads",
    "Truckloads",
    "Oodles",
    "Bunches",
    "A lot",
    "Oceans",
    "Lots and lots",
    "Heaps",
    "Oodles and oodles",
    "More than plenty",
    "Loads and loads",
    "A gazillion",
    "A load",
    "Plenty",
    "Heaps",
    "Gobs",
    "Truck scale units",
    "Cargo weights",
    "A huge amount",
    "Weigh station units",
    "Zillions",
    "Scads",
    "Plenty",
    "A slew",
    "Oodles",
    "A good deal",
    "Oceans",
    "A bundle",
    "Plenty",
    "Shipping units",
    "Lots and lots",
    "A whole lot",
    "Quite a bit",
    "Oodles",
    "Oodles",
    "Scads"
  ],
  "fail": [
    "Hold for another year, say",
    "Not get above 60, say",
    "Come to nothing",
    "Get 29-Across",
    "Crash and burn",
    "Pass's opposite",
    "Go pfft",
    "Not make the grade?",
    "Bomb",
    "\"Words ___ me!\"",
    "Come to naught",
    "Flunk",
    "With 55-Across, 58-/46-Across movie (1964)",
    "Without ___ (religiously)",
    "Go kaput",
    "Not work out",
    "Go bankrupt",
    "Wash out",
    "Strike out",
    "Go bankrupt",
    "Go belly up",
    "Go belly-up",
    "Come up short",
    "Come to naught",
    "Go bankrupt",
    "Not work out",
    "Go kaput",
    "Fall short",
    "Flunk",
    "Bomb",
    "Go pfft!",
    "Not work",
    "Lose power",
    "Go belly up",
    "Lose vigor"
  ],
  "yser": [
    "W.W. I battle locale",
    "Belgian river to the North Sea",
    "French/Belgian river",
    "Scene of W.W. I fighting",
    "Belgian river to the North Sea",
    "River in a 1914 battle",
    "Belgian river to the North Sea",
    "Flemish river",
    "River through Flanders",
    "River in W.W. I fighting",
    "River flooded in W.W. I to thwart the Germans",
    "French/Belgian river",
    "Nieuwpoort's river",
    "W.W. I battle site",
    "River to the North Sea",
    "1914 battle site",
    "North Sea feeder",
    "W.W. I battle site",
    "Belgian river deliberately flooded during W.W. I",
    "Belgian river to the North Sea",
    "1914 Belgian battle line",
    "W.W. I's Battle of the ___",
    "River through Flanders",
    "River of W.W. I",
    "River of W.W. I",
    "French/Belgian river",
    "Battle of the ___, 1914",
    "North Sea feeder",
    "North Sea feeder",
    "River to the North Sea",
    "Belgian river to the North Sea",
    "Belgian river",
    "River intentionally flooded in W.W. I",
    "River in W.W. I fighting",
    "River of Flanders",
    "River to the North Sea",
    "River through Belgium",
    "River of Flanders",
    "River to the North Sea",
    "River of Flanders",
    "North Sea feeder",
    "River to the North Sea",
    "Northern French river",
    "River that begins in Nord",
    "River in a 1914 battle",
    "River to the North Sea",
    "River through Flanders",
    "River deliberately flooded in W.W. I",
    "North Sea tributary",
    "River of Flanders",
    "Nieuwpoort's river",
    "Scene of W.W. I fighting",
    "Strategic W.W. I river",
    "River of Flanders",
    "North Sea feeder",
    "Battle of the ___, 1914",
    "River in W.W. I fighting",
    "Belgian river to the North Sea",
    "River of Flanders",
    "Nieuwpoort's river",
    "River of Flanders",
    "Belgian river",
    "North Sea feeder",
    "It flows in Flanders",
    "North Sea feeder",
    "Belgian river",
    "River whose mouth is near Nieuwpoort",
    "1914 battle line",
    "North Sea feeder",
    "Belgian river",
    "Belgian river",
    "River of Flanders",
    "Battle of the ___, opened on 10/16/1914",
    "River of W.W. I",
    "Strategic W.W. I river",
    "River of Flanders",
    "W.W. I battle locale",
    "Scene of heavy W.W. I fighting",
    "Belgian river",
    "Scene of heavy W.W. I fighting",
    "River of Flanders",
    "Flanders river",
    "Belgian river",
    "W.W. I battle site",
    "1914 battle site",
    "Scene of 1914 fighting",
    "River in Belgium",
    "Nord department river",
    "W.W. I locale",
    "River of Belgium",
    "1914 Belgian battle line",
    "French/Belgian river",
    "Scene of heavy W.W. I fighting",
    "North Sea feeder",
    "Nieuwpoort's river",
    "North Sea feeder",
    "1914 battle line",
    "River through Belgium",
    "River of Flanders",
    "Belgian river",
    "W.W. I battle site",
    "Belgian river, a W.W. I battle line",
    "Nieuport's river",
    "River of Flanders",
    "River to the North Sea",
    "Niuewpoort's river",
    "North Sea feeder",
    "North Sea feeder",
    "River to the North Sea",
    "River of Belgium",
    "Site of a W.W. I Allied victory",
    "River of Flanders",
    "River crossing France's Nord department",
    "Scene of W.W. I fighting",
    "North Sea feeder",
    "River to the North Sea",
    "European river that connects a network of canals",
    "W.W. I battle river",
    "River to the North Sea",
    "W.W. I battle locale",
    "French-Belgian river",
    "W.W. I battle area",
    "Nieuport's river",
    "River through Flanders",
    "North Sea feeder",
    "W.W. I battle site",
    "Belgian river",
    "River of Flanders",
    "River to the North Sea",
    "River to the North Sea",
    "W.W. I battle site",
    "Belgian river",
    "River in Belgium"
  ],
  "wish": [
    "Birthday ___",
    "It's often made along with a birthday cake",
    "Something frequently made with the eyes shut",
    "Well thought?",
    "Something to make before blowing out the birthday candles",
    "Something made on one's birthday",
    "___ list",
    "Genie's offering",
    "*Birthday secret",
    "Thought before blowing out the candles",
    "Birthday creation",
    "\"Peace on earth,\" e.g.",
    "Genie's offering",
    "Fountain request?",
    "Action before blowing out the candles",
    "It may be made by a stargazer",
    "Well product?",
    "It's often made with the eyes closed",
    "Something to make on one's birthday",
    "Hankering",
    "What precedes a blowout?",
    "Kind of list",
    "Genie's grant",
    "Birthday-party tradition"
  ],
  "gogo": [
    "Big name in in-flight internet",
    "___ boots",
    "Kind of dancer",
    "Rush-rush",
    "Big name in in-flight internet",
    "Kind of boots",
    "___ girl",
    "Kind of boots popular in the '60s",
    "\"Get out of here! Fast!\"",
    "Kind of dancer at a discotheque",
    "Full of energy",
    "Energetic",
    "Kind of dancer",
    "Highly energetic",
    "Spirited",
    "Full of energy",
    "Energetic",
    "Full of energy",
    "Always-moving",
    "Energetic",
    "Kind of dancer",
    "Full of energy",
    "Kind of dancer",
    "Kind of dancer",
    "Full of energy",
    "Miracles' \"Going to a ___\""
  ],
  "flaw": [
    "Shortcoming",
    "Achilles' heel, e.g.",
    "Chip, e.g.",
    "Imperfection",
    "Diamond defect",
    "Chip, maybe",
    "Hubris, for Icarus",
    "Scratch, say",
    "Scratch in a diamond, e.g.",
    "Imperfection",
    "Logic problem",
    "Chip in a dish, e.g.",
    "It can make a 10 a 9",
    "Defect",
    "Chink in the armor, say",
    "Scratch on a diamond, e.g.",
    "Scratch on a gem, e.g.",
    "It might be only a scratch",
    "Bug, to so speak",
    "Nick or chip",
    "Defect",
    "Gem devaluer",
    "Blemish",
    "Scratch",
    "Imperfection",
    "It may be fatal",
    "Imperfection",
    "Error",
    "Imperfection",
    "Defect"
  ],
  "buff": [
    "Muscly",
    "Muscular",
    "Maven",
    "Shine up",
    "Aficionado",
    "Yellow-brown color",
    "Toned",
    "Aficionado",
    "Like a hunk",
    "Like a bodybuilder",
    "Quite the expert",
    "Bare skin"
  ],
  "vega": [
    "Summer Triangle star",
    "Brightest star in Lyra",
    "Summer Triangle star",
    "Fifth-brightest star in the night sky",
    "Star in the Summer Triangle",
    "Singer Suzanne",
    "Fifth-brightest star in the night sky",
    "Lyra's brightest star",
    "Suzanne with the 1987 hit \"Luka\"",
    "Brightest star in Lyra",
    "1970s Chevy",
    "Singer Suzanne",
    "Bright northern star",
    "Star in the Summer Triangle",
    "First star ever photographed, 1850",
    "Lyra's brightest star",
    "1970's Chevy",
    "Lyra's brightest star",
    "Star in Lyra",
    "Star in Lyra",
    "80's-90's singer Suzanne",
    "Star of Lyra",
    "Dramatist Lope de ___",
    "Lyra star",
    "\"La Dorotea\" writer"
  ],
  "deaf": [
    "What 49-Down became in later life",
    "Tone-___",
    "Like the daughter in 2018's \"A Quiet Place\"",
    "Tone-___",
    "Unmindful",
    "___ ears",
    "Refusing to listen",
    "Dependent on subtitles, say",
    "Like many users of sign language",
    "Needing sign language, say",
    "Unable to hear",
    "Not to be persuaded",
    "___ as a post",
    "\"Are you ___?!\"",
    "Like most users of sign language",
    "See 73-Across",
    "Unhearing",
    "Like Beethoven",
    "Like Tommy, of the Who's 1969 rock opera",
    "Unwilling to listen",
    "See 22-Down",
    "Like Beethoven",
    "Ones who sign, with \"the\"",
    "Refusing to listen",
    "Not hearing",
    "Heedless",
    "Kind of ears",
    "Not able to hear",
    "Unpersuadable",
    "Kind of ear",
    "Incognizant",
    "Heedless",
    "Fall on ___ ears",
    "Hard of hearing",
    "Unyielding"
  ],
  "tops": [
    "Dreidels, e.g.",
    "At most",
    "Shirts and blouses",
    "One-ups",
    "At the most",
    "\"... max\"",
    "A-1",
    "Twirlers",
    "Max",
    "A number one",
    "A-number-one",
    "Maximally",
    "Spinning toys",
    "Trumps",
    "Shirts and blouses",
    "Blouses and sweaters",
    "Maximally",
    "The best",
    "At most",
    "The best",
    "Outstanding",
    "First-rate",
    "Super-duper",
    "Spinning toys",
    "First-rate",
    "First-rate",
    "The best",
    "Spinners",
    "A-one",
    "Take these out for a spin",
    "First-rate",
    "First-class"
  ],
  "posh": [
    "Fancy-schmancy",
    "Ritzy",
    "Inducing an \"Ooh-la-la,\" say",
    "Fancy-schmancy",
    "Deluxe",
    "Swanky",
    "Luxurious",
    "Fancy-schmancy",
    "Fancy-schmancy",
    "Swanky",
    "Sumptuously furnished",
    "Like five-star accommodations",
    "Fancy",
    "Swank",
    "Deluxe",
    "Fancy-schmancy",
    "Like many suites",
    "Luxurious",
    "Far from shabby",
    "Deluxe",
    "Uptown",
    "Ritzy",
    "Ritzy",
    "Fancy-schmancy",
    "Expensively elegant",
    "Ritzy",
    "Sumptuous",
    "Fit for a king",
    "Swanky",
    "Swanky",
    "Ritzy",
    "Far from seedy",
    "Luxurious",
    "Swanky",
    "Superdeluxe",
    "Fancy-schmancy",
    "Deluxe",
    "Luxurious",
    "Swanky",
    "Swank",
    "Finely appointed",
    "Ritzy",
    "Swanky",
    "Swanky",
    "Deluxe",
    "Ritzy"
  ],
  "inot": [
    "Beyond regulation, briefly",
    "How tied N.F.L. games are resolved, for short",
    "How some exciting N.B.A. games are won",
    "How a tie may be broken, in brief",
    "Playing a fifth N.F.L. period, say",
    "Past the regulation period, informally",
    "Playing extra minutes, for short",
    "Playing past regulation, informally",
    "\"How could ___?\"",
    "Trying to break a tie, say",
    "Playing longer than expected, for short",
    "How some N.F.L. games are resolved",
    "\"How could ___?\""
  ],
  "heap": [
    "Disorganized pile",
    "In a ___ of trouble",
    "Great deal",
    "Crate",
    "Lot",
    "Mound",
    "Bucket of bolts",
    "Whole bunch",
    "Amount of trouble?",
    "Junker",
    "Jalopy",
    "Pile",
    "Ton",
    "Bucket of bolts",
    "Rustbucket",
    "Mountain",
    "Great deal",
    "Jalopy",
    "Crate",
    "Jalopy",
    "Disorderly stack",
    "Bucket of bolts",
    "Bucket of bolts",
    "Bunch",
    "Jalopy",
    "Bucket of bolts",
    "Jalopy",
    "Pile",
    "Clunker of a car",
    "Lot",
    "Old jalopy",
    "Pile",
    "Great deal",
    "Lot",
    "Mound",
    "Bunch",
    "Multitude",
    "Jalopy",
    "Great deal",
    "Pile",
    "Pile",
    "Jalopy",
    "Lot",
    "Pile",
    "Cairn",
    "Jalopy",
    "Mound",
    "Jalopy"
  ],
  "liar": [
    "Mythomaniac",
    "One needing new, unburned pants?",
    "Heated accusation",
    "Polygraph flunker",
    "Mythomaniac",
    "Pseudologist",
    "Pinocchio, e.g.",
    "Yarn spinner",
    "\"Not true!\"",
    "Fabulist",
    "Incredible person",
    "Courtroom outburst",
    "Pinocchio, notably",
    "Whopper inventor",
    "Whopper maker",
    "\"A ___ believes no one\" (old saying)",
    "Word repeated before \"pants on fire\"",
    "Fabulist",
    "Fabulous speaker?",
    "Trust buster?",
    "Whopper inventor",
    "Repeated word before \"pants on fire\"",
    "Mythomaniac",
    "Political accusation",
    "Story teller",
    "Put-down in an argument",
    "Whopper server?",
    "Type who might say \"The dog ate my homework\"",
    "Word repeated before \"pants on fire!\"",
    "Unreliable narrator",
    "Perjurer",
    "\"That is so not true!\"",
    "Pinocchio, periodically",
    "Untrustworthy sort",
    "Word repeated before \"pants on fire\"",
    "One who's incredible",
    "Interrogee, often",
    "One who breaks a court oath",
    "Make-up person?",
    "When repeated, a hit 1997 movie",
    "\"A ___ should have a good memory\": Quintilian",
    "Fabulist",
    "Pinocchio, at times",
    "One guilty of pseudologia",
    "Fabulist",
    "When doubled, a 1997 Jim Carrey movie",
    "Story teller",
    "When doubled, cry before \"pants on fire\"",
    "Many an interrogee",
    "Teller of tales",
    "Embroidery expert",
    "Fabricator",
    "Stereotypical debate outburst",
    "Disorderly courtroom outburst",
    "\"Pants on fire\" person",
    "One who's not straight",
    "Epithet that's an anagram of 60-Down",
    "Either of two guests on \"To Tell the Truth\"",
    "Word repeated before \"pants on fire\"",
    "Fact fudger",
    "Tall tale teller",
    "One who tells it like it isn't",
    "Pinocchio, famously",
    "Pseudologue",
    "False witness",
    "Word repeated in a child's taunt",
    "Fish story teller",
    "Yarn producer?",
    "Bad witness",
    "Teller of stories",
    "When repeated, start of a child's taunt",
    "Person who's not straight",
    "Disbeliever's cry",
    "Fibber",
    "Fibber",
    "Baloney peddler",
    "Cry in a mudslinging contest",
    "Inventor, of a sort",
    "Fudge maker?",
    "Great pretender",
    "Fibber",
    "Moonshine maker",
    "Yarn spinner",
    "Make-up person?",
    "Pseudologue",
    "Tall tale teller",
    "Pants-on-fire guy",
    "Iago, e.g.",
    "Makeup artist?",
    "Iago, notably",
    "Whopper maker",
    "Baloney producer",
    "Make-up artist?",
    "\"Not true!\"",
    "Pinocchio, for one",
    "One might be chronic",
    "Fiction teller",
    "Libeler, almost by definition",
    "Comeback to an accusation",
    "Tall tale teller",
    "Pinocchio, at times",
    "Pseudologue",
    "Bluffer",
    "Makeup person?",
    "Fiction enthusiast?",
    "Make-up artist?",
    "Unreliable source",
    "Fibster",
    "Storyteller",
    "Yarn spinner",
    "When doubled, a Jim Carrey film",
    "Pseudologist",
    "Perjurer",
    "Fable creator",
    "Schoolyard putdown",
    "When repeated, a 1997 Jim Carrey movie",
    "Word repeated before \"pants on fire\"",
    "Fibster",
    "When repeated, a 1997 Jim Carrey comedy",
    "Yarn maker",
    "Tall tale teller",
    "Unbelievable one",
    "Makeup artist?",
    "Fibster",
    "Jim Carrey, in a 1997 movie",
    "Polygraph flunker",
    "Detector target",
    "Storyteller",
    "Storyteller",
    "Pinocchio, at times",
    "Mudslinger's charge",
    "Fibber",
    "Makeup artist",
    "Fabulist",
    "Tale teller",
    "Bull tosser?",
    "Makeup artist?",
    "Whopper server",
    "\"Billy ___\" (Keith Waterhouse satire)",
    "Polygraph failer",
    "False fellow",
    "Whopper maker",
    "Polygraph flunker",
    "Prevaricator",
    "Ananias",
    "Mythomaniac",
    "Pre-rehab Pinocchio",
    "One given to stretchers",
    "Munchhausen, for one"
  ],
  "atoz": [
    "All-encompassing",
    "The whole nine yards",
    "Comprehensive",
    "The whole shebang",
    "The whole shebang",
    "All-inclusive",
    "Complete",
    "All-inclusive",
    "Covering the waterfront",
    "Leaving nothing out",
    "Complete",
    "The gamut",
    "Entirely",
    "The whole spectrum",
    "The gamut",
    "Fully",
    "The works",
    "*Everything",
    "All-inclusive",
    "Completely",
    "The whole kit and caboodle",
    "Complete",
    "Comprehensive",
    "The gamut",
    "Complete",
    "The whole kit and caboodle",
    "Complete",
    "Everything",
    "One-volume encyclopedia range",
    "From ___ (the works)",
    "The works ... or how each set of circled letters in this puzzle is arranged",
    "All-inclusive",
    "The whole shebang",
    "Complete",
    "The gamut",
    "Completely, after \"from\"",
    "The works",
    "All-inclusive",
    "From ___ (completely)",
    "Complete",
    "The whole ball of wax",
    "The gamut",
    "Full spectrum",
    "Comprehensive",
    "Complete gamut",
    "Completely",
    "Completely",
    "Completely",
    "The gamut",
    "The gamut",
    "The gamut",
    "Complete",
    "Exhaustive",
    "Forward and backward",
    "Thoroughly",
    "The whole spectrum",
    "Possible title for this puzzle",
    "The gamut",
    "Full range",
    "The gamut",
    "The works",
    "The whole shebang",
    "The gamut",
    "Complete",
    "The gamut",
    "All-inclusive",
    "The whole gamut",
    "Encyclopedic",
    "The gamut",
    "Complete",
    "Full, as an index",
    "Entire range",
    "The gamut",
    "The gamut",
    "The gamut"
  ],
  "tote": [
    "Bag with an NPR logo, maybe",
    "Pledge drive giveaway",
    "Pledge drive gift",
    "Carry",
    "Schlep",
    "Lug along",
    "Reusable bag",
    "Schlep",
    "Something brought to a supermarket",
    "Many a promotional giveaway",
    "Haul",
    "Lug",
    "Lug",
    "Donor gift, perhaps",
    "Schlep",
    "Many a pledge drive gift",
    "Telethon giveaway",
    "Add (up)",
    "___ board",
    "Schlep",
    "Mall bag",
    "Word with bag or board",
    "Pledge drive bag",
    "Gift for many a PBS donor",
    "Schlep",
    "Haul around",
    "Popular PBS pledge drive giveaway",
    "Carry",
    "Carryall",
    "Carryall",
    "Carry-on",
    "Carry",
    "Lug",
    "Backpack alternative",
    "Carry",
    "Lug",
    "Shopping bag",
    "Saks sack, say",
    "Bag with handles",
    "Lug",
    "Bear",
    "Frequent gift for a PBS donation",
    "Pari-mutuel machine",
    "Schlep",
    "Lug",
    "Carry",
    "Carry",
    "Shopper's convenience",
    "Mall aid",
    "Haul",
    "Popular pledge drive gift",
    "Lug",
    "Bag",
    "Schlep",
    "Lug",
    "Carry",
    "Lug",
    "Lug",
    "Lug",
    "Big bag",
    "Carry-on",
    "Kind of bag",
    "Canvas bag",
    "Carry",
    "Lug",
    "Pari-mutuel",
    "Convey",
    "Carry",
    "Kind of bag",
    "Package carrier",
    "___ bag",
    "Handled baggage",
    "Pari-mutuel",
    "Lug",
    "Lug",
    "Carryall",
    "Convey",
    "Kind of bag",
    "Shopper's bag",
    "Schlep",
    "Carry",
    "Shopping aid",
    "Carry-along",
    "Mall carry-along"
  ],
  "alms": [
    "Donations to the poor",
    "Some donations",
    "Charitable giving",
    "Form of relief",
    "Charitable offering",
    "Relief",
    "Welfare",
    "Help for the poor",
    "Charitable donations",
    "Poor box contents",
    "Old-fashioned charity",
    "Something for the needy",
    "Form of relief",
    "Charity",
    "\"___ for the poor\"",
    "Charitable contributions",
    "Poor support",
    "Money for the poor",
    "Donations to the needy",
    "Donations for the poor",
    "Offerings to the poor",
    "Beggar's cry",
    "Poor returns?",
    "Poor box contents",
    "Poor support",
    "Charity",
    "Help",
    "Mendicant's want",
    "Offering",
    "Charity",
    "Gifts to the poor",
    "Offering",
    "Salvation Army collection",
    "Poor box filler",
    "Gifts for the poor",
    "Money for the poor",
    "Donations",
    "Charity",
    "Handouts",
    "Charitable donations",
    "\"___ for the poor\"",
    "Maundy money",
    "Bygone request",
    "Charitable donation",
    "Relief, of a sort",
    "Beggar's cry",
    "Beggar's quest",
    "Charitable giving",
    "Money for a poor box",
    "\"___ for the poor\"",
    "Fakir's income",
    "Relief",
    "Pauper's cry",
    "Handouts"
  ],
  "zebu": [
    "Indian ox",
    "Holy cow",
    "Humped ox",
    "Indian bovine",
    "Domesticated ox in India",
    "Indian bovine with a humped shoulder",
    "Humped ox",
    "Hump-shouldered bovine",
    "Indian ox"
  ],
  "pulp": [
    "Part of orange juice that's not juice",
    "Papermaking material",
    "Choice in orange juice",
    "Orange juice option",
    "Trashy, in a way",
    "Orange juice option",
    "Juice component",
    "Amazing Stories, e.g.",
    "Solid parts of orange juice",
    "Great literature's opposite",
    "Papermaking material",
    "Orange component",
    "___-free",
    "Newsprint need",
    "An orange, minus the juice",
    "Juicer refuse",
    "Part of an orange",
    "\"___Fiction\""
  ],
  "ably": [
    "With skill",
    "With skill",
    "With adroitness",
    "With competence",
    "Well",
    "In a masterly manner",
    "With skill",
    "Without fumbling",
    "With a sure hand",
    "With proficiency",
    "Well",
    "With competence",
    "How experts work",
    "Well",
    "Skillfully",
    "Well",
    "With competence",
    "With proficiency",
    "Well",
    "With skill",
    "With competence",
    "With skill",
    "With adroitness",
    "With competence",
    "Skillfully",
    "With deftness"
  ],
  "carl": [
    "Sagan who hosted TV's \"Cosmos\"",
    "___ Laemmle, film pioneer who co-founded Universal Pictures",
    "Astronomer Sagan",
    "Baseball Hall-of-Famer Yastrzemski",
    "Baseball legend Yastrzemski",
    "Cosmologist Sagan",
    "Sagan who hosted \"Cosmos\"",
    "Friend of Homer on \"The Simpsons\"",
    "Astronomer Sagan",
    "Lenny's friend on \"The Simpsons\"",
    "Psychologist Jung",
    "Astronomer Sagan",
    "Hall-of-Famer Yastrzemski",
    "Co-worker of Homer on \"The Simpsons\"",
    "Real first name of Alfalfa of the Little Rascals",
    "Baseball player Yastrzemski",
    "Psychologist Jung",
    "Missing from 13-Down",
    "Director Reiner",
    "Sagan or Sandburg",
    "Psychologist Jung",
    "Rob Reiner's father",
    "Track star Lewis",
    "Songwriter Perkins",
    "Sagan of \"Cosmos\"",
    "Vikings great Eller",
    "Perkins who sang \"Blue Suede Shoes\"",
    "Cosmologist Sagan",
    "Director Reiner",
    "Poet Sandburg",
    "Screwballer Hubbell",
    "Baseball's Yastrzemski",
    "Perkins who wrote \"Blue Suede Shoes\"",
    "Columnist Rowan",
    "One of the Reiners",
    "Reiner of TV",
    "Poet Sandburg",
    "Sagan of \"Cosmos\"",
    "Journalist Bernstein",
    "31-Across's first name",
    "Runner Lewis",
    "Columnist Rowan",
    "Baseball's Yastrzemski",
    "Runner Lewis"
  ],
  "otay": [
    "\"The Little Rascals\" assent",
    "\"Our Gang\" approval",
    "Buckwheat's affirmative",
    "\"Our Gang\" affirmative",
    "Approval on \"The Little Rascals\"",
    "\"Our Gang\" assent"
  ],
  "flat": [
    "Out of tune ... or bubbles",
    "Unchanged, as on an earnings report",
    "Outright",
    "Sharp's counterpart, in music",
    "Down a half step, say",
    "Dead-tired?",
    "Unshiny",
    "Like some fees and feet",
    "With 24-Down, blowout result",
    "*Smooth",
    "Needing to get keyed up?",
    "Motorist's woe",
    "Needing tuning, maybe",
    "One way to be turned down",
    "Fizzless, as a Coke",
    "What a 17-Across might get assistance with",
    "Unchanged",
    "Driver's headache",
    "Kansaslike",
    "Showing no growth",
    "Lacking sparkle",
    "Out of fizz",
    "Pitched too low",
    "Off-key, in a way",
    "Fizzless, as a soft drink",
    "Cyclist's problem",
    "Like some rates",
    "Apartment, to Brits",
    "Fizzless",
    "Apartment",
    "Sharp?Æs counterpart",
    "Motorist's woe",
    "Blowout result",
    "Like a pancake",
    "Fizzless",
    "Like the world, to the ancients",
    "One way to fall",
    "Like the world to pre-Columbians",
    "Off-pitch",
    "Like earnings in some reports",
    "Fizzless",
    "Unexciting",
    "Woman's shoe",
    "Holding steady",
    "Lacking fizz",
    "Not increasing, as earnings",
    "Dull and dreary",
    "Iron or foot preceder",
    "Fizzless"
  ],
  "osay": [
    "Anthem opening",
    "Anthem starter",
    "Start of \"The Star-Spangled Banner\"",
    "Start of \"The Star-Spangled Banner\"",
    "Anthem starter",
    "Start of \"The Star-Spangled Banner\"",
    "Anthem opener",
    "\"The Star-Spangled Banner\" opening",
    "Starting words at many a sporting event?",
    "Key opening?",
    "\"The Star-Spangled Banner\" opener",
    "\"___ can you see ...?\"",
    "Start of a song that ends with \"brave\"",
    "\"The Star-Spangled Banner\" start",
    "Anthem starter",
    "Anthem starter",
    "Anthem opener",
    "Key opening?",
    "Anthem opening",
    "Anthem opener",
    "Key start?",
    "\"___ can you see ...\"",
    "Anthem starter",
    "Anthem opening",
    "Opening words at a ball game?",
    "National anthem start",
    "Anthem's start"
  ],
  "drea": [
    "Actress de Matteo of \"Sons of Anarchy\"",
    "\"The Sopranos\" actress ___ de Matteo",
    "De Matteo of \"Desperate Housewives\"",
    "Actress de Matteo of \"The Sopranos\"",
    "De Matteo of \"The Sopranos\""
  ],
  "upat": [
    "___ the crack of dawn",
    "Awake by",
    "Show ___ (attend, as a meeting)",
    "Show ___ (attend)",
    "\"___ Oxford\" (Ved Mehta memoir)",
    "___ the crack of dawn",
    "___ the crack of dawn",
    "___ the crack of dawn"
  ],
  "goof": [
    "Flub",
    "Slip-up",
    "Horse (around)",
    "Err",
    "Idle, with \"off\"",
    "Blunder",
    "Clown (around)",
    "Silly billy",
    "Slip",
    "Slip",
    "Typo, e.g.",
    "Blunder",
    "Slip",
    "Anachronism, e.g.",
    "Flub",
    "Play around, with \"off\"",
    "Bobble",
    "Foul-up",
    "Muff",
    "Clinker",
    "Make a boo-boo",
    "Foolish person",
    "Flub",
    "Boo-boo"
  ],
  "dama": [
    "Lady of Spain",
    "Lady of Spain",
    "Lady of Spain",
    "Lady of Spain",
    "Lady of Spain"
  ],
  "yada": [
    "When repeated, a \"Seinfeld\" expression",
    "When repeated, \"You get the idea\"",
    "When tripled, \"etc.\"",
    "When tripled, a \"Seinfeld\" catchphrase",
    "One-third of \"et cetera\"?",
    "When tripled, blah, blah, blah",
    "When tripled, a Seinfeld catchphrase",
    "When tripled, et cetera",
    "When tripled, et cetera",
    "When said three times, \"and so on\"",
    "When tripled, a \"Seinfeld\" catchphrase",
    "When tripled, \"et cetera\"",
    "When repeated twice, et cetera",
    "In triplicate, a Seinfeld catchphrase",
    "When said three times, \"et cetera\"",
    "Word usually said in triplicate"
  ],
  "mali": [
    "Country whose flag stripe colors are exactly the same as Guinea's except in reverse order",
    "Timbuktu's country",
    "Home of Timbuktu",
    "Neighbor of Algeria",
    "Country between Algeria and Burkina Faso",
    "Home of Timbuktu",
    "Its capital is Bamako",
    "It gained independence from France in 1960",
    "Timbuktu's land",
    "Neighbor of Burkina Faso",
    "Where Bambara is widely spoken",
    "Burkina Faso neighbor",
    "Bamako is its capital",
    "Niger's western neighbor",
    "Country once known as French Sudan",
    "Bamako's land",
    "Big African exporter of gold",
    "French Sudan, today",
    "African country bordering 12-Down",
    "Country whose name is an anagram of another country's capital",
    "Tuareg rebellion locale of 2012",
    "Landlocked African land",
    "Country that's over 50% desert",
    "Africa's bygone ___ Empire",
    "West African land",
    "Country where Bambara is the main spoken language",
    "Guinea's neighbor to the northeast",
    "Landlocked African country",
    "Landlocked land of Africa",
    "Where Timbuktu is",
    "Where the Senegal River begins",
    "Country with a green, yellow and red flag",
    "Landlocked Muslim land",
    "Saharan country south of Algeria",
    "Its capital is Bamako",
    "Timbuktu's home",
    "Where Bambara is spoken",
    "French Sudan, today",
    "Saharan land",
    "Bamako's land",
    "Timbuktu's country",
    "Landlocked land of 12 million",
    "The Sudanese Republic, today",
    "49-Down neighbor",
    "Bamako's land",
    "Bamako is its capital",
    "French Sudan, today",
    "Saharan land",
    "Timbuktu's land",
    "Algeria neighbor",
    "Sudanese Republic, now",
    "Landlocked land",
    "Niger neighbor",
    "Neighbor of Mauritania",
    "Timbuktu's land",
    "Bamako is its capital",
    "French Sudan, today",
    "Neighbor of Guinea",
    "French Sudan, today",
    "Saharan land",
    "French Sudan today",
    "Neighbor of Senegal",
    "Niger neighbor",
    "Landlocked African country",
    "African country",
    "Bamako is its capital",
    "Niger's western neighbor",
    "Where Timbuktu is",
    "Neighbor of Mauritania",
    "Bamako is its capital",
    "Its capital is Bamako",
    "French Sudan, today",
    "Bamako is its capital",
    "French Sudan, today",
    "Saharan nation",
    "French Sudan, today",
    "Neighbor of Senegal",
    "Bamako's country",
    "Neighbor of Algeria",
    "Former French Sudan"
  ],
  "bran": [
    "Stuff in a muffin",
    "Stark who was crowned king in the \"Game of Thrones\" finale",
    "Muffin choice",
    "Bread enricher",
    "Roughage source",
    "Something white rice lacks",
    "Kellogg's Raisin ___",
    "Cereal ingredient",
    "Whole grain component",
    "Muffin material",
    "Healthful breakfast choice",
    "Milling byproduct",
    "Kellogg's All-___",
    "All-___",
    "Hominy makers extract it",
    "Stuff in a muffin",
    "Fiber-rich food",
    "Kind of muffin",
    "Fiber source",
    "Muffin ingredient",
    "Kellogg's All-___",
    "Raisin ___",
    "Healthful husks",
    "What white flour lacks",
    "Muffin composition, maybe",
    "Kellogg's Raisin ___",
    "Muffin ingredient",
    "Wheat ___",
    "Fiber source",
    "Cereal choice",
    "Raisin ___ (cereal)",
    "Breakfast cereal",
    "Fiber",
    "Flake material",
    "Food for regular folks?",
    "Fiber source",
    "Breakfast staple",
    "It's in whole wheat",
    "Composition of some flakes",
    "Raisin ___",
    "Fare for regular folks?",
    "High-fiber food",
    "Muffin ingredient",
    "It's removed from white rice",
    "Ingredient in many cereals",
    "Product of sifting",
    "Some cereal",
    "Stuff in a muffin",
    "Source of fiber",
    "Breakfast cereal",
    "Cereal choice",
    "Flake material",
    "Breakfast fiber source",
    "Healthful breakfast food"
  ],
  "aboo": [
    "Peek-___",
    "Ending with peek",
    "\"Pink-___\" (1966 Pink Panther short)",
    "Ending with peek",
    "Peek-___",
    "\"Peek-___!\"",
    "\"Bug ___\" (1999 Destiny's Child hit)",
    "Peek follower",
    "\"Peek-___!\"",
    "Bug chaser?",
    "Peek-___",
    "\"Peek-___!\"",
    "\"Peek-___, I see you!\"",
    "\"Peek-___\"",
    "See 29-Down",
    "Peek follower",
    "Ending with peek or bug",
    "Peek-___",
    "\"Peek-___!\"",
    "\"Peek\" follower",
    "Bug tail?",
    "Peek-___",
    "Ending with bug or peek",
    "It follows \"peek\" in a baby's game",
    "\"Peek-___!\"",
    "Peek-___",
    "Peek follower",
    "Peek ending",
    "Peek-___",
    "Peek ending",
    "\"Peek-___\"",
    "Peek ending"
  ],
  "dose": [
    "Measurement that might be made in milligrams",
    "Two tablets or five milliliters, say",
    "Spoonful of medicine, e.g.",
    "10 milliliters, perhaps",
    "Two tablets, maybe",
    "One or two tablets, say",
    "Recommended amount",
    "Rx detail",
    "12 cc, maybe",
    "Spoonful of medicine, say",
    "___ of reality",
    "Give meds",
    "Spoonful, say",
    "Pharmacological amount",
    "Doctor's recommendation",
    "Amount of medicine",
    "Hospital meal accompanier",
    "5 milliliters of medicine, say",
    "Amount of medicine to take",
    "Medicinal amount",
    "One tablet, maybe",
    "A shot in the arm, say",
    "Prescribed amount",
    "Pill bottle recommendation",
    "Two teaspoons, e.g.",
    "One teaspoonful or two caplets, maybe",
    "Spoonful, say",
    "Two caplets, say",
    "A teaspoonful, maybe",
    "Recommended intake",
    "Two tablets, say",
    "Info on a pill bottle",
    "Two caplets, e.g.",
    "Two capsules, perhaps",
    "Two tablets every six hours, e.g.",
    "Two caplets, maybe",
    "One tablespoon, e.g.",
    "One teaspoon, maybe",
    "Doctor's order",
    "Recommended amount",
    "Amount of medicine",
    "Bit of reality?",
    "Teaspoonful, maybe",
    "One bit of medicine",
    "Two caplets, say",
    "Recommended amount",
    "Amount of medicine",
    "Spoonful, say",
    "Healthy amount?",
    "One teaspoon, maybe",
    "Two tablets, say",
    "Teaspoon or tablet, say",
    "Two capsules, say",
    "Teaspoonful, maybe",
    "Doctor's cupful, maybe",
    "Two tablets, maybe",
    "Two teaspoons, say",
    "Medicinal amount",
    "Prescription info",
    "Two caplets, e.g.",
    "Capsule contents",
    "Two tablets, maybe",
    "2 mg, say",
    "Prescription particular",
    "Medicinal unit",
    "Prescription amount",
    "10 cc, perhaps",
    "Shot",
    "Two pills, e.g.",
    "Two pills, maybe",
    "Two shots, maybe",
    "Bit of medicine",
    "Pill allotment"
  ],
  "jett": [
    "Rock's Joan ___ & the Blackhearts",
    "Rocker Joan",
    "Rocker Joan",
    "Joan nicknamed \"The Godmother of Punk\"",
    "2015 Rock and Roll Hall of Fame inductee",
    "Joan of rock",
    "Joan of the Blackhearts",
    "Joan who sang \"I Love Rock 'n Roll\"",
    "Singer Joan",
    "\"I Love Rock 'n Roll\" singer",
    "Joan of rock",
    "Rocker Joan",
    "Singer Joan",
    "Rocker Joan",
    "\"I Love Rock 'n Roll\" singer Joan"
  ],
  "imed": [
    "Discussed over Slack, say, in brief",
    "Used Facebook Messenger, for short",
    "Chatted on the internet, for short",
    "Used Gchat, e.g.",
    "Chatted with, in a way",
    "Contacted via Google Talk, say",
    "Reached quickly, quickly?",
    "Alternative to texted"
  ],
  "roth": [
    "\"Divergent\" author Veronica",
    "Big name in retirement accounts",
    "Philip who wrote \"American Pastoral\"",
    "Kind of I.R.A.",
    "___ I.R.A.",
    "Retirement account option, informally",
    "Rocker David Lee ___",
    "Veronica ___, author of the best-selling \"Divergent\" series",
    "Philip who wrote \"Portnoy's Complaint\"",
    "Philip who said \"goodbye\" to Columbus",
    "Kind of I.R.A.",
    "Hyman ___, main antagonist in \"The Godfather Part II\"",
    "Senator William who pioneered a type of I.R.A.",
    "Singer David Lee ___",
    "Writer Philip",
    "With 29-Across, nest egg choice",
    "Horror director Eli",
    "Novelist Philip",
    "\"Letting Go\" novelist Philip",
    "Author Philip",
    "\"The Human Stain\" novelist",
    "\"Portnoy's Complaint\" author",
    "\"The Human Stain\" novelist",
    "Rock's David Lee ___",
    "___ I.R.A. (savings plan for old age)",
    "Philip who wrote \"Goodbye, Columbus\"",
    "Longtime Delaware senator William",
    "___ I.R.A. (investment)",
    "\"The Human Stain\" novelist",
    "\"Zuckerman Unbound\" novelist",
    "___ I.R.A.",
    "Rocker David Lee ___",
    "Longtime Delaware senator William",
    "\"Portnoy's Complaint\" author",
    "Writer Philip",
    "\"Letting Go\" novelist",
    "\"The Dying Animal\" novelist",
    "\"Just a Gigolo\" singer, 1985",
    "___ v. United States (classic Supreme Court obscenity case)",
    "Longtime Delaware senator",
    "\"Portnoy's Complaint\" author",
    "\"Goodbye Columbus\" author",
    "\"Zuckerman Unbound\" novelist",
    "Longtime Delaware Senator",
    "\"Portnoy's Complaint\" author",
    "\"Call It Sleep\" novelist",
    "Delaware Senator William",
    "\"Letting Go\" novelist",
    "\"Portnoy's Complaint\" author",
    "\"Goodbye, Columbus\" author",
    "Portnoy's creator"
  ],
  "work": [
    "9-to-5 activity",
    "Forms to process",
    "Ending for \"right to\" or \"put to\"",
    "One half of a 55-Across",
    "Get down to business",
    "Something cited in a citation",
    "Do the trick",
    "A bummer for bums",
    "Cry made while cracking a whip, maybe",
    "Slacker's bane",
    "Succeed",
    "Task",
    "Function",
    "Labor",
    "Have an effect (on)",
    "Lead-in for -aholic",
    "Have an effect",
    "Operate"
  ],
  "kcup": ["Single-serving coffee pod", "Single-serve coffee holder"],
  "sals": [
    "Pizza joint in \"Do the Right Thing\"",
    "Pizzeria in \"Do the Right Thing\"",
    "Movie pizzeria destroyed in a riot",
    "\"Do the Right Thing\" pizzeria",
    "Pizza joint in \"Do the Right Thing\"",
    "Movie pizzeria where Radio Raheem ate",
    "\"Do the Right Thing\" pizzeria",
    "\"Do the Right Thing\" pizzeria",
    "\"Do the Right Thing\" pizzeria",
    "Pizza joint in \"Do the Right Thing\"",
    "\"Do the Right Thing\" pizzeria",
    "Pizzeria in Spike Lee's \"Do the Right Thing\"",
    "Maglie and Mineo",
    "\"Do the Right Thing\" pizzeria",
    "\"Do the Right Thing\" pizzeria",
    "\"Do the Right Thing\" pizzeria",
    "\"Do the Right Thing\" pizzeria",
    "Pitcher Maglie and others",
    "\"My gal\" et al.",
    "\"Do the Right Thing\" pizzeria",
    "\"Do the Right Thing\" pizzeria",
    "Pharmacists' compounds",
    "Actor Mineo and others",
    "\"Do the Right Thing\" pizzeria",
    "Actor Mineo et al.",
    "Mineo and others"
  ],
  "wily": [
    "Foxlike",
    "Good at scheming",
    "Guileful",
    "Cunning",
    "Cunning",
    "Cunning",
    "Scheming"
  ],
  "memo": [
    "Message to the office staff",
    "Note to a staffer",
    "Staff note",
    "Firm-ly worded letter?",
    "\"I guess you missed the ___\"",
    "\"Didn't you get the ___?\"",
    "An assistant might take one",
    "Posting on an office bulletin board",
    "Office note",
    "Message with a subject line",
    "___ pad",
    "\"I guess you didn't get the ___\"",
    "It might start \"Attn.\"",
    "\"Didn't you get the ___?\"",
    "Office paper",
    "Office note",
    "Note from a co-worker",
    "Office message",
    "Note",
    "Workplace communication",
    "Kind of pad",
    "Brief reminder",
    "Note",
    "In-tray item",
    "Note at the office",
    "It might have the heading \"Re:\"",
    "Office reminder",
    "Note from the boss",
    "It may go around the office",
    "Office note",
    "Staff lines?",
    "Office note",
    "Staff note",
    "Staff note",
    "Exec's note",
    "Staff note",
    "\"I must have missed the ___\"",
    "Office missive",
    "Staff note",
    "Office reminder",
    "Management issuance",
    "Quick note",
    "Kind of pad",
    "Note",
    "Note on a Post-it, maybe",
    "Note",
    "Office message",
    "Office message",
    "Exec's note",
    "Message",
    "Boss's writing",
    "Short note",
    "Office communique",
    "It may start with \"re\"",
    "Staff note",
    "Note",
    "Office transmittal",
    "Office note",
    "Bulletin board notice",
    "Directive at work",
    "Company correspondence",
    "Office mail",
    "Office note",
    "Office communique",
    "Written reminder",
    "Exec?Æs dispatch",
    "Reminder",
    "Steno's product",
    "Office note",
    "Office note",
    "Secretarial work"
  ],
  "metz": [
    "Chrissy of \"This Is Us\"",
    "\"This Is Us\" co-star Chrissy",
    "Capital of Lorraine",
    "French city in 1944 fighting",
    "Birthplace of poet Paul Verlaine",
    "Capital of Lorraine",
    "City on the Moselle",
    "City north of Nancy",
    "City on the Moselle",
    "City on the Moselle",
    "Site of the oldest church in France",
    "French city in W.W. II fighting"
  ],
  "moue": [
    "Pouty expression",
    "Pouty face",
    "Pouty face",
    "Pouty face",
    "Pout",
    "Manifestation of sulkiness",
    "Sour expression",
    "Pouting expression",
    "Silent \"ick\"",
    "Pouty look",
    "Sulker's expression",
    "Peeved pout",
    "Pout",
    "Sourpuss's look",
    "Vexed look",
    "Pout",
    "Unhappy face",
    "Pouty look",
    "Grimace",
    "Pouting look",
    "Pouty look",
    "Pouty look",
    "No happy face",
    "Pouting grimace",
    "Sullen look",
    "Pout",
    "Grimace",
    "Pout",
    "Grimace",
    "Pained look",
    "Pouting face",
    "Pout",
    "Grimace",
    "Pout",
    "Pout"
  ],
  "cant": [
    "Jargon",
    "Pessimist's word",
    "\"Too busy\"",
    "Jargon",
    "Lean",
    "Terse refusal",
    "Jargon",
    "Is unable to",
    "\"Why ___ we be friends?\"",
    "\"I ___ blame you\"",
    "Tilt",
    "\"Not doable\"",
    "Slope",
    "Frankie Valli's \"___ Take My Eyes Off You\"",
    "Word for quitters",
    "Tilt",
    "\"I'm busy then\"",
    "\"You ___ handle the truth!\"",
    "Insincere talk",
    "\"This ___ be!\"",
    "\"Out of the question\"",
    "Lingo",
    "Quitter's word",
    "With 1-Across, is very important",
    "Insincere talk",
    "Tilted position",
    "Pessimist's word",
    "Empty talk",
    "Jargon",
    "Pessimist's word",
    "Pessimist's word",
    "Defeatist's word",
    "Isn't able to",
    "Quitter's word",
    "Argot",
    "Quitter's word",
    "Oblique line",
    "Wimp's word",
    "Shoptalk",
    "Insincere talk",
    "Projecting angle",
    "Isn't able to"
  ],
  "comb": [
    "Item in a purse",
    "Honey bunch?",
    "Scour",
    "Carefully search",
    "Grooming implement",
    "Aid in creating a part",
    "Tangle untangler",
    "Barber's implement",
    "Hair parter",
    "Really go through",
    "*Jim's gift in \"The Gift of the Magi\"",
    "Hair untangler",
    "Purse item",
    "Hive production",
    "Parting need?",
    "Rooster feature",
    "Hair straightener",
    "Hair untangler",
    "Mr. Clean would never part with one",
    "Scour",
    "Search, as a beach",
    "Toothed item",
    "Purse item",
    "Search thoroughly",
    "Hair raiser, perhaps",
    "Search",
    "Search thoroughly"
  ],
  "zaps": [
    "Microwaves",
    "Microwaves",
    "Shocks",
    "Stuns, as with a phaser",
    "Video game sounds",
    "Obliterates",
    "Electrocutes",
    "Kills, as bugs",
    "Sounds of shock?",
    "Hits with a Taser",
    "Patio bug-killer sounds",
    "Nukes",
    "Uses a 13-Across on",
    "Microwaves",
    "Microwaves",
    "Microwaves",
    "Microwaves",
    "Hits with bug spray",
    "Nukes",
    "Microwaves",
    "Nukes",
    "Stuns",
    "Touches with a live wire",
    "Blasts with a ray gun",
    "Eliminates by remote control",
    "Hits with phaser fire",
    "Hits with a ray gun",
    "Hits with a stun gun",
    "Skips over, as TV ads",
    "Bypasses, as commercials",
    "Nukes"
  ],
  "blam": [
    "Explosive sound",
    "\"Pow!\"",
    "Exploding cigar sound",
    "Gunfight sound effect",
    "Noise in a comic book gunfight"
  ],
  "bows": [
    "Curtain call actions",
    "Fiddlesticks",
    "Things to draw",
    "Arrow shooters",
    "Violinists' needs",
    "Fiddle sticks",
    "Archaic greetings",
    "Salaams",
    "Exits, with \"out\"",
    "Hair decorations",
    "Old-fashioned greetings",
    "Accepts defeat"
  ],
  "btus": [
    "A/C measures",
    "Air-conditioner fig.",
    "Energy qtys.",
    "Air-conditioner fig.",
    "A/C measures",
    "HVAC measures, for short",
    "A/C specs",
    "Energy qtys.",
    "Furnace meas.",
    "A/C measures",
    "A/C measures",
    "A.C. measures",
    "A/C measures",
    "Heater stats",
    "Appliance figs.",
    "A/C units",
    "A/C measure",
    "Heater stats",
    "Relatives of calories: Abbr.",
    "Air conditioner meas.",
    "Air conditioner capacity, for short",
    "A.C. capacity",
    "Cooling capacity",
    "Heat measures, for short",
    "Heat quantities: Abbr."
  ],
  "onek": [
    "Short race, for short",
    "Brief race, in brief",
    "A thou",
    "Fun run length, for short",
    "Short race spec",
    "Brief race, in brief",
    "Short race, informally",
    "Brief race distance, briefly",
    "Short race, briefly",
    "Short race, for short"
  ],
  "leks": [
    "Albanian coins",
    "Albanian cash",
    "Albanian coins",
    "Albanian coins"
  ],
  "agfa": [
    "Digital imaging company that used to make film",
    "Letters on old film boxes",
    "Name on some boxes of film",
    "Fuji rival",
    "Digital imaging brand",
    "Big name in photography, once",
    "Belgium-based imaging company",
    "Fuji rival",
    "Film brand",
    "Film company",
    "Fuji film competitor",
    "Kodak rival",
    "Kodak competitor",
    "Fuji competitor",
    "Kodak competitor",
    "Kodak or Fuji competitor",
    "Konica competitor",
    "Alternative to Kodak or Fuji"
  ],
  "uggs": [
    "\"Fluff Yeah\" slipper sandals, e.g.",
    "Often-counterfeited boots",
    "Popular boots from Australia",
    "Popular footwear from Down Under",
    "Popular fleece-lined boots",
    "Popular boots that originated from surf culture",
    "Fleecy boots",
    "Popular sheepskin boots"
  ],
  "refs": [
    "Some coin tossers",
    "Flag tossers, informally",
    "Zebras",
    "Foul rulers",
    "Dict., gaz. and others",
    "Yellow card displayers",
    "Wearers of striped shirts",
    "Dictionaries, almanacs, etc., in brief",
    "Ones whistling while they work?",
    "Officials crying \"Offside\" and \"Pass interference\"",
    "Some whistle blowers",
    "Notable whistle blowers",
    "Makes the calls",
    "Calls",
    "Gridiron officials",
    "Gridiron officials, in brief",
    "Frequent targets of fan heckling",
    "Court V.I.P.'s",
    "Basketball officials, informally",
    "Makes calls on a basketball court",
    "Hand signal users",
    "Ones dressed in stripes, for short",
    "TKO callers",
    "Zebras",
    "Calls",
    "Umps",
    "Calls",
    "Judges",
    "Flag tossers, for short",
    "Makes calls",
    "Sports \"zebras\"",
    "Calls the shots?",
    "Makes calls",
    "Foul callers",
    "Makes calls",
    "Play directors",
    "Stripe wearers",
    "Gridiron \"zebras\"",
    "Makes calls on the court",
    "They keep track of pins",
    "Court figures",
    "Judges",
    "Whistle blowers",
    "TKO callers",
    "Zebras",
    "Zebras on the gridiron",
    "Ency. and dict.",
    "Calls, as a game",
    "Officiates",
    "Zebras",
    "They may stop the fighting",
    "Makes the calls",
    "Makers of calls",
    "Some whistle blowers",
    "Judges",
    "N.B.A. personnel",
    "Some whistle blowers",
    "Calls",
    "Zebras",
    "They're paid to make calls",
    "Umps",
    "Arbitrators, for short",
    "KO callers",
    "Arbiters of plays"
  ],
  "dept": [
    "Corp. division",
    "Part of U.S.D.A.: Abbr.",
    "University div.",
    "Abbr. in many a mail-order address",
    "State, e.g.: Abbr.",
    "Part of 5-Across: Abbr.",
    "Firm part: Abbr.",
    "Co. unit",
    "Cabinet div.",
    "Org. division",
    "HUD, for one: Abbr.",
    "Part of D.O.E.: Abbr.",
    "Corp. section",
    "Educ. or H.U.D., e.g.",
    "Part of U.S.D.A.: Abbr.",
    "Accounting, e.g.: Abbr.",
    "Agric. or H.H.S., e.g.",
    "___of Educ.",
    "___of Sanitation",
    "N.Y. Police ___"
  ],
  "guns": [
    "Big biceps, slangily",
    "Big biceps, in slang",
    "Magazine articles",
    "Magazine contents",
    "They're held by stocks",
    "Big ___ (V.I.P.'s)",
    "See 31-Across",
    "Magazine contents",
    "Magazine contents",
    "26-Across items",
    "Aims (for)",
    "Butter substitute?",
    "Firearms"
  ],
  "clem": [
    "Nickname for a man whose name means \"merciful\"",
    "___ Kadiddlehopper (Red Skelton character)",
    "Mrs. Miniver's husband in \"Mrs. Miniver\"",
    "___ Kadiddlehopper, Red Skelton character",
    "___ Haskins, 1960s-'70s N.B.A. player",
    "1950s Dodgers pitcher ___ Labine",
    "Boy in the comic strip \"Rose Is Rose\"",
    "One of the Scraggs in \"Li'l Abner\"",
    "___ Kadiddlehopper, old TV hayseed",
    "Brooklyn Dodgers pitcher Labine",
    "Red Skelton's Kadiddlehopper",
    "Mr. Miniver, in \"Mrs. Miniver\"",
    "Mr. Miniver in \"Mrs. Miniver\"",
    "Skelton's Kadiddlehopper",
    "Red Skelton character",
    "One of the Scraggs in \"Li'l Abner\"",
    "Mr. Miniver in \"Mrs. Miniver\"",
    "Skelton's Kadiddlehopper",
    "Mr. Miniver",
    "Mr. Miniver in \"Mrs. Miniver\"",
    "Skelton's Kadiddlehopper",
    "Pitcher Labine",
    "Mr. Kadiddlehopper",
    "Skelton's Kadiddlehopper",
    "Mr. Kadiddlehopper",
    "Mr. Kadiddlehopper",
    "Mr. Kadiddlehopper"
  ],
  "sobe": [
    "Beverage brand with a lizard logo",
    "Iced tea brand in a bottle",
    "Pipes purchase of 2001",
    "\"___ it!\"",
    "Drink with a lizard logo",
    "PepsiCo brand",
    "Drink with two lizards in its logo",
    "Pepsi-owned beverage brand",
    "Drink with a lizard logo",
    "Juice brand",
    "Lizard Fuel beverage maker",
    "Beverage with a lizard logo",
    "Beverage brand whose logo is two lizards",
    "\"___ it!\"",
    "Pepsi-owned juice drink",
    "\"___ it\" (\"Amen\")",
    "\"___ it\" (conversation closer)"
  ],
  "jets": [
    "Dashes (off)",
    "Shark fighters",
    "Strong sprays",
    "One might ask for them to be cooled",
    "747s, e.g.",
    "Things aircraft carriers carry",
    "\"West Side Story\" gang",
    "767s, e.g.",
    "Movie foes of the Sharks",
    "They're grounded when they're misbehaving",
    "They leave trails",
    "Boeing 747s, e.g.",
    "Meadowlands squad",
    "\"West Side Story\" gang",
    "Boeing products",
    "Spurts from fountains",
    "\"Cool your ___!\"",
    "Boeing 747's and 767's",
    "Sharks' enemies",
    "Flight fleet",
    "\"West Side Story\" gang",
    "New York footballers",
    "Bills' rivals",
    "New York team",
    "Hot tub inlets",
    "Super Bowl III champs",
    "Jacuzzi set",
    "New York 11",
    "\"West Side Story\" gang",
    "\"West Side Story\" group",
    "Super Bowl III champs"
  ],
  "ided": [
    "Tagged, for short",
    "Picked from a lineup, informally",
    "Pointed out, in a way",
    "Carded, informally",
    "Tagged, informally",
    "Chose from the lineup, in brief",
    "Named, for short",
    "No longer anonymous, for short",
    "Picked out of a lineup, informally",
    "Carded, for short",
    "Fingered",
    "Picked as the one, say",
    "Named, informally",
    "No longer anonymous, in brief",
    "Known to authorities",
    "Named by an informer, informally",
    "Fingered",
    "Put the finger on",
    "Picked out of a lineup",
    "On a list of knowns",
    "Like many perps in lineups",
    "Carded at a club, say",
    "Put the finger on",
    "Fingered, for short",
    "Verified, in a way",
    "Picked out of a lineup",
    "Fingered",
    "Fingered",
    "Fingered, briefly",
    "Carded",
    "Carded, say",
    "Fingered, briefly",
    "Checked a license, informally"
  ],
  "mote": [
    "Crumb",
    "Speck",
    "Speck",
    "Speck",
    "Speck",
    "Tenth word ...",
    "Tiny bit",
    "Speck",
    "Speck of dust",
    "Bit of dust",
    "Speck",
    "Minute bit",
    "Particle",
    "Speck",
    "Speck of dust",
    "Bit of dust",
    "Bit of dust",
    "Minute bit",
    "Bit of dust",
    "No big thing",
    "Speck",
    "Speck",
    "It's no big thing",
    "Flyspeck",
    "Flyspeck",
    "Eye irritant",
    "Speck of dust",
    "Speck"
  ],
  "nods": [
    "Communicates nonverbally, in a way",
    "Subtle endorsements",
    "Subtle signals",
    "\"Even Homer ___\" (old saying)",
    "Some signals from pitchers",
    "Some O.K.s",
    "Go-aheads",
    "Silent signals",
    "Silent approvals",
    "Shows signs of wanting to nap",
    "Inattention indications",
    "Approvals",
    "Signals silently",
    "Drops (off)",
    "Gives a heady response?",
    "Moves a head?",
    "Drifts (off)",
    "Signs an agreement?",
    "Signs of approval",
    "Affirmations to pitchers",
    "Agrees nonverbally",
    "Some yeses",
    "Nonverbal O.K.'s",
    "Go-aheads",
    "Nonverbal O.K.'s",
    "Go-aheads",
    "O.K.'s",
    "Goes to sleep, with \"off\"",
    "Gives a heady answer?",
    "Lets mistakes pass",
    "Silent signals",
    "Some auction bids",
    "Drops off",
    "Agrees, in a way",
    "Silent assents",
    "Signals agreement",
    "Dozes",
    "Actions at auctions",
    "Silent assents",
    "Silent votes",
    "Go-aheads",
    "Drifts off",
    "Some auction bids",
    "Signs of approval",
    "Acceptances",
    "Indicates assent",
    "Signals at Sotheby's",
    "Auction actions",
    "Appears to agree",
    "Silent signals",
    "Signals approval",
    "Go-ahead cues",
    "Signals assent",
    "Dozes off",
    "Sleeps in a sitting position",
    "Shows approval",
    "Auction actions",
    "Silent O.K.'s"
  ],
  "fest": [
    "Ending with love or snooze",
    "Ending with slug or gab",
    "Ending with song or slug",
    "Celebration",
    "Celebration",
    "See 75-Across",
    "Suffix with Oktober or Ozz",
    "Fun or laugh follower",
    "Celebration",
    "Suffix with song",
    "Big celebration",
    "Suffix with Oktober",
    "Suffix with beer or fun",
    "Suffix with song or slug",
    "Slug follower?",
    "Suffix with gab or song",
    "Celebratory suffix",
    "Suffix with Oktober",
    "Rear end of a slug?",
    "Suffix with slug",
    "Holiday suffix",
    "It follows song or slug",
    "Cultural gathering",
    "Gathering suffix",
    "Song ending?",
    "Love or song ending",
    "Slug follower",
    "Celebration suffix",
    "Big whoop-de-do",
    "Ending with song",
    "Suffix with gab or slug",
    "Event suffix",
    "Ending with slug or song",
    "Song or slug follower",
    "Suffix with slug or song"
  ],
  "ccup": [
    "Size in a lingerie shop",
    "Medium bra spec",
    "Size in a lingerie shop",
    "Medium bra specification",
    "Lingerie shop specification",
    "Measure of support?",
    "Size in a lingerie shop",
    "Bikini spec",
    "Measure of support?",
    "Medium size in a lingerie shop",
    "Medium bra size",
    "Size in a lingerie shop"
  ],
  "midi": [
    "Modest skirt",
    "Calf-length skirt",
    "The south of France, with \"le\"",
    "Skirt style",
    "French for \"noon\"",
    "Style of skirt that reaches just below the knees",
    "Noon, in Nantes",
    "Skirt option",
    "Certain conservative skirt",
    "Modest skirt",
    "Skirt style",
    "Noon, in Nantes",
    "Skirt hemmed at the calf",
    "Dress that falls between the knee and ankle",
    "Skirt style",
    "Certain skirt",
    "Fashionable '70s dress",
    "Trend in 1970s fashion",
    "Skirt length",
    "Longish skirt",
    "Longish dress",
    "Calf-length skirt",
    "18-Across in France",
    "Length between mini and maxi",
    "Late-60's fashion item",
    "South of France",
    "Skirt style",
    "Noon, in Nice",
    "Longish skirt",
    "24-Across, in France",
    "Skirt that goes below the knee",
    "Ankle-showing skirt",
    "Kind of dress",
    "High time in Paris",
    "Late-60's fashion item",
    "Longish dress",
    "Longish skirt",
    "Noon, in France",
    "Skirt style",
    "Knee concealer",
    "Certain skirt",
    "Noon, in Nantes",
    "Calf-length dress",
    "Southern France",
    "South of France",
    "Longish dress",
    "The south of France",
    "The south of France",
    "South of France",
    "70's dress"
  ],
  "iona": [
    "College named after a Scottish island",
    "College in New Rochelle, N.Y.",
    "Macbeth's burial isle",
    "College in New Rochelle, N.Y.",
    "College in New Rochelle, N.Y.",
    "College in New Rochelle, N.Y.",
    "Macbeth's Scottish burial isle",
    "Island off the western coast of Scotland",
    "College in Westchester County, N.Y.",
    "Hebrides isle",
    "Where Macbeth is buried",
    "The Gaels of the N.C.A.A.",
    "College in New Rochelle, N.Y.",
    "College just north of New York City",
    "College in New Rochelle, N.Y.",
    "Inner Hebrides isle",
    "Island west of Mull",
    "The Gaels of collegiate sports",
    "Where Macbeth, Malcolm and Duncan are buried",
    "Isle where Macbeth is buried",
    "Isle near Mull",
    "Island off the coast of Scotland",
    "College in New Rochelle, N.Y.",
    "Liberal arts college 20 minutes north of Manhattan",
    "College in New Rochelle, N.Y.",
    "Isle of the Inner Hebrides",
    "Burial site of early Scottish kings",
    "Inner Hebrides isle",
    "College in New Rochelle, N.Y.",
    "Hebrides isle",
    "New Rochelle campus",
    "College in New Rochelle, N.Y.",
    "Burial place of Macbeth",
    "Setting of St. Columba's missionary work",
    "College in New Rochelle, N.Y.",
    "One of Scotland's Inner Hebrides",
    "College in New Rochelle, N.Y.",
    "Hebrides island",
    "Macbeth's burial place",
    "Metro Atlantic Athletic Conference team",
    "Isle of Mull neighbor",
    "College in New Rochelle, N.Y.",
    "New Rochelle college",
    "Skye neighbor",
    "Hebrides isle",
    "College in New Rochelle, N.Y.",
    "Historic Hebrides monastery site",
    "Island near Mull",
    "Member of the Metro Atlantic Athletic Conference",
    "Hebrides island",
    "Home of the Gaels, in college sports",
    "Inner Hebrides island",
    "New Rochelle college",
    "St. Columba's island",
    "School in New Rochelle, N.Y.",
    "New Rochelle college",
    "Island with a 13th-century cathedral",
    "New Rochelle college",
    "New York-area college",
    "New Rochelle college",
    "College in New Rochelle, N.Y.",
    "College in New Rochelle",
    "Macbeth's burial place",
    "Island of the Inner Hebrides",
    "College founded in 1940",
    "New Rochelle college",
    "Hebrides island",
    "Island close to Mull",
    "College in New Rochelle",
    "Suburban New York college",
    "New Rochelle college",
    "New Rochelle college",
    "Scottish isle",
    "Isle of Mull neighbor",
    "Site of St. Columbia's monastery",
    "King Duncan's resting place",
    "Gaels of college athletics",
    "New Rochelle college",
    "Hebrides island",
    "New Rochelle college",
    "Island west of Scotland",
    "New York college",
    "Scottish abbey site",
    "St. Columba's island",
    "Scotland's Monastery of ___",
    "One of the Hebrides",
    "New Rochelle college",
    "Isle near Mull",
    "New Rochelle institution",
    "New Rochelle college",
    "Early center of Celtic learning",
    "New Rochelle college",
    "Scottish isle",
    "New Rochelle college"
  ],
  "lgbt": [
    "Film genre that includes \"Moonlight\" and \"Call Me by Your Name\"",
    "Pride Month initials",
    "Rainbow flag initialism",
    "Pride parade letters",
    "Human Rights Campaign inits.",
    "Pride parade inits.",
    "Pride Month inits.",
    "Prideful grp.?",
    "Letters before Q",
    "Pride parade letters",
    "Letters before Q",
    "Rainbow flag letters",
    "Like the group you're in if you're out, for short",
    "Letters associated with a rainbow flag",
    "Pride parade letters",
    "Letters for Out readers",
    "Letters of \"pride\"",
    "Pride parade letters",
    "Letters associated with a rainbow flag",
    "Modern movement inits."
  ],
  "riel": [
    "Cambodian cash",
    "Cambodian currency",
    "Cambodian bread",
    "Tip of Cambodia?",
    "Cambodian currency",
    "Cambodian currency",
    "Cambodian cash",
    "Cambodian money",
    "Indochinese currency",
    "Cambodian money",
    "Phnom Penh cash",
    "Cambodian currency",
    "100 sen",
    "Phnom Penh money",
    "Cambodian money",
    "100 sen",
    "Capital of Cambodia",
    "Cambodian capital",
    "100 Cambodian sen",
    "Cambodian currency",
    "Cambodian currency",
    "100 sen, in Cambodia",
    "Cambodian currency",
    "Cambodian money",
    "Cambodian money",
    "Monetary unit of Cambodia"
  ],
  "rcas": [
    "Some HDTVs",
    "Some HDTVs",
    "First U.S. color TVs",
    "Some old TVs",
    "Some HDTVs",
    "Some TVs",
    "Some DVD players",
    "Some DVRs",
    "Some Blu-ray players",
    "Some camcorders",
    "Some HDTVs",
    "Some camcorders",
    "Some HDTVs",
    "Some HDTVs",
    "Some DVRs",
    "Some home theater systems",
    "Some stereos",
    "Some TV's",
    "Some VCR's",
    "Some DVD players",
    "Certain TV's",
    "Some HDTV's",
    "Some TV's",
    "Some TV's",
    "Some TV's",
    "Some TV's",
    "Some TV's",
    "Some TV's and VCR's",
    "Some TV's",
    "Some TV's",
    "Some TV's",
    "They were once \"The most trusted name in television\"",
    "Some VCR's"
  ],
  "gino": [
    "Heisman winner Torretta",
    "Vocalist Vannelli",
    "___ Torretta, 1992 Heisman Trophy winner",
    "Vocalist Vannelli",
    "Pop singer Vannelli",
    "Football Hall-of-Famer Marchetti",
    "___ Franco (watch brand)",
    "Singer Vannelli of 1970s-'80s pop",
    "Soft-rock singer Vannelli",
    "N.F.L. Hall-of-Famer Marchetti",
    "\"I Just Wanna Stop\" singer ___ Vannelli",
    "1970's-80's singer Vannelli",
    "1992 Heisman winner ___ Torretta",
    "Italian painter Severini",
    "Italian pet form of John",
    "Vocalist Vannelli",
    "Football Hall-of-Famer Marchetti"
  ],
  "boom": [
    "Bust's counterpart",
    "[There you have it!]",
    "[Out of nowhere!]",
    "Prosperous period",
    "Cousin of pow! or wham!",
    "Good economic news",
    "Dynamite sound",
    "Up time",
    "Film set item",
    "Business owner's delight",
    "Sound made when passing the sound barrier",
    "Ka-blam!",
    "A-B",
    "Sound of ... an explosion",
    "Thriving time",
    "Mike holder on a film set",
    "Prosperous period",
    "More than an uptick",
    "Bust alternative",
    "Prosperity",
    "Prosperous time",
    "Rapid growth",
    "Baby ___",
    "[Sans warning!]",
    "Thriving time",
    "Kapow!",
    "Kin of \"wham!\"",
    "Cannon's sound",
    "Ship?Æs pole",
    "Economic news",
    "Bust's opposite",
    "Economic success"
  ],
  "full": [
    "Like the Rose Bowl, with 92,542 spectators",
    "\"F\" on a gas gauge",
    "With 66-Across, back to the beginning ... or a description of 21- and 48-Down?",
    "Like Santa's bag on Christmas Eve",
    "No longer hungry",
    "Parking lot sign",
    "At capacity"
  ],
  "fool": [
    "April 1 target",
    "Onetime court figure",
    "Pull a fast one on",
    "Tarot card, with \"the\"",
    "Hoodwink",
    "Jester",
    "Jester",
    "Recurring Shakespearean figure",
    "Hornswoggle",
    "Dupe",
    "Not be serious",
    "Object of pity for Mr. T",
    "One pitied by Mr. T",
    "Nincompoop",
    "Half-wit",
    "Jester",
    "One pitied by Mr. T",
    "Toy (with)",
    "Tarot card, with \"The\"",
    "Court entertainer",
    "Put one over on",
    "Hoodwink",
    "Jester",
    "April 1 victim",
    "Flimflam",
    "April honoree",
    "April honoree",
    "Silly billy",
    "Surely he jests",
    "Cozen"
  ],
  "gang": [
    "Squad",
    "The Jets, but not the Giants",
    "Group with a hand sign, maybe",
    "Group of friends",
    "Bunch of friends",
    "Racketeering outfit",
    "Ring",
    "Crew",
    "Bloods or Crips",
    "Blood members, e.g.",
    "Sharks, e.g.",
    "What a tattoo may identify",
    "\"Our ___\"",
    "What graffiti may signify",
    "Turf group",
    "Club familiars",
    "The Jets, e.g.",
    "Crips or Bloods",
    "What a prisoner's tattoo may signify",
    "Jets or Sharks, in \"West Side Story\"",
    "Tattoo identification, maybe",
    "Clique",
    "Kind of warfare",
    "Kind of warfare",
    "Turf defenders",
    "Something that may be busted",
    "Clique",
    "Band",
    "Thieves' group",
    "Jets or Sharks",
    "The Daltons, for example",
    "The Jets, for one",
    "Homeboys' \"fraternity\"",
    "Police target",
    "Street band",
    "Problem group",
    "The Daltons, e.g.",
    "Grips or Bloods"
  ],
  "hath": [
    "\"Hell ___ no fury ...\"",
    "Keepeth",
    "\"Thirty days ___ September ...\"",
    "Possesses, biblically",
    "\"Poison, I see, ___ been his timeless end\": Shak.",
    "Possesses, to the Bard",
    "Verb in the first telegraph message",
    "Has in an old form?",
    "\"Thirty days ___ September ...\"",
    "Possesses, once",
    "Biblical verb",
    "Owneth",
    "\"___ Romeo slain himself?\": Juliet",
    "Verb in the world's first telegraph message",
    "See 53-Down",
    "Owns, in the Bible",
    "Owns, in the Bible",
    "\"She ___ Dian's wit\": Romeo",
    "\"Hell ___ no fury ...\"",
    "Doth own",
    "Biblical verb",
    "\"Hell ___ no fury ?à\"",
    "Biblical verb",
    "Biblical verb",
    "Biblical verb",
    "Possesses, old-style",
    "\"What ___ God wrought?\"",
    "Shakespearean verb",
    "Biblical verb",
    "Biblical verb",
    "\"What ___ God wrought?\"",
    "\"... ___ no fury ...\"",
    "Biblical verb",
    "Possesses, old-style",
    "Shakespearean verb",
    "\"Hell ___ no fury...\"",
    "\"Thirty days ___ November...\"",
    "Owns, once",
    "\"What___God wrought!\"",
    "Biblical verb",
    "Doth possess"
  ],
  "eroo": [
    "Suffix with switch",
    "Suffix with switch",
    "Old switch extension",
    "Sock attachment?",
    "Suffix with stink",
    "Suffix with switch",
    "Suffix with switch",
    "Suffix with smack",
    "Switch attachment?",
    "Old switch attachment?",
    "Switch ending",
    "Suffix with stink",
    "Old switch attachment?",
    "Switch add-on",
    "Sock attachment?",
    "Suffix with switch",
    "Switch finish?",
    "Suffix with sock",
    "Suffix with smack",
    "Suffix with stink",
    "Switch attachment?",
    "Switch add-on",
    "Suffix with switch",
    "Suffix with switch",
    "End of the old switch",
    "Suffix with smack",
    "Suffix with smack",
    "Suffix with switch",
    "Suffix with switch",
    "Suffix with sock",
    "Suffix with switch",
    "Suffix with switch",
    "Suffix with smash",
    "Suffix with jack",
    "Suffix with smack",
    "Suffix with switch",
    "Switch add-on",
    "Suffix with smack",
    "Suffix with sock",
    "Suffix with smack",
    "Suffix with stink",
    "Switch back?",
    "Switch extension",
    "Switch ending",
    "Switch add-on",
    "Switch add-on",
    "Switch end",
    "Switch add-on",
    "Suffix with switch",
    "Ending with switch",
    "Switch ending",
    "Switch suffix",
    "Switch ending",
    "Slangy suffix",
    "Slangy suffix",
    "Ending with switch",
    "Suffix with switch",
    "Jocular suffix",
    "Suffix with switch",
    "Suffix with switch",
    "Switch suffix",
    "Ending for switch or smack",
    "Slangy suffix",
    "Switch ending",
    "Suffix with stink",
    "Suffix with switch",
    "Suffix for stink"
  ],
  "issa": [
    "Actress Rae of \"Insecure\"",
    "Actress Rae",
    "\"Insecure\" creator Rae",
    "Rae who created \"The Misadventures of Awkward Black Girl\"",
    "California congressman Darrell",
    "What California congressman heads the House Oversight Committee?",
    "House committee chairman Darrell",
    "Rep. Darrell of California"
  ],
  "elea": [
    "Home of the ancient philosopher Zeno",
    "Where Zeno taught",
    "Ancient Greek birthplace of Parmenides",
    "Philosopher Zeno of ___",
    "Zeno of ___ (philosopher)",
    "Zeno's birthplace",
    "Zeno of ___",
    "Birthplace of the philosopher Parmenides",
    "Home of Zeno and Parmenides",
    "Zeno's home",
    "Colony in ancient Magna Graecia",
    "Home of ancient Greek scholars",
    "Zeno's home",
    "Whence Parmenides",
    "Zeno's home",
    "Home to a school of pre-Socratic philosophers",
    "Whence Zeno",
    "Zeno's home",
    "Home of Zeno",
    "Zeno's locale",
    "Magna Graecia colony",
    "Birthplace of Parmenides",
    "Philosopher Zeno of ___",
    "Home of Parmenides",
    "Philosopher Zeno of ___",
    "Whence Zeno",
    "Home of the philosopher Parmenides",
    "Parmenides of ___",
    "Parmenides?Æ home",
    "Zeno's home",
    "Zeno of ___",
    "Zeno's home",
    "Zeno's home",
    "Zeno of ___",
    "Home of Zeno",
    "Zeno's home",
    "Xenophanes taught there",
    "Zeno's home",
    "Parmenides' home",
    "Zeno's home",
    "Philosopher Zeno of ___",
    "Where Zeno taught",
    "Zeno of ___",
    "Zeno's birthplace",
    "Parmenides' home",
    "City in ancient Lucania",
    "Zeno's home",
    "Zeno's home",
    "Zeno's home",
    "Where Zeno and Parmenides taught",
    "Home of Zeno",
    "Zeno of ___ (Greek logician)",
    "Parmenides's birthplace",
    "Zeno's home",
    "Parmenides's birthplace"
  ],
  "hiss": [
    "Blow off steam, say",
    "Radiator sound",
    "Cat sound",
    "Bad response from an audience",
    "Poor reception?",
    "Sound of contemptuous disapproval",
    "Snake warning",
    "Cat-meets-dog sound",
    "Cottonmouth's warning",
    "Feline's warning",
    "Sibilate",
    "Radiator sound",
    "Blow off steam?",
    "Snake sound",
    "Snake's warning",
    "Kettle sound",
    "Angry cat's sound",
    "Audible reproof",
    "What letting off steam might result in",
    "Unwelcome reception",
    "Snake sound",
    "*Let off some steam?",
    "Snake's sound",
    "Sound of crowd disapproval",
    "Moccasin sound",
    "Cat's warning",
    "Villain's reception",
    "Serpentine sound",
    "Cobra's greeting",
    "Express disapproval",
    "Cat sound",
    "Angry audience reaction",
    "Sign of hostility",
    "Villain's reception",
    "Greeting for a villain",
    "Blow off steam?",
    "Unwarm welcome",
    "Radiator sound",
    "Unwarm welcome",
    "Sound like a snake",
    "Express displeasure",
    "Unhappy fan's reaction",
    "Warning sound",
    "Fuse sound",
    "Snake's warning",
    "Greeting for the villain",
    "Vent sound",
    "Ophidian sound",
    "Heckle, in a way",
    "Greeting for Legree",
    "Sound like thisss",
    "Boo follower",
    "Defendant of 1949",
    "Unwelcome greeting",
    "Greet uncivilly",
    "Coal-dousing sound",
    "Serpentine greeting",
    "Leak indicator",
    "Infamous Alger",
    "Subject of the book \"Perjury\"",
    "Sibilate",
    "Serpent song"
  ],
  "ariz": [
    "Nev. neighbor",
    "Tombstone locale: Abbr.",
    "PHX airport locale: Abbr.",
    "Only non-Southern state won by the G.O.P. in '64",
    "Calif. neighbor",
    "Home state of the 1964 and 2008 Rep. presidential candidates",
    "Phoenix's home: Abbr.",
    "Mex. neighbor",
    "48th state: Abbr.",
    "Home of Snowflake, which, ironically, gets very little snow: Abbr.",
    "Phoenix's home: Abbr.",
    "London Bridge's home now: Abbr.",
    "Home of the Diamondbacks: Abbr.",
    "Phoenix's state: Abbr.",
    "McCain's state: Abbr.",
    "Mex. neighbor",
    "Sen. McCain's state: Abbr.",
    "Home of Organ Pipe Cactus Natl. Mon.",
    "Nev. neighbor",
    "Neighbor of Mex.",
    "N.M. neighbor",
    "N.M. neighbor",
    "Calif. neighbor",
    "Mesa's location: Abbr.",
    "Grand Canyon state: Abbr.",
    "U.S. terr until 1912",
    "ZIP codes starting 85- and 86-: Abbr."
  ],
  "enes": [
    "Hydrocarbon suffixes",
    "Spanish letters with (or without) tildes",
    "6'11\" Kanter of the N.B.A.",
    "The Spanish word \"nacion\" has two",
    "Chemical endings",
    "Chemical endings",
    "Hydrocarbon suffixes",
    "Hydrocarbon suffixes",
    "Hydrocarbon endings",
    "Hydrocarbon suffixes",
    "Chemical endings",
    "Chemical suffixes",
    "Hydrocarbon suffixes",
    "Hydrocarbon endings",
    "Chemical endings",
    "Chemical endings",
    "Hydrocarbon suffixes",
    "Hydrocarbon suffixes",
    "Chemical endings",
    "Hydrocarbon endings",
    "Hydrocarbon suffixes",
    "Hydrocarbon suffixes",
    "Chemical endings",
    "Endings for hydrocarbons",
    "Chemical endings",
    "Hydrocarbon suffixes",
    "Ethyl endings",
    "Chemical endings",
    "Chemical suffixes",
    "Chemical endings"
  ],
  "prev": [
    "Before: Abbr.",
    "<<< button: Abbr.",
    "\"Go back\" button abbr. on some remotes",
    "Before: Abbr.",
    "Earlier: Abbr.",
    "Prior: Abbr.",
    "Before: Abbr.",
    "Before: Abbr.",
    "Before: Abbr.",
    "Beforehand: Abbr."
  ],
  "deci": [
    "Metric prefix",
    "Liter lead-in",
    "Prefix with liter",
    "Numerical prefix",
    "Prefix with liter",
    "Liter lead-in",
    "Numerical prefix",
    "Numerical prefix",
    "Prefix with bel",
    "Metric system prefix",
    "Prefix with bel",
    "Numerical prefix",
    "Tenth: Prefix",
    "Tenth: Prefix",
    "Numerical prefix",
    "Tenth: Prefix",
    "Prefix with meter",
    "Tenth: Prefix",
    "Metric prefix",
    "Prefix with bel",
    "One-tenth: Prefix",
    "Metric prefix",
    "Metric prefix",
    "Prefix with liter",
    "Prefix with liter",
    "Prefix with meter or bel",
    "Metric prefix",
    "Numerical prefix",
    "Metric prefix"
  ],
  "cees": [
    "Followers of bees",
    "They'll earn you a 2.0",
    "Hitchcock triple feature?",
    "Chocolate chip cookie starters?",
    "Fair grades",
    "Seventy-somethings?",
    "2.0s",
    "So-so marks",
    "Broccoli bits?",
    "Middling grades",
    "More than a quarter of academic circles?",
    "Comedic duo?",
    "Grades in the mid-70s",
    "Cosmetic extremes?",
    "Broccoli centers?",
    "Piccolo duet?",
    "Middling grades",
    "Middling grades",
    "Stucco ingredients?",
    "Middling marks",
    "Non-dean's list grades",
    "Not very high marks",
    "2.0 grades",
    "Middling marks",
    "Bee chasers",
    "Mediocre marks",
    "Occult characters?",
    "Components of a 2.0 average",
    "Crescent shapes",
    "They're in circulation",
    "Straight ones = 2.0",
    "Copyright indicators",
    "Couple in contact?",
    "Certain clamp shapes",
    "Average grades",
    "Unexciting marks",
    "So-so grades",
    "Civic boundaries?",
    "Medium grades",
    "So-so grades",
    "Copyright symbols",
    "2.0 grades",
    "Average marks",
    "So-so grades",
    "Mediocre grades",
    "Mediocre marks"
  ],
  "maes": [
    "Fannie ___ (some securities)",
    "Fannie ___ (securities)",
    "Actresses West and Clarke",
    "Fannie ___ (some investments)",
    "Fannie ___ (some securities)",
    "Fannie ___ (securities)",
    "Fannie ___ (investing options)",
    "Fannie ___ (securities)",
    "Actresses Murray and Clark",
    "Fannie ___ (securities)",
    "Actress Marsh and others",
    "West and Clarke",
    "Old-time actress Murray and others",
    "Fannie___ (securities)",
    "Ginnie and Fannie of finance",
    "Artist Nicolaes ___"
  ],
  "xman": [
    "Wolverine, for example",
    "Comics mutant",
    "Comics figure with extraordinary powers",
    "Cyclops, e.g., in comic books",
    "Marvel Comics hero",
    "Marvel mutant superhero",
    "Marvel Comics hero"
  ],
  "gpas": [
    "Nos. representing years' worth of letters",
    "Transcript figs.",
    "College figs.",
    "College application figs.",
    "Transcript figs.",
    "Application figs.",
    "University figs.",
    "Coll. application figures",
    "Figs. on some resumes",
    "College figs.",
    "Info on college applications",
    "Transcript nos.",
    "Transcript figs.",
    "Ranking nos.",
    "College application nos.",
    "Transcript stats",
    "Transcript figs.",
    "Transcript stats",
    "3.7 and 4.0, e.g."
  ],
  "bins": [
    "Household organizers",
    "Items in an airport security line",
    "Coal containers",
    "Spots for luggage",
    "Trash holders",
    "They're used for storage",
    "Trash receptacles",
    "Hampers, say",
    "Used bookstore containers",
    "Alternatives to racks",
    "Rubbermaid wares",
    "Mail room sights",
    "Recycling containers",
    "Bargain basement lineup",
    "Lost-and-found containers",
    "Grain containers",
    "Plastic containers",
    "Hoppers, e.g.",
    "Trash sites",
    "Coal deposits?",
    "Grain holders",
    "Trash collectors?",
    "Coal holders",
    "*Seventh step",
    "Containers",
    "Storage places",
    "Hampers",
    "Storage containers"
  ],
  "vain": [
    "Given to self-reflection?",
    "Peacockish",
    "Fruitless",
    "Self-involved",
    "Bigheaded",
    "Self-absorbed",
    "Pointless",
    "Marked by futility",
    "Futile",
    "Futile",
    "Fond of self-reflection?",
    "Self-absorbed",
    "Useless",
    "See 62-Across",
    "Like peacocks",
    "Futile",
    "Conceited",
    "Self-absorbed",
    "Conceited",
    "Futile",
    "Mirror-conscious",
    "Puffed up",
    "Conceited",
    "Self-involved",
    "Like some attempts",
    "In love with oneself",
    "Stuck on oneself",
    "Puffed up",
    "Hardly humble",
    "Full of oneself",
    "Narcissistic"
  ],
  "dull": [
    "Lacking vivacity",
    "Yawn-inducing",
    "Like some finishes",
    "Yawn-provoking",
    "Dimwitted",
    "Yawn-inducing",
    "Uninteresting",
    "Uninteresting",
    "Lackluster",
    "Lackluster",
    "Like dishwater",
    "Blunt",
    "Dishwaterlike",
    "Tedious"
  ],
  "nala": [
    "2019 voice role for Beyonce",
    "Character voiced by Beyonce in 2019's \"The Lion King\"",
    "Simba's mate in \"The Lion King\"",
    "\"The Lion King\" queen",
    "Beyonce's role in 2019's \"The Lion King\"",
    "Beyonce film role",
    "Voice role for Beyonce in 2019's \"The Lion King\"",
    "Simba's mate in 23-Down",
    "\"Queen\" on Broadway",
    "Singer of the song \"Shadowland\" in \"The Lion King\"",
    "Lion queen in \"The Lion King\"",
    "Lion in \"The Lion King\"",
    "8-Across's mate",
    "\"The Lion King\" lion",
    "Lion in \"The Lion King\"",
    "Simba's mate in \"The Lion King\"",
    "\"The Lion King\" queen",
    "\"The Lion King\" lion",
    "Simba's mate",
    "\"The Lion King\" queen",
    "\"The Lion King\" lioness",
    "Simba's best friend in \"The Lion King\"",
    "\"The Lion King\" lioness",
    "Pride Lands queen",
    "Simba's mate",
    "Queen in \"The Lion King\"",
    "Queen in \"The Lion King\"",
    "\"The Lion King\" role",
    "Simba's love in \"The Lion King\"",
    "Simba's mate",
    "Simba's mate in \"The Lion King\"",
    "\"The Lion King\" lioness",
    "\"The Lion King\" daughter",
    "\"The Lion King\" queen",
    "Simba's mate, in \"The Lion King\"",
    "Simba's love in \"The Lion King\"",
    "\"The Lion King\" lion",
    "Disney lioness",
    "Queen in \"The Lion King\"",
    "\"The Lion King\" lioness",
    "\"The Lion King\" lion",
    "\"The Lion King\" lion",
    "Disney lion",
    "Lion in \"The Lion King\"",
    "\"The Lion King\" lion",
    "Simba's \"Lion King\" love"
  ],
  "romp": [
    "Play like a puppy",
    "Blowout",
    "Lighthearted movie",
    "Blowout",
    "Easy win",
    "Lopsided win",
    "Easy win",
    "Cavort",
    "Frolic",
    "Runaway",
    "Frolic",
    "Frolic",
    "Cavort",
    "49-0 game, e.g.",
    "Easy win",
    "Frolic",
    "Rollick or frolic",
    "Nail-biter's opposite",
    "Cakewalk",
    "Walkaway",
    "Easy win",
    "Many a Marx Brothers movie",
    "Walkaway",
    "Cavort",
    "Frolic",
    "Win going away",
    "Cakewalk",
    "Madcap comedy",
    "Effortless pace",
    "Easy win",
    "Win big",
    "20-0 win, e.g.",
    "Frolic",
    "Hardly a close contest",
    "Easy win",
    "Walkover",
    "Runaway",
    "Easy win",
    "Frolic",
    "Cavort",
    "Cakewalk",
    "Gambol",
    "Frolic",
    "Win easily, in the sports pages",
    "Gambol",
    "Disport",
    "Cavort",
    "Frolic",
    "Easy victory"
  ],
  "long": [
    "Word interpreted literally in completing four of this puzzle's answers",
    "Word before or after run",
    "Past the baseline, in tennis",
    "What \"L\" stands for in pant sizes",
    "Word with shot or suit",
    "Like marathons and maxiskirts",
    "Word before ball or shot",
    "Thirst",
    "Past the baseline, in tennis",
    "Like \"War and Peace,\" famously",
    "New York's ___ Island",
    "Key word #3",
    "Protracted",
    "Pine (for)",
    "Tennis court determination",
    "___ John Silver",
    "Extended",
    "Like the \"i\" in \"like\"",
    "Like books in two volumes",
    "Like some vowels and pants",
    "Protracted",
    "Extended",
    "Past the golf pin, say",
    "Time-consuming",
    "Pants spec",
    "How some quarterbacks go",
    "Dragged out",
    "Dragged out",
    "Like \"Lawrence of Arabia\"",
    "Yearn (for)",
    "Suit spec",
    "Like a Hail Mary pass",
    "Beyond the end line",
    "Men's jacket size",
    "Trousers spec",
    "Like \"War and Peace\"",
    "Like a hippie's hair",
    "Yearn",
    "Overshot",
    "Kind of division",
    "Extended",
    "Protracted",
    "Tennis referee's cry",
    "Over the limit",
    "Like Chandler's \"Goodbye\"",
    "\"The Kingfish\""
  ],
  "guru": [
    "Whiz",
    "Expert",
    "Spiritual guide",
    "Spiritual leader often pictured sitting cross-legged",
    "Expert",
    "Maven",
    "Maven",
    "Maven",
    "Expert",
    "Expert",
    "Spiritual teacher of a 42-Across",
    "Teacher sitting cross-legged, maybe",
    "Maven",
    "Wise guy",
    "Wise one",
    "Wise one",
    "Whiz",
    "Ashram leader",
    "Expert",
    "Maven",
    "Expert",
    "Wise guy",
    "Wise one",
    "Spiritual guide",
    "Old pro",
    "Knowledgeable one",
    "Wise guy",
    "Bill Gates, to some",
    "Expert",
    "Mentor",
    "Expert",
    "Wizard",
    "Mentor",
    "Wise one",
    "Know-it-all",
    "Expert",
    "Swami",
    "Mentor",
    "Whiz",
    "Timothy Leary, to some",
    "Mentor",
    "Maven",
    "Expert"
  ],
  "loos": [
    "Places to go in England",
    "Heads of Parliament?",
    "Johns across the pond",
    "Elton's johns",
    "English johns",
    "Heathrow facilities",
    "British thrones?",
    "London lavs",
    "Heads for Britain?",
    "Eton johns",
    "British heads",
    "Going places?",
    "English facilities",
    "Elton's johns",
    "\"Happy Birthday\" playwright",
    "Lorelei Lee's creator",
    "London privies",
    "\"Gentlemen Prefer Blondes\" writer Anita",
    "Stops in London",
    "British \"rest stations\"",
    "\"A Girl Like I\" author",
    "Lorelei Lee's creator",
    "British conveniences",
    "London \"stops\"",
    "Facilities, in Falmouth",
    "Novelist Anita",
    "\"Gentlemen Prefer Blondes\" author",
    "Flat parts",
    "\"The Talmadge Girls\" author",
    "Creator of Lorelei Lee",
    "Water closets",
    "?ôGigi?ö playwright, 1951",
    "Writer Anita",
    "\"The Talmadge Girls\" author",
    "Writer Anita",
    "Creator of Lorelei Lee"
  ],
  "amok": [
    "Wildly",
    "Chaotic way to run",
    "Run ___ (go wild)",
    "Bad way to run",
    "Wildly",
    "One way to run",
    "With frenzy",
    "Every which way",
    "Run ___",
    "Frenzied way to go",
    "One way to run",
    "Wild way to run",
    "Bad way to run",
    "In a frenzy",
    "Without restraint",
    "Out of control",
    "Hectically",
    "One way to run",
    "Run ___ (rampage)",
    "Running ___",
    "Out of control",
    "Uncontrollably",
    "Run ___",
    "Higgledy-piggledy",
    "Higgledy-piggledy",
    "One way to run",
    "In a frenzy",
    "Run ___ (go wild)",
    "In a frenzy",
    "See 5-Down",
    "Every which way",
    "Run ___ (go wild)",
    "Bad way to run",
    "Running ___",
    "In a frenzy",
    "Wildly",
    "Helter-skelter",
    "Frenzied",
    "Crazedly",
    "One way to run",
    "Totally out of control",
    "Confusedly",
    "In a frenzy",
    "Wild",
    "Berserk",
    "Wildly",
    "Madly",
    "Crazedly",
    "Wild",
    "Bad way to run",
    "Every which way",
    "Uncontrollably",
    "Not a good way to run",
    "Wild",
    "Wildly",
    "In a frenzy",
    "One way to run",
    "Out of control",
    "Every which way",
    "Frenzied",
    "Berserkly",
    "In a frenzied way",
    "Wild",
    "Running ___",
    "Out of control",
    "One way to run",
    "Frenziedly",
    "Run ___ (go crazy)",
    "One way to have gone",
    "One way to run",
    "Frenziedly",
    "Berserk",
    "One way to run",
    "Every which way",
    "Crazily",
    "One way to run",
    "Frenziedly",
    "Out of control",
    "Run ___ (go wild)",
    "Without control",
    "In a frenzy",
    "Run ___ (go wild)",
    "Frenziedly",
    "In a frenzy",
    "How one might run",
    "Out of control",
    "See 50-Across",
    "In a frenzy",
    "Uncontrolled",
    "Run ___ (go crazy)",
    "In a frenzy",
    "In a frenzy",
    "Crazily",
    "In a frenzy",
    "In a frenzy",
    "One way to run",
    "One way to run",
    "Run ___ (go off course)",
    "Frenziedly",
    "Out of control",
    "One way to run",
    "Rampaging",
    "How not to run",
    "Uncontrollably",
    "One way to run",
    "In a frenzy",
    "In a frenzied fashion",
    "One way to run",
    "One way to run",
    "Running wild"
  ],
  "warn": [
    "Caution",
    "Caution",
    "Put on guard",
    "Tip off",
    "Not punish just yet",
    "Caution",
    "Give a heads-up",
    "Alert",
    "Yellow-card, e.g.",
    "Say \"It's a trap!,\" say",
    "Give a heads-up",
    "Tip off",
    "Show a yellow card, e.g.",
    "Premonish",
    "Serve notice",
    "Alert to danger",
    "Give a heads-up",
    "Give an alert",
    "Tip off",
    "Tip off",
    "Tip off",
    "Put on notice",
    "Raise a red flag",
    "Tip off",
    "Caution",
    "Alert",
    "Alert",
    "Counsel, perhaps",
    "Alert"
  ],
  "fond": [
    "Like some farewells",
    "A fan (of)",
    "Enamored (of)",
    "Affectionate, as a farewell",
    "Dear, as a memory",
    "Like some farewells",
    "Kind of memory",
    "Enamored (of)",
    "Like some memories",
    "Sweet on, with \"of\"",
    "Like some memories",
    "Affectionate",
    "Adoring, with \"of\"",
    "Enamored (of)",
    "Affectionate",
    "___ du Lac, Wis.",
    "Dear, as memories"
  ],
  "fawn": [
    "Babe in the woods",
    "Baby deer",
    "Be a toady",
    "Babe in the woods",
    "Forest newcomer",
    "Gush (over)",
    "Gush (over)",
    "Baby deer",
    "Forest issue",
    "Young Bambi",
    "Yellowish brown",
    "Babe in the woods",
    "Babe in the woods"
  ],
  "inks": [
    "Cartoonists' supplies",
    "Gives a tattoo to",
    "Calligraphers' choices",
    "Signs",
    "Signs, as a contract",
    "Works on the strip?",
    "Color printer purchases",
    "Signs, as a contract",
    "Stationery store stock",
    "Finalizes, as a contract",
    "Cartridge fillers",
    "Calligraphers' supplies",
    "Writes indelibly",
    "Tattoo parlor supply",
    "Pens' contents",
    "Signs",
    "Signs",
    "Signs, as a contract",
    "Tattooist's supply",
    "Contents of some cartridges",
    "Prepares, as the presses",
    "Signs",
    "Closes, as a deal",
    "Tattooist's stock",
    "Finishes, as cartoon artwork, with \"in\"",
    "Puts one's John Hancock on",
    "Printers' supplies",
    "Signs, as a contract",
    "Tattooist's stock",
    "Signs",
    "Signs",
    "Stationer's stock",
    "Signs, as a contract",
    "Finalizes, as a deal",
    "Finalizes, in a way",
    "Squid squirts",
    "Signs",
    "Squid's squirts",
    "Signs, as a contract",
    "Signs",
    "Squid defenses",
    "Squid's squirts",
    "Some are indelible",
    "Signs",
    "Signs",
    "Signs, as a contract",
    "Seals, as deals",
    "Prepares the printing press",
    "Signs",
    "Finalizes the deal",
    "Signs on the dotted line",
    "Endorses",
    "Signs, in away",
    "Prepares the presses"
  ],
  "lynn": [
    "Loretta with the #1 country hit \"Coal Miner's Daughter\"",
    "Loretta who was the subject of \"Coal Miner's Daughter\"",
    "Country singer Loretta",
    "City between Boston and Gloucester",
    "___ Swann, Super Bowl X M.V.P.",
    "Loretta who sang \"Coal Miner's Daughter\"",
    "Boston suburb",
    "\"For Better or for Worse\" cartoonist Johnston",
    "Loretta who sang \"Don't Come Home A' Drinkin' (With Lovin' on Your Mind)\"",
    "One of the Redgrave sisters",
    "Boston suburb",
    "Footballer-turned-politician Swann",
    "Port on Massachusetts Bay",
    "Country singer on whom \"Coal Miner's Daughter\" is based",
    "Boston suburb",
    "City between Boston and Salem",
    "Country singer Anderson",
    "Boston suburb",
    "Massachusetts city with a harbor",
    "Spacek biopic role",
    "Figure skater Janet",
    "\"For Better or for Worse\" cartoonist Johnston",
    "Boston suburb",
    "City between Boston and Salem",
    "Boston suburb",
    "Newswoman Sherr",
    "City northeast of Boston",
    "Boston suburb",
    "Loretta who sang \"You Ain't Woman Enough\"",
    "Boston suburb",
    "Actress Redgrave",
    "Boston suburb",
    "Boston suburb"
  ],
  "hola": [
    "Greeting south of the border",
    "Greeting in Granada",
    "Greeting in Guatemala",
    "Spanish greeting",
    "Spanish greeting",
    "Counterpart of 6-Down",
    "Spanish greeting",
    "Hi, on Hispaniola",
    "\"Buenos dias!\"",
    "Greeting abroad",
    "Spanish welcome",
    "Barrio greeting",
    "Chihuahua greeting",
    "Greeting south of the border",
    "\"Hey, Jose!\"",
    "\"Hi, Jose!\"",
    "Spanish greeting",
    "Greeting in Granada",
    "Guadalajara greeting",
    "Ciao, in Chile",
    "Guadalajara greeting",
    "Overseas greeting",
    "Opposite of adios",
    "Granada greeting",
    "Spanish welcome",
    "15-Across, in Spain",
    "Juan's greeting",
    "Granada greeting",
    "Granada greeting",
    "Hidalgo hi"
  ],
  "sawn": [
    "Like cut logs",
    "Like logs that have been cut",
    "Cut into planks, say",
    "Like lumber",
    "Cut, as a log",
    "Cut",
    "Cut, as logs",
    "Cut",
    "Cut into planks, say",
    "Like logs, quaintly",
    "Cut, as logs",
    "Like much lumber",
    "Like much lumber",
    "Like lumber in a mill",
    "Cut",
    "Cut",
    "Cut, as a log",
    "Cut",
    "Cut with a crosscut",
    "Like logs",
    "Like logs",
    "Cut",
    "Cut",
    "Cut, as a log",
    "Cut, as logs",
    "Like fireplace logs",
    "Cut up"
  ],
  "baby": [
    "Word before boom or blues",
    "Pamper",
    "Midwife's delivery",
    "Bundle of joy",
    "Coddle",
    "\"And ___ makes three\"",
    "Infant",
    "Word before \"Boy,\" \"Love\" and \"Come Back\" in titles to #1 songs",
    "See 7-Down",
    "Newborn",
    "Word before the starts of 16-, 36- and 58-Across and 10-, 14- and 33-Down",
    "One often needing a change",
    "Buggy rider",
    "Word with boom or tooth",
    "Mollycoddle",
    "Snookums",
    "Diaper wearer",
    "Stork's delivery",
    "Pebbles, e.g., on \"The Flintstones\"",
    "Kind of grand",
    "Snookums",
    "1983 Maltby/Shire musical"
  ],
  "tude": [
    "Resentful and hostile manner, informally",
    "Lip, in slang",
    "Impudence, slangily",
    "Sass, in slang",
    "Brashness, informally",
    "Swagger, slangily",
    "Sassiness, slangily",
    "Chip on one's shoulder, say",
    "Cheekiness, slangily",
    "Suffix with 13-Down"
  ],
  "lawn": [
    "What a sprinkler may sprinkle",
    "What a sidewalk may abut",
    "Grass roots development?",
    "It may be manicured",
    "It may be manicured",
    "It extends about a yard",
    "___ tennis",
    "Croquet locale",
    "Wimbledon surface",
    "White House adjunct",
    "Cemetery expanse",
    "Home turf?",
    "Croquet site",
    "Homeowner's pride",
    "\"For sale\" sign site",
    "Croquet area",
    "Bowling green, e.g.",
    "Place for an Easter egg hunt",
    "Croquet area",
    "Bowling green",
    "Place for an egg roll",
    "Suburban green",
    "Croquet site",
    "Croquet area",
    "Court material",
    "Croquet site",
    "Bowling green",
    "Croquet field",
    "Suburban sight",
    "Spot for tennis",
    "White House area",
    "White House area",
    "Mowing site"
  ],
  "uhuh": [
    "[Head shake]",
    "\"Nah\"",
    "\"No way, Jose!\"",
    "\"Nope!\"",
    "Thumbs-down response",
    "\"Not gonna happen!\"",
    "\"No way, Jose\"",
    "\"No way!\"",
    "\"Nah!\"",
    "\"Forget it!\"",
    "\"Ain't gonna happen\"",
    "\"Not gonna happen\"",
    "\"Don't even think about it\"",
    "\"No way!\"",
    "\"Ain't gonna happen!\"",
    "\"Dream on!\"",
    "\"Forget it!\"",
    "\"No way, no how\"",
    "Guttural refusal",
    "\"Never gonna happen\"",
    "\"No way!\"",
    "\"Naw\"",
    "\"That's a lie!\"",
    "\"Nope\"",
    "\"No way!\"",
    "\"Nope!\"",
    "\"Nah!\"",
    "Nope",
    "\"No way\"",
    "\"No sirree\"",
    "\"No way!\"",
    "\"Nah!\"",
    "\"Nah!\"",
    "\"Don't think so\"",
    "Slangy denial",
    "\"Nothin' doin' \"",
    "\"No way!\"",
    "\"No way!\"",
    "\"Nope!\"",
    "\"No way!\"",
    "\"Nope\"",
    "\"Nah!\"",
    "\"No dice!\"",
    "\"No way\"",
    "Nope"
  ],
  "nyny": [
    "Abbr. before the ZIP code 10001",
    "Big Apple inits.",
    "Letters in a Big Apple address",
    "It contains M.S.G.: Abbr.",
    "Letters that might precede 10001",
    "\"30 Rock\" setting, briefly",
    "Part of Rockefeller Ctr.'s address",
    "Big Apple ltrs.",
    "ZIP code 10001 locale: Abbr.",
    "Part of a Fifth Ave. address",
    "Park Ave. address part",
    "Part of a Mad. Ave. address",
    "Abbr. before ZIP code 10001",
    "Address with the ZIP code 10001",
    "Big Apple inits.",
    "Part of MOMA's address",
    "Part of an Empire State Bldg. address",
    "Part of a Manhattan address: Abbr.",
    "Abbr. on mail to ZIP code 10001",
    "Manhattan address abbr.",
    "Manhattan address: Abbr.",
    "Address with ZIP code 10001: Abbr."
  ],
  "haze": [
    "Smog, e.g.",
    "Visibility reducer",
    "Dreamy state",
    "Mental blur",
    "The night before, to a hard partier?",
    "Confused state of mind",
    "Confusion",
    "Hangover feeling",
    "Confusion",
    "Run through the gantlet, say",
    "Break a pledge?",
    "Smog",
    "Smog, e.g.",
    "Smog, e.g.",
    "Fog or smog",
    "Give a paddling, maybe",
    "Jimi Hendrix's \"Purple ___\"",
    "Sight blocker",
    "Sun block",
    "Put through demeaning rituals",
    "Skyline obscurer",
    "Smog",
    "Some confusion",
    "Part of many a summer forecast",
    "\"Purple ___\" (song played at Woodstock)",
    "Harass, in a way",
    "Mist",
    "Part of a summer forecast",
    "Demean, as pledges",
    "Cause of low visibility",
    "Confusion",
    "Run pledges through the gantlet, say",
    "Smog",
    "Smog",
    "Harass the pledges",
    "Visibility problem",
    "Smog",
    "Confused thoughts"
  ],
  "naes": [
    "Scottish refusals",
    "Turndowns across the Atlantic",
    "Dundee disavowals",
    "Scottish negatives",
    "Highlands refusals",
    "Dundee denials",
    "Paisley refusals",
    "Scottish turndowns",
    "Glasgow negations",
    "Scottish refusals",
    "Scottish rejections",
    "Highlands denials",
    "Glasgow denials",
    "Gretna Green rebuffs",
    "Caledonian contradictions",
    "Scottish refusals",
    "Scots' turndowns",
    "Glasgow negatives",
    "Scottish turndowns",
    "Dundee denials",
    "Scottish refusals",
    "Scottish denials",
    "Rebuffs overseas",
    "Dundee denials",
    "Words from stubborn Scots",
    "Highlands negatives",
    "Dundee turndowns"
  ],
  "gats": [
    "Pieces of gangs",
    "Prohibition-era guns",
    "Heaters",
    "Gangland weapons, quaintly",
    "Mob pieces",
    "Heaters",
    "Old gang weapons",
    "Street heaters",
    "Gangsters' guns",
    "Hoods may conceal them",
    "G-men's weapons",
    "Hit makers?",
    "Heaters",
    "Mobsters' guns",
    "Hood rods",
    "Gangster weapons",
    "Gangsters' guns",
    "Gangster pieces",
    "Saturday night specials",
    "Roscoes",
    "Mobsters' guns"
  ],
  "bade": [
    "Wished",
    "Commanded",
    "Commanded",
    "Instructed",
    "Requested",
    "Directed",
    "Urged",
    "Uttered, as a farewell",
    "Ordered",
    "Ordered",
    "Requested",
    "Wished",
    "Wished",
    "Commanded",
    "Expressed, as a farewell",
    "Wished",
    "Invited",
    "Gave an order to",
    "Ordered",
    "Commanded",
    "Commanded",
    "Wished",
    "Invited",
    "Wished",
    "Requested",
    "Summoned",
    "Ordered",
    "Directed",
    "Enjoined",
    "Enjoined",
    "Adjured",
    "Invited",
    "Invited",
    "Invited",
    "Directed",
    "Beckoned"
  ],
  "want": [
    "Desire",
    "In ___ of",
    "Hunger or thirst",
    "Desire",
    "Hankering",
    "Covet",
    "Fancy",
    "Poverty",
    "Any entry on a Dear Santa list",
    "Poverty",
    "Privation",
    "Kind of ad",
    "Poverty",
    "Have a yen for",
    "Have too little",
    "Desire",
    "Poverty",
    "Covet",
    "Lust after",
    "Go without",
    "Poverty",
    "Desire",
    "Be bereft of",
    "Shortage",
    "Have an itch for",
    "Have too little"
  ],
  "muse": [
    "Creative inspiration",
    "Creative person's inspiration",
    "Creative inspiration",
    "Creative influence",
    "Deliberate",
    "Artist's inspiration",
    "Ponder",
    "Calliope or Euterpe",
    "Inspiration",
    "Become absorbed in thought",
    "Ponder, with \"on\"",
    "Calliope or Euterpe",
    "What writer's block may block",
    "Deliberate",
    "Opine",
    "Cogitate",
    "Cogitate",
    "Inspiration",
    "Poet's inspiration",
    "There's one for dance",
    "Melpomene, e.g.",
    "Deliberate",
    "Cogitate",
    "Inspiration source",
    "Guiding genius",
    "Ponder",
    "Inspirer",
    "Reflect (on)",
    "Deliberate",
    "Ponder",
    "Inspiration",
    "Inspiration, maybe",
    "Cogitate",
    "She's an inspiration",
    "Euterpe, e.g.",
    "Calliope, e.g.",
    "Polyhymnia, e.g.",
    "Meditate",
    "Ruminate",
    "Dream",
    "Daydream",
    "Deliberate",
    "Chew (on)",
    "Poet's inspiration",
    "Urania, e.g."
  ],
  "aahs": [
    "Sighing sounds",
    "Companion of \"oohs\"",
    "Sounds of satisfaction",
    "Oohs' counterparts",
    "Sounds at a fireworks show",
    "Sounds when settling into a hot bath",
    "Sounds at spas",
    "They follow oohs",
    "Sounds of satisfaction",
    "Sounds during a massage",
    "Sounds during a massage",
    "Sounds from saunas",
    "Sauna exhalations",
    "Music to a masseur's ears",
    "Sounds of satisfaction",
    "Circus cries",
    "Contented sighs",
    "Music to masseurs' ears",
    "\"So satisfying!\" sounds",
    "Contented sighs (and a homophonic hint to this puzzle's theme)",
    "Relief reactions",
    "Sounds of relief",
    "Joyous sounds",
    "Cries of delight",
    "Sounds of relief",
    "Sounds of contentment",
    "Sounds of relief",
    "Contented sighs",
    "Expressions of relief",
    "Examination room sounds",
    "Sounds of pleasure",
    "Sounds of relief",
    "Satisfied sounds",
    "Cries at a circus",
    "Sounds from a sauna",
    "Sounds at massages",
    "Sounds of relief",
    "Sounds from the masseur's room",
    "Sounds of delight",
    "Comments to a doctor"
  ],
  "gunk": [
    "Greasy goo",
    "Residue in a bathtub drain",
    "Engine buildup",
    "Sticky matter",
    "Icky stuff",
    "Slime",
    "Unwanted buildup",
    "Icky stuff",
    "Crud"
  ],
  "cowl": [
    "Monk's hood",
    "More than a bird, but less than a facial expression",
    "Druid's head cover",
    "Monk's hood",
    "Monk's hood",
    "Brother's attire",
    "Monk's hood",
    "Monastic garb",
    "Monk's garb",
    "Monk's hood",
    "Hood",
    "Hood",
    "Hood",
    "Monk's attire",
    "Monk's hood",
    "Monk's hood",
    "Monk's hood"
  ],
  "hold": [
    "Stock market recommendation",
    "Grasp",
    "Wrestling maneuver",
    "A plan may be put on it",
    "Cargo's place",
    "\"Please ___\" (secretary's words)",
    "No-no for an offensive lineman",
    "Ship or plane part",
    "Advice between \"buy\" and \"sell\"",
    "\"Please ___\" (operator's request)",
    "A caller may be on this",
    "Place for cargo",
    "Remain true",
    "Full or half nelson",
    "You may hear Muzak when you're on it",
    "Not give way",
    "Wrestling move",
    "Word before or after \"on\"",
    "Phone button",
    "Grasp",
    "Set aside",
    "Hang on to",
    "Maintain",
    "Hammerlock, e.g.",
    "Ship part",
    "Business phone button",
    "Reserve",
    "Telephone button",
    "Nelson, e.g.",
    "Half nelson or full nelson, e.g."
  ],
  "inhd": [
    "Crisp, picturewise",
    "How many shows are broadcast",
    "How TV shows may be shown",
    "Shown crisply on TV",
    "How many TV shows are viewed nowadays",
    "Like a crisp picture, say",
    "Like many TV broadcasts",
    "Very pixel-dense, as a TV picture",
    "Having a crisp picture, say",
    "Like the best streams?",
    "How many TV shows are shown nowadays",
    "How many TV shows are shown",
    "How many TV movies can be seen",
    "How M.L.B. games are often broadcast",
    "How many TV programs are aired nowadays",
    "How many TV shows can be seen nowadays"
  ],
  "duel": [
    "Face-off",
    "Go one on one",
    "Face-off",
    "Climax in \"Hamilton\"",
    "\"Hamilton\" climax",
    "Engagement at 20 paces, maybe",
    "Climax of \"Hamilton\"",
    "Drawing contest?",
    "\"Ten ___ Commandments\" (song from \"Hamilton\")",
    "Burr/Hamilton showdown",
    "Fight at 20 paces, say",
    "Sword fight, e.g.",
    "Highlight in a Zorro movie",
    "Contest with lightsabers",
    "Cause for pacing?",
    "Contest with seconds",
    "What might put you through your paces?",
    "Gauntlet thrower's challenge",
    "Western climax",
    "Sword fight, e.g.",
    "Contest at 20 paces",
    "Emulate Cyrano",
    "Climactic scene in \"Hamlet\"",
    "Alexander Hamilton's last act",
    "Contest that leads to a draw",
    "Result of a slap, perhaps",
    "Matter of honor",
    "Old-fashioned showdown",
    "Pacers' contest?",
    "Field of honor event",
    "Event done at 20 paces, maybe",
    "One way to settle a dispute",
    "Face-off",
    "Quarrel settler, maybe",
    "Face-off",
    "Hamilton's last act",
    "Burr-Hamilton event",
    "One-on-one",
    "Field of honor fight",
    "Use epees"
  ],
  "cogs": [
    "They turn on machines",
    "They go around in circles",
    "Teeth not connected to jaws",
    "Teeth",
    "Gear parts",
    "Gear teeth",
    "Gear parts",
    "Some working parts",
    "Teeth",
    "Mechanical teeth",
    "Gear teeth",
    "Lowly workers",
    "Unimportant workers",
    "Gear teeth",
    "Industrial revolution elements?",
    "Gear teeth",
    "Teeth"
  ],
  "tidy": [
    "Shipshape",
    "Having everything in its place",
    "In apple-pie order",
    "Having nothing out of place",
    "Dusted off, say",
    "Shipshape",
    "Having everything in its place",
    "Like some sums",
    "Neat",
    "Well-kept",
    "Uncluttered",
    "Kind of sum",
    "Neat",
    "Neaten",
    "Like some sums",
    "Like some sums",
    "Like certain sums",
    "Neat",
    "Like Felix Unger's room",
    "Neat",
    "Shipshape",
    "Like some sums",
    "Shipshape",
    "Sizable",
    "In good order",
    "Orderly",
    "Like some sums",
    "Fastidious",
    "Neat",
    "Clean (up)",
    "Spruce",
    "A ___ sum",
    "Good-sized"
  ],
  "fixe": [
    "Set, in French",
    "Prix ___",
    "Prix ___ (restaurant offering)",
    "Prix ___",
    "Set on the Saone?",
    "Set, in Somme",
    "Set, in Somme",
    "Set, in Soissons",
    "Idee ___",
    "Prix ___"
  ],
  "nail": [
    "Ace",
    "It might be painted in the bathroom",
    "Get perfect",
    "Hammer's target",
    "Get exactly",
    "Get perfectly",
    "Hammer's target",
    "Polish place",
    "Execute perfectly, as a routine",
    "Do flawlessly",
    "It gets hit on the head",
    "Pull off perfectly",
    "Get exactly right",
    "Something to hit on the head",
    "Polish site",
    "Hammer's target",
    "Something chewed by a nervous person",
    "Do perfectly",
    "Execute perfectly",
    "Catch but good",
    "One hit on the head",
    "Something to file",
    "Finger's tip",
    "Digital protection",
    "It gets hammered",
    "One taking a pounding",
    "Execute perfectly",
    "It can scratch an itch",
    "Get exactly right",
    "Get exactly right",
    "Something an office worker might file",
    "Ace",
    "Finger's end",
    "Do perfectly",
    "Polish locale",
    "Get the goods on",
    "It's right at your fingertip",
    "Perform perfectly",
    "It may be filed",
    "Hammer's target",
    "Catch red-handed",
    "Execute perfectly",
    "Accomplish flawlessly",
    "Picture holder",
    "Place for some polish",
    "Hammer's target",
    "Catch but good",
    "Get exactly right",
    "It's on the tip of one's finger",
    "Do perfectly",
    "One with a pounding head?",
    "Catch but good",
    "Hit hard",
    "Digital protection",
    "Do perfectly",
    "Catch, and how!",
    "Execute perfectly",
    "It's at your fingertip",
    "Do perfectly",
    "Catch but good",
    "Clip-and-file item",
    "It gets hit on the head",
    "Tenpenny ___",
    "It gets hit on the head",
    "Clinch",
    "Do perfectly",
    "Catch",
    "Get but good",
    "Item filed by a secretary?",
    "Catch",
    "Finalize, with \"down\"",
    "Catch red-handed",
    "Execute perfectly",
    "Catch, slangily",
    "Fasten permanently",
    "One that gets hit on the head",
    "Secure, in a way"
  ],
  "dmaj": [
    "Key of Pachelbel's Canon: Abbr.",
    "Key of Pachelbel's Canon: Abbr.",
    "Baroque \"key of glory\": Abbr.",
    "Key with two sharps: Abbr.",
    "Key of Mahler's Symphony No. 1: Abbr."
  ],
  "teed": [
    "Peeved, with \"off\"",
    "Ready to go, with \"up\"",
    "Angry, with \"off\"",
    "Put out, with \"off\"",
    "Plenty sore, with \"off\"",
    "Ticked (off)",
    "Plenty angry, with \"off\"",
    "Ticked (off)",
    "Ticked (off)",
    "Cross, with \"off\"",
    "Ticked (off)",
    "Ticked (off)",
    "Angry, with \"off\"",
    "Ready, with \"up\"",
    "Ticked (off)",
    "Ready for a drive",
    "Cheesed (off)",
    "Ready to be driven",
    "Ready to be driven, in golf",
    "Ticked (off)",
    "Nettled, with \"off\"",
    "Put out, with \"off\"",
    "Ready, with \"up\"",
    "Ticked",
    "___ off (began)",
    "Ticked (off)",
    "Sore, with \"off\"",
    "Ticked (off)",
    "Sore, with \"off\"",
    "Miffed, with \"off\"",
    "Sore, with \"off\"",
    "Put on a peg",
    "Angry, with \"off\"",
    "Ticked (off)",
    "Started (off)",
    "Sore, with \"off\"",
    "Plenty mad, with \"off\"",
    "Drove, with \"off\"",
    "Prepared to drive, with \"up\"",
    "___ off (angered)",
    "Like some golf balls",
    "Ticked (off)",
    "Like some golf balls",
    "Put down one's ball",
    "Like some golf balls",
    "Began, with \"off\"",
    "Prepared to drive",
    "Miffed, with \"off\"",
    "Angry, with \"off\"",
    "Ready for a drive",
    "Got ready to drive, with \"up\"",
    "Miffed, with \"off\"",
    "Ticked (off)",
    "Initiated, with \"off\"",
    "Got ready to drive",
    "Prepared to drive",
    "Ready to be hit, as a golf ball",
    "___ off (plenty mad)",
    "Prepared to drive, with \"up\"",
    "Got ready to drive, with \"up\"",
    "Nettled, with \"off\"",
    "Ticked (off)",
    "Started a hole, with \"up\"",
    "___ off (miffed)",
    "___ off (angry)",
    "Prepared to drive",
    "Began, with \"off\"",
    "___ off (began)",
    "___ off (beside oneself)"
  ],
  "mill": [
    "Move around, with \"about\"",
    "Machine shop essential",
    "Pepper dispenser",
    "Grain-grinding facility",
    "Grind down",
    "Move (about)",
    "See 1-Across",
    "Grinding place",
    "Casting locale",
    "Pepper grinder",
    "Grinding location",
    "Grain grinder",
    "Word with pepper or saw",
    "Where workers may do the daily grind?",
    "Big wheel site",
    "Pepper grinder",
    "Steelmaking site",
    "Rumor generator?",
    "Flour factory",
    "Word with pepper or paper",
    "Factory on a stream",
    "Coffee grinder",
    "\"On Liberty\" writer"
  ],
  "pore": [
    "Study, with \"over\"",
    "Scrutinize, with \"over\"",
    "Something opened in hot water",
    "Astringent target",
    "Place for a bead",
    "Bead source",
    "Little sweater?",
    "Sweat spot",
    "Opening in cosmetology?",
    "Bead producer?",
    "Dermatologist's concern",
    "Little sweater",
    "Opening for a dermatologist",
    "Skin opening",
    "Opening for a dermatologist?",
    "Bead maker?",
    "Pumice feature",
    "Read carefully",
    "Scrutinize, with \"over\"",
    "Go (over)",
    "Source of perspiration",
    "Skin opening",
    "Tiny perforation",
    "Source of many a bead",
    "Go carefully (over)",
    "Ruminate (over)",
    "Sweat opening",
    "Sweater?",
    "Breathing space?",
    "Look (over)",
    "Oil spot",
    "Read (over)",
    "Go (over)",
    "Site of a sweat bead",
    "Go (over) carefully",
    "Minute opening",
    "Leaf opening",
    "Opening in a sweater?",
    "Sweat source",
    "Go (over)",
    "Dermal opening",
    "Dermatologist's concern",
    "Spot for a sweat bead",
    "Minute opening",
    "Look (over)",
    "A sweater uses it",
    "Go (over)",
    "Read (over)",
    "Skin opening",
    "Opening for a dermatologist",
    "Sweat source",
    "Look (over)",
    "Read carefully (over)",
    "Skin opening",
    "Read attentively, with \"over\"",
    "Skin opening",
    "Skin opening",
    "Study",
    "Scrutinize, with \"over\"",
    "Study",
    "Opening for a sweat bead"
  ],
  "sung": [
    "Like hymns, unlike most poems",
    "Like praises and arias",
    "Like choir music",
    "*Like carols and cantatas",
    "Like arias and anthems",
    "Like carols at Christmas",
    "Belted out",
    "Like a chorus line",
    "Like some praises",
    "Produced some pitches",
    "Like arias",
    "Like some telegrams",
    "Like lyrics",
    "Chinese dynasty a thousand years ago",
    "Like some telegrams",
    "Warbled",
    "Vocalized",
    "Like some verses",
    "Belted out, as a tune",
    "Like anthems",
    "Tweeted",
    "Like some rounds",
    "Like some Mass parts",
    "Vocalized",
    "Chinese dynasty overthrown by the Mongols",
    "Serenaded"
  ],
  "peds": [
    "Children's medicine, in doctor-speak",
    "Driver's concerns, briefly",
    "Ones hoofing it",
    "Walkers, in brief",
    "Those hoofing it",
    "Eco-friendly commuters, for short",
    "Go-___ (certain motorized scooters)",
    "Xers?",
    "Crosswalk users, for short",
    "Walkers, for short",
    "Walkers, for short",
    "Walkers, briefly",
    "Walkers, for short"
  ],
  "hurt": [
    "\"The ___ Locker,\" 2009 Best Picture winner",
    "Smart, in a way",
    "Pain",
    "Offended",
    "What words can do, in an admonishment",
    "Damaged",
    "Offended",
    "Offended",
    "\"Where does it ___?\"",
    "Injured",
    "Offended",
    "Banged up",
    "Offended",
    "Disabled",
    "Vitiated",
    "Beat up on",
    "Smart",
    "Tortured",
    "Sprained, as an ankle",
    "Assault and batter",
    "Smart",
    "On the disabled list",
    "Offended",
    "Offended",
    "Smart",
    "In pain",
    "Offended",
    "Smart",
    "Offended",
    "Grieved",
    "Offended",
    "Affronted",
    "1976 Elvis song",
    "Smart",
    "Feeling insulted",
    "Injury",
    "Offended",
    "William of \"The Doctor\""
  ],
  "silt": [
    "It's sedimentary, my dear",
    "What a foul mouth is full of?",
    "What might block a channel",
    "It may be fine in a stream",
    "It goes with the flow",
    "Fine deposit",
    "Delta deposit",
    "Riverbank deposit",
    "Fine sediment",
    "Sediment",
    "Deposited soil",
    "Delta deposit",
    "Channel buildup",
    "Buildup behind a dam",
    "Part 3 of the word ladder",
    "It goes with the flow",
    "Mouth filler",
    "River deposit",
    "Bed liner?",
    "Channel choker",
    "Delta deposit",
    "Potential mouth choker",
    "Deposit around a river's mouth",
    "What may accumulate in the mouth",
    "Buildup at a river's mouth",
    "It builds up in bars",
    "Bank deposit?",
    "Channel blocker",
    "Layer of a bed",
    "Delta deposit",
    "Channel buildup",
    "Sediment",
    "Riverbed deposit",
    "River deposit",
    "Channel choker",
    "Loam component",
    "Delta deposit",
    "Bank deposit",
    "Composition of a river sandbar",
    "Delta deposit",
    "River deposit",
    "Noncash deposit",
    "Riverbank component",
    "Bank deposit?",
    "Bank deposit",
    "Bank deposit",
    "Delta builder",
    "Bank deposit",
    "Sediment",
    "Alluvium",
    "Get choked up?",
    "Bank deposit?",
    "Sediment"
  ],
  "fade": [
    "Tapered hairstyle",
    "Lose color",
    "Hairstyle that's short on the sides",
    "Lessen in power",
    "Direction in a film script",
    "Tapering haircut",
    "Music outro effect",
    "Lose vibrancy, as from exposure to sunlight",
    "Dim",
    "Become dull",
    "Lose vibrancy",
    "Lose vividness",
    "Lose vividness",
    "Tapering hairdo",
    "Film technique",
    "Lose brilliance",
    "Die",
    "Scene-ending film technique",
    "Sparkle a little less",
    "What colors do over time",
    "What some things do in the sun",
    "Be outscored at the end",
    "Wane",
    "Lose strength in the backstretch",
    "Lose support, as at campaign's end",
    "Movie ending effect",
    "Movie shot",
    "Lose brilliance",
    "Screenplay direction",
    "Falter at the finish",
    "Not do well in the stretch",
    "Lose one's blush",
    "Wash out",
    "Wither away",
    "Lose brilliancy",
    "Direction at a scene's end",
    "Get lighter",
    "Fall behind in the stretch",
    "Lose color",
    "Grow dim",
    "Wash out",
    "Lose brightness",
    "Lose color",
    "Dim"
  ],
  "estd": [
    "Abbr. on a cornerstone",
    "Abbr. on a city limits sign",
    "Begun: Abbr.",
    "Cornerstone abbr.",
    "Abbr. on a brewery sign",
    "Abbr. on a college entrance sign",
    "Abbr. on a city boundary sign",
    "Abbr. on many a cornerstone",
    "Abbr. on a foundation stone",
    "Abbr. on a sign at a town line",
    "Abbr. before a year",
    "Abbr. before a date",
    "Abbr. on a company's sign",
    "Founded: Abbr.",
    "Abbr. found on some corporate logos",
    "Abbr. in the Guinness logo",
    "Abbr. preceding a year",
    "Abbr. on a town's welcome sign",
    "Founded, on city signs",
    "Abbr. before a cornerstone date",
    "Cornerstone abbr.",
    "Abbr. before a founding date",
    "Abbr. on a historic building",
    "Abbr. before a date",
    "Foundation stone abbr.",
    "Founded: Abbr.",
    "Abbr. before a year",
    "Set up: Abbr.",
    "Abbr. before a year",
    "Abbr. on many a cornerstone",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Abbr. on a city limit sign",
    "Cornerstone abbr.",
    "Founded: Abbr.",
    "Abbr. sometimes before a date",
    "Abbr. after an institution's name",
    "Founded: Abbr.",
    "Abbr. before a date",
    "Town line sign abbr.",
    "Cornerstone abbr.",
    "Abbr. accompanying a college name",
    "Abbr. on a business sign",
    "Abbr. before a date",
    "Cornerstone abbr.",
    "Abbr. before a date",
    "Shingle abbr.",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Abbr. preceding a date",
    "Founded: Abbr.",
    "Abbr. before a date",
    "Roughly computed: Abbr.",
    "Founded: Abbr.",
    "Founded: Abbr.",
    "Like budget amts.",
    "Cornerstone abbr.",
    "Cornerstone abbr.",
    "Abbr. on company letterheads"
  ],
  "rata": [
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "See 59-Down",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro accompanier?",
    "Pro ___",
    "Pro ___",
    "Pro ___ (in proportion)",
    "Pro ___ distribution",
    "Pro ___",
    "Pro ___ (proportionally)",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___ (proportionately)",
    "Pro follower",
    "Pro accompanier?",
    "Pro ___ (proportionately)",
    "Pro follower",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___ (proportionately)",
    "Pro ___",
    "Pro ___",
    "Madrid maze-runner",
    "Pro ___",
    "Pro ___",
    "Pro ___ (one way to divide things)",
    "Pro ___ (proportionally)",
    "Pro ___",
    "Pro ___",
    "With 59-Across, a knock",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___ (proportionately)",
    "Pro ___",
    "Pro follower",
    "Pro ___",
    "Pro ___",
    "Reynosa rodent",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___ (proportionate)",
    "Pro ___",
    "Pro ___",
    "Tat-tat preceder",
    "Pro ___",
    "Pro ___",
    "___-tat",
    "See 72-Across",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro___",
    "Pro___",
    "Pro___",
    "___-tat-tat",
    "Pro___",
    "Pro___",
    "Pro___",
    "Pro ___ (proportionately)",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro ___",
    "Pro follower"
  ],
  "wimp": [
    "Scaredy-cat",
    "Cowardly sort",
    "Get too scared, with \"out\"",
    "Weakling",
    "Wuss",
    "Namby-pamby",
    "Bully's target, maybe",
    "Wuss",
    "Chicken",
    "Chicken",
    "Chicken (out)",
    "Cream puff",
    "Chicken, so to speak"
  ],
  "palo": [
    "Stick: Sp.",
    "___ verde (desert tree)",
    "___ Alto, Calif.",
    "Texas' ___ Duro Canyon",
    "___ Alto, Calif.",
    "___ Alto, Calif.",
    "___ Alto",
    "___ Alto, Calif.",
    "___ Alto, Calif.",
    "Texas' ___ Duro Canyon",
    "___ Alto",
    "___ Alto",
    "Alto preceder",
    "___ Pinto (Texas county or its seat)",
    "Arizona's ___ Verde Nuclear Generating Station",
    "Alto lead-in?",
    "___ Alto",
    "___ Alto",
    "___ Alto",
    "Texas's ___ Duro Canyon",
    "___ Alto",
    "With 4-Across, part of Silicon Valley",
    "___ Alto",
    "___ Alto",
    "___ Alto",
    "___ Alto, Calif.",
    "___ Alto, Calif.",
    "Stick, in Seville",
    "___ Duro Canyon, Tex.",
    "___ Alto, Calif.",
    "___ Alto, Calif.",
    "___ Alto",
    "___ Alto",
    "___ Pinto (Texas county)",
    "___ Alto, Calif.",
    "___ Alto",
    "Stick: Sp.",
    "___ Alto"
  ],
  "rigg": [
    "Late actress Diana of \"Game of Thrones\"",
    "Diana ___, 1969 Bond girl",
    "Bond girl opposite Lazenby",
    "Patrick Macnee's 1960s TV co-star Diana",
    "1969 Bond girl",
    "1997 Emmy winner for PBS's \"Rebecca\"",
    "Tony winner Diana",
    "\"Medea\" Tony winner, 1994",
    "\"Mystery!\" host Diana",
    "Diana of stage and screen",
    "Actress Diana",
    "Actress Diana",
    "Diana of \"The Avengers\"",
    "\"Mystery\" host Diana",
    "Diana of \"The Avengers\"",
    "Co-star of \"The Avengers\""
  ],
  "ipse": [
    "___ dixit",
    "___ dixit (unproven assertion)",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "Alter ___ amicus",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit (allegation)",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit",
    "___ dixit (dogmatic statement)",
    "___ dixit"
  ],
  "nein": [
    "Bundestag vote",
    "Refusal overseas",
    "German refusal",
    "Deutschland turndown",
    "German dissent",
    "German homophone of 60-Across",
    "Ruhr refusal",
    "Teutonic turndown",
    "Rhineland refusal",
    "Answer to \"Is Bonn the capital of Deutschland?\"",
    "\"Das ist verboten!\"",
    "German refusal",
    "No de Cologne?",
    "European refusal",
    "Hamburger refusal?",
    "Frank refusal?",
    "\"Forget it, Friedrich!\"",
    "Deutsch denial",
    "Nietzsche's \"no\"",
    "Nyet : Russian :: ___ : German",
    "Foreign refusal",
    "Fritz's \"Forget it!\"",
    "Foreign refusal",
    "Frau's refusal",
    "\"Absolut nicht!\"",
    "21-Across refusal",
    "\"___, danke\"",
    "Cologne contradiction",
    "Unlikely response to \"Sprechen Sie Deutsch?\"",
    "Ja's opposite",
    "\"___ doch!\" (German reply)",
    "Prussian prohibition",
    "Vote in der Bundesrat",
    "No, in Nuremberg",
    "\"No, mein Herr\"",
    "Dresden denial",
    "Frau's \"forget it\"",
    "Deutschland denial",
    "Ja's opposite",
    "Overseas denial",
    "Dresden denial",
    "Ja's opposite",
    "Kurt denial?",
    "Ruhr refusal",
    "Overseas refusal",
    "Teutonic turndown",
    "\"No, mein Herr\"",
    "\"___, danke\"",
    "Opposite of \"ja\"",
    "No in Nuremberg",
    "Teutonic turndown",
    "Turndown in Munich",
    "Dusseldorf denial",
    "Bundestag vote",
    "It's declining in Germany",
    "NATO refusal",
    "Rheinland rejection",
    "Ja's opposite",
    "Rudolf's refusal",
    "Nuremberg negative",
    "Foreign refusal",
    "Opposite of ja",
    "Negative in Nuremberg",
    "Nurnberg negative",
    "No de Cologne?",
    "Nurnberg no"
  ],
  "cuny": [
    "Big Apple sch. system",
    "Brooklyn Coll. is part of it",
    "Hunter College is part of it, in brief",
    "Big Apple sch."
  ],
  "soul": [
    "\"Charms strike the sight, but merit wins the ___\": Pope",
    "Heart and ___",
    "\"Whatever satisfies the ___ is truth\": Whitman",
    "\"The ___ should always stand ajar\": Dickinson",
    "Heart's partner",
    "One ferried by Charon",
    "Dangerous thing to sell",
    "\"Or what shall a man give in exchange for his ___?\": Mark 8:37",
    "Music genre",
    "\"O my prophetic ___!\": Hamlet",
    "Aretha Franklin, the Queen of ___",
    "Music category",
    "Record store section",
    "James Brown's genre",
    "James Brown's genre",
    "Heart's partner",
    "Genre for Aretha Franklin",
    "Blues alternative",
    "Essential element",
    "Music from the Miracles, e.g.",
    "Topic of some 7-Down",
    "Body's partner",
    "Essence",
    "Record store category",
    "Marvin Gaye music",
    "Essence",
    "Philosopher's study",
    "Essence",
    "Music category",
    "Motown music category",
    "Popular music category",
    "Music category",
    "Aretha Franklin's singing style",
    "Inner person",
    "Cleaver's \"___ on Ice\"",
    "Musical genre",
    "Music category",
    "Body's partner"
  ],
  "emaj": [
    "Key of Dvorak's \"Serenade for Strings\": Abbr.",
    "Key with four sharps: Abbr.",
    "Key with four sharps: Abbr.",
    "Key with four sharps: Abbr.",
    "Key with four sharps: Abbr.",
    "Key of Beethoven's \"Fidelio\" Overture: Abbr.",
    "Key of Haydn's Symphony No. 12 or 29",
    "Mus. key with four sharps",
    "Key with four sharps: Abbr.",
    "Key of Bach's second violin concerto: Abbr.",
    "What Bruckner's Symphony No. 7 is written in: Abbr.",
    "Key of Bruckner's Symphony No. 7: Abbr."
  ],
  "whoa": [
    "Cousin of \"OMG!\"",
    "\"What just happened here ...?!\"",
    "Equestrian's \"Stop!\"",
    "\"Hold your horses!\"",
    "Cry of surprise",
    "\"You just blew my mind!\"",
    "\"Stop right there!\"",
    "Shout from a coach driver",
    "Cousin of \"OMG!\"",
    "Command spoken while pulling the reins",
    "\"Mind ... blown!\"",
    "\"Hey, take it easy!\"",
    "\"That's super crazy!\"",
    "\"You just blew my mind!\"",
    "\"That's crazy, dude!\"",
    "\"Hang on a sec!\"",
    "Cry to a horse that's the opposite of \"Giddyup!\"",
    "Horse's halter?",
    "\"That's big news, dude!\"",
    "Cowboy's \"Stop!\"",
    "Liveryman's command",
    "Equestrian's \"Stop!\"",
    "Steed stopper",
    "Command to a steed",
    "\"Hold on there!\"",
    "\"Hold your horses!\"",
    "\"Stop!\"",
    "Cry coming up to a barn",
    "\"Hold your horses!\"",
    "Horse's halter",
    "?ôNow that?Æs awesome!?ö",
    "\"Slow down!\"",
    "\"Hold on there!\"",
    "Command to a horse",
    "\"Hold it, horse!\"",
    "\"Hold on a moment!\"",
    "Shout from a coach",
    "\"Stop right there!\"",
    "Cry made while the reins are pulled",
    "\"Hold it right there!\"",
    "\"Hold it, buster!\"",
    "\"___,\" said Tom haltingly",
    "\"Hold it!\"",
    "\"Hold on there!\"",
    "\"Hold it!\"",
    "\"Hold on there!\"",
    "\"Stop right there\"",
    "\"Wait just a minute!\"",
    "Rider's command",
    "\"Hold up!\"",
    "Command to Dobbin",
    "Horse's halter",
    "\"Hold it!\"",
    "Rider's \"Stop!\"",
    "Horse's halter",
    "Command to Dobbin",
    "Horse's halter?",
    "Avast, on land"
  ],
  "tusk": [
    "Prominent feature of a babirusa (\"deer-pig\")",
    "Boar's head feature",
    "Source of ivory",
    "*Narwhal feature",
    "Warthog feature",
    "Ivory source",
    "Mammoth growth",
    "Ivory source",
    "Narwhal feature",
    "Big tooth",
    "Walrus feature"
  ],
  "john": [
    "Most common papal name",
    "Legend of pop music",
    "Doe; a Deere?",
    "Place to solve a crossword, maybe",
    "Lav",
    "Loo",
    "In song, Jacob Jingleheimer Schmidt's first name",
    "Donald ___ Trump",
    "Loo",
    "Facilities",
    "Chief Justice Roberts",
    "W.C.",
    "Lav",
    "Lav",
    "Adams, Tyler or Kennedy",
    "Dear person?",
    "Loo",
    "One caught in a police sting",
    "Sen. McCain",
    "Head",
    "New Testament figure",
    "With 45-Across, noted Arctic explorer",
    "Sportscaster Madden",
    "Name of more than 20 popes",
    "W.C.",
    "Most common papal name",
    "Head out on the ocean?",
    "Head",
    "Facilities",
    "Dear guy?",
    "With 49-Down, \"The Firm\" actor",
    "First name of four Presidents",
    "One of 21 popes",
    "Most common papal name"
  ],
  "most": [
    "___ of all",
    "Yearbook award word",
    "___ likely to succeed",
    "Start of a Guinness record",
    "Lion's share",
    "More than more",
    "Superlatively",
    "Ending with second or upper",
    "Guinness superlative",
    "Guinness Book adjective",
    "A majority of",
    "\"It's the ___ Wonderful Time of the Year\"",
    "Start of many records",
    "Guinness word",
    "A majority",
    "Extremely",
    "Not quite all",
    "Amount between some and all",
    "Very, very",
    "Very",
    "Superlative adverb",
    "The majority",
    "Kind of votes a candidate wants",
    "___ of all",
    "Part of M.V.P.",
    "Max",
    "More than half",
    "Maximum",
    "A majority",
    "Very cool, in 50's slang, with \"the\"",
    "Just 'bout",
    "Word in a record's description",
    "Guinness superlative",
    "50.1%+",
    "The bulk",
    "90%, say",
    "Lion's share",
    "The majority",
    "Lion's share",
    "50+ percent",
    "The bulk",
    "Between half and all",
    "Guinness superlative",
    "\"Make the ___ of it\""
  ],
  "amis": [
    "Parts of some circles, in France",
    "Martin ___, \"London Fields\" novelist",
    "Friends of Nancy",
    "French buddies",
    "Author Kingsley",
    "Martin who wrote \"The Pregnant Widow\"",
    "\"Lucky Jim\" author Kingsley",
    "Fraternite fellows",
    "Martin who wrote \"London Fields\"",
    "\"Lionel Asbo: State of England\" novelist, 2012",
    "French friends",
    "Buds in Bordeaux",
    "French buds",
    "\"The Zone of Interest\" author, 2014",
    "\"Lucky Jim\" author",
    "French Facebookers' connections",
    "Les Trois Mousquetaires, to one another",
    "British author who wrote \"The Old Devils\"",
    "\"Time's Arrow\" novelist Martin",
    "Marseille mates",
    "Author Kingsley or Martin",
    "\"Money\" novelist, 1984",
    "Bordeaux buddies",
    "Athos, Porthos and Aramis, e.g.",
    "French friends",
    "\"The Pregnant Widow\" author Martin",
    "Friends of Francois",
    "Pals around in Paris?",
    "Author in the 1950s \"angry young men\" movement",
    "They might give each French kisses",
    "\"Lucky Jim\" author Kingsley",
    "\"House of Meetings\" novelist, 2006",
    "\"London Fields\" novelist, 1989",
    "Author of \"Time's Arrow,\" 1991, a novel written in reverse chronological order",
    "\"Lucky Jim\" novelist, 1954",
    "French friends",
    "\"London Fields\" author",
    "Some foreign pen pals",
    "Les Trois Mousquetaires, e.g.",
    "People a Frenchman may address, after \"mes\"",
    "Overseas friends",
    "\"Yellow Dog\" author Martin",
    "Porthos and Athos, e.g.",
    "Mercutio and Romeo, in Gounod's \"Romeo et Juliette\"",
    "Athos, Porthos and Aramis",
    "Author Kingsley",
    "French friends",
    "Ones close to le coeur",
    "Writer Kingsley",
    "Societe des ___",
    "\"Jake's Thing\" author",
    "Porthos and Athos, e.g.",
    "\"Lucky Jim\" novelist",
    "Hugo's friends",
    "Writer Kingsley",
    "\"Jake's Thing\" author",
    "French friends",
    "\"Lucky Jim\" author",
    "\"Jake's Thing\" novelist",
    "\"Lucky Jim\" author",
    "Writer Kingsley",
    "\"Lucky Jim\" author",
    "\"Time's Arrow\" novelist",
    "Contemporary novelist Martin",
    "Francois's friends",
    "\"Lucky Jim\" author Kingsley",
    "\"Lucky Jim\" author",
    "\"Lucky Jim\" author",
    "\"Jake's Thing\" author",
    "\"Lucky Jim\" author",
    "\"Jake's Thing\" author",
    "\"Lucky Jim\" author",
    "\"Jake's Thing\" author"
  ],
  "fort": [
    "Certain snow construction",
    "Snow construction",
    "Defense in a snowball fight",
    "Stronghold",
    "Kid's creation out of pillows",
    "Snow ___ (kids' winter construction)",
    "___ Ticonderoga",
    "Baltimore's ___ McHenry",
    "___ Lauderdale, Fla.",
    "Setting for 76-Down",
    "Something to hold down",
    "Western setting",
    "Old fur trader's locale",
    "Old West trading post",
    "With 43-Down, storied Bronx station house",
    "Defense in a snow fight",
    "Snow structure",
    "Siege site",
    "*Locale in a western",
    "Kids' snow construction",
    "Siege site",
    "Stronghold",
    "Construction of snow, maybe",
    "Western setting",
    "Snow creation",
    "Locale in a western",
    "Means of defense",
    "Snow construction",
    "___ Hood, Tex.",
    "Alcaide's command",
    "Fastness",
    "Bragg or Lee, e.g.",
    "McHenry, e.g."
  ],
  "debs": [
    "Eugene V. ___, early American Socialist leader",
    "Socialist Eugene who ran for president five times",
    "Some purchasers of expensive gowns",
    "Belles at balls, informally",
    "Eugene in labor history",
    "Belles of the ball",
    "Belles of the ball",
    "Ones coming out",
    "Socialites having a ball",
    "Five-time U.S. presidential candidate in the early 1900s",
    "Third-place candidate in the 1920 presidential election who ran his campaign from jail",
    "New members of society",
    "Girls with coming-out parties",
    "U.S. labor leader Eugene",
    "Third-place presidential candidate of 1920 who ran his campaign from jail",
    "Young socialites",
    "Five-time U.S. presidential candidate in the early 1900s",
    "Ball girls",
    "Ball belles",
    "Young socialites",
    "They're coming out",
    "Young socialites",
    "Partygoers",
    "They've come out",
    "Girls in gowns",
    "Ball girls?",
    "Ball girls",
    "Ball throwers",
    "Presidential candidate who campaigned from prison",
    "Five-time Socialist candidate",
    "Socialist Eugene",
    "Socialist Eugene",
    "Five-time Presidential candidate",
    "Socialist Eugene",
    "Presidential candidate of yore",
    "Candidate of '08"
  ],
  "cahn": [
    "Sammy with four Oscars",
    "Lyricist Sammy",
    "\"My Kind of Town\" lyricist",
    "Lyricist Sammy",
    "\"High Hopes\" lyricist Sammy",
    "Sammy who wrote the lyrics to \"Ain't That a Kick in the Head\"",
    "\"High Hopes\" lyricist Sammy",
    "\"High Hopes\" lyricist Sammy",
    "\"Come Fly With Me\" lyricist Sammy",
    "\"All the Way\" lyricist",
    "\"Three Coins in the Fountain\" lyricist Sammy",
    "Songwriter Sammy",
    "\"High Hopes\" lyricist Sammy",
    "\"High Hopes\" lyricist",
    "\"Call Me Irresponsible\" lyricist",
    "Sammy the lyricist",
    "\"Let It Snow\" lyricist",
    "\"Love and Marriage\" lyricist Sammy",
    "Songwriter Sammy",
    "Frequent Styne collaborator",
    "\"Call Me Irresponsible\" lyricist",
    "\"High Hopes\" lyricist",
    "\"High Hopes\" lyricist",
    "\"Be My Love\" lyricist",
    "\"High Hopes\" lyricist",
    "Songwriter Sammy",
    "\"High Hopes\" songwriter",
    "\"High Hopes\" lyricist",
    "\"High Hopes\" lyricist",
    "\"Call Me Irresponsible\" lyricist",
    "\"All the Way\" lyricist Sammy",
    "\"High Hopes\" lyricist",
    "\"Call Me Irresponsible\" songwriter",
    "Songwriter Sammy",
    "\"Three Coins in the Fountain\" lyricist"
  ],
  "germ": [
    "Start of an idea",
    "Start of an idea",
    "Start of a 14-Across",
    "Seed",
    "Wheat ___",
    "Cause of a cold",
    "Wheat ___",
    "\"Bug\"",
    "Target for a disinfectant wipe",
    "___ warfare",
    "Infection cause",
    "Start of an idea",
    "Genesis of an idea",
    "Wheat ___",
    "Thought starter",
    "It can make you sick",
    "Purell target",
    "Bug",
    "Seed",
    "It might make you sick",
    "It may be what ails you",
    "Lysol target",
    "Basis",
    "\"Cootie\"",
    "Start, as of an idea",
    "Idea's start",
    "Starting point",
    "Listerine target",
    "Beginning, as of an idea",
    "It might make you sick",
    "It's sickening",
    "Spark",
    "It can make you sick",
    "Starting point",
    "Start of an idea",
    "Start of an idea",
    "Idea's beginning",
    "Origin, as of an idea",
    "Disease cause",
    "Starting point",
    "Kind of warfare",
    "Microbe",
    "Start, as of an idea",
    "Listerine target",
    "Wheat center",
    "Seed"
  ],
  "nuts": [
    "Trail mix ingredients",
    "\"Dang!\"",
    "\"Dagnabbit!\"",
    "\"Dang!\"",
    "Cuckoo",
    "Beer ___",
    "\"Dang it!\"",
    "\"Aw, heck!\"",
    "Antithesis of 35-Across",
    "\"Phooey!\"",
    "Mad",
    "See 34-Across",
    "\"Shoot!\"",
    "Party bowlful",
    "\"Darn!\"",
    "Items in a Planters can",
    "\"Phooey!\"",
    "With 70-Across, some people are ___ crosswords",
    "*Fanatics",
    "Bananas",
    "Loco",
    "Balmy",
    "Baklava ingredients",
    "Around the bend",
    "\"Ape\"",
    "Cashews and such",
    "Loony",
    "Daft",
    "Wacky",
    "\"Darn it!\"",
    "\"Darn it!\"",
    "Loony",
    "Gen. McAuliffe's retort",
    "\"Phooey!\"",
    "*End of the word ladder",
    "Mad",
    "Gonzo",
    "Wacko",
    "Kooky",
    "Meshuga",
    "Excessive buffs",
    "See 56 Across",
    "\"Phooey!\"",
    "Goofy",
    "Buggy"
  ],
  "hobs": [
    "Goblins, in folklore",
    "Goblins, old-style",
    "Gear-cutting tools",
    "Quoits pegs",
    "Quoits pegs"
  ],
  "naoh": [
    "Lye, in chemistry class",
    "Lye, chemically",
    "Caustic soda, chemically",
    "Chemical formula for sodium hydroxide",
    "Caustic soda, chemically",
    "Drain cleaner, chemically",
    "Caustic soda, symbolically",
    "Caustic soda, to a chemist",
    "Sodium hydroxide, to a chemist",
    "Sodium hydroxide, chemically",
    "Sodium hydroxide, to chemists"
  ],
  "gyre": [
    "Move in a spiral",
    "Circular or spiral motion",
    "*To go round and round",
    "Circular course",
    "Circular course",
    "Circular motion"
  ],
  "hare": [
    "Long-eared hopper",
    "Main ingredient in the German stew hasenpfeffer",
    "Cocky character in a fable",
    "Fabled race loser",
    "___ Krishna (religious movement)",
    "Fabled loser to a tortoise",
    "Title creature in an Aesop fable",
    "Fabled slacker",
    "Wonderland tea party attendee",
    "Relative of a rabbit",
    "Title creature in an Aesop fable",
    "March ___",
    "Hopper",
    "\"___ Today, Gone Tomorrow\" (\"Tiny Toon Adventures\" episode)",
    "Long-eared hopper",
    "Storied loser in an upset",
    "Racer with the tortoise",
    "Storied also-ran",
    "Second-place finisher, famously",
    "Loser to the tortoise, in fable",
    "Race loser in an Aesop fable",
    "Fast runner",
    "Jugged ___ (old British delicacy)",
    "Fictional braggart",
    "Word in many punny Bugs Bunny titles",
    "Aesop character who lost a race",
    "Tortoise taunter",
    "Long-eared hopper",
    "Noted second-place finisher",
    "Fabled 90-Down",
    "Opponent of 78-Down, in fable",
    "Hound's quarry",
    "Tortoise racer",
    "Storied slacker",
    "___ Krishna",
    "Tea party attendee in \"Alice in Wonderland\"",
    "*Storied also-ran",
    "Big-eared hopper",
    "Lion : pride :: ___ : husk",
    "March ___",
    "Fabulous slacker",
    "Dummy on a greyhound track",
    "Storied slacker",
    "A leveret is a young one",
    "Famously overconfident competitor",
    "Loser to a tortoise, in a fable by 1-Down",
    "Tortoise's race opponent",
    "A leveret is a young one",
    "Fabled racer",
    "Hound's quarry",
    "Fabled slacker",
    "Fabled race loser",
    "Tortoise's race opponent",
    "Aesopian also-ran",
    "Symbol of speed",
    "Fabled race loser",
    "Animal with a 57-Across",
    "Hopper",
    "Loser to the tortoise",
    "Noted race loser",
    "Tortoise racer",
    "Long-eared leaper",
    "\"A Wild ___\" (cartoon in which Bugs Bunny first says \"What's up, Doc?\")",
    "One leading a chase",
    "Jumper",
    "Big-eared hopper",
    "Long-eared hopper",
    "Animal with a scut",
    "Fabled racer",
    "Racer of fable",
    "Aesop's also-ran",
    "Constellation south of the Hunter, with \"the\"",
    "Relative of a rabbit",
    "Fast runner",
    "Relative of a rabbit",
    "Fabulous loser",
    "Tortoise racer",
    "Fox's prey",
    "It's famous for its rapid transit",
    "Meadow denizen",
    "Legendary loser",
    "Partner of 20-Down",
    "Loser to a tortoise",
    "Also-ran of fable",
    "One with big ears",
    "Noted sprinter",
    "Fast loser",
    "Aesop's also-ran",
    "Hopper",
    "Fabled loser",
    "Tortoise's rival",
    "Tortoise's opponent",
    "Fast one",
    "Hounds' quarry",
    "Overconfident racer of fable",
    "Wonderland character",
    "Loser in an upset",
    "Fabled fast starter",
    "Fabled racer",
    "Famous loser",
    "Fabled racer",
    "Fabled loser",
    "Chinese calendar animal",
    "Leporine creature",
    "Animal with big ears",
    "___ Krishna",
    "March ___",
    "Fabled loser",
    "Wonderland creature",
    "Tortoise's competitor",
    "___ and hounds (outdoor game)",
    "Hound's quarry",
    "Lagomorph",
    "___ and hounds"
  ],
  "flax": [
    "Source of linen",
    "Neutral paint color",
    "Spun fiber",
    "Source of linseed oil",
    "Cigarette paper source",
    "Oil source",
    "Source of linen",
    "Something that's spun",
    "Linen fiber",
    "Source of linen",
    "Linen fiber",
    "Raw material for Rumpelstiltskin",
    "Linseed oil source",
    "Fine fiber"
  ],
  "rios": [
    "___ de las Amazonas",
    "Rivers, in Mexico",
    "Amazonas and others",
    "Amazonas and Nilo",
    "Ocho ___ (Jamaican resort)",
    "Kia subcompacts",
    "Spanish waterways",
    "Amazonas and others",
    "Amazon and Orinoco, to natives",
    "Spanish rivers",
    "Carriers of water to los oceanos",
    "Delgado's rivers",
    "They run through South America",
    "Uruguay and Paraguay",
    "Mexican waterways",
    "Paraguay and others",
    "Ocho ___, Jamaica",
    "Rivers of Spain",
    "Ebro y otros",
    "Paraguay and Uruguay",
    "Mexico's Yaqui and others",
    "Mexican rivers",
    "The Amazon and others",
    "Spanish streams",
    "Guadalquivir et al.",
    "Spanish rivers",
    "Guadalquivir and others",
    "Jamaica's Ocho ___",
    "Grande and Bravo",
    "Portuguese rivers",
    "Ocho ___, Jamaica",
    "Bravo and Grande",
    "Ocho___, Jamaica"
  ],
  "bump": [
    "Word after fist or baby",
    "Baby ___",
    "Displace",
    "Traffic slower",
    "Fist ___ (modern greeting)",
    "Word after speed or baby",
    "Jolt provider in a car",
    "Jolt",
    "Run (into)",
    "Road caution",
    "Cause for an apology"
  ],
  "azul": [
    "Blue: Sp.",
    "Blue: Sp.",
    "Color of el mar",
    "Color of el mar",
    "Color of el mar",
    "Blue, south of the border",
    "Blue, in Bogota",
    "Blue, in Baja"
  ],
  "foot": [
    "12 inches",
    "Here's the kicker!",
    "Take care of, as the bill",
    "Locale for tarsals and metatarsals",
    "Pedal pusher",
    "Pick up, as a bill",
    "Pay, as the bill",
    "Unit of 97-Across",
    "Ruler's length",
    "Bottom",
    "Big snowfall",
    "Bit of poetry",
    "Arch site"
  ],
  "atno": [
    "___ time (never)",
    "___ expense (free)",
    "Scandium's is 21: Abbr.",
    "Periodic table fig.",
    "What each arrow in a clue points to, for its answer: Abbr.",
    "Md or Rn fig.",
    "1 for hydrogen or 2 for helium: Abbr.",
    "42, for Mo",
    "\"... ___ extra charge!\"",
    "___ extra cost",
    "53 for I, e.g.",
    "\"... ___ cost to you!\"",
    "O's is one more than N's",
    "He's 2, for one",
    "It's 8 for O",
    "Fig. for I, O or U, but not A or E",
    "Stat. for Re, La or Ti",
    "47, for Ag",
    "Periodic table fig.",
    "W's is 74",
    "___ time (never)",
    "Figure on a table: Abbr.",
    "Zinc's is 30: Abbr.",
    "Periodic table info: Abbr.",
    "He's 2, say",
    "Am or Fm stat",
    "6, for C",
    "___ time (never)",
    "\"___ extra cost!\"",
    "Periodic table fig.",
    "___ extra cost",
    "8 for O, say",
    "5 for B or 6 for C",
    "80, for Hg",
    "It's 8 for O",
    "13, for Al",
    "79, for gold: Abbr.",
    "___ extra cost",
    "95 for Am or 100 for Fm: Abbr.",
    "Au's is 79",
    "\"___ cost to you!\"",
    "14 for Si or 102 for No: Abbr.",
    "___ extra cost",
    "Periodic table fig.",
    "Fig. on a periodic table",
    "\"___ cost to you\"",
    "27 for 56-Across, e.g.: Abbr.",
    "Chem. datum",
    "79 for gold, e.g.: Abbr.",
    "\"___ additional cost!\"",
    "79 for gold, e.g.: Abbr.",
    "Periodic table stat.",
    "\"___ extra cost!\"",
    "\"___ extra cost!\"",
    "Gold's is 79: Abbr.",
    "___ point (never)",
    "___ cost (free)",
    "13, for Al",
    "It's 5 for B and 6 for C: Abbr.",
    "___ time (never)",
    "___ time",
    "___ time",
    "With 48-Across, never",
    "Au's is 79",
    "29 for copper, e.g.: Abbr.",
    "O's is 8",
    "\"___ charge!\"",
    "26 for Fe: Abbr.",
    "\"___ extra cost\"",
    "___ point (never)",
    "___ extra cost",
    "___ extra cost",
    "79 for gold, e.g.: Abbr.",
    "___ cost",
    "___ time (never)",
    "\"___ cost to you!\"",
    "___ time (never)",
    "\"___ cost to you!\"",
    "1 for H, or 2 for He",
    "\"___ added expense\"",
    "___ time (never)",
    "___ time (never)",
    "___expense",
    "___cost (free)",
    "___time",
    "___ time (never)",
    "___ time (never)",
    "Words before time or expense",
    "___ time (never)",
    "___ time (never)"
  ],
  "papi": [
    "Big ___, nickname of baseball's David Ortiz",
    "Big ___, nickname for slugger David Ortiz",
    "Nickname for Dad",
    "Big ___, nickname of baseball's David Ortiz",
    "Big ___ (longtime Red Sox nickname)",
    "Big ___ (baseball's David Ortiz)",
    "Spanish term of endearment",
    "Big ___ (nickname of baseball's David Ortiz)",
    "Big ___ (nickname of baseball's Ortiz)",
    "Parental term of endearment, in Spain"
  ],
  "enol": [
    "Unstable chemical compound",
    "Chemical compound",
    "Hydroxyl-bearing compound",
    "Hydroxyl compound",
    "Hydroxyl group compound [14]",
    "Carbon compound",
    "___ ether",
    "Alkene derivative",
    "Hydroxyl-bearing compound",
    "Hydroxyl compound",
    "Unstable compound",
    "Hydroxyl compound",
    "Organic compound",
    "___ ether",
    "Carbon compound",
    "Hydroxyl compound",
    "Compound with a double-bonded carbon atom",
    "Carbon compound",
    "Organic compound",
    "Hydroxyl compound",
    "Hydroxyl compound",
    "Organic compound",
    "Organic compound",
    "Form of pyruvic acid",
    "Hydroxyl compound",
    "Hydroxyl compound",
    "Organic compound",
    "Organic compound",
    "Form of acetylacetone",
    "Certain alkene",
    "Double-bonded compound",
    "Carbon compound",
    "Compound that's subject to tautomerism",
    "Certain alkene",
    "Compound with a double bond",
    "Certain alkene",
    "Hydroxyl-carbon compound",
    "Keto-___ tautomerism (organic chemistry topic)",
    "Hydroxyl compound",
    "Hydroxyl compund",
    "Organic compound",
    "___ ether",
    "Form of ether",
    "Double-bonded compound",
    "Compound with a hydroxyl group",
    "Tautomeric form of vitamin C, e.g.",
    "Organic compound",
    "Hydroxyl compound",
    "Compound with double-bonded carbon atoms",
    "Hydroxyl compound",
    "Hydroxyl compound",
    "Organic compound",
    "Carbon compound",
    "Organic compound",
    "Organic compound",
    "Organic compound",
    "Carbon compound",
    "Hydroxyl compound",
    "Organic compound",
    "Organic compound",
    "Organic compound",
    "Form of acetylacetone",
    "Carbon compound",
    "Carbon compound",
    "Hydroxyl compound",
    "Compound with two carbon atoms",
    "Chemical compound",
    "Carbon compound",
    "Organic compound",
    "Form of ether",
    "Hydroxyl compound",
    "Acetylacetone form",
    "Compound with a doubly linked carbon atom",
    "Hydroxyl-carbon compound",
    "Tautomeric compound",
    "Chemical compound",
    "Carbon compound",
    "Hydroxyl compound",
    "Chemical compound",
    "Hydroxyl compound",
    "Hydroxyl compound",
    "Ascorbic acid, for one",
    "Organic compound",
    "Organic compound"
  ],
  "rots": [
    "Breaks down",
    "Doesn't keep",
    "Wastes away",
    "Decays",
    "Doesn't keep",
    "Deteriorates",
    "Spoils badly",
    "Is no longer good",
    "Becomes compost",
    "Turns bad",
    "Goes bad",
    "Decomposes",
    "Breaks down",
    "Goes to pot",
    "Breaks down",
    "Turns bad",
    "Falls into decay",
    "Goes bad",
    "Languishes in the lockup",
    "Spoils",
    "Goes bad",
    "Goes to hell",
    "Degenerates",
    "Spoils",
    "Fails to keep",
    "Fails to keep",
    "Goes bad, as fruit",
    "Breaks down",
    "Deteriorates",
    "Doesn't keep",
    "Goes bad",
    "Raises a stink?",
    "Breaks down",
    "Breaks down, in a way",
    "Makes a stink?",
    "Goes bad",
    "Doesn't keep",
    "Doesn't keep",
    "Goes bad",
    "Languishes in confinement",
    "Goes bad",
    "Falls apart",
    "Turns",
    "Doesn't keep",
    "Doesn't keep",
    "Goes to waste",
    "Decomposes",
    "Languishes",
    "Falls apart",
    "Molders",
    "Stagnates",
    "Spoils",
    "Putrefies",
    "Goes to waste",
    "Spoils"
  ],
  "wipe": [
    "Wet-Nap, for one",
    "Erase",
    "Clean (off)",
    "Film editor's gradual transition",
    "Moist towelette",
    "Utterly wear out, in slang",
    "Napkin, e.g.",
    "Erase",
    "Run a towel over",
    "Exhaust, with \"out\"",
    "Help the dishwasher, perhaps",
    "Cinematic scene-changer",
    "Towelette, e.g.",
    "Towel (off)",
    "Disposable cleaning aid",
    "Baby bottom cleaner",
    "Efface, with \"off\"",
    "Use a towel",
    "Film editor's technique",
    "Use a napkin",
    "Clear off",
    "Film editing technique",
    "Film editing effect",
    "Piece of absorbent material",
    "Help with the dishes",
    "Help with the dishes",
    "Dry dishes",
    "Towel (off)"
  ],
  "dunk": [
    "Showy basket",
    "Basketball highlight",
    "Make a show-offy basket",
    "Dip, as a doughnut",
    "Easy two-pointer in basketball",
    "Court stuff",
    "Submerge",
    "Drop, as a doughnut in milk",
    "Easy two-pointer",
    "Flashy basket",
    "Easy two-pointer",
    "Easy bucket"
  ],
  "dent": [
    "Sign of progress",
    "Not-so-great depression",
    "Result of a fender bender",
    "Bit of progress, metaphorically",
    "Job for a body shop",
    "Nick's cousin",
    "Leave a bad impression?",
    "Parking lot souvenir",
    "Defect in a can",
    "Feature of many an old car",
    "Some headway",
    "Ding",
    "Blue Book value decreaser",
    "Bit of initial progress",
    "Result of going bumper to bumper?",
    "A little progress, so to speak",
    "Bad impression?",
    "Bit of progress",
    "Ding",
    "Dimple",
    "A little progress, idiomatically",
    "What might result from a minor hit",
    "Make an impression on?",
    "It might be hammered out",
    "Impact result",
    "Bumper bummer",
    "Impression",
    "Dimple",
    "Result of a bang-up job?",
    "Tin can blemish",
    "Minor collision reminder",
    "Cause of a car rental surcharge",
    "Bit of bumper damage",
    "Bumper blemish",
    "Fender ding",
    "Bit of progress",
    "Car ding",
    "Ding on a car door, e.g.",
    "Repair shop job",
    "Car door ding",
    "Bumper blemish",
    "What's often pounded out",
    "Make an impression on?",
    "Bit of headway",
    "Lot \"souvenir\"",
    "Parking lot mishap",
    "1978 Yankees hero Bucky",
    "Ding",
    "Impression",
    "Ding",
    "Small bit of progress",
    "Ending of many toothpaste names",
    "Ding",
    "Results of a wrong turn, perhaps",
    "Ding",
    "Something hammered out",
    "Bad impression?",
    "Bit of headway",
    "Can blemish",
    "Subject of an insurance appraisal",
    "Impression",
    "Fender blemish",
    "Mar, in a way",
    "Make an impression",
    "Collision memento",
    "Fender blemish",
    "Mar, in a way",
    "Something that might be created by accident?",
    "Item for an insurance examiner",
    "Headway",
    "Fender blemish",
    "Job for a body shop",
    "Bumper blemish",
    "Car scar",
    "Nick",
    "Bad impression?",
    "Job for a body shop",
    "Fender problem",
    "Car owner's worry",
    "Job for a body shop",
    "New car owner's worry",
    "Target of a bang-up job?",
    "Ding",
    "Fender bender",
    "Fender flaw",
    "1978 Yankee hero",
    "More than a ding",
    "Small progress",
    "Car insurance case",
    "Fender flaw",
    "Fender bender memento",
    "Fender bender",
    "Hollow",
    "1978 Yankee hero",
    "Parking mishap"
  ],
  "sion": [
    "Priory of ___ (group in \"The Da Vinci Code\")",
    "\"The Da Vinci Code\" priory",
    "Priory in \"The Da Vinci Code\"",
    "Capital of the Swiss canton of Valais",
    "Priory in \"The Da Vinci Code\"",
    "\"The Da Vinci Code\" priory",
    "Capital of Valais",
    "Priory of ___ (group in \"The Da Vinci Code\")",
    "Capital of Valais",
    "\"The Da Vinci Code\" priory",
    "Priory of ___, group in \"The Da Vinci Code\"",
    "Capital of Valais canton",
    "Priory of ___, group in \"The Da Vinci Code\"",
    "Noun suffix",
    "Capital of Valais canton, Switzerland",
    "Suffix with scan"
  ],
  "desc": [
    "Abbr. on a family tree",
    "Fam. tree member",
    "Progeny: Abbr.",
    "Family tree listing: Abbr.",
    "Fam. tree member",
    "Family tree abbr.",
    "One with an ancestor: Abbr.",
    "Genealogical abbr.",
    "Family tree listing: Abbr.",
    "Fam. tree member",
    "Progeny: Abbr.",
    "Fam. tree member",
    "Genealogical abbr.",
    "Offspring, genealogically: Abbr.",
    "Family tree abbr.",
    "Offspring: Abbr."
  ],
  "lutz": [
    "Figure skating maneuver",
    "Difficult skating jump with a backward takeoff",
    "Turn on the ice",
    "Spin out on the ice?",
    "Figure skating jump",
    "Skating maneuver",
    "Skater's leap",
    "Skating jump",
    "Skating jump",
    "Skater's jump",
    "Skating maneuver",
    "Rink leap",
    "Leap for Lipinski",
    "Skater's jump",
    "Jump for Oksana Baiul"
  ],
  "dobe": ["\"___ careful, will you?\"", "\"___ careful\""],
  "imit": [
    "\"Just me!\"",
    "Tag line?",
    "\"That's me you're looking for\"",
    "Not genuine: Abbr.",
    "\"You're lookin' at your guy\"",
    "Words heard in 24-Across, maybe",
    "\"You're stuck with me\"",
    "\"You're looking at the whole department\"",
    "\"You've got no one else to turn to\"",
    "Tag statement",
    "Cry that makes children run away",
    "\"Nobody else is coming\"",
    "Cry during a recess game",
    "Not orig.",
    "\"You're looking at your guy!\"",
    "Not an orig.",
    "Not orig.",
    "Faux: Abbr.",
    "Spurious: Abbr.",
    "Not genuine: Abbr.",
    "Tag declaration",
    "Not genuine: Abbr.",
    "Not orig.",
    "Not orig.",
    "\"You're lookin' at your person!\"",
    "Not orig.",
    "Not orig.",
    "Not an orig."
  ],
  "whee": [
    "\"How fun!\"",
    "\"This is fun!\"",
    "\"So much fu-u-u-u-un!\"",
    "\"What fun!\"",
    "Exhilarated cry",
    "Cry on a roller coaster",
    "Cry from a thrill ride",
    "Roller coaster cry",
    "Cry on a roller coaster",
    "Cry while careering downhill",
    "Amusement park shout",
    "\"Whoopee!\"",
    "Cry on a roller coaster",
    "\"This is fun!\"",
    "Midway cry",
    "\"What a ride!\"",
    "Roller coaster cry",
    "\"This is fun!\"",
    "\"This is fu-u-u-un!\"",
    "Cry on a roller coaster",
    "Playground cry",
    "Thrill ride cry",
    "Cry of glee",
    "Coaster rider's cry",
    "Ferris wheel cry",
    "Roller coaster cry",
    "Carnival ride cry"
  ],
  "wigs": [
    "Options when picking locks?",
    "Freaks (out)",
    "Barristers' wear",
    "What many of the founding fathers wore",
    "Freaks (out)",
    "Freaks (out)",
    "Tops of many Halloween getups",
    "Bald people may wear them",
    "Drag queen's collection",
    "Halloween supplies",
    "Dome covers?",
    "Clown's supply",
    "Pate toppers",
    "False locks",
    "Barristers' accouterments"
  ],
  "char": [
    "Singe",
    "Get burnt",
    "Burn black",
    "Blacken on the barbecue",
    "Burn a little",
    "Scorch",
    "Make black, in a way",
    "A fish ... or to cook it, in a way",
    "Blacken, as by fire",
    "Arctic food fish",
    "Blacken on a grill",
    "Give a grilling?",
    "Blacken on a grill",
    "Scorch",
    "Not burn completely",
    "Blacken, in cooking",
    "Blacken on the grill",
    "Blacken",
    "Blacken",
    "Not just brown",
    "Blacken",
    "Blacken",
    "Blacken",
    "Scorch",
    "More than brown",
    "Burn on the grill",
    "Blacken",
    "Blacken",
    "Blacken on the barbecue",
    "Blacken",
    "Red-bellied trout",
    "Scorch",
    "Singe",
    "Reduce to carbon",
    "Blacken",
    "Dover domestic",
    "Toast",
    "Scorch",
    "Blacken",
    "Scorch",
    "Blacken",
    "Tea, to Brits",
    "Variety of trout",
    "Blacken",
    "Coventry cleaner",
    "Burn",
    "Overcook",
    "Burn",
    "Blacken",
    "Burn",
    "Burn a bit",
    "Blacken",
    "Blacken",
    "Burn slightly",
    "Blacken",
    "Blacken",
    "Flat cleaner",
    "Blacken, as steak",
    "Turn to cinders",
    "Burn",
    "Burn",
    "Scorch",
    "Brit cleaner",
    "Blacken",
    "Mayfair hotel employee",
    "Scorch",
    "House cleaner, in England"
  ],
  "goes": [
    "Takes a turn, in a board game",
    "Takes off",
    "Splits",
    "Sez",
    "Leaves",
    "Says, informally",
    "\"There ___ the neighborhood\"",
    "Departs",
    "Attends",
    "Says, informally",
    "Says, in teenspeak",
    "Departs",
    "Says, informally",
    "Anything ___",
    "\"Anything ___\"",
    "Exits",
    "Takes off",
    "With 34-Across, protests peacefully, in a way",
    "Says, in teenspeak",
    "Takes off",
    "Takes a turn",
    "\"Here ___!\"",
    "Decamps",
    "Pushes off",
    "Converts (to)",
    "Functions",
    "Takes a turn",
    "Travels",
    "Functions properly",
    "Parts",
    "\"There ___ the neighborhood!\"",
    "Takes a turn",
    "Takes off",
    "Leaves",
    "Heads off",
    "Operates",
    "Runs",
    "Pushes off",
    "Sells (for)",
    "Proceeds",
    "Examines, with \"over\"",
    "\"Anything ___\" (1934 or 1987 musical)",
    "Functions",
    "\"Anything___\"",
    "Accepts an invitation",
    "\"Here ___!\"",
    "Journeys"
  ],
  "hehe": [
    "Sound of a chuckle",
    "Mischievous titter",
    "Giggle",
    "Snide chuckle",
    "\"lol\" alternative",
    "Titter",
    "Giggle",
    "Titter",
    "[Giggle]",
    "Gleeful giggle",
    "[Giggle]",
    "Gleeful giggle",
    "Schemer's syllables",
    "Online giggle"
  ],
  "feat": [
    "Grand achievement",
    "Quite an accomplishment",
    "Abbr. between two singers' names",
    "Quite an accomplishment",
    "Bowling a 300, e.g.",
    "Herculean act",
    "Notable achievement",
    "Setting a world record, e.g.",
    "Daredevil's highlight",
    "Coup",
    "Breaking a world record, e.g.",
    "Quite an achievement",
    "It's impressive",
    "Impressive accomplishment",
    "Tour de force",
    "Guinness Book entry",
    "Exploit",
    "Grand slam, e.g.",
    "Quite an accomplishment",
    "Impressive act",
    "Accomplishment",
    "Juggling nine balls, e.g.",
    "Heroic deed",
    "Guinness entry",
    "Exploit",
    "Skillful act",
    "Many a Guinness listing",
    "Accomplishment",
    "Accomplishment",
    "Scoring a 10 in gymnastics, say",
    "Achievement",
    "Something to be proud of",
    "Bit of derring-do",
    "Coup",
    "Masterstroke",
    "Exploit",
    "Exploit",
    "Potential Guinness Book entry",
    "Achievement",
    "Achievement",
    "Guinness record, maybe",
    "Accomplishment",
    "Epic achievement"
  ],
  "muff": ["Botch, as a catch", "Blow", "Mess up"],
  "yams": [
    "Some Thanksgiving side dishes",
    "Dish next to stuffing and cranberry sauce",
    "Candied Thanksgiving servings",
    "Orange dish",
    "Thanksgiving dishes",
    "Underground crop",
    "Sweet tubers",
    "Vegetables high in vitamin C",
    "Thanksgiving dish",
    "Orange side dish",
    "Thanksgiving dish",
    "They're sweeter than sweet potatoes",
    "Orange tubers",
    "Vegetables whose skins may be cooked and eaten separately",
    "Candied dish",
    "Thanksgiving dish",
    "Thanksgiving dishes",
    "Thanksgiving serving",
    "Thanksgiving side dish",
    "Starchy side dish",
    "Thanksgiving fare",
    "Thanksgiving dishes",
    "Starchy fare",
    "Candied tubers",
    "Traditional Thanksgiving dish",
    "Thanksgiving dish",
    "Candied dish",
    "Thanksgiving dish",
    "Dish that's often mashed",
    "They're sometimes candied",
    "Thanksgiving dish",
    "Beta carotene sources",
    "Thanksgiving side dish",
    "Southern roots",
    "Mashed dish",
    "Orangeish vegetables",
    "Thanksgiving dish",
    "Thanksgiving bowlful",
    "Thanksgiving potatoes",
    "Thanksgiving dishes",
    "Thanksgiving dishes",
    "Candied items",
    "Sweet potatoes",
    "Tubers",
    "Candied items",
    "Good source of starch",
    "Orangish food",
    "Thanksgiving side dishes"
  ],
  "dike": [
    "Flood protector",
    "Construction on the coast of Holland",
    "Bank in need of support?",
    "It holds back the water in Holland",
    "Flood barrier",
    "Big bank investment?",
    "Dutch construction",
    "It may hold back the sea",
    "Bank manager?",
    "Bank guard?",
    "Bank with significant deposits?",
    "Zuider Zee sight",
    "Flood protector",
    "Dutch embankment",
    "It holds back the sea",
    "Big engineering project",
    "Bank check?",
    "Water barrier",
    "Retainer",
    "High ground in Dutch lowlands",
    "Sandbag stack, maybe",
    "Engineers' work",
    "Place for a finger?",
    "Water barrier",
    "Dutch tourist attraction",
    "Dutch engineerig feat",
    "Site along the IJsselmeer",
    "Water barrier"
  ],
  "acue": ["Take ___ from (follow)", "Take ___ from", "Take ___ from"],
  "rudy": [
    "___ Gobert, 2018 and 2019 N.B.A. Defensive Player of the Year",
    "Inspiring 1993 movie about a Notre Dame football team walk-on",
    "Former Big Apple mayor Giuliani",
    "1993 football movie starring Sean Astin",
    "Former New York mayor Giuliani",
    "New York's Giuliani",
    "Former New York mayor Giuliani",
    "Former Big Apple mayor Giuliani",
    "Basketball's Tomjanovich",
    "First name of \"America's Mayor\"",
    "New York's Giuliani",
    "Former name in Gracie Mansion",
    "Former mayor Giuliani",
    "New York's Giuliani",
    "The rich man in \"Rich Man, Poor Man\"",
    "First name in New York City politics"
  ],
  "joie": [
    "Screenwriter Lee, sister of Spike",
    "___ de vivre",
    "Gladness: Fr.",
    "___ de vivre",
    "___ de vivre",
    "___ de vivre",
    "___ de vivre"
  ],
  "cana": [
    "Site of an early miracle",
    "Where Jesus turned water into wine",
    "Biblical miracle town",
    "Veronese's \"The Wedding at ___\"",
    "Miracle site",
    "Biblical miracle setting",
    "Water-into-wine site",
    "Biblical water-to-wine locale",
    "Biblical water-to-wine site",
    "Water-to-wine site",
    "Biblical wedding site",
    "Biblical miracle setting",
    "Water-to-wine town",
    "Marriage site in a Veronese painting",
    "Water-to-wine site",
    "Galilee town in John 2:1",
    "Site of Jesus' first miracle",
    "Scene of Jesus' first miracle",
    "Miracle site in John 2:1",
    "Site of Jesus' first miracle",
    "Water-to-wine site",
    "Scene of Jesus' first miracle",
    "\"Marriage at ___\" (Veronese painting)",
    "Site of Jesus' first miracle",
    "Marriage site in John 2:1",
    "Site of Jesus' first miracle",
    "Biblical miracles site",
    "Where water turned to wine",
    "Miracle site",
    "Miracle site",
    "Site of Jesus' first miracle",
    "Site of Jesus' first miracle"
  ],
  "korn": [
    "Pioneering band in nu metal",
    "\"Freak on a Leash\" metal band",
    "Grammy-winning metal band with a tasty-sounding name"
  ],
  "rias": [
    "Narrow inlets",
    "Narrow estuaries",
    "Coastal inlets",
    "Shore indentations",
    "Inlets",
    "Inlets",
    "Narrow estuaries",
    "Narrow estuaries",
    "Narrow inlets",
    "Shore indentations",
    "Coastal indentations",
    "Estuaries",
    "Formations at the mouths of some streams",
    "Narrow inlets",
    "Cousins of fjords",
    "Coastal features",
    "Narrow inlets",
    "Narrow inlets",
    "Estuaries",
    "Narrow inlets",
    "Narrow inlets",
    "Narrow inlets",
    "Narrow inlets",
    "Shore indentations",
    "Inlets",
    "Inlets",
    "Inlets",
    "Narrow inlets",
    "Small estuaries",
    "Inlets",
    "Mooring sites",
    "Narrow inlets",
    "Creeks",
    "Inlets"
  ],
  "bach": [
    "\"Mass in B Minor\" composer",
    "Contemporary of Vivaldi",
    "\"Coffee Cantata\" composer",
    "Name that follows J. S. or P. D. Q.",
    "Master of fugues",
    "\"Coffee Cantata\" composer",
    "\"St. John Passion\" composer",
    "One of the Three B's of classical music",
    "Bond girl Barbara",
    "\"Brandenburg Concertos\" composer",
    "\"Godel, Escher, ___\" (Douglas Hofstadter book)",
    "\"The Art of Fugue\" composer",
    "Composer with 20 children",
    "One of the Three B's",
    "One of the Three B's",
    "\"The Well-Tempered Clavier\" composer",
    "\"Brandenburg Concertos\" composer",
    "\"Goldberg Variations\" composer",
    "\"English Suites\" composer",
    "\"Goldberg Variations\" composer",
    "Canon creator",
    "\"Sheep May Safely Graze\" composer",
    "Mass producer",
    "One of the three B's",
    "\"Goldberg Variations\" composer",
    "He went for baroque",
    "\"Passion According to St. John\" composer",
    "One of the Three B's",
    "\"Art of the Fugue\" composer",
    "One of the 3 B's",
    "Fugue master",
    "\"Brandenburg Concertos\" composer",
    "One of the Three B's of classical music",
    "Johann Sebastian ___",
    "One of the three B's",
    "Fugue master",
    "Composer with a clavier",
    "\"St. John Passion\" composer"
  ],
  "palp": [
    "Bug's sensory appendage",
    "Insect's feeler",
    "Sensory appendage",
    "Insect organ",
    "Feeler",
    "Insect's sense organ",
    "Feeler"
  ],
  "zigs": [
    "Begins some evasive maneuvering",
    "Makes a sharp turn",
    "Veers quickly",
    "Moves erratically",
    "Goes this way before that",
    "Suddenly changes course",
    "Makes an unexpected move",
    "Takes a jog",
    "Turns sharply",
    "Turns sharply",
    "Turns sharply",
    "Sharp turns",
    "Sidesteps"
  ],
  "mute": [
    "Sound off?",
    "Zoom call option",
    "Trumpet accessory",
    "Make silent",
    "Silence",
    "Cello attachment near the bridge",
    "Remote control button",
    "Silent",
    "Remote possibility?",
    "Remote button",
    "Turn off, maybe",
    "Remote button",
    "Remote button",
    "Quiet",
    "Remote button",
    "Speechless",
    "Make less flamboyant",
    "Not just turn down",
    "Universal remote button",
    "Remote option",
    "Remote option",
    "Button on a remote",
    "Saying nothing",
    "Remote choice",
    "Still",
    "Remote option",
    "Muffler",
    "Bell insert",
    "Remote button",
    "Quiet",
    "Remote selection",
    "Remote button",
    "Silence",
    "Trombone attachment",
    "Speechless",
    "Remote option",
    "Remote control button",
    "Remote control button",
    "Speechless",
    "Remote button",
    "Silent",
    "Tone down",
    "Trombone device"
  ],
  "cloy": [
    "Sicken with sweetness",
    "Be too sweet, possibly",
    "Please too much",
    "Sicken with sweetness",
    "Become too sweet after a while, say",
    "Be too sweet",
    "Sicken with sentiment",
    "Be overly sweet",
    "Grow tiresome",
    "Get old",
    "Be too sweet",
    "Be too syrupy",
    "Fill to excess",
    "Surfeit",
    "Be too sweet",
    "Satiate",
    "Be too much",
    "Fill to excess",
    "Pall",
    "Satiate",
    "Become tiresome"
  ],
  "arfs": [
    "Sounds at pounds",
    "Some canine sounds",
    "Relatives of yips",
    "Sounds from schnauzers",
    "Meows : cats :: ___ : dogs",
    "Lab sounds?",
    "Pounds' sounds",
    "Pounds' sounds",
    "Many masters respond to them",
    "Pekingese sounds",
    "Pound's sounds",
    "Boxer rebellion cries?",
    "Barks",
    "Pound racket",
    "Barks",
    "Basset sounds",
    "Terriers' warnings",
    "Schnauzer sounds",
    "Pet sounds",
    "Terrier sounds",
    "Obedience school sounds",
    "Pound sounds",
    "Doggie sounds",
    "Barks",
    "Poodle sounds",
    "Sounds from the end of a leash",
    "Canine pleas",
    "Kennel racket",
    "Canine clamor",
    "Expressions of puppy love",
    "Pound sounds",
    "Spot announcements?",
    "Yaps",
    "Canine sounds",
    "Pound sounds",
    "Pounds' sounds",
    "Pound sounds",
    "Barks",
    "Barks",
    "Pound's sounds",
    "Sandy's sounds",
    "Sandy's barks",
    "Canine cries"
  ],
  "kidd": [
    "Sue Monk ___, author of \"The Secret Life of Bees\"",
    "Captain played twice in film by Charles Laughton",
    "Player/coach Jason of the N.B.A.",
    "Pirate captain whose treasure was thought to be buried on Oak Island",
    "Pirate captain mentioned in Poe's \"The Gold-Bug\"",
    "Jason of the N.B.A.",
    "N.B.A. player-turned-coach Jason",
    "Captain ___ (pirate)",
    "Captain ___, legendary pirate",
    "Privateer who captained the Blessed William",
    "Jason of the N.B.A.",
    "English pirate captain",
    "Pirate whose treasure is recovered in Poe's \"The Gold-Bug\"",
    "William the pirate",
    "Pirate captain of legend",
    "Jason of the N.B.A.",
    "N.B.A. star Jason",
    "Hanged pirate",
    "Capturer of the Quedagh Merchant",
    "Noted captain"
  ],
  "corp": [
    "N.Y.S.E. listing: Abbr.",
    "Bus. concern",
    "News ___ (media giant)",
    "___ limit (sign at the edge of town)",
    "N.Y.S.E. listing ... also what can fill the square at the crossing of 24-Across and 25-Down",
    "The \"C\" of FDIC: Abbr.",
    "Nasdaq listing: Abbr.",
    "G.M. or G.E.",
    "Many a Del. registration",
    "N.Y.S.E. listing",
    "Company: Abbr.",
    "Wall Street Journal subj.",
    "Trust, e.g.: Abbr.",
    "I.B.M., e.g.",
    "Business org.",
    "I.B.M. or 3M, e.g.: Abbr.",
    "Business abbr.",
    "Fortune 500 abbr."
  ],
  "xray": [
    "See through?",
    "Letter in the NATO alphabet",
    "Cavity revealer",
    "Kind of vision",
    "Discovery of Wilhelm Roentgen, which earned him the first Nobel Prize in Physics in 1901",
    "Look through?",
    "Like Superman's vision",
    "Cavity detector",
    "Help in finding fractures",
    "Word with tube or vision",
    "Shot after a break?",
    "Aid in finding a break",
    "*Revealing photo",
    "Look inside?",
    "Airport security measure",
    "With 13-Down, \"super power\" glasses",
    "Photo often taken after an accident",
    "Look inside?",
    "Inside look?",
    "Look into?",
    "Look inside",
    "Get an inside look at",
    "Like Superman's vision",
    "Look inside?",
    "Kind of vision",
    "Interior view",
    "Look into?",
    "Picture of health?",
    "Picture of health?",
    "Get an inside look",
    "Picture of health?",
    "Revealer of the inner self?",
    "Part of a dental exam",
    "Roentgen's discovery",
    "Inside view?",
    "Inside view",
    "Inside shot?",
    "Orthopedist's tool",
    "Inside look",
    "Picture of a physician",
    "Inner vision",
    "Like Superman's vision",
    "Fracture detector",
    "Like Superman's vision",
    "Dental exam part",
    "Inside shot?",
    "___ vision (Superman skill)",
    "Physician's photo",
    "Dental photo",
    "Kind of vision"
  ],
  "ians": [
    "Johns, to Scots",
    "Johns across the pond",
    "Actors McShane and McKellen",
    "Writer Fleming and others",
    "Johns of Britain",
    "Actor McKellen and others",
    "Fleming and McEwan",
    "Baseball All-Star Kinsler and others",
    "Writers Fleming and McEwan",
    "McShane and McKellen",
    "Tenor Bostridge and others",
    "Northern Ireland politician Paisley and others",
    "Fleming and Paisley",
    "Actors Holm and McKellen",
    "Hunter and Holm",
    "Mathematician Stewart and others",
    "Hunter and others",
    "Anderson and Fleming",
    "Writer Buruma and others",
    "Fleming and McKellen",
    "Christ's following?",
    "Actors McKellen and Holm",
    "Author Fleming and others",
    "Fleming and others",
    "Fleming and namesakes",
    "Scottish playwright Hay et al.",
    "Holm and Hunter",
    "English actor Carmichael et al.",
    "Actor Holm et al.",
    "Paisley and Fleming",
    "McKellen and Fleming",
    "Actors McKellen and Holm",
    "Johns, in the Highlands"
  ],
  "jodi": ["\"My Sister's Keeper\" author Picoult"],
  "pail": [
    "Sand castle shaper",
    "Beachgoer's item",
    "It holds water",
    "Shovel's go-with",
    "Water holder for a farm animal",
    "Vessel for Jack and Jill",
    "Beach toy with a handle",
    "Burden for Jack and Jill",
    "Beach mold",
    "Container in a dairy barn",
    "Something to take to a beach",
    "Lunch container, for some",
    "Bucket",
    "Water bearer",
    "Nursery rhyme vessel",
    "Construction worker's lunch container",
    "Beachgoer's item",
    "Something well-placed?",
    "Sandbox item",
    "Water carrier",
    "Bucket",
    "Bucket",
    "Milkmaid's need",
    "Stopgap measure for a 28-Across",
    "Gardener's accessory",
    "Bucket",
    "Beach item",
    "Beach item",
    "Mop's companion",
    "Jack and Jill's vessel",
    "Kid's beach toy",
    "Beach sight",
    "Gardener's equipment",
    "Load for Jack and Jill",
    "Easter egg hunt sight",
    "Bucket",
    "Item for Jack and Jill",
    "Big dipper",
    "Bucket",
    "Bucket",
    "Vessel for Jill"
  ],
  "arks": [
    "Torah holders",
    "Asylums",
    "Temple cabinets",
    "Torah holders",
    "Sizable vessels",
    "Temple cabinets",
    "Some refuges",
    "Torah vessels",
    "Torah holders",
    "Torah receptacles",
    "Chests in synagogues",
    "Flat-bottomed boats of old",
    "Torah holders",
    "Boats like the one Noah built",
    "Shul fixtures",
    "Temple receptacles",
    "Refuges",
    "They may come with covenants",
    "Safe havens",
    "Covenant keepers",
    "Clumsy boats",
    "Torahs are kept in them",
    "Torah holders",
    "Scroll holders",
    "Scroll holders",
    "Scroll storers",
    "Big boats",
    "They're open on Saturdays",
    "Sacred chests",
    "Torah holders",
    "Torah holders",
    "Safe havens",
    "Temple cases",
    "30-Down holders",
    "Refuges",
    "Scroll holders",
    "Torah holders",
    "Commodious craft",
    "Synagogue cabinets",
    "Asylums",
    "Sacred chests",
    "Torah holders",
    "Torah holders",
    "Refuges",
    "Symbols of safe passage",
    "Synagogue chests",
    "Synagogue cabinets",
    "Sizable vessels",
    "Refuges",
    "Boats like Noah's",
    "Havens",
    "Clumsy boats",
    "Vessels also called broadhorns",
    "Torah holders",
    "Commodious boats",
    "Refuges",
    "Torah storers",
    "Places of refuge",
    "Torah holders",
    "Boats like 3-Down's",
    "Synagogue chests",
    "Places where Torah scrolls are kept",
    "Refuges",
    "Old flatboats",
    "Places of protection",
    "Old flatboats",
    "Safe places",
    "Torah holders",
    "Synagogue cabinets",
    "Refuges",
    "Asylums",
    "Boats like Noah's",
    "Clumsy ships",
    "Clumsy craft",
    "Clumsy boats",
    "Synagogue cabinets"
  ],
  "rigs": [
    "Fixes, as an outcome",
    "Lineup at a truck stop",
    "Fixes",
    "Weigh station lineup",
    "Fixes wrongly?",
    "Oil machinery",
    "Fixes",
    "Fixes badly?",
    "Ocean eyesores",
    "Drilling sites",
    "Oil field sights",
    "Arranges",
    "Semis",
    "Hard-to-park vehicles",
    "Throws together",
    "Fixes",
    "Fixes",
    "Truck stop stoppers",
    "Semis",
    "Fixes illegally",
    "Puts in proper order, nautically",
    "Teamsters' vehicles",
    "Fixes",
    "Convoy lineup",
    "Fixes, in a way",
    "Fixes",
    "Fixes, in a way",
    "Fixes",
    "Fixes, as fights",
    "Tractor-trailers",
    "Convoy components",
    "Equips",
    "Fixes, illegally",
    "Drawn carriages",
    "Fixes",
    "Truck stop stoppers"
  ],
  "marx": [
    "Karl who co-wrote a manifesto",
    "His tombstone reads \"Workers of all lands unite\"",
    "Influence on Castro and Mao",
    "\"Das Kapital\" author",
    "See 10-Down",
    "Who said \"Revolutions are the locomotives of history\"",
    "Who said religion \"is the opium of the people\"",
    "Who said \"Revolutions are the locomotives of history\"",
    "Funny Groucho or Harpo",
    "Who said history repeats itself \"the first time as tragedy, the second time as farce\"",
    "Harpo, Zeppo or Groucho",
    "Karl, Richard or Harpo",
    "Richard with the 1989 #1 hit \"Right Here Waiting\"",
    "\"I have had a perfectly wonderful evening, but this wasn't it\" speaker",
    "Groucho or Chico",
    "Karl with a manifesto",
    "\"The Poverty of Philosophy\" author",
    "Any brother in \"Animal Crackers\"",
    "Last name in comedy",
    "With 53-Across, noted comedy group, in brief",
    "Karl who philosophized about class struggle",
    "Philosopher who gave his name to an -ism",
    "Manifesto writer",
    "Out-of-favor icon",
    "\"Manifesto\" writer",
    "Zeppo, for one",
    "\"Capital\" fellow",
    "Groucho or Zeppo",
    "Communist Karl",
    "Pop singer Richard",
    "\"Das Kapital\" author"
  ],
  "tick": [
    "Target of a camper's scalp-to-toe inspection",
    "Sound effect during a bomb defusing, perhaps",
    "Clock sound",
    "Camping menace",
    "Sound of a watch",
    "Check mark",
    "OFF! target",
    "Stock price movement",
    "Watch sound",
    "Peeve, with \"off\"",
    "Second indicator?",
    "\"60 Minutes\" sound",
    "Watch's sound",
    "Clock sound",
    "Watch word?",
    "Watch word?",
    "Clock sound",
    "Watch word",
    "Backbiter?",
    "___ off (anger)"
  ],
  "skee": [
    "___-Ball",
    "___-Ball (carnival attraction)",
    "___-Ball",
    "___-Ball",
    "___-Ball (arcade game)",
    "___-Ball",
    "\"I Wish\" rapper ___-Lo",
    "Ball opener?",
    "___-Ball",
    "___-Ball",
    "___-Ball",
    "___-Ball",
    "___-Lo (\"I Wish\" rapper)",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball",
    "___-Ball",
    "___-Ball (game with scores up to 100)",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball",
    "___-Ball (arcade game)",
    "Rapper ___-Lo",
    "___-Ball",
    "___-Ball (game on an incline)",
    "___-Ball",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball",
    "\"I Wish\" rapper ___-Lo",
    "___-Ball",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "Arcade game starter",
    "___-Ball",
    "___-Ball",
    "___-Ball",
    "___-Ball",
    "___-Ball",
    "___-Ball",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball",
    "___-Ball",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)",
    "___-Ball",
    "___-Ball (arcade game)",
    "___-Ball (pay-to-play game)",
    "___-Ball (arcade game)",
    "___-Ball (arcade game)"
  ],
  "sags": [
    "Slumps",
    "Really sinks in",
    "Slumps",
    "Flags",
    "Droops",
    "Loses rigidity",
    "Dips",
    "Downturns",
    "Wilts",
    "Succumbs to gravity",
    "Droops",
    "Slumps",
    "Droops",
    "Is low around the waist, as pants",
    "Gives a little",
    "Loses intensity",
    "Doesn't look at all youthful",
    "Wilts",
    "Drops off",
    "Isn't straight in the middle",
    "Doesn't stay up",
    "Doesn't hold up well",
    "Droops",
    "Slumps",
    "Succumbs to gravity",
    "Gives in to gravity",
    "Wilts",
    "Gives under pressure",
    "Droops",
    "Is looking tired",
    "Droops",
    "Isn't robust",
    "Droops",
    "Can't stand the weight",
    "Droops",
    "Loses rigidity",
    "Looks pooped",
    "Needs a facelift",
    "Is weary",
    "Is in a slump"
  ],
  "anse": [
    "\"As I Lay Dying\" father",
    "Addie's husband in \"As I Lay Dying\"",
    "\"As I Lay Dying\" father",
    "Father in \"As I Lay Dying\"",
    "Devil ___ Hatfield, Golden Globe-winning role for Kevin Costner",
    "Addie's husband in \"As I Lay Dying\"",
    "\"As I Lay Dying\" father",
    "Addie's husband in \"As I Lay Dying\"",
    "\"As I Lay Dying\" father",
    "\"As I Lay Dying\" character",
    "Faulkner hero",
    "\"As I Lay Dying\" father",
    "\"As I Lay Dying\" father",
    "\"As I Lay Dying\" character",
    "Handle: Fr.",
    "Addie's husband in \"As I Lay Dying\"",
    "\"As I Lay Dying\" father",
    "\"As I Lay Dying\" father",
    "\"As I Lay Dying\" character",
    "Handle: Fr.",
    "Addie's husband in \"As I Lay Dying\""
  ],
  "roux": [
    "Component of bechamel sauce",
    "Gravy thickener",
    "Sauce thickener",
    "Sauce thickener",
    "Gumbo thickener",
    "Sauce thickener",
    "Whisked mixture",
    "Sauce thickener",
    "Soup thickener",
    "Gumbo thickener",
    "Sauce thickener",
    "Sauce thickener",
    "Sauce thickener",
    "Sauce thickener"
  ],
  "nary": [
    "Quaint \"not\"",
    "Antiquated \"not\"",
    "Old-timey \"not\"",
    "___ a soul (no one)",
    "Not a single",
    "___ a one",
    "___ a soul",
    "Old-timey \"not\"",
    "___ a one",
    "___ a one (zero)",
    "Not one, colloquially",
    "___ a one",
    "Hardly",
    "___ a soul",
    "___ a one",
    "___ a one",
    "___ a soul",
    "___ a one",
    "___ a one",
    "___ a scratch",
    "___ a one",
    "Not any",
    "Not",
    "Not",
    "Quaint \"not\"",
    "___ a one",
    "Not",
    "Not, in dialect",
    "___ a one",
    "Quaint negative",
    "___ a soul",
    "___ a one (zip)",
    "Hardly",
    "___ a soul",
    "___ a one",
    "___ a soul",
    "___ a soul",
    "___ a one",
    "___ a one",
    "Not (a one)",
    "Word before \"a soul\"",
    "Not",
    "Not",
    "___a one",
    "___ a one",
    "___ a soul",
    "___ a one",
    "___ a one",
    "Colloquial \"not\"",
    "Not any",
    "___ a one",
    "Not any"
  ],
  "dust": [
    "Tidy up ... or make less tidy",
    "Cover lightly, as snow",
    "Do a little tidying",
    "Prep to find fingerprints",
    "Attic accumulation",
    "Attic buildup",
    "Do some housekeeping",
    "Sign of neglect",
    "Tidy up, in a way",
    "Attic accumulation",
    "Tidy up, in a way",
    "Make dirty ... or clean",
    "Make clean ... or dirty",
    "Common settler",
    "Do some housecleaning",
    "Indoor settler",
    "Do some cleaning",
    "Saw stuff?",
    "Bite this and die",
    "It settles indoors",
    "It looks bad on the surface",
    "Do some housecleaning",
    "Sneeze inducer",
    "Run a cloth over",
    "Composition of some clouds",
    "Do some housework",
    "Problem for Sneezy?",
    "Furniture buildup",
    "Neatnik's bane",
    "Maid's target",
    "Fine powder",
    "Motes"
  ],
  "joel": [
    "Billy in the Rock and Roll Hall of Fame",
    "Book before Amos",
    "Follower of Hosea in the 31-Across",
    "One of the Coen brothers",
    "Follower of 21-Across",
    "Book after Hosea",
    "Billy a.k.a. the Piano Man",
    "Spingarn of the N.A.A.C.P.'s Spingarn Medal",
    "Prophet read on Ash Wednesday",
    "Biblical preceder of 27-Down",
    "Book before Amos",
    "Billy who sang \"We Didn't Start the Fire\"",
    "\"Piano Man\" performer Billy",
    "\"Piano Man\" singer",
    "Follower of Hosea",
    "\"My Life\" singer, 1978",
    "Biblical prophet",
    "Director Schumacher",
    "One of the Coen brothers",
    "Book before Amos",
    "\"It's Still Rock and Roll to Me\" singer Billy",
    "Author ___ Chandler Harris"
  ],
  "abed": [
    "Not up",
    "Not yet astir",
    "Under the covers",
    "Not up",
    "Tucked in",
    "In the sack",
    "Laid up",
    "Not up",
    "Still snoozing, say",
    "Not up",
    "Not up",
    "Undercover?",
    "Lying, maybe",
    "Sacked out",
    "Not yet up",
    "Sacked out",
    "Resting at night",
    "Resting, say",
    "Not up",
    "Sleeping, say",
    "Sleeping in, say",
    "Still sleeping",
    "Sleeping, most likely",
    "Opposite of up",
    "Lying, in a way",
    "In dreamland, perhaps",
    "Laid up",
    "Supine, possibly",
    "In the sack",
    "Not up",
    "Sleeping, say",
    "In the sack",
    "Retired",
    "Horizontal, perhaps",
    "Still snoring",
    "Sacked out",
    "In the sack",
    "Tucked in",
    "Under cover?",
    "Sacked out",
    "Not up",
    "Not up yet",
    "In the sack",
    "Tucked in",
    "Not up",
    "Unwell, perhaps",
    "Out, in a way",
    "Lying, maybe",
    "In for the night",
    "Under cover, perhaps",
    "Not up",
    "Lying, maybe",
    "Not up",
    "Under the covers",
    "Resting",
    "Out for the night",
    "Not up",
    "Still sleeping",
    "Laid up",
    "Not up yet",
    "Still sleeping",
    "Retired",
    "Out for the night",
    "Not up",
    "Laid up",
    "Still sleeping",
    "Laid up, so to speak",
    "Sleeping",
    "Slumbering",
    "Out for the night",
    "Sacked out",
    "Not up"
  ],
  "acro": [
    "Lead-in to phobia",
    "Height: Prefix",
    "Prefix meaning \"height\"",
    "Prefix with phobia",
    "Height: Prefix",
    "Prefix with -polis",
    "Prefix with phobia",
    "Common prefix with phobia",
    "Top: Prefix",
    "\"-phobia\" start",
    "Height: Prefix",
    "Prefix with phobia",
    "Onset of phobia?",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with phobia",
    "Height: Prefix",
    "Prefix with -polis",
    "Top: Prefix",
    "Lofty beginning?",
    "Height: Prefix",
    "Height: Prefix",
    "Tip end: Prefix",
    "Prefix with -polis",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with phobic",
    "Height: Prefix",
    "Prefix with phobia",
    "Height: Prefix",
    "Height: Prefix",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with 25-Across",
    "Height: Prefix",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with phobia",
    "Phobia starter",
    "Prefix with phobia",
    "Prefix with phobia",
    "Common prefix with phobia",
    "High: Prefix",
    "Prefix with phobia",
    "Height: Prefix",
    "Prefix with phobia",
    "Kind of phobia"
  ],
  "osse": [
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix"
  ],
  "vaca": [
    "Cow, in Cadiz",
    "Cow, in Colombia",
    "Cow, in Cadiz",
    "Explorer Cabeza de ___",
    "Cow: Sp.",
    "Cow: Sp.",
    "Explorer Cabeza de ___",
    "Explorer Cabeza de ___"
  ],
  "otra": [
    "___ vez (again, in Spanish)",
    "___ vez (again, in Spanish)",
    "___ vez (again: Sp.)",
    "Other, in Oaxaca",
    "\"Por ___ parte\" (Spanish for \"on the other hand\")",
    "De ___ manera (elsewise: Sp.)",
    "Other: Sp.",
    "___ cosa (something else: Sp.)",
    "Another, in Aragon",
    "___ vez (again: Sp.)",
    "___ vez (again: Sp.)",
    "Other: Sp.",
    "Not esta or esa",
    "Other: Sp.",
    "___ vez (again: Sp.)",
    "___ vez (again: Sp.)",
    "Spanish alternative?",
    "Other, in Oaxaca",
    "Other, in Oviedo",
    "___ vez (again: Sp.)",
    "Tomas's \"other\"",
    "Other: Sp.",
    "___ vez (again: Sp.)",
    "Other, to Orozco",
    "Not esta or esa",
    "Other, in Oaxaca",
    "Check box option on a Spanish survey?",
    "Barbara Kingsolver's \"___ America\"",
    "___ vez (again, in Spanish)",
    "Other: Sp.",
    "Other, in the barrio",
    "\"La ___,\" 1946 Dolores del Rio film",
    "___ vez (again, in Spanish)",
    "Paulina's \"other\"",
    "Not this or that, in Spain",
    "Barbara Kingsolver's \"___ America\"",
    "___ vez (again): Sp.",
    "Not esa or esta",
    "Other, in Madrid",
    "\"La ___ Conquista\" (hit 1999 Latino film)",
    "Other, to Ortega",
    "Another, for Andalusians",
    "___ vez (again): Sp.",
    "Other, in Oaxaca",
    "Other, in Espana",
    "___ vez",
    "Other, to Ortega",
    "Other, in Oaxaca",
    "___ vez (again, in Spanish)",
    "Not this or that, below the border",
    "Another, overseas",
    "Not this or that, in Spain",
    "Other, in Oaxaca",
    "___ cosa (something else): Sp.",
    "Not esta or esa",
    "___ vez (again, in Acapulco)",
    "___ cosa (something else)",
    "Spaniard's other",
    "Other, in the barrio",
    "Other, to Orozco",
    "___ vez (again): Sp.",
    "Other: Sp.",
    "Other: Sp.",
    "Spanish other",
    "___ cosa (something else): Sp."
  ],
  "abcd": [
    "Passing options",
    "First string?",
    "Opening of a kid's song",
    "Pre-K song start",
    "Start of a nursery rhyme",
    "Opening letters",
    "Start of a common run",
    "Children's tune starter",
    "Children's song start",
    "Starting four",
    "Opening run",
    "Starting lineup",
    "Opening run",
    "Multiple choice choices",
    "Alphabetical start",
    "Quartet for first graders",
    "Black verse rhyme scheme",
    "Starting four",
    "Start of a children's rhyme",
    "Opening letters",
    "\"Alphabet Song\" start"
  ],
  "tref": [
    "Not kosher",
    "Not kosher, in Jewish law",
    "Like shellfish",
    "Forbidden, in a way",
    "Not kosher",
    "Nonkosher",
    "Not kosher",
    "Like shellfish",
    "Not kosher",
    "Not kosher",
    "Not kosher",
    "Not allowed on certain diets",
    "Not kosher",
    "Not kosher",
    "Not kosher",
    "Not kosher",
    "Not kosher",
    "Not kosher",
    "Nonkosher",
    "Not kosher"
  ],
  "recs": [
    "Requests made to profs for postgrad programs",
    "Parts of a college app",
    "Letters accompanying college applications, for short",
    "CDs, LPs, etc.",
    "Letters of support, for short",
    "Letters teachers send to colleges, informally",
    "LPs and CDs: Abbr.",
    "LP's and CD's: Abbr.",
    "RCA products: Abbr.",
    "LP's: Abbr."
  ],
  "take": [
    "Word with hot or spit",
    "Appropriate",
    "Opinion, informally",
    "With 45-Across, focus directly on hitting someone",
    "Stomach",
    "Opinion",
    "With 25-Down, office request",
    "Stomach",
    "Grab",
    "Bit of filming",
    "Give's opposite",
    "Box-office receipts",
    "With \"in\" and 55-Down, use without proper respect, as a name",
    "Bit of filming",
    "Assume",
    "Capture",
    "Seize",
    "Bit of filming",
    "Chews",
    "Help oneself to",
    "Proceeds",
    "Receipts",
    "Opinion",
    "Tolerate",
    "Plunder",
    "Seize",
    "Crib",
    "See 8-Down",
    "Appropriate",
    "Thieves' accumulation",
    "Proceeds",
    "Bit of filming",
    "With 25-Down, be prudent",
    "Capture",
    "Profits",
    "Receipts",
    "Bit of filming",
    "Give's partner",
    "Grab hold of",
    "Profits",
    "Money brought in",
    "Seize",
    "Revenue",
    "Heist tally",
    "Profits, informally",
    "Appropriate",
    "Receipts",
    "Fish haul"
  ],
  "mimi": [
    "Only character with the same name in both \"Rent\" and \"La Boheme\"",
    "\"The Emancipation of ___\" (6x platinum Mariah Carey album)",
    "Lead role in \"Rent\"",
    "\"Rent\" role",
    "\"La Boheme\" soprano",
    "\"Rent\" character ___ Marquez",
    "Puccini seamstress",
    "Food critic Sheraton",
    "Actress Rogers",
    "Actress Rogers",
    "\"La Boheme\" heroine",
    "\"La Boheme\" role",
    "\"Si, mi chiamano ___\" (Puccini aria)",
    "\"La Boheme\" soprano",
    "Maurice Chevalier song",
    "Actress Rogers",
    "Actress Rogers who was once married to Tom Cruise",
    "Actress Rogers",
    "\"La Boheme\" heroine",
    "\"La Boheme\" soprano",
    "Actress Rogers",
    "Noted woman?",
    "Rodolfo's love in \"La Boheme\"",
    "\"La Boheme\" heroine",
    "Food critic Sheraton",
    "\"La Boheme\" heroine",
    "\"La Boheme\" heroine",
    "\"La Boheme\" heroine",
    "Food critic Sheraton",
    "\"La Boheme\" heroine",
    "\"The Drew Carey Show\" woman",
    "Maurice Chevalier's theme song",
    "\"La Boheme\" soprano",
    "Actress Rogers",
    "Actress Rogers",
    "Food critic Sheraton",
    "\"La Boheme\" role"
  ],
  "pair": [
    "Single piece of underwear, paradoxically",
    "Unexciting Yahtzee roll",
    "It beats nothing",
    "Low poker holding",
    "Duo",
    "Two of a kind",
    "Part of a full house",
    "Modest poker holding",
    "Glove purchase",
    "Match (with)",
    "Unexciting poker holding",
    "Part of a full house",
    "Two turtledoves, e.g.",
    "Two twos, for one",
    "Hose purchase",
    "Duo",
    "One plus one",
    "Brace",
    "Put together, as socks",
    "Nonpolygamous grouping",
    "10-10 or Q-Q",
    "Skating competition entry, maybe",
    "Couple",
    "Modest poker holding",
    "Low poker holding",
    "Match",
    "Couple",
    "Match (up)",
    "Two threes, for one",
    "Low poker holding",
    "Au ___",
    "Two twos, say",
    "Duo",
    "It's better than ace-high",
    "Ark unit",
    "See 25-Down",
    "Four and four, say",
    "Poker holding",
    "Ark complement",
    "Two of a kind",
    "Poker holding",
    "Entry in an ice-skating competition",
    "It's not much to show",
    "Full house holding",
    "Tabloid topic",
    "Poker holding lower than three-of-a-kind",
    "Ice dancing team",
    "69-Across component",
    "Marriage requirement",
    "Low poker holding",
    "Weak poker hand",
    "Brace",
    "Eyes or ears",
    "One and one",
    "Complement of socks",
    "Twosome",
    "Unexciting poker holding",
    "Duet",
    "A three-of-a-kind beats it",
    "Twosome",
    "Two socks",
    "Match",
    "Part of a full house",
    "Brace",
    "Au ___",
    "Poker holding",
    "Twosome",
    "Hosiery purchase",
    "Poker holding",
    "Modest hand"
  ],
  "brae": [
    "Highland slope",
    "Scottish hillside",
    "Highlands hillside",
    "Scottish hillside",
    "Landform near a loch",
    "Scottish hillside",
    "Hill by a loch",
    "Scottish hillside",
    "\"Bramble ___\" (book of Robert Bridges poems)",
    "Aberdeen hillside",
    "Scottish slope",
    "Scottish hillside",
    "Hill near a loch",
    "Scottish hillside",
    "Scottish hillside",
    "Highlands hillside",
    "Scottish hillside",
    "Scottish hillside",
    "Scottish hillside",
    "Highlands hillside",
    "Aberdeen hillside",
    "Scottish hillside",
    "Mill Hole ___ (home for Robert Burns)",
    "Scottish hillside",
    "Scottish hillside",
    "Highlands hillside",
    "Highlands hillside",
    "Highlands hillside",
    "Highlands hillside",
    "Greenock hillock",
    "Scottish hillside",
    "Scottish hillside",
    "Scottish hillside"
  ],
  "gird": [
    "Steel (oneself)",
    "Brace",
    "Bind with a belt",
    "Strengthen",
    "Prepare for a struggle",
    "Fortify",
    "Encircle",
    "Encircle with a belt",
    "Encircle",
    "Encircle",
    "Encircle",
    "Bind with a belt",
    "Bind with a band",
    "Encircle",
    "Put a belt on",
    "Surround",
    "Encircle",
    "Encircle",
    "Fasten with a belt",
    "Surround",
    "Surround",
    "Encircle",
    "Surround",
    "Prepare for action",
    "Surround",
    "Brace for trouble",
    "Prepare for action",
    "Encircle"
  ],
  "eder": [
    "River of Germany",
    "River of central Germany",
    "Linda of Broadway's \"Jekyll & Hyde\"",
    "River of Hesse",
    "Fulda tributary",
    "Pulitzer-winning critic Richard",
    "Hessian river",
    "Broadway singer Linda",
    "River of Hesse",
    "River of Hesse",
    "Linda of Broadway's \"Jekyll & Hyde\"",
    "Critic Richard",
    "Pulitzer-winning critic Richard",
    "Linda of Broadway",
    "Fulda feeder",
    "Broadway star Linda who won $100,000 on \"Star Search\"",
    "Schwalm-___ (German district)",
    "Singer/actress Linda",
    "German river",
    "German river to the Fulda",
    "Pulitzer-winning critic Richard",
    "German river in a 1943 R.A.F. raid",
    "River of central Germany",
    "River of Hesse",
    "Linda of \"Jekyll & Hyde\"",
    "Linda of \"Jekyll & Hyde\"",
    "River of Hesse",
    "River to the Fulda",
    "Linda of Broadway",
    "Ems-Weser Canal feeder",
    "Linda of \"Jekyll & Hyde\"",
    "Pulitzer-winning critic Richard",
    "1987 Pulitzer-winning critic Richard ___",
    "River that joins the Fulda",
    "River of Hesse",
    "River of Hesse",
    "1987 Pulitzer critic Richard",
    "Linda of \"Jekyll & Hyde\"",
    "River of central Germany",
    "German river",
    "German river",
    "River near Kassel, Germany",
    "River to the Fulda",
    "River of Hesse",
    "River of Hesse",
    "River of central Germany",
    "Fulda tributary",
    "River in Hesse",
    "Germany's ___ Reservoir Dam",
    "River of Hesse",
    "River to the Fulda",
    "Hesse river",
    "German river",
    "Fulda feeder",
    "River to the Fulda",
    "River or reservoir in Hesse",
    "Pulitzer-winning critic Richard",
    "River of Hesse",
    "River in Hesse",
    "Pulitzer-winning critic Richard",
    "Fulda tributary",
    "River to the Fulda",
    "Pulitzer-winning critic Richard",
    "Pulitizer-winning critic Richard",
    "River near Kassel",
    "German river",
    "River in Hesse",
    "Fulda feeder",
    "German river",
    "Pulitzer-winning critic Richard"
  ],
  "copa": [
    "Nightclub of song, familiarly",
    "___ America (soccer tournament)",
    "Famed N.Y.C. nightclub, with \"the\"",
    "Nightclub in a Manilow song",
    "\"At the ___\" (subtitle of a 1978 hit)",
    "Legendary N.Y.C. club, with \"the\"",
    "Setting of a top 10 Barry Manilow hit",
    "Lola's club, in brief",
    "Club in a Barry Manilow hit, with \"the\"",
    "Setting of a Barry Manilow hit",
    "Club of song",
    "\"Meet Me at the ___\"",
    "\"Meet Me at the ___\"",
    "Nightclub of song",
    "Club of song",
    "Sinatra's \"Meet Me at the ___\"",
    "Spot in a Manilow tune",
    "\"Hottest spot north of Havana,\" in song",
    "Frank Sinatra's \"Meet Me at the ___\"",
    "Storied nightspot",
    "Club of song",
    "Club of song",
    "Place where you're advised \"Don't fall in love,\" in song",
    "Nightclub in a Manilow song",
    "Manilow song setting",
    "Manilow song setting",
    "Famed Rio hotspot, with \"the\"",
    "Club of song",
    "Nightclub of song",
    "Club of song",
    "Barry Manilow song locale",
    "Manilow song setting",
    "\"At the ___\" (1978 song phrase)",
    "Nightclub of song",
    "Storied Big Apple nightclub",
    "Noted nightclub",
    "Rio nitery, for short"
  ],
  "sues": [
    "Seeks judgment, in a way",
    "Makes a case against",
    "Doesn't settle in advance, say",
    "Seeks legal recourse",
    "Takes to court",
    "Is litigious",
    "Refuses to settle?",
    "Prefers charges against",
    "Seeks damages",
    "Seeks damages",
    "Takes to court",
    "Formally appeals",
    "Seeks redress",
    "Goes after",
    "Takes to court",
    "Hauls to court",
    "Pleads",
    "Doesn't sit still for mistreatment, maybe",
    "Appeals to",
    "Seeks money damages from",
    "Takes action against",
    "Litigates",
    "Takes to court",
    "Petitions",
    "Pleads",
    "Takes to court",
    "Petitions",
    "Pleads",
    "\"Laugh-In\" regular Alan",
    "Institutes legal action",
    "Litigates",
    "Courts"
  ],
  "whos": [
    "\"___ with me?\"",
    "Villagers victimized by the Grinch",
    "Villagers the Grinch stole from in Dr. Seuss",
    "\"___ your daddy?\"",
    "\"___ asking?\"",
    "\"... but ___ counting?\"",
    "\"___ calling?\"",
    "\"___ next?\"",
    "Seussian villagers",
    "Microscopic Dr. Seuss characters",
    "\"___ next?\"",
    "\"___ next?\"",
    "\"Look ___ Talking\"",
    "\"___ on first?\"",
    "\"___ there?\"",
    "\"___ next?\"",
    "\"___ there?\" (part of a knock-knock joke)",
    "\"___ there?\"",
    "\"___ on first?\""
  ],
  "whig": [
    "Harrison, e.g., but not Ford",
    "Fillmore's party",
    "Clay, for one",
    "Like four U.S. presidents",
    "Lincoln while in Congress, e.g.",
    "Taylor or Tyler",
    "Henry Clay, politically",
    "Taylor or Tyler",
    "Taylor or Tyler, politically",
    "Antiroyalist, in the Revolution",
    "Kit-Cat Club member in 18th-century London",
    "Jacksonian detractor",
    "Supporter of the American Revolution",
    "Fillmore, politically",
    "Tyler or Taylor, e.g."
  ],
  "jpeg": [
    "Format of a digital pic",
    "Digital image format",
    "Relative of a .png file",
    "Picture file format",
    "Compressed file format",
    "Compressed pic, of a sort",
    "Emailed pic, often",
    "Digital image format",
    "Uploaded pic, often",
    "PC image acronym",
    "Image format",
    "PC image format",
    "Computer image file format",
    "Computer image file format",
    "Computer graphics file format"
  ],
  "gyms": [
    "Where squatters may be seen",
    "\"Y\" facilities",
    "Rooms with high ceilings",
    "School areas with high ceilings",
    "Recess sites, at times",
    "Reps' places?",
    "Gold's and others",
    "Workout centers",
    "B-ball sites",
    "Locker sites",
    "Fitness centers",
    "Aerobics habitats"
  ],
  "cane": [
    "Prop for Mr. Peanut",
    "Christmas tree hanging",
    "Word with sugar or candy",
    "Alternative to a walker",
    "Dance prop for Fred Astaire",
    "Support staff",
    "Top hat go-with",
    "Christmas decoration",
    "Sweet stalk",
    "Crook, e.g.",
    "Stick by the front door, say",
    "Quaint stage dancing accessory",
    "Walker alternative",
    "Support staff",
    "One of man's three legs, in the riddle of the Sphinx",
    "Pier 1 furniture material",
    "Boulevardier's accessory",
    "Prop for Mr. Monopoly or Mr. Peanut",
    "Aid in getting around",
    "One to walk with",
    "Little Tramp prop",
    "Punish, in a way",
    "Supporter of Yoda",
    "Support staff",
    "Prop for Winston Churchill",
    "Fred Astaire prop",
    "Means of support",
    "Chaplin prop",
    "Mr. Peanut prop",
    "Means of support",
    "Staff",
    "Beat",
    "Porch chair material",
    "Mr. Peanut accessory",
    "Punish, in a way",
    "Knobstick",
    "Support provider",
    "Nursing home staff?",
    "Sugar source",
    "One with a supporting role",
    "Mobility improver",
    "Support for the elderly?",
    "Crook",
    "Lean on me",
    "Staff",
    "Sugar source",
    "Walking stick",
    "Supporter",
    "Chaplin prop",
    "Sugar source",
    "Christmas sweet",
    "It has a supporting role",
    "Walking stick",
    "Vaudeville dancer's accessory",
    "Walker's aid",
    "Soundly beat",
    "Stick",
    "Beat severely",
    "Sugar source",
    { "formula": "+Punish severely", "result": { "error": "#NAME?" } },
    "Word with sugar",
    "Sugar source",
    "Punish in Singapore, maybe",
    "Punish severely",
    "Beat",
    "Hobbler's support",
    "Chaplin trademark",
    "Beat, in away",
    "Bamboo stalk",
    "Churchill prop",
    "Dandy's accouterment"
  ],
  "lyin": [
    "\"___ Eyes\" (1975 Eagles hit)",
    "\"You ain't ___!\"",
    "\"___ Eyes\" (Eagles hit)",
    "\"___ Eyes\" (1975 hit)",
    "The Eagles' \"___ Eyes\"",
    "\"___ Eyes\" (Eagles hit)",
    "\"You ain't ___!\"",
    "\"___ Eyes\" (1975 #2 hit)",
    "\"___ Eyes\" (1975 Eagles hit)",
    "\"Can't Believe Your ___\" (1988 Neil Young song)",
    "The Eagles' \"___ Eyes\"",
    "\"___ Eyes\" by the Eagles",
    "Eagles hit \"___ Eyes\""
  ],
  "babu": [
    "Hindu title of respect",
    "Pakistani restaurant owner on \"Seinfeld\"",
    "Hindu title of respect",
    "Hindu gentleman"
  ],
  "haim": [
    "American pop-rock band composed of three sisters",
    "Onetime teen idol Corey",
    "Feldman's co-star on \"The Two Coreys\"",
    "One of the Coreys on \"The Two Coreys\"",
    "Corey of \"The Lost Boys\"",
    "Actor Corey ___"
  ],
  "baba": [
    "Cake with rum",
    "The Who's \"___ O'Riley\"",
    "\"Ali ___ and the 40 Thieves\"",
    "Cake with rum",
    "___ ghanouj",
    "See 34-Down",
    "Yeast cake made with rum",
    "Rum-soaked cake",
    "Cake with a kick",
    "Rum-laced cake",
    "Cake with a kick",
    "Rummy cake",
    "\"Ali ___ and the 40 Thieves\"",
    "___ ghanouj",
    "Ali ___ of \"The Arabian Nights\"",
    "\"Ali ___ and the 40 Thieves\"",
    "Cake with a kick",
    "\"Ali ___ and the Forty Thieves\"",
    "Spongelike cake",
    "Dessert whose name means \"peasant woman\"",
    "Rummy treat",
    "The Who's \"___ O'Riley\"",
    "___ Wawa, role for Gilda Radner",
    "\"Ali ___ and the 40 Thieves\"",
    "Syrup-soaked cake",
    "See 120-Down",
    "Rum-soaked cake",
    "Rummy cake",
    "Cake with a kick",
    "Ali ___",
    "Ali ___ and the 40 Thieves",
    "Yeast cake",
    "Rum cake",
    "Rich, raisiny cake",
    "\"Ali ___ and the 40 Thieves\"",
    "Cake with a kick",
    "The Who's \"___ O'Riley\"",
    "Cake with a kick",
    "Cake with a kick",
    "___ ghanouj (eggplant dish)",
    "Rum cake",
    "Rum-soaked cake",
    "Ali ___",
    "Ali ___",
    "Alcohol-laced dessert",
    "\"Ali ___ and the Forty Thieves\"",
    "Rum-laced cake",
    "Rum-soaked cake",
    "Rum cake",
    "Rum-soaked cake",
    "Ali who said \"Open sesame!\"",
    "___ au rhum (cake with a punch)",
    "Ali___of children's fiction",
    "Rum cake",
    "Rum cake"
  ],
  "imus": [
    "\"___ in the Morning\" (bygone radio show)",
    "Shock jock Don",
    "Stern competitor, once",
    "\"___ in the Morning\"",
    "Shock jock Don",
    "\"___ in the Morning\"",
    "Longtime radio rival of Stern",
    "\"___ in the Morning\" (radio show)",
    "Radio's \"___ in the Morning\"",
    "Radio host who often wears cowboy hats",
    "\"___ in the Morning\"",
    "\"Morning\" person",
    "Stern contemporary",
    "\"___ in the Morning\"",
    "1989 Radio Hall of Fame inductee",
    "Big name in morning radio",
    "Radio figure who co-wrote \"Two Guys Four Corners\"",
    "Big name in radio",
    "ABC Radio host",
    "\"___ in the Morning\"",
    "Don in the National Radio Hall of Fame",
    "Radio's former \"___ in the Morning\"",
    "Don with a big mouth",
    "\"___ in the Morning\"",
    "Celeb fired in 2007",
    "Don formerly of morning radio",
    "Radio iconoclast",
    "MSNBC talk host",
    "Radio jock Don",
    "Don of talk radio",
    "Don of morning radio",
    "Popular Don",
    "Don with a mike",
    "Radio host Don",
    "Don with a gift of gab",
    "Stern competitor",
    "Radio man Don",
    "Deejay Don",
    "Big name in morning radio",
    "Deejay Don",
    "Radio host Don",
    "Radio wise guy Don",
    "Don of talk radio",
    "Radio host of note"
  ],
  "phon": [
    "Sound: Prefix",
    "Sound: Prefix",
    "Unit of loudness",
    "Sound: Prefix"
  ],
  "mias": [
    "Farrow and Hamm, for two",
    "G.I.s of concern",
    "War stat",
    "Some Veterans Day honorees, for short",
    "Mil. stat",
    "Unaccounted-for G.I.'s",
    "They're officially honored on the third Friday in Sept.",
    "War stat",
    "Military list, quickly",
    "Unaccounted-for G.I.'s",
    "Mil. list",
    "G.I.'s not accounted for",
    "Farrow and Hamm",
    "Unaccounted-for combatants: Abbr.",
    "Veterans Day honorees",
    "War statistics",
    "Actresses Farrow and Sara",
    "Vietnam War legacy: Abbr.",
    "\"Rambo\" rescuees"
  ],
  "usdo": [
    "\"If you prick ___ we not bleed?\": \"The Merchant of Venice\"",
    "\"If you prick ___ we not bleed?\": Shak.",
    "\"Let ___ good unto all men\": Galatians 6:10",
    "\"As we have therefore opportunity, let ___ good to all men\": Galatians"
  ],
  "mpaa": [
    "Longtime film rating org.",
    "Longtime film rating org.",
    "Film ratings org.",
    "Film-rating org.",
    "Movie-rating org.",
    "Film-rating grp.",
    "Grp. that takes on pirates",
    "Production Code org.",
    "Grp. assigning film ratings",
    "Org. associated with filmratings.com",
    "Org. assigning PG-13 or R",
    "Org. with an antipiracy stance",
    "NC-17 assigner: Abbr.",
    "FIlm-rating grp.",
    "Grp. concerned with violence levels",
    "Trailer-approving grp.",
    "Rating org.",
    "Movie-rating org.",
    "Org. that may assess violence levels",
    "Trailer org.?",
    "Org. that approves trailers",
    "G or R issuer: Abbr.",
    "Film-rating org.",
    "Assigner of G's and R's: Abbr.",
    "Film rating org.",
    "Movie-rating org."
  ],
  "bush": [
    "President George or George W.",
    "Wild country",
    "Number 41 or 43",
    "Outback",
    "Amateurish",
    "Political family name",
    "#41 or #43",
    "Amateurish",
    "Amateurish",
    "Australian wild",
    "Job for a barber",
    "Wild land in Africa",
    "Justice Souter's appointer",
    "Opponent of 120-Across",
    "Unprofessional, slangily",
    "Hair in need of a barber",
    "41st in a series"
  ],
  "math": [
    "Trig, calc, etc.",
    "Work with planes, maybe",
    "Adding and subtracting",
    "University department, for short",
    "Trig or calc",
    "Subject with fractions",
    "Quadratics, e.g.",
    "What's nothing but problems?",
    "Planes are studied in it",
    "\"Do the ___\" (\"You figure it out\")",
    "SAT section",
    "\"Do the ___!\"",
    "\"You do the ___!\"",
    "Field with bases",
    "It has many functions",
    "Trig or geometry",
    "SAT section",
    "SAT component",
    "Trig, e.g.",
    "Algebra or trig",
    "S.A.T. part",
    "Test section",
    "Standardized test topic",
    "S.A.T. section",
    "\"Do the ___\"",
    "It has its share of problems",
    "Algebra and trig",
    "A calculator assists with it",
    "Geometry, e.g.",
    "Multiplying, e.g.",
    "It takes figuring",
    "Algebra or trig",
    "Arith.",
    "Algebra or trig",
    "High school requirement",
    "It has roots and many branches",
    "Figure this!",
    "Figuring",
    "Subject of anxiety?",
    "Calculator work",
    "Number crunching"
  ],
  "tkod": [
    "Beat in a boxing match, in a way",
    "Eliminated by a ref's decision",
    "Walloped to win the bout, in brief",
    "Having thrown in the towel, maybe",
    "Ousted from the ring, for short",
    "Eliminated from a boxing match, in a way"
  ],
  "croc": [
    "Shoe with holes",
    "Gator's cousin",
    "Gator's cousin",
    "Big snapper, informally",
    "Gator's cousin",
    "Popular gardening shoe",
    "Shoe with lots of holes",
    "Swamp snapper",
    "Menace following Captain Hook around, for short",
    "Critter that likes to lie in the sun",
    "Nile reptile, for short",
    "Shoe with holes",
    "Animal with a big bite, informally",
    "Cousin of a gator",
    "Swamp swimmer",
    "Nile predator, briefly",
    "Swamp critter",
    "Shoe with holes",
    "Large snapper",
    "Pursuer of Capt. Hook",
    "Swamp critter",
    "Nile biter, for short",
    "Swamp menace, for short",
    "Swamp beast, informally",
    "Cold-blooded killer, for short",
    "Mangrove menace, informally",
    "Holey plastic shoe",
    "Swamp denizen, briefly",
    "Gator's cousin",
    "Riverbank basker, informally",
    "Gator's cousin",
    "Critter with a lot of teeth",
    "Nile menace, informally",
    "Nile menace, informally",
    "Big snapper, informally",
    "Killer ___ (green-skinned \"Batman\" villain)",
    "Swamp menace, for short",
    "Swamp critter",
    "Nile critter",
    "Gator's cousin",
    "Bigmouth",
    "Relative of a gator",
    "Gator relative",
    "Swamp thing",
    "Bigmouthed critter",
    "Toothy menace",
    "How Capt. Hook lost his right hand, informally",
    "Gator's relative",
    "Riverbank predator",
    "Gator's kin",
    "Gator's cousin",
    "Leathery sunbather",
    "Snappish one",
    "Swamp snapper",
    "Marsh critter",
    "Relative of a gator",
    "Swamp critter",
    "Nile menace",
    "Big-mouthed critter",
    "Gator's cousin",
    "Swamp critter",
    "Gator's kin",
    "Swamp thing",
    "Gator cousin",
    "Caiman's cousin, for short"
  ],
  "dios": [
    "God, in Guadalajara",
    "God: Sp.",
    "\"Gracias a ___\"",
    "\"___ mio!\" (Spanish cry)",
    "God, in Granada",
    "God, in Granada",
    "\"Vaya con ___\" (\"Go with God\")",
    "God: Sp.",
    "Spanish Almighty",
    "God: Sp."
  ],
  "pits": [
    "Sets (against)",
    "A dreadful state, with \"the\"",
    "Very bad, with \"the\"",
    "Peach stones",
    "Where coal miners work",
    "Very worst",
    "Fire places",
    "The absolute worst, with \"the\"",
    "Tire-changing spots",
    "Something horrible, with \"the\"",
    "Where auto racers retire?",
    "Places to refuel",
    "What 20-, 41-, and 56-Across all have",
    "Absolute worst",
    "Mining areas",
    "500 places",
    "They may be bottomless",
    "Absolute worst",
    "Salt mines",
    "Absolute worst, with \"the\"",
    "Orchestra areas",
    "Quarries",
    "Worst possible situation, with \"the\"",
    "Absolute worst",
    "Low state, with \"the\"",
    "Quarries",
    "Absolute worst"
  ],
  "bans": [
    "Some last a lifetime",
    "Prohibits",
    "Outlaws",
    "Prohibits",
    "Proscriptions",
    "Outlaws",
    "Some school edicts",
    "Harsh punishments",
    "Blacks out",
    "Some dictators' proclamations",
    "Blacks out",
    "Prohibitions",
    "Blacks out",
    "Most of the Ten Commandments, basically",
    "Makes verboten",
    "Outlaws",
    "Embargoes",
    "Interdicts",
    "Outlaws",
    "Makes impossible",
    "Prohibits",
    "Declares off-limits",
    "Outlaws",
    "Makes verboten",
    "Prohibits",
    "Outlaws",
    "Rules out",
    "Embargoes",
    "Prohibits",
    "Some diktats",
    "Forbids",
    "Excommunicates",
    "Excommunicates",
    "Outlaws",
    "Prohibits",
    "Forbids"
  ],
  "uscg": [
    "SOS responder, for short",
    "Org. whose academy's motto in English is \"The sea yields to knowledge\"",
    "S O S first responders",
    "\"Semper paratus\" grp.",
    "Law enforcers at sea: Abbr.",
    "Marine rescue grp.",
    "Org. with boats",
    "Its motto is \"Semper paratus\": Abbr.",
    "S O S responder: Abbr.",
    "Grp. that patrols shores",
    "Maritime grp.",
    "Naut. law enforcers",
    "Drug interdictors: Abbr.",
    "New London-based org.",
    "New London grp.",
    "New London grp."
  ],
  "nunn": [
    "Theater director Trevor with three Tonys",
    "Georgia senator who helped establish \"don't ask, don't tell\"",
    "Late actor Bill who played Radio Raheem",
    "Sam for whom Georgia Tech's School of International Affairs is named",
    "Former Georgia senator Sam",
    "Sir Trevor of the Royal Shakespeare Company",
    "\"Cats\" Tony winner Trevor",
    "Senate Armed Services Committee chairman after Goldwater",
    "Former Georgia senator Sam",
    "Ex-senator Sam",
    "1970s-'90s senator Sam",
    "Longtime Georgia senator",
    "Ex-senator Sam",
    "Georgia Tech's Sam ___ School of International Affairs",
    "Trevor who directed \"Cats\"",
    "Former Georgia senator Sam",
    "Ex-Senator from Georgia",
    "Former Georgia senator Sam",
    "Former Georgia senator",
    "\"Cats\" director Trevor ___",
    "Former Georgia senator",
    "\"Cats\" director Trevor",
    "Former Senate Armed Services Committee chairman",
    "Georgia Senator until 1997",
    "Senator succeeded by Cleland",
    "Former Senate Armed Forces Committee head",
    "Senator Sam"
  ],
  "dins": [
    "Rackets",
    "Rackets",
    "Rackets",
    "Roars",
    "Rackets",
    "Tinnitus causes",
    "Headache intensifiers",
    "Cacophonies",
    "Rackets",
    "Ruckuses",
    "Rackets",
    "Noise pollution",
    "Thunders",
    "Rackets",
    "Noises"
  ],
  "lope": [
    "Easy pace",
    "Easy run",
    "Wolf's gait",
    "Easy stride",
    "Make great strides?",
    "Spanish dramatist ___ de Vega",
    "Unhurried run",
    "Easy gait",
    "Not a full-out run",
    "What may be good for the long run?",
    "Less than a full run",
    "Run easily",
    "Easy gait",
    "Horse's run",
    "Easy run",
    "Canter",
    "Bound along",
    "Bound",
    "Bound",
    "Run",
    "Easy gait",
    "Run",
    "Bound",
    "Run easily",
    "Easy stride",
    "Horse's run",
    "Run easily",
    "Bound along",
    "Easy gait",
    "Easy run",
    "Run off a farm?",
    "Unhurried gait",
    "Run of the ranch?",
    "Canter",
    "Spanish dramatist ___ de Vega",
    "Relaxed gait",
    "Cross-country gait",
    "Run",
    "Natural gait",
    "Canter"
  ],
  "mres": [
    "G.I. rations, for short",
    "G.I. food packs",
    "G.I. portions",
    "G.I. fare",
    "Dinners for G.I.s",
    "G.I. rations",
    "Field fare, briefly",
    "They replaced C rations",
    "G.I. chow"
  ],
  "jena": [
    "Actress Malone of the \"Hunger Games\" films",
    "Actress Malone of \"The Hunger Games\"",
    "Malone of \"Into the Wild\"",
    "German city where Napoleon defeated the Prussians",
    "Actress Malone"
  ],
  "kush": ["Potent strain of marijuana", "Strain of potent marijuana"],
  "kyra": [
    "Newswoman Phillips",
    "Actress Sedgwick",
    "Sedgwick of \"The Closer\"",
    "Actress Sedgwick of \"The Closer\"",
    "Actress Sedgwick of \"The Closer\"",
    "\"The Closer\" star Sedgwick",
    "Actress Sedgwick",
    "Actress Sedgwick",
    "Actress Sedgwick",
    "Actress Sedgwick",
    "Actress Sedgwick",
    "Actress Sedgwick",
    "Sedgwick of the screen",
    "Actress Sedgwick"
  ],
  "ahok": ["\"Well, I guess so\""],
  "wart": [
    "Part of a witch's Halloween mask",
    "Blemish",
    "Flaw, metaphorically",
    "Imperfection, metaphorically",
    "Blemish",
    "Minor flaw",
    "Apparent flaw",
    "Freeze Away target",
    "Something on a hog?",
    "Blemish",
    "Witch's blemish",
    "Target of salicylic acid",
    "Imperfection",
    "Dermatologist's concern",
    "Imperfection",
    "Flaw",
    "Job for a dermatologist",
    "Toad feature",
    "Dermal development",
    "Compound W's target",
    "Flaw",
    "Witch's blemish",
    "Witch's feature",
    "Electrologist's target",
    "Imperfection",
    "Job for a dermatologist",
    "Witch's facial blemish",
    "Flaw",
    "Flaw",
    "Witch's blemish",
    "Witch's blemish",
    "Part of Halloween makeup"
  ],
  "fran": [
    "San ___ (California city, informally)",
    "Actress Drescher of \"The Nanny\"",
    "Human member of an old TV trio",
    "Ollie's friend on old TV",
    "Football Hall-of-Famer Tarkenton",
    "Human member of an old TV trio",
    "Actress Drescher",
    "San ___",
    "Funny Drescher",
    "___ Fine, \"The Nanny\" nanny",
    "Tarkenton of three Super Bowls",
    "Actress Drescher",
    "Memorable 1996 hurricane",
    "Memorable 1996 hurricane along the Eastern Seaboard",
    "\"The Nanny\" actress Drescher",
    "QB Tarkenton",
    "Friend of Kukla and Ollie",
    "QB Tarkenton",
    "Kukla, ___ and Ollie",
    "Ollie's human friend",
    "Kukla, ___ and Ollie",
    "Drescher of \"The Nanny\"",
    "\"The Nanny\" nanny",
    "Humorist Lebowitz",
    "Tarkenton of Minnesota",
    "Middle of an old children's trio",
    "Humorist Lebowitz",
    "Football Hall-of-Famer Tarkenton",
    "Middle of a TV trio",
    "Humorist Lebowitz",
    "Writer Lebowitz",
    "Pal of Ollie",
    "Humorist Lebowitz",
    "QB Tarkenton",
    "Football's Tarkenton",
    "Ollie's biggest friend",
    "Actress Drescher of \"The Nanny\"",
    "Companion of Ollie"
  ],
  "agri": [
    "Prefix with business or culture",
    "Prefix with cultural",
    "Prefix with culture",
    "Prefix with cultural",
    "Prefix with business",
    "Cultural leader?",
    "Commercial lead-in to Bank, in many Midwest states",
    "Prefix with cultural",
    "Farming prefix",
    "Farming prefix",
    "Prefix with cultural",
    "Business opening?",
    "Prefix with culture",
    "Prefix with cultural",
    "Prefix with business",
    "___-food industry",
    "Prefix with cultural",
    "Prefix with chemical",
    "Prefix with business",
    "Prefix with business",
    "Intro to business?",
    "Prefix with culture",
    "Cultural beginning?",
    "Prefix with cultural",
    "Prefix with cultural",
    "Prefix with business",
    "Prefix with business",
    "Prefix with business",
    "Farm-related: Prefix",
    "Cultural beginning?",
    "Prefix with business",
    "Prefix with cultural",
    "Prefix with culture",
    "Culture starter",
    "Farming prefix",
    "Cultural intro",
    "Farm prefix",
    "Cultural heading",
    "Prefix with culture",
    "Prefix with business",
    "Farm: Prefix",
    "Prefix with culture",
    "Rural prefix",
    "Culture lead-in",
    "Farm prefix",
    "Cultural opening",
    "Business start",
    "Culture starter"
  ],
  "dmvs": [
    "Auto license issuers, for short",
    "License bureaus, for short",
    "Photo ID issuers"
  ],
  "tish": [
    "Mrs. Addams, to Gomez",
    "Nickname on \"The Addams Family\"",
    "Mrs. Addams, to Gomez",
    "Mrs. Addams, to Gomez",
    "\"The Addams Family\" nickname",
    "\"Oh, pooh!\"",
    "Mrs. Addams, to Gomez",
    "Gomez's sweetie",
    "\"Oh, pooh!\"",
    "Nickname on \"The Addams Family\"",
    "One of \"The Addams Family,\" informally",
    "Mrs. Addams, to Gomez",
    "\"Oh, pooh!\"",
    "Cookbook author ___ Boyle",
    "Mrs. Addams, to Gomez",
    "Mrs. Addams, to Gomez",
    "Mrs. Addams, to Gomez",
    "Mary Roberts Rinehart volume",
    "\"Addams Family\" nickname",
    "Mrs. Addams, affectionately"
  ],
  "pone": [
    "Cornmeal bread",
    "Corn product",
    "Southern bread",
    "Bread in Southern cuisine",
    "Southern side",
    "Dixie bread",
    "Southern bread",
    "Cornmeal bread",
    "Unleavened cornbread",
    "Southern bread",
    "Corn bread",
    "Corn cake",
    "Johnnycake",
    "Corn bread",
    "Corn bread",
    "Soul food side dish",
    "Corn dish",
    "Cornmeal bread",
    "Corn bread",
    "Corn ___",
    "Oval-shaped loaf",
    "Southern side?",
    "Corn cake",
    "Southern dish",
    "Corn cake",
    "Southern bread",
    "Corn ___",
    "Southern side?",
    "Corn ___",
    "Corn bread",
    "Corn bread",
    "Corn bread",
    "In bridge, the player on the dealer's right",
    "Corn ___",
    "Johnnycake",
    "Corn ___",
    "Corn ___",
    "Corn bread",
    "Corn-coction",
    "Cornmeal cake",
    "Corn product",
    "Corn cake",
    "Corn bread",
    "Corn bread"
  ],
  "loop": [
    "Aerial maneuver",
    "Circle",
    "Repeating segment of computer code",
    "Circuit",
    "Air show maneuver",
    "Aerial stunt",
    "Airport shuttle route, commonly",
    "Circle",
    "See 1-Across",
    "Lariat part",
    "Ring",
    "1 Infinite ___ (address of Apple's headquarters)",
    "Circle",
    "Stunt plane maneuver",
    "Word with belt or tape",
    "Mobius strip, e.g.",
    "Encircle",
    "Heart of Chicago, with \"the\"",
    "Coil",
    "Part of a shoelace tie",
    "Ankh's top",
    "In the ___",
    "Chicago district, with \"the\"",
    "Figure skating maneuver",
    "Circle",
    "Ankh feature",
    "Bow part",
    "Flight maneuver",
    "Part of a tied tie",
    "Circle",
    "What goes around",
    "Chicago locale, with \"the\"",
    "Orbit",
    "Ankh feature",
    "Aerial maneuver",
    "Roller coaster part",
    "Cloverleaf feature",
    "Computer program feature",
    "Roller coaster maneuver",
    "Continuous-play tape",
    "Aeronautical maneuver",
    "Aerobatic maneuver",
    "In the ___ (informed)",
    "Chicago area, with \"the\"",
    "Enwreathe"
  ],
  "cyan": [
    "Color for the right eye of a pair of 3-D glasses",
    "Color in a color printer",
    "Ink cartridge color",
    "Ink cartridge color",
    "Shade of blue",
    "Blue used by a printer",
    "Blue shade",
    "Blue shade",
    "Greenish blue",
    "Printer's color",
    "Greenish-blue",
    "Shade of blue",
    "Shade of blue",
    "Blue hue",
    "Blue-green",
    "Printer's color",
    "Color used in four-color processing",
    "Greenish blue",
    "Greenish blue",
    "___ blue",
    "Greenish blue",
    "Shade of blue",
    "Shade of blue",
    "Blue hue"
  ],
  "yeps": [
    "Informal assents",
    "Slangy assents",
    "O.K.'s from the O.K. Corral?",
    "Slangy assents",
    "Informal assents",
    "Uh-huh's",
    "Slangy assents",
    "Slangy approvals",
    "Slangy assents",
    "Cowboy affirmatives",
    "Rural affirmatives"
  ],
  "whap": [
    "Smack!",
    "Striking sound",
    "Swatting sound",
    "Fly swatter sound",
    "Sound of a fly swatter"
  ],
  "tits": [
    "\"White-fronted\" or \"chestnut-bellied\" birds",
    "Small songbirds",
    "Little songbirds",
    "Small songbirds",
    "Small songbirds",
    "Small songbirds",
    "Small songbirds",
    "Chickadees' kin",
    "Little birds",
    "Bearded ___ (reedlings)",
    "Small songbirds",
    "Short-billed songbirds"
  ],
  "hifi": [
    "Like some sound systems",
    "Early record holder",
    "Player of oldies when they were newies",
    "Revolutionary music device?",
    "Place for a '55 45, say",
    "Audiophile's preference, maybe",
    "Old record player",
    "Old platter player",
    "LP player",
    "1950s living room feature",
    "Old record holder",
    "Sound investment",
    "Spinner for the Spinners",
    "Spinner for the Spinners",
    "Rec room item of old",
    "Record holder",
    "Old platter player",
    "Player of 45's",
    "Old-fashioned phono",
    "Old 45 player",
    "Old 45 player",
    "Player of old tunes",
    "Place for platters",
    "Oldies player",
    "Platter player",
    "Perry Como player",
    "Bygone player",
    "The Platters player",
    "Player of the Four Aces",
    "Purchase for a 1950's home",
    "Audiophile's purchase",
    "It played the Platters' platters",
    "LP player",
    "Sound setup",
    "CD player ancestor",
    "Platter player",
    "Record player"
  ],
  "init": [
    "Not eliminated",
    "Participating",
    "Not yet out of the running",
    "Not yet out of contention",
    "\"What's ___ for me?\"",
    "Monogram component: Abbr.",
    "Not yet out of the running",
    "J. Edgar Hoover used one: Abbr.",
    "Still vying",
    "\"What's ___ for me?\"",
    "Part of a competition",
    "Still competitive",
    "Still a contender",
    "Still competing",
    "Rolling ___ (wealthy)",
    "Participating",
    "Competing",
    "One of two in 27-Down: Abbr.",
    "One of three for J. R. R. Tolkien: Abbr.",
    "Rolling ___ (rich)",
    "\"What's ___ for me?\"",
    "Monogram letter: Abbr.",
    "Part of U.S.: Abbr.",
    "Not yet out of the running",
    "Rolling ___ (rich)",
    "Still contending",
    "Not out of the running",
    "\"What's ___ for me?\"",
    "J. Edgar Hoover used one: Abbr.",
    "Questionnaire datum: Abbr.",
    "\"Put a sock ___!\"",
    "\"What's ___ for me?\"",
    "W., e.g.: Abbr.",
    "Still alive",
    "The G, W or B in G.W.B.",
    "L., B. or J.",
    "Ltr. with a period",
    "Competing",
    "The \"W\" in Geo. W. Bush, e.g.",
    "\"What's ___ for me?\"",
    "Still having a shot to win",
    "L., B. or J.",
    "Quick approval: Abbr.",
    "Signature part: Abbr.",
    "O.K., in a way: Abbr.",
    "J, F or K, e.g., in J.F.K.: Abbr.",
    "___ for the long haul",
    "J., F. or K.: Abbr.",
    "Quick approval: Abbr.",
    "Participating",
    "Quick approval: Abbr.",
    "Quick approval: Abbr.",
    "\"What's ___ for me?\"",
    "Quick O.K.: Abbr.",
    "\"What's ___ for me?\"",
    "Monogram part: Abbr.",
    "Letterhead?: Abbr.",
    "Embroidered ltr., often",
    "Quick approval: Abbr.",
    "Monogram unit: Abbr.",
    "\"What's ___ for me?\"",
    "Signature piece?: Abbr.",
    "Rolling ___ (rich)",
    "Bit of handkerchief embroidery: Abbr.",
    "F. Murray Abraham feature: Abbr.",
    "___ for the money",
    "\"What's ___ for me?\"",
    "Acronym part: Abbr.",
    "Name part: Abbr.",
    "\"What's ___ for me?\"",
    "?ôWhat?Æs ___ for me??ö",
    "Monogram ltr.",
    "Part of F.D.R.: Abbr.",
    "\"What's ___ for me?\"",
    "Letter of approval?: Abbr.",
    "Entry blank datum: Abbr.",
    "Brief O.K.: Abbr.",
    "Monogram part: Abbr.",
    "Bit of stitching: Abbr.",
    "Monogram unit: Abbr.",
    "Any part of J.F.K.: Abbr.",
    "\"What's ___ for me?\"",
    "Name part: Abbr.",
    "F. Scott Fitzgerald had one: Abbr.",
    "Monogram feature: Abbr.",
    "Abbr. part, often",
    "\"What's___for me?\"",
    "Orig.",
    "___ for the money",
    "Monogram part: Abbr.",
    "\"What's ___ for me?\"",
    "\"Where's the profit ___?\"",
    "\"What's ___ for me?\""
  ],
  "mask": [
    "Hide",
    "Part of a Halloween costume",
    "Obscure",
    "Hide",
    "Lone Ranger accessory",
    "Facial option at a spa",
    "Trick-or-treater's wear",
    "Purchase for Halloween",
    "Costume accessory",
    "It may be right in front of your eyes",
    "Umpire's wear",
    "Part of a Halloween costume",
    "Mardi Gras wear",
    "Jim Carrey film, with \"The\"",
    "Ski wear",
    "Halloween purchase",
    "Halloween wear",
    "Hide",
    "Halloween accessory",
    "Mardi Gras wear",
    "Halloween purchase",
    "Stocking, sometimes",
    "Face-saver?",
    "1985 Cher film",
    "Lone Ranger attire",
    "Lone Ranger attire"
  ],
  "dish": [
    "Trade gossip",
    "Bowl or plate",
    "Trade gossip",
    "Satellite signal receiver",
    "\"I want details!\"",
    "Hot gossip",
    "One running away in \"Hey Diddle Diddle\"",
    "Satellite ___",
    "Gossip",
    "Gossip",
    "Provide the juicy bits",
    "What ran away with the spoon, in \"Hey Diddle Diddle\"",
    "DirecTV requirement",
    "Food serving",
    "Potluck choice",
    "Gossip, slangily",
    "Gossip",
    "What everyone brings to a potluck dinner",
    "One who ran away with the spoon, in a nursery rhyme",
    "Knockout",
    "Appetizer, entree or dessert",
    "Signal receiver",
    "Waiter's serving",
    "Satellite signal receiver",
    "Good-looker",
    "One helping",
    "Satellite TV receiver",
    "One running away with a spoon, in a children's rhyme",
    "Gossip, slangily",
    "Gossip",
    "Gossip, slangily",
    "Appetizer or entree",
    "TV receiver",
    "Signal receiver",
    "Good-looker",
    "Chef's serving",
    "Gossip",
    "It's seen on many a roof",
    "Piece of china",
    "Restaurant serving",
    "Antenna type",
    "Potluck choice",
    "Gossip",
    "Potluck choice",
    "Any course",
    "Rooftop fixture",
    "Menu offering",
    "Satellite ___",
    "Gossip",
    "Chef's serving",
    "Gossip",
    "TV signal receiver",
    "Gossip"
  ],
  "deke": [
    "Hockey fake-out",
    "Hockey feint",
    "Ice hockey feint / Squeezed (out)",
    "Hockey feint",
    "Hockey feint",
    "Hockey feint",
    "Hockey fake-out",
    "Rink fooler",
    "Astronaut Slayton",
    "Hockey fake",
    "Fool on the ice",
    "Hockey feint",
    "Hockey feint",
    "Hockey feint",
    "Hockey player's \"dangle\"",
    "Rink fake-out",
    "Fake at the rink",
    "Rink fake",
    "Hockey player's deceptive move",
    "Hockey fake-out",
    "Apollo astronaut Slayton",
    "Hockey feint",
    "Hockey player's deceptive move",
    "Fool on the ice",
    "Fraternity brother of Dubya",
    "Apollo astronaut Slayton",
    "Gridiron feint",
    "Astronaut Slayton",
    "Slayton of Apollo 18",
    "Astronaut Slayton",
    "Astronaut Slayton",
    "Apollo astronaut Slayton",
    "Astronaut Slayton",
    "Deceive, on the ice",
    "Astronaut Slayton",
    "Hockey feint",
    "Hockey fake"
  ],
  "bleh": ["\"Ugh!\"", "\"Ugh!\""],
  "muir": [
    "California's ___ Woods",
    "\"Studies in the Sierra\" author",
    "John who wrote \"Our National Parks\"",
    "California's ___ Woods National Monument",
    "Eponymous naturalist of a California woods",
    "Sierra Club founder John",
    "\"The Ghost and Mrs. ___\" (1947 movie)",
    "California's ___ Woods",
    "News anchor David",
    "Conservationist John",
    "California's ___ Woods",
    "Naturalist John",
    "Pioneering conservationist",
    "California's ___ Woods",
    "California's ___ Woods",
    "Naturalist John",
    "Conservationist on the California commemorative quarter",
    "John who wrote \"My First Summer in the Sierra\"",
    "John who founded the Sierra Club",
    "Sierra Club founder",
    "California's ___ Woods",
    "Sierra Club founder",
    "Naturalist who appears on the California quarter",
    "California's ___ Woods National Monument",
    "Sierra Club's first president",
    "1960's TV's \"The Ghost and Mrs. ___\"",
    "Sierra Club co-founder",
    "___ Woods National Monument",
    "Naturalist John",
    "Sierra Club founder John",
    "Naturalist John",
    "\"Steep Trails\" author",
    "___ Woods National Monument",
    "American naturalist John",
    "Pioneering conservationist",
    "Sierra Club founder John",
    "\"The Ghost and Mrs. ___\"",
    "\"The Ghost and Mrs. ___\"",
    "Mrs. of TV and film",
    "___ Woods (California tourist site)",
    "Sierra Club founder John"
  ],
  "haha": [
    "\"Oh, you told a funny\"",
    "Alternative to \"lol\"",
    "It's a laugh",
    "Reaction button option for a Facebook post",
    "[So funny!]",
    "\"Fooled you!\"",
    "___ funny (genuinely humorous)",
    "What might follow a crack",
    "\"Good joke!\"",
    "Funny (or sarcastic) joke response",
    "[LOL]",
    "\"Very funny!\"",
    "\"That's funny!\"",
    "\"Made you look!\"",
    "Interjection heard when breaking up",
    "\"So funny!\"",
    "\"Hilarious!\"",
    "\"Everybody's a comedian\"",
    "[LOL]",
    "\"Hilarious!\"",
    "\"The joke's on you\"",
    "Response to a joke",
    "\"lol\"",
    "Reaction to a crack",
    "\"That's hilarious!\"",
    "Certain Facebook reaction button",
    "\"You make me laugh\"",
    "Response to a joke",
    "Laugh",
    "Funny ___",
    "It's a laugh",
    "Audible \"LOL\"",
    "\"Good joke!\"",
    "\"That's a funny one\"",
    "\"You crack me up\"",
    "Reaction to a card",
    "\"You are so-o-o funny\"",
    "\"Joke's on you!\"",
    "Response to a wisecrack",
    "Routine reaction?",
    "\"Funny!\"",
    "\"Good one!\"",
    "\"Ver-r-ry funny!\"",
    "\"Very funny\"",
    "\"You're so funny,\" sarcastically",
    "\"Yeah, right\"",
    "\"Oh man, you're killing me!\"",
    "\"Funny strange or funny ___?\"",
    "Nelson's catchphrase on \"The Simpsons\"",
    "Crack response",
    "LOL, out loud",
    "\"That's rich!\"",
    "\"That's a good one!\"",
    "\"Very funny!\"",
    "Gag reflex?",
    "\"Aren't you the comedian?!\"",
    "\"That's a laugh!\"",
    "Reaction to a knee-slapper",
    "\"Funny ___\"",
    "LOL, spoken",
    "\"Oh, very funny!\"",
    "Ditch with a retaining wall used to divide land",
    "\"What a comic!\"",
    "\"Stop! You're killing me!\"",
    "\"Aren't we the comedian?!\"",
    "\"That's hysterical!\"",
    "\"Funny!\"",
    "Response to sarcasm",
    "\"Very funny ... not!\"",
    "\"Very funny!\"",
    "\"That's hysterical!\"",
    "Derisive comeback",
    "\"That is so funny\"",
    "More than a tee-hee",
    "Funny response",
    "\"Good joke!\"",
    "It's a laugh",
    "Comic's feedback",
    "\"Funny!\"",
    "Response to a gag",
    "\"Very funny!\"",
    "Big laugh",
    "\"Very funny!\"",
    "Response to a comic",
    "That's a laugh!",
    "Result of a crack?",
    "Sound of breaking up?",
    "Comic's favorite sound",
    "Result of a crack?",
    "A few laughs",
    "\"Very funny!\"",
    "More than a tee-hee",
    "Result of a crack?",
    "Joker's gibe",
    "8-Down sound",
    "\"That is so funny\"",
    "A little laughter",
    "Sarcastic laugh",
    "\"Very funny!\"",
    "\"That was funny\"",
    "Punchline reaction",
    "\"Ver-ry funny\"",
    "It's a laugh",
    "Mirthful reaction",
    "Punchline reaction",
    "\"That's a laugh\"",
    "It's a laugh",
    "\"Oh, very funny\"",
    "It's a laugh",
    "It's a laugh"
  ],
  "uies": [
    "180s",
    "Turnabouts, informally",
    "180s",
    "180s",
    "Some \"Fast and the Furious\" maneuvers, slangily",
    "Some GPS suggestions, informally",
    "Sometimes-illegal turns, in slang",
    "Turnarounds, slangily",
    "180s",
    "They're hung across roads",
    "180-degree turns, in slang",
    "180's",
    "Turnabouts, slangily"
  ],
  "inxs": [
    "\"Need You Tonight\" band",
    "\"Need You Tonight\" band, 1987",
    "Rock band with the 1988 #1 hit \"Need You Tonight\"",
    "Australian band with the 1988 #1 hit \"Need You Tonight\"",
    "Band with the 1988 #1 hit \"Need You Tonight\"",
    "\"Suicide Blonde\" band, 1990",
    "Band with the 1988 #1 hit \"Need You Tonight\"",
    "Band with the 1987 6x platinum album \"Kick\"",
    "\"Suicide Blonde\" band",
    "Band with the 1987 hit \"Need You Tonight\"",
    "\"Suicide Blonde\" band",
    "\"New Sensation\" band, 1988",
    "\"Need You Tonight\" band, 1987",
    "\"Listen Like Thieves\" band",
    "\"Need You Tonight\" band",
    "Band featured on the reality show \"Rock Star\"",
    "\"Suicide Blonde\" band, 1990",
    "\"Need You Tonight\" group, 1987",
    "\"Devil Inside\" group, 1988",
    "\"Need You Tonight\" group, 1987",
    "\"Need You Tonight\" band, 1987",
    "Band with the 1988 #1 hit \"Need You Tonight\"",
    "\"Suicide Blonde\" rock group",
    "80's rock band from Australia",
    "\"Suicide Blonde\" rock group",
    "\"Need You Tonight\" rock group"
  ],
  "fine": [
    "Punishment for jaywalking",
    "\"OK, have it your way!\"",
    "Parking ticket penalty",
    "\"Whatever!\"",
    "Numismatist's rating",
    "Result of a traffic ticket",
    "\"O.K. by me\"",
    "Punishment short of jail time",
    "Punishment that might follow a summons",
    "Dandy",
    "\"Be that way!\"",
    "Not just a slap on the wrist",
    "Comment before \"Be that way!\"",
    "\"O.K., have it your way\"",
    "Numismatic condition",
    "Numismatic classification",
    "Alternative to a jail sentence",
    "Something received with a ticket",
    "Dandy",
    "All right",
    "\"___ by me\"",
    "\"O.K. then\"",
    "Numismatist's classification",
    "Ticket cost?",
    "Speeder's risk",
    "Reduced sentence, maybe",
    "Something to be slapped with",
    "Dandy",
    "With 8-Down, museum material",
    "Just dandy",
    "Hunky-dory",
    "Speeder's penalty",
    "\"O.K.!\"",
    "Dandy",
    "Dandy",
    "Like some print",
    "Jaywalker's punishment",
    "Rare-coin rating",
    "Better than good",
    "Coin catalogue rating",
    "Hairsplitting",
    "Penalty",
    "Numismatist's classification",
    "Mulct"
  ],
  "bama": [
    "Crimson Tide, to fans",
    "Nine-time winner of the Sugar Bowl, informally",
    "Rival of Ole Miss",
    "\"Roll Tide!\" school",
    "\"Roll Tide!\" school, for short",
    "Southeastern Conference football powerhouse, for short",
    "The Tide",
    "Tuscaloosa team, for short",
    "Ole Miss rival",
    "N.C.A.A. football champs of '09 and '11",
    "Rival of Ole Miss",
    "Tuscaloosa university, for short",
    "Crimson Tide school, for short",
    "Univ. with the cheer \"Roll Tide!\"",
    "The Crimson Tide, familiarly",
    "The Crimson Tide, for short",
    "Crimson Tide, to fans",
    "The Crimson Tide, informally",
    "The Crimson Tide, familiarly",
    "Bart Starr's alma mater, briefly",
    "Southeastern Conference team, for short",
    "The Crimson Tide, to fans",
    "Crimson and white school, for short",
    "L.S.U. rival, informally",
    "Ole Miss rival",
    "Southeastern Conf. powerhouse",
    "The Crimson Tide, familiarly",
    "The Tide, of college sports",
    "The Crimson Tide, familiarly",
    "Eight-time Sugar Bowl champs",
    "Crimson Tide, informally",
    "'93 Sugar Bowl champs",
    "Southeastern Conf. team",
    "The Crimson Tide, for short",
    "The Crimson Tide",
    "Crimson Tide, briefly",
    "Southeastern Conference team, informally",
    "1993 Sugar Bowl winner, for short",
    "Crimson Tide, for short",
    "Eight-time Sugar Bowl champs, familiarly",
    "Where Bear Bryant coached, informally",
    "The Crimson Tide",
    "The Crimson Tide, for short",
    "Tuscaloosa university, informally",
    "The Crimson Tide"
  ],
  "fdic": [
    "Banking org. founded during the Great Depression",
    "Bank backer, for short",
    "Bank-backing grp.",
    "Banking org. since 1933",
    "Savings acct. protector",
    "Bank window letters",
    "Bank account protector, for short",
    "Banking inits.",
    "Bank-protecting agcy.",
    "Initialism on a bank door",
    "Guarantor of financial accounts, for short",
    "Guarantor of financial accts.",
    "Govt. money guarantor",
    "Bank guarantor, for short",
    "Bank protector, for short",
    "Bank acct. guarantor",
    "Banking initialism",
    "Bank regulating org.",
    "It helps prevent runs: Abbr.",
    "Bank letters",
    "Money guarantor, for short",
    "Bank ad letters",
    "Bank savings protection org.",
    "Money guarantor, for short",
    "S.&L. protector",
    "Protection for some I.R.A.'s",
    "Org. that guarantees bank holdings",
    "Bank letters"
  ],
  "ojos": [
    "Eyes: Sp.",
    "44-Across, en espanol",
    "13-Down, south of the border",
    "Iberian eyes",
    "Spanish eyes",
    "Sight-seers at the Prado?",
    "Spanish seers?",
    "Optometria concern",
    "Spanish eyes",
    "Spanish eyes",
    "Spanish eyes",
    "Spanish eyes",
    "Spanish eyes",
    "Spanish eyes"
  ],
  "lump": [
    "Feature of a batter that needs more whisking",
    "Sugar serving",
    "\"One ___ or two?\"",
    "Coal unit",
    "Mattress problem",
    "Sugar quantity",
    "Concern for a dermatologist",
    "Group (together)",
    "Mattress problem",
    "Sugar unit",
    "It's in your throat when you choke up",
    "Imperfect gravy feature",
    "Mattress problem",
    "Sugar unit",
    "Domino unit",
    "Coal unit",
    "Unit of sugar",
    "Unit of sugar or coal"
  ],
  "flag": [
    "Item on a president's lapel",
    "Every country has one",
    "With 22-Across, common lapel attachments for presidents",
    "Call attention to, as a potential problem",
    "Something planted when claiming territory",
    "Fourth of July centerpiece",
    "Important Stratego piece",
    "United Nations headquarters decoration",
    "Tire",
    "Wane",
    "It can be raised or folded",
    "Standard",
    "Tire",
    "*Union, e.g.",
    "Old Glory, for one",
    "Stars and Stripes, e.g.",
    "Jolly Roger, for one",
    "Stars and Stripes, e.g.",
    "Weary",
    "Olympic ceremony sight",
    "Sixth word in the Pledge of Allegiance",
    "Piece in the game Stratego",
    "United Nations sight",
    "Fort McHenry sight",
    "Lose strength",
    "Indy 500 prop",
    "Tire",
    "Ross national product",
    "Lose pep",
    "Old Glory"
  ],
  "tama": [
    "Author Janowitz of literature's \"brat pack\"",
    "Writer Janowitz",
    "\"Memoir of Glamour and Dysfunction\" writer Janowitz",
    "Janowitz who wrote \"Slaves of New York\"",
    "Author Janowitz",
    "Author Janowitz",
    "Janowitz who wrote \"Slaves of New York\"",
    "Author Janowitz",
    "Novelist Janowitz",
    "Author Janowitz",
    "Author Janowitz",
    "Author Janowitz",
    "Author Janowitz",
    "Author Janowitz",
    "Novelist Janowitz",
    "\"Slaves of New York\" author Janowitz",
    "Author Janowitz",
    "Author Janowitz",
    "Author Janowitz",
    "Novelist Janowitz",
    "Novelist Janowitz",
    "Janowitz who wrote \"Slaves of New York\"",
    "Writer Janowitz",
    "Author Janowitz"
  ],
  "gris": [
    "Pinot ___",
    "Cubist who painted \"Violin and Glass\"",
    "Spanish cubist Juan",
    "Cubism pioneer Juan",
    "1912 painter of Picasso",
    "Cubism pioneer Juan",
    "Cubism pioneer"
  ],
  "ohhi": [
    "Greeting when running into someone unexpectedly",
    "\"Huh, funny running into you!\"",
    "\"Didn't expect to see you here!\"",
    "\"Didn't think I'd run into you here!\"",
    "\"Well, howdy\"",
    "Tepid greeting",
    "\"Didn't expect to run into you here!\"",
    "Surprised salutation",
    "\"What a surprise to see you here!\"",
    "\"Fancy seeing you here!\"",
    "\"Didn't expect to see you here!\"",
    "\"Fancy seeing you here!\"",
    "\"Surprising to run into you here\"",
    "\"Didn't see ya there!\"",
    "\"Um ... fancy meeting you here\"",
    "\"Didn't think you'd be here\"",
    "\"Fancy meeting you here!\"",
    "\"Fancy seeing you here!\"",
    "\"Funny bumping into you here\"",
    "\"What a surprise to run into you!\"",
    "\"Didn't see you there\"",
    "\"Fancy meeting you here!\"",
    "\"I'm surprised to see you!\"",
    "\"Funny running into you here!\"",
    "\"I wasn't expecting you!\" lead-in",
    "\"Funny meeting you here!\""
  ],
  "hsia": [
    "Earliest-known Chinese dynasty (dating back to 2000 B.C.)",
    "Early Chinese dynasty",
    "Early Chinese dynasty",
    "First Chinese dynasty",
    "Dynasty founded by Yu the Great",
    "First Chinese dynasty",
    "Chinese dynasty before the Shang",
    "Early Chinese dynasty",
    "Early Chinese dynasty",
    "Dynasty before the Shang",
    "Early Chinese dynasty",
    "Early Chinese dynasty",
    "4,000-year-old Chinese dynasty"
  ],
  "sala": [
    "Division de la casa",
    "Hacienda room",
    "Division of a hacienda",
    "Hacienda room",
    "41-Down's room",
    "Part of una casa",
    "Hacienda room",
    "Division of a house",
    "Room in una casa",
    "Spanish parlor",
    "Palacio part",
    "Part of una casa",
    "Room in una residencia",
    "Hacienda room",
    "A room with una vista?",
    "Room in la casa",
    "Spanish hotel reservation",
    "Room in una casa",
    "Part of a casa",
    "Cuarto de bano, e.g.",
    "Hacienda chamber",
    "Casa part",
    "Room for Ricardo",
    "Spanish parlor",
    "Part of una casa",
    "Hacienda room",
    "Room in a casa",
    "Casa chamber",
    "Large hall",
    "Hacienda room",
    "Hacienda room",
    "Casa chamber",
    "Hacienda room",
    "Large hall",
    "Part of una casa",
    "Room at the Alhambra",
    "Casa room",
    "Room, to Ricardo",
    "Room in an albergo",
    "Parlor, in La Paz",
    "Casa room",
    "Albergo offering",
    "Hacienda part",
    "Hacienda division"
  ],
  "shih": [
    "___ Tzu (dog breed)",
    "___ Tzu",
    "___ Tzu (dog)",
    "___ Tzu (dog)",
    "___-poo (designer dog)",
    "___ Tzu (dog)",
    "\"___ Ching\" (classic book of Chinese poetry)",
    "___ Tzu",
    "___ Tzu (toy dog)",
    "___ Tzu (dog)",
    "___ Tzu (toy dog)"
  ],
  "lucy": [
    "Cartoon character with a noted football prank",
    "Ethel's neighbor/pal, on 1950s TV",
    "Archaeological find of 1974",
    "Sitcom character discussed in the 2003 biography \"Ball of Fire\"",
    "Exasperated cry in a 1950's sitcom",
    "Desi loved her",
    "Charlie Brown tormentor",
    "Charlie Brown's belligerent friend"
  ],
  "rust": [
    "Orange-brown hue",
    "Brownish shade",
    "Lack of practice, metaphorically",
    "It can take root in wet places",
    "Lack of practice, metaphorically",
    "Feature of many a jalopy",
    "Deteriorate with inactivity",
    "Sign of age",
    "Fall foliage color",
    "\"Too much rest is ___\": Sir Walter Scott",
    "Tin Man's worry",
    "Formation on 28-Down",
    "Sign of industrial decay",
    "Reddish brown",
    "Body shop concern",
    "Lie idle too long",
    "What oil helps dissolve",
    "Fall shade",
    "Impair through inactivity",
    "Reddish-brown",
    "Corrosion sign",
    "Sign of neglect",
    "Reddish-brown",
    "Tin Man's malady",
    "___ Belt",
    "Fall shade",
    "Sign of disuse",
    "Oxidize",
    "Sign of inactivity",
    "Autumnal hue",
    "Fall shade",
    "Pipe problem",
    "Fall shade",
    "What stainless steel doesn't do",
    "Color close to copper brown",
    "Fall color",
    "Red-brown",
    "Reddish-brown",
    "Reddish-brown",
    "Deteriorate",
    "Acquire signs of age",
    "Oxidize",
    "Jalopy feature"
  ],
  "tarn": [
    "Alpine lake",
    "Alpine lake",
    "Mountain lake",
    "Mountain lake",
    "Small mountain lake",
    "Lake formed by glaciation",
    "Mountain pool",
    "Mountain pool",
    "Mountain lake",
    "Mountain pool"
  ],
  "sops": [
    "Concessions",
    "Concessions",
    "Soaks (up)",
    "Concessions",
    "Absorbs, with \"up\"",
    "Minor concessions",
    "Soaks (up)",
    "Gravy go-withs",
    "Minor concessions",
    "Soaks (up)",
    "Chunks of bread for stew, e.g.",
    "Biscuits and rolls, sometimes",
    "Concessions",
    "Pacifiers",
    "Payoffs",
    "Absorbs, with \"up\"",
    "Bribes",
    "Soaks (up)",
    "Absorbs, with \"up\"",
    "Food eaten with gravy",
    "Concessions",
    "Absorbs, with \"up\"",
    "Soaks (up)",
    "Payoffs",
    "Dips in gravy",
    "Soaks (up)",
    "Bribes",
    "Payoffs",
    "Payoffs",
    "Absorbs, with \"up\"",
    "Bribes",
    "Saturates",
    "Drenches",
    "Rolls, for stew"
  ],
  "lett": [
    "Neighbor of a Belarusian",
    "Baltic native",
    "Baltic dweller",
    "Resident of Riga",
    "Neighbor of an Estonian",
    "Riga resident",
    "Baltic native",
    "Riga resident",
    "Baltic native",
    "Resident of Riga",
    "Riga resident",
    "Riga native",
    "Riga resident",
    "Dweller on the Baltic",
    "Mikhail Baryshnikov, by birth",
    "Member of a NATO land since 2004",
    "Riga resident",
    "Riga resident",
    "Two-time football Pro Bowler Leon",
    "Riga native",
    "Riga native",
    "Leon with three Super Bowl rings",
    "Baltic Sea viewer, maybe",
    "Baltic resident",
    "Neighbor of an Estonian",
    "Riga resident",
    "Certain Baltic",
    "Baryshnikov, by birth",
    "Neighbor of a Belarussian",
    "Baltic native",
    "Riga native",
    "Liepaja resident",
    "Riga resident",
    "Baryshnikov, by birth",
    "Baltic native",
    "Riga resident",
    "Riga native",
    "Baltic native",
    "Latvian",
    "Riga native",
    "Baltic native",
    "Riga denizen",
    "Riga native",
    "Riga resident"
  ],
  "xers": [
    "Many college students of the 1980s-'90s, informally",
    "Gen ___ (preceders of millennials)",
    "Gen ___ (post-baby boomers)",
    "Gen ___",
    "Gen ___",
    "Boomers' kids",
    "Gen ___ (boomers' kids)",
    "Boomers' followers",
    "MTV generation",
    "Gen ___ (thirtysomethings)",
    "Gen ___ (thirtysomethings)",
    "Gen ___ (demographic group)",
    "Gen ___",
    "Some voters",
    "Gen-___ (young set)"
  ],
  "gnar": [
    "Growl like an angry dog",
    "Growl like a dog",
    "Make a snarling sound",
    "Growl",
    "Growl",
    "Growl",
    "Make a snarling sound",
    "Sound like an angry dog",
    "Make a snarling sound",
    "Growl",
    "Cur's sound",
    "Imitate an angry dog",
    "Growl",
    "Growl like a dog",
    "Growl"
  ],
  "link": [
    "Serving of kielbasa or knackwurst",
    "Something to click online",
    "Association",
    "One might be embedded",
    "Safari sight?",
    "Something clickable",
    "Coupling",
    "Connection",
    "___ sausage",
    "One may be sent in an e-mail",
    "Coupling",
    "Evolutionist's discovery",
    "Something a computer user may click",
    "Web site offering",
    "Web page feature",
    "On-line convenience",
    "It may be hot on the Web",
    "Join",
    "Nexus",
    "7.92 inches"
  ],
  "quik": [
    "Classic cocoa powder brand",
    "Speedy, in adspeak",
    "Classic Nestle drink",
    "Old Nestle brand",
    "Classic brand with a bunny mascot"
  ],
  "bike": [
    "Triathlete's need",
    "Harley, e.g., informally",
    "Cycle",
    "Need for a triathlon",
    "Two-wheeler",
    "Do one-third of a triathlon",
    "Transportation for Mary Poppins or E.T.",
    "Ten-speed, e.g.",
    "Exercise ___",
    "What might take a stand outside a school?",
    "Hog",
    "With 18-Across, the Tour de France, for one",
    "Two-wheeler",
    "Hog, so to speak",
    "Two-wheeler",
    "Triathlete's need",
    "Mountain ___",
    "Pedal",
    "Two-wheeler",
    "Kind of messenger",
    "Messenger's vehicle"
  ],
  "peon": [
    "Drudge",
    "One doing menial work",
    "Lowly laborer",
    "Menial laborer",
    "Lowly worker",
    "Hacienda drudge",
    "Drudge",
    "Lowly worker",
    "Unpaid intern, jocularly",
    "Lowly worker",
    "Lowly worker",
    "Drudge",
    "Common laborer",
    "Cog, so to speak",
    "Low hand?",
    "Grunt",
    "Worker hardly making a living wage",
    "Lowly worker",
    "Lowly one",
    "Drone",
    "Grunt",
    "Lowly laborer",
    "McJob doer",
    "Low jack?",
    "Unappreciated worker",
    "Drudge",
    "Lowly sort",
    "Lowly worker",
    "Underhand?",
    "Drudge",
    "Unskilled laborer",
    "V.I.P.'s opposite",
    "Low hand",
    "Mighty boss's opposite",
    "Dogsbody, so to speak",
    "Hacienda hand, maybe",
    "Menial",
    "Unskilled laborer",
    "Menial",
    "V.I.P.'s opposite",
    "Working stiff",
    "Hacienda hand, maybe",
    "Nobody",
    "Lowly laborer",
    "Drudge",
    "Lowly laborer",
    "Menial laborer",
    "Drudge",
    "Lowly laborer",
    "Drudge",
    "Lowly worker",
    "Lowly laborer",
    "Hacienda hand",
    "Drudge",
    "Menial",
    "Working stiff",
    "Drudge",
    "Menial",
    "Working class member",
    "Drudge",
    "Lowly worker",
    "Exploited worker",
    "Drudge"
  ],
  "caky": [
    "Thick and clumpy",
    "Like some poorly applied makeup",
    "Liable to clump",
    "Clumped",
    "Forming clumps, like drying mud",
    "Forming clumps",
    "Like badly applied makeup",
    "Encrusted",
    "Like dry mud",
    "Like some face powders",
    "Not crumbly"
  ],
  "luke": [
    "___ Bryan, \"American Idol\" judge",
    "One of the four Gospels",
    "Princess Leia's twin brother",
    "Book that's the source of the phrase \"Physician, heal thyself\"",
    "Gospel with the Prodigal Son parable",
    "Trainer of Rey in \"The Last Jedi\"",
    "Longest book in the New Testament",
    "See 65-Across",
    "Bo's cousin on \"The Dukes of Hazzard\"",
    "First name in \"Star Wars\"",
    "Book between Mark and John",
    "It begins \"Forasmuch as many have taken in hand ...\"",
    "Perry of \"Beverly Hills 90210\"",
    "\"Use the Force, ___\"",
    "It mentions the Prodigal Son",
    "Country singer Bryan",
    "Patron saint of surgeons",
    "The prodigal son is found in it",
    "Darth's son",
    "Gospel writer",
    "Gospel writer",
    "New Testament book",
    "Perry of \"90210\"",
    "An apostle",
    "Book before John",
    "Laura's daytime lover",
    "New Testament book",
    "One of the Gospels",
    "Darth Vader's son",
    "Heartthrob Perry",
    "Patron saint of physicians"
  ],
  "deck": [
    "Punch out",
    "K.O.",
    "Set of 108 for Uno",
    "Place for a chaise longue",
    "It holds 5,148 potential flushes",
    "KO",
    "It's divided at the start of war",
    "Hand source",
    "Kayo",
    "Really wallop",
    "Send to the canvas",
    "All 52 cards",
    "What a swabbie swabs",
    "Where to spot a king or queen",
    "It may be stacked or cut",
    "Kayo",
    "Bicycle pack",
    "52 cards",
    "K.O.",
    "Knock down",
    "Knock down",
    "Knock flat",
    "Flatten",
    "It may be stacked",
    "It's not damaged by cutting",
    "Pack of sharks?",
    "It may be cut",
    "King's place",
    "Where to play shipboard shuffleboard",
    "Shuffleboard locale, maybe",
    "Wallop",
    "Bicycle set",
    "Clobber",
    "Flatten",
    "Wallop"
  ],
  "very": [
    "Extremely",
    "With 20-Across, \"Well done!\"",
    "\"___ funny!\"",
    "See 114-Across",
    "So",
    "Extremely",
    "Extremely",
    "So so?",
    "First part of an I.P. address?",
    "\"___ funny!\"",
    "With 26-Across, \"Amen!\"",
    "Truly",
    "VHF part",
    "Especially",
    "So",
    "Extremely",
    "Exceedingly",
    "More than a bit",
    "More than a little",
    "So",
    "Exact",
    "Extremely",
    "Certainly",
    "Damned",
    "Darned",
    "\"___ funny. Ha, ha!\"",
    "Darned",
    "Exceedingly",
    "To a high degree",
    "Exceedingly",
    "Mucho",
    "Exceedingly",
    "V.I.P. section?",
    "Jolly, to the British"
  ],
  "rums": [
    "Captain Morgan and others",
    "Some Cuban exports",
    "Captain Morgan and Admiral Nelson's",
    "Big Caribbean exports",
    "Captain Morgan and others",
    "Caribbean exports",
    "Captain Morgan and others",
    "Liquors for pirates",
    "Jamaica exports",
    "Pirate spirits",
    "Jamaican spirits",
    "Jamaican exports",
    "Jamaican exports",
    "Ingredients in presidentes"
  ],
  "joan": [
    "\"Play It as It Lays\" author Didion",
    "Woman's name in English that's a man's name in Catalan",
    "___ Watson, role for Lucy Liu on \"Elementary\"",
    "Rivers of New York City",
    "\"Mad Men\" femme fatale",
    "Essayist Didion",
    "___ of Arc",
    "Artist Miro",
    "Rivers of comedy",
    "Rivers with barbs",
    "Writer Didion",
    "Rivers of New York",
    "___ of Arc",
    "Novelist Didion",
    "___ of Arc",
    "Title heroine in a Shaw play"
  ],
  "diet": [
    "Calorie-counting regimen",
    "Not down so much?",
    "Losing effort",
    "Weight watcher's plan",
    "Insects, berries and worms, for a robin",
    "Assembly",
    "Nutritionist's plan",
    "Beverly Hills ___",
    "Take in less",
    "Trim the fat",
    "Weight-loss program",
    "Losing effort?",
    "\"Breakthrough\" detailed in many a best seller",
    "Not down very much?",
    "___ of Worms",
    "Insects and seeds, for many birds",
    "Cut down on calories",
    "Nuts and fruit, in part, for squirrels",
    "Course-altering plan?",
    "Lighten up?",
    "Take in less",
    "Weight-loss program",
    "Nuts, berries, etc., for squirrels",
    "Parliament",
    "Reduction of sugar intake, e.g.",
    "Plan that changes courses",
    "Legislature",
    "Many a New Year's resolution",
    "Legislature",
    "Become less of a person?",
    "Jenny Craig regimen",
    "Weightlifter?",
    "Purposely try to lose",
    "Locusts and wild honey, to John the Baptist",
    "Losing proposition?",
    "Slimmer's regimen",
    "What's taken in",
    "South Beach ___",
    "Losing cause?",
    "One may cheat on it",
    "Regimen",
    "Hope to lose?",
    "See 6-Down",
    "Losing cause",
    "Losing effort?",
    "Go easy on the calories",
    "Reduced in calories",
    "Losing proposition?",
    "Down less",
    "Assembly",
    "Losing proposition?",
    "Eating regimen",
    "Losing hope?",
    "Become lighter?",
    "Cut the fat",
    "Many a New Year's resolution",
    "Weight loss plan",
    "Plan to take off",
    "Reduced fare",
    "Dr. Atkins's plan",
    "Count calories",
    "Reduced-fare program?",
    "Regular fare",
    "Reduced fare",
    "Japanese assembly",
    "Loser's concern",
    "Japanese parliament",
    "Regular fare",
    "Balanced regimen",
    "Losing proposition",
    "Self-deprivation",
    "Legislature",
    "Losing proposition"
  ],
  "kind": [
    "Sort",
    "Tenderhearted",
    "Variety",
    "Clement",
    "Ilk",
    "Type",
    "Humane",
    "\"___ sir ...\"",
    "Like some souls or words",
    "Sort",
    "Warm-hearted",
    "Species",
    "Giving",
    "Considerate"
  ],
  "onkp": [
    "Doing some menial duty, in old army lingo",
    "Peeling potatoes as punishment, maybe",
    "Cleaning up the mess, for short?",
    "Like a G.I. cleaning up after a meal, maybe",
    "Working in the mess hall",
    "Stuck in a mess?",
    "Peeling potatoes in a mess hall, say",
    "Like some punished 1-Down",
    "Peeling potatoes, perhaps",
    "Cleaning a mess, maybe",
    "Like a private peeling potatoes",
    "Cleaning a mess in a mess, maybe",
    "Cleaning the mess?",
    "Peeling potatoes, perhaps",
    "Being punished, military-style",
    "Cleaning up a mess, maybe",
    "Peeling potatoes, stereotypically",
    "Preparing hash for G.I. Joe, say",
    "Where Beetle Bailey can often be found",
    "Like some punished G.I.'s",
    "Like a G.I. peeling spuds",
    "Wielding a peeler, maybe",
    "Making a mess in the army?",
    "Like a punished G.I., perhaps",
    "Working in a mess"
  ],
  "tats": [
    "Parlor pics",
    "Hip designs?",
    "Lots of ink, in slang",
    "Some parlor designs, informally",
    "Ink",
    "Pictures created with needles, informally",
    "Some body art, for short",
    "Some needlework, informally?",
    "Ink",
    "Some body work, in slang",
    "Ink",
    "Pictures inked on the body, in slang",
    "Body designs, informally",
    "Some lasting art, in slang",
    "Needlework, for short?",
    "Body art, slangily",
    "Makes lace",
    "Body pics",
    "Makes a doily",
    "Does a doily",
    "Uses a bobbin",
    "Makes lace",
    "Makes lace",
    "Makes lace",
    "Makes lace",
    "Makes lace",
    "Makes doilies",
    "Does loops and knots",
    "Makes lace",
    "Uses a sewing shuttle",
    "Uses a shuttle",
    "Does lacework",
    "Does hand shuttle work",
    "Makes lace"
  ],
  "bots": [
    "Spam spewers",
    "Sources of much spam",
    "Droids and such",
    "Computer-controlled players, in gaming lingo",
    "Droids",
    "Some modern viruses and worms",
    "Some spam senders",
    "Internet prowlers",
    "Droids"
  ],
  "bevy": [
    "Gaggle",
    "Group of like things",
    "Group of quail",
    "Flock",
    "Group of quails",
    "Group of beauties",
    "___ of beauties",
    "Group of quail",
    "Group of birds",
    "Large group",
    "Bunch",
    "Raft",
    "Multitude",
    "Gathering",
    "Group of birds",
    "Group, as of beauties",
    "Large group",
    "Flock",
    "Group of quail"
  ],
  "pres": [
    "V.I.P. above veep",
    "Univ. bigwig",
    "R.M.N. or L.B.J.",
    "Air Force One passenger: Abbr.",
    "Part of Potus: Abbr.",
    "U.S. 1?",
    "Inauguration V.I.P.: Abbr.",
    "Top exec.",
    "Univ. bigwig",
    "One aboard Marine One: Abbr.",
    "Air Force One passenger: Abbr.",
    "Secret Service's charge: Abbr.",
    "Near: Fr.",
    "Cabinet maker?: Abbr.",
    "Three-term title for 22-Across: Abbr.",
    "Roosevelt, Wilson, Carter or Obama: Abbr.",
    "Certain tense: Abbr.",
    "P.M. counterpart",
    "U. V.I.P.",
    "Corp. V.I.P.",
    "Top exec.",
    "Firm wheel: Abbr.",
    "White House occupant: Abbr.",
    "Alternative to Meth. or Luth.",
    "Corp. honcho",
    "Board member: Abbr.",
    "F.D.R. or J.F.K.",
    "Article II subj.",
    "J.F.K. or Dubya",
    "Firm boss: Abbr.",
    "Co. V.I.P.",
    "Firm head: Abbr.",
    "Motorcade V.I.P.: Abbr.",
    "Member of a board of dirs.",
    "Air Force One passenger: Abbr.",
    "Penn. Ave. resident",
    "Firm head: Abbr.",
    "Johnson or Johnson: Abbr.",
    "F.D.R. or D.D.E.",
    "Exec.",
    "P.M. counterpart",
    "Chief exec.",
    "Executive: Abbr.",
    "Nov. electee",
    "D.C. V.I.P.",
    "C.E.O.",
    "Chief exec",
    "H.S.T. was one",
    "Clinton, e.g.: Abbr.",
    "J.F.K., for one",
    "C. in C.",
    "H.S.T. or D.D.E.",
    "H.S.T. or R.M.N.",
    "C.E.O.",
    "White House abbr.",
    "Chief exec."
  ],
  "kiri": [
    "Soprano ___ Te Kanawa",
    "Soprano ___ Te Kanawa",
    "Dame ___ Te Kanawa",
    "Diva ___ Te Kanawa",
    "Diva ___ Te Kanawa",
    "Soprano ___ Te Kanawa",
    "Soprano ___ Te Kanawa",
    "___ Davis, \"A Girl Like Me\" documentarian",
    "Opera's ___ Te Kanawa",
    "Soprano ___ Te Kanawa",
    "Opera's ___ Te Kanawa",
    "Diva ___ Te Kanawa",
    "Diva ___ Te Kanawa",
    "Opera's ___ Te Kanawa",
    "Soprano ___ Te Kanawa",
    "___ Te Kanawa",
    "Opera's ___ Te Kanawa",
    "Singer ___ Te Kanawa",
    "First name in opera",
    "Opera's ___ Te Kanawa",
    "First name among sopranos",
    "Opera's ___ Te Kanawa",
    "Diva ___ Te Kanawa",
    "Soprano Te Kanawa",
    "First name in sopranos",
    "Dame ___ Te Kanawa",
    "Soprano Te Kanawa"
  ],
  "isaw": [
    "\"You don't need to tell me what happened\"",
    "Middle of Caesar's boast",
    "Part of Caesar's boast",
    "Dr. Seuss's \"And to Think That ___ It on Mulberry Street\"",
    "\"Vidi\"",
    "\"___ the light!\"",
    "Caesarean section?",
    "What \"vidi\" means in \"Veni, vidi, vici\"",
    "\"___ Mommy kissing ...\"",
    "\"___ Her Standing There\"",
    "\"Vidi,\" translated",
    "Caesarean section?",
    "1965 movie \"___ What You Did\"",
    "Vidi in \"Veni, vidi, vici\"",
    "\"The Last Time ___ Paris\"",
    "Dr. Seuss's \"And to Think That ___ It on Mulberry Street\"",
    "\"Vidi\"",
    "\"Vidi,\" translated",
    "Eyewitness's words",
    "\"The Last Time ___ Paris\"",
    "Middle of Caesar's boast",
    "Vidi, translated",
    "Caesar's \"vidi\"",
    "Caesarean section?",
    "\"___ Stars,\" #1 hit for Freddy Martin, 1934",
    "Part of Caesar's boast",
    "\"___ Mommy Kissing Santa Claus\"",
    "Part of Caesar's boast",
    "\"___ Mommy Kissing Santa Claus\"",
    "Part of Caesar's boast",
    "Second of an ancient trio",
    "What \"vidi\" means",
    "The Beatles' \"___Her Standing There\"",
    "Caesarean section?",
    "\".... ere ___ Elba\""
  ],
  "zinc": [
    "Immunity-boosting element",
    "Metal in galvanization",
    "About 97% of the modern penny",
    "30, on a table",
    "Penny, mostly",
    "Portion of a penny",
    "Next-to-last element alphabetically",
    "Multivitamin ingredient",
    "More than a fraction of a cent",
    "Mineral contained in oysters",
    "Next-to-last chemical element alphabetically, before zirconium",
    "Battery element",
    "___ oxide (beachgoer's protection)",
    "Brass section?",
    "Corrosion-preventing coating",
    "Component of brass",
    "97.5% of a penny",
    "Major Peruvian export",
    "Brass component",
    "Calamine component",
    "97.5% of a penny",
    "Next-to-last element alphabetically",
    "___ oxide (sunscreen compound)",
    "Much of a penny",
    "Multivitamin additive",
    "Metal in galvanization",
    "Metal in some batteries",
    "Metal in pennies",
    "Shade of gray",
    "Brass component",
    "Metal in brass",
    "Kind of sulfide",
    "Brass = copper + ___",
    "Brass component",
    "Plating material",
    "Galvanizing metal",
    "Like some plating",
    "Brass component",
    "Healthful mineral",
    "Element #30",
    "Coating metal",
    "Over-the-counter cold remedy",
    "Vitamin additive",
    "Wurtzite ingredient"
  ],
  "isak": [
    "\"Out of Africa\" writer Dinesen",
    "\"Out of Africa\" author Dinesen",
    "Dinesen who wrote \"Out of Africa\"",
    "\"Out of Africa\" author Dinesen",
    "Writer Dinesen",
    "Dinesen who wrote \"Out of Africa\"",
    "Author Dinesen",
    "\"Out of Africa\" writer Dinesen",
    "\"Babette's Feast\" author Dinesen",
    "Author Dinesen",
    "Author Dinesen",
    "Writer Dinesen",
    "Writer Dinesen",
    "\"Out of Africa\" author Dinesen",
    "Author Dinesen",
    "Dinesen who wrote \"Out of Africa\"",
    "Author Dinesen",
    "\"Out of Africa\" author Dinesen",
    "Author Dinesen",
    "\"Out of Africa\" novelist Dinesen",
    "Author Dinesen",
    "\"Out of Africa\" name",
    "Author Dinesen",
    "Author Dinesen",
    "Author Dinesen",
    "Author Dinesen",
    "Professor Borg in Bergman's \"Wild Strawberries\"",
    "Author Dinesen",
    "Author Dinesen",
    "Writer Dinesen",
    "Writer Dinesen",
    "Author Dinesen",
    "Writer Dinesen",
    "Dinesen who wrote \"Out of Africa\"",
    "Author Dinesen",
    "Writer Dinesen",
    "Writer Dinesen",
    "Author Dinesen",
    "Danish writer Dinesen",
    "Author Dinesen"
  ],
  "wake": [
    "What's aft a ship's aft",
    "Aftermath",
    "Shake, maybe",
    "Rouse",
    "Speedboat follower",
    "What might give a water-skier trouble",
    "Aftermath",
    "After-life gathering?",
    "Boat trailer?",
    "Evidence of a ship's passing",
    "Come to",
    "Come out of a coma",
    "Danger for small craft",
    "Rouse from slumber",
    "Bring around",
    "Boat trailer?",
    "Aftermath",
    "Twister's trail",
    "Boat trailer",
    "Rouse",
    "Boat follower",
    "Stir",
    "Ship's trail",
    "U.S. Pacific atoll",
    "Pacific island",
    "Ship's trail",
    "Going-away party?",
    "Water trail"
  ],
  "iana": [
    "Ending with Louis",
    "Collector's suffix",
    "Suffix with Edward",
    "Bibliophile's suffix",
    "Collector's suffix",
    "Suffix for a collection",
    "Collector's suffix",
    "Bibliophile's suffix"
  ],
  "amfm": [
    "Kind of radio",
    "Radio band options",
    "Letters on a radio button",
    "Switch on the radio",
    "Like some radios",
    "Car radio button",
    "Band options",
    "Switch that changes bands on the radio",
    "Radio button",
    "Like some radios",
    "Car radio button",
    "Having two bands, as most radios",
    "Like most car radios",
    "Band options",
    "Radio switch",
    "Kind of radio",
    "Like most 22-Acrosses",
    "Radio switch",
    "Having more than one band",
    "Like most car radios",
    "Satellite precursor",
    "Band switch",
    "Like most radios",
    "Alternative to satellite",
    "___ radio",
    "Like most car radios",
    "Switch letters",
    "Kind of tuner",
    "Like car radios",
    "Band switch",
    "Radio button",
    "Switch letters",
    "Radio switch",
    "Satellite counterpart",
    "Radio choice",
    "Radio letters",
    "Kind of radio",
    "Kind of radio",
    "Like some tuners",
    "Broadcasting bands",
    "Radio switch",
    "Kind of radio",
    "Kind of radio",
    "Clock-radio switch",
    "Kind of radio",
    "___ radio",
    "Radio type",
    "Radio feature"
  ],
  "orcs": [
    "Captors of Frodo Baggins",
    "Enemies of hobbits",
    "Goblinlike creatures",
    "Foes of elves, in Tolkien",
    "World of Warcraft enemies",
    "Certain warriors in Magic: The Gathering",
    "Middle-earth menaces",
    "Wolf riders in \"The Lord of the Rings\"",
    "Fantasy creatures",
    "Marauding group in Tolkien's \"The Two Towers\"",
    "Tolkien creatures",
    "Meanies of fantasy",
    "Villains of fantasy",
    "Ugly Middle-earth creatures",
    "Tolkien's Gorbag and Bolg",
    "\"The Lord of the Rings\" creatures",
    "Tolkien creatures",
    "\"The Two Towers\" army",
    "Foes of Frodo",
    "Fellowship foes",
    "Tolkien villains",
    "Tolkien beasts",
    "Tolkien monsters",
    "Tolkien brutes",
    "Menaces to hobbits",
    "\"The Lord of the Rings\" army",
    "Dungeons & Dragons creatures",
    "Soldiers of Saruman, in Tolkien",
    "Tolkien monsters",
    "Tolkien Moria warriors",
    "Mythical monsters",
    "Ogres",
    "Grampuses",
    "Horrid giants",
    "Tolkien creatures",
    "Killer whales"
  ],
  "atad": [
    "Just slightly",
    "Slightly",
    "Opposite of very",
    "Slightly",
    "Slightly",
    "Slightly",
    "Not much",
    "Slightly",
    "Not much",
    "Not much",
    "Slightly",
    "Facts / Somewhat",
    "Not very much",
    "Not much",
    "Slightly",
    "Somewhat",
    "Not much",
    "Somewhat",
    "Slightly",
    "Sum",
    "Barely",
    "Not much",
    "Just slightly",
    "Slightly",
    "Somewhat",
    "Somewhat",
    "Slightly",
    "Somewhat",
    "Not much",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Barely",
    "Somewhat",
    "Slightly",
    "Somewhat",
    "Not very much",
    "Only minimally",
    "Not much",
    "Not much",
    "Slightly",
    "Slightly",
    "Slightly",
    "Just slightly",
    "Opposite of extremely",
    "Just ___ (slightly)",
    "Somewhat",
    "Barely",
    "Slightly",
    "Not much",
    "Not much",
    "Opposite of much",
    "Not much",
    "Slightly",
    "Not much",
    "Not much",
    "Slightly",
    "Not much",
    "Opposite of very",
    "Not much",
    "Not much",
    "Slightly",
    "Slightly",
    "Not much",
    "Slightly",
    "Not too much",
    "Somewhat",
    "Just slightly",
    "Not much",
    "Slightly",
    "Slightly",
    "Not much",
    "Just barely",
    "Slightly",
    "Not much",
    "Not much",
    "Slightly",
    "Not much",
    "Somewhat",
    "Somewhat",
    "Somewhat",
    "Not much",
    "Not much",
    "Just barely",
    "Not much",
    "Somewhat",
    "Not much",
    "Just slightly",
    "Not much",
    "Slightly",
    "Slightly",
    "Just slightly",
    "Slightly",
    "Slightly",
    "Slightly",
    "Just slightly",
    "Not very much",
    "Slightly",
    "Not much",
    "Not much",
    "Diminutive amount",
    "Not much",
    "Somewhat",
    "Slightly",
    "Some",
    "Slightly",
    "Minimally",
    "Not much",
    "Not much",
    "Not much",
    "Slightly",
    "Somewhat",
    "Not much",
    "Not much",
    "Hardly any",
    "Not much",
    "Tiny bit",
    "Not much",
    "Somewhat",
    "Not much",
    "Small amount"
  ],
  "pent": [
    "Locked (up)",
    "Bottled (up)",
    "Confined, with \"up\"",
    "Confined, with \"up\"",
    "Shut (up)",
    "Confined, with \"up\"",
    "Cooped (up)",
    "Locked (up)",
    "Cooped (up)",
    "Shut (up)",
    "Kept in",
    "Locked (up)",
    "Shut up",
    "Shut up",
    "Shut (up)",
    "Shut (up)",
    "Confined, with \"up\"",
    "Shut (up)",
    "Shut (up)",
    "Cooped (up)",
    "Abbr. on a certain elevator button",
    "Constrained, with \"up\"",
    "Locked (up)",
    "Locked (up)",
    "Caged",
    "Confined",
    "___-up (confined)",
    "Shut (up)",
    "Confined, with \"up\"",
    "Locked (up)",
    "Repressed, with \"up\"",
    "Cooped",
    "Confined, with \"up\"",
    "Shut (up)",
    "Shut (up)",
    "Bottled (up)",
    "Shut (up)",
    "Shut in",
    "Contained, with \"up\"",
    "Cooped (up)",
    "Shut (up)",
    "Cooped (up)",
    "Shut (up)",
    "Shut in",
    "Confined, with \"up\""
  ],
  "hops": [
    "Moves like a rabbit",
    "Challenges for infielders",
    "Kangaroo movements",
    "Brewing need",
    "Short trips",
    "Brewery supply",
    "Bunny movements",
    "Brewery supply",
    "Beer ingredients",
    "Rabbit moves",
    "Brewer's need",
    "Brewer's need",
    "Short flights",
    "Brewery need",
    "Brewers' needs",
    "Brewery stock",
    "Beer ingredient",
    "Brewer's base",
    "Commuter plane trips",
    "Bunny movements",
    "Brewer's need",
    "Brewery supplies"
  ],
  "hulu": [
    "Streaming service with \"The Handmaid's Tale\"",
    "Disney+ alternative",
    "Amazon Prime competitor",
    "Giant in streaming video",
    "Streaming video giant",
    "\"Anywhere, Anytime\" sloganeer",
    "Amazon Prime competitor",
    "Alternative to standard TV",
    "Online source for TV shows",
    "Streaming video giant"
  ],
  "molt": [
    "Shed",
    "Lose one's feathers",
    "Lose one's coat",
    "Shed, as feathers",
    "Shed old feathers",
    "Undergo ecdysis",
    "Come out of one's shell, maybe",
    "Shed",
    "Experience ecdysis",
    "Take off one's coat?",
    "Shed",
    "Undergo ecdysis",
    "Shed one's skin",
    "Shed",
    "What some birds do",
    "Shed",
    "Shed",
    "Snakes do it",
    "Shed",
    "Shed",
    "Drop down?",
    "Shed, as skin",
    "Shed",
    "Jump out of one's skin?",
    "Shed",
    "Shed"
  ],
  "from": [
    "\"Let's take it ___ the top\"",
    "Native to",
    "Email line",
    "Judging by",
    "Starting",
    "To's opposite",
    "Email heading",
    "Gift tag word",
    "Counterpart of \"to\"",
    "Preprinted gift tag word",
    "Partner of \"to\" on a gift tag",
    "Out of",
    "Gift tag word",
    "Native to",
    "With 37-Across, theme of this puzzle",
    "Word with \"hand to hand\" or \"time to time\"",
    "Gift tag word",
    "Native to",
    "Word on a gift tag",
    "Gift tag word",
    "Born in",
    "Gift tag word",
    "Memo word",
    "Gift line",
    "\"Pennies ___ Heaven\"",
    "___ A to Z",
    "Gift label word",
    "Fax cover-page word",
    "Gift tag word",
    "\"Greetings ___...\" (postcard opening)",
    "Word on a gift tag",
    "\"Greetings___...\"",
    "\"It Came ___ Outer Space\"",
    "___ scratch"
  ],
  "bree": [
    "One of the housewives on \"Desperate Housewives\"",
    "Village of the Prancing Pony inn in \"The Lord of the Rings\"",
    "\"Desperate Housewives\" role",
    "One of the \"Desperate Housewives\"",
    "One of the housewives on \"Desperate Housewives\"",
    "\"Desperate Housewives\" role",
    "One of the housewives on \"Desperate Housewives\"",
    "\"Desperate Housewives\" role",
    "\"Desperate Housewives\" role",
    "Jane's role in \"Klute\""
  ],
  "feel": [
    "Stay in touch?",
    "Touch",
    "Sense",
    "\"How do you ___?\"",
    "Be of the opinion",
    "\"Sure, go ahead\"",
    "Mood",
    "Have a hunch",
    "Sense",
    "Believe",
    "Bleed (for)",
    "Grope",
    "Have a hunch",
    "Grope",
    "Sense",
    "Sense",
    "Finger, perhaps",
    "Touch",
    "Have hands-on experience?",
    "Believe",
    "Experience",
    "Experience",
    "Perceive",
    "Grope",
    "\"I ___ Fine\" (Beatles hit)",
    "Have a hunch",
    "Have a hunch",
    "Have a hunch"
  ],
  "ugli": [
    "Citrus fruit that won't win any beauty contests?",
    "Wrinkled fruit",
    "Wrinkly fruit",
    "Wrinkly fruit",
    "\"Blemished\" fruit",
    "___ fruit (wrinkly citrus)",
    "Unattractive fruit",
    "Citrus hybrid",
    "Citrus named for its appearance",
    "Hybrid citrus",
    "Relative of a tangelo",
    "Wrinkly fruit",
    "Citrus hybrid",
    "Unattractive fruit",
    "Citrus hybrid whose name suggests its appearance",
    "Wrinkly citrus",
    "___ fruit (relative of a tangelo)",
    "Hybrid fruit",
    "Citrus fruit",
    "Fruit hybrid",
    "Not-so-cute fruit",
    "Hybrid citrus fruit",
    "Citrus hybrid",
    "Aptly named fruit",
    "Wrinkly fruit",
    "___ fruit",
    "Furrowed fruit",
    "Tropical citrus fruit",
    "Hybrid citrus fruit",
    "Aptly named fruit",
    "Aptly named hybrid",
    "___ fruit",
    "Wrinkly citrus",
    "Relative of a grapefruit",
    "Trademarked citrus",
    "___ fruit",
    "Fruit named for its appearance",
    "Aptly named tropical fruit",
    "Wrinkly fruit",
    "Wrinkly fruit",
    "Hybrid fruit",
    "Wrinkly fruit",
    "Hybrid fruit",
    "Wrinkly fruit",
    "Wrinkled fruit",
    "Wrinkly-skinned fruit",
    "Trademarked fruit name",
    "___ fruit",
    "Unattractive tropical fruit",
    "Tangelo trademark",
    "Misshapen citrus",
    "___ fruit (large tangelo)",
    "Fruit with wrinkled skin",
    "Aptly named citrus fruit",
    "___ fruit",
    "Wrinkly fruit",
    "___ fruit",
    "Appropriately named fruit",
    "Aptly named fruit",
    "Wrinkly fruit",
    "Unattractive fruit that sounds that way",
    "Good fruit with a bad name?",
    "Homely fruit",
    "Unattractive fruit",
    "Wrinkly-skinned fruit",
    "Wrinkly fruit",
    "Not a pretty fruit"
  ],
  "rune": [
    "Bit of ancient text",
    "Letter in a Viking inscription",
    "Bit of ancient script",
    "It may be set in stone",
    "Part of a Viking message",
    "Tolkien character",
    "It may be carved in stone",
    "Mystical character",
    "Viking character",
    "Enigmatic one in \"The Hobbit\"",
    "Letter in an Anglo-Saxon script",
    "Tolkien character",
    "Hard-to-read character",
    "It may be written in stone",
    "Character seen in \"The Hobbit\"",
    "Bit of Old Norse writing",
    "Kylver Stone letter",
    "Letter to Odin?",
    "Character in \"The Hobbit\"",
    "Dead letter?",
    "Bit of Viking writing",
    "Character in \"Beowulf\"",
    "Viking letter",
    "Anglo-Saxon writing symbol",
    "Mysterious character",
    "Anglo-Saxon writing symbol",
    "Letter in a mysterious inscription",
    "Old character",
    "Old character",
    "Old Germanic character",
    "Something written in stone",
    "Bit of writing on the wall?",
    "Mysterious writing",
    "Mysterious letter",
    "Something Eric the Red read",
    "Viking letter",
    "Viking character",
    "Ancient writing",
    "Ancient letter",
    "Old character",
    "Part of an old inscription",
    "Mysterious letter",
    "Mysterious character",
    "Old character",
    "Ancient symbol",
    "Part of an ancient inscription",
    "Old-fashioned letter",
    "Letter for Gandalf",
    "Mystical character",
    "Mysterious character",
    "Mystical saying",
    "Ancient inscription",
    "Ancient alphabetic character",
    "Letter for Cynewulf",
    "Archeological inscription",
    "Viking character",
    "Thorn, for one",
    "Ancient writing",
    "Magic incantation",
    "Ancient symbol",
    "Ancient letter"
  ],
  "sexy": [
    "Like a fox",
    "Erotic",
    "Like Sadie, in a Beatles song",
    "Hot",
    "Super-hot",
    "Risque, say",
    "Close to 10, say",
    "Alluring",
    "Erotic",
    "Fetching",
    "Hot",
    "Inspiring lust",
    "\"Ooh-la-la!\"",
    "Like Playboy models [24]",
    "Hot",
    "Like calendar firemen",
    "10-ish?",
    "What word precedes \"Eyes,\" \"Girl,\" \"Love\" and \"Mama\" in Top 40 song titles?",
    "Hot, like a hunk",
    "Turning heads, perhaps",
    "Very exciting",
    "Alluring",
    "Like a proverbial 10",
    "Hot",
    "Hot",
    "Alluring",
    "Like a hottie",
    "What Justin Timberlake's \"bringin' back,\" in a song",
    "*With 68-Across, lingerie model's asset",
    "Like a hottie",
    "Like the models in a swimsuit issue",
    "Risqu?Ä",
    "Like the Beatles' Sadie",
    "Hot",
    "Voluptuous",
    "Alluring",
    "Hot",
    "Like a Playmate of the Month",
    "Alluring",
    "Hot",
    "Very hot",
    "Like a Playboy Playmate",
    "Alluring",
    "Like Playboy models",
    "Alluring",
    "Hot",
    "Appealing",
    "Alluring",
    "Sultry",
    "Hot",
    "Like a pinup",
    "Alluring",
    "Like Marilyn Monroe",
    "Like Playboy models",
    "Likely to turn on?",
    "Hot",
    "Like a Playmate",
    "Voluptuous",
    "Hot",
    "Arousing",
    "Alluring",
    "Hot",
    "Erotic",
    "Alluring",
    "Hot",
    "Hot",
    "Erotic",
    "\"Da Ya Think I'm ___?\" (1979 hit)",
    "Hot"
  ],
  "file": [
    "Word ladder, part 3",
    "Cabinet selection?",
    "Computer menu heading",
    "Escape tool secreted in a cake, in cartoons",
    "\"___ and Forget\" (classic humor piece by James Thurber)",
    "Menu bar heading",
    "Take the edge off",
    "Cliched prison contraband item",
    "Blacksmith's tool",
    "Put in the archives",
    "Cabinet member?",
    "Do a taxing task?",
    "Manicurist's tool",
    "Manicurist's tool",
    "Document burial place",
    "Hard drive item",
    "Tab site",
    "Dossier",
    "Rasp",
    "It may be used on a nail",
    "Do some manicuring",
    "Something to keep tabs on",
    "Manicurist's tool",
    "F.B.I. info",
    "Computer record",
    "Tool for a prisoner's escape, maybe",
    "Smooth",
    "Put away, in a way",
    "Folder's locale",
    "Kind of cabinet",
    "Smooth"
  ],
  "tati": [
    "French filmmaker Jacques",
    "French comic actor Jacques",
    "Jacques of French comedy",
    "Slapstick actor Jacques",
    "Jacques of film",
    "Jacques who played Monsieur Hulot",
    "\"Play Time\" director Jacques",
    "Star of \"Mr. Hulot's Holiday\"",
    "\"Mon Oncle\" star",
    "Comic actor Jacques",
    "Star of \"Mon Oncle\"",
    "Jacques of \"Mon Oncle\"",
    "\"Mr. Hulot's Holiday\" Oscar nominee",
    "\"Mon Oncle\" star",
    "\"Jour de Fete\" star, director and writer, 1949",
    "Comical Jacques",
    "Jacques who starred in \"Mon Oncle\"",
    "Director and star of the 1958 Best Foreign Language Film",
    "1955 Oscar nominee for \"Mr. Hulot's Holiday\"",
    "Comic actor Jacques",
    "Comical Jacques",
    "Mr. Hulot's player, in films",
    "Jacques of \"Mon Oncle\"",
    "Jacques of \"Mon Oncle\"",
    "Mr. Hulot's creator",
    "Jacques of \"Mon Oncle\"",
    "\"Mon Oncle\" star",
    "Jacques of \"Mon Oncle\"",
    "\"Mon Oncle\" star Jacques",
    "\"My Uncle\" star",
    "Comic Jacques",
    "Star of \"Mr. Hulot's Holiday\"",
    "\"Jour de Fete\" star",
    "\"Mon Oncle\" star",
    "Comic actor Jacques",
    "Filmmaker Jacques",
    "Pantomimist Jacques",
    "Jacques of French cinema",
    "M. Hulot's creator",
    "\"Jour de Fete\" star",
    "\"Play Time\" actor and director",
    "\"Jour de Fete\" director and star",
    "\"Mon Oncle\" director",
    "Comic Jacques",
    "\"Mon Oncle\" star",
    "Monsieur Hulot, in person",
    "\"Mon Oncle\" director",
    "Star of \"Mon Oncle\"",
    "Noted Chaplin follower",
    "Mr. Hulot's creator",
    "Mr. Hulot's portrayer"
  ],
  "fill": [
    "Word ladder, part 4",
    "Opposite of drain",
    "Truckload at a garbage dump",
    "Dirt in a dump truck, perhaps",
    "Occupy completely",
    "Permeate",
    "Complete, as a crossword grid",
    "___-in-the-blank",
    "Dirt in a dump truck, maybe",
    "\"___ 'er up!\"",
    "\"___ 'er up!\"",
    "Occupy",
    "Plug",
    "Stuff",
    "Hire for, as a position",
    "\"___ 'er up!\"",
    "Plug up",
    "All you can eat",
    "Occupy",
    "Leave no part empty",
    "Pervade",
    "Satiate",
    "Satisfy",
    "Gorge"
  ],
  "pedi": [
    "Treatment during \"sandal season,\" informally",
    "Mani-___ (salon combo)",
    "Nail job, for short",
    "Mani-___ (spa offering)",
    "Lead-in to cab",
    "Salon offering, familiarly",
    "Mani-___",
    "Spa treatment, informally",
    "Lead-in to cab",
    "Mani-___ (salon offering)",
    "Mani-___",
    "Half of a salon job",
    "Salon job, for short",
    "Treat for one's dogs?",
    "Mani-___ (nail job)",
    "Mani-___",
    "59-Down treatment, informally",
    "Foot: Prefix",
    "Foot part?",
    "Foot: Prefix",
    "Cab opener?",
    "Prefix with cab or cure",
    "Prefix with cab",
    "Prefix with cab",
    "Foot: Prefix",
    "Prefix with cure",
    "Foot: Prefix",
    "Prefix with form",
    "Foot: Prefix",
    "Prefix with cure"
  ],
  "heme": [
    "Blood pigment",
    "Deep red pigment",
    "Pigment in red blood cells",
    "Myoglobin component",
    "Blood pigment",
    "Blood pigment",
    "Blood pigment",
    "Blood pigment"
  ],
  "ests": [
    "Some quotes: Abbr.",
    "Rough amts.",
    "Imprecise stats: Abbr.",
    "Repair shop figs.",
    "Ballpark figs.",
    "Best guesses: Abbr.",
    "Guessed nos.",
    "Ballpark amts.",
    "Guesses: Abbr.",
    "Back-of-the-envelope figs.",
    "Proposal figs.",
    "They're probably close: Abbr.",
    "Educated guesses: Abbr.",
    "Shop quotes: Abbr.",
    "Round figs.",
    "Ballpark figs.",
    "Figs. that aren't final",
    "Quoted figs.",
    "Round nos.",
    "Ballpark figs.",
    "They're in the vicinity: Abbr.",
    "Repair shop figs.",
    "Rough figs.",
    "Forecasting figs.",
    "Best guesses: Abbr.",
    "They're in the neighborhood: Abbr.",
    "Job bidding figs.",
    "Figs. like \"a million or so\"",
    "Repair shop figs.",
    "Nos. for crowds",
    "Mechanics give them: Abbr.",
    "Contractors' figs.",
    "Guesses: Abbr.",
    "Ballpark figs.",
    "Ballpark amts.",
    "Some attendance figs.",
    "Ballpark figs.",
    "Guesses: Abbr.",
    "Guinness Book suffixes"
  ],
  "aorb": ["Choice between two options", "Simplest of choices"],
  "glop": [
    "Unlikely serving at a Michelin-starred restaurant",
    "What school lunch is often depicted as in cartoons",
    "Hardly Michelin-star fare",
    "Mushy mass",
    "Unappetizing food that might be served with a ladle",
    "Unappealing bowlful",
    "Zero-star fare",
    "Unappetizing food",
    "Ladleful of unappetizing food",
    "Icky stuff",
    "Far from haute cuisine",
    "Hardly haute cuisine",
    "Goo",
    "Unappetizing cafeteria serving",
    "Hardly five-star fare",
    "Mush",
    "Hardly haute cuisine",
    "Food served with a ladle",
    "Unappetizing fare",
    "Puddinglike serving"
  ],
  "peek": [
    "Sneaky look",
    "Something not to do before Christmas?",
    "Christmas Eve no-no",
    "Something you shouldn't do around Christmas",
    "Advance look, say",
    "Hide-and-seek no-no",
    "Sneak a look",
    "Naughty look, maybe",
    "Furtive look",
    "Sneak a look",
    "Not wait until December 25, say",
    "Look through half-closed blinds, e.g.",
    "Sneak preview",
    "With 2-Down, toddler's game",
    "What not to do before December 25?",
    "Act out of impatience, maybe",
    "Sneak ___",
    "Have a look-see",
    "Cheat, in a way",
    "Cheat, in a way",
    "Furtive look",
    "Break an Xmas rule",
    "Look surreptitiously",
    "Look-see",
    "Sneaky look"
  ],
  "lurk": [
    "Browse online without commenting, informally",
    "Stand in the shadows",
    "Stand in the shadows",
    "Browse without comment",
    "Lie in wait",
    "Read but not comment, in Internet lingo",
    "Read but never post",
    "Watch from the sidelines",
    "Watch from a hidden position",
    "Lie unobserved",
    "Hang around",
    "Read but not post, in a chat room",
    "Hang around unobserved",
    "Lie in wait",
    "Remain in the shadows"
  ],
  "hazy": [
    "Indistinct",
    "Blurred",
    "Like recollections of people trying to avoid perjury?",
    "Imprecise, as a memory",
    "Nebulous",
    "Vague",
    "Confused",
    "Like some memories or summer skies",
    "Having a bit of smog",
    "Vague, as a recollection",
    "Unclear",
    "Unclear",
    "Having a bit of smog",
    "One of the three H's in a summer weather forecast",
    "Hard to recollect",
    "Like some recollections"
  ],
  "maul": [
    "Damage badly",
    "Beat badly",
    "Thrash",
    "Injure badly",
    "Savage",
    "Mangle",
    "Badly rough up",
    "Manhandle horribly",
    "Rough up",
    "Tent erector's tool",
    "Rough up",
    "Hefty hammer",
    "Stake driver",
    "Deal with brutally",
    "Handle roughly",
    "Wedge driver",
    "Heavy hammer",
    "Beat badly",
    "Bruise",
    "Stake-driving hammer",
    "Badly claw",
    "Stake driver",
    "Trounce",
    "Handle without care",
    "Rough up",
    "Mangle",
    "Manhandle",
    "Manhandle"
  ],
  "ohmy": [
    "\"Goodness me!\"",
    "\"Goodness!\"",
    "\"Good heavens!\"",
    "\"Goodness sakes!\"",
    "\"Well, isn't that something!\"",
    "\"Goodness gracious!\"",
    "Exclamation sometimes said with a hand over the mouth",
    "Cry of surprise",
    "\"Good heavens!\"",
    "\"I wasn't expecting that!\"",
    "\"This is bad\"",
    "\"Jeepers!\"",
    "\"I wasn't expecting that!\"",
    "\"Good golly!\"",
    "\"Goodness gracious!\"",
    "\"Heavens!\"",
    "\"Dear God!\"",
    "\"Horrors!\"",
    "Cry of surprise",
    "\"Good heavens!\"",
    "\"Egad!\"",
    "\"Goodness!\"",
    "\"Heavens!\"",
    "Words of surprise",
    "\"For goodness sake!\"",
    "\"Whatever will I do?\"",
    "\"Horrors!\"",
    "\"Goodness gracious!\""
  ],
  "tock": [
    "Tick-___",
    "Half-a-second sound",
    "Clock sound",
    "Clock sound",
    "Tick-___",
    "Clock sound",
    "Clock sound",
    "Clock sound",
    "It's heard around the clock",
    "Clock sound"
  ],
  "wire": [
    "Something an informant might wear",
    "Barbed-___ fence",
    "Computer cable",
    "Kind of transfer",
    "Hide a mike on",
    "Police informant's wear",
    "Electronic money transfer",
    "Listening device on a narc",
    "Listening device",
    "Concealed mike",
    "Sculpture medium for Calder",
    "Send, in a way",
    "Something a police informant might wear",
    "Current carrier",
    "Send, in a way",
    "Finish line marker",
    "Something an undercover agent might wear",
    "Electrician's hookup",
    "Bra insert",
    "Telegram",
    "Undercover device",
    "Booby trap component",
    "Finish line, maybe",
    "Telegram",
    "Put a traveling mike on",
    "News source",
    "Conductor",
    "Telegram",
    "Hidden mike",
    "Telegraph",
    "Send via Western Union",
    "A.P. or Reuters, informally",
    "End of a footrace",
    "25-Across's perch, perhaps",
    "Hide a mike on",
    "Certain money transfer",
    "Race's end",
    "Telegram",
    "Tapping target",
    "Hook up electronically",
    "Kind of service",
    "Current line",
    "Kind of service",
    "Fence material",
    "Send urgently",
    "Urgent message",
    "Finish line",
    "Use Western Union",
    "Telegram",
    "Electricity carrier",
    "Down to the ___",
    "Telegraph"
  ],
  "tora": [
    "___ Bora, area of Afghanistan",
    "Said three times, a war film about Pearl Harbor",
    "Part of a battle cry",
    "When tripled, 1970 film about the attack on Pearl Harbor",
    "Repeated battle cry",
    "___ Bora (former Taliban stronghold)",
    "When said three times, 1970 film on the Pearl Harbor attack",
    "When tripled, a 1970 war movie",
    "When said three times, a W.W. II cry",
    "When tripled, a W.W. II movie",
    "When said three times, a W.W. II cry",
    "Pearl Harbor code word",
    "Japanese code word meaning \"tiger\"",
    "When tripled, a W.W. II movie",
    "___ Bora, wild part of Afghanistan",
    "When tripled, a 1970 war film",
    "When said three times, a film about Pearl Harbor",
    "When said three times, a 1970 film",
    "When said three times, a W.W. II movie",
    "When said three times, a W.W. II film",
    "When tripled, a war movie",
    "___ Bora caves",
    "When said three times, a W.W. II film",
    "Part of a battle cry",
    "Part of a Japanese war cry",
    "___ Bora (Afghan region)",
    "When tripled, a W.W. II movie",
    "___ Bora (Afghan region)",
    "When said three times, a 1970 film",
    "When said three times, a 1970 war film",
    "When tripled, a war film",
    "When tripled, an old war cry",
    "When said three times, a W.W. II movie",
    "When said three times, a 1970 war movie",
    "Part of a W.W. II exclamation",
    "Part of a Japanese pilot's war cry",
    "Pentateuch: Var.",
    "When twice repeated, a 1970 war movie"
  ],
  "ship": [
    "FedEx, maybe",
    "Liner, e.g.",
    "Send off, as an online order",
    "The Flying Dutchman, e.g.",
    "Mail",
    "Overnight, maybe",
    "Mother ___",
    "Send off",
    "The Titanic, e.g.",
    "Tanker or cutter",
    "Figurehead's place",
    "Send by FedEx or UPS",
    "Frigate or freighter",
    "FedEx, say",
    "Send via DHL, say",
    "Send",
    "One of the S's in U.S.S.",
    "It may go in a lock",
    "She, at sea",
    "Use FedEx, say",
    "Freighter or whaler",
    "Air-freight, e.g.",
    "FedEx, say",
    "Pack and send",
    "Send",
    "Nina, Pinta or Santa Maria",
    "Craft",
    "Transport",
    "U.P.S., say",
    "Half Moon, e.g.",
    "Argo, e.g.",
    "Junk, e.g.",
    "Place for hands",
    "FedEx, say",
    "Vessel",
    "Send",
    "The Constitution, e.g.",
    "Deliver",
    "Send",
    "Side-wheeler, for one",
    "Windjammer",
    "Send",
    "Armada member",
    "Send",
    "She at sea",
    "Lollipop was a \"good\" one"
  ],
  "veda": [
    "Sanskrit scripture",
    "Hindu sacred writing",
    "Ancient Hindu scripture",
    "Hindu scripture",
    "Sacred Hindu text",
    "Hindu scripture",
    "Rig-___, Hindu sacred book",
    "Sacred Hindu book",
    "Hindu sacred text",
    "Sacred Hindu text"
  ],
  "tach": [
    "Dash gauge",
    "Dash dial",
    "Dash gauge",
    "Instrument with a needle, for short",
    "Auto dial",
    "R.p.m. indicator",
    "Dash part",
    "Instrument with a needle, for short",
    "Part of a dashboard, for short",
    "Dash gauge",
    "Dash dial",
    "Dashboard gauge, for short",
    "Gauge showing r.p.m.'s",
    "Dashboard device, briefly",
    "Needle holder",
    "Dash part",
    "Dash part",
    "Dash feature",
    "Part of a dash",
    "Dash part",
    "Indicator of r.p.m.'s",
    "Dashboard dial, for short",
    "Dash gauge",
    "Part of a dash",
    "Dash gauge",
    "R.p.m. indicator",
    "Dial on the dash",
    "R.p.m. indicator",
    "Dashboard item, for short",
    "Dash gauge",
    "Dash gauge",
    "Dashboard dial, for short"
  ],
  "rang": [
    "\"You ___?\"",
    "Pealed",
    "Called up",
    "Tolled, as bells",
    "Used a doorbell",
    "\"You ___?\" (butler's response)",
    "Buzzed",
    "Pealed",
    "Requested service",
    "Tolled",
    "Summoned, as a butler",
    "\"You ___?\"",
    "Telephoned",
    "Called",
    "\"You ___?\" (butler's line)",
    "Tolled, as a bell",
    "Telephoned",
    "Phoned",
    "Telephoned",
    "Seemed to be",
    "Was like a bell",
    "\"You ___?\"",
    "Phoned",
    "Telephoned",
    "\"You ___?\"",
    "Summoned, in a way",
    "Tolled",
    "Called",
    "\"You ___?\"",
    "Sounded a bell",
    "Sounded, as a phone",
    "Buzzed",
    "Called",
    "Phoned",
    "Buzzed",
    "Summoned Jeeves",
    "Went off, as a bell",
    "Gave a buzz",
    "Called",
    "Summoned",
    "Summoned help, maybe",
    "Heralded",
    "Tried to contact",
    "Telephoned",
    "\"You ___?\"",
    "Phoned",
    "Tried to get hold of",
    "Gave a buzz",
    "Telephoned",
    "Went off, in a way",
    "Registered, with \"up\"",
    "Called up",
    "\"You ___?\" (butler's question)",
    "Ushered (in)",
    "\"You ___?\"",
    "Called the butler",
    "Summoned",
    "Phoned",
    "Reverberated",
    "\"You ___?\"",
    "Called",
    "Summoned, as a servant",
    "Called up",
    "Summoned, in a way",
    "Pealed"
  ],
  "ouis": [
    "Non-nons?",
    "Legislateurs' votes",
    "French acceptances",
    "French assents",
    "Some French votes",
    "Alsace assents",
    "French yeses",
    "They might precede \"Monsieur!\"",
    "Arles affirmatives",
    "Some votes in Quebec",
    "Alsace assents",
    "Votes overseas",
    "French yeses",
    "Foreign approvals",
    "Legislature votes",
    "French accords?"
  ],
  "byes": [
    "Rewards for regular-season winners, maybe",
    "Weeks off",
    "Quick farewells",
    "What seeds often have",
    "Ta-tas",
    "Seeds often have them",
    "Tournament passes",
    "What top seeds often get at the starts of tournaments",
    "Tournament passes",
    "Tournament passes",
    "Farewells",
    "They're exchanged at parting",
    "Some tourney draws",
    "Tournament passes",
    "Seeds often get them",
    "Tournament passes",
    "Tournament passes",
    "Tournament passes",
    "Ciao, adios, etc.",
    "Toodle-oos",
    "Playoff breathers"
  ],
  "jowl": [
    "Prominent part of a bloodhound",
    "Prominent Nixon feature",
    "Bloodhound feature",
    "It hangs under the chin",
    "Part of the head",
    "It may get a nip on \"Nip/Tuck\"",
    "Cheek",
    "Cheek",
    "Fleshy part",
    "It may hang by the neck",
    "It may hang by the neck",
    "Cheek by ___ (close)",
    "Lower jaw",
    "Cheek's neighbor"
  ],
  "luck": [
    "Unfortunate thing to be out of",
    "\"Preparation meeting opportunity,\" it's said",
    "Word that can follow the starts of 20-, 26-, 43- and 52-Across",
    "See 28-Down",
    "Gambler's need",
    "See 10-Down",
    "Game factor",
    "Way things fall",
    "Gambler's need"
  ],
  "bose": [
    "Big producer of speakers",
    "Big name in speakers",
    "Well-known speaker",
    "Big name in audio systems",
    "Big name in headphones",
    "Big name in audio equipment",
    "Big name in stereo speakers",
    "Big name in audio equipment",
    "Big name in audio equipment",
    "Big name in audio speakers",
    "Audio equipment giant",
    "Speaker of the house, perhaps",
    "Giant in audio equipment",
    "Big name in speakers",
    "Big name in audio equipment",
    "Big maker of audio equipment",
    "Speaker's name?"
  ],
  "ilia": [
    "Pelvic bones",
    "Pelvic bones",
    "Largest pelvic bones",
    "Largest pelvic bones",
    "Pelvic bones",
    "Heroine princess of Mozart's \"Idomeneo\"",
    "Pelvic parts",
    "Olympic skating champ Kulik",
    "\"Idomeneo\" heroine",
    "Hip parts",
    "Hip bones",
    "Hip bones",
    "Mother of Romulus and Remus",
    "Pelvic bones",
    "Russian figure skater Kulik",
    "1998 Olympic figure skating gold medalist ___ Kulik",
    "Pelvic bones",
    "Princess in Mozart's \"Idomeneo\"",
    "Hip bones",
    "Pelvic bones",
    "Pelvic bones",
    "Hip parts",
    "Hip bones",
    "Hip bones",
    "Skater Kulik",
    "Pelvic bones",
    "Pelvic bones",
    "Russian gold medalist ___ Kulik",
    "Skating star Kulik",
    "Pelvic bones",
    "Hipbones",
    "Winter Olympics gold medalist Kulik",
    "Pelvic parts",
    "Pelvic bones",
    "Pelvic bones",
    "Pelvic bones",
    "Hip parts",
    "Pelvic parts",
    "Pelvic bones",
    "Hip bones",
    "Pelvic parts",
    "Hipbones",
    "Hip bones",
    "Pelvic bones",
    "Hipbones",
    "Trojan princess of a Mozart opera",
    "Pelvic bones",
    "Pelvis parts",
    "Pelvic bones",
    "Pelvis parts",
    "They adjoin the ischia",
    "What hiphuggers hug",
    "Pelvic bones",
    "Pelvic bones",
    "Parts of pelvises"
  ],
  "ogee": [
    "Curved molding",
    "Molding shape",
    "Double-curved molding",
    "Curved molding",
    "Curved molding",
    "Molding shape",
    "Kind of arch",
    "Arch type",
    "Kind of arch",
    "Kind of arch",
    "S-shaped line",
    "Arch type",
    "Curve in a crown molding",
    "S-shaped molding",
    "Curved molding",
    "Double curve",
    "Double curve",
    "Curved molding",
    "S-shaped molding",
    "S-shaped molding",
    "Arch type",
    "Curved molding",
    "Arch type",
    "Kind of arch",
    "S-curve",
    "Sigmoid architectural feature",
    "S-shaped molding",
    "Double curve",
    "Curved molding",
    "S-curve",
    "Arch type",
    "Curved molding",
    "Decorative molding",
    "S-shaped molding",
    "S-shaped molding",
    "Architectural molding",
    "Curved molding",
    "Curved molding",
    "Decorative molding",
    "S-shaped molding",
    "S-curve",
    "Pointed arch variety",
    "S-shaped molding",
    "S-shaped curve",
    "Arch with a point",
    "Kind of arch",
    "Curved arch",
    "Curved molding",
    "S-shaped molding",
    "Arch type",
    "S-shaped molding",
    "Molding shape",
    "Double curve",
    "Pointed arch",
    "Molding with a curved profile",
    "Arch type",
    "Kind of arch",
    "S shape",
    "Kind of arch",
    "S-shaped curve",
    "S-shaped molding",
    "S-curve",
    "Double curve",
    "S-curve",
    "S-shaped molding",
    "Double curve",
    "Distinctive profile",
    "Ess molding",
    "Kind of arch",
    "S-curve",
    "Double-curved molding",
    "Molding type",
    "___ arch",
    "Double curve",
    "Kind of arch",
    "Curved molding",
    "Kind of arch",
    "Curved molding",
    "Architect's curve",
    "___ arch",
    "Curved molding",
    "S-shaped curve",
    "Kind of arch",
    "Double-curved molding",
    "Double curve",
    "Kind of arch",
    "Double curve",
    "S-shaped molding",
    "Kind of arch",
    "Convex/concave molding",
    "S-shaped curve",
    "Arch molding",
    "Double-curved decoration"
  ],
  "hemp": [
    "Source of canvas and cannabis",
    "Plant supplying burlap fiber",
    "Cannabis variety used for rope",
    "Burlap fiber",
    "Biodiesel fuel source",
    "Eco-chic clothing option",
    "Marijuana plant",
    "Fiber-yielding plant",
    "Sackcloth material",
    "Rope material",
    "Rope material",
    "Rope material",
    "Rope material",
    "Rope material",
    "Crop grown by George Washington",
    "Rope material",
    "Source of hashish",
    "Rope fiber",
    "Rope fiber",
    "Drug source",
    "Burlap material",
    "Marijuana source",
    "Rope material",
    "Rope material",
    "Sailcloth fiber",
    "Natural narcotic",
    "Rope material",
    "Illegal crop",
    "Rope fiber",
    "Plant for burlap",
    "Cannabis",
    "Rope fiber",
    "Rope material",
    "Cord fiber",
    "Kentucky product",
    "Fiber source",
    "Cannabis",
    "Cord fiber",
    "Hashish source",
    "Rope material"
  ],
  "aper": [
    "Mimic",
    "Impressionist",
    "Mimic",
    "Impressionist",
    "Copier",
    "Imitator",
    "Parodist, e.g.",
    "Mimic",
    "Impressionist",
    "One doing impressions",
    "One who makes an impression?",
    "Mimic",
    "Mimicry pro",
    "Parrot",
    "Copycat",
    "Copycat",
    "One who might have an original imitation",
    "Copycat",
    "Impressionist",
    "One making a good impression?",
    "Little or Short",
    "Imitator",
    "Mimicry expert",
    "Copycat",
    "Simon Says player",
    "Copier",
    "One who makes a good first impression?",
    "Imitator",
    "Mimic",
    "Copycat",
    "Little, e.g.",
    "Copycat",
    "Wannabe, often",
    "Monkey",
    "Impressionist",
    "Mimic",
    "Mimicker",
    "Copycat",
    "Impressionist",
    "Copycat",
    "Mimic",
    "Impressionist",
    "Mimicker",
    "Impressionist",
    "Copycat",
    "Takeoff artist",
    "Mimic",
    "Little, e.g.",
    "Mimic",
    "Takeoff artist",
    "Mimic",
    "Mimic",
    "Copycat",
    "Mimic",
    "Parrot",
    "Monkey",
    "Mimic",
    "Impressionist",
    "Impersonator",
    "Copycat",
    "Mimic",
    "Impressionist",
    "Mimic",
    "Mimic",
    "Copycat",
    "Takeoff artist",
    "Little or Short",
    "Impersonator",
    "Parrot",
    "Impressionist",
    "Copier",
    "Impressionist",
    "Copycat",
    "Rich Little, e.g.",
    "Copycat",
    "Parodist",
    "Mimic",
    "Little, e.g.",
    "Mimic",
    "Impersonator",
    "Mimic",
    "Rich Little, e.g.",
    "Mimic"
  ],
  "lyft": ["Uber alternative", "Company originally called Zimride"],
  "sctv": [
    "1970s-'80s sketch comedy show",
    "Canadian sketch comedy show of the 1970s-'80s",
    "Show on which John Candy and Eugene Levy got their starts",
    "It featured the parody soap \"The Days of the Week\"",
    "Canadian comedy show of the 1970s-'80s",
    "Onetime \"S.N.L.\"-type show",
    "John Candy's old comedy program",
    "1970s-'80s sketch comedy show",
    "Candy used to be seen on it",
    "Onetime show for John Candy",
    "Onetime \"S.N.L.\"-type show",
    "Old sketch comedy show",
    "John Candy's old comedy show",
    "Comedy show that once featured John Candy and Martin Short",
    "Where 46-Down got his start",
    "\"___ Network\" (1980's comedy series)",
    "Initials in 70's-80's comedy",
    "Where John Candy made his debut",
    "Noted initials in 70's-80's comedy"
  ],
  "vava": ["\"___-voom!\"", "\"___-voom!\"", "\"___-voom!\"", "\"___-voom!\""],
  "node": [
    "Connecting point",
    "Lymph ___",
    "Hub",
    "Connection point",
    "Part of a network",
    "Bump on a log, literally",
    "Point on a flowchart",
    "Branching point",
    "Point where lines meet",
    "Lymph ___",
    "Terminal in a computer network",
    "Meeting place",
    "Anatomical mass",
    "Lymph ___",
    "Network connection",
    "Network point",
    "Hub",
    "Anatomical knot",
    "Convergent point",
    "Branching-out point",
    "Either end of an edge, in graph theory",
    "Intersection point",
    "Branching point",
    "Bump on a branch",
    "Origination point",
    "Knotty swelling",
    "Junction point",
    "Central point",
    "Focal point",
    "Knot",
    "Connecting point",
    "Connecting point",
    "Orbital point",
    "Lymph bump",
    "Meeting point",
    "Intersection",
    "Connecting point",
    "Knot",
    "Knot",
    "Bump on a branch",
    "Abnormal enlargement",
    "Knot",
    "Protuberance",
    "Junction point",
    "Central point",
    "Orbital point",
    "Point of intersection",
    "Joint point",
    "Connecting point",
    "Network terminal",
    "Junction point",
    "Lymph bump",
    "Botanical joint",
    "Junction",
    "Protuberance",
    "Lymph ___",
    "Protuberance",
    "Focal point",
    "Stem joint",
    "Lymphatic part",
    "Knob",
    "Central point",
    "Bump",
    "Knob",
    "Lump",
    "Junction point",
    "Knob",
    "Lymph ___",
    "Leaf's starting point"
  ],
  "skit": [
    "\"S.N.L.\" bit",
    "Typical \"Key & Peele\" segment",
    "Improv offering",
    "Playlet",
    "Bit of burlesque",
    "\"Saturday Night Live\" sketch",
    "Bit of improv",
    "\"S.N.L.\" bit",
    "Variety show segment",
    "\"S.N.L.\" bit",
    "Variety show offering",
    "\"S.N.L.\" piece",
    "\"Saturday Night Live\" segment",
    "Sketch",
    "Short comic sketch",
    "Theater playlet",
    "Playlet",
    "Burlesque bit",
    "\"The Tonight Show\" piece",
    "Burlesque bit",
    "Child's play, perhaps",
    "Improv bit",
    "Revue segment",
    "\"Saturday Night Live\" bit",
    "\"Monty Python\" segment",
    "Funny number",
    "Camp performance",
    "\"Laugh-In\" segment",
    "Bit of comedy",
    "Funny business",
    "Sketch",
    "Playlet",
    "\"S.N.L.\" bit",
    "\"Laugh-In\" segment",
    "\"Saturday Night Live\" staple",
    "Burlesque bit",
    "Child's play, perhaps",
    "Playlet",
    "Little play",
    "Comical playlet",
    "\"S.N.L.\" offering",
    "Comical piece",
    "Vaudeville bit",
    "Stage presentation",
    "Playlet",
    "Blackout",
    "\"In Living Color\" segment",
    "Burlesque bit",
    "It's a put-on",
    "A bit of vaudeville",
    "Revue segment",
    "Revue segment",
    "Stiller and Meara routine",
    "Comical playlet",
    "Revue piece",
    "Miniplay",
    "Theatrical bit",
    "Blackout",
    "Revue segment",
    "Comic bit"
  ],
  "gone": [
    "Disappeared",
    "Vanished",
    "Plastered",
    "Extinct",
    "Off the shelves",
    "No longer to be found",
    "Outta here",
    "Spent",
    "Ruined",
    "Done for",
    "Out of town",
    "\"Going, going, ___!\"",
    "Disappeared",
    "All used up",
    "Here today, ___ tomorrow",
    "Auctioneer's last word",
    "Exhausted",
    "Disappeared",
    "Call after a hammer is hit",
    "No longer around",
    "\"Outta here\"",
    "Sold, to an auctioneer",
    "Auctioneer's cry",
    "AWOL",
    "Missing",
    "Over the stadium wall",
    "Auctioneer's \"Sold!\"",
    "Completely unconscious",
    "Sold out",
    "Out of the ballpark",
    "No more",
    "Used up",
    "Auctioneer's last word",
    "Used up",
    "Eaten up",
    "Left",
    "Last word before the gavel hits",
    "Vanished",
    "Out of here",
    "Missing",
    "Out of sight",
    "Out",
    "Depleted",
    "Hopeless",
    "Missing",
    "Missing",
    "Missing",
    "Used up"
  ],
  "bask": [
    "Sunbathe",
    "Luxuriate",
    "Enjoy the sun",
    "Laze in the rays",
    "Soak up the sun",
    "Lie in the sun",
    "Sit back and enjoy",
    "Luxuriate",
    "Soak up the sun",
    "Take pleasure, as in one's glory",
    "Enjoy the moment",
    "Soak up some rays",
    "Take in some sun",
    "Enjoy a pleasant situation",
    "Soak up rays",
    "Lounge in the sun",
    "Revel, in a way",
    "Enjoy the sun",
    "Soak up some sun",
    "Sit in the sun",
    "Luxuriate, as in the sun",
    "Luxuriate",
    "Lie poolside"
  ],
  "camp": [
    "Have an in-tents experience?",
    "Something to pitch or break",
    "Word after base or space",
    "Notoriously hard-to-define aesthetic style",
    "Pitch a tent",
    "Wait in a strategic location, in video game lingo",
    "Group of tents in the woods",
    "Setting for \"Friday the 13th\"",
    "Counselor employer",
    "Overly theatrical, maybe",
    "Group of like-minded thinkers",
    "___ David (presidential retreat)",
    "Summer getaway",
    "Like \"The Rocky Horror Picture Show\"",
    "\"Friday the 13th\" setting",
    "Not serious, in a way",
    "Place to eat a 38-Across",
    "Summer getaway",
    "Sleepaway, e.g.",
    "Sleepaway, e.g.",
    "Rough it, say",
    "*Sleepaway, e.g.",
    "Kid's summer getaway",
    "___ David",
    "Kitschy",
    "Pitch tents for the night",
    "See 18-Down",
    "Where soldiers stay overnight",
    "Cot site",
    "Kid's getaway",
    "Sleepaway, e.g.",
    "Political group",
    "Some amateur comedy",
    "Summer place",
    "Place for a fire",
    "Summer getaway",
    "Extravagantly theatrical",
    "___ David",
    "Pitch a tent",
    "Summer getaway, perhaps",
    "Archly theatrical",
    "In-tents experience?",
    "Some skit humor",
    "Bivouac",
    "Woody home",
    "Rustic stopover",
    "Wilderness home",
    "Word with boot or summer",
    "TV's \"Batman,\" e.g.",
    "Summer getaway",
    "Rough it",
    "Child's getaway"
  ],
  "nils": [
    "___ Lofgren, guitarist for the E Street Band",
    "Rocker Lofgren",
    "Guitarist Lofgren of the E Street Band",
    "Zeros, in soccer",
    "Rocker Lofgren",
    "Selma Lagerlof's \"The Wonderful Adventures of ___\"",
    "Rocker Lofgren",
    "Rock's Lofgren",
    "Guitarist Lofgren",
    "They mean nothing",
    "Guitarist Lofgren",
    "1912 Physics Nobelist ___ Dalen",
    "___ Asther, suave, Swedish-born actor in old films",
    "Guitarist Lofgren",
    "Guitarist Lofgren",
    "Rock guitarist Lofgren",
    "Guitarist Lofgren",
    "Guitarist Lofgren",
    "Lagerlof's \"The Wonderful Adventures of ___\"",
    "Guitarist Lofgren",
    "Lagerlof's \"The Wonderful Adventures of ___\"",
    "Pop musician Lofgren",
    "Selma Lagerlof hero",
    "Guitarist Lofgren",
    "Guitarist Lofgren"
  ],
  "sert": [
    "Painter Jose Maria ___",
    "Waldorf Astoria muralist",
    "Spanish muralist Jose Maria ___",
    "Muralist Jose Maria ___",
    "Rockefeller Center muralist",
    "Spanish muralist Jose Maria ___",
    "\"American Progress\" muralist",
    "Spanish muralist",
    "Fundacio Joan Miro designer",
    "Waldorf-Astoria muralist",
    "Spanish muralist",
    "Rockefeller Center muralist",
    "Spanish muralist Jose Maria ___",
    "Spanish muralist",
    "Miro Museum architect Jose Luis ___",
    "Noted Spanish muralist",
    "Muralist Jose Maria ___",
    "Harvard Science Center architect Jose Luis ___",
    "Harvard Science Center architect",
    "GE Building muralist",
    "Muralist Jose Maria ___",
    "Architect of Spain?Æs Miro museum",
    "Spanish muralist",
    "Palais des Nations muralist",
    "Spanish muralist",
    "Muralist Jose Maria ___",
    "Muralist Jose Maria ___",
    "Rockefeller Center muralist",
    "Muralist Jose Maria ___",
    "Spanish muralist",
    "Rockefeller Center muralist",
    "Miro compatriot",
    "Spanish muralist",
    "Barcelona-born architect",
    "Architect Jose Luis___",
    "Spanish artist",
    "Spanish painter Jose",
    "Spanish painter Jose Maria",
    "Rockefeller Center muralist",
    "Muralist Jose",
    "Spanish muralist",
    "Spanish painter"
  ],
  "bene": [
    "\"Va ___\" (Italian \"All right\")",
    "It means well in Italy",
    "Positive response to \"Come va?\"",
    "Nota ___",
    "Part of N.B.",
    "Part of N.B.",
    "\"Molto ___!\" (positive reply in Italy)",
    "Well, in old Rome",
    "Nota ___",
    "Nota ___",
    "Forum fine?",
    "Italian fine?",
    "Nota ___",
    "Nota ___",
    "Well, in Rome",
    "Old Roman well?",
    "Italian \"well\"",
    "Italian well",
    "Prefix with factor",
    "Roman well",
    "Well around Trevi Fountain?",
    "\"Vogliatemi ___\" (aria from \"Madama Butterfly\")",
    "The \"B\" of N.B.",
    "The \"B\" of N.B.",
    "Old Roman well",
    "\"Good!\" in Genoa",
    "Roman well",
    "Nota ___",
    "Gaetano's \"good\"",
    "Cato's \"well\"",
    "___ vale (farewell)",
    "The \"B\" of N.B.",
    "\"Stand By Me\" singer ___ King",
    "\"Well done!\" in Italy",
    "Nota ___",
    "Caesar's well",
    "Nota ___",
    "Nota ___",
    "It meant well to Caesar",
    "Giovanni's \"good\"",
    "Response to \"Come va?\"",
    "Italian well",
    "Pop music's ___King",
    "___exeat (certificate phrase)",
    "___ facta (brave deeds): Lat.",
    "Nota ___",
    "The \"B\" in N.B.",
    "Nota ___",
    "Part of N.B."
  ],
  "ints": [
    "Picks, in football: Abbr.",
    "Some safety stats: Abbr.",
    "Certain gridiron stats: Abbr.",
    "QB mistakes: Abbr.",
    "Some turnovers: Abbr.",
    "Some QB turnovers: Abbr.",
    "Stats for a QB",
    "Some exciting plays: Abbr.",
    "QB stats",
    "QB boo-boos: Abbr.",
    "They're not good for QB's",
    "Gridiron stat.",
    "Dramatic football plays: Abbr.",
    "Football stats: Abbr.",
    "Results of some bad QB passes",
    "Football stats: Abbr.",
    "\"Hurray!\" and others: Abbr.",
    "Bank amts."
  ],
  "deen": [
    "Celebrity chef Paula",
    "Celebrity chef Paula",
    "Paula who wrote \"It Ain't All About the Cookin'\"",
    "Restaurateur Paula",
    "Paula of \"Paula's Home Cooking\"",
    "Celebrity cook Paula",
    "\"It Ain't All About the Cookin'\" memoirist",
    "Chef Paula",
    "Celebrity chef Paula known for Southern cuisine",
    "TV chef Paula"
  ],
  "iman": [
    "\"___ absolute mess\"",
    "\"___ Ordinary Man\" (\"My Fair Lady\" tune)",
    "\"___ Old Cowhand\" (Bing Crosby hit)",
    "Mononymous model",
    "One-named supermodel of the 1970s-'80s",
    "Mogadishu-born supermodel",
    "Model with the Global Chic fashion line",
    "Supermodel from Somalia",
    "Supermodel who married David Bowie",
    "Model married to David Bowie",
    "One-named supermodel",
    "One-named supermodel from Somalia",
    "One-named 1970s-'80s supermodel",
    "\"___ Old Cowhand\" (1936 Bing Crosby hit)",
    "\"___ Ordinary Man\" (\"My Fair Lady\" song)",
    "Model married to David Bowie",
    "Mogadishu-born supermodel",
    "Somalia-born supermodel",
    "One-named supermodel",
    "Somalian-born supermodel",
    "Classic supermodel",
    "Model/actress in \"No Way Out\"",
    "One-named supermodel",
    "Single-named supermodel",
    "One-named supermodel",
    "Mogadishu-born model",
    "One-named supermodel",
    "Single-named supermodel",
    "\"___ Ordinary Man\" (\"My Fair Lady\" song)",
    "1970's Vogue discovery",
    "Somalian model-turned-actress",
    "African model",
    "\"___ old cowhand...\"",
    "Single-named supermodel",
    "Noted actress-model",
    "One-named supermodel",
    "\"___ old cowhand...\"",
    "One-named supermodel",
    "Somali-born supermodel",
    "One-named model",
    "One-named model",
    "Noted fashion model",
    "\"___Old Cowhand\"",
    "\"___ Ordinary Man\" (\"My Fair Lady\" song)",
    "Model from Mogadishu",
    "\"___ Old Cowhand\"",
    "Model married to David Bowie",
    "Model who told of Somalia's plight",
    "David Bowie's model wife"
  ],
  "juts": [
    "Projects",
    "Sticks (out)",
    "Protrudes",
    "Projects, with \"out\"",
    "Projects",
    "What a cape does",
    "Sticks (out)",
    "Protrudes, with \"out\"",
    "Sticks (out)",
    "Extends (out)",
    "Extrudes",
    "Sticks out",
    "Sticks (out)",
    "Sticks (out)",
    "Protrudes"
  ],
  "rats": [
    "\"Shoot!\"",
    "\"Dagnabbit!\"",
    "Cry sometimes made with a snap of the fingers",
    "\"Shoot!\"",
    "Winston's greatest fear in \"1984\"",
    "\"Dang it!\"",
    "\"Darn!\"",
    "\"Phooey!\"",
    "\"Shoot!\"",
    "\"Phooey!\"",
    "Cats' catches",
    "\"Phooey!\"",
    "\"Goshdarnit!\"",
    "Charlie Brown expletive",
    "\"Darn it all!\"",
    "\"Confound it!\"",
    "\"Darn!\"",
    "\"Phooey!\"",
    "Charlie Brown lamentation",
    "\"Darn it all!\"",
    "\"Aw, man!\"",
    "\"Darn it!\"",
    "\"Darn it all!\"",
    "\"Darn!\"",
    "Unwanted subway sights",
    "\"Darn!\"",
    "Snitches (on)",
    "\"Phooey!\"",
    "\"Consarn it!\"",
    "Bad singers?",
    "Disappointed outburst",
    "\"I hate when that happens!\"",
    "\"Doggone it!\"",
    "\"Darn it!\"",
    "\"Dagnabbit!\"",
    "Squealers",
    "\"Gosh darn it!\"",
    "\"Shoot!\"",
    "\"Darn it all!\"",
    "\"Bummer!\"",
    "\"Curses!\"",
    "Winston's biggest fear in \"1984\"",
    "\"Darn!\"",
    "Cry often made while snapping the fingers",
    "\"I hate it when that happens!\"",
    "\"Peanuts\" expletive",
    "Experiment runners?",
    "\"Darn!\"",
    "\"What rotten luck!\"",
    "Laboratory maze runners",
    "\"Fiddlesticks!\"",
    "Mrs. Frisby's charges in \"The Secret of NIMH\"",
    "\"Darn it!\"",
    "Slum vermin",
    "Cry from Charlie Brown",
    "Candidates for witness protection programs",
    "Winston Smith's greatest fear, in \"1984\"",
    "\"Darn it all!\"",
    "Piper's followers",
    "Hamelin's problem",
    "Loser's cry",
    "\"I hate it when that happens!\"",
    "Two-timing types",
    "\"Darn!\"",
    "Mafiosi who \"flip\"",
    "\"Phooey!\"",
    "\"The Secret of NIMH\" figures",
    "Cry from Charlie Brown",
    "\"Darn it all!\"",
    "Refuse visitors",
    "\"Darn!\"",
    "Loser?Æs cry",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Doggoneit!\"",
    "\"Phooey!\"",
    "Dumpsite sights",
    "\"Phooey!\"",
    "Squealers",
    "\"Fiddlesticks!\"",
    "Vermin",
    "Stinkers",
    "Lab subjects",
    "\"Dang it all!\"",
    "Followers of the Pied Piper of Hamelin",
    "Some lab stock",
    "\"Of all the luck!\"",
    "\"Darn it!\"",
    "Refuse visitors?",
    "\"Doggone it\"",
    "\"Darn!\"",
    "Tellers",
    "\"Darn!\"",
    "Charlie Brown's exclamation",
    "Charlie Brown expletive",
    "So-and-sos",
    "Plague carriers",
    "\"Darn!\"",
    "Charlie Brown exclamation",
    "\"Phooey!\"",
    "Tellers?",
    "Refuse visitors",
    "Word of disappointment",
    "\"Phooey!\"",
    "Lab runners",
    "\"Fudge!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Darn!\"",
    "\"Nuts!\"",
    "\"Phooey!\"",
    "\"Darn!\"",
    "Comics expletive",
    "\"Peanuts\" expletive",
    "\"Darn!\"",
    "\"Phooey!\"",
    "Charlie Brown exclamation",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "Charlie Brown's \"Darn!\"",
    "Opposite of \"yippee!\"",
    "\"Phooeyl\""
  ],
  "xian": [
    "Easternmost point of the Silk Road",
    "Chinese city known for its terra-cotta warriors",
    "Capital of Shaanxi province",
    "Capital of Shaanxi province"
  ],
  "gods": [
    "Rama and Krishna, e.g.",
    "What polytheists believe in",
    "Olympians, e.g.",
    "Rejection of a monotheist",
    "Mount Olympus residents",
    "Eponyms of the week?",
    "Mercury and Saturn",
    "\"Ye ___!\"",
    "Dwellers on Mount Olympus",
    "Some depictions on a pyramid wall",
    "Pantheon members",
    "\"___ and Monsters\" (1998 film)",
    "Mercury and Mars",
    "Saturn and Mercury, for two",
    "Mount Olympus dwellers",
    "Mount Olympus dwellers",
    "Mercury and Mars",
    "Olympus residents",
    "Neptune and Pluto",
    "Mount Olympus dwellers",
    "Olympus dwellers",
    "Balder and Odin",
    "Neptune and Uranus, e.g.",
    "___country (natural rural area)",
    "\"The Twilight of the ___\""
  ],
  "myob": [
    "\"Butt out!,\" briefly",
    "\"Butt out!\"",
    "\"Stay out of this!\"",
    "\"Butt out,\" briefly",
    "\"Butt out!,\" briefly",
    "Rebuke to an eavesdropper, for short",
    "\"Butt out,\" briefly",
    "\"Butt out!,\" initially"
  ],
  "joke": [
    "\"What a ___!\"",
    "Ha-ha",
    "Complete embarrassment",
    "Ha-ha elicitor",
    "Ridiculously inadequate sort",
    "Laughing matter",
    "Rib-tickler",
    "One-liner, e.g.",
    "One might start \"Knock knock ...\"",
    "It's bad when nobody gets it",
    "Routine part",
    "Part of a routine",
    "A good one is cracked",
    "One may be running over time",
    "Toastmaster's offering",
    "Cause for winking",
    "It may start with someone entering a bar",
    "It's best when cracked",
    "Word that may follow the start of 20-, 25-, 44- or 50-Across",
    "Friars Club offering",
    "Wisecrack",
    "It may begin with a buildup",
    "Funny",
    "One-liner, e.g.",
    "Crack",
    "Speechmaker's opening",
    "One-liner"
  ],
  "mohs": [
    "Mineralogical eponym",
    "___ scale (rater of mineral hardness)",
    "Eponymous scale inventor",
    "___ scale",
    "___ scale (measure of hardness)",
    "Inventor of a mineral hardness scale",
    "___ scale",
    "Mineralogist for whom a scale is named",
    "Kind of scale of mineral hardness",
    "___ scale",
    "Hardness scale inventor",
    "Eponymous scale developer",
    "___ scale",
    "___ scale",
    "___ scale",
    "Mineralogist Friedrich",
    "Mineral hardness scale inventor",
    "Scale of mineral hardness",
    "Eponymous German mineralogist",
    "Kind of scale",
    "___ scale",
    "Kind of scale",
    "___ scale",
    "___ scale (hardness measure)"
  ],
  "call": [
    "\"Safe!\" or \"You're out!\"",
    "Get on the line",
    "Decision",
    "Not raise or fold",
    "Phone",
    "Dial on a telephone",
    "Summon",
    "\"Safe!,\" in baseball, or \"Safety!,\" in football",
    "Sixth word ...",
    "Give a ring",
    "Poker action",
    "Telephone",
    "Phone",
    "Telephone",
    "Ring up",
    "Telephone",
    "Ring up",
    "Decision",
    "Match a bet, in poker",
    "Do some telemarketing",
    "Give a ring",
    "\"Out!\" or \"Safe!\"",
    "___ letters",
    "Ring up",
    "Ring up",
    "\"Safe\" or \"out\"",
    "Message Waiting instruction",
    "Convoke",
    "\"Steeeerike!\", e.g.",
    "Telephone",
    "Lead a square dance",
    "\"Tails,\" e.g.",
    "Summon",
    "Decision",
    "Poker action",
    "Telephone",
    "Summon",
    "Cellular response?"
  ],
  "skys": [
    "\"The ___ the limit\"",
    "\"The ___ the limit\"",
    "\"The ___ in love with you\" (misheard lyric in a Herb Alpert hit)",
    "\"___ the Limit\" (Notorious B.I.G. hit)",
    "\"The ___ the limit!\"",
    "\"The ___ the limit\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit\"",
    "\"The ___ the limit\"",
    "\"___ the Limit\" (Temptations album)",
    "\"The ___ the limit!\"",
    "\"The ___ the limit\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit!\"",
    "\"The ___ the limit\""
  ],
  "pill": [
    "Sort who's hard to tolerate",
    "Person who's hard to take",
    "Bit of medicine",
    "Sweater ball",
    "Capsule alternative",
    "Disagreeable person",
    "Tiresome sort",
    "Hard-to-tolerate sort",
    "Hard-to-take person",
    "Item in a box with seven compartments, say",
    "Tab",
    "Tiresomely disagreeable sort",
    "It can be popped",
    "Dosage unit",
    "Difficult sort",
    "Wet blanket",
    "Grouch",
    "Bit of medicine",
    "Tablet",
    "Annoyer",
    "It's taken in for treatment",
    "Tiresome one",
    "Hardly the life of the party",
    "Tablet",
    "Unpleasant person",
    "Complainer",
    "Alternative to a caplet",
    "Medicine tablet",
    "Disagreeable person",
    "Medicinal tablet",
    "Tablet",
    "Tiresome one"
  ],
  "yeoh": [
    "Michelle of \"Star Trek: Discovery\"",
    "Michelle of \"Crazy Rich Asians\"",
    "Michelle of \"Crouching Tiger, Hidden Dragon\"",
    "Michelle of \"Crazy Rich Asians\"",
    "Actress Michelle of \"Crazy Rich Asians\"",
    "Michelle of \"Crouching Tiger, Hidden Dragon\"",
    "Michelle of \"Crouching Tiger, Hidden Dragon\""
  ],
  "vita": [
    "Life, in Rome",
    "\"La Dolce ___\"",
    "\"La Dolce ___\"",
    "\"Apologia pro ___ Sua\"",
    "Lucio's life",
    "\"La Dolce ___\"",
    "Short biography",
    "Ars longa, ___ brevis",
    "Ars longa, ___ brevis",
    "Life of Cicero",
    "Ars longa, ___ brevis",
    "Career summary"
  ],
  "sham": [
    "Pillow cover",
    "Fake",
    "Bogus",
    "Bogus",
    "Phony",
    "Bogus",
    "Bogus",
    "Imposture",
    "Fake",
    "Fake",
    "Fraud",
    "Decorative pillowcase",
    "Fraud",
    "Imitation",
    "Fake",
    "Bogus",
    "Faux",
    "Hoax",
    "Its appearance is deceiving",
    "Fake",
    "Phony",
    "Hoax",
    "Comforter go-with",
    "Hoax",
    "Bogus",
    "Ersatz",
    "Fast shuffle",
    "Phony",
    "Bedding item",
    "Pillow cover",
    "Fake",
    "Pretense",
    "Fake",
    "Counterfeit",
    "Deception",
    "Pillow cover",
    "Fraud",
    "Mockery",
    "Impostor",
    "Pillow cover",
    "Fraud",
    "Only make-believe",
    "Pretended",
    "Fraud",
    "Fake",
    "Fraud",
    "Pillow cover",
    "Not-to-be-believed",
    "Ersatz",
    "Fake",
    "Pillow covering",
    "Bogus",
    "Fakery",
    "Ersatz",
    "Hoax",
    "Not real",
    "Pillow cover",
    "Deception",
    "Make-believe",
    "Sam the ___ of 60's pop",
    "Pretended",
    "Farce",
    "Ersatz",
    "Counterfeit",
    "Ersatz",
    "Fraud",
    "Make-believe"
  ],
  "dual": [
    "Two-part",
    "Two-way",
    "Kind of citizenship",
    "Binary",
    "Lionize / Twin",
    "Twofold",
    "With 36-Down, suitable for two applications",
    "Twin",
    "___ personality (Dr. Jekyll and Mr. Hyde trait)",
    "Like many exhausts",
    "Having a couple of elements",
    "Word before income or exhaust",
    "Like some people's citizenships",
    "___ exhaust",
    "Designed for two",
    "Like some citizenship",
    "Like a Jekyll/Hyde personality",
    "Kind of income",
    "Janus-faced",
    "With 39-Across, kind of engine",
    "Twin",
    "Two-part",
    "Twofold",
    "Twofold",
    "Twin",
    "Twofold",
    "___ purpose",
    "Like some exhausts",
    "___-purpose",
    "In two parts",
    "Twofold",
    "Like some controls",
    "Like some citizenships",
    "Kind of income",
    "Like some track meets",
    "Kind of citizen",
    "Kind of exhaust",
    "Twofold",
    "Kind of exhaust",
    "Like a Jekyll-Hyde personality",
    "Twofold",
    "Word with citizen or purpose",
    "Twin",
    "Twofold"
  ],
  "sync": [
    "Align, informally",
    "Match up, as a phone with a computer",
    "Coordinate, informally",
    "Connect, as a smartphone to a computer",
    "Adjust to match, informally",
    "Bring into harmony",
    "Harmonious, after \"in\"",
    "Match up",
    "Match up",
    "Connect, as picture with sound",
    "Match up",
    "Align",
    "Coordinate, as audio and video",
    "Align",
    "Coordinate",
    "Align",
    "Match up",
    "In ___ with",
    "Match up, as iPod and laptop files",
    "In ___ (working harmoniously)",
    "___ in progress (iPhone phrase)",
    "Harmonize, informally",
    "Matched up, after \"in\"",
    "In ___ (harmonious)",
    "Harmony",
    "Harmony",
    "Short agreement?",
    "Align",
    "Lip-___",
    "Working well together, after \"in\"",
    "Coordination, briefly",
    "Out of ___ (not harmonizing)",
    "Harmony, briefly",
    "Harmony",
    "See 27-Down",
    "Harmony",
    "In ___ (together)",
    "Harmony",
    "In ___ (together)",
    "Match, informally",
    "Harmony",
    "Lip-___ (not really sing)",
    "In ___ (together)",
    "In ___ (coordinated)",
    "Harmony, briefly",
    "Harmony",
    "Coordination",
    "Together, after \"in\"",
    "Lip-___",
    "Harmony, informally",
    "Harmony",
    "In ___ (together)",
    "In ___ (harmonious)",
    "In ___ (harmonious)",
    "Lip- ___",
    "Harmonious relationship"
  ],
  "soya": [
    "Tofu bean",
    "Tofu source",
    "___ beans",
    "Source of protein in veggie burgers",
    "Miso bean",
    "Bean used to make tempeh",
    "Versatile bean",
    "Protein-rich bean",
    "___ bean",
    "Bean staple",
    "Versatile bean",
    "Kind of bean",
    "Edamame source",
    "Good protein source",
    "Protein-rich bean",
    "Kind of bean",
    "Bean type",
    "Protein-rich seed",
    "Kind of bean",
    "___ beans (miso ingredients)",
    "Bean from which sauce is made",
    "Kind of bean",
    "Nutritious bean",
    "Bean type",
    "Protein bean",
    "___ bean",
    "Versatile bean",
    "Source of sauce or milk",
    "Kind of bean",
    "Type of bean",
    "High-protein bean",
    "Kind of bean",
    "Protein source",
    "Multipurpose protein source"
  ],
  "mana": [
    "Magical resource in Magic: The Gathering",
    "Tom Clancy's \"Every ___ Tiger\"",
    "\"Every ___ king\"",
    "\"Give that ___ cigar!\"",
    "\"A ___ plan ...\""
  ],
  "atmo": [
    "Prefix with -sphere",
    "Prefix with sphere",
    "Prefix with sphere",
    "Vapor: Prefix",
    "Prefix with sphere",
    "Spherical beginning?",
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with sphere",
    "Air: Prefix",
    "Spherical opening?",
    "Prefix with meter or sphere",
    "Prefix with sphere",
    "Spheric opening?",
    "Sphere starter",
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with meter or sphere",
    "Sphere starter",
    "Prefix with sphere",
    "Sphere starter",
    "Prefix with sphere",
    "Sphere starter"
  ],
  "jape": [
    "Practical joke",
    "Mocking remark",
    "Wisecrack",
    "Say mockingly",
    "Practical joke",
    "Wisecrack",
    "Mock",
    "Kid",
    "Mocking remark",
    "Make fun of",
    "Mock",
    "Make fun of",
    "Joke",
    "Jest",
    "Make sport of",
    "Bit of mockery",
    "Jest",
    "Mock",
    "Make fun of",
    "Practical joke",
    "Quip",
    "Practical joke",
    "Trick",
    "Prank",
    "Rib"
  ],
  "orts": [
    "Table scraps",
    "Table scraps",
    "Table scraps",
    "Dinner scraps",
    "Crumbs",
    "Scraps",
    "Table scraps",
    "Table scraps",
    "Dinner scraps",
    "Dinner scraps",
    "Scraps",
    "Table scraps",
    "Dinner crumbs",
    "Items at one's disposal?",
    "Scraps",
    "Stuff for the disposal",
    "Scraps for Spike",
    "Table scraps",
    "Leftovers",
    "Pet food, maybe",
    "Dog bowl bits",
    "Crumbs",
    "Leavings",
    "Restaurant refuse",
    "Leftovers for Fido",
    "Scraps",
    "Crumbs",
    "Disposal items",
    "Scraps",
    "Table scraps",
    "Morsels",
    "Table scraps",
    "Fido's dinner, maybe",
    "Dinner scraps",
    "Bits for Fido",
    "Scraps",
    "Treat for Rover",
    "Scraps",
    "Food, for Fido",
    "Table scraps",
    "Dog bowl bits",
    "Scraps",
    "Waste of a meal",
    "Table scraps",
    "Items for the disposal",
    "Morsels",
    "The makings of a stew",
    "Table scraps",
    "Plate scrapings"
  ],
  "reps": [
    "Handles, as an account, in brief",
    "Parts of gym routines",
    "Individual curls, say",
    "Gym count",
    "Is an agent for, informally",
    "Exerciser's sets",
    "Gym rat's count",
    "Workout set",
    "Group of 435 pols",
    "Training tally",
    "Dems.' foes",
    "Count for crunches",
    "\"One ... two ... three ...,\" in a gym",
    "Hill people, for short",
    "Bench press count",
    "Sales force, informally",
    "Some elected officials, briefly",
    "Gym count",
    "Workout count",
    "Works as an agent (for)",
    "Sales force, informally",
    "Count in a weight room",
    "Bodybuilder's count",
    "Gym count",
    "Contract negotiators, for short",
    "House members, for short",
    "The \"20\" in \"Drop and give me 20!\"",
    "15-Across and allies: Abbr.",
    "Gym number",
    "Workout count",
    "There are 435 in Cong.",
    "Salespeople, informally",
    "Solicits orders (for)",
    "Parts of a gym set",
    "Gym count",
    "Curl units",
    "Ones with talent?",
    "Sales crew",
    "Counts in the gym",
    "Weight lifters' units",
    "They're counted in gyms",
    "Weightlifter's count, informally",
    "Salespeople, in brief",
    "Foes of Dems.",
    "Officials elected for two yrs.",
    "Iron pumper's count",
    "Sales team, informally",
    "Sales agents, briefly",
    "Dems.' foes",
    "Sells (for)",
    "Sales force, for short",
    "Solicits (for)",
    "Workout set",
    "Account execs",
    "Sales agents",
    "Weightlifters' lifts",
    "Gym set",
    "Gym set",
    "Lifters count them",
    "Lifting units",
    "Sales force",
    "Gym groupings",
    "Exercise room count",
    "Sales people",
    "Sales force",
    "Sales force, informally",
    "Sells (for)",
    "Sales force",
    "Bench-press iterations",
    "Workout number",
    "Salespeople, informally",
    "There are 435 in Cong.",
    "Handles for a client company",
    "Capitol Hill workers, for short",
    "Workout count",
    "Exercise units",
    "Iron pumper's routine",
    "Salesmen, briefly"
  ],
  "hadj": [
    "The last of the Pillars of Islam",
    "Pilgrimage to Mecca",
    "Pilgrimage to Mecca",
    "Islam's final pillar",
    "One of the Five Pillars of Islam",
    "Muslim pilgrimage",
    "The last of the Pillars of Islam",
    "One of the Five Pillars of Islam",
    "Trek ending in Mecca",
    "Pilgrimage near year's end",
    "Muslim's pilgrimage",
    "Pilgrimage to Mecca",
    "Muslim's pilgrimage",
    "Trek to Mecca",
    "Journey to Mecca",
    "Religious pilgrimage",
    "Mecca trek",
    "Muslim pilgrimage",
    "Muslim pilgrimage",
    "Pilgrimage to Mecca",
    "Pilgrimage to Mecca",
    "Muslim pilgrimage",
    "Pilgrimage to Mecca",
    "Muslim pilgrimage",
    "Muslim pilgrimage",
    "It ends in Mecca",
    "One of the Pillars of Islam",
    "Muslim journey",
    "One of the Pillars of Islam",
    "Pilgrimage to Mecca",
    "One of the Pillars of Islam",
    "Requirement of Islam",
    "Trip to Mecca",
    "Pilgrimage to Mecca",
    "Mideast pilgrimage",
    "Muslim journey",
    "Trip for a 21 Across",
    "Pilgrimage",
    "Muslim pilgrim",
    "Islamic pilgrimage"
  ],
  "sahl": [
    "Political satirist who said \"If you were the only person left on the planet, I would have to attack you. That's my job\"",
    "Comedian Mort",
    "Jokester Mort",
    "First-ever comedian to appear on the cover of Time (1960)",
    "Satirist who said \"If you were the only person left on the planet, I would have to attack you. That's my job\"",
    "Witty Mort",
    "Pioneering stand-up comedian",
    "Comedian Mort",
    "Comedic Mort",
    "\"The Next President\" comedian",
    "Comedian Mort",
    "Comic Mort",
    "Canadian-born comedian once featured on the cover of Time",
    "Mort who said \"My life needs editing\"",
    "Witty Mort",
    "Comic who said \"A conservative is someone who believes in reform. But not now\"",
    "Mordant Mort",
    "Comic with the 1955 album \"At Sunset\"",
    "Comic Mort",
    "Funny Mort",
    "\"The Future Lies Ahead\" comedian, 1958",
    "Satirist Mort",
    "Wry comic Mort",
    "Satirist who wrote jokes for J.F.K.",
    "Comedian Mort",
    "Joke writer for many Kennedy campaign speeches",
    "\"Look Forward in Anger\" comedian",
    "Comedian Mort",
    "Stand-up comic famous for carrying a rolled-up newspaper onstage",
    "Comic who declared \"I'm not a liberal, I'm a radical!\"",
    "Comedian who appeared on the cover of Time, 8/15/1960",
    "Montreal-born comic who wrote jokes for J.F.K.",
    "Comedian Mort",
    "Comic Mort",
    "Satirical Mort",
    "Comedian Mort",
    "Wit who recorded the classic 1960 comedy album \"At the Hungry I\"",
    "Comedian Mort",
    "Satirist Mort",
    "Satirist Mort",
    "Comic Mort",
    "Comic Mort",
    "Satirist Mort",
    "Comic Mort",
    "Satirist from Canada",
    "Mort from Montreal",
    "Mordant Mort",
    "\"Is there anyone here I haven't offended?\" comic",
    "Satirist Mort",
    "Wit Mort",
    "Political comic Mort",
    "Mordant Mort",
    "Satirist Mort",
    "Satirical Mort",
    "Comedian Mort",
    "Satirist Mort",
    "Iconoclastic comedian",
    "Comedian Mort",
    "Nightclub satirist",
    "Comedian Mort",
    "Comedian Mort",
    "Satirist Mort"
  ],
  "heft": [
    "Weight",
    "Weight",
    "Test by lifting",
    "Weight",
    "Weight",
    "Test the weight of",
    "Weight",
    "Significance",
    "Weight",
    "Bulk",
    "Weight",
    "Importance",
    "Weight",
    "Weight",
    "Weight",
    "Weight",
    "Pounds, say",
    "Bulk",
    "Weight",
    "Weight",
    "Weight urbane",
    "Avoirdupois"
  ],
  "surf": [
    "Get on board?",
    "It's a shore thing",
    "Beach pounder",
    "Catch a break?",
    "Do the wave?",
    "Roar producer",
    "Sound heard at a beach",
    "Explore, as the Internet",
    "Ride the waves on a board",
    "___ music (Beach Boys genre)",
    "Move from site to site?",
    "Roar producer",
    "Browse, as the Web",
    "Catch waves",
    "Hang ten, e.g.",
    "Hang ten",
    "Beach lapper",
    "Catch a wave",
    "Go around the Internet",
    "Roar producer",
    "Waves to a wahine",
    "Roar at the shore",
    "Hang ten or shoot the curl",
    "Explore, as the Internet",
    "Sound swell?",
    "Ride the waves",
    "Malibu sight"
  ],
  "jolt": [
    "Unpleasant surprise",
    "What a double shot of caffeine provides",
    "Shock to the system",
    "Give a turn",
    "What caffeine can give you",
    "With 5-Down, bygone beverage",
    "Big bump",
    "Kick",
    "Big bump",
    "Sudden impact",
    "Rude awakening",
    "Shock",
    "Bump hard",
    "Supercaffeinated cola",
    "Elbow hard",
    "Shake up",
    "Finger-in-the-socket consequence",
    "Shock",
    "Shock",
    "Big buffet",
    "Shake up",
    "Stun with a blow",
    "Sudden shock",
    "Stun"
  ],
  "kate": [
    "Writer DiCamillo with two Newbery Medals",
    "Comic McKinnon",
    "See 33-Across",
    "Mother of the royals George, Charlotte and Louis",
    "McKinnon of \"S.N.L.\"",
    "Half of a 1980s sitcom duo",
    "Prince George's mom",
    "Duchess of Cambridge, to friends",
    "Actress Winslet",
    "Feminist author Millett",
    "Actress Mara of \"House of Cards\"",
    "Title parent in a TLC reality series",
    "Cole Porter title woman",
    "Winslet who played half of 67-Down",
    "Actress Bosworth",
    "Jackson or Winslet",
    "Oscar winner Winslet",
    "The shrew in \"The Taming of the Shrew\"",
    "Actress Winslet",
    "Shakespearean shrew",
    "Actress Winslet",
    "Actress Winslet",
    "Shakespeare's shrew, informally",
    "Smith who sang \"God Bless America\"",
    "\"Kiss me\" miss",
    "Actress Winslet",
    "Half a 1980's sitcom duo",
    "The \"shrew\" in \"The Taming of the Shrew\"",
    "Title role in a Cole Porter musical",
    "Actress Jackson",
    "\"Please Don't Eat the Daisies\" mother",
    "\"Kiss Me, ___\"",
    "Actress Winslet",
    "Shakespearean character who \"had a tongue with a tang\"",
    "Jackson or Smith",
    "Model Moss",
    "Feminist Millett",
    "Petruchio's mate"
  ],
  "idno": [
    "Security fig.",
    "Fig. on a driver's license or passport",
    "\"___ idea!\"",
    "Alternative to a name: Abbr.",
    "Nine-digit fig. on a Social Security card",
    "Social Security fig.",
    "Prisoner's assignment: Abbr.",
    "Security figure: Abbr.",
    "\"___ idea!\"",
    "Fig. on a driver's license"
  ],
  "cads": [
    "Scoundrels",
    "Scoundrels",
    "Untrustworthy types",
    "Rude dudes",
    "Heels",
    "Scoundrels",
    "Bad catches?",
    "Ill-bred fellows",
    "Unfaithful sorts",
    "Boors",
    "Base men?",
    "Heels",
    "Wham-bam-thank-you-ma'am types",
    "Rogues",
    "Rascals",
    "Repeat offenders?",
    "Heartbreaker types",
    "Men whom women can't trust",
    "Poor dating prospects",
    "Scoundrels",
    "Rogues",
    "Blackguards",
    "Bad boyfriends",
    "No-goodniks",
    "Scoundrels",
    "Bounders",
    "Rotters",
    "Reprobates",
    "Heels",
    "Heels",
    "Bounders",
    "Untrustworthy types",
    "Rascals",
    "No-goodniks",
    "Kin to blackguards and knaves",
    "Men behaving badly",
    "Low heels?",
    "Rogues",
    "Heels",
    "Scamps",
    "They're no gentlemen",
    "Rotters"
  ],
  "tels": [
    "Phone nos.",
    "Nos. in a directory",
    "BlackBerry nos.",
    "Contact info: Abbr.",
    "Rolodex nos."
  ],
  "dirk": [
    "Old-fashioned weapon for hand-to-hand combat",
    "N.B.A. star Nowitzki",
    "Quaint dagger",
    "Weapon in old hand-to-hand combat",
    "Straight-bladed dagger",
    "Dagger",
    "Dagger",
    "Dagger",
    "Dagger",
    "Benedict of \"The A-Team\"",
    "Weapon in old hand-to-hand fighting",
    "Highlands weapon",
    "Dagger",
    "Dagger",
    "Highlander's weapon",
    "Quaint weapon",
    "Bogarde of \"Darling\"",
    "Actor Bogarde",
    "Actor Bogarde"
  ],
  "pose": [
    "Do some modeling for artists",
    "Get ready for the camera",
    "Get in place for the camera",
    "Present",
    "Ask",
    "Warrior, e.g., in yoga",
    "Prepare for a photo",
    "Ask",
    "One may be struck",
    "It may be struck on a runway",
    "What a model strikes",
    "Sit (for)",
    "Ask",
    "It may be struck",
    "Sit to be shot",
    "Ask, as a riddle",
    "Work for an artist, maybe",
    "Ask, as a question",
    "A model strikes one",
    "Sit for a painting, say",
    "Vogue on a dance floor",
    "Something to strike",
    "Model's stance",
    "Sit for a photo",
    "Ask",
    "Sit for a painting",
    "Sit still?",
    "What people often do for pictures",
    "Sit (for)",
    "Sitting with one's hand on one's chin, e.g.",
    "Ask, as questions",
    "Sit (for)",
    "You might strike one",
    "Sit for a picture",
    "Sit for a shot",
    "Get shot in a studio?",
    "Something that's struck",
    "Ask",
    "Model behavior?",
    "Prepare to be shot",
    "Get set for a shot",
    "Sit for a shot",
    "Sit",
    "Sit for a photo",
    "Example of model behavior?",
    "Flex one's muscles, maybe",
    "Something to strike",
    "Sit",
    "It's done for appearance' sake",
    "Sit (for)",
    "False front",
    "Sit for a photograph",
    "Prepare to be shot",
    "Prepare for a snap",
    "It may be struck",
    "Put forward",
    "Cooperate with a shooter",
    "Stance",
    "Prepare to be shot",
    "Assert",
    "Emulate Tyra Banks",
    "Sit for a photo",
    "It may be struck",
    "Affectation",
    "Stance",
    "Model",
    "Model",
    "Sit for a camera",
    "Put forward",
    "Sit",
    "Attitudinize",
    "Model's position"
  ],
  "nogo": [
    "Like an area that's off-limits",
    "\"Ain't happenin'\"",
    "Something scratched",
    "Scrubbed",
    "Scrubbed",
    "Canceled, as a launch",
    "Scrapped, to NASA",
    "Aborted plan",
    "Canceled, as a mission",
    "Scrubbed, as a NASA mission",
    "Canceled, as a launch",
    "Scrubbed, as a mission",
    "\"Ain't happening\"",
    "Aborted",
    "Off-limits",
    "Canceled ... or a hint to answering 17-, 27-, 34-, 45- and 57-Across",
    "Canceled",
    "Scrubbed, as a mission",
    "Scrubbed",
    "Scrapped, as a mission",
    "Canceled",
    "NASA cancellation",
    "Scuttled mission",
    "Scrubbed",
    "Cancellation",
    "Scrubbed",
    "NASA scrub",
    "Canceled",
    "Aborted",
    "Scrubbed",
    "Forbidden",
    "Scrapped, at NASA",
    "Canceled",
    "Canceled",
    "NASA scrub",
    "Scrubbed mission",
    "Scrubbed",
    "Canceled, to NASA",
    "NASA cancellation",
    "Mission cancellation",
    "Unworkable",
    "Aborted mission words",
    "Failed attempt",
    "Inoperative",
    "Off",
    "Canceled",
    "Inoperative",
    "Canceled",
    "Canceled",
    "Canceled",
    "Canceled, as a launch",
    "Cancelled",
    "Canceled",
    "Canceled"
  ],
  "rush": [
    "Fraternity activity",
    "\"A.S.A.P.!\"",
    "Imperative on an overnight package",
    "Surge of adrenaline",
    "\"A.S.A.P.!\"",
    "Overnight, maybe",
    "Heady feeling",
    "Charge on a field",
    "Hurry it up",
    "Thrill",
    "___-hour traffic",
    "Try to pledge",
    "\"A.S.A.P.!\"",
    "Hurry up",
    "Late-afternoon traffic, e.g.",
    "First name in talk",
    "Blitz",
    "Kind of hour",
    "Bungee jumper's feeling",
    "Hurry",
    "Send by FedEx",
    "Send overnight, for example",
    "Kind of job",
    "Send by FedEx, e.g.",
    "Hurry",
    "\"High priority!\"",
    "First name in talk radio",
    "\"What's the ___?\""
  ],
  "kohl": [
    "Department store eponym",
    "Department store eponym",
    "German chancellor between Schmidt and Schroder",
    "1980s-'90s German leader Helmut",
    "1980's-90's chancellor Helmut",
    "Germany reunifier",
    "German reunifier",
    "Schmidt's successor as German chancellor",
    "Schroeder's predecessor as chancellor",
    "Schmidt's successor",
    "Successor to Schmidt"
  ],
  "wave": [
    "Sand castle destroyer",
    "Surfer's need",
    "Image on an oscilloscope",
    "Aspect of hydrodynamics",
    "Greeting from a parade float",
    "Surfer's catch",
    "Swell",
    "Something that may crash and break",
    "What a surfer catches",
    "Something a surfer catches",
    "Hello or goodbye, maybe",
    "A surfboard rides it",
    "Breaker, e.g.",
    "One might be caught near a beach",
    "You might catch one near a beach",
    "Thing caught near the shore",
    "50-Across sight",
    "Informal greeting",
    "Salon option",
    "Hi sign?",
    "Signal hello or goodbye",
    "What a surfer rides",
    "Hello from a distance",
    "See 9-Down",
    "Common greeting",
    "Common hello or goodbye",
    "Silent goodbye",
    "Salon product"
  ],
  "lugs": [
    "Totes",
    "Hauls with effort",
    "Totes",
    "Big oafs",
    "Chowderheads",
    "Carries",
    "Blockheads",
    "Apes",
    "Hauls",
    "Blockheads",
    "Schleps",
    "Drags along",
    "Big galoots",
    "Schleps",
    "Schleps",
    "Totes",
    "Blockheads",
    "Drags",
    "Hauls"
  ],
  "itty": [
    "Teeny",
    "___-bitty",
    "Wee",
    "Eensy-weensy",
    "Teensy",
    "Teensy",
    "Minute, informally",
    "Minute, in baby talk",
    "Minute, to a tot",
    "Teeny",
    "Minuscule, informally",
    "___-bitty",
    "Wee, informally",
    "Tiny opening?",
    "___-bitty",
    "___-bitty",
    "___-bitty",
    "___-bitty",
    "___-bitty",
    "___-bitty",
    "Start of something small?",
    "Small word",
    "Tiny, in part",
    "___-bitty",
    "Bitty's partner",
    "___-bitty",
    "___ -bitty"
  ],
  "firm": [
    "Resolute",
    "Not budging",
    "Mattress description",
    "How the staunch stand",
    "Not yielding, as a mattress",
    "Unyielding",
    "Toothbrush or mattress specification",
    "Unvarying",
    "Unwilling to budge",
    "Tighten (up)",
    "Like some offers",
    "Al dente, say",
    "Unwilling to budge",
    "Al dente",
    "Al dente",
    "Tofu specification",
    "Like some mattresses",
    "Not budging",
    "Al dente",
    "See 39-Down",
    "Not negotiable",
    "Business",
    "Al dente",
    "Concern",
    "Grisham thriller, with \"The\""
  ],
  "dove": [
    "Peacenik",
    "Peace sign",
    "Flier for a magic show",
    "Peacenik",
    "Peace symbol",
    "Ice cream bar brand",
    "Bird in a magician's hat",
    "Plunged",
    "Bird in Genesis",
    "Went off the board",
    "Peace symbol",
    "Bird of peace",
    "*Chocolate brand",
    "Chocolate brand",
    "Antiwar advocate",
    "Messenger of Noah",
    "Bird with an olive branch",
    "Went off the deep end?",
    "41-Across symbol",
    "Hawk's opposite",
    "Hawk's opposite",
    "Went under",
    "Magician's live prop",
    "Didn't just tiptoe into the water",
    "Peace proponent",
    "Shade of gray",
    "Cooer",
    "Peacenik",
    "Peace symbol",
    "Gospel music award",
    "Peace symbol",
    "Went headlong"
  ],
  "maus": [
    "Only graphic novel to win a Pulitzer Prize (awarded 1992)",
    "Art Spiegelman's Pulitzer-winning graphic novel",
    "First graphic novel to win a Pulitzer (1992)",
    "Ruhr rodent",
    "What a Katze catches",
    "German pest",
    "Art Spiegelman's Pulitzer-winning graphic novel",
    "Art Spiegelman best seller",
    "1986 Art Spiegelman book",
    "Art Spiegelman's Pulitzer-winning graphic novel"
  ],
  "usma": [
    "\"The Point,\" in brief",
    "Alma mater of Grant and Lee: Abbr.",
    "Institute signed into existence by Thos. Jefferson",
    "Cadet's sch.",
    "West Point inst.",
    "West Point inits.",
    "West Point inits.",
    "Sch. whose alumni constitute the Long Gray Line",
    "Initials for 56-Across",
    "Classic football rival of Notre Dame: Abbr.",
    "Black Knights' home: Abbr.",
    "86-Across's alma mater: Abbr.",
    "Ike's alma mater: Abbr.",
    "Alma mater of D.D.E.",
    "West Point abbr.",
    "Where D.D.E. went to sch.",
    "Sch. with generals as alums",
    "West Point institution, for short",
    "West Point inits.",
    "Cadets' inits.",
    "West Point inits.",
    "West Point inits.",
    "West Point inits.",
    "Plebe's. place: Abbr.",
    "U.S. Grant's school: Abbr.",
    "West Point inits."
  ],
  "siva": [
    "Hindu god of destruction",
    "The Destroyer, in Hinduism",
    "Hindu god of destruction",
    "One of the Hindu trinity",
    "Hindu deity",
    "The Destroyer, in Hinduism",
    "Member of the Hindu trinity",
    "Hindu trinity member",
    "Part of the Hindu trinity",
    "One of the Hindu trinity",
    "Part of the Hindu trinity",
    "The Destroyer, in Hinduism",
    "God of destruction",
    "Hindu god",
    "God of destruction"
  ],
  "fila": [
    "Puma competitor",
    "Reebok rival",
    "Nike rival",
    "Adidas competitor",
    "Nike competitor",
    "Adidas alternative",
    "Nike rival",
    "Sportswear brand",
    "Reebok competitor"
  ],
  "abra": [
    "Introduction to magic?",
    "Start of an incantation",
    "Start of a magic incantation",
    "\"East of Eden\" girlfriend",
    "Start of a magician's cry",
    "Magical start?",
    "Aron's girlfriend in \"East of Eden\"",
    "Incantation starter",
    "Spelling start?",
    "Start of a magic incantation",
    "Magical opener",
    "Steinbeck siren",
    "\"East of Eden\" girl",
    "Incantation opener",
    "Start of an incantation",
    "Start of an incantation",
    "Start of an incantation",
    "Magician's opening",
    "Start of a spell",
    "Start of a spell",
    "Start of an incantation",
    "Start of a magic incantation",
    "Start of an incantation",
    "Start of a spell",
    "Julie Harris's \"East of Eden\" role",
    "Magician's start",
    "Start of a magician's cry",
    "Start of a spell",
    "\"East of Eden\" woman",
    "Magician's opening",
    "Magician's start",
    "Start of a magician's cry",
    "Start of an incantation",
    "Magical opening",
    "Spelling start?",
    "Magician's opening",
    "Incantation beginning",
    "Incantation opener",
    "Start of a magician's cry",
    "Start and end of a magician's cry",
    "Start of an incantation",
    "\"East of Eden\" girl",
    "Part of a magic incantation",
    "\"East of Eden\" role",
    "\"East of Eden\" woman",
    "Incantation start",
    "Magic phrase starter"
  ],
  "flop": [
    "Hit's opposite",
    "Bomb",
    "Theatrical failure",
    "Lay an egg, so to speak",
    "Edsel or New Coke, notably",
    "Turkey",
    "Dud",
    "Big failure",
    "Washout",
    "Edsel",
    "Dud",
    "Dud",
    "Dud",
    "Lay an egg",
    "Dog",
    "Egg layer?",
    "Dog",
    "Lead balloon",
    "Hit's opposite",
    "Washout",
    "Turkey, businesswise",
    "Lay an egg",
    "Sorry failure",
    "No hit",
    "Lay an egg",
    "Bomb",
    "Angel's worry",
    "Big failure",
    "Bomb",
    "Big dud",
    "Bomb",
    "Dud",
    "Angel's dread",
    "Bomb"
  ],
  "nehi": [
    "Crush alternative",
    "Classic soda brand",
    "Classic soda brand",
    "Fruity soda brand",
    "Classic pop brand",
    "Classic soft drink",
    "Soda with fruity flavors",
    "Classic soda brand",
    "___ Corporation, predecessor of RC Cola's Royal Crown Company",
    "Fruity soft drink",
    "Old pop",
    "New pop of 1924",
    "Soda brand introduced in 1924",
    "Fanta competitor",
    "___ Bottling Company (Cleveland fixture for over 85 years)",
    "Soda pop brand",
    "Classic pop",
    "Dr Pepper Snapple Group brand",
    "Classic soda brand",
    "Member of the old Chero-Cola product line",
    "Classic pop brand",
    "Fanta alternative",
    "Classic soda brand",
    "Drink named for a certain small stature",
    "\"M*A*S*H\" drink",
    "Soda with a Blue Cream flavor",
    "Chero-Cola, after a name change",
    "Beverage once sold \"in all popular flavors\"",
    "___ Grape",
    "Classic soft drink brand",
    "\"M*A*S*H\" soft drink",
    "Classic soda brand",
    "Beverage whose logo was once the bottom half of a woman's legs",
    "Pop name",
    "Beverage that once offered a Luau flavor",
    "Beverage brand once sold \"in all popular flavors\"",
    "Classic soda pop",
    "Royal Crown, once",
    "It was once advertised as \"Your favorite drink in your favorite flavor\"",
    "Radar selection",
    "Soft drink since 1924",
    "Orange, peach or strawberry product",
    "Classic soft drink",
    "Certain pop",
    "Pop that's over 80 years old",
    "NuGrape competitor",
    "Soft drink brand",
    "Classic soft drink",
    "Fruit-flavored soda",
    "Classic soft drink with orange, grape and peach flavors",
    "Soda pop brand",
    "Cadbury Schweppes brand",
    "Cadbury Schweppes brand",
    "Old pop",
    "Classic soft drink",
    "___ Corp., former name for Royal Crown Cola",
    "Classic soft drink brand",
    "Royal Crown product",
    "\"M*A*S*H\" soft drink",
    "NuGrape competitor",
    "Classic soft drink",
    "Beverage brand",
    "Classic soda brand",
    "Classic soft drink",
    "Drink since 1924",
    "Classic soft drink",
    "Beverage company formerly known as Chero-Cola",
    "Brand once advertised as \"Tops in taste\"",
    "Classic pop",
    "Drink whose name is a homophone of 48-Across",
    "Beverage brand",
    "Nostalgic soft drink",
    "Blue Cream Soda maker",
    "Classic drink",
    "Royal Crown product",
    "Royal Crown brand",
    "Classic soft drink",
    "Classic soft drink",
    "Classic soft drink",
    "RC Cola brand",
    "Soft drink brand",
    "Radar's favorite drink on \"M*A*S*H\"",
    "Radar's soft drink",
    "Royal Crown Cola brand",
    "Soft drink brand",
    "Royal Crown Cola brand",
    "Nostalgic soft-drink brand"
  ],
  "down": [
    "With 39-Down, minimize",
    "See 54-Across",
    "One direction for an elevator",
    "In a blue funk",
    "Up (for), paradoxically",
    "In the dumps",
    "With 67-Across, coastal Maine",
    "With 40-Down, how rain falls ... or a literal description of the answers to the four theme clues",
    "Southward",
    "Chug",
    "Heading for half of crossword clues",
    "Depressed",
    "Blue",
    "\"The moon is ___; I have not heard the clock\": \"Macbeth\"",
    "*Rejected",
    "Elevator direction half the time",
    "Out of order",
    "Temporarily not working",
    "Pillow filler",
    "Elevator button",
    "Put away",
    "*Quilt filling",
    "Low in spirits",
    "Like the answer to this clue",
    "Sad",
    "Dejected",
    "Knock out of the sky",
    "Blue",
    "Gridiron scoreboard info",
    "Swallow",
    "Depressed",
    "On toast, at a diner",
    "Pillow filler",
    "Quote, part 3",
    "On toast, in diner slang",
    "How the answer to this goes",
    "Fluff"
  ],
  "face": [
    "With 27-Down, Apple app",
    "Visage",
    "Main part of a selfie",
    "Turn toward",
    "Turn toward",
    "With 33-Across, meeting with someone in person",
    "One of a couple for the Roman god 28-Across",
    "Not flee",
    "Body part first transplanted in 2010",
    "Polyhedron part",
    "Makeup target",
    "Something that's often made up",
    "Deal with",
    "Watch part",
    "Place for a smile",
    "Turn toward",
    "Take 1, clue 1",
    "Model's asset",
    "It may be lost or saved",
    "Confront",
    "Veneer",
    "Mug",
    "Physiognomy",
    "Come to grips with",
    "Confront",
    "Turn to",
    "Confront",
    "Site of this puzzle's thematic features",
    "Stand up to",
    "Kind of value",
    "Physiognomy",
    "Stand before",
    "Clock part",
    "Die feature",
    "Confront"
  ],
  "whom": [
    "Often-misused pronoun",
    "Tricky pronoun to use",
    "\"For ___ the Bell Tolls\"",
    "Pronoun in letter greetings",
    "To ___ it may concern",
    "\"___ the gods would destroy ...\"",
    "Pronoun in the starts of many letters",
    "Part of T.W.I.M.C.",
    "Often-misused pronoun",
    "Much underused pronoun",
    "Maine radio station whose call letters spell a pronoun",
    "Pronoun in a Hemingway title",
    "Pronoun following a preposition",
    "Oft-misused pronoun",
    "\"For ___ the Bell Tolls\"",
    "To ___ it may concern",
    "Pronoun in a Hemingway title",
    "\"To ___ it may concern\""
  ],
  "drag": [
    "\"We're all born naked and the rest is ___\": RuPaul",
    "Party pooper",
    "Hot rod race",
    "Pride Parade participants may be in it",
    "RuPaul's purview",
    "What a Pride Day parader might dress in",
    "Bummer",
    "Long inhaling of a cigarette",
    "Go on a little too long",
    "What RuPaul dresses in",
    "Be long and boring",
    "Bummer",
    "Tow",
    "Bummer",
    "Pull along",
    "What some performers lip-sync in",
    "Race for hot rods",
    "Bummer",
    "Wet blanket",
    "Engage in a street auto race",
    "Men might dress in this",
    "Go on too long",
    "Bummer",
    "Pull ... or puff",
    "Pull behind",
    "Bring unwillingly",
    "Zip strip?",
    "Quarter-mile race, e.g.",
    "Party pooper",
    "Downer",
    "Pain in the neck",
    "Big bore",
    "Bore",
    "Party pooper",
    "Use a trawl",
    "Aerodynamic force",
    "Use a trawl",
    "Downer",
    "Bummer",
    "Bore",
    "Complete bore",
    "Downer",
    "Bummer",
    "Long puff",
    "Race, in a way",
    "Kind of race or queen"
  ],
  "fems": [
    "Some partners in lesbian couples",
    "Partners in many lesbian couples",
    "Women, slangily"
  ],
  "olan": [
    "Heroine in Pearl Buck's \"The Good Earth\"",
    "Pearl Buck heroine",
    "Pearl Buck heroine",
    "Pearl S. Buck heroine",
    "Pearl Buck heroine",
    "Good wife in \"The Good Earth\"",
    "\"The Good Earth\" wife",
    "Wife in \"The Good Earth\"",
    "Wang Lung's wife, in literature",
    "Pearl Buck heroine",
    "\"The Good Earth\" heroine",
    "\"The Good Earth\" heroine",
    "\"The Good Earth\" wife",
    "Slave in Buck's House of Hwang",
    "\"The Good Earth\" heroine",
    "\"The Good Earth\" heroine",
    "\"The Good Earth\" mother",
    "1937 Oscar role for Luise Rainer",
    "Pearl Buck heroine",
    "Pearl Buck heroine",
    "\"The Good Earth\" heroine",
    "Pearl Buck heroine",
    "\"The Good Earth\" heroine",
    "Pearl Buck heroine",
    "Pearl Buck heroine",
    "Pearl Buck heroine",
    "\"The Good Earth\" mother",
    "\"The Good Earth\" heroine",
    "Pearl Buck heroine",
    "\"The Good Earth\" wife",
    "Pearl Buck heroine",
    "Pearl Buck heroine",
    "\"The Good Earth\" heroine",
    "Oscar role for Luise Rainer, 1937",
    "Pearl Buck heroine",
    "\"The Good Earth\" heroine",
    "Pearl Buck heroine",
    "\"The Good Earth\" heroine",
    "Pearl Buck heroine",
    "Wang Lung's patient wife",
    "Pearl Buck heroine",
    "\"The Good Earth\" woman",
    "\"The Good Earth\" wife",
    "Pearl Buck heroine",
    "Buck heroine",
    "Wang Lung's wife, in fiction",
    "Wang Lung's wife"
  ],
  "adee": [
    "Ending with chick",
    "Ending with chick",
    "Chick's tail?",
    "Chick's tail?",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "Chick's tail?",
    "Chick chaser?",
    "\"Zip\" follower",
    "\"Zip-___-Doo-Dah\"",
    "\"Song of the South\" song syllables",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "Chick follower",
    "\"Song of the South\" song syllables",
    "\"Zip-___-Doo-Dah\"",
    "Suffix with chick",
    "\"Zip-___-Doo-Dah\"",
    "Chick's tail?",
    "Chick's tail?",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Song of the South\" song syllables",
    "\"Zip-___-Doo-Dah\"",
    "Chick's ending",
    "Chick follower",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "Chick's tail?",
    "\"Zip-___-Doo-Dah\"",
    "Chick ending",
    "\"Zip- ___ -Doo-Dah\"",
    "\"Zip-___-Doo-Dah\"",
    "Chick ender"
  ],
  "mnop": [
    "Pre-Q quartet",
    "Run before Q",
    "Alphabetical foursome",
    "Alphabet quartet",
    "Alphabet quartet",
    "Pre-Q queue",
    "L train",
    "Alphabet quartet",
    "Alphabetical run",
    "Alphabet quartet",
    "Alphabet series",
    "Letter run",
    "Alphabet quartet",
    "Alphabet run",
    "The L train?",
    "Queue before Q",
    "Alphabet run",
    "Alphabetical foursome",
    "Alphabet quartet",
    "Alphabet quartet"
  ],
  "pals": [
    "Buddies",
    "Buddies",
    "Buds",
    "Buds",
    "Buds",
    "Laverne and Shirley, e.g.",
    "Buds",
    "Buddies",
    "Huck Finn and Tom Sawyer, e.g.",
    "Mates",
    "Buddies",
    "Mates",
    "Homeboys",
    "Buddies",
    "Buddies",
    "Buds",
    "Gang members",
    "Clubmates",
    "Best buds",
    "Buddies",
    "Buds",
    "Buds",
    "Bros",
    "Buddies",
    "Intimates",
    "They hang together",
    "People to hang with",
    "Buddies",
    "Cronies",
    "Good buds",
    "Buds",
    "Alter egos",
    "People to hang with",
    "Comrades",
    "Jokes (around)",
    "Amigos",
    "Plays (around with)",
    "Cohorts",
    "Inner circle members",
    "Is friendly, with \"around\"",
    "Cronies",
    "Buddies"
  ],
  "ossa": [
    "Greek peak in Thessaly",
    "Greek peak southeast of Olympus",
    "Mount near Olympus",
    "Mount near Olympus",
    "Mount in Greek mythology",
    "Peak in Thessaly",
    "Peak in Thessaly",
    "Peak near Olympus",
    "Mount of Greek myth",
    "Greek peak",
    "Bones, anatomically",
    "\"Odyssey\" peak",
    "Greece's Mount ___",
    "Mount of Greek myth",
    "Greek summit",
    "Thessaly's Mount ___",
    "Greece's Mount ___",
    "Greek peak",
    "Peak in \"The Odyssey\"",
    "Mount in myth",
    "Greek peak",
    "Bones, anatomically",
    "Mountain near Pelion",
    "Greek peak",
    "Greek summit",
    "Tasmania's highest peak",
    "Bones: Lat.",
    "\"Odyssey\" high point",
    "Mythical mount",
    "Greek height",
    "Peak in Thessaly",
    "Greek peak",
    "Bones, anatomically",
    "Greek peak",
    "Bones, to an anatomist",
    "Greek peak",
    "Thessaly peak",
    "Mount near Mt. Olympus",
    "Peak SE of Olympus",
    "Peak in Thessaly",
    "Neighbor of Olympus",
    "Bones, anatomically",
    "Greek peak",
    "Mountain in Thessaly",
    "Thessalian peak",
    "Greek peak",
    "Peak in the mythical war of the Giants",
    "Peak near the Vale of Tempe",
    "Peak in Greek myth",
    "Peak in Greek myth",
    "Bones, anatomically",
    "Mount in Greek myth",
    "Peak of myth",
    "Pelion's support, in legend",
    "Bones, to a doctor",
    "Peak in Thessaly",
    "Tasmania's highest peak",
    "Greek peak",
    "Greek peak",
    "Bones, zoologically",
    "Greek peak",
    "Mountain of Greek legend",
    "\"Odyssey\" peak",
    "Neighbor of Pelion",
    "Peak in the \"Odyssey\"",
    "Mountain in Thessaly",
    "Bones, in anatomy",
    "Bones",
    "Bones",
    "Mountain on the Gulf of Salonika",
    "Greek peak",
    "\"Odyssey\" peak",
    "Greek peak",
    "Peak in myth",
    "Thessaly peak",
    "Greek peak",
    "Pelion's sister peak",
    "Greek peak",
    "Thessalian high spot",
    "Greek mountain",
    "Greek peak"
  ],
  "flan": [
    "Dessert often topped with caramel sauce",
    "Custardy dessert",
    "Custardy dessert",
    "Custardy dessert",
    "Custardy dessert",
    "Sweet treat",
    "Glazed dessert",
    "Custard dessert",
    "Pasteleria offering",
    "Sweet Spanish dessert",
    "Sweet baked dessert",
    "Custard dessert",
    "Sweet Spanish dessert",
    "Custard dessert",
    "Custard concoction",
    "Custard concoction",
    "Custard dessert",
    "Spanish dessert",
    "Spanish custard",
    "Sweetened custard",
    "Custard dessert",
    "Caramel-topped dessert",
    "Creme caramel",
    "Custard",
    "Sweet dessert",
    "Custard tart",
    "Spanish custard",
    "Custard tart",
    "Bakery offering",
    "Carmel-topped dessert",
    "Caramel-topped dessert",
    "Tartlike pastry",
    "Spanish dessert",
    "Caramel-topped dessert"
  ],
  "nuys": ["Van ___, Calif.", "Van ___, Calif."],
  "ravi": [
    "Shankar who taught sitar to George Harrison",
    "Sitarist Shankar",
    "First name at Woodstock",
    "Shankar with a sitar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Hindu sun god",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "___ Shankar, influence on George Harrison",
    "Shankar who mentored George Harrison",
    "Musician Shankar",
    "Shankar at Woodstock",
    "Sitarist Shankar",
    "Beatle George's sitar teacher",
    "Sitarist Shankar",
    "Noted first name in raga",
    "Sitar master Shankar",
    "Sitarist Shankar",
    "Shankar at Woodstock",
    "Musician Shankar",
    "Sitar player Shankar",
    "Beatle George studied under him",
    "Shankar who played at the Concert for Bangladesh, 1971",
    "Jazz saxophonist Coltrane",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "First name in raga performance",
    "Sitarist Shankar",
    "Raga player Shankar",
    "First name in Indian music",
    "Sitarist Shankar",
    "First name in raga",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitar master Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "___ Shankar",
    "Sitarist Shankar",
    "26-Down player Shankar",
    "Sitar master Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "A teacher of John, Paul and George",
    "Sitarist Shankar",
    "Lahore's river",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Sitarist Shankar",
    "Mr. Shankar"
  ],
  "rams": [
    "Head-butts",
    "Losing Super Bowl LIII team",
    "Only N.F.L. franchise to win championships representing three different cities",
    "Butts",
    "Plows (into)",
    "Members of the flock",
    "Head-butts",
    "Aries animals",
    "Merlin Olsen's team",
    "Merlin Olsen's team",
    "Colorado State's team",
    "Pickup line?",
    "Lambs' fathers",
    "Super Bowl XXXIV winners",
    "Fordham athletes",
    "Crashes into",
    "Bumps hard",
    "Ewes' mates",
    "Super Bowl XXXIV champs",
    "Ewes' mates",
    "Means of forced entry",
    "Some butters",
    "Door-busting equipment",
    "Fearsome Foursome team",
    "Ones going head to head?",
    "Ones going head to head",
    "Aids in storming castle gates",
    "Natural butters",
    "Runs into",
    "Runs into",
    "Butts",
    "Butts",
    "Hits head-on",
    "Butts into",
    "Trans World Dome team",
    "Super Bowl XXXIV champs",
    "Head-butts",
    "Butts",
    "St. Louis gridders",
    "St. Louis N.F.L.'ers",
    "Butts",
    "Namath's last team",
    "St. Louis 11",
    "Super Bowl XIV participants",
    "Butts",
    "Trans World Dome team",
    "1980 Super Bowl losers",
    "Some butters",
    "Members of the flock",
    "Bumps, as in bumper cars",
    "Fordham team",
    "Bears' adversaries",
    "___ horn (shofar)",
    "Ex-L.A. team",
    "Thrusts",
    "St. Louis team",
    "Butts",
    "___ horn (shofar)",
    "Los Angeles 11"
  ],
  "mown": [
    "Like grass you can smell, perhaps",
    "Like fairways",
    "Neat, as a lawn",
    "Like a well-kept lawn",
    "No longer standing tall?",
    "Like cut greens",
    "Like fairways",
    "Like golf greens, frequently",
    "Like a trim lawn",
    "Cut close",
    "Cut, as a lawn",
    "Cut",
    "Cut",
    "Like a trim lawn",
    "Like a neat yard",
    "Cut",
    "Cut",
    "Cut down"
  ],
  "cult": [
    "___ classic",
    "Extremely popular to a small fan base",
    "Single-minded religious group",
    "___ of personality",
    "Kind of movie",
    "Kind of film",
    "Extremist sect",
    "Zealot's group",
    "Following group",
    "Zealot's group",
    "Kind of film",
    "Order of the Solar Temple, e.g.",
    "Kind of film",
    "Kind of film",
    "Movie following, maybe",
    "Heaven's Gate, e.g.",
    "Branch Davidians, e.g.",
    "Close-knit religious group",
    "Unorthodox sect",
    "Hero-worshipers",
    "Kind of following"
  ],
  "chou": [
    "French term of endearment that literally means \"cabbage\"",
    "Compatriot of Mao",
    "China's ___ En-lai",
    "China's ___ En-lai",
    "China's ___ En-lai",
    "Dynasty for Confucius",
    "Chinese dynasty during the time of Confucius",
    "With 47-Across, onetime Chinese premier",
    "Comrade of Mao",
    "Chinese dynasty lasting eight centuries",
    "Dynasty of Confucius and Lao-tzu",
    "800-year Chinese dynasty",
    "Old Chinese dynasty",
    "Hua's predecessor in China",
    "___ En-lai",
    "Dynasty of Confucius",
    "Chinese dynasty",
    "Dynasty during which Taoism emerged",
    "Chinese dynasty before the birth of Christ",
    "Dynasty of Confucius and Lao-tzu",
    "Third Chinese dynasty",
    "Chinese premier, 1949-76",
    "___ En-lai"
  ],
  "burr": [
    "One singer of \"Dear Theodosia\" in \"Hamilton\"",
    "\"Aaron ___, Sir\" (song from \"Hamilton\")",
    "First vice president not to become president",
    "\"Hamilton\" duelist",
    "Aaron who was vice president under Jefferson",
    "Part of a Scottish accent",
    "Historical subject for Gore Vidal",
    "A Scot has one",
    "Victor in a duel with Hamilton",
    "Gore Vidal historical novel",
    "Jefferson's first vice president",
    "Power-driven shop tool",
    "Vice president who graduated from Princeton at 16",
    "Vice president under Jefferson",
    "1973 Gore Vidal political novel",
    "Famous duelist",
    "Dueler of 1804",
    "Vice president under Jefferson",
    "Extrashort haircut",
    "First U.S. vice president not to become president",
    "Gore Vidal biographical novel",
    "Northern English pronunciation",
    "Dueler with Hamilton",
    "Extra-short haircut",
    "1973 Gore Vidal best seller",
    "Duelist of 1804",
    "Raymond of \"Perry Mason\"",
    "1973 Vidal novel",
    "Duelist of 1804",
    "Third Vice President"
  ],
  "spys": [
    "1974 C.I.A. spoof",
    "1974 Gould/Sutherland C.I.A. spoof",
    "1974 C.I.A. parody",
    "1974 C.I.A. spoof",
    "1974 film with three stars in its title",
    "1974 C.I.A. spoof",
    "Satirical 1974 espionage film",
    "1974 Gould/Sutherland C.I.A. spoof",
    "1974 Sutherland/Gould spoof",
    "1974 Gould/Sutherland spoof",
    "1974 Gould/Sutherland spoof",
    "1974 Sutherland/Gould spoof",
    "1974 C.I.A.-spoof movie",
    "1974 Gould/Sutherland spoof",
    "1974 Sutherland/Gould film",
    "1974 Sutherland/Gould spoof",
    "1974 Sutherland/Gould spoof",
    "1974 Sutherland/Gould spoof",
    "Northern apples"
  ],
  "nomo": [
    "Hideo who threw no-hitters in both the American and National Leagues",
    "Hideo ___, 1995 N.L. Rookie of the Year",
    "Baseball's Hideo",
    "Major-leaguer from Osaka who threw two no-hitters",
    "Pitcher Hideo ___",
    "Baseball's Hideo",
    "Hurler Hideo",
    "Pitcher who was the 1995 N.L. Rookie of the Year",
    "Baseball's Hideo ___",
    "Hurler Hideo",
    "Pitcher Hideo ___",
    "Pitcher Hideo",
    "Dodgers pitcher Hideo",
    "Pitcher Hideo",
    "1995 N.L. Rookie of the Year",
    "Pitcher Hideo"
  ],
  "tmen": [
    "Tax evasion agts.",
    "Al Capone chasers, informally",
    "Counterfeiter trackers, in old lingo",
    "Tax evasion investigators, quaintly",
    "Group reporting to Eliot Ness",
    "Dodgers' pursuers",
    "Counterfeiter-catching Feds",
    "Federal tax agts.",
    "Some investigators, informally",
    "Capone's adversaries, informally",
    "*Sitting figures, maybe",
    "Fraud fighters, for short",
    "Ones on the money trail, informally",
    "Feds who catch counterfeiters",
    "Bootleggers' foes",
    "Bootleggers' banes",
    "Dodgers' foes",
    "Eliot Ness and others",
    "Feds",
    "Govt. gangbusters",
    "Some Feds",
    "Fed. agents",
    "Govt. gangbusters",
    "Some govt. raiders",
    "Feds who nabbed Capone",
    "Eliot Ness and cohorts",
    "Feds who catch counterfeiters",
    "Eliot Ness and others",
    "Prohibition enforcers, informally",
    "Govt. gangbusters",
    "Bootleggers' bane",
    "Some feds",
    "Ringleaders' nemeses",
    "Some feds",
    "Some Feds",
    "Feds",
    "Prohibition agents",
    "Catchers of some ring leaders",
    "Some govt. investigators",
    "1947 semi-documentary-style crime drama",
    "Agts. looking for tax cheats",
    "1947 crime drama",
    "Some agents, for short",
    "Some snoops",
    "Feds",
    "Some govt. agents",
    "Some special agts.",
    "Some govt. agents",
    "1947 film noir starring Dennis O'Keefe",
    "Capone's nemeses",
    "Catchers of tax cheats",
    "Counterfeiter hunters",
    "Some feds",
    "Some feds",
    "Some feds",
    "\"The Untouchables\" extras",
    "Federal agts.",
    "Some feds",
    "Some feds",
    "Counterfeiters' counters",
    "Feds",
    "Counterfeiter catchers",
    "Followers of tax cheats",
    "Counterfeiter catchers",
    "Many Feds",
    "Revenuers",
    "Certain Feds",
    "Counterfeiters' nemeses",
    "Counterfeiters' nemeses",
    "Federal agents",
    "Some Feds",
    "Fed. agents",
    "Some special agents",
    "Counterfeit cops?",
    "Capone's adversaries",
    "Feds",
    "Govt. agents",
    "Undercover types",
    "The Untouchables, e.g.",
    "Ness and others",
    "Feds",
    "Crime-fighters, for short",
    "1947 docudrama",
    "Bootleggers' bane",
    "Feds",
    "Federal agents, informally",
    "U.S. Govt. agents",
    "Govt. agents",
    "Fed. tax agents",
    "Govt. agents",
    "Govt. investigators",
    "Counterfeiters' foes",
    "Govt. agents",
    "Some U.S. agents",
    "Special agents",
    "I.R.S. agents",
    "The Untouchables",
    "Govt. agents",
    "Revenuers, for short",
    "Phony bill collectors"
  ],
  "zero": [
    "Goose egg",
    "Worst possible mark on a test",
    "Digit in binary code",
    "The \"x\" in Euler's Identity - e<sup>i&pi;</sup> + 1 = x",
    "Absolute nobody",
    "Any digit in a googol after the first one",
    "32?? Fahrenheit, in Celsius",
    "Home (in on)",
    "Abysmal score",
    "Loser, informally",
    "Nonentity",
    "It's nothing",
    "\"___ Dark Thirty\"",
    "One minus one",
    "Open love?",
    "Lowest of the low?",
    "Complete loser",
    "Nonentity",
    "One goes after it",
    "Worthless sort",
    "Sum of opposites",
    "Complete bomb on a test",
    "Nobody",
    "Absolute nobody",
    "Number of calories in water",
    "Home (in on)",
    "Total loser",
    "It turns a hundred into a thousand",
    "Much-repeated part of binary code",
    "A round number ends in it",
    "\"Beetle Bailey\" soldier",
    "Home (in on)",
    "Loser",
    "Complete drip",
    "It's nothing at all",
    "Abysmal grade",
    "Bubkes",
    "Part of binary code",
    "Nil",
    "Goose egg",
    "Reset figure",
    "Round number?",
    "Cipher",
    "Nobody, slangily",
    "Hardly Mr. Personality",
    "Nada",
    "Kind of tolerance",
    "Horrible grade on a test",
    "Goose egg",
    "Nil",
    "Abysmal test score",
    "Goose egg",
    "A definite F",
    "Absolute twit",
    "Home (in on)",
    "\"Beetle Bailey\" boob",
    "Definitely a flunking score",
    "Zilch",
    "One of 50",
    "Two before two?",
    "Ground ___",
    "Worst possible test score",
    "Round number",
    "Abysmal test score",
    "No-show's score",
    "Round number",
    "Worst possible score",
    "Japanese plane of W.W. II",
    "Today's dodo count",
    "Ground___",
    "W.W. II Japanese plane",
    "Close (in on)",
    "Mr. Mostel",
    "Mr. Mostel",
    "Tevye portrayer on stage"
  ],
  "cuba": [
    "Bay of Pigs locale",
    "Home to the Bay of Pigs",
    "Havana's home",
    "Havana's home",
    "Olympic powerhouse in boxing",
    "Target of the U.S.-backed Radio Marti",
    "Where Hemingway wrote \"The Old Man and the Sea\"",
    "1962 Organization of American States expellee",
    "Country with which the U.S. re-established diplomatic relations in 2015",
    "Historic 2016 Obama destination",
    "Guantanamo Bay locale",
    "Homeland of many Miamians",
    "Island sometimes called El Cocodrilo",
    "Country with the most all-time medals in Olympic baseball",
    "Home to Morro Castle",
    "1950s coup site",
    "Locale of Guantanamo Bay",
    "Destination of many 1960s-'70s airplane hijackings",
    "International baseball powerhouse",
    "Bay of Pigs locale",
    "It's south of Florida",
    "San Juan Hill site",
    "Castro's country",
    "Havana's home",
    "Embargoed land",
    "Bay of Pigs location",
    "Where many Miamians were born",
    "Santiago de ___",
    "Embargoed country",
    "Home of 49-Across",
    "Sierra Maestra country",
    "Where the conga originated",
    "Actor Gooding"
  ],
  "corn": [
    "___ on the cob",
    "With 68-Down, summer side dish",
    "Toe woe",
    "Food that's husked",
    "Major Iowa crop",
    "Ears that can't hear",
    "Cheesy stuff",
    "Iowa's largest export",
    "Ears you may bite on",
    "Not the most sophisticated humor",
    "See 35-Across",
    "Hokey jokes",
    "It's hard to walk on",
    "Base of some ethanol",
    "___ flakes",
    "Chip ingredient",
    "Foot ailment",
    "Word that can precede the start of 17- or 54-Across or 11- or 27-Down",
    "Unwanted 35-Across feature",
    "Muffin choice",
    "Common syrup source",
    "Moonshiner's sackful",
    "Some humor",
    "It's grown in ears",
    "\"Hee Haw\" humor",
    "Hiker's woe",
    "Ears that can't hear",
    "Hokey stuff",
    "Mawkish sentiment",
    "Toe woe",
    "Moonshine ingredient",
    "\"Hee Haw\" fodder?",
    "Succotash ingredient",
    "\"Hee Haw\" humor",
    "It's both grown and eaten in rows",
    "Toe woe",
    "Not the most sophisticated humor",
    "Mawkish material",
    "Toe woe",
    "Skier's snow",
    "On-the-cob treat"
  ],
  "ccny": [
    "Big Apple school inits.",
    "Sch. overlooking Harlem",
    "Big Apple sch.",
    "Sch. Woody Allen flunked out of",
    "Big Apple sch.",
    "School inits. in Harlem since 1907",
    "Only same-year N.C.A.A. and N.I.T. tourney winner (1950)",
    "Big Apple educ. inits."
  ],
  "leov": [
    "Pope after Benedict IV",
    "Byzantine emperor known as \"the Armenian\"",
    "Benedict IV's successor",
    "Byzantine emperor called \"the Armenian\"",
    "Pope after Benedict IV"
  ],
  "cyto": [
    "Prefix with -plasm",
    "Prefix with -plasm",
    "Plasm prefix",
    "Cell: Prefix"
  ],
  "lira": [
    "Piece paid by Pisans for a piece of pizza, previously",
    "Turkish coin",
    "100 kurus",
    "Money in Istanbul",
    "Pre-euro currency",
    "Currency replaced by the euro",
    "Currency unit equal to 100 kurus",
    "Turkey bacon?",
    "Italian bread that's become toast?",
    "Currency replaced by the euro",
    "Old Italian bread",
    "Tip of Italy, once?",
    "Former Italian currency",
    "Bygone European capital",
    "Tip of Italy, once?",
    "Turkish currency unit",
    "Old Italian money",
    "Currency in Turkey",
    "Italian currency before the euro",
    "Turkish money",
    "Old Italian money",
    "Old Italian money",
    "Former capital of Italy?",
    "Turkey tip?",
    "Currency superseded by the euro",
    "Old currency abbreviated \"L.\"",
    "Old Italian coin",
    "Old Italian coin",
    "The euro replaced it",
    "Stale Italian bread?",
    "Turkish coin",
    "Pound of Turkey?",
    "Bygone European capital",
    "Money in the banca, once",
    "Turkey's currency",
    "Coin with the words REPVBBLICA ITALIANA",
    "Italian currency before the euro",
    "Old capital of 36-Across",
    "Trevi Fountain throw-in, once",
    "Franc : France :: ___ : Italy",
    "Old Italian bread",
    "Franc : France :: ___ : Italy",
    "Old Italian money",
    "Money that's an anagram of 2-Down",
    "Former currency of Vatican City",
    "Former coin in the Trevi Fountain",
    "Trevi coin, once",
    "The euro replaced it",
    "Old Italian money",
    "Old Italian currency",
    "Old Italian bread",
    "100 kurus",
    "Old Italian money",
    "Old Italian coin",
    "Bologna bread, once",
    "Coin collected by a numismatico",
    "Turkey tip?",
    "Old Italian money",
    "Old Italian bread",
    "Old Italian coin",
    "Turkish money",
    "Former Italian money",
    "Money replaced by the euro",
    "Coin in the Trevi Fountain, once",
    "Turkish money",
    "Maltese monetary unit",
    "Old money",
    "Turkish dough",
    "Turkish dough",
    "Pisa dough",
    "Milano moolah",
    "Italian money",
    "Milano moolah",
    "Money in the banca",
    "Pound in Turkey",
    "Istanbul currency",
    "Bit of denaro",
    "Turkish money",
    "Peseta : Spain :: ___ : Italy",
    "Italian money",
    "Milan money",
    "Cash in Capua",
    "100 centesimi",
    "Cassino money",
    "Money in 31-Across",
    "Coin no longer being minted",
    "Milan moola",
    "Dough for pizza",
    "Money in Milano",
    "Money in the banca",
    "Turkish money",
    "Bit of Italian bread?",
    "Italian moola",
    "Italian money",
    "Italian bread",
    "It's spent in Trent",
    "Money in Milan",
    "Bit of Euromoney",
    "Trevi toss-in",
    "Foreign currency",
    "Coin no longer minted",
    "It's tender in Turin",
    "100 kurus",
    "Trevi Fountain coin",
    "Italy's capital",
    "Foreign currency",
    "Italian cabbage",
    "Currency in Capri",
    "Il cambio currency",
    "Capri currency",
    "Money in Milano",
    "Turkish money",
    "San Remo currency",
    "100 centesimi",
    "Bit of Livorno lucre",
    "100 kurus",
    "Turkish money",
    "Vatican City monetary unit",
    "Italian bread",
    "Italian bread",
    "Turkish bread",
    "Trevi Fountain coin",
    "Coin in the Trevi",
    "Turkish money"
  ],
  "nyse": [
    "It gives Ford an \"F\": Abbr.",
    "Where Southwest Airlines is LUV",
    "Where Harley-Davidson is HOG",
    "Big Board inits.",
    "Where to find a Bitcoin price index, for short",
    "Where a bell is rung M-F at 9:30 a.m. and 4:00 p.m.",
    "Org. that rings a bell",
    "Big Board inits.",
    "The Big Board, briefly",
    "Home of \"the bell,\" briefly",
    "Nasdaq alternative",
    "Where Molson Coors is TAP",
    "Where Harley-Davidson Inc. is HOG",
    "Where Kellogg's is \"K\"",
    "Wall Street inits.",
    "Inits. at 11 Wall Street",
    "Big Board inits.",
    "Org. with a closing bell",
    "Business page heading",
    "Wall Street inits.",
    "Where Citigroup is C, for short",
    "Wall St. initials",
    "Where Sotheby's is BID",
    "Where 5-Down's company gets an \"F\"?",
    "Closing bell org.",
    "Where to buy GM and GE",
    "CNBC subj.",
    "Big Board inits.",
    "It lists G.M. and I.B.M.",
    "The Big Board, for short",
    "Where Loews is \"L\"",
    "Big Board initials",
    "Nasdaq alternative",
    "Closing bell org.",
    "Wall Street inits.",
    "Org. with a closing bell",
    "Frequent 5-Down topic",
    "Trading letters",
    "Closing bell place: Abbr.",
    "It has 1,366 seats: Abbr.",
    "The Big Board, for short",
    "It's closed on Dec. 25",
    "Big Board letters",
    "Big Board: Abbr.",
    "Org. with a National Historic Landmark building in lower Manhattan",
    "The Big Board: Abbr.",
    "Financial inits.",
    "Wall Street inits.",
    "Business page inits.",
    "Nasdaq alternative",
    "Trading org.",
    "Wall St. letters",
    "Wall Street inits.",
    "The Big Board, for short",
    "Provider of daily listings: Abbr.",
    "Wall St. letters",
    "Nasdaq competitor",
    "Nasdaq rival",
    "Financial page inits.",
    "Wall St. landmark",
    "Wall Street letters",
    "Wall Street inits.",
    "Wall St. initials",
    "Where AT&T is T",
    "Wall St. trading center",
    "4 p.m. bellringer",
    "Big Board initials",
    "Wall Street inits.",
    "Wall St. trading group",
    "The Big Board: Abbr.",
    "Nasdaq rival",
    "Where E*Trade is traded: Abbr.",
    "Where Coca-Cola is KO",
    "Trading letters",
    "Wall Street table heading: Abbr.",
    "AMEX counterpart",
    "Wall Street Journal subj.",
    "Ticker locale: Abbr.",
    "Wall Street inits.",
    "NASDAQ rival",
    "Big Board inits.",
    "Ticker locale: Abbr.",
    "Trading letters",
    "A bell ringer closes it: Abbr.",
    "Where bulls and bears run: Abbr.",
    "Financial page inits.",
    "Amex alternative",
    "Stock page heading",
    "Financial page abbr.",
    "Wall St. workplace",
    "Letters from Wall Street",
    "Wall Street abbr.",
    "Popular source of quotes, for short"
  ],
  "addl": [
    "Not incl.",
    "Extra, in ads",
    "Not incl.",
    "Extra: Abbr.",
    "Extra: Abbr.",
    "Like shipping and handling, typically: Abbr.",
    "Not included: Abbr.",
    "Extra: Abbr.",
    "Supplementary: Abbr.",
    "Extra, in ads",
    "Extra: Abbr.",
    "Extra: Abbr.",
    "Extra: Abbr."
  ],
  "vick": [
    "___ Chemical Company, onetime maker of VapoRub",
    "Four-time Pro Bowler Michael"
  ],
  "smit": [
    "Struck, old-style",
    "Infatuated, old-style",
    "Goo-goo-eyed, old-style",
    "Struck down, old-style",
    "Struck, old-style",
    "Struck, old-style",
    "Struck, once",
    "Struck, old-style",
    "Slugged, old-style",
    "Struck, old-style",
    "In love"
  ],
  "bess": [
    "First lady between Eleanor and Mamie",
    "First lady Truman",
    "\"Porgy and ___\"",
    "\"Porgy and ___\"",
    "Gershwin heroine",
    "First lady after Eleanor",
    "Porgy's partner",
    "Mrs. Truman",
    "Gershwin title heroine",
    "20th-century first lady",
    "Mrs. Truman",
    "First lady before Mamie",
    "\"Porgy and ___\"",
    "Gershwin opera heroine",
    "Gershwin title character",
    "\"Porgy and ___\"",
    "1940s-'50s White House name",
    "Longest-lived first lady",
    "Gershwin title character",
    "Gershwin heroine",
    "Mrs. Truman",
    "Catfish Row denizen",
    "Former first lady",
    "Porgy's woman",
    "Tudor queen, informally",
    "Heroine of a Gershwin opera",
    "1940's first lady",
    "1940's first lady",
    "Eleanor's follower as first lady",
    "First lady before Mamie",
    "Former first lady's name",
    "Former first lady's first name",
    "Gershwin title character",
    "1953 Jean Simmons title role",
    "1940's first lady",
    "\"I Loves You, Porgy\" singer",
    "Mrs. Harry Truman",
    "Porgy's woman",
    "Porgy's mate",
    "Porgy's woman",
    "Porgy's love",
    "She was wild about Harry",
    "Brown ___ (old musket)",
    "Porgy's woman",
    "Actress Armstrong",
    "Porgy's beloved",
    "Former First Lady",
    "\"The Highwayman\" heroine",
    "Porgy?Æs love",
    "Actress Armstrong",
    "Good Queen ___",
    "Mamie's predecessor",
    "Catfish Row soprano"
  ],
  "omri": [
    "Ahab's father in the Bible",
    "Father of Ahab in the Bible",
    "Actor Katz of \"Dallas\"",
    "King of Israel who founded Samaria",
    "Father of King Ahab",
    "Ahab's father",
    "Katz of \"Dallas\"",
    "Katz of \"Hocus Pocus\"",
    "Katz of \"Dallas\"",
    "One of the kings in Kings",
    "TV actor Katz",
    "Ahab's father",
    "Katz of \"Eerie, Indiana\"",
    "Ahab's father",
    "Ahab's father"
  ],
  "nsew": [
    "Compass letters",
    "The compass points",
    "Compass letters",
    "Compass points (seen spelled out in 20-, 26-, 43- and 53-Across)"
  ],
  "evie": [
    "Singer/songwriter Sands",
    "Girl's name that sounds like two letters of the alphabet",
    "Wilcox daughter in \"Howards End\"",
    "Woman's name that sounds like two letters",
    "___ Wilcox, daughter in E. M. Forster's \"Howards End\"",
    "Singer Sands",
    "Christian singer Tornquist",
    "Daughter in E. M. Forster's \"Howards End\"",
    "Woman's name that sounds like its first two letters",
    "Singer/songwriter Sands",
    "1960s pop singer Sands",
    "1960s singer Sands",
    "One of the Wilcoxes in \"Howards End\"",
    "\"Howards End\" role",
    "Singer/songwriter Sands",
    "\"A Letter for ___\" (Hume Cronyn film)",
    "Christian pop singer Tornquist",
    "Pop singer Sands",
    "\"Howards End\" role",
    "60's singer Sands",
    "'60s singer Sands",
    "60's singer Sands",
    "\"A Letter for ___\" (1945 movie)"
  ],
  "tgel": ["Shampoo brand", "Neutrogena dandruff shampoo"],
  "boff": [
    "Broadway hit, informally",
    "Box office smash, in slang",
    "Sockeroo",
    "Broadway hit, slangily",
    "Big Broadway hit",
    "Hit, as on the noggin",
    "Hit, in Variety slang",
    "Laugh, in showbiz slang",
    "Hit on Broadway",
    "Theatrical hit, in slang",
    "Box office hit, slangily"
  ],
  "koln": [
    "Rheinland city",
    "City about 280 miles NW of Munchen",
    "City on der Rhein",
    "City on the Rhine, to locals",
    "City NW of Munchen",
    "Fourth-largest city in Deutschland",
    "Severinsbrucke's city",
    "German cathedral city",
    "Largest city in Nordrhein-Westfalen",
    "___ Bonn Airport",
    "German cathedral city",
    "Rheinland city",
    "2006 World Cup city",
    "City on the Rhein",
    "City on the Rhine, to locals",
    "City on the Rhein",
    "Rhein port",
    "City on the Rhein",
    "City south of Dusseldorf",
    "Rheinland city"
  ],
  "domo": [
    "Major-___",
    "\"___ arigato, Mr. Roboto\"",
    "Major-___",
    "Major-___",
    "\"___ arigato\"",
    "\"___ arigato, Mr. Roboto\"",
    "Major-___ (bigwig)",
    "Major-___",
    "Spanish building topper",
    "Major-___",
    "Major ending"
  ],
  "recd": [
    "Mailroom stamp: Abbr.",
    "Office stamp abbr.",
    "Mailroom stamp",
    "Invoice abbr.",
    "Shipping dept. stamp",
    "Office stamp",
    "Invoice abbr.",
    "Invoice abbr.",
    "Office stamp",
    "Invoice abbr.",
    "Bank stamp abbr.",
    "Shipping dept. stamp",
    "In-basket stamp: Abbr.",
    "Office stamp abbr.",
    "Invoice abbr.",
    "In-basket stamp: Abbr.",
    "Invoice stamp",
    "Shipping dept. stamp"
  ],
  "earp": [
    "Wyatt, Virgil or Morgan of the Old West",
    "Historical figure played by Kurt Russell in \"Tombstone\"",
    "TV gunslinger Wynonna, supposed descendant of Wyatt",
    "Big name in Old West justice",
    "Lawman at the O.K. Corral",
    "1946 role for Fonda or 1994 role for Costner",
    "Brother of the Wild West",
    "Role for Hugh O'Brian on TV and Kevin Costner in film",
    "Family name of three lawmen brothers",
    "Tombstone figure",
    "Frontier figure",
    "Wyatt of Dodge City",
    "Role in 1993's \"Tombstone\"",
    "Wyatt at the O.K. Corral",
    "Wyatt of the Old West",
    "Wyatt of the Old West",
    "Wyatt of the Old West",
    "Lawman played by James Garner and Kevin Costner",
    "Tombstone lawman",
    "Wyatt of the Old West",
    "Marshal played in film by Kurt Russell and Kevin Costner",
    "Tombstone lawman",
    "Kurt Russell's role in \"Tombstone\"",
    "Tombstone figure",
    "Tombstone lawman",
    "Lawman Wyatt",
    "Gunslinger Wyatt",
    "Tombstone figure",
    "Wyatt out West",
    "O.K. Corral lawman",
    "Lawman Wyatt",
    "Family name in the Old West",
    "\"Tombstone\" role",
    "Dodge bullet dodger",
    "Title role for Kilmer and Costner",
    "O.K. Corral hero",
    "Tombstone figure",
    "1994 Costner title role",
    "O.K. Corral figure",
    "Dodge City lawman Wyatt",
    "One of three brothers in the Old West",
    "O.K. Corral figure",
    "Lawman Wyatt",
    "O.K. Corral gunslinger",
    "O.K. Corral fighter",
    "Marshal played by Fonda, Costner and Lancaster",
    "Lawman Wyatt",
    "Lawman Wyatt",
    "Film role for Russell in 1993 and Costner in 1994",
    "\"Deadwood\" figure",
    "Wyatt of the Wild West",
    "Lawman Wyatt",
    "Clanton rival",
    "Tombstone lawman",
    "O.K. Corral lawman",
    "Tombstone lawman",
    "Friend of Masterson",
    "O.K. Corral lawman",
    "1994 Costner role",
    "Clanton nemesis",
    "O.K. Corral figure",
    "Historical character in John Ford's \"My Darling Clementine\"",
    "Tombstone name",
    "1994 Costner role",
    "Wyatt of the West",
    "\"Gunfight at the O.K. Corral\" role",
    "Subject of TV's \"Life and Legend,\" 1955-61",
    "O.K. Corral shooter",
    "Lawman Wyatt",
    "Frontier figure",
    "O.K. Corral figher",
    "1957 Lancaster role",
    "Foe of the Clantons",
    "Holliday partner",
    "Marshal Wyatt",
    "Tombstone name",
    "Clanton foe",
    "Russell's \"Tombstone\" role",
    "The marshal in \"Frontier Marshal\"",
    "Tombstone lawman",
    "Clanton gang foe",
    "Holliday companion",
    "O.K. Corral fighter",
    "O.K. Corral fighter",
    "Marshal Wyatt",
    "Frontier sheriff",
    "See 30 Down",
    "Fonda in \"My Darling Clementine\"",
    "Clanton foe of 1881",
    "Legendary gunfighter",
    "O.K. Corral figure"
  ],
  "stes": [
    "Fr. religious figures",
    "Divisions of office bldgs.",
    "Fr. honorees",
    "Some Fr. honorees",
    "Heads of some towns in Quebec?: Abbr.",
    "Fr. holy women",
    "Marie et Therese: Abbr.",
    "Some Fr. honorees",
    "They may be prayed to in Fr.",
    "Jeanne et Julie, e.g.: Abbr.",
    "Images on windows of une cathedrale: Abbr.",
    "Fr. holy women",
    "Women with aureoles: Abbr.",
    "Marie and Julie: Abbr.",
    "Jeanne d'Arc et al.: Abbr.",
    "Some Fr. honorees",
    "Some martyrs: Abbr.",
    "Marie and others: Abbr.",
    "Honorees in l'Eglise catholique: Abbr.",
    "Fr. holy women",
    "Fr. holy women",
    "Fr. holy women",
    "Rel. figures",
    "Holy ones: Abbr.",
    "Fr. holy women",
    "Therese and others: Abbr.",
    "Fr. honorees: Abbr.",
    "Holy Fr. women",
    "Jeanne d'Arc et al.: Abbr.",
    "Some Fr. nuns",
    "Some Fr. martyrs",
    "French honorees: Abbr.",
    "Jeanne d'Arc et al.: Abbr.",
    "Fr. holy women",
    "Fr. holy women",
    "Holy Fr. ladies",
    "French holy women: Abbr.",
    "Marie and others: Abbr.",
    "Fr. holy women",
    "Fr. holy women",
    "Genevieve and others: Abbr.",
    "Jeanne d'Arc and others: Abbr.",
    "Agathe and others: Abbr.",
    "French holy women: Abbr.",
    "Fr. holy women",
    "Starts of many Quebec place names",
    "Fr. holy women",
    "\"Therese and others: Abbr."
  ],
  "tyco": [
    "Tickle Me Elmo toymaker",
    "Onetime maker of Matchbox cars",
    "Mattel subsidiary that got its start in model trains",
    "Toy company acquired by Mattel in 1997",
    "Tickle Me Elmo maker",
    "Classic model train brand",
    "Tickle Me Elmo manufacturer",
    "Tickle Me Elmo maker",
    "Company acquired by Mattel in 1997",
    "Longtime Magic 8 Ball maker",
    "___ Toys, maker of the Magic 8-Ball"
  ],
  "meek": [
    "Like a shrinking violet",
    "Compliant",
    "Like a milquetoast",
    "\"The ___ shall inherit the earth\"",
    "Easily pushed around",
    "Submissive",
    "Not assertive",
    "Unrebellious",
    "Submissive",
    "Easy to walk all over",
    "Easily walked over",
    "Submissive",
    "Timid",
    "Deferential",
    "Lamblike",
    "Timid",
    "Overly compliant",
    "Easily cowed",
    "Easily imposed upon",
    "Docile",
    "Overly docile",
    "Humbly patient",
    "Like a milquetoast",
    "Docile",
    "Hardly warlike",
    "Submissive",
    "Deferential",
    "Wimpish",
    "Unlikely to raise a ruckus",
    "Pacific",
    "Not bold",
    "Proverbial heirs",
    "Hardly demanding",
    "Overly docile",
    "Shrinking",
    "Proverbial inheritors",
    "Like a milquetoast",
    "Biblical heirs, with \"the\"",
    "Undemanding",
    "Earth inheritors, with \"the\"",
    "Easily imposed upon"
  ],
  "woes": [
    "Troubles",
    "Toil and trouble",
    "Heavy burdens",
    "Ills",
    "Sob story subjects",
    "Troubles",
    "Troubles",
    "Troubles",
    "Reasons to despair",
    "Trials and tribulations",
    "Email virus, power outage, etc.",
    "Parts of a sob story",
    "Troubles",
    "Difficulties",
    "Misfortunes",
    "Headaches",
    "Reasons to cry",
    "Sorrows",
    "Things to worry about",
    "Depression causes",
    "Tribulations",
    "Melancholiac's list",
    "Bounced checks, hangnails, etc.",
    "Trials",
    "Griper's litany",
    "Torments",
    "Troubles",
    "Calamities",
    "Things to stew about",
    "Miseries",
    "Troubles",
    "Misfortunes",
    "Bounced checks, hangnails, etc.",
    "Afflictions",
    "Troubles",
    "Trials and tribulations",
    "Maladies, worries, etc.",
    "Troubles",
    "Trials"
  ],
  "cyte": [
    "Suffix meaning \"cell\"",
    "Suffix meaning \"cell\"",
    "Suffix with spermato-",
    "Suffix with adipo-",
    "Cell suffix",
    "Biological suffix",
    "Cellular suffix",
    "Cell suffix"
  ],
  "lorn": [
    "Bereft, old-style",
    "Forsaken",
    "Bereft, old-style",
    "Desolate",
    "See 18-Down",
    "Forsaken",
    "Forsaken",
    "Desolate",
    "Forsaken",
    "Bereft",
    "Forsaken",
    "Forsaken"
  ],
  "tcby": [
    "Frozen dessert chain",
    "Frozen dessert chain",
    "Frappe Chiller offerer",
    "Frozen dessert franchise",
    "Soft-serve chain",
    "Frozen dessert chain",
    "Dessert chain",
    "Super Fro-Yo seller",
    "Leading inits. in frozen desserts",
    "Frozen dessert brand owned by Mrs. Fields",
    "Chain selling desserts",
    "Franchise offering \"soft serve\" and \"hand scooped\"",
    "Frozen dessert franchise",
    "Tastee-Freez alternative",
    "National frozen dessert chain",
    "Frozen dessert chain since 1981",
    "Frozen dessert chain",
    "Big seller of smoothies",
    "Popular frozen dessert chain",
    "Store that offers Moo Malts"
  ],
  "shea": [
    "Mets' venue before Citi Field",
    "Kind of butter used in cosmetics",
    "Onetime Queens stadium",
    "Mets' former ballpark",
    "Mets' old stadium",
    "Queens stadium that was 14-Across in 2008-09",
    "Citi Field predecessor",
    "Old home of the Mets and the Jets",
    "Mets' home before Citi Field",
    "Kind of butter used in lip gloss",
    "___ butter (moisturizer)",
    "Stadium demolished in 2009",
    "Citi Field precursor",
    "Stadium closed in 2008",
    "Where 24-Down began his managerial career",
    "1965 and 1966 concert site for this puzzle's subjects",
    "Former home of the Mets",
    "New York Jets home from 1964 to 1983",
    "Where the Mets once met",
    "Home to the 90-Down, once",
    "Former home of the Mets",
    "Old home for Mr. Met",
    "2008 demolition target",
    "2000 World Series locale",
    "Mets' ballpark until 2008",
    "Jets used to make touchdowns there",
    "2000 World Series locale",
    "Where the Beatles opened their 1965 North American tour",
    "Citi Field predecessor",
    "Where the Mets once met",
    "Former home of the 64-Across",
    "Bygone stadium",
    "Locale for four World Series",
    "Jets used to be seen there",
    "Seaver once called it home",
    "New York stadium",
    "Flushing stadium",
    "Attorney William after whom a stadium is named",
    "1969 and 2000 World Series venue",
    "Ballpark near La Guardia",
    "Home to the 29-Down",
    "Sight from New York's Grand Central Parkway",
    "Sports venue seen from the Grand Central Parkway",
    "Big Apple park",
    "Stadium near La Guardia",
    "Big Apple park",
    "Queens stadium",
    "Home of a '69 \"miracle\"",
    "2000 World Series locale",
    "View from New York's Grand Central Parkway",
    "Baseball park",
    "Queens ball park",
    "Where the 2000 World Series ended",
    "Where the Mets can be met",
    "Queens stadium",
    "Park opened in 1964",
    "Ballpark near Ashe Stadium",
    "Big Apple ballpark",
    "Queens plate setting",
    "Where the Mets play",
    "Queens diamond holder",
    "Flushing site",
    "Long Island Railroad stop",
    "Queens's ___ Stadium",
    "Queens place",
    "2000 World Series venue",
    "Where the Mets play",
    "1997 Jackie Robinson commemoration site",
    "2000 World Series locale",
    "Valentine's place",
    "Queens stadium name",
    "1986 World Series site",
    "Mets' home",
    "Stadium near the Grand Central Parkway",
    "Polo Grounds replacement",
    "Flushing field",
    "Where the 1986 World Series ended",
    "Stadium that seats 55,000+",
    "Stadium seen from the Van Wyck Expressway",
    "It erupted on October 27, 1986",
    "1973 World Series stadium",
    "It seats 55,601",
    "Mets stadium",
    "Where Dwight Gooden once pitched",
    "Park near Flushing Bay",
    "Where Mays wrapped up his career",
    "Strawberry's field, once",
    "1966 Beatles concert site",
    "Where the 1986 World Series was won",
    "Met home",
    "Where the Mets play",
    "1986 World Series stadium",
    "1973 World Series stadium",
    "Queens stadium",
    "Where the Mets meet",
    "Flushing stadium",
    "Stadium since 1964",
    "Mets stadium",
    "Where to meet the Mets",
    "Mets' home",
    "Queens park",
    "Stadium seating 55,601",
    "Met's home",
    "Diamond of Queens",
    "Mets stadium",
    "Where some N.L. pitchers wind up",
    "Queens stadium",
    "Met home",
    "N.L. stadium",
    "Mets milieu",
    "1969 World Series stadium",
    "Actor John of \"Missing\"",
    "Queens stadium"
  ],
  "cabo": [
    "___ San Lucas (Mexican resort city)",
    "Mexican resort area, for short",
    "___ San Lucas (Baja resort city)",
    "Baja resort",
    "___ San Lucas, Mexico",
    "Popular Mexican resort, for short",
    "Baja resort area",
    "Baja vacation spot, familiarly"
  ],
  "lang": [
    "\"Auld ___ Syne\"",
    "A.P. English subj.",
    "A.P. ___ (high school English course, for short)",
    "Helmut of fashion",
    "\"State Fair\" director Walter",
    "Fritz ___, noir director",
    "Singer k. d.",
    "Early filmmaker Fritz",
    "Designer Helmut",
    "Grammy-winning blues guitarist Jonny",
    "Singer with lowercase initials",
    "Director Fritz",
    "Filmmaker Fritz",
    "Singer k. d. ___",
    "Fritz who directed \"Metropolis\"",
    "Eng. or Span.",
    "January 1 song word",
    "\"The King and I\" film director",
    "\"The King and I\" film director",
    "\"Metropolis\" director",
    "\"Metropolis\" director Fritz",
    "\"Metropolis\" director Fritz",
    "Singer k. d. ___",
    "Singer k. d. ___",
    "Singer k. d. ___",
    "\"M\" director Fritz",
    "\"M\" director, 1931",
    "Country singer k. d. ___",
    "Director of the \"Dr. Mabuse\" films",
    "\"The Big Heat\" director",
    "Singer k.d.",
    "\"Constant Craving\" singer, 1992",
    "Director Fritz",
    "\"Shadowland\" singer",
    "\"Ministry of Fear\" director"
  ],
  "aset": [
    "For ___ amount of time",
    "Play ___ (perform with the band)",
    "\"I hate to break up ___\"",
    "At ___ time (prearranged)",
    "Play ___ (enjoy some tennis)",
    "Play ___ (do some tennis)",
    "\"I'd hate to break up ___\"",
    "Play ___ (perform some songs)",
    "\"Win ___ of ...\" (contest come-on)",
    "Play ___ (do some tennis)",
    "Play ___ (enjoy some tennis)",
    "Warhol's \"___ of Six Self-Portraits\"",
    "\"___ of Six\" (Joseph Conrad story collection)",
    "\"I'd hate to break up ___\"",
    "\"___ of Six\" (Joseph Conrad story collection)",
    "\"I'd hate to break up ___\"",
    "\"I'd hate to break up ___\"",
    "Playing ___ (court activity)",
    "Play ___ (do some tennis)",
    "\"I'd hate to break up ___\"",
    "\"I'd hate to break up ___\""
  ],
  "hats": [
    "Overhead expenses?",
    "Derbies, e.g.",
    "\"___ off to you!\"",
    "Multiple jobs, metaphorically",
    "Covers for locks",
    "\"___ off!\" (phrase of homage)",
    "They're not tipped very much nowadays",
    "Receptacles for street performers",
    "Things going to your head?",
    "Top gear",
    "Roles, metaphorically",
    "Bowler and sailor",
    "\"___ off!\"",
    "Bowlers and skimmers",
    "Roles, figuratively",
    "Busby and derby",
    "They have crowns",
    "Roles, figuratively",
    "Bowlers that don't bowl",
    "They may be thrown into the ring",
    "Occupiers of top spots",
    "Milliners' output",
    "Millinery",
    "Gentlemen tipped theirs to ladies, once",
    "Boaters and such",
    "Porkpies and panamas",
    "Boaters and bowlers",
    "Tops",
    "Stetsons, e.g.",
    "Panama and porkpie",
    "Responsibilities, so to speak",
    "Jobs, metaphorically",
    "Bowlers and boaters",
    "They may be cocked",
    "Symbols of office",
    "Busbies, boaters and billycocks",
    "Bowler and boater",
    "Stetsons and stovepipes",
    "Bowlers",
    "Millinery",
    "Jobs, so to speak",
    "Pillboxes, e.g.",
    "Bowlers",
    "Derbies",
    "Boater and sailor",
    "Checked items",
    "They go over your part",
    "Derbies",
    "Items at a lost-and-found",
    "Jobs, figuratively",
    "Some ring occupants",
    "Ring contents?",
    "Bretons, e.g.",
    "Items in the ring",
    "\"___off!\" (\"Congrats!\")",
    "Rug covers",
    "Haberdasher's wares",
    "Vocational identifiers",
    "Boaters"
  ],
  "ende": [
    "Michael who wrote \"The Neverending Story\"",
    "Frankish finish",
    "Michael who wrote \"The Neverending Story\"",
    "Fantasy writer Michael",
    "Michael who wrote \"The Neverending Story\"",
    "German fantasy writer Michael",
    "\"The Neverending Story\" writer",
    "German finale",
    "\"The Neverending Story\" author Michael",
    "\"The Neverending Story\" author",
    "Conclusion, in Germany",
    "\"The Neverending Story\" author Michael",
    "\"Momo\" author Michael ___",
    "Frankfurt finale",
    "\"The NeverEnding Story\" writer",
    "\"Momo\" author Michael",
    "Michael who wrote \"The Neverending Story\"",
    "\"Momo\" author Michael",
    "\"Momo\" author Michael",
    "Finis, in Frankfurt",
    "\"The NeverEnding Story\" writer",
    "\"Momo\" author Michael",
    "\"The Neverending Story\" writer"
  ],
  "penh": [
    "Phnom ___, Cambodia",
    "Phnom ___ (capital of Cambodia)",
    "Phnom ___",
    "Phnom ___",
    "End of an Asian capital's name",
    "Phnom ___",
    "Phnom ___",
    "Phnom ___",
    "Phnom ___",
    "Phnom ___",
    "Phnom ___",
    "Phnom ___",
    "Phnom ___",
    "Part of an Asian capital's name",
    "Phnom ___",
    "See 104-Down",
    "Phnom ___",
    "Phnom ___"
  ],
  "afoe": [
    "\"___ to tyrants, and my country's friend\" (words of Cato in \"Julius Caesar\")",
    "\"He makes no friend who never made ___\": Tennyson"
  ],
  "cmdr": [
    "Naval officer: Abbr.",
    "Spock's rank: Abbr.",
    "Spock, e.g.: Abbr.",
    "Mr. Spock's rank: Abbr.",
    "Nav. leader",
    "Naval officer: Abbr.",
    "U.S.N. position",
    "One reporting to a capt.",
    "U.S.N. rank",
    "Naval leader: Abbr.",
    "U.S.N. rank below Capt.",
    "Naval position: Abbr.",
    "U.S.N. rank"
  ],
  "feed": [
    "Series of posts on social media",
    "Word with chicken or news",
    "Twitter feature",
    "Farm store purchase",
    "Facebook ___ (collection of posts)",
    "Oats, for a horse",
    "Video ___",
    "Twitter ___ (news source)",
    "Oats, e.g.",
    "Stable supply",
    "Grain, e.g.",
    "Graze (on)",
    "Audio ___",
    "Network signal",
    "Nourish",
    "Barn sackful",
    "Broadcast signal",
    "Oats, to Trigger",
    "Nourish",
    "Insert coins in, as a parking meter",
    "Stoke",
    "Serve supper to",
    "Chicken kernels?",
    "Rancher's purchase",
    "Satellite transmission",
    "Oats and the like",
    "Oats, possibly",
    "Graze",
    "Oats, e.g.",
    "Corn, to chickens",
    "Oats for horses, say",
    "Grain for farm animals",
    "Nurse",
    "Network transmission",
    "Nourish",
    "Satellite broadcast",
    "Oats",
    "Oats for horses",
    "Oats, e.g."
  ],
  "baez": [
    "She performed while six months pregnant at Woodstock",
    "She performed \"We Shall Overcome\" at the 1963 March on Washington",
    "Folk singer and protester Joan",
    "Female singer inducted into the Rock and Roll Hall of Fame in 2017",
    "Joan who sang at Woodstock",
    "\"And a Voice to Sing With\" memoirist",
    "\"Love Is Just a Four-Letter Word\" singer",
    "Performer at 1963's March on Washington",
    "Folk singer Joan",
    "Woodstock artist who performed while six months pregnant",
    "Singer of many Dylan songs",
    "Guthrie's follower at Woodstock",
    "\"We Shall Overcome\" singer",
    "Joan with a guitar",
    "Joan who sang at Woodstock",
    "Woodstock folk singer",
    "\"And a Voice to Sing With\" memoirist",
    "Dylan was once her protege",
    "Joan at Woodstock",
    "Guthrie's follower at Woodstock",
    "Folk singer Joan",
    "Female singer at the original Woodstock",
    "\"Diamonds and Rust\" singer, 1975",
    "Singer in Bob Dylan's Rolling Thunder Revue",
    "Singer/activist Joan",
    "Performer of many Dylan songs",
    "59-Across performer",
    "Classic name at the Newport Folk Festival",
    "Newport Folk Festival figure",
    "Folk singer Joan"
  ],
  "bulb": [
    "It's used in a flash",
    "Light source that needs occasional replacement",
    "Light source",
    "Something screwed into a lamp",
    "It may have 40, 60 or 75 watts",
    "Hardware store or nursery purchase",
    "*Replaceable part of a lamp",
    "Shallot, e.g.",
    "Daffodil-to-be",
    "Onion, for one",
    "Sylvania product",
    "Tulip or lily planting",
    "Bottom of a lily",
    "Thermometer's terminus",
    "Onion, for one",
    "Onion, for one",
    "Flower starter",
    "Gardener's purchase",
    "Gardener's purchase",
    "Iris's base",
    "Thermometer part",
    "Tulip planting",
    "It's often screwed up",
    "Tulip's base",
    "Future flower"
  ],
  "wads": [
    "Scrunches up",
    "Walletfuls",
    "Mouthfuls of chewing gum",
    "Tobacco chewers' chews",
    "Money rolls",
    "Crumples (up)",
    "Gobs",
    "Rolls of dough",
    "Hunks of chewing tobacco",
    "Fistfuls of money",
    "Lots of moolah",
    "Gum globs",
    "Rolls of dough",
    "Bankrolls",
    "Fistfuls, say",
    "Rolls of dough",
    "Balls (up)",
    "Bankrolls",
    "Hunks",
    "Rolls of bills",
    "Mouthfuls of gum",
    "Bankrolls",
    "Bankrolls",
    "Rolls",
    "Bankrolls",
    "Great sums of money",
    "Rolls",
    "Crumples",
    "Bankrolls"
  ],
  "poly": [
    "Lead-in to amorous",
    "Synthetic fiber, for short",
    "Cal ___",
    "Part of many a tech school's name",
    "Cal ___",
    "With 19-Across, domain of civics, in brief",
    "Roly-___",
    "Prefix with graph",
    "Cal ___",
    "Prefix with ethnic"
  ],
  "faye": [
    "Actress Alice of old Hollywood",
    "Actress Marsay of \"Game of Thrones\"",
    "Dunaway of \"Chinatown\"",
    "Dunaway of \"Chinatown\"",
    "Tammy ___ of 1970s-'80s TV",
    "Actress Dunaway",
    "Alice of \"Hollywood Cavalcade\"",
    "\"The Phil Harris-Alice ___ Show\" of 1940's-50's radio",
    "Mystery novelist Kellerman",
    "Alice of old musicals",
    "Feminist Wattleton"
  ],
  "rhos": [
    "Density symbols",
    "Some sorority chapters",
    "Density symbols",
    "P's",
    "Symbols of density",
    "Greek P's",
    "Greek R's",
    "Fraternity letters",
    "What are Greek P's?",
    "They precede sigmas",
    "Greek R's",
    "Sorority letters",
    "Fraternity letters",
    "Letters after pis",
    "P's, but not Q's",
    "Fraternity letters",
    "P P P, in Greek",
    "Greek R's",
    "P's, to Pericles",
    "Preceders of sigmas",
    "P's",
    "Followers of pis",
    "Fraternity letters",
    "Greek R's",
    "Fraternity letters",
    "Fraternity characters",
    "P P P",
    "Fraternity letters",
    "Greek letters",
    "Density symbols, in physics",
    "Pi followers",
    "Pythagorean P's",
    "Frat letters",
    "Sorority letters",
    "Greek letters",
    "Fraternity letters",
    "Platonic P's",
    "Greek letters",
    "Letters from Greece",
    "Greek letters",
    "Greek letters",
    "Greek letters"
  ],
  "wifi": [
    "Connection you might miss while flying?",
    "Free offering from a cafe",
    "What'll help you see the sites?",
    "Flight amenity that costs extra",
    "Surfing aid",
    "Service symbolized by a dot and three curved lines",
    "Airport amenity",
    "Modern flight amenity",
    "It may require a password",
    "Boingo service at airports",
    "Surcharge item in many hotels",
    "Provider of a hot spot at a coffee shop?",
    "Internet cafe connection",
    "Coffee shop convenience for a laptop",
    "Internet cafe offering",
    "Internet cafe offering",
    "Internet connection at a restaurant or airport",
    "Modern kind of network",
    "Kind of computer connection"
  ],
  "bens": [
    "$100 bills, in slang",
    "Uncle ___",
    "Uncle ___",
    "Uncle ___",
    "Uncle ___",
    "Uncle ___ rice",
    "Uncle ___",
    "Uncle ___ rice",
    "Uncle ___",
    "Uncle ___",
    "Uncle ___",
    "Uncle ___",
    "Blue and Cross",
    "Kingsley et al.",
    "Writer Hecht and others"
  ],
  "pike": [
    "Fish with a pointed snout",
    "Diving position",
    "Pointy-headed fish",
    "*Weapon with a point",
    "Gefilte fish fish",
    "Long spear",
    "Gymnastics position",
    "Toll road",
    "Lake catch",
    "Diving position",
    "Highway",
    "Anti-cavalry weapon",
    "Enterprise captain prior to Kirk",
    "Main route",
    "Explorer Zebulon",
    "Most of I-76 across Pennsylvania",
    "He reached his peak in 1806",
    "Expressway",
    "Toll road",
    "Gymnastics position",
    "Toll road",
    "Long-snouted fish",
    "Infantry weapon of old",
    "Main road",
    "Peak discoverer",
    "Diving maneuver",
    "Scafell ___ (highest point in England)",
    "Diving position",
    "He reached his peak in 1806"
  ],
  "hips": [
    "Source of power for a golf swing",
    "Coxae, familiarly",
    "Where hands go in the Time Warp dance",
    "Things that swing at a swing dance",
    "Handholds while slow-dancing",
    "\"___ Don't Lie\" (2006 Shakira hit)",
    "Where holsters go",
    "They \"don't lie,\" in a #1 Shakira hit",
    "Hands-on sites?",
    "They move around a lot at a square dance",
    "Hula dancers shake them",
    "Disco swingers?",
    "Hula dancers wiggle them",
    "They may swivel",
    "Places for holsters",
    "Places for holsters",
    "Elvis's swivelers",
    "Hula shakers",
    "Elvis moved his, famously",
    "Part of 36-24-36",
    "One Miss America measurement",
    "Frequent reduction targets",
    "Coxae"
  ],
  "huts": [
    "Shacks",
    "Humble homes",
    "Calls from quarterbacks",
    "They often precede hikes",
    "Dwellings for castaways",
    "Preceder of snaps",
    "\"Survivor\" quarters",
    "Humble dwellings",
    "Humble dwellings",
    "Makeshift housing",
    "QB calls",
    "Gridiron cues",
    "\"Survivor\" homes",
    "Sheds",
    "Simple housing",
    "Preceders of snaps",
    "\"Gilligan's Island\" dwellings",
    "Hogans",
    "Primitive homes",
    "Humble homes",
    "Rancho units",
    "A lot of a drill sergeant's drill",
    "Shacks",
    "\"Gilligan's Island\" homes",
    "Lowly homes"
  ],
  "cong": [
    "Capitol Hill org.",
    "D.C. body",
    "Viet ___",
    "Viet ___",
    "Workplace for Reps. and Dems.",
    "D.C. group",
    "Attached houses?: Abbr.",
    "Reps. and Sens."
  ],
  "gars": [
    "Fish with long jaws",
    "Needlefish",
    "Needle-nosed fish",
    "Elongated, heavily armored fish",
    "Long-jawed fishes",
    "Needle-nosed swimmers",
    "Needlefish",
    "Pike lookalikes",
    "Long-jawed fishes",
    "Needlefish"
  ],
  "inst": [
    "Part of M.I.T.: Abbr.",
    "The \"I\" of M.I.T.: Abbr.",
    "The \"I\" of M.I.T.: Abbr.",
    "Research org.",
    "Think tank, e.g.: Abbr.",
    "Caltech, e.g.: Abbr.",
    "Smithsonian, e.g.: Abbr.",
    "M.I.T. part: Abbr.",
    "Think tank, e.g.: Abbr.",
    "Part of V.M.I.: Abbr.",
    "Research center: Abbr.",
    "Phila.'s Franklin ___",
    "Part of M.I.T.: Abbr.",
    "The \"I\" of M.I.T.: Abbr.",
    "The \"I\" in M.I.T.: Abbr.",
    "Center of learning: Abbr.",
    "M.I.T. part: Abbr.",
    "Scholarly org.",
    "The \"I\" of R.P.I.: Abbr.",
    "Part of 58-Down: Abbr.",
    "Place of research: Abbr.",
    "Sch. or hosp.",
    "Franklin in Phila., e.g.",
    "Amer. Film ___ (annual awards grp.)",
    "Tech., for one",
    "Tech. school",
    "The \"I\" in M.I.T.: Abbr.",
    "Research venue: Abbr.",
    "Many a D.C. org.",
    "Part of M.I.T.: Abbr.",
    "Acad.",
    "Mass. ___ of Tech.",
    "Part of M.I.T.: Abbr.",
    "Part of M.I.T.: Abbr.",
    "One may be technical: Abbr.",
    "M.I.T. part: Abbr.",
    "R & D site",
    "Acad.",
    "Philadelphia?Æs Franklin ___: Abbr.",
    "Part of V.M.I.: Abbr.",
    "Research facility: Abbr.",
    "Part of N.I.H.: Abbr.",
    "M.I.T. part: Abbr.",
    "Lab site: Abbr.",
    "Education location: Abbr.",
    "Research facil.",
    "Research facil.",
    "Research facility: Abbr.",
    "Technical sch.",
    "Acad.",
    "Research facility: Abbr.",
    "Research facility: Abbr.",
    "Tech. school",
    "Moment: Abbr.",
    "Scholarly org.",
    "Educational org.",
    "Think tank: Abbr.",
    "Technical sch.",
    "Split sec."
  ],
  "jock": [
    "Varsity letter earner, say",
    "Athletic type ... or athletic wear",
    "B.M.O.C., typically",
    "Musclehead",
    "Varsity letter earner",
    "Enthusiast, informally",
    "Letter getter",
    "Athlete",
    "Bookworm's counterpart",
    "Athletic sort"
  ],
  "wind": [
    "Word before chill or chimes",
    "Air condition?",
    "Tucker out, in a way",
    "Notable feature of Chicago",
    "Kite flier's need",
    "Empty talk",
    "Make tired",
    "Kiting necessity",
    "Oboe, e.g.",
    "Chimes player",
    "Empty talk",
    "Chicago phenomenon",
    "Flag waver?",
    "Weather vane turner",
    "Gale",
    "\"Inherit the___\"",
    "Crank (up)"
  ],
  "ukes": [
    "Luau instruments, for short",
    "Some of them come in \"pineapple\" and \"soprano\" varieties, informally",
    "Strummed instruments, for short",
    "Island instruments, for short",
    "Instruments played close to the chest, informally",
    "Luau music makers, for short",
    "Luau staples, for short",
    "Relatives of banjoleles",
    "Luau entertainers",
    "Strummed instruments, for short",
    "They may be made with koa wood, briefly",
    "Island music makers, for short",
    "Hawaiian tourist purchases",
    "What some strummers strum, informally",
    "Luau strings",
    "Hawaiian strings, informally",
    "Entertainment at a luau",
    "Soprano pineapple and others, briefly",
    "Strings at luaus",
    "Strummed instruments, for short",
    "Hawaiian instruments, informally",
    "Island instruments, for short",
    "Luau instruments, for short",
    "Luau strings",
    "Maui music makers",
    "Luau instruments, informally",
    "Hawaiian strings, informally",
    "Maui music makers",
    "Hawaiian strings",
    "They?Ære picked in grass skirts",
    "Luau instruments, for short",
    "Strummed instruments",
    "Instruments at luaus",
    "Cousins of banjos",
    "Instruments that may be made from monkeypod wood",
    "Little strings?",
    "Luau instruments",
    "Hawaiian musicmakers",
    "Luau strings",
    "They're played at a 25-Down",
    "They're picked in Hawaii",
    "Instruments from 119-Across",
    "Instruments that are strummed",
    "Banjos' kin",
    "Sounds from Hawaii",
    "Popular college items of the 20's",
    "Maui strummers",
    "Little guitars",
    "Hawaiian music makers",
    "Plunked items",
    "\"Under Hawaiian Skies\" accompanists"
  ],
  "tyre": [
    "One of a set of four on a London taxi",
    "Lebanese city on the Mediterranean",
    "Purchase for a lorry",
    "\"Pericles, Prince of ___\"",
    "Part of a 17-Across",
    "Lebanese city that was once the center of Phoenician civilization",
    "Pericles' domain, in Shakespeare",
    "Roller on a carriageway",
    "Phoenician port",
    "It rolls on a Rolls",
    "Port on the eastern Mediterranean",
    "One of four on a Rolls",
    "Home to a Shakespearean prince",
    "Ancient city whose name means \"rock\"",
    "Spare in a boot",
    "It may be kept in a boot",
    "Rolls roller",
    "Phoenician trading center",
    "It rolls on a Rolls",
    "Radial for a Jaguar, e.g.",
    "Phoenician trading center",
    "It's inflated in England",
    "It may be kept in a boot",
    "Place for a London flat",
    "Phoenician port",
    "Storied Phoenician port",
    "King Hiram's home",
    "Biblical city of King Hiram",
    "Whitewall, in Whitehall",
    "Phoenician city",
    "City in the Crusades",
    "Home of Jezebel"
  ],
  "porn": [
    "X-rated stuff",
    "Internet content typically viewed alone",
    "Steamy fare",
    "Subject of a certain addiction",
    "Lucrative Internet biz",
    "NSFW material",
    "YouTube no-no",
    "Nonlibrary reading",
    "Feelthy stuff",
    "X-rated stuff",
    "No-no on office computers",
    "Blue matter",
    "Blue books",
    "V-chips block it",
    "V-chips block it",
    "Smut",
    "Smut"
  ],
  "swig": [
    "Big gulp from a bottle",
    "Gulp",
    "Big gulp",
    "Big gulp",
    "Draft",
    "Big gulp",
    "Gulp from a flask",
    "Gulp from a flask",
    "Giant gulp, as from a bottle",
    "Big swallow",
    "Draft",
    "Drink, as from a bottle",
    "More than a sip",
    "Gulp from a bottle",
    "Great gulp",
    "Deep draft",
    "Deep drink",
    "Big gulp",
    "More than a nip",
    "Drink from a flask",
    "Gulp of liquor",
    "Small belt",
    "Mouthful of moonshine",
    "Draft",
    "Take a shot?",
    "Gulp down",
    "Belt"
  ],
  "pyle": [
    "1960s TV's \"Gomer ___, U.S.M.C\"",
    "Ernie ___, Pulitzer-winning journalist of W.W. II",
    "Nabors title role of 1960s TV",
    "Journalist who wrote the 1943 book \"Here Is Your War\"",
    "Gomer of Mayberry",
    "Writer Ernie",
    "\"The Merry Adventures of Robin Hood\" author Howard",
    "Nabors role",
    "Nabors role",
    "One of Carter's charges, on TV",
    "\"Here Is Your War\" author",
    "1944 Pulitzer correspondent",
    "War correspondent Ernie",
    "W.W. II journalist Ernie",
    "TV actor Denver",
    "G.I. journalist",
    "\"Brave Men\" writer, 1944",
    "\"Here Is Your War\" author",
    "\"Gomer ___, U.S.M.C.\"",
    "Nabors role",
    "Writer Ernie",
    "Mayberry's Goober",
    "1944 Pulitzer journalist",
    "See 49-Down",
    "Winner of a 1944 Pulitzer",
    "Denver of \"The Dukes of Hazzard\"",
    "\"Here Is Your War\" author",
    "TV marine",
    "W.W. II newsman Ernie",
    "\"Brave Men\" author, 1944",
    "Children's author Howard",
    "1944 Pulitzer journalist"
  ],
  "vast": [
    "Oceanic",
    "Limitless",
    "Like the ocean",
    "Widespread",
    "Widespread",
    "Encyclopedic",
    "Seemingly endless",
    "Immense",
    "Encyclopedic",
    "Sweeping",
    "Sweeping",
    "Boundless",
    "Oceanic in scope",
    "Seemingly limitless",
    "Extensive",
    "Seemingly endless",
    "Wide as the ocean",
    "Seemingly boundless",
    "Huge",
    "Widespread",
    "Widespread",
    "Seemingly boundless",
    "Immense",
    "Like tundras",
    "Broad",
    "Like the Great Plains",
    "Sweeping",
    "Wide-ranging",
    "Huge",
    "Kind of improvement",
    "Huge",
    "Infinite",
    "Extensive",
    "Like Asia's reaches",
    "Like the Sahara",
    "Like outer space",
    "Measureless",
    "Like all outdoors"
  ],
  "ilea": [
    "Intestinal neighbors of jejuna and ceca",
    "Guts, in part",
    "Parts of small intestines",
    "They're near appendices",
    "Sections of digestive tracts",
    "Parts of gastrointestinal tracts",
    "Intestinal parts",
    "Intestinal parts",
    "Lower small intestines",
    "Intestinal parts",
    "Intestinal parts",
    "Intestinal parts",
    "Intestinal parts",
    "Intestinal sections",
    "Jejuna neighbors",
    "Jejuna neighbors"
  ],
  "agni": [
    "Hindu god of fire",
    "Lambs, to Lucius",
    "Latin lambs",
    "Lambs: Lat.",
    "Lambs: Latin",
    "Lambs: Lat.",
    "Lambs: Lat.",
    "Lambs, to Livy"
  ],
  "chis": [
    "XXX",
    "XXX",
    "X X X",
    "XXX",
    "Parts of some chapter names",
    "X's",
    "XXX",
    "XXX abroad",
    "X's, in Greece",
    "XXX",
    "Sigma ___ (fraternity men)",
    "XXX, in a way",
    "Psis' predecessors",
    "X's"
  ],
  "homo": [
    "Part of who you are?",
    "Man, to Marcus Aurelius",
    "Genus that includes geniuses",
    "___ floresiensis (extinct \"hobbit\")",
    "Our kind of people?",
    "___ neanderthalensis",
    "Group of people",
    "Horace man?",
    "Cato's man",
    "38-Across's genus",
    "___ sapiens",
    "Our genus",
    "Our genus",
    "___ sapiens",
    "Human genus",
    "Genus of 115-Across",
    "___ habilis",
    "Primate genus",
    "See 35-Across",
    "___ sapiens",
    "Our genus",
    "___ erectus",
    "Classification in human evolution",
    "Ecce ___",
    "___ sapiens",
    "Our genus"
  ],
  "pint": [
    "Pub purchase",
    "Ben & Jerry's buy",
    "Draft amount, maybe",
    "Word after half or before size",
    "*Resistance units",
    "Little capacity",
    "Pub order",
    "What do four gills make?",
    "See 15-Across",
    "Guinness measurement",
    "Takeout container size",
    "Half-and-half carton, often",
    "Blood donation unit",
    "Pub order",
    "Guinness measure",
    "Pub order",
    "Pub order",
    "Pub quantity",
    "Blood drive donation",
    "Pub serving",
    "Small milk carton capacity",
    "Half a 52-Down",
    "Pub order",
    "Pub serving",
    "Blood donation, maybe",
    "Pub offering",
    "Amount of eggnog",
    "Pub order",
    "Pub purchase",
    "Pub purchase",
    "Two-cupper",
    "Four gills",
    "Pub order",
    "Stout serving",
    "Small amount of milk",
    "Ice cream purchase",
    "Four gills",
    "Pub order",
    "Quaff quantity",
    "Pub order"
  ],
  "atri": [
    "\"The Bell of ___\" (Longfellow poem)",
    "Longfellow's bell town",
    "Abruzzi bell town",
    "Bell town in a Longfellow poem",
    "\"The Bell of ___\" (Longfellow poem)",
    "Longfellow bell town",
    "Italian bell town",
    "Longfellow bell town",
    "Title town of a Longfellow poem",
    "Italian bell town",
    "Longfellow's bell town",
    "Longfellow bell town",
    "Longfellow's bell town",
    "Abruzzi bell town",
    "Bell town in a Longfellow poem",
    "Longfellow's bell town",
    "Longfellow's bell town",
    "Longfellow's bell town",
    "Longfellow's bell town",
    "Picturesque town in 27-Across",
    "Longfellow's bell town",
    "Longfellow's bell town",
    "Longfellow's bell town",
    "Longfellow bell town",
    "Abruzzi bell town",
    "Longfellow bell town",
    "\"Tales of a Wayside Inn\" bell town",
    "Abruzzi bell town",
    "Longfellow's \"The Bell of ___\"",
    "Longfellow's bell town",
    "Longfellow's bell town"
  ],
  "isat": [
    "Attends",
    "\"Time ___ a premium\"",
    "Attends",
    "Attends",
    "\"The hour ___ hand\"",
    "\"Peace ___ hand\"",
    "\"The wolf ___ the door\"",
    "\"Time ___ a premium\"",
    "\"Alice ___ It Again\" (Noel Coward song)",
    "Attends",
    "\"Our quest ___ an end!\"",
    "\"The wolf ___ the door\"",
    "\"Time ___ a premium\"",
    "\"The hour ___ hand\"",
    "\"Time ___ a premium\"",
    "\"The wolf\"/\"the door\" connector",
    "\"Time ___ a premium\"",
    "\"The wolf ___ the door\"",
    "\"Peace ___ hand\"",
    "\"They laughed when ___...\""
  ],
  "fern": [
    "Nonflowering plant",
    "Spore-producing plant",
    "Plant with fronds",
    "Spore producer",
    "Green housewarming gift",
    "Bit of office greenery",
    "Shade of green",
    "Plant with spores",
    "Item by many a reception desk",
    "Leaves in a waiting room?",
    "Bit of office greenery",
    "Frond bearer",
    "Flowerless plant",
    "Bit of office greenery",
    "Office plant",
    "Plant with fronds",
    "\"Charlotte's Web\" girl",
    "Crayola color since 1998",
    "Shedder of spores",
    "Plant with fiddleheads",
    "Decorative plant",
    "Plant with fronds",
    "\"Charlotte's Web\" girl",
    "Pteridologist's specimen",
    "Bit of green in a floral display",
    "Fronded plant",
    "Common green house gift",
    "Adder's-tongue or Venus's-hair",
    "Spore producer",
    "Woods plant",
    "Spore source",
    "Spore producer",
    "See 103-Across",
    "Popular house gift",
    "Plant with fiddleheads",
    "Adder's-tongue, e.g.",
    "Terrarium plant",
    "Bracken, for one",
    "Bit of greenery",
    "Forest plant",
    "Plant with a frond",
    "Forest growth",
    "Fossil impression",
    "Terrarium plant",
    "Moonwort, for one"
  ],
  "ofme": [
    "\"You want a piece ___?\"",
    "\"All ___\" (Steve Martin/Lily Tomlin comedy)",
    "\"You want a piece ___?\"",
    "\"For the life ___ ...\"",
    "\"All ___\" (Tomlin film)",
    "\"How foolish ___!\"",
    "\"For the life ___ ...\"",
    "Steve Martin's \"All ___\"",
    "\"What's to become ___?\"",
    "\"For the life ___...\"",
    "\"Memories___\" (Billy Crystal film)",
    "\"What's to become___?\" (words of despair)",
    "\"All ___,\" (1984 comedy)"
  ],
  "otic": [
    "Ending for patri-",
    "Suffix with hypn-",
    "Suffix with psych-",
    "Suffix with narc-",
    "Ear-related",
    "Ear-related",
    "Ear-related",
    "Ear-related",
    "Suffix with psych-",
    "Suffix with narc-",
    "Ear-related",
    "Ear-related",
    "Suffix with neur-",
    "Ear-related",
    "Like anatomical anvils",
    "Ear-relevant",
    "Ear-related",
    "Ear-related",
    "___ suspension (ear drops)",
    "Ear-related",
    "Suffix with psych-",
    "___ suspension (ear medication)",
    "Of the ears",
    "Suffix with hypn-",
    "Ear-related",
    "Suffix with hypn-",
    "Ear-related",
    "Ear-relevant",
    "Suffix with psych-",
    "Narc tail?",
    "Suffix with neur-",
    "Hearing-related",
    "Suffix with psych-",
    "Ear-related",
    "Ear-related",
    "Suffix with narc",
    "Suffix with neur-",
    "Ear-related",
    "Auditory",
    "Auditory",
    "Suffix with psych-",
    "Auricular",
    "Suffix with psych-",
    "Auditory",
    "Suffix with neur-",
    "Of the ear",
    "Suffix with psych-",
    "Psych final?",
    "Auricular",
    "Auditory",
    "Suffix with psych-",
    "Of the 66-Across",
    "Suffix with psych-",
    "Ear-related",
    "Suffix with psych- or neur-",
    "Ear-related",
    "Auditory",
    "Of the ear",
    "Suffix with psych-",
    "Auricular",
    "Pertaining to the ear",
    "Pertaining to hearing",
    "Auricular",
    "Ear-related",
    "Ear-related"
  ],
  "boer": [
    "___ War (1899-1902)",
    "Afrikaans \"farmer\"",
    "It means \"farmer\" in Afrikaans",
    "Great Trek figure of the 1830s",
    "Two-time belligerent against the British Empire",
    "Paul Kruger of Krugerrand fame, e.g.",
    "Participant in an 1899 conflict",
    "It means \"farmer\" in Afrikaans",
    "Andries Pretorius, e.g., who gave his name to a national capital",
    "Orange Free State settler",
    "Orange Free State settler",
    "Great Trek trekker",
    "Great Trek emigrant",
    "Great Trek figure",
    "Uitlander foe",
    "Transvaal trekker",
    "Afrikaner",
    "Great Trek participant of the 1830s",
    "Cape settler",
    "___ War of 1899",
    "Afrikaner",
    "Great Trek trekker",
    "One who fought Uitlanders",
    "Orange Free State settler",
    "___ War",
    "Afrikaner",
    "Afrikaner",
    "Transvaal settler",
    "Anglo-___ War",
    "Louis Botha, notably",
    "___ War",
    "Afrikaner",
    "1899 warrior",
    "Great Trek participant",
    "Afrikaner",
    "Jan Smuts, for one",
    "Literally, farmer",
    "Combatant of 1899",
    "Afrikaner",
    "Transvaal settler",
    "\"Farmer,\" in Dutch",
    "Afrikaner",
    "Fighter of 1899-1902"
  ],
  "lpns": [
    "Some hosp. workers",
    "Some hosp. staffers",
    "Hosp. employees",
    "Hosp. staffers"
  ],
  "asst": [
    "Aide: Abbr.",
    "Part of P.D.A.: Abbr.",
    "Abbr. in some job titles",
    "Abbr. that begins some entry-level job titles",
    "P.D.A. component: Abbr.",
    "Second person?: Abbr.",
    "Help in a small way?",
    "Number two: Abbr.",
    "Helper: Abbr.",
    "Second hand: Abbr.",
    "Admin. aide",
    "Like some profs.",
    "Deputy: Abbr.",
    "Brief second?",
    "Aide: Abbr.",
    "Helper: Abbr.",
    "Lead-in for prof. or V.P.",
    "Subordinate: Abbr.",
    "Second hand: Abbr.",
    "Kind of prof.",
    "Number two: Abbr.",
    "Helper: Abbr.",
    "Right hand: Abbr.",
    "No. 2",
    "___ prof.",
    "No. 2",
    "Second: Abbr.",
    "Secy., e.g.",
    "Helper: Abbr.",
    "___ supt.",
    "___ D.A.",
    "Part of P.D.A.: Abbr.",
    "No. 2",
    "Secondary: Abbr.",
    "Right hand: Abbr.",
    "Job title abbr.",
    "Part of T.A.: Abbr.",
    "Amanuensis, e.g.: Abbr.",
    "#2: Abbr.",
    "Jr. in an office",
    "Admin. aide",
    "Aide, in ads",
    "___ U.S. atty.",
    "Helper: Abbr.",
    "Aide: Abbr.",
    "Kind of coach: Abbr.",
    "Helper: Abbr.",
    "Aide: Abbr.",
    "Paralegal, for example: Abbr.",
    "Mgr.'s aide",
    "No. 2: Abbr.",
    "Bob Cratchit to Scrooge, e.g.: Abbr.",
    "___ prof.",
    "Secy., e.g.",
    "Staffer: Abbr.",
    "Part of many a job title: Abbr.",
    "Number two: Abbr.",
    "Right hand: Abbr.",
    "Aide: Abbr.",
    "___ prof.",
    "Kind of prof. or D.A.",
    "Deputy: Abbr.",
    "___ mgr.",
    "Igor, to Dr. Frankenstein: Abbr.",
    "Number two: Abbr.",
    "___ mgr.",
    "Support staffer: Abbr.",
    "Kind of ed.",
    "P.D.A. part: Abbr.",
    "Entry-level position: Abbr.",
    "#2: Abbr.",
    "Part of T.A.: Abbr.",
    "Support staffer: Abbr.",
    "Clerk, to a bus. owner",
    "Secy.",
    "Mgr.'s helper",
    "Helper: Abbr.",
    "Kind of producer: Abbr.",
    "Secretary: Abbr.",
    "Deputy: Abbr.",
    "Entry-level position: Abbr.",
    "Second: Abbr.",
    "Kind of prof.",
    "Tel. message taker, maybe",
    "Paralegal, for one: Abbr.",
    "___ prof.",
    "Aide: Abbr.",
    "___ D.A.",
    "Office figure: Abbr.",
    "___ prof.",
    "Kind of D.A.",
    "Secy.",
    "Gofer: Abbr.",
    "___ mgr.",
    "Second hand: Abbr.",
    "Office title abbr.",
    "Aide: Abbr.",
    "One taking tel. messages",
    "___ prof.",
    "Aide: Abbr.",
    "Helper: Abbr.",
    "Aide: Abbr.",
    "Kind of prof.",
    "___ prof.",
    "Kind of principal: Abbr.",
    "Second hand: Abbr.",
    "Secy.",
    "___ prof.",
    "No. 2: Abbr.",
    "___ prof.",
    "___ city atty.",
    "Like some D.A.'s",
    "Gofer: Abbr.",
    "___ prof.",
    "Kind of prof.",
    "Kind of V.P.",
    "___ prof.",
    "Beleaguered exec.'s need",
    "Lieutenant: Abbr.",
    "Aide: Abbr.",
    "Like some D.A.'s",
    "Sec'y",
    "Helper: Abbr.",
    "Like some D.A.'s",
    "Mgr.'s helper",
    "Helper: Abbr.",
    "Like some coaches: Abbr.",
    "Like certain profs.",
    "Aide: Abbr.",
    "Secy., e.g.",
    "Like some D.A.'s",
    "Like some mgrs.",
    "Mgr.'s aide",
    "Subordinate title: Abbr.",
    "Aide: Abbr.",
    "Secy.",
    "Kind of D.A.",
    "No. 2",
    "Like some profs.",
    "Helper: Abbr.",
    "Mgr.'s aide",
    "Secy.",
    "Entourage part: Abbr.",
    "Kind of prof.",
    "Like some profs.",
    "___ prof.",
    "Gal Fri.",
    "Second: Abbr.",
    "Helper, in brief",
    "Little helper?",
    "Helper: Abbr.",
    "Secy.",
    "Off. helper",
    "Kind of D.A.",
    "Helper: Abbr.",
    "Abbr. in an office title",
    "Adjt.",
    "Right-hand man: Abbr.",
    "Second: Abbr.",
    "Like some eds.",
    "Helper: Abbr.",
    "Secretary, e.g.: Abbr.",
    "___ prof.",
    "Like some profs.",
    "Like a V.P.",
    "Helper: Abbr.",
    "Office abbr.",
    "Kind of prof.",
    "Secretary: Abbr."
  ],
  "olio": [
    "Mixture",
    "A little of this, a little of that",
    "This and that",
    "Mishmash",
    "Miscellany",
    "Mishmash",
    "Miscellany",
    "Melange",
    "Jumble",
    "Some of this, some of that",
    "Mishmash",
    "Mishmash",
    "This and that",
    "Potpourri",
    "Mixed bag",
    "Bunch of stuff",
    "Smorgasbord",
    "Odds and ends",
    "Mixed bag",
    "Mixed bag",
    "Goulash",
    "Mishmash",
    "A little of this, a little of that",
    "Diverse grouping",
    "Mixed bag",
    "This and that",
    "Jumble",
    "Potpourri",
    "Scramble",
    "Grab bag",
    "Odd collection",
    "Potpourri",
    "Little of this and that",
    "This and that",
    "Grab bag",
    "Medley",
    "Scramble",
    "Hodgepodge",
    "Mix",
    "Mix",
    "Grab bag",
    "Hodgepodge",
    "Number between scenes",
    "Gallimaufry",
    "This and that",
    "Mixed bag",
    "Mixture",
    "Mix",
    "Program of variety acts",
    "Mishmash",
    "Assortment",
    "Mixed bag",
    "This and that",
    "Mixed bag",
    "This and that",
    "Jambalaya",
    "Melange",
    "Salmagundi",
    "Mixed bag",
    "Hodgepodge",
    "Hodgepodge",
    "Eclectic mix",
    "Mishmash",
    "Hodgepodge",
    "Mishmash",
    "Mix",
    "Mishmash",
    "Mishmash",
    "Mishmash",
    "Variety show fare",
    "Hodgepodge",
    "Potpourri",
    "Mishmash",
    "Assortment",
    "Mixture",
    "String of numbers?",
    "Mixture",
    "Mixed bag",
    "Miscellany",
    "Miscellany",
    "Mishmash",
    "Mishmash",
    "Miscellany",
    "This and that",
    "This and that",
    "Mishmash",
    "Mishmash",
    "Mix",
    "This and that",
    "Varied group",
    "Hash",
    "Hodgepodge",
    "This and that",
    "Hodgepodge",
    "Melange",
    "Miscellany",
    "Mishmash",
    "Stew",
    "Hodgepodge",
    "Aglio e ___ (pasta dressing)",
    "Melange",
    "Mishmash",
    "Mixed bag",
    "This and that",
    "Hodgepodge",
    "Old curiosity shop stock",
    "Medley",
    "A little of this, a little of that",
    "Medley",
    "Hodgepodge",
    "Hodgepodge",
    "Mixed bag",
    "Omnium-gatherum",
    "Mixed bag",
    "Collage",
    "Hodgepodge",
    "Melange",
    "Mixed dish",
    "Mixed bag",
    "Hodgepodge",
    "Mishmash",
    "Melange",
    "This and that",
    "This and that",
    "Haphazard collection",
    "Medley",
    "Mishmash",
    "Crazy quilt",
    "Mishmash",
    "A little of this, a little of that",
    "Mishmash",
    "Hodgepodge",
    "Mishmash",
    "Mixed bag",
    "Mishmash",
    "Spiced stew",
    "Gallimaufry",
    "Mixture",
    "Mishmash",
    "Mishmash",
    "Gallimaufry",
    "Pastiche",
    "Stage curtain",
    "Melange",
    "Hodgepodge",
    "Blend",
    "Miscellany",
    "Mess",
    "Mixed bag",
    "Pastiche",
    "Mixed bag"
  ],
  "gong": [
    "Pagoda instrument",
    "Bit of percussion",
    "Percussion in a pagoda",
    "Signal a performer doesn't want to hear",
    "Percussion in a Buddhist temple",
    "Signal for dinner",
    "One getting hammered",
    "Mallet target",
    "Call for dinner, maybe",
    "Hammer accompanier",
    "Sound heard at the end of \"Bohemian Rhapsody\"",
    "Instrument played with a mallet",
    "Remove from a talent show, maybe",
    "1970s TV's \"The ___ Show\"",
    "Percussion instrument",
    "Pagoda instrument",
    "Instrument hit with a hammer",
    "Signal to leave, perhaps",
    "It may be hit with a hammer",
    "Signal to leave",
    "Signal for an act to end",
    "Alarm sounder",
    "TV's \"The___Show\"",
    "Percussion instrument",
    "Bad news at a talent show"
  ],
  "mymy": [
    "\"Well, I declare!\"",
    "\"Well!\"",
    "\"Well, what do we have here!\"",
    "\"Land o' Goshen!\"",
    "\"Dear me!\"",
    "\"Really!\"",
    "\"Well, I declare!\"",
    "\"Well, I declare!\"",
    "\"Oh, dear!\""
  ],
  "rake": [
    "Tool by a golf bunker",
    "Item for a croupier",
    "Zen garden accessory",
    "Leaf-gathering tool",
    "Collect, with \"in\"",
    "Leaf blower alternative",
    "Croupier's implement",
    "Leaf-gathering tool",
    "Tool with teeth",
    "Fall clearance item?",
    "Libertine",
    "Playboy",
    "Bunker implement",
    "Be a fall guy?",
    "Playboy",
    "No gentleman",
    "Leaf gatherer",
    "Do some fall cleanup",
    "Croupier's tool",
    "Casino collector",
    "Do a fall chore",
    "___ over the coals",
    "Symbol of St. Barnabas, whose saint day comes at hay harvesting time",
    "Make a pile in a casino?",
    "Do some yard work",
    "Autumn tool",
    "Croupier's tool",
    "Bring (in) abundantly",
    "Clean up leaves, e.g.",
    "Don Juan",
    "Make copiously, with \"in\"",
    "Croupier's tool",
    "Croupier's implement",
    "Wolf",
    "Leaf collector",
    "Pile maker",
    "Pile maker",
    "Dredge (up)",
    "Croupier's tool",
    "Lothario",
    "Yard tool",
    "Croupier's tool",
    "Toothed tool",
    "Autumn tool",
    "Tool with teeth",
    "Haymaking aid",
    "Gardener's tool",
    "Shed item",
    "Libertine",
    "Fall tool",
    "Libertine",
    "Casino tool",
    "Don Juan",
    "Garden smoother",
    "Gather leaves",
    "Yard tool",
    "Garden tool",
    "Gather leaves",
    "Womanizer",
    "Fall cleanup need",
    "Garden tool",
    "Toothy tool",
    "Autumn tool",
    "Autumn tool",
    "Garden smoother",
    "Libertine",
    "Search thoroughly",
    "Smooth the flower bed",
    "Gaming table equipment",
    "Roue",
    "Shed item",
    "Hogarth depiction",
    "Toothy tool",
    "It might turn over a new leaf"
  ],
  "vans": [
    "Delivery vehicles",
    "Fleet at a distribution center",
    "They have sliding doors",
    "Needs for some touring bands",
    "Circlers at airports",
    "Movers' vehicles",
    "Popular sneakers",
    "U-Haul rentals",
    "Some shuttles",
    "Movers' trucks",
    "Relocation transportation",
    "Rideshare rides",
    "Plumbers' wheels",
    "Ryder fleet",
    "Contractors usually have them",
    "Hotel transportation",
    "Moving vehicles",
    "U-Haul rentals",
    "Airport shuttles",
    "Moving vehicles",
    "Movers' trucks",
    "Some car-pooling vehicles",
    "Movers' inventory",
    "Some campers",
    "Movers",
    "U-Haul rentals",
    "Delivery vehicles",
    "Movers' trucks"
  ],
  "hems": [
    "Borders of skirts",
    "Adjusts lengthwise, as a skirt",
    "Brings up, say",
    "Clothing lines",
    "Skirt bottoms",
    "Clothes lines?",
    "___ and haws",
    "Maintains the border, say",
    "Borders",
    "Border lines?",
    "Partners of haws",
    "Skirt bottoms",
    "___ and haws",
    "Skirt lines",
    "Does some tailoring",
    "Skirt lines",
    "Clothes lines",
    "Hesitations",
    "Speech pauses",
    "Sewer lines?",
    "Fashion lines",
    "A-lines' lines",
    "Surrounds, with \"in\"",
    "Lines of fashion",
    "Edges",
    "Fixes the length of",
    "Takes up",
    "Haw's partner",
    "Often-changed lines"
  ],
  "lehi": [
    "Where Samson slew the Philistines",
    "Where Samson slew the Philistines",
    "Prophet in the Book of Mormon",
    "City on Utah Lake",
    "With 61-Down, city named for a Book of Mormon prophet",
    "Where Samson slew the Philistines",
    "Where Samson slew the Philistines",
    "Where Samson defeated the Philistines",
    "Where Samson defeated the Philistines",
    "Where Samson slew the Philistines",
    "Where Samson wielded a jawbone, in Judges",
    "Utah town"
  ],
  "tums": [
    "Brand with a jingle to the \"Dragnet\" theme",
    "Big name in antacids",
    "Brand name that spells something not nice backward",
    "Product whose jingle uses the \"Dragnet\" theme",
    "Over-the-counter antacid",
    "Dyspepsia reliever",
    "Rolaids rival",
    "Rolaids competitor",
    "Product of some relief pitches?",
    "Rolaids alternative",
    "Rolaids rival",
    "Rolaids alternative",
    "GlaxoSmithKline brand",
    "Bromo alternative",
    "Rolaids rival",
    "Rolaids rival"
  ],
  "milt": [
    "Jazz's Jackson",
    "Three-time All-Star pitcher Pappas",
    "Jazz vibraphonist Jackson",
    "Jazzman Jackson",
    "Pioneer bop vibist Jackson",
    "Jackson of jazz",
    "Jazz musician Jackson",
    "Cartoonist Gross",
    "Jazz bassist Hinton",
    "Comic Kamen",
    "Cartoonist Gross"
  ],
  "gabs": [
    "Chats away",
    "Chatters",
    "Flaps one's gums",
    "Shoots the breeze",
    "Doesn't shut up",
    "Yaks",
    "Yaks",
    "Chews the rag",
    "Chews the fat",
    "Chews the fat",
    "Ties up a phone line, maybe",
    "Chews the fat",
    "Doesn't shut up",
    "Runs off at the mouth",
    "Yaks",
    "Chatters",
    "Schmoozes",
    "Chews the fat",
    "Gossips",
    "Shoots the breeze",
    "Chews the fat",
    "Talks for hours",
    "Gossips",
    "Yaks",
    "Ties up the line",
    "Confabulates",
    "Chinwags",
    "Ties up the phone",
    "Goes on and on",
    "Yaks",
    "Schmoozes",
    "Talks one's ear off",
    "Schmoozes",
    "Yaks",
    "Bumps one's gums",
    "Chatters",
    "Yaks"
  ],
  "lena": [
    "Singer/activist Horne",
    "Lex Luthor's sister",
    "Long river of Siberia",
    "Dunham of \"Girls\"",
    "Entertainer and civil rights activist Horne",
    "Actress Headey of \"Game of Thrones\"",
    "River to the Arctic Ocean",
    "Actress Headey of \"Game of Thrones\"",
    "Largest river to the Laptev Sea",
    "Actress Dunham who wrote \"Not That Kind of Girl\"",
    "\"Game of Thrones\" actress Headey",
    "Golden Globe winner Dunham",
    "Musical Horne",
    "___ the Hyena of old comics",
    "Headey of \"Game of Thrones\"",
    "\"Girls\" creator/star Dunham",
    "Dunham of \"Girls\"",
    "2,800-mile river to the Laptev Sea",
    "Actress Headey",
    "Dunham of \"Girls\"",
    "Russian river to the Arctic Ocean",
    "Actress Headey of \"Game of Thrones\"",
    "Dunham of \"Girls\"",
    "Dunham of \"Girls\"",
    "Siberian river",
    "Horne of \"The Lady and Her Music\"",
    "Dunham of \"Girls\"",
    "Horne with backup horns",
    "Dunham of \"Girls\"",
    "Dunham of HBO's \"Girls\"",
    "River known for the goldfields in its basin",
    "Olin of \"Chocolat\"",
    "Sultry singer Horne",
    "\"Girls\" creator Dunham",
    "Olin of \"Havana\"",
    "Olin of \"Enemies, a Love Story\"",
    "\"Stormy Weather\" singer Horne",
    "Yakutsk's river",
    "River to the Arctic Ocean",
    "\"Chocolat\" actress Olin",
    "Singer Horne",
    "With 13-Down, \"Stormy Weather\" singer",
    "\"Stormy Weather\" singer Horne",
    "Actress Olin",
    "\"Havana\" actress Olin",
    "Late singer Horne",
    "___ the Hyena of \"Li'l Abner\"",
    "Singer Horne",
    "Actress Olin",
    "Yakutsk's river",
    "River of Irkutsk",
    "Horne who sang \"Stormy Weather\"",
    "Singer Horne",
    "Horne of \"The Lady and Her Music\"",
    "River of Yakutsk",
    "Siberian river",
    "River through Yakutsk",
    "River of Siberia",
    "Actress Olin",
    "Cartoon hyena",
    "River through Siberia",
    "Diva Horne",
    "Olin of \"Chocolat\"",
    "___ the Hyena",
    "River whose headwaters are near Lake Baikal",
    "Olin of \"Chocolat\"",
    "River through Yakutsk",
    "Singer Horne",
    "Olin of \"Chocolat\"",
    "Olin of \"Hollywood Homicide,\" 2003",
    "Mellow Horne",
    "2,700-mile-long Russian river",
    "Musical Horne",
    "\"Beany & Cecil\" boat Leakin' ___",
    "River through Yakutsk",
    "Olin of \"Chocolat\"",
    "Musical Horne",
    "Singer Horne",
    "Singer Horne",
    "Melodious Horne",
    "Horne or Olin",
    "Tuneful Horne",
    "Actress Olin",
    "Yakutsk's river",
    "River to the Laptev Sea",
    "Yakutsk's river",
    "Diva Horne",
    "Miss Horne",
    "Musical Horne",
    "Watercolorist ___ Liu",
    "\"Gossip\" co-star Headey",
    "Russian river to the Laptev Sea",
    "Horne or Olin",
    "Horne who sang \"Stormy Weather\"",
    "Singer Horne",
    "Siberian river",
    "___ the Hyena of \"Li'l Abner\"",
    "River through Yakutsk",
    "Georg Buchner's \"Leonce and ___\"",
    "Sweet-sounding Horne",
    "With 66-Across, \"Romeo is Bleeding\" star",
    "Musical Horne",
    "Russian river",
    "Songstress Horne",
    "Russian river",
    "Songstress Horne",
    "Singer Horne",
    "___ the Hyena (\"Li'l Abner\" character)",
    "Actress Olin",
    "Horne who sang \"Deed I Do\"",
    "Singer Horne",
    "Musical Horne",
    "World's seventh-longest river",
    "Singer Horne",
    "Horne or Olin",
    "Miss Horne",
    "Yakutsk river",
    "Vocalist Horne",
    "Actress Olin",
    "Singer Horne",
    "Actress Olin",
    "First name in supper club entertainment",
    "Yakutsk's river",
    "River to the Laptev Sea",
    "Actress Olin"
  ],
  "mocs": [
    "Comfy shoes, for short",
    "Comfy slip-ons",
    "Comfy footwear",
    "Some slip-ons",
    "Tribal wear, for short",
    "Soft slip-ons",
    "Footwear that may be worn with PJs",
    "Around-the-house footwear, for short",
    "Comfy footwear, for short",
    "Comfy wear",
    "Beaded shoes, informally",
    "Casual footwear, briefly",
    "Soft shoes, informally",
    "Soft shoes",
    "Comfy footwear",
    "Soft shoes",
    "Some shoes, for short",
    "Footwear, for short"
  ],
  "mods": [
    "Body shop add-ons, informally",
    "Internet forum overseers, informally",
    "Forum rule enforcers, for short",
    "Chat room policers, informally",
    "Chat room overseers, for short",
    "Certain '60s teens",
    "Hip '60s types",
    "Some '60s hipsters",
    "Adjustments, informally",
    "Carnaby Street types",
    "Fashionable group",
    "Up-to-date dressers",
    "Fashionable dressers",
    "60's teens",
    "60's British teens"
  ],
  "deca": [
    "Prefix with -hedron",
    "Ten: Prefix",
    "Numerical prefix",
    "Ten, for openers",
    "Numerical prefix",
    "Prefix meaning 27-Down",
    "Prefix with -gon",
    "Prefix with -gon",
    "Numerical prefix",
    "Numerical prefix",
    "Prefix with pod",
    "Ten: Prefix",
    "Ten: Prefix",
    "Numerical prefix",
    "Prefix with -gon",
    "Geometric prefix",
    "Ten, for openers",
    "Ten: Prefix",
    "Ten in front?",
    "Numerical prefix",
    "Prefix with -gon",
    "Numerical prefix",
    "Ten, in combos",
    "Ten: Prefix",
    "Prefix with -hedron"
  ],
  "swab": [
    "Clear, as the deck",
    "Q-tip, e.g.",
    "Q-tip tip",
    "Clear the decks?",
    "DNA collector",
    "Stick in a cabinet, say",
    "Deckhand",
    "Tar",
    "Mop",
    "Q-tip, e.g.",
    "Q-tip, for one",
    "Lowly navy person",
    "Make shipshape, as a ship",
    "Navy enlistee, informally",
    "Mop, as a deck",
    "One standing on deck",
    "Crime lab tool",
    "Aid for collecting some samples",
    "Mop",
    "Q-tip, e.g.",
    "Deck washer",
    "Tar",
    "Q-tip, e.g.",
    "Mop",
    "Shipboard mop",
    "DNA collector, perhaps",
    "Sailor",
    "Seaborne lackey",
    "Q-Tip, e.g.",
    "Tidy up topside",
    "Deck cleaner",
    "What tars do to decks",
    "Tar",
    "Do a salt's job",
    "Gun barrel cleaner",
    "Tar",
    "Q-tip, e.g.",
    "Forensic evidence collector",
    "Culture transferer",
    "Mop",
    "Matey",
    "Do the deck",
    "Medium for a medical sample",
    "Sailor's mop",
    "Clear the deck?",
    "Nurse's item",
    "Q-Tip",
    "Use a mop",
    "Q-Tip, for one",
    "Tar",
    "Med lab specimen",
    "Q-Tip",
    "Hit the deck?",
    "Mop up",
    "Clear the decks?",
    "Q-tip, e.g.",
    "Sailor, informally",
    "Sailor",
    "Nurse's stick"
  ],
  "haji": [
    "Mideast traveler, of a sort",
    "Mecca trekker",
    "Certain pilgrim",
    "Muslim pilgrim",
    "Traveler to a certain 27-Across",
    "Observant Muslim, at times",
    "Visitor to Mecca",
    "Certain trekker",
    "Mecca trekker",
    "Once-in-a-lifetime traveler",
    "Muslim pilgrim",
    "Muslim honorific",
    "Returnee of a sort",
    "Muslim pilgrim",
    "Traveler to Mecca",
    "Muslim pilgrim",
    "Muslim pilgrim",
    "Pilgrim to Mecca"
  ],
  "land": [
    "Sighting from a crow's-nest",
    "Secure",
    "Developer's purchase",
    "Touch down",
    "Domain of the Queen of Hearts",
    "Hit the tarmac, e.g.",
    "International treaty subject",
    "See 53-Down",
    "Come to earth",
    "Word that fills both blanks in \"This ___ is your ___\"",
    "End of many country names",
    "Deliver, as a punch",
    "Reel in",
    "Make a touchdown",
    "Secure, as a contract",
    "Alight",
    "Hit the dirt?",
    "Bring in, as a big client",
    "Touch down",
    "Real estate",
    "Come to earth",
    "Come to shore",
    "Arrive",
    "Reel in",
    "Crow's-nest sighting",
    "Set down",
    "Touch down",
    "Arrive at the airport",
    "Domain",
    "Touch the tarmac",
    "It's touched in a touchdown",
    "Capture",
    "See 39-Across",
    "Come down",
    "Subject of many disputes",
    "Touch down",
    "Set down",
    "Come down",
    "Sight from the crow's-nest",
    "Light",
    "Hit the runway",
    "Catch",
    "Get, as a job",
    "Touch down",
    "Come down",
    "Touch down",
    "Coast, for example",
    "Sighting from the crow's-nest",
    "Reel in",
    "Shore",
    "Come to earth",
    "Catch",
    "Polaroid inventor"
  ],
  "nypd": [
    "\"Brooklyn Nine-Nine\" org.",
    "\"Ghostbusters\" force, for short",
    "Times Sq. squad",
    "Manhattan law enforcement grp.",
    "Washington Sq. Park squad",
    "Times Sq. watcher",
    "Org. in 1950s-'60s TV's \"Naked City\"",
    "\"Law & Order: SVU\" force",
    "Org. in 1997's \"Cop Land\"",
    "SoHo patroller: Abbr.",
    "\"Cagney & Lacey\" org.",
    "Org. for a Big Apple cop",
    "Cagney's employer, briefly",
    "Big Apple enforcement org.",
    "See 12-Down",
    "Title grp. in an ABC drama",
    "\"___ Blue\"",
    "Title grp. in an ABC drama",
    "\"___ Blue\"",
    "Org. in an ABC drama",
    "TV's \"___ Blue\"",
    "TV's \"___ Blue\""
  ],
  "glam": [
    "___ rock (Queen's onetime musical style)",
    "Flamboyant rock genre",
    "Flair, informally",
    "___ rock",
    "Glitz",
    "Early rock genre for David Bowie",
    "\"Wow\"-producing look",
    "Glitz",
    "Glitz",
    "___ it up (dress flamboyantly)",
    "David Bowie's rock genre, informally",
    "Razzle-dazzle",
    "Glitz",
    "1970s rock genre",
    "Rock type",
    "Kind of rock",
    "David Bowie genre"
  ],
  "lips": [
    "Gloss targets",
    "Kissers",
    "Smackers",
    "Pair that's kissing?",
    "Loose ones sink ships, in a saying",
    "Mouth pieces",
    "They might be sealed",
    "One might apply gloss to them",
    "Things that may be locked or sealed",
    "Bussing requirement",
    "Conversation openers?",
    "Romeo's \"two blushing pilgrims\"",
    "Logo image for \"The Rocky Horror Picture Show\"",
    "They're kissable",
    "They may be licked or smacked",
    "Kissers",
    "Kissers",
    "Mouth pieces",
    "Kissers",
    "They may be locked or sealed",
    "They're kissable",
    "Smack target",
    "\"Loose ___ sink ships\"",
    "Some read them",
    "Two smackers?",
    "Kissers",
    "They get smacked",
    "Items in sync?",
    "Revlon targets",
    "Kissers",
    "Kissers",
    "Ruby items"
  ],
  "norn": [
    "Norse goddess of fate",
    "Scandinavian goddess of fate",
    "Norse goddess of fate",
    "Norse goddess of fate",
    "Scandinavian goddess of fate",
    "One of a trio in Scandinavian myth"
  ],
  "roca": [
    "Almond ___ (candy brand)",
    "Almond ___ (candy)",
    "Almond ___ (candy brand)",
    "Almond ___ (candy brand)",
    "Cabo da ___, westernmost spot in continental Europe",
    "Jay-Z's ___-Fella Records",
    "Cape ___, Portugal (continental Europe's westernmost point)",
    "Cape ___ (westernmost point in continental Europe)",
    "Cape ___ (westernmost point in continental Europe)"
  ],
  "grey": [
    "Jennifer of \"Dirty Dancing\"",
    "Earl ___ tea",
    "___ Poupon mustard",
    "Overcast, in Britain",
    "___ Goose (vodka brand)",
    "___ Goose vodka",
    "Sombre",
    "Gandalf the ___",
    "\"Pardon me, would you have any ___ Poupon?\"",
    "Earl ___ tea",
    "Dull, as London skies",
    "Leaden, in London",
    "\"The Last of the Plainsmen\" novelist",
    "Writer from Zanesville",
    "Colored like a certain hound",
    "Lady Jane ___",
    "___ Poupon mustard",
    "Novelist Zane",
    "Joel of \"Cabaret\"",
    "Neutral-colored: Var.",
    "Western author",
    "See 8-Down",
    "Earl ___",
    "Certain horse",
    "\"Riders of the Purple Sage\" author",
    "Granite",
    "Hair hue",
    "\"Riders of the Purple Sage\" novelist",
    "\"Cabaret\" star"
  ],
  "rees": [
    "Dee ___, director of \"Bessie\" and \"Mudbound\"",
    "\"Cheers\" actor Roger",
    "Roger who played a part on \"Cheers\"",
    "\"Cheers\" actor Roger",
    "Roger of \"Cheers\"",
    "\"Cheers\" actor Roger",
    "Roger of \"Cheers\"",
    "Actor Roger",
    "\"Cheers\" actor Roger",
    "Roger of \"Cheers\"",
    "Roger of stage and screen",
    "Roger of \"Cheers\"",
    "Roger who won the Best Actor Tony for \"Nicholas Nickleby\"",
    "Children's author Ennis ___",
    "Roger of \"Nicholas Nickleby\"",
    "Children's author Ennis",
    "Roger of \"Cheers\"",
    "Roger of \"Cheers\"",
    "Roger of \"Nicholas Nickleby\"",
    "Tony-winning \"Nicholas Nickleby\" actor",
    "\"Cheers\" actor Roger",
    "Actor Roger of \"Cheers\"",
    "\"Nicholas Nickleby\" Tony winner"
  ],
  "hull": [
    "Main body of a ship",
    "Prow's place",
    "Main part of a ship",
    "Bottom of the sea?",
    "Oceanic body",
    "Part of a 23-Across",
    "Ship's framework",
    "Bottom of the ocean?",
    "Keel's place",
    "Bottom of the sea?",
    "E-F-G-H-E",
    "Naval base?",
    "Naval base?",
    "Hockey great Bobby",
    "Load line locale",
    "Bottom of a boat"
  ],
  "suri": [
    "Daughter of Katie Holmes and Tom Cruise",
    "Daughter of Tom Cruise and Katie Holmes"
  ],
  "wiiu": ["Nintendo gaming console with a pileup of vowels"],
  "elim": [
    "Tourney format, for short",
    "Kind of round in a tournament, informally"
  ],
  "gest": [
    "Heroic exploit",
    "Daring deed",
    "Tale of adventure",
    "Feat",
    "Knight-time story",
    "Heroic exploit",
    "Tale of derring-do",
    "Exploit",
    "Daring exploit",
    "Heroic deed",
    "Exploit",
    "Adventure",
    "Exploit",
    "Adventurous deed",
    "Heroic exploit",
    "Exploit",
    "Exploit",
    "Daring deed",
    "Heroic deed",
    "Medieval romance tale",
    "Romantic exploit",
    "Adventurous tale",
    "Daring deed"
  ],
  "epub": ["Digital book file extension"],
  "girl": [
    "Word before talk or power",
    "\"The ___ From Ipanema\" (1964 hit)",
    "Miss",
    "___ group",
    "Miss",
    "Miss",
    "Former Little League star Mo'ne Davis, e.g.",
    "\"___ on Fire\" (2012 Alicia Keys hit)",
    "___ power",
    "With 10-Across and the circled letters, a best-selling novel, with \"The\"",
    "Miss",
    "\"The ___ With the Dragon Tattoo\"",
    "Lady in waiting?",
    "See 53-Down",
    "Bat mitzvah, e.g.",
    "Beatles song that begins \"Is there anybody going to listen to my story\"",
    "Delivery possibility",
    "Miss",
    "Anita Loos's autobiographical \"A ___ Like I\"",
    "Brownie, e.g.",
    "Song on the Beatles' \"Rubber Soul\"",
    "Lass",
    "\"You go, ___!\"",
    "Lass",
    "Lass",
    "Miss",
    "Miss",
    "Song on the Beatles' \"Rubber Soul\" album",
    "Word with crazy or guide",
    "Demoiselle",
    "Human equivalent of a 16-Across",
    "Miss",
    "Miss",
    "Demoiselle",
    "Tomorrow's woman",
    "1966 Lennon-McCartney tune",
    "Slumber party guest",
    "See 17-Across",
    "Lass",
    "Demoiselle",
    "Demoiselle",
    "1966 Lennon-McCartney song",
    "Miss"
  ],
  "albs": [
    "Garb for the masses?",
    "Fathers' clothes",
    "Mass vestments",
    "Priestly robes",
    "Priests' robes",
    "Garments covered by amices",
    "Priests' garb",
    "Priestly garb",
    "Narrow-sleeved garments",
    "Mass apparel",
    "Priestly wear",
    "Priests' vestments",
    "Clerical clothing",
    "Fathers' robes",
    "Chancel wear",
    "Celebrants' wear",
    "Clergyman's closetful",
    "Priestly robes",
    "Priestly garb",
    "Priests' garments",
    "Priests' vestments",
    "Linen vestments",
    "Priests' robes",
    "Priestly vestments",
    "Priests' robes",
    "Priestly garb",
    "Narrow-sleeved robes",
    "Altar robes",
    "Priests' robes",
    "Liturgical robes",
    "Clerical garb"
  ],
  "ochs": [
    "Folk singer Phil",
    "Counterculture singer Phil",
    "Adolph ___, creator of the slogan \"All the News That's Fit to Print\"",
    "Protest singer Phil",
    "Phil who sang \"Love Me, I'm a Liberal\"",
    "Publisher Arthur ___ Sulzberger",
    "Phil who described himself as a \"singing journalist\"",
    "\"Draft Dodger Rag\" singer",
    "Adolph who coined the motto \"All the News That's Fit to Print\"",
    "Folkie Phil",
    "Adolph in New York Times history",
    "Phil who sang \"Draft Dodger Rag\"",
    "Newspaper publisher Adolph",
    "\"I Ain't Marching Anymore\" singer/songwriter",
    "Protest singer Phil",
    "Former New York Times publisher Adolph",
    "Phil who sang \"Draft Dodger Rag\"",
    "\"Love Me, I'm a Liberal\" singer",
    "Newspaperman Adolph",
    "Protest singer Phil",
    "Singer Phil",
    "\"The War Is Over\" writer/singer",
    "Newspaper publisher Arthur ___ Sulzberger",
    "Phil who sang \"Draft Dodger Rag\"",
    "Adolph who was chief of The New York Times from 1896 to 1935",
    "\"Draft Dodger Rag\" singer",
    "Phil who sang \"I Ain't Marching Anymore\"",
    "Phil who sang \"Draft Dodger Rag\"",
    "Director of the Associated Press, 1900-35",
    "Phil who sang \"Jim Dean of Indiana\"",
    "\"I Ain't Marching Anymore\" singer",
    "Self-proclaimed \"singing journalist\"",
    "Famous name in newspaper publishing",
    "Newspaperman Arthur ___ Sulzberger",
    "\"Draft Dodger Rag\" singer",
    "Phil who sang \"I Ain't Marching Anymore\"",
    "Baron in \"Der Rosenkavalier\"",
    "Newspaperman Adolph",
    "Big name in newspaper publishing",
    "\"I Ain't Marching Anymore\" singer, 1965",
    "Publisher Adolph",
    "Publisher Adolph",
    "Newspaperman Adolph",
    "Phil of folk",
    "\"The Highwayman\" singer",
    "Newspaperman Adolph",
    "Baron of \"Der Rosenkavalier\"",
    "Publisher Adolph",
    "\"Singing journalist\" Phil",
    "Folk singer Phil",
    "Newspaper publisher Adolph",
    "Pulitzer contemporary",
    "\"I Ain't Marching Anymore\" singer",
    "Noted name in newspapering",
    "Baron in \"Der Rosenkavalier\"",
    "Big name in newspaper publishing",
    "Noted newspaper publisher",
    "Singer Phil",
    "Newspaper publisher Adolph",
    "Publisher Adolph",
    "Baron in \"Der Rosenkavalier\"",
    "Publishing notable Adolph",
    "Newspaperman Adolph"
  ],
  "smug": [
    "Puffed up",
    "Cocksure",
    "Holier-than-thou",
    "Overly pleased with oneself",
    "Self-satisfied",
    "Like know-it-alls",
    "Like an \"I told you so\" look",
    "Thinking one is pretty hot stuff, say",
    "Self-satisfied",
    "Like one saying \"I told you so!\"",
    "Overly confident",
    "Complacent",
    "Like many a goody-goody",
    "Far from self-effacing",
    "Overweening",
    "Overconfident",
    "Full of oneself",
    "Too big for one's britches, say",
    "Contentedly confident",
    "Self-important",
    "Overly self-assured",
    "Very pleased with oneself",
    "Feeling superior",
    "Self-satisfied",
    "Confident of one's superiority",
    "More than self-confident",
    "Self-satisfied",
    "Like a stuffed shirt",
    "Complacent",
    "Overproud",
    "Self-satisfied",
    "Riding for a fall",
    "Complacent"
  ],
  "iget": [
    "\"Can ___ an amen?\"",
    "\"___ that a lot\"",
    "The Beach Boys' \"___ Around\"",
    "\"___ it!\"",
    "\"___ it\" (\"Understood\")",
    "\"___ that a lot\"",
    "\"___ the picture!\"",
    "\"___ the picture!\"",
    "\"Now ___ it!\"",
    "\"___ Around\" (#1 Beach Boys hit)",
    "1988 Belinda Carlisle hit \"___ Weak\"",
    "\"Now ___ it!\"",
    "Janet Jackson hit \"___ Lonely\"",
    "\"Now ___ it!\"",
    "\"This is the thanks ___?\"",
    "\"___ Ideas\" (1951 Tony Martin hit)",
    "\"___ Weak\" (1988 Belinda Carlisle hit)",
    "The Beach Boys' \"___ Around\"",
    "\"And this is the thanks ___?\"",
    "Belinda Carlisle's \"___ Weak\"",
    "Beach Boys' \"___ Around\"",
    "\"___ a Kick Out of You\"",
    "Start for \"Around\" and \"Ideas\" in song titles",
    "\"That's what___for...\" (\"Stupid me!\")",
    "\"___it\" (delayed reaction to a joke)",
    "\"___So Lonely\" (1954 hit)",
    "\"___Ideas\" (1950 hit)",
    "Beach Boys' \"___ Around\"",
    "\"___ Ideas\" (1950 hit)",
    "?ô___ Ideas?ö (1950 hit)",
    "\"___ a Kick Out of You\"",
    "\"___ it!\" (\"Eureka!\")",
    "\"___ the picture\"",
    "Beach Boys' \"___Around\""
  ],
  "ofno": [
    "___ importance (trivial)",
    "Be ___ use",
    "___ concern",
    "___ use (worthless)",
    "___ concern",
    "___ consequence",
    "___ consequence",
    "___ consequence",
    "___ consequence",
    "\"River ___ Return\" (Mitchum/Monroe flick)",
    "___ importance",
    "___ consequence",
    "___ consequence (insignificant)",
    "___importance",
    "___ concern"
  ],
  "nole": [
    "Florida State athlete, for short",
    "F.S.U. player, to fans",
    "Florida State athlete, slangily",
    "Florida State athlete, for short",
    "F.S.U. player, for short"
  ],
  "sras": [
    "Sp. ladies",
    "Sp. titles",
    "Sp. mujeres",
    "Mmes. of Madrid",
    "Sp. ladies",
    "Mmes., to Don Juan",
    "Mmes., in Madrid",
    "Some donas: Abbr.",
    "Esposas: Abbr.",
    "Many S.A. women",
    "Mmes. of Espana",
    "Many telenovela viewers: Abbr.",
    "Mmes., over the border",
    "Mex. women",
    "Some damas: Abbr.",
    "Esposas: Abbr.",
    "Mmes., in Iberia",
    "Sp. titles",
    "They have maridos: Abbr.",
    "Mmes., across the Pyrenees",
    "Femmes mariees, across the Pyrenees: Abbr.",
    "Ladies of Spain: Abbr.",
    "Ladies of Spain: Abbr.",
    "Mmes., in Madrid",
    "Women of Andalucia: Abbr.",
    "Sp. matrons",
    "Many Mexicanas: Abbr.",
    "Lima ladies: Abbr.",
    "Oaxaca women: Abbr.",
    "Mmes., across the Pyrenees",
    "South-of-the-border spouses: Abbr.",
    "Madrid Mmes.",
    "Mmes. of Madrid",
    "They're married in Mex.",
    "Familia members: Abbr.",
    "Mmes., in Madrid",
    "Sp. ladies",
    "Ladies of Sp.",
    "Mmes., in Madrid",
    "Sp. titles",
    "Span. titles",
    "Sp. ladies",
    "Mex. madames",
    "Sp. ladies",
    "They're married in Mex.",
    "Mmes., in Malaga",
    "Sp. titles",
    "Mmes., in Madrid",
    "Sp. ladies",
    "Relatives of Mmes.",
    "Spanish ladies: Abbr.",
    "Madrid Mmes.",
    "Mmes., across the Pyrenees",
    "Madrid Mmes."
  ],
  "pico": [
    "___ de gallo (condiment)",
    "___ de gallo",
    "One trillionth: Prefix",
    "Micro : millionth :: ___ : trillionth",
    "___ Rivera (Los Angeles suburb)",
    "Boulevard where Fox Studios and the Los Angeles Convention Center are located",
    "___ Rivera, Calif.",
    "Trillionth: Prefix",
    "One trillionth: Prefix",
    "___ de gallo sauce",
    "Trillionth: Prefix",
    "___ Rivera, Calif.",
    "Trillionth: Prefix"
  ],
  "mica": [
    "Mineral easily split into layers",
    "Material found in countertops",
    "Material in sheets",
    "It's flaky",
    "Flaky mineral",
    "Flaky stuff",
    "Mineral in layers",
    "Mineral used for insulation",
    "Sheet rock?",
    "It makes flakes",
    "Flaky stuff",
    "It makes granite glint",
    "Mineral in sheets",
    "Flaky mineral",
    "Material also known as cat-gold or glimmer",
    "Thin-layered mineral",
    "Mineral in thin sheets",
    "It's flaky",
    "Translucent mineral in sheets",
    "Makeup of some insulating sheets",
    "Sheet mineral",
    "Isinglass",
    "Mineral in sheets",
    "Flaky mineral",
    "Easily split mineral",
    "Easily split mineral",
    "Glinting flecks in granite",
    "Glass substitute",
    "Muscovite, for one",
    "Mineral in transparent sheets",
    "Mineral in sheets",
    "Layered mineral",
    "Oven window material",
    "Popular insulator",
    "Isinglass",
    "Sheet rock?",
    "It's flaky",
    "Mineral that glistens",
    "Flexible mineral",
    "Flexible mineral",
    "Material in sheets",
    "Translucent mineral",
    "It comes in sheets",
    "Constituent of biotite",
    "Mineral used for insulation",
    "Flaky rock",
    "Isinglass",
    "Flaky mineral",
    "Easily split stuff",
    "Isinglass",
    "Popular insulator",
    "Isinglass",
    "Some sheets",
    "Isinglass",
    "Vermiculite, essentially",
    "Foliate mineral",
    "Muscovite, e.g.",
    "See-through silicate",
    "Isinglass"
  ],
  "tyne": [
    "Daly with a Tony for \"Gypsy\"",
    "Newcastle upon ___, England",
    "Newcastle upon ___, England",
    "Northumberland river",
    "Newcastle's river",
    "Newcastle's river",
    "River to the North Sea",
    "Daly of \"Cagney & Lacey\"",
    "Newcastle's river",
    "Daly of \"Cagney & Lacey\"",
    "Daly of \"Judging Amy\"",
    "Newcastle upon ___, England",
    "Daly of \"Judging Amy\"",
    "River near Hadrian's Wall",
    "___ and Wear (English county)",
    "Newcastle's river",
    "Actress Daly",
    "Newcastle's river",
    "Jarrow's river",
    "Northumberland river",
    "Actress Daly",
    "Newcastle's river",
    "Actress Daly",
    "Northumberland river",
    "Northumberland river",
    "North Sea feeder",
    "Newcastle's river",
    "Newcastle's river",
    "Daly of \"Judging Amy\"",
    "Northumberland river",
    "North Sea feeder",
    "Daly of \"Judging Amy\"",
    "Daly of \"Judging Amy\"",
    "River to the North Sea",
    "Daly of \"Judging Amy\"",
    "Newcastle's place",
    "Daly of \"Judging Amy\"",
    "___ and Wear (English county)",
    "Actress Daly",
    "Newcastle's river",
    "Sharon's \"Cagney & Lacey\" co-star",
    "Newcastle's river",
    "Newcastle's river",
    "Emmy-winning Daly",
    "Sharon's co-star in 80's TV",
    "Daly of \"Gypsy\"",
    "One of the Dalys",
    "Newcastle-upon-___, England"
  ],
  "sens": [
    "State pair: Abbr.",
    "State couple: Abbr.",
    "Hill workers: Abbr.",
    "Political century: Abbr.",
    "They sit for six yrs.",
    "Meaning: Fr.",
    "Professional filibusterers: Abbr.",
    "Filibusterers, e.g.: Abbr.",
    "Capitol V.I.P.'s: Abbr.",
    "D.C. 100: Abbr.",
    "Grp. on the floor",
    "Chamber workers: Abbr.",
    "A century in Washington: Abbr.",
    "Chamber workers: Abbr.",
    "Upper house members: Abbr.",
    "45-Across and others: Abbr.",
    "Members in a 100-member club: Abbr.",
    "Brownback and Obama, e.g.: Abbr.",
    "Chamber workers: Abbr.",
    "Legislators: Abbr.",
    "Lincoln and Kennedy, e.g.: Abbr.",
    "Chamber workers: Abbr.",
    "Boxer and others: Abbr.",
    "D.C. group",
    "Burns and Allen: Abbr.",
    "Byrd and Hatch, e.g.: Abbr.",
    "Burns and Allen: Abbr.",
    "D.C. pols",
    "D.C. figures: Abbr.",
    "Chamber workers: Abbr.",
    "D.C. figures",
    "D.C. pols",
    "Chamber workers: Abbr.",
    "Mikulski and Murkowski: Abbr."
  ],
  "ergs": [
    "Tiny fractions of joules",
    "There are 10 million in a joule",
    "Little bits of energy",
    "Bits of work",
    "Pieces of work?",
    "Rowers' workout machines, informally",
    "Units of work in physics",
    "Tiny bits of work",
    "Dyne-centimeters",
    "Physics units",
    "Energy units",
    "Work units, in physics",
    "Dyne-centimeters",
    "Physics units",
    "Work units",
    "Measures of work, in physics",
    "Work units",
    "Physicists' work units",
    "Small fractions of joules",
    "___ per second (luminosity unit)",
    "Basic work units",
    "Newton-meter fractions",
    "Physics units",
    "Energy output units",
    "Pieces of work?",
    "Physics units",
    "Units in physics",
    "Joule fractions",
    "Bursts of energy?",
    "Parts of a joule",
    "Energy units",
    "Units in physics",
    "Physics 101 units",
    "Pieces of work?",
    "Work units",
    "Work units",
    "Pieces of work?",
    "Energy units",
    "Newton-meter divisions",
    "Fractions of watt-seconds",
    "Units in Physics 101",
    "Work units",
    "Joule parts",
    "Tiny metric units",
    "Work units",
    "Energy units",
    "Bits of energy",
    "Work, physically",
    "Dyne-centimeters",
    "Metric work units",
    "Physics units",
    "Work units",
    "10 million of them equal a joule",
    "Work units",
    "Work units",
    "They may be converted into calories",
    "Joule fractions",
    "Kilowatt-hour fractions",
    "Amount of work",
    "Bits of work",
    "Work units",
    "Work units",
    "Work units",
    "Joule divisions",
    "Work units",
    "Work units",
    "What solar flares are measured in",
    "___ per second (luminosity unit)",
    "Work units",
    "Divisions of joules",
    "Work units",
    "Joule fragments",
    "Work units",
    "Physics units",
    "One foot-pound = 13,560,000___",
    "Physics units",
    "Work units",
    "Parts of joules",
    "Portions of joules",
    "Work units",
    "Parts of joules"
  ],
  "reos": [
    "Flying Clouds and Royales",
    "Old cars with wings in their logo",
    "Old Model M's and Model T-6s",
    "Cars that disappeared during the Depression",
    "Some old delivery trucks",
    "Model T competitors",
    "Some antique buses",
    "Some old fire trucks",
    "Speedsters of old autodom",
    "Some vintage autos",
    "They competed with Franklins",
    "Classic cars",
    "Speed Wagons of old autodom",
    "Some antique buses",
    "Old-fashioned Speed Wagons",
    "Flying Cloud and Royale 8",
    "Auburn competitors",
    "Some old runabouts",
    "1930s Royales",
    "Antique autos",
    "Vintage autos",
    "Vintage cars",
    "Some old fire trucks",
    "Vintage autos",
    "Vintage vehicles",
    "Model T contemporaries",
    "Vintage autos",
    "Antique autos",
    "Model T contemporaries",
    "Olds oldies",
    "Cars once advertised as \"The Gold Standard of Value\"",
    "Essex contemporaries",
    "Vintage vehicles",
    "20's touring cars",
    "1930?Æs Royales",
    "Model T contemporaries",
    "Model T contemporaries",
    "Classic cars",
    "Classic cars",
    "Model T alternatives",
    "Model T alternatives",
    "Essex contemporaries",
    "Classic cars",
    "Antique autos",
    "Old-time cars",
    "20's-30's cars",
    "Vintage vehicles",
    "Old Oldses",
    "Vintage cars",
    "Old cars",
    "Classic cars",
    "Classic cars",
    "Classic cars",
    "Classic cars",
    "Cars once built in Lansing",
    "Old cars",
    "Old autos",
    "Antique autos",
    "Antique autos",
    "Classic cars",
    "Collectors' cars"
  ],
  "pond": [
    "Traditional sight next to many a mill",
    "Ice skating spot",
    "Duck's habitat",
    "Polliwog's place",
    "Where you might hear someone say \"Duck!\"",
    "Koi's habitat",
    "Giverny backdrop for Monet",
    "The Atlantic, in a common phrase",
    "Skating venue",
    "Place for a lily",
    "Duck's home",
    "Decoy site, maybe",
    "Golf course feature",
    "Duck's home",
    "Many a Monet scene",
    "Swimming site",
    "Duck's place",
    "Duffer's obstacle",
    "It may be dammed",
    "Winter skating venue",
    "Fishing site",
    "Duck's home",
    "Toy boat spot",
    "Frog's home",
    "Swimming site",
    "Result of a dam, perhaps",
    "Minnow's milieu",
    "Lily locale",
    "Limnologist's study",
    "Fishing area",
    "Duck's home",
    "Site for an alligator bonnet",
    "Mere",
    "Thoreau subject",
    "Mill locale",
    "Golden, for one",
    "Duck's habitat"
  ],
  "kook": [
    "Eccentric",
    "Weirdo",
    "Weirdo",
    "Nut",
    "Loony",
    "Palindromic nut",
    "Nut",
    "Nutcase",
    "Nutcase",
    "Wack job",
    "Eccentric",
    "Nut case",
    "Wacko",
    "Oddball",
    "Flake",
    "Nutcase",
    "Eccentric",
    "Screwball",
    "Nut",
    "Crackpot"
  ],
  "blob": [
    "Water formation on wax paper",
    "Ill-defined lump",
    "Indistinct shape",
    "Amoeba, e.g.",
    "Amoeba's shape",
    "Pollock painting unit",
    "1950s sci-fi terror, with \"the\"",
    "Lump",
    "Lava lamp lump",
    "Indistinct shape",
    "Amorphous mass",
    "Formless lump",
    "Lava lamp formation",
    "Classic sci-fi terror, with \"the\"",
    "Squished bug, e.g.",
    "Steve McQueen's first major movie, with \"The\"",
    "Gooey mass",
    "1958 sci-fi classic, with \"The\"",
    "Unidentifiable mass",
    "Mass",
    "Out-of-focus image, say",
    "Embryonic shape, at first",
    "Amorphous mass",
    "Amorphous movie monster",
    "Amoeba, e.g.",
    "Campy 1958 sci-fi film, with \"The\"",
    "1950's horror film creature, with \"the\"",
    "1958 chiller, with \"The\"",
    "Sight for very poor eyes",
    "1958 movie chiller, with \"The\"",
    "Splotch",
    "Amorphous mass",
    "Out-of-focus picture",
    "Unspecified shape",
    "Out-of-focus object",
    "1958 sci-fi terror, with \"the\"",
    "Steve McQueen?Æs first major movie, with ?ôThe?ö"
  ],
  "dram": [
    "Small order of whiskey",
    "Apothecary's unit",
    "Apothecary's weight",
    "Perfumery measure",
    "1/8 fluid ounce",
    "Perfumery measure",
    "Liquor unit",
    "Apothecary unit",
    "Armenia's basic monetary unit",
    "Mixologist's measure",
    "Apothecary weight",
    "Three scruples",
    "60 grains",
    "Apothecary weight",
    "Fraction of an ounce",
    "Small amount",
    "Little bit",
    "Apothecaries' weight",
    "A few grains",
    "1/8 ounce",
    "Pharmacist's weight",
    "1/8 of a fluid ounce",
    "Short drink",
    "Small amount",
    "Small amount of liquor",
    "Apothecary's weight",
    "Apothecary's weight",
    "Fluid ___",
    "Pharmacist's weight",
    "Apothecary's measure",
    "Fluid ___",
    "Shot"
  ],
  "orno": [
    "\"Yes ___?\"",
    "\"A simple yes ___ will suffice\"",
    "Yes ___",
    "TV's \"Deal ___ Deal\"",
    "\"Yes ___?\"",
    "Yes ___",
    "Of little ___ use",
    "\"Yes ___?\"",
    "Little ___",
    "\"Yes ___?\"",
    "Little ___",
    "Little ___ ...",
    "Yes-___ question",
    "Yes ___",
    "Whether ___",
    "\"Yes ___?\"",
    "Little ___ (hardly any)",
    "\"Yes ___?\"",
    "\"Yes ___?\"",
    "Words after \"yes\"",
    "\"Yes ___?\""
  ],
  "mane": [
    "Zebra feature",
    "Mare hair",
    "Hair on the back of the neck",
    "Whole head of hair",
    "Hair on a horse or a lion",
    "Lion's hair",
    "Locks in a barn?",
    "Flowy hair",
    "Locks in a barn?",
    "Lion's pride?",
    "Mustang feature",
    "Thick locks",
    "Some locks",
    "Long, flowing locks",
    "Lion's locks",
    "Stable locks?",
    "Equestrian's handhold",
    "Something braided on a farm",
    "Rider's handful",
    "Flowing hair",
    "What a lion has that a lioness lacks",
    "Locks in the stable?",
    "Shock of hair",
    "A pride of lions?",
    "Zebra feature",
    "Barber's challenge",
    "Lioness's lack",
    "A zebra has a short one",
    "Flowing hair",
    "Mare hair",
    "It runs down the neck",
    "Lion's locks",
    "Bald person's envy, maybe",
    "Neck line?",
    "Stable locks?",
    "It may flow on a ranch",
    "Werewolf feature",
    "Equestrian's grip, maybe",
    "Horsehair source",
    "Lion's hair",
    "Place for a comb",
    "Zebra feature",
    "Hair behind the ears, maybe",
    "Lippizaner's locks",
    "Locks in a stable",
    "Series of shocks?",
    "Luxuriant locks",
    "Wildebeest feature",
    "Challenge for a barber",
    "Barn locks?",
    "It connects to the neck",
    "Mule feature",
    "What a groom may groom",
    "Locks in a barn?",
    "Lion's locks",
    "Locks in a zoo?",
    "Luxuriant hair",
    "Place for a comb",
    "Locks in a barn?",
    "Zebra feature",
    "Lion's tresses",
    "Place for a comb",
    "Neck hair",
    "Rider's handhold",
    "Leonine locks",
    "It may be braided",
    "Neck hair",
    "Neck band?",
    "Stable locks",
    "Silver hair",
    "Long hair",
    "Equestrian's handful",
    "It grows from the neck",
    "Mare's hairs",
    "Rider's grip, perhaps",
    "Pride of lions",
    "Lion's pride",
    "Lion's pride?",
    "Currycomb target",
    "Horse hair"
  ],
  "mice": [
    "Timid people, so to speak",
    "Little squeakers",
    "They might squeak by",
    "Little scurriers",
    "Prey for cats",
    "Three blind creatures, in a children's rhyme",
    "Stuart Little and Chuck E. Cheese, for two",
    "Trio in a children's rhyme",
    "They click",
    "\"Of ___ and Men\"",
    "Computer alternatives to touchpads",
    "Squeakers",
    "Game for cats",
    "Victims of the farmer's wife",
    "Symbols of timidity",
    "Squeakers",
    "Many sit on pads",
    "Ones sitting on pads",
    "Cheese fanciers",
    "Runners in lab mazes",
    "Many trial subjects",
    "Cheese lovers",
    "They're often double-clicked",
    "Cursor movers",
    "Prey for owls",
    "Lab test subjects",
    "Owls' prey",
    "Some sit on pads",
    "Cats' prey",
    "Blind trio in a children's rhyme",
    "See 51-Down",
    "Quiet types",
    "Nursery rhyme trio",
    "Squeakers",
    "Squeakers",
    "Cheese nibblers",
    "Computer clickers",
    "Certain schemers, to Burns",
    "Cheese nibblers",
    "Cheese nibblers",
    "Cheese lovers?",
    "Nursery rhyme threesome",
    "\"Of___and Men\"",
    "Tenant problem, perhaps",
    "Attic problem",
    "Mickey and Mighty"
  ],
  "trig": [
    "A lecture on it might be full of tangents",
    "Subject in which sin is an important topic?",
    "Class in which kids may learn about sin?",
    "Sin-ful teaching?",
    "Sin subject?",
    "H.S. math class",
    "Subj. for those working the angles?",
    "Pre-calc course",
    "Pre-calc course",
    "Prerequisite for calculus, informally",
    "One of the Palins",
    "Calc prerequisite",
    "Palin boy",
    "H.S. math class",
    "H.S. math",
    "One of the Palins",
    "Math subj. with many functions",
    "Calc prerequisite",
    "Math class, in brief",
    "Pre-calc class",
    "Math class before calc",
    "Some H.S. math",
    "Smart-looking, in British lingo",
    "Math class before calc.",
    "___ function",
    "H.S. math class",
    "Math branch",
    "H.S. math",
    "It has several functions",
    "Sine language?",
    "Class for anglers?",
    "One may consider all the angles in it",
    "Kind of function",
    "H.S. class",
    "Pre-calc",
    "Kind of function",
    "Tech course",
    "H.S. math",
    "H.S. subject",
    "Kind of function",
    "Calc preceder, perhaps",
    "Math course",
    "Math subject",
    "Math class, informally",
    "H.S. math",
    "High school math",
    "Math branch",
    "Advanced math",
    "Math subject"
  ],
  "pdfs": [
    "JPEG alternatives",
    "Shareable PC files",
    "Acrobat displays",
    ".docx alternatives",
    "Some email attachments, for short",
    "Common email attachments",
    "Results of some scans",
    "Some email attachments",
    "Some email pics",
    "Some email attachments",
    "Acrobat displays?",
    "Many email attachments",
    "Some e-mail attachments",
    "Some E-mail attachments"
  ],
  "junk": [
    "Refuse",
    "Kind of mail or bond",
    "Kind of mail",
    "Chinese vessel",
    "Atticful",
    "Attic buildup",
    "Closet buildup"
  ],
  "evel": [
    "Daredevil Knievel",
    "Stuntman Knievel",
    "Daredevil Knievel",
    "First name in stunt cycling",
    "Stuntman Knievel",
    "First name in stunt cycling",
    "Daredevil Knievel",
    "First name in daredevilry",
    "\"Being ___\" (2015 documentary featuring many wipeouts)",
    "First name of the \"father of extreme sports\"",
    "First name in long jumps",
    "First name in daredevilry",
    "First name in long jumping",
    "Stuntman Knievel",
    "Knievel of motorcycle stunts",
    "First name in long jumping",
    "Daredevil Knievel",
    "Robbie's daredevil dad",
    "Knievel on a motorcycle",
    "Stuntman Knievel",
    "Stunt legend Knievel",
    "Daredevil Knievel",
    "First name in daredeviltry",
    "Robbie Knievel's father",
    "Stuntmaster Knievel",
    "First name in motorcycling",
    "Daredevil Knievel",
    "Risk-taking Knievel",
    "Daredevil Knievel",
    "Daredevil Knievel",
    "First name in motorcycle jumping",
    "First name in stunts",
    "First name in daredevils",
    "Motorcyclist Knievel",
    "Daredevil Knievel",
    "Daredevil Knievel",
    "Robbie's daredevil dad",
    "Big name in stunt riding",
    "Daredevil Knievel",
    "First name in daredeviltry",
    "First name in derring-do",
    "Robbie's daredevil dad",
    "First name in daredeviltry",
    "Stunt-doer Knievel",
    "Big name in daredeviltry",
    "Daredevil Knievel",
    "Stuntmeister Knievel",
    "Daredevil Knievel",
    "First name in daredeviltry",
    "Notable name in derring-do",
    "Daredevil Knievel",
    "First name in stunts",
    "Daredevil Knievel",
    "Daredevil Knievel",
    "Stuntman Knievel",
    "Motorcycle hero",
    "Stuntman Knievel"
  ],
  "ipas": [
    "Some hoppy drafts, for short",
    "Many craft brews, for short",
    "Bitter brews, for short",
    "Imperial ___ (bar orders)",
    "Some brewery offerings, in brief",
    "Hoppy brews"
  ],
  "alif": [
    "Start of the Arabic alphabet",
    "First letter of the Arabic alphabet",
    "Arabic leader",
    "Arabic leader?",
    "A, in Arabic",
    "Arabic leader?",
    "First Arabic letter",
    "A, in Arabic",
    "A first for Arabia?",
    "Ba preceder",
    "Arab leader?",
    "Letter written as a straight vertical line",
    "Mideast leader?",
    "Letter before ba",
    "It comes before ba",
    "Arabic leader",
    "Arabic letter",
    "Arabic \"A\""
  ],
  "gold": [
    "Top Olympic medal",
    "See 7-Across",
    "Top prize at the Olympics",
    "Alchemist's quest",
    "[50]",
    "What some hearts are made of",
    "Olympic prize",
    "#1 honor",
    "Expensive tooth filling material",
    "Filling material",
    "Rumpelstiltskin's output",
    "First-place",
    "Symbol of goodness",
    "Olympian's quest",
    "Kind of standard",
    "Basket-of-___ (yellow perennial)",
    "Top prize",
    "Olympian's quest",
    "Like some records",
    "First-place",
    "Like some charge cards",
    "Credit card color",
    "Precious metal",
    "Klondike strike",
    "Million-selling",
    "The color of honey",
    "Olympics award",
    "Olympian's quest"
  ],
  "imax": [
    "Big-screen film format",
    "Big name in theaters",
    "3-D movie option",
    "Theater option",
    "Format much used for action films",
    "Film format that's sometimes in 3-D",
    "Format too big for conventional reels",
    "Wide-screen format",
    "Big movie format",
    "Kind of theater",
    "Where to see pictures on the big screen?",
    "Tall tale producer?",
    "Big-screen movie format",
    "It's big in movies",
    "Big-screen format",
    "Big movie screen format",
    "Theater giant?",
    "Big-screen format",
    "Kind of screen",
    "Immersive film format",
    "Big-screen format",
    "What's big at the movies?",
    "Kind of screen",
    "Motion picture format",
    "Choice for a huge movie fan?",
    "Big movie fan's option?",
    "Big picture?",
    "Movie format for a big date?",
    "Kind of theater",
    "Kind of screen",
    "Supersized movie screen format",
    "Big picture?",
    "Big film shower",
    "Means of seeing the big picture?",
    "Film format sometimes in 3-D",
    "Kind of screen",
    "Huge-screen format",
    "3-D camera maker",
    "Big film shower",
    "Big movie fan's option?",
    "\"Think big\" sloganeer",
    "Giant-screen theater",
    "Giant-screen film format",
    "\"Think big\" company",
    "Film format for domed theaters",
    "Giant-screen movie format",
    "Large-screen cinema format",
    "Giant-screen film venue",
    "Big screen",
    "Big-screen format",
    "Big name in theaters?",
    "Huge-screen film format",
    "It puts on a really big show",
    "Kind of theater",
    "Theater company that thinks big",
    "Big screen name",
    "Place to get the big picture",
    "Huge-screen film format",
    "Film system with an extra-large screen"
  ],
  "cuts": [
    "With 48-Across, stops wasting time",
    "Problems with pay or paper",
    "Salon jobs",
    "Shares of profits",
    "Deli case display",
    "Has an ad that really stands out",
    "Skips, as class",
    "*Salon styles",
    "Batters take them",
    "Record collection?",
    "Abridges",
    "Roster removals",
    "Mows",
    "Film versions",
    "Some need stitching",
    "Swordsmen's worries",
    "Shares",
    "Spoils shares?",
    "What Band-Aids aid",
    "Saws",
    "Belt-tightening",
    "Shares"
  ],
  "sous": [
    "___ vide (culinary technique)",
    "___-chef",
    "Under: Fr.",
    "___-chef",
    "Under: Fr.",
    "___-chef",
    "___-chef",
    "___-chef (kitchen's number two)",
    "___-chef",
    "___-chef",
    "Old French coins",
    "___-chef (kitchen #2)",
    "___-chef",
    "Under: Fr.",
    "Bygone coins",
    "Old French coins"
  ],
  "ulan": [
    "___ Bator",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator, Mongolia",
    "Mongolian for \"red\"",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator",
    "Asian capital name starter",
    "___ Bator",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Hot (city in Inner Mongolia)",
    "___ Bator",
    "Half an Asian capital?",
    "___ Bator, Mongolia",
    "___ Bator",
    "___-Ude (Russian city on the Trans-Siberian Railroad)",
    "___ Bator",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "It means \"red\" in Mongolian",
    "___ Bator, Mongolia",
    "___-Ude, Mongolia",
    "___-Ude (Asian capital)",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator",
    "___-Ude (Trans-Siberian Railroad city)",
    "___ Bator",
    "___ Bator, Mongolia",
    "It means \"red\" in Mongolian",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "___ Bator, Mongolia",
    "___-Ude (Trans-Siberian Railroad city)",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator",
    "___ Bator, Mongolia",
    "___ Bator",
    "___ Bator",
    "___ Bator",
    "___Bator, Mongolia",
    "Light-cavalry lancer",
    "Asian capital, with 14 Down"
  ],
  "lunt": [
    "Broadway's ___-Fontanne Theater",
    "Broadway's ___-Fontanne Theater",
    "Fontanne's stage partner",
    "New York's ___-Fontanne Theater",
    "Broadway's ___-Fontanne Theater",
    "1955 Tony winner for \"Quadrille\"",
    "Stage actor Alfred",
    "\"The Guardsman\" star",
    "Fontanne's dramatic partner",
    "Fontanne's partner"
  ],
  "peen": [
    "Part of a hammer",
    "Hammer part",
    "Ball ___",
    "Round part of a tool",
    "Hammer's end",
    "Hammer's end",
    "Ball ___",
    "Round end, of a sort",
    "Hammer extremity",
    "Round pounder",
    "Back end of a hammer",
    "Hammer part",
    "Part for a whack job?",
    "Ball ___",
    "Round hammer part",
    "Flatten, as a rivet",
    "Ball-___ hammer",
    "Ball-___ hammer",
    "Striking part",
    "It's not meant to be used on nails",
    "Ball-shaped part",
    "Hammer part",
    "Hammer part",
    "Claw alternative",
    "One end of a hammer",
    "Hammer part",
    "Round end",
    "Hammer's end",
    "End of a hammer",
    "Round end of a hammer",
    "Hammer's end",
    "Hammer's end",
    "Hammer part",
    "Shape with a hammer",
    "Flatten, in metalworking",
    "Hammer's end",
    "Striking end",
    "Rounded end",
    "Round end",
    "Light hammer part",
    "Hammer part",
    "Hammer part",
    "Ball-shaped hammer part",
    "Striking end",
    "Hammer part",
    "Hammer part",
    "Hammer's end",
    "Hammer end",
    "Hammer head",
    "Spherical striker",
    "Hammerhead",
    "Round pounder",
    "Hammer end",
    "Hammer part",
    "Hammer part",
    "Flatten by pounding",
    "Ball ___",
    "Hammer's end",
    "Hammer head"
  ],
  "elam": [
    "Kingdom east of Babylonia",
    "Jack of \"Rio Lobo\"",
    "Jack of old westerns",
    "Ancient land located in what is now southwest Iran",
    "Ancient civilization around Susa",
    "Land east of Babylonia",
    "Ancient land east of the Tigris",
    "Kingdom vanquished by Hammurabi",
    "Actor Jack of oaters",
    "Shem's eldest son",
    "Jack of old westerns",
    "Ancient rival of Assyria",
    "Ancient rival of Assyria",
    "Jason ___, longtime Denver Bronco",
    "Oater actor Jack",
    "Kingdom east of Babylonia",
    "Biblical kingdom",
    "Jack of \"Rio Lobo\"",
    "Jason of the N.F.L.",
    "Western film actor Jack",
    "Jack of \"Rio Lobo\"",
    "63-yard field goal kicker Jason",
    "Jack of \"Rio Lobo\"",
    "Jack of old oaters",
    "Jack of \"Big Bad John\"",
    "Jack in oaters",
    "\"Rio Lobo\" actor",
    "Shem's eldest son",
    "Actor Jack",
    "Jack of TV's \"Easy Street\"",
    "Ancient land east of the Tigris",
    "Susa was its capital",
    "Ancient Khuzistan",
    "Susa's country"
  ],
  "bong": [
    "Water pipe",
    "Beer ___ (frat party item)",
    "Pot holder",
    "Head shop buy",
    "Sound from Big Ben",
    "Sound from Big Ben",
    "Signal at a pagoda, maybe",
    "Large bell's sound",
    "Big bell sound",
    "Large bell sound",
    "Deep bell sound"
  ],
  "jogs": [
    "Nudges",
    "Brief, abrupt changes in direction",
    "Nudges, in a way",
    "Goes once or twice around the track, maybe",
    "Slight pushes",
    "Nudges",
    "Does laps, maybe",
    "Trots",
    "Runs for exercise",
    "Nudges",
    "Bends, as in a river",
    "Helps, as a memory",
    "Uses a treadmill, maybe",
    "Clinton's runs"
  ],
  "droz": [
    "Regular guest on \"The Oprah Winfrey Show\"",
    "Physician who was once a regular on \"The Oprah Winfrey Show\"",
    "Medical professional on TV",
    "Health adviser on talk TV",
    "Talk show physician"
  ],
  "itbe": [
    "\"Let ___\"",
    "The Beatles' \"Let ___\"",
    "\"Let ___\"",
    "Barry Manilow's \"Could ___ Magic\"",
    "The Beatles' \"Let ___\"",
    "The Everly Brothers' \"Let ___ Me\"",
    "\"Wouldn't ___ Loverly?\"",
    "\"What'll ___?\"",
    "\"Can ___ true?\"",
    "\"How can ___?\"",
    "\"What'll ___?\"",
    "\"Can ___?\"",
    "\"Let ___\"",
    "\"Could ___?\"",
    "The Beatles' \"Let ___\"",
    "\"What'll ___?\" (bartender's question)",
    "Beatles' \"Let___\"",
    "\"Let___Me\"",
    "60's hit \"Let ___ Me\""
  ],
  "essa": [
    "Pronoun for Florence in Venice, say",
    "Italian pronoun",
    "She, in Salerno",
    "She, in Venice",
    "Italian pronoun",
    "That girl, in Genoa",
    "She, in Salerno",
    "She, in Italy",
    "She, in Salerno",
    "She, in Siena",
    "She, in Rome",
    "She, in Sicily",
    "She, to Schiaparelli",
    "She, overseas",
    "What she is in Italy",
    "She, in Venice",
    "She, in Italy",
    "She, in Roma",
    "She, in Italy",
    "She, to Sophia",
    "She, in Italy",
    "Early weather satellite",
    "What it is in Italy",
    "She, in Siena",
    "La donna",
    "It, in Italy",
    "She, abroad",
    "Pisan pronoun",
    "She, in Siena",
    "'60s satellite series",
    "Early weather satellite"
  ],
  "beav": [
    "1950s-'60s sitcom nickname",
    "Wally's sitcom brother, with \"the\"",
    "Wally's bro, on '50s-'60s TV",
    "Wally's kid bro, in 1950s-'60s TV",
    "Sitcom nickname of Wally's bro",
    "'50s-'60s sitcom nickname",
    "Wally's bro",
    "Wally's little bro",
    "Wally's TV brother, with \"the\"",
    "Wally's sitcom bro",
    "50's-60's sitcom boy"
  ],
  "guys": [
    "Chaps",
    "Fellas",
    "Dolls' counterpart",
    "Fellas",
    "Blokes",
    "Insults playfully",
    "Fellas",
    "\"___ and Dolls\"",
    "Fellas",
    "Fraternity members",
    "Chaps",
    "Stag party parties",
    "Chaps",
    "Fellows",
    "Dolls' friends",
    "Fellows"
  ],
  "macy": [
    "R&B singer Gray",
    "Soul singer Gray",
    "Gray with the 3x platinum album \"On How Life Is\"",
    "Department store founder R. H. ___",
    "Gray of R&B",
    "Department store chain founder",
    "Department store chain founder",
    "Department store founder",
    "Wanamaker contemporary",
    "Retailer R.H.___",
    "Retailer R.H.",
    "Merchant R. H. ___"
  ],
  "ahot": [
    "Like ___ knife through butter",
    "\"Cat on ___ Tin Roof\"",
    "On ___ streak",
    "\"Cat on ___ Tin Roof\"",
    "\"That's ___ one!\"",
    "\"There'll be ___ time ...\"",
    "On ___ streak (lucky)",
    "On ___ streak (winning)",
    "\"Cat on ___ Tin Roof\"",
    "\"Cat on ___...\"",
    "16-Across's \"Cat on ___Tin Roof\""
  ],
  "yell": [
    "Long-distance call",
    "Holler",
    "Bellow",
    "Give a whoop",
    "Rise above the din, say",
    "Holler",
    "Speak up, and then some",
    "Shout",
    "Say \"HEY YOU!,\" e.g.",
    "Cheerleader's cheer",
    "Cheerleader's cheer",
    "Scream",
    "Raise the volume?",
    "Raise one's voice",
    "Holler",
    "\"Go, team, go!,\" e.g.",
    "Cheerleader's cheer",
    "Hoot and holler",
    "Tarzan trademark",
    "\"2, 4, 6, 8 - Who do we appreciate?,\" e.g.",
    "Scream and holler",
    "Whoop",
    "\"Gimme a C! ...,\" e.g.",
    "Holler",
    "Long-distance call?",
    "Holler",
    "\"Gimme a C ...!,\" e.g.",
    "Shout",
    "Cry",
    "Shout",
    "Whoop",
    "Holler",
    "What fans may do",
    "Whoop",
    "Try to reach the hard-of-hearing?",
    "Cheerleader's practice",
    "Cheerleaders' practice",
    "Cheerleader's bit",
    "Cry out loud",
    "Bark",
    "Give a hoot",
    "Vociferate",
    "Cheerleader's routine",
    "\"Gimme an A!...,\" e.g.",
    "Raise the roof",
    "\"Gimme a C!\" etc.",
    "Cheerleader's cheer",
    "Cry",
    "Cry out loud",
    "Protest in no uncertain terms",
    "\"Gimme a C...!\" is one",
    "Speak to the hard-of-hearing?",
    "\"Gimme an A...\", e.g.",
    "Scream",
    "Clamor",
    "Scream",
    "Holler",
    "Cheerleader's cheer",
    "Cheerleader's routine",
    "Holler",
    "Rebel follower",
    "Make oneself heard in the din",
    "Cheerleaders' number"
  ],
  "aziz": [
    "Ansari of \"Parks and Recreation\"",
    "Former Iraqi V.I.P. Tariq ___",
    "Comic Ansari",
    "Actor Ansari",
    "___ Ansari of \"Parks and Recreation\"",
    "Longtime adviser to Saddam Hussein",
    "Doctor in \"A Passage to India\"",
    "Iraqi V.I.P. Tariq ___",
    "\"A Passage to India\" doctor",
    "Abdul-___ (first Saudi king)"
  ],
  "vonn": [
    "Winter Olympics star Lindsey",
    "Skier Lindsey with three Olympic medals"
  ],
  "tars": [
    "Blackens",
    "Blackens with goo",
    "Smears",
    "Sea dogs",
    "Covers with black goo",
    "Smears, as a reputation",
    "Popeye and others",
    "Blackens",
    "Ones whose assent is 26-Across",
    "Besmirches",
    "Smears",
    "Surfaces, in a way",
    "Blackens, as a reputation",
    "Seamen",
    "Sailors",
    "Hands on deck",
    "Besmirches",
    "Besmirches",
    "Sailors",
    "Hold hands?",
    "Deck hands",
    "Clippers' skippers, e.g.",
    "Hold hands?",
    "Swabbies",
    "20-Across's crew",
    "Old salts",
    "Crew team?",
    "Sailors",
    "Swabbies",
    "Blackens",
    "Hands on deck",
    "Crew members",
    "Blackens",
    "Old salts",
    "Ship's crew",
    "Surfaces, in a way",
    "Mariners",
    "Blackens",
    "Hands at sea",
    "Gobs",
    "Pine products",
    "Sailors",
    "Salts",
    "Swabbies",
    "Besmirches",
    "Bounty hunters?",
    "Besmears",
    "Sailors",
    "Crew on the high seas",
    "Crewmen",
    "Blackens",
    "Gobs",
    "Besmirches",
    "Gobs",
    "Gobs",
    "Punishes in an old way",
    "Punishes publicly",
    "Cigarette substances",
    "Gobs",
    "Salts"
  ],
  "mete": [
    "Ration, with \"out\"",
    "Portion",
    "Give (out)",
    "Ration",
    "Parcel (out)",
    "Dole (out)",
    "Dole (out)",
    "Measure (out)",
    "Portion (out)",
    "Allocate, with \"out\"",
    "Apportion",
    "Parcel (out)",
    "Pass (out)",
    "Dole (out)",
    "Dole (out)",
    "Ration (out)",
    "Dole",
    "Allot, with \"out\"",
    "Parcel (out)",
    "Apportion, with \"out\"",
    "Parcel (out)",
    "Dish (out)",
    "Deal (out)",
    "Hand (out)",
    "Deal (out)",
    "Parcel (out)",
    "Parcel (out)",
    "Distribute",
    "Measure (out)",
    "Parcel",
    "Apportion, with \"out\"",
    "Parcel",
    "Parcel (out)",
    "Apportion, with \"out\"",
    "Allocate, with \"out\"",
    "Parcel",
    "Deal (out)",
    "Give (out)",
    "Parcel (out)",
    "Apportion, with \"out\"",
    "Hand (out)",
    "Allot, with \"out\"",
    "Dole (out)",
    "Parcel",
    "Parcel",
    "Dole, with \"out\"",
    "Measure (out)",
    "Measure",
    "Allocate, with \"out\"",
    "Allot, with \"out\"",
    "Dole (out)",
    "Dole",
    "Dole",
    "Dole (out)",
    "Dish (out)",
    "Parcel",
    "Distribute, with \"out\"",
    "Ration",
    "Allocate, with \"out\"",
    "Parcel (out)",
    "Ration (out)",
    "Dole",
    "Distribute, with \"out\"",
    "Allot",
    "Dole (out)",
    "Dole (out)",
    "Pass (out)",
    "Apportion",
    "Apportion",
    "Distribute",
    "Deal (out)",
    "Allocate",
    "Apportion",
    "Parcel (out)",
    "Dish (out)",
    "Measure out"
  ],
  "amcs": [
    "Some chain theaters",
    "Old Gremlins and Hornets",
    "Some movie theaters",
    "Gremlins and Pacers",
    "Gremlins of the '70s, e.g.",
    "Ramblers and Hornets",
    "Gremlins and Hornets of old autodom",
    "Javelins and Hornets, e.g.",
    "Some movie theaters",
    "Gremlins and Pacers",
    "Gremlins, Pacers and others",
    "Gremlins and Hornets",
    "Gremlins and Pacers",
    "Pacer and Rambler",
    "Gremlins and others, for short",
    "Gremlins, Pacers, etc."
  ],
  "knew": [
    "Was sure of",
    "Didn't doubt a bit",
    "Had down",
    "Didn't doubt",
    "Was in the loop",
    "Had by heart",
    "Slept with, biblically",
    "Had down pat",
    "Had more than an inkling",
    "Didn't just guess",
    "\"I ___ it!\"",
    "Was in on",
    "Had down pat",
    "Grasped",
    "Apprehended",
    "Sensed",
    "Grasped",
    "Had down cold",
    "\"I ___ it!\"",
    "Had more than a feeling",
    "Had down",
    "Had down",
    "Had a hunch",
    "Was friends with",
    "Had down pat",
    "Had down",
    "Didn't just guess",
    "Had down pat",
    "Had down cold",
    "Understood",
    "Had down cold",
    "Recognized",
    "Didn't just guess",
    "Perceived",
    "Had a gut feeling",
    "Was in on",
    "Was able to answer on a test",
    "Was positive",
    "Sensed",
    "Apprehended",
    "Experienced",
    "Understood",
    "Understood",
    "Was in the loop",
    "Had down",
    "Didn't guess",
    "Had no doubts",
    "Had down",
    "Was aware of",
    "Grasped",
    "Felt in one's bones",
    "Was friends with",
    "Socialized with",
    "Had no doubt"
  ],
  "dart": [
    "Item that can be blown or thrown",
    "Move like a flash",
    "What might be tipped at a bar",
    "Missile aimed at a bull's-eye",
    "Scamper",
    "Make a quick move",
    "Chute",
    "Move quickly",
    "Bar flier",
    "Bolt",
    "Something thrown at a bull's-eye",
    "One going for a board position?",
    "It may be thrown at a corkboard",
    "Blowgun ammunition",
    "What eyes and pedestrians may do",
    "What tiny fish and eyes do",
    "Zip around",
    "It has feathers and flies",
    "Objet ___",
    "Flit",
    "Zip",
    "Feathered flier",
    "Object thrown in a pub",
    "It has feathers and flies",
    "Tranquilizer gun projectile",
    "Zip",
    "It may hit a bull's-eye",
    "Missile that might be tipped with curare",
    "Pub projectile",
    "It has feathers and flies",
    "Blowgun ammo",
    "Corrida sticker",
    "Feathered missile",
    "Feathered missile",
    "Cross against traffic, e.g.",
    "It's typically thrown eight feet",
    "Feathered missile",
    "Pub projectile",
    "1960's-70's Dodge",
    "Bull's-eye hitter",
    "Old Dodge",
    "It's thrown at a bull's-eye",
    "Zip",
    "Zip",
    "Scurry",
    "Bull's-eye hitter",
    "Primitive weapon",
    "It has feathers and flies",
    "Feather's place",
    "What shifty eyes do",
    "What some eyes do",
    "Blowgun ammo",
    "Pub missile",
    "Shoot",
    "Old Dodge",
    "Blowgun ammo",
    "Bull's-eye hitter",
    "Old Dodge model",
    "Pub throw",
    "Tapered seam",
    "Pub missile",
    "Old Dodge",
    "Move like a dragonfly",
    "Flit",
    "Bull's-eye hitter"
  ],
  "pixy": [
    "___ Stix",
    "Sprite",
    "___ Stix (powdered candy brand)",
    "Sprite",
    "Sprite",
    "Fairy",
    "Folklore figure"
  ],
  "remi": [
    "Do, ___, fa ...",
    "Scale sequence",
    "Segment of the 47-Across",
    "Notes after do",
    "Do followers",
    "Do followers",
    "Notes after do",
    "Do followers",
    "Do trailers?",
    "Notes after do",
    "Do followers",
    "Do follower",
    "Do followers",
    "Do-___ (moolah)",
    "Scale sequence",
    "Do follow-up?",
    "Singers' syllables",
    "Do-___ (money)",
    "Notes after do",
    "Do-___ (cabbage)",
    "Apostle of the Franks",
    "Do-fa filler",
    "The Apostle of the Franks"
  ],
  "lese": [
    "___-majeste",
    "___-majeste",
    "Injured: Fr.",
    "Start of treason?",
    "Start of treason?",
    "Un crime de ___-humanite",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "Majesty lead-in",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-humanite",
    "___-majeste",
    "___-majeste",
    "Suffix with Congo",
    "___-majeste",
    "Suffix with Congo",
    "___-majeste",
    "___-majeste",
    "___-humanite",
    "Suffix with Congo",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "Literally, \"injured\"",
    "___-majeste",
    "___-humanite: Fr.",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "Literally, \"injured\"",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-humanite (crime against humanity): Fr.",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___ majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "___-majeste",
    "Literally, \"injured\"",
    "___-majeste",
    "___-majeste",
    "Majesty start",
    "___ majeste",
    "___ majeste",
    "Majesty lead-in",
    "___-majeste",
    "___ majeste",
    "___-majeste",
    "___ - majeste",
    "___ -majeste",
    "Majesty preceder",
    "___ majesty",
    "Majesty's start",
    "___ -majeste",
    "Majesty lead-in"
  ],
  "egal": [
    "The same as in France",
    "Alike: Fr.",
    "Alike, in Paris",
    "Even, to Yvonne",
    "Like three and trois",
    "Yves's \"even\"",
    "Like un + quatre vis-a-vis deux + trois",
    "Even: Fr.",
    "Even in Paris?",
    "Even, in Evreux",
    "Alike: Fr.",
    "Neither more nor less, in France",
    "Alike: Fr.",
    "Even around the Seine?",
    "The same to vous?",
    "Not different",
    "It's the same in Paris",
    "Even, to Yves",
    "Not different",
    "Alike: Fr.",
    "Even, to Yvette",
    "Same, in Somme",
    "Alike, to Andre",
    "On a par, in Orleans",
    "Alike: Fr.",
    "Even, to Yves",
    "Alike, in Alencon",
    "On a par, in Paris",
    "It's all the same to moi",
    "Even, to Yvonne",
    "Alike, to Jacques",
    "On even terms, old-style",
    "Even, to Yvonne"
  ],
  "circ": [
    "Newspaper fig.",
    "Magazine audience fig.",
    "Newspaper sales fig.",
    "Magazine fig.",
    "Mag.'s statistic",
    "Magazine fig.",
    "Mag. sales info",
    "Magazine figure, for short",
    "No. on which a magazine's ad rates are based",
    "Magazine fig.",
    "No. of People?",
    "Mag. publisher's concern",
    "Newspaper no.",
    "Magazine figure, for short",
    "Publication concern: Abbr.",
    "Mag. sales info",
    "Publisher's concern: Abbr.",
    "About: Abbr."
  ],
  "misc": [
    "Line in an expense report: Abbr.",
    "1099-___ (I.R.S. form)",
    "Catchall abbr.",
    "Form 1099-___",
    "Other: Abbr.",
    "This and that: Abbr.",
    "Category for leftovers: Abbr.",
    "This and that: Abbr.",
    "Odds and ends: Abbr.",
    "This and that: Abbr.",
    "Grab bag: Abbr.",
    "Catchall abbreviation",
    "Other: Abbr.",
    "Like items in a junk drawer: Abbr.",
    "I.R.S. form 1099-___",
    "Last budget category, usually: Abbr.",
    "Other: Abbr.",
    "Catchall category: Abbr.",
    "Catchall abbr.",
    "Catchall: Abbr.",
    "\"Other\" category: Abbr.",
    "Junk drawer abbr.",
    "Common file name",
    "Sundry: Abbr.",
    "Catchall category: Abbr.",
    "Odds and ends: Abbr.",
    "Catalogue abbr.",
    "Etc. category",
    "Hodgepodge: Abbr."
  ],
  "dets": [
    "Ones on cases: Abbr.",
    "People with badges: Abbr.",
    "Police dept. employees",
    "Investigators: Abbr.",
    "Police dept. figs.",
    "Some \"CSI\" figs.",
    "Some police officers: Abbr.",
    "Police staff: Abbr.",
    "Priv. eyes",
    "\"N.Y.P.D. Blue\" offs."
  ],
  "eeks": [
    "Cries of faux terror",
    "Comics exclamations",
    "Startled squeals",
    "Cartoonish cries",
    "Horror movie sounds",
    "Cartoon cries",
    "Fun house outbursts",
    "Squeals of alarm",
    "Comic strip cries",
    "Cartoon squeals",
    "Fun house cries",
    "Comics shrieks",
    "Alarmed squeals",
    "Mice might elicit them",
    "Mock-scared cries",
    "Squealed cries",
    "Cries of alarm",
    "Scared sounds",
    "Comics cries",
    "Mice-inspired yelps",
    "Funhouse cries",
    "Shouts of fear",
    "Funhouse sounds",
    "Frightful sounds",
    "Fun house sounds",
    "Cries of horror",
    "Cries in the comics",
    "Fun-house cries",
    "Comic screams"
  ],
  "kubo": ["\"___ and the Two Strings\" (2016 animated film)"],
  "cord": [
    "Parachute part",
    "Thick string",
    "Thin rope",
    "Clothesline, e.g.",
    "Carrier of electricity",
    "Unit of firewood",
    "Drawstring, e.g.",
    "Part of a tennis net",
    "Literally, with 63-Across, distinguished chef",
    "One of a vocal pair",
    "Telephone attachment",
    "Peace",
    "Drapery attachment",
    "Plug's place",
    "Appliance attachment",
    "Parachute part",
    "Parachute part",
    "128 cubic feet",
    "One may be on the phone",
    "Telephone part",
    "Firewood measure",
    "Telephone attachment",
    "It may lead to an outlet",
    "Part of a parachute",
    "128 cubic feet",
    "Wood stack",
    "Part of a parachute",
    "Twine",
    "Big stack of firewood",
    "Tendon",
    "Electricity carrier",
    "Classic '30s auto"
  ],
  "kans": [
    "Neighbor of Nebr.",
    "Jayhawks' home: Abbr.",
    "Nebr. neighbor",
    "Home of McConnell A.F.B.",
    "Where Manhattan is: Abbr.",
    "Manhattan's locale: Abbr.",
    "Okla. neighbor",
    "It's north of Okla."
  ],
  "inez": [
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "1966 hurricane",
    "\"The Gondoliers\" nurse",
    "\"Little\" sister in \"Hairspray\"",
    "Mother of Don Juan",
    "Deadly 1966 hurricane with a Spanish-derived name",
    "Destructive 1966 hurricane",
    "Don Juan's mother",
    "Don Juan's mother",
    "Deadly 1966 hurricane",
    "Don Juan's mother",
    "Mother of Don Juan",
    "Don Juan's mother",
    "\"Mockingbird\" singer Foxx, 1963",
    "Memorable 1966 hurricane",
    "Don Juan's mother",
    "\"Mockingbird\" singer Foxx",
    "\"Mockingbird\" singer Foxx, 1963",
    "\"Il Trovatore\" soprano",
    "Don Juan's mother",
    "Friend of Leonora in \"Il Trovatore\"",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "Don Juan's mother",
    "\"The Gondoliers\" nurse",
    "Don Juan's mother",
    "Don Juan's mother",
    "\"Mockingbird\" singer Foxx",
    "Don Juan's mother",
    "Don Juan's mother",
    "Lady in Meyerbeer's \"L'Africaine\""
  ],
  "husk": [
    "Shell",
    "Ear covering",
    "Covering of a corn ear",
    "Corn covering",
    "Ear flap?",
    "Corn covering",
    "Corn covering"
  ],
  "four": [
    "Only digit in the ZIP code for Newton Falls, Ohio",
    "Purple pool ball",
    "{See Notepad}",
    "The number of letters in this clue's answer",
    "Number of Teenage Mutant Ninja Turtles",
    "With 34-Across, another way to make 60-Across",
    "Near-impossible N.F.L. point total",
    "An hour before office closing time, maybe",
    "Number in a quartet",
    "Late afternoon hour",
    "Teatime, maybe",
    "Time that little Susie is woken in the 1957 hit \"Wake Up Little Susie\"",
    "Two by two?",
    "Number of pecks in a 34-Down",
    "Number in a quartet",
    "With 69-Across, childish taunt ... and a homophonic hint to the answers to the asterisked clues",
    "Minimum number of times each letter of the alphabet appears in this puzzle's solution",
    "Quartet number",
    "... of a 1954 Lincoln stamp",
    "See 33-Across",
    "Afternoon hour",
    "Afternoon hour for tea",
    "Tea time, perhaps",
    "Count in a march",
    "Lou Gehrig, on the diamond",
    "Late afternoon",
    "Lou Gehrig, on the diamond",
    "Lou Gehrig's number",
    "Quad number"
  ],
  "furl": [
    "Roll up, as a sail",
    "Roll up",
    "Roll up",
    "Secure neatly, as an umbrella",
    "Roll up and bind",
    "Roll up",
    "Roll up, as a flag",
    "Roll up, as a sail",
    "Roll up",
    "Roll and bind",
    "Prepare for storage, as papyrus",
    "Roll up",
    "Roll up"
  ],
  "toby": [
    "Country singer ___ Keith",
    "Country singer Keith",
    "Country singer Keith",
    "___ Ziegler, Richard Schiff's Emmy-winning role on \"The West Wing\"",
    "___ Keith, singer with 20 #1 country hits",
    "\"Twelfth Night\" sir",
    "Man-shaped mug",
    "Mug with a mug",
    "Sir ___ Belch of \"Twelfth Night\"",
    "Shakespeare's Sir ___ Belch",
    "Mug in a pub",
    "Mug with a mug",
    "Decorative mug",
    "Kunta Kinte's slave name",
    "Illustrated mug",
    "Beer mug"
  ],
  "toff": [
    "Dandy, on Downing Street",
    "British fop",
    "Elegantly dressed bloke",
    "Stylish gent, in Britain"
  ],
  "oncd": [
    "Like some music collections",
    "How music can be stored",
    "Burned, in a way",
    "How a lot of music got sold in the 1990s and early 2000s",
    "Like some audiobooks",
    "How a lot of Generation X's music was released",
    "Like much music of the '90s",
    "How much 1990s music was issued",
    "Like much music, starting in the late 1980s",
    "Like many music reissues",
    "How albums may be stored",
    "One way to store data",
    "How many oldies get rereleased",
    "One way to put out an album",
    "Ready for a drive?",
    "Modern way to put out an album",
    "One way to store data",
    "How most computer software is sold",
    "Like most music in record stores nowadays",
    "Like much music, today",
    "How Elvis albums are rereleased",
    "How a lot of modern music is sold"
  ],
  "faro": [
    "Gambling game",
    "Gambling card game",
    "Gambling card game that up to 10 may comfortably play at once",
    "Betting game popular with Wyatt Earp and Doc Holliday",
    "Game similar to baccarat",
    "Gambling card game",
    "Card game with a bank",
    "Casino card game",
    "Game for which Wyatt Earp and Doc Holliday were once dealers",
    "Card game with a bank",
    "Baccarat cousin",
    "Old West casino game",
    "Casino offering",
    "Old West gambling game",
    "Baccarat alternative",
    "Baccarat alternative",
    "Gambling game enjoyed by Wyatt Earp",
    "Game dealt by Doc Holliday",
    "Card game with a bank",
    "Casino card game",
    "Game sometimes called \"bucking the tiger\"",
    "Popular game in Old West saloons",
    "Casino game",
    "Gambling game",
    "Baccarat alternative",
    "Casino game",
    "Card game with the 13 spades laid out",
    "Game in which the 13 spades are laid faceup",
    "Card game for up to 10 players",
    "Bettor's card game",
    "King Tut's favorite card game?",
    "Betting game",
    "Game in which all the spades are laid out",
    "Game sometimes called \"bucking the tiger\"",
    "Card game with a bank",
    "Game with a banker",
    "Casino game",
    "Casino game",
    "Betting game",
    "Game often played with hexagonal chips",
    "Gamblers' game",
    "Vegas game"
  ],
  "curd": [
    "Product of coagulation",
    "Cottage cheese morsel",
    "Bean ___",
    "Sour milk product",
    "Tofu base",
    "Coagulate",
    "Dairy morsel",
    "Bit for Miss Muffet",
    "Congeal",
    "Bean ___",
    "Bean ___",
    "Bean ___ (tofu)",
    "Cheese base",
    "Morsel for Miss Muffet",
    "Kind of cheese",
    "Milk product"
  ],
  "gman": [
    "Fed",
    "F.B.I. agent, in old slang",
    "Extra in 2009's \"Public Enemies\"",
    "Gangster tracker",
    "Fed",
    "F.B.I. agent, informally",
    "F.B.I. worker, informally",
    "Gangster catcher, informally",
    "Agent under Hoover, informally",
    "Fibbie",
    "Gangbuster",
    "Sting figure",
    "Old pulp fiction hero",
    "*Ace ___ Stories (old detective pulp magazine)",
    "F.B.I. agent",
    "Fed",
    "Hood catcher",
    "Fed",
    "F.B.I. agent",
    "Fed",
    "F.B.I. investigator, slangily",
    "Undercover type",
    "F.B.I. guy",
    "Fed",
    "F.B.I. operative",
    "Fed",
    "Gangster follower",
    "Crimebuster, informally",
    "Fed",
    "\"The X-Files\" extra",
    "Fed",
    "Pulp novel hero",
    "Gangster chaser",
    "Crime buster",
    "Fed. agent",
    "Dillinger fighter",
    "Fed",
    "Fed",
    "Crimebuster",
    "Hoover employee?"
  ],
  "actv": [
    "When Lady Macbeth sleepwalks",
    "When Brutus dies",
    "When Macbeth delivers the \"Tomorrow, and tomorrow, and tomorrow\" soliloquy",
    "When Macduff slays Macbeth",
    "End of \"Macbeth\" or \"Othello\"",
    "When Hamlet says \"Alas, poor Yorick! I knew him, Horatio\"",
    "When Hamlet says \"The rest is silence\"",
    "When Macbeth dies",
    "When Hamlet dies",
    "When Othello kills himself",
    "End of a Shakespeare play",
    "When Hamlet dies",
    "When Hamlet dies",
    "When Hamlet dies in \"Hamlet\""
  ],
  "neve": [
    "Campbell of \"Scream\"",
    "Actress Campbell of the \"Scream\" series",
    "Actress Campbell",
    "Actress Campbell",
    "Campbell of \"Scream\"",
    "Actress Campbell of \"Scream\"",
    "Campbell of \"House of Cards\"",
    "Actress Campbell",
    "Campbell of \"Party of Five\"",
    "Actress Campbell",
    "Campbell of \"Scream\"",
    "Actress Campbell",
    "\"Scream\" actress Campbell",
    "Campbell of \"Party of Five\"",
    "Actress Campbell",
    "Campbell of \"Three to Tango\"",
    "Campbell of \"Scream\"",
    "Campbell of the \"Scream\" movies",
    "Actress Campbell",
    "\"Scream\" star Campbell",
    "Campbell of ?ôParty of Five?ö",
    "Actress Campbell",
    "Campbell of \"Wild Things\"",
    "Actress Campbell",
    "Actress Campbell",
    "\"Scream\" star Campbell",
    "Actress Campbell",
    "Campbell of \"Party of Five\"",
    "Julia?Æs portrayer on ?ôParty of Five?ö",
    "Actress Campbell",
    "Actress Campbell"
  ],
  "cine": [
    "Cannes offering",
    "Endeavor recognized by the Cesar awards",
    "Cannes festival focus",
    "Art house theater",
    "French film",
    "French filmdom",
    "French filmdom",
    "French filmdom",
    "Field of Jean-Luc Godard",
    "Cesar subject",
    "Cannes film",
    "Film",
    "Cannes showing",
    "Field for Gerard Depardieu and Audrey Tautou",
    "What Cesar awards honor",
    "Francois Truffaut's field",
    "Jean Renoir's field",
    "French filmdom",
    "Place to watch Truffaut, e.g.",
    "Cannes subject",
    "Cannes film",
    "Cannes presentation",
    "Francois Truffaut's field",
    "French film",
    "Film",
    "Art movie theater",
    "Motion picture",
    "French film",
    "Motion picture",
    "Gerard Depardieu work",
    "Movie",
    "Where to see Alain Delon or Genevieve Bujold",
    "Movie theater",
    "French film",
    "Film",
    "Motion picture",
    "Movie theater",
    "Movie theater",
    "Theater",
    "Movie prefix",
    "Where to see Gerard Depardieu",
    "Art film theater",
    "Motion picture",
    "Motion picture",
    "Film",
    "French theater"
  ],
  "kern": [
    "Adjust the spacing between letters",
    "\"Ol' Man River\" composer Jerome",
    "Jerome who composed \"Ol' Man River\"",
    "Typeface projection",
    "Font menu choice",
    "\"The Way You Look Tonight\" composer",
    "\"Smoke Gets in Your Eyes\" composer",
    "\"Show Boat\" composer",
    "Jerome who composed \"Ol' Man River\"",
    "\"Show Boat\" composer Jerome",
    "\"Can't Help Lovin' Dat Man\" composer",
    "Jerome who composed \"The Last Time I Saw Paris\"",
    "\"A Fine Romance\" composer",
    "\"Show Boat\" composer",
    "\"All the Things You Are\" composer",
    "\"Ol' Man River\" composer",
    "Jerome who composed \"Show Boat\"",
    "\"Show Boat\" composer",
    "\"Show Boat\" composer",
    "Composer of Broadway's \"Sunny\"",
    "Composer Jerome",
    "\"Show Boat\" composer",
    "\"The Last Time I Saw Paris\" composer",
    "\"Show Boat\" composer",
    "\"Show Boat\" composer",
    "\"Show Boat\" composer",
    "Composer Jerome",
    "\"Show Boat\" composer",
    "\"Show Boat\" composer",
    "\"The Last Time I Saw Paris\" composer",
    "\"Roberta\" composer"
  ],
  "tyro": [
    "Noob",
    "Veteran's opposite",
    "Newbie",
    "\"Noob\"",
    "*Cubit",
    "Hardly a vet",
    "One on probation, maybe",
    "Tenderfoot",
    "Beginner",
    "Rookie",
    "Beginner",
    "Newbie",
    "Novice",
    "Newbie",
    "Trainee",
    "Newbie",
    "Pro's opposite",
    "New kid on the block",
    "Beginner",
    "Beginner",
    "Newbie",
    "Newbie",
    "Apprentice",
    "Rookie",
    "Green one",
    "Novice",
    "Beginner",
    "Beginner",
    "Neophyte",
    "Neophyte"
  ],
  "knox": [
    "Fort ___ (gold depository)",
    "Fort ___ (gold depository)",
    "Fort ___, home of the U.S. Bullion Depository",
    "Kentucky's Fort ___",
    "Name attached to a Southeastern \"-ville\"",
    "U.S. fort with very tight security",
    "Kentucky's Fort ___",
    "Kentucky's Fort ___",
    "Washington's first secretary of war",
    "The \"K\" in James K. Polk",
    "One of the two characters in Dr. Seuss' \"Fox in Socks\"",
    "Kentucky's Fort ___",
    "Henry ___, first secretary of war",
    "The \"K\" of James K. Polk",
    "Fort ___ (gold repository)",
    "Presidential middle name",
    "The \"K\" in James K. Polk",
    "James ___ Polk",
    "N.F.L. coach known as \"Ground Chuck\"",
    "Secretary of state who initiated dollar diplomacy",
    "Fort ___ (gold site)",
    "Fort with a fortune",
    "Fort ___ (gold site)",
    "Fort ___ (gold repository)",
    "First Secretary of War",
    "The \"K\" of James K. Polk",
    "Fort ___ (gold depository site)",
    "Fort ___ (U.S. gold depository)",
    "Landon's running mate, 1936"
  ],
  "jovi": [
    "See 52-Across",
    "Rocker Jon Bon ___",
    "Bon ___",
    "Bon ___",
    "Bon ___",
    "Rock's Bon ___",
    "Rock's Bon ___",
    "Rock's Bon ___",
    "Rock's Bon ___"
  ],
  "odic": [
    "Of lyric poetry",
    "Lyrical, as poetry",
    "Keatsian, e.g.",
    "Like many of Pindar's works",
    "Horatian or Keatsian",
    "Like many works with \"To\" in their titles",
    "Like much of Horace's poetry",
    "Like much of Pindar's work",
    "Keatsian or Horatian",
    "Like much of Pindar's work",
    "Keatsian, e.g.",
    "Like some poetry",
    "Keatsian or Pindaric",
    "Lyrical, like a Pindar poem",
    "Like some of Keats's work",
    "Like some Keats works",
    "Like some Keatsian works",
    "Keatsian or Pindaric",
    "Lyrical",
    "Lyrically written",
    "Of lyric poetry",
    "Like Pindar's works",
    "Lyrical",
    "Like some Keats works",
    "Of old poetry",
    "Pindaric",
    "Like Pindar's work",
    "Of Pindar's work"
  ],
  "gape": [
    "Stare stupidly",
    "Stare slack-jawed",
    "Look of astonishment",
    "Goggle",
    "Look stunned",
    "Stare open-mouthed",
    "Stare slack-jawed",
    "Open wide",
    "Look shocked",
    "Goggle",
    "Wide breach",
    "Stare dumbfoundedly",
    "Bewildered look",
    "Act starstruck, say",
    "Stare in shock",
    "Display awe",
    "Hang open",
    "Open the mouth wide",
    "Open widely",
    "Be wide-open",
    "Show fixation, maybe",
    "Be slack-jawed",
    "Be wide open",
    "Hang open",
    "Stare",
    "Rubberneck",
    "Be slack-jawed",
    "Get an eyeful",
    "Goggle",
    "Gawk",
    "Rubberneck",
    "Stare agog",
    "Stare open-mouthed",
    "Goggle",
    "Stare with lax jaw",
    "Be in awe",
    "Stare",
    "Yawn",
    "Be wide open",
    "Open wide",
    "Stare stupidly",
    "Breach",
    "Stare open-mouthed"
  ],
  "foto": [
    "Snapshot, informally",
    "Pic",
    "Snap",
    "Something developed casually?",
    "Pic",
    "Shot, informally",
    "Picture, informally",
    "Shot, informally",
    "Pic",
    "Pic",
    "Picture, commercially",
    "Pic",
    "Shot, informally",
    "Snap",
    "Picture, informally",
    "Picture, commercially",
    "Snapshot, Mad. Ave.-style",
    "Picture, in commercial names"
  ],
  "fume": [
    "Be quietly angry",
    "Seethe",
    "Be furious",
    "Show anger",
    "Stew",
    "Be plenty angry",
    "Show anger",
    "Get steamed up",
    "Cause for opening a window",
    "See red",
    "Rage",
    "Sulk angrily"
  ],
  "rule": [
    "Be awesome",
    "Be awesome",
    "Reign",
    "Be absolutely awesome",
    "Be the best, in slang",
    "Govern",
    "\"No shoes, no shirt, no service,\" e.g.",
    "\"Collect $200 after passing Go,\" e.g.",
    "Be the bomb",
    "\"Employees must wash hands before returning to work,\" e.g.",
    "Line at a stationery store?",
    "Stickler's citation",
    "Make a call",
    "Be the best, in slang",
    "Announce a decision",
    "Reign",
    "Game player's concern",
    "Exclude, with \"out\"",
    "\"No shirt, no shoes, no service,\" e.g.",
    "Govern",
    "Occupy the throne",
    "\"Look twice before crossing,\" e.g.",
    "Lord over",
    "\"No shirt, no shoes, no service,\" e.g.",
    "Reign",
    "Eliminate, with \"out\"",
    "Listing in Hoyle's",
    "\"Look both ways before crossing,\" e.g.",
    "Reign",
    "Hold sway",
    "Three strikes and you're out, e.g.",
    "Call the shots",
    "Imperative",
    "Run things",
    "Hold sway",
    "Reign",
    "Raj",
    "Govern",
    "Preside over",
    "Be a monarch",
    "Be the master of",
    "Dominate",
    "Hold sway",
    "Govern",
    "Measuring stick",
    "Make a decision",
    "Govern",
    "Pattern",
    "Hold sway over",
    "It may begin \"Do not ...\"",
    "It may be standing",
    "Reign",
    "Be a monarch",
    "Reign",
    "Do or don't",
    "Hold sway",
    "Reign",
    "Decree",
    "Be a monarch",
    "Make a call",
    "\"First in, first out,\" e.g.",
    "Make a call",
    "Act as an arbiter",
    "Word with slide or home",
    "Be king over",
    "Have the throne",
    "Lay down the law",
    "Regnum",
    "Wear the crown",
    "Govern",
    "\"I before E except after C,\" e.g.",
    "Straight line",
    "Preside over",
    "Hold sway over",
    "Decree",
    "Referee's guideline",
    "Straight line",
    "\"___, Britannia\"",
    "Reign",
    "Dictum",
    "Principle",
    "Regulation",
    "Hold sway",
    "\"I before e, except after c,\" e.g.",
    "Golden, e.g."
  ],
  "cass": [
    "Mama ___",
    "Mama ___ Elliot",
    "Mama of song",
    "Mama ___ Elliot",
    "Lewis ___, 1848 Democratic candidate for president",
    "Musical Mama",
    "___ Gilbert, designer of the Supreme Court building",
    "Lewis ___, presidential also-ran of 1848",
    "Mama ___",
    "Lewis ___, loser to Zachary Taylor in 1848",
    "Musical Mama",
    "Mama ___ Elliot",
    "See 13-Down",
    "Whom Taylor defeated for president in 1848",
    "Buchanan's secretary of state",
    "Famous Mama",
    "Mama ___ of the Mamas and the Papas",
    "See 64-Down",
    "Mama ___ of the Mamas and the Papas",
    "Sinclair Lewis's ___ Timberlane",
    "Mama of the Mamas and the Papas",
    "Architect ___ Gilbert",
    "One of the Mamas and the Papas",
    "Game show panelist Peggy",
    "Big Mama",
    "1848 presidential candidate after whom eight U.S. counties are named",
    "Actress Peggy",
    "Singing Mama",
    "He ran against Taylor, 1848",
    "Elliot, of the Mamas and the Papas",
    "Elliot of the Mamas and the Papas",
    "Big Mama"
  ],
  "aman": [
    "Tom Wolfe's \"___ in Full\"",
    "Robert Bolt's \"___ for All Seasons\"",
    "Everyone included, after \"to\"",
    "Tom Wolfe's \"___ in Full\"",
    "Unanimously, after \"to\"",
    "\"___ in Full\" (Tom Wolfe novel)",
    "\"Walk Like ___\" (1963 hit)",
    "\"I'm ___\" (Bo Diddley song)",
    "Tom Wolfe novel \"___ in Full\"",
    "\"That's one small step for ___ ...\"",
    "\"Walk Like ___\"",
    "\"___ Apart,\" 2003 Vin Diesel flick",
    "Make ___ of",
    "With all agreeing, after \"to\"",
    "Without exception, after \"to\"",
    "\"___ Apart\" (2003 film)",
    "\"I Want ___\" (Rodgers and Hart song)",
    "Make ___ out of",
    "\"It's So Nice to Have ___ Around the House\"",
    "\"___ in Full\" (Tom Wolfe novel)",
    "Make ___ of",
    "To ___ (without exception)",
    "To ___ (unanimously)",
    "\"___ for All Seasons\"",
    "Quote, part 2",
    "The Four Seasons' \"Walk Like ___\"",
    "\"Walk Like ___\" (1963 hit)",
    "\"When___Loves a Woman\"",
    "To ___ (unanimously)",
    "Rodgers and Hart?Æs ?ôI Want ___?ö",
    "\"...I met ___ with seven wives\"",
    "\"___ for All Seasons\"",
    "\"___ for All Seasons\"",
    "\"That's one small step for ___ ...\""
  ],
  "fala": [
    "Presidential dog of the 1940s",
    "Start of a Christmas refrain",
    "Old presidential dog whose name starts a Christmas carol refrain",
    "F.D.R.'s dog",
    "Beginning of a seasonal refrain",
    "F.D.R.'s Scottie",
    "1940s White House dog",
    "Bo : Obama :: ___ : Roosevelt",
    "F.D.R. dog",
    "F.D.R. dog",
    "Start of a Christmas chorus",
    "F.D.R.'s dog",
    "Onetime White House pooch",
    "Famous White House pooch",
    "Scottie in the White House",
    "F.D.R.'s Scottie",
    "\"___ Speech\" (9/23/44 F.D.R. address)",
    "Start of a carol's refrain",
    "F.D.R.'s Scottie",
    "F.D.R.'s pooch",
    "White House Scottie",
    "40's White House name",
    "F.D.R.'s dog"
  ],
  "fogy": [
    "Traditionalist",
    "Old-fashioned sort",
    "Square type",
    "Fuddy-dud",
    "Geezer",
    "Geezer",
    "One who's out of touch",
    "Mossback",
    "Mossback",
    "Old ___",
    "Old ___ (not the modern type)",
    "Stick-in-the-mud"
  ],
  "blat": [
    "Beginner's trumpet sound",
    "Musical miscue",
    "Sheep's cry",
    "Unappealing trumpet sound",
    "Trombone honk, e.g.",
    "Speak raucously",
    "Unappealing trumpet sound",
    "Be noisy"
  ],
  "skim": [
    "Graze",
    "1% alternative",
    "Take the top off",
    "Take from the top",
    "Less than 1%, say",
    "Take in just the highlights, say",
    "Give the once-over",
    "Take off the top",
    "0%, in a way",
    "Milk option",
    "Far from whole",
    "Not peruse",
    "Less than 1%",
    "Glance through quickly",
    "0% fat, say",
    "Less than 1%",
    "Alternative to whole",
    "Alternative to whole or 2%",
    "Read over hurriedly",
    "Rake",
    "Read cursorily",
    "Kind of milk",
    "Read on the run",
    "Read rapidly",
    "Barely read",
    "Not fully digest",
    "Go once over lightly",
    "Take some off the top",
    "Fat-free, as milk",
    "Dairy-case choice",
    "Take from the top",
    "Take illegally",
    "Look at the highlights",
    "Conceal profits illegally",
    "Low-fat",
    "Alternative to whole"
  ],
  "moon": [
    "It \"monthly changes in her circled orb,\" in \"Romeo and Juliet\"",
    "Part of the DreamWorks logo",
    "Image in the poster for \"E.T. the Extra-Terrestrial\"",
    "Something Venus lacks",
    "Titan, Triton or Titania",
    "Get cheeky with?",
    "It has phases that are represented by the starts of 18-, 29-, 47- and 61-Across ... and by 1-Down",
    "Man in the ___",
    "A new one is nearly invisible",
    "Where the U.S. flag has flown since 1969",
    "Apollo program destination",
    "Sky light",
    "What Artemis is the goddess of",
    "Titania or Oberon, in space",
    "Project Apollo destination",
    "Spend time in idle reverie",
    "Apollo 11 goal",
    "It goes through many phases",
    "Butt out?",
    "Create an open-ended view?",
    "Pull up a seat for?",
    "Hold up one's end?",
    "Natural satellite",
    "Apollo 11's destination",
    "Outhouse door symbol",
    "Apollo target",
    "Sentimentalize",
    "Titan, to 9-Down",
    "See 1-Across",
    "Provide with a rear view?",
    "Its cycle is indicated by the starts of 20-, 32-, 40- and 55-Across",
    "In romance poetry, a frequent rhyme for June",
    "Apollo 11 destination",
    "It turns the tide",
    "Object rising in a Van Gogh landscape",
    "Night light provider",
    "Skylight?",
    "It has a bright side",
    "Gaze dreamily",
    "It turns the tide",
    "1969 target",
    "Gene Cernan was the last man there",
    "Phobos, to Mars",
    "It may be new or blue",
    "Place for a halo",
    "Put the end first?",
    "\"Destination\" in a 1950 sci-fi film",
    "Phobos, to Mars",
    "Part of a syzygy",
    "Lycanthrope's catalyst",
    "Bring up the rear?",
    "Apollo 11's goal",
    "Tide controller",
    "Waxer, of a sort",
    "Ganymede, e.g.",
    "Light for serenaders",
    "Gaze dreamily"
  ],
  "fava": [
    "Kind of bean mentioned memorably in \"The Silence of the Lambs\"",
    "Hardy bean",
    "___ bean",
    "___ bean",
    "___ bean",
    "___ bean",
    "Broad bean",
    "___ bean",
    "___ bean",
    "Kind of bean",
    "___ bean",
    "Kind of bean",
    "Falafel bean",
    "Broad bean",
    "Kind of bean",
    "Kind of bean",
    "Kind of bean",
    "Kind of bean",
    "Kind of bean",
    "Kind of bean",
    "Broad bean",
    "Broad bean",
    "Broad bean",
    "___ bean"
  ],
  "vida": [
    "\"Livin' la ___ Loca\"",
    "\"Livin' la ___ Loca\"",
    "\"Livin' La ___ Loca\"",
    "\"Livin' La ___ Loca\"",
    "___ Blue, 1971 Cy Young Award winner",
    "\"Livin' La ___ Loca\"",
    "Cy Young Award winner Blue",
    "Manuel de Falla opera \"La ___ Breve\"",
    "1971 Cy Young Award winner ___ Blue",
    "\"Livin' la ___ Loca\"",
    "Ricky Martin's \"Livin' La ___ Loca\"",
    "Dali's life?",
    "\"Mi ___ Loca\" (Pam Tillis hit)",
    "Blue of baseball"
  ],
  "gelt": [
    "Hanukkah coins",
    "Traditional Hanukkah gift for kids",
    "Money, in slang",
    "Hanukkah \"moolah\"",
    "Lucre",
    "Loot",
    "Moolah",
    "Hanukkah largesse",
    "Moolah",
    "Hanukkah goodies",
    "Moola",
    "Do-re-mi",
    "Hanukkah gift",
    "Moolah",
    "Moolah",
    "Spondulix",
    "Dinero",
    "Moolah",
    "Moolah",
    "Cabbage",
    "Traditional Hanukkah gift",
    "Moola",
    "Moola",
    "Moola",
    "Moolah",
    "Shekels",
    "Cabbage",
    "Yiddish cash",
    "Moola",
    "Moolah"
  ],
  "tios": [
    "Spanish uncles",
    "Uncles, in Acapulco",
    "Mexican uncles",
    "Uruguayan uncles",
    "Madre's brothers",
    "Members of la familia",
    "Madre's brothers",
    "Parents' hermanos",
    "Spanish uncles",
    "Madre's hermanos",
    "Members of la familia",
    "Reunion attendees",
    "Spanish uncles",
    "Monterrey kin",
    "Hombres en la familia",
    "Uncles, in Cuba",
    "Los hermanos de la madre",
    "Castilian kinsmen",
    "Padre's brothers",
    "Castilian kinsmen"
  ],
  "koch": [
    "Former New York City mayor with the autobiography \"Mayor\"",
    "\"Mayor\" memoirist",
    "___ Industries (oil and gas giant)",
    "Author of the 1984 memoir \"Mayor\"",
    "___ brothers, big political donors",
    "___ brothers, noted political donors",
    "___ Industries (oil giant)",
    "Mayor who later served as judge on \"The People's Court\"",
    "\"Citizen ___\" (1992 autobiography)",
    "Mayor who co-wrote \"Murder at City Hall\"",
    "1970's-80's Big Apple mayor",
    "\"How'm I doin'?\" asker",
    "Mayor Dinkins's predecessor",
    "\"The People's Court\" judge, now",
    "Ed who asked \"How'm I doin'?\"",
    "Big Apple ex-mayor"
  ],
  "mann": [
    "Horace who championed public education",
    "Abolitionist Horace",
    "Golfer Carol who won the 1965 U.S. Women's Open",
    "\"Doctor Faustus\" novelist",
    "\"Doctor Faustus\" novelist",
    "Manfred ___ (1960s band)",
    "Michael who directed \"The Insider\"",
    "Michael who directed the 2015 film \"Blackhat\"",
    "\"Doctor Faustus\" novelist Thomas",
    "Thomas who wrote \"Buddenbrooks\"",
    "Thomas who wrote \"Death in Venice\"",
    "\"The Magic Mountain\" novelist Thomas",
    "Actor Thomas of \"Me and Earl and the Dying Girl\"",
    "\"Buddenbrooks\" novelist",
    "Thomas who wrote \"Death in Venice\"",
    "\"Ali\" director Michael",
    "\"Doctor Faustus\" novelist",
    "\"Buddenbrooks\" novelist",
    "Singer Aimee",
    "Jazz flutist Herbie",
    "Educator Horace",
    "19th-century educator Horace",
    "\"Death in Venice\" author Thomas",
    "Singer/songwriter Aimee",
    "\"Doctor Faustus\" novelist",
    "\"Doctor Faustus\" novelist",
    "Thomas who wrote \"The Magic Mountain\"",
    "Jazz's Herbie",
    "\"Buddenbrooks\" author",
    "1929 Literature Nobelist",
    "Pop singer Manfred ___",
    "\"The Magic Mountain\" novelist",
    "\"Death in Venice\" author",
    "Educator Horace",
    "\"Joseph and His Brothers\" novelist",
    "Jazzman Herbie",
    "\"Death in Venice\" author",
    "\"Death in Venice\" author",
    "\"Buddenbrooks\" author",
    "Educator Horace",
    "\"Death in Venice\" author Thomas",
    "\"Buddenbrooks\" novelist",
    "\"Death in Venice\" author",
    "\"Buddenbrooks\" novelist",
    "Tony-winning producer Theodore",
    "\"Buddenbrooks\" novelist Thomas",
    "\"Buddenbrooks\" author",
    "\"Buddenbrooks\" author",
    "\"Death in Venice\" author",
    "?ôThe Magic Mountain?ö novelist",
    "Jazzman Herbie",
    "\"Confessions of Felix Krull\" author",
    "\"Buddenbrooks\" author",
    "Jazz flutist Herbie",
    "\"The Magic Mountain\" author"
  ],
  "pump": [
    "Place to get your fill?",
    "What you might do with gas or a fist",
    "Question persistently",
    "Shell fixture",
    "The heart, essentially",
    "Bilge need",
    "Gas station fixture",
    "Heart, essentially",
    "Lady's shoe",
    "Hose site",
    "It may cause inflation",
    "Ship's engine part",
    "Low-cut shoe"
  ],
  "clot": [
    "Thrombus, more familiarly",
    "Congeal",
    "Artery problem",
    "What \"thrombus\" means to a doctor",
    "Congeal, as blood",
    "Curdle",
    "Solidify",
    "Feature of Devonshire cream",
    "Congeal, as blood",
    "Circulatory block",
    "Cardiologist's concern",
    "Thicken, as blood",
    "Blood worry",
    "Target of a blood thinner",
    "What milk will do if you add lemon juice",
    "Flow stopper",
    "Cause for cardiological concern",
    "Circulation problem",
    "Form a mass",
    "Circulation blocker",
    "Cardiological concern",
    "Medical condition treated by thrombolysis",
    "Blood circulation problem",
    "Coagulate",
    "Arterial blockage",
    "Thrombosis cause",
    "Stick together",
    "Congealment",
    "Set",
    "Blood problem",
    "Cluster",
    "Coagulate",
    "Flow stopper",
    "Become obstructed",
    "Not run",
    "Solidify",
    "Thrombus",
    "Blockage",
    "Coagulate",
    "Congeal",
    "Blood obstruction",
    "Become solid",
    "Blood mass",
    "Phlebitis problem",
    "Blood ball",
    "Thrombus",
    "Coagulate"
  ],
  "duct": [
    "Channel",
    "It may bring a tear to one's eye",
    "Channel for fumes",
    "Airway or pipe",
    "Prison escape path, maybe",
    "___ tape",
    "Passage",
    "Kind of tape",
    "Air passage",
    "Main, e.g.",
    "Tube",
    "It'll bring a tear to your eye",
    "Channel",
    "Main",
    "Way in or out",
    "Way in or out",
    "Water carrier",
    "Canal",
    "Pipe up or down?",
    "Way in or out",
    "Tear carrier",
    "It brings a tear to the eye",
    "Cable conduit",
    "Airpipe, e.g.",
    "It brings tears to one's eyes",
    "Passageway",
    "Conduit"
  ],
  "enne": [
    "Suffix similar to -ess",
    "Gendered suffix",
    "Cousin of -trix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Trix alternative?",
    "Feminine suffix",
    "Female suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Non-P.C. suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Cousin of -ette or -trix",
    "Equivalent of -trix",
    "Feminine ending",
    "Gender-altering ending",
    "Feminizing suffix",
    { "formula": "-ess alternative", "result": { "error": "#NAME?" } },
    { "formula": "-trix alternative", "result": { "error": "#NAME?" } },
    "Feminine suffix",
    { "formula": "-trix kin", "result": { "error": "#NAME?" } },
    "Kin of -ess or -trix",
    "Feminine suffix",
    "Suffix akin to -trix",
    "Feminine suffix",
    "Cousin of -trix",
    "Feminine suffix",
    "Cousin of -trix",
    "Trix alternative?",
    "Feminizing suffix",
    "Feminine suffix",
    "Cousin of -trix",
    "Feminine suffix",
    "Relative of -trix",
    "Non-P.C. suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Female suffix",
    "Feminine suffix",
    "Relative of -trix",
    "Feminizing suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminizing suffix",
    "Feminizing suffix",
    "Feminine suffix",
    "Feminine ending",
    "Feminine suffix",
    "Kin of -ess or -trix",
    "Feminine suffix",
    "Feminine suffix",
    "Female suffix",
    "Politically incorrect suffix",
    "Non-P.C. suffix",
    "Feminine suffix",
    "Feminine suffix",
    "Feminine suffix"
  ],
  "ebro": [
    "River through Spain",
    "River that rises in the Cantabrian Mountains",
    "Second-longest river in Iberia",
    "River that drains the western Pyrenees",
    "Iberian river",
    "River of Iberia",
    "Iberian Peninsula river",
    "Spain's longest river",
    "River of Aragon",
    "River through Castile and Leon",
    "Spain's longest river",
    "River through Logrono and Aragon",
    "River that was the ancient dividing line between Rome and Carthage",
    "River to the Mediterranean",
    "River that ends in Tarragona",
    "River of Spain",
    "The Aragon River flows into it",
    "Zaragoza's river",
    "River that rises in Cantabria",
    "Longest Spanish river",
    "It rises in the Cantabrian Mountains",
    "River of Spain",
    "Battle of the ___, in the Spanish Civil War",
    "Spanish river to the Mediterranean",
    "Saragossa is on it",
    "River whose delta is Cape Tortosa",
    "River of Spain",
    "Spanish river to the Mediterranean",
    "River of Spain",
    "Longest river of Spain",
    "River of Aragon",
    "Saragossa's river",
    "Miranda de ___, Spain",
    "River rising in the Cantabrian Mountains",
    "Saragossa's river",
    "Spain's second-longest river",
    "Saragossa's stream",
    "Rain in Spain collector",
    "River through Zaragoza",
    "Aragon River's outlet",
    "Zaragoza's river",
    "River of Spain",
    "River of Aragon",
    "Catalan river",
    "River of Aragon",
    "Zaragoza's river",
    "Tortosa's river",
    "River in Spain",
    "River through Aragon",
    "River through Aragon",
    "River in Spain",
    "The Aragon flows into it",
    "Catalonian river",
    "River to the Mediterranean",
    "River rising in the Cantabrian Mountains",
    "Spanish river",
    "Rain in Spain collector",
    "Zaragoza's river",
    "River of Spain",
    "Iberian river",
    "Catalonian river",
    "River of Spain"
  ],
  "size": [
    "Order specification",
    "It's not everything, per a saying",
    "Drop-down menu in online shopping",
    "Small, medium or large",
    "Word with pint or plus",
    "S or M",
    "Girth",
    "Economy, for one",
    "Carry-on concern",
    "XL or XXL",
    "Large or jumbo",
    "Large or extra-large",
    "Label info",
    "S, M, L or XL",
    "Small, medium or large",
    "Jumbo, for one",
    "Regular or large",
    "Fitting decision",
    "Assess, with \"up\"",
    "44 Regular, e.g.",
    "One criterion for sorting",
    "Economy-___",
    "Evaluate, with \"up\"",
    "Clothing tag information",
    "Number on a tag",
    "9 1/2 narrow, e.g.",
    "Tag info",
    "See 68-Across",
    "Tag info",
    "Fitting decision?",
    "Photocopier choice",
    "Judge, with \"up\"",
    "Economy-___",
    "6 7/8, e.g.",
    "Petite or jumbo",
    "Label info",
    "Jumbo, for one",
    "Small, medium or large",
    "Medium, e.g.",
    "Label info",
    "Tag info",
    "Assess, with \"up\"",
    "Petite, e.g.",
    "XXL, e.g.",
    "Label info",
    "42 Long, e.g.",
    "Economy, for one",
    "Tailor's concern",
    "8 1/2, e.g.",
    "Small, medium or large",
    "Judge, with \"up\"",
    "Tag info",
    "Mail-order specification",
    "9C, say",
    "Label info",
    "Number of shoes?",
    "Small, medium or large",
    "Shoebox datum",
    "\"One ___ fits all\"",
    "Number of dresses?",
    "Tag info",
    "Dimension",
    "Capacity",
    "7D, e.g.",
    "Petite or jumbo",
    "Clothing specification"
  ],
  "aqui": [
    "Here, in Honduras",
    "Here, in Madrid",
    "Here, in Honduras",
    "Here, to Jose",
    "Here: Sp.",
    "\"___ se habla espanol\"",
    "Here, in Honduras",
    "Here, in Honduras",
    "Here, in Honduras",
    "Here, in 1-Down",
    "Prefix with -fer",
    "Here, in Seville",
    "Here, in Honduras",
    "Here, in Honduras",
    "Pablo's here",
    "Here, en espanol"
  ],
  "curl": [
    "What a bobby pin might help make",
    "Part of a weightlifter's routine",
    "Biceps exercise",
    "Headlock?",
    "Ringlet",
    "Permanent thing?",
    "Cheese ___",
    "Make waves?",
    "Pig's tail feature",
    "Ringlet",
    "Show signs of age, maybe",
    "Weightlifter's maneuver",
    "Weightlifter's rep",
    "See 12-Down",
    "Weight lifter's lift",
    "Ringlet",
    "Weightlifter's action",
    "Ringlet",
    "Weightlifter's lift",
    "Hair piece",
    "Hair roller result"
  ],
  "nanu": [
    "When repeated, Mork's farewell",
    "When repeated, an old sitcom catchphrase",
    "When repeated, Mork's sign-off",
    "When doubled, a sitcom sign-off",
    "When repeated, Mork's sign-off",
    "When doubled, part of many a Robin Williams tribute",
    "Half of Mork's farewell",
    "Part of a sitcom sign-off",
    "When repeated, a TV sign-off",
    "Half of a sitcom farewell",
    "When repeated, Mork's sign-off",
    "When repeated, a classic sitcom sign-off",
    "Half of Mork's sign-off",
    "When repeated, an old sitcom farewell",
    "TV alien's word",
    "Half of Mork's goodbye",
    "Half of Mork's sitcom sign-off",
    "When doubled, an old sitcom sign-off",
    "When repeated, Mork's TV sign-off",
    "When doubled, a Robin Williams character's catchphrase",
    "When doubled, an old sitcom goodbye",
    "When doubled, an old sitcom goodbye",
    "When repeated, an old TV sign-off",
    "When repeated, Mork's TV sign-off",
    "Half of a TV alien's send-off",
    "Half an Orkan farewell",
    "Half of Mork's greeting",
    "When doubled, a sitcom sign-off",
    "Half of Mork's sign-off",
    "Half of Mork's sign-off",
    "Part of a sitcom sign-off",
    "Half of Mork's sign-off",
    "Half of Mork's sign-off",
    "When doubled, an old TV signoff",
    "Part of Mork's goodbye",
    "Half of Mork's greeting",
    "Half of Mork's sign-off",
    "Part of Mork's sign-off"
  ],
  "rani": [
    "Hindu princess",
    "Hindu queen",
    "Indian blueblood",
    "Delhi dignitary",
    "Bollywood actress Mukerji",
    "Indian queen",
    "Indian royal",
    "Indian princess",
    "Eastern sovereign",
    "Indian royal",
    "Punjabi princess",
    "Eastern princess",
    "Mysore Palace resident",
    "Hindu noblewoman",
    "Indian princess",
    "Eastern royal",
    "Eastern royal",
    "14-Across V.I.P.",
    "Eastern blueblood",
    "Eastern dignitary",
    "Hindu noble",
    "Eastern princess",
    "Eastern royal",
    "Eastern noble",
    "Eastern royal",
    "\"Doctor Who\" villainess, with \"the\"",
    "Eastern queen",
    "Queen of Bollywood",
    "Sari-clad royal",
    "Eastern queen",
    "Asian princess",
    "Sari-clad royal",
    "Eastern dignitary",
    "Eastern queen",
    "Eastern royal",
    "Hindu royal",
    "Hindu V.I.P.",
    "Hindu princess",
    "Hindu royal",
    "Indian royal",
    "Rajah's mate",
    "Asian princess",
    "Indian princess",
    "Royal in a sari",
    "Sari-clad royal",
    "Important Indian",
    "Rajah's wife",
    "Hindu noble",
    "Eastern royal",
    "Punjabi royal",
    "Palace resident",
    "Punjabi princess",
    "Indian princess",
    "Hindu princess",
    "Indian royalty",
    "Indian princess",
    "Eastern royal",
    "Sari wearer",
    "Indian queen",
    "Rajah's wife",
    "Eastern royal",
    "Hindu queen",
    "Indian princess",
    "Eastern royal",
    "Rajah's spouse",
    "Sari-clad royal",
    "Punjabi princess",
    "Rajah's mate",
    "Eastern royal",
    "Punjabi peeress",
    "Eastern royal",
    "Kharagpur queen",
    "Punjabi princess",
    "Punjabi princess",
    "Foreign princess",
    "Rajah's mate",
    "Hindu princess",
    "Hindu royalty",
    "Eastern royalty",
    "Asian princess"
  ],
  "lies": [
    "Whoppers",
    "Don't believe them!",
    "Calumnies",
    "Word cried before and after \"all\"",
    "\"Hogwash!\"",
    "Some rulings on PolitiFact",
    "\"Hope ___ to mortals / And most believe her\": Housman",
    "\"I deny all that!\"",
    "\"That's utter slander!\"",
    "Tells a tale",
    "Trust eroders",
    "Not just deception",
    "Reposes",
    "Fictional accounts",
    "Creates an account?",
    "\"Debts and ___ are generally mixed together\": Rabelais",
    "Prevarications",
    "\"That's all false, and you know it!\"",
    "Isn't straight",
    "Propaganda, often",
    "Fabrications",
    "Fabrications",
    "They often come in packs",
    "Big fibs",
    "Whoppers",
    "Isn't straight",
    "White ones are little",
    "Some excuses",
    "Tells it like it isn't",
    "Links positions",
    "Storyteller's pack",
    "Cry of vehement denial",
    "\"Here ___ ...\"",
    "Untruths",
    "Stays flat",
    "Tall tales",
    "Empty promises",
    "\"Hogwash!\"",
    "Sprawls",
    "Is positioned",
    "Title start of a 2003 Al Franken best seller",
    "Epitaph word",
    "Fiction collection",
    "Inventions",
    "Is positioned",
    "Cry before and after \"all\"",
    "Not just evasions",
    "\"Hogwash!\"",
    "Tells it like it isn't",
    "Is prone",
    "Some Pinocchio pronouncements",
    "Baloney",
    "Polygraph detections",
    "Extends",
    "Untruths",
    "Commits perjury",
    "Golf positions",
    "More than fudges",
    "Falsehoods",
    "Inventions, so to speak",
    "Equivocator's forte",
    "Accused's retort",
    "\"Fairy tales\"",
    "All hogwash",
    "Prevaricates",
    "Propaganda, often",
    "Baloney",
    "\"True___\"",
    "Fabrications",
    "Is positioned",
    "\"Fairy tales\"",
    "Fibs",
    "Prevaricates",
    "\"Hogwash!\"",
    "\"Fairy tales\"",
    "Whoppers"
  ],
  "ical": [
    "Suffix with period or class",
    "Suffix with egotist",
    "Suffix with class",
    "Suffix with method",
    "Myth ending",
    "Suffix with myth",
    "Suffix with myth",
    "Suffix with period",
    "Suffix with poet",
    "Suffix with poet",
    "Suffix with egotist",
    "Suffix with myth or monarch"
  ],
  "aday": [
    "\"An apple ___ keeps ...\"",
    "\"There will come ___ ...\"",
    "Every 24 hours",
    "\"There will come ___ ...\"",
    "One ___ (multivitamin)",
    "Take ___ off",
    "Old TV's \"Queen for ___\"",
    "One ___ (vitamin brand)",
    "The Beatles' \"___ in the Life\"",
    "Forever and ___",
    "The Beatles' \"___ in the Life\"",
    "Diurnally",
    "Diurnally",
    "What you may call it when you're wiped out",
    "\"An apple ___ keeps ?à\"",
    "One-___",
    "?ôQueen for ___?ö (old TV show)",
    "Forever's partner",
    "24 hours ___",
    "Diurnally",
    "Partner of 108-Across",
    "\"An apple ___...\"",
    "Start of a Marx Brothers title",
    "\"An apple ___...\"",
    "Marvin Lee ___ aka Meat Loaf",
    "1955 \"Fifty Million Times ___\"",
    "Call it ___",
    "Call it ___ (stop working)",
    "Call it ___"
  ],
  "uofa": [
    "Tucson school, in brief",
    "Tucson school, informally",
    "Tucson school, briefly",
    "Fayetteville campus, briefly"
  ],
  "arut": [
    "In ___ (stuck)",
    "Stuck, after \"in\"",
    "Stuck in ___",
    "Stuck in ___",
    "In ___ (stuck in the same old same old)",
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "Mired, after \"in\"",
    "In ___ (stuck)",
    "Unable to move, after \"in\"",
    "In ___ (stagnant)",
    "In ___ (stuck)",
    "In ___ (stuck)",
    "Stuck, after \"in\"",
    "In ___ (trapped)",
    "Stuck in ___",
    "In ___ (stuck)",
    "In ___ (stagnant)",
    "In ___ (stuck)",
    "In ___ (stuck)",
    "In___ (stagnant)",
    "In___ (stymied)",
    "Fall into ___",
    "In ___ (stagnant)",
    "In ___ (going nowhere)",
    "In ___ (bogged down)",
    "In ___ (stuck)",
    "In ___ (mired)",
    "In ___ (stuck)",
    "In ___ (stuck)"
  ],
  "kean": [
    "Thomas who chaired the 9/11 Commission",
    "Thomas who headed the 9/11 Commission",
    "University in Union, N.J.",
    "New Jersey's ___ University",
    "New Jersey's ___ University",
    "New Jersey's ___ University",
    "9/11 commission chairman Thomas",
    "New Jersey university",
    "Shakespearean actor Edmund",
    "New Jersey's ___ University",
    "Shakespearean actor Edmund",
    "Union, N.J. college",
    "Former New Jersey Governor Thomas"
  ],
  "wean": [
    "Move off the bottle",
    "Detach slowly (from)",
    "Gradually cut off (from)",
    "Withdraw gradually (from)",
    "Move to solid food",
    "Gradually withdraw, as from the bottle",
    "Gradually remove, as from a mother's milk",
    "Withdraw slowly",
    "Withdraw gradually",
    "Detach gradually (from)",
    "Withdraw gradually from a mother's milk",
    "Remove gradually, as from dependence",
    "Detach (from)",
    "Gradually remove, as a foal from its mother's milk",
    "Slowly withdraw",
    "Remove gradually from, with \"off\"",
    "Gradually remove",
    "Detach from a source of dependence",
    "With 7-Down, slowly remove from",
    "Move to solid food",
    "Withdraw (from)",
    "Break off",
    "Withdraw gradually",
    "Gradually withdraw",
    "Remove, as from a habit",
    "Cut off (from)",
    "Detach gradually",
    "Remove from a mother's milk",
    "Break off",
    "Break off from a mother's milk",
    "Withdraw gradually",
    "Withdraw by degrees",
    "Withdraw, as from a dependency",
    "Withdraw from a habit",
    "Detach, in a way"
  ],
  "soph": [
    "Jr.'s junior",
    "Student who's no longer a frosh",
    "Freshman, a year later, informally",
    "10th grader, informally",
    "10th-grade student, for short",
    "Second-year student, for short",
    "Many a frat pledge",
    "Tomorrow's jr.",
    "10th: Abbr.",
    "Next year's jr.",
    "Second-class person, informally?",
    "2nd-yr. student",
    "10th grader, informally",
    "10th grader: Abbr.",
    "One who's junior to a jr.",
    "'13 grad in '11, e.g.",
    "New varsity team member, maybe: Abbr.",
    "H.S. class member",
    "Many a J.V. player",
    "One who attends school hops",
    "Jayvee player, maybe",
    "Not yet a jr.",
    "Year before jr.",
    "10th, in a way: Abbr.",
    "Frosh outranker",
    "10th-grader, for short",
    "School year, for short",
    "Former frosh",
    "Certain student, for short",
    "Frosh follower",
    "Short school year?",
    "Frosh, next year",
    "Campus figure",
    "Second-year student, for short",
    "Future jr.",
    "Frosh, next year",
    "Certain high schooler, for short",
    "College student, for short",
    "Year before jr.",
    "Year in school: Abbr.",
    "Undergrad"
  ],
  "amir": [
    "Leader whose name means, literally, \"commander\"",
    "Literally, \"commander\"",
    "\"Jake and ___\" (comedy web series)",
    "Arab chieftain: Var.",
    "Mideast V.I.P.",
    "Mideast ruler",
    "\"The Kite Runner\" protagonist",
    "Mideast bigwig: Var.",
    "Mideast leader: Var.",
    "Mideast potentate: Var.",
    "Mideast ruler: Var.",
    "Mideast ruler: Var.",
    "Israeli political leader Peretz",
    "Gulf state royalty",
    "Mideast ruler",
    "Mideast chief: Var.",
    "Commander, in Arabic",
    "Gulf States bigwig",
    "Bahrain power?",
    "\"Commander,\" in Arabic",
    "Mideast chief: Var.",
    "Gulf V.I.P.",
    "\"Abdul Abulbul ___\" (1927 tune)",
    "Bahraini bigwig",
    "Mideast ruler",
    "Mideast pooh-bah",
    "Eastern title",
    "Old song \"Abdul Abulbul ___\"",
    "Mideast leader",
    "Eastern prince",
    "Arab leader",
    "Mideast V.I.P.",
    "Mideast chief",
    "Abu Dhabi bigwig",
    "Eastern V.I.P."
  ],
  "noni": ["Anika ___ Rose, 2004 Tony winner"],
  "tues": [
    "It's named for the Norse god of war: Abbr.",
    "Election day in the U.S.: Abbr.",
    "A school yr. often begins on one",
    "It's named for a Scand. god of battle",
    "Election day: Abbr.",
    "Calendar heading: Abbr.",
    "When many ballots are cast: Abbr.",
    "Part of Shrovetide: Abbr.",
    "When to vote, usually: Abbr.",
    "Election day: Abbr.",
    "Mardi Gras, e.g.: Abbr.",
    "Calendar abbr.",
    "January 27, 1756 (Mystery Person's birthdate), e.g.",
    "When to vote, often: Abbr.",
    "Calendar column: Abbr.",
    "Calendar abbr.",
    "Calendar abbr.",
    "Calendar col.",
    "Calendar abbr.",
    "Amer. election day"
  ],
  "cart": [
    "Amazon icon",
    "Supermarket vehicle",
    "Golfer's vehicle",
    "Shopping aid",
    "Cause of a supermarket parking mishap",
    "Icon leading to checkout",
    "Sight at a golf course or grocery",
    "Golf course rental",
    "Amazon icon",
    "Airport rental",
    "Wheel (off)",
    "Amazon icon",
    "Icon on Amazon",
    "Country club vehicle",
    "Supermarket aid",
    "Sight at a supermarket or golf course",
    "\"Add to ___\" (e-shopper's button)",
    "Golfer's vehicle",
    "Trolley",
    "It rolls in the aisles",
    "Haul",
    "Auto denter in a supermarket parking lot",
    "Trolley",
    "Schlep",
    "Grocery carrier",
    "Online shopping icon",
    "Golfer's vehicle",
    "Shopping ___",
    "Transport in the country",
    "Vendor's vehicle",
    "Golfing vehicle",
    "Pastry server",
    "Groceries carrier",
    "One may get pushed around",
    "Rental for a round",
    "Haul",
    "It may get pushed around",
    "Apple carrier",
    "Basket alternative",
    "Links carrier",
    "Rickshaw",
    "Golfer's transport",
    "Word with apple or dessert",
    "Grocery vehicle",
    "Links rental",
    "Links rental",
    "Help for airport baggage",
    "Carry (away)",
    "Grocery transporter",
    "Farm vehicle",
    "Haul",
    "Transport, in a way",
    "Shopper's helper"
  ],
  "kegs": [
    "Bar supply",
    "Holders of 59-Across",
    "Beer barrels",
    "Tap sites",
    "Beer blast purchases",
    "Frat party staples",
    "They're tapped",
    "They're tapped",
    "They might help produce a blowout",
    "Items sometimes tossed in strongman contests",
    "Fraternity party staples",
    "Gunpowder holders",
    "Draft sources",
    "Things to tap",
    "They're tapped",
    "Purchases for a shindig",
    "They're tapped",
    "Tap sites",
    "Fraternity party staples",
    "They're found under the counter at bars",
    "Powder containers",
    "They're tapped",
    "Tavern inventory",
    "Bud holders?",
    "Toga party needs",
    "Pub needs",
    "Barroom supplies",
    "Barrels"
  ],
  "lido": [
    "Venice Film Festival locale",
    "Resort near Venice",
    "Italian tourist destination",
    "\"Death in Venice\" locale",
    "Newport Beach isle",
    "Italian beach resort",
    "___ deck (part of a cruise ship)",
    "Adriatic resort",
    "Adriatic resort near Venice",
    "Beach resort at the entrance to the Lagoon of Venice",
    "Venice Film Festival locale",
    "Italian resort",
    "Italian island reef",
    "Resort island near Venice",
    "Italian resort on the Adriatic",
    "Italian resort",
    "Fashionable beach resort",
    "Adriatic resort",
    "Resort near Venice",
    "Laguna Veneta borderer",
    "Open-air swimming pool",
    "Italian beach resort",
    "Adriatic resort",
    "Venetian resort",
    "Venetian resort",
    "Resort near Venice",
    "Venetian resort",
    "Resort near Venice",
    "Venetian beach",
    "Famous beach",
    "Noted resort",
    "Island near Venice"
  ],
  "edel": [
    "Biographer Leon",
    "Leon ___, Henry James biographer",
    "1963 Pulitzer winner Leon",
    "Pulitzer-winning biographer Leon",
    "Leon who won a Pulitzer for his biography of Henry James",
    "Henry James biographer",
    "Biographer Leon",
    "Henry James biographer Leon",
    "Pulitzer-winning biographer Leon",
    "Writer of the five-volume biography \"Henry James\"",
    "Pulitzer-winning biographer Leon",
    "Writer of a five-volume Henry James biography",
    "Leon who won both a Pulitzer and a National Book Award in 1963",
    "Biographer Leon",
    "Pulitzer-winning biographer Leon",
    "Henry James biographer Leon",
    "\"James Joyce\" author Leon ___",
    "Henry James biographer Leon",
    "Pulitzer-winning biographer Leon",
    "Noble, in Nuremberg",
    "Pulitzer-winning biographer Leon",
    "Cather biographer Leon",
    "1963 Pulitzer biographer Leon"
  ],
  "dabs": [
    "Applies with a cotton ball",
    "Uses a tissue, maybe",
    "Little amounts of cream",
    "Brylcreem amounts",
    "Painters' touches",
    "Delicately applies",
    "Applies gingerly",
    "Applies gingerly",
    "Pats gently",
    "Small dollops",
    "Light touches",
    "Applies with a Q-Tip, say",
    "Smidgens",
    "Paints gently",
    "Little bits",
    "Smidgens",
    "Artistic touch-ups",
    "Applies lightly",
    "Soft touches",
    "Touches",
    "Smidgens, as of cream",
    "Small amounts",
    "Small amounts, as of cream",
    "Applies lightly",
    "Small amounts, as of cream",
    "Cotton ball applications",
    "Teeny amounts",
    "Touches lightly, as with a hanky",
    "Pats",
    "Taps or pats",
    "Uses a powder puff",
    "Smidgens",
    "Applies with cotton balls",
    "Small flatfishes",
    "Smidgens"
  ],
  "cues": [
    "Pool necessities",
    "\"You're on!\" and others",
    "Sticks for breaking things",
    "Prompts",
    "Aids after blanking out",
    "Stage prompts",
    "Sticks in the rec room",
    "Striking things about rec rooms",
    "Providers of tips for improving one's English?",
    "Billiard sticks",
    "Breaking sticks",
    "They may come from the wings",
    "Pool sticks",
    "Wink, nudge, etc.",
    "Hints",
    "Barroom sticks",
    "Sticks on a table",
    "Baton waves, e.g.",
    "Sticks in a bar?",
    "49-Down's aids",
    "They may be given from behind a curtain",
    "Some nods",
    "Sticks around a game parlor",
    "They're waited for at a theater",
    "Pool needs",
    "Signals",
    "Hints at the pool table?"
  ],
  "bobs": [
    "Short hairstyles",
    "Short cuts",
    "Fishing floats",
    "Some haircuts",
    "Goes up and down, as a buoy",
    "Gatsby-era hairstyles",
    "Some fishing gear",
    "Short cuts",
    "Flapper hairdos",
    "Short dos",
    "Fishing corks",
    "Goes up and down",
    "Moves in the ring",
    "Flappers' hairdos",
    "Feints in boxing",
    "Cuts short",
    "Short haircuts",
    "Haircuts",
    "Old dos"
  ],
  "aves": [
    "Flying class?",
    "Class of fliers?",
    "Madison and Fifth in Manhattan: Abbr.",
    "D.C.'s New York and Pennsylvania",
    "Fifth and Park, in N.Y.C.",
    "Class of birds",
    "Greetings of old",
    "Birds, scientifically speaking",
    "Streets: Abbr.",
    "Madison and Fifth, e.g.: Abbr.",
    "Some Monopoly purchases: Abbr.",
    "Many Monopoly properties: Abbr.",
    "Mediterranean and Baltic, in Monopoly: Abbr.",
    "Ventnor and Baltic, in Monopoly: Abbr.",
    "Some Monopoly properties: Abbr.",
    "Upper class?",
    "Connecticut and Virginia, in Monopoly: Abbr.",
    "Birds, collectively",
    "Forum greetings",
    "Greetings of long ago",
    "Bird classification",
    "Pennsylvania and others: Abbr.",
    "Map parts: Abbr.",
    "Lex and Park, e.g., in N.Y.C.",
    "Class of birds",
    "Lexington and others, in N.Y.C.",
    "Fifth and Mad.",
    "Madison and Fifth, in N.Y.C.",
    "N.Y.C.'s 5th and 7th, e.g.",
    "Mad. and Lex.",
    "Birds, in Latin",
    "Park and Lexington, e.g., in N.Y.C.",
    "They sometimes have presidents' names: Abbr.",
    "Welcomes",
    "Prayers to Mary",
    "2nd, 3rd, 4th, etc., in S.F.",
    "Maryland, Virginia, etc., in D.C.",
    "Class that's for the birds",
    "Ancient greetings",
    "His, in old Rome",
    "17 Monopoly props.",
    "Ancient greetings",
    "Pennsylvania and Park, e.g.: Abbr.",
    "Fifth and Mad., e.g.",
    "Ancient greetings",
    "St. crossers",
    "Ancient greetings",
    "Former ova",
    "Lexington and Madison: Abbr.",
    "Welcomes"
  ],
  "vied": [
    "Competed",
    "Competed",
    "Competed",
    "Went head to head",
    "Competed",
    "Competed",
    "Traded blows",
    "Got in the game",
    "Fought",
    "Strived (for)",
    "Strove (for)",
    "Competed",
    "Tried for a title"
  ],
  "baio": [
    "Scott of \"See Dad Run\"",
    "Actor Scott of \"Happy Days\"",
    "Scott of \"Charles in Charge\"",
    "Scott of \"Happy Days\"",
    "Scott of \"Joanie Loves Chachi\"",
    "\"Bugsy Malone\" star Scott",
    "\"Soap\" actor Jimmy",
    "Scott of \"Charles in Charge\"",
    "\"Joanie Loves Chachi\" co-star",
    "Scott of \"Happy Days\"",
    "\"Charles in Charge\" star",
    "Scott of \"Joanie Loves Chachi\"",
    "Actor Scott",
    "Star of \"Charles in Charge\"",
    "Scott of \"Happy Days\"",
    "\"Soap\" actor Jimmy",
    "Scott of \"Happy Days\"",
    "\"Charles in Charge\" star",
    "Scott of \"Charles in Charge\""
  ],
  "bete": [
    "\"La Belle et la ___\" (French fairy tale)",
    "___ noire (pet peeve)",
    "___ noire",
    "Emile Zola's \"La ___ humaine\"",
    "With 62-Across, dreaded one",
    "___ noire",
    "___ noire",
    "___ noire",
    "\"La Belle et la ___\" (French fairy tale)",
    "___ noire",
    "\"La Belle et la ___\"",
    "___ noire",
    "\"La Belle et la ___\"",
    "___ noire",
    "Jean Renoir film \"La ___ Humaine\"",
    "Animal, in Arles",
    "Emile Zola's \"La ___ humaine\"",
    "\"La Belle et la ___\"",
    "___ noire",
    "___ noire",
    "Belle's counterpart",
    "Belle's companion",
    "Belle's counterpart",
    "___ noire",
    "Jean Renoir film \"La ___ Humaine\"",
    "Belle's partner in film",
    "___ noire",
    "___ noire",
    "\"La Belle et la ___\" (Cocteau film)",
    "___ noire"
  ],
  "cote": [
    "Avian shelter",
    "Pigeon coop",
    "Animal shelter",
    "Home for doves and pigeons",
    "Animal shelter",
    "Bird house",
    "Dove's home",
    "___ d'Ivoire",
    "___ d'Ivoire",
    "Sheep shelter",
    "Farm shelter",
    "___ d'Ivoire (African land)",
    "Ram home?",
    "Dove's site",
    "Homing pigeon's home",
    "___ d'Azur",
    "Small animal shelter",
    "Rooftop coop",
    "With 17-Across, Dijon's department",
    "Pigeon coop",
    "French wine-producing hillside",
    "Sheep shelter",
    "Bird shelter",
    "Fowl place",
    "___ d'Azur (French Riviera)",
    "Pigeon's home",
    "Pigeon's home",
    "Dove home",
    "Pigeon pad",
    "French hillside with vineyards",
    "Pigeon coop"
  ],
  "iput": [
    "\"Where did ___ my keys?\"",
    "\"Hmm, how shall ___ this?\"",
    "\"___ a Spell on You\" (1956 hit)",
    "\"___ a Spell on You\"",
    "\"___ a Spell on You\" (classic 1956 Screamin' Jay Hawkins song)"
  ],
  "juan": [
    "Strait of ___ de Fuca, waterway separating Washington State from Vancouver Island",
    "Explorer Ponce de Leon",
    "Valdez of coffee advertising",
    "Follower of Don or San",
    "Don ___",
    "Argentine strongman Peron",
    "San ___",
    "King ___ Carlos of Spain",
    "Slugger Gonzalez",
    "Spanish explorer ___ Bautista de Anza",
    "Bullfighter Belmonte",
    "San ___",
    "A Peron",
    "Explorer Cabrillo",
    "Don ___"
  ],
  "pibb": [
    "___ Xtra (soda)",
    "___ Xtra (Dr Pepper alternative)",
    "___ Xtra (soft drink)",
    "___ Xtra (soda)",
    "___ Xtra (Coca-Cola product)",
    "___ Xtra (soda)",
    "Mr. ___ (soft drink)",
    "Mr. ___",
    "Mr. ___",
    "Mr. ___ (old soft drink name)",
    "Mr. ___ (soft drink)",
    "Mr. ___ (Dr. Pepper rival)",
    "Mr. ___ (soft drink brand)"
  ],
  "hoar": [
    "Frost",
    "Winter leaf covering",
    "Frost",
    "Frozen dew",
    "Frost",
    "Winter frost",
    "Winter frost",
    "Winter frost",
    "Window coating?",
    "Frozen dew",
    "Look of aged whitening",
    "Frost",
    "Winter coat?",
    "Cold cover",
    "Frost",
    "Rime",
    "Rime",
    "Rime",
    "Frost",
    "Winter coating",
    "Icy coating",
    "Frost",
    "Frost",
    "Frost",
    "Frozen dew",
    "Frost",
    "Frost",
    "Frost",
    "Frost",
    "Rime",
    "Frost",
    "Frozen dew",
    "Frost",
    "Frozen dew",
    "Kind of frost"
  ],
  "cide": [
    "Ending with herbi- or insecti-",
    "Killer ending?",
    "Murderous suffix",
    "Funny ___ (2003 Derby winner)",
    "Funny ___, 2003 Derby winner"
  ],
  "lund": [
    "See 101-Down",
    "Ilsa ___, \"Casablanca\" character",
    "Ilsa in \"Casablanca\"",
    "Home of the oldest school in Sweden, founded in 1085",
    "Bergman's role in \"Casablanca\"",
    "18-Across speaker",
    "Bergman in \"Casablanca\"",
    "City in southern Sweden",
    "\"My Friend Irma\" star",
    "Capital of Denmark in the Middle Ages",
    "Bergman's \"Casablanca\" surname",
    "Bergman in \"Casablanca\"",
    "Ilsa of \"Casablanca\""
  ],
  "dyad": [
    "Set of two",
    "Twosome",
    "Twosome",
    "Couple",
    "Brace",
    "Set of two",
    "Group of two",
    "Couple",
    "Couple",
    "Twosome",
    "Pair",
    "Couple",
    "Pair",
    "Twosome",
    "Twosome",
    "Couple"
  ],
  "bids": [
    "Raises one's paddle, say",
    "Trump is often involved in these",
    "Contends (for)",
    "\"Two no-trump\" and others",
    "Offers for lots",
    "Says \"Two 19-Across,\" e.g.",
    "Contractors' offerings",
    "Bridge declarations",
    "Makes an offer",
    "Sellers' solicitations",
    "One heart, two diamonds, e.g.",
    "Olympic efforts",
    "Presidential runs",
    "Actions at Sotheby's",
    "Auction actions",
    "Nods, perhaps",
    "They may be made in clubs",
    "Political campaigns",
    "Two hearts and others",
    "Some auction action",
    "Invitations"
  ],
  "ilsa": [
    "With 37-Across, Ingrid Bergman's role in \"Casablanca\"",
    "Film character who says \"Kiss me as if it were the last time\"",
    "\"Casablanca\" role",
    "\"Casablanca\" lover",
    "Visitor to Rick's Cafe Americain",
    "Rick's love in \"Casablanca\"",
    "\"Casablanca\" woman",
    "\"Play it, Sam\" speaker",
    "The \"kid\" in \"Here's looking at you, kid\"",
    "\"___, She Wolf of the SS\" (1975 cult film)",
    "Ingrid Bergman's \"Casablanca\" role",
    "\"Casablanca\" woman",
    "Ingrid's \"Casablanca\" role",
    "\"Casablanca\" woman",
    "\"Casablanca\" role",
    "\"Casablanca\" role",
    "\"Casablanca\" heroine",
    "Rick's love in \"Casablanca\"",
    "\"Here's looking at you, kid\" addressee",
    "\"Casablanca\" heroine",
    "\"Casablanca\" role",
    "\"Casablanca\" heroine",
    "The \"kid\" of \"Here's looking at you, kid\"",
    "\"Casablanca\" character Lund",
    "Rick's beloved in \"Casablanca\"",
    "___ Lund of \"Casablanca\"",
    "\"Play it, Sam\" speaker",
    "Rick's love in \"Casablanca\"",
    "\"Casablanca\" role",
    "\"Casablanca\" role",
    "Lund of \"Casablanca\"",
    "Film character who actually does not say \"Play it again, Sam\"",
    "Rick's \"Casablanca\" love",
    "\"Play it, Sam\" speaker",
    "She said \"Play it, Sam\"",
    "\"Casablanca\" heroine",
    "Rick's love, in film",
    "She'll \"always have Paris\"",
    "\"The most beautiful woman ever to visit Casablanca\"",
    "Rick's film love",
    "She requested \"As Time Goes By\"",
    "Ingrid's role in \"Casablanca\"",
    "Film character who says \"Play it once, Sam, for old times' sake\"",
    "Rick's \"Casablanca\" love",
    "Ingrid's role in \"Casablanca\"",
    "\"As Time Goes By\" requester, in film",
    "Rick's love in \"Casablanca\"",
    "Rick's love in \"Casablanca\"",
    "Rick's love in \"Casablanca\"",
    "To whom \"We'll always have Paris\" was spoken",
    "\"Casablanca\" name",
    "\"Casablanca\" role",
    "\"Casablanca\" role",
    "Requester of \"As Time Goes By\"",
    "Mrs. Victor Laszlo",
    "Rick's love in \"Casablanca\"",
    "Whom Ingrid played in \"Casablanca\"",
    "Rick's love in \"Casablanca\"",
    "Rick's love in \"Casablanca\"",
    "\"Casablanca\" role",
    "Rick's love in \"Casablanca\"",
    "Rick's old flame",
    "Rick's beloved",
    "Rick's love in \"Casablanca\"",
    "Rick's \"Casablanca\" love",
    "She met Rick in Paris",
    "___ Lund",
    "Ingrid's \"Casablanca\" role",
    "\"Casablanca\" woman",
    "Mrs. Victor Laszlo",
    "The kid in \"Here's looking at you, kid\"",
    "Role for Ingrid",
    "Mrs. Victor Laszlo",
    "Madeleine L'Engle novel or its heroine",
    "\"Kiss me as if it were the last time\" speaker",
    "Ingrid, in \"Casablanca\"",
    "Role for Ingrid",
    "Rick's love, in \"Casablanca\"",
    "Role for Ingrid",
    "\"Casablanca\" heroine",
    "Role for Ingrid",
    "Rick's \"Casablanca\" love",
    "\"Casablanca\" role",
    "\"Casablanca\" heroine",
    "___ Lund of \"Casablanca\"",
    "\"Judge Dredd\" villainess",
    "Cafe Americain visitor",
    "Rick's love in \"Casablanca\"",
    "Rick's love",
    "Ingrid's role in \"Casablanca\"",
    "Mrs. Victor Laszlo",
    "\"Casablanca\" role",
    "Rick's beloved",
    "Ingrid's \"Casablanca\" role",
    "Ingrid's \"Casablanca\" role",
    "Rick Blaine's love",
    "Rick's film love",
    "___ Lund of \"Casablanca\"",
    "Rick's love, in \"Casablanca\"",
    "She said ?ôPlay it, Sam!?ö",
    "Ingrid in \"Casablanca\"",
    "She requested \"As Time Goes By\""
  ],
  "frug": [
    "'60s dance craze that evolved from the Chicken",
    "\"Hullabaloo\" dance",
    "Lively '60s dance",
    "Dance seen on TV's \"Hullabaloo\"",
    "60's dance",
    "60's dance"
  ],
  "fuse": [
    "Join",
    "Item in a box in the basement",
    "Part of a bomb",
    "Item in a box in the basement",
    "What to light on a stick of dynamite",
    "Join",
    "Item in a box in the basement",
    "Current governor",
    "It may be found in a box in the basement",
    "It may blow when it's hot",
    "Grenade part",
    "Detonator",
    "Cherry bomb stem",
    "Combine",
    "It may be blown",
    "It may be kept in a box in the basement",
    "Electrical safeguard",
    "Electrical overload protector",
    "Circuit component"
  ],
  "bide": [
    "Wait for",
    "Wait",
    "Wait",
    "___ one's time",
    "Wait",
    "Remain",
    "Endure",
    "Wait awhile",
    "Something to do with one's time",
    "___ one's time",
    "Wait",
    "Wait",
    "Wait",
    "Remain",
    "Patiently wait",
    "Dwell",
    "Remain",
    "Wait",
    "Wait",
    "Remain",
    "___ one's time (wait)",
    "Wait",
    "Patiently wait",
    "Remain",
    "Dwell",
    "It may be before one's time",
    "Dwell",
    "Remain",
    "Wait",
    "Remain",
    "Wait",
    "___ one's time",
    "Remain",
    "Remain",
    "Linger"
  ],
  "vols": [
    "Lady ___, powerhouse in college basketball",
    "Tennessee team, for short",
    "Encyclopedia units: Abbr.",
    "Knoxville team, to fans",
    "Tennessee ___",
    "Tennessee team, for short",
    "Crimson Tide rival",
    "Tennessee players"
  ],
  "heep": [
    "Dickens clerk",
    "Uriah of \"David Copperfield\"",
    "Dickens's Uriah ___",
    "Dickens's Uriah",
    "See 30-Across",
    "Dickens's scheming clerk",
    "Dickens's Uriah ___",
    "Dickens schemer",
    "Dickens's Uriah",
    "Dickens creep",
    "Villainous Uriah",
    "Blackmailer in an 1850 novel",
    "Wickfield's scheming partner in \"David Copperfield\"",
    "Mr. Wickfield's clerk, in literature",
    "Perfidious clerk in \"David Copperfield\"",
    "Dickens's villainous clerk",
    "Villain in \"David Copperfield\"",
    "Dickens's Uriah",
    "Dickens's Uriah",
    "Dickens forger",
    "Dickens villain",
    "Smarmy Dickens character",
    "Dickens villain",
    "\"David Copperfield\" character"
  ],
  "stub": [
    "Ticket part",
    "Proof of purchase",
    "Rip-off?",
    "What's left of a ticket after it's been used",
    "Paycheck go-with",
    "Proof of purchase, perhaps",
    "Bit of theater detritus",
    "Ticket leftover",
    "Split ticket?",
    "What's left of a ticket after it's used",
    "Admission evidence",
    "Something shown to an usher",
    "Remainder of a used ticket",
    "Leftover part of a ticket",
    "Bump, as one's toe",
    "Concert souvenir",
    "Checkbook record",
    "Theater keepsake",
    "Hit accidentally",
    "Ballgame souvenir",
    "It's returned by a ticket-taker",
    "Butt",
    "Bit of theater litter",
    "Check writer's record",
    "It's ripped off at the movies",
    "One-inch pencil, for example",
    "Evidence of an admission",
    "Pitiful pencil",
    "Checkbook record",
    "Much-used pencil",
    "Check mate?",
    "Concertgoer's keepsake",
    "Hit, as one's toe",
    "Not much of a pencil",
    "Usher's request",
    "Short end",
    "It's shown to the usher",
    "Theater souvenir",
    "Check mate",
    "Concert memento",
    "The short end",
    "Hit, as the toe",
    "Ticket remainder",
    "Movie souvenir",
    "Part to keep",
    "Ticket part",
    "Proof of attendance",
    "Short end of the stick",
    "Movie memento",
    "Proof of purchase, maybe"
  ],
  "rags": [
    "Poverty, metaphorically",
    "Tattered threads",
    "Uses for worn-out T-shirts",
    "Symbol of poverty",
    "Precursor to riches, it's said",
    "Cloths used to collect dust",
    "Rips (on)",
    "Impoverished state",
    "From ___ to riches",
    "Tatters",
    "Car wash supply",
    "Sign of destitution",
    "Cleaning cloths",
    "Clothing, colloquially",
    "Cleaner's supply",
    "Poverty, metaphorically",
    "Threadbare threads",
    "___-to-riches",
    "Cinderella's wear, at home",
    "Unimpressive attire",
    "Car wash gear",
    "Wear that's worn",
    "Castaway's clothing",
    "No fancy threads",
    "Lively piano pieces",
    "No fancy threads",
    "Teases mercilessly",
    "Unprosperous time, metaphorically",
    "Janitor's supply",
    "Maids?Æ supplies",
    "Poverty, so to speak",
    "Glad ___",
    "State of poverty, metaphorically",
    "Criticizes, with \"on\"",
    "Tramp's attire",
    "Old piano tunes",
    "Car wash supply",
    "Maid's supply",
    "Tatters",
    "Lively piano tunes",
    "Beggar's duds",
    "Syncopated songs",
    "Poverty, so to speak",
    "Tatters",
    "Start of an Alger story?",
    "Destitution",
    "Poverty, metaphorically",
    "With 35 Down, the American Dream",
    "Extreme poverty",
    "___ to riches",
    "Riches' opposite"
  ],
  "gaff": [
    "Fishing hook",
    "Heavy fishing hook",
    "Large iron hook",
    "Sailor's hook",
    "Large fishing hook",
    "Fishing hook with a handle",
    "Spur on a climbing iron",
    "Ship's spar",
    "Captain's hook",
    "Hook on a handle",
    "Fishing hook",
    "Hook with a handle",
    "Iron hook with a handle",
    "Fish hook",
    "Fisherman's hook",
    "Fisherman's hook",
    "Kind of sail",
    "Butcher's hook"
  ],
  "zoot": [
    "___ suit",
    "___ suit",
    "Kind of suit worn by a 21-Down",
    "___ suit",
    "___-suiter",
    "\"The ___ Cat\" (Tom and Jerry short)",
    "Kind of suit",
    "___ suiter",
    "___ suit (baggy outfit)",
    "Kind of suit",
    "___ suiter",
    "Kind of suit",
    "Kind of suit",
    "Kind of suit",
    "Kind of suit"
  ],
  "wisp": [
    "Puff of smoke",
    "Swirl of smoke",
    "Pufflet",
    "Bit of smoke",
    "Bit of smoke",
    "Bit of smoke",
    "Cirrus cloud formation",
    "Bit of smoke or hair",
    "Faint trace",
    "Flimsy lock",
    "Bit of smoke",
    "Bit of smoke",
    "Bit of smoke",
    "Faint cloud",
    "Slender amount",
    "Bit of a shock?",
    "Thin fragment",
    "Trace of smoke",
    "Bit of smoke",
    "Fleeting trace",
    "Small handful",
    "Bit of smoke",
    "Trace",
    "Trace",
    "Bit of a shock?",
    "Miniature cloud",
    "Delicate lock of hair",
    "Thin lock",
    "Bit of a cloud",
    "Thin streak",
    "Part of a smoke screen?",
    "Tenuous fragment",
    "Bit of smoke",
    "Skinny one",
    "Slender one",
    "Trace of smoke",
    "Smoke trace",
    "Puff",
    "Cirrus cloud formation",
    "Thin strand",
    "Handful of hay",
    "Stray strand",
    "Handful of hay",
    "Tuft",
    "Bit of smoke"
  ],
  "conk": [
    "Bop on the head",
    "Crown",
    "Bop",
    "Crown",
    "Crash, with \"out\"",
    "Bop",
    "Collapse, with \"out\"",
    "Go pfft, with \"out\"",
    "Bean",
    "Bop",
    "Bop",
    "Bop",
    "Go pffft, with \"out\"",
    "Go kaput, with \"out\"",
    "Bop on the head",
    "Bop",
    "Bean"
  ],
  "hari": [
    "Stand-up comic Kondabolu",
    "Mata ___ (W.W. I spy)",
    "Mata ___ (spy)",
    "Mata ___",
    "Mata ___ (W.W. I spy)",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___ (spy)",
    "Mata ___ (spy)",
    "Mata ___",
    "Mata ___",
    "Mata ___ (W.W. I spy)",
    "Spy Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Spy Mata ___",
    "Mata ___",
    "Mata ___ (spy)",
    "Mata ___ (spy)",
    "Spy Mata ___",
    "Mata ___ (spy)",
    "Spy Mata ___",
    "Spy Mata ___",
    "Mata ___",
    "\"Roots\" actor Rhodes",
    "Mata ___",
    "Spy Mata ___",
    "Mata ___",
    "Mata ___",
    "\"Daktari\" actor Rhodes",
    "Mata ___",
    "Mata ___",
    "Mata ___ (spy)",
    "Kumar of \"The Jewel in the Crown\"",
    "Spy Mata ___",
    "___ Kumar, \"The Jewel in the Crown\" role",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Spy Mata ___",
    "Last name in spydom",
    "Mata ___",
    "Mata ___",
    "Mata___",
    "Spy name",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___",
    "Mata ___"
  ],
  "ubid": ["Competitor of eBay"],
  "mayi": [
    "\"Will you allow me to demonstrate?\"",
    "\"Please?\"",
    "\"Would you mind?\"",
    "Polite request",
    "Polite helper's question",
    "\"Please?\"",
    "Start of a request",
    "Polite request for permission",
    "Polite question",
    "\"___ help you?\"",
    "Request for permission"
  ],
  "risd": ["New England art inst.", "Providence art inst."],
  "rpms": [
    "Engine stats",
    "Turntable rates, in brief",
    "Tach readings",
    "Turntable speeds, briefly",
    "Turntable rates, for short",
    "Stats for DVDs",
    "Dashboard readings, for short",
    "CD rate?",
    "33 1/3 and others",
    "Tach readings",
    "High ones may produce a roar",
    "Tach readings",
    "33 1/3 and 45, for short",
    "Auto performance factor, informally",
    "Engine measures: Abbr.",
    "Tach readings, for short",
    "Tach readings",
    "Record speeds, for short",
    "Tach readings",
    "Engine stats",
    "45 and 78, e.g."
  ],
  "leox": [
    "16th-century pope who owned a pet elephant",
    "Pope who excommunicated Martin Luther",
    "Religious leader with a pet elephant",
    "Excommunicator of Martin Luther",
    "Pope who excommunicated Martin Luther",
    "Pope who excommunicated Martin Luther",
    "Religious leader born Giovanni de Medici",
    "Pope, 1513-21"
  ],
  "sulu": [
    "Role for John Cho in the \"Star Trek\" movies",
    "\"Star Trek\" role for Takei",
    "Enterprise crewman named after an Asian sea",
    "___ Sea, body of water between Borneo and the Philippines",
    "Classic George Takei role",
    "\"Star Trek\" role for George Takei",
    "\"Star Trek\" lieutenant",
    "The Philippines' ___ Sea",
    "The Philippines' ___ Archipelago",
    "Lieutenant on the original U.S.S. Enterprise",
    "\"Star Trek\" role",
    "\"Mr.\" of \"Star Trek\"",
    "George Takei TV and film role",
    "Officer on the bridge with Spock and Uhura",
    "Original \"Star Trek\" helmsman",
    "Shipmate of Spock",
    "U.S.S. Enterprise helmsman",
    "\"Star Trek\" role",
    "Navigator on the Enterprise",
    "Takei's \"Star Trek\" role",
    "Enterprise crewman",
    "Original Enterprise navigator",
    "The Philippines' ___ Archipelago",
    "\"Star Trek\" helmsman",
    "\"Star Trek\" navigator",
    "___ Sea, next to the Philippines",
    "\"Star Trek\" navigator",
    "Enterprise helmsman",
    "\"Star Trek\" navigator",
    "\"Star Trek\" navigator",
    "\"Star Trek\" navigator",
    "Officer in the original \"Star Trek\"",
    "George Ade's \"The Sultan of ___\"",
    "\"Star Trek\" helmsman",
    "\"Star Trek\" navigator",
    "Crewmate of Scotty",
    "\"Star Trek\" character",
    "\"Star Trek\" helmsman",
    "George Takei TV/film role",
    "Enterprise helmsman",
    "\"Star Trek\" role",
    "\"Star Trek\" crewman",
    "George Takei TV/movie role",
    "Colleague of Scotty and Spock"
  ],
  "bang": [
    "*In rapid succession, in slang",
    "Gun noise",
    "!",
    "[Just like that!]",
    "Pistol sound",
    "[All of a sudden!]",
    "Word repeated before \"You're dead!\"",
    "Report of a shooting",
    "Sound suggested by the first letters of the words in 17-, 26-, 44- and 58-Across",
    "Pop's relative?",
    "Prank cigar sound",
    "Big report",
    "Pow!",
    "Gun sound",
    "Screen door sound",
    "[Just like that!]",
    "Backfire sound",
    "Return for a buck?",
    "[Suddenly!]",
    "When repeated, a shout when playing cowboy",
    "Report",
    "Sudden start",
    "Attention getter",
    "Report",
    "Gun sound",
    "Report of a shooting?",
    "!, to a printer",
    "Quite a thrill",
    "[Just like that!]",
    "Slangy thrill",
    "Gun blast",
    "Exclamation point",
    "Kaboom!"
  ],
  "chop": [
    "*\"On the double!\"",
    "Martial arts film hit",
    "Martial arts move",
    "Word after pork or karate",
    "Karate move",
    "Veal cut",
    "Use a Veg-o-Matic",
    "Blender setting",
    "Food processor setting",
    "Ax",
    "Cut of meat",
    "Hack",
    "Take an ax to",
    "Dice",
    "Dojo blow",
    "Black belt's blow",
    "Spin-producing tennis shot",
    "Dice, say",
    "Karate blow",
    "Karate blow",
    "Karate blow",
    "Karate blow",
    "Hack",
    "Karate blow",
    "When doubled, quickly",
    "Cut of lamb",
    "Hack",
    "Tennis stroke",
    "A little lamb",
    "Certain tennis stroke",
    "Ninja's motion",
    "Blender setting",
    "Tennis stroke",
    "Karate blow",
    "Hew",
    "Mince",
    "Whack",
    "Karate blow",
    "Hatchet job?",
    "Karate motion"
  ],
  "lott": [
    "Former Mississippi senator Trent",
    "Ronnie who was a 10-time N.F.L. Pro Bowler",
    "Senate majority leader between Dole and Daschle",
    "Trent ___, former Senate majority leader",
    "Former Mississippi senator Trent",
    "Longtime Mississippi politico Trent",
    "1980s-'90s N.F.L. great Ronnie",
    "Former senator Trent",
    "Senate majority leader who succeeded Dole",
    "Former minority whip in both the House and Senate",
    "Former Mississippi senator Trent",
    "Former senator Trent",
    "1990s Senate majority leader Trent",
    "Author of \"Herding Cats: A Life in Politics\"",
    "He followed Dole in the Senate",
    "Former Mississippi senator Trent",
    "Former Mississippi senator",
    "Former senator Trent",
    "Mississippi's Trent",
    "Dole's successor in the Senate",
    "1990's Senate majority leader",
    "Trent of the Senate",
    "Football Hall-of-Famer Ronnie",
    "Trent of the Senate",
    "Ronnie of Fox Sports",
    "Sportscaster Ronnie",
    "Senator who succeeded John Stennis",
    "1990's Senate majority leader",
    "N.F.L. Hall-of-Famer Ronnie",
    "Trent of Mississippi",
    "Football Hall-of-Famer Ronnie",
    "Former Senate majority leader",
    "Frist's predecessor as Senate majority leader",
    "Football Hall-of-Famer Ronnie",
    "Football great Ronnie",
    "Mississippi senator Trent",
    "1990's Senate leader",
    "Trent of the Senate",
    "Sen. Trent ___",
    "Dole's Senate successor",
    "Sen. Trent",
    "Mississippi Senator",
    "Sen. Trent ___",
    "Senator from Mississippi"
  ],
  "hash": [
    "Hodgepodge",
    "___ browns (breakfast side dish)",
    "Jumble",
    "This-and-that dish",
    "___ browns (side dish)",
    "___ mark (#)",
    "With 71-Across, symbol used four times in this puzzle with four different meanings",
    "Corned beef dish",
    "Dish made of leftovers",
    "___ browns (breakfast order)",
    "This-and-that dish",
    "Debate, with \"out\"",
    "___ mark (# symbol)",
    "Diced dish",
    "Meat-and-potatoes dish",
    "Greasy spoon order",
    "___ mark (#)",
    "___ mark (#)",
    "Dinner from previous dinners",
    "Potpourri",
    "Jumble",
    "Jumble",
    "Jumble",
    "\"Slung\" dish",
    "Beanery fare",
    "___ browns (diner fare)",
    "Corned beef concoction",
    "This-and-that dish",
    "Talk (over)",
    "\"Slung\" dish",
    "Discuss, with ?ôover?ö",
    "Work (out)",
    "Chop finely",
    "Dish that's \"slung\"",
    "Dish of leftovers",
    "Truck stop fare",
    "Chop finely",
    "Beanery fare",
    "Talk (over)",
    "This-and-that dish",
    "Work (out)",
    "Corned beef concoction",
    "Jumble",
    "Mess",
    "Reworking of old material",
    "Work (out)",
    "Sauteed dish",
    "Corned beef dish",
    "Slice and dice",
    "Truck stop entree",
    "Olio",
    "Truck stop order",
    "Jumble",
    "Talk (over)",
    "Yesterday's dinner today",
    "Sauteed dish",
    "Dish of leftovers",
    "Jumble",
    "Mess",
    "Muddle",
    "Leftovers dish",
    "Greasy-spoon fare",
    "Improvisation with leftovers",
    "Mess-hall meal"
  ],
  "olla": [
    "Clay pot used for cooking",
    "Earthen pot",
    "Earthen pot",
    "Widemouthed pot",
    "Cantina vessel",
    "Relative of a bean pot",
    "Ceramic jar",
    "Jar for stews",
    "Cookware item",
    "Pueblo cooker",
    "It's a crock",
    "Pueblo pot",
    "Spanish pot",
    "Stewpot",
    "Cantina vessel",
    "Pueblo vessel",
    "Spanish pot",
    "Ceramic vessel",
    "Large crock",
    "Earthen pot",
    "Earthen pot",
    "Vessel over heat",
    "Bit of pottery",
    "___ podrida (spicy stew)",
    "Bean pot",
    "Pot on a fire",
    "Spanish pot",
    "Indian water holder",
    "Earthenware pot",
    "Earthen pot",
    "Oviedo stew",
    "Earthenware jar",
    "Stewpot",
    "Pot over a fire",
    "Earthenware pot",
    "Cooking vessel",
    "Pueblo vessel",
    "Spicy stew",
    "Stewpot",
    "Spicy stew, or its pot",
    "Pot over a fire",
    "Earthen pot",
    "Cantina cooker",
    "Earthen pot",
    "Dish cooked in a pot",
    "Spicy stew",
    "Cantina cooker",
    "___ podrida",
    "___ podrida",
    "Guisado cooker",
    "Earthen pot",
    "Widemouthed jug",
    "Stewpot",
    "Earthen pot",
    "Spicy stew",
    "Meat stew, for short",
    "Stewpot",
    "Chili pot",
    "Stewpot",
    "Earthen pot",
    "Guisado cooker",
    "Spanish pot",
    "Stewpot",
    "Bulging earthenware vessel",
    "Earthenware pot",
    "Spicy stew",
    "Stewpot",
    "Paella pot",
    "Stewpot",
    "Stewpot",
    "Spanish stew",
    "Paella cooker",
    "Stew crock",
    "Stewpot",
    "Pueblo jar",
    "Pueblo pot",
    "Paella pot",
    "Cooking pot",
    "___ podrida",
    "Jar",
    "Cooking pot",
    "Stewpot",
    "Stewpot",
    "___ podrida",
    "Stew",
    "Item of earthenware"
  ],
  "piaf": [
    "Edith who sang \"La Vie en Rose\"",
    "Singer Edith known as \"The Little Sparrow\"",
    "\"La Vie en Rose\" singer",
    "Cotillard won Best Actress for playing her",
    "\"Hymne a l'Amour\" singer",
    "Oscar-winning role for Cotillard",
    "La Mome ___ (The Little Sparrow)",
    "Role for which Marion Cotillard won a 2007 Best Actress Oscar",
    "Cotillard's \"La Vie en Rose\" role",
    "\"La Vie en Rose\" singer",
    "Edith who sang \"La Vie en Rose\"",
    "Singer called \"The Little Sparrow\"",
    "Singer Edith",
    "\"La Vie en Rose\" singer",
    "\"La Vie en Rose\" singer Edith",
    "Singer known as the \"Little Sparrow\"",
    "Singer known as the Sparrow of Paris",
    "\"La Vie en Rose\" singer",
    "\"Milord\" chanteuse",
    "Edith who sang \"La Vie en Rose\"",
    "Singer whose life was made into a 1980 hit play"
  ],
  "pats": [
    "Apt anagram of TAPS",
    "Super Bowl LIII winners, informally",
    "A.F.C. East team, informally",
    "Super Bowl XLIX champs, to their fans",
    "Brady bunch, in headlines",
    "Consoling gestures",
    "New England football team, informally",
    "Gently strokes, as a dog",
    "2015 Super Bowl winners, familiarly",
    "Some congratulations",
    "Some dog rewards",
    "Super Bowl XXXVI champs, to fans",
    "Spread things?",
    "Three-time grid champs of the 2000s",
    "Gentle touches",
    "Consoles, in a way",
    "Congratulates, in a way",
    "Butter slices",
    "Butter servings",
    "Butter slices",
    "Loving strokes",
    "New England team, to fans",
    "Frisks, with \"down\"",
    "They're welcome on the back",
    "Dabs",
    "Taps",
    "Encouraging signs?",
    "They may be directed toward the back",
    "Certain congratulations",
    "Praises for pups",
    "Shows of approval",
    "Butter servings",
    "Some congratulations",
    "Congratulations, of a sort",
    "Butter servings",
    "Puppy pleasers",
    "Some encouragement",
    "Congratulates",
    "Frisks",
    "Butter squares",
    "Nixon and Schroeder",
    "Dog rewards",
    "Encourages",
    "Congratulates in a way"
  ],
  "lahr": [
    "Bert of \"The Wizard of Oz\"",
    "Bert who played the Cowardly Lion",
    "\"The Wizard of Oz\" co-star",
    "Bert of \"The Wizard of Oz\"",
    "Cowardly Lion portrayer",
    "Bert who sang \"If I Only Had the Nerve\"",
    "Bert who played the Cowardly Lion",
    "Actor who was lionized in the 1930s?",
    "Bert of \"The Wizard of Oz\"",
    "Drama critic John of The New Yorker",
    "Bert of \"The Wizard of Oz\"",
    "Cowardly Lion portrayer Bert",
    "1939 Garland co-star",
    "Co-star in the U.S. premiere of \"Waiting for Godot,\" 1956",
    "Cowardly Lion portrayer",
    "Lion portrayer",
    "Player of a big scaredy-cat?",
    "Cowardly Lion portrayer",
    "\"If I were king of the forest ...\" singer",
    "Leonine movie star of old",
    "\"The Wizard Of Oz\" co-star",
    "Player of a big yellow cat",
    "Actor Bert",
    "Bert who played a cowardly lion",
    "Bert of \"The Wizard of Oz\"",
    "Bert who was a Leo, aptly",
    "Bert who sang \"If I Only Had the Nerve\"",
    "Garland's \"cowardly\" co-star",
    "Cowardly Lion portrayer",
    "Actor who roared to fame?",
    "Garland co-star of 1939",
    "Actor Bert in a lion's suit",
    "Lion player in \"The Wizard of Oz\"",
    "1939 co-star of Haley and Bolger",
    "Co-star of Bolger and Haley",
    "Bert of \"The Wizard of Oz\"",
    "Bert who sang \"If I Were King of the Forest\"",
    "Lionized actor?",
    "Actor Bert",
    "Author/critic John",
    "\"If I Only Had the Nerve\" singer",
    "\"The Wizard of Oz\" actor",
    "Cowardly Lion portrayer",
    "Bert of \"The Wizard of Oz\"",
    "Bert of \"The Wizard of Oz\"",
    "His last film was \"The Night They Raided Minsky's,\" 1968",
    "Bert of \"The Wizard of Oz\"",
    "Lionized comedian?",
    "Bert of \"The Wizard of Oz\"",
    "Actor Bert",
    "Bert who was a Leo, aptly",
    "Comic Bert",
    "Bolger co-star of 1939",
    "Cowardly Lion portrayer",
    "Tony winner Bert",
    "1964 Tony winner for \"Foxy\"",
    "\"The Wizard of Oz\" actor",
    "Lion player of 1939",
    "Lion of Hollywood",
    "\"The Wizard of Oz\" actor",
    "Bert of \"The Wizard of Oz\"",
    "?ôPrick Up Your Ears?ö author John",
    "Cowardly Lion portrayer",
    "1939 Bolger co-star",
    "Bolger co-star",
    "Oz lion",
    "Cowardly Lion actor",
    "Actor Bert",
    "Drama critic John",
    "\"The Wizard of Oz\" co-star",
    "Cowardly Lion portrayer",
    "Comic Bert"
  ],
  "yeow": [
    "\"That's my foot!!!\"",
    "Cartoonish wail",
    "\"Ouch!\"",
    "\"Jeez, that's hot!\"",
    "\"Dang, that hurts!\"",
    "\"Hey! That hurts!\"",
    "\"That hurts!\"",
    "Cartoon \"Ouch!\"",
    "\"Man, that smarts!\"",
    "\"That hurts!\"",
    "\"Ouch!\"",
    "\"That HURT!\"",
    "\"That hurts!\"",
    "\"That really hurt!\"",
    "\"Man, that hurts!\"",
    "\"That hurts!\"",
    "\"Yikes!\"",
    "Cry after hitting a hammer on one's thumb, say",
    "\"That hurts!\"",
    "\"Man, that hurts!\"",
    "\"Ouch!\"",
    "\"That hurts!\"",
    "\"That hurt!\""
  ],
  "fahd": [
    "Former Saudi king",
    "Saudi king before Abdullah",
    "Former Saudi king",
    "Late Saudi king",
    "Late Saudi king",
    "Saudi monarch",
    "Saudi Arabian king",
    "Khalid's successor",
    "King of Saudi Arabia",
    "Saudi king"
  ],
  "deis": ["Agnus ___ (prayers)", "Agnus ___ (Mass prayers)"],
  "kool": [
    "Lead-in to Aid",
    "___-Aid",
    "Noted 1970s-'80s Gang leader?",
    "Newport rival",
    "Brand name whose middle two letters are linked in its logo",
    "___-Aid",
    "Noted gang leader",
    "Brand at a checkout counter that's also the name of a Phoenix radio station",
    "Leader of a musical \"gang\"",
    "The Gang's leader",
    "R.J. Reynolds brand",
    "Marlboro competitor",
    "Newport rival",
    "Brand of smokes",
    "Marlboro alternative",
    "Brown & Williamson brand",
    "Brown & Williamson brand",
    "Gang leader of 70's-80's music",
    "Brown and Williamson brand",
    "Leader of the Gang, in 70's-80's music",
    "Camel rival"
  ],
  "ilie": [
    "Nastase of tennis",
    "Nastase of 1970s tennis",
    "1970s tennis champ Nastase",
    "Nastase of tennis",
    "1982 Loretta Lynn song with the lyric \"It's not easy to deceive you\"",
    "1970s tennis champ Nastase",
    "Nastase of tennis fame",
    "\"Would ___?\"",
    "\"Would ___?\"",
    "\"Would ___?\"",
    "\"Would ___?\"",
    "Tennis's Nastase",
    "First name in the International Tennis Hall of Fame",
    "\"Would ___ to you?\"",
    "\"Would ___ to you?\"",
    "Paradoxical assertion, perhaps",
    "Nastase of tennis",
    "Tennis's Nastase",
    "Tennis's Nastase",
    "First name in '70s tennis",
    "Tennis's Nastase",
    "\"Would ___ to you?\"",
    "Tennis's Nastase",
    "Tennis's Nastase",
    "Tennis's Nastase",
    "First name in 1970s tennis",
    "Tennis's Nastase",
    "Netman Nastase",
    "Nastase of tennis",
    "Netman Nastase",
    "\"Would ___?\"",
    "1976 loser to Bjorn at Wimbledon",
    "\"Would ___?\"",
    "\"Why would ___?\"",
    "Tennis's Nastase",
    "\"Would ___ to You?\" (1985 Eurythmics hit)",
    "Tennis champ Nastase",
    "\"Would ___?\"",
    "\"Lo, here ___, / Never to rise again\": \"Hamlet\"",
    "Tennis's Nastase",
    "Nastase of tennis",
    "\"Would ___?\"",
    "Tennis's Nastase",
    "Arthur lost to him at the 1972 U.S. Open",
    "Tennis's Nastase",
    "Nastase of tennis",
    "Tennis's Nastase",
    "\"Would ___?\"",
    "First name in Romanian tennis",
    "1970's tennis champ Nastase",
    "\"Would ___?\"",
    "\"Would ___?\"",
    "Rival of Bjorn",
    "Court rival of Bjorn",
    "\"Would ___ to you?\"",
    "Bjorn rival",
    "Tennis's Nastase",
    "Tennis great Nastase",
    "\"Would ___?\"",
    "Tennis champ Nastase",
    "Court foe of Bjorn",
    "Tennis's Nastase",
    "\"Would ___?\"",
    "First name in 1970's tennis",
    "\"Would ___ to you?\"",
    "\"Would ___?\"",
    "Arthur's onetime court rival",
    "Netman Nastase",
    "First name in 70's tennis",
    "Tennis's Nastase",
    "Rival of Bjorn",
    "Netman Nastase",
    "He beat Arthur at the 1972 U.S. Open",
    "Tennis's Nastase",
    "Tennis's Nastase",
    "Tennis's Nastase",
    "Tennis's Nastase",
    "Netman Nastase",
    "Onetime rival of Bjorn",
    "Netman Nastase",
    "Tennis's Nastase",
    "\"Would ___?\" (sleazeball's question)",
    "\"In a cowslip's bell ___\": \"The Tempest\"",
    "Tennis's Nastase",
    "Rival of Bjorn",
    "Contemporary of Bjorn",
    "Tennis's Nastase",
    "Nastase of tennis",
    "Tennis's Nastase",
    "Nasty, formally",
    "Nasty at court",
    "First name in tennis",
    "Nastase of tennis",
    "Netman Nastase",
    "Colleague of Jimmy and Bjorn",
    "Netman Nastase",
    "Mr. Nastase",
    "Nasty racket operator?",
    "Opponent of Jimmy and Arthur",
    "Nastase of tennis",
    "Tennis hothead",
    "Netman Nastase",
    "Netman Nastase",
    "First name in tennis",
    "Coach Nastase"
  ],
  "musk": [
    "Strong-smelling secretion",
    "Perfume ingredient",
    "Pioneer in commercial spaceflight",
    "Elon who co-founded Tesla",
    "Perfume ingredient",
    "Cologne scent",
    "Cologne ingredient",
    "Kind of ox"
  ],
  "iola": [
    "Joe Hardy's girlfriend in the Hardy Boys books",
    "City SW of Kansas City",
    "Joe Hardy's girlfriend in the Hardy Boys books",
    "Seat of Allen County, Kan.",
    "Kansas town on the Neosho River",
    "Kansas county seat",
    "Seat of Allen County, Kan.",
    "Neighbor on the 1980s sitcom \"Mama's Family\"",
    "Kansas county seat on the Neosho River",
    "Seat of Allen County, Kan.",
    "Seat of Allen County, Kansas",
    "Seat of Allen County, Kan.",
    "Seat of Allen County, Kan.",
    "Seat of Allen County, Kansas",
    "Seat of Allen County, Kan.",
    "Kansas town",
    "Seat of Allen County, Kan.",
    "Southeast Kansas town",
    "Southeastern Kansas town",
    "City in SE Kansas",
    "Southeast Kansas town",
    "Southeast Kansas town"
  ],
  "bcup": [
    "Certain bra spec",
    "Bra size specification",
    "Victoria's Secret spec",
    "Certain bra spec",
    "Smallish lingerie spec",
    "Certain bra specification",
    "Medium bra specification"
  ],
  "geer": [
    "Actor Will of \"The Waltons\"",
    "Will who played Grandpa Walton on \"The Waltons\"",
    "Grandpa Walton portrayer",
    "Will of \"The Waltons\"",
    "Will of \"The Waltons\"",
    "Will of \"Jeremiah Johnson\"",
    "Will of \"The Waltons\"",
    "Will who played Grandpa Walton",
    "Emmy winner Will",
    "\"The Waltons\" actor",
    "Will of \"Jeremiah Johnson\"",
    "Grandpa Walton portrayer Will",
    "\"The Waltons\" actor",
    "Will of Hollywood",
    "Will of \"The Waltons\"",
    "Grandpa Walton portrayer",
    "Grandpa on \"The Waltons\"",
    "One going in the right direction?",
    "Will of \"The Waltons\"",
    "Will of \"The Waltons\"",
    "Grandpa Walton portrayer"
  ],
  "wand": [
    "Means of applying lip gloss",
    "Appurtenance for a T.S.A. agent",
    "T.S.A. agent's tool",
    "T.S.A. tool",
    "Prop for a magician",
    "Airport security worker's device",
    "Magician's prop",
    "Airport security item",
    "Spelling aid?",
    "Item used by a 2-Down",
    "Wizard's stick",
    "Hogwarts stick",
    "Fairy's stick",
    "Harry Potter prop",
    "Fairy's prop",
    "Magician's stick",
    "Harry Potter accessory",
    "Source of magic dust",
    "Magician's need",
    "Tinker Bell prop",
    "Pixie stick",
    "Prop for Doug Henning",
    "What a magician may wave",
    "Cosmetics applicator"
  ],
  "sewn": [
    "In stitches",
    "Basted, e.g.",
    "In stitches",
    "Tailor-made",
    "Stitched",
    "Stitched together",
    "Clinched, with \"up\"",
    "Wrapped (up)",
    "In stitches",
    "Hanging on by a thread?",
    "Tailor-made",
    "In stitches",
    "Wrapped (up)",
    "Wrapped (up)",
    "In stitches",
    "Stitched",
    "In stitches",
    "Tailor-made",
    "Basted",
    "In stitches",
    "Stitched",
    "Like baseball covers",
    "In stitches",
    "Tailor-made",
    "Iced, with \"up\"",
    "Mended",
    "Like flags",
    "Mended",
    "Iced, with \"up\"",
    "Tailor-made",
    "In stitches",
    "___ up (in the bag)",
    "All wrapped (up)",
    "In stitches",
    "Stitched",
    "Clinched, with \"up\"",
    "Pieced together",
    "Embroidered",
    "Concluded, with \"up\"",
    "Basted",
    "Stitched",
    "In stitches",
    "Stitched",
    "Wrapped (up)",
    "Stitched",
    "Stitched",
    "Darned",
    "Basted"
  ],
  "govt": [
    "The \"G\" of Geico: Abbr.",
    "C-Span focus: Abbr.",
    "Political science subj.",
    "Something with many seats: Abbr.",
    "Main subj. of a constitution"
  ],
  "aril": [
    "Seed covering",
    "Seed case",
    "Seed cover",
    "Edible part of a litchi",
    "Seed coat",
    "Seed cover",
    "Seed covering",
    "Botanical cover",
    "Seed cover",
    "Seed cover",
    "Seed coat",
    "Seed casing",
    "Seed covering",
    "Relative of a husk",
    "Seed covering",
    "Seed cover",
    "Seed covering",
    "Seed cover",
    "Seed case",
    "Seed cover",
    "Seed cover",
    "Seed covering",
    "Seed coat",
    "Seed covering",
    "Seed covering",
    "Botanical coat",
    "Seed coat",
    "Seed cover",
    "Seed covering",
    "Seed cover",
    "Seed cover",
    "Husk",
    "Botanical cover",
    "Seed covering",
    "Seed cover",
    "Seed covering",
    "Seed covering",
    "Seed covering",
    "Seed appendage",
    "Seed covering",
    "Seed covering",
    "Seed coat",
    "Seed coat",
    "Seed covering",
    "Seed covering"
  ],
  "prix": [
    "Grand ___",
    "Grand ___",
    "___ fixe (menu notation)",
    "Grand ___ (auto race)",
    "___ fixe",
    "Grand ___ (auto race)",
    "Grand ___ (annual race)",
    "___ fixe",
    "___ fixe",
    "Grand ___ (annual French auto 17-Across)",
    "Grand finale?",
    "Grand finale?",
    "Grand ___",
    "Grand ___",
    "Grand finale?",
    "___ fixe (menu notation)"
  ],
  "whim": [
    "See 24-Across",
    "Impulse",
    "Sudden change of mind",
    "Caprice",
    "Sudden impulse",
    "Sudden impulse",
    "Impulse",
    "Capricious desire",
    "Sudden fancy",
    "Sudden impulse",
    "Caprice",
    "Impulse",
    "A trip can be taken on it",
    "Passing fancy",
    "Idle fancy",
    "Fancy",
    "Caprice",
    "Fancy",
    "Vagary",
    "Impulse",
    "Fancy",
    "Impulse"
  ],
  "huac": [
    "1950s Communist-bashing grp. in Congress",
    "1950s blacklisting grp.",
    "Old blacklisting org.",
    "Grp. that interrogated Alger Hiss",
    "Red Scare grp.",
    "Antisubversive grp. of old",
    "Org. that subpoenaed Abbie Hoffman",
    "Red Scare grp.",
    "Sen. McCarthy ally",
    "Old antisubversive grp.",
    "Blacklisting org. of the 50's",
    "1950's Communist-hunting grp.",
    "Grp. that questioned Alger Hiss",
    "R.M.N. was a member of it",
    "Anti-Red grp.",
    "McCarthy-era hearings grp.",
    "Old Red scare grp.",
    "Former blacklisting org.",
    "Old antisubversive group",
    "1950's Red-hunting grp.",
    "Sen. McCarthy's grp.",
    "Communist-hunting grp. of the 50's",
    "Antisubversive grp. until 1975",
    "Hollywood 10 condemner: Abbr."
  ],
  "deng": [
    "Post-Mao Chinese leader",
    "Mao's successor",
    "Leader between Mao and Jiang",
    "Wendi ___, ex-wife of Rupert Murdoch",
    "Tiananmen Square demonstration suppressor",
    "Leader after Mao",
    "China's ___ Xiaoping",
    "China's ___ Xiaoping",
    "Jiang's predecessor",
    "China's ___ Xiaoping",
    "China's ___ Xiaoping",
    "Jiang's predecessor as Chinese Communist leader",
    "Post-Mao Chinese leader",
    "Communist leader after Mao",
    "Successor of Mao",
    "China's ___ Xiaoping",
    "Mao successor",
    "Successor to 5-Across",
    "Mao's successor",
    "Late Chinese leader",
    "Hua's successor",
    "Mao successor",
    "___ Xiaoping",
    "Tiananmen Square crusher",
    "Mao successor"
  ],
  "lyse": [
    "Disintegrate, in a way, as cells in the body",
    "Dissolve, as cells"
  ],
  "rolf": [
    "\"The Sound of Music\" character who's \"17 going on 18\"",
    "Deeply massage",
    "Ida for whom a massage therapy is named",
    "Massage intensely",
    "Massage deeply",
    "Massage deeply",
    "Liesl's love in \"The Sound of Music\"",
    "Massage therapeutically",
    "Massage deeply",
    "Massage deeply",
    "Give a good rubdown",
    "Use deep massage on",
    "Massage deeply",
    "Massage deeply",
    "Massage intensely",
    "Massage deeply",
    "Massage, in a way",
    "Do body work of a sort",
    "Massage deeply",
    "Massage deeply",
    "Place-kicker Benirschke"
  ],
  "dara": [
    "Swimmer Torres who medaled in five Olympic Games",
    "Swimmer Torres with 12 Olympic medals",
    "Swimmer Torres with 12 Olympic medals",
    "___ Torres, 12-time Olympic swimming medalist",
    "Five-time Olympian Torres",
    "Olympic swimmer Torres",
    "___ Torres, four-time Olympic swimming gold medalist",
    "___ Torres, four-time Olympic swimming gold medalist",
    "Gold-medal Olympic swimmer ___ Torres",
    "U.S. Olympic swimming gold medalist Torres",
    "Neil Sedaka's daughter/co-singer",
    "Operatic basso Enzo ___"
  ],
  "webb": [
    "Lucy ___ Hayes, 1800s first lady",
    "Spud ___, 5'7\" N.B.A. star who famously won a Slam Dunk Contest",
    "Jack who played Sgt. Friday on \"Dragnet\"",
    "\"The Amazing Spider-Man\" director, amazingly enough",
    "Jack who starred on \"Dragnet\"",
    "Onetime Virginia senator Jim",
    "Jack of \"Dragnet\"",
    "Jack who played Sgt. Friday",
    "Jimmy who wrote \"Galveston\" and \"MacArthur Park\"",
    "Jack of \"Dragnet\"",
    "Former N.B.A. star Spud",
    "Jack who played Sgt. Friday",
    "Jack who said \"Just the facts, ma'am\"",
    "Friday player",
    "\"Galveston\" songwriter Jimmy",
    "\"Our Town\" family name",
    "Supermodel Veronica",
    "Jim who wrote \"MacArthur Park\"",
    "\"Dragnet\" star",
    "\"Just the facts, ma'am\" speaker",
    "Chick ___, who played \"Stompin' at the Savoy\"",
    "Jack who said \"Just the facts, ma'am\"",
    "Emily of \"Our Town\"",
    "Emily of \"Our Town\"",
    "Actor Jack",
    "\"Dragnet\" star",
    "Jack of \"Dragnet\""
  ],
  "rank": [
    "Malodorous",
    "Malodorous",
    "Horizontal line on a chessboard",
    "Status",
    "Stinky",
    "Malodorous",
    "Captain or major",
    "Arrange in order",
    "Captain, e.g.",
    "Major, for example",
    "What stars may indicate",
    "What stars might indicate",
    "It might be pulled",
    "Captain, for one",
    "One of eight in chess",
    "Relative standing",
    "Needing a bath badly",
    "Place in the pecking order",
    "File's counterpart",
    "File's partner",
    "Colonel or captain",
    "Row",
    "Have value",
    "Captain, e.g.",
    "Major, for one",
    "Military level",
    "General or major",
    "Colonel or captain",
    "Captain, e.g.",
    "Like some amateurs",
    "Colonel or corporal",
    "General, e.g.",
    "What stripes may indicate",
    "Noisome",
    "Malodorous",
    "Captain, e.g.",
    "Echelon",
    "Captain, e.g.",
    "File's partner",
    "Chessboard line",
    "Assign places to",
    "Offensive"
  ],
  "afta": [
    "Old Spice alternative",
    "Shaving aisle brand",
    "Skin conditioner brand",
    "Men's grooming brand",
    "Skin Bracer alternative",
    "Old Spice alternative",
    "Mennen product",
    "Mennen shaving product",
    "Aqua Velva alternative",
    "Mennen shaving product",
    "Old Spice alternative",
    "Mennen line",
    "Aqua Velva competitor",
    "Shaving lotion brand",
    "Shaving brand",
    "Colgate product for men",
    "Old Spice alternative",
    "English Leather alternative",
    "Old Spice rival",
    "Old Spice alternative",
    "Shaving lotion brand",
    "Brut rival",
    "Brand seen near razors",
    "Mennen shaving brand",
    "Aqua Velva alternative",
    "Brand of shaving products",
    "Colgate brand"
  ],
  "clef": [
    "Pitch setter",
    "Sign on a staff",
    "Staff leader?",
    "Curly musical symbol",
    "Score marking",
    "Pitch setter",
    "First symbol on a musical staff",
    "Word after bass or treble",
    "Head of staff?",
    "Sign at the front of some bars",
    "Musical symbol",
    "One on staff?",
    "G ___",
    "Start of a score",
    "Figure at the left side of a musical staff",
    "Tenor ___",
    "Pitch setter",
    "Register indicator",
    "Bass ___",
    "Staff symbol",
    "C ___",
    "Sign seen in front of some bars",
    "Symbol on the front of some bars",
    "Staff leader",
    "Music staff symbol",
    "Musical staff symbol",
    "Musical symbol",
    "Staff symbol",
    "Staff leader?",
    "Staff symbol",
    "Staff leader",
    "Sign at the front of some bars",
    "One that heads up the staff",
    "Staff leader",
    "Staff symbol",
    "G, maybe",
    "Staff symbol",
    "Staff opening",
    "Pitch indicator",
    "C or G",
    "G ___",
    "Musical sign",
    "Pitch indicator",
    "C or G, e.g.",
    "Staff sign",
    "F or G, e.g."
  ],
  "flex": [
    "Sport one's biceps",
    "Show off one's muscles",
    "Show off, as muscles",
    "Move a muscle?",
    "Show off at Muscle Beach",
    "Show off one's \"guns\"",
    "Display some guns",
    "Bend one's elbow, e.g.",
    "Show off at the gym, say",
    "Flaunt, say, as muscles",
    "Show off at the gym",
    "Show off, like Mr. America",
    "Show off one's biceps",
    "Bend",
    "Show muscle?",
    "Bend",
    "Bend"
  ],
  "itgo": [
    "\"Let ___!\"",
    "\"Let ___\"",
    "\"Let ___ already!\"",
    "\"Let ___\"",
    "\"Make ___ away\"",
    "\"Let ___\" (\"Quit worrying\")",
    "\"Don't let ___ to your head\""
  ],
  "dvrs": ["Devices issued with some TV subscription packages"],
  "mris": [
    "Some diagnostic scans, for short",
    "Parts of some neuro exams",
    "Some hospital pics",
    "They aid in diagnosing A.C.L. tears",
    "Some hospital pics",
    "Some scans, for short",
    "Some hosp. tests",
    "Drs. may order them",
    "Providers of inside looks?",
    "Some med. scans",
    "CT scan alternatives",
    "Pics for docs",
    "Ways to get inside hip joints?",
    "3-D tests",
    "Scans ordered by M.D.'s",
    "Dr.'s orders",
    "Dr.'s orders",
    "Hosp. testing techniques",
    "M.D.'s diagnostic tools"
  ],
  "fads": [
    "Cultural flashes in the pan",
    "They're in one year and out the other",
    "Man buns and the Mannequin Challenge, once",
    "They're in",
    "They're only in for a while",
    "Rubik's Cube and troll dolls, once",
    "They're not hot for very long",
    "They come and go",
    "Lava lamps and pet rocks, once",
    "Rage inducers",
    "They don't stay hot for very long",
    "They come and go",
    "The twist, once, and others",
    "Sudoku and others",
    "Crazes",
    "In things",
    "Teletubbies and others",
    "Collecting Pokemon cards, and others",
    "Passing things",
    "Hula-Hoops, lava lamps, etc.",
    "Manias",
    "Hula hoops and such",
    "Macarena and others",
    "Hula hoops, mood rings, etc."
  ],
  "soir": [
    "Matin's opposite",
    "\"Bon\" time",
    "Yves's evening",
    "Setting for a sunset on the Seine",
    "\"Bon\" time in France",
    "Nuit lead-in",
    "Evening in Paris",
    "Evening for Yves",
    "Time after le soleil sets",
    "It may be \"bon\"",
    "Matin's opposite",
    "Yves's eve",
    "France ___ (Parisian daily)",
    "Evening, to Yves",
    "\"Bon ___\"",
    "Evening, in Paris",
    "\"Bon ___\"",
    "Debussy's \"Beau ___\"",
    "Evening for Yvette",
    "Matin's opposite",
    "\"Bon\" time",
    "Evening abroad",
    "\"Bon ___\" (good evening, abroad)",
    "Evian evening",
    "It's apres apres-midi",
    "Evening, in Evreux",
    "Evening in Paris",
    "Paris newspaper France-___",
    "Yves's eve"
  ],
  "flog": [
    "Excessively promote",
    "Whip",
    "Promote shamelessly",
    "Lash",
    "Whip",
    "Promote to annoyance",
    "Beat",
    "Whip",
    "Lash",
    "Beat",
    "Whip",
    "Aggressively promote",
    "Thrash",
    "Aggressively publicize",
    "Cane",
    "Birch",
    "Whip",
    "Wield the whip",
    "Lash"
  ],
  "pitt": [
    "See 46-Down",
    "Brad of \"Fight Club\"",
    "The Panthers of the A.C.C.",
    "Surname of father-and-son British P.M.s",
    "William ___, early British P.M.",
    "The Panthers of the A.C.C.",
    "See 57-Across",
    "British P.M. before and after Addington",
    "British P.M. during the Seven Years' War",
    "Brad of \"Moneyball\"",
    "Brad of \"Moneyball\"",
    "See 1-Down",
    "The Great Commoner",
    "Big East team",
    "The Panthers of the N.C.A.A.",
    "British leader in the Seven Years' War",
    "Surname of two British P.M.'s",
    "The Big East's Panthers, for short",
    "Big East Conference team, for short",
    "Pennsylvania university, for short",
    "First Earl of Chatham",
    "British P.M. when the U.S. Constitution was signed",
    "Willis's \"Twelve Monkeys\" co-star",
    "Pennsylvania university, for short",
    "The Panthers of the Big East",
    "Two-time designee as People's Sexiest Man Alive",
    "Brad of film",
    "Longtime British Tory P.M.",
    "\"Se7en\" star, 1995",
    "The Panthers of the Big East",
    "British P.M. under George III",
    "Whig prime minister of Britain",
    "The Great Commoner",
    "Big East team",
    "Name of two British P.M.'s",
    "Sex symbol Brad",
    "British P.M., 1783-1801",
    "The Panthers of the Big East Conference",
    "Big East team",
    "Brad of \"Seven Years in Tibet\"",
    "24-year-old British P.M.",
    "\"Seven Years in Tibet\" star",
    "English P.M. called \"The Great Commoner\"",
    "Big East powerhouse",
    "The Great Commoner",
    "Englsih statesman William",
    "The Great Commoner"
  ],
  "dory": [
    "Simple craft",
    "Fish trying to find Nemo in \"Finding Nemo\"",
    "Place to fish from",
    "Fishing vessel",
    "Boat with oars",
    "Traditional fishing boat",
    "Flat-bottomed boat",
    "Small fishing vessel",
    "Lake craft",
    "Fishing boat",
    "Hunky-___",
    "Grand Canyon craft",
    "Fishing site",
    "Paddled craft",
    "Boat with a high bow",
    "Open boat",
    "Site for a 37-Down",
    "Fishing boat",
    "Boat with oars",
    "Boat with a V-shaped transom",
    "Small boat",
    "Fishing boat",
    "Fishing boat",
    "Fisher's boat"
  ],
  "arno": [
    "It waters the Boboli Gardens",
    "Florence's river",
    "What the Ponte Vecchio spans",
    "It flows through Florence",
    "It flows near the Piazzale Michelangelo",
    "View from the Piazzale Michelangelo",
    "River of Florence",
    "Central Italian river",
    "Uffizi Gallery's river",
    "River to the Ligurian Sea",
    "View from the Ponte alla Carraia",
    "View from the Uffizi Gallery",
    "Ligurian Sea feeder",
    "It's spanned by the Ponte Santa Trinita",
    "Ligurian Sea feeder",
    "River through Florence",
    "River through Florence",
    "Florence's river",
    "Florence's river",
    "River that can be seen from the Uffizi Gallery",
    "River of Pisa",
    "River under the Ponte Vecchio",
    "River of Pisa",
    "It springs from Monte Falterona",
    "Pisa's river",
    "1966 Florentine flooder",
    "The Piazzale Michelangelo affords a view of it",
    "River through Florence",
    "River near the Leaning Tower",
    "Oscar Wilde poem \"By the ___\"",
    "The New Yorker cartoonist Peter",
    "The Ponte Vecchio spans it",
    "Classical Italian typeface",
    "View from the Leaning Tower",
    "River through Florence",
    "Florence's river",
    "It flows near the Piazzale Michelangelo",
    "Sight from the top of the Leaning Tower",
    "Pisa's river",
    "Florence's river",
    "It flows through 55-Across",
    "The Ponte Santa Trinita spans it",
    "River of Florence",
    "River through Florence",
    "River with historic flooding in 1966",
    "The Ponte alle Grazie spans it",
    "River of Florence",
    "River under the Ponte Vecchio",
    "River through Pisa",
    "The New Yorker cartoonist Peter",
    "Florence's river",
    "Part of the view in \"A Room With a View\"",
    "View from the Ponte Vecchio",
    "River of Tuscany",
    "Florence's river",
    "Florence's river",
    "River to the Ligurian Sea",
    "It flows through Florence",
    "River through Florence",
    "Florence is on it",
    "River of Tuscany",
    "The New Yorker cartoonist Peter",
    "Ponte Vecchio's river",
    "River that rises in Monte Falterona",
    "River to the Ligurian Sea",
    "Peter ___, classic cartoonist for The New Yorker",
    "Florence's river",
    "Florence's river",
    "River of Tuscany",
    "Ligurian Sea feeder",
    "River of Florence",
    "Ligurian Sea feeder",
    "Florence's river",
    "Florence's river",
    "Pisa's river",
    "River through Florence",
    "Classic New Yorker cartoonist",
    "Cartoonist Peter",
    "Florence's river",
    "The Ponte Vecchio crosses it",
    "The New Yorker cartoonist Peter",
    "Arezzo's river",
    "The New Yorker cartoonist Peter",
    "Cartoonist Peter",
    "Florence's river",
    "Cartoonist Peter",
    "River spanned by 27-Across",
    "It's under the Ponte Vecchio",
    "\"Man in the Shower\" cartoonist",
    "Florence is on it",
    "River spanned by the Ponte Vecchio",
    "Florence's river",
    "Florence's river",
    "Italy's Val d'___",
    "Physics Nobelist Penzias",
    "The New Yorker cartoonist Peter",
    "Italy's fertile Val d'___",
    "European river known for disastrous floods",
    "River connected by canal to the Tiber",
    "Cartoonist Peter",
    "Cartoonist Peter",
    "Florence flooder",
    "Cartoonist Peter",
    "Flower through Florence",
    "Florentine flower",
    "Cartoonist Peter",
    "Florence's river",
    "Florentine river",
    "Cartoonist Peter",
    "River known for disastrous floods",
    "Florence's river",
    "Cartoonist Peter",
    "The New Yorker cartoonist Peter",
    "Cartoonist Peter",
    "Florence's river",
    "Classic New Yorker cartoonist",
    "Florence's river",
    "\"A Room With a View\" view",
    "Cartoonist Peter",
    "Florence's river",
    "Florence's river",
    "Cartoonist Peter",
    "River under the Ponte Vecchio",
    "Florence's river",
    "Big-bang physicist Penzias",
    "River to the Ligurian Sea",
    "Florence's river",
    "River through Pisa",
    "Ponte Vecchio site",
    "Cartoonist Peter",
    "Cartoonist Peter",
    "Cartoonist Peter",
    "River through Florence",
    "Peter the cartoonist",
    "Cartoonist Peter",
    "The Ponte Vecchio crosses it",
    "Florence's river"
  ],
  "bola": [
    "Weapon on a cord",
    "Gaucho's weapon",
    "Hurled weapon",
    "Weapon with a rope and balls",
    "Weapon swung by a gaucho",
    "Limb-entangling weapon",
    "Weapon that's thrown",
    "Weighted weapon used by the Inca army",
    "Weapon with knots",
    "Limb-entangling weapon",
    "Gaucho's weapon",
    "Gaucho's weapon",
    "Gaucho's weapon",
    "Weapon swung by a gaucho",
    "Gaucho's weapon"
  ],
  "hite": [
    "Shere who wrote \"Sexual Honesty: By Women for Women\"",
    "Sexologist Shere",
    "Shere who wrote \"Women and Love\"",
    "\"Sexual Honesty\" compiler",
    "\"Women and Love\" writer",
    "\"The ___ Report,\" 1976 best seller",
    "Author Shere",
    "\"Women and Love\" author Shere",
    "\"The ___ Report,\" 1976 best seller",
    "Sexologist Shere",
    "Sexologist Shere",
    "\"Women and Love\" author",
    "\"The ___ Report\" (1976 best seller)",
    "\"Women and Love\" author",
    "\"Women and Love\" author",
    "Issuer of a famous report",
    "1976 best seller \"The___Report\"",
    "\"The ___ Report\" (1976 best seller)",
    "Sexologist Shere",
    "Writer Shere",
    "\"The ___ Report\" (1976 bestseller)"
  ],
  "bleu": [
    "Like the leftmost stripe on le drapeau francais",
    "Chicken cordon ___",
    "___ cheese",
    "Cordon ___",
    "Une couleur primaire",
    "Kind of cheese",
    "Like the French sky",
    "Cordon ___",
    "French flag hue",
    "Like the sky, in France",
    "See 59-Across",
    "___ cheese",
    "___ cheese",
    "___ cheese",
    "Kind of cheese",
    "___ cheese",
    "___ cheese",
    "French flag color",
    "___ cheese",
    "Chicken cordon ___",
    "Sky color, in Paris",
    "___ cheese (salad topping)",
    "Color of le ciel",
    "Color of the Seine",
    "___ cheese dressing",
    "Conte ___ (fairy tale): Fr.",
    "Color of le ciel",
    "___ cheese",
    "Cordon ___"
  ],
  "gobs": [
    "Oodles",
    "A ton",
    "A host",
    "A whole bunch",
    "A large amount",
    "Oodles",
    "Bunches",
    "A whole bunch",
    "Seamen, slangily",
    "Oodles",
    "A lot",
    "A bunch",
    "A lot",
    "Oodles",
    "Oodles",
    "Oodles",
    "A great many",
    "Bushels",
    "Lots",
    "Bluejackets"
  ],
  "cold": [
    "Refrigerated",
    "Faucet handle on the right",
    "Nowhere near the target, in a children's guessing game",
    "Distant",
    "Frigid",
    "Having no active leads",
    "With 44-Down, conflict between 7-Down and the 34-Down",
    "Refrigerated",
    "Siberian",
    "At a dead end, as a case",
    "Wintry",
    "How you may know something",
    "Distant",
    "In the 20's, say",
    "Chilled",
    "Icy",
    "Winter woe",
    "One way to be out",
    "Like a very rare day in hell",
    "Common ailment",
    "Like gazpacho",
    "Like many a detective's trail",
    "Arctic",
    "Weak, as a scent",
    "Algid",
    "Winter woe"
  ],
  "gosh": [
    "\"Oh my!\"",
    "\"Oh, man!\"",
    "\"Holy cow!\"",
    "\"Holy guacamole!\"",
    "\"Goodness me!\"",
    "\"Holy smokes!\"",
    "Word of wonder",
    "\"My goodness!\"",
    "\"My oh my!\"",
    "\"Wow!\"",
    "\"Wowzers!\"",
    "\"Oh my!\"",
    "\"Goodness me!\"",
    "Exclamation before \"darn it!\"",
    "\"Jeepers!\"",
    "\"Yipe!\"",
    "\"Holy cow!\"",
    "\"Gee willikers!\"",
    "\"Holy cow!\"",
    "\"Oh, my stars!\"",
    "\"Oh, my!\"",
    "\"Oh my ___!\"",
    "\"Gee whiz!\"",
    "\"Gee whiz!\"",
    "Beaver damn?",
    "\"Gee whillikers!\"",
    "\"Gee whillikers!\"",
    "\"Hey, I never thought of that!\"",
    "\"Oh, my!\"",
    "More than \"hmmm!\"",
    "\"Gee whillikers!\"",
    "\"Gee!\"",
    "\"No kidding!\"",
    "\"No kidding!\"",
    "\"You don't say!\"",
    "\"I'll be!\"",
    "Mild oath",
    "\"Holy cow!\"",
    "\"Gee whiz!\""
  ],
  "coil": [
    "Spring feature",
    "Tesla ___",
    "Snake's shape",
    "\"When we have shuffled off this mortal ___\": Hamlet",
    "Slinky, e.g.",
    "Spring's shape",
    "Cobra's shape, at times",
    "Hose shape",
    "Spiral shape",
    "Slinky, e.g.",
    "Wind up",
    "Stamp purchase",
    "Thing to wind",
    "Stamp purchase",
    "Rattlesnake's shape",
    "Spring",
    "Electromagnet component",
    "Spring shape",
    "Postage purchase",
    "Slinky's shape",
    "Spring",
    "Rattler's posture",
    "Heater feature",
    "Wire arrangement",
    "Wind",
    "Helix",
    "Kind of spring",
    "Spring, maybe",
    "Shakespeare's was \"mortal\"",
    "Slinky, basically",
    "Spring feature",
    "Stamp purchase",
    "Heater component",
    "Radiator part",
    "Snake shape"
  ],
  "pell": [
    "Big name in student grants",
    "Kind of grant",
    "Eponym of a federal college grant",
    "___ Grant (college financial aid)",
    "Student grant named for a senator",
    "Big name in educational grants",
    "Big name in educational funding",
    "___-mell",
    "___-mell",
    "Study grant named for a senator",
    "Study grant named after a Rhode Island senator",
    "Educational grant named for a senator",
    "___ Grant (college scholarship)",
    "___-mell",
    "Former Rhode Island Senator",
    "Claiborne of Rhode Island"
  ],
  "thar": [
    "Yonder, in dialect",
    "Thataway, from a crow's-nest",
    "\"___ she blows!\"",
    "Them ___ hills",
    "\"___ she blows!\"",
    "\"___ she blows!\"",
    "Whaler's direction?",
    "Where she blows?",
    "Seafarer's adverb",
    "\"There's gold in them ___ hills!\"",
    "Direction for a whaler?",
    "Old salt's direction",
    "Whaling adverb",
    "\"There's gold in them ___ hills!\"",
    "Dialectal direction",
    "Old salt's adverb",
    "In that direction, to a whaler",
    "\"___ she blows!\"",
    "Whaler's direction",
    "Where a whale may be found?",
    "Where the whale is?",
    "Whaler's direction",
    "In that place, to a whaler",
    "Over yonder",
    "Where, to a whaler?",
    "\"___ she blows!\"",
    "Whale's location?",
    "\"___ she blows!\"",
    "Start of a whaler's cry",
    "Whaler's direction",
    "Where she blows",
    "\"___ she blows!\"",
    "\"___ she blows!\""
  ],
  "tran": [
    "Second-most common Vietnamese surname",
    "Second most popular Vietnamese surname (after Nguyen)",
    "Second-most common family name in Vietnam, after Nguyen",
    "Second-most common Vietnamese family name, after Nguyen",
    "Air___, discount carrier"
  ],
  "esai": [
    "Actor Morales",
    "Actor Morales",
    "Morales of \"NYPD Blue\"",
    "Morales of \"NYPD Blue\"",
    "Actor Morales of \"The Brink\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales of \"La Bamba\"",
    "Morales of \"NYPD Blue\"",
    "Morales of \"La Bamba\"",
    "Morales of \"Criminal Minds\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "Morales of \"Criminal Minds\"",
    "Morales of \"NYPD Blue\"",
    "Morales of HBO's \"The Brink\"",
    "Actor Morales",
    "Morales of \"NYPD Blue\"",
    "Morales of \"NYPD Blue\"",
    "Morales of \"La Bamba\"",
    "Morales of \"NYPD Blue\"",
    "Actor Morales of \"La Bamba\"",
    "Morales of \"La Bamba\"",
    "Morales of \"La Bamba\"",
    "Morales of film",
    "Lou's \"La Bamba\" co-star",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "Morales of \"Jericho\"",
    "Morales of \"Caprica\"",
    "Actor Morales",
    "Morales of \"Caprica\"",
    "Morales of \"NYPD Blue\"",
    "Morales of \"Gun Hill Road\"",
    "Actor Morales",
    "Morales of \"NYPD Blue\"",
    "Actor Morales",
    "\"NYPD Blue\" actor Morales",
    "Actor Morales",
    "Morales who played a 13-Down officer on TV",
    "Tony's portrayer on \"NYPD Blue\"",
    "Morales of \"La Bamba\"",
    "Morales of \"La Bamba\"",
    "Morales of \"La Bamba\"",
    "Morales in Hollywood",
    "Morales of \"La Bamba\"",
    "Morales in movies",
    "Morales of \"NYPD Blue\"",
    "Lou's \"La Bamba\" co-star",
    "Actor Morales",
    "Morales of \"NYPD Blue\"",
    "Tony's portrayer on \"NYPD Blue\"",
    "\"La Bamba\" actor Morales",
    "Tony player on \"NYPD Blue\"",
    "Morales of \"N.Y.P.D. Blue\"",
    "Morales of \"La Bamba\"",
    "He played Bob in \"La Bamba,\" 1987",
    "Actor Morales",
    "Tony's player on \"NYPD Blue\"",
    "Morales of \"NYPD Blue\"",
    "Actor Morales",
    "Morales of \"NYPD Blue\"",
    "Actor Morales",
    "Morales of \"N.Y.P.D. Blue\"",
    "Morales of \"N.Y.P.D. Blue\"",
    "Tony's portrayer on \"N.Y.P.D. Blue\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "Tony's portrayer on \"N.Y.P.D. Blue\"",
    "Morales of movies",
    "Morales of \"La Bamba\"",
    "Morales of \"N.Y.P.D. Blue\"",
    "Morales of movies",
    "Actor Morales",
    "Morales of \"N.Y.P.D. Blue\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"N.Y.P.D. Blue\"",
    "Actor Morales",
    "Actor Morales",
    "Morales in movies",
    "Morales of \"La Bamba\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "Morales of \"La Bamba\"",
    "Lou's \"La Bamba\" co-star",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "He played Bob in \"La Bamba\"",
    "Actor Morales",
    "Actor Morales",
    "Hollywood's Morales",
    "Morales of \"La Bamba\"",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Actor Morales",
    "Morales of \"La Bamba\"",
    "Morales of \"La Bamba\"",
    "Actor Morales",
    "Actor Morales"
  ],
  "bate": [
    "Lessen",
    "Reduce in intensity",
    "Lessen",
    "Diminish",
    "Reduce in force or intensity",
    "Restrain, as one's breath",
    "Moderate",
    "Diminish",
    "Restrain",
    "Diminish",
    "Diminish",
    "Restrain",
    "Diminish",
    "Restrain",
    "Restrain",
    "Restrain",
    "Restrain"
  ],
  "swit": [
    "Loretta of \"M*A*S*H\"",
    "\"M*A*S*H\" actress Loretta",
    "\"M*A*S*H\" actress",
    "Houlihan player on \"M*A*S*H\"",
    "\"M*A*S*H\" Emmy winner",
    "\"M*A*S*H\" co-star",
    "Loretta of \"M*A*S*H\"",
    "Houlihan player on TV",
    "\"M*A*S*H\" co-star",
    "Loretta of \"M*A*S*H\"",
    "\"M*A*S*H\" co-star",
    "Cagney player in the \"Cagney & Lacey\" pilot film",
    "Loretta of \"M*A*S*H\"",
    "Houlihan portrayer",
    "\"M*A*S*H\" Emmy winner",
    "\"M*A*S*H\" co-star",
    "Loretta who played Hot Lips",
    "\"M*A*S*H\" Emmy-winner",
    "Emmy-winner Loretta",
    "Houlihan portrayer",
    "Maj. Houlihan portrayer in \"M*A*S*H\"",
    "\"M*A*S*H\" regular",
    "Hot Lips Houlihan player",
    "\"M*A*S*H\" Emmy winner",
    "Houlihan portrayer",
    "\"M*A*S*H\" actress"
  ],
  "leet": [
    "System of modified spellings used on the internet",
    "Erstwhile manorial court"
  ],
  "isao": [
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golf's Aoki",
    "Golfer Aoki",
    "___ Takahata, Oscar-nominated director of 2013's \"The Tale of Princess Kaguya\"",
    "Golfer Aoki",
    "Aoki of the Champions Tour",
    "Golfer Aoki",
    "Aoki of the World Golf Hall of Fame",
    "Golfer Aoki",
    "Aoki of the P.G.A.",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Aoki of the P.G.A.",
    "Aoki of the World Golf Hall of Fame",
    "Golf's Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golf's ___ Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer Aoki",
    "Golfer ___ Aoki",
    "Golfer Aoki",
    "Golfer ___ Aoki"
  ],
  "clay": [
    "French Open court material",
    "Medium for a model",
    "Pot-making supply",
    "Modeling medium",
    "Adobe material",
    "Body, metaphorically",
    "Medium for cuneiform writing",
    "Potter's base",
    "Molding material",
    "Potter's supply",
    "Pottery base",
    "Ali, before he was Ali",
    "Ali, before he was Ali",
    "It's molded",
    "Adobe material",
    "Grass alternative",
    "Potter's medium",
    "Pot composition",
    "Modeler's need",
    "*Trapshooting ... Ali ... kiln",
    "Potter's supply",
    "It might crack after getting fired",
    "Kind of pot",
    "Like some pots",
    "Sculptor's purchase",
    "Modeling material",
    "Kind of court",
    "Brick material",
    "Modeler's medium",
    "Some soil",
    "Nursery school item",
    "Potter's material",
    "Like target pigeons",
    "Ali, once"
  ],
  "veni": [
    "Start of Caesar's boast",
    "Part of Caesar's boast",
    "Start of an old boast",
    "Start of a Julius Caesar quote",
    "Start of a boast",
    "I came: Lat.",
    "Start of a classic boast",
    "First of a Latin trio",
    "\"___, vidi, vici\"",
    "\"___, vidi, vici\"",
    "Caesar's \"I came\"",
    "Start of an old boast",
    "Caesar's \"I came\"",
    "Start of Caesar's boast",
    "\"___ Creator Spiritus\" (Latin hymn)",
    "\"___, vidi, vici\"",
    "Part of a Latin trio",
    "\"___, vidi, vici\" (Caesar's boast)",
    "Part of a boast of Caesar",
    "Start of Caesar's boast",
    "First of a noted trio",
    "\"___, vidi, vici\" (Caesar's boast)",
    "One of a Latin trio",
    "Part of a famed boast",
    "\"___, vidi, vici\"",
    "Start of a boast",
    "Start of Caesar's boast",
    "\"___, vidi, vici\"",
    "Part of Caesar's boast",
    "Start of a classic Latin quote",
    "Start of a Latin boast",
    "I came: Lat.",
    "Start of Caesar's boast",
    "\"___, vidi, vici\"",
    "\"I came,\" to Caesar",
    "First of an ancient trio",
    "\"I came,\" to Caesar"
  ],
  "agin": [
    "Opposed to, rurally",
    "Opposed to, in dialect",
    "Not backing, in the backwoods",
    "Not fer",
    "Feudin' with",
    "\"I'm ___ it!\" (hick's nix)",
    "Backwoods con?",
    "Not \"fer\"",
    "Another time, in \"Li'l Abner\"",
    "Opposed to, to Li'l Abner",
    "Opposed to, in dialect",
    "Not supportin'",
    "Not fer",
    "Not supportin'",
    "Not fer",
    "Fightin'",
    "Feudin' with, say",
    "Opposed to, in dialect",
    "Not \"fer\"",
    "Hostile to, in the hills",
    "Votin' no on",
    "Feudin' with",
    "Sayin' no to",
    "Not backing, in the backwoods",
    "Not fer",
    "Votin' no on",
    "Not fer",
    "Predisposed to fightin'",
    "Not fer",
    "Opposin'",
    "Averse to, in dialect",
    "Arguin' with",
    "Opposed to, in dialect",
    "Not \"fer\"",
    "Opposin'",
    "Not fer",
    "Not accepting nohow",
    "65-Across to, regionally",
    "Not fer",
    "Not fer",
    "Not supporting, slangily",
    "Votin' no",
    "Opposed to, in \"Li'l Abner\"",
    "Not fer",
    "Not fer",
    "Not \"fer\"",
    "Opposed to, in dialect",
    "Opposed to, hillbilly-style",
    "Opposed, in Dogpatch",
    "\"Ah'm ___ it!\"",
    "Not fer",
    "Opposed to, in the boondocks",
    "Opposed to, in Dogpatch",
    "11-Down, Dogpatch-style",
    "\"I'm ___ it!\"",
    "Once more, country-style",
    "Opposed to, in the backwoods",
    "Not fer",
    "Opposed, in Dogpatch",
    "Opposed to, in dialect",
    "Opposed to, in dialect",
    "Opposed, in oaters"
  ],
  "biga": [
    "Nickname for major-league baseball's Angel Stadium, with \"the\"",
    "Nickname for Angel Stadium, with \"the\"",
    "Nickname for Angel Stadium, with \"the\"",
    "N.Y.C. racetrack, informally",
    "Nickname for Angel Stadium, with \"the\"",
    "Nickname for New York's Aqueduct Racetrack",
    "Nickname for Angel Stadium, with \"the\"",
    "Nickname for Anaheim's Angel Stadium",
    "Nickname for Georgia's capital",
    "N.Y.C. racetrack moniker",
    "New York City racetrack, informally",
    "Nickname for the Aqueduct Racetrack",
    "Aqueduct Racetrack, familiarly",
    "N.Y.C. race track, informally"
  ],
  "oran": [
    "Algerian port",
    "Algerian port",
    "Algerian city where Camus's \"The Plague\" is set",
    "Part of the escape route in \"Casablanca\"",
    "\"Casablanca\" escape route city",
    "Port of Algeria",
    "Algerian port",
    "Port where Camus set \"The Plague\"",
    "Algerian port",
    "Algerian port",
    "Algeria's second-largest city",
    "Port of Algeria",
    "\"The Plague\" setting",
    "Algerian port",
    "Setting for Cervantes's \"El Gallardo Espanol\"",
    "Algerian port",
    "Escape route city in \"Casablanca\"",
    "Algerian port",
    "Algerian port",
    "North African port",
    "North African harbor site",
    "North African city captured by the Allies in 1942",
    "Birthplace of Yves Saint Laurent",
    "Algerian port in \"The Plague\"",
    "Algerian port",
    "\"The Plague\" setting",
    "Algerian city",
    "Algerian seaport",
    "Camus's birthplace",
    "Mediterranean port of a million",
    "\"The Plague\" city",
    "North African port",
    "Setting for \"The Plague\"",
    "North African port",
    "Allied target of November 1942",
    "Yves Saint Laurent's birthplace",
    "Pivotal port in W.W. II",
    "Algerian port",
    "Port near Mascara",
    "Algerian seaport",
    "Port of a 1942 Allies landing",
    "\"The Plague\" setting",
    "Mediterranean port",
    "Allied invasion site in W.W. II",
    "Algerian port",
    "Port captured by Allied forces in 1942",
    "City WSW of Algiers",
    "Mediterranean port",
    "Setting of \"The Plague\"",
    "Port east of Gibraltar",
    "Setting for Camus's \"The Stranger\"",
    "Algeria's second-biggest city",
    "Mediterranean port",
    "African city where Yves St. Laurent was born",
    "Algerian port",
    "Algerian port",
    "Setting for Camus's \"The Plague\"",
    "Algeria's Gulf of ___",
    "Algeria's Gulf of ___",
    "Algerian seaport",
    "Algerian port",
    "City captured by Allied forces, November 1942",
    "Algerian port of 600,000",
    "Mediterranean seaport",
    "Port of \"The Plague\"",
    "City NW of Mascara",
    "Algerian port",
    "City WNW of Mascara",
    "Mediterranean port",
    "Mediterranean port",
    "French- and Arabic-speaking seaport",
    "Algerian port",
    "Algerian port",
    "Algerian seaport",
    "Algerian seaport",
    "Port south of Cartagena",
    "Site of Camus's \"The Plague\"",
    "\"The Plague\" setting",
    "Mediterranean port",
    "Algerian seaport",
    "Algerian port",
    "Algerian port",
    "Algerian seaport"
  ],
  "gaul": [
    "Ancient France",
    "Ancient land conquered by Caesar",
    "Caesar's conquest of 58-50 B.C.",
    "Battle of Soissons setting",
    "Ancient France",
    "Caesarean section?",
    "Conquest of Caesar",
    "Conquest of Caesar's",
    "France before it was France",
    "Vandals' target",
    "\"All ___ is divided into three parts\"",
    "Home of ancient Aquitaine",
    "France, under Caesar",
    "Locale of ancient Aquitaine",
    "Site of ancient Aquitania",
    "Conquest of Caesar's",
    "Subject of Caesar's writing",
    "Caesarean conquest",
    "Frenchman",
    "Land on the Rubicon",
    "Ancient France",
    "France, once"
  ],
  "ball": [
    "Sports item absent from wrestling and track",
    "Part of a Cinderella story",
    "*Kind of pen",
    "High pitch, maybe",
    "Where Cinderella lost her slipper",
    "Event for Cinderella",
    "Event for Cinderella",
    "Spalding or Voit product",
    "*Event in \"Cinderella\"",
    "Blast",
    "Event attended by Cinderella",
    "One blowing up a lottery machine?",
    "Cinderella's soiree",
    "Socialite's party",
    "Event Cinderella attended",
    "High-pitched cry from an ump?",
    "See 25-Down",
    "Blast",
    "Cinderella's destination",
    "Event for Cinderella",
    "Part of the foot",
    "Blast",
    "Diamond object",
    "Something passed in courts",
    "Fun time",
    "Masquerader's event",
    "Event attended by Cinderella",
    "Event for Cinderella",
    "Bouncer",
    "Word that can follow the ends of 17-, 26-, 43- and 58-Across",
    "Bouncer?",
    "Wax unit?",
    "\"Cinderella\" event",
    "Good time",
    "Formal dance",
    "Musket missile",
    "Debutante's affair",
    "Horsehide or pigskin",
    "Gala affair",
    "Event for Cinderella",
    "\"Cinderella\" event",
    "\"Cinderella\" event",
    "___ State (David Letterman's alma mater)",
    "Event for Cinderella"
  ],
  "awns": [
    "Grain bristles",
    "Grain bristles",
    "Botanical bristles",
    "Plant bristles",
    "Plant bristles",
    "Stiff bristles",
    "Grass appendages",
    "Barley beards",
    "Beards",
    "Plant bristles",
    "Botanical beards",
    "Bristlelike appendages",
    "Barley beards",
    "Beards",
    "Plant bristles",
    "Barley beards",
    "Grass bristles",
    "Barley beards",
    "Plant bristles",
    "Bristles",
    "Bristles"
  ],
  "suer": [
    "Litigant",
    "Litigant",
    "Plaintiff",
    "One on \"Judge Judy\"",
    "Litigant",
    "One in court",
    "One going before a judge",
    "One seeking damages",
    "\"Judge Judy\" figure",
    "Plaintiff",
    "One who goes a-courting?",
    "Litigant",
    "Litigant",
    "Plaintiff",
    "One who says \"See you in court!\"",
    "Litigious sort",
    "Plaintiff",
    "Litigant",
    "Litigant",
    "Person seen in court",
    "Litigant",
    "Litigious one",
    "Plaintiff",
    "Litigant",
    "Litigant",
    "One righting a wrong, perhaps",
    "Plaintiff",
    "One who prefers charges",
    "Litigant",
    "Litigant",
    "Plaintiff"
  ],
  "rubs": [
    "Barbecue applications",
    "Alternatives to marinades",
    "Summons a genie, perhaps",
    "Chafes",
    "Coats put on at barbecues",
    "Chafes",
    "Chafes",
    "Chafes",
    "Applies, with \"on\"",
    "Annoyances",
    "Massages",
    "Burnishes",
    "Massages",
    "Points of difficulty",
    "Difficulties"
  ],
  "wood": [
    "Cherry, for one",
    "Golfer's choice",
    "Walnut, for one",
    "Choice for a par 5 hole, often",
    "Pinocchio material",
    "Lumber",
    "Beech and birch",
    "Covered club, usually",
    "Makeup of one of the homes of the Three Little Pigs",
    "[5]",
    "Lumber",
    "Alternative to an iron",
    "Alternative to an iron, in golf",
    "Golf club",
    "Ebony, e.g.",
    "Object of the actions suggested by the starts of 17-, 30-, 47- and 66-Across",
    "Iron alternative",
    "Fifth-anniversary gift",
    "Golf club",
    "Cherry, e.g.",
    "Club option",
    "Grove",
    "With 34D, a pretty tree",
    "Percussion instrument struck with a mallet",
    "Whittler?Æs material",
    "Modeling medium",
    "Golf club",
    "Caddie's offering",
    "Not an iron",
    "A three or a five, for instance",
    "Couples club"
  ],
  "barf": ["\"Eww, gross!\"", "[Gross!]", "[Yecch!]"],
  "flip": [
    "Word before phone or book",
    "Go gaga (over)",
    "Somersault",
    "Turn over",
    "Resell quickly",
    "Wig out",
    "Toss, as a coin",
    "1960s hairstyle",
    "Gymnast's move",
    "With 65-Across, part of a record ... or what each of this puzzle's five long Across answers has?",
    "Rapid turnover",
    "Somersault",
    "Freak (out)",
    "Smart-alecky",
    "Resell quickly, as a house",
    "Disrespectful",
    "Saucy",
    "Go bonkers",
    "Page (through)",
    "Simple hairstyle",
    "Gymnast's feat",
    "Turn, as pancakes",
    "Basic gymnastics move",
    "Gymnastics move",
    "Go crazy, slangily",
    "Somersault",
    "Do a burger joint job",
    "Lose it",
    "Smart-alecky",
    "Gymnast's move",
    "Impertinent",
    "Drink topped with nutmeg",
    "Go ga-ga",
    "Mixed drink made with an egg"
  ],
  "gsix": [
    "Germany, France, the U.K., Italy, Spain and Poland, collectively",
    "E.U. alliance"
  ],
  "mens": [
    "Shoe size specification",
    "Store department with jackets and ties",
    "Department store department with shirts and slacks",
    "Clothing department",
    "___ rea (intent to commit a crime)",
    "Kind of store or chorus",
    "Clothing department with jackets and ties",
    "Department store department",
    "Section of a clothing catalog",
    "Clothing store department",
    "Clothing store section",
    "Store department selling suits and ties",
    "Department store department",
    "___ Health magazine",
    "The \"M\" in Y.M.C.A.",
    "Clothing store section",
    "The \"M\" of Y.M.C.A.",
    "___ Health magazine",
    "Department store department",
    "___ chorus",
    "___ Journal (magazine)",
    "Department store department",
    "Department store department",
    "Department store department",
    "___ room",
    "Macy's department",
    "\"___ et manus\" (M.I.T.'s motto)",
    "Department store section",
    "Department store department",
    "Restroom door word",
    "___ room",
    "Kind of room",
    "Magazine genre",
    "Department store department",
    "Magazine genre",
    "Kind of room",
    "The \"M\" in Y.M.C.A.",
    "Store department with jackets, ties and such",
    "Magazine category",
    "Kind of room",
    "Department store department",
    "___ wear",
    "___ Fitness magazine",
    "Kind of room",
    "Department store department",
    "Kind of wear",
    "Boutique department",
    "Department store department",
    "Department store department",
    "Department store department",
    "Clothing department"
  ],
  "seul": [
    "Alone, to Alain",
    "Alone, in Paris",
    "Only: Fr.",
    "Alone, in Arles",
    "Pas ___ (dance solo)"
  ],
  "pura": ["Aqua ___", "Aqua ___", "Aqua ___", "Aqua ___"],
  "sure": [
    "Positive",
    "\"Okey-doke\"",
    "\"Yeah, whatever\"",
    "100% positive",
    "\"Whatever you say\"",
    "\"Oh, what the hell ... I'll do it\"",
    "\"I'm down\"",
    "\"Yeah, why not!\"",
    "\"Yeah, why not!?\"",
    "\"O.K., I'm game\"",
    "\"What-ev-er you say ...\"",
    "Word of agreement",
    "\"You bet!\"",
    "Not doubting",
    "Convinced",
    "\"I don't mind\"",
    "\"Oh, O.K.\"",
    "\"Why not?!\"",
    "\"Positively!\"",
    "\"No problem!\"",
    "___ as shootin'",
    "\"My pleasure!\"",
    "\"I'd be glad to!\"",
    "Guaranteed to happen",
    "\"You bet!\"",
    "\"You betcha!\"",
    "\"Natch!\"",
    "\"Absolutely!\"",
    "Always effective",
    "Ban alternative",
    "\"Certainly!\"",
    "\"No sweat\"",
    "Degree competitor",
    "Beyond doubt",
    "Without a doubt",
    "\"Why not?!\"",
    "\"No problem!\"",
    "\"Okey-doke\"",
    "Convinced",
    "\"You bet!\"",
    "\"Why not?!\"",
    "\"That'd be fine\"",
    "\"You betcha!\"",
    "\"You betcha\"",
    "\"Why not!\"",
    "\"No problem\"",
    "\"Without a doubt!\"",
    "\"You betcha!\"",
    "\"O.K.\"",
    "Confident",
    "Positive",
    "Steady",
    "\"Oh, what the hell\"",
    "Proven",
    "Irrefutable",
    "Positive",
    "\"Yeah, right\"",
    "\"Why not?\"",
    "\"Yeah, right\"",
    "Confident",
    "Positive",
    "Slangy yes",
    "Beyond question"
  ],
  "abat": [
    "Blind as ___",
    "Blind as ___",
    "Blind as ___",
    "Blind as ___",
    "Like ___ out of hell",
    "Like ___ out of hell",
    "\"Like ___ out of ...\"",
    "Blind as ___",
    "Like ___ out of hell",
    "Blind as ___",
    "Like ___ out of hell",
    "Blind as ___",
    "Blind as ___",
    "Like ___ out of hell",
    "Blind as ___",
    "Like ___ out of hell",
    "Like___out of hell",
    "Blind as ___"
  ],
  "eggy": [
    "Like quiche",
    "Like a souffle",
    "Like custard",
    "Like custard",
    "Like quiche",
    "Like French toast",
    "Like a souffle",
    "Like custard and meringue",
    "Like creme brulee",
    "Like many batters",
    "Like some batters",
    "Like a souffle",
    "Like custard",
    "Like an omelet",
    "Like omelets",
    "Like a flan",
    "Like frittatas",
    "Like brioche",
    "Like custard",
    "Like some batters",
    "Like zabaglione",
    "Like French toast",
    "Like custards",
    "Like some batters",
    "Rich, as food",
    "Like zabaglione"
  ],
  "dive": [
    "Go in headfirst",
    "Kind of bar",
    "Cruddy joint",
    "Shabby club",
    "Enter a pool headfirst",
    "Opposite of soar",
    "Drop precipitously",
    "Hardly an upscale bar",
    "Run-down tavern",
    "Low-class watering hole",
    "Action from a springboard",
    "One may be given a 10",
    "Jackknife, for one",
    "Low joint",
    "Intentional loss, in boxing",
    "Get in over one's head?",
    "Ring fix?",
    "Skin or nose follower",
    "Go by the board?",
    "Jackknife, e.g.",
    "Command on a submarine",
    "Belly flop, e.g.",
    "Emulate a loon",
    "Joint",
    "Bathyspheric mission",
    "Belly flop, e.g.",
    "Unsavory bar"
  ],
  "entr": [
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "Start of an intermission?",
    "___'acte",
    "Intermission starter?",
    "___'acte",
    "___'acte",
    "Start of an intermission?",
    "___'acte",
    "___'acte",
    "___'acte",
    "Theater's ___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "Play's ___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte (intermission)",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte",
    "___'acte (intermission)",
    "___'acte",
    "___'acte"
  ],
  "july": [
    "Part of the calendar named for Caesar",
    "National Hot Dog Month",
    "Bastille Day's month",
    "What the beginnings of 17-, 25-, 40- and 52-Across are each a fourth of, phonetically",
    "Hot time",
    "Canada Day starts it"
  ],
  "itan": [
    "Call ___ early night",
    "\"I'd consider ___ honor\"",
    "Call ___ evening",
    "\"I'd consider ___ honor\"",
    "\"I'd consider ___ honor\""
  ],
  "paso": [
    "___ doble (dance)",
    "El ___",
    "___ Robles, Calif.",
    "El ___, Tex.",
    "___ Robles, Calif.",
    "El ___",
    "El ___",
    "___ Robles, Calif.",
    "___ doble (Spanish dance)",
    "Old El ___ (food brand)",
    "Part of una salsa",
    "___ Robles, Calif.",
    "El ___, Tex.",
    "Hit by Marty Robbins, \"El ___\"",
    "Senora's step",
    "___ Robles, Calif.",
    "El ___",
    "El ___",
    "___ doble (Spanish march step)",
    "El ___, Tex.",
    "El ___, Tex.",
    "El ___, Tex.",
    "Step, in Seville",
    "El ___, Tex.",
    "Part of UTEP",
    "El ___",
    "___ doble (Latin dance)",
    "___ doble (Spanish dance)",
    "___ doble (two-step dance)",
    "El ___, Tex.",
    "El ___, Tex.",
    "El ___, Tex.",
    "El ___, Tex.",
    "El ___, Tex.",
    "El ___, Tex.",
    "___ doble (Spanish dance)",
    "El ___, Tex.",
    "El ___",
    "El ___, Tex.",
    "Spanish step",
    "El___, Tex.",
    "El___, Tex.",
    "El___",
    "Opening between montanas",
    "El trailer",
    "El ___"
  ],
  "much": [
    "Significantly",
    "A lot",
    "A lot",
    "\"Thank you very ___\"",
    "A lot",
    "A great deal",
    "Greatly",
    "\"How ___?\"",
    "Considerably",
    "Considerably",
    "Often",
    "A whole lot",
    "Lots of",
    "A lot",
    "Frequently"
  ],
  "rems": [
    "Radiation units",
    "Sleep phenomena",
    "Sleep stages",
    "Dream states, for short",
    "Sleep stages",
    "Signs of dreaming",
    "Phenomena after retiring",
    "Bedtime phenomena",
    "Radiation units",
    "Radiation units",
    "Radiation units",
    "Sleep activities",
    "Radiation measures",
    "Radiation units",
    "Radiation dosages",
    "Sleep phenomena",
    "Sleep phenomena",
    "Sleep phenomena",
    "Radiation units",
    "X-ray measurements",
    "Dream states",
    "Dream stages"
  ],
  "pips": [
    "Spots at the card table",
    "Card count",
    "Spots at a casino",
    "Marks in a casino",
    "Deck spots",
    "Trey trio",
    "Domino features",
    "1960's-70's backup group, with \"the\"",
    "Card spots",
    "Dillies",
    "Show of hands?",
    "Fivesome on a five",
    "Four on a four, e.g."
  ],
  "lars": [
    "Director von Trier",
    "Swedish name akin to Lawrence",
    "Film director ___ von Trier",
    "\"___ and the Real Girl\" (2007 comedy)",
    "\"___ and the Real Girl\" (Ryan Gosling film)",
    "Swedish version of Lawrence",
    "Cousin of Sven",
    "Never-seen neighbor on \"The Mary Tyler Moore Show\"",
    "Ulrich of Metallica",
    "\"___ and the Real Girl\" (2007 Ryan Gosling film)",
    "Phyllis's never-seen TV husband",
    "\"___ and the Real Girl\" (2007 movie)",
    "TV husband of Phyllis",
    "\"___ and the Real Girl\" (2007 film)",
    "2007 film \"___ and the Real Girl\"",
    "Swedish version of Lawrence",
    "Phyllis's 1970's TV husband",
    "TV husband of Phyllis",
    "Metallica drummer Ulrich",
    "Sven's cousin, maybe",
    "Unseen \"Mary Tyler Moore Show\" character",
    "Filmmaker von Trier",
    "1968 Chemistry Nobelist Onsager",
    "Sven's cousin?",
    "Cousin of Sven",
    "1968 Chemistry Nobelist Onsager",
    "Swede's name",
    "Chemistry Nobelist Onsager",
    "Swedish author Gustafsson",
    "Friend of Sven",
    "Lawrence of Scandinavia"
  ],
  "anis": [
    "Black birds",
    "Spanish liqueur",
    "Spanish ouzo flavoring",
    "Black birds",
    "Black birds",
    "Spanish liqueur",
    "Spanish liqueur",
    "Flavoring for a French cordial",
    "Spanish liqueur",
    "Spanish liqueur",
    "Cordoba cordial",
    "Spanish liqueur",
    "French cordial flavoring",
    "Tropical avians",
    "Spanish liqueur",
    "Spanish liqueur",
    "Black cuckoos",
    "Leon liqueur",
    "Cordoba cordial flavoring",
    "French cordial flavoring",
    "Black cuckoos",
    "French cordial flavoring",
    "Spanish liqueur",
    "Cuckoos",
    "Tropical cuckoos",
    "French cordial flavoring",
    "Cuckoos",
    "Cuckoos",
    "Spanish liqueur",
    "Black birds",
    "Black cuckoos",
    "Black cuckoos"
  ],
  "wyle": [
    "Actor Noah of \"ER\"",
    "Noah of \"ER\"",
    "Noah of \"Falling Skies\"",
    "Noah of \"ER\"",
    "Noah of \"Falling Skies\"",
    "Noah of \"ER\"",
    "Noah of \"ER\"",
    "\"ER\" actor"
  ],
  "fred": [
    "Actor Armisen",
    "Neighbor of Lucy and Ricky on \"I Love Lucy\"",
    "Armisen of \"Portlandia\"",
    "Former CBS News chief Friendly",
    "Wilma's hubby on \"The Flintstones\"",
    "Dance-pop trio Right Said ___",
    "Durst of Limp Bizkit",
    "Lead role in \"Miracle on 34th Street\"",
    "Ebenezer Scrooge's nephew",
    "Scrooge's nephew",
    "___ Gailey of \"Miracle on 34th Street\"",
    "Barney's buddy, in cartoondom",
    "Scrooge's nephew in \"A Christmas Carol\"",
    "Ward of Hollywood",
    "Ethel's sitcom husband",
    "Basset hound of the comics",
    "Flintstone fellow",
    "Landlord of Lucy and Ricky",
    "___ Gailey of \"Miracle on 34th Street\"",
    "___ C. Dobbs, noted Humphrey Bogart role",
    "Neighbor of Lucy and Desi",
    "___ Gailey, of \"Miracle on 34th Street\"",
    "A Flintstone",
    "Basset of the comics",
    "Mister Rogers, for one",
    "___ Gailey of \"Miracle on 34th Street\"",
    "N.F.L. receiver Biletnikoff",
    "Oscar-winning film director Zinnemann",
    "___Basset of the comics",
    "Baretta's cockatoo",
    "Mr. Rogers",
    "British astronomer Sir ___ Hoyle",
    "Oscar-winning film director Zinnemann",
    "TV exec Friendly"
  ],
  "fett": [
    "Sci-fi bounty hunter Boba ___",
    "Boba ___, \"Star Wars\" bounty hunter"
  ],
  "doup": [
    "Arrange, in a way, as hair",
    "Fancify",
    "Prettify",
    "Decorate",
    "Arrange, as the hair",
    "Arrange, as hair",
    "Arrange, as hair",
    "Make spiffy",
    "Arrange, as the hair"
  ],
  "paul": [
    "Cathedral eponym",
    "Revolutionary Revere",
    "Singer McCartney",
    "House speaker Ryan",
    "Simon of Simon & Garfunkel",
    "Giamatti of \"Sideways\"",
    "Beatle who was born with the first name James",
    "Author of several New Testament epistles",
    "Left-handed Beatle",
    "Left-handed Beatle",
    "Apostle who wrote \"Ye see how large a letter I have written\"",
    "Epistle apostle",
    "One of the Fab Four",
    "With 5-Down, Microsoft co-founder",
    "One who studied \"at the feet of Gamaliel\"",
    "Klee or Cezanne",
    "Gauguin or Cezanne",
    "Etcher Cadmus",
    "Catherine the Great's successor",
    "Epistle apostle",
    "Name of six popes",
    "Newsman Harvey",
    "Singer Stookey",
    "Legendary Bunyan",
    "Simon of song",
    "Fab Four name",
    "Czar of 1800",
    "Singing Beatle",
    "Novelist Theroux"
  ],
  "hawn": [
    "\"Death Becomes Her\" co-star, 1992",
    "Goldie of \"Snatched\"",
    "Actress Goldie",
    "Oscar winner who was formerly a regular on TV's \"Laugh-In\"",
    "\"The First Wives Club\" co-star, 1996",
    "Goldie of \"Laugh-In\"",
    "\"The First Wives Club\" actress Goldie",
    "\"Foul Play\" actress",
    "Best Supporting Actress for \"Cactus Flower\"",
    "\"Cactus Flower\" Oscar winner",
    "Best Supporting Actress for \"Cactus Flower,\" 1969",
    "Goldie of \"Cactus Flower\"",
    "Goldie of \"Laugh-In\"",
    "\"The Banger Sisters\" co-star, 2002",
    "Goldie of \"Laugh-In\" fame",
    "\"Shampoo\" co-star",
    "\"Laugh-In\" actress",
    "\"Laugh-In\" giggler",
    "\"Foul Play\" star",
    "\"Overboard\" actress, 1987",
    "Actress Goldie",
    "\"Bird on a Wire\" actress"
  ],
  "argh": ["Cry of frustration"],
  "mame": [
    "Broadway auntie",
    "\"We Need a Little Christmas\" musical",
    "Musical with the song \"It's Today\"",
    "Broadway auntie",
    "\"We Need a Little Christmas\" musical",
    "Musical title character who \"made us feel alive again\"",
    "\"We Need a Little Christmas\" musical",
    "\"We Need a Little Christmas\" singer",
    "Hit musical with the song \"Bosom Buddies\"",
    "Musical featuring \"It's Today\"",
    "Broadway Auntie",
    "Auntie of Broadway",
    "\"If He Walked Into My Life\" musical",
    "Title heroine on Broadway, 10/31/56",
    "Patrick Dennis's aunt",
    "1974 title role for Lucille Ball",
    "Broadway aunt",
    "She \"charmed the husk right off of the corn\"",
    "\"If He Walked Into My Life\" musical",
    "\"Life is a banquet\" lady",
    "Broadway \"Auntie\"",
    "Jerry Herman musical",
    "Auntie, dramatically",
    "Broadway musical with the song \"We Need a Little Christmas\"",
    "Blame receiver?",
    "Lucille Ball title role",
    "Lansbury role",
    "Rosalind Russell Broadway role",
    "Broadway \"auntie\"",
    "Rosalind Russell role",
    "Jerry Herman musical",
    "Angela Lansbury role",
    "Jerry Herman composition"
  ],
  "suey": [
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop ___",
    "Chop___"
  ],
  "calc": [
    "Course after trig",
    "High-level math, informally",
    "Course in which to determine a curve's slope, informally",
    "Advanced H.S. class",
    "Advanced high school class, informally",
    "Course that tests one's limits?",
    "Class with derivatives, briefly",
    "Subj. with Riemann sums",
    "Integral course of study, briefly?",
    "Class graded on a curve?",
    "Class after trig",
    "Advanced math class, for short",
    "It has its limits, briefly",
    "Integral course, informally",
    "Subject with limits and functions, informally",
    "Course after trig",
    "Math class, for short",
    "Class after trig",
    "It has many functions, briefly",
    "Integral subj.",
    "Course that has its limits, briefly",
    "Course after trig",
    "Math class, for short",
    "Math course, briefly",
    "Math course, informally",
    "Math class, for short"
  ],
  "vday": [
    "Feb. 14",
    "Heady time for soldiers",
    "When fighting ends, in short"
  ],
  "shod": [
    "Having footwear",
    "Like show horses' hooves",
    "Not barefoot",
    "Protected, as feet",
    "Like show horses",
    "Did a farrier's job on",
    "Like racehorses' feet",
    "No longer barefoot",
    "Like derby entrants",
    "Like a racehorse",
    "Like Derby entrants",
    "Like racehorses",
    "Like many horses' feet",
    "Like dressage horses",
    "Protected, as horses' hooves",
    "Like show horses' feet",
    "Like racehorses' feet",
    "Like plow horses",
    "Having protected feet",
    "Sporting boots, say",
    "Like plow horses",
    "Like show horses' feet",
    "Like some Arabians",
    "Like racehorses",
    "Like plow horses",
    "Not baring one's sole?",
    "Like horses' hooves",
    "Like show horses' feet",
    "Did a farrier's work",
    "Like racehorses",
    "Like show horses' feet",
    "Booted, maybe",
    "Booted, say",
    "Like show horses",
    "Having protected feet",
    "Protected, in a way",
    "Like workhorses",
    "Like most racehorses",
    "Did a farrier's work",
    "Booted?",
    "Fitted at the smithy's",
    "Rough finish?",
    "Unlike wild horses",
    "Like show horses",
    "Not barefoot",
    "Like many horses",
    "Like show horses",
    "Like horse's feet",
    "Like plow horses",
    "Like Clydesdales",
    "Like workhorses",
    "Like show horses",
    "Like show horses",
    "Like plowhorses",
    "Protected, as the feet",
    "Supplied with footwear",
    "Like workhorses",
    "Like most workhorses",
    "Equipped by a blacksmith",
    "Like work horses",
    "Like workhorses",
    "Rough finish",
    "Like show horses",
    "Did blacksmith's work"
  ],
  "oofs": [
    "Reactions to gut punches",
    "Punch lines?",
    "Punch-in-the-gut sounds",
    "\"Batman\" sound effects",
    "Comic book exclamations",
    "Responses to punches",
    "Punch lines?",
    "Punch elicitations",
    "Sounds of pain",
    "Punch responses"
  ],
  "jojo": [
    "\"___ Rabbit,\" 2019 Oscar-nominated film",
    "Who left his home in Tucson, Arizona, in a Beatles tune",
    "Guy from Tucson in a Beatles song",
    "One-named singer with the 2006 hit \"Too Little Too Late\"",
    "Skater Starbuck",
    "Bart Simpson's middle name",
    "\"___ left his home in Tucson, Arizona\" (Beatles lyric)",
    "Skater Starbuck",
    "Skater Starbuck"
  ],
  "pork": [
    "Bill padding",
    "Wasteful government spending",
    "Bill fatteners",
    "Excess spending by Congress",
    "It takes time to cure",
    "Bill add-ons",
    "Legislative excess",
    "Chops in a kitchen",
    "Bill bloater",
    "Government largesse",
    "Much-criticized Congressional spending",
    "Waste of Congress?",
    "\"The other white meat\"",
    "Some Congressional spending",
    "Scrapple ingredient",
    "Bill extras",
    "Kind of barrel",
    "Legislative excess",
    "\"The other white meat\"",
    "Legislative largess",
    "Some government appropriations",
    "Kind of chop"
  ],
  "moos": [
    "Stock sounds",
    "Cow sounds",
    "Cow sounds",
    "Stockyard bellows",
    "Things Old MacDonald hears",
    "\"Old MacDonald Had a Farm\" sounds",
    "Sounds heard by 57-Across",
    "Lows",
    "Farmyard chorus",
    "Calls to farmers",
    "Dairy farm sounds",
    "Lows",
    "Dairy airs?",
    "Farm sounds",
    "Cattle calls",
    "Cows' chorus",
    "Farm sounds",
    "Roundup sounds",
    "Lows",
    "Dairy airs?",
    "Farm sounds",
    "Stockyard sounds",
    "Lows"
  ],
  "dump": [
    "Refuse to go there!",
    "Garbage disposal site",
    "Trash site",
    "Eyesore",
    "Hauler's destination",
    "Break up with, and not nicely",
    "Place for trash"
  ],
  "tazo": [
    "Tea company owned by Unilever",
    "Brand once marketed as \"The Reincarnation of Tea\"",
    "Brand once promoted as \"The reincarnation of tea\"",
    "Brand of tea",
    "Tea brand owned by Starbucks"
  ],
  "xtra": [
    "Bonus, in ad lingo",
    "More, in ads",
    "More, in ads",
    "Additional, in adspeak",
    "Bonus, in ads",
    "Bonus, in commercial lingo",
    "Bonus, in ads",
    "Bonus, in ads",
    "Added, in commercialese",
    "More, in adspeak",
    "More, in ads",
    "Additional, in ads",
    "More, in adspeak",
    "Remarkably, in commercialese",
    "More, in commercialese",
    "Additional, in commercialese",
    "Additional, in ads",
    "Remarkably, in commercialese"
  ],
  "ozma": [
    "Princess in L. Frank Baum books",
    "Princess created by L. Frank Baum",
    "L. Frank Baum princess",
    "L. Frank Baum princess",
    "L. Frank Baum princess",
    "Character in many Baum works",
    "L. Frank Baum princess",
    "L. Frank Baum princess",
    "Princess in 41-Across books",
    "Baum princess",
    "Princess in L. Frank Baum books",
    "Baum princess",
    "Princess of literature",
    "Baum princess",
    "Emerald City princess"
  ],
  "guts": [
    "Fortitude",
    "Fortitude",
    "Intestinal fortitude, informally",
    "Fortitude",
    "Courage",
    "They may be spilled",
    "Kishkes",
    "Fortitude",
    "Moxie",
    "Gumption",
    "Mettle",
    "Grit",
    "Fortitude",
    "Courage",
    "Courage"
  ],
  "city": [
    "Ending of four state capitals [Can you name them all?]",
    "Counterpart of \"highway\" in an m.p.g. rating",
    "Urban area",
    "___ desk (newspaper post)",
    "Part of the names of four state capitals",
    "Word following Kansas or Oklahoma",
    "Dot on a map",
    "Louisville or Baltimore",
    "See 16-Across",
    "Commuter's destination, often",
    "Word in the names of four state capitals",
    "Part of 85-Down",
    "Nancy in France, e.g.",
    "Half of an E.P.A. rating",
    "Part of a mileage rating",
    "The \"C\" in N.Y.C.",
    "Half of an E.P.A. mileage rating",
    "Half a gas mileage rating",
    "Washington or Madison",
    "What a bypass may bypass",
    "Like lowest-mileage driving",
    "Part of a gas mileage rating",
    "It has its limits",
    "Part of N.Y.C.",
    "Jackson or Cleveland",
    "___ hall",
    "See 93-Across or 11-Down",
    "Kind of desk",
    "Word with slicker or hall",
    "Lincoln or Madison",
    "Metro",
    "Slicker's home",
    "Metropolis"
  ],
  "purl": [
    "Knit and ___",
    "Knitting stitch",
    "It's a stitch",
    "Reversal of sorts",
    "Knit alternative",
    "Knitting loop",
    "Knitter's stitch",
    "Reverse movement, of a sort",
    "Knitter's stitch",
    "Knit and ___",
    "Finish with loops",
    "Knit one, ___ two",
    "Small loop",
    "Use a reverse stitch",
    "Knitting stitch",
    "Knitter's stitch",
    "Lace loop",
    "Knitting stitch",
    "Knitting stitch",
    "Knitting loop"
  ],
  "voit": [
    "Brand of basketballs",
    "Basketball brand",
    "Big brand of sports equipment"
  ],
  "ulee": [
    "1997 title role for Peter Fonda",
    "Peter Fonda title role of 1997",
    "Peter Fonda title character",
    "Nickname for Ulysses",
    "Beekeeper of filmdom",
    "Beekeeper in a 1997 movie",
    "1997 Peter Fonda title role",
    "Lead role in the film known in France as \"L'Or de la Vie\"",
    "Title 54-Across of film",
    "Title beekeeper in a 1997 film",
    "Title beekeeper in a 1997 film",
    "Peter Fonda title role",
    "1997 title role for Peter Fonda",
    "1997 Oscar-nominated title role",
    "1997 Peter Fonda title role",
    "Peter Fonda title role",
    "Beekeeper in a 1997 film",
    "Beekeeper played by Peter Fonda",
    "Beekeeper of filmdom",
    "1997 Peter Fonda role",
    "Title role for a 1997 Oscar nominee",
    "Peter Fonda title role",
    "Fictional apiarist Jackson",
    "Big-screen beekeeper",
    "Title apiarist of a 1997 film",
    "Big-screen beekeeper",
    "Victor Nunez title hero",
    "\"Gold\" Fonda role",
    "1997 Peter Fonda title role",
    "Title apiarist of film",
    "Giant role for Peter Fonda, 1997",
    "1997 Peter Fonda title role",
    "Cinematic beekeeper",
    "Peter Fonda title role",
    "1997 title role for Peter Fonda",
    "Title beekeeper in a 1997 film",
    "1997 Peter Fonda role",
    "1997 Peter Fonda role",
    "Title beekeeper in a 1997 film",
    "Peter Fonda title role",
    "1997 Peter Fonda role",
    "Peter Fonda role",
    "Beekeeper in a 1997 film",
    "1997 Peter Fonda title role",
    "1997 Peter Fonda title role",
    "Beekeeper in a highly rated 1997 film",
    "1997 Peter Fonda role",
    "Peter Fonda played him in a 1997 film",
    "1997 title role for Peter Fonda",
    "Peter Fonda title role",
    "Peter Fonda title role",
    "Title role for Peter Fonda",
    "Title role for Peter Fonda",
    "1997 Fonda role",
    "1997 Fonda role",
    "Peter Fonda title role",
    "Peter Fonda title role",
    "1997 Peter Fonda title role",
    "1997 Peter Fonda title role",
    "1997 Fonda role",
    "Oscar-nominated Peter Fonda role",
    "1997 Oscar-nominated title role",
    "1997 Peter Fonda role",
    "1997 Peter Fonda title role",
    "1997 Peter Fonda title role",
    "1997 Peter Fonda title role"
  ],
  "agro": [
    "Farming prefix",
    "Prefix with industry",
    "Soil-related prefix",
    "Prefix with business",
    "Prefix with ecology or chemical",
    "Soil: Prefix",
    "Farm-related prefix",
    "Farming prefix",
    "Prefix with chemical",
    "Farming: Prefix",
    "Farming: Prefix",
    "Farming-related: Prefix",
    "Farming prefix",
    "Farming: Prefix",
    "Prefix with business",
    "Soil: Prefix",
    "Soil: Prefix",
    "Prefix with biology",
    "Soil: Prefix",
    "Farming prefix",
    "Prefix with industry",
    "Farming prefix",
    "Soil: Prefix",
    "Prefix with industry",
    "Prefix with industry",
    "Prefix with industry",
    "Prefix with biology",
    "Prefix with biology",
    "Farm prefix",
    "Soil: Prefix",
    "Soil: Prefix",
    "Soil: Prefix",
    "Field: Prefix",
    "Soil combiner",
    "Earthy prefix",
    "Farming prefix"
  ],
  "tune": [
    "Strain",
    "A singer can carry one",
    "Adjust, as a piano",
    "Ignore, with \"out\"",
    "Adjust the pitch of",
    "Something carried by a singer",
    "Something carried by a singer",
    "What a whistler whistles",
    "Adjust, as a piano",
    "Fix a flat for?",
    "Adjust, as a piano",
    "Something carried onstage?",
    "Prep before playing",
    "Adjust, as guitar strings",
    "Adjust the strings of, as a guitar",
    "Something to whistle",
    "Simple number",
    "Something whistled",
    "Whistler's production",
    "Earworm, e.g.",
    "It's carried at a karaoke bar",
    "Make less sharp, maybe",
    "Ensure that a G is actually a G, say",
    "Something to sing along to",
    "Get ready to play, say",
    "It's whistleable",
    "Often-carried thing",
    "Strain",
    "Whistler's whistle",
    "Thing to hum or whistle",
    "Whistler's whistle, maybe",
    "Something to whistle",
    "Some people have trouble carrying one",
    "Adjust the pitch of",
    "Ditty",
    "Lyricist's need",
    "Whistler's whistle",
    "Whistler's whistle",
    "Melody",
    "Organized notes",
    "It may be carried with a guitar",
    "Adjust",
    "Ditty",
    "Whistler's whistle",
    "Adjust, as a radio",
    "Pull a few strings?",
    "Whistler's whistle",
    "Jukebox choice",
    "Work to achieve harmony",
    "Harmonize",
    "It may be hard to carry",
    "Air",
    "Bring to proper pitch",
    "Fix a flat?",
    "The sound of music",
    "Air",
    "Whistler's whistle",
    "Bring into resonance",
    "It's hard for some people to carry",
    "Composer's output",
    "Ignore, with \"out\"",
    "Play \"My Dog Has Fleas\"",
    "Air",
    "Nightclub number",
    "Strain",
    "Melody",
    "Air",
    "Adjust",
    "___ out (ignore)"
  ],
  "moly": ["\"Holy ___!\"", "\"Holy ___!\"", "\"Holy ___!\"", "\"Holy ___!\""],
  "wuss": [
    "Get cold feet, with \"out\"",
    "Get cold feet, with \"out\"",
    "Become too scared, with \"out\"",
    "Big chicken",
    "Fraidy-cat",
    "Wimpy sort",
    "Scaredy-cat",
    "Scaredy-cat",
    "Pantywaist",
    "Pantywaist",
    "Milquetoast",
    "Weak one",
    "Weak one",
    "Hardly a he-man",
    "Weak one"
  ],
  "parc": [
    "Setting for a classic Georges Seurat painting, en francais",
    "Paris's ___ Monceau",
    "Setting for Seurat's \"La Grande Jatte\"",
    "Jardin du Luxembourg, par exemple",
    "Where les enfants might play",
    "Seurat painted in one",
    "Parisian picnic spot",
    "Setting of many a Monet painting",
    "___ zoologique (French zoo)",
    "Green piece of Paris",
    "Green stretch in Grenoble",
    "Paris picnic place",
    "Paris recreation area",
    "Rouen recreation spot",
    "Paris's ___ Monceau"
  ],
  "deut": [
    "Source of the line \"Man does not live by bread alone\": Abbr.",
    "Book after Num.",
    "Book after Num.",
    "Numbers follower: Abbr.",
    "Old Testament book: Abbr.",
    "Old Test. book"
  ],
  "hind": [
    "Back",
    "Posterior",
    "In the posterior",
    "Rear",
    "Rear",
    "Rearmost",
    "Backside",
    "Rear",
    "Rear",
    "Back",
    "Rear",
    "Kind of leg",
    "Rear",
    "Female deer",
    "Posterior",
    "Posterior",
    "Back",
    "Rear",
    "Posterior",
    "Posterior",
    "Posterior",
    "Back",
    "Buck's mate",
    "Posterior",
    "Posterior",
    "Posterior",
    "Kind of legs"
  ],
  "minx": [
    "Vixen",
    "Coquette",
    "Cheeky, flirtatious sort",
    "Flirt",
    "Coquette",
    "Coquette",
    "Flirtatious sort",
    "Flirt",
    "Coquette",
    "Coquette",
    "Hussy",
    "Impudent girl",
    "Pert lass"
  ],
  "trex": [
    "Threat bearing small arms?",
    "See 5-Down",
    "Sue at Chicago's Field Museum, e.g.",
    "Apex predator of old, informally",
    "Image in the \"Jurassic Park\" logo, informally",
    "Big dinosaur, for short",
    "Museum of natural history attraction, in brief",
    "Fearsome dino",
    "Fearsome dino",
    "Natural history museum attraction, for short",
    "Image in the \"Jurassic Park\" logo, familiarly",
    "Classic glam band named for an extinct creature",
    "Cloned menace of film",
    "Prehistoric terror, informally",
    "Extinct carnivore, familiarly",
    "Big-jawed dinosaur, for short",
    "Fearsome dino",
    "Creature in a \"King Kong\" fight",
    "\"The Lost World\" menace",
    "Fearsome display at a natural history museum",
    "\"Jurassic Park\" giant, informally",
    "Fearsome dino",
    "Bygone carnivore",
    "Fearsome dino",
    "Dino whose body was more than 30 feet long",
    "Terrifying dino",
    "*Natural history museum attraction",
    "Paleontological wonder at a natural history museum",
    "Attraction at Chicago's Field Museum",
    "Fearsome dino",
    "\"Jurassic Park\" terror, for short",
    "\"Jurassic Park\" menace",
    "Fearsome dino",
    "Attraction at Chicago's Field Museum",
    "Big-mouthed carnivorous dinosaur, for short",
    "Natural hist. museum attraction",
    "\"The Lost World\" menace, briefly",
    "Bygone terror, for short",
    "\"Jurassic Park\" menace, briefly",
    "\"Jurassic Park\" menace, briefly",
    "Prehistoric predator, for short",
    "Natural history museum display",
    "\"Jurassic Park\" beast, briefly",
    "Natural History Mus. display",
    "Prehistoric terror, informally",
    "\"Jurassic Park\" beast, for short",
    "\"Jurassic Park\" terror",
    "\"The Lost World\" menace",
    "Frightening dinosaur",
    "\"The Lost World\" menace",
    "Fearsome dinosaur, for short",
    "Big museum attraction",
    "\"Jurassic Park\" menace"
  ],
  "hama": [
    "Major city of west-central Syria",
    "Mie ___, actress who played the Bond girl Kissy Suzuki"
  ],
  "ecol": [
    "Environmentalist's subj.",
    "Earth Science subj.",
    "Natl. Audubon Society concern",
    "E.P.A. subject: Abbr.",
    "Paris accord subj.",
    "Climate change subj.",
    "Subj. of interest for the Green Party",
    "Biol. branch",
    "Environmental sci.",
    "Green sci.",
    "Earth sci.",
    "Green science: Abbr.",
    "Global warming subj.",
    "Green sci.",
    "Subj. for a forest ranger",
    "Green sci.",
    "Bionomics: Abbr.",
    "Environmental sci.",
    "Naturalist's subj.",
    "Environmental subj.",
    "Environmental sci.",
    "Life sci. course",
    "Greenpeace subj.",
    "Earth Day subj.",
    "Reforestation subj.",
    "Sci. specialty",
    "Green sci.",
    "Environmental sci.",
    "\"Green\" sci.",
    "Environmental sci.",
    "Earth Day subj.",
    "Environmental sci.",
    "Rachel Carson's sci.",
    "Kyoto Protocol concern: Abbr.",
    "Earth Day subj.",
    "Sierra Club concern: Abbr.",
    "Green's concern: Abbr.",
    "Life sci.",
    "\"Green\" sci.",
    "Earth Day subj.",
    "Sci. course",
    "E.P.A. subj.",
    "Area of E.P.A. purview: Abbr.",
    "Env. science",
    "Earth Day subj.",
    "Green subj.",
    "\"Silent Spring\" subj.",
    "Biol. branch",
    "E.P.A. concern: Abbr.",
    "E.P.A. subj.",
    "Park concern: Abbr.",
    "\"Earth in the Balance\" subj.",
    "Forester's concern: Abbr.",
    "Kyoto treaty subj.",
    "Earth Day subj.",
    "Tree hugger's subj.",
    "Biol. branch",
    "Life sci.",
    "E.P.A. concern: Abbr.",
    "Park ranger's study: Abbr.",
    "Earth sci.",
    "E.P.A. concern: Abbr.",
    "Environmental sci.",
    "Bionomics: Abbr.",
    "Modern sci. course",
    "Environmentalist's maj.",
    "E.P.A. concern: Abbr.",
    "E.P.A. concern: Abbr.",
    "Earth Day subj.",
    "E.P.A. concern: Abbr.",
    "Sierra Club concern: Abbr.",
    "Park director's concern: Abbr.",
    "Environmental sci.",
    "Sci. course",
    "Green concern: Abbr.",
    "Environmental study: Abbr.",
    "Greenpeace subj.",
    "Modern sci. course",
    "School subj. for environmentalists",
    "Environmentalism: Abbr."
  ],
  "mila": [
    "Actress Kunis",
    "Kunis of \"That '70s Show\"",
    "Kunis of \"Bad Moms\"",
    "Leon Uris's \"___ 18\"",
    "Kunis of \"Friends With Benefits\"",
    "Kunis of \"Black Swan\"",
    "___ 18 (title setting of a 1961 novel)",
    "Kunis of \"Black Swan\"",
    "Actress Kunis of \"That '70s Show\"",
    "\"___ 18\" (Leon Uris novel)",
    "\"___ 18\" (Leon Uris novel)",
    "\"___ 18\" (Uris book)",
    "Leon Uris's \"___ 18\"",
    "\"___ 18\" (Uris novel)",
    "\"___ 18\" (Leon Uris book)",
    "Uris's \"___ 18\"",
    "Uris's \"___ 18\"",
    "Leon Uris's \"___ 18\"",
    "Leon Uris's \"___ 18\"",
    "Uris's \"___ 18\"",
    "Country singer Mason",
    "Uris novel \"___ 18\"",
    "Uris's \"___18\"",
    "Uris's \"___18\""
  ],
  "coty": [
    "French-founded fragrance firm",
    "Cosmetics company whose letters appear in left-to-right order in \"cosmetics company\"",
    "Big name in French perfumes",
    "Chanel rival",
    "1950s French president Rene",
    "Big name in perfumery",
    "20th-century French leader",
    "President after Auriol",
    "De Gaulle's predecessor",
    "Mass-market fragrance maker",
    "Perfume brand",
    "Annual fashion award",
    "Perfume name",
    "Big maker of perfumes",
    "Name in fragrances",
    "Chanel competitor",
    "1950's French president Rene",
    "French president before De Gaulle",
    "De Gaulle's predecessor",
    "Perfumery name",
    "Cosmetics brand",
    "See 71 Across"
  ],
  "arap": [
    "Not give ___ (not care)",
    "Give ___ (have any interest)",
    "Not give ___",
    "Give ___ (care)",
    "Not give ___",
    "\"But hark! ___ comes gently to the door\": Robert Burns",
    "Amount not to care",
    "Not give ___",
    "Daniel ___ Moi (former Kenyan president)",
    "\"I don't give ___!\"",
    "\"I don't give ___!\"",
    "Not care ___",
    "Not give ___",
    "Give ___ (care)",
    "Not care ___",
    "Kenya's Daniel ___ Moi",
    "Not give ___",
    "Not give ___",
    "Not give ___"
  ],
  "nasl": [
    "Org. for which Pele once played",
    "Org. for the New York Cosmos",
    "Pele's org.",
    "Chicago Sting's org.",
    "Pele was its M.V.P. in '76",
    "New York Cosmos' sports org.",
    "Old sports org. featuring the Minnesota Kicks",
    "Pele's org., once",
    "Pele's org.",
    "Pele once played in it: Abbr.",
    "Onetime athletic org.",
    "Org. for Pele, once"
  ],
  "yann": ["\"Life of Pi\" author Martel"],
  "icus": [
    "Some \"Grey's Anatomy\" settings, for short",
    "Places for post-op patients",
    "Hosp. areas",
    "Where cc's may be delivered",
    "Hosp. areas",
    "Heavily monitored areas: Abbr.",
    "Critical hosp. areas",
    "Crit. condition areas",
    "Closely monitored hosp. areas",
    "Hosp. areas",
    "People are closely watched in them: Abbr.",
    "Critical hosp. areas",
    "Places for masks, in brief"
  ],
  "monk": [
    "Title whose name comes from the Greek for \"alone\"",
    "Brother in an order",
    "Father figure?",
    "Man in the hood?",
    "Person with few possessions",
    "\"Monty Python and the Holy Grail\" figure",
    "Man in a robe",
    "Figure in Umberto Eco's \"The Name of the Rose\"",
    "Neurotic TV detective played by Tony Shalhoub",
    "Member of an order",
    "Brother",
    "One in a hood",
    "Brother",
    "Lama, e.g.",
    "Saint Boniface, e.g.",
    "Person with vows",
    "Brother, perhaps",
    "One of Chaucer's travelers"
  ],
  "nacl": [
    "About .4% of the weight of the human body",
    "Seawater compound",
    "It dissolves in H2O",
    "Deicer formula",
    "It reacts with H2SO4",
    "Salt, chemically",
    "Deicing formula",
    "Table salt, to a chemist",
    "Salt, chemically",
    "What a chemist brings to the table?",
    "Chemical compound often labeled \"S\"",
    "Salt, chemically",
    "Curing stuff, symbolically",
    "Crystal on the dinner table?",
    "Table salt, chemically",
    "Common crystals, chemically",
    "Salt, symbolically",
    "Table salt, chemically",
    "Halite, chemically",
    "Shaker formula",
    "Formula for \"S\"",
    "Shaker formula",
    "Chemist's salt",
    "Table salt, to a chemist",
    "Table salt formula",
    "It dissolves in H2O",
    "Salt",
    "Table salt, to a chemist",
    "Salt, to a chemist",
    "Table salt, symbolically",
    "Table salt",
    "Part of seawater",
    "Salt",
    "Chemist's condiment",
    "Frequent H2O accompanier",
    "Salty letters",
    "Ocean compound",
    "Salt",
    "Table salt",
    "Table salt"
  ],
  "bmws": [
    "Z4 and i3",
    "Some expensive imports",
    "German autos",
    "Rivals of Audis",
    "6 Series cars",
    "Gran Turismos and others",
    "Autobahn sights",
    "507s of the '50s, e.g.",
    "Upscale autos",
    "Jaguar alternatives",
    "Mercedes competitors",
    "Some luxury cars",
    "Autobahn autos"
  ],
  "mani": [
    "Spa job, informally",
    "___-pedi",
    "Salon job, informally",
    "___-pedi",
    "___-pedi",
    "Gershwin's \"The ___ Love\"",
    "Salon job, for short",
    "Gershwin's \"The ___ Love\"",
    "\"The ___ Love\" (Gershwin song)",
    "Gershwin's \"The ___ Love\"",
    "Gershwin's \"The ___ Love\"",
    "\"The ___ Love\" (Gershwin tune)",
    "\"The ___ Love\"",
    "\"The ___ Love\" (Gershwin tune)"
  ],
  "winn": [
    "\"Because of ___-Dixie\" (2000 award-winning children's book)",
    "___-Dixie (grocery chain)"
  ],
  "cape": [
    "Dracula accessory",
    "Attire that flaps in the wind",
    "Ranch alternative",
    "Back cover?",
    "Rhinestone-covered appurtenance for Elvis",
    "Hurricane-prone area, maybe",
    "With 48-Across, East Coast vacation spot",
    "Superhero accessory",
    "Elvis impersonator's accessory",
    "Where the Pilgrims first landed in the New World",
    "___ Verde Islands",
    "Northeast vacation locale, with \"the\"",
    "South Africa has a famous one",
    "___ Canaveral",
    "Superman costume part",
    "Beach site, maybe",
    "Headless Horseman's wear",
    "Massachusetts getaway, with \"the\"",
    "New Jersey's ___ May",
    "Corrida wear",
    "Massachusetts vacation spot, with \"the\"",
    "Part of Batman's ensemble",
    "Bullfighter's cloth",
    "Back cover",
    "With 26-Across, Massachusetts resort area",
    "Where to watch whales in Massachusetts, with \"the\"",
    "Bull baiter",
    "Sleeveless garment",
    "Zorro garb",
    "___ Horn",
    "Massachusetts vacation spot, with \"the\"",
    "With 118-Down, a Massachusetts locale",
    "Attire for the Headless Horseman",
    "Superman accessory",
    "2-Down's accessory",
    "___ Canaveral",
    "Superhero accessory",
    "May in New Jersey, e.g.",
    "Superhero accessory",
    "Bull's target",
    "Supergarb",
    "Supergarb",
    "Vacation spot",
    "Fear is one",
    "Garb for Superman",
    "Superman attire",
    "Seaside setting",
    "Garment for Batman",
    "Attire for Dracula",
    "Accessory for Robin",
    "___ Hatteras, N.C.",
    "Horn, for one",
    "Part of The Shadow's attire"
  ],
  "rver": [
    "Camper driver",
    "KOA customer",
    "Winnebago owner, briefly",
    "One with a home away from home",
    "Winnebago owner, briefly",
    "Winnebago driver, informally",
    "Camper driver, for short",
    "Mobile camper, informally",
    "Cross-country camper, for short",
    "KOA campground user",
    "Camper driver",
    "Many a camper, informally",
    "Winnebago owner",
    "Campsite hookup user"
  ],
  "adog": [
    "\"On the internet, nobody knows you're ___\" (classic New Yorker cartoon caption)",
    "Sick as ___",
    "Work like ___",
    "Sick as ___",
    "Work like ___",
    "Work like ___",
    "\"My Life as ___\" (1985 film)",
    "Work like ___",
    "Ouida's \"___ of Flanders\"",
    "Ouida's \"___ of Flanders\"",
    "Work like ___",
    "Sick as ___",
    "Sick as ___",
    "\"My Life as ___\" (1985 film)",
    "1985 film \"My Life as ___\""
  ],
  "kotb": ["Hoda of morning TV"],
  "vidi": [
    "\"Veni, ___, vici\"",
    "Middle of a Latin trio",
    "\"Veni, ___, vici\"",
    "Middle of a famous boast",
    "Part of Caesar's boast",
    "Part of a Caesarean trio",
    "\"Veni, ___, vici\"",
    "Part of Caesar's boast",
    "Middle of Caesar's boast",
    "Middle of a famous boast",
    "\"Veni, ___, vici\"",
    "\"I saw,\" Caesar-style",
    "Second of a Latin trio"
  ],
  "levy": [
    "Impose, as a tax",
    "Impose, as a tax",
    "Impose, as a tax",
    "Impose",
    "Charge",
    "Tax",
    "It's an imposition",
    "Impose, as a tax",
    "Charge",
    "Impose, as a tax",
    "Assess",
    "Charge",
    "Impose, as a tax",
    "Impose",
    "Impose",
    "Tax",
    "Impose, as taxes",
    "Tax",
    "Duty",
    "Impose",
    "Tax"
  ],
  "dems": [
    "Pols with a donkey party logo",
    "Left side, informally",
    "Pols like Pelosi",
    "Pelosi and Schumer, informally",
    "Blue side, for short",
    "Blue state majority, for short",
    "Ones in the 49-Across voting bloc, for short",
    "Bush whackers?",
    "4-Down's grp.",
    "House party members, briefly",
    "2012 Charlotte conventioneers: Abbr.",
    "Certain party, in headlines",
    "House party, briefly",
    "Majority of U.S. governors, as of 2009: Abbr.",
    "G.O.P. rivals",
    "Politicos with a donkey symbol",
    "Clinton followers, for short",
    "G.O.P. foes",
    "2004 Boston conventioneers, informally",
    "Reps.' foes",
    "One side of the Senate, informally",
    "F.D.R. and L.B.J., e.g.",
    "G.O.P.'s rivals",
    "G.O.P. opposers",
    "Gore's grp.",
    "Reps.' opponents",
    "G.O.P. opponents",
    "Whom Reps. run against",
    "Reps.' counterparts"
  ],
  "nimh": [
    "\"Mrs. Frisby and the Rats of ___\" (Robert C. O'Brien children's book)",
    "\"The Secret of ___\" (1982 animated film)",
    "\"The Secret of ___\" (1982 film)",
    "\"The Secret of ___\" (1982 animated film)"
  ],
  "orch": [
    "Theater sect. near the stage",
    "Philharmonic grp.",
    "N.Y. Philharmonic, e.g.",
    "Philharmonic grp.",
    "Seating sect.",
    "Seating sect.",
    "Its players know the score: Abbr.",
    "Grp. of players",
    "Symphony performer: Abbr.",
    "Mezz. alternative",
    "Mus. ensemble",
    "Stringed grp.",
    "Section under the mezz.",
    "Mezz. alternative"
  ],
  "effs": [
    "Failing grades",
    "Symbols of failure",
    "Lots of fluff?",
    "Letters before gees",
    "Bad grades",
    "Pair of buffalo?",
    "Pair of ruffians?",
    "Bad grades",
    "They keep you from passing",
    "Sign of poor schoolwork",
    "Failing marks",
    "Flunking marks",
    "This puzzle's surplus",
    "Rotten grades",
    "They can make a suer suffer",
    "Two of fifty?",
    "Report card woes"
  ],
  "loom": [
    "Weaving machine",
    "Impend",
    "Weaving machine",
    "Weaver's device",
    "Aid in tapestry-making",
    "Weaver's device",
    "Machine with a treadle",
    "Item smashed by the original Luddites",
    "Portend",
    "Weaving machine",
    "Threaten",
    "Impend",
    "Be impending",
    "Seem forthcoming",
    "Hang (over)",
    "Weaver's apparatus",
    "Tapestry-making device",
    "Machine worked in \"Norma Rae\"",
    "Rugmaking apparatus",
    "Tower",
    "Hang over",
    "Be on the horizon",
    "Machine with a shuttle",
    "Tapestry device",
    "Arachne had one",
    "Appear ominously",
    "Tower",
    "1785 invention of England's Edmund Cartwright",
    "Hang over",
    "It has a warp",
    "Place for a warp and a heddle",
    "Hang over one's head",
    "Impend",
    "It generates material",
    "Weaving machine",
    "Warp-and-weft machine",
    "Wool gatherer?",
    "Weaving machine",
    "Weaving machine",
    "Shuttle site",
    "Shuttle site",
    "Power ___, invention of Edmund Cartwright, ca. 1785",
    "Tower",
    "Weaver's apparatus",
    "Hang over one's head",
    "Apparatus with pedals",
    "Hang over one's head",
    "Come into view",
    "Come into view",
    "Shuttle site",
    "Edmund Cartwright's power invention, 1785",
    "Weaver?Æs equipment",
    "The art of weaving",
    "Weaver's apparatus",
    "Appear ahead",
    "Weaving machine",
    "Appear",
    "Weaving machine",
    "Weaver's apparatus",
    "Weaving machine",
    "Appear",
    "Appear ahead"
  ],
  "gamy": [
    "Like venison that's been sitting awhile",
    "Like wild boar",
    "No longer fresh",
    "Slightly tainted",
    "Like venison",
    "Slightly tainted",
    "Risque",
    "Like venison"
  ],
  "orme": [
    "\"Your choice - him ___\"",
    "\"Her ___\" (\"Miss Saigon\" song)",
    "\"Coffee, Tea ___?\" (1960s best seller)",
    "\"Coffee, Tea ___?\"",
    "\"... a man no mightier than thyself ___\": \"Julius Caesar\"",
    "\"It's either you ___\"",
    "\"Coffee, Tea ___?\" (1960s best seller)",
    "\"Coffee, Tea ___?\"",
    "\"Coffee, Tea ___?\"",
    "\"Her ___\" (\"Miss Saigon\" song)",
    "\"It's you ___\"",
    "\"Coffee, Tea ___?\" (1960's best seller)",
    "\"Coffee, Tea ___\" (1960's best seller)",
    "\"Her ___\" (song from \"Miss Saigon\")",
    "\"It's either you ___\"",
    "\"Coffee, Tea ___?\""
  ],
  "sdak": [
    "Home of Mount Rushmore: Abbr.",
    "Badlands locale: Abbr.",
    "Mt. Rushmore's state: Abbr.",
    "Lake Oahe locale: Abbr.",
    "Neb. neighbor",
    "Where I-90 and I-29 cross: Abbr.",
    "Mt. Rushmore's home: Abbr.",
    "Home of Wind Cave Natl. Park",
    "Home to Ellsworth A.F.B.",
    "Its state sport is rodeo: Abbr.",
    "Only U.S. state in which the name of the state and its capital share no letters: Abbr.",
    "It's bisected by the Missouri R.",
    "Pierre is there: Abbr.",
    "Pierre's state: Abbr.",
    "Badlands locale: Abbr.",
    "Wounded Knee's locale: Abbr.",
    "Neb. neighbor",
    "Its motto is \"Under God, the people rule\": Abbr.",
    "Neb. neighbor",
    "Home of Rapid City: Abbr.",
    "Mt. Rushmore's locale: Abbr.",
    "Where Wounded Knee is: Abbr.",
    "Mount Rushmore's state: Abbr.",
    "Neb. neighbor",
    "Mt. Rushmore's place: Abbr.",
    "Badlands Natl. Park's state",
    "Mount Rushmore locale: Abbr.",
    "Admission of 1889: Abbr.",
    "Badlands state: Abbr.",
    "Wind Cave Natl. Park locale",
    "Neb. neighbor",
    "Nebr. neighbor",
    "Badlands Natl. Park locale",
    "Minn. neighbor",
    "Mont. neighbor",
    "Minn. neighbor",
    "Pierre's state: Abbr.",
    "Badlands Natl. Park locale",
    "Wounded Knee locale: Abbr.",
    "Mt. Rushmore's state: Abbr.",
    "Neighbor of Minn.",
    "Mount Rushmore's site: Abbr.",
    "Mt. Rushmore site",
    "Neb. neighbor",
    "Neb. neighbor",
    "Neighbor of 16-Down: Abbr.",
    "It's north of Neb."
  ],
  "inla": [
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\" (1985 film)",
    "\"To Live and Die ___\" (1985 action thriller)",
    "\"To Live and Die ___\"",
    "Visiting the Getty, e.g.",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "Where to see 20th Century Fox studios",
    "On Wilshire Blvd., say",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "Where a Laker may live",
    "\"To Live and Die ___\"",
    "Where I-5 crosses I-10",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "On the Coast, maybe",
    "1985 film \"To Live and Die ___\"",
    "\"To Live and Die ___\" (film)",
    "\"To Live and Die ___\" ('85 film)",
    "1985 movie \"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\"",
    "\"To Live and Die ___\""
  ],
  "onym": ["Ending with pseud- or syn-", "Ending with syn- or ant-"],
  "tiro": [
    "Neophyte: Var.",
    "Beginner: Var.",
    "Beginner: Var.",
    "Cicero's longtime servant and scribe",
    "Newbie: Var.",
    "Rookie: Var.",
    "Cicero's servant and secretary",
    "Newbie: Var.",
    "Beginner: Var.",
    "Newbie: Var.",
    "Beginner: Var.",
    "Rookie: Var.",
    "Novice: Var.",
    "Novice: Var.",
    "Novice",
    "Neophyte: Var."
  ],
  "myna": [
    "Member of the starling family",
    "Avian mimic",
    "Flier from Asia",
    "One often saying \"hello,\" maybe",
    "Chatty bird",
    "Avian mimic",
    "Talkative bird",
    "Mimicking bird",
    "Repetitive sort",
    "Talkative one",
    "Avian talker",
    "Talker with a beak",
    "Mimic",
    "Talkative starling",
    "Repeater?",
    "Talkative bird",
    "Popular pet bird",
    "Mimicking bird",
    "Gabby bird"
  ],
  "muon": [
    "Unstable subatomic particle",
    "Unstable subatomic particle",
    "Subatomic particle more massive than an electron",
    "Unstable particle",
    "Unstable particle",
    "Elementary particle",
    "Particle created by a cosmic ray",
    "Atomic particle",
    "Unstable lepton",
    "Unstable particle",
    "Unstable particle",
    "Elementary particle",
    "Physics particle"
  ],
  "quay": [
    "Landing spot",
    "Berth place",
    "Platform for loading ships",
    "Where ships get loaded",
    "Jetty",
    "Unloading point",
    "Pier",
    "Shoreline structure",
    "Landing place",
    "Unloading site",
    "Place to dock",
    "Waterfront site",
    "Dock",
    "Wharf",
    "Jetty",
    "Jetty",
    "Wharf",
    "Wharf",
    "Landing",
    "Landing",
    "Wharf",
    "Jetty",
    "Wharf"
  ],
  "kepi": [
    "Hat worn by Charles de Gaulle",
    "French military hat",
    "Foreign Legion hat",
    "58-Across topper",
    "Gendarme's topper",
    "French army headwear",
    "Military hat",
    "Foreign Legion hat",
    "Zouave headgear",
    "\"Beau Geste\" headgear",
    "Hat worn in \"Casablanca\"",
    "Topper for Charles de Gaulle",
    "Hat for a French soldier",
    "Visored cap",
    "Visored cap",
    "French military cap"
  ],
  "hana": [
    "Tennis champ Mandlikova",
    "Mandlikova of tennis",
    "Tennis champ Mandlikova",
    "Mandlikova of tennis",
    "Tennis star Mandlikova",
    "Tennis's Mandlikova",
    "Tennis's Mandlikova",
    "Tennis champ Mandlikova",
    "Mandlikova of tennis",
    "Mandlikova of tennis",
    "Easternmost town on Maui, on one end of 52 miles of twisty highway",
    "Mandlikova of tennis fame",
    "Tennis star Mandlikova",
    "Tennis star Mandlikova",
    "Mandlikova of tennis",
    "Mandlikova of tennis",
    "Mandlikova of tennis",
    "Mandlikova of tennis",
    "Tennis star Mandlikova",
    "Tennis's Mandlikova",
    "Tennis ace Mandlikova",
    "Tennis's Mandlikova",
    "Tennis's Mandlikova",
    "Mandlikova of tennis",
    "Mandlikova of tennis",
    "Tennis star Mandlikova",
    "Mandlikova of tennis",
    "Tennis star Mandlikova",
    "Czech tennis ace Mandlikova",
    "Tennis star Mandlikova",
    "Mandlikova of tennis",
    "First name in tennis",
    "Tennis's Mandlikova",
    "Tennis champ Mandilikova",
    "Rival of Martina"
  ],
  "mash": [
    "Pulp",
    "TV show with three stars",
    "Moonshine maker's need",
    "Button-___ (hit everything at once, in gamer lingo)",
    "Turn to pulp",
    "Reduce to a pulp",
    "Pound, as potatoes",
    "1968 novel set in Korea",
    "\"Monster ___\" (1962 #1 novelty hit)",
    "Hit TV show set in Korea",
    "Sitcom with three stars",
    "Reduce to a pulp",
    "Squoosh",
    "Kind of note",
    "Turn into pulp",
    "Pulp",
    "Prepare potatoes, in a way",
    "Moonshine mix",
    "Whiskey fermenter",
    "Alan Alda series",
    "Radar unit?",
    "Livestock feed",
    "Beat to a pulp",
    "Flirt, in old slang",
    "Pulp",
    "Livestock feed",
    "Reduce to a pulp",
    "TV series with Hawkeye and Hot Lips",
    "Not handle gently",
    "Hawkeye's show",
    "Moonshiner's mixture",
    "Squush",
    "Sitcom set in Korea",
    "Radar unit?",
    "Beat to a pulp",
    "Trapper John's post",
    "Potato dish, in British slang",
    "Pound",
    "British potato dish, informally",
    "Wartime acronym",
    "Premiere of 9/17/72",
    "Sitcom set in Korea",
    "Beer-brewing mixture",
    "Cattle feed",
    "Beat to a pulp",
    "Moonshine mix",
    "Pulverize",
    "Farm feed",
    "Flirt",
    "Movie-spinoff TV series",
    "Pound",
    "Moonshine-to-be"
  ],
  "whip": [
    "Legislature V.I.P.",
    "First object made by humans to break the sound barrier",
    "Congressional vote wrangler",
    "Blender button",
    "Trounce",
    "Carriage driver's tool",
    "Lash",
    "It's most useful when it's cracked",
    "Party leader",
    "Senate figure",
    "Senate V.I.P.",
    "Horse trainer's aid",
    "Party manager, in Congress",
    "Kind of stitch",
    "It's most useful when it's cracked"
  ],
  "baer": [
    "1930s boxing champ Max",
    "Boxer upset in the biopic \"Cinderella Man\"",
    "Boxer Max",
    "Old newspaper humorist Arthur \"Bugs\" ___",
    "1930s heavyweight champ",
    "Ring champ Max",
    "Heavyweight champ after Carnera",
    "1930s boxing champ Max",
    "1930s boxing champ Max",
    "1930s ring champ",
    "Max of the ring",
    "1930s heavyweight champ Max",
    "Defeater of Schmeling in 1933",
    "1930s heavyweight champ",
    "Max of \"The Beverly Hillbillies\"",
    "Entertainer Max or Max Jr.",
    "1930's boxing champ Max",
    "Champ who lost his title to Braddock",
    "Heavyweight champ before Braddock",
    "Boxer Max",
    "1930's boxing champ",
    "1930's boxer Max",
    "1930's boxing champ Max",
    "30's boxing champ Max",
    "1930's heavyweight champ Max",
    "Heavyweight champ of 1934",
    "Braddock took away his title",
    "Braddock beat him to become heavyweight champ",
    "Boxer Max",
    "30's boxing champ Max",
    "Heavyweight champ after Carnera",
    "Boxer Max",
    "1930's heavyweight champ Max",
    "Loser to Braddock, 1935",
    "Heavyweight champ dethroned by Braddock",
    "He KO'd Carnera, 6/14/34",
    "Boxer Max",
    "Old-time journalist Bugs",
    "Boxer Max",
    "Max or Buddy"
  ],
  "situ": [
    "In ___ (as found)",
    "In ___ (untouched)",
    "In ___ (as found)",
    "Undisturbed, after \"in\"",
    "The \"s\" of Lasik",
    "Archaeologists often find what they're looking for in this",
    "In ___ (undisturbed)",
    "Nero's position?",
    "In ___ (archaeologist's phrase)",
    "In ___ (as placed)",
    "In ___ (naturally positioned)",
    "In ___ (as found)",
    "In ___ (as found)",
    "In ___ (undisturbed)",
    "In ___ (as found)",
    "In ___ (unmoved)",
    "Archaeologists usually find things in this",
    "In ___ (as placed)",
    "In ___ (as originally located)",
    "In ___ (as found)",
    "In ___ (as found)",
    "As originally placed, after \"in\"",
    "In ___ (positioned naturally)",
    "In ___ (as found)",
    "In ___ (where found)",
    "As found, after \"in\"",
    "In ___ (where found)",
    "In ___ (as placed)",
    "Ex ___ (not in its original habitat)",
    "In ___ (as found)",
    "In ___ (undisturbed)",
    "In ___ (undisturbed)",
    "Caesar's place",
    "In ___ (undisturbed)",
    "In ___ (as found)",
    "In ___ (not yet moved)",
    "In ___ (undisturbed)",
    "In ___ (properly placed)",
    "In ___ (as originally positioned)",
    "In___(naturally positioned)"
  ],
  "driq": [
    "Title host of radio's first major quiz show",
    "Popular 1940's radio quiz show",
    "Popular 1940's radio quiz show",
    "Old radio quiz show"
  ],
  "renu": [
    "Contact lens care brand",
    "Contact lens care brand",
    "Brand in contact lens care",
    "Lens care brand",
    "Solution for poor eyesight?",
    "Contact lens cleaner brand",
    "Lens solution brand",
    "Contact lens care brand",
    "Big name in lens care",
    "Brand in contact lens care",
    "Noteworthy name in lens care",
    "Bausch & Lomb lens-care product",
    "Contact lens solution brand"
  ],
  "anil": [
    "\"Slumdog Millionaire\" co-star ___ Kapoor",
    "Dark blue",
    "Deep blue dye",
    "Blue dye",
    "___ Kapoor, \"Slumdog Millionaire\" actor",
    "Deep blue dye",
    "Indigo source",
    "Indigo dye source",
    "Component of the pigment Maya blue",
    "Source of indigo",
    "Deep blue dye",
    "Indigo source",
    "Dye containing indigotin",
    "Blue dye source",
    "Blue hue",
    "What makes blue jeans blue",
    "Blue dye source",
    "Dye-yielding shrub",
    "Indigo dye",
    "Indigo source",
    "Deep blue",
    "Indigo plant",
    "Source of indigo",
    "Shade darker than azure",
    "Blue shade",
    "Plant in the pea family",
    "Shrub used in dyeing",
    "Source of indigo dye",
    "Navy relative",
    "Indigo dye source",
    "Dye plant",
    "Blue shade",
    "Dye plant",
    "Shrub of the genus Indigofera",
    "Dye-yielding plant",
    "Blue dye",
    "Indigo plant",
    "Indigo dye source",
    "Indigo plant",
    "Dye-yielding plant",
    "Indigo source",
    "Blue shade",
    "Indigo dye source",
    "Blue shade",
    "Indigo dye source",
    "Deep blue",
    "Indigo",
    "Shrub that yields indigo",
    "Deep blue",
    "Shade of blue",
    "Deep blue",
    "Indigo dye source",
    "Dye source",
    "It's to dye with",
    "Dye-producing shrub",
    "Deep blue",
    "Indigo dye",
    "Deep blue",
    "Indigo source",
    "Dyeing plant",
    "Indigo plant",
    "Blue dye",
    "Blue dye",
    "A dye",
    "Dye-yielding plant",
    "Shade of blue",
    "Deep blue",
    "Dye color appropriate to this puzzle",
    "Deep blue"
  ],
  "pouf": [
    "High hairstyle",
    "High style of the 1700s",
    "Hairdo for Snooki of \"Jersey Shore\"",
    "Rounded ottoman"
  ],
  "ouch": [
    "Facetious response to a verbal jab",
    "Reaction to pain",
    "\"That's a good burn!\"",
    "\"That's gotta hurt!\"",
    "\"That hurts!\"",
    "\"That smarts!\"",
    "Response to a burn or a pun",
    "\"That hurts!\"",
    "\"That hurt!\"",
    "Response to a bad pun",
    "\"You got me\"",
    "Stinging reply?",
    "Punch line?",
    "Reaction to a pun or a punch",
    "Response to an insult",
    "Response to a shot, maybe",
    "\"That hurt!\"",
    "Comment upon receiving a large bill",
    "\"That hurt!\"",
    "\"Yow!\"",
    "Response to a bad pun",
    "\"Yow!\"",
    "Punch response",
    "Smart comment?",
    "Word said with a grimace",
    "Toe stubber's cry",
    "Response to many a punch line",
    "Beekeeper's exclamation?",
    "\"That hurts!\"",
    "\"That hurts!\"",
    "\"That hurt!\"",
    "\"That hurts!\"",
    "\"That smarts!\"",
    "Reaction to a pinch",
    "Response to a jerk?",
    "\"That hurts!\"",
    "Smart remark?",
    "Cry of dismay",
    "Pinch reaction"
  ],
  "oooo": [
    "New pedometer reading",
    "Ghost's sound",
    "Is behind",
    "Reset number"
  ],
  "loni": [
    "Actress Anderson of old TV",
    "Actress Anderson",
    "Comedian Love who co-hosts \"The Real\"",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson who was once married to 21-Down",
    "Anderson of \"WKRP\"",
    "Actress Anderson",
    "Anderson of sitcomdom",
    "Anderson of \"Nurses\"",
    "Actress Anderson",
    "Anderson of \"WKRP in Cincinnati\"",
    "Actress Anderson",
    "Blonde Anderson",
    "Actress Anderson",
    "Anderson who wrote \"My Life in High Heels\"",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "1970s pinup name",
    "TV's Anderson",
    "Anderson of \"WKRP in Cincinnati\"",
    "Anderson of \"WKRP in Cincinnati\"",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Anderson of \"WKRP in Cincinnati\"",
    "Anderson of \"Stroker Ace\"",
    "Burt's \"Stroker Ace\" co-star",
    "Actress Anderson",
    "\"WKRP\" actress Anderson",
    "Anderson of \"WKRP in Cincinnati\"",
    "TV's Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Anderson of TV",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Burt's ex",
    "Burt's ex",
    "Actress Anderson",
    "Burt's ex",
    "Portrayer of WKRP's Jennifer",
    "She was WKRP's Jennifer",
    "Anderson of sitcoms",
    "She was Jennifer on \"WKRP\"",
    "Actress Anderson",
    "Actress Anderson",
    "Actress Anderson",
    "Burt's ex",
    "Burt's ex",
    "Actress Anderson",
    "Anderson of TV sitcoms",
    "Burl's ex",
    "Ex-Mrs. Burt Reynolds"
  ],
  "oner": [
    "Remarkable person",
    "Unique individual",
    "Extraordinary thing, in slang",
    "Long, single take, in filmmaking",
    "Lollapalooza",
    "Long, unbroken take, in film lingo",
    "British pound, informally",
    "Doozy",
    "Standout",
    "Nonpareil",
    "Doozy",
    "Embarassing spelling mistake?",
    "Dandy",
    "Lollapalooza",
    "Lulu",
    "Humdinger",
    "Knockout",
    "Corker",
    "Lollapalooza",
    "Lulu",
    "Standout",
    "There's nothing like it",
    "Lollapalooza",
    "Humdinger",
    "Lollapalooza",
    "Humdinger",
    "Humdinger",
    "Lulu",
    "Lollapalooza",
    "Unique person",
    "Lulu",
    "Real lulu",
    "Humdinger",
    "Lollapalooza",
    "Lulu",
    "Unique individual",
    "Singular person",
    "Not just a rarity",
    "Lulu",
    "Unique person, informally",
    "Humdinger",
    "Lulu",
    "Lulu",
    "Lollapalooza",
    "Dilly",
    "Lollapalooza",
    "Standout",
    "Unique person",
    "Doozy",
    "Important spelling feature of \"iridescent\"",
    "Dilly",
    "It has no comparison",
    "Doozy",
    "Rara avis",
    "Doozy",
    "Odd cat",
    "A standout for sure",
    "Singular fellow",
    "Lulu",
    "Humdinger",
    "Lollapalooza",
    "Unique person",
    "Lulu",
    "Pip",
    "Quite a rarity",
    "Pip",
    "It's unique",
    "Lollapalooza",
    "Standout",
    "Critical spelling feature of \"Caribbean\"",
    "Lulu",
    "Odd happenstance",
    "Standout",
    "Humdinger",
    "Marvel",
    "Humdinger",
    "Knockout",
    "Dilly",
    "Nonpareil",
    "Pip",
    "Nonesuch",
    "It's unique",
    "Jim-dandy",
    "Lulu",
    "Rara avis",
    "It's unique",
    "Lollapalooza",
    "Singular person",
    "Nonpareil",
    "A lulu",
    "Singular person",
    "It's not a dime a dozen"
  ],
  "eink": [
    "Text-displaying technology for Kindles and Nooks",
    "Text-displaying technology on Kindles"
  ],
  "fret": [
    "Something found on a neck",
    "Lose sleep (over)",
    "Guitar part",
    "Lose sleep, so to speak",
    "Stew",
    "Agonize (over)",
    "Fuss",
    "Worry",
    "Be in a dither",
    "Worry",
    "Brood",
    "Worry",
    "Worry",
    "Part of the neck?",
    "Stress (over)",
    "Neck ridge",
    "Get in a stew",
    "Neckline?",
    "Worry",
    "Part of a long neck",
    "Stew",
    "Worry",
    "Stew",
    "Say \"What to do? What to do?,\" e.g.",
    "Not rest easy",
    "Neck piece",
    "Stew",
    "Stew (over)",
    "Be in a stew",
    "Feature of some necks",
    "Get in a stew",
    "Worry (about)",
    "Fingerboard ridge",
    "Fingerboard part",
    "Fingerboard ridge",
    "Stew",
    "Guitar player's guide",
    "Become vexed",
    "Ridge on a guitar neck",
    "Stay awake nights",
    "Fingerboard ridge",
    "Guitarist's worry?",
    "Sweat bullets",
    "Worry",
    "Stew",
    "Worry",
    "Stew",
    "Not be calm",
    "Not have peace of mind",
    "Stew",
    "Fuss",
    "Lose sleep (over)",
    "Guitar part",
    "Mandolin feature",
    "Worry",
    "Stew",
    "Stew",
    "Agonize",
    "Ukulele feature",
    "Stew",
    "Stew",
    "Lute feature",
    "Stew",
    "Stew",
    "Toss and turn"
  ],
  "mein": [
    "German possessive",
    "Lo ___ (Chinese noodle dish)",
    "Lo ___",
    "Lo ___",
    "Chow ___",
    "Half of a noodle dish?",
    "Lo ___",
    "Chow ___",
    "Words after count or clue",
    "Lo ___",
    "Words after count or let",
    "Words after let or count",
    "Chow ___",
    "\"Count ___!\"",
    "\"___ Kampf\"",
    "Chow ___",
    "\"___ Kampf\"",
    "\"Count ___\"",
    "Lo ___ (noodle dish)",
    "\"___ Herr\" (\"Cabaret\" tune)",
    "Words after deal or count",
    "\"Count ___\"",
    "\"Deal ___!\"",
    "Chow ___",
    "Chow___",
    "\"Don't Fence___\"",
    "\"Count___!\"",
    "\"Count ___!\"",
    "Chow ___",
    "Chow ___"
  ],
  "pomp": [
    "Fanfare",
    "Ostentatious display",
    "Circumstance's partner",
    "Festival display",
    "\"___ and Circumstance\"",
    "\"___ and Circumstance\"",
    "Grandiosity",
    "Partner of circumstance",
    "Spectacle",
    "Feature of a royal event",
    "Splendor",
    "Circumstance's partner",
    "\"___ and Circumstance\"",
    "Pageantry",
    "Pageantry",
    "Ceremonial splendor",
    "Ceremonial splendor",
    "Partner of circumstance",
    "Magnificent display",
    "Magnificence",
    "Regal showing",
    "Splendor",
    "Splendor",
    "Grand display",
    "\"___and Circumstance\"",
    "Splendor",
    "Magnificence"
  ],
  "know": [
    "Apt radio station to air \"Wait Wait ... Don't Tell Me!\" (and it does!)",
    "Not just think",
    "\"False face must hide what the false heart doth ___\": Macbeth",
    "Have the answers",
    "Have in mind?",
    "Minneapolis radio station that carries a lot of news, appropriately",
    "See 50-Down",
    "Not just surmise",
    "Be aware of",
    "Have down",
    "Be certain about",
    "In the ___",
    "Have in one's head",
    "Have down cold",
    "Have down pat",
    "Have no doubts",
    "\"___ what?\"",
    "\"Well, what do you ___?!\"",
    "More than suspect",
    "Be wise to",
    "Be sure",
    "Be positive about",
    "Feel sure about",
    "Be sure of",
    "Have memorized",
    "Recognize",
    "Feel in one's bones",
    "Comprehend",
    "Have memorized",
    "Be close to"
  ],
  "thug": [
    "Mafia enforcer, e.g.",
    "Gangster",
    "Tough",
    "Tough",
    "Tough guy",
    "Hoodlum",
    "Hoodlum",
    "Hood",
    "Tough",
    "Gangster",
    "Goon",
    "Plugugly",
    "Tough",
    "Criminal",
    "Racketeer",
    "Hood",
    "Hoodlum",
    "Hoodlum",
    "55-Across, maybe",
    "Goon",
    "Hood",
    "Mob member",
    "Hooligan",
    "Mob member"
  ],
  "jack": [
    "\"J\" in a deck of cards",
    "Either of the two highest trump cards in euchre",
    "Beanstalk climber in a children's story",
    "Steal, slangily",
    "Raise, with \"up\"",
    "Nothing, slangily",
    "Diddly-squat",
    "Hike, with \"up\"",
    "Hike, with \"up\"",
    "With 41-Across, co-creator of Captain America and the Hulk",
    "With 65-Across, second in a series of five TV personalities (1957-62)",
    "Word that can define 17- and 59-Across and 11-, 28- and 29-Down",
    "\"You don't know ___\"",
    "Trunk item",
    "Phone hook-up",
    "Moolah",
    "Telephone device",
    "It's an honor",
    "Item in a trunk",
    "Moolah",
    "Part of a royal flush"
  ],
  "moil": [
    "Drudgery, in older usage",
    "Be in continuous agitation",
    "Work hard",
    "Turbulence",
    "Work hard",
    "Work hard",
    "Do drudgery",
    "Drudgery",
    "Churn",
    "Drudge",
    "Drudge",
    "Work hard",
    "Drudge"
  ],
  "luge": [
    "Sled supinely",
    "Olympics event since 1964",
    "Olympic racer",
    "Olympic event with singles, doubles and team relay",
    "Olympics racer",
    "Olympic racing event",
    "Winter Olympics sport",
    "Olympic downhill event",
    "Olympic sled",
    "Downhill sport",
    "Race in a chute",
    "Sled at the Winter Olympics",
    "Olympics item ... or the winning word in the 1984 National Spelling Bee",
    "Winter Olympics event since 1964",
    "Olympic sled",
    "Spill producer",
    "Olympic racer",
    "Winter Olympics sport",
    "Racer on ice",
    "Bob's cousin?",
    "Olympic sled",
    "Fast sport",
    "Winter Olympics vehicle",
    "Downhill racer",
    "Go downhill fast?",
    "Bob's cousin",
    "Winter sport",
    "Olympic vehicle",
    "Racing sled",
    "In this race, the runners are always behind",
    "Winter Olympics event",
    "Lillehammer event"
  ],
  "give": [
    "Bestow",
    "Supply",
    "Partner of take",
    "With 16-Across, donate",
    "Elasticity",
    "With 40-Across, tip off",
    "With 37-Across, express freely, with \"to\"",
    "Say \"Uncle!\"",
    "Yield some",
    "Say \"uncle\"",
    "Springiness",
    "Turn over",
    "\"Had enough?\"",
    "Be generous",
    "Say \"Uncle!\"",
    "Buckle",
    "\"Out with it!\"",
    "\"Had enough?\"",
    "Confer upon",
    "Donate",
    "\"Out with it!\"",
    "\"___ Me a Break\" (title for this puzzle?)",
    "Room to move",
    "Looseness",
    "Donate",
    "\"Hand it over!\"",
    "\"Had enough?\"",
    "Collapse",
    "Turn over",
    "Award",
    "Bestow",
    "Take's partner"
  ],
  "jcts": ["Hwy. crossings", "Hwy. crossings"],
  "bboy": [
    "Break-dancer, in slang",
    "Break-dancer, slangily",
    "Certain street dancer, in slang",
    "*Rap devotee, slangily",
    "Hip-hop devotee, in old slang",
    "Rap devotee, in slang"
  ],
  "tabu": [
    "Fragrance since 1932",
    "Popular perfume",
    "___ by Dana (perfume)",
    "\"Forbidden\" fragrance in old ads",
    "Forbidden-sounding perfume",
    "Fragrance from Dana Perfumes",
    "Perfume that's off-limits?",
    "Fragrance name that's forbidden-sounding",
    "Classic perfume",
    "Popular perfume",
    "Forbidden perfume?",
    "\"The forbidden fragrance\"",
    "Brand advertised as \"the forbidden fragrance\"",
    "Popular fragrance",
    "Forbidden-sounding perfume",
    "\"Forbidden\" perfume brand",
    "Classic Dana fragrance for women",
    "Perfume brand",
    "Off-limits: Var.",
    "Forbidden: Var.",
    "Forbidden: Var.",
    "Popular fragrance",
    "Don?Æt do it: Var.",
    "No-no: Var.",
    "Off-limits: Var.",
    "Verboten: Var.",
    "Prohibition: Var.",
    "1931 film that won an Oscar for cinematography",
    "Off-limits: Var.",
    "Forbidden: Var.",
    "Anthropologist's topic: Var.",
    "More than frowned upon: Var.",
    "Popular cologne",
    "No-no: Var.",
    "Banned: Var.",
    "Perfume billed as \"The forbidden fragrance\"",
    "Forbidden: Var.",
    "Popular cologne",
    "No-no: Var.",
    "Off-limits: Var.",
    "Verboten: Var."
  ],
  "usta": [
    "Racketeer's org.?",
    "Arthur Ashe Stadium org.",
    "Org. for the Williams sisters",
    "Court grp.",
    "Org. with a national center named for Billie Jean King",
    "Arthur Ashe Kids' Day org.",
    "Court org.",
    "Singles grp.?",
    "Racket org.",
    "Org. concerned with court proceedings?",
    "Seeding org.",
    "Org. for singles?",
    "Grp. for court pros",
    "Ashe Stadium org.",
    "Court org.",
    "Court org. since 1881",
    "Ashe Stadium org.",
    "Court grp.",
    "Court grp.",
    "Org. for 42-Across",
    "Its members strive for good service",
    "Org. for Borg",
    "Racketeer's org.?",
    "Logo at Arthur Ashe Stadium",
    "Singles' grp.?",
    "Racketeer's org.?",
    "Pete Sampas org.",
    "Org. of court players"
  ],
  "oohs": [
    "Cries of awe",
    "Thrilled cries",
    "Exclamations during eclipses",
    "Sounds after a magic trick",
    "___ and aahs",
    "Sounds heard during fireworks shows",
    "Some reactions to fireworks",
    "Cries of joy",
    "Reactions to fireworks",
    "Circus sounds",
    "Sounds of amazement",
    "Responses to pleasure or pain",
    "Sounds of amazement",
    "Circus cries",
    "Reactions to fireworks",
    "Circus reactions",
    "Wonder-full sounds",
    "Circus reactions",
    "Wonderment cries",
    "Circusgoers' sounds",
    "Circus cries",
    "Cries at fireworks",
    "Sounds of amazement",
    "Cries at fireworks",
    "___ and aahs",
    "Expressions of joy",
    "Sounds of envy",
    "Sounds of amazement",
    "Circus cries",
    "Amazed outcries",
    "Surprise cries",
    "Circus cries",
    "Sounds of pleasure"
  ],
  "vamp": [
    "Improvise, in jazz",
    "Femme fatale",
    "Temptress",
    "Improvise musically",
    "Seductress",
    "Siren",
    "Seductress",
    "Seductress",
    "Improvise",
    "Seductress",
    "Improvise",
    "\"Hard Hearted Hannah\" of song",
    "Man trapper",
    "Fill in time on stage",
    "Theda Bara, e.g.",
    "Improvise",
    "Improvise",
    "Siren",
    "Seductress"
  ],
  "pave": [
    "Smooth over",
    "Give a smooth surface",
    "Make way?",
    "Finish a drive?",
    "Flatten, in a way",
    "___ the way",
    "Smooth, in a way",
    "Cobble, e.g.",
    "Smooth, in a way",
    "Surface",
    "Smooth over",
    "Drive off the top?",
    "Asphalt, e.g.",
    "Smooth, as the way",
    "Work on the street",
    "Put blacktop on",
    "Cover, in a way",
    "Cobble, for example",
    "Tar, maybe",
    "Cover a road",
    "Make a road",
    "Cover the driveway",
    "Macadamize, e.g.",
    "Surface",
    "Smooth, as a drive",
    "Finish a drive?",
    "Do roadwork",
    "Smooth over",
    "Make way?",
    "Make way?",
    "Finish a drive?",
    "Do roadwork",
    "Work on the street",
    "___ the way (lead)",
    "___ the way",
    "Smooth",
    "Surface",
    "Smooth the way",
    "Do road work",
    "Make way?",
    "Surface",
    "Asphalt",
    "Prepare, as the way",
    "Asphalt",
    "Tar",
    "Smooth, as a road",
    "Blacktop",
    "Blacktop"
  ],
  "melc": [
    "One of the Spice Girls",
    "Spice Girl also known as Sporty Spice",
    "Sporty Spice of the Spice Girls",
    "Sporty Spice, by another name",
    "Sporty Spice's other nickname",
    "Sporty Spice of the Spice Girls"
  ],
  "sams": [
    "Costco competitor, informally",
    "___ Club",
    "Costco competitor, informally",
    "___ Club",
    "BJ's competitor, informally",
    "___ Club",
    "Some defensive weapons, in brief",
    "___ Club",
    "___ Club",
    "___ Club (Costco competitor)",
    "Costco competitor, casually",
    "___ Club (discount chain)",
    "Club for bulking up?",
    "___ Club",
    "___ Club (discount store)",
    "___ Club (retail chain)",
    "Some mil. defenses",
    "___ Club",
    "___ Club",
    "___ Club",
    "Part of a military defense, for short",
    "Weapons in shootdowns, for short",
    "Dangers to military planes",
    "Parts of a military defense",
    "Menaces for warplanes",
    "Hawk and Patriot, for short",
    "Donaldson and others",
    "___ Club (retail chain)",
    "Actor Neill et al.",
    "Levene and Levenson",
    "Defensive weapons, for short",
    "Nuclear force elements",
    "Texas pioneer Houston et al.",
    "Snead and Spade"
  ],
  "ways": [
    "How, with \"the\"",
    "Manners",
    "House Committee on ___ and Means",
    "Means",
    "Routes",
    "Methods",
    "House ___ and Means Committee",
    "Maze choices",
    "Partner of means",
    "Means' partner",
    "Means",
    "___ and Means Committee",
    "\"We have our ___\"",
    "Methods",
    "Paths",
    "Partner of means",
    "Directions",
    "Channels",
    "Methods",
    "Paths",
    "Streets and avenues"
  ],
  "bona": [
    "___ fide",
    "___ fides",
    "___ fide",
    "___ fides",
    "___ fide",
    "___ fides (credentials)",
    "___ fide",
    "___ fide",
    "___ fide",
    "Good, to Galba",
    "\"___ Nox\" (Mozart title meaning \"good night\")",
    "___ fide",
    "___ fide",
    "___ fide",
    "___ fide",
    "___ fide",
    "___ fides",
    "___ fide",
    "___ gratia (in all kindness): Lat.",
    "___ fide",
    "___ fide",
    "___ gratia (in all kindness): Lat.",
    "___ fide",
    "___ Dea (Roman fertility goddess)",
    "___ fide",
    "___ fide",
    "___ fide",
    "___ fide",
    "___ fide",
    "___ fide"
  ],
  "tkos": [
    "M.M.A. stats, for short",
    "Some referee calls, for short",
    "Ali had more than 20 of them in his career",
    "Bout enders, informally",
    "Some calls from boxing refs",
    "Ring data",
    "Some referee calls, for short",
    "Some ring decisions",
    "Boxing stats",
    "Boxing achievements, in brief",
    "Boxing decisions",
    "They may result in title changes, for short",
    "Ring stoppages, for short",
    "Some boxing results, for short",
    "What severe cuts may result in, briefly",
    "Some fight finishes, for short",
    "Some referee calls, for short",
    "Fight stoppers, for short",
    "Match enders, briefly",
    "Some boxing results, for short",
    "Too many hits might result in them, for short",
    "Bout enders, for short",
    "Ring results, briefly",
    "Certain match results",
    "Ring decisions, for short",
    "Some boxing wins, for short",
    "Boxing stats",
    "Some boxing results",
    "Ring stats",
    "Some boxing decisions, briefly",
    "Ring wins, briefly",
    "Ring data",
    "Ring decisions, for short",
    "Some Joe Frazier wins",
    "Round ends?",
    "They stop fights",
    "Refs' calls, for short",
    "Fight enders, briefly",
    "W.B.A. stats",
    "W.B.A. calls",
    "Bout enders, in brief",
    "Win category in the W.B.A.",
    "W.B.A. stats",
    "Boxing match results",
    "Bout enders, in brief",
    "W.B.A. calls",
    "W.B.A. outcomes",
    "Boxing stat",
    "W.B.A. stats",
    "Some W.B.C. outcomes",
    "Some boxing wins",
    "Boxer's stat",
    "Ring results",
    "Fight enders",
    "Bout enders",
    "Refs' decisions",
    "Some ring decisions"
  ],
  "brim": [
    "Hat part",
    "Part of a cap",
    "Hat's edge",
    "Hat part",
    "Tipping point?",
    "Hat's edge",
    "Panama part",
    "It's around a cup",
    "Lip",
    "Filling point",
    "A cowboy hat has a wide one",
    "Spilling point",
    "Sombrero part",
    "Fedora feature",
    "Overflow site",
    "Spilling point",
    "Fedora feature",
    "Cup part",
    "Porkpie feature",
    "Derby projection",
    "Lip",
    "Hat part",
    "It may be turned down on the street",
    "Stop pouring here",
    "Full mark",
    "Cup part",
    "Sombrero feature",
    "Hat's edge",
    "Fill to the ___",
    "Cup lip",
    "Sanka rival",
    "A fez lacks one",
    "Sunshade, of a sort",
    "Hat or teacup part",
    "Fedora feature"
  ],
  "idas": [
    "One of the Argonauts in Greek myth",
    "Mythical Greek who slew Castor",
    "Lupino and Tarbell",
    "One of the Argonauts",
    "Actress Lupino and others",
    "Argonaut who slew Castor",
    "Actress Lupino and others",
    "Actress Lupino and others",
    "Actress Lupino and others",
    "Actress Lupino and others",
    "Actress Lupino and others",
    "Actress Lupino and others",
    "Argonaut who slew Castor",
    "Mrs. William McKinley and others",
    "Argonaut who slew Castor",
    "One of the Argonauts",
    "Castor's slayer",
    "Operetta princess and others",
    "Actress Kaminska et al.",
    "Writer Tarbell and others",
    "Social reformer Wells and others",
    "Writer Tarbell and others",
    "Lupino and others",
    "Mrs. McKinley and others",
    "Castor's killer",
    "Muckraker Tarbell et al.",
    "Castor's slayer",
    "TV's Mrs. Morgenstern et al.",
    "Actress Kaminska et al.",
    "Lupino et al.",
    "G. & S. princess and others"
  ],
  "curt": [
    "To the point",
    "Short",
    "Short but not necessarily sweet",
    "Terse",
    "Clipped",
    "Laconic",
    "Short",
    "Terse to the point of rudeness",
    "Short-spoken",
    "Hardly windy",
    "Abrupt",
    "Brusque",
    "Brusque",
    "Abrupt",
    "Short",
    "Rudely abrupt",
    "Rudely terse",
    "Brusque",
    "Brusque",
    "Snippy"
  ],
  "iton": [
    "Get ___",
    "\"Keep ___ the D.L.\" (\"Don't tell anyone\")",
    "Words after pour or pass",
    "Get ___ (fight)",
    "Lay ___",
    "Pour ___",
    "\"Bring ___!\"",
    "\"Pass ___!\"",
    "\"I have ___ good authority\"",
    "\"Try ___ for size\"",
    "Lay ___ the line",
    "\"I have ___ good authority\"",
    "\"Psst! Pass ___!\"",
    "\"Let's Get ___\" (1973 #1 hit)",
    "Pour ___",
    "Lay ___ the line",
    "Pour ___",
    "Have ___ good authority",
    "Pour ___",
    "\"Put ___ my tab\"",
    "Have ___ good authority",
    "Lay ___ (exaggerate)",
    "Have___good authority",
    "Has___good authority",
    "\"Put ___ my bill\"",
    "Have ___ good authority",
    "\"Blame ___ Rio\" (1984 film)",
    "Lay ___ thick"
  ],
  "greg": [
    "The kid in \"Diary of a Wimpy Kid\"",
    "Diver Louganis",
    "One of the Brady kids",
    "Lake of rock's Emerson, Lake & Palmer",
    "Maddux who won four Cy Young Awards",
    "Daniels who adapted \"The Office\" for NBC",
    "The kid in \"Diary of a Wimpy Kid\"",
    "Eldest of the Brady boys",
    "Actor Kinnear",
    "Golfer Norman a.k.a. the Great White Shark",
    "Olympian Louganis",
    "Kinnear of \"Little Miss Sunshine\"",
    "Norman with a legendary swing",
    "Actor/TV personality Kinnear",
    "Pitcher Maddux with four Cy Young Awards",
    "Pitcher Maddux who won four straight Cy Young Awards",
    "N.F.L.'er Olsen or Toler",
    "Dharma's sitcom spouse",
    "Kinnear of \"Little Miss Sunshine\"",
    "Tour de France winner LeMond",
    "Tennis star Rusedski",
    "Tennis star Rusedski with a sizzling serve",
    "Lake of rock's Emerson, Lake & Palmer",
    "Rusedski of tennis",
    "Cyclist LeMond",
    "Louganis who did gainers",
    "Norman with a club",
    "Actor Kinnear",
    "Diver Louganis",
    "Dharma's partner",
    "Diver Louganis",
    "Baseball's Brock",
    "Lake of Emerson, Lake & Palmer",
    "Baseball's Luzinski",
    "The oldest Brady kid",
    "Luzinski of baseball",
    "Hurler Maddux",
    "Thomas Gibson's sitcom title role",
    "Lake of Emerson, Lake and Palmer",
    "Dharma's guy",
    "Oldest of the Brady bunch",
    "Cyclist___LeMond",
    "Baseball's Luzinski",
    "Diver Louganis",
    "Golfer Norman"
  ],
  "hunt": [
    "Diana is the Roman goddess of it",
    "Event with foxhounds",
    "Land animals?",
    "Easter activity",
    "Scavenge (for)",
    "Go after",
    "Event on an estate",
    "Kind of club",
    "Go after bucks or ducks, say",
    "Rural event on horseback",
    "Event for hounds",
    "Proceed with a game plan",
    "Seek food, perhaps",
    "Forest doings",
    "Follow a fox, say",
    "Event in a forest",
    "Safari",
    "Best Actress of 1998",
    "Stalk",
    "Event for a foxhound",
    "Go for game",
    "Follow the game"
  ],
  "alog": [
    "Easy as falling off ___",
    "Sleep like ___",
    "Sleep like ___",
    "Easy as falling off ___",
    "Sleep like ___",
    "Like falling off ___",
    "Sleep like ___",
    "As easy as falling off ___",
    "\"... bump on ___\"",
    "Sleep like ___",
    "Sleep like ___",
    "Like a bump on ___",
    "Sleep like ___",
    "Like a bump on ___",
    "Sleep like___"
  ],
  "brea": [
    "La ___ Tar Pits",
    "Hollywood-area attraction La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "City in southern California",
    "California city near Fullerton",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "City next to Fullerton",
    "City in Orange County, Calif.",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "Town north of Anaheim",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "La ___ tar pits",
    "City north of Anaheim",
    "Town north of Anaheim",
    "Spanish tar",
    "La ___ tar pits",
    "La ___ tar pits",
    "Spanish tar",
    "La ___ (fossil-rich area)",
    "La ___ Tar Pits",
    "La ___ Tar Pits",
    "Town near Long Beach",
    "Mineral tar"
  ],
  "aaaa": [
    "Tiny battery",
    "Tiny battery size",
    "Tiny battery",
    "Tiny battery",
    "Diminutive battery size",
    "Tiny battery",
    "High rating"
  ],
  "puff": [
    "Smoke",
    "What an out-of-shape person might do while exercising",
    "Bit of wind",
    "Cream ___",
    "Magical dragon",
    "Small drawing?",
    "Bit of smoke",
    "Overblown praise",
    "Word after cream or powder",
    "Bit of a drag"
  ],
  "slag": [
    "Dross",
    "Foundry detritus",
    "Smelting refuse",
    "Smeltery refuse",
    "Metal refuse",
    "Refinery waste",
    "Smelting residue",
    "Smelting waste",
    "Criticize, in British slang",
    "Smelting waste",
    "Some industrial waste",
    "Mill runoff",
    "Coal-mining waste",
    "Smelting residue",
    "Refinery waste",
    "Smelting waste",
    "Filaments in wrought iron",
    "Bad-mouth, in Britain",
    "Metal waste",
    "Refuse at a mill",
    "Denigrate, in English slang, with \"off\"",
    "Metal refuse",
    "Refinery waste",
    "Waste",
    "Waste",
    "Dross",
    "Refined waste",
    "Waste material",
    "Smeltery refuse",
    "Smelting waste",
    "Waste",
    "Remains at a steel mill",
    "Steel mill by-product",
    "Refuse to get rid of",
    "Waste",
    "Coal waste",
    "Smelting waste",
    "Mill by-product",
    "Smelter stuff",
    "Smeltery refuse",
    "Smelting byproduct",
    "Smelter's waste",
    "Furnace waste",
    "Waste",
    "Smelt residue",
    "Smelting waste",
    "Smelting residue",
    "Waste",
    "Smeltery waste",
    "Metal refuse",
    "Mining waste",
    "Coal-mining waste",
    "Cinder",
    "Smelter refuse",
    "Molten waste",
    "Smelter waste",
    "Dross",
    "Mine waste",
    "Dross",
    "Smelting dross"
  ],
  "arme": [
    "Pistolet, par exemple",
    "Pistolet ou canon",
    "Sabre ou pistolet",
    "French weapon",
    "French weapon",
    "Weapon for un soldat",
    "French weapon",
    "French weapon",
    "French weapon",
    "French weapon",
    "Pistolet, e.g.",
    "Baionnette, e.g.",
    "Epee ou pistolet",
    "French weapon",
    "___ blanche (sword)"
  ],
  "sims": [
    "Popular video game of 2000, with \"The\"",
    "Virtual people",
    "Family of computer games",
    "Family in a computer game",
    "PC game sensation of the early 2000s, with \"The\"",
    "Family of games",
    "Best-selling computer game from the early 2000s, with \"The\"",
    "Saxophonist Zoot",
    "Jazzman Zoot",
    "Denizens of a popular computer \"city\"",
    "Computerized enactments, for short",
    "Beautician Naomi"
  ],
  "gigs": [
    "Short-term engagements",
    "Parties, e.g., for D.J.s",
    "Band bookings",
    "Play dates?",
    "What many cats play",
    "Band lineup",
    "Play dates?",
    "Band jobs",
    "Band's lineup",
    "Musicians' jobs",
    "Band's schedule",
    "Tour dates",
    "Temporary jobs",
    "Rock stars' dates",
    "Bands' bookings",
    "Musicians' engagements"
  ],
  "kits": [
    "Purchases for do-it-yourselfers",
    "All-in-one boxes",
    "Baby beavers",
    "Little foxes",
    "Baby foxes",
    "Young foxes",
    "Modelists' purchases",
    "Little foxes",
    "Little foxes",
    "Doctors' bags",
    "Litter contents",
    "Hobby shop stock",
    "Hobby shop inventory",
    "Ambulance supplies",
    "They're used to fashion models",
    "Do-it-yourselfers' needs",
    "Salespeople's handouts",
    "See 30-Across",
    "Hobbyist purchases",
    "Model sets"
  ],
  "biko": [
    "Anti-apartheid activist Steve",
    "Anti-apartheid activist Steve",
    "Soweto uprising figure",
    "Steven ___, subject of \"Cry Freedom\"",
    "Steven ___, real-life subject of the 1987 film \"Cry Freedom\""
  ],
  "isms": [
    "Ideologies",
    "Schools of thought",
    "Ideologies",
    "Belief systems",
    "Philosophies",
    "Ideologies",
    "Schools of thought",
    "Schools of thought",
    "Beliefs",
    "*Plural suffix with organ",
    "Ideologies",
    "Belief systems",
    "Ideologies",
    "Doctrines",
    "Beliefs",
    "Belief systems",
    "Systems of principles",
    "Schools of thought",
    "Schools of thought",
    "Beliefs",
    "Ideological beliefs",
    "Faiths, say",
    "Philosophies",
    "Theories",
    "Doctrines",
    "Systems of principles",
    "Philosophies",
    "Doctrines",
    "Schools of thought",
    "Philosophical subjects",
    "Things to believe in",
    "Schools of thought",
    "Political doctrines",
    "Doctrines, informally",
    "Philosophy subjects",
    "Doctrines",
    "Doctrines",
    "Theories",
    "Theories",
    "Philosophical holdings",
    "Poly-sci subjects",
    "Theories",
    "Doctrines",
    "Distinctive doctrines",
    "Doctrines",
    "Tenets",
    "Doctrines",
    "Doctrines",
    "Creeds",
    "Ideologies"
  ],
  "mark": [
    "Old German money",
    "Beauty ___",
    "\"___ my words\"",
    "Book between Matthew and Luke",
    "Letter from the teacher",
    "\"The ___ of Zorro\"",
    "Grade, as papers",
    "Con man's target",
    "Accent, e.g.",
    "On your ___",
    "Word that can follow the end of 17-, 23-, 37-, 48- or 60-Across",
    "X",
    "\"___ my words\"",
    "Discontinued German money",
    "German capital",
    "Sting target",
    "Inflated currency of the 20's",
    "Slash, for one",
    "Luke preceder",
    "Take notice of",
    "The \"M\" in M-1 rifle",
    "Squiggle, e.g."
  ],
  "gild": [
    "Richly adorn",
    "___ the lily",
    "Decorate elegantly",
    "___ the lily",
    "Impart a false brilliance to",
    "Richly adorn",
    "Put a coat on",
    "Put a coat on",
    "Embellish richly",
    "Embellish richly",
    "Embellish",
    "Richly adorn",
    "Richly decorate",
    "Apply gold leaf to",
    "Decorate expensively",
    "___ the lily",
    "Make deceptively attractive",
    "Aurify",
    "Decorate, as a book",
    "Decorate"
  ],
  "spad": [
    "W.W. I French biplane",
    "Fokker foe",
    "W.W. I fighter plane",
    "W.W. I French fighter plane",
    "W.W. I plane",
    "W.W. I plane",
    "W.W. I French plane",
    "W.W. I Allied plane",
    "Miner's nail"
  ],
  "itor": [
    "\"Believe ___ not!\"",
    "\"Click ___ ticket\"",
    "\"Believe ___ Not!\"",
    "\"Believe ___ Not!\"",
    "\"Believe ___ Not!\"",
    "\"Like ___ not ...\"",
    "\"Like ___ not ...\"",
    "\"Do ___ else!\"",
    "\"Believe ___ Not!\"",
    "\"Like ___ not ...\"",
    "\"Like ___ not ...\"",
    "Like ___ not",
    "\"Do ___ else!\"",
    "\"... like ___ not!\"",
    "\"Believe ___ ...\"",
    "\"Believe ___ not!\"",
    "\"Believe ___ Not!\"",
    "Atlanta Rhythm Section's \"Do ___ Die\"",
    "\"Like ___ not\"",
    "\"...like ___ not!\"",
    "\"Take ___ leave it!\"",
    "\"...like ___ not!\"",
    "\"Like ___ not!\"",
    "\"Ripley's Believe ___ Not!\"",
    "\"Like ___ not...\"",
    "Like-not link",
    "Like___not",
    "\"Love ___ leave it\"",
    "\"Love ___ leave it\"",
    "Like ___ not",
    "\"Like ___ lump it\""
  ],
  "larc": [
    "___ de Triomphe",
    "___ de Triomphe",
    "Tir a ___ (bow-and-arrow sport: Fr.)",
    "Prix de ___ de Triomphe (annual Paris horse race)",
    "___ de Triomphe"
  ],
  "edom": [
    "Ancient Dead Sea land",
    "Ancient kingdom in modern-day Jordan",
    "Moab's neighbor, in the Bible",
    "Biblical kingdom",
    "Old Testament kingdom",
    "Ancient land around today's Jordan",
    "Ancient rival of Judah",
    "Biblical land whose name means \"red\" in Hebrew",
    "Biblical kingdom",
    "Land in Genesis",
    "Ancient land in modern Jordan",
    "Esau's descendants' land",
    "Retro style",
    "Land of Esau's descendants",
    "Land of the descendants of 67-Across",
    "Ancient land near the Dead Sea",
    "Birthplace of Herod the Great",
    "Land of Esau's descendants",
    "Kingdom given to Esau",
    "Rugged biblical land",
    "Ancient land south of the Dead Sea",
    "Another name for the biblical Esau",
    "Old land south of the Dead Sea",
    "Neighbor of ancient Palestine",
    "Kingdom whose people descended from 5-Down",
    "Land of Esau's descendants",
    "Biblical land",
    "Biblical country",
    "Land south of Judah",
    "Land in Genesis",
    "Brother of Jacob",
    "Old Dead Sea kingdom",
    "Biblical kingdom",
    "Old Testament kingdom",
    "Region near ancient Palestine"
  ],
  "cobs": [
    "Cookout discards",
    "Cookout discards",
    "Drakes : ducks :: ___ : swans",
    "Inner parts of corn",
    "Ear parts",
    "They're held at both ends when eating",
    "Some Thanksgiving decorations",
    "Ones that are all ears?",
    "Kernel keepers",
    "Ear pieces?",
    "Corn throwaways",
    "Inner ears",
    "They're all ears",
    "Inner ears?",
    "Corn leftovers",
    "Middle ears?",
    "Pens' mates",
    "Corny throwaways"
  ],
  "prog": [
    "___ rock (music genre)",
    "___ rock (music genre)",
    "___ rock",
    "___ rock (music genre)",
    "Bull Moose party: Abbr.",
    "Bull Moose's party: Abbr.",
    "Govt. initiative"
  ],
  "balt": [
    "Lithuanian, e.g.",
    "Latvian, e.g.",
    "Riga resident, e.g.",
    "Estonian or Lithuanian",
    "Latvian, e.g.",
    "Latvian, e.g.",
    "Latvian or Estonian",
    "Estonian, e.g.",
    "Latvian, e.g."
  ],
  "pong": [
    "Beer ___",
    "See 63-Across",
    "Game with a 40-millimeter ball, informally",
    "Video game with a square ball",
    "Beer ___",
    "Beer ___",
    "Video game with a paddle",
    "Beer ___",
    "Ping-___",
    "See 23-Across",
    "Early Atari offering",
    "Beer ___",
    "See 32-Across",
    "Table game with paddles, slangily",
    "Early video game",
    "Game with a paddle",
    "Simple game with a paddle",
    "Simple game",
    "\"Turandot\" tenor",
    "Pioneering 70's video game",
    "Ping-___",
    "Ping-___",
    "Pioneer video game",
    "Pioneering video game",
    "Pioneering video game"
  ],
  "haas": [
    "Nine-time P.G.A. Tour winner Jay",
    "Businessman who gave his name to Berkeley's business school",
    "Tommy of tennis",
    "Actor Lukas of \"Witness\"",
    "Tennis great Tommy",
    "Tennis great Tommy",
    "Lukas of \"Witness\"",
    "2009 Wimbledon semifinalist Tommy",
    "Actor Lukas of \"Witness\"",
    "German-born tennis star Tommy",
    "Tennis star Tommy",
    "Lukas of \"Witness\"",
    "German tennis star Tommy",
    "Lukas of \"Witness\"",
    "Berkeley's ___ School of Business",
    "See 90-Across",
    "\"Adventures in Good Music\" host Karl ___",
    "\"Eat to Win\" author Robert",
    "Lukas of \"Witness\"",
    "Lukas of \"Witness\"",
    "Lukas of \"Witness\"",
    "Actor Lukas of \"Witness\""
  ],
  "idio": [
    "Individual: Prefix",
    "Prefix with -cracy",
    "Prefix with -syncratic",
    "Start to -matic",
    "Peculiar: Prefix",
    "Graphic beginning?",
    "Peculiar: Prefix",
    "Prefix with -matic",
    "Prefix with -matic",
    "Peculiar: Prefix",
    "Peculiar: Prefix",
    "Peculiar: Prefix",
    "Peculiar: Prefix",
    "Prefix with -syncratic",
    "Peculiar: Prefix",
    "Peculiar: Prefix",
    "Peculiar: Prefix",
    "Prefix with -syncratic",
    "Personal: Prefix",
    "Prefix with -syncratic",
    "Peculiar: Prefix",
    "Peculiar: Prefix",
    "Prefix with graphic",
    "Peculiar: Prefix",
    "Personal prefix",
    "One's own, for a starter",
    "Peculiar: Prefix",
    "Personal prefix",
    "Personal prefix",
    "Peculiar: Prefix",
    "Prefix meaning peculiar",
    "\"Syncratic\" prefix",
    "Peculiar: Prefix",
    "Personal prefix",
    "One's own: Prefix"
  ],
  "notv": [
    "Timeout alternative",
    "Child's punishment, maybe",
    "Timeout alternative",
    "Angry parent's decree, maybe",
    "Homework-time prohibition",
    "Child's punishment, maybe",
    "Punishment for a teen, maybe",
    "Alternative to grounding",
    "Less harsh alternative to grounding",
    "Punishment for some kids"
  ],
  "anke": [
    "Tennis great Huber",
    "1990s tennis great Huber",
    "Huber of women's tennis",
    "Huber of women's tennis",
    "Tennis star ___ Huber",
    "Huber of women's tennis"
  ],
  "teds": [
    "\"Bill & ___ Excellent Adventure\"",
    "\"Bill & ___ Excellent Adventure\"",
    "Spreads, as straw",
    "\"Bill & ___ Excellent Adventure\"",
    "Kennedy and others",
    "\"Bill & ___ Excellent Adventure\"",
    "Three Stooges creator Healy and others",
    "Senators Cruz and Kennedy",
    "\"___ Wedding\" (\"The Mary Tyler Moore Show\" episode)",
    "\"Bill & ___ Excellent Adventure\"",
    "Senators Kennedy and Stevens",
    "Oscar-winning screenwriter Tally and others",
    "Turner and others",
    "Dr. Seuss, informally, and others",
    "Dries, as hay",
    "\"Bill & ___ Excellent Adventure\"",
    "Turner and Williams",
    "\"Bill & ___ Bogus Journey,\" 1991 comedy",
    "2004-06 poet laureate Kooser and others",
    "Senators Kennedy and Stevens",
    "Dries, as hay",
    "Kennedy and Turner",
    "Sen. Stevens and others",
    "Turner and others",
    "Sen. Stevens and others",
    "Nugent and Turner",
    "Spreads for drying",
    "Kennedy and others",
    "Slugger Williams and others",
    "Turner and Danson",
    "\"Bill & ___ Excellent Adventure\"",
    "\"Bill & ___ Excellent Adventure\"",
    "Turner and others",
    "Turner and Williams",
    "Kennedy and Koppel",
    "Kennedy and Turner",
    "Williams and Kennedy",
    "Williams and Knight",
    "Williams and Kennedy",
    "Guitarist Nugent et al.",
    "Turner and Williams",
    "1991 flick \"Bill and ___ Bogus Journey\"",
    "Danson et al.",
    "Koppel and Kennedy"
  ],
  "gulf": [
    "Wide gap",
    "Wide divide",
    "Chasm",
    "Waters south of the South, e.g.",
    "___ war syndrome",
    "Chasm",
    "Split",
    "Persian ___"
  ],
  "pugs": [
    "Flat-faced dogs",
    "Boxers",
    "Curly-tailed dogs",
    "Ones with faces only a mother could love",
    "Boxers, derogatorily",
    "Boxers, informally"
  ],
  "zulu": [
    "Origin of some lyrics sung in \"The Lion King\"",
    "Bantu speaker of southern Africa",
    "Letter after X-ray and Yankee in the NATO alphabet",
    "End of the NATO phonetic alphabet",
    "South African native",
    "1879's Anglo-___ War",
    "Natal native",
    "End of an alphabet that begins Alpha, Bravo, Charlie",
    "Native of eastern South Africa",
    "End of the NATO phonetic alphabet",
    "Last letter of a pilot's alphabet",
    "End of a phonetic alphabet",
    "Bantu language",
    "South African native",
    "Bantu language",
    "Last letter in radio lingo",
    "Language related to Xhosa",
    "One of about nine million South Africans",
    "Natal native",
    "Natal native",
    "Bantu tribesman",
    "Buthelezi, for one",
    "Inkatha Freedom Party supporter",
    "African language",
    "Bantu language",
    "Bantu people"
  ],
  "cccp": [
    "Letters on cosmonauts' helmets, once",
    "Letters on a Soviet rocket",
    "Inits. on old rubles",
    "Letters on a Soyuz rocket",
    "Letters on Soyuz rockets",
    "Cold war inits.",
    "Letters on old rubles",
    "Soyuz letters",
    "Letters on old Russian maps",
    "Marking on an old MIG",
    "Old cosmonauts' insignia",
    "Cosmonauts' insignia, once",
    "Letters on a Soyuz rocket",
    "Onetime cosmonaut's insignia",
    "3-Down, in 3-Down",
    "Letters on old Soviet rockets",
    "Inits. on a Soyuz rocket"
  ],
  "ucsb": [
    "Golden State school inits.",
    "Golden State school up the coast from L.A.",
    "College up the coast from L.A."
  ],
  "tims": [
    "Cook and Curry",
    "Rice and Robbins",
    "Popular beige work boots, colloquially",
    "Curry and Rice",
    "Allen and Conway",
    "Actors Robbins and Allen",
    "Rice and Robbins",
    "Robbins and Russert"
  ],
  "holt": [
    "Young otter's home",
    "Otter's lair",
    "Otter's den",
    "News anchor Lester",
    "News anchor Lester",
    "Publisher Henry",
    "Big name in book publishing",
    "Author Victoria"
  ],
  "glut": [
    "Surfeit",
    "Overabundance",
    "Market oversupply",
    "Oversupply",
    "Flood",
    "Stuff to the gills",
    "Fill to excess",
    "Overabundance",
    "Overfill",
    "Market surplus",
    "Engorge",
    "Excess supply",
    "Overabundance",
    "Oversupply",
    "Oversupply",
    "Market oversaturation",
    "Overindulge",
    "Superabundance",
    "Overabundance",
    "Oversupply",
    "Oversupply",
    "Nimiety",
    "Flood",
    "Oversupply",
    "Flood",
    "Flood"
  ],
  "colt": [
    "Certain yearling",
    "Indianapolis footballer",
    "Noted arms manufacturer",
    "Future stallion",
    "<--, for example",
    "Young stud?",
    "Stallion-to-be",
    "Male foal",
    "Texan's rival",
    "___ .45",
    "Johnny Unitas, for most of his career",
    "Gelding-to-be, maybe",
    "Stallion-to-be",
    "Revolver inventor",
    "Stable youngster",
    "Two- or three-year-old, maybe",
    "Peacemaker maker",
    "Frisky one",
    "Indianapolis pro",
    "Indianapolis gridder",
    "Neophyte",
    "Stallion, once",
    "Farm newborn",
    "Gun man",
    "Stallion, once",
    "Stallion-to-be",
    "Greenhorn",
    "Kentucky Derby prospect",
    "Filly's brother",
    "Western weapon",
    "Derby prospect",
    "Revolver inventor",
    "Revolver inventor",
    "___ .45",
    "Future stallion",
    ".45, e.g.",
    "Pistol company",
    "Popular pistol",
    "Future stallion"
  ],
  "sabe": [
    "\"Quien ___?\" (Spanish \"Who knows?\")",
    "\"Quien ___?\" (\"Who knows?\": Sp.)",
    "\"Quien ___?\"",
    "\"Quien ___?\"",
    "Kemo ___",
    "\"Quien ___?\" (\"Who knows?\": Sp.)",
    "Kemo ___",
    "Kemo ___",
    "\"Quien ___?\"",
    "Kemo ___ (the Lone Ranger)",
    "Kemo ___ (the Lone Ranger)",
    "Kemo ___",
    "\"Quien ___?\" (\"Who knows?\")",
    "\"Quien ___?\" (Spanish \"Who knows?\")",
    "Kemo ___ (the Lone Ranger)",
    "Kemo ___",
    "Kemo ___",
    "Kemo ___",
    "Kemo ___",
    "Kemo ___ (the Lone Ranger)",
    "\"Quien ___?\"",
    "\"Quien ___?\"",
    "\"Quien ___?\" (\"Who knows?\"): Sp.",
    "Kemo ___ (the Lone Ranger)",
    "Kemo ___",
    "Kemo ___ (\"trusty scout\")",
    "Kemo ___",
    "Kemo ___",
    "Kemo ___",
    "\"Quien ___?\"",
    "\"Quien ___?\" (\"Who knows?\")",
    "Kemo ___",
    "Kemo ___",
    "Kemo___ (the Lone Ranger)",
    "Kemo ___ (trusty scout)",
    "Kemo ___"
  ],
  "emag": [
    "Computer issue, in brief",
    "Salon, e.g., informally",
    "Online publication, informally",
    "Online read",
    "Certain Kindle download, for short",
    "Online read, for short",
    "Kindle purchase, in brief",
    "Slate, e.g.",
    "Web periodical",
    "Slate, e.g.",
    "Newsweek, e.g., now",
    "Web periodical",
    "Cyberspace 'zine",
    "'Zine on the Net",
    "Some online reading",
    "iPad read, maybe",
    "iPad owner's subscription",
    "Slate, e.g.",
    "Salon, e.g., informally",
    "Online publication, for short",
    "Slate, e.g., briefly",
    "Slate, e.g., informally",
    "Online 'zine",
    "Slate, e.g.",
    "Hi-tech read",
    "Online periodical, for short",
    "Slate, for one",
    "Online pub.",
    "Time online, for example",
    "It's read virtually, briefly",
    "Online periodical, for short",
    "Webzine",
    "Slate, e.g., for short",
    "Slate, for one",
    "Slate, e.g., informally",
    "Online periodical",
    "Webzine",
    "Slate, e.g.",
    "Slate, e.g., for short",
    "Salon, e.g., informally",
    "Webzine",
    "Webzine"
  ],
  "btwo": [
    "Riboflavin, alternatively",
    "Vitamin also known as riboflavin",
    "Stealth bomber, familiarly",
    "Vitamin a.k.a. riboflavin",
    "Riboflavin, familiarly"
  ],
  "coke": [
    "Jack and ___ (bar order)",
    "Soft drink in a red can",
    "Soft drink choice",
    "___ Zero",
    "Old \"Red, White & You\" sloganeer",
    "Popular 9-Across",
    "Vanilla ___ (cold drink)",
    "Jack's partner",
    "Furnace fuel",
    "Pepsi alternative",
    "Product once advertised as \"Ice-cold sunshine\"",
    "Blast furnace fuel",
    "\"The global high-sign\" sloganeer, once",
    "Pepsi rival",
    "\"___ is it\" (old slogan)",
    "Metallurgy fuel",
    "\"New\" drink of the 80's",
    "Rum's partner",
    "Pepsi rival",
    "Furnace fuel",
    "Pepsi rival",
    "Steelmaker's need",
    "Furnace fuel"
  ],
  "kilt": [
    "Traditional wedding wear, for some",
    "Attire that's often checkered",
    "Garment that's often plaid",
    "Bit of Highlands costume",
    "Black Watch soldier's garb",
    "Piper's wear",
    "\"Braveheart\" getup",
    "Scot's attire",
    "Bagpiper's wear",
    "Bagpiper's wear",
    "Piper's wear",
    "Traditional garb",
    "Bit of Scottish attire",
    "Tartan garb",
    "Scot's wear",
    "Highlander's skirt"
  ],
  "dred": [
    "Historic plaintiff Scott",
    "Scott of an 1857 Supreme Court case",
    "Harriet Beecher Stowe novel subtitled \"A Tale of the Great Dismal Swamp\"",
    "___ Scott",
    "Scott in 1857 news",
    "1856 antislavery novel",
    "Historic Scott",
    "Stowe antislavery novel",
    "Great Scott?",
    "Harriet Beecher Stowe novel",
    "Scott in 1857 news",
    "1856 antislavery novel",
    "Scott in an 1857 Supreme Court case",
    "Harriet Beecher Stowe novel",
    "Great Scott?",
    "1856 Stowe novel",
    "Harriet Beecher Stowe novel",
    "Harriet Beecher Stowe novel",
    "1856 antislavery novel",
    "___ Scott decision, 1857",
    "1856 Stowe novel",
    "___ Scott Decision",
    "Scott in a noted court case",
    "Historic Scott",
    "Historic Scott",
    "Stowe book",
    "Harriet Beecher Stowe novel",
    "___ Scott Decision",
    "1856 political novel",
    "Stowe novel",
    "Scott of antebellum legal fame",
    "1856 Stowe novel",
    "Slave Scott",
    "Slave Scott",
    "Stowe novel",
    "Stowe novel",
    "1857's ___ Scott Decision",
    "___ Scott Decision, 1857",
    "1856 Stowe novel",
    "Great Scott of 1857"
  ],
  "fios": ["Verizon offering"],
  "nang": ["Da ___, Vietnam", "Da ___, Vietnam", "Da ___, Vietnam"],
  "boob": [
    "Nincompoop",
    "Imbecile",
    "Fool",
    "Ninny",
    "Fool",
    "Dumb cluck",
    "Numbskull",
    "*Doofus",
    "Idiot",
    "Half-wit",
    "Numbskull",
    "Ninny",
    "Nitwit",
    "Meathead",
    "Doofus",
    "Schmo",
    "Pinhead",
    "Numbskull",
    "Chucklehead",
    "Hardly a genius",
    "Simpleton",
    "Palindromic ninny",
    "Chucklehead",
    "Lamebrain"
  ],
  "skeg": [
    "Surfboard stabilizer",
    "Surfboard stabilizer",
    "Keel extension",
    "Surfboard fin",
    "Piece supporting the rudder",
    "Keel extension",
    "Rudder support",
    "Keel afterpart"
  ],
  "xero": [
    "Prefix with -phyte",
    "Prefix with -graphic",
    "Dry: Prefix",
    "Prefix with -graphy",
    "Dry: Prefix",
    "Prefix with graphic",
    "Dry: Prefix"
  ],
  "bosh": [
    "Brit's \"Baloney!\"",
    "\"Nonsense!\"",
    "Malarkey",
    "Claptrap",
    "Baloney",
    "Nonsense",
    "\"What nonsense!\"",
    "\"Nonsense!\"",
    "Twaddle",
    "Claptrap",
    "Applesauce",
    "Twaddle",
    "\"Poppycock!\"",
    "Claptrap",
    "Hooey",
    "\"Nonsense!\"",
    "\"Poppycock!\"",
    "Hokum",
    "Poppycock",
    "Nonsense"
  ],
  "mara": [
    "NPR's ___ Liasson",
    "___-Lago (presidential retreat)",
    "Actress Kate of \"House of Cards\"",
    "Actress Wilson of \"Mrs. Doubtfire\"",
    "Rooney ___, 2016 Oscar nominee for \"Carol\"",
    "___ Liasson, NPR political correspondent",
    "Rooney ___, star of \"The Girl With the Dragon Tattoo\"",
    "Rooney ___, star of \"The Girl With the Dragon Tattoo\"",
    "\"Matilda\" star ___ Wilson"
  ],
  "erne": [
    "Sea eagle",
    "Fish-eating raptor",
    "Fish-eating raptor",
    "Cousin of a kite",
    "White-tailed raptor",
    "Shore bird",
    "Seashore flier",
    "Bird that's also the name of an Irish river",
    "Littoral eagle",
    "Shoreline raptor",
    "Seaside bird",
    "Coastal raptor",
    "Sea eagle",
    "Sea eagle",
    "Cliff dweller",
    "Marine eagle",
    "Predatory seabird",
    "White-tailed raptor",
    "Shoreline flier",
    "Shore bird",
    "Predatory seabird",
    "Sea cliff dweller",
    "Sea eagle",
    "Kite's kin",
    "White-tailed flier",
    "Fish-loving bird",
    "Seaside raptor",
    "Fish-eating raptor",
    "White-tailed eagle",
    "White-tailed bird",
    "Fish-eating raptor",
    "Marine eagle",
    "Marine raptor",
    "Coastal flier",
    "Island-dotted lake of Northern Ireland",
    "Sea flier",
    "Ocean eagle",
    "Sea eagle",
    "Coastal flier",
    "Fish-eating eagle",
    "Sea eagle",
    "Marine flier",
    "White-tailed eagle",
    "Sea eagle",
    "White-tailed eagle",
    "Fish-eating raptor",
    "Sea eagle",
    "Irish river to Donegal Bay",
    "Ballyshannon's river",
    "White-tailed eagle",
    "Eagle of the sea",
    "Sea flier",
    "White-tailed bird",
    "Sea eagle",
    "River to Donegal Bay",
    "Shore soarer",
    "Seaside raptor",
    "Sea eagle",
    "River to Donegal Bay",
    "Northern Ireland's Shannon-___ Waterway",
    "Northern Ireland lough",
    "Fish-eating flier",
    "Sea eagle",
    "River to Donegal Bay",
    "Shore soarer",
    "Coastal raptor",
    "White-tailed eagle",
    "Coastal raptor",
    "Sea bird",
    "Bird on a cliff",
    "Marine eagle",
    "Seaside bird",
    "Sea flier",
    "Flying fisher",
    "Sea eagle",
    "Sea eagle",
    "Coastal flier",
    "Sea eagle",
    "Shore soarer",
    "Bald eagle's cousin",
    "River to Donegal Bay",
    "Relative of 55-Across",
    "River to Donegal Bay",
    "River to Donegal Bay",
    "White-tailed flier",
    "Ocean flier",
    "Fish-eating eagle",
    "River to Donegal Bay",
    "Ocean flier",
    "Sea eagle",
    "Piscivorous flyer",
    "River to Donegal Bay",
    "Fish-eating bird",
    "Sea eagle",
    "Marine bird",
    "Sea bird",
    "Sea eagle",
    "Sea eagle",
    "Sea eagle",
    "Atlantic flier",
    "Cliff dweller",
    "Crossword bird",
    "River to Donegal Bay",
    "Sea eagle",
    "Sea eagle",
    "Sea bird",
    "Sea flyer",
    "Sea swooper"
  ],
  "spee": [
    "Admiral Graf ___ (German W.W. II ship)",
    "German vice admiral killed in W.W. I's Battle of the Falklands",
    "Historic German admiral Maximilian von ___",
    "German W.W. I admiral",
    "Graf ___ (ill-fated German cruiser)",
    "Admiral who bombarded Tahiti in 1914",
    "Graf ___",
    "\"Pursuit of the Graf ___\" (1956 war film)",
    "W.W. I German admiral",
    "German admiral who went down with the Scharnhorst",
    "German admiral Maximilian von ___",
    "Ill-fated German admiral",
    "Losing admiral in the Battle of the Falkland Islands, 1914",
    "Graf ___",
    "Scharnhorst admiral of W.W. I",
    "W.W. I German admiral",
    "W.W. I German admiral",
    "Admiral who went down with the Scharnhorst",
    "Graf ___",
    "W.W. I German admiral",
    "German admiral defeated by the British, 1914",
    "Scharnhorst admiral",
    "Graf ___",
    "German battleship Graf ___",
    "Graf ___",
    "Graf ___",
    "Scharnhorst commander",
    "Ill-fated German admiral",
    "Battle of Coronel admiral, 1914",
    "He sank with the Scharnhorst",
    "German admiral",
    "Germany's Graf von ___",
    "Graf ___",
    "Graf___",
    "Graf ___",
    "Film \"Pursuit of the Graf ___\"",
    "Graf ___",
    "Maximilian von ___",
    "Graf ___",
    "Graf ___",
    "Admiral sunk with the Scharnhorst",
    "The Graf___",
    "W.W. I German admiral"
  ],
  "rehm": ["Longtime NPR host Diane"],
  "wouk": [
    "\"The Caine Mutiny\" author",
    "Herman who wrote \"This Is My God\"",
    "\"Marjorie Morningstar\" novelist",
    "Herman who wrote \"The Caine Mutiny\"",
    "Captain Queeg's creator",
    "Captain Queeg's creator",
    "\"Marjorie Morningstar\" novelist"
  ],
  "sais": [
    "\"Je ne ___ quoi\"",
    "\"Que ___-je?\" (\"What do I know?\": Fr.)",
    "Je ne ___ quoi",
    "Je ne ___ quoi",
    "\"Je ne ___ quoi\"",
    "Je ne ___ quoi",
    "\"Je ne ___ quoi\"",
    "\"Je ne ___ quoi\"",
    "\"Je ne ___ quoi\""
  ],
  "nona": [
    "Prefix for a polygon with 140&deg; interior angles",
    "Prefix with -gon",
    "Prefix meaning 60-Across",
    "Singer Hendryx",
    "Numerical prefix",
    "R&B singer Hendryx",
    "\"Ali\" actress Gaye",
    "Hendryx of the group Labelle",
    "Like some hepatitis",
    "Soul singer Hendryx",
    "Pop singer Hendryx"
  ],
  "five": [
    "A short rest, so to speak",
    "{See Notepad}",
    "Time to knock off work, maybe",
    "Orange ball",
    "See 33-Across",
    "Solid orange ball",
    "Maximum on a hurricane wind scale",
    "\"Lincoln\"",
    "Dave Clark ___",
    "Word after \"take\" or \"give me\"",
    "... of a 1964 Kennedy stamp",
    "See 71-Across",
    "\"Gimme ___!\"",
    "V",
    "*End of a 38-Across",
    "V, to Valerian",
    "V",
    "With 102-Across, London-New York time difference",
    "One in ___ (20%)",
    "Half a sawbuck",
    "Quitting time, often",
    "A short rest, in actors' lingo",
    "Take ___ (rest)",
    "Word after take or high",
    "Joe DiMaggio's number"
  ],
  "ille": [
    "He: Lat.",
    "Latin pronoun",
    "\"Winnie ___ Pu\"",
    "\"Winnie ___ Pu\"",
    "\"Winnie ___ Pu\"",
    "\"Winnie ___ Pu\"",
    "River at Rennes",
    "\"Winnie ___ Pu\"",
    "Latin pronoun",
    "Latin pronoun",
    "River at Rennes",
    "\"Winnie ___ Pu\"",
    "\"Winnie-___-Pu\"",
    "Latin \"that\"",
    "___-et-Vilaine, France",
    "\"Winnie ___ Pu\""
  ],
  "guam": [
    "See 24-Across",
    "Hagatna is its capital",
    "\"Where America's Day Begins\"",
    "53rd \"state quarter\" locale",
    "The Japanese captured it in 1941",
    "Island near the Mariana Trench",
    "Stop on the first trans-Pacific air route",
    "Where Chamorro is spoken",
    "Pacific island \"where America's day begins\"",
    "\"Where America's day begins\"",
    "Spanish cession in the Spanish-American War",
    "Japanese capture after Pearl Harbor",
    "U.S. territory in W.W. II fighting",
    "Where America's Day Begins",
    "Island \"where America's day begins\"",
    "Stop on Magellan's circumnavigation of the world",
    "Home of the War in the Pacific National Historic Park",
    "W.W. II battleground",
    "Acquisition of the U.S. in the Spanish-American War",
    "U.S. possession since 1898",
    "Where America's Day Begins",
    "U.S. Pacific island",
    "Locale of some U.S. naval forces",
    "Its slogan is \"Where America's Day Begins\"",
    "Apra Harbor is its main anchorage",
    "Its capital is Hagatna",
    "Trans-Pacific stopping point",
    "Southernmost of the Marianas",
    "Island discovered by Magellan",
    "One of the Marianas",
    "\"Where America's Day Begins\"",
    "U.S. Pacific territory",
    "The fruit dove is its official bird"
  ],
  "judd": [
    "Either half of a duo with 14 #1 country hits",
    "Director Apatow",
    "Naomi or Wynonna of country music",
    "\"Knocked Up\" director Apatow",
    "Filmmaker Apatow",
    "Director Apatow",
    "Film producer Apatow",
    "Actress Ashley",
    "Hirsch of \"Taxi\""
  ],
  "chit": [
    "I.O.U., e.g.",
    "Note of indebtedness",
    "\"Pay me later\" marker",
    "Debt memo",
    "I.O.U.",
    "Check",
    "Marker",
    "Slip in a pot",
    "IOU",
    "Marker",
    "Gambler's marker",
    "Voucher",
    "Voucher",
    "Marker",
    "It might go into a pot",
    "It may be thrown into a pot",
    "Voucher",
    "I.O.U.",
    "I.O.U.",
    "l.O.U.",
    "Gambler's marker"
  ],
  "kama": [
    "Hindu love god",
    "___ Sutra",
    "___ Sutra",
    "Hindu god often depicted with a bow and arrow",
    "___ Sutra",
    "___ Sutra",
    "Hindu god of desire",
    "Hindu love god",
    "___ Sutra",
    "\"___ Sutra\"",
    "___ Sutra",
    "___ sutra",
    "___ Sutra",
    "___ Sutra"
  ],
  "devo": [
    "\"Whip It\" band",
    "Band with the 1980 hit album \"Freedom of Choice\"",
    "Rock band known for its \"energy domes\"",
    "Musical group with \"energy domes\"",
    "Jumpsuit-wearing music group",
    "Band with the hit \"Whip It\"",
    "\"Whip It\" band",
    "\"Whip It\" rock band",
    "Musical group known for wearing red hats called \"energy domes\"",
    "New wave band with the hit \"Whip It\"",
    "\"Whip It\" band",
    "Band parodied by Weird Al Yankovic's \"Dare to Be Stupid\"",
    "Band parodied by Weird Al Yankovic's \"Dare to Be Stupid\"",
    "\"Oh, No! It's ___\" (1982 rock album)",
    "Robotic rock group popular in the 1980s",
    "\"Whip It\" band",
    "Band that famously remade \"Satisfaction\" on its first album",
    "\"Whip It\" rock group",
    "Rock group whose members wear red flowerpots on their heads",
    "\"Whip It\" band",
    "\"Q: Are We Not Men? A: We Are ___!\" (hit 1978 album)",
    "\"Whip It\" rock group",
    "\"Whip It\" band",
    "\"Whip It\" band",
    "Rock group from Akron that was a 1980 one-hit wonder",
    "\"Whip It\" group",
    "\"Whip It\" rock group",
    "Quirky 70's-80's band",
    "\"Whip It\" singers",
    "Red-helmeted rock group",
    "70's-80's robotic rock group",
    "\"Whip It\" rock group",
    "\"Whip It\" band",
    "Robotic rock group of the 80's",
    "\"Whip It\" group"
  ],
  "hemi": [
    "Muscle car engine",
    "Hot-rod engine, informally",
    "Prefix with cycle or sphere",
    "Powerful engine, informally",
    "Powerful car engine",
    "Powerful engine, for short",
    "Prefix meaning \"half\"",
    "Half: Prefix",
    "Powerful engine, informally",
    "Prefix with sphere",
    "Half-",
    "___ engine",
    "Spheric opener?",
    "Prefix with sphere",
    "Certain engine",
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with sphere",
    "Powerful auto engine",
    "Hot-rod engine, briefly",
    "Prefix with sphere",
    "Kind of engine",
    "Prefix with sphere",
    "Half: Prefix",
    "Prefix with sphere",
    "Prefix with sphere",
    "Powerful car engine, informally",
    "Half in front?",
    "Half: Prefix",
    "Prefix with sphere",
    "Powerful auto engine",
    "Prefix with 48-Down",
    "Half in front?",
    "Prefix with spherical",
    "Prefix with sphere",
    "Prefix with spherical",
    "Prefix with sphere",
    "Prefix with sphere",
    "Hot-rod engine, for short"
  ],
  "mise": [
    "___ en place (putting in place: Fr.)",
    "___ en scene",
    "___ en scene",
    "___ en scene (stage setting)",
    "___ en place (putting in place: Fr.)",
    "___-en-scene",
    "___ en scene (stage setting)",
    "___-en-scene",
    "___-en-scene",
    "___ en scene"
  ],
  "fend": [
    "___ for oneself",
    "Beat (off)",
    "Repulse, with \"off\"",
    "Ward (off)",
    "Repel, with \"off\"",
    "Ward (off)",
    "Manage without assistance",
    "Manage",
    "Ward",
    "Provide (for)",
    "___ for oneself",
    "Beat (off)",
    "Provide (for)",
    "Ward (off)",
    "Ward (off)",
    "Deflect, with \"off\"",
    "Ward (off)",
    "___ off (repel)"
  ],
  "wawa": [
    "East Coast 7-Eleven competitor",
    "Basic beverage, in baby talk",
    "Trumpet or guitar effect",
    "About 60% of a tot's body, to a tot",
    "Trumpet sound",
    "H2O, to a tot",
    "Muted trumpet sound",
    "Part of a guitarist's pedalboard",
    "Tot's glassful",
    "Baba ___ (Gilda Radner character)",
    "Drink for a toddler",
    "Thirsty tot's request",
    "Thirsty toddler's request",
    "Drink in a sippy cup",
    "Guitar pedal effect",
    "Baba ___, Gilda Radner \"S.N.L.\" character",
    "Toddler's cry when thirsty",
    "Guitar effect",
    "Electric guitar effect",
    "Trumpet sound",
    "Trumpet sound",
    "Trumpet sound",
    "\"S.N.L.\" character Baba ___",
    "Mournful trumpet sound",
    "Electric guitar attachment",
    "Muted effect",
    "Gilda's Baba",
    "Baba for 20-Across",
    "Trumpet sound",
    "Baba ___ (Radner character)",
    "Trumpet sound",
    "Guitar attachment"
  ],
  "huns": [
    "Fifth-century barbarians",
    "Vandals",
    "Barbaric sorts",
    "Roman foes",
    "Onetime Asiatic nomads",
    "Vandals",
    "Barbarians at the Battle of Chalons",
    "Vandals",
    "Barbarous brutes",
    "Attila's horde",
    "Vandals"
  ],
  "boho": [
    "___-chic (fashion style)",
    "___-chic (hippie-influenced style)",
    "___-chic",
    "___-chic (hippie-inspired fashion)",
    "___-chic (fashion style)",
    "Unconventional and hippielike, informally",
    "___-chic (fashion style)"
  ],
  "geez": [
    "\"Unbelievable!\"",
    "\"Holy moly!\"",
    "\"I can't believe that!\"",
    "\"Oh ... come ... on!\"",
    "\"Man!\"",
    "\"Man!\"",
    "\"Man!\""
  ],
  "sase": [
    "Enclosure for a slush pile, for short",
    "It's often included with an R.S.V.P. card",
    "Wedding invitation courtesy, briefly",
    "Onetime MS. accompanier",
    "Ed.'s convenience",
    "Ed.'s request",
    "Autograph collector's enclosure, for short",
    "Aid for an ed.",
    "Business reply encl.",
    "Slush pile item, for short",
    "Return mailer, for short",
    "Junk mail encl., sometimes",
    "It may have a row of 28-Down, briefly",
    "Author's encl.",
    "Autograph collecting aid, for short",
    "Response facilitator: Abbr.",
    "Ed.'s convenience",
    "Manuscript encl.",
    "Mailing courtesy, briefly",
    "Aid in answering: Abbr.",
    "A MS. might come back in it",
    "Postpaid encl.",
    "MS. enclosure",
    "Encl. with a manuscript",
    "Manuscript encl.",
    "Response facilitator: Abbr.",
    "Enclosure with a MS.",
    "Manuscript encl.",
    "Encl. to facilitate a response",
    "MS. accompanier",
    "MS. enclosure",
    "Postpaid encl.",
    "It may be used for many unhappy returns: Abbr.",
    "MS. enclosure",
    "Response facilitator: Abbr.",
    "Postpaid encl.",
    "Encl. with a manuscript",
    "Convenience for an ed.",
    "Postpaid encl.",
    "Business letter encl.",
    "Freelancer's encl.",
    "MS. enclosure",
    "Mail convenience: Abbr.",
    "It may accompany a MS.",
    "Enclosure with a MS.",
    "Postpaid encl.",
    "Postpaid enc.",
    "Polite encl.",
    "It has your name on it: Abbr.",
    "Ed.'s request",
    "Ed.'s request",
    "MS. inclusion",
    "Freelancer's encl.",
    "Enclosure to an ed.",
    "Request from an ed.",
    "Freelancer's enc.",
    "Returned MS holder",
    "Ed.'s request",
    "Inclusion with a MS.",
    "Letter encl.",
    "Return mailer: Abbr.",
    "Postpaid encl.",
    "Writer's Market abbr.",
    "Enclosure with a MS.",
    "Ed.'s request",
    "Reply courtesy, briefly",
    "Encl. for a reply",
    "Manuscript encl.",
    "Letter encl.",
    "Freelancer's encl.",
    "Manuscript encl.",
    "Business letter encl.",
    "Letter encl.",
    "Env. enclosure"
  ],
  "hoed": [
    "Made the earth turn?",
    "Broke ground?",
    "Did groundbreaking work?",
    "Was anti-weed?",
    "Did some gardening",
    "Cleared, as a garden",
    "Did some weeding",
    "Dug up some dirt?",
    "Weeded, in a way",
    "Broke ground?",
    "Did some garden work",
    "Broke ground?",
    "Cleared weeds, say",
    "Like many a garden",
    "Worked a garden",
    "Broke ground",
    "Worked the soil",
    "Worked in rows",
    "Got rid of 64-Across",
    "Did groundwork?",
    "Worked on a bed",
    "Readied some beds",
    "Tended to the weeds",
    "Cultivated",
    "Worked the garden",
    "Gardened, in a way",
    "Broke clods"
  ],
  "wast": ["Were, biblically", "Biblical verb", "Biblical verb with \"thou\""],
  "leda": [
    "Mother of Castor and Pollux",
    "Princess seduced by Zeus",
    "Mother of Helen of Troy",
    "\"___ and the Swan\" (Yeats poem)",
    "Mother of Helen",
    "Mother of Helen of Troy",
    "Mythological subject for Leonardo, Correggio and Rubens",
    "Queen of Sparta",
    "Helen of Troy's mother",
    "Mythological subject of a Michelangelo painting",
    "Queen who fell for Zeus' swan song?",
    "Yeats's \"___ and the Swan\"",
    "Helen of Troy's mother",
    "Mother of Castor and Pollux",
    "Helen of Troy's mother",
    "Title partner of \"the Swan\" in a Yeats poem",
    "Mother of Helen, in myth",
    "Mother of Castor and Pollux",
    "Mother of Castor and Pollux",
    "Mother of Helen",
    "Mother of Helen and Pollux",
    "Helen of Troy's mother",
    "13th moon of Jupiter",
    "Mother of Castor and Pollux",
    "Mother of Helen, in myth",
    "Swan's mate, in myth",
    "Spartan queen",
    "Helen's mother, in Greek myth",
    "Mother of Clytemnestra",
    "Mother of Pollux and Helen",
    "Yeats's \"___ and the Swan\"",
    "Helen's mother",
    "Castor's mother",
    "Mother of Castor and Pollux",
    "Mother of Helen",
    "Mother of Castor and Pollux",
    "Mother of Helen of Troy",
    "Clytemnestra's mother",
    "Mother of Castor and Pollux",
    "Leonardo da Vinci's \"___ and the Swan\"",
    "Zeus visited her as a swan",
    "Mother of Helen of Troy",
    "Castor and Pollux's mother",
    "Spartan queen of myth",
    "Mother of Helen",
    "Mother of Helen",
    "Helen's mother",
    "Mother of twins, in myth",
    "Helen's mother",
    "Mother of Castor and Pollux",
    "Helen's mother, in Greek myth",
    "Moon of Jupiter",
    "Castor's mother",
    "Mother of 41-Across",
    "Mother of Helen of Troy",
    "Helen's mother",
    "Helen of Troy's mother",
    "Mother of Castor and Pollux",
    "Whom Zeus visited as a swan",
    "Swan's partner, in myth",
    "Helen of Troy's mother"
  ],
  "crud": [
    "\"Rats!\"",
    "\"Phooey!\"",
    "\"Darn!\"",
    "\"Oh, fudge\"",
    "\"Oh, darn!\"",
    "\"Phooey!\"",
    "Gunk",
    "Gunk",
    "\"Rats!\"",
    "\"Darn it!\"",
    "\"Phooey!\"",
    "\"Phooey!\"",
    "Gunk",
    "Rubbish",
    "Rubbish",
    "Muck",
    "Gunk",
    "Dirty deposit",
    "\"Phooey!\""
  ],
  "tapa": [
    "Fiesta finger food",
    "Spanish appetizer",
    "Appetizer that may accompany sangria",
    "Spanish snack",
    "Tidbit at a Spanish bar",
    "Madrid tidbit",
    "Finger food at a fiesta",
    "Toledo tidbit",
    "Spanish appetizer",
    "Part of a bar order",
    "Toledo tidbit",
    "Appetizer abroad",
    "Spanish tidbit",
    "Spanish morsel",
    "Bite-size appetizer",
    "Spanish hors d'oeuvre",
    "Aragon appetizer",
    "Spanish snack",
    "Spanish hors d'oeuvre",
    "Spanish snack",
    "Snack with cerveza",
    "Seville snack",
    "Spanish appetizer",
    "Spanish snack",
    "Pre-paella bite",
    "Appetizer for Juan",
    "Bark cloth",
    "Tidbit in Toledo",
    "Seville snack"
  ],
  "mavs": [
    "Dallas N.B.A. team, informally",
    "N.B.A. Western Conference team, familiarly",
    "2011 N.B.A. champs, for short",
    "Dallas team, to fans",
    "American Airlines Ctr. team",
    "Dallas five",
    "N.B.A. team, briefly",
    "Dallas squad, to fans",
    "Dallas team, informally"
  ],
  "acar": [
    "Rent-___ (Hertz or Avis)",
    "Rent-___",
    "Rent-___",
    "\"___ in every garage\"",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent--"
  ],
  "iask": [
    "\"Can ___ you a question?\"",
    "\"Now ___ you ...\"",
    "\"Now ___ you...\"",
    "\"Now ___ you!\""
  ],
  "sers": [
    "Knights' titles in \"A Game of Thrones\"",
    "Sun. deliveries",
    "Talks from a Rev.",
    "Mass appeals: Abbr.",
    "Sun. talks",
    "Sat. rehearsals, perhaps",
    "Sun. talks",
    "Sun. talks",
    "Talks on Sun.",
    "Sun. talks",
    "Sun. talks",
    "Sun. speeches"
  ],
  "cigs": [
    "Most actors don't hold real ones, informally",
    "Pack of butts",
    "Camels, e.g., for short",
    "They may be bummed",
    "Butts",
    "\"Smokes\"",
    "Marlboros, e.g., for short",
    "Weeds",
    "Purchases that are puffed, slangily",
    "Coffin nails",
    "Smokes",
    "Smokes",
    "Weeds",
    "Weeds",
    "Butts",
    "Smokes",
    "Smokes",
    "Carton contents, for short",
    "They come in a pack"
  ],
  "dost": [
    "Biblical verb with \"thou\"",
    "\"If thou ___ marry, I'll give thee this plague for thy dowry\": Hamlet",
    "Biblical verb with \"thou\"",
    "\"___ thou love me?\": Juliet",
    "What cometh after thou, maybe",
    "\"___ not thou fear God ...\": Luke 23:4",
    "Biblical verb",
    "\"O mighty Caesar! ___ thou lie so low?\": Shak.",
    "\"After whom ___ thou pursue?\": 1 Samuel",
    "\"O mighty Caesar! ___ thou lie so low?\": Shak.",
    "\"O mighty Caesar! ___ thou lie so low?\": Shak.",
    "Biblical verb",
    "Verb with thou",
    "Old-style auxiliary verb",
    "Biblical verb",
    "Biblical verb"
  ],
  "jigs": [
    "The tunes \"The Blarney Pilgrim\" and \"The Lark in the Morning,\" e.g.",
    "Lively dances",
    "Lively Irish dances",
    "Lively dances",
    "Happy people dance them",
    "Sprightly dances",
    "Spirited dances",
    "Shows of joy",
    "Sprightly dances"
  ],
  "khan": [
    "Sadiq ___, London mayor beginning in 2016",
    "Title that translates as \"lord\"",
    "Sadiq ___, London mayor elected in 2016",
    "Wrathful \"Star Trek\" villain",
    "___ Academy (means of online education)",
    "Tribal title",
    "Title villain of a \"Star Trek\" film",
    "Head of a Tatar group",
    "\"Star Trek II\" villain",
    "Villainous role for Montalban",
    "Title that's a homophone of 13-Down",
    "\"Star Trek\" villain",
    "Kirk's foe in a \"Star Trek\" sequel",
    "Genghis ___",
    "Tribal chief",
    "Old Asian ruler",
    "Wrathful foe of Captain Kirk",
    "Genghis ___",
    "Top Tatar",
    "Mongol title",
    "Marco Polo associate",
    "Top Tatar",
    "Wrathful one in a \"Star Trek\" sequel",
    "Hayworth's royal hubby",
    "Top Tatar",
    "Tribal chief",
    "Tatar ruler",
    "Turkish for \"ruler\"",
    "Tatar chief"
  ],
  "mink": [
    "Fancy fur",
    "Relative of a weasel",
    "Stole fur",
    "Relative of a ferret",
    "No-longer-fashionable fur",
    "Stole material",
    "Stole option",
    "Weasel relatives",
    "Stole material",
    "Stole material",
    "Non-P.C. wrap",
    "Expensive trim",
    "Fine fur",
    "Fur coat provider",
    "Stole, perhaps",
    "Valued fur",
    "Precious fur"
  ],
  "asou": [
    "Not worth ___ (valueless)",
    "Not worth ___",
    "Not worth ___",
    "Not worth ___",
    "Not worth ___"
  ],
  "brig": [
    "Prison at sea",
    "Water cooler?",
    "Two-masted vessel",
    "Clink on the drink",
    "Water cooler?",
    "Jolly Roger, in \"Peter Pan\"",
    "Two-masted vessel",
    "Naval jail",
    "Jolly Roger in \"Peter Pan,\" e.g.",
    "Two-master",
    "Naval lockup",
    "Ship part",
    "Clink, at sea",
    "Punishment for a sailor, maybe",
    "Ship lockup",
    "Kind of gen.",
    "Jail for a sailor",
    "Sailor's stir",
    "Ship's prison",
    "Nancy, in a Sir William Gilbert song",
    "Guardhouse",
    "Lockup"
  ],
  "ason": [
    "\"He's like ___ to me\"",
    "\"Unto us ___ is given\"",
    "\"Chacun ___ gout\"",
    "\"Unto us ___ is given\": Isaiah",
    "Whom \"unto us\" is given, in Isaiah",
    "\"He's like ___ to me\"",
    "\"... and she bare ___\": Ruth 4:13",
    "\"Unto us ___ is given\"",
    "\"Unto us ___ is given\"",
    "\"___ of the Sun\" (Jack London novel)",
    "\"And she shall bring forth ___\": Matthew 1:21",
    "\"I love him like ___\"",
    "\"And she shall bring forth ___ ?à\": Matthew",
    "One given \"unto us,\" in Isaiah",
    "\"I'll be ___ of a gun!\"",
    "\"Unto us ___ is given\"",
    "\"...unto us ___ is given\"",
    "\"Unto us ___ is given\"",
    "\"...and shall bring forth ___\": Matthew",
    "Chacun ___ gout (each to his own taste)",
    "\"Chacun ___ gout\"",
    "\"...and shall bring forth ___\": Matt.",
    "\"Unto us ___ is given\"",
    "\"...unto us ___ is given\"",
    "\"...bring forth ___\": Matt. 1:23",
    "\"Unto us ___ is given\"",
    "\".... unto us ___ is given\"",
    "\"Unto us___ is given\"",
    "\"Unto us ___ is given\""
  ],
  "bits": [
    "Atoms",
    "Smithereens",
    "Atoms",
    "Partner of pieces",
    "Parts of an \"S.N.L.\" audition",
    "Smithereens",
    "Stand-up's routines",
    "They come straight from the horse's mouth",
    "Smithereens",
    "Memory units",
    "Computing 0s and 1s",
    "Smithereens",
    "Smithereens",
    "Bridle parts",
    "Partner of pieces",
    "Crumbs",
    "Atoms",
    "Kibbles 'n ___",
    "Smithereens",
    "Parts of bridles",
    "Two ___",
    "Smidgens",
    "Crumbs",
    "They may be boring",
    "Computer units",
    "Bridle parts",
    "Morsels",
    "Bridle parts",
    "0's and 1's, to a programmer",
    "Parts of bytes",
    "Tiny memory measures",
    "Short writings",
    "Shreds",
    "Computer units",
    "Computer units",
    "Partner of pieces"
  ],
  "ojai": [
    "California city whose name sounds like a surprised two-word greeting",
    "California city north of Ventura",
    "Tourist city in Ventura County",
    "California tourist destination",
    "City whose name sounds like a surprised greeting",
    "California resort town",
    "California resort town",
    "City east of Santa Barbara",
    "City near Santa Barbara",
    "Ventura County's ___ Valley",
    "Town east of Santa Barbara",
    "California's ___ Music Festival, since 1947",
    "California hometown of the Six Million Dollar Man and the Bionic Woman",
    "Town near Santa Barbara",
    "California's ___ Valley",
    "City near Santa Barbara",
    "Town near Santa Barbara",
    "City east of Santa Barbara",
    "City east of Santa Barbara",
    "Sierra Madres resort",
    "Resort town near Santa Barbara",
    "California town east of Santa Barbara",
    "California hometown for TV's Six Million Dollar Man",
    "Santa Barbara suburb",
    "Town east of Santa Barbara",
    "Resort town near Santa Barbara",
    "Southwest California town"
  ],
  "fini": [
    "Done: Fr.",
    "Wrapped up, in Paris",
    "Over, to Odette",
    "Done, in Dijon",
    "Done, in Verdun",
    "Done: Fr.",
    "What's done in Haiti?",
    "Done: Fr.",
    "Done, in Verdun",
    "Over, overseas",
    "Done, in Dunkirk",
    "Artist's \"Done!\"",
    "Monet's \"Done!\"",
    "Done, in Dijon",
    "It's done in France all the time",
    "Over, overseas",
    "Done, in Dijon",
    "Done, in Verdun"
  ],
  "upsy": [
    "\"___-daisy!\"",
    "\"___-daisy!\"",
    "\"___-daisy!\"",
    "\"___-daisy!\"",
    "\"___-daisy!\"",
    "___ -daisy",
    "___-daisy"
  ],
  "cpus": [
    "Apple cores, for short",
    "Hearts of PCs, for short",
    "PC brains",
    "PC hearts",
    "Computer innards, for short",
    "PC cores",
    "Apple cores?: Abbr.",
    "Program executors, for short",
    "PC \"brains\"",
    "Apple cores?: Abbr."
  ],
  "theo": [
    "With 44-Across, Dutch art dealer who supported his artist brother",
    "Letter you don't pronounce in \"jeopardy\" and \"leopard\"",
    "One of the Huxtable kids on 1980s-'90s TV",
    "Teddy, by another name",
    "\"The Cosby Show\" son",
    "Where to accent \"Laotian\"",
    "\"Divergent\" actor James",
    "Baseball exec Epstein",
    "Baseball executive Epstein",
    "One of TV's Huxtables",
    "Van Gogh's brother",
    "\"The Cosby Show\" son",
    "16-season N.H.L.'er Fleury",
    "Vincent van Gogh's brother",
    "Kojak's first name",
    "___ Epstein, baseball V.I.P. known as \"Boy Wonder\"",
    "\"Vincent & ___\" (film about the van Gogh brothers)",
    "\"The Cosby Show\" son",
    "\"The Cosby Show\" boy",
    "Director Angelopoulos who won the 1988 Palme d'Or",
    "Kojak's first name",
    "Malcolm-Jamal's \"Cosby Show\" role",
    "Filmmaker van Gogh",
    "TV's Kojak",
    "Dutch film director van Gogh",
    "Lt. Kojak",
    "Lt. Kojak",
    "Prefix with -crat",
    "One of the \"Cosby Show\" kids",
    "TV's Kojak",
    "___ Epstein, Red Sox G.M. starting in 2002",
    "\"Vincent & ___\" (1990 Robert Altman film)",
    "\"The Cosby Show\" kid",
    "\"The Cosby Show\" boy",
    "Lt. Kojak",
    "Huxtable boy, on \"The Cosby Show\"",
    "Lt. Kojak on \"Kojak\"",
    "Dutch filmmaker ___ van Gogh",
    "Kojak, to friends",
    "\"The Cosby Show\" boy",
    "Baseball general manager Epstein",
    "Vincent Van Gogh's brother",
    "Greek director Angelopoulos",
    "One of TV?Æs Huxtables",
    "Lt. Kojak",
    "Lt. Kojak",
    "\"Dear ___: The Autobiography of Vincent Van Gogh\"",
    "Huxtable boy",
    "Huxtable boy",
    "Huxtable son",
    "The Huxtable boy, on \"The Cosby Show\"",
    "Van Gogh's \"Letters to His Brother ___\"",
    "Lt. Kojak",
    "Lt. Kojak",
    "Lt. Kojak",
    "Lt. Kojak",
    "One of TV's Huxtables",
    "Van Gogh's \"Letters to His Brother ___\""
  ],
  "pods": [
    "Compact storage units",
    "Things cotton pickers pick",
    "Racing vehicles for Anakin Skywalker",
    "Bean holders",
    "Tide detergent capsules",
    "Okra units",
    "Sci-fi vehicles",
    "Acacia features",
    "Pea holders",
    "Seed containers",
    "Pea protectors",
    "Jet engine housings",
    "Sci-fi escape vehicles",
    "Pea holders",
    "Underwater families",
    "Cacao holders",
    "Some marine herds",
    "Opium poppies have them",
    "Miniature sci-fi vehicles",
    "Pea jackets?",
    "Pea holders",
    "Mustard plant features",
    "They're full of beans",
    "Pea holders",
    "Miniature sci-fi vehicles",
    "Pea holders",
    "Whale herds",
    "Escape vehicles",
    "Pea places"
  ],
  "dyan": [
    "Actress Cannon",
    "Actress Cannon",
    "Actress Cannon",
    "Cannon in movies",
    "Cannon on screen",
    "Cannon shot in Hollywood",
    "Cannon of \"Heaven Can Wait\"",
    "Cannon who married Cary Grant",
    "Actress Cannon",
    "Cannon who married Cary Grant",
    "Actress Cannon",
    "Actress Cannon",
    "Cannon shot on a set?",
    "Actress Cannon",
    "Cannon of \"Heaven Can Wait\"",
    "Cannon of Hollywood",
    "Actress Cannon",
    "Actress Cannon",
    "Cannon of film",
    "Cannon of \"Out to Sea\"",
    "Actress Cannon",
    "Actress Cannon",
    "Actress Cannon",
    "Cannon of \"Deathtrap\"",
    "Cannon in Hollywood",
    "Actress Cannon",
    "Actress Cannon"
  ],
  "afly": [
    "Emily Dickinson's \"I heard ___ buzz - when I died\"",
    "Wouldn't hurt ___",
    "Be ___ in the ointment",
    "___ in the ointment"
  ],
  "saar": [
    "German industrial region",
    "German coal district",
    "Coal-rich German region",
    "River across the French/German border",
    "River of western Germany",
    "Coal-rich area in Europe",
    "River across the French/German border",
    "Coal-rich region of Germany",
    "Mosel tributary",
    "Coal-rich valley",
    "River across the French/German border",
    "River of Germany",
    "Moselle tributary",
    "Coal-rich German region",
    "Coal area",
    "German/French river",
    "European coal area",
    "German industrial region",
    "___ Basin, German coal region",
    "Moselle tributary",
    "River to the Mosel",
    "Coal-rich German region",
    "Germany's ___ Valley",
    "German coal-producing region",
    "River from the Vosges Mountains",
    "Coal-rich European region",
    "Mosel tributary",
    "Coal-rich area in Europe",
    "Moselle tributary",
    "River to the Moselle",
    "Mosel feeder",
    "German coal region",
    "Region of heavy W.W. II fighting",
    "German coal region",
    "German river"
  ],
  "amah": [
    "Nanny, in Nanjing",
    "Southeast Asian housemaid",
    "Shanghai nursemaid",
    "Asian au pair",
    "Indian nursemaid",
    "Nanny, in Nanjing",
    "Far Eastern housemaid",
    "Eastern nurse",
    "Eastern nurse",
    "Asian nurse",
    "Asian nurse",
    "Eastern nurse",
    "Eastern nanny",
    "Indian housemaid",
    "Asian nanny",
    "Asian nurse",
    "Eastern domestic",
    "Asian au pair",
    "Asian domestic",
    "Asian nurse",
    "Mother's helper in Madras",
    "Asian nurse",
    "Indian nurse",
    "Asian au pair",
    "Eastern nurse",
    "Eastern nanny",
    "Asian nurse",
    "Asian nurse",
    "East Asian servant",
    "Asian nursemaid",
    "Wet nurse",
    "Nanjing nanny",
    "Asian nurse",
    "Sloe-eyed nanny",
    "Eastern nurse",
    "Wet nurse",
    "Female servant",
    "Nanjing nanny",
    "Nanking nanny",
    "Oriental au pair",
    "Asian caregiver",
    "Chinese nurse",
    "Oriental nurse",
    "Nanking nanny",
    "Chinese nurse",
    "Oriental housemaid",
    "Nanking nanny",
    "Oriental nurse",
    "Nanjing nurse",
    "Asian nurse",
    "Nanking nanny",
    "Oriental servant",
    "Eastern nurse",
    "Oriental pram-pusher"
  ],
  "amso": [
    "Schoolyard retort",
    "\"Are not!\" comeback",
    "Retort to \"Are not!\"",
    "\"Are not!\" rejoinder",
    "Playground rejoinder",
    "\"I ___ confused\"",
    "\"Are not!\" elicitor",
    "\"I ___ bored!\"",
    "\"Are not!\" playground retort",
    "Assertive comeback",
    "\"I ___ dead!\"",
    "\"I ___ bored!\"",
    "Schoolyard comeback",
    "\"I ___ sorry!\"",
    "Reply to \"Are not!\"",
    "Childish retort",
    "\"I ___ sorry!\"",
    "Schoolyard retort",
    "\"I know not why I ___ sad\": Shak."
  ],
  "escs": ["Keys often hit in panic: Abbr.", "Some PC keys"],
  "frye": [
    "Channing with a 14-year N.B.A. career (2005-19)",
    "David ___, comedian famous for impersonating Richard Nixon",
    "6'11\" Channing of the N.B.A.",
    "David ___, comic with a famous Richard Nixon impression",
    "Comic impressionist David"
  ],
  "gaea": [
    "Greek earth goddess",
    "Greek earth goddess",
    "Mother and wife of Uranus",
    "Mother of Cronus and Rhea",
    "Greek earth goddess",
    "Earth, to the ancient Greeks",
    "Greek earth goddess",
    "Earth goddess created by Chaos",
    "Mother of the Titans",
    "Wife of Uranus",
    "Earth goddess",
    "Mother Earth",
    "Earth goddess",
    "Mother of Cronus",
    "Earth personified",
    "Greek earth goddess",
    "Greek Mother Earth",
    "Mythical mother of the Titans",
    "Mother of the Titans",
    "Greek earth goddess",
    "Earth goddess"
  ],
  "fobs": [
    "Watch chains",
    "Watch chains",
    "Watch chains",
    "Palms (off on)",
    "Watch chains",
    "Watch chains",
    "Watch chains",
    "Palms (off)"
  ],
  "urbs": [
    "Metro areas, informally",
    "Cities, informally",
    "Heavily populated areas, informally",
    "Cities, informally",
    "City, to Cicero",
    "Cities, informally",
    "Downtowns, e.g.",
    "High-rise locales",
    "Cities",
    "City areas, informally",
    "City, to Cato"
  ],
  "bock": ["Strong brew", "Dark beer", "Dark quaff", "Dark beer"],
  "boos": [
    "Uncivil greetings",
    "Angry chorus",
    "Greetings for the visiting team",
    "Disapproving sounds",
    "Chorus that's not nice to hear",
    "Stands abuse?",
    "Haunted house sounds",
    "Greets the Red Sox at Yankee Stadium, say",
    "Jeering from the bleachers",
    "They might follow bad calls",
    "Responses to bad calls",
    "Chorus after a bad call",
    "Unhappy chorus",
    "Reactions to dirty tricks",
    "Heckles",
    "Derisive reception",
    "Stadium harassment",
    "Calls after bad calls",
    "Responses to some calls",
    "Ringside responses",
    "Hecklers' chorus",
    "Opposite of huzzahs",
    "Stadium protests"
  ],
  "amat": [
    "Latin 101 word",
    "Latin 101 word",
    "Amo, amas, ___",
    "He loves, she loves, or it loves: Lat.",
    "Part of a Latin 101 conjugation",
    "Amo, amas, ___ ...",
    "Word in a Latin 101 lesson",
    "One of a Latin trio",
    "Latin 101 verb",
    "Part of a Latin 101 conjugation",
    "Latin 101 verb",
    "Latin trio member",
    "Member of a Latin trio",
    "Member of a loving trio?",
    "Amo, amas, ___ ...",
    "Amo, amas, ___",
    "Latin primer word",
    "She loves, in 10-Down",
    "Latin 101 verb",
    "Latin 101 word",
    "Member of a loving trio",
    "Part of a Latin conjugation",
    "Part of a Latin succession",
    "Part of a Latin 101 trio",
    "Word from a Latin lover?",
    "Latin lover's word?",
    "Part of a loving threesome?",
    "Amo, amas, ___ ...",
    "\"He loves,\" in Latin",
    "Amo, amas, ___",
    "One of a loving trio?",
    "Part of a Latin 101 conjugation",
    "Third in a Latin series",
    "Part of a Latin 101 conjugation",
    "Latin trio member",
    "Latin lover's word?",
    "End of a loving trio",
    "Part of a Latin 101 conjugation",
    "Part of a Latin 101 conjugation",
    "Latin 101 verb",
    "Amo, amas, ___ ...",
    "Part of a Latin 101 conjugation",
    "Latin 101 verb",
    "Latin 101 verb",
    "Third of a Latin sextet",
    "Latin 101 word",
    "Part of a Latin I conjugation",
    "Amo, amas, ___",
    "Latin 101 word",
    "Amo, amas, ___",
    "Part of a conjugative series",
    "Latin 101 verb",
    "Part of Latin 101 conjugation",
    "Amo, amas, ___ ...",
    "\"Amo, amas, ___ ...\"",
    "Bit of elementary Latin",
    "Latin 101 word",
    "Part of a Latin conjugation",
    "Latin 101 verb",
    "Part of a Latin 101 trio",
    "Foreign trio member?",
    "Part of a Latin trio",
    "Part of a conjugation lesson",
    "Amo, amas, ___",
    "Part of a Latin trio",
    "Latin 101 word",
    "Amo, amas, ___",
    "Third in a Latin recital",
    "Amo, amas, ___",
    "Latin I word",
    "Latin 101 verb",
    "Latin 101 verb",
    "One of a conjugative series",
    "Latin trio member?",
    "Third of a Latin trio",
    "Latin conjugation word",
    "Latin learner's verb",
    "Latin conjugation word",
    "Latin lover's word?",
    "Last of a Latin trio",
    "Amo, amas, ___",
    "Bit of Latin conjugation",
    "Part of Latin I conjugation",
    "Part of a Latin trio",
    "Last of a Latin trio",
    "Amo, amas, ___",
    "Word for a Latin lover?",
    "Latin 101 verb",
    "Latin 101 verb",
    "Latin lover's word?",
    "Latin I word",
    "Latin 101 word",
    "Part of conjugation practice",
    "He loves: Lat.",
    "Latin I verb",
    "Latin 101 word",
    "She loves in Latin"
  ],
  "burb": [
    "Where many a commuter lives, informally",
    "Start of many a morning commute, informally",
    "Pacifica or Sausalito, to S.F.",
    "Home to many commuters, for short",
    "Bellflower or Bell Gardens, vis-a-vis L.A.",
    "Many a commuter's home, for short",
    "Bedroom community, briefly",
    "Outlying district, for short",
    "Outlying district, briefly"
  ],
  "xylo": ["Prefix with -phone", "Prefix with -phone", "Phone lead-in"],
  "lobs": [
    "Arcing tennis hits",
    "High shots",
    "Sends in a high arc",
    "Defensive tennis shots",
    "Slo-pitch pitches",
    "They follow arcs",
    "Arcing shots",
    "Highballs?",
    "High hits",
    "Arcing shots",
    "High balls?",
    "Hardly hard questions",
    "Soft shots",
    "Easy tennis shots",
    "Arcing shots",
    "Some pitches",
    "Arcing shots",
    "Arcing shots",
    "Passing shots",
    "Easy questions",
    "Arcing shots"
  ],
  "hmos": [
    "Certain insurers, for short",
    "Med. care providers",
    "Med. plans",
    "Med. insurance groups",
    "Coverage options, briefly",
    "Med. insurance plans",
    "Employer-offered plans, for short",
    "Groups with co-pays, briefly",
    "Med. care options",
    "Managed care grps.",
    "Care providers, for short",
    "Certain co. plans",
    "Med. care options",
    "Managed care grps.",
    "Insurance grps.",
    "Dr. providers",
    "Insurance providers, for short",
    "Med. plans",
    "Premium chargers, briefly",
    "Med. plans",
    "Doctors' grps.",
    "Managed care grps.",
    "Med. care choices",
    "Med. care providers",
    "Group insurance grps.",
    "Benefit package grps.",
    "They have plans, for short",
    "Modern medical grps.",
    "Caring grps.",
    "Med. plan options",
    "Humana and others, for short",
    "Some modern bill payers, for short",
    "Co. medical offerings",
    "Certain co. plans",
    "Med. choices",
    "Managed care grps.",
    "They have participating M.D.'s",
    "Benefit package providers",
    "Managed care grps.",
    "Med. care grps.",
    "Certain insurers",
    "Med. care providers",
    "Med. service providers",
    "Medical care grps.",
    "Medical grps.",
    "Some med. insurance cos.",
    "Some medical plans, for short"
  ],
  "ktel": [
    "\"Hooked on Classics\" company",
    "Producer of many compilation records",
    "Pioneering infomercial company",
    "Classic seller of compilation albums",
    "\"Hooked on Classics\" label",
    "Record label that looks like the name of a radio station",
    "\"Superhits of the Superstars\" label",
    "\"Hooked on Classics\" record promoter",
    "Label of the \"Singing to the Hits\" series",
    "Onetime TV music vendor",
    "\"Hooked on Classics\" company",
    "\"Super Hits\" company",
    "*\"As Seen on TV\" company",
    "\"Hooked on Classics\" record company",
    "Music compilation marketer",
    "\"Hooked on Classics\" record company",
    "Record label with many collections",
    "Big name in compilation albums",
    "Music compilation seller",
    "Record label known for compilations",
    "Big name in music compilations"
  ],
  "elke": [
    "Actress Sommer",
    "Actress Sommer",
    "Sommer of 1960s-'70s films",
    "Actress Sommer",
    "Sommer of \"A Shot in the Dark\"",
    "Hollywood's Sommer",
    "Sommer of Hollywood",
    "Actress Sommer",
    "Actress Sommer",
    "Sommer of film",
    "Actress Sommer",
    "Actress Sommer",
    "Sommer on screen",
    "Actress Sommer",
    "Sommer in Southern California",
    "Actress Sommer",
    "Golden Globe winner Sommer",
    "Sommer in cinema",
    "Actress Sommer",
    "Sommer of Hollywood",
    "Actress Sommer",
    "Sommer of \"The Prize\"",
    "Actress Sommer",
    "Sommer in the cinema",
    "Actress Sommer",
    "Sommer of \"The Prize,\" 1963",
    "Actress Sommer",
    "Actress Sommer",
    "Berlin-born Sommer",
    "Actress Sommer",
    "Sommer of Berlin",
    "Actress Sommer",
    "Actress Sommer",
    "Peter's \"A Shot in the Dark\" co-star",
    "Sommer in the movies",
    "Actress Sommer",
    "Sommer in the cinema",
    "Actress Sommer",
    "Actress Sommer",
    "Sommer of film",
    "Actress Sommer",
    "Actress Sommer",
    "Actress Sommer",
    "Actress Sommer",
    "Actress Sommer",
    "Sommer of \"The Prize\"",
    "Actress Sommer",
    "Actress Sommer",
    "Actress Sommer",
    "Actress Sommer",
    "Actress Sommer",
    "Sommer of the screen",
    "Actress Sommer",
    "Actress Sommer"
  ],
  "tige": [
    "Buster Brown's dog",
    "Buster Brown's canine sidekick",
    "First talking pet in American comics",
    "Buster Brown's dog",
    "Buster Brown's dog, in old comics",
    "Old comics dog",
    "Buster Brown's dog",
    "Dog of old comics",
    "Buster Brown's dog",
    "Buster Brown's dog",
    "Buster Brown's four-legged friend",
    "Shoe-endorsing dog",
    "Andrews of \"The Mod Squad\"",
    "Buster Brown's dog",
    "Andrews of \"The Mod Squad\"",
    "Buster Brown's dog",
    "Shoe-touting bulldog",
    "Buster Brown's dog",
    "Buster Brown's dog",
    "Buster Brown's dog",
    "Andrews of TV's \"Mod Squad\"",
    "Buster Brown's dog",
    "Comics dog",
    "Buster Brown's dog",
    "Buster Brown's dog",
    "Buster Brown's bulldog",
    "Comics bulldog",
    "Buster Brown's dog"
  ],
  "buys": [
    "Believes hook, line and sinker",
    "Accepts, as an argument",
    "Believes",
    "Accepts",
    "Totally believes",
    "Falls for",
    "Accepts as true",
    "Accepts",
    "Acquires",
    "Pays the price for",
    "Falls for"
  ],
  "mrsc": [
    "\"Happy Days\" character played by Marion Ross, informally",
    "Richie's mom on \"Happy Days\"",
    "Richie's mom, to Fonzie",
    "Joanie's mom, to Fonzie",
    "Richie's mom, to the Fonz",
    "Richie's mom, to the Fonz",
    "Richie's mom, to the Fonz",
    "Richie's mother, to the Fonz",
    "Fonzie's name for Richie's mom"
  ],
  "igas": ["Alternatives to Krogers and Safeways", "Certain supermarkets"],
  "weeb": [
    "Hall-of-Fame coach Ewbank",
    "___ Ewbank, 1969 Super Bowl-winning coach",
    "Former Jets coach Ewbank",
    "Coach Ewbank who led the Jets to a Super Bowl championship",
    "Hall of Fame coach Ewbank",
    "Football Hall-of-Famer Ewbank",
    "Hall-of-Fame coach Ewbank",
    "Former Jets coach Ewbank"
  ],
  "mena": [
    "Actress Suvari",
    "Actress Suvari",
    "Actress Suvari",
    "Actress Suvari of \"American Beauty\"",
    "Actress Suvari, co-star of \"American Pie\"",
    "Actress Suvari",
    "Actress Suvari",
    "Actress Suvari of \"American Beauty\""
  ],
  "peay": [
    "Austin ___ (Tennessee university)",
    "Austin ___ (Tenn. university)"
  ],
  "hoax": [
    "Elaborate deception",
    "Hoodwink",
    "Relative of fake news",
    "Crop circles, e.g.",
    "Put on",
    "What an unbelievable YouTube video might be",
    "Snopes.com subject",
    "Piltdown man, for one",
    "Put one over on",
    "April 1 news story, maybe",
    "Bigfoot photo, e.g.",
    "Piltdown man, notably",
    "Elaborate April fool",
    "April 1st event",
    "Con",
    "Hornswoggle",
    "Deception",
    "Put-on",
    "Put-on",
    "Major prank",
    "Martian invasion report, e.g.",
    "Mare's-nest",
    "Imposture"
  ],
  "kroc": [
    "McDonald's founder Ray",
    "Ray of fast-food fame",
    "McDonald's founder Ray",
    "Fast-food empire builder",
    "Ray of fast-food fame",
    "McDonald's founder Ray",
    "Ray who built McDonald's into a giant",
    "Ray a k a the Hamburger King",
    "Ray who founded McDonald's",
    "Ray who created the McDonald's empire",
    "McDonald's founder Ray",
    "Former McDonald's head Ray",
    "Ray who founded McDonald's",
    "Late philanthropist Joan",
    "Golden Arches founder Ray",
    "Longtime McDonald's chief",
    "McDonald's founder",
    "McDonald's founder Ray",
    "McDonald's founder Ray"
  ],
  "bana": [
    "See 2-Down",
    "Actor Eric",
    "Eric of \"Munich\"",
    "Actor Eric",
    "See 68-Across",
    "Eric who played Hector in \"Troy\"",
    "See 59-Across",
    "Eric of \"Hulk\"",
    "Eric of \"Munich\"",
    "Eric who played Nero in 2009's \"Star Trek\"",
    "Eric of \"Troy\"",
    "Eric who played the villain in 2009's \"Star Trek\"",
    "Actor Eric of \"Troy\"",
    "Eric of \"Funny People,\" 2009",
    "Actor who played the villain in 2009's \"Star Trek\"",
    "Eric who played the Hulk in 2003",
    "Eric of \"Munich\"",
    "Eric who played 2003's Hulk",
    "Eric of \"Munich\"",
    "Eric of \"Lucky You\"",
    "Eric who played Hector in \"Troy,\" 2004"
  ],
  "tics": [
    "Idiosyncrasies",
    "\"Nervous\" reactions",
    "Little jerks",
    "Motor problems",
    "Nerves may cause them",
    "Motor problems",
    "Idiosyncrasies",
    "Odd behaviors",
    "They might be nervous",
    "Muscular jerks",
    "Nervous reactions",
    "Idiosyncrasies",
    "Little jerks",
    "Quirks",
    "Impressionists exaggerate them",
    "Little jerks",
    "Tiny spasms",
    "Idiosyncrasies",
    "Nervous twitches",
    "Quirky habits",
    "Odd mannerisms",
    "Mannerisms",
    "Twitches",
    "Quirks",
    "Some facial features",
    "Rapid blinks, maybe",
    "Body movements",
    "Nervous twitches",
    "Peculiarities",
    "Sharp movements",
    "Uncontrollable motions",
    "Some contractions",
    "Personal quirks",
    "Spasms",
    "Eccentricities",
    "Quirks",
    "Muscle spasms",
    "Twitches",
    "Spasms",
    "Personal quirks",
    "Muscle spasms"
  ],
  "neko": [
    "Indie singer/songwriter ___ Case",
    "Indie singer ___ Case",
    "Indie singer ___ Case"
  ],
  "mais": [
    "But: Fr.",
    "\"___ oui!\"",
    "\"___ oui!\"",
    "?ô___ oui!?ö",
    "But, in Bordeaux",
    "\"___ oui!\" (certainly): Fr."
  ],
  "ygor": [
    "Bela Lugosi's role in \"The Ghost of Frankenstein\"",
    "Bela Lugosi's role in \"Son of Frankenstein\"",
    "Role in \"Son of Frankenstein\"",
    "\"Son of Frankenstein\" role"
  ],
  "cots": [
    "Fold-up beds",
    "Portable places to sleep",
    "Hotel rollouts",
    "Items at an emergency shelter",
    "Bunks in barracks",
    "Supplies in emergency shelters",
    "Some Red Cross supplies",
    "Foldable furniture",
    "Handy roll-outs at sleepovers",
    "Some prison furnishings",
    "Foldable beds",
    "Hotel supply",
    "Barracks lineup",
    "Hotel room roll-ins",
    "Red Cross supply",
    "Shelter array",
    "*Sleep lab purchases",
    "Shelter supplies",
    "Hospital sights",
    "Hotel supplies",
    "Tent furniture",
    "Camp beds",
    "Huts",
    "Bunkhouse beds",
    "Camp beds",
    "Foldable items",
    "Nap sacks",
    "Temporary accommodations"
  ],
  "cern": [
    "Org. that operates the Large Hadron Collider",
    "Large Hadron Collider org.",
    "Lab housing the world's largest machine",
    "World's largest particle physics lab, in Switzerland",
    "World's largest particle physics lab",
    "Geneva research ctr."
  ],
  "asch": [
    "Yiddish language author Sholem",
    "Author Sholem",
    "\"Moses\" novelist",
    "Author Sholem",
    "Noted American writer in Yiddish",
    "\"Mottke the Thief\" novelist, 1935",
    "\"Uncle Moses\" novelist Sholem",
    "Yiddish writer Sholem",
    "\"East River\" novelist, 1946",
    "\"Moses\" novelist",
    "\"The Nazarene\" author Sholem",
    "\"The Apostle\" author Sholem",
    "\"The Nazarene\" novelist Sholem",
    "Author Sholem",
    "Polish-born author Sholem",
    "Writer Sholem",
    "Author of \"Chaim Lederer's Return\"",
    "\"The Nazarene\" novelist",
    "Author Sholem",
    "Author Sholem",
    "\"The Nazarene\" writer Sholem",
    "\"Moses\" author",
    "Novelist Sholem",
    "\"Moses\" novelist, 1951",
    "\"Moses\" novelist",
    "\"The Nazarene\" author",
    "\"The Nazarene\" writer",
    "Writer Sholem",
    "Author of biblical novels",
    "Yiddish writer Sholem",
    "\"Moses\" author, 1951",
    "\"The Apostle\" novelist",
    "\"The Nazarene\" writer",
    "Yiddish writer Sholem___"
  ],
  "todo": [
    "Brouhaha",
    "Checklist heading",
    "Kind of list",
    "Big fuss",
    "Not yet completed",
    "Commotion",
    "Heading with check boxes below it",
    "List heading",
    "Title of a list of errands",
    "Brouhaha",
    "___ list",
    "Rumpus",
    "Fuss",
    "Heading on a list of errands",
    "List heading",
    "Commotion",
    "Kind of list",
    "Commotion",
    "Brouhaha",
    "Kind of list",
    "Flap",
    "___ list",
    "Hullabaloo",
    "Heading on a list of errands",
    "Foofaraw",
    "Hubbub",
    "Dustup",
    "Heading on a list of errands",
    "Stir",
    "___ list",
    "List heading",
    "Bother",
    "List heading",
    "___ list",
    "List-starting words",
    "Fuss",
    "List heading",
    "\"What ___?\"",
    "Disturbance",
    "Kerfuffle",
    "List heading",
    "Fuss",
    "Flap",
    "Brouhaha",
    "Big fuss",
    "Heading on a list of errands",
    "List heading",
    "Hurly-burly",
    "Stir",
    "Foofaraw",
    "Heading on a list of errands",
    "Fuss",
    "List heading",
    "___ list",
    "Busy activity",
    "Commotion",
    "Commotion",
    "Foofaraw",
    "Opening words on an agenda",
    "Kind of list",
    "Hoo-ha",
    "Pother",
    "Fuss",
    "Stir",
    "Flap",
    "Flap",
    "Uproar",
    "Fuss",
    "Bustle",
    "Hoopla",
    "Busy person's list heading",
    "Spat",
    "List heading",
    "Kind of list",
    "Kind of list",
    "Kind of list",
    "Kind of list",
    "Kind of list",
    "Brouhaha",
    "Row",
    "Commotion",
    "Kind of list",
    "Hoo-ha",
    "Hoo-ha",
    "Fuss"
  ],
  "aids": [
    "Helps",
    "Helps",
    "Buttresses",
    "An almanac and Google, for a crossword solver",
    "Lends a hand",
    "Some are audiovisual",
    "Assists",
    "Helps",
    "Affliction in \"Philadelphia\"",
    "Facilitates",
    "Lends a hand",
    "Teleprompters, for speakers",
    "Supports",
    "Assists",
    "Helps",
    "Gives succor to",
    "Assists",
    "Lends a hand",
    "Buttresses",
    "___ quilt (modern memorial)",
    "Boosts",
    "Does one's part",
    "Gives a hand",
    "Pitches in",
    "Supports",
    "Lends a hand",
    "Supports",
    "Helps out",
    "Is a good Samaritan",
    "Boosts",
    "Comes to the rescue",
    "Helps",
    "Contributes"
  ],
  "remo": [
    "San ___, Italy",
    "San ___, Italy",
    "San ___, Italy",
    "San ___, Italy",
    "San ___, Italy",
    "San ___ (resort locale)",
    "San ___",
    "San ___, Italy",
    "San ___ (Italian resort)",
    "Italy's San ___",
    "San ___, Italy",
    "San ___ (Italian seaport)",
    "San ___, Italian resort on the Mediterranean",
    "San ___, Italy",
    "San ___, Italy",
    "San ___, Italy",
    "Action film hero Williams",
    "San ___ (Riviera resort)",
    "Riviera's San ___",
    "San ___, Italy",
    "San ___",
    "\"___ Williams,\" 1985 action flick",
    "San ___, Italian Riviera city",
    "San ___, Italy",
    "San ___, Italy",
    "San ___, Italy",
    "San ___, Italy",
    "Fictional secret agent Williams",
    "San ___",
    "San ___, Italy",
    "San ___",
    "San ___, Italy",
    "Adventure hero Williams",
    "Adventure hero ___ Williams",
    "San ___, Italy",
    "San ___",
    "Adventurer-hero___Williams",
    "San ___, Italy",
    "San ___, Italy",
    "1985 film \"___ Williams\"",
    "San ___ (Riviera's resort)",
    "San___, Italy"
  ],
  "amin": [
    "Uganda's Idi ___",
    "Dictator deposed in 1979",
    "Exiled ruler of 1979",
    "Despot exiled in 1979",
    "Ugandan tyrant Idi ___",
    "Dictator Idi",
    "\"Wait ___!\" (\"Hold on there!\")",
    "Last name in despotism",
    "Forest Whitaker's Oscar-winning role",
    "See 6-Down",
    "Bygone Ugandan tyrant",
    "See 69-Across",
    "Cruel Ugandan",
    "Whitaker's Oscar-winning role",
    "Leader exiled in 1979",
    "Brute of the 1970s",
    "Whitaker's Oscar-winning role",
    "1979 exile to Saudi Arabia",
    "Despot Idi ___",
    "Role for which Whitaker won Best Actor of 2006",
    "Whitaker played him in a 2006 film",
    "Exiled Ugandan Idi ___",
    "See 48-Down",
    "1980's Lebanese president ___ Gemayel",
    "Despot of old",
    "Exiled African leader",
    "Despot Idi ___",
    "Ugandan tyrant Idi ___",
    "Bygone dictator",
    "Deposed tyrant",
    "Exiled tyrant",
    "Notorious Idi",
    "Obote's overthrower",
    "Afghan leader before the Soviet invasion",
    "Obote's deposer",
    "Ugandan dictator",
    "Ugandan tyrant",
    "African despot",
    "African despot",
    "Ugandan exile",
    "Dictator Idi",
    "Ugandan tyrant",
    "Dictator Idi",
    "Idi ___",
    "Dictator Idi",
    "Ex-tyrant",
    "Ousted Ugandan",
    "Ugandan exile",
    "Obote's successor",
    "The Wild Man of Africa"
  ],
  "poll": [
    "Bit of pre-election news",
    "Campaign undertaking",
    "Exit ___",
    "Interactive website feature",
    "Second word in the word ladder",
    "Survey",
    "Input for fivethirtyeight.com",
    "Voting place",
    "Topic of a pre-election news story",
    "Take in some views?",
    "Viewfinder?",
    "Ladder, part 3",
    "Vote",
    "Survey",
    "Gallup sampling",
    "Opinion tester",
    "It's often done by phone",
    "Campaign news",
    "Campaign effort",
    "Roper undertaking",
    "Question session",
    "Campaign aid",
    "Straw vote",
    "Pre-election product",
    "Gallup product",
    "Campaign event"
  ],
  "katz": [
    "Name on a famous New York deli",
    "Willy who lent his name to a historic Manhattan deli",
    "Classic name in New York delis",
    "Dr. ___, 1990s TV therapist",
    "___ v. United States, 1967 decision limiting wiretapping",
    "Artist Alex, leader of modern realism",
    "1970 Medicine Nobelist Bernard",
    "1990's cartoon Dr."
  ],
  "jayz": [
    "Rapper whose name hints at the extremities of the answers to the five starred clues",
    "Rapper with the music streaming service Tidal",
    "Rapper born Shawn Corey Carter",
    "Rapper with the #1 hit \"Empire State of Mind\"",
    "Rap star who co-owns the New Jersey Nets"
  ],
  "gall": [
    "Chutzpah",
    "Chutzpah",
    "Presumption",
    "Chutzpah",
    "Audacity",
    "Real nerve",
    "Effrontery",
    "Rancor",
    "Brass",
    "Effrontery",
    "Boldness to a fault",
    "Brass",
    "Nerve",
    "Annoy no end",
    "Rub the wrong way",
    "Crust",
    "Effrontery",
    "Chutzpah",
    "Chutzpah",
    "Chutzpah",
    "Chutzpah",
    "Brass",
    "Chutzpah",
    "More than irk",
    "Effrontery",
    "Temerity"
  ],
  "ison": [
    "Glenn Frey's \"The Heat ___\"",
    "Will go ahead as planned",
    "Has the stage",
    "Airs",
    "Has the stage",
    "\"Help ___ the way!\"",
    "Gets broadcast",
    "\"The race ___!\"",
    "\"The heat ___!\"",
    "Currently airs",
    "Airs",
    "\"The Heat ___\"",
    "Airs now",
    "\"The Heat ___\"",
    "\"The race ___!\"",
    "\"The heat ___\"",
    "Airs",
    "A couple of words after the race",
    "\"The shoe ___ the other foot\"",
    "\"The Race ___\" (1965 hit)",
    "Airs",
    "\"The heat ___!\"",
    "Currently has the stage",
    "The Rolling Stones' \"Time ___ My Side\"",
    "\"The heat ___!\"",
    "The writing ___ the wall",
    "\"The heat ___\"",
    "\"Help ___ the way!\"",
    "\"The pressure ___\"",
    "Airs",
    "Words after the race",
    "\"The Heat ___\"",
    "\"God ___ our side\"",
    "\"The Race ___\" (1965 song)",
    "Has the stage",
    "\"Time ___ My Side\" (Rolling Stones hit)",
    "\"Time ___ My Side\" (Stones hit)",
    "\"Help ___ the way!\"",
    "80's hit \"The Heat ___\"",
    "\"The race___!\"",
    "?ôTime ___ My Side?ö",
    "\"This one ___ me\""
  ],
  "wray": [
    "Actress Fay of the original \"King Kong\"",
    "\"King Kong\" co-star",
    "Fay of \"King Kong\"",
    "Fay of \"King Kong\"",
    "\"King Kong\" co-star, 1933",
    "Old film actress who starred in 1950's TV's \"The Pride of the Family\"",
    "Fay famous for a scream",
    "\"On the Other Hand\" autobiographer",
    "Fay of \"King Kong\"",
    "\"King Kong\" star",
    "\"King Kong\" star"
  ],
  "koan": [
    "Riddle in Zen Buddhism",
    "Zen master's query",
    "Aid to Zen meditation",
    "Paradox to be meditated on",
    "\"What is the sound of one hand clapping?,\" say",
    "Zen question",
    "Zen master's question",
    "Zen riddle"
  ],
  "gulp": [
    "Reaction to being sent to the principal's office, say",
    "More than a sip",
    "7-Eleven's Big ___",
    "[Um, this can't be good]",
    "[Boy, am I in trouble now!]",
    "[This is scary!]",
    "Get down quickly",
    "Mouthful",
    "Big swallow",
    "Chug-a-lug",
    "[Uh-oh!]",
    "[I'm frightened!]",
    "Swallow",
    "[I'm scared!]",
    "Eat greedily",
    "[uh-oh!]"
  ],
  "mazy": ["Labyrinthine", "Labyrinthine", "Tangled and interwoven"],
  "minh": [
    "Ho Chi ___ City",
    "Ho Chi ___ City",
    "Ho Chi ___ City",
    "Ho Chi ___",
    "Phu My Hung site, Ho Chi ___ City",
    "Viet ___",
    "Chi follower",
    "Chi follower",
    "Ho Chi___",
    "Ho Chi ___"
  ],
  "cals": [
    "Things counted on a diet: Abbr.",
    "Fast-food menu information: Abbr.",
    "They're counted at meals: Abbr.",
    "Ripken Sr. and Jr.",
    "Ripken, Jr. and Sr.",
    "Ripken, Jr. and Sr.",
    "Jazzman Tjader et al.",
    "Hall-of-Famer Hubbard and others"
  ],
  "kens": [
    "Dolls seen near Barbies",
    "Boy dolls",
    "Salad dressing brand",
    "Grasps",
    "Comprehends",
    "Wish-Bone alternative",
    "Understands",
    "Big name in salad dressing",
    "Understands, to a Scot",
    "Ranges of knowledge",
    "Boy toys?",
    "Male companions for Barbies",
    "Some dolls",
    "Grasps",
    "Understands",
    "Some dolls",
    "Some male dolls",
    "Grasps",
    "Ranges of knowledge",
    "Kesey and Follett",
    "Dolls since 1961",
    "Male dolls",
    "Howard and Maynard",
    "Actors Olin and Berry",
    "Popular dolls",
    "Understands",
    "Barbies' mates"
  ],
  "lily": [
    "Flower near a pad",
    "___ pad",
    "Bell-shaped flower",
    "A fleur-de-lis is a stylized one",
    "Good name for a florist?",
    "Water ___ (pond plant)",
    "Bloom on a pad",
    "Flower that symbolizes purity",
    "Pond sight",
    "Easter sight",
    "Easter bloom",
    "Symbol of innocence and purity",
    "Asparagus's family",
    "Easter decoration",
    "One of TV's Munsters",
    "Easter bloom",
    "___-white",
    "Easter sight",
    "Pad producer",
    "Showy flower",
    "Easter ___",
    "Valley girl?",
    "Tiger ___",
    "Easter flower",
    "See 2-Down",
    "Emblem of innocence, in art",
    "Easter emblem",
    "Easter bloom",
    "Easter flower",
    "Pond sight",
    "Kind of pad"
  ],
  "idem": [
    "The same, in legal citations",
    "Latin word in a footnote",
    "Footnote word",
    "As above, in citations",
    "The same, to Caesar",
    "The same, in footnotes",
    "Latin word in legal briefs",
    "Ditto, in footnotes",
    "As previously mentioned, in bibliographies",
    "As above, in a footnote",
    "More of the same, in research papers",
    "Latin word in a quotation book",
    "Footnote word",
    "Footnote word",
    "Footnote word",
    "The same: Lat.",
    "Footnote word",
    "Same as before",
    "The same, in footnotes",
    "Footnote word",
    "Footnote word",
    "Alter ___ (another exactly the same)",
    "The same, in bibliographies",
    "Same, in footnotes",
    "The same",
    "As previously mentioned",
    "The same as above",
    "Footnote word",
    "The same, in a bibliography",
    "Alter ___ (exact duplicate)",
    "The same as before",
    "The same",
    "The same, to Caesar"
  ],
  "iten": [
    "Main lower artery, informally",
    "Major Calif.-to-Fla. route",
    "Calif.-to-Fla. route",
    "Cross-country route, informally",
    "Major thoroughfare through Houston, informally",
    "Southern hwy.",
    "Christopher Columbus Transcontinental Hwy.",
    "Major Fla.-to-Calif. route",
    "Calif.-to-Fla. hwy.",
    "Major Calif.-to-Fla. route",
    "Coast-to-coast route, informally",
    "Major artery through San Antonio",
    "L.A.-to-Jacksonville rte.",
    "Eight-state coast-to-coast rte.",
    "Major Calif.-to-Fla. route",
    "Tucson-to-New Orleans route",
    "Highway damaged by hurricane Katrina",
    "Coast-to-coast hwy.",
    "Calif.-Fla. route",
    "Route west of Jacksonville, Fla.",
    "L.A.-to-Jacksonville route",
    "Major Calif.-to-Fla. route"
  ],
  "rads": [
    "Dosimeter units",
    "X-ray units",
    "They may be high in a fallout zone",
    "Dosimeter reading",
    "CAT scan units",
    "Physics units",
    "X-ray units",
    "Dangerous units",
    "Exposure units",
    "X-ray units",
    "Units of a dangerous dosage",
    "X-ray units",
    "X-ray units",
    "Dosimeters measure them",
    "X-ray dosage units",
    "Dosage units",
    "X-ray units",
    "X-ray doses",
    "CAT scan units",
    "X-ray units",
    "Physics units also known as centigrays",
    "Units of 100 ergs per gram",
    "X-ray units",
    "Dosage units",
    "Exposure units",
    "X-ray doses",
    "Units of 100 ergs/gram",
    "Dangerous dosage units",
    "Physics units",
    "X-ray units",
    "Physics units",
    "X-ray units",
    "Dosimeters measure them"
  ],
  "bawl": [
    "Cry like a baby",
    "Turn on the waterworks",
    "Cry like a baby",
    "Experience catharsis, in a way",
    "Not just tear",
    "Turn on the waterworks",
    "Sob",
    "Not just sniffle",
    "Cry one's eyes out",
    "Cry out loud",
    "Go \"waaaah!\"",
    "Boohoo",
    "Turn on the waterworks",
    "Cry like a baby",
    "Wail",
    "Sob",
    "Sob",
    "Wail",
    "Loudly cry",
    "Cry a river",
    "Blubber",
    "Shout out",
    "Really cry",
    "Chew (out)",
    "Wail",
    "Reprimand, with \"out\"",
    "Scold, with \"out\"",
    "Cuss (out)",
    "Let out one's emotions, maybe",
    "Cry like a baby"
  ],
  "leni": [
    "Filmmaker Riefenstahl",
    "Seducer of Josef in Kafka's \"The Trial\"",
    "Director Riefenstahl",
    "Filmmaker Riefenstahl",
    "Filmmaker Riefenstahl",
    "Filmmaker Riefenstahl",
    "Director Riefenstahl",
    "Filmmaker Riefenstahl",
    "Director Riefenstahl",
    "Filmmaker Riefenstahl",
    "Filmmaker Riefenstahl",
    "Filmmaker Riefenstahl",
    "Director Riefenstahl",
    "Director Riefenstahl",
    "Director Riefenstahl",
    "Director Riefenstahl",
    "Film maker Riefenstahl"
  ],
  "yech": [
    "\"Ugh!\"",
    "\"Ick!\"",
    "\"Bleah!\"",
    "\"Not broccoli again!\"",
    "\"Eeew!\"",
    "\"Ugh!\""
  ],
  "phis": [
    "Some college Greeks",
    "Sorority letters",
    "Fraternity letters",
    "Greek letters",
    "Diameter symbols, in math",
    "Letters from Crete",
    "Greek characters",
    "Fraternity letters",
    "Letters from Greece",
    "Key letters"
  ],
  "mary": [
    "Singer ___ J. Blige",
    "\"Quite contrary\" girl of rhyme",
    "With 77-Across, red, blue and yellow",
    "Musical partner of Peter and Paul",
    "\"Bloody\" queen",
    "Creche figure",
    "\"The Wind Cries ___\" (song by 56-Across)",
    "Figure in a creche",
    "A Lincoln",
    "Miraculous Medal figure",
    "Sister of Lazarus, in the Bible",
    "Pieta figure",
    "Successor to Edward VI",
    "With 28-Down, Abe Lincoln's bride",
    "Name repeated in a nursery rhyme",
    "Pieta figure",
    "Creche figure",
    "One of the Tudors",
    "One of two English queens",
    "Tudor queen",
    "\"Quite contrary\" nursery rhyme girl",
    "George V's wife",
    "G.O.P. insider Matalin",
    "Creche figure",
    "Contrary one",
    "Sister of Lazarus",
    "Contrary girl",
    "Higgins Clark, the novelist",
    "Queen of Scots",
    "One of the Astors",
    "___Tyler Moore"
  ],
  "sics": [
    "Incites to attack, with \"on\"",
    "Unleashes (on)",
    "Turns loose (on)",
    "Unleashes (on)",
    "Sets (on)",
    "Unleashes (on)",
    "Sets (on)",
    "Sets (on)",
    "Sets (on)",
    "Unleashes",
    "Sets (on)",
    "Unleashes",
    "Canine attack commands",
    "Sets a setter on, say",
    "Sets (on)",
    "Unleashes (upon)",
    "Sets (on)",
    "Sets the dogs (on)",
    "___ on (orders to attack)",
    "Sets (on)"
  ],
  "suds": [
    "Brewski",
    "Beer, slangily",
    "Brew",
    "Brewski",
    "Soap bubbles",
    "Pub offering",
    "Brewskis",
    "Beer, slangily",
    "Lather",
    "Brewskis",
    "Brewskis",
    "Brewskis",
    "Head",
    "Some cold ones",
    "Soapy froth",
    "Head makeup",
    "Beer, slangily",
    "Root beer feature",
    "Lather",
    "Brewski",
    "Brew",
    "Brewski",
    "Brewski",
    "Brewski",
    "Brewski",
    "Beer, slangily",
    "Foam",
    "Beer",
    "Beer, informally"
  ],
  "jlaw": ["\"The Hunger Games\" actress, in tabloids"],
  "kyle": [
    "___ Reese, soldier in \"The Terminator\"",
    "\"South Park\" boy",
    "Nascar's Busch or Petty",
    "Pal of Stan on \"South Park\"",
    "\"American Sniper\" subject Chris ___",
    "\"South Park\" boy",
    "Richard Petty's racing son",
    "\"South Park\" kid",
    "MacLachlan of \"Desperate Housewives\"",
    "MacLachlan of \"Twin Peaks\"",
    "Actor MacLachlan of \"Blue Velvet\"",
    "Actor MacLachlan",
    "Actor MacLachlan",
    "MacLachlan of \"Twin Peaks\"",
    "Sportscaster Rote"
  ],
  "aceh": ["Banda ___ (2004 Indonesian tsunami site)"],
  "hajj": [
    "Pilgrim's journey",
    "Muslim pilgrimage",
    "Muslim's trek",
    "Pilgrimage to Mecca",
    "Muslim trek",
    "Muslim duty",
    "Muslim's journey"
  ],
  "hbar": [
    "Letter-shaped girder",
    "Construction piece with wide flanges",
    "Letter-shaped girder",
    "Flanged construction beam"
  ],
  "lied": [
    "Spoke with a forked tongue",
    "Told a 29-Across",
    "Set off a polygraph",
    "Told stories",
    "Told a tale",
    "Served whoppers?",
    "Told untruths",
    "Emulated Pinocchio",
    "Perjured oneself",
    "Wasn't veracious",
    "Told stories",
    "Didn't tell the truth",
    "Produced stories",
    "Told a whopper",
    "Perjured oneself",
    "Wasn't straight",
    "Didn't just mislead someone",
    "Specialized in fiction, say",
    "Wasn't truthful",
    "\"So I ___\"",
    "Told stories",
    "Schubert piece",
    "Schubert composition",
    "Came up with an invention",
    "Perjured oneself",
    "Invented things",
    "Wasn't straight",
    "Wasn't straight",
    "Wasn't true",
    "Dissembled and then some",
    "\"The Erl-King,\" for one",
    "Served up a whopper",
    "Told a whopper",
    "Wasn't straight",
    "Made stuff up",
    "Told a whopper",
    "Wasn't straight",
    "\"So I ___!\"",
    "Wasn't straight",
    "Emulated Pinocchio",
    "Wasn't straight",
    "Emulated Pinocchio",
    "Served up a whopper",
    "Wasn't honest",
    "\"So I ___\"",
    "Emulated Ananias",
    "Wasn't straight",
    "Schubert composition",
    "Falsified",
    "Made yarns",
    "Perjured oneself",
    "Stretched the truth, so to speak",
    "Told a whopper",
    "Played fast and loose with the facts",
    "Produced fiction",
    "Wasn't straight",
    "Manufactured baloney?",
    "Uttered a taradiddle",
    "Schubert composition",
    "Told a whopper",
    "Paltered",
    "Told a whopper",
    "Schubert specialty",
    "More than misled",
    "Deutschland song",
    "Emulated Ananais",
    "Cooked up stories",
    "Dissembled",
    "More than misled"
  ],
  "stks": ["Parts of a portfolio: Abbr.", "Nasdaq listings: Abbr."],
  "obes": [
    "U.K. honours",
    "U.K. honours",
    "U.K. awards",
    "Awards for J. K. Rowling and P. L. Travers: Abbr.",
    "U.K. awards"
  ],
  "atee": [
    "Do to ___",
    "Perfectly, after \"to\"",
    "Do to ___",
    "Suit to ___",
    "Suit to ___",
    "\"That suits me to ___\"",
    "Precisely, after \"to\"",
    "Fit to ___",
    "Just so, after \"to\"",
    "Done to ___",
    "Suit to ___",
    "Suit to ___",
    "To ___ (perfectly)",
    "Unerringly, after \"to\"",
    "Suit to ___",
    "Fit to finish?",
    "To ___ (precisely)",
    "Exactly, after \"to\"",
    "To ___ (just so)",
    "Done to ___",
    "Suit to ___",
    "Suit to ___",
    "Perfectly, after \"to\"",
    "Perfectly, after \"to\"",
    "Perfectly, after \"to\"",
    "Suit to ___",
    "Faultlessly, after \"to\"",
    "Exactly, after \"to\"",
    "Suit to ___",
    "Fit to ___",
    "To ___ (just so)",
    "Suit to ___",
    "To ___",
    "Suit to ___",
    "To ___ (exactly)",
    "Exactly, after \"to\"",
    "Suit to ___",
    "To ___ (perfectly)",
    "Perfectly, after \"to\"",
    "Done to ___",
    "Exactly, after \"to\"",
    "Unerringly, after \"to\"",
    "Suit to ___",
    "Suit to ___",
    "Suit to ___",
    "Have down to ___",
    "To ___ (perfectly)",
    "Just so, after \"to\"",
    "Suit to ___",
    "Just so, after \"to\"",
    "Precisely, after \"to\"",
    "Suit to ___",
    "Suit to ___",
    "To ___ (exactly)",
    "To ___ (exactly)",
    "Something might suit to this",
    "To ___ (exactly)",
    "Suit to ___",
    "To ___ (exactly)",
    "To ___",
    "Suit to ___",
    "Fit to ___",
    "Done to ___",
    "To ___",
    "Suit to ___",
    "To ___ (just so)",
    "Suit to ___",
    "Suit to ___",
    "To ___ (unerringly)",
    "Suit to ___",
    "To ___ (exactly)",
    "Fit to ___",
    "To ___ (perfectly)",
    "To ___ (exactly)",
    "To ___ (exactly)",
    "To ___ (just so)",
    "End of a fitting phrase",
    "Suit to ___",
    "Suit to ___",
    "To ___ (exactly)",
    "To ___",
    "To ___ (perfectly)",
    "To ___ (how a golfer wants to perform?)",
    "Suit to ___",
    "To ___ (precisely)",
    "To ___ (exactly)",
    "Suit to ___",
    "To___(exactly)",
    "To___(precisely)",
    "To___(just right)",
    "To ___ (precisely)",
    "Suit to ___",
    "Fit to ___",
    "To ___ (exactly)",
    "Fitting phrase ending",
    "Fit conclusion?",
    "Suit to___",
    "Suit to ___",
    "End of a fitting phrase",
    "To ___",
    "To ___ (just right)"
  ],
  "etds": [
    "Flight board figs.",
    "Airport approximations: Abbr.",
    "Some pickup info on rideshare apps: Abbr.",
    "Gate expectations, briefly?",
    "Strong winds may push them back, in brief",
    "Some flight board info",
    "Snow may push them back, for short",
    "Some sked predictions",
    "Some postings at 44-Across, for short",
    "Takeoff figs.",
    "Flight deck guesses about takeoff, for short",
    "When planes are due to take off, for short",
    "Displays near gates, briefly",
    "Airport figs.",
    "SFO postings",
    "Some airport postings, for short",
    "Terminal info, for short",
    "Airport data, for short",
    "LAX figs.",
    "Gate expectations, for short?",
    "Ballparks at J.F.K.?",
    "Flight data: Abbr.",
    "Some itinerary data, briefly",
    "LAX data: Abbr.",
    "Nos. on terminal monitors",
    "LAX postings",
    "Waiting area announcements, briefly",
    "Delays set them back: Abbr.",
    "Terminal figs.",
    "They're often moved back in airports: Abbr.",
    "RR sta. info",
    "They frequently change in airports, in brief",
    "Sked guesses: Abbr.",
    "J.F.K. postings",
    "LAX info",
    "Sked figures",
    "Passenger inquiries: Abbr.",
    "La Guardia data: Abbr.",
    "Airport info: Abbr."
  ],
  "hilt": [
    "Part of a sword",
    "Sword handle",
    "Hold it!",
    "Sword handle",
    "Foil component",
    "Cutlass part",
    "Sword handle",
    "Maximum extent",
    "Maximum extent",
    "Dagger part",
    "Weapon handle",
    "Sword handle",
    "Sword part",
    "Sword handle",
    "To the ___",
    "Fullest extent",
    "To the ___ (completely)",
    "Sword part",
    "Maximum degree",
    "To the ___ (maximally)",
    "Saber handle",
    "It sticks out of a scabbard"
  ],
  "mils": [
    "\"Cool\" amounts",
    "Units of wire thickness",
    "Thickness measures",
    "Minuscule lengths",
    "Wire measures",
    "Wire measures",
    "Units for measuring wire",
    "Wire units",
    "Wire measures",
    "Tiny measures",
    "Thickness units"
  ],
  "octo": [
    "Prefix meaning \"eight\"",
    "Eight: Prefix",
    "Numerical prefix ... or, with 62-Across, another name for this puzzle's key symbol",
    "Numerical prefix",
    "Numerical prefix",
    "Quattuor doubled",
    "Twice quattuor",
    "Numerical prefix",
    "Eight: Prefix",
    "Sex plus two, to Caesar",
    "Prefix with -genarian",
    "Eight, for starters?",
    "Prefix with -genarian",
    "Numerical prefix",
    "Modern prefix with mom",
    "Prefix with \"mom\" in 2009 news",
    "Twice tetra-",
    "Prefix with -genarian",
    "Twice tetra-",
    "Prefix with -pod",
    "Eight: Prefix",
    "Numerical prefix",
    "Prefix with mom",
    "Prefix with -pod",
    "Numerical prefix with -ber",
    "Numerical prefix",
    "Numerical prefix",
    "Eight: Prefix",
    "Twice tetra-",
    "Eight: Prefix",
    "Eight: Prefix",
    "Numerical prefix",
    "Eight: Prefix",
    "Prefix with -pod",
    "Numerical prefix",
    "Twice tetra-",
    "Prefix with -genarian",
    "Prefix with -pod",
    "Eight: Prefix",
    "Eight, for starters",
    "Numerical prefix",
    "Eight: Prefix",
    "Prefix with -pod",
    "Prefix with -pod",
    "Twice tetra-",
    "Numerical prefix",
    "VIII",
    "Prefix with -genarian",
    "Numerical prefix",
    "Quattuor doubled",
    "Half of sedecim",
    "Eight: Prefix",
    "Half of sedecim",
    "Eight: Prefix",
    "VIII, to Virgil",
    "Tetra x 2",
    "VIII, to Virgil",
    "Eight, in combinations"
  ],
  "burg": [
    "Small town",
    "Dot on the map",
    "Hamlet",
    "Little town",
    "Hamlet",
    "One-horse town",
    "Small town",
    "Walled town",
    "Town",
    "Any old town",
    "One-horse town",
    "Hamlet"
  ],
  "fide": [
    "Bona ___",
    "World chess org.",
    "Worldwide chess org.",
    "Bona ___",
    "Bona ___"
  ],
  "fund": [
    "Bankroll",
    "A philanthropist might set one up",
    "Stipend source",
    "Charitable creation",
    "Supply with money",
    "Financial adviser's suggestion",
    "It may be mutual",
    "Provide money for",
    "Part of a financial portfolio",
    "Stockbroker's suggestion",
    "Broker's suggestion",
    "It may be revolving or sinking"
  ],
  "knut": [
    "Coin in the Potterverse",
    "Bronze coin in the Harry Potter books",
    "Danish king who conquered England",
    "Unit of currency in the Harry Potter books",
    "Currency unit in Harry Potter tales",
    "Old Danish king who conquered England"
  ],
  "dona": [
    "\"___ nobis pacem\" (\"Grant us peace\": Lat.)",
    "Lady of Spain",
    "Portuguese woman's title",
    "Lisbon lady",
    "Lady of Brazil",
    "Spanish lady",
    "Spanish lady",
    "Spanish title",
    "Lady of Spain",
    "Lisbon lady",
    "\"___ Flor and Her Two Husbands\"",
    "Spanish lady",
    "Lady of Lisbon",
    "Courtesy title overseas",
    "Portuguese lady",
    "Spanish lady",
    "Portuguese title",
    "Lady of Spain",
    "With 63-Down, a New Mexico county",
    "Lady of Portugal",
    "Lady of Lisbon",
    "Lady of Spain",
    "Lady of La Mancha",
    "Spanish woman",
    "Spanish lady",
    "Spanish lady's title",
    "Lady of Brazil",
    "Lady of La Mancha",
    "Senhora",
    "\"___ Barbara\" (Gallegos novel)",
    "Honorific of Spain",
    "Flor of film"
  ],
  "mews": [
    "Pet sounds",
    "Litter noises",
    "British term for a row of houses converted from stables",
    "Litter sounds",
    "Pet sounds",
    "Kitten sounds",
    "Persian language?",
    "Cries from a litter",
    "Kitty cries",
    "Row of stables, in Britain",
    "Litter cries",
    "Catty comments?",
    "Kittens' cries",
    "Cat calls",
    "Kittens' cries",
    "Kittens' cries",
    "Kitty cries",
    "Kitty chorus",
    "Short street of converted stables",
    "British stables",
    "Catty comments",
    "Seabirds",
    "Residential alley",
    "Quaint residential street"
  ],
  "zuni": [
    "Southwest tribe or river",
    "Native of New Mexico",
    "Pueblo people of New Mexico",
    "New Mexico native",
    "Kachina doll makers",
    "Tribe associated with the Seven Cities of Cibola",
    "Tribe noted for its silver and turquoise jewelry",
    "New Mexico Indian",
    "Pueblo dweller",
    "New Mexico native",
    "Pueblo tongue",
    "Native New Mexican"
  ],
  "isps": [
    "Cos. with many connections",
    "Comcast Xfinity and others, in brief",
    "AOL and MSN, for two",
    "AOL and MSN",
    "AT&T and Comcast, for short",
    "Comcast and CenturyLink, in brief",
    "AOL and MSN",
    "Verizon FiOS and EarthLink, briefly",
    "AOL and Comcast",
    "Connections to the WWW",
    "Ways to the Web: Abbr.",
    "Cos. that offer access",
    "EarthLink and others",
    "Connection means, for short",
    "AOL and others",
    "AOL and others, in brief",
    "AOL and Road Runner: Abbr.",
    "Some surfers' choices, briefly",
    "AOL and others"
  ],
  "lank": [
    "Like hair after rain",
    "All limbs",
    "Tall and thin",
    "Tall and thin",
    "Skinny",
    "Long and lean",
    "Tall and thin",
    "Long and lean",
    "Thin",
    "Opposite of stout",
    "Rangy",
    "Tall and thin",
    "Rangy",
    "Tall and thin",
    "Long and lean",
    "Lacking any curl",
    "Lacking spring or curl, as hair",
    "Like young Abe Lincoln",
    "Hardly portly",
    "Thin",
    "Limp, as hair",
    "Like young Abe Lincoln",
    "Thin",
    "Thin",
    "Tall and slender",
    "Tall and thin",
    "Thin",
    "Thin"
  ],
  "gilt": [
    "Gold-coated",
    "Finely decorated",
    "Finished elegantly",
    "Gold-coated",
    "Like many thrones",
    "Like some statues and book spines",
    "Expensive coat?",
    "Showy coat?",
    "Illuminated, in a way",
    "Given an expensive coat",
    "Expensively decorated",
    "___-edged",
    "Gold-coated",
    "Coated with gold",
    "Expensively finished",
    "With gold trim",
    "Prettily decorated",
    "Having a golden touch"
  ],
  "secy": [
    "Cabinet position: Abbr.",
    "Cabinet head: Abbr.",
    "Cabinet member: Abbr.",
    "Cabinet head: Abbr.",
    "Cabinet position: Abbr.",
    "Telephone answerer: Abbr.",
    "Cabinet head: Abbr.",
    "Dept. head",
    "Pres. appointee",
    "Cabinet member: Abbr.",
    "Certain asst.",
    "Pres. appointee"
  ],
  "alyn": [
    "Kirk ___, first actor to play Superman on the big screen",
    "Kirk ___, first actor to play Superman on screen, 1948",
    "Kirk ___, first actor to play Superman on the big screen"
  ],
  "cava": [
    "See 31-Down",
    "Vena ___ (major vessel)",
    "Spanish sparkling wine",
    "Vena ___ (passage to the heart)",
    "Vena ___ (blood line to the heart)",
    "Anatomical term that's Latin for \"hollow\"",
    "Vena ___",
    "Vena ___ (vessel to the heart)"
  ],
  "atie": [
    "End in ___ (be deadlocked)",
    "End in ___",
    "End in ___",
    "End in ___ (require overtime)",
    "\"___ is like kissing your sister\" (sports adage)",
    "Play to ___",
    "End in ___",
    "End in ___",
    "Even, after \"in\"",
    "Play to ___",
    "Play to ___",
    "End in ___ (finish evenly)",
    "End in ___",
    "Play to ___",
    "End in ___ (come out even)",
    "Play to ___",
    "End in ___",
    "In ___ (even)",
    "End in ___ (come out even)",
    "Play to ___",
    "End in ___ (draw)",
    "Break ___ (go into sudden death)",
    "End in ___",
    "Play to ___",
    "In ___ (even)",
    "End in ___",
    "End in ___",
    "In ___ (even)",
    "Even, after \"in\"",
    "In ___ (even)",
    "Play to ___",
    "End in ___ (require overtime)",
    "In ___ (even)",
    "Play to ___",
    "Force ___ (draw)",
    "Play to ___",
    "End in ___",
    "End in ___",
    "Play to ___",
    "End in ___",
    "Even, after \"in\"",
    "What some games end in",
    "Play to ___ (deadlock)",
    "End in ___",
    "End in ___ (come out even)",
    "End in ___ (draw)"
  ],
  "pish": [
    "\"Poppycock!\"",
    "\"Nonsense!\"",
    "\"Hogwash!\"",
    "\"That's ridiculous!\"",
    "\"Fiddlesticks!\"",
    "\"That's hogwash!\"",
    "\"Baloney!\"",
    "Word of contempt",
    "Disdainful remark",
    "\"Oh, nonsense!\"",
    "\"Nonsense!\"",
    "\"Nonsense!\"",
    "Tut-tut"
  ],
  "hump": [
    "Distinctive part of a zebu",
    "Distinctive part of a bison",
    "Zebu feature",
    "Something to get over",
    "Camel feature",
    "Camel feature",
    "Good thing to get over",
    "___ Day (Wednesday)"
  ],
  "puce": [
    "Color akin to eggplant",
    "Relative of sepia",
    "Brownish purple",
    "Purplish red",
    "Brownish purple",
    "Brownish purple",
    "Purplish shade",
    "Color whose name is French for \"flea\"",
    "Dark purple",
    "Purple shade",
    "One of two school colors (along with heliotrope) of New York's Purchase College",
    "Color close to cranberry",
    "Purple shade",
    "Purplish",
    "Purple shade",
    "Purple shade",
    "Brownish purple",
    "Brownish purple",
    "Dark red",
    "Purple shade",
    "Dark purple",
    "Purplish brown",
    "Eggplant color",
    "Purplish brown",
    "Color also called \"eureka red\"",
    "Brownish purple"
  ],
  "wwii": [
    "It had theaters in Europe and the Pacific, for short",
    "Depression follower, for short",
    "Conflict with the European Theater of Operations, for short",
    "\"The Greatest Generation\" subj.",
    "When the O.S.S. was formed",
    "Hitler started it: Abbr.",
    "1940's conflict, for short",
    "What V-J Day ended",
    "Period of rationing: Abbr.",
    "What some vets recall: Abbr.",
    "Chapter in 20th-century hist.",
    "V-J Day ended it",
    "Axis/Allies conflict: Abbr.",
    "E.T.O. period",
    "See 60 Across"
  ],
  "terp": [
    "Maryland collegian, informally",
    "Maryland athlete, for short",
    "U. of Md. player",
    "\"Fight, fight, fight for Maryland!\" singer, familiarly",
    "Maryland athlete, for short",
    "Maryland athlete, informally",
    "Maryland athlete, for short",
    "Comcast Center hoopster",
    "One singing \"Fight, fight, fight for Maryland!\"",
    "Comcast Center athlete, briefly",
    "U. of Maryland athlete",
    "A.C.C. athlete",
    "U. of Maryland player",
    "Maryland athlete, for short",
    "A.C.C. athlete",
    "Maryland player, informally",
    "College Park player, briefly",
    "Maryland collegian",
    "College Park player, informally",
    "Maryland athlete, for short",
    "Maryland athlete, for short",
    "Maryland athlete, for short",
    "Maryland athlete, for short",
    "Maryland player, for short",
    "University of Maryland player",
    "Maryland athlete"
  ],
  "mira": [
    "Oscar winner Sorvino",
    "___ Nair, \"Mississippi Masala\" director",
    "Sorvino of \"Mighty Aphrodite\"",
    "Red giant in the constellation Cetus",
    "Actress Sorvino",
    "Sorvino of \"Mighty Aphrodite\"",
    "Red giant in Cetus",
    "Red giant in the constellation Cetus",
    "Actress Sorvino",
    "Actress Sorvino",
    "Oscar winner Sorvino",
    "Major star of 2-Down",
    "\"Check it out!,\" in Chihuahua",
    "Actress Sorvino",
    "Sorvino of \"Mighty Aphrodite\"",
    "Sorvino of \"Mighty Aphrodite\"",
    "Actress Sorvino",
    "Binary star in Cetus",
    "Sorvino of \"Mighty Aphrodite\"",
    "Star in Cetus",
    "Sorvino of \"Mighty Aphrodite\"",
    "Red giant in Cetus",
    "Sorvino of \"Mighty Aphrodite\"",
    "Actress Sorvino",
    "Sorvino of \"Mighty Aphrodite\"",
    "Oscar winner Sorvino",
    "Oscar winner Sorvino",
    "Actress Sorvino",
    "\"Mighty Aphrodite\" co-star Sorvino",
    "Actress Sorvino",
    "\"Look!,\" in Lima",
    "Variable star in Cetus",
    "Red giant in Cetus",
    "Sorvino of \"Mighty Aphrodite\"",
    "Actress Sorvino",
    "Oscar winner Sorvino",
    "Actress Sorvino",
    "Oscar-winner Sorvino",
    "Red giant in Cetus",
    "Actress Sorvino",
    "\"Mississippi Masala\" director ___ Nair",
    "\"Look!\" in La Mancha",
    "Red giant in the constellation Cetus",
    "Actress Sorvino",
    "Star in Cetus",
    "Red giant in the constellation Cetus"
  ],
  "alvy": [
    "___ Singer (\"Annie Hall\" protagonist)",
    "Woody's \"Annie Hall\" role"
  ],
  "piny": [
    "Like some car air fresheners",
    "Like potpourri, sometimes",
    "Like some air fresheners",
    "Coniferous"
  ],
  "pink": [
    "Like breast cancer awareness ribbons",
    "Rosy",
    "\"Get the Party Started\" singer",
    "Flamingo's color",
    "Birth announcement color",
    "It can be shocking",
    "Medium-rare steak color",
    "Color for baby girls, traditionally",
    "Like the sky at dawn or sunset",
    "Baby girls may be dressed in it",
    "\"___ Cadillac\" (1988 hit)",
    "Flamingo color",
    "Hot ___",
    "Not well-done",
    "This is sometimes shocking",
    "Lightly cooked",
    "Nursery color"
  ],
  "vent": [
    "Part of a volcano",
    "Clothes dryer attachment",
    "Let off some steam",
    "Go on a tweetstorm, say",
    "Let it all out",
    "Let it all out",
    "Magma conduit",
    "Jacket feature",
    "Blow off steam",
    "Scream one's head off",
    "Volcano's opening",
    "Let it all out",
    "Hole in the wall",
    "Let it all out",
    "Blowout locale?",
    "Let off steam",
    "Blow off",
    "See 36-Across",
    "Air outlet",
    "A/C opening",
    "Blow off some hot air",
    "Blow off steam",
    "Means of escape",
    "Rant and rave",
    "Let out",
    "Blow off steam",
    "Opening",
    "Let it all out",
    "Scream for a while",
    "Dryer outlet",
    "Let off steam",
    "Jacket part",
    "Blazer's detail",
    "Hole in the wall?",
    "Blow off steam",
    "Blow off steam",
    "Blow off steam?",
    "Let off steam",
    "Air outlet",
    "Garment slit",
    "Hole in the wall?",
    "Outlet",
    "Air out"
  ],
  "mick": [
    "Head Stone?",
    "Head Stone",
    "Dundee of \"Crocodile Dundee\"",
    "Rock's Jagger"
  ],
  "keds": [
    "Converse competitor",
    "Inexpensive sneakers brand",
    "Sneakers that come in over a hundred designs",
    "Footwear donned on camera by Mr. Rogers",
    "Classic canvas-top sneakers",
    "Footwear brand since 1916",
    "Some gym shoes",
    "Sneaker brand",
    "Sneakers since 1916",
    "Alternative to 33-Across",
    "Sneaker brand",
    "Sneakers brand",
    "\"The original sneaker\" sloganeer",
    "Some colorful sneakers",
    "Some sneakers",
    "Some playground shoes",
    "1917 shoe debut",
    "Popular sneakers",
    "Some sneakers",
    "Classic sneakers",
    "Some sneakers",
    "Some sneakers",
    "Some sneakers",
    "Popular sneakers",
    "Adidas alternative",
    "Reebok competitor",
    "Sneakers brand",
    "Product once touted with the slogan \"They feel good\"",
    "Popular sneakers",
    "Sneakers brand",
    "Popular sneakers",
    "Shoes introduced by the United States Rubber Co.",
    "Converse competitor",
    "Its slogan was once \"They feel good\"",
    "Reebok alternative",
    "Sneaker brand"
  ],
  "yoyo": [
    "Toy on a string",
    "Quickly change one's mind back and forth",
    "Toy on a string",
    "Dimwit",
    "Show extreme instability",
    "Dum-dum",
    "Kook",
    "Fluctuate wildly",
    "Vacillate",
    "What some dieters do",
    "Ditz",
    "Performer of tricks?",
    "Numbskull",
    "Idiot",
    "Dummy",
    "It has its ups and downs",
    "Toy that does tricks",
    "Toy you can do tricks with",
    "One being put to sleep?",
    "Swing wildly",
    "Fad item of 1962",
    "Jerk",
    "Fluctuate",
    "Unstable type",
    "Jerk",
    "Toy that might go \"around the world\"",
    "Toy on a string",
    "Jerk",
    "Dingbat",
    "It can go around the world",
    "It's used to walk the dog",
    "Goofball",
    "Something to swing on a string",
    "Stringed toy",
    "Ma with a bow",
    "Toy that does tricks",
    "Toy that \"sleeps\"",
    "Toy that does tricks",
    "Jerk",
    "Go up and down rapidly",
    "It has its ups and downs",
    "Subject of a 1930's fad",
    "Toy that does tricks",
    "Toy on a finger",
    "It can be used to walk the dog",
    "Fluctuate wildly",
    "Goofy guy",
    "Toy making a comeback",
    "Ding-a-ling",
    "Toy that goes \"around the world\"",
    "It comes easily to hand",
    "It's used to walk the dog",
    "It has its ups and downs",
    "Show wild instability",
    "Fad item of 1961",
    "\"Space cadet\"",
    "It may come easily to hand",
    "Dork",
    "Fad item of '61",
    "It comes easily to hand",
    "Fluctuate repeatedly",
    "It can go round the world",
    "It may walk the dog",
    "Fluctuate wildly",
    "Vacillate",
    "Vacillate",
    "Tom Smothers amusement",
    "Tom Smothers plaything"
  ],
  "ynez": [
    "California's Santa ___ Mountains",
    "California's Santa ___ Mountains",
    "California's Santa ___ Mountains",
    "Santa ___ Valley (winegrowing region)",
    "California's Santa ___ Valley",
    "Santa ___, Calif.",
    "California's Santa ___ Valley",
    "California's Santa ___ Valley"
  ],
  "fibs": [
    "Small prevarications",
    "Minor inventions",
    "Twists the truth",
    "Bits of baloney",
    "Little lies",
    "Small inventions",
    "Little lies",
    "Whopper juniors?",
    "Whopper juniors?",
    "Taradiddles",
    "Makes stuff up",
    "Certain inventions",
    "Taradiddles",
    "Whopper juniors"
  ],
  "zags": [
    "Swerves back",
    "Veers the other way",
    "Moves unpredictably",
    "Suddenly changes direction",
    "Sudden turns",
    "Navigates a switchback, in part",
    "Slalomer's moves",
    "Turns the other way",
    "Takes a sharp turn",
    "Turns abruptly",
    "Takes another direction"
  ],
  "jess": [
    "The new girl of Fox's \"New Girl\"",
    "The new girl on Fox's \"New Girl\"",
    "The new girl on TV's \"New Girl\"",
    "Boxer Willard defeated by Jack Dempsey for the world heavyweight title",
    "1910s heavyweight champ ___ Willard",
    "___ Willard, heavyweight champ dethroned by Jack Dempsey",
    "Old heavyweight champ Willard",
    "Heavyweight champ Willard",
    "1910's heavyweight champ Willard",
    "Jack beat him for the heavyweight title, 1919",
    "TV sleuth Fletcher, to friends",
    "Boxer Willard",
    "Barker of 40's films",
    "1910's boxing champ Willard",
    "Heavyweight champ Willard"
  ],
  "luce": [
    "Henry who founded Life",
    "Man of Fortune",
    "Henry who made a Fortune?",
    "Time founder Henry",
    "Time starter",
    "Time-honored name?",
    "Time-honored name",
    "Life's founder",
    "Time-honored name?",
    "A head of Time",
    "\"Kiss the Boys Goodbye\" writer",
    "\"Time\" founder",
    "Time co-founder",
    "Politico Clare Boothe ___",
    "?ôThe Women?ö playwright",
    "\"Stuffed Shirts\" author",
    "Time founder"
  ],
  "amas": [
    "One of a Latin trio",
    "Part of a Latin trio",
    "Reddit Q&A sessions, briefly",
    "You love: Lat.",
    "Good word to know if you love Latin?",
    "Grammy alternatives voted on by the public, for short",
    "You love, to Livy",
    "One of a Latin trio",
    "Part of a Latin 101 conjugation",
    "Latin \"you love\"",
    "Amo, ___, amat ...",
    "Latin 101 verb",
    "Part of a Latin exercise",
    "Second of a Latin trio",
    "Second of a Latin 101 trio",
    "Latin trio member",
    "Amo, ___, amat",
    "Domitian's \"you love\"",
    "Amo, ___, amat",
    "Member of a Latin trio",
    "\"You love,\" to Livy",
    "Part of a famous conjugation",
    "Latin 101 word",
    "Latin lesson word",
    "Part of a Latin 101 conjugation",
    "Amo, ___, amat",
    "Latin 101 verb",
    "Second in a Latin series",
    "Latin 101 word",
    "Amo, ___, amat ...",
    "Latin 101 verb",
    "Amo follower",
    "Latin 101 verb",
    "One of a Latin trio",
    "One of a Latin trio",
    "Latin trio part",
    "Second in a classical trio",
    "Latin trio part",
    "Latin 101 word",
    "Latin 101 verb",
    "Part of a Latin trio",
    "Latin lesson word",
    "Amo, ___, amat (Latin trio)",
    "Latin trio member",
    "Part of a classical trio",
    "Conjugation lesson word",
    "One of a Latin trio",
    "Amo, ___, amat (Latin exercise)",
    "One of a Latin trio",
    "Latin I word",
    "Amo, ___, amat (Latin practice)",
    "One of a Latin trio",
    "Latin 101 verb",
    "Latin 101 word",
    "You love, to Livy",
    "One of a Latin trio",
    "Part of a Latin I trio",
    "Part of conjugation practice",
    "Amo, ___, amat",
    "Latin I word",
    "Latin 101 verb",
    "Amo, ___, amat",
    "Conjugation bit",
    "\"Amo, ___, I love a lass\"",
    "Latin I word",
    "Amo, ___, amat",
    "\"Amo, ___, I love a lass...\""
  ],
  "trib": [
    "Chi-town paper, with \"the\"",
    "Chicago paper, informally",
    "Chicago daily, informally",
    "Chicago daily, familiarly",
    "Oakland paper, for short",
    "Chi-town daily",
    "Chicago paper, briefly, with \"the\"",
    "Common newspaper nickname",
    "Windy City paper, with \"the\"",
    "Windy City daily, with \"the\"",
    "Oakland daily, for short",
    "Old New York paper, for short",
    "Chicago daily, briefly, with \"the\"",
    "\"Lou Grant\" paper, with \"the\"",
    "Tampa paper, briefly, with \"the\"",
    "Chi-town read, with \"the\"",
    "\"Lou Grant\" paper, with \"the\"",
    "Oakland paper, informally",
    "Chi-town paper, with \"the\"",
    "\"Lou Grant\" paper, for short",
    "Chi-town daily, with \"the\"",
    "Old New York paper, for short",
    "Salt Lake City daily, briefly, with \"the\"",
    "\"Lou Grant\" newspaper, with \"the\"",
    "Chicago paper, for short",
    "Chicago daily, with \"the\"",
    "Old Big Apple paper, with \"the\"",
    "\"Lou Grant\" paper, with \"the\"",
    "Common newspaper nickname",
    "Chi-town paper",
    "Tampa paper, for short, with \"The\"",
    "Chicago paper, familiarly, with \"the\"",
    "Chicago paper, for short, with \"the\"",
    "Chicago daily, familiarly",
    "Chicago paper, briefly",
    "Chicago paper, familiarly",
    "Oakland paper, with \"The\"",
    "Lou Grant's paper, in brief",
    "Chi paper, with \"The\"",
    "Windy City paper, with \"The\"",
    "Tampa paper, familiarly, with \"the\"",
    "\"Lou Grant\" paper, with \"The\"",
    "Former Big Apple newspaper, with \"The\"",
    "Newspaper nickname"
  ],
  "kink": [
    "Unusual sexual preference",
    "Pain in the neck",
    "Glitch",
    "Twist, as in a chain",
    "Twist",
    "Tight curl",
    "Hose problem",
    "Neck problem",
    "Imperfection",
    "Idiosyncrasy",
    "Twist"
  ],
  "karo": [
    "Corn syrup brand",
    "Corn syrup brand",
    "Corn syrup brand",
    "Syrup brand used in making pecan pie",
    "Big name in corn syrup",
    "Corn syrup brand",
    "Corn syrup brand",
    "Corn syrup brand",
    "Corn syrup brand",
    "Syrup brand",
    "Syrup brand",
    "Corn syrup brand",
    "Syrup brand",
    "Popular syrup",
    "Corn syrup brand",
    "Corn syrup brand",
    "Big name in corn syrup",
    "Syrup brand",
    "Syrup brand"
  ],
  "mede": [
    "Ancient Iranian",
    "Ancient Iranian",
    "Ancient Persian",
    "Ancient Assyrian foe",
    "Parthian predecessor",
    "Dweller in ancient Persepolis",
    "Subject of King Deioces",
    "Ancient Persian",
    "Member of an extensive empire of the seventh century B.C.",
    "One subjugated by Cyrus the Great",
    "Ancient Persian",
    "Ancient dweller in present-day Kurdistan",
    "Ancient denizen along the Caspian",
    "Subject of Cyrus the Great",
    "Ancient dweller of modern Iran",
    "Subject of King Cyaxares",
    "Inhabitant of ancient Persia",
    "Ancient dweller in modern Azerbaijan",
    "Ancient Iranian",
    "Persian's foe",
    "Assyrian foe",
    "\"One man's ___ is another man's Persian\"",
    "Assyrian's foe",
    "Subject of Cyaxares",
    "Ancient Persian",
    "Ancient Iranian",
    "Ancient Iranian"
  ],
  "ntsb": [
    "Accident-investigating org.",
    "Accident investigation org.",
    "Accident investigation org.",
    "Grp. called in when things go off the rails?",
    "Crash-probing agcy.",
    "Federal investigative grp.",
    "Accident-investigating agcy.",
    "Accident investigator, for short",
    "Crash-probing agcy.",
    "Accident investigator, for short",
    "Investigator of many accidents, for short",
    "Pipeline accident investigator: Abbr.",
    "Crash-investigating org.",
    "Wreck checker: Abbr.",
    "Wreck-checking org.",
    "Crash-probing agcy.",
    "It may delve into a derailment: Abbr.",
    "Fed. accident investigator",
    "Crash-probing agcy."
  ],
  "lirr": [
    "Train between N.Y.C. and Montauk",
    "Train to N.Y.C.",
    "Line to Penn Sta.",
    "Line to Jamaica in N.Y.C.",
    "Way out of N.Y.C.",
    "Line out of N.Y.C.",
    "Line ending at Penn Sta.",
    "Train line to Penn Sta.",
    "Line to Penn Sta.",
    "Line from Penn Sta.",
    "One way to N.Y.C.'s Penn Sta.",
    "Line to Penn Sta.",
    "Line to Penn Sta.",
    "Way into N.Y.C.",
    "Penn Station inits.",
    "One way to the Hamptons, for short",
    "Line into N.Y.C.",
    "Commuters' line to N.Y.C.",
    "Penn Station abbr.",
    "Penn Station inits.",
    "Line to Penn Station",
    "One way to Southampton: Abbr.",
    "N.Y. commuter line",
    "Transportation to N.Y.C.",
    "Way to get to N.Y.C.",
    "Penn Station initials",
    "Transportation to N.Y.C.",
    "N.Y. commuter's transport",
    "Line to the Hamptons, for short"
  ],
  "gawp": [
    "Stare slack-jawed",
    "Stare slack-jawed",
    "Stare in astonishment",
    "Stare with an open mouth",
    "Stare stupidly"
  ],
  "tuck": [
    "Hide, as shirttails, with \"in\"",
    "Cosmetic procedure",
    "Diving position",
    "Legendary friar"
  ],
  "drno": [
    "Title film villain whose first name is Julius",
    "Old Bond rival",
    "Title 1962 film villain",
    "Movie villain whose first name is Julius",
    "Villainous resident of Crab Key island",
    "First James Bond film",
    "1962 007 villain",
    "Fictional villain whose given name is Julius",
    "Film villain with prosthetic hands",
    "Movie that introduced the line \"Bond, James Bond\"",
    "First James Bond movie",
    "Fictional character with steel pincers for hands",
    "Early Bond foe",
    "Bond villain",
    "Film resident of Crab Key Island",
    "1958 spy novel set in Jamaica",
    "Character inspired by Fu Manchu",
    "Film villain with prosthetic hands",
    "James Bond's film debut",
    "Movie villain who sought to disrupt a space launch",
    "1962 action film set in Jamaica",
    "Villain in the title of a James Bond book",
    "Crab Key villain of book and film",
    "1962 title film character played by Joseph Wiseman",
    "Fictional villain whose given name is Julius",
    "Fleming supervillain",
    "Early James Bond foe",
    "1962 film set partly on Crab Key",
    "Early 007 foe",
    "Literary preceder of \"Goldfinger\"",
    "1962 hit film whose climax is on Crab Key island",
    "Villain who says \"That's a Dom Perignon '55. It would be a pity to break it\"",
    "Early Bond foe",
    "Early Bond baddie",
    "Movie character whose first name is Julius",
    "Movie with the opening line \"I admire your courage, Miss ?à?\"",
    "First 007 film",
    "007 foe",
    "First 007 film",
    "Villain at Crab Key fortress",
    "First 007 film",
    "Early Bond foe",
    "Villain played by Joseph Wiseman",
    "Bond foe",
    "1962 Joseph Wiseman title role",
    "007 foe",
    "Villain played by Joseph Wiseman",
    "Bond baddie",
    "Initial Bond offering?",
    "First 007 flick",
    "Ian Fleming villain",
    "First Bond flick",
    "Bond foe",
    "Villain at Crab Key",
    "Film villain played by Joseph Wiseman",
    "Bond classic",
    "The first James Bond film",
    "1962 film set in Jamaica",
    "1962 Joseph Wiseman role",
    "Noted Joseph Wiseman role",
    "Bond foe",
    "Fictional Chinese scientist",
    "Crab Key fortress villain",
    "Ian Fleming title",
    "Fleming villain",
    "Bond adversary",
    "First Bond flick",
    "Bond foe",
    "1962 film villain",
    "1962 Bond villain"
  ],
  "sulk": [
    "Pout",
    "Show signs of a sore loser",
    "Sit and mope",
    "Show petulance",
    "Act the sore loser, say",
    "Be in a pet",
    "Be ill-humored",
    "Be a sourpuss",
    "Resist cheerfulness",
    "Display of displeasure",
    "Be ill-humored"
  ],
  "yond": [
    "Location of Cassius, who \"has a lean and hungry look\"",
    "O'er there",
    "Over there, to bards",
    "In the distance, poetically",
    "Over there, poetically",
    "\"___ Cassius has a lean ...\": Shak.",
    "Over there, poetically",
    "Over thar",
    "Over there, archaically"
  ],
  "whey": [
    "Fare for Little Miss Muffet",
    "Curds and ___",
    "Curds' partner in a nursery rhyme",
    "Part of Miss Muffet's meal",
    "Curds and ___",
    "Cheesemaking byproduct",
    "Byproduct of cheesemaking",
    "Miss Muffet spillage",
    "Fare for Miss Muffet",
    "Treat for Little Miss Muffet"
  ],
  "anas": [
    "Anecdotal collections",
    "Santa ___ (some winds)",
    "Santa ___ (some winds)",
    "Santa ___ (weather phenomena)",
    "Santa ___ (weather phenomena)",
    "Santa ___ (some winds)",
    "\"___ Story\" (2007 Jenna Bush book)",
    "Literary olios",
    "Santa ___ (hot winds)",
    "Santa ___ (hot desert winds)",
    "Santa ___ (desert winds)",
    "\"___ Story: A Journey of Hope\" (Jenna Bush best seller)",
    "Biographers' collections",
    "Santa ___ (hot California winds)",
    "Miscellanies",
    "Literary collections",
    "Santa ___ (hot winds)",
    "Santa ___ (meteorological phenomena)",
    "Literary olios",
    "Santa ___ (hot winds)",
    "Santa ___ (some winds)",
    "Santa ___ (hot winds)",
    "Anthologies",
    "Literary olios",
    "Comic Gasteyer and others",
    "Santa ___ (hot winds)",
    "Literary collections",
    "Miscellanies",
    "Collections of quotations",
    "Santa ___ (hot winds)",
    "Literary collections",
    "Miscellanies",
    "Anthologies",
    "Collections of anecdotes",
    "Literary olios",
    "Collections",
    "Literary olios",
    "Collector's items",
    "Literary olios",
    "Table-talk collections",
    "Common duck genus",
    "Literary garlands"
  ],
  "bait": [
    "Lure",
    "Chum, e.g.",
    "Bug on a hook, maybe",
    "Worms and flies",
    "Worms for fishing",
    "Cheese, for some",
    "Worm or fly",
    "Entice",
    "Can of worms?",
    "Fisherman's purchase",
    "*Old West robber",
    "Worm on a hook, e.g.",
    "Worms, to a fisherman",
    "Switch's partner",
    "Trout's temptation",
    "Can of worms, say",
    "Worms, in fishing",
    "Temptation",
    "Worms and such, to a fisherman",
    "Chum, at sea",
    "Setup for a switch",
    "Cheese in a mousetrap",
    "Worms, to a fisherman",
    "Worms in a can, e.g.",
    "Worms, for a fisherman",
    "Chum, e.g.",
    "Provoke",
    "Wrigglers, to a fisherman",
    "Worms, e.g.",
    "Lure",
    "Hook attachment",
    "Tempt",
    "Flies, maybe",
    "Cheese in a mousetrap, e.g.",
    "Lure",
    "Hook worm",
    "Entice",
    "Tempting food",
    "Can of worms?",
    "Can of worms, maybe",
    "Grub, e.g.",
    "Switch's partner",
    "Flies and such",
    "Switch's partner",
    "Tease",
    "Fisherman's bucketful",
    "Entice",
    "Prepare, as a hook",
    "Switch's partner",
    "Torment",
    "Night crawlers, e.g.",
    "Fish food",
    "Enticement",
    "Switch's partner",
    "Cheese in a mousetrap",
    "Switch's partner"
  ],
  "bway": [
    "Spot to buy tix in N.Y.C.",
    "Tony's place, for short?",
    "Street through Times Sq.",
    "N.Y.C. theater district, for short",
    "N.Y.C. theater area"
  ],
  "isno": [
    "\"Do or do not. There ___ try\": Yoda",
    "\"Money ___ object\"",
    "\"My reply ___\" (Magic 8 Ball answer)",
    "\"There ___ 'I' in 'team'\"",
    "\"There ___ there there\"",
    "\"There ___ spoon\" (\"The Matrix\" line)",
    "\"There ___ 'I' in 'team'\"",
    "\"This ___ joke!\"",
    "\"The answer ___\"",
    "\"This ___ joke!\"",
    "\"That ___ excuse\"",
    "\"But there ___ joy in Mudville ?à\"",
    "\"The answer ___!\"",
    "\"Ignorance ___ excuse\"",
    "\"Ignorance ___ excuse\"",
    "\"Ignorance ___ excuse\"",
    "\"Money ___ object\"",
    "\"That ___ excuse!\"",
    "\"Money ___ object\"",
    "\"Money ___ object!\"",
    "\"There ___ excuse!\"",
    "\"This ___ laughing matter!\"",
    "\"Money ___ object!\""
  ],
  "hirt": [
    "Jazzman Al",
    "Trumpeter Al",
    "\"Java\" trumpeter Al",
    "\"The Green Hornet\" trumpeter",
    "\"Honey in the Horn\" trumpeter",
    "\"Sugar Lips\" trumpeter",
    "\"The Green Hornet\" trumpeter",
    "Trumpeter on the \"Kill Bill\" soundtrack",
    "\"Our Man in New Orleans\" jazzman",
    "\"Java\" blower",
    "\"Honey in the Horn\" jazzman",
    "?ôJava?ö trumpeter Al",
    "\"Java\" player Al",
    "\"Sugar Lips\" jazzman",
    "\"Java\" man",
    "\"Java\" man",
    "\"Cotton Candy\" trumpeter",
    "Big name in Dixieland",
    "\"Java\" trumpeter",
    "Al from New Orleans",
    "Trumpeter Al",
    "Trumpeter Al",
    "\"Cotton Candy\" trumpeter",
    "\"Cotton Candy\" trumpeter",
    "\"Java\" player Al",
    "Trumpeter Al"
  ],
  "vend": [
    "What soda and snack machines do",
    "Peddle",
    "Sell",
    "Sell",
    "Sell",
    "Hawk on the street",
    "Sell",
    "Hawk",
    "Sell",
    "Deal in",
    "Market",
    "Peddle",
    "Sell",
    "Move merchandise",
    "Sell",
    "Peddle",
    "Peddle",
    "Deal in",
    "Sell",
    "Sell",
    "Dispose of",
    "Work in the bleachers",
    "Retail",
    "Market",
    "Sell"
  ],
  "eero": [
    "Gateway Arch designer Saarinen",
    "Architect Saarinen",
    "First name in design",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Designer Saarinen",
    "First name in architecture",
    "Designer Saarinen",
    "A Saarinen",
    "One of the Saarinens",
    "Dulles airport designer Saarinen",
    "First name in industrial design",
    "The younger Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "First name in design",
    "Architect Saarinen",
    "Architect Saarinen",
    "Eric, in Finland",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Aarnio of furniture",
    "Architect Saarinen",
    "___ Aarnio, innovative furniture designer",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Olympic gold-medal pentathlete Lehtonen",
    "Architect Saarinen",
    "Interior designer Aarnio",
    "Architect Saarinen",
    "First name in design",
    "Architect Saarinen",
    "Finnish pentathlete Lehtonen",
    "Architect Saarinen",
    "Tulip chair designer Saarinen",
    "Chair designer Aarnio",
    "One of the Saarinens of Finland",
    "Architect Saarinen",
    "First name in architecture",
    "Architect Saarinen",
    "First name in architecture",
    "Architect Saarinen",
    "Architect Saarinen",
    "First name in design",
    "Architect Saarinen",
    "Innovative chair designer Aarnio",
    "Innovative furniture designer Aarnio",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Eliel Saarinen's son",
    "First name in architecture",
    "Eliel?Æs architect son",
    "Finnish painter Jarnefelt",
    "Architect Saarinen",
    "Architect Saarinen",
    "Saarinen of design",
    "Architect Saarinen",
    "Architect Saarinen",
    "Saarinen of Finland",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "First name in architecture",
    "Architect Saarinen",
    "First name in architecture",
    "Architect Saarinen",
    "First name in architecture",
    "First name in architecture",
    "First name in design",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "First name in architecture",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Contemporary of Alvar",
    "A Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Finnish cross-country gold medalist Maentyranta",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "First name in architecture",
    "First name in architecture",
    "Architect Saarinen",
    "Gateway Arch-itect, to friends",
    "Architect Saarinen",
    "A Saarinen",
    "A Saarinen",
    "Finnish skiing gold medalist Maentyranta",
    "Architect Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Finnish skiing gold medalist Mantyranta",
    "Architect Saarinen",
    "Mr. Saarinen",
    "Architect Saarinen",
    "Mr. Saarinen",
    "Architect Saarinen",
    "Architect Saarinen",
    "Mr. Saarinen",
    "First name in architecture",
    "Mr. Saarinen",
    "Eliel's son",
    "Architect Saarinen",
    "Architect Saarinen",
    "Mr. Saarinen"
  ],
  "dnas": [
    "Forensic IDs",
    "Some lab samples",
    "Forensic IDs",
    "Strands of biology",
    "Genetic strands",
    "Double-helix molecules",
    "Test materials, in brief",
    "Geneticist's studies",
    "Genetic materials"
  ],
  "zits": [
    "Concerns for a dermatologist, informally",
    "Dreaded dermal development",
    "Jerry Scott/Jim Borgman teen comic strip",
    "Some get them in their teens",
    "Bad marks for a high schooler?",
    "Jerry Scott/Jim Borgman comic",
    "Teens might try to hide them",
    "Bad marks in high school, maybe"
  ],
  "duet": [
    "Song that can't be sung alone",
    "What it takes two to do",
    "Number for two",
    "Soprano + tenor, maybe",
    "Something no one can sing?",
    "Sondheim's \"It Takes Two,\" e.g.",
    "Number for two",
    "1982's \"Ebony and Ivory,\" e.g.",
    "1965's \"I Got You Babe,\" e.g.",
    "Twosome",
    "What a couple of people can play",
    "Certain recital piece",
    "Offenbach's \"Belle nuit, o nuit d'amour,\" e.g.",
    "\"I Got You Babe,\" e.g.",
    "Musical piece for two",
    "Piece played by a pair",
    "Number with two",
    "Puccini's \"O Mimi, tu piu non torni\" e.g.",
    "\"Beauty and the Beast,\" e.g.",
    "Shared air",
    "Performing pair",
    "Something one can never do",
    "Performance that takes a second",
    "Any Sonny and Cher song",
    "\"Say Say Say,\" say",
    "Two-player activity",
    "A two-piece?",
    "The Everly Brothers, e.g.",
    "One can't do this",
    "Irving Berlin's \"You're Just in Love,\" e.g.",
    "It may call for four hands",
    "Song for two",
    "Collaborative work",
    "Donizetti's \"Tornami a dir che m'ami,\" e.g.",
    "\"You Don't Bring Me Flowers,\" e.g.",
    "Two-piece?",
    "Verdi's \"Fu la sorte dell' armi,\" e.g.",
    "Tune for two",
    "You can't do it alone",
    "The Carpenters, e.g.",
    "\"Heart and Soul,\" to young pianists",
    "Tuneful twosome",
    "\"Up Where We Belong,\" for one",
    "Puccini's \"Flower ___\"",
    "Piano-violin piece",
    "Piece for two",
    "Music for two",
    "Carpenters' work?",
    "What two can coo",
    "Twosome",
    "Something one can't do",
    "\"Ma mere, je la vois,\" in \"Carmen\"",
    "Song for the Everly Brothers",
    "\"Parigi, ocara,\" in \"La Traviata\""
  ],
  "orne": [
    "French river to the English Channel",
    "Author Sarah ___ Jewett",
    "Writer Sarah ___ Jewett",
    "English Channel feeder",
    "Writer Sarah ___ Jewett",
    "Neighbor of Eure-et-Loir",
    "Department where Camembert cheese is made",
    "Department of NW France",
    "French river or department",
    "D-Day invasion river",
    "Author Sarah ___ Jewett",
    "River of Normandy",
    "Caen's river",
    "French river or department",
    "French department",
    "River to the English Channel",
    "Basse-Normandie department",
    "Caen's river",
    "River in the D-Day invasion",
    "French department",
    "Caen's river",
    "Writer Sarah ___ Jewett",
    "Normandy river",
    "French river or department",
    "Neighbor of Eure-et-Loir",
    "French river",
    "River in D-Day news",
    "19th-century writer Sarah ___ Jewett",
    "It's between Manche and Eure-et-Loir",
    "Alencon's department",
    "Writer Sarah ___ Jewett",
    "Caen's river",
    "Author Sarah ___ Jewett",
    "River to the English Channel",
    "Caen's river",
    "French department or river",
    "Alencon's department",
    "English Channel feeder",
    "Author Sarah ___Jewett",
    "Caen's river",
    "Caen?Æs river",
    "D-Day river",
    "Caen's river",
    "Normandy river or department",
    "D-Day river",
    "Author Sarah ___ Jewett",
    "French river or department",
    "Normandy river"
  ],
  "tion": [
    "Noun-forming suffix",
    "Common noun ending",
    "Common noun suffix",
    "Relative of -ance",
    "Noun suffix",
    "Noun suffix",
    "Common noun suffix"
  ],
  "cuff": [
    "Strike on the head",
    "Strike sharply",
    "Place for a link",
    "Copper bracelet?",
    "Take into custody",
    "Slap",
    "It may have a link",
    "Many a leg's end",
    "Give a hand?",
    "Trouser turnup",
    "Sleeve's end"
  ],
  "fowl": [
    "Some game",
    "Goose, e.g.",
    "They're game",
    "Some game",
    "Poultry",
    "Chickens and turkeys",
    "Turkeys and such",
    "Some game"
  ],
  "elhi": [
    "Comprehensive, in ed-speak",
    "K-12",
    "K-12",
    "K-12",
    "Like many textbook publishers",
    "Precollege, for short",
    "K-12",
    "K-12",
    "For grades K-12",
    "For grades 1-12, briefly",
    "K thru 12",
    "Including all grades, briefly",
    "Precollege",
    "Textbook market shorthand",
    "K-12",
    "All-inclusive, in edspeak",
    "All-grade",
    "Like certain educational publishing",
    "Precollege, briefly",
    "Not class-specific",
    "Thru 12th grade",
    "Like some textbook publishers",
    "Comprehensive, in edspeak",
    "Pre-coll. years",
    "K-12",
    "Educ. book category",
    "Primary and secondary, briefly",
    "Grades 1-12",
    "K-12, in education",
    "Not grade-specific",
    "Pre-coll., in education",
    "K-12, in education",
    "All the way through 12th grade, informally",
    "K-12, in education lingo",
    "Geared to 1st-12th grades",
    "For grades 1-12",
    "K-12",
    "Pre-college yrs.",
    "Grades K-12",
    "For all grades",
    "Like some textbooks",
    "K-12",
    "For grades 1-12",
    "K-12",
    "K-12 grades, collectively",
    "Grades 1 to 12, briefly",
    "Like some educ. publishing",
    "Relating to grades 1-12",
    "K-12",
    "K-12",
    "Like some textbook publishers",
    "Precollege",
    "Type of textbook market",
    "1-to-12, gradewise",
    "K-12 grades, collectively",
    "K-12, in education",
    "K-12, in education",
    "K-12, in education",
    "Like some textbook publ.",
    "Grades 1-12, for short",
    "K-12, educationally speaking",
    "For grades K through 12",
    "Like some textbook publishing",
    "K-12",
    "Pre-college yrs.",
    "Like some textbook publishers",
    "Like some textbook publishers",
    "Precollege",
    "Designed for all grades",
    "Like some schoolbook publishers",
    "K-12, in education",
    "Precollege",
    "Precollege",
    "Like some textbook publishing",
    "Precollege, briefly",
    "Like some textbook publishing",
    "For all grades, as textbooks",
    "K-12, scholastically speaking",
    "K-12, scholastically speaking",
    "K-12, educationally speaking",
    "Like some textbook publishers",
    "Curriculum range",
    "Precollege",
    "Denoting certain textbook publishers",
    "Like many textbook publishers"
  ],
  "adze": [
    "Wood shaper",
    "Woodcutting tool",
    "Shaping tool",
    "Woodworking tool",
    "Pie cutter's tool",
    "Wood-shaping tool",
    "Shaping tool",
    "Wood shop shaper",
    "Cousin of an ax",
    "Shop chopper",
    "Woodworking tool",
    "Shop dresser",
    "Wood shaper",
    "Woodcarver's tool",
    "Wood-shaping tool",
    "Trimming and smoothing aid",
    "Woodworking tool",
    "It might help you dress in a shop",
    "Wood-shaping tool",
    "Wood-shaping tool",
    "Shaping tool",
    "Axlike tool with a curved blade",
    "Wood-shaping tool",
    "Chisellike tool",
    "Wood dresser",
    "Timber-shaping tool",
    "Woodworker's tool",
    "Wood-dressing tool",
    "Cutting tool",
    "Tool related to a 2-Down",
    "Cabinetmaker's tool",
    "Wood cutter",
    "Woodworker's tool",
    "Wood worker",
    "Wood trimmer",
    "Wood trimmer",
    "Axlike tool",
    "Axlike tool",
    "Cooper's tool",
    "Wood-dressing tool",
    "Shaping tool",
    "Workbench item",
    "Wood trimmer",
    "Axlike tool",
    "Cooper's tool",
    "Wood-shaping tool"
  ],
  "noon": [
    "Common lunchtime",
    "Lunchtime, often",
    "Separator of a.m. and p.m.",
    "Twelve",
    "Busy time at the drive-thru",
    12,
    "It comes before one",
    "It comes before one",
    "11 follower",
    "Eight bells, maybe",
    "Lunch time, maybe",
    "Eleven plus one",
    "XII, perhaps",
    "Whistle time?",
    "What may precede one",
    "When shadows are short",
    "When shadows are shortest",
    "Showdown time",
    "Time for both hands to be up",
    "Lunch time",
    "Midday",
    "Lunchtime, often",
    "Palindromic time",
    "Hand-passing time",
    "XII, maybe",
    "It comes before one",
    "Factory whistle time",
    "When morning ends",
    "Good time for suntanning",
    "Midday",
    "When shadows are shortest",
    "High time?",
    "Shootout time, perhaps",
    "Twelve ___",
    "Duel time, maybe",
    "Factory whistle time",
    "Time to draw?",
    "Midday",
    "Gunfight time, maybe",
    "Eight bells",
    "It follows 11",
    "Nautical day's beginning",
    "When shadows are short",
    "See 37-Across",
    "12 chimes",
    "It precedes one",
    "Bright time",
    "Time for a Wild West shootout",
    "Twelve",
    "\"High\" time",
    "Marshal Kane's time",
    "It precedes one",
    "When shadows are short",
    "High time?",
    "It follows 11",
    "Chime time",
    "High time?",
    "Whistle time",
    "Zenith, metaphorically",
    "Twelve",
    "Twelve, maybe",
    "Shootout time, maybe",
    "When all hands meet",
    "High time?",
    "Hands-together time",
    "Shootout time",
    "Twelve",
    "Palindromic time",
    "Palindromic time",
    "Day divider",
    "Tiffin time",
    "Deadline, sometimes",
    "Twelve, half of the time",
    "\"High\" time",
    "Twelve ___",
    "When a factory whistle blows",
    "Zero hour for Will Kane, in a film",
    "Deadline for Sheriff Kane",
    "High time",
    "Shootout time",
    "Lunchtime",
    "Time for a whistle",
    "When shadows shorten",
    "\"High\" time",
    "Palindromic time",
    "Time for mad dogs and Englishmen",
    "Whistle time",
    "Twelve",
    "Cooper's was high",
    "High time?"
  ],
  "loca": [
    "\"Livin' la Vida ___\"",
    "\"Livin' la Vida ___\"",
    "Ricky Martin's \"Livin' La Vida ___\"",
    "Crazy, in a 2010 Shakira hit",
    "\"Livin' La Vida ___\"",
    "\"Livin' la Vida ___\"",
    "\"Mi Vida ___,\" gritty 1994 drama set in L.A.",
    "\"Livin' la Vida ___\"",
    "\"Livin' la Vida ___\"",
    "\"Livin' La Vida ___\"",
    "\"Livin' la Vida ___\"",
    "Like \"la vida\" in song",
    "Like Ricky Martin's \"vida\"",
    "\"Livin' la Vida ___\" (Ricky Martin song)",
    "\"Livin' La Vida ___\" (Ricky Martin hit)"
  ],
  "fell": [
    "Chop down",
    "Cut down",
    "Dropped",
    "Cut down",
    "Tumbled",
    "Hew",
    "Cut down",
    "Cut down, as a tree",
    "Dipped",
    "Tumbled",
    "Take an ax to",
    "Bring down",
    "Tumbled",
    "Bring down",
    "Lost value",
    "Hew",
    "Cut down",
    "Cut down",
    "Tumbled",
    "Backslid",
    "Collapsed",
    "Cut down",
    "Kind of swoop"
  ],
  "aaba": [
    "Rhyme scheme of Robert Frost's \"Stopping by Woods on a Snowy Evening\"",
    "Rhyme scheme for Robert Frost's \"Stopping by Woods on a Snowy Evening\"",
    "Rhyme scheme for \"Stopping by Woods on a Snowy Evening\"",
    "Rhyme scheme for Frost's \"Stopping By Woods on a Snowy Evening\"",
    "Rhyme scheme for Frost's \"Stopping by Woods on a Snowy Evening\"",
    "Four-line rhyme scheme",
    "Form of many Tin Pan Alley tunes",
    "Rhyme scheme of \"Stopping by Woods on a Snowy Evening\"",
    "Rhyme scheme of the \"Rubaiyat\"",
    "Rubaiyat stanza scheme",
    "\"Stopping By Woods on a Snowy Evening\" rhyme scheme"
  ],
  "high": [
    "Tripping",
    "Loopy from drugs",
    "Setting for 46-Across on a very hot day",
    "What you might microwave something on",
    "Runner's feeling",
    "Weather forecast figure",
    "Dow Jones extreme",
    "Stoned",
    "Over the estimate",
    "Exorbitant",
    "Lofty",
    "Baked",
    "Like some seas and teas",
    "With 37-Across, leave quickly ... or what both words in 17-, 21-, 26-, 49-, 56- and 61-Across could be?",
    "Drugged out",
    "With 69-Across, where to find the ends of 17-, 22-, 32-, 43-, 54- and 61-Across",
    "Daily weather datum",
    "___ and mighty",
    "On dope",
    "Daily temperature stat",
    "___ as a kite",
    "Intoxicating feeling",
    "With 38-Across, subject of this puzzle",
    "Stock stat",
    "Lofty",
    "Mighty's partner",
    "Lit",
    "Tipsy",
    "Weather map area",
    "___ five",
    "Temperature extreme",
    "Fifth gear",
    "Rhapsodic"
  ],
  "bigd": [
    "Cowboy's home, informally",
    "City NNE of San Antone",
    "Longtime Cotton Bowl home, informally",
    "Cowboy's home, familiarly",
    "Home of the Cowboys, informally",
    "Cowboys' home, familiarly",
    "Stars' city, informally",
    "Cowboy's home, informally",
    "Cowboys' home, informally",
    "Home of the Cowboys, familiarly",
    "\"The Most Happy Fella\" song",
    "Texas metropolis nickname",
    "Nickname for Dallas",
    "Cowboys' home, familiarly",
    "Dallas, familiarly",
    "Dallas's nickname",
    "Nickname for the Cowboys' hometown"
  ],
  "scry": [
    "See the future with a crystal ball",
    "Foretell the future",
    "Foretell the future by using a crystal ball"
  ],
  "nyad": [
    "Diana who was the first person to swim from Cuba to Florida without the aid of shark protection (2013)",
    "Swimmer Diana",
    "Long-distance swimmer Diana",
    "Diana with a record-setting swim around Manhattan in 1975",
    "Swimmer Diana",
    "Long-distance swimmer Diana",
    "Long-distance swimmer Diana"
  ],
  "begs": [
    "Implores",
    "Sits up for food, say",
    "Pleads",
    "Does more than ask",
    "Pleads",
    "Does a certain dog trick",
    "Seeks change?",
    "Panhandles",
    "Adjures",
    "Seeks change?",
    "Panhandles",
    "Does a dog trick",
    "Does a certain dog trick",
    "Doesn't just ask",
    "Seeks change, maybe",
    "Has a hand out",
    "Mendicates",
    "Tries for change?",
    "Importunes",
    "Asks for alms",
    "Panhandles",
    "Seeks change?",
    "Is a mendicant",
    "Sits up on two legs, maybe",
    "Does a standard dog trick",
    "Panhandles",
    "Petitions",
    "Petitions"
  ],
  "tube": [
    "Lazy river transport",
    "Idiot box",
    "Hoes",
    "Way around London, with \"the\"",
    "Inner ___ (flotation device)",
    "London subway, with \"the\"",
    "London subway, with \"the\"",
    "Poster mailer's need",
    "Macaroni's form",
    "Hose, e.g.",
    "Toothpaste holder",
    "Piece of cannelloni, essentially",
    "TV, slangily, with \"the\"",
    "Inside of a paper towel roll",
    "London Underground, with \"the\"",
    "TV, slangily, with \"the\"",
    "Toothpaste holder",
    "IV part",
    "Toothpaste holder",
    "Bazooka part",
    "Subway, to Brits",
    "Part of an IV",
    "London subway, with \"the\"",
    "Part of a paper towel roll",
    "Surfing site, with \"the\"",
    "___ top",
    "Toothpaste unit",
    "Bassoon, basically",
    "TV",
    "Couch potato's passion",
    "Narrow conduit",
    "Ointment container",
    "Toothpaste holder",
    "Part of a paper roll"
  ],
  "getz": [
    "Jazzman Stan",
    "Jazzman Stan",
    "Jazzman Stan",
    "\"The Sound\" of music",
    "Saxophonist Stan",
    "Stan on the sax",
    "Gilberto's partner on \"The Girl From Ipanema,\" 1964",
    "Saxophonist nicknamed \"the Sound\"",
    "Saxophonist Stan",
    "Noted Woody Herman band member",
    "Jazzman Stan",
    "Tenor sax great",
    "See 3-Down"
  ],
  "odon": [
    "Take too much of, briefly",
    "Stuff oneself with, briefly",
    "Have way too much of",
    "Take too much of, in brief",
    "Binge-watch, maybe",
    "Take too much of, briefly",
    "Have too much of, briefly",
    "Go crazy with, in a way",
    "Consume too much of, in brief",
    "Gorge oneself with, facetiously",
    "Use in great excess",
    "Take too much of, briefly",
    "Take too much of, briefly",
    "Use excessively, briefly",
    "Take too much of, briefly",
    "Take too much of, briefly",
    "Have too much of, briefly",
    "Take too much of, briefly"
  ],
  "yuzu": ["Yellow citrus fruit used in Japanese cuisine"],
  "cctv": [
    "Means of surveillance, for short",
    "Security guard's viewing, for short"
  ],
  "josh": [
    "Banter jokingly",
    "Kid around",
    "Kid around",
    "Kid",
    "Tease good-naturedly",
    "Kid",
    "Kid around",
    "Tease",
    "Kid around",
    "Rib",
    "Tease",
    "Kid around with",
    "Rib",
    "Rib",
    "Kid",
    "Kid",
    "Kid",
    "Kid",
    "Kid",
    "Playwright Logan"
  ],
  "iest": [
    "Superlative ending with grass or glass",
    "Superlative suffix",
    "Hair extension?",
    "Superlative ending",
    "Superlative suffix",
    "Suffix with luck",
    "Follower of blood and guts",
    "Superlative suffix",
    "Pluck or hair extension?",
    "Superlative suffix",
    "\"y\" ending, in superlative form",
    "Superlative ending",
    "Y to the max?",
    "Superlative suffix",
    "Superlative suffix"
  ],
  "faun": [
    "Half-man/half-goat",
    "Mythical piper",
    "8-Down's Roman equivalent",
    "Figure in \"The Lion, the Witch and the Wardrobe\"",
    "Pan, for one",
    "Subject of a Debussy prelude",
    "Goat-man of myth",
    "\"The Afternoon of a ___\" (Nijinsky ballet)",
    "Woodland spirit",
    "Pan, e.g.",
    "Forest flautist",
    "Horned deity",
    "Man/goat",
    "Lawn sculpture, maybe",
    "Satyr's kin",
    "Goat-man, in myth",
    "Horned deity",
    "Debussy's had quite an afternoon",
    "\"The Afternoon of a ___\""
  ],
  "soys": [
    "Some beans",
    "Some beans",
    "Edamame beans",
    "Beans high in protein",
    "Some beans",
    "Kikkoman sauces",
    "Kikkoman options",
    "Tofu sources",
    "High-protein beans",
    "Tamari sauce and the like",
    "Some beans",
    "Farmers' beans",
    "Some beans",
    "Fermented sauces"
  ],
  "csis": [
    "Some CBS police dramas",
    "Collectors of DNA",
    "Several CBS dramas",
    "Collectors of DNA, prints, etc.",
    "Print collectors, for short",
    "Some CBS forensic spinoffs",
    "Collectors of DNA",
    "Several CBS dramas"
  ],
  "lome": [
    "Togolese city on the Gulf of Guinea",
    "Capital of Togo",
    "Capital of Togo",
    "Togo's capital",
    "Capital on the Gulf of Guinea",
    "West African capital",
    "Capital of Togo",
    "Togo's capital",
    "Togo's capital",
    "Togo's capital",
    "Togo's capital",
    "Capital of Togo",
    "Capital of Togo"
  ],
  "pull": [
    "Tug on",
    "Sway",
    "Influence with higher-ups",
    "Influence",
    "Push's opposite",
    "Word on a door handle",
    "Influence",
    "Door sign",
    "Clout",
    "SWAY, SO TO SPEAK",
    "Sign on a door",
    "Influence",
    "Trapshooter's shout",
    "Clout",
    "Influence"
  ],
  "pica": [
    "Printing measurement",
    "12 points, typographically",
    "Ink of elite type",
    "Typewriter type",
    "12 points",
    "12-point type",
    "12-point type",
    "12 points",
    "About 4.2 millimeters, in printing",
    "Common type",
    "Typewriter type",
    "1/6 of an inch",
    "Type size",
    "12-point type",
    "Type size",
    "One-sixth of an inch",
    "12-point type",
    "Printer's unit",
    "One-sixth of an inch",
    "Elite alternative",
    "Typewriter type",
    "34-Across alternative",
    "Type size",
    "It's smaller than English",
    "Typewriter type",
    "Type type",
    "Part of an inch",
    "Typesetter's unit",
    "Type size",
    "About 1/6 inch",
    "12-point type",
    "Type of type",
    "Type size",
    "Typesetting unit",
    "Not elite",
    "Type size",
    "12 points",
    "Typewriter type",
    "A regular type",
    "57-Down measurement",
    "Type size",
    "Type size",
    "One size smaller than English"
  ],
  "seta": [
    "\"Go ___ Watchman\" (Harper Lee novel)",
    "___ good example",
    "___ good example",
    "___ date (make some plans)",
    "___ trap",
    "Gecko's gripper",
    "___ good example",
    "___ course",
    "___ high standard",
    "___ record",
    "Trap or record preceder",
    "Bristle",
    "___ date",
    "Botanical bristle",
    "___ precedent",
    "Biological bristle",
    "___ goal",
    "Bristlelike part",
    "___ good example",
    "___ good example",
    "___ precedent",
    "Stiff hair",
    "___ good example",
    "Words before date and record",
    "Lead-in to date or trap",
    "___ place for",
    "___ record",
    "Lead-in to fire, trap or price",
    "___ record",
    "Bristle",
    "___ good example",
    "\"La Scala di ___\" (Rossini opera)",
    "___ good example",
    "___ record",
    "___ record",
    "Stiff hair",
    "Stiff hair",
    "Caterpillar hair",
    "___ good example",
    "Words before goal or course",
    "Biological bristle",
    "___ record",
    "Stiff bristle",
    "___ time limit",
    "___ good example",
    "\"La Scala di ___\" (Rossini opera)",
    "___ precedent",
    "___ good example",
    "___ poor example",
    "___ high standard",
    "Bristle",
    "___ world record",
    "___ precedent",
    "___good example",
    "___bad example",
    "Bristle",
    "Bristle",
    "___ precedent",
    "Bristle",
    "Bristle",
    "Bristle",
    "Stiff whisker",
    "Bristle"
  ],
  "natl": [
    "N.B.A. starter?: Abbr.",
    "Part of NASA: Abbr.",
    "Like some monuments: Abbr.",
    "The \"N\" of N.F.L.: Abbr.",
    "The \"N\" in NASA: Abbr.",
    "NASA part: Abbr.",
    "Part of N.B.A.: Abbr.",
    "Part of 31-Down: Abbr.",
    "The \"N\" of N.A.A.C.P.: Abbr.",
    "NASA component: Abbr.",
    "N.R.A. piece?: Abbr.",
    "N.B.A. part: Abbr.",
    "Mets' league: Abbr.",
    "Like some parks: Abbr.",
    "N.F.C. part: Abbr.",
    "Fed.",
    "Like some monuments: Abbr.",
    "Part of NBC: Abbr.",
    "NOW head?: Abbr.",
    "Part of N.F.L.: Abbr.",
    "N.L.R.B. part: Abbr.",
    "League heading: Abbr.",
    "The \"N\" of N.B.A.: Abbr.",
    "Part of NASA: Abbr.",
    "Like a certain govt.",
    "Amer. counterpart",
    "One of the major leagues: Abbr.",
    "Major League league: Abbr.",
    "Part of N.R.A.: Abbr.",
    "Abbr. in many org. names",
    "One of the major leagues: Abbr.",
    "Part of N.F.L.: Abbr.",
    "Abbr. in many org. names",
    "Certain league: Abbr.",
    "One of the leagues: Abbr.",
    "Abbr. In many org. names",
    "A football conf.",
    "Part of NASA: Abbr."
  ],
  "buzz": [
    "Hype",
    "Telephone, informally",
    "Dreaded game show sound",
    "[Wrong]",
    "Caffeine-induced state, slangily",
    "Fly low over"
  ],
  "sago": [
    "Starch-producing palm tree",
    "___ pearls (tapioca lookalike)",
    "Palm starch",
    "Kind of palm",
    "Pudding starch",
    "Starch from a tropical palm",
    "Starch-yielding palm",
    "___ palm",
    "Palm variety",
    "Starchy foodstuff",
    "Starchy stuff",
    "Steamed pudding ingredient",
    "Starch-yielding palm",
    "Palm type",
    "Starchy foodstuff",
    "Pudding starch",
    "Palm starch",
    "Palm starch",
    "Palm starch",
    "Kind of palm",
    "Kind of palm",
    "Starch tree",
    "Palm starch"
  ],
  "soma": [
    "\"Brave New World\" drug",
    "\"Brave New World\" drug",
    "\"Brave New World\" drug",
    "\"Brave New World\" drug",
    "\"Brave New World\" drug",
    "___ cube (popular 1960s puzzle)",
    "\"Brave New World\" drug",
    "\"Brave New World\" drug",
    "Popular block puzzle first put out in 1969",
    "\"Brave New World\" drug",
    "___ cube (popular 60's-70's puzzle)",
    "\"Brave New World\" drug",
    "Body of an organism",
    "\"Brave New World\" joy juice",
    "Intoxicating drink of the gods"
  ],
  "wiry": [
    "Lean and tough",
    "Like many a distance runner",
    "Thin but strong",
    "Like poodle hair",
    "Like some coats",
    "Like gymnasts' bodies",
    "Lean and sinewy",
    "Lean and tough",
    "Lean and strong",
    "Lean and sinewy",
    "Lean and flexible",
    "Lean and strong",
    "In fighting trim"
  ],
  "sked": [
    "Slated events, in brief",
    "Working arrangement, for short",
    "What's happening and when, informally",
    "Plan, for short",
    "Program listings, briefly",
    "Timetable, informally",
    "TV Guide chart, for short",
    "Short timetable?",
    "TV listings, informally",
    "Big TV announcement, informally",
    "Slot holder, for short",
    "Convention handout, for short",
    "TV exec's concern",
    "Tube lineup",
    "Piece of Variety news",
    "It's full of slots, briefly",
    "Short hours of operation?",
    "Airline listing, for short",
    "Agenda, for short",
    "TV exec's work",
    "Agenda, informally",
    "TV exec's work",
    "Timetable, for short",
    "Listing, a la Variety",
    "TV Guide info",
    "TV listings",
    "Variety listing",
    "TV Guide chart, for short",
    "Slangy timetable",
    "Timetable, for short",
    "Calendar a la Variety"
  ],
  "clic": [
    "___ Stic (pen brand)",
    "___ Stic (ballpoint pen)",
    "___ Stic (retractable Bic pen)"
  ],
  "pohl": [
    "Nebula Award winner Frederik",
    "Science fiction writer Frederik",
    "Science fiction author Frederik",
    "Sci-fi writer Frederik",
    "Science fiction writer Frederik",
    "\"The Far Shore of Time\" author Frederik",
    "Science fiction writer Frederik",
    "Sci-fi writer Frederik",
    "Sci-fi author Frederik",
    "Sci-fi writer Frederic",
    "Sci-fi author Frederik"
  ],
  "bled": [
    "Sapped of resources",
    "Didn't stay within the lines, say",
    "Ran, as fabric dye",
    "Sucked dry",
    "Ran across the border",
    "Drained",
    "Ran",
    "Ran past the border",
    "Ran",
    "Ran, as colors",
    "Ran in the wash",
    "Ran",
    "Ran in the wash",
    "Shook down",
    "Ran in the wash",
    "Ran while wet",
    "Suffered from a cut",
    "Ran in the rain",
    "Ran, as colors",
    "Wasn't colorfast",
    "Ran",
    "Ran, as colors",
    "Felt sorrow",
    "Ran",
    "Felt deep pity (for)",
    "Suffered terribly",
    "Ran, as colors",
    "Wasn't colorfast",
    "Blackmailed",
    "Extorted from",
    "Ran in the wash",
    "Extorted from",
    "Blackmailed",
    "Extorted from",
    "Ran",
    "Blackmailed",
    "Ran",
    "Blackmailed",
    "Ran in the laundry"
  ],
  "gums": [
    "Bites harmlessly",
    "Canine supporters",
    "Windbags beat them",
    "What gingivitis affects",
    "Canine holders",
    "Teeth holders",
    "Bites ineffectually"
  ],
  "suvs": [
    "Alternatives to wagons",
    "Explorers and Expeditions",
    "Explorer and Escalade, in brief",
    "Some 4x4s",
    "Blazers, e.g., in brief",
    "Yukon and Tahoe, for short",
    "Some 4WD rides",
    "Explorers on a hwy., e.g."
  ],
  "cook": [
    "Captain who circumnavigated the globe",
    "Pan handler",
    "See 40-Across",
    "Diner employee",
    "___ Islands",
    "Chicago's county",
    "Pan handler",
    "Captain James of the high seas",
    "Prepare",
    "Pacific explorer",
    "Manipulate, as the books",
    "Chuck wagon honcho",
    "Person in an apron",
    "One who's home on the range?",
    "Doctor, in a way",
    "Boil or broil",
    "Camp employee",
    "Kitchener"
  ],
  "pend": [
    "Hang in the balance",
    "Need settling",
    "Hang in the balance",
    "Remain undecided",
    "Await resolution",
    "Hang",
    "Remain undecided",
    "Await",
    "Remain in limbo",
    "Remain unresolved",
    "Await decision",
    "Be in limbo",
    "Hang",
    "Await",
    "Remain undecided",
    "Remain unsettled",
    "Hang",
    "Wait in line",
    "Hang",
    "Remain unsettled",
    "Remain unsettled",
    "Remain undecided",
    "Await judgment",
    "Remain unsettled",
    "Hang",
    "Hang fire",
    "Await judgment",
    "Await judgment",
    "Hang",
    "Hang fire",
    "Hang",
    "Remain undecided",
    "Await judgment",
    "Await",
    "Remain unsettled",
    "Hang in the balance",
    "Hang fire"
  ],
  "rowr": ["\"Meeeeeeeeow!\""],
  "whup": [
    "Give a good beatin'",
    "Beat the tar out of",
    "Lick",
    "Clobber",
    "Beat and how!",
    "Tan",
    "Beat, and how!",
    "Trounce",
    "Beat but good!",
    "Paste",
    "Lick",
    "Defeat, slangily",
    "Defeat, a la Ali"
  ],
  "mpeg": [
    "Video file extension",
    "Multimedia file format",
    "Digital video file format",
    "Downloaded video format",
    "QuickTime or RealPlayer format option",
    "Compressed video format",
    "Compressed video file format",
    "Video file format",
    "Compressed video file format"
  ],
  "faze": [
    "Rattle",
    "Disconcert",
    "Put off",
    "Discombobulate",
    "Throw for a loop",
    "Discombobulate",
    "Disconcert",
    "Fluster",
    "Disconcert",
    "Discombobulate",
    "Daunt",
    "Unnerve",
    "Shake up",
    "Disconcert",
    "Unnerve",
    "Disconcert",
    "Discomfit",
    "Unnerve",
    "Rattle",
    "Perturb",
    "Throw",
    "Disconcert",
    "Unnerve",
    "Disorient",
    "Dismay",
    "Disconcert"
  ],
  "with": [
    "Employed by",
    "Including",
    "\"I'm ___ Her,\" 2016 political slogan",
    "Seeing",
    "___ honors",
    "Accompanying",
    "Accompanying",
    "Among",
    "Beside",
    "Accompanying",
    "See 1-Across",
    "Dating",
    "___ child (pregnant)",
    "Accompanying",
    "Beside",
    "Plus",
    "On the side of",
    "Attached to",
    "In support of",
    "\"Get ___ it!\"",
    "On the same side as",
    "Dating",
    "In the company of",
    "Accompanying",
    "Accompanying",
    "Attached to"
  ],
  "kiev": [
    "Chicken ___",
    "Capital on the Dnieper",
    "Ukraine's capital",
    "Eastern European capital",
    "Eastern European capital",
    "Ukraine's capital",
    "Chicken ___",
    "Capital in 2004-05's Orange Revolution",
    "Chicken ___",
    "Eastern European capital",
    "Chicken ___ (dish)",
    "Chicken ___",
    "Chicken ___",
    "Ukraine's capital",
    "Capital on the Dnieper",
    "Russians call it the Mother of Cities",
    "Ukraine's capital",
    "Chicken ___",
    "Chicken ___",
    "Capital of Ukraine",
    "Capital on the Dnieper",
    "Mussorgsky's \"The Great Gate of ___\"",
    "Capital south of Chernobyl",
    "European capital of 21/2 million",
    "Capital on the Dnieper",
    "Chicken ___",
    "City on the Dneiper",
    "Chicken ___ (dish)",
    "Chicken ___",
    "Ukraine's capital",
    "Chicken ___",
    "Ukraine's capital",
    "Chicken ___",
    "Chicken ___",
    "Ukraine?Æs capital",
    "Ukraine's capital",
    "Ukraine's capital",
    "Chicken ___",
    "Chicken style",
    "Chicken ___ (deep-fried dish)"
  ],
  "afor": [
    "Get an ___ effort",
    "Get an ___ effort",
    "\"___ Andromeda\" (British sci-fi series)",
    "Get an ___ effort",
    "Get an ___ effort",
    "___ effort",
    "___ effort",
    "Get an ___ effort",
    "Get an ___ effort",
    "Get an ___ effort",
    "Get an ___ effort"
  ],
  "tows": [
    "Help for the disabled?",
    "Garage services",
    "Triple-A jobs",
    "Carries away",
    "Triple-A requests",
    "Some rescues",
    "Responses to some OnStar calls",
    "Followers of many breakdowns",
    "Rescues for broken-down cars",
    "Drags",
    "Brings along",
    "Aids for disabled cars",
    "Some emergency services",
    "Tugboat services",
    "A.A.A. jobs",
    "Garage jobs",
    "Drags",
    "Expensive car trips",
    "Pulls",
    "Lugs",
    "Trucks for hire",
    "Hauls"
  ],
  "skua": [
    "Arctic seabird",
    "Arctic seabird",
    "Arctic seabird",
    "Arctic bird",
    "Gull-like predator",
    "Arctic bird",
    "Gull-like predator",
    "Arctic gull",
    "Antarctic flier",
    "Gull-like bird",
    "Arctic bird",
    "Rapacious seabird",
    "Gull-like bird"
  ],
  "capp": [
    "Al who created Li'l Abner",
    "Cartoonist who created Fearless Fosdick",
    "Dogpatch creator",
    "Andy of cartoons",
    "Dogpatch creator",
    "Al who created Joe Btfsplk",
    "Joe Btfsplk's creator",
    "Li'l Abner creator",
    "Sadie Hawkins Day creator",
    "Lower Slobbovia creator",
    "Al who created Fearless Fosdick",
    "Li'l Abner creator Al",
    "Creator of Earthquake McGoon and Moonbeam McSwine",
    "Cartoonist Al",
    "Cartoonist who drew the Shmoo",
    "Daisy Mae's drawer",
    "\"Li'l Abner\" creator",
    "Andy in the funnies",
    "Andy in the newspapers",
    "Andy of the comics",
    "Dogpatch cartoonist",
    "Comics fellow who hangs out in a pub",
    "Abner's artist",
    "\"Li'l Abner\" cartoonist",
    "Andy of the comics",
    "Cartoonist with regular \"Advice fo' Chillun\"",
    "Cartoonist Al",
    "Andy of the funnies",
    "Fearless Fosdick's creator",
    "Daisy Mae's creator",
    "Andy of the comics",
    "Li'l Abner's creator",
    "Dogpatch's creator",
    "Daisy Mae's drawer",
    "Creator of the Shmoos"
  ],
  "gran": [
    "Mom's mom, for short",
    "Nickname for Mom's mom",
    "Part of GTO",
    "Part of GTO",
    "___ Canaria Island",
    "Mother's mother, informally",
    "___ Torino (old Ford model)",
    "1970's Plymouth ___ Fury",
    "Mom's mom",
    "___Paradiso in the Alps",
    "___ Canaria island"
  ],
  "frey": [
    "Glenn of the Eagles",
    "James who wrote the best seller \"A Million Little Pieces\"",
    "\"A Million Little Pieces\" author James",
    "James who wrote \"A Million Little Pieces\"",
    "Glenn of the Eagles",
    "Glenn of the Eagles",
    "Rocker Glenn",
    "Norse god of peace",
    "Scandinavian god of fertility",
    "Rocker Glenn"
  ],
  "morn": [
    "Eve's counterpart",
    "Time starting at dawn, to poets",
    "Dawn, to Donne",
    "Daybreak, to Donne",
    "Even's opposite",
    "Time to rise, in poetry",
    "A.M. hours, in poetry",
    "Early time",
    "Eve's counterpart",
    "Eve's opposite",
    "Neil Diamond's \"September ___\"",
    "Even's counterpart",
    "Poetic time of day",
    "Eve's opposite",
    "Eve's opposite",
    "Dawn, to Donne",
    "\"September ___\" (Neil Diamond hit)",
    "When roosters crow",
    "Prenoon period, in poetry",
    "In \"Hamlet,\" it's \"in russet mantle clad\"",
    "Time starting at dawn",
    "Poetic time of day",
    "Even's opposite",
    "\"September ___\" (Neil Diamond hit)",
    "Literary time of day",
    "Early part of the day",
    "Eve's counterpart",
    "Eve's counterpart",
    "Evening's opposite",
    "Dawn",
    "Daybreak",
    "Dawn",
    "Daybreak"
  ],
  "nots": [
    "What dashes may represent in internet searches",
    "Forget-me-___",
    "Have-___ (poor people)",
    "Have-___ (lower economic group)",
    "Have-___ (poor people)",
    "Relatives of AND's and OR's in Boolean logic",
    "Have-___(the poor)"
  ],
  "eads": [
    "St. Louis's ___ Bridge, the oldest span over the Mississippi",
    "___ Bridge (historic span across the Mississippi)",
    "James B. ___, diving bell inventor",
    "Actor George of \"CSI\"",
    "St. Louis landmark designer",
    "___ Bridge, historic 1874 span across the Mississippi",
    "19th-century engineer with a star on the St. Louis Walk of Fame",
    "St. Louis's ___ Bridge",
    "___ Bridge, first to span the Mississippi at St. Louis",
    "St. Louis's historic ___ Bridge",
    "St. Louis bridge designer",
    "St. Louis's ___ Bridge",
    "St. Louis's historic ___ Bridge",
    "___ Bridge, St. Louis"
  ],
  "brun": ["Couleur du chocolat", "French painter Charles Le ___"],
  "guar": [
    "Gum-producing plant",
    "Gum-producing plant",
    "___ gum (thickening agent)",
    "Kind of gum in food preparation",
    "___ gum"
  ],
  "ases": [
    "\"___ Death,\" movement from \"Peer Gynt\"",
    "Grieg's \"___ Death\"",
    "\"___ Death\" (Grieg work)",
    "\"___ Death\" (Grieg movement)",
    "\"___ Death\" (\"Peer Gynt\" piece)",
    "Grieg's \"___ Death\"",
    "\"___ Death\" (\"Peer Gynt Suite\" piece)",
    "\"___ Death\" (Grieg work)",
    "\"___ Death\" (Grieg work)",
    "Enzyme suffixes",
    "\"___ Death\" (Grieg work)",
    "\"___ Death\" (Grieg work)",
    "\"A hillside near ___ farm\" (opening \"Peer Gynt\" scene)",
    "\"___ Death\" (Grieg elegy)"
  ],
  "tali": [
    "Anklebones",
    "Anklebones",
    "Anklebones",
    "Anklebones",
    "Anklebones",
    "Bones that support tibiae",
    "Anklebones",
    "Bones connected to fibulae",
    "Anklebones",
    "Anklebones",
    "Anklebones",
    "Anklebones",
    "Ankles, anatomically",
    "Anklebones"
  ],
  "eens": [
    "Dark periods, in poetry",
    "Poetic times",
    "Poetic nights",
    "Poetic times",
    "Dark times, in literature",
    "Poetic dusks",
    "Poetic times of day",
    "Opposite of morns",
    "Dark times in literature",
    "Poetic gloamings",
    "Poets' dark periods",
    "Dark times, to poets",
    "Poetic periods",
    "Twilights, poetically",
    "Dark times, briefly",
    "Nighttimes, in poetry",
    "Twilights, poetically",
    "Dusks, to Donne"
  ],
  "reza": [
    "___ Shah Pahlavi, former leader of Iran",
    "Yasmina ___, two-time Tony-winning playwright",
    "Exiled shah Mohammad ___ Pahlavi",
    "Iran's ___ Shah Pahlevi",
    "Shah ___ Pahlavi",
    "Iran's ___ Shah Pahlavi",
    "___ Pahlevi, last shah of Iran",
    "Former shah ___ Pahlavi",
    "\"No god but God\" author ___ Aslan",
    "Shah ___ Pahlavi",
    "___ Pahlavi, former shah",
    "Iran's ___ Shah Pahlavi",
    "___ Pahlavi, former shah",
    "Shah Pahlavi",
    "Iran's ___ Shah Pahlavi",
    "Shah ___ Pahlavi",
    "___Pahlevi, former Shah",
    "Shah name",
    "Iran?Æs Mohammed ___ Pahlevi"
  ],
  "iter": [
    "Old Roman course",
    "Way of old Rome",
    "Roman road",
    "Roman road",
    "Anatomical canal",
    "Road to the Forum, e.g.",
    "Roman road",
    "Old Roman road",
    "Roman road",
    "Ancient artery",
    "Way, in Pompeii",
    "The Roman way",
    "Roman road",
    "Path of Caesar",
    "Bodily channel",
    "Way of old Rome",
    "Anatomical passage",
    "Anatomical duct",
    "Anatomical canal",
    "Brain passage",
    "Bodily channel",
    "Path of Caesar",
    "Anatomical passage",
    "Corporeal canal",
    "Route for Ben-Hur",
    "Anatomical canal",
    "Old Roman road",
    "Roman road",
    "Roman way",
    "Roman road",
    "Aqueduct of Sylvius, e.g.",
    "Old Roman road",
    "Anatomical canal",
    "It led to ancient Rome",
    "Road to old Rome",
    "Roman road",
    "Passage in Latin",
    "Cato's way",
    "Roman's way",
    "Roman way",
    "Roman way",
    "Aqueduct of Sylvius, e.g.",
    "Route for Ben-Hur",
    "Road to Rome",
    "Road to Rome",
    "Anatomical aqueduct",
    "Roman road",
    "Sinus, e.g.",
    "Passage in Latin",
    "Roman route",
    "Roman road",
    "Roman road",
    "Journey for Caesar",
    "Brain passage",
    "Road, for Romulus",
    "Ancient route",
    "Anatomical passage",
    "Roman road",
    "Anatomical passage",
    "Anatomical passage",
    "Roman way",
    "Latin journey",
    "Road to Rome",
    "Roman road",
    "Road to ancient Rome",
    "Anatomical passage",
    "Appian Way, e.g.",
    "Passage for Paulus",
    "Roman way",
    "Road for Caesar",
    "Body passage",
    "Passage",
    "Road to Roma",
    "Brain passage",
    "Anatomical passage",
    "Ovid's way",
    "Brain passage"
  ],
  "gael": [
    "Scottish Highlander",
    "Erse speaker",
    "Certain Scotsman",
    "Manxman, e.g.",
    "Scottish Highlander",
    "Scottish Highlander",
    "Fine ___ (Irish political party)",
    "Iona College athlete",
    "Isle of Man man",
    "Highlander",
    "Celt or Highlander",
    "Certain Scot",
    "Celtic speaker",
    "Iona College athlete",
    "Highlander",
    "Food critic Greene",
    "Celt, e.g.",
    "Iona College athlete",
    "Certain Celt",
    "Celt or Highlander",
    "Highlander",
    "Highlander",
    "Many a plaid wearer",
    "Highlander",
    "Wearer of 71-Down",
    "Iona College player",
    "Scottish native",
    "Dumbarton denizen",
    "Highlander",
    "Isle of Man man",
    "Scotsman",
    "Highlander",
    "Highlander",
    "Iona College athlete",
    "Food maven Greene",
    "Critic Greene",
    "Food critic Greene",
    "Food critic Greene",
    "Highlander",
    "Highlander",
    "Food critic Greene"
  ],
  "esqs": [
    "Bar members: Abbr.",
    "Titles for attys.",
    "Attys.' titles",
    "Titles of lawsuits?: Abbr.",
    "Attys.' titles",
    "Ones working on a case-by-case basis?: Abbr.",
    "Titles for attorneys: Abbr.",
    "Titles for attys.",
    "A.B.A. members' titles",
    "Titles at A.B.A. mtgs.",
    "Titles for attys.",
    "Honorifics for attys.",
    "A.B.A. members' titles",
    "Many A.B.A. members",
    "Gentlemen: Abbr."
  ],
  "gees": [
    "Thousands of dollars, in slang",
    "Baffled exclamations",
    "Bee ___",
    "Bee ___ (\"Night Fever\" group)",
    "Turns right on a horse",
    "Thousands, in slang",
    "Bee ___",
    "Big money units, in slang",
    "Pop music's Bee ___",
    "Thousand-dollar sums, slangily",
    "Thousands, in slang",
    "Goes right",
    "Calls to 57-Across",
    "Pop music's Bee ___",
    "Mob money units",
    "Thousands, slangily",
    "Turns right",
    "Orders to plow horses",
    "Bee ___",
    "Turns right",
    "Turns right",
    "Orders to plow horses",
    "The Bee ___"
  ],
  "barr": [
    "Attorney general under both Bush 41 and Trump",
    "Roseanne who's not on \"The Conners\"",
    "Attorney general before Reno",
    "Roseanne of \"Roseanne\"",
    "Mystery writer Nevada ___",
    "Epstein-___ virus",
    "Comic who was the 2012 presidential nominee of the Peace and Freedom Party",
    "Roseanne of \"Roseanne\"",
    "Attorney general under Bush 41",
    "2008 Libertarian candidate for president",
    "2008 Libertarian presidential candidate",
    "\"Fierce working-class domestic goddess\" of a sitcom",
    "Alfred H. ___ Jr., founding director of MoMA",
    "Roseanne, before and after Arnold",
    "William ___, 1990s attorney general",
    "Bob ___, 2008 Libertarian candidate for president",
    "Attorney general before Reno",
    "Attorney general after Thornburgh",
    "Roseanne, again",
    "Actress Roseanne",
    "Roseanne, formerly",
    "\"Roseanne\" star",
    "William ___, attorney general under Bush the elder",
    "\"Roseanne\" star",
    "Mystery writer Nevada",
    "Roseanne, once",
    "New sitcom star of 1988",
    "Attorney General under Bush",
    "Roseanne, once",
    "Roseanne, formerly",
    "Roseanne, once",
    "Bush Attorney General William"
  ],
  "reni": [
    "Italian artist Guido",
    "Renaissance painter Guido",
    "Italian artist Guido",
    "Guido ___, painter of the \"Crucifixion of St. Peter\"",
    "Baroque artist Guido",
    "Guido who painted \"Massacre of the Innocents\"",
    "\"Crucifixion of St. Peter\" painter",
    "\"The Labors of Hercules\" painter Guido",
    "Painter of the \"Crucifixion of St. Peter\" in the Vatican",
    "\"The Labors of Hercules\" painter",
    "Italian artist Guido",
    "Italian painter Guido",
    "Italian artist Guido",
    "Renaissance artist Guido ___",
    "Italian painter Guido",
    "\"The Labors of Hercules\" painter Guido",
    "Italian artist Guido",
    "Actor Santoni",
    "\"Crucifixion of St. Peter\" painter Guido ___",
    "Italian artist Guido",
    "\"Crucifixion of St. Peter\" artist",
    "\"Madonna With Rosary\" artist",
    "Italian artist Guido ___",
    "\"Aurora\" painter"
  ],
  "coca": [
    "Traditional treatment for altitude sickness",
    "Lucrative South American crop",
    "Part of a white script on a red can",
    "South American cash crop",
    "Cash crop in Colombia",
    "Natural stimulant",
    "Colombian crop",
    "___-Cola",
    "Bolivian export",
    "Something to chew on",
    "Andean stimulant",
    "Chewed stimulant",
    "Chewed stimulant",
    "It's cultivated in the Andes",
    "___ leaf",
    "Drug-yielding plant",
    "Chewed stimulant",
    "Bean source",
    "Plant with a chewable leaf",
    "___-Cola",
    "Pioneer of TV comedy",
    "Best Actress Emmy winner of 1951",
    "Cola's beginning",
    "___-Cola",
    "\"Your Show of Shows\" regular",
    "Kind of leaves",
    "1951 Best Actress Emmy winner",
    "With 42-Across, a popular 1886 creation",
    "Chewed stimulant",
    "She found success with Caesar",
    "Imogene of early TV",
    "Caesar's partner",
    "Andean shrub",
    "Caesar's sidekick",
    "Caesar's sidekick",
    "Caesar's partner in 50's TV",
    "Kind of leaf",
    "Stimulating shrub",
    "A Caesar partner",
    "Comic Imogene",
    "Caesar's partner in 50's TV",
    "Imogene of \"Your Show of Shows\"",
    "Caesar's partner",
    "Illicit leaves"
  ],
  "flit": [
    "Move like a butterfly",
    "Move like a moth",
    "Move like a fairy",
    "Dart (around)",
    "Move like a moth",
    "Dart",
    "Move like a hummingbird",
    "Move like a butterfly",
    "Dart along",
    "Be like a bee",
    "Move like a moth",
    "Dart",
    "Dart",
    "Move quickly and lightly",
    "Move like a butterfly",
    "Dart",
    "Dart",
    "Dart",
    "Dart",
    "Hummingbirds do it",
    "Pass quickly",
    "Dart about",
    "Dart",
    "Fly like a butterfly",
    "Dart",
    "Skitter",
    "Skim along",
    "Move erratically",
    "Dart",
    "Move like a butterfly"
  ],
  "jade": [
    "Green stone popular in Chinese craftwork",
    "Green hue",
    "Green carving stone",
    "Green stone",
    "Stone prized in China",
    "Green precious stone",
    "Light blue-green",
    "Green stone",
    "Green gem used in Chinese carvings",
    "Green gem",
    "Light bluish green",
    "Shade of green",
    "___ Emperor (Taoism figure)",
    "Green gem",
    "Official gemstone of Alaska",
    "Stone in a 2008 Olympic medal",
    "Shade of green",
    "Chinese export",
    "Green stone",
    "Stone revered in China",
    "Semiprecious stone",
    "Green gem",
    "Green gem",
    "Shade of green",
    "Valuable green stuff",
    "Like some expensive vases",
    "Fine stone",
    "Nephrite",
    "Shade of green",
    "Carved gem",
    "Green hue",
    "Green shade",
    "Nephrite",
    "Ornamental stone",
    "Shade of green",
    "Shade of green",
    "Figurine material"
  ],
  "sten": [
    "Product from the Royal Small Arms Factory",
    "W.W. II weapon",
    "W.W. II-era British gun",
    "Old 9-mm.",
    "W.W. II British gun",
    "British submachine gun",
    "British gun",
    "Acronymic weapon name",
    "Suez Crisis weapon",
    "Bygone British gun",
    "Old carbine",
    "Korean War weapon",
    "British submachine gun",
    "British submachine gun",
    "Antique gun",
    "Korean War weapon",
    "British W.W. II gun",
    "9-mm. weapon",
    "British submachine gun",
    "British gun",
    "Antique gun",
    "Brit's W.W. II gun",
    "W.W. II submachine gun",
    "Cooper's co-star in \"The Wedding Night,\" 1935",
    "Gun of old",
    "W.W. II gun",
    "British gun",
    "9-mm. gun of W.W. II",
    "British W.W. II-era gun",
    "Old British gun",
    "Antique gun",
    "Tommy gun?",
    "British gun",
    "British gun of W.W. II",
    "W.W. II gun",
    "British gun",
    "W.W. II gun",
    "Antique weapon",
    "British weapon",
    "Arm of the British military?",
    "See 123-Across",
    "British gun",
    "British gun",
    "W.W. II weapon",
    "British rifle",
    "British gun",
    "British submachine gun",
    "\"Nana\" star Anna",
    "W.W. II gun",
    "W.W. II weapon",
    "W.W. II gun",
    "Uzi relative",
    "British weapon",
    "1930's Goldwyn star Anna",
    "Anna of \"Nana\"",
    "\"Nana\" star Anna",
    "British carbine",
    "Tommy gun",
    "Submachine gun",
    "Predecessor of an Uzi",
    "\"The Man I Married\" actress",
    "W.W. II gun",
    "World War II weapon",
    "\"Nana\" actress",
    "Arm from overseas",
    "Submachine gun",
    "British submachine gun",
    "British gun",
    "W.W. II-issue gun",
    "British gun",
    "Gun that uses nine-millimeter ammo",
    "Submachine gun",
    "British gun",
    "\"The Girl With the Hatbox\" star",
    "Tommy's gun",
    "Contemporary of Garbo",
    "British submachine gun",
    "Anna of \"Nana\"",
    "W.W. II gun",
    "W.W. II gun",
    "Anna of \"Nana\"",
    "Anna of \"Nana\"",
    "See 5-Across",
    "British carbine",
    "Light submachine gun",
    "British gun",
    "Actress Anna",
    "Filmdom's Anna",
    "Old-time actress Anna",
    "W.W. II gun",
    "Anna who played Nana",
    "Gfoldwyn discovery Anna ___",
    "Sheppard and Turpin's gun",
    "British weapon",
    "W.W. II gun",
    "British carbine",
    "British gun",
    "Light submachine gun",
    "British gun",
    "Actress Anna",
    "Tommy gun?",
    "British gun",
    "30's and 40's actress Anna",
    "British automatic"
  ],
  "atem": [
    "\"Up and ___!\"",
    "\"Lemme ___!\"",
    "\"Up and ___!\"",
    "\"Let me ___!\"",
    "\"Lemme ___!\"",
    "\"Time to rise!\" (\"Up and ___!\")",
    "\"Lemme ___!\"",
    "\"Lemme ___!\"",
    "\"Lemme ___!\"",
    "\"Lemme ___!\"",
    "\"Lemme ___!\"",
    "\"Up and ___!\"",
    "\"Up and ___!\"",
    "\"Lemme ___!\"",
    "\"Lemme ___!\"",
    "See 19-Across",
    "Up and ___",
    "\"Lemme ___!\"",
    "\"Up and ___!\"",
    "\"Up and ___!\"",
    "\"Up and ___!\"",
    "\"Up and ___!\"",
    "\"Up and ___!\"",
    "\"Lemme ___!\" (fightin' words)",
    "\"Up and ___!\""
  ],
  "ence": [
    "Suffix with defer or insist",
    "An end to depend",
    "Verb-to-noun suffix",
    "Ending to prefer?",
    "Suffix with depend",
    "Suffix with exist",
    "Ending with prefer",
    "Suffix with confer",
    "Suffix with insist",
    "Suffix with exist",
    "Suffix with differ",
    "Ending with defer or refer",
    "Suffix with depend",
    "Suffix with persist",
    "Suffix with persist",
    "Suffix with refer",
    "Suffix with defer",
    "Suffix with exist",
    "Suffix with refer",
    "Noun-making suffix",
    "Suffix with infer",
    "Suffix with differ",
    "Noun-forming suffix",
    "Suffix with differ",
    "Suffix with exist",
    "Depend add-on",
    "Suffix with refer or prefer",
    "Ending with insist or persist",
    "Suffix with defer or confer",
    "Prefer follower"
  ],
  "bret": [
    "Author Harte",
    "\"Rock of Love\" star Michaels",
    "Michaels of rock and reality TV",
    "Four-time Gold Glove Award winner Boone",
    "Author Harte",
    "___ Easton Ellis, author of \"American Psycho\"",
    "Second baseman Boone",
    "Author ___ Easton Ellis",
    "One of TV's Mavericks",
    "Writer Harte",
    "Writer Harte",
    "Pitcher Saberhagen",
    "Harte of fiction",
    "TV's Maverick",
    "Writer Harte",
    "Cy Young winner Saberhagen",
    "Writer Harte",
    "One of the baseball Boones",
    "Brother of Bart, in 50's-60's TV",
    "Writer Harte",
    "Author ___ Easton Ellis",
    "A Maverick",
    "Writer Harte",
    "Maverick of \"Maverick\"",
    "Novelist ___ Easton Ellis",
    "Cy Young winner Saberhagen",
    "Baseball's Saberhagen",
    "Boone of the Braves",
    "Harness-racing legend ___ Hanover",
    "Writer Harte",
    "One of TV's Mavericks",
    "TV Maverick",
    "TV's Maverick",
    "Author Harte",
    "One of TV's Mavericks",
    "Novelist ___ Easton Ellis"
  ],
  "bigs": [
    "Majors",
    "Step up from Triple-A, with \"the\"",
    "See 52-Across",
    "Major leagues, slangily, with \"the\"",
    "V.I.P.'s",
    "V.I.P.'s"
  ],
  "bilk": [
    "Fleece",
    "Con",
    "Cheat",
    "Rook",
    "Defraud",
    "Fleece",
    "Swindle",
    "Fleece",
    "Sting",
    "Defraud",
    "Gyp"
  ],
  "moro": [
    "___ reflex, infant's instinctual spreading of the arms",
    "Former Italian P.M. Aldo",
    "1960's-70's Italian P.M.",
    "Former P.M. of 11-Down",
    "1960's-70's Italian P.M.",
    "Red Brigades victim Aldo",
    "60's-70's Italian P.M.",
    "60's-70's Italian P.M.",
    "Former Italian P.M. Aldo",
    "70's Italian P.M.",
    "Southern Filipino",
    "Filipino"
  ],
  "burt": [
    "Singer/songwriter Bacharach",
    "Reynolds of \"Boogie Nights\"",
    "Actor Reynolds who was once married to 2-Down",
    "Reynolds of \"Boogie Nights\"",
    "Reynolds of \"Boogie Nights\"",
    "Ward who played Robin",
    "Actor Young of the \"Rocky\" films",
    "___ Ward, who played Robin in TV's \"Batman\"",
    "Songwriter Bacharach",
    "Songwriter Bacharach"
  ],
  "adah": [
    "Wife of Esau",
    "Wife of Esau",
    "Wife of Esau",
    "Wife of Esau",
    "Wife of Esau",
    "Wife of Esau",
    "Wife of Esau",
    "Wife of Lamech, in the Bible",
    "Esau's wife",
    "Esau?Æs wife"
  ],
  "idve": [
    "Double-apostrophe contraction",
    "Word with two apostrophes",
    "Contraction with two apostrophes",
    "\"If only ___ known ...\"",
    "Double contraction"
  ],
  "font": [
    "Typesetter's choice",
    "Typesetter's choice",
    "Times New Roman, e.g.",
    "Courier or Myriad",
    "Comic Sans, e.g.",
    "Word choice?",
    "Century, for one",
    "Microsoft Word menu pick",
    "Times or Century",
    "It might have serifs",
    "Helvetica, e.g.",
    "Times Roman, for one",
    "Times or Century",
    "Garamond, e.g.",
    "Times, e.g.",
    "Helvetica, for one",
    "Rite aid?",
    "Productive source",
    "Helvetica is one",
    "Roman, e.g.",
    "Times, e.g.",
    "Helvetica, e.g.",
    "Type choice",
    "Times or Schoolbook",
    "Source",
    "Rite receptacle",
    "Plentiful source",
    "Church receptacle",
    "Type assortment",
    "Printer's choice",
    "Type set",
    "Set of letters",
    "Poet's spring",
    "Set of type"
  ],
  "spfs": [
    "Coppertone stats, for short",
    "Nos. at the beach",
    "15, 30 and 50 are common ones, in brief",
    "Nos. at the beach",
    "UV blockage nos.",
    "Nos. on some lotion bottles",
    "Beach nos.",
    "Concerns of some bathers: Abbr."
  ],
  "crue": [
    "Motley ___",
    "Motley ___",
    "Motley ___",
    "Rock's Motley ___",
    "\"Red, White & ___\" (2005 rock album)",
    "Rock's Motley ___",
    "Motley ___",
    "Rock's Motley ___",
    "Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "\"Red, White & ___\" (2005 rock album)",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock's Motley ___",
    "Rock music's Motley ___"
  ],
  "adit": [
    "Mine entrance",
    "Mine entrance",
    "Mine entrance",
    "Mine entrance",
    "Shaft entrance",
    "Mine entrance",
    "Colliery access",
    "Mine opening",
    "Passage to get 8-Down",
    "Miner's entry",
    "Access for a collier",
    "Mine passage",
    "Mine access",
    "Mine opening",
    "Miner's entry",
    "Mine entrance",
    "Mine entrance",
    "Shaft's end",
    "Collier's entrance",
    "Pit passage",
    "Mine entrance",
    "Mine entrance"
  ],
  "onec": [
    "What \"accommodate\" is often inaccurately spelled with",
    "Draft status for someone in the Public Health Service",
    "Equivalent of 20 fins",
    "10 sawbucks",
    "10 sawbucks",
    "\"Currently serving\" military designation"
  ],
  "marg": [
    "Tequila cocktail, in slang",
    "Helgenberger of \"CSI\"",
    "Actress Helgenberger of \"CSI\"",
    "\"China Beach\" actress Helgenberger",
    "Helgenberger of \"CSI\"",
    "Helgenberger of \"C.S.I.\"",
    "Helgenberger of \"CSI\"",
    "Actress Helgenberger"
  ],
  "eyer": [
    "One who's looking",
    "One ogling",
    "Watchful one",
    "Observer",
    "Assessor",
    "Leering sort",
    "Scrutinizer",
    "Loupe user, say",
    "One who's \"just looking\"",
    "Observer",
    "One making a visual assessment",
    "Assessor",
    "Appraiser",
    "Observer",
    "Appraiser",
    "Ogler",
    "One giving the once-over",
    "Witness",
    "Viewer",
    "One with good looks?",
    "One taking a gander",
    "One who is \"just looking\"",
    "Observer",
    "Looker",
    "Ogler",
    "Observer",
    "Beholder",
    "Watchful one",
    "Watcher",
    "Flirtatious one",
    "Ogler",
    "Inspector",
    "Ogler",
    "Checker of a sort",
    "Giver of regards",
    "Watchful one",
    "Inspector",
    "Spectator"
  ],
  "geds": [
    "Alternatives to H.S. diplomas",
    "Certifications in some college apps",
    "H.S. dropouts' documents",
    "H.S. proficiency exams",
    "Some home-schoolers get them, briefly",
    "Tests that consist of five subjects, for short",
    "Certifications on some college apps",
    "People may get them before going to coll.",
    "Some students' goals: Abbr.",
    "H.S. dropouts' redemptions"
  ],
  "mraz": [
    "Pop singer Jason",
    "Jason with the 2008 hit \"I'm Yours\"",
    "2009 Grammy winner for \"Make It Mine\"",
    "Singer Jason"
  ],
  "soin": [
    "\"___ Love\" (Cole Porter song)",
    "\"___ conclusion...\" (wrapping-up phrase)"
  ],
  "ajam": [
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "Stuck, after \"in\"",
    "In ___ (stuck)",
    "In ___ (having trouble)",
    "In ___ (stuck)"
  ],
  "ahum": ["Buzzing", "Buzzing", "Extremely busy", "Buzzing", "Like a beehive"],
  "mtst": [
    "___ Helens",
    "___ Mary's (L.A. college)",
    "___ Helens",
    "___ Helens",
    "___ Helens",
    "___ Helens"
  ],
  "ikid": [
    "\"Just playing\"",
    "\"Just joshin'!\"",
    "\"It's a joke\"",
    "\"Just foolin'\"",
    "Jocular disclaimer",
    "\"Not serious!\"",
    "\"Just playin'\"",
    "\"Just messin' with you!\"",
    "\"Don't take my criticism seriously\"",
    "\"Just playin' with ya\"",
    "\"___ you not\""
  ],
  "masc": [
    "Foreign language dictionary abbr.",
    "Like the German article \"der\": Abbr.",
    "Like the Spanish nouns \"gato\" (cat) and \"perro\" (dog): Abbr.",
    "Not fem.",
    "Like the Fr. \"le,\" but not \"la\"",
    "Like some Fr. nouns",
    "Like Italian \"bread,\" e.g.: Abbr.",
    "Opposite of fem.",
    "More yang than yin: Abbr.",
    "Gender abbr.",
    "Like 60-Down: Abbr.",
    "Like bueno but not buena: Abbr.",
    "Like \"el\" or \"le\": Abbr.",
    "Pronoun designation: Abbr.",
    "Opposite of fem.",
    "Not fem.",
    "Not fem.",
    "Like some Sp. nouns",
    "Like il but not elle: Abbr.",
    "Like some Fr. nouns",
    "Like El Nino: Abbr.",
    "Neither fem. nor neut.",
    "Like Spanish \"eyes,\" e.g.: Abbr.",
    "Like some pronouns: Abbr.",
    "Like \"der,\" in Ger.",
    "Fem. opposite",
    "Like \"Spanish\" in Spanish: Abbr.",
    "Foreign noun designation: Abbr.",
    "Like some names: Abbr.",
    "Like some nouns: Abbr.",
    "Like some Fr. nouns",
    "Like some pronouns: Abbr.",
    "Like the yang: Abbr.",
    "Virile: Abbr.",
    "Opposite of fem.",
    "Not fem.",
    "Opposite of fem."
  ],
  "oise": [
    "River of northern France",
    "Seine tributary",
    "French river or department",
    "River to the Seine",
    "Department in Picardy",
    "Chantilly's department",
    "Seine tributary",
    "River to the Seine",
    "Van Gogh's \"L'Eglise d'Auvers-sur-___\"",
    "Beauvais's department",
    "French river",
    "Seine tributary",
    "Department north of Paris",
    "River to the Seine",
    "Seine feeder",
    "Seine tributary",
    "Auvers-sur-___, last home of Vincent van Gogh",
    "Chantilly's department",
    "Seine tributary",
    "River to the Seine",
    "River to the Seine",
    "Department that is home to the Parc Asterix amusement park",
    "Seine feeder",
    "Seine tributary",
    "Seine feeder",
    "Chantilly's department",
    "Seine feeder",
    "River to the Seine",
    "Seine tributary",
    "River to the Seine",
    "Tributary of the Seine",
    "Seine tributary",
    "It rises in the Ardennes",
    "Seine tributary",
    "Chantilly's department",
    "Seine feeder",
    "Seine feeder",
    "Seine tributary",
    "Seine tributary",
    "Seine feeder",
    "River to the Seine",
    "Department north of Paris",
    "Seine feeder",
    "River to the Seine",
    "Seine tributary",
    "River near Chantilly",
    "Seine feeder",
    "Seine feeder",
    "French river",
    "Department north of Paris",
    "River of France",
    "Compiegne's river",
    "Seine tributary",
    "French river or department",
    "Belgian waterway",
    "Department of northern France",
    "Seine feeder",
    "Seine feeder",
    "Seine feeder",
    "River to the Seine",
    "River of northern France",
    "French flower",
    "Seine tributary",
    "Compiegne's river",
    "Ile-de-France river",
    "River to the Seine"
  ],
  "nyuk": [
    "Three Stooges laugh sound",
    "When repeated, a Stooge's laugh",
    "Three Stooges laugh sound",
    "Stooge syllable",
    "Stooge's laugh syllable"
  ],
  "amaj": [
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key with three sharps: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key of Schubert's \"Trout\" Quintet: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key of Mozart's \"Turkish\" Concerto: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key of Schubert's \"Trout\" Quintet: Abbr.",
    "Key with three sharps: Abbr.",
    "Setting of Mozart's only clarinet concerto: Abbr.",
    "Key of Beethoven's Seventh: Abbr.",
    "Key with three sharps: Abbr.",
    "Key related to F# minor: Abbr.",
    "Key with three sharps: Abbr.",
    "Key related to F# minor: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr.",
    "Key to Mozart's 21st: Abbr.",
    "Key of Beethoven's Symphony No. 7: Abbr."
  ],
  "laic": [
    "Concerning the nonordained",
    "Of the flock",
    "Secular",
    "Of the flock",
    "Secular",
    "Of the flock",
    "Secular",
    "Of the flock",
    "Of the flock",
    "Nonclerical",
    "Of a church flock",
    "Not of the clergy",
    "Nonclerical",
    "Secular",
    "Not of the cloth",
    "Secular",
    "Of the flock",
    "Not clerical",
    "Of the congregation",
    "Of the flock",
    "Congregational",
    "Flock member",
    "Not of the cloth",
    "Secular",
    "Of the flock",
    "Not of the clergy",
    "Not of the cloth",
    "Not of the cloth",
    "Secular",
    "Not of the cloth",
    "Secular",
    "Of the flock",
    "Flock member",
    "Of the flock",
    "Nonclerical",
    "Secular",
    "Like some church matters",
    "Like some church matters",
    "Of the flock",
    "Not of the cloth",
    "Of the flock",
    "Flock-related",
    "Like some church matters",
    "Regarding some church matters",
    "Of the flock",
    "Clerk's counterpart",
    "Secular",
    "Nonclerical",
    "Of the flock",
    "Secular",
    "Secular",
    "Secular",
    "Like some church matters",
    "Not clerical",
    "Secular",
    "Secular",
    "From the flock",
    "Churchgoer",
    "Of churchgoers",
    "Not clerical",
    "Nonclerical",
    "Secular",
    "Not clerical",
    "Of the flock",
    "Not cleric"
  ],
  "tfal": [
    "Big name in cookware",
    "Big name in cookware",
    "Nonstick cookware brand",
    "Kitchenware brand with a hyphenated name",
    "Nonstick pan brand",
    "Cookware brand",
    "Purveyor of nonstick cookware"
  ],
  "pima": [
    "Native people of southern Arizona",
    "High-grade cotton",
    "High-quality cotton",
    "Fine cotton",
    "High-grade cotton",
    "Variety of cotton",
    "Fine grade of cotton",
    "Fine cotton",
    "Southwest native",
    "Gila River native",
    "People of the Southwest",
    "Cotton fabric",
    "Tucson's county",
    "River People of the Southwest",
    "Strong cotton",
    "Sheet cotton",
    "Arizona Indian",
    "Variety of fine cotton",
    "Fine cotton",
    "Arizona native",
    "Mexican Indian",
    "Variety of cotton"
  ],
  "ilre": ["Mozart's \"___ Pastore\"", "\"___ Pastore\" (Mozart opera)"],
  "orle": [
    "Coat-of-arms border",
    "Shield border",
    "Heraldic wreath",
    "Heraldic border",
    "Heraldic border",
    "Shield border, in heraldry",
    "Edge on a shield",
    "Shield border",
    "Heraldic band",
    "Shield border",
    "Shield border, in heraldry",
    "Shield border",
    "Heraldic band",
    "Shield border",
    "Shield border",
    "Shield border",
    "Heraldic border",
    "Crest bearer in heraldry",
    "Heraldic border",
    "Shield border",
    "Heraldic border",
    "Heraldic border",
    "Heraldic border",
    "Heraldic wreath",
    "Shield border",
    "Heraldic charge",
    "Shield border",
    "Heraldic band",
    "Heraldic band",
    "Heraldic band"
  ],
  "kett": [
    "\"Etta ___\" (old comic strip)",
    "Etta of old comics",
    "Etta of old comics",
    "Etta of old comics",
    "Last name of a comic strip title teen",
    "Last name in the funnies for nearly 50 years",
    "\"Etta ___\" (old comic strip)",
    "Etta of old comics",
    "Teenager of old comics",
    "Etta of old comics",
    "See 73-Down",
    "Etta of old comics",
    "\"Etta ___\"",
    "\"Etta ___\"",
    "Etta of the comics",
    "Funny Etta",
    "Etta of the funnies",
    "Etta of old comics",
    "Etta of old comics"
  ],
  "lech": [
    "Peace Nobelist Walesa",
    "Solidarity leader Walesa",
    "Source of dirty looks",
    "Polish hero Walesa",
    "First name in Polish politics",
    "Hardly a good looker",
    "Poland's Walesa",
    "Nobelist Walesa",
    "Nobelist Walesa",
    "Poland's Walesa",
    "Solidarity's Walesa",
    "Lustful one, informally",
    "Polish Nobelist Walesa",
    "Poland's Walesa",
    "Polish hero Walesa",
    "Creepy sort, for short",
    "Nobelist Walesa",
    "Poland's Walesa",
    "Satyr, slangily",
    "First name in Polish politics",
    "Poland's Walesa",
    "Poland's Walesa",
    "Poland's Walesa",
    "Preyer",
    "Poland's Walesa",
    "Poland's Walesa",
    "Poland's Walesa",
    "Poland's Walesa",
    "Poland's Walesa",
    "First name in Solidarity",
    "Walesa of Warsaw"
  ],
  "drub": [
    "Clobber",
    "Give a beating",
    "Rout",
    "Beat decisively",
    "Shellac",
    "Beat badly",
    "Wallop",
    "Cudgel",
    "Cudgel",
    "Defeat decisively",
    "Clobber",
    "Wallop but good",
    "Trounce",
    "Beat soundly",
    "Defeat decisively",
    "Trounce"
  ],
  "cpls": [
    "Sgts. outrank them",
    "Two-stripe NCOs: Abbr.",
    "Inferiors to sgts.",
    "They're over specialists: Abbr.",
    "They rank above Pfc.'s"
  ],
  "saud": [
    "Mideast royal name",
    "Mideast royal name",
    "Mideast royal house",
    "Mideast ruler",
    "Mideast royal name",
    "Mideast's House of ___",
    "Royal house",
    "Mideastern royal family name",
    "King who abdicated in 1964",
    "King Faisal's brother",
    "Mideast royal name",
    "50's-60's Mideast king"
  ],
  "dyke": [
    "Comic actor Dick Van ___",
    "Dick Van ___",
    "Director W. S. Van ___"
  ],
  "rstu": [
    "Q-V link",
    "Alphabet quartet",
    "Q-V connection",
    "Queue after Q",
    "VW forerunners?",
    "They're 18 to 21",
    "Queue after Q",
    "Queue after Q",
    "Alphabet string",
    "Alphabet quartet",
    "Queue after Q",
    "Set before V",
    "VW predecessor",
    "Queue after Q",
    "Queue after Q",
    "Pre-V formation",
    "Queue after Q",
    "V preceder",
    "Alphabet bit",
    "Letter run",
    "Queue after Q",
    "Alphabetical sequence",
    "Alphabet quartet",
    "Queue after Q",
    "Alphabet run",
    "Alphabet sequence",
    "Stadium section"
  ],
  "epos": [
    "Heroic poetry",
    "Some narrative writing",
    "\"Beowulf\" and others",
    "Poems featuring 39-Across",
    "\"Odyssey,\" for one",
    "Long narrative poem",
    "\"Beowulf\" or \"Gilgamesh\"",
    "Heroic verse",
    "Apollonius of Rhodes' \"Argonautica,\" e.g.",
    "\"Gilgamesh,\" e.g.",
    "Narrative writing",
    "Long narrative poem",
    "Poetic storytelling",
    "Poetic saga",
    "Heroic verse",
    "Oral verse",
    "Work of Homer",
    "\"Odyssey,\" e.g.",
    "\"Aeneid,\" e.g.",
    "Works of Homer",
    "\"Beowulf,\" e.g.",
    "\"Iliad,\" \"Odyssey\" and the like",
    "\"Iliad\" or \"Odyssey\"",
    "\"Beowulf,\" for one",
    "Poetry studied in Greek class",
    "Oral poetry",
    "\"Iliad\" and \"Odyssey,\" e.g.",
    "Lofty verse",
    "Group of poems",
    "Heroic poetry",
    "Virgil genre",
    "Thematic poetry",
    "Homeric poetry",
    "Narrative poetry",
    "The \"Odyssey,\" e.g.",
    "Heroic poetry",
    "Grandiose poetry"
  ],
  "qual": ["Preliminary exam: Abbr.", "Kind of analysis: Abbr."],
  "miro": [
    "Artist given the 1958 Guggenheim International Award",
    "\"Triptych Bleu I, II, III,\" e.g.",
    "\"Harlequin's Carnival\" painter",
    "Joan of art",
    "\"The Farm\" painter, 1921",
    "Barcelona's Joan ___ Foundation",
    "\"The Hunter (Catalan Landscape)\" painter",
    "Artist Joan",
    "Abstract artist Joan",
    "\"The Tilled Field\" painter Joan",
    "A Barcelona museum is dedicated to his work",
    "\"Harlequin's Carnival,\" for one",
    "Spanish Surrealist",
    "Spanish artist Joan",
    "Muralist Joan",
    "\"Blue II\" painter, 1961",
    "\"Harlequin's Carnival\" painter",
    "Surrealist Joan",
    "Ceramic muralist for the Unesco building in Paris",
    "\"Dog Barking at the Moon\" painter, 1926",
    "\"Dutch Interior\" painter",
    "\"The Farm\" or \"Still Life With Old Shoe\"",
    "Surrealist Joan",
    "Catalan-born Surrealist",
    "Catalan painter Joan",
    "Spanish Surrealist Joan",
    "Dali contemporary",
    "Joan of art",
    "Joan of art",
    "Fantastical artist",
    "Harvard muralist",
    "Spanish Surrealist",
    "Dali contemporary",
    "Barcelona-born artist",
    "Spanish Surrealist",
    "Joan of art",
    "Leading surrealist",
    "Picasso contemporary",
    "Spanish Surrealist",
    "Painter Joan",
    "Joan of art",
    "Muralist Joan"
  ],
  "roto": [
    "Picture section in old newspapers",
    "___-tiller",
    "___-tiller",
    "Postcard printing process, for short",
    "Spinning: Prefix",
    "___-Rooter",
    "Tiller attachment?",
    "___-Rooter",
    "___-Rooter",
    "Commercial lead-in for Rooter",
    "Prefix with tiller",
    "Lead-in to tiller",
    "___-Rooter",
    "___-Rooter",
    "___-Rooter",
    "___-Rooter",
    "___-tiller",
    "Old printing process, for short",
    "___-Rooter",
    "Old newspaper section",
    "___-Rooter",
    "___-Rooter",
    "Prefix with tiller",
    "___-Rooter",
    "Old paper section",
    "___-Rooter",
    "___-Rooter",
    "Old paper part",
    "Old newspaper section",
    "___-tiller",
    "Old newspaper section",
    "Old paper part",
    "Old newspaper part",
    "___-Rooter",
    "Prefix with gravure",
    "___-Rooter",
    "___-Rooter",
    "___-Rooter",
    "Rooter preceder",
    "Old newspaper section",
    "Old photo print",
    "Photo process",
    "___-tiller",
    "___-Rooter",
    "Old newspaper section",
    "Rooter starter",
    "Old Sunday paper section",
    "Tiller's start",
    "Old newspaper section",
    "___-Rooter",
    "___ Rooter",
    "___-Rooter",
    "___-Rooter",
    "___-tiller",
    "Old newspaper section"
  ],
  "gust": [
    "Big wind",
    "Burst of wind",
    "Indicator of a coming storm",
    "Brief blow",
    "Wind that might blow one's hat off",
    "Sudden wind",
    "Burst of wind",
    "Kite flier's wish",
    "Burst of wind",
    "Sudden blow",
    "Sudden burst",
    "Heavy blow",
    "Anemometer spinner",
    "Sudden burst",
    "Burst of wind",
    "Sudden rush of air",
    "Anemometer's measurement",
    "Sudden wind"
  ],
  "fang": [
    "Scary part of a wolf",
    "Vampire's telltale sign",
    "Biter",
    "Sucker for vampire stories?",
    "Long tooth",
    "Dracula's biter",
    "Venom conduit",
    "Point of a vampire story?",
    "Canine",
    "One drawing blood",
    "Canine on a feline",
    "Part of a vampire",
    "Snake's bioweapon",
    "Vampire's tooth",
    "Dracula feature",
    "Werewolf's tooth",
    "Part of the mouth of a cottonmouth",
    "Weapon of 59-Across",
    "Poison injector",
    "Cobra's weapon",
    "Dracula feature",
    "Point of attack?",
    "Adder's threat",
    "Poison conduit",
    "Wolf's tooth",
    "Poison administrant",
    "Vampire's tooth",
    "\"White ___\""
  ],
  "bayh": [
    "1990s Indiana governor Evan",
    "Evan or Birch of Indiana politics",
    "Sen. Evan of Indiana",
    "Evan of the Senate",
    "Two-generation name in Indiana politics",
    "Evan from Indiana",
    "Indiana governor Evan"
  ],
  "yugo": [
    "Bygone auto whose name sounds like a command",
    "1980s auto",
    "\"Worst car of the millennium,\" per \"Car Talk\"",
    "Much-derided 1980s-'90s car",
    "\"Car Talk\" dubbed it \"the worst car of the millennium\""
  ],
  "lans": [
    "Office PC connections",
    "Telecom hookups",
    "Telecom hookups",
    "They serve many computer clients: Abbr."
  ],
  "kalb": [
    "Former \"Meet the Press\" host Marvin",
    "Former \"Meet the Press\" host Marvin",
    "Journalist Marvin or Bernard",
    "1980s \"Meet the Press\" host Marvin",
    "Newsman Marvin or Bernard",
    "Former \"Meet the Press\" host Marvin",
    "Newsman Marvin",
    "Former \"Meet the Press\" moderator Marvin",
    "Former \"Meet the Press\" host Marvin",
    "Henry Kissinger biographer Marvin",
    "Marvin or Bernard",
    "TV journalist Marvin"
  ],
  "hvac": ["Climate control syst."],
  "peri": [
    "Prefix with -scope",
    "Prefix with scope",
    "Gilpin of \"Frasier\"",
    "Prefix with -meter or -scope",
    "Gilpin of \"Frasier\"",
    "Prefix with -scope",
    "Graceful genie of myth",
    "Persian fairy",
    "Graceful fairy",
    "Actress Gilpin of \"Frasier\"",
    "Mythological sprite",
    "Actress Gilpin of \"Frasier\"",
    "Actress Gilpin of \"Frasier\"",
    "Gilpin of \"Frasier\"",
    "Prefix with scope or meter",
    "Gilpin of \"Frasier\"",
    "Jacopo ___, composer of the earliest surviving opera",
    "Gilpin of \"Frasier\"",
    "Persian sprite",
    "Persian spirit",
    "Fairylike being of myth",
    "Beautiful fairylike being",
    "Prefix with scope or meter",
    "Prefix with scope",
    "\"Frasier\" actress Gilpin",
    "Persian spirit",
    "\"Frasier\" actress Gilpin",
    "Lovely young woman",
    "Prefix with scope or meter",
    "Persian sprite",
    "Prefix with scope",
    "Elf of Persian folklore",
    "Persian sprite"
  ],
  "amer": [
    "The \"A\" of U.S.A.: Abbr.",
    "The New World: Abbr.",
    "AAA part: Abbr.",
    "A.C.L.U. part: Abbr.",
    "The \"A\" of N.A. or S.A.: Abbr.",
    "The \"A\" in U.S.A.: Abbr.",
    "Part of A.M.A.: Abbr.",
    "U.S.A. part: Abbr.",
    "Part of AARP: Abbr.",
    "Ascap part: Abbr.",
    "C.S.A. part: Abbr.",
    "A.M.A. member?: Abbr.",
    "New World abbr.",
    "Vista part: Abbr.",
    "From the States: Abbr.",
    "The \"A\" in U.S.A.: Abbr.",
    "Like Old Glory: Abbr.",
    "Part of O.A.S.: Abbr.",
    "AARP part: Abbr.",
    "Part of C.S.A.: Abbr.",
    "Western Hemisphere abbr.",
    "The New World: Abbr.",
    "Continental abbr.",
    "From the U.S.",
    "Part of VISTA: Abbr.",
    "U.S.: Abbr.",
    "New World abbr.",
    "From the States: Abbr.",
    "Part of A.C.L.U.: Abbr.",
    "From the United States: Abbr.",
    "Part of 5-Down: Abbr.",
    "New World abbr.",
    "From the U.S.",
    "Abbr. after N. or S.",
    "Part of A.C.L.U.: Abbr.",
    "Like the top medalists at the 2000 Olympics: Abbr.",
    "One of the maj. leagues",
    "Part of Ascap: Abbr.",
    "From the U.S.: Abbr.",
    "Western Hemisphere abbr.",
    "From the U.S.",
    "U.S.A. part: Abbr.",
    "One of the major leagues: Abbr.",
    "Certain league: Abbr.",
    "Part of A.B.A.: Abbr.",
    "Part of A.A.R.P.: Abbr.",
    "Continental abbr.",
    "New World abbr.",
    "W. Hemisphere land",
    "New World abbr.",
    "Western Hemisphere abbr.",
    "U.S.",
    "New World abbr.",
    "From the U.S.: Abbr.",
    "New World abbr.",
    "Part of A.F.L.-C.I.O.: Abbr.",
    "From the U.S. of A."
  ],
  "ordo": [
    "Latin word on a dollar bill",
    "Latin word on a dollar bill",
    "Latin word on the back of a dollar bill",
    "Great Seal word",
    "Great Seal word",
    "Great Seal word",
    "Novus ___ seclorum (Great Seal phrase)",
    "Novus ___ seclorum (phrase on a dollar)",
    "Latin word on a dollar bill",
    "Novus ___ seclorum (Great Seal phrase)",
    "Word on the Great Seal",
    "Mass booklet",
    "Church calendar",
    "Church calendar",
    "Word on a $1 bill"
  ],
  "suns": [
    "Earns a bronze?",
    "Tatooine has two of them",
    "Stars",
    "Goes for the bronze?",
    "Phoenix athletes",
    "Stars",
    "Catches some rays",
    "Lies out",
    "Lies on the beach",
    "Goes for the bronze?",
    "Heavenly orbs",
    "Phoenix five",
    "Phoenix team",
    "Tans",
    "Soaks up rays",
    "Arizona N.B.A.'ers",
    "Stars",
    "Stars",
    "Phoenix fivesome",
    "Phoenix five",
    "America West Arena team",
    "Sir Charles and chums, in basketball",
    "Charles Barkley's crew",
    "Goes for the bronze"
  ],
  "fens": [
    "Swamps",
    "Moors",
    "Marshes",
    "Peaty places",
    "Boggy lands",
    "Sloughs",
    "Wetlands",
    "Boggy places",
    "Lowlands",
    "Bogs",
    "Peaty areas",
    "Sloughs",
    "Swampy spots",
    "Marshlands",
    "Peaty areas",
    "Low peaty lands",
    "Lincolnshire locale, with \"The\""
  ],
  "buck": [
    "Smacker",
    "Simoleon",
    "Go against",
    "Simoleon",
    "One, informally",
    "Oppose",
    "What rodeo horses do",
    "Go against",
    "Dollar",
    "Resist obstinately"
  ],
  "ueys": [
    "Usually illegal maneuvers",
    "Dangerous things to do at busy intersections",
    "180s",
    "180s",
    "180s",
    "Often-illegal turns, informally",
    "180s",
    "Often-illegal turns, in slang",
    "180's, slangily",
    "Some chase scene maneuvers, slangily",
    "One-eighties",
    "One-eighties",
    "180's"
  ],
  "pict": [
    "Ancient dweller beyond Hadrian's Wall",
    "Briton of old",
    "Ancient Briton",
    "Ancient Brit",
    "Roman's foe of yore",
    "Early Inverness resident",
    "Early Briton",
    "Pre-Celtic person",
    "Ancient Briton",
    "Orkney Islands settler",
    "Orkney Islands ancient",
    "Early Briton",
    "Early Inverness resident",
    "Early Brit",
    "Ancient Briton",
    "Ancient Brit",
    "Early Brit",
    "Ancient Brit",
    "Ancient Briton",
    "Early Briton"
  ],
  "hung": [
    "Like Christmas decorations and some juries",
    "Like some juries",
    "Like a jury that can't reach a decision",
    "At an impasse",
    "Unable to decide, in court",
    "Placed on a wall, as a picture",
    "Like paintings and some juries",
    "Up, in a way",
    "Like some juries",
    "Permanently undecided",
    "Displayed, in a way",
    "Hovered",
    "Put up"
  ],
  "mvps": [
    "Some postseason awardees, for short",
    "End-of-season honorees, for short",
    "Sportswriters' picks, for short",
    "Court stars, maybe, in brief",
    "Troy Aikman, John Elway and others, in brief",
    "Biggest stars, briefly",
    "A.L. or N.L. honorees"
  ],
  "russ": [
    "Former Wisconsin senator Feingold",
    "Bygone sportscaster Hodges",
    "Football Hall-of-Famer Grimm",
    "Hodges who called baseball's \"shot heard 'round the world\"",
    "Former Wisconsin senator Feingold",
    "Wisconsin senator Feingold",
    "Sen. Feingold",
    "Sen. Feingold",
    "Old bandleader Columbo",
    "Director Meyer",
    "\"tom thumb\" star Tamblyn",
    "Moscow's land: Abbr.",
    "Ukr. neighbor",
    "Crooner Columbo",
    "\"Tom Thumb\" star Tamblyn",
    "\"Tom Thumb\" star Tamblyn, 1958",
    "30's crooner Columbo",
    "Ex-sportscaster Hodges",
    "Director Meyer",
    "Old bandleader Morgan",
    "Wisconsin Senator Feingold",
    "Bandleader Columbo",
    "\"Broom Hilda\" creator Myers"
  ],
  "ifat": [
    "\"___ first you don't succeed ...\"",
    "\"___ first ...\"",
    "\"___ first you don't succeed ...\"",
    "\"___ first you don't succeed ...\"",
    "___ all possible",
    "\"___ first you don't succeed ...\"",
    "\"___ first ...\"",
    "\"___ first you don't succeed ...\"",
    "\"___ first you don't succeed ...\"",
    "\"___ first ...\"",
    "\"___ all possible\"",
    "\"___ first you ...\"",
    "\"___ first you don't succeed ...\"",
    "\"___ first you don't succeed ...\"",
    "\"___ first ...\"",
    "\"___ first you don't succeed ...\"",
    "\"___ first ...\"",
    "\"___ first ...\"",
    "\"___ first ...\"",
    "\"___ first ...\"",
    "\"___ first ...\"",
    "\"___ first you ...\"",
    "\"___ first ...\"",
    "\"___ first you don't...\"",
    "\"___ first you don't...\"",
    "\"___ first you...\"",
    "\"___ first...\"",
    "\"___ first you ...\"",
    "\"___first you...\""
  ],
  "ewen": [
    "Actor Bremner of \"Wonder Woman\"",
    "Actor Bremner of \"Black Hawk Down\"",
    "Actor Bremner of \"Trainspotting\" and \"Black Hawk Down\"",
    "Gershwin biographer David",
    "Gershwin biographer David",
    "David ___, Cole Porter biographer",
    "\"Trainspotting\" star Bremner",
    "Writer on music David ___",
    "Gershwin biographer David",
    "Gershwin biographer David",
    "\"Music for the Millions\" author David",
    "Gershwin biographer David"
  ],
  "gunn": [
    "Ben ___, pirate in \"Treasure Island\"",
    "Fashion guru Tim",
    "\"Peter ___\" (1950s-'60s detective show)",
    "Private eye Peter of old TV",
    "Ben ___, \"Treasure Island\" pirate",
    "Jazz-loving TV sleuth of the 1950s-'60s",
    "\"Treasure Island\" character",
    "See 117-Across",
    "Detective who frequented the jazz club Mother's",
    "\"Treasure Island\" pirate Ben ___",
    "\"Treasure Island\" character",
    "\"Peter ___\" of 50's-60's TV",
    "Old TV detective Peter",
    "TV private eye Peter"
  ],
  "dits": [
    "Dahs' counterparts in Morse code",
    "Most of an S O S",
    "Morse units, informally",
    "Morse clicks",
    "Counterparts of dahs in Morse code",
    "Morse code for \"sissies\"",
    "Counterparts of dahs",
    "A Morse \"I\" consists of two",
    "Code signals",
    "E's, I's and S's, in Morse code",
    "Morse marks",
    "Dah's counterparts"
  ],
  "adas": [
    "Some prosecutors, for short",
    "Some prosecutors, for short",
    "Ones helping a public prosecutor, for short",
    "Some prosecutors, for short",
    "Some prosecutors: Abbr.",
    "Computer pioneer Lovelace and others",
    "Architecture critic Huxtable and others",
    "Nabokov heroine and others",
    "County seats in Minnesota and Oklahoma",
    "Roles in \"The Piano\" and \"Tobacco Road\"",
    "Actress Rehan and others",
    "\"Bleak House\" girl and others",
    "Nabokov heroine and others",
    "Nabokov heroine and namesakes",
    "Stage actress Rehan et al.",
    "Nabokov heroine et al.",
    "Critic Huxtable and others"
  ],
  "gita": [
    "Bhagavad-___ (Sanskrit text)",
    "Bhagavad ___ (Hindu text)",
    "Bhagavad ___ (Hindu scripture)"
  ],
  "drib": [
    "Minute amount",
    "Tiny bit",
    "Little bit",
    "Tiny amount",
    "Tiny bit",
    "Small amount",
    "Tiny amount",
    "Smidgen",
    "Small quantity",
    "Wee bit",
    "Smidgen",
    "Small amount",
    "Minute amount",
    "Small amount"
  ],
  "weep": [
    "Openly lament",
    "Cry",
    "Shed tears",
    "Break down, in a way",
    "Break down",
    "Boohoo",
    "Cry",
    "Exhibit some grief",
    "Spill tears",
    "Mourn openly",
    "Grieve openly",
    "Cry",
    "Partner of \"read 'em\"",
    "Shed tears",
    "Break down",
    "Break down, in a way",
    "Boo-hoo",
    "Emulate Niobe",
    "Have a bawl",
    "Bawl",
    "Blubber",
    "Boo-hoo",
    "Get blubbery",
    "Sob",
    "Show grief",
    "Mourn audibly",
    "Cry",
    "Have a bawl",
    "What a card reader may do?",
    "Tear up",
    "Grieve (for)",
    "Break down",
    "Blubber",
    "Display dolor",
    "Break down",
    "Partner of read 'em",
    "Have a bawl",
    "Blubber"
  ],
  "bahn": [
    "German route",
    "German way",
    "German train track",
    "U-___ (Berlin railway)",
    "Ruhr road",
    "Road, in the Rheinland",
    "Volkswagen route",
    "Ruhr road",
    "Road to Rostock",
    "Where to take a Volkswagen for a spin",
    "Rheinland road",
    "Road to the Rhein"
  ],
  "woke": [
    "Socially aware, modernly",
    "Aware, in a modern way",
    "Roused",
    "Opened one's eyes in the morning",
    "Shook out of dreamland",
    "More than stirred",
    "Shook, maybe",
    "Regained consciousness",
    "Came to",
    "Stirred",
    "Came alive",
    "Stopped sleeping"
  ],
  "zing": [
    "Hit with a deft comeback",
    "Stimulating quality",
    "Pizazz",
    "Cry after a successful insult",
    "Pizazz",
    "Throw a barb at",
    "Get but good",
    "Insult, comically",
    "Hurl an insult at",
    "Pizazz",
    "Tabasco's effect",
    "Bite",
    "Hurl a barb at",
    "Animation",
    "Sound of swift passage",
    "Get in a good one on",
    "Lambaste, slangily",
    "Elan",
    "Criticize cleverly",
    "Throw barbs at"
  ],
  "dogg": [
    "Snoop ___",
    "Rapper Snoop ___",
    "Snoop ___",
    "\"Malice N Wonderland\" rapper Snoop ___",
    "Rapper Snoop ___",
    "Snoop ___",
    "Rapper Snoop ___"
  ],
  "kopf": ["Head: Ger.", "Head: Ger."],
  "daly": [
    "Tony and Emmy winner Tyne",
    "NBC host Carson",
    "Golfer nicknamed Long John",
    "Emmy-winning Tyne",
    "New Year's Eve host Carson",
    "New Year's Eve ball-drop commentator beginning in 2003",
    "1991 P.G.A. champion John",
    "Original \"What's My Line?\" host John",
    "Golfer John",
    "Tony-winning Tyne",
    "Tyne of \"Cagney & Lacey\"",
    "Tony winner Tyne",
    "Big John of golf",
    "1990 Tony winner for \"Gypsy\"",
    "Tyne of \"Judging Amy\"",
    "John ___, 1995 British Open winner",
    "\"Judging Amy\" actress",
    "Carson ___ of MTV",
    "Tyne of \"Judging Amy\"",
    "Actress Tyne",
    "Emmy-winning Tyne",
    "1995 British Open winner John",
    "Tyne or Timothy",
    "Lacey on \"Cagney & Lacey\"",
    "?ôWhat?Æs My Line?ö host John, 1950-67",
    "Former Pistons coach Chuck",
    "\"What's My Line\" host John"
  ],
  "yuks": [
    "Just for ___",
    "Hardy-har-hars",
    "Belly laughs",
    "Big laughs, slangily",
    "Big laughs",
    "Big laughs",
    "Loud laughs",
    "Hearty har-hars",
    "Loud laughs",
    "Big laughs",
    "Laughs over some unsophisticated humor",
    "Big laughs",
    "Cheap laughs",
    "Cheap laughs",
    "Stooges' laughs"
  ],
  "vsop": [
    "Cognac age indicator",
    "Abbr. on a label of brandy",
    "Cognac letters",
    "Brandy grade, briefly",
    "Cognac bottle letters",
    "Abbr. on a bottle of Courvoisier",
    "Letters signifying quality brandy",
    "Letters on a Remy Martin bottle",
    "Cognac bottle initials",
    "Letters on a brandy bottle",
    "Letters on a cognac bottle",
    "Brandy bottle abbr."
  ],
  "rect": [
    "Geom. figure",
    "Door shape: Abbr.",
    "Geom. shape",
    "Four-sided fig.",
    "Geom. shape",
    "Geom. figure",
    "Like most city blocks: Abbr.",
    "Geom. figure",
    "Prefix with angular",
    "Mathematical fig.",
    "Domino-shaped fig.",
    "Prefix with angular",
    "Geom. figure",
    "Geom. shape",
    "Four-sided fig.",
    "Geometric fig.",
    "Prefix with angle",
    "Geom. shape",
    "Quadrilateral fig.",
    "Right: Prefix",
    "Straight prefix"
  ],
  "itso": [
    "\"Wishing won't make ___\"",
    "\"You've never had ___ good\"",
    "\"___ happens ...\"",
    "\"Don't take ___ seriously!\"",
    "\"Make ___\" (Picard's command on \"Star Trek: T.N.G.\")",
    "\"Make ___!\"",
    "\"Wishing won't make ___\"",
    "\"Don't take ___ seriously!\"",
    "\"Wishing won't make ___\"",
    "\"Wishing won't make ___\"",
    "\"Now, was ___ bad?\"",
    "\"Wishing won't make ___\"",
    "\"Don't take ___ seriously\"",
    "\"Wishing Will Make ___\"",
    "\"Wishing won't make ___\"",
    "\"Make ___!\" (captain's order)",
    "\"Make ___\" (captain's directive)",
    "\"Wishing will make ___\" (pop song lyric)"
  ],
  "lodi": [
    "Site of a 1796 Napoleon victory",
    "California wine city",
    "1796 Napoleon battle site",
    "City in New Jersey or California",
    "Battle of ___, 1796 Napoleon victory",
    "California wine locale",
    "Italian site of a 1796 Napoleon victory",
    "City in San Joaquin County",
    "Lombardy town",
    "City in California or New Jersey",
    "California city where A & W root beer was born",
    "City near Milan",
    "City near Sacramento",
    "California wine center",
    "Wine-making city of California",
    "Jersey city south of Paramus",
    "New Jersey city near Hackensack",
    "Town in Bergen County, N.J.",
    "New Jersey city next to Hackensack",
    "New Jersey city",
    "New Jersey city south of Paramus",
    "New Jersey city",
    "Felician College site",
    "City southeast of Milan",
    "Italian town, site of a 1796 Napoleon victory"
  ],
  "joad": [
    "Steinbeck's Ma or Pa",
    "1940 Fonda role",
    "\"The Grapes of Wrath\" surname",
    "Family name in \"The Grapes of Wrath\"",
    "\"The Ghost of Tom ___\" (1995 Bruce Springsteen album)",
    "\"The Grapes of Wrath\" family name",
    "Ma or Pa in a Steinbeck novel",
    "Tom of \"The Grapes of Wrath\"",
    "1940 Fonda role",
    "Bruce Springsteen album \"The Ghost of Tom ___\"",
    "Fictional Tom",
    "\"The Grapes of Wrath\" surname",
    "Woody Guthrie's \"Tom ___\"",
    "\"The Grapes of Wrath\" family name",
    "Steinbeck family name",
    "Steinbeck hero",
    "1940 Fonda role",
    "\"The Grapes of Wrath\" name",
    "\"The Grapes of Wrath\" name",
    "Steinbeck family name"
  ],
  "emer": [
    "Like a retired prof.",
    "Part of E.M.S.: Abbr.",
    "Reason to use the 44-Across: Abbr.",
    "Like a 911 call: Abbr.",
    "Part of FEMA: Abbr.",
    "Part of FEMA: Abbr.",
    "E.M.T. part: Abbr.",
    "Part of E.M.S.: Abbr.",
    "Part of FEMA: Abbr.",
    "FEMA part: Abbr.",
    "Like 911: Abbr.",
    "Matter of life and death: Abbr.",
    "E.R. part: Abbr.",
    "Part of FEMA: Abbr.",
    "Part of E.M.T.: Abbr.",
    "Like some profs.",
    "E.R. part: Abbr.",
    "Like some profs.",
    "The \"E\" in E.R.: Abbr.",
    "Like a retired prof.",
    "Part of E.M.T.: Abbr.",
    "Part of E.M.T.: Abbr.",
    "Kind of rm. for an R.N.",
    "Reason to call 911: Abbr.",
    "Kind of room at a hosp.",
    "Retired, as a prof.",
    "Like a 911 call: Abbr.",
    "Part of E.R.: Abbr.",
    "Like a 911 call: Abbr.",
    "Like some profs.",
    "Part of FEMA: Abbr.",
    "Life-or-death matter: Abbr.",
    "Like a 911 call: Abbr.",
    "Part of E.M.T.: Abbr.",
    "Like certain profs.",
    "Prof. ___ (ex-academic)"
  ],
  "sipe": [
    "Brian who was the 1980 N.F.L. M.V.P.",
    "Ex-Cleveland QB Brian",
    "1980 N.F.L. M.V.P. Brian",
    "A.F.C. passing leader, 1980",
    "Ex-Cleveland QB Brian",
    "Former Browns QB Brian"
  ],
  "xoxo": [
    "Common sign-off",
    "Short and sweet sign-off",
    "Love letters",
    "Letter ender",
    "Love letter letters",
    "Love letter sign-off",
    "Love letter sign-off",
    "Valentine letters",
    "Fan letters?",
    "Cutesy sign-off",
    "Love letters?",
    "Letters in love letters"
  ],
  "glum": [
    "Down in the dumps",
    "Down in the mouth",
    "Down",
    "Down in the dumps",
    "Down",
    "Wearing a long face",
    "Saturnine",
    "Down",
    "Down in the dumps",
    "Not at all cheerful",
    "Down at the mouth",
    "Like a sad sack",
    "Dejected",
    "Dour",
    "Saturnine",
    "Rather morose",
    "Unhappy"
  ],
  "tsos": [
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "Chinese menu possessive",
    "General ___ chicken",
    "Chinese menu possessive",
    "General ___ chicken",
    "Possessive on a Chinese menu",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "Chinese menu possessive",
    "Chinese menu word",
    "General ___ chicken",
    "Possessive on Chinese menus",
    "Chinese menu word",
    "General ___ chicken",
    "Chinese menu possessive",
    "Possessive on a Chinese menu",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken (Chinese menu item)",
    "General ___ chicken",
    "General ___ chicken",
    "General ___ chicken (Chinese dish)",
    "General ___ chicken",
    "General ___ chicken"
  ],
  "taus": [
    "Greek T's",
    "T-shaped crosses",
    "Some sorority letters",
    "T-shaped crosses",
    "Followers of 1-Acrosses",
    "Torque symbols",
    "Some crosses",
    "Certain crosses",
    "Sorority letters",
    "St. Anthony's crosses",
    "Unstable leptons",
    "Letters from Greece",
    "Greek consonants",
    "Short-lived particles",
    "Particles of the lepton family",
    "Cross shapes",
    "Greek crosses",
    "They're cross-shaped",
    "Laertes' T's",
    "Crosses",
    "Sorority letters",
    "Elementary particles",
    "Greek letters"
  ],
  "alti": [
    "Some choir voices",
    "Harmonizers with soprani and bassi",
    "Height: Prefix",
    "Some madrigal singers",
    "Certain choir singers",
    "Certain operatic voices",
    "Certain singers",
    "Some choir members",
    "Prefix with meter",
    "Some chorus members",
    "Some voices",
    "Some singers",
    "High: Prefix",
    "Some choristers",
    "High: Prefix",
    "Some chorus voices",
    "Some singers",
    "Some choir members",
    "Choir singers",
    "Some choir voices",
    "Height: Prefix",
    "Prefix with plane",
    "Prefix with plano",
    "High: Prefix",
    "High: Prefix",
    "Chorus members",
    "Prefix with meter",
    "Some tabernacle singers",
    "Choir members",
    "Choir members",
    "Prefix with meter",
    "Choral voices"
  ],
  "onat": [
    "Go ___ great length",
    "Go ___ great length",
    "Go ___ great length",
    "Go ___ great length",
    "Go ___ length",
    "Go ___ great length",
    "Go ___ some length"
  ],
  "fink": [
    "Squealer",
    "Squealer",
    "Rat",
    "Informant",
    "Informer",
    "Obnoxious sort",
    "Disloyal sort"
  ],
  "poop": [
    "Inside info",
    "Latest dope",
    "Lowdown",
    "Dope",
    "___ deck",
    "Lowdown, in slang",
    "Inside dope",
    "Inside dope",
    "Lowdown",
    "Tire (out)",
    "Inside info",
    "Inside dope",
    "Info",
    "Lowdown",
    "Tire (out)",
    "Lowdown",
    "Inside info",
    "Lowdown",
    "Lowdown",
    "Gossip",
    "Latest news",
    "One of the decks"
  ],
  "eaux": [
    "Perrier and Evian",
    "Parisian waters",
    "French waters",
    "French waters",
    "French waters",
    "Evian and others",
    "French waters",
    "___ de vie (brandies)",
    "___ minerales",
    "Waters: Fr."
  ],
  "lacy": [
    "Frilly",
    "Having an open, delicate pattern",
    "Like some tablecloths",
    "Like teddies, often",
    "Full of frills",
    "Frilly, as lingerie",
    "Frilly, as lingerie",
    "Like some clouds",
    "Like some petticoats",
    "Full of frills",
    "Like some delicate lingerie",
    "Like cobwebs",
    "Like some petticoats",
    "Like some lingerie",
    "Delicate",
    "Gossamer",
    "Delicate",
    "Filigreed"
  ],
  "alis": [
    "Father-and-daughter boxing champs",
    "Celebrated boxing family",
    "Noted boxing family",
    "Father-and-daughter boxing family",
    "Four of the 12 imams of Shia",
    "Boxers Muhammad and Laila",
    "Boxers Muhammad and Laila",
    "Father/daughter fighters",
    "Noted ring family",
    "Father-and-daughter boxers",
    "Boxers Muhammad and Laila",
    "Noted ring family",
    "Boxer Muhammad and family",
    "First word of Oregon's Latin motto",
    "Father-and-daughter fighters",
    "Two caliphs",
    "Some Muslim men",
    "Father-and-daughter boxers",
    "Noted boxing family",
    "\"___ volat propriis\" (Oregon's motto)",
    "Baba and others",
    "MacGraw's namesakes",
    "Muhammad and others",
    "\"Aladdin\" prince and namesakes",
    "First word of Oregon's motto",
    "Two caliphs",
    "\"Aladdin\" prince and namesakes",
    "Many Muslim men",
    "Baba and others",
    "Some Arab kings",
    "Author Ahmed and others",
    "Actress MacGraw et al.",
    "Men's names meaning \"lion\"",
    "Names in a Saudi phone book"
  ],
  "fisc": [
    "Kind of year: Abbr.",
    "Money-related: Abbr.",
    "Kind of year: Abbr.",
    "Money-related: Abbr.",
    "Kind of year: Abbr.",
    "Money-related: Abbr."
  ],
  "anni": [
    "Years in the Roman Empire",
    "Latin years",
    "Latin years",
    "Years in old Rome",
    "Years at the Colosseum",
    "Years abroad",
    "Years, for Cicero",
    "Years in old Rome",
    "Years at the Vatican",
    "Years, to Tiberius",
    "10 in un decennio",
    "Years, in Rome",
    "Millennio divisions",
    "Years, in Rome",
    "Years in old Rome",
    "Years in old Rome",
    "Years, in Latin",
    "Years in old Rome",
    "MCI and others",
    "Years in old Rome",
    "Years, to Cicero",
    "Decem ___ (Latin decade)",
    "Years abroad",
    "Years in Hadrian's reign",
    "MCMLVII and others",
    "Years of Caesar's reign",
    "Years, to Caesar",
    "Years of Nero's reign",
    "Years, in old Rome",
    "MCI and CCC",
    "Years, to Tiberius"
  ],
  "vacs": [
    "Cleaners, for short",
    "Dust busters, for short",
    "Dust busters, for short"
  ],
  "kaos": [
    "Enemy agency in \"Get Smart\"",
    "Enemy of CONTROL on \"Get Smart\"",
    "Villainous \"Get Smart\" group",
    "Evil \"Get Smart\" group",
    "\"Get Smart\" adversary",
    "Evil \"Get Smart\" organization",
    "\"Get Smart\" enemy agency",
    "Villainous group in \"Get Smart\"",
    "Evil organization in \"Get Smart\"",
    "\"Get Smart\" org.",
    "\"Get Smart\" group",
    "C.O.N.T.R.O.L. enemy"
  ],
  "mike": [
    "Emcee's need",
    "Lapel attachment",
    "Karaoke need",
    "Karaoke bar sight",
    "Love from the Beach Boys?",
    "Boxer Tyson",
    "It may be open at a comedy club",
    "Bit of D.J. equipment",
    "What a D.J. speaks into",
    "One of a candy box duo",
    "Love of the Beach Boys",
    "Karaoke need",
    "Karaoke need",
    "Emcee's need",
    "Eavesdropping aid",
    "Item at center stage",
    "Emcee's need",
    "Stand-up's prop",
    "Amp attachment",
    "Podium feature",
    "Phone bug, e.g.",
    "Spy's equipment",
    "Studio need",
    "Lapel item, sometimes",
    "Part of a sound stage"
  ],
  "mkts": [
    "N.Y.S.E. and Nasdaq: Abbr.",
    "Stock exchanges: Abbr.",
    "Trading places: Abbr.",
    "N.Y.S.E. and Nasdaq, e.g."
  ],
  "omoo": [
    "Melville work following \"Typee\"",
    "1847 novel partly set on a whaler",
    "1847 novel of the sea",
    "1847 Melville novel",
    "Novel narrated by a soon-to-be mutineer",
    "Melville's second novel",
    "Melville's second novel",
    "Melville adventure",
    "Classic novel whose title means \"wanderer\"",
    "Melville's sequel to \"Typee\"",
    "Polynesian term for an island hopper",
    "Work written between \"Typee\" and \"Mardi\"",
    "Novel subtitled \"A Narrative of Adventures in the South Seas\"",
    "Melville novel",
    "\"Typee\" sequel",
    "Melville opus",
    "Book with the chapter \"How They Dress in Tahiti\"",
    "Sequel to \"Typee\"",
    "It was published four years before \"Moby-Dick\"",
    "\"Typee\" sequel",
    "Romance of 1847",
    "Novel subtitled \"A Narrative of Adventures in the South Seas\"",
    "Novel with the chapter \"Farming in Polynesia\"",
    "Melville work",
    "\"Typee\" sequel",
    "Melville novel",
    "Melville work set in Tahiti",
    "Melville work set in Tahiti",
    "Sequel to \"Typee\"",
    "1847 Melville work",
    "Classic novel whose title means \"rover\"",
    "1847 novel involving a mutiny",
    "Novel about its author's experiences on Tahiti",
    "Tale of the South Seas",
    "Autobiographical novel of 1847",
    "Melville title",
    "Sequel to Melville's \"Typee\"",
    "1847 tale of the South Seas",
    "1847 novel subtitled \"A Narrative of Adventures in the South Seas\"",
    "Melville novel",
    "\"Typee\" sequel",
    "Novel that ends \"By noon, the island had gone down in the horizon; and all before us was the wide Pacific\"",
    "Novel of the South Seas",
    "\"Typee\" sequel",
    "Melville novel",
    "Melville novel",
    "Classic novel set in Tahiti",
    "\"Typee\" sequel",
    "1847 Melville work",
    "Melville work",
    "Melville novel",
    "1847 novel set on Tahiti",
    "Sequel novel to \"Typee\"",
    "Melville romance",
    "Melville novel",
    "1847 Melville novel",
    "1847 novel set in part aboard a whaler",
    "Melville tale",
    "Melville tale",
    "Adventure tale of 1847",
    "1847 novel about a mutiny",
    "Sequel to Melville's \"Typee\"",
    "Literary sequel of 1847",
    "\"Typee\" sequel",
    "1847 tale of the South Seas",
    "Novel of the South Seas",
    "Melville tale",
    "Melville novel",
    "Melville novel",
    "Melville tale",
    "Melville work",
    "Melville novel",
    "1847 South Seas adventure",
    "Sequel to \"Typee\"",
    "South Seas adventure story",
    "\"Typee\" sequel",
    "Melville novel",
    "Melville novel",
    "Melville novel",
    "Melville novel",
    "1847 sea adventure",
    "Melville classic",
    "Melville work",
    "Novel set in Tahiti",
    "\"Typee\" sequel",
    "Novel of the South Seas",
    "Melville romance",
    "Novel featuring Doctor Long Ghost",
    "Novel that begins with a mutiny",
    "Novel set on Tahiti",
    "\"Typee\" sequel",
    "Melville book",
    "Novel set on Tahiti",
    "Melville novel",
    "Melville novel"
  ],
  "limn": [
    "Rung #2 of the ladder",
    "Draw, as a scene",
    "Portray",
    "Illustrate",
    "Depict",
    "Portray",
    "Describe",
    "Represent by drawing",
    "Paint",
    "Draw",
    "Sketch",
    "Depict",
    "Describe",
    "Describe",
    "Draw",
    "Portray",
    "Paint",
    "Paint a word-picture",
    "Put into words",
    "Illustrate",
    "Portray",
    "Describe"
  ],
  "xiii": [
    "Lowest number not found on a grandfather clock",
    "Number of one of the Olympics canceled due to W.W. II",
    "Unlucky number for Caesar?",
    "Chapter 13?",
    "Number not on a grandfather clock",
    "Hour not found on a grandfather clock",
    "Constitutional Amendment that abolished slavery",
    "Number not on a clock",
    "Missing floor in Caesar's Palace?"
  ],
  "blew": [
    "Gusted",
    "Huffed and puffed",
    "Played, as a trumpet",
    "Ruined, in a way",
    "Extinguished, as birthday candles, with \"out\"",
    "Huffed and puffed",
    "With 66-Across, left speechless",
    "Went off",
    "Howled, as the wind",
    "Erupted",
    "Ruined, in a way",
    "Flubbed",
    "Messed up",
    "Squandered, as a lead",
    "Muffed",
    "Totally bungled",
    "Missed, as a chance",
    "Gusted",
    "Huffed and puffed",
    "Howled, as the wind",
    "Did horribly on, as a test",
    "Went off",
    "Mishandled",
    "Spent unwisely",
    "Squandered",
    "Blasted",
    "Made a bubble, in a way",
    "Exploded",
    "Huffed and puffed",
    "Squandered",
    "Muffed"
  ],
  "prob": [
    "Small complication?",
    "\"No ___!\"",
    "\"No ___!\"",
    "\"No ___!\" (\"Sure thing!\")",
    "\"No ___\" (\"Sure thing\")",
    "\"No ___\" (reassuring words)",
    "\"No ___!\" (\"Easy!\")",
    "\"No ___!\" (\"Easy!\")",
    "\"No ___!\"",
    "\"No ___!\"",
    "\"No ___!\"",
    "\"No ___!\" (slangy reply)",
    "\"No ___!\"",
    "\"No ___\" (\"Piece of cake\")",
    "\"No ___!\" (slangy O.K.)"
  ],
  "hiho": [
    "Cheerful greeting",
    "Cheery greeting",
    "Cheery cry",
    "Onetime Ritz rival",
    "Chipper greeting",
    "Syllables in a children's refrain",
    "Jaunty greeting",
    "Bygone cracker brand",
    "Cry in \"The Farmer in the Dell\"",
    "Chirpy greeting",
    "Old Ritz rival",
    "Sunshine cracker",
    "Song syllables before \"It's off to work we go\"",
    "Longtime Ritz competitor",
    "Ritz look-alike of old",
    "Brand discontinued by Keebler",
    "Bygone cracker brand",
    "Old cracker brand",
    "Onetime Ritz rival",
    "Exclamation in \"The Farmer in the Dell\"",
    "Old cracker brand",
    "Old cracker brand",
    "Happy cry in song",
    "Bygone cracker brand",
    "\"The Farmer in the Dell\" syllables",
    "Ritz shelfmate",
    "Jaunty greeting",
    "Ritz rival",
    "\"Farmer in the Dell\" syllables",
    "Joyous cry",
    "Sunshine Biscuits brand",
    "Ritz rival",
    "Popular snack cracker",
    "\"Farmer in the Dell\" syllables"
  ],
  "unas": [
    "Some, in Sevilla",
    "Some, in Seville",
    "Some, in Spain",
    "Some, to Spaniards",
    "Senora's \"some\"",
    "Some, in Sevilla",
    "Some: Sp.",
    "Final pharaoh of the Fifth Dynasty, whose pyramid is near Cairo",
    "Some, in Seville",
    "Last pharaoh of Egypt's Fifth Dynasty",
    "Alicante article",
    "Some in Spain",
    "Amalfi articles",
    "Actress O'Connor and others",
    "Italian articles",
    "Actress Merkel and others",
    "Actress Merkel and others",
    "Merkel and O'Connor"
  ],
  "liii": [
    "Super Bowl ___ (game played February 3, 2019)",
    "53, in old Rome",
    "53, in old Rome",
    "Year Trajan was born",
    "53, in old Rome",
    "Chapter 53",
    "Year Trajan was born",
    "CVI halved",
    "Year Trajan was born",
    "Next-to-last year in Claudius's reign",
    "Year B.C. in which Crassus died"
  ],
  "gibb": [
    "Barry, Robin or Maurice of the Bee Gees",
    "Last name of 60-Across",
    "Bandmate Barry, Maurice or Robin",
    "Pop's Brothers ___",
    "Any of the Bee Gees brothers",
    "Andy with the #1 hit \"Shadow Dancing\"",
    "1970s-'80s singer Andy",
    "Bee Gees' surname",
    "Bee Gees brother",
    "Any of the Bee Gees",
    "Singing brother's surname",
    "Any of the Bee Gees",
    "Andy who sang \"Shadow Dancing\"",
    "Singing brother of 60's-90's pop",
    "The \"Gee\" in Bee Gees"
  ],
  "joss": [
    "Kind of stick for incense",
    "Whedon who directed 2012's \"The Avengers\"",
    "Kind of stick",
    "___ stick (incense)",
    "Kind of stick",
    "Chinese image in a shrine",
    "Kind of stick",
    "___ stick (incense)",
    "Chinese idol",
    "Kind of stick"
  ],
  "yers": [
    "Gen ___ (millennials)",
    "Gen ___ (millennials)",
    "Backwoods possessive",
    "Informal possessive"
  ],
  "geos": [
    "Bygone compacts",
    "Storms of the 1990s",
    "Bygone compacts",
    "1990s compacts",
    "Storms, e.g.",
    "Storms and others",
    "Prizm and Spectrum, once",
    "Storms on the road",
    "Trackers and Prizms, once",
    "Bygone G.M. cars",
    "1980's-90's cars",
    "Some G.M. cars",
    "Trackers, e.g.",
    "Metro cars",
    "Autos since 1989"
  ],
  "curb": [
    "Park place?",
    "Keep in check",
    "Sidewalk's edge",
    "Walk on the edge?",
    "Street's edge",
    "\"___ Your Enthusiasm\"",
    "Cut down",
    "Parking spot",
    "Place to park a car",
    "Restraint",
    "Parking spot",
    "Rein in",
    "Street's edge",
    "Street/sidewalk separator",
    "Rain drain locale",
    "Park place?",
    "Rein in",
    "Taxi's drop-off point",
    "Check",
    "Bridle"
  ],
  "kcar": [
    "Classic Chrysler product",
    "Old Chrysler",
    "1980s Chrysler offering",
    "Dodge Aries, e.g.",
    "1980s Chrysler offering",
    "1980s Chrysler offering",
    "'80s Chrysler offering",
    "1981 Chrysler debut",
    "1980s Chrysler debut",
    "Plymouth Reliant, for one",
    "1980's Chrysler product",
    "Plymouth Reliant, for one",
    "1980 Chrysler debut",
    "Former Chrysler offering"
  ],
  "tavi": [
    "Kipling's \"Rikki-Tikki-___\"",
    "Kipling's \"Rikki-Tikki-___\"",
    "Rikki-tikki-___ (Kipling mongoose)",
    "Rikki-tikki-___",
    "\"Rikki-Tikki-___\"",
    "Rikki-tikki-___",
    "Rikki-tikki-___",
    "Rikki-Tikki-___",
    "Rikki-tikki-___",
    "Rikki-Tikki-___",
    "Rikki-Tikki-___",
    "Rikki-Tikki-___",
    "Rikki-tikki-___",
    "Rikki-Tikki-___",
    "Rikki-tikki-___ (Kipling mongoose)",
    "Kipling's Rikki-Tikki-___",
    "Rikki-tikki-___"
  ],
  "beck": [
    "Partner of call",
    "Radio personality Glenn",
    "One-named rock star of the 1990s-2000s",
    "Fox News star",
    "Call's partner",
    "Call's companion",
    "Call's companion",
    "Call's partner",
    "Call's mate",
    "Chip of the P.G.A."
  ],
  "dusk": [
    "Twilight time",
    "Closing time for many city parks",
    "You might call it an early night",
    "Period just before dark",
    "When car headlights get turned on",
    "Dawn's opposite",
    "*End",
    "Nightfall",
    "The edge of night",
    "Gloaming",
    "Evening"
  ],
  "gaol": [
    "Lockup, to Sherlock",
    "What the Tower of London was for over 850 years",
    "English lockup",
    "Wilde wrote \"De Profundis\" in one",
    "British lockup",
    "Dickens's pen?",
    "Canterbury can",
    "The Tower of London was one",
    "Cooler overseas",
    "Reading pen",
    "Kind of house, in England",
    "Canterbury can",
    "British can",
    "Famous Dartmoor facility",
    "Big house in London",
    "Can in Canterbury",
    "Plymouth poky",
    "Wilde's \"The Ballad of Reading ___\"",
    "Bath cooler",
    "Reading, for a famous example",
    "Reading, e.g.",
    "\"The Ballad of Reading ___\"",
    "Hold overseas"
  ],
  "fifa": ["Governing org. of soccer"],
  "lilt": [
    "Feature of a Welsh accent",
    "Breezy air",
    "Carousel music",
    "Cheerful tune",
    "Gentle tune",
    "Cheery tune",
    "Easy-breezy tune",
    "Gentle rising and falling, of a sort",
    "Music box music",
    "Pleasant inflection",
    "Pleasant inflection",
    "Swinging rhythm",
    "Cheery air",
    "Gentle rise and fall of the voice",
    "\"Rock-a-bye Baby,\" e.g.",
    "Pleasant cadence",
    "Light air",
    "Ice cream truck music, e.g.",
    "Pleasant accent",
    "You might hear a children's song in one",
    "Singsongy cadence",
    "Light, rhythmic cadence",
    "Cheerful tune",
    "Music on a carnival ride",
    "Melodic speech",
    "Joyful tune",
    "Gentle rise and fall of the voice",
    "Light tune",
    "Talk with an Irish brogue, e.g.",
    "Merry-go-round music",
    "Light air",
    "Cheery song",
    "Bounce",
    "Cheery tune",
    "Light air",
    "Bouncy step",
    "Airy melody",
    "Cheerful tune",
    "Light, happy tune",
    "Music box music",
    "Merry-go-round music",
    "Airy tune",
    "Merry-go-round music, e.g.",
    "Pleasant tune",
    "Cheery tune",
    "Carnival tune",
    "Light, happy tune",
    "Ice-cream truck song",
    "Cheerful tune",
    "Sing with grace",
    "Rhythmic swing",
    "Pleasant tune"
  ],
  "tias": [
    "Some Spanish babysitters",
    "Madres' sisters",
    "Madres' kin",
    "Aunts, in Andalusia",
    "Familia members",
    "Members of la familia",
    "Familia members",
    "Colombian kinfolk",
    "Familia members",
    "Mexican kin",
    "Andalusian aunts",
    "Some familia members",
    "Havana aunts",
    "Spanish aunts",
    "Padre's hermanas",
    "Andalusian aunts",
    "Familia members",
    "Familia members",
    "Relatives of Rafael",
    "Spanish aunts",
    "Madres' kin"
  ],
  "burl": [
    "Bump on a log",
    "Knotty tree growth",
    "Lump on a trunk",
    "Knotty growth",
    "Small knot",
    "Small knot",
    "Knot",
    "Lump in cloth",
    "Singer Ives",
    "Singer Ives",
    "Small knot",
    "Singer Ives",
    "Knot in wood"
  ],
  "howe": [
    "Julia Ward ___, writer of \"The Battle Hymn of the Republic\"",
    "Victor at Brandywine",
    "Gordie ___ a.k.a. Mr. Hockey",
    "William ___, British general in the Revolutionary War",
    "Mr. Hockey's surname",
    "N.H.L. star nicknamed \"Mr. Hockey\"",
    "\"The Battle Hymn of the Republic\" lyricist",
    "Hockey great Gordie",
    "Hockey legend Gordie",
    "Inventor Elias",
    "British general in the American Revolution",
    "British general in the American Revolution",
    "\"The Battle Hymn of the Republic\" writer",
    "Hockey legend Gordie",
    "Julia ___, first woman elected to the American Academy of Arts and Letters",
    "Bunker Hill general",
    "Player second only to Gretzky in most N.H.L. goals",
    "British vice admiral in the American Revolution",
    "Inventor Elias",
    "British commander who captured New York City, 1776",
    "British commander at Bunker Hill",
    "Victor at Brandywine",
    "26-season N.H.L. player",
    "Sewing machine inventor",
    "Inventor Elias",
    "Gordie of the N.H.L.",
    "Inventor Elias",
    "Legendary Detroit Red Wing",
    "Hockey great Gordie",
    "Julia Ward ___",
    "Hockey's Gordie",
    "Six-time N.H.L. M.V.P.",
    "N.H.L legend Gordie"
  ],
  "inds": [
    "Unaffiliated voters: Abbr.",
    "Unaffiliated voters: Abbr.",
    "Hill minority: Abbr.",
    "Neither Dems. nor Reps.",
    "Middle-of-the-roaders: Abbr.",
    "They're unaffiliated: Abbr.",
    "Polit. wild cards",
    "Some candidates: Abbr.",
    "They're often responsible for upsets: Abbr.",
    "Some voters: Abbr."
  ],
  "cols": ["Newspaper units: Abbr.", "Newspaper units: Abbr.", "Mil. officers"],
  "evah": [
    "Cole Porter's \"Well, Did You ___?\"",
    "\"Well, Did You ___?\"",
    "Cole Porter's \"Well, Did You ___?\"",
    "\"Well, Did You ___?\" (Cole Porter tune)",
    "\"Well, Did You ___?\"",
    "Cole Porter's \"Well, Did You ___?\"",
    "Cole Porter's \"Well, Did You ___?\"",
    "Cole Porter's \"Well, Did You ___?\"",
    "\"Well, Did You ___?\" (Cole Porter song)",
    "Cole Porter's \"Well, Did You ___?\"",
    "Cole Porter's \"Well, Did You ___!\"",
    "Cole Porter's \"Well, Did You ___?\"",
    "\"Well, Did You ___?\" (Cole Porter query)"
  ],
  "noll": [
    "Longtime Steelers coach Chuck",
    "Chuck ___, four-time Super Bowl-winning coach",
    "Chuck who won four Super Bowl rings",
    "Four-time Super Bowl-winning coach"
  ],
  "ower": [
    "One with a debt",
    "One in arrears",
    "One in debt",
    "Person in the hole",
    "Short person?",
    "Shy person?",
    "One in debt",
    "One who's in the hole",
    "Deadbeat, e.g.",
    "Chit writer",
    "Collection agent's target",
    "One with creditors",
    "One making markers",
    "One in the red",
    "Debtor",
    "One who charges"
  ],
  "adar": [
    "Jewish month before Nisan",
    "29-day month",
    "Purim's month",
    "Month whose zodiac sign is a fish",
    "Month before Nisan",
    "Purim time",
    "Month before Nisan",
    "Month in which Moses is said to have been born and died",
    "Time of Ta'anit Esther",
    "When the Feast of Lots is observed",
    "Purim's month",
    "It follows Shevat",
    "Month after Shevat",
    "Hebrew month",
    "Month before Nisan",
    "Month after Shevat",
    "Purim month",
    "Sixth Jewish month",
    "Month before Nisan",
    "Jewish month",
    "Jewish month",
    "Month in Israel",
    "Purim time",
    "Shevat follower",
    "Month before Nisan",
    "Shevat follower",
    "Shebat follower",
    "Purim's month",
    "29- or 30-day month",
    "When Taanit Esther is observed",
    "Purim's month",
    "When the Feast of Lots is observed",
    "When Purim is observed",
    "Sixth Jewish month",
    "Month after Shebat",
    "Jewish month",
    "Month after Shevat"
  ],
  "tams": [
    "Scottish caps",
    "Hats in the Highlands",
    "Certain caps",
    "Scottish caps",
    "Headwear worn over dreadlocks",
    "Kilt accompaniers",
    "Floppy headgear",
    "Highland headgear",
    "Lids around lochs",
    "Scots' caps",
    "Scottish caps",
    "Wool caps",
    "Scot's tops",
    "Flat hats",
    "They have tight headbands",
    "Skye caps",
    "Tartan items",
    "Woolen caps",
    "Highland hats",
    "Caps on the Clyde",
    "Caps",
    "Highland toppers",
    "Dundee toppers",
    "Woolen caps",
    "They cover Highland heads",
    "Highlands attire"
  ],
  "rohe": [
    "Architect Mies van der ___",
    "Architect Ludwig Mies van der ___",
    "Mies van der ___",
    "Architect Mies van der ___",
    "Architect Ludwig Mies van der ___",
    "Architect Ludwig Mies van der ___",
    "Mies van der ___",
    "Mies van der ___",
    "End of an architect's name",
    "Ludwig Mies van der ___",
    "Mies van der ___ (German-born architect)",
    "Architect Mies van der ___",
    "Architect Mies van der ___",
    "Architect Mies van der ___",
    "Architect Mies van der ___",
    "Architect Mies van der ___",
    "Architect Mies van der ___",
    "Architect Mies var der ___"
  ],
  "futz": [
    "Mess (with)",
    "*Crafts site",
    "Mess around (with)",
    "Fiddle (around)"
  ],
  "fdny": ["Big Apple's \"bravest,\" briefly"],
  "nite": [
    "Time of day, in ads",
    "Time after dark, in commercials",
    "Evening, in ads",
    "Nick at ___",
    "Late hours, in ads",
    "Nick at ___",
    "Time in ads",
    "The time of Nick?",
    "Kid Cudi's \"Day 'n' ___\"",
    "Evening, in ads",
    "Dark time, in ads",
    "Prime-time time, informally",
    "Brief period of darkness?",
    "Time in TV ads",
    "Evening, in ads",
    "Dark time, in ads",
    "Time for Variety?",
    "Kiss hit \"Rock and Roll All ___\"",
    "The ___ Owl, \"L.A. Confidential\" coffee shop",
    "TV's Nick at ___",
    "Evening, in ads",
    "Commercial time of day",
    "When the stars come out, in ads",
    "Short evening?",
    "Nick at ___",
    "Time on a marquee",
    "Time on a marquee",
    "Nick at ___",
    "\"Saturday ___,\" 1976 Earth, Wind & Fire hit",
    "Nick at ___",
    "Evening, in ads",
    "Ad time",
    "\"In the Still of the ___\" (Boyz II Men hit)",
    "Nick at ___",
    "Late hours, commercially",
    "Nick at ___",
    "Informal evening",
    "Period after dark, in ads",
    "Evening, in ads",
    "Evening, in ads",
    "Marquee time",
    "Nick at ___",
    "Commercial time of day",
    "When the stars come out, in ads",
    "Dark time, informally",
    "Nick at ___",
    "Time for Nick?",
    "Nick at ___",
    "Nick at ___",
    "TV's Nick at ___",
    "Prime-time time, informally",
    "Nick's time",
    "TV's Nick at ___",
    "Evening, in adspeak",
    "Nick at ___",
    "Prime time for Nick",
    "Nick at ___",
    "Evening, informally",
    "Evening, informally",
    "Nick at___",
    "Day's opposite in commercials"
  ],
  "foer": ["Author Jonathan Safran ___"],
  "duad": [
    "Twosome",
    "Pair",
    "Couple",
    "Couple",
    "Group of two",
    "Pair",
    "Doublet",
    "Couple",
    "Couple",
    "Pair",
    "Couple"
  ],
  "osis": [
    "Suffix with psych-",
    "Suffix with narc-",
    "Medical suffix",
    "Medical suffix",
    "Medical suffix",
    "Suffix with psych-",
    "Medical suffix",
    "Suffix with psych-",
    "Medical suffix",
    "Narc tail?",
    "Medical suffix",
    "Medical suffix",
    "Narc linkup?",
    "Medical suffix",
    "Medical suffix",
    "Medical suffix",
    "Medical suffix",
    "Medical suffix"
  ],
  "dqed": [
    "Barred from competition, briefly",
    "Tossed out of the game, informally"
  ],
  "rabe": [
    "Broccoli ___",
    "Broccoli ___ (leafy vegetable)",
    "\"Sticks and Bones\" playwright David",
    "Broccoli ___",
    "Broccoli ___",
    "Broccoli ___",
    "\"Hurlyburly\" writer David",
    "\"Streamers\" playwright",
    "Broccoli ___",
    "Broccoli ___",
    "\"Streamers\" playwright David",
    "Broccoli ___",
    "\"Hurlyburly\" playwright David",
    "Broccoli ___ (leafy vegetable)",
    "\"Streamers\" playwright",
    "Broccoli ___ (vegetable)",
    "\"Hurlyburly\" playwright",
    "Broccoli ___ (leafy vegetable)",
    "\"Streamers\" playwright",
    "\"Streamers\" playwright David",
    "Broccoli ___ (leafy vegetable)",
    "\"Hurlyburly\" playwright",
    "\"Streamers\" playwright David",
    "Obie-winning dramatist David",
    "\"Streamers\" playwright",
    "Playwright David",
    "Playwright David",
    "Contemporary dramatist David"
  ],
  "culp": [
    "Cosby's \"I Spy\" co-star",
    "\"PT 109\" actor Robert",
    "Late actor Robert of \"I Spy\"",
    "\"I Spy\" co-star",
    "Cosby's \"I Spy\" co-star",
    "\"I Spy\" co-star Robert",
    "\"I Spy\" co-star",
    "Cosby's \"I Spy\" co-star",
    "\"PT 109\" actor",
    "\"I Spy\" co-star",
    "\"I Spy\" star"
  ],
  "dayo": [
    "Repeated word in a 1957 Harry Belafonte hit",
    "Refrain word in the song with the lyric \"Come, Mister Tally Man, tally me banana\"",
    "Repeated word in \"The Banana Boat Song\"",
    "Belafonte hit",
    "\"Me say\" this word in a 1957 hit",
    "Harry Belafonte catchword",
    "Start of a 1957 hit song",
    "Harry Belafonte catchword",
    "\"The Banana Boat Song\" word",
    "Belafonte song opener",
    "Belafonte catchword",
    "Harry Belafonte cry",
    "Belafonte catchword",
    "Belafonte song opener",
    "Belafonte song catchword",
    "Belafonte catchword",
    "Harry Belafonte catchword",
    "Belafonte standard",
    "1957 Harry Belafonte hit",
    "Belafonte song opener",
    "Harry Belafonte song phrase",
    "Calypso standard"
  ],
  "sita": [
    "___ spell",
    "Rama's wife, in Hinduism",
    "___ spell",
    "___ spell",
    "___ spell",
    "___spell (relax)"
  ],
  "naha": [
    "Okinawa port",
    "Capital of Okinawa",
    "Okinawa port",
    "Okinawa port",
    "Okinawan port",
    "Okinawa's capital",
    "Okinawa's capital",
    "Capital of Okinawa",
    "Okinawan seaport",
    "Okinawa port"
  ],
  "igot": [
    "\"___ Rhythm\"",
    "\"___ Plenty o' Nuttin'\" (\"Porgy and Bess\" song)",
    "\"What ___\" (1996 Sublime hit)",
    "\"___ You Babe\" (Sonny & Cher hit)",
    "\"___ you something\"",
    "\"___ Rhythm\"",
    "\"What ___\" (1996 Sublime hit)",
    "Croce's \"___ a Name\"",
    "Gershwin's \"___ Plenty o' Nuttin'\"",
    "\"___ Rhythm\"",
    "Gershwin's \"___ Rhythm\"",
    "Jerome Kern's \"___ Love\"",
    "\"___ Shoes\" (old spiritual)",
    "Duke Ellington's \"___ It Bad and That Ain't Good\"",
    "James Brown's \"___ the Feelin'\"",
    "Gershwin's \"___ Plenty o' Nuttin'\"",
    "\"___ Plenty o' Nuttin'\"",
    "Sonny and Cher's \"___ You Babe\"",
    "Berlin's \"___ Lost in His Arms\"",
    "\"___ a Name\" (Jim Croce hit)",
    "\"___ Plenty O' Nuttin'\"",
    "\"___Shoes\" (American spiritual)",
    "Jim Croce's \"___a Name\"",
    "\"___ Plenty o' Nuttin\"",
    "\"___ You Babe\" (1965 song)",
    "\"___ Plenty o' Nuttin'!\"",
    "Jim Croce's \"___ a Name\"",
    "\"___ Rhythm\"",
    "?ô___ You Babe?ö",
    "\"___ Rhythm\"",
    "\"___ You Babe\""
  ],
  "doms": [
    "Whip wielders, for short",
    "DiMaggio and others",
    "Titles for some monks"
  ],
  "beit": [
    "\"So ___\"",
    "\"So ___\"",
    "\"___ ever so humble ...\"",
    "\"___ ever so humble ...\"",
    "\"So ___\"",
    "\"So ___\"",
    "\"___ ever so humble...\""
  ],
  "ryne": [
    "Sandberg at second base",
    "Baseball Hall-of-Famer Sandberg",
    "Hall-of-Fame Cub Sandberg",
    "Slugger Sandberg",
    "Hall-of-Famer Sandberg",
    "Hall-of-Famer Sandberg",
    "Former Cub ___ Sandberg",
    "Sandberg of Cooperstown",
    "Sandberg of baseball",
    "Baseballer Sandberg",
    "Second baseman Sandberg",
    "Baseball's Sandberg",
    "Baseball's Sandberg",
    "Baseball's Sandberg",
    "Baseball's Sandberg",
    "Sandberg of baseball",
    "Sandberg of the Cubs",
    "Sandberg of baseball"
  ],
  "mels": [
    "Where TV's Flo waitressed",
    "Classic TV diner",
    "Diner on TV's \"Alice\"",
    "1970s-'80s sitcom diner",
    "1970s-'80s sitcom setting",
    "Sitcom diner",
    "Diner of 1970s-'80s TV",
    "Diner on \"Alice\"",
    "1970s-'80s sitcom setting",
    "Diner on \"Alice\"",
    "Allen and Ott",
    "Diner on \"Alice\"",
    "Blanc and Brooks",
    "Diner in \"Alice\"",
    "Where TV's Flo waitressed",
    "Sitcom diner",
    "Gibson and Brooks",
    "___ Diner",
    "Alice's restaurant",
    "Sitcom eatery",
    "Sitcom diner",
    "___ Diner, on \"Alice\"",
    "Sitcom diner",
    "Where Alice worked",
    "Alice doesn't work here anymore",
    "\"Alice\" diner",
    "\"Alice\" diner",
    "Sitcom diner",
    "Alice's restaurant",
    "\"Alice\" diner",
    "Torme and Gibson"
  ],
  "mays": [
    "#24 in 24 All-Star Games",
    "Billy of infomercial fame",
    "Willie who made \"The Catch\" in the 1954 World Series",
    "Parts of springs",
    "Willie who's #5 in career home runs",
    "Giant among Giants",
    "Baseball great Willie",
    "Baseball Hall-of-Famer who played for the Giants",
    "Billy famous for infomercials",
    "Giant who made \"The Catch,\" 1954",
    "Baseball All-Star, 1954-73",
    "Baseball great who's Bonds's godfather",
    "Willie of the 1950s-'60s Giants",
    "#24 who played in 24 All-Star Games",
    "Giant with the retired jersey #24",
    "Slugger Willie",
    "Giants great Willie",
    "They follow Aprils",
    "The Say Hey Kid",
    "Baseball's Say Hey Kid",
    "Spark for the Giants' 1951 pennant win",
    "Giant hero",
    "He homered 660 times",
    "OUTFIELDER",
    "Giants' retired number 24",
    "1951 N.L. Rookie of the Year",
    "1951 N.L. Rookie of the Year",
    "Hitter of 660 career home runs",
    "Spring times",
    "Giant great",
    "Gold Glove winner, 1957-68",
    "Giant great",
    "Baseball's \"Say Hey Kid\"",
    "Kentucky Derby times",
    "Diamond star Willie",
    "Third all-time record home-run hitter",
    "Baseball's Say Hey Kid",
    "Giant Giant",
    "N.L. M.V.P., 1954. and 1965",
    "Baseball's \"Say Hey\""
  ],
  "nyah": [
    "When doubled, a taunt",
    "When repeated, a taunt",
    "When doubled, a taunt",
    "When repeated, a child's taunt",
    "When doubled, a taunt",
    "When repeated, a child's taunt"
  ],
  "idie": [
    "\"Thus with a kiss ___\" (Romeo's last words)",
    "\"And When ___,\" 1969 Blood, Sweat & Tears hit",
    "Romeo's last words",
    "Romeo's last words",
    "Romeo's last words",
    "\"And if ___ before ...\"",
    "Romeo's last words"
  ],
  "dale": [
    "Chip's cartoon partner",
    "Broad valley",
    "Hollow",
    "Natural depression",
    "Counterpart of a rise",
    "Over hill and ___",
    "Hill's partner",
    "Bumpers of Arkansas",
    "Place for a rivulet",
    "Hollow",
    "Hill's opposite",
    "Racer Earnhardt",
    "Lowland",
    "Land in a depression",
    "Norman ___, coach in \"Hoosiers\"",
    "Cowgirl Evans",
    "Peak's counterpart",
    "Cartoon chipmunk",
    "Place for a rivulet",
    "Area between hills",
    "Valley",
    "Broad valley",
    "With 8-Down, \"Happy Trails\" songwriter",
    "Place for a stream",
    "Rustic locale",
    "Chip's cartoon chum",
    "Valley",
    "Area between hills",
    "Over hill and ___",
    "View from a hill",
    "Valley",
    "Cowgirl Evans",
    "Depression",
    "Hill's partner",
    "Over hill and ___",
    "Chip's cartoon chum",
    "Broad valley",
    "Hill's opposite",
    "Hill's opposite",
    "Lowland",
    "Broad valley",
    "Broad valley",
    "Lowland",
    "Broad valley",
    "Depression",
    "Cowboy Roy's better half",
    "Broad valley",
    "Hill's partner",
    "___ Arden, Flash Gordon companion",
    "Valley",
    "Cowgirl Evans",
    "Common town name ending",
    "Chip's chipmunk chum",
    "Valley",
    "Broad valley",
    "Cowgirl Evans",
    "Buttermilk's rider",
    "Hill's opposite",
    "Sheltered spot",
    "Chip's partner",
    "Mr. Carnegie"
  ],
  "dura": [
    "___ mater (brain membrane)",
    "Long-lasting, in commercial names",
    "Long-lasting, in commercial names",
    "___ mater (brain membrane)",
    "___ mater",
    "___ mater (brain cover)",
    "Brain membrane",
    "___ mater"
  ],
  "leed": [
    "Green building certification, for short",
    "Eco-friendly building certification, for short",
    "Certification for eco-friendly buildings, for short",
    "Certification for eco-friendly buildings, for short"
  ],
  "inka": [
    "\"___ Dinka Doo\" (theme song of Jimmy Durante)",
    "\"___ Dinka Doo\" (theme song of Jimmy Durante)",
    "\"___ Dinka Doo\" (Durante tune)",
    "\"___ Dinka Doo\"",
    "1934 hit \"___ Dinka Doo\"",
    "Start of a Jimmy Durante song",
    "Durante's \"___ Dinka Doo\"",
    "Durante's \"___ Dinka Doo\"",
    "Start of a Durante title",
    "\"___ Dinka Doo\""
  ],
  "syns": [
    "Destiny and fate, e.g.: Abbr.",
    "Warm and toasty, e.g.: Abbr.",
    "Thesaurus entries: Abbr.",
    "Roget's listings: Abbr.",
    "Aid and abet: Abbr.",
    "Thesaurus offerings: Abbr.",
    "Bits and pieces, e.g.: Abbr.",
    "Roget offerings (abbr.) ... or, loosely, the first and last words of 20-, 28-, 48- and 56-Across",
    "\"Cease\" and \"desist,\" e.g.: Abbr.",
    "Thesaurus contents: Abbr.",
    "Dict. offerings",
    "Roget offerings: Abbr.",
    "Roget offerings: Abbr.",
    "Dict. listings",
    "Dict. offerings",
    "Aid and abet: Abbr.",
    "Assn. and org.",
    "Prim and proper, e.g.: Abbr.",
    "Dict. listings",
    "Wds. of similar meaning",
    "Words often listed together: Abbr.",
    "Thesaurus wds.",
    "O.E.D. listings",
    "Assn. and org., e.g.",
    "Dict. offerings",
    "They say the same things: Abbr."
  ],
  "tacs": ["Tic ___ (mints)", "Tic ___ (mints)", "Tic-___ (candies)"],
  "cops": [
    "Blue group",
    "Chasers in many a chase scene",
    "Beat people?",
    "Admits (to)",
    "Pinches",
    "Lifts",
    "Police",
    "Takes the wrong way?",
    "Swipes",
    "Robbers' partner",
    "Perp pursuers",
    "Sting participants",
    "The heat",
    "Forceful group?",
    "The finest",
    "Police officers",
    "The good guys, in a chase film",
    "True-crime TV series",
    "Long-running Fox show",
    "___ a plea (works a deal with the D.A.)",
    "Flatfoots"
  ],
  "duke": [
    "College hoops powerhouse",
    "Nickname for John Wayne, with \"the\"",
    "School with five March Madness titles",
    "2015 N.C.A.A. basketball champs",
    "Where Nixon went to law school",
    "\"Doonesbury\" character based on Hunter S. Thompson",
    "Home of the Blue Devils",
    "Nixon's law alma mater",
    "John Wayne nickname",
    "Single fist?",
    "___ it out (fight)",
    "North Carolina school",
    "52-Down rival",
    "The Blue Devils"
  ],
  "wait": [
    "\"Hang on!\"",
    "\"Hold on a sec!\"",
    "\"Hold it!\"",
    "Take orders, say",
    "\"Just a moment!\"",
    "See 68-Across",
    "Checkout line complaint",
    "Hang around (for)",
    "\"Hold your horses!\"",
    "Emergency room concern",
    "Word repeated before \"Don't Tell Me!\"",
    "Not yet 58-Down",
    "\"Hold your horses!\"",
    "\"Hold it!\"",
    "\"Hold it!\"",
    "Word said twice before \"Don't tell me!\"",
    "\"You're going too fast for me!\"",
    "\"Hold on!\"",
    "Hang around",
    "See 33-Down",
    "Queue annoyance",
    "\"Don't go yet!\"",
    "Word repeated before \"Don't tell me!\"",
    "\"Hold on!\"",
    "What an outturned palm may mean",
    "Links annoyance",
    "\"Hold on!\"",
    "\"Hurry up and ___\"",
    "Restaurant annoyance",
    "Stand in line",
    "Don't go",
    "Hold on",
    "Take orders, in a way",
    "Web user's woe",
    "Linger",
    "\"But___!\"",
    "Hold on"
  ],
  "atoy": [
    "\"This is not ___\" (warning to kids)",
    "\"Love is not ___\" (\"Tears on My Pillow\" lyric)",
    "\"This bag is not ___\"",
    "\"This is not ___\" (warning label)",
    "\"This is not ___\" (warning label)",
    "\"That's not ___!\" (parent's admonishment)",
    "Like ___ on a string"
  ],
  "rahs": [
    "Sounds of support",
    "Stadium sounds",
    "Cries from bleachers",
    "Pompom wielder's cries",
    "Pep rally cries",
    "Stadium cheers",
    "Stadium cheers",
    "Fan sounds",
    "Words of support",
    "Sounds from a 50-Down",
    "Shouts while shaking pompoms",
    "Sounds heard in a bowl",
    "Motivational cries",
    "Sis-boom-bahs",
    "Encouraging sounds",
    "Athletic support",
    "Cheers",
    "Fan sounds",
    "Stadium sounds",
    "Cheers",
    "Cheers",
    "Root words",
    "Supporting chorus",
    "Chorus from the sidelines",
    "Stands roars",
    "Team encouragement",
    "Game cries",
    "Encouraging sounds",
    "Fan sounds",
    "Cheerleaders' cheers",
    "Stadium sounds",
    "Stadium sounds",
    "Sounds from the stands",
    "Stadium cheers",
    "Stadium cheers",
    "Crowd sounds",
    "Hoorays"
  ],
  "upin": [
    "Words before \"smoke\" or \"the air\"",
    "With 47-Down, angry",
    "___ arms",
    "\"___ the Air\" (2009 Clooney movie)",
    "Go ___ smoke",
    "___ arms (indignant)",
    "Get ___ arms",
    "___ arms",
    "___ arms",
    "Words before \"arms\" or \"the air\"",
    "Went ___ smoke",
    "___ smoke",
    "Go ___ flames",
    "\"___ Central Park\" (1945 Broadway hit)",
    "___ arms (angry)"
  ],
  "heys": [
    "Casual greetings",
    "Informal greetings",
    "Startled cries",
    "Attention getters",
    "Attention getters",
    "Slangy greetings",
    "Attention-getters",
    "Cries of surprise",
    "Attention-getters"
  ],
  "neth": [
    "Home of The Hague: Abbr.",
    "Where to find Edam and Gouda: Abbr.",
    "Belg. neighbor",
    "One of the Low Countries: Abbr.",
    "Neighbor of Ger.",
    "Rembrandt's land: Abbr.",
    "Neighbor of Belg.",
    "Willem I's land: Abbr."
  ],
  "orto": [
    "\"___ put it another way ...\"",
    "\"___ take arms against a sea of troubles\": Hamlet",
    "\"___ put it another way ...\"",
    "\"___ put it another way ...\"",
    "\"___ put it another way ...\"",
    "\"For here ___ go?\"",
    "\"___ take arms against ...\"",
    "\"For here ___ go?\"",
    "\"... ___ go?\"",
    "\"___ put it another way ...\"",
    "\"___ say, Rise up and walk?\": Luke 5:23",
    "\"... outrageous fortune, ___ ...\": Shak.",
    "\"___ dispraise my lord ...\": Juliet",
    "\"___ take arms ...\"",
    "\"___ put it another way ?à\"",
    "\"___ put it another way ...\"",
    "\"___ take arms against a sea of troubles\": \"Hamlet\"",
    "\"___ take arms against a sea of troubles\": Hamlet",
    "\"___ take arms against a sea of troubles\": Hamlet",
    "\"___ take arms against a sea of troubles\": Shak."
  ],
  "vila": [
    "Bob of \"Home Again\"",
    "Bob of TV's \"This Old House\"",
    "TV handyman",
    "TV handyman Bob",
    "Home improvement pro Bob",
    "Handyman Bob",
    "Handyman Bob",
    "TV handyman Bob",
    "Bob of TV's \"Home Again\"",
    "Handyman Bob"
  ],
  "swum": [
    "Like some Olympic races",
    "Taken a dip",
    "Done the backstroke, e.g.",
    "Done some strokes",
    "Like some Olympic races",
    "Like the English Channel for the first time in 1875",
    "Like one leg of a triathlon",
    "Like the Florida Straits, rarely",
    "Used strokes",
    "Like laps in a pool",
    "Gone across a pool",
    "Done laps",
    "Having dogpaddled"
  ],
  "pout": [
    "Something you shouldn't do around Christmas",
    "Petulant expression",
    "Moue",
    "Mope",
    "Sullen look",
    "Wear a puss",
    "Sulk",
    "Look down",
    "Look sullen",
    "Look down",
    "Sulk",
    "Look down",
    "Wear a puss",
    "Wear a long face",
    "Show pique",
    "Unhappy expression",
    "Displease Santa, in a way",
    "Show petulance",
    "Fit of pique",
    "Be sullen",
    "Show petulance"
  ],
  "wary": [
    "Gun-shy",
    "Circumspect",
    "Guarded",
    "Not easily taken",
    "Untrusting",
    "Suspicious",
    "Cautious",
    "Gun-shy",
    "Keeping the eyes and ears open",
    "On guard",
    "On one's guard",
    "On one's guard",
    "On guard",
    "On guard",
    "Cautious",
    "Cautious",
    "Guarded",
    "Cautious",
    "Cautious",
    "On one's guard",
    "Guarded",
    "With eyes and ears open"
  ],
  "blin": [
    "Russian pancake",
    "Thin pancake",
    "Thin Russian pancake",
    "Buckwheat pancake",
    "Russian pancake"
  ],
  "prii": ["Toyota hybrids, jocularly"],
  "waft": [
    "Drift, as an aroma",
    "Float in the air",
    "Float, as an aroma",
    "Drift",
    "Go with the flow?",
    "Ride the breeze",
    "Blow gently",
    "Drift",
    "Float gracefully",
    "Go with the wind",
    "Blow gently",
    "Float, as a scent",
    "Float",
    "Float"
  ],
  "simi": [
    "___ Valley",
    "California's ___ Valley",
    "___ Valley",
    "___ Valley, Calif.",
    "___ Valley (Reagan Library locale)",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "___ Valley",
    "___ Valley, Calif.",
    "Sonoma County winery",
    "California's ___ Valley",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "___ Valley",
    "Winery in Sonoma County",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "___ Valley, Calif.",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "___ Hills (edge of the San Fernando Valley)",
    "California's ___ Valley",
    "___ Valley, Calif.",
    "___ Valley, Calif.",
    "___ Valley, Calif.",
    "___ Valley, city near L.A.",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "California's ___ Valley",
    "___ Valley, Calif.",
    "One of the Dodecanese Islands",
    "___ Valley, Calif.",
    "California's ___ Valley",
    "California's ___ Valley",
    "Ventura County's ___ Valley",
    "___ Valley, Calif.",
    "California's___ Valley",
    "Ventura County's___Valley",
    "___ Valley, Calif."
  ],
  "amyl": [
    "___ nitrate (diesel fuel additive)",
    "___ nitrite (angina treatment)",
    "___ nitrate",
    "___ nitrite",
    "___ acetate (banana oil)",
    "___ nitrite",
    "___ nitrate",
    "___ nitrate",
    "___ nitrate",
    "___ nitrite",
    "___ nitrate",
    "Form of nitrite",
    "___ nitrate (stimulant)",
    "Kind of alcohol",
    "Univalent chemical group"
  ],
  "lora": [
    "Best-selling erotic novelist ___ Leigh",
    "Romance novelist ___ Leigh"
  ],
  "auks": [
    "Relatives of puffins",
    "Arctic fliers",
    "Dovekies, e.g.",
    "\"Great\" birds",
    "Arctic seabirds",
    "Razor-billed birds",
    "Diving seabirds",
    "\"Great\" birds",
    "Razor-billed birds",
    "Diving birds",
    "Web-footed birds"
  ],
  "sinn": [
    "___ Fein (Irish political party)",
    "Ireland's ___ Fein",
    "___ Fein",
    "___ Fein (Irish political group)",
    "___ Fein (Irish political party)",
    "___ Fein (Irish group)",
    "___ Fein",
    "___ Fein",
    "___ Fein",
    "Ireland's ___ Fein",
    "___ Fein",
    "___ Fein",
    "Irish party word",
    "___ Fein",
    "___ Fein",
    "___ Fein",
    "___ Fein",
    "___ Fein",
    "___ Fein",
    "___ Fein"
  ],
  "noth": [
    "Chris of \"The Good Wife\"",
    "Chris of CBS's \"The Good Wife\"",
    "Chris who played Mr. Big on \"Sex and the City\""
  ],
  "prie": [
    "\"Je vous en ___\" (French for \"You're welcome\")",
    "___-dieu",
    "___-dieu (kneeling bench)",
    "___-dieu",
    "\"Je vous en ___\"",
    "___-dieu",
    "\"Je vous en ___\" (\"You're welcome\": Fr.)",
    "___-dieu",
    "___-dieu",
    "Prayed, in Paris",
    "___-dieu (kneeling bench)",
    "___-dieu",
    "___-dieu",
    "___-dieu (pew part)",
    "___-dieu"
  ],
  "nowi": [
    "\"___ get it\"",
    "\"___ get it!\"",
    "\"___ get it!\"",
    "\"___ ask you!\"",
    "Start of a prayer",
    "\"___ lay me down...\""
  ],
  "sone": [
    "Acoustic measure",
    "Volume measure",
    "Aural measure",
    "Unit of loudness",
    "Volume measure",
    "Loudness unit",
    "Loudness unit",
    "Loudness unit",
    "Loudness unit",
    "Loudness unit",
    "Loudness measure",
    "Unit of volume",
    "Sound amount",
    "Loudness unit",
    "Unit of loudness",
    "Loudness unit",
    "Loudness measure",
    "Sound measure",
    "Loudness measure",
    "Loudness unit",
    "Loudness unit",
    "Unit of loudness"
  ],
  "hehs": [
    "Sneaky laughs",
    "Sneaky little snickers",
    "Little chortles",
    "Dastardly laughs",
    "Sneaky laughs",
    "Derisive laughs",
    "Sneaky laughs",
    "Exclamations of inquiry"
  ],
  "gams": [
    "Groups of whales",
    "Pinup's legs",
    "W.W. II pinup features",
    "Pinup's pride",
    "Pinup's legs",
    "Pinup's legs",
    "Legs, slangily",
    "Legs, slangily",
    "Schools of whales",
    "Legs, to a zoot suiter",
    "Pinup features",
    "Cheesecake feature",
    "Herds of humpbacks",
    "Herds of whales"
  ],
  "xmas": [
    "December 25, informally",
    "Holiday shortening",
    "Big workday for Saint Nick",
    "High-airfare season, for short",
    "Dec. celebration",
    "Holiday associated with 44-Downs, in brief",
    "Short winter holiday?",
    "Dec. 25",
    "Short winter day?",
    "Holiday time, in ads",
    "Present time, briefly",
    "December ad word",
    "Seasonal time, in store signs",
    "Brief holiday?",
    "Lennon/Ono's \"Happy ___ (War Is Over)\"",
    "$hopping season?",
    "Short holiday?",
    "Opening day?",
    "Present occasion, informally",
    "Dec. 25",
    "Short holiday?",
    "Kind of tree",
    "John Lennon's \"Happy ___ (War Is Over)\"",
    "Present time?",
    "Yule",
    "Brief holiday?",
    "Yule, in ads",
    "2022-12-25T00:00:00.000Z",
    "Present day?",
    "Present occasion",
    "Holiday shortening",
    "Big e-tailing season",
    "Dec. holiday",
    "Present time",
    "Holiday season, for short"
  ],
  "pcts": [
    "Hundredths: Abbr.",
    "Free throw avgs., e.g.",
    "Loan figs.",
    "Election night figs.",
    "Pie chart figs.",
    "Police hdqrs.",
    "Cuts for agts.",
    "Home mortgage stats",
    "Election figs.",
    "Batting avgs., e.g.",
    "Poll amts.",
    "Election numbers: Abbr.",
    "Poll amts."
  ],
  "attu": [
    "Most remote of the Near Islands",
    "W.W. II's Battle of ___",
    "One of the Near Islands",
    "Battle of ___ (1943 U.S./Japanese conflict)",
    "Island whose battlefield area is a U.S. National Historic Landmark",
    "Westernmost island of the Aleutians",
    "Aleutian isle",
    "Outermost Aleutian island",
    "Aleutian island",
    "1943 U.S.-vs.-Japan battle site",
    "Aleutian island",
    "Westernmost of the Aleutians",
    "Westernmost Aleutian",
    "Westernmost of the Aleutians",
    "North Pacific island",
    "U.S. island occupied by Japan during W.W. II",
    "Westernmost of the Aleutians",
    "End island of the Aleutians",
    "Aleutian island",
    "Outermost of the Aleutians",
    "Westernmost Aleutian",
    "Aleutian island",
    "Farthest of the Near Islands",
    "Westernmost Aleutian",
    "One of the Aleutians",
    "North Pacific island",
    "Alaska radar station site",
    "Westernmost of the Aleutians",
    "Bering Sea island",
    "One of the Near Islands",
    "One of the Near Islands",
    "An Aleutian",
    "Alaska wildlife refuge site",
    "Westernmost Aleutian",
    "Aleutian island",
    "Bering Sea isle",
    "Aleutian island",
    "Westernmost of the Aleutians",
    "Alaskan wildlife refuge site",
    "One of the Aleutians",
    "Westernmost Aleutian",
    "Westernmost Aleutian",
    "Aleutian island"
  ],
  "ekco": [
    "Kitchenware brand",
    "Sister brand of CorningWare",
    "Big name in kitchenware",
    "Kitchenware brand",
    "Kitchenware brand",
    "Kitchen utensil brand name"
  ],
  "coot": [
    "Codger",
    "Codger",
    "Geezer",
    "Symbol of baldness",
    "Codger",
    "Codger",
    "Geezer",
    "Old geezer",
    "Old geezer",
    "Geezer",
    "Codger",
    "Codger",
    "Codger",
    "Geezer",
    "Old fool",
    "Geezer",
    "Geezer",
    "Codger",
    "Crotchety one",
    "Codger",
    "Foolish fellow",
    "Old fool",
    "Codger",
    "Old feller",
    "Geezer"
  ],
  "neva": [
    "St. Petersburg's river",
    "St. Petersburg's river",
    "River that passes by the Hermitage",
    "St. Petersburg's river",
    "Gulf of Finland feeder",
    "River through St. Petersburg",
    "It has banks in St. Petersburg",
    "River of St. Petersburg",
    "River that flows by the Hermitage",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "River connected by canal to the Volga",
    "Gulf of Finland feeder",
    "Gulf of Finland feeder",
    "Winter Palace river",
    "St. Petersburg's river",
    "St. Petersburg's river",
    "St. Petersburg's river"
  ],
  "sufi": [
    "Islamic mystic",
    "One practicing a mystical form of Islam",
    "Muslim mystic",
    "Muslim mystic",
    "Muslim mystic",
    "Religious mystic",
    "Muslim mystic",
    "Muslim mystic"
  ],
  "doso": [
    "\"Go ahead!\"",
    "\"I can only ___ much\"",
    "\"One can only ___ much\"",
    "\"I ___ appreciate ...\"",
    "\"I can only ___ much\"",
    "\"I can only ___ much\""
  ],
  "imas": [
    "\"Look at me, ___ helpless ...\" (opening to \"Misty\")",
    "\"___ surprised as you are!\"",
    "\"___ shocked as you are\"",
    "\"Look at me, ___ helpless ...\" (first words of \"Misty\")",
    "\"Look at me, ___ helpless ...\" (opening to \"Misty\")",
    "\"___ corny ...\"",
    "\"___ corny ?à\" (\"A Wonderful Guy\" lyric)",
    "\"___ corny ...\"",
    "\"___ shocked as you...\"",
    "\"___ restless as a willow...\" (1945 movie lyric)",
    "\"___ helpless as a kitten up a tree...\"",
    "\"Look at me, ___...\"",
    "\"___ blue as blue...\"",
    "\"___ blue as I can be...\"",
    "\"___ corny as Kansas...\""
  ],
  "ancy": ["Suffix with expect", "Suffix with buoy"],
  "nhra": [
    "Drag racing org.",
    "Dragsters' grp.",
    "Dragsters' org.",
    "Drag racers' governing grp.",
    "Drag racers' org.",
    "Drag racing org."
  ],
  "slug": [
    "Hit hard",
    "Garden crawler",
    "Counterfeit token",
    "Bullet",
    "Creature leaving a slime trail",
    "Sock",
    "Hard punch",
    "Shot of liquor",
    "Lie-abed",
    "Hit hard, as a baseball",
    "It doesn't really represent change",
    "Vending machine tricker",
    "Creepy crawler",
    "Fare beater?",
    "Hit with the fist",
    "Token look-alike",
    "Powerful punch",
    "Fare-beater's aid",
    "Garden pest",
    "Hard punch",
    "Bullet",
    "Hit hard",
    "Wallop",
    "False coin",
    "Soda machine tricker",
    "Turnstile cheater",
    "False coin",
    "Counterfeit coin",
    "Coin that's not a coin"
  ],
  "itat": [
    "\"Let's leave ___ that\"",
    "\"Leave ___ that!\"",
    "Leave ___ that",
    "\"Let's just leave ___ that\"",
    "\"Let's leave ___ that\"",
    "\"Take ___ face value\"",
    "\"Let's just leave ___ that\"",
    "\"Let's just leave ___ that\""
  ],
  "wags": [
    "Goes back and forth, as a tail",
    "Goes back and forth, as a tail",
    "Jokesters",
    "Jokers",
    "Dog tail motions",
    "Cards",
    "Witty sorts",
    "Tail motions",
    "Tail motions",
    "Quipsters",
    "Friars Club emcees",
    "Turns tail?",
    "Jokesters",
    "Tail movements",
    "Jokesters"
  ],
  "bugs": [
    "Gets to",
    "Software issues",
    "Perturbs",
    "Program problems",
    "Vexes"
  ],
  "biol": [
    "Science subj.",
    "H.S. science class",
    "Subj. involving cells",
    "Frog-dissecting class: Abbr.",
    "High school subj.",
    "Junior high subj.",
    "Branch of 39-Down",
    "Sci. course"
  ],
  "nazi": [
    "\"Life Is Beautiful\" extra",
    "Extra in \"The Producers\"",
    "Frequent enemy in comics set in the 1940s",
    "The Soup ___ (\"Seinfeld\" character)",
    "\"Inglourious Basterds\" figure",
    "\"Judgment at Nuremberg\" defendant",
    "W.W. II foe",
    "Foe in the first Indiana Jones film",
    "See 34-Down",
    "Extra in \"The Producers\"",
    "\"Hogan's Heroes\" figure",
    "\"Hogan's Heroes\" figure",
    "\"Casablanca\" extra",
    "Ultra-authoritarian",
    "\"The Producers\" extra",
    "The Soup ___",
    "\"The Soup ___\" (classic \"Seinfeld\" episode)",
    "\"The Producers\" extra",
    "\"The Pianist\" extra",
    "Many a part in \"The Pianist\"",
    "Axis figure",
    "\"Schindler's List\" villain",
    "Hitlerite",
    "\"Hogan's Heroes\" extra",
    "\"Schindler's List\" extra"
  ],
  "cuer": [
    "One who might work in the wings of a theater",
    "Prompter?",
    "One in a prompt box",
    "Move caller for a round dance",
    "Round dance official",
    "Stage assistant",
    "One calling the moves for a round dance",
    "Signal caller",
    "Prompt giver",
    "Stage assistant",
    "Line feeder",
    "Square dance official"
  ],
  "beam": [
    "Radiant smile",
    "Smile from ear to ear",
    "Headlight",
    "Look ecstatic",
    "Gymnastics event, informally",
    "4x4, e.g.",
    "Grin from ear to ear",
    "Put on a happy face",
    "Smile broadly",
    "Searchlight element",
    "Ray of light",
    "Smile",
    "Flashlight light",
    "Ship's keel, e.g.",
    "Look elated",
    "Appear thrilled",
    "Cantilever, e.g.",
    "Show of pride",
    "Appear elated",
    "Smile from ear to ear",
    "Light from a headlight",
    "Bit of moonshine",
    "Gymnastics apparatus, for short",
    "Show pride, in a way",
    "Radiant look",
    "Show one's teeth?",
    "Ceiling support",
    "Smile proudly",
    "Smile widely",
    "Laser light",
    "It travels at the speed of light",
    "Grin from ear to ear",
    "Radiant look",
    "Show pleasure",
    "Smile widely",
    "Laser light",
    "Gymnast's place",
    "Smile broadly",
    "It may be high or low on a car",
    "Bit of moonlight",
    "Gymnast's perch",
    "Skyscraper support",
    "Laser output",
    "Flashlight's projection",
    "Jim at the bar",
    "Smile",
    "Grin broadly",
    "Light shaft",
    "Widest part",
    "Smile broadly"
  ],
  "seau": [
    "Junior in the Football Hall of Fame",
    "Junior ___, 12-time Pro Bowl linebacker",
    "Junior posthumously inducted into the Football Hall of Fame",
    "Junior in the Football Hall of Fame",
    "Junior in 12 Pro Bowls",
    "Junior of the N.F.L.",
    "Former N.F.L. great Junior ___",
    "12-time Pro Bowl player Junior",
    "Junior in the N.F.L.",
    "12-time Pro Bowl pick Junior ___",
    "All-Pro linebacker Junior of the Chargers",
    "Linebacker Junior"
  ],
  "echt": [
    "Real: Ger.",
    "German \"genuine\"",
    "Genuine: Ger.",
    "Authentic: Ger.",
    "Real: Ger."
  ],
  "elas": [
    "They, in Portugal",
    "Old musical high notes",
    "They, in Sao Paulo",
    "Old musical notes"
  ],
  "illy": [
    "Poorly",
    "In a bad way",
    "In a bad way",
    "Badly",
    "Not well",
    "In a bad way",
    "In a sick way",
    "Not well",
    "In a bad way",
    "Unfavorably",
    "In a sick way",
    "Not wisely"
  ],
  "shul": [
    "Sabbath service site",
    "Synagogue",
    "Jewish house of worship",
    "Jewish temple",
    "House of prayer",
    "Place for a bar mitzvah service",
    "Holy Ark's location",
    "Synagogue",
    "Synagogue",
    "Synagogue",
    "Temple",
    "House of worship",
    "Holy Ark locale",
    "Rebbe's locale",
    "Synagogue",
    "Synagogue"
  ],
  "lani": [
    "Civil rights activist Guinier",
    "Activist Guinier",
    "Civil rights activist Guinier",
    "Legal scholar Guinier",
    "Activist Guinier",
    "Legal scholar Guinier",
    "Guinier of civil rights",
    "Legal scholar Guinier",
    "\"Lift Every Voice\" author Guinier",
    "Lawyer/civil-rights activist Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "Legal scholar Guinier",
    "O?ÆGrady of ?ôEight is Enough?ö"
  ],
  "jens": ["Norwegian P.M. Stoltenberg", "Norwegian P.M. Stoltenberg"],
  "tole": [
    "Painted tinware",
    "Lacquered metalware",
    "Old lampshade material",
    "Tinware art",
    "Japanned metal",
    "Enamelware",
    "Lacquered metalware",
    "Painted metalware",
    "Some black metalware",
    "Old lampshade material",
    "Lacquered metalware",
    "Lacquered metalware",
    "Lacquered metalware"
  ],
  "rasa": [
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___ (blank slate)",
    "Blank, as a tabula",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Literally, \"scraped\"",
    "Tabula ___",
    "Literally, \"scraped\"",
    "Tabula ___",
    "Tabula description",
    "Tabula ___",
    "Tabula ___",
    "Latin for \"scraped\"",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___ (clean slate)",
    "Tabula ___",
    "Tabula ___",
    "Literally, \"scraped\"",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___",
    "Tabula ___"
  ],
  "logy": [
    "Mentally sluggish",
    "Sluggish",
    "Lethargic",
    "Feeling sluggish",
    "Mentally sluggish",
    "Sluggish",
    "Half-awake"
  ],
  "bofa": ["U.S. financial giant, for short"],
  "katy": [
    "Singer Perry",
    "Perry with the 2010 hit \"Firework\"",
    "\"___ Bell\" (Stephen Foster song)",
    "Musical Perry",
    "Steely Dan's title liar",
    "1918 song girl whose name was sung with a stutter",
    "Singer Perry with the 2010 #1 hit \"California Gurls\"",
    "S-s-s-subject of a 1918 hit song",
    "\"What ___ Did\" (classic children's book with a punny title)",
    "\"A maid with hair of gold,\" in an old song",
    "Name in a stuttered 1918 song title",
    "Title girl of a 1918 hit song",
    "Steely Dan's \"___ Lied\"",
    "Stutterer's love?",
    "\"___ Bell\" (Stephen Foster song)",
    "Lady of a 1918 hit",
    "Lady of a stuttering song",
    "Girl of a 1918 song"
  ],
  "jove": [
    "One to swear by?",
    "\"By ___!\"",
    "Pantheon member",
    "Jupiter",
    "Zeus, to the Romans",
    "\"By ___!\"",
    "Zeus, to Romans",
    "Name in an old oath",
    "You can swear by him",
    "\"By ___!\"",
    "Jupiter",
    "Roman Zeus",
    "Jupiter, in an interjection"
  ],
  "boyo": ["Laddie", "Laddie", "Laddie", "99-Down, Down Under"],
  "alar": [
    "Bygone orchard spray",
    "Apple application that's now banned",
    "Now-regulated growth regulator",
    "Banned apple spray",
    "Banned fruit spray",
    "Banned plant growth regulator",
    "Banned apple spray",
    "Subject of a 1989 E.P.A. ban",
    "Old orchard spray",
    "What is the popular name for daminozide?",
    "Creator of bad apples?",
    "Winglike",
    "Banned orchard spray",
    "Banned apple spray",
    "Winged",
    "Withdrawn apple spray",
    "1989 E.P.A. target",
    "Withdrawn apple spray",
    "Bygone spray",
    "Banned fruit spray",
    "Controversial color enhancer",
    "Wing-shaped",
    "Former orchard spray",
    "Poison apple creator?",
    "Banned apple spray",
    "Bygone spray",
    "Trade name of daminozide",
    "Banned orchard spray",
    "Banned apple spray",
    "Winglike",
    "Banned chemical",
    "Wing-shaped",
    "Target of a 1989 E.P.A. investigation",
    "Spray withdrawn in 1989",
    "Banned spray on apple trees",
    "Banned orchard spray",
    "Garden no-no, now",
    "Onetime E.P.A. target",
    "Wing-shaped",
    "Banned orchard spray",
    "Bygone spray",
    "Controversial spray",
    "Winged",
    "Former fruit ripening retardant",
    "Banned spray",
    "Banned apple spray",
    "Banned orchard spray",
    "Having wings",
    "Banned apple spray",
    "Banned orchard spray",
    "Winged",
    "Banned spray",
    "Controversial apple spray",
    "Fruit spray",
    "Winged",
    "Garden spray",
    "Winged",
    "Apple spray",
    "Apple spray",
    "Wing-shaped",
    "Old apple spray",
    "Banned apple application",
    "Winglike",
    "Fruit spray",
    "Color-enhancing apple spray",
    "Controversial spray",
    "Controversial spray",
    "Apple color enhancer",
    "Controversial orchard application",
    "Winged",
    "Banned orchard chemical",
    "Orchard no-no",
    "Apple growth retardant",
    "Wing-shaped",
    "Controversial apple growth regulator",
    "1989 E.P.A. target",
    "Banned apple spray",
    "Winged",
    "Banned spray",
    "Daminozide brand",
    "Winged",
    "Apple application, once",
    "Brand of daminozide",
    "Plant-growth retardant",
    "Daminozide, commercially",
    "Out-of-favor apple treatment",
    "Banned apple spray",
    "Winged",
    "Agricultural chemical",
    "Outlawed spray",
    "Brand of daminozide",
    "Controversial ripener",
    "Controversial orchard spray",
    "Controversial orchard spray",
    "Winglike",
    "Controversial apple spray",
    "Banned plant-growth retardant",
    "Winged",
    "Orchard spray",
    "Wing-shaped",
    "Wing-shaped",
    "Winglike",
    "Daminozide, familiarly",
    "Former orchard spray",
    "Plant-growth retardant",
    "Controversial orchard spray",
    "Winglike",
    "Banned apple spray",
    "Banned apple spray"
  ],
  "esth": [
    "O.T. book read during 28-Down",
    "Tallinn native",
    "O.T. book read during Purim",
    "Old Testament book: Abbr.",
    "Book before Job: Abbr.",
    "Job preceder: Abbr.",
    "Book after Neh.",
    "Only O.T. book that never mentions God: Abbr.",
    "O.T. book read at Purim",
    "Job precursor: Abbr.",
    "Book before Job: Abbr.",
    "Job preceder: Abbr.",
    "Book before Job: Abbr.",
    "Old Testament book: Abbr.",
    "Baltic resident",
    "Job preceder: Abbr.",
    "Tallinn native",
    "Book after Neh.",
    "Book before Job: Abbr.",
    "Tallinn native",
    "Job preceder: Abbr.",
    "Book after Nehemiah: Abbr.",
    "Book after Neh.",
    "O.T. book read at Purim",
    "Book before Job: Abbr.",
    "O.T. book",
    "O.T. book",
    "O.T. book",
    "Book after Neh.",
    "O.T. book",
    "Tallinn native"
  ],
  "ster": [
    "Suffix with trick",
    "Suffix with speed",
    "Team finale?",
    "End with speed",
    "Suffix with prank or poll",
    "Hip attachment?",
    "Young or old follower",
    "Suffix with slick",
    "Suffix with mob",
    "Suffix with hip or hoop",
    "Suffix with prank",
    "Nap finish?",
    "Suffix with hip or tip",
    "Song ending",
    "Poll closing?",
    "Suffix with prank",
    "Suffix with hip",
    "Suffix with tip",
    "Suffix with pun",
    "Trick ending?",
    "Suffix with poll or pun",
    "Team follower?",
    "Poll closing?",
    "Suffix with hip",
    "Suffix with game",
    "Poll conclusion?",
    "Suffix with 44-Across",
    "Like some silver: Abbr.",
    "Suffix with trick",
    "Suffix with young or old",
    "Suffix with prank",
    "Trick ending?",
    "Suffix with old",
    "Suffix with dump",
    "Suffix with hip or quip",
    "Tip or hip follower",
    "Poll ending",
    "Suffix with song",
    "Suffix with young",
    "Tip follower",
    "Suffix with prank or trick",
    "Suffix with game",
    "Suffix with hip",
    "Huck follower",
    "Pun conclusion",
    "Spin tail?",
    "Song ender",
    "Road extension",
    "Song ending",
    "Suffix with hip or quip",
    "Suffix with gang",
    "Suffix with pun",
    "Suffix with hip or hoop",
    "Suffix with 22-Down",
    "Suffix with gang",
    "Hip ending",
    "Trick ending",
    "Ending with hoop",
    "Suffix with hoop",
    "Suffix with hip",
    "Slangy noun suffix",
    "Suffix with huck",
    "Suffix with old",
    "Trick ending?",
    "Suffix with trick or prank",
    "Suffix with mob",
    "Associate: Suffix",
    "Pun's finish",
    "Suffix with pun",
    "Suffix with poll",
    "Ending with road",
    "Suffix with tip",
    "Suffix with road or speed",
    "Dump closure",
    "Suffix with trick or prank",
    "Prank ending",
    "Ending with seam or team",
    "Suffix with road or hip",
    "Team finisher",
    "Ending for hip or hoop",
    "Road ending",
    "Suffix with young or old",
    "Suffix with tip or dump",
    "Ending for tip or team",
    "Ending with gang or mob",
    "Ending with spin or speed"
  ],
  "sieg": [
    "Victory, in German",
    "Victory, in German",
    "Victory, to Wagner",
    "German \"victory\"",
    "Victory, Viennese-style",
    "Victory: Ger.",
    "Victory, to Viktor",
    "Victory: Ger.",
    "Victory, Viennese-style",
    "Victory, to Wagner",
    "German victory",
    "Teutonic triumph"
  ],
  "qeii": [
    "Role for Helen Mirren, informally",
    "U.K. V.I.P.",
    "Monarch who took the throne in '52",
    "Prince Philip's spouse, for short",
    "Longtime Cunard flagship, for short",
    "Longest-reigning British monarch, informally",
    "Royal who's notably a crossword fan, for short",
    "Role for Helen Mirren, briefly",
    "Long-reigning English monarch, informally",
    "Monarch moniker, for short",
    "Grandmother of 30-Across, informally",
    "British monarch beginning in '52",
    "Helen Mirren's crowning role, informally?",
    "Ruler crowned in 1953, informally",
    "Oscar-winning role for Helen Mirren, in brief",
    "Modern-day monarch, for short"
  ],
  "outa": [
    "Take ___ loan",
    "Eke ___ living",
    "Eke ___ living",
    "\"I'm ___ here!\"",
    "\"I'm Gonna Wash That Man Right ___ My Hair\"",
    "Make ___ check",
    "\"I'm ___ here!\""
  ],
  "orff": [
    "Carl who composed \"Carmina Burana\"",
    "\"Carmina Burana\" composer Carl",
    "\"Carmina Burana\" composer",
    "\"Antigonae\" composer Carl",
    "\"Carmina Burana\" composer",
    "\"Carmina Burana\" composer",
    "\"Antigonae\" opera composer",
    "\"O Fortuna\" composer",
    "\"Trionfo di Afrodite\" composer",
    "\"Catulli Carmina\" composer",
    "\"Carmina Burana\" composer",
    "\"Carmina Burana\" composer",
    "German composer Carl",
    "\"Carmina Burana\" composer"
  ],
  "twod": [
    "Lacking depth, informally",
    "Having no depth, briefly",
    "Like most movies",
    "Like a plane, for short",
    "Like traditional movies, for short",
    "Lacking depth, in brief",
    "Like flicks seen without special glasses",
    "Having length and width only, briefly",
    "Lacking depth",
    "Flat",
    "Lacking depth",
    "Having no depth",
    "Lacking depth"
  ],
  "imre": [
    "Nagy of Hungarian history",
    "2002 Literature Nobelist Kertesz",
    "Nobel-winning novelist ___ Kertesz",
    "Hungarian patriot Nagy",
    "Hungarian man's name that's an anagram of 38-Down",
    "2002 Literature Nobelist Kertesz",
    "Hungary's ___ Nagy",
    "Hungarian hero ___ Nagy",
    "Hungarian patriot Nagy",
    "Hungarian writer Madach",
    "1950s Hungarian premier ___ Nagy",
    "2002 Literature Nobelist ___ Kertesz",
    "2002 Literature Nobelist Kertesz",
    "2002 Literature Nobelist Kertesz",
    "Hungarian composer Kalman",
    "Hungarian hero ___ Nagy",
    "2002 Literature Nobelist Kertesz",
    "Nagy of Hungary",
    "1950's Hungarian premier Nagy",
    "Hungary?Æs Nagy",
    "50's Hungarian leader Nagy",
    "Hungary's Nagy",
    "Hungarian patriot Nagy",
    "Hungary's ___ Nagy",
    "Nagy of Hungary",
    "Hungarian name equivalent to Amory",
    "Nagy of Hungary",
    "Hungarian patriot Nagy",
    "Hungarian poet Madach",
    "Hungarian patriot Nagy",
    "50's Hungarian leader Nagy",
    "Hungarian patriot___Nagy",
    "Nagy of Hungary",
    "Hungary's Nagy",
    "Hungarian patriot Nagy"
  ],
  "akey": [
    "\"It is a riddle, wrapped in a mystery, inside an enigma; but perhaps there is ___\": Churchill",
    "Answer to the riddle \"What force or strength cannot get through / I, with gentle touch, can do\"",
    "Solution to the classic riddle \"What force or strength cannot get through, / I, with gentle touch, can do\"",
    "\"The House Without ___\" (first Charlie Chan mystery)",
    "Caps Lock neighbor, on a computer"
  ],
  "zora": [
    "Author ___ Neale Hurston",
    "Novelist ___ Neale Hurston",
    "Novelist ___ Neale Hurston",
    "First name in Harlem Renaissance literature",
    "First name in the Harlem Renaissance",
    "Author ___ Neale Hurston",
    "Author ___ Neale Hurston",
    "Author ___ Neale Hurston",
    "Author ___ Neale Hurston"
  ],
  "sino": [
    "___-Japanese War",
    "___-Soviet",
    "Chinese leader?",
    "Chinese: Prefix",
    "___-Japanese War",
    "___-Soviet",
    "___-Japanese War",
    "___-Japanese War",
    "___-Soviet relations",
    "___-Soviet relations",
    "___-American relations",
    "___-Japanese War",
    "___-Tibetan languages",
    "___-Japanese War",
    "___-Japanese",
    "___-Soviet relations",
    "___-American relations",
    "___-Japanese War",
    "___-Soviet relations",
    "Chinese: Prefix",
    "___-American relations",
    "Prefix with Tibetan",
    "___-Soviet relations",
    "Chinese leader?",
    "___-American relations",
    "___-American relations",
    "Chinese leader?",
    "Chinese: Prefix",
    "Chinese: Prefix",
    "___-Japanese War",
    "Chinese: prefix",
    "Chinese: Prefix",
    "East Asian prefix",
    "China: Prefix",
    "Chinese: Prefix"
  ],
  "jama": [
    "Mag for docs",
    "Weekly reading for drs.",
    "Publication read by drs.",
    "Doc's subscription",
    "Weekly reading for drs.",
    "Drs.' reading since 1883",
    "Publication read by drs.",
    "Dr.'s magazine",
    "Dr.'s professional magazine",
    "Dr.'s magazine",
    "Doctors' professional magazine"
  ],
  "jure": [
    "De ___ (by law)",
    "De ___ (legally)",
    "De ___ (by law)",
    "De ___ (by right)"
  ],
  "hoer": [
    "Garden worker",
    "Gardener, often",
    "Gardener in the weeds",
    "Many a gardener at work",
    "Part of a landscaping team",
    "Earth mover",
    "Person who's groundbreaking?",
    "Gardener, at times",
    "One breaking new ground?",
    "Weed whacker",
    "Garden worker",
    "Crop cropper",
    "Worker in the garden",
    "Furrow maker",
    "Weed killer?",
    "Many a gardener",
    "Gardener, sometimes",
    "One with ground strokes?",
    "Groundbreaking person",
    "Person standing in a row",
    "Gardener's role",
    "Farmer, sometimes",
    "Cultivator",
    "Garden hand",
    "Weed killer",
    "Earth mover"
  ],
  "lago": [
    "Italian body of water",
    "Body of agua",
    "Spanish body of water",
    "Italy's ___ di Como",
    "Italy's ___ di Garda",
    "Body of water in Italy",
    "Italy's ___ di Como",
    "Titicaca, por ejemplo",
    "Titicaca is one",
    "Titicaca, por ejemplo",
    "Maracaibo or Titicaca, e.g.",
    "Italy's ___ di Como",
    "Italian body of water",
    "___ di Como, Italia",
    "Titicaca, por ejemplo"
  ],
  "iglu": [
    "Inuit shelter: Var.",
    "Eskimo home: Var.",
    "Inuit word for \"house\"",
    "Ice house: Var.",
    "White house: Var.",
    "Ice house: Var.",
    "Ice house: Var.",
    "Eskimo home: Var."
  ],
  "poco": [
    "A little, musically",
    "A little, musically",
    "Somewhat, in music",
    "Somewhat, in music",
    "Little, in La Scala",
    "___ adagio (score direction)",
    "Somewhat, musically",
    "Somewhat, in music",
    "Rather, musically",
    "Somewhat, in music",
    "Somewhat, to Salieri",
    "Somewhat, in music",
    "Somewhat, in music",
    "Slightly, in music",
    "A little, musically",
    "Somewhat, in music",
    "Little of Verdi?",
    "Somewhat, musically",
    "Somewhat, to Salieri",
    "Somewhat, in music",
    "Somewhat, in music"
  ],
  "addr": ["It might end in a ZIP code: Abbr.", "P.O. box, e.g."],
  "gmcs": [
    "Sierra and others",
    "Yukons and Envoys",
    "Sierra and Acadia vehicles",
    "Some pickups",
    "Some S.U.V.'s",
    "Yukons, e.g."
  ],
  "prek": [
    "Like some early learning, for short",
    "Like very early education, for short",
    "Nursery school, informally",
    "Like the Head Start program, for short",
    "Like school for toddlers, in brief",
    "Head Start program service, briefly",
    "Nursery school, briefly",
    "Like students in the Head Start program"
  ],
  "adig": ["Take ___ at (insult)", "Take ___ at (insult)"],
  "bowl": [
    "Container for soup or cereal",
    "Big game",
    "Big game",
    "Postseason grid matchup",
    "Many a New Year's Day game",
    "January 1 game",
    "Word with Orange or Peach",
    "Cricketer's action",
    "Play in the alley",
    "Mixing site",
    "Big sports event",
    "Play in an alley",
    "New Year's Day game",
    "Cereal serving",
    "New Year's entertainment",
    "New Year's event",
    "New Year's Day game",
    "Get spares, perhaps",
    "Fiesta or fish follower"
  ],
  "gimp": [
    "Hobble",
    "Hitch",
    "Walking difficulty",
    "Hobbling gait",
    "Hobbling gait",
    "Walk with difficulty"
  ],
  "ajax": [
    "Battler of Hector in the \"Iliad\"",
    "He fought alongside Achilles",
    "Sophocles tragedy",
    "Cleanser brand with a name from mythology",
    "Greek warrior of myth",
    "Hero in the Trojan War",
    "Brand of lemon dish liquid",
    "Homer's \"bulwark of the Achaeans\"",
    "Title character in a Sophocles play",
    "Sophocles play set during the Trojan War",
    "\"Iliad\" warrior",
    "Prince in \"Troilus and Cressida\"",
    "\"Iliad\" character",
    "Sophocles tragedy",
    "\"Iliad\" figure",
    "War hero who killed himself with a onetime rival's sword",
    "Sophocles title hero",
    "Fighter in the \"Iliad\"",
    "Greek warrior",
    "Popular cleanser",
    "Role in \"Troy\"",
    "Trojan War hero",
    "Cleanser whose name comes from Greek myth",
    "Product with TV's first advertising jingle, 1948",
    "\"Stronger than dirt\" sloganeer",
    "It's \"stronger than dirt\"",
    "Role in \"Troy\"",
    "Trojan War hero",
    "\"Cleans like a white tornado\" brand",
    "Comet competitor",
    "Comet competitor",
    "Sophocles drama",
    "Brand under a sink",
    "\"Iliad\" figure",
    "Trojan War hero",
    "Trojan War hero",
    "Subject of a Sophocles tragedy",
    "Comet competitor",
    "Trojan war suicide",
    "\"Stronger than dirt\" sloganeer",
    "Trojan War hero",
    "Sophocles tragedy",
    "Sophocles tragedy",
    "Warrior of legend",
    "Comet competitor",
    "Its slogan was once \"Cleans like a white tornado\"",
    "Bon Ami alternative",
    "Sophocles tragedy",
    "Rival of Bon Ami",
    "Comet competitor",
    "Son of Telamon",
    "Comet competitor",
    "Sophocles tragedy",
    "Bon Ami rival"
  ],
  "egis": [
    "Protection: Var.",
    "Protection: Var.",
    "Sponsorship: Var.",
    "Breastplate of Athena",
    "Guidance",
    "Backing: Var.",
    "Sponsorship: Var.",
    "Protection: Var.",
    "Protection: Var.",
    "Auspices: Var.",
    "Sponsorship: Var.",
    "Auspices: Var.",
    "Protection",
    "Sponsorship: Var.",
    "Sponsorship",
    "Auspices: Var.",
    "Sponsorship",
    "Sponsorship: Var.",
    "Patronage: Var.",
    "Authority: Var.",
    "Sponsorship",
    "Auspices: Var.",
    "Protection: Var.",
    "Protection: Var.",
    "Auspices",
    "Protection: Var.",
    "Protection: Var.",
    "Sponsorship",
    "Sponsorship",
    "Auspices",
    "Athena's shield",
    "Auspices: Var.",
    "Guardianship",
    "Sponsorship",
    "Sponsorship"
  ],
  "jpop": ["Music export from Tokyo, for short"],
  "shem": [
    "Brother of Ham",
    "A son of Noah",
    "Brother of Ham and Japheth",
    "Father of Elam and Aram",
    "Biblical son",
    "One of Noah's sons",
    "Ark deckhand",
    "Noah's firstborn",
    "Noah's eldest"
  ],
  "raye": [
    "Funny Martha",
    "\"The Martha ___ Show\" of 1950s TV",
    "Country singer Collin",
    "Comedian once called the Female Bob Hope",
    "Madcap Martha",
    "Martha a k a the Big Mouth",
    "Funny Martha of old TV",
    "Funnywoman Martha",
    "Comical Martha",
    "Comedic star Martha",
    "Country singer Collin ___",
    "\"The Martha ___ Show\" of 1950s TV",
    "Comic Martha",
    "Funnywoman Martha",
    "Comedic actress Martha",
    "\"Hellzapoppin'\" funnywoman",
    "Zany Martha",
    "Martha in denture ads",
    "1950's TV's \"The Martha ___ Show\"",
    "Zany Martha",
    "Wide-mouthed comic Martha",
    "Country singer Collin ___",
    "Martha in denture ads",
    "\"Big Mouth\" Martha",
    "Zany Martha",
    "Actress Martha",
    "Zany Martha",
    "\"Hellzapoppin'\" actress",
    "Oscar-winning Martha",
    "Widemouthed Martha",
    "Toothy Martha",
    "50's TV's \"The Martha ___ Show\"",
    "\"Big Mouth\" Martha",
    "Martha of \"Hellzapoppin\"",
    "Comic Martha",
    "Zany Martha",
    "Comic Martha"
  ],
  "hays": [
    "Kansas' Fort ___",
    "Robert of \"Airplane!\"",
    "Fort ___, Kan.",
    "\"Airplane!\" actor",
    "\"Airplane!\" star Robert",
    "Old Hollywood's ___ Code",
    "Makes bale?",
    "Makes bales on a farm",
    "Kansas' Fort ___ State University",
    "Makes bales, say",
    "Robert of \"Airplane!\"",
    "Eponymous code creator",
    "\"Airplane!\" actor Robert",
    "Actor Robert of \"Airplane!\"",
    "1930's movie bowdlerizer",
    "\"Airplane!\" actor Robert",
    "Makes bales for the barn",
    "\"Airplane\" co-star Robert",
    "Makes bales for the barn",
    "Makes bales on the farm",
    "Makes bales of alfalfa",
    "Does field work",
    "___ office (old Hollywood code group)",
    "Fodders for mudders"
  ],
  "meer": [
    "Dutch artist Jan van der ___",
    "Physics Nobelist Simon van der ___",
    "1984 Physics co-Nobelist Simon van der ___",
    "Dutch artist Jan van der ___"
  ],
  "scut": [
    "Rabbit's tail",
    "___ work (tedious tasks)",
    "___ work",
    "Short tail",
    "Cottontail's tail",
    "___ work (drudgery)",
    "Cottontail's tail",
    "Hare's tail",
    "Bunny's tail",
    "Rabbit's tail",
    "Rabbit's tail"
  ],
  "oozy": [
    "Like mud",
    "Like tar pits",
    "Like mud",
    "Like mud between one's toes, say",
    "Like wetlands",
    "Seeping",
    "Like slime",
    "Like mud",
    "Like mud",
    "Leaking goop",
    "Leaking",
    "Slimy",
    "Like mud",
    "Like mud"
  ],
  "ditz": [
    "Airhead",
    "Airhead",
    "Space cadet",
    "Scatterbrain",
    "Airhead",
    "Airhead",
    "Scatterbrain",
    "Airhead",
    "Airhead"
  ],
  "tesh": [
    "Composer John with six Emmys",
    "Pianist/radio host John",
    "Radio host John",
    "2002 Grammy nominee John",
    "Radio host John",
    "\"One World\" musician John",
    "Radio host John",
    "Syndicated radio host John",
    "\"Sax on the Beach\" musician John",
    "\"Music in the Key of Love\" composer",
    "\"One World\" musician John",
    "Radio host John",
    "\"Sax All Night\" New Ager",
    "\"Live at Red Rocks\" pianist",
    "Former \"Entertainment Tonight\" co-host",
    "TV/radio host John",
    "John who once co-hosted \"Entertainment Tonight\"",
    "Radio host/pianist John",
    "10-year host of \"Entertainment Tonight\"",
    "Radio host John",
    "New Ager John",
    "Radio host John",
    "Singer/radio host John",
    "Radio host John",
    "Radio host John",
    "Radio host John",
    "Emmy winner John",
    "Musical John",
    "New-Ager John",
    "Musician John",
    "New Ager John",
    "Hart co-anchor, once",
    "TV host turned New Ager",
    "New Age composer John",
    "Musician John",
    "Musician John",
    "New Age musician John",
    "Musician John",
    "Musician John",
    "Former TV host John",
    "Former TV co-host",
    "\"Entertainment Tonight\" co-host",
    "Hart's TV partner",
    "\"Entertainment Tonight\" host",
    "Musician/sportscaster John",
    "Former \"Entertainment Tonight\" co-host"
  ],
  "arei": [
    "\"Whose woods these ___ ...\": Frost",
    "\"Whose woods these ___ think ...\": Frost",
    "\"Whose woods these ___ think I know\": Frost",
    "\"Whoever you ___ love you\" (Burt Bacharach lyric)"
  ],
  "hoth": [
    "Ice planet in \"The Empire Strikes Back\"",
    "Ice planet in \"The Empire Strikes Back\"",
    "Ice planet in \"The Empire Strikes Back\""
  ],
  "lows": [
    "The worst of times",
    "Worst of times",
    "Some temperatures",
    "Cattle calls",
    "Stock exchange?",
    "Stats on weather reports",
    "The worst of times",
    "Wall Street minimums",
    "Some stock quotes",
    "Opposite of highs",
    "Temperature extremes",
    "Stock market figures",
    "Areas on weather maps",
    "Weather stats",
    "Night temps, usually",
    "Cyclones, meteorologically"
  ],
  "zima": [
    "Product of the Coors Brewing Company",
    "Bygone alcopop",
    "Former competitor of Smirnoff Ice",
    "Once-popular alcopop",
    "Onetime Coors alcopop",
    "Coors alcopop brand",
    "Former drink marketed as \"zomething different\"",
    "Alcopop brand",
    "Coors product",
    "Coors brand",
    "Fruity alcoholic brand",
    "Lemon-lime malt brand",
    "Coors product",
    "Coors brand",
    "Coors product since 1992"
  ],
  "deva": [
    "Hindu divinity",
    "Hindu god",
    "Literally, \"god,\" in Sanskrit",
    "Zoroastrian spirit",
    "Vedic deity",
    "Hindu divinity"
  ],
  "juba": ["Capital of South Sudan", "Capital of South Sudan"],
  "folk": [
    "Genre at the Grammys",
    "Ordinary people",
    "Much coffeehouse music",
    "Pete Seeger's genre",
    "Common people",
    "Music genre for Joan Baez",
    "Coffeehouse music genre",
    "Arlo Guthrie's genre",
    "Music genre",
    "Coffeehouse music",
    "Kind of rock",
    "___ music (Weavers' genre)",
    "Townspeople"
  ],
  "educ": [
    "Cabinet dept.",
    "Cabinet dept.",
    "Cabinet dept. concerned with schools",
    "Common Core dept.",
    "Cabinet dept.",
    "N.E.A. part: Abbr.",
    "Cabinet dept.",
    "P.T.A. interest: Abbr.",
    "Part of E.T.S.: Abbr.",
    "Schooling: Abbr.",
    "P.T.A.'s concern: Abbr.",
    "Cabinet dept. since 1979",
    "P.T.A. interest",
    "N.E.A. part: Abbr.",
    "No Child Left Behind dept.",
    "N.E.A. part: Abbr.",
    "Cabinet dept.",
    "Part of H.E.W.: Abbr.",
    "Cabinet dept.",
    "The \"E\" of N.E.A.: Abbr.",
    "P.T.A. concern: Abbr.",
    "Cabinet dept.",
    "P.T.A. concern: Abbr.",
    "Cabinet dept. since 1979",
    "Cabinet dept.",
    "Instruction: Abbr.",
    "Cabinet dept.",
    "P.T.A.'s focus: Abbr.",
    "Cabinet dept.",
    "Part of N.E.A.: Abbr.",
    "Part of E.T.S.: Abbr.",
    "Cabinet dept.",
    "Cabinet Dept."
  ],
  "lock": [
    "Stone-cold cinch",
    "Overwhelming favorite, informally",
    "Product of Yale or Medeco",
    "Certainty",
    "Key's partner",
    "Product of Yale",
    "Sure thing",
    "Tumbler locale",
    "Where tumblers can be found",
    "Canal part",
    "Combination ___",
    "Sure winner",
    "Canal section",
    "Wrestling hold",
    "It may be picked",
    "Canal device"
  ],
  "alls": [
    "Shakespeare title starter",
    "\"God's in his heaven - ___ right with the world\"",
    "First word in a Shakespeare title",
    "\"___ well\"",
    "\"___ fair in love and war\"",
    "\"___ well\"",
    "\"___ right with the world\"",
    "\"___ fair ...\"",
    "Shakespeare title contraction",
    "\"___ well\"",
    "\"___ well\"",
    "\"___ well\"",
    "Know-it-___ (cocky types)",
    "Tell-___ (some bios)",
    "Ending with over or cover",
    "\"___ well ...\"",
    "Shakespeare title starter",
    "Shakespearean title start",
    "Cure-___ (panaceas)",
    "\"___ Well That Ends Well\"",
    "\"___ well\"",
    "Cure-___ (panaceas)",
    "\"___ fair in love and war\"",
    "Heal-___ (mint family members)",
    "\"___ well\"",
    "\"___ Well That Ends Well\"",
    "\"___ fair in love ...\"",
    "Word before fair or well",
    "\"___ fair in love and war\"",
    "Whittier poem \"___ Well\"",
    "Shakespeare title starter",
    "\"___ well that ends well\"",
    "\"___ well ...\"",
    "Whittier poem \"___ Well\"",
    "\"When ___ said and done...\"",
    "Shakespeare title starter",
    "\"___ Fair\" (1989 comedy)",
    "Shakespeare title starter",
    "Start of a Shakespearean title",
    "Start of a Shakespearean title",
    "\"___ Well That Ends Well\"",
    "\"___well!\"",
    "\"___right with the world!\": Browning",
    "Shakespeare title start",
    "Shakespeare title starter",
    "\"___ Well That Ends Well\"",
    "Start of a Shakespeare title",
    "\"___ right with the world\"",
    "Shakespearean title start",
    "\"___ well...\"",
    "\"___ Well That Ends Well\"",
    "\"___ right with the world\"",
    "\"___ fair... \""
  ],
  "duos": [
    "Some acts",
    "Pairs",
    "Pairs",
    "Two and two?",
    "The Blues Brothers and others",
    "Twosomes",
    "The Black Keys and the White Stripes",
    "The Hardy Boys and others",
    "Pairs",
    "Twosomes",
    "Pairs",
    "Some acts",
    "Some Grammy winners",
    "Some folk acts",
    "The Righteous Brothers and the Everly Brothers",
    "What 17-, 25-, 37-, 52- and 62-Across are, themewise",
    "Pairings",
    "Rap music's Kris Kross and others",
    "Performing twosomes",
    "Chad & Jeremy and others",
    "Many comedy teams",
    "Comedy teams, usually",
    "News \"items\"",
    "Couples",
    "Ren and Stimpy et al.",
    "Some combos"
  ],
  "behr": [
    "Big name in house paint",
    "Paint company whose name sounds like an animal"
  ],
  "sada": [
    "Actress Thompson of \"Family\"",
    "Actress Thompson of \"Family\"",
    "Actress Thompson of TV's \"Family\"",
    "Actress Thompson",
    "Thompson of \"Family\"",
    "Thompson of TV's \"Family\"",
    "Thompson of \"Family\"",
    "Emmy-winning Thompson",
    "Actress Thompson",
    "Thompson of \"Family\"",
    "Tony-winning Thompson",
    "Actress Thompson of TV's \"Family\"",
    "Actress Thompson",
    "Thompson of \"Family\"",
    "Thompson of \"Pollock\"",
    "Emmy-winning Thompson",
    "Actress Thompson",
    "Emmy-winning Thompson",
    "Actress Thompson",
    "Actress Thompson",
    "Actress Thompson of \"Family\"",
    "Actress Thompson of \"Family\"",
    "Actress Thompson",
    "Actress Thompson",
    "Actress Thompson",
    "Actress Thompson"
  ],
  "viva": [
    "\"Long live ...!\"",
    "\"___ la Vida,\" #1 Coldplay album",
    "Presley's \"___ Las Vegas\"",
    "Cheer starter",
    "South-of-the-border cheer starter",
    "Elvis's \"___ Las Vegas\"",
    "Start of a Spanish cheer",
    "\"___ Las Vegas\" (1964 Elvis movie)",
    "Brawny competitor",
    "Bounty competitor",
    "Cheer starter",
    "Hurray, in Hidalgo",
    "Cheer starter",
    "Bounty rival",
    "Start of a cheer",
    "Start of a cheer",
    "Cheer for Zapata",
    "\"___Zapata!\"",
    "Brando's \"___ Zapata!\"",
    "Bounty rival"
  ],
  "pupu": [
    "Hawaiian for \"appetizer\"",
    "___ platter",
    "___ platter",
    "___ platter (order at a Polynesian restaurant)",
    "Kind of platter",
    "Kind of platter",
    "___ platter",
    "Kind of platter",
    "Kind of platter"
  ],
  "linc": [
    "Nickname for the Philadelphia Eagles stadium, with \"the\"",
    "\"The Mod Squad\" role",
    "Tod's sidekick on \"Route 66\"",
    "Pete and Julie's \"Mod Squad\" partner",
    "Nickname for the Philadelphia Eagles' stadium, with \"the\"",
    "\"The Mod Squad\" role",
    "Nickname for someone who shares a name with the 16th president",
    "\"The Mod Squad\" role",
    "___ Hayes of \"The Mod Squad\"",
    "\"The Mod Squad\" role",
    "\"The Mod Squad\" role",
    "\"Mod Squad\" member"
  ],
  "vici": [
    "End of Caesar's boast",
    "End of a famous boast",
    "End of a famous boast",
    "Part of Caesar's boast",
    "Part of a boast",
    "End of a boast",
    "End of a boast",
    "End of a Caesarean boast",
    "End of a famous claim",
    "Part of a Latin trio",
    "Last of a Latin trio",
    "End of a famous boast",
    "\"Veni, vidi, ___\"",
    "\"Veni, vidi, ___\"",
    "One of a Caesarean trio",
    "Caesarean section?"
  ],
  "medo": [
    "\"Love ___\" (Beatles hit)",
    "\"Love ___\"",
    "\"Love ___\" (Beatles song)",
    "\"Love ___\"",
    "\"Love ___\"",
    "\"Love ___\"",
    "\"Love ___\" (1964 hit)",
    "\"Love ___\"",
    "\"Love ___\" (Beatles song)"
  ],
  "xkes": [
    "Classic Jaguars",
    "Vintage Jaguars",
    "Sporty Jaguars",
    "Classic Jaguars",
    "Vintage Jags",
    "Classic Jags",
    "Jags of the 1960s and '70s",
    "Classic Jaguars",
    "Classic sports cars",
    "Classic Jags",
    "Classic Jags",
    "Classic Jags"
  ],
  "joes": [
    "Trader ___",
    "Trader ___",
    "Trader ___",
    "\"Eat at ___\" (classic sign)",
    "Frank Zappa rock opera \"___ Garage\"",
    "Average fellows",
    "\"Eat at ___\"",
    "Generic comic strip diner",
    "Stereotypical sandwich board diner",
    "Average guys",
    "Average guys",
    "Average guys",
    "Smokey ___ (Broadway hit locale)",
    "\"Eat at ___\""
  ],
  "aveo": [
    "Old Chevy model renamed the Sonic",
    "Bygone Chevy model",
    "Onetime Chevy subcompact",
    "Chevy that debuted in 2004",
    "Chevy that's now called the Sonic",
    "Bygone Chevrolet",
    "Bygone Chevy subcompact",
    "Former Chevy subcompact",
    "Car name that's Latin for \"desire\"",
    "2004 Chevy debut",
    "Bygone Chevrolet",
    "Former Chevy subcompact",
    "Former Chevy subcompact",
    "Former Chevy subcompact",
    "Chevy model"
  ],
  "yays": [
    "Cries of approval",
    "Hurrahs",
    "Celebratory cheers",
    "Cries of approval",
    "Supportive cries",
    "Reactions to good news",
    "Some cheers",
    "Exuberant cries",
    "Cheers for successes",
    "Triumphant cries",
    "Cheerful chorus"
  ],
  "neos": [
    "Modernists, for short",
    "Modernists, informally",
    "Modernists, briefly",
    "Revivalists, for short",
    "Modernists, informally",
    "Modernists, informally",
    "Modernists, informally",
    "Modernists",
    "Modernists",
    "Traditionalists' opposite, briefly",
    "Hardly traditionalists",
    "Revivalists, informally",
    "Converts to a cause, briefly",
    "Modernists",
    "Hardly classicists",
    "Revivalists",
    "Revivalists",
    "Modernists"
  ],
  "vole": [
    "Field mouse",
    "Mouse lookalike",
    "Grasslands burrower",
    "Burrowing animal",
    "Crop-damaging rodent",
    "Cousin of a lemming",
    "Mouselike animal",
    "Burrowing rodent",
    "Mouselike animal",
    "Mouse lookalike",
    "Relative of a lemming",
    "Tiny-eared rodent",
    "Meadow rodent",
    "Mouselike animal",
    "Short-tailed rodent",
    "Mouselike animal",
    "Mouselike animal",
    "Ratlike rodent",
    "Mouse-like rodent",
    "Relative of the lemming",
    "Field mouse",
    "Bobtail mouse"
  ],
  "bout": [
    "Series of rounds",
    "Ring event",
    "Something with round parts?",
    "Ring contest",
    "Series of rounds",
    "Spell",
    "Series of rounds",
    "Spell",
    "Match",
    "Boxing match",
    "Event on a card",
    "Spell",
    "Item on a card",
    "Attack",
    "Match",
    "Spell",
    "Spell",
    "Ring engagement",
    "Marciano match",
    "Title ___",
    "Ring happening",
    "Attack of the flu"
  ],
  "jung": [
    "Psychologist who coined the word \"synchronicity\"",
    "Contemporary of Freud",
    "Founder of analytic psychology",
    "Anima doctor",
    "Father of Analytical Psychology",
    "Analytic psychology founder"
  ],
  "schs": [
    "Univs., e.g.",
    "75- and 80-Down, e.g.: Abbr.",
    "The 12 of the Pac-12: Abbr.",
    "Athletic conference members: Abbr."
  ],
  "oyez": [
    "Court order?",
    "Call in court",
    "Hearing command",
    "Court call",
    "Cry for attention",
    "Attention-getter, in some rooms",
    "Courtroom cry",
    "Bringer of order in the court",
    "Courtroom attention-getter",
    "Court cry",
    "When repeated, a court cry",
    "Cry in court",
    "Court cry",
    "Court call",
    "Court cry",
    "Court attention-getter",
    "Bailiff's cry",
    "Court cry",
    "Court call"
  ],
  "aust": [
    "Neighbor of N.Z.",
    "N.Z. neighbor",
    "Victoria's home: Abbr.",
    "N.Z. neighbor",
    "Neighbor of Hung.",
    "Down Under land: Abbr.",
    "E.U. mem. since 1995",
    "Neighbor of Switz.",
    "Where to find the Wienerwald: Abbr.",
    "Neighbor of Hung.",
    "See 84-Down: Abbr.",
    "Neighbor of Ger. and Hung.",
    "Czech Rep. neighbor",
    "Locale of Vict. and N.S.W.",
    "Hung. neighbor"
  ],
  "bmoc": [
    "Dorm V.I.P.",
    "Interfraternity council pres., e.g.",
    "Fellow who might be senior class president, for short",
    "Standout in a quad",
    "Univ. V.I.P.",
    "College QB, often",
    "Coll. football star, e.g.",
    "Varsity QB, e.g.",
    "Univ. QB, perhaps",
    "Possible college class pres.",
    "Univ. class president, maybe",
    "College QB, maybe",
    "College V.I.P.",
    "Popular coll. guy",
    "U.C.L.A. quarterback, perhaps",
    "College QB or student body pres., maybe",
    "Univ. hotshot",
    "Student body pres., e.g.",
    "Interfraternity pres., e.g.",
    "Coll. V.I.P.",
    "Coll. hotshot"
  ],
  "acap": [
    "Put ___ on (limit)",
    "Put ___ on (limit)",
    "Put ___ on (limit)",
    "Put ___ on (limit)",
    "Put ___ on (limit)",
    "Set___on (limit)",
    "Put ___ on (limit)"
  ],
  "nebr": [
    "Big Ten school: Abbr.",
    "Neighbor of Colo.",
    "State for which a Springsteen album is named: Abbr.",
    "Wyo. neighbor",
    "Lincoln's state: Abbr.",
    "Hastings, ___, where Kool-Aid was invented: Abbr.",
    "Lincoln's home: Abbr.",
    "Its motto is \"Equality before the law\": Abbr.",
    "Neighbor of Colo.",
    "North Platte locale: Abbr.",
    "Wyo. neighbor",
    "I-80 runs through it",
    "1867 admission to the Union: Abbr.",
    "Colo. neighbor"
  ],
  "noaa": ["Operator of weather.gov"],
  "chow": [
    "Vittles",
    "Grub",
    "Grub",
    "Eats",
    "Grub",
    "*Grub",
    "*Food, slangily",
    "Grub",
    "Food, colloquially",
    "Eats",
    "Eats",
    "Galley output",
    "Vittles",
    "Grub",
    "Grub",
    "Grub",
    "K rations, e.g.",
    "Dog with a blue-black tongue",
    "Grub",
    "Eats",
    "Grub",
    "Victuals",
    "Eat, with \"down\"",
    "Vittles"
  ],
  "mgmt": [
    "Powers that be: Abbr.",
    "Corp. leadership",
    "Corp. leadership",
    "Bosses, collectively: Abbr.",
    "C.E.O.'s job: Abbr.",
    "Band with the 2008 song \"Electric Feel\"",
    "Corp. leadership",
    "Those in charge: Abbr.",
    "Ones minding the store: Abbr.",
    "Bus. runners",
    "Bus. heads"
  ],
  "lunk": [
    "Blockhead",
    "Galoot",
    "Lamebrain",
    "Blockhead",
    "Blockhead",
    "Dullard"
  ],
  "perf": [
    "Ideal, in teenspeak",
    "Philatelist's abbr.",
    "Philatelist's concern, briefly",
    "Stamp feature, in philately lingo",
    "B'way showing",
    "B'way showing",
    "Stamp feature, to a philatelist",
    "B'way showing",
    "Like most postage stamps: Abbr.",
    "Broadway showing, for short"
  ],
  "ivie": [
    "Good name for a landscaper?",
    "Ellington band vocalist Anderson",
    "Jazz singer Anderson",
    "Jazz singer Anderson",
    "Jazz singer Anderson",
    "Jazz singer Anderson"
  ],
  "paca": [
    "Agouti relative",
    "Amazon rodent",
    "Prey for a jaguar",
    "Spotted rodent of South America",
    "Amazon rodent",
    "Rain forest rodent",
    "Spotted South American mammal",
    "Spotted rodent",
    "Spotted cavy",
    "Cousin of a guinea pig",
    "Cousin of a guinea pig",
    "Tropical rodent",
    "Cousin of an agouti",
    "Animal that resembles a guinea pig",
    "Amazon Basin creature",
    "Cousin of an agouti",
    "Spotted cavy",
    "White-spotted rodent",
    "South American rodent",
    "White-spotted rodent",
    "Edible rodent"
  ],
  "blog": [
    "WordPress creation",
    "Place for a post",
    "Modern journal",
    "WordPress or Tumblr page",
    "E-journal",
    "Post holder",
    "Modern vent outlet?",
    "Modern writing",
    "Modern journal"
  ],
  "holm": [
    "Ian of \"Alien\"",
    "Actress Celeste",
    "Baggins portrayer in \"The Lord of the Rings\"",
    "Celeste who won an Oscar for \"Gentleman's Agreement\"",
    "___ oak",
    "Evergreen oak",
    "1947 Oscar winner Celeste"
  ],
  "rete": [
    "Bundle of nerves",
    "Bundle of nerves",
    "Neural network",
    "Neural network",
    "Bundle of nerves",
    "Neural network",
    "Bundle of nerves",
    "Nerve network",
    "Neural network",
    "Astrolabe plate",
    "Anatomical network",
    "Nerve network",
    "Interconnection of nerves",
    "Nerve network"
  ],
  "taur": [
    "Bull: Prefix",
    "Bull: Prefix",
    "Bull: Prefix",
    "Bull: Prefix",
    "Bull: Prefix",
    "Bullish opening",
    "Bull's head?",
    "Bull: Prefix"
  ],
  "milo": [
    "Actor O'Shea",
    "Lieutenant Minderbinder of \"Catch-22\"",
    "___ Minderbinder, lieutenant in \"Catch-22\"",
    "Otis's feline pal",
    "Minderbinder of \"Catch-22\"",
    "Hero of kid-lit's \"The Phantom Tollbooth\"",
    "Venus de ___",
    "Venus de ___",
    "\"Catch-22\" profiteer Minderbinder",
    "Venus de ___",
    "Whence Venus?",
    "Minderbinder of \"Catch-22\"",
    "Actor Ventimiglia of \"Heroes\"",
    "Venus de ___",
    "Venus de ___",
    "Actor O'Shea",
    "Actor O'Shea",
    "Minderbinder of \"Catch-22\"",
    "Island where a noted Greek statue was found",
    "Actor O'Shea",
    "___ Minderbender, villain in \"Catch-22\"",
    "Venus de ___",
    "Title cat in a 1980's cartoon film",
    "Venus de ___",
    "Venus de ___",
    "Actor O'Shea",
    "Venus de ___",
    "Actor O'Shea",
    "Mr. Minderbinder of \"Catch-22\"",
    "Venus's home",
    "Venus de___",
    "Actor O'Shea",
    "Venus de ___",
    "Venus de ___",
    "\"Venus de ___\"",
    "Venus's home",
    "Venus's home"
  ],
  "trax": [
    "\"Time ___\" (bygone sci-fi series)",
    "\"Time ___\" (bygone sci-fi series)",
    "\"Time ___\" (1990s sci-fi series)",
    "\"Time ___\" (bygone sci-fi series)",
    "\"Time ___,\" 1990's sci-fi TV series"
  ],
  "bier": [
    "Oktoberfest quaff",
    "Casket stand",
    "Coffin stand",
    "Oktoberfest quaff",
    "Funeral stand",
    "German quaff",
    "Coffin holder",
    "Drink served with Brezeln",
    "Stand at a ceremony",
    "Funeral stand",
    "German quaff",
    "Resting place",
    "Place for a body to repose",
    "Coffin support",
    "Stand for the deceased",
    "Stand at a wake",
    "Oktoberfest supply",
    "Stand at wakes",
    "Funeral stand",
    "Oktoberfest draft",
    "Catafalque",
    "Coffin stand",
    "Funeral stand",
    "Oktoberfest order",
    "Oktoberfest serving",
    "Catafalque",
    "Funeral stand",
    "Rotunda resting place",
    "Stand"
  ],
  "isin": [
    "Says yes to the gang, say",
    "\"... as it ___ heaven\"",
    "See blurb",
    "\"The doctor ___\"",
    "\"The check ___ the mail\"",
    "\"The devil ___ the details\"",
    "\"Love ___ the air\"",
    "\"The fix ___\"",
    "\"The devil ___ the details\"",
    "\"The fix ___\"",
    "\"Beauty ___ the eye ...\"",
    "\"Beauty ___ the eye ...\"",
    "Hasn't left",
    "\"... as it ___ heaven\"",
    "\"The devil ___ the details\"",
    "\"The fix ___\"",
    "\"Love ___ the air\"",
    "\"The fix ___\"",
    "\"The doctor ___\"",
    "\"The fix ___\"",
    "\"Beauty ___ the eye ?à\"",
    "Hasn't left",
    "\"Beauty ___ the eye ?à\"",
    "\"The proof ___ the pudding\"",
    "\"The fix ___\"",
    "\"The doctor ___\"",
    "\"The doctor ___\"",
    "\"The doctor ___\"",
    "\"The doctor ___\"",
    "\"The doctor___\"",
    "\"Beauty ___ the eye...\"",
    "\"The doctor ___\"",
    "\"The proof of the pudding ___...\"",
    "\"The doctor ___\"",
    "\"The doctor ___\""
  ],
  "weal": [
    "Prosperity",
    "Prosperity",
    "Prosperity",
    "Fortune",
    "Prosperity",
    "Prosperousness",
    "Prosperity",
    "Prosperity",
    "Prosperity",
    "Prosperity",
    "Prosperity",
    "Prosperity",
    "Prosperity"
  ],
  "boyd": [
    "\"The Lord of the Rings\" actor Billy",
    "\"The Lord of the Rings\" actor Billy",
    "Stephen of \"Ben-Hur\"",
    "William who played Hopalong Cassidy in old westerns",
    "William who played Hopalong Cassidy",
    "William ___, Hopalong Cassidy player",
    "Cassidy portrayer of TV and film",
    "Hopalong Cassidy player William",
    "Hopalong Cassidy actor",
    "Hopalong Cassidy portrayer Bill",
    "Peace Nobelist John ___ Orr",
    "Singer Bill known as the Cowboy Rambler",
    "Hopalong Cassidy portrayer",
    "\"Hopalong Cassidy\" actor",
    "\"Cheers\" bartender Woody ___",
    "\"Hopalong Cassidy\" actor",
    "Hopalong Cassidy portrayer William",
    "Hopalong Cassidy's portrayer",
    "Hopalong Cassidy portrayer William",
    "Hopalong Cassidy portrayer",
    "Hopalong Cassidy portrayer",
    "Cassidy portrayer William"
  ],
  "mudd": [
    "Emmy-winning newsman Roger",
    "California's Harvey ___ College",
    "Harvey ___ College",
    "California's Harvey ___ College",
    "Newsman Roger",
    "Newsman Roger",
    "Longtime CBS and NBC newsman Roger",
    "Newsman Roger",
    "Noted PBS newsman"
  ],
  "guff": [
    "Insolent talk",
    "Lip",
    "Back talk",
    "Humbug",
    "Bunk",
    "Backtalk",
    "Bunk",
    "Insolence",
    "Malarkey",
    "Back talk",
    "Back talk",
    "Insolent talk"
  ],
  "opto": [
    "Prefix with -metry",
    "Vision: Prefix",
    "Vision: Prefix",
    "Vision: Prefix",
    "Prefix with -metry",
    "Vision: Prefix",
    "Vision: Prefix",
    "Vision: Prefix",
    "Vision: Prefix"
  ],
  "ayla": [
    "Jean Auel heroine",
    "Heroine of Jean Auel's \"The Clan of the Cave Bear\"",
    "\"The Clan of the Cave Bear\" heroine",
    "Cro-Magnon orphan of literature",
    "Auel heroine",
    "\"The Clan of the Cave Bear\" heroine",
    "\"The Clan of the Cave Bear\" heroine",
    "\"The Shelters of Stone\" heroine",
    "\"The Shelters of Stone\" heroine",
    "\"The Mammoth Hunters\" heroine",
    "Auel heroine",
    "Auel heroine",
    "\"Clan of the Cave Bear\" heroine"
  ],
  "xbox": [
    "Popular game console",
    "Gaming debut of 2001",
    "___ 360 (game console)",
    "___ One (2013 release)",
    "Wii alternative",
    "Microsoft debut of 2001",
    "PlayStation 2 competitor",
    "PlayStation alternative",
    "Wii alternative",
    "Console used with the game Halo",
    "___ 360",
    "___ 360",
    "GameCube competitor",
    "Microsoft product launched in 2001",
    "PlayStation alternative",
    "Microsoft game system"
  ],
  "awar": [
    "Wage ___ of words",
    "\"To End ___\" (1998 Richard Holbrooke best seller)",
    "\"Don't you know there's ___ on?\"",
    "Start ___ (be extremely aggressive)",
    "\"I'll raise the preparation of ___\": Mark Antony",
    "\"Suppose they gave ___ and nobody came?\"",
    "\"Suppose they gave ___...\""
  ],
  "otho": [
    "Roman emperor of A.D. 69",
    "Roman emperor who overthrew Galba",
    "Emperor after Galba",
    "Roman emperor who overthrew Galba",
    "Roman emperor",
    "Emperor of A.D. 69",
    "Roman emperor of A.D. 69",
    "Early Roman emperor",
    "Roman emperor after Galba",
    "Roman emperor with a three-month reign",
    "Emperor for only three months",
    "Emperor before Vitellius",
    "First-century Roman emperor",
    "Roman emperor for just three months",
    "Roman emperor after Galba",
    "Vitellius succeeded him",
    "First Holy Roman emperor",
    "Emperor of A.D. 69",
    "Roman emperor in A.D. 69",
    "Roman emperor after Galba",
    "Emperor after Galba",
    "Galba's successor",
    "First-century Roman emperor",
    "First-century Roman emperor",
    "Roman emperor of A.D. 69",
    "Roman emperor",
    "Emperor of A.D. 69"
  ],
  "aare": [
    "European river that originates from a glacier",
    "Swiss river to the Rhine",
    "Longest river entirely within Switzerland",
    "European river whose tributaries include the Wigger and the Emme",
    "High Rhine feeder",
    "River that flows from the Bernese Alps",
    "Longest river wholly in Switzerland",
    "It's canalized at Interlaken",
    "Swiss bank depositor?",
    "River to the Rhine",
    "Swiss river",
    "Rhine tributary",
    "Swiss river",
    "Bern's river",
    "Tributary of the High Rhine",
    "Swiss banks may be affiliated with it",
    "Drainer of most of Switzerland",
    "It has banks in Switzerland",
    "Rhine feeder",
    "Longest river entirely in Switzerland",
    "Rhein tributary",
    "River with the Reichenbach Falls",
    "Interlaken's river",
    "River through Interlaken",
    "Lake Thun's river",
    "It has banks in Bern",
    "River to the Rhine",
    "Swiss river",
    "Bern's river",
    "Rhine tributary",
    "It rises in the Bernese Alps",
    "River of Switzerland",
    "River to the Rhine",
    "Rhine feeder",
    "Sight from Bern",
    "Alpine stream",
    "Bern's river",
    "Thun's river",
    "Swiss bank site",
    "Bern's river",
    "River of Bern",
    "Sight from Bern",
    "River known anciently as Obringa",
    "Europe's Gorge of the ___",
    "Webster's first river",
    "It has Swiss banks",
    "It has banks in Switzerland",
    "It flows past Olten",
    "It established banks in Switzerland",
    "Swiss river",
    "River to the Rhine",
    "Alpine river",
    "Bern is on it",
    "It flows through the Lake of Thun",
    "River known in ancient times as Obringa",
    "Bern's river",
    "Rhine feeder",
    "River from the Bernese Alps",
    "Swiss stream",
    "It rises in the Bernese Alps",
    "Bern's river",
    "Interlaken's river",
    "Rhine feeder",
    "River through Bern",
    "Swiss river",
    "It has banks in Bern",
    "River known to the ancients as Obringa",
    "Swiss river",
    "Interlaken's river",
    "Switzerland's Gorge of the ___",
    "Bern's river",
    "Swiss river",
    "River past Interlaken",
    "Rhine tributary",
    "Swiss river",
    "Alpine stream",
    "Rhine feeder",
    "Swiss river",
    "Swiss river",
    "Swiss river",
    "Bern's river",
    "River past Bern",
    "River to the Rhine"
  ],
  "fisk": [
    "11-time All-Star Carlton",
    "1972 A.L. Rookie of the Year",
    "Hall-of-Fame catcher Carlton"
  ],
  "yuan": [
    "Chinese money",
    "Chinese money",
    "Chinese money",
    "Banknote featuring Mao",
    "Chinese money",
    "Chinese dollar",
    "10 jiao",
    "Chinese dollar",
    "Money in China",
    "10 jiao",
    "Chinese money",
    "Chinese dollar",
    "Beijing coin",
    "Chinese dollar",
    "China's dollar"
  ],
  "cest": [
    "\"___ magnifique!\"",
    "\"___ la vie\"",
    "\"___ bon\"",
    "Cole Porter's \"___ Magnifique\"",
    "\"___ la vie\"",
    "\"___ Si Bon\" (1950s Eartha Kitt hit)",
    "\"___ fini!\"",
    "\"___ la guerre\"",
    "\"___ la vie\"",
    "\"___ la vie\"",
    "\"___ la vie\"",
    "\"___ Vous\" (1927 song hit)",
    "\"___ bon!\"",
    "\"___ Si Bon\" (1950's song)",
    "\"___ la vie\"",
    "Cole Porter's \"Aa ___ L'Amour\"",
    "\"___ la guerre!\"",
    "\"___ la guerre\"",
    "\"___ la guerre\"",
    "\"___ a rire\" (\"It is to laugh\"): Fr.",
    "\"___ Si Bon\" (old song hit)"
  ],
  "chip": [
    "Roulette playing piece",
    "Approach shot in golf",
    "Pot component",
    "Golf shot near a green",
    "Ante matter?",
    "Computer ___",
    "Part of a casino stack",
    "Word with potato or chocolate",
    "One may follow a long drive",
    "Pot item",
    "China problem",
    "Part of a poker player's pile",
    "Casino souvenir",
    "Chocolate-___",
    "Approach shot",
    "Word that can follow the ends of 17-, 27-, 43- and 57-Across",
    "Shot near the green",
    "Reason to get some cosmetic dental work",
    "Antique damage",
    "Intel product",
    "Flake",
    "Computer component",
    "Shot out of a sand trap",
    "Pentium product",
    "Golf shot",
    "China problem",
    "Circuitry site",
    "It's dipped in a dip",
    "China problem",
    "Problem for a dentist",
    "Golf shot",
    "Guacamole support",
    "Computer marvel",
    "Minimum wager",
    "Golf stroke"
  ],
  "dink": [
    "Light tennis shot",
    "Surprise volleyball shot",
    "Soft touch?",
    "It's hardly a smash hit",
    "Softly hit ball in tennis",
    "Soft shot in tennis",
    "Drop shot",
    "Surprise tennis shot"
  ],
  "amon": [
    "Egyptian deity",
    "Patron god of ancient Thebes",
    "King of the gods, in Egyptian myth",
    "Chief Theban deity",
    "Fort Worth's ___ Carter Museum",
    "Pharaoh's deity",
    "Ft. Worth's ___ Carter Museum",
    "Ralph's \"Schindler's List\" role",
    "Chief Theban deity",
    "___Goeth of \"Schindler's List\"",
    "Egypt's ___-Ra",
    "Egyptian deity"
  ],
  "bnai": [
    "___ B'rith",
    "___ B'rith",
    "Start of many congregation names",
    "Sons of, in Hebrew",
    "___ B'rith",
    "Beginning of some temple names",
    "___ B'rith",
    "___ B'rith",
    "Beginning of some temple names",
    "___ B'rith",
    "Start of some congregation names",
    "Sons of, in Hebrew",
    "Sons of, in Hebrew",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "Sons of, in Hebrew",
    "___ B'rith",
    "___ B'rith",
    "Start of some Jewish congregation names",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "Sons of, in Hebrew",
    "___ B'rith",
    "___ B'rith",
    "___ B'rith",
    "Sons of, in Hebrew",
    "___ B'rith",
    "Sons of, to a sabra",
    "___ B'rith",
    "Start of the Sons of the Covenant's name",
    "___ B'rith",
    "___ B'rith"
  ],
  "lack": [
    "Absence",
    "Deficiency",
    "Be short of",
    "Be deficient in",
    "Mane, for a female lion, e.g.",
    "Absence",
    "Need",
    "Shyness?",
    "Are short",
    "Dearth",
    "Dearth"
  ],
  "opes": [
    "Unfolds, in poetry",
    "Unlocks, in poesy",
    "Exposes, old-style",
    "Unlocks, in verse",
    "Reveals, in verse",
    "Unlocks, in verse",
    "Makes accessible, old-style",
    "Unlocks, poetically",
    "Reveals, in verse",
    "\"And Morning ___ with haste her lids\": Emerson",
    "Unlocks, poetically",
    "Unlocks, in poetry",
    "Reveals, a la Shakespeare",
    "Reveals, poetically",
    "Unfolds, in poetry",
    "Unlocks, to Milton",
    "Unlocks, in a sonnet"
  ],
  "cohn": [
    "McCarthy aide Roy",
    "Singer Marc with the 1991 hit \"Walking in Memphis\"",
    "Saxophonist Al",
    "Lawyer Roy of the McCarthy hearings",
    "McCarthy-era attorney Roy",
    "Harry who co-founded Columbia Pictures",
    "Red Scare prosecutor Roy",
    "McCarthy associate Roy",
    "Marc who sang \"Walking in Memphis\"",
    "Columbia Pictures co-founder",
    "Big band saxophonist Al",
    "Role for 45-Down in \"Angels in America\"",
    "Saxophonist Al",
    "McCarthy-era attorney Roy",
    "Harry ___, Columbia Pictures co-founder",
    "Harry who co-founded Columbia Pictures",
    "Roy portrayed in \"Angels in America\"",
    "\"Angels in America\" figure",
    "Columbia Pictures co-founder",
    "Columbia Pictures founder",
    "Jazzman Al",
    "Attorney Roy",
    "Columbia Pictures co-founder Harry",
    "\"It Happened One Night\" producer",
    "Lawyer Roy",
    "Columbia Pictures co-founder Harry",
    "Big Band saxophonist Al",
    "Lawyer Roy",
    "Roy of Senate hearings"
  ],
  "drei": [
    "Eins + zwei",
    "Three, in Berlin",
    "Eins + zwei",
    "1 + 2, in Germany",
    "Trio abroad",
    "Eins, zwei, ___",
    "Quarter of zwolf",
    "Half of sechs",
    "Eins und zwei",
    "Eins + zwei",
    "Hofbrauhaus crowd?",
    "German three",
    "Eins und zwei",
    "Crowd in Cologne?",
    "Cologne trio",
    "Crowd in Berlin?",
    "Beethoven's third?",
    "Zwei follower",
    "Eins + zwei",
    "German crowd?",
    "Trier trio",
    "Koln crowd?",
    "Eins + zwei",
    "Number in a \"crowd,\" in Koln",
    "Teutonic trio",
    "Afternoon hour in Bonn",
    "2 + 1, in Germany",
    "Brandenburg trio",
    "Half of sechs",
    "Number in Nuremberg",
    "Vier preceder",
    "Eins + zwei",
    "Vier preceder",
    "Eins + zwei",
    "German number",
    "Vier preceder",
    "Trio abroad",
    "Three of a Kind?",
    "Cologne crowd?",
    "Eins + zwei",
    "Zwei follower",
    "Three, in Ulm",
    "Eins + zwei",
    "Three, in Thuringen",
    "Half of sechs",
    "Vier preceded",
    "Vier preceded",
    "Vier minus eins",
    "German numeral",
    "Half of sechs"
  ],
  "nlrb": [
    "Workers' rights agcy.",
    "Workers' rights org.",
    "Strike-monitoring org.",
    "Workplace rights org.",
    "Workers' rights org.",
    "Workers' rights agcy.",
    "Collective bargaining watchdog org.",
    "Workers' rights grp.",
    "Strike-monitoring agcy.",
    "Strike-monitoring agcy."
  ],
  "dews": [
    "Mountain ___ (some sodas)",
    "Certain soft drinks, informally",
    "Mountain ___ (soft drinks)",
    "Morning moistures",
    "Mountain ___ (Pepsi products)",
    "Moistens, in a way"
  ],
  "wavy": [
    "Apt radio call letters for a beach town",
    "Like odor-indicating lines, in comics",
    "Like moire patterns",
    "Crimped, as hair",
    "Rolling",
    "Not straight",
    "Like moire patterns",
    "Not straight",
    "Undulating",
    "Like some hair",
    "Not straight",
    "Like marcelled hair"
  ],
  "trop": [
    "De ___ (excessive)",
    "Too much, to Marcel",
    "Atlantic City resort, informally, with \"the\"",
    "St. Pete stadium, with \"the\"",
    "St. Pete ball field, with \"the\"",
    "Too: Fr.",
    "Classic Vegas hotel, with \"the\"",
    "Too: Fr.",
    "Too much: Fr.",
    "De___ (too much)",
    "De ___ (too much)",
    "De ___ (too much)"
  ],
  "sora": [
    "Marsh bird",
    "Marsh bird",
    "Short-billed rail",
    "Short-billed rail",
    "Short-billed rail",
    "Carolina rail",
    "Marsh bird",
    "Marsh bird",
    "Small rail bird"
  ],
  "haig": [
    "Secretary of state after Muskie",
    "\"Caveat: Realism, Reagan and Foreign Policy\" memoirist",
    "Secretary of state under Reagan",
    "Alexander who served three presidents",
    "General who became a secretary of state",
    "\"Caveat: Realism, Reagan and Foreign Policy\" writer",
    "Watergate-era White House chief of staff",
    "Secretary of state after Muskie",
    "Reagan's first secretary of state",
    "Alexander who said \"I'm in control here\"",
    "Secretary of state before Shultz",
    "Reagan's first secretary of state",
    "Alexander ___, secretary of state under Reagan",
    "Shultz's predecessor as secretary of state",
    "Reagan cabinet member",
    "Muskie's successor in the Cabinet",
    "Secretary of State with \"Jr.\" in his name",
    "Reagan Cabinet member",
    "Department of State chief under Reagan",
    "\"Inner Circles\" memoirist",
    "Reagan's first Secretary of State",
    "Reagan Cabinet member",
    "Chief of staff under Nixon",
    "Reagan Secretary of State",
    "Secretary of State under Reagan",
    "Reagan Secretary of State",
    "Reagan Secretary of State",
    "Reagan pal Al",
    "Nixon chief of staff",
    "Reagan Secretary of State",
    "Reagan Secretary of State"
  ],
  "choy": [
    "Bok ___",
    "La ___ (Chinese food brand)",
    "___ sum (Chinese flowering cabbage)",
    "Bok ___",
    "Bok ___ (Chinese vegetable)"
  ],
  "sros": [
    "Cramped dwellings, for short",
    "B'way hit signs",
    "Low-budget hotels, for short",
    "Some hotels, for short",
    "Sellout signs",
    "Sellout signs",
    "Cramped urban dwellings, briefly",
    "Shared-bath accommodations, briefly",
    "Cramped urban accommodations, for short",
    "Cheap digs: Abbr.",
    "B'way hit signs",
    "Some cheap hotels: Abbr.",
    "Some low-budget hotels, for short",
    "Sellout shows, for short",
    "Some B'way shows",
    "B'way hits"
  ],
  "afog": [
    "Lost in ___",
    "In ___ (dazed)",
    "In ___ (confused)",
    "In ___ (dazed)",
    "In ___ (clueless)"
  ],
  "waxy": [
    "Like candles",
    "Like candles",
    "Like candy corn's texture",
    "Candlelike, say",
    "Like some crayons",
    "Like Stilton cheese",
    "Readily shaped",
    "Like some floors",
    "Like some floors",
    "Like crayons",
    "Crayolalike",
    "Sleek, as a floor",
    "Having a pallor",
    "Like candles",
    "Like a 103-Down, maybe",
    "Like linoleum floors",
    "Ceraceous"
  ],
  "cull": [
    "Pick out",
    "Gather",
    "Choose the best of",
    "Get the best of",
    "Get the best of",
    "Get the best of",
    "Select",
    "Select",
    "Pick",
    "Select",
    "Get the best of",
    "Select",
    "Select"
  ],
  "gaby": [
    "Actress Hoffmann of \"Transparent\"",
    "One of the housewives on \"Desperate Housewives\""
  ],
  "afig": ["Not worth ___", "Not worth ___", "Not care ___ for"],
  "dade": [
    "Florida's Miami-___ County",
    "Florida's ___ Battlefield Historic State Park",
    "Miami's county, familiarly",
    "Miami-___ County",
    "___ City, Fla.",
    "___ City, suburb of Tampa/St. Petersburg",
    "Miami-___ County",
    "___ City, Fla.",
    "___ City, Fla.",
    "___ City, seat of Pasco County, Fla.",
    "Miami-___ County, Fla.",
    "___ City, Fla., NE of Tampa",
    "___ City, Fla.",
    "Florida's Miami-___ County",
    "Miami-___ County",
    "___ City (seat of Pasco County, Fla.)",
    "Miami-___ County",
    "See 3-Down",
    "Miami-___ County",
    "Seminole War officer for whom a county was named",
    "Florida's Miami-___ County",
    "Florida's Miami-___ County",
    "Florida's Miami-___ County",
    "Sunshine State county",
    "Florida county",
    "Miami's county",
    "County below Broward",
    "Miami's county",
    "Miami's county",
    "Janet Reno's home county"
  ],
  "zach": [
    "Galifianakis of \"The Hangover\"",
    "Director in \"A Chorus Line\"",
    "Galifianakis of \"The Hangover\"",
    "Galifianakis of \"The Hangover\"",
    "Comic actor Galifianakis",
    "Galifianakis of \"The Hangover\"",
    "Actor Galifianakis of \"The Hangover\"",
    "Galifianakis of \"The Hangover\"",
    "\"Scrubs\" actor Braff",
    "\"Scrubs\" actor Braff",
    "2007 Masters champion Johnson",
    "Director/actor Braff of \"Garden State\"",
    "\"Scrubs\" co-star ___ Braff",
    "Lead role in \"A Chorus Line\"",
    "\"A Chorus Line\" character"
  ],
  "hedy": [
    "Lamarr of old Hollywood",
    "Actress Lamarr",
    "Actress Lamarr",
    "Delilah portrayer Lamarr",
    "Screen star Lamarr",
    "Actress Lamarr",
    "Lamarr of film",
    "Lamarr of film",
    "Actress Lamarr",
    "Actress Lamarr",
    "Actress Lamarr",
    "Actress Lamarr",
    "Actress Lamarr",
    "Lovely Lamarr",
    "Actress Lamarr",
    "Delilah in \"Samson and Delilah\"",
    "Miss Lamarr",
    "Actress Lamarr"
  ],
  "azov": [
    "Sea of ___ (arm of the Black Sea)",
    "Sea of ___, outlet of the Don River",
    "Eastern Europe's Sea of ___",
    "Sea of ___ (view from Crimea's eastern coast)",
    "Sea of ___ (arm of the Black Sea)",
    "Russia/Ukraine's Sea of ___",
    "Sea of ___ between Russia and Ukraine",
    "Sea of ___ (Black Sea arm)",
    "Russia's Sea of ___",
    "Sea of ___, arm of the Black Sea",
    "Russia/Ukraine?Æs Sea of ___",
    "Sea fed by the Don",
    "Sea of ___ (Don River's receiver)",
    "Sea of ___ (Black Sea arm)",
    "Russia's Sea of ___",
    "Asia's Sea of ___",
    "Ukraine's Sea of ___",
    "City sacked by Tamerlane, 1395",
    "Don River's outlet",
    "Don River's outlet",
    "Russian sea"
  ],
  "zola": [
    "Author Emile",
    "Dreyfus Affair figure",
    "Emile who wrote \"J'accuse\"",
    "Emile of the Dreyfus Affair",
    "\"Germinal\" novelist",
    "Dreyfus Affair chronicler",
    "\"Les Trois Villes\" novelist",
    "Novelist who was a childhood friend of Cezanne",
    "Dreyfus defender",
    "Author Emile",
    "Writer Emile",
    "Runner Budd",
    "Emile who wrote \"Truth is on the march\"",
    "\"The Dram Shop\" author",
    "\"Nana\" author Emile",
    "\"Therese Raquin\" novelist",
    "\"Nana\" author",
    "\"La Debacle\" novelist",
    "Defender of Manet's work",
    "Dreyfus Affair figure",
    "Writer Emile",
    "Dreyfus defender",
    "\"Nana\" author",
    "\"Germinal\" author",
    "\"J'accuse\" penner",
    "French novelist Emile",
    "\"Therese Raquin\" novelist",
    "Defender of Dreyfus",
    "\"Therese Raquin\" novelist",
    "\"Nana\" author",
    "\"J'accuse\" author",
    "\"Germinal\" author",
    "Dreyfus ally, 1898",
    "\"Nana\" writer",
    "Runner Budd",
    "\"The Dram-Shop\" author",
    "\"Germinal\" author",
    "Author Emile"
  ],
  "fria": [
    "Arizona's Agua ___ National Monument",
    "Arizona's Agua ___ River",
    "Like refreshing agua",
    "Cold, as snow in Spain",
    "Cold, to Conchita"
  ],
  "nogs": [
    "Some holiday concoctions",
    "Spiced holiday drinks",
    "Often-rummy holiday drinks",
    "Yuletide quaffs",
    "Seasonal servings",
    "Beverages in bowls",
    "Spiced quaffs",
    "Often-ladled drinks",
    "Holiday servings",
    "Holiday quaffs",
    "Holiday drinks",
    "Thick drinks",
    "Year-end libations",
    "Holiday drinks",
    "Yuletide drinks"
  ],
  "quad": [
    "Leg press target, informally",
    "Dazzling figure skating feat",
    "Learning center",
    "Leg muscle, informally",
    "Area of study?",
    "Target of a squat, for short",
    "Campus area",
    "Brown green?",
    "Thigh muscle, informally",
    "Campus area",
    "Campus area",
    "Risky thing to try in figure skating",
    "College locale",
    "Campus space",
    "College square",
    "Campus area",
    "Campus hangout",
    "Triplet plus one",
    "Campus locale",
    "One of a multiple birth",
    "Campus hangout",
    "Campus area",
    "Campus locale",
    "Campus area",
    "Thigh muscle, for short",
    "Leg muscle, briefly",
    "Rare multiple birth",
    "Stereo alternative",
    "College area",
    "Thigh muscle, informally",
    "View from some dorms",
    "Printer's spacer"
  ],
  "alpe": [
    "Suisse peak",
    "Peak in Suisse",
    "Mont Blanc, par exemple",
    "Mont Blanc, e.g., to locals",
    "Mont Blanc, e.g., to locals",
    "Mont Blanc, par exemple",
    "Mont Blanc, par exemple",
    "L'Eiger, e.g.",
    "Mont Blanc, par exemple",
    "Mont Blanc, par exemple",
    "Mont Blanc, par exemple",
    "Mont Blanc, e.g., to locals",
    "View from Chamonix",
    "Mont Blanc, e.g., locally",
    "French peak",
    "Peak near Neuchatel",
    "Mont Blanc, e.g., locally",
    "Mont Blanc, par exemple",
    "Peak in Haute-Savoie",
    "Mont Blanc, e.g."
  ],
  "maja": [
    "Goya subject",
    "\"The Naked ___\" (Goya painting)",
    "Goya subject",
    "Goya's \"The Naked ___\"",
    "Goya figure",
    "Goya subject",
    "Goya's \"La ___ Desnuda\"",
    "See 44-Across",
    "Goya's \"The Naked ___\"",
    "Goya subject",
    "Goya subject, nude and clothed",
    "Goya subject",
    "Goya's naked lady",
    "Goya subject",
    "Goya depiction",
    "Goya's \"Naked ___\""
  ],
  "lino": [
    "Lead-in to type",
    "Old typesetting machine, informally",
    "Lead-in to type",
    "Flat flooring",
    "Kitchen flooring, for short",
    "Prefix with type",
    "Old typesetting machine, briefly",
    "Start to type?",
    "Prefix with type",
    "Prefix with type",
    "Prefix with type",
    "Prefix with type"
  ],
  "wive": ["Take as a bride", "Take as a bride", "Marry a woman"],
  "venn": [
    "___ diagram",
    "Kind of diagram",
    "Kind of diagram",
    "___ diagram",
    "___ diagram",
    "___ diagram (logic illustration)",
    "___ diagram (logic tool)",
    "A diagram bears his name",
    "___ diagram",
    "Kind of diagram in logic",
    "___ diagram"
  ],
  "etym": [
    "Part of a dict. entry",
    "Dict. info",
    "Part of a dict. entry",
    "Dict. info",
    "It may follow a def.",
    "Dict. offering",
    "Dict. info",
    "Linguistics branch: Abbr.",
    "Word origin: Abbr."
  ],
  "anda": [
    "Forever ___ day",
    "All that ___ bag of chips",
    "Forever ___ day",
    "\"___ partridge in ...\"",
    "Shave ___ haircut",
    "Lawrence Welk's \"one\"/\"two\" connector",
    "One-two connector",
    "\"... ___ bottle of rum\"",
    "One-two connector",
    "Time ___ half",
    "Wing ___ prayer",
    "Wing ___ prayer",
    "Bandleader's start",
    "\"Three Men ___ Baby\"",
    "Time ___ half",
    "\"Shave ___ haircut\"",
    "Q ___",
    "One-two connector",
    "\"Shave ___ haircut\"",
    "Forever-day connector",
    "Shave ___ haircut",
    "\"A one ___ two...\"",
    "Time ___ half",
    "Lick ___ promise",
    "Lick ___ promise",
    "\"...one ___ two!\" (Welk intro)",
    "\"___ partridge in...\"",
    "\"Four Weddings ___ Funeral\"",
    "\"Shave ___ haircut...\"",
    "One-two link for Welk",
    "\"___ partridge ...\"",
    "\"___ partridge in a...\""
  ],
  "ithe": [
    "\"Am ___ only one?\"",
    "Spillane's \"___ Jury\"",
    "\"___ Jury\" (Spillane detective novel)",
    "\"Am ___ only one?\"",
    "\"Am ___ only one?\"",
    "\"___ Jury\"",
    "Spillane's \"___ Jury\"",
    "\"___ Jury\" (Spillane novel)",
    "Spillane's \"___ Jury\"",
    "\"Am ___ Man\" (1960 Jackie Wilson hit)",
    "Spillane's \"___ Jury\"",
    "Spillane's \"___ Jury\"",
    "\"___ Jury\" (Spillane novel)",
    "Jackie Wilson's \"Am ___ Man\"",
    "Spillane's \"___ Jury\"",
    "\"Am ___ only one...?\"",
    "Spillane's \"___ Jury\"",
    "Spillane's \"___ Jury\""
  ],
  "fifi": [
    "Classic name for a poodle",
    "Diminutive of Josephine",
    "Standard poodle name",
    "Classic name for a 61-Down",
    "Classic name for a French poodle",
    "Minnie Mouse's dog",
    "Maggie's dog in \"Bringing Up Father\"",
    "Guy de Maupassant's \"Mademoiselle ___\"",
    "Poodle's handle",
    "Little dog of old Disney cartoons",
    "Poodle name",
    "Poodle's name"
  ],
  "foal": [
    "Stable newborn",
    "It's generally up and running within a few hours",
    "Stable newborn",
    "New pony",
    "Stable arrival",
    "\"Poor little\" one in Coleridge's \"To a Young Ass\"",
    "Young Arab",
    "Certain weanling",
    "Baby horse",
    "Four-legged newborn",
    "Livery delivery",
    "What a mare bears",
    "Certain suckling",
    "New range rover?",
    "Mare's newborn",
    "Stable newborn",
    "Lamb : ewe :: ___ : mare",
    "Farm young",
    "Stable baby",
    "One in a mare's nest?",
    "New stable arrival",
    "Babe in the stable",
    "Young Arab",
    "Future Derby runner, maybe",
    "Farm newborn",
    "New arrival on a horse farm",
    "Young zebra",
    "Farm delivery",
    "Stable newcomer",
    "Filly, e.g.",
    "Stable newborn",
    "One with a stable family",
    "Part of a stable family",
    "Young horse",
    "Newborn equine",
    "Farm baby",
    "Filly or colt",
    "Mare's offspring"
  ],
  "hgts": [
    "Elevs.",
    "Abbr. in some city names",
    "Elevs.",
    "Mtn. stats",
    "Ming's 7'6\" and Bryant's 6'6\", e.g.: Abbr.",
    "Elevs.",
    "Elevs.",
    "Hills: Abbr.",
    "Abbr. used in some town names"
  ],
  "faqs": [
    "They're answered once and for all",
    "Online help features, for short",
    "Online help",
    "Users' resources",
    "Online info sources",
    "Helpful website feature, for short",
    "Queries on the Internet, for short",
    "Refs. for Web site newbies",
    "Online help features, briefly",
    "Recommended reading for newbies",
    "Newbies are often directed to them",
    "Recommended reading for www newbies",
    "Common Web site content",
    "Web site sect. for newbies",
    "Basic Web site info, for short",
    "On-line newsgroup features",
    "Queries on the Internet"
  ],
  "livy": [
    "Writer on the history of Rome",
    "Early historian of ancient Rome",
    "Writer of the history \"Ab Urbe Condita\"",
    "Roman historian",
    "Roman writer",
    "\"History of Rome\" author",
    "Roman historian",
    "Roman historian",
    "Roman historian",
    "Roman historian",
    "Latin literary lion"
  ],
  "haag": [
    "Den ___, Nederland",
    "Den ___ (home of the International Criminal Court)",
    "Den ___, Nederland",
    "Den ___, Nederland",
    "Den ___, Nederland",
    "City name part that's Dutch for \"hedge\"",
    "Den ___, Nederland",
    "Den ___ (Dutch city, to the Dutch)"
  ],
  "wnyc": ["Producer of public radio's \"Radiolab\""],
  "brin": ["Sergey of Google", "Google co-founder Sergey"],
  "lupe": [
    "\"Little Latin ___ Lu\" (1960s hit)",
    "Hip-hop's ___ Fiasco",
    "\"Little Latin ___ Lu\" (1966 hit)",
    "Hip-hop's ___ Fiasco",
    "Rapper Fiasco",
    "1966 hit \"Little Latin ___ Lu\"",
    "Old-time actress Velez"
  ],
  "stoa": [
    "Ancient Greek colonnade",
    "Site of Zeno's teaching",
    "Greek colonnade",
    "Athenian colonnade",
    "Old colonnade",
    "Greek portico",
    "Greek walkway",
    "Ancient Greek colonnade",
    "Ancient walkway",
    "Covered Greek walkway",
    "Spartan gathering place",
    "Greek colonnade",
    "Classic covered walk",
    "Old philosophers' place",
    "Spartan walkway",
    "Spartan walkway",
    "Athenian porch",
    "Feature of some Greek buildings",
    "Greek portico",
    "Site of Zeno's teaching",
    "Greek gathering spot",
    "___ of Attalos (Greek museum site)",
    "Ancient Greek portico",
    "Covered walkway",
    "Ancient walkway",
    "Ancient Greek walkway",
    "Covered walkway",
    "Old marketplace surrounder",
    "Ancient Greek colonnade",
    "Feature of some classical architecture",
    "Covered walkway",
    "Covered walk",
    "Feature of old Greek architecture",
    "Classical lecture site",
    "Portico in Greek architecture",
    "Ancient gathering place",
    "Ancient sheltered promenade",
    "Greek portico",
    "Old gathering place",
    "Portico in Athens",
    "Portico",
    "Greek promenade",
    "Classical meeting place",
    "Ancient colonnade",
    "Athenian hub",
    "Ancient portico",
    "Ancient temple attachment",
    "Ancient gathering spot",
    "Ancient colonnade",
    "Portico",
    "Greek promenade",
    "Where Zeno of Citium lectured",
    "Portico",
    "Classical colonnade",
    "Portico",
    "Athenian site",
    "Ancient porch",
    "Greek architectural feature",
    "Greek portico",
    "Ancient meeting place",
    "Walkway for Plato",
    "Greek meeting place",
    "Portico",
    "Athens's___of Hadrian",
    "Portico",
    "Greek portico",
    "Covered walkway",
    "Columned walkway",
    "Greek portico",
    "Ancient meeting place",
    "Greek portico",
    "Greek portico",
    "Greek portico",
    "Greek portico",
    "Where Zeno taught"
  ],
  "alph": [
    "River running \"down to a sunless sea,\" in \"Kubla Khan\"",
    "Xanadu's river",
    "\"Kubla Khan\" river",
    "Xanadu river",
    "Coleridge's sacred river",
    "River in \"Kubla Khan\"",
    "Fictional river of verse",
    "\"Kubla Khan\" river",
    "Coleridge's \"sacred river\""
  ],
  "apar": [
    "On ___ with (even with)",
    "On ___ with (comparable to)",
    "On ___ with (equal to)",
    "On ___ with",
    "On ___ with",
    "On ___ with",
    "On ___ with (equal to)",
    "On ___ with (equal to)",
    "On ___ with (equal to)",
    "On ___ with",
    "On ___ with (even with)",
    "On ___ with (equal to)",
    "On ___ (equipotent)",
    "On ___ with",
    "On ___ with",
    "On ___ with (equal to)",
    "On ___ with (equal to)",
    "On ___ with",
    "On ___ with",
    "On ___ with",
    "On ___ with",
    "On ___ with (equal to)",
    "On ___ with",
    "On ___ with",
    "On ___ with",
    "On ___ (equipotent)",
    "On ___ with (equal to)",
    "On ___ with",
    "On ___ (commensurate)",
    "Be on ___ with (equal)",
    "On ___ with (equal to)",
    "On ___ with (equal to)",
    "On ___ with",
    "On ___ with",
    "On ___ with (equal to)",
    "On___with"
  ],
  "obla": [
    "Song lead-in to \"di\" or \"da\"",
    "Lead-in to \"di\" or \"da\" in a Beatles song",
    "Start of a Beatles title",
    "Preceder of \"di\" or \"da\" in a Beatles song",
    "Lead-in to \"di\" or \"da\" in a Beatles song",
    "Song lead-in to \"di\" or \"da\"",
    "Syllables before \"di\" or \"da\" in a Beatles song",
    "Di or da preceder in a Beatles song",
    "Syllables before \"di\" or \"da\" in a Beatles song",
    "Di or da preceder in a Beatles song",
    "Syllables before \"Di\" and \"Da\" in a Beatles song"
  ],
  "fili": ["Thread: Prefix", "Thread: Prefix"],
  "wahs": [
    "Temper tantrum outbursts",
    "Muted trumpet sounds",
    "Wails from baby"
  ],
  "duly": [
    "Properly",
    "As is fitting",
    "In the proper manner",
    "As required",
    "As required",
    "In a proper manner",
    "As required",
    "Properly",
    "As expected",
    "As is fitting"
  ],
  "judo": [
    "Sensei's teaching",
    "Martial art that's an Olympic sport",
    "___ chop",
    "Activity with holding and throwing",
    "Cousin of karate",
    "Sport with throws",
    "Olympic sport since 1964",
    "Cousin of karate",
    "Olympic sport from Japan",
    "Sport whose name means \"gentle way\"",
    "Literally, \"the gentle way\"",
    "Unarmed combat",
    "Olympic sport since 1964",
    "Sport in which Israel won its first Olympic medal",
    "Self-defense sport",
    "It might throw you",
    "Olympic event since 1972",
    "Black belt's activity",
    "Kind of chop",
    "Defense means",
    "Sport whose name means \"soft way\""
  ],
  "haws": [
    "Hems' partners",
    "Partner of hems",
    "Plowmen's cries",
    "Turns left, as a plow horse",
    "Turns left",
    "Orders to plow horses",
    "Equivocates",
    "Hems' partners",
    "Hems' partners",
    "Fumbles for words"
  ],
  "agts": [
    "Go-betweens: Abbr.",
    "Hollywood V.I.P.s: Abbr.",
    "Operatives: Abbr.",
    "See 13-Down",
    "Many authors have them: Abbr.",
    "I.R.S. employees: Abbr.",
    "Bureau employees: Abbr.",
    "Cut takers: Abbr.",
    "Some people with sports M.B.A.'s: Abbr.",
    "They get cuts: Abbr.",
    "15-percenters: Abbr.",
    "Authors' aids: Abbr.",
    "007 and others: Abbr.",
    "10-percenters: Abbr.",
    "Reps.",
    "Ins. sellers",
    "F.B.I. workers: Abbr.",
    "F.B.I. workers: Abbr.",
    "Ticket sellers: Abbr.",
    "F.B.I. workers: Abbr.",
    "Mulder and Scully: Abbr.",
    "Reps.",
    "Govt. employees",
    "C.I.A. types",
    "They do lunch: Abbr.",
    "10-percenters: Abbr.",
    "Reps.",
    "Reps."
  ],
  "ifno": ["\"Otherwise ...\"", "\"___ one objects ...\""],
  "unlv": [
    "The Runnin' Rebels of the N.C.A.A.",
    "Silver State sch.",
    "Runnin' Rebels' sch.",
    "The Runnin' Rebels of the N.C.A.A.",
    "Sch. of the Runnin' Rebels",
    "Runnin' Rebels of the N.C.A.A.",
    "The Runnin' Rebels, for short",
    "Silver State sch.",
    "1990 N.C.A.A. hoops champs",
    "The Runnin' Rebels of coll. sports",
    "1990 N.C.A.A. basketball champions"
  ],
  "exon": [
    "Former Nebraska senator James",
    "Former Nebraska senator James",
    "Yeomen of the Guard officer",
    "Nebraska senator succeeded by Hagel",
    "Former Nebraska senator James",
    "Former Nebraska senator James",
    "Nebraska Senator James",
    "Nebraska Senator James"
  ],
  "sncc": [
    "Old civil rights org.",
    "March on Washington grp.",
    "Grp. organizing '60s sit-ins",
    "'60's civil rights org.",
    "1960's civil rights org.",
    "60's civil rights org.",
    "1960's civil rights org.",
    "60's civil rights grp.",
    "60's Black Power grp.",
    "Rights org. estab. 1960"
  ],
  "clea": ["\"21 Grams\" actress DuVall", "\"The Alexandria Quartet\" finale"],
  "shar": [
    "___-pei (wrinkly dog)",
    "___-Pei (dog breed)",
    "___-Pei (dog breed)",
    "___-Pei (dog)",
    "___-Pei (Chinese dog)"
  ],
  "bums": [
    "Bottoms",
    "Gets free, as a smoke",
    "Borrows without intending to repay",
    "They're often seeking change",
    "Spare change collectors",
    "Sponges",
    "Depresses, with \"out\"",
    "Vagrants",
    "Bowery ___",
    "Loafers"
  ],
  "anod": [
    "Give ___ of approval",
    "\"And giving ___, up the chimney he rose\"",
    "Give ___ of approval",
    "\"And giving ___, up the chimney ...\"",
    "\"And giving ___, up the chimney he rose\"",
    "Give ___ of approval",
    "Give ___ to (approve)",
    "\"With a wink and ___\"",
    "Give ___ (say O.K.)",
    "\"And giving ___, up the chimney ...\"",
    "Give ___ to (O.K.)",
    "Give ___ to (approve)",
    "\"With a wink and ___ ...\"",
    "Give ___ of approval",
    "Give ___ to (approve)",
    "Give ___ to (acknowledge)",
    "SLEEPY",
    "\"With a wink and ___\"",
    "\"___ is as good as a wink\"",
    "\"___ is as good as a wink\"",
    "Give ___ to (approve)",
    "It was given by St. Nicholas"
  ],
  "jobs": [
    "Steve who once headed Apple",
    "Perennial campaign issue",
    "Steve of Apple",
    "Union concerns",
    "Classified information?",
    "Perennial presidential campaign issue",
    "Perennial campaign issue",
    "Steven of Apple computers"
  ],
  "berm": [
    "Road shoulder",
    "Road shoulder",
    "Road shoulder",
    "Side of a road",
    "Shoulder",
    "Earthen embankment",
    "Road's shoulder",
    "Landscaping screen",
    "Road shoulder",
    "Wall of earth",
    "Earthwork",
    "Road shoulder",
    "Edge of a rampart",
    "Shoulder of a road",
    "Road shoulder"
  ],
  "jeez": [
    "\"Holy moly!\"",
    "\"Oh, man!\"",
    "\"Aw, come on!\"",
    "\"Oh, man!\"",
    "\"All in the Family\" exclamation",
    "\"Oh, c'mon!\"",
    "\"Man, oh, man!\"",
    "\"Holy mackerel!\""
  ],
  "dazs": [
    "Haagen-___",
    "Haagen-___",
    "Haagen-___",
    "Haagen-___",
    "Haagen-___",
    "Haagen-___"
  ],
  "reda": [
    "Hester Prynne's mark",
    "The titular scarlet letter",
    "The scarlet letter",
    "Hester Prynne's stigma",
    "The scarlet letter",
    "Hawthorne novel stigma",
    "Stigma borne by Hester Prynne",
    "Hester Prynne's stigma",
    "The scarlet letter"
  ],
  "apin": [
    "Neat as ___",
    "So quiet you can hear ___ drop",
    "Neat as ___",
    "\"Scratch thee but with ___ ...\": Shak.",
    "\"... hear ___ drop\"",
    "Neat as ___",
    "\"... on the head of ___?\"",
    "Neat as ___",
    "\"?à on the head of ___\"",
    "Neat as ___",
    "Neat as ___",
    "Answer to the folk riddle \"Big head, no hair\"",
    "\"... hear ___ drop\"",
    "Neat as ___",
    "\"...hear ___ drop\"",
    "Neat as ___"
  ],
  "cale": [
    "John of the Velvet Underground",
    "Nascar's Yarborough",
    "Racer Yarborough",
    "Racer Yarborough",
    "John of the Velvet Underground",
    "Nascar's Yarborough",
    "Racer Yarborough",
    "First name in auto racing",
    "Racer Yarborough",
    "Auto racer Yarborough",
    "Yarborough of Nascar",
    "Yarborough of the Daytona 500",
    "Race driver Yarborough",
    "Racer Yarborough",
    "Auto racer Yarborough",
    "Rock musician John"
  ],
  "achs": [
    "German interjections",
    "German cries",
    "Expressions of frustration abroad",
    "Bonn exclamations",
    "German interjections",
    "Essen expletives",
    "Cries in Cologne",
    "Worms cries",
    "German exclamations",
    "Cologne cries",
    "German cries",
    "Cries from Krupp"
  ],
  "inme": [
    "\"You've Got a Friend ___\" (song from \"Toy Story\")",
    "\"You've Got a Friend ___\"",
    "\"Have a Little Faith ___\" (1930 hit)",
    "\"The Gypsy ___\" (\"Anything Goes\" song)",
    "\"I didn't know I had it ___!\"",
    "\"She Believes ___\" (Kenny Rogers hit)",
    "\"By the power vested ___ ...\"",
    "\"By the power vested ___ ...\"",
    "\"Trust ___\" (1937 hit)",
    "\"She Believes ___\" (Kenny Rogers song)",
    "\"Trust ___\" (1937 hit)",
    "\"She Believes ___\" (1979 hit)"
  ],
  "puns": [
    "The salon names To Dye For and Best Little Hairhouse (both real!), e.g.",
    "What crossword clues with question marks often are",
    "Some crossword clues",
    "Salon names like Scissors Palace and Curl Up & Dye",
    "Some Groucho Marx humor",
    "Many conundrums have them",
    "Wits' bits",
    "Groaners",
    "\"Sanity clause\" and others",
    "Groan causers",
    "Some crossword clues",
    "Groucho Marx specialties",
    "Puzzle clues, sometimes",
    "Some crossword clues"
  ],
  "acup": [
    "\"And we'll tak' ___ o' kindness yet\": Burns",
    "Small bra size",
    "Bra size smaller than a \"B\"",
    "Small bra specification",
    "Small 58-Down size",
    "Repeated lyric in \"Java Jive\"",
    "Smallish bra size",
    "Small bra part",
    "\"We'll tak ___ o' kindness yet\"",
    "Bra size",
    "\"We'll tak___o' kindness yet\": Burns"
  ],
  "whoo": ["Cry of excitement", "Owl's question?"],
  "fuzz": ["Beginnings of a beard", "Nap kin"],
  "ladd": [
    "Actress Cheryl or Diane",
    "\"Shane\" star",
    "Cheryl of \"Charlie's Angels\"",
    "Cheryl of \"Charlie's Angels\"",
    "\"The Blue Dahlia\" star, 1946",
    "\"Charlie's Angels\" actress",
    "\"Shane\" star Alan",
    "See 15-Across",
    "Angel Cheryl",
    "Diane of \"Wild at Heart\"",
    "\"O.S.S.\" star, 1946",
    "Cheryl of TV's \"Charlie's Angels\"",
    "Diane of \"Alice Doesn't Live Here Anymore\"",
    "Actor Alan",
    "\"The Blue Dahlia\" star",
    "Alan of \"The Carpetbaggers\"",
    "\"Shane\" star",
    "Diane of \"A Kiss Before Dying,\" 1991",
    "\"Shane\" star",
    "Alan who starred in \"Saigon,\" 1948",
    "Alan of \"Shane\"",
    "She played one of Charlie's Angels",
    "See 80-Down",
    "\"Shane\" star Alan",
    "Actress Diane",
    "Great Gatsby player, 1949",
    "Actor Alan",
    "\"This Gun for Hire\" star",
    "\"The Carpetbaggers\" co-star, 1964",
    "29-Down player",
    "\"Shane\" man",
    "Cheryl of \"Charlie's Angels\"",
    "\"This Gun for Hire\" star",
    "Alan or Cheryl",
    "\"This Gun for Hire\" star, 1942",
    "Alan of \"The Glass Key\"",
    "Actor Alan",
    "See 23-Across",
    "Gatsby portrayer, 1949",
    "Alan or Cheryl",
    "Munroe of \"Charlie's Angels\"",
    "\"This Gun for Hire\" star",
    "Alan or Cheryl",
    "\"Shane\" star",
    "\"Charlie's Angels\" actress"
  ],
  "coit": [
    "San Francisco's ___ Tower",
    "San Francisco's ___ Tower",
    "San Francisco's ___ Tower",
    "San Francisco's ___ Tower",
    "___ Tower (San Francisco landmark)",
    "San Francisco's___Tower",
    "San Francisco's ___ Tower"
  ],
  "ense": [
    "Start of Massachusetts' state motto",
    "Start of Massachusetts' motto",
    "Start of the Bay State's motto",
    "First word of Massachusetts' motto",
    "First word in Massachusetts' motto",
    "Massachusetts motto opener",
    "Massachusetts motto starter",
    "Start of Massachusetts' motto",
    "Massachusetts motto starter",
    "Start of Massachusetts' motto",
    "Start of Massachusetts' motto",
    "Start of Massachusetts' motto",
    "Massachusetts motto opener",
    "Start of Massachusetts' motto",
    "Massachusetts motto opener",
    "Word in Massachusetts' motto",
    "First word in Massachusetts' motto",
    "Start of Massachusetts' motto",
    "First word of Massachusetts' motto",
    "Massachusetts's motto start",
    "Start of Massachusett's motto",
    "First word of Massachusett's motto"
  ],
  "vlad": [
    "Infamous impaler",
    "___ III, inspiration for Dracula",
    "Wallachian prince who inspired \"Dracula\"",
    "The Impaler",
    "___ the Impaler",
    "___ the Dragon, ruler of old Wallachia",
    "Prince called \"the Impaler,\" who was the inspiration for Dracula",
    "___ the Impaler",
    "15th-century prince of Wallachia",
    "Dracula's inspiration, traditionally",
    "Dracula prototype",
    "Romanian ruler known as \"the Impaler\""
  ],
  "sarg": [
    "Tony ___, early Macy's Day Parade balloon designer",
    "Puppeteer Tony",
    "Pioneering puppeteer Tony",
    "Puppeteer Tony",
    "Puppeteer Tony",
    "America's Puppet Master",
    "Puppeteer Tony",
    "Pioneering puppeteer",
    "Prominent puppet show producer",
    "Puppeteer Tony",
    "Puppeteer Tony",
    "Famed puppeteer",
    "Puppeteer Tony",
    "Puppeteer Tony",
    "Puppeteer Tony",
    "Marionette maker Tony",
    "Puppeteer Tony",
    "Puppeteer Tony",
    "Puppeteer Tony",
    "Marionette man Tony",
    "Puppeteer Tony"
  ],
  "lene": [
    "___ Hau, pioneering physicist from Denmark",
    "Voiceless consonant like \"b\" or \"p\"",
    "Singer Lovich",
    "Singer Lovich",
    "New Wave singer Lovich",
    "Unaspirated, in old phonetics",
    "Smooth, in phonetics"
  ],
  "robt": [
    "Gen. ___ E. Lee",
    "___ Morris, signature on the Declaration of Independence",
    "Gen. ___ E. Lee",
    "Gen. Lee, in brief",
    "Gen. ___ E. Lee",
    "___ Morris, signature on the Declaration of Independence",
    "Gen. ___ E. Lee"
  ],
  "babs": [
    "Streisand, familiarly",
    "Streisand, familiarly",
    "Streisand, familiarly",
    "Nickname for Barbara",
    "Streisand, to friends",
    "Streisand, in fanzines",
    "Streisand, in fanzines",
    "Barbara, familiarly",
    "Barbara, to friends",
    "Barbara, to friends",
    "Another name for Barb",
    "Streisand, in fanzines",
    "Barbara, to friends",
    "Nickname for Barbara"
  ],
  "xeno": [
    "Prefix with phobia",
    "Foreign: Prefix",
    "Alien: Prefix",
    "Alien: Prefix",
    "Prefix with phobia",
    "Start of a phobia?",
    "Strange beginning?",
    "Alien: Prefix",
    "Prefix with phobia",
    "Prefix with phobia",
    "Strange: Prefix",
    "Prefix with phobia",
    "Prefix with phobic",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with phobia",
    "Prefix with -lith or -phobia",
    "Prefix with phobia",
    "Prefix with phobia",
    "Alien prefix",
    "Strange: Prefix",
    "Foreign start"
  ],
  "diez": ["Dos x 5", "Number between nueve and once", "Ten, in Toledo"],
  "catt": [
    "Suffragist Carrie Chapman ___",
    "Suffragist Carrie Chapman ___",
    "Suffragist Carrie Chapman ___",
    "Women's suffrage leader Carrie Chapman ___",
    "Suffragist Carrie Chapman ___",
    "Women's suffrage leader Carrie Chapman ___",
    "Suffragist Carrie Chapman ___",
    "Carrie Chapman ___, founder of the League of Women Voters",
    "Suffragist Carrie Chapman ___",
    "19th Amendment champion",
    "Women's suffrage leader Carrie Chapman ___",
    "Women's rights pioneer",
    "Suffragist Carrie Chapman ___",
    "Noted suffragist leader",
    "Suffragist Carrie",
    "Suffragist Carrie Chapman ___",
    "Women's rights pioneer",
    "\"Pogo\" character Wiley___",
    "V.I.P. in woman suffrage",
    "Women's suffrage leader"
  ],
  "xing": [
    "RR ___",
    "Ped ___",
    "RR ___",
    "Ped ___",
    "Deer ___",
    "Deer ___",
    "Shortened word on a yellow street sign",
    "Deer ___",
    "Deer ___",
    "Pedestrian sign",
    "Place to walk, for short",
    "RR ___",
    "Ped ___ (traffic caution)"
  ],
  "alop": [
    "Cockeyed",
    "Off-kilter",
    "Like some rabbit ears",
    "Cockeyed",
    "Catawampus",
    "Cockeyed",
    "Skewed to one side",
    "Crooked",
    "Drooping, as a rabbit's ears",
    "Crooked",
    "Catawampus",
    "Uneven",
    "Not true",
    "Cockeyed",
    "Cockeyed",
    "Crooked",
    "Canted",
    "Crooked",
    "Crooked",
    "In an unbalanced way",
    "Not straight",
    "Drooping",
    "Out of balance",
    "Off kilter",
    "Unbalanced",
    "Not straight",
    "Cockeyed",
    "Hanging loosely",
    "Not straight",
    "Unbalanced",
    "Not on the level",
    "Crooked"
  ],
  "cris": [
    "N.F.L. Hall-of-Famer Carter",
    "Sportscaster Collinsworth",
    "Sportscaster Collinsworth",
    "Collinsworth of N.F.L. fame"
  ],
  "ltyr": [
    "Distance in astronomy: Abbr.",
    "Astronomer's unit: Abbr.",
    "Astronomical distance: Abbr.",
    "Astronomical meas.",
    "Astronomical distance: Abbr.",
    "Astronomical distance: Abbr.",
    "Astronomical distance: Abbr.",
    "Astronomical meas."
  ],
  "univ": [
    "Duke, e.g.: Abbr.",
    "Part of P.S.U.: Abbr.",
    "Place to get an M.A. or Ph.D.",
    "Prof's place: Abbr.",
    "Ph.D. awarder",
    "B.A. or M.A. offerer",
    "Butler, e.g.: Abbr.",
    "Educ. institution",
    "Duke, e.g.: Abbr.",
    "Colgate, e.g.: Abbr.",
    "Part of A.A.U.W.: Abbr.",
    "Part of A.A.U.W.: Abbr.",
    "Harvard or Stanford: Abbr.",
    "Rice, e.g.: Abbr.",
    "Purdue, e.g.: Abbr.",
    "Where to earn a B.A.",
    "Brown, e.g.: Abbr.",
    "Rice, e.g.: Abbr."
  ],
  "dave": [
    "Singer/songwriter Matthews",
    "\"Affirmative, ___, I read you\" (line in \"2001: A Space Odyssey\")",
    "Actor Franco of \"Now You See Me\"",
    "Letterman of late-night",
    "Humorist Barry",
    "Thwarter of HAL",
    "Humorist Barry",
    "Brubeck of jazz",
    "Writer Eggers",
    "\"Late Show\" host, colloquially",
    "Early TV host Garroway",
    "Comic Chappelle",
    "Comedian Chappelle",
    "Host Letterman",
    "Humorist Barry",
    "Starsky of \"Starsky and Hutch\"",
    "Pulitzer-winning humorist Barry",
    "Noted Barry",
    "Letterman of late-night",
    "Pianist Brubeck",
    "1993 Kevin Kline movie",
    "Singer Matthews",
    "1993 film in which Kevin Kline played the president",
    "1993 Kevin Kline title role",
    "Jay's competition",
    "Hoopster Hall-of-Famer Bing",
    "Late-night name",
    "TV's Letterman",
    "1993 Kevin Kline comedy",
    "Garroway of early TV",
    "\"___ Barry Turns 40\" (1990 best seller)",
    "1993 Kevin Kline movie",
    "Garroway of 50's TV"
  ],
  "eous": ["Suffix with right", "Suffix with gas", "Suffix with right"],
  "mlle": [
    "Swiss miss, maybe: Abbr.",
    "Miss from Metz: Abbr.",
    "Srta., on the Seine",
    "French miss: Abbr.",
    "Title for a jeune fille: Abbr.",
    "Fr. girl",
    "\"___ Modiste\" (Victor Herbert operetta)",
    "Fr. title",
    "Miss, in Meuse: Abbr.",
    "French miss: Abbr.",
    "Fr. title",
    "Miss from Marseille: Abbr.",
    "M.'s counterpart",
    "Lille girl: Abbr.",
    "French miss: Abbr.",
    "French miss: Abbr.",
    "French miss: Abbr.",
    "Miss from Marseille: Abbr.",
    "Fr. title",
    "M.'s counterpart",
    "Swiss miss, maybe: Abbr.",
    "Srta., in France",
    "Fr. miss",
    "Abbr. on a French envelope",
    "Fr. miss",
    "French miss, for short",
    "Srta., across the Pyrenees"
  ],
  "nonu": [
    "Like Cockneys, in British lingo",
    "Lower-class, to Brits",
    "Lower-class, in Leeds",
    "Low-class, in Leeds",
    "Like average folks, in Britain",
    "Unlike aristos",
    "Bourgeois, to a Brit",
    "Like the hoi polloi",
    "Unelite, in London"
  ],
  "alie": [
    "Caught in ___",
    "\"I cannot tell ___\"",
    "\"___ told often enough becomes the truth\": Lenin",
    "Be caught in ___",
    "\"That's ___!\" (\"Not true!\")",
    "\"That's ___!\"",
    "Caught in ___",
    "\"My life is ___!\"",
    "\"___ gets halfway around the world ...\"",
    "\"___ told often enough ...\"",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "\"That's ___!\" (\"Not true!\")",
    "\"That's ___!\"",
    "Get caught in ___",
    "Get caught in ___",
    "\"I cannot tell ___\"",
    "Caught in ___",
    "\"I cannot tell ___\"",
    "\"It's a sin to tell ___\"",
    "Caught in ___",
    "\"That's ___!\" (debate retort)",
    "\"It's a sin to tell ___\"",
    "Live ___ (be someone you're not)",
    "?ôThat?Æs ___!?ö (angry denial)",
    "\"It's a Sin to Tell ___\" (1936 hit)",
    "\"That's ___!\" (angry retort)",
    "\"That's ___!\"",
    "\"That's ___!\"",
    "\"That's ___!\"",
    "\"That's ___!\"",
    "Sam Shepard's \"___ of the Mind\"",
    "\"That's ___!\"",
    "\"___ of the Mind\" (Shepard play)",
    "\"That's ___!\"",
    "\"___ of the Mind\" (Sam Shepard play)",
    "\"That is ___!\" (debate charge)",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "\"That's ___!\" (debate cry)",
    "\"That's ___!\"",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "\"That's ___!\"",
    "What George couldn't tell",
    "\"I cannot tell ___\"",
    "Sam Shepard's \"___ of the Mind\"",
    "\"I cannot tell___\"",
    "\"I cannot tell___\"",
    "\"I cannot tell___\"",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "\"It's a Sin to Tell ___\"",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "\"It's a sin to tell ___\"",
    "Sam Shepard?Æs ?ô___ of the Mind?ö",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\"",
    "What George couldn't tell",
    "\"I cannot tell ___\"",
    "\"I cannot tell ___\""
  ],
  "flue": [
    "Something a chimney sweep sweeps",
    "Chimney vent",
    "Where there's smoke",
    "Chimney feature",
    "Drawing device",
    "Current director",
    "Chimney channel",
    "Smoke conveyor",
    "Drawing device",
    "Chimney part",
    "Air duct",
    "Chimney channel",
    "Where there's smoke",
    "Chimney channel",
    "Chimney duct",
    "Sooty shaft",
    "Kind of pipe",
    "It's full of hot air",
    "Fire exit?",
    "Chimney channel",
    "Air duct"
  ],
  "gook": [
    "Proceed well enough",
    "Icky stuff",
    "Icky stuff",
    "Too heavy makeup",
    "Sticky stuff",
    "Sticky stuff",
    "Sludge"
  ],
  "yard": [
    "Ending with brick or stock",
    "Almost a meter",
    "Prison part",
    "Ending with school or steel",
    "Prison area",
    "Navy ___",
    "It has three feet",
    "One of 100 on a football field",
    "See 73-Down",
    "One of 10 in a series of football downs",
    "Sale site, maybe",
    "Gain from a quarterback sneak, perhaps",
    "36\"",
    "Sale locale",
    "Exterior area",
    "Half a fathom",
    "Area within a picket fence, say",
    "Play area",
    "Landscaper's locale",
    "Prison part",
    "It may be behind a picket fence",
    "Sale locale",
    "Cloth measure",
    "Sometime sale site",
    "With 66-Across, where one might find 16-, 26-, 43- and 58-Across",
    "Sale site",
    "Spar",
    "Prison exercise area",
    "Prison exercise area",
    "Draper's unit",
    "Something found around the house",
    "A bit less than a meter",
    "Railroad area",
    "Sail support",
    "House adjunct",
    "Word with bone or court",
    "Place to play",
    "Harvard ___",
    "Train locale",
    "Play area",
    "Stock follower",
    "Prison part",
    "Small football gain",
    "Long spar",
    "Play area",
    "The \"y\" in Nimby",
    "Lager quantity",
    "Three feet",
    "Croquet locale",
    "Sale place"
  ],
  "tojo": [
    "Leader among the Axis powers",
    "Axis leader",
    "Japanese P.M. executed in 1948",
    "Pearl Harbor attack initiator",
    "W.W. II Axis leader",
    "Hitler : Germany :: ___ : Japan",
    "Japanese P.M. during W.W. II",
    "Axis leader",
    "W.W. II villain",
    "Axis leader",
    "W.W. II leader",
    "Axis villain",
    "W.W. II adversary",
    "Axis leader"
  ],
  "bolo": [
    "Tie out West",
    "Skinny tie",
    "With 24-Across, item of western haberdashery",
    "Shoestring necktie",
    "Kind of tie",
    "String tie",
    "Western accessory",
    "Vaquero's neckwear",
    "String tie",
    "Western tie",
    "___ tie",
    "Cowboy's tie",
    "Tie with a clasp",
    "String tie",
    "Western tie",
    "Tie with a cord",
    "Single-edged knife",
    "___ tie",
    "Skinny tie",
    "Hacking tool",
    "Thin tie with a clasp",
    "Square dancer's tie",
    "Hacking tool",
    "Western tie",
    "Tie type",
    "Machete",
    "___ tie",
    "Thin necktie",
    "Manila machete",
    "Western necktie",
    "Heavy Army knife",
    "Machetelike knife"
  ],
  "bahs": [
    "Scroogeisms",
    "Dismissive cries",
    "Cross words",
    "Exclamations from Scrooge",
    "\"Humbug\" preceders",
    "Curmudgeonly cries",
    "Scrooge-like cries",
    "Scornful replies",
    "Curmudgeonly cries",
    "Cross words",
    "Outbursts from Scrooge",
    "Skeptics' remarks",
    "Dismissive remarks",
    "Scornful cries",
    "Cross words",
    "Scroogeisms",
    "Pessimist's comments",
    "Scornful cries",
    "\"A Christmas Carol\" cries",
    "Critical replies",
    "Disparaging comments",
    "Contemptuous comments",
    "Dickensian cries",
    "Dismissive interjections",
    "Dickensian expletives",
    "Exclamations from Ebenezer",
    "\"A Christmas Carol\" cries",
    "Scroogian comments"
  ],
  "yost": [
    "World Series-winning manager Ned",
    "Dennis who fronted the 1960s-'70s Classics IV",
    "Royals manager Ned",
    "Baseball manager Ned",
    "Baseball's Eddie who was nicknamed \"The Walking Man\"",
    "Baseball manager Ned",
    "Dennis ___ and the Classics IV (1960s-'70s group)",
    "Baseball's Eddie, 1952 All-Star for the Senators",
    "Baseball's \"Walking Man\" Eddie",
    "Eddie ___, The Walking Man of baseball"
  ],
  "abou": [
    "\"___ Ben Adhem\" (Leigh Hunt poem)",
    "\"___ Ben Adhem\"",
    "___ Hassan, \"Arabian Nights\" figure",
    "\"___ Ben Adhem\"",
    "\"___ Ben Adhem\"",
    "\"___ Ben Adhem\"",
    "\"___ Ben Adhem\" (English poem)",
    "\"___ Ben Adhem\"",
    "\"___ Ben Adhem\" (Leigh Hunt poem)",
    "\"___ Ben Adhem\" (Leigh Hunt poem)",
    "___ Ben Adhem",
    "Leigh Hunt's \"___ Ben Adhem\"",
    "?ô___ Ben Adhem?ö",
    "\"___ Ben Adhem,\" Leigh Hunt poem",
    "\"___ Ben Adhem\"",
    "___ ben Adhem",
    "\"___ ben Adhem\"",
    "\"___ Ben Adhem\" (Leigh Hunt poem)",
    "\"___ Ben Adhem\"",
    "Part of many Arab names",
    "\"___ Ben Adhem\"",
    "\"___ Ben Adhem\" (Leigh Hunt poem)",
    "Arab name part",
    "___ Ben Adhem",
    "___ Ben Adhem",
    "___ Ben Adhem"
  ],
  "rima": [
    "Italian poetic form",
    "Feature of un poema",
    "Italian verse form",
    "Feature of \"pasta\" and \"basta\"",
    "Italian lyrical verse",
    "Ottava ___ (poetry type)",
    "Poesia feature",
    "Terza ___ (Italian verse form)",
    "Terza ___ (Italian verse form)",
    "Abel's \"Green Mansions\" love"
  ],
  "bpoe": [
    "Org. with a Grand Esteemed Loyal Knight",
    "Lodge org.",
    "Fraternal org.",
    "Fraternal grp.",
    "Fraternal letters",
    "Fraternal org.",
    "Fraternal org.",
    "Grp. with lodges",
    "Lodge letters",
    "Fraternal letters",
    "Lodge letters",
    "Lodge letters",
    "Lodge sign",
    "Fraternal org.",
    "Fraternal org.",
    "Fraternal org.",
    "Fraternal inits."
  ],
  "sess": [
    "Psychoanalysis appt.",
    "Time in therapy, e.g.: Abbr.",
    "Meeting of Congress: Abbr.",
    "Mtg.",
    "Cong. meeting",
    "Mtg.",
    "Short term?",
    "School period: Abbr.",
    "Psychiatrist's appt.",
    "Congressional period: Abbr.",
    "Practice period: Abbr.",
    "Legis. period",
    "Legis. period",
    "Part of a committee sched.",
    "Meeting: Abbr.",
    "Legis. meeting",
    "Legis. meeting",
    "Cong. period",
    "Period in Cong.",
    "Legis. period",
    "Part of a conference sched.",
    "Psychiatrist's appt.",
    "Legis. meeting",
    "Mtg.",
    "Period in sch.",
    "Congressional mtg.",
    "Series of legis. meetings",
    "Appt. with an analyst",
    "Meeting: Abbr.",
    "Cong. meeting",
    "Legis. meeting",
    "Cong. period",
    "Cong. period",
    "Gen. Assembly event",
    "Meeting of Cong.",
    "Meeting: Abbr.",
    "Shrink time: Abbr.",
    "Mtg.",
    "Meeting of Cong.",
    "Meeting: Abbr.",
    "Mtg. of Congress"
  ],
  "ftlb": ["Unit of energy: Abbr.", "Work unit abbr."],
  "pnin": [
    "Titular professor in a Nabokov novel",
    "Nabokov novel",
    "Nabokov novel",
    "Nabokov novel after \"Lolita\"",
    "Nabokov novel after \"Lolita\"",
    "Nabokov novel",
    "Vladimir Nabokov novel",
    "Nabokov novel",
    "Nabokov novel",
    "1957 Nabokov novel",
    "Title professor in a 1957 novel",
    "Nabokov novel",
    "Nabokov novel",
    "1957 Nabokov novel",
    "Nabokov novel",
    "Nabokov book, 1957",
    "Nabokov novel"
  ],
  "gawd": ["\"Good ___ almighty!\"", "\"Good ___ A'mighty!\""],
  "hisn": [
    "Huck Finn possessive",
    "Dogpatch possessive",
    "Belonging to Li'l Abner"
  ],
  "seqs": [
    "\"Look Who's Talking Too\" and \"2 Fast 2 Furious\": Abbr.",
    "\"II\" movies: Abbr.",
    "Some movies: Abbr."
  ],
  "quoi": [
    "Basic French question",
    "Je ne sais ___",
    "Je ne sais ___",
    "What, to Camus",
    "What, to Watteau",
    "What Sartre said?"
  ],
  "ideo": [
    "Prefix with -graphic",
    "Prefix with -logical",
    "Prefix with -logical",
    "Prefix with -logical",
    "Thought: Prefix",
    "Prefix with -graphic",
    "Thought: Prefix",
    "Logical beginning?",
    "Logical start?",
    "Prefix with -logical",
    "Prefix with logical",
    "Prefix with logical",
    "Logical introduction?",
    "Prefix with -gram or -graph",
    "Prefix with -logue or -gram",
    "Logical start?",
    "Logical start?",
    "Logical start?",
    "Prefix with graphic",
    "Prefix with -logue",
    "Thinking prefix",
    "Prefix with graphic",
    "Prefix with logical",
    "Prefix with logical",
    "Thought: Prefix",
    "Thought: Prefix",
    "Prefix with logical",
    "Prefix with logical",
    "Thought: Prefix",
    "Logical start?",
    "Prefix with logical",
    "Prefix with -graph",
    "Graphic beginning",
    "Prefix with -gram",
    "Prefix with logical",
    "Prefix with -logue",
    "Prefix with logical",
    "Prefix with gram or logical",
    "Graph start",
    "Logical start"
  ],
  "vere": [
    "Edward Fairfax ___, \"Billy Budd\" captain",
    "\"Billy Budd\" captain",
    "\"Billy Budd\" captain",
    "Captain in \"Billy Budd\"",
    "Billy Budd's captain"
  ],
  "bren": [
    "Air-cooled machine gun",
    "W.W. II light machine gun",
    "Air-cooled machine gun",
    "Gas-operated machine gun",
    "Clip-fed machine gun",
    "Machine gun",
    "Clip-fed submachine gun"
  ],
  "tpks": [
    "Toll rds.",
    "Rtes. with plazas",
    "Some hwys.",
    "Some N.Y. and N.J. roads",
    "There's no free ride on these hwys.",
    "Some rtes.",
    "Toll rds."
  ],
  "rkos": [
    "Some bygone theaters",
    "Bygone theaters",
    "Old theaters once owned by Howard Hughes",
    "Some old theaters",
    "Bygone theaters"
  ],
  "porc": [
    "Alternative to boeuf or jambon",
    "Alternative to boeuf or jambon",
    "Parisian pig",
    "Offering from a Parisian butcher",
    "French meat"
  ],
  "suez": [
    "Gulf of ___",
    "1956 crisis site",
    "Canal to the Red Sea",
    "Mideast's Gulf of ___",
    "1956-57 crisis site",
    "Red Sea port",
    "1956 trouble spot",
    "Egyptian port",
    "1956 hotspot",
    "Gulf of ___",
    "Mideast canal",
    "Egyptian port",
    "City rebuilt after the Arab-Israeli wars",
    "Egyptian canal",
    "1956 crisis site",
    "Egyptian port",
    "Canal that leads to the Red Sea",
    "Egyptian port",
    "Red Sea access",
    "Mideast canal",
    "Mideast canal",
    "___Canal",
    "Egyptian port",
    "Egyptian canal"
  ],
  "petr": [
    "Mrazek or Nedved of the N.H.L.",
    "Czech form of the French \"Pierre\"",
    "Czech-born N.H.L.'er Sykora or Prucha",
    "Korda of tennis"
  ],
  "ired": [
    "Plenty angry",
    "Ticked off",
    "Really mad",
    "Furious",
    "All riled up",
    "Wrathful",
    "Turned red, say",
    "Steaming",
    "Steamed",
    "Hot",
    "Seeing red",
    "Really ticked",
    "Ticked off",
    "Teed off",
    "Steamed",
    "Hot",
    "Plenty mad",
    "Plenty mad",
    "Burned up",
    "Hot",
    "Really steamed",
    "Steamed",
    "Put out",
    "Good and hot",
    "Angered",
    "Hot",
    "Angry",
    "Really steamed",
    "Plenty angry",
    "Vexed",
    "Hot",
    "Red-hot",
    "Burned up",
    "Hot",
    "Seeing red",
    "Enraged",
    "Put out",
    "Ticked off",
    "Hot",
    "Angered",
    "Plenty mad",
    "Angry",
    "Upset",
    "Drove up the wall",
    "Angry",
    "Ticked off"
  ],
  "ello": [
    "Cockney greeting",
    "London greeting",
    "Cockney greeting",
    "East End greeting",
    "Cockney greeting",
    "London greeting",
    "Greeting in London's East End",
    "Greenwich greeting",
    "East End greeting",
    "Cockney greeting",
    "London greeting",
    "Cockney greeting"
  ],
  "acey": [
    "___-deucey (card game)",
    "___-deucy",
    "___-deucey (card game)",
    "___-deucey",
    "___-deucey",
    "___-deucey",
    "___-deucy (backgammon variety)",
    "___-deucy",
    "___-deucey",
    "___-deucey",
    "___-deucy",
    "___-deucy (game with dice)",
    "___-deucey (game)",
    "Deucy preceder",
    "___-deucey"
  ],
  "qing": ["Last Chinese dynasty"],
  "mfas": [
    "Dramatists' degs.",
    "Degs. for creative types",
    "Juilliard degs.",
    "Some docents' degs.",
    "Pratt Institute degs.",
    "Painters' degs.",
    "Painters' degs.",
    "Some 6-Down curators: Abbr.",
    "Dramatists' degs.",
    "Degs. for curators",
    "Curators' degs."
  ],
  "sars": [
    "Virus in 2003 news",
    "Early 2000s outbreak, for short",
    "Outbreak of 2003",
    "2003 disease scare",
    "Headline-making illness of 2002-03",
    "Modern epidemic"
  ],
  "mush": [
    "Order to go",
    "Drivel",
    "\"Let's go!\" to sled dogs",
    "Cry to a sled dog",
    "Cry to a husky",
    "Cry to a leading team?",
    "Shout to a team, maybe",
    "Word to a team",
    "Shout to the team",
    "\"Go, team!\"",
    "Sled dog command",
    "Travel by sled",
    "Command at the Iditarod"
  ],
  "lolo": [
    "Obama's stepfather ___ Soetoro",
    "___ Soetoro, stepfather of Barack Obama"
  ],
  "aboy": [
    "\"About ___\" (2002 movie)",
    "\"It's ___!\" (birth announcement)",
    "\"It's ___!\"",
    "Bernstein/Sondheim's \"___ Like That\"",
    "\"It's ___!\"",
    "Sondheim's \"___ Like That\"",
    "\"When I was ___...\"",
    "Johnny Cash's \"___ Named Sue\"",
    "\"___ Named Sue\"",
    "\"It's ___!\" (proud parents' phrase)",
    "\"Looking for ___\" (Gershwin hit)",
    "\"___Like That\" (Bernstein/Sondheim song)"
  ],
  "deux": [
    "Two, in Toulouse",
    "Quatre halved",
    "Haitian couple",
    "Pierre's pair",
    "French pair",
    "French pair",
    "Anjou pair",
    "French count, maybe"
  ],
  "hier": [
    "24 heures ago",
    "Yves's yesterday",
    "Yesterday, to Yvette",
    "Yesterday: Fr.",
    "Yesterday, to Yves",
    "Yesterday, on the Yonne",
    "Yesterday: Fr."
  ],
  "xdin": [
    "Chose at the ballot box",
    "Double-crossed?",
    "Marked, as a box",
    "Marked, as a questionnaire box",
    "Marked, as a box on a test"
  ],
  "gres": [
    "E.T.S. offerings",
    "Potential hurdles for coll. students",
    "Predoctoral tests, for short",
    "Advanced deg. tests",
    "Some coll. tests",
    "Tests for coll. seniors",
    "Tests for srs.",
    "Hurdles for some srs.",
    "Coll. seniors' tests",
    "Some coll. tests",
    "Some college tests, for short",
    "Tests for some coll. seniors",
    "Some coll. tests",
    "Some college tests, for short"
  ],
  "twps": ["County divisions: Abbr.", "County divs.", "Local govt. units"],
  "ojay": [
    "Any singer of the 1973 #1 hit \"Love Train\"",
    "Any of the singers of the 1973 hit \"Love Train\"",
    "Any of the singers of the 1973 #1 hit \"Love Train\"",
    "Any member of a 1970s R & B group"
  ],
  "ozzy": ["Rocker Osbourne", "Black Sabbath singer, to fans"],
  "paix": ["Guerre's opposite", "Paris's Rue de la ___"],
  "both": [
    "Not just one or the other",
    "This and that",
    "This and that",
    "This and that",
    "Not just one or the other",
    "This and that",
    "Opposite of neither",
    "The two together",
    "This plus that",
    "Choice that avoids choosing",
    "Equivocator's choice",
    "This and that",
    "Choice for the indecisive",
    "Not just one of the two",
    "This and that",
    "This and that",
    "Not just one",
    "Opposite of neither",
    "Not just one",
    "Not just one",
    "Two out of two",
    "The two of them",
    "Two out of two",
    "This and that",
    "Not just one",
    "This and that",
    "The two together",
    "One and the other"
  ],
  "laud": [
    "Lavish praise on",
    "Celebrate",
    "Hail",
    "Praise",
    "Glorify",
    "Praise",
    "Sing the praises of",
    "Sing a paean to",
    "Hail",
    "Extol",
    "Heap praise on",
    "Give kudos",
    "Talk up",
    "Hail",
    "Extol",
    "Praise",
    "Give a glowing review",
    "Speak well of",
    "Write an ode to",
    "Praise",
    "Extol",
    "Hail",
    "Praise",
    "Lionize",
    "Tout",
    "Acclaim",
    "Sing the praises of",
    "Sing a paean to",
    "Extol",
    "Tout",
    "Glorify",
    "Extol",
    "Speak well of",
    "Panegyrize",
    "Praise",
    "Glorify",
    "Sing the praises of",
    "Praise",
    "Praise",
    "Praise",
    "Extol"
  ],
  "fabu": ["\"Terrif!\""],
  "kudu": [
    "Spiral-horned antelope",
    "African antelope with curvy horns",
    "Spiral-horned grazer",
    "Beast hunted by Hemingway in \"Green Hills of Africa\"",
    "Striped antelope",
    "Antelope with twisty horns"
  ],
  "ncar": [
    "Member of the C.S.A.",
    "Nascar Hall of Fame locale: Abbr.",
    "Tenn. neighbor",
    "Birthplace of Pres. Polk",
    "Tar Heels' state: Abbr.",
    "Biltmore Estate state: Abbr.",
    "Home of the Black Mts.",
    "Nascar Hall of Fame locale: Abbr.",
    "Duke's setting: Abbr.",
    "Raleigh's home: Abbr.",
    "Tenn. neighbor",
    "Birthplace of James K. Polk and Andrew Johnson: Abbr.",
    "Home of the Wright Brothers Natl. Memorial",
    "Home of the Natl. Hollerin' Contest",
    "Neighbor of Tenn.",
    "Neighbor of Va.",
    "Va. neighbor",
    "Raleigh's state: Abbr.",
    "Tar Heel State: Abbr.",
    "Tenn. neighbor",
    "Tenn. neighbor",
    "Va. neighbor",
    "It borders Tenn."
  ],
  "oneb": [
    "Spoiler of a perfect report card",
    "Ruiner of a perfect report card",
    "First-floor apartment, maybe",
    "First-class aisle seat on most planes",
    "First-floor apartment",
    "Seat near the front of a plane",
    "Apartment next to a super, maybe",
    "Certain ground-floor apartment",
    "First-floor apartment",
    "Apartment next to the super's, maybe",
    "Ground-floor apartment",
    "Common first floor apt. no."
  ],
  "trad": [
    "Like much folk music: Abbr.",
    "Like many folk songs: Abbr.",
    "Like much folk music: Abbr.",
    "Frequent abbr. on sheet music for folk songs",
    "Like much folk mus."
  ],
  "loma": [
    "___ Linda, Calif.",
    "___ Linda, Calif.",
    "___ Linda, Calif.",
    "___ Linda",
    "___ Linda, Calif.",
    "Casa ___, Toronto castle",
    "1989's ___ Prieta earthquake",
    "Glen Gray's \"Casa ___ Stomp\"",
    "Broad-topped hill, in the Southwest",
    "Broad-topped hill, in the Southwest",
    "___ Linda, Calif.",
    "___ Linda, Calif.",
    "___ Linda, Calif.",
    "Glen Gray & the Casa ___ Orchestra",
    "___ Linda, Calif.",
    "Glen Gray's \"Casa ___ Stomp\"",
    "___ Linda, Calif."
  ],
  "sodo": ["\"___ I\" (\"Same here\")", "\"___ I!\""],
  "skil": [
    "Brand of tools",
    "Black & Decker competitor",
    "Big name in power tools",
    "Big maker of power tools",
    "Home tool maker",
    "Black & Decker competitor"
  ],
  "pawl": [
    "Trigger-to-cylinder connection",
    "Ratcheting wheel mechanism",
    "Machine part connecting to a gearwheel",
    "Ratchet bar",
    "Bar locking a capstan in place"
  ],
  "bram": [
    "First name in horror",
    "Stoker of fear?",
    "Stoker who created Dracula",
    "Stoker who wrote \"Dracula\"",
    "Stoker who created Dracula",
    "Stoker of literature",
    "\"Dracula\" author Stoker",
    "Vampire chronicler Stoker",
    "First name in horror",
    "Author Stoker",
    "Author Stoker"
  ],
  "axil": [
    "Angle between a leafstalk and a stem",
    "Angle in botany",
    "Botanical angle",
    "Botanical angle",
    "Leaf-to-branch angle",
    "Botanist's angle",
    "Botanist's angle",
    "Leaf angle"
  ],
  "lila": [
    "___ Wallace, co-founder of Reader's Digest",
    "___ Kedrova, Oscar-winning actress for \"Zorba the Greek\"",
    "Kedrova of \"Torn Curtain\"",
    "Diane Sawyer's actual first name",
    "Actress Kedrova who won an Oscar for \"Zorba the Greek\"",
    "Lee of silent films",
    "Kedrova of \"Zorba the Greek\"",
    "Reader's Digest co-founder ___ Wallace",
    "Oscar winner Kedrova",
    "Reader's Digest co-founder Wallace",
    "Oscar winner Kedrova",
    "Oscar winner Kedrova",
    "Oscar winner Kedrova",
    "With 59-Down, Rudolph Valentino's \"Blood and Sand\" co-star",
    "Country singer McCann",
    "Oscar winner Kedrova",
    "McCann of country music",
    "Wallace of Reader's Digest",
    "Reader's Digest co-founder ___ Wallace",
    "Actress Kedrova",
    "___ Crane, Vera Miles's role in \"Psycho\"",
    "Reader's Digest co-founder Wallace",
    "Country singer McCann",
    "Wallace of Reader's Digest",
    "Oscar winner Kedrova",
    "Actress Kedrova of \"Torn Curtain\"",
    "Actress Kedrova",
    "Actress Kedrova",
    "Valentino co-star ___ Lei"
  ],
  "haid": [
    "Charles of \"Hill Street Blues\"",
    "Charles of \"Hill Street Blues\"",
    "Actor Charles of \"Hill Street Blues\""
  ],
  "flow": [
    "Go smoothly",
    "Stream",
    "Rapper's rhythm",
    "Showerhead adjustment",
    "Stream",
    "Move like water",
    "Rap component, to a rapper",
    "Go smoothly",
    "Move like lava",
    "Current",
    "Lava ___",
    "Go smoothly",
    "Ebb's partner",
    "Course",
    "Move like lava",
    "Current",
    "Run",
    "Go with it",
    "Go with the___"
  ],
  "diaz": [
    "Hollywood's Cameron",
    "Rodrigo ___ de Vivar (El Cid)",
    "Cameron of Hollywood",
    "Barrymore and Liu's \"Charlie's Angels\" co-star",
    "Cameron of \"Charlie's Angels\"",
    "Cameron of \"Charlie's Angels\"",
    "Cameron of \"Bad Teacher\"",
    "Player of the bad teacher in \"Bad Teacher\"",
    "\"Bad Teacher\" star, 2011",
    "Cameron who voiced Fiona",
    "Junot ___, 2008 Pulitzer winner for \"The Brief Wondrous Life of Oscar Wao\"",
    "Princess Fiona's voicer in \"Shrek\"",
    "She co-starred in \"Gangs of New York,\" 2002",
    "Actress Cameron",
    "Rodrigo ___ de Vivar (El Cid)",
    "Porfirio ___, president of Mexico, 1884-1911",
    "\"Gangs of New York\" actress",
    "Mexican dictator Porfirio ___",
    "\"Charlie's Angels\" co-star, 2000",
    "Longtime Mexican dictator Porfirio___",
    "Mexican dictator Porfirio"
  ],
  "sqin": [
    "Ad space meas.",
    "Abbr. in many an area measure",
    "Newspaper ad meas.",
    "Area meas.",
    "1/64 of a checkerboard, maybe: Abbr.",
    "Area meas."
  ],
  "mala": [
    "___ fide (in bad faith)",
    "___ fide",
    "___ fides (bad faith)",
    "___ fide",
    "___ fides (bad faith)",
    "Actress Powers of \"Cyrano de Bergerac\"",
    "___ fide (in bad faith)",
    "___ fide",
    "___ fide",
    "___ fides (bad faith)",
    "___ fide (in bad faith)",
    "___ fide (bad faith)",
    "___fides (bad faith)",
    "___ fide",
    "___ fides (bad faith): Lat.",
    "___ fide (in bad faith)"
  ],
  "jill": [
    "___ Stein, Green Party candidate for president in 2012 and 2016",
    "Hill climber of note",
    "Jack's partner in rhyme",
    "Jack's partner in rhyme",
    "Jack's partner",
    "One of two tumblers",
    "She tumbled after Jack",
    "Jack's partner",
    "Jack's companion, in rhyme",
    "Tumbler of rhyme",
    "TV angel Munroe",
    "Nursery rhyme girl",
    "Eikenberry of \"L.A. Law\"",
    "Hill-climber of rhyme"
  ],
  "tull": [
    "Rock's Jethro ___",
    "Rock's Jethro ___",
    "Jethro ___",
    "Rock's Jethro ___",
    "Pioneering agriculturist Jethro",
    "English agriculturist and inventor",
    "Rock's Jethro ___",
    "1960's-80's rock group Jethro ___",
    "\"As I Lay Dying\" family name"
  ],
  "zeds": [
    "Terminals at London Heathrow?",
    "British ends",
    "British terminals?",
    "London jazz duo?",
    "Sleep indicator in a British comic strip",
    "Letters at the end of the O.E.D.",
    "Reading letters from the end?",
    "London jazz duo?",
    "Jazz duo in London?",
    "Alphabet enders, to Brits",
    "Snore symbols in England",
    "British ends",
    "British finales",
    "Last section of a London telephone directory",
    "Alphabet ends, in Canada",
    "Alphabet enders, in Britain",
    "Last letters, in England",
    "Features of British jazz?",
    "London finales",
    "What jazz ends with, in England"
  ],
  "ptui": [
    "Spittoon sound",
    "Spitting sound",
    "Sound near a spittoon",
    "Cartoon \"Yuck!\"",
    "Spittoon sound",
    "Spitting sound",
    "Sound of disgust"
  ],
  "kirs": [
    "White wine cocktails",
    "Wine-and-cassis drinks",
    "Cocktails with creme de cassis",
    "White wine cocktails",
    "Some aperitifs",
    "White wine aperitifs"
  ],
  "wien": [
    "Capital of Osterreich",
    "Austria's capital, to Austrians",
    "Locale for Apfelstrudel and Sachertorte",
    "City of waltzes, to natives",
    "Austria's capital, to Austrians",
    "Austria's capital, to the locals",
    "Freud's home"
  ],
  "asan": [
    "Middle of a simile",
    "\"___ aside ...\"",
    "___ example",
    "Slippery ___ eel",
    "\"___ option ...\"",
    "Bald-eagle link",
    "Middle of a simile",
    "___ example",
    "\"___ alternative ...\"",
    "Dumb ___ ox",
    "Simile words",
    "___ example",
    "\"___ alternative ...\"",
    "___ example",
    "\"___ example ?à\"",
    "Wise ___ owl",
    "___ aside",
    "Wise ___ owl",
    "Simile center",
    "Wise ___ owl",
    "\"___ uncertain actor on the stage\" (Shakespearean sonnet start)",
    "Straight ___ arrow",
    "Hot ___ oven",
    "Simile center",
    "Simile part",
    "Straight ___ arrow",
    "Comfortable ___ old shoe",
    "Big ___ elephant",
    "Dumb ___ ox",
    "\"___ example ...\"",
    "Strong__ox",
    "Strong ___ ox",
    "Strong-ox link",
    "Wise ___ owl",
    "Straight ___ arrow",
    "Slippery ___ eel"
  ],
  "drac": [
    "Horror movie figure, in brief",
    "Transylvanian count, informally",
    "Scary sucker, for short",
    "Film figure with fangs, for short",
    "Horror movie figure, informally"
  ],
  "adot": [
    "Just ___ on the map",
    "\"Poke-___!\" (kids' book series)",
    "Just ___ on the map"
  ],
  "dede": [
    "\"___ Dinah\" (1958 hit for Frankie Avalon)",
    "Frankie Avalon's \"___ Dinah\"",
    "\"___ Dinah\" (1958 hit)",
    "\"___ Dinah\" (Frankie Avalon's first hit)",
    "\"___ Dinah\" (1958 Frankie Avalon song)",
    "\"___ Dinah\" (Frankie Avalon hit)"
  ],
  "jimi": [
    "First name at Woodstock",
    "Guitarist Hendrix",
    "First name at Woodstock",
    "Last performer at Woodstock, informally",
    "2010 New York Times best seller subtitled \"Sounds Like a Rainbow\"",
    "First name of Woodstock's last performer",
    "Rocker Hendrix",
    "\"___ Plays Monterey\" (posthumous 1986 album)",
    "Rock legend Hendrix",
    "First name at Woodstock",
    "Guitarist Hendrix",
    "First name in psychedelic rock",
    "Guitarist Hendrix",
    "Guitarist Hendrix",
    "Hendrix of 60's music",
    "First name at Woodstock",
    "Guitarist Hendrix",
    "Guitarist Hendrix",
    "Guitarist Hendrix",
    "Hendrix of 60's rock",
    "First name in 60's rock"
  ],
  "ainu": [
    "Japanese native",
    "Japanese native",
    "Japanese aborigine",
    "Japanese aborigine",
    "Aborigine of northern Japan",
    "Aborigine of Japan",
    "Hokkaido native",
    "Hokkaido native",
    "Hokkaido native",
    "Hokkaido native",
    "Hokkaido people",
    "Hokkaido people",
    "Hokkaido native",
    "Japanese aborigine",
    "Japanese aboriginal"
  ],
  "cont": [
    "Abbr. at the bottom of a page of text",
    "More to come: Abbr.",
    "End-of-page abbr.",
    "Afr. or Eur.",
    "Abbr. at the bottom of a page",
    "N.A., e.g.",
    "Serial abbr."
  ],
  "thom": [
    "Singer Yorke of Radiohead",
    "Fashion designer Browne",
    "Radiohead frontman Yorke",
    "Radiohead head Yorke",
    "Hartmann of talk radio",
    "First name in footwear",
    "First name in footwear",
    "___ McAn shoes",
    "Yorke of rock's Radiohead",
    "Radiohead frontman Yorke",
    "McAn of footwear",
    "___ Yorke, lead singer of Radiohead",
    "___ McAn shoes",
    "___ McAn shoes",
    "Anglo-American poet Gunn",
    "___ McAn shoes",
    "___ McAn shoes",
    "Relative of Geo. and Robt."
  ],
  "etic": ["Suffix with diet", "Suffix with diet"],
  "ansa": [
    "Archaeological handle",
    "Vase handle",
    "Jug handle, in archaeology",
    "Jug handle, in archaeology",
    "Looped handle, in archaeology",
    "Looped handle, in archaeology",
    "Looped vase handle",
    "Handle, in archaeology",
    "Archaeological handle",
    "Novelist Tina McElroy ___",
    "Looped handle, in archaeology",
    "Vase handle",
    "Handle: Lat.",
    "Looped handle",
    "Vase's handle",
    "Looped handle",
    "Italian news agency",
    "Amphora handle",
    "Archeologist's handle",
    "Looped handle",
    "Amphora handle",
    "Anatomical loop"
  ],
  "curs": [
    "Mean, lowdown sorts",
    "Base men?",
    "Some strays",
    "Mean types",
    "Mongrel dogs",
    "Junkyard dogs",
    "Growlers",
    "82-Across denizens",
    "Strays, perhaps",
    "Heels",
    "Junkyard dogs",
    "Meanies",
    "Junkyard dogs"
  ],
  "hoya": [
    "Georgetown athlete",
    "Georgetown player",
    "College athlete wearing blue and gray",
    "Georgetown athlete",
    "Washington athlete",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown hoopster",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown player",
    "Georgetown athlete",
    "Georgetown athlete",
    "Georgetown hoopster",
    "Georgetown hoopster",
    "Boxer Oscar De La ___",
    "Georgetown athlete",
    "Georgetown player",
    "Georgetown athlete"
  ],
  "zonk": [
    "Crash, with \"out\"",
    "Prize you don't want on \"Let's Make a Deal\"",
    "Fall dead asleep, with \"out\"",
    "Pass (out)",
    "Fall into a 32-Across, with \"out\"",
    "Hit the hay hard, with \"out\"",
    "Stupefy, slangily",
    "Knock out"
  ],
  "bada": ["\"___ bing!\""],
  "rcmp": [
    "Maintainer of law and order north of the U.S.",
    "North-of-the-border force: Abbr.",
    "Force in red uniforms: Abbr.",
    "Forces on horses: Abbr.",
    "Its uniform includes a red serge tunic and a Stetson: Abbr.",
    "Dudley Do-Right's org.",
    "Dudley Do-Right's org.",
    "Law grp.",
    "Dudley Do-Right's org.",
    "Renfrew's org., in old radio",
    "Noted law enforcement grp.",
    "Dudley Do-Right's org.",
    "Forces on horses: Abbr.",
    "Mounties: Abbr."
  ],
  "bust": [
    "Classic sculpture",
    "Go over in blackjack",
    "14-Across's result",
    "Complete dud",
    "Exceed 21 in twenty-one",
    "Total failure",
    "Boom's opposite on Wall Street",
    "Boom follower, maybe",
    "Abject failure",
    "Flop",
    "Statuary item",
    "Pedestal topper",
    "Narc's collar"
  ],
  "hojo": [
    "Iconic chain restaurant, informally",
    "Once-popular roadside chain, familiarly",
    "Orange-roofed establishment, in brief",
    "Breakfast spot, briefly",
    "\"28 Flavors\" chain, for short",
    "Breakfast spot, briefly",
    "Orange-roofed inn, for short",
    "Holiday Inn competitor, for short",
    "Chain hotel, for short",
    "Orange-roofed hotel, for short",
    "Chain hotel, for short",
    "Popular roadside chain, for short"
  ],
  "erei": [
    "\"... ___ saw Elba\"",
    "\"Fly ___ spurn thee ...\" : Shelley",
    "\"___ learn love, I'll practise to obey\": Shak.",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... kissed thee ___ killed thee\": Shak.",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"A special laurel ___ go\": Whitman",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\" ... ___ saw Elba\"",
    "\"I'll speak a prophecy ___ go\": Shak.",
    "\"?à ___ saw Elba\"",
    "\"Able was I ___ ?à\"",
    "\"I kiss'd thee ___ kill'd thee\": Othello",
    "\"?à ___ saw Elba\"",
    "\"?à ___ saw Elba\"",
    "\"I'll speak a prophecy ___ go\": Shak.",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"Able was I ___ saw Elba\"",
    "\"A special laurel ___ go\": Whitman",
    "\"I'll speak a prophecy ___ go\": Shak.",
    "\"___ Sleep, for Every Favor\" (old hymn)",
    "\"... ___ saw Elba\"",
    "\"I kiss'd thee ___ kill'd thee\": Othello",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"... ___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "Riley's \"___ Went Mad\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "James Whitcomb Riley's \"___ Went Mad\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\"Dear mother Ida, harken ___ die\" (Tennyson refrain)",
    "\"...___ saw Elba\"",
    "\"I kissed thee ___ kill'd thee\": Othello",
    "\"...___saw Elba\"",
    "\"...___saw Elba\"",
    "\"...___saw Elba\"",
    "\"...___ saw Elba\"",
    "\"I hope to see London once ___ die\": Shak.",
    "\"...___ saw Elba\"",
    "\"...___ saw Elba\"",
    "Palindrome part",
    "\"... ___ saw Elba\"",
    "\"...___ saw Elba\"",
    "\".... ___saw Elba\"",
    "\"...___ saw Elba\""
  ],
  "tren": [
    "Autobus alternative",
    "Mode of transportacion",
    "Mexican transportacion",
    "Autobus alternative",
    "Tampico track transport",
    "One way around Spain",
    "It's pulled by una locomotora"
  ],
  "lalo": [
    "Contemporary of Saint-Saens",
    "Composer Schifrin",
    "Film composer Schifrin",
    "Composer of the opera \"Fiesque\"",
    "\"Le Roi d'Ys\" composer",
    "Composer Schifrin",
    "Score creator Schifrin",
    "French composer Edouard",
    "\"Le Roi d'Ys\" composer",
    "\"Fiesque\" composer",
    "Four-time Grammy winner Schifrin",
    "\"Rhapsodie norvegienne\" composer",
    "Composer Edouard",
    "Film composer Schifrin",
    "Film composer Schifrin",
    "Composer Schifrin",
    "\"Le Roi d'Ys\" composer",
    "Film composer Schifrin",
    "\"Symphonie espagnole\" composer",
    "Composer Schifrin",
    "\"Le Roi d'Ys\" composer",
    "Composer Schifrin",
    "\"Symphonie Espagnole\" composer",
    "Composer Schifrin",
    "\"Le Roi d'Ys\" composer",
    "\"Symphonie espagnole\" composer",
    "\"Symphonie espagnole\" composer",
    "\"Le roi d'Ys\" composer",
    "Composer Schifrin",
    "Gounod contemporary",
    "\"Le Roi d'Ys\" composer",
    "\"Symphonie espagnole\" composer",
    "Composer Schifrin",
    "Composer Schifrin",
    "\"Fiesque\" composer"
  ],
  "abox": [
    "As dumb as ___ of rocks",
    "\"Life is like ___ of chocolates\"",
    "In ___ (confined)"
  ],
  "reqd": [
    "Mandatory: Abbr.",
    "Necessary: Abbr.",
    "Not optional: Abbr.",
    "Not opt.",
    "Necessary: Abbr."
  ],
  "enow": [
    "Plenty, once",
    "Sufficient, to a bard",
    "Sufficient, to a bard",
    "\"Stoppeth it!\"?",
    "Sufficient, for Shakespeare",
    "Sufficient, in \"Macbeth\"",
    "Sufficiently, in poetry",
    "Poetic \"plenty\"",
    "Plenty, to a poet",
    "Adequate, in verse",
    "Sufficient, once",
    "Adequate, old-style",
    "Sufficient, once",
    "Sufficient, for Shakespeare",
    "\"... there are evils ___ to darken all his goodness\": Shak.",
    "Sufficient, old-style",
    "Sufficient, once",
    "Adequate, old-style",
    "Adequate, once",
    "Sufficient, in verse",
    "Plenty, old-style",
    "Sufficient, in poetry",
    "Plenty, to a poet",
    "Sufficient, old-style",
    "Ample, poetically",
    "This once was sufficient",
    "Aplenty, once",
    "Sufficient, once",
    "Sufficient, once",
    "Sufficiently, in poetry",
    "Sufficient, old-style",
    "Sufficient for Shakespeare",
    "Sufficient, once",
    "Sufficient, in verse",
    "Adequate, once",
    "Poetically sufficient?",
    "Sufficient, once",
    "Aplenty, in the past",
    "Plenty, to FitzGerald",
    "Plenty, old-style",
    "Plenty, previously",
    "Adequate, way back",
    "Plenty, to a bard",
    "Sufficient, once"
  ],
  "iiii": [
    "Rare grandfather clock numeral",
    "Peculiar sundial numeral",
    "It's 180?? from X",
    "Peer group?",
    "4, on a sundial",
    "Sundial hour",
    "Late afternoon on a sundial",
    "Four on a sundial"
  ],
  "ucsd": [
    "State sch. on the Pacific Coast",
    "The Tritons of the N.C.A.A.",
    "La Jolla campus, briefly",
    "Golden State campus inits.",
    "Coll. in La Jolla",
    "Golden State sch."
  ],
  "whod": ["\"___ have thought ...\"", "\"___ have thought ...?\""],
  "girt": [
    "Ready for action",
    "Enclosed, old-style",
    "Encircled",
    "Belted",
    "Hemmed in",
    "Braced",
    "Surrounded",
    "Belted"
  ],
  "smew": [
    "Duck variety",
    "Eurasian duck",
    "Eurasian duck",
    "Duck that nests in tree hollows",
    "Cousin of a goldeneye",
    "Northern duck",
    "Goldeneye relative",
    "Eurasian duck",
    "Old World duck",
    "Old World duck",
    "Eurasian duck",
    "Old World duck",
    "Merganser relative",
    "Old World duck",
    "Eurasian diving duck",
    "Duck with a large white crest",
    "Smallest of the mergansers",
    "Small merganser",
    "Eurasian duck",
    "Merganser",
    "Fish-eating duck",
    "Saw-billed duck",
    "Diving duck",
    "Eurasian duck",
    "Diving duck"
  ],
  "isok": ["Emerges unscathed"],
  "wmds": [
    "Iraq War concerns, for short",
    "Iraq war concerns, for short",
    "Iraq war concerns, for short",
    "Saddam reportedly hid them, briefly"
  ],
  "ofit": [
    "\"Make ___ what you will\"",
    "\"What ___?\"",
    "The wonder ___ all",
    "\"What ___?\" (snippy reply)",
    "\"What ___?\"",
    "\"Well, what ___?!\"",
    "\"Think nothing ___\"",
    "\"Just for the heck ___...\"",
    "\"Just for the thrill ___\"",
    "The half ___",
    "Just for the thrill ___",
    "\"Wanna make something ___?\"",
    "\"Make the most ___\""
  ],
  "mita": [
    "Big name in photocopiers, once",
    "Classic name in photocopiers",
    "Former Xerox competitor",
    "Copier giant absorbed by the Kyocera Corporation",
    "Classic name in copiers",
    "Certain copier",
    "Canon alternative"
  ],
  "reys": [
    "Co-authors Margret and H. A.",
    "\"___ Theme,\" tune from \"Star Wars: The Force Awakens\"",
    "Margret and Hans, who created Curious George",
    "Curious George's creators",
    "Margret and H. A., creators of Curious George",
    "Curious George's creators",
    "Husband-and-wife creators of Curious George",
    "Baseball's Sanchez and Ordonez",
    "Baseball's Ordonez et al.",
    "Actor Fernando et al.",
    "Actors Alejandro and Fernando",
    "Alejandro and Fernando",
    "\"Curious George\" co-authors",
    "Actor Fernando et al.",
    "Alejandro and Fernando"
  ],
  "usfl": [
    "Defunct org. in which Donald Trump owned the New Jersey Generals",
    "Defunct sports org. with the Denver Gold and Chicago Blitz",
    "Group that included the L.A. Express",
    "Org. for the Denver Gold and Chicago Blitz",
    "Defunct gridders' org.",
    "Defunct sports grp."
  ],
  "jawa": [
    "\"Star Wars\" nomad",
    "Resident of Tatooine in \"Star Wars\"",
    "Tatooine race in the \"Star Wars\" saga"
  ],
  "caws": [
    "Rookery cries",
    "Corvine calls",
    "Crows' cries",
    "Crows' cries",
    "Hoarse cries",
    "Ravens' cries",
    "Cornfield sounds",
    "Calls in the field",
    "Harsh cries",
    "Calls in a field",
    "Field calls",
    "Crows' cries",
    "Field calls",
    "Unmelodic sounds",
    "Sounds from a cornfield",
    "Avian sounds",
    "Raven sounds",
    "Crow calls",
    "Cornfield sounds",
    "As the crow cries",
    "Ravens' ravin's?",
    "Bird calls"
  ],
  "tema": [
    "Motif for Verdi or Monteverdi",
    "Melodic subject, in music",
    "Subject of una sinfonia",
    "Melodic subject, in music",
    "Canzone melody",
    "Main idea of un libro",
    "Subject of a composition",
    "Puccini melody, e.g.",
    "Part of any Verdi composition",
    "Central idea, in music",
    "Subject, in Spain",
    "Subject, to Puccini",
    "Topic: Sp."
  ],
  "pola": [
    "Actress Negri of the silents",
    "Silents actress Negri",
    "Film legend Negri",
    "___ Debevoise, Marilyn Monroe's \"How to Marry a Millionaire\" role",
    "Vamp Negri",
    "Negri of silent films",
    "Silent film vamp Negri",
    "Actress Negri",
    "Old-time actress Negri",
    "\"Passion\" star Negri",
    "Negri of the silent screen",
    "Negri of silents",
    "Vamp Negri",
    "First name in silents"
  ],
  "rcpt": [
    "Rebate requirement, at times: Abbr.",
    "A.T.M. printout: Abbr.",
    "Tkt. stub, e.g.",
    "Proof of purchase: Abbr.",
    "Return request: Abbr.",
    "Gift exchange requirement: Abbr.",
    "Sales slip: Abbr.",
    "\"Bill paid\" paper: Abbr."
  ],
  "rupp": [
    "Kentucky's ___ Arena",
    "___ Arena, home to the Kentucky Wildcats",
    "Lexington's ___ Arena",
    "Lexington's ___ Arena",
    "Coach Adolph in the Basketball Hall of Fame",
    "University of Kentucky's ___ Arena",
    "The Baron, of college basketball",
    "Winningest N.C.A.A. basketball coach",
    "Legendary basketball coach Adolph",
    "Basketball coach Adolph",
    "Legendary Wildcats coach"
  ],
  "raes": [
    "Actress Issa and others",
    "Pulitzer winner Armantrout and others",
    "Actress Charlotte and others",
    "Actress Charlotte and explorer John",
    "Charlotte and others",
    "Pulitzer-winning poet Armantrout and others",
    "Explorer John and actress Charlotte",
    "Actress Charlotte and others",
    "Actress Charlotte and explorer John",
    "Explorer John and others",
    "Explorer John and others",
    "Explorer John and others",
    "Actress Charlotte et al.",
    "Victoria Island explorer and family",
    "Charlotte and others",
    "Actress Charlotte et al.",
    "Explorer John et al.",
    "Charlotte and others",
    "Charlotte and others",
    "Actress Charlotte et al."
  ],
  "lsts": [
    "D-Day vessels: Abbr.",
    "D-Day carriers: Abbr.",
    "D-Day vessels, for short",
    "Craft for amphibious operations, for short",
    "W.W. II transports: Abbr.",
    "D-Day transports: Abbr.",
    "Normandy vessels of '44",
    "W.W. II naval vessels: Abbr.",
    "Military transports: Abbr.",
    "D-Day refuges for the wounded, for short",
    "D-Day carriers: Abbr.",
    "W.W. II craft",
    "D-Day vessels",
    "D-Day vessels: Abbr.",
    "D-Day carriers: Abbr.",
    "D-Day sights: Abbr.",
    "W.W. II vessels",
    "D-Day craft",
    "D-Day transports: Abbr.",
    "Marine carriers in W.W. II",
    "Transports in W.W. II: Abbr.",
    "D-Day craft: Abbr.",
    "D-Day craft",
    "D-Day craft: Abbr.",
    "Vessels seen in \"Saving Private Ryan\": Abbr.",
    "W.W. II ships: Abbr.",
    "Mil. transports",
    "W.W. II transports: Abbr.",
    "Amphibious vehicles, for short",
    "W.W. II landing craft",
    "W.W. II assault craft",
    "D-Day ships: Abbr.",
    "Beach-storming vessels: Abbr."
  ],
  "gifs": [
    "Some looping online animations",
    "Loopy little films?",
    "Online pics that often move",
    "Bitmap images",
    "Some PC image files"
  ],
  "lams": [
    "Flees",
    "Flights",
    "Fugitives' flights",
    "Skedaddles",
    "Quick flights",
    "Flights",
    "Escapes, slangily",
    "Clears out",
    "Thrashes",
    "Takes off",
    "Gets out of Dodge",
    "Takes a powder",
    "Hightails it",
    "Thrashes",
    "Flees",
    "Flees",
    "Whales the tar out of",
    "Getaways",
    "Hightails",
    "Wallops",
    "Skedaddles",
    "Beats it"
  ],
  "wedo": [
    "Shared vow",
    "\"This Is How ___ It\" (1995 #1 hit)",
    "\"What can ___ to help?\"",
    "Words of collective consent"
  ],
  "lapp": [
    "Certain Finn",
    "Nomadic northerner",
    "Arctic resident",
    "Reindeer herder",
    "Northern Scandinavian",
    "Nordic native",
    "Reindeer herder",
    "Arctic herder",
    "Dweller above the Arctic Circle",
    "Northern Scandinavian",
    "Dweller on the Arctic Circle",
    "Finno-Ugric tongue",
    "Reindeer herder",
    "Scandinavian native",
    "Relative of Finnish",
    "Arctic native",
    "European nomad",
    "Reindeer raiser",
    "Arctic European",
    "Finnic language",
    "Arctic native",
    "Kola Peninsula inhabitant",
    "Arctic native",
    "Nordland resident",
    "Person from the North",
    "Reindeer herder",
    "Reindeer herder",
    "One who rides in a pulka",
    "Reindeer herder",
    "Arctic dweller",
    "Northern nomad",
    "Land of the Midnight Sun dweller",
    "Reindeer herder",
    "Reindeer herder",
    "Reindeer herder",
    "Arctic native"
  ],
  "rudi": [
    "Designer Gernreich who invented the monokini",
    "Fashion designer Gernreich",
    "Newswoman Bakhtiar",
    "Designer Gernreich",
    "Designer Gernreich",
    "Fashion designer Gernreich",
    "Designer Gernreich",
    "Designer Gernreich",
    "Gernreich of fashion",
    "Gernreich who invented the monokini",
    "Designer Gernreich",
    "Designer Gernreich",
    "1970's A's All-Star Joe",
    "Fashion designer Gernreich",
    "Designer Gernreich",
    "Designer Gernreich"
  ],
  "mtns": [
    "Range units: Abbr.",
    "Whitney and Shasta, for two: Abbr.",
    "Appalachians, e.g.: Abbr.",
    "Range parts: Abbr.",
    "Cascades, e.g.: Abbr.",
    "Himalayas, e.g.: Abbr.",
    "Range parts: Abbr.",
    "The Dolomites, e.g.: Abbr.",
    "Chain \"links\": Abbr.",
    "Where chamois and snow leopards live: Abbr.",
    "Range units: Abbr.",
    "Rockies, e.g.: Abbr.",
    "Chain links?: Abbr.",
    "Much of Colo.",
    "Range: Abbr.",
    "People may take a pass on them: Abbr.",
    "Tenn.'s Cumberland ___",
    "Range: Abbr.",
    "Range units: Abbr.",
    "Calif.'s Sierra Nevadas, e.g.",
    "Atlases, e.g.: Abbr.",
    "Range: Abbr.",
    "Range: Abbr."
  ],
  "megs": [
    "File size units, informally",
    "What an 18-Across's capacity is measured in, briefly",
    "Drive units, briefly",
    "Computer capacity, in brief",
    "Computer capacity, informally",
    "Modern storage units, briefly",
    "Digital camera units",
    "Modern storage units, briefly",
    "Computer capacity units, briefly",
    "Capacity units, these days",
    "Computer drive capacity, in slang"
  ],
  "inas": [
    "Lead-in to much",
    "Lead-in to much",
    "Bring ___ a third party",
    "What much can follow",
    "Sworn ___ (officially given the role of)",
    "Much precedent?",
    "Much may come after it",
    "Old actresses Claire and Balin",
    "Much often follows it",
    "\"Much\" preceder",
    "Lead-in to much",
    "Much may follow it",
    "Much may follow it",
    "Actresses Balin and Claire",
    "\"Much\" preceder",
    "Claire and Balin",
    "Actress Claire et al.",
    "Poet Coolbrith et al.",
    "Poet Coolbrith et al.",
    "Poet Coolbrith and others"
  ],
  "dscs": [
    "Army awards just below Medals of Honor: Abbr.",
    "U.S. Army decorations, in brief",
    "Mil. decorations",
    "Mil. awards",
    "Mil. awards",
    "Mil. awards",
    "Mil. awards",
    "Mil. medals",
    "Mil. awards",
    "Some mil. awards",
    "Mil. honors",
    "Mil. medals",
    "U.S. Army medals"
  ],
  "inor": [
    "\"Are you ___ out?\"",
    "\"___ out?\" (question to a pet)",
    "\"___ out?\"",
    "\"Are you ___ out?\"",
    "\"___ out?\" (poker query)",
    "\"___ out?\"",
    "\"___ out?\" (question to a pet)",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "\"Get ___ get out\"",
    "\"Neither ___ anyone else ?à\"",
    "\"Are you ___ out?\"",
    "\"___ out?\"",
    "\"Who has seen the wind? Neither ___ you\": Rossetti",
    "?ôAre you ___ out??ö",
    "\"___ out?\"",
    "\"___ out?\" (pet's choice)",
    "\"___ out?\" (dealer's query)",
    "\"___ out?\" (dealer's query)",
    "\"Are you ___ out?\"",
    "\"___ out?\" (dealer's query)",
    "\"___ out?\"",
    "\"Are you ___ out?\"",
    "\"Are you ___ out?\"",
    "___out? (pet's choice)",
    "With 69-Down, a pet's choice"
  ],
  "reve": [
    "Dream: Fr.",
    "Topic de Freud",
    "\"Le ___,\" Picasso painting of his sleeping mistress",
    "Dream, in Dijon",
    "Vision de nuit",
    "French dream",
    "Enfant's dream",
    "Dream of Debussy",
    "Debussy's dream",
    "Zola novel, with \"Le\""
  ],
  "bldg": [
    "Edifice: Abbr.",
    "Edifice: Abbr.",
    "Whse., e.g.",
    "Palace or prison, e.g.: Abbr."
  ],
  "caro": [
    "Italian dear",
    "Beloved, in \"Rigoletto\"",
    "Dear one, Italian-style",
    "L.B.J. biographer Robert ___",
    "Verdi's \"___ nome\"",
    "\"Beloved,\" in operas",
    "One loved in 48-Down",
    "Italian sweet?",
    "Recipient of a lettera amorosa",
    "Italian sweetheart",
    "Robert who wrote \"The Power Broker\"",
    "Italian term of endearment",
    "Beloved, in \"Rigoletto\"",
    "Dear, to Donizetti",
    "\"The Power Broker\" author Robert",
    "L.B.J. biographer Robert A. ___",
    "Dear one, in Italy",
    "\"O mio babbino ___\" (Puccini aria)",
    "Dear, to Dante",
    "Beloved, in \"Rigoletto\"",
    "Italian \"dear\"",
    "Abstract sculptor Sir Anthony",
    "Dear, in Tuscany",
    "Biographer Robert",
    "L.B.J. biographer Robert"
  ],
  "ratt": [
    "Heavy-metal band with the hit albums \"Out of the Cellar\" and \"Infestation\"",
    "Heavy metal band with 1980s hits"
  ],
  "sheb": [
    "Wooley with the 1958 #1 hit \"The Purple People Eater\"",
    "Wooley who sang \"The Purple People Eater\"",
    "Wooley of \"Rawhide\"",
    "Wooley of \"Rawhide\"",
    "Western actor Wooley",
    "\"The Purple People Eater\" singer ___ Wooley",
    "Wooley with the 1958 #1 hit \"The Purple People Eater\"",
    "C&W singer Wooley",
    "Wooley of 50's pop",
    "Actor/singer Wooley",
    "\"The Purple People Eater\" singer Wooley"
  ],
  "wits": [
    "Mental acuity",
    "Good things to have about you in an emergency",
    "Something to match",
    "Comedians",
    "Resourcefulness",
    "Resourcefulness",
    "Jokesters",
    "Mental acuity",
    "Quotable types",
    "Mental acuity",
    "Mental keenness",
    "Ingenuity",
    "Good thing to have about you",
    "Cards",
    "Clever ones",
    "It's good to have these about you",
    "Skilled punsters",
    "Composure",
    "Cleverness",
    "Ingenuity",
    "Algonquin Round Table members",
    "Some live by them"
  ],
  "sehr": [
    "Very: Ger.",
    "\"___ gut\"",
    "Ilse's \"very\"",
    "Very, in Wurzburg",
    "Very: Ger.",
    "Very, in Bonn",
    "\"___ gut\" (German praise)",
    "Very, in Bonn"
  ],
  "hada": [
    "\"If I ___ penny for every ...\"",
    "\"I ___ thought\"",
    "\"If I Only ___ Brain\"",
    "\"If I ___ nickel ...\"",
    "\"Humpty Dumpty ___ great fall\"",
    "\"We ___ ball!\"",
    "\"If I ___ hammer ...\"",
    "\"... ___ great fall\"",
    "\"Mary ___ little lamb\"",
    "Dionne Warwick's \"Anyone Who ___ Heart\"",
    "___ fit (blew up)",
    "\"If I ___ hammer...\""
  ],
  "gide": [
    "Andre ___, 1947 Literature Nobelist",
    "Nobel-winning author Andre",
    "\"Corydon\" author",
    "Nobel laureate between Hesse and Eliot",
    "French author who co-founded La Nouvelle Revue Francaise",
    "\"The Immoralist\" author",
    "\"Travels in the Congo\" writer",
    "1947 French Nobelist Andre",
    "French novelist Andre",
    "\"The Immoralist\" author",
    "1947 Literature Nobelist Andre",
    "France's 1947 Literature Nobelist",
    "French novelist Andre",
    "\"If It Die...\" autobiographer",
    "?ôThe Immoralist?ö author",
    "French writer Andre",
    "\"The Counterfeiters\" author"
  ],
  "tomy": [
    "Words before chagrin or surprise",
    "Popular handheld game company",
    "\"Skip ___ Lou...\"",
    "Popular toy company"
  ],
  "hoag": [
    "Judith of TV's \"Nashville\"",
    "Romance novelist Tami",
    "Romance novelist Tami",
    "Best-selling author Tami"
  ],
  "degs": [
    "M.B.A. and Ph.D., for two",
    "Univ. offerings",
    "M.D. and Ph.D.",
    "Univ. awards",
    "B.A. and B.S., e.g.",
    "What grads earn: Abbr."
  ],
  "dubs": [
    "Puts into English, say, as movie dialogue",
    "Nicknames",
    "Nicknames",
    "Puts words in the mouth of?",
    "Nicknames",
    "Coins a nickname for",
    "Recording studio tasks",
    "Puts a label on",
    "Makes tracks?"
  ],
  "inis": [
    "\"Last one ___ a rotten egg!\"",
    "\"Last one ___ a rotten egg!\"",
    "\"Last one ___ ...\"",
    "\"Last one ___ ?à\"",
    "\"Last one ___ a rotten egg!\""
  ],
  "lomb": [
    "Bausch & ___ (lens maker)",
    "Bausch & ___ (eye-care brand)",
    "Bausch & ___ (lens maker)",
    "Bausch & ___ (lens maker)"
  ],
  "enke": [
    "Speed skater Karin who won eight Olympic medals",
    "Three-time speed skating gold medalist Karin",
    "Speed-skating gold medalist Karin",
    "Three-time speed skating gold medalist Karin"
  ],
  "orth": [
    "Straight: Prefix",
    "Straight: Prefix",
    "Vertical: Prefix",
    "Straight: Prefix",
    "Straight: Prefix"
  ],
  "asno": [
    "Come ___ surprise",
    "Come ___ surprise",
    "Come ___ surprise",
    "___ other (matchlessly)",
    "___ other (uniquely)",
    "___ other (uniquely)",
    "___ other (uniquely)",
    "Come ___ surprise",
    "___ other (matchlessly)",
    "\"?à perhaps comes ___ surprise ?à\"",
    "Come ___ surprise",
    "\"It will come ___ surprise ?à\"",
    "\"This should come ___ surprise\"",
    "\"It comes ___ surprise\"",
    "\"It will come ___ surprise\""
  ],
  "hick": [
    "Hayseed",
    "Rube",
    "Bumpkin",
    "Hayshaker",
    "Country bumpkin",
    "Bumpkin",
    "Bumpkin",
    "Rustic",
    "Bumpkin",
    "Bumpkin",
    "Provincial",
    "Rube"
  ],
  "maxx": ["T. J. ___ (department store chain)"],
  "ylem": [
    "Primordial universe matter",
    "Proto-matter of the universe",
    "Matter in the Big Bang theory",
    "Proto-matter from which the universe was made",
    "Protomatter of the universe",
    "Substance from which the universe was created"
  ],
  "mmes": [
    "Lille women: Abbr.",
    "Fr. ladies",
    "Sras., across the Pyrenees",
    "Hugo's Thenardier and others: Abbr.",
    "Some Eur. ladies",
    "Sras. : Mexico :: ___ : France",
    "Ladies of Versailles: Abbr.",
    "Fr. ladies",
    "Mlles. after marriage",
    "Frauen, across the border: Abbr.",
    "Fr. ladies",
    "Messieurs' mates: Abbr.",
    "\"Dangerous Liaisons\" ladies: Abbr.",
    "Strasbourg ladies: Abbr.",
    "Ladies of Versailles: Abbr.",
    "Parisian ladies: Abbr.",
    "Sras. across the Pyrenees",
    "Fr. ladies"
  ],
  "witt": [
    "Alicia of \"Urban Legend,\" 1998",
    "Katarina ___, two-time Olympic gold-medalist skater",
    "Olympic skater Katarina",
    "1984 perfect game pitcher Mike",
    "Skater Katarina",
    "Gold medalist in Sarajevo and Calgary",
    "1984-88 skating gold medalist",
    "1984-88 Olympic skating champ",
    "1984-88 skating gold medalist",
    "1984-88 Olympic figure-skating gold medalist",
    "Two-time skating gold medalist",
    "Skater Katarina"
  ],
  "crts": [
    "Old TV screens, for short",
    "Old PC monitors",
    "Analog oscilloscope parts: Abbr.",
    "Some displays, briefly",
    "Some touch screens, for short",
    "Plasma alternatives, briefly",
    "PC screens, for short",
    "PC screens",
    "Some touch screens, briefly",
    "Electronic monitors, for short"
  ],
  "inup": [
    "___ to one's ears",
    "___ to one's neck",
    "___ to one's ears",
    "___ to one's ears"
  ],
  "cito": [
    "___ Gaston, first African-American manager to win a World Series",
    "Baseball's ___ Gaston"
  ],
  "tiea": [
    "\"___ yellow ribbon ...\"",
    "\"___ yellow ribbon ...\"",
    "\"___ yellow ribbon ...\"",
    "How to ___ knot (Boy Scout's lesson)",
    "___ knot",
    "\"___ yellow ribbon ...\"",
    "\"___ yellow ribbon ...\"",
    "\"___ yellow ribbon...\"",
    "\"___ yellow ribbon...\""
  ],
  "evas": [
    "Mendes and Longoria",
    "Spacewalks, in NASA lingo",
    "NASA spacewalks, in brief",
    "Spacewalks and moonwalks, briefly",
    "Actresses Mendes and Longoria",
    "Gabor and Longoria Parker",
    "NASA spacewalks",
    "\"Housewife\" Longoria et al.",
    "Peron and Gabor",
    "Sculptor Hesse and others",
    "Braun and Gabor",
    "Spacewalks, for short",
    "Gabor and Peron",
    "Gabor and Peron",
    "Peron and namesakes",
    "Stowe girl and others",
    "Peron and others",
    "Braun and others",
    "Miss Gabor et al.",
    "Peron et al.",
    "Le Gallienne and others"
  ],
  "ezio": [
    "Pinza of \"South Pacific\"",
    "Opera singer Pinza",
    "Opera singer Pinza",
    "Pinza of \"South Pacific\"",
    "Basso Pinza",
    "Basso Pinza",
    "Opera star Pinza",
    "Basso Pinza",
    "Met singer Pinza",
    "Opera singer Pinza",
    "Pinza of \"South Pacific\"",
    "\"The Silence of the Hams\" director Greggio",
    "Singer Pinza",
    "Basso Pinza",
    "Pinza of \"South Pacific\"",
    "Basso Pinza",
    "Operatic singer Pinza",
    "Pinza of \"South Pacific\"",
    "Mary's \"South Pacific\" co-star",
    "Basso Pinza",
    "Handel opera",
    "Basso Pinza",
    "Basso Pinza",
    "Mary's \"South Pacific\" co-star",
    "Basso Pinza",
    "Pinza of the Met",
    "Pinza of \"South Pacific\"",
    "Singer Pinza",
    "Opera singer Pinza",
    "Basso Pinza",
    "Pinza of \"South Pacific\"",
    "Handel opera",
    "Basso Pinza",
    "Singer Pinza",
    "Basso Pinza",
    "Mary's \"South Pacific\" co-star"
  ],
  "rive": [
    "Split",
    "Tear apart",
    "Break apart",
    "Tear",
    "Split",
    "Rend",
    "Paris's ___ Gauche",
    "Bank of Paris",
    "Split",
    "French bank",
    "Tear apart",
    "Split",
    "Split",
    "Split",
    "Bank of Paris",
    "Split",
    "Tear",
    "Gauche introduction?",
    "Tear apart",
    "Split",
    "Cleave",
    "Cleave",
    "Use a cleaver"
  ],
  "nobu": [
    "International fusion restaurant chain",
    "Celebrity chef Matsuhisa",
    "Restaurant chain founded by a celebrity chef",
    "Celebrity chef Matsuhisa",
    "___ Matsuhisa, celebrity chef and restaurateur"
  ],
  "alea": [
    "\"___ iacta est\" (\"The die is cast\")",
    "Greek goddess Athena ___",
    "Epithet of Athena",
    "\"Jacta est ___\" (\"The die is cast\")",
    "Epithet of Athena",
    "Epithet of Athena",
    "Epithet for Athena",
    "Epithet of Athena"
  ],
  "cebu": [
    "Pacific island Magellan visited in 1521",
    "Philippine island",
    "Island visited by Magellan, 1521",
    "Philippine island or its seaport",
    "Philippine island",
    "Oldest Spanish city in the Philippines",
    "Philippine isle",
    "Philippine island"
  ],
  "lesh": [
    "Grateful Dead bassist Phil",
    "Grateful Dead bassist Phil",
    "Grateful Dead bassist Phil"
  ],
  "amys": [
    "All-vegetarian frozen food brand",
    "Grant and Adams",
    "___ Kitchen (frozen food brand)",
    "Schumer and Poehler",
    "Tan and others",
    "Writer Tan and singer Grant",
    "Winehouse and Sedaris",
    "___ Kitchen (organic frozen food company)",
    "Grant and Carter",
    "Singer Grant and others",
    "Author Tan and others",
    "Writers Lowell and Tan",
    "Actress Poehler and others",
    "Carter and Adams",
    "Lowell and Tan",
    "Carter and Grant",
    "\"___ View\" (1999 Broadway play)",
    "Sondheim's \"Multitudes of ___\"",
    "The Carters' daughter and others",
    "Tan and others",
    "Vanderbilt and others",
    "Vanderbilt and Grant",
    "Tan and others",
    "Tan and Irving",
    "Lowell and Tan"
  ],
  "doff": [
    "Remove, as a hat",
    "Tip, as a hat",
    "Remove, in a way",
    "Lift in greeting",
    "Remove",
    "Remove out of respect",
    "Remove, as a hat",
    "Tip politely",
    "Tip, as a hat",
    "Remove",
    "Tip politely",
    "Remove, as a hat",
    "Tip, in a way",
    "Remove, as a hat",
    "Tip",
    "Remove",
    "Tip, in a way",
    "Remove",
    "Flip one's lid?"
  ],
  "rowa": [
    "Seats by the orchestra pit, perhaps",
    "Prime seating area",
    "Prime seating spot"
  ],
  "ords": [
    "Municipal regs.",
    "Municipal laws: Abbr.",
    "Municipal regs.",
    "Municipal laws: Abbr.",
    "Traffic regs., e.g."
  ],
  "anut": [
    "\"Are you some kind of ___?\"",
    "\"What are you, some kind of ___?\"",
    "\"Some Kind of ___\" (Dick Van Dyke comedy)",
    "\"Sometimes you feel like ___ ...\"",
    "Some kind of ___",
    "\"... some kind of ___?\"",
    "\"Everybody Loves ___\" (Johnny Cash title track)",
    "\"What are you, some kind of ___?\"",
    "\"Sometimes you feel like ___ ...\"",
    "\"Sometimes you feel like ___\"",
    "\"Sometimes you feel like ___\"",
    "\"Sometimes you feel like ___ ?à\"",
    "\"Sometimes you feel like ___ ...\"",
    "\"What are you, some kind of ___?\"",
    "\"Sometimes you feel like ___...\"",
    "\"Are you some kind of ___?\"",
    "\"Are you some kind of ___?\"",
    "\"...some kind of ___?\"",
    "\"The Figure of___\": Dickinson"
  ],
  "stac": [
    "Short and detached, in music: Abbr.",
    "Quick and detached, musically: Abbr.",
    "Abbr. on a music score",
    "Like some mus. notes",
    "Quick and detached, in mus.",
    "Opposite of legato: Abbr.",
    "Abbr. accompanying some dotted notes",
    "Opposite of leg., in music",
    "Short and detached, in mus.",
    "Short and disconnected: Abbr.",
    "Disconnected, in music: Abbr.",
    "Opposite of legato, in mus.",
    "Opposite of legato: Abbr.",
    "Short and detached, in mus.",
    "Disconnected, in mus.",
    "Musically detached: Abbr.",
    "Opp. of legato, in music"
  ],
  "dodd": [
    "___-Frank Act",
    "___-Frank Wall Street Reform and Consumer Protection Act, 2010",
    "Georgia Tech football coaching great Bobby",
    "Connecticut senator Christopher",
    "Longtime Connecticut senator",
    "Senator of 1967 censure",
    "Connecticut Senator",
    "\"Mark Trail\" cartoonist Ed"
  ],
  "wisc": [
    "The Dairy State: Abbr.",
    "Cheesehead's state: Abbr.",
    "Birthplace of the Rep. Party",
    "State north of Ill.",
    "Superior setting: Abbr.",
    "Appleton locale: Abbr.",
    "Madison's home: Abbr."
  ],
  "ywca": [
    "Female org. since the 1850s",
    "Female org. since the 1850s",
    "Ladies' service org. since the 1850s",
    "Service org. for females",
    "Intl. feminine group",
    "Feminine org. since the 1850s",
    "Geneva-based org.",
    "Org. that once used the slogan \"In Service for the Girls of the World\"",
    "Org. promoting leadership and growth for females",
    "Blue Triangle org.",
    "Service org. since the 1850's",
    "Blue Triangle org.",
    "Natl. org. of good works since 1906",
    "Blue Triangle grp.",
    "Feminine org. since the 1850's",
    "Rec center"
  ],
  "titi": [
    "South American monkey",
    "Monkey with a repetitive name",
    "Long-tailed monkey",
    "South American monkey",
    "New World monkey",
    "New World monkey",
    "South American monkey",
    "South American monkey",
    "South American monkey",
    "Furry tree-dweller of the Amazon",
    "Capuchin monkey relative",
    "South American monkey",
    "South American monkey",
    "South American monkey",
    "Small monkey",
    "Small, reddish monkey"
  ],
  "katt": [
    "Stand-up comic Williams",
    "Actor William",
    "Comedian/rapper Williams",
    "Nicky of \"Boston Public\"",
    "Actor William of \"The Greatest American Hero\"",
    "William of TV's \"The Greatest American Hero\"",
    "\"The Greatest American Hero\" star"
  ],
  "anth": [
    "Literary collection: Abbr.",
    "Literary collection: Abbr.",
    "Literary collection: Abbr.",
    "Lit. collection",
    "Literary collection: Abbr.",
    "Flower: Prefix"
  ],
  "atli": [
    "Gudrun's victim, in Norse myth",
    "Mythical king of the Huns",
    "Hun king, in myth",
    "Literary Hun king",
    "Husband of Gudrun",
    "Mythical Hun king",
    "Chief Hun, in Scandinavian legend",
    "Mythical king of the Huns",
    "Husband of Gudrun",
    "Brynhild's brother, in myth",
    "Mythic Hun",
    "Hun king, in myth",
    "King in the \"Volsunga Saga\"",
    "King of the Huns",
    "Gudrun's victim",
    "Gudrun did him in"
  ],
  "cela": [
    "That, in France",
    "1989 Literature Nobelist",
    "1989 Literature Nobelist",
    "That, in France",
    "Spanish novelist who won a 1989 Nobel",
    "1989 Literature Nobelist",
    "1989 Literature Nobelist"
  ],
  "fogg": [
    "\"Around the World in 80 Days\" protagonist",
    "Literary world traveler",
    "Phileas ___, fictional circumnavigator",
    "Phileas ___, who went around the world in 80 days",
    "Verne's circumnavigator",
    "Verne hero",
    "Fictional circumnavigator",
    "Verne hero Phileas",
    "Harvard art museum",
    "80-day traveler"
  ],
  "lina": [
    "Chinese tennis star who has won both the French and Australian Opens",
    "\"Singin' in the Rain\" role",
    "Wertmuller who directed \"The Seduction of Mimi\"",
    "Director Wertmuller",
    "Director Wertmuller",
    "Director Wertmuller",
    "Director Wertmuller",
    "Wertmuller who directed \"Seven Beauties\"",
    "Filmmaker Wertmuller",
    "Filmmaker Wertmuller",
    "Director Wertmuller",
    "\"Singin' in the Rain\" role",
    "Director Wertmuller",
    "___ Lamont (\"Singin' in the Rain\" role)",
    "Director Wertmuller",
    "Director Wertmuller",
    "Director Wertmuller",
    "Director Wertmuller",
    "Filmmaker Wertmuller",
    "Director Wertmuller",
    "Film director Wertmuller",
    "Filmdom's Wertmuller",
    "Director Wertmuller",
    "Film director Wertmuller",
    "Film maker Wertmuller"
  ],
  "gift": [
    "Gold, frankincense or myrrh, for baby Jesus",
    "Photographic memory or perfect pitch, e.g.",
    "Easy exam question, so to speak",
    "Photographic memory, e.g.",
    "Present",
    "Natural talent",
    "\"It's a ___\"",
    "Shower need",
    "Knack",
    "Partygoer's purchase",
    "It's not bought",
    "ESP, they say",
    "Endowment",
    "Knack",
    "Kind of wrap",
    "Present"
  ],
  "galt": [
    "Ayn Rand hero",
    "\"Who is John ___?\" (question in \"Atlas Shrugged\")",
    "\"Hair\" composer MacDermot",
    "\"Who is John ___?\" (question asked in \"Atlas Shrugged\")",
    "Ayn Rand hero",
    "?ôAtlas Shrugged?ö leader"
  ],
  "abaa": [
    "Rhyme scheme ending a villanelle",
    "Rhyme scheme in the last verse of a villanelle",
    "Certain quatrain rhyme scheme",
    "Rhyme pattern at the end of a villanelle",
    "Common quatrain form",
    "Simple quatrain form",
    "Quatrain rhyme scheme",
    "Simple rhyme scheme",
    "Simple quatrain form",
    "Quatrain form",
    "Simple rhyme scheme",
    "Simple rhyme scheme"
  ],
  "cpos": [
    "Naval noncoms",
    "Noncom naval personnel",
    "Navy equivalents of S.F.C.'s",
    "Coast Guard noncoms",
    "Some ship personnel, briefly",
    "Coast Guard noncoms",
    "U.S.N. noncoms",
    "Some ship personnel, briefly",
    "Navy noncoms, for short",
    "Navy noncoms",
    "Naval noncoms",
    "Nav. ratings",
    "Naval noncoms",
    "U.S.N. personnel",
    "U.S.N. noncoms",
    "TV's Sharkey et al."
  ],
  "jiff": ["Flash", "Quick second", "Sec", "Sec", "Instant"],
  "ehle": [
    "Jennifer of \"Pride and Prejudice,\" 1995",
    "Jennifer of \"The King's Speech\"",
    "Actress Jennifer of \"Pride and Prejudice\"",
    "Jennifer of the BBC production \"Pride and Prejudice\"",
    "\"Pride and Prejudice\" actress Jennifer"
  ],
  "emac": [
    "Early 2000s Apple product",
    "Apple originally marketed to schools",
    "Old Apple product marketed to schools"
  ],
  "roid": ["Antidoping target, informally", "___ rage (result of juicing)"],
  "aler": [
    "Blue Jay but not Cardinal, for short",
    "'Stro, e.g.",
    "A or O, e.g.",
    "Twin or Tiger, informally",
    "A or O, but not B",
    "A, e.g.",
    "Angel, e.g., for short",
    "Joltin' Joe, e.g.",
    "O, e.g.",
    "A, Jay or Ray",
    "DiMag, e.g., for his entire career",
    "34-Across, e.g., for short",
    "Yank or Ray",
    "Tiger, e.g., informally",
    "Oriole or Tiger, informally",
    "Angel, e.g., for short",
    "Any pro designated hitter, briefly",
    "Oriole, e.g., briefly",
    "Yank or Ray",
    "Jay or Ray",
    "Tiger or Twin, briefly",
    "Yank or Ranger",
    "Ray, e.g., in brief",
    "Yank or Tiger",
    "Oriole or Blue Jay, for short",
    "Devil Ray or Blue Jay, for short",
    "D-Ray, e.g.",
    "Yank or Ranger",
    "K.C. Royal, e.g.",
    "Det. Tiger or N.Y. Yankee",
    "Minnesota Twin, e.g., briefly",
    "Yank, e.g.",
    "K.C. Royal, e.g.",
    "Oakland A, e.g.",
    "Yank, e.g.",
    "Yank or Blue Jay",
    "Tiger or Indian, briefly",
    "Yankee or Angel, for short",
    "16-Across, for short"
  ],
  "maas": [
    "Peter who wrote \"Serpico\"",
    "Goat sounds",
    "Sounds from kids",
    "Peter who wrote \"Underboss\"",
    "Peter who wrote \"Underboss\"",
    "\"Underboss\" author Peter",
    "\"Serpico\" author",
    "\"Serpico\" author",
    "\"Serpico\" author Peter",
    "Peter who wrote \"The Valachi Papers\"",
    "\"Serpico\" author",
    "\"Serpico\" author",
    "\"Serpico\" novelist",
    "\"Serpico\" author",
    "\"Serpico\" author",
    "\"Serpico\" author Peter",
    "\"Serpico\" author",
    "\"Serpico\" author Peter"
  ],
  "dorr": [
    "U.S. rebellion leader of 1841-42",
    "1841 rebellion leader ... also what can fill the square at the crossing of 56-Across and 56-Down",
    "U.S. rebellion leader of 1842",
    "U.S. rebellion leader of 1841-42",
    "June bug",
    "Thomas ___, 1842 rebellion leader"
  ],
  "atwt": [
    "12.01, for carbon: Abbr.",
    "Fig. on a periodic table",
    "No. on a periodic table",
    "Periodic table abbr.",
    "No. in chemistry",
    "Silver's is 107.87: Abbr.",
    "No. on a periodic table",
    "Elementary figure: Abbr.",
    "Bit of elementary knowledge?: Abbr.",
    "Al's is almost 27",
    "Periodic table no.",
    "1, for hydrogen: Abbr.",
    "Periodic table abbr.",
    "No. for Na or Ni",
    "Periodic table abbr.",
    "Elementary abbr.",
    "No. on a certain table"
  ],
  "erna": ["Met soprano Berger", "Soprano Berger"],
  "viet": [
    "Word before Cong or Minh",
    "___ Cong",
    "___ Cong",
    "___ Minh (1940s independence movement)",
    "___ Cong",
    "___ Cong",
    "Some served veterans there: Abbr.",
    "Thai relative",
    "___ Cong"
  ],
  "seng": [
    "Hong Kong's Hang ___ Index",
    "Hong Kong's Hang ___ Index",
    "Hong Kong's Hang ___ index"
  ],
  "fonz": [
    "1970s TV cool dude, with \"the\"",
    "Epitome of cool, with \"the\"",
    "TV greaser, with \"the\"",
    "Epitome of cool, with \"the\"",
    "\"Hey-y-y-y!\" sayer of sitcomdom, with \"the\"",
    "TV character who jumped the shark, with \"the\"",
    "Old sitcom mechanic, with \"the\"",
    "Sitcom guy with a frequently upturned thumb, with \"the\"",
    "\"Happy Days\" cool cat, with \"the\"",
    "Winkler role, with \"the\"",
    "Henry Winkler role, with ?ôthe?ö",
    "1970's TV character whose real first name was Arthur, with \"the\"",
    "1970's-80's sitcom guy, with \"the\""
  ],
  "flor": [
    "Spanish bloom",
    "Bolivian blossom",
    "Bogota bloom",
    "Dona ___ (1976 Sonia Braga role)",
    "\"Dona ___ and Her Two Husbands\"",
    "___ de Cana (Nicaraguan rum)",
    "Bloom: Sp.",
    "\"Dona ___ and Her Two Husbands\"",
    "Bilbao bloom",
    "Rosa or violeta, por ejemplo",
    "Thing with petalos",
    "\"Dona ___ and Her Two Husbands\"",
    "Spanish bloom",
    "Dona ___, 1978 Sonia Braga role",
    "\"Dona ___ and Her Two Husbands\""
  ],
  "dick": [
    "Veep between Al and Joe",
    "Presidential nickname",
    "Author Philip K. ___",
    "See 9-Across",
    "16-Across, to some",
    "Tracy of the comics",
    "Gumshoe",
    "Former White House nickname",
    "Button on the ice",
    "Gumshoe"
  ],
  "hock": [
    "Pawn",
    "Pawn",
    "Sell at a pawnshop",
    "Ham option",
    "Take to a pawnshop",
    "What a debtor might be in",
    "Equine ankle",
    "Pawn",
    "Pawn",
    "Pawn",
    "What a gambler may be in",
    "Leave at a pawnshop",
    "Pawn"
  ],
  "diff": ["\"What's the ___?\"", "\"What's the ___?\"", "\"What's the ___?\""],
  "auel": [
    "\"The Clan of the Cave Bear\" author",
    "Author whose \"Earth's Children\" series has sold more than 45 million copies",
    "Jean who wrote \"The Clan of the Cave Bear\"",
    "Writer about Cro-Magnons and Neanderthals",
    "\"The Clan of the Cave Bear\" novelist",
    "\"The Clan of the Cave Bear\" author Jean",
    "\"The Land of Painted Caves\" novelist",
    "\"The Clan of the Cave Bear\" author",
    "Jean who wrote \"The Shelters of Stone\"",
    "Jean who wrote \"The Clan of the Cave Bear\"",
    "\"The Clan of the Cave Bear\" author",
    "\"The Valley of Horses\" novelist, 1982",
    "\"The Shelters of Stone\" author",
    "Jean who wrote \"The Clan of the Cave Bear\"",
    "Author Jean",
    "Author of the \"Earth's Children\" series",
    "\"Earth's Children\" series author Jean",
    "Jean ___, creator of 56-Down",
    "\"The Clan of the Cave Bear\" author Jean",
    "\"The Shelters of Stone\" author",
    "\"Clan of the Cave Bear\" author",
    "\"The Clan of the Cave Bear\" author Jean",
    "\"The Clan of the Cave Bear\" author",
    "\"The Clan of the Cave Bear\" author",
    "\"The Clan of the Cave Bear\" author",
    "Novelist Jean",
    "\"The Mammoth Hunters\" author"
  ],
  "goss": [
    "Former C.I.A. director Porter ___",
    "Former C.I.A. chief Porter ___",
    "Porter ___, former C.I.A. director"
  ],
  "melo": [
    "Longtime Knicks nickname",
    "Knicks star Anthony, to fans",
    "Prefix with drama",
    "Drama intro?",
    "Prefix with drama",
    "Dramatic opening?",
    "Dramatic opening?",
    "Prefix with drama",
    "Dramatic beginning"
  ],
  "arrs": [
    "Some airport times: Abbr.",
    "Column on an airport screen: Abbr.",
    "Column on a flight board: Abbr.",
    "Flight board column: Abbr.",
    "Flight board info: Abbr.",
    "Touchdowns: Abbr.",
    "Opposites of departures: Abbr.",
    "Terminal list: Abbr.",
    "Some airport data: Abbr.",
    "Musical settings: Abbr.",
    "LAX info: Abbr.",
    "Some airport data: Abbr.",
    "Airport postings: Abbr.",
    "Some notices at LAX"
  ],
  "vcrs": [
    "Betamaxes, e.g.",
    "Obsolescent players",
    "Players last produced in July 2016",
    "Obsolescent tape holders",
    "See 45-Across",
    "TV hookups",
    "Devices with spools",
    "TV accessories",
    "RCA products",
    "Tapers, briefly",
    "Some RCA products",
    "TV hookups",
    "TiVo forerunners",
    "TV hookups",
    "Some RCA products",
    "Zenith products",
    "They sometimes flash \"12:00\"",
    "Some RCA products"
  ],
  "saes": [
    "Freelancers' enclosures, for short",
    "Mailing encls.",
    "Requests from an ed.",
    "Writer's supply: Abbr.",
    "Mail-order encls."
  ],
  "mtgs": [
    "Boardroom events: Abbr.",
    "Boardroom events: Abbr.",
    "Chairmen often call them: Abbr.",
    "Board events: Abbr."
  ],
  "worf": [
    "\"Star Trek: T.N.G.\" character",
    "\"Star Trek: T.N.G.\" Klingon",
    "Klingon on \"Star Trek: T.N.G.\"",
    "Enterprise Klingon",
    "\"Star Trek: T.N.G.\" role",
    "Klingon on \"Star Trek: T.N.G.\"",
    "Enterprise Klingon",
    "Klingon on the Enterprise",
    "\"Star Trek\" Klingon"
  ],
  "fein": [
    "Ireland's Sinn ___",
    "Sinn ___",
    "Sinn ___",
    "Ireland's Sinn ___",
    "Sinn ___",
    "Sinn ___ (Irish organization)",
    "Sinn ___",
    "Sinn ___",
    "Sinn ___",
    "Sinn ___",
    "Sinn ___"
  ],
  "agas": [
    "Turkish pooh-bahs",
    "Ottoman chiefs",
    "Bygone military titles",
    "\"What ___!\" (\"How fun!\")",
    "Turkish V.I.P.'s",
    "\"Jumpin' Jack Flash, it's ___\"",
    "Near Eastern V.I.P.'s",
    "Old Turkish V.I.P.'s",
    "Turkish pooh-bahs",
    "\"What ___!\" (\"That was fun!\")",
    "Old Turkish leaders",
    "They're akin to khans",
    "Mideast leaders",
    "Turkish V.I.P.'s",
    "\"What ___!\" (\"How cool!\")",
    "Bygone dignitaries",
    "Eastern leaders",
    "Old Turkish titles",
    "Eastern titles",
    "Turkish chiefs",
    "Mideast bigwigs of old",
    "Turkish generals",
    "Ones taking a sultan's orders",
    "\"What ___!\"",
    "\"What ___!\"",
    "Mideast titles",
    "Foreign titles",
    "Turkish V.I.P.'s",
    "\"Jumpin' Jack Flash, it's ___\" (1968 lyric)",
    "Turkish generals",
    "Turkish potentates",
    "Foreign military leaders",
    "Turkish bigwigs",
    "Eastern V.I.P.'s",
    "\"What ___!\" (\"Groovy\")",
    "\"It's ___!\" (\"Groovy!\")",
    "Muslim officers",
    "Kin of khans",
    "Turkish generals",
    "Angora notables",
    "Mideast potentates",
    "Arab nobles",
    "\"It's___!\" (wow!)"
  ],
  "mack": [
    "Vehicle company with a bulldog logo",
    "\"___ the Knife\"",
    "Big name in trucks",
    "Connie ___, Philadelphia Athletics manager for 50 years",
    "Big truck maker",
    "Big truck maker",
    "Big truck maker",
    "Ted with TV's old \"Original Amateur Hour\"",
    "Truck name",
    "Kind of truck",
    "Hollywood mogul Sennett",
    "Built like a___truck"
  ],
  "debi": [
    "Actress Mazar of \"Entourage\"",
    "Mazar of \"Entourage\"",
    "\"Entourage\" actress Mazar",
    "World champion figure skater Thomas",
    "Mazar of \"Entourage\"",
    "Actress Mazar",
    "Actress Mazar",
    "Actress Mazar of HBO's \"Entourage\"",
    "Actress Mazar",
    "Actress Mazar",
    "Skater Thomas",
    "Actress Mazar",
    "Skater Thomas",
    "Actress Mazar",
    "Figure skater Thomas",
    "Figure skater Thomas",
    "Skater Thomas"
  ],
  "voom": ["\"Va-va-___!\"", "\"Va-va-va-___!\""],
  "jeri": [
    "Ryan of \"Boston Public\"",
    "Ryan of \"Boston Public\"",
    "Actress Ryan of \"Boston Public\"",
    "Ryan of \"Boston Public\"",
    "Ryan of \"Star Trek: Voyager\"",
    "Ryan of \"Boston Public\"",
    "Ryan of \"Star Trek: Voyager\"",
    "Ryan of \"Boston Public\""
  ],
  "buts": ["Qualifying words", "Partner of \"ifs\" and \"ands\""],
  "sitz": [
    "___ bath",
    "___ bath",
    "___ bath",
    "___ bath",
    "___ bath",
    "___ bath (therapeutic treatment)",
    "___ bath",
    "Therapeutic kind of bath",
    "___ bath (therapeutic treatment)",
    "___ bath (shallow tub)"
  ],
  "rire": [
    "To laugh, in Lyon",
    "Laugh, in Lyon",
    "Laugh, in Lille",
    "Laugh, in Lille",
    "Laugh, in Lille",
    "To laugh, to Lafayette"
  ],
  "slys": [
    "Stallone and Stone",
    "Stallone and Stone",
    "Stallone and others",
    "Stallone and Stone",
    "Stone and Stallone",
    "Stallone namesakes"
  ],
  "meso": [
    "Prefix with -zoic",
    "American leader?",
    "Prefix with -zoic",
    "Prefix with -zoic",
    "Middle: Prefix",
    "Prefix with America",
    "Middle: Prefix",
    "\"The Bible Tells ___\"",
    "\"You've Made ___ Very Happy\" (1969 hit)"
  ],
  "puny": [
    "Pint-size",
    "Piddling",
    "Pint-size",
    "Insignificant",
    "Pint-size",
    "Runtish",
    "Insignificant",
    "Undersized",
    "Pint-sized",
    "Insignificant",
    "Small and weak",
    "Hardly Herculean",
    "Stunted",
    "Brawnless",
    "Shrimpish"
  ],
  "hesa": [
    "\"___ Rebel\" (1962 #1 hit)",
    "\"For ___ jolly good fellow\"",
    "The Crystals' \"___ Rebel\"",
    "The Bee Gees' \"___ Liar\"",
    "\"___ Tramp,\" Peggy Lee song in \"Lady and the Tramp\"",
    "\"___ Rebel\" (1962 #1 hit)",
    "The Bee Gees' \"___ Liar\"",
    "\"___ Rebel\" (Crystals song)",
    "\"___ real nowhere man\" (Beatles lyric)",
    "\"___ Devil in His Own Home Town\" (classic song)",
    "Peggy Lee's \"___ Tramp\"",
    "\"___ Heartache\" (#1 country hit for Janie Fricke)",
    "\"___ Rebel\" (1962 hit)",
    "The Crystals' \"___ Rebel\"",
    "\"___ Rebel\" (1962 #1 hit)",
    "\"For ___ jolly...\"",
    "\"___ Rebel\" (1962 song)",
    "\"___ Rebel\" ('62 Crystals hit)"
  ],
  "auri": ["Ear: Prefix", "Hearing: Prefix"],
  "hake": [
    "Relative of a cod",
    "Marine fish related to the cod",
    "Relative of a haddock",
    "Cod relative",
    "Relative of a cod",
    "Cod kin",
    "Cod relative"
  ],
  "bics": [
    "Certain lighters",
    "Disposable lighters and pens",
    "Popular ballpoints",
    "Inexpensive writing implements",
    "Zippo alternatives",
    "Some 27-Down",
    "They're flicked",
    "Pens sold in 10-packs",
    "Alternatives to Zippos",
    "Popular ballpoints",
    "Inexpensive pens",
    "Some ballpoints",
    "Popular writers",
    "Popular pens",
    "Certain pens",
    "Some ballpoints",
    "Popular pens",
    "Writers with caps?",
    "Some pens",
    "Some pens"
  ],
  "mhos": [
    "Conductance quantities",
    "Conductance units",
    "Conductance units",
    "Units now called siemens",
    "Anti-resistance units?",
    "Electrical units",
    "Conductance units",
    "Conductance units"
  ],
  "osos": [
    "Baja bears",
    "Animals in un zoologico",
    "Some Spanish zoo exhibits",
    "Bogota bears",
    "Spanish bears",
    "Bolivian bears",
    "Bears, in Bolivia",
    "Bogota bears",
    "Spanish bears",
    "Some growlers, in Granada",
    "Bears, in Baja",
    "Bogota bears",
    "Zoo de Madrid beasts",
    "Zoo de Madrid animals",
    "Spanish bears",
    "Chicano bears?",
    "Bears, in Barcelona",
    "Granada grizzlies"
  ],
  "coon": [
    "Cousin of a polecat",
    "Garbage collector, informally?",
    "Masked \"bandit\"",
    "Animal used for Davy Crockett's cap",
    "Cousin of a kinkajou",
    "Masked critter",
    "Nighttime scavenger, informally",
    "Masked critter",
    "Masked one, informally",
    "Masked critter",
    "Certain cap source",
    "Maine ___ cat",
    "Masked critter",
    "Masked critter",
    "___ dog (backwoods animal)",
    "Kind of skin",
    "Woodland critter",
    "Woods critter",
    "Ring-tailed critter"
  ],
  "wego": [
    "Words after \"Here\" and \"Away\"",
    "\"And away ___!\"",
    "\"___ Together\" (song from \"Grease\")",
    "\"And away ___!\"",
    "\"And away ___!\""
  ],
  "engs": [
    "Some Caltech alums: Abbr.",
    "Some M.I.T. grads: Abbr.",
    "Lots of R.P.I. grads: Abbr.",
    "Some R.P.I. grads",
    "Many Caltech grads: Abbr.",
    "Some specialize in elec.",
    "Some M.I.T. grads: Abbr.",
    "Some M.I.T. grads",
    "M.I.T. grads: Abbr.",
    "M.I.T. grads"
  ],
  "hoad": [
    "Two-time Wimbledon winner Lew",
    "1950s Wimbledon champ Lew",
    "1956-57 Wimbledon champion Lew",
    "'50s Davis Cup player"
  ],
  "adda": [
    "Start of many recipe steps",
    "\"___ little sugar\" (recipe directive)",
    "Recipe step starter",
    "Recipe direction starter",
    "Recipe step starter",
    "Recipe direction starter",
    "\"___ little spice to your life\"",
    "\"... to your beauteous blessings ___ curse\": Shak.",
    "Frequent recipe words",
    "Start of a recipe direction",
    "\"___ pinch of salt...\"",
    "Recipe words"
  ],
  "msgs": [
    "What blinks on a telephone may signify: Abbr.",
    "Texts, e.g.: Abbr.",
    "Secretaries may collect them: Abbr.",
    "Txts, e.g.",
    "Texts, e.g.: Abbr.",
    "E-mail, e.g.: Abbr.",
    "In-box fill: Abbr.",
    "E-mails: Abbr.",
    "BlackBerry output: Abbr.",
    "E-mail, e.g.: Abbr.",
    "E-mail: Abbr.",
    "E-mail: Abbr.",
    "E-mail: Abbr."
  ],
  "alae": [
    "Wings, to zoologists",
    "Wings: Lat.",
    "Wings: Lat.",
    "Botanical wings",
    "Wings: Lat.",
    "Wings, in zoology",
    "Zoological wings",
    "Wings, in zoology",
    "Wings on an avis",
    "Butterfly wings, e.g.",
    "Latin wings",
    "Wings, zoologically",
    "Wings: Lat.",
    "Wings: Lat.",
    "Zoological appendages",
    "Wings: Lat.",
    "Wings",
    "Wings: Lat.",
    "Winglike parts",
    "Avis features",
    "Winglike appendages",
    "Wings",
    "Wings",
    "Wings",
    "Wings: Lat.",
    "Wings",
    "Wings: Lat.",
    "Caesar's wings",
    "Avis pair",
    "Wings",
    "Wings",
    "Wings: Lat.",
    "Wings",
    "Wings",
    "Aves have them",
    "Wings",
    "Wings",
    "Side petals of a flower",
    "Wings",
    "Wings",
    "Wings"
  ],
  "sauk": [
    "Fox neighbor",
    "___ Centre, Minn. (Sinclair Lewis's birthplace)",
    "Midwest Indian"
  ],
  "aleg": [
    "Words after shake or break",
    "Words after break or shake",
    "\"Break ___!\"",
    "Give ___ up",
    "\"Get ___ Up\" (John Mellencamp hit)",
    "\"Shake ___!\"",
    "Give ___ up",
    "\"Break ___!\" (\"Good luck!\")",
    "Something to break or shake, in phrase",
    "\"Break ___!\"",
    "\"Shake ___!\"",
    "Get ___ up",
    "\"Shake ___!\"",
    "\"Break ___!\"",
    "Without ___ to stand on",
    "Shake ___",
    "Something to shake",
    "Shake it or break it",
    "Shake or break follower",
    "\"Shake ___!\"",
    "Shake or break follower",
    "\"Shake ___!\"",
    "Give ___ up",
    "Give___up",
    "Shake ___ (hurry up)",
    "Something to shake",
    "Give___ up (assist)",
    "Shake ___",
    "Words after shake or break",
    "Get ___ up",
    "\"Shake ___!\""
  ],
  "duck": [
    "Avoid a beanball, maybe",
    "Evade",
    "With 46-Across, \"It ain't hard!\"",
    "Canvasback"
  ],
  "nisi": [
    "Unless, in law",
    "Like some legal decrees",
    "Pending, as a legal decree",
    "Not yet final, legally",
    "Not final, legally",
    "Vivaldi's \"___ Dominus\"",
    "Not finalized, at law",
    "Not final, in law",
    "Like some divorce decrees",
    "Not yet final, at law",
    "Not final, at law",
    "Not yet final, in law",
    "Not final, at law",
    "Not yet final, at law",
    "Not yet final, at law",
    "Not yet final, as a decree",
    "Not final, as a legal decree",
    "Like some decrees",
    "Like some decrees",
    "Not yet final, in law",
    "Like some decrees",
    "Unless, in law",
    "Not yet final, legally",
    "___ prius (trial court)",
    "Nil___bonum",
    "Unless, to Cato",
    "Unless, in court",
    "Decree ___ (legal term)",
    "Pending, as a legal decree"
  ],
  "lbos": [
    "Some Wall St. deals",
    "Some mergers, for short",
    "Some Wall St. deals",
    "Wall St. deals",
    "Wall St. deals",
    "Wall St. deals"
  ],
  "alcs": ["Oct. pennant race"],
  "ghee": ["Butter in South Asian cuisines", "Indian butter"],
  "alix": [
    "\"The Adventures of ___\" (European comics series)",
    "TV actress ___ Elias"
  ],
  "eriq": [
    "La Salle of \"ER\"",
    "La Salle of \"ER\"",
    "La Salle of \"Coming to America\"",
    "La Salle of \"ER\"",
    "Actor La Salle",
    "Actor La Salle",
    "\"ER\" actor La Salle",
    "Former \"ER\" co-star La Salle",
    "Actor La Salle",
    "\"ER\" actor La Salle",
    "LaSalle of \"ER\"",
    "\"ER\" actor La Salle"
  ],
  "aout": [
    "Month after juillet",
    "Month in l'ete",
    "Juillet follower",
    "Month before septembre",
    "Summer month in France",
    "Month in l'ete",
    "Follower of juillet",
    "Part of l'ete",
    "Vacation time in France",
    "Hot time in Montreal",
    "Summer month, in Paris",
    "Juillet's follower",
    "Hot time on the Riviera",
    "Juillet's follower",
    "Hot month in Quebec",
    "Month of l'ete",
    "Hot time in Quebec",
    "When the French go en vacances",
    "Part of 35-Across",
    "Month of l'ete",
    "Ete month"
  ],
  "luci": [
    "First daughter of the 1960s",
    "___ Baines Johnson (presidential daughter)",
    "___ Baines Johnson",
    "Light: Prefix"
  ],
  "rans": ["Also-___", "Also-___ (losers)"],
  "belg": [
    "Like M. Poirot",
    "Lux. neighbor",
    "E.U. country where Hoegaarden beer is brewed",
    "E.U. member",
    "Lux. neighbor",
    "Lux. neighbor",
    "Eur. kingdom",
    "Lux. neighbor",
    "Neth. neighbor"
  ],
  "atya": [
    "\"Right back ___!\"",
    "\"Comin' ___!\"",
    "\"Right back ___!\"",
    "\"Comin' ___!\"",
    "\"Comin ___!\" (1981 3-D western)",
    "\"Comin' ___\" (1981 3-D western)"
  ],
  "kael": [
    "Longtime New Yorker writer Pauline",
    "Film critic Pauline",
    "Film critic Pauline",
    "Film critic Pauline",
    "Film critic Pauline",
    "Critic Pauline",
    "Film critic Pauline",
    "Critic Pauline"
  ],
  "ment": [
    "Suffix with refresh or replace",
    "Base closure?",
    "Puzzle completion?",
    "Suffix with puzzle",
    "Suffix with base or case"
  ],
  "aguy": ["\"So ___ walks into ...\"", "\"___ walks into a bar ?à\""],
  "marv": [
    "___ Levy, Buffalo Bills coach in the Hall of Fame",
    "Man's nickname that's just wonderful?",
    "Sportscaster Albert",
    "___ Levy, four-time Super Bowl coach for Buffalo",
    "Sportscaster Albert",
    "Sportscaster Albert",
    "Sportscaster Albert",
    "First name in sportscasting",
    "\"Terrif!\"",
    "Basketball Hall-of-Famer Harshman",
    "Former Met Throneberry",
    "Basketball Hall-of-Famer Harshman",
    "Baseball's Throneberry",
    "Sportscaster Albert",
    "Sportscaster Albert",
    "Bills coach Levy",
    "Sportscaster Albert",
    "Sportscaster Albert"
  ],
  "saya": [
    "\"Don't ___ thing\"",
    "\"Don't ___ word!\"",
    "\"I won't ___ word\"",
    "___ prayer",
    "___ prayer for",
    "Dionne Warwick's \"I ___ Little Prayer\"",
    "\"___ Prayer\" (1990 hit)",
    "___ mouthful"
  ],
  "fold": [
    "Collapse",
    "Turn down a raise?",
    "Dog-ear, e.g.",
    "Crease",
    "Bow out of a poker hand",
    "Give up a poker hand",
    "Close up",
    "Go out of business",
    "Go belly up",
    "Do a post-laundry job",
    "Do origami",
    "Quit, in poker",
    "Drop out, in poker",
    "Quit, in poker",
    "Go belly up"
  ],
  "lude": ["Certain trank"],
  "klan": [
    "Group lampooned in \"Django Unchained\"",
    "Group in the Bogart film \"Black Legion\"",
    "Group investigated in \"Mississippi Burning\"",
    "Kind of meeting in \"O Brother, Where Art Thou?\"",
    "Hateful group",
    "Hate group",
    "Hate group",
    "\"The Birth of a Nation\" group",
    "Group seen in the film \"Legion of Terror\"",
    "D. W. Griffith subject",
    "Grand Dragon's group",
    "Hoods in hoods",
    "Hateful group",
    "Part of K.K.K."
  ],
  "awag": [
    "Like many canine tails, quaintly",
    "Like many dogs' tails",
    "Like an excited puppy's tail, old-style",
    "Like a friendly dog's tail",
    "Like gossiping tongues",
    "Like gossiping tongues"
  ],
  "zbar": [
    "Letter-shaped construction piece",
    "Doubly bent construction piece",
    "Letter-shaped beam",
    "Letter-shaped beam",
    "Construction piece shaped like the last letter of the alphabet",
    "Letter-shaped construction piece"
  ],
  "akio": [
    "___ Morita, co-founder of Sony",
    "Toyota exec ___ Toyoda",
    "Sony co-founder Morita",
    "Sony co-founder Morita",
    "Sony founder Morita"
  ],
  "astr": [
    "Study of the heavens: Abbr.",
    "\"Cosmos\" subj.",
    "Sagan's specialty: Abbr.",
    "Subj. for Galileo",
    "Observatory subj.",
    "Sci. branch",
    "Sci. major",
    "Physics dept. offering",
    "Stellar start?",
    "Space science: Abbr.",
    "Galaxy sci.",
    "College sci. class",
    "Observatory subj.",
    "Observatory subj.",
    "Field in which things are looking up?: Abbr.",
    "Study of the heavens: Abbr.",
    "NASA subj.",
    "Space: Prefix",
    "Observatory observer: Abbr.",
    "Heavenly field?: Abbr.",
    "Sci. of the heavens",
    "Carl Sagan's subj.",
    "Science class: Abbr.",
    "Star sci.",
    "Study of the stars: Abbr.",
    "One of the hard sciences: Abbr."
  ],
  "rapa": ["___ Nui (Easter Island)", "___ Nui (Easter Island)"],
  "cuds": [
    "Mooers' mouthfuls",
    "Things to chew on",
    "Cows chew them",
    "They're chewed in the field",
    "Things to chew on",
    "Cows' mouthfuls"
  ],
  "ecua": [
    "Land next to Peru: Abbr.",
    "S. American land",
    "S. Amer. land",
    "Quito's land: Abbr.",
    "S. Amer. land",
    "O.A.S. member: Abbr.",
    "Land in S.A.",
    "Quito's country: Abbr.",
    "Its cap. is Quito",
    "Quito's country: Abbr.",
    "Andean land: Abbr.",
    "Neighbor of Peru: Abbr.",
    "S.A. country"
  ],
  "vsix": [
    "Rather powerful engine",
    "Motor with some muscle",
    "Engine type pioneered by the Buick Special",
    "*It may be under a hood",
    "Kind of engine",
    "Midsize motor",
    "Midsize auto engine",
    "Auto engine type",
    "Powerful auto engine"
  ],
  "ming": [
    "Vase style",
    "Chinese dynasty during which trade with Portugal began",
    "\"Flash Gordon\" villain ___ the Merciless",
    "Like some vases",
    "Merciless one",
    "Like some vases",
    "Chu Yuan-chang's dynasty",
    "Chinese dynasty",
    "Like some vases",
    "Flash Gordon villain",
    "Flash Gordon's foe",
    "Chinese dynasty, 1368-1644"
  ],
  "inno": [
    "___ way",
    "___ uncertain terms",
    "___ time at all",
    "___ uncertain terms",
    "___ time",
    "___ time",
    "___ way, shape or form",
    "___ case",
    "___ hurry",
    "___ time",
    "___ time flat",
    "___ uncertain terms",
    "___ way, shape or form",
    "___way (never)",
    "___ time (right away)"
  ],
  "bbls": [
    "Oil qtys.",
    "Crude qty.",
    "Oil qty.",
    "Crude containers: Abbr.",
    "OPEC units: Abbr.",
    "OPEC units",
    "Beer holders: Abbr.",
    "Oil quantities: Abbr."
  ],
  "indc": [
    "Visiting the nation's capital, for short",
    "Where to find the Capitol and the Library of Cong.",
    "Visiting the Library of Cong., say",
    "Visiting the U.S. Capitol, say",
    "Visiting the U.S. capital",
    "Where to find Pennsylvania Ave."
  ],
  "mcml": [
    "Year the Korean War began",
    "Korean War outbreak year",
    "Credits date for \"Cinderella\" or \"All About Eve\"",
    "1950, on a cornerstone",
    "1950, in copyrights"
  ],
  "yips": [
    "Nervousness that causes a golfer to miss an easy putt, with \"the\"",
    "Puppy sounds",
    "Athlete's sudden loss of ability, informally",
    "Dog sounds",
    "Puppy sounds",
    "Puppy sounds",
    "Pups' complaints",
    "Puppies' plaints",
    "Sounds at pounds",
    "Puppy sounds",
    "Puppy sounds",
    "Sounds made by 36-Across",
    "Pound's sounds",
    "Small dogs' cries",
    "Sounds in pounds",
    "Puppy sounds",
    "Pound sounds",
    "Sounds from pounds",
    "Puppy sounds",
    "Puppy cries",
    "Puppies' plaints",
    "Barks",
    "Litter cries",
    "Kennel noises",
    "Puppies' barks",
    "Pup's complaints"
  ],
  "cosm": ["Ending with micro-", "Suffix with macro-"],
  "unis": [
    "Les Etats-___",
    "Sports teams wear them, informally",
    "One-piece garments, informally",
    "Les Etats-___",
    "Aussie colleges",
    "Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "Colleges, to Aussies",
    "Les Etats-___",
    "Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "As one, at Orly",
    "Colleges, to Aussies",
    "Les Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "Les Etats-___",
    "French part of the U.S.A.",
    "Les Etats-___"
  ],
  "msec": [
    "Brief period in nuclear physics: Abbr.",
    "Fraction of a min.",
    "Approx. camera flash duration",
    "Brief moment, briefly",
    "How long it takes light to travel 186 mi.",
    "Processing time unit: Abbr.",
    "Fraction of a tick: Abbr.",
    "Fraction of a min.",
    "Fraction of a min.",
    "Not much time: Abbr.",
    "Brief moment in time: Abbr.",
    "Very short time, for short"
  ],
  "usia": [
    "Govt. cultural org. until 1999",
    "\"Public diplomacy\" broadcast org. until 1999",
    "Former Voice of America org.",
    "Former Voice of America org.",
    "Old Voice of America org.",
    "Old \"public diplomacy\" org.",
    "Voice of America grp.",
    "Govt. cultural org.",
    "Voice of America org.",
    "Govt. publicity grp.",
    "Agcy. once headed by Edward R. Murrow"
  ],
  "farr": [
    "Jamie of \"M*A*S*H\"",
    "\"M*A*S*H\" co-star",
    "Jamie of old TV",
    "Diane of \"Numb3rs\"",
    "\"The Red Skelton Show\" regular",
    "Jamie of \"M*A*S*H\"",
    "Actress Diane of \"Numb3rs\"",
    "Klinger portrayer on \"M*A*S*H\"",
    "Sitcom cross-dresser portrayer",
    "Klinger player on \"M*A*S*H\"",
    "Boxer Tommy, loser to Joe Louis in a 1937 title bout",
    "Klinger portrayer",
    "Jamie of \"M*A*S*H\"",
    "\"M*A*S*H\" actor",
    "\"M*A*S*H\" cast member",
    "\"M*A*S*H\" actor",
    "Klinger player on \"M*A*S*H\"",
    "Actress Felicia",
    "Klinger portrayer, in 70's-80's TV",
    "Actress Felicia",
    "Co-star of 55-Down",
    "\"M*A*S*H\" co-star",
    "Alda colleague",
    "Jamie of TV's \"M*A*S*H\"",
    "Jamie of \"M*A*S*H\"",
    "\"M*A*S*H\" co-star Jamie",
    "Actor Jamie"
  ],
  "scis": [
    "Some core classes: Abbr.",
    "Geol. and phys.",
    "Biol. and others",
    "Nobel pursuits?: Abbr.",
    "\"Hard\" or \"soft\" subjects: Abbr.",
    "Agronomy and metallurgy: Abbr.",
    "Univ. grouping",
    "Biol. and others",
    "Chem. and bio.",
    "Biol., geol., etc.",
    "Univ. subjects",
    "H.S. requirements"
  ],
  "ouse": [
    "River of York",
    "\"Great\" river of England",
    "River through York",
    "Sussex river where Virginia Woolf tragically ended her life",
    "Yorkshire river",
    "River of York",
    "River of York",
    "River of York",
    "River of York",
    "Northamptonshire river",
    "Yorkshire river",
    "English river to the Wash",
    "River of York",
    "Yorkshire river",
    "England's Great ___ River",
    "River past Buckingham",
    "River in England",
    "Name of three English rivers",
    "Any one of three English rivers",
    "Any of three English rivers",
    "Northamptonshire river",
    "Name of three English rivers"
  ],
  "suck": [
    "Be lousy",
    "Use a straw",
    "Drink through a straw",
    "Use a straw",
    "Be a leech",
    "Vacuum (up)",
    "Use a straw"
  ],
  "doin": [
    "Off",
    "Bump off",
    "\"Whatcha ___?\"",
    "Dispatch",
    "Murder",
    "Whack",
    "End",
    "Finish",
    "Bump off",
    "Bump off",
    "\"Whatcha ___?\"",
    "Put away",
    "Ice",
    "Off",
    "Murder",
    "Bump off",
    "Kill",
    "Finish off",
    "?ôWhatcha ___??ö",
    "\"Whatcha ___?\"",
    "Finish off",
    "\"Whatcha ___?\"",
    "Swindle",
    "Bump off",
    "Ruin",
    "Bump off",
    "Bump off",
    "\"___ What Comes Natur'lly\" (Irving Berlin song)",
    "Ruin",
    "Rub out",
    "Murder",
    "Slay",
    "Murder"
  ],
  "noid": [
    "Cause for being refused a drink at a bar",
    "Reason to be barred from a bar",
    "Reason to deny bar service",
    "Reason for denying entry, maybe"
  ],
  "isth": [
    "Panama, e.g.: Abbr.",
    "Neck: Abbr.",
    "Common canal locale: Abbr.",
    "Narrow strip of land: Abbr.",
    "Transcontinental bridge, e.g.: Abbr.",
    "Panama, e.g.: Abbr.",
    "Neck of land: Abbr.",
    "Canal locale: Abbr.",
    "Narrow strait of land: Abbr."
  ],
  "loti": [
    "\"There's not a ___ can do\"",
    "Novelist Pierre",
    "French novelist Pierre",
    "Pierre who wrote \"Pecheur d'Islande\"",
    "French novelist Pierre",
    "Fin de siecle writer Pierre ___",
    "French novelist Pierre",
    "\"An Iceland Fisherman\" author Pierre",
    "French novelist Pierre",
    "French novelist Pierre",
    "French novelist Pierre",
    "French novelist Pierre",
    "French novelist Pierre"
  ],
  "wolf": [
    "Bolt (down)",
    "Villain in some fairy tales",
    "Scarf (down)",
    "Villain in the tale named by the starts of 20-, 32-, 41- and 52-Across",
    "\"The Three Little Pigs\" antagonist",
    "Gobble (down)",
    "Grimm villain",
    "Woman-chaser",
    "Three Little Pigs' foe",
    "Impersonator in \"Little Red Riding Hood\"",
    "Alarmist's cry, in a fable",
    "Grandma, in a fairy tale",
    "Grimm villain",
    "Bolt (down)",
    "Whistler?",
    "Whistle maker?",
    "\"Little Red Riding-Hood\" villain",
    "Eat in a hurry",
    "\"The Call of the Wild\" animal"
  ],
  "acan": [
    "Like ___ of worms",
    "Like ___ of corn (really easy)",
    "Open ___ of worms",
    "Open ___ of worms",
    "Open ___ of worms",
    "Open ___ of worms",
    "Open up ___ of worms",
    "Open ___ of worms",
    "Open ___ of worms",
    "\"Do you have Prince Albert in ___?\"",
    "Open ___ of worms",
    "Open ___ of worms",
    "Open ___ of worms"
  ],
  "bvds": [
    "Some briefs",
    "Some briefs",
    "Some men's underwear",
    "Some briefs",
    "Some undies",
    "Drawers in a laundry room",
    "Briefs, briefly",
    "Some underwear",
    "Briefs, briefly"
  ],
  "gull": [
    "Shorebird with a distinctive shriek",
    "Seaside scavenger",
    "Take in",
    "Dock figure",
    "Sucker",
    "Rook",
    "Shore bird",
    "Deceive",
    "Web-footed flier",
    "Wing shape"
  ],
  "pows": [
    "Big hits",
    "John McCain and Kurt Vonnegut, once, for short",
    "Part of a treaty exchange, for short",
    "\"Stalag 17\" extras",
    "Geneva Convention concern",
    "Some camp denizens, for short"
  ],
  "scag": [
    "Heroin, slangily",
    "Heroin, in slang",
    "Heroin, slangily",
    "Heroin, slangily",
    "Heroin, slangily",
    "Heroin, slangily",
    "Heroin, slangily",
    "Heroin, slangily",
    "Heroin, to addicts"
  ],
  "fica": [
    "Abbr. on a pay stub",
    "Form W-2 abbr.",
    "Payroll tax, for short",
    "Medicare funder, briefly",
    "W-2 inclusion",
    "Pay stub abbr.",
    "Kind of tax that funds Soc. Sec.",
    "Paycheck abbr.",
    "Paycheck deduction",
    "Paycheck abbr.",
    "Paycheck abbr.",
    "Social Security tax abbr.",
    "Paycheck stub inits.",
    "Paycheck stub abbr."
  ],
  "klum": [
    "Heidi of \"Project Runway\"",
    "Supermodel Heidi",
    "Supermodel Heidi",
    "Model/TV host Heidi",
    "Supermodel Heidi"
  ],
  "addn": [
    "Building annex: Abbr.",
    "Wing: Abbr.",
    "Postscript: Abbr.",
    "Annex: Abbr.",
    "Wing: Abbr.",
    "Arith. process",
    "Annex: Abbr."
  ],
  "abig": ["Not ___ deal", "\"...and carry ___ stick\""],
  "vten": [
    "Power-packed engine",
    "Dodge Viper engine",
    "Powerful engine",
    "Powerful engine",
    "The muscle of a muscle car, maybe",
    "Powerful kind of engine",
    "Powerful engine",
    "Powerful auto engine",
    "Powerful engine"
  ],
  "pups": [
    "Things weighed in pounds?",
    "Young wolves",
    "Canine newborns",
    "Litter, maybe",
    "Litter members",
    "Littermates",
    "Critters in litters",
    "Small boxers"
  ],
  "itea": [
    "Virginia willow's genus",
    "Virginia willow's genus",
    "Willow variety",
    "Virginia willow"
  ],
  "atug": ["Give ___ (yank)", "Give ___ (yank)"],
  "apal": [
    "\"Be ___!\" (\"Help me out here!\")",
    "\"C'mon, be ___\"",
    "\"Be ___!\"",
    "\"Be ___!\"",
    "\"Be ___\"",
    "\"C'mon, be ___\"",
    "\"Be ___!\" (\"Help me out!\")",
    "\"Be ___!\" (\"Come on!\")",
    "\"C'mon, be ___\"",
    "\"Be ___!\" (\"Help me out!\")",
    "\"Be ___!\"",
    "Be ___ to (help out)",
    "\"Be___\" (\"Help me out\")",
    "\"You're ___\" (\"Thanks\")"
  ],
  "foyt": [
    "Indy 500 winner A. J.",
    "Track star A. J.",
    "Four-time Indy winner",
    "A.J., the racer",
    "Four-time Indy winner",
    "Four-time Indy 500 winner",
    "Auto racer A. J.",
    "Four-time Indy winner"
  ],
  "jami": ["\"Twister\" actress Gertz"],
  "grau": [
    "Dull color, in Dusseldorf",
    "Pulitzer-winning novelist Shirley Ann ___",
    "1965 Pulitzer novelist Shirley Ann ___"
  ],
  "negs": [
    "Sources of prints, for short",
    "Darkroom masters, for short",
    "Darkroom production, for short",
    "Developer's needs, for short",
    "Pics from which to make more pics",
    "Masters in photography: Abbr.",
    "Photog's cache",
    "Darkroom images, for short",
    "Darkroom items, for short"
  ],
  "dogy": ["Stray calf: Var.", "Item in a roundup"],
  "rudd": [
    "He played Ant-Man in 2015's \"Ant-Man\"",
    "\"Ant-Man\" star Paul",
    "Paul of \"Ant-Man\"",
    "Paul who co-starred in \"I Love You, Man\"",
    "Australian P.M. Kevin",
    "Paul of \"Knocked Up\"",
    "Race car driver Ricky a k a Iron Man",
    "1960's revolutionary Mark",
    "Late newsman Hughes ___"
  ],
  "ting": [
    "Sound repeatedly heard at a wedding reception",
    "Sound of a fork on a wineglass",
    "Little bell sound",
    "High tone?",
    "Sound of silver striking crystal",
    "Wind chimes sound",
    "Bell sound",
    "Tiny ringing sound",
    "Glassy sound"
  ],
  "tenk": [
    "Running event",
    "Many a charity run",
    "Long race, in brief",
    "Piece of cake for a marathoner",
    "It's no marathon",
    "Fun race",
    "Race of about 6.2 mi.",
    "Certain race ... or a cryptic title to this puzzle",
    "Lengthy footrace",
    "Race much shorter than a marathon",
    "Certain race, informally",
    "Common race",
    "Popular race"
  ],
  "mdli": [
    "Mid-16th-century year",
    "The year 1551",
    "Roman 1551",
    "Mid 16th-century year",
    "Year Boris Godunov was born",
    "Year the National University of Mexico was founded",
    "Year the National University of Mexico was founded",
    "Year of Boris Godunov's birth",
    "Year the oldest college in the Americas was founded, in Mexico City",
    "Livy's 1,551",
    "1551, in monuments",
    "Year in Ivan the Terrible's reign",
    "1551, on monuments",
    "16th-century date"
  ],
  "tass": [
    "Moscow news source",
    "Fourth-largest news agency in the world",
    "News agency for a 57-Down",
    "Itar-___ news agency",
    "Red alert source?",
    "Communist bloc news source",
    "Longtime Russian acronym",
    "Itar-___ news agency",
    "Onetime propaganda source",
    "Soviet news group",
    "News agency that was the first to report on Sputnik",
    "Cold war news source",
    "News source in a 14-Down",
    "Soviet agency created from Rosta",
    "Old propaganda source",
    "Cold war propaganda disseminator",
    "Info source for 58-Down",
    "Longtime name in news-gathering",
    "Former news gatherer",
    "Old name in news",
    "News source beginning in 1925",
    "Old propaganda propagator",
    "News agency started in 1925",
    "Transmitter of cold war news",
    "Soviet news agency",
    "Old propaganda source",
    "Red ink source?",
    "See 57-Across",
    "Big name in news beginning 1925",
    "Cold war news service",
    "Itar-___ news agency",
    "Old name in news",
    "Big name in foreign news",
    "Soviet news agency",
    "Itar-___ news agency",
    "Russia's Itar-___ news agency",
    "Cold war news name",
    "Foreign news source",
    "Putin quoter",
    "Provider for Pravda",
    "Sputnik coverer",
    "News agency founded in 1918",
    "Soviet news service",
    "Reporter on Sputnik",
    "News agency name",
    "Soviet news agency",
    "Soviet news agency",
    "Pravda provider",
    "Soviet news agency",
    "Soviet news agency",
    "Russia's Itar-___ news agency",
    "Old news agency",
    "Source for Pravda",
    "Soviet news agency",
    "Moscow news name",
    "Source for Pravda",
    "Itar- ___ (news agency)"
  ],
  "acop": [
    "Rent-___ (security guard)",
    "Rent-___",
    "\"I'm ___\" (Friday declaration)",
    "Rent-___",
    "Rent-___",
    "Rent-___",
    "Rent-___ (private security guard)",
    "Rent-___ (security person for hire)",
    "\"Rent-___\"",
    "\"Rent-___\" (1988 flick)",
    "Reynolds film \"Rent-___\"",
    "1988 film \"Rent-___\""
  ],
  "hics": [
    "Sots' sounds",
    "Saloon sounds",
    "Lush sounds",
    "Skid row sounds",
    "Tippling evidence",
    "Bar sounds",
    "Pickled utterances"
  ],
  "acut": [
    "___ above",
    "___ above (better than)",
    "___ above",
    "___ above",
    "___ above",
    "___ above the rest",
    "___ above",
    "___ above (superior to)"
  ],
  "yowl": [
    "Mournful cry",
    "Cry of pain",
    "Caterwaul",
    "Pained cry",
    "Wail",
    "Protest vehemently",
    "Wailing cry",
    "Cry of pain",
    "Caterwaul",
    "Long, dismal cry",
    "Long, dismal cry",
    "Distressed cry",
    "Protest long and loud",
    "Distressed cry",
    "Cry of pain"
  ],
  "cath": ["___ lab (place for an angiogram)", "Like J.F.K.: Abbr."],
  "faso": [
    "Burkina ___ (African land)",
    "Burkina ___ (Niger neighbor)",
    "Burkina ___ (African land)",
    "Burkina ___ (African land)",
    "Burkina ___",
    "Burkina ___",
    "Burkina ___",
    "Burkina ___",
    "Burkina ___, neighbor of Mali",
    "Burkina ___",
    "Burkina ___",
    "Burkina ___"
  ],
  "onan": [
    "Be ___ equal footing",
    "___ empty stomach",
    "Send ___ errand",
    "\"Chestnuts roasting ___ open fire\"",
    "___ empty stomach",
    "___ incline (tilted)",
    "\"___ unrelated matter ...\"",
    "___ impulse",
    "Put ___ act",
    "Put ___ act",
    "___ incline",
    "Put ___ act",
    "___ open road",
    "___ equal footing",
    "___ irregular basis",
    "Second son of Judah",
    "___ outing",
    "\"___ unrelated note ...\"",
    "___ even keel",
    "Put ___ act",
    "___ impulse",
    "Son of Judah",
    "Son of Judah",
    "___ empty stomach",
    "___ equal footing",
    "___ empty stomach",
    "___ empty stomach",
    "___ even keel",
    "Grandson of Leah",
    "\"___ unrelated note ...\"",
    "Grandson of Leah",
    "___ outing",
    "___ even keel",
    "Son of Judah",
    "Go ___ outing",
    "___ upswing",
    "Son of Judah",
    "___ even keel",
    "\"___ Island With You\" (1948 film)",
    "\"___ unrelated note .\"",
    "Son of Judah",
    "Son of Judah",
    "___ impulse",
    "John Synge poem \"___ Anniversary\"",
    "___ empty stomach",
    "___ epic scale",
    "\"Chestnuts roasting ___ open fire\"",
    "___ even keel",
    "Son of Judah",
    "___ even keel",
    "Son of Judah",
    "___ empty stomach",
    "\"...___ open fire\"",
    "\"Chestnuts roasting ___ open fire\"",
    "___ even keel",
    "With 37-Across, steady",
    "Son of Judah",
    "___ empty stomach",
    "\"Chestnuts roasting ___ open fire\"",
    "___ even keel",
    "Son of Judah",
    "___ errand",
    "Son of Judah",
    "___ errand",
    "___ even keel",
    "___ even keel",
    "___ even keel",
    "___ impulse",
    "___ even keel",
    "___ even keel",
    "Judah's second son",
    "Son of Judah",
    "Synge's \"___ Island\"",
    "___ errand of mercy",
    "___ even keel",
    "___ impulse"
  ],
  "libs": [
    "Mad ___",
    "The left, informally",
    "Mad ___",
    "The left, informally",
    "Left-wingers",
    "Ad ___",
    "Ad-___ (improvises)"
  ],
  "etun": [
    "Vingt-___ (multiple de trois)",
    "Vingt-___ (blackjack)",
    "Vingt-___",
    "Vingt-___ (casino game)"
  ],
  "trya": [
    "\"___ Little Tenderness\"",
    "\"___ Little Tenderness\" (1960s hit)",
    "\"___ Little Tenderness\"",
    "Sam Cooke's \"___ Little Love\"",
    "\"___ Little Tenderness\"",
    "\"___ Little Tenderness\" (1960s hit)",
    "___ sample",
    "\"___ Little Tenderness\"",
    "\"___ Little Tenderness\"",
    "\"___ Little Tenderness\" (old hit song)"
  ],
  "elul": [
    "Month after Av",
    "Hebrew month",
    "Month in which Creation began, by tradition",
    "Month after Av",
    "Month before Tishri",
    "Jewish month after Av",
    "Hebrew month",
    "Ab follower",
    "Month before Tishri",
    "Month before Tishri",
    "Hebrew month",
    "Jewish month",
    "Month after Ab",
    "End of the Jewish calendar",
    "Jewish month",
    "End of the year, for some",
    "Month after Av",
    "Month after Ab",
    "Av follower",
    "Month after Av",
    "Last month of the Jewish calendar",
    "Jewish month",
    "Lead-in to Tishri",
    "Month after Av"
  ],
  "tipi": [
    "Plains dwelling: Var.",
    "Home on the range: Var.",
    "Home on the range: Var.",
    "Home of the brave?: Var."
  ],
  "mdci": [
    "The year 1601",
    "Year the mathematician Pierre de Fermat was born",
    "Early 17th-century year",
    "Start of the 17th century"
  ],
  "samp": [
    "Corn porridge",
    "Hominy cereal",
    "Coarse hominy",
    "Corn porridge",
    "Coarse hominy"
  ],
  "gage": [
    "Bunker Hill general",
    "English general in the American Revolution",
    "Revolutionary War general Thomas",
    "Thomas ___, last royal governor of Massachusetts",
    "Plum variety",
    "Revolutionary War general Thomas ___",
    "Plum variety, for short",
    "British governor of Massachusetts, 1775",
    "\"Eleni\" author Nicholas",
    "Old-fashioned pledge",
    "Knight's glove",
    "British general of 1775"
  ],
  "jims": [
    "Slim ___ (snack sticks)",
    "\"Huckleberry Finn\" character and others"
  ],
  "erns": [
    "Shore birds",
    "Sea eagles",
    "Shoreline avifauna",
    "Coastal fish consumers",
    "Cousins of kites",
    "Flying fish-eaters",
    "Sea eagles",
    "Birds of prey",
    "Sea eagles",
    "Kite kin",
    "Kite relatives",
    "Relatives of kites",
    "Sea eagles",
    "Birds with \"meat cleaver\" bills",
    "Shore birds",
    "Flying piscivores",
    "Coastal raptors",
    "Coastal avifauna",
    "Shore scavengers",
    "Marine birds",
    "Graceful fliers",
    "Diving raptors",
    "Coastal raptors",
    "Coastal raptors",
    "Seaside soarers",
    "Sea eagles",
    "Shore soarers",
    "Shore birds",
    "Flying fish eaters",
    "Coastal raptors",
    "Shore scavengers",
    "Sea eagles",
    "Sea eagles",
    "Marine birds",
    "Sea eagles",
    "Coastal raptors",
    "Fliers with wedge-shaped tails",
    "White-tailed birds",
    "Coastal raptors",
    "Fish-eating birds",
    "Some eagles",
    "Seabirds",
    "Atlantic fliers",
    "Sea divers",
    "Sea eagles",
    "Littoral fliers",
    "Sea birds",
    "Eagles",
    "Some eagles"
  ],
  "kith": [
    "Friends and neighbors",
    "Familiar folks",
    "___ and 14-Across (family)",
    "___ and kin",
    "Friends and neighbors",
    "Friends and neighbors",
    "Friends",
    "Acquaintances",
    "Friends and neighbors",
    "50-Across's partner",
    "Kin's partner",
    "Friends and neighbors",
    "Friends and neighbors",
    "Friends and neighbors",
    "Kin's partner",
    "Kin's partner",
    "Kin's partner",
    "Kiss, to 47 Down",
    "___ and kin"
  ],
  "ilay": [
    "\"Now ___ me down to sleep\"",
    "\"Now ___ me down to sleep\"",
    "Bedtime prayer words after \"Now\"",
    "\"As ___ Dying\"",
    "Robert Fulghum's \"It Was on Fire When ___ Down on It\"",
    "\"___ me down to rest me\" (old prayer start)",
    "\"Now ___ me down...\"",
    "\"Now ___ me down...\"",
    "\"Now___me down to sleep\"",
    "\"Now ___ me down...\"",
    "\"Now ___ me down...\"",
    "\"Now ___ me down...\""
  ],
  "huit": [
    "Eight, in France",
    "French 4 + 4",
    "Number before neuf",
    "Un plus sept",
    "Eight: Fr.",
    "Quatre + quatre",
    "Quatre doubled",
    "Twice quatre"
  ],
  "pick": [
    "See 53-Across",
    "What may help break the ice",
    "Choose",
    "Item for a guitarist or a prospector",
    "Horse on a ticket",
    "Choose",
    "Miner's tool",
    "Go with",
    "Forty-niner's equipment",
    "Dentist's tool",
    "Eat like a bird",
    "Ice breaker",
    "Basketball maneuver"
  ],
  "neds": [
    "Nickelodeon's \"___ Declassified School Survival Guide\"",
    "Nascar Hall-of-Famer Jarrett and others",
    "Actors Beatty and Sparks",
    "\"___ Declassified\" (old Nickelodeon show)",
    "Oscar nominee Beatty and others",
    "Fictional Flanders and Plimpton",
    "Relatives of Teddys?",
    "Flanders and Kelly",
    "Fictional Flanders and Devine",
    "Nickelodeon's \"___ Declassified School Survival Guide\"",
    "Beatty and Flanders",
    "\"___ Declassified School Survival Guide\"",
    "Nancy Drew's beau and others",
    "ABC newsman Potter and others",
    "Actor Beatty and others",
    "Beatty and Sparks",
    "\"Homer and ___ Hail Mary Pass\" (\"The Simpsons\" episode)",
    "Composer Rorem and others",
    "___ Point Lighthouse, Massachusetts landmark since 1838",
    "___ Atomic Dustbin (English rock band)",
    "Beatty and others",
    "Neighbor of Homer, and others",
    "Composer Rorem and others",
    "Newsman Potter and others",
    "Actor Beatty and others",
    "___ Point Lighthouse, on Buzzards Bay",
    "\"___ Newt\" (Cartoon Network series)",
    "Sparks and others",
    "Oldtime radio kids' show \"Uncle ___ Squadron\"",
    "Beatty and Rorem",
    "Beatty and Buntline",
    "Beatty and Rorem",
    "TV newsman Potter and others",
    "Sparks et al.",
    "Composer Rorem and others",
    "Beatty and Rorem",
    "Buntline and Rorem",
    "Actor Beatty and others",
    "Sparks and namesakes"
  ],
  "ists": [
    "Followers: Suffix",
    "Adherents",
    "Believers: Suffix",
    "Believers",
    "Followers: Suffix",
    "Motor add-ons?",
    "Believers",
    "Cult followers?",
    "They believe",
    "Followers: Suffix",
    "Followers: Suffix",
    "Believers: Suffix",
    "Adherents",
    "Adherents",
    "Believers",
    "Believers",
    "Followers",
    "Practitioners' suffix",
    "Political suffixes",
    "Followers: Suffix",
    "Followers",
    "Adherents",
    "Career suffixes",
    "Adherents",
    "Social hangers-on",
    "Followers",
    "Professional suffixes"
  ],
  "pons": [
    "Lily of the opera",
    "Linking brainstem part",
    "Diva Lily",
    "Lily who debuted as Lakme",
    "Bridge: Lat.",
    "Diva Lily",
    "French-born Met diva",
    "Operatic Lily",
    "Soprano Lily",
    "Longtime Met soprano",
    "Diva Lily",
    "Singer Lily"
  ],
  "lbar": [
    "Beam bent at 90??",
    "90-degree angle iron",
    "Letter-shaped beam",
    "Bent beam",
    "Letter-shaped shelf support",
    "Angle iron",
    "*Beam with a bend",
    "Angle iron",
    "Beam with a 90 degree bend",
    "Iron brace"
  ],
  "ohos": [
    "Surprised exclamations",
    "\"Look what I found!\" cries",
    "Cries of discovery",
    "Shouts of surprise",
    "Cries of surprise",
    "Cries of surprise",
    "Cries of surprise",
    "Cries of surprise",
    "Cries of discovery",
    "Cries of surprise",
    "Cries of surprise",
    "Cries of surprise",
    "Cries of surprise"
  ],
  "bota": ["Leather bag for wine"],
  "bind": [
    "Secure",
    "Hamstring",
    "Tie up",
    "Jam",
    "Tough situation",
    "Tight spot",
    "Sticky situation",
    "Tricky situation",
    "Tie up",
    "Tough spot",
    "Bad thing to be in",
    "Tough spot",
    "Fasten",
    "Glue",
    "Pickle",
    "Tie up",
    "Predicament",
    "Predicament",
    "Pickle"
  ],
  "cubs": [
    "\"Jinx\" breakers of 2016",
    "Team supposedly cursed by a billy goat",
    "Litter lying around a den",
    "Windy City team",
    "Wrigley Field team",
    "Den denizens",
    "8-Down also-rans to the 39-Across in 1918",
    "Wrigley team",
    "Tinker, Evers and Chance",
    "Denmates",
    "Forest litter",
    "See 80-Down",
    "Den deliveries",
    "Chicago nine",
    "1907-8 Series winner",
    "Chicago team",
    "1907-08 World Series champs",
    "Ernie Banks' team",
    "Certain reporters"
  ],
  "velo": [
    "Lead-in to -drome",
    "Rider-propelled vehicle, for short",
    "Prefix with -drome"
  ],
  "inso": [
    "Not ___ many words",
    "Not ___ many words",
    "\"___ doing ...\"",
    "___ many words",
    "\"___ doing ...\"",
    "Not ___ many words",
    "\"___ doing ...\"",
    "Preceder of many words?",
    "Not ___ many words",
    "\"___ doing ?à\"",
    "Not ___ many words",
    "___ many words",
    "Not ___ many words",
    "___ many words",
    "___ many words",
    "___ many words",
    "___ many words",
    "\"___ doing ...\"",
    "Not ___ many words",
    "___ many words",
    "___ many words",
    "___ many words",
    "Preceder of many words?",
    "___ many words",
    "___ many words",
    "___ many words",
    "Not ___ many words",
    "Start of many words?",
    "___ many words",
    "___ many words",
    "___many words",
    "___ many words",
    "___ many words",
    "___ many words",
    "___ many words"
  ],
  "figs": [
    "Mediterranean fruits",
    "Fiber-rich fruits",
    "Mediterranean fruit trees",
    "Mediterranean harvest",
    "Big Turkish export",
    "Illustrations: Abbr.",
    "Often-dried fruit",
    "Pudding ingredients"
  ],
  "nita": [
    "Silent screen actress Naldi",
    "1920s silver screen star Naldi",
    "Silent screen vamp Naldi",
    "Naldi of film",
    "Silent screen star Naldi",
    "Old-time actress Talbot or Naldi",
    "Actress Naldi of the silents",
    "Old-time actress Talbot or Naldi",
    "New York congresswoman Lowey",
    "Silent screen star Naldi",
    "1920s leading lady ___ Naldi",
    "Naldi of the Ziegfeld Follies",
    "Actress Naldi of the silents",
    "___ Naldi, who co-starred with Valentino",
    "Actress Talbot",
    "Congresswoman Lowey",
    "Naldi of old films",
    "Italian-American film vamp Naldi",
    "New York congresswoman ___ Lowey",
    "Actress Talbot",
    "Naldi of the Ziegfeld Follies",
    "Silents star Naldi",
    "Silent actress Naldi",
    "Naldi of the \"Ziegfeld Follies\"",
    "Actress Naldi",
    "Actress Naldi",
    "Actress Talbot",
    "Actress Talbot",
    "Actress Talbot",
    "Naldi of silents",
    "Actress Talbot"
  ],
  "goup": [
    "Ascend",
    "Burst into flames",
    "Increase",
    "Ascend",
    "Increase",
    "Rise",
    "Increase",
    "Shin",
    "Climb",
    "Scale",
    "Rise"
  ],
  "puck": [
    "Trickster in \"A Midsummer Night's Dream\"",
    "One reaching a goal?",
    "Hockey disk",
    "It gets slapped around a lot",
    "Robin Goodfellow",
    "Prankish person",
    "Oberon's imp"
  ],
  "alui": [
    "His: Fr.",
    "His: Fr.",
    "Writing on a French gift tag",
    "Parisian possessive",
    "His: Fr.",
    "Dedication to an unnamed homme",
    "C'est ___ (it's his)"
  ],
  "wort": [
    "Extract used in brewing",
    "St. John's, for one",
    "St. John's ___ (herbal remedy)",
    "Ending of some plant names",
    "Herbaceous plant",
    "Follower of St. John's",
    "Plant, in combos",
    "Ending of many plant names"
  ],
  "anta": [
    "Nonprofit Broadway production grp.",
    "Old Broadway production grp.",
    "Broadway acronym",
    "Architectural pier",
    "\"Man of La Mancha\" production org.",
    "Architectural pier",
    "Architectural pier",
    "\"Man of La Mancha\" org.",
    "Theater org.",
    "Stage org.",
    "Stage org.",
    "Stage org.",
    "Broadway acronym",
    "Architectural pier",
    "Architectural pier",
    "Pilaster",
    "Pier, in architecture",
    "Architectural pier",
    "Rectangular pier",
    "Theater acronym",
    "Rectangular pilaster",
    "Theater org."
  ],
  "otbs": [
    "Wagering sites, for short",
    "Some parlors, for short",
    "Parlors with TV screens: Abbr.",
    "Places where the Daily Racing Form is read, in brief",
    "Gambling venues, briefly",
    "Some parlors, for short",
    "Wagering parlors, for short",
    "Wagering sites, for short",
    "Wagering places, for short",
    "Some wagering parlors, briefly"
  ],
  "swee": [
    "___'Pea",
    "___'Pea",
    "___'Pea",
    "___'Pea (Popeye's kid)",
    "\"Little ___' Pea\"",
    "___' Pea",
    "___' Pea",
    "Popeye's ___' Pea",
    "Popeye's ___' Pea",
    "Popeye's ___' Pea",
    "___' Pea",
    "\"Little ___' Pea\" (1936 cartoon)",
    "___' Pea",
    "Popeye's ___' Pea",
    "___' Pea",
    "___' Pea (Popeye's boy)",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea",
    "___' Pea (Popeye's kid)",
    "Popeye's ___'pea",
    "___'Pea",
    "___' Pea",
    "Popeye's ___ Pea",
    "___ Pea (Popeye's kid)",
    "Popeye's ___ Pea",
    "___'pea"
  ],
  "boll": [
    "___ weevil",
    "Meal for a weevil",
    "Cotton pod",
    "Flax pod",
    "Cotton capsule",
    "Picker's target",
    "Weevil's target",
    "Seed pod of flax",
    "___ weevil",
    "Cotton pod",
    "Plant pod",
    "Literature Nobelist Heinrich",
    "Cotton pod"
  ],
  "thos": [
    "Pres. Jefferson",
    "Pres. Jefferson",
    "Heyward, Stone or Nelson, as each signed the Declaration of Independence",
    "Pres. Jefferson",
    "Wilson's vice pres. ___ Marshall",
    "Pres. Jefferson",
    "Companion of Geo. or Wm.",
    "Pres. Jefferson",
    "Pres. Jefferson",
    "Jefferson or Edison: Abbr.",
    "Relative of Wm. or Robt."
  ],
  "larp": ["Acronym for an outdoor fantasy game"],
  "hemo": [
    "Prefix with -stat",
    "Blood: Prefix",
    "Prefix with globin",
    "Globin lead-in"
  ],
  "gays": ["Target audience of Out magazine", "The out crowd?"],
  "bova": ["Six-time Hugo Award winner Ben"],
  "fenn": [
    "Actress Sherilyn who was an Emmy nominee for \"Twin Peaks\"",
    "Sherilyn of \"Twin Peaks\"",
    "\"Boxing Helena\" star Sherilyn",
    "\"Twin Peaks\" actress Sherilyn",
    "George Manville ___, English adventure writer"
  ],
  "giro": [
    "Big name in bicycle helmets",
    "Copter's forerunner",
    "Big name in cycling helmets",
    "Early copter",
    "Early whirlybird",
    "Copter's forerunner",
    "Copter's forerunner",
    "Copter kin"
  ],
  "danl": [
    "Twain's \"celebrated jumping frog\"",
    "Frontiersman Boone, informally",
    "Frontiersman Boone, familiarly",
    "___ Webster, Twain's \"celebrated jumping frog\"",
    "Twain's \"celebrated jumping frog\"",
    "Frontiersman Boone, informally",
    "Twain's \"celebrated\" frog",
    "Relative of Thos. or Wm.",
    "\"Young ___ Boone\" (short-lived 1970s TV series)",
    "Pioneer Boone, familiarly",
    "Frontier name, for short",
    "Boone, to rustics",
    "Twain's jumping frog",
    "Mark Twain's jumping frog",
    "Frontier nickname",
    "Frontiersman Boone, for short",
    "Twain's celebrated jumping frog",
    "Frontiersman Boone, informally",
    "First name in frontiersmen",
    "Part of Boone's signature"
  ],
  "gwyn": [
    "Early British actress Nell",
    "Early English actress Nell ___",
    "17th-century actress Nell"
  ],
  "onei": [
    "R.E.M.'s \"The ___ Love\"",
    "\"That's ___ haven't heard\"",
    "\"Dedicated to the ___ Love\"",
    "\"No ___ think is in my tree\" (\"Strawberry Fields Forever\" lyric)",
    "\"You're the ___ Love\"",
    "\"The ___ Love\" (R.E.M. hit)",
    "\"This is dedicated to the ___ love\"",
    "\"That's ___ hadn't heard\"",
    "\"That's ___ haven't heard\"",
    "\"The ___ Love\" (1987 hit)",
    "\"The ___ have almost forgot\": Shak.",
    "\"The ___ Love\"",
    "R.E.M.'s \"The ___ Love\"",
    "\"The ___ Gave My Heart To\" (1997 Aaliyah hit)",
    "\"Dedicated to the ___ Love\"",
    "\"That's ___ haven't heard!\"",
    "\"The ___ Love\"",
    "\"The ___ Love\" (hit by 42-Across)",
    "\"The ___ Love\" (R.E.M. hit)",
    "\"The ___ Love\" (1987 hit)",
    "\"Phew! Finally ___ know!\"",
    "\"Dedicated to the ___ Love\"",
    "\"That's ___ haven't heard!\"",
    "R.E.M.'s \"The ___ Love\"",
    "\"Dedicated to the ___ Love\"",
    "\"The ___ Love\" (R.E.M. hit)",
    "R.E.M.'s \"The ___ Love\"",
    "\"That's ___ haven't heard\"",
    "R.E.M.'s \"The ___ Love\"",
    "\"The ___ Gave My Heart To\" (1997 pop hit)",
    "R.E.M.'s \"The ___ Love\"",
    "R.E.M.'s \"The ___ Love\"",
    "R.E.M.'s \"The ___ Love\"",
    "\"You're the ___ Care For\" (1931 song)",
    "\"The ___ Love\" (R.E.M. hit)",
    "\"You're the ___ Care For\" (1931 hit)",
    "Gus Kahn song \"The ___ Love\"",
    "\"You're the ___ Care For\" (1930 hit)",
    "\"You're the ___ Care For\" (1930 song)",
    "\"The ___ Love\" (1987 R.E.M. hit)",
    "R.E.M.'s \"The ___ Love\"",
    "\"You're the___ Care For\"",
    "\"Dedicated to the ___ Love\"",
    "\"The ___ Love\" (Al Jolson tune)"
  ],
  "itto": [
    "Stick ___",
    "Leave ___ the pros",
    "\"Break ___ me gently\"",
    "Hand ___",
    "\"Gotta hand ___ ya ...\"",
    "\"Sock ___ me!\"",
    "Give ___ (berate)",
    "Stick ___",
    "Stick ___",
    "Give ___ (lambaste)",
    "Stick ___",
    "\"Tell ___ the judge\"",
    "The Eagles' \"Take ___ the Limit\"",
    "\"What's ___ you?\"",
    "\"Tell ___ the judge!\"",
    "The Eagles' \"Take ___ the Limit\"",
    "Put ___ (ask a hard question)",
    "\"Take ___ the Limit\"",
    "\"Tell ___ the marines!\"",
    "\"Sock ___ me!\"",
    "\"Suffice ___ say...\"",
    "\"Tell ___ the judge\"",
    "\"Takin' ___ the Streets\" (Doobie Brothers hit)",
    "\"Leave ___ Beaver\"",
    "\"Tell ___ My Heart\" (1987 hit)",
    "Put ___ (ask hard questions of)",
    "\"Tell ___ the Marines!\"",
    "\"Tell ___ the Marines!\"",
    "\"What's ___ you?\"",
    "Give___(curse)",
    "\"Tell___the judge\"",
    "\"Leave___Beaver\"",
    "\"Do___Me\" (1992 #1 hit)"
  ],
  "mers": [
    "Seas overseas",
    "Bodies of eau",
    "French seas",
    "Cousteau's milieux",
    "French seas",
    "French seas",
    "French seas",
    "Bleu parts of French maps",
    "Seas of France",
    "They lap France's coasts",
    "La Rouge et la Noire",
    "Oceans",
    "French seas",
    "Sartre's seas",
    "Seas, to Cousteau",
    "Bodies of eau"
  ],
  "ilet": [
    "\"Before ___ you go ...\"",
    "\"Before ___ you go ...\"",
    "Abba's \"___ the Music Speak\"",
    "\"Before ___ you go ...\"",
    "\"How could ___ this happen?\"",
    "\"___ a Song Go Out of My Heart\"",
    "\"Why did ___ this happen?\"",
    "\"How did ___ this happen?\"",
    "Abba's \"___ the Music Speak\"",
    "\"Why should ___ you?\"",
    "\"___ Her Go\" (Frankie Laine hit)",
    "Abba's \"___ the Music Speak\"",
    "\"Before ___ you go ?à\"",
    "Frankie Laine's \"___ Her Go\"",
    "\"___ a song go out ?à\"",
    "\"Why should ___ you?\"",
    "\"___ a song go ...\"",
    "\"___ Her Go\" (Frankie Laine song)",
    "\"Why should ___ you?\"",
    "\"Before ___ you go...\"",
    "\"___the Stars Get in My Eyes\" (1953 country hit)",
    "\"___ a song...\"",
    "\"___ a song go...\""
  ],
  "avas": [
    "Actress Gardner and others",
    "Gardner namesakes",
    "Actress Gardner and others",
    "Roles on \"Evening Shade\" and \"Nip/Tuck\"",
    "Actress Gardner and others",
    "Prizes for video production",
    "Clark's \"Mogambo\" co-star and others",
    "Gardner and others",
    "Gardner and others",
    "Rock video prizes",
    "Prizes for MTV artists",
    "Gardner and others",
    "Gardner and others",
    "MTV prizes",
    "Rock video awards",
    "Trouper Gardner et al."
  ],
  "octs": [
    "Fall times: Abbr.",
    "Fall mos.",
    "Fall times: Abbr.",
    "Fourth qtr. starters",
    "Fall mos.",
    "Fall times: Abbr.",
    "Fall times: Abbr.",
    "Fall mos."
  ],
  "subj": [
    "Eng. or hist.",
    "Hist. or Eng.",
    "Topic: Abbr.",
    "Part of a sentence: Abbr"
  ],
  "mung": [
    "Kind of bean",
    "___ bean",
    "___ bean",
    "Kind of bean",
    "Kind of bean",
    "Kind of bean",
    "Kind of bean",
    "___ bean",
    "Bean-sprout bean"
  ],
  "mose": [
    "Jazz pianist Allison",
    "Blues musician ___ Allison",
    "Jazzman ___ Allison",
    "Jazzman Allison",
    "Jazz pianist Allison",
    "Jazzman Allison",
    "Jazz pianist Allison",
    "Jazz pianist Allison"
  ],
  "dewy": [
    "Like morning grass",
    "Like morning grass",
    "Like morning grass",
    "Youthful and fresh",
    "Moist, as morning grass",
    "Moist",
    "Moist, as morning grass",
    "Moist, as morning grass",
    "Like morning grass",
    "___-eyed",
    "Wet, as morning grass",
    "Like morning grass",
    "Moist",
    "___-eyed (naive)",
    "Like a lawn at dawn",
    "___-eyed",
    "Moist",
    "Moist in the morning",
    "___-eyed (naive)",
    "Moist",
    "Damp",
    "Like a morning meadow",
    "Like some morning grass",
    "Wet, as morning grass",
    "Slightly wet",
    "Moist in the morning",
    "___-eyed",
    "Moist",
    "Moist",
    "Moist",
    "Slightly wet",
    "Moist",
    "Moist, perhaps"
  ],
  "what": [
    "\"___ the heck\"",
    "\"Huh?\"",
    "\"Huh?\"",
    "\"Come again?\"",
    "Word after say or now",
    "\"Huh?\"",
    "Word from the hard-of-hearing",
    "\"Say that again?\"",
    "With 54-Down, start of a historic telegraph message",
    "Incredulous reply",
    "The second of the five W's",
    "\"Guess ___?\"",
    "Question from one in another room",
    "\"Come again?\"",
    "\"Say again?\"",
    "One of the five W's",
    "\"Huh?\"",
    "\"Come again?\"",
    "\"Huh?\"",
    "\"Are you serious?!\"",
    "\"Say ___?\"",
    "Request for repetition",
    "\"Come again?\"",
    "Journalist's question",
    "One of the five W's",
    "\"Eh?\"",
    "\"Eh?\"",
    "\"Huh?\"",
    "\"Know ___?\""
  ],
  "graz": [
    "Austrian city where Kepler taught",
    "Arnold Schwarzenegger's Austrian birthplace"
  ],
  "kofi": [
    "Former U.N. secretary general ___ Annan",
    "Annan of the U.N.",
    "First name at the U.N., once",
    "Diplomat Annan",
    "Diplomat Annan",
    "First name in international diplomacy",
    "Diplomat Annan",
    "First name in diplomacy",
    "The U.N.'s ___ Annan",
    "With 74-Across, U.N. chief Annan"
  ],
  "suzi": ["\"Stumblin' In\" singer Quatro", "Rocker Quatro"],
  "intr": [
    "Like some verbs: Abbr.",
    "Like some verbs: Abbr.",
    "Like some verbs: Abbr.",
    "Preface: Abbr.",
    "Like some verbs: Abbr.",
    "Verb type: Abbr.",
    "Like 101 courses: Abbr.",
    "Like some verbs: Abbr.",
    "Like some verbs: Abbr.",
    "Foreword: Abbr.",
    "Verb type: Abbr.",
    "Foreword: Abbr.",
    "Verb type: Abbr."
  ],
  "koop": [
    "C. Everett ___, 1980s surgeon general",
    "Surgeon general under Reagan",
    "Reagan-era surgeon general",
    "Surgeon general under Reagan",
    "Surgeon General with a beard"
  ],
  "gung": ["___-ho", "Ho lead-in"],
  "mora": [
    "Football coach Jim",
    "Either of two N.F.L. coaches named Jim",
    "N.F.L. coach Jim",
    "Third baseman and two-time All-Star Melvin ___",
    "Third baseman Melvin",
    "Last name of father-and-son N.F.L. coaches",
    "Melvin of the Orioles",
    "N.F.L. coach Jim",
    "Jim ___, onetime N.F.L. Coach of the Year",
    "N.F.L. coach Jim",
    "N.F.L. coach Jim"
  ],
  "snax": [
    "Chips and popcorn, in commercialese",
    "Munchies, in ads",
    "Pretzels and chips, in adspeak"
  ],
  "tras": [
    "Refrain syllables",
    "Starts of some chorus lies",
    "Refrain syllables",
    "Refrain syllables",
    "Chorus syllables",
    "Skipping syllables",
    "Sounds to skip by",
    "Parts of chorus lines?",
    "Skipping syllables",
    "Musical syllables",
    "Skipping sounds",
    "Musical syllables",
    "Musical syllables"
  ],
  "knex": ["Lego competitor", "Building toy brand"],
  "your": [
    "\"Bite ___ tongue!\"",
    "What comes before honor?",
    "B.Y.O.B. part",
    "___ Grace",
    "M.Y.O.B. part",
    "Something said before grace?",
    "\"___ Song\" (Elton John hit)",
    "\"___ turn\"",
    "Not their",
    "Part of F.Y.I.",
    "Watch-step connection",
    "Part of B.Y.O.B.",
    "\"___ turn\"",
    "\"___ choice\"",
    "Elton John?Æs ?ô___ Song?ö",
    "The \"Y\" of B.Y.O.B.",
    "\"___ Hit Parade\"",
    "\"___ move\"",
    "\"What's ___ pleasure?\"",
    "___ Honor",
    "\"It's ___ move\"",
    "Part of B.Y.O.B.",
    "\"In___face!\"",
    "Part of 36-Down",
    "1953 hit ?ô___ Cheatin?Æ Heart?ö"
  ],
  "asim": [
    "\"___ sure you know ...\"",
    "\"___ sure you know ...\"",
    "\"Just ___ about to ?à\""
  ],
  "rena": [
    "Sofer of \"General Hospital\"",
    "1995 Emmy winner Sofer",
    "Soap actress Sofer",
    "Soap actress Sofer",
    "Actress Sofer",
    "Sofer of soaps",
    "Actress Sofer",
    "Sofer of \"Blind Justice\"",
    "Fashion designer Rowan",
    "Sofer of soaps",
    "Sofer of soaps",
    "Sofer of \"General Hospital\"",
    "Designer Rowan",
    "Actress Sofer",
    "Sofer of soaps",
    "\"General Hospital\" regular Sofer",
    "Hebrew name meaning \"joy\""
  ],
  "ssgt": [
    "Two steps above cpl.",
    "Army E-6: Abbr.",
    "Squad cmdr.",
    "Army NCO",
    "Squad leader: Abbr.",
    "Army NCO",
    "U.S.A.F. noncom",
    "U.S.A.F. E-5",
    "U.S. Army E-6",
    "Army NCO",
    "Mil. rank",
    "Army NCO",
    "E-5 in the U.S.A.F.",
    "An NCO",
    "U.S.A.F. rank",
    "Mil. rank",
    "Senior airman's superior: Abbr.",
    "Army N.C.O.",
    "Certain N.C.O.",
    "U.S.A.F. rank",
    "Army N.C.O.",
    "Army NCO",
    "Army N.C.O.",
    "Army E-6: Abbr.",
    "Certain N.C.O.",
    "A.P.O. addressee",
    "NCO",
    "An NCO",
    "Noncom: Abbr.",
    "Mil. officer"
  ],
  "maud": [
    "Bond girl Adams",
    "Bond girl Adams",
    "Tennyson title lady",
    "Bond girl Adams",
    "Adams of \"Octopussy\"",
    "Antarctica's Queen ___ Mountains",
    "Tennyson poem",
    "Actress Adams of James Bond films",
    "Tennyson poem",
    "Whittier heroine",
    "Miss Muller, of a Whittier poem"
  ],
  "pref": [
    "Choice: Abbr.",
    "Aisle or window, e.g.: Abbr.",
    "Aisle or window, e.g.: Abbr.",
    "Foreword: Abbr.",
    "Non- or un-: Abbr.",
    "Smoking or non, e.g.: Abbr."
  ],
  "geta": [
    "\"___ grip!\"",
    "\"___ grip!\"",
    "\"___ life!\"",
    "___ clue",
    "\"___ life!\"",
    "___ clue",
    "\"___ life!\"",
    "Words with ?ômove on?ö or ?ôlife?ö",
    "\"___ life!\"",
    "\"___ life!\"",
    "\"___ grip!\"",
    "\"___ Job\" (1958 hit)",
    "\"I Still___Thrill\" (1930 hit)",
    "\"___ life!\"",
    "\"___ life!\""
  ],
  "aram": [
    "Biblical region from which the name of a language is derived",
    "Biblical name of ancient Syria",
    "Composer Khachaturian",
    "Biblical name for Syria",
    "Composer Khachaturian",
    "Ancient Syria",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Saroyan's \"My Name Is ___\"",
    "Composer Khachaturian",
    "Bulwer-Lytton's \"Eugene ___\"",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "William Saroyan's \"My Name Is ___\"",
    "\"Eugene ___\" (1832 Bulwer-Lytton novel)",
    "Biblical name for Syria",
    "Ancient Syria",
    "Composer Khachaturian",
    "\"11 Harrowhouse\" director Avakian",
    "Composer Khachaturian",
    "Saroyan's \"My Name is ___\"",
    "Syria, long ago",
    "Composer Khachaturian",
    "80's-90's writer Saroyan",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Saroyan hero",
    "Composer Khachaturian",
    "Saroyan's \"My Name Is ___\"",
    "Ancient Syria",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Composer Khachaturian",
    "Saroyan character",
    "Saroyan's \"My Name Is ___\"",
    "Old Syria",
    "Old Syria",
    "Ancient Syria"
  ],
  "tove": ["Slithy one, to Carroll", "Carroll's \"slithy\" thing"],
  "iled": [
    "\"Where Is the Life That Late ___?\" (\"Kiss Me, Kate\" number)",
    "Old TV's \"___ Three Lives\"",
    "___'Orleans, Quebec",
    "Old TV's \"___ Three Lives\"",
    "\"___ Three Lives\"",
    "\"___ Three Lives\"",
    "___'Oleron (island off 35-Across)",
    "TV's \"___ Three Lives\"",
    "\"___ Three Lives\" (TV oldie)",
    "\"___ Three Lives\"",
    "\"___ Three Lives\"",
    "Philbrick's \"___ Three Lives\"",
    "Early TV's \"___Three Lives\"",
    "TV's \"___Three Lives\"",
    "France's ___ 'Oleron",
    "TV's \"___Three Lives\""
  ],
  "mans": [
    "Staffs",
    "Word repeated in James Brown's \"It's a ___ ___ ___ World\"",
    "Has control of",
    "Operates, as a booth",
    "Furnishes with soldiers",
    "Staffs",
    "Provides with personnel",
    "Staffs",
    "\"___ Fate\" (Andre Malraux novel)",
    "___ best friend",
    "Staffs",
    "Staffs",
    "Fortifies",
    "Staffs",
    "Staffs",
    "Staffs",
    "Old-___-beard (green lichen)",
    "Hires a crew, perhaps",
    "Eschews robots",
    "\"...on the Dead ___ Chest\"",
    "\".... ___ best friend\"",
    "___ best friend"
  ],
  "ator": [
    "Fabric finish?",
    "Suffix with liquid",
    "Suffix with origin or comment",
    "Suffix with origin",
    "Suffix with liquid",
    "Decor finish?",
    "Suffix with origin",
    "Suffix with liquid or fabric",
    "Valid conclusion?",
    "Decor finish?",
    "Suffix with fabric",
    "Origin suffix",
    "Ending with comment or liquid",
    "Suffix with decor",
    "Suffix with origin",
    "Suffix with origin",
    "Suffix with fabric",
    "Suffix with liquid",
    "Suffix with origin",
    "Fabric finish?",
    "Suffix with fabric",
    "Suffix with liquid",
    "Ending with origin",
    "Suffix with fabric or authentic",
    "Noun-forming suffix"
  ],
  "sqmi": [
    "One of about 1,000 in Lux.",
    "640 57-Acrosses: Abbr.",
    "Acreage fig.",
    "2,560 roods: Abbr.",
    "640 acres: Abbr.",
    "Gazetteer meas.",
    "640 acres: Abbr.",
    "Map meas."
  ],
  "dten": [
    "Battleship guess",
    "Fourth-row Battleship position",
    "Tank gun first produced by the Soviets in W.W. II",
    "Decahedron-shaped die, to a gamer",
    "Guess in Battleship",
    "Square at the end of the fourth row in Battleship"
  ],
  "goop": [
    "Sticky stuff",
    "Sticky stuff",
    "Hair gel, e.g.",
    "Hair gel, e.g.",
    "Sticky stuff",
    "Viscous substance",
    "Sticky stuff",
    "Gunk",
    "Sticky stuff",
    "Sticky stuff",
    "Sticky stuff",
    "Sticky stuff",
    "Gunk",
    "Muck",
    "Tar, e.g.",
    "Gunk",
    "Sticky stuff",
    "Slime",
    "Sticky stuff",
    "Mud",
    "Sticky stuff",
    "Sticky stuff",
    "Sticky stuff",
    "Gunk",
    "Sticky stuff",
    "Sticky stuff"
  ],
  "ehud": [
    "Israel's Olmert",
    "Former Israeli P.M. Barak",
    "Israel's Barak",
    "Book of Judges judge",
    "Former Israeli P.M. Barak",
    "Israel's Olmert",
    "Former Israeli P.M. Olmert",
    "Israel's Barak",
    "Israel's Olmert",
    "Israel's Olmert or Barak",
    "Israeli P.M. Olmert",
    "Israeli statesman Barak",
    "___ Barak, former Israeli P.M.",
    "Israel's Barak"
  ],
  "paps": ["Mushy foods", "Dads, in dialect"],
  "defs": [
    "Webster wrote many of them: Abbr.",
    "Dict. material",
    "Etym. followers, often",
    "Dict. info",
    "Webster's entries: Abbr.",
    "Dict. material",
    "O.E.D. stuff",
    "Dict. items"
  ],
  "vous": [
    "Counterpart of moi",
    "You, in Nice",
    "The \"V\" of R.S.V.P.",
    "\"Voulez-___\" (\"Mamma Mia!\" song)",
    "R.S.V.P. part",
    "Part of R.S.V.P.",
    "Part of R.S.V.P.",
    "\"Parlez-___ francais?\"",
    "Part of R.S.V.P.",
    "R.S.V.P. part",
    "Part of R.S.V.P.",
    "\"Comment allez-___?\""
  ],
  "hows": ["\"___ tricks?\"", "\"___ that again?\""],
  "raki": [
    "Turkish brandy",
    "Anise-flavored aperitif popular in Turkey and the Balkans"
  ],
  "sqft": [
    "About 6.5 million for the Pentagon: Abbr.",
    "Carpet meas.",
    "Apt. area measurement",
    "Room meas."
  ],
  "moby": [
    "\"___-Dick\"",
    "Musician who's a great-great-grandnephew of Herman Melville",
    "One-named musician with the hit albums \"18\" and \"Hotel\"",
    "With 59-Across, novel of 1851",
    "Popular techno musician",
    "Popular one-named techno musician",
    "___ Dick",
    "\"___-Dick\""
  ],
  "fohn": ["Warm Alpine wind", "Warm Alpine wind", "Warm, dry Alpine wind"],
  "itno": [
    "\"My Heart Can't Take ___ More\" (1963 Supremes song)",
    "\"Pay ___ mind\"",
    "\"Pay ___ mind\"",
    "\"Give ___ thought!\"",
    "\"Pay ___ mind\"",
    "\"Pay ___ mind\"",
    "\"Pay ___ mind\"",
    "\"Pay ___ mind\"",
    "\"Pay ___ mind\"",
    "\"Pay ___ mind\"",
    "Give___ thought",
    "Pay ___ mind"
  ],
  "sado": [
    "Prefix with masochistic",
    "Lead-in to masochism",
    "___-masochist",
    "Prefix with masochistic",
    "It can precede masochism",
    "___-masochism",
    "Lead-in to masochism",
    "___-masochist",
    "Masochist's start",
    "Prefix with masochism",
    "Prefix with masochism",
    "Masochist's start",
    "Prefix with masochism"
  ],
  "oeil": [
    "Coup d'___",
    "Trompe l'___",
    "Trompe l'___",
    "Part of a French face",
    "French for 44-Across",
    "Coup d'___ (quick glance)",
    "Coup d'___ (survey made with a glance)",
    "French sight-seer?",
    "Trompe l'___",
    "French eye",
    "Parisian peeper",
    "Concern of optometrie",
    "Trompe l'___ (art illusion)",
    "Monique's eye",
    "Trompe l'___",
    "Trompe l'___",
    "Trompe l'___ (optical illusion)",
    "Trompe I'___",
    "Trompe l' ___"
  ],
  "ndak": [
    "Home to Theo. Roosevelt Natl. Park",
    "State bordering Can.",
    "Fargo's home: Abbr.",
    "Neighbor of Sask.",
    "Mont. neighbor",
    "Fargo's state: Abbr.",
    "State south of Manitoba: Abbr.",
    "State straddling two time zones: Abbr.",
    "Fargo's home: Abbr.",
    "The Missouri R. runs through it",
    "Neighbor of Sask.",
    "Intl. Peace Garden state",
    "Home of Theo. Roosevelt Natl. Park",
    "State whose tallest bldg. has only 19 floors",
    "Home of Grand Forks: Abbr.",
    "Sask. neighbor",
    "Locale of Sitting Bull Coll.",
    "Home of Theo. Roosevelt Natl. Park",
    "Sask. neighbor",
    "Theo. Roosevelt Natl. Park locale",
    "Bismarck's locale: Abbr.",
    "Bismarck's home: Abbr.",
    "Theo. Roosevelt Natl. Park site",
    "Fargo's state: Abbr.",
    "Bismarck's state: Abbr.",
    "Bismarck's state: Abbr.",
    "Garrison Dam's state: Abbr.",
    "Neighbor of Minn.",
    "Peace Garden State: Abbr.",
    "Fargo's state: Abbr.",
    "Sask. neighbor",
    "Can. borderer",
    "Fargo's locale: Abbr.",
    "Sask. neighbor",
    "Minn. neighbor",
    "Sask. neighbor",
    "Neighbor of Minn.",
    "Site of Theo. Roosevelt Natl. Park",
    "Site of Theo. Roosevelt Natl. Park",
    "State since 1889: Abbr.",
    "Bismarck's state: Abbr.",
    "Sask. neighbor",
    "Mont. neighbor",
    "Peace Garden State: Abbr.",
    "Neighbor of Sas.",
    "Minn. neighbor",
    "Minn. neighbor",
    "Minn. neighbor"
  ],
  "encl": [
    "Business letter abbr.",
    "Env. within an env., perhaps",
    "S.A.S.E., e.g.: Abbr.",
    "Abbr. on a business letter",
    "Check within, say?: Abbr.",
    "Abbr. on the bottom of a business letter",
    "Return envelope, e.g.: Abbr.",
    "Abbr. at the bottom of a letter",
    "Bottom-of-letter abbr.",
    "Unsolicited MS., perhaps",
    "Letter abbr.",
    "Bottom-of-letter abbr.",
    "Bottom-of-letter abbr.",
    "Check inside?: Abbr.",
    "S.A.S.E., e.g.: Abbr.",
    "Letter accompanier: Abbr.",
    "Abbr. at the bottom of a business letter",
    "Abbr. at the bottom of a business letter",
    "S.A.S.E., e.g.",
    "Abbr. on a business letter",
    "Business letter abbr.",
    "Letter abbr.",
    "Abbr. on a business letter",
    "A check, one might hope: Abbr.",
    "Postal letters?",
    "Env. stuffer"
  ],
  "mtge": [
    "Part of F.N.M.A.: Abbr.",
    "Loan insured by the F.H.A.: Abbr.",
    "Lender's offering: Abbr.",
    "Buy-now-pay-later arrangement: Abbr.",
    "Homeowner's debt: Abbr.",
    "You can get one on the house: Abbr.",
    "Monthly pymt., for some",
    "Homeowner's concern: Abbr.",
    "Homeowner's pymt.",
    "Number of points?: Abbr.",
    "F.H.A. loan",
    "Bank deal: Abbr.",
    "Homeowner's payment: Abbr.",
    "Budget item: Abbr.",
    "Homeowner's pymt.",
    "F.H.A. loan"
  ],
  "roys": [
    "Artist Lichtenstein and others",
    "Rogers, Orbison and Yamaguchi",
    "Composer Harris and others",
    "Orbison and Bean",
    "Rogers and Bean",
    "Artist Lichtenstein and others",
    "Popular fast food chain, informally",
    "Rogers and Clark",
    "Rob ___ (Scotch manhattans)",
    "Rogers and Clark",
    "Rogers and others",
    "Rogers and others",
    "Popular fast-food chain, informally",
    "Popular fast-food chain, informally",
    "Rogers and Clark",
    "Popular fast-food chain, informally",
    "Tennis's Emerson and others",
    "Orbison and Rogers",
    "Orbison and Rogers"
  ],
  "cene": ["End of geologic time?", "End of an epoch?"],
  "atco": [
    "Record label for Cream and Sonny & Cher",
    "Big record label in 1960s pop",
    "Record label for Cream and the Bee Gees",
    "Classic record label",
    "Label for the Bee Gees",
    "Record label of the Beatles' \"Ain't She Sweet\"",
    "Classic record label for the Bee Gees and Cream",
    "Classic record label for the Bee Gees and Cream",
    "1960's-70's record label",
    "Old record label",
    "Old record label",
    "Old record company"
  ],
  "kali": [
    "Hindu goddess often portrayed with her husband Shiva",
    "Shiva's wife",
    "Malevolent Hindu goddess",
    "Many-armed Hindu goddess",
    "Wife of Shiva, in Hinduism",
    "Shiva's wife"
  ],
  "qaid": ["Muslim judge of North Africa"],
  "comp": [
    "Give gratis",
    "Give for free, as a ticket",
    "Give for free, slangily",
    "Give for free",
    "Give for free",
    "Give for free",
    "*Provide for free",
    "Freebie",
    "Provide for free, informally",
    "Give at no charge, as a hotel room",
    "Give a free pass",
    "Give a free pass",
    "Freebie",
    "Worker's ___ (insurance)",
    "Gratis",
    "Provide gratis",
    "Free, as a ticket",
    "Free ticket",
    "Provide for free, informally",
    "Free ticket",
    "Free ticket",
    "Free, as a subscription",
    "Workers' ___"
  ],
  "nuff": ["\"___ said!\"", "\"___ said!\"", "\"___ said!\""],
  "emly": [
    "\"Little\" girl in \"David Copperfield\"",
    "Mr. Peggotty's \"little\" niece",
    "\"Little\" girl in \"David Copperfield\"",
    "Peggotty girl in \"David Copperfield\"",
    "\"Little\" girl in \"David Copperfield\"",
    "Dickens's Little ___"
  ],
  "agut": [
    "Bust ___ (guffaw)",
    "Bust ___",
    "Bust ___",
    "Bust ___ (laugh uproariously)"
  ],
  "sono": [
    "OB/GYN's prefix with -gram",
    "Pic of an unborn child",
    "\"Dove ___\" (Mozart aria)",
    "\"I am,\" in Italy",
    "\"Ronzoni ___ buoni\" (old ad slogan)",
    "Prefix with -gram",
    "Mozart's \"Dove ___\"",
    "Prefix with gram or meter"
  ],
  "arun": [
    "Give ___ for one's money",
    "Give ___ for one's money",
    "Make ___ for it",
    "Give ___ for one's money",
    "Score ___ (reach home)",
    "Make ___ for it",
    "Give ___ for one's money",
    "Give ___ for one's money",
    "Get ___ for one's money",
    "Make ___ for it",
    "Give___for one's money",
    "Give___for one's money",
    "Give ___ for one's money",
    "Give ___ for one's money"
  ],
  "asye": [
    "\"___ sow, so shall ...\"",
    "\"___ sow ...\"",
    "\"... so long ___ both shall live?\"",
    "\"___ do, so he shall do\": Numbers 15:14",
    "\"___ sow, so shall ...\"",
    "\"... so long ___ both shall live?\"",
    "\"___ sow ...\"",
    "\"... so long ___ both shall live?\"",
    "\"___ sow, so shall ...\"",
    "\"___ sow ...\"",
    "\"... so long ___ both shall live\"",
    "Start of a sowing adage",
    "\"___ sow, so shall ...\"",
    "\"___ sow ...\"",
    "\"___ sow, so shall ...\"",
    "\"___ Came From the Holy Land\" (classic poem)",
    "\"...so long ___ both shall live?\"",
    "\"For ___ sow...\"",
    "\"___ sow, so shall...\"",
    "\"___ sow, so shall...\"",
    "\"___sow...\""
  ],
  "meis": ["\"Within ___ a hell\": Shak."],
  "mize": [
    "Hall-of-Fame slugger Johnny",
    "1987 Masters winner Larry",
    "Larry who won the 1987 Masters",
    "Baseball's Johnny, known as the Big Cat",
    "Slugger known as the Big Cat",
    "Baseballer known as the Big Cat"
  ],
  "gnma": ["Fed. lending agency"],
  "tyes": [
    "Sailing ropes",
    "Sailors' ropes",
    "Sailors' chains",
    "Sailing ropes",
    "Nautical ropes",
    "Sailing ropes",
    "Yardarm attachments",
    "Ship ropes",
    "Parts of topsails",
    "Nautical chains"
  ],
  "feds": [
    "RICO enforcers",
    "Gangsters' counterparts, informally",
    "G-men",
    "A.T.F. agents, e.g.",
    "Regulatory group",
    "D.C.-based agents",
    "A.T.F. agents, e.g.",
    "Some badge flashers",
    "Gangbusters",
    "Some badge holders",
    "Certain investigators, for short",
    "Gangsters' foes",
    "U.S. attorneys, e.g.",
    "Revenuers, e.g.",
    "A.T.F. agents, e.g.",
    "Government agents",
    "T-men, e.g.",
    "D.E.A. employees, e.g.",
    "Agents from D.C.",
    "Some officers",
    "A.T.F. agents",
    "Revenuers",
    "20's beer barrel busters",
    "Some narcs",
    "G-men"
  ],
  "upup": [
    "\"Superman\" catchphrase starter",
    "\"___ and away!\"",
    "\"___ and away!\"",
    "\"___ and away!\"",
    "Start of Superman's catchphrase"
  ],
  "cdef": [
    "Scale opening",
    "B's tail?",
    "Alphabet quartet",
    "Certain scale start",
    "Preceders of G",
    "Scale start",
    "Alphabet quartet"
  ],
  "lave": [
    "Wash",
    "Wash",
    "Take a bath",
    "Bathe",
    "Bathe",
    "Wash up",
    "Bathe",
    "Wash",
    "Wash",
    "Wash",
    "Wash",
    "Wash",
    "Cleanse",
    "Wash",
    "Wash",
    "Lap against, as the shore",
    "Wash",
    "Clean",
    "Wash",
    "Deterge",
    "Bathe",
    "Wash",
    "Wash"
  ],
  "acad": [
    "Military sch.",
    "Part of U.S.C.G.A.: Abbr.",
    "Part of A.M.P.A.S.: Abbr.",
    "It may be mil.",
    "Many a prep sch.",
    "Military sch.",
    "Part of U.S.M.A.: Abbr.",
    "Plebe's place: Abbr.",
    "Nav. ___",
    "Sch. known for its discipline",
    "Plebe's place: Abbr.",
    "Mil. school",
    "Part of U.S.N.A.: Abbr.",
    "Ens. producer",
    "Drill locale: Abbr.",
    "Military sch.",
    "Military sch.",
    "Military sch.",
    "Mil. school",
    "It may be nav.",
    "Part of 18-Across: Abbr.",
    "Learning ctr.",
    "U.S.M.A. part: Abbr.",
    "Military sch.",
    "Part of U.S.N.A.: Abbr.",
    "Military sch.",
    "Uniform locale: Abbr.",
    "West Point, e.g.: Abbr.",
    "Place for some preppies: Abbr.",
    "Sch. with tuition",
    "Part of A.M.P.A.S.: Abbr.",
    "Boarding sch.",
    "Inst.",
    "Military sch.",
    "Educ. establishment",
    "Mil. school",
    "Police sch."
  ],
  "ikes": [
    "\"___ Bluff: President Eisenhower's Secret Battle to Save the World\" (2012 book)",
    "Pre-Susan B. Anthony dollar coins, informally",
    "Mike and ___ (some jellybeans, informally)",
    "Eisenhower and Turner",
    "General store on \"The Waltons\"",
    "Turner and others",
    "Alibi ___ (excuse makers)",
    "Turner and others",
    "Alibi ___ (excuse makers)",
    "Turner and others",
    "Turner and Eisenhower",
    "Turner and others",
    "Alibi ___ (excuse makers)",
    "Alibi ___ (liars, of a sort)",
    "Namesakes of 57-Down",
    "Eisenhower and others",
    "Turner and Pappas"
  ],
  "arrr": ["Pirate's interjection"],
  "joey": [
    "Outback baby",
    "Bouncing baby",
    "Baby kangaroo",
    "Nickname for a namesake of Mary's husband",
    "\"Friends\" spinoff",
    "2004 spinoff show",
    "\"Friends\" spinoff",
    "Bishop of the Rat Pack",
    "Funny Bishop",
    "Young kangaroo",
    "Bishop of old TV"
  ],
  "endy": ["Onetime Expos/Mets outfielder Chavez"],
  "arbs": [
    "Some Wall St. traders",
    "Some Wall St. traders",
    "Wall St. manipulators",
    "Wall St. figures",
    "Wall St. figures",
    "Wall St. figures",
    "Wall St. figures",
    "Wall St. figures",
    "Market figures, briefly",
    "Financial wheeler-dealers, briefly",
    "Finance workers, for short",
    "Wall St. pros",
    "Some Wall St. traders"
  ],
  "fies": [
    "Cross words",
    "Expressions of outrage",
    "Cries of disgust",
    "Cries of disgust",
    "Declarations of disgust",
    "Cries of disgust"
  ],
  "seko": [
    "Zaire's Mobutu Sese ___",
    "Mobutu Sese ___, Zairian despot",
    "Zaire's Mobuto Sese ___",
    "Zaire's Mobuto Sese ___"
  ],
  "bonk": ["Knock on the noodle"],
  "abab": [
    "Basic rhyme scheme",
    "Simple quatrain pattern",
    "Scheme for the start of a sonnet",
    "Basic quatrain scheme",
    "Basic rhyme scheme",
    "Simple rhyme scheme",
    "Quatrain scheme",
    "Basic verse option",
    "Common rhyme scheme",
    "Like many of Shakespeare's rhymes",
    "Basic verse option",
    "Quatrain rhyme scheme",
    "Basic rhyme scheme",
    "Common rhyme scheme",
    "\"The Purple Cow\" rhyme scheme",
    "Basic rhyme scheme",
    "Common rhyme scheme",
    "Simple rhyme scheme",
    "Common rhyme scheme",
    "Simple rhyme scheme",
    "Common rhyme scheme",
    "Quatrain scheme",
    "Rhymester's scheme",
    "Unimaginative rhyme scheme",
    "Basic rhyme scheme",
    "Verse pattern",
    "Quatrain rhyme scheme",
    "Quatrain's pattern"
  ],
  "sami": [
    "People of Lapland",
    "Arctic residents",
    "Laplander",
    "Laplanders",
    "Language related to Finnish",
    "Northern Scandinavian"
  ],
  "llds": [
    "Parts of some law profs.' resumes",
    "Advanced law degs.",
    "Some Yale degs.",
    "Degrees for attys.",
    "Some attorneys' degs.",
    "Barristers' degs.",
    "Degs. from Yale and Harvard",
    "Honorary law degs.",
    "Some legal scholars, for short",
    "Attorneys' degs.",
    "Geo. Washington and M.L.K. Jr. were awarded them",
    "Attorneys' degrees: Abbr.",
    "Degrees for attys.",
    "Some legal scholars, for short",
    "Lawyers' degrees"
  ],
  "neas": ["Liberal political activist Ralph"],
  "obol": [
    "Ancient Greek coin",
    "Coin to pay for passage across the River Styx",
    "One-sixth of a drachma",
    "Ancient Greek coin",
    "Silver coin of ancient Greece",
    "Ancient Greek coin",
    "Ancient Greek coin",
    "One-sixth of a drachma"
  ],
  "nans": [
    "Indian flatbreads",
    "Servings with tandoori chicken",
    "Indian breads",
    "Tandoori-baked fare",
    "Tandoori-baked breads",
    "Tandoor flatbreads",
    "Bert Bobbsey's twin and others",
    "Tandoor-baked breads",
    "Photographer Goldin and others",
    "Namesakes of Bert Bobbsey's twin",
    "Actress Martin et al.",
    "Actresses Grey and Martin",
    "Actress Woods and others",
    "30's actress Grey and others"
  ],
  "zoic": [
    "Geological period suffix",
    "End of an era?",
    "Suffix on era names",
    "Suffix with Meso- or Paleo-",
    "Suffix on era names",
    "Suffix in the names of many eras",
    "Suffix on era names"
  ],
  "pari": [
    "Equal: Prefix",
    "___-mutuel betting",
    "___ passu (on equal footing)",
    "___-mutuel betting",
    "___ passu (equably)",
    "___-mutuel",
    "___-mutuel (form of betting)",
    "___-mutuel",
    "___ passu (equably)",
    "___-mutuel",
    "___-mutuel",
    "___-mutuel"
  ],
  "paco": [
    "Nickname for Francisco",
    "Rabanne who was the costume designer for \"Barbarella\"",
    "Designer Rabanne",
    "Fashion designer Rabanne",
    "Flamenco guitarist ___ de Lucia",
    "Spanish guitarist ___ De Lucia"
  ],
  "cmix": ["Early 10th-century year"],
  "sabu": [
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" boy",
    "Star of India, once",
    "Actor awarded a Distinguished Flying Cross in W.W. II",
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" actor",
    "One-named film star of the 1930s-'60s",
    "\"Elephant Boy\" boy",
    "\"Jungle Book\" star, 1942",
    "\"The Thief of Bagdad\" actor, 1940",
    "Indian-born actor in \"A Tiger Walks,\" 1964",
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" star",
    "\"The Thief of Bagdad\" star, 1940",
    "Elephant Boy of 30's film",
    "Elephant Boy of 30's film",
    "Indian boy of film",
    "Elephant boy of film",
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" boy",
    "\"Elephant Boy\" star, 1937"
  ],
  "apie": [
    "Cap-___ (from head to toe)",
    "\"... baked in ___\"",
    "\"... baked in ___\"",
    "\"... blackbirds baked in ___\"",
    "Tom T. Hall's \"Mama Bake ___\"",
    "\"... blackbirds baked in ___\"",
    "Cap-___ (from head to foot)",
    "\"... baked in ___\"",
    "Cap-___",
    "\"...baked in ___\"",
    "\"...baked in ___\"",
    "\"...baked in ___\"",
    "\"...blackbirds, baked in ___\"",
    "\"...baked in ___\"",
    "\"...baked in ___\"",
    "Cap-___ (from head to toe)",
    "\"...blackbirds baked in___\"",
    "\"...baked in___\"",
    "\"...baked in ___\"",
    "\"Baked in ___\" (nursery rhyme phrase)"
  ],
  "ezek": [
    "O.T. book before Daniel",
    "O.T. book before Daniel",
    "O.T. book",
    "Book before Daniel: Abbr.",
    "Book before Daniel: Abbr.",
    "Dan. preceder",
    "Book before Daniel: Abbr.",
    "O.T. book",
    "O.T. book",
    "Book before Daniel: Abbr.",
    "O.T. book before Daniel",
    "Book before Daniel: Abbr."
  ],
  "liao": [
    "Chinese dynasty of 1,000 years ago",
    "Chinese dynasty of a thousand years ago",
    "10th- to 12th-century Chinese dynasty",
    "Chinese dynasty of 1,000 years ago",
    "Chinese dynasty a thousand years ago",
    "Ancient dynasty of northern China",
    "10th-12th century dynasty",
    "Chinese dynasty up to A.D. 1125",
    "Manchurian river",
    "Dynasty after the T'ang"
  ],
  "onno": [
    "Anne Sexton's palindrome-inspired poem \"Rats Live ___ Evil Star\"",
    "___ occasion (never)",
    "___ account (never)",
    "___ account (never)",
    "___ account (never)",
    "___ account",
    "___ account (never)",
    "___ account (absolutely not)"
  ],
  "hugs": [
    "Clasps",
    "Holds close",
    "Part of xoxox",
    "Holds close",
    "Love signs",
    "Partner of kisses",
    "Squeezes"
  ],
  "anit": [
    "Pick ___ (fault-find)",
    "Pick ___ (quibble)",
    "Have ___ to pick",
    "Pick ___ (pettifog)",
    "Have ___ to pick",
    "Have ___ to pick"
  ],
  "sohn": ["Male offspring, in Munich", "Vater's boy"],
  "oyer": [
    "___ and terminer (criminal court)",
    "___ and terminer (high criminal court)",
    "Legal hearing",
    "Court hearing",
    "___ and terminer",
    "Court hearing",
    "Legal hearing",
    "Open hearing, in law",
    "___ and terminer",
    "Legal hearing",
    "Open court hearing",
    "Court hearing",
    "Legal hearing",
    "Legal hearing",
    "Terminer's partner",
    "Certain court hearing",
    "___ and terminer (criminal court)",
    "Open court hearing",
    "Hearing in open court",
    "___ and terminer (criminal court)",
    "Court hearing",
    "___ and terminer"
  ],
  "usoc": [
    "Natl. sports org.",
    "Grp. with national antidoping rules",
    "Org. that oversees American athletes",
    "Org. with a SportsMan of the Year award",
    "Natl. athletics supporter",
    "Summer Games org.",
    "Summer Games org.",
    "Quadrennial games org."
  ],
  "oort": [
    "___ cloud (source of comets)",
    "___ cloud (source of comets)",
    "Kind of cloud",
    "Astronomy's ___ cloud",
    "___ cloud (solar system outlier)",
    "___ cloud (region of comets far beyond Pluto)",
    "Astronomy's ___ cloud",
    "___ cloud (astronomical sighting)"
  ],
  "cavs": [
    "Cleveland team, informally",
    "Quicken Loans Arena cagers",
    "Cleveland b-ball team",
    "Cleveland cagers, briefly",
    "Cleveland cagers, briefly",
    "Cleveland cagers",
    "Gund Arena hoopsters",
    "Cleveland team, for short"
  ],
  "ivor": [
    "___ Novello Award (songwriter's honor)",
    "Composer Novello",
    "Songwriter Novello",
    "Composer Novello",
    "\"Severn Meadows\" poet Gurney",
    "English war poet Gurney",
    "\"The Dancing Years\" composer Novello",
    "Actor/songwriter Novello",
    "Actor/composer Novello",
    "English poet/composer Gurney",
    "Songwriter Novello",
    "Songwriter Novello",
    "Actor and songwriter Novello",
    "Actor/composer Novello",
    "Actor Novello",
    "___ Novello Award for songwriters",
    "Actor Novello",
    "\"War's Embers\" poet Gurney",
    "\"Gosford Park\" character ___ Novello",
    "Songwriter Novello",
    "Actor Novello",
    "Actor Novello",
    "Songwriter Novello",
    "Actor-songwriter Novello",
    "Actor Novello",
    "Novello of old films",
    "Actor-songsmith Novello"
  ],
  "tets": [
    "Hanoi holidays",
    "Hanoi celebrations",
    "Asian holidays",
    "Asian holidays",
    "Hanoi holidays",
    "Holidays in Hanoi",
    "Asian holidays",
    "Hanoi holidays",
    "Eastern holidays",
    "Vietnamese festivals",
    "Asian celebrations",
    "Holidays overseas",
    "Asian holidays",
    "Hanoi holidays"
  ],
  "iere": [
    "French suffix that's an anagram of 4-Down",
    "French suffix with jardin",
    "French noun suffix",
    "French suffix",
    "\"Able was ___ ...\"",
    "Suffix with jardin",
    "\"Able was ___ ?à\"",
    "French noun suffix",
    "\"Able was ___...\"",
    "\"Able was ___...\"",
    "French suffix",
    "Fashion suffix"
  ],
  "atoe": [
    "Dip ___ in",
    "Vol. 1 of a four-volume encyclopedia, maybe",
    "Dip ___ in",
    "Stick ___ in the water",
    "Stub ___",
    "Stub ___",
    "Stick ___ in the water",
    "Stub ___"
  ],
  "blau": [
    "Color of der Ozean",
    "Danube's color, to a Berliner",
    "Blue, in Bonn",
    "Blue in Berlin"
  ],
  "derr": [
    "Charlie Chan's creator Earl ___ Biggers",
    "Detective writer Earl ___ Biggers",
    "Charlie Chan's creator Earl ___ Biggers",
    "Charlie Chan creator Earl ___ Biggers",
    "Writer Earl ___ Biggers",
    "Author Earl ___ Biggers"
  ],
  "derm": [
    "Skin: Suffix",
    "Skin: Suffix",
    "Skin: Suffix",
    "Covering: Suffix",
    "Skin: Suffix",
    "Suffix for 41-Down"
  ],
  "itin": [
    "\"Get ___ writing\"",
    "See 69-Down",
    "Mail or phone follower",
    "Phone ___",
    "\"Put ___ writing!\"",
    "Rub ___",
    "\"Put ___ writing!\"",
    "\"Put ___ writing!\"",
    "\"Put ___ writing\"",
    "\"Get ___ writing\"",
    "\"Put ___ writing!\"",
    "Find ___ one's heart",
    "Get ___ writing",
    "Pack ___ (quit)",
    "Pack ___",
    "Have ___ for",
    "Pack ___",
    "Rub ___",
    "Put ___ writing",
    "\"Get ___ writing!\"",
    "Take ___ stride",
    "\"Put ___ writing\"",
    "Have ___ for",
    "Rake ___ (get rich)",
    "Pack ___",
    "\"Put ___ writing\"",
    "Have ___ for",
    "Pack ___ (give up)",
    "\"Don't rub ___!\"",
    "Pack ___"
  ],
  "mmli": [
    "The year 2051",
    "Mid 21st-century date",
    "Year in the middle of this century"
  ],
  "hexa": [
    "Tetra- plus two",
    "Tri and tri again?",
    "Prefix related to benzene's shape",
    "Dodeca- halved",
    "Six: Prefix",
    "Decimal starter",
    "One meter start?",
    "Six: Prefix",
    "Dodeca- halved",
    "Six: Prefix",
    "Prefix with -pod",
    "One more than penta-",
    "Prefix with meter",
    "Six: Prefix",
    "Prefix with meter",
    "Prefix with -hedron",
    "Prefix with syllabic",
    "Numerical prefix",
    "Dodeca- halved",
    "Prefix with -gon",
    "Prefix with -gon",
    "Penta- plus one",
    "Prefix with -gram",
    "Six: Prefix",
    "Prefix re honeycombs"
  ],
  "nico": [
    "One-named singer born Christa Paffgen",
    "One-named singer who was a muse for Andy Warhol",
    "One-named singer/actress associated with Warhol",
    "One-named singer for the Velvet Underground",
    "One-named singer with the 1960s Velvet Underground",
    "Unicorn in a 1998 movie",
    "One-named singer for the 1960s Velvet Underground"
  ],
  "yago": [
    "Sant' Gria brand",
    "___ Sant'Gria (wine choice)",
    "___ Sant'Gria (wine)"
  ],
  "yest": [
    "Not long ago at all: Abbr.",
    "The very recent past: Abbr.",
    "Tues. vis-a-vis Wed.",
    "24 hrs. ago",
    "Very recently: Abbr.",
    "24 hrs. ago",
    "Tomorrow's opposite: Abbr.",
    "Recently: Abbr.",
    "Tomorrow's opposite: Abbr.",
    "Recently: Abbr."
  ],
  "abas": [
    "\"___ le roi!\" (French Revolution cry)",
    "Overseas rebellion cry",
    "Sheiks' garments",
    "\"___ le roi!\"",
    "\"Down with ...!\": Fr.",
    "\"Down with,\" at the Bastille",
    "Tunic toppers",
    "French mutineer's phrase",
    "Start of a Parisian protest",
    "Arab garments",
    "Cry of French 40-Across",
    "\"Down with!,\" in Dijon",
    "\"___ le roi!\"",
    "\"___ le roi!\" (Bastille cry)",
    "French protest phrase",
    "Down with: Fr.",
    "\"___ le roi!\" (1792 cry)"
  ],
  "owar": [
    "Man ___",
    "\"Hug ___\" (Shel Silverstein poem)",
    "Man ___ (racehorse)",
    "Man ___ (A.P.'s Horse of the Century)",
    "Man ___",
    "Man ___",
    "Man ___",
    "Man ___",
    "Man chaser?",
    "Man follower",
    "Man-___",
    "Man ___",
    "Man-___",
    "Man ___ (famous race horse)",
    "Horse racing's Man ___"
  ],
  "orbe": [
    "\"Kill ___ killed\"",
    "\"Eat ___ eaten\"",
    "\"... there ___ square\"",
    "\"Awake, arise ___ forever fallen!\": Milton",
    "Kill ___ killed",
    "Kill ___ killed",
    "\"Kill ___ killed\"",
    "Kill ___ killed (law of the jungle)"
  ],
  "ecus": [
    "Coins with fleurs-de-lis",
    "Old French coins",
    "Coins that disappeared during the French Revolution",
    "Currency dropped during the French Revolution",
    "Bygone French coins",
    "Old five-franc pieces",
    "Old French coins",
    "Coins displaying fleurs-de-lis",
    "Common Market money",
    "French coins of yore",
    "Continental money",
    "Common Market money",
    "Old French coins",
    "Old French coins",
    "Shields for men-at-arms",
    "Old French coins",
    "Old French coins",
    "Currency for 35-Down"
  ],
  "jugs": [
    "Moonshine holders",
    "See 103-Down",
    "Rural musical instruments",
    "Liquor containers",
    "Acoustic instruments",
    "Moonshine containers"
  ],
  "evry": [
    "\"Climb ___ Mountain\"",
    "\"Lift ___ Voice and Sing\" (old hymn)",
    "\"Climb ___ Mountain\"",
    "\"Climb ___ Mountain\"",
    "\"Climb ___ Mountain\""
  ],
  "lema": [
    "Golf's Champagne Tony",
    "Champagne Tony of golf",
    "Champagne Tony of golf",
    "Champagne Tony of golf",
    "Champagne Tony of golf"
  ],
  "eliz": [
    "Part of QE2: Abbr.",
    "Monarch crowned in 1558: Abbr.",
    "1558-1603 monarch: Abbr.",
    "Queen of Eng.",
    "Her Majesty: Abbr.",
    "The Queen: Abbr."
  ],
  "buen": ["Good, in Guatemala"],
  "howi": [
    "\"That's just ___ roll\"",
    "\"That's just ___ feel\"",
    "\"Oh, ___ Love Jesus\" (hymn)"
  ],
  "nyes": [
    "Oakland's Bill ___ Climate Lab",
    "\"Bill ___ History of the United States\" (1894 humor book)",
    "Bill ___ Climate Lab (Oakland science exhibit)",
    "Actress Carrie and others",
    "\"Bill ___ History of the United States\"",
    "Longtime North Dakota senator Gerald and others",
    "TV's Science Guy and others",
    "Louis and Carrie",
    "Funny Louis and others",
    "TV's Science Guy and others",
    "\"Bill ___ History of the United States\" (1894 humor book)",
    "Comic Bill et al.",
    "Louis, Carrie and Bill",
    "Humorist Bill and others",
    "Bill and Carrie",
    "Humorist Bill et al.",
    "Actress Carrie et al.",
    "Historian Russell and others"
  ],
  "edtv": [
    "1999 Ron Howard satire",
    "1999 Ron Howard film",
    "1999 Ron Howard bomb",
    "1999 Ron Howard satire",
    "1999 film satirizing media ruthlessness",
    "1999 film with the tagline \"Fame. Be careful. It's out there\"",
    "Ron Howard media satire",
    "1999 Ron Howard comedy",
    "Ron Howard flick of 1999",
    "1999 satire of reality shows",
    "1999 Ron Howard comedy",
    "1999 Ron Howard film",
    "1999 Ron Howard film",
    "1999 Matthew McConaughey movie",
    "1999 Ron Howard film"
  ],
  "apat": [
    "Give ___ on the back",
    "Pit-___",
    "Pit-___",
    "Pit-___",
    "Give ___ on the back",
    "Give ___ on the back",
    "Give ___ on the back",
    "Pit-___",
    "Give ___ on the 7-Down",
    "Pit-___ (heart sound)",
    "Get ___ on the back",
    "Give ___ on the back",
    "Pit-___ (heartbeat sound)",
    "Give ___ on the back",
    "Give ___ on the back"
  ],
  "aswe": [
    "___ speak",
    "Life ___ know it",
    "___ speak",
    "\"Even ___ speak ...\"",
    "\"Even ___ speak ...\"",
    "\"For ___ all know ...\"",
    "R.E.M.'s \"It's the End of the World ___ Know It\"",
    "Life ___ know it",
    "\"... mercy on such ___\": Kipling",
    "\"... mercy on such ___\": Kipling",
    "\"___ forgive those...\"",
    "\"...civilization ___ know it\"",
    "\"___ forgive our debtors\"",
    "\"___ speak\"",
    "\"...___ forgive our debtors\"",
    "\"___ forgive those who trespass...\"",
    "\"Even ___ speak...\"",
    "\"___ forgive our debtors\"",
    "\"God ha' mercy on such ___\"",
    "\"Even___speak...\"",
    "\"...___ forgive our debtors\"",
    "\"...___ forgive our debtors\"",
    "\"... ___forgive our debtors\""
  ],
  "agun": [
    "\"Janie's Got ___\" (1989 Aerosmith hit)",
    "\"Son of ___!\"",
    "Have ___ to one's head",
    "\"Son of ___!\"",
    "\"Son of ___!\"",
    "Pull ___ on",
    "Have ___ to one's head",
    "Son of ___",
    "Son of ___"
  ],
  "vaya": [
    "\"___ con Dios\" (Spanish farewell)",
    "\"___ con Dios\"",
    "___ con Dios (Spanish farewell)"
  ],
  "zool": ["Veterinarian's branch of sci.", "Branch of sci."],
  "saxe": [
    "___-Altenburg (old German duchy)",
    "Old German duchy name",
    "___-Coburg (former German duchy)",
    "___-Coburg and Gotha (former British ruling family)",
    "Old German duchy name",
    "Old German duchy name",
    "___-Altenburg (former German duchy)",
    "Name in old German duchies",
    "___-Coburg- Gotha, former British royal house",
    "___-Coburg-Gotha (old British royal house)",
    "___-Weimar-Eisenach (duchy until W.W. I)",
    "Old German duchy name",
    "Partner of Coburg, historically",
    "___-Coburg (part of historic Germany)",
    "18th-century French marshal",
    "Old German duchy name",
    "___-Altenburg (old German duchy)",
    "Old German duchy name",
    "Old German duchy",
    "___-Coburg, old German duchy",
    "___-Coburg-Gotha",
    "Old duchy in royal family names",
    "Old German duchy name",
    "___-Coburg-Gotha (British royal house)",
    "House of ___-Coburg-Gotha",
    "___-Coburg (former duchy)"
  ],
  "isan": [
    "\"This ___ outrage!\"",
    "A3",
    "LeAnn Rimes's \"Love ___ Army\"",
    "\"This ___ outrage!\"",
    "\"This ___ outrage!\"",
    "\"This ___ outrage!\"",
    "\"My life ___ open book\"",
    "\"No man ___ island ?à\"",
    "\"My life ___ open book\"",
    "\"There ___ old saying ...\"",
    "\"No man ___ island\"",
    "\"Sword ___ oath, and oaths must have their course\": Shak.",
    "\"No man ___ island ...\"",
    "\"This ___ outrage!\"",
    "\"No man ___ island...\"",
    "\"My life ___ open book\"",
    "\"This ___ outrage!\"",
    "\"No man ___...\"",
    "\"This___outrage!\"",
    "\"No man ___ island\"",
    "\"This ___ outrage!\"",
    "\"This ___ outrage!\""
  ],
  "pcps": [
    "H.M.O. doctor designations",
    "Psychedelic drugs, for short",
    "Some hallucinogens, for short",
    "Some hallucinogens, for short",
    "Illicit drugs, briefly"
  ],
  "aska": [
    "\"___ silly question ...\"",
    "\"___ stupid question ...\"",
    "\"___ stupid question ...\"",
    "\"May I ___ question?\"",
    "___ question (inquire)",
    "\"May I ___ favor?\"",
    "\"___ silly ?à\"",
    "\"___ silly question ...\"",
    "\"___ silly question ...\"",
    "\"May I ___ silly question?\"",
    "\"___ silly question...\"",
    "\"___silly question...\"",
    "\"___ silly question...\"",
    "\"___ silly question ...\""
  ],
  "exod": [
    "Gen. follower",
    "Gen. follower",
    "Commemoration at a seder: Abbr.",
    "Gen. follower",
    "Whence the line \"Let my people go\": Abbr.",
    "Gen. follower",
    "Second book of Moses: Abbr.",
    "Book after Gen."
  ],
  "hods": [
    "Brick carriers",
    "Coal carriers",
    "Masonry containers",
    "Mortar troughs",
    "Brick carriers",
    "Burdens on some shoulders",
    "Brick holders",
    "Bricklayers' equipment",
    "Bricklayers carry them",
    "Masons' burdens",
    "Bricklayers' equipment"
  ],
  "huff": [
    "Fit of pique",
    "Angry, resentful state",
    "In a ___ (indignant)",
    "Peeved mood",
    "Piqued state"
  ],
  "mdxc": ["Year that Spenser's \"The Faerie Queene\" was published"],
  "atic": [
    "Ending with idiom or axiom",
    "Suffix with symptom",
    "Suffix with symptom",
    "Suffix with problem",
    "Suffix with problem",
    "Axiom ender",
    "Problem ending",
    "Suffix with axiom",
    "Suffix with symptom",
    "Suffix with problem",
    "Problem ending?",
    "Suffix with symptom",
    "Suffix with idiom",
    "Suffix with idiom"
  ],
  "viii": [
    "Number on a grandfather clock",
    "Henry ___",
    "Britain's last King Henry",
    "The last King Edward of England",
    "IV + IV",
    "The last Pope Urban",
    "Henry ___",
    "Henry ___",
    "Hour on a grandfather clock",
    "Eight on a sundial",
    "Clock hour",
    "Two before X"
  ],
  "nebo": [
    "Mount near the Dead Sea",
    "Mount of Moses",
    "Jordan's Mount ___, from which Jericho can be seen",
    "Mountain where Moses died, in the Bible",
    "Biblical peak",
    "Mount of Moses",
    "Mount from which Moses saw Canaan",
    "Peak in ancient Palestine",
    "Mount from which Moses viewed the Promised Land",
    "Biblical summit Mt. ___",
    "Mount of Moses"
  ],
  "oban": [
    "Scottish seaport known for its single-malt Scotch",
    "Port in Scott's \"Lord of the Isles\""
  ],
  "bsed": ["Deg. for a teacher-to-be", "Teacher's deg.", "Teacher's deg."],
  "diag": [
    "Screen meas.",
    "TV meas.",
    "TV screen meas.",
    "TV screen meas.",
    "Neither hor. nor vert.",
    "Rect. divider",
    "Corner-to-corner: Abbr.",
    "On a bias: Abbr.",
    "Certain measurement: Abbr."
  ],
  "stol": [
    "Many a bush plane, in brief",
    "Small-runway craft, in brief",
    "Adaptable aircraft, for short",
    "Small-runway aircraft, briefly",
    "Adaptable aircraft",
    "Acronym for a small-runway aircraft",
    "Small-runway aircraft, briefly",
    "Adaptable aircraft",
    "Small runway craft, for short",
    "Adaptable aircraft, for short",
    "Adaptable aircraft",
    "Mil. plane for quick takeoffs",
    "Convertiplane, e.g.",
    "Certain commuter plane, for short",
    "Small airport craft, for short",
    "Convertiplane",
    "Plane on an aircraft carrier"
  ],
  "kcal": [
    "Heat meas. that also names a major L.A. TV station",
    "Heat meas.",
    "Food energy unit: Abbr."
  ],
  "rado": [
    "High-end Swiss watch",
    "Pricey Swiss watch brand",
    "\"Hair\" co-writer James",
    "\"Hair\" co-writer James",
    "\"Hair\" co-writer",
    "\"Hair\" co-lyricist"
  ],
  "imat": [
    "\"___ my wit's end!\"",
    "\"___ my wit's end\"",
    "\"___ the end of my rope!\"",
    "\"This is where ___\"",
    "\"While ___ it ...\"",
    "\"While ___ it ?à\"",
    "\"While ___ it ...\"",
    "\"___ my wit's end!\"",
    "\"___ your disposal\"",
    "\"___ my wit's end\"",
    "\"___ the end of my rope!\""
  ],
  "gama": [
    "Vasco da ___",
    "Vasco da ___",
    "Explorer Vasco da ___",
    "Explorer Vasco da ___",
    "Vasco da ___",
    "Navigator Vasco da ___",
    "Vasco da ___"
  ],
  "cups": [
    "Some trophies",
    "Recipe amounts",
    "Parts of a tea set",
    "Tarot suit",
    "Silver prizes",
    "Tarot suit",
    "Recipe measures",
    "Coffeehouse orders",
    "Putting targets",
    "Quart quartet",
    "Golf targets",
    "Silver prizes",
    "Golf targets",
    "Quart quartet",
    "Some trophies",
    "Stanley and Davis, e.g.",
    "Tarot suit"
  ],
  "aryl": ["Benzene derivative, for one", "Of a certain hydrocarbon group"],
  "vers": [
    "___ libre (poetry form)",
    "___ libre (poetry style)",
    "___ libre (poetry style)",
    "___libre"
  ],
  "davi": ["Actor Robert of \"Licence to Kill\" and \"The Goonies\""],
  "deys": [
    "Bygone Ottoman rulers",
    "Bygone Tunisian V.I.P.'s",
    "Old African rulers",
    "Bygone Tunisian V.I.P.'s",
    "Old Tunisian rulers",
    "Old Algerian titles",
    "African rulers",
    "Tunisian rulers, once"
  ],
  "aban": ["Impose ___ on", "Impose ___ on (outlaw)"],
  "rnas": [
    "Coding molecules",
    "They're stranded, briefly",
    "Genetic materials",
    "Genetic molecules",
    "They're stranded in the body",
    "Nucleotide chains",
    "Genetic strands",
    "Genetic molecules",
    "Cytoplasmic molecules",
    "Transfer and messenger materials",
    "Protein-synthesis strands"
  ],
  "ctrs": [
    "Some med. facilities",
    "Civic bldgs.",
    "Some basketball players: Abbr.",
    "N.F.L. snappers: Abbr.",
    "Midpoints: Abbr.",
    "They play in front of QBs",
    "Hubs: Abbr.",
    "Middles: Abbr.",
    "Some basketball players: Abbr.",
    "Shopping sites: Abbr.",
    "Major civic bldgs.",
    "Municipal sites: Abbr.",
    "Middles: Abbr."
  ],
  "sorb": [
    "Take up and hold, chemically",
    "Collect on the surface, in chemistry",
    "Gather on the surface, chemically",
    "European fruit tree",
    "Gather on a surface, chemically",
    "Soak up",
    "Take up like a sponge"
  ],
  "ieri": [
    "Yesterday, in Italy",
    "Yesterday: It.",
    "Yesterday, in Italy",
    "Yesterday, in Italy",
    "Opposite of domani",
    "\"___ son salita\" (Puccini aria)"
  ],
  "stps": ["Line of additives", "Motor oil additives"],
  "dreg": [
    "Bit at the bottom",
    "Leftover bit",
    "Last little bit",
    "Bit of residue",
    "Small remnant",
    "Remnant",
    "Vestige"
  ],
  "teac": ["Japanese audio equipment maker", "Noted 26-Across brand"],
  "choc": [
    "One of the three flavors of Neapolitan ice cream, for short",
    "Rocky road ingredient, for short",
    "One-third of Neapolitan ice cream: Abbr.",
    "One-third of Neapolitan, for short",
    "Ice cream flavor, briefly",
    "22-Across flavor, for short",
    "Ice cream flavor, briefly",
    "Fudge ingredient: Abbr.",
    "Kiss flavor: Abbr.",
    "Ice-cream flav."
  ],
  "strs": [
    "Orch. section",
    "Violins and violas: Abbr.",
    "Cellos, violas, etc.: Abbr.",
    "Navigation rtes.",
    "Orch. section",
    "Orch. section",
    "Orch. section",
    "Violins and violas: Abbr."
  ],
  "ekgs": [
    "Hosp. readouts",
    "Heart tests, for short",
    "Ticker tests, for short",
    "Beat reporters?: Abbr.",
    "Heart lines: Abbr.",
    "Ticker tapes?",
    "Ticker tapes?: Abbr.",
    "E.R. helps",
    "Ticker tapes?"
  ],
  "apox": ["\"___ on both your houses!\"", "\"___ on both your houses!\""],
  "arum": [
    "Calla lily family",
    "Jack-in-the-pulpit, e.g.",
    "___ lily",
    "Poisonous lily",
    "Jack-in-the-pulpit, e.g.",
    "Calla lily, e.g.",
    "Jack-in-the-pulpit, e.g.",
    "Kind of lily",
    "Kind of lily",
    "Jack-in-the-pulpit, e.g.",
    "Calla lily family",
    "Kind of lily"
  ],
  "ughs": [
    "Not music to a chef's ears",
    "Lousy \"reviews\"",
    "Chefs hate hearing them",
    "\"That tastes awful!\" comments",
    "Critical comments",
    "Terse critiques",
    "Words from Charlie Brown",
    "Oral grimaces",
    "Short pans",
    "\"Fear Factor\" comments",
    "Short pans",
    "Words with grimaces",
    "Terse critiques",
    "Short pans",
    "Taste-testers' turndowns",
    "Cries of aversion",
    "Words said while holding the nose",
    "Terse pans",
    "Exclamations of disgust",
    "Bad reviews",
    "Cries of aversion",
    "Verbal pans",
    "Uncomplimentary comments",
    "Grunts of distaste"
  ],
  "roms": [
    "CD-___ (storage objects)",
    "DVD-___",
    "CD-___",
    "CD-___",
    "CD-___",
    "Gypsies"
  ],
  "popi": ["1969 Alan Arkin comedy/drama"],
  "foss": [
    "Conductor Lukas",
    "Conductor Lukas ___",
    "\"Time Cycle\" composer Lukas ___"
  ],
  "heil": [
    "Greeting with a salute",
    "Greeting with a salute",
    "Greeting with a salute",
    "Greeting to Hitler",
    "Word to Hitler"
  ],
  "doya": ["1977 hit by 55-Down"],
  "yuck": ["\"Eww, gross!\"", "\"Gross!\"", "\"Do not like\"", "\"Ewww!\""],
  "rafe": [
    "Actor Spall of \"Life of Pi\"",
    "Rafael, to friends",
    "___ McCawley, Ben Affleck's role in \"Pearl Harbor\"",
    "Male protagonist in William Inge's \"Good Luck, Miss Wyckoff\"",
    "Olympic track great Johnson, familiarly",
    "Ben Affleck, in \"Pearl Harbor\""
  ],
  "apis": [
    "Egyptian bull god",
    "Honeybee genus",
    "Honeybee genus",
    "Idol in Memphis",
    "Sacred bull of Egyptian myth",
    "Sacred bull of Egypt",
    "Sacred bull"
  ],
  "nark": [
    "Informer, informally",
    "D.E.A. agent: Var.",
    "Stoolie",
    "British informer",
    "British stoolie",
    "London stoolie"
  ],
  "dohs": [
    "Springfield exclamations",
    "Exclamations often made with head-slaps",
    "Homer Simpson outbursts",
    "Head slappers' cries",
    "Cries from Homer Simpson",
    "Sounds at the Simpsons'",
    "Cries from Homer Simpson"
  ],
  "atap": ["Give ___ on the shoulder", "\"___ at the pane\": Robert Browning"],
  "kirk": [
    "Enterprise captain",
    "Captain of TV and film",
    "\"Star Trek\" captain",
    "Sulu's superior",
    "2009 sci-fi role for Chris Pine",
    "Captain for Spock and McCoy",
    "Captain of 1960's TV",
    "TV captain",
    "Enterprise log signature",
    "Picard predecessor"
  ],
  "agal": [
    "\"___ in Calico\" (jazz classic)",
    "\"... ___ in Kalamazoo\"",
    "\"I got ___ in Kalamazoo\"",
    "\"I got ___ ...\"",
    "\"___ in Calico\" (jazz standard)",
    "\"I've Got ___ in Kalamazoo\"",
    "\"I've Got ___ in Kalamazoo\"",
    "1946 hit \"___ in Calico\"",
    "\"I've Got ___ in Kalamazoo\"",
    "\"... ___ in Kalamazoo\"",
    "\"___ in Calico\" (hit of the 40's)",
    "\"___ in Calico\" (1946 song)",
    "\"___ in Calico\" (1946 song)",
    "\"I've Got___...\"",
    "\"___ in Calico\" (1947 song)",
    "\"I've Got ___ in Kalamazoo\"",
    "1946 song ?ô___ in Calico?ö"
  ],
  "esne": [
    "A slave to crosswords?",
    "Feudal laborer",
    "Old laborer",
    "Bygone laborer",
    "Feudal laborer",
    "Feudal serf",
    "Feudal laborer",
    "Bygone laborer",
    "Anglo-Saxon laborer",
    "Feudal serf",
    "Feudal worker",
    "Old laborer",
    "Crossword worker?",
    "Hireling, in history",
    "Crossword worker?",
    "Laborer of old",
    "Crossword worker?",
    "Laborer",
    "Feudal worker",
    "Lord's worker",
    "Anglo-Saxon slave",
    "Old slave",
    "Olden slave",
    "Peon of yore",
    "Anglo-Saxon toiler",
    "Domestic slave",
    "Anglo-Saxon worker",
    "Crossword worker?",
    "Thrall of yore"
  ],
  "kenl": [
    "___ Ration (old dog food brand)",
    "___ Ration (dog food brand)",
    "___ Ration (Alpo alternative)"
  ],
  "ocat": [
    "One-___ (old ball game)",
    "One-___ (old ball game)",
    "One-___ (old ball game)",
    "One ___ (baseball variant)",
    "One ___ (ball game)",
    "One ___ (kid's game)",
    "One ___ (kid's game)",
    "One ___ (baseball variant)",
    "See 65-Down",
    "One ___ (kids' game)",
    "One-___ (old ball game)",
    "One ___ (kids' game)",
    "One ___ (kids' game)",
    "One ___ (ball game)",
    "One-___ (kids' game)",
    "One ___ (ball game)",
    "One ___ (ball game)",
    "One ___ (form of baseball)",
    "One ___ (ball game)"
  ],
  "wnet": [
    "PBS station in the Big Apple",
    "PBS station behind \"Live From Lincoln Center\"",
    "N.Y.C.'s PBS station",
    "PBS flagship station",
    "N.Y.C.'s PBS station",
    "PBS flagship station",
    "PBS station with a transmitter on the Empire State Building",
    "PBS station behind Charlie Rose"
  ],
  "sard": [
    "Variety of chalcedony",
    "Reddish-brown quartz",
    "Orangish gem",
    "Reddish-brown gem",
    "Reddish-brown gem",
    "Variety of chalcedony",
    "Reddish-brown gem",
    "Reddish-brown gem",
    "Reddish-brown gem",
    "Quartz variety"
  ],
  "arma": [
    "\"___ virumque cano\" (\"Aeneid\" opening)",
    "\"___ virumque cano\" (first words of the \"Aeneid\")",
    "First word of the \"Aeneid\"",
    "First word of the \"Aeneid\"",
    "First word of Virgil's \"Aeneid\"",
    "First word of Virgil's \"Aeneid\"",
    "First word of the \"Aeneid\"",
    "First word of the ?ôAeneid?ö"
  ],
  "grrs": [
    "Canine warnings",
    "Toy warnings?",
    "Canine warnings",
    "Boxers' warnings"
  ],
  "chaw": [
    "Tobacco plug",
    "It's a mouthful",
    "Something placed in the mouth of a pitcher?",
    "Hillbilly's plug",
    "It's a mouthful",
    "Tobacco mouthful",
    "Tobacco plug",
    "Mouthful of tobacco",
    "It's a mouthful",
    "Plug in the sticks",
    "Plug of tobacco",
    "Tobacco mouthful",
    "Tobacco wad",
    "Plug of tobacco",
    "Wad",
    "Quid",
    "Plug, of a sort",
    "Mouthful",
    "Tobacco mouthful",
    "Plug, of a sort",
    "Plug",
    "Plug in the mouth",
    "Tobacco mouthful",
    "Tobacco wad",
    "Plug of tobacco",
    "Tobacco wad"
  ],
  "bois": [
    "___ de Boulogne (Parisian park)",
    "___ de Boulogne (Paris park)",
    "___ de Boulogne",
    "___ de Bologne (park west of Paris)",
    "___ de Boulogne (Paris park)",
    "Forest: Fr.",
    "___ de Boulogne (Parisian park)"
  ],
  "knar": [
    "Knot on a tree",
    "Bump on a log",
    "Wood knot",
    "Lumber feature",
    "Wood blemish",
    "Trunk protuberance",
    "Bump on a log",
    "Wood blemish",
    "Wood protuberance",
    "Tree trunk bulge",
    "Wood knot",
    "Trunk bulge"
  ],
  "auth": [
    "Card catalog abbr.",
    "Wolfe or Woolf, e.g.: Abbr.",
    "Title accompanier: Abbr.",
    "Bibliography abbr.",
    "Bibliographical info: Abbr.",
    "Bibliog. info",
    "Datum in a lib. search",
    "Short story-writer"
  ],
  "tway": [
    "Golfer Bob or his golfing son Kevin",
    "Bob ___, 1986 P.G.A. Player of the Year",
    "Bob of the P.G.A.",
    "1986 P.G.A. Championship winner Bob"
  ],
  "moen": [
    "Big name in faucets",
    "Kitchen brand",
    "Big name in faucets",
    "Faucet brand",
    "Big name in faucets",
    "Big name in faucetry",
    "Plumbing fixture manufacturer",
    "Big name in faucets",
    "Big name in faucets",
    "Big name in faucets",
    "Big name in faucets",
    "Big name in faucets",
    "Faucet brand",
    "Faucet brand",
    "Big name in water faucets",
    "Big name in plumbing products",
    "Big name in faucets",
    "Popular brand of faucet"
  ],
  "doce": ["88-Across + cuatro", "Number of signos del zodiaco"],
  "nobs": [
    "Cribbage one-pointers",
    "Wealthy Brits",
    "Heads, slangily",
    "Loaded Londoners",
    "Wealthy Londoners",
    "Heads, in slang"
  ],
  "guck": ["Slimy stuff", "Crud"],
  "alaw": [
    "___ unto oneself",
    "\"There oughta be ___\"",
    "Is ___ unto oneself",
    "\"There oughta be ___!\"",
    "\"There oughta be ___\"",
    "\"There oughta be ___!\"",
    "___ unto itself",
    "\"There oughta be ___!\"",
    "What \"there oughta be\"",
    "___ unto himself",
    "___ unto himself",
    "\"There oughta be ___!\"",
    "___ unto itself",
    "\"There ought to be ___!\"",
    "\"There oughta be ___!\"",
    "What there oughta be",
    "\"There oughta be ___!\"",
    "\"There oughta be ___!\"",
    "\"There oughta be ___!\"",
    "\"There oughta be ___!\"",
    "\"There oughta be ___!\"",
    "\"There ought to be ___!\"",
    "There ought to be one"
  ],
  "fain": [
    "Willingly, once",
    "Gladly, old-style",
    "\"April Love\" composer Sammy",
    "Willingly, old-style",
    "Willing",
    "Gladly, old-style",
    "\"April Love\" composer Sammy",
    "Willingly",
    "Gladly",
    "Gladly",
    "Gladly, in olden times"
  ],
  "nipa": [
    "Long-leaved palm",
    "Basketry palm",
    "Palm for thatching",
    "Palm used for basketry",
    "Thatching palm"
  ],
  "apod": [
    "Animal without feet",
    "\"... peas in ___\"",
    "Like peas in ___",
    "Footless creature",
    "Like two peas in ___",
    "Like two peas in ___",
    "Like two peas in ___",
    "Snake or eel, e.g.",
    "Like peas in ___",
    "\"... peas in ___\"",
    "Footless animal",
    "Like two peas in ___",
    "Snake, for one",
    "Footless animal",
    "Snake, for one",
    "Footless critter",
    "\"...peas in ___\"",
    "Like peas in ___"
  ],
  "darc": [
    "Jeanne ___",
    "Ste. Jeanne ___",
    "Jeanne ___",
    "Ste. Jeanne ___",
    "Ste. Jeanne ___",
    "Ste. Jeanne ___",
    "Jeanne ___",
    "France's Jeanne ___",
    "Ste. Jeanne ___",
    "Jeanne ___",
    "Ste. Jeanne ___",
    "Jeanne ___",
    "Bois ___ (Osage orange)",
    "Jeanne ___ (French saint)",
    "Jeanne ___",
    "Jeanne ___"
  ],
  "pkwy": ["N.Y.C.'s F.D.R. Drive, e.g.", "Tree-lined road: Abbr."],
  "abms": [
    "Some military defenses, for short",
    "1972 treaty subjects, briefly",
    "Means of defense: Abbr.",
    "Some mil. defenses",
    "Contents of some silos: Abbr.",
    "Defense means, briefly",
    "Some military defenses: Abbr.",
    "Mil. defense systems",
    "Airborne defenses, for short"
  ],
  "pkgs": [
    "U.P.S. truck contents: Abbr.",
    "U.P.S. cargo: Abbr.",
    "Xmas armfuls: Abbr."
  ],
  "oyes": ["Cry for silence and attention", "Court cry", "Court cry"],
  "dese": [
    "Dis twice?",
    "\"___ Bones G'wine Rise Again\" (spiritual)",
    "Certain ones, in Brooklyn",
    "Not those, in Brooklyn",
    "Brooklynese pronoun",
    "Dis and dis",
    "Brooklynese pronoun",
    "Not those, in Brooklyn",
    "\"___ Bones Gwine ter Rise Again\" (old song)",
    "Not dose"
  ],
  "quod": [
    "Which, in Latin",
    "Q.E.D. part",
    "\"Eris ___ sum\" (\"You will be what I am\")",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of Q.E.D.",
    "Part of 25-Down"
  ],
  "zwei": [
    "Two, in German",
    "A quarter of acht",
    "Number between eins and drei",
    "Deux : France :: ___ : Germany",
    "What's needed for a Teutonic tango"
  ],
  "abar": [
    "\"A priest and a dog walk into ___ ...\"",
    "\"This guy walks into ___ ...\"",
    "\"This guy walks into ___ ...\"",
    "\"A rabbi, a priest and a duck walk into ___ ...\"",
    "\"This guy walks into ___ ?à\""
  ],
  "weve": [
    "Bob Seger's \"___ Got Tonite\"",
    "\"___ Only Just Begun\"",
    "\"___ Only Just Begun\"",
    "Bob Seger's \"___ Got Tonite\"",
    "\"___Only Just Begun\"",
    "\"___ Got Tonight\" (hit song)"
  ],
  "cerf": [
    "Vint ___, one of the so-called \"fathers of the Internet\"",
    "Humorist Bennett",
    "Bennett of \"What's My Line?\"",
    "Vint ___, Father of the Internet",
    "Vint ___, the Father of the Internet",
    "Punny Bennett",
    "Early \"What's My Line?\" panelist",
    "Old \"What's My Line?\" panelist",
    "Humorist Bennett who co-founded Random House",
    "Bennett of publishing",
    "Witty Bennett",
    "Old \"What's My Line\" panelist",
    "\"At Random\" autobiographer",
    "\"At Random\" autobiographer",
    "Publisher Bennett",
    "Old ?ôWhat?Æs My Line?ö panelist",
    "Writer who co-founded Random House"
  ],
  "alef": [
    "Hebrew letter: Var.",
    "Beth preceder",
    "Hebrew leader?",
    "First Hebrew letter: Var.",
    "Hebrew leader?",
    "Hebrew leader: Var.",
    "Hebrew leader",
    "Hebrew letter",
    "A as in Absalom",
    "Letter before beth",
    "Letter before beth"
  ],
  "unes": [
    "Quelques-___ (some: Fr.)",
    "Some: Fr.",
    "Quelques-___ (some: Fr.)",
    "Catalan article",
    "French singles",
    "Quelques-___ (some: Fr.)",
    "Quelques-___ (some: Fr.)",
    "French \"some,\" with \"les\"",
    "French individuals",
    "French singles",
    "French articles",
    "French articles",
    "Arles articles"
  ],
  "cmii": [
    "The year 902",
    "Early 10th-century year",
    "Year in the reign of Edward the Elder"
  ],
  "msgr": [
    "Roman Cath. title",
    "Roman Cath. title",
    "Cath. title",
    "Roman Cath. title",
    "Roman Cath. title",
    "Eccles. dignitary",
    "Relig. title",
    "Religious title: Abbr.",
    "Relig. title",
    "Religious title: Abbr.",
    "Church dignitary's title: Abbr.",
    "Prelate's honorific: Abbr.",
    "Prelate's title: Abbr."
  ],
  "arow": [
    "In ___ (lined up)",
    "Without interruption, after \"in\"",
    "\"Pretty Maids All in ___\"",
    "Sequentially, after \"in\"",
    "\"... ducks in ___\"",
    "Aligned, after \"in\"",
    "In ___ (lined up)",
    "Shoulder-to-shoulder",
    "In ranks",
    "Lined up, after \"in\"",
    "Ranked",
    "In ranks",
    "In ___ (lined up)",
    "Lined up",
    "In ranks",
    "Get one's ducks in ___",
    "In ranks",
    "Three-in-___",
    "Get one's ducks in ___",
    "In ___ (aligned)",
    "Get one's ducks in ___",
    "Lined up",
    "\"And pretty maids all in ___\"",
    "\"... maids all in ___\"",
    "Ranked",
    "Forming ranks",
    "In line",
    "Straight, after \"in\"",
    "Lined up",
    "Lined up",
    "Queued up",
    "\"Pretty Maids All in___\"",
    "Lined up",
    "Aligned, with \"in\"",
    "\"Pretty Maids All in ___\"",
    "In a line"
  ],
  "otts": [
    "23-Across and others",
    "Giant Mel and Pirate Ed",
    "Giant giant's family",
    "Cards once traded for Gehrigs, say",
    "Hockey's Steve and baseball's Mel",
    "Some valuable 1920s-'40s baseball cards",
    "Baseball's Ed and Mel",
    "Slugger Mel et al.",
    "Baseball's Ed and Mel",
    "Baseball's Mel and others",
    "Baseball's Ed and Mel",
    "Cards traded for Musials, maybe",
    "Legendary Giant's family",
    "Baseballers Ed and Mel",
    "Giant Mel and others",
    "Baseball's Mel and family",
    "Baseball's Mel and others",
    "Giant Mel and family",
    "Baseball's Mel and others",
    "Giant Mel et al.",
    "Giant Mel et al.",
    "Baseball's Mel and Ed",
    "Baseballers Ed and Mel",
    "Mel and family",
    "Mel and family"
  ],
  "rhum": [
    "Baba au ___",
    "Baba au ___",
    "Baba au ___",
    "Product of la Jamaique"
  ],
  "ahit": [
    "Score ___ (enjoy some success)",
    "Take ___ (lose one)",
    "Take ___ (suffer loss)",
    "\"We want ___!\" (baseball fans' cry)",
    "Make ___ with (please)",
    "\"It's___!\" (sportscaster's cry)",
    "\"We want ___!\" (stadium cheer)",
    "Take ___ on (inhale, as drugs)"
  ],
  "doux": [
    "Billet-___",
    "Very sweet, as Champagne",
    "Billet-___ (love letter)"
  ],
  "tuts": [
    "King ___ tomb",
    "Sounds of scolding",
    "Sounds of disapproval",
    "King ___ tomb",
    "Sounds of reproof",
    "Mild reproofs",
    "Expresses disdain, in a way",
    "Sounds of disapproval",
    "Sounds of reproof"
  ],
  "oste": [
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone head?",
    "Bone head?",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone: Prefix",
    "Bone, in combinations",
    "Bone: Prefix",
    "Bone: Prefix"
  ],
  "dado": [
    "Woodworker's groove",
    "Middle part of a pedestal",
    "Part of a pedestal between the base and the cornice",
    "Joint groove",
    "Carpenter's groove",
    "Woodworker's groove",
    "Pedestal part",
    "Carpenter's groove",
    "Woodworking channel",
    "Woodworker's groove",
    "Woodworking groove",
    "Pedestal part",
    "Pedestal part",
    "Part below a cornice",
    "Carpenter's groove",
    "Pedestal part",
    "Carpenter's groove",
    "Carpenter's groove",
    "Carpenter's groove",
    "Pedestal part",
    "Woodworking groove",
    "Carpenter's groove",
    "Carpenter's groove",
    "Pedestal part"
  ],
  "moll": [
    "Mobster's gal",
    "Mobster's gal",
    "Gang girl",
    "Gang woman",
    "Gang hanger-on",
    "Tough's partner",
    "Fictional Flanders",
    "Gun-toting gal",
    "Gangster's gal",
    "Tough companion?",
    "Gangster's gal",
    "Gangster's gal",
    "Gangster's girl",
    "\"___ Flanders\"",
    "Gangster's gal",
    "Gangster's gal",
    "Mobster's lady"
  ],
  "yawp": [
    "Complain loudly",
    "Complain",
    "Harsh cry",
    "Strident complaint",
    "Cry harshly",
    "Squawk",
    "Raucous speech"
  ],
  "wang": [
    "First computer company to run an ad during the Super Bowl",
    "Vera of haute couture",
    "Designer Vera",
    "\"The Joy Luck Club\" director, 1993",
    "Designer Vera",
    "Fashion designer Vera"
  ],
  "frei": [
    "Without cost: Ger.",
    "Independent, in Ingolstadt",
    "Costing nothing, in Cologne",
    "Having no cost, in Cologne",
    "At no cost, in Germany",
    "Chilean president, 1964-70",
    "At liberty, in Munich"
  ],
  "brel": [
    "Jacques who was \"alive and well and living in Paris\"",
    "Songwriter Jacques",
    "Belgian balladeer",
    "Singer Jacques",
    "Composer Jacques",
    "Jacques of song",
    "Belgian balladeer",
    "Belgian composer Jacques",
    "Belgian composer Jacques",
    "Songwriter Jacques",
    "Jacques the musical Belgian",
    "Belgian songwriter Jacques",
    "Jacques \"alive and well and living in Paris\"",
    "\"If We Only Have Love\" composer",
    "Singer Jacques",
    "Entertaining Jacques"
  ],
  "fait": [
    "French act",
    "___ accompli",
    "Made in France?",
    "___ accompli",
    "Cela ne ___ rien (never mind): Fr.",
    "Tout a ___ (entirely): Fr.",
    "___ accompli",
    "___ accompli"
  ],
  "enna": [
    "At 3,000 feet above sea level, the highest provincial capital in Italy",
    "Sicilian province",
    "Sicilian city",
    "City known as the Navel of Sicily",
    "Sicilian city",
    "Central Sicilian province",
    "Commune SE of Palermo",
    "City in Sicily",
    "Sicilian province or its capital",
    "Sicilian city",
    "City of central Sicily",
    "Sicilian city",
    "Sicilian province",
    "Central Sicily city",
    "Central Sicilian city",
    "Province between Palermo and Catania",
    "Sicilian resort city",
    "Sicilian city",
    "Elevated Sicilian city",
    "Italian province or its capital",
    "Italian province",
    "Sicilian city",
    "Italian summer resort",
    "Sicilian resort",
    "Sicilian resort",
    "Sicilian city",
    "Sicilian resort",
    "Sicilian province or its capital",
    "Italian resort city",
    "Sicilian resort",
    "Central Sicilian city"
  ],
  "dors": [
    "Actress Diana nicknamed the \"Blonde Bombshell\"",
    "British actress Diana",
    "Actress Diana",
    "Diana on the cover of \"Sgt. Pepper's Lonely Hearts Club Band\"",
    "\"The Blonde Bombshell\" biopic subject",
    "British actress Diana",
    "British sex symbol Diana",
    "Gobel's \"I Married a Woman\" co-star",
    "Actress Diana",
    "Actress Diana"
  ],
  "dray": [
    "Strong sideless wagon",
    "Farm cart",
    "Transporter of beer barrels",
    "Horse-pulled cart",
    "Heavy cart",
    "Heavy cart",
    "Utility cart",
    "Heavy cart",
    "Transporter of heavy loads",
    "Cart for heavy loads",
    "Horse-pulled vehicle",
    "Heavy cart",
    "Strong cart",
    "Strong cart",
    "Sideless wagon",
    "Sledge",
    "Heavy hauler",
    "Horse-drawn vehicle",
    "Mule-drawn vehicle",
    "Farm hauler",
    "Sideless wagon",
    "Heavy cart",
    "Strong cart",
    "Sideless cart",
    "Sturdy cart",
    "Sturdy cart",
    "Sturdy wagon",
    "Cart",
    "Strong cart",
    "Cart"
  ],
  "wong": [
    "\"The World of Suzie ___\" (1957 novel)",
    "\"The World of Suzie ___\"",
    "B. D. ___ of Broadway's \"M. Butterfly\"",
    "Actor B. D. of \"Law & Order: S.V.U.\"",
    "Anna May of \"Shanghai Express\"",
    "\"The World of Suzie ___\" (1960 movie)",
    "\"M. Butterfly\" Tony winner",
    "Anna May of \"Shanghai Express\"",
    "Old actress Anna May"
  ],
  "uele": [
    "River to the Ubangi",
    "Congolese river",
    "Ubangi tributary",
    "River to the Ubangi",
    "Ubangi tributary",
    "One of two rivers forming the Ubangi",
    "River to the Ubangi",
    "Congo river",
    "Ubangi feeder",
    "River to the Ubangi",
    "Congo river",
    "Ubangi tributary",
    "River to the Ubangi",
    "River to the Ubangi"
  ],
  "aoks": [
    "Breezy assents",
    "Positive signs of life in outer space?",
    "Thumbs-up responses",
    "NASA approvals",
    "Informal approvals",
    "\"All systems go\" signals",
    "\"Thumbs-up\" responses",
    "NASA green lights"
  ],
  "itar": [
    "Russia's ___-Tass news service",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "___-Tass news agency",
    "With 17-Across, big name in international news",
    "___-Tass (Russian news agency)",
    "___-Tass news agency",
    "___-Tass news agency",
    "___ Tass news agency"
  ],
  "tael": [
    "Far East unit of weight",
    "Bygone Chinese money",
    "Asian gold bar measure",
    "Chinese weight",
    "Old Chinese money",
    "Old Chinese money",
    "Asian unit of weight",
    "Chinese weight",
    "Chinese weight",
    "Far East weight",
    "Old Chinese money of account"
  ],
  "xout": [
    "Delete",
    "Cross through",
    "Delete with a cross",
    "Strike",
    "Cancel",
    "Expunge",
    "Get rid of",
    "Delete",
    "Delete",
    "Eliminate",
    "Delete",
    "Delete",
    "Delete",
    "Strike",
    "Cancel",
    "Cancel",
    "Delete",
    "Strike from a list",
    "Delete",
    "Strike from a list",
    "Strike",
    "Delete",
    "Delete",
    "Delete"
  ],
  "hoyt": [
    "Knuckleballer Wilhelm",
    "Folk singer ___ Axton",
    "First or last name in the Baseball Hall of Fame",
    "Wilhelm of Cooperstown",
    "Pitcher Wilhelm",
    "Waite ___, Hall-of-Fame Yankees pitcher",
    "Baseball Hall-of-Famer Wilhelm",
    "Hall-of-Fame pitcher Waite ___",
    "Yankee great Waite ___",
    "Yankee Hall-of-Famer Waite ___",
    "Knuckleballer ___ Wilhelm",
    "Singer/songwriter Axton",
    "Baseball Hall-of-Famer Waite ___",
    "Pitcher Wilhelm",
    "Country music's Axton"
  ],
  "fras": [
    "Monks' titles",
    "Some brothers",
    "Bartolommeo and Angelico",
    "Giocondo and Angelico",
    "Brothers",
    "Brothers",
    "Brothers",
    "Brothers",
    "Monastery titles",
    "Men in the hood?",
    "Benedictines",
    "Brothers' titles",
    "Abbey figures",
    "Brothers",
    "Lippo Lippi et al."
  ],
  "siss": [
    "Make a sibilant sound",
    "Leaky noise",
    "Puncture sound",
    "Snake sound"
  ],
  "bunk": [
    "Hooey",
    "Nonsense",
    "Rot",
    "Nonsense",
    "Upper or lower bed",
    "\"Nonsense!\"",
    "Empty promises",
    "Hogwash",
    "History, according to Ford",
    "Horsefeathers",
    "Hooey"
  ],
  "atip": [
    "On tenterhooks",
    "Eagerly expectant",
    "\"Take ___ from me!\"",
    "\"Take ___ from me\"",
    "Give ___ of the hat",
    "How police may act, after \"on\"",
    "\"Take ___ from me ?à\"",
    "Not having one's feet flat on the ground",
    "Eagerly expectant",
    "Expectantly",
    "One one's toes",
    "\"Take ___ from me ...\"",
    "On one's toes",
    "Eagerly expectant",
    "On one's toes",
    "Expectant",
    "\"Take___from me\"",
    "On one?Æs toes",
    "Eagerly expecting",
    "Eagerly expectant",
    "Eagerly expecting",
    "\"Take ___ from me\""
  ],
  "enzo": [
    "Automaker Ferrari",
    "Automaker Ferrari",
    "___ Ferrari, Italian automaker",
    "Automobile builder Ferrari",
    "Automaker Ferrari",
    "___ Angiolini (women's shoe brand)",
    "Automaker Ferrari",
    "Tenor role in \"La Gioconda\"",
    "Automaker Ferrari",
    "Automaker Ferrari",
    "Automaker Ferrari",
    "Women's shoe designer ___ Angiolini",
    "Automaker Ferrari",
    "Auto executive Ferrari",
    "Laura's \"La Gioconda\" lover",
    "Automaker Ferrari",
    "Carmaker Ferrari",
    "Automaker Ferrari",
    "Singer Stuarti",
    "Auto maker Ferrari",
    "Laura's lover in \"La Giocanda\"",
    "Car developer Ferrari",
    "Auto designer Ferrari",
    "Auto maker Ferrari",
    "Laura's lover in \"La Gioconda\""
  ],
  "amur": [
    "Border river between China and Russia",
    "Russia/China border river",
    "Russia/China border river",
    "Russia/China border river",
    "Russia/Manchuria boundary river",
    "Tatar Strait feeder",
    "Russia/Manchuria boundary river",
    "Asian border river",
    "China-Russia boundary river",
    "Manchurian border river"
  ],
  "doto": [
    "Inflict upon",
    "Inflict upon",
    "___ death (overwork)",
    "Inflict upon",
    "Inflict upon",
    "Inflict on",
    "Inflict upon",
    "Have an impact on",
    "Work on",
    "Affect",
    "Inflict on",
    "Inflict on"
  ],
  "resp": [
    "Defendant in court: Abbr.",
    "Court defendant: Abbr.",
    "Party in a legal proceeding: Abbr.",
    "Sued party in a legal case: Abbr.",
    "Legal defendant: Abbr.",
    "Defendant in court: Abbr.",
    "Plaintiff's opposite: Abbr.",
    "Defendant at law: Abbr.",
    "Defendant in court: Abbr.",
    "Legal defendant: Abbr.",
    "Legal defendant: Abbr.",
    "Answer: Abbr.",
    "Answer: Abbr."
  ],
  "dast": [
    "Dare, colloquially",
    "Has the nerve, in Dogpatch",
    "Dare, in Dogpatch"
  ],
  "groh": [
    "David of \"Rhoda\"",
    "\"Rhoda\" co-star David",
    "Actor David of \"Rhoda\"",
    "Actor David of \"Rhoda\"",
    "David of \"Rhoda\"",
    "David of \"Rhoda\"",
    "David of \"Rhoda\"",
    "Actor David of \"Rhoda\""
  ],
  "waac": [
    "Certain W.W. II enlistee",
    "Female mil. unit created 5/15/42",
    "W.W. II service acronym",
    "Female W.W. II grp.",
    "W.W. II server",
    "W.W. II female",
    "W.W. II org.",
    "W.W. II enlistee",
    "W.W. II females' service grp.",
    "W.W. II participant",
    "W.W. II woman",
    "W.W. II woman",
    "W.W. II miss"
  ],
  "bric": [
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac",
    "___-a-brac"
  ],
  "cmvi": [
    "The year 906",
    "The year 906",
    "Early 10th-century year",
    "10th-century annum",
    "Year in Sergius III's papacy"
  ],
  "zhou": [
    "First premier of the People's Republic of China",
    "Hua succeeded him",
    "Hua's predecessor as Chinese premier",
    "Mao colleague",
    "Premier under Mao"
  ],
  "hote": [
    "Table d'___",
    "Table d'___",
    "Table d'___",
    "Table d'___ (restaurant offering)",
    "Reception V.I.P.",
    "Table d'___",
    "Table d'___",
    "Table d'___",
    "Table d'___"
  ],
  "niro": [
    "\"De ___: A Life\" (2014 biography)",
    "Actor Robert De ___",
    "Robert De ___",
    "Actor Robert De ___",
    "De ___ (Nolte's \"Cape Fear\" co-star)",
    "Robert De ___"
  ],
  "poli": [
    "___ sci",
    "___ sci",
    "___ sci",
    "___ sci (coll. major)",
    "___ sci",
    "___ sci (college major, informally)",
    "___ Sci (college dept.)",
    "___ Sci"
  ],
  "iwas": [
    "\"___ robbed!\"",
    "\"___ a Teenage Werewolf\"",
    "\"When ___ younger, so much younger ...\" (\"Help!\" lyric)",
    "\"___ framed!\"",
    "\"___ framed!\"",
    "\"___ framed!\"",
    "\"___ robbed!\"",
    "\"As ___ saying ?à\"",
    "Question after \"You were snoring!\"",
    "\"___ afraid of that!\"",
    "\"___ framed!\"",
    "Elvis Presley's \"___ the One\"",
    "\"___ a Teen-age Werewolf\"",
    "\"As ___ saying ...\"",
    "\"___ only trying to help\"",
    "\"___ a Teen-age Werewolf\"",
    "\"___ Made to Love Her\"",
    "\"___ Made to Love Her\" (1967 hit)",
    "\"___ a Teen-Age Werewolf\"",
    "\"___a Male War Bride\" (Grant flick)"
  ],
  "perl": ["Physics Nobelist Martin, discoverer of the tauon"],
  "ipsa": [
    "Itself, in a Latin legal phrase",
    "Res ___ loquitur",
    "Self, in a Latin phrase",
    "Res ___ loquitur",
    "Res ___ loquitur",
    "Itself, in a Latin legal phrase",
    "Itself, in a legal phrase",
    "Res ___ loquitur",
    "Res ___ loquitur (legal phrase)",
    "\"Itself,\" in a phrase",
    "Res ___ loquitur",
    "Itself, in a Latin phrase",
    "Res ___ loquitur",
    "Res___ loquitur",
    "Res ___ loquitur"
  ],
  "anox": [
    "Dumb as ___",
    "\"He eateth grass as ___\": Job 40:15",
    "Strong as ___",
    "Strong as ___",
    "Dumb as ___"
  ],
  "awho": [
    "What Horton heard",
    "\"Horton Hears ___\"",
    "\"Horton Hears ___\"",
    "Seuss's \"Horton Hears ___\"",
    "Dr. Seuss's \"Horton Hears ___\"",
    "Seuss?Æs ?ôHorton Hears ___?ö",
    "Dr. Seuss's \"Horton Hears ___\"",
    "What Horton heard",
    "Dr. Seuss's \"Horton Hears ___\"",
    "Dr. Seuss's \"Horton Hears ___\"",
    "What Horton heard"
  ],
  "icel": [
    "Home of the elves known as huldufolk: Abbr.",
    "NATO member with the smallest population: Abbr.",
    "Nordic land: Abbr.",
    "Home of the Eyjafjallajokull volcano: Abbr.",
    "Reykjavik's land: Abbr.",
    "Reykjavik's home: Abbr.",
    "Place to find fjord explorers: Abbr.",
    "Home of the world's northernmost capital: Abbr.",
    "More than 70% of its exports are fish: Abbr.",
    "Reykjavik's home: Abbr.",
    "Where the Althing sits: Abbr.",
    "Where 17-Down originated: Abbr.",
    "NATO member: Abbr."
  ],
  "ilex": [
    "Holly tree",
    "Holly",
    "Holly genus",
    "Genus of holly",
    "Holly",
    "Holly plant",
    "Holly",
    "Holly tree",
    "Holly",
    "Holly",
    "Holm oak",
    "Holly genus",
    "Oak variety",
    "Holly",
    "Holly",
    "Holly shrub",
    "The holm oak"
  ],
  "adip": [
    "Go for ___ (swim)",
    "Take ___ (go swimming)",
    "Took ___ (went swimming)",
    "Go for ___"
  ],
  "rimy": [
    "Frost-covered",
    "Frost-covered",
    "Frost-covered",
    "Frost-covered",
    "Frost-covered",
    "Frosted",
    "Frosted",
    "Frost-covered"
  ],
  "okla": [
    "Home of Broken Arrow and Broken Bow: Abbr.",
    "Locale of Ada and Enid: Abbr.",
    "Where I-35 and I-40 meet: Abbr.",
    "Stillwater's home: Abbr.",
    "Tex. neighbor",
    "Fort Sill's home: Abbr.",
    "It's between Kan. and Tex.",
    "Tulsa's home: Abbr.",
    "The redbud is one of its symbols: Abbr.",
    "State with a panhandle: Abbr.",
    "The redbud is one of its symbols: Abbr.",
    "State with a panhandle: Abbr.",
    "Neighbor of Kan.",
    "Tulsa's locale: Abbr.",
    "Joad family's home state: Abbr.",
    "Home of 3-Down: Abbr.",
    "Kans. neighbor",
    "Kan. borderer",
    "Bartlesville's home: Abbr.",
    "Tex. neighbor",
    "Kan. neighbor",
    "Where Tulsa is: Abbr.",
    "Tex. neighbor",
    "Neighbor of Kan.",
    "Where Ron Howard was born: Abbr.",
    "Panhandler's home?: Abbr.",
    "It's west of Ark.",
    "Kan. neighbor",
    "It's west of Ark.",
    "Neighbor of Ark."
  ],
  "yows": [
    "Pained plaints",
    "Cries of agony",
    "Cries after being burned",
    "Cries during a bikini waxing?"
  ],
  "goos": [
    "Bits of baby talk",
    "Nursery sounds",
    "Gunks",
    "Pasty substances",
    "Gunks",
    "Baby talk \"words\"",
    "Gunks",
    "Gunks",
    "Sticky substances"
  ],
  "hist": [
    "Pulitzer Prize category: Abbr.",
    "Liberal arts subj.",
    "Arts and Sciences dept.",
    "H.S. subject",
    "H.S. subject",
    "Pulitzer Prize subj.",
    "H.S. subject",
    "6-Down's subj.",
    "Pulitzer Prize category: Abbr.",
    "High school subj.",
    "High sch. subject",
    "School subj.",
    "School subj.",
    "\"Hey you!\" sound"
  ],
  "aits": [
    "River islands",
    "River islands",
    "Small islands",
    "Brentford and Isleworth in the Thames, e.g.",
    "River isles",
    "Isles",
    "Small islands",
    "Islets",
    "River isles",
    "Isles",
    "British isles",
    "Islets",
    "River isle",
    "Small islands"
  ],
  "oros": [
    "Suit in a Spanish card deck",
    "Top honors for atletas olimpicos",
    "Some medals for Spanish athletes",
    "Top awards at los Juegos Olimpicos",
    "Top prizes at the Juegos Olimpicos",
    "Prizes for top atletas",
    "Rich Spanish decorations",
    "Spanish card suit",
    "1992 Olympics prizes",
    "Spanish card suit",
    "Inca fortunes"
  ],
  "mcci": [
    "Start of the 13th century",
    "Start of the 13th century",
    "Turn-of-the-century year in King John's reign",
    "14 years before the Magna Carta",
    "Thirteenth-century starter",
    "Beginning of the XIII century"
  ],
  "ruer": [
    "Regretful one",
    "One with misgivings",
    "Regretful one",
    "Sorry sort",
    "Regretful type",
    "Monday morning quarterback, maybe",
    "Penitent",
    "Sorry sort",
    "Sorry sort",
    "Sorry sort?",
    "Regretful one",
    "One who's sadder and maybe wiser",
    "One having second thoughts",
    "One kicking oneself",
    "One who's sorry",
    "Who's sorry now?",
    "Contrite type",
    "One having second thoughts",
    "One with second thoughts, say",
    "Sorry soul",
    "Sorry sort",
    "Remorseful one",
    "Sorry sort",
    "Person kicking himself, maybe",
    "\"Shoulda, woulda, coulda\" thinker",
    "Wistful sort",
    "Remorseful one",
    "Penitent one",
    "Regretful one",
    "Sorry soul"
  ],
  "akim": [
    "Tamiroff of \"Anastasia\"",
    "Actor Tamiroff",
    "Actor Tamiroff",
    "Actor Tamiroff",
    "Tamiroff of \"For Whom the Bell Tolls\"",
    "\"The Vulture\" star Tamiroff",
    "Old-time actor Tamiroff",
    "Actor ___ Tamiroff"
  ],
  "plax": ["Brand of dental rinse"],
  "nabe": [
    "Local theater, slangily",
    "Local theater, slangily",
    "Local theater, slangily",
    "Local theater",
    "Local theater, to Variety",
    "Local theater"
  ],
  "rota": [
    "Personnel list",
    "Series of golf courses that host the British Open",
    "\"The Godfather\" score composer Nino",
    "Membership list",
    "Vatican tribunal",
    "\"The Godfather\" composer Nino",
    "Nino who composed the music for \"The Godfather\"",
    "Membership list",
    "\"The Godfather\" composer Nino ___",
    "Italian composer Nino ___",
    "Roster",
    "Vatican tribunal",
    "Ancient Roman wheel",
    "Roster",
    "Papal tribunal"
  ],
  "onby": [
    "\"Walk ___\" (1964 hit)",
    "\"Walk ___\" (1964 hit)",
    "\"Walk ___\" (Dionne Warwick hit)",
    "Hanging ___ a thread",
    "\"Walk ___\" (1964 Warwick hit)"
  ],
  "oses": [
    "Sugar suffixes",
    "Ending with psych-",
    "Sugar suffixes",
    "Plural suffix for conditions",
    "End of abnormalities?",
    "Sweet suffixes?",
    "Sugar suffixes",
    "Sugar suffixes",
    "Suffix for abnormalities",
    "Sugar suffixes",
    "Medical suffixes",
    "Suffix for abnormalities",
    "Simple sugars",
    "Suffixes on candy wrappers",
    "Sugars"
  ],
  "apos": [
    "Common blood type: Abbr.",
    "Mil. mail centers",
    "Mil. addresses",
    "Blood drive spec.",
    "Blood type, for short",
    "G.I. addresses",
    "Where private messages may be sent?: Abbr.",
    "Sgts. and lts. get mail at them",
    "G.I. addresses",
    "Second-commonest blood type: Abbr.",
    "Mil. addresses",
    "Mil. addresses",
    "Blood type, briefly",
    "Mil. addresses",
    "G.I. addresses",
    "Mil. address",
    "Army addresses"
  ],
  "baro": [
    "Prefix with -meter",
    "Prefix with -meter",
    "Beginning for metric",
    "Pressure: Prefix"
  ],
  "yaya": [
    "1961 #1 R&B hit for Lee Dorsey",
    "\"Divine Secrets of the ___ Sisterhood\""
  ],
  "dace": [
    "Small freshwater fish",
    "Relative of a carp",
    "Bait fish for pike angling",
    "Freshwater fish",
    "Stout, freshwater fish",
    "Carp's kin",
    "Small freshwater fish",
    "Bait fish",
    "Carp's kin",
    "European freshwater fish"
  ],
  "tubb": [
    "Country singer Ernest",
    "Ernest nicknamed \"The Texas Troubadour\"",
    "Ernest of country music",
    "Ernest of the Country Music Hall of Fame",
    "Country music pioneer Ernest",
    "Country music legend Ernest",
    "Singer called the Texas Troubadour"
  ],
  "eeee": [
    "Extra-wide shoe spec",
    "Very wide shoe spec",
    "Facility",
    "Clown shoe width",
    "Very wide spec.",
    "Big shoe spec",
    "Extra-large shoe spec",
    "Shoe box marking",
    "Unusual shoe spec",
    "Extra-wide",
    "A really big shoe",
    "Superwide shoe specification",
    "Shoe width",
    "Extra-large shoe width"
  ],
  "amad": [
    "Make ___ dash for",
    "Make ___ dash for",
    "In ___ rush",
    "Make ___ dash",
    "\"Diary of ___ Housewife\"",
    "Make ___ dash",
    "In ___ rush",
    "In ___ rush",
    "\"Diary of ___ Black Woman\" (2005 film)",
    "In ___ rush",
    "Make ___ dash for",
    "\"Diary of ___ Housewife\" (1970 film)",
    "In ___ rush",
    "Make ___ dash",
    "\"Diary of ___ Housewife\"",
    "Make ___ dash for",
    "Make ___ dash for",
    "Make ___ dash for",
    "\"Diary of ___ Housewife\"",
    "Make ___ dash for",
    "\"Diary of ___ Housewife\"",
    "\"It's ___ world\": Dickens",
    "\"Diary of ___ Housewife\"",
    "\"It's ___...World\"",
    "\"Diary of ___ Housewife\"",
    "\"Diary of ___ Housewife\"",
    "Make ___ dash for"
  ],
  "mnem": [
    "Memory: Prefix",
    "Memory: Prefix",
    "Memory: Prefix",
    "Memory: Prefix",
    "Memory: Prefix"
  ],
  "ciel": [
    "Sky: Fr.",
    "Sky: Fr.",
    "Sky: Fr.",
    "Sky over the Seine",
    "Where to see le soleil",
    "Sky over Strasbourg",
    "Heaven, in Le Havre",
    "Heaven, to Henri"
  ],
  "rels": [
    "Mom and pop, e.g.: Abbr.",
    "Genealogical line: Abbr.",
    "Kin: Abbr.",
    "Fam. members",
    "Family: Abbr.",
    "Reunion group: Abbr.",
    "Genealogical listings: Abbr.",
    "Reuners: Abbr.",
    "Blood grp.?",
    "Kinfolk: Abbr.",
    "Brother, aunt, etc.: Abbr.",
    "Aunts and uncles: Abbr.",
    "Electric units",
    "Fam. tree listings"
  ],
  "razr": [
    "Motorola phone brand",
    "Motorola smartphone",
    "Motorola phone brand",
    "Motorola phone",
    "Motorola cellphone",
    "Motorola cell phone brand"
  ],
  "eger": [
    "Hungarian city",
    "Castle of ___ (Hungarian tourist draw)",
    "Hungarian city known for \"Bull's Blood\" wine",
    "Hohenberg's river",
    "Hungarian city that has hosted two World Puzzle Championships",
    "Hungarian city known for its thermal baths",
    "Hungarian city",
    "Hungarian city known for its thermal baths",
    "Hungarian spa town",
    "Hungarian city or its river",
    "It flows to the Elbe",
    "Hungarian city near Miskolc",
    "City ENE of Budapest",
    "Bavarian river",
    "River to the Elbe",
    "Hungarian spa city",
    "Bavarian river",
    "Elbe feeder",
    "Hungarian city or river",
    "River through Bavaria",
    "Elbe tributary",
    "Elbe tributary",
    "The Rome of Hungary",
    "Elbe tributary",
    "Elbe feeder"
  ],
  "agon": [
    "Ancient Greek contest",
    "Stravinsky ballet",
    "1957 Stravinsky ballet",
    "Literary conflict",
    "Ballet to Stravinsky's music, by George",
    "Greek-style contest",
    "Stravinsky ballet",
    "Stravinsky ballet",
    "Stravinsky ballet",
    "Stravinsky ballet",
    "Conflict in Greek drama",
    "Attic contest"
  ],
  "mums": [
    "Fall bloomers",
    "Appropriate flowers for Mother's Day?",
    "Bouquet parts",
    "British P.T.A. members",
    "Vase occupants",
    "Autumn bouquet",
    "Flowers, for short",
    "Flowers for floats"
  ],
  "kwai": [
    "Title river in a 1957 film",
    "\"The Bridge on the River ___\"",
    "\"The Bridge on the River ___\"",
    "Title river in 1957's Best Picture",
    "River in a 1957 hit movie",
    "River in a 1957 hit film",
    "Famous movie river",
    "River in a 1957 film",
    "\"The Bridge on the River ___\"",
    "River in a Best Picture title",
    "River in a 1957 hit film",
    "\"The Bridge on the River ___\"",
    "River in a 1957 film",
    "River in a Best Picture title"
  ],
  "aths": [
    "Users of locker rooms: Abbr.",
    "Olympians, e.g.: Abbr.",
    "Sports players: Abbr.",
    "Jocks: Abbr.",
    "Olympians: Abbr."
  ],
  "neur": [
    "Prefix with -algia",
    "Prefix with -itis",
    "Doctor's prefix with -ologist",
    "Prefix with -algia",
    "Prefix with -algia",
    "Prefix with -itis",
    "Nerve opening?",
    "Medical prefix"
  ],
  "seri": [
    "Bandar ___ Begawan (Brunei's capital)",
    "Golfer ___ Pak",
    "Golfer ___ Pak",
    "Golf's ___ Pak",
    "Bandar ___ Begawan (capital of Brunei)",
    "Bandar ___ Begawan, capital of Brunei",
    "Bandar ___ Begawan, capital of Brunei",
    "Golf's 1998 U.S. Women's Open winner ___ Pak",
    "Bandar ___ Begawan (Brunei's capital)",
    "Bandar___ Begawan (Brunei's capital)"
  ],
  "rfds": [
    "Country addresses: Abbr.",
    "Country addresses, for short",
    "Old U.S.P.S. routing codes",
    "Country addresses: Abbr.",
    "Country mail rtes.",
    "Country mail rtes.",
    "Country addresses, for short",
    "Some mail services: Abbr.",
    "Some mail designations: Abbr.",
    "Some rtes.",
    "Country mail systems, for short"
  ],
  "zeit": [
    "Time, in Germany",
    "Time, to Freud",
    "Time, in Munich",
    "Time, in Mannheim",
    "Time: Ger."
  ],
  "mawr": [
    "Bryn ___ College",
    "Bryn ___",
    "Bryn ___",
    "Bryn ___ College",
    "Bryn ___",
    "Bryn ___ College",
    "Bryn ___",
    "Bryn ___ College",
    "Bryn ___, Pa.",
    "Bryn ___ College",
    "Bryn ___ College",
    "Bryn ___ College",
    "Bryn ___ College",
    "Bryn ___ College",
    "Bryn ___ College",
    "Bryn ___, Pa."
  ],
  "pimp": [
    "\"___ My Ride\" (old MTV series)",
    "Trick out, as a car",
    "Go-between, of sorts"
  ],
  "webs": [
    "Some Halloween decorations",
    "Some Halloween decor",
    "ABC, Fox, etc., in Variety",
    "Attic fillers",
    "Signs of disuse",
    "Signs of neglect",
    "Fly nets?",
    "Entanglements",
    "Spiders' creations",
    "Fly catchers",
    "Spiders' work",
    "Fly traps",
    "Attic sights",
    "Fly traps",
    "Tangles",
    "Signs of disuse",
    "Haunted house decor",
    "Insect snares",
    "Entanglements"
  ],
  "unca": [
    "Donald Duck, to his nephews",
    "Donald Duck, to his nephews",
    "Donald, to Dewey",
    "Donald Duck, to Dewey",
    "Donald Duck, to his nephews",
    "Donald, to Huey, Dewey or Louie",
    "Donald Duck, to Huey, Dewey and Louie"
  ],
  "edin": ["___ chief (mag. V.I.P.)", "___ chief (publ. honcho)"],
  "afan": [
    "Picasso's \"Lady With ___\"",
    "Diego Velazquez?Æs ?ôLady With ___?ö"
  ],
  "alad": [
    "\"When I was ___ ...\"",
    "\"___ of life, an imp of fame; / Of parents good ...\": \"Henry V\"",
    "\"___-in His Lamp\" (Bugs Bunny classic)",
    "\"When I was ___ ...\"",
    "\"When I was ___ ...\"",
    "\"When I was ___ ...\"",
    "\"When I was ___\"",
    "\"When I Was ___\"",
    "\"When I was___...\"",
    "\"When I was ___...\"",
    "\"When I was ___...\""
  ],
  "ites": [
    "Followers: Suffix",
    "Endings of rock names",
    "Suffixes of 61-Across",
    "Devotees: Suffix",
    "Kin of -niks",
    "Mineral suffixes",
    "Denizens: Suffix",
    "Social finishes?",
    "Plural suffix with urban",
    "Residents: Suffix",
    "Residents: Suffix",
    "Suffix with social",
    "Followers: Suffix",
    "Inhabitants: Suffix",
    "Residents: Suffix",
    "Suffix akin to -ese",
    "Mineral suffixes",
    "Residents: Suffix",
    "Resident suffixes",
    "Devotees: Suffix",
    "Residents: Suffix",
    "Followers: Suffix",
    "Residents: Suffix",
    "Citizens: Suffix"
  ],
  "deme": ["Greek township", "Greek township"],
  "foch": [
    "French W.W. I general Ferdinand ___",
    "Nina of 1940s-'50s films",
    "French W.W. I commander Ferdinand",
    "Allied commander of 1918",
    "Marshal of fighting fame"
  ],
  "sett": [
    "Granite paving block",
    "Rectangular paving stone",
    "Paving block",
    "Paving stone",
    "Rectangular paving stone",
    "Small paving stone"
  ],
  "arag": [
    "Limp as ___",
    "\"She's ___ doll\" (4 Seasons lyric)",
    "Limp as ___",
    "\"She's ___ doll\" (4 Seasons lyric)",
    "Limp as ___",
    "\"To ___ and a bone ...\": Kipling",
    "?ôTo ___ and a bone...?ö: Kipling"
  ],
  "cmaj": [
    "Key of all white keys: Abbr.",
    "Key of Schubert's Symphony No. 9: Abbr."
  ],
  "orit": ["\"Pics ___ didn't happen\" (slangy challenge)"],
  "nyer": ["Manhattanite, e.g., for short", "Manhattanite, e.g., informally"],
  "thro": [
    "\"Comin' ___ the Rye\"",
    "\"And ___ the field the road runs by\": Tennyson",
    "\"Comin' ___ the Rye\"",
    "Sno-___ (winter blower brand)",
    "\"Comin' ___ the Rye\"",
    "\"And ___ the field the road runs by\": Tennyson",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "Poetic preposition",
    "\"Comin' ___ the Rye\"",
    "\"Comin' ___ the Rye\"",
    "Amidst, briefly",
    "\"Comin' ___ the Rye\"",
    "Archaic preposition",
    "\"Comin' ___ the Rye\"",
    "Brief preposition",
    "\"Comin' ___ the Rye\"",
    "Across, old-style",
    "\"Comin'___the rye\"",
    "Poet's preposition",
    "\"Comin' ___ the Rye\"",
    "By way of, old-style",
    "\"Comin' ___ the Rye\""
  ],
  "mdxi": [
    "Year that Cambridge's St. John's College was founded",
    "Year of the first Spanish settlement in Cuba",
    "Year Cortes conquered Cuba"
  ],
  "axer": [
    "Woodcutter, e.g.",
    "Hatchet man",
    "Woodchopper, say",
    "Lumberjack, e.g.",
    "Ruthless personnel director",
    "Ruthless boss, perhaps",
    "Downsizer",
    "Feller",
    "Ruthless boss",
    "Splitter who makes splinters?",
    "Paul Bunyan, at times",
    "Canner?"
  ],
  "lehr": [
    "Glassmaker's oven",
    "Specialty oven",
    "Old-time comic Lew",
    "Glassmaker's oven",
    "Glassmaking oven",
    "Comic Lew",
    "Annealing oven",
    "Glassmaker's oven"
  ],
  "vaio": ["Big name in laptops", "Sony laptop line"],
  "dabo": [
    "Olivia who won a Razzie for \"Bolero\" and \"Conan the Destroyer\"",
    "Actress Olivia of \"Law & Order: Criminal Intent\"",
    "Actress Maryam",
    "Olivia ___ of \"The Wonder Years\"",
    "Actress Maryam",
    "Actress Maryam",
    "Actress Maryam"
  ],
  "wisk": ["Oxi Complete detergent"],
  "spaz": [
    "\"I'm such a ___\" (klutz's comment)",
    "Very clumsy person, in slang",
    "\"I'm such a ___!\" (klutz's comment)"
  ],
  "kays": [
    "Some jewelry stores, informally",
    "Letters between jays and ells",
    "Starr and Thompson",
    "Strikeout symbols, in baseball"
  ],
  "pams": [
    "Dawber and Grier",
    "Fox Sports reporter Oliver and others",
    "Actress Dawber and others",
    "Actress Dawber et al."
  ],
  "grig": ["Small eel", "Young eel", "Lively person", "Live wire"],
  "omer": [
    "Post-Passover period",
    "Biblical dry measure",
    "Counting of the ___ (observance after Passover)",
    "Post-Passover period",
    "Hebrew measure",
    "One-tenth of an ephah",
    "49-day period in Judaism",
    "Hebrew measure",
    "Hebrew dry measure",
    "Biblical measure",
    "Hebrew dry measure"
  ],
  "mdii": [
    "Year Columbus began his last voyage",
    "Year of Columbus's last voyage"
  ],
  "awet": [
    "Mad as ___ hen",
    "\"Seance on ___ Afternoon\" (1964 suspense thriller)",
    "With 21-Across, symbol of madness",
    "Mad as ___ hen"
  ],
  "odas": [
    "Harem rooms",
    "Harem rooms",
    "Harem chambers",
    "Harem rooms",
    "Harem rooms",
    "Sultanas' rooms",
    "Turkish rooms"
  ],
  "olea": [
    "Olive genus",
    "Olive, to Ovid",
    "Olive genus",
    "Olive genus",
    "Roman \"olive\"",
    "Olive genus",
    "Olive genus",
    "Pharmaceutical oils",
    "Olive genus",
    "Olive genus",
    "Olive genus",
    "Latin oils",
    "Olive genus",
    "Olive genus"
  ],
  "dong": [
    "Bell sound",
    "Vietnamese currency",
    "End of a doorbell sound",
    "Doorbell sound",
    "Half a ring",
    "... a doorbell",
    "Steeple sound",
    "Tintinnabulation",
    "Vietnamese currency",
    "Bell sound",
    "Doorbell sound"
  ],
  "foxy": [
    "Sly",
    "Guileful",
    "Devious",
    "With 67-Across, song by 56-Across",
    "Clever",
    "Cunning",
    "Full of guile",
    "Cunning",
    "Cunning",
    "Good-lookin'",
    "Very alluring",
    "Artful",
    "Conniving"
  ],
  "jute": [
    "Burlap fiber",
    "Coffee sack material",
    "Rope fiber",
    "Cordage material",
    "Rope fiber",
    "Coarse fiber",
    "Gunnysack fiber",
    "Burlap fiber",
    "Saxon contemporary"
  ],
  "atwo": [
    "Part of Lawrence Welk's introduction",
    "Audi model retired in 2005",
    "Part of the intro to a piece of \"Champagne Music\"",
    "U.K. highway connecting London and Dover",
    "\"A one and ___\"",
    "German superhighway connecting the Ruhr with Berlin",
    "Part of Lawrence Welk's intro",
    "Part of Lawrence Welk's intro",
    "Retired Audi supermini",
    "See 64-Across",
    "Part of a Lawrence Welk count",
    "Welk's \"?à and ___\"",
    "\"A one and ___...\"",
    "\"A-one and ___\"",
    "Part of a Welk intro"
  ],
  "vals": [
    "Triple-time dance, in Durango",
    "Dance in triple time: Sp.",
    "Actor Kilmer and others",
    "Actor Kilmer and others",
    "Actor Kilmer and others",
    "Physics Nobelist Fitch and others",
    "Where many vins come from"
  ],
  "erde": [
    "Our planet, to Germans",
    "One of die Planeten",
    "Our planet, to a German",
    "Earth, to Brahms",
    "Our planet, to a Berliner",
    "Himmel und ___ (apple-and-potato dish)",
    "Earth, in Essen",
    "One of the Planeten",
    "Himmel und ___ (traditional German potato dish)",
    "Mahler's \"Das Lied von der ___\"",
    "Our 6-Down, in Germany",
    "Earth, to Mahler",
    "Earth, to Mahler",
    "Second planet past Merkur",
    "Mahler's \"Das Lied von der ___\"",
    "One of die Planeten",
    "Solid ground, in Stuttgart",
    "\"Das Lied von der ___\"",
    "Earth, to Mahler",
    "Third planet from die Sonne",
    "Mahler's \"Das Lied von der ___\"",
    "\"Das Lied von der ___\"",
    "Earth, in Essen",
    "Mahler's \"Das Lied von der ___\"",
    "Mahler's \"Das Lied von der ___\"",
    "Mahler's \"Das Lied von der ___\""
  ],
  "sese": [
    "Zairean president Mobutu ___ Seko",
    "Former Zairian leader Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Mobutu ___ Seko (African despot)",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Mobutu ___ Seko of Zaire",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Dictator Mobutu ___ Seko",
    "Zairean autocrat Mobutu ___ Seko",
    "Late Zairean dictator Mobutu ___ Seko",
    "Zaire's Mobutu ___ Seko",
    "Late ruler Mobotu ___ Seko",
    "Zaire's Mobutu ___Seko"
  ],
  "copt": [
    "Egyptian Christian",
    "Egyptian Christian",
    "Egyptian Christian",
    "Egyptian church member"
  ],
  "onin": [
    "Getting ___ years",
    "\"Come ___!\"",
    "\"What's going ___ there?\"",
    "Words after \"come\" or \"go\"",
    "\"Go ___!\"",
    "\"Hey, what's going ___ there?\"",
    "\"Go ___!\"",
    "\"What's going ___ there?\"",
    "\"Come ___!\"",
    "\"Come ___!\"",
    "\"Come ___!\"",
    "\"Come ___\"",
    "\"Hey, what's going ___ there?\"",
    "\"Come ___!\"",
    "Getting ___ years",
    "___ years (old)",
    "\"Come ___!\"",
    "Getting ___ years",
    "Getting ___ years",
    "Getting ___ years",
    "\"Come ___!\"",
    "\"Come___!\"",
    "Getting ___ years",
    "Getting ___ years",
    "\"Come ___!\"",
    "Getting ___ years",
    "\"Come ___, the water's fine!\""
  ],
  "adak": [
    "Alaskan island or its principal town",
    "Aleutian island",
    "One of the Aleutians",
    "Aleutian island"
  ],
  "yegg": [
    "One doing safe work?",
    "One with whom your safe is not safe",
    "Sort who isn't safe around a safe",
    "One with a job opening?",
    "Safecracker",
    "Person with a safe job?",
    "Burglar who really gets cracking?",
    "Burglar in detective stories",
    "Safecracker",
    "Safecracker",
    "One trying to find the right combination?",
    "Safecracker",
    "Human cracker",
    "Person with a safe job?",
    "Burglar",
    "Cracker, of sorts",
    "Itinerant burglar, slangily",
    "Vault cracker",
    "Skilled felon",
    "Burglar",
    "Safecracker",
    "Safecracker",
    "Peterman",
    "Peterman",
    "Burglar",
    "Old-fashioned criminal",
    "Peterman"
  ],
  "arip": [
    "Not give ___ (be indifferent)",
    "Take ___ (swing hard in baseball)",
    "Take ___ (swing hard)",
    "Take ___ (swing hard)",
    "\"What ___!\" (\"That's robbery!\")",
    "Give ___ (care)",
    "Not give ___ (not care)",
    "Give it ___ (swing hard)",
    "\"What ___!\" (\"That's rich!\")",
    "Doesn't give ___"
  ],
  "jima": [
    "\"Sands of Iwo ___\"",
    "Iwo ___",
    "Chichi-___ (largest of Japan's Bonin Islands)",
    "Chichi ___, Japan"
  ],
  "judi": [
    "Dame Dench",
    "Oscar winner Dench",
    "Dench who played Elizabeth I",
    "Actress Dench"
  ],
  "efgh": [
    "I will follow it",
    "Alphabet quartet",
    "What I will follow",
    "What I will follow",
    "Run after D",
    "D train?",
    "What I must follow, in a children's song"
  ],
  "slye": [
    "Roy Rogers's real last name",
    "Leonard ___ a.k.a. Roy Rogers",
    "Roy Rogers's surname at birth",
    "Roy Rogers's original surname",
    "Roy Rogers's real surname",
    "Roy Rogers, ne Leonard ___",
    "Roy Rogers's real surname",
    "Roy Rogers a k a Leonard ___",
    "Leonard ___, alias Roy Rogers",
    "Cowboy Rogers's real last name"
  ],
  "leal": [
    "Faithful, in old poetry",
    "Faithful, to a Scot",
    "Faithful, to a Scot",
    "Faithful, to a Scot"
  ],
  "stus": [
    "Lakers commentator Lantz and others",
    "N.B.A. coach Jackson and others",
    "Poker great Ungar and others",
    "Good names for old flight attendants?",
    "Apt male names for this puzzle",
    "Comic Gilliam et al.",
    "Actor Erwin et al.",
    "Comic Gilliam and namesakes"
  ],
  "fehr": [
    "N.H.L. players' representative Donald",
    "Longtime baseball union exec Donald",
    "Donald of the Major League Baseball Players Association",
    "Longtime baseball union head Donald ___"
  ],
  "eers": [
    "Plural suffix with musket",
    "Suffixes with mountain and cannon",
    "Relative of -ists",
    "Endings with mountain and election",
    "Plural suffix with auction or musket",
    "Kin of -ists",
    "Plural suffix with auction",
    "Plural suffix with weapon",
    "Suffixes with ballad and command"
  ],
  "flix": ["Movies, informally"],
  "aber": ["But, in Bonn", "But, to Brahms", "But: Ger."],
  "aroo": [
    "\"King ___\" of old comics",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Buck tail?",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Ending with buck",
    "Suffix with buck",
    "Buck's tail?",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Buck chaser?",
    "Suffix with buck",
    "Suffix with buck",
    "Ending for buck",
    "Buck add-on",
    "Suffix with buck",
    "King of 50's-60's comics",
    "Suffix with buck",
    "Suffix with buck",
    "Suffix with buck",
    "Slangy suffix",
    "\"King ___\" (1950-65 comic strip)",
    "Slangy suffix",
    "Slangy suffix",
    "King of old comics",
    "Suffix with buck",
    "Suffix with buck",
    "Ending with buck or stink",
    "Slangy suffix",
    "Suffix with buck",
    "Buck extension",
    "\"King ___\" of old comics",
    "Buck add-on",
    "Suffix with buck",
    "Suffix with buck",
    "Buck follower"
  ],
  "shmo": [
    "Ordinary guy: Var.",
    "Chucklehead",
    "Jerk",
    "Jerk: Var.",
    "Jerk: Var."
  ],
  "sero": [
    "Hematology prefix",
    "Prefix in hematology",
    "Blood fluid: Prefix",
    "Blood fluid: Prefix"
  ],
  "fays": ["Elves, in poetry", "Wray and others"],
  "atry": [
    "\"Give this ___\"",
    "\"Give it ___!\"",
    "Give it ___",
    "\"It's worth ___\"",
    "Give it ___",
    "\"Give it ___!\""
  ],
  "kerf": ["Slit made with a saw", "Cut made by a saw"],
  "epis": [
    "Certain Protestant: Abbr.",
    "Like Bush Sr., religiously",
    "Christian denom.",
    "Certain Prot.",
    "Certain Prot.",
    "Christian denom.",
    "Anglican's cousin: Abbr.",
    "Like some churches: Abbr.",
    "Bush's denom.",
    "Certain Protestant: Abbr.",
    "Prot. denomination"
  ],
  "goby": ["Elapse", "Elapse", "Pass", "Pass", "Elapse", "Elapse", "Pass"],
  "buco": ["Osso ___", "Osso ___", "Osso ___ (veal dish)"],
  "yoho": [
    "High-seas cry",
    "Disney tune subtitled \"A Pirate's Life for Me\"",
    "Start of a pirate's chant",
    "Boozy sailor's cry"
  ],
  "sols": [
    "Some notes",
    "Short answers?",
    "Answers, for short",
    "Peruvian money",
    "Hundreds of centavos",
    "Answers, for short",
    "Musical notes"
  ],
  "duff": [
    "Homer Simpson's favorite beer",
    "Keister",
    "Heinie",
    "Rear",
    "Backside",
    "Backside",
    "Rump"
  ],
  "budd": [
    "Melville's \"Billy ___\"",
    "Fictional Billy",
    "Melville's \"Billy ___\"",
    "Melville's Billy",
    "Melville's Billy",
    "Melville foretopman"
  ],
  "biog": [
    "Bookstore sect.",
    "Bookstore sect.",
    "Bookstore sect.",
    "Bookstore sect.",
    "Bookstore sect.",
    "Library section: Abbr.",
    "Like some sketches: Abbr.",
    "Literary genre: Abbr.",
    "Lit. genre",
    "Life story: Abbr."
  ],
  "coms": [
    "Rom-___ (some films)",
    "Rom-___ (some film fare)",
    "Dot-___",
    "Dot-___",
    "Dot-___",
    "Nav. officers"
  ],
  "atme": [
    "\"Look ___!\"",
    "\"Look ___ now!\"",
    "\"Look ___!\"",
    "\"Look ___ now\"",
    "*\"Don't look ___!\"",
    "\"Stop staring ___!\"",
    "\"Don't throw bouquets ___\"",
    "\"Ma! He's Making Eyes ___\" (1940 hit)",
    "\"Don't look ___!\"",
    "\"Don't look ___ that way!\"",
    "\"You lookin' ___?\"",
    "\"Look ___\" (1975 #1 R & B hit)",
    "\"Look ___!\"",
    "\"You looking ___?\"",
    "\"Look ___!\"",
    "Tommy Dorsey hit \"Oh, Look ___ Now\"",
    "\"Look ___!\"",
    "\"Don't look ___!\"",
    "\"Stop yelling ___!\"",
    "\"Don't look ___!\"",
    "\"Don't look ___!\"",
    "\"Look ___!\"",
    "\"Ma! He's Making Eyes ___\"",
    "\"Don't look ___!\"",
    "\"Look ___...\"",
    "\"Don't look ___!\"",
    "\"Look ___!\"",
    "\"Don't look ___ like that!\"",
    "\"Look ___!\" (showoff's words)",
    "\"Look ___ (I'm in Love)\"",
    "Tommy Dorsey's \"Oh, Look___Now\""
  ],
  "hema": [
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix",
    "Blood: Prefix"
  ],
  "enyo": [
    "Greek war goddess",
    "Greek war goddess",
    "Greek war goddess",
    "Greek war goddess",
    "Greek war goddess",
    "Greek goddess of war",
    "Greek war goddess",
    "Greek war goddess"
  ],
  "agob": [
    "Oodles",
    "Lots",
    "\"A Girl, A Guy and ___\" (1941 Ball movie)",
    "Lots"
  ],
  "stas": [
    "Boarding spots: Abbr.",
    "Stops: Abbr.",
    "Places where connections are made: Abbr.",
    "Union and others: Abbr.",
    "Metro map points: Abbr.",
    "Depots: Abbr.",
    "Stepping-off points: Abbr.",
    "Subway stops: Abbr.",
    "London's Covent Garden and others: Abbr.",
    "Subway map array: Abbr.",
    "Penn. and others",
    "Commuter map points: Abbr.",
    "Reading stops: Abbr.",
    "Points on a line: Abbr.",
    "Parts of a line: Abbr.",
    "On/off points: Abbr.",
    "Stops: Abbr.",
    "They're found all along the line: Abbr.",
    "Training places?: Abbr.",
    "RR stops",
    "Stops on the I.R.T.",
    "AM selections: Abbr.",
    "L.I.R.R. stops",
    "Stops: Abbr.",
    "B.&O. stops",
    "L.I.R.R. stops",
    "Timetable listings: Abbr.",
    "Depots: Abbr.",
    "El stops: Abbr.",
    "Penn., et al.",
    "Victoria et al.: Abbr.",
    "RR stops",
    "Termini: Abbr.",
    "Union and others: Abbr."
  ],
  "abow": [
    "\"Take ___\" (1994 Madonna hit that was #1 for seven weeks)",
    "Take ___ (acknowledge applause)",
    "\"Take ___\" (1994 Madonna hit)",
    "\"Take ___\" (\"Congratulations!\")",
    "Take___(accept congratulations)",
    "\"Take___\" (\"Congrats!\")"
  ],
  "acth": [
    "Pituitary gland output, briefly",
    "Pituitary hormone",
    "Pituitary gland hormone",
    "Pituitary gland output",
    "Pituitary hormone",
    "Growth hormone: Abbr.",
    "Pituitary hormone, for short"
  ],
  "taws": [
    "Shooters",
    "Fancy shooters",
    "Shooters",
    "Fancy marbles",
    "Fancy shooting marbles",
    "Choice marbles",
    "Shooters",
    "Fancy marbles",
    "Fancy marbles",
    "Shooters",
    "Shooters",
    "Fancy shooters",
    "Fancy marbles",
    "Shooters"
  ],
  "lind": [
    "The Swedish Nightingale",
    "Jenny ___ a k a the Swedish Nightingale",
    "Swedish soprano Jenny",
    "Operatic Jenny",
    "Soprano Jenny",
    "Swedish diva Jenny",
    "Soprano who once worked for P. T. Barnum",
    "The Swedish Nightingale",
    "Star of an 1850's Barnum tour",
    "Barnum's soprano"
  ],
  "adms": [
    "Four-star figs.",
    "Byrd and others: Abbr.",
    "U.S.N. brass: Abbr.",
    "U.S.N. brass: Abbr.",
    "Wearers of four stars: Abbr.",
    "Planners of nav. maneuvers",
    "U.S.N. bigwigs",
    "Nav. officers"
  ],
  "rpts": [
    "Studies: Abbr.",
    "What sgts. turn in at HQ's",
    "Sch. research papers",
    "Sch. papers"
  ],
  "kiel": [
    "German city on the Baltic",
    "Actor Richard who played Jaws in Bond films",
    "German port on the Baltic",
    "German city on a canal of the same name",
    "Germany's ___ Canal",
    "German port",
    "German port",
    "German canal name",
    "German city on the Baltic",
    "German city with a harbor",
    "Actor Martin of \"Hill Street Blues\"",
    "German canal city",
    "Canal to the Baltic",
    "Port on the Baltic",
    "Canal to the Baltic"
  ],
  "unie": [
    "Together, in France",
    "Together, in Toulouse",
    "Together, in Toulon",
    "Combined, in Compiegne"
  ],
  "clew": [
    "Discovery in a British mystery",
    "Something of interest to Miss Marple",
    "Interest of Miss Marple",
    "Scotland Yard discovery",
    "Scotland Yard discovery",
    "Miss Marple's discovery",
    "Miss Marple discovery"
  ],
  "hava": ["\"___ Nagila\"", "\"___ Nagila\" (Hebrew folk song)"],
  "voss": ["1957 Patrick White novel adapted into a 1986 opera"],
  "hotl": [
    "Lanford Wilson's \"The ___ Baltimore\"",
    "Wilson's \"The ___ Baltimore\"",
    "Off-Broadway's \"___ Baltimore\"",
    "Lanford Wilson's \"The ___ Baltimore\"",
    "Wilson's \"The ___ Baltimore\"",
    "Lanford Wilson's \"The ___ Baltimore\"",
    "\"The ___ Baltimore\" (Lanford Wilson play)",
    "Lanford Wilson's \"The ___ Baltimore\"",
    "Off-Broadway's \"___ Baltimore\""
  ],
  "asip": [
    "Take ___ (sample some)",
    "Take ___ (sample some)",
    "Take ___ of (sample)",
    "Take ___ (try some)"
  ],
  "bels": [
    "Sound units",
    "Sound units",
    "Units of sound",
    "Loudness units",
    "Sound units"
  ],
  "mmiv": ["Year Bush was re-elected", "Year of Super Bowl XXXVIII"],
  "liat": [
    "\"South Pacific\" girl",
    "\"South Pacific\" girl",
    "Joe's love interest in \"South Pacific\"",
    "Bloody Mary's daughter in \"South Pacific\"",
    "\"South Pacific\" girl",
    "\"South Pacific\" role",
    "\"South Pacific\" lass",
    "\"South Pacific\" role"
  ],
  "arib": [
    "Bust ___ (laugh hard)",
    "Bust ___ (laugh hard)",
    "Bust ___ (laugh hard)",
    "\"Then join you with them, like ___ of steel\": Shak."
  ],
  "efor": [
    "___ effort",
    "Get an ___ effort",
    "Get an ___ effort",
    "___ effort",
    "Get an ___ effort",
    "Get an ___ effort",
    "Get an ___ effort",
    "Get an ___ effort",
    "___ effort",
    "Get an ___ effort",
    "___ effort",
    "Get an ___ effort",
    "Give an ___ effort",
    "___ effort",
    "___ excellence",
    "___ effort",
    "Get an ___ effort"
  ],
  "jana": [
    "1998 Wimbledon champ Novotna",
    "Actress/country singer Kramer",
    "Tennis star Novotna",
    "1998 Wimbledon winner Novotna"
  ],
  "dats": [
    "\"___ what I'm talkin' 'bout!\"",
    "Jamie Foxx's \"Yep ___ Me\"",
    "\"___ all!\" (\"Fini!\")",
    "Missy Elliott's \"___ What I'm Talkin' About\""
  ],
  "zazu": ["\"The Lion King\" bird", "Bird in \"The Lion King\""],
  "bleb": ["Air bubble", "Air bubble", "Blister", "Air bubble"],
  "marl": [
    "Earthy deposit",
    "Clayey deposit",
    "Earthy deposit",
    "Clayey sediment",
    "Soil fertilizer",
    "Earthy deposit",
    "Clayey deposit",
    "Soil fertilizer",
    "Common fertilizer",
    "Clayey deposit"
  ],
  "mdcc": [
    "Year John Dryden died",
    "Year that Clement XI became pope",
    1700,
    "Year Dryden died"
  ],
  "dels": [
    "Conventioneers: Abbr.",
    "Singers Shannon and Reeves",
    "Pol. convention attendees",
    "Singers Shannon and Reeves",
    "Country singer Reeves et al."
  ],
  "owoe": ["Shakespearean lament", "Shakespearean lament"],
  "juju": ["Tribal symbol of luck", "Amulet"],
  "elee": [
    "Part of a C.S.A. signature",
    "Gen. Robert ___",
    "Gen. Robert ___",
    "Gen. Robert ___",
    "Gen. Robt. ___",
    "Part of a C.S.A. signature",
    "Gen. Robt. ___",
    "Mrs. Robert ___ (Mary Custis)",
    "Gen. Robert ___",
    "Gen. Robert ___",
    "\"Waiting for the Robert ___\"",
    "Robt. ___",
    "Gen. Robt. ___",
    "Part of a noted reb's signature",
    "Gen. Robt. ___",
    "General Robt. ___",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Gen. Robert ___",
    "Part of a rebel name",
    "Gen. Robert ___",
    "Robert ___ Prewitt (\"From Here to Eternity\" soldier)",
    "Part of a C.S.A. signature",
    "\"Waiting for the Robert ___\"",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Robert ___ Prewitt (\"From Here to Eternity\" soldier)",
    "Robt. ___",
    "Robt. ___",
    "\"Waiting for the Robert ___\"",
    "Robt. ___",
    "Gen. Robert ___",
    "Robt. ___",
    "Part of a C.S.A. signature",
    "Virginia's Robert ___",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Part of a C.S.A. signature",
    "Gen. Robert ___",
    "Part of a rebel name",
    "\"Waiting for the Robert ___\"",
    "\"Waiting for the Robert ___\"",
    "Part of a C.S.A. signature",
    "Robt. ___",
    "Robert ___",
    "Gen. Robt. ___",
    "Robt. ___",
    "Completion of Robt.'s signature",
    "Robt. ___",
    "Robert ___",
    "Part of a C.S.A. signature",
    "Gen. Robt. ___",
    "\"Waiting for the Robert ___\"",
    "Gen. Robt. ___",
    "C.S.A.'s Robert___",
    "Gen. Robt.___",
    "Confederate Robert___",
    "\"Waiting for the Robert___\"",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Reb Robt. ___",
    "Gen. Robt. ___",
    "Robt. ___",
    "Gen. Robt. ___",
    "Gen. Robt. ___",
    "Riverboat Robert ___",
    "Robt. ___",
    "Part of a Civil War signature",
    "Robt. ___",
    "See 124 Across",
    "Robt. ___",
    "\"Waiting for the Robert ___\"",
    "Robert of the C.S.A.",
    "\"Waiting for the Robert ___\""
  ],
  "ener": [
    "Cabinet dept. since 1977",
    "D.O.E. part: Abbr.",
    "Cabinet dept.",
    "Cabinet dept."
  ],
  "ghia": [
    "Karmann ___, classic German sports car",
    "Carmen ___ (\"The Producers\" role)",
    "Karmann ___ (old Volkswagen)",
    "Karmann ___ (car)",
    "Karmann ___ (old Volkswagen)"
  ],
  "segs": ["Line parts: Abbr.", "Line parts: Abbr."],
  "novo": [
    "Porto-___, Benin",
    "Porto-___ (capital of Benin)",
    "Porto-___ (capital of Benin)",
    "De ___ (anew)",
    "De ___ (from the start)",
    "De ___ (again)",
    "De ___ (from the start)",
    "De ___ (again)",
    "De ___ (from the beginning)",
    "Porto-___ (Benin's capital)"
  ],
  "dciv": [
    "604, in old Rome",
    "Year St. Augustine of Canterbury died",
    "Early seventh-century year"
  ],
  "dcci": [
    "701, once",
    "Year the Chinese poet Li Po was born",
    "First year of John VI's papacy",
    "When John VI's papacy began"
  ],
  "keir": [
    "Actor Dullea",
    "Dullea of \"2001: A Space Odyssey\"",
    "Actor Dullea",
    "Actor Dullea"
  ],
  "aris": [
    "\"Exodus\" hero and others",
    "Fleischer and others",
    "Greek war god, to Greeks",
    "Fleischer and Onassis",
    "Onassis and others",
    "Actress Meyers and others",
    "Actress Meyers and others",
    "Diminutive Greek names",
    "Onassis and others",
    "Onassis and others"
  ],
  "wabe": [
    "\"Did gyre and gimble in the ___\": \"Jabberwocky\"",
    "*Grass plot around a sundial",
    "\"...gimble in the ___\": Carroll",
    "\"Jabberwocky\" word"
  ],
  "soie": [
    "Mousseline de ___ (fabric)",
    "French silk",
    "Somme silk",
    "Silk: Fr.",
    "Peau de ___ (soft fabric)",
    "French silk",
    "French silk",
    "Fine French fabric",
    "Peau de ___ (soft cloth)",
    "Parisian silk",
    "French silk",
    "Silk, in Strasbourg",
    "French silk",
    "Fabric for Francoise"
  ],
  "flic": ["French cop", "Cannes cop", "Caen cop"],
  "tpke": [
    "No place for a free ride: Abbr.",
    "N.J. and Pa. each have a famous one",
    "I-90 in Mass., e.g.",
    "It takes a toll: Abbr.",
    "Plaza-to-plaza stretch: Abbr.",
    "N.J. or Pa. route",
    "Something that takes its toll?: Abbr.",
    "Hwy. with tolls",
    "Highlighted rte. on a map"
  ],
  "ambs": [
    "U.N. V.I.P.'s",
    "State reps., of sorts",
    "Many former senators and governors: Abbr.",
    "Appointees confirmed by Cong.",
    "Appointments approved by the Sen."
  ],
  "idbe": [
    "\"___ much obliged\"",
    "\"___ delighted!\"",
    "\"___ a fool to ...\"",
    "\"___ delighted!\"",
    "\"Why, ___ delighted!\""
  ],
  "upmy": [
    "\"Nothing ___ sleeve\"",
    "\"Nothing ___ sleeve\"",
    "\"This is right ___ alley\""
  ],
  "iowe": [
    "Part of i.o.u.",
    "\"___ you one\"",
    "\"___ you one!\"",
    "\"To you, Antonio, ___ the most\": Shak.",
    "\"___ you one!\"",
    "\"___ Russia $1200\" (Bob Hope book)",
    "\"___ Russia $1200\" (Bob Hope book)",
    "\"___ you one!\"",
    "Bob Hope's \"___ Russia $1,200\""
  ],
  "thes": ["Alternative to cafes", "Either of two N.T. books"],
  "itas": [
    "\"Take ___ a sign\"",
    "\"Think of ___ ...\"",
    "\"Take ___ a sign\"",
    "\"Take ___ a sign\"",
    "\"But I must also feel ___ a man\": Macduff",
    "\"Take ___ a sign\"",
    "\"Take ___ a sign\"",
    "\"Take ___ a compliment\"",
    "\"Play ___ it lays\""
  ],
  "nahs": [
    "Informal rejections",
    "Casual denials",
    "Informal turndowns",
    "Slangy turndowns",
    "Casual denials",
    "Casual denials",
    "Slangy rejections",
    "Casual turndowns",
    "Slangy dissents",
    "Slangy dissents",
    "Slangy denials",
    "Nopes",
    "Yups' opposites"
  ],
  "mies": [
    "___ van der Rohe",
    "Ludwig ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect Ludwig ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect Ludwig ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect ___ van der Rohe",
    "Architect van der Rohe",
    "___ van der Rohe"
  ],
  "kudo": ["Bit of praise, in modern usage", "Singular praise?"],
  "haec": [
    "Hic, ___, hoc",
    "Caesar's \"these\"",
    "Feminine \"this,\" to Brutus",
    "Hic, ___, hoc",
    "Caesar's \"these\"",
    "Middle of a Latin trio",
    "This one, to Ovid"
  ],
  "corm": [
    "Bulblike plant part",
    "Bulbous plant part",
    "Base of a crocus stem",
    "Base of a crocus stem",
    "Crocus bulb"
  ],
  "mmvi": [
    "Year of Super Bowl XL",
    "100th anniversary of the San Francisco earthquake"
  ],
  "pont": [
    "Way to l'Ile de la Cite",
    "___ Neuf (Parisian landmark)",
    "France's ___ d'Avignon",
    "Bridge, in Bordeaux",
    "Bridge, in Bretagne",
    "___-Aven (home of Paul Gauguin)",
    "Bridge, in Bretagne",
    "Brest bridge",
    "French bridge",
    "___ l'Eveque cheese",
    "___ l'Eveque (French cheese)"
  ],
  "kips": [
    "1,000-pound weight units",
    "___ Bay (Manhattan area)",
    "Goes to bed, in Britspeak",
    "Laotian dollars"
  ],
  "neue": [
    "New York City's ___ Galerie",
    "Das ___ Testament",
    "___ Galerie, art museum on Manhattan's Fifth Avenue",
    "___ Zurcher Zeitung (leading Swiss daily)",
    "Modern, in Munster",
    "___ Galerie (Manhattan art museum)",
    "Modern, to Mahler",
    "Opposite of alte"
  ],
  "aand": ["___ E (TV channel)", "Texas ___ M", "Texas ___ M"],
  "vero": [
    "___ Beach, Fla.",
    "Florida's ___ Beach",
    "___ Beach, Fla.",
    "___ Beach (former home of Dodgertown)",
    "___ Beach, Fla.",
    "___ Beach, Fla.",
    "Florida's ___ Beach",
    "Florida's ___ Beach",
    "___ Beach, Fla.",
    "___ Beach, Fla.",
    "___ Beach, Fla.",
    "___ Beach, Fla.",
    "___Beach, Fla.",
    "___ Beach, Fla.",
    "___ Beach, Fla.",
    "Florida's ___ Beach",
    "___Beach, Fla."
  ],
  "pome": [
    "Pear or quince",
    "Apple, e.g.",
    "Apple or quince",
    "Apple, e.g.",
    "Apple or pear",
    "Quince, for one",
    "Apple, to a botanist",
    "Apple or quince",
    "Fleshy fruit",
    "Quince, e.g.",
    "Fleshy fruit",
    "Apple or pear",
    "Fleshy fruit",
    "Fleshy fruit"
  ],
  "hawg": ["Big bass, in fishing lingo"],
  "issy": [
    "Paris suburb on the Seine",
    "___-les-Moulineaux (Paris suburb)",
    "Suburb of Paris",
    "Paris suburb on the Seine"
  ],
  "mony": [
    "When doubled, a Billy Idol #1 hit",
    "With 70-Across, #1 hit for Billy Idol",
    "When doubled, a #3 hit of 1968 or a #1 hit of 1987",
    "When repeated, a Billy Idol hit",
    "When repeated, a top five hit of 1968 or 1987",
    "When repeated, a 1968 pop hit"
  ],
  "tela": ["Anatomical tissue", "Anatomical tissue"],
  "ikon": [
    "Religious figure: Var.",
    "Big name in copiers",
    "Venerated image: Var.",
    "Religious image: Var.",
    "Image: Var.",
    "Sacred image: Var.",
    "Religious image: Var.",
    "Devotional carving: Var.",
    "Religious image: Var.",
    "Sacred image: Var.",
    "Sacred image: Var."
  ],
  "agcy": [
    "Bureau, for short",
    "Part of 16-Across: Abbr.",
    "Govt. org.",
    "Bureau: Abbr.",
    "Arm of the U.N., e.g."
  ],
  "sask": [
    "Regina's locale: Abbr.",
    "Prince Albert's home: Abbr.",
    "Its cap. is Regina",
    "N. Dak. neighbor",
    "Can. province",
    "Mont. neighbor",
    "Province north of Mont.",
    "Neighbor of Alta.",
    "It's between Alta. and Man.",
    "Alta. neighbor"
  ],
  "kaon": [
    "Unstable subatomic particle",
    "Particle named for a letter of the alphabet"
  ],
  "agio": [
    "Foreign exchange fee",
    "Charge for currency exchange",
    "Currency exchange premium",
    "Currency exchange premium",
    "Exchange premium",
    "Money-changer's profit",
    "Currency transaction fee",
    "Exchange premium",
    "Money exchange fee",
    "Money-changer's cut",
    "Money-changer's profit",
    "Exchange premium",
    "Money-changer's profit",
    "Exchange premium",
    "Currency premium",
    "Foreign-exchange cost",
    "Exchange premium"
  ],
  "amof": [
    "\"I ___ the opinion ...\"",
    "\"I ___ the opinion ...\"",
    "\"I ___ the opinion ?à\"",
    "\"I ___ the opinion ?à\""
  ],
  "meon": [
    "\"Are you putting ___?\"",
    "\"Turnin ___\" (2009 Keri Hilson hit)",
    "\"You're putting ___!\""
  ],
  "hame": [
    "Part of a plowing harness",
    "Harness part",
    "Harness part",
    "Harness part"
  ],
  "wans": ["Pales", "Grows pale", "Becomes pallid"],
  "tuft": [
    "Clump of hair",
    "Clump of grass",
    "Bit of hair standing up",
    "Clump of grass",
    "Cowlick, e.g.",
    "Goatee, e.g.",
    "Clump of hair",
    "Feature of many a bird",
    "Bunch of hairs",
    "Feather cluster"
  ],
  "ropo": ["El ___ (cheap cigar, slangily)", "El ___ (cheap cigar, in slang)"],
  "asci": [
    "Sacs studied by 58-Across",
    "Fungal spore cases",
    "Fungal spore sacs",
    "Spore cases",
    "Fungal spore sacs",
    "Spore sacs"
  ],
  "giga": [
    "Prefix with watt",
    "Prefix with flop",
    "Memory unit prefix",
    "Prefix with byte",
    "Billion: Prefix",
    "Prefix denoting 10 to the ninth power",
    "Bigger than mega-",
    "Prefix with byte",
    "Prefix with byte",
    "Prefix with byte",
    "Prefix with byte",
    "Billion: Prefix"
  ],
  "muck": ["Flood residue", "Gooey dirt", "Mire", "Gunk", "Filth"],
  "ying": ["Rap's ___ Yang Twins", "Soprano ___ Huang"],
  "duhs": [
    "\"Obviously!\" remarks",
    "Interjections from the obtuse",
    "Stupidity syllables",
    "Cries from the momentarily stupid",
    "Dim responses",
    "Popular rejoinders",
    "Sure signs of density",
    "Simps' syllables",
    "Silly syllables",
    "Dummies' replies"
  ],
  "shan": [
    "Young-adult fiction author Darren",
    "Central Asia's Tien ___ Mountains",
    "Tien ___ mountains in central Asia"
  ],
  "nowa": [
    "\"And ___ word from our sponsor\"",
    "\"And ___ word from our sponsor\""
  ],
  "mcli": [
    "Mid 12th-century year",
    "Mid 12th-century year",
    "Mid 12th-century date",
    "Mid 12th-century date"
  ],
  "tero": [
    "The \"T\" of Mr. T",
    "Mr. T's real last name",
    "Mr. T's family name",
    "Mr. T's last name",
    "Mr. T's last name",
    "Mr. T's real name"
  ],
  "cods": [
    "Haddock relatives",
    "Atlantic swimmers",
    "Relatives of pollocks",
    "New England catch",
    "Relatives of pollacks",
    "Atlantic swimmers"
  ],
  "mmii": [
    "Year the Angels won the World Series",
    "When the Salt Lake City Olympics took place",
    "Year the Department of Homeland Security was created",
    "Year of the Utah Winter Olympics",
    "See 4-Across",
    "Early third millennium date"
  ],
  "inan": [
    "___ instant",
    "___ instant",
    "___ instant",
    "___ awkward position",
    "___ uproar",
    "___ instant",
    "\"Back ___ hour\"",
    "___ awful way",
    "___ instant",
    "___ instant",
    "Words with instant or uproar",
    "___ uproar",
    "Put ___ appearance",
    "\"___ emergency ...\"",
    "___ uproar",
    "___ uproar",
    "Aerosmith's \"Love ___ Elevator\"",
    "___ uproar",
    "\"Back ___ hour\" (shop sign)",
    "___ instant (quickly)",
    "___ instant (quickly)",
    "___ uproar",
    "___ instant (quickly)",
    "___ uproar",
    "___ instant",
    "___ instant (at once)",
    "___instant",
    "___ uproar",
    "Words before instant or uproar"
  ],
  "norw": [
    "Oslo's home: Abbr.",
    "Land on the Arctic Cir.",
    "Major Eur. oil exporter",
    "Neighbor of Swed.",
    "Swed. butter?",
    "Neighbor of Swed.",
    "Swed. neighbor",
    "Neighbor of Swed."
  ],
  "beof": ["\"___ good cheer!\"", "\"___ good cheer\"", "\"___ good cheer!\""],
  "urim": [
    "___ and Thummim (sacred Judaic objects)",
    "___ and Thummin (Judaic objects)",
    "___ and Thummim (sacred Judaic articles)"
  ],
  "mene": [
    "Word on a biblical wall",
    "Biblical word on a wall",
    "Word repeated before \"tekel\" in biblical writing on the wall",
    "Word on a biblical wall",
    "Word on a biblical wall",
    "Mysterious word repeated in Daniel 5:25",
    "Bit of biblical graffiti",
    "Word on a wall, in the Bible",
    "Word on a biblical wall",
    "Literally, \"numbered\"",
    "Word on the wall, in Daniel",
    "Word interpreted by Daniel",
    "Word on a wall, in the Bible",
    "Literally, \"numbered\"",
    "Word on a wall",
    "Bit of biblical writing",
    "Writing on the biblical wall",
    "Biblical writing on the wall"
  ],
  "roks": [
    "Seoul soldiers",
    "Some Asian fighters",
    "Korean soldiers",
    "Some Asian enlistees"
  ],
  "thex": ["TV's \"___ Factor\"", "\"___ Factor\" (TV talent show)"],
  "baff": ["Strike the ground in a golf swing", "Bad golf shot"],
  "asok": [
    "\"Dilbert\" intern",
    "Dilbert co-worker",
    "\"Dilbert\" character who was reincarnated as his own clone",
    "Co-worker of Dilbert",
    "\"Dilbert\" intern",
    "\"Dilbert\" intern"
  ],
  "sadd": [
    "Travel safety grp.",
    "High-school org.",
    "High school org.",
    "D.W.I. opposers"
  ],
  "pago": [
    "When repeated, a Polynesian capital",
    "Vino de ___ (Spanish wine designation)",
    "When doubled, a Pacific capital",
    "Polynesian port, when doubled",
    "When doubled, a Samoan port",
    "When repeated, capital city of 11,000",
    "When repeated, a Samoan city",
    "Guam's ___ Bay",
    "When doubled, a Pacific capital",
    "Doubled, a Polynesian capital",
    "When doubled, a Pacific capital",
    "When repeated, a Samoan city",
    "When repeated, a Samoan port",
    "When repeated, a Samoan port",
    "Samoan port, if repeated",
    "Half of a Samoan port",
    "When doubled, a Samoan port"
  ],
  "ajig": ["Doing ___ (dancing springily)"],
  "quin": [
    "Christie's \"The Mysterious Mr. ___\"",
    "One of a fivesome, for short"
  ],
  "bige": ["Former 6'9\" N.B.A.'er Hayes, to fans"],
  "swed": [
    "Nor. neighbor",
    "By area, the third-largest country in the E.U.",
    "Scand. language",
    "See 92-Across: Abbr."
  ],
  "dict": [
    "Place to find wds.",
    "Webster's ref.",
    "Webster's, e.g.: Abbr.",
    "Wordsmith's ref.",
    "Webster's, e.g.: Abbr.",
    "Crossworder's crutch: Abbr.",
    "Ref. book",
    "Webster's, e.g.: Abbr.",
    "Ref. work"
  ],
  "wyes": [
    "Followers of exes",
    "Some branched pipes",
    "Pygmy couple?",
    "Things that open and close yearly?",
    "Ex followers",
    "Forks",
    "Dowsing rods, essentially",
    "They follow exes",
    "Forks in the road",
    "Some electrical junctions"
  ],
  "gant": [
    "Family name in \"Look Homeward, Angel\"",
    "Eugene ___, hero of \"Look Homeward, Angel\"",
    "\"Look Homeward, Angel\" hero Eugene",
    "Left-fielder Ron"
  ],
  "pahs": ["Oom-___ (tuba sounds)", "Oom-___ (tuba sounds)"],
  "zoes": [
    "Actress Caldwell and others",
    "Stage actress Caldwell and others",
    "Writer Akins and others"
  ],
  "broz": [
    "Josip ___ Tito, Yugoslav statesman",
    "Tito's surname",
    "Yugoslavia's Josip ___ Tito",
    "Josip ___ Tito",
    "Tito's family name",
    "Tito's real name",
    "Last name in Communism",
    "Marshal Tito"
  ],
  "oooh": [
    "\"I'm intrigued!\"",
    "Squeal of delight",
    "\"So nice!\"",
    "Sound of delight",
    "\"Aren't you special!\"",
    "\"Color me impressed!\"",
    "Cry of delight",
    "... a circusgoer",
    "\"How exciting!\""
  ],
  "seco": [
    "Like vino de Rioja",
    "Like some Spanish wine",
    "Like some vino",
    "Dry, in Durango",
    "Like Rioja wine"
  ],
  "sabo": [
    "1988 N.L. Rookie of the Year Chris",
    "Chris ___, 1988 N.L. Rookie of the Year",
    "Former Cincinnati Red Chris"
  ],
  "sant": [
    "Director Gus Van ___",
    "Start of some Italian church names",
    "Filmmaker Gus Van ___",
    "Director Gus Van ___",
    "Director Gus Van ___",
    "Start of some Italian church names",
    "Film maker Gus Van ___",
    "Film director Gus Van ___"
  ],
  "naut": [
    "Like some flags: Abbr.",
    "Kind of mile: Abbr.",
    "Suffix with aqua",
    "Seagoing: Abbr.",
    "Kind of mile: Abbr.",
    "Maritime: Abbr.",
    "Kind of mile: Abbr.",
    "Suffix with aqua",
    "Seagoing: Abbr.",
    "Seagoing: Abbr.",
    "Ship-related: Abbr.",
    "Of ships: Abbr.",
    "Cosmo follower",
    "Argo ending"
  ],
  "heps": ["Drill bits?", "Ones, when marching"],
  "hank": [
    "Hammerin' ___",
    "Yarn quantity",
    "Yarn unit",
    "Baseball's Hammerin' ___",
    "Bit of hair",
    "Snow on an album cover",
    "Coil",
    "Snow in Nashville",
    "Unit of yarn",
    "Handful of hair",
    "Some yarn",
    "Length of yarn",
    "Handful",
    "Bit of yarn",
    "Bit of hair",
    "Length of yarn",
    "Amount of hair",
    "Yarn measure"
  ],
  "upas": [
    "Dress ___ (impersonate)",
    "Dress ___ (look like)",
    "Dress ___ (resemble)"
  ],
  "awee": [
    "Bide-___",
    "___ bit",
    "Bide-___",
    "Bide-___",
    "___ bit (slightly)",
    "Bide-___",
    "___ bit (slightly)",
    "Bide-___",
    "Bide-___",
    "Bide-___",
    "\"Just ___ bit\"",
    "Bide ___ (stay a bit): Scot."
  ],
  "leta": [
    "\"___ thousand flowers bloom\"",
    "\"___ thousand flowers bloom\"",
    "\"___ joy keep you\": Sandburg",
    "\"___ joy keep you\" (start of a Sandburg poem)",
    "\"I ___ Song Go Out of My Heart\"",
    "\"I___Song Go...\"",
    "\"___ smile be...\"",
    "\"___ smile be...\""
  ],
  "hara": [
    "___-kiri",
    "___-kiri",
    "___-kiri",
    "___-kiri",
    "___-kiri",
    "___-kiri",
    "___-kiri",
    "___-kiri"
  ],
  "ivar": [
    "Financier Kreuger called the Match King",
    "Swedish monopolist Kreuger",
    "___ Kreuger, the Match King",
    "Crazy ___ (\"O Pioneers!\" hermit)",
    "Match king Kreuger",
    "Basso Andresen"
  ],
  "daps": [
    "Fist-bumps",
    "Skips on water",
    "Drops bait",
    "Fishes by dangling the bait on the water"
  ],
  "iddo": [
    "\"___ Anything\" (\"Oliver!\" song)",
    "\"... and ___ it again!\"",
    "\"... and ___ it again!\"",
    "\"___ Anything\" (\"Oliver!\" song)"
  ],
  "kert": [
    "Larry of the original \"West Side Story\"",
    "Larry who played Tony in \"West Side Story\""
  ],
  "ctns": [
    "U.P.S. deliveries: Abbr.",
    "They may hold many pks.",
    "Milk holders: Abbr.",
    "Stack on a pallet: Abbr.",
    "Cig. purchases",
    "Egg holders: Abbr.",
    "Cig. boxes",
    "Cigarette pkgs.",
    "Warehouse array: Abbr.",
    "Boxes: Abbr.",
    "Egg holders: Abbr.",
    "Warehouse supply: Abbr."
  ],
  "bole": [
    "Woody trunk",
    "Tree trunk",
    "Tree trunk",
    "Tree trunk",
    "Tree trunk"
  ],
  "dbls": [
    "Some substantial hits: Abbr.",
    "Two-base hits: Abbr.",
    "Two-baggers: Abbr.",
    "Two-base hits: Abbr.",
    "Some baseball hits: Abbr.",
    "Some hits: Abbr.",
    "R.B.I. producers, often"
  ],
  "anim": ["Lively, on scores: Abbr.", "Lively, in mus.", "Music-score abbr."],
  "wacs": [
    "W.W. II group",
    "W.W. II group",
    "\"My best soldiers,\" according to Douglas MacArthur",
    "1954 war comedy \"Francis Joins the ___\"",
    "W.W. II noncombatants",
    "W.W. II group",
    "W.W. II servers",
    "Armed forces females",
    "Some W.W. II service personnel",
    "G.I. Janes"
  ],
  "bawd": [
    "Madam",
    "Madam",
    "Madam",
    "Madam",
    "House runner",
    "House keeper?",
    "Madam",
    "Madam",
    "Shady lady",
    "Madam",
    "Floozy"
  ],
  "mina": [
    "Jonathan's wife in \"Dracula\"",
    "\"Dracula\" heroine Harker",
    "___ Harker, wife in Bram Stoker's \"Dracula\"",
    "___ Harker, heroine of \"Bram Stoker's Dracula\"",
    "\"Dracula\" girl",
    "\"Dracula\" miss"
  ],
  "murk": [
    "It's difficult to see through",
    "Visibility reducer",
    "Gloom",
    "Gloom",
    "Darkness",
    "Darkness",
    "Darkness"
  ],
  "acow": [
    "Had ___ (flipped)",
    "\"Don't have ___, man!\"",
    "Have ___",
    "\"Don't have ___, man!\"",
    "Have ___ (lose it)",
    "\"___ on the Line\" (Thomas the Tank Engine story)",
    "Have ___ (lose it)",
    "\"Don't have ___!\"",
    "\"Don't have ___, man!\"",
    "\"Don't have ___!\"",
    "\"Don't have ___, man!\"",
    "Have ___ (freak out)",
    "Have ___ (overreact)",
    "As nimble as ___ in a cage (old saying)",
    "Have ___ (freak out)",
    "Have ___ (flip out)"
  ],
  "mohr": [
    "Actor Jay of \"Jerry Maguire\"",
    "Actor Jay",
    "Jay who once hosted \"Last Comic Standing\"",
    "Comedian Jay",
    "\"Jerry Maguire\" actor Jay",
    "\"Silent Night\" writer Joseph"
  ],
  "sati": [
    "Old coll. entrance hurdle",
    "H.S. senior's exam, once",
    "Coll. entrance hurdle, once",
    "Coll. entrance hurdle",
    "Exam with a perfect score of 2400"
  ],
  "teno": [
    "___'clock scholar",
    "Sinew: Prefix",
    "___'clock",
    "\"___'clock scholar\"",
    "\"A ___'clock scholar\"",
    "\"...___'clock scholar\"",
    "\"A ___' clock scholar\"",
    "\"A ___'clock scholar\""
  ],
  "etcs": [
    "List-ending abbrs.",
    "Series conclusions: Abbr.",
    "Truncation indications: Abbr.",
    "Series finales?: Abbr.",
    "Customary extras: Abbr."
  ],
  "cien": ["Hernando's hundred", "Hundred, in Juarez", "Diez x diez"],
  "asad": [
    "\"Like ___ Song\" (John Denver hit)",
    "\"It's ___ state of affairs\"",
    "\"___ Child\" (Margaret Atwood poem)",
    "\"What ___ state of affairs!\"",
    "\"___ tale's best for winter\": Shak.",
    "\"That is ___ commentary ?à\""
  ],
  "feng": [
    "Wind, in Chinese",
    "___ shui",
    "___ shui",
    "___ shui",
    "___ shui",
    "Chinese for \"wind\""
  ],
  "jann": ["Rolling Stone co-founder Wenner"],
  "hten": ["Coordinate in the game Battleship"],
  "comm": ["Ways and Means, e.g.: Abbr.", "Part of S.E.C.: Abbr."],
  "mcii": [
    "Early 12th-century year",
    "Early 12th-century year",
    "Early 12th-century year",
    "Early 12th-century date",
    "Roman 1,102",
    "Early 12th-century date",
    "1102 on a monument",
    "1102, in dates",
    "Year in Henry I's reign"
  ],
  "brac": [
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___",
    "Bric-a-___"
  ],
  "geom": [
    "Math subj. with proofs",
    "Math. class",
    "Study of figures: Abbr.",
    "Math subj. with angles",
    "Points are discussed in it: Abbr.",
    "Euclidean subj.",
    "Euclid's element?: Abbr.",
    "Subj. with circles and such",
    "H.S. class"
  ],
  "baud": [
    "Dial-up unit",
    "Modem speed unit",
    "Modem speed measure",
    "Data transmission rate",
    "Transfer rate",
    "Unit of speed",
    "Data transfer speed unit",
    "Unit of data transmission"
  ],
  "pule": [
    "Whine",
    "Act the crybaby",
    "Whine",
    "Whine",
    "Whimper",
    "Whine",
    "Whimper",
    "Be a crybaby",
    "Whimper"
  ],
  "lita": [
    "Grammy-nominated Ford",
    "Singer Ford",
    "Self-titled platinum album of 1988",
    "Ex-Runaways guitarist Ford",
    "Rock guitarist Ford",
    "___ fire (started burning something)",
    "\"Close My Eyes Forever\" singer ___ Ford",
    "Grammy-winning Ford",
    "Rock music's Ford"
  ],
  "pias": [
    "Spinal cord surrounders",
    "Lindstrom and Zadora",
    "Zadora and Lindstrom"
  ],
  "bain": [
    "___ Capital",
    "___ Capital (investment firm)",
    "Barbara of \"Mission: Impossible\"",
    "Winner of three consecutive Emmys for \"Mission: Impossible\"",
    "Actress Barbara",
    "Barbara of \"Mission: Impossible\"",
    "\"Diff'rent Strokes\" star Conrad",
    "\"Mission: Impossible\" actress",
    "Hollywood's Barbara",
    "Hollywood's Barbara or Conrad"
  ],
  "sovs": [
    "Kings and queens: Abbr.",
    "Old Brit. coins",
    "King and queen: Abbr."
  ],
  "doos": [
    "Ski-___ (snowmobiles)",
    "Ski-___",
    "Ski-___",
    "Ski-___ (snowmobiles)",
    "Ski-___ (snowmobiles)"
  ],
  "naya": ["Evian competitor", "Fiji competitor", "Water brand", "Water brand"],
  "haft": [
    "Foil feature",
    "Sword part",
    "Foil part",
    "Knife handle",
    "Sword handle",
    "Sword handle",
    "Handle",
    "Sword handle",
    "5-Across handle",
    "Knife handle",
    "Knife handle",
    "Dagger handle",
    "Hatchet handle",
    "Sword handle",
    "Dagger handle",
    "Sword handle"
  ],
  "papp": [
    "Shakespeare in the Park founder/producer Joseph",
    "\"Hair\" producer Joseph",
    "Founder of New York's Public Theater",
    "\"The Pirates of Penzance\" producer, 1981",
    "Noted Shakespearean producer",
    "\"Hair\" producer, 1967",
    "\"Hair\" producer",
    "New York Shakespeare Festival founder",
    "New York Shakespeare Festival founder Joseph",
    "New York Shakespeare Festival founder",
    "Founder of New York's Public Theater",
    "Founder of New York's Public Theater"
  ],
  "asas": [
    "Old camera settings, for short",
    "Old camera settings, for short",
    "Gray and others",
    "Botanist Gray and others",
    "Politico Hutchinson and others",
    "Politico Hutchinson and others",
    "Botanist Gray and others",
    "Gray and others",
    "Gray and others"
  ],
  "asdf": [
    "Home row sequence",
    "Left hand's starting position, for a touch typist"
  ],
  "koto": [
    "One may be played by a geisha",
    "Japanese stringed instrument",
    "13-stringed Japanese zither",
    "Japanese stringed instrument",
    "Japanese zither",
    "Instrument with 13 strings"
  ],
  "duse": [
    "Italian actress Eleonora",
    "Italian actress Eleonora",
    "Old stage actress Eleanora",
    "Bernhardt contemporary of old theater",
    "Early actress Eleanora"
  ],
  "olen": [
    "\"The Tourist\" novelist Steinhauer",
    "Author Steinhauer with the 2009 best seller \"The Tourist\"",
    "Author Robert ___ Butler",
    "Pulitzer-winning author Robert ___ Butler",
    "Author Robert ___ Butler",
    "Author Robert ___ Butler",
    "Author Robert ___ Butler",
    "Author Robert ___ Butler"
  ],
  "ccli": ["Mid third-century year", "251, in old Rome", "Third-century year"],
  "orhe": ["\"What's Hecuba to him, ___ to Hecuba\": Hamlet"],
  "twoa": [
    "Old draft category for civilian workers",
    "Old deferment classification",
    "Old draft category",
    "Old draft deferment category for critical civilian work",
    "60's draft deferment category",
    "Occupational deferment category in the '60s draft"
  ],
  "imof": ["\"___ two minds\""],
  "cccl": ["Cicero's 350", "Mid-century year", "Multiple of XXXV"],
  "sids": [
    "Caesar and others",
    "Caesar and others",
    "Caesar and others",
    "Jazz drummer Catlett et al.",
    "Caesar and others",
    "Caesar and Vicious, e.g."
  ],
  "pvts": [
    "Sarge's charges: Abbr.",
    "Ones under sgts., in the Army",
    "E-1s and E-2s, in the Army: Abbr.",
    "A lot of mil. personnel",
    "Most G.I.'s: Abbr.",
    "U.S.M.C. recruits: Abbr."
  ],
  "iluv": ["\"___ It\" (2006 Young Jeezy single)"],
  "garn": [
    "Only U.S. senator with a unit of measure named after him",
    "First senator in space",
    "Senator Jake in space",
    "Senator aboard Discovery",
    "Senator in space, 1985"
  ],
  "nidi": [
    "Spiders' nests",
    "Insect nests",
    "Spiders' nests",
    "Insect nests",
    "Insect nests",
    "Spider nests"
  ],
  "cwts": [
    "1/20 tons: Abbr.",
    "Shipping units: Abbr.",
    "100-lb. units",
    "100-lb. units",
    "100-lb. units",
    "100-lb. units",
    "100-lb. units"
  ],
  "daae": [
    "Christine ___, heroine of \"The Phantom of the Opera\"",
    "Christine ___, \"The Phantom of the Opera\" girl",
    "\"The Phantom of the Opera\" heroine Christine ___",
    "Christine ___, \"The Phantom of the Opera\" heroine"
  ],
  "phys": [
    "___ ed. (gym class)",
    "___ Ed",
    "M.D.",
    "Sci. class",
    "___ Ed.",
    "M.D.",
    "A sci.",
    "M.D.",
    "___ ed.",
    "___ Ed. (H.S. course)",
    "___ ed."
  ],
  "jeff": [
    "Bezos who founded Amazon",
    "Actor Bridges of \"The Big Lebowski\"",
    "One of the acting Bridges",
    "Nascar's Gordon",
    "Comedian Foxworthy",
    "Mutt's buddy, in the comics",
    "Bridges in Hollywood",
    "Diminutive Bud Fisher cartoon character",
    "Auto racer Gordon",
    "Mutt's partner in old comics",
    "Bridges in movies",
    "Newsman Greenfield",
    "Bridges or Daniels"
  ],
  "brno": [
    "Largest city in Moravia",
    "Moravian capital",
    "Czech city",
    "Moravian capital",
    "Chief city of Moravia",
    "Capital of Moravia",
    "Capital of Moravia"
  ],
  "eure": [
    "French river or department",
    "River at Chartres",
    "French river or department",
    "Normandy department"
  ],
  "pret": [
    "___-a-porter",
    "Ready, in Rouen",
    "___-a-porter",
    "___-a-porter",
    "___ a manger (ready to eat, in France)",
    "___-a-porter",
    "___-a-porter (ready-to-wear)",
    "___-a-porter",
    "Ready, in Rouen",
    "___-a-porter (ready-to-wear)",
    "___-a-porter (ready-to-wear)",
    "Ready: Fr.",
    "___-a-porter",
    "___-a-porter (ready-to-wear)"
  ],
  "voir": [
    "___ dire (court examination)",
    "___ dire (jury selection process)",
    "___ dire",
    "See, in Somme",
    "___ dire",
    "___ dire (legal process)",
    "To see, in Marseille",
    "___dire (court procedure)",
    "___dire (court oath)",
    "___ dire (jurors' examination)"
  ],
  "hoch": [
    "German Dadaist Hannah",
    "Mystery writer Edward D. ___",
    "High, in Heidelberg",
    "Scott of the P.G.A.",
    "High: Ger."
  ],
  "cero": ["Large sport fish", "Dos minus dos", "Mackerel-like fish"],
  "cyls": ["Engine specification: Abbr.", "V-8's eight: Abbr."],
  "gota": [
    "\"___ light?\"",
    "#1 Ray Charles R&B hit \"I've ___ Woman\"",
    "\"___ match?\"",
    "\"___match?\""
  ],
  "nins": ["Marxist Andres and writer Anais"],
  "dodi": [
    "___ Fayed, last romantic partner of Princess Diana",
    "Film producer Fayed",
    "___ al-Fayed (friend of Diana)",
    "___ al-Fayed, companion of Princess Diana",
    "Film producer ___ Al-Fayed",
    "___ al-Fayed, friend of Princess Diana",
    "___ al-Fayed (friend of Diana)",
    "\"Hook\" producer Fayed",
    "Late film producer Fayed"
  ],
  "dccc": ["800, in old Rome", "800, to Caesar"],
  "bito": [
    "___-Honey",
    "___-Honey (candy name)",
    "___-Honey (candy bar)",
    "___-Honey (candy bar)"
  ],
  "ucal": [
    "Berkeley campus, for short",
    "The Golden Bears, briefly",
    "It has 10 branches: Abbr.",
    "Golden State sch.",
    "West Coast sch.",
    "Sch. with a branch in Berkeley",
    "Sch. with a branch in Irvine",
    "The Golden Bears, for short"
  ],
  "elah": [
    "\"In the Valley of ___\" (2007 film)",
    "\"In the Valley of ___\" (2007 film)"
  ],
  "dood": [
    "\"I ___ it!\" (Skelton catchphrase)",
    "\"I ___ it!\": Red Skelton",
    "\"I ___ it!\" (cry of success)"
  ],
  "essu": [
    "\"Mi casa ___ casa\"",
    "\"Mi casa ___ casa\"",
    "\"Mi casa ___ casa\""
  ],
  "toit": [
    "\"There's nothing ___!\"",
    "\"Hop ___!\"",
    "\"Hop ___!\"",
    "\"Hop ___!\"",
    "\"Nothing___\"",
    "Words after \"hop\" or \"get around\""
  ],
  "arps": [
    "Some Dadaist pieces",
    "MoMA's \"Two Heads\" and \"Birds in an Aquarium\"",
    "Some MoMA works",
    "Some Dadaist works",
    "The lithograph \"Mustache Hat\" and others",
    "The sculptures \"Cloud Shepherd\" and \"Coquille Crystals\"",
    "Some Dada prints",
    "Some Guggenheim works",
    "Some Dada works",
    "Some Dadaist works",
    "Certain Dadaist works",
    "Some Dadaist collectibles",
    "Surrealist Jean and others",
    "Dadaist collection",
    "Some Dadaist works",
    "Works by sculptor Hans",
    "Dadaist pieces",
    "Some Dada works"
  ],
  "funt": [
    "Allen of \"Candid Camera\"",
    "Old TV host Allen",
    "\"Candid Camera\" creator",
    "\"Candid Camera\" man",
    "Allen of \"Candid Camera\"",
    "Candid Allen",
    "\"Candid Camera\" host",
    "Allen of \"Candid Camera\"",
    "Candid cameraman"
  ],
  "asir": [
    "Province of Saudi Arabia",
    "Saudi Arabian province",
    "Province of Saudi Arabia",
    "Province of Saudi Arabia",
    "Region of Saudi Arabia",
    "District in Arabia"
  ],
  "kwan": [
    "Olympic skater Michelle",
    "Skater Michelle",
    "Skater Michelle",
    "Olympian Michelle",
    "Skating champ Michelle",
    "Skater Michelle",
    "Actress Nancy",
    "Actress Nancy from Hong Kong"
  ],
  "daws": [
    "Crow relatives",
    "Crows' kin",
    "Glossy black birds",
    "Crow cousins",
    "Crow cousins",
    "Black birds",
    "Chattering birds"
  ],
  "diam": [
    "Circle measure: Abbr.",
    "Geometry meas.",
    "Circle meas.",
    "Geom. measure",
    "Circular meas.",
    "Geom. line",
    "Circle meas.",
    "Cir. bisector"
  ],
  "zaza": [
    "Drag queen in \"La Cage aux Folles\"",
    "Mistress in a Thomas Mann novel"
  ],
  "gmaj": ["Setting of \"Love Me Do\": Abbr."],
  "he's": ["\"___ a Rebel\""],
  "flos": [
    "Mrs. Capp and others",
    "Ziegfeld and others",
    "Andy Capp's wife and others",
    "Showman Ziegfeld and others"
  ],
  "edds": ["Teaching degs.", "Byrnes and Hall", "TV's Byrnes and Hall"],
  "ihre": ["German possessive pronoun", "Her: Ger.", "Their, in Munich"],
  "abri": [
    "Shelter dug into a hillside",
    "Shelter-providing dugout",
    "Dugout shelter",
    "Shelter dug into a hillside",
    "Dugout shelter",
    "Hillside shelter",
    "Archeological shelter",
    "Rock shelter",
    "Dugout",
    "Shelter: Fr."
  ],
  "isof": [
    "\"Time ___ the essence\"",
    "\"It ___ no concern\"",
    "\"If this ___ any help ...\"",
    "\"Time ___ the essence\"",
    "\"Time ___ the essence\"",
    "\"Time ___ the essence\"",
    "\"Time ___ the essence\""
  ],
  "adim": [
    "Take ___ view of",
    "Take ___ view of",
    "Take ___ view of",
    "Take ___ view of",
    "Take___view of"
  ],
  "honi": [
    "Daughter of Hagar the Horrible",
    "Hagar's daughter in the comics",
    "\"___ soit qui mal y pense\" (old motto)",
    "___ soit qui mal y pense",
    "Start of the Order of the Garter's motto",
    "Dik Browne cartoon girl",
    "Hagar the Horrible's daughter",
    "___ soit qui mal y pense (old motto)",
    "___ soit qui mal y pense",
    "___ soit qui mal y pense",
    "___ soit qui mal y pense (classic motto)",
    "\"___ soit qui mal y pense\"",
    "\"___ soit qui mal y pense\"",
    "Daughter of Hagar the Horrible",
    "\"___ soit qui mal y pense\"",
    "Hagar's daughter, in the comics"
  ],
  "kwon": [
    "Tae ___ do",
    "Tae ___ do",
    "Tae ___ do",
    "\"Lost\" character Jin-Soo ___",
    "Tae ___ do"
  ],
  "llbs": [
    "Degrees for foreign attys.",
    "Foreign attys.' degrees",
    "Foreign attorneys' degs.",
    "Law degrees",
    "Lawyers' degrees"
  ],
  "seve": [
    "Golf's Ballesteros",
    "___ Trophy (golf tourney)",
    "Golfer Ballesteros",
    "Ballesteros of golf",
    "Golf's Ballesteros",
    "Golf great Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golf's Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golf's Ballesteros",
    "Golfer Ballesteros",
    "Golf's Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "P.G.A. Masters champion Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golf champ Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros",
    "Golfer Ballesteros"
  ],
  "dono": [
    "\"First, ___ harm\"",
    "\"First, ___ harm\" (medical axiom)",
    "\"___ harm\" (medical maxim)",
    "___harm to (leave be)"
  ],
  "oneo": [
    "Cat or clock preceder",
    "With 62-Down, old ball game",
    "Beginning of time?",
    "\"___'Clock Jump\" (Basie hit)",
    "___ cat",
    "___ cat",
    "Clock starter?",
    "___ cat",
    "\"___'Clock Jump\" (1930's hit)",
    "\"___'Clock Jump\" (1937 hit)",
    "Clock or cat preceder",
    "\"___'Clock Jump\"",
    "Basie's \"___ 'Clock Jump\""
  ],
  "nlat": ["GPS above-the-Equator fig.", "About 40 degrees, for N.Y.C."],
  "orrs": [
    "Harriet Beecher Stowe's \"The Pearl of ___ Island\"",
    "\"The Pearl of ___ Island\" (Harriet Beecher Stowe novel)",
    "Ice legend's family",
    "Bobby and others",
    "\"The Pearl of ___ Island\" (Stowe novel)",
    "Bobby and others",
    "Defenseman Bobby et al.",
    "Hockey's Bobby et al.",
    "Hockey's Bobby et al.",
    "Hockey's Bobby et al."
  ],
  "jong": [
    "\"Fear of Flying\" author",
    "Erica who wrote \"Fear of Flying\"",
    "Erica who wrote \"Any Woman's Blues\"",
    "\"Fanny\" novelist"
  ],
  "mccl": [
    "Mid 13th-century year",
    "Quarter-millennium year",
    "Year the emperor Frederick II died",
    "Year the emperor Frederick II died",
    "1250, in old Rome"
  ],
  "dsos": [
    "British mil. decorations",
    "U.K. mil. decorations",
    "U.K. decorations",
    "Brit. decorations",
    "U.K. decorations",
    "U.K. military medals",
    "U.K. decorations",
    "Brit. decorations",
    "Brit. decorations",
    "Medals for Tommy Atkins: Abbr.",
    "Brit. mil. awards"
  ],
  "arta": [
    "\"Thou ___ lady\": King Lear",
    "Greek gulf or city",
    "Greek city known to the ancients as Ambracia"
  ],
  "iole": [
    "Captive of Heracles",
    "Princess loved by Hercules",
    "Princess loved by Heracles",
    "One loved by Hercules",
    "Princess loved by Hercules",
    "Hercules fell in love with her",
    "Heracles' captive maiden",
    "Captive of Hercules",
    "Maiden loved by Hercules",
    "Captive of Hercules"
  ],
  "iand": ["Martin Buber's \"___ Thou\"", "\"___ my Annabel Lee\": Poe"],
  "ince": [
    "Thomas H. ___, the Father of the Western",
    "Director Thomas H. ___ of the silent era",
    "Early film director Thomas H. ___",
    "\"Civilization\" director Thomas"
  ],
  "olof": [
    "Former Swedish P.M. Palme",
    "Former Swedish P.M. Palme",
    "Former Swedish P.M. Palme",
    "Palme of Sweden",
    "Former Swedish P.M. Palme",
    "Former Swedish P.M. Palme",
    "With 93-Across, assassinated European leader"
  ],
  "usto": ["\"From all of ___ all of you ...\""],
  "lith": [
    "Latvia neighbor: Abbr.",
    "Neighbor of Lat.",
    "Neighbor of Poland: Abbr.",
    "Neighbor of Poland: Abbr.",
    "Currier and Ives product: Abbr.",
    "Baltic land: Abbr.",
    "Neighbor of Pol.",
    "Printing technique: Abbr.",
    "An S.S.R.",
    "Baltic country: Abbr.",
    "Neighbor of Pol.",
    "Art print: Abbr."
  ],
  "mech": [
    "Branch of engineering: Abbr.",
    "Kind of engr.",
    "Kind of engr.",
    "Kind of engr.",
    "Field for an engr.",
    "Kind of engr.",
    "Field for an engr."
  ],
  "acry": [
    "D. W. Griffith's \"___ for Help\"",
    "Streep's \"___ in the Dark\"",
    "Streep's \"___ in the Dark\"",
    "\"___ in the Dark\"",
    "\"___in the Dark\" (Streep film)"
  ],
  "ulla": [
    "\"The Producers\" sex kitten",
    "\"The Producers\" character who sings \"When You Got It, Flaunt It\"",
    "\"The Producers\" role"
  ],
  "ofus": [
    "\"Just the Two ___\"",
    "\"The Sum ___\" (Russell Crowe movie)",
    "\"The Story ___\" (Willis movie)"
  ],
  "cvii": [
    "Year in Trajan's reign",
    "Roman numeral that's an anagram of part of Caesar's boast",
    "Early second-century year"
  ],
  "mciv": [
    "Early 12th-century year",
    "Year that Acre fell in the First Crusade"
  ],
  "luft": ["Actress Lorna", "Air, in Augsburg", "Singer-actress Lorna"],
  "laff": [
    "Comic response, in Variety",
    "\"___-A-Lympics\" (old TV cartoon series)",
    "___ riot (very funny skit)",
    "___ riot (comedy review)",
    "Joke response, informally"
  ],
  "tods": [
    "Upscale Italian shoe brand",
    "High-end shoe and handbag maker",
    "Wool weights",
    "Bushy clumps"
  ],
  "sato": [
    "1974 Peace Nobelist",
    "1974 Japanese Nobelist",
    "Japanese Peace Nobelist of 1974",
    "Japanese leader of the 1960s",
    "Japanese who won the 1974 Nobel Peace Prize",
    "1974 Peace Nobelist",
    "1960's-70's Japanese leader",
    "1974 Peace Nobelist Eisaku ___",
    "1974 Peace Prize recipient",
    "Japan's P.M., 1964-72",
    "Former P.M. who shared a 1974 Nobel Peace Prize",
    "60's-70's Japanese P.M."
  ],
  "ohed": [
    "Expressed some surprise",
    "Was audibly surprised, maybe",
    "Expressed surprise",
    "Expressed surprise"
  ],
  "pneu": [
    "French tire",
    "French tire",
    "French tire",
    "French tire",
    "French tire",
    "French tire"
  ],
  "miry": [
    "Boglike",
    "Boglike",
    "Swampy",
    "Like a mudhole",
    "Boglike",
    "Like a bog",
    "Not solid",
    "Swamplike",
    "Swampish",
    "Boglike"
  ],
  "eben": [
    "Irving Bacheller's \"___ Holden\"",
    "\"___ Holden\" (Irving Bacheller novel)",
    "Irving Bacheller novel \"___ Holden\"",
    "Irving Bacheller's \"___ Holden\"",
    "\"___ Holden,\" Irving Bacheller novel",
    "Irving Bacheller's \"___ Holden\"",
    "Possible nickname for Scrooge",
    "Man's nickname that is German for \"level\"",
    "Bacheller's \"___ Holden\"",
    "?ôDesire Under the Elms?ö son",
    "Bacheller's \"___ Holden\""
  ],
  "iami": [
    "\"___ Said\" (Neil Diamond hit)",
    "\"___ Said\" (Neil Diamond hit)",
    "Neil Diamond's \"___ Said\"",
    "\"___ Said\" (Neil Diamond hit)",
    "\"___ Said\" (Neil Diamond hit)",
    "Neil Diamond's \"___ Said\"",
    "\"___ Said\" (Neil Diamond hit)",
    "\"___ Said\" (Neil Diamond hit)"
  ],
  "sark": [
    "Cutty ___ (clipper ship)",
    "Cutty ___ (clipper ship)",
    "One of the Channel Islands",
    "Name on some Scotch bottles",
    "Cutty ___",
    "One of the Channel Islands",
    "Cutty ___",
    "Cutty ___"
  ],
  "ques": [
    "\"Jeopardy!\" response: Abbr.",
    "Topic for debate: Abbr.",
    "Survey part: Abbr.",
    "Opposite of an ans.",
    "Who, what or where sentence: Abbr.",
    "Opposite of an ans.",
    "Opposite of an ans.",
    "Inquiry: Abbr."
  ],
  "sano": [
    "Not loco",
    "\"Mens sana in corpore ___\"",
    "Not loco",
    "Not loco",
    "Healthy: lt."
  ],
  "doby": ["Behave with respect to", "American League color line breaker"],
  "acat": [
    "\"... to skin ___\"",
    "\"Ev'rybody Wants to Be ___\" (Disney film tune)",
    "Nervous as ___",
    "Nervous as ___",
    "See 45-Down",
    "Not enough room to swing ___",
    "\"___ in gloves catches no mice\"",
    "Room to swing ___",
    "Lead ___-and-dog life (constantly quarrel)",
    "No room to swing ___",
    "Room to swing ___",
    "More than one way to skin ___",
    "Not enough room to swing ___",
    "It may look on a king",
    "\"___ may look on a king\"",
    "\"... way to skin ___\"",
    "No room to swing ___",
    "\"___ may look on a king\"",
    "\"... more than one way to skin ___\"",
    "One-___ (ball game)",
    "\"___ may look on a king\": Heywood"
  ],
  "olas": ["Spanish waves", "Spanish waves"],
  "ofst": ["Spirit ___ Louis", "\"The Bells ___ Mary's\""],
  "pnom": [
    "___-Penh",
    "___ Penh, Cambodia: Var.",
    "___ Penh: Var.",
    "___ Penh",
    "___-Penh",
    "___-Penh",
    "___ Penh, Cambodia",
    "___ Penh"
  ],
  "onde": ["French wave", "French wave", "French wave", "French wave"],
  "fict": ["Half of a best-seller list: Abbr.", "Book category: Abbr."],
  "ifhe": [
    "\"___ hollers, let ...\"",
    "\"___ can do it ...\"",
    "\"___ Walked Into My Life\" (\"Mame\" song)",
    "\"___ Should Ever Leave You\" (Tom Jones song)",
    "\"___ hollers, let ?à\"",
    "\"___ hollers...\"",
    "\"For what profit has a man,___...\": Matthew"
  ],
  "edas": ["Writer LeShan and others", "Writer LeShan and others"],
  "kats": [
    "Kit ___ (candy bars)",
    "Kit ___ (chocolate bars)",
    "Kit ___ (candy bars)",
    "Kit ___ (candy bars)",
    "Kit ___ (Hershey bars)"
  ],
  "duce": [
    "Il ___",
    "Il ___ (Mussolini)",
    "Italian leader",
    "W.W. II nickname",
    "Mussolini, in headlines",
    "Mussolini, e.g.",
    "Fascist leader, in Italy",
    "Mussolini moniker",
    "Chief, in Italian"
  ],
  "kung": [
    "___ fu",
    "___ fu",
    "\"___ Pow! Enter the Fist\" (2002 spoof film)",
    "___ fu"
  ],
  "kdka": [
    "Pittsburgh radio station since 1920, said to be the world's first",
    "America's first commercial radio station"
  ],
  "imto": [
    "\"If ___ believe ...\"",
    "\"For ___ be Queen o' the May\": Tennyson",
    "\"___ be Queen o' the May\""
  ],
  "subs": [
    "Fill-ins, informally",
    "Benchwarmers",
    "Sits in (for)",
    "Heros",
    "Footlong sandwiches",
    "Two-handed lunch orders",
    "Those on the bench",
    "Nonstarters",
    "Depth charge targets",
    "Ocean prowlers"
  ],
  "rijn": [
    "Artist Rembrandt van ___",
    "Rembrandt van ___",
    "Rembrandt van ___"
  ],
  "noif": ["\"There Is ___ ...\" (song by the Cure)"],
  "hoke": [
    "Bunkum",
    "Miss Daisy's driver in \"Driving Miss Daisy\"",
    "Jazz (up)",
    "Jazz (up)",
    "Bunkum",
    "Artificially jazz (up)",
    "Jazz (up)",
    "Make artificially better, with \"up\"",
    "Nonsense",
    "Balderdash",
    "Nonsense"
  ],
  "itup": [
    "Live ___",
    "See 2-Down",
    "\"Live ___!\"",
    "\"Shake ___\" (1981 song by the Cars)",
    "Live ___",
    "\"Give ___ for ?à\"",
    "Give ___ for ...!\" (intro phrase)",
    "\"Live ___!\"",
    "Words after give or live",
    "Lived ___ (celebrated)",
    "Live ___",
    "Live ___",
    "Live ___ (enjoy the good life)"
  ],
  "tonk": ["Honky-___", "Honky-___", "Honky-___", "Honky-___"],
  "lodz": [
    "City of 750,000 SW of Warsaw",
    "Poland's second-largest city",
    "Polish birthplace of Arthur Rubinstein",
    "Arthur Rubinstein's birthplace",
    "City WSW of Warsaw",
    "Polish industrial city",
    "Poland?Æs second-largest city"
  ],
  "esce": [
    "Ending with fluor-",
    "Suffix with opal",
    "Suffix with 1-Across",
    "Ending with fluor-",
    "Suffix with lumin-",
    "Suffix with opal",
    "Verb ending",
    "Suffix with opal",
    "Suffix with fluor-",
    "Suffix with fluor-",
    "Suffix with lumin-",
    "Suffix meaning \"to become\"",
    "Suffix with phosphor",
    "Suffix with opal"
  ],
  "odra": [
    "Polish border river",
    "Wroclaw's river, to Poles",
    "Polish border river",
    "Polish border river"
  ],
  "kist": [
    "Commercial ending for Sun or Star",
    "Word with Star or Sun in product names",
    "Star-___ tuna"
  ],
  "prot": [
    "Luth. or Presb.",
    "Like Luther: Abbr.",
    "Meth., e.g.",
    "Bapt. or Meth."
  ],
  "vivo": [
    "Andrea Bocelli's \"___ per lei\"",
    "In ___ (form of research)",
    "Lively, on scores",
    "In ___ (occurring naturally)",
    "In ___ (type of fertilization)",
    "In ___ (occurring naturally)",
    "Lively, to Liszt"
  ],
  "lvov": [
    "Ukrainian city, once",
    "Ukrainian city, formerly",
    "Ukrainian hub",
    "Ukrainian city near the Polish border",
    "City in Ukraine"
  ],
  "joon": [
    "\"Benny & ___\" (1993 rom-com)",
    "\"Benny & ___\" (1993 film)",
    "\"Benny & ___\" (1993 romance)"
  ],
  "hwys": [
    "Some map lines: Abbr.",
    "Rtes.",
    "Major rtes.",
    "Jct. joiners",
    "Rtes."
  ],
  "tetr": [
    "Four: Prefix",
    "Half of oct-",
    "Numerical prefix with oxide",
    "Four: Prefix",
    "Four: Prefix",
    "Having four parts: Prefix",
    "Four: Prefix",
    "Fourfront?",
    "Four: Prefix"
  ],
  "twol": ["Like Nash's llama vis-a-vis lama"],
  "beok": ["Suffice"],
  "appl": [
    "Washer, e.g.: Abbr.",
    "G.E. unit: Abbr.",
    "Stove or washer: Abbr.",
    "Kitchen item: Abbr.",
    "G.E. product: Abbr.",
    "Special use: Abbr."
  ],
  "ovis": [
    "Sheep's genus",
    "Sheep genus",
    "Sheep genus",
    "Sheep's genus",
    "Sheep's genus",
    "21-Down?Æs genus"
  ],
  "atub": [
    "\"Three Men in ___\" (\"Our Gang\" short)",
    "\"... three men in ___\"",
    "Swift's \"A Tale of ___\"",
    "\"... three men in ___\"",
    "\"Three men in ___\"",
    "Swift's \"A Tale of ___\"",
    "Swift's \"A Tale of ___\"",
    "\"?à three men in ___\"",
    "\"... men in ___\"",
    "Swift's \"The Tale of ___\"",
    "\"Three men in ___\"",
    "\"Three men in ___\"",
    "\"Three men in ___\"",
    "\"Three men in ___\"",
    "\"Three men in ___\"",
    "\"Three men in ___\""
  ],
  "jeni": [
    "Late comic Richard",
    "Late stand-up comic Richard",
    "Comic Richard ___"
  ],
  "irrs": [
    "Sale bin items: Abbr.",
    "Bargain basement markings",
    "Clothing store bargain fodder: Abbr.",
    "Factory seconds: Abbr.",
    "Factory seconds: Abbr.",
    "Some flawed mdse.",
    "Some bargain bin contents: Abbr.",
    "Some bargain bin items, for short",
    "Sale items, for short"
  ],
  "mlii": [
    "Birth year of King Philip I",
    "The year 1052",
    "1052, in a proclamation",
    "14 years before the Battle of Hastings",
    "Mid 11th-century date",
    "Year of Philip I's birth",
    "Year in St. Leo IX's papacy"
  ],
  "lary": [
    "N.F.L. Hall-of-Famer Yale ___",
    "Three-time All-Star pitcher Frank",
    "Football Hall-of-Famer Yale ___"
  ],
  "avgs": [
    "Stock market figs.",
    "N.Y.S.E. nos.",
    "Means: Abbr.",
    "Some baseball stats: Abbr.",
    "Stock market figs.",
    "Middle amts."
  ],
  "pelf": [
    "Ill-gotten wealth",
    "Lucre",
    "Ill-gotten gains",
    "Ill-gotten gains",
    "Filthy lucre",
    "Ill-gotten funds",
    "Ill-gotten gains",
    "Ill-gotten gain",
    "Lucre",
    "Ill-gotten gains",
    "Filthy lucre",
    "Loot",
    "Ill-gotten gains",
    "Riches",
    "Filthy lucre",
    "Ill-gotten wealth",
    "Ill-gotten gains"
  ],
  "dlix": [
    "1,000 years before the coronation of Queen Elizabeth I",
    "Mid sixth-century year",
    "Year in Pelagius I's papacy"
  ],
  "trow": [
    "Suppose, to Shakespeare",
    "Think, in olden times",
    "Think way back?",
    "Think, old-style"
  ],
  "wain": ["Farm wagon", "Farm cart", "Heavy farm cart"],
  "wald": ["Forest, in Germany", "\"Peyton Place\" producer Jerry"],
  "frit": ["Glassmaking material", "Glassmaking ingredient"],
  "amoo": [
    "\"Here ___, there ...\"",
    "Words after \"here,\" \"there\" and \"everywhere\" in \"Old MacDonald Had a Farm\"",
    "\"Here ___, there...\""
  ],
  "onon": [
    "\"___, you noblest English\": Shak.",
    "\"___, you noblest English ...!\": \"Henry V\"",
    "\"___, upward thro' the golden air\": Lindsay"
  ],
  "alid": [
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\"",
    "\"Put___on it!\"",
    "\"Put ___ on it!\"",
    "\"Put ___ on it!\""
  ],
  "sola": [
    "Alone, as a female on stage",
    "Alone, on the stage",
    "Fa followers"
  ],
  "hopo": ["___-my-thumb", "___-my-thumb"],
  "afit": [
    "Throw ___",
    "Throw ___",
    "Pitch ___",
    "Throw ___",
    "In ___ of anger",
    "Throw ___ (go ballistic)",
    "Have ___ (fume)",
    "Throw___ (rant)",
    "Throw ___ (explode)",
    "Have ___ (blow one's top)"
  ],
  "geol": [
    "Hydrologist's field: Abbr.",
    "Earth sci.",
    "Earth sci.",
    "Earth sci.",
    "One of the phys. sciences",
    "Seismologist's field: Abbr.",
    "Earth sci."
  ],
  "isas": [
    "\"Your guess ___ good ...\"",
    "\"A miss ___ good ...\"",
    "\"The stroke of death ___ a lover's pinch\": Cleopatra",
    "\"That ___ it should be\"",
    "\"This ___ good as it gets!\"",
    "\"Handsome ___ handsome does\"",
    "\"A miss ___ good...\"",
    "\"Stupid ___ stupid does\"",
    "\"A miss___good...\""
  ],
  "moas": [
    "Extinct emu-like birds",
    "Old New Zealand natives",
    "Extinct relatives of ostriches",
    "Extinct birds",
    "Extinct New Zealanders",
    "Extinct birds"
  ],
  "mdiv": [
    "Year Michelangelo's \"David\" was completed",
    "Year Columbus returned from his final voyage to the New World",
    "XII years after Columbus's first voyage",
    "DCCLII doubled"
  ],
  "ling": [
    "Suffix with duck",
    "TV journalist Lisa",
    "When repeated, a noted panda",
    "Diminutive suffix",
    "Kind of cod",
    "Diminutive suffix",
    "Suffix with strip or seed"
  ],
  "quai": [
    "Paris's ___ d'Orsay",
    "Paris's ___ d'Orsay",
    "Paris's ___ d'Orsay",
    "Le Havre lading place",
    "Gare de l?ÆEst platform"
  ],
  "dozy": [
    "On the way out?",
    "Ready for a nap",
    "On the way out?",
    "Ready to conk out"
  ],
  "foxx": ["2004 Best Actor winner for \"Ray\"", "Comic Redd"],
  "euwe": [
    "1930s world chess champion Max",
    "Dutch chess grandmaster Max",
    "World chess champ, 1935-37",
    "30's chess champ Max"
  ],
  "tana": [
    "Lake from which the Blue Nile begins",
    "Lake ___ (Blue Nile source)",
    "Lake ___, head of the Blue Nile",
    "Lake ___ (Blue Nile source)",
    "Lake ___ (Blue Nile source)",
    "Lake from which the Blue Nile springs"
  ],
  "bsmt": [
    "Place for a washer and dryer: Abbr.",
    "Cellar, in classifieds",
    "Real-estate abbr.",
    "Realty ad abbr.",
    "Rec rm. locale, often",
    "Real-estate ad abbr.",
    "Real-estate ad abbr.",
    "Cellar, in real estate ads"
  ],
  "ofan": [
    "Blink ___ eye",
    "Wink ___ eye",
    "Blink ___ eye",
    "Blink ___ eye",
    "In the blink ___ eye",
    "In the wink ___ eye",
    "Germ ___ idea",
    "The end ___ era",
    "P. D. James's \"Death ___ Expert Witness\"",
    "End ___ era",
    "In the blink ___ eye",
    "In the blink ___ eye"
  ],
  "pfcs": [
    "Some G.I.'s",
    "Ranks for jarheads: Abbr.",
    "Some basic training grads",
    "Ones ranking below cpls.",
    "Gomer Pyle and platoonmates, by rank: Abbr.",
    "NCO's charges",
    "Sgt.'s charges",
    "Some G.I.'s",
    "Subordinates to cpls.",
    "Noncoms: Abbr."
  ],
  "glyn": ["English author Elinor", "\"Three Weeks\" author Elinor"],
  "seps": ["Turn-of-the-season mos.", "Fall mos.", "Back-to-sch. times"],
  "beas": [
    "Arthur and Benaderet",
    "Actress Arthur and others",
    "Arthur and others",
    "\"I'll ___ brief as possible\"",
    "Actresses Arthur and Benaderet",
    "Arthur and others",
    "Actress Arthur and others"
  ],
  "anes": [
    "Hydrocarbon suffixes",
    "Chemical suffixes",
    "Hydrocarbon endings",
    "Scottish singletons",
    "Hydrocarbon suffixes",
    "Hydrocarbon suffixes",
    "Hydrocarbon suffixes"
  ],
  "soit": [
    "\"... and ___ goes\"",
    "\"___ would seem\"",
    "\"___ would seem\"",
    "\"___ goes\"",
    "\"___ seems\"",
    "\"... and ___ goes\"",
    "\"___ seems\"",
    "\"___ goes\"",
    "\"And ___ goes\"",
    "Linda Ellerbee's \"And___Goes\"",
    "\"___ was you!\" (mystery denouement)",
    "\"___ goes\" (\"Slaughter-house-Five\" refrain)"
  ],
  "ssss": [
    "Flat sound",
    "Indication of deflation",
    "Flat sound",
    "Deflation indication",
    "Flat sound",
    "Deflation indicator",
    "Bacon sizzle",
    "Air hose sound",
    "Flat tire indicator",
    "Radiator sound",
    "Sound of a leak",
    "Sound of a leak",
    "Not a good sound for a balloonist",
    "Radiator sound",
    "Kettle sound",
    "Sound of a live wire"
  ],
  "rabi": [
    "1944 Nobel physicist Isidor",
    "1944 Physics Nobelist I. I. ___",
    "Physics Nobelist Isidor I. ___",
    "Physics Nobelist Isidor"
  ],
  "encs": [
    "Accompaniers of cover letters: Abbr.",
    "Letter attachments: Abbr.",
    "Abbr. at the bottom of a business letter",
    "Ltr. extras",
    "Letter attachments: Abbr.",
    "Env. contents"
  ],
  "amia": [
    "\"What ___ mind reader?\"",
    "\"What ___ mind reader?\"",
    "\"What ___ mind reader?\"",
    "\"What ___ mind reader?"
  ],
  "ebla": ["Powerful Syrian city in the third millennium B.C."],
  "ceil": [
    "Put a cover on",
    "Furnish with a lining",
    "Overlay with wood or plaster"
  ],
  "pyne": [
    "Christie detective Parker ___",
    "Old talk show host Joe",
    "60's talk show host Joe"
  ],
  "obad": [
    "Book after Amos: Abbr.",
    "Book between Amos and Jonah: Abbr.",
    "Book after Amos: Abbr."
  ],
  "tasm": [
    "Isl. off the coast of Australia",
    "Land under Down Under: Abbr.",
    "Australian island: Abbr.",
    "Island that's part of 90-Down: Abbr.",
    "Land under Down Under: Abbr."
  ],
  "agod": [
    "\"Yes, there is ___!\"",
    "\"There is ___!\"",
    "Steinbeck's \"To ___ Unknown\"",
    "Steinbeck's \"To ___ Unknown\"",
    "\"Yes, there is ___!\"",
    "\"Yes, there is ___!\"",
    "\"This man is now become ___\":\"Julius Caesar\""
  ],
  "gyps": [
    "Cheats",
    "Swindles",
    "Pulls a fast one on",
    "Shortchanges",
    "Works a deal on"
  ],
  "drax": ["\"Moonraker\" villain"],
  "imso": [
    "\"___ sorry\"",
    "\"___ sorry!\"",
    "\"___ Excited\" (Pointer Sisters hit)",
    "\"___ Excited\" (Pointer Sisters hit)",
    "The Pointer Sisters' \"___ Excited\"",
    "\"___ sorry!\"",
    "\"___ in Love With You\"",
    "The Pointer Sisters' \"___ Excited\"",
    "\"___ hungry I could...\"",
    "The Pointer Sisters' \"___ Excited\"",
    "\"___ Excited\" (1984 hit)",
    "\"___ hungry I could...\"",
    "\"___ Excited\" (1984 hit)"
  ],
  "colo": [
    "Home of the Rockies: Abbr.",
    "Home of Steamboat Spgs.",
    "Boulder's home: Abbr.",
    "Neighbor of N.M.",
    "U.S.A.F. Academy site",
    "Neighbor of N.M.",
    "Denver's home: Abbr."
  ],
  "nord": [
    "Away from l'Antarctique",
    "France's ___-Pas-de-Calais",
    "Toward l'Arctique",
    "N, on a French map",
    "Sud's opposite",
    "L'etoile du ___, Minnesota's motto",
    "Sud's opposite",
    "Opposite of sud",
    "Direction opposite sud",
    "L'Etoile du ___, Minnesota's motto",
    "Department whose capital is Lille"
  ],
  "iaim": ["\"___ to please\"", "\"___ to please!\""],
  "iono": [
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with sphere",
    "Prefix with sphere"
  ],
  "incl": ["With: Abbr.", "With: Abbr."],
  "grrr": ["[Don't touch my food!]", "Boxer's warning"],
  "vive": [
    "\"___ l'amour\"",
    "\"___ le roi!\"",
    "\"___ le roi!\"",
    "On the qui ___",
    "On the qui ___",
    "On the qui ___"
  ],
  "mdvi": [
    "Year Christopher Columbus died",
    "Year Columbus died",
    "Year Columbus died",
    "Year of Columbus's death",
    "Year of Christopher Columbus's death",
    "Year of Columbus's death"
  ],
  "muti": [
    "Conductor Riccardo",
    "Longtime La Scala music director",
    "La Scala music director beginning 1986",
    "Ormandy's successor in Philadelphia",
    "Acclaimed Philly conductor",
    "Conductor Riccardo",
    "Conductor Riccardo",
    "Conductor Riccardo"
  ],
  "birl": [
    "Do some logrolling",
    "Do some logrolling",
    "Compete in a 15-Across",
    "Engage in logrolling",
    "Practice logrolling",
    "Engage in logrolling",
    "Spin, as a floating log"
  ],
  "doss": [
    "Avoid work, in Britain",
    "Sleep, in British slang",
    "Place to sleep, in Britain"
  ],
  "tamo": [
    "___-shanter",
    "___-shanter",
    "___-shanter",
    "___-shanter",
    "___-shanter",
    "Shanter start",
    "___-shanter"
  ],
  "heth": [
    "The first \"H\" in Hanukkah",
    "Eighth Hebrew letter",
    "The \"H\" in Hanukkah",
    "The \"H\" in Hanukkah"
  ],
  "punk": [
    "No-goodnik",
    "Play a practical joke on, slangily",
    "The Sex Pistols' genre",
    "Kind of rock",
    "Lousy"
  ],
  "deum": ["Te ___", "\"Te ___\" (hymn)", "\"Te ___\" (hymn)"],
  "apot": [
    "\"There's ___ of gold ...\"",
    "\"... poison'd with ___ of ale\": Shakespeare",
    "Words before \"of money\" or \"of gold\""
  ],
  "itos": ["Midori and Lance", "Skater Midori et al."],
  "iasi": [
    "Former capital of Romania",
    "Capital of old Moldavia",
    "Former capital of Romania",
    "Old capital of Romania",
    "Romanian city"
  ],
  "euse": ["Feminine suffix"],
  "atto": [
    "Quintillionth: Prefix",
    "Quintillionth: Prefix",
    "Quintillionth: Prefix",
    "Quintillionth: Prefix",
    "Quintillionth: Prefix",
    "One-quintillionth: Prefix",
    "Quintillionth: Prefix",
    "One-quintillionth: Prefix"
  ],
  "erda": [
    "Mother of the Valkyries",
    "Wagner's earth goddess",
    "\"Das Rheingold\" goddess",
    "Wagnerian earth goddess",
    "Mother of the Valkyries",
    "\"Das Rheingold\" goddess",
    "\"Das Rheingold\" goddess",
    "Mother of the Valkyries",
    "\"Das Rheingold\" earth goddess",
    "\"Das Rheingold\" role",
    "Earth goddess",
    "Wagner's earth goddess"
  ],
  "regt": [
    "Armory grp.",
    "Mil. unit below a division",
    "Colonel's div.",
    "Armory grp.",
    "Grp. of battalions",
    "Mil. unit"
  ],
  "alfs": [
    "Film composer Clausen and others",
    "Film composer Clausen and others",
    "Melmac alien et al.",
    "NBC sitcom showings of the late 1980's",
    "Gov. Landon and others",
    "Swedish actor Kjellin et al."
  ],
  "ilse": [
    "Actress Steppat of \"On Her Majesty's Secret Service\"",
    "German photographer ___ Bing"
  ],
  "dafe": ["Auto-___", "See 25-Across"],
  "beri": [
    "When doubled, a vitamin deficiency",
    "When repeated, a vitamin B deficiency",
    "When repeated, a vitamin B deficiency"
  ],
  "amap": ["\"Want me to draw you ___?\"", "\"Do I need to draw you ___?\""],
  "gert": [
    "Frobe who played Goldfinger",
    "Frobe who played Goldfinger",
    "Actor Frobe of \"Goldfinger\"",
    "What Hamlet never called his mom",
    "Frobe who played Goldfinger",
    "Frobe who played Goldfinger",
    "Hurricane of 1999",
    "Goldfinger portrayer ___ Frobe",
    "Actor Frobe",
    "Goldfinger portrayer Frobe",
    "Actor Frobe of \"Goldfinger\""
  ],
  "tors": [
    "Rocky outcrops",
    "Places for some aeries",
    "Rocky peaks",
    "Craggy peaks",
    "Rocky peaks",
    "High spots",
    "Rocky peaks",
    "Lonely heights",
    "Rocky peaks",
    "Rocky peaks",
    "Peaks",
    "High points",
    "Crags",
    "Rocky crags"
  ],
  "aery": [
    "Eagle's nest: Var.",
    "Ethereal",
    "Bird's home: Var.",
    "Ethereal",
    "Unsubstantial",
    "Unsubstantial",
    "Hawk's home: Var.",
    "Lofty, as a mountain peak"
  ],
  "tami": [
    "Romance/thriller novelist Hoag",
    "Suspense novelist Hoag",
    "Novelist Hoag",
    "Novelist Hoag",
    "Suspense novelist ___ Hoag",
    "Suspense novelist ___ Hoag"
  ],
  "eths": [
    "Old English letters",
    "Biblical verb endings",
    "Biblical endings",
    "Old English letters",
    "Ordinal endings",
    "Letters in Icelandic",
    "Old English letters",
    "Biblical word endings"
  ],
  "itsi": [
    "Formal/informal reply to \"Who's there?\"",
    "Formal/informal response to \"Who's there?\""
  ],
  "ppps": [
    "Thought after an after-afterthought: Abbr.",
    "Third afterthought in a missive: Abbr.",
    "Afterthought #3: Abbr.",
    "Third line at the bottom: Abbr.",
    "Third afterthought in a letter: Abbr.",
    "Third addendum to a letter"
  ],
  "eula": [
    "\"The Long, Hot Summer\" woman ___ Varner",
    "Faulkner femme fatale ___ Varner",
    "Faulkner's ___ Varner",
    "Faulkner character ___ Varner",
    "Faulkner's femme fatale ___ Varner",
    "Faulkner character ___ Varner",
    "___ Varner, Faulkner woman"
  ],
  "ruta": ["Actress Lee of \"Funny Face\"", "Actress Lee of TV and film"],
  "atea": ["___ lot (gorged oneself)", "___ lot (gorged oneself)"],
  "othe": [
    "\"The wearin' ___ green\"",
    "Will-___-wisp",
    "\"Top ___ mornin'!\"",
    "Prince's \"Sign ___ Times\"",
    "\"___ days gone by!\": Riley",
    "Will-wisp connection",
    "Will-wisp connection",
    "Will-___-wisp"
  ],
  "irvs": ["Sportscaster Cross and others", "Kupcinet and Cross"],
  "meno": [
    "\"Tell ___ lies\"",
    "___ forte (less loud, in music)",
    "___ mosso (less rapid, in music)",
    "\"Don't give ___ lip!\"",
    "\"Tell ___ lies\"",
    "\"Tell ___ lies\"",
    "Month: Prefix"
  ],
  "cdli": [
    "Mid fifth-century year",
    "Year Attila invaded Gaul",
    "Fifth-century year",
    "Year Attila was defeated in Gaul",
    "A year in the life of Attila"
  ],
  "tnts": [
    "They cause blowups",
    "Demolition supplies",
    "Blowup causes",
    "Demolition compounds",
    "Explosives"
  ],
  "pero": ["But, in Bolivia", "Spanish \"but\"", "Spanish \"but\""],
  "dore": [
    "French artist famous for 60-Acrossing",
    "Illustrator of Cervantes's \"Don Quixote\"",
    "Scale start",
    "Illustrator of \"Paradise Lost\" and \"The Divine Comedy\"",
    "\"Paradise Lost\" illustrator",
    "Noted 19th-century French illustrator",
    "French illustrator Gustave",
    "Scale start",
    "Scale start",
    "French illustrator Gustave",
    "French illustrator Gustave",
    "Artist Gustave",
    "\"Le Neophyte\" artist",
    "Opening notes",
    "Illustrator Gustave",
    "Film producer ___ Schary"
  ],
  "namu": ["Film whale", "Movie whale"],
  "enas": [
    "Spanish queen and namesakes",
    "Onetime Spanish queen and namesakes"
  ],
  "rhin": ["Nose: Prefix", "Nose: Prefix", "Nose: Prefix"],
  "mota": [
    "Longtime Dodgers coach Manny",
    "Baseballer Manny",
    "Pinch-hitting great Manny ___",
    "1988 marathon gold medalist Rosa ___",
    "Manny of the Dodgers"
  ],
  "beon": [
    "\"___ your toes!\"",
    "\"___ your best behavior\"",
    "\"___ the lookout!\"",
    "\"___ the alert!\""
  ],
  "keas": ["New Zealand parrots", "Large green parrots"],
  "apeg": [
    "Take down ___ (humble)",
    "Take down ___",
    "Take down ___ (humble)",
    "Take down ___",
    "Take down ___",
    "Take down ___",
    "Take down___"
  ],
  "adjs": ["Sm., med. and lg., e.g."],
  "hadi": [
    "\"___ but known ...\"",
    "\"___ known then ...\"",
    "\"___ known ...\"",
    "\"___ known then...\"",
    "\"___ but known...\"",
    "\"___ known then what...\""
  ],
  "cire": [
    "Highly glazed fabric",
    "Glazed fabric",
    "Glazed, waxy fabric finish",
    "Wax-glazed fabric",
    "Wax-glazed fabric"
  ],
  "bitt": [
    "Mooring post on a ship",
    "Mooring post",
    "Ship post for securing cables",
    "Ship post"
  ],
  "hund": ["\"Best friend\" from Germany?", "Dusseldorf dog", "Coburg canine"],
  "cock": [
    "One making a wake-up call?",
    "Tilt",
    "Bull's partner",
    "Rooster",
    "Tilt, as the head"
  ],
  "phen": [
    "Fen-___ (former weight-loss drug)",
    "Fen-___ (banned diet aid)",
    "Weight-loss drug combo fen-___",
    "Fen-___ (former diet drug combo)",
    "Fen-___ (diet drug combo)"
  ],
  "eery": [
    "Creepy: Var.",
    "Mysterious: Var.",
    "Bone-chilling: Var.",
    "Creepy: Var.",
    "Mysterious: Var.",
    "Mysterious: Var.",
    "Like Serling tales: Var.",
    "Mysterious: Var.",
    "Like Serling stories: Var.",
    "Like a King novel: Var.",
    "Odd, spelled oddly",
    "Uncanny: Var.",
    "Weird: Var.",
    "Weird: Var.",
    "Unnerving: Var.",
    "Like a Poe tale",
    "Spine-tingling: Var.",
    "Like a haunted house"
  ],
  "capa": [
    "Bullfighter's cloak",
    "Bullfighting cloak",
    "Cloak, in Cordoba",
    "Corrida cloak",
    "Bullfighter's red cloak",
    "Bullfighter's cloak",
    "Bullfighter's cloak",
    "Bullfighter's red cloak"
  ],
  "falk": [
    "Peter who played Columbo",
    "Columbo portrayer",
    "Colombo portrayer",
    "TV's Peter",
    "\"The In-Laws\" co-star"
  ],
  "mumm": ["Big name in Champagne", "Name on a champagne label"],
  "dene": [
    "Sandy tract by the sea, to a Brit",
    "Sandy tract, in Britain",
    "Sandy tract",
    "Sandy tract"
  ],
  "eata": [
    "\"I could ___ horse!\"",
    "\"I could ___ horse!\"",
    "\"Do I dare to ___ peach?\"",
    "___ lot (gorge)",
    "\"I can't ___ thing!\"",
    "\"I can't ___ thing\"",
    "David George Gordon's \"The ___-Bug Cookbook\"",
    "\"___ Bowl of Tea\" (1989 comedy)",
    "\"Do I dare to ___ peach?\"",
    "\"...could ___ horse!\"",
    "\"I could ___ horse!\"",
    "\"I can't ___ thing\"",
    "\"___ Peach\" (Allman Brothers album)",
    "\"I could___horse!\"",
    "\"Do I dare to ___ peach?\": Eliot"
  ],
  "sfax": [
    "Tunisian seaport",
    "Tunisian port once a stronghold for Barbary pirates"
  ],
  "neri": [
    "Saint Philip ___",
    "St. Philip of Rome",
    "Italian saint Philip ___",
    "Congregation of the Oratory founder Philip",
    "Saint Philip ___ (Renaissance figure)",
    "Rodolfo ___, first Mexican astronaut"
  ],
  "tais": [
    "Mai ___ (drinks)",
    "Mai ___ (drinks)",
    "Mai ___ (drinks)",
    "See 45-Across",
    "Mai ___",
    "Mai ___"
  ],
  "amoy": [
    "Taiwan Strait city",
    "Taiwan Strait city",
    "Chinese port also called Xiamen",
    "Island in the Taiwan Strait",
    "Treaty of Nanking port",
    "Chinese island now called Xiamen",
    "Fukien seaport",
    "Formosa Strait island"
  ],
  "mmdi": ["Early 26th-century year", "Mid-millennium year"],
  "rell": ["Former Connecticut governor Jodi"],
  "usac": ["Midget car-racing org.", "Car-racing org."],
  "ceno": [
    "Prefix with -zoic",
    "Recent: Prefix",
    "Recent: Prefix",
    "The current \"-zoic\""
  ],
  "lenz": [
    "Kay of \"Rich Man, Poor Man\"",
    "?ôBreezy?ö star, 1973",
    "Actress Kay of \"Breezy,\" 1973",
    "Kay of \"Fast-Walking\"",
    "Kay of \"Physical Evidence\"",
    "\"Breezy\" star, 1973"
  ],
  "lect": ["Symposium offering: Abbr.", "Prof.'s class", "Univ. worker"],
  "doty": ["Poet Mark", "Feeble-minded", "Feeble-minded", "Cartoonist Roy"],
  "yung": ["Egg foo ___", "Foo ___ (Chinese dish)", "Egg foo ___"],
  "weft": [
    "Filling yarn",
    "Tapestry thread",
    "Horizontal thread in a fabric",
    "Tapestry thread",
    "Fabric",
    "Filling"
  ],
  "meto": [
    "\"Drag ___ Hell\" (2009 movie)",
    "\"Take ___ your leader\"",
    "\"Fly ___ the Moon\"",
    "\"Take ___ the River\" (Talking Heads hit)",
    "\"Get ___ the Church on Time\"",
    "\"If You Asked ___\" (Celine Dion hit)",
    "\"Take ___ your leader\"",
    "\"Take ___ your leader\"",
    "\"Take ___ your leader\""
  ],
  "dopy": ["Sluggish from sedatives", "Half-baked", "Idiotic"],
  "dlvi": ["Mid sixth-century year", "Mid sixth-century year"],
  "vids": ["YouTube content, for short"],
  "aube": ["French river or department", "Seine feeder", "18-Across feeder"],
  "wadi": [
    "Often-parched gully",
    "Desert stream",
    "Dry riverbed",
    "Dry riverbed",
    "Gully that's usually dry",
    "Dry riverbed",
    "Dry wash",
    "African stream",
    "Desert stream",
    "Desert streambed",
    "Desert stream",
    "Desert streambed",
    "Arroyo"
  ],
  "msgt": [
    "U.S.M.C. E-8",
    "U.S.M.C. E-8",
    "Certain NCO",
    "Ernie Bilko's rank: Abbr.",
    "One of a group of 40-Across",
    "Certain N.C.O.",
    "Noncom: Abbr.",
    "High-ranking NCO"
  ],
  "otea": [
    "Cup ___ (hot drink, informally)",
    "\"Cup ___\" (1970s Don Williams song)",
    "Great Barrier Island"
  ],
  "inmy": [
    "With 64-Across, 1963 Beach Boys hit",
    "The Beatles' \"___ Life\"",
    "With 10-Across, 1963 hit for the Beach Boys",
    "\"___ humble opinion ...\"",
    "\"___ Life\" (Beatles song)",
    "\"___ Room\" (Beach Boys hit)"
  ],
  "ryas": ["Scandinavian rugs", "Scandinavian area rugs"],
  "dipl": ["Embassy worker: Abbr.", "Natl. negotiator"],
  "oulu": ["Finnish city near the Arctic Circle", "City of northern Finland"],
  "ocas": [
    "Edible Andean tubers",
    "Wood sorrels",
    "Wood sorrels",
    "Wood sorrels",
    "Wood sorrels"
  ],
  "gono": [
    "\"___ further ...\"",
    "\"This will ___ further!\"",
    "\"This will ___ further\""
  ],
  "sima": [
    "Lower layer of the earth's crust",
    "Lower layer of the earth's crust"
  ],
  "itic": [
    "Suffix with arthr-",
    "Suffix with phosphor",
    "Related to: Suffix",
    "Suffix with arthr-",
    "Suffix with arthr-",
    "Inflamed, in suffixes",
    "Suffix meaning inflamed"
  ],
  "isme": [
    "\"This ___ You're Talking To\" (Trisha Yearwood song)",
    "\"This ___ ... Then\" (Jennifer Lopez album)",
    "See 44-Across",
    "Words after \"woe\"",
    "\"All You Need ___\" (2008 Morrissey song)",
    "\"Woe ___!\"",
    "\"This ___ ... Then\" (Jennifer Lopez album)",
    "\"Woe ___!\"",
    "\"Woe ___!\"",
    "Words after woe",
    "\"Woe ___\"",
    "\"Woe ___!\"",
    "\"Woe ___!\"",
    "\"Woe___!\"",
    "\"Woe ___\"",
    "\"Woe ___!\""
  ],
  "resh": ["Hebrew letter after koph", "Letter before shin"],
  "rets": [
    "Softens in water, in a way",
    "Soaks, as flax",
    "Soaks, as flax",
    "Soaks",
    "Soaks, as flax",
    "Soaks, as flax",
    "Soaks flax"
  ],
  "boac": [
    "U.K. carrier, once",
    "Old U.K. carrier",
    "Airline mentioned in \"Back in the U.S.S.R.\"",
    "Onetime airline",
    "Former Eur. carrier",
    "Former Eur. airline",
    "U.K. carrier of old",
    "Defunct airline",
    "Bygone airline",
    "Whilom airline inits."
  ],
  "supt": [
    "Overseer of schools: Abbr.",
    "Apt. overseer",
    "Boss: Abbr.",
    "School district C.E.O."
  ],
  "rheo": [
    "Prefix with -stat",
    "Stat starter",
    "Current: Prefix",
    { "formula": "-stat starter", "result": { "error": "#NAME?" } },
    "Prefix with -stat",
    "Stat starter",
    "Stat start",
    "Stat start",
    "Prefix with -stat",
    "Current: Prefix",
    "Stat starter"
  ],
  "lacs": [
    "Varnish resins",
    "Superieur et Erie",
    "Leman and others",
    "Geneve and others",
    "Mille ___ (Minnesota county)",
    "Resins"
  ],
  "gipp": [
    "Notre Dame football legend",
    "Rockne protege",
    "Notre Dame footballer coached by Rockne"
  ],
  "tink": ["\"Peter Pan\" fairy, for short", "\"Peter Pan\" fairy, for short"],
  "blvd": ["Road map abbr.", "Hollywood or Sunset: Abbr."],
  "eeka": [
    "Reggae artist ___-Mouse",
    "\"___ mouse!\"",
    "Reggae artist ___-Mouse",
    "Reggae's ___-Mouse"
  ],
  "froe": [
    "Wood-cleaving tool",
    "Cooper's cleaving tool",
    "Cleaving tool",
    "Splitting tool"
  ],
  "yesi": [
    "\"___ know\" (\"O.K., O.K.\")",
    "\"___ Can\" (Sammy Davis Jr. autobiography)",
    "Sammy Davis Jr.'s \"___ Can\"",
    "Sammy Davis Jr.'s \"___ Can\"",
    "\"Can\" opener?",
    "Sammy Davis Jr.'s \"___ Can\"",
    "\"___ Will\" (Backstreet Boys song)",
    "Sammy Davis Jr.'s \"___ Can\"",
    "\"___ Can\" (Sammy Davis Jr. book)",
    "\"___ Am\" (Melissa Etheridge title track)",
    "Webb Pierce song \"___ Know Why\"",
    "Sammy Davis's \"___ Can\""
  ],
  "doas": [
    "\"___ you're told!\"",
    "\"___ I say, not ...\"",
    "___ told",
    "\"___ I say, not ...\"",
    "\"___I say...\""
  ],
  "klmn": ["Alphabet string", "OP's forerunners", "J train?"],
  "dlit": ["Honorary deg. for many a writer", "Advanced Eng. degree"],
  "wonk": [
    "Policy expert",
    "Policy ___",
    "Bookish sort, slangily",
    "Overstudious sort",
    "Policy expert",
    "Quite the bookworm",
    "Grind"
  ],
  "knap": ["Chip, as flint, in Britain", "Sack starter", "Sack starter"],
  "ofid": [
    "\"The Wizard ___\"",
    "\"The Wizard ___\"",
    "\"The Wizard ___\"",
    "\"The Wizard ___\"",
    "\"The Wizard ___\""
  ],
  "lems": [
    "Old NASA landers",
    "NASA's Falcon and Intrepid",
    "Moon-landing vehicles, for short",
    "Some touchdown makers, briefly",
    "The Eagle and others",
    "NASA craft",
    "NASA craft",
    "Apollo vehicles",
    "Moon vehicles",
    "NASA vehicles",
    "Vehicles for Apollo",
    "Moon vehicles, for short",
    "Apollo craft",
    "Golf-cartlike vehicles"
  ],
  "envs": [
    "Mailers: Abbr.",
    "Ltr. holders",
    "Ltr. carriers",
    "Stationery store stock: Abbr.",
    "Ltr. holders",
    "Stationery store stock: Abbr."
  ],
  "linn": [
    "Philatelist George, founder of the largest weekly newspaper for stamp collectors",
    "Actor Mark ___-Baker",
    "Actor Mark ___-Baker"
  ],
  "dfcs": [
    "R.A.F. awards",
    "Gallantry-in-war medals: Abbr.",
    "U.S.A.F. awards"
  ],
  "uhry": [
    "Alfred who wrote \"Driving Miss Daisy\"",
    "Pulitzer winner for \"Driving Miss Daisy\""
  ],
  "yare": [
    "Easily handled, as a ship",
    "Easy to maneuver, at sea",
    "Easily maneuvered, as a boat",
    "Responsive to the helm, in nautical lingo",
    "Ready, to Shakespeare",
    "Easily handled, as a ship",
    "Quick to the helm",
    "Maneuverable"
  ],
  "runa": [
    "___ temperature",
    "___ race (vie)",
    "___ close second",
    "___ fever (be hot)"
  ],
  "suny": [
    "Sch. syst. with a campus in Buffalo",
    "Sch. system with campuses in Albany and 63 other places",
    "Schools in Albany and Oneonta are part of it: Abbr."
  ],
  "mmdc": ["End of the 26th century", "About six centuries hence"],
  "lmno": [
    "Alphabet run",
    "Middle of a run?",
    "Alphabet quartet",
    "Alphabetic run",
    "K-P interval",
    "Alphabet quartet",
    "Rows before P"
  ],
  "kaat": [
    "Pitcher Jim who was a 16-time Gold Glove winner",
    "'60s-'70s pitcher Jim"
  ],
  "pede": ["Word ending meaning \"foot\"", "Ending with centi- or milli-"],
  "isls": [
    "Cruise stops: Abbr.",
    "Lanai and Maui: Abbr.",
    "Archipelago's makeup: Abbr.",
    "Molokai and Maui: Abbr.",
    "Archipelago makeup: Abbr.",
    "Cuba and Aruba: Abbr."
  ],
  "jour": [
    "Plat du ___",
    "Carte du ___",
    "Day at the Louvre",
    "Du ___ (menu phrase)"
  ],
  "usee": [
    "One for whose benefit a legal suit is brought",
    "Certain plaintiff, at law",
    "Certain plaintiff, at law"
  ],
  "arca": [
    "Medieval treasure chest",
    "Medieval chest",
    "Old Spanish treasure chest",
    "Medieval chest",
    "Medieval chest",
    "Medieval chest",
    "Medieval chest",
    "Old alms box",
    "Old treasure chest",
    "Ancient strongbox",
    "Ancient strongbox",
    "Chest on the Spanish Main"
  ],
  "meor": ["\"Love ___ leave ...\"", "\"Is it just ___ ...\""],
  "irid": [
    "Crocus or freesia, botanically",
    "Crocus or freesia, e.g.",
    "Crocus or freesia"
  ],
  "usar": ["Org. for part-time soldiers", "Companion of the Natl. Guard"],
  "repr": ["Second ed.", "Not an orig.", "Not an orig."],
  "hogg": [
    "Scottish poet James known as \"The Ettrick Shepherd\"",
    "\"Dukes of Hazzard\" boss"
  ],
  "teng": [
    "Singer/songwriter Vienna ___",
    "100 18-Acrosses",
    "Like a Salmon P. Chase bill, slangily"
  ],
  "alap": [
    "Gain ___ on",
    "Gain ___",
    "\"Take ___!\" (track coach's order)",
    "Gain ___ (get ahead in a race)"
  ],
  "tmac": ["Nickname for a seven-time N.B.A. All-Star"],
  "irna": ["Soap opera creator Phillips", "Soap opera creator ___ Phillips"],
  "exel": [
    "Former N.B.A. star Nick Van ___",
    "Basketball All-Star Nick Van ___"
  ],
  "geed": [
    "Turned right",
    "Turned right, like Dobbin",
    "Turned right",
    "Made a right turn",
    "Turned right"
  ],
  "araw": [
    "Got ___ deal (was rooked)",
    "Got ___ deal (was rooked)",
    "Get ___ deal",
    "Get ___ deal",
    "Get ___ deal",
    "Get ___ deal"
  ],
  "hups": ["Cadence syllables", "Cadence sounds"],
  "alen": [
    "Chrysler Building architect William Van ___",
    "Art Deco architect William Van ___",
    "Architect William Van ___",
    "Architect William van ___",
    "Architect William Van ___",
    "Chrysler Building architect William Van ___",
    "Chrysler Building architect William Van ___"
  ],
  "ible": [
    "Suffix with contempt",
    "Suffix with convert",
    "Suffix with corrupt",
    "Suffix with flex",
    "Suffix with discern",
    "Suffix with collect",
    "Suffix with deduct",
    "Suffix with flex",
    "Suffix with corrupt",
    "Suffix with flex",
    "Suffix with convert"
  ],
  "dwan": ["Allan who directed \"Sands of Iwo Jima\""],
  "sica": [
    "Director Vittorio De ___",
    "\"The Bicycle Thief\" director Vittorio De ___",
    "Director Vittorio De ___",
    "Director Vittorio De ___",
    "Director Vittorio De ___",
    "Director Vittorio De ___",
    "Filmdom's Vittorio De___",
    "Film director Vittorio De ___"
  ],
  "utwo": ["Aircraft in 1960 headlines", "Classic spy plane"],
  "gatt": [
    "Intl. commerce pact replaced by the W.T.O.",
    "Letters of commerce",
    "Commerce treaty starting 1947",
    "Intl. commercial agreement first signed in 1947",
    "Global commerce org.",
    "Intl. org. since 1948",
    "Intl. business accord",
    "Intl. commerce org."
  ],
  "wtwo": ["*I.R.S. form"],
  "dhow": [
    "Indian Ocean vessel",
    "Persian Gulf craft",
    "Boat on the Indian Ocean",
    "Red Sea vessel",
    "Arabian sailing vessel",
    "Indian Ocean vessel",
    "Lateen-rigged boat",
    "Lateen-rigged boat",
    "Gulf of Aden vessel"
  ],
  "cens": [
    "Millennium divs.",
    "Long times: Abbr.",
    "Long times to live: Abbr."
  ],
  "mcxl": ["1140, in old Rome"],
  "shor": [
    "Restaurateur Toots",
    "Restaurateur Toots",
    "Old Manhattan restaurateur",
    "Restaurateur Toots",
    "Subject of \"Toots\" by Bob Considine, 1969",
    "Restaurateur Toots",
    "Restaurant business bigwig",
    "Toots in a restaurant",
    "Famed host near Broadway",
    "Subject of the 2006 documentary \"Toots\"",
    "Restaurateur Toots",
    "Restaurateur Toots",
    "Restaurateur Toots",
    "Toots in a dining room",
    "Restaurateur Toots",
    "Longtime Big Apple restaurateur",
    "Toots in a restaurant",
    "Toots at a restaurant?",
    "Restaurateur Toots",
    "Restaurateur Toots",
    "Renowned restaurateur",
    "Restaurateur Toots",
    "Restaurateur Toots"
  ],
  "tmax": ["Kodak film used in surveillance"],
  "jehu": [
    "King in II Kings",
    "King in II Kings",
    "Biblical king who destroyed the worshipers of Baal"
  ],
  "bams": [
    "Smashing sounds",
    "Loud hits",
    "Loud hits",
    "Sounds of impact",
    "Sounds of impact"
  ],
  "gens": [
    "Washington, Grant and others: Abbr.",
    "Pentagon V.I.P.'s: Abbr.",
    "U.S.M.C. V.I.P.'s",
    "D.D.E. and others"
  ],
  "pfui": ["\"Drat!\"", "\"Bah, humbug!\"", "\"Bah, humbug!\""],
  "afat": [
    "\"... to buy ___ pig\"",
    "___ lot (very little)",
    "\"... to buy ___ pig\"",
    "Give ___ lip (punch)"
  ],
  "rego": [
    "___ Park, Queens",
    "___ Park (Queens neighborhood)",
    "___ Park (Queens neighborhood)",
    "___ Park, N.Y.",
    "Queens's ___ Park",
    "___ Park, N.Y.",
    "___ Park, Queens",
    "___ Park, N.Y.",
    "___ Park, N.Y."
  ],
  "luvs": [
    "Huggies competitor",
    "Huggies rival",
    "Pampers rival",
    "Brand at the bottom?"
  ],
  "reft": [
    "Robbed, old-style",
    "Torn (from), old-style",
    "Broken, old-style",
    "Plundered, old-style",
    "Taken by force, old-style",
    "Taken away by force, old-style",
    "Plundered, old-style",
    "Deprived, poetically",
    "Deprived, once"
  ],
  "zorn": [
    "N.F.L. coach Jim",
    "Avant-garde saxophonist John",
    "Swedish painter of \"At the Granary Door\""
  ],
  "apig": [
    "Fat as ___",
    "Eat like ___",
    "Fat as ___",
    "Eat like ___",
    "Like ___ in a poke",
    "Eat like ___",
    "Eat like ___",
    "___ in a poke",
    "Make ___ of oneself"
  ],
  "leej": [
    "Cobb of \"12 Angry Men\"",
    "Cobb of \"12 Angry Men\"",
    "Actor ___ Cobb",
    "Actor ___ Cobb",
    "Actor ___ Cobb",
    "Actor ___ Cobb"
  ],
  "aabb": ["Common rhyme scheme", "Rhyme scheme"],
  "toan": [
    "\"___ Athlete Dying Young\" (A. E. Housman poem)",
    "Means ___ end",
    "Means ___ end",
    "Means___end",
    "Bring ___ end"
  ],
  "bano": ["Spanish bath", "Bilbao bath"],
  "chee": ["Tony Hillerman detective Jim"],
  "noto": [
    "See 19-Across",
    "Say ___ (reject)",
    "Say ___ (refuse)",
    "Say ___ (deny)",
    "Say ___ (refuse)",
    "Say ___ (deny)",
    "Say ___ (refuse)"
  ],
  "clar": [
    "Woodwind instr.",
    "Reed instrument: Abbr.",
    "Woodwind instrument: Abbr."
  ],
  "oyls": ["Olive kin", "Brother Castor and sister Olive", "Olive's family"],
  "sawa": ["\"I never ___ moor\": Emily Dickinson", "\"I thought I ___ ...\""],
  "enls": [
    "Photo blowups: Abbr.",
    "Big pictures: Abbr.",
    "Requests for developers: Abbr."
  ],
  "sher": ["\"Fer ___!\"", "\"Fer ___!\""],
  "cdrs": ["Mil. leaders", "Capts.' subordinates", "U.S.N. officers"],
  "aran": [
    "Ireland's ___ Islands",
    "___ Islands, group at the mouth of Galway Bay",
    "Group of Irish islands in quarantine",
    "Ireland's ___ Islands",
    "Galway Bay's ___ Islands",
    "___ Islands of Galway Bay",
    "___ Islands, west of Ireland",
    "Galway Bay's ___ Islands",
    "Whence Flaherty's man",
    "Galway Bay's ___ Islands",
    "Flaherty's \"Man of ___\"",
    "Classic documentary \"Man of ___\"",
    "Ireland's___Islands",
    "Ireland's___Islands",
    "Galway Bay's ___ Island",
    "Ireland's ___ Island",
    "Flaherty's \"Man of ___\"",
    "Galway Bay isles",
    "Flaherty's \"Man of ___\"",
    "Ireland's ___ Islands"
  ],
  "ajet": [
    "\"Leaving on ___ Plane\"",
    "\"Leaving on ___ Plane\"",
    "Answer to the riddle \"The higher it goes, the less you hear it\""
  ],
  "teut": ["German: Abbr.", "German: Abbr.", "Ger.", "German: Abbr."],
  "mell": [
    "\"Momma\" cartoonist Lazarus",
    "Pell-___",
    "Cartoonist Lazarus",
    "Pell-___",
    "Pell-___",
    "Cartoonist Lazarus",
    "Pell-___"
  ],
  "dopa": [
    "L-___ (Parkinson's treatment)",
    "L-___ (treatment for parkinsonism)",
    "Neurotransmitter amino acid"
  ],
  "torr": ["Unit of pressure", "Unit of pressure"],
  "blas": [
    "Lesage book \"Gil ___\"",
    "Lesage hero Gil ___",
    "Panama's San ___ Islands",
    "Gulf of San ___ (Caribbean Sea inlet)",
    "Longfellow's \"The Bells of San ___\"",
    "\"Gil ___\"",
    "Gulf of San ___, east of the Panama Canal",
    "San ___ (Caribbean gulf)",
    "\"Gil ___\"",
    "Le Sage's \"Gil ___\"",
    "\"Gil ___\"",
    "\"Gil ___\"",
    "Le Sage's \"Gil ___\""
  ],
  "inow": [
    "\"___ pronounce you man and wife\"",
    "\"___ pronounce you ...\"",
    "\"___ pronounce...\""
  ],
  "grot": [
    "Small cave, poetically",
    "Small cavern, in poetry",
    "Cave, in poetry",
    "Cavern",
    "Cavern, in poetry"
  ],
  "oron": [
    "\"___ Diana's altar to protest\": Shak.",
    "\"___ Diana's altar to protest\": Shak.",
    "\"Straight ___ the rocks?\""
  ],
  "essy": ["Swedish actress Persson"],
  "soas": [
    "In order (to)",
    "In order (to)",
    "With the intent",
    "In order (to)",
    "In order (to)",
    "In order",
    "In order (to)",
    "In order (to)",
    "With the intent",
    "In order",
    "___ to say"
  ],
  "mmix": ["Year of the swine flu epidemic", "Time of Obama's swearing-in"],
  "rana": [
    "___ close second (almost won)",
    "\"The young lambs ___ pretty race\": Wordsworth",
    "___ temperature (was feverish)",
    "___ deficit (lost money)",
    "Frog genus that's Spanish for \"frog\"",
    "___ fever (was sick)",
    "___ fever",
    "Words before tab or temperature",
    "Indian prince"
  ],
  "nara": [
    "First capital of Japan",
    "Former Japanese capital",
    "Japan's first capital",
    "Japan's first capital",
    "Former Japanese capital",
    "Japan's first capital",
    "Japan's first capital"
  ],
  "lxiv": ["Year of the Great Fire of Rome", "VIII x VIII"],
  "ency": [
    "Suffix with depend",
    "Suffix with consist",
    "Ref. set",
    "Reference work: Abbr."
  ],
  "ohbe": ["\"___ quiet!\""],
  "sain": [
    "Bygone pitching star Johnny",
    "Spahn teammate Johnny",
    "1940's-50's All-Star Johnny",
    "1940's-50's All-Star Johnny",
    "Pitching coach Johnny",
    "Johnny of the '40s Braves",
    "Pitcher Johnny"
  ],
  "vito": [
    "Don Corleone",
    "Don Corleone",
    "Marlon's Oscar-winning role",
    "The head Corleone",
    "One of the Corleones",
    "Don Corleone"
  ],
  "dirs": [
    "47-Across personnel: Abbr.",
    "Ones who make scenes?: Abbr.",
    "Board members: Abbr."
  ],
  "ihad": [
    "Harry James's \"___ the Craziest Dream\"",
    "\"If ___ my druthers ...\"",
    "\"___ no idea!\"",
    "\"If ___ a Hammer\"",
    "\"If ___ You\" (#1 Alabama song)",
    "\"___ no idea!\"",
    "\"___ no idea!\"",
    "\"___ no idea!\"",
    "\"___ the Craziest Dream\" (1942 song)",
    "\"If ___ a nickel...\"",
    "\"___ no idea!\"",
    "Harry James's \"___ the Craziest Dream\"",
    "\"If ___ a Hammer\"",
    "\"If___a nickel...\"",
    "\"If ___ a Hammer\"",
    "'56 song \"If ___ My Druthers\"",
    "\"If___You\" (1929 hit)",
    "'64 musical \"___a Ball\""
  ],
  "skal": ["Swedish toast", "Swedish toast"],
  "alow": [
    "On a deck beneath",
    "Down from the deck",
    "Strike ___ blow",
    "\"What ___ blow!\"",
    "Under the decks",
    "\"That's ___ blow!\"",
    "In the hold, nautically"
  ],
  "gare": [
    "French rail station",
    "Paris's ___ de Lyon",
    "\"___ Saint-Lazare\" (Manet painting)"
  ],
  "esel": [
    "Donkey, in Dusseldorf",
    "Dusseldorf donkey",
    "Dusseldorf donkey",
    "German donkey",
    "German donkey"
  ],
  "onna": [
    "Tony-nominated choreographer White",
    "\"Oliver!\" choreographer White",
    "Choreographer White",
    "Choreographer White of \"The Music Man\""
  ],
  "bast": ["Cord material", "Cordage fiber", "Rope fiber", "Strong fiber"],
  "shew": [
    "Reveal, to Shakespeare",
    "Sullivan had a really big one",
    "Sullivan had a really big one",
    "Sullivan's \"really big\" one"
  ],
  "anun": [
    "Faulkner's \"Requiem for ___\"",
    "Faulkner's \"Requiem for ___\"",
    "Faulkner's \"Requiem for ___\""
  ],
  "goit": ["___ alone (have no help)", "___ alone (fly solo)"],
  "ures": ["Actress Mary and musician Midge", "Actress Mary et al."],
  "ceta": [
    "Job legislation estab. in 1973",
    "Job legislation estab. in 1973",
    "Govt. program for the unemployed"
  ],
  "mlvi": [
    "10 years before the Battle of Hastings",
    "Year in Edward the Confessor's reign",
    "X years before Hastings"
  ],
  "sems": [
    "Coll. terms",
    "Theological schools: Abbr.",
    "Theological schools: Abbr.",
    "Sch. periods",
    "Informative mtgs.",
    "Grad sch. classes",
    "Academic periods: Abbr."
  ],
  "sprs": ["Colo. ___, Colo.", "Spa: Abbr."],
  "orfe": [
    "Ornamental pond fish",
    "Golden pond fish",
    "Golden fish stocked in ornamental pools",
    "Fish stocked in ornamental pools"
  ],
  "pter": [
    "Wing: Prefix",
    "Feather: Prefix",
    "Wing: Prefix",
    "Wing: Prefix",
    "Wing: Prefix",
    "Feather: Prefix",
    "Feather: Prefix"
  ],
  "bulk": [
    "Major portion",
    "Mail may be sent in this",
    "Like some mail",
    "Kind of mail"
  ],
  "cony": ["Rabbit fur", "Rabbit fur", "Rabbit"],
  "pred": ["Sentence segment: Abbr.", "Subj. follower", "Sentence part: Abbr."],
  "clii": [
    "Second-century year",
    "Roman 152",
    "Roman 152",
    "Mid-2nd-century date",
    "Second-century date"
  ],
  "omei": ["Sacred Buddhist mountain", "Buddhists' sacred peak"],
  "lact": [
    "Milk: Prefix",
    "Opening for milk?",
    "Milk: Prefix",
    "Milk: Prefix",
    "Milk: Prefix",
    "Milk: Prefix",
    "Dairy prefix",
    "Milk: Prefix"
  ],
  "nasd": [
    "Former Wall St. letters",
    "Former Wall St. inits.",
    "Former stock regulating org.",
    "Brokerage initialism"
  ],
  "ayer": [
    "Yesterday, in the Yucatan",
    "Philosopher who promoted logical positivism",
    "Manana's opposite",
    "Philosopher A.J. ___"
  ],
  "tork": ["The blond Monkee", "One of the Monkees", "Peter of the Monkees"],
  "meas": ["Tsp. or tbsp."],
  "huis": ["Sartre's \"___ clos\"", "Sartre's \"___ Clos\""],
  "dail": ["___ Eireann (Irish legislative assembly)", "Dublin's legislature"],
  "zarf": ["Cup holder", "Cup holder"],
  "lgth": ["Width's opposite: Abbr.", "Geom. measure"],
  "decs": ["Fourth qtr. enders", "Christmas times: Abbr."],
  "acto": [
    "Part of a Spanish play",
    "Part of a Spanish play",
    "Part of a Spanish play"
  ],
  "eyck": [
    "Flemish painter Jan van ___",
    "Flemish painter Jan van ___",
    "... Jan van ___"
  ],
  "lyte": [
    "Suffix with electro-",
    "Suffix with electro-",
    "Suffix with electro-",
    "Suffix with electro-",
    "Hydro- or electro- follower"
  ],
  "adry": [
    "\"___ White Season\" (Andre Brink novel)",
    "Not ___ eye in the house",
    "\"Not ___ eye ...\"",
    "Not ___ eye in the house"
  ],
  "dlii": [
    "Roman 552",
    "Mid sixth-century year",
    "552, on a cornerstone",
    "Sixth-century year",
    "Sixth-century year",
    "Year the Ostrogoths were defeated at the Battle of Taginae",
    "552, in old Rome",
    "Year in Justinian I's reign",
    "6th-century year",
    "Diocletian's 552",
    "Half of MCIV",
    "Sixth-century date",
    "Sixth-century date",
    "Mid-sixth century date",
    "Half of MCIV"
  ],
  "stum": ["Unfermented grape juice", "Wine additive"],
  "sura": [
    "Koran chapter",
    "Any chapter of the Koran",
    "Any chapter of the Koran",
    "Any chapter of the Koran"
  ],
  "devi": ["Supreme Hindu goddess", "Siva's consort, in Hinduism"],
  "buon": ["\"___ appetito!\"", "\"___ giorno!\""],
  "waah": ["Crib cry", "Cry from a crib", "Crib cry"],
  "elys": [
    "Golf innovator Callaway and bridge maven Culbertson",
    "Country rocker Joe and others",
    "Tarzan player Ron and others",
    "Bridge expert Culbertson and others",
    "Country rocker Joe et al.",
    "TV Tarzan's kin",
    "Mr. Culbertson and others"
  ],
  "osas": ["She-bears, south of the border", "Cub bearers, in Spain"],
  "scop": [
    "Old English bard",
    "Old English bard",
    "Old English poet",
    "Old English bard"
  ],
  "rhet": ["Orator's skill: Abbr.", "Effective use of lang."],
  "ofla": ["\"Man ___ Mancha\"", "\"Man ___ Mancha\"", "\"Man ___ Mancha\""],
  "wahl": [
    "\"Wiseguy\" actor Ken",
    "\"Wiseguy\" actor Ken",
    "Actor Ken of TV's \"Wiseguy\""
  ],
  "hove": [
    "Hoisted, as a sail",
    "Moved on the ocean?",
    "Hoisted, nautically",
    "Moved, nautically speaking",
    "Raised nautically"
  ],
  "luka": ["1987 Suzanne Vega hit"],
  "batt": [
    "Quilt filler",
    "Matted cotton sheet",
    "Sheet of matted fabric used in quilting",
    "Santa suit stuffing",
    "Sheet of matted wool"
  ],
  "peur": ["Fear, to Francois", "Fear, to Francois"],
  "atka": [
    "Aleutian island",
    "Aleutian island",
    "One of the Aleutians",
    "Aleutian island",
    "Aleutian island",
    "Aleutian island",
    "One of the Aleutians",
    "Aleutian island",
    "Aleutian island",
    "Aleutian island"
  ],
  "deps": ["Passbook amts.", "Passbook amts.", "Assts.", "Passbook amts."],
  "apoc": [
    "Part of some Bibles: Abbr.",
    "Grp. of books in the Bible",
    "Part of the Bible: Abbr.",
    "Part of the Bible: Abbr.",
    "Bk. of Revelation"
  ],
  "cess": [
    "British tax",
    "Lead-in to pipe or pit",
    "British tax",
    "Tax, in Tottenham",
    "Tax, in Britain",
    "British tax",
    "British tax",
    "British tax",
    "Start of dirty pool?",
    "Word with pool or pit",
    "British levy"
  ],
  "cobo": [
    "Detroit's ___ Center",
    "Detroit's ___ Arena",
    "Detroit's ___ Arena",
    "With 16-Across, where the Pistons used to play"
  ],
  "ismy": ["\"The Lord ___ shepherd ...\"", "\"The Lord ___ shepherd ...\""],
  "prov": ["Can. division", "P.E.I., e.g.", "B.C. or N.B."],
  "stuv": ["Alphabet quartet"],
  "eees": [
    "Some shoes ... and a feature of this puzzle's theme",
    "Clowns' shoe widths"
  ],
  "ilka": [
    "Chase in films",
    "Chase in films",
    "Actress Chase of \"Now, Voyager\"",
    "Actress Chase",
    "Chase of \"Now, Voyager\"",
    "Chase on stage",
    "Actress Chase",
    "Chase of old game shows",
    "Actress Chase",
    "With 1-Across, star of Broadway's \"The Women,\" 1936",
    "Novelist Chase",
    "Chase in the movies",
    "Chase of \"Now, Voyager\"",
    "Actress Chase",
    "Actress Chase",
    "Actress Chase",
    "\"Casablanca\" actress Gruning",
    "First name in the \"Now, Voyager\" cast",
    "Actress Chase",
    "Actress Chase",
    "Actress Chase",
    "She was Sylvia in Broadway's \"The Women\"",
    "Actress Chase",
    "Actress Chase",
    "Chase of Tinseltown",
    "Movie Chase"
  ],
  "cres": [
    "Increase in volume, in mus.",
    "Musical score abbr.",
    "Mus. increase in volume",
    "Abbr. in a musical score",
    "Street: Abbr."
  ],
  "loye": [
    "Ravel's \"Ma Mere ___,\" a k a \"Mother Goose\"",
    "Ravel's \"Ma mere ___\" (\"Mother Goose\")"
  ],
  "mcxi": [1111, "Roman 1,111"],
  "ajob": [
    "Do ___ on",
    "\"Get ___!\"",
    "Do ___ on (work over)",
    "\"Get ___\" (doo-wop classic)",
    "\"This is ___ for Superman!\"",
    "\"This is ___ for Superman!\"",
    "\"Get ___\" (1957 hit)",
    "\"...___ for Superman!\"",
    "\"Get ___\" (1958 hit)"
  ],
  "welk": [
    "Onetime popular musician ... or a radio station where he might be heard?",
    "Lawrence of the North Dakota Hall of Fame",
    "\"Wunnerful, Wunnerful!\" autobiographer",
    "\"Champagne music\" maestro",
    "TV host, 1955-82"
  ],
  "mirv": [
    "Weapon with many warheads",
    "Versatile nuke",
    "Part of the U.S. nuclear arsenal",
    "Nuclear missile, briefly",
    "Nuclear missile"
  ],
  "tttt": [
    "Razz",
    "Torment",
    "Some socials",
    "Rib",
    "Sri Lankan exports",
    "Informal tops",
    "Plumbing joints",
    "Angers, with \"off\"",
    "Timeout signs"
  ],
  "nias": [
    "Actress Long and others",
    "Moviedom's Long and Vardalos",
    "Actress Long and others",
    "Peeples and Vardalos",
    "Vardalos and Peeples",
    "Actress Peeples et al."
  ],
  "tsgt": [
    "U.S.A.F. NCO",
    "Air Force noncom: Abbr.",
    "U.S.A.F. NCO",
    "Air Force NCO"
  ],
  "lvii": [
    "Super Bowl of 2023",
    "Roman 57",
    "Third year of Nero's reign",
    "Chapter 57",
    "Mid first-century year",
    "Number of Heinz flavors in old Rome?",
    "Chapter 57",
    "Heinz number, to Ovid"
  ],
  "kuhn": [
    "Former baseball commissioner Bowie",
    "Former baseball commissioner Bowie",
    "Former baseball commissioner Bowie ___",
    "Bowie the commish"
  ],
  "bdrm": [
    "Apt. part",
    "Abbr. in the real estate section",
    "Real estate ad abbr."
  ],
  "mols": ["Compound fractions: Abbr.", "Chem. units", "Atomic groups: Abbr."],
  "apip": [
    "\"You're ___, ya know that?\": Archie Bunker",
    "\"You're ___!\" (Archie Bunker comment)"
  ],
  "ihit": ["\"___ the jackpot!\""],
  "modi": [
    "___ operandi (methods)",
    "___ operandi",
    "Ways of Caesar",
    "___ operandi (ways of working)",
    "___ operandi"
  ],
  "cays": [
    "Low islands",
    "A lot of the Bahamas",
    "Low islands",
    "Low islands",
    "Low islands",
    "Low islands",
    "Islets"
  ],
  "dois": [
    "The Everly Brothers' \"All I Have to ___ Dream\"",
    "Two, in Lisbon",
    "Two, in Brazil"
  ],
  "jons": [
    "Stewart and Lovitz",
    "Singer Anderson and others",
    "Voight and others",
    "Rock's Bon Jovi and others"
  ],
  "wold": ["Chain of treeless rolling hills", "Elevated, open country"],
  "kpax": ["Title planet in a 2001 Kevin Spacey movie"],
  "onmy": [
    "\"___ Own\" (song from \"Les Miz\")",
    "\"Time Is ___ Side\" (Rolling Stones hit)",
    "Start of the Boy Scout Oath",
    "\"___ Own\" (song from \"Les Miz\")",
    "\"Gentle ___ Mind\" (country classic)",
    "\"___word...\""
  ],
  "jaye": ["\"The Gong Show\" panelist ___ P. Morgan"],
  "nagy": ["Hungarian patriot Imre ___", "Hungary's Imre ___"],
  "nard": [
    "Source of a fragrant oil",
    "Olden ointment",
    "Ancient fragrance",
    "Aromatic herb used by the ancients",
    "Plant used in ancient ointments",
    "Aromatic herb"
  ],
  "gute": [
    "\"___ Nacht\" (German words of parting)",
    "\"___ Nacht\" (German words of parting)"
  ],
  "etla": ["See 28-Down", "Ici ___ (here and there, to Therese)"],
  "harz": ["Germany's __ National Park", "Germany's ___ Mountains"],
  "cdiv": [
    "Year of the last known Roman gladiator competition",
    "404 in old Rome",
    "404, in old Rome"
  ],
  "jefe": ["Baja boss", "Mexican leader", "Castro, for one"],
  "pers": ["Asian lang.", "Kind of pronoun: Abbr.", "Kind of pronoun: Abbr."],
  "brom": [
    "___ Bones of \"The Legend of Sleepy Hollow\"",
    "___ Bones, Ichabod Crane's rival",
    "___ Bones (Ichabod Crane's rival)"
  ],
  "hebe": [
    "Greek goddess of youth",
    "Nectar-pouring goddess",
    "Daughter of Zeus",
    "Daughter of Hera and Zeus"
  ],
  "idae": ["Family name suffix in taxonomy", "Zoological name suffix"],
  "ethn": ["People: Prefix", "People: Prefix", "People: Prefix"],
  "lada": [
    "Russian car make",
    "Russian car",
    "Russian car",
    "Russian auto make",
    "Terhune's \"___ Dog\"",
    "Terhune's \"___ Dog\"",
    "Terhune's \"___ Dog\""
  ],
  "visc": [
    "Baron's superior: Abbr.",
    "Baron's superior: Abbr.",
    "Earl's inferior: Abbr."
  ],
  "fils": ["Son, at the Sorbonne", "Louis I, to Charlemagne", "Junior Dumas"],
  "muds": [
    "Oozy mixtures",
    "Some spa mixtures",
    "Stirs up the sediment in",
    "Mucks",
    "Flood residues"
  ],
  "saml": [
    "___ Adams, signature on the Declaration of Independence",
    "With 72-Down, a signature on the Declaration of Independence",
    "Heb. judge"
  ],
  "inro": ["Obi accessory", "Japanese lacquer box"],
  "ogpu": [
    "Forerunner of the K.G.B.",
    "K.G.B. predecessor",
    "K.G.B. predecessor",
    "Old Soviet secret police org.",
    "K.G.B. predecessor",
    "Old Russian secret-police org.",
    "Lenin's police org."
  ],
  "mfor": ["\"Dial ___ Murder\"", "\"Dial ___ Murder\""],
  "ance": [
    "Clear conclusion?",
    "Suffix with govern",
    "Utter conclusion?",
    "Ending to avoid?",
    "Ending with sever or suffer",
    "Suffix with accept",
    "Ending to avoid?",
    "Suffix with utter",
    "Suffix with avoid",
    "Suffix with utter",
    "Clear finish?",
    "Clear finish"
  ],
  "apet": [
    "Irritated, after \"in\"",
    "Miffed, after \"in\"",
    "Agitated, after \"in\"",
    "In ___ (worked up)",
    "Ticked off, after \"in\"",
    "Sullen, after \"in\"",
    "Miffed, after \"in\"",
    "Adopt-___",
    "Peeved, after \"in\"",
    "Peeved, after \"in\"",
    "In ___ (sulking)"
  ],
  "toma": [
    "Tony Musante TV series",
    "1970's Tony Musante series",
    "1970's Tony Musante series",
    "70's TV detective",
    "Tony Musante TV series"
  ],
  "ryun": [
    "Miler turned congressman",
    "Track star elected to Congress in 1996"
  ],
  "abug": [
    "Put ___ in one's ear",
    "As snug as ___ ...",
    "\"Snug as ___ ...\"",
    "Put ___ in one's ear",
    "Put ___ in one's ear",
    "\"Snug as ___...\""
  ],
  "incr": [
    "Elevation: Abbr.",
    "Enlargement: Abbr.",
    "Go up: Abbr.",
    "Enlargement: Abbr."
  ],
  "dorp": ["Hamlet", "Hamlet", "Hamlet", "Village"],
  "shhh": ["\"Zip it\""],
  "sqyd": ["Wallpaper meas.", "3 ft. by 3 ft.", "Carpet meas."],
  "conf": ["Part of the N.C.A.A.'s purview: Abbr.", "Athletic assoc."],
  "narz": ["Onetime \"Concentration\" host Jack"],
  "bopp": ["Hale-___ (comet seen in 1997)", "Hale-___ comet"],
  "pase": ["Matador's move", "Matador maneuver"],
  "dist": ["Kind of atty.", "Mile or kilometer: Abbr.", "Kind of atty."],
  "hita": ["Words with snag or home run", "___ run of bad luck"],
  "conc": ["Solution strength: Abbr.", "Regarding: Abbr."],
  "acog": [
    "Slip ___ (blunder)",
    "Slip ___",
    "Slip ___ (blunder)",
    "Slip ___ (err)",
    "Slip ___ (blunder)"
  ],
  "azan": [
    "Mideast call to prayer",
    "Muezzin's call to prayer",
    "Call from the minaret",
    "Muezzin's call to prayer",
    "Muezzin's call at a mosque",
    "Call from the minaret",
    "Call from a minaret",
    "Muslim call to prayer"
  ],
  "sian": [
    "___ Phillips, who played Livia in \"I, Claudius\"",
    "Actress Phillips of \"I, Claudius\""
  ],
  "puta": ["___ hold on", "___ hold on", "\"___ lid on it!\""],
  "dcvi": ["Year of Pope Sabinian's death", "7th-century date"],
  "rina": [
    "Actress Morelli of \"The Leopard,\" 1963",
    "Femme fatale in \"The Carpetbaggers\""
  ],
  "gumm": [
    "Judy Garland's real last name",
    "Judy Garland's real surname",
    "Judy Garland's real last name"
  ],
  "rics": [
    "Rocker Ocasek and others",
    "Wrestler Flair and others",
    "Singer Ocasek et al."
  ],
  "vier": [
    "Number between drei and funf",
    "Rival",
    "Contestant",
    "Contender",
    "One in a heat",
    "Contender",
    "Competitor"
  ],
  "linz": [
    "Capital of Upper Austria",
    "Mozart's ___ Symphony (No. 36)",
    "Austrian city on the Danube"
  ],
  "winy": ["Having a taste of the grape", "Having a bouquet, maybe"],
  "coll": ["Univ.", "Follower of H.S."],
  "bref": ["Concise, in Cannes", "Short: Fr."],
  "reas": [
    "Actor Stephen and kin",
    "Annual short-story awards since 1986",
    "Actor Stephen et al."
  ],
  "spic": [
    "___ and Span",
    "___ and Span (cleanser)",
    "___ and Span",
    "___ and Span (cleaner brand)"
  ],
  "yack": ["Talk idly", "Talk, talk, talk: Var.", "Gab"],
  "ambo": ["Early pulpit", "Early pulpit", "Early pulpit"],
  "ohne": [
    "\"Die Frau ___ Schatten\" (Strauss opera)",
    "\"Die Frau ___ Schatten\" (Strauss opera)"
  ],
  "tono": [
    "Up ___ good",
    "___ avail",
    "___ end",
    "___ avail",
    "Up ___ good",
    "\"___-Bungay\" (Wells novel)",
    "Up ___ good",
    "Up ___ good",
    "\"___-Bungay\" (Wells novel)",
    "___ avail",
    "Up ___ good",
    "___ avail",
    "___ avail (fruitless)"
  ],
  "abad": [
    "\"Born Under ___ Sign\" (1960s blues album)",
    "In ___ way",
    "In ___ way",
    "In ___ way",
    "Have ___ hair day"
  ],
  "saco": [
    "River or city of Maine",
    "Maine/New Hampshire river",
    "White Mountains river"
  ],
  "dien": [
    "___ Bien Phu, Vietnam",
    "___Bien Phu, Vietnam",
    "___ Bien Phu (1954 battle site)"
  ],
  "tchr": ["Sch. staffer", "N.E.A. member", "N.E.A. member: Abbr."],
  "dcli": ["Seventh-century year", "Mid seventh-century date"],
  "zasu": [
    "Pitts of silent film",
    "Early film actress Pitts",
    "Pitts of old films",
    "Pitts of Hollywood",
    "Pitts of old Hollywood",
    "Pitts of \"Life With Father\"",
    "Actress Pitts",
    "Pitts of old films",
    "Actress Pitts of old films",
    "Pitts of \"Life With Father\"",
    "Actress Pitts"
  ],
  "dxii": [
    "Year of St. Genevieve's death",
    "Early sixth-century date",
    "CCLVI doubled"
  ],
  "freq": ["Radio no.", "Part of UHF: Abbr."],
  "moya": ["1998 French Open winner Carlos ___"],
  "lati": [
    "Scale succession",
    "Followers of so",
    "They follow so",
    "Sol-do bridge"
  ],
  "bcde": [
    "A following?",
    "A following?",
    "A following",
    "Four after the first?",
    "A train?",
    "A following?"
  ],
  "wyss": [
    "\"The Swiss Family Robinson\" author Johann",
    "Johann who wrote the Swiss national anthem",
    "\"Swiss Family Robinson\" author",
    "\"Swiss Family Robinson\" author Johann",
    "\"Swiss Family Robinson\" writer",
    "\"Swiss Family Robinson\" author"
  ],
  "surg": ["Like some instruments: Abbr.", "O.R. doings"],
  "roly": [
    "___-poly",
    "___-poly",
    "___-poly",
    "___-poly",
    "___-poly",
    "___-poly",
    "___-poly",
    "___-poly"
  ],
  "micr": ["Minute: Prefix", "Little: Prefix"],
  "exch": ["Kind of rate in a bank: Abbr.", "Trading place: Abbr."],
  "coli": ["E. ___ (health menace)", "E. ___"],
  "jato": [
    "Mil. plane's boosted launch",
    "Military flier's acronym",
    "Vertical departure, acronymically"
  ],
  "malo": [
    "Opposite of bueno",
    "Bueno's opposite",
    "Bad, in Bogota",
    "Saint-___ (French seaport)",
    "St. ___ (Channel port)",
    "Saint ___, France",
    "St.-___, France"
  ],
  "mots": [
    "Cracks",
    "Zingers",
    "Parts of un livre",
    "___ justes",
    "Zingers",
    "Witty sayings",
    "Memorable lines",
    "\"Bon\" words",
    "Zingers",
    "Witticisms"
  ],
  "tzar": [
    "Russian autocrat: Var.",
    "Russian monarch: Var.",
    "Russian ruler: Var.",
    "Palace ruler: Var.",
    "Autocrat: Var.",
    "Former Winter Palace resident: Var."
  ],
  "mome": [
    "\"And the ___ raths outgrabe\" (\"Jabberwocky\" line)",
    "\"Jabberwocky\" word"
  ],
  "kiki": ["Vandeweghe of the N.B.A.", "1970's singer ___ Dee"],
  "caul": [
    "Harry ___, Gene Hackman role in \"The Conversation\"",
    "Net lining in a woman's hat"
  ],
  "clvi": ["Mid second-century date", "Ovid's 156"],
  "beme": ["\"It's Not Easy to ___\"", "\"Let It ___ \" (Everly Brothers hit)"],
  "eccl": [
    "Book after Proverbs: Abbr.",
    "Book after Proverbs: Abbr.",
    "Book after Proverbs: Abbr.",
    "O.T. book of teachings",
    "Churchly: Abbr.",
    "Book after Proverbs: Abbr.",
    "O.T. book"
  ],
  "noit": ["\"___ can't be!\""],
  "eria": [
    "Ending with ranch",
    "Suffix with ranch",
    "Emporium suffix",
    "Italian emporium ending",
    "Assam silkworm"
  ],
  "ladi": ["___-da (pretentious)"],
  "exim": ["___ Bank (U.S. loan guarantor)"],
  "hiyo": ["\"___ Silver, away!\"", "Silver spur?", "Silver spur, so to speak"],
  "xlii": ["Forum 42", "2008 Super Bowl number"],
  "arug": [
    "Cut ___ (dance)",
    "Cut ___",
    "Cut ___",
    "Cut ___ (dance)",
    "Cut ___",
    "Cut ___",
    "Cut ___",
    "\".... as a bug in ___\""
  ],
  "dcii": [
    "Early seventh-century year",
    "Seventh-century year",
    "A fifth of MMMX",
    "Year after Augustine became the first Archbishop of Canterbury",
    "Seventh-century date"
  ],
  "noop": [
    "Informal denial",
    "Colloquial denial",
    "\"Are you nervous?\" response, a la Don Knotts"
  ],
  "ente": ["Duck: Ger.", "Duck: Ger."],
  "areo": [
    "Mars: Prefix",
    "Mars: Prefix",
    "Mars: Prefix",
    "Mars, in combinations",
    "Mars: Prefix",
    "Mars: Prefix",
    "Mars, in combinations"
  ],
  "pqrs": ["7, on modern phones", "OT filler", "OT filler", "Alphabetic run"],
  "seea": [
    "\"I ___ bad moon rising\"",
    "___ movie",
    "Words before ghost or doctor",
    "\"Did You Ever ___ Dream Walking?\" (1933 hit)",
    "\"I ___ Dark Stranger\" (Deborah Kerr film)",
    "\"Did you ever ___ lassie...?\"",
    "\"___ pin and pick it up...\"",
    "___ ghost",
    "\"Did You Ever ___ Lassie?\"",
    "\"___ pin...\"",
    "\"Did you ever ___ lassie...?\""
  ],
  "fata": [
    "___ morgana (mirage)",
    "___ morgana (mirage)",
    "___ morgana (mirage)",
    "___ morgana (illusion)",
    "___ morgana (mirage)"
  ],
  "parl": ["Lord's body: Abbr.", "Legislative grp."],
  "lisi": [
    "Actress Virna",
    "Virna ___ of \"La Reine Margot\"",
    "Actress Virna",
    "Actress Virna ___",
    "\"How to Murder Your Wife\" star",
    "Actress Virna",
    "\"How to Murder Your Wife\" star Virna",
    "\"The Statue\" actress Virna",
    "Actress Virna ___",
    "Actress Virna"
  ],
  "brau": ["Oktoberfest serving", "Meister ___ (beer brand)"],
  "syll": ["One of three in eleven: Abbr.", "Word div."],
  "boaz": [
    "Great-grandfather of David",
    "Husband of Ruth, in the Bible",
    "Husband of Ruth",
    "Husband of Ruth",
    "Ruth's husband"
  ],
  "mfrs": ["G.M. and G.E.", "They have plants: Abbr."],
  "lour": ["Frown", "Frown"],
  "mego": ["\"Let ___!\"", "\"Let ___!\""],
  "qrst": ["Alphabet run", "Alphabet quartet"],
  "lxii": [
    "Year in Nero's reign",
    "Year the empress Octavia was murdered",
    "Year the Roman writer Persius died"
  ],
  "osip": [
    "Russian poet ___ Mandelstam",
    "Poet Mandelstam",
    "Soviet poet Mandelstam",
    "Poet Mandelstam"
  ],
  "calx": ["Mineral residue", "Metal oxide", "Mineral residue"],
  "qoph": ["Letter before resh", "Hebrew letter before resh"],
  "wert": [
    "Van ___ (Ohio county or its seat)",
    "Robert Burns's \"O, ___ Thou in the Cauld Blast\"",
    "Archaic form of \"to be\"",
    "Biblical verb",
    "Didst exist"
  ],
  "phar": [
    "\"___ Lap\" (1983 film)",
    "Rx dispenser: Abbr.",
    "\"___ Lap\" (1983 film)"
  ],
  "cavy": ["South American rodent", "Guinea pig"],
  "doak": [
    "1940's-50's football legend ___ Walker",
    "Football Hall-of-Famer ___ Walker"
  ],
  "mcmi": ["Year Theodore Roosevelt took office", "Year Queen Victoria died"],
  "pock": ["Small indentation", "Small indentation", "Kind of mark"],
  "ciii": ["Early second-century year", "2nd-century date"],
  "yean": [
    "Birth",
    "Have a kid",
    "Bear young, as sheep",
    "Give birth, as a sheep",
    "Give birth on the farm"
  ],
  "arpa": ["Angelo's instrument", "It's plucked in Parma"],
  "duro": [
    "Spanish peso",
    "Hard, in Havana",
    "Spanish peso",
    "Spanish silver dollar",
    "Old Spanish coin",
    "Five-peseta coin",
    "Spanish peso"
  ],
  "pais": ["Spanish newspaper El ___", "Espana, por ejemplo"],
  "bapt": ["Protestant denom.", "Bill Clinton, for one: Abbr."],
  "redi": [
    "Quick, in trade names",
    "Quick, in trade names",
    "___-Tea (first instant iced tea)",
    "Commercial prefix meaning \"convenient to use\""
  ],
  "heis": ["\"___ an Englishman\" (\"H.M.S. Pinafore\" song)"],
  "abid": ["Put ___ on (go for at auction)", "Put in ___ for"],
  "ifor": ["\"___ one ?à\" (opiner's opening)"],
  "tnpk": ["Expy.", "It takes its toll: Abbr."],
  "rale": [
    "Sound heard through a stethoscope",
    "Breathing abnormality",
    "Rough breathing",
    "Breathing sound",
    "Bronchial sound",
    "Chest rattle",
    "Smoker's sound"
  ],
  "oris": [
    "\"___ my flesh of brass?\": Job",
    "\"... ___ it me?\"",
    "\"...___ You Ain't My Baby?\" (1944 hit)"
  ],
  "onos": ["Lennon's in-laws", "Lennon's in-laws", "Lennon's in-laws"],
  "lons": ["Chaney and others", "Chaney Jr. and Sr.", "Chaney Jr. and Sr."],
  "conv": ["Quadrennial polit. event", "Party mtg."],
  "coir": [
    "Coconut fiber",
    "Rope fiber",
    "Fiber made from coconuts",
    "Coconut fiber"
  ],
  "ofoz": [
    "\"The Wizard ___\"",
    "End of many L. Frank Baum titles",
    "\"The Wizard ___\"",
    "End of a Baum title",
    "\"The Wizard___\""
  ],
  "ccii": [
    "Roman year with \"two pair\"",
    "Third-century year",
    "202, to Tiberius",
    "Third-century date"
  ],
  "nera": ["Tiber tributary whose name means \"black\"", "Tiber tributary"],
  "spqr": ["Caesar's empire, for short", "Initials in ancient Rome"],
  "joed": ["Outfield mate of Scooter, of the 40's-50's Yankees"],
  "rede": ["Counsel, old-style", "Counsel, in English dialect"],
  "peau": ["___ de soie (silk cloth)", "___ de soie (silk cloth)"],
  "corr": [
    "Not wrong: Abbr.",
    "Field reporter: Abbr.",
    "Fixed by an ed.",
    "Letter writing: Abbr."
  ],
  "alug": ["Chug follower", "Chug-___", "Chug-___"],
  "sapp": [
    "N.F.L. defensive tackle Warren",
    "Warren ___, 1999 N.F.L. Defensive Player of the Year",
    "Defensive tackle Warren ___"
  ],
  "lits": [
    "Wagons-___ (sleeping cars, abroad)",
    "Wagons-___ (sleeping cars)",
    "Wagons-___",
    "Wagons-___"
  ],
  "ridi": [
    "\"___ Pagliaccio\"",
    "\"___, Pagliaccio\" (aria)",
    "\"___, Pagliaccio\"",
    "\"___, Pagliaccio\""
  ],
  "uncs": [
    "Family members, affectionately",
    "Aunties' husbands",
    "Family members, informally"
  ],
  "bons": ["___ mots", "___marches (bargains)"],
  "dliv": [
    "Mid sixth-century year",
    "Mid 6th-century year",
    "Year in Vigilius's papacy",
    "Sixth-century date"
  ],
  "cepe": ["French chef's mushroom", "French chef's mushroom"],
  "toth": ["1960's Cubs pitcher Paul"],
  "scup": ["Atlantic fish", "Atlantic food fish"],
  "diii": [
    "Early sixth-century year",
    "503, long ago",
    "Early 6th-century date",
    "Early sixth-century date"
  ],
  "lyne": [
    "\"Fatal Attraction\" director Adrian",
    "\"Unfaithful\" director Adrian",
    "\"Flashdance\" director Adrian",
    "\"Fatal Attraction\" director Adrian"
  ],
  "wctu": ["Teetotalers?Æ org.", "Grp. known for its dry comments?"],
  "anoa": [
    "Wild Indonesian bovine",
    "Small forest buffalo",
    "Exotic zoo animal",
    "Celebes ox"
  ],
  "riva": [
    "Racehorse ___ Ridge",
    "___ Ridge (1972 Derby winner)",
    "___ Ridge (racehorse)",
    "1972 Kentucky Derby winner ___ Ridge",
    "1972 Kentucky Derby winner ___ Ridge",
    "Horse-racing Hall-of-Famer ___ Ridge",
    "1972 Kentucky Derby winner ___ Ridge"
  ],
  "jans": [
    "Van Eyck and Vermeer",
    "Peerce and Smuts",
    "Comedienne Hooks et al."
  ],
  "aras": [
    "Principal river of Armenia",
    "First king of Phliasia, in myth",
    "Parseghian et al.",
    "Mr. Parseghian and others",
    "First king of Phliasia, in myth"
  ],
  "tret": [
    "Waste allowance",
    "Waste allowance",
    "Waste allowance of old",
    "Waste allowance",
    "Weight allowance"
  ],
  "ghat": [
    "Steps down to a river, in India",
    "Steps leading down to the Ganges"
  ],
  "caco": ["Bad: Prefix", "Bad: Prefix", "Bad: Prefix"],
  "asie": ["Japon's place", "Indochine locale"],
  "uxor": [
    "Wife, in legalese",
    "Portia to Brutus, e.g.",
    "Pompeia, to Caesar",
    "Wife, in old Rome",
    "Wife, to Caesar",
    "Caeser's wife"
  ],
  "cume": ["G.P.A., slangily"],
  "jule": ["Composer Styne"],
  "acis": [
    "Victim of Polyphemus, in myth",
    "Galatea's love",
    "Galatea's love",
    "Galatea?Æs love",
    "Galatea's beloved",
    "Handel's \"___ and Galatea\""
  ],
  "luiz": [
    "Portuguese king, 1861-89",
    "King in G. & S.'s \"The Gondoliers\"",
    "Portuguese king, 1861-89"
  ],
  "awad": ["Make ___ (earn big bucks)", "Make ___ (get paid well)"],
  "pois": ["Petits ___ (French peas)", "Tropical pastes"],
  "edhs": ["Old English letters", "Old English letters", "Old English letters"],
  "dvii": [
    "507, on a slab",
    "Early sixth-century year",
    "Year in St. Symmachus's papacy"
  ],
  "tyee": ["Chinook salmon", "Prized salmon", "Prized Pacific salmon"],
  "liny": ["Wrinkled", "Ruled", "Streaked"],
  "lics": ["Hunters' needs: Abbr.", "Cabbies' credentials: Abbr."],
  "hasa": [
    "___ go at (tries)",
    "___ point (makes sense)",
    "\"Ford ___ better idea\" (old slogan)"
  ],
  "xvii": [
    "1983 Super Bowl designation",
    "The Louis whose mother was Marie Antoinette",
    "17, in old Rome"
  ],
  "lier": [
    "One who waits in ambush",
    "One with no standing?",
    "One who's recumbent"
  ],
  "mccc": ["1300 on a cornerstone", "Year in the reign of Edward I"],
  "firn": ["Granular snow atop a glacier", "Swiss snowfield"],
  "pavo": ["Peacock constellation", "Peacock in the sky"],
  "exer": ["Swimming laps, e.g.: Abbr."],
  "inly": ["Toward the center", "Deeply"],
  "veld": [
    "South African grassland",
    "Kruger National Park terrain",
    "African spread",
    "South African grassland"
  ],
  "novi": [
    "___ Sad (city on the Danube)",
    "___Sad (Yugoslavia's second-largest city)"
  ],
  "deon": ["\"The Cosby Show\" actor Richmond"],
  "gogi": [
    "___ Grant, \"The Wayward Wind\" singer, 1956",
    "Singer Grant with the 1956 #1 hit \"The Wayward Wind\""
  ],
  "whop": ["Hit hard", "Hit upside the head", "Loud punch"],
  "osee": ["Hebrew prophet in the Douay Bible", "Douay prophet"],
  "mlxi": ["Five years before the Battle of Hastings"],
  "hurd": [
    "Western scene painter Peter",
    "Former British Foreign Secretary Douglas"
  ],
  "kine": [
    "Old cattle",
    "Biblical herd",
    "Animals in Pharaoh's dream, in Genesis",
    "Cattle"
  ],
  "bemy": ["Start of a 2/14 request", "The Ronettes' \"___ Baby\""],
  "unch": [
    "Not up or down, as a stock price: Abbr.",
    "Neither up nor down, as a stock price: Abbr."
  ],
  "prin": ["High sch. disciplinarian", "Sch. bigwig"],
  "larf": [
    "1968 British comedy \"Only When I ___\"",
    "1968 British comedy \"Only When I ___\""
  ],
  "foys": ["Noted vaudeville family", "Vaudevillian family of note"],
  "emeu": ["Flightless bird: Var.", "Big bird: Var."],
  "ates": [
    "Suffixes with sultan",
    "Suffixes with consul",
    "Chemical suffixes",
    "Tropical fruit tree"
  ],
  "rcts": [
    "Gate: Abbr.",
    "Proofs of purchase: Abbr.",
    "Business records: Abbr."
  ],
  "homy": ["Comfortable, as accommodations", "Very comfortable"],
  "mcdi": ["Start of the 15th century"],
  "ules": ["Diminutive suffixes", "Diminutive suffixes"],
  "mcmx": ["Year George V took the throne", "1910, on cornerstones"],
  "mvii": ["Early second-millennium year", "Year in John XVIII's papacy"],
  "fitz": ["Irish surname starter", "Irish name part"],
  "mons": [
    "Starts of workweeks: Abbr.",
    "Labor Day and many other fed. holidays",
    "Feature of a lunar landscape"
  ],
  "offa": ["Easy as falling ___ log", "\"...___ short pier!\""],
  "plur": ["Of mice and men?: Abbr.", "Having more than one: Abbr."],
  "daba": ["\"The Aba ___ Honeymoon\"", "Song word after \"Aba\""],
  "pung": ["Boxlike sled", "Boxlike sleigh"],
  "faux": ["Not genuine, as gems", "Imitation", "Artificial"],
  "mibs": ["Marbles game", "Game of marbles"],
  "bung": ["Keg stopper", "Keg stopper"],
  "owly": ["Nearsighted", "Big-eyed"],
  "rept": ["Weather info: Abbr.", "CNN offering: Abbr."],
  "asop": [
    "\"Give ___ to Cerberus\" (Greek and Roman saying)",
    "Drippy",
    "Drenched",
    "Dripping wet"
  ],
  "ccci": [
    "Year in Diocletian's reign",
    "Old-fashioned 301",
    "Fourth-centruy date"
  ],
  "wona": ["___ prize (came in first)"],
  "cinc": ["George W. Bush, now: Abbr.", "U.S. Pres., militarily"],
  "bine": [
    "Twining stem",
    "Twining plant stem",
    "Twining plant stem",
    "Twining stem"
  ],
  "afee": ["Without ___ (pro bono)"],
  "mmmm": ["\"Tasty!\""],
  "fong": ["Former Hawaii senator Hiram", "Former Hawaii Senator Hiram"],
  "rhue": ["Actress Madlyn"],
  "vide": ["___ infra (see below)", "___ supra (see above)"],
  "iyar": ["Month after Nisan", "Month after Nisan"],
  "fugu": ["Japanese fish delicacy", "Japanese fish delicacy"],
  "vins": [
    "Medoc and others",
    "Cotes du Rhone and others",
    "Burgundy and Bordeaux"
  ],
  "adjt": ["Mil. staff officer", "Kind of gen."],
  "lifo": ["Accounting acronym", "Acctg. principle"],
  "coxa": ["Hip joint", "Hip joint"],
  "seme": [
    "Covered with many small figures, in heraldry",
    "Dotted, as a coat of arms"
  ],
  "phos": ["Light: Prefix", "Light: Prefix"],
  "ifni": ["Sidi ___, Morocco", "Sidi ___, Morocco"],
  "bals": ["Debutantes' affairs", "Nice shindigs"],
  "isar": [
    "Munich's river",
    "Bavarian river",
    "Munich's river",
    "River to the Danube",
    "Munich's river"
  ],
  "dyck": ["Painter Anthony Van ___", "Painter Anthony Van ___"],
  "levo": ["The L of L-dopa", "The L of L-dopa"],
  "ioof": ["B.P.O.E. cousin", "Initials on old meeting halls"],
  "rotl": ["Mideast dry measure", "Mideast weight"],
  "naso": ["Ovid's family name", "Publius Ovidius ___"],
  "weng": ["Memorable puzzle editor", "Colleague of Farrar and Maleska"],
  "proa": [
    "Swift Malay boat",
    "Swift Malay boat",
    "Malay boat",
    "Swift sailing boat"
  ],
  "asse": ["African fox", "Cape fox"],
  "pego": [
    "\"___ My Heart\"",
    "\"___ My Heart\"",
    "\"___ My Heart\" (1913 hit)"
  ],
  "emes": ["Scottish uncles", "Tayside tios"],
  "snye": ["Side-channel, in Canada"],
  "pern": ["Anne McCaffrey's dragon land", "Anne McCaffrey's dragon world"],
  "lely": [
    "\"The Windsor Beauties\" painter",
    "Sir Peter ___, painter of British royalty"
  ],
  "upsa": ["\"___-daisy!\"", "___-daisy"],
  "taxa": ["Biological classes", "Biological classifications"],
  "rojo": [
    "Like vino tinto",
    "Color of a Baja sunset",
    "Color in a Spanish sunset"
  ],
  "umas": [
    "Thurman et al.",
    "Actress Thurman and others",
    "Actress Thurman et al.",
    "Actress Thurman et al."
  ],
  "evoe": ["Ancient reveler's \"whoopee!\"", "Bacchanalian cry"],
  "akee": [
    "Tropical tree of the soapberry family",
    "Tropical fruit",
    "Tropical tree"
  ],
  "topi": ["Sun hat", "Pith helmet"],
  "perc": ["Orch. section", "Fringe benefit, for short: Var."],
  "veau": ["Cote de ___ (French entree)", "Cote de___ (French entree)"],
  "rape": [
    "\"The ___ of the Lock\"",
    "Rembrandt's \"___ of Ganymede\"",
    "Titian's \"The___of Europa\"",
    "Shakespeare's \"The ___ of Lucrece\"",
    "Rembrandt's \"The ___ of Europa\""
  ],
  "olid": ["Smelly", "Stinky"],
  "inny": ["Belly-button type"],
  "rula": ["Actress Lenska", "Pitchwoman ___ Lenska"],
  "waaf": ["W.W. II Brit. group", "Women's auxiliary of 60 Down: Abbr."],
  "cete": ["Badger group", "Group of badgers"],
  "bork": ["Jurist Robert"],
  "cras": ["Tomorrow, to Tacitus", "Tomorrow: Lat."],
  "spcc": ["Juvenile protection grp.", "Minor's advocacy grp."],
  "anza": ["San Francisco's founder Juan Bautista de___"],
  "iers": ["Occupational name endings", "Cash conclusions"],
  "egli": ["He, to Enrico", "He, to Loren"],
  "wass": ["Ted of TV's \"Blossom\""],
  "azon": ["Radio-controlled aerial bomb", "Radio-controlled aerial bomb"],
  "bogy": ["Mental barrier", "Frightener", "Hobgoblin"],
  "eral": ["Of historic time", "Of an age"],
  "maru": ["Japanese merchant ship", "Japanese merchant ship"],
  "olpe": ["Greek oil-flask", "Wine pitcher"],
  "orad": ["Toward the mouth", "Toward the mouth", "Toward the mouth"],
  "agag": [
    "King slain by Samuel",
    "Magistrate in Dryden's \"Absalom and Achitophel\""
  ],
  "osar": ["Geological ridges", "Glacial ridges", "Glacial ridges"],
  "sete": ["Rio seven", "French resort town"],
  "stor": ["Warehouse abbr.", "Warehouse: Abbr."],
  "dsms": ["U.S. mil. awards", "Mil. awards"],
  "ular": ["Suffix with cell or gland", "Gland finale?"],
  "ecos": ["Umberto and kin", "Author Umberto et al."],
  "advt": ["Paid promotion: Abbr."],
  "zell": ["George Burns buddy Harry Von ___"],
  "orde": ["Wingate of W.W. II", "General Wingate"]
}

export default fourData;